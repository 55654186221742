import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import doLoadPickupTypes from '../services/loadPickupTypes';
import { PickupType } from '../interfaces/PickupTypes';

interface State {
  isLoading: boolean;
  pickupTypes?: PickupType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Actions
const START_LOAD = 'routes/pickupTypes/START_LOAD';
const COMPLETE_LOAD = 'routes/pickupTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/pickupTypes/FAIL_LOAD';

// Initial state
const initialState = {
  isLoading: false,
  pickupTypes: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          pickupTypes: action.pickupTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          pickupTypes: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (pickupTypes: PickupType[]) => ({
  type: COMPLETE_LOAD,
  pickupTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadPickupTypes = (vehicleTypeId : number | null = null) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadPickupTypesPromise = doLoadPickupTypes(vehicleTypeId);
  loadPickupTypesPromise.then(pickupTypes => dispatch(completeLoad(pickupTypes))).catch(() => dispatch(failLoad()));
  return loadPickupTypesPromise;
};
