import moment from 'moment';

import { Holiday, RawHolidayOptionalId, HolidayPlannerState } from '../interfaces/HolidayPlanner';
import { POSTPONEMENT_TYPES } from '../constants/holidayPlanner';
import translate from '../../core/services/translate';

const checkIfRecurrent = (holiday: RawHolidayOptionalId): boolean => !holiday.exactDayOfMonth;

const checkIfExactPostpone = (holiday: RawHolidayOptionalId): boolean => !!holiday.postponeToDate;

const generateDate = (date?: string | null): string | undefined =>
  date ? moment(date).format('MM/DD/YYYY') : undefined;

export const transformHoliday = (holiday: Holiday): RawHolidayOptionalId => {
  const rawHoliday: RawHolidayOptionalId = {
    id: holiday.id,
    name: holiday.isDefault ? holiday.originalName : holiday.name,
    isActive: holiday.isActive,
    isDefault: holiday.isDefault,
    targetYear: holiday.calculatedHolidayDate
      ? new Date(holiday.calculatedHolidayDate).getFullYear()
      : new Date().getFullYear(),
  };

  if (holiday.isExactPostpone) {
    rawHoliday.postponeToDate = holiday.postponeToDate;
  } else {
    rawHoliday.postponeNumberOfDays = holiday.postponeNumberOfDays;
    rawHoliday.postponementType = holiday.postponementType;

    if (
      holiday.postponeNumberOfDays === 1 &&
      holiday.postponementType &&
      holiday.postponementType.id === POSTPONEMENT_TYPES.AFTER
    ) {
      rawHoliday.routeShiftingDays = holiday.routeShiftingDays === 0 ? null : holiday.routeShiftingDays;
    }
  }

  if (holiday.isRecurrent) {
    rawHoliday.recurrenceDayType = holiday.recurrenceDayType;
    rawHoliday.recurrenceDayOfWeek = holiday.recurrenceDayOfWeek;
    rawHoliday.recurrenceMonthOfYear = holiday.recurrenceMonthOfYear;
  } else {
    rawHoliday.exactDayOfMonth = Number(moment(holiday.calculatedHolidayDate, 'MM/DD/YYYY').format('D'));
    rawHoliday.exactMonthOfYear = Number(moment(holiday.calculatedHolidayDate, 'MM/DD/YYYY').format('M'));
  }

  return rawHoliday;
};

export const transformRawHoliday = (holiday: RawHolidayOptionalId): Holiday => ({
  ...holiday,
  name: holiday.isDefault ? translate(`routes.holidayPlanner.defaultHolidays.${btoa(holiday.name)}`) : holiday.name,
  originalName: holiday.name,
  saveInProgress: false,
  deleteInProgress: false,
  isRecurrent: checkIfRecurrent(holiday),
  isExactPostpone: checkIfExactPostpone(holiday),
  postponeToDate: generateDate(holiday.postponeToDate),
  calculatedHolidayDate: generateDate(holiday.calculatedHolidayDate),
  calculatedPostponeDate: generateDate(holiday.calculatedPostponeDate),
});

export const transformRawHolidays = (holidays: RawHolidayOptionalId[]): Holiday[] => holidays.map(transformRawHoliday);

export const fetchHolidayIndexById = (state: HolidayPlannerState, id: number) => {
  for (let i = 0; i < state.holidays.length; i++) {
    if (state.holidays[i].id === id) {
      return i;
    }
  }

  return -1;
};

export const fetchFreshHolidayIndex = (state: HolidayPlannerState) => {
  for (let i = 0; i < state.holidays.length; i++) {
    if (state.holidays[i].isFresh) {
      return i;
    }
  }

  return -1;
};

export function notUndefined<T>(t: T | undefined | null): t is T {
  return t !== undefined && t !== null;
}

export const buildRecurrenceId = (holiday: Holiday) =>
  `${holiday.recurrenceDayType && holiday.recurrenceDayType.id}/${holiday.recurrenceDayOfWeek}/${
    holiday.recurrenceMonthOfYear
  }`;
