import { push } from 'connected-react-router';
import moment from 'moment';
import { useMemo } from 'react';
import { Navigate, ToolbarProps } from 'react-big-calendar';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';

import { ROUTES_PLANNER_EXPORT, ROUTES_PLANNER_SERVICE_ZONES } from 'src/account/constants';
import { ROUTES_PLANNER_HOLIDAY_PLANNER, ROUTES_PLANNER_SCHEDULE } from 'src/account/constants/permissions';
import {
  checkIfSupport,
  checkIfViewOnly,
  hasPermission,
  useHasRouteTemplateBuilderAccess,
} from 'src/account/utils/permissions';
import { ActionButtonTooltip, MoreButton, UnconnectedDropdown } from 'src/core/components';
import { MoreButtonItem } from 'src/core/components/MoreButton';
import { Button, Grid, GridColumn, IconButtonIcon } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { ShadowedButtonSet } from 'src/core/components/styled/ButtonSet';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { RoutePlannerVehicleTypesForm } from 'src/routes/components/forms';
import { CalendarToolbarWrapper } from 'src/routes/components/styled';
import { generateCalendarDropDownOptions } from 'src/routes/utils/routePlanner';
import {
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  routeTemplateBuilderIsEnabled,
} from 'src/vendors/ducks/features';

interface CalendarToolbarProps {
  onDateChange: (date: Date) => void;
  openScheduler: (templates: { id: number; scheduledDay: number }[]) => void;
  onExport: (isDaily: boolean) => void;
  onImport: () => void;
  date: Date;
  vehicleTypeInitialValues: any;
}

const CalendarToolbar = ({
  onDateChange,
  openScheduler,
  onImport,
  onExport,
  date: incomingDate,
  vehicleTypeInitialValues,
}: CalendarToolbarProps) => {
  const dispatch = useDispatch();

  const hasRouteTemplateBuilderAccess = useHasRouteTemplateBuilderAccess();
  const routeTemplateBuilderEnabled = useSelector(routeTemplateBuilderIsEnabled);
  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const isStreetSweeperFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);

  const isViewOnlyOrSupport = checkIfSupport() || checkIfViewOnly();

  const moreButtonItemsMemo = useMemo(() => {
    const moreButtonItems: MoreButtonItem[] = [];

    if (hasPermission(ROUTES_PLANNER_SERVICE_ZONES))
      moreButtonItems.push({
        text: translate('routes.serviceZones'),
        startAdornment: <IconButtonIcon size="mLarge" icon="pinZone" color="black" customViewBox="0 -3 28 28" />,
        handler: () => dispatch(push('/routes/service-zones')),
      });

    if (
      (isSnowPlowFeatureEnabled || isStreetSweeperFeatureEnabled) &&
      !isViewOnlyOrSupport &&
      hasPermission(ROUTES_PLANNER_SCHEDULE)
    )
      moreButtonItems.push({
        text: `${translate('tooltips.scheduleAll')}...`,
        startAdornment: <IconButtonIcon size="large" icon="clock" color="black" margin="no xSmall no no" />,
        handler: () => openScheduler([]),
      });

    if (hasRouteTemplateBuilderAccess && routeTemplateBuilderEnabled)
      moreButtonItems.push({
        text: translate('routeTemplateBuilder.routeTemplateBuilder'),
        startAdornment: <IconButtonIcon size="mLarge" icon="pinGrid" color="black" customViewBox="0 -4 32 32" />,
        handler: () => dispatch(push('/routes/route-template-builder')),
      });

    if (hasPermission(ROUTES_PLANNER_EXPORT))
      moreButtonItems.push(
        {
          text: translate('routes.planner.exportReoccurringRoutes'),
          startAdornment: <IconButtonIcon size="mLarge" icon="cloudDown" color="black" customViewBox="0 -3 32 32" />,
          handler: () => onExport(false),
        },
        {
          text: translate('routes.planner.exportDailyRoutes'),
          startAdornment: <IconButtonIcon size="mLarge" icon="cloudDown" color="black" customViewBox="0 -3 32 32" />,
          handler: () => onExport(true),
        },
      );

    if (!isViewOnlyOrSupport)
      moreButtonItems.push({
        text: translate('tooltips.import'),
        startAdornment: <IconButtonIcon size="mLarge" icon="cloudUp" color="black" customViewBox="0 -3 30 30" />,
        handler: () => onImport(),
      });

    if (hasPermission(ROUTES_PLANNER_HOLIDAY_PLANNER))
      moreButtonItems.push({
        text: translate('routes.holidayPlanner.holidayPlanner'),
        startAdornment: (
          <IconButtonIcon
            size="large"
            icon="calendar"
            color="black"
            margin="no xxSmall no no"
            customViewBox="0, 0, 32,32"
          />
        ),
        handler: () => dispatch(push('/routes/holiday-planner')),
      });

    moreButtonItems.push({
      text: translate('routes.groups.manageGroups'),
      startAdornment: (
        <IconButtonIcon size="mLarge" icon="groups" color="black" customViewBox="0 0 27 27" margin="no xxSmall no no" />
      ),
      handler: () => dispatch(push('/routes/groups')),
    });

    return moreButtonItems;
  }, [
    dispatch,
    hasRouteTemplateBuilderAccess,
    isSnowPlowFeatureEnabled,
    isStreetSweeperFeatureEnabled,
    isViewOnlyOrSupport,
    onExport,
    onImport,
    openScheduler,
    routeTemplateBuilderEnabled,
  ]);

  return ({ onNavigate, date }: ToolbarProps) => {
    //  generating options from dropdown (5 options before and after current date)
    const dropdownOptions = generateCalendarDropDownOptions(date);

    const onDropdownChange = (value: string) => {
      onDateChange(new Date(value));
      onNavigate(Navigate.DATE, new Date(value));
    };

    const onPrevClick = () => {
      onNavigate(Navigate.PREVIOUS);
      onDateChange(moment(date).add(-1, 'week').toDate());
    };

    const onNextClick = () => {
      onNavigate(Navigate.NEXT);
      onDateChange(moment(date).add(1, 'week').toDate());
    };

    const onTodayClick = () => {
      onNavigate(Navigate.TODAY);
      onDateChange(new Date());
    };

    return (
      <CalendarToolbarWrapper>
        <Box display="inline-flex" alignItems="center">
          <RoutePlannerVehicleTypesForm initialValues={vehicleTypeInitialValues} />
          <ShadowedButtonSet margin="no" padding="no" roundedBorder>
            <Button
              size="xSmall"
              margin="no"
              color="white"
              onClick={onPrevClick}
              borderRight
              squaredOnBottom
              squaredOnTop
            >
              <IconButtonIcon icon="back" color="primary" />
            </Button>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="255px"
              padding="xxSmall xSmall"
              backgroundColor="white"
              withBorderRight
            >
              <Button color="white" onClick={onTodayClick} size="xxSmall">
                <ActionButtonTooltip withHoverColor icon="calendar" color="black" size="sMedium" tooltip="today" />
              </Button>
              <UnconnectedDropdown
                options={dropdownOptions}
                margin="no xSmall"
                width="245px"
                value={moment(incomingDate).format('MM-DD-YYYY')}
                onChange={onDropdownChange}
                customComponents={{
                  Option: ({ children, ...props }: any) => {
                    const separatedDate = children.split('-');
                    return (
                      <components.Option {...props}>
                        <Grid>
                          <GridColumn width="45%" padding="no">
                            {separatedDate[0]}
                          </GridColumn>
                          <GridColumn padding="no xSmall">-</GridColumn>
                          <GridColumn width="45%" padding="no">
                            {separatedDate[1]}
                          </GridColumn>
                        </Grid>
                      </components.Option>
                    );
                  },
                }}
              />
            </Box>
            <Button size="xSmall" margin="no" color="white" onClick={onNextClick} squaredOnBottom squaredOnTop>
              <IconButtonIcon icon="back" rotate={180} color="primary" />
            </Button>
          </ShadowedButtonSet>
        </Box>
        <Box display="inline-flex" alignItems="center">
          <MoreButton margin="no small no no" buttonText={translate('common.options')} items={moreButtonItemsMemo} />
        </Box>
      </CalendarToolbarWrapper>
    );
  };
};

export default CalendarToolbar;
