import { map } from 'lodash-es';
import { ReasonCodeTypesResponse } from '../interfaces/ReasonCodeTypes';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformReasonCodeTypes = (reasonCodeTypes: ReasonCodeTypesResponse) =>
  map(reasonCodeTypes, reasonCodeType => ({
    ...reasonCodeType,
    name: translate(createTranslationKey(reasonCodeType.technicalName, 'common.reasonCodeTypes')),
  }));

export default transformReasonCodeTypes;
