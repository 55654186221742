import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Source, Layer } from 'react-map-gl';
import { FeaturePoint } from 'src/common/components/map/FeaturePoint';
import { getFeatureCollection, getMapBounds } from 'src/common/components/map/util';
import mapboxgl from 'mapbox-gl';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { COLLECTION_POINT } from 'src/common/constants/binMappingTypes';
import { DirtyPickupLocationsDictionary } from './PickupLocationsEditorPage';
import { DirtyPickupLocation } from 'src/routes/interfaces/DirtyPickupLocation';
import { useDraggableMarkers } from 'src/common/components/map/hooks/useDraggableMarkers';
import { Coordinates } from 'src/common/components/map/Coordinates';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import { PickupLocationMarkerInfoWindow } from './PickupLocationMarkerInfoWindow';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { CollectionPoint } from 'src/routes/interfaces/CollectionPoint';
import { debounce } from 'lodash-es';
import { useMapDoubleClick } from 'src/common/components/map/hooks/useMapDoubleClick';
import { useClickableFeatures } from 'src/common/components/map/hooks/useClickableFeatures';
import { useZoomableClusters } from 'src/common/components/map/hooks/useZoomableClusters';

const pickupLocationsSourceId = 'pickup-locations';
export const pickupLocationsLayerId = 'pickup-locations-layer';
const assignedLayerId = `${pickupLocationsLayerId}_assigned`;
const clusterLayerId = `${pickupLocationsLayerId}_cluster`;
const clusterNumberLayerId = `${pickupLocationsLayerId}_cluster_number`;
const selectedLayerId = `${pickupLocationsLayerId}_selected`;

const getMarkerPosition = (routeLocation: RouteLocation, dirtyPickupLocation?: DirtyPickupLocation) => {
  const container = routeLocation.service.serviceContractBinDetails[0];
  const address = routeLocation.location.address;
  return {
    lat: dirtyPickupLocation
      ? dirtyPickupLocation.binMappingTypeId === COLLECTION_POINT
        ? address.latitude
        : dirtyPickupLocation.latitude
      : container.binLocationMappingTypeId === COLLECTION_POINT
      ? address.latitude
      : container.binLatitude,
    lng: dirtyPickupLocation
      ? dirtyPickupLocation.binMappingTypeId === COLLECTION_POINT
        ? address.longitude
        : dirtyPickupLocation.longitude
      : container.binLocationMappingTypeId === COLLECTION_POINT
      ? address.longitude
      : container.binLongitude,
  };
};

const getFeaturePoints = (routeLocations: RouteLocation[], dirtyLocations: DirtyPickupLocationsDictionary) => {
  return routeLocations.map((rl, index) => {
    const { lat, lng } = getMarkerPosition(rl, dirtyLocations[index]);
    const { serviceContractRouteTemplateId } = rl;
    const serviceContractId = rl.service.serviceContractBinDetails[0].serviceContractId;
    const featurePoint = new FeaturePoint(lat, lng, serviceContractRouteTemplateId);
    featurePoint.properties = {
      serviceContractId,
      index,
      id: serviceContractRouteTemplateId,
      lat,
      lng,
    };
    return featurePoint;
  });
};

interface RouteLocationsMarkerSourceProps {
  activeCollectionPoint?: CollectionPoint;
  markLocationActive: (index: number) => void;
  activeLocations: number[];
  routeLocations: RouteLocation[];
  dirtyPickupLocations: DirtyPickupLocationsDictionary;
  map: mapboxgl.Map;
  setDragPan: (drag: boolean) => void;
  isEditingCollectionPoints: boolean;
  onAddressChange: (index: number, serviceContractId: number, lat: number, lng: number) => void;
  setViewport: (viewPort: MapGLViewport) => void;
  fitToMapBounds: boolean;
}

export const PickupLocationsMarkerSource: React.FC<RouteLocationsMarkerSourceProps> = ({
  activeCollectionPoint,
  isEditingCollectionPoints,
  routeLocations,
  map,
  setDragPan,
  dirtyPickupLocations,
  onAddressChange,
  markLocationActive,
  setViewport,
  activeLocations,
  fitToMapBounds,
}) => {
  const geojson = useMemo(() => {
    return getFeatureCollection(getFeaturePoints(routeLocations, dirtyPickupLocations));
  }, [dirtyPickupLocations, routeLocations]);

  useEffect(() => {
    if (fitToMapBounds) {
      const bounds = getMapBounds(
        geojson.features.map(f => ({ latitude: f.properties?.lat, longitude: f.properties?.lng })),
        { padding: 20 },
      );
      setViewport(bounds);
    }
  }, [fitToMapBounds, geojson.features, map, setViewport]);

  const onDragEnd = async (marker: GeoJSON.Feature<GeoJSON.Point, any>, newCoords: Coordinates) => {
    const index = routeLocations.findIndex(rl => rl.serviceContractRouteTemplateId === marker.id);
    if (index > -1) {
      const routeLocation = routeLocations[index];
      const container = routeLocation.service.serviceContractBinDetails[0];
      const dirtyPickupLocation = dirtyPickupLocations[index];

      const binMappingTypeId = dirtyPickupLocation
        ? dirtyPickupLocation.binMappingTypeId
        : container.binLocationMappingTypeId;
      if (binMappingTypeId === COLLECTION_POINT) {
        if (
          !(await confirm(
            translate('pickupLocationEditor.alerts.areYouSure'),
            translate('pickupLocationEditor.alerts.pointIsAssociatedToCollectionPoint'),
          ))
        ) {
          return false;
        }
      }

      onAddressChange(index, container.serviceContractId, newCoords.lat, newCoords.lng);
      return true;
    }
    return false;
  };

  const markLocationActiveDebounced = useMemo(() => debounce(markLocationActive, 200), [markLocationActive]);

  const handleRouteLocationClick = useCallback(
    (marker: mapboxgl.MapboxGeoJSONFeature) => {
      const locationIndex = routeLocations.findIndex(rl => rl.serviceContractRouteTemplateId === marker.id);
      if (locationIndex > -1) {
        if (isEditingCollectionPoints) {
          markLocationActiveDebounced(locationIndex);
        } else {
          setActiveLocation(routeLocations[locationIndex]);
        }
      }
    },
    [isEditingCollectionPoints, markLocationActiveDebounced, routeLocations],
  );

  const handleDoubleClick = useCallback(
    (lng: number, lat: number, feature?: mapboxgl.MapboxGeoJSONFeature) => {
      if (isEditingCollectionPoints) {
        const rlIndex = routeLocations.find(rl => rl.serviceContractRouteTemplateId === feature?.properties?.id);
        setActiveLocation(rlIndex);
      }
    },
    [isEditingCollectionPoints, routeLocations],
  );

  const [activeLocation, setActiveLocation] = useState<RouteLocation | undefined>();

  useDraggableMarkers({
    map,
    source: geojson,
    sourceId: pickupLocationsSourceId,
    layerId: pickupLocationsLayerId,
    setDragPan,
    updateAddress: onDragEnd,
    draggingEnabled: !isEditingCollectionPoints,
  });

  useMapDoubleClick([pickupLocationsLayerId], handleDoubleClick, map);

  useClickableFeatures([pickupLocationsLayerId], handleRouteLocationClick, map);

  useZoomableClusters(pickupLocationsSourceId, [clusterLayerId], setViewport, map);

  return (
    <>
      <Source tolerance={0.0001} clusterMaxZoom={11} cluster type="geojson" id={pickupLocationsSourceId} data={geojson}>
        <Layer
          id={pickupLocationsLayerId}
          type="circle"
          filter={['!', ['has', 'point_count']]}
          paint={{
            'circle-color': '#009688',
            'circle-radius': 6,
          }}
        />
        {!!activeCollectionPoint?.serviceContractIds.length && (
          <Layer
            id={assignedLayerId}
            filter={['in', ['get', 'serviceContractId'], ['literal', activeCollectionPoint.serviceContractIds]]}
            type="circle"
            paint={{
              'circle-color': '#cd2927',
              'circle-radius': 6,
            }}
          />
        )}

        {!!activeLocations.length && (
          <Layer
            id={selectedLayerId}
            filter={['in', ['get', 'index'], ['literal', [...activeLocations]]]}
            type="circle"
            paint={{
              'circle-color': '#f6b169',
              'circle-radius': 6,
            }}
          />
        )}

        <Layer
          id={clusterLayerId}
          type="circle"
          filter={['has', 'point_count']}
          paint={{
            'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
            'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          }}
        />
        <Layer
          id={clusterNumberLayerId}
          type="symbol"
          filter={['has', 'point_count']}
          paint={{
            'text-color': 'white',
          }}
          layout={{
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
          }}
        />
      </Source>
      {activeLocation && (
        <PickupLocationMarkerInfoWindow
          onClose={() => setActiveLocation(undefined)}
          routeLocation={activeLocation}
          dirtyPickupLocation={dirtyPickupLocations[routeLocations.indexOf(activeLocation)]}
        />
      )}
    </>
  );
};
