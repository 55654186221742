import { http } from '../../core/services/http';
import { ServiceZone } from '../interfaces/ServiceZones';
import { transformLoadServiceZones } from './transformLoadServiceZones';

const ALL_RECORDS = true;
const INCLUDE_COUNTS = true;

export const loadServiceZones = (
  vendorId: number,
  allRecords: boolean = ALL_RECORDS,
  includeCounts: boolean = INCLUDE_COUNTS,
) =>
  http
    .get(`/vendors/${vendorId}/serviceZones`, {
      params: {
        allRecords,
        includeCounts,
      },
    })
    .then(response => transformLoadServiceZones(response.data.serviceZones));

export const updateServiceZone = (serviceZone: ServiceZone) => http.put(`/serviceZones/${serviceZone.id}`, serviceZone);

export const createServiceZone = (vendorId: number, serviceZone: ServiceZone) =>
  http.post(`/vendors/${vendorId}/serviceZones/`, serviceZone);

export const deleteServiceZone = (serviceZoneId: number) => http.delete(`/serviceZones/${serviceZoneId}`);
