import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { mapper, sizeMapper } from '../../../utils/styles';

interface Props {
  padding?: string;
  margin?: string;
  size?: string;
  vertical?: boolean;
  width?: string;
}

export const MapInfoWindowDetailLabel = styled.span`
  margin-right: 4px;
  font-weight: ${props => props.theme.fontWeightMedium};
`;

export const MapInfoWindowTitleContainer = styled.div`
  display: flex;
`;

export const MapInfoWindowDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
`;

export const MapInfoWindowDetail = styled.div<{ noCapitalize?: boolean }>`
  margin-bottom: 4px;
  text-transform: ${p => (p.noCapitalize ? 'initial' : 'capitalize')};
  line-height: 18px;
  font-size: 12px;
`;

export const MapInfoWindowTitle = styled.span<{ align?: string }>`
  display: block;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 18px;
  text-align: ${props => props.align || 'left'};
`;

export const MapInfoWindowDetails = styled.div<{ padding?: string; margin?: boolean }>`
  flex: 1;
  padding: ${props => sizeMapper(props.padding || 'no')};

  ${props =>
    props.margin &&
    css`
      margin-right: 80px;
    `};
`;

export const MapInfoImageWrapper = styled.div<{
  overlay?: number;
  width?: string;
  noStyling?: boolean;
}>`
  width: ${props => props.width || '30%'};
  height: 100px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  ${props =>
    props.noStyling &&
    css`
      height: auto;

      ${MapInfoImage} {
        max-width: 100% !important;
        max-height: 150px;
        top: 0;
        transform: none;
      }
    `};

  ${props =>
    props.overlay &&
    css`
      &:before {
        content: '${props.overlay}';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        text-align: center;
        background-color: ${transparentize(0.5, props.theme.grayBase)};
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
      }
    `};
`;

export const MapInfoWindowIcon = styled.img<Props>`
  width: ${props => mapper(props.size, { small: '15px', medium: '40px' })};
  height: ${props => mapper(props.size, { small: '15px', medium: '40px' })};
  margin: 5px 15px 0 0;
`;

export const MapInfoWindow = styled.div<Props>`
  display: flex;
  width: ${props => props.width || '340px'};
  padding: 4px 0;
  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};
`;

export const MapInfoImage = styled.img<Props>`
  display: block;
  max-width: 100% !important;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
