
import styled, { css } from 'styled-components';

export const ThumbnailWrapper = styled.div<{
  active?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;

  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.brandPrimary};
    `};
`;
