import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { BareButtonStyle } from '../../../../core/components/styled/Button';
import translate from '../../../../core/services/translate';

interface Props {
  isEditingCollectionPoints: boolean;
  hasMarkedLocations: boolean;
}

const Container = styled.div`
  z-index: 1;
  position: absolute;
  background-color: white;
  right: 5px;
  bottom: 15px;
  max-width: 250px;
  padding: 20px;

  div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Toggle = styled.button`
  ${BareButtonStyle}
  color: ${p => p.theme.brandPrimary};
`;

const individualPointsInstructions = [translate('pickupLocationEditor.instructions.movePickupLocations')];
const collectionPointsInstructions = [
  translate('pickupLocationEditor.instructions.createCollectionPoint'),
  translate('pickupLocationEditor.instructions.dragCollectionPoints'),
  translate('pickupLocationEditor.instructions.markActiveLocations'),
  translate('pickupLocationEditor.instructions.doubleClickStops'),
];
const activeLocationsInstructions = [
  translate('pickupLocationEditor.instructions.associateToCollectionPoint'),
  translate('pickupLocationEditor.instructions.createCollectionPoint'),
];

export const PickupLocationEditorInstructions: React.FC<Props> = ({
  isEditingCollectionPoints,
  hasMarkedLocations,
}) => {
  const [showInstructions, setShowInstructions] = useState(true);
  const getInstructions = () => {
    if (hasMarkedLocations) {
      return activeLocationsInstructions;
    }
    if (isEditingCollectionPoints) {
      return collectionPointsInstructions;
    }
    if (!isEditingCollectionPoints) {
      return individualPointsInstructions;
    }
    return [];
  };
  return (
    <Container>
      <div>
        <Toggle onClick={() => setShowInstructions(!showInstructions)}>
          {translate(`pickupLocationEditor.instructions.${showInstructions ? 'hideInstructions' : 'showInstructions'}`)}
        </Toggle>
      </div>
      {showInstructions && getInstructions().map(i => <div key={i}>{i}</div>)}
    </Container>
  );
};
