import React from 'react';

import { useDispatch } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadBillingModule, loadVehicleTypesForPickupExceptions } from '../../ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ExceptionConfigurationModal from './ExceptionConfigurationModal';
import { useSelector } from 'src/core/hooks/useSelector';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { loadRateAccountingCodes, loadRateCodeTypes } from 'src/common/ducks';

const ExceptionConfigurationModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const features = useSelector(state => state.vendors.features.features);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadVehicleTypesForPickupExceptions(vendorId)(dispatch),
      loadRateCodeTypes()(dispatch),
      loadRateAccountingCodes()(dispatch),
    ];

    if (!!billingFeatureStatusSelector(features)) dependencies.push(loadBillingModule(vendorId)(dispatch));

    return Promise.all(dependencies);
  }, [vendorId, dispatch, features]);

  return (
    <Resolver
      successComponent={ExceptionConfigurationModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default ExceptionConfigurationModalResolver;
