import humps from 'humps';

import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { PICKUP_STATUSES, WASTE_AUDIT } from 'src/common/constants';
import { ROUTE_MAP_ROUTE_STOPS_LAYER } from '../constants';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import {
  ACCOUNT_STATUSES,
  ACTIVE,
  EXTRA_PICKUP,
  COMPLETED,
  ISSUE_REPORTED,
  SERVICED,
  SCHEDULED,
  CONTAINER_NOT_OUT,
} from 'src/routes/constants';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';

export type ContainerInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
  orderNo?: number;
};

export const getRouteStopIconType = (routeStop: RouteStop, routeSummary?: RouteSummary) => {
  const accountStatus =
    routeStop.accountTypeId && routeStop.accountTypeId !== ACTIVE && routeStop.accountTypeId !== EXTRA_PICKUP
      ? ACCOUNT_STATUSES[routeStop.accountTypeId]?.technicalName
      : '';
  const flagged = routeStop.vendorLocationAlertIds?.length ? 'Flagged' : '';
  const assisted = routeStop.assistingVehicleRegplate ? 'Assisted' : '';
  let pickupStatus = PICKUP_STATUSES[routeStop.pickupStatusTypeId || SCHEDULED].technicalName;

  if (routeStop.pickupStatusTypeId === SERVICED)
    return NEW_INSIGHT_ICON_TYPES.find(
      ({ types }) => types.indexOf(humps.camelize(PICKUP_STATUSES[SERVICED].technicalName)) !== -1,
    );

  if (routeStop.numberOfIssues && routeSummary?.vehicleTypeName !== WASTE_AUDIT) {
    pickupStatus = PICKUP_STATUSES[ISSUE_REPORTED].technicalName;
    if (routeStop.pickupStatusTypeId === SCHEDULED) pickupStatus += 'NotServiced';
    if (routeStop.pickupStatusTypeId === COMPLETED) pickupStatus += 'Serviced';
    if (routeStop.pickupStatusTypeId === ISSUE_REPORTED) pickupStatus += 'NotServiced';
  }

  if (
    routeSummary?.vehicleTypeName === WASTE_AUDIT &&
    !!routeStop.numberOfIssues &&
    (routeStop.wasteAuditStatuses?.length || routeStop.wasteAuditLocationStatusTypeId === CONTAINER_NOT_OUT)
  ) {
    pickupStatus = PICKUP_STATUSES[ISSUE_REPORTED].technicalName;
    if (routeStop.pickupStatusTypeId === COMPLETED) pickupStatus += 'Serviced';
    if (routeStop.pickupStatusTypeId === ISSUE_REPORTED) pickupStatus += 'NotServiced';
  }

  if (routeStop.displayAsScheduled && routeStop.displayAsScheduled.get()) {
    pickupStatus = PICKUP_STATUSES[SCHEDULED].technicalName;
  }

  const insightType = `ic${accountStatus}${flagged}${assisted}${pickupStatus}`;

  return NEW_INSIGHT_ICON_TYPES.find(({ types }) => types.indexOf(insightType) !== -1);
};

export const getRouteStopsGeoJSON = (routeStops: RouteStop[], routeSummary?: RouteSummary) =>
  getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
    routeStops.map(routeStop => {
      return getPointFeature(routeStop.id, [routeStop.displayLongitude, routeStop.displayLatitude], {
        id: routeStop.id,
        clickable: true,
        layer: ROUTE_MAP_ROUTE_STOPS_LAYER,
        icon: getRouteStopIconType(routeStop, routeSummary)?.id,
        orderNo: routeStop.orderNo,
        newOrderNumber: routeStop.newOrderNumber || undefined,
      });
    }),
  );

export const getYRouteStopIconType = (routeLocation: RouteLocation) => {
  const pickupStatus = PICKUP_STATUSES[routeLocation.pickupStatusId || SCHEDULED].technicalName;

  const insightType = `ic${pickupStatus}`;
  return NEW_INSIGHT_ICON_TYPES.find(({ types }) => types.indexOf(insightType) !== -1);
};

export const getYRouteStopsGeoJSON = (routeLocations: RouteLocation[]) =>
  getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
    routeLocations.map(routeLocation =>
      getPointFeature(
        routeLocation.id,
        [routeLocation.location.address.longitude, routeLocation.location.address.latitude],
        {
          id: routeLocation.id,
          clickable: true,
          layer: ROUTE_MAP_ROUTE_STOPS_LAYER,
          icon: getYRouteStopIconType(routeLocation)?.id,
          orderNo: routeLocation.orderNumber,
        },
      ),
    ),
  );
