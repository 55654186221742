import React from 'react';
import MediaQuery from 'react-responsive';
import { mobileNavigationPageWidthThreshold, mobilePageWidthThreshold } from 'src/core/constants/mobilePages';
import { useIsMobileView } from 'src/utils/hooks';

export const MobileWidthView: React.FC<{ isNavigation?: boolean }> = ({ children, isNavigation }) => (
  <MediaQuery maxWidth={isNavigation ? mobileNavigationPageWidthThreshold : mobilePageWidthThreshold + 'px'}>
    {children || null}
  </MediaQuery>
);

export const UseIsMobileWidthView: React.FC<{ render: (isMobile: boolean) => JSX.Element }> = ({ render }) => {
  return render(useIsMobileView());
};
