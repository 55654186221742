import humps from 'humps';
import { every, filter, find, groupBy, isEmpty, map } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';

import { ALERT_TYPES, ROUTE_CITY_ALERTS, ALL_CITY_ALERTS } from 'src/fleet/constants/locationAndCityAlerts';
import { Button, Popover, Separator, Text } from 'src/core/components/styled';
import {
  CANCELED,
  COMPLETED,
  CUSTOMER_SUBTYPE_CONTAINER_YARD,
  CUSTOMER_SUBTYPE_FUELING_STATION,
  CUSTOMER_SUBTYPE_LANDFILL_OTHER,
  CUSTOMER_SUBTYPE_LANDFILL,
  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
  CUSTOMER_SUBTYPE_TRUCK_YARD,
  CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
  HIGH_JOB_PRIORITY_ID,
  IN_PROGRESS,
  ISSUE_REPORTED,
  PICKUP_STATUSES,
  PLACED_ON_HOLD,
  REASSIGNED,
  SCHEDULED,
  SERVICED,
} from 'src/common/constants';
import { Checkbox, PopoverWrapper, Switch, TypedField } from 'src/core/components';
import { checkIfGeoFenceIsEnabled } from 'src/vendors/ducks/features';
import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { ExpandableMapCityAlertsSubsection } from 'src/customers/components/forms/ExpandableMapCityAlertsSubsection';
import { ExpandableMapFiltersSection } from 'src/customers/components/forms/ExpandableMapFiltersSection';
import { ExpandableMapFiltersSubsection } from 'src/customers/components/forms/ExpandableMapFiltersSubsection';
import { GENERAL_GEO_FENCE_ID, GEO_FENCES_ALL_TYPES, SCHEDULED as SCHEDULED_ROUTE } from 'src/routes/constants';
import { GeoFenceMapFilterOption } from 'src/routes/ducks/geoFenceFilterOptions';
import { getActiveCityAlertTypes } from 'src/fleet/components/forms/LocationAndCityAlertsSearchForm';
import { getPersistentMapFilters } from 'src/routes/services/persistentMapFilterSettingStorage';
import { ROUTE } from 'src/customers/constants/streetNetwork';
import {
  RouteMapFiltersClose,
  RouteMapFiltersCloseIcon,
  RouteMapFiltersSectionTitle,
  RouteMapFiltersSectionWording,
  RouteMapFiltersSection as RouteMapFiltersSectionWrapper,
  RouteMapFiltersTitle,
  RouteMapFiltersWrapper,
} from 'src/routes/components/styled/RouteMapFilters';
import { TravelPathField } from 'src/routes/components/forms';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export const HAULER_LOCATION_FILTERS = [
  {
    id: CUSTOMER_SUBTYPE_TRUCK_YARD,
    label: translate('facilities.facilitySubTypes.truckYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_CONTAINER_YARD,
    label: translate('facilities.facilitySubTypes.containerYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_LANDFILL,
    label: translate('facilities.facilitySubTypes.landfill'),
  },
  {
    id: CUSTOMER_SUBTYPE_FUELING_STATION,
    label: translate('facilities.facilitySubTypes.fuelingStation'),
  },
];

export const SNOW_SWEEPER_HAULER_LOCATION_FILTERS = [
  {
    id: CUSTOMER_SUBTYPE_TRUCK_YARD,
    label: translate('facilities.facilitySubTypes.truckYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_CONTAINER_YARD,
    label: translate('facilities.facilitySubTypes.containerYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_LANDFILL,
    label: translate('facilities.facilitySubTypes.landfill'),
  },
  {
    id: CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
    label: translate('facilities.facilitySubTypes.saltChemicalFillingStation'),
  },
  {
    id: CUSTOMER_SUBTYPE_FUELING_STATION,
    label: translate('facilities.facilitySubTypes.fuelingStation'),
  },
  {
    id: CUSTOMER_SUBTYPE_LANDFILL_OTHER,
    label: translate('facilities.facilitySubTypes.otherOperationalFacility'),
  },
  {
    id: CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
    label: translate('facilities.facilitySubTypes.waterFillingStation'),
  },
];

type FiltersSectionProps = {
  change: InjectedFormProps['change'];
  checkAllFieldName: string;
  filters: { id: number | string; label: string }[];
  name: string;
  title: string;
};

function RouteMapFiltersSection({ change, checkAllFieldName, filters, name, title }: FiltersSectionProps) {
  const handleCheckAll: TypedFieldOnChangeFunction = (_, isChecked) => {
    filters.forEach(filter => {
      change(`${name}[${filter.id}]`, isChecked);
    });
  };

  const handleChangeFilter = () => {
    change(checkAllFieldName, false);
  };

  return (
    <RouteMapFiltersSectionWrapper>
      <RouteMapFiltersSectionTitle>{title}</RouteMapFiltersSectionTitle>
      <TypedField
        name={checkAllFieldName}
        component={Checkbox}
        onChange={handleCheckAll}
        props={{
          label: translate('common.all'),
          block: true,
          margin: 'xSmall no',
          size: 'small',
        }}
      />
      {filters.map(filter => (
        <TypedField
          key={filter.id}
          name={`${name}[${filter.id}]`}
          component={Checkbox}
          onChange={handleChangeFilter}
          props={{
            label: filter.label,
            block: true,
            margin: 'xSmall no',
            size: 'small',
          }}
        />
      ))}
    </RouteMapFiltersSectionWrapper>
  );
}

export type RouteMapFiltersFormValues = {
  cityInsightsFilters: boolean[];
  geoFenceSearchTerm: string;
  geoFencesTypesFilters: boolean[];
  geoFenceSubFilters: {
    [key: string]: boolean;
  };
  haulerLocationsFilters: boolean[];
  cityAlertsFilters: boolean[];
  cityAlertsSubFilters: {
    [key: string]: boolean;
  };
  isLastFiltersApplied?: boolean;
  pickupStatusFilters: boolean[];
  jobPriorityFilters: boolean[];
  routeId?: number;
  showAllCityInsights: boolean;
  showAllGeoFences: boolean;
  showAllHaulerLocations: boolean;
  showAllStops: boolean;
  showAllVehicleInsights: boolean;
  showOrderNumbers: boolean;
  showRouteGeoFence: boolean;
  showStopConfirmation: boolean;
  showStopsWithLocationAlerts: boolean;
  showTravelPath: boolean;
  showTemporaryStops: boolean;
  showVehicleTrackings: boolean;
  showXDeviceTrackings: boolean;
  showYDeviceTrackings: boolean;
  vehicleFilters: {
    [key: string]: boolean;
  };
  vehicleInsightsFilters: {
    [key: string]: boolean;
  };
  defaultRouteLocationId?: number;
};

type PropsWithoutReduxForm = {
  closeRouteMapFilters: () => void;
  handleApplyLastFilters: (currentRouteFilters: RouteMapFiltersFormValues) => void;
  isRouteMapFiltersOpen: boolean;
  isInSomeSortOfEditMode: boolean;
  onMount: () => void;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteMapFiltersFormValues, PropsWithoutReduxForm>;

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

// returns the keys of the elements that are true
const normalizeFilterObject = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: string[], cur) => (cur[1] ? [...acc, cur[0]] : acc), []);

// returns the keys of the elements that are true as numbers
const normalizeFilterObjectAsNumbersAndRemovePrefix = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: number[], cur) => (cur[1] ? [...acc, Number(cur[0].replace('_', ''))] : acc), []);

export const ROUTE_MAP_FILTERS_FORM_NAME = 'routeMapFiltersForm';

export default reduxForm<RouteMapFiltersFormValues, PropsWithoutReduxForm>({
  form: ROUTE_MAP_FILTERS_FORM_NAME,
})(function RouteMapFiltersForm({
  change,
  closeRouteMapFilters,
  handleApplyLastFilters,
  handleSubmit,
  isRouteMapFiltersOpen,
  isInSomeSortOfEditMode,
  onMount,
}: Props) {
  const formValues = useSelector(getFormValues(ROUTE_MAP_FILTERS_FORM_NAME)) as RouteMapFiltersFormValues;
  const [isGeoFenceFiltersOpen, setIsGeoFenceFiltersOpen] = useState(false);
  const [isCityAlertFiltersOpen, setIsCityAlertFiltersOpen] = useState(false);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { locationFlagTypes } = useSelector(state => state.common.locationFlagTypes);
  const { filters: vehicleFilters } = useSelector(state => state.routes.routeMapVehicleData);
  const { filters: vehicleInsightsFiltersData } = useSelector(state => state.routes.routeMapVehicleInsights);
  const { filters: cityInsightsFiltersData } = useSelector(state => state.routes.routeMapCityInsights);
  const { geoFence: routeGeoFence } = useSelector(state => state.routes.geoFence);
  const { geoFenceMapFilterOptions } = useSelector(state => state.routes.geoFencesFilterOptions.geoFencesFilterOptions);
  const isGeoFenceEnabled = useSelector(checkIfGeoFenceIsEnabled);
  const selectedPickupStatusFiltersNumber = useMemo(
    () => formValues?.pickupStatusFilters?.filter(Boolean).length,
    [formValues?.pickupStatusFilters],
  );

  const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
  const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
  const hasCityAlerts = getActiveCityAlertTypes(cityAlertSettings).hasCityAlerts;

  const pageType = ROUTE;
  const routeOrTemplateId = routeSummary?.routeId;
  const isApplyLastFiltersDisabled = isEmpty(getPersistentMapFilters(routeOrTemplateId, pageType));

  const showTravelPathAction = routeSummary && routeSummary.totalStopsCount >= 2 && !isInSomeSortOfEditMode;

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (!selectedPickupStatusFiltersNumber) change('showOrderNumbers', false);
  }, [change, selectedPickupStatusFiltersNumber]);

  const vehicleInsightsFilters = vehicleInsightsFiltersData
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map(vehicleInsightsFilter => ({
      id: vehicleInsightsFilter.name,
      label: `${translate(`dashboard.${humps.camelize(vehicleInsightsFilter.name)}`)} (${
        vehicleInsightsFilter.insightFormattedValue
      })`,
    }));

  const cityInsightsFilters = cityInsightsFiltersData.map(({ locationFlaggingTypeId, count }) => {
    const locationFlagType = locationFlagTypes.find(locationFlagType => locationFlagType.id === locationFlaggingTypeId);
    return {
      id: locationFlaggingTypeId,
      label: `${translate(
        `vendors.locationFlags.${humps.camelize(locationFlagType?.technicalName || '')}`,
      )} (${count})`,
    };
  });

  const cityAlertsFilters = useMemo(
    () =>
      [
        {
          id: ALERT_TYPES[ROUTE_CITY_ALERTS].id,
          label: ALERT_TYPES[ROUTE_CITY_ALERTS].name,
        },
        {
          id: ALERT_TYPES[ALL_CITY_ALERTS].id,
          label: ALERT_TYPES[ALL_CITY_ALERTS].name,
        },
      ] as any,
    [],
  );

  const normalizedCityAlertsFiltersIds = normalizeFilterArray(formValues.cityAlertsFilters || []);
  const cityAlertsSubFilters = normalizedCityAlertsFiltersIds.map(id => {
    return {
      label: cityAlertsFilters.find((cityAlert: CityAlert) => cityAlert.id === id).label,
      id: id,
      subFilters: map(activeCityAlertTypes, (cityAlert: CityAlert) => ({
        id: `${id}_${cityAlert.cityAlertType.id}`,
        label: cityAlert.cityAlertType.name,
      })) as any,
    };
  });

  const onCityAlertsFilterChange = (_: any, isChecked: boolean, id: string | number) => {
    isChecked &&
      cityAlertsFilters.forEach((cityAlertsFilter: any) => {
        cityAlertsFilter.id !== Number(id) && change(`cityAlertsFilters.[${cityAlertsFilter.id}]`, false);
      });
  };

  useEffect(() => {
    if (formValues.showVehicleTrackings === true && every(formValues.vehicleFilters, v => v === false))
      change('showVehicleTrackings', false);
  }, [change, formValues.showVehicleTrackings, formValues.vehicleFilters]);

  const geoFencesGroupedByType = useMemo(
    () =>
      groupBy(
        filter(geoFenceMapFilterOptions, (geoFence: GeoFenceMapFilterOption) => geoFence.id !== routeGeoFence?.id),
        (geoFence: GeoFenceMapFilterOption) => geoFence.zoneTypeId,
      ),
    [geoFenceMapFilterOptions, routeGeoFence?.id],
  );

  const geoFencesFilters = useMemo(() => {
    const filters = map(Object.keys(geoFencesGroupedByType), (geoFenceZoneTypeId: number) => {
      const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId];
      const geoFenceZoneTypeName = GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId];

      return {
        id: geoFenceZoneTypeId,
        label: geoFenceZoneTypeName.name,
        rightLabel: `${geoFences.length}`,
        subFilters: map(geoFences, (option: GeoFenceMapFilterOption) => ({
          id: option.id,
          label: option.name,
        })),
      };
    }) as any;

    if (routeGeoFence && routeGeoFence.id) {
      filters.unshift({
        id: routeGeoFence.id,
        label: translate('routes.geoFences.routeGeoFence'),
        subFilters: [],
      });
    }
    return filters;
  }, [geoFencesGroupedByType, routeGeoFence]);

  const subGeoFenceFilters = useMemo(() => {
    /// General GeoFence has to be first in the list
    const geoFenceTypeIds = Object.keys(geoFencesGroupedByType);
    if (geoFenceTypeIds.includes(GENERAL_GEO_FENCE_ID.toString())) {
      geoFenceTypeIds.splice(geoFenceTypeIds.indexOf(GENERAL_GEO_FENCE_ID.toString()), 1);
      geoFenceTypeIds.unshift(GENERAL_GEO_FENCE_ID.toString());
    }
    return map(
      filter(geoFenceTypeIds, (id: number) => formValues.geoFencesTypesFilters[id]) as any,
      geoFenceZoneTypeId => {
        const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId] as GeoFenceMapFilterOption[];
        const filteredGeoFences = filter(
          geoFences,
          gf => gf.name.toLowerCase().indexOf(formValues.geoFenceSearchTerm.toLowerCase()) > -1,
        );
        return {
          label: GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId].name,
          id: geoFenceZoneTypeId,
          rightLabel: `${geoFences.length}`,
          subFilters: map(filteredGeoFences, (option: GeoFenceMapFilterOption) => ({
            id: `_${option.id}`,
            label: option.name,
          })) as any,
        };
      },
    );
  }, [geoFencesGroupedByType, formValues.geoFencesTypesFilters, formValues.geoFenceSearchTerm]);

  // uncheck all subFilters if category is unchecked
  useEffect(() => {
    const normalizedGeoFencesIds = normalizeFilterObjectAsNumbersAndRemovePrefix(formValues.geoFenceSubFilters);
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formValues.geoFencesTypesFilters);
    const filteredGeoFencesIdsToDeselect = normalizedGeoFencesIds.filter((id: number) => {
      const geoFenceOption = find(geoFenceMapFilterOptions, { id });
      return (
        geoFenceOption && geoFenceOption.zoneTypeId && !normalizedGeoFencesTypesIds.includes(geoFenceOption.zoneTypeId)
      );
    });
    filteredGeoFencesIdsToDeselect.forEach(subFilter => {
      change(`geoFenceSubFilters._${subFilter}`, false);
    });
  }, [change, formValues.geoFenceSubFilters, formValues.geoFencesTypesFilters, geoFenceMapFilterOptions]);

  // uncheck all cityAlertsSubFilters if category is unchecked
  useEffect(() => {
    const normalizedCityAlertsIds = normalizeFilterObject(formValues.cityAlertsSubFilters || {});
    const normalizedCityAlertsTypesIds = normalizeFilterArray(formValues.cityAlertsFilters);
    const filteredCityAlertsIdsToDeselect = normalizedCityAlertsIds.filter(normalizedCityAlertsId => {
      const cityAlertsOption = cityAlertsFilters.find(
        (cityAlertsFilter: any) => cityAlertsFilter.id === Number(normalizedCityAlertsId.toString().split('_')[0]),
      );
      return cityAlertsOption && !normalizedCityAlertsTypesIds.includes(cityAlertsOption.id);
    });
    filteredCityAlertsIdsToDeselect.forEach(subFilter => {
      change(`${ALERT_TYPES[filteredCityAlertsIdsToDeselect[0].toString().split('_')[0]].name}_checkAll`, false);
      change(`cityAlertsSubFilters.[${subFilter}]`, false);
    });
  }, [change, formValues.cityAlertsSubFilters, formValues.cityAlertsFilters, cityAlertsFilters]);

  if (!routeSummary) return null;

  const isScheduledRoute = routeSummary.routeStatusTypeId === SCHEDULED_ROUTE;
  const pickupStatuses = isScheduledRoute
    ? [SCHEDULED]
    : [SCHEDULED, IN_PROGRESS, COMPLETED, CANCELED, REASSIGNED, ISSUE_REPORTED, SERVICED, PLACED_ON_HOLD];

  const handleSelectAllStopsFilters: TypedFieldOnChangeFunction = (_, isChecked) => {
    pickupStatuses.forEach(pickupStatusId => {
      change(`pickupStatusFilters[${pickupStatusId}]`, isChecked);
    });
  };

  const handleChangeStopFilter: TypedFieldOnChangeFunction = () => {
    change('showAllStops', false);
  };

  const handleChangeShowVehicleTracking: TypedFieldOnChangeFunction = (_, isChecked) => {
    change('showStopConfirmation', isChecked);
    change('showXDeviceTrackings', isChecked);
    change('showYDeviceTrackings', isChecked);
  };

  const applyLastFilters = () => {
    const currentRouteFilters = getPersistentMapFilters(routeSummary.routeId, pageType);

    Object.keys(currentRouteFilters).forEach(filter => {
      return change(filter, currentRouteFilters[filter]);
    });

    handleApplyLastFilters(currentRouteFilters);
  };

  return isRouteMapFiltersOpen ? (
    <form onSubmit={handleSubmit}>
      <RouteMapFiltersWrapper>
        <RouteMapFiltersTitle>{translate('tooltips.mapFilters')}</RouteMapFiltersTitle>
        <RouteMapFiltersClose onClick={closeRouteMapFilters}>
          <RouteMapFiltersCloseIcon />
        </RouteMapFiltersClose>
        <RouteMapFiltersSectionWrapper>
          <RouteMapFiltersSectionTitle>{translate('routes.stops')}</RouteMapFiltersSectionTitle>
          <RouteMapFiltersSectionWording>{translate('routes.filters.selectStops')}</RouteMapFiltersSectionWording>

          <TypedField
            name="showOrderNumbers"
            component={Switch}
            props={{
              label: translate('routes.filters.showOrderNumbers'),
              margin: 'xSmall no',
              size: 'medium',
              disabled: !selectedPickupStatusFiltersNumber,
            }}
          />

          <TypedField
            name="showAllStops"
            component={Checkbox}
            onChange={handleSelectAllStopsFilters}
            props={{
              label: translate('routes.allStops'),
              block: true,
              margin: 'xSmall no',
              size: 'small',
            }}
          />

          {pickupStatuses.map(pickupStatusId => (
            <TypedField
              key={pickupStatusId}
              name={`pickupStatusFilters[${pickupStatusId}]`}
              component={Checkbox}
              onChange={handleChangeStopFilter}
              props={{
                label: PICKUP_STATUSES[pickupStatusId].name,
                block: true,
                margin: 'xSmall no',
                size: 'small',
              }}
            />
          ))}

          <Separator />
          <TypedField
            name="showStopsWithLocationAlerts"
            component={Checkbox}
            props={{
              label: translate('customers.locationAlerts'),
              block: true,
              margin: 'xSmall no',
              size: 'small',
            }}
          />
          <TypedField
            name="showTemporaryStops"
            component={Checkbox}
            props={{
              label: translate('routes.temporaryStops'),
              block: true,
              margin: 'xSmall no',
              size: 'small',
            }}
          />
          <TypedField
            name={`jobPriorityFilters[${HIGH_JOB_PRIORITY_ID}]`}
            component={Checkbox}
            props={{
              label: translate('common.highPriority'),
              block: true,
              margin: 'xSmall no',
              size: 'small',
            }}
          />
        </RouteMapFiltersSectionWrapper>
        {!isScheduledRoute && (
          <>
            <RouteMapFiltersSectionWrapper>
              <RouteMapFiltersSectionTitle>{translate('vehicles.vehicles')}</RouteMapFiltersSectionTitle>
              <TypedField
                name="showVehicleTrackings"
                component={Switch}
                onChange={handleChangeShowVehicleTracking}
                props={{
                  label: translate('routes.filters.showVehicleTrackings'),
                  margin: 'xSmall no',
                  size: 'medium',
                  disabled: every(formValues.vehicleFilters, v => v === false),
                }}
              />
              {vehicleFilters
                .sort((a, b) => Number(a.isAssisting) - Number(b.isAssisting))
                .map(vehicleFilter => (
                  <TypedField
                    key={vehicleFilter.vehicleId}
                    name={`vehicleFilters._${vehicleFilter.vehicleId}`}
                    component={Checkbox}
                    props={{
                      label: `${vehicleFilter.vehicleName}${
                        vehicleFilter.isAssisting ? ` (${translate('vehicles.assisted')})` : ''
                      }`,
                      block: true,
                      margin: 'xSmall no',
                      size: 'small',
                    }}
                  />
                ))}
            </RouteMapFiltersSectionWrapper>

            {!!vehicleInsightsFilters.length && (
              <RouteMapFiltersSection
                name="vehicleInsightsFilters"
                title={translate('dashboard.vehicleInsights')}
                change={change}
                checkAllFieldName="showAllVehicleInsights"
                filters={vehicleInsightsFilters}
              />
            )}

            {!!cityInsightsFilters.length && (
              <RouteMapFiltersSection
                name="cityInsightsFilters"
                title={translate('dashboard.cityInsights')}
                change={change}
                checkAllFieldName="showAllCityInsights"
                filters={cityInsightsFilters}
              />
            )}
          </>
        )}

        <RouteMapFiltersSection
          name="haulerLocationsFilters"
          title={translate('dashboard.vendorLocations')}
          change={change}
          checkAllFieldName="showAllHaulerLocations"
          filters={HAULER_LOCATION_FILTERS}
        />

        {showTravelPathAction && (
          <>
            <Separator />
            <TypedField
              name="showTravelPath"
              component={TravelPathField}
              props={{
                displayTravelPath: routeSummary.totalStopsCount >= 2,
                isActionButtonAvailable: routeSummary.routeStatusTypeId !== COMPLETED,
                routeId: routeSummary.routeId,
                routeName: routeSummary.name || '',
                date: routeSummary.date,
              }}
            />
          </>
        )}

        {hasCityAlerts && (
          <RouteMapFiltersSectionWrapper>
            <ExpandableMapFiltersSection
              title={translate('vendors.cityAlerts.cityAlerts')}
              filters={cityAlertsFilters}
              name="cityAlertsFilters"
              change={change}
              isOpen={isCityAlertFiltersOpen}
              setIsOpen={setIsCityAlertFiltersOpen}
              onFilterChange={onCityAlertsFilterChange}
            />
          </RouteMapFiltersSectionWrapper>
        )}

        {/* General & Route & StreetSweeper GeoFences grouped */}
        {isGeoFenceEnabled && !!geoFenceMapFilterOptions?.length && (
          <RouteMapFiltersSectionWrapper>
            <ExpandableMapFiltersSection
              title={translate('routes.geoFences.geoFencesTitle')}
              filters={geoFencesFilters}
              name="geoFencesTypesFilters"
              change={change}
              isOpen={isGeoFenceFiltersOpen}
              setIsOpen={setIsGeoFenceFiltersOpen}
            />
          </RouteMapFiltersSectionWrapper>
        )}

        <PopoverWrapper
          triggerButton={
            <Text
              color="primary"
              cursor="pointer"
              onClick={() => (isApplyLastFiltersDisabled ? undefined : applyLastFilters())}
              block
              align="center"
              disabled={isApplyLastFiltersDisabled}
            >
              {translate('dashboard.applyLastFilters')}
            </Text>
          }
          popoverContent={
            isApplyLastFiltersDisabled ? (
              <Popover>{translate('dashboard.alertMessages.lastFiltersNotAvailable')}</Popover>
            ) : undefined
          }
          size="medium"
          margin="medium no small no"
          width="100%"
        />

        <Button type="submit" color="primary" fullWidth margin="no">
          {translate('common.apply')}
        </Button>
      </RouteMapFiltersWrapper>

      <ExpandableMapFiltersSubsection
        isOpen={isGeoFenceFiltersOpen}
        setIsOpen={setIsGeoFenceFiltersOpen}
        change={change}
        name="geoFenceSubFilters"
        filters={subGeoFenceFilters}
        leftPosition={normalizedCityAlertsFiltersIds.length && isCityAlertFiltersOpen ? '500px' : '250px'}
      />

      <ExpandableMapCityAlertsSubsection
        isOpen={isCityAlertFiltersOpen}
        setIsOpen={setIsCityAlertFiltersOpen}
        change={change}
        name="cityAlertsSubFilters"
        filters={cityAlertsSubFilters}
        leftPosition="250px"
      />
    </form>
  ) : null;
});
