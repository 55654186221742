import React, { PureComponent, Fragment } from 'react';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { checkSelfUser, createSelfUser } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { AccountPage, AccountPanel, AccountPanelLink, CreateUserPanel, CreateUserLink } from '../styled';
import { CreateUserAccountForm } from '../forms';
import translate from '../../../core/services/translate';
import { getQueryParams } from '../../../utils/services/queryParams';
import { createSuccessNotification } from '../../../core/services/createNotification';
import { getCurrentLanguageId, getInitialLocale } from '../../../core/services/i18n';
import { AppState } from '../../../store';
import { DuckFunction } from '../../../contracts/ducks';

interface ComponentProps extends RouteComponentProps {}

interface Props extends ComponentProps {
  isCreatingUser: boolean;
  isCheckingUserFailed: boolean;
  isFailedCreatingUser: boolean;
  vendorId?: number;
  checkSelfUser: DuckFunction<typeof checkSelfUser>;
  createSelfUser: DuckFunction<typeof createSelfUser>;
}

interface OnSubmitProps {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

class CreateUserAccountPage extends PureComponent<Props> {
  componentDidMount() {
    const { checkSelfUser } = this.props;
    const { search } = this.props.location;
    const params = getQueryParams(search);
    const token = params.autoDispatchToken ? params.autoDispatchToken : params.opportunitiesToken;
    checkSelfUser(token);
  }

  onSubmit = async ({ email, name, password, confirmPassword }: OnSubmitProps) => {
    const { createSelfUser, vendorId, location } = this.props;
    const { search } = this.props.location;
    const params = getQueryParams(search);
    const redirectedFrom = get(location, 'state.from.pathname');
    const token = params.autoDispatchToken ? params.autoDispatchToken : params.opportunitiesToken;
    let redirectTo = '/';

    if (params.autoDispatchToken) {
      redirectTo = '/workorders';
    } else if (params.opportunitiesToken) {
      redirectTo = '/opportunities/hauler-opportunities/';
    } else {
      redirectTo = redirectedFrom !== '/account/logout' ? redirectedFrom : '/dashboard';
    }

    if (!vendorId) {
      return;
    }

    await createSelfUser(email, name, password, confirmPassword, vendorId, token, redirectTo);
    createSuccessNotification('User Account Created Successfully.');
  };

  createUrl = () => {
    const { location } = this.props;
    const params = getQueryParams(location.search);

    if (params.autoDispatchToken) {
      return `/workorders/${params.autoDispatchToken}`;
    } else if (params.opportunitiesToken) {
      return `/opportunities/hauler-opportunities/${params.opportunitiesToken}`;
    }

    return '/';
  };
  render() {
    const { isCreatingUser, isCheckingUserFailed, isFailedCreatingUser } = this.props;
    const languageId = getCurrentLanguageId();

    if (languageId && languageId !== getInitialLocale()) {
      window.location.reload();
      return null;
    }

    return (
      <Fragment>
        <DocumentTitle>{translate('account.createUserAccount')}</DocumentTitle>
        <AccountPage>
          <AccountPanel size="small" isLoading={isCreatingUser}>
            <CreateUserAccountForm
              isCheckingUserFailed={isCheckingUserFailed}
              isFailedCreatingUser={isFailedCreatingUser}
              onSubmit={this.onSubmit}
            />
            {isCheckingUserFailed && (
              <CreateUserPanel>
                <CreateUserLink>
                  <AccountPanelLink isCreateUser to={this.createUrl()}>
                    {translate('account.goBack')}
                  </AccountPanelLink>
                </CreateUserLink>
              </CreateUserPanel>
            )}
          </AccountPanel>
        </AccountPage>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isCreatingUser: state.account.createUserAccount.isCreatingUser,
  isCheckingUserFailed: state.account.createUserAccount.isCheckingUserFailed,
  isFailedCreatingUser: state.account.createUserAccount.isFailedCreatingUser,
  vendorId: state.account.createUserAccount.vendorId,
  location: state.router.location,
});

const mapDispatchToProps = {
  checkSelfUser,
  createSelfUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateUserAccountPage));
