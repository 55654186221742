import { filter, size } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Grid, GridColumn, PanelSection, Text } from 'src/core/components/styled';
import { ContainersRelocationFormValues } from './ContainersRelocationForm';
import { Dropdown, Table, TypedField } from 'src/core/components';
import { Facility } from 'src/common/interfaces/Facility';
import { getFormValues, InjectedFormProps, WrappedFieldArrayProps } from 'redux-form';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import ContainerRelocationTableRow from './ContainerRelocationTableRow';
import translate from 'src/core/services/translate';

interface ComponentProps extends WrappedFieldArrayProps {
  change: InjectedFormProps['change'];
  currentFacilityId: number;
}

const ContainerRelocationFieldArray = ({ fields, currentFacilityId, meta: { form }, change }: ComponentProps) => {
  const dispatch = useDispatch();

  const { facilities } = useSelector(state => state.fleet.facilities);
  const formValues = useSelector(getFormValues(form)) as ContainersRelocationFormValues;

  const currentFacilityTypeId = facilities.find((f: Facility) => f.id === currentFacilityId)?.facilityType.id;

  const facilityOptions = useMemo(
    () =>
      filter(
        facilities,
        f => f.id !== currentFacilityId && f.isActive && f.facilityType.id === currentFacilityTypeId,
      ).map(f => ({
        value: f.locationId,
        label: f.name,
      })),
    [facilities, currentFacilityId, currentFacilityTypeId],
  );

  const handleApplyToAll = useCallback(() => {
    fields.forEach((field: string, index: number) => {
      dispatch(change(`relocatedContainers[${index}].locationId`, formValues.applyToAll));
    });
  }, [change, dispatch, fields, formValues.applyToAll]);

  const containersRelocationTableCells = useMemo(
    () => [
      {
        name: 'boxNumber',
        label: translate('containers.containerNumber'),
        padding: 'xSmall no xSmall small',
        width: '40%',
        sortable: true,
      },
      {
        name: 'locationId',
        label: translate('containers.containerNewLocation'),
        width: '60%',
        sortable: true,
      },
    ],
    [],
  );

  const rows = useMemo(() => {
    return fields.map((fieldArrayKey: string, index: number) => {
      return {
        fieldArrayKey,
        index,
        fields,
      };
    });
  }, [fields]);

  const virtualizedProps = {
    itemSize: TABLE_ROW_HEIGHT_SMALL,
    height: Math.min(rows.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 7) || 1,
  };

  return (
    <>
      <Grid margin="small no">
        <GridColumn size="5/12" verticalAlign="center">
          <Text size="large">{translate('containers.reassignContainers')}:</Text>
        </GridColumn>
        {size(fields) > 1 && (
          <GridColumn size="7/12" verticalAlign="center">
            <TypedField
              component={Dropdown}
              name="applyToAll"
              props={{
                inline: true,
                margin: 'no',
                menuPosition: 'fixed',
                options: facilityOptions,
                placeholder: translate('containers.containerNewLocation'),
                width: '200px',
              }}
            />
            <Button
              color="primary"
              margin="no small"
              size="small"
              type="button"
              disabled={!formValues?.applyToAll}
              onClick={handleApplyToAll}
            >
              {translate('facilities.applyToAll')}
            </Button>
          </GridColumn>
        )}
      </Grid>

      {!!size(rows) && (
        <PanelSection>
          <Table
            cells={containersRelocationTableCells}
            rows={rows}
            rowComponent={ContainerRelocationTableRow}
            rowProps={{ facilityOptions }}
            virtualized
            virtualizedProps={virtualizedProps}
            scrollMarker
            noBottomBorder
          />
        </PanelSection>
      )}
    </>
  );
};

export default ContainerRelocationFieldArray;
