import {
  loadReleaseNotes as doLoadReleaseNotes,
  loadPublicReleaseNotes as doLoadPublicReleaseNotes,
  saveReleaseNote as doSaveReleaseNote,
  deleteReleaseNote as doDeleteReleaseNote,
  deleteReleaseNoteItem as doDeleteReleaseNoteItem,
  editReleaseNote as doEditReleaseNote,
  getLastSeenReleaseNote,
  setLastSeenReleaseNote,
  getLatestReleaseNote,
} from '../services/releaseNotes';
import { Dispatch, AnyAction } from 'redux';
import update from 'immutability-helper';
import { ReleaseNote } from '../interfaces/ReleaseNote';
import moment from 'moment';
// Constants
const START_LOAD = 'common/releaseNotes/START_LOAD';
const COMPLETE_LOAD = 'common/releaseNotes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/releaseNotes/FAIL_LOAD';
const START_SAVE = 'common/releaseNotes/START_SAVE';
const COMPLETE_SAVE = 'common/releaseNotes/COMPLETE_SAVE';
const FAIL_SAVE = 'common/releaseNotes/FAIL_SAVE';
const START_DELETE = 'common/releaseNotes/START_DELETE';
const COMPLETE_DELETE = 'common/releaseNotes/COMPLETE_DELETE';
const FAIL_DELETE = 'common/releaseNotes/FAIL_DELETE';
const HIDE_NOTIFICATION = 'common/releaseNotes/HIDE_NOTIFICATION';
const SHOW_NOTIFICATION = 'common/releaseNotes/SHOW_NOTIFICATION';

interface ReleaseNotesState {
  isLoading: boolean;
  isSaving: boolean;
  releaseNotes: ReleaseNote[] | [];
  showNotification: boolean;
}

// Initial State
const initialState: ReleaseNotesState = {
  isLoading: false,
  isSaving: false,
  releaseNotes: [],
  showNotification: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          releaseNotes: action.releaseNotes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case SHOW_NOTIFICATION:
      return update(state, {
        $merge: {
          showNotification: action.showNotification,
        },
      });

    case HIDE_NOTIFICATION:
      return update(state, {
        $merge: {
          showNotification: false,
        },
      });
    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (releaseNotes: ReleaseNote[]) => ({
  type: COMPLETE_LOAD,
  releaseNotes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});
const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});
const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});

const showNotification = (showNotification: boolean) => ({
  type: SHOW_NOTIFICATION,
  showNotification,
});

export const loadPublicReleaseNotes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadReleaseNotesPromise = doLoadPublicReleaseNotes();
  loadReleaseNotesPromise
    .then((releaseNotes: ReleaseNote[]) => {
      dispatch(completeLoad(releaseNotes));
    })
    .catch(() => dispatch(failLoad()));
  return loadReleaseNotesPromise;
};

export const loadReleaseNotes = (fromDate: string, toDate: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadReleaseNotesPromise = doLoadReleaseNotes(fromDate, toDate);
  loadReleaseNotesPromise
    .then((releaseNotes: ReleaseNote[]) => {
      dispatch(completeLoad(releaseNotes));
    })
    .catch(() => dispatch(failLoad()));
  return loadReleaseNotesPromise;
};

export const showNotificationIfNewReleaseNotes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const latestReleaseNotePromise = getLatestReleaseNote();
  latestReleaseNotePromise
    .then((latestReleaseNote?: ReleaseNote) => {
      let notifyUser = false;
      if (latestReleaseNote) {
        const lastSeenReleaseNote = getLastSeenReleaseNote();
        if (
          !lastSeenReleaseNote ||
          (moment(latestReleaseNote.publishDate) > moment(lastSeenReleaseNote) && latestReleaseNote.notifyUsers)
        ) {
          notifyUser = true;
        }
      }
      dispatch(showNotification(notifyUser));
    })
    .catch(() => dispatch(failLoad()));
  return latestReleaseNotePromise;
};

export const saveReleaseNote = (releaseNote: ReleaseNote) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveReleaseNotePromise = doSaveReleaseNote(releaseNote);
  saveReleaseNotePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveReleaseNotePromise;
};

export const editReleaseNote = (releaseNote: ReleaseNote) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const editReleaseNotePromise = doEditReleaseNote(releaseNote);
  editReleaseNotePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return editReleaseNotePromise;
};

export const hideReleaseNoteNotification = (lastSeenReleaseNotePublishDate: string) => (dispatch: Dispatch) => {
  setLastSeenReleaseNote(lastSeenReleaseNotePublishDate);
  dispatch(hideNotification());
};

export const deleteReleaseNote = (id: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteReleaseNotesPromise = doDeleteReleaseNote(id);
  deleteReleaseNotesPromise
    .then(() => {
      dispatch(completeDelete());
    })
    .catch(() => {
      dispatch(failDelete());
    });
  return deleteReleaseNotesPromise;
};

export const deleteReleaseNoteItem = (id: number, featureId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteReleaseNotesPromise = doDeleteReleaseNoteItem(id, featureId);
  deleteReleaseNotesPromise
    .then(() => {
      dispatch(completeDelete());
      dispatch(startLoad());
    })
    .catch(() => {
      dispatch(failDelete());
      dispatch(startLoad());
    });
  return deleteReleaseNotesPromise;
};
