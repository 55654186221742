import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { loadInvoices, exportInvoices, uploadInvoices } from '../../ducks';

import { Banner } from '../../../common/components';
import { InvoiceForm, InvoiceFormTableRow } from '../forms';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
  PageActions,
  PageSubtitle,
} from '../../../common/components/styled';
import { Panel, PanelSectionGroup, PanelSection, Button, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { DuckFunction } from '../../../contracts/ducks';
import { Table } from '../../../core/components';
import { InvoiceSearchParams } from '../../interfaces/InvoiceSearchParams';
import { getTextWithLinks } from '../../../landing/components/services/Utils';
import { createUrl } from '../../../utils/services/queryParams';
import { RouteComponentProps, withRouter } from 'react-router';
import { RubiconSelectBannerContainer } from '../../components/styled/rubiconSelect';
import { SortOrder } from '../../../core/components/Table';
import { NoResultsContainer, AdditionalInfo } from '../styled/Invoice';
import UploadWorkOrdersModal from '../modal/UploadWorkOrdersModal';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';

interface Props extends RouteComponentProps {
  invoices?: any;
  loadInvoices: DuckFunction<typeof loadInvoices>;
  exportInvoices: DuckFunction<typeof exportInvoices>;
  uploadInvoices: DuckFunction<typeof uploadInvoices>;
  push: any;
  currentVendorId: number;
  searchFormInitialValues: any;
}

const invoiceTableCells = [
  { name: 'invoiceNumber', label: translate('invoice.invoiceNumber'), width: '20%', sortable: true },
  { name: 'accountNumber', label: translate('common.account'), width: '20%', sortable: true },
  { name: 'status', label: translate('invoice.status'), width: '10%', sortable: true },
  { name: 'invoiceDate', label: translate('invoice.invoiceDate'), width: '10%', sortable: true },
  { name: 'receivedDate', label: translate('invoice.receivedDate'), width: '10%', sortable: true },
  { name: 'invoiceAmount', label: translate('invoice.invoiceAmount'), width: '10%', sortable: true },
  { name: 'paymentAmount', label: translate('invoice.paymentAmount'), width: '10%', sortable: true },
  { name: 'paymentInfo', label: translate('invoice.paymentInfo'), width: '10%', sortable: true },
];

interface State {
  sortedBy?: string;
  sortOrder: SortOrder;
  invoiceSearchParams?: InvoiceSearchParams;
  isUploadInvoicesModalOpen: boolean;
}

class InvoicePage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sortedBy: undefined,
      sortOrder: 'asc',
      invoiceSearchParams: undefined,
      isUploadInvoicesModalOpen: false,
    };
  }

  onInvoiceUpload = async (object: any) => {
    const { uploadInvoices, currentVendorId } = this.props;
    try {
      await uploadInvoices(object, currentVendorId);
      createSuccessNotification(translate('opportunity.uploadSuccess'));
    } catch (err) {
      createErrorNotification(translate('opportunity.uploadError'));
    }
  };

  onSubmit = async (formData: any) => {
    const {
      loadInvoices,
      push,
      location: { pathname, search },
    } = this.props;

    const { dateRange, vendorGroup, accountNumber, invoiceNumber } = formData;

    const invoiceSearchParams: InvoiceSearchParams = {
      vendorGroup,
      invoiceNumber,
      accountNumber,
      invoiceStartDate: dateRange.from,
      invoiceEndDate: dateRange.to,
    };

    push(
      createUrl(pathname, search, {
        from: dateRange.from,
        to: dateRange.to,
        invoiceNumber: invoiceNumber,
        accountNumber: accountNumber,
      }),
    );

    loadInvoices(invoiceSearchParams);
  };

  onSortOrderChange = (sortedBy: string, sortOrder: SortOrder) => {
    this.setState({ sortedBy, sortOrder });
  };

  onExport = () => {
    const { invoiceSearchParams } = this.state;
    const { exportInvoices, searchFormInitialValues } = this.props;

    if (invoiceSearchParams !== undefined) {
      exportInvoices(invoiceSearchParams);
    } else {
      exportInvoices({
        ...searchFormInitialValues,
        invoiceStartDate: searchFormInitialValues.dateRange.from,
        invoiceEndDate: searchFormInitialValues.dateRange.to,
      });
    }
  };

  openInvoiceModal = () => {
    this.setState({ isUploadInvoicesModalOpen: true });
  };

  closeInvoiceModal = () => {
    this.setState({ isUploadInvoicesModalOpen: false });
  };

  render() {
    const { invoices } = this.props;
    const { isUploadInvoicesModalOpen } = this.state;
    return (
      <>
        <RubiconSelectBannerContainer>
          <Banner />
        </RubiconSelectBannerContainer>
        <PageContent>
          <PageHeader withSubTitle>
            <PageDetails>
              <PageTitleContainer>
                <PageTitle>{translate('invoice.invoices')}</PageTitle>
              </PageTitleContainer>
            </PageDetails>
            <PageActions align="right">
              <Button onClick={this.openInvoiceModal} margin="small" color="primary">
                Upload
              </Button>
              <Button onClick={this.onExport} margin="small" color="primary">
                {translate('common.export')}
              </Button>
            </PageActions>
          </PageHeader>
          <Panel>
            <PanelSectionGroup>
              <PanelSection>
                <InvoiceForm initialValues={this.props.searchFormInitialValues} onSubmit={this.onSubmit} />
              </PanelSection>
              <PanelSection vertical>
                <Table
                  cells={invoiceTableCells}
                  rows={invoices}
                  rowComponent={InvoiceFormTableRow}
                  sortedBy={this.state.sortedBy}
                  sortOrder={this.state.sortOrder}
                />
                {!invoices.length && (
                  <NoResultsContainer>
                    <PageSubtitle>{translate('invoice.noInvoicesFound')}</PageSubtitle>
                    <div>{translate('invoice.noInvoicesFoundAdditional')}</div>
                  </NoResultsContainer>
                )}
              </PanelSection>
            </PanelSectionGroup>
          </Panel>
          <PanelSectionGroup>
            <AdditionalInfo>
              <Text block weight="normal" size="medium" margin="xSmall xSmall xxSmall">
                {getTextWithLinks(translate('invoice.messages.inquiryMessage'))}
              </Text>
            </AdditionalInfo>
            <AdditionalInfo>
              <Text block weight="normal" size="medium" margin="xSmall xSmall xxSmall">
                {getTextWithLinks(translate('invoice.messages.invoiceMessage'))}
              </Text>
            </AdditionalInfo>
          </PanelSectionGroup>
          {isUploadInvoicesModalOpen && (
            <UploadWorkOrdersModal
              uploadFile={this.onInvoiceUpload}
              vendorId={56}
              closeModal={this.closeInvoiceModal}
            />
          )}
        </PageContent>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  invoices: state.opportunity.invoice.invoices,
  currentVendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadInvoices,
  exportInvoices,
  push,
  uploadInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvoicePage));
