import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ACTIVE = 1;
export const STATIONARY = 2;
export const INACTIVE = 3;
export const OFFLINE = 4;

export const VEHICLE_STATUSES = mapKeys(
  [
    { id: ACTIVE, name: translate('vehicles.vehicleStatuses.active') },
    { id: STATIONARY, name: translate('vehicles.vehicleStatuses.stationary') },
    { id: INACTIVE, name: translate('vehicles.vehicleStatuses.inactive') },
    { id: OFFLINE, name: translate('vehicles.vehicleStatuses.offline') },
  ],
  'id',
);
