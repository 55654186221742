import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const YES = 'Yes';
export const NO = 'No';

export const STATUSES = [
  { id: YES, name: translate('common.yes') },
  { id: NO, name: translate('common.no') },
];

export const STATUSES_BY_ID = mapKeys(STATUSES, 'id');

export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';

export const EXPLICIT_STATUSES = [
  { id: ACTIVE, name: translate('common.active') },
  { id: INACTIVE, name: translate('common.inactive') },
];

export const AVAILABLE = 'Available';
export const UNAVAILABLE = 'Unavailable';

export const SERVICE_STATUSES = [
  { id: AVAILABLE, name: translate('common.available'), label: translate('common.available'), value: AVAILABLE },
  {
    id: UNAVAILABLE,
    name: translate('common.unavailable'),
    label: translate('common.unavailable'),
    value: UNAVAILABLE,
  },
];
