import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GeoJSONSource } from 'mapbox-gl';

import { getFeatureCollection } from 'src/common/components/map/util';
import { getTriggerGeoJSON } from './utils';
import { setTriggerMapGeoJson } from 'src/routes/ducks/routeMapSettings';
import { setTriggerMapViewport } from 'src/routes/ducks';
import { Trigger } from 'src/routes/interfaces/Trigger';
import { TRIGGER_MAP_CLUSTER_LAYER, TRIGGER_MAP_CLUSTER_SOURCE } from 'src/routes/constants/triggerMap';
import { useSelector } from 'src/core/hooks/useSelector';
import TriggerMapClustersGLSource from './TriggerMapClustersGLSource';

type Props = {
  map: mapboxgl.Map;
  triggers: Trigger[];
};

export default function TriggerMapClustersGL({ map, triggers }: Props) {
  const dispatch = useDispatch();
  const geoJson = useSelector(s => s.routes.routeMapSettings.triggerMapGeoJson);

  useEffect(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (triggers.length) {
      const triggersCollection = getTriggerGeoJSON(triggers);
      collection.features = collection.features.concat(triggersCollection.features);
    }

    dispatch(setTriggerMapGeoJson(collection));
  }, [dispatch, triggers]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', TRIGGER_MAP_CLUSTER_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [TRIGGER_MAP_CLUSTER_LAYER],
          })
          .filter(feature => feature.source === TRIGGER_MAP_CLUSTER_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(TRIGGER_MAP_CLUSTER_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setTriggerMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <TriggerMapClustersGLSource geoJSON={geoJson} />;
}
