import { http } from 'src/core/services/http';
import { AvailableRoutesAndRouteTemplates } from 'src/routes/interfaces/DispatchBoardRoute';
import { Route } from 'src/routes/interfaces/Route';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';

export interface LoadRoutesParams {
  vendorId: number;
  vehicleTypeId: number;
  date?: Date | string;
  routeStatuses?: number[];
  searchTerm?: string;
  serviceZones?: number[];
  supervisors?: number[];
  groupIds?: number[];
}

export const loadRoute = (routeId: number) =>
  http.get(`/dispatchBoard/routes/${routeId}`).then(response => response.data);

export const loadRoutes = ({
  vendorId,
  vehicleTypeId,
  date,
  routeStatuses,
  searchTerm,
  serviceZones,
  supervisors,
  groupIds,
}: LoadRoutesParams) => {
  const params = {
    vendorId,
    vehicleTypeId,
    date,
    searchTerm,
    routeStatuses: routeStatuses && routeStatuses.toString(),
    serviceZoneIds: serviceZones && serviceZones.toString(),
    supervisorIds: supervisors && supervisors.toString(),
    groupIds: groupIds && groupIds.toString(),
  };

  return http.get('/dispatchBoard/routes', { params }).then(response => response.data);
};

export const loadRoutesAndTemplates = (
  vendorId: number,
  vehicleTypeId: number,
  date?: Date | string,
  filtersPreferencesIds?: FiltersPreferencesIds,
) => {
  const params = {
    vendorId,
    vehicleTypeId,
    date,
    serviceZoneIds: filtersPreferencesIds?.serviceZoneIdsCSV,
    supervisorIds: filtersPreferencesIds?.supervisorIdsCSV,
  };

  return http.get('/dispatchBoard/availableRoutesAndRouteTemplates', { params }).then(response => {
    const routes = response.data.routes.map((r: AvailableRoutesAndRouteTemplates) => ({ ...r, isTemplate: false }));
    const routeTemplates = response.data.routeTemplates.map((r: AvailableRoutesAndRouteTemplates) => ({
      ...r,
      isTemplate: true,
    }));
    return [...routes, ...routeTemplates];
  });
};

export const createRoute = (routeData: Route) =>
  http.post('/dispatchBoard/routes', routeData).then(response => response.data);

export const updateRoute = (routeId: number, routeData: Route) =>
  http.put(`/dispatchBoard/routes/${routeId}`, routeData).then(response => response.data);

export const deleteRoutes = (routeIds: number[]) =>
  http.post('/dispatchBoard/routes/delete', routeIds).then(response => response.data);
