import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import { AdminGuard } from '../../../account/components';
import {
  Grid,
  GridColumn,
  PanelSection,
  Button,
  ButtonSet,
  ModalClose,
  ModalCloseIcon,
} from '../../../core/components/styled';
import { Input, Dropdown } from '../../../core/components';
import { isAdminSelector } from '../../../account/ducks';
import { isRequired, minLength8, passwordComplexity } from '../../../utils/services/validator';
import { Users } from './../../interfaces/UserEditor';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

const differentPassword = (value: string, allValues: any) =>
  value !== allValues.oldPassword ? undefined : translate('account.mustBeDifferentPassword');

const matchPassword = (value: string, allValues: any) =>
  value === allValues.password ? undefined : translate('account.mustMatchPassword');

interface ComponentProps {
  isAdmin: boolean;
  onCancel(pristine: boolean): void;
  onSubmit(formData: any): void;
  users?: Users[];
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class ChangePasswordForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, isAdmin, users, onCancel, pristine } = this.props;

    const userOptions = map(users, user => ({
      label: user.name,
      value: user.userId,
    }));

    const newPasswordValidate = [isRequired, minLength8, passwordComplexity];

    if (!isAdmin) {
      newPasswordValidate.push(differentPassword as any);
    }

    return (
      <Fragment>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit} noValidate>
          <PanelSection padding="small xSmall no">
            <Grid multiLine>
              <AdminGuard>
                <GridColumn size="12/12">
                  <Field
                    name="userId"
                    component={Dropdown}
                    options={userOptions}
                    placeholder={translate('vendors.users')}
                    autoComplete="off"
                    validate={[isRequired]}
                  />
                </GridColumn>
              </AdminGuard>
              {!isAdmin && (
                <GridColumn size="12/12">
                  <Field
                    name="oldPassword"
                    component={Input}
                    label={translate('common.oldPassword')}
                    type="password"
                    validate={[isRequired]}
                  />
                </GridColumn>
              )}
              <GridColumn size="12/12">
                <Field
                  name="password"
                  component={Input}
                  label={translate('common.password')}
                  type="password"
                  validate={newPasswordValidate}
                />
              </GridColumn>
              <GridColumn size="12/12">
                <Field
                  name="confirmPassword"
                  component={Input}
                  label={translate('account.passwordConfirmation')}
                  type="password"
                  validate={[isRequired, matchPassword]}
                />
              </GridColumn>
              <ButtonSet margin="large auto no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </Grid>
          </PanelSection>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isAdmin: isAdminSelector(state.account.login),
  users: state.vendors.users.users,
});

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'changePassowrd',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(ChangePasswordForm),
);
