import React from 'react';
import UnconnectedCheckbox from '../../../core/components/UnconnectedCheckbox';
import translate from '../../../core/services/translate';
import { EquipmentSize } from '../../interfaces/Equipments';

interface Props {
  equipmentSize: EquipmentSize;
  onChange: (value: boolean) => void;
}

export const StandardEquipmentSizeField: React.FC<Props> = ({ equipmentSize, onChange }) => (
  <UnconnectedCheckbox
    onChange={event => onChange(event.target.checked)}
    checked={equipmentSize.isActive}
    label={translate(`haulerProfile.equipments.equipmentTypes.${equipmentSize.binClass}`)}
  />
);
