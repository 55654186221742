import * as turf from '@turf/turf';
import { forEach } from 'lodash-es';
import { useEffect, useState } from 'react';

import { getMapBounds } from 'src/common/components/map/util';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { useSelector } from 'src/core/hooks/useSelector';

interface Props {
  minAndMaxCoordinatesGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, any> | null;
}
export const useTravelPathBuilderMapBounds = ({ minAndMaxCoordinatesGeoJSON }: Props) => {
  const [viewport, setViewport] = useState<MapGLViewport>();

  const { routeStops, haulerLocations, geoFences, routeVehiclePositions, routeVehicleBreadCrumbs } = useSelector(
    state => state.routes.travelPathBuildAndEdit,
  );

  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    forEach(routeStops, ({ displayLatitude, displayLongitude }) => {
      points.push({ latitude: displayLatitude, longitude: displayLongitude });
    });

    forEach(haulerLocations, ({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    forEach(geoFences, ({ geoFenceJson }) => {
      const geoFence = JSON.parse(geoFenceJson);
      const feature = turf.feature(geoFence);
      const coordinates = turf.getCoords(feature);
      forEach(coordinates, coordinate => {
        forEach(coordinate, c => {
          points.push({ latitude: c[1], longitude: c[0] });
        });
      });
    });

    forEach(routeVehiclePositions, ({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    forEach(routeVehicleBreadCrumbs?.vehicles, veh => {
      forEach(veh.coords, coordGroup => {
        forEach(coordGroup, coord => {
          points.push({ latitude: coord.lat, longitude: coord.lng });
        });
      });
    });

    if (!!points.length) {
      const bounds = getMapBounds(points, {
        padding: 10,
        capZoom: 15,
      });

      setViewport({
        ...bounds,
        transitionDuration: 1000,
      });
    }
  }, [routeStops, haulerLocations, geoFences, routeVehiclePositions, routeVehicleBreadCrumbs?.vehicles]);

  // add the min and max coordinates to the bounds
  useEffect(() => {
    if (!minAndMaxCoordinatesGeoJSON) return;

    const firstFlag = minAndMaxCoordinatesGeoJSON.features[0];

    const lastFlag = minAndMaxCoordinatesGeoJSON.features[1];

    if (firstFlag.properties.isMin && lastFlag.properties.isMax) return;

    const points = [
      { latitude: firstFlag.geometry.coordinates[1], longitude: firstFlag.geometry.coordinates[0] },
      { latitude: lastFlag.geometry.coordinates[1], longitude: lastFlag.geometry.coordinates[0] },
    ];

    const bounds = getMapBounds(points, {
      padding: 5,
      capZoom: 20,
    });

    setViewport({
      ...bounds,
      transitionDuration: 1000,
    });
  }, [minAndMaxCoordinatesGeoJSON]);

  return { viewport };
};
