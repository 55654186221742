import React, { useState } from 'react';

import { Button } from 'src/core/components/styled/Button';
import {
  MessageLengthInfo,
  ResizableTextarea,
  SendMessageContainer,
  SendMessageInputContainer,
} from '../styles/MessagingContainer';
import translate from 'src/core/services/translate';

const maxNumberOfCharacters = 500;

export const MessageInput: React.FC<{
  onSendMessage: (message: string) => void;
  disabledSendButton: boolean;
  disabledTextArea?: boolean;
}> = ({ onSendMessage, disabledSendButton, disabledTextArea }) => {
  const [messageText, setMessageText] = useState('');

  return (
    <SendMessageContainer>
      <SendMessageInputContainer>
        <ResizableTextarea
          disabled={disabledTextArea}
          autoFocus
          maxRows={5}
          maxLength={maxNumberOfCharacters}
          value={messageText}
          onChange={event => setMessageText(event.target.value)}
          placeholder={translate('messaging.writeMessage')}
        />
        <MessageLengthInfo>
          {messageText.length} / {maxNumberOfCharacters}
        </MessageLengthInfo>
        <Button
          disabled={disabledSendButton || !messageText.trim().length}
          margin="no"
          onClick={() => {
            onSendMessage(messageText);
            setMessageText('');
          }}
          color="primary"
        >
          {translate('common.send')}
        </Button>
      </SendMessageInputContainer>
    </SendMessageContainer>
  );
};
