import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  exportReportingDetails as doExportReportingDetails,
  loadReportingDetails as doLoadReportingDetails,
} from '../services/reportingDetails';
import searchReportingDetailsVehicles from '../services/searchReportingDetailsVehicles';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';

// Actions
const START_LOAD = 'insights/reportingDetails/START_LOAD';
const COMPLETE_LOAD = 'insights/reportingDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/reportingDetails/FAIL_LOAD';
const START_VEHICLES_LOAD = 'insights/reportingDetails/vehicles/START_LOAD';
const COMPLETE_VEHICLES_LOAD = 'insights/reportingDetails/vehicles/COMPLETE_LOAD';
const FAIL_VEHICLES_LOAD = 'insights/reportingDetails/vehicles/FAIL_LOAD';
const START_EXPORT = 'settings/reportingDetails/START_EXPORT';
const COMPLETE_EXPORT = 'settings/reportingDetails/COMPLETE_EXPORT';
const FAIL_EXPORT = 'settings/reportingDetails/FAIL_EXPORT';
const RESET = 'insights/reportingDetails/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isExporting: false,
  reportingDetails: undefined,
  vehicles: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          reportingDetails: action.reportingDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case START_VEHICLES_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_VEHICLES_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: action.vehicles,
        },
      });

    case FAIL_VEHICLES_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (reportingDetails: any) => ({
  type: COMPLETE_LOAD,
  reportingDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const startVehiclesLoad = () => ({
  type: START_VEHICLES_LOAD,
});

const completeVehiclesLoad = (vehicles: any) => ({
  type: COMPLETE_VEHICLES_LOAD,
  vehicles,
});

const failVehiclesLoad = () => ({
  type: FAIL_VEHICLES_LOAD,
});

export const loadReportingDetails =
  (
    vendorId: number,
    startDate: string,
    endDate: string,
    vehicleTypeId: number,
    vehicleId: number,
    searchString: string,
    wasteAuditType: string,
    reportType: string,
    sortedBy: string,
    sortOrder: string,
    obstacleType: string,
    materialContaminationType: string,
    routeId: number,
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadReportingDetailsPromise = doLoadReportingDetails(
      vendorId,
      startDate,
      endDate,
      vehicleTypeId,
      vehicleId,
      searchString,
      wasteAuditType,
      reportType,
      sortedBy,
      sortOrder,
      obstacleType,
      materialContaminationType,
      routeId,
      filtersPreferencesIds,
    );
    loadReportingDetailsPromise
      .then(reportingDetails => dispatch(completeLoad(reportingDetails)))
      .catch(() => dispatch(failLoad()));
    return loadReportingDetailsPromise;
  };

export const exportReportingDetails =
  (
    vendorId: number,
    startDate: string,
    endDate: string,
    vehicleTypeId: number,
    vehicleId: number,
    searchString: string,
    wasteAuditType: string,
    reportType: string,
    sortedBy: string,
    sortOrder: string,
    obstacleType: string,
    materialContaminationType: string,
    routeId: number,
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportReportingDetailsPromise = doExportReportingDetails(
      vendorId,
      startDate,
      endDate,
      vehicleTypeId,
      vehicleId,
      searchString,
      wasteAuditType,
      reportType,
      sortedBy,
      sortOrder,
      obstacleType,
      materialContaminationType,
      routeId,
      filtersPreferencesIds,
    )
      .then(() => dispatch(completeExport()))
      .catch(() => dispatch(failExport()));
    return exportReportingDetailsPromise;
  };

export const loadVehiclesByReportType =
  (
    vendorId: number,
    startDate: string,
    endDate: string,
    reportType: string,
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startVehiclesLoad());
    const searchReportingDetailsVehiclesPromise = searchReportingDetailsVehicles(
      vendorId,
      startDate,
      endDate,
      reportType,
      filtersPreferencesIds,
    );
    searchReportingDetailsVehiclesPromise
      .then(vehicles => dispatch(completeVehiclesLoad(vehicles)))
      .catch(() => dispatch(failVehiclesLoad()));
    return searchReportingDetailsVehiclesPromise;
  };

export const resetReportingDetails = () => ({
  type: RESET,
});
