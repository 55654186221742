import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../../../contracts/ducks';
import { loadEventLog, loadEventTypes } from '../../../../ducks';
import { PanelSectionLoading } from '../../../../../core/components/styled';
import { TypedResolver } from '../../../../../core/components';
import EventLog from './EventLog';
import { loadZEventLog } from 'src/routes/ducks/eventLog';

interface EventLogResolverProps {
  loadEventLog: DuckFunction<typeof loadEventLog>;
  loadZEventLog: DuckFunction<typeof loadZEventLog>;
  loadEventTypes: DuckFunction<typeof loadEventTypes>;
  onLoadTab?: () => void;
  isZ?: boolean;
  routeId: number;
  isSnowPlowRoute: boolean;
}

class EventLogResolver extends PureComponent<EventLogResolverProps> {
  loadDependencies = () => {
    const { routeId, loadEventTypes, loadEventLog, loadZEventLog, isZ } = this.props;
    const evLogPromise = isZ ? loadZEventLog : loadEventLog;
    return Promise.all([loadEventTypes(), evLogPromise(routeId)]);
  };

  render() {
    const { onLoadTab, routeId, isSnowPlowRoute, isZ } = this.props;

    return (
      <TypedResolver
        loadingComponent={PanelSectionLoading}
        resolve={this.loadDependencies}
        successComponent={EventLog}
        successProps={{
          isZ,
          onLoadTab,
          routeId,
          isSnowPlowRoute,
        }}
      />
    );
  }
}

const mapDispatchToProps = { loadEventLog, loadZEventLog, loadEventTypes };

export default connect(undefined, mapDispatchToProps)(EventLogResolver);
