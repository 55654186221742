import React, { PureComponent } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormGroup, GridColumn } from './styled';
import Radio from './Radio';

interface Props extends WrappedFieldProps {
  items: any[];
  margin?: string;
  color?: string;
}

class RadioGroup extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      items,
      margin,
      color,
    } = this.props;

    return (
      <FormGroup margin={margin}>
        {items.map(item => (
          <GridColumn key={item.value} margin={item.margin} verticalAlign="normal">
            <Field
              color={color}
              name={name}
              component={Radio as any}
              type="radio"
              label={item.label}
              value={item.value}
              key={item.value}
              onChange={this.onChange}
            />
            {item.infoIcon}
          </GridColumn>
        ))}
      </FormGroup>
    );
  }
}

export default RadioGroup;
