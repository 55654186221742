import { connect, useDispatch, useSelector } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  PanelSection,
  PanelSectionGroup,
  PanelSectionSubTitle,
} from '../../../core/components/styled';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isRequired, maxLength50, maxLength160 } from '../../../utils/services/validator';
import { Input, Dropdown, Switch, TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';
import { loadServiceTypes } from 'src/common/ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { useCallback, useEffect, useState } from 'react';
import { loadPickupTypes } from 'src/routes/ducks/pickupTypes';
import { FRONT_LOAD_ID, ROLL_OFF_ID, TOTER_ID, DELIVERY_UTILITY_ID, RESIDENTIAL_ID } from 'src/fleet/constants';
import { inboundCreationRule } from 'src/vendors/interfaces/Open311';
import { loadExceptions } from 'src/routes/ducks';
import { loadExceptionConfigurations } from 'src/vendors/ducks';
import { SCHEDULED_DATE_TYPE } from 'src/core/constants/scheduledDateType';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

interface PropsWithoutReduxForm {
  onClose: (formPristine?: boolean) => void;
  isInbound: boolean;
  open311FormValues?: inboundCreationRule;
  initialServiceCode: string;
}

interface FormValues {
  adhocJob: boolean;
  equipmentTypeId: number;
  exceptionTypeId: number;
  pickupTypeId: number;
  reasonCodeId: number;
  serviceCode: string;
  serviceName: string;
  vehicleTypeId: number;
  wasteMaterialTypeId: number;
  open311FormValues?: inboundCreationRule;
  isLoading: boolean;
  loadServiceTypes: DuckFunction<typeof loadServiceTypes>;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

function Open311CreationRuleEditorForm({
  handleSubmit,
  onClose,
  open311FormValues,
  isInbound,
  initialServiceCode,
  pristine,
}: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const reasonCodeTypes = useSelector((state: AppState) => state.vendors.open311Settings.reasonCodeTypes);
  const vehicleTypes = useSelector((state: AppState) => state.vendors.open311Settings.vehicleTypes);
  const wasteMaterialTypes = useSelector((state: AppState) => state.vendors.open311Settings.wasteMaterialTypes);
  const serviceCodesList = useSelector((state: AppState) => state.vendors.open311Settings.serviceTypes);

  const [serviceEquipData, setServiceEquipData] = useState<any[]>([]);
  const [serviceTypeOptions, setServiceTypes] = useState<any[]>([]);
  const [equipmentTypeOptions, setEquiptmentTypes] = useState<any[]>([]);
  const [equipmentSizeOptions, setEquipmentSizes] = useState<any[]>([]);
  const [exceptionTypes, setExceptionTypes] = useState<any[]>([]);
  const [pickupTypesOptions, setPickupTypes] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const equipmentTypeId = open311FormValues?.equipmentTypeId;
  const vehicleTypeId = open311FormValues?.vehicleTypeId;
  const serviceTypeId = open311FormValues?.serviceTypeId;
  const serviceCode = open311FormValues?.serviceCode;

  const loadServiceOptions = useCallback(
    (newVehicleTypeId: number) => {
      setIsLoading(true);
      loadServiceTypes(newVehicleTypeId)(dispatch).then(results => {
        setIsLoading(false);
        const serviceTypeOptions = map(results, serviceType => ({
          label: serviceType.name,
          value: serviceType.id,
        }));
        setServiceEquipData(results);
        setServiceTypes(serviceTypeOptions);
      });
    },
    [dispatch],
  );

  const loadExceptionOptions = useCallback(
    (serviceCode: string | undefined) => {
      const vehicleTypeId = serviceCodesList?.find(cr => cr.serviceCode === serviceCode)?.vehicleTypeId;
      if (!vehicleTypeId) return;
      setIsLoading(true);
      vehicleTypeId &&
        loadExceptionConfigurations(
          vendorId,
          vehicleTypeId,
        )(dispatch).then(results => {
          setIsLoading(false);
          const filteredExceptions = results.filter((exception: any) => exception.isEnabled);
          setIsLoading(false);
          const exceptionTypeOptions = map(filteredExceptions, exception => ({
            name: exception.name,
            id: exception.id,
          }));
          setExceptionTypes(exceptionTypeOptions);
        });
    },
    [dispatch, serviceCodesList, vendorId],
  );

  const loadEquiptmentOptions = useCallback(
    (newServiceTypeId: number) => {
      setIsLoading(true);
      const equiptmentTypeFound =
        serviceEquipData?.length > 0 && serviceEquipData[0] && serviceEquipData[0].id
          ? serviceEquipData.find(obj => obj.id === newServiceTypeId)?.equipmentTypes
          : [];
      const equipOptions = map(equiptmentTypeFound, equipmentType => ({
        label: equipmentType.name,
        value: equipmentType.id,
      }));
      setEquiptmentTypes(equipOptions);
      setIsLoading(false);
    },
    [serviceEquipData],
  );

  const loadEquiptmentSizeOptions = useCallback(
    (newServiceTypeId: number, equipmentTypeId: number) => {
      setIsLoading(true);
      const equiptmentTypesList =
        serviceEquipData?.length > 0 && serviceEquipData[0] && serviceEquipData[0].id
          ? serviceEquipData.find(obj => obj.id === newServiceTypeId)?.equipmentTypes
          : [];
      const equipmentSizeFound = equiptmentTypesList?.find(
        (obj: TechnicalType) => obj.id === equipmentTypeId,
      )?.equipmentSizes;

      const equipmentSizeOptions = map(equipmentSizeFound, equipmentType => ({
        label: equipmentType.name,
        value: equipmentType.id,
      }));
      setEquipmentSizes(equipmentSizeOptions);
      setIsLoading(false);
    },
    [serviceEquipData],
  );

  const loadPickupOptions = useCallback(
    (newVehicleTypeId: number) => {
      setIsLoading(true);
      loadPickupTypes(newVehicleTypeId)(dispatch).then(results => {
        let pickupTypes = results[0].pickupTypes;
        const pickupTypeOptions = map(pickupTypes, pickupType => ({
          label: pickupType.name,
          value: pickupType.id,
        }));

        setPickupTypes(pickupTypeOptions);
        setIsLoading(false);
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (!vehicleTypeId) {
      return;
    } else {
      loadServiceOptions(vehicleTypeId);
      loadPickupOptions(vehicleTypeId);
    }
  }, [vehicleTypeId, loadServiceOptions, loadPickupOptions]);

  useEffect(() => {
    if (!serviceCode && !isInbound) return;
    loadExceptionOptions(serviceCode);
  }, [serviceCode, serviceCodesList, isInbound, vendorId, loadExceptionOptions]);

  useEffect(() => {
    if (serviceTypeOptions.length > 0 && serviceTypeId && vehicleTypeId) {
      loadEquiptmentOptions(serviceTypeId);
    }
    if (serviceTypeId && equipmentTypeId && vehicleTypeId) {
      loadEquiptmentSizeOptions(serviceTypeId, equipmentTypeId);
    }
  }, [
    serviceTypeOptions,
    equipmentTypeId,
    serviceTypeId,
    vehicleTypeId,
    loadEquiptmentOptions,
    loadEquiptmentSizeOptions,
  ]);

  const onVehicleTypeChange = (e: any, newVehicleTypeId: number) => {
    loadServiceOptions(newVehicleTypeId);
    loadPickupOptions(newVehicleTypeId);
    loadExceptions(newVehicleTypeId);
  };

  const exceptionTypeOptions = map(exceptionTypes, exceptionType => ({
    label: exceptionType.name,
    value: exceptionType.id,
  }));

  const reasonCodeOptions = map(reasonCodeTypes, rc => ({
    label: rc.name,
    value: rc.id,
  }));

  const acceptedVehicleTypes = [FRONT_LOAD_ID, ROLL_OFF_ID, TOTER_ID, DELIVERY_UTILITY_ID, RESIDENTIAL_ID];
  const acceptedVehicles = vehicleTypes.filter(vehicleType => acceptedVehicleTypes.includes(vehicleType.id));
  const vehicleTypeOptions = map(acceptedVehicles, vt => ({
    label: vt.name,
    value: vt.id,
  }));

  const wasteMaterialTypeOptions = map(wasteMaterialTypes, wasteMaterial => ({
    label: wasteMaterial.name,
    value: wasteMaterial.id,
  }));

  const serviceCodeTypeOptions = map(serviceCodesList, creationRule => ({
    label: creationRule.serviceCode,
    value: creationRule.serviceCode,
  }));

  const dateTypeOptions = map(SCHEDULED_DATE_TYPE, date => ({
    label: date.name,
    value: date.id,
  }));

  const isUniqueServiceCode = useCallback(
    (newServiceCode: any) => {
      if (initialServiceCode === newServiceCode) return undefined;
      if (newServiceCode) {
        const isServiceCodeFound = serviceCodesList?.find(cr => cr.serviceCode === newServiceCode);
        const valueReturned = isServiceCodeFound
          ? translate('vendors.open311.alertMessages.duplicateServiceCode')
          : undefined;
        return valueReturned;
      }
      return undefined;
    },
    [serviceCodesList, initialServiceCode],
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection isLoading={isLoading} padding="small xSmall no">
        <PanelSectionGroup padding="no" width="100%">
          <Grid multiLine>
            <GridColumn size="12/12">
              <PanelSectionSubTitle margin="small no small">
                {translate('vendors.open311.serviceConfiguration')}
              </PanelSectionSubTitle>
            </GridColumn>
            {isInbound ? (
              <GridColumn size="6/12">
                <TypedField
                  name="serviceCode"
                  validate={!!open311FormValues?.serviceCode ? [isUniqueServiceCode, isRequired] : [isRequired]}
                  component={Input}
                  props={{
                    label: `* ${translate('vendors.open311.serviceCode')}`,
                  }}
                />
              </GridColumn>
            ) : (
              <GridColumn size="6/12">
                <TypedField
                  name="serviceCode"
                  validate={[isRequired]}
                  component={Dropdown}
                  props={{
                    label: `* ${translate('vendors.open311.serviceCode')}`,
                    options: serviceCodeTypeOptions,
                  }}
                />
              </GridColumn>
            )}

            <GridColumn size="6/12">
              <TypedField
                name="serviceName"
                component={Input}
                props={{
                  label: translate('vendors.open311.serviceName'),
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="reasonCodeId"
                component={Dropdown}
                validate={[isRequired]}
                props={{
                  options: reasonCodeOptions,
                  label: `* ${translate('autoDispatch.reasonCode')}`,
                }}
              />
            </GridColumn>
            {isInbound ? (
              <>
                <GridColumn size="6/12">
                  <TypedField
                    name="vehicleTypeId"
                    component={Dropdown}
                    validate={[isRequired]}
                    onChange={onVehicleTypeChange}
                    props={{
                      label: `* ${translate('vehicles.vehicleType')}`,
                      options: vehicleTypeOptions,
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="serviceTypeId"
                    component={Dropdown}
                    validate={[isRequired]}
                    props={{
                      label: `* ${translate('common.serviceType')}`,
                      options: serviceTypeOptions,
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="pickupTypeId"
                    component={Dropdown}
                    validate={[isRequired]}
                    props={{
                      label: `* ${translate('routes.pickupType')}`,
                      options: pickupTypesOptions,
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="wasteMaterialTypeId"
                    component={Dropdown}
                    validate={[isRequired]}
                    props={{
                      label: `* ${translate('customers.wasteMaterial')}`,
                      options: wasteMaterialTypeOptions,
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="equipmentTypeId"
                    component={Dropdown}
                    validate={[isRequired]}
                    props={{
                      label: `* ${translate('common.equipmentType')}`,
                      options: equipmentTypeOptions,
                    }}
                  />
                </GridColumn>

                <GridColumn size="6/12">
                  <TypedField
                    name="equipmentSizeId"
                    component={Dropdown}
                    validate={[isRequired]}
                    props={{
                      label: `* ${translate('common.equipmentSize')}`,
                      options: equipmentSizeOptions,
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="customNotesField"
                    component={Input}
                    props={{
                      label: translate('vendors.open311.customNotesField'),
                    }}
                  />
                </GridColumn>

                <GridColumn size="6/12">
                  <TypedField
                    name="useScheduledDate"
                    component={Switch}
                    props={{
                      label: translate('vendors.open311.useScheduledDate'),
                      labelOnLeft: true,
                      margin: 'xSmall no sMedium no',
                      width: '100%',
                    }}
                  />
                </GridColumn>

                <GridColumn size="6/12">
                  <TypedField
                    name="adHocJob"
                    component={Switch}
                    props={{
                      label: translate(`vendors.open311.adhocJob`),
                      labelOnLeft: true,
                      margin: 'xSmall no sMedium no',
                      width: '100%',
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="createServiceContractIfNotFound"
                    component={Switch}
                    props={{
                      label: translate('vendors.open311.autoCreateNewService'),
                      labelOnLeft: true,
                      margin: 'xSmall no sMedium no',
                      width: '100%',
                    }}
                  />
                </GridColumn>
              </>
            ) : (
              <GridColumn size="6/12">
                <TypedField
                  name="exceptionTypeId"
                  component={Dropdown}
                  validate={[isRequired]}
                  props={{
                    label: `* ${translate('dashboard.exceptionType')}`,
                    options: exceptionTypeOptions,
                  }}
                />
              </GridColumn>
            )}
          </Grid>
        </PanelSectionGroup>
      </PanelSection>
      {isInbound && (
        <PanelSection padding="no">
          <PanelSectionGroup padding="small no" width="100%">
            <Grid margin="no" multiLine>
              <GridColumn size="12/12">
                <PanelSectionSubTitle margin="no no small">
                  {translate('vendors.open311.fieldMappingSetting')}
                </PanelSectionSubTitle>
              </GridColumn>
              <GridColumn size="6/12">
                <TypedField
                  name="scheduledDate"
                  component={Input}
                  validate={[maxLength160]}
                  props={{
                    label: translate('vendors.open311.scheduledDate'),
                    margin: 'small no',
                  }}
                />
              </GridColumn>
              <GridColumn size="6/12">
                <TypedField
                  name="scheduledDateType"
                  component={Dropdown}
                  validate={[maxLength160]}
                  props={{
                    label: translate('vendors.open311.scheduledDateType'),
                    margin: 'small no',
                    options: dateTypeOptions,
                  }}
                />
              </GridColumn>
            </Grid>
          </PanelSectionGroup>
        </PanelSection>
      )}

      <PanelSection padding="no">
        <PanelSectionGroup padding="small no" width="100%">
          <Grid margin="no" multiLine>
            <GridColumn size="12/12">
              <PanelSectionSubTitle margin="no no small">
                {translate('vendors.open311.serviceRequestClosureSetting')}
              </PanelSectionSubTitle>
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="closeOutCode"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.code'),
                  margin: 'no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="outcomeCode"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.outcomeCode'),
                  margin: 'no',
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="responseCode"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.responseCode'),
                  margin: 'small no',
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSectionGroup>
      </PanelSection>

      <ButtonSet align="center" margin="large small">
        <Button type="submit" color="primary">
          {translate('common.save')}
        </Button>
        <Button type="button" margin="no small" color="secondary" onClick={() => onClose(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ButtonSet>
    </form>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    open311FormValues: getFormValues('open311CreationRuleEditorForm')(state) || {},
  } as Partial<inboundCreationRule>;
};

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: 'open311CreationRuleEditorForm',
    onSubmitFail: focusFirstInvalidField,
  })(Open311CreationRuleEditorForm),
);
