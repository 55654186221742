import translate from 'src/core/services/translate';

const SECONDS_10 = 10; // number of seconds
const SECONDS_30 = 30;
const MINUTES_1 = 60;
const MINUTES_2 = 120;
const MINUTES_3 = 180;
const MINUTES_5 = 300;

export const FORWARD_VIEW_ID = 1;
export const HOPPER_VIEW_ID = 5;
export const VIDEO_DEVICE_SURFSIGHT_TYPE_ID = 1;
export const VIDEO_DEVICE_RUBICON_ALL_IN_ONE_TYPE_ID = 2;

export const DURATION_LIST = [
  { id: SECONDS_10, name: `10 ${translate('common.seconds')}` },
  { id: SECONDS_30, name: `30 ${translate('common.seconds')}` },
  { id: MINUTES_1, name: `1 ${translate('common.minute')}` },
  { id: MINUTES_2, name: `2 ${translate('common.minutes')}` },
  { id: MINUTES_5, name: `5 ${translate('common.minutes')}` },
];

export const DURATION_LIST_RUBICON = [
  { id: MINUTES_1, name: `1 ${translate('common.minute')}` },
  { id: MINUTES_2, name: `2 ${translate('common.minutes')}` },
  { id: MINUTES_3, name: `3 ${translate('common.minutes')}` },
];

export const CAMERAS_LIST = [{ id: FORWARD_VIEW_ID, name: translate('routes.videoRequest.cameras.forwardView') }];
