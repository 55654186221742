import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RouteMapFeature, setRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { ContainerFacility } from 'src/fleet/interfaces/containers';
import {
  CONTAINERS_MAP_CLUSTER_SOURCE,
  CONTAINERS_MAP_FACILITIES_LAYER,
  FACILITIES_MAP_COLORS,
} from 'src/fleet/constants/containersMap';
import FacilitiesPopupResolver from './FacilitiesPopupResolver';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import FacilitiesGLLayers from './FacilitiesGLLayers';

type Props = {
  map: mapboxgl.Map;
  facilities: ContainerFacility[];
};

const mapImages = FACILITIES_MAP_COLORS.map(fac => ({
  id: fac.iconType,
  url: fac.icon,
  sdf: false,
}));

const mapImagesOptions = { addLayers: false };

export default function FacilitiesGL({ map, facilities }: Props) {
  const dispatch = useDispatch();
  useMapImages(mapImages, map, mapImagesOptions);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === CONTAINERS_MAP_CLUSTER_SOURCE &&
              feature.properties?.layer === CONTAINERS_MAP_FACILITIES_LAYER,
          );

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.facility, feature.properties?.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <FacilitiesGLLayers />
      <FacilitiesPopupResolver facilities={facilities} />
    </>
  );
}
