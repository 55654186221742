export const COLOR_DEFAULT = 'default';
export const COLOR_PRIMARY = 'primary';
export const COLOR_SECONDARY = 'secondary';
export const COLOR_SUCCESS = 'success';
export const COLOR_PART_SUCCESS = 'partsuccess';
export const COLOR_INFO = 'info';
export const COLOR_WARNING = 'warning';
export const COLOR_ALERT = 'alert';
export const COLOR_GRAY = 'gray';
export const COLOR_GRAY_DARK = 'grayDark';
export const COLOR_GRAY_DARKER = 'grayDarker';
export const COLOR_GRAY_LIGHT = 'grayLight';
export const COLOR_GRAY_LIGHTER = 'grayLighter';
export const COLOR_BLACK = 'black';
export const COLOR_WHITE = 'white';
export const COLOR_ORANGE_DARK = 'orangeDark';
