import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { ServiceDeliveredForm } from '../forms';
import { AppState } from '../../../store';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { DuckFunction } from '../../../contracts/ducks';
import { updateHaulerOpportunity } from '../../ducks';
import { REMOVAL } from '../../../fleet/constants/openDispatachesServices';
import { REQUESTED } from '../../../fleet/constants/rubiconDisptachesStatus';
interface RouteParams {
  token: string;
}

interface ComponentProps {
  action: string;
  caseId: number;
  itemId: number;
  userId: string;
  vendorId: number;
  liftAndShiftVendor?: boolean;
  pickupTypeId: number;
  statusId: number;
  onCancel(pristine: boolean, isReload?: boolean): void;
}

interface ReduxProps {
  response: any;
  requestedChanges: any;
  WasDelivered: any;
  CanNotDeliver: any;
  isSaving: boolean;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const ServiceDeliveredModal: React.FC<Props> = props => {
  const { isSaving, statusId, onCancel } = props;

  const onSubmit = React.useCallback(async () => {
    const {
      itemId,
      liftAndShiftVendor,
      pickupTypeId,
      match: {
        params: { token },
      },
      statusId,
      userId,
      vendorId,
      WasDelivered,
      onCancel,
      loadDispatchOpportunitiesCount,
      updateHaulerOpportunity,
    } = props;

    const requestDeliveryObj: any = {};
    requestDeliveryObj.ItemId = itemId;
    requestDeliveryObj.liftAndShiftVendor = liftAndShiftVendor;

    if (statusId === REQUESTED) {
      requestDeliveryObj.Response = 'WillDeliverOnScheduledDate';
    } else if (pickupTypeId === REMOVAL) {
      requestDeliveryObj.Response = 'WasRemoved';
      requestDeliveryObj.wasRemoved = WasDelivered;
      if (requestDeliveryObj.wasRemoved && requestDeliveryObj.wasRemoved.deliveryDate) {
        requestDeliveryObj.wasRemoved.deliveryDate = moment(requestDeliveryObj.wasRemoved.deliveryDate).format(
          'YYYY-MM-DD',
        );
      }
    } else {
      requestDeliveryObj.Response = 'WasDelivered';
      requestDeliveryObj.WasDelivered = WasDelivered;
      requestDeliveryObj.WasDelivered.Schedule = requestDeliveryObj.WasDelivered.Schedule
        ? Object.assign({}, ...requestDeliveryObj.WasDelivered.Schedule.map((d: any) => ({ [d[0]]: true })))
        : { onCall: true };

      if (requestDeliveryObj.WasDelivered && requestDeliveryObj.WasDelivered.deliveryDate) {
        requestDeliveryObj.WasDelivered.deliveryDate = moment(requestDeliveryObj.WasDelivered.deliveryDate).format(
          'YYYY-MM-DD',
        );
      }
    }

    const promise = updateHaulerOpportunity(requestDeliveryObj, userId, token, vendorId);

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        const isReload = true;
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true, isReload);
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);

  return (
    <Modal size="small" isLoading={isSaving}>
      <ModalTitle>
        {statusId === REQUESTED
          ? translate('opportunity.deliveries.acceptDelivery')
          : translate('opportunity.deliveries.serviceCompleted')}
      </ModalTitle>
      <ModalSubtitle />
      <Grid centered>
        <GridColumn size="10/12">
          <ServiceDeliveredForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('serviceDelivered');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `deliveries.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `deliveries.settings${ownProps.caseId}.requestedChanges`),
  WasDelivered: formSelector(state, `deliveries.settings${ownProps.caseId}.WasDelivered`),
  CanNotDeliver: formSelector(state, `deliveries.settings${ownProps.caseId}.CanNotDeliver`),
  isSaving: state.opportunity.opportunity.isSaving,
});

export default connect(mapStateToProps, undefined)(ServiceDeliveredModal);
