import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { loadingOverlay } from '../../../core/styles';
import { Icon } from '../../../core/components';

interface FeedbackProps {
  xOffset: number;
  yOffset: number;
}

export const Feedback = styled.div<FeedbackProps>`
  position: fixed;
  right: ${props => props.xOffset}px;
  bottom: ${props => props.yOffset}px;
  z-index: 6000;
`;

export const FeedbackIcon = styled.span`
  position: absolute;
  font-size: 20px;
`;

interface FeedbackButtonProps {
  isFeedbackFormOpen: boolean;
}

export const FeedbackButton = styled.div<FeedbackButtonProps>`
  transform: rotate(90deg) translateY(100%);
  transform-origin: bottom right;
  transition: transform 0.3s;
  position: absolute;
  right: 0;
  bottom: 0;

  ${props =>
    props.isFeedbackFormOpen &&
    css`
      button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}
`;

export const FeedbackText = styled.span`
  padding-left: 20px;
`;

interface FeedbackFormWrapperProps {
  isFeedbackFormOpen: boolean;
  isLoading: boolean;
  xOffset: number;
}

export const FeedbackFormWrapper = styled.div<FeedbackFormWrapperProps>`
  position: relative;
  background-color: #fff;
  border: 1px solid #f2f2f5;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};
  width: ${props => (props.isFeedbackFormOpen ? 500 : 0)}px;
  pointer-events: ${props => (props.isFeedbackFormOpen ? 'all' : 'none')};
  visibility: ${props => (props.isFeedbackFormOpen ? 'visible' : 'hidden')};
  transition: width 0.3s ease-out, visibility 0.4s;
  margin-right: ${props => props.xOffset}px;
  border-radius: 5px 5px 0;
  overflow: hidden;

  ${props =>
    props.isLoading &&
    css`
      ${props => loadingOverlay('24px', props.theme.bodyBackgroundColor)};
    `};
`;

export const FeedbackSendButton = styled.div`
  text-align: right;
  margin: 15px 0 0 0;
`;

export const FeedbackClose = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
  cursor: pointer;
`;

export const FeedbackCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 10px;
  height: 10px;
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;
