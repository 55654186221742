import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { DriverPickListDefaults } from '../interfaces/DriverPickListDefaults';
import {
  loadDriverPickListDefaults as doLoadDriverPickListDefaults,
  saveDriverPickListDefaults as doSaveDriverPickListDefaults,
} from '../services/driverPickListDefaults';

// Actions
const COMPLETE_LOAD = 'vendors/driverPickListDefaults/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/driverPickListDefaults/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/driverPickListDefaults/FAIL_LOAD';
const FAIL_SAVE = 'vendors/driverPickListDefaults/FAIL_SAVE';
const START_LOAD = 'vendors/driverPickListDefaults/START_LOAD';
const START_SAVE = 'vendors/driverPickListDefaults/START_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  driverPickListDefaults: [] as DriverPickListDefaults[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          driverPickListDefaults: action.driverPickListDefaults,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (driverPickListDefaults: DriverPickListDefaults[]) => ({
  type: COMPLETE_LOAD,
  driverPickListDefaults,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadDriverPickListDefaults = () => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadDriverPickListDefaultsPromise = doLoadDriverPickListDefaults();
  loadDriverPickListDefaultsPromise
    .then(driverPickListDefaults => dispatch(completeLoad(driverPickListDefaults)))
    .catch(() => dispatch(failLoad()));

  return loadDriverPickListDefaultsPromise;
};

export const saveDriverPickListDefaults =
  (driverPickListDefaults: DriverPickListDefaults[]) => (dispatch: Dispatch) => {
    dispatch(startSave());

    const saveDriverPickListDefaultsPromise = doSaveDriverPickListDefaults(driverPickListDefaults);
    saveDriverPickListDefaultsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveDriverPickListDefaultsPromise;
  };
