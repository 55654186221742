import React, { Fragment, PureComponent } from 'react';
import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { Table } from '../../../core/components';
import { Message, PanelSection, PanelSectionGroup, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import {
  appVersionsSelector,
  currentApplicationBuildNumberSelector,
  loadVersionStatuses,
  resetVersionStatuses,
} from '../../ducks';
import { VersionStatusesForm } from '../forms';
import VersionStatusesPageTableRow from './VersionStatusesPageTableRow';
import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';

interface Props extends RouteComponentProps {
  isLoading: boolean;
  vendors: any[];
  appVersions?: any[];
  currentApplicationBuildNumber?: string;
  vendorId: string;
  loadVersionStatuses(...args: any[]): any;
  push(...args: any[]): any;
  resetVersionStatuses(...args: any[]): any;
}

class VersionStatusesPage extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { isLoading, loadVersionStatuses, location, vendorId } = this.props;
    const { vehicleTypeId } = getQueryParams(location.search);
    if (location.search !== prevProps.location.search && !isLoading && vendorId && vehicleTypeId) {
      loadVersionStatuses(vendorId, vehicleTypeId);
    }
  }

  componentWillUnmount() {
    this.props.resetVersionStatuses();
  }

  onSubmit = ({ vehicleTypeId }: { vehicleTypeId: number }) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { vehicleTypeId }));
  };

  render() {
    const { currentApplicationBuildNumber, appVersions, isLoading } = this.props;
    const versionStatusTableColumns = [
      { name: 'regplate', label: translate('common.regplate'), width: '50%', sortable: true },
      { name: 'applicationBuildNumber', label: translate('common.buildNumber'), width: '50%', sortable: true },
    ];

    return (
      <PanelSectionGroup isLoading={isLoading}>
        <VersionStatusesForm onSubmit={this.onSubmit} />

        {!!size(appVersions) && (
          <Fragment>
            <PanelSection padding="sMedium">
              <Text margin="no small no no" weight="light" size="large">
                {translate('settings.currentApplicationBuildNumber')}:
              </Text>
              <Text weight="medium" size="large">
                {currentApplicationBuildNumber}
              </Text>
            </PanelSection>

            <PanelSection>
              <Table cells={versionStatusTableColumns} rows={appVersions} rowComponent={VersionStatusesPageTableRow} />
            </PanelSection>
          </Fragment>
        )}

        {!size(appVersions) && <Message padding="sMedium">{translate('settings.noApplicationVersions')}</Message>}
      </PanelSectionGroup>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  appVersions: appVersionsSelector(state.settings.versionStatuses),
  currentApplicationBuildNumber: currentApplicationBuildNumberSelector(state.settings.versionStatuses),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  isLoading: state.settings.versionStatuses.isLoading,
  vendors: state.vendors.vendors.vendors,
});

const mapDispatchToProps = {
  loadVersionStatuses,
  resetVersionStatuses,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VersionStatusesPage));
