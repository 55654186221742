import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';

import { InputStyle } from './Input';
import sizeMapper from '../../../utils/styles/sizeMapper';

interface ChipInputWrapperProps {
  margin?: string;
}

export const ChipInputWrapper = styled.div<ChipInputWrapperProps>`
  display: flex;
  flex-direction: column;
  margin: ${props => sizeMapper(props.margin, 'no')};
`;

export const ChipInputChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px 4px;
  width: calc(100% + 8px);
`;

interface ChipInputChipProps {
  isInvalid?: boolean;
}

export const ChipInputChip = styled.div<ChipInputChipProps>`
  display: inline-flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 7px;
  border: 1px solid ${props => (props.isInvalid ? props.theme.brandAlert : props.theme.brandPrimary)};
  color: ${props => (props.isInvalid ? props.theme.brandAlert : props.theme.brandPrimary)};
  user-select: none;
  margin: 4px;

  &:hover {
    color: ${props => lighten(0.02, props.isInvalid ? props.theme.brandAlert : props.theme.brandPrimary)};
  }

  &:active {
    color: ${props => darken(0.02, props.isInvalid ? props.theme.brandAlert : props.theme.brandPrimary)};
  }
`;

export const ChipInputChipButton = styled.div`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  margin: 0 0 0 8px;
  padding: 0;
  line-height: 1;
  color: inherit;
  font-size: 0;
  cursor: pointer;
`;

interface ChipInputFieldProps {
  hasError?: boolean;
}

export const ChipInputField = styled.input<ChipInputFieldProps>`
  ${InputStyle}

  ${props =>
    props.hasError &&
    css`
      border-bottom-color: ${props.theme.brandAlert};

      &:focus {
        border-bottom-color: ${props.theme.brandAlert};
      }
    `}
`;
