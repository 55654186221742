import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { UnconnectedInput, UnconnectedSwitch } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  deviceService: any;
  isEnabled: boolean;
  onZDeviceConfigurationVersionChange: (id: boolean) => any;
  toggleZDeviceConfiguration: (id: boolean) => any;
  versionNumber: string;
}

const ZDeviceConfigurationModalTableRow: React.FC<Props> = ({
  deviceService: { technicalName, id },
  isEnabled,
  onZDeviceConfigurationVersionChange,
  toggleZDeviceConfiguration,
  versionNumber,
}) => (
  <TableRow>
    <TableCell width="60%">{translate(createTranslationKey(technicalName, 'vendors.deviceServices'))}</TableCell>
    <TableCell width="20%" align="right">
      <UnconnectedSwitch checked={isEnabled} onChange={toggleZDeviceConfiguration(id)} />
    </TableCell>
    <TableCell width="20%" align="right">
      <UnconnectedInput
        margin="xSmall no no"
        onChange={onZDeviceConfigurationVersionChange(id)}
        value={versionNumber}
      />
    </TableCell>
  </TableRow>
);

export default ZDeviceConfigurationModalTableRow;
