import { omit, sum } from 'lodash-es';

import { RESIDENTIAL_ID } from '../../fleet/constants';
import { RouteSettings, RawRouteSettings } from '../interfaces/RouteSettings';

const normalizeBoolean = (value: string) => {
  if (value === 'true') {
    return true;
  }

  return false;
};

export const normalizeTimeObjectToDate = (
  time?: { hours: number; minutes: number; seconds: number } | string | null,
) => {
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  if (time && typeof time === 'object') date.setHours(time.hours, time.minutes, time.seconds);
  return date;
};

export const normalizeTimeDateToObject = (time?: Date | null) => {
  if (time && typeof time === 'object')
    return {
      hours: time.getHours(),
      minutes: time.getMinutes(),
      seconds: time.getSeconds(),
    };
  return {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
};

const transformRawSettings = (parameters: RawRouteSettings): RouteSettings => {
  const maxRouteTime = normalizeTimeObjectToDate(parameters.maxRouteTime);
  const postEndTime = normalizeTimeObjectToDate(parameters.postEndTime);
  const preStartTime = normalizeTimeObjectToDate(parameters.preStartTime);
  const routeStartTime = normalizeTimeObjectToDate(parameters.routeStartTime);
  const serviceTimePerStop = normalizeTimeObjectToDate(parameters.serviceTimePerStop);
  const singleSideServiceTimePerStop = normalizeTimeObjectToDate(parameters.singleSideServiceTimePerStop);
  const bothSidesServiceTimePerStop = normalizeTimeObjectToDate(parameters.bothSidesServiceTimePerStop);
  const amBreakTime = normalizeTimeObjectToDate(parameters.breaks[0].time);
  const lunchBreakTime = normalizeTimeObjectToDate(parameters.breaks[1].time);
  const pmBreakTime = normalizeTimeObjectToDate(parameters.breaks[2].time);

  const totalBreakTime = normalizeTimeObjectToDate({
    hours: sum(parameters.breaks.map(b => b.time.hours)),
    minutes: sum(parameters.breaks.map(b => b.time.minutes)),
    seconds: sum(parameters.breaks.map(b => b.time.seconds)),
  });

  let newParameters: RouteSettings = {
    ...parameters,
    bothSidesServiceTimePerStop,
    preStartTime,
    maxRouteTime,
    postEndTime,
    routeStartTime,
    serviceTimePerStop,
    singleSideServiceTimePerStop,
    totalBreakTime,
    amBreakTime,
    pmBreakTime,
    lunchBreakTime,
    globalTurnTimeSettingsEnabled: normalizeBoolean(parameters.globalTurnTimeSettingsEnabled),
  };

  if (newParameters.vehicleTypeId === RESIDENTIAL_ID) {
    delete newParameters.serviceTimePerStop;
  } else {
    delete newParameters.singleSideServiceTimePerStop;
    delete newParameters.bothSidesServiceTimePerStop;
  }
  return newParameters;
};

const transformSettings = (parameters: RouteSettings): RawRouteSettings => {
  let singleSideServiceTimePerStopDate = null;
  if (parameters.singleSideServiceTimePerStop && typeof parameters.singleSideServiceTimePerStop === 'number') {
    singleSideServiceTimePerStopDate = normalizeTimeObjectToDate({
      hours: 0,
      minutes: 0,
      seconds: parameters.singleSideServiceTimePerStop,
    });
  }

  let bothSidesServiceTimePerStopDate = null;
  if (parameters.bothSidesServiceTimePerStop && typeof parameters.bothSidesServiceTimePerStop === 'number') {
    bothSidesServiceTimePerStopDate = normalizeTimeObjectToDate({
      hours: 0,
      minutes: 0,
      seconds: parameters.bothSidesServiceTimePerStop,
    });
  }

  let newParameters: RawRouteSettings = {
    ...omit(parameters, ['pmBreakTime', 'amBreakTime', 'lunchBreakTime', 'totalBreakTime']),
    bothSidesServiceTimePerStop: normalizeTimeDateToObject(bothSidesServiceTimePerStopDate),
    preStartTime: normalizeTimeDateToObject(parameters.preStartTime),
    maxRouteTime: normalizeTimeDateToObject(parameters.maxRouteTime),
    postEndTime: normalizeTimeDateToObject(parameters.postEndTime),
    routeStartTime: normalizeTimeDateToObject(parameters.routeStartTime),
    serviceTimePerStop: normalizeTimeDateToObject(parameters.serviceTimePerStop),
    singleSideServiceTimePerStop: normalizeTimeDateToObject(singleSideServiceTimePerStopDate),
    breaks: [
      { ...parameters.breaks[0], time: normalizeTimeDateToObject(parameters.amBreakTime) },
      { ...parameters.breaks[1], time: normalizeTimeDateToObject(parameters.lunchBreakTime) },
      { ...parameters.breaks[2], time: normalizeTimeDateToObject(parameters.pmBreakTime) },
    ],
    globalTurnTimeSettingsEnabled: String(parameters.globalTurnTimeSettingsEnabled),
  };

  if (newParameters.vehicleTypeId === RESIDENTIAL_ID) {
    delete newParameters.serviceTimePerStop;
  } else {
    delete newParameters.singleSideServiceTimePerStop;
    delete newParameters.bothSidesServiceTimePerStop;
  }
  return newParameters;
};

export const transformRouteBuilderRawSettings = (settings: RawRouteSettings): RouteSettings =>
  transformRawSettings(settings);

export const transformRouteSequenceRawSettings = (settingsList: RawRouteSettings[]): RouteSettings[] =>
  settingsList.map(settings => transformRawSettings(settings));

export const transformRouteBuilderSettings = (settings: RouteSettings): RawRouteSettings => transformSettings(settings);

export const transformRouteSequenceSettings = (settingsList: RouteSettings[]): RawRouteSettings[] =>
  settingsList.map(settings => transformSettings(settings));
