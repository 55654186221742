import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { TableRow } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import { colorMapperWithoutProps } from 'src/utils/styles/colorMapper';

export const RouteTemplateBuilderTableRowCollapseIcon = styled(Icon)`
  color: ${colorMapperWithoutProps('grayDarker')};
`;

interface RouteTemplateBuilderTableRowClusterProps {
  isExpanded: boolean;
}

export const RouteTemplateBuilderTableRowCluster = styled.div<RouteTemplateBuilderTableRowClusterProps>`
  position: relative;

  ${props =>
    props.isExpanded &&
    css`
      background-color: ${transparentize(0.95, props.theme.brandPrimary)};

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
        box-shadow: inset 0 0 0 3px ${props.theme.brandPrimary};
      }

      ${TableRow}:first-child:hover {
        background-color: ${transparentize(0.97, props.theme.brandPrimary)};
      }

      & ${RouteTemplateBuilderTableRowCollapseIcon} {
        transform: rotate(180deg);
      }
    `}

  & ${RouteTemplateBuilderTableRowCollapseIcon} {
    width: 16px;
    height: 16px;
    transition: transform 0.2s;
  }
`;
