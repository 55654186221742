import { identity, mapKeys } from 'lodash-es';
import { createSelector } from 'reselect';
import { RESIDENTIAL_ID } from 'src/fleet/constants';

import { AppState } from '../../store';
import { RouteSequenceSettingsState, RouteSequenceFormValues } from '../interfaces/RouteSequenceSettings';

const routeSequenceSettings = (
  routeSequenceSettingsState: RouteSequenceSettingsState,
  intermediateLocationId?: number | null,
) =>
  mapKeys(
    routeSequenceSettingsState.routeSequenceSettings.map(settings => ({
      ...settings,
      intermediateLocationId,
    })),
    ({ vehicleTypeId }) => vehicleTypeId,
  );

const getMappedInitialValues = (
  routeSequenceSettingsState: RouteSequenceSettingsState,
  side: string,
  intermediateLocationId?: number | null,
) => {
  return side
    ? {
        ...routeSequenceSettings(routeSequenceSettingsState, intermediateLocationId),
        serviceSide: side,
      }
    : { ...routeSequenceSettings(routeSequenceSettingsState, intermediateLocationId) };
};

export const routeSequenceMappedSettingsFormInitialValuesSelector = createSelector(getMappedInitialValues, identity);

const getInitialValues = (routeSequenceSettingsState: RouteSequenceSettingsState) => {
  return {
    settings: routeSequenceSettingsState.routeSequenceSettings.map(settings => {
      if (settings.vehicleTypeId === RESIDENTIAL_ID) {
        if (settings?.bothSidesServiceTimePerStop && typeof settings?.bothSidesServiceTimePerStop === 'object')
          settings.bothSidesServiceTimePerStop =
            settings?.bothSidesServiceTimePerStop?.getHours() * 3600 +
              settings?.bothSidesServiceTimePerStop?.getMinutes() * 60 +
              settings?.bothSidesServiceTimePerStop?.getSeconds() || 0;
        if (settings?.singleSideServiceTimePerStop && typeof settings?.singleSideServiceTimePerStop === 'object')
          settings.singleSideServiceTimePerStop =
            settings.singleSideServiceTimePerStop?.getHours() * 3600 +
              settings.singleSideServiceTimePerStop?.getMinutes() * 60 +
              settings.singleSideServiceTimePerStop?.getSeconds() || 0;
      }
      return settings;
    }),
  };
};

export const routeSequenceSettingsFormInitialValuesSelector = createSelector<
  RouteSequenceSettingsState,
  RouteSequenceFormValues,
  RouteSequenceFormValues
>(getInitialValues, identity);

export const routeSequenceSettingsFormInitialValues = (state: AppState) =>
  routeSequenceSettingsFormInitialValuesSelector(state.vendors.routeSequenceSettings);
