import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';

import CustomerLocationsClustersSource, {
  CUSTOMER_LOCATIONS_CLUSTERS_LAYER,
  CUSTOMER_LOCATIONS_CLUSTERS_SOURCE,
} from './CustomerLocationsClustersSource';
import { setCustomerLocationsMapViewport } from '../../ducks/mapControl';

type Props<P extends object = {}> = {
  map: mapboxgl.Map;
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
};

export default function CustomerLocationsClustersGL({ geoJSON, map }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', CUSTOMER_LOCATIONS_CLUSTERS_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [CUSTOMER_LOCATIONS_CLUSTERS_LAYER],
          })
          .filter(feature => feature.source === CUSTOMER_LOCATIONS_CLUSTERS_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(CUSTOMER_LOCATIONS_CLUSTERS_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setCustomerLocationsMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <CustomerLocationsClustersSource geoJSON={geoJSON} />;
}
