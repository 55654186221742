import React, { PureComponent, Fragment } from 'react';

import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { size } from 'lodash-es';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  ModalClose,
  ModalCloseIcon,
} from '../../../core/components/styled';
import { Table } from '../../../core/components';
import { VehicleTypesTableRow } from '../';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import routeAssistSettingsFormInitialValuesSelector from '../../../vendors/services/routeAssistSettingsFormInitialValuesSelector';
import translate from '../../../core/services/translate';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
  pristine?: boolean;
  routeAssistSettings: any;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class RouteAssistForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine, routeAssistSettings } = this.props;

    const routeAssistFormTableCells = [
      { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '80%' },
      { name: 'isActive', label: translate('common.active'), width: '20%' },
    ];

    return (
      <Fragment>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit}>
          {size(routeAssistSettings) ? (
            <Fragment>
              <Table cells={routeAssistFormTableCells} rowComponent={VehicleTypesTableRow} rows={routeAssistSettings} />
              <Grid centered>
                <GridColumn size="5/12">
                  <ButtonSet margin="medium no">
                    <Button type="submit" color="primary">
                      {translate('common.save')}
                    </Button>
                  </ButtonSet>
                </GridColumn>
              </Grid>
            </Fragment>
          ) : (
            <Message padding="sMedium sMedium medium sMedium">{translate('vehicles.noVehiclesConfigured')}</Message>
          )}
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: (routeAssistSettingsFormInitialValuesSelector as any)(state.vendors.routeAssistSettings),
  routeAssistSettings: state.vendors.routeAssistSettings.routeAssistSettings,
});

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    enableReinitialize: true,
    form: 'routeAssistForm',
    onSubmitFail: focusFirstInvalidField,
  })(RouteAssistForm),
);
