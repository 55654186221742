import { http } from '../../core/services/http';
import { RouteSequenceStatus } from 'src/routes/interfaces/Route';

export const routeSequence = (route: any) => http.post(`routeSequence/${route.routeId}/startSequence`, route);

export const loadRouteSequenceStatus = (routeIds: any[], sequenceSourceTypeId: number) =>
  http
    .get('routeSequence/status', { params: { routeIds: routeIds.join(','), sequenceSourceTypeId } })
    .then(response => response.data);

export const loadRouteSequenceStatusByVendor = (vendorId: number, sequenceSourceTypeId: number) =>
  http
    .get(`vendor/${vendorId}/routeSequence/status`, { params: { sequenceSourceTypeId } })
    .then(response => response.data);

export const loadRouteSequence = (routeId: number | string, sequenceSourceTypeId: number) =>
  http.get(`routeSequence/${routeId}?sequenceSourceTypeId=${sequenceSourceTypeId}`).then(response => response.data);

export const updateRouteSequenceStatus = (data: RouteSequenceStatus) => http.post('/routeSequence/status', data);

export const loadRouteSequenceHistory = (routeId: number, sequenceSourceTypeId: number) =>
  http
    .get(`routeSequence/${routeId}/history?sequenceSourceTypeId=${sequenceSourceTypeId}`)
    .then(response => response.data);
