import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { RoutePlannerMetric } from 'src/routes/interfaces/routePlanner/RoutePlannerMetric';
import {
  loadRoutePlannerRouteMetricsSettings as doLoadRoutePlannerMetricsSettings,
  saveRoutePlannerRouteMetricsSettings as doSaveRoutePlannerMetricsSettings,
  loadRoutePlannerRouteTemplateMetricsSettings as doLoadRoutePlannerRouteTemplateMetricsSettings,
  saveRoutePlannerRouteTemplateMetricsSettings as doSaveRoutePlannerRouteTemplateMetricsSettings,
} from 'src/routes/services/routePlannerMetrics';
import { findIndex, sortBy } from 'lodash-es';

// Constants
const START_LOAD_ROUTE_METRICS_SETTINGS = 'routePlanner/routeMetricsSettings/START_LOAD_ROUTE_METRICS_SETTINGS';
const COMPLETE_LOAD_ROUTE_METRICS_SETTINGS = 'routePlanner/routeMetricsSettings/COMPLETE_LOAD_ROUTE_METRICS_SETTINGS';
const FAIL_LOAD_ROUTE_METRICS_SETTINGS = 'routePlanner/routeMetricsSettings/FAIL_LOAD_ROUTE_METRICS_SETTINGS';
const REORDER_ROUTE_METRICS_SETTINGS = 'routePlanner/routeMetricsSettings/REORDER_ROUTE_METRICS_SETTINGS';
const TOGGLE_ROUTE_METRICS_SETTINGS = 'routePlanner/routeMetricsSettings/TOGGLE_ROUTE_METRICS_SETTINGS';
const START_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS =
  'routePlanner/routeMetricsSettings/START_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS';
const COMPLETE_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS =
  'routePlanner/routeMetricsSettings/COMPLETE_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS';
const FAIL_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS =
  'routePlanner/routeMetricsSettings/FAIL_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS';
const REORDER_ROUTE_TEMPLATE_METRICS_SETTINGS =
  'routePlanner/routeMetricsSettings/REORDER_ROUTE_TEMPLATE_METRICS_SETTINGS';
const TOGGLE_ROUTE_TEMPLATE_METRICS_SETTINGS =
  'routePlanner/routeMetricsSettings/TOGGLE_ROUTE_TEMPLATE_METRICS_SETTINGS';
const START_SAVE = 'routePlanner/routeMetricsSettings/START_SAVE';
const COMPLETE_SAVE = 'routePlanner/routeMetricsSettings/COMPLETE_SAVE';
const FAIL_SAVE = 'routePlanner/routeMetricsSettings/FAIL_SAVE';
const RESET_ROUTE_METRICS_SETTINGS = 'routePlanner/routeMetricsSettings/RESET_ROUTE_METRICS_SETTINGS';
const RESET_ROUTE_TEMPLATE_METRICS_SETTINGS = 'routePlanner/routeMetricsSettings/RESET_ROUTE_TEMPLATE_METRICS_SETTINGS';

// Initial State
const initialState: RouteMetricsSettings = {
  isLoading: false,
  hasChangesToSave: false,
  routeMetricsSettings: [],
  routeTemplateMetricsSettings: [],
};

interface RouteMetricsSettings {
  isLoading: boolean;
  hasChangesToSave: boolean;
  routeMetricsSettings: RoutePlannerMetric[];
  routeTemplateMetricsSettings: RoutePlannerMetric[];
}

// Reducer
export const reducer = (state: RouteMetricsSettings = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_ROUTE_METRICS_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_METRICS_SETTINGS: {
      //  routeMetricsSettings order by orderNo
      const routeMetricsSettings = sortBy(action.data, 'orderNo');
      return update(state, {
        $merge: {
          isLoading: false,
          routeMetricsSettings,
        },
      });
    }

    case FAIL_LOAD_ROUTE_METRICS_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: false,
          routeMetricsSettings: [],
        },
      });
    case START_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS: {
      //  routeTemplateMetricsSettings order by orderNo
      const routeTemplateMetricsSettings = sortBy(action.data, 'orderNo');
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplateMetricsSettings,
        },
      });
    }
    case FAIL_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplateMetricsSettings: [],
        },
      });
    case REORDER_ROUTE_METRICS_SETTINGS: {
      // update position of routeMetricsSettings form oldIndex to newIndex and update orderNo of all routeMetricsSettings
      const routeMetricsSettings = update(state.routeMetricsSettings, {
        $splice: [
          [action.oldIndex, 1],
          [action.newIndex, 0, state.routeMetricsSettings[action.oldIndex]],
        ],
      });
      routeMetricsSettings.forEach((routeMetricsSetting, index) => {
        routeMetricsSetting.orderNo = index + 1;
      });
      return update(state, {
        $merge: {
          routeMetricsSettings,
          hasChangesToSave: true,
        },
      });
    }
    case REORDER_ROUTE_TEMPLATE_METRICS_SETTINGS: {
      // update position of routeTemplateMetricsSettings form oldIndex to newIndex and update orderNo of all routeTemplateMetricsSettings
      const routeTemplateMetricsSettings = update(state.routeTemplateMetricsSettings, {
        $splice: [
          [action.oldIndex, 1],
          [action.newIndex, 0, state.routeTemplateMetricsSettings[action.oldIndex]],
        ],
      });
      routeTemplateMetricsSettings.forEach((routeTemplateMetricsSetting, index) => {
        routeTemplateMetricsSetting.orderNo = index + 1;
      });
      return update(state, {
        $merge: {
          routeTemplateMetricsSettings,
          hasChangesToSave: true,
        },
      });
    }
    case TOGGLE_ROUTE_METRICS_SETTINGS: {
      const settingIndex = findIndex(state.routeMetricsSettings, { id: action.id });
      return update(state, {
        routeMetricsSettings: {
          [settingIndex]: {
            $merge: {
              isActive: action.isActive,
            },
          },
        },
        hasChangesToSave: {
          $set: true,
        },
      });
    }
    case TOGGLE_ROUTE_TEMPLATE_METRICS_SETTINGS: {
      const settingIndex = findIndex(state.routeTemplateMetricsSettings, { id: action.id });
      return update(state, {
        routeTemplateMetricsSettings: {
          [settingIndex]: {
            $merge: {
              isActive: action.isActive,
            },
          },
        },
        hasChangesToSave: {
          $set: true,
        },
      });
    }
    case START_SAVE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
          hasChangesToSave: false,
        },
      });
    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case RESET_ROUTE_METRICS_SETTINGS:
      return update(state, {
        $merge: {
          routeMetricsSettings: [],
        },
      });
    case RESET_ROUTE_TEMPLATE_METRICS_SETTINGS:
      return update(state, {
        $merge: {
          routeTemplateMetricsSettings: [],
        },
      });
    default:
      return state;
  }
};

// Actions Creators
const startLoadRouteMetricsSettings = () => ({
  type: START_LOAD_ROUTE_METRICS_SETTINGS,
});

const completeLoadRouteMetricsSettings = (data: RoutePlannerMetric[]) => ({
  type: COMPLETE_LOAD_ROUTE_METRICS_SETTINGS,
  data,
});

const failLoadRouteMetricsSettings = () => ({
  type: FAIL_LOAD_ROUTE_METRICS_SETTINGS,
});

const startLoadRouteTemplateMetricsSettings = () => ({
  type: START_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS,
});

const completeLoadRouteTemplateMetricsSettings = (data: RoutePlannerMetric[]) => ({
  type: COMPLETE_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS,
  data,
});

const failLoadRouteTemplateMetricsSettings = () => ({
  type: FAIL_LOAD_ROUTE_TEMPLATE_METRICS_SETTINGS,
});

export const reorderRouteMetricsSettings = (oldIndex: number, newIndex: number) => ({
  type: REORDER_ROUTE_METRICS_SETTINGS,
  oldIndex,
  newIndex,
});

export const reorderRouteTemplateMetricsSettings = (oldIndex: number, newIndex: number) => ({
  type: REORDER_ROUTE_TEMPLATE_METRICS_SETTINGS,
  oldIndex,
  newIndex,
});

export const toggleRouteMetricsSettings = (id: number, isActive: boolean) => ({
  type: TOGGLE_ROUTE_METRICS_SETTINGS,
  id,
  isActive,
});

export const toggleRouteTemplateMetricsSettings = (id: number, isActive: boolean) => ({
  type: TOGGLE_ROUTE_TEMPLATE_METRICS_SETTINGS,
  id,
  isActive,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const resetRoutePlannerRouteMetricsSettings = () => ({
  type: RESET_ROUTE_METRICS_SETTINGS,
});

export const resetRoutePlannerRouteTemplateMetricsSettings = () => ({
  type: RESET_ROUTE_TEMPLATE_METRICS_SETTINGS,
});

export const loadRoutePlannerRouteMetricsSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteMetricsSettings());
  const loadRoutePlannerRouteMetricsSettingsPromise = doLoadRoutePlannerMetricsSettings(vendorId);

  loadRoutePlannerRouteMetricsSettingsPromise
    .then((response: RoutePlannerMetric[]) => {
      dispatch(completeLoadRouteMetricsSettings(response));
    })
    .catch(() => {
      dispatch(failLoadRouteMetricsSettings());
    });

  return loadRoutePlannerRouteMetricsSettingsPromise;
};

export const loadRoutePlannerRouteTemplateMetricsSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteTemplateMetricsSettings());
  const loadRoutePlannerRouteTemplateMetricsSettingsPromise = doLoadRoutePlannerRouteTemplateMetricsSettings(vendorId);

  loadRoutePlannerRouteTemplateMetricsSettingsPromise
    .then((response: RoutePlannerMetric[]) => {
      dispatch(completeLoadRouteTemplateMetricsSettings(response));
    })
    .catch(() => {
      dispatch(failLoadRouteTemplateMetricsSettings());
    });

  return loadRoutePlannerRouteTemplateMetricsSettingsPromise;
};

export const saveRoutePlannerRouteMetricsSettings =
  (vendorId: number, data: RoutePlannerMetric[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveRoutePlannerRouteMetricsSettingsPromise = doSaveRoutePlannerMetricsSettings(vendorId, data);

    saveRoutePlannerRouteMetricsSettingsPromise
      .then(() => {
        dispatch(completeSave());
      })
      .catch(() => {
        dispatch(failSave());
      });

    return saveRoutePlannerRouteMetricsSettingsPromise;
  };

export const saveRoutePlannerRouteTemplateMetricsSettings =
  (vendorId: number, data: RoutePlannerMetric[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveRoutePlannerRouteTemplateMetricsSettingsPromise = doSaveRoutePlannerRouteTemplateMetricsSettings(
      vendorId,
      data,
    );

    saveRoutePlannerRouteTemplateMetricsSettingsPromise
      .then(() => {
        dispatch(completeSave());
      })
      .catch(() => {
        dispatch(failSave());
      });

    return saveRoutePlannerRouteTemplateMetricsSettingsPromise;
  };
