import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getCustomerImportStatusTypeValues } from '../services/customerImportStatusType';

// Constants
const START_LOAD_STATUSES = 'common/systemTypes/customerImportStatusTypeValues/START_LOAD_STATUSES';
const COMPLETE_LOAD_STATUSES = 'common/systemTypes/customerImportStatusTypeValues/COMPLETE_LOAD_STATUSES';
const FAIL_LOAD_STATUSES = 'common/systemTypes/customerImportStatusTypeValues/FAIL_LOAD_STATUSES';

interface State {
  isLoading: boolean;
  statusTypeValues: TechnicalType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial State
const initialState: State = {
  isLoading: false,
  statusTypeValues: [],
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD_STATUSES,
});

const completeLoad = (statusTypeValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_STATUSES,
  statusTypeValues,
});

const failLoad = () => ({
  type: FAIL_LOAD_STATUSES,
});

// Async Actions
export const loadCustomerImportStatusTypeValues = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getCustomerImportStatusTypeValuesPromise = getCustomerImportStatusTypeValues();
  getCustomerImportStatusTypeValuesPromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getCustomerImportStatusTypeValuesPromise;
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_STATUSES:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_STATUSES:
      return {
        ...state,
        isLoading: false,
      };
    case COMPLETE_LOAD_STATUSES:
      return {
        ...state,
        statusTypeValues: action.statusTypeValues,
        isLoading: false,
      };
    default:
      return state;
  }
};
