import React, { Fragment } from 'react';

import { Field, WrappedFieldArrayProps } from 'redux-form';

import { ServiceZoneDropdown } from '../..';
import { Facility } from '../../../../common/interfaces/Facility';
import { Dropdown, Input } from '../../../../core/components';
import { TableCell, TableRow } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';
import { VehicleDropdown } from '../../../../fleet/components';
import { Route } from '../../../interfaces/Route';

type RouteBuilderTableRowProps = WrappedFieldArrayProps<Route> & {
  vehicleTypeId: number;
  truckYardFacilities: Facility[];
  disposalFacilities: Facility[];
  change: any;
  /** TODO Intermediate Facility for Route Builder */
  // disposalFacilities: Facility[],
};

const RouteBuilderTableRow = ({
  change,
  fields,
  truckYardFacilities,
  disposalFacilities,
  vehicleTypeId,
}: /** TODO Intermediate Facility for Route Builder */
// disposalFacilities,

RouteBuilderTableRowProps) => {
  const onStartingLocationChange = (name: string, value: number) => {
    change(name, value);
  };
  let customerLocations = [] as any[];
  if (!!truckYardFacilities && !!disposalFacilities)
    customerLocations = [...truckYardFacilities, ...disposalFacilities];

  return (
    <Fragment>
      {fields.map((route, index) => (
        <TableRow key={index}>
          <TableCell width="20%" vertical align="top" padding="xSmall no xSmall small">
            <Field component={Input} label={translate('routes.routeName')} name={`${route}.routeName`} type="text" />
          </TableCell>

          <TableCell width="20%" vertical align="top" padding="xSmall no xSmall small">
            <Field
              component={Dropdown}
              isClearable
              label={translate('routes.startingLocation')}
              name={`${route}.startingLocationId`}
              options={truckYardFacilities}
              onChange={(value: any) => onStartingLocationChange(`${route}.endingLocationId`, value)}
              width="100%"
            />
          </TableCell>

          <TableCell width="20%" vertical align="top" padding="xSmall no xSmall small">
            <Field
              component={Dropdown}
              isClearable
              label={translate('routes.endingLocation')}
              name={`${route}.endingLocationId`}
              options={customerLocations}
              width="100%"
            />
          </TableCell>

          {/** TODO Intermediate Facility for Route Builder */}
          {/*
          <TableCell width="25%" vertical="top" padding="xSmall no xSmall small">
            <Field
              component={Dropdown}
              name={`${route}.intermediateLocationId`}
              props={{
                isClearable: true,
                width: '100%',
                label: translate('routes.intermediateFacility'),
                options: disposalFacilities.map(facility => ({
                  label: facility.name,
                  value: facility.locationId,
                })),
              }}
            />
          </TableCell>
          */}

          <TableCell width="20%" vertical align="top" padding="xSmall no xSmall small">
            <Field
              component={ServiceZoneDropdown}
              dropdownProps={{ isClearable: true, width: '100%' }}
              label={translate('routes.serviceZone')}
              name={`${route}.serviceZoneId`}
            />
          </TableCell>

          <TableCell width="20%" vertical align="top" padding="xSmall small">
            <Field
              component={VehicleDropdown}
              dropdownProps={{ isClearable: true, width: '100%' }}
              name={`${route}.vehicleId`}
              vehicleTypeId={vehicleTypeId}
              withLabel
              shouldGroupByAvailability={true}
            />
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};

export default RouteBuilderTableRow;
