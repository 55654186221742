import React from 'react';

import { map } from 'lodash-es';

import { Dropdown, TypedField } from '../../../core/components';
import { FLEET_MAINTENANCE_OPTIONS } from 'src/common/constants';
import { TableCell, TableRow } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface Props {}

const FleetMaintenanceTableRow: React.FC<Props> = () => {
  const fleetMaintenanceOptions = map(FLEET_MAINTENANCE_OPTIONS, option => ({
    value: option.id,
    label: option.name,
  }));

  return (
    <TableRow>
      <TableCell width="40%">{translate('vendors.prePostTripIssues')}</TableCell>
      <TableCell width="60%">
        <TypedField
          name="prePostTripIssues"
          component={Dropdown}
          props={{
            margin: 'no',
            options: fleetMaintenanceOptions,
            width: '50%',
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default FleetMaintenanceTableRow;
