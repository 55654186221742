import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Cookie from 'js-cookie';

import { AppState } from 'src/store';
import { currentUserIdSelector, currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { INSIGHTS_COOKIE_DATE_KEY, INSIGHTS_COOKIE_START_DATE_KEY } from 'src/insights/constants';
import { loadDailyReportTypes, loadReportsList } from '../../ducks';
import { loadDataRetention } from 'src/vendors/ducks';
import { loadDrivers, loadVehicles, loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadUsers } from 'src/vendors/ducks/users';
import { loadVideoRequests, loadVideoStatuses } from 'src/routes/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { YESTERDAY, TODAY_FORMATTED } from 'src/core/constants';
import BetaReportsPage from './BetaReportsPage';
import translate from '../../../core/services/translate';

interface Params {
  date: string;
  videoId: string;
}

interface Props extends RouteComponentProps<Params> {
  loadDailyReportTypes: DuckFunction<typeof loadDailyReportTypes>;
  loadDataRetention: DuckFunction<typeof loadDataRetention>;
  loadDrivers: DuckFunction<typeof loadDrivers>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadReportsList: DuckFunction<typeof loadReportsList>;
  loadUsers: DuckFunction<typeof loadUsers>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadVideoRequests: DuckFunction<typeof loadVideoRequests>;
  loadVideoStatuses: DuckFunction<typeof loadVideoStatuses>;
  userId: string;
  vendorId: number;
  videoId?: string;
  videoRequestDate?: Date | string;
}

const BetaReportsPageResolver: React.FC<Props> = ({
  loadDailyReportTypes,
  loadDataRetention,
  loadDrivers,
  loadFiltersSettings,
  loadReportsList,
  loadUsers,
  loadVehicles,
  loadVehicleTypesForVendor,
  loadVideoRequests,
  loadVideoStatuses,
  userId,
  vendorId,
  videoId,
  videoRequestDate,
}) => {
  const videoRequestStartDate = videoRequestDate || Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY) || YESTERDAY;
  const videoRequestEndDate = videoRequestDate || Cookie.get(INSIGHTS_COOKIE_DATE_KEY) || TODAY_FORMATTED;

  const loadDependencies = () => {
    return Promise.all([
      loadDailyReportTypes(vendorId),
      loadDrivers(vendorId),
      loadFiltersSettings(vendorId, userId),
      loadReportsList(vendorId),
      loadUsers(vendorId),
      loadVehicles(vendorId, true),
      loadVehicleTypesForVendor(vendorId),
      loadVideoRequests(vendorId, userId, videoRequestStartDate, videoRequestEndDate),
      loadVideoStatuses(),
      loadDataRetention(),
    ]);
  };

  return (
    <Fragment>
      <DocumentTitle>{translate('insights.dataExport')}</DocumentTitle>
      <Resolver
        successComponent={BetaReportsPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        successProps={{ videoId, videoRequestEndDate, videoRequestStartDate }}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const { location } = ownProps;
  const { date, videoId } = getQueryParams(location.search);

  return {
    userId: currentUserIdSelector(state.account.login) as any,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    videoId,
    videoRequestDate: date,
  };
};

const mapDispatchToProps = {
  loadDailyReportTypes,
  loadDataRetention,
  loadDrivers,
  loadFiltersSettings,
  loadReportsList,
  loadUsers,
  loadVehicles,
  loadVehicleTypesForVendor,
  loadVideoRequests,
  loadVideoStatuses,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BetaReportsPageResolver));
