import { FC, MouseEvent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { map, find, get } from 'lodash-es';

import { ActionButtonTooltip, Table } from 'src/core/components';
import { CollapsiblePageSection } from 'src/common/components';
import { ContactsEditorModalResolver } from 'src/customers/components/modals';
import {
  createSuccessNotification,
  createErrorNotificationIncludingTechnicalMessage,
} from 'src/core/services/createNotification';
import { loadContacts, deleteContact } from 'src/customers/ducks/contacts';
import { loadContactTypes } from 'src/common/ducks';
import { Panel, Button } from 'src/core/components/styled';
import { SectionTableMoreIndicatorWrapper, SectionTableWrapper } from 'src/common/components/styled';
import { useContainerScrollAtRockBottom } from 'src/common/utils/collapsiblePageSectionHooks';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import ContactsTableRow from 'src/customers/components/pages/ContactsTableRow';
import translate from 'src/core/services/translate';

interface Props extends RouteComponentProps {
  customerId: number;
  customerName: string;
  locationId?: number;
  title: string;
}

const ContactsPageSection: FC<Props> = ({ customerId, customerName, locationId, title }) => {
  const dispatch = useDispatch();

  const [isContactsEditorModalOpen, setIsContactsEditorModalOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(0);

  const isLoading = useSelector(state => state.customers.contacts.isLoading);
  const isDeleting = useSelector(state => state.customers.contacts.isDeleting);
  const contacts = useSelector(state => state.customers.contacts.contacts) || [];
  const contactTypes = useSelector(state => state.common.contactTypes.contactTypes) || [];

  const { containerHasMaximumScroll, setRef, onScroll } = useContainerScrollAtRockBottom(contacts.length);

  const openDeleteContactModal = async (contactId: number) => {
    if (!(await confirm(translate('common.contact.alertMessages.confirmDeleteContact')))) return;

    deleteContact(contactId)(dispatch)
      .then(() => {
        createSuccessNotification(`${translate('common.contact.alertMessages.contactDeleted')}`);

        loadContacts(customerId, locationId)(dispatch);
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          `${translate('common.contact.alertMessages.contactDeleteError')}`,
        );
      });
  };

  const openContactsEditorModal = (contactId: number, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsContactsEditorModalOpen(true);
    setSelectedContactId(contactId);
  };

  const closeContactsEditorModal = async (pristine: boolean) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) return;

    setIsContactsEditorModalOpen(false);
    setSelectedContactId(0);
  };

  const contactsTableCells = [
    {
      name: 'name',
      label: translate('common.contact.contacts'),
      width: '15%',
      sortable: true,
    },
    {
      name: 'role',
      label: translate('common.contact.role'),
      width: '15%',
    },
    {
      name: 'emailAddress',
      label: translate('common.contact.email'),
      width: '15%',
      sortable: true,
    },
    {
      name: 'phone',
      label: translate('common.contact.primaryPhone'),
      width: '15%',
      sortable: true,
    },
    {
      name: 'fax',
      label: translate('common.contact.fax'),
      width: '15%',
      sortable: true,
    },
    {
      name: 'notifications',
      label: translate('common.contact.notifications'),
      width: '15%',
      sortable: true,
    },
    {
      name: 'options',
      label: translate('common.options'),
      width: '10%',
      align: 'right',
      noPaddingRight: true,
    },
  ];

  useEffect(() => {
    if (!customerId) {
      return;
    }

    loadContacts(customerId, locationId)(dispatch);
    loadContactTypes()(dispatch);
  }, [dispatch, customerId, locationId]);

  const formattedContacts = map(contacts, contact => ({
    ...contact,
    contactTypeNames: map(contact.contactTypeIds, contactTypeId =>
      get(find(contactTypes, { id: contactTypeId }), 'name'),
    ),
  }));

  return (
    <Panel padding="small">
      <CollapsiblePageSection
        title={title}
        headerAction={
          <Button
            margin="no no no medium"
            line
            color="primary"
            onClick={(event: MouseEvent<HTMLButtonElement>) => openContactsEditorModal(0, event)}
          >
            {translate('common.contact.addContact')}
          </Button>
        }
        sectionLoading={isLoading || isDeleting}
        sectionHasBorder
        sectionDisabled={!contacts.length}
        headerActionVisible={true}
        subtitle={
          !contacts.length
            ? translate('common.contact.noContacts')
            : translate('common.contact.noOfContacts', { count: contacts.length })
        }
        content={
          <>
            {!!contacts.length && (
              <SectionTableWrapper ref={setRef} onScroll={onScroll} padding="medium">
                <Table
                  cells={contactsTableCells}
                  rows={formattedContacts}
                  rowComponent={ContactsTableRow}
                  rowProps={{
                    contactTypes,
                    openDeleteContactModal: openDeleteContactModal,
                    openContactEditorModal: openContactsEditorModal,
                  }}
                />
              </SectionTableWrapper>
            )}

            {!!contacts.length && (
              <SectionTableMoreIndicatorWrapper disabled={containerHasMaximumScroll}>
                <ActionButtonTooltip iconSize="mLarge" icon="chevronCircleDown" />
              </SectionTableMoreIndicatorWrapper>
            )}
          </>
        }
      />

      {!!isContactsEditorModalOpen && (
        <ContactsEditorModalResolver
          onClose={closeContactsEditorModal}
          customerId={customerId}
          locationId={locationId}
          contactId={selectedContactId}
          customerName={customerName}
        />
      )}
    </Panel>
  );
};

export default withRouter(ContactsPageSection);
