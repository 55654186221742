import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Devices, loading } from '../../../core/styles';
import theme from '../../../core/styles/theme';

import loginBanner from '../../assets/loginBanner.png';
import RubiconLogo from '../../assets/rubiconLogoBlackOnWhite.svg';

export const LoginPageWrapper = styled.div`
  height: auto;
  width: 100%;
  background: ${theme.brandWhite};
  display: block;
`;

export const RubiconHorizontalLogo = styled.div`
  width: 165px;
  height: 30px;
  margin: 30px 0;
  background: url(${RubiconLogo});
  display: block;
  @media screen and (max-width: 1640px) {
    margin: 30px;
  }
  @media ${Devices.laptop} {
    margin: 30px;
  }
`;

export const LoginAreaWrapper = styled.div`
  height: auto;
  width: 100%;
  background: ${theme.brandWhite};
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 0 0 50px;
`;

export const LoginFormWrapper = styled.div<{ isLoading?: boolean }>`
  width: 35%;
  min-height: 500px;
  display: inline-block;
  margin: auto;
  padding: 50px;
  border-left: 3px solid ${theme.gray};
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  @media ${Devices.laptop} {
    width: 80%;
    box-shadow: none;
    border: none;
  }
  @media ${Devices.tablet} {
    width: 100%;
  }

  ${props =>
    props.isLoading &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: ${transparentize(0.05, theme.grayLighter)};
      }

      &:after {
        position: absolute;
        content: '';
        top: 30%;
        left: 48%;
        z-index: 1000;
        justify-items: center;
        margin: -13px 0 0 -13px;
        ${loading('26px')};
      }
    `};
`;

export const LoginPageBanner = styled.div`
  width: 65%;
  min-height: 500px;
  padding: 40px;
  display: inline-block;
  vertical-align: top;
  background: url(${loginBanner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${Devices.tablet} {
    width: 100%;
  }
  @media ${Devices.mobile} {
    background-position: 60% center;
  }
`;

export const LoginBannerTitle = styled.h1`
  font-size: 44px;
  color: ${theme.brandWhite};
  font-weight: 800;
  line-height: 54px;
  width: 60%;
  user-select: none;
  margin: 0;
  @media ${Devices.tablet} {
    width: 75%;
    font-size: 36px;
    line-height: 44px;
  }
  @media ${Devices.mobile} {
    width: 96%;
    font-size: 30px;
    line-height: 40px;
  }
`;

export const LoginBannerSubTitle = styled.p`
  font-size: 14px;
  color: ${theme.brandWhite};
  font-weight: 500;
  line-height: 18px;
  margin: 100px 0 0;
  user-select: none;
  @media ${Devices.tablet} {
    margin: 120px 0 0;
  }
  @media ${Devices.mobile} {
    margin: 140px 0 0;
  }
`;

interface LoginFormLinkProps {
  isCenter?: string;
}
export const LoginFormLink = styled(Link)<LoginFormLinkProps>`
  font-size: 12px;
  line-height: 15px;
  color: ${theme.brandGreenDark};
  display: block;
  margin-top: 15px;
  ${props =>
    props.isCenter &&
    css`
      text-align: center;
    `};
`;

export const SiteWidth = styled.div`
  width: 1250px;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 1640px) {
    width: 100%;
  }
  @media ${Devices.laptop} {
    width: 100%;
  }
`;

export const LoginFormTitle = styled.h1`
  margin-bottom: 60px;
  text-align: center;
  font-size: 22px;
  line-height: 24px;
`;

export const FooterMenuContainer = styled.div`
  display: block;
  background: #f6f6f6;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const FooterLink = styled.a`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  padding: 0 20px 0 20px;
  color: #009d90;
  border-right: 1px solid #009d90;
  &:nth-child(3) {
    border-right: 0px;
  }
`;

export const ServiceCarouselWrapper = styled.div`
  position: relative;
  width: 65%;
  @media ${Devices.laptop} {
    width: 100%;
    margin-bottom: 500px;
  }
  @media ${Devices.mobile} {
    margin-bottom: 700px;
  }
`;
