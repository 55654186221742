import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from 'src/core/components/styled/Box';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { Link } from 'react-router-dom';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
  MapGLPopupSubtitle,
} from 'src/common/components/map/MapGLPopup';
import { getVehicleIconType } from 'src/common/components/VehiclePositionMarker';
import { time } from 'src/utils/services/formatter';
import { useSelector } from 'src/core/hooks/useSelector';
import { VEHICLE_ICONS, VEHICLE_STATUSES } from 'src/common/constants';
import { VehiclePosition } from 'src/routes/components/mapWithTimeline/Interfaces';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

type Props = {
  currentVehiclePositions?: VehiclePosition[];
};

export default function RouteMapVehiclePositionsGLPopup({ currentVehiclePositions }: Props) {
  const dispatch = useDispatch();
  const { vehiclePositions } = useSelector(state => state.routes.routeMapVehicleData);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { vehicleTypes } = useSelector(state => state.fleet.vehicleTypes);

  const vehiclePosition = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.vehiclePositions) {
      return undefined;
    }

    return vehiclePositions.find(vehiclePosition => vehiclePosition.vehicleId === selectedFeature.id);
  }, [selectedFeature, vehiclePositions]);

  const vehicleSubTypeName = useMemo(() => {
    if (!routeSummary) return undefined;

    const vehicleSubTypes =
      vehicleTypes.find(vehicleType => vehicleType.id === routeSummary.vehicleTypeId)?.subTypes || [];
    return vehicleSubTypes.find(vehicleSubType => vehicleSubType.id === vehiclePosition?.vehicleSubTypeId)?.name;
  }, [routeSummary, vehicleTypes, vehiclePosition?.vehicleSubTypeId]);

  if (!routeSummary || !vehiclePosition) {
    return null;
  }

  const vehicleIconType = getVehicleIconType(
    routeSummary.vehicleTypeName,
    vehiclePosition.isStationary,
    vehiclePosition.isActive,
  );

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={(currentVehiclePositions ? currentVehiclePositions[0] : vehiclePosition).latitude}
      longitude={(currentVehiclePositions ? currentVehiclePositions[0] : vehiclePosition).longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle
          beforeTitleContent={
            <Box mr={8} width={48} height={48}>
              <img src={VEHICLE_ICONS[vehicleIconType]} width="48" height="48" alt={routeSummary.vehicleTypeName} />
            </Box>
          }
          title={vehiclePosition.vehicleName}
        />

        <MapGLPopupSubtitle subtitle={vehicleSubTypeName || '-'} />

        <MapGLPopupContent>
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.timestamp')}
            value={time(vehiclePosition.eventDateTime)}
          />

          {!!vehiclePosition.driverName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('drivers.driverName')}
              value={vehiclePosition.driverName}
            />
          )}

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('vehicles.vehicleStatus')}
            value={VEHICLE_STATUSES[vehiclePosition.vehicleStatus].name}
          />

          <LabeledDataView noWrap width="calc(100% - 10px)" size="small">
            <Box textDecoration="underline">
              <Link
                to={`/fleet/vehicles/${vehiclePosition.vehicleId}/inspection-data?&source=routes/route-tracker/${routeSummary.routeId}`}
              >
                {translate('vehicles.vehicleInspections.viewInspections')}
              </Link>
            </Box>
          </LabeledDataView>
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
