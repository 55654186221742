import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const CUSTOMER_SUBTYPE_LANDFILL = 1;
export const CUSTOMER_SUBTYPE_TRANSFER_STATION = 2;
export const CUSTOMER_SUBTYPE_MRF = 3;
export const CUSTOMER_SUBTYPE_WASTE_TO_ENERGY = 4;
export const CUSTOMER_SUBTYPE_COMPOST = 5;
export const CUSTOMER_SUBTYPE_DISPOSAL_OTHER = 6;
export const CUSTOMER_SUBTYPE_CONTAINER_YARD = 7;
export const CUSTOMER_SUBTYPE_TRUCK_YARD = 8;
export const CUSTOMER_SUBTYPE_WATER_FILLING_STATION = 9;
export const CUSTOMER_SUBTYPE_FUELING_STATION = 10;
export const CUSTOMER_SUBTYPE_MAINTENANCE = 11;
export const CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION = 12;
export const CUSTOMER_SUBTYPE_LANDFILL_OTHER = 13;

export const CUSTOMER_SUBTYPES = mapKeys(
  [
    { id: CUSTOMER_SUBTYPE_LANDFILL, name: translate('facilities.facilitySubTypes.landfill') },
    { id: CUSTOMER_SUBTYPE_TRANSFER_STATION, name: translate('facilities.facilitySubTypes.transferStation') },
    { id: CUSTOMER_SUBTYPE_MRF, name: translate('facilities.facilitySubTypes.mrf') },
    { id: CUSTOMER_SUBTYPE_WASTE_TO_ENERGY, name: translate('facilities.facilitySubTypes.wasteToEnergy') },
    { id: CUSTOMER_SUBTYPE_COMPOST, name: translate('facilities.facilitySubTypes.compost') },
    { id: CUSTOMER_SUBTYPE_DISPOSAL_OTHER, name: translate('facilities.facilitySubTypes.otherDisposalFacility') },
    { id: CUSTOMER_SUBTYPE_CONTAINER_YARD, name: translate('facilities.facilitySubTypes.containerYard') },
    { id: CUSTOMER_SUBTYPE_TRUCK_YARD, name: translate('facilities.facilitySubTypes.truckYard') },
    { id: CUSTOMER_SUBTYPE_WATER_FILLING_STATION, name: translate('facilities.facilitySubTypes.waterFillingStation') },
    { id: CUSTOMER_SUBTYPE_FUELING_STATION, name: translate('facilities.facilitySubTypes.fuelingStation') },
    { id: CUSTOMER_SUBTYPE_MAINTENANCE, name: translate('facilities.facilitySubTypes.maintenance') },
    { id: CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION, name: translate('facilities.facilitySubTypes.saltChemicalFillingStation') },
    { id: CUSTOMER_SUBTYPE_LANDFILL_OTHER, name: translate('facilities.facilitySubTypes.otherOperationalFacility') },
  ],
  'id',
);

export const CUSTOMER_SUBTYPES_OPERATIONAL = mapKeys(
  [
    { id: CUSTOMER_SUBTYPE_CONTAINER_YARD, name: translate('facilities.facilitySubTypes.containerYard') },
    { id: CUSTOMER_SUBTYPE_TRUCK_YARD, name: translate('facilities.facilitySubTypes.truckYard') },
    { id: CUSTOMER_SUBTYPE_WATER_FILLING_STATION, name: translate('facilities.facilitySubTypes.waterFillingStation') },
    { id: CUSTOMER_SUBTYPE_FUELING_STATION, name: translate('facilities.facilitySubTypes.fuelingStation') },
    { id: CUSTOMER_SUBTYPE_MAINTENANCE, name: translate('facilities.facilitySubTypes.maintenance') },
    { id: CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION, name: translate('facilities.facilitySubTypes.saltChemicalFillingStation') },
    { id: CUSTOMER_SUBTYPE_LANDFILL_OTHER, name: translate('facilities.facilitySubTypes.otherOperationalFacility') },
  ],
  'id',
);

export const CUSTOMER_SUBTYPES_DISPOSAL = mapKeys(
  [
    { id: CUSTOMER_SUBTYPE_LANDFILL, name: translate('facilities.facilitySubTypes.landfill') },
    { id: CUSTOMER_SUBTYPE_TRANSFER_STATION, name: translate('facilities.facilitySubTypes.transferStation') },
    { id: CUSTOMER_SUBTYPE_MRF, name: translate('facilities.facilitySubTypes.mrf') },
    { id: CUSTOMER_SUBTYPE_WASTE_TO_ENERGY, name: translate('facilities.facilitySubTypes.wasteToEnergy') },
    { id: CUSTOMER_SUBTYPE_COMPOST, name: translate('facilities.facilitySubTypes.compost') },
    { id: CUSTOMER_SUBTYPE_DISPOSAL_OTHER, name: translate('facilities.facilitySubTypes.otherDisposalFacility') },
  ],
  'id',
);
