export const EVERY_WEEK = 'EveryWeek';
export const EVERY_OTHER_WEEK = 'EveryOtherWeek';
export const MONTHLY = 'Monthly';
export const ON_CALL = 'OnCall';
export const X_TIMES_PER_WEEK = 'Xtimesperweek';
export const EVERY_X_DAYS = 'EveryXDays';
export const EVERY_X_WEEKS = 'EveryXWeeks';
export const MONTHLY_NON_SCHEDULE = 'MonthlyNonSchedule';
export const UNKNOWN = 'Unknown';

export const EVERY_1_MONTH = 1;
export const MONTHLY_ID = 3;
