import { FC } from 'react';

import { useSelector } from 'src/core/hooks/useSelector';
import StreetNetworkGLSource from './StreetNetworkGLSource';

const StreetNetworkGL: FC = () => {
  const streetNetwork = useSelector(state => state.routes.travelPathBuildAndEdit.streetNetwork);

  if (!streetNetwork) return null;

  return <StreetNetworkGLSource geoJSON={streetNetwork} />;
};

export default StreetNetworkGL;
