import React, { PureComponent } from 'react';

import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { BIN_COLORS } from 'src/common/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { MultiSelect, TypedField } from 'src/core/components';
import { MultiSelectProps } from 'src/core/components/MultiSelect';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) => {
  if (allOptionsSelected) {
    return translate('common.allContainerColors');
  }

  if (size(selectedOptions) === 1) {
    return BIN_COLORS[selectedOptions[0]]
      ? `${BIN_COLORS[selectedOptions[0]].name} ${translate('common.containerColor')}`
      : translate('common.containerColor');
  }

  return translate('common.xContainerColors', { selected: size(selectedOptions) });
};

interface Props extends WrappedFieldProps {
  placeholder?: string;
  multiSelectProps: Partial<MultiSelectProps>;
  binColors: TechnicalType[];
}

class BinColorsMultiSelect extends PureComponent<Props> {
  onChange = (_: unknown, value: number[]) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      binColors,
      multiSelectProps,
    } = this.props;

    const binColorsOptions = binColors.map(binColor => ({
      label: binColor.name,
      value: binColor.id,
    }));

    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          label: translate('common.containerColor'),
          placeholder,
          options: binColorsOptions,
          formatText,
          ...multiSelectProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  binColors: state.fleet.vehicle.binColors,
});

export default connect(mapStateToProps)(BinColorsMultiSelect);
