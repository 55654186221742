import { change, Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import React from 'react';

import { AppState } from '../../../store';
import { DuckFunction } from 'src/contracts/ducks';
import { EmailRecipient, EmailRecipientRemove, EmailRecipientRemoveIcon } from '../styled/EmailRecipient';
import { Input, Modal, UnconnectedCheckbox } from '../../../core/components';
import { isEmail } from 'src/utils/services/validator';
import { MAX_REPORT_RECIPIENTS } from 'src/insights/constants';
import { PanelSection, GridColumn, Button, Grid, Text } from 'src/core/components/styled';
import { updateReportsEmails, loadReportsList } from 'src/insights/ducks';
import translate from '../../../core/services/translate';

interface ComponentProps {
  change: any;
  closeModal(): void;
  emails: string[];
  id: number;
  vendorId: number;
}

interface PropsWithoutReduxForm extends ComponentProps {
  updateReportsEmails: DuckFunction<typeof updateReportsEmails>;
  loadReportsList: DuckFunction<typeof loadReportsList>;
  formValues?: any;
  currentAccountEmail: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const EditReportRecipientsModal: React.FC<Props> = ({
  change,
  closeModal,
  currentAccountEmail,
  emails: sentEmails,
  formValues,
  id,
  vendorId,
}) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [validationMessage, setValidationMessage] = React.useState<string>('');
  const [emailAddresses, setEmailAddresses] = React.useState<Array<string>>([]);

  const { recipientEmail } = formValues;
  const dispatch = useDispatch();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    updateReportsEmails(
      vendorId,
      id,
      emailAddresses,
    )(dispatch).then(() => {
      loadReportsList(vendorId)(dispatch).then(() => {
        setIsLoading(false);
        closeModal();
      });
    });
  };

  return (
    <Modal
      isLoading={isLoading}
      onClose={closeModal}
      padding="medium"
      size="small"
      title={translate('insights.reportRecipientsTitle')}
    >
      <form onSubmit={handleSubmit}>
        {!!sentEmails.length && (
          <PanelSection vertical>
            <Text align="center" margin="sMedium no small no">
              {translate('common.sentTo')}
            </Text>
            <Grid multiLine padding="no">
              {sentEmails.map((el: any, index: number) => {
                return (
                  <EmailRecipient disabled key={index}>
                    {el}
                  </EmailRecipient>
                );
              })}
            </Grid>
          </PanelSection>
        )}
        <PanelSection vertical padding="small no small no">
          <Text align="center" margin="xSmall no medium no">
            {translate('insights.addNewRecipients', {
              numberOfRecipients: emailAddresses && emailAddresses.length,
              maxRecipients: MAX_REPORT_RECIPIENTS,
            })}
          </Text>
          <UnconnectedCheckbox
            checked={isChecked}
            name="checkBox"
            margin="no no sMedium no"
            onChange={e => {
              if (e.target.checked && currentAccountEmail) {
                setEmailAddresses([...emailAddresses, currentAccountEmail]);
                setIsChecked(true);
              } else {
                setEmailAddresses(emailAddresses.filter(el => el !== currentAccountEmail));
                setIsChecked(false);
              }
            }}
            label={translate('common.emailToMe')}
          />
          <GridColumn verticalAlign="center" padding="no">
            <Field
              onChange={(e: any) => !e.target.value && setValidationMessage('')}
              name="recipientEmail"
              label={translate('insights.recipientEmail')}
              component={Input}
              props={{
                margin: `${validationMessage ? 'no no xSmall' : ''}`,
                id: 'insights-recipient-email',
              }}
            />

            <Button
              id="add-recipient-button"
              onClick={(e: any) => {
                e.preventDefault();

                if (isEmail(recipientEmail) === undefined) {
                  if (emailAddresses.includes(recipientEmail)) {
                    setValidationMessage(translate('insights.emailIsAlreadyAdded'));
                  } else {
                    setEmailAddresses([...emailAddresses, recipientEmail]);
                    setValidationMessage('');
                    change('recipientEmail', undefined);
                  }
                } else {
                  setValidationMessage(isEmail(recipientEmail));
                }
              }}
              line
              color="primary"
              margin="no xSmall no sMedium"
            >
              {translate('insights.addRecipient')}
            </Button>
          </GridColumn>
          {validationMessage && (
            <Text margin="no no sMedium no" color="alert">
              {validationMessage}
            </Text>
          )}
          <Grid multiLine padding="no">
            {emailAddresses.map((el, index) => {
              return (
                <EmailRecipient key={index}>
                  {el}
                  <EmailRecipientRemove
                    onClick={e => {
                      e.preventDefault();
                      setEmailAddresses(emailAddresses.filter((el, i) => i !== index));
                      setIsChecked(el === currentAccountEmail ? false : isChecked);
                    }}
                    id={`insights-remove-recipient-${index}`}
                  >
                    <EmailRecipientRemoveIcon />
                  </EmailRecipientRemove>
                </EmailRecipient>
              );
            })}
          </Grid>
        </PanelSection>{' '}
        <Grid centered>
          <GridColumn margin="ssMedium no no no" size="12" align="center">
            <Button onClick={closeModal} line color="primary">
              {translate('common.close')}
            </Button>
          </GridColumn>
          <GridColumn margin="ssMedium no no no" size="12" align="center">
            <Button color="primary">{translate('common.send')}</Button>
          </GridColumn>
        </Grid>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  formValues: getFormValues('editReportRecipients')(state) || {},
  currentAccountEmail: state.account.login.user.email,
});

const mapDispatchToProps = {
  change,
  loadReportsList,
  updateReportsEmails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'editReportRecipients',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditReportRecipientsModal),
);
