import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { Billing } from 'src/finance/interfaces/Billing';
import * as Services from 'src/finance/services/billing';

// Actions
const START_LOAD_BILLING = 'finance/billing/billing/START_LOAD_BILLING';
const COMPLETE_LOAD_BILLING = 'finance/billing/billing/COMPLETE_LOAD_BILLING';
const FAIL_LOAD_BILLING = 'finance/billing/billing/FAIL_LOAD_BILLING';
const START_CHANGE_STATUS = 'finance/billing/billing/START_CHANGE_STATUS';
const COMPLETE_CHANGE_STATUS = 'finance/billing/billing/COMPLETE_CHANGE_STATUS';
const FAIL_CHANGE_STATUS = 'finance/billing/billing/FAIL_CHANGE_STATUS';
const START_EXPORT_BILLS = 'finance/billing/billing/START_EXPORT_BILLS';
const COMPLETE_EXPORT_BILLS = 'finance/billing/billing/COMPLETE_EXPORT_BILLS';
const FAIL_EXPORT_BILLS = 'finance/billing/billing/FAIL_EXPORT_BILLS';
const RESET = 'finance/billing/billing/RESET';

// Initial state
const initialState: {
  billing: Billing;
  isLoadingBilling: boolean;
  isChangingStatus: boolean;
  isExportingBills: boolean;
} = {
  billing: {} as Billing,
  isLoadingBilling: false,
  isChangingStatus: false,
  isExportingBills: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_BILLING:
      return update(state, {
        $merge: {
          isLoadingBilling: true,
        },
      });

    case COMPLETE_LOAD_BILLING:
      return update(state, {
        $merge: {
          isLoadingBilling: false,
          billing: action.billing,
        },
      });

    case FAIL_LOAD_BILLING:
      return update(state, {
        $merge: {
          isLoadingBilling: false,
          billing: {} as Billing,
        },
      });

    case START_CHANGE_STATUS:
      return update(state, {
        $merge: {
          isChangingStatus: true,
        },
      });

    case COMPLETE_CHANGE_STATUS:
      return update(state, {
        $merge: {
          isChangingStatus: false,
        },
      });

    case FAIL_CHANGE_STATUS:
      return update(state, {
        $merge: {
          isChangingStatus: false,
        },
      });

    case START_EXPORT_BILLS:
      return update(state, {
        $merge: {
          isExportingBills: true,
        },
      });

    case COMPLETE_EXPORT_BILLS:
      return update(state, {
        $merge: {
          isExportingBills: false,
        },
      });

    case FAIL_EXPORT_BILLS:
      return update(state, {
        $merge: {
          isExportingBills: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoadBilling = () => ({
  type: START_LOAD_BILLING,
});

const completeLoadBilling = (billing: Billing) => ({
  type: COMPLETE_LOAD_BILLING,
  billing,
});

const failLoadBilling = () => ({
  type: FAIL_LOAD_BILLING,
});

const startChangeStatusToUnbilled = () => ({
  type: START_CHANGE_STATUS,
});

const completeChangeStatusToUnbilled = () => ({
  type: COMPLETE_CHANGE_STATUS,
});

const failChangeStatusToUnbilled = () => ({
  type: FAIL_CHANGE_STATUS,
});

const startExportBills = () => ({
  type: START_EXPORT_BILLS,
});

const completeExportBills = () => ({
  type: COMPLETE_EXPORT_BILLS,
});

const failExportBills = () => ({
  type: FAIL_EXPORT_BILLS,
});

export const loadBilling =
  (
    vendorId: number,
    startDate: StringOrDate,
    endDate: StringOrDate,
    billsSearchTerm?: string,
    billStatusId?: number,
    unbilledSearchTerm?: string,
    workOrderTypeId?: number,
    containerTypeId?: number,
    includeRates?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadBilling());
    const loadBillingPromise = Services.loadBilling(
      vendorId,
      startDate,
      endDate,
      billsSearchTerm,
      billStatusId,
      unbilledSearchTerm,
      workOrderTypeId,
      containerTypeId,
      includeRates,
    );
    loadBillingPromise
      .then((billing: Billing) => dispatch(completeLoadBilling(billing)))
      .catch(() => dispatch(failLoadBilling()));
    return loadBillingPromise;
  };

export const changeStatusToUnbilled = (vendorId: number, billId: number) => (dispatch: Dispatch) => {
  dispatch(startChangeStatusToUnbilled());
  const changeStatusToUnbilledPromise = Services.changeStatusToUnbilled(vendorId, billId);
  changeStatusToUnbilledPromise
    .then(() => dispatch(completeChangeStatusToUnbilled()))
    .catch(() => dispatch(failChangeStatusToUnbilled()));
  return changeStatusToUnbilledPromise;
};

export const exportBills =
  (searchTerm?: string, billStatusId?: number, startDate?: StringOrDate, endDate?: StringOrDate) =>
  (dispatch: Dispatch) => {
    dispatch(startExportBills());
    const exportBillsPromise = Services.exportBills(searchTerm, billStatusId, startDate, endDate);
    exportBillsPromise.then(() => dispatch(completeExportBills())).catch(() => dispatch(failExportBills()));
    return exportBillsPromise;
  };

export const releaseBills = (billIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startChangeStatusToUnbilled());
  const billStatusesPromise = Services.releaseBills(billIds);
  billStatusesPromise
    .then((result) => {
      dispatch(completeChangeStatusToUnbilled());
      return result;
    })
    .catch(() => dispatch(failChangeStatusToUnbilled()));
  return billStatusesPromise;
};

export const resetBilling = () => ({
  type: RESET,
});
