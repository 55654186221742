import styled, { css } from 'styled-components';
import { mapper } from '../../../utils/styles';

const MapContainer = styled.div<{ fullscreen?: boolean; size?: string }>`
  width: 100%;
  position: relative;

  *:focus {
    outline: none;
  }

  ${props =>
    props.fullscreen
      ? css`
        height 100%;
        min-height: none;
        max-height: none;
        position: relative;
      `
      : css`
          height: ${mapper(
            props.size,
            {
              small: '300px',
              medium: '400px',
              large: '500px',
            },
            'medium',
          )};
          min-height: 100%;
          max-height: 100%;
        `};
`;

export default MapContainer;
