import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { formValueSelector, reset } from 'redux-form';

import { AppState } from '../../../store';
import {
  ButtonSet,
  Button,
  IconButton,
  IconButtonIcon,
  Text,
  TableRow,
  TableCell,
  Popover,
} from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DeclineOpportunityModal, AcceptOpportunityModal, RequestChangesForOpportunityModal } from '../modal';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { OPPORTUNITIES } from '../../../account/constants';
import {
  OpportunityInfoFillIcon,
  OpportunityCompleteIcon,
  OpportunityPendingIcon,
  OpportunityDisabledIcon,
  OpportunityEditIcon,
  OpportunityEditIconPopUpOpen,
  OpportunityContainer,
  OpportunityLink,
} from '../styled';
import { PopoverWrapper } from '../../../core/components';
import { PermissionGuard } from '../../../account/components';
import { showNotesModal } from '../../../fleet/ducks';
import translate from '../../../core/services/translate';
import { updateHaulerOpportunity } from '../../ducks';
import { YES, NO, AWAITING_HAULER_APPROVAL, REQUEST_CHANGE, REQUEST_CUSTOMER_APPROVAL } from '../../constants/status';

interface ComponentProps extends RouteComponentProps {
  businessType?: string;
  caseId: number;
  customerName: string;
  customerAddress?: string;
  daysLeft?: string;
  endDate?: string;
  equipmentType?: string;
  frequency?: string;
  hasWorkOrderNotes: boolean;
  isActionRequired: boolean;
  material?: string;
  quantity?: string;
  responseStatus?: string;
  status?: string;
  startDate: string;
  workflowStatus?: string;
  workOrder: string;
}

interface Props extends ComponentProps {
  activitiBaseUrl?: string;
  offer: any;
  response: any;
  readonly?: boolean;
  reset: any;
  requestedChanges: any;
  userId: number;
  vendorId?: number;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  showNotesModal: DuckAction<typeof showNotesModal>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
}

interface State {
  isAcceptOpportunityModalOpen: boolean;
  isRequestChangesForOpportunityModalOpen: boolean;
  isDeclineOpportunityModalOpen: boolean;
  response?: any;
  requestedChanges: { service?: any };
}

class OpportunityFormTableRow extends PureComponent<Props, State> {
  readonly state: State = {
    isAcceptOpportunityModalOpen: false,
    isRequestChangesForOpportunityModalOpen: false,
    isDeclineOpportunityModalOpen: false,
    response: undefined,
    requestedChanges: { service: undefined },
  };

  // Accept
  openAcceptOpportunityModal = (event: any) => {
    this.setState({ isAcceptOpportunityModalOpen: true });
    event.stopPropagation();

    return false;
  };

  closeAcceptOpportunityModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('acceptOpportunity');
    this.setState({ isAcceptOpportunityModalOpen: false });
  };

  // Request Changes
  openRequestChangesOpportunityModal = (event: any, value: any) => {
    this.setState({ isRequestChangesForOpportunityModalOpen: true });
    this.setState({ response: value });
  };

  closeRequestChangesOpportunityModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('opportunityForm');
    this.setState({ isRequestChangesForOpportunityModalOpen: false });
  };

  // Decline
  openDeclineOpportunityModal = () => {
    this.setState({ isDeclineOpportunityModalOpen: true });
  };

  closeDeclineOpportunityModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('declineOpportunity');
    this.setState({ isDeclineOpportunityModalOpen: false });
  };

  splitRequestedChanges = (array: any[], length: number) =>
    array.reduce((result, item, index) => {
      if (index % length === 0) result.push([]);
      result[Math.floor(index / length)].push(item);
      return result;
    }, []);

  openNotesModal = () => {
    const { customerName, customerAddress, offer, showNotesModal, workOrder } = this.props;
    showNotesModal({
      workOrder,
      serviceDate: offer.deliveryDate,
      title: customerName,
      subtitle: customerAddress,
      isWorkOrderNote: true,
    });
  };
  render() {
    const {
      activitiBaseUrl,
      businessType,
      caseId,
      customerName,
      customerAddress,
      daysLeft,
      endDate,
      equipmentType,
      frequency,
      hasWorkOrderNotes,
      isActionRequired,
      material,
      offer,
      quantity,
      responseStatus,
      response,
      requestedChanges,
      readonly,
      status,
      workflowStatus,
    } = this.props;
    return (
      <TableRow key={caseId}>
        <TableCell vertical width="19%">
          {customerName && (
            <Text block weight="medium" margin="no no xxSmall">
              {customerName}
            </Text>
          )}
          {businessType && (
            <Text block margin="no no xxSmall" weight="medium" size="small">
              ({businessType})
            </Text>
          )}
          {customerAddress && (
            <Text block margin="no no xxSmall" weight="light" size="small">
              {` ${customerAddress}`}
            </Text>
          )}
          {activitiBaseUrl && (
            <Text block margin="no no xxSmall" weight="medium" size="small">
              {translate('opportunity.opportunities.activitiCase')}:{' '}
              <OpportunityLink target="blank" href={`${activitiBaseUrl}${caseId}`}>
                {`${caseId}`}
              </OpportunityLink>
            </Text>
          )}
        </TableCell>
        <TableCell width="15%" vertical>
          {equipmentType && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="grayDarker">
                {translate('common.equipmentType')}:{' '}
              </Text>

              <Text weight="normal" size="small" singleLine>
                {equipmentType}
              </Text>
            </Fragment>
          )}
          {material && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="grayDarker">
                {translate('common.material')}:{' '}
              </Text>
              <Text weight="normal" size="small" singleLine>
                {material}
              </Text>
            </Fragment>
          )}
          {frequency && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="grayDarker">
                {translate('opportunity.frequency')}:{' '}
              </Text>
              <Text weight="normal" size="small" singleLine>
                {frequency}
              </Text>
            </Fragment>
          )}
          {quantity && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="grayDarker">
                {translate('opportunity.quantity')}:{' '}
              </Text>
              <Text weight="normal" size="small" singleLine>
                {quantity}
              </Text>
            </Fragment>
          )}
        </TableCell>
        <TableCell width="16%" vertical padding="xSmall xSmall xSmall xSmall">
          {offer.deliveryDate && (
            <Fragment>
              <Text margin="no no xxSmall" weight="light" size="small" singleLine color="grayDarker">
                {translate('opportunity.requestedDeliveryDate')}:{' '}
              </Text>
              <Text weight="normal" size="small" singleLine>
                {offer.deliveryDate}
                {hasWorkOrderNotes && (
                  <IconButton
                    type="submit"
                    size="xSmall"
                    color="secondary"
                    margin="no"
                    onClick={() => this.openNotesModal()}
                  >
                    <IconButtonIcon
                      icon="page-content"
                      color="secondary"
                      size="xLarge"
                      margin="xxSmall no no xxSmall"
                    />
                  </IconButton>
                )}
              </Text>
            </Fragment>
          )}
          {endDate && (
            <Fragment>
              <Text margin="small no no" size="small" singleLine color="grayDarker">
                {translate('common.endDate')}:
              </Text>
              <Text weight="normal" size="small" singleLine>
                {endDate}
              </Text>
            </Fragment>
          )}
        </TableCell>
        <TableCell vertical width="15%" padding="xSmall xSmall xSmall xSmall">
          {offer.displayFees.map((fees: any) => (
            <Fragment key={fees.name}>
              <Text block margin="no no no" weight="light" size="small" color="grayDarker">
                {fees.name} :{' '}
              </Text>
              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {fees.value}
              </Text>
            </Fragment>
          ))}
        </TableCell>

        <TableCell width="20%">
          {!!response && (
            <Fragment>
              {(response === REQUEST_CHANGE || response === YES) && <OpportunityPendingIcon margin="sMedium no no" />}
              {response === NO && <OpportunityDisabledIcon margin="sMedium no no" />}
              <Text block weight="normal" margin="small no no" size="small">
                {status === AWAITING_HAULER_APPROVAL
                  ? translate('opportunity.opportunities.awaitingAcceptance')
                  : responseStatus}
                {response === REQUEST_CHANGE && workflowStatus !== REQUEST_CUSTOMER_APPROVAL && (
                  <PopoverWrapper
                    triggerButton={<OpportunityInfoFillIcon color="primary" margin="no" />}
                    popoverContent={
                      <Popover>
                        {this.splitRequestedChanges(requestedChanges.displayFees, 3).map(
                          (requestedChange: any, index: number) => (
                            <TableRow key={index} borderBottom="0px">
                              {requestedChange.map((fees: any) => (
                                <TableCell vertical padding="no no no xSmall" width="170px">
                                  <Text block margin="no no no" weight="light" size="small" color="grayDarker">
                                    {fees.name} :{' '}
                                  </Text>
                                  <Text margin="no no no" weight="normal" size="small">
                                    {fees.value}
                                  </Text>
                                </TableCell>
                              ))}
                            </TableRow>
                          ),
                        )}

                        <OpportunityEditIconPopUpOpen
                          title={translate('common.edit')}
                          onClick={
                            status !== AWAITING_HAULER_APPROVAL && !readonly
                              ? (this.openRequestChangesOpportunityModal as any)
                              : undefined
                          }
                        >
                          <OpportunityEditIcon margin="no no no" />
                        </OpportunityEditIconPopUpOpen>
                      </Popover>
                    }
                    size="xLarge"
                    width="16px"
                    height="18px"
                  />
                )}
                {isActionRequired && <IconButtonIcon icon="bell" color="alert" size="medium" margin="no xxSmall" />}
              </Text>
            </Fragment>
          )}
          {!response && (
            <Fragment>
              <OpportunityCompleteIcon margin="no no xxSmall" icon="complete" />
              <OpportunityContainer>
                <Text block weight="normal" margin="small no no" singleLine size="small">
                  {translate('common.open')}
                  {isActionRequired && <IconButtonIcon icon="bell" color="alert" size="mLarge" margin="no" />}
                </Text>
                <Text block weight="normal" margin="no no xxSmall" singleLine size="small" color="grayDarker">
                  {daysLeft}
                </Text>
              </OpportunityContainer>
            </Fragment>
          )}
        </TableCell>
        <TableCell vertical width="15%">
          <PermissionGuard permission={OPPORTUNITIES}>
            <ButtonSet vertical flex-direction="column">
              <Button
                id={`accept-${caseId}-opportunity-button`}
                background-color="transparent"
                color="primary"
                size="small"
                onClick={this.openAcceptOpportunityModal}
                disabled={readonly || workflowStatus === REQUEST_CUSTOMER_APPROVAL}
                margin="no no xSmall"
              >
                {translate('accept')}
              </Button>
              {this.state.isAcceptOpportunityModalOpen && (
                <AcceptOpportunityModal {...this.props} onCancel={this.closeAcceptOpportunityModal} />
              )}

              <Button
                id={`request-changes-${caseId}-opportunity-button`}
                color="primary"
                background-color="transparent"
                size="small"
                onClick={(event: any) => this.openRequestChangesOpportunityModal(event, 'RequestChanges')}
                line
                disabled={
                  readonly || status === AWAITING_HAULER_APPROVAL || workflowStatus === REQUEST_CUSTOMER_APPROVAL
                }
                margin="no no xSmall"
              >
                {translate('opportunity.requestChanges')}
              </Button>
              {this.state.isRequestChangesForOpportunityModalOpen && (
                <RequestChangesForOpportunityModal
                  {...this.props}
                  onCancel={this.closeRequestChangesOpportunityModal}
                  responseValue={this.state.response}
                />
              )}

              <Button
                id={`decline-${caseId}-opportunity-button`}
                color="alert"
                background-color="transparent"
                size="small"
                onClick={this.openDeclineOpportunityModal}
                line
                disabled={readonly || workflowStatus === REQUEST_CUSTOMER_APPROVAL}
                margin="no no xSmall"
              >
                {translate('autoDispatch.decline')}
              </Button>
              {this.state.isDeclineOpportunityModalOpen && (
                <DeclineOpportunityModal {...this.props} onCancel={this.closeDeclineOpportunityModal} />
              )}
            </ButtonSet>
          </PermissionGuard>
        </TableCell>
      </TableRow>
    );
  }
}

const formSelector = formValueSelector('opportunityForm');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  response: formSelector(state, `opportunities.settings${ownProps.caseId}.response`),
  offer: formSelector(state, `opportunities.settings${ownProps.caseId}.offer`),
  requestedChanges: formSelector(state, `opportunities.settings${ownProps.caseId}.requestedChanges`),
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
  activitiBaseUrl: state.opportunity.opportunity.activitiBaseUrl,
  readonly: state.opportunity.opportunity.readonly,
});

const mapDispatchToProps = {
  loadDispatchOpportunitiesCount,
  updateHaulerOpportunity,
  reset,
  showNotesModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpportunityFormTableRow));
