import { Field, getFormValues } from 'redux-form';
import { map } from 'lodash-es';
import React from 'react';

import { DEBRIS_UNIT_OF_MEASUREMENT, WATER_UNIT_OF_MEASUREMENT } from '../constants/materialExperience';
import { Dropdown, Switch, TypedField } from '../../core/components';
import { Grid, GridColumn, Separator, TableCell, TableRow } from '../../core/components/styled';
import { STREET_SWEEPER_FORM_NAME } from './forms/StreetSweepingForm';
import { StreetSweepingSettings } from '../interfaces/StreetSweepingSettings';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../core/services/translate';
import { PICTURE_REQUIREMENT_OPTIONS } from '../constants';

interface Props {
  label: string;
  name: string;
}

const MaterialExperienceTableRow: React.FC<Props> = ({ label, name }) => {
  const formValues = useSelector(getFormValues(STREET_SWEEPER_FORM_NAME)) as StreetSweepingSettings;

  const isWaterFillUpEnabled = formValues.waterFillUps;
  const isDisposalEnabled = formValues.loadsDumped;

  const isFeatureEnabled =
    (isWaterFillUpEnabled && name === 'waterFillUps') || (isDisposalEnabled && name === 'loadsDumped');

  const index = name === 'waterFillUps' ? 0 : 1;

  const dropdownOptions = [
    map(WATER_UNIT_OF_MEASUREMENT, ({ id, name }) => ({
      label: name,
      value: id,
    })),
    map(DEBRIS_UNIT_OF_MEASUREMENT, ({ id, name }) => ({
      label: name,
      value: id,
    })),
  ];

  const materialTypes = [
    translate('common.waterFill.materialTypeValues.water'),
    translate('common.loadsDumped.materialTypeValues.debris'),
  ];

  return (
    <>
      <TableRow>
        <TableCell width="80%" padding="small sMedium">
          {translate(label)}
        </TableCell>
        <TableCell width="20%">
          <Field component={Switch} name={name} />
        </TableCell>
      </TableRow>
      {isFeatureEnabled && (
        <>
          <Grid multiLine spaceBetween padding={'small no xSmall'}>
            <GridColumn size="6/12" padding="medium no no sMedium">
              {materialTypes[index]}
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name={`unitConfiguration[${index}].unitOfMeasureType.id`}
                component={Dropdown}
                props={{
                  label: translate('common.defaultUnitOfMeasurement'),
                  disabled: false,
                  margin: 'no',
                  options: dropdownOptions[index],
                }}
              />
            </GridColumn>
            <GridColumn size="6/12" padding="medium no no sMedium">
              {translate('common.picture')}
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name={`unitConfiguration[${index}].pictureRequirementType.id`}
                component={Dropdown}
                props={{
                  label: translate('vendors.requirePicture'),
                  disabled: false,
                  margin: 'no',
                  options: PICTURE_REQUIREMENT_OPTIONS,
                }}
              />
            </GridColumn>
          </Grid>
          <Separator padding="no" size={1} color="grayLight" />
        </>
      )}
    </>
  );
};

export default MaterialExperienceTableRow;
