import styled, { css } from 'styled-components';

interface Props {
  errorOnSingleLine?: boolean;
  errorTopPosition?: string;
}

const FormError = styled.span<Props>`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${props => props.theme.brandAlert};

  ${props =>
    props.errorOnSingleLine &&
    css`
      position: absolute;
      white-space: nowrap;
    `};

  ${props =>
    props.errorTopPosition &&
    css`
      top: ${props.errorTopPosition};
    `};
`;

export default FormError;
