import React, { PureComponent } from 'react';
import { fill, map, replace } from 'lodash-es';
import { WrappedFieldProps } from 'redux-form';
import { FieldProps } from './FieldProps';
import { Dropdown, TypedField } from '.';
import translate from '../services/translate';
import { DropDownProps } from './Dropdown';

interface Props extends WrappedFieldProps, FieldProps {
  dropdownProps: Partial<DropDownProps>;
}

class HourPicker extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;
    /* eslint no-bitwise: [2, { allow: ["~"] }] */
    const options = map(fill(Array(24 * 2), 0), (value, index) => {
      const option = replace(
        `0${index <= 25 ? ~~(index / 2) : ~~((index - 24) / 2)}:0${60 * ((index / 2) % 1)} ${index < 23 ? 'AM' : 'PM'}`,
        /\d(\d\d)/g,
        '$1',
      );
      return {
        label: option.replace('00:00', '12:00').replace('00:30', '12:30'),
        value: option,
      };
    });

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.from') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.from') : undefined),
          options,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default HourPicker;
