import { reduce, identity, set } from 'lodash-es';
import { createSelector } from 'reselect';

const getRecurringServicesInitialValues = (recurringServicesState: any) => ({
  workOrdersInfo: reduce(
    recurringServicesState,
    (
      initialValues,
      {
        workOrderIdentifier,
        workOrder,
        wcheduledDate,
        customerName,
        vendId,
        vendName,
        svcOrd,
        serviceFreq,
        serviceType,
        serviceDescr,
        material,
        action,
        status,
        confirmed,
        account,
        woTotal,
        rateCodes,
        scheduledDate,
        serviceTab,
      },
    ) =>
      set(initialValues, `settings${workOrderIdentifier}`, {
        workOrderIdentifier,
        workOrder,
        wcheduledDate,
        customerName,
        vendId,
        vendName,
        svcOrd,
        serviceFreq,
        serviceType,
        serviceDescr,
        material,
        action,
        status,
        confirmed,
        account,
        woTotal,
        rateCodes,
        scheduledDate,
        serviceTab,
      }),
    {},
  ),
});

const recurringServicesInitialValueSelector = createSelector(getRecurringServicesInitialValues, identity);

export default recurringServicesInitialValueSelector;
