import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { size } from 'lodash-es';

import { Modal, Table } from '../../../core/components';
import { ModalTitle, ModalSubtitle, ModalSection } from '../../../core/components/styled';
import { VIEW_TITLE } from '../../constants/modalTitles';
import getModalTitle from '../../services/getModalTitle';
import translate from '../../../core/services/translate';
import ViewErrorsTableRow from '../pages/ViewErrorsTableRow';

interface Props extends RouteComponentProps {
  closeModal: () => void;
  errorsList: any[];
  documentType: string;
}

interface State {
  errors: any[];
}

class ViewErrorsModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: props.errorsList,
    };
  }

  render() {
    const { closeModal, documentType } = this.props;
    const { errors } = this.state;

    const viewErrorsTableCells = [
      { name: 'deficiencies', label: translate('haulerProfile.deficiencies'), width: '100%' },
    ];

    return (
      <Modal onClose={() => closeModal()} padding="medium" size="mediumLarge">
        <ModalTitle>
          {getModalTitle(VIEW_TITLE, documentType)} {translate('haulerProfile.errors')}
        </ModalTitle>
        <ModalSubtitle color="alert">
          {size(errors) === 1
            ? translate('haulerProfile.xErrorUploaded')
            : translate('haulerProfile.xErrorsUploaded', { count: size(errors) })}
        </ModalSubtitle>
        <ModalSection margin="medium no no">
          <Table cells={viewErrorsTableCells} rows={errors} rowComponent={ViewErrorsTableRow} />
        </ModalSection>
      </Modal>
    );
  }
}

export default withRouter(connect()(ViewErrorsModal));
