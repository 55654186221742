import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DuckFunction } from 'src/contracts/ducks';
import { loadLowInventoryParts } from 'src/fleet/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import LowInventoryPartsModal from './LowInventoryPartsModal';

interface Props {
  closeModal: () => void;
  loadLowInventoryParts: DuckFunction<typeof loadLowInventoryParts>;
  vendorId: number;
  containerTypeId?: number;
}

class LowInventoryPartsModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadLowInventoryParts, vendorId, containerTypeId } = this.props;
    const promises = [loadLowInventoryParts(vendorId, containerTypeId)];
    return Promise.all(promises);
  };

  render() {
    const { closeModal, containerTypeId } = this.props;

    return (
      <Resolver
        successComponent={LowInventoryPartsModal}
        successProps={{
          closeModal,
          containerTypeId,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadLowInventoryParts };

export default connect(mapStateToProps, mapDispatchToProps)(LowInventoryPartsModalResolver);
