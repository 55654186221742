interface BaseLatLng {
  lat: number;
  lng: number;
}
// TODO: look into the spacial analysis library @turf to make this easier next time

// Function to calculate the distance between a point and a line segment
export function pointToSegmentDistance(px: number, py: number, x1: number, y1: number, x2: number, y2: number) {
  const A = px - x1;
  const B = py - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq !== 0) {
    param = dot / len_sq;
  }

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = px - xx;
  const dy = py - yy;
  return { distance: Math.sqrt(dx * dx + dy * dy), xx, yy };
}

export function distanceBetweenPoints(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}

export function findClosestCoordinate<T extends BaseLatLng>(pointX: number, pointY: number, coord1: T, coord2: T): T {
  const distanceToCoord1 = distanceBetweenPoints(pointX, pointY, coord1.lng, coord1.lat);
  const distanceToCoord2 = distanceBetweenPoints(pointX, pointY, coord2.lng, coord2.lat);

  if (distanceToCoord1 < distanceToCoord2) {
    return coord1;
  } else {
    return coord2;
  }
}

// Function to find the closest point on the line to a given point
export const findClosestPointOnLine = <T extends BaseLatLng>(lat: number, lng: number, coordinates: T[]): T => {
  let minDistance = Infinity;
  let closestCoord1: BaseLatLng = { lat, lng };
  let closestCoord2: BaseLatLng = { lat, lng };

  // Iterate through each line segment defined by consecutive pairs of coordinates
  for (let i = 0; i < coordinates.length - 1; i++) {
    const coord1 = coordinates[i];
    const coord2 = coordinates[i + 1];
    // Calculate the distance from the point to the line segment
    const { distance } = pointToSegmentDistance(lng, lat, coord1.lng, coord1.lat, coord2.lng, coord2.lat);

    // Update closestPoint if this segment is closer
    if (distance < minDistance) {
      minDistance = distance;
      closestCoord1 = coord1;
      closestCoord2 = coord2;
    }
  }

  const closestCoordinate = findClosestCoordinate(lng, lat, closestCoord1, closestCoord2) as T;

  return closestCoordinate;
};
