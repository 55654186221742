import { reduxForm, InjectedFormProps } from 'redux-form';

import CreationRulesTab from '../modals/ServiceRequestConfigurationTabs/CreationRulesTab';
import DefaultNoteFieldsSection from '../modals/ServiceRequestConfigurationSections/DefaultNoteFieldsConfigurationSection';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

import GeneralSettingsSection from '../modals/ServiceRequestConfigurationSections/GeneralSettingsSection';
import { Grid, GridColumn, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import Open311Section from '../modals/ServiceRequestConfigurationSections/Open311Section';
import OutBoundConfigurationSection from '../modals/ServiceRequestConfigurationSections/OutboundConfigurationSection';
import ServiceRequestErrorsSection from '../modals/ServiceRequestConfigurationSections/ServiceRequestErrorsSection';
import translate from '../../../core/services/translate';
interface ComponentProps {
  onCancel: (pristine: boolean) => void;
  pristine?: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

function Open311Form({ onCancel, pristine }: Props) {
  return (
    <>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <Grid multiLine>
        <GridColumn size="12/12">
          <Open311Section
            title={translate('vendors.open311.serviceConfiguration')}
            content={<GeneralSettingsSection />}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Open311Section title={translate('vendors.open311.defaultFields')} content={<DefaultNoteFieldsSection />} />
        </GridColumn>
        <GridColumn size="12/12">
          <Open311Section title={translate('vendors.open311.inboundSection')} content={<CreationRulesTab />} />
        </GridColumn>
        <GridColumn size="12/12">
          <Open311Section
            title={translate('vendors.open311.outboundSection')}
            content={<OutBoundConfigurationSection />}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Open311Section
            title={translate('vendors.open311.serviceRequestErrors')}
            content={<ServiceRequestErrorsSection />}
          />
        </GridColumn>
      </Grid>
    </>
  );
}

export default reduxForm({ form: 'open311Form', onSubmitFail: focusFirstInvalidField })(Open311Form);
