import styled, { css } from 'styled-components';
import { transparentize, darken } from 'polished';
import { Link } from 'react-router-dom';
import { BareButtonStyle } from '../../../core/components/styled';
import { Icon } from '../../../core/components';

interface Props {
  isOpen?: boolean;
}

export const DashboardFleetInsightsToggleIcon = styled(Icon).attrs({ icon: 'arrowDown' })<Props>`
  width: 12px;
  height: 12px;
  color: ${props => props.theme.grayDark};
  transition: transform 0.3s ease;
  transform: rotate(180deg);

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(0);
    `};
`;

interface DashboardFleetInsightsToggleProps {
  isOpen?: boolean;
}

export const DashboardFleetInsightsToggle = styled.button.attrs({ type: 'button' })<DashboardFleetInsightsToggleProps>`
  ${BareButtonStyle};
  position: absolute;
  right: 0;
  z-index: 2200;
  width: 36px;
  height: 100%;
  padding-top: 4px;
  background-color: #fff;
  border-radius: 3px 3px 0 0;

  ${props =>
    props.isOpen &&
    css`
      top: -26px;
      height: 26px;
      box-shadow: 0 -1px 2px ${props => transparentize(0.9, props.theme.grayBase)};
    `};
`;

export const DashboardFleetInsightsSummary = styled.div`
  position: relative;
  padding: 15px 0 10px 15px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 8px;
  color: ${props => props.theme.grayDarker};
  cursor: pointer;
`;

export const DashboardFleetInsightsCount = styled.span`
  display: inline-block;
  height: 14px;
  min-width: 14px;
  margin: 0 12px;
  padding: 0 4px;
  background-color: ${props => props.theme.brandAlert};
  border-radius: 10px;
  text-align: center;
  line-height: 14px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 8px;
  color: #fff;
`;

export const DashboardFleetInsightsHeaderTableLink = styled(Link)`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 10px;
  text-transform: capitalize;
  font-size: 10px;
  color: ${props => props.theme.brandPrimary};

  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
  }
`;

interface DashboardFleetInsightsHeaderTableCellProps {
  width?: string;
}

export const DashboardFleetInsightsHeaderTableCell = styled.td<DashboardFleetInsightsHeaderTableCellProps>`
  padding: 12px 10px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 8px;
  color: ${props => props.theme.grayDark};
`;

export const DashboardFleetInsightsHeaderTable = styled.table`
  /* position: absolute;
  top: 0;
  left: 0; */
  z-index: 2100;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const DashboardFleetInsightsTableRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.grayLight};
`;

export const DashboardFleetInsightsTableBody = styled.tbody``;

interface DashboardFleetInsightsDetailsTableCellProps {
  width?: string;
}

export const DashboardFleetInsightsDetailsTableCell = styled.td<DashboardFleetInsightsDetailsTableCellProps>`
  padding: 12px 8px;
  line-height: 16px;
  word-wrap: break-word;

  &:last-of-type {
    position: relative;
    padding-right: 15px;
  }
`;

export const DashboardFleetInsightsDetailsTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

export const DashboardFleetInsightsDetailsTableContainer = styled.div`
  height: 220px;
  overflow: auto;
`;

export const DashboardFleetInsightsRemoveIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
  color: ${props => props.theme.grayDark};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const DashboardFleetInsightsRemove = styled.button`
  ${BareButtonStyle};
  position: absolute;
  top: 12px;
  right: 10px;
  width: 8px;
  height: 8px;
`;

export const DashboardFleetInsightsNoResults = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.grayDark};
`;

export const DashboardFleetInsightsTableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

interface DashboardFleetInsightsContainerProps {
  xOffset: number;
  yOffset: number;
  isOpen?: boolean;
}

export const DashboardFleetInsightsContainer = styled.div<DashboardFleetInsightsContainerProps>`
  position: fixed;
  right: ${props => props.xOffset}px;
  bottom: ${props => props.yOffset}px;
  z-index: 1000;
  font-size: 12px;

  ${props =>
    props.isOpen &&
    css`
      width: 545px;
    `};
`;
