import React, { PureComponent, Fragment } from 'react';

import { DocumentTitle } from '../../../common/components';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import CreateRouteImportPage from './CreateRouteImportPage';
import translate from '../../../core/services/translate';

class CreateRouteImportPageResolver extends PureComponent {
  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routeImport.createRouteImport')}</DocumentTitle>
        <Resolver
          successComponent={CreateRouteImportPage}
          loadingComponent={PageLoading}
          redirectOnError="/route-import"
        />
      </Fragment>
    );
  }
}

export default CreateRouteImportPageResolver;
