import React, { Fragment, useState } from 'react';

import { size } from 'lodash-es';

import { ActionButtonTooltip } from '../../../core/components';
import {
  ActionButtonContainer,
  ClassificationsContainer,
  ClassificationsDetails,
} from '../styled/HaulerProfileOverview';
import { AppState } from '../../../store';
import { Button, Text, Grid, Message } from '../../../core/components/styled';
import { CERTIFICATIONS } from '../../constants';
import { connect } from 'react-redux';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentUserIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { saveFile, loadCertifications } from '../../ducks';
import translate from '../../../core/services/translate';
import UploadFileModal from '../modals/UploadFileModal';
import ViewCertificationsModal from '../modals/ViewCertificationsModal';
import { Certification } from '../../interfaces/Certification';

interface Props {
  certifications: Certification[];
  gusId: string;
  userId: string;
  loadCertifications: DuckFunction<typeof loadCertifications>;
  saveFile: DuckFunction<typeof saveFile>;
}

export const CertificationsPage: React.FC<Props> = ({
  certifications,
  gusId,
  userId,
  saveFile,
  loadCertifications,
}) => {
  const [isCertificationsModalOpen, setIsCertificationsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [workingDocumentType, setWorkingDocumentType] = useState<string>();

  const submitUploadFile = (data: any) => {
    saveFile({
      data,
      documentType: workingDocumentType,
      vendorGroupId: gusId,
      userId,
      isFileUpload: data.file.length > 0 || false,
    })
      .then(() => {
        setIsUploadModalOpen(false);
        createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
        loadCertifications(gusId);
      })
      .catch((error: any) => createErrorNotification(`File upload fail: ${error}`));
  };

  const viewCertifications = (documentType: string) => {
    setWorkingDocumentType(documentType);
    setIsCertificationsModalOpen(true);
  };

  const uploadCertification = (documentType: string) => {
    setWorkingDocumentType(documentType);
    setIsUploadModalOpen(true);
  };

  const openCertification = certifications.find(c => c.documentType === workingDocumentType);

  return (
    <>
      <Grid multiLine>
        {certifications &&
          certifications.map((certification, index: number) => (
            <Fragment key={index}>
              <ClassificationsContainer padding="xSmall">
                <ActionButtonContainer>
                  {certification.isCertified && <ActionButtonTooltip color="primary" icon="check" size="sMedium" />}
                </ActionButtonContainer>

                <ClassificationsDetails>
                  {certification.isCertified ? (
                    <Text block size="medium" weight="medium">
                      {certification.displayTitle}
                    </Text>
                  ) : (
                    <Text size="medium" color="grayDarker">
                      {certification.displayTitle}
                    </Text>
                  )}
                  {/* {certification.certificateNumber && (
                    <Text block size="small" color="grayDarker">
                      #{certification.certificationNumber}
                    </Text>
                  )} */}
                </ClassificationsDetails>
                <ActionButtonContainer>
                  <Button
                    id="view-uploaded-document-modal"
                    text
                    color={size(certification.documents) ? 'primary' : 'secondary'}
                    disabled={!size(certification.documents)}
                    padding="small"
                    onClick={
                      size(certification.documents) ? () => viewCertifications(certification.documentType) : undefined
                    }
                  >
                    <ActionButtonTooltip
                      size="medium"
                      icon="redeye"
                      tooltip={size(certification.documents) ? 'viewDocuments' : undefined}
                    />
                  </Button>
                </ActionButtonContainer>
                <ActionButtonContainer>
                  <Button
                    id="upload-file"
                    text
                    color="primary"
                    padding="small"
                    margin="xSmall no no"
                    onClick={() => uploadCertification(certification.documentType)}
                  >
                    <ActionButtonTooltip
                      customViewBox="0 0 24 24"
                      size="medium"
                      icon="cloud-upload"
                      tooltip="uploadDocument"
                    />
                  </Button>
                </ActionButtonContainer>
              </ClassificationsContainer>
            </Fragment>
          ))}
      </Grid>

      {!certifications.length && <Message padding="no">{translate('haulerProfile.noCertifications')}</Message>}
      {isCertificationsModalOpen && openCertification && (
        <ViewCertificationsModal
          closeModal={() => setIsCertificationsModalOpen(false)}
          documentList={openCertification.documents}
          documentType={openCertification.documentType}
          isviewCertificateNo={true}
        />
      )}
      {isUploadModalOpen && (
        <UploadFileModal
          closeModal={() => setIsUploadModalOpen(false)}
          documentType={workingDocumentType}
          submitUploadFile={submitUploadFile}
          uploadFileSource={CERTIFICATIONS}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  certifications: state.haulerProfile.certifications.certifications as any as any[],
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor) as any as string,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor) as any as string,
});

const mapDispatchToProps = {
  saveFile,
  loadCertifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificationsPage);
