import { http } from '../../core/services/http';
import {
  transformRubiconServices,
  transformRubiconServicesOptions,
  transformRubiconCustomerServiceInfo,
} from './transformRubiconServicesOptions';
import { ACTIVE, ON_HOLD } from '../../customers/constants/serviceStatuses';
import { LIMIT_PER_PAGE, PAGE } from 'src/core/constants';
import { downloadExcelFilebyPost } from 'src/utils/services/downloadExcelFile';
import translate from 'src/core/services/translate';

export const loadRubiconServices = (
  vendorId: number,
  userId: string,
  customerName?: string,
  divisionId?: string,
  serviceId?: string,
  equipmentType?: string,
  materialType?: string,
  servciceStatusIds: string = `${ACTIVE},${ON_HOLD}`,
  destinationId?: number,
  isTemp?: string,
  startDate?: string,
  endDate?: string,
  page: number = PAGE,
  limit: number = LIMIT_PER_PAGE,
) => {
  return http
    .post(`gus/rubiconservices/vendor/${vendorId}/user/${userId}`, {
      address: customerName,
      division: divisionId,
      serviceNumber: serviceId || undefined,
      equipmentType,
      materialType,
      serviceStatus: servciceStatusIds,
      destinationId,
      isTemp,
      startDate,
      endDate,
      pageNumber: page,
      pageSize: limit,
    })
    .then(response => {
      let coordinates = transformRubiconServices(response.data.services);
      return { ...response.data, coordinates };
    });
};

const searchRubiconCustomerLocations = (
  vendorId: number,
  userId: string,
  customerName?: string,
  divisionId?: string,
  serviceId?: string,
  equipmentType?: string,
  materialType?: string,
  servciceStatusIds: string = `${ACTIVE},${ON_HOLD}`,
  page: number = PAGE,
  limit: number = LIMIT_PER_PAGE,
) => {
  return http
    .post(`gus/rubiconservices/vendor/${vendorId}/user/${userId}`, {
      address: customerName,
      division: divisionId,
      serviceNumber: serviceId,
      equipmentType,
      materialType,
      serviceStatus: servciceStatusIds,
      pageNumber: page,
      pageSize: limit,
    })
    .then(response => transformRubiconCustomerServiceInfo(response.data.services));
};

export default searchRubiconCustomerLocations;

export const loadRubiconServicesOptions = (gusId: string) =>
  http.get(`haulerprofile/${gusId}/lookups`).then(response => transformRubiconServicesOptions(response.data));

export const loadRubiconServicesDocuments = (
  userId: string,
  vendorId: string,
  isAdmin: boolean,
  serviceNumber: number,
) =>
  http
    .post(`gus/rubiconservices/documents/vendor/${vendorId}/user/${userId}`, {
      user: userId,
      isAdmin,
      serviceNumber,
    })
    .then(response => response.data);

export const exportRubiconServices = (
  vendorId: number,
  userId: string,
  customerName: string,
  divisionId: string,
  serviceId: string,
  equipmentType: string,
  materialType: string,
  servciceStatusIds: string = `${ACTIVE},${ON_HOLD}`,
) => {
  return downloadExcelFilebyPost(
    `gus/rubiconservices/vendor/${vendorId}/user/${userId}/export/details`,
    translate('customers.rubiconServices').replace(' ', '_'),
    {
      address: customerName,
      division: divisionId,
      serviceNumber: serviceId,
      equipmentType,
      materialType,
      serviceStatus: servciceStatusIds,
    },
  );
};

export const loadRubiconServicesSubmittedTickets = (serviceId: number, vendorId: string, userId: string) => {
  return http.get(`gus/vendors/${vendorId}/rubiconservices/${serviceId}/tickets`).then(response => {
    return response.data;
  });
};

export const loadEndDestinations = (vendorId: number, userId: string) => {
  return http.get(`gus/rubiconservices/enddestinations/vendor/${vendorId}/user/${userId}`).then(response => {
    return response.data;
  });
};
