import { AnyAction, Dispatch } from 'redux';

import { GetStateFunction } from '../../contracts/ducks';
import {
  clearVehicleZDeviceConfiguration as doClearConfig,
  createVehicleZDeviceConfiguration as doCreateConfig,
  loadVehicleZDeviceConfiguration as doLoadVehicle,
} from '../services/zDeviceConfiguration';

// Types
const START_LOAD_DEFAULT = 'fleet/zDeviceConfiguration/START_LOAD_DEFAULT';
const COMPLETE_LOAD_DEFAULT = 'fleet/zDeviceConfiguration/COMPLETE_LOAD_DEFAULT';
const FAIL_LOAD_DEFAULT = 'fleet/zDeviceConfiguration/FAIL_LOAD_DEFAULT';
const START_SAVE = 'fleet/zDeviceConfiguration/START_SAVE';
const COMPLETE_SAVE = 'fleet/zDeviceConfiguration/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/zDeviceConfiguration/FAIL_SAVE';
const START_CLEAR_CONFIG = 'fleet/zDeviceConfiguration/START_CLEAR_CONFIG';
const COMPLETE_CLEAR_CONFIG = 'fleet/zDeviceConfiguration/COMPLETE_CLEAR_CONFIG';
const FAIL_CLEAR_CONFIG = 'fleet/zDeviceConfiguration/FAIL_CLEAR_CONFIG';

// Initial State
const initialState: any = {
  isLoading: false,
  isSaving: false,
  isClearing: false,
};

// Reducer
const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_DEFAULT:
      return {
        ...state,
        isLoading: true,
      };
    case COMPLETE_LOAD_DEFAULT:
      return {
        ...state,
        isLoading: false,
        vehicle: action.vehicle,
      };
    case FAIL_LOAD_DEFAULT:
      return {
        ...state,
        isLoading: false,
        deviceServices: null,
      };
    case START_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case COMPLETE_SAVE:
      return {
        ...state,
        isSaving: false,
        vehicle: action.vehicle,
      };
    case FAIL_SAVE:
      return {
        ...state,
        isSaving: false,
      };

    case START_CLEAR_CONFIG:
      return {
        ...state,
        isClearing: true,
      };

    case COMPLETE_CLEAR_CONFIG:
      return {
        ...state,
        isClearing: false,
        vehicle: {
          ...state.vehicle,
          deviceServices: null,
          commands: '',
        },
      };

    case FAIL_CLEAR_CONFIG:
      return {
        ...state,
        isClearing: false,
      };
    default:
      return state;
  }
};

// Action Creators

const startLoad = () => ({
  type: START_LOAD_DEFAULT,
});

const completeLoad = (vehicle: any) => ({
  type: COMPLETE_LOAD_DEFAULT,
  vehicle,
});

const failLoad = () => ({
  type: FAIL_LOAD_DEFAULT,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (vehicle: any) => ({
  type: COMPLETE_SAVE,
  vehicle,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startClearConfig = () => ({
  type: START_CLEAR_CONFIG,
});

const completeClearConfig = (vehicle: any) => ({
  type: COMPLETE_CLEAR_CONFIG,
  vehicle,
});

const failClearConfig = () => ({
  type: FAIL_CLEAR_CONFIG,
});

// Async Actions
export const loadDeviceDefaultVehicle = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVehiclePromise = doLoadVehicle(vehicleId);
  loadVehiclePromise.then(vehicle => dispatch(completeLoad(vehicle))).catch(() => dispatch(failLoad()));
  return loadVehiclePromise;
};

export const clearDeviceConfig = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startClearConfig());
  const clearConfigPromise = doClearConfig(vehicleId);
  clearConfigPromise.then(vehicle => dispatch(completeClearConfig(vehicle))).catch(() => dispatch(failClearConfig()));
  return clearConfigPromise;
};

export const saveVehicleDeviceConfig =
  (deviceServices: any, commands?: any) => (dispatch: Dispatch, getState: GetStateFunction) => {
    const vehicle = getState().fleet.zDeviceConfiguration.vehicle;
    const newVehicle = { ...vehicle, deviceServices, commands };

    dispatch(startSave());
    const saveVehiclePromise = doCreateConfig(newVehicle);
    saveVehiclePromise
      .then(({ data }) => dispatch(completeSave(data)))
      .catch(() => {
        dispatch(failSave());
      });

    return saveVehiclePromise;
  };

// Selectors

export const deviceServicesSelector = (state: any) =>
  state.fleet.zDeviceConfiguration.vehicle && state.fleet.zDeviceConfiguration.vehicle.deviceServices;

export const selectZDeviceServices = (state: any) =>
  state.fleet.zDeviceConfiguration.vehicle && state.fleet.zDeviceConfiguration.vehicle.commands;

export default reducer;
