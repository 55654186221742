import { take } from 'lodash-es';
import moment from 'moment';
import React, { useState } from 'react';

import { Icon, Modal, PopoverWrapper } from 'src/core/components';
import { Button, Popover } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { Message } from '../interfaces/Message';
import { MessageType } from '../interfaces/MessateType';
import {
  MessageContainer,
  MessageDate,
  MessageDetailsContainer,
  ReadReceiptRow,
  ReadReceiptsContainer,
  ReadReceiptsList,
} from '../styles/MessagingContainer';

const maxNumberOfSeeners = 5;

export const MessageItem: React.FC<{
  message: Partial<Message>;
  onRetry?: (message: Partial<Message>) => void;
}> = ({ message, onRetry }) => {
  const isReceived = !!message.fromDriverId;

  const [isReceiversModalOpen, setIsReceiversModalOpen] = useState(false);

  const isLoading = message.isLoading;

  return (
    <>
      <MessageContainer isReceived={isReceived}>
        {message.messageText}
        <MessageDetailsContainer>
          {!isLoading && !onRetry && <MessageDate>{moment(message.sentDate).format('LLL')}</MessageDate>}
          {!isReceived && !isLoading && message.recipients && !!message.recipients.length && (
            <PopoverWrapper
              orientation="left"
              triggerButton={
                <Button margin="no" padding="no" color="secondary" onClick={() => setIsReceiversModalOpen(true)} text>
                  <Icon width="15px" icon="check" />
                </Button>
              }
              popoverContent={
                <Popover>
                  <ReadReceiptsContainer>
                    <div>{translate('messaging.seen')}</div>
                    <ReadReceiptsList>
                      {take(message.recipients || [], maxNumberOfSeeners).map((r, i) => (
                        <ReadReceiptRow key={i}>
                          {message.messageTypeId !== MessageType.Direct && <strong>{r.name}: </strong>}
                          <span>
                            {moment(message.sentDate).format('L')} {moment(r.readDate).format('LTS')}
                          </span>
                        </ReadReceiptRow>
                      ))}
                      {message.recipients && message.recipients.length > maxNumberOfSeeners && (
                        <ReadReceiptRow key="more">
                          {translate('messaging.xMore', { number: message.recipients.length - maxNumberOfSeeners })}
                        </ReadReceiptRow>
                      )}
                    </ReadReceiptsList>
                  </ReadReceiptsContainer>
                </Popover>
              }
            />
          )}
          {isLoading && !onRetry && <Loader light />}
        </MessageDetailsContainer>
        {onRetry && (
          <Button padding="no" margin="no" onClick={() => onRetry && onRetry(message)} text color="warning">
            <Icon width="12px" icon={'exclamation'} />
            <span> {translate('messaging.retrySendMessage')}</span>
          </Button>
        )}
      </MessageContainer>
      {isReceiversModalOpen && (
        <Modal
          size="xSmall"
          verticalSize="xSmall"
          title={translate('messaging.seen')}
          onClose={() => setIsReceiversModalOpen(false)}
        >
          <ReadReceiptsList>
            {(message.recipients || []).map((r, i) => (
              <ReadReceiptRow key={i}>
                <strong>{r.name}:</strong> {moment(r.readDate).format('L')} {moment(r.readDate).format('LTS')}
              </ReadReceiptRow>
            ))}
          </ReadReceiptsList>
        </Modal>
      )}
    </>
  );
};
