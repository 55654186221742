export const OPEN_FOR_BID = 1;
export const BID_REVIEW = 2;
export const PENDING_RUBICON_APPROVAL = 3;
export const PENDING_HAULER_APPROVAL = 4;
export const OPEN_AWARD = 5;
export const AWARD_COMPLETED = 6;
export const DECLINED = 7;
export const CLOSED = 8;
export const CANCELLED = 9;
export const AWARD_SENT = 10;
export const AWARD_ACCEPTED = 11;
export const AWARD_DECLINED = 12;
export const NOT_AWARDED = 13;
export const COMPLETED = 14;
export const HAULER_SELECTED = 15;
/////////////////////////
export const SUBMITTED_BIDS = 15;
export const UNDER_REVIEW = 16;
export const YOUR_AWARDS = 17;
export const UNSUBMITTED_BIDS = 18;
export const OPEN_BIDS = 19;
export const SELECTED_MAX_OPEN_AWARDS = 250;

////// Rate Codes
export const CHGHAULNG = 'CHGHAULNG';
export const CHGVHAULNG = 'CHGVHAULNG';
export const CHGVLOCK = 'CHGVLOCK';
export const CHGLOCK = 'CHGLOCK';
export const CHGVCASTOR = 'CHGVCASTOR';
export const CHGCASTOR = 'CHGCASTOR';
export const CHGCASTERS = 'CHGCASTERS';
export const CHGLOCKFEE = 'CHGLOCKFEE';
