import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createUrl, createUrlWithBooleans } from '../../../utils/services/queryParams';
import { EXPLICIT_STATUSES, ACTIVE, AVAILABLE, SERVICE_STATUSES } from '../../constants/status';
import { Facility } from 'src/common/interfaces/Facility';
import { getOperationalFacilityWithNoneOptions } from '../utils/vehiclesPageHooks';
import { PanelSearch, Dropdown, TypedField, MultiSelect } from '../../../core/components';
import { PanelSection } from '../../../core/components/styled';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import { VehiclesFormWrapper, VehiclesFormItemWrapper } from '../styled/VehiclesPage';
import translate from '../../../core/services/translate';

const vehicleStatusOptions = EXPLICIT_STATUSES.map(status => ({
  label: status.name,
  value: status.id,
}));

const serviceStatusesOptions = SERVICE_STATUSES.map(status => ({
  label: status.name,
  value: status.id,
}));

export interface VehiclesFormValues {
  operationalFacility?: number;
  searchTerm?: string;
  serviceStatus?: string;
  vehicleStatus?: string;
  vehicleTypes?: number[];
}

type PropsWithoutReduxForm = RouteComponentProps & {
  vehicleFacilities: Facility[];
};

type Props = PropsWithoutReduxForm & InjectedFormProps<VehiclesFormValues, PropsWithoutReduxForm>;

const VehiclesForm: React.FC<Props> = ({ location: { pathname, search }, handleSubmit, vehicleFacilities }) => {
  const dispatch = useDispatch();

  const vehicleTypes = useSelector((state: AppState) =>
    (state.fleet.vehicleTypesForVendor.vehicleTypesForVendor || []).map((type: TechnicalType) => ({
      label: type.name,
      value: type.id,
    })),
  );

  const debouncedCallback = React.useMemo(
    () =>
      debounce((term?: string) => {
        dispatch(push(createUrl(pathname, search, { searchTerm: term })));
      }, 470),
    [dispatch, pathname, search],
  );

  const setSearchTermDebounced = React.useCallback(debouncedCallback, [debouncedCallback]);

  const setVehicleTypeIds = (vehicleTypeIds?: number[]) => {
    dispatch(
      push(
        createUrl(pathname, search, {
          vehicleTypeIds:
            vehicleTypeIds && vehicleTypeIds.length !== vehicleTypes.length ? vehicleTypeIds.join(',') : undefined,
        }),
      ),
    );
  };

  const setShowActiveVehicles = (vehicleStatus?: string) => {
    dispatch(
      push(
        createUrlWithBooleans(pathname, search, {
          showActiveVehicles: !vehicleStatus ? undefined : vehicleStatus === ACTIVE,
        }),
      ),
    );
  };

  const setShowAvailableVehicles = (serviceStatus?: string) => {
    dispatch(
      push(
        createUrlWithBooleans(pathname, search, {
          showAvailableVehicles: !serviceStatus ? undefined : serviceStatus === AVAILABLE,
        }),
      ),
    );
  };

  const setOperationFacility = (operationalFacility?: number) => {
    dispatch(
      push(
        createUrl(pathname, search, {
          operationalFacility: operationalFacility || undefined,
        }),
      ),
    );
  };

  const operationalFacilityOptions = getOperationalFacilityWithNoneOptions(vehicleFacilities);

  return (
    <form onSubmit={handleSubmit(() => {})}>
      <PanelSection padding="sMedium no" withBorder>
        <VehiclesFormWrapper>
          <VehiclesFormItemWrapper grow>
            <TypedField
              name="searchTerm"
              component={PanelSearch}
              onChange={(_, newValue: VehiclesFormValues['searchTerm']) => setSearchTermDebounced(newValue)}
              props={{
                margin: 'no',
                isClearable: true,
              }}
            />
          </VehiclesFormItemWrapper>

          <VehiclesFormItemWrapper size={20}>
            <TypedField
              name="vehicleTypes"
              component={MultiSelect}
              onChange={(_, newValue: VehiclesFormValues['vehicleTypes']) => setVehicleTypeIds(newValue || undefined)}
              props={{
                margin: 'no',
                options: vehicleTypes,
                placeholder: translate('common.vehicleTypes'),
                formatText(selectedOptions: any[], allSelected: boolean) {
                  return allSelected
                    ? translate('vehicles.allVehicleTypes')
                    : translate('vehicles.xVehicleTypesSelected', { selected: selectedOptions.length });
                },
                normalizeValues(value: string | number) {
                  return +value;
                },
              }}
            />
          </VehiclesFormItemWrapper>

          <VehiclesFormItemWrapper size={20}>
            <TypedField
              name="vehicleStatus"
              component={Dropdown}
              onChange={(_, newValue: VehiclesFormValues['vehicleStatus']) => setShowActiveVehicles(newValue)}
              props={{
                isClearable: true,
                margin: 'no',
                options: vehicleStatusOptions,
                placeholder: translate('vehicles.vehicleStatus'),
              }}
            />
          </VehiclesFormItemWrapper>

          <VehiclesFormItemWrapper size={20}>
            <TypedField
              name="serviceStatus"
              component={Dropdown}
              onChange={(_, newValue: VehiclesFormValues['serviceStatus']) => setShowAvailableVehicles(newValue)}
              props={{
                isClearable: true,
                margin: 'no',
                options: serviceStatusesOptions,
                placeholder: translate('common.serviceStatus'),
              }}
            />
          </VehiclesFormItemWrapper>

          <VehiclesFormItemWrapper size={20}>
            <TypedField
              name="operationalFacility"
              component={Dropdown}
              onChange={(_, newValue: VehiclesFormValues['operationalFacility']) => setOperationFacility(newValue)}
              props={{
                isClearable: true,
                margin: 'no',
                options: operationalFacilityOptions,
                placeholder: translate('common.customerTypes.operationalFacility'),
              }}
            />
          </VehiclesFormItemWrapper>
        </VehiclesFormWrapper>
      </PanelSection>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  vehicleFacilities: state.fleet.vehicle.vehicleFacilities,
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<VehiclesFormValues, PropsWithoutReduxForm>({
      form: 'vehiclesForm',
      enableReinitialize: true,
    })(VehiclesForm),
  ),
);
