import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, reset } from 'redux-form';
import { includes, map } from 'lodash-es';
import { RouteComponentProps, useLocation, useParams, withRouter } from 'react-router';
import React, { Component } from 'react';

import { AppState } from '../../../../store';
import { ACCOUNT_STATUSES, BUSINESS } from '../../../../common/constants';
import { accountStatusFormatText } from '../../../services/accountStatusFormatText';
import { ActionButtonTooltip, MultiSelect } from '../../../../core/components';
import { ACTIVE, NEW } from '../../../../common/constants/accountStatuses';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import {
  ButtonLink,
  DetailsList,
  DetailsListActionButton,
  DetailsListActions,
  DetailsListBody,
  DetailsListBodySection,
  DetailsListHeader,
  DetailsListHeaderTitle,
  DetailsListItemDescription,
  DetailsListItemTitle,
  Grid,
  GridColumn,
  Link,
  Panel,
  PanelSection,
  PanelSectionGroup,
} from '../../../../core/components/styled';
import { checkServiceHasScheduledDailyRoutes } from '../../../../routes/services/routeTemplate';
import { checkServiceHasVendorContainers } from 'src/customers/services/service';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import {
  CUSTOMERS_LOCATION_DETAILS_ADD_SERVICE,
  CUSTOMERS_LOCATION_DETAILS_DELETE_SERVICE,
  CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE,
} from '../../../../account/constants';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DeleteCustomerLocationOrServiceModal } from '../../modals';
import { deleteService, resetServices } from '../../../ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { PermissionGuard } from '../../../../account/components';
import { SERVICE_DETAILS_EDITOR_FORM } from 'src/customers/components/forms/ServiceDetailsEditorForm';
import { SERVICE_TYPE_ICONS } from '../../../constants';
import { ServiceDetailsCard, ServiceDetailsCardImage } from '../../styled';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from '../../../../core/services/confirm';
import customersLocationsFormInitialValuesSelector from '../../../services/customersLocationsFormInitialValuesSelector';
import TransferContainerModalResolver from '../../modals/TransferContainerModalResolver';
import translate from '../../../../core/services/translate';

interface ServiceDetailsProps {
  deleteService: (event: any, serviceId: number) => void;
  service: any;
}

const ServiceDetails: React.FC<ServiceDetailsProps> = ({ deleteService, service }) => {
  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));
  const { customerId } = useParams<MatchState>();
  const { search } = useLocation();
  const { locationId } = getQueryParams(search);
  return (
    <ServiceDetailsCard
      isParent={service.isParent}
      isChildren={service.isChild && service.effectiveDate}
      childLevel={service.generation}
    >
      <Grid>
        <GridColumn size="2/12">
          <ServiceDetailsCardImage src={SERVICE_TYPE_ICONS[service.serviceTypeIcon]} />
        </GridColumn>
        <GridColumn size="10/12">
          <DetailsList>
            <DetailsListHeader withBorder padding="xSmall no">
              <DetailsListHeaderTitle>
                {service.serviceName} ({service.equipmentTypeName})
              </DetailsListHeaderTitle>
              <DetailsListActions>
                <PermissionGuard permission={CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE}>
                  <DetailsListActionButton id="edit-service-link" margin="no xSmall">
                    <Link
                      id="edit-service-link"
                      color="grayDark"
                      to={`/customers/customers/${customerId}/location/${locationId}/service/${service.id}`}
                    >
                      <ActionButtonTooltip icon="edit" tooltip="editService" />
                    </Link>
                  </DetailsListActionButton>
                </PermissionGuard>
                {!isBillingFeatureActive && (
                  <PermissionGuard permission={CUSTOMERS_LOCATION_DETAILS_DELETE_SERVICE}>
                    <DetailsListActionButton
                      id={`delete-service-${service.id}-button`}
                      onClick={event => deleteService(event, service.id)}
                      margin="no"
                    >
                      <ActionButtonTooltip icon="delete" tooltip="deleteService" />
                    </DetailsListActionButton>
                  </PermissionGuard>
                )}
              </DetailsListActions>
            </DetailsListHeader>
            <DetailsListBody margin="small no no no">
              <DetailsListBodySection>
                <DetailsListItemTitle color="secondary" margin="no no small">
                  {translate('customers.containerCount')}
                </DetailsListItemTitle>
                <DetailsListItemDescription>{service.numberOfContainers}</DetailsListItemDescription>
              </DetailsListBodySection>
              <DetailsListBodySection>
                <DetailsListItemTitle color="secondary" margin="no no small">
                  {translate('common.serviceAccountNumber')}
                </DetailsListItemTitle>
                <DetailsListItemDescription>{service.haulerAccountNo || '-'}</DetailsListItemDescription>
              </DetailsListBodySection>
              <DetailsListBodySection>
                <DetailsListItemTitle color="secondary" margin="no no small">
                  {translate('customers.equipmentSize')}
                </DetailsListItemTitle>
                <DetailsListItemDescription>{service.equipmentSizeName}</DetailsListItemDescription>
              </DetailsListBodySection>
              <DetailsListBodySection>
                <DetailsListItemTitle color="secondary" margin="no no small">
                  {translate('customers.frequency')}
                </DetailsListItemTitle>
                <DetailsListItemDescription>{service.pickupFrequencyType}</DetailsListItemDescription>
              </DetailsListBodySection>
              <DetailsListBodySection>
                <DetailsListItemTitle color="secondary" margin="no no small">
                  {translate('customers.wasteMaterial')}
                </DetailsListItemTitle>
                <DetailsListItemDescription>{service.wasteMaterialType}</DetailsListItemDescription>
              </DetailsListBodySection>
            </DetailsListBody>
          </DetailsList>
        </GridColumn>
      </Grid>
    </ServiceDetailsCard>
  );
};

ServiceDetails.defaultProps = {
  service: {},
};

interface MatchState {
  customerId?: string;
}

interface LocationState {
  locationId?: string;
}

interface ServicesProps extends RouteComponentProps<MatchState, {}, LocationState> {
  customer: any;
  deleteService: (serviceId: number, shouldRecreate: boolean, moveContainersToFacilityId?: number) => Promise<any>;
  isBillingFeatureActive?: boolean;
  match: any;
  reset: any;
  resetServices: () => void;
  services: any[];
  vendorId: number;
}

type Props = ServicesProps & InjectedFormProps<any, ServicesProps>;

interface State {
  filteredServices: any[];
  isDeleteServiceModalOpen: boolean;
  isTransferContainersModalOpen: boolean;
  moveContainersToFacilityId?: number;
  selectedService?: any;
  selectedServiceId: number;
  selectedLocationId?: number;
  servicesStatus: string;
}

class Services extends Component<Props, State> {
  readonly state: State = {
    filteredServices: [],
    isDeleteServiceModalOpen: false,
    isTransferContainersModalOpen: false,
    selectedServiceId: 0,
    servicesStatus: `${ACTIVE},${NEW}`,
  };

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    const { services } = this.props;
    const { servicesStatus } = this.state;

    if (services !== nextProps.services) {
      const filteredServices =
        nextProps.services &&
        nextProps.services.filter(service => includes(servicesStatus, service.accountStatusTypeId));
      this.setState({ filteredServices });
    }

    return true;
  }

  componentDidMount() {
    this.onInitialServicesLoad();
  }

  componentWillUnmount() {
    this.props.resetServices();
  }

  onDeleteService = (shouldRecreateRoutes: boolean) => {
    const { deleteService } = this.props;
    const { selectedServiceId, moveContainersToFacilityId } = this.state;

    this.setState({ isDeleteServiceModalOpen: false, selectedLocationId: 0 });

    deleteService(selectedServiceId, shouldRecreateRoutes, moveContainersToFacilityId)
      .then(() => {
        createSuccessNotification(`${translate('customers.alertMessages.serviceDeleted')}`);

        const { services } = this.props;
        const { servicesStatus } = this.state;
        const filteredServices =
          services &&
          services.filter(
            service => includes(servicesStatus, service.accountStatusTypeId) && service.id !== selectedServiceId,
          );

        this.setState({ filteredServices });
      })
      .catch(() => {
        createErrorNotification(`${translate('customers.alertMessages.serviceDeleteError')}`);
      });
  };

  onCancelDeleteService = () => {
    this.setState({ isDeleteServiceModalOpen: false, selectedServiceId: 0 });
  };

  onCancelSaveService = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset(SERVICE_DETAILS_EDITOR_FORM);
  };

  onServicesStatusChange = (event: any, servicesStatus: string) => {
    const { services } = this.props;
    const filteredServices =
      services && services.filter(service => includes(servicesStatus, service.accountStatusTypeId));
    this.setState({ filteredServices, servicesStatus });
  };

  onInitialServicesLoad = () => {
    const { services } = this.props;
    const { servicesStatus } = this.state;
    const filteredServices =
      services && services.filter(service => includes(servicesStatus, service.accountStatusTypeId));
    this.setState({ filteredServices });
  };

  onTransferContainer = (values: { facilityTypeId: number }) => {
    this.setState({
      moveContainersToFacilityId: values?.facilityTypeId,
      isTransferContainersModalOpen: false,
      isDeleteServiceModalOpen: true,
    });
  };

  openDeleteServiceModal = async (event: any, serviceId: number) => {
    event.stopPropagation();
    const hasContainers = await checkServiceHasVendorContainers(serviceId);
    if (hasContainers) this.setState({ isTransferContainersModalOpen: true, selectedServiceId: serviceId });
    else this.setState({ isDeleteServiceModalOpen: true, selectedServiceId: serviceId });
  };

  closeTransferContainersModal = () => {
    this.setState({ isTransferContainersModalOpen: false, moveContainersToFacilityId: 0 });
  };

  render() {
    const {
      customer: { customerTypeId },
      vendorId,
      services,
      match: {
        params: { customerId },
      },
      location,
    } = this.props;

    const { filteredServices, isDeleteServiceModalOpen, isTransferContainersModalOpen, selectedServiceId } = this.state;

    const { locationId } = getQueryParams(location.search);

    const servicesStatusOptions = map(ACCOUNT_STATUSES, servicesStatus => ({
      label: servicesStatus.name,
      value: servicesStatus.id,
    }));

    return (
      <Panel>
        <PanelSectionGroup>
          <PanelSection vertical dark padding="sMedium">
            {services.length > 0 && (
              <ServiceDetailsCard>
                <DetailsListHeaderTitle>{translate('customers.services')}</DetailsListHeaderTitle>
                <GridColumn size="3/12">
                  <Field
                    component={MultiSelect as any}
                    margin="no"
                    name="accountStatusTypeId"
                    normalizeValues={Number}
                    onChange={this.onServicesStatusChange}
                    options={servicesStatusOptions}
                    placeholder={translate('common.status')}
                    formatText={accountStatusFormatText}
                  />
                </GridColumn>
              </ServiceDetailsCard>
            )}
            {map(filteredServices, service => (
              <ServiceDetails deleteService={this.openDeleteServiceModal} key={service.id} service={service} />
            ))}
          </PanelSection>
          {customerTypeId === BUSINESS && (
            <PermissionGuard permission={CUSTOMERS_LOCATION_DETAILS_ADD_SERVICE}>
              <PanelSection padding="sMedium">
                <ButtonLink
                  id="add-service-button-link"
                  color="primary"
                  to={`/customers/customers/${customerId}/location/${locationId}/service/-1`}
                >
                  + {translate('customers.addService')}
                </ButtonLink>
              </PanelSection>
            </PermissionGuard>
          )}

          {!!isDeleteServiceModalOpen && (
            <DeleteCustomerLocationOrServiceModal
              checkMethod={checkServiceHasScheduledDailyRoutes}
              modalTitle={translate('customers.alertMessages.confirmDeleteService')}
              onCancel={this.onCancelDeleteService}
              onDelete={this.onDeleteService}
              selectedId={selectedServiceId}
              vendorId={vendorId}
            />
          )}

          {!!isTransferContainersModalOpen && (
            <TransferContainerModalResolver
              title={translate('containers.whereTransferContainers', {
                containerPlace: translate('common.location'),
              })}
              closeModal={this.closeTransferContainersModal}
              handleContainerTransfer={this.onTransferContainer}
            />
          )}
        </PanelSectionGroup>
      </Panel>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const initialValues = (customersLocationsFormInitialValuesSelector as any)();

  return {
    isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
    customer: state.customers.customer.customer,
    services: state.customers.services.services,
    initialValues,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
  };
};

const mapDispatchToProps = {
  deleteService,
  reset,
  resetServices,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, ServicesProps>({
      enableReinitialize: true,
      form: 'services',
    })(Services),
  ),
);
