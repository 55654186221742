import React from 'react';

import { PLOWING, SALTING, BRINING, SWEEPING } from 'src/common/constants/serviceConfiguration';
import { TableHead, TableHeadCell } from '../../../core/components/styled';
import { CheckboxGroup, TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  isStreetSweeperRoute?: boolean;
}

const ServiceSubTypes: React.FC<Props> = ({ isStreetSweeperRoute }) => {
  const items = isStreetSweeperRoute
    ? [
        {
          value: SWEEPING,
          label: translate('vendors.serviceConfiguration.sweeping'),
        },
      ]
    : [
        {
          value: PLOWING,
          label: translate('vendors.serviceConfiguration.plowing'),
        },
        {
          value: SALTING,
          label: translate('vendors.serviceConfiguration.salting'),
        },
        {
          value: BRINING,
          label: translate('vendors.serviceConfiguration.brining'),
        },
      ];
  return (
    <>
      <TableHead>
        <TableHeadCell>{translate('vendors.serviceSubTypes')}</TableHeadCell>
      </TableHead>

      <TypedField
        component={CheckboxGroup}
        name="serviceSubTypes"
        props={{
          padding: 'sMedium xSmall',
          formGroupProps: {
            margin: 'no',
            flex: true,
            justifyContent: true,
          },
          items: items,
        }}
      />
    </>
  );
};

export default ServiceSubTypes;
