import { http } from '../../core/services/http';
import { transformLoadUnassignedJobs } from '../services/transformDispatchBoardUnassignedJobs';
import { UnassignedJob } from '../interfaces/DispatchBoardRouteJob';

export const loadUnassignedJobs = (vendorId: number, vehicleTypeId: number, searchTerm?: string) =>
  http
    .get('dispatchBoard/unassignedJobs', {
      params: { vendorId, vehicleTypeId, searchTerm },
    })
    .then(response => transformLoadUnassignedJobs(response.data));

export const loadUnassignedJobsCount = (vendorId: number, vehicleTypeId: number, searchTerm: string) =>
  http
    .get('dispatchBoard/unassignedJobs/count', { params: { vendorId, vehicleTypeId, searchTerm } })
    .then(response => response.data);

export const loadUnassignedJob = (jobId: number) =>
  http.get(`dispatchBoard/unassignedJobs/${jobId}`).then(response => response.data);

export const createUnassignedJob = (jobData: UnassignedJob) =>
  http.post('/dispatchBoard/unassignedJobs', jobData).then(response => response.data);

export const updateUnassignedJob = (jobId: number, jobData: UnassignedJob) =>
  http.put(`/dispatchBoard/unassignedJobs/${jobId}`, jobData).then(response => response.data);

export const assignUnassignedJob = (jobIds: number[], routeId: number, position: number) =>
  http.post('/dispatchBoard/unassignedJobs/assign', { jobIds, routeId, position }).then(response => response.data);

export const deleteUnassignedJob = (jobIds: number[]) =>
  http.post('/dispatchBoard/unassignedJobs/delete', jobIds).then(response => response.data);

export const loadUnassignedJobsRouteNote = (jobId?: number) =>
  http.get(`/dispatchboard/unassignedJobs/${jobId}/note`).then(response => response.data);

export const updateUnassignedJobsRouteNote = (jobId: number, routeNote: string) =>
  http.put(`/dispatchboard/unassignedJobs/${jobId}/note`, { jobId, routeNote }).then(response => response.data);

export const checkDuplicateWorkOrderNoForUnassignedJob = (
  vendorId: number,
  workOrderNumber: number,
  unassignedJobId: number,
) =>
  http
    .get('dispatchBoard/jobs/duplicateWorkorderNo', { params: { vendorId, workOrderNumber, unassignedJobId } })
    .then(response => response.data);
