import React, { PureComponent } from 'react';

import { Button, ButtonSet, GridColumn, ModalTitle } from '../../../core/components/styled';
import { DispatchBoardContainerError } from '../styled';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: () => void;
  duplicateJobConfirmationData: any;
  onRouteJobDropped: (targetRouteId: number, jobIds: any[], position: number) => void;
  onUnassignedJobDropped: (targetRoutargetRouteId: number, jobIds: any[], position: number) => void;
}

class DispatchBoardDuplicateJobConfirmationModal extends PureComponent<Props> {
  onRouteJobDrop = () => {
    const { onRouteJobDropped, onUnassignedJobDropped, duplicateJobConfirmationData } = this.props;
    const { targetRouteId, sourceRouteId, jobIds, position } = duplicateJobConfirmationData;

    if (sourceRouteId && sourceRouteId !== -1) {
      onRouteJobDropped(targetRouteId, jobIds, position);
    } else {
      onUnassignedJobDropped(targetRouteId, jobIds, position);
    }
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Modal onClose={closeModal} padding="medium">
        <ModalTitle noTransform>{translate('routes.editJobs')}</ModalTitle>
        <DispatchBoardContainerError margin="large auto">
          <p>{translate('dispatchBoard.duplicateJobsEditMessage')}</p>
          <p>{translate('dispatchBoard.duplicateJobsSelectedConfirmation')}</p>
        </DispatchBoardContainerError>

        <GridColumn size="12/12">
          <ButtonSet margin="large auto no">
            <Button type="submit" color="primary" onClick={this.onRouteJobDrop}>
              {translate('dispatchBoard.continueSave')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Modal>
    );
  }
}

export default DispatchBoardDuplicateJobConfirmationModal;
