import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { Panel, PanelSection } from '../../../core/components/styled';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageTitleContainer,
  PageTitle,
} from '../../../common/components/styled';
import { saveVehicleTypesForVendor, resetVehicleTypesForVendor } from '../../ducks';
import { VehicleTypesForm } from '../forms';
import { getSelectedVehicleTypeIds } from '../../services/getVehicleTypesFormData';
import { createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { AppState } from '../../../store';
import PreventativeMaintenanceModalResolver from '../modal/PreventativeMaintenanceModalResolver';
import { PreventativeMaintenanceConfiguration } from '../../interfaces/PreventativeMaintenance';
import { savePMConfigurationForVehicleType } from '../../ducks/preventativeMaintenance';

interface Props {
  isSaving: boolean;
  vendorId?: number;
  push: any;
  saveVehicleTypesForVendor: DuckFunction<typeof saveVehicleTypesForVendor>;
  resetVehicleTypesForVendor: DuckAction<typeof resetVehicleTypesForVendor>;
  savePMConfigurationForVehicleType: DuckFunction<typeof savePMConfigurationForVehicleType>;
}

interface State {
  preventativeMaintenanceModalOpen: boolean;
  preventativeMaintenanceModalForVehicleTypeId?: number;
}

class VehicleTypesPage extends PureComponent<Props, State> {
  readonly state: State = {
    preventativeMaintenanceModalOpen: false,
  };

  componentWillUnmount() {
    this.props.resetVehicleTypesForVendor();
  }

  onSubmit = async (formData: any) => {
    const { saveVehicleTypesForVendor, vendorId, push } = this.props;
    const selectedVehicleTypeIds = getSelectedVehicleTypeIds(formData);

    if (!vendorId) {
      return;
    }

    await saveVehicleTypesForVendor(vendorId, selectedVehicleTypeIds);
    createSuccessNotification(`${translate('vehicles.alertMessages.vehicleTypesSaved')}`);
    push('/fleet/vehicles');
  };

  openPreventativeMaintenanceModal = (vehicleTypeId: number) => {
    this.setState({
      preventativeMaintenanceModalOpen: true,
      preventativeMaintenanceModalForVehicleTypeId: vehicleTypeId,
    });
  };

  closePreventativeMaintenanceModal = () => {
    this.setState({ preventativeMaintenanceModalOpen: false, preventativeMaintenanceModalForVehicleTypeId: undefined });
  };

  savePMConfiguration = (configuration: PreventativeMaintenanceConfiguration) => {
    const { vendorId, savePMConfigurationForVehicleType } = this.props;
    const { preventativeMaintenanceModalForVehicleTypeId: vehicleTypeId } = this.state;

    if (!vendorId || !vehicleTypeId) {
      return;
    }

    savePMConfigurationForVehicleType(vendorId, vehicleTypeId, configuration)
      .then(() => {
        this.closePreventativeMaintenanceModal();
      })
      .catch(() => {});
  };

  render() {
    const { isSaving } = this.props;
    const { preventativeMaintenanceModalOpen, preventativeMaintenanceModalForVehicleTypeId } = this.state;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to={'/fleet/vehicles'}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle>{translate('vehicles.configVehicleTypes')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions />
        </PageHeader>

        <Panel>
          <PanelSection centered padding="large" isLoading={isSaving}>
            <VehicleTypesForm onConfigureToggle={this.openPreventativeMaintenanceModal} onSubmit={this.onSubmit} />
          </PanelSection>
        </Panel>

        {preventativeMaintenanceModalOpen && typeof preventativeMaintenanceModalForVehicleTypeId === 'number' && (
          <PreventativeMaintenanceModalResolver
            vehicleTypeId={preventativeMaintenanceModalForVehicleTypeId}
            onClose={this.closePreventativeMaintenanceModal}
            onSubmit={this.savePMConfiguration}
          />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorId(state),
  isSaving: state.fleet.vehicleTypesForVendor.isSaving,
});

const mapDispatchToProps = {
  saveVehicleTypesForVendor,
  resetVehicleTypesForVendor,
  push,
  savePMConfigurationForVehicleType,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypesPage);
