import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import Open311Form from '../forms/Open311Form';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function Open311ConfigurationModal({ closeModal }: Props) {
  return (
    <Modal size="xLarge" title={translate('vendors.open311.serviceConfigTitle')} onClose={closeModal}>
      <ModalSection margin="medium no no" align="center">
        <Open311Form onCancel={closeModal} />
      </ModalSection>
    </Modal>
  );
}
