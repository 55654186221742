import React, { useState, useEffect, Fragment } from 'react';

import { getTextWithAccentSections, getTextWithBoldSections } from '../../landing/components/services';
import { Tile, TileText, Title, Subtitle, Description, TileImage } from './styled/ServiceCarousel';
import translate from '../../core/services/translate';

import buyingProgramImg from '../../common/assets/img/landingPage/overview4.jpg';
import rubiconProPortalImg from '../../common/assets/img/landingPage/overview1.jpg';
import rubiconXImg from '../../common/assets/img/landingPage/overview2.jpg';
import rubiconYImg from '../../common/assets/img/landingPage/overview5.jpg';
import techFeaturesImg from '../../common/assets/img/landingPage/overview3.jpg';

const services = [
  { name: 'rubiconProPortal', image: rubiconProPortalImg, leftToRight: true },
  { name: 'rubiconX', image: rubiconXImg, leftToRight: false },
  { name: 'techFeatures', image: techFeaturesImg, leftToRight: true },
  { name: 'buyingProgram', image: buyingProgramImg, leftToRight: false },
  { name: 'rubiconY', image: rubiconYImg, leftToRight: true },
];

type ServiceTileProps = {
  active: boolean;
  service: {
    name: string;
    image: any;
    leftToRight: boolean;
  };
};

const ServiceTile = ({ active, service }: ServiceTileProps) => (
  <Tile data-active={active} reverse={service.leftToRight}>
    <TileText>
      <Title>{getTextWithAccentSections(translate(`landingPage.${service.name}.title`))}</Title>
      <Subtitle>{getTextWithBoldSections(translate(`landingPage.${service.name}.subTitle`))}</Subtitle>
      <Description>{getTextWithBoldSections(translate(`landingPage.${service.name}.description`))}</Description>
    </TileText>
    <TileImage alt={service.name} src={service.image} />
  </Tile>
);

type Props = {
  interval: number;
};

export default function ServiceCarousel({ interval }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % services.length);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);

  return (
    <Fragment>
      {services.map((service, index) => (
        <ServiceTile key={service.name} service={service} active={activeIndex === index} />
      ))}
    </Fragment>
  );
}
