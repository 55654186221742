import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { ACTIVE, NEW } from '../../common/constants/accountStatuses';
import { getQueryParams } from '../../utils/services/queryParams';

const getCustomersLocationsFormInitialValues = (queryString: string) => {
  const { accountStatusTypeId = [ACTIVE, NEW] } = getQueryParams(queryString, { mapTypeIdsToArray: true });

  return {
    accountStatusTypeId,
  };
};

const customersLocationsFormInitialValuesSelector = createSelector(getCustomersLocationsFormInitialValues, identity);

export default customersLocationsFormInitialValuesSelector;
