import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { extendTravelPathLock, loadTravelPathStatusDetails, finishTravelPathBuildOrEdit } from '../ducks';
import controlledInformationPrompt from 'src/core/services/controlledInformationPrompt';
import controlledConfirm from 'src/core/services/controlledConfirm';

interface TravelPathLockHandlerProps {
  closeModal: (shouldRefreshDisplayedTP: boolean) => void;
  isBuilder: boolean;
  routeId?: number;
  routeTemplateId?: number;
}

const useTravelPathLockHandler = ({ closeModal, isBuilder, routeId, routeTemplateId }: TravelPathLockHandlerProps) => {
  const travelPathStatusDetails = useSelector(state => state.routes.travelPath.travelPathStatusDetails);
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const popupExpiration = useRef<{ promise: Promise<boolean>; close: () => void } | null>(null);
  const popupEndedSession = useRef<{ promise: Promise<boolean>; close: () => void } | null>(null);

  const travelPathLockDetails = travelPathStatusDetails?.lockDetails;

  const expirationDateTime = travelPathLockDetails?.expirationDateTime;

  useEffect(() => {
    const checkLockWarning = async () => {
      if (!travelPathLockDetails?.isLockedByMe) {
        if (popupExpiration.current) {
          // if the lock is removed, close the expiration warning
          popupExpiration.current.close();
          popupExpiration.current = null;
        }

        // general lock removed warning
        if (
          travelPathLockDetails?.isLockedBySystem ||
          !travelPathLockDetails ||
          (!travelPathLockDetails?.isLockedByUser && !travelPathLockDetails?.isLockedBySystem)
        ) {
          if (popupEndedSession.current) {
            popupEndedSession.current.close();
            popupEndedSession.current = null;
          }

          popupEndedSession.current = travelPathLockDetails?.unlockedByUsername
            ? controlledInformationPrompt(
                `${travelPathLockDetails?.unlockedByUsername} ${translate(
                  'routes.travelPath.yourSessionHasEndedByUser',
                )}`,
                '',
                true,
              )
            : controlledInformationPrompt(translate('routes.travelPath.yourSessionHasEnded'), '', true);

          if (await popupEndedSession.current.promise) {
            popupEndedSession.current = null;
            closeModal(false);
          }
        } else if (travelPathLockDetails?.isLockedByUser) {
          if (popupEndedSession.current) {
            popupEndedSession.current.close();
            popupEndedSession.current = null;
          }
          // user lock removed by another user warning
          popupEndedSession.current = controlledInformationPrompt(
            `${travelPathLockDetails?.lockedByUsername} ${translate('routes.travelPath.hasBegunEditing')}`,
            '',
            true,
          );

          if (await popupEndedSession.current.promise) {
            popupEndedSession.current = null;
            closeModal(false);
          }
        }
      }
    };

    checkLockWarning();
  }, [closeModal, travelPathLockDetails]);

  // confirmation for extending the interval 15 minutes prior to expiration
  useEffect(() => {
    const checkExpirationWarning = async () => {
      if (!expirationDateTime) {
        return;
      }

      const expirationTime = moment.utc(expirationDateTime).toDate().getTime();
      const currentTime = moment.utc().toDate().getTime();

      const minutesRemaining = Math.floor((expirationTime - currentTime) / 60000);

      if (minutesRemaining <= 0) {
        if (popupExpiration.current) {
          popupExpiration.current.close();
          popupExpiration.current = null;
        }

        if (!popupEndedSession.current) {
          popupEndedSession.current = controlledInformationPrompt(
            translate('routes.travelPath.yourSessionHasEnded'),
            '',
            true,
          );

          if (await popupEndedSession.current.promise) {
            popupEndedSession.current = null;
            finishTravelPathBuildOrEdit(
              vendorId,
              isBuilder,
              routeId,
              routeTemplateId,
            )(dispatch).finally(() => {
              closeModal(false);
            });
          }
        }
        return;
      } else if (minutesRemaining <= 30) {
        if (popupExpiration.current) {
          popupExpiration.current.close();
          popupExpiration.current = null;

          popupExpiration.current = controlledConfirm(
            translate('routes.travelPath.alertMessages.yourTravelPathSessionIsAboutToExpire', {
              time: minutesRemaining,
            }),
            translate('routes.travelPath.alertMessages.ifYouEndYourSession'),
            translate('routes.travelPath.endSession'),
            translate('routes.travelPath.extend'),
          );
        } else if (!popupEndedSession.current) {
          popupExpiration.current = controlledConfirm(
            translate('routes.travelPath.alertMessages.yourTravelPathSessionIsAboutToExpire', {
              time: minutesRemaining,
            }),
            translate('routes.travelPath.alertMessages.ifYouEndYourSession'),
            translate('routes.travelPath.endSession'),
            translate('routes.travelPath.extend'),
          );

          if (await popupExpiration.current.promise) {
            popupExpiration.current = null;
            extendTravelPathLock(
              vendorId,
              isBuilder,
              routeId,
              routeTemplateId,
            )(dispatch).then(() => {
              loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
            });
          } else {
            finishTravelPathBuildOrEdit(
              vendorId,
              isBuilder,
              routeId,
              routeTemplateId,
            )(dispatch).then(() => {
              popupExpiration.current = null;
              closeModal(false);
            });
          }
        }
      }
    };

    // Check for expiration warning when the component mounts
    checkExpirationWarning();

    // Set up an interval to check for expiration warning every minute
    const intervalId = setInterval(checkExpirationWarning, 60000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [closeModal, dispatch, expirationDateTime, isBuilder, routeId, routeTemplateId, vendorId]);
};

export default useTravelPathLockHandler;
