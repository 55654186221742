import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { loadRouteImages as doLoadRouteImages } from '../services/routeLocationImages';
import { RouteLocationImage } from '../interfaces/RouteLocationImage';

// Actions
const START_LOAD = 'routes/routeLocationImages/START_LOAD';
export const COMPLETE_LOAD = 'routes/routeLocationImages/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeLocationImages/FAIL_LOAD';
const RESET = 'routes/routeLocationImages/RESET';

// Initial state
interface State {
  isLoading: boolean;
  routeLocationImages: RouteLocationImage[];
}

const initialState: State = {
  isLoading: false,
  routeLocationImages: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeLocationImages: action.routeLocationImages,
        },
      });

    case FAIL_LOAD:
    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeLocationImages: RouteLocationImage[]) => ({
  type: COMPLETE_LOAD,
  routeLocationImages,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteLocationImages =
  (routeId?: number, routeLocationId?: number) => (dispatch: ThunkDispatch<State, any, AnyAction>) => {
    dispatch(startLoad());
    const loadRouteImagesPromise = doLoadRouteImages(routeId, routeLocationId);
    loadRouteImagesPromise
      .then(routeLocationImages => dispatch(completeLoad(routeLocationImages)))
      .catch(() => dispatch(failLoad()));
    return loadRouteImagesPromise;
  };

export const resetRouteLocationImages = () => ({
  type: RESET,
});
