import styled from 'styled-components';

export const CustomerLocationDropdownOptionExclamation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: ${props => props.theme.brandAlert};
  color: white;
  margin-left: 10px;

  &:before {
    content: '!';
    font-weight: 700;
  }
`;

export const CustomerLocationDropdownOption = styled.div`
  display: flex;
  align-items: center;
`;
