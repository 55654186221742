import { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { push } from 'connected-react-router';
import { debounce, find, get, map, orderBy, size } from 'lodash-es';
import { Resizable } from 're-resizable';
import { arrayMove } from 'react-sortable-hoc';

import { useSelector } from 'src/core/hooks/useSelector';
import { DELIVERY_UTILITY_ID, ROLL_OFF_ID, SNOW_PLOW_ID } from 'src/fleet/constants';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import {
  Button,
  Grid,
  GridColumn,
  MapContainer,
  Message,
  Panel,
  PanelSection,
  PanelSectionLoading,
  Text,
} from 'src/core/components/styled';
import { ActionButtonTooltip, MapDragHandle, SortableTable, Table } from 'src/core/components';
import routeAssistance from 'src/common/assets/img/insights/routeAssistance.png';
import translate from 'src/core/services/translate';
import {
  resetRouteSequenceInstructions,
  resetRouteStops,
  resetRouteStopsFilters,
  setSearchTerm,
  updateRouteSequenceStatus,
  updateRouteStops,
} from 'src/routes/ducks';
import RouteSequenceInstructions from './RouteSequenceInstructions';
import RouteSequenceSummaryPanel from './RouteSequenceSummaryPanel';
import RouteSequenceMapGL from './routeSequenceMap/RouteSequenceMapGL';
import { filterStopsPredicate } from '../routes/routePageSections/routeStops/RouteStops';
import RouteSequenceStopsSearchForm from '../../forms/RouteSequenceStopsSearchForm';
import { TABLE_ROW_HEIGHT, TOP } from 'src/core/constants';
import { OrderNumberForm } from '../../forms';
import createPopover from 'src/core/services/createPopover';
import NewRouteSequenceEditorPageTableRow from './NewRouteSequenceEditorPageTableRow';
import NewRouteSequenceEditorPageStopsWithZeroTableRow from './NewRouteSequenceEditorPageStopsWithZeroTableRow';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import confirm from '../../../../core/services/confirm';
import { CANCELED, FINALIZED, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE } from 'src/routes/constants';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';

export default function NewRouteSequenceEditorPage() {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const [routeStopsModified, setRouteStopsModified] = useState<RouteStop[]>([]);

  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);

  const routeSequenceInstructions = useSelector(state => state.routes.routeSequenceInstructions.isRouteSequenceOpen);
  const routeSequence = useSelector(state => state.routes.routeSequence.routeSequence || []);
  const routeStatuses = useSelector(state => state.routes.routeSequence.routeStatuses);
  const { routeStops, isLoading: isRouteStopsLoading, isSaving } = useSelector(state => state.routes.routeStops);
  const { isUpdatingSequenceStatus } = useSelector(state => state.routes.routeSequence);
  const { pickupStatusIds, searchTerm, showStopsWithLocationAlerts, showTemporaryStops } = useSelector(
    state => state.routes.filters,
  );
  const { lastLocations } = useSelector(state => state.core);

  let closePopover = () => {};

  const routeStopsNewOrderNumber = useMemo(() => {
    if (!routeStops.length) return [];
    return orderBy(
      map(routeStops, routeStop => ({
        ...routeStop,
        newOrderNumber: get(
          find(routeSequence, l => l.routeLocationId === routeStop.id),
          'orderNo',
        ),
      })),
      ['newOrderNumber'],
    );
  }, [routeStops, routeSequence]);

  useEffect(() => {
    setRouteStopsModified(routeStopsNewOrderNumber);
  }, [routeStopsNewOrderNumber]);

  const filteredStops = useMemo(() => {
    return routeStopsModified.filter(
      filterStopsPredicate(showStopsWithLocationAlerts, showTemporaryStops, pickupStatusIds, searchTerm),
    );
  }, [routeStopsModified, searchTerm, pickupStatusIds, showStopsWithLocationAlerts, showTemporaryStops]);

  const routeStopsWithZeroStopNumber = useMemo(
    () => filteredStops.filter(stop => stop?.newOrderNumber === 0),
    [filteredStops],
  );
  const routeStopsWithStopNumber = useMemo(
    () => filteredStops.filter(stop => stop && stop.newOrderNumber && stop.newOrderNumber > 0),
    [filteredStops],
  );

  useEffect(() => {
    dispatch(setSearchTerm(''));
  }, [dispatch, routeStops]);

  useEffect(
    () => () => {
      dispatch(resetRouteStops());
      dispatch(resetRouteStopsFilters());
      dispatch(resetRouteSequenceInstructions());
    },
    [dispatch],
  );

  const tableCellsMemo = useMemo(() => {
    const tableCellWidths =
      routeSummary?.vehicleTypeId === ROLL_OFF_ID || routeSummary?.vehicleTypeId === DELIVERY_UTILITY_ID
        ? ['5%', '11%', '9%', '25%', '15%', '15%', '10%']
        : ['5%', '11%', '9%', '40%', undefined, '15%', '10%'];

    const tableCells = [
      { name: 'dragHandle', width: tableCellWidths[0] },
      { name: 'newStopNumber', label: translate('routes.newStopNr'), width: tableCellWidths[1] },
      { name: 'stopNumber', label: translate('routes.stopNumber'), width: tableCellWidths[2] },
      { name: 'customer', label: translate('common.customer'), width: tableCellWidths[3] },
      { name: 'accountStatus', label: translate('routes.accountStatus'), width: tableCellWidths[5] },
      { name: 'service', label: translate('common.service'), width: tableCellWidths[6] },
    ];

    if (routeSummary?.vehicleTypeId === ROLL_OFF_ID || routeSummary?.vehicleTypeId === DELIVERY_UTILITY_ID) {
      tableCells.splice(4, 0, {
        name: 'pickupType',
        label: translate('routes.pickupType'),
        width: tableCellWidths[4],
      });
    }
    return tableCells;
  }, [routeSummary?.vehicleTypeId]);

  if (!routeSummary) return null;

  const isSnowPlowRoute = routeSummary.vehicleTypeId === SNOW_PLOW_ID;

  const goBackToPreviousPage = () => {
    const hasLastLocations = Object.keys(lastLocations).length > 1;
    return hasLastLocations ? goBack() : dispatch(push('/routes/route-tracker'));
  };

  const isActionButtonsDisabled = isRouteStopsLoading;

  const onSearchTermChange = debounce(searchTerm => {
    dispatch(setSearchTerm(searchTerm));
  }, 200);

  let routeAssistTooltip = '';
  if (routeSummary.assistingVehiclesActiveCount || routeSummary.assistingVehiclesCount) {
    routeAssistTooltip =
      translate('routeAssist.routeAssisted', { numberOfVehicles: routeSummary.assistingVehiclesCount }) +
      (routeSummary.assistingVehiclesActiveCount
        ? ` (${translate('routeAssist.activeVehicles', {
            numberOfVehicles: routeSummary.assistingVehiclesActiveCount,
          })})`
        : '');
  }

  const onOrderNumberFormSubmit = (oldOrderNumber: number, newOrderNumber: number, locationId: number) => {
    if (oldOrderNumber === 0) {
      onSortOrderChangeLocationWithZeroStopNumber(oldOrderNumber - 1, newOrderNumber - 1, locationId);
    } else {
      onSortOrderChange(oldOrderNumber - 1, newOrderNumber - 1);
    }
    !!closePopover && closePopover();
  };

  const onRouteLocationDrop = (event: { nativeEvent: { dataTransfer: any } }, insertRouteLocationAtIndex: number) => {
    const { dataTransfer } = event.nativeEvent;
    const routeLocationId = dataTransfer?.getData('routeLocationId')
      ? Number(dataTransfer.getData('routeLocationId'))
      : -1;
    onSortOrderChangeLocationWithZeroStopNumber(0, insertRouteLocationAtIndex, routeLocationId);
  };

  const onSortOrderChange = (oldIndex: number, newIndex: number) => {
    const routeLocationsWithStopNumber = routeStopsModified.filter(routeLocation => routeLocation.newOrderNumber !== 0);
    const routeLocationsWithZeroStopNumber = routeStopsModified.filter(
      routeLocation => routeLocation.newOrderNumber === 0,
    );
    const routeLocationsReorderedMoved = arrayMove(routeLocationsWithStopNumber, oldIndex, newIndex);
    const routeLocationsReordered = resetOrderNumbers(routeLocationsReorderedMoved);
    setRouteStopsModified([...routeLocationsReordered, ...routeLocationsWithZeroStopNumber]);
  };

  const resetOrderNumbers = (routeStops: any[]) =>
    map(routeStops, (routeLocation, index) => ({
      ...routeLocation,
      newOrderNumber: index + 1,
    }));

  const onSortOrderChangeLocationWithZeroStopNumber = (oldIndex: number, newIndex: number, locationId: number) => {
    const routeLocationsWithStopNumber = routeStopsModified.filter(routeLocation => routeLocation.newOrderNumber !== 0);
    const routeLocationCurrent = map(
      routeStopsModified.filter((routeLocation: { id: number }) => routeLocation.id === locationId),
      routeLocation => ({
        ...routeLocation,
        newOrderNumber: newIndex + 1,
      }),
    );
    const routeLocationsWithZeroStopNumber = routeStopsModified.filter(
      routeLocation => routeLocation.newOrderNumber === 0 && routeLocation.id !== locationId,
    );
    if (routeLocationCurrent[0]) routeLocationsWithStopNumber.splice(newIndex, 0, routeLocationCurrent[0]);
    const routeLocationsWithStopNumberReordered = resetOrderNumbers(routeLocationsWithStopNumber);
    setRouteStopsModified([...routeLocationsWithZeroStopNumber, ...routeLocationsWithStopNumberReordered]);
  };

  const openOrderNumberPopover = (
    oldOrderNumber: number,
    event: { stopPropagation: () => void; currentTarget: HTMLElement },
    locationId: number,
  ) => {
    event.stopPropagation();
    closePopover = createPopover(
      event.currentTarget,
      OrderNumberForm,
      {
        onSubmit: ({ orderNumber }: any) => onOrderNumberFormSubmit(oldOrderNumber, Number(orderNumber), locationId),
        maxOrderNumber: size(routeStops),
        initialValues: { orderNumber: oldOrderNumber },
      },
      { position: TOP },
    );
  };

  const cancelRouteSequence = async () => {
    if (!(await confirm(translate('routes.alertMessages.cancelRouteSequence')))) {
      return;
    }
    const jobId = get(find(routeStatuses, { routeId: routeSummary.routeId }), 'jobId');
    updateRouteSequenceStatus({
      routeId: routeSummary.routeId,
      jobId,
      status: CANCELED,
      sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
    })(dispatch).then(() => dispatch(push(`/routes/route-tracker/${routeSummary.routeId}`)));
  };

  const saveRouteSequence = () => {
    const routeLocations = map(routeStopsModified, (routeStop: any) => ({
      routeLocationId: routeStop.id,
      accountTypeId: routeStop.accountTypeId,
      pickupTypeId: routeStop.pickupTypeId,
      reasonCodeTypeId: routeStop.reasonCodeTypeId || null,
      positionTypeId: routeStop.positionTypeId,
      latitude: routeStop.isUpdatedLatLng ? routeStop.binLatitude : undefined,
      longitude: routeStop.isUpdatedLatLng ? routeStop.binLongitude : undefined,
      orderNo: routeStop.newOrderNumber,
      wasteMaterialTypeId: routeStop.wasteMaterialTypeId,
    }));

    const locationsWithZeroStopNumber = routeStopsModified.filter(location => location.orderNo === 0).length;
    const shouldCreateTravelPath = isTravelPathFeatureEnabled || isNaviV3FeatureEnabled;

    let isOrderNumberChangedByUser = false;
    routeStopsNewOrderNumber.forEach(routeStop => {
      if (
        routeLocations.find(routeLocation => routeLocation.routeLocationId === routeStop.id)?.orderNo !==
        routeStop.newOrderNumber
      ) {
        isOrderNumberChangedByUser = true;
      }
    });

    if (locationsWithZeroStopNumber > 0) {
      createErrorNotification(translate('routes.alertMessages.routeSaveErrorWithNewStopNumberZero'));
    } else {
      const updatedRouteStops = {
        changedStops: routeLocations,
        newStops: [],
        pinnedStops: [],
        deletedStops: [],
      };
      updateRouteStops(
        vendorId,
        routeSummary.routeId,
        updatedRouteStops,
        shouldCreateTravelPath,
        isOrderNumberChangedByUser,
      )(dispatch)
        .then(() => {
          const jobId = get(find(routeStatuses, { routeId: routeSummary.routeId }), 'jobId');
          updateRouteSequenceStatus({
            routeId: routeSummary.routeId,
            jobId,
            status: FINALIZED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
          })(dispatch).then(() => dispatch(push(`/routes/route-tracker/${routeSummary.routeId}`)));
          createSuccessNotification(translate('routes.alertMessages.routeSaved'));
        })
        .catch(() => createErrorNotification(translate('routes.alertMessages.routeSaveError')));
    }
  };

  const renderLocationsTable = (id: number) => {
    const virtualizedProps = {
      itemSize: TABLE_ROW_HEIGHT,
      height: Math.min(filteredStops.length * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 8) || 1,
    };

    if (isRouteStopsLoading && !filteredStops.length) {
      return <PanelSectionLoading />;
    }

    return !filteredStops.length ? (
      <Message padding="sMedium">{translate('routes.noRouteLocations')}</Message>
    ) : (
      <>
        {!!size(routeStopsWithZeroStopNumber) && (
          <PanelSection>
            <Table
              cells={tableCellsMemo}
              rowComponent={NewRouteSequenceEditorPageStopsWithZeroTableRow}
              rows={routeStopsWithZeroStopNumber}
              rowProps={{
                openOrderNumberPopover: openOrderNumberPopover,
                routeId: id,
              }}
              tableHeading={translate('routes.unableToSequenceRoute')}
            />
          </PanelSection>
        )}

        <PanelSection>
          {!!size(routeStopsWithStopNumber) && (
            <SortableTable
              cells={size(routeStopsWithZeroStopNumber) ? [] : tableCellsMemo}
              rows={routeStopsWithStopNumber}
              rowComponent={NewRouteSequenceEditorPageTableRow}
              rowProps={{
                onRouteLocationDrop: onRouteLocationDrop,
                openOrderNumberPopover: openOrderNumberPopover,
                routeId: id,
                vehicleTypeId: routeSummary.vehicleTypeId,
              }}
              virtualized
              virtualizedProps={virtualizedProps}
              sort={onSortOrderChange}
              withClickableRows
            />
          )}
        </PanelSection>
      </>
    );
  };

  return (
    <PageContent isLoading={isSaving || isUpdatingSequenceStatus}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={goBackToPreviousPage} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <Grid>
              <PageTitle>
                {routeSummary.name}
                {isSnowPlowRoute && (
                  <Text size="xLarge">
                    {' '}
                    • {translate('routes.snowPlow.priority')}{' '}
                    {routeSummary.routePriorityTypeId || translate('common.notAvailable')}
                  </Text>
                )}
              </PageTitle>
              {(routeSummary.routeAssistanceEnabled ||
                !!(routeSummary.assistingVehiclesActiveCount || routeSummary.assistingVehiclesCount)) && (
                <ActionButtonTooltip
                  imageSrc={routeAssistance}
                  tooltip={routeAssistTooltip}
                  tooltipAsString
                  margin="small xSmall"
                />
              )}
            </Grid>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <Button
            color="alert"
            onClick={cancelRouteSequence}
            line
            margin="no xSmall no no"
            disabled={isActionButtonsDisabled}
          >
            {translate('routes.cancelSequenceUpdates')}
          </Button>
          <Button
            color="primary"
            margin="no xSmall no no"
            onClick={saveRouteSequence}
            disabled={isActionButtonsDisabled}
          >
            {translate('routes.saveStopSequence')}
          </Button>
        </PageActions>
      </PageHeader>
      {routeSequenceInstructions && <RouteSequenceInstructions />}
      <Panel margin="no no xLarge">
        <PanelSection>
          <RouteSequenceSummaryPanel />
        </PanelSection>
        <PanelSection isLoading={isRouteStopsLoading} centered>
          <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
            <MapContainer size="large">
              <RouteSequenceMapGL routeStops={filteredStops} vehicleTypeId={routeSummary.vehicleTypeId} />
            </MapContainer>
          </Resizable>
        </PanelSection>
        <PanelSection withBorder>
          <Grid multiLine>
            <GridColumn size={'12/12'}>
              <RouteSequenceStopsSearchForm
                initialValues={{ routeStopsSearchTerm: searchTerm }}
                onSearchTermChange={onSearchTermChange}
              />
            </GridColumn>
            <GridColumn size="12/12" padding="no">
              {renderLocationsTable(routeSummary.routeId)}
            </GridColumn>
          </Grid>
        </PanelSection>
      </Panel>
    </PageContent>
  );
}
