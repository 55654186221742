import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';

import {
  getOrderedInsightReport,
  loadFleetInsightReports as doLoadFleetInsightReports,
  loadSnowPlowInsightReports as doLoadSnowPlowInsightReports,
  loadStreetSweepingInsightReports as doLoadStreetSweepingInsightReports,
  loadWasteAuditInsightReports as doLoadWasteAuditInsightReports,
} from '../services/loadInsightReports';

// Actions
const START_FLEET_LOAD = 'insights/insightReports/fleet/START_LOAD';
const COMPLETE_FLEET_LOAD = 'insights/insightReports/fleet/COMPLETE_LOAD';
const FAIL_FLEET_LOAD = 'insights/insightReports/fleet/FAIL_LOAD';

const START_STREET_SWEEPING_LOAD = 'insights/insightReports/streetSweeping/START_LOAD';
const COMPLETE_STREET_SWEEPING_LOAD = 'insights/insightReports/streetSweeping/COMPLETE_LOAD';
const FAIL_STREET_SWEEPING_LOAD = 'insights/insightReports/streetSweeping/FAIL_LOAD';

const START_SNOW_PLOW_LOAD = 'insights/insightReports/snowPlow/START_LOAD';
const COMPLETE_SNOW_PLOW_LOAD = 'insights/insightReports/snowPlow/COMPLETE_LOAD';
const FAIL_SNOW_PLOW_LOAD = 'insights/insightReports/snowPlow/FAIL_LOAD';

const START_WASTE_AUDIT_LOAD = 'insights/insightReports/wasteAudit/START_LOAD';
const COMPLETE_WASTE_AUDIT_LOAD = 'insights/insightReports/wasteAudit/COMPLETE_LOAD';
const FAIL_WASTE_AUDIT_LOAD = 'insights/insightReports/wasteAudit/FAIL_LOAD';

const RESET = 'insights/insightReports/RESET';

interface InsightReportsState {
  fleetInsightReports: any[];
  isLoadingFleet: boolean;
  isLoadingSnowPlow: boolean;
  isLoadingStreetSweeper: boolean;
  isLoadingWasteAudit: boolean;
  snowPlowInsightReports: any[];
  streetSweeperInsightReports: any[];
  wasteAuditInsightReports: any[];
}

// Initial state
const initialState: InsightReportsState = {
  isLoadingFleet: false,
  fleetInsightReports: [],

  isLoadingStreetSweeper: false,
  streetSweeperInsightReports: [],

  isLoadingWasteAudit: false,
  wasteAuditInsightReports: [],

  isLoadingSnowPlow: false,
  snowPlowInsightReports: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case RESET:
      return update(state, {
        $merge: initialState,
      });

    case START_FLEET_LOAD:
      return update(state, {
        $merge: {
          isLoadingFleet: true,
        },
      });

    case COMPLETE_FLEET_LOAD: {
      return update(state, {
        $merge: {
          isLoadingFleet: false,
          fleetInsightReports: getOrderedInsightReport(action.reports, action.insightDashboardPreferences),
        },
      });
    }

    case FAIL_FLEET_LOAD:
      return update(state, {
        $merge: {
          isLoadingFleet: false,
          fleetInsightReports: [],
        },
      });

    case START_STREET_SWEEPING_LOAD:
      return update(state, {
        $merge: {
          isLoadingStreetSweeper: true,
        },
      });

    case COMPLETE_STREET_SWEEPING_LOAD: {
      return update(state, {
        $merge: {
          isLoadingStreetSweeper: false,
          streetSweeperInsightReports: getOrderedInsightReport(action.reports, action.insightDashboardPreferences),
        },
      });
    }

    case FAIL_STREET_SWEEPING_LOAD:
      return update(state, {
        $merge: {
          isLoadingStreetSweeper: false,
          streetSweeperInsightReports: [],
        },
      });

    case START_SNOW_PLOW_LOAD:
      return update(state, {
        $merge: {
          isLoadingSnowPlow: true,
        },
      });

    case COMPLETE_SNOW_PLOW_LOAD: {
      return update(state, {
        $merge: {
          isLoadingSnowPlow: false,
          snowPlowInsightReports: getOrderedInsightReport(action.reports, action.insightDashboardPreferences),
        },
      });
    }

    case FAIL_SNOW_PLOW_LOAD:
      return update(state, {
        $merge: {
          isLoadingSnowPlow: false,
          snowPlowInsightReports: [],
        },
      });

    case START_WASTE_AUDIT_LOAD:
      return update(state, {
        $merge: {
          isLoadingWasteAudit: true,
        },
      });

    case COMPLETE_WASTE_AUDIT_LOAD: {
      return update(state, {
        $merge: {
          isLoadingWasteAudit: false,
          wasteAuditInsightReports: getOrderedInsightReport(action.reports, action.insightDashboardPreferences),
        },
      });
    }

    case FAIL_WASTE_AUDIT_LOAD:
      return update(state, {
        $merge: {
          isLoadingWasteAudit: false,
          wasteAuditInsightReports: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startFleetLoad = () => ({
  type: START_FLEET_LOAD,
});

const completeFleetLoad = (reports: any, insightDashboardPreferences: any) => ({
  type: COMPLETE_FLEET_LOAD,
  reports,
  insightDashboardPreferences,
});

const failFleetLoad = () => ({
  type: FAIL_FLEET_LOAD,
});

const startStreetSweepingLoad = () => ({
  type: START_STREET_SWEEPING_LOAD,
});

const completeStreetSweepingLoad = (reports: any, insightDashboardPreferences: any) => ({
  type: COMPLETE_STREET_SWEEPING_LOAD,
  reports,
  insightDashboardPreferences,
});

const failStreetSweepingLoad = () => ({
  type: FAIL_STREET_SWEEPING_LOAD,
});

const startSnowPlowLoad = () => ({
  type: START_SNOW_PLOW_LOAD,
});

const completeSnowPlowLoad = (reports: any, insightDashboardPreferences: any) => ({
  type: COMPLETE_SNOW_PLOW_LOAD,
  reports,
  insightDashboardPreferences,
});

const failSnowPlowLoad = () => ({
  type: FAIL_SNOW_PLOW_LOAD,
});

const startWasteAuditLoad = () => ({
  type: START_WASTE_AUDIT_LOAD,
});

const completeWasteAuditLoad = (reports: any, insightDashboardPreferences: any) => ({
  type: COMPLETE_WASTE_AUDIT_LOAD,
  reports,
  insightDashboardPreferences,
});

const failWasteAuditLoad = () => ({
  type: FAIL_WASTE_AUDIT_LOAD,
});

export const loadFleetInsightReports =
  (
    vendorId: number,
    date: Date | string,
    dashboardInsightTypeIds: string,
    vehicleTypeId: number,
    insightDashboardPreferences: any,
    daysOfOperation: any[],
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startFleetLoad());
    const loadFleetInsightReportsPromise = doLoadFleetInsightReports(
      vendorId,
      date,
      dashboardInsightTypeIds,
      vehicleTypeId,
      daysOfOperation,
      filtersPreferencesIds,
    );
    loadFleetInsightReportsPromise
      .then(reports => dispatch(completeFleetLoad(reports, insightDashboardPreferences)))
      .catch(() => dispatch(failFleetLoad()));
    return loadFleetInsightReportsPromise;
  };

export const loadStreetSweepingInsightReports =
  (
    vendorId: number,
    date: Date | string,
    dashboardInsightTypeIds: string,
    vehicleTypeId: number,
    insightDashboardPreferences: any,
    daysOfOperation: any[],
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startStreetSweepingLoad());
    const loadStreetSweepingInsightReportsPromise = doLoadStreetSweepingInsightReports(
      vendorId,
      date,
      dashboardInsightTypeIds,
      vehicleTypeId,
      daysOfOperation,
      filtersPreferencesIds,
    );
    loadStreetSweepingInsightReportsPromise
      .then(reports => dispatch(completeStreetSweepingLoad(reports, insightDashboardPreferences)))
      .catch(() => dispatch(failStreetSweepingLoad()));
    return loadStreetSweepingInsightReportsPromise;
  };

export const loadSnowPlowInsightReports =
  (
    vendorId: number,
    date: Date | string,
    dashboardInsightTypeIds: string,
    vehicleTypeId: number,
    insightDashboardPreferences: any,
    daysOfOperation: any[],
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSnowPlowLoad());
    const loadSnowPlowInsightReportsPromise = doLoadSnowPlowInsightReports(
      vendorId,
      date,
      dashboardInsightTypeIds,
      vehicleTypeId,
      daysOfOperation,
      filtersPreferencesIds,
    );
    loadSnowPlowInsightReportsPromise
      .then(reports => dispatch(completeSnowPlowLoad(reports, insightDashboardPreferences)))
      .catch(() => dispatch(failSnowPlowLoad()));
    return loadSnowPlowInsightReportsPromise;
  };

export const loadWasteAuditInsightReports =
  (
    vendorId: number,
    date: Date | string,
    dashboardInsightTypeIds: string,
    vehicleTypeId: number,
    insightDashboardPreferences: any,
    daysOfOperation: any[],
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startWasteAuditLoad());
    const loadWasteAuditInsightReportsPromise = doLoadWasteAuditInsightReports(
      vendorId,
      date,
      dashboardInsightTypeIds,
      vehicleTypeId,
      daysOfOperation,
      filtersPreferencesIds,
    );
    loadWasteAuditInsightReportsPromise
      .then(reports => dispatch(completeWasteAuditLoad(reports, insightDashboardPreferences)))
      .catch(() => dispatch(failWasteAuditLoad()));
    return loadWasteAuditInsightReportsPromise;
  };

export const resetInsightReports = () => ({
  type: RESET,
});
