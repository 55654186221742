import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch } from '../../../core/components';
import geoFenceSearchFormInitialValuesSelector from '../../services/geoFenceSearchFormInitialValuesSelector';

interface ComponentProps extends RouteComponentProps {
  onSearchTermChange(searchTerm: string): void;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class GeoFencesSearchForm extends PureComponent<Props> {
  onSearchTermChange = (event: any, searchTerm: string) => {
    this.props.onSearchTermChange(searchTerm);
  };

  render() {
    return (
      <form>
        <PanelSection padding="sMedium xSmall" withBorder>
          <Grid>
            <GridColumn size="6/12">
              <Field
                name="geoFenceName"
                component={PanelSearch}
                onChange={this.onSearchTermChange}
                padding="sMedium no no no"
                props={{ isClearable: true }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, { location: { search } }: ComponentProps) => ({
  initialValues: geoFenceSearchFormInitialValuesSelector(search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'geoFenceSearch',
      enableReinitialize: true,
    })(GeoFencesSearchForm),
  ),
);
