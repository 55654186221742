import { FC, useMemo } from 'react';
import { filter, find } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { clearDashboardModalMapSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { RouteVehicleCoord } from 'src/dashboard/interfaces/routesData';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import VehicleTrackingsGLPopup from './VehicleTrackingsGLPopup';

interface Props {
  isOnDemandVideoDownloadEnabled: boolean;
}

const VehicleTrackingsGLPopupResolver: FC<Props> = ({ isOnDemandVideoDownloadEnabled }) => {
  const dispatch = useDispatch();

  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routeHistoryData.routeVehiclesBreadCrumbs);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedModalMapFeature);
  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.namespace !== 'vehicleTrackings' ||
      selectedFeature.vehicleTrackingGroupIndex === undefined ||
      selectedFeature.coordinates === undefined
    ) {
      return undefined;
    }

    const { vehicleTrackingGroupIndex: groupIndex, vehicleTrackingsSource, id } = selectedFeature;

    let group: RouteVehicleCoord[] = [];

    if (vehicleTrackingsSource === 'route') {
      const filtered = filter(vehicleTrackingsForRoute?.vehicles, v => v.id === id && v.coords.length > 0);
      if (filtered.length > 0) {
        group = filtered[0].coords[groupIndex];
      }
    }

    if (!vehicleTrackingsForRoute || !group) {
      return undefined;
    }

    // get the closest coordinate to the selected point
    const closestCoordinate = group.reduce((prev, curr) => {
      if (!selectedFeature.coordinates) return prev;

      const prevDistance = Math.sqrt(
        Math.pow(prev.lat - selectedFeature.coordinates.lat, 2) +
          Math.pow(prev.lng - selectedFeature.coordinates.lng, 2),
      );
      const currDistance = Math.sqrt(
        Math.pow(curr.lat - selectedFeature.coordinates.lat, 2) +
          Math.pow(curr.lng - selectedFeature.coordinates.lng, 2),
      );

      return prevDistance < currDistance ? prev : curr;
    }, [] as any);

    const routeVehicle = find(vehicleTrackingsForRoute?.vehicles, { id });

    return {
      vehicle: routeVehicle?.name || '',
      id: selectedFeature.id,
      selectedData: closestCoordinate,
      shouldDisplayDownloadVideo: routeVehicle?.hdv,
      videoDeviceTypeId: routeVehicle?.vdtId,
      anchor: {
        latitude: selectedFeature.coordinates.lat,
        longitude: selectedFeature.coordinates.lng,
      },
    };
  }, [selectedFeature, vehicleTrackingsForRoute]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.anchor.latitude}
      longitude={selectedTracking.anchor.longitude}
      onClose={() => dispatch(clearDashboardModalMapSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingGroupIndex}`}
        successComponent={VehicleTrackingsGLPopup}
        successProps={{
          showTimestamp: true,
          speed: selectedTracking.selectedData.sp,
          unitOfMeasure: getMeasurementType(systemOfMeasurementId),
          vehicleId: selectedTracking.id,
          vehicleName: selectedTracking.vehicle,
          timestamp: selectedTracking.selectedData.ts,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled,
          applicationModeStatusId: selectedTracking.selectedData.am,
          videoDeviceTypeId: selectedTracking.videoDeviceTypeId,
          deviceId: selectedTracking.selectedData.deid,
        }}
        loadingComponent={MapGLPopupLoading}
      />
    </MapGLPopupWrapper>
  );
};

export default VehicleTrackingsGLPopupResolver;
