import { size } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { APPLICATION_STATUSES } from 'src/common/constants';
import { getSpeedInformation } from 'src/common/services/getSpeedInformation';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Button } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import { StrobeImagesModalResolver } from 'src/routes/components/modals';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { AppState } from 'src/store';
import { time, timeStamp } from 'src/utils/services/formatter';
import { getVehicleTrackingCoordinateGroupCenter } from '../../../routes/routePageSections/routeMap/vehicleTrackings/utils';

export default function YRouteMapVehicleTrackingsGLPopup() {
  const dispatch = useDispatch();
  const [isStrobeImageModalOpen, setIsStrobeImageModalOpen] = useState(false);

  const { selectedFeature } = useSelector((state: AppState) => state.routes.mapControls);
  const { unitOfMeasure, vehicleTrackings } = useSelector((state: any) => state.routes.routeMapDetails.routeMapDetails);
  const { strobeImages } = useSelector((state: AppState) => state.routes);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.feature !== RouteMapFeature.vehicleTrackings ||
      selectedFeature.vehicleTrackingCoordinateGroupIndex === undefined
    ) {
      return undefined;
    }

    const { id: vehicleId, vehicleTrackingCoordinateGroupIndex: coordinateGroupIndex } = selectedFeature;

    const vehicleTracking = vehicleTrackings.find((vehicleTracking: any) => vehicleTracking.vehicle.id === vehicleId);
    const coordinateGroup = vehicleTracking?.coordinateGroups[coordinateGroupIndex];

    if (!vehicleTracking || !coordinateGroup) {
      return undefined;
    }

    return {
      unitOfMeasure,
      vehicle: vehicleTracking.vehicle,
      coordinateGroup,
      anchor: selectedFeature.coordinates
        ? {
            latitude: selectedFeature.coordinates.lat,
            longitude: selectedFeature.coordinates.lng,
          }
        : getVehicleTrackingCoordinateGroupCenter(coordinateGroup),
    };
  }, [selectedFeature, vehicleTrackings, unitOfMeasure]);

  if (!selectedTracking) return null;

  const vehicleName = selectedTracking.vehicle.name;
  const timestamp = selectedTracking.coordinateGroup.endTimestamp;
  const applicationModeStatusId = selectedTracking.coordinateGroup.applicationModeStatusId;
  const speed = selectedTracking.coordinateGroup.speed;
  const vehicleId = selectedTracking.vehicle.id;
  const showTimestamp = true;

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={selectedTracking.anchor.latitude}
        longitude={selectedTracking.anchor.longitude}
        onClose={() => dispatch(clearRouteMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle title={vehicleName} />

          <MapGLPopupContent>
            {!!timestamp && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.timestamp')}
                value={showTimestamp ? timeStamp(timestamp) : time(timestamp)}
              />
            )}

            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.appStatus')}
              value={APPLICATION_STATUSES[applicationModeStatusId].name}
            />

            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.speed')}
              value={getSpeedInformation(speed, unitOfMeasure)}
            />

            {!!size(strobeImages.closestStrobeImage) && (
              <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
                <Box mt={5}>
                  <Button margin="no" padding="no" text onClick={() => setIsStrobeImageModalOpen(true)}>
                    <img alt="" width="100px" src={strobeImages.closestStrobeImage.imageUrl} />
                  </Button>
                </Box>
              </LabeledDataView>
            )}
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isStrobeImageModalOpen && (
        <StrobeImagesModalResolver
          vehicleId={vehicleId}
          vehicleName={vehicleName}
          timeStamp={timestamp}
          closeModal={() => setIsStrobeImageModalOpen(false)}
        />
      )}
    </>
  );
}
