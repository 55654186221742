import { filter, map } from 'lodash-es';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Button, ButtonSet, Grid, GridColumn, Popover } from 'src/core/components/styled';
import { Checkbox, DatePicker, Dropdown, Input, PopoverWrapper, TypedField } from 'src/core/components';
import { COMPLETED, IN_PROGRESS } from 'src/routes/constants';
import { customerLocationsSelector } from 'src/customers/ducks';
import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { getExcludedFiltersIds } from 'src/common/utils/filters';
import { GroupsMultiSelect, ServiceZoneDropdown, SupervisorDropdown } from '..';
import { isDateValidValidator, isRequired, maxLength50 } from 'src/utils/services/validator';
import { ROUTE_PRIORITY_TYPES_OPTIONS } from 'src/routes/constants/routePriorityTypes';
import { RouteDetailsFormData } from 'src/routes/interfaces/RouteDetails';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { WASTE_AUDIT } from 'src/fleet/constants';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';
import { Facility } from 'src/common/interfaces/Facility';

type PropsWithoutReduxForm = {
  closeModal: () => void;
  isEditMode: boolean;
};

type CreateEditSnowSweeperRouteFormProps = PropsWithoutReduxForm &
  InjectedFormProps<RouteDetailsFormData, PropsWithoutReduxForm>;

const CREATE_EDIT_SNOW_SWEEPER_ROUTE_FORM = 'CREATE_EDIT_SNOW_SWEEPER_ROUTE_FORM';

export default reduxForm<RouteDetailsFormData, PropsWithoutReduxForm>({
  form: CREATE_EDIT_SNOW_SWEEPER_ROUTE_FORM,
  onSubmitFail: focusFirstInvalidField,
})(function CreateEditSnowSweeperRouteForm({
  change,
  closeModal,
  handleSubmit,
  initialValues,
  isEditMode,
}: CreateEditSnowSweeperRouteFormProps) {
  const truckYardFacilities = useSelector(state => state.fleet.facilities.operationalFacilities);
  const disposalFacilities = useSelector(state => state.fleet.facilities.disposalFacilities);

  let filteredTruckYardFacilities = [] as Facility[];
  if (!!truckYardFacilities)
    filteredTruckYardFacilities = isEditMode
      ? truckYardFacilities?.filter(
          (facility: Facility) =>
            facility.isActive || (!facility.isActive && facility.locationId === initialValues.startingLocationId),
        )
      : truckYardFacilities;

  let filteredCustomerLocations = [] as Facility[];
  if (!!truckYardFacilities && !!disposalFacilities)
    filteredCustomerLocations = isEditMode
      ? [...truckYardFacilities, ...disposalFacilities].filter(
          (facility: Facility) =>
            facility.isActive || (!facility.isActive && facility.locationId === initialValues.endingLocationId),
        )
      : [...truckYardFacilities, ...disposalFacilities];

  const startingLocationOptions = customerLocationsSelector(filteredTruckYardFacilities);
  const endingLocationOptions = customerLocationsSelector(filteredCustomerLocations);

  const routeSummary = useSelector(state => state.routes.routeSummary.routeSummary) || ({} as RouteSummary);
  const supervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const serviceZones = useSelector(state => state.routes.serviceZones.serviceZones) as unknown as ServiceZone[];
  const supervisors = useSelector(state => state.routes.supervisors.supervisors) as Supervisor[];
  const filterPreferences = useSelector(state => state.common.filters.filters);
  const groups = useSelector(state => state.routes.groups.groups);

  // groupIds to exclude those inactive groups  except if initial value is inactive
  const groupIdsToExclude = map(
    filter(groups, group => !initialValues?.groups?.includes(group.id) && !group.isActive),
    'id',
  );

  const {
    routeStatusTypeId,
    supervisorId: selectedSupervisor,
    vehicleTypeName,
    vendorServiceZoneId: selectedServiceZone,
  } = routeSummary;

  const isSupervisorDropdownVisible = vehicleTypeName !== WASTE_AUDIT;

  const isRouteStatusCompleted = routeStatusTypeId === COMPLETED;
  const isRouteStatusInProgress = routeStatusTypeId === IN_PROGRESS;

  const isDatePickerDisabled = isEditMode && (isRouteStatusInProgress || isRouteStatusCompleted);
  const isStartingLocationDisabled = isEditMode && isRouteStatusCompleted;
  const isEndingLocationDisabled = isEditMode && isRouteStatusCompleted;
  const isPriorityTypeRequired = !isEditMode;

  const excludedServiceZones = getExcludedFiltersIds(serviceZones, filterPreferences, FILTER_SERVICE_ZONE_ID).filter(
    (el: number) => el !== selectedServiceZone,
  );
  const excludedSupervisors = getExcludedFiltersIds(supervisors, filterPreferences, FILTER_SUPERVISOR_ID).filter(
    (el: number) => el !== selectedSupervisor,
  );

  const onStartingLocationChange = (_: any, value: number) => {
    change('endingLocationId', value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid multiLine padding="medium no no">
        <GridColumn size="6/12">
          <TypedField
            name="routeName"
            component={Input}
            props={{ label: translate('routes.routeName') }}
            validate={[isRequired, maxLength50]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="routeDate"
            component={DatePicker}
            props={{
              label: translate('common.date'),
              disabledDays: [{ before: TODAY }],
              disabled: isDatePickerDisabled,
            }}
            validate={[isRequired, isDateValidValidator]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="routePriorityTypeId"
            component={Dropdown}
            props={{
              options: ROUTE_PRIORITY_TYPES_OPTIONS,
              label: translate('customers.streetNetwork.priority'),
              isClearable: true,
            }}
            validate={isPriorityTypeRequired ? [isRequired] : []}
          />
        </GridColumn>
        <GridColumn size="6/12">
          {supervisorExperienceEnabled && isSupervisorDropdownVisible && (
            <TypedField
              name="supervisorId"
              component={SupervisorDropdown}
              props={{
                withLabel: true,
                excludeSupervisorsIds: excludedSupervisors,
                shouldGroupByAvailability: true,
              }}
            />
          )}
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="startingLocationId"
            component={Dropdown}
            props={{
              options: startingLocationOptions,
              label: translate('routes.startingLocation'),
              isClearable: true,
              disabled: isStartingLocationDisabled,
            }}
            onChange={onStartingLocationChange}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="endingLocationId"
            component={Dropdown}
            props={{
              options: endingLocationOptions,
              label: translate('routes.endingLocation'),
              isClearable: true,
              disabled: isEndingLocationDisabled,
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="groups"
            component={GroupsMultiSelect}
            props={{
              withLabel: true,
              cantSelectInactive: true,
              excludeGroupsIds: groupIdsToExclude,
              multiSelectProps: {
                defaultToAll: false,
                canCheckAll: false,
              },
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="vendorServiceZoneId"
            component={ServiceZoneDropdown}
            props={{
              label: translate('routes.serviceZone'),
              excludeServiceZonesIds: excludedServiceZones,
              dropdownProps: {
                menuPosition: 'fixed',
              },
            }}
          />
        </GridColumn>
      </Grid>
      <Grid multiLine>
        <GridColumn size="12/12">
          <PopoverWrapper
            triggerButton={
              <TypedField name="isTestRoute" component={Checkbox} props={{ label: translate('routes.testRoute') }} />
            }
            popoverContent={<Popover>{translate('routes.testRoutePopover')}</Popover>}
          />
        </GridColumn>
      </Grid>

      <Grid centered>
        <GridColumn margin="small no" size="8/12">
          <ButtonSet>
            <Button type="button" color="secondary" onClick={closeModal}>
              {translate('common.cancel')}
            </Button>
            <Button type="submit" color="primary">
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </form>
  );
});
