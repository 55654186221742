import { AnyAction, Dispatch } from 'redux';

import { getCustomerTypesForContainers } from '../services/customerTypes';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Constants
const START_LOAD_CUSTOMER = 'fleet/customerTypes/START_LOAD_CUSTOMER';
const FAIL_LOAD_CUSTOMER = 'fleet/customerTypes/FAIL_LOAD_CUSTOMER';
const COMPLETE_LOAD_CUSTOMER = 'fleet/customerTypes/COMPLETE_LOAD_CUSTOMER';

// Action Creators
const startLoad = () => ({
  type: START_LOAD_CUSTOMER,
});

const failLoad = () => ({
  type: FAIL_LOAD_CUSTOMER,
});

const completeLoad = (customerTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_CUSTOMER,
  customerTypes,
});

// Async Actions
export const loadCustomerTypesForContainers = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getCustomerTypesForContainersPromises = getCustomerTypesForContainers();
  getCustomerTypesForContainersPromises.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getCustomerTypesForContainersPromises;
};

// InitialState
const initialState = {
  isLoading: false,
  customerTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CUSTOMER:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_CUSTOMER:
      return {
        ...state,
        isLoading: false,
        customerTypes: [],
      };
    case COMPLETE_LOAD_CUSTOMER:
      return {
        ...state,
        isLoading: false,
        customerTypes: action.customerTypes,
      };
    default:
      return state;
  }
};
