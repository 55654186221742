import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { LocationServiceTypes } from '../interfaces/LocationServiceTypes';
import doLoadServiceTypesForLocation from '../services/locations';

// Actions
const START_LOAD = 'common/locationServiceTypes/START_LOAD';
const COMPLETE_LOAD = 'common/locationServiceTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/locationServiceTypes/FAIL_LOAD';
const RESET = 'common/locationServiceTypes/RESET';

interface State {
  isLoading: boolean;
  locationServiceTypes: LocationServiceTypes[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  locationServiceTypes: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationServiceTypes: action.locationServiceTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationServiceTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (locationServiceTypes: LocationServiceTypes[]) => ({
  type: COMPLETE_LOAD,
  locationServiceTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadServiceTypesForLocation = (locationId: number, serviceContactId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadServiceTypesForLocationPromise = doLoadServiceTypesForLocation(locationId, serviceContactId);
  loadServiceTypesForLocationPromise
    .then(serviceTypes => dispatch(completeLoad(serviceTypes)))
    .catch(() => dispatch(failLoad()));
  return loadServiceTypesForLocationPromise;
};

export const resetServiceTypesForLocation = () => ({
  type: RESET,
});
