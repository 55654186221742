import { http } from '../../core/services/http';
import { SnowPlowSettings } from '../interfaces/SnowPlowSettings';

export const loadSnowPlowSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/snowPlowSettings`).then(response => response.data);

export const saveSnowPlowSettings = (vendorId: number, snowPlowSettings: SnowPlowSettings) =>
  http
    .put(`/vendors/${vendorId}/snowPlowSettings`, snowPlowSettings)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadSnowPlowDefaultSettings = (vendorId: number) => {
  return http.get(`/vendors/${vendorId}/snowPlowDefaultSettings`).then(response => response.data);
};
