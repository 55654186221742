import { get } from 'lodash-es';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldArray, getFormValues, InjectedFormProps } from 'redux-form';

import { FormError } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import TimePickerInput from 'src/core/components/TimePicker';
import {
  ROUTE_BUILDER_SETTINGS_FORM_NAME,
  ROUTE_SEQUENCE_SETTINGS_FORM_NAME,
} from 'src/vendors/constants/routeSettingsForm';
import { RouteSequenceFormValues } from 'src/vendors/interfaces/RouteSequenceSettings';
import { Input, Radio, Switch } from '../../../core/components';
import normalizeNumber from '../../../core/services/normalizeNumber';
import translate from '../../../core/services/translate';
import { RESIDENTIAL_ID } from '../../../fleet/constants';
import {
  isDecimalUpTo1OrNull,
  isOptionalDecimalUpTo1OrNull,
  isOptionalNumberOrZero,
  isRequired,
  isRequiredNumber,
  isRequiredOrZero,
  maxLength4,
  maxLength6,
  maxValueNumeric86399,
  maxValueNumeric9999,
  minValueNumeric1,
} from '../../../utils/services/validator';
import {
  RouteSettingsFormRadioGroup,
  RouteSettingsFormRadioInstance,
  RouteSettingsFormRow,
  RouteSettingsFormRowHeader,
  RouteSettingsFormRowInputHolder,
} from '../styled/RouteSettingsForm';
import RouteSettingsTurnTimeField from './RouteSettingsTurnTimeField';

export const getTotalBreakTimeDate = (amBreakTime?: Date, pmBreakTime?: Date, lunchBreakTime?: Date) => {
  let totalBreakTimeHours = 0;
  let totalBreakTimeMinutes = 0;
  let totalBreakTimeSeconds = 0;
  if (amBreakTime && moment(amBreakTime).isValid()) {
    totalBreakTimeHours += amBreakTime.getHours();
    totalBreakTimeMinutes += amBreakTime.getMinutes();
    totalBreakTimeSeconds += amBreakTime.getSeconds();
  }
  if (pmBreakTime && moment(pmBreakTime).isValid()) {
    totalBreakTimeHours += pmBreakTime.getHours();
    totalBreakTimeMinutes += pmBreakTime.getMinutes();
    totalBreakTimeSeconds += pmBreakTime.getSeconds();
  }
  if (lunchBreakTime && moment(lunchBreakTime).isValid()) {
    totalBreakTimeHours += lunchBreakTime.getHours();
    totalBreakTimeMinutes += lunchBreakTime.getMinutes();
    totalBreakTimeSeconds += lunchBreakTime.getSeconds();
  }

  return moment()
    .set('hours', totalBreakTimeHours)
    .set('minutes', totalBreakTimeMinutes)
    .set('seconds', totalBreakTimeSeconds)
    .toDate();
};

interface Props {
  index?: number;
  isRouteBuilder?: boolean;
  vehicleTypeId?: number;
  defaultRouteStartTime?: Date;
  globalTurnTimeSettingsEnabled?: string;
  hasError?: boolean[];
  setHasError?: (hasError: boolean[]) => void;
  change: InjectedFormProps['change'];
}

const RouteSettingsForm: React.FC<Props> = ({
  isRouteBuilder = false,
  index,
  vehicleTypeId,
  defaultRouteStartTime,
  globalTurnTimeSettingsEnabled,
  hasError,
  setHasError,
  change,
}) => {
  const [error, setError] = React.useState<string | null>(null);

  const formValues = useSelector(
    getFormValues(isRouteBuilder ? ROUTE_BUILDER_SETTINGS_FORM_NAME : ROUTE_SEQUENCE_SETTINGS_FORM_NAME),
  ) as RouteSequenceFormValues;
  const amBreakTime = get(formValues, isRouteBuilder ? 'amBreakTime' : `settings[${index}].amBreakTime`);
  const pmBreakTime = get(formValues, isRouteBuilder ? 'pmBreakTime' : `settings[${index}].pmBreakTime`);
  const lunchBreakTime = get(formValues, isRouteBuilder ? 'lunchBreakTime' : `settings[${index}].lunchBreakTime`);
  const maxRouteTime = get(formValues, isRouteBuilder ? 'maxRouteTime' : `settings[${index}].maxRouteTime`);

  const isResidential = vehicleTypeId === RESIDENTIAL_ID;

  const showAverageDisposalTime = false;
  const showStreetLevelParameters = false;
  const showGlobalTurnTimeParameters = false;

  const names = React.useMemo(() => {
    const cache: { [key: string]: string } = {
      routeStartTime: 'routeStartTime',
      maxRouteTime: 'maxRouteTime',
      serviceTimePerStop: 'serviceTimePerStop',
      preStartTime: 'preStartTime',
      postEndTime: 'postEndTime',
      totalBreakTime: 'totalBreakTime',
      amBreakTime: 'amBreakTime',
      pmBreakTime: 'pmBreakTime',
      lunchBreakTime: 'lunchBreakTime',
      averageDisposalTime: 'averageDisposalTime',
      vehicleCapacity: 'vehicleCapacity',
      pUCapacityAllotment: 'pUCapacityAllotment',
      singleSideServiceTimePerStop: 'singleSideServiceTimePerStop',
      bothSidesServiceTimePerStop: 'bothSidesServiceTimePerStop',
      globalTurnTimeSettingsEnabled: 'globalTurnTimeSettingsEnabled',
      avoidOptionalUTurns: 'avoidOptionalUTurns',
      leftTurnTimePenalty: 'leftTurnTimePenalty',
      rightTurnTimePenalty: 'rightTurnTimePenalty',
      uTurnTimePenalty: 'uTurnTimePenalty',
      straightTimePenalty: 'straightTimePenalty',
      streetTurnTimes: 'streetTurnTimes',
    };

    if (isRouteBuilder) {
      return cache;
    }

    Object.keys(cache).forEach(key => {
      cache[key] = `settings[${index}].${key}`;
    });

    return cache;
  }, [isRouteBuilder, index]);

  const onTimeChange = (name: string, value: Date | string) => {
    const dateTime = moment(value, 'HH:mm:ss');
    if (dateTime.isValid()) change(name, dateTime.toDate());
    else change(name, null);
  };

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      const totalBreakTime = getTotalBreakTimeDate(amBreakTime, pmBreakTime, lunchBreakTime);
      isRouteBuilder
        ? change('totalBreakTime', totalBreakTime)
        : change(`settings[${index}].totalBreakTime`, totalBreakTime);

      // validation for totalBreakTime field
      if (maxRouteTime && totalBreakTime > maxRouteTime) {
        !error && setError(translate('routes.routeSettings.totalBreakTimeHasToBeLessThanMaxRouteTime'));
        if (hasError && setHasError && !hasError[index || 0]) {
          const hasErrorCopy = [...hasError];
          hasErrorCopy[index || 0] = true;
          setHasError([...hasErrorCopy]);
        }
      } else {
        error && setError(null);
        if (hasError && setHasError && hasError[index || 0]) {
          const hasErrorCopy = [...hasError];
          hasErrorCopy[index || 0] = false;
          setHasError([...hasErrorCopy]);
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [amBreakTime, pmBreakTime, lunchBreakTime, index]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <RouteSettingsFormRowHeader>
        <span>{translate('routes.routeSettings.routeTime')}</span>
      </RouteSettingsFormRowHeader>
      <RouteSettingsFormRow>
        <span>{translate('routes.routeSettings.startTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.routeStartTime}
            component={TimePickerInput}
            validate={[isRequired]}
            props={{
              maxDetail: 'second',
              format: 'hh:mm:ss a',
              time: defaultRouteStartTime,
              onTimeChange: (value: Date | string) => onTimeChange(names.routeStartTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>
      <RouteSettingsFormRow>
        <span>{translate('routes.routeSettings.maxRouteTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.maxRouteTime}
            component={TimePickerInput}
            validate={[isRequired]}
            props={{
              width: '100%',
              margin: 'no',
              format: 'HH:mm:ss',
              maxDetail: 'second',
              onTimeChange: (value: string | Date) => onTimeChange(names.maxRouteTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>
      {!isResidential && (
        <RouteSettingsFormRow>
          <span>{translate('routes.routeSettings.serviceTimePerStop')}</span>

          <RouteSettingsFormRowInputHolder>
            <Field
              name={names.serviceTimePerStop}
              component={TimePickerInput}
              props={{
                width: '100%',
                margin: 'no',
                maxDetail: 'second',
                format: 'HH:mm:ss',
                onTimeChange: (value: string | Date) => onTimeChange(names.serviceTimePerStop, value),
              }}
            />
          </RouteSettingsFormRowInputHolder>
        </RouteSettingsFormRow>
      )}
      <RouteSettingsFormRow>
        <span>{translate('routes.routeSettings.preTripTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.preStartTime}
            component={TimePickerInput}
            props={{
              width: '100%',
              margin: 'no',
              maxDetail: 'second',
              format: 'HH:mm:ss',
              onTimeChange: (value: string | Date) => onTimeChange(names.preStartTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>
      <RouteSettingsFormRow>
        <span>{translate('routes.routeSettings.postTripTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.postEndTime}
            component={TimePickerInput}
            props={{
              width: '100%',
              margin: 'no',
              maxDetail: 'second',
              format: 'HH:mm:ss',
              onTimeChange: (value: string | Date) => onTimeChange(names.postEndTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>
      <RouteSettingsFormRow>
        <span>{translate('routes.routeSettings.totalBreakTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.totalBreakTime}
            component={TimePickerInput}
            props={{
              width: '100%',
              margin: 'no',
              maxDetail: 'second',
              format: 'HH:mm:ss',
              readOnly: true,
              onTimeChange: (value: string | Date) => onTimeChange(names.totalBreakTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>
      {error && (
        <Box padding="no medium">
          <FormError>{error}</FormError>
        </Box>
      )}
      <RouteSettingsFormRow margin="no no no medium">
        <span>{translate('routes.routeSettings.amBreakTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.amBreakTime}
            component={TimePickerInput}
            props={{
              width: '100%',
              margin: 'no',
              maxDetail: 'second',
              format: 'HH:mm:ss',
              onTimeChange: (value: string | Date) => onTimeChange(names.amBreakTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>
      <RouteSettingsFormRow margin="no no no medium">
        <span>{translate('routes.routeSettings.lunchBreakTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.lunchBreakTime}
            component={TimePickerInput}
            props={{
              width: '100%',
              margin: 'no',
              maxDetail: 'second',
              format: 'HH:mm:ss',
              onTimeChange: (value: string | Date) => onTimeChange(names.lunchBreakTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>
      <RouteSettingsFormRow margin="no no no medium">
        <span>{translate('routes.routeSettings.pmBreakTime')}</span>

        <RouteSettingsFormRowInputHolder>
          <Field
            name={names.pmBreakTime}
            component={TimePickerInput}
            props={{
              width: '100%',
              margin: 'no',
              maxDetail: 'second',
              format: 'HH:mm:ss',
              onTimeChange: (value: string | Date) => onTimeChange(names.pmBreakTime, value),
            }}
          />
        </RouteSettingsFormRowInputHolder>
      </RouteSettingsFormRow>{' '}
      {showAverageDisposalTime && (
        <RouteSettingsFormRow>
          <span>{translate('routes.routeSettings.averageDisposalTime')}</span>

          <RouteSettingsFormRowInputHolder>
            <Field
              name={names.averageDisposalTime}
              type="number"
              component={Input}
              validate={[isOptionalNumberOrZero, maxLength4]}
              props={{
                width: '100%',
                margin: 'no',
                placeholder: translate('routes.routeSettings.seconds'),
              }}
              normalize={normalizeNumber}
            />
          </RouteSettingsFormRowInputHolder>
        </RouteSettingsFormRow>
      )}
      {isRouteBuilder && (
        <>
          <RouteSettingsFormRow>
            <span>{translate('routes.routeSettings.truckCapacity')}</span>

            <RouteSettingsFormRowInputHolder>
              <Field
                name={names.vehicleCapacity}
                type="number"
                component={Input}
                validate={[isOptionalDecimalUpTo1OrNull, maxLength6, maxValueNumeric9999]}
                props={{
                  width: '100%',
                  margin: 'no',
                  min: 0,
                }}
                normalize={normalizeNumber}
              />
            </RouteSettingsFormRowInputHolder>
          </RouteSettingsFormRow>

          <RouteSettingsFormRow>
            <span>{translate('routes.routeSettings.pUCapacityAllotment')}</span>

            <RouteSettingsFormRowInputHolder>
              <Field
                name={names.pUCapacityAllotment}
                type="number"
                component={Input}
                validate={[isRequiredOrZero, isDecimalUpTo1OrNull, maxLength6, maxValueNumeric9999]}
                props={{
                  width: '100%',
                  margin: 'no',
                  min: 0,
                }}
                normalize={normalizeNumber}
              />
            </RouteSettingsFormRowInputHolder>
          </RouteSettingsFormRow>
        </>
      )}
      {isResidential && (
        <>
          <RouteSettingsFormRowHeader>
            <span>{translate('routes.routeSettings.serviceSideParameters')}</span>
          </RouteSettingsFormRowHeader>

          <RouteSettingsFormRow>
            <span>{translate('routes.routeSettings.singleSideServiceTimePerStop')}</span>

            <RouteSettingsFormRowInputHolder>
              <Field
                name={names.singleSideServiceTimePerStop}
                type="number"
                component={Input}
                validate={[isRequiredNumber, minValueNumeric1, maxValueNumeric86399]}
                props={{
                  width: '100%',
                  margin: 'no',
                  placeholder: translate('routes.routeSettings.seconds'),
                }}
                normalize={normalizeNumber}
              />
            </RouteSettingsFormRowInputHolder>
          </RouteSettingsFormRow>

          <RouteSettingsFormRow>
            <span>{translate('routes.routeSettings.bothSidesServiceTimePerStop')}</span>

            <RouteSettingsFormRowInputHolder>
              <Field
                name={names.bothSidesServiceTimePerStop}
                type="number"
                component={Input}
                validate={[isRequiredNumber, minValueNumeric1, maxValueNumeric86399]}
                props={{
                  width: '100%',
                  margin: 'no',
                  placeholder: translate('routes.routeSettings.seconds'),
                }}
                normalize={normalizeNumber}
              />
            </RouteSettingsFormRowInputHolder>
          </RouteSettingsFormRow>
        </>
      )}
      {showGlobalTurnTimeParameters && (
        <>
          <RouteSettingsFormRowHeader>
            <span>{translate('routes.routeSettings.turnTimeParameters')}</span>
          </RouteSettingsFormRowHeader>

          <RouteSettingsFormRow>
            <RouteSettingsFormRadioGroup>
              <RouteSettingsFormRadioInstance>
                <RouteSettingsFormRowInputHolder>
                  <Field
                    name={names.globalTurnTimeSettingsEnabled}
                    type="radio"
                    value="true"
                    component={Radio}
                    props={{
                      label: translate('routes.routeSettings.global'),
                    }}
                  />
                </RouteSettingsFormRowInputHolder>
              </RouteSettingsFormRadioInstance>

              {showStreetLevelParameters && (
                <RouteSettingsFormRadioInstance>
                  <RouteSettingsFormRowInputHolder>
                    <Field
                      name={names.globalTurnTimeSettingsEnabled}
                      type="radio"
                      value="false"
                      component={Radio}
                      props={{
                        label: translate('routes.routeSettings.streetLevel'),
                      }}
                    />
                  </RouteSettingsFormRowInputHolder>
                </RouteSettingsFormRadioInstance>
              )}
            </RouteSettingsFormRadioGroup>
          </RouteSettingsFormRow>

          {globalTurnTimeSettingsEnabled === 'true' && (
            <>
              <RouteSettingsFormRow>
                <span>{translate('routes.routeSettings.avoidOptionalUTurns')}</span>

                <RouteSettingsFormRowInputHolder>
                  <Field name={names.avoidOptionalUTurns} component={Switch} />
                </RouteSettingsFormRowInputHolder>
              </RouteSettingsFormRow>

              <RouteSettingsFormRow>
                <span>{translate('routes.routeSettings.leftTurnTimePenalty')}</span>

                <RouteSettingsFormRowInputHolder>
                  <Field
                    name={names.leftTurnTimePenalty}
                    type="number"
                    component={Input}
                    validate={[isOptionalNumberOrZero, maxLength4]}
                    props={{
                      width: '100%',
                      margin: 'no',
                      placeholder: translate('routes.routeSettings.seconds'),
                    }}
                    normalize={normalizeNumber}
                  />
                </RouteSettingsFormRowInputHolder>
              </RouteSettingsFormRow>

              <RouteSettingsFormRow>
                <span>{translate('routes.routeSettings.rightTurnTimePenalty')}</span>

                <RouteSettingsFormRowInputHolder>
                  <Field
                    name={names.rightTurnTimePenalty}
                    type="number"
                    component={Input}
                    validate={[isOptionalNumberOrZero, maxLength4]}
                    props={{
                      width: '100%',
                      margin: 'no',
                      placeholder: translate('routes.routeSettings.seconds'),
                    }}
                    normalize={normalizeNumber}
                  />
                </RouteSettingsFormRowInputHolder>
              </RouteSettingsFormRow>

              <RouteSettingsFormRow>
                <span>{translate('routes.routeSettings.uTurnTimePenalty')}</span>

                <RouteSettingsFormRowInputHolder>
                  <Field
                    name={names.uTurnTimePenalty}
                    type="number"
                    component={Input}
                    validate={[isOptionalNumberOrZero, maxLength4]}
                    props={{
                      width: '100%',
                      margin: 'no',
                      placeholder: translate('routes.routeSettings.seconds'),
                    }}
                    normalize={normalizeNumber}
                  />
                </RouteSettingsFormRowInputHolder>
              </RouteSettingsFormRow>

              <RouteSettingsFormRow>
                <span>{translate('routes.routeSettings.straightTimePenalty')}</span>

                <RouteSettingsFormRowInputHolder>
                  <Field
                    name={names.straightTimePenalty}
                    type="number"
                    component={Input}
                    validate={[isOptionalNumberOrZero, maxLength4]}
                    props={{
                      width: '100%',
                      margin: 'no',
                      placeholder: translate('routes.routeSettings.seconds'),
                    }}
                    normalize={normalizeNumber}
                  />
                </RouteSettingsFormRowInputHolder>
              </RouteSettingsFormRow>
            </>
          )}
        </>
      )}
      {globalTurnTimeSettingsEnabled === 'false' && showStreetLevelParameters && (
        <FieldArray name={names.streetTurnTimes} component={RouteSettingsTurnTimeField} props={{}} />
      )}
    </>
  );
};

export default RouteSettingsForm;
