import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Modal } from '../../../core/components';
import { ModalTitle } from '../../../core/components/styled';
import { ServiceZoneForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props {
  isSaving: boolean;
  closeModal: (pristine: boolean) => void;
  onServiceZoneSubmit: (data: any) => void;
  serviceZoneId?: number;
}

class ServiceZoneEditorModal extends PureComponent<Props> {
  render() {
    const { isSaving, closeModal, onServiceZoneSubmit, serviceZoneId } = this.props;

    return (
      <Modal padding="medium" isLoading={isSaving}>
        <ModalTitle>{translate(`routes.${serviceZoneId ? 'edit' : 'create'}ServiceZone`)}</ModalTitle>
        <ServiceZoneForm onSubmit={onServiceZoneSubmit} serviceZoneId={serviceZoneId} onCancel={closeModal} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isSaving: state.routes.serviceZones.isSaving,
});

export default connect(mapStateToProps)(ServiceZoneEditorModal);
