import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { map } from 'lodash-es';
import moment from 'moment';

import {
  checkIfGeoFenceIsEnabled,
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
} from '../../..//vendors/ducks/features';
import { currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getExcludeVehicleFilters } from 'src/common/utils/filters';
import { getPersistentFilters, setPersistentFilters } from '../../services/persistentFilterStorage';
import { getQueryParams } from '../../../utils/services/queryParams';
import {
  loadFeatures,
  loadSnowPlowSettings,
  loadStreetSweepingSettings,
  loadUserById,
  loadVendor,
} from '../../../vendors/ducks';
import { loadFiltersSettings } from 'src/common/ducks';
import { loadVehicles, loadCityInsightTypes, loadPickupExceptionTypes } from '../../ducks';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { shouldLoadPermissionsSelector, loadPermissions } from '../../../account/ducks';
import { TODAY_FORMATTED } from '../../../core/constants';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import DashboardPage from './DashboardPage';
import store, { AppState } from '../../../store';
import translate from '../../../core/services/translate';
import { loadGeoFenceFilterOptions } from 'src/routes/ducks';

interface Props extends RouteComponentProps {
  loadCityInsightTypes: DuckFunction<typeof loadCityInsightTypes>;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadPickupExceptionTypes: DuckFunction<typeof loadPickupExceptionTypes>;
  loadSnowPlowSettings: DuckFunction<typeof loadSnowPlowSettings>;
  loadStreetSweepingSettings: DuckFunction<typeof loadStreetSweepingSettings>;
  loadUserById: DuckFunction<typeof loadUserById>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadVendor: DuckFunction<typeof loadVendor>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadGeoFenceFilterOptions: DuckFunction<typeof loadGeoFenceFilterOptions>;
  shouldLoadPermissions: boolean;
  userId: string;
  vendorId?: number;
}

class DashboardPageResolver extends PureComponent<Props> {
  loadVehicleAndCityInsightTypes = async () => {
    const {
      loadCityInsightTypes,
      loadFeatures,
      loadPermissions,
      loadPickupExceptionTypes,
      loadSnowPlowSettings,
      loadStreetSweepingSettings,
      loadUserById,
      loadVehicles,
      loadVehicleTypesForVendor,
      loadVendor,
      loadFiltersSettings,
      loadGeoFenceFilterOptions,
      location: { search },
      shouldLoadPermissions,
      userId,
    } = this.props;

    const vendorId = this.props.vendorId as number;
    const { date: urlDate } = getQueryParams(search);
    const { date: persistentDate } = getPersistentFilters(vendorId);
    const defaultDate = TODAY_FORMATTED;

    let date = defaultDate;

    if (urlDate) {
      date = urlDate;
    } else if (persistentDate) {
      const isCorrectDate = moment(persistentDate, 'MM/DD/YYYY', true).isValid();
      if (isCorrectDate) {
        const correctDate = moment(persistentDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
        date = correctDate;
      }
    }

    if (date !== persistentDate) {
      setPersistentFilters({});
    }

    await loadFeatures(vendorId);

    const filters = await loadFiltersSettings(vendorId, userId);

    const isSnowPlowFeatureEnabled = checkIfSnowPlowIsEnabled(store.getState());
    const isStreetSweepingFeatureEnabled = checkIfStreetSweepingIsEnabled(store.getState());
    const isGeoFenceActive = checkIfGeoFenceIsEnabled(store.getState());

    let vehicleTypes = map(VEHICLE_TYPE_IDS, vt => vt.technicalName);

    const excludeVehicleTypes = getExcludeVehicleFilters(filters);
    vehicleTypes = vehicleTypes.filter(vt => !excludeVehicleTypes.includes(vt));

    const dependencies = [
      loadVehicleTypesForVendor(vendorId),
      loadCityInsightTypes(vendorId),
      loadPickupExceptionTypes(vendorId),
      loadVendor(vendorId),
      loadUserById(vendorId, userId),
      loadVehicles(vendorId, date, isGeoFenceActive, vehicleTypes),
      loadGeoFenceFilterOptions({ vendorId, routeDate: date }),
    ];

    if (isSnowPlowFeatureEnabled) dependencies.push(loadSnowPlowSettings(vendorId));
    if (isStreetSweepingFeatureEnabled) dependencies.push(loadStreetSweepingSettings(vendorId));
    if (shouldLoadPermissions) dependencies.unshift(loadPermissions());

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('dashboard.dashboard')}</DocumentTitle>
        <Resolver
          successComponent={DashboardPage}
          loadingComponent={PageLoading}
          resolve={this.loadVehicleAndCityInsightTypes}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: (shouldLoadPermissionsSelector as any)(state.account.login, state.account.permissions),
  userId: currentUserIdSelector(state.account.login) as any,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadCityInsightTypes,
  loadFeatures,
  loadPermissions,
  loadPickupExceptionTypes,
  loadSnowPlowSettings,
  loadStreetSweepingSettings,
  loadUserById,
  loadVehicles,
  loadVehicleTypesForVendor,
  loadVendor,
  loadFiltersSettings,
  loadGeoFenceFilterOptions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPageResolver));
