import { change } from 'redux-form';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { Dropdown, TypedField } from 'src/core/components';
import { DropDownFieldProps } from 'src/common/components/DropDownFieldProps';
import { RATE_CODE_EDITOR_FORM } from './RateCodeModal';
import { RateType } from 'src/finance/interfaces/ServiceRates';
import translate from 'src/core/services/translate';

interface RateTypeByWorkOrderOrServiceContractIdDropdownProps extends DropDownFieldProps {
  margin?: string;
  uomFieldName: string;
}

const RateTypesByWorkOrderOrServiceContractIdDropdown = (
  props: RateTypeByWorkOrderOrServiceContractIdDropdownProps,
) => {
  const {
    dropdownProps,
    input: { name, onChange: inputOnChange },
    label,
    margin,
    placeholder,
    uomFieldName,
    withLabel,
    withPlaceholder,
  } = props;

  const dispatch = useDispatch();

  const rateTypesByWorkOrder: RateType[] =
    useSelector((state: AppState) => state.customers.service.rateTypesByWorkOrder) || [];
  const rateTypesByServiceContractId: RateType[] =
    useSelector((state: AppState) => state.customers.service.rateTypesByServiceContractId) || [];
  const allRateTypes: RateType[] = useSelector((state: AppState) => state.common.rateCodeTypes.rateCodes) || [];

  const rateTypes = !!rateTypesByWorkOrder.length
    ? rateTypesByWorkOrder
    : !!rateTypesByServiceContractId.length
    ? rateTypesByServiceContractId
    : allRateTypes;

  const onChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: string | undefined,
    _previousValue: string | undefined,
    fieldName: string,
  ) => {
    inputOnChange({ fieldName, value });
    dispatch(change(RATE_CODE_EDITOR_FORM, uomFieldName, null));
  };

  const rateTypeOptions = rateTypes
    ? rateTypes.map(rate => ({
        label: `${rate.typeCode} / ${rate.description}`,
        value: rate.id,
      }))
    : [];

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChange}
      props={{
        width: '100%',
        label: label || (withLabel ? translate('finance.modal.rateType') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('finance.modal.rateType') : undefined),
        options: rateTypeOptions,
        ...dropdownProps,
        menuPosition: 'fixed',
        margin: margin,
      }}
    />
  );
};

export default RateTypesByWorkOrderOrServiceContractIdDropdown;
