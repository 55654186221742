import { useState, memo, Fragment } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { Button, PanelSearchIcon, PanelSearchInput, PanelSearch } from './styled';
import translate from '../services/translate';
import { FixedSizeList, VariableSizeList } from 'react-window';
import { REACT_WINDOW_CLASS_NAME } from '../constants/reactWindow';
import { openMobilePageDetailOverlay } from '../ducks/mobilePage';
import { useDispatch } from 'react-redux';
import { MobileWidthView } from './mediaQueries/MobileWidthView';
import MobileListDetailViewOverlay from './MobileListDetailViewOverlay';
import { useSelector } from '../hooks/useSelector';

const MobileListContainer = styled.div`
  width: 100%;
  position: relative;
`;

const RowContainer = styled.div<{ hasDetails: boolean }>`
  display: flex;
  padding: 0 10px;
  align-items: center;
`;

const RowContent = styled.div`
  flex: 1;
  width: calc(100% - 20px);
`;

const DetailsTriggerContainer = styled(Button)`
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

const SearchPanel = styled(PanelSearch)`
  padding: 15px 10px;
  background: ${p => p.theme.grayLight};
`;

const Rows = styled.div``;

interface Props {
  rows: any[];
  rowProps?: any;
  rowComponent: React.FC<any>;
  detailsComponent?: any;
  onSearch?: (searchTerm: string) => void;
  searchPlaceholder?: string;
  detailsComponentProps?: any;
  onSelectActiveRow?: (activeRow: any) => void;
  virtualizedProps?: any;
}

const Row = ({ data, style, index }: any) => {
  const { RowComponent, rowProps, rows, detailsComponent, onSelectActiveRow } = data;

  return (
    <RowContainer
      hasDetails={!!detailsComponent}
      key={index}
      onClick={() => detailsComponent && onSelectActiveRow && onSelectActiveRow(rows[index])}
      style={style}
    >
      <RowContent>
        <RowComponent key={index} index={index} orderNumber={index} {...rowProps} {...rows[index]} />
      </RowContent>
      {!!detailsComponent && onSelectActiveRow && (
        <div>
          <DetailsTriggerContainer text>
            <Icon width="24px" icon="arrowRight" />
          </DetailsTriggerContainer>
        </div>
      )}
    </RowContainer>
  );
};

const RowList: React.FC<Props> = memo(
  ({ rows, rowComponent: RowComponent, detailsComponent, rowProps = {}, onSelectActiveRow, virtualizedProps }) => {
    const getRows = () =>
      rows.map((row, i) => (
        <RowContainer
          onClick={() => detailsComponent && onSelectActiveRow && onSelectActiveRow(row)}
          key={i}
          hasDetails={!!detailsComponent}
        >
          <RowContent>
            <RowComponent {...row} {...rowProps} />
          </RowContent>
          {!!detailsComponent && onSelectActiveRow && (
            <div>
              <DetailsTriggerContainer text>
                <Icon width="24px" icon="arrowRight" />
              </DetailsTriggerContainer>
            </div>
          )}
        </RowContainer>
      ));

    const ListWrapper: any = virtualizedProps
      ? virtualizedProps && virtualizedProps.itemSize === 'function'
        ? VariableSizeList
        : FixedSizeList
      : Fragment;

    const props = virtualizedProps
      ? {
          ...virtualizedProps,
          itemCount: rows.length,
          itemData: { rows, RowComponent, rowProps, detailsComponent, onSelectActiveRow },
          width: '100%',
          className: REACT_WINDOW_CLASS_NAME,
        }
      : {};

    return (
      <MobileListContainer>
        <Rows>
          <ListWrapper {...props}>{virtualizedProps ? Row : getRows()}</ListWrapper>
        </Rows>
      </MobileListContainer>
    );
  },
);

export const MobileList: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { onSearch, searchPlaceholder } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { detailsComponent: DetailsComponent, detailsComponentProps = {} } = props;

  const search = (term: string) => {
    setSearchTerm(term);
    onSearch && onSearch(term);
  };

  const selectActiveRow = (activeRow: any) => {
    if (props.onSelectActiveRow) {
      props.onSelectActiveRow(activeRow);
    } else {
      openMobilePageDetailOverlay(<DetailsComponent {...activeRow} {...detailsComponentProps} />)(dispatch);
    }
  };

  const mobileDetailOverlayContent = useSelector(s => s.core.mobilePage.detailOverlayContent);

  return (
    <>
      <div>
        {onSearch && (
          <SearchPanel>
            <PanelSearchIcon />
            <PanelSearchInput
              value={searchTerm}
              placeholder={searchPlaceholder || translate('common.search')}
              onChange={e => search(e.target.value)}
            />
          </SearchPanel>
        )}
        <RowList onSelectActiveRow={selectActiveRow} {...props} />
      </div>
      {mobileDetailOverlayContent && (
        <MobileWidthView>
          <MobileListDetailViewOverlay>{mobileDetailOverlayContent}</MobileListDetailViewOverlay>
        </MobileWidthView>
      )}
    </>
  );
};
