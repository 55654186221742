import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import {
  ModalTitle,
  ModalSubtitle,
  ModalSection,
  Text,
  ModalActionButton,
  ModalBackButtonIcon,
} from '../../../core/components/styled';
import { doNotShowRouteSequenceInstructions, hideRouteSequenceInstructions } from '../../ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { Modal } from '../../../core/components';
import { RouteInstructionsForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props {
  doNotShowRouteSequenceInstructions: DuckFunction<typeof doNotShowRouteSequenceInstructions>;
  hideRouteSequenceInstructions: DuckFunction<typeof hideRouteSequenceInstructions>;
}

interface State {
  step: number;
}

class RouteSequenceInstructionsModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: 1,
    };
  }

  onSubmit = (formData: any) => {
    const { doNotShowRouteSequenceInstructions } = this.props;
    const { hideRouteSequenceInstructions } = formData;
    return hideRouteSequenceInstructions
      ? doNotShowRouteSequenceInstructions()
      : this.props.hideRouteSequenceInstructions();
  };

  decreaseStep = () => this.setState(prevState => ({ step: prevState.step - 1 }));
  increaseStep = () => this.setState(prevState => ({ step: prevState.step + 1 }));

  render() {
    const { step } = this.state;

    return (
      <Modal padding="medium" size="small">
        <ModalTitle>{translate('routes.routeInstructions')}</ModalTitle>
        <ModalSubtitle>{translate('routes.routeInstructionsStep', { step })}</ModalSubtitle>
        <ModalSection padding="large small" flex>
          {step > 1 && (
            <ModalActionButton color="primary" onClick={this.decreaseStep}>
              <ModalBackButtonIcon marginTop="12px" />
            </ModalActionButton>
          )}
          <Text block size="large" margin="no medium" align="center">
            {translate(`routes.routeSequenceInstructions.step${step}`)}
          </Text>
          {step < 3 && (
            <ModalActionButton color="primary" onClick={this.increaseStep}>
              <ModalBackButtonIcon forward marginTop="12px" />
            </ModalActionButton>
          )}
        </ModalSection>
        <RouteInstructionsForm onSubmit={this.onSubmit} step={step} />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  doNotShowRouteSequenceInstructions,
  hideRouteSequenceInstructions,
};

export default connect(undefined, mapDispatchToProps)(RouteSequenceInstructionsModal);
