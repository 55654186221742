import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { http } from '../../core/services/http';
import {
  HolidayPlannerSettings,
  LoadHolidaysResponse,
  RawHoliday,
  RawHolidayOptionalId,
} from '../interfaces/HolidayPlanner';

export const loadHolidays = (vendorId: number) =>
  http.get<LoadHolidaysResponse>(`vendors/${vendorId}/holidayPlanner`).then(response => response.data);

export const saveHoliday = (vendorId: number, holiday: RawHolidayOptionalId) =>
  http
    .post<RawHoliday>(`vendors/${vendorId}/holidayPlanner`, {
      vendorId,
      holidayPlanner: holiday,
    })
    .then(response => response.data);

export const deleteHoliday = (vendorId: number, holidayId: number) =>
  http.delete(`vendors/${vendorId}/holidayPlanner/${holidayId}`).then(response => response.status === 200);

export const saveSettings = (vendorId: number, settings: HolidayPlannerSettings) =>
  http
    .put<HolidayPlannerSettings>(`vendors/${vendorId}/holidayPlanner/settings`, settings)
    .then(response => response.status === 200);

export const loadRecurrenceDayTypes = () =>
  http.get<TechnicalType[]>(`common/systemTypes/recurrenceDayTypes`).then(response => response.data);

export const saveHolidayPlannerRouteTemplates = (
  vendorId: number,
  holidayId: number,
  isDefaultHoliday: boolean,
  routeTemplates: any[],
) =>
  http
    .post(`vendors/holidayPlanner/routeTemplates`, {
      vendorId,
      holidayId,
      isDefaultHoliday,
      routeTemplates,
    })
    .then(response => response.data);

export const loadRouteDatesImpacted = (
  vendorId: number,
  holidayPlanner: RawHolidayOptionalId,
  useBusinessDays: boolean,
) =>
  http
    .post(`vendors/${vendorId}/holidayPlanner/routeDatesImpacted?useBusinessDays=${useBusinessDays}`, {
      ...holidayPlanner,
    })
    .then(response => response.data);
