import { http } from '../../core/services/http';
import transformAiModelConfigurations from './transformAiModelConfigurations';

export const loadAiModelConfigurations = (vendorId: number, vehicleTypeId: number) =>
  http
    .get(`/vendors/${vendorId}/modelConfiguration`, { params: { vehicleTypeId } })
    .then(response => transformAiModelConfigurations(response.data));

export const saveAiModelConfigurations = (aiModelConfiguration: any) =>
  http
    .post(`/vendors/${aiModelConfiguration.vendor.id}/modelConfiguration`, aiModelConfiguration)
    .then(response => response.data);
