import styled, { css } from 'styled-components';

import { loadingOverlay } from 'src/core/styles';

interface Props {
  isLoading?: boolean;
  wrap?: string;
}

export const FlexForm = styled.form<Props>`
  display: flex;
  width: 100%;
  position: relative;

  ${props =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `};

  ${props =>
    props.isLoading &&
    css`
      ${props => loadingOverlay('24px', props.theme.bodyBackgroundColor)};
    `};
`;
