import { useDispatch } from 'react-redux';
import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react';

import { Button, ButtonSet, Grid, GridColumn } from 'src/core/components/styled';
import { createErrorNotification } from 'src/core/services/createNotification';
import { finalizeSetupPaymentIntent } from 'src/finance/ducks/paymentManagement';
import { InputWrapper } from './styled/CardInputFields';
import { PAYMENT_STATUS_SUCCEEDED } from 'src/finance/constants/paymentStatuses';
import translate from 'src/core/services/translate';

interface Props {
  onCancel: () => void;
  returnToPreviousPage?: () => void;
}

const CreateCardPayment = ({ onCancel, returnToPreviousPage }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const elements: any = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.href,
      },
    });

    const redirect_status = setupIntent?.status;
    const setup_intent = setupIntent?.id;
    const setup_intent_client_secret = setupIntent?.client_secret;

    const returnToPage = () => {
      if (returnToPreviousPage) {
        returnToPreviousPage();
      } else {
        window.location.reload();
      }
    };

    if (error) createErrorNotification(error.message as string);

    if (redirect_status && redirect_status !== PAYMENT_STATUS_SUCCEEDED) {
      returnToPage();
    }

    if (redirect_status && redirect_status === PAYMENT_STATUS_SUCCEEDED && setup_intent && setup_intent_client_secret) {
      await finalizeSetupPaymentIntent({ setup_intent, setup_intent_client_secret, redirect_status })(dispatch);

      returnToPage();
    }

    setIsLoading(false);
  };

  return (
    <Grid padding="no">
      <GridColumn size="10/12" verticalAlign="bottom">
        <InputWrapper>
          <PaymentElement />
        </InputWrapper>
      </GridColumn>
      <GridColumn size="2/12" align="right" verticalAlign="bottom">
        <ButtonSet vertical>
          <Button
            type="button"
            color="primary"
            size="xLarge"
            margin="xSmall no"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {translate('common.submit')}
          </Button>
          <Button type="button" color="secondary" size="xLarge" onClick={onCancel} disabled={isLoading}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </GridColumn>
    </Grid>
  );
};

export default CreateCardPayment;
