import { find } from 'lodash-es';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadStopMapDetails } from 'src/routes/ducks';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { RouteMapFeature, clearRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import TravelPathRouteStopGLPopup from './TravelPathRouteStopGLPopup';

const Loading: React.FC<{ latitude: number; longitude: number }> = ({ latitude, longitude }) => {
  const dispatch = useDispatch();

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={latitude}
      longitude={longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature(true))}
    >
      <MapGLPopupLoading />
    </MapGLPopupWrapper>
  );
};

interface Props {
  routeId?: number;
  routeTemplateId?: number;
}

const TravelPathRouteStopGLPopupResolver: FC<Props> = ({ routeId, routeTemplateId }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const { selectedFeatureForTravelPath } = useSelector(state => state.routes.mapControls);
  const routeStops = useSelector(state => state.routes.travelPathBuildAndEdit.routeStops);

  const loadDependencies = () => {
    if (!selectedFeatureForTravelPath) return Promise.reject();

    const dependencies = [];
    if (routeId && vendorId)
      dependencies.push(loadStopMapDetails(vendorId, routeId, selectedFeatureForTravelPath.id)(dispatch));

    return Promise.all(dependencies);
  };

  const routeStop = useMemo(() => {
    if (!selectedFeatureForTravelPath || selectedFeatureForTravelPath.feature !== RouteMapFeature.routeStops)
      return undefined;

    return find(routeStops, routeStop => routeStop.id === selectedFeatureForTravelPath.id);
  }, [routeStops, selectedFeatureForTravelPath]);

  if (!routeStop) return null;

  return (
    <TypedResolver
      key={routeStop.id}
      successComponent={TravelPathRouteStopGLPopup}
      successProps={{ routeStop }}
      loadingComponent={Loading}
      loadingProps={{ latitude: routeStop.displayLatitude, longitude: routeStop.displayLongitude }}
      resolve={loadDependencies}
    />
  );
};

export default TravelPathRouteStopGLPopupResolver;
