import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { useSelector } from 'react-redux';
import { AppState } from '../../../store';

type Props = WrappedFieldProps;

const RubiconDispatchesNotesReasonDisplay: React.FC<Props> = ({ input: { value } }) => {
  const reasonCodes = useSelector((state: AppState) => state.fleet.notes.reasonCodes);

  /**
   * TODO
   * This needs to be fixed in a future sprint. This is the required
   * behavior for now, but will need to work without filtering
   * reason codes.
   */
  if (value === 'OTH') {
    return '';
  }

  const reason = reasonCodes.find(reasonCode => reasonCode.value === value);

  return reason ? reason.label : value;
};

export default RubiconDispatchesNotesReasonDisplay;
