import { reduce, find, omit, get } from 'lodash-es';

const transformLoadPickupExceptions = (pickupExceptionTypes: any) =>
  reduce(
    pickupExceptionTypes,
    (sortedPickupExceptionTypes, element) => {
      if (element.isEnabled) {
        const exception = find(sortedPickupExceptionTypes, exceptionType => exceptionType.id === element.id);
        if (exception) {
          get(exception, 'vehicleTypeIds').push(element.vehicleTypeId);
        } else {
          sortedPickupExceptionTypes.push(omit({ ...element, vehicleTypeIds: [element.vehicleTypeId] }));
        }
      }
      return sortedPickupExceptionTypes;
    },
    [] as any,
  );

export default transformLoadPickupExceptions;
