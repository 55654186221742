import { ReactNode } from 'react';
import { useMonthlyBody } from '@zach.codes/react-calendar';
import moment from 'moment';

import { DayNumber, EventsList, MonthlyDayWrapper } from './styled/Calendar';

type MonthlyDayProps<DayData> = {
  height?: string;
  noOverflow?: boolean;
  highlightCurrentDay?: boolean;
  renderDay: (events: DayData[]) => ReactNode;
};

const MonthlyDay = <DayData extends {}>({
  height,
  highlightCurrentDay,
  noOverflow,
  renderDay,
}: MonthlyDayProps<DayData>) => {
  let { day, events } = useMonthlyBody<DayData>();
  let dayNumber = moment(day).format('DD');

  return (
    <MonthlyDayWrapper
      key={day.toISOString()}
      aria-label={`Events for day ${dayNumber}`}
      height={height}
      noOverflow={noOverflow}
      hasContent={events.length > 1}
      highlight={highlightCurrentDay && moment().isSame(day, 'day')}
    >
      <DayNumber>{dayNumber}</DayNumber>
      <EventsList>{renderDay(events)}</EventsList>
    </MonthlyDayWrapper>
  );
};

export default MonthlyDay;
