import { identity, get } from 'lodash-es';
import { createSelector } from 'reselect';

import { ContainerState } from '../interfaces/containers';

const getContainerEditorFormInitialValues = (containerState: ContainerState, vendorId: number) => ({
  vendorId,
  ...get(containerState, 'container', {}),
});

const containerEditorFormInitialValuesSelector = createSelector(getContainerEditorFormInitialValues, identity);

export default containerEditorFormInitialValuesSelector;
