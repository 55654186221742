import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import DashboardCityInsightsGLLayers from './DashboardCityInsightsGLLayers';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import DashboardCityInsightsGLPopup from './DashboardCityInsightsGLPopup';
import { DASHBOARD_CLUSTERS_SOURCE, DASHBOARD_CITY_INSIGHTS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { useCheckIfCityInsightsAreVisibleMapbox } from 'src/dashboard/utils/dashboardMapbox';

const mapImages = INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: DASHBOARD_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const DashboardCityInsightsGL: React.FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();
  const cityInsightsAreVisible = useCheckIfCityInsightsAreVisibleMapbox();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_CLUSTERS_SOURCE &&
              feature.properties?.layer === DASHBOARD_CITY_INSIGHTS_LAYER,
          );

        if (!!feature) {
          dispatch(setDashboardSelectedFeature('cityInsights', feature.id as number));
        }
      });
    });
  }, [map, dispatch]);

  if (!cityInsightsAreVisible) {
    return null;
  }

  return (
    <>
      <DashboardCityInsightsGLLayers />
      <DashboardCityInsightsGLPopup />
    </>
  );
};

export default DashboardCityInsightsGL;
