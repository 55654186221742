import { http } from '../../core/services/http';

const loadRoutesForReassignment = (
  vendorId: number,
  sourceRouteId: number | string,
  vehicleTypeId: number,
  wasteMaterialTypeId: number | undefined,
  date: Date | string,
) =>
  http
    .get('routes/forReassignment', { params: { vendorId, sourceRouteId, vehicleTypeId, wasteMaterialTypeId, date } })
    .then(response => response.data);

export default loadRoutesForReassignment;
