import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import createTranslationKey from '../../utils/services/createTranslationKey';
import doLoadReportingDataTrends from '../services/loadReportingDataTrends';
import translate from '../../core/services/translate';

// Actions
const START_LOAD = 'insights/reportingDataTrends/START_LOAD';
const COMPLETE_LOAD = 'insights/reportingDataTrends/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/reportingDataTrends/FAIL_LOAD';
const RESET = 'insights/reportingDataTrends/RESET';

// Initial state
const initialState = {
  isLoading: false,
  reportingDataTrends: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          reportingDataTrends: {
            ...action.reportingDataTrends,
            unit: translate(createTranslationKey(action.reportingDataTrends.unit, 'insights.reportTypeUnits')),
          },
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (reportingDataTrends: any) => ({
  type: COMPLETE_LOAD,
  reportingDataTrends,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadReportingDataTrends =
  (
    vendorId: number,
    reportType: string,
    startDate: string,
    endDate: string,
    vehicleTypeId: number,
    daysOfOperation: any[],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadReportingDataTrendsPromise = doLoadReportingDataTrends(
      vendorId,
      reportType,
      startDate,
      endDate,
      vehicleTypeId,
      daysOfOperation,
    );
    loadReportingDataTrendsPromise
      .then(reportingDataTrends => dispatch(completeLoad(reportingDataTrends)))
      .catch(() => dispatch(failLoad()));
    return loadReportingDataTrendsPromise;
  };

export const resetReportingDataTrends = () => ({
  type: RESET,
});
