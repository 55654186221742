import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { currentUserIdSelector, currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { loadDailyReportTypes } from '../../ducks';
import { loadDrivers, loadVehicles, loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ReportsPage from './ReportsPage';
import translate from '../../../core/services/translate';

interface Props {
  loadDailyReportTypes: DuckFunction<typeof loadDailyReportTypes>;
  loadDrivers: DuckFunction<typeof loadDrivers>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  userId: string;
  vendorId: number;
}

class ReportsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadDailyReportTypes,
      loadDrivers,
      loadFiltersSettings,
      loadVehicles,
      loadVehicleTypesForVendor,
      userId,
      vendorId,
    } = this.props;

    return Promise.all([
      loadDailyReportTypes(vendorId),
      loadVehicleTypesForVendor(vendorId),
      loadVehicles(vendorId, true),
      loadDrivers(vendorId),
      loadFiltersSettings(vendorId, userId),
    ]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('insights.dataExport')}</DocumentTitle>
        <Resolver successComponent={ReportsPage} loadingComponent={PageLoading} resolve={this.loadDependencies} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login) as any,
});

const mapDispatchToProps = {
  loadDailyReportTypes,
  loadDrivers,
  loadFiltersSettings,
  loadVehicles,
  loadVehicleTypesForVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPageResolver);
