import humps from 'humps';
import translate from 'src/core/services/translate';
import SERVICE_TYPE_ICONS from 'src/customers/constants/serviceTypeIcons';
import styled from 'styled-components';
import { ServiceIconImage } from '../styled/RubiconDispatches';
import { DispatchStatus, WorkOrderRow } from './RubiconDispatchesTableRow';
import { IconButtonIcon, Text } from '../../../core/components/styled';
import { getDateByStatus } from '../utils/rubiconDispatchesPageHooks';

const MobileListRowContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 10px 0;
`;

const ContentContainer = styled.div`
  width: calc(100% - 70px);
`;

const ContentRowContainer = styled.div`
  width: 90%;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0;
`;

const HeaderInfoContainer = styled(ContentRowContainer)`
  display: flex;
`;

export const RubiconDispatchesMobileListRow: React.FC<WorkOrderRow> = ({
  customerName,
  dates,
  equipment,
  isActionRequired,
  pickupType,
  statusName,
  workorderNumber,
  actionRequired,
  daysOverDue,
  workOrderType,
}) => {
  return (
    <MobileListRowContainer>
      <ServiceIconImage width="70px" src={SERVICE_TYPE_ICONS[humps.camelize(equipment.iconType.split('.').join(''))]} />
      <ContentContainer>
        {actionRequired && !!daysOverDue && (
          <Text block weight="normal" margin="no xxSmall" singleLine size="small" color="alert">
            {translate('common.xDaysOverdue', { days: daysOverDue })}
          </Text>
        )}
        <HeaderInfoContainer>
          <Text size="small" weight="medium" margin="no xxSmall">
            {statusName}
          </Text>
          <Text block margin="no xxSmall" weight="normal" size="small" color="grayDarker">
            {getDateByStatus(statusName, dates)}
            {isActionRequired && <IconButtonIcon icon="bell" color="alert" size="medium" margin="no no no xxSmall" />}
          </Text>
        </HeaderInfoContainer>
        <ContentRowContainer>
          <Text margin="no xxSmall" weight="medium" singleLine>
            {customerName}
          </Text>
        </ContentRowContainer>
        <ContentRowContainer>
          <Text margin="no xxSmall" weight="medium" singleLine>
            {equipment.subType
              ? translate(`haulerProfile.equipments.equipmentSubTypes.${equipment.subType}`)
              : equipment.type}
          </Text>
          {equipment.size && (
            <Text margin="no xxSmall" weight="light" size="small" singleLine>
              {parseInt(equipment.size)} {equipment.units}
            </Text>
          )}

          <Text margin="no xxSmall" singleLine weight="light" size="small">
            {equipment.wasteMaterial}
          </Text>
        </ContentRowContainer>
        <ContentRowContainer>
          {workOrderType === DispatchStatus.SCHEDULED && (
            <IconButtonIcon icon="calendarTime" color="info" size="medium" margin="no no no xxSmall" />
          )}
          <Text size="small" margin="no xxSmall" weight="light">
            {pickupType}
          </Text>
          <Text margin="no xxSmall" singleLine weight="light" size="small">
            {workorderNumber}
          </Text>
        </ContentRowContainer>
      </ContentContainer>
    </MobileListRowContainer>
  );
};
