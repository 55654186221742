import React from 'react';
import { Layer } from 'react-map-gl';

import {
  CONTAINERS_MAP_CONTAINERS_LAYER,
  CONTAINERS_MAP_CLUSTER_SOURCE,
  MULTIPLE,
  CONTAINERS_MAP_ICONS_BY_ID,
} from 'src/fleet/constants/containersMap';

export default React.memo(function ContainerGLLayer() {
  return (
    <>
      <Layer
        id={CONTAINERS_MAP_CONTAINERS_LAYER}
        type="symbol"
        source={CONTAINERS_MAP_CLUSTER_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], CONTAINERS_MAP_CONTAINERS_LAYER]]}
        paint={{
          'text-halo-color': '#fff',
          'text-halo-width': 3,
          'text-translate': [7, -25],
        }}
        layout={{
          'text-field': ['case', ['==', ['get', 'color'], CONTAINERS_MAP_ICONS_BY_ID[MULTIPLE].color], '+', ''],
          'text-offset': [0, 1.25],
          'text-size': 18,
          'text-font': ['Roboto Regular', 'Arial Unicode MS Regular'],
          'icon-image': ['get', 'icon'],
          'icon-size': 0.7,
          'symbol-z-order': 'source',
          'symbol-sort-key': 1,
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
        }}
      />
    </>
  );
});
