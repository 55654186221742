import { combineReducers } from 'redux';

import { reducer as dispatchBoardReducer } from './dispatchBoard';
import { reducer as disposalsReducer } from './disposals';
import { reducer as disposalsSummaryReducer } from './disposalsSummary';
import { reducer as drivingIncidentsReducer } from './drivingIncidents';
import { reducer as eventLogReducer } from './eventLog';
import { reducer as eventTypesReducer } from './eventTypes';
import { reducer as exceptionsReducer } from './exceptions';
import { reducer as filtersReducer } from './filters';
import { reducer as fleetDetailsReducer } from './fleetDetails';
import { reducer as fuelTicketsReducer } from './fuelTickets';
import { reducer as geoFenceFilterOptionsReducer } from './geoFenceFilterOptions';
import { reducer as geoFencesReducer } from './geoFences';
import { reducer as groupsReducer } from './groups';
import { reducer as holidayPlannerReducer } from './holidayPlanner';
import { reducer as loadsDumpedReducer } from './loadsDumped';
import { reducer as mapControlsReducer } from './mapControls';
import { reducer as materialTicketsReducer } from './materialTickets';
import { reducer as materialTripsReducer } from './materialTrips';
import { reducer as pickupTypesReducer } from './pickupTypes';
import { reducer as pinOnMapGeoFencesReducer } from './pinOnMapGeoFences';
import { reducer as rollOffJobsReducer } from './rollOffJobs';
import { reducer as routeReducer } from './route';
import { reducer as routeAssistReducer } from './routeAssist';
import { reducer as routeDetailsReducer } from './routeDetails';
import { reducer as routeGeoFenceReducer } from './routeGeoFence';
import { reducer as routeImagesReducer } from './routeImages';
import { reducer as routeLocationImagesReducer } from './routeLocationImages';
import { reducer as routeLocationIssuesReducer } from './routeLocationIssues';
import { reducer as routeLocationNotesReducer } from './routeLocationNotes';
import { reducer as routeMapCityInsightsReducer } from './routeMapCityInsights';
import { routeMapDebugReducer } from './routeMapDebug';
import { reducer as routeMapDetailsReducer } from './routeMapDetails';
import { routeMapSettingsReducer } from './routeMapSettings';
import { reducer as routeMapVehicleDataReducer } from './routeMapVehicleData';
import { reducer as routeMapVehicleInsightsReducer } from './routeMapVehicleInsights';
import { reducer as routePlannerReducer } from './routePlanner';
import { reducer as routeSegmentsReducer } from './routeSegments';
import { reducer as routeSequenceReducer } from './routeSequence';
import { reducer as routeSequenceInstructionsReducer } from './routeSequenceInstructions';
import { reducer as routeStopsReducer } from './routeStops';
import { reducer as routeSummaryReducer } from './routeSummary';
import { reducer as routeTemplateReducer } from './routeTemplate';
import { routeTemplateBuilderReducer } from './routeTemplateBuilder';
import { reducer as routeTemplateUnassignedLocationsReducer } from './routeTemplateUnassignedLocations';
import { reducer as routeTemplatesReducer } from './routeTemplates';
import { reducer as routeTemplatesForReassignmentReducer } from './routeTemplatesForReassignment';
import { reducer as routeTrackerReducer } from './routeTracker';
import { reducer as loadSnowPlowSegmentList } from './snowPlowSegmentList';
import { reducer as routesForReassignmentReducer } from './routesForReassignment';
import { reducer as routeTrackerIssuesReducer } from './routeTrackerIssues';
import { reducer as routeTrackerIssuesSummaryReducer } from './routeTrackerIssuesSummary';
import { reducer as serviceDetailsReducer } from './serviceDetails';
import { reducer as serviceHistoryReducer } from './serviceHistory';
import { reducer as serviceZonesReducer } from './serviceZones';
import { reducer as stopMapDetailsReducer } from './stopMapDetails';
import { reducer as streetSweeperSegmentListReducer } from './streetSweeperSegmentList';
import { reducer as strobeImagesReducer } from './strobeImages';
import { reducer as supervisorsReducer } from './supervisors';
import { reducer as timeManagementReducer } from './timeManagement';
import { reducer as travelPathReducer } from './travelPath';
import { reducer as triggersReducer } from './triggers';
import { reducer as videoRequestReducer } from './videoRequest';
import { reducer as wasteRecorderReducer } from './wasteRecorder';
import { reducer as waterFillsReducer } from './waterFills';
import { reducer as weightTicketReducer } from './weightTicket';
import { reducer as weightTicketsReducer } from './weightTickets';
import { reducer as travelPathBuildAndEditReducer } from './travelPathBuildAndEdit';

export { loadDisposalsSummary } from './disposalsSummary';
export { loadEventLog, resetEventLog } from './eventLog';
export { loadEventTypes, resetEventTypes } from './eventTypes';
export {
  ADD_JOB as ADD_ROLLOFF_JOB,
  ADD_NOTE as ADD_ROLLOFF_NOTE,
  COMPLETE_DELETE as COMPLETE_DELETE_ROLLOFF_JOBS,
  COMPLETE_LOAD as COMPLETE_LOAD_ROLLOFF_JOBS,
  COMPLETE_SAVE as COMPLETE_SAVE_ROLLOFF_JOBS,
  addJob,
  addNote,
  loadRollOffJobs,
  resetRollOffJobs,
} from './rollOffJobs';
export {
  COMPLETE_EXPORT as COMPLETE_EXPORT_ROUTE,
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTE,
  COMPLETE_SAVE as COMPLETE_SAVE_ROUTE,
  COMPLETE_BATCH_TRANSFER as COMPLETE_TRANSFER_STOPS,
  cancelBatchedLocationsLoading,
  completedStopCountSelector,
  exportRoute,
  exportYRoute,
  loadRoute,
  loadTripTimeDetails,
  loadYRoute,
  resetRoute,
  routeLocationAddressChange,
  routeLocationsByIdSelector,
  saveRoute,
  transferRouteLocations,
} from './route';
export { COMPLETE_LOAD as COMPLETE_LOAD_ROUTE_IMAGES, loadRouteImages, resetRouteImages } from './routeImages';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTE_LOCATION_IMAGES,
  loadRouteLocationImages,
  resetRouteLocationImages,
} from './routeLocationImages';
export { loadRouteLocationIssues, resetRouteLocationIssues } from './routeLocationIssues';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTE_LOCATION_NOTES,
  COMPLETE_SAVE_ROUTE_NOTE,
  deleteRouteNote,
  loadRouteLocationNotes,
  resetRouteLocationNotes,
  saveRouteNote,
} from './routeLocationNotes';
export {
  containerInsightsSelector,
  getUnitOfMeasureSelector,
  hasStrobeDataSelector,
  loadRouteMapDetails,
  resetRouteMapDetails,
  vehiclePositionsSelector,
  vehicleTrackingsSelector,
} from './routeMapDetails';
export {
  loadRouteSequence,
  loadRouteSequenceHistory,
  loadRouteSequenceStatus,
  loadRouteSequenceStatusByVendor,
  loadRouteSequenceStatusCombined,
  routeSequence,
  updateRouteSequenceStatus,
} from './routeSequence';
export {
  COMPLETE_EXPORT as COMPLETE_EXPORT_ROUTE_TEMPLATE,
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTE_TEMPLATE,
  COMPLETE_SAVE as COMPLETE_SAVE_ROUTE_TEMPLATE,
  COMPLETE_BATCH_TRANSFER as COMPLETE_TRANSFER_STOPS_ROUTE_TEMPLATE,
  exportRouteTemplate,
  loadRouteTemplate,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  resetRouteTemplate,
  routeTemplateLocationAddressChange,
  saveRouteTemplate,
  transferRouteTemplateLocations,
} from './routeTemplate';
export {
  loadRouteTemplateUnassignedLocations,
  resetRouteTemplateUnassignedLocations,
} from './routeTemplateUnassignedLocations';
export {
  COMPLETE_CREATE_ROUTE,
  COMPLETE_DELETE as COMPLETE_DELETE_ROUTE_TEMPLATE,
  COMPLETE_EXPORT as COMPLETE_EXPORT_ROUTE_TEMPLATES,
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTE_TEMPLATES,
  COMPLETE_SCHEDULE_ROUTES,
  createRouteFromRouteTemplate,
  deleteRouteTemplate,
  exportRouteTemplates,
  loadRouteTemplates,
  loadRouteTemplatesForHolidayPlanner,
  resetRouteTemplates,
} from './routeTemplates';
export {
  COMPLETE_CLONE as COMPLETE_CLONE_ROUTE_TRACKER,
  COMPLETE_DELETE as COMPLETE_DELETE_ROUTE_TRACKER,
  COMPLETE_EXPORT as COMPLETE_EXPORT_ROUTES_TRACKER,
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTES_TRACKER,
  bulkDeleteRouteTrackers,
  cloneRouteTracker,
  resetRouteTracker,
  deleteRouteTracker,
  exportRoutesTracker,
  exportSnowOrSweeperRoutesTracker,
  loadRoutesResourceAvailability,
  loadNextRunNumber,
  loadRoutesTracker,
  loadSnowOrSweeperRoutesHeader,
  loadSnowOrSweeperRoutesTracker,
  rescheduleRouteTrackers,
  resetRoutesTracker,
} from './routeTracker';
export { loadRouteTrackerIssues, resetRouteTrackerIssues } from './routeTrackerIssues';
export { loadRouteTrackerIssuesSummary, resetRouteTrackerIssuesSummary } from './routeTrackerIssuesSummary';
export { loadClosestStrobeImage, loadNextStrobeImages, loadPrevStrobeImages, loadStrobeImages } from './strobeImages';
export { loadTriggers, resetTriggers } from './triggers';
export {
  COMPLETE_SAVE as COMPLETE_SAVE_WEIGHTTICKETS,
  linkImageToWeightTicket,
  resetWeightTicket,
  saveWeightTicket,
  uploadWeightTicketImage,
} from './weightTicket';
export { deleteWeightTicket, loadWeightTickets, resetWeightTickets } from './weightTickets';

export {
  doNotShowRouteSequenceInstructions,
  hideRouteSequenceInstructions,
  resetRouteSequenceInstructions,
  startRouteSequenceInstructions,
} from './routeSequenceInstructions';
export { loadRouteTemplatesForReassignment, resetRouteTemplatesForReassignment } from './routeTemplatesForReassignment';
export { loadRoutesForReassignment, resetRoutesForReassignment } from './routesForReassignment';
export {
  COMPLETE_DELETE as COMPLETE_DELETE_SERVICE_ZONES,
  COMPLETE_LOAD as COMPLETE_LOAD_SERVICE_ZONES,
  COMPLETE_SAVE as COMPLETE_SAVE_SERVICE_ZONES,
  deleteServiceZone,
  loadServiceZones,
  resetServiceZones,
  saveServiceZone,
} from './serviceZones';

export { loadWasteRecorder, resetWasteRecorder } from './wasteRecorder';

export { loadExceptions, saveJobStatus, saveWasteAuditJobStatus } from './exceptions';

export { loadGeoFences, resetGeoFences, loadGeoFenceBasicList } from './geoFences';

export { loadGeoFenceFilterOptions, resetGeoFenceFilterOptions } from './geoFenceFilterOptions';

export { loadPinOnMapGeoFences, resetPinOnMapGeoFences } from './pinOnMapGeoFences';

export { loadRouteSummary, loadSnowSweeperRouteSummary } from './routeSummary';

export { loadRouteDetails, loadSnowSweeperRouteDetails, resetRouteDetails, updateRouteDetails } from './routeDetails';

export { loadFleetDetails, resetFleetDetails } from './fleetDetails';

export {
  deleteFuelTicket,
  linkFuelTicketImage,
  loadFuelTicket,
  loadFuelTicketUnitOfMeasure,
  loadFuelTickets,
  loadFuelingStation,
  resetFuelTickets,
  saveFuelTicket,
  uploadFuelTicketImage,
} from './fuelTickets';

export { loadServiceDetails, loadSnowSweeperServiceDetails, resetServiceDetails } from './serviceDetails';

export { loadDrivingIncidents, resetDrivingIncidents } from './drivingIncidents';

export { loadRouteAssistData, loadSnowSweeperRouteAssistData, resetRouteAssistData } from './routeAssist';

export { loadTimeManagementData, resetTimeManagementData } from './timeManagement';

export { loadServiceHistoryData, resetServiceHistoryData } from './serviceHistory';

export {
  loadRouteLocationDetails,
  loadRouteStops,
  loadRouteStopsForMap,
  loadRouteStopsForSequence,
  loadUnscheduledStops,
  loadWasteAuditNotes,
  resetIsLoaded,
  resetRouteStops,
  updateRouteStops,
} from './routeStops';

export { loadRouteSegments, resetRouteSegments } from './routeSegments';

export { loadStopMapDetails, resetStopMapDetails } from './stopMapDetails';

export { resetRouteStopsFilters, setJobPriorityTypeIds, setPickupStatusIds, setSearchTerm } from './filters';

export {
  toggleSpeedColor,
  clearRouteMapSelectedFeature,
  resetRouteMapControls,
  setDebugMode,
  setRouteMapSelectedFeature,
  setRouteMapViewport,
  setTriggerMapViewport,
} from './mapControls';

export { loadRouteMapFilters, loadRouteMapVehicleData, resetIncludeVehicleTracking } from './routeMapVehicleData';

export { loadRouteMapVehicleInsights } from './routeMapVehicleInsights';

export { loadRouteMapCityInsights, resetRouteMapCityInsights } from './routeMapCityInsights';

export { loadSnowPlowSegmentList } from './snowPlowSegmentList';

export { loadStreetSweeperSegmentList } from './streetSweeperSegmentList';

export {
  deleteMaterialTicket,
  loadMaterialLocations,
  loadMaterialPickups,
  loadMaterialTicket,
  loadMaterialTickets,
  loadMaterialTypeValues,
  loadUnitOfMeasureValues,
  resetMaterialTickets,
  saveMaterialTicket,
  uploadMaterialTicketImage,
} from './materialTickets';

export {
  deleteLoadDumped,
  loadLoadDumped,
  loadLoadsDumped,
  loadStreetSweeperMaterialTypeValues,
  loadStreetSweeperUnitOfMeasureValues,
  resetLoadsDumped,
  saveLoadDumped,
} from './loadsDumped';

export {
  deleteWaterFill,
  loadStreetSweeperMaterialTypeValuesWaterFill,
  loadStreetSweeperUnitOfMeasureValuesWaterFill,
  loadWaterFill,
  loadWaterFills,
  resetWaterFills,
  saveWaterFill,
} from './waterFills';

export { loadVideoDownloadDetails, loadVideoRequests, loadVideoStatuses, saveVideoRequest } from './videoRequest';

export {
  downloadTravelPathGeoJsonFile,
  loadTravelPathStatusDetails,
  resetTravelPathDetails,
  setIsTravelPathLegendOpen,
  setShowTravelPath,
  triggerTravelPath,
  resetTravelPathDetailsForBuildOrEdit,
} from './travelPath';

export {
  applyEditsToTravelPath,
  finishTravelPathBuildOrEdit,
  initializeTravelPathBuildOrEdit,
  loadGeoFencesForTravelPathModal,
  loadHaulerLocationsForTravelPathModal,
  loadRouteSegmentsForTravelPathModal,
  loadRouteStopsForTravelPathModal,
  loadRouteVehicleInformation,
  loadRouteVehiclePositionAndBreadcrumb,
  loadStreetNetworkForTravelPathModal,
  loadTravelPathBuilderFilters,
  loadTravelPathForBuildOrEdit,
  publishEditsToTravelPath,
  resetTravelPathBuildEdit,
  resetMapFeatures,
  setIsTravelPathEditLegendOpen,
  setSelectedSegment,
  extendTravelPathLock,
  resetStartEndTravelPathEdit,
  travelPathBuilderDoTracing,
  resetTravelPathBuildTracer,
} from './travelPathBuildAndEdit';

export { loadMaterialTrips, resetMaterialTrips } from './materialTrips';

export {
  assignRoutesToGroups,
  assignTemplatesToGroups,
  createGroup,
  deleteGroup,
  loadGroup,
  loadGroups,
  updateGroup,
} from './groups';

export const reducer = combineReducers({
  dispatchBoard: dispatchBoardReducer,
  disposals: disposalsReducer,
  disposalsSummary: disposalsSummaryReducer,
  drivingIncidents: drivingIncidentsReducer,
  eventLog: eventLogReducer,
  eventTypes: eventTypesReducer,
  exceptions: exceptionsReducer,
  filters: filtersReducer,
  fleetDetails: fleetDetailsReducer,
  fuelTickets: fuelTicketsReducer,
  geoFence: routeGeoFenceReducer,
  geoFences: geoFencesReducer,
  geoFencesFilterOptions: geoFenceFilterOptionsReducer,
  groups: groupsReducer,
  holidayPlanner: holidayPlannerReducer,
  loadsDumped: loadsDumpedReducer,
  mapControls: mapControlsReducer,
  materialTickets: materialTicketsReducer,
  materialTrips: materialTripsReducer,
  pickupTypes: pickupTypesReducer,
  pinOnMapGeoFences: pinOnMapGeoFencesReducer,
  rollOffJobs: rollOffJobsReducer,
  route: routeReducer,
  routeAssist: routeAssistReducer,
  routeDetails: routeDetailsReducer,
  routeImages: routeImagesReducer,
  routeLocationImages: routeLocationImagesReducer,
  routeLocationIssues: routeLocationIssuesReducer,
  routeLocationNotes: routeLocationNotesReducer,
  routeMapCityInsights: routeMapCityInsightsReducer,
  routeMapDebug: routeMapDebugReducer,
  routeMapDetails: routeMapDetailsReducer,
  routeMapSettings: routeMapSettingsReducer,
  routeMapVehicleData: routeMapVehicleDataReducer,
  routeMapVehicleInsights: routeMapVehicleInsightsReducer,
  routePlanner: routePlannerReducer,
  routeSegments: routeSegmentsReducer,
  routeSequence: routeSequenceReducer,
  routeSequenceInstructions: routeSequenceInstructionsReducer,
  routesForReassignment: routesForReassignmentReducer,
  routeStops: routeStopsReducer,
  routeSummary: routeSummaryReducer,
  routeTemplate: routeTemplateReducer,
  routeTemplateBuilder: routeTemplateBuilderReducer,
  routeTemplates: routeTemplatesReducer,
  routeTemplatesForReassignment: routeTemplatesForReassignmentReducer,
  routeTemplateUnassignedLocations: routeTemplateUnassignedLocationsReducer,
  routeTracker: routeTrackerReducer,
  routeTrackerIssues: routeTrackerIssuesReducer,
  routeTrackerIssuesSummary: routeTrackerIssuesSummaryReducer,
  serviceDetails: serviceDetailsReducer,
  serviceHistory: serviceHistoryReducer,
  serviceZones: serviceZonesReducer,
  snowPlowSegmentList: loadSnowPlowSegmentList,
  stopMapDetails: stopMapDetailsReducer,
  streetSweeperSegmentList: streetSweeperSegmentListReducer,
  strobeImages: strobeImagesReducer,
  supervisors: supervisorsReducer,
  timeManagement: timeManagementReducer,
  travelPath: travelPathReducer,
  travelPathBuildAndEdit: travelPathBuildAndEditReducer,
  triggers: triggersReducer,
  videoRequest: videoRequestReducer,
  wasteRecorder: wasteRecorderReducer,
  waterFills: waterFillsReducer,
  weightTicket: weightTicketReducer,
  weightTickets: weightTicketsReducer,
});
