import React, { PureComponent, Fragment } from 'react';
import { size, map, pickBy, keys, reduce, set, indexOf } from 'lodash-es';
import { WrappedFieldProps } from 'redux-form';
import {
  FormGroup,
  FormLabel,
  FormError,
  Input as FormInput,
  MultiSelectOverlay,
  MultiSelectOptions,
  Button,
  ButtonSet,
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  CheckboxText,
  ContainerSeparator,
} from './styled';
import translate from '../services/translate';

interface Props extends WrappedFieldProps {
  isLoading?: boolean;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  margin?: string;
  options1: any[];
  options2: any[];
  normalizeValues?: (values: any) => any;
  formatText?: (text: Array<number | string>) => string;
  selectedExceptions?: any[];
}

interface State {
  selectedValues1: { [key: string]: boolean };
  selectedValues2: { [key: string]: boolean };
  selectedValuesCount1: number;
  selectedValuesCount2: number;
  text: string;
  filteredOptions1: any[];
  filteredOptions2: any[];
  isOverlayOpen: boolean;
}

class MultiSelectSeparator extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState = (props: Props) => {
    const {
      options1,
      options2,
      input: { value },
    } = props;

    const selectedValues1 = reduce(
      options1,
      (selectedValues1, option) =>
        set(selectedValues1, option.value, indexOf(value, option.value) > -1 && size(value) > 0),
      {},
    );
    const selectedValuesCount1 = size(pickBy(selectedValues1));

    const selectedValues2 = reduce(
      options2,
      (selectedValues2, option) =>
        set(selectedValues2, option.value, indexOf(value, option.value) > -1 && size(value) > 0),
      {},
    );
    const selectedValuesCount2 = size(pickBy(selectedValues2));

    return {
      selectedValues1,
      selectedValues2,
      selectedValuesCount1,
      selectedValuesCount2,
      text: this.formatText(value),
      filteredOptions1: options1,
      filteredOptions2: options2,
      isOverlayOpen: false,
    };
  };

  componentDidUpdate(prevProps: Props) {
    if (
      (prevProps.input.value && !this.props.input.value) ||
      prevProps.options1.length !== this.props.options1.length ||
      prevProps.options2.length !== this.props.options2.length
    ) {
      this.setState(this.getInitialState(this.props));
    }
  }

  onApply = () => {
    const {
      input: { onChange },
      normalizeValues,
    } = this.props;
    const selectedValues1 = keys(pickBy(this.state.selectedValues1));
    const selectedValues2 = keys(pickBy(this.state.selectedValues2));
    const normalizedValues1 = normalizeValues ? map(selectedValues1, normalizeValues) : selectedValues1;
    const normalizedValues2 = normalizeValues ? map(selectedValues2, normalizeValues) : selectedValues2;
    const value1 = normalizedValues1.length ? normalizedValues1 : null;
    const value2 = normalizedValues2.length ? (normalizedValues2 as any).map(Number) : null;

    this.setState(() => ({
      text: this.formatText(value1 || value2),
      isOverlayOpen: false,
    }));

    return onChange(value1 || value2);
  };

  onOptionChange1 = (value: string) => {
    const { options1, options2 } = this.props;

    this.setState(prevState => {
      const selectedValues1 = reduce(
        options1,
        (selectedValues1, option) => set(selectedValues1, option.value, false),
        {},
      );
      const selectedValues1Updated = { ...selectedValues1, [value]: !prevState.selectedValues1[value] };
      const selectedValuesCount1 = size(pickBy(selectedValues1Updated));

      const selectedValues2 = reduce(
        options2,
        (selectedValues2, option) => set(selectedValues2, option.value, false),
        {},
      );
      const selectedValuesCount2 = size(pickBy(selectedValues2));

      return {
        selectedValues1: selectedValues1Updated,
        selectedValues2,
        selectedValuesCount1,
        selectedValuesCount2,
      };
    });
  };

  onOptionChange2 = (value: string) => {
    const { options1 } = this.props;

    this.setState(prevState => {
      const selectedValues2 = { ...prevState.selectedValues2, [value]: !prevState.selectedValues2[value] };
      const selectedValuesCount2 = size(pickBy(selectedValues2));

      const selectedValues1 = reduce(
        options1.filter(filter => !filter.isDisabled),
        (selectedValues1, option) => set(selectedValues1, option.value, false),
        {},
      );
      const selectedValuesCount1 = size(pickBy(selectedValues1));

      return {
        selectedValues1,
        selectedValues2,
        selectedValuesCount1,
        selectedValuesCount2,
      };
    });
  };

  onOverlayMouseDown = (event: React.SyntheticEvent) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  formatText = (selectedOptions: Array<number | string>) => {
    const length = size(selectedOptions);
    if (!length) return '';

    const { formatText } = this.props;
    if (formatText) return formatText(selectedOptions);

    return translate('common.xSelected', { selected: length });
  };

  openOverlay = () => {
    this.setState({ isOverlayOpen: true });
    setTimeout(() => {
      document.addEventListener('mousedown', this.closeOverlay);
    });
  };

  closeOverlay = () => {
    this.setState(this.getInitialState(this.props));

    setTimeout(() => {
      document.removeEventListener('mousedown', this.closeOverlay);
    });
  };

  render() {
    const {
      input,
      meta: { submitFailed, error },
      label,
      placeholder,
      disabled,
      margin,
      isLoading,
      ...props
    } = this.props;

    const {
      selectedValues1,
      selectedValues2,
      selectedValuesCount1,
      selectedValuesCount2,
      filteredOptions1,
      filteredOptions2,
      text,
    } = this.state;

    return (
      <FormGroup hasValue isLoading={isLoading} margin={margin}>
        {!!label && <FormLabel>{label}</FormLabel>}

        <FormInput
          value={text}
          placeholder={placeholder}
          disabled={disabled}
          onClick={this.openOverlay}
          onFocus={this.openOverlay}
          withSelectStyle
          readOnly
          {...props}
        />

        {this.state.isOverlayOpen && (
          <MultiSelectOverlay onMouseDown={this.onOverlayMouseDown}>
            {!!size(filteredOptions1) && (
              <Fragment>
                <MultiSelectOptions>
                  {map(filteredOptions1, (option, index) => (
                    <CheckboxContainer
                      block
                      key={index}
                      isDisabled={option.isDisabled}
                      size="small"
                      margin="no no xSmall no"
                    >
                      <CheckboxInput
                        type="checkbox"
                        name={option.value}
                        disabled={option.isDisabled}
                        checked={option.isDisabled ? !option.isDisabled : selectedValues1[option.value]}
                        onChange={() => this.onOptionChange1(option.value)}
                      />
                      <CheckboxCheck />
                      <CheckboxText>{option.label}</CheckboxText>
                    </CheckboxContainer>
                  ))}
                  <ContainerSeparator />
                  {map(
                    filteredOptions2,
                    (option, index) =>
                      ((option.isDisabled && selectedValues2[option.value]) || !option.isDisabled) && (
                        <CheckboxContainer
                          block
                          key={index}
                          isDisabled={option.isDisabled}
                          size="small"
                          margin="no no xSmall no"
                        >
                          <CheckboxInput
                            type="checkbox"
                            name={option.value}
                            disabled={option.isDisabled}
                            checked={selectedValues2[option.value]}
                            onChange={() => this.onOptionChange2(option.value)}
                          />
                          <CheckboxCheck />
                          <CheckboxText>{option.label}</CheckboxText>
                        </CheckboxContainer>
                      ),
                  )}
                </MultiSelectOptions>

                <ButtonSet margin="small no no">
                  <Button
                    fluid
                    color="primary"
                    size="small"
                    type="button"
                    disabled={!selectedValuesCount1 && !selectedValuesCount2}
                    onClick={this.onApply}
                  >
                    {translate('common.apply')}
                  </Button>
                </ButtonSet>
              </Fragment>
            )}

            {!size(filteredOptions1) && !size(filteredOptions2) && translate('common.noResults')}
          </MultiSelectOverlay>
        )}

        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

export default MultiSelectSeparator;
