import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { groupBy, map } from 'lodash-es';

import { DropdownOption } from 'src/core/components/Dropdown';
import EnhancedDropdown from 'src/core/components/EnhancedDropdown';
import { useSelector } from 'src/core/hooks/useSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { RouteTemplateStatus } from 'src/routes/components/styled/RouteTemplateBuilderMap';
import {
  DRAFT,
  NEW,
  PUBLISHED,
  RTBTemplateStatuses,
  RTB_TEMPLATE_STATUSES_MAP,
} from 'src/routes/constants/routeTemplateBuilder';
import { loadRouteTemplateOptionsForWorkSession } from 'src/routes/ducks/routeTemplateBuilder';
import { RouteTemplateBuilderService } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { Dropdown, Modal, TypedField } from '../../../../../core/components';
import LabeledDataView from '../../../../../core/components/LabeledDataView';
import { Button, Grid, GridColumn, Text } from '../../../../../core/components/styled';
import { ShadowedButtonSet } from '../../../../../core/components/styled/ButtonSet';
import { WEEKDAYS, WEEKDAYS_BY_ID } from '../../../../../core/constants/weekdays';
import translate from '../../../../../core/services/translate';
import store from '../../../../../store';
import createTranslationKey from '../../../../../utils/services/createTranslationKey';
import { isRequired } from '../../../../../utils/services/validator';
import {
  refreshWorkSession,
  resetFilterRouteTemplates,
  upsertDraftRouteTemplateForWorkSession,
} from '../../../../ducks/routeTemplateBuilder';
import { RouteTemplateBuilderModalForm, RouteTemplateBuilderSpacer } from '../../../styled/RouteTemplateBuilderModals';
import RouteTemplateBuilderStopCountsPerDay from '../routeTemplateBuilderSections/RouteTemplateBuilderStopCountsPerDay';
import { RouteTemplateBuilderLeaveStopsOnRoutesToggle } from './RouteTemplateBuilderLeaveStopsOnRoutesToggle';

const daysOfServiceIds: DropdownOption[] = WEEKDAYS.map(day => ({
  label: day.name,
  value: day.id,
}));

interface FormValues {
  routeTemplateId: number;
}

export interface RouteTemplateBuilderAssignToRouteModalProps {
  services: RouteTemplateBuilderService[];
  onClose(actionSuccessful?: boolean): void;
  hasAssignedStops: boolean;
}

type Props = RouteTemplateBuilderAssignToRouteModalProps &
  InjectedFormProps<FormValues, RouteTemplateBuilderAssignToRouteModalProps>;

const RouteTemplateBuilderAssignToRouteModal: React.FC<Props> = ({
  services,
  form,
  onClose,
  handleSubmit,
  change,
  hasAssignedStops,
}) => {
  const selector = formValueSelector(form);
  const dispatch = useDispatch();

  const [leaveStopsOnOriginalTemplates, setLeaveStopsOnOriginalTemplates] = useState(false);
  const [routeTemplatesLoading, setRouteTemplatesLoading] = useState<boolean>(false);

  const routeTemplateIds = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);
  const routeTemplatesDictionary = useSelector(s => s.routes.routeTemplateBuilder.routeTemplatesDictionary);
  const selectedDraftId = useSelector(s => s.routes.routeTemplateBuilder.selectedDraftId);
  const selectedDraft = useSelector(s => s.routes.routeTemplateBuilder.selectedDraft);
  const selectedDayOfServiceId = useSelector(state => selector(state, 'dayOfServiceId'));
  const selectedRouteTemplateId = useSelector(state => selector(state, 'routeTemplateId'));
  const vehicleTypeId = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.vehicleTypeId);
  const workSessionId = useSelector(state => state.routes.routeTemplateBuilder.workSession?.id);
  const routeTemplates = useSelector(state => state.routes.routeTemplateBuilder.workSessionRouteTemplates);
  const vehicleTypes = useSelector(state => state.fleet.vehicleTypes.vehicleTypes);
  const serviceZones = useSelector(state => (state.routes.serviceZones.serviceZones || []) as any[]);
  const assignLoading = useSelector(state => state.routes.routeTemplateBuilder.serivcesAssignToRouteLoading);

  const selectedRouteTemplate = routeTemplates.find(
    template => template.draftId === selectedRouteTemplateId || template.routeTemplateId === selectedRouteTemplateId,
  );
  const selectedRouteTemplateVehicleType = vehicleTypes.find(type => type.id === selectedRouteTemplate?.vehicleTypeId);
  const selectedRouteTemplateZone = serviceZones.find(
    (zone: any) => zone.id === selectedRouteTemplate?.vendorServiceZoneId,
  );

  const loadRouteTemplates = (dayOfServiceId: number) => {
    if (!workSessionId) {
      return;
    }

    setRouteTemplatesLoading(true);
    dispatch(change('routeTemplateId', undefined));

    loadRouteTemplateOptionsForWorkSession(
      workSessionId,
      vehicleTypeId,
      dayOfServiceId,
    )(dispatch).finally(() => {
      setRouteTemplatesLoading(false);
    });
  };

  const onSubmit = (values: FormValues) => {
    if (!workSessionId || !selectedRouteTemplate) {
      return;
    }

    const selectedRouteTemplateIds = Object.keys(routeTemplatesDictionary)
      .filter(id => routeTemplateIds.includes(routeTemplatesDictionary[id]))
      .map(id => +id);

    const routeIds =
      selectedDraftId && selectedDraft?.id === selectedDraftId ? [-selectedDraftId] : selectedRouteTemplateIds;

    upsertDraftRouteTemplateForWorkSession(workSessionId, selectedRouteTemplate.draftId || null, {
      ...selectedRouteTemplate,
      serviceContracts: services.map(({ id }) => ({ id })),
      routeTemplateIds: !leaveStopsOnOriginalTemplates && hasAssignedStops ? routeIds : undefined,
    })(dispatch)
      .then(() => {
        createSuccessNotification(
          translate('routeTemplateBuilder.serviceContractsAssignedSuccessfully', { name: selectedRouteTemplate.name }),
        );
        onClose(true);
        refreshWorkSession(false)(dispatch, store.getState).then(() => {
          dispatch(resetFilterRouteTemplates());
        });
      })
      .catch(error => {
        const { code } = error.response.data;

        switch (code) {
          case 'RouteTemplatePlannerWorkSessionRouteTemplateNameExists':
            createErrorNotification(translate('routeTemplateBuilder.errors.routeTemplateNameExistsWithinSession'));
            break;

          case 'RouteTemplatePlannerDeleteTransferredStops':
            createErrorNotification(translate('routeTemplateBuilder.errors.routeTemplateDeleteTransferredStops'));
            break;

          default:
            createErrorNotification(translate('routeTemplateBuilder.errors.unexpectedError'));
            break;
        }
      });
  };

  const generateRouteTemplateOptions = (stage: any) =>
    map(groupBy(routeTemplates, 'vehicleTypeId'), templatesGrouped => {
      const vehicleType = vehicleTypes.find(type => type.id === templatesGrouped[0].vehicleTypeId);

      return {
        label: vehicleType?.name || translate('routes.unassigned'),
        value: vehicleType?.id,
        options: map(templatesGrouped, template => {
          const statusId: RTBTemplateStatuses = !!template.draftId
            ? !template.routeTemplateId
              ? NEW
              : DRAFT
            : PUBLISHED;

          const routeId = template.draftId || template.routeTemplateId;

          return {
            label: (
              <>
                {template.name}

                {!template.isRouteTemplateEnabled && statusId !== NEW && (
                  <RouteTemplateStatus
                    neutral
                    inverted={stage !== 'singleValue' && selectedRouteTemplateId === routeId}
                    margin="xxSmall no xxSmall xxSmall"
                  >
                    {translate('common.inactive')}
                  </RouteTemplateStatus>
                )}

                {statusId !== PUBLISHED && (
                  <RouteTemplateStatus
                    statusId={statusId}
                    inverted={stage !== 'singleValue' && selectedRouteTemplateId === routeId}
                    margin="xxSmall no xxSmall xxSmall"
                  >
                    {RTB_TEMPLATE_STATUSES_MAP[statusId].name}
                  </RouteTemplateStatus>
                )}
              </>
            ),
            value: routeId,
            textSearchValue: template.name,
          };
        }),
      };
    });

  return (
    <Modal
      hasBorderRadius
      flex
      flexDirection="column"
      title={translate('routeTemplateBuilder.assignToRoute')}
      size="small"
      minHeight="630px"
      padding="medium no no"
      isLoading={assignLoading}
      onClose={() => onClose()}
    >
      <Text block color="primary" align="center">
        {translate('routeTemplateBuilder.fromXStopsSelected', { count: services.length })}
      </Text>

      <RouteTemplateBuilderModalForm>
        <Grid multiLine margin="small no no">
          <GridColumn size="12/12" padding="no small xSmall" verticalAlign="center" align="center">
            <RouteTemplateBuilderStopCountsPerDay services={services} />
          </GridColumn>

          {hasAssignedStops && (
            <GridColumn size="12/12" padding="no small xSmall" verticalAlign="center" align="center">
              <RouteTemplateBuilderLeaveStopsOnRoutesToggle
                checked={leaveStopsOnOriginalTemplates}
                onChange={setLeaveStopsOnOriginalTemplates}
              />
            </GridColumn>
          )}

          <GridColumn size="6/12" padding="xSmall small">
            <TypedField
              name="dayOfServiceId"
              component={Dropdown}
              validate={[isRequired]}
              props={{
                margin: 'no',
                label: translate('routes.dayOfService'),
                options: daysOfServiceIds,
              }}
              onChange={(event, newDayOfServiceId: number) => {
                loadRouteTemplates(newDayOfServiceId);
              }}
            />
          </GridColumn>

          {!!selectedDayOfServiceId && (
            <GridColumn
              padding="xSmall small"
              size="6/12"
              key={selectedDayOfServiceId}
              isLoading={routeTemplatesLoading}
            >
              <TypedField
                name="routeTemplateId"
                component={EnhancedDropdown}
                validate={[isRequired]}
                props={{
                  margin: 'no',
                  label: translate('routes.route'),
                  options: generateRouteTemplateOptions,
                }}
              />
            </GridColumn>
          )}
        </Grid>

        {selectedRouteTemplate && (
          <Grid multiLine margin="small no no">
            <GridColumn size="6/12" padding="xSmall small">
              <LabeledDataView
                label={translate('routeTemplateBuilder.dayOfService')}
                value={WEEKDAYS_BY_ID[selectedRouteTemplate.dayOfService].name}
              />
            </GridColumn>

            <GridColumn size="6/12" padding="xSmall small">
              <LabeledDataView
                label={translate('routeTemplateBuilder.vehicleType')}
                value={
                  selectedRouteTemplateVehicleType
                    ? translate(
                        createTranslationKey(selectedRouteTemplateVehicleType.technicalName, 'vehicles.vehicleTypes'),
                      )
                    : '–'
                }
              />
            </GridColumn>

            <GridColumn size="6/12" padding="xSmall small">
              <LabeledDataView
                label={translate('routeTemplateBuilder.wasteType')}
                value={translate(
                  createTranslationKey(selectedRouteTemplate.wasteMaterialTypeName, 'common.wasteTypes'),
                )}
              />
            </GridColumn>

            <GridColumn size="6/12" padding="xSmall small">
              <LabeledDataView
                label={translate('routeTemplateBuilder.startDate')}
                value={
                  selectedRouteTemplate.startDate ? moment(selectedRouteTemplate.startDate).format('MM/DD/YYYY') : '–'
                }
              />
            </GridColumn>

            <GridColumn size="6/12" padding="xSmall small">
              <LabeledDataView
                label={translate('routeTemplateBuilder.startingLocation')}
                value={selectedRouteTemplate.startingLocationName ? selectedRouteTemplate.startingLocationName : '–'}
              />
            </GridColumn>

            <GridColumn size="6/12" padding="xSmall small">
              <LabeledDataView
                label={translate('routeTemplateBuilder.serviceZone')}
                value={selectedRouteTemplateZone ? selectedRouteTemplateZone.name : '–'}
              />
            </GridColumn>
          </Grid>
        )}
      </RouteTemplateBuilderModalForm>

      <RouteTemplateBuilderSpacer />

      <ShadowedButtonSet align="center">
        <Button color="primary" onClick={handleSubmit(onSubmit)}>
          {translate('common.save')}
        </Button>
      </ShadowedButtonSet>
    </Modal>
  );
};

export default reduxForm<FormValues, RouteTemplateBuilderAssignToRouteModalProps>({
  form: 'routeTemplateBuilderAssignToRoute',
})(RouteTemplateBuilderAssignToRouteModal);
