import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../../store';
import {
  BULKY_PICKUPS_GEO_FENCE_ID,
  GENERAL_GEO_FENCE_ID,
  ROUTE_TEMPLATE_GEO_FENCE_ID,
  STREET_SWEEPER_GEO_FENCE_ID,
} from 'src/routes/constants';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadFeatures, loadVendor } from '../../../../vendors/ducks';
import { loadGeoFences } from '../../../ducks';
import { loadPermissions, shouldLoadPermissionsSelector } from '../../../../account/ducks';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { PageLoading } from '../../../../common/components/styled';
import { Resolver } from '../../../../core/components';
import GeoFencesPage from './GeoFencesPage';
import translate from '../../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadFeatures: (vendorId: number) => Promise<any>;
  loadGeoFences: DuckFunction<typeof loadGeoFences>;
  loadPermissions: () => Promise<any>;
  loadVehicleTypesForVendor: (vendorId: number) => Promise<any>;
  loadVendor: (vendorId: number) => Promise<any>;
  shouldLoadPermissions?: boolean;
  vendorId: number;
}

class GeoFencesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadFeatures,
      loadGeoFences,
      loadPermissions,
      loadVehicleTypesForVendor,
      loadVendor,
      location: { search },
      shouldLoadPermissions,
      vendorId,
    } = this.props;

    const {
      geoFenceZoneTypeIds = [
        BULKY_PICKUPS_GEO_FENCE_ID,
        GENERAL_GEO_FENCE_ID,
        ROUTE_TEMPLATE_GEO_FENCE_ID,
        STREET_SWEEPER_GEO_FENCE_ID,
      ].join(','),
      geoFenceName,
      page,
      limit,
      sortOrder,
      sortedBy,
    } = getQueryParams(search);

    let geoFenceZoneTypesIdsFormatted = geoFenceZoneTypeIds;
    if (geoFenceZoneTypesIdsFormatted[geoFenceZoneTypesIdsFormatted.length - 1] === ',') {
      geoFenceZoneTypesIdsFormatted = geoFenceZoneTypesIdsFormatted.slice(0, -1);
    }
    const dependencies = [
      loadVehicleTypesForVendor(vendorId),
      loadFeatures(vendorId),
      loadVendor(vendorId),
      loadGeoFences({
        vendorId,
        geoFenceZoneTypeIds: geoFenceZoneTypesIdsFormatted,
        geoFenceName,
        page,
        limit,
        sortOrder,
        sortedBy,
      }),
    ];

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <>
        <DocumentTitle>{translate('routes.geoFences.geoFencesTitle')}</DocumentTitle>
        <Resolver
          successComponent={GeoFencesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/routes/route-tracker"
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadFeatures,
  loadGeoFences,
  loadPermissions,
  loadVehicleTypesForVendor,
  loadVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoFencesPageResolver);
