import { ChangeEvent, PureComponent } from 'react';

import { map, size } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { MultiSelect, TypedField } from '../../core/components';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import translate from '../../core/services/translate';

const STATUSES_OPTIONS = [
  { label: translate('insights.cleared'), value: '1' },
  { label: translate('insights.open'), value: '0' },
];

interface Props {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  label?: string;
  placeholder?: string;
  withLabel?: boolean;
  withPlaceholder?: boolean;
}

class StatusMultiSelectDropdown extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    this.props.input.onChange(value);
  };

  formatText = (selectedOptions: any[], allOptionsSelected: boolean) => {
    if (allOptionsSelected) {
      return translate('insights.allStatuses');
    }

    const selectedStatus = (STATUSES_OPTIONS.find(option => option.value === selectedOptions[0]) || {}).label;

    return size(selectedOptions) === 1
      ? selectedStatus
      : translate('insights.xStatusesSelected', { selected: size(selectedOptions) });
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      multiSelectProps,
    } = this.props;

    const statusesOptions = map(STATUSES_OPTIONS, status => ({
      label: status.label,
      value: status.value,
    }));

    return (
      <TypedField
        component={MultiSelect}
        name={name}
        onChange={this.onChange}
        props={{
          formatText: this.formatText,
          label: label || (withLabel ? translate('insights.allStatuses') : undefined),
          options: statusesOptions,
          placeholder: placeholder || (withPlaceholder ? translate('insights.allStatuses') : undefined),
          ...multiSelectProps,
        }}
      />
    );
  }
}

export default StatusMultiSelectDropdown;
