import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from '../../../store';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { DATE_RANGE_PICKER_TODAY_3 } from '../../../core/constants';
import { DateRangeOptionValue } from '../../../core/components/DateRangePicker';
import { DateRangePicker, MultiSelect, PanelSearch, TypedField } from '../../../core/components';
import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import {
  getExcludeVehicleFilters,
  getExcludedFiltersIds,
  getServiceZonesFiltersPreferencesIds,
  getSupervisorsFiltersPreferencesIds,
  getVehicleFiltersPreferencesIds,
} from 'src/common/utils/filters';
import { getAgingIntervalOptionLabel } from 'src/routes/utils/routeDetails';
import { GroupsMultiSelect, RouteStatusesMultiSelect, ServiceZonesMultiSelect, SupervisorsMultiSelect } from '..';
import { ROUTE, SNOW_PLOW_ID, STREET_SWEEPER_ID } from '../../../fleet/constants';
import { ROUTE_ALL_PRIORITY_TYPES_OPTIONS } from 'src/routes/constants/routePriorityTypes';
import { SegmentConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { SNOW_PLOW, STREET_SWEEPER } from 'src/fleet/constants';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { supervisorExperienceFeatureIsEnabled } from '../../../vendors/ducks/features';
import { VehicleTypeForVendorMultiSelect } from '../../../fleet/components';
import routesFormInitialValuesSelector from '../../services/routesFormInitialValuesSelector';
import translate from '../../../core/services/translate';

export interface RouteTrackerFormValues {
  agingIntervalIds: number[];
  date: DateRangeOptionValue;
  priorityTypeIds: number[];
  routeStatusTypeIds: number[];
  searchTerm: string;
  serviceZones: number[];
  supervisors: number[];
  vehicleTypeIds: number[];
  groupIds: number[];
}

interface PropsWithoutReduxForm extends RouteComponentProps {
  filtersPreferences?: FilterSetting[];
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  isTrackerPage?: boolean;
  isVehicleTypeFilterVisible?: boolean;
  segmentColorSettings?: SegmentConfiguration[];
  segmentConfiguration?: SegmentConfiguration[];
  serviceZones?: ServiceZone[];
  supervisorExperienceEnabled?: boolean;
  supervisors?: Supervisor[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteTrackerFormValues, PropsWithoutReduxForm>;

class RouteTrackerForm extends PureComponent<Props> {
  render() {
    const {
      filtersPreferences,
      handleSubmit,
      isSnowPlowRoute,
      isStreetSweeperRoute,
      isTrackerPage,
      isVehicleTypeFilterVisible = true,
      segmentColorSettings,
      segmentConfiguration,
      serviceZones,
      supervisorExperienceEnabled,
      supervisors,
    } = this.props;

    const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

    // VehicleFilters Preferences
    const excludeVehicleFilters = getExcludeVehicleFilters(filtersPreferences);

    // ServiceZoneFilters Preferences
    const excludedServiceZonesFiltersIds = getExcludedFiltersIds(
      serviceZones,
      filtersPreferences,
      FILTER_SERVICE_ZONE_ID,
    );

    // SupervisorFilters Preferences
    const excludedSupervisorsFiltersIds = getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID);

    const SearchField = <Field name="searchTerm" component={PanelSearch} />;
    const DateField = (
      <Field
        props={{ hasMarginLeft: 'normal' }}
        id="search-route-date-field"
        name="date"
        component={DateRangePicker}
        options={DATE_RANGE_PICKER_TODAY_3}
        margin="no"
        maxInterval={{ amount: 90, unit: 'days' }}
      />
    );

    const segmentConfig = isSnowPlowRoute ? segmentConfiguration : segmentColorSettings;
    const agingIntervalOptions =
      segmentConfig
        ?.filter(item => !!item.enabled)
        ?.map((item: SegmentConfiguration) => {
          const { id } = item.streetSegmentAgingInterval.timeMeasurementType;
          const { id: streetSegmentAgingIntervalId, name } = item.streetSegmentAgingInterval;

          return {
            label: getAgingIntervalOptionLabel(id, name),
            value: streetSegmentAgingIntervalId,
          };
        }) || [];

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection vertical padding="small xSmall" withBorder={isTrackerPage}>
          <Grid multiLine>
            <GridColumn size="3/12" margin={isTrackerPage ? 'no no sMedium no' : 'no'}>
              {DateField}
            </GridColumn>

            {!isSnowOrSweeperRoute && isVehicleTypeFilterVisible && (
              <GridColumn size={isTrackerPage && !supervisorExperienceEnabled ? '3/12' : '2/12'}>
                <Field
                  name="vehicleTypeIds"
                  component={VehicleTypeForVendorMultiSelect}
                  placeholder={translate('vehicles.allVehicleTypes')}
                  multiSelectProps={{
                    margin: 'no',
                    fitContentWidth: true,
                  }}
                  vehicleRoleTypeId={ROUTE}
                  excludeVehicleTypes={[...excludeVehicleFilters, SNOW_PLOW, STREET_SWEEPER]}
                />
              </GridColumn>
            )}

            <GridColumn size={isTrackerPage && !supervisorExperienceEnabled && !isSnowOrSweeperRoute ? '3/12' : '2/12'}>
              <Field
                name="routeStatusTypeIds"
                component={RouteStatusesMultiSelect}
                multiSelectProps={{ margin: 'no', fitContentWidth: true }}
                placeholder={translate('routes.allRouteStatuses')}
              />
            </GridColumn>

            {isSnowOrSweeperRoute && (
              <>
                <GridColumn size="2/12">
                  <TypedField
                    name="priorityTypeIds"
                    component={MultiSelect}
                    props={{
                      options: ROUTE_ALL_PRIORITY_TYPES_OPTIONS,
                      placeholder: translate('customers.streetNetwork.priority'),
                      isClearable: true,
                      margin: 'no small no no',
                      fitContentWidth: true,
                    }}
                  />
                </GridColumn>
                <GridColumn size="2/12">
                  <TypedField
                    name="agingIntervalIds"
                    component={MultiSelect}
                    props={{
                      options: agingIntervalOptions,
                      placeholder: translate('dashboard.lastActivity'),
                      isClearable: true,
                      margin: 'no',
                      fitContentWidth: true,
                    }}
                  />
                </GridColumn>
              </>
            )}

            {isTrackerPage && (
              <>
                <GridColumn size={supervisorExperienceEnabled ? '3/12' : '3/12'}>
                  <TypedField
                    name="serviceZones"
                    component={ServiceZonesMultiSelect}
                    props={{
                      placeholder: translate('routes.allServiceZones'),
                      excludeServiceZonesIds: excludedServiceZonesFiltersIds,
                      includeNoneOption: true,
                      multiSelectProps: {
                        normalizeValues: Number,
                        fitContentWidth: true,
                      },
                    }}
                  />
                </GridColumn>

                {supervisorExperienceEnabled && (
                  <GridColumn size="2/12">
                    <TypedField
                      name="supervisors"
                      component={SupervisorsMultiSelect}
                      props={{
                        placeholder: translate('routes.allSupervisors'),
                        excludeSupervisorsIds: excludedSupervisorsFiltersIds,
                        multiSelectProps: {
                          normalizeValues: Number,
                          fitContentWidth: true,
                        },
                      }}
                    />
                  </GridColumn>
                )}
                <GridColumn size="3/12">
                  <TypedField
                    name="groupIds"
                    component={GroupsMultiSelect}
                    props={{
                      withPlaceholder: true,
                      includeNoneOption: true,
                      multiSelectProps: {
                        normalizeValues: Number,
                        fitContentWidth: true,
                        canCheckAll: true,
                      },
                    }}
                  />
                </GridColumn>
              </>
            )}

            <GridColumn
              size={
                isTrackerPage ? (isSnowOrSweeperRoute ? '4/12' : '5/12') : isVehicleTypeFilterVisible ? '2/12' : '4/12'
              }
            >
              {SearchField}
            </GridColumn>

            <GridColumn align="right" marginLeft="auto">
              <Button id="search-route-button" color="primary">
                {translate('common.search')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: PropsWithoutReduxForm) => {
  const { filters } = state.common.filters;
  const vehicleFiltersPreferencesIds = getVehicleFiltersPreferencesIds(filters).filter(
    s => s !== SNOW_PLOW_ID && s !== STREET_SWEEPER_ID,
  );
  const serviceZonesFiltersPreferencesIds = getServiceZonesFiltersPreferencesIds(filters);
  const supervisorsFiltersPreferencesIds = getSupervisorsFiltersPreferencesIds(filters);

  return {
    filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
    initialValues: routesFormInitialValuesSelector(
      ownProps.location.search,
      vehicleFiltersPreferencesIds,
      serviceZonesFiltersPreferencesIds,
      supervisorsFiltersPreferencesIds,
    ),
    segmentConfiguration: state.vendors.snowPlowSettings.snowPlowSettings?.displayConfiguration?.segmentConfiguration,
    segmentColorSettings: state.vendors.streetSweepingSettings.streetSweepingSettings?.segmentColorSettings,
    serviceZones: state.routes.serviceZones.serviceZones,
    supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
    supervisors: state.routes.supervisors.supervisors,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<RouteTrackerFormValues, PropsWithoutReduxForm>({
      form: 'routeTracker',
      enableReinitialize: true,
    })(RouteTrackerForm),
  ),
);
