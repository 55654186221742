import React, { useMemo } from 'react';

import { push } from 'connected-react-router';
import { debounce, map } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { FlexForm } from 'src/common/components/styled/FlexForm';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Dropdown, PanelSearch, TypedField } from 'src/core/components';
import { Grid, GridColumn } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { createUrl } from 'src/utils/services/queryParams';

export interface UnbilledChargesFilterFormValues {
  unbilledSearchTerm: string;
  workOrderTypeId: number;
  containerTypeId: number;
}

interface PropsWithoutReduxForm extends RouteComponentProps {
  containerTypesOptions: TechnicalType[];
  workFlowTypesOptions: TechnicalType[];
  setLoadingUnbilledSection: (status: boolean) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<UnbilledChargesFilterFormValues, PropsWithoutReduxForm>;

const UnbilledChargesFilterForm = (props: Props) => {
  const {
    handleSubmit,
    location: { pathname, search },
    containerTypesOptions,
    workFlowTypesOptions,
    setLoadingUnbilledSection,
  } = props;

  const dispatch = useDispatch();

  const containerTypes = map(containerTypesOptions, ({ name, id }) => ({
    label: name,
    value: id.toString(),
  }));

  const workFlowTypes = map(workFlowTypesOptions, ({ name, id }) => ({
    label: name,
    value: id.toString(),
  }));

  const debouncedCallback = useMemo(
    () =>
      debounce((searchTerm: string) => {
        dispatch(push(createUrl(pathname, search, { unbilledSearchTerm: searchTerm })));
        setLoadingUnbilledSection(true);
      }, 470),
    [dispatch, setLoadingUnbilledSection, pathname, search],
  );

  const setSearchTermDebounced = React.useCallback(debouncedCallback, [debouncedCallback]);

  const setWorkOrderType = (workOrderTypeId: number) => {
    dispatch(push(createUrl(pathname, search, { workOrderTypeId })));
    setLoadingUnbilledSection(true);
  };

  const setContainerType = (containerTypeId: number) => {
    dispatch(push(createUrl(pathname, search, { containerTypeId })));
    setLoadingUnbilledSection(true);
  };

  return (
    <FlexForm onSubmit={handleSubmit} noValidate>
      <Grid width="100%">
        <GridColumn size="6/12" padding="no xSmall no no" borderRight>
          <TypedField
            name="unbilledSearchTerm"
            component={PanelSearch}
            props={{
              placeholder: translate('common.search'),
              isClearable: true,
              margin: 'no',
            }}
            onChange={(_, newValue: UnbilledChargesFilterFormValues['unbilledSearchTerm']) =>
              setSearchTermDebounced(newValue)
            }
          />
        </GridColumn>
        <GridColumn size="3/12">
          <TypedField
            name="workOrderTypeId"
            component={Dropdown}
            props={{
              width: '100%',
              isClearable: true,
              options: workFlowTypes as any,
              placeholder: translate('finance.workOrderDescription'),
              margin: 'no',
            }}
            onChange={(_, newValue: UnbilledChargesFilterFormValues['workOrderTypeId']) => setWorkOrderType(newValue)}
          />
        </GridColumn>
        <GridColumn size="3/12" padding="no no no xSmall">
          <TypedField
            name="containerTypeId"
            component={Dropdown}
            props={{
              width: '100%',
              isClearable: true,
              options: containerTypes as any,
              placeholder: translate('finance.containerType'),
              margin: 'no',
            }}
            onChange={(_, newValue: UnbilledChargesFilterFormValues['containerTypeId']) => setContainerType(newValue)}
          />
        </GridColumn>
      </Grid>
    </FlexForm>
  );
};

export default withRouter(
  reduxForm<UnbilledChargesFilterFormValues, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: 'unbillChargesFilter',
  })(UnbilledChargesFilterForm),
);
