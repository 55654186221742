import { map, orderBy } from 'lodash-es';

import { CHART_COLORS_BY_STATUS } from '../constants';
import { getAgingIntervalOptionLabel } from '../utils/routeDetails';
import { PICKUP_STATUSES } from '../../common/constants';
import { Route } from '../interfaces/Route';
import { ROUTE_STATUSES_BY_ID } from '../constants';
import { SNOW_OR_SWEEPER_CHART_COLORS_BY_STATUS } from '../constants/chartColorsByStatus';
import { SNOW_OR_SWEEPER_ROUTE_STATUSES, SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES } from 'src/common/constants';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

export const transformHeader = (o: any) => ({
  ...o,
  routeProgress: map(o.routeProgress, ({ id, value }) => ({
    label: PICKUP_STATUSES[id].name,
    value,
    color: !!id && CHART_COLORS_BY_STATUS[id].color,
    technicalName: PICKUP_STATUSES[id].technicalName,
  })),
  jobsProgress: map(o.jobsProgress, ({ id, value }) => ({
    label: PICKUP_STATUSES[id].name,
    value,
    color: !!id && CHART_COLORS_BY_STATUS[id].color,
    technicalName: PICKUP_STATUSES[id].technicalName,
  })),
});

export const transformSnowOrSweeperRoutesHeader = (o: any) => ({
  ...o,
  routeProgress: map(o.routesProgress, ({ id, value }) => ({
    label: PICKUP_STATUSES[id].name,
    value,
    color: !!id && CHART_COLORS_BY_STATUS[id].color,
    technicalName: PICKUP_STATUSES[id].technicalName,
  })),
  segmentsProgress: map(orderBy(o.segmentsProgress, 'id', 'asc'), ({ id, value }) => ({
    label: SNOW_OR_SWEEPER_ROUTE_STATUSES[id].name,
    value,
    color: !!id && SNOW_OR_SWEEPER_CHART_COLORS_BY_STATUS[id].color,
    technicalName: SNOW_OR_SWEEPER_ROUTE_STATUSES[id].technicalName,
  })),
  agingIntervals: map(o.agingIntervals, agingInterval => {
    const { red, blue, green, timeMeasurementTypeId } = agingInterval;
    const { label, value } = agingInterval.valueLabel;

    return {
      label: getAgingIntervalOptionLabel(timeMeasurementTypeId, label),
      value,
      color: `rgba(${red}, ${green}, ${blue})`,
    };
  }),
});

export const transformLoadSnowOrSweeperRoutesTracker = (routes: any) => {
  return map(routes, route => ({
    ...route,
    segmentsProgress: map(orderBy(route.segmentsProgress, 'statusType.id', 'asc'), segmentProgress => ({
      ...segmentProgress,
      label: SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES[segmentProgress.statusType.id].name,
      technicalName: SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES[segmentProgress.statusType.id].technicalName,
    })),
    lastServicedVehicleName: route.lastServiced?.vehicleName,
    nowServicingVehicleName: route.nowServicing?.vehicleName,
    nowServicing: orderBy(route.nowServicing, 'driverId', 'asc'),
    routeStatusName: ROUTE_STATUSES_BY_ID[route.routeStatusType.id].name,
    routeStatusTypeTechnicalName: route.routeStatusType?.technicalName,
    routeStatusTypeId: route.routeStatusType?.id,
  }));
};

const transformLoadRouteTracker = (values: any) => ({
  ...values,
  routes: values.routes.map((route: Route) => ({
    ...route,
    materialType: translate(createTranslationKey(route.wasteMaterialTypeTechnicalName, 'common.wasteTypes')),
    routeStatusName: ROUTE_STATUSES_BY_ID[route.routeStatusTypeId].name,
    vehicleTypeName: translate(createTranslationKey(route.vehicleType, 'vehicles.vehicleTypes')),
    routeProgress: map(route.routeProgress, ({ value, id }) => ({
      label: PICKUP_STATUSES[id].name,
      value,
      technicalName: PICKUP_STATUSES[id].technicalName,
      id,
    })),
  })),
  header: transformHeader(values.header),
  total: values.routes.length,
});

export default transformLoadRouteTracker;
