import cart from '../../common/assets/img/services/cart.png';
import frontLoad from '../../common/assets/img/services/frontLoad.png';
import handPickup from '../../common/assets/img/services/handPickup.png';
import openTop from '../../common/assets/img/services/openTop.png';
import rearLoad from '../../common/assets/img/services/rearLoad.png';
import receiverBox from '../../common/assets/img/services/receiverBox.png';
import stationaryCompactor from '../../common/assets/img/services/stationaryCompactor.png';
import verticalCompactor from '../../common/assets/img/services/verticalCompactor.png';

export const ALL_EQUIPMENTS_TYPES = 'ALL_EQUIPMENTS_TYPES';

export const EQUIPMENT_TYPES = ['FL', 'RL', 'VC', 'OT', 'RB', 'RESBG', 'RESCT', 'COMCT', 'CP'];

export const EQUIPMENT_TYPE_ICONS: { [key: string]: string } = {
  FL: frontLoad,
  RL: rearLoad,
  VC: verticalCompactor,
  OT: openTop,
  RB: receiverBox,
  RESBG: handPickup,
  RESCT: cart,
  COMCT: cart,
  CP: stationaryCompactor,
};
