import { http } from '../../core/services/http';
import transformLoadPickupFrequencyTypes from './transformLoadPickupFrequencyTypes';
import transformServicesYearlyFrequencyTypes from './transformServicesYearlyFrequencyTypes';
import transformServicesMonthlyFrequencyTypes from './transformServicesMonthlyFrequencyTypes';
import transformServicesWeeklyFrequencyTypes from './transformServicesWeeklyFrequencyTypes';

export const doLoadpickupFrequencyTypes = () =>
  http.get('services/pickupFrequencyTypes').then(response => transformLoadPickupFrequencyTypes(response.data));

export const doLoadMonthlyServicesYearlyFrequencyTypes = () =>
  http.get('common/systemTypes/monthlyServicesYearlyFrequencyTypes').then(response => transformServicesYearlyFrequencyTypes(response.data));

export const doLoadMonthlyServicesMonthlyFrequencyTypes = () =>
  http.get('common/systemTypes/monthlyServicesMonthlyFrequencyTypes').then(response => transformServicesMonthlyFrequencyTypes(response.data));

export const doLoadMonthlyServicesWeeklyFrequencyTypes = () =>
  http.get('common/systemTypes/monthlyServicesWeeklyFrequencyTypes').then(response => transformServicesWeeklyFrequencyTypes(response.data));
