import React from 'react';
import { connect } from 'react-redux';

import { HaulerProfileGridColumn } from '../styled';
import { AppState } from '../../../store';
import { Grid, PanelSectionSubTitle } from '../../../core/components/styled';
import { EquipmentCategory } from '../../interfaces/Equipments';
import { EquipmentSizeField } from './EquipmentSizeField';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import translate from '../../../core/services/translate';
import { EquipmentsGrid } from '../styled/HaulerProfileGrid';

interface Props {
  haulerId: string;
  userName: string;
  equipments: EquipmentCategory[];
}

const EquipmentsForm: React.FC<Props> = ({ equipments, haulerId, userName }) => {
  return (
    <EquipmentsGrid>
      {equipments.map((equipmentType, index) => (
        <HaulerProfileGridColumn padding="small" key={index}>
          <PanelSectionSubTitle>
            {translate(
              `haulerProfile.equipments.equipmentSubTypes.${
                (equipmentType.binSubTypeCategory || '') + equipmentType.binSubType
              }`,
            )}
          </PanelSectionSubTitle>
          <Grid multiLine>
            {equipmentType.binClasses.map((es, index) => (
              <EquipmentSizeField
                equipmentSize={es}
                equipmentCategory={equipmentType}
                haulerId={haulerId}
                key={`${index}_${es.id}`}
                userName={userName}
              />
            ))}
            <EquipmentSizeField
              equipmentSize={{
                isActive: false,
                isOther: true,
                isUsedInServiceArea: false,
                canEdit: true
              }}
              equipmentCategory={equipmentType}
              haulerId={haulerId}
              key={equipmentType.binClasses.length}
              userName={userName}
            />
          </Grid>
        </HaulerProfileGridColumn>
      ))}
    </EquipmentsGrid>
  );
};

const mapStateToProps = (state: AppState) => ({
  haulerId: (currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor) as any) as string,
  equipments: state.haulerProfile.equipments.equipments as any,
  userName: state.account.login.user.email,
});

export default connect(mapStateToProps)(EquipmentsForm);
