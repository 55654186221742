import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';

import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { CustomerBillSetting } from 'src/customers/interfaces/Customers';
import { CustomerDetailsEditorForm } from '../forms';
import { CustomerDetailsEditorFormValues } from '../forms/CustomerDetailsEditorForm';
import { dateFormat } from 'src/utils/services/validator';
import { getFormattedAddress } from 'src/haulerProfile/utils/address';
import { loadBillingCycles } from 'src/common/ducks';
import { loadBillingModule } from 'src/vendors/ducks';
import { loadBusinessTypes } from 'src/common/ducks/businessTypes';
import { loadCustomerTypes, loadPaymentTerms } from 'src/customers/ducks';
import {
  loadExcludeCustomerFromProcessingFee,
  resetExcludeCustomerFromProcessingFee,
} from 'src/vendors/ducks/excludeCustomerFromProcessingFee';
import { Modal } from '../../../core/components';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { useSelector } from 'src/core/hooks/useSelector';
import customerEditorFormInitialValuesSelector from 'src/customers/services/customerEditorFormInitialValuesSelector';
import translate from '../../../core/services/translate';

export const customerDetailsEditorModalId = 'customers-details-editor-modal-id';

interface RouteParams {
  customerId?: string;
}

interface Props {
  allowedCustomerTypeId?: number;
  serviceContractCustomerName?: string;
  serviceContractCustomerTypeId?: number;
  customerId?: number;
  onSaveCustomer(...args: any[]): void;
  onCancel(pristine: boolean): void;
}

const CustomerDetailsEditorModal = (props: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));
  const {
    onSaveCustomer,
    onCancel,
    allowedCustomerTypeId,
    serviceContractCustomerName,
    serviceContractCustomerTypeId,
    customerId: propsCustomerId,
  } = props;

  useEffect(() => {
    loadBillingCycles()(dispatch);
    loadCustomerTypes()(dispatch);
    loadPaymentTerms()(dispatch);
    loadBusinessTypes()(dispatch);
    loadExcludeCustomerFromProcessingFee()(dispatch);
    if (isBillingFeatureActive) {
      loadBillingModule(vendorId)(dispatch);
    }

    return () => {
      resetExcludeCustomerFromProcessingFee()(dispatch);
    };
  }, [dispatch, vendorId, isBillingFeatureActive]);

  const isSaving = useSelector(state => state.customers.customer.isSaving);

  const { customerId: routeCustomerId } = useParams<RouteParams>();

  const customerId = propsCustomerId ? propsCustomerId : Number(routeCustomerId);

  const customerTypeOptions = useSelector(state =>
    (state.customers.customerTypes.customerTypes as TechnicalType[]).filter(
      allowedCustomerTypeId ? customerType => customerType.id === allowedCustomerTypeId : customerType => customerType,
    ),
  );

  const billingCycleOptions = useSelector(state => state.common.billingCycles.billingCycles);
  const billingMethodOptions = useSelector(state => state.common.billingMethods.billingMethods);
  const currencyOptions = useSelector(state => state.customers.currencies.currencies);
  const paymentTermOptions = useSelector(state => state.customers.paymentTerms.paymentTerms);
  const isBillingModuleLoading = useSelector(state => state.vendors.billingModule.isLoading);

  const initialValues: CustomerDetailsEditorFormValues =
    useSelector(state =>
      customerEditorFormInitialValuesSelector(
        state.customers.customer,
        customerTypeOptions,
        serviceContractCustomerName,
        serviceContractCustomerTypeId,
      ),
    ) || ({} as CustomerDetailsEditorFormValues);

  const { customerBillSetting } = initialValues;
  const { billingAddress, sendInvoiceByEmail } = customerBillSetting || ({} as CustomerBillSetting);

  return (
    <Modal
      padding="medium no no no"
      size="medium"
      isLoading={isSaving || isBillingModuleLoading}
      title={translate(`customers.${customerId ? 'edit' : 'create'}Customer`)}
      id={customerDetailsEditorModalId}
      overflow={isSaving || isBillingModuleLoading ? 'hidden' : 'auto'}
    >
      <CustomerDetailsEditorForm
        billingCycleOptions={billingCycleOptions}
        billingMethodOptions={billingMethodOptions}
        currencyOptions={currencyOptions}
        customerId={customerId}
        customerTypeOptions={customerTypeOptions}
        initialValues={{
          ...initialValues,
          customerBillSettingSendInvoiceByEmail:
            sendInvoiceByEmail === false || sendInvoiceByEmail === true ? sendInvoiceByEmail : true,
          startDate: initialValues?.startDate ? moment(initialValues?.startDate).format(dateFormat) : undefined,
          endDate: initialValues?.endDate ? moment(initialValues?.endDate).format(dateFormat) : undefined,
          address: {
            ...initialValues?.address,
            formattedAddress: getFormattedAddress(initialValues?.address),
          },
          customerBillSettingBillingAddress: {
            ...billingAddress,
            formattedAddress: getFormattedAddress(billingAddress),
          },
          customerPOBoxAddress: billingAddress?.line1,
          customerIsPOBoxAddress: billingAddress?.isPOBox,
        }}
        onCancel={onCancel}
        onSubmit={onSaveCustomer}
        paymentTermOptions={paymentTermOptions}
      />
    </Modal>
  );
};

export default CustomerDetailsEditorModal;
