import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { BareButtonStyle } from './Button';

type Props = Partial<{
  alignRight: boolean;
  isActive: boolean;
  last: boolean;
  tabletAlignHalfLeft: boolean;
  tabletAlignLeft: boolean;
  numberOfMonths: number;
}>;

export const DateRangePickerOption = styled.button<Props>`
  ${BareButtonStyle};
  margin-right: 5px;
  padding: 8px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 12px;

  ${props =>
    !props.isActive &&
    css`
      &:hover {
        background-color: ${props => props.theme.grayLight};
      }
    `};

  ${props =>
    props.isActive &&
    css`
      background-color: ${props.theme.brandPrimary};
      color: #fff;
    `};

  ${props =>
    props.last &&
    css`
      float: right;
      color: ${props => props.theme.brandPrimary};
    `};
`;

export const DateRangePickerOptions = styled.div`
  margin: 0 10px 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.grayLight};
`;

export const DateRangePickerOverlay = styled.div<Props>`
  position: absolute;
  z-index: 3000;
  width: ${p => 250 * (p.numberOfMonths || 2)}px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};

  ${props =>
    props.alignRight &&
    css`
      right: 0;
    `};

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    right: -50%;

    ${props =>
      props.tabletAlignLeft &&
      css`
        right: auto;
      `};

    ${props =>
      props.tabletAlignHalfLeft &&
      css`
        right: -25%;
      `};
  }

  @media (max-width: ${props => props.theme.mobileBreakpoint}) {
    right: initial;
    width: initial;
  }
`;
