import { identity, get, find } from 'lodash-es';
import { createSelector } from 'reselect';

import { CUSTOMER_NOTIFICATION_TEXT_MESSAGES } from '../constants';

const customerNotificationFormInitialValues = (
  notificationEmailTemplatesState: any,
  formValues: any,
  notificationTypesState: any,
) => {
  const notificationEmailTemplate = formValues.pickupExceptionTypeId
    ? find(get(notificationEmailTemplatesState, 'notificationEmailTemplates', []), {
        pickupExceptionTypeId: formValues.pickupExceptionTypeId,
      })
    : get(notificationEmailTemplatesState, 'notificationEmailTemplates', [])[0];

  const fromAddress = get(notificationEmailTemplate, 'fromAddress', '');
  const subject = get(notificationEmailTemplate, 'subject', '');
  const bodyContent = get(notificationEmailTemplate, 'bodyContent', '');
  const id = get(notificationEmailTemplate, 'id');
  const sendDailySummaryEmail = get(notificationEmailTemplate, 'sendDailySummaryEmail');
  const toAdditionalCCAddress = get(notificationEmailTemplate, 'toAdditionalCCAddress');
  const sendIndividualEventEmail = get(notificationEmailTemplate, 'sendIndividualEventEmail');
  const textMessage = get(notificationEmailTemplate, 'textMessage');
  const notificationTypeTechnicalName = get(
    find(notificationTypesState, { id: get(formValues, 'name') }),
    'technicalName',
  );
  const name = get(notificationEmailTemplate, 'name') || notificationTypeTechnicalName;

  const defalultMessage =
    name && CUSTOMER_NOTIFICATION_TEXT_MESSAGES[name] ? CUSTOMER_NOTIFICATION_TEXT_MESSAGES[name].name : undefined;

  return {
    ...formValues,
    id,
    fromAddress,
    subject,
    bodyContent,
    sendDailySummaryEmail,
    toAdditionalCCAddress,
    sendIndividualEventEmail,
    textMessage: textMessage || defalultMessage,
  };
};

const customerNotificationFormInitialValuesSelector = createSelector(customerNotificationFormInitialValues, identity);

export default customerNotificationFormInitialValuesSelector;
