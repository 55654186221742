import { duration } from 'src/utils/services/formatter';
import { RouteDetail, RouteDetailLabel, RouteDetailsWrapper, RouteDetailValue } from '../../styled/RouteDetails';
import { Table } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import TimeManagementTableRow from './TimeManagementTableRow';
import translate from 'src/core/services/translate';

export const timeManagementTableCellWidths = ['90%', '10%'];

const timeManagementTableCells = [
  {
    name: 'vehicleDriver',
    label: translate('routes.timeManagementData.vehicleDriver'),
    width: timeManagementTableCellWidths[0],
    sortable: true,
  },
  {
    name: 'options',
    label: translate('common.options'),
    width: timeManagementTableCellWidths[1],
    sortable: false,
  },
];

export default function TimeManagementSection() {
  const { timeManagementData } = useSelector(state => state.routes.timeManagement);

  if (!timeManagementData) return null;

  return (
    <>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.routeStatusDetails.totalBreaktime')}</RouteDetailLabel>
          <RouteDetailValue>{duration(timeManagementData?.totalBreaktime) || '-'}</RouteDetailValue>
        </RouteDetail>
        {timeManagementData?.isSweeperRoute && (
          <RouteDetail>
            <RouteDetailLabel>{translate('routes.routeStatusDetails.totalDrivingToDumpDebris')}</RouteDetailLabel>
            <RouteDetailValue>{duration(timeManagementData?.totalDrivingToDumpDebris) || '-'}</RouteDetailValue>
          </RouteDetail>
        )}
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.routeStatusDetails.totalOffRoute')}</RouteDetailLabel>
          <RouteDetailValue>{duration(timeManagementData?.totalOffRoute) || '-'}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.routeStatusDetails.totalFacilityTransit')}</RouteDetailLabel>
          <RouteDetailValue>{duration(timeManagementData?.totalFacilityTransit) || '-'}</RouteDetailValue>
        </RouteDetail>
      </RouteDetailsWrapper>
      {timeManagementData.serviceActivity?.length ? (
        <Table
          cells={timeManagementTableCells}
          rows={timeManagementData.serviceActivity}
          rowComponent={TimeManagementTableRow}
        />
      ) : (
        <Text block align="center">
          {translate('routes.noTimeManagementDetails')}
        </Text>
      )}
    </>
  );
}
