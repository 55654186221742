import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { isPreTripFeatureEnabled, isPostTripFeatureEnabled } from 'src/vendors/ducks/features';
import { PRE_TRIP_INSPECTION, POST_TRIP_INSPECTION } from 'src/vendors/constants/faultCodeSummaryType';
import { PopoverWrapper } from 'src/core/components';
import { Popover } from 'src/core/components/styled';
import { Switch } from '../../core/components';
import { TableCell, TableRow } from '../../core/components/styled';
import translate from 'src/core/services/translate';
import { LOADS_DUMPED, WATER_FILL_UPS } from './forms/StreetSweepingForm';

interface Props {
  label: string;
  name: string;
}

const SnowPlowTableRow: React.FC<Props> = ({ label, name }) => {
  const isPreTripEnabled = useSelector((state: AppState) => isPreTripFeatureEnabled(state));
  const isPostTripEnabled = useSelector((state: AppState) => isPostTripFeatureEnabled(state));
  const isFeatureDisabled =
    (!isPreTripEnabled && name === PRE_TRIP_INSPECTION) || (!isPostTripEnabled && name === POST_TRIP_INSPECTION);
  const isFeatureHidden = name === WATER_FILL_UPS || name === LOADS_DUMPED;

  return (
    <Fragment>
      {!isFeatureHidden && (
        <TableRow>
          <TableCell width="80%">{label}</TableCell>
          <TableCell width="20%">
            {isFeatureDisabled ? (
              <PopoverWrapper
                triggerButton={<Field disabled={isFeatureDisabled} component={Switch} name={name} />}
                popoverContent={<Popover>{translate('vendors.prePostTripConfigMessage')}</Popover>}
                width="15px"
                size="small"
                margin="no"
              />
            ) : (
              <Field disabled={isFeatureDisabled} component={Switch} name={name} />
            )}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export default SnowPlowTableRow;
