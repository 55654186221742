import { camelCase } from 'lodash-es';
import { memo } from 'react';

import { AppState } from 'src/store';
import {
  CANCELED,
  COMPLETED,
  IN_PROGRESS,
  ISSUE_REPORTED,
  PLACED_ON_HOLD,
  REASSIGNED,
  SCHEDULED,
  SERVICED,
} from 'src/common/constants';
import { CHART_COLORS_BY_STATUS, SNOW_OR_SWEEPER_CHART_COLORS_BY_STATUS } from 'src/routes/constants';
import { getRandomHexColor } from 'src/utils/randomColor';
import {
  HorizontalBarChartTitle,
  HorizontalBarChartWrapper,
} from 'src/core/components/styled/HorizontalBarChartStyles';
import { kFormatter } from 'src/utils/services/formatter';
import { RouteDetail, RouteDetailsWrapper } from '../../styled/RouteDetails';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { Text } from 'src/core/components/styled';
import { transparentize } from 'polished';
import { useSelector } from 'src/core/hooks/useSelector';
import DoughnutChart from 'src/core/components/DoughnutChart';
import HorizontalBarChart from 'src/core/components/HorizontalBarChart';
import translate from 'src/core/services/translate';

type Props = {
  isDriverExperienceSimple?: boolean;
  notServicedSegmentsCount: number;
  partiallyServicedSegmentsCount: number;
  servicedSegmentsCount: number;
};

export default memo(function StopConfirmationSection({
  isDriverExperienceSimple,
  notServicedSegmentsCount,
  partiallyServicedSegmentsCount,
  servicedSegmentsCount,
}: Props) {
  const { serviceDetails } = useSelector((state: AppState) => state.routes.serviceDetails);
  const { routeSummary } = useSelector((state: AppState) => state.routes.routeSummary);

  if (!serviceDetails || !routeSummary) return null;

  const isSnowPlowRoute = routeSummary.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const stopStatuses = isSnowOrSweeperRoute
    ? [
        {
          id: SCHEDULED,
          value: notServicedSegmentsCount,
        },
        {
          id: COMPLETED,
          value: servicedSegmentsCount,
        },
      ]
    : [
        {
          id: SCHEDULED,
          value: serviceDetails.scheduledStopsCount,
        },
        {
          id: IN_PROGRESS,
          value: serviceDetails.inProgressStopsCount,
        },
        {
          id: COMPLETED,
          value: serviceDetails.completedStopsCount,
        },
        {
          id: CANCELED,
          value: serviceDetails.cancelledStopsCount,
        },
        {
          id: REASSIGNED,
          value: serviceDetails.reassignedStopsCount,
        },
        {
          id: ISSUE_REPORTED,
          value: serviceDetails.issueReportedStopsCount,
        },
        {
          id: SERVICED,
          value: serviceDetails.servicedStopsCount,
        },
        {
          id: PLACED_ON_HOLD,
          value: serviceDetails.placedOnHoldStopsCount,
        },
      ];

  !isDriverExperienceSimple &&
    isSnowOrSweeperRoute &&
    stopStatuses.splice(1, 0, {
      id: IN_PROGRESS,
      value: partiallyServicedSegmentsCount,
    });

  const currentRouteIssues = isSnowOrSweeperRoute ? serviceDetails.exceptions : serviceDetails.routeIssues;

  const barChartColors = currentRouteIssues.map(getRandomHexColor);
  const chartColors = isSnowOrSweeperRoute ? SNOW_OR_SWEEPER_CHART_COLORS_BY_STATUS : CHART_COLORS_BY_STATUS;

  const routeIssues = currentRouteIssues
    .sort((a, b) => b.pickupExceptionTypeCount - a.pickupExceptionTypeCount)
    .slice(0, 5);

  const activeAgingIntervals = serviceDetails.agingIntervals;
  const totalAgingIntervalsSegments = activeAgingIntervals?.reduce((acc, curr) => {
    return acc + curr.value;
  }, 0);
  const activeAgingIntervalsLabels = activeAgingIntervals?.map(el => `${el?.label} (${el?.value})`);
  const activeAgingIntervalsColors = activeAgingIntervals?.map(el => el?.color);
  const activeAgingIntervalsData = activeAgingIntervals?.map(el => (el.value * 100) / totalAgingIntervalsSegments);

  return (
    <RouteDetailsWrapper>
      <RouteDetail>
        <DoughnutChart
          showLegend
          title={translate(isSnowOrSweeperRoute ? 'routes.snowPlow.streetSegments' : 'routes.stops')}
          doughnutSize={150}
          dataset={{
            data: stopStatuses.map(stop => stop.value),
            backgroundColor: stopStatuses.map(stop => chartColors[stop.id].color),
          }}
          labels={stopStatuses.map(stop => `${chartColors[stop.id].name} (${stop.value})`)}
          hasMarginLeft={(stopStatuses?.find(status => status.id === IN_PROGRESS)?.value || 0) > 0}
        />
      </RouteDetail>

      {isSnowOrSweeperRoute && (
        <RouteDetail>
          <DoughnutChart
            showLegend
            title={translate('routes.latestActivity')}
            doughnutSize={150}
            dataset={{
              data: activeAgingIntervalsData,
              backgroundColor: activeAgingIntervalsColors,
            }}
            labels={activeAgingIntervalsLabels}
          />
        </RouteDetail>
      )}

      <RouteDetail>
        {routeIssues.length ? (
          <HorizontalBarChart
            chartWidth={400}
            chartHeight={170}
            title={translate('routes.top5RouteIssues')}
            dataset={{
              data: routeIssues.map(routeIssue => routeIssue.pickupExceptionTypeCount),
              backgroundColor: barChartColors.map(color => transparentize(0.9, color)),
              borderColor: barChartColors,
              borderWidth: 1,
            }}
            labels={routeIssues.map(
              routeIssue =>
                `${translate(`routes.pickupIssueTypes.${camelCase(routeIssue.pickupExceptionTypeName)}`)} (${kFormatter(
                  routeIssue.pickupExceptionTypeCount,
                )})`,
            )}
          />
        ) : (
          <HorizontalBarChartWrapper>
            <HorizontalBarChartTitle>{translate('vehicles.issues')}</HorizontalBarChartTitle>
            <Text margin="medium no">{translate('routes.noIssues')}</Text>
          </HorizontalBarChartWrapper>
        )}
      </RouteDetail>
    </RouteDetailsWrapper>
  );
});
