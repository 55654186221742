import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { reduce, map, has, find, includes } from 'lodash-es';

import {
  DashboardFilterList as DashboardFilterListContainer,
  DashboardFilterListTitle,
  DashboardFilterListItems,
  DashboardFilterListItem,
  DashboardFilterListDisabledItem,
} from '../../styled';
import { Checkbox } from '../../../../core/components';
import translate from '../../../../core/services/translate';
import { SNOW_ROAD_CONDITIONS, STREET_SWEEPER_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';

const FieldMock = Field as any;

interface Props {
  filters: any;
  pickupExceptionTypes?: any[];
  title?: string;
  checkAllFormName?: string;
  secondary?: boolean;
  canCheckAll?: boolean;
  shouldNotTranslate?: boolean;
  isAllExceptionsChecked?: boolean;
  isDisabled?: boolean;
  updateFormValue(name: string, value: any, event?: any): void;
}

class DashboardFilterList extends PureComponent<Props> {
  onCheckAllChange = (event: any, isChecked: boolean) =>
    isChecked ? this.checkAllSubFilters() : this.unCheckAllSubFilters();

  onCheckFilterChange = (event: any, isChecked: boolean, hasSubFilters: boolean) => {
    const { canCheckAll, checkAllFormName, updateFormValue, pickupExceptionTypes, isAllExceptionsChecked } = this.props;

    if (checkAllFormName && (canCheckAll || !isChecked)) {
      updateFormValue(checkAllFormName, false);
    }

    updateFormValue(event.target.name, isChecked, event);

    if (hasSubFilters) {
      if (isChecked) {
        this.checkAllFilterSubFilters(event.target.name);
      } else {
        this.unCheckAllFilterSubFilters(event.target.name);
      }
    }

    (this.checkAllExceptions as any)(pickupExceptionTypes, isAllExceptionsChecked, updateFormValue);
  };

  checkAllFilterSubFilters = (filterName: string) => {
    const { filters, updateFormValue } = this.props;

    const filter = find(
      filters.filter((filter: any) => !filter.isDisabled),
      filter => filter.formName === filterName,
    );

    const { canCheckAll, checkAllFormName, filters: subFilters, unCheckedByDefault } = filter;

    const formNames = reduce(
      subFilters,
      (formNames, filter) => {
        formNames.push(filter.formName);
        return formNames;
      },
      [] as any[],
    );
    if (canCheckAll) formNames.push(checkAllFormName);

    !unCheckedByDefault && formNames.forEach(formName => updateFormValue(formName, true));
  };

  unCheckAllFilterSubFilters = (filterName: string) => {
    const { filters, updateFormValue, checkAllFormName } = this.props;
    const filter = find(
      filters.filter((filter: any) => !filter.isDisabled),
      filter => filter.formName === filterName,
    );

    const { filters: subFilters } = filter;

    const formNames = map(subFilters, 'formName');
    formNames.forEach(formName => updateFormValue(formName, false));
    (updateFormValue as any)(checkAllFormName, false);
  };

  checkAllSubFilters = () => {
    const { filters, updateFormValue, checkAllFormName, pickupExceptionTypes, isAllExceptionsChecked } = this.props;
    const formNames = reduce(
      filters.filter((filter: any) => !filter.isDisabled),
      (formNames, filter) => {
        const subFilterFormNames = filter.filters
          ? map(
              filter.filters.filter((filter: any) => !filter.isDisabled),
              'formName',
            )
          : [];

        formNames.push(filter.formName, ...subFilterFormNames);
        if (filter.canCheckAll) formNames.push(filter.checkAllFormName);
        return formNames;
      },
      [] as any[],
    );

    formNames.forEach(formName => updateFormValue(formName, true));
    (updateFormValue as any)(checkAllFormName, false);

    (this.checkAllExceptions as any)(pickupExceptionTypes, isAllExceptionsChecked, updateFormValue);
  };

  unCheckAllSubFilters = () => {
    const { filters, updateFormValue, checkAllFormName } = this.props;
    const formNames = map(filters, 'formName');
    formNames.forEach(formName => updateFormValue(formName, false));
    (updateFormValue as any)(checkAllFormName, false);
  };

  checkAllExceptions = (
    pickupExceptionTypes: any[],
    isAllExceptionsChecked: boolean,
    updateFormValue: (name: string, value: any) => void,
  ) => {
    if (pickupExceptionTypes && isAllExceptionsChecked) {
      pickupExceptionTypes.forEach(exception =>
        updateFormValue(`containerServiceInsightTypes.issuesReportedFilters.${exception.id}`, true),
      );
    }
  };

  render() {
    const { secondary, title, canCheckAll, checkAllFormName, filters, shouldNotTranslate, isDisabled } = this.props;

    return (
      <DashboardFilterListContainer secondary={secondary}>
        {title && <DashboardFilterListTitle>{title}</DashboardFilterListTitle>}

        <DashboardFilterListItems>
          {canCheckAll && (
            <DashboardFilterListItem>
              <FieldMock
                name={checkAllFormName}
                component={Checkbox}
                label={translate('common.all')}
                size="small"
                onChange={this.onCheckAllChange}
              />
            </DashboardFilterListItem>
          )}

          {filters.map((filter: any) => {
            const isFieldDisabled =
              includes(filter.label, [SNOW_ROAD_CONDITIONS]) || includes(filter.label, STREET_SWEEPER_CONDITIONS)
                ? isDisabled
                : filter.isDisabled;
            return (
              <DashboardFilterListItem
                key={filter.formName}
                hasSubFilters={has(filter, 'hasSubFilters')}
                isFieldDisabled={isFieldDisabled}
              >
                {isFieldDisabled ? (
                  <DashboardFilterListDisabledItem hasSubFilters={has(filter, 'hasSubFilters')}>
                    {shouldNotTranslate ? filter.label : translate(filter.label)}
                  </DashboardFilterListDisabledItem>
                ) : (
                  <FieldMock
                    name={filter.formName}
                    component={Checkbox}
                    label={shouldNotTranslate ? filter.label : translate(filter.label)}
                    disabled={isFieldDisabled}
                    size="small"
                    onChange={(event: any, isChecked: boolean) =>
                      this.onCheckFilterChange(event, isChecked, has(filter, 'hasSubFilters'))
                    }
                  />
                )}
              </DashboardFilterListItem>
            );
          })}
        </DashboardFilterListItems>
      </DashboardFilterListContainer>
    );
  }
}

export default DashboardFilterList;
