import { httpInsightsReporting } from '../../core/services/http';
import transformLoadFleetInsights from './transformLoadFleetInsights';

export const loadFleetInsights = (
  vendorId: number,
  date: Date | string,
  limit: number,
  fromInsightId?: number,
  vehicleTypeIdsCSV?: number[],
  serviceZoneIdsCSV?: number[],
  supervisorIdsCSV?: number[],
) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/fleetInsights`, {
      params: {
        date,
        numberOfItems: limit,
        startingFromRealTimeInsightsId: fromInsightId,
        vehicleTypeIdsCSV: vehicleTypeIdsCSV ? vehicleTypeIdsCSV.join(',') : undefined,
        serviceZoneIdsCSV: serviceZoneIdsCSV ? serviceZoneIdsCSV.join(',') : undefined,
        supervisorIdsCSV: supervisorIdsCSV ? supervisorIdsCSV.join(',') : undefined,
      },
    })
    .then(response => transformLoadFleetInsights(response.data));

export const deleteFleetInsight = (insightId: number) =>
  httpInsightsReporting.post(`fleetinsights/${insightId}/dismiss`);
