import { camelCase, map } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { ActionButtonTooltip, DatePicker, Dropdown, FileOpenDialogToggle, Input } from '../../../core/components';
import { Button, ButtonSet, Grid, GridColumn, ModalFixedFooter, Text } from '../../../core/components/styled';
import {
  BRINE_ID,
  BUCKET_ID,
  CALCIUM_CHLORIDE_ID,
  GALLON_ID,
  POUND_ID,
  SALT_ID,
  SCOOP_ID,
  TON_ID,
} from 'src/routes/constants';
import { Facility } from 'src/common/interfaces/Facility';
import {
  isAfterCurrentTimeMaterialTicket,
  isDateValidValidator,
  isRequired,
  maxLength20,
  maxValueNumeric100,
  maxValueNumeric1000,
  maxValueNumeric999,
  minValueNumeric1,
} from '../../../utils/services/validator';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TODAY } from '../../../core/constants';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import TimePickerInput from '../../../core/components/TimePicker';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  materialLocations: Facility[];
  materialTypeValues: TechnicalType[];
  onClearfileName: () => void;
  onClose: (formPristine?: boolean) => void;
  onImageUpload: (files: any) => void;
  selectedFileName?: string;
  unitOfMeasureValues: TechnicalType[];
}

export interface MaterialTicketFormValues {
  locationId?: number;
  materialAmount?: number;
  materialTicketDate?: string;
  materialTicketTime?: string;
  materialTypeId?: number;
  ticketNumber?: string;
  unitOfMeasureTypeId?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<MaterialTicketFormValues, PropsWithoutReduxForm>;

function MaterialTicketEditorForm({
  change,
  handleSubmit,
  materialLocations,
  materialTypeValues,
  onClearfileName,
  onClose,
  onImageUpload,
  pristine,
  selectedFileName,
  unitOfMeasureValues,
}: Props) {
  const formValues: MaterialTicketFormValues = useSelector((state: AppState) =>
    getFormValues('materialTicketEditorForm')(state),
  );

  const onTimeChange = (value: Date | string) => {
    change('materialTicketTime', value);
  };

  const onMaterialTypeChange = () => {
    change('unitOfMeasureTypeId', undefined);
  };

  const fileName = selectedFileName && selectedFileName?.split('.')[1] + '.' + selectedFileName?.split('.')[2];

  const unitOfMeasureValuesFiltered =
    formValues.materialTypeId === SALT_ID
      ? unitOfMeasureValues.filter(
          uom => uom.id === BUCKET_ID || uom.id === TON_ID || uom.id === SCOOP_ID || uom.id === POUND_ID,
        )
      : formValues.materialTypeId === BRINE_ID || formValues.materialTypeId === CALCIUM_CHLORIDE_ID
      ? unitOfMeasureValues.filter(uom => uom.id === GALLON_ID)
      : unitOfMeasureValues;

  const quantityValidation =
    formValues.materialTypeId === SALT_ID
      ? formValues.unitOfMeasureTypeId === POUND_ID
        ? [maxValueNumeric999]
        : [maxValueNumeric100]
      : [maxValueNumeric1000];

  const puLocationOptions = map(materialLocations, ({ locationId, name }: Facility) => ({
    label: name,
    value: locationId,
  }));

  const materialTypeValuesOptions = map(materialTypeValues, ({ id, technicalName }: TechnicalType) => ({
    label: translate(`common.materialTickets.materialTypeValues.${camelCase(technicalName)}`),
    value: id,
  }));

  const unitOfMeasureValuesOptions = map(unitOfMeasureValuesFiltered, ({ id, technicalName }: TechnicalType) => ({
    label: translate(`common.materialTickets.unitOfMeasureValues.${camelCase(technicalName)}`),
    value: id,
  }));

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid multiLine>
        <GridColumn size="6/12">
          <Field
            name="ticketNumber"
            component={Input}
            label={translate('common.ticketNumber')}
            validate={[isRequired, maxLength20]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="locationId"
            component={Dropdown}
            label={translate('common.materialTickets.puLocation')}
            options={puLocationOptions}
            validate={[isRequired]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="materialTypeId"
            component={Dropdown}
            onChange={() => onMaterialTypeChange()}
            label={translate('common.material')}
            options={materialTypeValuesOptions}
            validate={[isRequired]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="materialAmount"
            component={Input}
            label={translate('common.materialTickets.quantity')}
            validate={[isRequired, minValueNumeric1, ...quantityValidation]}
            type="number"
            min="1"
            max="100"
            props={{
              autoComplete: 'off',
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="unitOfMeasureTypeId"
            component={Dropdown}
            label={translate('common.materialTickets.uom')}
            options={unitOfMeasureValuesOptions}
            validate={[isRequired]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="materialTicketDate"
            component={DatePicker}
            label={translate('common.materialTickets.date')}
            validate={[isRequired, isDateValidValidator]}
            disabledDays={[{ after: TODAY }]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="materialTicketTime"
            component={TimePickerInput}
            label={translate('common.materialTickets.time')}
            onTimeChange={(value: Date | string) => onTimeChange(value)}
            validate={[isRequired, isAfterCurrentTimeMaterialTicket]}
            props={{
              raisedLabel: true,
            }}
          />
        </GridColumn>
        {!!fileName ? (
          <GridColumn size="6/12">
            <Text weight="medium" size="small" margin="no">
              {fileName}
            </Text>

            <Button id="clear-image-button" text color="primary" padding="no" onClick={() => onClearfileName()}>
              <ActionButtonTooltip icon="cancelClear" tooltip="clear" />{' '}
            </Button>
          </GridColumn>
        ) : (
          <GridColumn size="6/12">
            <Text block size="small">
              {translate('common.images')}
            </Text>

            <FileOpenDialogToggle
              margin="no xSmall no no"
              icon="upload"
              accept=".jpg"
              onFilesSelected={onImageUpload}
            />
          </GridColumn>
        )}
      </Grid>
      <ModalFixedFooter padding="sMedium small" justifyContent="space-around">
        <ButtonSet align="center" margin="no small">
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
          <Button type="button" margin="no small" color="secondary" onClick={() => onClose(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </ModalFixedFooter>
    </form>
  );
}

const mapStateToProps = (state: AppState) => ({
  materialLocations: state.routes.materialTickets.materialLocations,
  materialTypeValues: state.routes.materialTickets.materialTypeValues,
  unitOfMeasureValues: state.routes.materialTickets.unitOfMeasureValues,
});

export default connect(mapStateToProps)(
  reduxForm<MaterialTicketFormValues, PropsWithoutReduxForm>({
    form: 'materialTicketEditorForm',
    onSubmitFail: focusFirstInvalidField,
  })(MaterialTicketEditorForm),
);
