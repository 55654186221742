import React, { PureComponent } from 'react';

import { debounce } from 'lodash-es';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch } from '../../../core/components';

interface ComponentProps {
  onSearchTermChange: (searchTerm: string) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class RouteTemplateLocationsForm extends PureComponent<Props> {
  onSearchTermChange = debounce((_e, searchTerm: string) => {
    this.props.onSearchTermChange(searchTerm);
  }, 300);

  render() {
    return (
      <form>
        <PanelSection padding="xSmall" withBorder>
          <Grid>
            <GridColumn size="12/12">
              <Field
                name="routeTemplateLocationSearchTerm"
                component={PanelSearch}
                onChange={this.onSearchTermChange}
                margin="no"
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

export default reduxForm<any, ComponentProps>({
  form: 'routeTemplateLocations',
  enableReinitialize: true,
})(RouteTemplateLocationsForm);
