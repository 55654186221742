import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../../store';
import { Grid, GridColumn } from '../../../../../core/components/styled';
import { COLOR_ALERT, COLOR_SUCCESS } from '../../../../../core/styles';
import {
  DispatchBoardJobContainer,
  DispatchBoardJobCustomerName,
  DispatchBoardJobDetail,
  DispatchBoardJobExpand,
  DispatchBoardJobExpandIcon,
  DispatchBoardJobLocationAddress,
  DispatchBoardJobLocationName,
} from '../../../styled';
import { DispatchBoardRouteJob } from '../../../../interfaces/DispatchBoardRouteJob';

type Props = {
  expandedItemHeight: number;
  job: DispatchBoardRouteJob;
  isExpanded: boolean;
  toggleExpand: () => void;
};

const DispatchBoardBuilderModalJob: React.FC<Props> = props => {
  const { expandedItemHeight, job, isExpanded, toggleExpand } = props;

  let jobDateColor;
  if (job.isOverdue) jobDateColor = COLOR_ALERT;
  if (job.isInFuture) jobDateColor = COLOR_SUCCESS;

  const reasonCodeTypes = useSelector((state: AppState) => state.common.reasonCodeTypes.reasonCodeTypes);
  const reasonCode = reasonCodeTypes.find(type => type.id === job.reasonCodeTypeId);

  return (
    <DispatchBoardJobContainer height={expandedItemHeight} isExpanded={isExpanded} padding="small no" transparent>
      <Grid multiLine>
        <GridColumn size="9/12">
          <DispatchBoardJobCustomerName>{job.customerName}</DispatchBoardJobCustomerName>
          <DispatchBoardJobLocationName>{job.locationName}</DispatchBoardJobLocationName>
          <DispatchBoardJobLocationAddress>
            {job.locationAddress ? job.locationAddress.formattedAddress : '-'}
          </DispatchBoardJobLocationAddress>
        </GridColumn>

        <GridColumn size="2/12" align="right">
          <DispatchBoardJobDetail>{job.jobTypeName}</DispatchBoardJobDetail>
          <DispatchBoardJobDetail color={jobDateColor}>{job.formattedDate}</DispatchBoardJobDetail>
        </GridColumn>

        <GridColumn size="1/12" align="right">
          <DispatchBoardJobExpand onClick={toggleExpand}>
            <DispatchBoardJobExpandIcon />
          </DispatchBoardJobExpand>
        </GridColumn>

        {isExpanded && (
          <Fragment>
            <GridColumn size="9/12" padding="small xSmall">
              <DispatchBoardJobDetail>{job.containerTypeName}</DispatchBoardJobDetail>
              <DispatchBoardJobDetail>
                {job.numberOfContainers} x {job.containerSizeName}
              </DispatchBoardJobDetail>
              <DispatchBoardJobDetail>{job.wasteMaterialTypeName}</DispatchBoardJobDetail>
            </GridColumn>
            <GridColumn size="3/12" padding="small xSmall" align="right">
              <DispatchBoardJobDetail>{job.workOrderNumber || '-'}</DispatchBoardJobDetail>
              {!!reasonCode && <DispatchBoardJobDetail>{reasonCode.name}</DispatchBoardJobDetail>}
            </GridColumn>
          </Fragment>
        )}
      </Grid>
    </DispatchBoardJobContainer>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.isExpanded === nextProps.isExpanded && prevProps.toggleExpand === nextProps.toggleExpand;

export default React.memo(DispatchBoardBuilderModalJob, areEqual);
