import { httpInsightsReporting } from '../../core/services/http';
import transformLoadRouteMapDetails from './transformLoadRouteMapDetails';

const loadRouteMapDetails = (vendorId: number, routeId: number | string, isYRoute?: boolean) => {
  const params = isYRoute ? { routeDetailsId: routeId } : { routeId };

  return httpInsightsReporting
    .get(`vendors/${vendorId}/routeMapDetails`, { params })
    .then(response => transformLoadRouteMapDetails(response.data));
};

export default loadRouteMapDetails;
