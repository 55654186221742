import { map } from 'lodash-es';
import moment from 'moment';
import { HAULER_ACCEPTED, PROCESSED, PENDING_HAULER_ACCEPT, HAULER_REPORTED_ISSUE } from '../constants/serviceType';

export const transformPaymentMethods = (p: any) =>
  map(p.paymentMethods, p => ({
    label: p,
    value: p,
  }));

export const transformVendorPayment = (vp: any) =>
  map(vp.vendorPayment, v => ({
    ...v,
    label: `${v.remitName !== undefined ? v.remitName : ''} ${v.remitName !== undefined ? ': ' : ''} ${
      v.remitAddr1 !== undefined ? v.remitAddr1 : ''
    } ${v.remitCity !== undefined ? v.remitCity : ''} ${v.remitState !== undefined ? v.remitState : ''} ${
      v.remitZip !== undefined ? v.remitZip : ''
    }`,
    value: v.paymentId,
  }));

export const transformPaymentInfo = (o: any) => ({
  ...o.paymentInfo,
  paymentMethods: o.paymentInfo ? transformPaymentMethods(o.paymentInfo) : [],
  vendorPayment: o.paymentInfo ? transformVendorPayment(o.paymentInfo) : [],
});

export const transformPricingInfo = (o: any) => ({
  ...o.pricingInfo,
  reportDate: moment(o.pricingInfo.reportDate).format('MM/DD/YYYY'),
});

export const getIconStatus = (s: string) => {
  switch (s) {
    case PENDING_HAULER_ACCEPT:
      return 'pendingStatus';
    case HAULER_REPORTED_ISSUE:
      return 'issueStatus';
    case HAULER_ACCEPTED:
      return 'accepted';
    case PROCESSED:
      return 'processed';
    default:
      return 'pendingStatus';
  }
};

export const transformWorkOrdersInfo = (r: any) =>
  map(r.workOrdersInfo, s => ({
    ...s,
    iconStatus: getIconStatus(s.status),
  }));

export const transformRecurringServices = (recurringServices: any) => ({
  ...recurringServices,
  workOrdersInfo: recurringServices.workOrdersInfo ? transformWorkOrdersInfo(recurringServices) : [],
  haulerName: recurringServices.haulerName || '',
  paymentInfo: recurringServices.paymentInfo ? transformPaymentInfo(recurringServices) : [],
  pricingInfo: recurringServices.pricingInfo ? transformPricingInfo(recurringServices) : {},
});

export const transformRecurringServicesIssueType = (issueType: any) =>
  map(issueType, e => ({
    ...e,
    label: e.description,
    value: e.code,
  }));
