import { useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { Button, ButtonSet, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { ESRI_CREDENTIALS_LAYER } from 'src/vendors/constants/esriConfig';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import EsriConfigValidationModalResolver from '../modals/EsriConfigValidationModalResolver';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { Input, TypedField } from '../../../core/components';
import { isRequired, maxLength50 } from '../../../utils/services/validator';
import translate from 'src/core/services/translate';

function EsriCredentialsForm({ form, handleSubmit }: InjectedFormProps) {
  const vendorId = useSelector(currentVendorId);
  const [isEsriValidationModalOpen, toggleIsEsriValidationModalOpen] = useState(false);
  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
      <PanelSection padding="no">
        <Grid multiLine>
          <GridColumn size="12/12" margin="small no">
            <TypedField
              name="baseUrl"
              component={Input}
              validate={[isRequired]}
              props={{
                label: translate('vendors.esriSync.baseUrl'),
                margin: 'no',
              }}
            />
          </GridColumn>

          <GridColumn size="6/12">
            <TypedField
              name="userName"
              component={Input}
              validate={[maxLength50]}
              props={{
                label: translate('vendors.esriSync.userName'),
                margin: 'no',
                autoComplete: "off"
              }}
            />
          </GridColumn>
          
          <GridColumn size="6/12">
            <TypedField
              name="password"
              component={Input}
              validate={[maxLength50]}
              props={{
                label: translate('vendors.esriSync.password'),
                margin: 'no',
                type: 'password',
                autoComplete: "new-password"
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>

      <Grid centered>
        <ButtonSet margin="medium">
          <Button type="button" color="primary" line onClick={() => toggleIsEsriValidationModalOpen(true)}>
            {translate('vendors.esriSync.validate')}
          </Button>
          <Button type="submit" color="primary" margin="no small">
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </Grid>
      {isEsriValidationModalOpen && (
        <EsriConfigValidationModalResolver
          vendorId={vendorId}
          configLayer={ESRI_CREDENTIALS_LAYER}
          closeModal={() => toggleIsEsriValidationModalOpen(false)}
        ></EsriConfigValidationModalResolver>
      )}
    </form>
  );
}
export default reduxForm({ form: 'esriCredentialsForm', onSubmitFail: focusFirstInvalidField })(EsriCredentialsForm);
