import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, Modal } from 'src/core/components';
import { Button, TableCell, TableRow, Text } from 'src/core/components/styled';
import { DRIVING_INCIDENT_TYPES } from 'src/common/constants/drivingIncidentTypes';
import { DrivingIncident } from 'src/routes/interfaces/DrivingIncidents';
import { drivingIncidentsTableCellWidths } from './DrivingIncidentsSection';
import { duration } from 'src/utils/services/formatter';
import OverviewMapGL from 'src/haulerProfile/components/OverviewMapGL';
import translate from 'src/core/services/translate';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import { MAP_CITY_ZOOM_IN_BIGGER } from 'src/core/constants/mapOptions';

export default function DrivingIncidentsTableRow({
  durationTime,
  incidentTypeId,
  latitude,
  longitude,
  timestamp,
}: DrivingIncident) {
  const [isDrivingIncidentLocationModalOpen, setDrivingIncidentLocationModalOpen] = useState(false);

  const timestampMoment = moment(timestamp);

  return (
    <>
      <TableRow>
        <TableCell width={drivingIncidentsTableCellWidths[0]} vertical>
          <Text weight="medium">{timestampMoment.format('MM/DD/YYYY')}</Text>
          <Text>{timestampMoment.format(timeFormatWithoutSeconds)}</Text>
        </TableCell>
        <TableCell width={drivingIncidentsTableCellWidths[1]}>
          <Text>{DRIVING_INCIDENT_TYPES[incidentTypeId].name}</Text>
        </TableCell>
        <TableCell width={drivingIncidentsTableCellWidths[2]}>
          <Text>{durationTime ? duration(durationTime, 'seconds', 'hh:mm:ss') : '-'}</Text>
        </TableCell>
        <TableCell width={drivingIncidentsTableCellWidths[3]}>
          <Button
            type="button"
            text
            margin="no small"
            padding="no"
            onClick={() => setDrivingIncidentLocationModalOpen(true)}
          >
            <ActionButtonTooltip
              icon="map"
              size="medium"
              tooltip={translate('routes.seeLocationOnMap')}
              tooltipAsString
            />
          </Button>
        </TableCell>
      </TableRow>

      {isDrivingIncidentLocationModalOpen && (
        <Modal verticalSize="medium" onClose={() => setDrivingIncidentLocationModalOpen(false)}>
          {!!longitude && !!latitude && (
            <OverviewMapGL isMarker address={[[[longitude, latitude]]]} mapZoom={MAP_CITY_ZOOM_IN_BIGGER} />
          )}
        </Modal>
      )}
    </>
  );
}
