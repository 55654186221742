import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from 'src/store';
import { createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadContainersStatistics, loadLowInventoryParts, saveLowInventoryPart } from 'src/fleet/ducks';
import { Modal } from 'src/core/components';
import AddRemoveInventoryForm from './AddRemoveInventoryForm';
import translate from 'src/core/services/translate';

interface Props extends RouteComponentProps {
  closeModal: () => void;
  containerTypeId: number;
  currentQuantity: number;
  id: number;
  isAddInventory: boolean;
  isAddNewInventory: boolean;
  isSaving: boolean;
  loadContainersStatistics: DuckFunction<typeof loadContainersStatistics>;
  loadLowInventoryParts: DuckFunction<typeof loadLowInventoryParts>;
  saveLowInventoryPart: DuckFunction<typeof saveLowInventoryPart>;
  vendorId: number;
}

const AddRemoveInventoryModal: React.FC<Props> = ({
  closeModal,
  containerTypeId,
  currentQuantity,
  id,
  isAddInventory,
  isAddNewInventory,
  isSaving,
  loadContainersStatistics,
  loadLowInventoryParts,
  saveLowInventoryPart,
  vendorId,
  location: { search },
}) => {
  const saveNewInventory = (formData: any) => {
    const operationTypeId = isAddInventory || (!id && !formData.isNew) ? 1 : 2;

    const payload = {
      addedOn: formData.date,
      quantity: formData.quantity,
      name: formData.partName,
      containerTypeId,
      operationTypeId,
    };

    (id || !formData.isNew) && delete payload.name;

    const currentId = typeof formData.partName === 'number' ? formData.partName : id;

    saveLowInventoryPart(vendorId, payload, currentId).then(() => {
      closeModal();
      loadLowInventoryParts(vendorId, containerTypeId);

      const { searchTerm, locationId, equipmentTypeId, equipmentSizeId, equipmentConditionId } = getQueryParams(search);
      loadContainersStatistics(
        vendorId,
        containerTypeId,
        equipmentTypeId,
        equipmentTypeId ? equipmentSizeId : undefined,
        equipmentConditionId,
        searchTerm,
        locationId,
      );

      createSuccessNotification(
        translate(currentId ? 'containers.alertMessages.quantityChanged' : 'containers.alertMessages.inventoryAdded'),
      );
    });
  };

  return (
    <Modal
      title={
        isAddNewInventory
          ? translate('containers.addNewInventory')
          : isAddInventory
          ? translate('containers.addInventory')
          : translate('containers.removeInventory')
      }
      onClose={closeModal}
      size="xSmall"
      padding="medium"
      isLoading={isSaving}
    >
      <AddRemoveInventoryForm
        currentQuantity={currentQuantity}
        id={id}
        isAddInventory={isAddInventory}
        isAddNewInventory={isAddNewInventory}
        onCancel={closeModal}
        onSubmit={saveNewInventory}
      />
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isSaving: state.fleet.lowInventoryParts.isSaving,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadContainersStatistics, loadLowInventoryParts, saveLowInventoryPart };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRemoveInventoryModal));
