import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { LocationAlert } from '../../interfaces/LocationAlert';
import { LocationAlertEditorForm } from '../forms';
import { locationAlertsModalId } from './LocationAlertsModal';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalSection, ModalTitle } from '../../../core/components/styled';
import { saveLocationAlert } from '../../ducks';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  vendorId: number;
  locationAlert?: LocationAlert;
  saveLocationAlert: DuckFunction<typeof saveLocationAlert>;
}

class LocationAlertEditorModal extends PureComponent<Props> {
  saveLocationAlert = (locationAlert: LocationAlert) => {
    const { vendorId, saveLocationAlert, closeModal } = this.props;

    scrollToTopOfModal(locationAlertsModalId);
    closeModal(true);

    saveLocationAlert(vendorId, locationAlert)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.locationAlertSaved'));
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.saveLocationAlertError')));
  };

  render() {
    const { locationAlert, closeModal } = this.props;

    return (
      <Modal padding="no" size="small">
        <ModalSection padding="medium medium small">
          <ModalTitle>
            {locationAlert ? translate('vendors.editLocationAlert') : translate('vendors.createLocationAlert')}
          </ModalTitle>
        </ModalSection>
        <LocationAlertEditorForm
          initialValues={locationAlert || { isActive: true }}
          onSubmit={this.saveLocationAlert}
          onCancel={closeModal}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = { saveLocationAlert };

export default connect(undefined, mapDispatchToProps)(LocationAlertEditorModal);
