import { Button, IconButtonIcon, ModalTitle, Text } from '../../../core/components/styled';
import { changeMobilePageDetailOverlayTitleContent } from 'src/core/ducks/mobilePage';
import { getDateByStatus } from '../utils/rubiconDispatchesPageHooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { DispatchStatus, WorkOrderRow } from './RubiconDispatchesTableRow';
import Icon from 'src/core/components/Icon';
import styled from 'styled-components';
import translate from 'src/core/services/translate';
import SERVICE_TYPE_ICONS from 'src/customers/constants/serviceTypeIcons';
import humps from 'humps';
import { ServiceIconImage } from '../styled/RubiconDispatches';
import { date } from 'src/utils/services/formatter';
import { MobileDataFields } from 'src/core/components/MobileDataFields';

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RubiconDispatchesMobileListRowDetailsView: React.FC<
  WorkOrderRow & {
    onOpenAcceptServiceModal: () => void;
    onOpenDeclineOpenDispatchesModal: () => void;
    onOpenRequestDateChangeModal: () => void;
    onOpenCompleteModal: () => void;
    onOpenServiceUnableToDeliverModal: () => void;
    onOpenNotesModal: () => void;
    onViewUploadedDocuments: () => void;
  }
> = ({
  customerAddress,
  customerName,
  dates,
  equipment,
  hasVendorNotes,
  pickupType,
  siteCode,
  siteName,
  statusName,
  weightTicket,
  workorderNumber,
  workOrderType,
  actionRequired,
  daysOverDue,
  options: { canAccept, canDecline, canRequestDateChange, canComplete, canChooseUnable, canViewUploadedDocuments },
  onOpenAcceptServiceModal,
  onOpenCompleteModal,
  onOpenDeclineOpenDispatchesModal,
  onOpenRequestDateChangeModal,
  onOpenServiceUnableToDeliverModal,
  onViewUploadedDocuments,
}) => {
  const fields = [
    {
      label: translate('routes.workOrderNumber'),
      text: (
        <div>
          {workorderNumber}{' '}
          {workOrderType === DispatchStatus.SCHEDULED && (
            <>
              <IconButtonIcon icon="calendarTime" color="info" size="large" margin="no no no small" />{' '}
              <Text weight="normal" margin="no" singleLine size="normal" color="info">
                {translate('autoDispatch.autoScheduled')}
              </Text>
            </>
          )}
        </div>
      ),
    },
    { label: translate('routes.pickupType'), text: pickupType },
    {
      label: translate('common.equipmentType'),
      text: equipment.subType
        ? translate(`haulerProfile.equipments.equipmentSubTypes.${equipment.subType}`)
        : equipment.type,
    },
    { label: translate('common.equipmentSize'), text: `${parseInt(equipment.size)} ${equipment.units}` },
    { label: translate('customers.wasteMaterial'), text: equipment.wasteMaterial },
    { label: translate('customers.frequency'), text: equipment.frequency },
    { label: translate('opportunity.quantity'), text: equipment.quantity || 0 },
    { label: translate('payment.serviceDescription'), text: equipment.serviceDescription },
    {
      label: translate('opportunity.scheduledDate'),
      text: (
        <div>
          {getDateByStatus(statusName, dates)}{' '}
          {actionRequired && !!daysOverDue && (
            <Text weight="normal" margin="no small" singleLine size="normal" color="alert">
              {translate('common.xDaysOverdue', { days: daysOverDue })}
            </Text>
          )}
        </div>
      ),
    },
    { label: translate('autoDispatch.requestedDate'), text: dates.requestedDate && date(dates.requestedDate) },
    { label: translate('autoDispatch.lastPickupDate'), text: dates.lastServiceDate && date(dates.lastServiceDate) },
    { label: translate('autoDispatch.completedDate'), text: dates.completedDate && date(dates.completedDate) },
    { label: translate('customers.customerName'), text: customerName },
    { label: translate('autoDispatch.siteName'), text: `${siteName}${siteCode && `, ${siteCode}`}` },
    { label: translate('common.address'), text: customerAddress },
    { label: translate('common.status'), text: statusName },
    {
      label: translate('common.weightTicket'),
      text: weightTicket && `${weightTicket} ${translate('common.weightMeasurementUnits.tons')}`,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    changeMobilePageDetailOverlayTitleContent(
      <TitleSectionContainer>
        <TitleContainer>
          <ServiceIconImage
            width="50px"
            src={SERVICE_TYPE_ICONS[humps.camelize(equipment.iconType.split('.').join(''))]}
          />
          <div>
            <ModalTitle textAlign="left" margin="no xxSmall" fontSize="22px">
              {workorderNumber}
            </ModalTitle>
            <ModalTitle fontSize="18px" margin="no xxSmall">
              {pickupType}
            </ModalTitle>
          </div>
        </TitleContainer>

        <Actions>
          {canAccept && (
            <Button padding="no" margin="no xSmall" text onClick={onOpenAcceptServiceModal}>
              <Icon customViewBox="0 0 20 20" width="24px" icon="checkCircle" />
            </Button>
          )}
          {canDecline && (
            <Button padding="no" margin="no xSmall" text onClick={onOpenDeclineOpenDispatchesModal}>
              <Icon width="24px" icon="closedIssue" />
            </Button>
          )}
          {canRequestDateChange && (
            <Button color="primary" padding="no" margin="no xSmall" text onClick={onOpenRequestDateChangeModal}>
              <Icon customViewBox="0 0 18 18" width="24px" icon="scheduler" />
            </Button>
          )}
          {canComplete && (
            <Button padding="no" margin="no xSmall" text onClick={onOpenCompleteModal}>
              <Icon customViewBox="0 0 20 20" width="24px" icon="checkCircle" />
            </Button>
          )}
          {canChooseUnable && (
            <Button padding="no" margin="no xSmall" text onClick={onOpenServiceUnableToDeliverModal}>
              <Icon width="24px" icon="closedIssue" />
            </Button>
          )}
          {canViewUploadedDocuments && (
            <Button color="primary" padding="no" margin="no xSmall" text onClick={onViewUploadedDocuments}>
              <Icon customViewBox="0 0 30 23" width="24px" icon="redeye" />
            </Button>
          )}
        </Actions>
      </TitleSectionContainer>,
    )(dispatch);
  }, [
    canAccept,
    canChooseUnable,
    canComplete,
    canDecline,
    canRequestDateChange,
    canViewUploadedDocuments,
    dispatch,
    equipment,
    hasVendorNotes,
    onOpenAcceptServiceModal,
    onOpenCompleteModal,
    onOpenDeclineOpenDispatchesModal,
    onOpenRequestDateChangeModal,
    onOpenServiceUnableToDeliverModal,
    onViewUploadedDocuments,
    pickupType,
    workorderNumber,
  ]);

  return (
    <div>
      <MobileDataFields fields={fields} />
    </div>
  );
};
