import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { SearchedLocation, ServiceHistory } from '../interfaces/ServiceHistory';
import doLoadSearchedLocation from '../services/loadSearchedLocation';
import {
  exportServiceHistory as doExportServiceHistory,
  loadServiceHistory as doLoadServiceHistory,
} from '../services/serviceHistory';

// Actions
const START_LOAD = 'customers/serviceHistory/START_LOAD';
const COMPLETE_LOAD = 'customers/serviceHistory/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/serviceHistory/FAIL_LOAD';
const START_EXPORT = 'customers/serviceHistory/START_EXPORT';
const COMPLETE_EXPORT = 'customers/serviceHistory/COMPLETE_EXPORT';
const FAIL_EXPORT = 'customers/serviceHistory/FAIL_EXPORT';
const START_LOAD_LOCATION = 'customers/serviceHistory/START_LOAD_LOCATION';
const COMPLETE_LOAD_LOCATION = 'customers/serviceHistory/COMPLETE_LOAD_LOCATION';
const FAIL_LOAD_LOCATION = 'customers/serviceHistory/FAIL_LOAD_LOCATION';
const RESET = 'customers/serviceHistory/RESET';
const RESET_SEARCHED_LOCATION = 'customers/serviceHistory/RESET_SEARCHED_LOCATION';
const SET_CURRENT_SERVICE_HISTORY_PAGE_URL = 'customers/serviceHistory/SET_CURRENT_SERVICE_HISTORY_PAGE_URL';

interface State {
  isLoading: boolean;
  isExporting: boolean;
  serviceHistory: ServiceHistory[];
  searchedLocation?: SearchedLocation;
  currentServiceHistoryPageUrl?: string;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isExporting: false,
  serviceHistory: [],
  searchedLocation: undefined,
  currentServiceHistoryPageUrl: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceHistory: action.serviceHistory,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceHistory: [],
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case START_LOAD_LOCATION:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_LOCATION:
      return update(state, {
        $merge: {
          isLoading: false,
          searchedLocation: action.searchedLocation,
        },
      });

    case FAIL_LOAD_LOCATION:
      return update(state, {
        $merge: {
          isLoading: false,
          searchedLocation: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          ...initialState,
          currentServiceHistoryPageUrl: state.currentServiceHistoryPageUrl,
        },
      });

    case RESET_SEARCHED_LOCATION:
      return update(state, {
        $merge: {
          searchedLocation: undefined,
        },
      });

    case SET_CURRENT_SERVICE_HISTORY_PAGE_URL:
      return update(state, {
        $merge: {
          currentServiceHistoryPageUrl: action.currentServiceHistoryPageUrl,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceHistory: ServiceHistory[]) => ({
  type: COMPLETE_LOAD,
  serviceHistory,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const startLoadLocation = () => ({
  type: START_LOAD_LOCATION,
});

const completeLoadLocation = (searchedLocation: SearchedLocation) => ({
  type: COMPLETE_LOAD_LOCATION,
  searchedLocation,
});

const failLoadLocation = () => ({
  type: FAIL_LOAD_LOCATION,
});

export const loadServiceHistory =
  (
    customerId: number,
    locationId: number,
    serviceContractIds: string | undefined,
    pickupStatusTypeIds: string | undefined,
    jobPriorityTypeIds: string | undefined,
    startDate: Date | string,
    endDate: Date | string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadServiceHistoryPromise = doLoadServiceHistory(
      customerId,
      locationId,
      serviceContractIds,
      pickupStatusTypeIds,
      jobPriorityTypeIds,
      startDate,
      endDate,
    );
    loadServiceHistoryPromise
      .then(serviceHistory => dispatch(completeLoad(serviceHistory)))
      .catch(() => dispatch(failLoad()));
    return loadServiceHistoryPromise;
  };

export const loadSearchedLocation = (locationId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadLocation());
  const loadSearchedLocationPromise = doLoadSearchedLocation(locationId)
    .then(searchedLocation => dispatch(completeLoadLocation(searchedLocation)))
    .catch(() => dispatch(failLoadLocation()));
  return loadSearchedLocationPromise;
};

export const exportServiceHistory =
  (
    customerId: number,
    customerName: string | undefined,
    locationId: number,
    serviceContractIds: string | undefined,
    pickupStatusTypeIds: string | undefined,
    jobPriorityTypeIds: string | undefined,
    startDate: Date | string,
    endDate: Date | string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportServiceHistoryPromise = doExportServiceHistory(
      customerId,
      customerName,
      locationId,
      serviceContractIds,
      pickupStatusTypeIds,
      jobPriorityTypeIds,
      startDate,
      endDate,
    );
    exportServiceHistoryPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportServiceHistoryPromise;
  };

export const resetServiceHistory = () => ({
  type: RESET,
});

export const resetSearchedLocation = () => ({
  type: RESET_SEARCHED_LOCATION,
});

export const setCurrentServiceHistoryPageUrl = (currentServiceHistoryPageUrl: string) => ({
  type: SET_CURRENT_SERVICE_HISTORY_PAGE_URL,
  currentServiceHistoryPageUrl,
});
