import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { DataRetention } from '../interfaces/DataRetention';
import {
  loadDataRetention as doLoadDataRetention,
  loadVendorDataRetention as doLoadVendorDataRetention,
  saveDataRetention as doSaveDataRetention,
} from '../services/dataRetention';

// Actions
const START_LOAD = 'vendors/dataRetention/START_LOAD';
const COMPLETE_LOAD = 'vendors/dataRetention/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/dataRetention/FAIL_LOAD';
const START_SAVE = 'vendors/dataRetention/START_SAVE';
const COMPLETE_SAVE = 'vendors/dataRetention/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/dataRetention/FAIL_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  dataRetention: [] as DataRetention[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          dataRetention: action.dataRetention,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (dataRetention: DataRetention[]) => ({
  type: COMPLETE_LOAD,
  dataRetention,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadDataRetention = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDataRetentionPromise = doLoadDataRetention();
  loadDataRetentionPromise
    .then((dataRetention: DataRetention[]) => {
      dispatch(completeLoad(dataRetention));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadDataRetentionPromise;
};

export const loadVendorDataRetention = (vendorId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVendorDataRetentionPromise = doLoadVendorDataRetention(vendorId);
  loadVendorDataRetentionPromise
    .then((dataRetention: DataRetention[]) => {
      dispatch(completeLoad(dataRetention));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadVendorDataRetentionPromise;
};

export const saveDataRetention = (dataRetention?: DataRetention) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveDataRetentionPromise = doSaveDataRetention(dataRetention);
  saveDataRetentionPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveDataRetentionPromise;
};
