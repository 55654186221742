import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { filter, find, flatten, get } from 'lodash-es';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import { MapGLPopupWrapper, MapGLPopupLoading } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteMapVehicleTrackingsGLPopup from '../vehicleTrackings/RouteMapVehicleTrackingsGLPopup';

export default function RouteMapApplicationStatusGLPopupResolver() {
  const dispatch = useDispatch();

  const { unitOfMeasure } = useSelector(state => state.routes.routeMapVehicleData);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);

  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const isOnDemandVideoDownloadEnabled = useSelector(isOnDemandVideoDownloadFeatureEnabled);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.feature !== RouteMapFeature.applicationModeChanges ||
      selectedFeature.vehicleTrackingApplicationStatusIndex === undefined
    ) {
      return undefined;
    }

    const { id: vehicleId, vehicleTrackingApplicationStatusIndex: statusIndex } = selectedFeature;

    let status = null;
    let trackingsForVehicle = null;

    trackingsForVehicle = find(vehicleTrackingsForRoute?.vehicles, { id: vehicleId });
    const flatTrackings = flatten(trackingsForVehicle?.coords || []);
    const vehicleApplicationModeChanges = filter(flatTrackings, (coord, index) => {
      if (index === 0) return false;
      return coord.am !== flatTrackings[index - 1].am;
    });
    status = get(vehicleApplicationModeChanges, statusIndex);

    if (!status || !trackingsForVehicle) {
      return undefined;
    }

    return {
      unitOfMeasure,
      vehicle: trackingsForVehicle.name,
      vehicleId: trackingsForVehicle.id,
      status,
      shouldDisplayDownloadVideo: vehicleTrackingsForRoute?.vehicles.find(vehicle => vehicle.id === vehicleId)?.hdv,
    };
  }, [selectedFeature, unitOfMeasure, vehicleTrackingsForRoute?.vehicles]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.status.lat}
      longitude={selectedTracking.status.lng}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingApplicationStatusIndex}`}
        successComponent={RouteMapVehicleTrackingsGLPopup}
        successProps={{
          applicationModeStatusId: selectedTracking.status.am,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled,
          speed: selectedTracking.status.sp,
          timestamp: selectedTracking.status.ts,
          unitOfMeasure: selectedTracking.unitOfMeasure,
          vehicleId: selectedTracking.vehicleId,
          vehicleName: selectedTracking.vehicle,
        }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 240 }}
      />
    </MapGLPopupWrapper>
  );
}
