import { arrayMove } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { difference, find, findIndex, get, map, size } from 'lodash-es';
import { PureComponent } from 'react';
import update from 'immutability-helper';

import { AppState } from '../../../store';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { Button, Message, ModalFixedFooter, PanelSection } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { ExceptionConfigurationModalTableRow } from '.';
import {
  loadDefaultExceptionConfigurations,
  loadExceptionConfigurations,
  resetExceptionConfigurations,
  saveExceptionConfigurations,
} from '../../ducks';
import { Modal, SortableTable } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { TABLE_ROW_HEIGHT_LARGEST } from '../../../core/constants';
import { VehicleTypeForm } from '../forms';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

export const exceptionConfigurationsModalId = 'exception-configurations-modal-id';

interface Props extends ModalProps {
  exceptionConfigurations?: any[];
  isLoading: boolean;
  isSaving: boolean;
  loadDefaultExceptionConfigurations: DuckFunction<typeof loadDefaultExceptionConfigurations>;
  loadExceptionConfigurations: DuckFunction<typeof loadExceptionConfigurations>;
  resetExceptionConfigurations: DuckAction<typeof resetExceptionConfigurations>;
  saveExceptionConfigurations: DuckFunction<typeof saveExceptionConfigurations>;
  vehicles?: any[];
  vendorId?: number;
  billingModuleEnabled?: boolean;
  useExceptionCharges?: boolean;
}

interface State {
  exceptionConfigurations: any[];
  initialValuesLoaded: boolean;
  noConfigurationsMessageVisible: boolean;
  vehicleTypeId?: number;
}

class ExceptionConfigurationModal extends PureComponent<Props, State> {
  readonly state: State = {
    exceptionConfigurations: [],
    initialValuesLoaded: false,
    noConfigurationsMessageVisible: false,
  };

  componentDidUpdate() {
    const { exceptionConfigurations } = this.props;
    const { initialValuesLoaded } = this.state;

    if (!!exceptionConfigurations && exceptionConfigurations?.length && !initialValuesLoaded) {
      this.setState({ initialValuesLoaded: true, exceptionConfigurations });
    }
  }

  componentWillUnmount() {
    this.props.resetExceptionConfigurations();
  }

  onChangeVehicleTypeId = (vehicleTypeId: number) => {
    this.setState({
      initialValuesLoaded: false,
      vehicleTypeId,
      noConfigurationsMessageVisible: true,
    });
  };

  onChangePicturePermissions = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          canTakePicture: { $set: value },
        },
      }),
    });
  };

  onChangeCanAddDetailPermissions = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          canAddDetail: { $set: value },
        },
      }),
    });
  };

  onChangePickupExceptionSubType = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          defaultPickupExceptionSubTypeId: { $set: value },
        },
      }),
    });
  };

  onSortOrderChange = (oldIndex: number, newIndex: number) => {
    const reorderedExceptionConfigurations = arrayMove(this.state.exceptionConfigurations, oldIndex, newIndex);
    const exceptionConfigurations = this.resetOrderNumbers(reorderedExceptionConfigurations);

    this.setState({ exceptionConfigurations });
  };

  resetOrderNumbers = (exceptionConfigurations: any[]) =>
    map(exceptionConfigurations, (exceptionConfiguration, index) => ({
      ...exceptionConfiguration,
      orderNo: index + 1,
    }));

  toggleExceptionFlag = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          isEnabled: { $set: value },
        },
      }),
    });
  };

  toggleCreateAdditionalCharge = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          createAdditionalCharge: { $set: value },
        },
      }),
    });
  };

  changeChargeAmount = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          chargeAmount: { $set: value },
        },
      }),
    });
  };

  changeRateCode = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          rateCodeId: { $set: value },
        },
      }),
    });
  };

  changeAccountingCode = (id: number, value: any) => {
    const { exceptionConfigurations } = this.state;
    const exceptionConfigurationIndex = findIndex(exceptionConfigurations, { id });

    this.setState({
      exceptionConfigurations: update(exceptionConfigurations, {
        [exceptionConfigurationIndex]: {
          rateAccountingCodeId: { $set: value },
        },
      }),
    });
  };

  saveExceptionConfigurations = () => {
    const { vendorId, saveExceptionConfigurations, closeModal, vehicles, billingModuleEnabled } = this.props;
    const { exceptionConfigurations, vehicleTypeId } = this.state;
    const vehicleType = get(find(vehicles, { id: vehicleTypeId }), 'technicalName');
    const PICKUP_EXCEPTIONS_LIMIT = 50;

    if (!vendorId || !vehicleTypeId) {
      return;
    }

    if (exceptionConfigurations.filter(exception => exception.isEnabled).length > PICKUP_EXCEPTIONS_LIMIT) {
      createErrorNotification(translate(`vendors.alertMessages.pickupExceptionsMax${PICKUP_EXCEPTIONS_LIMIT}`));
    } else {
      const exceptionConfigurationWithError = exceptionConfigurations.find(
        exceptionConfiguration =>
          !!exceptionConfiguration.createAdditionalCharge &&
          (!exceptionConfiguration.rateCodeId || !exceptionConfiguration.rateAccountingCodeId),
      );

      const exceptionConfigurationWithErrorPosition = exceptionConfigurations.findIndex(
        exceptionConfiguration => exceptionConfiguration.id === exceptionConfigurationWithError?.id,
      );

      if (exceptionConfigurationWithErrorPosition >= 0 && billingModuleEnabled) {
        const exceptionConfigurationsModal = document.getElementById(exceptionConfigurationsModalId);
        exceptionConfigurationsModal?.scrollTo(
          0,
          exceptionConfigurationWithErrorPosition * TABLE_ROW_HEIGHT_LARGEST + 220,
        );
      } else {
        scrollToTopOfModal(exceptionConfigurationsModalId);

        saveExceptionConfigurations(vendorId, vehicleTypeId, vehicleType, exceptionConfigurations)
          .then(() => {
            createSuccessNotification(translate('vendors.alertMessages.exceptionConfigurationSaved'));
            closeModal(true);
          })
          .catch(({ code }) => {
            createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
          });
      }
    }
  };

  loadDefaultExceptionConfigurations = () => {
    const { vehicles, loadDefaultExceptionConfigurations } = this.props;
    const { vehicleTypeId } = this.state;
    const vehicleType = get(find(vehicles, { id: vehicleTypeId }), 'technicalName');

    if (!vehicleTypeId) {
      return;
    }

    scrollToTopOfModal(exceptionConfigurationsModalId);

    loadDefaultExceptionConfigurations(vehicleTypeId, vehicleType).then(exceptionConfigurations =>
      this.setState({ exceptionConfigurations }),
    );
  };

  closeModal = () => {
    const { exceptionConfigurations, closeModal } = this.props;
    closeModal(!size(difference(exceptionConfigurations, this.state.exceptionConfigurations)));
  };

  render() {
    const {
      featureCode,
      isLoading,
      isSaving,
      loadExceptionConfigurations,
      vehicles,
      billingModuleEnabled,
      useExceptionCharges,
    } = this.props;
    const { exceptionConfigurations, noConfigurationsMessageVisible, vehicleTypeId } = this.state;

    const tableCellWidths = {
      dragHandle: '3%',
      featureName: billingModuleEnabled && useExceptionCharges ? '13.8%' : '23%',
      enabled: billingModuleEnabled && useExceptionCharges ? '7.5%' : '9.5%',
      versionNumber: billingModuleEnabled && useExceptionCharges ? '11.8%' : '21.5%',
      canAddDetail: billingModuleEnabled && useExceptionCharges ? '11.8%' : '21.5%',
      exceptionSubTypes: billingModuleEnabled && useExceptionCharges ? '14.8%' : '21.5%',
      createAdditionalCharge: billingModuleEnabled && useExceptionCharges ? '9.8%' : '0%',
      rateAmount: billingModuleEnabled && useExceptionCharges ? '19.7%' : '0%',
      chargeAmount: billingModuleEnabled && useExceptionCharges ? '7.8%' : '0%',
    };

    const exceptionConfigurationTableCells = [
      { name: 'dragHandle', width: tableCellWidths.dragHandle, noPaddingRight: true },
      {
        name: 'featureName',
        label: translate('vendors.configurations'),
        width: tableCellWidths.featureName,
        align: 'left',
        noPaddingRight: true,
      },
      { name: 'enabled', label: translate('common.active'), width: tableCellWidths.enabled, align: 'left' },
      {
        name: 'versionNumber',
        label: translate('common.picture'),
        width: tableCellWidths.versionNumber,
        align: 'left',
        noPaddingRight: true,
      },
      {
        name: 'canAddDetail',
        label: translate('common.canAddDetail'),
        width: tableCellWidths.canAddDetail,
        align: 'left',
        noPaddingRight: true,
      },
      {
        name: 'exceptionSubTypes',
        label: translate('common.defaultSubtype'),
        width: tableCellWidths.exceptionSubTypes,
        align: 'left',
        noPaddingRight: true,
      },
    ];

    if (billingModuleEnabled && useExceptionCharges) {
      exceptionConfigurationTableCells.push(
        {
          name: 'createAdditionalCharge',
          label: translate('common.createAdditionalCharge'),
          width: tableCellWidths.createAdditionalCharge,
          align: 'left',
          noPaddingRight: true,
        },
        {
          name: 'rateCodeId',
          label: translate('common.rateCodeAndAccountingCode'),
          width: tableCellWidths.rateAmount,
          align: 'left',
          noPaddingRight: true,
        },
        {
          name: 'chargeAmount',
          label: translate('common.chargeAmount'),
          width: tableCellWidths.chargeAmount,
          align: 'left',
          noPaddingRight: true,
        },
      );
    }

    return (
      <>
        <Modal
          verticalSize="small"
          size="xLarge"
          title={translate(createTranslationKey(featureCode || '', 'vendors.featureCodes'))}
          onClose={this.closeModal}
          isLoading={isLoading || isSaving}
          padding="medium no no no"
          id={exceptionConfigurationsModalId}
          overflow={isLoading || isSaving ? 'hidden' : 'auto'}
        >
          <VehicleTypeForm
            vehicles={vehicles}
            loadModelConfigurations={loadExceptionConfigurations}
            onChangeVehicleTypeId={this.onChangeVehicleTypeId}
          />

          {!!size(exceptionConfigurations) && (
            <PanelSection padding="no small">
              <SortableTable
                cells={exceptionConfigurationTableCells}
                rows={exceptionConfigurations}
                rowComponent={ExceptionConfigurationModalTableRow}
                rowProps={{
                  billingModuleEnabled,
                  changeChargeAmount: this.changeChargeAmount,
                  onChangeAccountingCode: this.changeAccountingCode,
                  onChangeCanAddDetailPermissions: this.onChangeCanAddDetailPermissions,
                  onChangePickupExceptionSubType: this.onChangePickupExceptionSubType,
                  onChangePicturePermissions: this.onChangePicturePermissions,
                  onChangeRateCode: this.changeRateCode,
                  tableCellWidths,
                  toggleCreateAdditionalCharge: this.toggleCreateAdditionalCharge,
                  toggleExceptionFlag: this.toggleExceptionFlag,
                  useExceptionCharges,
                }}
                sort={this.onSortOrderChange}
                withClickableRows
              />
            </PanelSection>
          )}
          {!size(exceptionConfigurations) && noConfigurationsMessageVisible && (
            <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
          )}

          {!!size(exceptionConfigurations) && !isLoading && !isSaving && (
            <ModalFixedFooter isShadowed>
              <Button color="primary" margin="no small" onClick={this.saveExceptionConfigurations}>
                {translate('common.save')}
              </Button>
              {!!vehicleTypeId && (
                <Button color="primary" margin="no small" onClick={this.loadDefaultExceptionConfigurations}>
                  {translate('vendors.defaultExceptionConfiguration')}
                </Button>
              )}
            </ModalFixedFooter>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  exceptionConfigurations: state.vendors.exceptionConfigurations.exceptionConfigurations,
  isLoading: state.vendors.exceptionConfigurations.isLoading,
  isSaving: state.vendors.exceptionConfigurations.isSaving,
  vehicles: state.vendors.vehicleTypesForExceptionConfiguration.vehicles,
  vendorId: currentVendorId(state),
  billingModuleEnabled: billingFeatureStatusSelector(state.vendors.features.features),
  useExceptionCharges: state.vendors.billingModule.billingModule.useExceptionCharges,
});

const mapDispatchToProps = {
  loadDefaultExceptionConfigurations,
  loadExceptionConfigurations,
  resetExceptionConfigurations,
  saveExceptionConfigurations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionConfigurationModal);
