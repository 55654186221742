import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ONE_X_PER_WEEK = '1';
export const TWO_X_PER_WEEK = '2';
export const THREE_X_PER_WEEK = '3';
export const FOUR_X_PER_WEEK = '4';
export const FIVE_X_PER_WEEK = '5';
export const SIX_X_PER_WEEK = '6';
export const SEVEN_X_PER_WEEK = '7';
export const ONE_X_PER_MONTH = 'M1';
export const ON_CALL = 'OC';
export const ONE_X_PER_TWO_WEEK = 'W2';
export const UNKNOWN = 'UN';

export const TEMP = translate('opportunity.serviceTemp.temp');
export const PERM = translate('opportunity.serviceTemp.perm');

const SERVICE_FREQUENCIES = mapKeys(
  [
    { id: ONE_X_PER_WEEK, name: translate('opportunity.serviceFrequency.oneXPerWeek') },
    { id: TWO_X_PER_WEEK, name: translate('opportunity.serviceFrequency.twoXPerWeek') },
    { id: THREE_X_PER_WEEK, name: translate('opportunity.serviceFrequency.threeXPerWeek') },
    { id: FOUR_X_PER_WEEK, name: translate('opportunity.serviceFrequency.fourXPerWeek') },
    { id: FIVE_X_PER_WEEK, name: translate('opportunity.serviceFrequency.fiveXPerWeek') },
    { id: SIX_X_PER_WEEK, name: translate('opportunity.serviceFrequency.sixXPerWeek') },
    { id: SEVEN_X_PER_WEEK, name: translate('opportunity.serviceFrequency.sevenXPerWeek') },
    { id: ONE_X_PER_MONTH, name: translate('opportunity.serviceFrequency.oneXPerMonth') },
    { id: ON_CALL, name: translate('opportunity.serviceFrequency.onCall') },
    { id: ONE_X_PER_TWO_WEEK, name: translate('opportunity.serviceFrequency.oneXPerTwoWeek') },
  ],
  'id',
);

export default SERVICE_FREQUENCIES;
