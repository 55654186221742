import { filter, map } from 'lodash-es';

import { ON_HOLD, SUSPENDED_ACCOUNT, SUSPENDED_LABEL } from 'src/common/constants';
import translate from 'src/core/services/translate';
import { getServiceName } from './transformEquipmentTypeSizeWaste';
import { ACCOUNT_STATUSES, ACCOUNT_STATUSES_BY_NAME, ON_HOLD_LABEL } from 'src/common/constants/accountStatuses';

const transformLocations = (locations: any) =>
  map(locations, ({ customer, location, address, equipmentType, equipmentSize, materialType, serviceContract }) => ({
    customer,
    address,
    location: {
      id: location ? location.id : null,
      name: location ? location.name : translate('customers.allLocationsOnHoldOrSuspended'),
    },
    service: {
      id: serviceContract ? serviceContract.id : null,
      name: serviceContract
        ? getServiceName(equipmentSize, equipmentType, materialType)
        : translate('customers.allServicesOnHoldOrSuspended'),
    },
    status: !!serviceContract
      ? serviceContract?.status === ON_HOLD_LABEL
        ? ON_HOLD
        : serviceContract?.status === SUSPENDED_LABEL
        ? SUSPENDED_ACCOUNT
        : ACCOUNT_STATUSES_BY_NAME[customer.status].id
      : !!location
      ? location?.status === ON_HOLD_LABEL
        ? ON_HOLD
        : location?.status === SUSPENDED_LABEL
        ? SUSPENDED_ACCOUNT
        : ACCOUNT_STATUSES_BY_NAME[customer.status].id
      : ACCOUNT_STATUSES_BY_NAME[customer.status].id,
  }));

export const transformSuspendedLocations = (suspendedLocations: any) => ({
  ...suspendedLocations,
  suspendedStops: transformLocations(suspendedLocations.suspendedStops),
});

export const transformSuspendLocations = (suspendLocations: any[]) =>
  map(
    filter(suspendLocations, location => location.isChanged),
    ({ customer, location, service, status }) => ({
      id: service.id || location.id || customer.id,
      status: ACCOUNT_STATUSES[status].name.replace(' ', ''),
      suspendedItemType: (service.id ? 'ServiceContract' : null) || (location.id ? 'Location' : null) || 'Customer',
    }),
  );

export const transformAddSuspendLocation = (customer: any, location: any, serviceContract: any) => ({
  customer: { id: customer.id, name: customer.name, status: customer.status },
  address: location ? location.address : undefined,
  location: {
    id: location ? location.id : null,
    name: location ? location.name : translate('customers.allLocationsOnHoldOrSuspended'),
  },
  service: {
    id: serviceContract ? serviceContract.id : null,
    name: serviceContract ? serviceContract.name : translate('customers.allServicesOnHoldOrSuspended'),
  },
  status: !!serviceContract
    ? serviceContract?.status === ON_HOLD_LABEL
      ? ON_HOLD
      : serviceContract?.status === SUSPENDED_LABEL
      ? SUSPENDED_ACCOUNT
      : ACCOUNT_STATUSES_BY_NAME[customer.status].id
    : !!location
    ? location?.status === ON_HOLD_LABEL
      ? ON_HOLD
      : location?.status === SUSPENDED_LABEL
      ? SUSPENDED_ACCOUNT
      : ACCOUNT_STATUSES_BY_NAME[customer.status].id
    : ACCOUNT_STATUSES_BY_NAME[customer.status].id,
  isChanged: true,
});
