import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CITY_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { loadCityAlertImages } from 'src/vendors/ducks';
import { loadLocationAndCityAlert } from 'src/fleet/ducks';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import DashboardCityAlertsGLPopup from './DashboardCityAlertsGLPopup';

interface Props {}

const DashboardCityAlertGLPopupResolver: FC<Props> = () => {
  const dispatch = useDispatch();

  const cityAlerts = useSelector(state => state.vendors.cityAlerts.cityAlerts);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);
  const { locationAndCityAlert: alert } = useSelector(state => state.fleet.locationAndCityAlerts);

  const cityAlert = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'cityAlerts') {
      return undefined;
    }

    return cityAlerts.find((alert: CityAlert) => alert.id === selectedFeature.id);
  }, [cityAlerts, selectedFeature]);

  const loadDependencies = useCallback(() => {
    const dependencies = [];

    if (selectedFeature?.namespace === 'cityAlerts' && cityAlert?.id) {
      dependencies.push(
        loadCityAlertImages(cityAlert.id)(dispatch),
        loadLocationAndCityAlert(cityAlert.id, cityAlert.alertTypeId || CITY_ALERT_TYPE_ID)(dispatch),
      );
    } else return Promise.reject();

    return Promise.all(dependencies);
  }, [cityAlert, dispatch, selectedFeature?.namespace]);

  if (!cityAlert || !selectedFeature) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={cityAlert?.latitude}
      longitude={cityAlert?.longitude}
      onClose={() => dispatch(clearDashboardSelectedFeature())}
      tipSize={15}
      anchor="bottom-right"
    >
      <TypedResolver
        resolve={loadDependencies}
        successComponent={DashboardCityAlertsGLPopup}
        successProps={{ cityAlert: alert }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 320, width: 250 }}
        onError={() => dispatch(clearDashboardSelectedFeature())}
      />
    </MapGLPopupWrapper>
  );
};

export default DashboardCityAlertGLPopupResolver;
