import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import moment from 'moment';

import { GridColumn, Grid, Text, ActionIcon, Button } from '../../../core/components/styled';
import { Input, TypedField, Switch, DatePicker } from '../../../core/components';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { PreventativeMaintenanceFormPieceWrapper } from '../styled/PreventativeMaintenance';
import { PreventativeMaintenanceConfiguration } from '../../interfaces/PreventativeMaintenance';
import {
  isRequired,
  isDateValidValidator,
  minValueNumeric1,
  maxValueNumeric99M,
  isOptionalNumberOrZero,
  optionalMinValueNumeric1,
  optionalMaxValueNumeric99M,
  isRequiredNumber,
} from '../../../utils/services/validator';
import { trimAndNormalizeNumber } from '../../../core/services/normalizeNumber';

interface MetaInfoValue {
  unit: string;
  unitBefore: string;
  unitAfter: string;
  dueEvery: string;
  startAt: string;
  almostDue: string;
  pastDue: string;
}

const metaInfoHolder: { [k: string]: MetaInfoValue } = {
  milesType: {
    unit: translate('vehicles.preventativeMaintenanceLabels.miles'),
    unitBefore: translate('vehicles.preventativeMaintenanceLabels.milesBefore'),
    unitAfter: translate('vehicles.preventativeMaintenanceLabels.milesAfter'),
    dueEvery: 'Eg. 20000',
    startAt: 'Eg. 1',
    almostDue: 'Eg. 1000',
    pastDue: 'Eg. 2500',
  },
  hoursType: {
    unit: translate('vehicles.preventativeMaintenanceLabels.hours'),
    unitBefore: translate('vehicles.preventativeMaintenanceLabels.hoursBefore'),
    unitAfter: translate('vehicles.preventativeMaintenanceLabels.hoursAfter'),
    dueEvery: 'Eg. 20000',
    startAt: 'Eg. 1',
    almostDue: 'Eg. 1000',
    pastDue: 'Eg. 2500',
  },
  dateType: {
    unit: translate('vehicles.preventativeMaintenanceLabels.months'),
    unitBefore: translate('vehicles.preventativeMaintenanceLabels.daysBefore'),
    unitAfter: translate('vehicles.preventativeMaintenanceLabels.daysAfter'),
    dueEvery: 'Eg. 3',
    startAt: 'Eg. 12/12/2020',
    almostDue: 'Eg. 30',
    pastDue: 'Eg. 30',
  },
};

type MetaFetcher = {
  [key in keyof PreventativeMaintenanceConfiguration]: MetaInfoValue;
};

const metaFetcher: MetaFetcher = {
  mileageThresholdConfiguration: metaInfoHolder.milesType,
  engineHoursThresholdConfiguration: metaInfoHolder.hoursType,
  dateThresholdConfiguration: metaInfoHolder.dateType,
};

interface Props {
  pieceKey: keyof PreventativeMaintenanceConfiguration;
  form: string;
  isUnavailable?: boolean;
  onSwitch(status: boolean): void;
}

const PreventativeMaintenanceFormPiece: React.FC<Props> = ({ pieceKey, form, isUnavailable = false, onSwitch }) => {
  const dispatch = useDispatch();

  const selector = formValueSelector(form);
  const metaInfo = metaFetcher[pieceKey];

  const configurationInherited = useSelector(
    (state: AppState) => state.fleet.preventativeMaintenance.configurationInherited,
  );
  const topLevelConfiguration = useSelector(
    (state: AppState) => state.fleet.preventativeMaintenance.topLevelConfiguration,
  );
  const isActive: boolean = useSelector((state: AppState) => selector(state, `${pieceKey}.isActive`));

  const resetDefaults = () => {
    if (!topLevelConfiguration) {
      return;
    }

    dispatch(change(form, pieceKey, topLevelConfiguration[pieceKey]));
  };

  React.useEffect(() => {
    if (isActive && isUnavailable) {
      dispatch(change(form, `${pieceKey}.isActive`, false));
    }
  }, [isActive, isUnavailable, dispatch, form, pieceKey]);

  return (
    <PreventativeMaintenanceFormPieceWrapper isActive={isActive}>
      <Grid multiLine margin="medium no">
        <GridColumn size="12/12">
          <Grid>
            <GridColumn size="9/12" verticalAlign="center" padding="no no small">
              <Text size="large" weight="medium">
                {translate(`vehicles.preventativeMaintenancePieces.${pieceKey}`)}
              </Text>
            </GridColumn>

            <GridColumn size="3/12" align="right" verticalAlign="center" padding="no no small">
              <TypedField
                name={`${pieceKey}.isActive`}
                component={Switch}
                onChange={(event: unknown, newValue: boolean) => {
                  onSwitch(newValue);
                }}
                props={{
                  disabled: isUnavailable,
                }}
              />
            </GridColumn>
          </Grid>
        </GridColumn>

        {isActive && (
          <>
            <GridColumn size="4/12">
              <TypedField
                name={`${pieceKey}.dueThreshold`}
                component={Input}
                normalize={trimAndNormalizeNumber}
                validate={[isRequiredNumber, minValueNumeric1, maxValueNumeric99M]}
                props={{
                  raisedLabel: true,
                  label: translate('vehicles.preventativeMaintenanceLabels.pmDueEvery'),
                  placeholder: metaInfo.dueEvery,
                  metaInfo: metaInfo.unit,
                  margin: 'no',
                }}
              />
            </GridColumn>

            <GridColumn size="4/12">
              {pieceKey !== 'dateThresholdConfiguration' && (
                <TypedField
                  name={`${pieceKey}.startAt`}
                  component={Input}
                  normalize={trimAndNormalizeNumber}
                  validate={[isRequiredNumber, minValueNumeric1, maxValueNumeric99M]}
                  props={{
                    raisedLabel: true,
                    label: translate('vehicles.preventativeMaintenanceLabels.startingAt'),
                    placeholder: metaInfo.startAt,
                    metaInfo: metaInfo.unit,
                    margin: 'no',
                  }}
                />
              )}

              {pieceKey === 'dateThresholdConfiguration' && (
                <TypedField
                  name={`${pieceKey}.startAt`}
                  component={DatePicker}
                  validate={[isRequired, isDateValidValidator]}
                  props={{
                    raisedLabel: true,
                    label: translate('vehicles.preventativeMaintenanceLabels.startingAt'),
                    placeholder: metaInfo.startAt,
                    margin: 'no',
                    disabledDays: [
                      {
                        before: moment().toDate(),
                      },
                    ],
                  }}
                />
              )}
            </GridColumn>

            <GridColumn size="12/12">
              <Grid>
                <GridColumn size="12/12" verticalAlign="center" padding="medium no xSmall">
                  <Text size="medium" weight="normal">
                    {translate('vehicles.alerts')}
                  </Text>
                </GridColumn>
              </Grid>
            </GridColumn>

            <GridColumn size="4/12">
              <TypedField
                name={`${pieceKey}.almostDueThresholdAlert`}
                component={Input}
                normalize={trimAndNormalizeNumber}
                validate={[isOptionalNumberOrZero, optionalMinValueNumeric1, optionalMaxValueNumeric99M]}
                props={{
                  raisedLabel: true,
                  label: translate('vehicles.preventativeMaintenanceLabels.almostDueThreshold'),
                  placeholder: metaInfo.almostDue,
                  metaInfo: metaInfo.unitBefore,
                  margin: 'no no small',
                }}
              />
            </GridColumn>

            <GridColumn size="4/12">
              <TypedField
                name={`${pieceKey}.pastDueThresholdAlert`}
                component={Input}
                normalize={trimAndNormalizeNumber}
                validate={[isOptionalNumberOrZero, optionalMinValueNumeric1, optionalMaxValueNumeric99M]}
                props={{
                  raisedLabel: true,
                  label: translate('vehicles.preventativeMaintenanceLabels.pastDueThreshold'),
                  placeholder: metaInfo.pastDue,
                  metaInfo: metaInfo.unitAfter,
                  margin: 'no no small',
                }}
              />
            </GridColumn>

            {!!topLevelConfiguration && (
              <GridColumn size="12/12" padding="small xSmall xSmall">
                <Button line color="primary" type="button" onClick={resetDefaults}>
                  {translate('common.resetDefault')}
                </Button>
              </GridColumn>
            )}

            {configurationInherited && (
              <GridColumn size="12/12" padding="small xSmall xSmall">
                <ActionIcon icon="exclamation" size="sMedium" color="grayDarker" margin="no xxSmall no no" />

                {translate('vehicles.configurationsInherited')}
              </GridColumn>
            )}
          </>
        )}
      </Grid>
    </PreventativeMaintenanceFormPieceWrapper>
  );
};

export default PreventativeMaintenanceFormPiece;
