import React, { useState } from 'react';

import { INSIGHT_ICONS } from '../../../common/constants';
import { StationaryTime, StationaryTimeIndicator } from '../styled/MapWithTimeline';
import { StationaryTimeMap } from './Interfaces';
import { Text as TextUntyped } from '../../../core/components/styled';
import { PopoverWrapper } from '../../../core/components';
import { time } from '../../../utils/services/formatter';
import { Popover } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

const Text = TextUntyped as any;

interface Props {
  stationaryTimeMap: StationaryTimeMap;
  stationaryTimeStartIndex: number;
  timelineUnitWidth: number;
}

export const StationaryTimeTimelineMarker: React.SFC<Props> = ({
  timelineUnitWidth,
  stationaryTimeMap,
  stationaryTimeStartIndex,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <StationaryTime
        showBorder={isHovering}
        startPosition={timelineUnitWidth * stationaryTimeStartIndex}
        length={stationaryTimeMap[stationaryTimeStartIndex].numberOfStationaryTrackings * timelineUnitWidth}
      >
        <StationaryTimeIndicator onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
          <PopoverWrapper
            triggerButton={
              <img alt={translate('routeTimeline.stationaryTime')} width="12px" src={INSIGHT_ICONS.stationary} />
            }
            popoverContent={
              <Popover>
                <Text block margin="xxSmall">
                  {translate('routeTimeline.stationaryTime')}
                </Text>
                <Text block margin="xxSmall" weight="medium">
                  {`${time(stationaryTimeMap[stationaryTimeStartIndex].startTimestamp)} - ${time(
                    stationaryTimeMap[stationaryTimeStartIndex].endTimestamp,
                  )}`}
                </Text>
              </Popover>
            }
            size="medium"
            width="100%"
            height="100%"
          />
        </StationaryTimeIndicator>
      </StationaryTime>
    </>
  );
};
