import { FC } from 'react';
import {
  ProgressRoundedDoughnut,
  ProgressRoundedDoughnutText,
  ProgressRoundedDoughnutWrapper,
} from './styled/ProgressPopup';

interface Props {
  progress?: number;
  size?: number;
  color?: string;
}

const ProgressDoughnut: FC<Props> = ({ progress, size = 50, color = 'primary' }) => {
  return (
    <ProgressRoundedDoughnutWrapper size={size} color={color}>
      <ProgressRoundedDoughnut progress={progress} size={size} />
      <ProgressRoundedDoughnutText size={size}>{progress}</ProgressRoundedDoughnutText>
    </ProgressRoundedDoughnutWrapper>
  );
};

export default ProgressDoughnut;
