import styled from 'styled-components';

export const WeightsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WeightsRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 18x;
  text-align: left;
  margin: 0 0 12px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const WeightsMaterial = styled.span`
  padding: 0 10px 0 0;
`;

export const WeightsValue = styled.span`
  min-width: 100px;
  align-self: stretch;
  text-align: right;
`;
