import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { StreetNetworkPropertiesTravelPath } from 'src/routes/interfaces/TravelPath';

export const STREET_NETWORK_SOURCE = 'streetNetworkSource';
export const STREET_NETWORK_LAYER = 'streetNetworkLayer';

interface Props {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
}

const StreetNetworkGLSource: FC<Props> = ({ geoJSON }) => {
  return (
    <Source id={STREET_NETWORK_SOURCE} type="geojson" data={geoJSON}>
      <Layer
        id={STREET_NETWORK_LAYER}
        type="line"
        source={STREET_NETWORK_SOURCE}
        paint={{
          'line-color': '#000',
          'line-width': ['interpolate', ['linear'], ['zoom'], 12, 5, 22, 20],
          'line-opacity': 0.05,
        }}
      />
    </Source>
  );
};

export default memo(StreetNetworkGLSource);
