import React from 'react';

import { Label, TableCell, TableRow } from '../../../core/components/styled';
import { date } from '../../../utils/services/formatter';
import {
  FILE_IMPORT_PROCESS_COMPLETED,
  FILE_IMPORT_PROCESS_ERROR,
  FILE_IMPORT_PROCESS_IN_PROGRESS,
} from '../../constants';

interface Props {
  completedCount: number;
  createdOn: string;
  description: string;
  errorCount: number;
  fileProcessingStatusName: string;
  fileProcessingStatusTypeId: number;
  id: number;
  totalCount: number;
  vendorName: string;
  push: any;
}

const LABEL_COLORS: { [key: number]: string } = {
  [FILE_IMPORT_PROCESS_IN_PROGRESS]: 'info',
  [FILE_IMPORT_PROCESS_ERROR]: 'alert',
  [FILE_IMPORT_PROCESS_COMPLETED]: 'success',
};

const RouteImportsPageTableRow: React.FC<Props> = ({
  completedCount,
  createdOn,
  description,
  errorCount,
  fileProcessingStatusName,
  fileProcessingStatusTypeId,
  id,
  totalCount,
  vendorName,
  push,
}) => (
  <TableRow onClick={() => push(`/route-import/statuses?routeImportId=${id}`)}>
    <TableCell width="25%">{vendorName}</TableCell>
    <TableCell width="20%">{description}</TableCell>
    <TableCell width="15%">{date(createdOn)}</TableCell>
    <TableCell width="15%">
      {completedCount} / {errorCount}
    </TableCell>
    <TableCell width="10%">{totalCount}</TableCell>
    <TableCell width="15%">
      <Label color={LABEL_COLORS[fileProcessingStatusTypeId]}>{fileProcessingStatusName}</Label>
    </TableCell>
  </TableRow>
);

export default RouteImportsPageTableRow;
