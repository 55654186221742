import { map } from 'lodash-es';
import { PaymentStatus } from '../interfaces/ApplyChecks';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

const transformPaymentStatuses = (paymentStatuses: PaymentStatus[]) =>
  map(paymentStatuses, ({ id, technicalName }) => ({
    label: translate(createTranslationKey(technicalName, 'common.billingMethodTypes')),
    value: id,
  }));

export default transformPaymentStatuses;
