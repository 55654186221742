import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ContactEditorModal from './ContactEditorModal';

interface Props extends RouteComponentProps {
  closeContactEditorModal: () => void;
  contactId?: number;
  onCancel: () => void;
  contactList: any[];
}

class ContactEditorModalResolver extends PureComponent<Props> {
  render() {
    const { onCancel, contactId, closeContactEditorModal, contactList } = this.props;

    return (
      <Fragment>
        <Resolver
          successComponent={ContactEditorModal}
          loadingComponent={PageLoadingOverlay}
          successProps={{ onCancel, contactId, closeContactEditorModal, contactList }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContactEditorModalResolver),
);
