import { connect } from 'react-redux';
import { isSuperAdminSelector } from '../ducks';
import { AppState } from '../../store';

const SuperAdminGuard = ({ isSuperAdmin, children }: { isSuperAdmin: boolean; children: any }) => (isSuperAdmin ? children : null);

const mapStateToProps = (state: AppState) => ({
  isSuperAdmin: isSuperAdminSelector(state.account.login),
});

export default connect(mapStateToProps)(SuperAdminGuard);
