import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE,
  STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER,
} from 'src/customers/constants';
import { setStreetNetworkServiceAreasSelectedFeature } from 'src/customers/ducks/streetNetworkServiceAreas';
import CustomerLocationsGLLayer from './CustomerLocationsGLLayer';
import CustomerLocationGLPopup from './CustomerLocationsPopup';

interface Props {
  map: mapboxgl.Map;
}

const CustomerLocationsGL: React.FC<Props> = ({ map }) => {
  const dispatch = useDispatch();

  const handleHaulerLocationsClick = useCallback(
    (event: mapboxgl.MapLayerMouseEvent) => {
      const [feature] = map
        .queryRenderedFeatures(event.point)
        .filter(
          feature =>
            feature.source === STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE &&
            feature.properties?.layer === STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER,
        );

      if (!!feature) {
        dispatch(
          setStreetNetworkServiceAreasSelectedFeature({
            namespace: 'customerLocations',
            id: feature.id as number,
            extraData: {
              name: feature.properties?.name,
              latitude: event.lngLat.lat,
              longitude: event.lngLat.lng,
            },
          }),
        );
      }
    },
    [dispatch, map],
  );

  useEffect(() => {
    map.once('load', () => {
      map.on('click', handleHaulerLocationsClick);
    });

    return () => {
      map.off('click', handleHaulerLocationsClick);
    };
  }, [map, handleHaulerLocationsClick]);

  return (
    <>
      <CustomerLocationsGLLayer />
      <CustomerLocationGLPopup />
    </>
  );
};

export default CustomerLocationsGL;
