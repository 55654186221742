import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Icon } from 'src/core/components';
import { loadingOverlay } from 'src/core/styles';

export const RoutePlannerCalendarWrapper = styled.div<{ isLoading: boolean }>`
  position: relative;

  .rbc-time-content,
  .rbc-time-header-gutter {
    display: none !important;
  }
  .rbc-time-header {
    min-height: 500px;
    .rbc-time-header-content {
      border-left: none;
    }
  }
  .rbc-button-link {
    width: 100%;
  }
  .rbc-header {
    padding: 0;
  }
  .rbc-event {
    background-color: ${props => props.theme.brandWhite};
    color: ${props => props.theme.black};
    border-radius: 0;
    height: 100%;
    padding: 0;
    /* .rbc-event-content {
      overflow-y: auto;
    } */
    &:hover {
      cursor: auto;
    }
    &:focus {
      outline: none;
    }
  }
  .rbc-row-content .rbc-row:last-child {
    display: none;
  }

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `}
`;

export const CalendarDayCellWrapper = styled.div`
  .rbc-allday-cell {
    min-height: 485px;
  }
`;

export const CalendarToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  .vpDropdown__option {
    white-space: nowrap;
  }
`;

export const CalendarWeekHeaderWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5px;

  ${props =>
    props.isSelected &&
    css`
      background-color: ${transparentize(0.89, props.theme.brandPrimary)};
      border: 2px solid ${props.theme.brandPrimary};
      border-bottom: none;
    `}
`;

export const CalendarEventWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  height: 490px;
  padding: 10px 5px;

  ${props =>
    props.isSelected &&
    css`
      background-color: ${transparentize(0.89, props.theme.brandPrimary)};
      border: 2px solid ${props.theme.brandPrimary};
      border-top: none;
      ${CalendarEventFooter} {
        background-color: #e7f6f5;
      }
    `}
`;

export const ExpandableCalendarEventSectionIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  width: 12px;
  height: 12px;
  color: ${props => transparentize(0.3, props.theme.brandPrimary)};
  transition: transform 0.3s ease-out;
`;

export const ExpandableCalendarEventSectionHeader = styled.div`
  position: relative;
  text-transform: capitalize;
  font-size: 14px;
  width: 100%;
  color: ${props => props.theme.grayBase};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  & div > span {
    font-size: 10px;
    align-self: flex-start;
  }
`;

export const ExpandableCalendarEventSectionContent = styled.div`
  overflow: hidden;
  padding: 0 3px;
`;

export const ExpandableCalendarEventSection = styled.div<{
  isOpen?: boolean;
}>`
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  width: 100%;

  ${props =>
    props.isOpen &&
    css`
      ${ExpandableCalendarEventSectionIcon} {
        transform: rotate(180deg);
      }
      ${ExpandableCalendarEventSectionHeader} {
        color: ${props.theme.brandPrimary};
      }
    `};
`;

export const CalendarEventBody = styled.div`
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  margin-bottom: 10px;
  padding-bottom: 50px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
`;

export const CalendarEventFooter = styled.div`
  margin-top: 10px;
  padding: 0 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 99%;
  background-color: ${props => props.theme.brandWhite};
  overflow: hidden;
`;
