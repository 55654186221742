import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { applyCheck, loadOpenBills, loadPayments, savePayment } from 'src/finance/ducks';
import { CreateEditPaymentData } from 'src/finance/interfaces/ApplyChecks';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import { CreatePaymentForm } from 'src/finance/components/forms';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getPaymentsParams } from 'src/finance/components/pages/applyChecks/ApplyChecksPage';
import { getQueryParams } from 'src/utils/services/queryParams';
import { INVOICE_PAYMENT_DEFAULT_STATUSES } from 'src/finance/constants/invoicePaymentStatuses';
import { Modal } from 'src/core/components';
import { PaymentsSearchQueryParams } from 'src/finance/components/pages/applyChecks/ApplyChecksPageResolver';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

const createPaymentModalId = 'create-payment-modal-id';

interface Props {
  onClose(): void;
}

const CreatePaymentModal = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const vendorId = useSelector(currentVendorId);
  const isSaving = useSelector(state => state.finance.payments.isSaving);

  const { customerId, locationId, paymentNumberName } = getQueryParams<PaymentsSearchQueryParams>(search);
  const searchParams = getQueryParams(search);
  const paymentsParams = getPaymentsParams(vendorId, searchParams);

  const loadPaymentParams = {
    paymentNumber: paymentNumberName,
    customerId,
    locationId,
    ...paymentsParams,
  };

  const loadOpenBillsParams = {
    vendorId,
    billStatusIds: INVOICE_PAYMENT_DEFAULT_STATUSES,
    startDate: searchParams.startDate,
    endDate: searchParams.endDate,
    customerId,
    locationId,
  };

  const loadData = async () => {
    return Promise.all([loadPayments(loadPaymentParams)(dispatch), loadOpenBills(loadOpenBillsParams)(dispatch)]);
  };

  const onSaveCheck = (data: CreateEditPaymentData) => {
    scrollToTopOfModal(createPaymentModalId);

    savePayment(data)(dispatch)
      .then(() => {
        onClose();
        createSuccessNotification(translate('finance.alertMessages.paymentSaved'));
        loadData();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.paymentSaveError'),
          'finance.alertMessages',
        );
      });
  };

  const onApplyCheck = (data: CreateEditPaymentData) => {
    scrollToTopOfModal(createPaymentModalId);

    applyCheck(data)(dispatch)
      .then(() => {
        onClose();
        createSuccessNotification(translate('finance.alertMessages.paymentApplied'));
        loadData();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.paymentApplyError'),
          'finance.alertMessages',
        );
      });
  };

  return (
    <Modal
      padding="medium no no no"
      size="xLarge"
      isLoading={isSaving}
      title={translate('finance.applyChecks.newPayment')}
      onClose={onClose}
      id={createPaymentModalId}
      overflow={isSaving ? 'hidden' : 'auto'}
    >
      <CreatePaymentForm onApplyCheck={onApplyCheck} onSaveCheck={onSaveCheck} />
    </Modal>
  );
};

export default CreatePaymentModal;
