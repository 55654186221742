import React from 'react';

import { ButtonProps, IconButton } from './styled/Button';
import { TooltipButtonWrapper, TooltipButtonTooltip, TooltipButtonTooltipProps } from './styled/TooltipButtonStyles';
import translate from '../services/translate';

interface Props extends ButtonProps {
  tooltip?: string;
  tooltipProps?: any;
  tooltipAsString?: boolean;
  tooltipColor?: string;
  tooltipPosition?: TooltipButtonTooltipProps['position'];
  type?: 'button' | 'submit' | 'reset';
  form?: string;
  noButtonWrapper?: boolean;
  inlineFlexWrapper?: boolean;
  showTooltip?: boolean;
}

const TooltipIconButton: React.FC<Props & any> = ({
  tooltip,
  tooltipProps,
  tooltipAsString,
  tooltipColor,
  tooltipPosition,
  margin,
  inlineFlexWrapper,
  noButtonWrapper = false,
  showTooltip = false,
  ...iconButtonProps
}) => (
  <TooltipButtonWrapper margin={margin} inlineFlex={inlineFlexWrapper} showTooltip={showTooltip}>
    {!!tooltip && (
      <TooltipButtonTooltip color={tooltipColor} position={tooltipPosition || 'top'}>
        {tooltipAsString ? tooltip : translate(`tooltips.${tooltip}`, tooltipProps)}
      </TooltipButtonTooltip>
    )}

    {!noButtonWrapper && <IconButton margin="no" {...iconButtonProps} />}

    {noButtonWrapper && iconButtonProps.children}
  </TooltipButtonWrapper>
);

export default TooltipIconButton;
