import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, ThumbnailSet } from 'src/core/components';
import { DisposalTicket } from 'src/routes/interfaces/DisposalTicket';
import { getDisposalsTableCellWidths } from './DisposalTicketsSection';
import { ROLL_OFF_ID } from 'src/fleet/constants';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { WEIGHT_MEASUREMENT_UNITS } from 'src/common/constants';
import { WeightTicketImagesModal } from '..';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';

type Props = {
  deleteWeightTicket: (weightTicketId: number) => void;
  editWeightTicket: (weightTicketId: number) => void;
};

export default function DisposalsTableRow({
  customerLocationAddress,
  customerName,
  deleteWeightTicket,
  disposalDate,
  disposalSite,
  driverName,
  editWeightTicket,
  imageUrl,
  rawImageUrl,
  ticketNumber,
  unitOfMeasureTypeId,
  vehicleName,
  wasteMaterialTypeId,
  weight,
  weightTicketDetailId,
}: Props & DisposalTicket) {
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { wasteTypes } = useSelector(state => state.common.wasteTypes);

  const [isWeightTicketImageModalOpen, setWeightTicketImageModalOpen] = useState(false);

  if (!routeSummary) return;

  const timestampMoment = moment(disposalDate);
  const disposalsTableCellWidths = getDisposalsTableCellWidths(routeSummary.vehicleTypeId);
  const wasteMaterialType = wasteTypes.find(wasteType => wasteType.id === wasteMaterialTypeId)?.name;

  return (
    <>
      <TableRow>
        <TableCell width={disposalsTableCellWidths[0]}>
          <Text>{ticketNumber}</Text>
        </TableCell>
        <TableCell width={disposalsTableCellWidths[1]}>
          <Text>{disposalSite}</Text>
        </TableCell>
        <TableCell width={disposalsTableCellWidths[2]} vertical justifyContent="center">
          {vehicleName && driverName ? (
            <Text>
              <Text block weight="medium">
                {vehicleName}
              </Text>
              <Text>{driverName}</Text>
            </Text>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell width={disposalsTableCellWidths[3]}>
          <Text>{wasteMaterialType}</Text>
        </TableCell>
        <TableCell width={disposalsTableCellWidths[4]}>
          <Text>{weight}</Text>
        </TableCell>
        <TableCell width={disposalsTableCellWidths[5]}>
          <Text>{WEIGHT_MEASUREMENT_UNITS[unitOfMeasureTypeId].name}</Text>
        </TableCell>
        <TableCell width={disposalsTableCellWidths[6]} vertical justifyContent="center">
          <Text weight="medium">{timestampMoment.format('MM/DD/YYYY')}</Text>
          <Text>{timestampMoment.format(timeFormatWithoutSeconds)}</Text>
        </TableCell>

        {routeSummary.vehicleTypeId === ROLL_OFF_ID && (
          <TableCell width={disposalsTableCellWidths[7]} vertical justifyContent="center">
            <Text block singleLine weight="medium" margin="no no xxSmall">
              {customerName}
            </Text>
            <Text block singleLine size="small">
              {customerLocationAddress}
            </Text>
          </TableCell>
        )}
        <TableCell width={disposalsTableCellWidths[8]}>
          <Text>
            {imageUrl && <ThumbnailSet images={[imageUrl]} onClick={() => setWeightTicketImageModalOpen(true)} />}
          </Text>
        </TableCell>
        <TableCell width={disposalsTableCellWidths[8]} align="right">
          <TableActionButton onClick={() => editWeightTicket(weightTicketDetailId)}>
            <ActionButtonTooltip icon="edit" tooltip="edit" />
          </TableActionButton>
          <TableActionButton onClick={() => deleteWeightTicket(weightTicketDetailId)}>
            <ActionButtonTooltip icon="delete" tooltip="delete" />
          </TableActionButton>
        </TableCell>
      </TableRow>
      {isWeightTicketImageModalOpen && (
        <WeightTicketImagesModal
          weightTicket={{
            ticketNumber,
            disposalSiteAddress: disposalSite,
            image: { url: imageUrl, rawUrl: rawImageUrl || imageUrl },
            customerName,
          }}
          closeModal={() => setWeightTicketImageModalOpen(false)}
        />
      )}
    </>
  );
}
