import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { http } from 'src/core/services/http';

export const createBills = (
  vendorId: number,
  startDate: StringOrDate,
  endDate: StringOrDate,
  customerId?: number,
  locationId?: number,
  customerStatusIds?: number[],
  customerTypeIds?: number[],
  billMethodIds?: number[],
  paymentTermsIds?: number[],
  unbilledSearchTerm?: string,
  workOrderTypeId?: number,
  containerTypeId?: number,
  isOverdue?: boolean,
) =>
  http
    .post('statements/charges/createBills', {
      vendorId,
      startDate,
      endDate,
      customerId,
      locationId,
      customerStatusIds: customerStatusIds?.length ? customerStatusIds : null,
      customerTypeIds: customerTypeIds?.length ? customerTypeIds : null,
      billMethodIds: billMethodIds?.length ? billMethodIds : null,
      paymentTermsIds: paymentTermsIds?.length ? paymentTermsIds : null,
      unbilledSearchTerm,
      workOrderTypeId,
      containerTypeId,
      isOverdue,
    })
    .then(response => response.data);
