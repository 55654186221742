import translate from 'src/core/services/translate';

export const QUEUED_ID = 1;
export const IN_PROGRESS_ID = 2;
export const FAILED_ID = 3;
export const COMPLETED_ID = 4;

export const QUEUED = translate('insights.dailyReportTypes.status.queued');
export const IN_PROGRESS = translate('insights.dailyReportTypes.status.inProgress');
export const FAILED = translate('insights.dailyReportTypes.status.failed');
export const COMPLETED = translate('insights.dailyReportTypes.status.completed');

export const VideoRequestErrorMessageDeviceOffline = 'VideoRequestErrorMessageDeviceOffline';
export const VideoRequestErrorMessageRequestNotProcessed = 'VideoRequestErrorMessageRequestNotProcessed';
