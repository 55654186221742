import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import {
  loadFeatureConfigurations as doLoadFeatureConfigurations,
  saveFeatureConfigurations as doSaveFeatureConfigurations,
} from '../services/featureConfigurations';
import { FeatureConfigurations } from '../interfaces/FeatureConfiguration';

// Actions
const START_LOAD = 'vendors/featureConfigurations/START_LOAD';
const COMPLETE_LOAD = 'vendors/featureConfigurations/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/featureConfigurations/FAIL_LOAD';
const TOGGLE_FEATURE_CONFIGURATION = 'vendors/featureConfigurations/TOGGLE_FEATURE_CONFIGURATION';
const START_SAVE = 'vendors/featureConfigurations/START_SAVE';
const COMPLETE_SAVE = 'vendors/featureConfigurations/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/featureConfigurations/FAIL_SAVE';
const RESET = 'vendors/featureConfigurations/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  featureConfigurations: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          featureConfigurations: action.featureConfigurations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case TOGGLE_FEATURE_CONFIGURATION: {
      const { id, enabled } = action;
      const configurationIndex = findIndex((state.featureConfigurations as any).configData, { id });

      return update(state, {
        featureConfigurations: {
          configData: {
            [configurationIndex]: { enabled: { $set: enabled } },
          },
        },
      } as any);
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (featureConfigurations: FeatureConfigurations) => ({
  type: COMPLETE_LOAD,
  featureConfigurations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadFeatureConfigurations =
  (vendorId: number, vehicleTypeId: number, featureCode: any) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadFeatureConfigurationsPromise = doLoadFeatureConfigurations(vendorId, vehicleTypeId, featureCode);
    loadFeatureConfigurationsPromise
      .then(featureConfigurations => dispatch(completeLoad(featureConfigurations)))
      .catch(() => dispatch(failLoad()));

    return loadFeatureConfigurationsPromise;
  };

export const toggleFeatureConfiguration = (id: any, enabled: boolean) => ({
  type: TOGGLE_FEATURE_CONFIGURATION,
  id,
  enabled,
});

export const saveFeatureConfigurations =
  (vendorId: number, featureConfigurations?: FeatureConfigurations) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveFeatureConfigurationsPromise = doSaveFeatureConfigurations(vendorId, featureConfigurations);
    saveFeatureConfigurationsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveFeatureConfigurationsPromise;
  };

export const resetFeatureConfigurations = () => ({
  type: RESET,
});
