import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles';
import {
  Confirmation as ConfirmationContainer,
  ConfirmationDialog,
  ConfirmationTitle,
  ConfirmationMessage,
  ButtonSet,
  Button,
} from './styled';
import translate from '../services/translate';

interface Props {
  align?: string;
  message?: string;
  noLabel?: string;
  noLabelHidden?: boolean;
  reject: (value: any) => void;
  resolve: (value: any) => void;
  title: string;
  yesLabel?: string;
}

class Confirmation extends React.Component<Props> {
  state = { show: true };

  componentDidMount() {
    document.addEventListener('keydown', this.keyboardHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardHandler, false);
  }

  keyboardHandler = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.keyCode === 27) {
      this.cancel();
      return;
    }

    if (event.keyCode === 13) {
      this.accept();
    }
  };

  accept = (value?: any) => {
    this.setState({ show: false }, () => {
      this.props.resolve(value);
    });
  };

  cancel = (value?: any) => {
    this.setState({ show: false }, () => {
      this.props.reject(value);
    });
  };

  render() {
    const { align = 'right', message, noLabel, noLabelHidden = false, title, yesLabel } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <ConfirmationContainer>
          <ConfirmationDialog>
            <ConfirmationTitle>{title}</ConfirmationTitle>
            <ConfirmationMessage>{message}</ConfirmationMessage>
            <ButtonSet align={align}>
              {!noLabelHidden && (
                <Button line color="primary" margin="no small no no" onClick={this.cancel}>
                  {noLabel || translate('common.no')}
                </Button>
              )}

              <Button color="primary" onClick={this.accept}>
                {yesLabel || translate('common.yes')}
              </Button>
            </ButtonSet>
          </ConfirmationDialog>
        </ConfirmationContainer>
      </ThemeProvider>
    );
  }
}

export default Confirmation;
