import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getBillingCycles } from '../../common/services/billingCycleOptions';

// Constants
const START_LOAD_BILLING_CYCLE_OPTIONS = 'customers/billingCycleOptions/START_LOAD_BILLING_CYCLE_OPTIONS';
const COMPLETE_LOAD_BILLING_CYCLE_OPTIONS = 'customers/billingCycleOptions/COMPLETE_LOAD_BILLING_CYCLE_OPTIONS';
const FAIL_LOAD_BILLING_CYCLE_OPTIONS = 'customers/billingCycleOptions/FAIL_LOAD_BILLING_CYCLE_OPTIONS';

interface State {
  isLoading: boolean;
  billingCycles: TechnicalType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial State
const initialState: State = {
  isLoading: false,
  billingCycles: [],
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD_BILLING_CYCLE_OPTIONS,
});

const completeLoad = (billingCycles: TechnicalType[]) => ({
  type: COMPLETE_LOAD_BILLING_CYCLE_OPTIONS,
  billingCycles,
});

const failLoad = () => ({
  type: FAIL_LOAD_BILLING_CYCLE_OPTIONS,
});

// Async Actions
export const loadBillingCycles = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getBillingCyclesPromise = getBillingCycles();
  getBillingCyclesPromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getBillingCyclesPromise;
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_BILLING_CYCLE_OPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_BILLING_CYCLE_OPTIONS:
      return {
        ...initialState,
        isLoading: false,
      };
    case COMPLETE_LOAD_BILLING_CYCLE_OPTIONS:
      return {
        ...state,
        billingCycles: action.billingCycles,
        isLoading: false,
      };
    default:
      return state;
  }
};
