import { map } from 'lodash-es';
import { MouseEvent, useMemo, useState } from 'react';

import { ActionButtonTooltip, DragHandle } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, TableRowDraggable, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_SMALLER, TOP } from 'src/core/constants';
import createPopover from 'src/core/services/createPopover';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';

interface RoutePlannerDriverTableRowProps extends RoutePlannerRouteDriver, RoutePlannerRouteTemplateDriver {
  isDaily?: boolean;
  onDragStart: (driverId: number) => void;
  onDragEnd: () => void;
  onEditAvailability: (event: MouseEvent<HTMLElement>, driverId?: number, vehicleId?: number) => void;
  isViewOnlyOrSupport: boolean;
}

const RoutePlannerDriverTableRow = ({
  driverName,
  driverId,
  routes,
  routesTemplates,
  isDaily,
  isAvailable,
  isViewOnlyOrSupport,
  onDragStart,
  onDragEnd,
  onEditAvailability,
  ...props
}: RoutePlannerDriverTableRowProps) => {
  const cellHeight = `${TABLE_ROW_HEIGHT_SMALLER}px`;

  const [isDragging, setIsDragging] = useState(false);

  let closePopover = () => {};

  const openPopover = (event: MouseEvent<HTMLSpanElement>, content?: any) => {
    event.stopPropagation();
    closePopover = createPopover(
      event.currentTarget,
      Text,
      { size: 'small', color: 'grayDarkest', weight: 'medium', children: content },
      { position: TOP, size: 'small' },
    );
  };

  const combinedRouteTemplatesNames = useMemo(
    () => map(routesTemplates, template => template.routeTemplateName).join(', '),
    [routesTemplates],
  );

  const routeNamesWithReoccurringIcon = useMemo(
    () =>
      map(routes, (route, i) => (
        <span key={route.routeId}>
          {route.routeName}
          {route.routeTemplateId && <Text size="xLarge"> ↻</Text>}
          {`${i < routes.length - 1 ? ', ' : ''}`}
        </span>
      )),
    [routes],
  );

  const routesOrTemplatesCount = isDaily ? routes?.length : routesTemplates?.length;
  const isDriverAvailable = isDaily ? isAvailable : true;

  return (
    <TableRow
      height={TABLE_ROW_HEIGHT_SMALLER}
      key={`${driverId}_${driverName}`}
      onMouseLeave={() => closePopover && closePopover()}
    >
      <TableCell width="5%" align="center" padding="no" minHeight={cellHeight}>
        {isDriverAvailable && !isViewOnlyOrSupport && (
          <>
            <TableRowDraggable
              draggable
              onDragStart={e => {
                onDragStart(driverId);
                setIsDragging(true);
              }}
              onDragEnd={e => {
                onDragEnd();
                setIsDragging(false);
              }}
              display={isDragging ? 'block' : 'inline-block'}
              width={isDragging ? '100%' : '40px'}
            />
            <DragHandle />
          </>
        )}
      </TableCell>
      <TableCell width="75%" vertical align="center" minHeight={cellHeight}>
        <Text
          size="sMedium"
          weight="medium"
          padding="no no xxSmall no"
          singleLine
          title={driverName}
          color={!isDriverAvailable ? 'grayDark' : routesOrTemplatesCount > 1 ? 'alert' : 'grayDarkest'}
          fontStyle={!isDriverAvailable ? 'italic' : 'normal'}
        >
          {driverName}
        </Text>
        {!isDaily && (
          <Text
            color="grayDarker"
            weight="medium"
            size="small"
            singleLine
            onMouseEnter={(e: MouseEvent<HTMLSpanElement>) => openPopover(e, combinedRouteTemplatesNames)}
            onMouseLeave={() => closePopover && closePopover()}
          >
            {combinedRouteTemplatesNames}
          </Text>
        )}
        {isDaily && (
          <Text
            color="grayDarker"
            weight="medium"
            size="small"
            singleLine
            onMouseEnter={(e: MouseEvent<HTMLSpanElement>) => openPopover(e, routeNamesWithReoccurringIcon)}
            onMouseLeave={() => closePopover && closePopover()}
          >
            {routeNamesWithReoccurringIcon}
          </Text>
        )}
      </TableCell>

      <TableCell width="20%" align="right" minHeight={cellHeight}>
        {isDaily && !isViewOnlyOrSupport && (
          <TableActionButton color="grayDarker" onClick={ev => onEditAvailability(ev, driverId, undefined)}>
            <ActionButtonTooltip iconSize="small" icon="edit" tooltip="editAvailability" />
          </TableActionButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RoutePlannerDriverTableRow;
