import { toLower } from 'lodash-es';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MenuPosition } from 'react-select';

import { AppState } from 'src/store';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { Dropdown, TypedField } from '../../core/components';
import translate from '../../core/services/translate';
import { vehicleIdByTechnicalNameSelector, vehiclesByVehicleTypeIdSelector } from '../ducks';
import { Vehicle } from '../interfaces/Vehicles';
import { AVAILABILITY_ID, getVehiclesWithLabels } from './utils/vehiclesPageHooks';

interface Props extends DropDownFieldProps {
  shouldGroupByAvailability?: boolean;
  vehicles?: Vehicle[];
  vehicleTechnicalName?: string;
  vehicleTypeId?: number;
  withVehicleName?: boolean;
  menuPosition?: MenuPosition;
}

class VehicleDropdown extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      vehicleTypeId,
      vehicleTechnicalName,
      input: { onChange },
    } = prevProps;
    if (
      (vehicleTypeId && vehicleTypeId !== this.props.vehicleTypeId) ||
      (vehicleTechnicalName && vehicleTechnicalName !== this.props.vehicleTechnicalName)
    ) {
      onChange(null);
    }
  }

  onChange = (_: unknown, value: number) => {
    this.props.input.onChange(value);
  };

  filterOption = (option: any, input: any) => {
    return option.data.value === AVAILABILITY_ID || toLower(option.label).includes(toLower(input));
  };

  render() {
    const {
      dropdownProps,
      input: { name },
      label,
      placeholder,
      shouldGroupByAvailability,
      vehicles = [],
      withLabel,
      withPlaceholder,
      withVehicleName,
      menuPosition,
    } = this.props;

    let vehicleOptions = [];

    if (shouldGroupByAvailability) {
      vehicleOptions = getVehiclesWithLabels(vehicles, withVehicleName);
    } else {
      vehicleOptions = vehicles.map(({ regplate, id }) => ({
        label: regplate,
        value: withVehicleName ? regplate : id,
      }));
    }

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicle') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicle') : undefined),
          options: vehicleOptions,
          menuPosition,
          filterOption: this.filterOption,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const { vehicleTechnicalName } = ownProps;
  const vehicleTypeId = vehicleTechnicalName
    ? vehicleIdByTechnicalNameSelector(state.fleet.vehicleTypesForVendor, vehicleTechnicalName)
    : ownProps.vehicleTypeId;

  return {
    vehicles: vehicleTypeId
      ? vehiclesByVehicleTypeIdSelector(state.fleet.vehicles, vehicleTypeId)
      : state.fleet.vehicles.vehicles,
  };
};

export default connect(mapStateToProps)(VehicleDropdown);
