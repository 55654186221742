import React from 'react';
import humps from 'humps';
import { TableRow, TableCell, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { DownloadFile } from 'src/customers/components/styled/Customers';
import { dateAndTime } from 'src/utils/services/formatter';

interface Props {
  date: Date | string;
  errorFileDownloadUrl: string;
  errorFileName: string;
  fileName: string;
  failedRecordsCount: number;
  totalRecordsCount: number;
  successRecordsCount: number;
  status: string;
}

export const ContainerImportModalTableRow: React.SFC<Props> = ({
  fileName,
  status,
  errorFileDownloadUrl,
  date: importDate,
  successRecordsCount,
  failedRecordsCount,
  totalRecordsCount,
}) => (
  <TableRow>
    <TableCell width={'30%'}>
      <Text>{fileName}</Text>
    </TableCell>
    <TableCell width={'13%'}>
      <Text>{translate(`containers.containerImport.statuses.${humps.camelize(status)}`)}</Text>
    </TableCell>
    <TableCell width={'10%'}>
      <Text>{totalRecordsCount}</Text>
    </TableCell>
    <TableCell width={'11%'}>
      <Text>{successRecordsCount}</Text>
    </TableCell>
    <TableCell width={'8%'}>
      <Text>{failedRecordsCount}</Text>
    </TableCell>
    <TableCell width={'12%'}>
      {errorFileDownloadUrl && <DownloadFile href={errorFileDownloadUrl}>{translate('common.download')}</DownloadFile>}
    </TableCell>
    <TableCell width={'16%'}>
      <Text>{dateAndTime(importDate)}</Text>
    </TableCell>
  </TableRow>
);
