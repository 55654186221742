import update from 'immutability-helper';
import { reduce, set } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { DASHBOARD_FILTER_FORM_NAME } from '../constants/dashboardFilter';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { getPersistentFilters } from '../services/persistentFilterStorage';
import { getQueryParams } from '../../utils/services/queryParams';
import { getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { TODAY_FORMATTED } from '../../core/constants';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import store from 'src/store';

// Actions
const COMPLETE_UPDATE = 'dashboard/initialFilterFormValues/COMPLETE_UPDATE';
const RESET = 'dashboard/initialFilterFormValues/RESET';

// Initial state
const initialState = {
  initialFilterFormValues: {
    date: TODAY_FORMATTED,
    autoRefresh: true,
    activeTab: 'vehicles',
  },
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case COMPLETE_UPDATE:
      return update(state, {
        $merge: {
          initialFilterFormValues: action.initialFilterFormValues,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          initialFilterFormValues: { date: TODAY_FORMATTED },
        },
      } as any);

    default:
      return state;
  }
};

// Action creators
const completeUpdate = (initialFilterFormValues: any) => ({
  type: COMPLETE_UPDATE,
  initialFilterFormValues,
});

export const getInitialFilterFormValues = (filters: any, queryString: string) => {
  const { date = TODAY_FORMATTED } = getQueryParams(queryString);
  const isSelectedDateToday = (TODAY_FORMATTED as Date | string) === (date as Date | string);

  return {
    ...filters,
    includeInactive: !isSelectedDateToday,
    date,
  };
};

export const updateInitialFilterFormValues =
  (reset: boolean, vendorId: number, filtersPreferences?: FilterSetting[], shouldApplyPersistentFilters?: boolean) =>
  (dispatch: Dispatch) => {
    const state = store.getState() as any;
    const filters = state.dashboard.filters.filters;
    const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);
    const persistentFilters = reset || !vendorId ? {} : getPersistentFilters(vendorId);
    const vehicleFiltersPreferences = getVehicleFiltersPreferencesIds(filtersPreferences).map(
      id => `vehicleTypes.filters.${VEHICLE_TYPE_IDS[id].technicalName}`,
    );

    let initialVehicleTypeValues;

    if (shouldApplyPersistentFilters) {
      initialVehicleTypeValues = {
        allVehicleTypes: persistentFilters.allVehicleTypes,
        vehicleTypes: persistentFilters.vehicleTypes.filters,
      };
    } else {
      initialVehicleTypeValues = reduce(
        filters.vehicles.filters,
        (initialVehicleTypeValues, vehicleType) => {
          return set(
            initialVehicleTypeValues,
            vehicleType.formName,
            formSelector(state, vehicleType.formName)
              ? true
              : vehicleFiltersPreferences.length
              ? vehicleFiltersPreferences.includes(vehicleType.formName)
              : true,
          );
        },
        set(
          {},
          filters.vehicles.checkAllFormName,
          formSelector(state, filters.vehicles.checkAllFormName) || vehicleFiltersPreferences.length
            ? vehicleFiltersPreferences.length === filters.vehicles.filters.length
            : true,
        ),
      );
    }

    if (!vehicleFiltersPreferences.length) {
      initialVehicleTypeValues = { ...initialVehicleTypeValues, allVehicleTypes: true };
    }

    const initialVehicleValues = reduce(
      filters.vehicles.filters,
      (initialVehicleValues, vehicleType) =>
        reduce(
          vehicleType.filters,
          (initialVehicleValues, vehicle) => set(initialVehicleValues, vehicle.formName, !vehicle.isDisabled),
          set(
            initialVehicleValues,
            vehicleType.checkAllFormName,
            reset || formSelector(state, vehicleType.checkAllFormName) !== false,
          ),
        ),
      {},
    );

    const initialVehicleTrackingValues = reduce(
      filters.vehicleTracking.filters,
      (initialVehicleTrackingValues, vehicleTrackingOption) =>
        set(
          initialVehicleTrackingValues,
          vehicleTrackingOption.formName,
          (reset && !vehicleTrackingOption.unCheckedByDefault) ||
            (!vehicleTrackingOption.unCheckedByDefault &&
              formSelector(state, vehicleTrackingOption.formName) !== false),
        ),
      {},
    );

    const initialVehicleInsightTypeValues = reduce(
      filters.vehicleInsightTypes.filters,
      (initialVehicleInsightTypeValues, vehicleInsightType) => {
        set(
          initialVehicleInsightTypeValues,
          vehicleInsightType.formName,
          !reset && formSelector(state, vehicleInsightType.formName),
        );

        return vehicleInsightType.filters
          ? reduce(
              vehicleInsightType.filters,
              (initialVehicleInsightTypeValues, vehicleInsightTypeFilter) =>
                set(
                  initialVehicleInsightTypeValues,
                  vehicleInsightTypeFilter.formName,
                  !reset && formSelector(state, vehicleInsightTypeFilter.formName),
                ),
              set(
                initialVehicleInsightTypeValues,
                vehicleInsightType.checkAllFormName,
                !reset && formSelector(state, vehicleInsightType.checkAllFormName),
              ),
            )
          : initialVehicleInsightTypeValues;
      },
      set(
        {},
        filters.vehicleInsightTypes.checkAllFormName,
        !reset && formSelector(state, filters.vehicleInsightTypes.checkAllFormName),
      ),
    );

    const initialContainerInsightTypeValues = reduce(
      filters.containerInsightTypes.filters,
      (initialContainerInsightTypeValues, containerInsightType) => {
        set(
          initialContainerInsightTypeValues,
          containerInsightType.formName,
          !reset && formSelector(state, containerInsightType.formName),
        );

        return containerInsightType.filters
          ? reduce(
              containerInsightType.filters,
              (initialContainerInsightTypeValues, containerInsightTypeFilter) =>
                set(
                  initialContainerInsightTypeValues,
                  containerInsightTypeFilter.formName,
                  !reset && formSelector(state, containerInsightTypeFilter.formName),
                ),
              set(
                initialContainerInsightTypeValues,
                containerInsightType.checkAllFormName,
                !reset && formSelector(state, containerInsightType.checkAllFormName),
              ),
            )
          : initialContainerInsightTypeValues;
      },
      set(
        {},
        filters.containerInsightTypes.checkAllFormName,
        !reset && formSelector(state, filters.containerInsightTypes.checkAllFormName),
      ),
    );

    const initialCityInsightTypeValues = reduce(
      filters.cityInsightTypes.filters,
      (initialCityInsightTypeValues, cityInsightType) =>
        set(
          initialCityInsightTypeValues,
          cityInsightType.formName,
          !reset && formSelector(state, cityInsightType.formName),
        ),
      set(
        {},
        filters.cityInsightTypes.checkAllFormName,
        !reset && formSelector(state, filters.cityInsightTypes.checkAllFormName),
      ),
    );

    const initialVendorLocationTypeValues = reduce(
      filters.vendorLocationTypes.filters,
      (initialVendorLocationTypeValues, vendorLocationType) =>
        set(
          initialVendorLocationTypeValues,
          vendorLocationType.formName,
          !reset && formSelector(state, vendorLocationType.formName),
        ),
      set(
        {},
        filters.vendorLocationTypes.checkAllFormName,
        !reset && formSelector(state, filters.vendorLocationTypes.checkAllFormName),
      ),
    );

    let hasAutoRefresh;
    if (reset) {
      hasAutoRefresh = true;
    } else if (formSelector(state, 'autoRefresh') !== undefined) {
      hasAutoRefresh = formSelector(state, 'autoRefresh');
    } else {
      hasAutoRefresh = false;
    }

    const searchedVehicleId = formSelector(state, 'searchedVehicleId');

    let selectedSearchedVehicleId;
    if (reset) {
      selectedSearchedVehicleId = undefined;
    } else if (searchedVehicleId) {
      selectedSearchedVehicleId = {
        label: searchedVehicleId.locationName,
        value: searchedVehicleId,
      };
    } else {
      selectedSearchedVehicleId = undefined;
    }

    const date = shouldApplyPersistentFilters ? persistentFilters.date : formSelector(state, 'date');
    delete persistentFilters.date;

    const isSelectedDateToday = (TODAY_FORMATTED as Date | string) === (date as Date | string);

    const initialFilterFormValues = {
      includeInactive: !isSelectedDateToday,
      vendorId,
      date,
      autoRefresh: hasAutoRefresh,
      searchedVehicleId: reset ? null : searchedVehicleId,
      selectedSearchedVehicleId,
      ...initialVehicleTypeValues,
      ...initialVehicleValues,
      ...initialVehicleTrackingValues,
      ...initialVehicleInsightTypeValues,
      ...initialContainerInsightTypeValues,
      ...initialCityInsightTypeValues,
      ...initialVendorLocationTypeValues,
    };

    const defaultFilterFormValues = { ...initialFilterFormValues, ...persistentFilters };
    dispatch(completeUpdate(shouldApplyPersistentFilters ? defaultFilterFormValues : initialFilterFormValues));
  };

export const resetInitialFilterFormValues = () => ({
  type: RESET,
});
