import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { filter, findIndex, map, size } from 'lodash-es';
import update from 'immutability-helper';

import { AppState } from '../../../../../store';
import {
  Button,
  ButtonSet,
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  Message,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  ModalFixedHeader,
  ModalSection,
  ModalTitle,
  PanelSection,
  PanelSectionGroup,
} from '../../../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../../../core/services/createNotification';
import { Modal, Table, UnconnectedDropdown } from '../../../../../core/components';
import { multiWordAndSearch } from '../../../../../core/services/search';
import { TABLE_ROW_HEIGHT_SMALL } from '../../../../../core/constants';
import { UnassignedLocationsForm } from '../../../forms';
import { UnassignedLocationsModalTableRow } from './';
import translate from '../../../../../core/services/translate';
import { ROLL_OFF_ID } from 'src/fleet/constants';
import {
  FIRST_JOB_POSITION_ID,
  JOB_POSITION_OPTIONS,
  ROLLOFF_JOB_POSITION_OPTIONS,
} from 'src/core/constants/jobPositionOptions';
import { Box } from 'src/core/components/styled/Box';
import { isOptimizedStopInsertionFeatureEnabled } from 'src/vendors/ducks/features';

const filteredUnnasignedLocationsBySearchTermSelector = (unassignedLocations: any[], searchTerm: string) => {
  const filteredUnassignedLocations = filter(
    unassignedLocations,
    unassignedLocation =>
      !searchTerm ||
      unassignedLocation.isChecked ||
      multiWordAndSearch(unassignedLocation.customer.name, searchTerm) ||
      multiWordAndSearch(unassignedLocation.location.address.line1, searchTerm),
  );
  return filteredUnassignedLocations;
};

const checkAllLocations = ({ checkAll, checked }: any) => (
  <CheckboxContainer block noLabel size="small" margin="no no xSmall no">
    <CheckboxInput type="checkbox" name="checkAll" checked={checked} onChange={() => checkAll()} />
    <CheckboxCheck />
  </CheckboxContainer>
);

interface Props {
  addUnassignedLocationsToRoute: (selectedLocations: any[], menuPosition: number) => void;
  closeModal: () => void;
  isLoading: boolean;
  isOptimizedStopInsertionEnabled: boolean;
  routeTemplateId?: string;
  unassignedLocations: any[];
  vehicleTypeId: number;
}

interface State {
  allLocationsChecked: boolean;
  insertPosition: number;
  searchTerm: string;
  unassignedLocations: any[];
}

class UnassignedLocationsModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const unassignedLocations = map(this.props.unassignedLocations, unassignedLocation => ({
      ...unassignedLocation,
      isChecked: false,
    }));

    this.state = {
      allLocationsChecked: false,
      insertPosition: FIRST_JOB_POSITION_ID,
      searchTerm: '',
      unassignedLocations,
    };
  }

  onLocationSelected = (serviceId: number) => {
    const { unassignedLocations: stateUnassignedLocations } = this.state;
    const unasignedLocationIndex = findIndex(stateUnassignedLocations, location => location.service.id === serviceId);

    this.setState({
      unassignedLocations: update(stateUnassignedLocations, {
        [unasignedLocationIndex]: {
          isChecked: { $set: !stateUnassignedLocations[unasignedLocationIndex].isChecked },
        },
      }),
    });
  };

  onCheckAllLocationsSelected = () => {
    this.setState(prevState => {
      const unassignedLocations = map(prevState.unassignedLocations, unassignedLocation => ({
        ...unassignedLocation,
        isChecked: !prevState.allLocationsChecked,
      }));
      return {
        unassignedLocations,
        allLocationsChecked: !prevState.allLocationsChecked,
      };
    });
  };

  onSearchTermChange = (searchTerm: string) => {
    const { unassignedLocations: propsUnassignedLocations } = this.props;
    const { unassignedLocations: stateUnassignedLocations } = this.state;

    const rawUnassignedLocations = map(propsUnassignedLocations, unassignedLocation => ({
      ...unassignedLocation,
      isChecked: false,
    }));

    if (!searchTerm) {
      this.setState({ allLocationsChecked: false });
    }
    const unassignedLocations = !!searchTerm
      ? filteredUnnasignedLocationsBySearchTermSelector(stateUnassignedLocations, searchTerm)
      : rawUnassignedLocations;

    this.setState(prevState => ({
      ...prevState,
      searchTerm,
      unassignedLocations,
    }));
  };

  addUnassignedLocationsToRoute = () => {
    const { addUnassignedLocationsToRoute, closeModal } = this.props;
    const { unassignedLocations: stateUnassignedLocations, insertPosition } = this.state;

    const selectedLocations = filter(stateUnassignedLocations, ({ isChecked }) => isChecked);
    const selectedLocationsSize = size(selectedLocations);

    if (selectedLocationsSize) {
      closeModal();
      addUnassignedLocationsToRoute(
        map(selectedLocations, selectedLocation => ({ ...selectedLocation, isChecked: false })),
        insertPosition,
      );

      selectedLocationsSize === 1
        ? createSuccessNotification(translate('routes.alertMessages.routeLocationAdded'))
        : createSuccessNotification(
            translate('routes.alertMessages.routeLocationsAdded', { selectedStops: selectedLocationsSize }),
          );
    } else {
      createErrorNotification(translate('routes.alertMessages.routeLocationAddError'));
    }
  };

  render() {
    const {
      closeModal,
      isLoading,
      isOptimizedStopInsertionEnabled,
      routeTemplateId,
      unassignedLocations: propsUnassignedLocations,
      vehicleTypeId,
    } = this.props;
    const { unassignedLocations, allLocationsChecked } = this.state;

    const unassignedLocationsTableCells = [
      {
        name: 'checkAll',
        component: checkAllLocations,
        componentProps: { checkAll: this.onCheckAllLocationsSelected, checked: allLocationsChecked },
        width: '5%',
      },
      { name: 'customer', label: translate('common.customer'), width: '25%' },
      { name: 'address', label: translate('common.address'), width: '30%' },
      { name: 'wasteType', label: translate('common.wasteType'), width: '25%' },
      { name: 'service', label: translate('routes.service'), width: '15%' },
    ];

    const virtualizedProps = {
      itemSize: TABLE_ROW_HEIGHT_SMALL,
      height: Math.min(unassignedLocations.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8),
    };

    const areUnassignedLocations = !!size(unassignedLocations);
    const arePropsUnassignedLocations = !!size(propsUnassignedLocations);
    const isOptimizedOptionHidden = vehicleTypeId === ROLL_OFF_ID;

    return (
      <Modal padding="no" size="large" flex verticalSize="smallMedium">
        <ModalSection padding="no" fluid>
          <ModalFixedHeader padding="sMedium no no no">
            <ModalClose onClick={closeModal}>
              <ModalCloseIcon />
            </ModalClose>
            <ModalTitle>{translate('routes.unassignedLocations')}</ModalTitle>
          </ModalFixedHeader>
          <PanelSectionGroup isLoading={isLoading}>
            {arePropsUnassignedLocations && <UnassignedLocationsForm onSearchTermChange={this.onSearchTermChange} />}
            <PanelSection>
              {areUnassignedLocations ? (
                <Table
                  cells={unassignedLocationsTableCells}
                  rows={unassignedLocations}
                  rowComponent={UnassignedLocationsModalTableRow}
                  rowProps={{ onLocationSelected: this.onLocationSelected }}
                  virtualizedProps={virtualizedProps}
                />
              ) : (
                <Message padding="sMedium">{translate('routes.noUnassignedStops')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
          {arePropsUnassignedLocations && (
            <ModalFixedFooter padding="sMedium small" flexDirection="column" align="center">
              {routeTemplateId && (
                <Box display="block" width="200px" margin="no no small">
                  <UnconnectedDropdown
                    label={translate('customers.routeRecommendations.insertAs')}
                    value={this.state.insertPosition}
                    options={
                      isOptimizedOptionHidden || !isOptimizedStopInsertionEnabled
                        ? ROLLOFF_JOB_POSITION_OPTIONS
                        : JOB_POSITION_OPTIONS
                    }
                    margin="no"
                    onChange={(value: any) => this.setState({ insertPosition: value })}
                    menuPosition="fixed"
                  />
                </Box>
              )}
              <ButtonSet align="center" margin="no small">
                <Button color="secondary" onClick={this.props.closeModal} margin="no small no">
                  {translate('common.cancel')}
                </Button>
                <Button color="primary" onClick={this.addUnassignedLocationsToRoute}>
                  {translate('routes.addToRoute')}
                </Button>
              </ButtonSet>
            </ModalFixedFooter>
          )}
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.routes.routeTemplateUnassignedLocations.isLoading,
  unassignedLocations: state.routes.routeTemplateUnassignedLocations.unassignedLocations || [],
  isOptimizedStopInsertionEnabled: isOptimizedStopInsertionFeatureEnabled(state),
});

export default connect(mapStateToProps)(UnassignedLocationsModal);
