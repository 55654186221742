import React from 'react';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import translate from '../../../core/services/translate';
import { Button, ButtonSet, Grid, GridColumn } from '../../../core/components/styled';
import ExceptionManagementConfigurationsFormFieldArray from './ExceptionManagementConfigurationsFormFieldArray';
import { AppState } from '../../../store';
import { ExceptionManagementConfigurationsFormValues } from '../../interfaces/ExceptionManagementConfigurations';
import { EXCEPTION_MANAGEMENT_CONFIGURATIONS_FORM } from '../../constants/exceptionManagementConfigurations';

type Props = InjectedFormProps<ExceptionManagementConfigurationsFormValues, {}>;

const ExceptionManagementConfigurationsForm: React.FC<Props> = ({ handleSubmit }) => {
  const reasonCodes = useSelector((state: AppState) =>
    state.common.reasonCodeTypes.reasonCodeTypes.map(type => ({
      label: type.name,
      value: type.id,
    })),
  );

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="configurations"
        component={ExceptionManagementConfigurationsFormFieldArray}
        props={{ reasonCodes }}
      />

      <Grid centered>
        <GridColumn size="6/12">
          <ButtonSet margin="medium no">
            <Button color="primary" type="submit">
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </form>
  );
};

export default reduxForm<ExceptionManagementConfigurationsFormValues, {}>({
  form: EXCEPTION_MANAGEMENT_CONFIGURATIONS_FORM,
  enableReinitialize: true,
})(ExceptionManagementConfigurationsForm);
