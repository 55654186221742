import { http } from '../../core/services/http';
import { transformSearchCustomerLocations } from './transformCustomers';
import { LIMIT_PER_PAGE, PAGE } from '../../core/constants';

export const searchCustomerLocations = (searchString: string, vendorId: number, customerTypeIds: string) =>
  http
    .get('/customers', {
      params: { searchQuery: searchString, page: PAGE, limit: LIMIT_PER_PAGE, vendorId, customerTypeIds },
    })
    .then(response => transformSearchCustomerLocations(response.data.customers));
