import { ChangeEvent } from 'react';

import moment from 'moment';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { BilledCharge } from 'src/finance/interfaces/BillDetails';

type Props = BilledCharge & {
  checkBill: (
    workOrderId: number,
    invoiceId: number,
    vendorLocationId: number,
    serviceDate: StringOrDate,
    headerId: number,
    invoiceSubTotal: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
  onClick?: (bill: any) => void;
  unassignBilledCharge: (
    workOrderId: number,
    invoiceId: number,
    vendorLocationId: number,
    serviceDate: StringOrDate,
    headerId: number,
    invoiceSubTotal: number,
    e: any,
  ) => void;
  isChecked: boolean;
  isBillReleased: boolean;
};

const BillDetailsTableRow = ({ checkBill, onClick, isBillReleased, unassignBilledCharge, ...bill }: Props) => (
  <TableRow id={`bill-${bill.workOrderId}`} height={TABLE_ROW_HEIGHT_LARGE}>
    {!isBillReleased && (
      <TableCell width="2%" padding="defaultCellVertical no">
        <UnconnectedCheckbox
          block
          checked={bill.isChecked}
          onChange={e =>
            checkBill(
              bill.workOrderId,
              bill.invoiceId,
              bill.vendorLocationId,
              bill.serviceDate,
              bill.headerId,
              bill.invoiceSubTotal,
              e,
            )
          }
          onClick={e => e.stopPropagation()}
          id={`bill-${bill.workOrderId}-checkbox`}
        />
      </TableCell>
    )}
    <TableCell
      id={`bill-${bill.workOrderId}-workOrder-cell`}
      justifyContent="center"
      vertical
      width={isBillReleased ? '12%' : '10%'}
      padding="defaultCellVertical xSmall"
    >
      <Text block>{bill.workOrderId || '–'}</Text>
    </TableCell>
    <TableCell
      id={`bill-${bill.workOrderId}-service-cell`}
      justifyContent="center"
      vertical
      width="41%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>
        {bill.equipmentTypeName || '–'} • {bill.equipmentSizeName || '–'}
      </Text>
      <Text block>
        {bill.materialTypeName || '–'} • {bill.serviceFrequencyDescription || '–'}
      </Text>
    </TableCell>
    <TableCell
      id={`bill-${bill.workOrderId}-work-description-cell`}
      justifyContent="center"
      vertical
      width="19%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>{bill.workFlowDescription || '–'}</Text>
    </TableCell>
    <TableCell id={`bill-${bill.workOrderId}-serviceDate-cell`} width="10%" padding="defaultCellVertical xSmall">
      <Text>{bill.serviceDate ? moment(bill.serviceDate).format('MM/DD/YYYY') : '–'}</Text>
    </TableCell>
    <TableCell id={`bill-${bill.workOrderId}-amount-cell`} width="10%" padding="defaultCellVertical xSmall">
      <Text>${bill.invoiceSubTotal || 0}</Text>
    </TableCell>
    <TableCell id={`bill-${bill.workOrderId}-option-cell`} width="8%" padding="defaultCellVertical xSmall">
      <>
        <TableActionButton onClick={() => (onClick ? onClick(bill) : null)} id={`bill-${bill.workOrderId}-edit-button`}>
          <ActionButtonTooltip icon="money" tooltip="editRate" customViewBox="0 -960 960 960" size="mMedium" />
        </TableActionButton>
        {!isBillReleased && (
          <TableActionButton
            onClick={e =>
              unassignBilledCharge(
                bill.workOrderId,
                bill.invoiceId,
                bill.vendorLocationId,
                bill.serviceDate,
                bill.headerId,
                bill.invoiceSubTotal,
                e,
              )
            }
            id={`bill-${bill.workOrderId}-delete-button`}
          >
            <ActionButtonTooltip icon="undo" tooltip="unassignBill" />
          </TableActionButton>
        )}
      </>
    </TableCell>
  </TableRow>
);

export default BillDetailsTableRow;
