import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { Modal } from '../../../core/components';
import { TransferRouteLocationsForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: () => void;
  onTransferRouteLocationsSubmit: ({
    targetRouteId,
    routeDate,
    positionTypeId,
    hasStopsInPendingOptimization,
  }: {
    targetRouteId: number;
    routeDate: Date | string;
    positionTypeId: number;
    hasStopsInPendingOptimization: boolean;
  }) => void;
}

class TransferRouteLocationsModal extends PureComponent<Props> {
  render() {
    const { closeModal, onTransferRouteLocationsSubmit } = this.props;

    return (
      <Modal title={translate('routes.transferStops')} onClose={closeModal} padding="medium">
        <TransferRouteLocationsForm onSubmit={onTransferRouteLocationsSubmit} />
      </Modal>
    );
  }
}

export default connect()(TransferRouteLocationsModal);
