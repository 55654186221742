import { Dispatch, AnyAction } from 'redux';
import { findIndex } from 'lodash-es';
import update from 'immutability-helper';

import { Group } from '../interfaces/Groups';
import {
  loadGroups as doLoadGroups,
  loadGroup as doLoadGroup,
  createGroup as doCreateGroup,
  updateGroup as doUpdateGroup,
  deleteGroup as doDeleteGroup,
  assignRoutesToGroups as doAssignRoutesToGroups,
  assignTemplatesToGroups as doAssignTemplatesToGroups,
} from '../services/groups';

//Actions
const START_LOAD = 'routes/groups/START_LOAD';
const COMPLETE_LOAD = 'routes/groups/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/groups/FAIL_LOAD';
const START_SAVE = 'routes/groups/START_SAVE';
const COMPLETE_SAVE = 'routes/groups/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/groups/FAIL_SAVE';
const START_DELETE = 'routes/groups/START_DELETE';
const COMPLETE_DELETE = 'routes/groups/COMPLETE_DELETE';
const FAIL_DELETE = 'routes/groups/FAIL_DELETE';
const START_ASSIGN = 'routes/groups/START_ASSIGN';
const COMPLETE_ASSIGN = 'routes/groups/COMPLETE_ASSIGN';
const FAIL_ASSIGN = 'routes/groups/FAIL_ASSIGN';
const START_LOAD_GROUP = 'routes/groups/START_LOAD_GROUP';
const COMPLETE_LOAD_GROUP = 'routes/groups/COMPLETE_LOAD_GROUP';
const FAIL_LOAD_GROUP = 'routes/groups/FAIL_LOAD_GROUP';
const RESET = 'routes/groups/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadingGroup: false,
  isSaving: false,
  isDeleting: false,
  isAssigning: false,
  group: {} as Group,
  groups: [] as Group[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          groups: action.groups,
        },
      });
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });
    case COMPLETE_SAVE:
      const groupToSave = action.group;
      const groupIndex = findIndex(state.groups, { id: groupToSave.id });
      const groups =
        groupIndex === -1
          ? [...state.groups, groupToSave]
          : update(state.groups, { $splice: [[groupIndex, 1, groupToSave]] });
      return update(state, {
        $merge: {
          isSaving: false,
          groups,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });
    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
          groups: state.groups.filter(group => group.id !== action.groupId),
        },
      });
    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });
    case START_ASSIGN:
      return update(state, {
        $merge: {
          isAssigning: true,
        },
      });
    case COMPLETE_ASSIGN:
      return update(state, {
        $merge: {
          isAssigning: false,
        },
      });
    case FAIL_ASSIGN:
      return update(state, {
        $merge: {
          isAssigning: false,
        },
      });
    case START_LOAD_GROUP:
      return update(state, {
        $merge: {
          isLoadingGroup: true,
        },
      });
    case COMPLETE_LOAD_GROUP:
      return update(state, {
        $merge: {
          isLoadingGroup: false,
          group: action.group,
        },
      });
    case FAIL_LOAD_GROUP:
      return update(state, {
        $merge: {
          isLoadingGroup: false,
        },
      });

    case RESET:
      return initialState;
    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (groups: Group[]) => ({
  type: COMPLETE_LOAD,
  groups,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (group: Group) => ({
  type: COMPLETE_SAVE,
  group,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (groupId: number) => ({
  type: COMPLETE_DELETE,
  groupId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startAssign = () => ({
  type: START_ASSIGN,
});

const completeAssign = () => ({
  type: COMPLETE_ASSIGN,
});

const failAssign = () => ({
  type: FAIL_ASSIGN,
});

const startLoadGroup = () => ({
  type: START_LOAD_GROUP,
});

const completeLoadGroup = (group: Group) => ({
  type: COMPLETE_LOAD_GROUP,
  group,
});

const failLoadGroup = () => ({
  type: FAIL_LOAD_GROUP,
});

export const resetGroups = () => ({
  type: RESET,
});

// Thunks
export const loadGroups = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadGroupsPromise = doLoadGroups(vendorId);
  loadGroupsPromise.then((groups: Group[]) => dispatch(completeLoad(groups))).catch(() => dispatch(failLoad()));
  return loadGroupsPromise;
};

export const loadGroup = (groupId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadGroup());
  const loadGroupPromise = doLoadGroup(groupId);
  loadGroupPromise.then((group: Group) => dispatch(completeLoadGroup(group))).catch(() => dispatch(failLoadGroup()));
  return loadGroupPromise;
};

export const createGroup = (group: Group) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const createGroupPromise = doCreateGroup(group);
  createGroupPromise
    .then((id: number) =>
      dispatch(
        completeSave({
          ...group,
          id,
        }),
      ),
    )
    .catch(() => dispatch(failSave()));
  return createGroupPromise;
};

export const updateGroup = (group: Group) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const updateGroupPromise = doUpdateGroup(group);
  updateGroupPromise.then(() => dispatch(completeSave(group))).catch(() => dispatch(failSave()));
  return updateGroupPromise;
};

export const deleteGroup = (groupId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteGroupPromise = doDeleteGroup(groupId);
  deleteGroupPromise.then(() => dispatch(completeDelete(groupId))).catch(() => dispatch(failDelete()));
  return deleteGroupPromise;
};

export const assignRoutesToGroups = (routesIds: number[], groupsIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startAssign());
  const assignRoutesToGroupsPromise = doAssignRoutesToGroups(routesIds, groupsIds);
  assignRoutesToGroupsPromise.then(() => dispatch(completeAssign())).catch(() => dispatch(failAssign()));
  return assignRoutesToGroupsPromise;
};

export const assignTemplatesToGroups = (templatesIds: number[], groupsIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startAssign());
  const assignTemplatesToGroupsPromise = doAssignTemplatesToGroups(templatesIds, groupsIds);
  assignTemplatesToGroupsPromise.then(() => dispatch(completeAssign())).catch(() => dispatch(failAssign()));
  return assignTemplatesToGroupsPromise;
};
