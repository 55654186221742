import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';

interface Props {
  division: string;
  errors: any[];
}

const ViewErrorsTableRow: React.FC<Props> = ({ division, errors }) => (
  <TableRow>
    <TableCell width={'100%'}>
      {division}:&nbsp;
      {errors.map((error, index) => {
        return (
          <span key={index}>
            {error.message}
            {index + 1 < errors.length ? <span>,&nbsp;</span> : null}
          </span>
        );
      })}
    </TableCell>
  </TableRow>
);

export default ViewErrorsTableRow;
