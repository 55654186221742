import React, { PureComponent } from 'react';

import { camelCase } from 'lodash-es';

import { ActionButtonTooltip } from '../../../core/components';
import { FLEET_FACILITIES_DELETE, FLEET_FACILITIES_EDIT } from './../../../account/constants/permissions';
import { PermissionGuard } from '../../../account/components';
import { TABLE_ROW_HEIGHT_LARGEST } from '../../../core/constants';
import { TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface Props {
  address?: String;
  deleteFacility: (id: number, isDefaultStartLocation: boolean, hasContainerAssociated: boolean, event: any) => void;
  editFacility: (event: any, id: number) => void;
  facilitySubType?: string;
  facilityType?: string;
  handleRowClick: (location: any) => void;
  id: number;
  isActive?: boolean;
  hasContainerAssociated: boolean;
  isDefaultStartLocation: boolean;
  location: any;
  locationId: number;
  locationNo?: number;
  name?: string;
}

class FacilitiesPageTableRow extends PureComponent<Props> {
  render() {
    const {
      address,
      deleteFacility,
      editFacility,
      facilitySubType,
      facilityType,
      handleRowClick,
      hasContainerAssociated,
      id,
      isActive,
      isDefaultStartLocation,
      location,
      locationId,
      locationNo,
      name,
    } = this.props;

    return (
      <TableRow
        height={TABLE_ROW_HEIGHT_LARGEST}
        onClick={() => handleRowClick(location)}
        id={`facility-location-${locationNo}`}
      >
        <TableCell width="10%">
          <Text block margin="no no xxSmall">
            {locationNo}
          </Text>
        </TableCell>
        <TableCell justifyContent="center" vertical width="25%">
          {name && (
            <Text block weight="medium" margin="no no xxSmall">
              {name}
            </Text>
          )}

          {address && (
            <Text block margin="no no xxSmall">
              <Text weight="light">{address}</Text>
            </Text>
          )}
        </TableCell>
        <TableCell justifyContent="center" vertical width="20%">
          {facilityType && (
            <Text block weight="medium" margin="no no xxSmall">
              {translate(`facilities.facilityTypes.${camelCase(facilityType)}`)}
            </Text>
          )}

          {facilitySubType && (
            <Text block margin="no no xxSmall">
              <Text weight="light">{translate(`facilities.facilitySubTypes.${camelCase(facilitySubType)}`)}</Text>
            </Text>
          )}
        </TableCell>
        <TableCell width="20%">
          <Text block margin="no no xxSmall">
            {locationId}
          </Text>
        </TableCell>
        <TableCell width="15%">
          <Text block margin="no no xxSmall">
            {isActive ? translate('common.active') : translate('common.inactive')}
          </Text>
        </TableCell>
        <TableCell width="10%" align="right">
          <PermissionGuard permission={FLEET_FACILITIES_EDIT}>
            <TableActionButton onClick={event => editFacility(event, id)} id={`edit-facility-${locationNo}-button`}>
              <ActionButtonTooltip icon="edit" tooltip="editFacility" />
            </TableActionButton>
          </PermissionGuard>
          <PermissionGuard permission={FLEET_FACILITIES_DELETE}>
            <TableActionButton
              onClick={event => deleteFacility(id, isDefaultStartLocation, hasContainerAssociated, event)}
              id={`delete-facility-${locationNo}-button`}
            >
              <ActionButtonTooltip icon="delete" tooltip="deleteFacility" />
            </TableActionButton>
          </PermissionGuard>
        </TableCell>
      </TableRow>
    );
  }
}

export default FacilitiesPageTableRow;
