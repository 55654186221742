import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { RoutePlannerCalendarPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { RoutePlannerCalendarData } from '../../interfaces/routePlanner/RoutePlannerCalendarData';
import { loadRoutePlannerCalendarData as doLoadRoutePlannerCalendarData } from '../../services/routePlanner';

const START_LOAD = 'routePlannerCalendarData/START_LOAD';
const COMPLETE_LOAD = 'routePlannerCalendarData/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerCalendarData/FAIL_LOAD';
const RESET = 'routePlannerCalendarData/RESET';

interface State {
  isLoading: boolean;
  calendarData: RoutePlannerCalendarData[];
}

const initialState: State = {
  isLoading: false,
  calendarData: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          calendarData: action.calendarData,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          calendarData: [],
        },
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (calendarData: RoutePlannerCalendarData[]) => ({
  type: COMPLETE_LOAD,
  calendarData,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRoutePlannerCalendarData = (params: RoutePlannerCalendarPayload) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePlannerCalendarDataPromise = doLoadRoutePlannerCalendarData(params)
    .then(calendarData => {
      dispatch(completeLoad(calendarData as RoutePlannerCalendarData[]));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadRoutePlannerCalendarDataPromise;
};

export const resetRoutePlannerCalendarData = () => ({
  type: RESET,
});
