import update from 'immutability-helper';
import { find, flatten, get, identity, map, reduce, uniqBy } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { ServiceType } from '../interfaces/ServiceType';
import doLoadServiceTypes from '../services/loadServiceTypes';

// Actions
const START_LOAD = 'common/serviceTypes/START_LOAD';
const COMPLETE_LOAD = 'common/serviceTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/serviceTypes/FAIL_LOAD';
const RESET = 'common/serviceTypes/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  serviceTypes: ServiceType[];
} = {
  isLoading: false,
  serviceTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceTypes: action.serviceTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceTypes: ServiceType[]) => ({
  type: COMPLETE_LOAD,
  serviceTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadServiceTypes = (vehicleTypeId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadServiceTypesPromise = doLoadServiceTypes(vehicleTypeId);
  loadServiceTypesPromise.then(serviceTypes => dispatch(completeLoad(serviceTypes))).catch(() => dispatch(failLoad()));
  return loadServiceTypesPromise;
};

export const resetServiceTypes = () => ({
  type: RESET,
});

// Selectors
export const getEquipmentTypes = (serviceTypes: ServiceType[], serviceTypeId?: number) => {
  if (serviceTypeId) {
    const serviceType = find(serviceTypes, { id: serviceTypeId });
    return get(serviceType, 'equipmentTypes', []);
  }
  const joinedEquipmentTypes = uniqBy(flatten(map(serviceTypes, serviceType => serviceType.equipmentTypes)), 'id');
  return joinedEquipmentTypes;
};

export const equipmentTypesSelector = createSelector(getEquipmentTypes, identity);

/* eslint-disable no-param-reassign */
const getEquipmentSizes = (serviceTypesState: any, equipmentTypeId: number) => {
  const equipmentType = reduce(
    serviceTypesState.serviceTypes,
    (equipmentType, serviceType) => {
      equipmentType = equipmentType || find(serviceType.equipmentTypes, { id: equipmentTypeId });
      return equipmentType;
    },
    undefined,
  );

  return get(equipmentType, 'equipmentSizes', []);
};
/* eslint-enable no-param-reassign */

export const equipmentSizesSelector = createSelector(getEquipmentSizes, identity);

const getServiceTypeIdByTechnicalName = (serviceTypesState: any, technicalName: string) => {
  const serviceType = find(serviceTypesState.serviceTypes, { technicalName });
  return get(serviceType, 'id');
};
export const serviceTypeIdByTechnicalNameSelector = createSelector(getServiceTypeIdByTechnicalName, identity);

const getTechnicalNameByServiceTypeId = (serviceTypesState: any, serviceTypeId: number) => {
  const serviceType = find(serviceTypesState.serviceTypes, { id: serviceTypeId });
  return get(serviceType, 'technicalName', '');
};
export const technicalNameByServiceTypeIdSelector = createSelector(getTechnicalNameByServiceTypeId, identity);
