import { connect } from 'react-redux';
import { debounce, get, isBoolean, isEmpty, isEqual, isString, map, reduce, some, sortBy } from 'lodash-es';
import { Field, InjectedFormProps, change, getFormValues, reduxForm, submit } from 'redux-form';
import { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import Cookie from 'js-cookie';
import moment from 'moment';

import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { getExcludeVehicleFilters } from 'src/common/utils/filters';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { SNOW_ROAD_CONDITIONS, STREET_SWEEPER_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { loadGeoFenceFilterOptions } from 'src/routes/ducks/geoFenceFilterOptions';
import { getQueryParams } from 'src/utils/services/queryParams';
import { isDateValidValidator, isRequired } from 'src/utils/services/validator';
import {
  checkIfGeoFenceIsEnabled,
  checkIfRoadConditionsAreEnabled,
  checkIfSnowRoadConditionsAreEnabled,
  checkIfStreetSweepingConditionsAreEnabled,
} from 'src/vendors/ducks/features';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { isSmartCitySelector } from '../../../account/ducks';
import { DuckFunction } from '../../../contracts/ducks';
import { DatePicker, PopoverWrapper, SimpleInput, Switch, TypeAhead } from '../../../core/components';
import { Button, ButtonSet, IconButtonIcon, Popover, Text } from '../../../core/components/styled';
import { TODAY } from '../../../core/constants/weekdays';
import translate from '../../../core/services/translate';
import { CustomerProximitySearchModalResolver } from '../../../customers/components/modals';
import { vehicleIdsByTechnicalNamesSelector } from '../../../fleet/ducks';
import { AppState } from '../../../store';
import { createUrl } from '../../../utils/services/queryParams';
import { autoRefreshStatusSelector, flagLocationStatusSelector } from '../../../vendors/ducks';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  filterPickupExceptionTypesByVehicle,
  filtersByVehicleSearchTermSelector,
  getInitialFilterFormValues,
  loadCityInsightTypes,
  loadPickupExceptionTypes,
  loadVehicles,
  updateInitialFilterFormValues,
} from '../../ducks';
import { getPersistentFilters } from '../../services/persistentFilterStorage';
import searchDashboardVehicles from '../../services/searchDashboardVehicles';
import {
  DashboardFilterClose,
  DashboardFilterCloseIcon,
  DashboardFilter as DashboardFilterContainer,
  DashboardFilterPanel,
  DashboardFilterPanelContent,
  DashboardFilterSearch,
  DashboardFilterSearchIcon,
  DashboardFilterSection,
  DashboardFilterSubPanel,
  DashboardFilterSubtitle,
  DashboardFilterTitle,
  DashboardFilterTitleWrapper,
} from '../styled';
import { DashboardFilterCategory, DashboardFilterList } from './dashboardFilterFormSections';

const FILTER_CATEGORIES = {
  vehicles: 'vehicles',
  vehicleInsights: 'vehicleInsights',
  containerInsights: 'containerInsights',
  cityInsights: 'cityInsights',
  vendorLocations: 'vendorLocations',
};

interface ComponentProps extends RouteComponentProps {
  applyFilters(): void;
  close(): void;
  disableRestrictionMessages(): void;
  isOpen: boolean;
  isSubMenuHidden: boolean;
  onApplyLastSettings(): void;
  onAutoRefresh(): void;
  onReset(): void;
  setConditionType?: (conditionType: string) => void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  change: any;
  defaultVendorId: number;
  filterPickupExceptionTypesByVehicle: DuckFunction<typeof filterPickupExceptionTypesByVehicle>;
  filters: any;
  formValues?: any;
  initialValues: any;
  isAutoRefreshEnabled?: boolean;
  isFlagLocationActive?: boolean;
  isGeoFenceActive: boolean;
  isLoadingCityInsightTypes: boolean;
  isLoadingVehicles: boolean;
  isSmartCity?: boolean;
  loadCityInsightTypes: DuckFunction<typeof loadCityInsightTypes>;
  loadPickupExceptionTypes: DuckFunction<typeof loadPickupExceptionTypes>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  pickupExceptionTypes?: any[];
  push: any;
  roadConditionsEnabled?: boolean;
  snowRoadConditionsEnabled: boolean;
  streetSweepingConditionsEnabled: boolean;
  selectedVehicleTypeIds?: number[];
  submit: any;
  updateInitialFilterFormValues: DuckFunction<typeof updateInitialFilterFormValues>;
  vendorId: number;
  filtersPreferences: FilterSetting[];
  initialFilterFormValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  isAutoRefreshDisabled: boolean;
  isCustomerProximitySearchModalOpen: boolean;
  isFirstLoad: boolean;
  isInitialLoad: boolean;
  isLoadingFilters: boolean;
  openedCategory?: string;
  persistentFilters: any;
  searchedDateValue?: Date | string;
  searchedVehicleIdValue?: any;
}

class DashboardFilterForm extends PureComponent<Props, State> {
  autoRefreshInterval?: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      isAutoRefreshDisabled: false,
      isCustomerProximitySearchModalOpen: false,
      isFirstLoad: true,
      isInitialLoad: false,
      isLoadingFilters: false,
      persistentFilters: getPersistentFilters(props.vendorId),
      searchedDateValue: undefined,
      searchedVehicleIdValue: undefined,
    };
  }

  componentDidMount() {
    const {
      updateInitialFilterFormValues,
      vendorId,
      filtersPreferences,
      location: { search },
    } = this.props;
    const params = getQueryParams(search);

    this.loadDependencies(vendorId, params.date || TODAY).then(() => {
      const shouldReset = false;
      updateInitialFilterFormValues(shouldReset, vendorId, filtersPreferences);

      this.setState({ isInitialLoad: true });
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      isAutoRefreshEnabled,
      isOpen,
      handleSubmit,
      formValues,
      vendorId,
      selectedVehicleTypeIds,
      filterPickupExceptionTypesByVehicle,
    } = this.props;
    const { autoRefresh } = formValues;
    const { isOpen: prevIsOpen } = prevProps;
    const { isFirstLoad } = this.state;
    const { persistentFilters: prevPersistentFilters } = prevState;
    const persistentFilters = getPersistentFilters(vendorId);

    if (isAutoRefreshEnabled && this.shouldDisableAutoRefresh()) {
      this.setAutoRefresh(true);
      this.updateFormValue('autoRefresh', false);
    } else {
      this.setAutoRefresh(false);
    }

    if (prevIsOpen !== isOpen) {
      if (isOpen) {
        window.clearInterval(this.autoRefreshInterval);
      } else {
        const isAutoRefresh = this.getFormValue('autoRefresh');

        if (isAutoRefresh && isAutoRefreshEnabled) {
          this.onAutoRefresh();
        }
      }
    }

    if (selectedVehicleTypeIds && !isEqual(sortBy(selectedVehicleTypeIds), sortBy(prevProps.selectedVehicleTypeIds))) {
      filterPickupExceptionTypesByVehicle(selectedVehicleTypeIds);
    }

    if (isFirstLoad && formValues && formValues.vendorId) {
      this.setIsFirstLoad(false, handleSubmit as any);

      if (isAutoRefreshEnabled && !this.shouldDisableAutoRefresh() && autoRefresh && !isOpen) {
        this.onAutoRefresh();
      }
    }

    if (!isEqual(prevPersistentFilters, persistentFilters)) {
      this.setPersistentFilters(persistentFilters);
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.autoRefreshInterval);
  }

  onDateSelected = (event: any, date: Date | string, shouldApplyPersistentFilters?: boolean) => {
    const { location, push, vendorId, change } = this.props;
    this.toggleCategory(this.state.openedCategory);

    const isCorrectDate = moment(date, 'MM/DD/YYYY', true).isValid();
    const correctDate = moment(date).format('MM/DD/YYYY');
    if (isCorrectDate) {
      push(
        createUrl(location.pathname, location.search, {
          date: correctDate,
        }),
      );

      if (!isBoolean(event) && event) {
        change('searchedVehicleId', undefined);
        this.setState({ isInitialLoad: true, searchedVehicleIdValue: undefined });
      }

      this.loadDependencies(vendorId, correctDate, shouldApplyPersistentFilters);
    }
  };

  onSearchChange = (val?: { vehicleId?: number }) => {
    const { formValues, vendorId } = this.props;
    const { date } = formValues;

    const isCorrectDate = moment(date, 'MM/DD/YYYY', true).isValid();
    const correctDate = moment(date).format('MM/DD/YYYY');

    if (isCorrectDate && (!val || !val.vehicleId)) {
      this.loadDependencies(vendorId, correctDate);
    }

    this.setState({ isInitialLoad: !val, searchedVehicleIdValue: undefined });
  };

  onAutoRefresh = () => {
    const autoRefreshIntervalInMs = 90000;

    this.autoRefreshInterval = window.setInterval(async () => {
      const { vendorId } = this.props;

      if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
        const date = this.getFormValue('date');
        const { handleSubmit, onAutoRefresh, defaultVendorId, loadVehicles, isGeoFenceActive, filtersPreferences } =
          this.props;
        const formattedDate = moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY');
        const correctDate = moment(formattedDate).isValid() ? formattedDate : moment().format('MM/DD/YYYY');

        let vehicleTypes = map(VEHICLE_TYPE_IDS, vt => vt.technicalName);
        const excludeVehicleTypes = getExcludeVehicleFilters(filtersPreferences);
        vehicleTypes = vehicleTypes.filter(vt => !excludeVehicleTypes.includes(vt));

        await loadVehicles(defaultVendorId, correctDate, isGeoFenceActive, vehicleTypes);

        onAutoRefresh();
        (handleSubmit as any)();
      }
    }, autoRefreshIntervalInMs);
  };

  setAutoRefresh = (value: boolean) => {
    this.setState({ isAutoRefreshDisabled: value });
  };

  setIsFirstLoad = (value: boolean, handleSubmit: () => void) => {
    this.setState(
      {
        isFirstLoad: value,
      },
      handleSubmit,
    );
  };

  setPersistentFilters = (persistentFilters: any) => {
    this.setState({ persistentFilters });
  };

  getFormValue = (name: string, defaultValue?: any) => get(this.props.formValues, name, defaultValue);

  shouldDisableAutoRefresh = () => {
    const date = this.getFormValue('date');
    return moment(date, 'MM/DD/YYYY').isBefore(moment(), 'day');
  };

  loadDependencies = async (vendorId: number, date: Date | string, shouldApplyPersistentFilters?: boolean) => {
    const { filtersPreferences, isGeoFenceActive, loadVehicles, updateInitialFilterFormValues } = this.props;

    const momentDate = moment(date, 'MM/DD/YYYY', true); // use strict parsing
    const correctDate = momentDate.isValid() ? momentDate.format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');

    let vehicleTypes = map(VEHICLE_TYPE_IDS, vt => vt.technicalName);
    const excludeVehicleTypes = getExcludeVehicleFilters(filtersPreferences);
    vehicleTypes = vehicleTypes.filter(vt => !excludeVehicleTypes.includes(vt));

    await loadVehicles(vendorId, correctDate, isGeoFenceActive, vehicleTypes);

    const shouldReset = true;
    if (!shouldApplyPersistentFilters || isString(shouldApplyPersistentFilters)) {
      updateInitialFilterFormValues(shouldReset, vendorId, filtersPreferences);
    }
  };

  updateFormValue = (name: string, value: any) => {
    this.props.change(name, value);
  };

  updateRoadConditions = (name: string, value: any) => {
    this.props.setConditionType && this.props.setConditionType(name);
    this.props.change(name, value);
  };

  loadDashboardVehicleOptions = debounce((searchTerm, onOptionsLoaded) => {
    const routeDate = this.getFormValue('date');
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    searchDashboardVehicles(this.props.vendorId, routeDate, searchTerm).then(onOptionsLoaded);
  }, 500);

  reset = () => {
    const { reset, disableRestrictionMessages, onReset } = this.props;

    this.setState({ isInitialLoad: false, searchedVehicleIdValue: undefined });

    reset();
    disableRestrictionMessages();
    setTimeout(onReset);
  };

  applyFilters = () => {
    const { applyFilters } = this.props;
    applyFilters();
  };

  submit = async () => {
    const { updateInitialFilterFormValues, disableRestrictionMessages, submit } = this.props;
    (updateInitialFilterFormValues as any)();
    disableRestrictionMessages();
    submit(DASHBOARD_FILTER_FORM_NAME);

    this.setState({ isInitialLoad: false });
  };

  isAnyVehicleTypeSelected = () => some(this.getFormValue('vehicleTypes.filters'));
  isHardDrivingSelected = () => !!this.getFormValue('vehicleInsightTypes.hardDriving');
  isIssuesReportedSelected = () => !!this.getFormValue('containerServiceInsightTypes.issuesReported');
  isSnowRoadConditionsSelected = () => !!this.getFormValue(`${SNOW_ROAD_CONDITIONS}.showSnowRoadConditions`);
  isStreetSweeperConditionsSelected = () =>
    !!this.getFormValue(`${STREET_SWEEPER_CONDITIONS}.showStreetSweeperConditions`);

  toggleCategory = (category?: string) =>
    this.setState(prevState => ({ openedCategory: prevState.openedCategory !== category ? category : undefined }));

  openProximitySearchModal = (event: any) => {
    event.stopPropagation();
    this.setState({ isCustomerProximitySearchModalOpen: true });
  };

  closeCustomerProximitySearchModal = () => {
    this.setState({ isCustomerProximitySearchModalOpen: false });
  };

  applyLastSettings = () => {
    const { onApplyLastSettings, vendorId } = this.props;
    const persistentFilters = getPersistentFilters(vendorId);
    this.setState(
      {
        isInitialLoad: false,
        searchedVehicleIdValue: persistentFilters.selectedSearchedVehicleId,
        searchedDateValue: persistentFilters.date,
      },
      async () => {
        const shouldResetSearchField = true;
        const shouldApplyPersistentFilters = true;
        await this.onDateSelected(shouldResetSearchField, persistentFilters.date, shouldApplyPersistentFilters);
        onApplyLastSettings();
      },
    );
  };

  render() {
    const {
      close,
      filters,
      handleSubmit,
      isAutoRefreshEnabled,
      isFlagLocationActive,
      isLoadingCityInsightTypes,
      isLoadingVehicles,
      isOpen,
      isSmartCity,
      isSubMenuHidden,
      pickupExceptionTypes,
      roadConditionsEnabled,
      snowRoadConditionsEnabled,
      streetSweepingConditionsEnabled,
    } = this.props;

    const {
      isAutoRefreshDisabled,
      isCustomerProximitySearchModalOpen,
      isInitialLoad,
      isLoadingFilters,
      persistentFilters,
      searchedDateValue,
      searchedVehicleIdValue,
    } = this.state;

    const isLoading = isLoadingVehicles || isLoadingCityInsightTypes || isLoadingFilters;
    const cityFlaggingIsEnabled = !!filters.cityInsightTypes.filters.length && isSmartCity && isFlagLocationActive;
    const cityInsightsSectionIsVisible =
      cityFlaggingIsEnabled || roadConditionsEnabled || snowRoadConditionsEnabled || streetSweepingConditionsEnabled;
    const searchedVehicleId = isInitialLoad
      ? undefined
      : this.getFormValue('searchedVehicleId') || persistentFilters.selectedSearchedVehicleId;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <DashboardFilterContainer isOpen={isOpen} isLoading={isLoading} isSubMenuHidden={isSubMenuHidden}>
          <DashboardFilterPanel>
            <DashboardFilterPanelContent>
              <DashboardFilterSection>
                <DashboardFilterClose onClick={close} id="dashboard-filter-close-button">
                  <DashboardFilterCloseIcon />
                </DashboardFilterClose>
                <DashboardFilterTitleWrapper>
                  <DashboardFilterTitle>{translate('dashboard.filters')}</DashboardFilterTitle>

                  <PopoverWrapper
                    triggerButton={
                      <Text
                        color="primary"
                        cursor="pointer"
                        onClick={isEmpty(persistentFilters) ? undefined : this.applyLastSettings}
                        block
                        padding="xSmall no"
                        disabled={isEmpty(persistentFilters)}
                        align="right"
                      >
                        {translate('dashboard.applyLastSettings')}
                      </Text>
                    }
                    popoverContent={
                      isEmpty(persistentFilters) ? (
                        <Popover>{translate('dashboard.alertMessages.lastSettingsNotAvailable')}</Popover>
                      ) : undefined
                    }
                    size="medium"
                  />
                </DashboardFilterTitleWrapper>

                <DashboardFilterSubtitle>{translate('dashboard.filtersWillBeApplied')}</DashboardFilterSubtitle>

                <Field
                  name="date"
                  component={DatePicker as any}
                  placeholder={translate('common.date')}
                  disabledDays={[{ after: TODAY }]}
                  inputDefaultValue={searchedDateValue}
                  onChange={this.onDateSelected}
                  props={{ id: 'dashboard-filter-date' }}
                  validate={[isRequired, isDateValidValidator]}
                />

                <Field
                  name="searchedVehicleId"
                  component={TypeAhead as any}
                  placeholder={translate('common.search')}
                  getOptions={this.loadDashboardVehicleOptions}
                  inputDefaultValue={searchedVehicleIdValue}
                  onChange={this.onSearchChange as any}
                  isClearable
                  props={{ id: 'dashboard-filter-search' }}
                />
              </DashboardFilterSection>

              <DashboardFilterCategory
                title={translate('vehicles.vehicles')}
                isDisabled={!!searchedVehicleId}
                isOpen={this.state.openedCategory === FILTER_CATEGORIES.vehicles && !searchedVehicleId}
                category={FILTER_CATEGORIES.vehicles}
                toggleCategory={this.toggleCategory}
                id="dashboard-filter-vehicles"
              >
                <DashboardFilterList
                  title={translate('vehicles.vehicles')}
                  canCheckAll={filters.vehicles.canCheckAll}
                  checkAllFormName={filters.vehicles.checkAllFormName}
                  filters={filters.vehicles.filters}
                  updateFormValue={this.updateFormValue}
                  pickupExceptionTypes={pickupExceptionTypes}
                  isAllExceptionsChecked={this.getFormValue('allContainerIssueInsightTypes')}
                />

                <DashboardFilterList
                  title={translate('dashboard.vehicleTracking')}
                  filters={filters.vehicleTracking.filters}
                  updateFormValue={this.updateFormValue}
                />

                <Text
                  onClick={event => this.openProximitySearchModal(event)}
                  block
                  color="primary"
                  cursor="pointer"
                  margin="no sMedium sMedium sMedium"
                  size="small"
                >
                  <IconButtonIcon icon="searchVehicle" margin="no xSmall no no" />
                  {translate('customers.customerProximitySearch')}
                </Text>

                {!!isCustomerProximitySearchModalOpen && (
                  <CustomerProximitySearchModalResolver
                    onCancel={this.closeCustomerProximitySearchModal}
                    defaultDate={this.getFormValue('date')}
                    defaultDateIsSelected
                  />
                )}
              </DashboardFilterCategory>

              <DashboardFilterCategory
                title={translate('dashboard.vehicleInsights')}
                isDisabled={!!searchedVehicleId}
                isOpen={this.state.openedCategory === FILTER_CATEGORIES.vehicleInsights && !searchedVehicleId}
                category={FILTER_CATEGORIES.vehicleInsights}
                toggleCategory={this.toggleCategory}
                id="dashboard-filter-vehicle-insights"
              >
                <DashboardFilterList
                  canCheckAll={filters.vehicleInsightTypes.canCheckAll}
                  checkAllFormName={filters.vehicleInsightTypes.checkAllFormName}
                  filters={filters.vehicleInsightTypes.filters}
                  updateFormValue={this.updateFormValue}
                />
              </DashboardFilterCategory>

              <DashboardFilterCategory
                title={translate('dashboard.containerInsights')}
                isDisabled={!!searchedVehicleId}
                isOpen={this.state.openedCategory === FILTER_CATEGORIES.containerInsights && !searchedVehicleId}
                category={FILTER_CATEGORIES.containerInsights}
                toggleCategory={this.toggleCategory}
                id="dashboard-filter-container-insights"
              >
                <DashboardFilterList
                  canCheckAll={filters.containerInsightTypes.canCheckAll}
                  checkAllFormName={filters.containerInsightTypes.checkAllFormName}
                  filters={filters.containerInsightTypes.filters}
                  updateFormValue={this.updateFormValue}
                />
              </DashboardFilterCategory>

              {cityInsightsSectionIsVisible && (
                <DashboardFilterCategory
                  title={translate('dashboard.cityInsights')}
                  isDisabled={!!searchedVehicleId}
                  isOpen={this.state.openedCategory === FILTER_CATEGORIES.cityInsights && !searchedVehicleId}
                  category={FILTER_CATEGORIES.cityInsights}
                  toggleCategory={this.toggleCategory}
                  id="dashboard-filter-city-insights"
                >
                  {cityFlaggingIsEnabled && (
                    <DashboardFilterList
                      canCheckAll={filters.cityInsightTypes.canCheckAll}
                      checkAllFormName={filters.cityInsightTypes.checkAllFormName}
                      filters={filters.cityInsightTypes.filters}
                      updateFormValue={this.updateFormValue}
                    />
                  )}

                  {roadConditionsEnabled && (
                    <DashboardFilterList
                      title={translate('dashboard.roadConditions')}
                      filters={filters.roadConditions.filters}
                      updateFormValue={this.updateRoadConditions}
                    />
                  )}

                  {snowRoadConditionsEnabled && (
                    <DashboardFilterList
                      isDisabled={this.isStreetSweeperConditionsSelected()}
                      title={translate(`dashboard.${SNOW_ROAD_CONDITIONS}`)}
                      filters={filters.snowRoadConditions.filters}
                      updateFormValue={this.updateRoadConditions}
                    />
                  )}

                  {streetSweepingConditionsEnabled && (
                    <DashboardFilterList
                      isDisabled={this.isSnowRoadConditionsSelected()}
                      title={translate(`dashboard.${STREET_SWEEPER_CONDITIONS}`)}
                      filters={filters.streetSweeperConditions.filters}
                      updateFormValue={this.updateRoadConditions}
                    />
                  )}
                </DashboardFilterCategory>
              )}

              <DashboardFilterCategory
                title={translate('dashboard.vendorLocations')}
                isDisabled={!!searchedVehicleId}
                isOpen={this.state.openedCategory === FILTER_CATEGORIES.vendorLocations && !searchedVehicleId}
                category={FILTER_CATEGORIES.vendorLocations}
                toggleCategory={this.toggleCategory}
                id="dashboard-filter-vendor-locations"
              >
                <DashboardFilterList
                  canCheckAll={filters.vendorLocationTypes.canCheckAll}
                  checkAllFormName={filters.vendorLocationTypes.checkAllFormName}
                  filters={filters.vendorLocationTypes.filters}
                  updateFormValue={this.updateFormValue}
                />
              </DashboardFilterCategory>

              {isAutoRefreshEnabled && (
                <DashboardFilterSection>
                  <Field
                    name="autoRefresh"
                    component={Switch}
                    margin={'small no no no'}
                    label={translate('dashboard.autoRefresh')}
                    disabled={isAutoRefreshDisabled}
                  />
                </DashboardFilterSection>
              )}

              <DashboardFilterSection>
                <ButtonSet margin="medium no">
                  <Button
                    fluid
                    size="small"
                    color="primary"
                    id="dashboard-filter-apply-button"
                    onClick={this.applyFilters}
                  >
                    {translate('common.apply')}
                  </Button>
                  <Button
                    text
                    fluid
                    type="button"
                    size="small"
                    margin="no xSmall"
                    id="dashboard-filter-reset-button"
                    onClick={this.reset}
                  >
                    {translate('common.reset')}
                  </Button>
                </ButtonSet>
              </DashboardFilterSection>
            </DashboardFilterPanelContent>
          </DashboardFilterPanel>

          {this.state.openedCategory === FILTER_CATEGORIES.vehicles &&
            this.isAnyVehicleTypeSelected() &&
            !searchedVehicleId && (
              <DashboardFilterSubPanel>
                <DashboardFilterSearch>
                  <Field
                    name="vehicleSearchTerm"
                    component={SimpleInput}
                    type="text"
                    placeholder={translate('vehicles.searchVehicles')}
                    size="small"
                    margin="no"
                  />
                  <DashboardFilterSearchIcon />
                </DashboardFilterSearch>

                {filters.vehicles.filters.map(
                  (filter: any) =>
                    this.getFormValue(filter.formName) && (
                      <DashboardFilterList
                        secondary
                        shouldNotTranslate
                        key={filter.formName}
                        title={translate(filter.label)}
                        canCheckAll={filter.canCheckAll}
                        checkAllFormName={filter.checkAllFormName}
                        filters={filter.filters}
                        updateFormValue={this.updateFormValue}
                      />
                    ),
                )}
              </DashboardFilterSubPanel>
            )}

          {this.state.openedCategory === FILTER_CATEGORIES.vehicleInsights &&
            this.isHardDrivingSelected() &&
            !searchedVehicleId && (
              <DashboardFilterSubPanel>
                {filters.vehicleInsightTypes.filters.map(
                  (filter: any) =>
                    this.getFormValue(filter.formName) &&
                    !!filter.filters && (
                      <DashboardFilterList
                        secondary
                        key={filter.id}
                        title={translate(filter.label)}
                        canCheckAll={filter.canCheckAll}
                        checkAllFormName={filter.checkAllFormName}
                        filters={filter.filters}
                        updateFormValue={this.updateFormValue}
                      />
                    ),
                )}
              </DashboardFilterSubPanel>
            )}

          {this.state.openedCategory === FILTER_CATEGORIES.containerInsights &&
            this.isIssuesReportedSelected() &&
            !searchedVehicleId && (
              <DashboardFilterSubPanel>
                {filters.containerInsightTypes.filters.map(
                  (filter: any) =>
                    this.getFormValue(filter.formName) &&
                    !!filter.filters && (
                      <DashboardFilterList
                        secondary
                        key={filter.formName}
                        title={translate(filter.label)}
                        canCheckAll={filter.canCheckAll}
                        checkAllFormName={filter.checkAllFormName}
                        filters={filter.filters}
                        updateFormValue={this.updateFormValue}
                      />
                    ),
                )}
              </DashboardFilterSubPanel>
            )}

          {this.state.openedCategory === FILTER_CATEGORIES.cityInsights &&
            snowRoadConditionsEnabled &&
            this.isSnowRoadConditionsSelected() &&
            !searchedVehicleId && (
              <DashboardFilterSubPanel>
                {filters.snowRoadConditions.filters.map(
                  (filter: any) =>
                    this.getFormValue(filter.formName) &&
                    !!filter.filters && (
                      <DashboardFilterList
                        secondary
                        key={filter.formName}
                        title={translate(filter.label)}
                        canCheckAll={filter.canCheckAll}
                        checkAllFormName={filter.checkAllFormName}
                        filters={filter.filters}
                        updateFormValue={this.updateFormValue}
                      />
                    ),
                )}
              </DashboardFilterSubPanel>
            )}

          {this.state.openedCategory === FILTER_CATEGORIES.cityInsights &&
            streetSweepingConditionsEnabled &&
            this.isStreetSweeperConditionsSelected() &&
            !searchedVehicleId && (
              <DashboardFilterSubPanel>
                {filters.streetSweeperConditions.filters.map(
                  (filter: any) =>
                    this.getFormValue(filter.formName) &&
                    !!filter.filters && (
                      <DashboardFilterList
                        secondary
                        key={filter.formName}
                        title={translate(filter.label)}
                        canCheckAll={filter.canCheckAll}
                        checkAllFormName={filter.checkAllFormName}
                        filters={filter.filters}
                        updateFormValue={this.updateFormValue}
                      />
                    ),
                )}
              </DashboardFilterSubPanel>
            )}
        </DashboardFilterContainer>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, { location: { search } }: ComponentProps) => {
  const { isLoadingVehicles, isLoadingCityInsightTypes } = state.dashboard.filters;
  const vendorId = (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor) as number;
  const initialValues = (getInitialFilterFormValues as any)(
    state.dashboard.initialFilterFormValues.initialFilterFormValues,
    search,
  );

  const formValues = getFormValues(DASHBOARD_FILTER_FORM_NAME)(state);
  const filters = filtersByVehicleSearchTermSelector(state.dashboard.filters.filters, formValues);

  const isAutoRefreshEnabled = autoRefreshStatusSelector(state.vendors.features.features);
  const selectedVehicleTypeIds = (vehicleIdsByTechnicalNamesSelector as any)(
    state.fleet.vehicleTypesForVendor,
    reduce(
      get(formValues, 'vehicleTypes.filters'),
      (array, value, key) => {
        const newArray = array;
        if (value) {
          newArray.push(key);
        }
        return newArray;
      },
      [] as any,
    ),
  );

  const { defaultVendor } = state.vendors as any;

  return {
    initialValues,
    formValues,
    filters,
    vendorId,
    isLoadingVehicles,
    isAutoRefreshEnabled,
    isLoadingCityInsightTypes,
    selectedVehicleTypeIds,
    isFlagLocationActive: flagLocationStatusSelector(state.vendors.features.features),
    isSmartCity: isSmartCitySelector(state.account.login, state.vendors.defaultVendor),
    defaultVendorId:
      defaultVendor || defaultVendor.defaultVendor || defaultVendor.defaultVendor.id
        ? vendorId
        : defaultVendor.defaultVendor.id,
    pickupExceptionTypes: state.dashboard.filters.pickupExceptionTypes,
    isGeoFenceActive: checkIfGeoFenceIsEnabled(state),
    roadConditionsEnabled: checkIfRoadConditionsAreEnabled(state),
    snowRoadConditionsEnabled: checkIfSnowRoadConditionsAreEnabled(state),
    streetSweepingConditionsEnabled: checkIfStreetSweepingConditionsAreEnabled(state),
    filtersPreferences: state.common.filters.filters,
    initialFilterFormValues: state.dashboard.initialFilterFormValues.initialFilterFormValues,
  };
};

const mapDispatchToProps = {
  loadVehicles,
  loadCityInsightTypes,
  loadPickupExceptionTypes,
  loadGeoFenceFilterOptions,
  updateInitialFilterFormValues,
  filterPickupExceptionTypesByVehicle,
  change,
  submit,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: DASHBOARD_FILTER_FORM_NAME,
      enableReinitialize: true,
    })(DashboardFilterForm),
  ),
);
