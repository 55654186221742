import React from 'react';
import { change, getFormValues } from 'redux-form';
import { map } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { DRIVER_SAFETY_FORM, DRIVER_SAFETY_OPTIONS, NONE_ID } from 'src/vendors/constants/driverSafety';
import { Dropdown, TypedField, Switch } from '../../../core/components';
import { TableCell, TableRow } from '../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { useSelector } from 'src/core/hooks/useSelector';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  driverSafetyDataCaptureType: TechnicalType;
  driverSafetySettingType: TechnicalType;
  index: number;
  isAudioAlertActive?: boolean;
  isVisualAlertActive?: boolean;
}

const DriverSafetyFormTableRow: React.FC<Props> = ({
  driverSafetyDataCaptureType,
  driverSafetySettingType,
  index,
  isAudioAlertActive,
  isVisualAlertActive,
}) => {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues(DRIVER_SAFETY_FORM)) as any[];

  const driverSafetyOptions = map(DRIVER_SAFETY_OPTIONS, option => ({
    value: option.id,
    label: option.name,
  }));

  const onEventActiveChange = (_: any, isChecked: true) => {
    if (!isChecked) {
      dispatch(change(DRIVER_SAFETY_FORM, `${index}.isAudioAlertActive`, isChecked));
      dispatch(change(DRIVER_SAFETY_FORM, `${index}.isVisualAlertActive`, isChecked));
      dispatch(change(DRIVER_SAFETY_FORM, `${index}.driverSafetyDataCaptureType.id`, NONE_ID));
    }
  };

  return (
    <TableRow>
      <TableCell width="20%">
        {translate(createTranslationKey(driverSafetySettingType.technicalName, 'vendors.driverSafety.title'))}
      </TableCell>
      <TableCell width="30%">
        {translate(createTranslationKey(driverSafetySettingType.technicalName, 'vendors.driverSafety.description'))}
      </TableCell>
      <TableCell width="9%" align="center">
        <TypedField name={`${index}.isEventActive`} component={Switch} onChange={onEventActiveChange} />
      </TableCell>
      <TableCell width="13%" align="center">
        {isAudioAlertActive != null && (
          <TypedField
            name={`${index}.isAudioAlertActive`}
            component={Switch}
            props={{
              disabled: !formValues[index].isEventActive,
            }}
          />
        )}
      </TableCell>
      <TableCell width="13%" align="center">
        {isVisualAlertActive != null && (
          <TypedField
            name={`${index}.isVisualAlertActive`}
            component={Switch}
            props={{
              disabled: !formValues[index].isEventActive,
            }}
          />
        )}
      </TableCell>
      <TableCell width="15%" align="center">
        {driverSafetyDataCaptureType != null && (
          <TypedField
            name={`${index}.driverSafetyDataCaptureType.id`}
            component={Dropdown}
            props={{
              margin: 'no',
              options: driverSafetyOptions,
              width: '100%',
              disabled: !formValues[index].isEventActive,
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default DriverSafetyFormTableRow;
