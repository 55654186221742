import { useDispatch } from 'react-redux';

import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { DriverPickListDefaults } from 'src/vendors/interfaces/DriverPickListDefaults';
import { DriverPickListDefaultsForm } from '../forms';
import { Modal } from '../../../core/components';
import { ModalProps } from 'src/vendors/interfaces/ModalProps';
import { saveDriverPickListDefaults } from 'src/vendors/ducks';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { useSelector } from 'src/core/hooks/useSelector';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

const driverPickListDefaultModalId = 'driver-pick-list-defaults-modal';

interface Props extends ModalProps {}

const DriverPickListDefaultsModal: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();

  const { driverPickListDefaults, isLoading, isSaving } = useSelector(state => state.vendors.driverPickListDefaults);
  const { pickupTypes } = useSelector(state => state.routes.pickupTypes);

  const onSubmit = (formData: DriverPickListDefaults[]) => {
    scrollToTopOfModal(driverPickListDefaultModalId);

    const newFormData = formData.map(driverPickList => {
      return {
        vehicleTypeId: driverPickList.vehicleTypeId,
        pickupTypeIds:
          !!driverPickList.pickupTypeIds && !!driverPickList.pickupTypeIds.length ? driverPickList.pickupTypeIds : [],
      };
    });

    saveDriverPickListDefaults(newFormData)(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.driverPickListDefaultsSaved'));
        closeModal(true);
      })
      .catch(({ code }) =>
        createErrorNotification(
          code
            ? translate(createTranslationKey(code, 'vendors.alertMessages'))
            : translate('vendors.alertMessages.driverPickListDefaultsSaveError'),
        ),
      );
  };

  const initialValues = pickupTypes?.map(pickupType => {
    return {
      vehicleTypeId: pickupType.vehicleTypeId,
      pickupTypeIds:
        driverPickListDefaults.find(driverPickList => driverPickList.vehicleTypeId === pickupType.vehicleTypeId)
          ?.pickupTypeIds || [],
    };
  });

  return (
    <Modal
      title={translate('vendors.featureCodes.driverPickListDefaults')}
      isLoading={isLoading || isSaving}
      size="smallMedium"
      padding="medium no no"
      id={driverPickListDefaultModalId}
      overflow={isSaving ? 'hidden' : 'auto'}
    >
      <DriverPickListDefaultsForm closeModal={closeModal} onSubmit={onSubmit} initialValues={initialValues} />
    </Modal>
  );
};

export default DriverPickListDefaultsModal;
