import React, { PureComponent } from 'react';

import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';

import { InspectionPreviewImageContainer } from '../styled/VehicleInspections';

interface Props {
  imageUrl: string;
  closeModal: () => void;
}

class InspectionPreviewImageModal extends PureComponent<Props> {
  render() {
    const { closeModal, imageUrl } = this.props;

    return (
      <Modal onClose={closeModal} padding="medium" size="medium">
        <ModalSection align="center">
          <InspectionPreviewImageContainer src={imageUrl} />
        </ModalSection>
      </Modal>
    );
  }
}

export default InspectionPreviewImageModal;
