import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { AppState } from 'src/store';
import { GetStateFunction } from '../../contracts/ducks';
import {
  getFacility as doLoadFacility,
  postFacility as doPostFacility,
  putFacility as doPutFacility,
} from '../services/facilities';
import {
  transformGeoJsonSaveFacility,
  transformLoadGeoJsonFacility,
  transformSaveFacility,
} from '../services/transformFacilities';

// Actions
const START_LOAD = 'fleet/facility/START_LOAD';
const COMPLETE_LOAD = 'fleet/facility/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/facility/FAIL_LOAD';
const RESET = 'fleet/facility/RESET';
const START_SAVE = 'fleet/facility/START_SAVE';
const COMPLETE_SAVE = 'fleet/facility/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/facility/FAIL_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  facility: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case COMPLETE_SAVE:
      return {
        ...state,
        isSaving: false,
        facility: action.facility,
      };
    case FAIL_SAVE:
      return {
        ...state,
        isSaving: false,
      };
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          facility: action.facility,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          facility: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (facility: any) => ({
  type: COMPLETE_SAVE,
  facility,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (facility: any) => ({
  type: COMPLETE_LOAD,
  facility,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

// Async actions

export const saveFacility = (facility: any) => (dispatch: Dispatch, getState: GetStateFunction) => {
  dispatch(startSave());
  const state = getState().fleet;
  const facilityTypeOptions = state.facilityTypes.facilityTypes;
  const facilityType = facilityTypeOptions.find((value: any) => value.id === facility.facilityType);
  const facilitySubTypeOptions = state.facilitySubTypes.facilitySubTypes;
  const facilitySubType = facilitySubTypeOptions.find((value: any) => value.id === facility.facilitySubType);
  let geoFenceJson = null;
  if (facility.geoFenceJson) {
    geoFenceJson = transformGeoJsonSaveFacility(facility.geoFenceJson);
  }

  const transformFacility = {
    ...transformSaveFacility,
    ...facility,
    facilityType,
    facilitySubType,
    geoFenceJson,
  };
  const saveFacilityPromise = facility.id ? doPutFacility(transformFacility) : doPostFacility(transformFacility);
  saveFacilityPromise
    .then(data => {
      let getCoord = null;
      if (data.geoFenceJson) {
        getCoord = transformLoadGeoJsonFacility(data.geoFenceJson);
      }
      const transformFacility = {
        ...data,
        address: {
          ...data.address,
          formattedAddress: data.address.line1,
        },
        geoFenceJson: getCoord,
      };
      dispatch(completeSave(transformFacility));
    })
    .catch(() => dispatch(failSave()));
  return saveFacilityPromise;
};

export const loadFacility = (facilityId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadFacilityPromise = doLoadFacility(facilityId);
  loadFacilityPromise
    .then(facility => {
      let getCoord = null;
      if (facility.geoFenceJson) {
        getCoord = transformLoadGeoJsonFacility(facility.geoFenceJson);
      }
      const transformFacility = {
        ...facility,
        address: {
          ...facility.address,
          formattedAddress: facility.address.line1,
        },
        geoFenceJson: getCoord,
      };
      dispatch(completeLoad(transformFacility));
    })
    .catch(() => dispatch(failLoad()));
  return loadFacilityPromise;
};

export const resetFacility = () => ({
  type: RESET,
});

const getFacility = (FacilityState: any) => get(FacilityState, 'facility', {});

// Selectors

export const vehicleTypesForVendorSelector = (state: AppState) =>
  state.fleet.vehicleTypesForVendor.vehicleTypesForVendor;

export const facilityDetailsSelector = createSelector(getFacility, identity);
