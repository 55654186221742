import React from 'react';
import { connect } from 'react-redux';

import { isVendorSelector } from '../ducks';
import { AppState } from '../../store';

interface Props {
  isVendor?: boolean;
}

const VendorGuard: React.FC<Props> = ({ isVendor, children }) => {
  if (isVendor && children) {
    return <>{children}</>;
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  isVendor: isVendorSelector(state.account.login),
});

export default connect(mapStateToProps)(VendorGuard);
