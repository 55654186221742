import ReactPlayer from 'react-player';
import moment from 'moment';

import { ActionButtonTooltip, Modal } from 'src/core/components';
import { AppState } from 'src/store';
import { dateFormat, timeFormat } from 'src/utils/services/validator';
import { Grid, GridColumn, TableActionButton, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import useDownloadFileWithProgress from '../hooks/useDownloadFIleWithProgress';
import ProgressDoughnut from './ProgressDoughnut';

interface Props {
  closeModal: () => void;
}

const VideoMessageModal = ({ closeModal }: Props) => {
  const {
    isLoading,
    videoDownloadDetails: {
      date,
      driverFirstName,
      driverLastName,
      expirationDate,
      fileUrl,
      route,
      timeStampEnd,
      timeStampStart,
      vehicle,
    },
  } = useSelector((state: AppState) => state.routes.videoRequest);

  const { download, progress, isDownloading } = useDownloadFileWithProgress();

  const fileName = `${vehicle}: ${timeStampStart} - ${timeStampEnd}`;

  const downloadVideo = (downloadUrl: string) => {
    download(downloadUrl, `${fileName}.mp4`);
  };

  return (
    <Modal
      title={translate('routes.videoRequest.videoFootage')}
      onClose={closeModal}
      padding="medium"
      size="smallMedium"
      isLoading={isLoading}
    >
      <Grid margin="sMedium no no no">
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('common.vehicle')}
          </Text>
          <Text block margin="xxSmall no no no">
            {vehicle || '-'}
          </Text>
        </GridColumn>
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('common.driver')}
          </Text>
          <Text block margin="xxSmall no no no">
            {driverFirstName ? `${driverFirstName} ${driverLastName}` : '-'}
          </Text>
        </GridColumn>
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('common.route')}
          </Text>
          <Text block margin="xxSmall no no no">
            {route || '-'}
          </Text>
        </GridColumn>
      </Grid>

      <Grid margin="small no no no">
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('common.date')}
          </Text>
          <Text block margin="xxSmall no no no">
            {date ? moment(date).format(dateFormat) : '-'}
          </Text>
        </GridColumn>
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('routes.videoRequest.timeStart')}
          </Text>
          <Text block margin="xxSmall no no no">
            {timeStampStart ? moment(timeStampStart).format(timeFormat) : '-'}
          </Text>
        </GridColumn>
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('routes.videoRequest.timeEnd')}
          </Text>
          <Text block margin="xxSmall no no no">
            {timeStampEnd ? moment(timeStampEnd).format(timeFormat) : '-'}
          </Text>
        </GridColumn>
      </Grid>

      <Grid margin="small no sMedium no">
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('routes.videoRequest.expirationDate')}
          </Text>
          <Text block margin="xxSmall no no no">
            {expirationDate ? moment(expirationDate).format(dateFormat) : '-'}
          </Text>
        </GridColumn>
        <GridColumn size="4/12">
          <Text block weight="medium" size="large">
            {translate('common.download')}
          </Text>
          <TableActionButton
            onClick={() => (isDownloading ? () => {} : downloadVideo(fileUrl))}
            margin="xxSmall no no no"
          >
            {isDownloading ? (
              <ProgressDoughnut progress={progress.percent} size={35} />
            ) : (
              <ActionButtonTooltip size="sMedium" icon="download" tooltip="downloadVideo" />
            )}
          </TableActionButton>
        </GridColumn>
      </Grid>
      <ReactPlayer url={fileUrl} controls={true} />
    </Modal>
  );
};

export default VideoMessageModal;
