import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';

import { AppState } from 'src/store';
import { Container, ButtonSet, Button } from '../../../core/components/styled';
import { Dropdown, TextArea, DatePicker } from '../../../core/components';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import openDispatchesFormInitialValueSelector from '../../services/openDispatchesFormInitialValueSelector';
import translate from '../../../core/services/translate';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

type PropsWithoutReduxForm = {
  rubiconPONbr: string;
  requestedServiceDate?: Date | string;
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  reasonCodes?: ReasonCodeGroups;
  serviceDate?: Date | string;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

class RequestDateChangeForOpenDispatchesForm extends PureComponent<Props> {
  render() {
    const { requestedServiceDate, rubiconPONbr, serviceDate, handleSubmit, onCancel, pristine, reasonCodes } =
      this.props;
    const reasonCodesOptions =
      reasonCodes && reasonCodes.requestDateChange ? reasonCodes.requestDateChange : reasonCodes;
    const today = moment().format('MM/DD/YYYY');

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <Field
            name={`opportunities.settings${rubiconPONbr}${serviceDate}.completedDate`}
            component={DatePicker}
            label={translate('common.date')}
            disabledDays={[
              {
                before: moment(requestedServiceDate).isAfter(today)
                  ? moment(requestedServiceDate, 'MM/DD/YYYY').subtract(1, 'days').toDate()
                  : moment(requestedServiceDate, 'MM/DD/YYYY').toDate(),
                after: moment(requestedServiceDate, 'MM/DD/YYYY').add(14, 'days').toDate(),
              },
            ]}
            margin="large small small"
            validate={[isRequired, isDateValidValidator]}
            isTodayWithNoStyle
          />
        </Container>
        <Container>
          <Field
            name={`opportunities.settings${rubiconPONbr}${serviceDate}.reasonCode`}
            component={Dropdown}
            options={reasonCodesOptions}
            validate={[isRequired]}
            label={translate('opportunity.selectReasonType')}
            margin="medium small small"
          />
        </Container>
        <Container>
          <Field
            name={`opportunities.settings${rubiconPONbr}${serviceDate}.note`}
            component={TextArea}
            rows={2}
            label={translate('routes.addRouteNote')}
            size="small"
            validate={[isRequired]}
            margin="medium small large"
          />
        </Container>
        <UseIsMobileWidthView
          render={isMobile => (
            <ButtonSet margin={isMobile ? 'no' : 'large small large'}>
              <Button type="submit" color="primary">
                {translate('opportunity.submit')}
              </Button>
              <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          )}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: openDispatchesFormInitialValueSelector(
    state.fleet.openDispatches.opportunities,
    state.fleet.rubiconDispatches.rubiconDispatches,
  ),
});

export default connect(mapStateToProps)(
  reduxForm<{}, PropsWithoutReduxForm>({
    form: 'requestDateChangeForOpenDispatches',
    enableReinitialize: true,
  })(RequestDateChangeForOpenDispatchesForm),
);
