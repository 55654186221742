import { DELIVERY_UTILITY_ID, ROLL_OFF_ID, TOTER_ID } from 'src/fleet/constants';
import { PICKUP_TYPE_EMPTY_AND_RETURN_ID, PICKUP_TYPE_PICKUP_ID, PICKUP_TYPE_SERVICE_ID } from 'src/routes/constants';
import { TableCell } from 'src/core/components/Table';
import { UnconnectedCheckbox } from 'src/core/components';
import translate from 'src/core/services/translate';

const ROUTE_STOPS_TABLE_CELLS_WIDTHS_TOTER = ['8%', '24%', '14%', '12%', '12%', '15%', '15%'];
const ROUTE_STOPS_TABLE_CELLS_WIDTHS = ['8%', '32%', '15%', '15%', undefined, '15%', '15%'];

export const getRouteStopsTableCellWidths = (vehicleTypeId: number) =>
  vehicleTypeId === TOTER_ID ? ROUTE_STOPS_TABLE_CELLS_WIDTHS_TOTER : ROUTE_STOPS_TABLE_CELLS_WIDTHS;

export const getRouteStopsTableCells = (vehicleTypeId: number) => {
  const tableCellWidths = getRouteStopsTableCellWidths(vehicleTypeId);

  const routeStopsTableCells: TableCell[] = [
    { name: 'orderNo', label: translate('routes.stopNumber'), width: tableCellWidths[0], sortable: true },
    { name: 'customerName', label: translate('common.customer'), width: tableCellWidths[1], sortable: true },
    { name: 'equipmentSizeId', label: translate('common.service'), width: tableCellWidths[2], sortable: true },
    {
      name: 'pickupTypeId',
      label: translate('routes.pickupInfo'),
      width: tableCellWidths[3],
      sortable: true,
      padding: 'defaultCellVertical no',
    },
    { name: 'pickupStatusTypeId', label: translate('common.status'), width: tableCellWidths[5], sortable: true },
    { name: 'events', label: translate('common.events'), width: tableCellWidths[6], align: 'right' },
  ];

  if (vehicleTypeId === TOTER_ID) {
    routeStopsTableCells.splice(4, 0, {
      name: 'serviceCount',
      label: translate('routes.serviceCount'),
      width: tableCellWidths[4],
    });
  }
  return routeStopsTableCells;
};

const EDIT_ROUTE_STOPS_TABLE_CELLS_WIDTHS = ['3%', '5%', '7.5%', '17%', '12%', '12%', '15%', '15%', '13%'];

export const getEditRouteStopsTableCellWidths = () => EDIT_ROUTE_STOPS_TABLE_CELLS_WIDTHS;

export const getEditRouteStopsTableCells = (
  selectedStopsCount: number,
  routesCount: number,
  toggleSelectAllStops: () => void,
  newOptimizedRouteLocationsLength: number,
) => {
  const tableCellWidths = getEditRouteStopsTableCellWidths();

  return [
    {
      name: 'selectAll',
      width: tableCellWidths[0],
      component: !newOptimizedRouteLocationsLength ? UnconnectedCheckbox : undefined,
      componentProps: {
        onChange: toggleSelectAllStops,
        checked: selectedStopsCount === routesCount,
        partial: selectedStopsCount > 0 && selectedStopsCount < routesCount,
      },
    },
    { name: 'dragHandle', width: tableCellWidths[1] },
    { name: 'stopNumber', label: translate('routes.stopNumber'), width: tableCellWidths[2] },
    { name: 'customer', label: translate('common.customer'), width: tableCellWidths[3] },
    { name: 'service', label: translate('common.service'), width: tableCellWidths[4] },
    { name: 'accountStatus', label: translate('routes.accountStatus'), width: tableCellWidths[5] },
    { name: 'pickupInfo', label: translate('routes.pickupInfo'), width: tableCellWidths[6] },
    { name: 'jobStatus', label: translate('common.status'), width: tableCellWidths[7] },
    { name: 'options', label: translate('common.options'), width: tableCellWidths[8] },
  ];
};

export const getSnowPlowRouteStopsTableCellWidths = () => ['10%', '38%', '21%', '18%', '13%'];

export const getInitialPickupTypeId = (vehicleTypeId: number) => {
  switch (vehicleTypeId) {
    case ROLL_OFF_ID:
      return PICKUP_TYPE_EMPTY_AND_RETURN_ID;
    case DELIVERY_UTILITY_ID:
      return PICKUP_TYPE_SERVICE_ID;
    default:
      return PICKUP_TYPE_PICKUP_ID;
  }
};

export const formatStopAddress = (
  locationName: string,
  streetName?: string,
  streetNumber?: string,
  vendorAccountNo?: number,
) => {
  let stopAddress = locationName;
  if (streetNumber) stopAddress = stopAddress?.concat(', ', streetNumber);
  if (streetName) stopAddress = stopAddress?.concat(streetNumber ? ' ' : ', ', streetName);
  if (vendorAccountNo) stopAddress = stopAddress?.concat(' ', `(${vendorAccountNo})`);

  return stopAddress;
};
