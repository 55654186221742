import { map } from 'lodash-es';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

const transformContainerTypes = (containerTypes: TechnicalType[]) =>
  map(containerTypes, ({ id, technicalName }) => ({
    id: id,
    name: translate(createTranslationKey(technicalName, 'finance.containerTypes')),
    technicalName: technicalName,
  }));

export default transformContainerTypes;
