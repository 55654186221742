import React, { useMemo } from 'react';
import { push } from 'connected-react-router';
import { change, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { debounce, find, groupBy, keys, map } from 'lodash-es';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';

import { Dropdown, PanelSearch, TypedField } from '../../../core/components';
import { PanelSection, Grid, GridColumn, Panel } from 'src/core/components/styled';
import { EquipmentConditionDropdown, EquipmentSizeDropdown } from 'src/common/components';
import containersNewFormInitialValuesSelector from 'src/fleet/services/containersNewInitialFormValueSelector';
import { CONTAINER_LOCATION_TYPES } from 'src/common/constants';
import { createUrl } from 'src/utils/services/queryParams';
import { useSelector } from 'src/core/hooks/useSelector';
import { AppState } from 'src/store';
import translate from 'src/core/services/translate';

export const CONTAINERS_FILTERS_FORM = 'containersFiltersForm';

export interface ContainersFiltersFormValues {
  searchTerm?: string;
  containerTypeId?: number;
  locationId?: number;
  equipmentTypeId?: number;
  equipmentSizeId?: number;
  equipmentConditionId?: number;
}

interface PropsWithoutReduxForm extends RouteComponentProps {
  change?: any;
  formValues?: ContainersFiltersFormValues;
  initialValues?: ContainersFiltersFormValues;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<ContainersFiltersFormValues, PropsWithoutReduxForm>;

const ContainersFormNew: React.FC<Props> = ({ handleSubmit, formValues, location: { pathname, search } }) => {
  const containerTypes = useSelector(state => state.common.containerTypes.containerTypes || []);
  const locations = useSelector(state => state.fleet.containersStatistics.containersStatistics?.locations || []);
  const dispatch = useDispatch();

  const containerTypeOptions = map(containerTypes, type => ({ label: type.name, value: type.id }));

  const containerSubType = useMemo(
    () =>
      map(find(containerTypes, ct => ct.id === formValues?.containerTypeId)?.subTypes || [], type => ({
        label: type.name,
        value: type.id,
      })),
    [containerTypes, formValues],
  );

  const groupedLocations = groupBy(locations, l => l.t);
  const containerLocationOptions = map(keys(groupedLocations), locKey => ({
    label: CONTAINER_LOCATION_TYPES[locKey].name,
    options: map(groupedLocations[locKey], ({ lid, n }) => ({ value: lid, label: n })),
  }));

  const debouncedCallback = useMemo(
    () =>
      debounce((term?: string) => {
        dispatch(push(createUrl(pathname, search, { searchTerm: term })));
      }, 470),
    [dispatch, pathname, search],
  );

  const setSearchTermDebounced = React.useCallback(debouncedCallback, [debouncedCallback]);

  const setContainerTypeId = (containerTypeId?: number) => {
    dispatch(
      push(createUrl(pathname, search, { containerTypeId, locationId: '', equipmentTypeId: '', equipmentSizeId: '' })),
    );
  };

  const setLocationId = (locationId?: number) => {
    dispatch(push(createUrl(pathname, search, { locationId })));
  };

  const setEquipmentTypeId = (equipmentTypeId?: number) => {
    dispatch(push(createUrl(pathname, search, { equipmentTypeId, equipmentSizeId: '' })));
  };

  const setEquipmentSizeId = (equipmentSizeId?: number) => {
    dispatch(push(createUrl(pathname, search, { equipmentSizeId })));
  };

  const setEquipmentConditionId = (equipmentConditionId?: number) => {
    dispatch(push(createUrl(pathname, search, { equipmentConditionId })));
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid>
        <GridColumn size="2/12">
          <TypedField
            component={Dropdown}
            name="containerTypeId"
            props={{ options: containerTypeOptions, placeholder: translate('containers.selectContainers') }}
            onChange={(_, newValue: ContainersFiltersFormValues['containerTypeId']) => setContainerTypeId(newValue)}
          />
        </GridColumn>
      </Grid>
      <Panel>
        <PanelSection padding="small xSmall no" withBorder>
          <Grid>
            <GridColumn size="4/12" borderRight>
              <TypedField
                name="searchTerm"
                component={PanelSearch}
                props={{ placeholder: translate('common.search'), isClearable: true }}
                onChange={(_, newValue: ContainersFiltersFormValues['searchTerm']) => setSearchTermDebounced(newValue)}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <TypedField
                component={Dropdown}
                name="locationId"
                props={{
                  options: containerLocationOptions as any,
                  placeholder: translate('customers.allLocations'),
                  isClearable: true,
                }}
                onChange={(_, newValue: ContainersFiltersFormValues['locationId']) => setLocationId(newValue)}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <TypedField
                component={Dropdown}
                name="equipmentTypeId"
                props={{
                  options: containerSubType,
                  placeholder: translate('containers.allTypes'),
                  isClearable: true,
                }}
                onChange={(_, newValue: ContainersFiltersFormValues['containerTypeId']) => setEquipmentTypeId(newValue)}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <TypedField
                component={EquipmentSizeDropdown}
                name="equipmentSizeId"
                props={{
                  equipmentTypeId: formValues?.equipmentTypeId,
                  dropdownProps: {
                    placeholder: translate('containers.allSizes'),
                    isClearable: true,
                    disabled: !formValues?.equipmentTypeId,
                  },
                }}
                onChange={(_, newValue: ContainersFiltersFormValues['equipmentSizeId']) => setEquipmentSizeId(newValue)}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <TypedField
                component={EquipmentConditionDropdown}
                name="equipmentConditionId"
                props={{
                  placeholder: translate('containers.allConditions'),
                  dropdownProps: {
                    isClearable: true,
                  },
                }}
                onChange={(_, newValue: ContainersFiltersFormValues['equipmentConditionId']) =>
                  setEquipmentConditionId(newValue)
                }
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </Panel>
    </form>
  );
};

const selector = getFormValues('containersFormNew');

const mapStateToProps = (state: AppState, ownProps: PropsWithoutReduxForm) => {
  const initialValues = containersNewFormInitialValuesSelector(ownProps.location.search);
  return {
    vehicleTypes: state.fleet.vehicleTypes.vehicleTypes,
    vehicleTypesByVendors: state.fleet.vehicleTypesForVendor.vehicleTypesForVendor,
    formValues: selector(state) || initialValues,
    initialValues,
  };
};

const mapDispatchToProps = { change };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<ContainersFiltersFormValues, PropsWithoutReduxForm>({
      enableReinitialize: true,
      form: CONTAINERS_FILTERS_FORM,
    })(ContainersFormNew),
  ),
);
