import React from 'react';
import { Layer } from 'react-map-gl';

import { DASHBOARD_CITY_INSIGHTS_LAYER, DASHBOARD_CLUSTERS_SOURCE } from 'src/dashboard/constants/dashboardMapGL';

const DashboardCityInsightsGLLayers: React.FC = React.memo(() => (
  <Layer
    id={DASHBOARD_CITY_INSIGHTS_LAYER}
    type="symbol"
    source={DASHBOARD_CLUSTERS_SOURCE}
    filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], DASHBOARD_CITY_INSIGHTS_LAYER]]}
    paint={{}}
    layout={{
      'icon-image': ['get', 'icon'],
      'icon-size': 1,
      'icon-allow-overlap': true,
    }}
  />
));

export default DashboardCityInsightsGLLayers;
