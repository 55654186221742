import { AnyAction } from 'redux';

import update from 'immutability-helper';
import { ThunkDispatch } from 'redux-thunk';

import { FullStory } from '../../fullstory';
import { VENDOR_TYPES } from '../constants';
import { Vendor } from '../interfaces/Vendors';
import {
  deleteDefaultVendor as doDeleteDefaultVendor,
  getDefaultVendor,
  setDefaultVendor as doSetDefaultVendor,
} from '../services/defaultVendor';
import { loadVendor as doLoadVendor } from '../services/vendor';

// Actions
const START_SET_DEFAULT_VENDOR = 'vendors/defaultVendor/START_SET_DEFAULT_VENDOR';
const COMPLETE_SET_DEFAULT_VENDOR = 'vendors/defaultVendor/COMPLETE_SET_DEFAULT_VENDOR';
const FAIL_SET_DEFAULT_VENDOR = 'vendors/defaultVendor/FAIL_SET_DEFAULT_VENDOR';
const COMPLETE_DELETE_DEFAULT_VENDOR = 'vendors/defaultVendor/COMPLETE_DELETE_DEFAULT_VENDOR';
const RESET = 'vendors/defaultVendor/RESET';

export interface DefaultVendorState {
  isSettingDefaultVendor: boolean;
  defaultVendor: Vendor;
}

type Dispatch = ThunkDispatch<DefaultVendorState, any, AnyAction>;

const defaultVendor = getDefaultVendor() as Vendor;
if (defaultVendor) {
  FullStory.setVendorVars(defaultVendor);
  window.Rubicon.vendor.setVendorInfo({
    vendorId: defaultVendor.id,
    vendorName: defaultVendor.name,
    vendorTypeId: defaultVendor.vendorTypeId,
    vendorType: VENDOR_TYPES[defaultVendor.vendorTypeId].technicalName,
  });
}

// Initial state
const initialState = {
  isSettingDefaultVendor: false,
  defaultVendor: getDefaultVendor(),
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_SET_DEFAULT_VENDOR:
      return update(state, {
        $merge: {
          isSettingDefaultVendor: true,
        },
      });

    case COMPLETE_SET_DEFAULT_VENDOR:
      return update(state, {
        $merge: {
          isSettingDefaultVendor: false,
          defaultVendor: action.defaultVendor,
        },
      });

    case FAIL_SET_DEFAULT_VENDOR:
      return update(state, {
        $merge: initialState,
      });

    case COMPLETE_DELETE_DEFAULT_VENDOR:
      return update(state, {
        $merge: {
          defaultVendor: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startSetDefaultVendor = () => ({
  type: START_SET_DEFAULT_VENDOR,
});

const completeSetDefaultVendor = (defaultVendor: Vendor) => ({
  type: COMPLETE_SET_DEFAULT_VENDOR,
  defaultVendor,
});

const failSetDefaultVendor = () => ({
  type: FAIL_SET_DEFAULT_VENDOR,
});

const completeDeleteDefaultVendor = () => ({
  type: COMPLETE_DELETE_DEFAULT_VENDOR,
});

export const setDefaultVendor = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startSetDefaultVendor());
  return doLoadVendor(vendorId)
    .then(vendor => {
      FullStory.setVendorVars(vendor);
      window.Rubicon.vendor.setVendorInfo({
        vendorId: vendor.id,
        vendorName: vendor.name,
        vendorTypeId: vendor.vendorTypeId,
        vendorType: VENDOR_TYPES[vendor.vendorTypeId].technicalName,
      });
      doSetDefaultVendor(vendor);
      dispatch(completeSetDefaultVendor(vendor));
      return vendor;
    })
    .catch(() => dispatch(failSetDefaultVendor()));
};

export const deleteDefaultVendor = () => (dispatch: Dispatch) => {
  doDeleteDefaultVendor();
  dispatch(completeDeleteDefaultVendor());
};

export const resetDefaultVendor = () => ({
  type: RESET,
});
