import { size } from 'lodash-es';
import { useMemo } from 'react';

import { AlternativeFleetStreetSegment } from 'src/dashboard/interfaces/alterativeFleetOps';
import { Box } from 'src/core/components/styled/Box';
import { COMPLETED, SCHEDULED } from 'src/routes/constants';
import { dateAndTime } from 'src/utils/services/formatter';
import { getTypesAsString } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapRouteSegmentsGLPopup';
import { Grid, GridColumn, Label, Separator, Text } from 'src/core/components/styled';
import { MapGLPopup, MapGLPopupContent, MapGLPopupTitle } from 'src/common/components/map/MapGLPopup';
import { SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES } from 'src/common/constants';
import { STREET_SEGMENT_SERVICE_SIDES } from 'src/customers/constants/streetNetwork';
import { useSelector } from 'src/core/hooks/useSelector';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  segment: AlternativeFleetStreetSegment;
  isSnowPlow?: boolean;
}

export default function DashboardSnowSweeperStreetSegmentsGLPopup({ segment, isSnowPlow }: Props) {
  const routeSegment = useSelector(state => state.customers.streetNetwork.streetNetworkSegmentDetails);

  const statusColor = useMemo(() => {
    if (!routeSegment || !routeSegment.pickupStatusTypeId || routeSegment.pickupStatusTypeId === SCHEDULED)
      return 'info';

    if (routeSegment.pickupStatusTypeId === COMPLETED) return 'success';

    return 'warning';
  }, [routeSegment]);

  if (!routeSegment) return null;

  const serviceSideTypeId = STREET_SEGMENT_SERVICE_SIDES.filter(side => side.id === routeSegment.serviceSideTypeId);

  const forwardPasses = routeSegment.streetSegmentActivitySettings[0].forwardPasses;
  const reversePasses = routeSegment.streetSegmentActivitySettings[0].reversePasses;

  return (
    <MapGLPopup minHeight={20}>
      <MapGLPopupTitle
        title={routeSegment?.streetName ? routeSegment?.streetName : routeSegment?.name}
        secondTitle={
          <Box alignItems="center" display="flex" margin="small no">
            <Label color={routeSegment.isActive ? 'success' : 'info'}>
              {routeSegment.isActive
                ? translate('customers.streetNetwork.active')
                : translate('customers.streetNetwork.inactive')}
            </Label>
            <Text padding="no xSmall">/</Text>
            <Label color={statusColor}>
              {SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES[routeSegment.pickupStatusTypeId]?.name}
            </Label>
          </Box>
        }
      />
      <MapGLPopupContent>
        <Grid multiLine margin="xSmall no no no">
          {!!routeSegment.streetId && (
            <GridColumn size="12/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.streetID')}
                value={routeSegment.streetId}
              />
            </GridColumn>
          )}
          {!!routeSegment.lastPassDateTime && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.lastPassDate')}
                value={dateAndTime(routeSegment.lastPassDateTime)}
              />
            </GridColumn>
          )}
          {routeSegment.vehicleName && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.lastPassVehicle')}
                value={routeSegment.vehicleName}
              />
            </GridColumn>
          )}
          {routeSegment.driverName && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.lastPassDriver')}
                value={routeSegment.driverName}
              />
            </GridColumn>
          )}
          {routeSegment.routeName && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.lastPassRoute')}
                value={routeSegment.routeName}
              />
            </GridColumn>
          )}
          <GridColumn size="6/12">
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('routes.snowPlow.totalRecordedPasses')}
              value={`${routeSegment.recordedPasses || 0}`}
            />
          </GridColumn>

          {/* GENERAL STREET SEGMENT INFORMATION */}
          <GridColumn size="12/12">
            <Separator width="80%" color="grayDark" margin="small xxSmall" />
          </GridColumn>
          <GridColumn size="6/12">
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate(
                isSnowPlow
                  ? 'customers.streetNetwork.forwardPassesSnow'
                  : 'customers.streetNetwork.forwardPassesSweeper',
              )}
              value={forwardPasses || 0}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate(
                isSnowPlow
                  ? 'customers.streetNetwork.reversePassesSnow'
                  : 'customers.streetNetwork.reversePassesSweeper',
              )}
              value={reversePasses || 0}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('customers.streetNetwork.travelDirection')}
              value={translate(`customers.streetNetwork.${routeSegment.isOneWay ? 'oneWay' : 'twoWay'}`)}
            />
          </GridColumn>
          {!!routeSegment.numberOfLanes && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.numberOfLanes')}
                value={routeSegment.numberOfLanes}
              />
            </GridColumn>
          )}
          {!!routeSegment.segmentWidth && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.segmentWidth')}
                value={routeSegment.segmentWidth}
              />
            </GridColumn>
          )}
          {!!routeSegment.speedLimit && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.speedLimit')}
                value={routeSegment.speedLimit}
              />
            </GridColumn>
          )}
          {routeSegment.type && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.classification')}
                value={routeSegment.type}
              />
            </GridColumn>
          )}
          {!!serviceSideTypeId.length && !isSnowPlow && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.serviceSide.serviceSide')}
                value={serviceSideTypeId[0].label}
              />
            </GridColumn>
          )}
          {!!routeSegment.priorityTypeId && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.priority')}
                value={routeSegment.priorityTypeId}
              />
            </GridColumn>
          )}
          {!!routeSegment.turnRestrictions && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.turnRestrictions')}
                value={routeSegment.turnRestrictions}
              />
            </GridColumn>
          )}
          {!!size(routeSegment.streetSegmentServiceTypes) && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.segmentType.segmentType')}
                value={getTypesAsString(routeSegment.streetSegmentServiceTypes)}
              />
            </GridColumn>
          )}
          {!!size(routeSegment.streetSegmentServiceTypes) && !isSnowPlow && (
            <GridColumn size="6/12">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.streetNetwork.sweeperSideType')}
                value={getTypesAsString(routeSegment.streetSegmentServiceTypes, true)}
              />
            </GridColumn>
          )}
        </Grid>
      </MapGLPopupContent>
    </MapGLPopup>
  );
}
