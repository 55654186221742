import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import translate from '../../core/services/translate';

export const uploadResourcesFile = (fileData: any, vendorId: number) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http.post('/resourceImport/upload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadSampleResourceTemplate = () =>
  downloadExcelFile('/resourceImport/downloadTemplate', translate('resources.resourceImport.sampleTemplate'));
