import translate from '../../core/services/translate';
import { FutureAccountStatus } from '../interfaces/Services';

const isRequiredMessage = translate('common.validationMessages.isRequired');

export const accountStatusUpdateFromDateValidator = (value: any, _allValues?: any, props?: any, _name?: string) => {
  const { futureAccountStatusId } = props;
  if (futureAccountStatusId) {
    return value ? undefined : isRequiredMessage;
  }
};

export const accountStatusUpdateFutureStatusValidator = (value: any, _allValues?: any, props?: any, _name?: string) => {
  const { futureAccountStatusFromDate } = props;
  if (futureAccountStatusFromDate) {
    return value ? undefined : isRequiredMessage;
  }
};

export const getFutureAccountStatus = (futureAccountStatus: FutureAccountStatus | null) =>
  !futureAccountStatus || (!futureAccountStatus.accountStatusId && !futureAccountStatus.fromDate)
    ? null
    : futureAccountStatus;
