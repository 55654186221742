import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const OTHER = 'Other';
export const PERCENT = 'PERCENT';
export const AMOUNT = 'AMOUNT';

const REASONS = mapKeys(
  [
    { id: 'Size unavailable', name: translate('opportunity.sizeUnavailable') },
    { id: 'Out of service area', name: translate('opportunity.outOfServiceArea') },
    { id: 'Site unsafe', name: translate('opportunity.siteUnsafe') },
    { id: 'Site inaccessible', name: translate('opportunity.siteInaccessible') },
    { id: 'Bad prior experience', name: translate('opportunity.badPriorExperience') },
    { id: 'Other', name: translate('opportunity.other') },
  ],
  'id',
);

export default REASONS;
