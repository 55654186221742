import { camelCase } from 'lodash-es';
import { connect } from 'react-redux';
import { DragEvent, Fragment, MouseEvent, PureComponent } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { AppState } from '../../../../../store';
import { ActionButtonTooltip, Icon, PopoverWrapper } from '../../../../../core/components';
import { Box } from 'src/core/components/styled/Box';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { COLOR_ALERT, COLOR_SUCCESS } from '../../../../../core/styles';
import { dateTimeFormat, dateFormat } from 'src/utils/services/validator';
import {
  DispatchBoardIconWrapper,
  DispatchBoardJobAction,
  DispatchBoardJobActions,
  DispatchBoardJobCell,
  DispatchBoardJobContainer,
  DispatchBoardJobCustomerName,
  DispatchBoardJobDetail,
  DispatchBoardJobExpand,
  DispatchBoardJobExpandIcon,
  DispatchBoardJobIndex,
  DispatchBoardJobLocationAddress,
  DispatchBoardJobLocationName,
  DispatchBoardJobRow,
} from '../../../styled';
import { DispatchBoardRouteJob } from '../../../../interfaces/DispatchBoardRouteJob';
import { DispatchBoardSimpleRoute } from '../../../../interfaces/DispatchBoardRoute';
import { hasPermissionSelector } from '../../../../../account/ducks';
import { InfoIcon } from '../../../../../routes/components/styled';
import { isAdminSelector, isVendorManagerSelector } from 'src/account/ducks';
import { itemNumberFormatter } from './common/formatters';
import { JobTypes, PICKUP_STATUS_LABEL_COLORS, JOB_PENDING_OPTIMIZATION_ID } from '../../../../constants';
import { Label, Popover, Text } from '../../../../../core/components/styled';
import { ModalTypes } from './common/ModalContainer';
import { OTHER_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { PICKUP_STATUSES, SCHEDULED } from '../../../../../common/constants';
import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from '../../../../../account/constants';
import { RouteTemplateStatus } from 'src/routes/components/styled/RouteTemplateBuilderMap';
import { showImagesForJob } from '../../../../ducks/dispatchBoard';
import {
  TABLE_ROW_HEIGHT_EXPANDED_LARGE,
  TABLE_ROW_HEIGHT_EXPANDED,
  TABLE_ROW_HEIGHT_EXPANDED_SMALL,
} from 'src/core/constants/table';
import { TechnicalType } from '../../../../../common/interfaces/TechnicalType';
import { time } from '../../../../../utils/services/formatter';
import DispatchBoardJobDragHandle from './DispatchBoardJobDragHandle';
import DispatchBoardJobDropzone from './DispatchBoardJobDropzone';
import JobActions from './common/JobActions';
import JobPriorityTypeIndicator from '../../common/JobPriorityTypeIndicator';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import translate from 'src/core/services/translate';

interface CommonProps {
  deleteJob: (jobId: number) => void;
  draggable: boolean;
  hasPermissionToManageScheduledJobs?: boolean;
  hasPermissionToManageUnscheduledJobs?: boolean;
  isAdmin?: boolean;
  isExpanded: boolean;
  isSelected: boolean;
  isVendorManager?: boolean;
  job: DispatchBoardRouteJob;
  jobIndex: number;
  onClick?: (event: MouseEvent) => void;
  onDragStart: (event: DragEvent<HTMLDivElement>) => void;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
  optimizedJobsLength?: number;
  reasonCodeTypes: TechnicalType[];
  route?: DispatchBoardSimpleRoute;
  routeIsFiltered?: boolean;
  showImagesForJob: (jobId: number) => void;
  toggleJob: (e: MouseEvent, jobId: number) => void;
  hideOrderNumber?: boolean;
  preventDrop?: boolean;
}

// Note `conditionalPropType` TS alternative
interface UnassignedProps extends CommonProps {
  type: JobTypes.unassigned;
  openJobStatusModal?: (jobId: number, jobStatusId: number) => void;
  openOrderNumberPopover?: (index: number, e: MouseEvent) => void;
  toggleModal?: (modal: ModalTypes, open?: boolean, isHeader?: boolean) => void;
  hideActions?: boolean;
}
interface AssignedProps extends CommonProps {
  type: JobTypes.source | JobTypes.target | JobTypes.onHold;
  openJobStatusModal: (jobId: number, jobStatusId: number) => void;
  openOrderNumberPopover: (index: number, e: MouseEvent) => void;
  toggleModal: (modal: ModalTypes, open?: boolean, isHeader?: boolean) => void;
  hideActions?: boolean;
}

type Props = UnassignedProps | AssignedProps;

class DispatchBoardJob extends PureComponent<Props, { isDragStart: boolean }> {
  static defaultProps = {
    hasPermissionToManageScheduledJobs: false,
    hasPermissionToManageUnscheduledJobs: false,
  };

  readonly state = {
    isDragStart: false,
  };

  onJobIndexClick = (event: MouseEvent, jobIndex: number) => {
    event.stopPropagation();
    const { hasPermissionToManageScheduledJobs, openOrderNumberPopover, type } = this.props;

    if (type !== JobTypes.unassigned && hasPermissionToManageScheduledJobs) {
      !!openOrderNumberPopover && openOrderNumberPopover(jobIndex, event);
    }
  };

  openJobEditorModal = (event: MouseEvent) => {
    event.stopPropagation();
    const { toggleModal } = this.props;
    !!toggleModal && toggleModal('jobEditor', true);
  };

  openUnassignedJobNoteModal = (event: MouseEvent) => {
    event.stopPropagation();
    const { toggleModal } = this.props;
    !!toggleModal && toggleModal('unassignedJobsRouteNote', true);
  };

  openJobStatusModal = (event: MouseEvent) => {
    event.stopPropagation();
    const { openJobStatusModal } = this.props;
    !!openJobStatusModal && openJobStatusModal(this.props.job.id, this.props.job.jobStatusId);
  };

  jobDateColor = (job: DispatchBoardRouteJob) => {
    if (job.isOverdue) return COLOR_ALERT;
    if (job.isInFuture) return COLOR_SUCCESS;
  };

  toggleModal = (event: MouseEvent, modal: ModalTypes, open?: boolean, isHeader?: boolean) => {
    event.stopPropagation();
    const { toggleModal } = this.props;
    !!toggleModal && toggleModal(modal, open);
  };

  showImage = (event: MouseEvent) => {
    event.stopPropagation();
    const { job, showImagesForJob } = this.props;
    showImagesForJob(job.id);
  };

  render() {
    const {
      deleteJob,
      draggable,
      hasPermissionToManageScheduledJobs,
      hasPermissionToManageUnscheduledJobs,
      isAdmin,
      isExpanded,
      isSelected,
      isVendorManager,
      job,
      jobIndex,
      onClick,
      onDragStart,
      onJobDrop,
      optimizedJobsLength,
      reasonCodeTypes,
      route,
      routeIsFiltered,
      toggleJob,
      toggleModal,
      type,
      hideActions,
      hideOrderNumber,
      preventDrop,
    } = this.props;
    const { isDragStart } = this.state;

    const canDragJob = type !== JobTypes.unassigned && type !== JobTypes.onHold && hasPermissionToManageScheduledJobs;
    const canEditJob =
      (type === JobTypes.unassigned && hasPermissionToManageUnscheduledJobs) ||
      (type !== JobTypes.unassigned && hasPermissionToManageScheduledJobs);

    const canDelete =
      (job.jobStatusId === SCHEDULED && hasPermissionToManageScheduledJobs) ||
      (type === JobTypes.unassigned && hasPermissionToManageUnscheduledJobs);

    const reasonCode = reasonCodeTypes.find(type => type.id === job.reasonCodeTypeId);

    const handleDeleteJob = (event: MouseEvent) => {
      event.stopPropagation();
      deleteJob(job.id);
    };

    const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
      onDragStart(event);

      this.setState({
        isDragStart: true,
      });
    };

    const handleDragEnd = () => {
      this.setState({
        isDragStart: false,
      });
    };

    const handleJobDrop = (
      sourceRouteId: number,
      targetRouteId: number,
      jobsSelected: DispatchBoardRouteJob[],
      position?: number,
      type?: string,
    ) => {
      onJobDrop(sourceRouteId, targetRouteId, jobsSelected, position, type);

      this.setState({
        isDragStart: false,
      });
    };

    const temporaryContainerNotice = isExpanded && job.isTemporaryContainer && (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Box display="inline-flex" mr={5}>
          <Icon icon="clock" height="14px" />
        </Box>

        <Box flex="0 0">
          <Text size="small">{translate('customers.temporaryContainer')}</Text>
        </Box>
      </Box>
    );

    const isViewOnly = checkIfViewOnly();
    const isSupport = checkIfSupport();

    const vehicleNameAndDriverName = `${job.vehicleName} / ${job.driverName}`;

    const jobIsPendingOptimization = job.orderNo === JOB_PENDING_OPTIMIZATION_ID;

    const isDeleteJobsVisible =
      (type === JobTypes.unassigned || type === JobTypes.onHold) && (isAdmin || isVendorManager);

    const getMaterialTypeLabel = () => {
      let label =
        job.materialType.categoryId === OTHER_ID
          ? job.materialType.technicalName
          : translate(`vendors.bulkyItemScheduler.itemTypes.${camelCase(job.materialType.technicalName)}`);

      if (!!job.materialType.technicalName && !job.materialType.isActive)
        label = (
          <>
            {label} <RouteTemplateStatus inherit>{translate('common.inactive')}</RouteTemplateStatus>
          </>
        );

      return label;
    };

    return (
      <DispatchBoardJobContainer
        padding="small"
        draggable={draggable && !optimizedJobsLength}
        isExpanded={isExpanded}
        isSelected={isSelected}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={onClick}
        jobIsPendingOptimization={jobIsPendingOptimization}
        height={
          type === JobTypes.onHold
            ? TABLE_ROW_HEIGHT_EXPANDED_SMALL
            : type !== JobTypes.unassigned && (!!job.containerNumber || job.isTemporaryContainer)
            ? TABLE_ROW_HEIGHT_EXPANDED_LARGE
            : TABLE_ROW_HEIGHT_EXPANDED
        }
        width={isDragStart ? '97%' : '100%'}
      >
        {!preventDrop && !routeIsFiltered && (
          <DispatchBoardJobDropzone
            position="top"
            targetRouteId={route ? route.id : undefined}
            jobIndex={jobIndex}
            onJobDrop={handleJobDrop}
          />
        )}

        <DispatchBoardJobRow>
          {canDragJob && (
            <DispatchBoardJobCell width="8%">
              {jobIsPendingOptimization ? (
                <PopoverWrapper
                  margin="no"
                  triggerButton={<InfoIcon />}
                  popoverContent={!isDragStart && <Popover>{translate('routes.pendingOptimization')}</Popover>}
                  size="medium"
                />
              ) : !optimizedJobsLength && !routeIsFiltered ? (
                <DispatchBoardJobDragHandle />
              ) : undefined}
            </DispatchBoardJobCell>
          )}

          <DispatchBoardJobCell width={isDeleteJobsVisible ? '12%' : '8%'}>
            <DispatchBoardJobActions noStyle isDragStart={isDragStart}>
              {isDeleteJobsVisible && (
                <ActionButtonTooltip
                  iconSize="small"
                  icon={isSelected ? 'checkboxChecked' : 'checkboxUnchecked'}
                  tooltip={isSelected ? 'deselectJob' : 'selectJob'}
                  margin="no xxSmall no no"
                />
              )}
              {!hideOrderNumber && (
                <>
                  {(optimizedJobsLength && !jobIsPendingOptimization) || routeIsFiltered ? (
                    <DispatchBoardJobIndex>{jobIndex + 1}</DispatchBoardJobIndex>
                  ) : !jobIsPendingOptimization ? (
                    <DispatchBoardJobIndex onClick={event => this.onJobIndexClick(event, jobIndex)}>
                      {jobIndex + 1}
                    </DispatchBoardJobIndex>
                  ) : undefined}
                </>
              )}
            </DispatchBoardJobActions>
          </DispatchBoardJobCell>

          <DispatchBoardJobCell
            width={canDragJob ? '51%' : isDeleteJobsVisible ? '55%' : type === JobTypes.onHold ? '67%' : '59%'}
          >
            <DispatchBoardJobCustomerName>
              {job.jobPriorityTypeId && (
                <Box mr={5}>
                  <DispatchBoardJobActions noStyle isDragStart={isDragStart}>
                    <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={job.jobPriorityTypeId} />
                  </DispatchBoardJobActions>
                </Box>
              )}
              {job.customerName}
              {type !== JobTypes.unassigned && itemNumberFormatter(job.customerAccountNumber)}
            </DispatchBoardJobCustomerName>
            <DispatchBoardJobLocationName>
              {job.locationName}
              {type !== JobTypes.unassigned && itemNumberFormatter(job.locationAccountNumber)}
            </DispatchBoardJobLocationName>
            <DispatchBoardJobLocationAddress>
              {job.locationAddress ? job.locationAddress.formattedAddress : '-'}
            </DispatchBoardJobLocationAddress>
          </DispatchBoardJobCell>

          <DispatchBoardJobCell width="25%" align="right">
            <Box display="flex" alignItems="center" justifyContent="flex-end" whiteSpace="nowrap" mb={6}>
              {!isExpanded && job.isTemporaryContainer && (
                <Box display="inline-flex" mr={5}>
                  <TooltipIconButton
                    noButtonWrapper
                    inlineFlexWrapper
                    tooltipAsString
                    tooltip={translate('customers.temporaryContainer')}
                    tooltipPosition="left"
                    tooltipColor="grayDarker"
                    margin="no"
                  >
                    <Icon icon="clock" height="14px" />
                  </TooltipIconButton>
                </Box>
              )}

              <DispatchBoardJobCell width="100%" align="right">
                <DispatchBoardJobDetail>{job.jobTypeName}</DispatchBoardJobDetail>

                {type === JobTypes.onHold && (
                  <DispatchBoardJobDetail>{moment(job.date).format(dateFormat)}</DispatchBoardJobDetail>
                )}
              </DispatchBoardJobCell>
            </Box>
            {type !== JobTypes.unassigned ? (
              <DispatchBoardJobDetail>
                <Label color={PICKUP_STATUS_LABEL_COLORS[job.jobStatusId].toString()} onClick={this.openJobStatusModal}>
                  {PICKUP_STATUSES[job.jobStatusId].name}
                </Label>

                {job.serviceDate && (
                  <DispatchBoardJobDetail color={PICKUP_STATUS_LABEL_COLORS[job.jobStatusId.toString()]}>
                    {time(job.serviceDate)}
                  </DispatchBoardJobDetail>
                )}

                {temporaryContainerNotice}
              </DispatchBoardJobDetail>
            ) : (
              <DispatchBoardJobDetail>
                <DispatchBoardJobDetail color={this.jobDateColor(job)}>{job.formattedDate}</DispatchBoardJobDetail>

                {temporaryContainerNotice}
              </DispatchBoardJobDetail>
            )}
          </DispatchBoardJobCell>

          <DispatchBoardJobCell width="8%" align="right">
            <DispatchBoardJobExpand onClick={e => toggleJob(e, job.id)} id={`toggle-${type}-job-${job.id}-button`}>
              <DispatchBoardJobExpandIcon />
            </DispatchBoardJobExpand>
          </DispatchBoardJobCell>
        </DispatchBoardJobRow>

        {isExpanded && (
          <Fragment>
            <DispatchBoardJobRow>
              <DispatchBoardJobCell width="45%">
                <DispatchBoardJobDetail>{job.containerTypeName}</DispatchBoardJobDetail>

                <DispatchBoardJobDetail>
                  {job.numberOfContainers} x {job.containerSizeName}
                </DispatchBoardJobDetail>

                <DispatchBoardJobDetail>{job.wasteMaterialTypeName}</DispatchBoardJobDetail>

                <DispatchBoardJobDetail>{getMaterialTypeLabel()}</DispatchBoardJobDetail>

                {type !== JobTypes.unassigned && <DispatchBoardJobDetail>{job.containerNumber}</DispatchBoardJobDetail>}
              </DispatchBoardJobCell>

              <DispatchBoardJobCell width="55%" align="right" verticalAlign>
                {type === JobTypes.unassigned && (
                  <>
                    {(!!job.prevRouteName || !!job.lastServiceDateTime || !!job.vehicleName || !!job.driverName) && (
                      <PopoverWrapper
                        triggerButton={
                          <DispatchBoardJobDetail ellipsis title={job.prevRouteName}>
                            {translate('dispatchBoard.lastServicedDetails')}{' '}
                            <DispatchBoardIconWrapper>
                              <Icon customViewBox="0 0 30 23" width="16px" icon="redeye" />
                            </DispatchBoardIconWrapper>
                          </DispatchBoardJobDetail>
                        }
                        popoverContent={
                          !isDragStart && (
                            <Popover>
                              {!!job.prevRouteName && (
                                <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                                  {translate('common.route')}: {job.prevRouteName}
                                </Text>
                              )}
                              {!!job.lastServiceDateTime && (
                                <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                                  {translate('common.date')}: {moment(job.lastServiceDateTime).format(dateTimeFormat)}
                                </Text>
                              )}
                              {(!!job.vehicleName || !!job.driverName) && (
                                <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                                  {translate('dispatchBoard.vehicleDriver')}: {vehicleNameAndDriverName}
                                </Text>
                              )}
                            </Popover>
                          )
                        }
                        size="lMedium"
                      />
                    )}
                  </>
                )}

                {!!job.workOrderNumber && (
                  <DispatchBoardJobDetail>
                    {translate('routes.workOrderNumber')}: {job.workOrderNumber}
                  </DispatchBoardJobDetail>
                )}

                {!!job.refNumber && (
                  <DispatchBoardJobDetail>
                    {translate('routes.refNumber')}: {job.refNumber}
                  </DispatchBoardJobDetail>
                )}

                {!!reasonCode && (
                  <DispatchBoardJobDetail>
                    {translate('common.reasonCode')}: {reasonCode.name}
                  </DispatchBoardJobDetail>
                )}
              </DispatchBoardJobCell>
            </DispatchBoardJobRow>

            {!hideActions && (
              <DispatchBoardJobRow>
                {!optimizedJobsLength && (
                  <DispatchBoardJobCell width="70%">
                    {type !== JobTypes.unassigned ? (
                      <JobActions
                        canEditService={hasPermissionToManageScheduledJobs}
                        imageCount={job.imageCount}
                        isDragStart={isDragStart}
                        issueReportedCount={job.issueReportedCount}
                        noteCount={job.noteCount}
                        toggleModal={toggleModal}
                        type={type}
                        weightTicketCount={job.weightTicketCount || 0}
                        locationId={job.locationId}
                        customerId={job.customerId}
                        serviceContractId={job.serviceContractId}
                      />
                    ) : (
                      <DispatchBoardJobActions align="left" isDragStart={isDragStart}>
                        {!!job.imageUrl && (
                          <DispatchBoardJobAction onClick={this.showImage}>
                            <ActionButtonTooltip icon="image" tooltip="viewImage" content="1" />
                          </DispatchBoardJobAction>
                        )}

                        <DispatchBoardJobAction onClick={this.openUnassignedJobNoteModal}>
                          <ActionButtonTooltip icon="note" tooltip="notes" />
                        </DispatchBoardJobAction>

                        {hasPermissionToManageUnscheduledJobs && (
                          <DispatchBoardJobAction>
                            <Link
                              id="edit-service-link"
                              color="grayDark"
                              to={`/customers/customers/${job.customerId}/location/${job.locationId}/service/${job.serviceContractId}`}
                            >
                              <ActionButtonTooltip icon="edit" tooltip="editService" />
                            </Link>
                          </DispatchBoardJobAction>
                        )}

                        {!isViewOnly && !isSupport && (
                          <>
                            <DispatchBoardJobAction onClick={e => this.toggleModal(e, 'customerEditor', true)}>
                              <ActionButtonTooltip icon="user" tooltip="editCustomer" />
                            </DispatchBoardJobAction>

                            <DispatchBoardJobAction onClick={e => this.toggleModal(e, 'locationEditor', true)}>
                              <ActionButtonTooltip icon="mapPin" tooltip="editLocation" />
                            </DispatchBoardJobAction>
                          </>
                        )}
                      </DispatchBoardJobActions>
                    )}
                  </DispatchBoardJobCell>
                )}

                <DispatchBoardJobCell width="100%">
                  <DispatchBoardJobActions align="right" isDragStart={isDragStart}>
                    {canEditJob && !optimizedJobsLength && (
                      <DispatchBoardJobAction
                        onClick={this.openJobEditorModal}
                        id={`edit-${type}-job-${job.id}-button`}
                      >
                        <ActionButtonTooltip icon="edit" tooltip="editJob" />
                      </DispatchBoardJobAction>
                    )}

                    {canDelete && !optimizedJobsLength && (
                      <DispatchBoardJobAction onClick={handleDeleteJob} id={`delete-${type}-job-${job.id}-button`}>
                        <ActionButtonTooltip icon="delete" tooltip="deleteJob" />
                      </DispatchBoardJobAction>
                    )}
                  </DispatchBoardJobActions>
                </DispatchBoardJobCell>
              </DispatchBoardJobRow>
            )}
          </Fragment>
        )}

        {!preventDrop && !optimizedJobsLength && !routeIsFiltered && (
          <DispatchBoardJobDropzone
            position="bottom"
            targetRouteId={route ? route.id : undefined}
            jobIndex={jobIndex + 1}
            onJobDrop={handleJobDrop}
          />
        )}
      </DispatchBoardJobContainer>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  hasPermissionToManageScheduledJobs: hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ),
  hasPermissionToManageUnscheduledJobs: hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
  ),
  reasonCodeTypes: state.common.reasonCodeTypes.reasonCodeTypes,
  isAdmin: isAdminSelector(state.account.login),
  isVendorManager: isVendorManagerSelector(state.account.login),
});

const mapDispatchToProps = {
  showImagesForJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchBoardJob);
export const SortableDispatchBoardJob = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SortableElement<Props>(DispatchBoardJob));
