import { useSelector, useDispatch } from 'react-redux';

import { TypedResolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadContainerActivity } from 'src/fleet/ducks';
import ContainerActivityModal from './ContainerActivityModal';

interface Props {
  containerId?: number;
  containerTypeId?: number;
  closeModal: () => void;
}

const ContainerActivityModalResolver: React.FC<Props> = ({ containerId, containerTypeId, closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [];
    if (containerId) {
      dependencies.push(loadContainerActivity(containerId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={ContainerActivityModal}
      successProps={{ closeModal, containerTypeId }}
      loadingComponent={PageLoadingOverlay}
      onError={closeModal}
      resolve={loadDependencies}
    />
  );
};

export default ContainerActivityModalResolver;
