import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const CONTAINER_PLACEMENT_ISSUE = 'Container Placement Issue';
export const LOCATION_INACCESSIBLE = 'Location Inaccessible';
export const PERSONNEL_ISSUE = 'Internal Truck or Personnel Issue';

const RUBICON_DISPATCHES_REASONS = mapKeys(
  [
    { id: 'Container Placement Issue', name: translate('opportunity.placementIssue') },
    { id: 'Container site is unsafe to service', name: translate('opportunity.containerSiteUnsafe') },
    { id: 'Location Inaccessible', name: translate('opportunity.locationInaccessible') },
    { id: 'Internal Truck or Personnel Issue', name: translate('opportunity.personnelIssue') },
    { id: 'Out of service area', name: translate('opportunity.outOfServiceArea') },
    { id: 'Other', name: translate('opportunity.other') },
  ],
  'id',
);

export default RUBICON_DISPATCHES_REASONS;
