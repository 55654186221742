import axios from 'axios';
import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ASC, DESC } from '../../core/constants';
import {
  RouteTrackerPayload,
  RoutesResourceAvailability,
  RoutesSummary,
  SnowSweeperRouteTrackerPayload,
  ResetRouteData,
  Route,
} from '../interfaces/Route';
import {
  cloneRoute as doCloneRoute,
  resetRouteTracker as doResetRouteTracker,
  deleteRoute as doDeleteRoute,
  deleteRoutes as doDeleteRoutes,
  exportRoutesTracker as doExportRoutes,
  exportSnowOrSweeperRoutesTracker as doExportSnowOrSweeperRoutes,
  loadRoutesTracker as doLoadRoutes,
  loadRoutesResourceAvailability as doLoadRoutesResourceAvailability,
  loadSnowOrSweeperRoutesTracker as doLoadSnowOrSweeperRoutes,
  loadSnowOrSweeperRoutesHeader as doLoadSnowOrSweeperRoutesHeader,
  rescheduleRoutes as doRescheduleRoutes,
  loadNextRunNumber as doLoadNextRunNumber,
} from '../services/routeTracker';
import translate from 'src/core/services/translate';

// Actions
const START_BULK_DELETE = 'routes/routeTracker/START_BULK_DELETE';
const START_CLONE = 'routes/routeTracker/START_CLONE';
const START_DELETE = 'routes/routeTracker/START_DELETE';
const START_EXPORT = 'routes/routeTracker/START_EXPORT';
const START_LOAD = 'routes/routeTracker/START_LOAD';
const START_RESCHEDULE = 'routes/routeTracker/START_RESCHEDULE';
const COMPLETE_BULK_DELETE = 'routes/routeTracker/COMPLETE_BULK_DELETE';
export const COMPLETE_CLONE = 'routes/routeTracker/COMPLETE_CLONE';
export const COMPLETE_DELETE = 'routes/routeTracker/COMPLETE_DELETE';
export const COMPLETE_EXPORT = 'routes/routeTracker/COMPLETE_EXPORT';
export const COMPLETE_LOAD = 'routes/routeTracker/COMPLETE_LOAD';
const COMPLETE_RESCHEDULE = 'routes/routeTracker/COMPLETE_RESCHEDULE';
const FAIL_BULK_DELETE = 'routes/routeTracker/FAIL_BULK_DELETE';
const FAIL_CLONE = 'routes/routeTracker/FAIL_CLONE';
const FAIL_DELETE = 'routes/routeTracker/FAIL_DELETE';
const FAIL_EXPORT = 'routes/routeTracker/FAIL_EXPORT';
const FAIL_LOAD = 'routes/routeTracker/FAIL_LOAD';
const FAIL_RESCHEDULE = 'routes/routeTracker/FAIL_RESCHEDULE';
const RESET = 'routes/routeTracker/RESET';
const START_LOAD_ROUTES_RESOURCE_AVAILABILITY = 'routes/routeTracker/START_LOAD_ROUTES_RESOURCE_AVAILABILITY';
const COMPLETE_LOAD_ROUTES_RESOURCE_AVAILABILITY = 'routes/routeTracker/COMPLETE_LOAD_ROUTES_RESOURCE_AVAILABILITY';
const FAIL_LOAD_ROUTES_RESOURCE_AVAILABILITY = 'routes/routeTracker/FAIL_LOAD_ROUTES_RESOURCE_AVAILABILITY';
const START_LOAD_SNOW_OR_SWEEPER_ROUTES = 'routes/routeTracker/START_LOAD_SNOW_OR_SWEEPER_ROUTES';
const COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES = 'routes/routeTracker/COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES';
const FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES = 'routes/routeTracker/FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES';
const START_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER = 'routes/routeTracker/START_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER';
const COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER = 'routes/routeTracker/COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER';
const FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER = 'routes/routeTracker/FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER';
const START_SNOW_OR_SWEEPER_EXPORT = 'routes/routeTracker/START_SNOW_OR_SWEEPER_EXPORT';
export const COMPLETE_SNOW_OR_SWEEPER_EXPORT = 'routes/routeTracker/COMPLETE_SNOW_OR_SWEEPER_EXPORT';
const FAIL_SNOW_OR_SWEEPER_EXPORT = 'routes/routeTracker/FAIL_SNOW_OR_SWEEPER_EXPORT';
const START_LOAD_NEXT_RUN_NUMBER = 'routes/routeTracker/START_LOAD_NEXT_RUN_NUMBER';
const COMPLETE_LOAD_NEXT_RUN_NUMBER = 'routes/routeTracker/COMPLETE_LOAD_NEXT_RUN_NUMBER';
const FAIL_LOAD_NEXT_RUN_NUMBER = 'routes/routeTracker/FAIL_LOAD_NEXT_RUN_NUMBER';
const START_RESET = 'routes/routeTracker/START_RESET';
const COMPLETE_RESET = 'routes/routeTracker/COMPLETE_RESET';
const FAIL_RESET = 'routes/routeTracker/FAIL_RESET';

const CancelToken = axios.CancelToken;
let cancelLoadRouteTracker: any;
let cancelLoadRouteTrackerHeader: any;

interface State {
  isCloning: boolean;
  isDeleting: boolean;
  isExporting: boolean;
  isLoading: boolean;
  isLoadingNextRunNumber: boolean;
  isLoadingRoutesResourceAvailability: boolean;
  isRescheduling: boolean;
  isReseting: boolean;
  nextRunNumber: number;
  routes: any[];
  routesHeader: any;
  routesResourceAvailability: RoutesResourceAvailability[];
  snowOrSweeperRoutes: any[];
  snowOrSweeperRoutesHeader: RoutesSummary;
  total: number;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isCloning: false,
  isDeleting: false,
  isExporting: false,
  isLoading: false,
  isLoadingNextRunNumber: false,
  isLoadingRoutesResourceAvailability: false,
  isRescheduling: false,
  isReseting: false,
  nextRunNumber: 0,
  routes: [],
  routesHeader: undefined,
  routesResourceAvailability: [],
  snowOrSweeperRoutes: [],
  snowOrSweeperRoutesHeader: {} as RoutesSummary,
  total: 0,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routes: action.routes.routes,
          routesHeader: action.routes.header,
          total: action.routes.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routes: undefined,
          routesHeader: undefined,
          total: undefined,
        },
      });

    case START_LOAD_SNOW_OR_SWEEPER_ROUTES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES:
      return update(state, {
        $merge: {
          isLoading: false,
          snowOrSweeperRoutes: action.snowOrSeeperRoutes,
          total: action.snowOrSeeperRoutes.length,
        },
      });

    case FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES:
      return update(state, {
        $merge: {
          isLoading: false,
          snowOrSweeperRoutes: [],
          total: undefined,
        },
      });

    case START_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER:
      return update(state, {
        $merge: {
          isLoading: false,
          snowOrSweeperRoutesHeader: action.snowOrSweeperRoutesHeader,
        },
      });

    case FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER:
      return update(state, {
        $merge: {
          isLoading: false,
          snowOrSweeperRoutesHeader: undefined,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case START_SNOW_OR_SWEEPER_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_SNOW_OR_SWEEPER_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_SNOW_OR_SWEEPER_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const routeIndex = findIndex(state.routes, { id: action.routeId });
      return update(state, {
        routes: { $splice: [[routeIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_BULK_DELETE: {
      const arr = state.routes.filter(item => !action.routeIds.includes(item.routeId));
      return update(state, {
        routes: { $merge: arr as any },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_CLONE:
      return update(state, {
        $merge: {
          isCloning: true,
        },
      });

    case COMPLETE_CLONE: {
      const routeIndex = findIndex(state.routes, { id: action.sourceRouteId });
      return update(state, {
        routes: { $splice: [[routeIndex, 0, action.route]] },
        $merge: { isCloning: false },
      });
    }

    case FAIL_CLONE:
      return update(state, {
        $merge: {
          isCloning: false,
        },
      });

    case START_RESCHEDULE:
      return update(state, {
        $merge: {
          isRescheduling: true,
        },
      });

    case COMPLETE_RESCHEDULE:
      return update(state, {
        $merge: {
          isRescheduling: false,
        },
      });

    case FAIL_RESCHEDULE:
      return update(state, {
        $merge: {
          isRescheduling: false,
        },
      });

    case START_LOAD_ROUTES_RESOURCE_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingRoutesResourceAvailability: true,
        },
      });

    case COMPLETE_LOAD_ROUTES_RESOURCE_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingRoutesResourceAvailability: false,
          routesResourceAvailability: action.routesResourceAvailability,
        },
      });

    case FAIL_LOAD_ROUTES_RESOURCE_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingRoutesResourceAvailability: false,
          routesResourceAvailability: [],
        },
      });

    case START_LOAD_NEXT_RUN_NUMBER:
      return update(state, {
        $merge: {
          isLoadingNextRunNumber: true,
        },
      });

    case COMPLETE_LOAD_NEXT_RUN_NUMBER:
      return update(state, {
        $merge: {
          isLoadingNextRunNumber: false,
          nextRunNumber: action.nextRunNumber,
        },
      });

    case FAIL_LOAD_NEXT_RUN_NUMBER:
      return update(state, {
        $merge: {
          isLoadingNextRunNumber: false,
          nextRunNumber: 0,
        },
      });

    case START_RESET:
      return update(state, {
        $merge: {
          isReseting: true,
        },
      });

    case COMPLETE_RESET: {
      const routeIndex = findIndex(state.routes, { id: action.sourceRouteId });
      return update(state, {
        routes: { $splice: [[routeIndex, 0, action.route]] },
        $merge: { isReseting: false },
      });
    }

    case FAIL_RESET:
      return update(state, {
        $merge: {
          isReseting: false,
        },
      });

    case RESET:
      return update(state, { $merge: { ...initialState } });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routes: any) => ({
  type: COMPLETE_LOAD,
  routes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadSnowOrSweeperRoutes = () => ({
  type: START_LOAD_SNOW_OR_SWEEPER_ROUTES,
});

const completeLoadSnowOrSweeperRoutes = (snowOrSeeperRoutes: any) => ({
  type: COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES,
  snowOrSeeperRoutes,
});

const failLoadSnowOrSweeperRoutes = () => ({
  type: FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES,
});
const startLoadSnowOrSweeperRoutesHeader = () => ({
  type: START_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER,
});

const completeLoadSnowOrSweeperRoutesHeader = (snowOrSweeperRoutesHeader: RoutesSummary) => ({
  type: COMPLETE_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER,
  snowOrSweeperRoutesHeader,
});

const failLoadSnowOrSweeperRoutesHeader = () => ({
  type: FAIL_LOAD_SNOW_OR_SWEEPER_ROUTES_HEADER,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const startSnowOrSweeperExport = () => ({
  type: START_SNOW_OR_SWEEPER_EXPORT,
});

const completeSnowOrSweeperExport = () => ({
  type: COMPLETE_SNOW_OR_SWEEPER_EXPORT,
});

const failSnowOrSweeperExport = () => ({
  type: FAIL_SNOW_OR_SWEEPER_EXPORT,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (routeId: number) => ({
  type: COMPLETE_DELETE,
  routeId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startBulkDelete = () => ({
  type: START_BULK_DELETE,
});

const completeBulkDelete = (routeIds: number[]) => ({
  type: COMPLETE_BULK_DELETE,
  routeIds,
});

const failBulkDelete = () => ({
  type: FAIL_BULK_DELETE,
});

const startClone = () => ({
  type: START_CLONE,
});

const completeClone = (sourceRouteId: number, route: any) => ({
  type: COMPLETE_CLONE,
  sourceRouteId,
  route,
});

const failClone = () => ({
  type: FAIL_CLONE,
});

const startReschedule = () => ({
  type: START_RESCHEDULE,
});

const completeReschedule = () => ({
  type: COMPLETE_RESCHEDULE,
});

const failReschedule = () => ({
  type: FAIL_RESCHEDULE,
});

const startLoadRoutesResourceAvailability = () => ({
  type: START_LOAD_ROUTES_RESOURCE_AVAILABILITY,
});

const completeLoadRoutesResourceAvailability = (routesResourceAvailability: RoutesResourceAvailability[]) => ({
  type: COMPLETE_LOAD_ROUTES_RESOURCE_AVAILABILITY,
  routesResourceAvailability,
});

const failLoadRoutesResourceAvailability = () => ({
  type: FAIL_LOAD_ROUTES_RESOURCE_AVAILABILITY,
});

const startLoadNextRunNumber = () => ({
  type: START_LOAD_NEXT_RUN_NUMBER,
});

const completeStartLoadNextRunNumber = (nextRunNumber: number) => ({
  type: COMPLETE_LOAD_NEXT_RUN_NUMBER,
  nextRunNumber,
});

const failStartLoadNextRunNumber = () => ({
  type: FAIL_LOAD_NEXT_RUN_NUMBER,
});

const startReset = () => ({
  type: START_RESET,
});

const completeReset = (sourceRouteId: number, route: Route) => ({
  type: COMPLETE_RESET,
  sourceRouteId,
  route,
});

const failReset = () => ({
  type: FAIL_RESET,
});

export const loadRoutesTracker = (queryParams: RouteTrackerPayload) => (dispatch: Dispatch) => {
  if (cancelLoadRouteTracker) cancelLoadRouteTracker(translate('common.inFlightRequestCanceled'));

  dispatch(startLoad());
  const loadRoutesPromise = doLoadRoutes(queryParams);
  loadRoutesPromise.then(routes => dispatch(completeLoad(routes))).catch(err => dispatch(failLoad()));
  return loadRoutesPromise;
};

export const loadSnowOrSweeperRoutesTracker = (queryParams: SnowSweeperRouteTrackerPayload) => (dispatch: Dispatch) => {
  if (cancelLoadRouteTracker) cancelLoadRouteTracker(translate('common.inFlightRequestCanceled'));

  const cancelToken = new CancelToken(c => {
    cancelLoadRouteTracker = c;
  });

  dispatch(startLoadSnowOrSweeperRoutes());
  const loadSnowOrSweeperRoutesPromise = doLoadSnowOrSweeperRoutes({
    ...queryParams,
    cancelToken,
  });
  loadSnowOrSweeperRoutesPromise
    .then(snowOrSweeperRoutes => dispatch(completeLoadSnowOrSweeperRoutes(snowOrSweeperRoutes)))
    .catch(err => {
      if (!axios.isCancel(err)) dispatch(failLoadSnowOrSweeperRoutes());
    });
  return loadSnowOrSweeperRoutesPromise;
};

export const loadSnowOrSweeperRoutesHeader = (queryParams: SnowSweeperRouteTrackerPayload) => (dispatch: Dispatch) => {
  if (cancelLoadRouteTrackerHeader) cancelLoadRouteTrackerHeader(translate('common.inFlightRequestCanceled'));

  const cancelToken = new CancelToken(c => {
    cancelLoadRouteTrackerHeader = c;
  });

  dispatch(startLoadSnowOrSweeperRoutesHeader());
  const loadRoutesPromiseHeader = doLoadSnowOrSweeperRoutesHeader({
    ...queryParams,
    cancelToken,
  });
  loadRoutesPromiseHeader
    .then(snowOrSweeperRoutesHeader => dispatch(completeLoadSnowOrSweeperRoutesHeader(snowOrSweeperRoutesHeader)))
    .catch(err => {
      if (!axios.isCancel(err)) dispatch(failLoadSnowOrSweeperRoutesHeader());
    });
  return loadRoutesPromiseHeader;
};

export const exportRoutesTracker =
  (
    vendorId: number,
    searchTerm: string,
    vehicleTypeIds: string,
    routeStatusTypeIds: string,
    startDate: Date | string,
    endDate: Date | string,
    page: number,
    sortOrder: typeof ASC | typeof DESC = DESC,
    sortedBy: string,
    limit: number,
    serviceZones: string,
    supervisors: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportRoutesPromise = doExportRoutes(
      vendorId,
      searchTerm,
      vehicleTypeIds,
      routeStatusTypeIds,
      startDate,
      endDate,
      page,
      sortOrder,
      sortedBy,
      limit,
      serviceZones,
      supervisors,
    );
    exportRoutesPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportRoutesPromise;
  };

export const exportSnowOrSweeperRoutesTracker =
  (vendorId: number, vehicleTypeId: number, startDate: Date | string, endDate: Date | string) =>
  (dispatch: Dispatch) => {
    dispatch(startSnowOrSweeperExport());
    const exportSnowOrSweeperRoutesPromise = doExportSnowOrSweeperRoutes(vendorId, vehicleTypeId, startDate, endDate);
    exportSnowOrSweeperRoutesPromise
      .then(() => dispatch(completeSnowOrSweeperExport()))
      .catch(() => dispatch(failSnowOrSweeperExport()));
    return exportSnowOrSweeperRoutesPromise;
  };

export const deleteRouteTracker = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteRoutePromise = doDeleteRoute(routeId);
  deleteRoutePromise.then(() => dispatch(completeDelete(routeId))).catch(() => dispatch(failDelete()));
  return deleteRoutePromise;
};

export const bulkDeleteRouteTrackers = (routeIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startBulkDelete());
  const deleteRoutesPromise = doDeleteRoutes(routeIds);
  deleteRoutesPromise.then(() => dispatch(completeBulkDelete(routeIds))).catch(() => dispatch(failBulkDelete()));
  return deleteRoutesPromise;
};

export const rescheduleRouteTrackers = (routes: any) => (dispatch: Dispatch) => {
  dispatch(startReschedule());
  const rescheduleRoutesPromise = doRescheduleRoutes(routes);
  rescheduleRoutesPromise
    .then(() => {
      dispatch(completeReschedule());
    })
    .catch(() => dispatch(failReschedule()));
  return rescheduleRoutesPromise;
};

export const cloneRouteTracker = (routeId: number, data: any) => (dispatch: Dispatch) => {
  dispatch(startClone());
  const cloneRoutePromise = doCloneRoute(routeId, data);
  cloneRoutePromise.then(route => dispatch(completeClone(routeId, route))).catch(() => dispatch(failClone()));
  return cloneRoutePromise;
};

export const loadRoutesResourceAvailability =
  (routesResourceAvailability: RoutesResourceAvailability[]) => (dispatch: Dispatch) => {
    dispatch(startLoadRoutesResourceAvailability());
    const loadRoutesResourceAvailabilityPromise = doLoadRoutesResourceAvailability(routesResourceAvailability);
    loadRoutesResourceAvailabilityPromise
      .then((routesResourceAvailability: RoutesResourceAvailability[]) =>
        dispatch(completeLoadRoutesResourceAvailability(routesResourceAvailability)),
      )
      .catch(() => dispatch(failLoadRoutesResourceAvailability()));
    return loadRoutesResourceAvailabilityPromise;
  };

export const loadNextRunNumber = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadNextRunNumber());
  const loadNextRunNumberPromise = doLoadNextRunNumber(routeId);
  loadNextRunNumberPromise
    .then((nextRunNumber: number) => dispatch(completeStartLoadNextRunNumber(nextRunNumber)))
    .catch(() => dispatch(failStartLoadNextRunNumber()));
  return loadNextRunNumberPromise;
};

export const resetRouteTracker = (routeId: number, data: ResetRouteData) => (dispatch: Dispatch) => {
  dispatch(startReset());
  const cloneRoutePromise = doResetRouteTracker(routeId, data);
  cloneRoutePromise.then(route => dispatch(completeReset(routeId, route))).catch(() => dispatch(failReset()));
  return cloneRoutePromise;
};

export const resetRoutesTracker = () => ({
  type: RESET,
});
