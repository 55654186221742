import { camelize } from 'humps';
import { orderBy, groupBy, keys, find, upperFirst } from 'lodash';

import translate from 'src/core/services/translate';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';
import { ContainerForStatistics } from 'src/fleet/interfaces/ContainerStatistics';

export const getChartData = (containers: ContainerForStatistics[], equipmentSizes: TechnicalType[]) => {
  const data: any[] = [];
  const containersGroupedBySize = groupBy(containers, 's');
  keys(containersGroupedBySize).forEach(key => {
    const size = find(equipmentSizes, { id: Number(key) });
    data.push({
      label: translate(
        `common.equipmentSizes.x${upperFirst(
          camelize(size?.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
        )}`,
        { size: size ? getEquipmentSize(size.technicalName, size?.name) : 0 },
      ),
      value: (containersGroupedBySize[key].length * 100) / containers.length,
      rawValue: containersGroupedBySize[key].length,
    });
  });

  const ordered = orderBy(data, 'rawValue', 'desc');

  return {
    labels: ordered.map(item => item.label),
    data: ordered.map(item => item.value),
    rawDataValues: ordered.map(item => item.rawValue),
  };
};
