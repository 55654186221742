import { map, size } from 'lodash-es';
import { MouseEvent, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';

import { PermissionGuard } from 'src/account/components';
import { GEO_FENCES_DAILY_ROUTE, GEO_FENCES_ROUTE_TEMPLATE } from 'src/account/constants';
import { isAdminSelector, isSuperAdminSelector } from 'src/account/ducks';
import { IconButtonIcon } from 'src/core/components/styled';
import { BOTTOM } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import createPopover from 'src/core/services/createPopover';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import RouteGeoFenceAlertsForm from 'src/routes/components/forms/RouteGeoFenceAlertsForm';
import EventLogModalResolver from 'src/routes/components/modals/EventLogModalResolver';
import {
  RouteMapAction,
  RouteMapActionText,
  RouteMapActionsClose,
  RouteMapActionsCloseIcon,
  RouteMapActionsContainer,
  RouteMapActionsSection,
  RouteMapActionsTitle,
  RouteMapActionsWrapper,
} from 'src/routes/components/styled/RouteMapActions';
import { SCHEDULED } from 'src/routes/constants';
import { GeoFence, deleteGeoFences } from 'src/routes/ducks/geoFences';
import { loadRouteGeoFence, saveRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { checkIfGeoFenceIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';

type Props = {
  closeRouteMapActions: () => void;
  isRouteMapActionsOpen: boolean;
  isEditingSegments: boolean;
  drawMode?: DrawPolygonMode | EditingMode;
  isAddingPolygon: boolean;
  polygonsInEdit: GeoJSON.Feature<GeoJSON.Polygon>[];
  selectedGeoFence?: any;
  enterDrawMode: () => void;
  exitDrawMode: () => void;
  editGeoFence: () => void;
  handleAddPolygon: () => void;
  handleDeletePolygon: () => void;
  undoDraw: (e: any) => void;
  showRouteGeoFence: (id?: number) => void;
  removeRouteGeoFence: (id: number) => void;
  routeTemplateId?: number;
};

export default function StreetNetworkRouteMapActions({
  closeRouteMapActions,
  isRouteMapActionsOpen,
  isEditingSegments,
  drawMode,
  enterDrawMode,
  editGeoFence,
  exitDrawMode,
  handleAddPolygon,
  undoDraw,
  showRouteGeoFence,
  handleDeletePolygon,
  removeRouteGeoFence,
  polygonsInEdit,
  isAddingPolygon,
  selectedGeoFence,
  routeTemplateId,
}: Props) {
  const [isEventLogModalOpen, setEventLogModalOpen] = useState(false);
  const [isGeoFenceAlertVisible, setGeoFenceAlertVisible] = useState(false);
  const closeGeoFenceAlertPopover = useRef<() => void>();
  const dispatch = useDispatch();

  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { geoFence } = useSelector(state => state.routes.geoFence);

  const vendorId = useSelector(currentVendorId);
  const geoFenceIsActive = useSelector(checkIfGeoFenceIsEnabled);

  const isRubiconAdmin = useSelector(state => {
    const loginState = state.account.login;
    return isAdminSelector(loginState) || isSuperAdminSelector(loginState);
  });

  const showGeoFenceActions =
    routeSummary &&
    (routeSummary.vehicleTypeId === SNOW_PLOW_ID || routeSummary?.vehicleTypeId === STREET_SWEEPER_ID) &&
    routeSummary.routeStatusTypeId === SCHEDULED &&
    geoFenceIsActive;

  if (!routeSummary) return null;
  const showGeoFenceAlert = (event: MouseEvent<HTMLDivElement>, readOnly = false) => {
    event.stopPropagation();
    setGeoFenceAlertVisible(true);
    if (!isGeoFenceAlertVisible) {
      closeGeoFenceAlertPopover.current = createPopover(
        event.target as HTMLElement,
        RouteGeoFenceAlertsForm,
        {
          alertsAreReadOnly: readOnly,
          geoFenceId: geoFence?.id,
          closeGeoFenceAlert: closeGeoFenceAlert,
        },
        {
          position: BOTTOM,
          tabletRightAlign: true,
        },
      );
    } else {
      closeGeoFenceAlert();
    }
  };

  const closeGeoFenceAlert = () => {
    if (isGeoFenceAlertVisible) {
      setGeoFenceAlertVisible(false);
    }
    if (closeGeoFenceAlertPopover.current) closeGeoFenceAlertPopover.current();
  };

  const handleSaveGeoFence = () => {
    const newGeoFence = {
      geoFenceCoordinates: map(polygonsInEdit, polygon => polygon.geometry.coordinates as number[][][]),
      vendorId,
      id: geoFence?.id || 0,
      geoFenceSettings: geoFence?.geoFenceSettings || [],
      isActive: true,
      routeName: routeSummary.name,
      routeId: routeSummary.routeId,
      startingLocationId: routeSummary.startingLocationId,
    };

    const routeId = (routeSummary.routeId || routeTemplateId) as number;

    saveRouteGeoFence(
      routeId,
      newGeoFence,
    )(dispatch)
      .then((data: GeoFence) => {
        const successMessage = geoFence?.id ? 'editSuccess' : 'saveSuccess';
        const isTemplate = !!routeTemplateId;
        loadRouteGeoFence(routeId, isTemplate)(dispatch);
        showRouteGeoFence(data?.id);
        exitDrawMode();
        createSuccessNotification(translate(`routes.geoFences.alertMessages.${successMessage}`));
      })
      .catch(() => {
        createErrorNotification(translate('routes.geoFences.alertMessages.saveError'));
      });
  };

  const handleDeleteGeoFence = async () => {
    if (!geoFence) return;

    if (!(await confirm(translate('routes.geoFences.alertMessages.confirmDeleteGeoFence')))) {
      return;
    }

    deleteGeoFences(
      vendorId,
      String(geoFence.id),
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.geoFences.alertMessages.geoFenceDeleted'));
        removeRouteGeoFence(geoFence.id);
        exitDrawMode();

        const isTemplate = !!routeTemplateId;
        loadRouteGeoFence((routeSummary.routeId || routeTemplateId) as number, isTemplate)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('routes.geoFences.alertMessages.geoFenceDeleteError'));
      });
  };

  const isUndoDisabled = !selectedGeoFence || size(selectedGeoFence.history) <= 1;

  return isRouteMapActionsOpen ? (
    <>
      <RouteMapActionsWrapper>
        <RouteMapActionsTitle>{translate('tooltips.mapActions')}</RouteMapActionsTitle>
        <RouteMapActionsClose onClick={closeRouteMapActions}>
          <RouteMapActionsCloseIcon />
        </RouteMapActionsClose>
        <RouteMapActionsContainer>
          <RouteMapActionsSection>
            {showGeoFenceActions && (
              <PermissionGuard permission={GEO_FENCES_DAILY_ROUTE || GEO_FENCES_ROUTE_TEMPLATE}>
                {!geoFence?.id && drawMode instanceof EditingMode && (
                  <>
                    <RouteMapAction onClick={handleSaveGeoFence}>
                      <IconButtonIcon size="large" icon="createGeoFence" />
                      <RouteMapActionText>{translate('routes.geoFences.saveGeoFence')}</RouteMapActionText>
                    </RouteMapAction>
                    {/* Delete GeoFence (if not saved) */}
                    <RouteMapAction onClick={exitDrawMode}>
                      <IconButtonIcon size="large" icon="deleteGeoFence" />
                      <RouteMapActionText>{translate('routes.geoFences.deleteGeoFence')}</RouteMapActionText>
                    </RouteMapAction>
                  </>
                )}
                {/* GeoFence edit actions */}
                {drawMode && (
                  <>
                    <RouteMapAction onClick={exitDrawMode}>
                      <IconButtonIcon size="xLarge" icon="cancelClear" />
                      <RouteMapActionText noLeftMargin>{translate('common.cancel')}</RouteMapActionText>
                    </RouteMapAction>
                    <RouteMapAction onClick={undoDraw} disabled={isUndoDisabled}>
                      <IconButtonIcon icon="betterUndo" size="large" />
                      <RouteMapActionText>{translate('payment.undo')}</RouteMapActionText>
                    </RouteMapAction>
                    <RouteMapAction
                      onClick={isAddingPolygon || !selectedGeoFence ? undefined : handleDeletePolygon}
                      disabled={isAddingPolygon || !selectedGeoFence}
                    >
                      <IconButtonIcon size="large" icon="deleteGeoFence" />
                      <RouteMapActionText>{translate('routes.geoFences.deletePolygon')}</RouteMapActionText>
                    </RouteMapAction>
                    <RouteMapAction onClick={isAddingPolygon ? undefined : handleAddPolygon} disabled={isAddingPolygon}>
                      <IconButtonIcon size="large" icon="createGeoFence" />
                      <RouteMapActionText>{translate('routes.geoFences.addPolygon')}</RouteMapActionText>
                    </RouteMapAction>

                    {!!geoFence?.id && (
                      <RouteMapAction onClick={handleSaveGeoFence}>
                        <IconButtonIcon size="large" icon="createGeoFence" />
                        <RouteMapActionText>{translate('routes.geoFences.saveGeoFence')}</RouteMapActionText>
                      </RouteMapAction>
                    )}
                  </>
                )}
                {!drawMode && (
                  <RouteMapAction onClick={geoFence?.id ? editGeoFence : enterDrawMode}>
                    <IconButtonIcon size="large" icon="createGeoFence" />
                    <RouteMapActionText>
                      {translate(geoFence?.id ? 'tooltips.editGeoFence' : 'routes.geoFences.doCreateGeoFence')}
                    </RouteMapActionText>
                  </RouteMapAction>
                )}
                {!!(geoFence && geoFence.id) && !drawMode && (
                  <>
                    <RouteMapAction onClick={handleDeleteGeoFence}>
                      <IconButtonIcon size="large" icon="deleteGeoFence" />
                      <RouteMapActionText>{translate('routes.geoFences.deleteGeoFence')}</RouteMapActionText>
                    </RouteMapAction>
                    <RouteMapAction onClick={showGeoFenceAlert}>
                      <IconButtonIcon size="large" icon="geoFenceAlert" />
                      <RouteMapActionText>{translate('routes.geoFences.setGeoFenceAlerts')}</RouteMapActionText>
                    </RouteMapAction>
                  </>
                )}
              </PermissionGuard>
            )}

            {routeSummary.routeStatusTypeId !== SCHEDULED && !!(geoFence && geoFence.id) && (
              <RouteMapAction onClick={e => showGeoFenceAlert(e, true)}>
                <IconButtonIcon size="large" icon="geoFenceAlert" />
                <RouteMapActionText>{translate('routes.geoFences.geoFenceAlerts')}</RouteMapActionText>
              </RouteMapAction>
            )}
          </RouteMapActionsSection>
          {isRubiconAdmin && (
            <RouteMapActionsSection position="bottom">
              <RouteMapAction onClick={() => setEventLogModalOpen(true)}>
                <IconButtonIcon size="large" icon="eventLog" />
                <RouteMapActionText>{translate('routes.eventLog')}</RouteMapActionText>
              </RouteMapAction>
            </RouteMapActionsSection>
          )}
        </RouteMapActionsContainer>
      </RouteMapActionsWrapper>
      {isEventLogModalOpen && <EventLogModalResolver closeModal={() => setEventLogModalOpen(false)} />}
    </>
  ) : null;
}
