import styled, { css } from 'styled-components';
import { BareButtonStyle } from '../../../core/components/styled';
import { cursorWidth } from '../mapWithTimeline/TimelineUtils';

export const OpenTimeline = styled.button<{ isDisabled?: boolean }>`
  ${BareButtonStyle}
  background-color: white;
  padding: 10px;

  ${props =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      color: ${props.theme.black};
      opacity: 0.5;
    `};
`;

export const TimelineContainer = styled.div<{ width: number; center?: boolean }>`
  padding-top: 30px;
  width: ${p => p.width}px;
  margin: auto;
  text-align: ${p => (p.center ? 'center' : 'initial')};
`;

export const Timeline = styled.div`
  width: 100%;
  position: relative;
  cursor: col-resize;
  margin-bottom: 30px;
`;

export const RouteTimeline = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 5px;
`;

export const TimelineCursor = styled.div`
  position: absolute;
  cursor: grab;
  top: -4px;
  bottom: -4px;
  width: ${cursorWidth}px;
  z-index: 1;
  background-color: black;
`;

export const TimelineControlsContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    margin: 10px;
    cursor: pointer;
  }
`;

export const TimelineCursorInnerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  span {
    position: absolute;
    top: -20px;
    left: -45px;
    height: 20px;
    width: 90px;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
  }

  div {
    position: absolute;
    left: -3px;
    right: -3px;
    top: 0;
    bottom: 0;
    transition: 0.1s;

    &:hover {
      background-color: black;
    }
  }
`;

export const Legend = styled.div`
  margin-top: 20px;
  justify-content: space-evenly;
  display: flex;

  & > div {
    padding: 0;
  }
`;

export interface StationaryTimeProps {
  startPosition: number;
  length: number;
  showBorder: boolean;
}

export const StationaryTimeIndicator = styled.div`
  position: relative;
  height: 100%;
  width: 2px;
  background-color: black;
  cursor: help;

  img {
    position: absolute;
    bottom: -16px;
    left: -5px;
  }
`;

export const StationaryTime = styled.div<StationaryTimeProps>`
  position: absolute;
  left: ${p => p.startPosition}px;
  top: 0;
  bottom: 0;
  ${p =>
    p.showBorder &&
    css`
      width: ${p.length}px;
      border-top: 2px dashed black;
      border-bottom: 2px dashed black;
      border-right: 2px dashed black;

      img {
        bottom: -18px;
      }
    `}
`;

export const Container = styled.div`
  position: relative;
  height: calc(100% - 50px);
`;

export const MapContainer = styled.div`
  height: calc(100% - 190px);

  *:focus {
    outline: none;
  }
`;

export const FollowCarContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 25%;

  *:focus {
    outline: none;
  }
`;

export const VehicleInfo = styled.div`
  padding: 10px;

  div {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
`;
