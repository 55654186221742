import React, { PureComponent } from 'react';
import { Switch as SwitchContainer, SwitchBar, SwitchHandle, SwitchText } from './styled';

interface Props {
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  margin?: string;
  onChange?: (value: any) => void;
  id?: string;
  size?: 'small' | 'medium' | 'large';
}

interface State {
  value: boolean;
}

class UnconnectedSwitch extends PureComponent<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { checked } = nextProps;
    if (checked !== prevState.value) {
      return { value: checked };
    }
    return prevState;
  }

  constructor(props: Props) {
    super(props);
    this.state = { value: !!props.checked };
  }

  toggle = (e: any) => {
    e.stopPropagation();
    this.setState(prevState => {
      const value = !prevState.value;

      const { onChange } = this.props;
      if (onChange) onChange(value);

      return { value };
    });
  };

  render() {
    const { label, disabled, margin, id, size } = this.props;
    const { value } = this.state;

    return (
      <SwitchContainer isDisabled={disabled} isActive={!!value} margin={margin} size={size}>
        <SwitchBar id={id} size={size} onClick={this.toggle}>
          <SwitchHandle size={size} />
        </SwitchBar>
        {!!label && <SwitchText size={size}>{label}</SwitchText>}
      </SwitchContainer>
    );
  }
}

export default UnconnectedSwitch;
