import { Modal } from 'src/core/components';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Grid, GridColumn, ModalSection, ModalTitle, PanelSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import SendBillForm from '../forms/SendBillForm';
import { useSelector } from 'src/core/hooks/useSelector';

interface Props {
  onCancel: any;
  onSendBill?: any;
  billInfo: {
    status: number;
    siteAddress: string;
    siteName: string;
    customerName: string;
    billId: number;
  };
}

const SendBillModal = ({ onCancel, onSendBill, billInfo }: Props) => {
  const { emailRecipients, isLoading } = useSelector(state => state.finance.sendBill);

  const initialValues = {
    emailRecipients: emailRecipients || [],
  };

  return (
    <Modal padding="small no no no" isLoading={false} size="mediumLarge" onClose={onCancel}>
      <ModalTitle margin="small no">
        {translate('finance.sendBill')} #{billInfo.billId}
      </ModalTitle>
      <ModalSection padding="no" fluid>
        <PanelSection vertical padding="medium" isLoading={isLoading}>
          <Grid margin="no">
            <GridColumn size="6/12" padding="no small no no">
              <LabeledDataView label="customer name" value={billInfo.customerName || '–'} />
            </GridColumn>
            <GridColumn size="6/12" padding="no small no no">
              <LabeledDataView label="location" value={billInfo.siteName || '-'} />
            </GridColumn>
          </Grid>
          <Grid margin="no">
            <GridColumn size="8/12" padding="no small no no">
              <LabeledDataView label="Address" value={billInfo.siteAddress || '–'} />
            </GridColumn>
            <GridColumn size="4/12" padding="no small no no">
              <LabeledDataView label="Status" value={billInfo.status || '-'} />
            </GridColumn>
          </Grid>
        </PanelSection>
      </ModalSection>

      <SendBillForm onSubmit={onSendBill} onCancel={onCancel} initialValues={initialValues} />
    </Modal>
  );
};

export default SendBillModal;
