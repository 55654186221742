import { filter, map, orderBy } from 'lodash-es';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, change, getFormValues, reduxForm } from 'redux-form';

import { vendorGusIdSelector } from 'src/account/ducks';
import { PanelSearch, Switch, TypedField } from 'src/core/components';
import { Button, Separator, Tab, Tabs, Text } from 'src/core/components/styled';
import { TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { RouteTemplateStatus } from 'src/routes/components/styled/RouteTemplateBuilderMap';
import { NONE_ZERO_ID } from 'src/routes/constants/routePriorityTypes';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import {
  DashboardFilterContainer,
  DashboardFilterDetailScreenSection,
  DashboardFilterPanel,
  DashboardFilterPanelContent,
  DashboardFilterSection,
  DashboardFilterSubtitle,
  DashboardFilterTitle,
  DashboardFilterTitleWrapper,
} from '../styled/DashboardFilterMapbox';
import { DashboardFilterCategoryMapbox } from './dashboardFilterFormSections';
import {
  LastActivityFiltersSection,
  RoutePriorityFilterSection,
  SearchableFiltersSection,
  SegmentStatusFilterSection,
  ServiceActivityFilterSection,
} from './snowSweeperFilterFormSections';
import { useMemoizedDashboardFilters } from 'src/dashboard/hooks/useMemoizedDashboardFilters';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { getNumberOfFiltersSelectedLabel } from './snowSweeperFilterFormSections/utils';

interface PropsWithoutReduxForm {
  isCollapsed?: boolean;
  isSnowPlow: boolean;
  isStreetSweeper: boolean;
}

export const SNOW_SWEEPER_FILTERS_FORM_NAME = 'SnowSweeperFiltersForm';

type FormDictionaryType = Dictionary<boolean>;

export interface SnowSweeperFiltersFormValues {
  lastActivity: FormDictionaryType;
  pickupStatusTypeIds: FormDictionaryType;
  priorityTypeIds: FormDictionaryType;
  serviceZoneIds: FormDictionaryType;
  isAll_serviceZoneIds: boolean;
  showVehiclePositions: boolean;
  groupIds: FormDictionaryType;
  isAll_groupIds: boolean;
  routeId: any;
  searchTerm: string;
  isLastActivity: boolean;
  serviceActivityIds: FormDictionaryType;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<SnowSweeperFiltersFormValues, PropsWithoutReduxForm>;

enum FILTER_TABS {
  lastActivity = 'lastActivity',
  segmentStatus = 'segmentStatus',
  serviceZone = 'serviceZone',
  groups = 'groups',
  routePriority = 'routePriority',
  routeTemplate = 'routeTemplate',
  serviceActivity = 'serviceActivity',
}

const SnowSweeperFiltersForm: FC<Props> = ({ isCollapsed, isSnowPlow, isStreetSweeper, handleSubmit }) => {
  const dispatch = useDispatch();

  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));

  const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;

  const serviceZones = useSelector(state => state.routes.serviceZones.serviceZones);
  const routes = useSelector(state => state.dashboard.alternativeFleetOps.routes);
  const groups = useSelector(state => state.routes.groups.groups);

  const serviceZonesFilters = useMemo(() => {
    const filters = map(
      orderBy(
        filter(serviceZones, (s: ServiceZone) => !!s.id),
        ['isActive', 'name'],
        ['desc', 'asc'],
      ),
      (serviceZone: ServiceZone) => ({
        name: serviceZone.name,
        id: serviceZone.id,
        rightContent: serviceZone.isActive ? null : (
          <RouteTemplateStatus neutral margin="xxSmall no xxSmall xxSmall">
            {translate('common.inactive')}
          </RouteTemplateStatus>
        ),
      }),
    );
    filters.unshift({
      name: translate('dashboard.none'),
      id: NONE_ZERO_ID,
      rightContent: null,
    });

    return filters;
  }, [serviceZones]);

  const groupFilters = useMemo(() => {
    const filters = map(orderBy(groups, ['isActive', 'groupName'], ['desc', 'asc']), group => ({
      name: group.groupName,
      id: group.id,
      rightContent: group.isActive ? null : (
        <RouteTemplateStatus neutral margin="xxSmall no xxSmall xxSmall">
          {translate('common.inactive')}
        </RouteTemplateStatus>
      ),
    }));

    filters.unshift({
      name: translate('routes.groups.noGroup'),
      id: NONE_ZERO_ID,
      rightContent: null,
    });

    return filters;
  }, [groups]);

  const [openedCategory, setOpenedCategory] = useState({
    lastActivity: true,
    segmentStatus: false,
    serviceZone: false,
    groups: false,
    routePriority: false,
    routeTemplate: false,
    driver: false,
    serviceActivity: false,
  });

  const toggleCategoryHandler = (category: FILTER_TABS) => {
    setOpenedCategory({
      ...openedCategory,
      [category]: !openedCategory[category],
    });
  };

  const toggleSegmentStatusOrLastActivity = () => {
    // if segment status is open, close it and open last activity
    if (openedCategory.segmentStatus) {
      setOpenedCategory({
        ...openedCategory,
        segmentStatus: false,
        lastActivity: true,
      });
      dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'isLastActivity', true));
    }
    // if last activity is open, close it and open segment status
    else if (openedCategory.lastActivity) {
      setOpenedCategory({
        ...openedCategory,
        segmentStatus: true,
        lastActivity: false,
      });
      dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'isLastActivity', false));
    }
  };

  const reapplyLastFilters = useMemoizedDashboardFilters(
    isSnowPlow ? 'snowOps-lastDashboardFilters' : 'sweeperOps-lastDashboardFilters',
    SNOW_SWEEPER_FILTERS_FORM_NAME,
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DashboardFilterContainer isOpen={!isCollapsed} isLarger={!isVendorWithGusId} noWider>
        <DashboardFilterPanel>
          <DashboardFilterPanelContent>
            <DashboardFilterSection>
              <DashboardFilterTitleWrapper>
                <DashboardFilterTitle>{translate('dashboard.filters')} </DashboardFilterTitle>
                <Text>{TODAY_FORMATTED}</Text>
              </DashboardFilterTitleWrapper>
              <Button onClick={reapplyLastFilters} color="primary" text>
                {translate('dashboard.applyLastFilters')}
              </Button>
              <DashboardFilterSubtitle>{translate('dashboard.filtersWillBeApplied')}</DashboardFilterSubtitle>
            </DashboardFilterSection>
            <DashboardFilterSection>
              <TypedField
                name="searchTerm"
                component={PanelSearch}
                props={{ margin: 'small no small no', borderBottom: true, minWidth: '250px', isClearable: true }}
              />
              <DashboardFilterDetailScreenSection>
                <Tabs fullWidth margin="no">
                  <Tab
                    flexDisplay
                    isSelected={openedCategory.lastActivity}
                    onClick={() => toggleSegmentStatusOrLastActivity()}
                    small
                  >
                    {translate('dashboard.latestActivity')}
                  </Tab>
                  <Tab
                    flexDisplay
                    isSelected={openedCategory.segmentStatus}
                    onClick={() => toggleSegmentStatusOrLastActivity()}
                    small
                  >
                    {translate('dashboard.todayStatus')}
                  </Tab>
                </Tabs>

                {openedCategory.lastActivity && (
                  <LastActivityFiltersSection isSnowPlow={isSnowPlow} isStreetSweeper={isStreetSweeper} />
                )}

                {openedCategory.segmentStatus && (
                  <SegmentStatusFilterSection isSnowPlow={isSnowPlow} isStreetSweeper={isStreetSweeper} />
                )}

                <Separator margin="small no" size={2} color="grayDark" />
              </DashboardFilterDetailScreenSection>

              <DashboardFilterCategoryMapbox
                title={translate('dashboard.filterKeys.serviceActivity')}
                isOpen={openedCategory.serviceActivity}
                category={FILTER_TABS.serviceActivity}
                toggleCategory={toggleCategoryHandler}
                id="dashboard-filter-serviceActivity"
                selectedLabel={getNumberOfFiltersSelectedLabel(formValues.serviceActivityIds)}
              >
                <DashboardFilterDetailScreenSection>
                  <ServiceActivityFilterSection isSnowPlow={isSnowPlow} isStreetSweeper={isStreetSweeper} />
                </DashboardFilterDetailScreenSection>
              </DashboardFilterCategoryMapbox>
              <DashboardFilterCategoryMapbox
                title={translate('dashboard.filterKeys.routePriority')}
                isOpen={openedCategory.routePriority}
                category={FILTER_TABS.routePriority}
                toggleCategory={toggleCategoryHandler}
                id="dashboard-filter-routePriority"
                selectedLabel={getNumberOfFiltersSelectedLabel(formValues.priorityTypeIds)}
              >
                <DashboardFilterDetailScreenSection>
                  <RoutePriorityFilterSection />
                </DashboardFilterDetailScreenSection>
              </DashboardFilterCategoryMapbox>
              <DashboardFilterCategoryMapbox
                title={translate('routes.serviceZone')}
                isOpen={openedCategory.serviceZone}
                category={FILTER_TABS.serviceZone}
                toggleCategory={toggleCategoryHandler}
                id="dashboard-filter-serviceZone"
                selectedLabel={getNumberOfFiltersSelectedLabel(formValues.serviceZoneIds, 'isAll_serviceZoneIds')}
              >
                <DashboardFilterDetailScreenSection>
                  <SearchableFiltersSection
                    filters={serviceZonesFilters || []}
                    filterName="serviceZoneIds"
                    formName={SNOW_SWEEPER_FILTERS_FORM_NAME}
                  />
                </DashboardFilterDetailScreenSection>
              </DashboardFilterCategoryMapbox>
              <DashboardFilterCategoryMapbox
                title={translate('routes.groups.groups')}
                isOpen={openedCategory.groups}
                category={FILTER_TABS.groups}
                toggleCategory={toggleCategoryHandler}
                id="dashboard-filter-groups"
                selectedLabel={getNumberOfFiltersSelectedLabel(formValues.groupIds, 'isAll_groupIds')}
              >
                <DashboardFilterDetailScreenSection>
                  <SearchableFiltersSection
                    filters={groupFilters || []}
                    filterName="groupIds"
                    formName={SNOW_SWEEPER_FILTERS_FORM_NAME}
                  />
                </DashboardFilterDetailScreenSection>
              </DashboardFilterCategoryMapbox>

              <DashboardFilterCategoryMapbox
                title={translate('routes.route')}
                isOpen={openedCategory.routeTemplate}
                category={FILTER_TABS.routeTemplate}
                toggleCategory={toggleCategoryHandler}
                id="dashboard-filter-route"
                selectedLabel={getNumberOfFiltersSelectedLabel(formValues.routeId) || undefined}
              >
                <DashboardFilterDetailScreenSection>
                  <SearchableFiltersSection
                    filters={routes || []}
                    filterName="routeId"
                    formName={SNOW_SWEEPER_FILTERS_FORM_NAME}
                    cantCheckAll
                    maxOneSelectable
                  />
                </DashboardFilterDetailScreenSection>
              </DashboardFilterCategoryMapbox>
            </DashboardFilterSection>
            <DashboardFilterSection>
              <DashboardFilterDetailScreenSection>
                <Separator margin="small no" size={2} color="grayDark" />
                <TypedField
                  name="showVehiclePositions"
                  component={Switch}
                  props={{
                    id: 'dashboard-filter-showVehiclePositions',
                    label: translate('dashboard.showVehiclePositions'),
                    margin: 'no no small no',
                  }}
                />
              </DashboardFilterDetailScreenSection>
            </DashboardFilterSection>
          </DashboardFilterPanelContent>
        </DashboardFilterPanel>
      </DashboardFilterContainer>
    </form>
  );
};

export default reduxForm<SnowSweeperFiltersFormValues, PropsWithoutReduxForm>({
  form: SNOW_SWEEPER_FILTERS_FORM_NAME,
  enableReinitialize: true,
  onSubmit: () => {},
})(SnowSweeperFiltersForm);
