import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteImage } from '../interfaces/RouteImages';
import { loadRouteImages as doLoadRouteImages } from '../services/routeLocationImages';

// Actions
const START_LOAD = 'routes/routeImages/START_LOAD';
export const COMPLETE_LOAD = 'routes/routeImages/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeImages/FAIL_LOAD';
const RESET = 'routes/routeImages/RESET';

interface State {
  isLoading: boolean,
  isEmailing: boolean,
  routeImages: RouteImage[],
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isEmailing: false,
  routeImages: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeImages: action.routeImages,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeImages: RouteImage[]) => ({
  type: COMPLETE_LOAD,
  routeImages,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteImages = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRouteImagesPromise = doLoadRouteImages(routeId);
  loadRouteImagesPromise.then(routeImages => dispatch(completeLoad(routeImages))).catch(() => dispatch(failLoad()));
  return loadRouteImagesPromise;
};

export const resetRouteImages = () => ({
  type: RESET,
});
