import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import { History } from 'history';

import { reducer as accountReducer } from './account/ducks';
import { reducer as commonReducer } from './common/ducks';
import { reducer as coreReducer } from './core/ducks';
import { reducer as customersReducer } from './customers/ducks';
import { reducer as dashboardReducer } from './dashboard/ducks';
import { reducer as fleetReducer } from './fleet/ducks';
import { reducer as haulerProfileReducer } from './haulerProfile/ducks';
import { reducer as inferenceAuditReducer } from './inferenceAudit/ducks';
import { reducer as insightsReducer } from './insights/ducks';
import { reducer as opportunityReducer } from './opportunity/ducks';
import { reducer as paymentsReducer } from './payments/ducks';
import { reducer as routeImportReducer } from './routeImport/ducks';
import { reducer as routesReducer } from './routes/ducks';
import { reducer as settingsReducers } from './settings/ducks';
import { reducer as vendorsReducer } from './vendors/ducks';
import { reducer as messagingReducer } from './messaging/ducks/messaging';
import { reducer as financeReducer } from './finance/ducks';

import gaMiddleware from './core/services/gaMiddleware';
import history from './core/services/history';

const router = routerMiddleware(history);

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    google: any;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    account: accountReducer,
    dashboard: dashboardReducer,
    settings: settingsReducers,
    routeImport: routeImportReducer,
    fleet: fleetReducer,
    common: commonReducer,
    insights: insightsReducer,
    opportunity: opportunityReducer,
    routes: routesReducer,
    customers: customersReducer,
    vendors: vendorsReducer,
    core: coreReducer,
    haulerProfile: haulerProfileReducer,
    payments: paymentsReducer,
    inferenceAudit: inferenceAuditReducer,
    messaging: messagingReducer,
    finance: financeReducer,
  });

export type AppState = ReturnType<ReturnType<typeof rootReducer>>;

const store = createStore(rootReducer(history), composeEnhancers(applyMiddleware(thunk, router, gaMiddleware)));

export default store;
