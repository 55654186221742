import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';
import { loadSnowPlowSegmentList as doLoadSnowPlowSegmentList } from '../services/loadSnowPlowSegmentList';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
// Constants
const START_LOAD = 'routes/segmentList/START_LOAD';
const COMPLETE_LOAD = 'routes/segmentList/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/segmentList/FAIL_LOAD';
const RESET = 'routes/segmentList/RESET';

// Initial State

type State = {
  segmentList: StreetNetwork[];
  isLoading: boolean;
};

const initialState: State = {
  segmentList: [],
  isLoading: false,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          segmentList: action.segmentList,
        },
      });
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          segmentList: [],
        },
      });
    case RESET:
      return update(state, {
        $merge: initialState,
      });
    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (segmentList: StreetNetwork) => ({
  type: COMPLETE_LOAD,
  segmentList,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const reset = () => {
  return {
    type: RESET,
  };
};

// Async Actions
export const loadSnowPlowSegmentList =
  (vendorId: number, segmentIds: number[], noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoad());
    const loadSnowPlowSegmentListPromise = doLoadSnowPlowSegmentList(vendorId, segmentIds);
    loadSnowPlowSegmentListPromise
      .then(segmentList => dispatch(completeLoad(segmentList)))
      .catch(() => dispatch(failLoad()));
    return loadSnowPlowSegmentListPromise;
  };
