import React, { PureComponent } from 'react';

import {
  DashboardFilterCategory as DashboardFilterCategoryContainer,
  DashboardFilterCategoryHeader,
  DashboardFilterCategoryHeaderIcon,
  DashboardFilterCategoryBody,
} from '../../styled';
import { Collapsible } from '../../../../core/components';

interface Props {
  title: string;
  isDisabled: boolean;
  isOpen: boolean;
  category: string;
  children: React.ReactNode;
  id?: string;
  toggleCategory(category: string): void;
}

class DashboardFilterCategory extends PureComponent<Props> {
  toggleCategory = () => {
    const { category, toggleCategory } = this.props;

    toggleCategory(category);
  };

  render() {
    const { title, isDisabled, isOpen, children, id } = this.props;

    return (
      <DashboardFilterCategoryContainer isOpen={isOpen} isDisabled={isDisabled} id={id}>
        <DashboardFilterCategoryHeader onClick={this.toggleCategory}>
          <DashboardFilterCategoryHeaderIcon /> {title}
        </DashboardFilterCategoryHeader>
        <DashboardFilterCategoryBody>
          <Collapsible isOpen={isOpen}>{children}</Collapsible>
        </DashboardFilterCategoryBody>
      </DashboardFilterCategoryContainer>
    );
  }
}

export default DashboardFilterCategory;
