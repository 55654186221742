import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { AccountPanelError, AccountPanelLink } from '../styled';
import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { isRequired, isEmail } from '../../../utils/services/validator';
import { LoginFormTitle } from '../styled/LoginPage';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

export interface LoginFormValues {
  email: string;
  password: string;
}

interface PropsWithoutReduxForm {
  isLoginFailed: boolean;
  isAccountLocked: boolean;
  isSSoEnabled: boolean;
  ssoError?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<LoginFormValues, PropsWithoutReduxForm>;

const LoginForm: React.FC<Props> = ({ isLoginFailed, isAccountLocked, isSSoEnabled, ssoError, handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <LoginFormTitle>{translate('account.signInToRubicon')}</LoginFormTitle>

    <Field
      name="email"
      component={Input}
      label={translate('common.email')}
      type="text"
      validate={[isRequired, isEmail]}
    />
    <Field
      name="password"
      component={Input}
      label={translate('common.password')}
      type="password"
      validate={[isRequired]}
    />

    {isLoginFailed && <AccountPanelError>{translate('account.tryAgain')}</AccountPanelError>}

    {isAccountLocked && <AccountPanelError>{translate('account.accountLocked')}</AccountPanelError>}

    {isSSoEnabled && <AccountPanelError>{translate('account.singleSignOnActive')}</AccountPanelError>}

    {ssoError && <AccountPanelError>{ssoError}</AccountPanelError>}

    <AccountPanelLink to="/account/forgot-password">{translate('account.forgotYourPassword')}</AccountPanelLink>

    <ButtonSet>
      <Button type="submit" color="primary">
        {translate('account.login')}
      </Button>
    </ButtonSet>
  </form>
);

export default reduxForm<LoginFormValues, PropsWithoutReduxForm>({
  form: 'login',
  onSubmitFail: focusFirstInvalidField,
})(LoginForm);
