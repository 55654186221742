import React from 'react';

import { Feature } from '../../interfaces/ModelFeature';
import { TableCell, TableRow } from '../../../core/components/styled';
import { UnconnectedInput, UnconnectedSwitch } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  isEnabled: boolean;
  modelFeature: Feature;
  versionNumber: string;
  rowProps: any;
}

const AiModelModalTableRow: React.FC<Props> = ({
  isEnabled,
  modelFeature: { id, technicalName },
  rowProps: { disableRowCheck, onConfigurationVersionChange, toggleAIModelTableRowSwitch },
  versionNumber,
}) => (
  <TableRow>
    <TableCell style={{ paddingLeft: '13%' }} width="60%">
      {translate(createTranslationKey(technicalName, 'vendors.aiModel'))}
    </TableCell>
    <TableCell width="20%" align="right">
      <UnconnectedSwitch
        disabled={disableRowCheck(technicalName)}
        checked={isEnabled}
        onChange={toggleAIModelTableRowSwitch(id)}
      />
    </TableCell>
    <TableCell width="20%" align="right">
      <UnconnectedInput margin="xSmall no no" onChange={onConfigurationVersionChange(id)} value={versionNumber} />
    </TableCell>
  </TableRow>
);

export default AiModelModalTableRow;
