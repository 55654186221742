import { map, filter } from 'lodash-es';

import { RateCodeTypes } from '../interfaces/RateCodeTypes';

const transformRateCodeAccountingTypes = (rateCodeTypes: RateCodeTypes[]) =>
  map(
    filter(rateCodeTypes, { isActive: true }), // Only include active rate codes
    rateCode => ({
      ...rateCode,
      value: rateCode.id,
      label: rateCode.code,
    }),
  );

export default transformRateCodeAccountingTypes;
