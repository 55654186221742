import { ChangeEvent, FC } from 'react';
import { getFormValues } from 'redux-form';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Button, Grid, GridColumn, ModalFixedFooter } from 'src/core/components/styled';
import { Dropdown, Input, MultiSelect } from 'src/core/components';
import { isRequired } from 'src/utils/services/validator';
import { NEW_RATE_ACCOUNTING_CODE_ID } from 'src/finance/constants';
import { TypedField } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export interface RateConfigurationAddRateFormValues {
  accountingCodeDescription?: string;
  code?: string;
  description?: string;
  enabledForServiceTypeIds?: number[];
  rateAccountingCode?: string;
  rateAccountingCodeDescription?: string;
  rateAccountingCodeId?: number;
  rateCode?: string;
  rateCodeDescription?: string;
  validUOMsForServiceType?: number[];
}

type PropsWithoutReduxForm = {
  initialValues: RateConfigurationAddRateFormValues;
  onCancel: () => void;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<RateConfigurationAddRateFormValues, PropsWithoutReduxForm>;

export const RATE_CONFIURATION_ADD_RATE_FORM_NAME = 'rateConfigurationAddRateForm';

const RateConfigurationAddRateForm: FC<Props> = ({ change, handleSubmit, onCancel }) => {
  const serviceTypes = useSelector(state => state.common.serviceTypes.serviceTypes);
  const { billingUnitOfMeasureTypes, rateAccountingCodes } = useSelector(state => state.finance.rateManager);
  const formValues: RateConfigurationAddRateFormValues = useSelector(state =>
    getFormValues(RATE_CONFIURATION_ADD_RATE_FORM_NAME)(state),
  );

  const serviceTypeOptions = serviceTypes.map(serviceType => ({
    label: serviceType.name,
    value: serviceType.id,
  }));

  const uomOptions = billingUnitOfMeasureTypes.map(uom => ({
    label: uom.name,
    value: uom.id,
  }));

  const rateAccountingCodeOptions = rateAccountingCodes.map(rateAccountingCode => ({
    label: rateAccountingCode.code,
    value: rateAccountingCode.id,
  }));

  rateAccountingCodeOptions.unshift({
    label: (
      <Button color="primary" size="xSmall">
        {translate('finance.rateManager.newRateAccountingCode')}
      </Button>
    ) as any,
    value: NEW_RATE_ACCOUNTING_CODE_ID,
  });

  const onRateAccountingCodeIdChanged = (value: ChangeEvent | number) => {
    const currentAccountingCode = rateAccountingCodes.find(code => code.id === Number(value));
    change('rateAccountingCodeDescription', currentAccountingCode?.description);
  };

  const isNewRateAccountingCode = formValues?.rateAccountingCodeId === NEW_RATE_ACCOUNTING_CODE_ID;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid padding="small medium sMedium medium" multiLine>
        <GridColumn size="6/12" padding="no xSmall">
          <TypedField
            name="enabledForServiceTypeIds"
            component={MultiSelect}
            props={{
              label: translate('common.serviceType'),
              options: serviceTypeOptions,
              margin: 'no',
              defaultToAll: false,
            }}
            validate={[isRequired]}
          />
        </GridColumn>

        <GridColumn size="6/12" padding="no xSmall">
          <TypedField
            name="validUOMsForServiceType"
            component={MultiSelect}
            props={{
              label: translate('finance.rateManager.uom.uom'),
              options: uomOptions,
              margin: 'no',
              defaultToAll: false,
            }}
            validate={[isRequired]}
          />
        </GridColumn>
      </Grid>

      <Grid padding="no medium sMedium medium" multiLine>
        <GridColumn size="6/12" padding="no xSmall">
          <TypedField
            name="rateCode"
            component={Input}
            props={{
              label: translate('finance.rateManager.rateCode'),
              margin: 'no',
            }}
            validate={[isRequired]}
          />
        </GridColumn>
        <GridColumn size="6/12" padding="no xSmall">
          <TypedField
            name="rateCodeDescription"
            component={Input}
            props={{
              label: translate('finance.rateManager.rateCodeDescription'),
              margin: 'no',
            }}
            validate={[isRequired]}
          />
        </GridColumn>
      </Grid>

      <Grid padding={isNewRateAccountingCode ? 'no medium sMedium medium' : 'no medium large medium'} multiLine>
        <GridColumn size="6/12" padding="no xSmall">
          <TypedField
            name="rateAccountingCodeId"
            component={Dropdown}
            props={{
              label: translate('finance.rateManager.accountingCode'),
              options: rateAccountingCodeOptions,
              margin: 'no',
              isClearable: true,
            }}
            onChange={onRateAccountingCodeIdChanged}
          />
        </GridColumn>
        {!isNewRateAccountingCode && (
          <GridColumn size="6/12" padding="no xSmall">
            <TypedField
              name="rateAccountingCodeDescription"
              component={Input}
              props={{
                label: translate('finance.rateManager.accountingCodeDescription'),
                margin: 'no',
                disabled: true,
                isClearable: true,
              }}
            
            />
          </GridColumn>
        )}
      </Grid>

      {isNewRateAccountingCode && (
        <Grid padding="no medium large medium" multiLine>
          <GridColumn size="6/12" padding="no xSmall">
            <TypedField
              name="rateAccountingCode"
              component={Input}
              props={{
                label: translate('finance.rateManager.accountingCode'),
                margin: 'no',
              }}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no xSmall">
            <TypedField
              name="rateAccountingCodeDescription"
              component={Input}
              props={{
                label: translate('finance.rateManager.accountingCodeDescription'),
                margin: 'no',
              }}
              validate={[isRequired]}
            />
          </GridColumn>
        </Grid>
      )}

      <ModalFixedFooter isShadowed>
        <Button type="submit" margin="no xSmall" color="primary">
          {translate('common.save')}
        </Button>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => onCancel()}>
          {translate('common.cancel')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<RateConfigurationAddRateFormValues, PropsWithoutReduxForm>({
  form: RATE_CONFIURATION_ADD_RATE_FORM_NAME,
  enableReinitialize: true,
})(RateConfigurationAddRateForm);
