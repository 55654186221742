import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CITY_ALERTS_ICON_TYPES } from 'src/common/constants';
import { clearDashboardSelectedFeature, setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { DASHBOARD_CITY_ALERTS_LAYER, DASHBOARD_CLUSTERS_SOURCE } from 'src/dashboard/constants/dashboardMapGL';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import DashboardCityAlertGLPopupResolver from './DashboardCityAlertGLPopupResolver';
import DashboardCityAlertsGLLayer from './DashboardCityAlertsGLLayer';

const mapImages = CITY_ALERTS_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: DASHBOARD_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const DashboardCityAlertsGL: FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_CLUSTERS_SOURCE && feature.properties?.layer === DASHBOARD_CITY_ALERTS_LAYER,
          );

        if (!!feature) {
          dispatch(clearDashboardSelectedFeature());
          dispatch(setDashboardSelectedFeature('cityAlerts', feature.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <DashboardCityAlertsGLLayer />
      <DashboardCityAlertGLPopupResolver />
    </>
  );
};

export default DashboardCityAlertsGL;
