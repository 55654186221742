import React from 'react';
import { MarkerClusterer, MarkerClustererProps } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';

interface Props extends MarkerClustererProps {
  clusterizeSmall?: boolean;
  maxZoom?: number;
  children: (markerClusterer: Clusterer) => JSX.Element;
}

export const RubiconMarkerClusterer: React.FC<Props> = ({ maxZoom, clusterizeSmall, children, ...rest }) => (
  <MarkerClusterer
    averageCenter
    enableRetinaIcons
    gridSize={clusterizeSmall ? 60 : 30}
    maxZoom={clusterizeSmall ? maxZoom : 10}
    minimumClusterSize={clusterizeSmall ? 4 : 10}
    {...rest}
  >
    {clusterer => children(clusterer)}
  </MarkerClusterer>
);
