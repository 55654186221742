import { debounce, forEach, size } from 'lodash-es';
import { Marker } from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';

import { MapGL } from 'src/common/components/map/MapGL';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import { getMapBounds } from 'src/common/components/map/util';
import { Facility } from 'src/common/interfaces/Facility';
import { IconButtonIcon } from 'src/core/components/styled';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { theme } from 'src/core/styles';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { clearRouteMapSelectedFeature, setRouteMapViewport } from 'src/routes/ducks';
import FacilitiesClustersGL from './FacilitiesClustersGL';
import FacilitiesGeoFenceGL from './FacilitiesGeoFenceGL';
import FacilitiesGL from './FacilitiesGL';

interface Props {
  facilities?: Facility[];
  isSingleFacility?: boolean;
  address?: any;
  geoFenceCoord?: any[];
  updateGeoFence?: (json: null | any[][]) => void;
}

const FacilitiesMapGL = ({ facilities, isSingleFacility, address, geoFenceCoord, updateGeoFence }: Props) => {
  const editorRef = useRef<Editor>(null);
  const dispatch = useDispatch();

  const [map, setMap] = useState<mapboxgl.Map>();
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [polygon, setPolygon] = useState<any>(null);
  const [marker] = useState<Marker>(new Marker({ color: theme.brandPrimary }));

  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });
  const dragPan = useSelector(s => s.routes.routeMapSettings.dragPan);
  const { viewport } = useSelector(state => state.routes.mapControls);

  const geoFenceGeoJson = useMemo(() => {
    if (geoFenceCoord && !isDrawingEnabled) {
      return {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [geoFenceCoord],
        },
        properties: {},
      };
    }
    return null;
  }, [geoFenceCoord, isDrawingEnabled]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: map?.getCenter(),
      mapZoom: map?.getZoom(),
    });
  };

  const updateFormDebounced = useMemo(
    () =>
      debounce((json: any) => {
        updateGeoFence && updateGeoFence(json);
      }, 500),
    [updateGeoFence],
  );

  const handleUpdateEditor = useCallback(
    (event: any) => {
      const newPolygon = event.data[0];

      if (!newPolygon) return;

      setPolygon(newPolygon);

      if (event.editType === 'addFeature') {
        setDrawMode(new EditingMode());
      }

      const { geometry } = newPolygon;
      const { coordinates } = geometry;
      const json = coordinates[0].map((coord: any) => [coord[0], coord[1]]);
      updateFormDebounced(json);
    },
    [updateFormDebounced],
  );

  const handleClearGeoFence = useCallback(() => {
    updateGeoFence && updateGeoFence(null);
    setPolygon(null);
    if (isDrawingEnabled) setDrawMode(new DrawPolygonMode());
  }, [isDrawingEnabled, updateGeoFence]);

  const handleEnterDrawMode = useCallback(() => {
    if (!isDrawingEnabled) {
      setIsDrawingEnabled(true);
      if (geoFenceCoord) {
        setDrawMode(new EditingMode());
        setPolygon({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [geoFenceCoord],
          },
          properties: {},
        });
      } else setDrawMode(new DrawPolygonMode());
    } else {
      setIsDrawingEnabled(false);
      setDrawMode(undefined);
    }
  }, [geoFenceCoord, isDrawingEnabled]);

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const features = map.queryRenderedFeatures(event.point).filter(feature => !!feature.properties?.cluster_id);
        if (features.length) {
          dispatch(clearRouteMapSelectedFeature());
        }
      });
      map.on('mousemove', event => {
        const features = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch]);

  useEffect(() => {
    if (isSingleFacility && map && address && address.latitude && address.longitude) {
      marker.remove();
      marker.setLngLat([address.longitude, address.latitude]);
      marker.addTo(map);
    }
  }, [isSingleFacility, map, address, marker]);

  // map viewport to fit all features
  useEffect(() => {
    if (isDrawingEnabled) return;
    const points: { latitude: number; longitude: number }[] = [];
    if (facilities)
      facilities.forEach(facility => {
        points.push({ latitude: facility.address.latitude, longitude: facility.address.longitude });
      });
    if (isSingleFacility) {
      if (address && address.latitude && address.longitude) {
        points.push({ latitude: address.latitude, longitude: address.longitude });
      }

      if (geoFenceCoord) {
        forEach(geoFenceCoord, coord => {
          points.push({ latitude: coord[1], longitude: coord[0] });
        });
      }
    }
    if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      dispatch(setRouteMapViewport(bounds));
    } else if (!!points.length) {
      const bounds = getMapBounds(points, {
        capZoom: 16,
      });
      dispatch(setRouteMapViewport(bounds));
    }
  }, [
    address,
    dispatch,
    facilities,
    geoFenceCoord,
    isDrawingEnabled,
    isSingleFacility,
    mapViewStyle.mapCenter,
    mapViewStyle.mapZoom,
  ]);

  return (
    <MapGLWrapper drawingEnabled={isDrawingEnabled} isDrawing={isDrawingEnabled}>
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        setIsSatelliteViewEnabled={handleMapViewStyleChange}
        onMapRefLoaded={setMap}
        viewport={viewport}
        dragPan={dragPan}
        enableNewNavigationControl
      >
        {drawMode && (
          <Editor
            ref={editorRef}
            clickRadius={12}
            mode={drawMode}
            onUpdate={handleUpdateEditor}
            features={polygon ? [polygon] : []}
          />
        )}

        {map && (
          <>
            {facilities && !!size(facilities) && (
              <>
                <FacilitiesClustersGL map={map} facilities={facilities} />
                <FacilitiesGL map={map} facilities={facilities} />
              </>
            )}
            {isSingleFacility && geoFenceGeoJson && (
              <FacilitiesGeoFenceGL geoFencesGeoJSON={geoFenceGeoJson} isSatellite={mapViewStyle.isSatelliteEnabled} />
            )}
          </>
        )}

        {isSingleFacility && (
          <ComplexMapControl position="top-left">
            <TooltipIconButton
              tooltipAsString
              tooltip={translate(
                isDrawingEnabled ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
              )}
              tooltipPosition="right"
              tooltipColor="grayDarker"
              margin="small"
              color={isDrawingEnabled ? 'primary' : 'secondary'}
              onClick={handleEnterDrawMode}
            >
              <IconButtonIcon icon="edit" margin="no" />
            </TooltipIconButton>
            {geoFenceCoord && (
              <TooltipIconButton
                tooltipAsString
                tooltip={translate('routeTemplateBuilder.deletePolygon')}
                tooltipPosition="right"
                tooltipColor="grayDarker"
                margin="small no"
                color={'secondary'}
                onClick={handleClearGeoFence}
              >
                <IconButtonIcon icon="clear-selection" margin="no" />
              </TooltipIconButton>
            )}
          </ComplexMapControl>
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default FacilitiesMapGL;
