import { FC, useEffect } from 'react';
import { useEsriMap } from '../../dashboadEsriMapPageSections/esri/EsriMapContext';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { formValueSelector } from 'redux-form';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { useSelector } from 'react-redux';
import Editor from '@arcgis/core/widgets/Editor'

interface EsriHostedLayerProps { }

const EsriHostedLayer: FC<EsriHostedLayerProps> = () => {
    const { map, view } = useEsriMap();
    const selector = formValueSelector(DASHBOARD_FILTER_FORM_MAPBOX);
    const hostedLayer = useSelector(state => selector(state, "hostedLayers"));
    const editHostedLayer = useSelector(state => selector(state, "editHostedLayer"));

    useEffect(() => {
        if (!hostedLayer) return;
        if (!map) return;

        // Create an array to hold the hosted feature layers
        const featureLayer : FeatureLayer = new FeatureLayer({
            url: hostedLayer.url,
        });
        map.add(featureLayer);

        let editor : Editor

        editHostedLayer && view && view.when(() => {
            editor = new Editor({
                view: view
            });

            // Add the widget to the view
            view.ui.add(editor, "top-right");
        });

        return () => {
            map.remove(featureLayer);
            view && view.ui.remove(editor)
        };
    }, [map, view, hostedLayer, editHostedLayer]);

    return null;
};

export default EsriHostedLayer;