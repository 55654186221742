import { Component } from 'react';
import { debounce, last } from 'lodash-es';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { AppState } from '../../../../store';
import { ControlBox } from 'src/dashboard/ducks/mapControls';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import {
  DashboardFleetInsightsContainer,
  DashboardFleetInsightsCount,
  DashboardFleetInsightsDetailsTable,
  DashboardFleetInsightsDetailsTableCell,
  DashboardFleetInsightsDetailsTableContainer,
  DashboardFleetInsightsHeaderTable,
  DashboardFleetInsightsHeaderTableCell,
  DashboardFleetInsightsNoResults,
  DashboardFleetInsightsRemove,
  DashboardFleetInsightsRemoveIcon,
  DashboardFleetInsightsTableBody,
  DashboardFleetInsightsTableContainer,
  DashboardFleetInsightsTableRow,
} from '../../styled';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';
import { DuckFunction, DuckAction } from '../../../../contracts/ducks';
import {
  fleetInsightCountSelector,
  fleetInsightsSelector,
  loadFleetInsights,
  removeFleetInsight,
  toggleFleetInsightsContainer,
} from '../../../ducks';
import { getPersistentFilters } from 'src/dashboard/services/persistentFilterStorage';
import { getSelectedVehicleTypes } from 'src/dashboard/services/getDashboardFilterFormData';
import {
  LOADS_DUMPED_FLEET_INSIGHT,
  STREET_SWEEPER_FLEET_INSIGHT,
  WATER_FILL_UPS_FLEET_INSIGHT,
  VIDEO_DOWNLOAD,
} from 'src/dashboard/constants/dashboardFleetInsights';
import { Link } from '../../../../core/components/styled';
import { time } from '../../../../utils/services/formatter';
import { VEHICLE_TYPES } from 'src/fleet/constants';
import FloatingCollapsible from 'src/core/components/FloatingCollapsible';
import translate from '../../../../core/services/translate';
import VideoMessageModalResolver from 'src/common/components/VideoMessageModalResolver';
import VideoMessageTriggerModal from 'src/common/components/VideoMessageTriggerModal';

interface Props {
  isLoading: boolean;
  fleetInsights: any;
  controlSpacing: number;
  messagesControl: ControlBox;
  feedbackControl: ControlBox;
  vendorId?: number;
  fleetInsightCount?: number;
  date?: string;
  loadFleetInsights: DuckFunction<typeof loadFleetInsights>;
  removeFleetInsight: DuckFunction<typeof removeFleetInsight>;
  toggleFleetInsightsContainer: DuckAction<typeof toggleFleetInsightsContainer>;
}

interface State {
  isOpen: boolean;
  isVideoFootageModalOpen: boolean;
  videoDownloadJobId?: number;
}

class DashboardFleetInsights extends Component<Props, State> {
  readonly state: State = {
    isOpen: true,
    isVideoFootageModalOpen: false,
    videoDownloadJobId: undefined,
  };

  detailsTableContainerRef: HTMLDivElement | null = null;

  onDetailsTableContainerScroll = debounce(() => {
    const { isLoading, loadFleetInsights, vendorId, date, fleetInsightCount = 0, fleetInsights } = this.props;

    if (!this.detailsTableContainerRef || !date || !vendorId) {
      return;
    }

    const canLoadMoreFleetInsights = fleetInsights && fleetInsights.length < fleetInsightCount;
    const isScolledToBottom =
      this.detailsTableContainerRef.scrollTop + this.detailsTableContainerRef.clientHeight ===
      this.detailsTableContainerRef.scrollHeight;

    if (isScolledToBottom && !isLoading && canLoadMoreFleetInsights) {
      const persistentFilters = getPersistentFilters(vendorId as any);
      const vehicleTypesIds = getSelectedVehicleTypes(persistentFilters).map(
        vehicleType => VEHICLE_TYPES[vehicleType].id,
      );
      loadFleetInsights(vendorId, date, 10, last<any>(fleetInsights).insightId, vehicleTypesIds);
    }
  }, 200);

  setDetailsTableContainerRef = (ref: HTMLDivElement | null) => {
    this.detailsTableContainerRef = ref;
  };

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
    this.props.toggleFleetInsightsContainer(this.state.isOpen);
  };

  toggleVideoFootageModal = (isOpen: boolean, videoDownloadJobId?: number) => {
    this.setState({
      isVideoFootageModalOpen: isOpen,
      videoDownloadJobId,
    });
  };

  render() {
    const {
      removeFleetInsight,
      date,
      fleetInsights,
      fleetInsightCount = 0,
      controlSpacing,
      feedbackControl,
      messagesControl,
    } = this.props;
    const { isOpen, isVideoFootageModalOpen, videoDownloadJobId } = this.state;

    let xOffset = controlSpacing;
    let yOffset = controlSpacing;

    if (feedbackControl.isVisible) {
      xOffset += controlSpacing + feedbackControl.width;
    }

    if (messagesControl.isVisible) {
      yOffset += controlSpacing + messagesControl.height;
    }

    return (
      <DashboardFleetInsightsContainer xOffset={xOffset} yOffset={yOffset}>
        <FloatingCollapsible
          isOpenByDefault={isOpen}
          title={translate('dashboard.fleetInsights')}
          minWidthWhenClosed={150}
          widthWhenOpen={545}
          renderAfterTitle={() =>
            fleetInsightCount > 0 && <DashboardFleetInsightsCount>{fleetInsightCount}</DashboardFleetInsightsCount>
          }
        >
          <DashboardFleetInsightsTableContainer>
            <DashboardFleetInsightsHeaderTable>
              <DashboardFleetInsightsTableBody>
                <DashboardFleetInsightsTableRow>
                  <DashboardFleetInsightsHeaderTableCell width="20%">{date}</DashboardFleetInsightsHeaderTableCell>
                  <DashboardFleetInsightsHeaderTableCell width="25%">
                    {translate('routes.route')}
                  </DashboardFleetInsightsHeaderTableCell>
                  <DashboardFleetInsightsHeaderTableCell width="55%">
                    {translate('dashboard.insight')}
                  </DashboardFleetInsightsHeaderTableCell>
                </DashboardFleetInsightsTableRow>
              </DashboardFleetInsightsTableBody>
            </DashboardFleetInsightsHeaderTable>

            <DashboardFleetInsightsDetailsTableContainer
              onScroll={this.onDetailsTableContainerScroll}
              ref={this.setDetailsTableContainerRef}
            >
              <DashboardFleetInsightsDetailsTable>
                <DashboardFleetInsightsTableBody>
                  {!!fleetInsights &&
                    fleetInsights.map(
                      (insight: any, insightIndex: number) =>
                        !insight.isDismissed && (
                          <DashboardFleetInsightsTableRow
                            key={insight.insightId}
                            id={`dashboard-fleet-insights-${insightIndex}`}
                          >
                            <DashboardFleetInsightsDetailsTableCell
                              width="20%"
                              id={`dashboard-fleet-insights-${insightIndex}-date`}
                            >
                              {time(insight.timestamp)}
                            </DashboardFleetInsightsDetailsTableCell>

                            <DashboardFleetInsightsDetailsTableCell
                              width="25%"
                              id={`dashboard-fleet-insights-${insightIndex}-route`}
                            >
                              {insight.vehicleType === STREET_SWEEPER_FLEET_INSIGHT &&
                              (insight.reportType === LOADS_DUMPED_FLEET_INSIGHT ||
                                insight.reportType === WATER_FILL_UPS_FLEET_INSIGHT) ? (
                                '-'
                              ) : insight.route?.id ? (
                                <Link to={`/routes/route-tracker/${insight.route.id}`}>{insight.route.name}</Link>
                              ) : (
                                insight.route?.name || '-'
                              )}
                            </DashboardFleetInsightsDetailsTableCell>

                            <DashboardFleetInsightsDetailsTableCell
                              width="55%"
                              id={`dashboard-fleet-insights-${insightIndex}-insight`}
                            >
                              {insight.reportType === VIDEO_DOWNLOAD ? (
                                <>
                                  {insight.vehicle.name}: {translate('routes.videoRequest.videoAvailable')}.{' '}
                                  <VideoMessageTriggerModal
                                    onOpenVideoFootageModal={() =>
                                      this.toggleVideoFootageModal(true, Number(insight.value1Formatted))
                                    }
                                  />
                                </>
                              ) : !insight.isVideoFootage ? (
                                translate(`dashboard.${insight.messageFormatUi}`, {
                                  message: insight.message,
                                  insightValue: insight.value1Formatted,
                                  insightSecondaryValue: insight.value2Formatted,
                                  vehicleName: insight.vehicle.name,
                                  geoFenceName: insight.geoFence?.name,
                                })
                              ) : (
                                insight.message
                              )}

                              <DashboardFleetInsightsRemove
                                onClick={() => {
                                  removeFleetInsight(insight.insightId);
                                }}
                                id={`dashboard-fleet-insights-${insightIndex}-remove-button`}
                              >
                                <DashboardFleetInsightsRemoveIcon />
                              </DashboardFleetInsightsRemove>
                            </DashboardFleetInsightsDetailsTableCell>
                          </DashboardFleetInsightsTableRow>
                        ),
                    )}
                </DashboardFleetInsightsTableBody>
              </DashboardFleetInsightsDetailsTable>
            </DashboardFleetInsightsDetailsTableContainer>

            {fleetInsightCount === 0 && (
              <DashboardFleetInsightsNoResults>
                {translate('dashboard.noFleetInsights', { date })}.
              </DashboardFleetInsightsNoResults>
            )}

            {isVideoFootageModalOpen && (
              <VideoMessageModalResolver
                videoDownloadJobId={videoDownloadJobId}
                closeModal={() => this.toggleVideoFootageModal(false)}
              />
            )}
          </DashboardFleetInsightsTableContainer>
        </FloatingCollapsible>
      </DashboardFleetInsightsContainer>
    );
  }
}

const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);

const mapStateToProps = (state: AppState) => ({
  isLoading: state.dashboard.fleetInsights.isLoading,
  fleetInsights: fleetInsightsSelector(state.dashboard.fleetInsights),
  fleetInsightCount: fleetInsightCountSelector(state.dashboard.fleetInsights),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  date: formSelector(state, 'date'),
  controlSpacing: state.dashboard.mapControls.spacing,
  messagesControl: state.dashboard.mapControls.messages,
  feedbackControl: state.dashboard.mapControls.feedback,
});

const mapDispatchToProps = {
  loadFleetInsights,
  removeFleetInsight,
  toggleFleetInsightsContainer,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFleetInsights);
