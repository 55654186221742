import humps from 'humps';

import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { LocationAndCityAlert } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { NEW_LOCATION_ALERT_ID } from 'src/fleet/constants/locationAndCityAlerts';
import accident from 'src/common/assets/img/cityAlerts/accident.png';
import IconWithTypes from '../interfaces/IconWithTypes';
import icyBridge from 'src/common/assets/img/cityAlerts/icyBridge.png';
import locationAlert from 'src/common/assets/img/cityAlerts/locationAlert.png';
import metalPlates from 'src/common/assets/img/cityAlerts/metalPlates.png';
import slickSpot from 'src/common/assets/img/cityAlerts/slickSpot.png';
import snowDrift from 'src/common/assets/img/cityAlerts/snowDrift.png';
import speedBump from 'src/common/assets/img/cityAlerts/speedBump.png';

export const CITY_ALERTS_ICON_TYPES: IconWithTypes[] = [
  new IconWithTypes('locationAlert', locationAlert, [NEW_LOCATION_ALERT_ID]),
  new IconWithTypes('accident', accident, ['accident']),
  new IconWithTypes('icyBridge', icyBridge, ['icyBridge']),
  new IconWithTypes('metalPlates', metalPlates, ['metalPlates']),
  new IconWithTypes('slickSpot', slickSpot, ['slickSpot']),
  new IconWithTypes('snowDrift', snowDrift, ['snowDrift']),
  new IconWithTypes('speedBump', speedBump, ['speedBump']),
];

export const getIconTypeForCityAlert = (alert: CityAlert | LocationAndCityAlert) => {
  const insightType = alert.cityAlertType ? humps.camelize(alert.cityAlertType.technicalName) : undefined;
  const iconType = CITY_ALERTS_ICON_TYPES.find(
    type => type.types.indexOf(insightType ? insightType : NEW_LOCATION_ALERT_ID) !== -1,
  );

  return iconType;
};
