import * as turf from '@turf/turf';
import { map } from 'lodash-es';

import {
  DEADHEAD_ID1,
  DEADHEAD_ID2,
  DEADHEAD_ID3,
  DOUBLE_SIDE_SERVICE,
  SINGLE_SIDE_SERVICE,
  TRAVEL_PATH_SERVICE_TYPES_IDS,
} from 'src/routes/constants';
import { getFeatureCollection, getLineStringFeature, getPointFeature } from 'src/common/components/map/util';
import { TravelPathEditProperties } from 'src/routes/interfaces/TravelPath';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { calculateIsDriverExperienceSimple } from 'src/customers/components/pages/streetNetwork/utils';

export const getTravelPathForEditGeoJSON = (
  travelPathData: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TravelPathEditProperties>,
  startSegment?: any,
  endSegment?: any,
) =>
  getFeatureCollection<GeoJSON.LineString, any>(
    map(travelPathData.features, feature => {
      const id = feature.properties.Path_Id;
      return getLineStringFeature(id, feature.geometry.coordinates.flat(1) as any, {
        id: id,
        color: getSegmentColor(feature, startSegment?.properties?.id, endSegment?.properties?.id),
        isDashed: [DEADHEAD_ID1, DEADHEAD_ID2, DEADHEAD_ID3].includes(feature.properties.ServCode),
        opacity: getOpacity(feature, startSegment, endSegment),
        priority: feature.properties.Priority,
        sequence: feature.properties.Sequence,
        service: feature.properties.Service,
        serviceName: TRAVEL_PATH_SERVICE_TYPES_IDS[feature.properties.ServCode]?.name,
        side: feature.properties.Side,
        streetId: feature.properties.Street_Id,
        servCode: feature.properties.ServCode,
        clickable:
          startSegment && endSegment
            ? false
            : !startSegment || feature.properties.Sequence > startSegment.properties.sequence, // not allow next segment to be clickable
      });
    }),
  );

export const getArrowsForEditGeoJSON = (
  travelPathData: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TravelPathEditProperties>,
  mapBearing: number,
  startSegment?: any,
  endSegment?: any,
) =>
  getFeatureCollection<GeoJSON.Point, any>(
    map(travelPathData.features, feature => {
      const coordinates = turf.getCoords(feature).flat(1);
      const lastCoordinate = coordinates[coordinates.length - 1] as any;
      const id = feature.properties.Path_Id;
      return getPointFeature(id, lastCoordinate, {
        id,
        color: getSegmentColor(feature, startSegment?.properties.id, endSegment?.properties.id),
        isDashed: [DEADHEAD_ID1, DEADHEAD_ID2, DEADHEAD_ID3].includes(feature.properties.ServCode),
        opacity: getOpacity(feature, startSegment, endSegment),
        priority: feature.properties.Priority,
        sequence: feature.properties.Sequence,
        service: feature.properties.Service,
        serviceName: TRAVEL_PATH_SERVICE_TYPES_IDS[feature.properties.ServCode]?.name,
        side: feature.properties.Side,
        streetId: feature.properties.Street_Id,
        rotate: getRotate(coordinates, mapBearing),
        clickable:
          startSegment && endSegment
            ? false
            : !startSegment || feature.properties.Sequence > startSegment.properties.sequence, // not allow next segment to be clickable
      });
    }),
  );

export const getArrowsForStreetSegmentsDirection = (features: any, mapBearing: number) => {
  if (!features?.length) return;

  const points: any[] = [];

  for (let i = 0; i < features.length; i++) {
    const feature = features[i];
    const flatCoordinates = turf.getCoords(feature).flat(1);

    const lastCoordinate = flatCoordinates[flatCoordinates.length - 1];

    const id = feature.properties.streetId;
    points.push(
      getPointFeature(id, lastCoordinate, {
        id,
        opacity: 1,
        rotate: getRotate(flatCoordinates, mapBearing),
        isInterconnectingStreets: feature.properties.isInterconnectingStreets,
        isStartingSegment: feature.properties.isStartingSegment,
        isEndingSegment: feature.properties.isEndingSegment,
        clickable: true,
      }),
    );
  }

  return getFeatureCollection<GeoJSON.Point, any>(points);
};

const getSegmentColor = (segment: any, start?: number, end?: number) => {
  if (start === segment.properties.Path_Id) return 'green';
  if (end === segment.properties.Path_Id) return 'red';
  return TRAVEL_PATH_SERVICE_TYPES_IDS[segment.properties.ServCode]?.color;
};

const getOpacity = (segment: any, start?: any, end?: any) => {
  if (!start) return 1;

  const isAfterStart = start && segment?.properties?.Sequence > start?.properties?.sequence;

  if (start && !end && isAfterStart) return 1;

  const isStartSegment = start?.properties.id === segment.properties.Path_Id;
  const isEndSegment = end?.properties.id === segment.properties.Path_Id;

  const isBetweenStartAndEnd =
    start &&
    end &&
    segment?.properties?.Sequence > start?.properties?.sequence &&
    segment?.properties?.Sequence < end?.properties?.sequence;

  if (isStartSegment || isEndSegment) return 1;

  if (isBetweenStartAndEnd) {
    if ([SINGLE_SIDE_SERVICE, DOUBLE_SIDE_SERVICE].includes(segment?.properties?.ServCode)) {
      return 0.7;
    } else {
      return 0.5;
    }
  }

  return 0.1;
};

const getRotate = (coordinates: any, mapBearing: number) => {
  const coordinatesFlatten = turf.getCoords(coordinates);
  const startPoint = turf.point(coordinatesFlatten[coordinatesFlatten.length - 2]);
  const endPoint = turf.point(coordinatesFlatten[coordinatesFlatten.length - 1]);
  const bearing = turf.bearing(startPoint, endPoint);

  return bearing - mapBearing - 90;
};

export const bboxIsInsideBbox = (bbox1?: turf.BBox, bbox2?: turf.BBox) => {
  if (!bbox1 || !bbox2) return false;

  const polygon1 = turf.bboxPolygon(bbox1);
  const polygon2 = turf.bboxPolygon(bbox2);
  const inside = turf.booleanContains(polygon1, polygon2);
  return inside;
};

export const getBboxBasedOnFeatures = (features: any) => {
  // get the bbox of the features collection and extend it by 10%
  const bbox = turf.bbox(features);
  const bboxPolygon = turf.bboxPolygon(bbox);
  const bboxPolygonExtended = turf.buffer(bboxPolygon, 0.5, { units: 'kilometers' });
  const bboxExtended = turf.bbox(bboxPolygonExtended);
  return bboxExtended;
};

export const getTravelPathRouteSegmentsGeoJSON = (routeSegments: StreetNetwork[], isSnowPlow: boolean) => {
  return getFeatureCollection<GeoJSON.LineString, any>(
    routeSegments.map((segment: StreetNetwork) => {
      const id = segment.id;
      const coords = JSON.parse(segment.lineSegment);

      const hasReversePass = !segment.isOneWay && !calculateIsDriverExperienceSimple(isSnowPlow);

      return getLineStringFeature(id, coords, {
        id: id,
        clickable: true,
        hasForwardPass: true,
        hasReversePass,
        lineOffset: hasReversePass ? 5 : 0,
        lineOffsetNegative: hasReversePass ? -5 : 0,
      });
    }),
  );
};
