import { dateAndTime } from '../../../utils/services/formatter';
import { Modal, Table } from '../../../core/components';
import { ModalSection, ModalTitle, TableCell, TableRow, Text } from '../../../core/components/styled';
import translate from 'src/core/services/translate';

interface tableRowProps {
  lastUpdatedDate: string;
  text: string;
}

const rubiconServicesTicketNotesTableCells = [
  { name: 'note', label: translate('common.note'), width: '70%' },
  { name: 'lastUpdatedDate', label: translate('customers.rubiconService.submittedDate'), width: '30%' },
];

const rubiconServicesTicketNotesTableRow: React.SFC<tableRowProps> = ({ lastUpdatedDate, text }: tableRowProps) => (
  <>
    <TableRow>
      <TableCell width="70%">
        <Text block>{text}</Text>
      </TableCell>
      <TableCell width="30%">
        <Text block>{dateAndTime(lastUpdatedDate)}</Text>
      </TableCell>
    </TableRow>
  </>
);

interface Props {
  closeModal: (pristine?: boolean) => void;
  notes: string[];
}
export default function RubiconServicesTicketNotesModal({
  closeModal,

  notes,
}: Props) {
  return (
    <Modal size="medium" onClose={closeModal}>
      <ModalSection margin="medium no no large" align="left">
        <ModalTitle align="center" size="large" margin="no no large">
          {translate('customers.serviceNotes')}
        </ModalTitle>
      </ModalSection>
      <Table
        cells={rubiconServicesTicketNotesTableCells}
        rows={notes}
        rowComponent={rubiconServicesTicketNotesTableRow}
      />
    </Modal>
  );
}
