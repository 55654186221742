import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { createUrl } from '../../../utils/services/queryParams';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch } from '../../../core/components';
import { ROUTE } from '../../../fleet/constants';
import { RouteTemplateStatusDropdown, DayOfServiceMultiSelect, GroupsMultiSelect } from '..';
import { VehicleTypeForVendorMultiSelect } from '../../../fleet/components';
import routeTemplatesFormInitialValuesSelector from '../../services/routeTemplatesFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { getExcludeVehicleFilters, getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { FilterSetting } from 'src/vendors/interfaces/Filters';

interface OwnProps extends RouteComponentProps {
  filtersPreferences?: FilterSetting[];
}

interface PropsWithoutReduxForm extends OwnProps {
  push: (url: string) => any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class RouteTemplatesForm extends PureComponent<Props> {
  onRouteTemplateNameChange = debounce((event, routeTemplateName) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { routeTemplateName, page: undefined }));
  }, 800);

  onDaysOfServiceChanged = (event: any, daysOfServiceIds: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, {
        daysOfServiceIds: daysOfServiceIds && daysOfServiceIds.toString(),
        page: undefined,
      }),
    );
  };

  onGroupsChanged = (event: any, groupIds: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { groupIds: groupIds && groupIds.toString(), page: undefined }));
  };

  onVehicleTypeChange = (event: any, vehicleTypeIds: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { vehicleTypeIds: vehicleTypeIds && vehicleTypeIds.toString(), page: undefined }));
  };

  onRoutesStatusChange = (event: any, routeStatus: string) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { routeStatus, page: undefined }));
  };

  render() {
    const { filtersPreferences } = this.props;
    const excludeVehicleFilters = getExcludeVehicleFilters(filtersPreferences);

    return (
      <form>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="4/12">
              <Field name="routeTemplateName" component={PanelSearch} onChange={this.onRouteTemplateNameChange} />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="daysOfServiceIds"
                component={DayOfServiceMultiSelect as any}
                multiSelectProps={{ margin: 'no' }}
                placeholder={translate('common.allDaysOfService')}
                onChange={this.onDaysOfServiceChanged}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <Field
                name="groupIds"
                component={GroupsMultiSelect}
                multiSelectProps={{ margin: 'no', canCheckAll: true, fitContentWidth: true }}
                includeNoneOption
                onChange={this.onGroupsChanged}
                withPlaceholder
              />
            </GridColumn>
            <GridColumn size="2/12">
              <Field
                name="vehicleTypeIds"
                component={VehicleTypeForVendorMultiSelect as any}
                multiSelectProps={{ margin: 'no' }}
                placeholder={translate('vehicles.allVehicleTypes')}
                vehicleRoleTypeId={ROUTE}
                onChange={this.onVehicleTypeChange}
                excludeVehicleTypes={excludeVehicleFilters}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <Field
                name="routeStatus"
                component={RouteTemplateStatusDropdown as any}
                dropdownProps={{ margin: 'no', isClearable: true }}
                withPlaceholder
                onChange={this.onRoutesStatusChange}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { filters } = state.common.filters;
  const vehicleFiltersPreferencesIds = getVehicleFiltersPreferencesIds(filters);
  const initialValues = routeTemplatesFormInitialValuesSelector(ownProps.location.search, vehicleFiltersPreferencesIds);
  return {
    initialValues,
    filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
  };
};

const mapDispatchToProps = { push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'routeTemplates',
      enableReinitialize: true,
    })(RouteTemplatesForm),
  ),
);
