import * as turf from '@turf/turf';
import { debounce, size } from 'lodash-es';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MapGL } from 'src/common/components/map/MapGL';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon, Text } from 'src/core/components/styled';
import { MAP_CITY_ZOOM_IN_BIGGER, MAP_CITY_ZOOM_SMALL } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { MapGLWrapper } from 'src/customers/components/styled';
import TravelPathEditorMapForm, {
  MapLayersFormValues,
} from 'src/routes/components/forms/travelPath/TravelPathEditorMapForm';
import { TravelPathWarningMapWrapper } from 'src/routes/components/styled';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import {
  applyEditsToTravelPath,
  finishTravelPathBuildOrEdit,
  loadGeoFencesForTravelPathModal,
  loadHaulerLocationsForTravelPathModal,
  loadRouteSegmentsForTravelPathModal,
  loadRouteStopsForTravelPathModal,
  loadStreetNetworkForTravelPathModal,
  loadTravelPathForBuildOrEdit,
  publishEditsToTravelPath,
  resetMapFeatures,
  resetStartEndTravelPathEdit,
} from 'src/routes/ducks';
import { loadRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { useTravelPathEditorMapBounds } from 'src/routes/hooks/useTravelPathEditorMapBounds';
import useTravelPathEditorService from 'src/routes/hooks/useTravelPathEditorService';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { bboxIsInsideBbox } from '../utils';
import TravelPathGeoFencesGL from './geoFences/TravelPathGeoFencesGL';
import TravelPathHaulerLocationsGL from './haulerLocations/TravelPathHaulerLocationsGL';
import { TRAVEL_PATH_HAULER_LOCATIONS_SOURCE } from './haulerLocations/TravelPathHaulerLocationsGLSource';
import TravelPathEditInstructions from './legends/TravelPathEditInstructions';
import TravelPathEditLegend from './legends/TravelPathEditLegend';
import TravelPathRouteSegmentsGL from './routeSegments/TravelPathRouteSegmentsGL';
import { TRAVEL_PATH_ROUTE_SEGMENTS_GL_LAYER } from './routeSegments/TravelPathRouteSegmentsGLSource';
import TravelPathRouteStopsGL from './routeStops/TravelPathRouteStopsGL';
import { TRAVEL_PATH_ROUTE_STOPS_LAYER } from './routeStops/TravelPathRouteStopsGLSource';
import StreetNetworkGL from './streetNetwork/StreetNetworkGL';
import StreetNetworkOptionsToChooseGL from './streetNetworkOptionToChoose/StreetNetworkOptionsToChooseGL';
import { STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER } from './streetNetworkOptionToChoose/StreetNetworkOptionsToChooseGLSource';
import StreetNetworkOptionsSelectedGL from './streetNetworkOptionsSelected/StreetNetworkOptionsSelectedGL';
import TravelPathGL from './travelPath/TravelPathGL';
import { TRAVEL_PATH_DASHED_LINES_LAYER, TRAVEL_PATH_LINES_LAYER } from './travelPath/TravelPathGLSource';

const normalizeFilterObjectAsNumbersAndRemovePrefix = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: number[], cur) => (cur[1] ? [...acc, Number(cur[0].replace('_', ''))] : acc), []);

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

interface Props {
  isAlternativeFleet?: boolean;
  isSnowPlow?: boolean;
  routeId?: number;
  routeTemplateId?: number;
  closeModal: (shouldRefreshDisplayedTP: boolean) => void;
}

const TravelPathEditorMapGL: FC<Props> = ({ isAlternativeFleet, isSnowPlow, routeId, routeTemplateId, closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  // map state
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();
  const [mapZoomDebounced, setMapZoomDebounced] = useState<number>(0);
  const [currentbbox, setCurrentbbox] = useState<turf.BBox>([0, 0, 0, 0]);
  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);
  const [mapBearing, setMapBearing] = useState<number>(0);

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [showOrderNumbers, setShowOrderNumbers] = useState(false);

  const { geoFence } = useSelector(state => state.routes.geoFence);
  const [showRouteGeoFence, setShowRouteGeoFence] = useState(false);

  /**
   * Loading map features for display
   */
  const handleSubmitLayersForm = async (formData: MapLayersFormValues) => {
    // geoFences
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);
    const normalizedGeoFencesIds = normalizeFilterObjectAsNumbersAndRemovePrefix(formData.geoFenceSubFilters);
    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      loadGeoFencesForTravelPathModal({
        vendorId,
        geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
        limit: 300,
        geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
      })(dispatch);
    } else {
      dispatch(resetMapFeatures('geoFences'));
    }

    if (geoFence && formData.geoFencesTypesFilters[geoFence.id] && (routeId || routeTemplateId)) {
      setShowRouteGeoFence(true);
      const isTemplate = !!routeTemplateId;
      await loadRouteGeoFence((routeId || routeTemplateId) as number, isTemplate)(dispatch);
    } else if (!normalizedGeoFencesIds.length) {
      setShowRouteGeoFence(false);
    }

    // routeSegments
    if (formData.showRouteSegments) {
      loadRouteSegmentsForTravelPathModal(vendorId, routeId, routeTemplateId)(dispatch);
    } else dispatch(resetMapFeatures('routeSegments'));
    // routeStops
    if (formData.showRouteStops) loadRouteStopsForTravelPathModal(vendorId, routeId, routeTemplateId)(dispatch);
    else dispatch(resetMapFeatures('routeStops'));
    // haulerLocations
    const filterHaulerLocations = normalizeFilterObjectAsNumbersAndRemovePrefix(formData.haulerLocations);
    if (size(filterHaulerLocations)) {
      loadHaulerLocationsForTravelPathModal(filterHaulerLocations.toString())(dispatch);
    } else dispatch(resetMapFeatures('haulerLocations'));

    setShowOrderNumbers(formData.showOrderNumbers);
    setIsFormOpen(false);
  };

  //setCurrentbbox debounced
  const debounceSetCurrentbbox = useMemo(
    () =>
      debounce((bbox: turf.BBox) => {
        setCurrentbbox(bbox);
      }, 500),
    [],
  );

  // setMapZoom debounced
  const debounceSetMapZoom = useMemo(
    () =>
      debounce((zoom: number) => {
        setMapZoomDebounced(zoom);
      }, 500),
    [],
  );

  /**
   * Map events
   */
  useEffect(() => {
    mapInstance?.once('load', () => {
      mapInstance.on('mousemove', event => {
        const features = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              [
                STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER,
                TRAVEL_PATH_DASHED_LINES_LAYER,
                TRAVEL_PATH_LINES_LAYER,
                TRAVEL_PATH_ROUTE_STOPS_LAYER,
                TRAVEL_PATH_HAULER_LOCATIONS_SOURCE,
                TRAVEL_PATH_ROUTE_SEGMENTS_GL_LAYER,
              ].includes(feature.layer.id) && feature?.properties?.clickable,
          );

        mapInstance.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      mapInstance.on('mouseleave', () => {
        mapInstance.getCanvas().style.cursor = '';
      });
      mapInstance.on('zoomend', () => {
        debounceSetMapZoom(mapInstance.getZoom());
      });
      mapInstance.on('moveend', () => {
        const bbox = mapInstance.getBounds().toArray().flat() as turf.BBox;
        debounceSetCurrentbbox(bbox);
      });
      mapInstance.on('rotate', () => {
        setMapBearing(mapInstance.getBearing());
      });
    });
  }, [debounceSetCurrentbbox, debounceSetMapZoom, mapInstance]);

  /* ======================== EDITOR ======================== */
  const {
    addFeatureToSelection,
    undoLastSelection,
    getApplyEditsTransaction,
    getHasSelectedAllServiceSegments,
    selectedSegmentsForDisplay: selectedSegments,
    optionsToChooseForPath,
    isUndoDisabled,
    lastSelectedSegment,
    isApplyEditsButtonDisabled,
    isSaveButtonDisabled,
    hasReachedEndSegment,
  } = useTravelPathEditorService();

  const { viewport } = useTravelPathEditorMapBounds(optionsToChooseForPath, lastSelectedSegment, selectedSegments);

  const {
    startSegment,
    endSegment,
    initEditConfigs,
    isLoadingMapFeatures,
    isLoadingTravelPathGeoStreets,
    streetNetworkBBOX,
    streetNetwork,
    isApplyingEditsToTravelPath,
    isLoadingTravelPathEdited,
    isSavingEditsToTravelPath,
  } = useSelector(state => state.routes.travelPathBuildAndEdit);

  /**
   * Loading street network for travel path editing
   * based on the current bbox of the map
   */
  const handleLoadStreetNetworkForTravelPathModal = useCallback(
    (bbox: turf.BBox) => {
      if (!startSegment || !endSegment || !initEditConfigs) return;

      //  check if the new bbox is different from the previous one and its not zoomed in from the previous one
      if (
        bboxIsInsideBbox(streetNetworkBBOX, bbox) ||
        JSON.stringify(bbox) === JSON.stringify(streetNetworkBBOX) ||
        hasReachedEndSegment ||
        optionsToChooseForPath.features.length > 1
      ) {
        return;
      } else if (mapZoomDebounced >= MAP_CITY_ZOOM_SMALL) {
        let bboxProcessed = bbox;
        if (mapZoomDebounced > MAP_CITY_ZOOM_IN_BIGGER) {
          // if zoomed in too much, we need to expand the bbox to get more segments
          const bboxPolygon = turf.bboxPolygon(bbox);
          const bboxPolygonBuffered = turf.buffer(bboxPolygon, 1, { units: 'miles' });
          bboxProcessed = turf.bbox(bboxPolygonBuffered);
        }
        loadStreetNetworkForTravelPathModal(vendorId, bboxProcessed, initEditConfigs)(dispatch);
      }
    },
    [
      dispatch,
      endSegment,
      hasReachedEndSegment,
      initEditConfigs,
      mapZoomDebounced,
      optionsToChooseForPath.features.length,
      startSegment,
      streetNetworkBBOX,
      vendorId,
    ],
  );

  /**
   * Loading street network for travel path editing
   */
  useEffect(() => {
    handleLoadStreetNetworkForTravelPathModal(currentbbox);
  }, [currentbbox, handleLoadStreetNetworkForTravelPathModal]);

  /**
   * Apply edits to travel path (intermediary step)
   */
  const handleApplyEditsToTravelPath = useCallback(async () => {
    const transaction = getApplyEditsTransaction();
    const hasSelectedAllServiceSegments = getHasSelectedAllServiceSegments();

    let hasConfirmed = true;

    if (!hasSelectedAllServiceSegments)
      hasConfirmed = await confirm(translate('routes.travelPath.alertMessages.notAllServiceSegmentsSelected'));

    if (hasConfirmed)
      applyEditsToTravelPath({
        travelPathEdits: [transaction],
        routeId,
        routeTemplateId,
        vendorId,
      })(dispatch).then(res => {
        if (initEditConfigs && !res.error?.code) {
          //reset start & end segments and load generated travel path
          dispatch(resetStartEndTravelPathEdit());
          loadTravelPathForBuildOrEdit(vendorId, initEditConfigs)(dispatch);
        }
      });
  }, [
    dispatch,
    getApplyEditsTransaction,
    getHasSelectedAllServiceSegments,
    initEditConfigs,
    routeId,
    routeTemplateId,
    vendorId,
  ]);

  const handleSaveTravelPath = useCallback(async () => {
    if (await confirm(translate('routes.travelPath.alertMessages.saveTravelPath'))) {
      publishEditsToTravelPath(
        vendorId,
        false,
        routeId,
        routeTemplateId,
      )(dispatch).then(res => {
        if (!res.error?.code) {
          finishTravelPathBuildOrEdit(
            vendorId,
            false,
            routeId,
            routeTemplateId,
          )(dispatch).then(res => {
            if (!res?.error?.code) {
              createSuccessNotification(translate('routes.travelPath.alertMessages.successSaveTravelPath'));
              closeModal(true);
            }
          });
        }
      });
    }
  }, [closeModal, dispatch, routeId, routeTemplateId, vendorId]);

  return (
    <MapGLWrapper
      isLoading={
        isLoadingMapFeatures ||
        isLoadingTravelPathGeoStreets ||
        isApplyingEditsToTravelPath ||
        isLoadingTravelPathEdited ||
        isSavingEditsToTravelPath
      }
      height="80vh"
    >
      <MapGL
        key="editorMap"
        dragPan
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        onMapRefLoaded={setMapInstance}
        viewport={viewport}
        setIsSatelliteViewEnabled={setIsSatelliteView}
      >
        <ComplexMapControl position="top-left" vertical>
          <TooltipIconButton
            tooltipAsString
            tooltip={translate('dashboard.filterKeys.mapLayers')}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color="secondary"
            margin="no"
            onClick={() => setIsFormOpen(true)}
          >
            <IconButtonIcon icon="layers" color="primary" />
          </TooltipIconButton>
        </ComplexMapControl>

        <ComplexMapControl position="top-right" vertical>
          <TooltipIconButton
            tooltipAsString
            tooltip={translate('routes.travelPath.startOver')}
            tooltipPosition="left"
            tooltipColor="grayDarker"
            color="secondary"
            margin="no"
            disabled={!startSegment && !endSegment}
            onClick={() => dispatch(resetStartEndTravelPathEdit())}
          >
            <IconButtonIcon icon="close" color="primary" />
          </TooltipIconButton>
          <TooltipIconButton
            tooltipAsString
            tooltip={translate('routes.travelPath.undoLastSelection')}
            tooltipPosition="left"
            tooltipColor="grayDarker"
            color="secondary"
            margin="small no no"
            disabled={isUndoDisabled}
            onClick={undoLastSelection}
          >
            <IconButtonIcon icon="betterUndo" color="primary" />
          </TooltipIconButton>
          <TooltipIconButton
            tooltipAsString
            tooltip={translate('routes.travelPath.applyChanges')}
            tooltipPosition="left"
            tooltipColor="grayDarker"
            color="secondary"
            margin="small no no"
            disabled={isApplyEditsButtonDisabled}
            onClick={handleApplyEditsToTravelPath}
          >
            <IconButtonIcon icon="check" color="primary" />
          </TooltipIconButton>
          <TooltipIconButton
            tooltipAsString
            tooltip={translate('routes.travelPath.saveTravelPath')}
            tooltipPosition="left"
            tooltipColor="grayDarker"
            color="secondary"
            disabled={isSaveButtonDisabled}
            margin="small no no"
            onClick={handleSaveTravelPath}
          >
            <IconButtonIcon icon="save" color="primary" size="large" />
          </TooltipIconButton>
        </ComplexMapControl>

        {mapInstance && (
          <>
            {/* the underlying street network */}
            <StreetNetworkGL />

            {/* the original TravelPath from where choose start & end */}
            <TravelPathGL map={mapInstance} mapBearing={mapBearing} />

            {/* the selected segments */}
            <StreetNetworkOptionsSelectedGL streetNetworkOptionsSelected={selectedSegments} mapBearing={mapBearing} />
            {/* the options to choose for the new path */}
            <StreetNetworkOptionsToChooseGL
              onSelect={addFeatureToSelection}
              map={mapInstance}
              mapBearing={mapBearing}
              streetNetworkOptions={optionsToChooseForPath}
            />

            {/* other features that can be displayed on map */}
            <TravelPathGeoFencesGL
              map={mapInstance}
              isSatellite={isSatelliteView}
              showRouteGeoFence={showRouteGeoFence}
            />
            <TravelPathRouteStopsGL
              map={mapInstance}
              routeId={routeId}
              routeTemplateId={routeTemplateId}
              showOrderNumbers={showOrderNumbers}
            />
            <TravelPathRouteSegmentsGL map={mapInstance} isSnowPlow={isSnowPlow || false} />
            <TravelPathHaulerLocationsGL map={mapInstance} />
          </>
        )}

        <TravelPathEditInstructions />
        <TravelPathEditLegend />

        {!isLoadingTravelPathGeoStreets && !size(streetNetwork?.features) && (
          <TravelPathWarningMapWrapper>
            <Text block size="xLarge" weight="medium" color="info">
              <em> {translate('routes.travelPath.alertMessages.streetNetworkNotLoaded')}</em>
            </Text>
          </TravelPathWarningMapWrapper>
        )}
      </MapGL>

      <TravelPathEditorMapForm
        isAlternativeFleet={isAlternativeFleet}
        isPanelOpen={isFormOpen}
        closePanel={() => setIsFormOpen(false)}
        onSubmit={handleSubmitLayersForm}
        hideDailyRouteGeoFence={!!routeTemplateId}
      />
    </MapGLWrapper>
  );
};

export default memo(TravelPathEditorMapGL);
