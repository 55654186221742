import * as turf from '@turf/turf';
import { forEach, size } from 'lodash-es';
import { useEffect, useState } from 'react';

import { getMapBounds } from 'src/common/components/map/util';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { useSelector } from 'src/core/hooks/useSelector';
import { DOUBLE_SIDE_SERVICE, SINGLE_SIDE_SERVICE } from '../constants';
import { StreetNetworkPropertiesTravelPath } from '../interfaces/TravelPath';

export const useTravelPathEditorMapBounds = (
  optionToChoose: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>,
  lastSelectedSegment: GeoJSON.Feature<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath> | null,
  selectedFeatureBeforeApply: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>,
) => {
  const [viewport, setViewport] = useState<MapGLViewport>();
  const [isInitialViewport, setIsInitialViewport] = useState<boolean>(true);
  const [previousSelectedFeatureBeforeApply, setPreviousSelectedFeatureBeforeApply] =
    useState<GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>>();
  const { routeStops, haulerLocations, geoFences, startSegment, endSegment, travelPathEditData } = useSelector(
    state => state.routes.travelPathBuildAndEdit,
  );

  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    forEach(routeStops, ({ displayLatitude, displayLongitude }) => {
      points.push({ latitude: displayLatitude, longitude: displayLongitude });
    });

    forEach(haulerLocations, ({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    forEach(geoFences, ({ geoFenceJson }) => {
      const geoFence = JSON.parse(geoFenceJson);
      const feature = turf.feature(geoFence);
      const coordinates = turf.getCoords(feature);
      forEach(coordinates, coordinate => {
        forEach(coordinate, c => {
          points.push({ latitude: c[1], longitude: c[0] });
        });
      });
    });

    if (isInitialViewport && travelPathEditData?.features?.length) {
      forEach(travelPathEditData.features, ({ geometry, properties }) => {
        // get the center of each feature for double side and single side services
        if ([DOUBLE_SIDE_SERVICE, SINGLE_SIDE_SERVICE].includes(properties.ServCode)) {
          const feature = turf.feature(geometry);
          const center = turf.center(feature);
          const coordinates = turf.getCoords(center);
          const [longitude, latitude] = coordinates;
          points.push({ latitude, longitude });
        }
      });
      setIsInitialViewport(false);
    }

    if (!!points.length) {
      const bounds = getMapBounds(points, {
        padding: 10,
        capZoom: 15,
        width: 1160,
        height: 1035,
      });

      setViewport(bounds);
    }
  }, [routeStops, haulerLocations, geoFences, isInitialViewport, travelPathEditData?.features]);

  // when the options change, reset the map bounds
  useEffect(() => {
    if (optionToChoose?.features?.length) {
      const points: { latitude: number; longitude: number }[] = [];

      forEach(optionToChoose.features, ({ geometry, properties }) => {
        // get the center of each feature
        if (properties.isSameStreet) return;

        const feature = turf.feature(geometry);

        const coordinates = turf.getCoords(feature).flat(1);

        const endPoint = coordinates[1];
        const startPoint = coordinates[0];
        points.push({ latitude: startPoint[1], longitude: startPoint[0] });

        points.push({ latitude: endPoint[1], longitude: endPoint[0] });
      });

      if (!!points.length) {
        let headingToSet = 0;
        // calculate the map heading based on the last selected segment
        if (lastSelectedSegment) {
          const coordinates = turf.getCoords(lastSelectedSegment).flat(1);
          // if is reversed, the last coordinate is the start point

          const startPoint = coordinates[coordinates.length - 2];

          const endPoint = coordinates[coordinates.length - 1];
          points.push({ latitude: startPoint[1], longitude: startPoint[0] });
          points.push({ latitude: endPoint[1], longitude: endPoint[0] });

          const heading = turf.bearing(startPoint, endPoint);
          headingToSet = heading;
        }

        const bounds = getMapBounds(points, {
          padding: 50,
          capZoom: 18,
          width: 1200,
          height: 1035,
        });

        setViewport({ ...bounds, transitionDuration: 1000, bearing: headingToSet, pitch: 35 });
      }
    }
  }, [optionToChoose, lastSelectedSegment]);

  // when the start segment changes, reset the map bounds
  useEffect(() => {
    if (size(selectedFeatureBeforeApply?.features)) {
      setPreviousSelectedFeatureBeforeApply(selectedFeatureBeforeApply);
    }
  }, [selectedFeatureBeforeApply]);

  useEffect(() => {
    // if there is no start segment, set the map bounds to the whole travel path
    if ((!startSegment || !endSegment) && viewport && viewport.pitch !== 0) {
      const points: { latitude: any; longitude: any }[] = [];
      forEach(previousSelectedFeatureBeforeApply?.features, ({ geometry }) => {
        const feature = turf.feature(geometry);
        const coordinates = turf.getCoords(feature).flat(1);
        const startPoint = coordinates[0];
        const endPoint = coordinates[coordinates.length - 1];
        points.push({ latitude: startPoint[1], longitude: startPoint[0] });
        points.push({ latitude: endPoint[1], longitude: endPoint[0] });
      });

      if (!!points.length) {
        const bounds = getMapBounds(points, {
          padding: 50,
          capZoom: 18,
          width: 1200,
          height: 1035,
        });

        setViewport({ ...bounds, transitionDuration: 1000, bearing: 0, pitch: 0 });
      } else setViewport({ ...viewport, transitionDuration: 1000, pitch: 0, bearing: 0 });
      setPreviousSelectedFeatureBeforeApply(undefined);
    }
  }, [startSegment, endSegment, viewport, previousSelectedFeatureBeforeApply?.features]);

  return { viewport };
};
