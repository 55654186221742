import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const HARD_ACCELERATION_ID = 1;
export const HARD_BRAKING_ID = 2;
export const HARD_TURNING_ID = 3;
export const OFF_ROUTE_ID = 4;
export const STATIONARY_ID = 5;
export const OFF_ROUTE_STATIONARY = 6;
export const INSIDE_GEO_FENCE = 7;
export const OUTSIDE_GEO_FENCE = 8;
export const SPEEDING = 9;

export const DRIVING_INCIDENT_TYPES_LIST = [
  { id: HARD_ACCELERATION_ID, name: translate('dashboard.hardAcceleration') },
  { id: HARD_BRAKING_ID, name: translate('dashboard.hardBraking') },
  { id: HARD_TURNING_ID, name: translate('dashboard.hardTurning') },
  { id: OFF_ROUTE_ID, name: translate('dashboard.offRoute') },
  { id: STATIONARY_ID, name: translate('dashboard.stationary') },
  { id: OFF_ROUTE_STATIONARY, name: translate('dashboard.offRouteStationary') },
  { id: INSIDE_GEO_FENCE, name: translate('dashboard.insideGeoFence') },
  { id: OUTSIDE_GEO_FENCE, name: translate('dashboard.outsideGeoFence') },
  { id: SPEEDING, name: translate('dashboard.speeding') },
];

export const DRIVING_INCIDENT_TYPES = mapKeys(DRIVING_INCIDENT_TYPES_LIST, 'id');
