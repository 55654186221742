import React, { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { createRouteImport, resetRouteImportCreation } from '../../ducks';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
  PageBackButton,
  PageBackButtonIcon,
} from '../../../common/components/styled';
import { Panel, PanelSection } from '../../../core/components/styled';
import { CreateRouteImportForm } from '../forms';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';

interface Props {
  isCreating: boolean;
  createRouteImport: DuckFunction<typeof createRouteImport>;
  resetRouteImportCreation: DuckAction<typeof resetRouteImportCreation>;
  push: any;
}

interface FormValues {
  vendorId: number;
  description: string;
  file: File[];
}

class CreateRouteImportPage extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetRouteImportCreation();
  }

  onSubmit = ({ vendorId, description, file }: FormValues) => {
    const { createRouteImport, push } = this.props;

    createRouteImport(vendorId, description, file[0])
      .then(() => {
        createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
        push('/route-import');
      })
      .catch(() => createErrorNotification(translate('common.alertMessages.uploadFailed')));
  };

  render() {
    const { isCreating } = this.props;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to={'/route-import'}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle>{translate('routeImport.createRouteImport')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>

        <Panel>
          <PanelSection padding="large" isLoading={isCreating}>
            <CreateRouteImportForm onSubmit={this.onSubmit} />
          </PanelSection>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isCreating: state.routeImport.routeImportCreation.isCreating,
});

const mapDispatchToProps = {
  createRouteImport,
  resetRouteImportCreation,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRouteImportPage);
