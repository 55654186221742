import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { ServiceNotOnScheduleDeliveryForm } from '../forms';
import { AppState } from '../../../store';
import { DuckFunction } from '../../../contracts/ducks';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { updateHaulerOpportunity } from '../../ducks';

interface RouteParams {
  token: string;
}

interface ComponentProps {
  caseId: number;
  itemId: number;
  vendorId: number;
  userId: string;
  onCancel(pristine: boolean): void;
}

interface ReduxProps {
  response: any;
  requestedChanges: any;
  WasDelivered: any;
  CanNotDeliver: any;
  isSaving: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const ServiceNotOnScheduleDeliveryModal: React.FC<Props> = props => {
  const { isSaving, onCancel } = props;

  const onSubmit = React.useCallback(async () => {
    const {
      itemId,
      match: {
        params: { token },
      },
      requestedChanges,
      userId,
      vendorId,
      onCancel,
      loadDispatchOpportunitiesCount,
      updateHaulerOpportunity,
    } = props;

    const requestDeliveryObj: any = {};

    requestDeliveryObj.ItemId = itemId;
    requestDeliveryObj.Response = 'WillDeliverOnNewDate';
    requestDeliveryObj.RequestedChanges = requestedChanges;

    if (requestDeliveryObj.RequestedChanges && requestDeliveryObj.RequestedChanges.deliveryDate) {
      requestDeliveryObj.RequestedChanges.deliveryDate = moment(
        requestDeliveryObj.RequestedChanges.deliveryDate,
      ).format('YYYY-MM-DD');
    }

    const promise = updateHaulerOpportunity(requestDeliveryObj, userId, token, vendorId);

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);

  return (
    <Modal size="small" isLoading={isSaving}>
      <ModalTitle>{translate('opportunity.deliveries.serviceNotOnSchedule')}</ModalTitle>
      <ModalSubtitle />
      <Grid centered>
        <GridColumn size="8/12">
          <ServiceNotOnScheduleDeliveryForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('serviceNotOnScheduleDelivery');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `deliveries.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `deliveries.settings${ownProps.caseId}.requestedChanges`),
  WasDelivered: formSelector(state, `deliveries.settings${ownProps.caseId}.WasDelivered`),
  CanNotDeliver: formSelector(state, `deliveries.settings${ownProps.caseId}.CanNotDeliver`),
  isSaving: state.opportunity.opportunity.isSaving,
});

export default connect(mapStateToProps, undefined)(ServiceNotOnScheduleDeliveryModal);
