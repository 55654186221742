import { useEffect } from 'react';

import { change, Field, formValueSelector, InjectedFormProps, reduxForm, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { filter, get, isEqual, map } from 'lodash-es';

import { AppState } from '../../../store';
import {
  ConfirmationTypeDropdown,
  GroupsMultiSelect,
  ServiceZoneDropdown,
  SupervisorDropdown,
  VehicleTypeForVendorDropdown,
} from '..';
import { customerLocationsSelector } from '../../../customers/ducks';
import {
  DatePicker,
  Dropdown,
  Input,
  NavigationPrompt,
  RadioCheckbox,
  Switch,
  TypedField,
} from '../../../core/components';
import {
  DELIVERY_UTILITY,
  RESIDENTIAL,
  ROLL_OFF,
  ROUTE,
  SNOW_PLOW,
  STREET_SWEEPER,
  WASTE_AUDIT,
} from '../../../fleet/constants';
import {
  DESCRIPTIVE_DATE_RANGE,
  RECURRENCE_TYPES,
  SPECIFIC_DATE_RANGE,
  WEEKDAYS,
  DAYS_OF_MONTH,
} from '../../../core/constants/weekdays';
import { Facility } from '../../../common/interfaces/Facility';
import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { getExcludeVehicleFilters, getExcludedFiltersIds } from 'src/common/utils/filters';
import { Grid, GridColumn, PanelSection, PanelSectionGroup, Text } from '../../../core/components/styled';
import {
  isValidEndDayOfMonth,
  isValidEndDay,
  isValidEndMonth,
  isDateValidValidator,
  isRequired,
  isValidEndTime,
  isValidStartDayOfMonth,
  maxLength50,
} from '../../../utils/services/validator';
import { RouteLocation } from '../../interfaces/RouteLocation';
import { RouteTemplate } from '../../interfaces/RouteTemplates';
import { Supervisor } from '../../interfaces/Supervisors';
import { supervisorExperienceFeatureIsEnabled } from '../../../vendors/ducks/features';
import { technicalNameByVehicleTypeIdSelector } from '../../../fleet/ducks';
import { WasteTypeDropdown } from '../../../common/components';
import { WEEKDAYS_BY_SHORTCODE } from '../../../core/constants';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import MONTHS from '../../../common/constants/months';
import routeTemplateEditorFormInitialValuesSelector from '../../services/routeTemplateEditorFormInitialValuesSelector';
import TimePickerField from 'src/core/components/TimePickerField';
import translate from '../../../core/services/translate';
import { Group } from 'src/routes/interfaces/Groups';

const ROUTE_TEMPLATE_EDITOR = 'routeTemplateEditor';

const dayOfWeekOptions = map(WEEKDAYS_BY_SHORTCODE, dayOfWeek => ({
  label: dayOfWeek.name,
  value: dayOfWeek.shortCode,
}));

export interface FormValues {
  endTime: string;
  isTestRoute: boolean;
  routeConfirmationTypeId: number;
  routeDate: Date | string;
  routeName: string;
  seasonality: boolean;
  startingLocationId: number;
  endingLocationId: number;
  startTime: string;
  supervisorId: number;
  vehicleId: number;
  vendorServiceZoneId: number;
  wasteMaterialTypeId: number;
  groups: number[];
  /** TODO Intermediate Facility */
  // intermediateLocationId: number;
}

interface PropsWithoutReduxForm {
  change: any;
  truckYardFacilities?: Facility[];
  disposalFacilities?: Facility[];
  disabledFields: { [key: string]: boolean | string | number };
  endTime?: string;
  filtersPreferences?: FilterSetting[];
  isCreateMode?: boolean;
  isDateRangeTypeSpecific?: boolean;
  isSeasonalityEnabled: boolean;
  routeTemplate?: RouteTemplate;
  routeTemplateLocations?: RouteLocation[];
  serviceZones?: number[];
  startTime?: string;
  supervisorExperienceEnabled: boolean;
  supervisors: Supervisor[];
  transferredStops: boolean;
  vehicleTypeTechnicalName?: string;
  groups: Group[];
  /** TODO Intermediate Facility */
  // disposalFacilities: Facility[]; // ??
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const RouteTemplateEditorForm = (props: Props) => {
  const {
    change,
    truckYardFacilities,
    disposalFacilities,
    disabledFields,
    endTime,
    filtersPreferences,
    handleSubmit,
    initialValues,
    isCreateMode,
    isDateRangeTypeSpecific,
    isSeasonalityEnabled,
    pristine,
    routeTemplate,
    routeTemplateLocations,
    serviceZones,
    startTime,
    submitSucceeded,
    supervisorExperienceEnabled,
    supervisors,
    transferredStops,
    vehicleTypeTechnicalName,
    groups,
    /** TODO Intermediate Facility */
    // disposalFacilities,
  } = props;

  const dispatch = useDispatch();

  const resetRouteTemplateLocation = () => {
    dispatch(reset('addRouteTemplateLocation'));
  };

  useEffect(() => {
    if (vehicleTypeTechnicalName === ROLL_OFF || vehicleTypeTechnicalName === DELIVERY_UTILITY) {
      change('wasteMaterialTypeId', null);
    }
  }, [change, vehicleTypeTechnicalName]);

  const routeTemplateLocationIds = map(
    routeTemplateLocations,
    routeTemplateLocation => routeTemplateLocation.serviceContractRouteTemplateId,
  );
  const locationIds = map(
    get(routeTemplate, 'routeLocations'),
    routeTemplateLocation => routeTemplateLocation.serviceContractRouteTemplateId,
  );
  const differentRouteTemplateLocations = !isEqual(routeTemplateLocationIds, locationIds) && !transferredStops;
  const isVisibleSupervisor = vehicleTypeTechnicalName !== STREET_SWEEPER && vehicleTypeTechnicalName !== WASTE_AUDIT;

  const excludeVehicleFilters = isCreateMode ? getExcludeVehicleFilters(filtersPreferences) : [];
  const excludedServiceZonesFiltersIds = isCreateMode
    ? getExcludedFiltersIds(serviceZones, filtersPreferences, FILTER_SERVICE_ZONE_ID)
    : filter(
        getExcludedFiltersIds(serviceZones, filtersPreferences, FILTER_SERVICE_ZONE_ID),
        id => initialValues && initialValues.vendorServiceZoneId !== id,
      );
  const excludedSupervisorsFiltersIds = isCreateMode
    ? getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID)
    : filter(
        getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID),
        id => initialValues && initialValues.supervisorId !== id,
      );

  const recurrenceTypes = RECURRENCE_TYPES.map(type => ({
    label: type.label,
    value: type.value,
  }));

  const recurrenceDaysOfMonth = DAYS_OF_MONTH.map(day => ({
    label: day.name,
    value: day.id,
  }));

  const recurrenceDays = WEEKDAYS.map(day => ({
    label: day.name,
    value: day.id,
  }));

  const recurrenceMonths = Object.keys(MONTHS).map((key, index) => ({
    label: translate(MONTHS[key].translationKey),
    value: index + 1,
  }));

  const onStartingLocationChange = (event: any, value: number) => {
    change('endingLocationId', value);
  };

  // groupIds to exclude those inactive groups  except if initial value is inactive
  const groupIdsToExclude = map(
    filter(groups, group => !initialValues?.groups?.includes(group.id) && !group.isActive),
    'id',
  );

  let filteredTruckYardFacilities = [] as Facility[];
  if (!!truckYardFacilities)
    filteredTruckYardFacilities = !isCreateMode
      ? truckYardFacilities?.filter(
          facility =>
            facility.isActive || (!facility.isActive && facility.locationId === initialValues.startingLocationId),
        )
      : truckYardFacilities;

  let filteredCustomerLocations = [] as Facility[];
  if (!!truckYardFacilities && !!disposalFacilities)
    filteredCustomerLocations = !isCreateMode
      ? [...truckYardFacilities, ...disposalFacilities].filter(
          facility =>
            facility.isActive || (!facility.isActive && facility.locationId === initialValues.endingLocationId),
        )
      : [...truckYardFacilities, ...disposalFacilities];

  const startingLocationOptions = customerLocationsSelector(filteredTruckYardFacilities);
  const endingLocationOptions = customerLocationsSelector(filteredCustomerLocations);

  return (
    <PanelSectionGroup>
      <NavigationPrompt when={(!pristine || differentRouteTemplateLocations) && !submitSucceeded} />
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall no">
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="routeTemplateName"
                component={Input}
                label={translate('routes.routeName')}
                validate={[isRequired, maxLength50]}
                disabled={disabledFields.routeTemplateName}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="scheduledDay"
                component={Dropdown as any}
                margin="no"
                options={dayOfWeekOptions}
                label={translate('routes.dayOfService')}
                validate={[isRequired]}
                disabled={disabledFields.scheduledDay}
                onChange={resetRouteTemplateLocation}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="vehicleTypeId"
                component={VehicleTypeForVendorDropdown}
                vehicleRoleTypeId={ROUTE}
                excludeVehicleTypes={[SNOW_PLOW, STREET_SWEEPER, ...excludeVehicleFilters]}
                withLabel
                validate={[isRequired]}
                dropdownProps={{ margin: 'no', disabled: disabledFields.vehicleTypeId }}
                onChange={resetRouteTemplateLocation}
              />
            </GridColumn>
            {/* NOTE: Temporarily we leave waste type field always enabled. See RVP-2001 */}
            {vehicleTypeTechnicalName !== ROLL_OFF && vehicleTypeTechnicalName !== DELIVERY_UTILITY && (
              <GridColumn size="3/12">
                <Field name="wasteMaterialTypeId" component={WasteTypeDropdown} withLabel validate={[isRequired]} />
              </GridColumn>
            )}
          </Grid>
        </PanelSection>
        <PanelSection padding="no xSmall xSmall" withBorder>
          <Grid multiLine>
            <GridColumn size="3/12">
              <Field name="isEnabled" component={Switch} label={translate('routes.activateRouteTemplate')} />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="startDate"
                component={DatePicker}
                label={translate('common.startDate')}
                disabled={disabledFields.startDate}
                validate={[isDateValidValidator]}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <TimePickerField
                defaultValue={initialValues?.startTime}
                labelName={translate('routes.scheduledStartTime')}
                name="startTime"
                validate={!!endTime ? [isRequired] : undefined}
                formName={ROUTE_TEMPLATE_EDITOR}
                minuteStep={15}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <TimePickerField
                defaultValue={initialValues?.endTime}
                labelName={translate('routes.scheduledEndTime')}
                name="endTime"
                validate={startTime ? [isRequired, isValidEndTime] : [isValidEndTime]}
                formName={ROUTE_TEMPLATE_EDITOR}
                minuteStep={15}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <TypedField
                name="description"
                component={Input}
                props={{ label: translate('routes.description'), autoComplete: 'off' }}
                validate={[maxLength50]}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="startingLocationId"
                component={Dropdown as any}
                options={startingLocationOptions}
                label={translate('routes.startingLocation')}
                disabled={disabledFields.startLocationId}
                onChange={onStartingLocationChange}
                isClearable
              />
            </GridColumn>

            {/** TODO Intermediate Facility */}
            {/* <GridColumn size="3/12">
              <Field
                name="intermediateLocationId"
                component={Dropdown}
                props={{
                  isClearable: true,
                  label: translate('routes.intermediateFacility'),
                  width: '100%',
                  options: disposalFacilities.map(facility => ({
                    label: facility.name,
                    value: facility.locationId,
                  })),
                }}
              />
            </GridColumn> */}
            <GridColumn size="3/12">
              <Field
                name="endingLocationId"
                component={Dropdown}
                options={endingLocationOptions}
                label={translate('routes.endingLocation')}
                disabled={disabledFields.startingLocationId}
                isClearable
                id="route-ending-location"
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="vendorServiceZoneId"
                component={ServiceZoneDropdown}
                label={translate('routes.serviceZone')}
                props={{
                  excludeServiceZonesIds: excludedServiceZonesFiltersIds,
                }}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="groups"
                component={GroupsMultiSelect}
                props={{
                  withLabel: true,
                  excludeGroupsIds: groupIdsToExclude,
                  cantSelectInactive: true,
                }}
                multiSelectProps={{
                  defaultToAll: false,
                }}
              />
            </GridColumn>
            {vehicleTypeTechnicalName === RESIDENTIAL && (
              <GridColumn size="3/12">
                <Field
                  name="routeConfirmationTypeId"
                  component={ConfirmationTypeDropdown}
                  label={translate('routes.confirmationType')}
                  validate={[isRequired]}
                  dropdownProps={{ disabled: disabledFields.routeConfirmationTypeId }}
                />
              </GridColumn>
            )}

            {supervisorExperienceEnabled && isVisibleSupervisor && (
              <GridColumn size="3/12">
                <TypedField
                  name="supervisorId"
                  component={SupervisorDropdown}
                  props={{
                    withLabel: true,
                    excludeSupervisorsIds: excludedSupervisorsFiltersIds,
                  }}
                />
              </GridColumn>
            )}
          </Grid>
        </PanelSection>

        <PanelSection padding="small xSmall sMedium xSmall" withBorder>
          <Grid multiLine>
            <GridColumn size="2/12" alignVerticalCenter margin="small no no no">
              <Field
                name="seasonality"
                component={Switch}
                margin="no"
                labelOnLeft
                labelSize="large"
                label={translate('routes.seasonality.seasonality')}
              />
            </GridColumn>
            <GridColumn size="10/12" alignVerticalCenter margin="small no no no">
              <Field
                name="createRouteWithNoStops"
                component={Switch}
                margin="no"
                labelOnLeft
                labelSize="large"
                label={translate('routes.createAsDailyRouteWithNoStops')}
              />
            </GridColumn>

            {isSeasonalityEnabled && (
              <>
                <GridColumn size="12/12" margin="small no xSmall no">
                  <Field
                    name="dateRangeType"
                    type="radio"
                    value={SPECIFIC_DATE_RANGE}
                    component={RadioCheckbox}
                    props={{
                      label: translate('routes.seasonality.specificDateRange'),
                      margin: 'small sMedium no no',
                    }}
                  />

                  <Field
                    name="dateRangeType"
                    type="radio"
                    value={DESCRIPTIVE_DATE_RANGE}
                    component={RadioCheckbox}
                    props={{
                      label: translate('routes.seasonality.descriptiveDateRange'),
                      margin: 'small no no',
                    }}
                  />
                </GridColumn>

                {isDateRangeTypeSpecific ? (
                  <>
                    <GridColumn size="4/12" alignVerticalCenter margin="small no no no">
                      <Text block weight="medium" size="medium" margin="no no small no">
                        {translate('routes.seasonality.rangeStart')}
                      </Text>
                    </GridColumn>

                    <GridColumn size="8/12" alignVerticalCenter margin="small no no no">
                      <Text block weight="medium" size="medium" margin="no no small no">
                        {translate('routes.seasonality.rangeEnd')}
                      </Text>
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="startDay"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.day'),
                          options: recurrenceDaysOfMonth,
                          isClearable: true,
                        }}
                        validate={[isRequired, isValidStartDayOfMonth]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="startMonth"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.month'),
                          options: recurrenceMonths,
                          isClearable: true,
                        }}
                        validate={[isRequired]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="endDay"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.day'),
                          options: recurrenceDaysOfMonth,
                          isClearable: true,
                        }}
                        validate={[isRequired, isValidEndDayOfMonth]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="endMonth"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.month'),
                          options: recurrenceMonths,
                          isClearable: true,
                        }}
                        validate={[isRequired, isValidEndMonth]}
                      />
                    </GridColumn>
                  </>
                ) : (
                  <>
                    <GridColumn size="6/12" alignVerticalCenter margin="small no no no">
                      <Text block weight="medium" size="medium" margin="no no small no">
                        {translate('routes.seasonality.rangeStart')}
                      </Text>
                    </GridColumn>

                    <GridColumn size="6/12" alignVerticalCenter margin="small no no no">
                      <Text block weight="medium" size="medium" margin="no no small no">
                        {translate('routes.seasonality.rangeEnd')}
                      </Text>
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="startRecurrenceDayTypeId"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.order'),
                          options: recurrenceTypes,
                          isClearable: true,
                        }}
                        validate={[isRequired]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="startDayOfWeek"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.dayOfWeek'),
                          options: recurrenceDays,
                          isClearable: true,
                        }}
                        validate={[isRequired]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="startMonth"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.month'),
                          options: recurrenceMonths,
                          isClearable: true,
                        }}
                        validate={[isRequired]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="endRecurrenceDayTypeId"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.order'),
                          options: recurrenceTypes,
                          isClearable: true,
                        }}
                        validate={[isRequired]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="endDayOfWeek"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.dayOfWeek'),
                          options: recurrenceDays,
                          isClearable: true,
                        }}
                        validate={[isRequired, isValidEndDay]}
                      />
                    </GridColumn>

                    <GridColumn size="2/12">
                      <Field
                        name="endMonth"
                        component={Dropdown}
                        props={{
                          width: '100%',
                          label: translate('routes.seasonality.month'),
                          options: recurrenceMonths,
                          isClearable: true,
                        }}
                        validate={[isRequired, isValidEndMonth]}
                      />
                    </GridColumn>
                  </>
                )}
              </>
            )}
          </Grid>
        </PanelSection>
      </form>
    </PanelSectionGroup>
  );
};

const formSelector = formValueSelector(ROUTE_TEMPLATE_EDITOR);

const mapStateToProps = (state: AppState) => {
  const vehicleTypeId = Number(formSelector(state, 'vehicleTypeId'));
  const startTime = formSelector(state, 'startTime');
  const endTime = formSelector(state, 'endTime');
  const isSeasonalityEnabled = formSelector(state, 'seasonality');
  const dateRangeType = formSelector(state, 'dateRangeType');

  return {
    truckYardFacilities: state.fleet.facilities.operationalFacilities,
    disposalFacilities: state.fleet.facilities.disposalFacilities,
    endTime,
    filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
    initialValues: routeTemplateEditorFormInitialValuesSelector(state.routes.routeTemplate, state.routes.geoFence),
    isDateRangeTypeSpecific: dateRangeType === SPECIFIC_DATE_RANGE,
    isSeasonalityEnabled,
    routeTemplate: state.routes.routeTemplate.routeTemplate,
    serviceZones: state.routes.serviceZones.serviceZones,
    startTime,
    supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
    supervisors: state.routes.supervisors.supervisors,
    vehicleTypeTechnicalName: technicalNameByVehicleTypeIdSelector(state.fleet.vehicleTypesForVendor, vehicleTypeId),
    groups: state.routes.groups.groups,
    /** TODO Intermediate Facility */
    // disposalFacilities: state.fleet.facilities.disposalFacilities,
  };
};

const mapDispatchToProps = { change };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: ROUTE_TEMPLATE_EDITOR,
    onSubmitFail: focusFirstInvalidField,
  })(RouteTemplateEditorForm),
);
