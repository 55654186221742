import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadRoute as doLoadRoute,
  createRoute as doCreateRoute,
  updateRoute as doUpdateRoute,
} from '../../services/dispatchBoardRoutes';

// Actions
const START_LOAD = 'dispatchBoard/route/START_LOAD';
const FAIL_LOAD = 'dispatchBoard/route/FAIL_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/route/COMPLETE_LOAD';
const START_SAVE = 'dispatchBoard/route/START_SAVE';
const FAIL_SAVE = 'dispatchBoard/route/FAIL_SAVE';
const COMPLETE_SAVE = 'dispatchBoard/route/COMPLETE_SAVE';
const RESET = 'dispatchBoard/route/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  route: any;
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isSaving: false,
  route: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          route: action.route,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (route: any) => ({
  type: COMPLETE_LOAD,
  route,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadDispatchBoardRoute = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDispatchBoardRoutePromise = doLoadRoute(routeId);
  loadDispatchBoardRoutePromise.then(route => dispatch(completeLoad(route))).catch(() => dispatch(failLoad()));
  return loadDispatchBoardRoutePromise;
};

export const saveDispatchBoardRoute = (route: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveDispatchBoardRoutePromise = route.id ? doUpdateRoute(route.id, route) : doCreateRoute(route);
  saveDispatchBoardRoutePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveDispatchBoardRoutePromise;
};

export const resetDispatchBoardRoute = () => ({
  type: RESET,
});
