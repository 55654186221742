import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import translate from '../../core/services/translate';

const CUSTOMER_UPLOAD_TYPE = 2;
export const ESRI_INTEGRATION = 'ESRIIntegration';

export const uploadCustomersFile = (fileData: any, vendorId: number) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http.post('/customerImport/batchUpload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUploadedFilesStatus = (vendorId: number): Promise<{ data: any[] }> =>
  http.get('/customerImport/batchList', { params: { vendorId } });

export const getUploadedEsriFilesStatus = (vendorId: number, featureCode: string): Promise<{ data: any[] }> => {
  if (featureCode === ESRI_INTEGRATION) {
    return http.get('esri/esriImport/batchList', {
      params: { vendorId, esriUploadType: CUSTOMER_UPLOAD_TYPE },
    });
  } else {
    return http.get('/customerImport/batchList', {
      params: { vendorId, customerUploadType: CUSTOMER_UPLOAD_TYPE },
    });
  }
};

export const downloadSampleCustomerTemplate = () =>
  downloadExcelFile('/customerImport/downloadTemplate', translate('customers.accountStatusUpload.sampleFile'));
