import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getHaulerLocationIcon } from 'src/common/constants/vendorLocationIcons';
import { HaulerLocation } from 'src/dashboard/interfaces/haulerLocations';
import { ROUTE_MAP_HAULER_LOCATIONS_LAYER } from '../constants';

export type HaulerLocationFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getHaulerLocationsGeoJSON = (haulerLocations: HaulerLocation[]) =>
  getFeatureCollection<GeoJSON.Point, HaulerLocationFeatureProperties>(
    haulerLocations.map(location =>
      getPointFeature(location.id, [location.longitude, location.latitude], {
        id: location.id,
        clickable: true,
        layer: ROUTE_MAP_HAULER_LOCATIONS_LAYER,
        icon: getHaulerLocationIcon(location),
      }),
    ),
  );
