import { map, trimStart } from 'lodash-es';

interface ProximitySettingsFormData {
  vendorId: number;
  proximitySettings: {
    distance: number;
    measurementType: string;
    vehicleTypeId: number;
    locationType: string;
    customerTypeId: number;
  }[];
}

const getProximitySettingsFormData = (formData: ProximitySettingsFormData) => ({
  vendorId: formData.vendorId,
  proximitySettings: map(
    formData.proximitySettings,
    ({ distance, measurementType, vehicleTypeId, locationType, customerTypeId }, formFieldName) => ({
      id: Number(trimStart(formFieldName as any, 'settings')),
      distance,
      measurementType,
      vehicleTypeId,
      locationType,
      customerTypeId,
    }),
  ),
});

export default getProximitySettingsFormData;
