import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
    loadCityWorksErrors as doLoadCityWorksErrors,
    loadCityWorksConfiguration as doLoadCityWorksConfiguration,
    loadCityWorksServiceConfiguration as doLoadCityWorksServiceConfiguration,
    loadCityWorksproblemtypes as doLoadCityWorksproblemtypes,
    deleteServiceRequestErrors as doDeleteServiceRequestErrors
} from '../services/cityWorksConfigurationService';
import { ICityWorksLookups as IState } from '../interfaces/CityWorksConfiguration';

// Actions
const START_LOAD_ERRORS = 'vendors/cityWorksConfiguration/START_LOAD_ERRORS';
const COMPLETE_LOAD_ERRORS = 'vendors/cityWorksConfiguration/COMPLETE_LOAD_ERRORS';
const FAIL_LOAD_ERRORS = 'vendors/cityWorksConfiguration/FAIL_LOAD_ERRORS';
const RESET_ERRORS = 'vendors/cityWorksConfiguration/RESET_ERRORS';

// Actions
const START_LOAD_SERVICE = 'vendors/cityWorksConfiguration/START_LOAD_SERVICE';
const COMPLETE_LOAD_SERVICE = 'vendors/cityWorksConfiguration/COMPLETE_LOAD_SERVICE';
const FAIL_LOAD_SERVICE = 'vendors/cityWorksConfiguration/FAIL_LOAD_SERVICE';
const RESET_SERVICE = 'vendors/cityWorksConfiguration/RESET_SERVICE';

// Actions
const START_LOAD_CONFIG = 'vendors/cityWorksConfiguration/START_LOAD_CONFIG';
const COMPLETE_LOAD_CONFIG = 'vendors/cityWorksConfiguration/COMPLETE_LOAD_CONFIG';
const FAIL_LOAD_CONFIG = 'vendors/cityWorksConfiguration/FAIL_LOAD_CONFIG';

// Actions-Problem Types
const START_LOAD_PROBLEM_TYPES = 'vendors/cityWorksConfiguration/START_LOAD_PROBLEM_TYPES';
const COMPLETE_LOAD_PROBLEM_TYPES = 'vendors/cityWorksConfiguration/COMPLETE_LOAD_PROBLEM_TYPES';
const FAIL_LOAD_PROBLEM_TYPES = 'vendors/cityWorksConfiguration/FAIL_LOAD_PROBLEM_TYPES';
const RESET_PROBLEM_TYPES = 'vendors/cityWorksConfiguration/RESET_PROBLEM_TYPES';

// Actions-Problem Types
const START_DELETE_SERVICE_ERROR = 'vendors/cityWorksConfiguration/START_DELETE_SERVICE_ERROR';
const COMPLETE_DELETE_SERVICE_ERROR = 'vendors/cityWorksConfiguration/COMPLETE_DELETE_SERVICE_ERROR';
const FAIL_DELETE_SERVICE_ERROR = 'vendors/cityWorksConfiguration/FAIL_DELETE_SERVICE_ERROR';


// Initial state
const initialState: IState = {
    isLoadingProblemTypes: false,
    isLoadingErrors: false,
    isLoadingService: false,
    isLoadingConfig: false,
    isLoadingServiceError: false,
    isFailedServiceError: false,
    dataErrors: undefined,
    dataService: undefined,
    dataConfig: undefined,
    problemTypes: undefined
};

// Reducer
export const reducer = (state: IState = initialState, action: AnyAction) => {
    switch (action.type) {
        case START_LOAD_ERRORS:
            return update(state, {
                $merge: {
                    isLoadingErrors: true,
                },
            });

        case COMPLETE_LOAD_ERRORS:
            return update(state, {
                $merge: {
                    isLoadingErrors: false,
                    dataErrors: action.errors,
                },
            });

        case FAIL_LOAD_ERRORS:
            return update(state, {
                $merge: {
                    isLoadingErrors: false,
                },
            });

        case RESET_ERRORS:
            return update(state, { $merge: initialState });

        case START_LOAD_SERVICE:
            return update(state, {
                $merge: {
                    isLoadingService: true,
                },
            });

        case COMPLETE_LOAD_SERVICE:
            return update(state, {
                $merge: {
                    isLoadingService: false,
                    dataService: action.services,
                },
            });

        case FAIL_LOAD_SERVICE:
            return update(state, {
                $merge: {
                    isLoadingService: false,
                },
            });

        case RESET_SERVICE:
            return update(state, { $merge: initialState });

        //Config

        case START_LOAD_CONFIG:
            return update(state, {
                $merge: {
                    isLoadingConfig: true,
                },
            });

        case COMPLETE_LOAD_CONFIG:
            return update(state, {
                $merge: {
                    isLoadingConfig: false,
                    dataConfig: action.config,
                },
            });

        case FAIL_LOAD_CONFIG:
            return update(state, {
                $merge: {
                    isLoadingConfig: false,
                },
            });

        // Service Request Error

        case START_DELETE_SERVICE_ERROR:
            return update(state, {
                $merge: {
                    isLoadingServiceError: true,
                },
            });

        case COMPLETE_DELETE_SERVICE_ERROR:
            return update(state, {
                $merge: {
                    isLoadingServiceError: false,
                    isFailedServiceError: false,
                },
            });

        case FAIL_DELETE_SERVICE_ERROR:
            return update(state, {
                $merge: {
                    isLoadingServiceError: false,
                    isFailedServiceError: true,
                },
            });

        //Config Problem Types

        case START_LOAD_PROBLEM_TYPES:
            return update(state, {
                $merge: {
                    isLoadingProblemTypes: true,
                },
            });

        case COMPLETE_LOAD_PROBLEM_TYPES:
            return update(state, {
                $merge: {
                    isLoadingProblemTypes: false,
                    problemTypes: action.config,
                },
            });

        case FAIL_LOAD_PROBLEM_TYPES:
            return update(state, {
                $merge: {
                    isLoadingProblemTypes: false,
                },
            });

        case RESET_PROBLEM_TYPES:
            return update(state, { $merge: initialState });

        default:
            return state;
    }
};

// Action creators for errors
const startLoadErrors = () => ({
    type: START_LOAD_ERRORS,
});

const completeLoadErrors = (errors: any) => ({
    type: COMPLETE_LOAD_ERRORS,
    errors,
});

const failLoadErrors = () => ({
    type: FAIL_LOAD_ERRORS,
});

// Action creators for service request errors
const startDeleteErrors = () => ({
    type: START_DELETE_SERVICE_ERROR,
});

const completeDeleteErrors = (errors: any) => ({
    type: COMPLETE_DELETE_SERVICE_ERROR,
    errors,
});

const failDeleteErrors = () => ({
    type: FAIL_DELETE_SERVICE_ERROR,
});


// Action creators for service
const startLoadService = () => ({
    type: START_LOAD_SERVICE,
});

const completeLoadService = (services: any) => ({
    type: COMPLETE_LOAD_SERVICE,
    services,
});

const failLoadService = () => ({
    type: FAIL_LOAD_SERVICE,
});

// Action creators for config
const startLoadConfig = () => ({
    type: START_LOAD_CONFIG,
});

const completeLoadConfig = (config: any) => ({
    type: COMPLETE_LOAD_CONFIG,
    config,
});

const failLoadConfig = () => ({
    type: FAIL_LOAD_CONFIG,
});


// Action creators for config - Problem
const startLoadProblemTypes = () => ({
    type: START_LOAD_PROBLEM_TYPES,
});

const completeLoadProblemTypes = (config: any) => ({
    type: COMPLETE_LOAD_PROBLEM_TYPES,
    config,
});

const failLoadProblemTypes = () => ({
    type: FAIL_LOAD_PROBLEM_TYPES,
});





export const loadCityWorksConfigurationErrors = (vendorId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadErrors());
    const loadReasonTypesPromise = doLoadCityWorksErrors(vendorId);
    loadReasonTypesPromise
        .then(errors => dispatch(completeLoadErrors(errors)))
        .catch(() => dispatch(failLoadErrors()));

    return loadReasonTypesPromise;
};

export const loadCityWorksConfiguration = (vendorId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadConfig());
    const loadReasonTypesPromise = doLoadCityWorksConfiguration(vendorId);
    loadReasonTypesPromise
        .then(errors => dispatch(completeLoadConfig(errors)))
        .catch(() => dispatch(failLoadConfig()));

    return loadReasonTypesPromise;
};

export const loadCityWorksServiceConfiguration = (vendorId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadService());
    const loadReasonTypesPromise = doLoadCityWorksServiceConfiguration(vendorId);
    loadReasonTypesPromise
        .then(errors => dispatch(completeLoadService(errors)))
        .catch(() => dispatch(failLoadService()));

    return loadReasonTypesPromise;
};

export const loadCityWorksproblemtypes = (vendorId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadProblemTypes());
    const loadReasonTypesPromise = doLoadCityWorksproblemtypes(vendorId);
    loadReasonTypesPromise
        .then(errors => dispatch(completeLoadProblemTypes(errors)))
        .catch(() => dispatch(failLoadProblemTypes()));

    return loadReasonTypesPromise;
};

export const deleteServiceRequestErrors = (vendorId: number, data: number[]) => (dispatch: Dispatch) => {
    dispatch(startDeleteErrors());
    const loadReasonTypesPromise = doDeleteServiceRequestErrors(vendorId, data);
    loadReasonTypesPromise
        .then(errors => dispatch(completeDeleteErrors(errors)))
        .catch(() => dispatch(failDeleteErrors()));

    return loadReasonTypesPromise;
};

