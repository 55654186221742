import frontLoad from '../../common/assets/img/services/frontLoad.png';
import openTop from '../../common/assets/img/services/openTop.png';
import selfContained from '../../common/assets/img/services/selfContained.png';
import rearLoad from '../../common/assets/img/services/rearLoad.png';
import cart from '../../common/assets/img/services/cart.png';
import publicContainer from '../../common/assets/img/services/publicContainer.png';
import bulkItem from '../../common/assets/img/services/bulkItem.png';
import portableToilet from '../../common/assets/img/services/portableToilet.png';
import bags from '../../common/assets/img/services/handPickup.png';
import rubiconLogo from '../../common/assets/img/services/rubiconLogo.png';
import receiverBox from '../../common/assets/img/services/receiverBox.png';
import stationaryCompactor from '../../common/assets/img/services/stationaryCompactor.png';
import verticalCompactor from '../../common/assets/img/services/verticalCompactor.png';

const SERVICE_TYPE_ICONS: { [key: string]: string } = {
  frontLoad,
  openTop,
  selfContained,
  rearLoad,
  toter: cart,
  cart,
  bags,
  verticalCompactor: verticalCompactor,
  receiverBox: receiverBox,
  selfContCompactor: selfContained,
  stationaryCompactor: stationaryCompactor,
  recycler: openTop,
  unknown: rubiconLogo,
  publicContainer,
  bulkItem,
  portableSink: portableToilet,
  portableRestroomTrailer: portableToilet,
  waterTote: portableToilet,
  holdingTank: portableToilet,
  portableToilet,
  none: rubiconLogo,
  selfContainedCompBox: selfContained,
  cART: cart,
};

export default SERVICE_TYPE_ICONS;
