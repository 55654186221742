import update from 'immutability-helper';
import { identity, orderBy } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { Vendor } from '../interfaces/Vendors';
import { AssignedVendors } from '../interfaces/UserEditor';
import {
  getAssignedVendors,
  getUserAssignedVendors,
  getUserAvailableVendors,
  saveVendorAccess as doSaveVendorAccess,
} from '../services/user';
import { loadVendors as doLoadVendors } from '../services/vendors';

// Actions
const START_LOAD_AVAILABLE_VENDORS = 'vendors/vendorAccess/START_LOAD_AVAILABLE_VENDORS';
const COMPLETE_LOAD_AVAILABLE_VENDORS = 'vendors/vendorAccess/COMPLETE_LOAD_AVAILABLE_VENDORS';
const FAIL_LOAD_AVAILABLE_VENDORS = 'vendors/vendorAccess/FAIL_LOAD_AVAILABLE_VENDORS';
const START_LOAD_ASSIGNED_VENDORS = 'vendors/vendorAccess/START_LOAD_ASSIGNED_VENDORS';
const COMPLETE_LOAD_ASSIGNED_VENDORS = 'vendors/vendorAccess/COMPLETE_LOAD_ASSIGNED_VENDORS';
const FAIL_LOAD_ASSIGNED_VENDORS = 'vendors/vendorAccess/FAIL_LOAD_ASSIGNED_VENDORS';
const START_SAVE_VENDOR_ACCESS = 'vendors/vendorAccess/START_SAVE_VENDOR_ACCESS';
const COMPLETE_SAVE_VENDOR_ACCESS = 'vendors/vendorAccess/COMPLETE_SAVE_VENDOR_ACCESS';
const FAIL_SAVE_VENDOR_ACCESS = 'vendors/vendorAccess/FAIL_SAVE_VENDOR_ACCESS';
const COMPLETE_SAVE_ASSIGNED_VENDORS = 'vendors/vendorAccess/COMPLETE_SAVE_ASSIGNED_VENDORS';
interface VendorAccessState {
  assignedUserVendors: Vendor[];
  availableVendors: Vendor[];
  isAllAssignedVendorsRemoved: boolean;
  isLoadingAvailableVendors: boolean;
  isLoadingAssignedVendors: boolean;
  isSaving: boolean;
  vendorAccessUserId: string | null;
}

// Initial state
const initialState: VendorAccessState = {
  assignedUserVendors: [],
  availableVendors: [],
  isAllAssignedVendorsRemoved: false,
  isLoadingAvailableVendors: false,
  isLoadingAssignedVendors: false,
  isSaving: false,
  vendorAccessUserId: null,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_SAVE_VENDOR_ACCESS:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });
    case START_LOAD_AVAILABLE_VENDORS:
      return update(state, {
        $merge: {
          isLoadingAvailableVendors: true,
        },
      });

    case COMPLETE_LOAD_AVAILABLE_VENDORS:
      return update(state, {
        $merge: {
          isLoadingAvailableVendors: false,
          availableVendors: orderBy(action.allAvailableVendors, [user => user.name.toLowerCase()], 'asc'),
          vendorAccessUserId: action.userId ? action.userId : null,
        },
      });
    case COMPLETE_SAVE_VENDOR_ACCESS:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_LOAD_AVAILABLE_VENDORS:
      return update(state, {
        $merge: {
          isLoadingAvailableVendors: false,
          availableVendors: [],
          vendorAccessUserId: null,
        },
      });
    case FAIL_SAVE_VENDOR_ACCESS:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    case START_LOAD_ASSIGNED_VENDORS:
      return update(state, {
        $merge: {
          isLoadingAssignedVendors: true,
        },
      });
    case COMPLETE_LOAD_ASSIGNED_VENDORS:
      return update(state, {
        $merge: {
          isLoadingAssignedVendors: true,
          isAllAssignedVendorsRemoved: false,
          assignedUserVendors: orderBy(action.assignedVendors, [user => user.name.toLowerCase()], 'asc'),
          vendorAccessUserId: action.userId ? action.userId : null,
        },
      });

    case FAIL_LOAD_ASSIGNED_VENDORS:
      return update(state, {
        $merge: {
          isLoadingAssignedVendors: true,
          assignedUserVendors: [],
          vendorAccessUserId: null,
        },
      });
    case COMPLETE_SAVE_ASSIGNED_VENDORS:
      return {
        ...state,
        assignedUserVendors: action.assignedVendors,
        availableVendors: action.availableVendors,
        isAllAssignedVendorsRemoved: action.isRemoveAllAssignedVendors,
      };

    default:
      return state;
  }
};

// Action creators

const startVendorsLoad = () => ({
  type: START_LOAD_AVAILABLE_VENDORS,
});

export const completeLoadVendors = (allAvailableVendors: any, userId?: number) => ({
  type: COMPLETE_LOAD_AVAILABLE_VENDORS,
  allAvailableVendors,
  userId,
});

const failLoadVendors = () => ({
  type: FAIL_LOAD_AVAILABLE_VENDORS,
});

const startAssignedVendorsLoad = () => ({
  type: START_LOAD_ASSIGNED_VENDORS,
});

export const completeLoadAssignedVendors = (assignedVendors: any[], userId?: number) => ({
  type: COMPLETE_LOAD_ASSIGNED_VENDORS,
  assignedVendors,
  userId,
});

const failLoadAssignedVendors = () => ({
  type: FAIL_LOAD_ASSIGNED_VENDORS,
});

const startVendorAccessSave = () => ({
  type: START_SAVE_VENDOR_ACCESS,
});

const failVendorAccessSave = () => ({
  type: FAIL_SAVE_VENDOR_ACCESS,
});

export const completeVendorAccessSave = () => ({
  type: COMPLETE_SAVE_VENDOR_ACCESS,
});

export const saveAssignedVendors = (
  assignedVendors: Vendor[],
  availableVendors: Vendor[],
  isRemoveAllAssignedVendors: boolean,
) => ({
  type: COMPLETE_SAVE_ASSIGNED_VENDORS,
  assignedVendors,
  availableVendors,
  isRemoveAllAssignedVendors,
});
// Async Actions

export const saveVendorAccess = (vendorId: number, userId: string, data: AssignedVendors[]) => (dispatch: Dispatch) => {
  dispatch(startVendorAccessSave());
  return doSaveVendorAccess(vendorId, userId, data)
    .then(() => dispatch(completeVendorAccessSave()))
    .catch(() => dispatch(failVendorAccessSave()));
};

export const loadAvailableVendors = (vendorId: number, userId?: string) => (dispatch: Dispatch) => {
  dispatch(startVendorsLoad());
  const loadAvailableVendorsPromise = userId ? getUserAvailableVendors(vendorId, userId) : doLoadVendors();
  loadAvailableVendorsPromise
    .then(({ vendors, userId }) => dispatch(completeLoadVendors(vendors, userId)))
    .catch(() => dispatch(failLoadVendors()));
  return loadAvailableVendorsPromise;
};

export const loadAssignedVendors = (vendorId?: number, userId?: string) => (dispatch: Dispatch) => {
  dispatch(startAssignedVendorsLoad());
  const loadAssignedVendorsPromise = vendorId ? getUserAssignedVendors(vendorId, userId) : getAssignedVendors(userId);
  loadAssignedVendorsPromise
    .then(({ vendors, userId }) => dispatch(completeLoadAssignedVendors(vendors, userId)))
    .catch(() => dispatch(failLoadAssignedVendors()));
  return loadAssignedVendorsPromise;
};

// Selectors

const vendorAccessUserId = (state: any) => state.vendors.vendorAccess.vendorAccessUserId;

export const vendorAccessUserIdSelector = createSelector(vendorAccessUserId, identity);
