import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadFiltersSettings, loadWasteTypes } from 'src/common/ducks';
import { loadMaterialTrips } from 'src/routes/ducks';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { MaterialTicketsParams } from 'src/routes/interfaces/MaterialTickets';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import MaterialTripsSummaryPage from './MaterialTripsSummaryPage';
import translate from 'src/core/services/translate';

const MaterialTripsSummaryPageResolver: FC = () => {
  const dispatch = useDispatch();

  const { search, pathname } = useLocation();

  const userId = useSelector(currentUserId);
  const vendorId = useSelector(currentVendorId);

  const [summaryPageTitle, setSummaryPageTitle] = useState('');
  const [isSnowPlowRoute, setIsSnowPlowRoute] = useState(false);
  const [isLoadsDumped, setIsLoadsDumped] = useState(false);

  const {
    startDate,
    endDate,
    vehicleTypeIds,
    searchTerm,
    routeStatusTypeIds,
    supervisorsIds,
    serviceZoneIds,
    agingIntervalsIds,
    priorityTypeIds,
  } = getQueryParams<MaterialTicketsParams>(search);

  const loadDependencies = useCallback(() => {
    const params = {
      vendorId,
      startDate,
      endDate,
      vehicleTypeIds,
      searchTerm,
      routeStatusTypeIds,
      supervisorsIds,
      serviceZoneIds,
      agingIntervalsIds,
      priorityTypeIds,
    };

    const dependencies = [loadVehicleTypesForVendor(vendorId)(dispatch), loadWasteTypes()(dispatch)];

    switch (pathname) {
      case '/routes/material-pickups-summary':
        dependencies.push(loadMaterialTrips('materialPickupTickets', params)(dispatch));
        setSummaryPageTitle(translate('routes.materialTrips.materialPickupTickets'));
        setIsSnowPlowRoute(true);
        setIsLoadsDumped(false);
        break;

      case '/routes/disposals-summary':
        dependencies.push(loadMaterialTrips('disposalTickets', params)(dispatch));
        setSummaryPageTitle(translate('routes.materialTrips.loadsDumped'));
        setIsSnowPlowRoute(false);
        setIsLoadsDumped(true);
        break;

      case '/routes/water-fills-summary':
        dependencies.push(loadMaterialTrips('waterFillUpTickets', params)(dispatch));
        setSummaryPageTitle(translate('routes.materialTrips.waterFillUpTickets'));
        setIsSnowPlowRoute(false);
        setIsLoadsDumped(false);
        break;

      default:
        break;
    }

    if (userId) dependencies.push(loadFiltersSettings(vendorId, userId)(dispatch));

    return Promise.all(dependencies);
  }, [
    agingIntervalsIds,
    dispatch,
    endDate,
    pathname,
    priorityTypeIds,
    routeStatusTypeIds,
    searchTerm,
    serviceZoneIds,
    startDate,
    supervisorsIds,
    userId,
    vehicleTypeIds,
    vendorId,
  ]);

  return (
    <>
      <DocumentTitle>{summaryPageTitle}</DocumentTitle>

      <TypedResolver
        successComponent={MaterialTripsSummaryPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/dashboard"
        successProps={{ isLoadsDumped, isSnowPlowRoute, summaryPageTitle, vendorId }}
      />
    </>
  );
};

export default MaterialTripsSummaryPageResolver;
