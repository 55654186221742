import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Table } from '../../../core/components';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { getLastUrlSelector } from '../../../core/ducks';
import translate from '../../../core/services/translate';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  dailyDisposalSummarySelector,
  exportDailyDisposalSummary,
  loadDailyDisposalSummary,
  resetDailyDisposalSummary,
  totalDisposalCountSelector,
} from '../../ducks';
import { dashboardActiveCategorySelector } from '../../services/dashboardActiveCategorySelector';
import { DisposalSummaryForm } from '../forms';
import { DisposalSummaryPageTableRow } from '.';
import { WASTE_AUDIT_INSIGHTS } from '../../constants';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { AppState } from '../../../store';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { getAllFiltersPreferencesIds } from 'src/common/utils/filters';

interface Props extends RouteComponentProps {
  dailyDisposalSummary?: any[];
  exportDailyDisposalSummary: DuckFunction<typeof exportDailyDisposalSummary>;
  filtersPreferencesIds: FiltersPreferencesIds;
  isExporting: boolean;
  isLoading: boolean;
  loadDailyDisposalSummary: DuckFunction<typeof loadDailyDisposalSummary>;
  previousPageUrl?: string;
  push: any;
  resetDailyDisposalSummary: DuckAction<typeof resetDailyDisposalSummary>;
  totalDisposalCount?: number;
  vendorId: number;
}

class DisposalSummaryPage extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { filtersPreferencesIds, vendorId, location, loadDailyDisposalSummary } = this.props;

    if (location.search !== prevProps.location.search) {
      const {
        startDate,
        endDate,
        vehicleTypeId,
        searchString,
        ticketNumber,
        disposalSiteLocationId,
        sortedBy,
        sortOrder,
      } = getQueryParams(location.search);

      loadDailyDisposalSummary(
        vendorId,
        startDate,
        endDate,
        vehicleTypeId,
        searchString,
        ticketNumber,
        disposalSiteLocationId,
        sortedBy,
        sortOrder,
        filtersPreferencesIds,
      );
    }
  }

  componentWillUnmount() {
    this.props.resetDailyDisposalSummary();
  }

  onSortOrderChange = (sortedBy: string, sortOrder: string) => {
    const { location, push } = this.props;

    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  handleSubmit = (formData: any) => {
    const {
      dateRange: { from, to },
    } = formData;
    const { location, push } = this.props;
    const { sortOrder } = getQueryParams(location.search);

    push(createUrl(location.pathname, location.search, { startDate: from, endDate: to, sortOrder, ...formData }));
  };

  exportDailyDisposalSummary = () => {
    const { filtersPreferencesIds, vendorId, location, exportDailyDisposalSummary } = this.props;
    const {
      startDate,
      endDate,
      vehicleTypeId,
      searchString,
      ticketNumber,
      disposalSiteLocationId,
      sortedBy,
      sortOrder,
    } = getQueryParams(location.search);

    exportDailyDisposalSummary(
      vendorId,
      startDate,
      endDate,
      vehicleTypeId,
      searchString,
      ticketNumber,
      disposalSiteLocationId,
      sortedBy,
      sortOrder,
      filtersPreferencesIds,
    );
  };

  dailyDisposalSummaryTableCells = [
    {
      name: 'ticketNumber',
      label: translate('routes.ticketNumber'),
      width: '9%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'date',
      label: translate('common.date'),
      width: '10%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'routeName',
      label: translate('routes.routeName'),
      width: '10%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'routeType',
      label: translate('routes.routeType'),
      width: '10%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'vehicleName',
      label: translate('vehicles.vehicle'),
      width: '10%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'vehicleTypeName',
      label: translate('vehicles.vehicleType'),
      width: '11%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'disposalSite',
      label: translate('common.disposalSite'),
      width: '10%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'weight',
      label: translate('common.weight'),
      width: '8%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'unitofMeasure',
      label: translate('common.unitOfMeasurement'),
      width: '12%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
    {
      name: 'disposalTime',
      label: translate('common.disposalTime'),
      width: '10%',
      sortable: true,
      get id() {
        return `disposal-summary-header-${this.name}`;
      },
    },
  ];

  render() {
    const { isLoading, isExporting, dailyDisposalSummary, location, totalDisposalCount, previousPageUrl } = this.props;
    const { startDate, endDate } = getQueryParams(location.search);

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to={previousPageUrl || '/'}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle id="disposal-summary-title">{translate('insights.dailyDisposalSummary')}</PageTitle>
            </PageTitleContainer>
            <PageSubtitle id="disposal-summary-subtitle">{`${translate(
              'insights.reportDate',
            )}: ${startDate} - ${endDate}, ${translate(
              'insights.totalDisposals',
            )}: ${totalDisposalCount}`}</PageSubtitle>
          </PageDetails>
          <PageActions>
            <Button color="primary" onClick={this.exportDailyDisposalSummary}>
              {translate('common.export')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isExporting}>
            <DisposalSummaryForm onSubmit={this.handleSubmit} />
            <PanelSection>
              {!!size(dailyDisposalSummary) && (
                <Table
                  cells={this.dailyDisposalSummaryTableCells}
                  rows={dailyDisposalSummary}
                  rowComponent={DisposalSummaryPageTableRow}
                  sort={this.onSortOrderChange}
                />
              )}

              {!size(dailyDisposalSummary) && (
                <Message padding="sMedium">{translate('insights.noReportsFound')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const activeCategories = dashboardActiveCategorySelector(state.insights.insightDashboardPreferences) as any;

  return {
    dailyDisposalSummary: dailyDisposalSummarySelector(state.insights.dailyDisposalSummary.dailyDisposalSummary),
    filtersPreferencesIds: getAllFiltersPreferencesIds(state.common.filters.filters),
    isExporting: state.insights.dailyDisposalSummary.isExporting,
    isLoading: state.insights.dailyDisposalSummary.isLoading,
    previousPageUrl: activeCategories[WASTE_AUDIT_INSIGHTS]
      ? (getLastUrlSelector as any)(state.core, '/insights/wasteaudit')
      : (getLastUrlSelector as any)(state.core, '/insights/insightsfleet'),
    totalDisposalCount: totalDisposalCountSelector(state.insights.dailyDisposalSummary.dailyDisposalSummary),
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  };
};

const mapDispatchToProps = {
  exportDailyDisposalSummary,
  loadDailyDisposalSummary,
  resetDailyDisposalSummary,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DisposalSummaryPage));
