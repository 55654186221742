import { arrayMove } from 'react-sortable-hoc';
import { AxiosResponse } from 'axios';
import { camelCase, debounce, filter, find, findIndex, has, map, size, throttle, unionBy, uniqueId } from 'lodash-es';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { PureComponent, MouseEvent, ReactNode } from 'react';
import { Resizable } from 're-resizable';
import update from 'immutability-helper';

import {
  Button,
  MapContainer,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionLoading,
  Popover,
  Text,
} from '../../../../core/components/styled';
import { checkGeoFenceDuplicateName, checkRouteHasScheduledDailyRoutes } from '../../../services/routeTemplate';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import { deleteGeoFences } from '../../../ducks/geoFences';
import { DispatchBoardRouteJobLocationAddress } from '../../../interfaces/DispatchBoardRouteJob';
import { DuckFunction } from 'src/contracts/ducks';
import { FIRST_JOB_POSITION_ID, OPTIMIZED_JOB_POSITION_ID } from 'src/core/constants/jobPositionOptions';
import { getRouteSaveErrorMessage } from 'src/routes/utils/routeDetails';
import { GoogleMapLoading } from '../../styled';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { loadRouteGeoFence } from '../../../services/routeGeoFence';
import {
  MapDragHandle,
  PopoverWrapper,
  UnconnectedCheckbox,
  UpdateTrackerRouteSwitchWrapper,
} from '../../../../core/components';
import { multiWordAndSearch } from 'src/core/services/search';
import { OrderNumberForm } from '../../forms';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageFooter,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { Route } from '../../../interfaces/Route';
import { routeFormKeys, SCHEDULED, SELECTED_TRANSFERRED_STOPS } from '../../../constants';
import {
  routeLocationAddressChange,
  routeTemplateLocationAddressChange,
  saveRoute,
  saveRouteTemplate,
} from 'src/routes/ducks';
import { RouteGeoFenceMultiPolygon } from '../../../interfaces/RouteGeoFence';
import { RouteLocation } from '../../../interfaces/RouteLocation';
import { RouteLocationsLoader } from '../../RouteLocationsLoader';
import { RouteTemplate } from '../../../interfaces/RouteTemplates';
import { saveRouteGeoFence } from '../../../ducks/routeGeoFence';
import { SelectedLocation } from '../../RouteMap';
import { SERVICE_DETAILS_EDITOR_FORM } from 'src/customers/components/forms/ServiceDetailsEditorForm';
import { SPECIFIC_DATE_RANGE, DESCRIPTIVE_DATE_RANGE } from 'src/core/constants/weekdays';
import { TOP } from '../../../../core/constants';
import confirm from '../../../../core/services/confirm';
import createPopover from '../../../../core/services/createPopover';
import createTranslationKey from '../../../../utils/services/createTranslationKey';
import moment from 'moment';
import ProgressPopup from '../../../../common/components/ProgressPopup';
import RouteEntityLocationSearchForm from '../../forms/RouteEntityLocationSearchForm';
import RouteMapGL from './routeMapGL/RouteMapGL';
import translate from '../../../../core/services/translate';

interface CheckAllRouteEntityLocationsCheckboxProps {
  checkAll: () => void;
  getChecked: () => boolean;
}

const CheckAllRouteEntityLocationsCheckbox = ({ checkAll, getChecked }: CheckAllRouteEntityLocationsCheckboxProps) => (
  <UnconnectedCheckbox block checked={getChecked()} onChange={() => checkAll()} size="small" />
);

export const filterRouteEntityLocations = (
  routeLocations: RouteEntityLocation[] = [],
  searchTerm?: string,
): RouteEntityLocation[] => {
  const filteredLocations = filter(
    routeLocations,
    routeLocation =>
      !searchTerm ||
      routeLocation.isChecked ||
      multiWordAndSearch(routeLocation.customer.name, searchTerm) ||
      multiWordAndSearch(routeLocation.location.name, searchTerm) ||
      multiWordAndSearch(routeLocation.location.address.line1, searchTerm) ||
      multiWordAndSearch(routeLocation.customer.accountNumber, searchTerm) ||
      multiWordAndSearch(routeLocation.location.vendorAccountNo, searchTerm),
  );
  return filteredLocations || [];
};

export type RouteEntityLocation = RouteLocation & { isChecked: boolean; stopId?: string };

export type TransferDispatch = (
  values: any,
) => Promise<{ totalStops: number; transferredStops: number; notTransfferedStopIds: number[] }>;

interface Props {
  additionalRouteEntityLocationsTableCells: Array<{ name: string; label: string; width: string }>;
  batchTransfererInProgress: boolean;
  batchTransfererProgress: number;
  change: typeof change;
  children: ReactNode;
  deleteGeoFences: (vendorId: number, geoFencesIds: string) => Promise<AxiosResponse<any>>;
  displayRouteEntityUrl: string;
  formKey: string;
  formsToResetOnLocationAdd: string[];
  isLoading: boolean;
  isLoadingLocations?: boolean;
  isRouteEntityFormPristine: boolean;
  isRouteEntityFormValid: boolean;
  isSaving: boolean;
  isServiceFormPristine?: boolean;
  locationsAccessorKey: 'routeLocations' | 'locations';
  locationsInfo?: {
    isLoading: boolean;
    limit: number;
    loadedPages: number[];
    total: number;
  };
  mapEditButtonId?: string;
  push: (path: string) => void;
  renderAddRouteEntityLocationForm: (onSubmit: (location: RouteEntityLocation) => void, p: any) => ReactNode;
  renderBackButton: () => void;
  renderEditorForm: (
    routeEntityLocations: RouteEntityLocation[],
    transferredStops: boolean,
    onRouteEntityEditorFormSubmit: (p: any) => void,
  ) => ReactNode;
  renderLocationsTable: (p: any) => void;
  renderTransferRouteEntityLocationsModal: (
    closeTransferLocationsModal: () => void,
    transferLocations: (
      transferDispatch: TransferDispatch,
      getStopId: (routeLocation: RouteLocation) => number,
      params: any,
    ) => void,
  ) => void;
  resetDisposalSiteLocations: () => void;
  resetForm: (formName: string) => void;
  resetRoutesForReassignment: () => void;
  resetVehicleTypesForVendor: () => void;
  routeEntity?: Route;
  routeEntityId: number | string;
  routeEntityLocationAddressChange: DuckFunction<
    typeof routeTemplateLocationAddressChange | typeof routeLocationAddressChange
  >;
  routeEntityLocationIdAccessor: 'id' | 'serviceContractRouteTemplateId' | 'stopId';
  routeEntityLocations?: RouteLocation[];
  routeEntityPayloadIdAccessor?: 'routeLocationId';
  routeEntitySavedTranslationKey: string;
  saveRouteEntity: DuckFunction<typeof saveRouteTemplate | typeof saveRoute>;
  saveRouteGeoFence: (routeId: number, geoFence: RouteGeoFenceMultiPolygon, isTemplate?: boolean) => Promise<boolean>;
  shouldRecreateRoutes?: boolean;
  submit: (formKey: string) => void;
  vendorId: number;
}

interface State {
  allRouteEntityLocationsChecked: boolean;
  cachedLocationIds: number[];
  cachedServiceContractIds: number[];
  deletedLocationIds: number[];
  deletedServiceContractIds: number[];
  filteredRouteEntityLocations: RouteEntityLocation[];
  hasScheduledDailyRoutes: boolean;
  isGeoFenceSaving: boolean;
  isMapLoading: boolean;
  isRouteSaving: boolean;
  isSearchInitialized: boolean;
  isTransferLocationsModalOpen: boolean;
  mapShouldFitBounds?: boolean;
  routeEntityLocations: RouteEntityLocation[];
  saveButtonPending?: boolean;
  saveStops: boolean;
  searchTerm?: string;
  selectedCustomerId?: number;
  selectedLocation?: SelectedLocation;
  selectedRouteLocationId?: number;
  selectedService?: number;
  transferredStops: boolean;
}

const transformRouteLocation = (routeLocation: RouteLocation[] = []) => {
  let routeEntityLocations: RouteEntityLocation[] = [];
  routeLocation.forEach(location => {
    routeEntityLocations.push({ ...location, isChecked: false, stopId: uniqueId() });
  });
  return routeEntityLocations;
};

class BaseRouteEntityEditorPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    let routeEntityLocations: RouteEntityLocation[] = [];
    let cachedLocationIds: number[] = [];
    let cachedServiceContractIds: number[] = [];

    (props.routeEntityLocations || []).forEach(location => {
      if (props.routeEntityId) routeEntityLocations.push({ ...location, isChecked: false, stopId: uniqueId() });

      cachedLocationIds.push(location.id);
      if (location.serviceContractRouteTemplateId) {
        cachedServiceContractIds.push(location.serviceContractRouteTemplateId);
      }
    });

    this.state = {
      allRouteEntityLocationsChecked: false,
      filteredRouteEntityLocations: routeEntityLocations,
      isMapLoading: false,
      isSearchInitialized: false,
      isTransferLocationsModalOpen: false,
      mapShouldFitBounds: undefined,
      routeEntityLocations,
      saveStops: false,
      searchTerm: undefined,
      selectedCustomerId: undefined,
      selectedRouteLocationId: undefined,
      selectedService: undefined,
      transferredStops: false,
      cachedLocationIds,
      cachedServiceContractIds,
      deletedLocationIds: [],
      deletedServiceContractIds: [],
      hasScheduledDailyRoutes: false,
      isRouteSaving: false,
      isGeoFenceSaving: false,
    };
  }

  static defaultProps = {
    isLoadingLocations: false,
  };

  componentDidMount() {
    const { routeEntity, vendorId } = this.props;

    if (this.isRouteTemplate && routeEntity && routeEntity.id) {
      checkRouteHasScheduledDailyRoutes(routeEntity.id, vendorId).then(response => {
        this.setState({ hasScheduledDailyRoutes: response });
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { routeEntityLocations } = this.props;
    const { searchTerm } = this.state;

    if (routeEntityLocations && prevProps.routeEntityLocations !== routeEntityLocations) {
      let routeLocations;

      if (has(routeEntityLocations, 'id')) {
        routeLocations = unionBy(this.state.routeEntityLocations, transformRouteLocation(routeEntityLocations), 'id');
      } else {
        routeLocations = transformRouteLocation(routeEntityLocations);
      }

      let cachedLocationIds: number[] = [];
      let cachedServiceContractIds: number[] = [];

      routeEntityLocations.forEach(location => {
        cachedLocationIds.push(location.id);
        if (location.serviceContractRouteTemplateId) {
          cachedServiceContractIds.push(location.serviceContractRouteTemplateId);
        }
      });

      if (searchTerm) {
        this.setState(
          {
            cachedLocationIds,
            cachedServiceContractIds,
            routeEntityLocations: routeLocations,
          },
          () => this.onSearchTermChange(searchTerm),
        );
      } else {
        this.setState({
          cachedLocationIds,
          cachedServiceContractIds,
          filteredRouteEntityLocations: routeLocations,
          routeEntityLocations: routeLocations,
        });
      }
    }
  }

  componentWillUnmount() {
    const { resetVehicleTypesForVendor, resetRoutesForReassignment, resetDisposalSiteLocations } = this.props;

    resetDisposalSiteLocations();
    resetRoutesForReassignment();
    resetVehicleTypesForVendor();
  }

  onCancelSaveService = async () => {
    const { isServiceFormPristine, resetForm } = this.props;
    if (!isServiceFormPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    resetForm(SERVICE_DETAILS_EDITOR_FORM);
  };

  onRouteEntityLocationSelected = (locationId: number, orderNumber: number, stopId?: string) => {
    const { routeEntityLocations, searchTerm } = this.state;

    const isOptimized = orderNumber === JOB_PENDING_OPTIMIZATION_ID;
    const optimizedRouteEntityLocations = routeEntityLocations.filter(
      routeLocation => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
    );
    const nonOptimizedRouteEntityLocations = routeEntityLocations.filter(
      routeLocation => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
    );

    const routeEntityLocationIndex = this.getLocationIndex(
      isOptimized ? optimizedRouteEntityLocations : nonOptimizedRouteEntityLocations,
      locationId,
      stopId,
    );

    const allRouteEntityLocationsUpdated = [
      ...(isOptimized
        ? update(optimizedRouteEntityLocations, {
            [routeEntityLocationIndex]: {
              isChecked: { $set: !optimizedRouteEntityLocations[routeEntityLocationIndex]?.isChecked },
            },
          })
        : optimizedRouteEntityLocations),
      ...(!isOptimized
        ? update(nonOptimizedRouteEntityLocations, {
            [routeEntityLocationIndex]: {
              isChecked: { $set: !nonOptimizedRouteEntityLocations[routeEntityLocationIndex]?.isChecked },
            },
          })
        : nonOptimizedRouteEntityLocations),
    ];

    const filteredRouteEntityLocations = filterRouteEntityLocations(allRouteEntityLocationsUpdated, searchTerm);

    const allRouteEntityLocationsChecked =
      filteredRouteEntityLocations.filter(location => location.isChecked).length ===
      filteredRouteEntityLocations.length;

    this.setState({
      allRouteEntityLocationsChecked,
      filteredRouteEntityLocations,
      mapShouldFitBounds: false,
      routeEntityLocations: allRouteEntityLocationsUpdated,
    });
  };

  onCheckLocationsSelected = (selectedLocations: RouteEntityLocation[]) => {
    this.setState(prevState => {
      const filteredRouteEntityLocations = map(prevState.filteredRouteEntityLocations, routeEntityLocation => ({
        ...routeEntityLocation,
        isChecked: !!find(selectedLocations, {
          [this.props.routeEntityLocationIdAccessor]: routeEntityLocation[this.props.routeEntityLocationIdAccessor],
        }),
      }));

      const filteredRouteEntityLocationsOrderNumbers = map(
        filteredRouteEntityLocations,
        ({ orderNumber }) => orderNumber,
      );

      const routeEntityLocations = map(prevState.routeEntityLocations, routeEntityLocation =>
        filteredRouteEntityLocationsOrderNumbers.includes(routeEntityLocation.orderNumber)
          ? {
              ...routeEntityLocation,
              isChecked: !!find(selectedLocations, {
                [this.props.routeEntityLocationIdAccessor]:
                  routeEntityLocation[this.props.routeEntityLocationIdAccessor],
              }),
            }
          : routeEntityLocation,
      );

      const allRouteEntityLocationsChecked = size(find(filteredRouteEntityLocations, { isChecked: false })) === 0;

      return {
        allRouteEntityLocationsChecked,
        filteredRouteEntityLocations,
        mapShouldFitBounds: false,
        routeEntityLocations,
      };
    });
  };

  onSearchTermChange = (searchTerm: string) => {
    const { routeEntityLocations } = this.state;
    const filteredRouteEntityLocations = filterRouteEntityLocations(routeEntityLocations, searchTerm);

    const allRouteEntityLocationsChecked =
      filteredRouteEntityLocations.filter(location => location.isChecked === true).length ===
      filteredRouteEntityLocations.length;

    this.setState(prevState => ({
      ...prevState,
      allRouteEntityLocationsChecked,
      filteredRouteEntityLocations,
      isMapLoading: true,
      isSearchInitialized: size(routeEntityLocations) !== size(filteredRouteEntityLocations),
      mapShouldFitBounds: true,
      searchTerm,
    }));
  };

  onSortOrderChange = (oldIndex: number, newIndex: number) => {
    const { routeEntityLocations, searchTerm } = this.state;

    const routeLocationsWithStopNumber = routeEntityLocations.filter(routeLocation => routeLocation.orderNumber > 0);
    const routeLocationsWithZeroStopNumber = routeEntityLocations.filter(
      routeLocation => routeLocation.orderNumber <= 0,
    );

    const reorderedRouteEntityLocations = arrayMove(routeLocationsWithStopNumber, oldIndex, newIndex);
    const routeEntityLocationsReset = this.resetOrderNumbers(reorderedRouteEntityLocations);

    const filteredRouteEntityLocationsWithStopNumber = filterRouteEntityLocations(
      routeEntityLocationsReset,
      searchTerm,
    );
    const filteredRouteEntityLocationsWithZeroStopNumber = filterRouteEntityLocations(
      routeLocationsWithZeroStopNumber,
      searchTerm,
    );

    this.setState({
      filteredRouteEntityLocations: [
        ...filteredRouteEntityLocationsWithStopNumber,
        ...filteredRouteEntityLocationsWithZeroStopNumber,
      ],
      mapShouldFitBounds: false,
      routeEntityLocations: [...routeEntityLocationsReset, ...routeLocationsWithZeroStopNumber],
      saveStops: true,
    });

    const {
      customer: { name: customerName },
      id,
      location: {
        name: locationName,
        address: { latitude, longitude },
      },
      orderNumber,
    } = routeEntityLocations[newIndex];
    const selectedLocation = { id, customerName, locationName, orderNumber, latitude, longitude };
    this.selectLocation(selectedLocation);
  };

  onRouteEntityEditorFormSubmit = async ({ geoFence, ...routeEntityEditorFormData }: any) => {
    this.setState({
      isRouteSaving: true,
    });

    const { locationsAccessorKey, routeEntity, shouldRecreateRoutes, vendorId } = this.props;

    const { deletedLocationIds, deletedServiceContractIds, routeEntityLocations, saveStops } = this.state;
    const locationsWithZeroStopNumber = routeEntityLocations.filter(location => location.orderNumber === 0).length;

    const optimized = true;

    let currentRouteEntity = {
      ...routeEntity,
      ...routeEntityEditorFormData,
      saveStops,
      vendorId,
      startTime: routeEntityEditorFormData.startTime
        ? moment(routeEntityEditorFormData.startTime, 'hh:mm a').format('HH:mm')
        : null,
      endTime: routeEntityEditorFormData.endTime
        ? moment(routeEntityEditorFormData.endTime, 'hh:mm a').format('HH:mm')
        : null,
      [locationsAccessorKey]: routeEntityLocations,
      groups: map(routeEntityEditorFormData.groups, (group: any) => ({
        id: group,
      })),
    };

    if (this.isRoute) {
      currentRouteEntity = {
        ...currentRouteEntity,
        deletedRouteLocationIds: deletedLocationIds,
      };
    }

    if (this.isRouteTemplate) {
      delete currentRouteEntity.routeLocations;

      const getSeasonalityData = (isNull?: boolean) => {
        return {
          endDay: isNull ? null : currentRouteEntity.endDay,
          endMonth: isNull ? null : currentRouteEntity.endMonth,
          endDayOfWeek: isNull ? null : currentRouteEntity.endDayOfWeek,
          endRecurrenceDayTypeId: isNull ? null : currentRouteEntity.endRecurrenceDayTypeId,
          startDay: isNull ? null : currentRouteEntity.startDay,
          startMonth: isNull ? null : currentRouteEntity.startMonth,
          startDayOfWeek: isNull ? null : currentRouteEntity.startDayOfWeek,
          startRecurrenceDayTypeId: isNull ? null : currentRouteEntity.startRecurrenceDayTypeId,
        };
      };

      const seasonalityNullData = {
        ...getSeasonalityData(true),
      };

      const seasonalitySpecificDateRangeData = {
        ...getSeasonalityData(),
        endDayOfWeek: null,
        endRecurrenceDayTypeId: null,
        startDayOfWeek: null,
        startRecurrenceDayTypeId: null,
      };

      const seasonalityDescriptiveDateRangeData = {
        ...getSeasonalityData(),
        endDay: null,
        startDay: null,
      };

      const seasonality = !currentRouteEntity.seasonality
        ? seasonalityNullData
        : currentRouteEntity.dateRangeType === SPECIFIC_DATE_RANGE
        ? seasonalitySpecificDateRangeData
        : currentRouteEntity.dateRangeType === DESCRIPTIVE_DATE_RANGE
        ? seasonalityDescriptiveDateRangeData
        : {};

      currentRouteEntity = {
        ...currentRouteEntity,
        ...seasonality,
        deletedServiceContractRouteTemplateIds: deletedServiceContractIds,
      };
    }

    if (locationsWithZeroStopNumber > 0) {
      createErrorNotification(translate('routes.alertMessages.routeSaveErrorWithStopNumberZero'));
    } else {
      let preventSaveGeoFence;
      if (geoFence.geoFenceCoordinates.length && this.isRouteTemplate) {
        checkGeoFenceDuplicateName(currentRouteEntity.routeTemplateName, vendorId, currentRouteEntity.id).then(
          async response => {
            if (!response.isValidGeoFenceName) {
              if (
                !(await confirm(
                  translate('routes.geoFences.alertMessages.geoFenceDuplicateNameTitle'),
                  translate('routes.geoFences.alertMessages.geoFenceDuplicateNameSubtitle'),
                  translate('common.cancel'),
                  translate('common.ok'),
                ))
              ) {
                this.setState({
                  isRouteSaving: false,
                  isGeoFenceSaving: false,
                });

                return;
              }
            }

            preventSaveGeoFence = !response.isValidGeoFenceName;
            this.onSaveRouteEntity(
              currentRouteEntity,
              optimized,
              shouldRecreateRoutes || false,
              geoFence,
              preventSaveGeoFence,
            );
          },
        );
      } else {
        preventSaveGeoFence = false;
        this.onSaveRouteEntity(
          currentRouteEntity,
          optimized,
          shouldRecreateRoutes || false,
          geoFence,
          preventSaveGeoFence,
        );
      }
    }
  };

  onSaveRouteEntity = (
    currentRouteEntity: Route | RouteTemplate,
    optimized: boolean,
    shouldRecreateRoutes: boolean,
    geoFence: RouteGeoFenceMultiPolygon,
    preventSaveGeoFence: boolean,
  ) => {
    const {
      displayRouteEntityUrl,
      push,
      routeEntitySavedTranslationKey,
      saveRouteEntity,
      vendorId,
      saveRouteGeoFence,
      deleteGeoFences,
    } = this.props;

    saveRouteEntity(currentRouteEntity as any, optimized, shouldRecreateRoutes)
      .then((response: Route) => {
        this.setState({
          isGeoFenceSaving: true,
        });

        const id = currentRouteEntity.id || (response && response.id);

        const saveSuccessfulAndRedirect = () => {
          push(`${displayRouteEntityUrl}${id}`);
          createSuccessNotification(translate(routeEntitySavedTranslationKey));

          this.setState({
            isGeoFenceSaving: false,
          });
        };

        const deleteEmptyGeoFence = () => {
          deleteGeoFences(vendorId, geoFence.id.toString()).finally(() => {
            saveSuccessfulAndRedirect();
          });
        };

        if (!geoFence.geoFenceCoordinates.length) {
          if (!geoFence.id) {
            saveSuccessfulAndRedirect();
          } else {
            deleteEmptyGeoFence();
          }
          return;
        }

        const saveGeoFence = (gF: RouteGeoFenceMultiPolygon) => {
          saveRouteGeoFence(
            id,
            {
              ...gF,
              vendorId,
              routeName: currentRouteEntity.routeName || currentRouteEntity.routeTemplateName,
              startingLocationId: currentRouteEntity.startingLocationId,
              endingLocationId: currentRouteEntity.endingLocationId,
            },
            this.isRouteTemplate,
          ).finally(() => {
            saveSuccessfulAndRedirect();
          });
        };
        if (preventSaveGeoFence) {
          saveSuccessfulAndRedirect();
        } else {
          if (currentRouteEntity.id) {
            saveGeoFence(geoFence);
          } else {
            loadRouteGeoFence(id, this.isRouteTemplate).then(newGeoFence => {
              saveGeoFence({
                ...newGeoFence,
                geoFenceCoordinates: geoFence.geoFenceCoordinates,
              });
            });
          }
        }
      })
      .catch(error => {
        const { code } = error?.response?.data;
        getRouteSaveErrorMessage(code, currentRouteEntity.routeDate);
      })
      .finally(() => {
        this.setState({
          isRouteSaving: false,
        });
      });
  };

  closePopover = () => {};

  onOrderNumberFormSubmit = (oldOrderNumber: number, newOrderNumber: number, locationId: number) => {
    if (oldOrderNumber === 0) {
      this.onSortOrderChangeLocationWithZeroStopNumber(oldOrderNumber - 1, newOrderNumber - 1, locationId);
    } else {
      this.onSortOrderChange(oldOrderNumber - 1, newOrderNumber - 1);
    }
    this.closePopover();
  };

  onSortOrderChangeLocationWithZeroStopNumber = (oldIndex: number, newIndex: number, locationId: number) => {
    const { routeEntityLocations, searchTerm } = this.state;

    const routeLocationsWithStopNumber = routeEntityLocations.filter(routeLocation => routeLocation.orderNumber > 0);
    const routeLocationCurrent = map(
      routeEntityLocations.filter(routeLocation =>
        has(routeLocation, 'serviceContractRouteTemplateId')
          ? routeLocation.serviceContractRouteTemplateId === locationId
          : routeLocation.id === locationId,
      ),
      routeLocation => ({
        ...routeLocation,
        orderNumber: newIndex + 1,
      }),
    );

    const routeLocationsWithZeroStopNumber = routeEntityLocations.filter(
      routeLocation =>
        routeLocation.orderNumber <= 0 &&
        (has(routeLocation, 'serviceContractRouteTemplateId')
          ? routeLocation.serviceContractRouteTemplateId !== locationId
          : routeLocation.id !== locationId),
    );

    if (routeLocationCurrent[0]) routeLocationsWithStopNumber.splice(newIndex, 0, routeLocationCurrent[0]);
    const routeLocationsWithStopNumberReordered = this.resetOrderNumbers(
      routeLocationsWithStopNumber,
      routeLocationCurrent[0].orderNumber,
      has(routeLocationCurrent[0], 'serviceContractRouteTemplateId')
        ? routeLocationCurrent[0].serviceContractRouteTemplateId
        : routeLocationCurrent[0].id,
    );

    const filteredRouteLocationsWithZeroStopNumber = filterRouteEntityLocations(
      routeLocationsWithZeroStopNumber,
      searchTerm,
    );

    const filteredRouteLocationsWithStopNumberReordered = filterRouteEntityLocations(
      routeLocationsWithStopNumberReordered,
      searchTerm,
    );

    this.setState({
      filteredRouteEntityLocations: [
        ...filteredRouteLocationsWithZeroStopNumber,
        ...filteredRouteLocationsWithStopNumberReordered,
      ],
      routeEntityLocations: [...routeLocationsWithZeroStopNumber, ...routeLocationsWithStopNumberReordered],
      saveStops: true,
    });
  };

  onAddressChange = debounce((index: number, serviceContractId: number, updatedAddress: google.maps.MapMouseEvent) => {
    const serviceContractIndex = findIndex(
      this.state.filteredRouteEntityLocations[index].service.serviceContractBinDetails,
      {
        id: serviceContractId,
      },
    );

    const routeLocationsIndex = this.state.routeEntityLocations.findIndex(
      l =>
        l[this.props.routeEntityLocationIdAccessor] ===
        this.state.filteredRouteEntityLocations[index][this.props.routeEntityLocationIdAccessor],
    );

    if (updatedAddress.latLng) {
      const updatedAddressLatitude = updatedAddress.latLng.lat();
      const updatedAddressLongitude = updatedAddress.latLng.lng();

      const routeEntityLocations = update(this.state.routeEntityLocations, {
        [routeLocationsIndex]: {
          service: {
            serviceContractBinDetails: {
              [serviceContractIndex]: {
                binLatitude: { $set: updatedAddressLatitude },
                binLongitude: { $set: updatedAddressLongitude },
                displayLatitude: { $set: updatedAddressLatitude },
                displayLongitude: { $set: updatedAddressLongitude },
              },
            },
          },
        },
      });

      const filteredRouteEntityLocations = update(this.state.filteredRouteEntityLocations, {
        [index]: {
          service: {
            serviceContractBinDetails: {
              [serviceContractIndex]: {
                binLatitude: { $set: updatedAddressLatitude },
                binLongitude: { $set: updatedAddressLongitude },
                displayLatitude: { $set: updatedAddressLatitude },
                displayLongitude: { $set: updatedAddressLongitude },
              },
            },
          },
        },
      });

      this.routeEntityLocationAddressChange({
        [this.props.routeEntityPayloadIdAccessor || this.props.routeEntityLocationIdAccessor]:
          routeEntityLocations[routeLocationsIndex][this.props.routeEntityLocationIdAccessor],
        latitude: updatedAddressLatitude,
        longitude: updatedAddressLongitude,
        displayLatitude: updatedAddressLatitude,
        displayLongitude: updatedAddressLongitude,
      });

      this.setState({
        filteredRouteEntityLocations,
        mapShouldFitBounds: false,
        routeEntityLocations,
      });
    }
  }, 800);

  onMapLoadComplete = debounce(() => {
    this.setState({ isMapLoading: false });
  }, 200);

  onRouteLocationDrop = (event: any, insertRouteLocationAtIndex: number) => {
    const { dataTransfer } = event.nativeEvent;
    const routeLocationId = dataTransfer.getData('routeLocationId')
      ? Number(dataTransfer.getData('routeLocationId'))
      : -1;

    this.onSortOrderChangeLocationWithZeroStopNumber(0, insertRouteLocationAtIndex, routeLocationId);
  };

  get isRoute() {
    return this.props.formKey === routeFormKeys.route;
  }

  get isRouteTemplate() {
    return this.props.formKey === routeFormKeys.routeTemplate;
  }

  getRouteLocationsTableCells = (newOptimizedRouteLocationsLength: number) => [
    {
      name: 'selectAll',
      component: !newOptimizedRouteLocationsLength ? CheckAllRouteEntityLocationsCheckbox : undefined,
      componentProps: {
        checkAll: this.checkAllLocations,
        getChecked: () => this.state.allRouteEntityLocationsChecked,
      },
      width: '5%',
    },
    { name: 'dragHandle', width: '5%' },
    { name: 'stopNumber', label: translate('routes.stopNumber'), width: '8%' },
    ...this.props.additionalRouteEntityLocationsTableCells,
  ];

  getLocationIndex = (routeEntityLocations: RouteEntityLocation[], locationId: number, stopId?: string) => {
    return routeEntityLocations.findIndex(routeEntityLocation =>
      locationId
        ? has(routeEntityLocation, 'serviceContractRouteTemplateId')
          ? routeEntityLocation.serviceContractRouteTemplateId === locationId
          : routeEntityLocation.id === locationId
        : routeEntityLocation.stopId === stopId,
    );
  };

  openOrderNumberPopover = (oldOrderNumber: number, event: MouseEvent<HTMLElement>, locationId: number) => {
    event.stopPropagation();

    const { routeEntityLocations } = this.state;
    const routeLocationsWithStopNumberLength = routeEntityLocations.filter(location => location.orderNumber > 0).length;

    this.closePopover = createPopover(
      event.currentTarget,
      OrderNumberForm,
      {
        onSubmit: ({ orderNumber }: { orderNumber: number }) =>
          this.onOrderNumberFormSubmit(oldOrderNumber, orderNumber, locationId),
        maxOrderNumber:
          oldOrderNumber === 0 ? routeLocationsWithStopNumberLength + 1 : routeLocationsWithStopNumberLength,
        initialValues: { orderNumber: oldOrderNumber },
      },
      { position: TOP },
    );
  };

  deleteRouteEntityLocation = (
    event: MouseEvent<HTMLElement>,
    orderNumber: number,
    locationId: number,
    stopId?: string,
  ) => {
    event.stopPropagation();

    const { routeEntityLocations } = this.state;
    const routeEntityLocationsAfterDelete = routeEntityLocations.map(routeLocation => {
      return {
        ...routeLocation,
        isChecked:
          routeLocation.orderNumber === orderNumber && Number(routeLocation.stopId) === Number(stopId)
            ? true
            : routeLocation.isChecked || false,
      };
    });

    this.setState(
      {
        routeEntityLocations: routeEntityLocationsAfterDelete,
      },
      () => {
        this.deleteRouteEntityLocations();
      },
    );
  };

  deleteRouteEntityLocations = () => {
    const {
      cachedLocationIds,
      cachedServiceContractIds,
      deletedLocationIds,
      deletedServiceContractIds,
      routeEntityLocations,
      searchTerm,
    } = this.state;

    const routeLocationsWithStopNumber = routeEntityLocations.filter(routeLocation => routeLocation.orderNumber > 0);
    const routeLocationsWithZeroStopNumber = routeEntityLocations.filter(
      routeLocation => routeLocation.orderNumber === 0,
    );
    const optimizedRouteLocations = routeEntityLocations.filter(
      routeLocation => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
    );

    const routeEntityLocationsAfterDelete = filter(routeLocationsWithStopNumber, ({ isChecked }) => !isChecked);
    const routeLocationsWithZeroStopNumberAfterDelete = filter(
      routeLocationsWithZeroStopNumber,
      ({ isChecked }) => !isChecked,
    );
    const optimizedRouteLocationsAfterDelete = filter(optimizedRouteLocations, ({ isChecked }) => !isChecked);

    const routeEntityLocationsReset = this.resetOrderNumbers(routeEntityLocationsAfterDelete);
    const routeEntityLocationsResetWithZeroStopNumber = this.resetOrderNumbers(
      routeLocationsWithZeroStopNumberAfterDelete,
    );

    const filteredRouteEntityLocationsWithStopNumber = filterRouteEntityLocations(
      routeEntityLocationsReset,
      searchTerm,
    );
    const filteredRouteEntityLocationsWithZeroStopNumber = filterRouteEntityLocations(
      routeEntityLocationsResetWithZeroStopNumber,
      searchTerm,
    );
    const filteredOptimizedRouteLocations = filterRouteEntityLocations(optimizedRouteLocationsAfterDelete, searchTerm);

    const newDeletedLocationIds = deletedLocationIds.slice();
    const newDeletedServiceContractIds: number[] = deletedServiceContractIds.slice() || [];
    const locationsThatWillBeDeleted: RouteEntityLocation[] =
      routeEntityLocations.filter(({ isChecked }) => isChecked) || [];

    locationsThatWillBeDeleted.forEach((location: RouteEntityLocation) => {
      if (this.isRoute && cachedLocationIds.indexOf(location.id) !== -1) {
        newDeletedLocationIds.push(location.id);
      }

      if (
        this.isRouteTemplate &&
        location.serviceContractRouteTemplateId &&
        cachedServiceContractIds.indexOf(location.serviceContractRouteTemplateId) !== -1
      ) {
        newDeletedServiceContractIds.push(location.serviceContractRouteTemplateId);
      }
    });

    this.setState({
      filteredRouteEntityLocations: [
        ...filteredOptimizedRouteLocations,
        ...filteredRouteEntityLocationsWithZeroStopNumber,
        ...filteredRouteEntityLocationsWithStopNumber,
      ],
      isMapLoading: true,
      mapShouldFitBounds: true,
      routeEntityLocations: [
        ...optimizedRouteLocationsAfterDelete,
        ...routeLocationsWithZeroStopNumberAfterDelete,
        ...routeEntityLocationsReset,
      ],
      saveStops: true,
      deletedLocationIds: newDeletedLocationIds,
      deletedServiceContractIds: newDeletedServiceContractIds,
    });

    const selectedRouteEntityLocations = filter(routeEntityLocations, ({ isChecked }) => isChecked);
    const deletedStops = size(selectedRouteEntityLocations);
    const deleteStopsSuccessMessage =
      deletedStops === 1 ? 'routes.alertMessages.routeLocationDeleted' : 'routes.alertMessages.routeLocationsDeleted';

    createSuccessNotification(translate(deleteStopsSuccessMessage, { deletedStops }));
  };

  resetOrderNumbers = (
    routeEntityLocations: RouteEntityLocation[],
    droppedLocationOrderNumber: number = -1,
    droppedLocationId?: number,
    isDelete?: boolean,
  ) => {
    const getNewOrderNumber = (routeEntityLocation: RouteEntityLocation, index: number) =>
      droppedLocationOrderNumber >= 0
        ? routeEntityLocation.orderNumber >= droppedLocationOrderNumber &&
          ((has(routeEntityLocation, 'serviceContractRouteTemplateId')
            ? routeEntityLocation.serviceContractRouteTemplateId
            : routeEntityLocation.id) !== droppedLocationId ||
            !droppedLocationId) &&
          droppedLocationOrderNumber !== 0
          ? routeEntityLocation.orderNumber + (isDelete ? -1 : 1)
          : routeEntityLocation.orderNumber
        : index + 1;

    const optimalRouteStopLength =
      routeEntityLocations?.filter(stop => stop.orderNumber === JOB_PENDING_OPTIMIZATION_ID).length || 0;

    return map(routeEntityLocations, (routeEntityLocation, index) => ({
      ...routeEntityLocation,
      orderNumber:
        droppedLocationOrderNumber === JOB_PENDING_OPTIMIZATION_ID &&
        routeEntityLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID
          ? JOB_PENDING_OPTIMIZATION_ID
          : droppedLocationOrderNumber === JOB_PENDING_OPTIMIZATION_ID && optimalRouteStopLength
          ? routeEntityLocation.orderNumber
          : getNewOrderNumber(routeEntityLocation, index),
    }));
  };

  resetOrderNumbersAndChecks = (routeEntityLocations: RouteEntityLocation[]) =>
    map(routeEntityLocations, (routeEntityLocation, index) => ({
      ...routeEntityLocation,
      isChecked: false,
      orderNumber: index + 1,
    }));

  openTransferLocationsModal = () => {
    const { isRouteEntityFormValid, submit } = this.props;
    if (!isRouteEntityFormValid) {
      submit(this.props.formKey);
      return;
    }
    this.setState({ isTransferLocationsModalOpen: true });
  };

  closeTransferLocationsModal = () => {
    this.setState({ isTransferLocationsModalOpen: false });
  };

  routeEntityLocationAddressChange = async (
    routeEntityLocationAddress: Partial<DispatchBoardRouteJobLocationAddress>,
  ) => this.props.routeEntityLocationAddressChange(routeEntityLocationAddress);

  selectLocation = (selectedLocation: SelectedLocation, event?: any) => {
    const isTableRowClicked = event ? camelCase(event.target.parentNode.tagName) !== 'label' : false;

    this.setState({
      mapShouldFitBounds: isTableRowClicked,
      selectedLocation,
    });
  };

  saveChanges = throttle(() => {
    const { submit, formKey } = this.props;
    const { routeEntityLocations } = this.state;

    const locationsWithZeroStopNumber = routeEntityLocations.filter(location => location.orderNumber === 0).length;

    if (locationsWithZeroStopNumber > 0) {
      createErrorNotification(translate('routes.alertMessages.routeSaveErrorWithStopNumberZero'));
    } else {
      submit(formKey);
    }
  }, 500);

  checkAllLocations = () => {
    this.setState(prevState => {
      const filteredRouteEntityLocations = map(prevState.filteredRouteEntityLocations, routeEntityLocation => ({
        ...routeEntityLocation,
        isChecked: !prevState.allRouteEntityLocationsChecked,
      }));

      const filteredRouteEntityLocationsOrderNumbers = map(
        filteredRouteEntityLocations,
        ({ orderNumber }) => orderNumber,
      );

      const routeEntityLocations = map(prevState.routeEntityLocations, routeEntityLocation =>
        filteredRouteEntityLocationsOrderNumbers.includes(routeEntityLocation.orderNumber)
          ? { ...routeEntityLocation, isChecked: !prevState.allRouteEntityLocationsChecked }
          : routeEntityLocation,
      );

      return {
        allRouteEntityLocationsChecked: !prevState.allRouteEntityLocationsChecked,
        filteredRouteEntityLocations,
        mapShouldFitBounds: false,
        routeEntityLocations,
      };
    });
  };

  updateRouteProperty = (value: string | number, locationId: number, propertyName: string, stopId?: string) => {
    const { routeEntityLocations, filteredRouteEntityLocations } = this.state;

    const routeLocationIndex = this.getLocationIndex(routeEntityLocations, locationId, stopId);
    const filteredRouteLocationIndex = this.getLocationIndex(filteredRouteEntityLocations, locationId, stopId);

    this.setState({
      filteredRouteEntityLocations: update(filteredRouteEntityLocations, {
        [filteredRouteLocationIndex]: {
          [propertyName]: { $set: value },
        },
      }),
      mapShouldFitBounds: false,
      routeEntityLocations: update(routeEntityLocations, {
        [routeLocationIndex]: {
          [propertyName]: { $set: value },
        },
      }),
      saveStops: true,
    });
  };

  addRouteEntityLocation = (location: RouteEntityLocation) => {
    const { routeEntityLocations, searchTerm } = this.state;

    const locationWithOrderNumber = {
      ...location,
      orderNumber:
        location.positionTypeId === FIRST_JOB_POSITION_ID
          ? 1
          : location.positionTypeId === OPTIMIZED_JOB_POSITION_ID
          ? JOB_PENDING_OPTIMIZATION_ID
          : routeEntityLocations.filter(routeLocation => routeLocation.orderNumber > 0).length + 1,
      stopId: uniqueId(),
    };

    const routeLocationsWithStopNumber = routeEntityLocations.filter(routeLocation => routeLocation.orderNumber !== 0);
    const routeLocationsWithZeroStopNumber = routeEntityLocations.filter(
      routeLocation => routeLocation.orderNumber === 0,
    );

    const routeEntityLocationsReset =
      location.positionTypeId === FIRST_JOB_POSITION_ID
        ? map(routeLocationsWithStopNumber, routeEntityLocation => ({
            ...routeEntityLocation,
            orderNumber:
              routeEntityLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID
                ? JOB_PENDING_OPTIMIZATION_ID
                : routeEntityLocation.orderNumber + 1,
            stopId: uniqueId(),
          }))
        : routeLocationsWithStopNumber;

    const routeLocationsAfterAdd =
      location.positionTypeId === FIRST_JOB_POSITION_ID || location.positionTypeId === OPTIMIZED_JOB_POSITION_ID
        ? update(routeEntityLocationsReset, {
            $unshift: [locationWithOrderNumber],
          })
        : update(routeEntityLocationsReset, {
            $push: [locationWithOrderNumber],
          });

    const filteredRouteEntityLocationsWithStopNumber = filterRouteEntityLocations(routeLocationsAfterAdd, searchTerm);
    const filteredRouteEntityLocationsWithZeroStopNumber = filterRouteEntityLocations(
      routeLocationsWithZeroStopNumber,
      searchTerm,
    );
    this.setState({
      allRouteEntityLocationsChecked: false,
      filteredRouteEntityLocations: [
        ...filteredRouteEntityLocationsWithZeroStopNumber,
        ...filteredRouteEntityLocationsWithStopNumber,
      ],
      isMapLoading: true,
      mapShouldFitBounds: true,
      routeEntityLocations: [...routeLocationsWithZeroStopNumber, ...routeLocationsAfterAdd],
      saveStops: true,
    });
    createSuccessNotification(translate('routes.alertMessages.routeLocationAdded'));

    location.positionTypeId === OPTIMIZED_JOB_POSITION_ID &&
      this.props.change('updateTrackerRouteSwitchWrapper', 'shouldRecreateRoutes', false);

    const { resetForm, formsToResetOnLocationAdd } = this.props;
    formsToResetOnLocationAdd.forEach(f => resetForm(f));
  };

  transferLocations = async (
    transferDispatch: TransferDispatch,
    getStopId: (routeLocation: RouteLocation) => number,
    params: any,
  ) => {
    const { routeEntityLocations, searchTerm } = this.state;
    const { shouldRecreateRoutes } = params.shouldRecreateRoutes || false;
    const routeEntityLocationsToTransfer = filter(routeEntityLocations, ({ isChecked }) => isChecked);

    await transferDispatch({ routeEntityLocationsToTransfer, shouldRecreateRoutes, ...params })
      .then(({ totalStops, transferredStops, notTransfferedStopIds }) => {
        const updatedRouteEntityLocations: RouteEntityLocation[] = routeEntityLocations.filter(routeEntityLocation => {
          const routeEntityId = has(routeEntityLocation, 'serviceContractRouteTemplateId')
            ? routeEntityLocation.service.id
            : routeEntityLocation.id;
          const unSuccessfulStop = notTransfferedStopIds.indexOf(routeEntityId) > -1;
          return unSuccessfulStop || (!unSuccessfulStop && !routeEntityLocation.isChecked);
        });

        const routeLocationsWithStopNumber = updatedRouteEntityLocations.filter(
          routeLocation => routeLocation.orderNumber !== 0,
        );
        const routeLocationsWithZeroStopNumber = updatedRouteEntityLocations.filter(
          routeLocation => routeLocation.orderNumber === 0,
        );
        const routeEntityLocationsReset = this.resetOrderNumbersAndChecks(routeLocationsWithStopNumber);

        const filteredRouteEntityLocationsWithStopNumber = filterRouteEntityLocations(
          routeEntityLocationsReset,
          searchTerm,
        );
        const filteredRouteEntityLocationsWithZeroStopNumber = filterRouteEntityLocations(
          map(routeLocationsWithZeroStopNumber, routeEntityLocation => ({
            ...routeEntityLocation,
            isChecked: false,
          })),
          searchTerm,
        );

        this.setState({
          routeEntityLocations: [...routeLocationsWithZeroStopNumber, ...routeEntityLocationsReset],
          filteredRouteEntityLocations: [
            ...filteredRouteEntityLocationsWithStopNumber,
            ...filteredRouteEntityLocationsWithZeroStopNumber,
          ],
          allRouteEntityLocationsChecked: false,
          transferredStops: transferredStops > 0,
          saveStops: true,
          mapShouldFitBounds: true,
        });

        this.closeTransferLocationsModal();
        createSuccessNotification(
          translate('routes.alertMessages.routeLocationsTransferred', { totalStops, transferredStops }),
        );
      })
      .catch(({ code }) => {
        createErrorNotification(
          `${translate(createTranslationKey(code || 'routeLocationsTransferError', 'routes.alertMessages'))}`,
        );
      });
  };

  render() {
    const {
      batchTransfererInProgress,
      batchTransfererProgress,
      isLoading,
      isRouteEntityFormPristine,
      isSaving,
      locationsAccessorKey,
      locationsInfo,
      routeEntity,
      routeEntityId,
      formKey,
    } = this.props;

    const {
      filteredRouteEntityLocations,
      hasScheduledDailyRoutes,
      isGeoFenceSaving,
      isMapLoading,
      isRouteSaving,
      mapShouldFitBounds,
      saveButtonPending,
      saveStops,
    } = this.state;

    const routeEntityLocations = filteredRouteEntityLocations;
    const routeEntityHasLocations = size(routeEntityLocations) > 0;
    const selectedRouteEntityLocations = filter(filteredRouteEntityLocations, ({ isChecked }) => isChecked);

    const pageTitle = translate(
      `routes.${
        routeEntityId
          ? this.isRouteTemplate
            ? 'editRouteTemplate'
            : 'editDailyRoute'
          : this.isRouteTemplate
          ? 'createRouteTemplate'
          : 'createDailyRoute'
      }`,
    );

    const optimizedRouteLocationsLength = this.props.routeEntityLocations?.filter(
      routeLocation => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
    ).length;

    const newOptimizedRouteLocationsLength = routeEntityLocations?.filter(
      routeLocation => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
    ).length;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              {this.props.renderBackButton()}
              <PageTitle>{pageTitle}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions align="right">
            <Button
              color="primary"
              disabled={
                (locationsInfo && locationsInfo.isLoading) || saveButtonPending || isRouteSaving || isGeoFenceSaving
              }
              id="route-save-button"
              onClick={!isRouteSaving && !isGeoFenceSaving ? this.saveChanges : undefined}
            >
              {translate('common.save')}
            </Button>
            {this.isRouteTemplate && routeEntityId && (
              <UpdateTrackerRouteSwitchWrapper
                hasScheduledDailyRoutes={
                  !!routeEntityId &&
                  hasScheduledDailyRoutes &&
                  (!isRouteEntityFormPristine || saveStops) &&
                  !newOptimizedRouteLocationsLength
                }
              />
            )}
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isSaving}>
            {this.props.renderEditorForm(
              routeEntityLocations,
              this.state.transferredStops,
              this.onRouteEntityEditorFormSubmit,
            )}

            {locationsInfo &&
            locationsInfo.isLoading &&
            routeEntity &&
            routeEntity[locationsAccessorKey] &&
            !size(routeEntity[locationsAccessorKey]) ? (
              <PanelSectionLoading />
            ) : (
              <>
                {this.props.renderAddRouteEntityLocationForm(this.addRouteEntityLocation, this)}

                <RouteEntityLocationSearchForm onSearchTermChange={this.onSearchTermChange} />

                <PanelSection withBorder>
                  <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                    <MapContainer size="large">
                      {locationsInfo && locationsInfo.isLoading ? (
                        <RouteLocationsLoader {...locationsInfo} />
                      ) : (
                        <>
                          {isMapLoading && <GoogleMapLoading />}
                          <RouteMapGL
                            isRoutePlannerPage={this.isRouteTemplate}
                            routeId={Number(routeEntityId || 0)}
                            hideTimeline
                            isInteractive
                            isMapLoading={isMapLoading}
                            mapShouldFitBounds={mapShouldFitBounds}
                            onMapLoadComplete={this.onMapLoadComplete}
                            onSelectLocations={this.onCheckLocationsSelected}
                            routeLocations={routeEntityLocations}
                            selectedLocation={this.state.selectedLocation}
                            formKey={formKey}
                            optimizedRouteLocationsLength={optimizedRouteLocationsLength}
                            routeIsNotScheduled={
                              routeEntity &&
                              !!routeEntity.id &&
                              !this.isRouteTemplate &&
                              routeEntity.routeStatusTypeId !== SCHEDULED
                            }
                          />
                        </>
                      )}
                    </MapContainer>
                  </Resizable>
                </PanelSection>
                <PanelSection>
                  {routeEntityHasLocations ? (
                    this.props.renderLocationsTable(this)
                  ) : (
                    <Message padding="sMedium">{translate('routes.noRouteStops')}</Message>
                  )}

                  {!!selectedRouteEntityLocations.length && (
                    <PageFooter>
                      {!!routeEntityId && (
                        <PopoverWrapper
                          triggerButton={
                            <Button
                              id={`route-${routeEntityId}-transfer-stops-button`}
                              color="primary"
                              margin="no small no no"
                              onClick={this.openTransferLocationsModal}
                              disabled={
                                !isRouteEntityFormPristine ||
                                saveStops ||
                                selectedRouteEntityLocations.length > SELECTED_TRANSFERRED_STOPS
                              }
                            >
                              {`${translate('routes.transferStops')} (${selectedRouteEntityLocations.length})`}
                            </Button>
                          }
                          popoverContent={
                            (!isRouteEntityFormPristine ||
                              saveStops ||
                              selectedRouteEntityLocations.length > SELECTED_TRANSFERRED_STOPS) && (
                              <Popover>
                                {(!isRouteEntityFormPristine || saveStops) && (
                                  <Text block weight="medium" margin="xxSmall no xxSmall">
                                    {translate('routes.alertMessages.routeLocationsDirtyForm')}
                                  </Text>
                                )}
                                {selectedRouteEntityLocations.length > SELECTED_TRANSFERRED_STOPS && (
                                  <Text block weight="medium" margin="xxSmall no xxSmall">
                                    {translate('routes.alertMessages.routeLocationsMoreThan', {
                                      SELECTED_TRANSFERRED_STOPS,
                                    })}
                                  </Text>
                                )}
                              </Popover>
                            )
                          }
                          size="large"
                        />
                      )}

                      <Button
                        id={`route-${routeEntityId}-delete-stops-button`}
                        color="primary"
                        line
                        onClick={this.deleteRouteEntityLocations}
                      >
                        {`${translate('routes.deleteStops')} (${selectedRouteEntityLocations.length})`}
                      </Button>
                    </PageFooter>
                  )}
                </PanelSection>
              </>
            )}
          </PanelSectionGroup>
        </Panel>

        {this.state.isTransferLocationsModalOpen &&
          this.props.renderTransferRouteEntityLocationsModal(this.closeTransferLocationsModal, this.transferLocations)}
        <ProgressPopup
          isVisible={batchTransfererInProgress}
          message={translate('routes.batchTransfer')}
          progress={batchTransfererProgress}
        />

        {this.props.children}
      </PageContent>
    );
  }
}

const mapDispatchToProps = {
  saveRouteGeoFence,
  deleteGeoFences,
  change,
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(BaseRouteEntityEditorPage);
