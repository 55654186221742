import { memo } from 'react';
import { Source, Layer } from 'react-map-gl';

import { COMMUNITY_INSIGHTS_CLUSTERS_LAYER, COMMUNITY_INSIGHTS_CLUSTERS_SOURCE } from './CommunityInsightsClustersGL';

type Props<P extends object = {}> = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
};

const COMMUNITY_INSIGHTS_CLUSTERS_COUNTERS_LAYER = 'communityInsightsClustersCountersLayer';

export default memo(function CommunityInsightsClustersGLSource({ geoJSON }: Props) {
  return (
    <Source
      cluster
      type="geojson"
      id={COMMUNITY_INSIGHTS_CLUSTERS_SOURCE}
      data={geoJSON}
      tolerance={0.0001}
      clusterMaxZoom={11}
    >
      <Layer
        id={COMMUNITY_INSIGHTS_CLUSTERS_LAYER}
        type="circle"
        source={COMMUNITY_INSIGHTS_CLUSTERS_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        }}
      />

      <Layer
        id={COMMUNITY_INSIGHTS_CLUSTERS_COUNTERS_LAYER}
        type="symbol"
        source={COMMUNITY_INSIGHTS_CLUSTERS_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'text-color': 'white',
        }}
        layout={{
          'text-field': '{point_count_abbreviated}',
          'text-size': 12,
          'text-allow-overlap': true,
        }}
      />
    </Source>
  );
});
