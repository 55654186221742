import React from 'react';

import { camelCase } from 'lodash-es';
import { Field, WrappedFieldArrayProps, WrappedFieldProps } from 'redux-form';

import { Dropdown, Table } from '../../../core/components';
import { ExceptionManagementConfiguration } from '../../interfaces/ExceptionManagementConfigurations';
import { DropdownOption } from '../../../core/components/Dropdown';
import { TableCell, TableRow } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

const cells = [
  { name: 'featureName', label: translate('vendors.configurations'), width: '70%', align: 'left' },
  { name: 'defaultReasonCode', label: translate('common.defaultReasonCode'), width: '30%', align: 'left' },
];

type Props = WrappedFieldArrayProps<ExceptionManagementConfiguration> & {
  reasonCodes: DropdownOption[];
};

const ExceptionManagementConfigurationsFormFieldArray: React.FC<Props> = ({ fields, reasonCodes }) => (
  <Table cells={cells}>
    {fields.map((configuration, index) => {
      const { reasonCodeTypePlaceholderIndex } = fields.get(index);

      return (
        <TableRow height={70} key={index}>
          <TableCell width="70%">
            <Field
              name={`${configuration}.technicalName`}
              component={({ input }: WrappedFieldProps) =>
                translate(`routes.pickupIssueTypes.${camelCase(input.value)}`)
              }
            />
          </TableCell>

          <TableCell width="30%">
            <Field
              name={`${configuration}.reasonCodeTypeId`}
              component={Dropdown}
              props={{
                isClearable: true,
                options: reasonCodes,
                width: '100%',
                margin: 'no',
                placeholder:
                  typeof reasonCodeTypePlaceholderIndex === 'number'
                    ? translate('routes.exceptionConfigurationExample', {
                        example: reasonCodes[reasonCodeTypePlaceholderIndex].label,
                      })
                    : '',
              }}
            />
          </TableCell>
        </TableRow>
      );
    })}
  </Table>
);

export default ExceptionManagementConfigurationsFormFieldArray;
