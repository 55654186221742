import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DirtyPickupLocationsDictionary } from './PickupLocationsEditorPage';
import { DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { CollectionPoint } from '../../../interfaces/CollectionPoint';
import { debounce, filter, find } from 'lodash-es';
import { COLLECTION_POINT } from '../../../../common/constants/binMappingTypes';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { PolygonDrawControlGL } from './PolygonDrawControlGL';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { MapGLWrapper } from 'src/customers/components/styled';
import { PickupLocationsCollectionPointsMarkerSource } from './PickupLocationsCollectionPoints';
import { PickupLocationsMarkerSource } from './PickupLocationsMarkerPoints';

interface Props {
  activeCollectionPoint?: CollectionPoint;
  activeRouteLocationIndexes: number[];
  addNewCollectionPoint: (lat: number, lng: number) => void;
  assignToCollectionPoint: (collectionPoint: CollectionPoint) => void;
  collectionPoints: any[];
  dirtyPickupLocations: DirtyPickupLocationsDictionary;
  isEditingCollectionPoints: boolean;
  handleCollectionPointsToDelete: (collectionPointId: number | string) => void;
  markLocationActive: (index: number) => void;
  markMultipleLocationsActive: (locationIndexes: number[]) => void;
  onAddressChange: (index: number, serviceContractId: number, lat: number, long: number) => void;
  onBoundsChanged: (ne: any, sw: any) => void;
  onMoveCollectionPoint: (collectionPoint: CollectionPoint, lat: number, long: number) => void;
  routeLocations: RouteLocation[];
  setActiveCollectionPoint: (collectionPoint?: CollectionPoint) => void;
}

const mapViewStyle = {
  isSatelliteEnabled: false,
  mapCenter: null,
  mapZoom: null,
};

const PickupLocationsEditorMapGL: React.FC<Props> = ({
  activeCollectionPoint,
  activeRouteLocationIndexes,
  addNewCollectionPoint,
  assignToCollectionPoint,
  collectionPoints: collectionPointsFromProps,
  dirtyPickupLocations,
  isEditingCollectionPoints,
  handleCollectionPointsToDelete,
  markLocationActive,
  markMultipleLocationsActive,
  onAddressChange,
  onBoundsChanged,
  onMoveCollectionPoint,
  routeLocations = [],
  setActiveCollectionPoint,
}) => {
  const [collectionPointsToDelete, setCollectionPointsToDelete] = useState<(number | string)[]>([]);
  const [collectionPoints, setCollectionPoints] = useState<CollectionPoint[]>([]);

  useEffect(() => {
    if (collectionPointsFromProps.length) {
      setCollectionPoints(
        filter(collectionPointsFromProps, (collectionPoint: CollectionPoint) =>
          collectionPoint?.id ? !collectionPointsToDelete.includes(collectionPoint.id) : collectionPoint,
        ),
      );
    }
  }, [collectionPointsFromProps, collectionPointsToDelete]);

  const deleteCollectionPoints = (collectionPointId: number | string) => {
    setCollectionPoints(collectionPoints.filter(collectionPoint => collectionPoint.id !== collectionPointId));
    setCollectionPointsToDelete([...collectionPointsToDelete, collectionPointId]);
    handleCollectionPointsToDelete(collectionPointId);
  };

  const handleRemoveCollectionPoints = async (collectionPointId: number | string) => {
    const collectionPointToBeDeleted = find(collectionPoints, c => c.id === collectionPointId);
    const hasAssociatedServices =
      (collectionPointToBeDeleted &&
        collectionPointToBeDeleted.serviceContractIds &&
        collectionPointToBeDeleted.serviceContractIds.length > 0) ||
      (collectionPointToBeDeleted && collectionPointToBeDeleted.isAssociated);

    if (hasAssociatedServices) {
      if (await confirm(translate('customers.pickupLocations.confirmDeleteAssociatedCollectionPoint'))) {
        setActiveCollectionPoint();
        deleteCollectionPoints(collectionPointId);
      } else {
        return null;
      }
    } else {
      if (await confirm(translate('customers.pickupLocations.confirmDeleteCollectionPoint'))) {
        setActiveCollectionPoint();
        deleteCollectionPoints(collectionPointId);
      } else {
        return null;
      }
    }
  };

  const [map, setMap] = useState<mapboxgl.Map>();

  const [viewport, setViewport] = useState<MapGLViewport>({});

  const [dragPan, setDragPan] = useState<boolean>(true);

  const [editPolygon, setEditPolygon] = useState<boolean>(false);
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();

  // Select stops via laso tool
  const selectPointsInPolygon = useCallback(
    (points: RouteLocation[], newPolygon: any) => {
      const includedPoints: number[] = [];
      points.forEach((routeLocation, index) => {
        let serviceLatitude: number;
        let serviceLongitude: number;
        if (dirtyPickupLocations[index] && dirtyPickupLocations[index].binMappingTypeId !== COLLECTION_POINT) {
          serviceLatitude = dirtyPickupLocations[index].latitude;
          serviceLongitude = dirtyPickupLocations[index].longitude;
        } else {
          const serviceContractBinDetails = routeLocation.service.serviceContractBinDetails[0];
          const isCollectionPoint = serviceContractBinDetails.binLocationMappingTypeId === COLLECTION_POINT;
          serviceLatitude = isCollectionPoint
            ? serviceContractBinDetails.displayLatitude!
            : serviceContractBinDetails.binLatitude;
          serviceLongitude = isCollectionPoint
            ? serviceContractBinDetails.displayLongitude!
            : serviceContractBinDetails.binLongitude;
        }
        if (booleanPointInPolygon([serviceLongitude, serviceLatitude], newPolygon)) {
          includedPoints.push(index);
        }
      });
      if (includedPoints.length) {
        markMultipleLocationsActive(includedPoints);
      }
    },
    [dirtyPickupLocations, markMultipleLocationsActive],
  );

  const checkLocationsInPolygon = (event: any) => {
    const newPolygon = event.data;

    if (event.editType === 'addFeature') {
      selectPointsInPolygon(routeLocations, newPolygon[0]);
      setDrawMode(undefined);
    }
  };

  const onBoundsChangedDebounced = useMemo(() => debounce(onBoundsChanged, 1000), [onBoundsChanged]);

  const handleViewportChange = useCallback(() => {
    if (map && isEditingCollectionPoints) {
      const ne = map?.getBounds().getNorthEast();
      const sw = map?.getBounds().getSouthWest();
      onBoundsChangedDebounced({ lat: () => ne.lat, lng: () => ne.lng }, { lat: () => sw.lat, lng: () => sw.lng });
    }
  }, [isEditingCollectionPoints, map, onBoundsChangedDebounced]);

  useEffect(() => {
    if (isEditingCollectionPoints) {
      handleViewportChange();
    }
  }, [handleViewportChange, isEditingCollectionPoints]);

  const [initialViewportSet, setInitialViewportSet] = useState(false);

  const handleSetViewport = useCallback((vp: MapGLViewport) => {
    setViewport(vp);
    setInitialViewportSet(true);
  }, []);

  return (
    <MapGLWrapper drawingEnabled={!!drawMode} isDrawing={!!drawMode}>
      <MapGL
        dragPan={dragPan}
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={setMap}
        onChangeViewport={handleViewportChange}
      >
        {map && (
          <>
            <PickupLocationsMarkerSource
              activeCollectionPoint={activeCollectionPoint}
              activeLocations={activeRouteLocationIndexes}
              setViewport={handleSetViewport}
              isEditingCollectionPoints={isEditingCollectionPoints}
              setDragPan={setDragPan}
              fitToMapBounds={!initialViewportSet}
              map={map}
              routeLocations={routeLocations}
              dirtyPickupLocations={dirtyPickupLocations}
              onAddressChange={onAddressChange}
              markLocationActive={markLocationActive}
            />
            {isEditingCollectionPoints && (
              <>
                <PickupLocationsCollectionPointsMarkerSource
                  onMoveCollectionPoint={onMoveCollectionPoint}
                  activeCollectionPoint={activeCollectionPoint}
                  setDragPan={setDragPan}
                  map={map}
                  collectionPoints={collectionPoints}
                  addNewCollectionPoint={addNewCollectionPoint}
                  setActiveCollectionPoint={setActiveCollectionPoint}
                  anyActiveLocations={!!activeRouteLocationIndexes.length}
                  assignToCollectionPoint={assignToCollectionPoint}
                  handleRemoveCollectionPoints={handleRemoveCollectionPoints}
                />
                <PolygonDrawControlGL
                  isDrawing={!!drawMode}
                  setDrawMode={setDrawMode}
                  showEditPolygon={false}
                  editPolygon={editPolygon}
                  setEditPolygon={setEditPolygon}
                  drawMode={drawMode}
                  polygon={undefined}
                  onUpdate={checkLocationsInPolygon}
                  isSatelliteEnabled={mapViewStyle.isSatelliteEnabled}
                />
              </>
            )}
          </>
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default PickupLocationsEditorMapGL;
