import styled, { css } from 'styled-components';

import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';
import Icon from '../Icon';

interface ActionTooltipProps {
  color?: string;
  size?: string;
  tooltipLeftPosition?: number;
  tooltipTopPosition?: number;
}

export const ActionTooltip = styled.span<ActionTooltipProps>`
  visibility: hidden;
  padding: 6px 24px;
  white-space: nowrap;
  min-width: 100px;
  background-color: ${props => (props.color ? colorMapper(props) : props.theme.brandPrimary)};
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  position: fixed;
  left: ${props => props.tooltipLeftPosition}px;
  top: ${props => props.tooltipTopPosition}px;
  text-align: center;
  z-index: 9999;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: calc(50% - 8px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -8px;
    border-top: 8px solid ${props => (props.color ? colorMapper(props) : props.theme.brandPrimary)};
  }
`;

export const ActionIcon = styled(Icon)<{ color?: string; rotationAngle?: string; size?: string; margin?: string }>`
  vertical-align: top;
  width: ${props =>
    mapper(
      props.size,
      {
        small: '13px',
        sMedium: '16px',
        mMedium: '20px',
        medium: '25px',
        mLarge: '30px',
        large: '50px',
        resetPasswordSize: '16.3px',
        resetPasswordSizeMedium: '20px',
      },
      'small',
    )};
  height: ${props =>
    mapper(
      props.size,
      {
        small: '13px',
        sMedium: '16px',
        mMedium: '20px',
        medium: '25px',
        mLarge: '30px',
        large: '50px',
        resetPasswordSize: '13px',
        resetPasswordSizeMedium: '16px',
      },
      'small',
    )};

  ${props =>
    props.margin &&
    css`
      margin: ${sizeMapper(props.margin, 'no')};
    `};

  ${props =>
    props.rotationAngle &&
    css`
      transform: rotate(${props.rotationAngle}deg);
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};
`;

interface ActionButtonProps {
  hasTextWithIcon?: boolean;
  margin?: string;
}

export const ActionButton = styled.span<ActionButtonProps>`
  margin: ${props => sizeMapper(props.margin, 'no')};

  > * {
    pointer-events: none;
  }

  ${props =>
    props.hasTextWithIcon &&
    css`
      position: absolute;
      right: 0;
      top: 0;
    `};
`;

interface ActionContentProps {
  marginTop?: string;
}
export const ActionContent = styled.div<ActionContentProps>`
  display: inline-block;
  pointer-events: none;
  margin-left: 4px;
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};
`;
