import { combineReducers } from 'redux';

import { reducer as billCreateSummaryReducer } from './billCreateSummary';
import { reducer as billDetailsReducer } from './billDetails';
import { reducer as billHistoryReducer } from './billHistory';
import { reducer as billingReducer } from './billing';
import { reducer as billStatusesReducer } from './billStatuses';
import { reducer as containerTypesReducer } from './containerTypes';
import { reducer as createBillsReducer } from './createBills';
import { reducer as customerAccountStatusesReducer } from './customerAccountStatuses';
import { reducer as invoiceStatusesReducer } from './invoiceStatuses';
import { reducer as openBillsReducer } from './openBills';
import { reducer as paymentDetailsReducer } from './paymentDetails';
import { reducer as paymentManagementReducer } from './paymentManagement';
import { reducer as paymentMethodsReducer } from './paymentMethods';
import { reducer as paymentsReducer } from './payments';
import { reducer as paymentStatistics } from './paymentStatistics';
import { reducer as paymentStatusesReducer } from './paymentStatuses';
import { reducer as rateManagerReducer } from './rateManager';
import { reducer as receivedViaReducer } from './receivedViaOptions';
import { reducer as sendBillReducer } from './sendBill';
import { reducer as serviceRatesReducer } from './serviceRates';
import { reducer as unbilledChargesByLocation } from './unbilledChargesByLocation';
import { reducer as unbilledChargesReducer } from './unbilledCharges';
import { reducer as workFlowTypesReducer } from './workFlowTypes';

export { loadBillCreateSummary } from './billCreateSummary';
export {
  exportBilledCharges,
  loadBillDetails,
  releaseBill,
  resetBillDetails,
  unassignBilledCharges,
} from './billDetails';
export { loadBillStatuses, resetBillStatuses } from './billStatuses';
export { changeStatusToUnbilled, exportBills, loadBilling, resetBilling } from './billing';
export { loadContainerTypes } from './containerTypes';
export { createBills, resetCreateBills } from './createBills';
export { loadCustomerAccountStatuses } from './customerAccountStatuses';
export { loadInvoiceStatuses } from './invoiceStatuses';
export {
  assignOpenBill,
  exportOpenBill,
  exportOpenBills,
  loadOpenBills,
  resetOpenBills,
  unassignOpenBill,
} from './openBills';
export { loadPaymentDetails, resetPaymentDetails } from './paymentDetails';
export {
  finalizePayment,
  getPaymentSetup as loadInvoiceDetails,
  loadPaymentMethodsByInvoice,
  setupPaymentIntent,
} from './paymentManagement';
export { loadPaymentStatistics, resetPaymentStatistics } from './paymentStatistics';
export { loadPaymentStatuses, resetPaymentStatuses } from './paymentStatuses';
export { loadPaymentMethods, resetPaymentMethods } from './paymentMethods';
export { applyCheck, exportPayments, loadPayments, resetPayments, savePayment, updatePayment } from './payments';
export { loadReceivedViaOptions } from './receivedViaOptions';
export { loadRateTypes, loadServiceRates, loadUOMs } from './serviceRates';
export { assignToBill, exportUnbilledCharges, loadUnbilledChargesList, resetUnbilledCharges } from './unbilledCharges';
export { loadWorkFlowTypes } from './workFlowTypes';
export {
  exportRateConfiguration,
  loadBillingUnitOfMeasureTypes,
  loadRateAccountingCodes,
  loadRateCodes,
  saveRateAccountingCode,
  saveRateCode,
  saveRateCodes,
  setChangedRateCodes,
  uploadRateCodeImportFile,
  uploadAccountingCodeImportFile,
  uploadRateImportFile,
  loadRateCodeImportUploadedFilesStatus,
  loadAccountingCodeImportUploadedFilesStatus,
  loadRateImportUploadedFilesStatus,
} from './rateManager';

export const reducer = combineReducers({
  billCreateSummary: billCreateSummaryReducer,
  billDetails: billDetailsReducer,
  billing: billingReducer,
  billStatuses: billStatusesReducer,
  containerTypes: containerTypesReducer,
  createBills: createBillsReducer,
  customerAccountStatuses: customerAccountStatusesReducer,
  invoiceStatuses: invoiceStatusesReducer,
  openBills: openBillsReducer,
  paymentManagement: paymentManagementReducer,
  paymentDetails: paymentDetailsReducer,
  payments: paymentsReducer,
  paymentStatistics: paymentStatistics,
  paymentStatuses: paymentStatusesReducer,
  paymentMethods: paymentMethodsReducer,
  receivedVia: receivedViaReducer,
  serviceRates: serviceRatesReducer,
  unbilledCharges: unbilledChargesReducer,
  workFlowTypes: workFlowTypesReducer,
  sendBill: sendBillReducer,
  unbilled: unbilledChargesByLocation,
  billHistory: billHistoryReducer,
  rateManager: rateManagerReducer,
});
