import { PureComponent } from 'react';

import { Field } from 'redux-form';
import moment from 'moment';

import TimePickerInput from 'src/core/components/TimePicker';
import { Input } from '../../core/components';
import {
  Button,
  FormError,
  Grid as GridUntyped,
  GridColumn as GridColumnUntyped,
  TableCell,
} from '../../core/components/styled';
import translate from '../../core/services/translate';
import {
  isDecimalUpTo1OrNull,
  isOptionalDecimalUpTo1OrNull,
  isRequired,
  isRequiredOrZero,
  maxLength6,
  maxValueNumeric9999,
} from '../../utils/services/validator';
import { loadRouteBuilderDefaultSettings } from '../services/routeBuilderSettings';
import { getTotalBreakTimeDate } from './forms/RouteSettingsForm';

const Grid = GridUntyped as any;
const GridColumn = GridColumnUntyped as any;

interface Props {
  change: (name: string, time: Date | null) => void;
  routeStartTime: Date;
  hideRestoreToDefaultsButton?: boolean;
  maxRouteTime?: Date;
  amBreakTime?: Date;
  pmBreakTime?: Date;
  lunchBreakTime?: Date;
}

interface State {
  defaultRouteStartTime: Date;
  error: string;
}

class RouteBuilderConfiguration extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      defaultRouteStartTime: props.routeStartTime,
      error: '',
    };
  }

  static defaultProps = {
    hideRestoreToDefaultsButton: false,
  };

  restoreToDefaults = () => {
    loadRouteBuilderDefaultSettings().then(data => {
      this.resetFormFields(data);
    });
  };

  resetFormFields = (defaultConfig: any) => {
    this.updateFormValue('routeStartTime', defaultConfig.routeStartTime);
    this.updateFormValue('maxRouteTime', defaultConfig.maxRouteTime);
    this.updateFormValue('serviceTimePerStop', defaultConfig.serviceTimePerStop);
    this.updateFormValue('preStartTime', defaultConfig.preStartTime);
    this.updateFormValue('postEndTime', defaultConfig.postEndTime);
    this.updateFormValue('vehicleCapacity', defaultConfig.vehicleCapacity);
    this.updateFormValue('pUCapacityAllotment', defaultConfig.pUCapacityAllotment);

    this.setState({ defaultRouteStartTime: defaultConfig.routeStartTime });
  };

  updateFormValue = (name: string, value: any) => {
    this.props.change(name, value);
  };

  onTimeComplexChange = (name: string, value: Date | string) => {
    const dateTime = moment(value, 'HH:mm:ss');
    if (dateTime.isValid()) this.updateFormValue(name, dateTime.toDate());
    else this.updateFormValue(name, null);
  };

  componentDidUpdate(prevProps: Props) {
    const { amBreakTime, pmBreakTime, lunchBreakTime, maxRouteTime } = this.props;

    if (
      prevProps.amBreakTime !== amBreakTime ||
      prevProps.pmBreakTime !== pmBreakTime ||
      prevProps.lunchBreakTime !== lunchBreakTime
    ) {
      const totalBreakTime = getTotalBreakTimeDate(amBreakTime, pmBreakTime, lunchBreakTime);
      this.updateFormValue('totalBreakTime', totalBreakTime);

      if (maxRouteTime && totalBreakTime > maxRouteTime) {
        this.setState({ error: translate('routes.routeSettings.totalBreakTimeHasToBeLessThanMaxRouteTime') });
      } else if (this.state.error) {
        this.setState({ error: '' });
      }
    }
  }

  render() {
    const { hideRestoreToDefaultsButton } = this.props;
    const { defaultRouteStartTime, error } = this.state;

    return (
      <Grid multiLine>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              time={defaultRouteStartTime}
              name="routeStartTime"
              props={{
                name: 'routeStartTime',
                errorOnSingleLine: true,
                margin: 'no',
                width: '100%',
                maxDetail: 'second',
                format: 'hh:mm:ss a',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('routeStartTime', value),
              }}
              component={TimePickerInput}
              validate={[isRequired]}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.routeStartTime')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              name="maxRouteTime"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                name: 'maxRouteTime',
                format: 'HH:mm:ss',
                maxDetail: 'second',
                errorOnSingleLine: true,
                margin: 'no',
                width: '100%',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('maxRouteTime', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.maxRouteTime')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              name="serviceTimePerStop"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                name: 'serviceTimePerStop',
                format: 'HH:mm:ss',
                maxDetail: 'second',
                errorOnSingleLine: true,
                margin: 'no',
                width: '100%',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('serviceTimePerStop', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.routeSettings.serviceTimePerStop')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              name="preStartTime"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                name: 'preStartTime',
                format: 'HH:mm:ss',
                maxDetail: 'second',
                errorOnSingleLine: true,
                margin: 'no',
                width: '100%',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('preStartTime', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.preTripTime')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              name="postEndTime"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                name: 'postEndTime',
                format: 'HH:mm:ss',
                maxDetail: 'second',
                errorOnSingleLine: true,
                margin: 'no',
                width: '100%',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('postEndTime', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.postTripTime')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              name="totalBreakTime"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                width: '100%',
                margin: 'no',
                maxDetail: 'second',
                format: 'HH:mm:ss',
                readOnly: true,
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('totalBreakTime', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.routeSettings.totalBreakTime')}</TableCell>
        </GridColumn>
        {error && (
          <GridColumn size="12/12" verticalAlign padding="no medium">
            <FormError>{error}</FormError>
          </GridColumn>
        )}
        <GridColumn size="12/12" verticalAlign>
          <TableCell padding="no no no large" width="25%">
            <Field
              name="amBreakTime"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                width: '100%',
                margin: 'no',
                maxDetail: 'second',
                format: 'HH:mm:ss',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('amBreakTime', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.routeSettings.amBreakTime')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell padding="no no no large" width="25%">
            <Field
              name="lunchBreakTime"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                width: '100%',
                margin: 'no',
                maxDetail: 'second',
                format: 'HH:mm:ss',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('lunchBreakTime', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.routeSettings.lunchBreakTime')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell padding="no no no large" width="25%">
            <Field
              name="pmBreakTime"
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                width: '100%',
                margin: 'no',
                maxDetail: 'second',
                format: 'HH:mm:ss',
                onTimeChange: (value: Date | string) => this.onTimeComplexChange('pmBreakTime', value),
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.routeSettings.pmBreakTime')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              name="vehicleCapacity"
              component={Input}
              validate={[isOptionalDecimalUpTo1OrNull, maxLength6, maxValueNumeric9999]}
              margin="no"
              type="number"
              props={{
                errorOnSingleLine: true,
                min: 0,
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.vehicleCapacity')}</TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="25%">
            <Field
              name="pUCapacityAllotment"
              component={Input}
              validate={[isRequiredOrZero, isDecimalUpTo1OrNull, maxLength6, maxValueNumeric9999]}
              margin="no"
              type="number"
              props={{
                errorOnSingleLine: true,
                min: 0,
              }}
            />
          </TableCell>
          <TableCell width="75%">{translate('routes.pUCapacityAllotment')}</TableCell>
        </GridColumn>

        {!hideRestoreToDefaultsButton && (
          <GridColumn size="12/12" verticalAlign>
            <TableCell width="100%">
              <Button
                type="button"
                color="secondary"
                margin="no no ssMedium no"
                onClick={() => this.restoreToDefaults()}
              >
                {translate('routes.restoreToDefaults')}
              </Button>
            </TableCell>
          </GridColumn>
        )}
      </Grid>
    );
  }
}

export default RouteBuilderConfiguration;
