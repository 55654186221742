import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { http } from 'src/core/services/http';
import translate from 'src/core/services/translate';
import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';

export const loadBilling = (
  vendorId: number,
  startDate: StringOrDate,
  endDate: StringOrDate,
  billsSearchTerm?: string,
  billStatusId?: number,
  unbilledSearchTerm?: string,
  workOrderTypeId?: number,
  containerTypeId?: number,
  includeRates?: boolean,
) =>
  http
    .post('statements/billed', {
      vendorId,
      startDate,
      endDate,
      billsSearchTerm,
      billStatusId,
      unbilledSearchTerm,
      workOrderTypeId,
      containerTypeId,
      includeRates,
    })
    .then(response => response.data);

export const changeStatusToUnbilled = (vendorId: number, billId: number) =>
  http.delete(`statements/${billId}/delete`, {
    params: { vendorId },
  });

export const exportBills = (
  billsSearchTerm?: string,
  billStatusId?: number,
  startDate?: StringOrDate,
  endDate?: StringOrDate,
) =>
  downloadExcelFile('statements/exportBills', `${translate('finance.bills')}-${translate('finance.report')}`, {
    billsSearchTerm,
    billStatusId,
    startDate,
    endDate,
  });

export const releaseBills = (billIds: number[]) =>
  http.put('statements/bulkRelease', billIds).then(response => response.data);
