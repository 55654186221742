import { PureComponent } from 'react';
import { Field } from 'redux-form';
import { filter, map, size } from 'lodash-es';

import { DropDownFieldProps } from './DropDownFieldProps';
import { MultiSelect } from '../../core/components';
import { PICKUP_STATUSES, SNOW_OR_SWEEPER_ROUTE_STATUSES } from '../constants';
import translate from '../../core/services/translate';

interface Props {
  acceptedPickupStatuses: number[];
  isSnowOrSweeperRoute?: boolean;
}

class PickupStatusDropdown extends PureComponent<DropDownFieldProps & Props> {
  onChange = (event: any, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      acceptedPickupStatuses,
      dropdownProps,
      input: { name },
      isSnowOrSweeperRoute,
      label,
      placeholder,
      withLabel,
      withPlaceholder,
    } = this.props;

    const pickupStatuses = map(
      isSnowOrSweeperRoute ? SNOW_OR_SWEEPER_ROUTE_STATUSES : PICKUP_STATUSES,
      pickupStatus => ({
        label: pickupStatus.name,
        value: pickupStatus.id,
      }),
    );

    const pickupStatusOptions = !!size(acceptedPickupStatuses)
      ? filter(pickupStatuses, pickupStatus => acceptedPickupStatuses.indexOf(pickupStatus.value) !== -1)
      : pickupStatuses;

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.pickupStatus') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.pickupStatus') : undefined)}
        component={MultiSelect as any}
        options={pickupStatusOptions}
        onChange={this.onChange}
        normalizeValues={Number}
        {...dropdownProps}
      />
    );
  }
}

export default PickupStatusDropdown;
