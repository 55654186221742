import React, { PureComponent } from 'react';

import { map } from 'lodash-es';
import { ModalImage } from 'src/routes/interfaces/RouteImages';
import { ImageSlider, Modal } from '../../../core/components';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemIcon,
  DetailsList,
  ModalSection,
  ModalSubtitle,
  Text,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { dateAndTime } from '../../../utils/services/formatter';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

interface Props {
  closeModal: () => void;
  images: ModalImage[];
  issueName?: string;
  locationAddress?: string;
  locationObstacles?: string;
  locationTimestamp?: string;
  ImageSourceType?: TechnicalType;
}

interface State {
  imageUrls: string[];
  imageToDownloadUrls: any[];
  index: number;
}

class RouteImagesModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      index: 0,
      imageUrls: map(props.images, 'imageUrl'),
      imageToDownloadUrls: map(props.images, 'rawImageUrl'),
    };
  }

  onIndexChange = (index: number) => {
    this.setState({ index });
  };

  render() {
    const { closeModal, images, locationAddress, locationObstacles, locationTimestamp } = this.props;
    const { index, imageUrls, imageToDownloadUrls } = this.state;

    if (!images[index]) {
      return (
        <Modal onClose={closeModal} padding="medium no no">
          <ModalSection padding="medium medium small">{translate('common.imageNotFound')}</ModalSection>
        </Modal>
      );
    }

    const {
      contaminationSubTypesName,
      customerName = '',
      issueName,
      locationName,
      routeName = '',
      sourceName,
      timeStamp,
      jobSourceTypeId,
    } = images[index];

    const modalHasDetails = !!sourceName || !!issueName || !!timeStamp || !!locationTimestamp || !!locationObstacles;

    return (
      <Modal title={customerName || routeName} onClose={closeModal} padding="medium no no">
        <ModalSection padding={customerName || routeName ? 'no medium small' : 'medium medium small'}>
          {!!locationAddress && (
            <DescriptionList centered margin="small no no">
              <DescriptionListItem>
                <strong>{translate('common.address')}</strong>: {locationAddress}
              </DescriptionListItem>
            </DescriptionList>
          )}
          {!!locationName && (
            <ModalSubtitle modalHasDetails={modalHasDetails}>
              <strong>{translate('common.address')}</strong>: {locationName}
            </ModalSubtitle>
          )}
          {(!!sourceName || !!issueName || !!timeStamp || !!locationTimestamp) && (
            <DescriptionList centered margin="small no no">
              {!!sourceName && (
                <DescriptionListItem margin="no small no no">
                  <DescriptionListItemIcon icon="user" color="primary" /> {sourceName}
                </DescriptionListItem>
              )}
              {!!issueName && !(jobSourceTypeId === 2) && (
                <DescriptionListItem margin="no small no no" maxWidth="60%">
                  <DescriptionListItemIcon icon="issue" color="warning" />
                  <DetailsList vertical>
                    <Text block size="modal" key={index}>
                      {issueName}
                      {contaminationSubTypesName ? `: ${contaminationSubTypesName}` : undefined}
                    </Text>
                  </DetailsList>
                </DescriptionListItem>
              )}
              {(!!timeStamp || !!locationTimestamp) && (
                <DescriptionListItem>
                  <strong>{translate('common.timestamp')}</strong>:
                  <DescriptionListItemIcon margin="no xxSmall" icon="calendar" color="primary" />
                  {timeStamp ? dateAndTime(timeStamp) : locationTimestamp}
                </DescriptionListItem>
              )}
            </DescriptionList>
          )}
          {locationObstacles && (
            <DescriptionList centered margin="small no no">
              <DescriptionListItem>
                <strong>{translate('common.obstacle')}</strong>: {locationObstacles}
              </DescriptionListItem>
            </DescriptionList>
          )}
        </ModalSection>

        <ImageSlider
          images={imageUrls}
          imagesToDownload={imageToDownloadUrls}
          imageTitle={customerName || routeName}
          margin="small no no"
          onIndexChange={this.onIndexChange}
        />
      </Modal>
    );
  }
}

export default RouteImagesModal;
