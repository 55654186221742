import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { DrivingIncidentsData } from '../interfaces/DrivingIncidents';
import { loadDrivingIncidents as doLoadDrivingIncidents } from '../services/drivingIncidents';

const START_LOAD = 'routes/drivingIncidents/START_LOAD';
const COMPLETE_LOAD = 'routes/drivingIncidents/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/drivingIncidents/FAIL_LOAD';
const RESET = 'routes/drivingIncidents/RESET';

interface State {
  drivingIncidents?: DrivingIncidentsData;
  isLoading: boolean;
}

const initialState: State = {
  drivingIncidents: undefined,
  isLoading: false,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivingIncidents: action.drivingIncidents,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivingIncidents: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (drivingIncidents: DrivingIncidentsData) => ({
  type: COMPLETE_LOAD,
  drivingIncidents,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDrivingIncidents = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDrivingIncidentsPromise = doLoadDrivingIncidents(vendorId, routeId);
  loadDrivingIncidentsPromise
    .then(drivingIncidents => dispatch(completeLoad(drivingIncidents)))
    .catch(() => dispatch(failLoad()));
  return loadDrivingIncidentsPromise;
};

export const resetDrivingIncidents = () => ({
  type: RESET,
});
