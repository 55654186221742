import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../contracts/ducks';
import { loadRouteLocationNotes } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import RouteLocationNotesModal from './RouteLocationNotesModal';

interface Props {
  closeModal: () => void;
  isNewStop?: boolean;
  isReadOnly?: boolean;
  loadRouteLocationNotes: DuckFunction<typeof loadRouteLocationNotes>;
  modalSubTitle?: string;
  modalTitle?: string;
  routeId?: number;
  routeLocationId?: number;
  siteAddress?: string;
}

class RouteLocationNotesModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadRouteLocationNotes, routeId, routeLocationId } = this.props;
    const promises = [loadRouteLocationNotes(routeId, routeLocationId)];
    return Promise.all(promises);
  };

  render() {
    const { closeModal, isNewStop, isReadOnly, modalSubTitle, modalTitle, routeId, routeLocationId, siteAddress } =
      this.props;

    return (
      <Resolver
        successComponent={RouteLocationNotesModal}
        successProps={{
          closeModal,
          isNewStop,
          isReadOnly,
          modalSubTitle,
          modalTitle,
          routeId,
          routeLocationId,
          siteAddress,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = { loadRouteLocationNotes };

export default connect(undefined, mapDispatchToProps)(RouteLocationNotesModalResolver);
