import { map } from 'lodash-es';
import { getServiceName } from './transformEquipmentTypeSizeWaste';

const transformLoadSearchedLocation = ({ serviceContracts, ...location }: any) => ({
  ...location,
  serviceContracts: map(
    serviceContracts,
    ({ id, equipmentSizeName, equipmentTypeTechnicalName, wasteMaterialTypeTechnicalName }) => ({
      id,
      name: getServiceName(equipmentSizeName, equipmentTypeTechnicalName, wasteMaterialTypeTechnicalName),
    }),
  ),
});

export default transformLoadSearchedLocation;
