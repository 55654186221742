import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getCustomerAccountStatuses } from 'src/finance/services/customerAccountStatuses';

// Actions
const START_LOAD_CUSTOMER_ACCOUNT_STATUSES =
  'finance/billing/customerAccountStatuses/START_LOAD_CUSTOMER_ACCOUNT_STATUSES ';
const COMPLETE_LOAD_CUSTOMER_ACCOUNT_STATUSES =
  'finance/billing/customerAccountStatuses/COMPLETE_LOAD_CUSTOMER_ACCOUNT_STATUSES ';
const FAIL_LOAD_CUSTOMER_ACCOUNT_STATUSES =
  'finance/billing/customerAccountStatuses/FAIL_LOAD_CUSTOMER_ACCOUNT_STATUSES ';

// Initial state
const initialState: {
  isLoading: boolean;
  customerAccountStatuses: TechnicalType[];
} = {
  isLoading: false,
  customerAccountStatuses: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CUSTOMER_ACCOUNT_STATUSES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_CUSTOMER_ACCOUNT_STATUSES:
      return update(state, {
        $merge: {
          isLoading: false,
          customerAccountStatuses: action.customerAccountStatuses,
        },
      });

    case FAIL_LOAD_CUSTOMER_ACCOUNT_STATUSES:
      return update(state, {
        $merge: {
          isLoading: false,
          customerAccountStatuses: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD_CUSTOMER_ACCOUNT_STATUSES,
});

const completeLoad = (customerAccountStatuses: TechnicalType[]) => ({
  type: COMPLETE_LOAD_CUSTOMER_ACCOUNT_STATUSES,
  customerAccountStatuses,
});

const failLoad = () => ({
  type: FAIL_LOAD_CUSTOMER_ACCOUNT_STATUSES,
});

export const loadCustomerAccountStatuses = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getCustomerAccountStatusesPromise = getCustomerAccountStatuses();
  getCustomerAccountStatusesPromise
    .then((customerAccountStatuses: any[]) => dispatch(completeLoad(customerAccountStatuses)))
    .catch(() => dispatch(failLoad()));
  return getCustomerAccountStatusesPromise;
};
