import React, { PureComponent } from 'react';
import { map, indexOf, without } from 'lodash-es';
import { WEEKDAYS_BY_SHORTCODE } from '../constants';
import { FormGroup, FormLabel, FormError, DaysOfWeek, DayOfWeek } from './styled';
import { WrappedFieldProps } from 'redux-form';

interface Props extends WrappedFieldProps {
  multiple?: boolean;
  label?: string;
  isReadOnly?: boolean;
  margin?: string;
  daysOfWeekProps?: any;
  dayOfWeekProps?: any;
}

class DaysOfWeekPicker extends PureComponent<Props> {
  onDayOfWeekClick = (dayOfWeekShortcode: string) => {
    const {
      input: { value, onChange },
      multiple,
    } = this.props;

    if (multiple) {
      const nextValue =
        indexOf(value, dayOfWeekShortcode) > -1 ? without(value, dayOfWeekShortcode) : [...value, dayOfWeekShortcode];
      onChange(nextValue);
      return;
    }

    onChange(value !== dayOfWeekShortcode ? dayOfWeekShortcode : null);
  };

  isDayOfWeekSelected = (dayOfWeekShortcode: string) => {
    const {
      input: { value },
      multiple,
    } = this.props;
    return multiple ? indexOf(value, dayOfWeekShortcode) > -1 : value === dayOfWeekShortcode;
  };

  render() {
    const {
      meta: { submitFailed, error },
      label,
      isReadOnly,
      margin,
      daysOfWeekProps,
      dayOfWeekProps,
    } = this.props;

    const weekdays = map(WEEKDAYS_BY_SHORTCODE, weekday => ({
      ...weekday,
      isSelected: this.isDayOfWeekSelected(weekday.shortCode),
    }));

    return (
      <FormGroup hasValue margin={margin}>
        {!!label && <FormLabel>{label}</FormLabel>}

        <DaysOfWeek {...daysOfWeekProps} isReadOnly={isReadOnly}>
          {map(weekdays, weekday => (
            <DayOfWeek
              key={weekday.id}
              isSelected={weekday.isSelected}
              onClick={() => this.onDayOfWeekClick(weekday.shortCode)}
              {...dayOfWeekProps}
            >
              {weekday.shortCodeName}
            </DayOfWeek>
          ))}
        </DaysOfWeek>

        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

export default DaysOfWeekPicker;
