import { mapKeys } from 'lodash-es';

import translate from 'src/core/services/translate';

const WATER_ID = 4;

const GALLON_ID = 6;
const FILLUP_ID = 9;

export const WATER_FILL_MATERIALS = mapKeys(
  [{ id: WATER_ID, name: translate('common.waterFill.materialTypeValues.water') }],
  'id',
);

export const WATER_FILL_UOM = mapKeys(
  [
    {
      id: GALLON_ID,
      name: translate('common.waterFill.unitOfMeasureValues.gallon'),
    },
    {
      id: FILLUP_ID,
      name: translate('common.waterFill.unitOfMeasureValues.fillUp'),
    },
  ],
  'id',
);
