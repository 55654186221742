import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import CityWorksGeneralSettingsForm from '../../forms/CityWorksGeneralSettingsForm';

export default function CityWorksGeneralSettingsSection() {

    const {dataService} = useSelector((state: AppState) => state.vendors.cityWorks);
      const initialValues = dataService;
      return <CityWorksGeneralSettingsForm onSubmit={()=>{}} initialValues={initialValues} />;
}
