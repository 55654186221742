import { useDispatch } from 'react-redux';

import { TypedResolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import TransferContainersModal from './TransferContainersModal';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadContainerFacilities } from 'src/fleet/ducks';

interface Props {
  closeModal: () => void;
  onSubmit: (values: any) => void;
}

const TransferContainersModalResolver: React.FC<Props> = ({ closeModal, onSubmit }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const containerFacilities = useSelector(state => state.fleet.containerFacilities.containerFacilities);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [];

    if (!containerFacilities.length) {
      dependencies.push(loadContainerFacilities(vendorId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={TransferContainersModal}
      successProps={{ closeModal, onSubmit }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default TransferContainersModalResolver;
