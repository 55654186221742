import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { VehicleTrackingFeatureProperties } from '../utils';

export const TRAVEL_PATH_VEHICLE_BREADCRUMBS_SOURCE = 'travelPathVehicleBreadcrumbsSource';
export const TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_SOURCE = 'travelPathVehicleBreadcrumbsPointsSource';
export const TRAVEL_PATH_VEHICLE_BREADCRUMBS_LINE_LAYER = 'travelPathVehicleBreadcrumbsLineLayer';
export const TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_LAYER = 'travelPathVehicleBreadcrumbsPointsLayer';
export const TRAVEL_PATH_VEHICLE_BREADCRUMBS_ARROWS_LAYER = 'travelPathVehicleBreadcrumbsArrowsLayer';

interface Props {
  geoJSONForLine: GeoJSON.FeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>;
  geoJsonForPoints?: GeoJSON.FeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>;
}

const TravelPathVehicleBreadcrumbGLSource: FC<Props> = ({ geoJSONForLine, geoJsonForPoints }) => {
  return (
    <>
      <Source type="geojson" id={TRAVEL_PATH_VEHICLE_BREADCRUMBS_SOURCE} data={geoJSONForLine} tolerance={0.0001}>
        <Layer
          id={TRAVEL_PATH_VEHICLE_BREADCRUMBS_LINE_LAYER}
          type="line"
          source={TRAVEL_PATH_VEHICLE_BREADCRUMBS_SOURCE}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          paint={{
            'line-color': ['get', 'color'],
            'line-width': ['interpolate', ['exponential', 1], ['zoom'], 10, 3, 30, 6],
            'line-blur': 0.5,
            'line-opacity': ['get', 'opacity'],
          }}
        />
      </Source>
      {geoJsonForPoints && (
        <Source
          type="geojson"
          id={TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_SOURCE}
          data={geoJsonForPoints}
          tolerance={0.0001}
        >
          <Layer
            id={TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_LAYER}
            type="circle"
            minzoom={15}
            source={TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_SOURCE}
            paint={{
              'circle-radius': ['interpolate', ['exponential', 1], ['zoom'], 10, 5, 30, 10],
              'circle-color': ['get', 'color'],
              'circle-opacity': ['get', 'opacity'],
            }}
          />
          <Layer
            id={TRAVEL_PATH_VEHICLE_BREADCRUMBS_ARROWS_LAYER}
            type="symbol"
            minzoom={15}
            source={TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_SOURCE}
            layout={{
              'symbol-placement': 'point',
              'icon-allow-overlap': true,
              'icon-image': ['case', ['has', 'bearing'], 'arrow', 'dot'],
              'icon-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 0.8, 30, 1.5],
              'icon-rotate': ['get', 'bearing'],
            }}
            paint={{
              'icon-color': 'white',
              'icon-opacity': ['get', 'opacity'],
            }}
          />
        </Source>
      )}
    </>
  );
};

export default memo(TravelPathVehicleBreadcrumbGLSource);
