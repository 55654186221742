import styled from 'styled-components';
import { sizeMapper } from '../../../utils/styles';

export const RouteTemplateBuilderModalForm = styled.form`
  padding: ${sizeMapper('no medium')};
`;

export const RouteTemplateBuilderSpacer = styled.div`
  flex: 1;
`;
