import { Component } from 'react';
import { connect } from 'react-redux';
import { findIndex, map, size } from 'lodash-es';
import { push } from 'connected-react-router';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Message,
  ModalSection,
  Panel,
  PanelSection,
  PanelSectionGroup,
} from '../../../core/components/styled';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadUnassignedWeightTickets, deleteWeightTicket } from '../../ducks/index';
import { Modal } from '../../../core/components';
import { TODAY_FORMATTED, LIMIT_PER_PAGE, PAGE } from '../../../core/constants';
import { UnassignedWeightTicketsForm } from '../forms';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { WeightTicketCard } from '../pages/weightTicketsPageSections/index';
import { WeightTicketsCards, WeightTicketsTableContainer } from '../styled/UnassignedWeightTickets';
import { WeightTicketsPreviewImageModal } from '../modal';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  vendorId: number;
  isLoading: boolean;
  location: any;
  match: any;
  push: any;
  token: string;
  unassignedWeightTickets: any[];
  loadUnassignedWeightTickets: (
    token: string,
    vendorId: number,
    startDate: string,
    endDate: string,
    searchTerm: string,
    page: number,
  ) => Promise<any>;
  deleteWeightTicket: (token: string, fileId: number, vendorId: number) => Promise<any>;
  closeModal: () => void;
  selectedFile: (fileId: number, fileName: string) => void;
}

interface State {
  isImagePreviewModalOpen: boolean;
  isShowBorder: boolean;
  weightTicketDetails: any;
  selectedImageId: number;
  selectedFileName: string;
  selectedImage: any[];
  unassignedWeightTicketList: any;
}

class UnassignedWeightTicketsModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isImagePreviewModalOpen: false,
      isShowBorder: false,
      weightTicketDetails: {},
      selectedImageId: 0,
      selectedFileName: '',
      selectedImage: [],
      unassignedWeightTicketList:
        size(props.unassignedWeightTickets) && props.unassignedWeightTickets.slice(0, LIMIT_PER_PAGE),
    };
  }

  componentDidMount = () => {
    this.loadBatchedUnassignedWeightTickets();
  };

  componentDidUpdate(prevProps: Props) {
    const { location, loadUnassignedWeightTickets, unassignedWeightTickets, vendorId, token } = this.props;
    if (prevProps.unassignedWeightTickets !== unassignedWeightTickets) {
      const unassignedWeightTicketList =
        size(unassignedWeightTickets) && unassignedWeightTickets.slice(0, LIMIT_PER_PAGE);
      this.setState({ unassignedWeightTicketList });
      this.loadBatchedUnassignedWeightTickets();
    }
    if (prevProps.location.search !== location.search) {
      const { searchTerm, startDate, endDate, page } = getQueryParams(location.search);
      loadUnassignedWeightTickets(
        token,
        vendorId,
        startDate || TODAY_FORMATTED,
        endDate || TODAY_FORMATTED,
        searchTerm,
        page,
      ).then(data => {
        if (prevProps.unassignedWeightTickets !== data.unassignedWeightTickets) {
          this.loadBatchedUnassignedWeightTickets();
        }
      });
    }
  }

  deleteUnassignedWeightTicket = async (id: number) => {
    const { deleteWeightTicket, vendorId, token } = this.props;
    if (!(await confirm(translate('autoDispatch.alertMessages.confirmDeleteWeightTicket')))) {
      return;
    }
    await deleteWeightTicket(token, id, vendorId);
  };

  openImagePreviewModal = (fileId: number) => {
    const { unassignedWeightTickets } = this.props;
    this.setState({
      isImagePreviewModalOpen: true,
      weightTicketDetails: unassignedWeightTickets.filter(weightTicket => weightTicket.id === fileId),
    });
  };

  getSelectedImage = (fileId: number, fileName: string) => {
    const selectedIndex = findIndex(this.props.unassignedWeightTickets, { id: fileId });
    this.setState({
      selectedImageId: fileId,
      selectedFileName: fileName,
      selectedImage: [selectedIndex],
    });
  };

  closeImagePreviewModal = () => {
    this.setState({
      isImagePreviewModalOpen: false,
    });
  };

  onSubmit = (data: any) => {
    const { token, loadUnassignedWeightTickets, vendorId } = this.props;
    const { from, to } = data.date;
    loadUnassignedWeightTickets(token, vendorId, from, to, data.searchTerm, PAGE);
  };

  assignSelectedFile = (fileId: number, fileName: string) => {
    const { selectedFile } = this.props;
    selectedFile(fileId, fileName);
  };

  loadBatchedUnassignedWeightTickets = () => {
    setTimeout(() => {
      const { unassignedWeightTickets } = this.props;
      const { unassignedWeightTicketList } = this.state;
      const hasMore = unassignedWeightTicketList.length + 1 < unassignedWeightTickets.length;
      this.setState((prev, props) => ({
        unassignedWeightTicketList: props.unassignedWeightTickets.slice(
          0,
          prev.unassignedWeightTicketList.length + LIMIT_PER_PAGE,
        ),
      }));
      if (hasMore) this.loadBatchedUnassignedWeightTickets();
    }, 0);
  };

  render() {
    const { isLoading, closeModal } = this.props;
    const {
      isImagePreviewModalOpen,
      weightTicketDetails,
      selectedImageId,
      selectedFileName,
      unassignedWeightTicketList,
    } = this.state;
    const selectedIndex = findIndex(unassignedWeightTicketList, { id: selectedImageId });
    return (
      <Modal
        title={translate('autoDispatch.unassignedWeightTickets')}
        onClose={closeModal}
        padding="medium"
        size="large"
      >
        <ModalSection>
          <Panel>
            <PanelSectionGroup isLoading={isLoading}>
              <UnassignedWeightTicketsForm onSubmit={this.onSubmit} />

              <PanelSection vertical dark padding="sMedium">
                <WeightTicketsTableContainer>
                  {size(unassignedWeightTicketList) > 0 ? (
                    <WeightTicketsCards>
                      {map(unassignedWeightTicketList, (weightTicket: any, index: number) => (
                        <WeightTicketCard
                          key={index}
                          isModal
                          isSelected={selectedIndex === index}
                          weightTicket={weightTicket}
                          openImagePreviewModal={this.openImagePreviewModal}
                          deleteUnassignedWeightTicket={this.deleteUnassignedWeightTicket}
                          getSelectedImage={this.getSelectedImage}
                        />
                      ))}
                    </WeightTicketsCards>
                  ) : (
                    <Message padding="sMedium">{translate('autoDispatch.noUnassignedWeightTickets')}</Message>
                  )}
                </WeightTicketsTableContainer>
              </PanelSection>
              {size(unassignedWeightTicketList) > 0 && (
                <UseIsMobileWidthView
                  render={isMobile => (
                    <ButtonSet align={isMobile ? 'center' : 'right'}>
                      <Button
                        disabled={!selectedImageId}
                        color="primary"
                        onClick={() => this.assignSelectedFile(selectedImageId, selectedFileName)}
                      >
                        {translate('tooltips.select')}
                      </Button>
                    </ButtonSet>
                  )}
                />
              )}
            </PanelSectionGroup>
          </Panel>
          {isImagePreviewModalOpen && (
            <WeightTicketsPreviewImageModal
              onSelectClick={this.assignSelectedFile}
              weightTicketDetails={weightTicketDetails[0]}
              closeModal={this.closeImagePreviewModal}
              isModalView
            />
          )}
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.fleet.unassignedWeightTickets.isLoading,
  unassignedWeightTickets: state.fleet.unassignedWeightTickets.unassignedWeightTickets,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadUnassignedWeightTickets,
  deleteWeightTicket,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnassignedWeightTicketsModal));
