import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { RoutePlannerDriverStatus } from 'src/routes/interfaces/routePlanner/RoutePlannerDriverStatus';
import {
  loadDriverStatus as doLoadDriverStatus,
  updateDriversStatus as doUpdateDriversStatus,
} from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerDriverStatus/START_LOAD';
const COMPLETE_LOAD = 'routePlannerDriverStatus/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerDriverStatus/FAIL_LOAD';
const START_SAVE = 'routePlannerDriverStatus/START_SAVE';
const COMPLETE_SAVE = 'routePlannerDriverStatus/COMPLETE_SAVE';
const FAIL_SAVE = 'routePlannerDriverStatus/FAIL_SAVE';
const RESET = 'routePlannerDriverStatus/RESET';

interface State {
  isLoading: boolean;
  driverStatus: RoutePlannerDriverStatus | null;
  updatedDriverStatuses: RoutePlannerDriverStatus[];
}

const initialState: State = {
  isLoading: false,
  driverStatus: null,
  updatedDriverStatuses: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          driverStatus: action.driverStatus,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          driverStatus: null,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    // on complete kee the updatedDriverStatuses updated with the new status, if any of them is already there update it otherwise add it
    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
          updatedDriverStatuses: action.driverStatusUpdated.reduce(
            (acc: RoutePlannerDriverStatus[], driverStatus: RoutePlannerDriverStatus) => {
              const index = acc.findIndex(
                driverStatusUpdated => driverStatusUpdated.driverId === driverStatus.driverId,
              );
              if (index === -1) {
                return [...acc, driverStatus];
              }
              return update(acc, {
                [index]: {
                  $merge: driverStatus,
                },
              });
            },
            state.updatedDriverStatuses,
          ),
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });
    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (driverStatus: RoutePlannerDriverStatus) => ({
  type: COMPLETE_LOAD,
  driverStatus,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (driverStatusUpdated: RoutePlannerDriverStatus[]) => ({
  type: COMPLETE_SAVE,
  driverStatusUpdated,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const resetRoutePlannerDriverStatus = () => ({
  type: RESET,
});

export const loadRoutePlannerDriverStatus =
  (vendorId: number, driverId: number, date: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRoutePlannerDriverStatusPromise = doLoadDriverStatus(vendorId, driverId, date);
    loadRoutePlannerDriverStatusPromise
      .then((driverStatus: RoutePlannerDriverStatus) => {
        dispatch(completeLoad(driverStatus));
      })
      .catch(() => {
        dispatch(failLoad());
      });
    return loadRoutePlannerDriverStatusPromise;
  };

export const updateRoutePlannerDriversStatus =
  (vendorId: number, driverStatuses: RoutePlannerDriverStatus[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const updateRoutePlannerDriversStatusPromise = doUpdateDriversStatus(vendorId, driverStatuses);
    updateRoutePlannerDriversStatusPromise
      .then(() => {
        dispatch(completeSave(driverStatuses));
      })
      .catch(() => {
        dispatch(failSave());
      });
    return updateRoutePlannerDriversStatusPromise;
  };
