import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, WrappedFieldInputProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';
import insightsFormInitialValuesSelector from '../services/insightsFormInitialValues';
import { AppState } from '../../store';

interface ComponentProps extends RouteComponentProps {
  input: WrappedFieldInputProps;
  placeholder?: string;
  withPlaceholder?: boolean;
  label?: string;
  withLabel?: boolean;
  dropdownProps?: any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  activeVehicles?: any[];
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class VehiclesForReportDropdown extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      activeVehicles = [],
      dropdownProps,
    } = this.props;

    const activeVehiclesOptions = activeVehicles.map(({ name, id }) => ({
      label: name,
      value: id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('vehicles.vehicle') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('vehicles.vehicle') : undefined)}
        component={Dropdown}
        options={activeVehiclesOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const activeVehicles = state.insights.reportingDetails.vehicles;
  const initialValues = insightsFormInitialValuesSelector(ownProps.location.search, null, ownProps.input.value) as any;

  return {
    initialValues: { ...initialValues, vehicleId: initialValues.vehicleId || ownProps.input.value },
    activeVehicles,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'vehicleDropdown',
      enableReinitialize: true,
    })(VehiclesForReportDropdown),
  ),
);
