import styled, { css } from 'styled-components';
import { theme } from '../../../core/styles';

interface RouteTemplateBuilderDaysCounterWrapperProps {
  forServiceActions?: boolean;
}

export const RouteTemplateBuilderDaysCounterWrapper = styled.div<RouteTemplateBuilderDaysCounterWrapperProps>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: white;
  border: 1px solid ${theme.grayLight};
  padding: 4px;
  margin: 0;
  text-align: left;

  table {
    td,
    th {
      padding: 0 4px;
    }
  }

  ${props =>
    props.forServiceActions &&
    css`
      border: none;
      padding: 0;
      margin: 12px -2px;

      table {
        border-collapse: collapse;

        td,
        th {
          &:first-child {
            padding: 0 4px 0 0;
          }
        }
      }
    `}
`;
