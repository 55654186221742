import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { getExcludedFiltersIds } from 'src/common/utils/filters';
import { DatePicker, SimpleInput, TypedField } from 'src/core/components';
import { Button, Grid, GridColumn, ToggleIcon } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { ToggleContainer } from 'src/routes/components/styled';
import { DispatchBoardFilterContainer } from 'src/routes/components/styled/DispatchBoard';
import { JobTypes } from 'src/routes/constants';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { AppState } from 'src/store';
import { isDateValidValidator, isRequired } from 'src/utils/services/validator';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { GroupsMultiSelect, RouteStatusesMultiSelect, ServiceZonesMultiSelect, SupervisorsMultiSelect } from 'src/routes/components';

export const DISPATCH_BOARD_TARGET_FORM_NAME = 'dispatchBoardTargetForm';

export interface DispatchBoardTargetFormFilters {
  targetDate: Date | string;
  targetRouteStatuses: number[];
  targetSearchTerm: string;
  targetServiceZones: number[];
  targetSupervisors: number[];
  targetGroupIds: number[],
}
interface Props {
  filtersPreferences?: FilterSetting[];
  serviceZones?: ServiceZone[];
  supervisorExperienceEnabled?: boolean;
  supervisors?: Supervisor[];
}

interface State {
  isExpanded: boolean;
}

class DispatchBoardTargetForm extends PureComponent<Props & InjectedFormProps<DispatchBoardTargetFormFilters, Props>, State> {
  state = { isExpanded: false };

  render() {
    const { handleSubmit, supervisorExperienceEnabled, serviceZones, filtersPreferences, supervisors } = this.props;
    const { isExpanded } = this.state;

    const onToggle = () => {
      this.setState({ isExpanded: !this.state.isExpanded });
    };

    const excludedServiceZonesFiltersIds = getExcludedFiltersIds(
      serviceZones,
      filtersPreferences,
      FILTER_SERVICE_ZONE_ID,
    ) as number[];

    const excludedSupervisorsFiltersIds = getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID);

    return (
      <form onSubmit={handleSubmit}>
        <Grid multiLine>
          <GridColumn size="4/12" padding="no xSmall no no">
            <Field
              name="targetDate"
              component={DatePicker}
              label={translate('common.date')}
              validate={[isRequired, isDateValidValidator]}
            />
          </GridColumn>

          <GridColumn size="5/12">
            <Field
              name="targetRouteStatuses"
              component={RouteStatusesMultiSelect}
              withLabel
              multiSelectProps={{ normalizeValues: Number }}
            />
          </GridColumn>

          <GridColumn align="right" size="3/12">
            <ToggleContainer id={`toggle-filter-${JobTypes.target}-button`} onClick={onToggle}>
              <ToggleIcon size={10} isOpen={isExpanded} icon={isExpanded ? 'remove' : 'add'} />
              {` ${translate('common.filter')}`}
            </ToggleContainer>
          </GridColumn>

          <DispatchBoardFilterContainer isOpen={isExpanded}>
            <GridColumn size={supervisorExperienceEnabled ? '4/12' : '8/12'} padding="no xSmall no no">
              <TypedField
                name="targetServiceZones"
                component={ServiceZonesMultiSelect}
                props={{
                  excludeServiceZonesIds: excludedServiceZonesFiltersIds,
                  placeholder: translate('routes.allServiceZones'),
                  multiSelectProps: {
                    normalizeValues: Number,
                    fitContentWidth: true,
                  },
                }}
              />
            </GridColumn>

            {supervisorExperienceEnabled && (
              <GridColumn size="4/12" padding="no xSmall">
                <TypedField
                  name="targetSupervisors"
                  component={SupervisorsMultiSelect}
                  props={{
                    excludeSupervisorsIds: excludedSupervisorsFiltersIds,
                    placeholder: translate('routes.allSupervisors'),
                    multiSelectProps: {
                      normalizeValues: Number,
                      fitContentWidth: true,
                    },
                  }}
                />
              </GridColumn>
            )}

            <GridColumn size="4/12" padding="no xSmall">
              <TypedField
                name="targetGroupIds"
                component={GroupsMultiSelect}
                props={{
                  withPlaceholder: true,
                  includeNoneOption: true,
                  multiSelectProps: {
                    canCheckAll: true,
                    fitContentWidth: true,
                  },
                }}
              />
            </GridColumn>
          </DispatchBoardFilterContainer>

          <GridColumn size="9/12" padding="no xSmall no no">
            <Field
              name="targetSearchTerm"
              component={SimpleInput}
              placeholder={translate('common.search')}
              margin="no"
              padding="xSmall small"
              withBorder
              withBoxShadow
            />
          </GridColumn>

          <GridColumn size="3/12">
            <Button fullWidth color="primary" id="target-apply-button">
              {translate('common.apply')}
            </Button>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
  serviceZones: state.routes.serviceZones.serviceZones,
  supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
  supervisors: state.routes.supervisors.supervisors,
});

export default connect(mapStateToProps)(
  reduxForm<DispatchBoardTargetFormFilters, Props>({
    form: DISPATCH_BOARD_TARGET_FORM_NAME,
  })(DispatchBoardTargetForm),
);
