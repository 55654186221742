import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { GEO_FENCE_OPTIONS_SAT } from 'src/core/constants';
import { theme } from 'src/core/styles';

interface Props {
  isSatellite: boolean;
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
  mapZoom: number;
}

export const TRAVEL_PATH_GEO_FENCES_SOURCE = 'travelPathGeoFences';
export const TRAVEL_PATH_GEO_FENCES_LAYER = 'travelPathGeoFences';
export const TRAVEL_PATH_GEO_FENCES_OUTLINE_LAYER = 'travelPathGeoFencesOutline';
export const TRAVEL_PATH_GEO_FENCES_NAME_LAYER = 'travelPathGeoFencesName';

const TravelPathGeoFencesGLSource: FC<Props> = ({ isSatellite, geoJSON, mapZoom }) => {
  const labelPlacement = mapZoom < 14 ? 'point' : 'line';
  const allowOverlap = mapZoom >= 14;

  return (
    <Source type="geojson" id={TRAVEL_PATH_GEO_FENCES_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id={TRAVEL_PATH_GEO_FENCES_LAYER}
        type="fill"
        source={TRAVEL_PATH_GEO_FENCES_SOURCE}
        paint={{
          'fill-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'fill-opacity': 0.1,
        }}
        layout={{}}
      />
      <Layer
        id={TRAVEL_PATH_GEO_FENCES_OUTLINE_LAYER}
        type="line"
        source={TRAVEL_PATH_GEO_FENCES_SOURCE}
        paint={{
          'line-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'line-width': 1,
        }}
        layout={{}}
      />
      <Layer
        id={TRAVEL_PATH_GEO_FENCES_NAME_LAYER}
        type="symbol"
        source={TRAVEL_PATH_GEO_FENCES_SOURCE}
        paint={{
          'text-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'text-halo-color': isSatellite ? '#000' : '#fff',
          'text-halo-width': 2,
        }}
        layout={{
          'symbol-avoid-edges': true,
          'symbol-placement': labelPlacement,
          'text-allow-overlap': allowOverlap,
          'text-ignore-placement': true,
          'text-field': ['get', 'name'],
          'text-keep-upright': true,
          'text-offset': [0, 1],
          'text-rotation-alignment': 'map',
          visibility: mapZoom >= 10 ? 'visible' : 'none',
          'text-size': allowOverlap ? ['interpolate', ['linear'], ['zoom'], 14, 12, 18, 16] : 12,
        }}
      />
    </Source>
  );
};

export default memo(TravelPathGeoFencesGLSource);
