import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { ContainerManagementForm } from '../forms';
import { ContainerManagementSettings } from '../../interfaces/ContainerManagement';
import { saveContainerManagementSettings, resetContainerManagementSettings } from '../../ducks';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  isLoading: boolean;
  isSaving: boolean;
  resetContainerManagementSettings: DuckAction<typeof resetContainerManagementSettings>;
  containerManagementSettings?: ContainerManagementSettings;
  saveContainerManagementSettings: DuckFunction<typeof saveContainerManagementSettings>;
  vendorId: number;
}

class ContainerManagementModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetContainerManagementSettings();
  }

  onSubmit = (containerManagementSettings: ContainerManagementSettings) => {
    const { vendorId, saveContainerManagementSettings, closeModal } = this.props;
    saveContainerManagementSettings(vendorId, containerManagementSettings)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.containerManagementSettingsSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.containerManagementSettingsSavedError')));
  };

  render() {
    const { containerManagementSettings, isLoading, isSaving, closeModal } = this.props;

    return (
      <Modal padding="no" size="small">
        <ModalSection padding="medium medium small">
          <ModalTitle>{translate('vendors.featureCodes.containerManagement')}</ModalTitle>
        </ModalSection>
        <ModalSection isLoading={isLoading || isSaving}>
          <ContainerManagementForm
            onSubmit={this.onSubmit}
            initialValues={containerManagementSettings}
            onCancel={closeModal}
          />
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.vendors.containerManagementSettings.isLoading,
  isSaving: state.vendors.containerManagementSettings.isSaving,
  containerManagementSettings: state.vendors.containerManagementSettings.containerManagementSettings,
});

const mapDispatchToProps = {
  saveContainerManagementSettings,
  resetContainerManagementSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerManagementModal);
