import { useSelector, useDispatch } from 'react-redux';
import { currentVendorId } from '../../services/currentVendorSelector';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { loadCityWorksConfigurationErrors,loadCityWorksConfiguration,loadCityWorksproblemtypes, loadCityWorksServiceConfiguration } from 'src/vendors/ducks/cityWorksConfigurations';
import CityWorksConfigurationModal from './CityWorksConfigurationModal';
import { loadEquipmentSizes, loadEquipmentTypes, loadServiceTypes } from 'src/common/ducks';
import { loadVehicleTypes } from 'src/fleet/ducks';
import { loadWasteMaterialTypes } from 'src/common/ducks/wasteMaterialTypes';
import { loadPickupTypes } from 'src/routes/ducks/pickupTypes';


interface Props {
  closeModal: () => void;
}

export default function CityWorksConfigurationModalResolver(props: Props) {
  const { closeModal } = props;
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    const dependencies: Promise<any>[] = [];
    dependencies.push(
        loadCityWorksConfigurationErrors(vendorId)(dispatch),
        loadCityWorksConfiguration(vendorId)(dispatch),
        loadCityWorksServiceConfiguration(vendorId)(dispatch),
        loadEquipmentTypes()(dispatch),
        loadVehicleTypes()(dispatch),
        loadWasteMaterialTypes()(dispatch),
        loadCityWorksproblemtypes(vendorId)(dispatch),
        loadServiceTypes()(dispatch),
        loadPickupTypes(null)(dispatch),
        loadEquipmentSizes()(dispatch)
  
    );
    return Promise.all(dependencies);
  };
  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={CityWorksConfigurationModal}
      successProps={{ closeModal }}
    />
  );
}
