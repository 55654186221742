import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentUserIdSelector, currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { FLEET, STREET_SWEEPER, SNOW_PLOW, WASTE_AUDIT } from '../../constants/insightsGroupIds';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadInsightDashboardPreferences } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import InsightDashboardPreferencesPage from './InsightDashboardPreferencesPage';
import translate from '../../../core/services/translate';
import { loadFiltersSettings } from 'src/common/ducks';

interface Props extends RouteComponentProps {
  vendorId: number;
  userId?: string;
  loadInsightDashboardPreferences: DuckFunction<typeof loadInsightDashboardPreferences>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
}

class InsightDashboardPreferencesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    let insightGroupIds: any[];
    const { vendorId, loadInsightDashboardPreferences, loadFiltersSettings, location, userId } = this.props;
    const params = getQueryParams(location.search);

    switch (params.source) {
      case 'insightsfleet':
        insightGroupIds = [[FLEET, STREET_SWEEPER, SNOW_PLOW]];
        break;
      case 'wasteaudit':
        insightGroupIds = [WASTE_AUDIT];
        break;
      default:
        insightGroupIds = [];
    }

    const dependencies: Promise<any>[] = [loadInsightDashboardPreferences(vendorId, insightGroupIds)];
    if (userId) dependencies.push(loadFiltersSettings(vendorId, userId));

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('insights.insightsSettings')}</DocumentTitle>
        <Resolver
          successComponent={InsightDashboardPreferencesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login),
});

const mapDispatchToProps = {
  loadInsightDashboardPreferences,
  loadFiltersSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightDashboardPreferencesPageResolver);
