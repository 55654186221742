import React, { MouseEvent } from 'react';

import { JobTypes } from '../../../../../constants/jobTypes';
import { DispatchBoardPaneBackButton, DispatchBoardPaneBackButtonIcon } from '../../../../styled';

interface BackButtonProps {
  onClick: (event: MouseEvent) => void;
  type?: JobTypes;
  padding?: string;
}

const BackButton = (props: BackButtonProps) => (
  <DispatchBoardPaneBackButton onClick={props.onClick} padding={props.padding} id={`job-${props.type}-back-button`}>
    <DispatchBoardPaneBackButtonIcon icon="back" />
  </DispatchBoardPaneBackButton>
);

export default BackButton;
