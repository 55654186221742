import update from 'immutability-helper';
import { map } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';
import { getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { TODAY_FORMATTED } from 'src/core/constants';
import store from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { DASHBOARD_FILTER_FORM_MAPBOX } from '../constants/dashboardFilter';
import { getPersistentFiltersMapbox } from '../services/persistentFilterStorage';

//actions
const COMPLETE_UPDATE = 'dashboard/initialFilterFormValuesMapbox/COMPLETE_UPDATE';
const RESET = 'dashboard/initialFilterFormValuesMapbox/RESET';

// Initial state
const initialState = {
  initialFilterFormValuesMapbox: {
    date: TODAY_FORMATTED,
    activeTab: 'vehicles',
  },
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case COMPLETE_UPDATE:
      return update(state, {
        $merge: {
          initialFilterFormValuesMapbox: action.initialFilterFormValuesMapbox,
        },
      });
    case RESET:
      return update(state, {
        $merge: {
          initialFilterFormValuesMapbox: { date: TODAY_FORMATTED },
        } as any,
      });
    default:
      return state;
  }
};

// Action creators
const completeUpdate = (initialFilterFormValues: any) => ({
  type: COMPLETE_UPDATE,
  initialFilterFormValues,
});

export const getDashboardMapboxInitialFormValues = (
  queryString: string,
  vehicleFiltersPreferencesIds: number[],
  supervisorsFiltersPreferencesIds: number[],
) => {
  const {
    searchTerm,
    date = TODAY_FORMATTED,
    vehicleTypeIds,
    routeStatusTypeIds,
    supervisors,
  } = getQueryParams(queryString);

  const isSelectedDateToday = (TODAY_FORMATTED as Date | string) === (date as Date | string);

  return {
    date,
    activeTab: 'vehicles',
    includeInactive: !isSelectedDateToday,
    searchTerm: searchTerm || '',
    vehicleTypeIds: vehicleTypeIds ? map(vehicleTypeIds.toString().split(','), Number) : vehicleFiltersPreferencesIds,
    routeStatusTypeIds: (routeStatusTypeIds && map(routeStatusTypeIds.toString().split(','), Number)) || [],
    supervisorIds: supervisors ? map(supervisors.toString().split(','), Number) : supervisorsFiltersPreferencesIds,
  };
};

export const updateDashboardMapboxInitialFilterFormValues =
  (reset: boolean, vendorId: number, filtersPreferences?: FilterSetting[], shouldApplyPersistentFilters?: boolean) =>
  (dispatch: Dispatch) => {
    const state = store.getState() as any;
    const mainFormSelector = formValueSelector(DASHBOARD_FILTER_FORM_MAPBOX);
    const persistentFilters = reset || !vendorId ? {} : getPersistentFiltersMapbox(vendorId);
    const vehicleTypesFiltersPreferencesIds = getVehicleFiltersPreferencesIds(filtersPreferences);
    let initialVehicleTypesValues;
    if (shouldApplyPersistentFilters) {
      initialVehicleTypesValues = {
        vehicleTypeIds: persistentFilters.vehicleTypeIds,
      };
    } else {
      initialVehicleTypesValues = {
        vehicleTypeIds: mainFormSelector(state, 'vehicleTypeIds') || vehicleTypesFiltersPreferencesIds,
      };
    }
    let supervisorIdsValues;
    if (shouldApplyPersistentFilters) {
      supervisorIdsValues = {
        supervisorIds: persistentFilters.supervisorIds,
      };
    } else {
      supervisorIdsValues = {
        supervisorIds: mainFormSelector(state, 'supervisorIds'),
      };
    }

    const date = shouldApplyPersistentFilters ? persistentFilters.date : mainFormSelector(state, 'date');
    const searchTerm = shouldApplyPersistentFilters
      ? persistentFilters.searchTerm
      : mainFormSelector(state, 'searchTerm') || '';
    delete persistentFilters.date;

    const isSelectedDateToday = (TODAY_FORMATTED as Date | string) === (date as Date | string);

    const initialFilterFormValues = {
      date,
      ...initialVehicleTypesValues,
      ...supervisorIdsValues,
      includeInactive: !isSelectedDateToday,
      vendorId,
      searchTerm,
    };

    const defaultFilterFormValues = {
      ...initialFilterFormValues,
      ...persistentFilters,
    };
    dispatch(completeUpdate(shouldApplyPersistentFilters ? defaultFilterFormValues : initialFilterFormValues));
  };

export const resetDashboardMapboxInitialFilterFormValues = () => ({
  type: RESET,
});
