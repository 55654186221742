import React from 'react';

import { ActionButtonTooltip, PopoverWrapper } from '../../core/components';
import { Popover, Text } from '../../core/components/styled';
import translate from '../../core/services/translate';

interface Props {
  routeRunDate?: string;
}

const OverrideDate: React.FC<Props> = ({ routeRunDate }) => {
  return (
    <PopoverWrapper
      size="medium"
      color="black"
      triggerButton={<ActionButtonTooltip size="sMedium" icon="dateOverride" />}
      width="14px"
      margin="no xSmall no xSmall"
      popoverContent={
        <Popover>
          <Text weight="light">
            <b>{translate('common.dateOverride')}</b>: {routeRunDate}
          </Text>
        </Popover>
      }
    />
  );
};

export default OverrideDate;
