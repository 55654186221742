import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadRollOffContainerManagementSettings as doLoadRollOffContainerManagementSettings,
  saveRollOffContainerManagementSettings as doSaveRollOffContainerManagementSettings,
} from '../services/rollOffContainerManagement';
import { RollOffContainerManagementSettings } from '../interfaces/RollOffContainerManagement';

// Actions
const START_LOAD = 'vendors/rollOffContainerManagement/START_LOAD';
const COMPLETE_LOAD = 'vendors/rollOffContainerManagement/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/rollOffContainerManagement/FAIL_LOAD';
const START_SAVE = 'vendors/rollOffContainerManagement/START_SAVE';
const COMPLETE_SAVE = 'vendors/rollOffContainerManagement/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/rollOffContainerManagement/FAIL_SAVE';
const RESET = 'vendors/rollOffContainerManagement/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  rollOffContainerManagementSettings: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rollOffContainerManagementSettings: action.rollOffContainerManagementSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rollOffContainerManagementSettings: RollOffContainerManagementSettings) => ({
  type: COMPLETE_LOAD,
  rollOffContainerManagementSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadRollOffContainerManagementSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRollOffContainerManagementSettingsPromise = doLoadRollOffContainerManagementSettings(vendorId);
  loadRollOffContainerManagementSettingsPromise
    .then(rollOffContainerManagementSettings => dispatch(completeLoad(rollOffContainerManagementSettings)))
    .catch(() => dispatch(failLoad()));

  return loadRollOffContainerManagementSettingsPromise;
};

export const saveRollOffContainerMangementSettings =
  (vendorId: number, rollOffContainerManagementSettings: RollOffContainerManagementSettings) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveRollOffContainerMangementSettingsPromise = doSaveRollOffContainerManagementSettings(
      vendorId,
      rollOffContainerManagementSettings,
    );
    saveRollOffContainerMangementSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveRollOffContainerMangementSettingsPromise;
  };

export const resetRollOffContainerManagementSettings = () => ({
  type: RESET,
});
