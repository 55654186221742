import { map, sortBy, upperFirst } from 'lodash-es';
import { camelize } from 'humps';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { BULK, PORTABLE_TOILET, PORTABLE_RESTROOM_TRAILER } from '../../common/constants';

export const getEquipmentSize = (equipmentSize: string, equipmentName: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

export const getEquipmentSizeTranslationKey = (equipmentSize: string, serviceTypeName: string, equipmentName: string) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

const transformLoadServiceTypes = (serviceTypes: any[]) =>
  map(serviceTypes, serviceType => ({
    ...serviceType,
    name: translate(createTranslationKey(serviceType.technicalName, 'common.serviceTypes')),
    equipmentTypes: map(serviceType.equipmentTypes, equipmentType => ({
      ...equipmentType,
      name: translate(createTranslationKey(equipmentType.technicalName, 'common.equipmentTypes')),
      equipmentSizes: sortBy(
        map(equipmentType.equipmentSizes, equipmentSize => {
          const size = getEquipmentSize(equipmentSize.technicalName, equipmentType.name);

          return {
            ...equipmentSize,
            size,
            name: translate(
              getEquipmentSizeTranslationKey(equipmentSize.technicalName, serviceType.name, equipmentType.name),
              { size },
            ),
          };
        }),
        'size',
      ),
    })),
  }));

export default transformLoadServiceTypes;
