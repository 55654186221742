import { httpInsightsReporting } from '../../core/services/http';
import { transformLoadVehicleInformation } from './transformLoadVehicleInformation';

export const loadVehiclesList = (
  date: Date | string,
  includeInactive?: boolean,
  vehicleTypeIds?: string,
  supervisorIds?: string,
  searchTerm?: string,
  driverSearchTerm?: string,
  cancelToken?: any,
) =>
  httpInsightsReporting
    .get('/dashboard/vehicles/list', {
      params: { date, includeInactive, vehicleTypeIds, supervisorIds, searchTerm, driverSearchTerm },
      cancelToken,
    })
    .then(response => response.data);

export const loadVehicleInformation = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/info`, {
      params: { date },
    })
    .then(response => transformLoadVehicleInformation(response.data));

export const loadVehicleStatus = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/status`, {
      params: { date },
    })
    .then(response => response.data);

export const loadVehicleBreadcrumbs = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/breadcrumbs/fleet`, {
      params: { date },
    })
    .then(response => response.data);

export const loadVehicleSafetyData = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/insights/summary`, {
      params: { date },
    })
    .then(response => response.data);

export const loadVehicleInsightDetails = (vehicleId?: number, insightTypeId?: number, date?: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/insights`, {
      params: { insightTypeId, date },
    })
    .then(response => response.data);

export const loadVehicleInspection = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/issues`, {
      params: { date },
    })
    .then(response => response.data);

export const loadRouteData = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/route-status`, {
      params: { date },
    })
    .then(response => response.data);

export const loadDeviceData = (vehicleId: number) =>
  httpInsightsReporting.get(`/dashboard/vehicles/${vehicleId}/devices`).then(response => response.data);

export const loadVehicleBreadcrumbsByRoutes = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/breadcrumbs/routes`, {
      params: { date },
    })
    .then(response => response.data);
