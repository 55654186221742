import React from 'react';
import { reduxForm, InjectedFormProps, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, GridColumn, Button } from '../../../core/components/styled';
import { TypedField, MultiSelect } from '../../../core/components';
import { VehicleIssuesFormElement } from '../styled/VehiclesPage';
import { AppState } from '../../../store';
import translate from '../../../core/services/translate';

export interface VehicleIssuesFormValues {
  statusIds: number[];
  driverNames: string[];
  issueSubcategoryIds: number[];
}

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<VehicleIssuesFormValues, PropsWithoutReduxForm>;

const VehicleIssuesForm: React.FC<Props> = ({ form, handleSubmit }) => {
  const dispatch = useDispatch();

  const issueStatuses = useSelector((state: AppState) => state.fleet.vehicleIssues.issueStatuses);
  const drivers = useSelector((state: AppState) => state.fleet.vehicleIssues.drivers);
  const issueSubcategories = useSelector((state: AppState) => state.fleet.vehicleIssues.issueSubcategories);

  const resetFormValues = () => {
    dispatch(reset(form));
  };

  return (
    <VehicleIssuesFormElement onSubmit={handleSubmit}>
      <Grid alignRight>
        <GridColumn width="150px" padding="no small no no">
          <TypedField
            name="statusIds"
            component={MultiSelect}
            props={{
              margin: 'no',
              placeholder: translate('vehicles.statuses'),
              options: issueStatuses.map(status => ({
                label: status.name,
                value: status.id,
              })),
              formatText(selectedOptions: any[], allSelected: boolean) {
                return allSelected
                  ? translate('vehicles.allStatuses')
                  : translate('vehicles.xStatusesSelected', { selected: selectedOptions.length });
              },
              normalizeValues(value: string | number) {
                return +value;
              },
            }}
          />
        </GridColumn>

        <GridColumn width="150px" padding="no small no no">
          <TypedField
            name="driverNames"
            component={MultiSelect}
            props={{
              margin: 'no',
              placeholder: translate('vehicles.drivers'),
              options: drivers.map(driver => ({
                label: driver,
                value: driver,
              })),
              formatText(selectedOptions: any[], allSelected: boolean) {
                return allSelected
                  ? translate('vehicles.allDrivers')
                  : translate('vehicles.xDriversSelected', { selected: selectedOptions.length });
              },
            }}
          />
        </GridColumn>

        <GridColumn width="150px" padding="no small no no">
          <TypedField
            name="issueSubcategoryIds"
            component={MultiSelect}
            props={{
              margin: 'no',
              placeholder: translate('vehicles.issues'),
              options: issueSubcategories.map(category => ({
                label: category.name,
                value: category.id,
              })),
              formatText(selectedOptions: any[], allSelected: boolean) {
                return allSelected
                  ? translate('vehicles.allIssues')
                  : translate('vehicles.xIssuesSelected', { selected: selectedOptions.length });
              },
              normalizeValues(value: string | number) {
                return +value;
              },
            }}
          />
        </GridColumn>

        <GridColumn width="100px" align="right" padding="no small no no">
          <Button text color="primary" onClick={resetFormValues}>
            {translate('vehicles.clearFilters')}
          </Button>
        </GridColumn>
      </Grid>
    </VehicleIssuesFormElement>
  );
};

export default reduxForm<VehicleIssuesFormValues, PropsWithoutReduxForm>({
  form: 'vehicleIssues',
  enableReinitialize: true,
})(VehicleIssuesForm);
