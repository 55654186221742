import React from 'react';
import { Redirect, Switch } from 'react-router';

import { AuthRoute } from '../../account/components';
import { Page } from '../../common/components';
import {
  AgreementsPageResolver,
  CompliancePageResolver,
  ContactsPageResolver,
  EquipmentsPageResolver,
  OverviewPageResolver,
  ServiceAreasPageResolver,
} from './pages';

const HaulerProfile = () => (
  <Page>
    <Switch>
      <AuthRoute exact path="/hauler-profile/agreements" component={AgreementsPageResolver} />
      <AuthRoute exact path="/hauler-profile/compliance" component={CompliancePageResolver} />
      <AuthRoute exact path="/hauler-profile/contacts" component={ContactsPageResolver} />
      <AuthRoute exact path="/hauler-profile/equipments" component={EquipmentsPageResolver} />
      <AuthRoute exact path="/hauler-profile/overview" component={OverviewPageResolver} />
      <AuthRoute exact path="/hauler-profile/services" component={ServiceAreasPageResolver} />
      <Redirect to="/hauler-profile/overview" />
    </Switch>
  </Page>
);

export default HaulerProfile;
