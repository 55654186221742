import React, { useState } from 'react';

import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm, Field, formValueSelector } from 'redux-form';
import { map } from 'lodash-es';
import moment from 'moment';

import { AppState } from 'src/store';
import { ACTIVE, AVAILABLE, UNAVAILABLE } from 'src/fleet/constants/status';
import { Button, Grid, GridColumn, PanelSection, Text } from '../../../core/components/styled';
import { DatePicker, Dropdown, TypedField } from '../../../core/components';
import { Driver } from 'src/fleet/interfaces/Driver';
import { EXPLICIT_STATUSES, SERVICE_STATUSES } from '../../constants/status';
import { Facility } from 'src/common/interfaces/Facility';
import { getOperationalFacilityWithNoneOptions } from '../utils/vehiclesPageHooks';
import { PageContent } from 'src/common/components/styled';
import translate from '../../../core/services/translate';

export const RESOURCES_ASSIGN_FORM = 'resourcesAssignForm';

const statusOptions = map(EXPLICIT_STATUSES, status => ({
  label: status.name,
  value: status.id,
}));

const serviceStatusesOptions = SERVICE_STATUSES.map(serviceStatus => ({
  label: serviceStatus.name,
  value: serviceStatus.id,
}));

interface FormValues {
  operationalFacility: number;
  serviceStatus: string;
  status: string;
}

interface PropsWithoutReduxForm {
  assignResources: (
    newStatus?: boolean,
    newServiceStatus?: boolean,
    newFacility?: number,
    resourceUnavailableEndDate?: Date | string,
    resourceUnavailableStartDate?: Date | string,
  ) => void;
  checkedResources: Driver[];
  deleteResources: () => void;
  facilities: Facility[];
  isSourceVehicles?: boolean;
  resourceUnavailableEndDate?: Date | string;
  resourceUnavailableStartDate?: Date | string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const ResourcesAssignForm: React.FC<Props> = ({
  assignResources,
  checkedResources,
  deleteResources,
  facilities,
  isSourceVehicles,
  resourceUnavailableEndDate,
  resourceUnavailableStartDate,
}) => {
  const [newStatus, setNewStatus] = useState<boolean>();
  const [newServiceStatus, setNewServiceStatus] = useState<boolean>();
  const [isNewServiceUnavailable, setIsNewServiceUnavailable] = useState<boolean>();
  const [newFacility, setNewFacility] = useState<number>();

  const shouldOrderFacilities = true;
  const operationalFacilityOptions = getOperationalFacilityWithNoneOptions(facilities, shouldOrderFacilities);

  const checkedResourcesSize = checkedResources.length;
  const checkedAvailableResourcesSize = checkedResources.filter(resource => resource.isAvailable).length;
  const checkedUnavailableResourcesSize = checkedResources.filter(resource => !resource.isAvailable).length;

  const onSetNewStatus = (event: any) => {
    setNewStatus(event === ACTIVE);
  };

  const onSetNewServiceStatus = (event: any) => {
    setNewServiceStatus(event ? event === AVAILABLE : undefined);
    setIsNewServiceUnavailable(event === UNAVAILABLE);
  };

  const onSetNewFacility = (event: any) => {
    setNewFacility(Number(event));
  };

  const resourceSelectedLabel = isSourceVehicles ? 'vehicleSelected' : 'resourceSelected';
  const resourcesSelectedLabel = isSourceVehicles ? 'vehiclesSelected' : 'resourcesSelected';

  return (
    <form>
      <PageContent marginBottom="0">
        <PanelSection vertical>
          <Grid margin="no no small no">
            <GridColumn size="12/12">
              <Text size="xLarge">
                {checkedResourcesSize}{' '}
                {translate(
                  checkedResourcesSize === 1 ? `common.${resourceSelectedLabel}` : `common.${resourcesSelectedLabel}`,
                )}
                :
              </Text>
              <Text margin="no no no xSmall" color="success">
                {checkedAvailableResourcesSize} {translate('common.available')},
              </Text>
              <Text margin="no no no xSmall" color="alert">
                {checkedUnavailableResourcesSize} {translate('common.unavailable')}
              </Text>
            </GridColumn>
          </Grid>
          <Grid>
            <GridColumn size="2/12">
              <TypedField
                name="status"
                component={Dropdown}
                props={{
                  isClearable: true,
                  margin: 'no',
                  placeholder: translate('common.statusSetNew'),
                  options: statusOptions,
                  menuPosition: 'fixed',
                }}
                onChange={onSetNewStatus}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <TypedField
                name="serviceStatus"
                component={Dropdown}
                props={{
                  isClearable: true,
                  margin: 'no',
                  options: serviceStatusesOptions,
                  placeholder: translate('common.serviceStatusSetNew'),
                  menuPosition: 'fixed',
                }}
                onChange={onSetNewServiceStatus}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <TypedField
                name="operationalFacility"
                component={Dropdown}
                props={{
                  isClearable: true,
                  margin: 'no',
                  options: operationalFacilityOptions,
                  placeholder: translate('common.assignToOperationalFacility'),
                  menuPosition: 'fixed',
                }}
                onChange={onSetNewFacility}
              />
            </GridColumn>

            <GridColumn size="5/12" align="right">
              <Button
                color="primary"
                type="button"
                margin="no no no small"
                onClick={() =>
                  assignResources(
                    newStatus,
                    newServiceStatus,
                    newFacility,
                    resourceUnavailableEndDate,
                    resourceUnavailableStartDate,
                  )
                }
                id="assign-items-button"
              >
                {translate('common.saveChanges')}
              </Button>

              <Button
                color="primary"
                line
                type="button"
                margin="no no no small"
                onClick={deleteResources}
                id="delete-items-button"
              >
                {translate(isSourceVehicles ? 'common.deleteVehicles' : 'common.deleteResources')}
              </Button>
            </GridColumn>
          </Grid>

          {isNewServiceUnavailable && (
            <Grid margin="no no small no">
              <GridColumn size="2/12"></GridColumn>
              <GridColumn size="2/12" padding="xSmall small no small">
                <Field
                  name="resourceUnavailableStartDate"
                  component={DatePicker}
                  disabledDays={[
                    {
                      before: moment().toDate(),
                      after: resourceUnavailableEndDate
                        ? moment(resourceUnavailableEndDate).subtract(1, 'days').toDate()
                        : undefined,
                    },
                  ]}
                  margin="no"
                  label={translate('common.unavailableFrom')}
                  isClearable
                  verticalAlign="top"
                />
              </GridColumn>
              <GridColumn size="2/12" padding="xSmall small no small">
                <Field
                  name="resourceUnavailableEndDate"
                  component={DatePicker}
                  disabledDays={[
                    {
                      before: resourceUnavailableStartDate
                        ? moment(resourceUnavailableStartDate).toDate()
                        : moment().toDate(),
                      after: undefined,
                    },
                  ]}
                  margin="no"
                  label={translate('common.unavailableTo')}
                  isClearable
                  verticalAlign="top"
                />
              </GridColumn>
            </Grid>
          )}
        </PanelSection>
      </PageContent>
    </form>
  );
};

const formSelector = formValueSelector(RESOURCES_ASSIGN_FORM);

const mapStateToProps = (state: AppState) => ({
  resourceUnavailableEndDate: formSelector(state, 'resourceUnavailableEndDate'),
  resourceUnavailableStartDate: formSelector(state, 'resourceUnavailableStartDate'),
});

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: RESOURCES_ASSIGN_FORM,
    enableReinitialize: true,
  })(ResourcesAssignForm),
);
