import styled, { css } from 'styled-components';

interface Props {
  id?: string | number;
  right?: boolean;
  selectedItem?: number;
}

export const AccessContainer = styled.div<Props>`
  height: 250px;
  width: 200px;
  padding: 5px;
  margin-top: 5px;
  overflow: auto;
  border: 2px solid ${props => props.theme.grayDarker};

  ${props =>
    props.right &&
    css`
      margin-left: auto;
    `};
`;

export const DistrictItem = styled.h6<Props>`
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 12px;
  text-align: left;
  padding: 10px;
  margin: none;
  &:hover {
    cursor: pointer;
  }
  ${props =>
    props.selectedItem === props.id &&
    css`
      background: ${props => props.theme.brandPrimary};
      color: ${props => props.theme.brandSecondary};
    `};
`;
