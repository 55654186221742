import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { PaymentInfo } from 'src/finance/interfaces/BillDetails';
import { date } from 'src/utils/services/formatter';

type Props = PaymentInfo & {
};

const PaymentInfoTableRow = ({  ...info }: Props) => (
  <TableRow
    id={`payment-info-${info.paymentNo}`}
    height={TABLE_ROW_HEIGHT_LARGE}
    >
    <TableCell width="12%" padding="defaultCellVertical no">
      <Text block>{info.paymentNo || '–'}</Text>
    </TableCell>
    <TableCell
      id={`payment-info-${info.paymentNo}-date`}
      justifyContent="center"
      vertical
      width="12%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>{info.paymentDate ? date(info.paymentDate) : '–'}</Text>
    </TableCell>
    <TableCell
      id={`payment-info-${info.paymentNo}-method`}
      justifyContent="center"
      vertical
      width="12%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>
        {info.paymentMethod || '–'}
      </Text>
    </TableCell>
    <TableCell
      id={`payment-info-${info.paymentNo}-amount`}
      justifyContent="center"
      vertical
      width="12%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>
        ${info.paymentAmount || '–'}
      </Text>
    </TableCell>
  </TableRow>
);

export default PaymentInfoTableRow;

