import { http } from '../../core/services/http';

export const loadNotificationEmailTemplates = (
  vendorId: number,
  vehicleTypeId: number,
  notificationTypeId: number,
  pickupExceptionTypeId?: number,
) =>
  http
    .get(`/vendors/${vendorId}/notificationEmailTemplates`, {
      params: {
        vehicleTypeId,
        vendorEmailTemplateNotificationTypeId: notificationTypeId,
        pickupExceptionTypeId,
      },
    })
    .then(response => response.data);

export const createNotificationEmailTemplate = (vendorId: number, emailTemplate: any) =>
  http
    .post(`/vendors/${vendorId}/notificationEmailTemplates`, emailTemplate)
    .then(response => response.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const updateNotificationEmailTemplate = (vendorId: number, emailTemplate: any) =>
  http
    .put(`/vendors/${vendorId}/notificationEmailTemplates`, emailTemplate)
    .then(response => response.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
