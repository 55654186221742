import React from 'react';

import { TableRow, TableCell } from 'src/core/components/styled';
import { RouteTemplateBuilderService } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';

type Props = RouteTemplateBuilderService;

export default function RouteTemplateBuilderWorkSessionListViewTableRow({ id, latitude, longitude }: Props) {
  return (
    <TableRow>
      <TableCell width="17%" padding="defaultCellVertical small">
        {id}
      </TableCell>

      <TableCell width="17%" padding="defaultCellVertical small">
        {latitude}
      </TableCell>

      <TableCell width="17%" padding="defaultCellVertical small">
        {longitude}
      </TableCell>

      <TableCell width="13%" padding="defaultCellVertical small defaultCellVertical no"></TableCell>
    </TableRow>
  );
}
