import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { pulse, COLOR_DEFAULT } from '../../styles';
import { mapper, sizeMapper, colorMapper } from '../../../utils/styles';

interface Props {
  align?: string;
  animated?: boolean;
  padding?: string;
}

const getMessageBackgroundColor = (props: any) =>
  props.color === COLOR_DEFAULT || props.color === undefined ? 'transparent' : transparentize(0.9, colorMapper(props));

const Message = styled.div<Props>`
  padding: ${props => sizeMapper(props.padding, 'no')};
  background-color: ${getMessageBackgroundColor};
  line-height: 22px;
  font-weight: ${props => props.theme.fontWeightLight};
  color: ${colorMapper};

  ${props =>
    props.animated &&
    css`
      animation: ${pulse} 0.3s ease-in-out 2;
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${mapper(props.align, { left: 'left', center: 'center', right: 'right' }, 'left')};
    `};
`;

export default Message;
