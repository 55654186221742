import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../store';
import translate from '../../../../core/services/translate';
import { Table } from '../../../../core/components';
import { VehicleTableWrapper } from '../../styled/VehiclesPage';
import { vehicleMaintenanceCells } from './vehiclePageCells';
import VehicleMaintenanceStatusTableRow from './VehicleMaintenanceStatusTableRow';
import { Vehicle } from '../../../interfaces/Vehicle';
import VehiclePageSection from './VehiclePageSection';
import { PM_STATUS_PAST_DUE } from '../../../constants/preventativeMaintenance';

const VehicleMaintenanceStatusSection: React.FC = () => {
  const vehicle: Vehicle | undefined = useSelector((state: AppState) => state.fleet.vehicle.vehicle);
  const vehiclePMStatus = useSelector((state: AppState) => state.fleet.preventativeMaintenance.vehiclePMStatus);
  const vehiclePMStatusLoading = useSelector(
    (state: AppState) => state.fleet.preventativeMaintenance.vehiclePMStatusLoading,
  );
  const vehicleMilestoneCompleteIsLoading = useSelector(
    (state: AppState) => state.fleet.preventativeMaintenance.vehicleMilestoneCompleteIsLoading,
  );

  if (!vehiclePMStatus) {
    return null;
  }

  return (
    <VehiclePageSection
      title={translate('vehicles.vehicleMaintenanceStatusHeader')}
      sectionLoading={vehiclePMStatusLoading || vehicleMilestoneCompleteIsLoading}
      subtitle={
        translate(
          `vehicles.preventativeMaintenanceTypes.${vehiclePMStatus.preventativeMaintenanceStatusType.technicalName}`,
        )
      }
      subtitleColor={vehiclePMStatus.preventativeMaintenanceStatusType.id === PM_STATUS_PAST_DUE ? 'alert' : undefined}
      content={
        <VehicleTableWrapper>
          <Table
            cells={vehicleMaintenanceCells}
            rows={[vehiclePMStatus]}
            rowComponent={VehicleMaintenanceStatusTableRow}
            rowProps={{
              vehicleId: vehicle && vehicle.id,
              currentMileage: vehicle && vehicle.mileage,
              currentEngineHours: vehicle && vehicle.engineHours,
            }}
          />
        </VehicleTableWrapper>
      }
    />
  );
};

export default VehicleMaintenanceStatusSection;
