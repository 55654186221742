import { forEach } from 'lodash-es';

function getNestingLevelOfCoordinates(coordinates: any[]): number {
  let nestingLevel = 0;

  function checkNestingLevel(arr: any[], currentLevel: number) {
    currentLevel++;
    if (Array.isArray(arr[0])) {
      checkNestingLevel(arr[0], currentLevel);
    } else {
      nestingLevel = Math.max(nestingLevel, currentLevel);
    }

    currentLevel--;
  }

  checkNestingLevel(coordinates, 0);

  return nestingLevel;
}
export const getPolygonsFromGeoFenceCoordinates = (coordinates: any) => {
  const polygons: { id: number; coordinates: number[][]; history: any[]; isNew: boolean }[] = [];
  const nestingLevel = getNestingLevelOfCoordinates(coordinates);
  forEach(coordinates, (polyGroup, index) => {
    let coords = [];
    if (nestingLevel === 5)
      // polygon
      coords = polyGroup[0][0];
    else if (nestingLevel === 4)
      // multiPolygon
      coords = polyGroup[0];

    polygons.push({
      id: Number(index),
      coordinates: coords,
      history: [coords],
      isNew: false,
    });
  });
  return polygons;
};
