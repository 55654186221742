import translate from 'src/core/services/translate';

export const ALL_ID = 1;
export const ENABLED_ID = 2;
export const DISABLED_ID = 3;

export const RATE_STATUSES = [
  { id: ALL_ID, name: translate('common.all') },
  { id: ENABLED_ID, name: translate('common.enabled') },
  { id: DISABLED_ID, name: translate('common.disabled') },
];

export const NEW_RATE_ACCOUNTING_CODE_ID = -1;

export const RATE_CONFIGURATION_SERVCE_TYPE_ID_SELECTED_COOKIE = 'rateConfigurationServiceTypeIdSelected';
