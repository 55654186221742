import styled from 'styled-components';
import translate from '../services/translate';
import Modal, { ModalProps } from './Modal';
import { Button } from './styled';

const actionsHeight = 70;

const Content = styled.div<{ hasActions: boolean }>``;

const Actions = styled.div`
  height: ${actionsHeight}px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const MobileModal: React.FC<ModalProps & { additionalActions?: JSX.Element }> = ({
  additionalActions,
  onClose,
  children,
  ...props
}) => {
  return (
    <Modal overflow="visible" padding="small small no" {...props}>
      <Content hasActions={!!onClose}>{children}</Content>
      {onClose && (
        <Actions>
          {additionalActions}
          <Button onClick={onClose} color="primary">
            {translate('common.close')}
          </Button>
        </Actions>
      )}
    </Modal>
  );
};
