import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { Modal } from '../../../core/components';
import { SignDocumentForm } from '../forms';
import translate from '../../../core/services/translate';

interface ComponentProps {
  closeModal(...args: any[]): any;
  submitDocument(...args: any[]): any;
  selfCertificationDocumentUrl?: string;
}

interface Props extends ComponentProps {
  isSubmitting: boolean;
  userEmail: string;
  vendorName: string;
}

const SignDocumentModal: React.FC<Props> = ({
  closeModal,
  userEmail,
  isSubmitting,
  selfCertificationDocumentUrl,
  submitDocument,
  vendorName,
}) => (
  <Modal
    title={translate('haulerProfile.complianceDocument.selfCertification')}
    onClose={closeModal}
    verticalSize="large"
    padding="medium xLarge"
    isLoading={isSubmitting}
  >
    <SignDocumentForm
      initialValues={{ vendor: vendorName, email: userEmail }}
      onSubmit={submitDocument}
      selfCertificationDocumentUrl={selfCertificationDocumentUrl}
    />
  </Modal>
);

const mapStateToProps = (state: AppState) => ({
  isSubmitting: state.haulerProfile.compliance.isLoading,
  vendorName: state.vendors.defaultVendor.defaultVendor.name,
  userEmail: state.account.login.user.email,
});

export default connect(mapStateToProps)(SignDocumentModal);
