import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';

import { AppState } from 'src/store';
import { createUrl } from '../../../utils/services/queryParams';
import { Dropdown, PanelSearch, TypedField } from '../../../core/components';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { ON_HOLD, ON_HOLD_LABEL } from 'src/common/constants/accountStatuses';
import { SUSPENDED_ACCOUNT, SUSPENDED_LABEL } from 'src/common/constants';
import suspendedLocationsFormInitialValuesSelector from 'src/customers/services/suspendedLocationsFormInitialValuesSelector';
import translate from 'src/core/services/translate';

interface ComponentProps extends RouteComponentProps {
  push: any;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class SuspendedLocationsSearchForm extends PureComponent<Props> {
  onSearchTermChange = debounce((event, searchTerm) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { searchTerm, page: undefined }));
  }, 800);

  onAccountStatusTypeIdChange = debounce((event, accountStatusTypeId) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { accountStatusTypeId, page: undefined }));
  }, 800);

  render() {
    const accountStatusTypeIdOptions = [
      { label: SUSPENDED_LABEL, value: SUSPENDED_ACCOUNT },
      { label: ON_HOLD_LABEL, value: ON_HOLD },
    ];

    return (
      <form>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="8/12" verticalAlign="center">
              <TypedField
                component={PanelSearch}
                onChange={this.onSearchTermChange}
                name="searchTerm"
                props={{
                  margin: 'no',
                }}
              />
            </GridColumn>
            <GridColumn size="4/12">
              <TypedField
                component={Dropdown}
                onChange={this.onAccountStatusTypeIdChange}
                name="accountStatusTypeId"
                props={{
                  margin: 'no',
                  options: accountStatusTypeIdOptions,
                  placeholder: translate('common.status'),
                  isClearable: true,
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps) => {
  const initialValues = suspendedLocationsFormInitialValuesSelector(ownProps.location.search);
  return {
    initialValues,
  };
};

const mapDispatchToProps = { push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, ComponentProps>({
      form: 'suspendedLocationsSearch',
      enableReinitialize: true,
    })(SuspendedLocationsSearchForm),
  ),
);
