import React from 'react';

import { SortableElement } from 'react-sortable-hoc';

import { DragHandle, Switch } from '../../../core/components';
import { TableCell, TableRow } from '../../../core/components/styled';
import { TypedField } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  vendorWasteAuditConfigurationIndex?: number;
  wasteAuditTypeOptionOrderNo: number;
  technicalName: string;
}

const WasteAuditFormTableRow: React.FC<Props> = ({
  vendorWasteAuditConfigurationIndex,
  wasteAuditTypeOptionOrderNo,
  technicalName,
}) => (
  <TableRow>
    <TableCell padding="small" width="5%" align="center">
      <DragHandle />
    </TableCell>
    <TableCell width="55%">{translate(createTranslationKey(technicalName, 'vendors.vendorWasteAuditTypes'))}</TableCell>
    <TableCell width="20%">
      <TypedField
        name={`wasteAuditConfiguration.${vendorWasteAuditConfigurationIndex}.vendorWasteAuditConfigurationTypes.${
          wasteAuditTypeOptionOrderNo - 1
        }.isEnabled`}
        component={Switch}
      />
    </TableCell>
    <TableCell width="20%" align="right">
      <TypedField
        name={`wasteAuditConfiguration.${vendorWasteAuditConfigurationIndex}.vendorWasteAuditConfigurationTypes.${
          wasteAuditTypeOptionOrderNo - 1
        }.allowPhotoUpload`}
        component={Switch}
      />
    </TableCell>
  </TableRow>
);

export default SortableElement(WasteAuditFormTableRow);
