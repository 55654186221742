import React from 'react';
import { camelize } from 'humps';
import { upperFirst } from 'lodash-es';

import { useSelector } from 'src/core/hooks/useSelector';
import { Button, Grid, GridColumn, Link, Text } from 'src/core/components/styled';
import LabeledDataView from 'src/core/components/LabeledDataView';
import DaysOfWeekDisplay from 'src/core/components/DaysOfWeekDisplay';
import {
  ServicePopupContent,
  ServicePopupDataWrap,
  RouteTemplateStatus,
  ServicePopupTitleWrapper,
  ServicePopupRouteEntry,
} from 'src/routes/components/styled/RouteTemplateBuilderMap';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { RTB_TEMPLATE_STATUSES_MAP, PUBLISHED, NEW } from 'src/routes/constants/routeTemplateBuilder';
import { RouteTemplateBuilderServiceDetails } from './RouteTemplateBuilderServiceDetails';
import { ActionButtonTooltip } from 'src/core/components';
import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';

const RouteTemplateBuilderServicePopup: React.FC = () => {
  const serviceContract = useSelector(state => state.routes.routeTemplateBuilder.selectedServiceContract);
  const service = useSelector(state => state.customers.service);

  const customerId = service?.service?.customerId;
  const locationId = service?.service?.locationId;
  const serviceContractId = service?.service?.id;

  const translateSize = (technicalName: string, name: string) => {
    const size = getEquipmentSize(technicalName, name);

    const sizeTranslated = translate(
      `common.equipmentSizes.x${upperFirst(
        camelize(technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
      )}`,
      {
        size: size ? getEquipmentSize(technicalName, name) : 0,
      },
    );
    return sizeTranslated;
  };

  if (!serviceContract) {
    return null;
  }

  return (
    <ServicePopupContent>
      <ServicePopupTitleWrapper>
        <Text weight="medium" size="medium">
          {serviceContract.locationName}
        </Text>

        <RouteTemplateStatus neutral margin="no no no xSmall">
          {!!serviceContract.routeTemplates.length ? translate('common.assigned') : translate('common.unassigned')}
        </RouteTemplateStatus>

        <Button text margin="no xSmall" padding="no">
          <Link
            id="edit-service-link"
            color="grayDark"
            to={`/customers/customers/${customerId}/location/${locationId}/service/${serviceContractId}`}
          >
            <ActionButtonTooltip icon="edit" tooltip="editService" />
          </Link>
        </Button>
      </ServicePopupTitleWrapper>

      <Text block size="small" margin="xxSmall no no">
        {serviceContract.locationAddress}
      </Text>

      <Grid multiLine>
        <GridColumn size="6/12" padding="xxSmall no">
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.customer')}
            value={serviceContract.customerName}
          />
        </GridColumn>
        <GridColumn size="6/12" padding="xxSmall no">
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('routeTemplateBuilder.wasteType')}
            value={translate(
              createTranslationKey(serviceContract.wasteMaterialType.technicalName, 'common.wasteTypes'),
            )}
          />
        </GridColumn>

        <GridColumn size="6/12" padding="xxSmall no">
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.equipmentType')}
            value={translate(
              createTranslationKey(serviceContract.equipmentType.technicalName, 'common.equipmentTypes'),
            )}
          />
        </GridColumn>
        <GridColumn size="6/12" padding="xxSmall no">
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.equipmentSize')}
            value={translateSize(serviceContract.equipmentSize.technicalName, serviceContract.equipmentSize.name)}
          />
        </GridColumn>

        <GridColumn size="6/12" padding="xxSmall no">
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('routeTemplateBuilder.daysOfService')}
          >
            <DaysOfWeekDisplay
              selectedDays={serviceContract.dayOfServiceIds}
              margin="no"
              daysOfWeekProps={{ margin: 'xxSmall no no' }}
              dayOfWeekProps={{ margin: 'no xSmall no no', size: 'xSmall' }}
            />
          </LabeledDataView>
        </GridColumn>
        <GridColumn size="6/12" padding="xxSmall no">
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.containerCount')}
            value={serviceContract.numberOfContainers}
          />
        </GridColumn>

        {!!serviceContract.pickUpTypes?.length && (
          <GridColumn size="6/12" padding="xxSmall no">
            <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('routes.pickupType')}>
              {serviceContract.pickUpTypes.map(pickUp => (
                <ServicePopupRouteEntry key={pickUp.id}>
                  {translate(createTranslationKey(pickUp.technicalName, 'routes.pickupTypes'))}
                </ServicePopupRouteEntry>
              ))}
            </LabeledDataView>
          </GridColumn>
        )}
        {!!serviceContract.binLocationMappingType && (
          <GridColumn size="6/12" padding="xxSmall no">
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('customers.pickupLocations.pickupLocationType')}
              value={translate(
                createTranslationKey(serviceContract.binLocationMappingType.technicalName, 'customers.pickupLocations'),
              )}
            />
          </GridColumn>
        )}

        {!!serviceContract.routeTemplates.length && (
          <GridColumn size="6/12" padding="xxSmall no">
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('routeTemplateBuilder.routes')}
            >
              {serviceContract.routeTemplates.map(template => (
                <ServicePopupRouteEntry key={template.id}>
                  {template.name}

                  {!template.isRouteTemplateEnabled && template.statusId !== NEW && (
                    <RouteTemplateStatus neutral margin="no no no xxSmall">
                      {translate('common.inactive')}
                    </RouteTemplateStatus>
                  )}

                  {template.statusId !== PUBLISHED && (
                    <RouteTemplateStatus statusId={template.statusId} margin="no no no xxSmall">
                      {RTB_TEMPLATE_STATUSES_MAP[template.statusId].name}
                    </RouteTemplateStatus>
                  )}
                </ServicePopupRouteEntry>
              ))}
            </LabeledDataView>
          </GridColumn>
        )}
      </Grid>

      <ServicePopupDataWrap>
        {!!serviceContract.serviceContractsAtLocation && (
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('routeTemplateBuilder.services')}
          >
            <RouteTemplateBuilderServiceDetails services={serviceContract.serviceContractsAtLocation} />
          </LabeledDataView>
        )}
      </ServicePopupDataWrap>
    </ServicePopupContent>
  );
};

export default RouteTemplateBuilderServicePopup;
