import React, { PureComponent } from 'react';

import {
  ActionButtonContainer,
  WeightTicketsCard,
  WeightTicketsCardContent,
  WeightTicketsCardFooter,
  WeightTicketDetails,
  WeightTicketsImageContainer,
  WeightTicketInfoContainer,
  WeightTicketOtherFileContainer,
  WeightTicketOtherFileIcon,
  WeightTicketOtherFileWrapper,
} from '../../styled/UnassignedWeightTickets';
import { ActionButtonTooltip } from '../../../../core/components';
import { Button, Text } from '../../../../core/components/styled';
import { dateAndTime } from '../../../../utils/services/formatter';
import { downloadFile } from 'src/core/services/downloadFile';
import translate from '../../../../core/services/translate';

interface Props {
  isSelected?: boolean;
  weightTicket: any;
  isModal: boolean;
  getSelectedImage: (fileId: number, fileName: string) => void;
  openImagePreviewModal: (fileId: number) => void;
  deleteUnassignedWeightTicket: (fileId: number) => Promise<any>;
}

class WeightTicketCard extends PureComponent<Props> {
  render() {
    const { isModal, isSelected, weightTicket, openImagePreviewModal, deleteUnassignedWeightTicket, getSelectedImage } =
      this.props;
    const size = isModal ? 'small' : 'medium';
    const isPreviewAvailable =
      weightTicket.fileExtension === '.png' ||
      weightTicket.fileExtension === '.jpg' ||
      weightTicket.fileExtension === '.jpeg'
        ? true
        : false;

    const downloadUrl = weightTicket?.rawUrl || weightTicket.url;

    return (
      <React.Fragment>
        <WeightTicketsCard isModal={isModal}>
          <WeightTicketsCardContent isModal={isModal}>
            {!!isPreviewAvailable ? (
              <WeightTicketsImageContainer
                isSelected={isSelected}
                isModal={isModal}
                src={weightTicket.thumbnailUrl}
                onClick={() => getSelectedImage(weightTicket.id, weightTicket.fileName)}
              />
            ) : (
              <WeightTicketOtherFileContainer
                isSelected={isSelected}
                isModal={isModal}
                onClick={() => getSelectedImage(weightTicket.id, weightTicket.fileName)}
              >
                <WeightTicketOtherFileWrapper isModal={isModal}>
                  <WeightTicketOtherFileIcon icon={weightTicket.fileExtension} />
                  <Text size="small">{translate('common.noPreview')}</Text>
                </WeightTicketOtherFileWrapper>
              </WeightTicketOtherFileContainer>
            )}
          </WeightTicketsCardContent>
          <WeightTicketsCardFooter>
            <WeightTicketInfoContainer>
              <WeightTicketDetails>
                <Text size={size}>{dateAndTime(weightTicket.timeStamp)}</Text>
              </WeightTicketDetails>
              <ActionButtonContainer>
                {!!isPreviewAvailable ? (
                  <Button
                    id="open-image-preview-modal"
                    text
                    color="primary"
                    padding="small"
                    onClick={() => openImagePreviewModal(weightTicket.id)}
                  >
                    <ActionButtonTooltip size="medium" icon="zoomIn" tooltip="clicktoEnlarge" />
                  </Button>
                ) : (
                  <Button
                    id="download-image"
                    text
                    color="primary"
                    padding="small"
                    onClick={() => downloadFile(downloadUrl, weightTicket.fileName)}
                  >
                    <ActionButtonTooltip icon="download" tooltip="downloadFile" />{' '}
                  </Button>
                )}
              </ActionButtonContainer>
              <ActionButtonContainer>
                <Button
                  id="delete-unassigned-weight-ticket"
                  text
                  color="primary"
                  padding="small"
                  onClick={() => deleteUnassignedWeightTicket(weightTicket.id)}
                >
                  <ActionButtonTooltip icon="delete" tooltip="deleteWeightTicket" />
                </Button>
              </ActionButtonContainer>
            </WeightTicketInfoContainer>
            <WeightTicketInfoContainer>
              {!!weightTicket.mobileNumber && (
                <Text size={size}>
                  {translate('common.phone')} : {weightTicket.mobileNumber}
                </Text>
              )}
            </WeightTicketInfoContainer>
            <WeightTicketInfoContainer>
              {!!weightTicket.driverName && (
                <Text size={size}>
                  {translate('drivers.driverName')} : {weightTicket.driverName}
                </Text>
              )}
            </WeightTicketInfoContainer>
          </WeightTicketsCardFooter>
        </WeightTicketsCard>
      </React.Fragment>
    );
  }
}

export default WeightTicketCard;
