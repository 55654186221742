import { Dispatch, AnyAction } from 'redux';
import { findIndex } from 'lodash-es';
import update from 'immutability-helper';

import { LoadsDumpedState, LoadsDumped } from '../interfaces/LoadsDumped';
import {
  createLoadDumped,
  updateLoadDumped,
  deleteLoadDumped as doDeleteLoadDumped,
  loadLoadDumped as doLoadLoadDumped,
  loadLoadsDumped as doLoadLoadsDumped,
  loadStreetSweeperMaterialTypeValues as doLoadStreetSweeperMaterialTypeValues,
  loadStreetSweeperUnitOfMeasureValues as doLoadStreetSweeperUnitOfMeasureValues,
  uploadLoadsDumpedImage as doUploadLoadsDumpedImage,
} from '../services/loadsDumped';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'routes/loadsDumped/START_LOAD';
const COMPLETE_LOAD = 'routes/loadsDumped/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/loadsDumped/FAIL_LOAD';
const START_SAVE = 'routes/loadsDumped/START_SAVE';
const COMPLETE_SAVE = 'routes/loadsDumped/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/loadsDumped/FAIL_SAVE';
const START_LOAD_LOAD_DUMPED = 'routes/loadsDumped/START_LOAD_LOAD_DUMPED';
const COMPLETE_LOAD_LOAD_DUMPED = 'routes/loadsDumped/COMPLETE_LOAD_LOAD_DUMPED';
const FAIL_LOAD_LOAD_DUMPED = 'routes/loadsDumped/FAIL_LOAD_LOAD_DUMPED';
const START_DELETE = 'routes/loadsDumped/START_DELETE';
const COMPLETE_DELETE = 'routes/loadsDumped/COMPLETE_DELETE';
const FAIL_DELETE = 'routes/loadsDumped/FAIL_DELETE';
const RESET = 'routes/loadsDumped/RESET';
const START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE = 'routes/loadsDumped/START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE';
const COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE = 'routes/loadsDumped/COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE';
const FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE = 'routes/loadsDumped/FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE';
const START_LOAD_STREET_SWEEPER_MATERIAL_TYPE = 'routes/loadsDumped/START_LOAD_STREET_SWEEPER_MATERIAL_TYPE';
const COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE = 'routes/loadsDumped/COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE';
const FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE = 'routes/loadsDumped/FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE';
const START_UPLOAD_LOADS_DUMPED_IMAGE = 'routes/weightTicket/START_UPLOAD_LOADS_DUMPED_IMAGE';
const COMPLETE_UPLOAD_LOADS_DUMPED_IMAGE = 'routes/weightTicket/COMPLETE_UPLOAD_LOADS_DUMPED_IMAGE';
const FAIL_UPLOAD_LOADS_DUMPED_IMAGE = 'routes/weightTicket/FAIL_UPLOAD_LOADS_DUMPED_IMAGE';

// Initial state
const initialState: LoadsDumpedState = {
  isDeleting: false,
  isLoading: false,
  isLoadingLoadDumped: false,
  isLoadingMaterialType: false,
  isLoadingUnitOfMeasure: false,
  isSaving: false,
  isUploadingLoadsDumpedImage: false,
  loadDumped: {} as LoadsDumped,
  loadsDumped: [],
  materialTypeValues: [],
  unitOfMeasureValues: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          loadsDumped: action.loadsDumped,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          loadsDumped: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          loadDumped: action.loadDumped,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          loadDumped: undefined,
        },
      });

    case START_LOAD_LOAD_DUMPED:
      return update(state, {
        $merge: {
          isLoadingLoadDumped: true,
        },
      });

    case COMPLETE_LOAD_LOAD_DUMPED:
      return update(state, {
        $merge: {
          isLoadingLoadDumped: false,
          loadDumped: action.loadDumped,
        },
      });

    case FAIL_LOAD_LOAD_DUMPED:
      return update(state, {
        $merge: {
          isLoadingLoadDumped: false,
          loadDumped: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const loadDumpedIndex = findIndex(state.loadsDumped, { id: action.loadDumpedId });
      return update(state, {
        loadsDumped: { $splice: [[loadDumpedIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: true,
        },
      });

    case COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: false,
          unitOfMeasureValues: action.unitOfMeasureValues,
        },
      });

    case FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: false,
          unitOfMeasureValues: undefined,
        },
      });

    case START_LOAD_STREET_SWEEPER_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: true,
        },
      });

    case COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: false,
          materialTypeValues: action.materialTypeValues,
        },
      });

    case FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: false,
          materialTypeValues: undefined,
        },
      });

    case START_UPLOAD_LOADS_DUMPED_IMAGE:
      return update(state, {
        $merge: {
          isUploadingLoadsDumpedImage: true,
        },
      });

    case COMPLETE_UPLOAD_LOADS_DUMPED_IMAGE:
      return update(state, {
        $merge: {
          isUploadingLoadsDumpedImage: false,
        },
      });

    case FAIL_UPLOAD_LOADS_DUMPED_IMAGE:
      return update(state, {
        $merge: {
          isUploadingLoadsDumpedImage: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (loadsDumped: LoadsDumped[]) => ({
  type: COMPLETE_LOAD,
  loadsDumped,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (loadDumped: LoadsDumped) => ({
  type: COMPLETE_SAVE,
  loadDumped,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadLoadDumped = () => ({
  type: START_LOAD_LOAD_DUMPED,
});

const completeLoadLoadDumped = (loadDumped: LoadsDumped) => ({
  type: COMPLETE_LOAD_LOAD_DUMPED,
  loadDumped,
});

const failLoadLoadDumped = () => ({
  type: FAIL_LOAD_LOAD_DUMPED,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (loadDumpedId: number) => ({
  type: COMPLETE_DELETE,
  loadDumpedId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startLoadStreetSweeperUnitOfMeasureValues = () => ({
  type: START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE,
});

const completeLoadStreetSweeperUnitOfMeasureValues = (unitOfMeasureValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE,
  unitOfMeasureValues,
});

const failLoadStreetSweeperUnitOfMeasureValues = () => ({
  type: FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE,
});

const startLoadStreetSweeperMaterialTypeValues = () => ({
  type: START_LOAD_STREET_SWEEPER_MATERIAL_TYPE,
});

const completeLoadStreetSweeperMaterialType = (materialTypeValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE,
  materialTypeValues,
});

const failLoadStreetSweeperMaterialType = () => ({
  type: FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE,
});

const startUploadLoadsDumpedImage = () => ({
  type: START_UPLOAD_LOADS_DUMPED_IMAGE,
});

const completeUploadLoadsDumpedImage = () => ({
  type: COMPLETE_UPLOAD_LOADS_DUMPED_IMAGE,
});

const failUploadLoadsDumpedImage = () => ({
  type: FAIL_UPLOAD_LOADS_DUMPED_IMAGE,
});

export const saveLoadDumped = (routeId: number, loadDumped: LoadsDumped, vendorId?: number) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveLoadDumpedPromise = loadDumped.id
    ? updateLoadDumped(loadDumped, vendorId)
    : createLoadDumped(routeId, loadDumped);

  saveLoadDumpedPromise
    .then((loadDumped: LoadsDumped) => dispatch(completeSave(loadDumped)))
    .catch(() => dispatch(failSave()));
  return saveLoadDumpedPromise;
};

export const loadLoadsDumped = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadLoadsDumpedPromise = doLoadLoadsDumped(vendorId, routeId);
  loadLoadsDumpedPromise.then(loadsDumped => dispatch(completeLoad(loadsDumped))).catch(() => dispatch(failLoad()));
  return loadLoadsDumpedPromise;
};

export const loadLoadDumped = (vendorId: number, loadDumpedId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadLoadDumped());
  const loadLoadDumpedPromise = doLoadLoadDumped(vendorId, loadDumpedId);
  loadLoadDumpedPromise
    .then((loadDumped: LoadsDumped) => dispatch(completeLoadLoadDumped(loadDumped)))
    .catch(() => dispatch(failLoadLoadDumped()));
  return loadLoadDumpedPromise;
};

export const deleteLoadDumped = (loadDumpedId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteLoadDumpedPromise = doDeleteLoadDumped(loadDumpedId);
  deleteLoadDumpedPromise.then(() => dispatch(completeDelete(loadDumpedId))).catch(() => dispatch(failDelete()));
  return deleteLoadDumpedPromise;
};

export const loadStreetSweeperUnitOfMeasureValues = () => (dispatch: Dispatch) => {
  dispatch(startLoadStreetSweeperUnitOfMeasureValues());
  const loadStreetSweeperUnitOfMeasureValuesPromise = doLoadStreetSweeperUnitOfMeasureValues();
  loadStreetSweeperUnitOfMeasureValuesPromise
    .then((unitOfMeasureValues: TechnicalType[]) =>
      dispatch(completeLoadStreetSweeperUnitOfMeasureValues(unitOfMeasureValues)),
    )
    .catch(() => dispatch(failLoadStreetSweeperUnitOfMeasureValues()));
  return loadStreetSweeperUnitOfMeasureValuesPromise;
};

export const loadStreetSweeperMaterialTypeValues = () => (dispatch: Dispatch) => {
  dispatch(startLoadStreetSweeperMaterialTypeValues());
  const loadStreetSweeperMaterialTypePromise = doLoadStreetSweeperMaterialTypeValues();
  loadStreetSweeperMaterialTypePromise
    .then((materialTypeValues: TechnicalType[]) => dispatch(completeLoadStreetSweeperMaterialType(materialTypeValues)))
    .catch(() => dispatch(failLoadStreetSweeperMaterialType()));
  return loadStreetSweeperMaterialTypePromise;
};

export const uploadLoadsDumpedImage = (file: File, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startUploadLoadsDumpedImage());

  const uploadLoadsDumpedImagePromise = doUploadLoadsDumpedImage(file, routeId);
  uploadLoadsDumpedImagePromise
    .then(loadsDumpedImage => {
      dispatch(completeUploadLoadsDumpedImage());
      return loadsDumpedImage;
    })
    .catch(() => dispatch(failUploadLoadsDumpedImage()));
  return uploadLoadsDumpedImagePromise;
};

export const resetLoadsDumped = () => ({
  type: RESET,
});
