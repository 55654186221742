import { map, get, isPlainObject } from 'lodash-es';

import { PICKUP_ISSUE_TYPES } from '../constants';
import { RouteIssues } from '../interfaces/Route';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadRouteIssues = (routeIssues: RouteIssues) => {
  const routeLocationPickupExceptions = get(routeIssues, 'routeLocationPickupExceptions');
  let pickupExceptions = [] as any;
  routeLocationPickupExceptions.forEach(routeLocationPickupException => {
    pickupExceptions = pickupExceptions.concat(get(routeLocationPickupException, 'pickupExceptions'));
  });

  if (isPlainObject(pickupExceptions)) {
    return map([pickupExceptions], routeIssue => ({
      ...routeIssue,
      pickupIssueReportedReasonTypeName: PICKUP_ISSUE_TYPES[routeIssue.pickupExceptionType.id].name,
      sourceTypeName: translate(createTranslationKey(routeLocationPickupExceptions[0].sourceType, 'common')),
      sourceName: routeLocationPickupExceptions[0].sourceName,
      vehicleTypeTechnicalName: routeLocationPickupExceptions[0].vehicleType
        ? routeLocationPickupExceptions[0].vehicleType.technicalName
        : undefined,
      routeId: routeIssues.routeId,
      routeLocationId: routeLocationPickupExceptions[0].routeLocationId,
      vehicleTypeId: routeLocationPickupExceptions[0].vehicleType
        ? routeLocationPickupExceptions[0].vehicleType.id
        : routeIssues.vehicleTypeId,
    }));
  }

  return map(pickupExceptions, routeIssue => ({
    ...routeIssue,
    pickupIssueReportedReasonTypeName: PICKUP_ISSUE_TYPES[routeIssue.pickupExceptionType.id]
      ? PICKUP_ISSUE_TYPES[routeIssue.pickupExceptionType.id].name
      : '-',
    sourceTypeName: translate(createTranslationKey(routeLocationPickupExceptions[0].sourceType, 'common')),
    sourceName: routeLocationPickupExceptions[0].sourceName,
    vehicleTypeTechnicalName: routeLocationPickupExceptions[0].vehicleType
      ? routeLocationPickupExceptions[0].vehicleType.technicalName
      : undefined,
    routeId: routeIssues.routeId,
    routeLocationId: routeLocationPickupExceptions[0].routeLocationId,
    vehicleTypeId: routeLocationPickupExceptions[0].vehicleType
      ? routeLocationPickupExceptions[0].vehicleType.id
      : routeIssues.vehicleTypeId,
  }));
};

export default transformLoadRouteIssues;
