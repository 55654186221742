import { FC } from 'react';

import { Button, ButtonSet } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

interface Props {
  isCityAlertEnabled?: boolean;
  isLocationAlertEnabled?: boolean;
  setIsCreateCityAlertModalVisible: () => void;
  setIsCreateLocationAlertModalVisible: () => void;
}

const LocationAndCityAlertsCreateAlert: FC<Props> = ({
  isCityAlertEnabled,
  isLocationAlertEnabled,
  setIsCreateCityAlertModalVisible,
  setIsCreateLocationAlertModalVisible,
}) => {
  return (
    <ButtonSet vertical margin="no">
      <>
        {isCityAlertEnabled && (
          <Button onClick={setIsCreateCityAlertModalVisible} color="primary" id="create-city-alert-button">
            {translate('vendors.cityAlerts.createCityAlert')}
          </Button>
        )}
        {isLocationAlertEnabled && (
          <Button
            onClick={setIsCreateLocationAlertModalVisible}
            color="primary"
            margin={isCityAlertEnabled ? 'small no no' : 'no'}
            id="create-location-alert-button"
          >
            {translate('vendors.cityAlerts.createLocationAlert')}
          </Button>
        )}
      </>
    </ButtonSet>
  );
};

export default LocationAndCityAlertsCreateAlert;
