import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadServices as doLoadServices, saveServices as doSaveServices } from '../services/services';

// Actions
const START_LOAD = 'haulerProfile/services/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/services/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/services/FAIL_LOAD';
const START_SAVE = 'haulerProfile/services/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/services/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/services/FAIL_SAVE';
const RESET = 'haulerProfile/services/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  services: undefined,
  isValidGusId: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          services: action.services,
          isValidGusId: true,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (services: any) => ({
  type: COMPLETE_LOAD,
  services,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadServices = (haulerId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadsSrvicesPromise = doLoadServices(haulerId);

  loadsSrvicesPromise.then(services => dispatch(completeLoad(services))).catch(() => dispatch(failLoad()));

  return loadsSrvicesPromise;
};

export const saveServices = (haulerId: number, data: any) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const saveServicesPromise = doSaveServices(haulerId, data);

  saveServicesPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveServicesPromise;
};

export const resetServices = () => ({
  type: RESET,
});
