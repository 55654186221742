import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { AcceptOpenDispatchesForm } from '../forms';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, ModalTitle } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { Modal } from '../../../core/components';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { NON_SCHEDULED, LIMIT_PER_PAGE } from '../../constants';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import translate from '../../../core/services/translate';
import { updateOpenDispatches } from '../../ducks';
import { responseMissedPickup } from 'src/fleet/ducks/openDispatches';

interface RouteParams {
  token: string;
}

interface ComponentProps extends RouteComponentProps<RouteParams> {
  itemId: number;
  pickupTypeId: number;
  requestedServiceDate: string;
  reasonCodes: ReasonCodeGroups;
  rubiconPONbr: string;
  serviceDate: Date | string;
  status: string;
  vendID?: string;
  daysInWeekSchedule?: number;
}

interface Props extends ComponentProps {
  file: any;
  isSaving: boolean;
  completedDate: string | Date;
  missedPickupReasonCodes: any[];
  note?: string;
  dayOfWeek: string[];
  reasonCode: string;
  userId?: string;
  vendorId?: number;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  onCancel(formPristine: boolean, isReload?: boolean): void;
  updateOpenDispatches: DuckFunction<typeof updateOpenDispatches>;
  responseMissedPickup: DuckFunction<typeof responseMissedPickup>;
}

class AcceptOpenDispatchesModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      file,
      itemId,
      completedDate,
      location: { search },
      match: {
        params: { token },
      },
      serviceDate,
      note,
      pickupTypeId,
      dayOfWeek,
      reasonCode,
      rubiconPONbr,
      status,
      userId,
      vendorId,
      vendID,
      loadDispatchOpportunitiesCount,
      onCancel,
      responseMissedPickup,
      updateOpenDispatches,
    } = this.props;
    const { dispatchStatus, startDate, endDate, workOrder, page } = getQueryParams(search);
    const searchFilter = {
      dispatchStatus,
      startDate,
      endDate,
      workOrder,
      workOrderType: NON_SCHEDULED,
      page,
      limit: LIMIT_PER_PAGE,
    };
    const action = 'Accept';

    if (!vendID) {
      return;
    }

    // defaulting reasoncode as 'OTH' for Accept
    const responseType = 'REQUESTED';
    let reasonType;
    let newServiceDate;
    let uploadedFile;
    if (pickupTypeId === MISSED_PICKUP) {
      reasonType = reasonCode;
      newServiceDate =
        itemId && completedDate
          ? moment(completedDate).format('YYYY-MM-DD')
          : completedDate
          ? moment(completedDate).format('YYYY-MM-DD HH:mm:ss')
          : undefined;
      uploadedFile = file ? file[0] : null;
    } else {
      reasonType = note ? 'OTH' : null;
      newServiceDate = undefined;
    }

    const weightTicketUom = undefined;

    const promise =
      pickupTypeId === MISSED_PICKUP && itemId
        ? responseMissedPickup(
            itemId,
            responseType,
            newServiceDate,
            reasonType,
            userId,
            token,
            vendorId,
            dayOfWeek,
            undefined,
            undefined,
            undefined,
            note,
            uploadedFile,
          )
        : updateOpenDispatches(
            rubiconPONbr,
            status,
            note,
            reasonType,
            moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
            action,
            null,
            null,
            userId,
            token,
            vendorId,
            vendID,
            weightTicketUom,
            searchFilter,
            newServiceDate,
          );

    if (!promise) {
      return;
    }

    promise
      .then(response => {
        if (response?.errorMessage) {
          createErrorNotification(response.errorMessage, 15000);
        } else {
          const isReload = true;
          loadDispatchOpportunitiesCount(userId, vendorId, token);
          createSuccessNotification(translate('opportunity.opportunitySave'));
          onCancel(true, isReload);
        }
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  };

  stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  render() {
    const {
      daysInWeekSchedule,
      itemId,
      missedPickupReasonCodes,
      pickupTypeId,
      reasonCodes,
      requestedServiceDate,
      rubiconPONbr,
      serviceDate,
      isSaving,
      onCancel,
    } = this.props;

    const modalTitle =
      pickupTypeId === MISSED_PICKUP
        ? translate('autoDispatch.missedPickup.acceptTitle')
        : translate('opportunity.acceptService');
    return (
      <Modal size="small" isLoading={isSaving}>
        <ModalTitle noTransform>{modalTitle}</ModalTitle>
        <Grid centered>
          <GridColumn size="12/12">
            <AcceptOpenDispatchesForm
              daysInWeekSchedule={daysInWeekSchedule}
              itemId={itemId}
              missedPickupReasonCodes={missedPickupReasonCodes}
              pickupTypeId={pickupTypeId}
              reasonCodes={reasonCodes}
              requestedServiceDate={requestedServiceDate}
              rubiconPONbr={rubiconPONbr}
              serviceDate={serviceDate}
              onSubmit={this.onSubmit}
              onCancel={onCancel}
            />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('acceptOpenDispatches');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  file: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.file`),
  note: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.note`),
  completedDate: formSelector(
    state,
    `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.completedDate`,
  ),
  reasonCode: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.reasonCode`),
  dayOfWeek: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.dayOfWeek`),
  isSaving: state.fleet.openDispatches.isSaving,
  userId: currentUserId(state),
  vendorId: currentVendorId(state),
  missedPickupReasonCodes: state.fleet.rubiconDispatches?.missedPickupReasonCodes,
});

const mapDispatchToProps = {
  responseMissedPickup,
  updateOpenDispatches,
  loadDispatchOpportunitiesCount,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcceptOpenDispatchesModal));
