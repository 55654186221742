import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const HAULERAPP = 1;
export const HAULERAPP_ANDROID = 6;
export const RUBICONZ = 7;
export const HAULERAPP_STORE = 8;
export const HAULERAPP_ANDROID_STORE = 9;
export const HAULERAPP_PARTNER = 10;

export const APPLICATION_TYPES = mapKeys(
  [
    { id: HAULERAPP, name: translate('settings.applicationTypes.haulerApp'), technicalName: 'HaulerApp' },
    { id: HAULERAPP_ANDROID, name: translate('settings.applicationTypes.haulerAppAndroid'), technicalName: 'HaulerApp_Android' },
    { id: RUBICONZ, name: translate('settings.applicationTypes.rubiconZ'), technicalName: 'Rubicon Z' },
    { id: HAULERAPP_STORE, name: translate('settings.applicationTypes.haulerAppStore'), technicalName: 'HaulerAppStore' },
    { id: HAULERAPP_ANDROID_STORE, name: translate('settings.applicationTypes.haulerAppStoreAndroid'), technicalName: 'HaulerAppStore_Android' },
    { id: HAULERAPP_PARTNER, name: translate('settings.applicationTypes.haulerAppStorePartner'), technicalName: 'HaulerAppStorePartner' },
  ],
  'id',
);
