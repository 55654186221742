import { reduxForm, InjectedFormProps, submit } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Grid, GridColumn, PanelSection, Button, ButtonSet } from '../../../core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../core/services/translate';
import { Dropdown, TypedField } from 'src/core/components';
import { isRequired } from 'src/utils/services/validator';
import { groupBy, keys, map } from 'lodash-es';

export type TransferContainersFormValues = {
  locationId: number;
};

const form = 'transferContainers';

export default reduxForm<TransferContainersFormValues>({
  form,
})(function TransferContainersForm({ handleSubmit }: InjectedFormProps<TransferContainersFormValues>) {
  const dispatch = useDispatch();
  const facilities = useSelector(state => state.fleet.containerFacilities.containerFacilities);

  const facilitiesGrouped = groupBy(facilities, facility => facility.facilitySubType.name);

  const facilitiesOptions = map(keys(facilitiesGrouped), locKey => {
    return {
      label: locKey,
      options: map(facilitiesGrouped[locKey], ({ name, locationId }) => ({ value: locationId, label: name })),
    };
  });

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="medium no no" vertical>
        <Grid centered>
          <GridColumn size="6/12">
            <TypedField
              name="locationId"
              component={Dropdown}
              validate={[isRequired]}
              props={{ options: facilitiesOptions as any, label: translate('containers.destinationFacility') }}
            />
          </GridColumn>
        </Grid>

        <ButtonSet margin="lMedium no no">
          <Button type="button" onClick={() => dispatch(submit(form))} color="primary">
            {translate('containers.transferContainers')}
          </Button>
        </ButtonSet>
      </PanelSection>
    </form>
  );
});
