import React, { PureComponent } from 'react';

import { arrayMove } from 'react-sortable-hoc';
import { map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn, Message, Separator, Text } from '../../../core/components/styled';
import {
  DriverSafetyInspectionSettings,
  SupervisorExperienceSettings,
} from '../../interfaces/SupervisorExperienceSettings';
import { DriverSafetyInspectionSettingsFormTableRow } from './';
import { SortableTable } from '../../../core/components';
import { Switch, TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';

type PropsWithoutReduxForm = {
  onCancel: (pristine: boolean) => void;
  driverSafetyInspectionSettings?: DriverSafetyInspectionSettings[];
};

type Props = PropsWithoutReduxForm & InjectedFormProps<SupervisorExperienceSettings, PropsWithoutReduxForm>;

interface State {
  driverSafetyInspectionSettings?: DriverSafetyInspectionSettings[];
}

class SupervisorExperienceSettingsForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { driverSafetyInspectionSettings: this.props.driverSafetyInspectionSettings };
  }

  onSortOrderChange = (oldIndex: number, newIndex: number) => {
    const driverSafetyInspectionSettings = arrayMove(
      this.state.driverSafetyInspectionSettings || [],
      oldIndex,
      newIndex,
    );
    this.resetOrderNumbers(driverSafetyInspectionSettings);

    this.setState({ driverSafetyInspectionSettings });
  };

  resetOrderNumbers = (driverSafetyInspectionSettings: DriverSafetyInspectionSettings[]) =>
    map(driverSafetyInspectionSettings, ({ driverSafetyInspectionType }, index) =>
      this.props.change(`${driverSafetyInspectionType.technicalName}.orderNo`, index + 1),
    );

  driverSafetyInspectionSettingsTableCells = [
    { name: 'dragHandle', width: '5%' },
    { name: 'driverSafetyChecks', label: translate('vendors.driverSafetyChecks'), width: '55%' },
    { name: 'isActive', label: translate('common.active'), width: '20%', align: 'center' },
    { name: 'isRequired', label: translate('vendors.required'), width: '20%', align: 'center' },
  ];

  render() {
    const { handleSubmit, onCancel, pristine } = this.props;
    const { driverSafetyInspectionSettings } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <Grid centered>
          <GridColumn size="12/12" padding="medium" verticalAlign="flex-start">
            <span>{translate('vendors.vehicleRequiredAtLogin')}</span>
            <TypedField
              name="requireVehicleAtLogin"
              component={Switch}
              props={{
                margin: 'no small',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid centered>
          <GridColumn size="12/12" padding="no medium medium medium">
            <Separator />
            <Text block margin="medium no small no" align="center" size="xLarge">
              {translate('vendors.driverSafetyReport.driverSafetyReport')}
            </Text>
            {!!size(driverSafetyInspectionSettings) ? (
              <SortableTable
                cells={this.driverSafetyInspectionSettingsTableCells}
                rows={driverSafetyInspectionSettings}
                rowComponent={DriverSafetyInspectionSettingsFormTableRow}
                sort={this.onSortOrderChange}
              />
            ) : (
              <Message padding="sMedium">{translate('vendors.noDriverSafetyReports')}</Message>
            )}

            <ButtonSet margin="medium no" align="center">
              <Button type="submit" color="primary" margin="no small">
                {translate('common.save')}
              </Button>
              <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  driverSafetyInspectionSettings: state.vendors.supervisorExperienceSettings.driverSafetyInspectionSettings || [],
});

export default connect(mapStateToProps)(
  reduxForm<SupervisorExperienceSettings, PropsWithoutReduxForm>({
    form: 'supervisorExperienceSettingsForm',
  })(SupervisorExperienceSettingsForm),
);
