import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { VEHICLE_ICON_TYPES } from 'src/common/constants/vehicleIcons';
import { useSelector } from 'src/core/hooks/useSelector';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { getTravelPathBuilderVehiclesPositionsGeoJSON } from '../utils';
import TravelPathVehiclePositionPopupResolver from './TravelPathVehiclePositionPopupResolver';
import VehiclePositionsGLSource, {
  TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER,
  TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_SOURCE,
} from './VehiclePositionsGLSource';

const mapImages = VEHICLE_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const TravelPathVehiclePositionsGL: FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);
  const dispatch = useDispatch();
  const vehiclePositions = useSelector(state => state.routes.travelPathBuildAndEdit.routeVehiclePositions);

  const geoJson = useMemo(() => getTravelPathBuilderVehiclesPositionsGeoJSON(vehiclePositions), [vehiclePositions]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_SOURCE &&
              feature.properties?.layer === TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER,
          );

        if (!!feature) {
          dispatch(
            setRouteMapSelectedFeature(
              RouteMapFeature.vehiclePositions,
              feature.properties?.id as number,
              undefined,
              true,
            ),
          );
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <VehiclePositionsGLSource geoJson={geoJson} />
      <TravelPathVehiclePositionPopupResolver />
    </>
  );
};

export default TravelPathVehiclePositionsGL;
