import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Modal, Table } from 'src/core/components';
import { ModalSection, Text } from 'src/core/components/styled';
import { WaterFills } from 'src/routes/interfaces/WaterFills';
import { WaterFillsTableRow } from 'src/routes/components/modals';
import translate from 'src/core/services/translate';

const waterFillsCellWidths = ['31%', '19%', '13%', '15%', '12%', '10%'];

const getWaterFillsTableCells = () => {
  const waterFillsTableCells = [
    {
      name: 'location',
      label: translate('common.materialTickets.location'),
      width: waterFillsCellWidths[0],
    },
    {
      name: 'material',
      label: translate('common.material'),
      width: waterFillsCellWidths[1],
    },
    {
      name: 'quantity',
      label: translate('common.materialTickets.quantity'),
      width: waterFillsCellWidths[2],
    },
    {
      name: 'uom',
      label: translate('common.materialTickets.uom'),
      width: waterFillsCellWidths[3],
    },
    {
      name: 'timestamp',
      label: translate('common.materialTickets.materialTicketDate'),
      width: waterFillsCellWidths[4],
    },
    {
      name: 'images',
      label: translate('common.images'),
      width: waterFillsCellWidths[5],
    },
  ];

  return waterFillsTableCells;
};

interface Props {
  closeModal: () => void;
  modalSubTitle?: string;
  modalTitle?: string;
  waterFills: WaterFills[];
}

const WaterFillModal: React.FC<Props> = props => {
  const { closeModal, modalSubTitle, modalTitle, waterFills } = props;

  return (
    <Modal size="large" subTitle={modalSubTitle} title={modalTitle} onClose={closeModal} padding="medium">
      <ModalSection margin="medium no no">
        {waterFills.length ? (
          <Table
            cells={getWaterFillsTableCells()}
            rows={waterFills}
            rowComponent={WaterFillsTableRow}
            rowProps={{
              isViewOnly: true,
              waterFillsCellWidths,
            }}
          />
        ) : (
          <Text block align="center" padding="no no medium no">
            {translate('common.waterFill.noWaterFill')}
          </Text>
        )}
      </ModalSection>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  waterFills: state.routes.waterFills.waterFills || [],
});

export default connect(mapStateToProps)(WaterFillModal);
