import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CITY_ALERTS_ICON_TYPES } from 'src/common/constants';
import { ROUTE_MAP_CITY_ALERTS_LAYER, ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';
import { RouteMapFeature, clearRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import CityAlertsGLLayer from './CityAlertsGLLayer';
import CityAlertsGLPopupResolver from './CityAlertsGLPopupResolver';

const mapImages = CITY_ALERTS_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: ROUTE_MAP_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const CityAlertsGL: FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_CLUSTERS_SOURCE && feature.properties?.layer === ROUTE_MAP_CITY_ALERTS_LAYER,
          );

        if (!!feature) {
          dispatch(clearRouteMapSelectedFeature());
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.cityAlert, Number(feature.id)));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <CityAlertsGLLayer />
      <CityAlertsGLPopupResolver />
    </>
  );
};

export default CityAlertsGL;
