import update from 'immutability-helper';
import { find, identity } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { Contact } from 'src/customers/interfaces/Customers';
import {
  updateContact,
  createContact,
  loadContacts as doLoadContacts,
  deleteContact as doDeleteContact,
} from 'src/customers/services/contacts';

// Actions
const START_LOAD = 'customers/contacts/START_LOAD';
export const COMPLETE_LOAD = 'customers/contacts/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/contacts/FAIL_LOAD';
const START_DELETE = 'customers/contacts/START_DELETE';
export const COMPLETE_DELETE = 'customers/contacts/COMPLETE_DELETE';
const FAIL_DELETE = 'customers/contacts/FAIL_DELETE';
const START_SAVE = 'customers/contacts/START_SAVE';
export const COMPLETE_SAVE = 'customers/contacts/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/contacts/FAIL_SAVE';
const RESET = 'customers/contacts/RESET';

export interface State {
  isLoading: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  contacts?: Contact[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isDeleting: false,
  isSaving: false,
  contacts: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          contacts: action.contacts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          contacts: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (contacts: Contact[]) => ({
  type: COMPLETE_LOAD,
  contacts,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadContacts = (customerId: number, locationId?: number, searchText?: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContactsPromise = doLoadContacts(customerId, locationId, searchText);
  loadContactsPromise.then(contacts => dispatch(completeLoad(contacts))).catch(() => dispatch(failLoad()));
  return loadContactsPromise;
};
export const deleteContact = (contactId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteContactPromise = doDeleteContact(contactId);
  deleteContactPromise.then(() => dispatch(completeDelete())).catch(() => dispatch(failDelete()));
  return deleteContactPromise;
};

export const saveContact = (customerContact: any, customerId: number) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveContactPromise = customerContact.id
    ? updateContact(customerContact, customerId)
    : createContact(customerContact, customerId);
  saveContactPromise
    .then(() => {
      dispatch(completeSave());
    })
    .catch(() => dispatch(failSave()));
  return saveContactPromise;
};

export const resetContacts = () => ({
  type: RESET,
});

// Selectors
const getContactById = (contactsState: State, contactId: number) => find(contactsState.contacts, { id: contactId });
export const contactByIdSelector = createSelector(getContactById, identity);
