import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { CRITICAL } from '../../constants';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { FaultCodeEditorForm } from '../forms';
import { FaultCodeEmail } from '../../interfaces/FaultCode';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalSection, ModalTitle } from '../../../core/components/styled';
import { saveFaultCodeEmail } from '../../ducks';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  faultCodeEmailId?: number;
  saveFaultCodeEmail: DuckFunction<typeof saveFaultCodeEmail>;
  vendorId: number;
}

class FaultCodeEmailEditorModal extends PureComponent<Props> {
  saveFaultCodeEmail = (faultCodeEmail: FaultCodeEmail) => {
    const { vendorId, saveFaultCodeEmail, closeModal } = this.props;
    closeModal(true);

    saveFaultCodeEmail(vendorId, {
      ...faultCodeEmail,
      sendCriticalForDailyEmailSummary: faultCodeEmail.sendCriticalForDailyEmailSummary === CRITICAL,
      sendCriticalForReoccurringEmailSummary: faultCodeEmail.sendCriticalForReoccurringEmailSummary === CRITICAL,
    })
      .then(() => {
        createSuccessNotification(translate('vendors.faultCodeEmailSaved'));
      })
      .catch(() => createErrorNotification(translate('vendors.saveFaultCodeEmailError')));
  };

  render() {
    const { faultCodeEmailId, closeModal } = this.props;

    return (
      <Modal padding="no" size="small" onClose={closeModal}>
        <ModalSection padding="medium medium small">
          <ModalTitle>
            {faultCodeEmailId ? translate('vendors.editFaultCodeEmail') : translate('vendors.addFaultCodeEmail')}
          </ModalTitle>
        </ModalSection>
        <FaultCodeEditorForm faultCodeEmailId={faultCodeEmailId} onSubmit={this.saveFaultCodeEmail} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { saveFaultCodeEmail };

export default connect(mapStateToProps, mapDispatchToProps)(FaultCodeEmailEditorModal);
