import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { find } from 'lodash-es';

import { UnconnectedDaysOfWeekPicker, UnconnectedDropdown } from 'src/core/components';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { ContainerLocationForMap, ContainerNewSimplified } from 'src/fleet/interfaces/containers';
import { resetContainersForMapLocation } from 'src/fleet/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import { Grid, GridColumn } from 'src/core/components/styled';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';
import { RubiconServicesHistory } from 'src/customers/interfaces/RubiconServices';
import { DropdownOption } from 'src/core/components/Dropdown';

type Props = {
  containerLocation: ContainerLocationForMap;
};

export default function RubiconServicesPopup({ containerLocation }: Props) {
  const dispatch = useDispatch();
  const containersForMapLocation = useSelector(state => state.fleet.containersForMapLocation.containersForMapLocation);
  const containerOptionsDropdown: DropdownOption[] = [];

  const rubiconServicesForMapLocation = useSelector(
    (state: AppState) => state.customers.rubiconServices.rubiconServices,
  );

  const [selectedContainer, setSelectedContainer] = useState<ContainerNewSimplified | undefined>();
  const [selectedRubiconService, setSelectedRubiconService] = useState<RubiconServicesHistory | undefined>();

  useEffect(
    () => () => {
      dispatch(resetContainersForMapLocation());
    },
    [dispatch],
  );

  useEffect(() => {
    setSelectedContainer(find(containersForMapLocation, { id: containerLocation.con[0]?.id }));
    setSelectedRubiconService(find(rubiconServicesForMapLocation, { siteID: containerLocation.lid.toString() }));
  }, [containersForMapLocation, containerLocation, rubiconServicesForMapLocation]);

  if (containerLocation.con.length === 0) {
    return null;
  }
  if (containerLocation.con.length > 1) {
    containerLocation.con.forEach(container => {
      const currentRubiconService = find(rubiconServicesForMapLocation, { svcID: container.id });
      currentRubiconService &&
        containerOptionsDropdown.push({
          label: currentRubiconService.svcID.toString(),
          value: currentRubiconService.svcID,
        });
    });
  }

  const handleContainerOnLocationChange = (containerId: number) => {
    const selectedRubiconService = find(rubiconServicesForMapLocation, { svcID: containerId });
    setSelectedRubiconService(selectedRubiconService);
  };

  const customerName = selectedRubiconService?.customerName;
  const locationName = `${selectedRubiconService?.siteName} ${selectedRubiconService?.siteID}`;
  const locationAddress = `${selectedRubiconService?.siteAddress}, ${selectedRubiconService?.siteCity}, ${selectedRubiconService?.siteState}`;
  const serviceId = `${selectedRubiconService?.svcID}`;
  const containerTypeSize = `${selectedRubiconService?.equipmentType} ${selectedRubiconService?.equipmentSize}`;
  const containerCount = selectedRubiconService?.containerCount;
  const materialType = selectedRubiconService?.materialType;
  const frequencyDescr = selectedRubiconService?.freqDescr;
  const frequencyDays = selectedRubiconService?.frequencyDays;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={containerLocation.la}
      longitude={containerLocation.lo}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle
          title={
            containerOptionsDropdown.length < 1 ? `${translate('containers.container')} ${selectedContainer?.nr} ` : ' '
          }
          beforeTitleContent={
            containerOptionsDropdown.length > 1 && (
              <Grid margin="no no small">
                <GridColumn size="4/12" verticalAlign="center">
                  {translate('containers.container')}
                </GridColumn>
                <GridColumn size="8/12">
                  <UnconnectedDropdown
                    options={containerOptionsDropdown}
                    value={selectedRubiconService?.svcID}
                    onChange={handleContainerOnLocationChange}
                    margin="no"
                  />
                </GridColumn>
              </Grid>
            )
          }
        />
        <MapGLPopupContent>
          <Grid multiLine padding="small no">
            <GridColumn size="12/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.customerName')}
                value={customerName}
              />
            </GridColumn>
            <GridColumn size="12/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.locationName')}
                value={locationName}
              />
            </GridColumn>
            <GridColumn size="12/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.locationAddress')}
                value={locationAddress}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={'serviceId'} value={serviceId} />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('containers.container')}
                value={containerTypeSize}
              />
            </GridColumn>
            {selectedContainer?.cn && (
              <GridColumn size="6/12" margin="xxSmall no">
                <LabeledDataView
                  noWrap
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('containers.container')}
                  value={containerCount}
                />
              </GridColumn>
            )}
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('routes.materialType')}
                value={materialType}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('customers.frequency')}
                value={frequencyDescr}
              />
            </GridColumn>
            <GridColumn size="12/12" margin="xxSmall no">
              <UnconnectedDaysOfWeekPicker
                selectedWeekdays={frequencyDays?.split('')}
                isReadOnly
                multiple
                dayOfWeekProps={{ margin: "no xSmall no no'" }}
                daysOfWeekProps={{ margin: 'no' }}
              />
            </GridColumn>
          </Grid>
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
