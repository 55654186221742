import { duration } from 'src/utils/services/formatter';
import { RouteAssistDetail } from 'src/routes/interfaces/RouteAssist';
import { routeAssistsTableCellWidths } from './RouteAssistSection';
import { TableCell, TableRow, Text } from 'src/core/components/styled';

type Props = {
  assistedStops: number;
  assistedTime: number;
  driverName: string;
  isSnowOrSweeperRoute: boolean;
  vehicleName: string;
};

export default function RouteAssistsTableRow({
  assistedStops,
  assistedTime,
  driverName,
  isSnowOrSweeperRoute,
  vehicleName,
}: Props & RouteAssistDetail) {
  return (
    <TableRow>
      <TableCell width={routeAssistsTableCellWidths[0]}>
        <Text>{vehicleName}</Text>
      </TableCell>
      <TableCell width={routeAssistsTableCellWidths[1]}>
        <Text>{driverName}</Text>
      </TableCell>
      <TableCell width={routeAssistsTableCellWidths[2]}>
        <Text>{assistedStops}</Text>
      </TableCell>
      <TableCell width={routeAssistsTableCellWidths[3]}>
        <Text>{duration(assistedTime, isSnowOrSweeperRoute ? 'seconds' : 'minutes', 'hh:mm:ss')}</Text>
      </TableCell>
    </TableRow>
  );
}
