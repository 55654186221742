import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import DispatchBoardRouteEditorModalResolver from 'src/routes/components/modals/DispatchBoardRouteEditorModalResolver';
import { DispatchBoardSubPanel } from 'src/routes/components/styled';
import { SELECTED_TRANSFERRED_STOPS } from 'src/routes/constants';
import JobTypes from 'src/routes/constants/jobTypes';
import { moveDispatchBoardAllJobsToPosition } from 'src/routes/ducks/dispatchBoard';
import {
  addDispatchBoardOnHoldJobsToRoute,
  deleteDispatchBoardOnHoldJobs,
  loadDispatchBoardOnHoldJobs,
  loadDispatchBoardOnHoldJobsCount,
} from 'src/routes/ducks/dispatchBoard/dispatchBoardOnHoldJobs';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';
import { DispatchBoardRouteJob } from 'src/routes/interfaces/DispatchBoardRouteJob';
import { getDispatchBoardFilteredUnassignedJobs } from 'src/routes/services/dispatchBoardFilteredUnassignedJobsSelector';
import DispatchBoardOnHoldJobs from './DispatchBoardOnHoldJobs';
import { numberOfJobsText } from './DispatchBoardUnassignedJobs';
import RouteHeaderContainer from './common/RouteHeaderContainer';
import useDispatchBoardFilters from './common/useDispatchBoardFilters';
import { getMultiSelectedJobs, sendJobSelectionNotifications } from './common/utils';

interface DispatchBoardOnHoldJobsDetailsProps {
  jobsTransferring: boolean;
  onClose: () => void;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
  openMapWithRoute?: (route: DispatchBoardSimpleRoute) => void;
  refreshUnassignedJobs?: () => void;
  toggleJobTransferring: (value: boolean) => void;
  vendorId: number;
  filters: {
    date?: Date | string;
    routeStatuses?: any[];
    searchTerm?: string;
    serviceZones?: number[];
    supervisors?: number[];
    groupIds?: number[];
  };
}

const DispatchBoardOnHoldJobsDetails = (props: DispatchBoardOnHoldJobsDetailsProps) => {
  const didMountRef = useRef(false);

  const { jobsTransferring, onJobDrop, toggleJobTransferring, filters, vendorId, onClose } = props;

  const dispatch = useDispatch();

  const jobs = useSelector(s => s.routes.dispatchBoard.onHoldJobs.onHoldJobs);
  const isLoading = useSelector(s => s.routes.dispatchBoard.onHoldJobs.isLoading);

  const [openedJobId, setOpenedJobId] = useState<number | undefined>(undefined);

  const [routeEditorModalOpen, setRouteEditorModalOpen] = useState(false);

  const { dispatchBoardSearchFilters, filterFunctions } = useDispatchBoardFilters();

  const allMaterialTypes = useSelector(s => s.vendors.materialTypes.materialTypes || []);
  const allServiceZones = useSelector(s => s.routes.serviceZones.serviceZones || []);
  const allSupervisors = useSelector(s => s.routes.supervisors.supervisors || []);
  const [selectedJobs, setSelectedJobs] = useState<number[]>([]);

  const filteredJobs = useMemo(() => {
    setSelectedJobs([]);
    return getDispatchBoardFilteredUnassignedJobs(
      jobs,
      dispatchBoardSearchFilters,
      allServiceZones.length,
      allSupervisors.length,
      allMaterialTypes.length,
    );
  }, [allMaterialTypes.length, allServiceZones.length, allSupervisors.length, dispatchBoardSearchFilters, jobs]);

  const toggleJob = (e: MouseEvent, jobId: number) => {
    e.stopPropagation();
    setOpenedJobId(openedJobId !== jobId ? jobId : undefined);
  };

  const loadJobs = useCallback(() => {
    const { date, routeStatuses, searchTerm, serviceZones, supervisors, groupIds } = filters;
    loadDispatchBoardOnHoldJobs(
      vendorId,
      date,
      routeStatuses,
      searchTerm,
      serviceZones,
      supervisors,
      groupIds,
    )(dispatch);
    loadDispatchBoardOnHoldJobsCount(
      vendorId,
      date,
      routeStatuses,
      searchTerm,
      serviceZones,
      supervisors,
      groupIds,
    )(dispatch);
  }, [dispatch, filters, vendorId]);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      loadJobs();
    }
  }, [loadJobs]);

  const deleteJobs = async (jobsIds: number[]) => {
    if (
      !(await confirm(
        translate(`routes.alertMessages.${jobsIds.length === 1 ? 'confirmDeleteJob' : 'confirmDeleteJobs'}`),
      ))
    ) {
      return;
    }

    deleteDispatchBoardOnHoldJobs(jobsIds)(dispatch).then(() => {
      createSuccessNotification(`${translate('routes.alertMessages.routeJobDeleted')}`);
      loadJobs();
      setSelectedJobs([]);
    });
  };

  const getSelectedJobIds = () => {
    const selectedJobIds: number[] = [];
    filteredJobs.forEach((_, index) => {
      selectedJobs.forEach(job => {
        index === job && selectedJobIds.push(filteredJobs[index].id);
      });
    });
    return selectedJobIds;
  };

  const handleDeleteJobs = () => {
    const selectedJobIds = getSelectedJobIds();
    deleteJobs(selectedJobIds);
  };

  const handleToggleSelectAllJobs = (isClear?: boolean) => {
    if (isClear) {
      setSelectedJobs([]);
    } else {
      const selectedJobs = filteredJobs.map((job, index) => index);
      setSelectedJobs(selectedJobs);
      sendJobSelectionNotifications(selectedJobs.length, SELECTED_TRANSFERRED_STOPS);
    }
  };

  useEffect(() => {
    if (jobsTransferring === false) {
      setSelectedJobs([]);
    }
  }, [jobsTransferring]);

  useEffect(() => {
    setSelectedJobs([]);
  }, [dispatchBoardSearchFilters.searchTerm]);

  const handleJobSelection = (index: number, canSelect: boolean) => (e: any) => {
    const isCheckboxClicked = e.target.id.includes('actionButton');

    const multiSelectedJobs = getMultiSelectedJobs(
      index,
      selectedJobs,
      SELECTED_TRANSFERRED_STOPS,
      e,
      canSelect,
      isCheckboxClicked,
    );

    setSelectedJobs(multiSelectedJobs as any);
    sendJobSelectionNotifications(multiSelectedJobs.length, SELECTED_TRANSFERRED_STOPS);
  };

  const saveRoute = (routeData: any) => addDispatchBoardOnHoldJobsToRoute(routeData)(dispatch);

  return (
    <>
      <DispatchBoardSubPanel isLoading={isLoading} isFilterFocused>
        <RouteHeaderContainer
          dispatchBoardSearchFilters={dispatchBoardSearchFilters}
          filterFunctions={filterFunctions}
          handleDeleteJobs={handleDeleteJobs}
          jobsCount={filteredJobs.length}
          jobsCountText={numberOfJobsText(filteredJobs.length)}
          name={translate('routes.onHoldJobs')}
          onClose={onClose}
          onToggleSelectAllJobs={handleToggleSelectAllJobs}
          selectedJobsCount={selectedJobs.length}
          toggleRouteEditorModal={() => setRouteEditorModalOpen(true)}
          type={JobTypes.onHold}
          vehicleTypeId={DELIVERY_UTILITY_ID}
        />
        <DispatchBoardOnHoldJobs
          selectedJobs={selectedJobs}
          handleJobSelection={handleJobSelection}
          toggleJob={toggleJob}
          openedJobId={openedJobId}
          jobs={filteredJobs}
          jobsTransferring={jobsTransferring}
          onJobDrop={onJobDrop}
          routeIsFiltered={false}
          searchTerm={dispatchBoardSearchFilters.searchTerm}
          toggleJobTransferring={toggleJobTransferring}
          type={JobTypes.onHold}
        />
      </DispatchBoardSubPanel>
      {routeEditorModalOpen && (
        <DispatchBoardRouteEditorModalResolver
          closeModal={() => setRouteEditorModalOpen(false)}
          jobIds={getSelectedJobIds()}
          disableVehicleType
          onSave={saveRoute}
          onSuccess={() => {
            setSelectedJobs([]);
            loadJobs();
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  moveDispatchBoardAllJobsToPosition,
};

export default connect(undefined, mapDispatchToProps)(DispatchBoardOnHoldJobsDetails);
