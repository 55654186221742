import { camelize } from 'humps';
import { find, size } from 'lodash-es';
import { getFormValues } from 'redux-form';
import { useMemo, FC } from 'react';

import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { DashboardMapDataInformationWrapper } from '../../styled';
import { MapboxFiltersFormValues } from '../../forms/DashboardMapboxFiltersForm';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  isSnowSweeperDashboard?: boolean;
}

const DashboardMapDataInformation: FC<Props> = ({ isSnowSweeperDashboard }) => {
  const mainFormValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;
  const vehiclesPositions = useSelector(state => state.dashboard.vehiclesData.vehiclesList);
  const vehicleBreadCrumb = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);
  const routeVehicles = useSelector(state => state.dashboard.routesData.routeVehiclePositions);
  const lastRefreshedVehicles = useSelector(state => state.dashboard.vehiclesData.lastRefreshed);
  const lastRefreshedRoutes = useSelector(state => state.dashboard.routesData.lastRefreshed);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);
  const isSatelliteView = useSelector(state => state.dashboard.mapControls.isSatelliteEnabled);
  const lastRefreshedSnowSweeperOps = useSelector(state => state.dashboard.alternativeFleetOps.lastRefreshed);

  const gpsSource = useMemo(() => {
    if (mainFormValues?.activeTab === 'routes' && (size(routeVehicles) || !!vehicleTrackingsForRoute)) return 'x';
    if (selectedFeature?.namespace === 'vehiclePositions') {
      const vehicle = find(vehiclesPositions, v => v.id === selectedFeature.id);
      const routeVehicle = find(routeVehicles, v => v.vehicleId === selectedFeature.id);
      if (vehicle?.sourceDevice || routeVehicle?.sourceDevice)
        return vehicle?.sourceDevice ?? routeVehicle?.sourceDevice;
    }
    if (vehicleBreadCrumb?.sd) return vehicleBreadCrumb.sd;
    return '';
  }, [
    mainFormValues?.activeTab,
    routeVehicles,
    selectedFeature?.namespace,
    selectedFeature?.id,
    vehicleBreadCrumb.sd,
    vehiclesPositions,
    vehicleTrackingsForRoute,
  ]);

  const dataToShow = useMemo(
    () => ({
      lastRefreshed: isSnowSweeperDashboard
        ? lastRefreshedSnowSweeperOps
        : mainFormValues?.activeTab === 'routes'
        ? lastRefreshedRoutes
        : lastRefreshedVehicles,
      gpsSource,
    }),
    [
      isSnowSweeperDashboard,
      lastRefreshedSnowSweeperOps,
      mainFormValues?.activeTab,
      lastRefreshedRoutes,
      lastRefreshedVehicles,
      gpsSource,
    ],
  );

  if (!dataToShow.lastRefreshed) return null;

  return (
    <ComplexMapControl position="top-left" zIndex={99} xOffset={-10} yOffset={-10}>
      <DashboardMapDataInformationWrapper color={isSatelliteView ? 'white' : 'black'}>
        <Text block>
          {translate('dashboard.filterKeys.lastRefreshed')}: <span>{dataToShow?.lastRefreshed || 'NA'}</span>
        </Text>
        {dataToShow?.gpsSource && (
          <Text block>
            {translate('dashboard.filterKeys.gpsSource')}:{' '}
            <span>{translate(`dashboard.filterKeys.gpsSources.${camelize(dataToShow.gpsSource)}`)}</span>
          </Text>
        )}
      </DashboardMapDataInformationWrapper>
    </ComplexMapControl>
  );
};

export default DashboardMapDataInformation;
