import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getIconTypeForCityAlert } from 'src/common/constants/cityAlertsIcons';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { DISPATCH_BOARD_SOURCE, ON_HOLD_ROUTE_ID, UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import { DispatchBoardRouteJobRoute } from 'src/routes/interfaces/DispatchBoardRouteJob';
import store from 'src/store';
import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { CityAlertProperties } from '../../routes/routePageSections/routeMap/cityAlerts/utils';
import { CITY_ALERTS_LAYER_ID } from './cityAlerts/CityAlertsGL';

export type DispatchBoardJobFeatureProperties = {
  id: number;
  clickable: boolean;
  stopNumber?: number;
};

export const getJobIsUnassignedOrOnHold = (routeId: number) =>
  routeId === UNASSIGNED_ROUTE_ID || routeId === ON_HOLD_ROUTE_ID;

export const getDispatchBoardJobsGeoJSON = (jobRoutes: DispatchBoardRouteJobRoute[]) =>
  getFeatureCollection<GeoJSON.Point, DispatchBoardJobFeatureProperties>(
    jobRoutes.map(job =>
      getPointFeature(job.id, [job.locationAddress.displayLongitude, job.locationAddress.displayLatitude], {
        id: job.id,
        clickable: true,
        stopNumber:
          getJobIsUnassignedOrOnHold(job.routeId) || job.orderNo === JOB_PENDING_OPTIMIZATION_ID
            ? undefined
            : job.orderNo,
      }),
    ),
  );

export const handleDispatchBoardJobsFeatureState = (map: mapboxgl.Map) => {
  if (!map.getSource(DISPATCH_BOARD_SOURCE)) {
    return;
  }

  const state = store.getState();
  const jobRoutes = state.routes.dispatchBoard.map.jobRoutes as DispatchBoardRouteJobRoute[];
  const jobRouteColors = state.routes.dispatchBoard.map.routeColors as any;

  jobRoutes.forEach(job => {
    map.setFeatureState(
      {
        id: job.id,
        source: DISPATCH_BOARD_SOURCE,
      },
      {
        color: jobRouteColors[job.routeId],
      },
    );
  });
};

export const getCityAlertsGeoJSON = (cityAlerts: CityAlert[]) =>
  getFeatureCollection<GeoJSON.Point, CityAlertProperties>(
    cityAlerts.map(alert =>
      getPointFeature(alert.id || 0, [alert.longitude, alert.latitude], {
        id: alert.id || 0,
        clickable: true,
        count: alert.totalCount,
        layer: CITY_ALERTS_LAYER_ID,
        icon: getIconTypeForCityAlert(alert)?.id,
      }),
    ),
  );
