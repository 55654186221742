export const STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE = 'street-network-service-areas-clusters';
export const STREET_NETWORK_SERVICE_AREAS_CLUSTERS_LAYER = 'street-network-service-areas-clusters-layer';
export const STREET_NETWORK_SERVICE_AREAS_CLUSTER_COUNTERS_LAYER =
  'street-network-service-areas-cluster-counters-layer';

export const STREET_NETWORK_SERVICE_AREAS_SOURCE = 'street-network-service-areas-source';
export const STREET_NETWORK_SERVICE_AREAS_LAYER = 'street-network-service-areas-layer';

export const STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER =
  'street-network-service-areas-hauler-locations-layer';
export const STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER = 'str_loc_lyr'; //short to save memory space
