import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const COMPLETE = 'true'; // must be string
export const OPEN = 'false'; // must be string

const keys = [
  {
    id: COMPLETE,
    technicalName: 'complete',
    name: translate('inferenceAudit.inferenceAuditStatuses.complete'),
  },
  {
    id: OPEN,
    technicalName: 'open',
    name: translate('inferenceAudit.inferenceAuditStatuses.open'),
  },
];

export const INFERENCE_AUDIT_STATUSES = mapKeys(keys, 'id');
export const INFERENCE_AUDIT_STATUSES_BY_TECHNICAL_NAME = mapKeys(keys, 'technicalName');
