import { map, orderBy } from 'lodash-es';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

interface InsightDashboardPreferences {
  userDashboardPreferences: any[];
  daysOfOperation: any[];
}

export const transformLoadInsightDashboardPreferences = ({
  userDashboardPreferences,
  daysOfOperation,
}: InsightDashboardPreferences) => {
  const userPref = orderBy(
    map(userDashboardPreferences, insightDashboardPreference => {
      const reportCategory = insightDashboardPreference.dashboardInsightGroupName.replace(new RegExp(' ', 'g'), '');
      const reportType = insightDashboardPreference.dashboardInsightTypeReportType.replace(new RegExp(' ', 'g'), '');

      return {
        ...insightDashboardPreference,
        reportCategory,
        reportType,
        reportTypeName: translate(createTranslationKey(reportType, 'insights.reportTypes')),
      };
    }),
    ['reportCategory', 'orderNo'],
  );

  return { userDashboardPreferences: userPref, daysOfOperation };
};

export const transformSaveInsightDashboardPreferences = (insightDashboardPreferences: any[]) =>
  map(insightDashboardPreferences, (preferencesForReportType, index) => ({
    ...preferencesForReportType,
    orderNo: index,
  }));
