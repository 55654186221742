import React, { Fragment } from 'react';
import { camelCase } from 'lodash-es';
import { connect, useSelector } from 'react-redux';
import { Field, FieldArray, change } from 'redux-form';

import { AppState } from '../../../store';
import { Grid, GridColumn, ButtonSet, Button, TableActionButton } from '../../../core/components/styled';
import { ActionButtonTooltip, Dropdown, Input } from 'src/core/components';
import { isDecimalWithoutZero } from 'src/utils/services/validator';
import translate from 'src/core/services/translate';

const RatesFieldAsray: React.FC<{ fields: any; change: any }> = ({ fields, change }) => {
  const rubiconServiceOptions = useSelector((state: AppState) => state.customers.rubiconTypes.rubiconServicesOptions);
  const rateOptions = rubiconServiceOptions.rates ? rubiconServiceOptions.rates : [];
  const uomOptions = rubiconServiceOptions.uom ? rubiconServiceOptions.uom : [];
  const uomOptionsFormatted = uomOptions.map(uom => {
    return {
      label: translate(`opportunity.vendorRates.uom.${camelCase(uom.label)}`),
      value: uom.value,
    };
  });
  return (
    <>
      <Grid multiLine>
        {fields.map((field: any, index: number) => {
          return (
            <Fragment key={index}>
              <Grid>
                <GridColumn size="4/12">
                  <Field
                    name={`${field}.rateAmount`}
                    component={Input}
                    label={translate('customers.rubiconService.amount')}
                    changeFieldValue={(value: any) => {
                      change('RubiconServicesRates', `${field}.keyField`, value);
                    }}
                    validate={[isDecimalWithoutZero]}
                  />
                </GridColumn>
                <GridColumn size="4/12">
                  <Field
                    name={`${field}.rateDescription`}
                    component={Dropdown}
                    props={{
                      options: rateOptions,
                      label: translate('customers.rubiconService.rateDescription'),
                    }}
                  />
                </GridColumn>
                <GridColumn size="3/12">
                  <Field
                    name={`${field}.uom`}
                    component={Dropdown}
                    props={{
                      options: uomOptionsFormatted,
                      label: translate('customers.rubiconService.uom'),
                    }}
                  />
                </GridColumn>
                <GridColumn size="1/12" padding="ssMedium">
                  <TableActionButton onClick={() => fields.remove(index)}>
                    <ActionButtonTooltip size="medium" icon="trash" />
                  </TableActionButton>
                </GridColumn>
              </Grid>
            </Fragment>
          );
        })}

        <GridColumn size="4/12">
          <ButtonSet margin="no" align="left">
            <Button type="button" color="primary" line onClick={() => fields.push({})}>
              {translate('customers.rubiconService.addMoreRates')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </>
  );
};

const RubiconServicesRates: React.FC<{ change: any }> = ({ change }) => (
  <FieldArray name="rubiconServicesRates" component={RatesFieldAsray} change={change} />
);

const mapDispatchToProps = {
  change,
};

export default connect(undefined, mapDispatchToProps)(RubiconServicesRates);
