import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { size } from 'lodash-es';

import { AppState } from '../../store';
import { Table } from '../../core/components';
import { VendorWasteAuditConfigurationStatusTypesTableRow } from './';
import { wasteAuditConfigurationIndexByTypeSelector } from '../ducks';
import translate from '../../core/services/translate';
import wasteAuditFormInitialValuesSelector from '../services/wasteAuditFormInitialValuesSelector';

interface Props {
  vendorWasteAuditConfigurationIndex?: number;
  wasteAuditConfiguration?: any[];
}

const VendorWasteAuditConfigurationStatusTypes: React.FC<Props> = ({
  vendorWasteAuditConfigurationIndex,
  wasteAuditConfiguration,
}) => {
  const wasteAuditConfigurationStatusTableCells = [
    { name: 'description', label: translate('common.description'), width: '80%' },
    { name: 'requirePicture', label: translate('vendors.requirePicture'), width: '20%', align: 'right' },
  ];

  return (
    <Fragment>
      {vendorWasteAuditConfigurationIndex &&
        wasteAuditConfiguration &&
        !!wasteAuditConfiguration[vendorWasteAuditConfigurationIndex] &&
        !!size(
          wasteAuditConfiguration[vendorWasteAuditConfigurationIndex].vendorWasteAuditConfigurationStatusTypes,
        ) && (
          <Table
            cells={wasteAuditConfigurationStatusTableCells}
            rows={wasteAuditConfiguration[vendorWasteAuditConfigurationIndex].vendorWasteAuditConfigurationStatusTypes}
            rowComponent={VendorWasteAuditConfigurationStatusTypesTableRow}
            rowProps={{ vendorWasteAuditConfigurationIndex, wasteAuditConfiguration }}
          />
        )}
    </Fragment>
  );
};

const formSelector = formValueSelector('wasteAudit');

const mapStateToProps = (state: AppState) => {
  const { wasteAuditConfiguration, wasteAuditConfigurationType } = (wasteAuditFormInitialValuesSelector as any)(
    state.vendors.wasteAuditConfiguration,
  );

  const vendorWasteAuditConfigurationIndex = wasteAuditConfigurationIndexByTypeSelector(
    state.vendors.wasteAuditConfiguration,
    formSelector(state, 'wasteAuditConfigurationType') || wasteAuditConfigurationType,
  );

  return {
    vendorWasteAuditConfigurationIndex,
    wasteAuditConfiguration: state.vendors.wasteAuditConfiguration.wasteAuditConfiguration,
    initialValues: {
      wasteAuditConfiguration,
      wasteAuditConfigurationType,
    },
  };
};

export default connect(mapStateToProps)(VendorWasteAuditConfigurationStatusTypes);
