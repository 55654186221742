import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { get, identity } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import doLoadRouteMapDetails from '../services/loadRouteMapDetails';
import { RouteMapDetails } from '../interfaces/Route';

// Actions
const START_LOAD = 'routes/routeMapDetails/START_LOAD';
const COMPLETE_LOAD = 'routes/routeMapDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeMapDetails/FAIL_LOAD';
const RESET = 'routes/routeMapDetails/RESET';

interface State {
  isLoading: boolean;
  routeMapDetails?: RouteMapDetails;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  routeMapDetails: {} as RouteMapDetails,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeMapDetails: action.routeMapDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeMapDetails: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeMapDetails: RouteMapDetails) => ({
  type: COMPLETE_LOAD,
  routeMapDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteMapDetails =
  (vendorId: number, routeId: number | string, isYRoute?: boolean) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRouteMapDetailsPromise = doLoadRouteMapDetails(vendorId, routeId, isYRoute);
    loadRouteMapDetailsPromise
      .then(routeMapDetails => dispatch(completeLoad(routeMapDetails)))
      .catch(() => dispatch(failLoad()));
    return loadRouteMapDetailsPromise;
  };

export const resetRouteMapDetails = () => ({
  type: RESET,
});

// Selectors
const getVehiclePositions = (routeMapDetailsState: any) =>
  get(routeMapDetailsState, 'routeMapDetails.vehiclePositions') || [];
export const vehiclePositionsSelector = createSelector(getVehiclePositions, identity);

const getVehicleTrackings = (routeMapDetailsState: any) =>
  get(routeMapDetailsState, 'routeMapDetails.vehicleTrackings') || [];
export const vehicleTrackingsSelector = createSelector(getVehicleTrackings, identity);

const getContainerInsights = (routeMapDetailsState: any) =>
  get(routeMapDetailsState, 'routeMapDetails.containerInsights') || [];

export const containerInsightsSelector = createSelector(getContainerInsights, identity);

const getUnitOfMeasure = (routeMapDetailsState: any) => get(routeMapDetailsState, 'unitOfMeasure');
export const getUnitOfMeasureSelector = createSelector(getUnitOfMeasure, identity);

const hasStrobeData = (routeMapDetailsState: any) => get(routeMapDetailsState, 'hasStrobeData');
export const hasStrobeDataSelector = createSelector(hasStrobeData, identity);
