import React from 'react';

import Icon from './Icon';

interface Props {
  width?: number;
  height?: number;
}

const ChevronDownArrow: React.FC<Props> = ({ width, height }) => (
  <Icon
    icon="chevron-down-arrow"
    customViewBox="0 0 256 207"
    width={width ? `${width}px` : undefined}
    height={height ? `${height}px` : undefined}
  />
);

export default ChevronDownArrow;
