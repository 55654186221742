import { change } from 'redux-form';
import { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { isNumber } from 'lodash-es';
import { push } from 'connected-react-router';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { COMPLETE, OPEN } from '../../constants/inferenceAuditStatuses';
import { createErrorNotification } from 'src/core/services/createNotification';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { getQueryParams } from '../../../utils/services/queryParams';
import { InferenceAudit, RunningVehicle } from '../../interfaces/interfaces';
import { InferenceAuditFilterForm } from '../forms';
import { InferenceAuditPageTableRow } from '.';
import { loadInferenceAudits, loadRunningVehicles, resetInferenceAudits, saveInferenceAudits } from '../../ducks';
import {
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
  PageBackButtonAction,
} from '../../../common/components/styled';
import { Pagination, Table } from '../../../core/components';
import { Panel, PanelSection, PanelSectionGroup, Text } from '../../../core/components/styled';
import { TODAY_FORMATTED } from '../../../core/constants';
import history from 'src/core/services/history';
import InferenceAuditImageModal from '../modals/InferenceAuditImageModal';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  change: typeof change;
  inferenceAudits: InferenceAudit[];
  isLoadingAudits: boolean;
  isLoadingRunningVehicles: boolean;
  lastLocations: Dictionary<string>;
  loadInferenceAudits: (
    vendorId: number,
    date: Date | string,
    vehicleId: number,
    status: string,
    page: number,
  ) => Promise<any>;
  loadRunningVehicles: (vendorId: number, date: Date | string) => void;
  push: (location: string) => void;
  runningVehicles: RunningVehicle[];
  saveInferenceAudits: (audit: InferenceAudit, auditId: number) => Promise<any>;
  total: number;
  vendorId: number;
  resetInferenceAudits: () => void;
}

interface State {
  isModalOpen: boolean;
  isSavingAudit: boolean;
  selectedAudit?: number;
  savingAuditIndex?: number;
  nextAuditIndex?: number;
  previousAuditIndex?: number;
}

class InferenceAuditPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isSavingAudit: false,
      selectedAudit: undefined,
      savingAuditIndex: undefined,
      nextAuditIndex: undefined,
      previousAuditIndex: undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const {
      loadRunningVehicles,
      location: { search },
      vendorId,
    } = this.props;

    const { page, status, vehicleId, date } = getQueryParams(search);
    const {
      page: prevPage,
      status: prevStatus,
      vehicleId: prevVehicleId,
      date: prevDate,
    } = getQueryParams(prevProps.location.search);

    if (page !== prevPage || status !== prevStatus || vehicleId !== prevVehicleId) {
      this.loadInferenceAudits();
    }

    if (date !== prevDate) {
      if (date && prevDate) this.resetFilters();
      this.loadInferenceAudits();
      loadRunningVehicles(vendorId, date);
    }
  }

  resetFilters = () => {
    const { change } = this.props;
    change('inferenceAuditFilter', 'vehicleId', null);
    change('inferenceAuditFilter', 'status', null);
  };

  onDateChange = (event: ChangeEvent, date: Date | string) => {
    this.updateUrl(date, undefined, undefined);
  };

  onVehicleChange = (event: ChangeEvent, vehicleId: number) => {
    const {
      location: { search },
    } = this.props;
    const { date, status } = getQueryParams(search);
    this.updateUrl(date, status, vehicleId);
  };

  onAuditStatusTypeChange = (event: ChangeEvent, status: string) => {
    const {
      location: { search },
    } = this.props;
    const { date, vehicleId } = getQueryParams(search);
    this.updateUrl(date, status, vehicleId);
  };

  updateUrl = (date: Date | string, status?: string, vehicleId?: number, page?: number) => {
    const { location, push } = this.props;
    const currentDate = date || TODAY_FORMATTED;

    const newUrl =
      status && vehicleId
        ? `date=${currentDate}&status=${status}&vehicleId=${vehicleId}`
        : status && !vehicleId
        ? `date=${currentDate}&status=${status}`
        : !status && vehicleId
        ? `date=${currentDate}&vehicleId=${vehicleId}`
        : `date=${currentDate}`;

    push(`${location.pathname}?${newUrl}&page=${page || 1}`);
  };

  loadInferenceAudits = () => {
    const {
      vendorId,
      loadInferenceAudits,
      location: { search },
      resetInferenceAudits,
    } = this.props;

    const { date = TODAY_FORMATTED, status, vehicleId, page } = getQueryParams(search);

    resetInferenceAudits();

    loadInferenceAudits(vendorId, date, vehicleId, status, page);
  };

  toggleModal = (isOpen: boolean, auditIndex?: number) => {
    this.setState({
      isModalOpen: isOpen,
      selectedAudit: auditIndex,
    });
  };

  handleSave = async (updatedAudit: InferenceAudit) => {
    const { inferenceAudits, saveInferenceAudits } = this.props;
    const updatedAuditIndex = inferenceAudits.findIndex(audit => audit.id === updatedAudit.id);
    const nextAuditIndex = inferenceAudits.findIndex(
      (audit, index) => audit.status === OPEN && index > updatedAuditIndex,
    );

    const auditId = updatedAudit.id || inferenceAudits[updatedAuditIndex as number].id;

    if (auditId) {
      if (updatedAudit.status !== COMPLETE) {
        this.setState({
          savingAuditIndex: updatedAuditIndex,
          isSavingAudit: true,
        });

        await saveInferenceAudits(updatedAudit, auditId);
        this.setState({
          savingAuditIndex: undefined,
          isSavingAudit: false,
        });
        nextAuditIndex !== -1 && this.goToNextInference(nextAuditIndex);
      }
    } else {
      createErrorNotification(translate('inferenceAudit.alertMessages.errorOnSave'));
    }
  };

  goToNextInference = (nextAuditIndex?: number) => {
    const { selectedAudit } = this.state;

    this.setState({
      selectedAudit: isNumber(nextAuditIndex) ? nextAuditIndex : (selectedAudit || 0) + 1,
    });
  };

  goToPreviousInference = () => {
    const { selectedAudit } = this.state;

    this.setState({
      selectedAudit: (selectedAudit || 0) - 1,
    });
  };

  render() {
    const { inferenceAudits, isLoadingAudits, isLoadingRunningVehicles, runningVehicles, total } = this.props;

    const { isModalOpen, isSavingAudit, selectedAudit, savingAuditIndex } = this.state;

    const inferenceAuditTableCells = [
      {
        name: 'modelInference',
        label: translate('inferenceAudit.inferenceAuditTableHeaders.modelInference'),
        width: '50%',
        sortable: false,
      },
      {
        name: 'auditOptions',
        label: translate('inferenceAudit.inferenceAuditTableHeaders.auditOptions'),
        width: '35%',
        sortable: false,
      },
      {
        name: 'status',
        label: translate('inferenceAudit.inferenceAuditTableHeaders.status'),
        width: '15%',
        sortable: false,
      },
    ];

    const goBackToPreviousPage = () => {
      const { lastLocations, push } = this.props;
      const hasLastLocations = Object.keys(lastLocations).length > 1;

      return hasLastLocations ? history.goBack() : push('/dashnoard');
    };

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction id={`inference-page-back-button`} onClick={goBackToPreviousPage}>
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate('inferenceAudit.inferenceAudit')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoadingAudits || isLoadingRunningVehicles}>
            <InferenceAuditFilterForm
              onAuditStatusTypeChange={this.onAuditStatusTypeChange}
              onDateChange={this.onDateChange}
              onVehicleChange={this.onVehicleChange}
              runningVehicles={runningVehicles}
            />
            <PanelSection padding={inferenceAudits.length > 0 ? 'no' : 'small'}>
              {inferenceAudits.length > 0 ? (
                <Table
                  cells={inferenceAuditTableCells}
                  rows={inferenceAudits}
                  rowComponent={InferenceAuditPageTableRow}
                  rowProps={{
                    toggleModal: this.toggleModal,
                    handleSave: this.handleSave,
                    savingAuditIndex,
                  }}
                />
              ) : (
                <Text>{translate('inferenceAudit.noInferencesFound')}</Text>
              )}
            </PanelSection>
            <Pagination totalResults={total} />
            {isModalOpen && (
              <InferenceAuditImageModal
                audit={inferenceAudits[selectedAudit as number]}
                auditIndex={selectedAudit as number}
                closeModal={() => this.toggleModal(false)}
                handleNextInference={this.goToNextInference}
                handlePreviousInference={this.goToPreviousInference}
                lastAuditInferenceIndex={inferenceAudits.length - 1}
                handleSave={this.handleSave}
                isLoading={isSavingAudit}
              />
            )}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  inferenceAudits: state.inferenceAudit.inferenceAudits.inferenceAudits,
  isLoadingAudits: state.inferenceAudit.inferenceAudits.isLoading,
  isLoadingRunningVehicles: state.inferenceAudit.runningVehicles.isLoading,
  lastLocations: state.core.lastLocations,
  runningVehicles: state.inferenceAudit.runningVehicles.runningVehicles as RunningVehicle[],
  total: state.inferenceAudit.inferenceAudits.total as number,
});

const mapDispatchToProps = {
  change,
  resetInferenceAudits,
  loadInferenceAudits,
  loadRunningVehicles,
  push,
  saveInferenceAudits,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InferenceAuditPage));
