import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import {
  transformCreateRouteTemplate,
  transformLoadOptimizedRouteTemplate,
  transformLoadRouteTemplate,
  transformTransferRouteTemplateLocations,
  transformUpdateRouteTemplate,
} from './transformRouteTemplate';
import { RouteTemplate, RouteTemplateLocation, RouteTemplateLocationsToTransfer } from '../interfaces/RouteTemplates';
import translate from '../../core/services/translate';

export const createRouteTemplate = (routeTemplate: RouteTemplate) =>
  http.post('/routeTemplates', transformCreateRouteTemplate(routeTemplate)).then(response => response.data);

export const updateRouteTemplate = (
  routeTemplate: RouteTemplate,
  shouldRecreateRoutes?: boolean,
  shouldCreateTravelPath?: boolean,
  isOrderNumberChangedByUser?: boolean,
) =>
  http
    .put(`/routeTemplates/${routeTemplate.id}`, transformUpdateRouteTemplate(routeTemplate), {
      params: { shouldRecreateRoutes, shouldCreateTravelPath, isOrderNumberChangedByUser },
    })
    .then(response => response.data);

export const deleteRouteTemplate = (routeTemplateId: number) =>
  http.delete(`/routeTemplates/${routeTemplateId}`).then(response => response.data);

export const loadRouteTemplate = (routeTemplateId: number) =>
  http.get(`/routeTemplates/${routeTemplateId}`).then(response => transformLoadRouteTemplate(response.data));

export const loadRouteTemplateOptimized = (routeTemplateId: number | string) =>
  http.get(`optimized/routeTemplates/${routeTemplateId}`).then(response => transformLoadRouteTemplate(response.data));

export const checkRouteHasScheduledDailyRoutes = (routeTemplateId: number, vendorId: number) =>
  http
    .get(`/routeTemplates/${routeTemplateId}/hasScheduledDailyRoutes`, { params: { vendorId } })
    .then(response => response.data);

export const checkGeoFenceDuplicateName = (routeTemplateName: string, vendorId: number, routeTemplateId?: number) =>
  http
    .get(`/geoFences/checkGeoFenceName`, { params: { geoFenceName: routeTemplateName, vendorId, routeTemplateId } })
    .then(response => response.data);

export const checkRouteIdsHasScheduledDailyRoutes = (selectedRouteTemplateIds: string | number[], vendorId: number) =>
  http
    .get('/services/hasScheduledDailyRoutes', {
      params: { routeTemplateIds: selectedRouteTemplateIds.toString(), vendorId },
    })
    .then(response => response.data);

export const checkRouteIdsHasScheduledDailyRoutesWithService = (
  serviceId: number,
  selectedRouteTemplateIds: number[],
  vendorId: number,
) =>
  http
    .get(`/services/${serviceId}/hasScheduledDailyRoutes`, {
      params: { routeTemplateIds: selectedRouteTemplateIds.toString(), vendorId },
    })
    .then(response => response.data);

export const checkLocationHasScheduledDailyRoutes = (locationId: number, vendorId: number) =>
  http
    .get(`/locations/${locationId}/hasScheduledDailyRoutes`, { params: { vendorId } })
    .then(response => response.data);

export const checkCustomerLocationHasScheduledDailyRoutes = (
  customerIds: number[],
  locationIds: number[],
  serviceContractIds: number[],
  vendorId: number,
) =>
  http
    .get('/customers/locations/hasScheduledDailyRoutes', {
      params: {
        customerIds: customerIds.toString(),
        locationIds: locationIds.toString(),
        serviceContractIds: serviceContractIds.toString(),
        vendorId,
      },
    })
    .then(response => response.data);

export const checkServiceHasScheduledDailyRoutes = (serviceId: number, vendorId: number) =>
  http.get(`/services/${serviceId}/hasScheduledDailyRoutes`, { params: { vendorId } }).then(response => response.data);

export const checkCustomerHasScheduledDailyRoutes = (customerId: string | number, vendorId: number) =>
  http
    .get(`/customers/${customerId}/hasScheduledDailyRoutes`, { params: { vendorId } })
    .then(response => response.data);

export const checkRouteRecommendationsHasScheduledDailyRoutes = (
  selectedRouteTemplateIds: number[],
  vendorId: number,
) =>
  http
    .get('/routeRecommendation/hasScheduledDailyRoutes', {
      params: { routeTemplateIds: selectedRouteTemplateIds.toString(), vendorId },
    })
    .then(response => response.data);

export const loadRouteTemplateWithLocationsOptimized = (
  routeTemplateId: number | string,
  page: number,
  limit: number,
  sortOrder?: string,
  sortDirection?: string,
) =>
  http
    .get(`optimized/routeTemplates/${routeTemplateId}/locations`, { params: { page, limit, sortOrder, sortDirection } })
    .then(response => transformLoadOptimizedRouteTemplate(response.data));

export const loadRouteTemplateRouteStops = (routeTemplateId: number | string) =>
  http.get(`routeTemplates/${routeTemplateId}/routeStops`).then(response => response.data);

export const transferRouteTemplateLocations = ({
  routeTemplateLocationsToTransfer,
  sourceRouteTemplateId,
  targetRouteTemplateId,
  shouldRecreateRoutes,
  positionTypeId,
}: RouteTemplateLocationsToTransfer) =>
  http
    .post(
      'routeTemplates/reassignServiceContracts',
      transformTransferRouteTemplateLocations(
        sourceRouteTemplateId,
        targetRouteTemplateId,
        routeTemplateLocationsToTransfer,
        positionTypeId,
      ),
      {
        params: { shouldRecreateRoutes: shouldRecreateRoutes || false },
      },
    )
    .then(response => response.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const routeTemplateLocationAddressChange = (routeTemplateLocation: RouteTemplateLocation) =>
  http.post(
    `/routeTemplates/${routeTemplateLocation.serviceContractRouteTemplateId}/updateBinGeoCoordinates`,
    routeTemplateLocation,
  );

export const exportRouteTemplate = (routeTemplateId: number) =>
  downloadExcelFile(`/routeTemplates/${routeTemplateId}/export`, translate('routes.routeTemplate'));
