import transparentize from 'polished/lib/color/transparentize';
import { PanelSearchInput } from 'src/core/components/styled';
import { sizeMapper } from 'src/utils/styles';
import styled, { css } from 'styled-components';

export const FilterableTableSectionWrapper = styled.div`
  padding: 10px;
`;

export const FilterableTableSectionHeader = styled.div`
  align-items: center;
  color: ${props => props.theme.grayBase};
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;
  padding: 10px 0;
`;

export const FilterableTableSectionSearch = styled.div`
  padding: 5px;
  margin-bottom: 10px;
  background: rgba(180, 180, 196, 0.1);
  border: 1px solid rgba(119, 119, 140, 0.2);
  box-shadow: 0px 0px 0px #d8d8d8;

  color: ${props => props.theme.grayBase};
  input {
    font-size: 14px;
  }
`;

export const FilterableTableSectionTableWrapper = styled.div<{ shadowed?: boolean }>`
  padding: 5px;

  ${props =>
    props.shadowed &&
    css`
      background: rgba(180, 180, 196, 0.1);
      border: 1px solid rgba(119, 119, 140, 0.2);
      box-shadow: 0px 0px 0px #d8d8d8;
    `}
`;

export const FilterableTableSectionTableRowWrapper = styled.div<{
  isReadyToDrop: boolean;
  dropZone: boolean;
  canPlanRoute?: boolean;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  background-color: ${props => (props.canPlanRoute ? props.theme.brandWhite : props.theme.grayLight)};
  border: 1px dashed ${props => props.theme.grayDarker};

  ${props =>
    props.dropZone &&
    css`
      & * {
        pointer-events: none;
      }
    `}

  ${props =>
    props.isReadyToDrop &&
    css`
      background-color: ${props.canPlanRoute
        ? transparentize(0.9, props.theme.brandPrimary)
        : transparentize(0.9, props.theme.brandAlert)};
      border-color: ${props.canPlanRoute
        ? transparentize(0.9, props.theme.brandPrimary)
        : transparentize(0.9, props.theme.brandAlert)};
      cursor: ${props.canPlanRoute ? 'move' : 'not-allowed'};
    `};
`;

export const PillAttributeWrapper = styled.div<{ margin?: string; isUnavailable?: boolean }>`
  display: inline-flex;
  padding: 4px 4px 4px 14px;
  background: #f2f2f5;
  border: 1px solid #d8d8d8;
  border-radius: 40px;
  margin-right: 10px;
  max-width: 47%;
  position: relative;
  z-index: 1;
  margin: ${props => sizeMapper(props.margin, 'no xSmall no no')};

  span {
    font-size: 12px;
    margin-right: 5px;
  }

  button {
    width: 15px;
    height: 15px;
    margin: 0;
    svg {
      width: 9px;
      height: 9px;
      margin-bottom: 4px;
    }
  }

  ${props =>
    props.isUnavailable &&
    css`
      border-color: red;
    `}
`;

export const PillDraggable = styled.div<{ draggable?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${props =>
    props.draggable &&
    css`
      &:hover {
        cursor: move;
      }
    `}
`;

export const RoutePlannerVehiclesAndDriversSearchFormWrapper = styled.div`
  padding: 2px;
  background: rgba(180, 180, 196, 0.1);
  border: 1px solid rgba(119, 119, 140, 0.2);
  box-shadow: 0px 0px 0px #d8d8d8;
  color: ${props => props.theme.grayBase};
  padding: 0 10px;
  width: 100%;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }

  input {
    font-size: 12px;
    width: 100%;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const PillContainerWrapper = styled.div`
  display: flex;
  width: calc(100% - 90px);
  flex-wrap: wrap;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.gray};
`;

export const PillClearWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 80px;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.gray};
`;

interface DriversAndVehiclesStatusEditItemProps {
  selectedItem?: boolean;
  grayed?: boolean;
  disabled?: boolean;
}

export const DriversAndVehiclesStatusEditContainer = styled.div<{ right?: boolean }>`
  height: 350px;
  width: 100%;
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px solid ${props => props.theme.grayDarker};
  ${props =>
    props.right &&
    css`
      margin-left: auto;
    `};
`;

export const DriversAndVehiclesStatusEditItem = styled.div<DriversAndVehiclesStatusEditItemProps>`
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 12px;
  text-align: left;
  padding: 10px;
  margin: 0;
  word-wrap: break-word;
  &:hover {
    cursor: pointer;
  }
  ${props =>
    props.selectedItem &&
    !props.disabled &&
    css`
      background: ${props => props.theme.brandPrimary};
      color: ${props => props.theme.brandSecondary};
    `};
  ${props =>
    props.grayed &&
    !props.selectedItem &&
    css`
      background: ${props => props.theme.grayLight};
    `};

  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.grayDark};
      &:hover {
        cursor: not-allowed;
      }
    `}
`;

export const SearchDriverOrVehicleWrapper = styled.div`
  ${PanelSearchInput} {
    &::placeholder {
      white-space: pre-line;
      position: relative;
      top: -7px;
      font-size: 14px;
    }
  }
`;
