import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const PERM = 'N';
export const TEMP = 'Y';

export const SERVICE_TYPES = mapKeys(
  [
    { id: TEMP, name: translate('opportunity.serviceTemp.temp') },
    { id: PERM, name: translate('opportunity.serviceTemp.perm') },
  ],
  'id',
);
