import React from 'react';
import { reduxForm, InjectedFormProps, SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, TypedField, Input } from '../../../../../core/components';
import { Button, Grid, GridColumn } from '../../../../../core/components/styled';
import { ShadowedButtonSet } from '../../../../../core/components/styled/ButtonSet';
import { AppState } from '../../../../../store';
import {
  initializeRouteTemplateBuilderWorkSession,
  loadRouteTemplateBuilderWorkSession,
  updateRouteTemplateBuilderWorkSession,
} from '../../../../ducks/routeTemplateBuilder';
import translate from '../../../../../core/services/translate';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { isRequired } from 'src/utils/services/validator';

const catcher = (error: any) => {
  switch (error?.response?.data?.code) {
    case 'RouteTemplatePlannerWorkSessionNameExists':
      throw new SubmissionError({
        workSessionName: translate('routeTemplateBuilder.errors.workSessionNameExists'),
      });
  }
};

interface FormValues {
  workSessionName: string;
}

interface PropsWithoutReduxForm {
  onClose(): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const RouteTemplateBuilderCreateWorkSessionModal: React.FC<Props> = ({ onClose, handleSubmit }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const workSession = useSelector((s: AppState) => s.routes.routeTemplateBuilder.workSession);
  const workSessionInitializing = useSelector((s: AppState) => s.routes.routeTemplateBuilder.workSessionInitializing);

  const onSubmit = ({ workSessionName }: FormValues) => {
    if (workSession) {
      return updateRouteTemplateBuilderWorkSession({ ...workSession, name: workSessionName })(dispatch)
        .then(() => {
          loadRouteTemplateBuilderWorkSession(workSession.id)(dispatch).then(() => onClose());
        })
        .catch(catcher);
    } else {
      return initializeRouteTemplateBuilderWorkSession(
        vendorId,
        workSessionName,
      )(dispatch)
        .then(workSessionId => {
          onClose();
          dispatch(push(`/routes/route-template-builder/${workSessionId}`));
        })
        .catch(catcher);
    }
  };

  return (
    <Modal
      hasBorderRadius
      size="small"
      padding="medium no no"
      title={
        workSession
          ? translate('routeTemplateBuilder.editWorkSession')
          : translate('routeTemplateBuilder.createWorkSession')
      }
      onClose={onClose}
      isLoading={workSessionInitializing}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid margin="no">
          <GridColumn padding="no" width="25%" />

          <GridColumn padding="no" width="50%">
            <TypedField
              name="workSessionName"
              component={Input}
              validate={[isRequired]}
              props={{
                width: '100%',
                label: translate('routeTemplateBuilder.workSessionName'),
              }}
            />
          </GridColumn>
        </Grid>

        <ShadowedButtonSet>
          <Button type="submit" color="primary">
            {workSession ? translate('common.save') : translate('routeTemplateBuilder.create')}
          </Button>
        </ShadowedButtonSet>
      </form>
    </Modal>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'routeTemplateBuilderCreateWorkSession',
})(RouteTemplateBuilderCreateWorkSessionModal);
