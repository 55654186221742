import update from 'immutability-helper';
import { AnyAction } from 'redux';

import { ContactLocation } from 'src/customers/interfaces/Customers';

// Actions
const COMPLETE_SAVE_ASSIGNED_LOCATIONS = 'customers/contactLocations/COMPLETE_SAVE_ASSIGNED_LOCATIONS';
const RESET = 'customers/contactLocations/RESET';

interface ContactLocationsState {
  assignedLocations: (ContactLocation | undefined)[];
}

// Initial state
const initialState: ContactLocationsState = {
  assignedLocations: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case COMPLETE_SAVE_ASSIGNED_LOCATIONS:
      return {
        ...state,
        assignedLocations: action.assignedLocations,
      };

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

export const saveAssignedLocations = (assignedLocations: (ContactLocation | undefined)[]) => ({
  type: COMPLETE_SAVE_ASSIGNED_LOCATIONS,
  assignedLocations,
});

// Async Actions
export const resetContactLocations = () => ({
  type: RESET,
});
