import { Redirect, Switch, Route } from 'react-router';

import DashboardPageMapboxResolver from './DashboardPageMapboxResolver';
import DashboardPageResolver from './DashboardPageResolver';
import SnowSweeperDashboardPageResolver from './SnowSweeperDashboardPageResolver';
import DashboardPageESRIResolver from './DashboardPageESRIResolver';
import SnowSweeperDashboardPageResolverEsri from './SnowSweeperDashboardPageResolverEsri';

const DashboardOperations = () => {
  return (
    <Switch>
      <Route exact path="/dashboard/all-ops" component={DashboardPageMapboxResolver} />

      <Route exact path="/dashboard/all-ops/esri" component={DashboardPageESRIResolver} />

      <Route exact path="/dashboard/all-ops-old" component={DashboardPageResolver} />

      <Route exact path="/dashboard/snow-ops" component={SnowSweeperDashboardPageResolver} />

      <Route exact path="/dashboard/snow-ops/esri" component={SnowSweeperDashboardPageResolverEsri} />

      <Route exact path="/dashboard/sweeper-ops" component={SnowSweeperDashboardPageResolver} />
      
      <Route exact path="/dashboard/sweeper-ops/esri" component={SnowSweeperDashboardPageResolverEsri} />

      <Redirect to="/dashboard/all-ops" />
    </Switch>
  );
};

export default DashboardOperations;
