import { createSelector } from 'reselect';
import { identity } from 'lodash-es';
import moment from 'moment';

import { technicalNameByVehicleTypeIdSelector } from '../../fleet/ducks';
import { UNKNOWN_ID } from 'src/common/constants/wasteTypes';
import { VEHICLE_TYPES } from '../../fleet/constants';
import { VehicleTypesForVendorState } from '../../fleet/interfaces/VehicleTypes';
import { UNASSIGNED_ROUTE_ID } from '../constants/dispatchBoard';

const getDispatchBoardJobEditorFormInitialValues = (
  vendorId: number,
  pickupTypeId: number,
  job: any,
  vehicleTypesForVendorState: VehicleTypesForVendorState[],
  vehicleTypeId: number,
  date: Date,
  routeId: number,
  locationName?: string,
  serviceContractId?: number,
) => {
  if (job) {
    const vehicleType = technicalNameByVehicleTypeIdSelector(vehicleTypesForVendorState as any, job.vehicleTypeId);

    return {
      vendorId,
      pickupTypeId,
      ...job,
      vehicleType: VEHICLE_TYPES[vehicleType ? vehicleType : 0].name,
      date: moment(job.date).format('MM/DD/YYYY'),
      isUnassigned: routeId === UNASSIGNED_ROUTE_ID,
      routeId: routeId ? routeId.toString() : UNASSIGNED_ROUTE_ID.toString(),
      wasteMaterialTypeId: UNKNOWN_ID,
      materialTypeId: job.materialType.technicalName ? job.materialType.id : undefined,
      locationName,
      serviceContractId,
    };
  }

  return {
    vendorId,
    pickupTypeId,
    vehicleTypeId,
    date,
    routeId: routeId ? routeId.toString() : UNASSIGNED_ROUTE_ID.toString(),
    wasteMaterialTypeId: UNKNOWN_ID,
    locationName,
    serviceContractId,
  };
};

const dispatchBoardJobEditorFormInitialValuesSelector = createSelector(
  getDispatchBoardJobEditorFormInitialValues,
  identity,
);

export default dispatchBoardJobEditorFormInitialValuesSelector;
