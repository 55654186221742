import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';
import { loadRubiconServicesDocuments as doLoadRubiconServicesDocuments } from '../services/rubiconServices';

const START_LOAD = 'customers/rubiconServicesDocuments/START_LOAD';
const COMPLETE_LOAD = 'customers/rubiconServicesDocuments/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/rubiconServicesDocuments/FAIL_LOAD';

interface DocumentListsState {
  documents: any[];
  isLoading: boolean;
}

const initialState: DocumentListsState = {
  documents: [],
  isLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          documents: action.documents || [],
          isLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          documents: [],
          isLoading: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (documents: any[]) => ({
  type: COMPLETE_LOAD,
  documents,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDocuments =
  (vendorId: string, userId: string, isAdmin: boolean, serviceNumber: number) => (dispatch: Dispatch) => {
    dispatch(startLoad());

    const promise = doLoadRubiconServicesDocuments(userId, vendorId, isAdmin, serviceNumber);
    promise
      .then(documents => {
        dispatch(completeLoad(documents));
      })
      .catch(() => {
        dispatch(failLoad());
      });
    return promise;
  };
