import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

export const MONTHLY_SERVICES_WEEKLY_DAY = 1;
export const MONTHLY_SERVICES_WEEKLY_WEEKDAY = 2;
export const MONTHLY_SERVICES_WEEKLY_WEEKENDDAY = 3;
export const MONTHLY_SERVICES_WEEKLY_SUNDAY = 4;
export const MONTHLY_SERVICES_WEEKLY_MONDAY = 5;
export const MONTHLY_SERVICES_WEEKLY_TUESDAY = 6;
export const MONTHLY_SERVICES_WEEKLY_WEDNESDAY = 7;
export const MONTHLY_SERVICES_WEEKLY_THURSDAY = 8;
export const MONTHLY_SERVICES_WEEKLY_FRIDAY = 9;
export const MONTHLY_SERVICES_WEEKLY_SATURDAY = 10;

export const MONTHLY_SERVICES_WEEKLY_FREQ = mapKeys(
  [
    {
      id: MONTHLY_SERVICES_WEEKLY_DAY,
      name: translate(createTranslationKey('Day', 'customers.dayOfMonthWeekList')),
      technicalName: 'Day',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_WEEKDAY,
      name: translate(createTranslationKey('WeekDay', 'customers.dayOfMonthWeekList')),
      technicalName: 'WeekDay',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_WEEKENDDAY,
      name: translate(createTranslationKey('WeekendDay', 'customers.dayOfMonthWeekList')),
      technicalName: 'WeekendDay',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_SUNDAY,
      name: translate(createTranslationKey('Sunday', 'customers.dayOfMonthWeekList')),
      technicalName: 'Sunday',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_MONDAY,
      name: translate(createTranslationKey('Monday', 'customers.dayOfMonthWeekList')),
      technicalName: 'Monday',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_TUESDAY,
      name: translate(createTranslationKey('Tuesday', 'customers.dayOfMonthWeekList')),
      technicalName: 'Tuesday',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_WEDNESDAY,
      name: translate(createTranslationKey('Wednesday', 'customers.dayOfMonthWeekList')),
      technicalName: 'Wednesday',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_THURSDAY,
      name: translate(createTranslationKey('Thursday', 'customers.dayOfMonthWeekList')),
      technicalName: 'Thursday',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_FRIDAY,
      name: translate(createTranslationKey('Friday', 'customers.dayOfMonthWeekList')),
      technicalName: 'Friday',
    },
    {
      id: MONTHLY_SERVICES_WEEKLY_SATURDAY,
      name: translate(createTranslationKey('Saturday', 'customers.dayOfMonthWeekList')),
      technicalName: 'Saturday',
    },
  ],
  'id',
);
