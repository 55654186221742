import styled from 'styled-components';
import { transparentize } from 'polished';

interface UploadAreaProps {
  isValidHover: boolean;
  isDragging: boolean;
  uploadPercentage?: number;
}

export const UploadArea = styled.div<UploadAreaProps>`
  height: 210px;
  border: 2px dashed ${p => (p.isDragging ? p.theme.brandWhite : p.theme.brandPrimary)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => transparentize(0.8, p.theme.brandPrimary)};
  margin: 25px 50px;
  position: relative;
  overflow: hidden;

  ${p =>
    p.isDragging &&
    `background-color: ${
      p.isValidHover ? transparentize(0.5, p.theme.brandPrimary) : transparentize(0.5, p.theme.brandAlert)
    };`}

  &:after {
    content: '';
    position: absolute;
    top: 3%;
    left: 5px;
    width: ${p => p.uploadPercentage}%;
    height: 94%;
    background-color: ${p => transparentize(0.5, p.theme.brandPrimary)};
    z-index: 1;
    transition: width 0.5s;
  }

  a {
    color: ${p => p.theme.brandPrimary};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 20px;
`;
