import { change } from 'redux-form';
import { map, uniqueId } from 'lodash-es';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Box } from 'src/core/components/styled/Box';
import { Checkbox, TypedField } from 'src/core/components';
import {
  DashboardDriverVehicleItemData,
  DashboardDriverVehicleItemContainer,
} from '../../styled/DashboardFilterMapbox';
import { DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME } from '../DashboardMapboxRouteDetailsForm';
import { getTimeFromMinutes } from 'src/dashboard/utils/dashboardMap';
import { RouteDriversVehiclesItem } from 'src/dashboard/interfaces/routesData';
import { Text } from 'src/core/components/styled';
import { timeFormat } from 'src/utils/services/validator';
import translate from 'src/core/services/translate';

interface Props {
  item: RouteDriversVehiclesItem;
  hasVehicleBreadCrumbs: boolean;
  hasDriversVehicles: boolean;
}

const DashboardDriverVehicleItem = ({ item, hasVehicleBreadCrumbs, hasDriversVehicles }: Props) => {
  const dispatch = useDispatch();

  const onVehicleBreadCrumbsChange = (_: any, isChecked: boolean) => {
    !isChecked && dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, `showAppStatus._${item.vehicleId}`, false));
  };

  const onDriversVehiclesChange = (_: any, isChecked: boolean) => {
    if (!isChecked) {
      dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, `vehicleBreadCrumbs._${item.vehicleId}`, false));
      dispatch(change(DASHBOARD_MAPBOX_ROUTE_DETAILS_FORM_NAME, `showAppStatus._${item.vehicleId}`, false));
    }
  };

  return (
    <DashboardDriverVehicleItemContainer>
      <DashboardDriverVehicleItemData withBottomBorder>
        <Box display="flex" flexDirection="column" width={220}>
          <Text size="sMedium" color="primary" singleLine title={`${item.driverName}/${item.vehicleName}`}>
            {item.driverName} / {item.vehicleName}
          </Text>

          {item.isRouteAssist && (
            <Text
              size="small"
              margin="xxSmall no no no"
              color="alert"
              singleLine
              title={`${item.driverName}/${item.vehicleName}`}
            >
              {translate('vehicles.assisted')}
            </Text>
          )}
        </Box>

        <TypedField
          name={`driversVehicles._${item.vehicleId}`}
          component={Checkbox}
          props={{
            size: 'small',
            margin: 'no',
            labelLeft: translate('dashboard.filterKeys.position'),
            block: true,
          }}
          onChange={onDriversVehiclesChange}
        />
      </DashboardDriverVehicleItemData>

      <DashboardDriverVehicleItemData leftMargin withBottomBorder>
        <Text color="grayDark" size="small">
          {translate('dashboard.filterKeys.totalMiles')}: {item.miles.toFixed(2) || 0} mi
        </Text>
        <TypedField
          name={`vehicleBreadCrumbs._${item.vehicleId}`}
          component={Checkbox}
          props={{
            size: 'small',
            margin: 'no',
            labelLeft: translate('dashboard.filterKeys.breadcrumbs'),
            disabled: !hasDriversVehicles,
          }}
          onChange={onVehicleBreadCrumbsChange}
        />
      </DashboardDriverVehicleItemData>

      <DashboardDriverVehicleItemData withBottomBorder leftMargin>
        <span></span>
        <TypedField
          name={`showAppStatus._${item.vehicleId}`}
          component={Checkbox}
          props={{
            size: 'small',
            margin: 'no',
            labelLeft: translate('dashboard.filterKeys.appStatus'),
            disabled: !hasVehicleBreadCrumbs || !hasDriversVehicles,
          }}
        />
      </DashboardDriverVehicleItemData>

      <DashboardDriverVehicleItemData leftMargin>
        <Text color="grayDark" size="small">
          {translate('common.time')}:
        </Text>
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          {map(item.routeTimes, time => {
            const label = `${moment(time.startTime).format(timeFormat)} - ${moment(time.endTime).format(timeFormat)}`;
            return (
              <Text key={uniqueId('route_time_')} margin="no no xxSmall" color="grayDarker" size="small" align="right">
                {label} - {getTimeFromMinutes(time.totalTime)}
              </Text>
            );
          })}
        </Box>
      </DashboardDriverVehicleItemData>
    </DashboardDriverVehicleItemContainer>
  );
};

export default DashboardDriverVehicleItem;
