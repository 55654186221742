import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import {
  Button,
  Grid,
  GridColumn,
  ModalFixedFooter,
  PanelSection,
  ModalSection,
} from '../../../core/components/styled';
import { Table } from '../../../core/components';
import { YData } from 'src/vendors/interfaces/YData';
import translate from 'src/core/services/translate';
import YDataTableRow from '../modals/YDataTableRow';

export const Y_DATA_FORM = 'YDataForm';

interface ComponentProps {
  currentYData: YData[];
  toggleYData?: (id: number, isChecked: any | boolean) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const YDataForm: React.FC<Props> = ({ handleSubmit, currentYData, toggleYData }) => {
  const yDataTableCells = [
    {
      name: 'yDataName',
      label: translate('insights.yDataName'),
      width: '70%',
      id: 'ydata-settings-header-data-type',
    },
    {
      name: 'switch',
      label: translate('insights.displayYData'),
      width: '30%',
      align: 'right',
      id: 'ydata-settings-header-display',
    },
  ];

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ModalSection padding="xxSmall" align="center">
        <PanelSection padding="small" centered>
          <GridColumn size="12/12">
            <Grid centered multiLine>
              <Table
                cells={yDataTableCells}
                rows={currentYData}
                rowComponent={YDataTableRow}
                rowProps={{
                  toggleYData,
                }}
              />
            </Grid>
          </GridColumn>
        </PanelSection>
      </ModalSection>

      <ModalFixedFooter>
        <Button color="primary" margin="no" type="submit">
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<any, ComponentProps>({ form: Y_DATA_FORM })(YDataForm);
