import { Fragment } from 'react';
import { camelize } from 'humps';
import { upperFirst } from 'lodash-es';

import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';
import { Grid, GridColumn, Text } from 'src/core/components/styled';
import { RouteTemplateBuilderServiceDetailsWrapper } from 'src/routes/components/styled/RouteTemplates';
import { ServiceContractAtLocation } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

interface Props {
  services: any;
}

export const RouteTemplateBuilderServiceDetails = ({ services }: Props) => {
  const translateSize = (technicalName: string, name: string) => {
    const size = getEquipmentSize(technicalName, name);

    const sizeTranslated = translate(
      `common.equipmentSizes.x${upperFirst(
        camelize(technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
      )}`,
      {
        size: size ? getEquipmentSize(technicalName, name) : 0,
      },
    );
    return sizeTranslated;
  };

  return (
    <RouteTemplateBuilderServiceDetailsWrapper>
      <Grid multiLine>
        <GridColumn width="19%" padding="no xxSmall" borderRight borderBottom darken>
          <Text weight="medium" align="center" block singleLine title={translate('common.service')} size="">
            {translate('common.service')}
          </Text>
        </GridColumn>
        <GridColumn width="19%" padding="no xxSmall" borderRight borderBottom darken>
          <Text weight="medium" align="center" block singleLine title={translate('haulerProfile.equipments.title')}>
            {translate('haulerProfile.equipments.title')}
          </Text>
        </GridColumn>
        <GridColumn width="16%" padding="no xxSmall" borderRight borderBottom darken>
          <Text weight="medium" align="center" block singleLine title={translate('common.material')}>
            {translate('common.material')}
          </Text>
        </GridColumn>
        <GridColumn width="16%" padding="no xxSmall" borderRight borderBottom darken>
          <Text weight="medium" align="center" block singleLine title={translate('common.size')}>
            {translate('common.size')}
          </Text>
        </GridColumn>
        <GridColumn width="18%" padding="no xxSmall" borderRight borderBottom darken>
          <Text weight="medium" align="center" block singleLine title={translate('containers.containers')}>
            {translate('containers.containers')}
          </Text>
        </GridColumn>

        <GridColumn width="12%" padding="no xxSmall" borderBottom darken>
          <Text weight="medium" align="center" block singleLine title={translate('common.status')}>
            {translate('common.status')}
          </Text>
        </GridColumn>
        {services.map((service: ServiceContractAtLocation, index: number) => (
          <Fragment key={index}>
            <GridColumn width="19%" padding="no xxSmall" borderRight>
              <Text
                block
                singleLine
                title={
                  translate(createTranslationKey(service.serviceType?.technicalName, 'common.serviceTypes')) || '-'
                }
              >
                {translate(createTranslationKey(service.serviceType?.technicalName, 'common.serviceTypes')) || '-'}
              </Text>
            </GridColumn>
            <GridColumn width="19%" padding="no xxSmall" borderRight>
              <Text
                block
                singleLine
                title={
                  translate(createTranslationKey(service.equipmentType?.technicalName, 'common.equipmentTypes')) || '-'
                }
              >
                {translate(createTranslationKey(service.equipmentType?.technicalName, 'common.equipmentTypes')) || '-'}
              </Text>
            </GridColumn>
            <GridColumn width="16%" padding="no xxSmall" borderRight>
              <Text
                block
                singleLine
                title={
                  translate(createTranslationKey(service.wasteMaterialType?.technicalName, 'common.wasteTypes')) || '-'
                }
              >
                {translate(createTranslationKey(service.wasteMaterialType?.technicalName, 'common.wasteTypes')) || '-'}
              </Text>
            </GridColumn>
            <GridColumn width="16%" padding="no xxSmall" borderRight>
              <Text
                block
                singleLine
                title={translateSize(service.equipmentSize?.technicalName, service.equipmentSize?.name) || '-'}
              >
                {translateSize(service.equipmentSize?.technicalName, service.equipmentSize?.name) || '-'}
              </Text>
            </GridColumn>
            <GridColumn width="18%" padding="no xxSmall" borderRight>
              <Text align="center" block singleLine title={service.numberOfContainers.toString()}>
                {service.numberOfContainers}
              </Text>
            </GridColumn>

            <GridColumn width="12%" padding="no xxSmall">
              <Text
                align="center"
                block
                singleLine
                title={
                  translate(
                    createTranslationKey(
                      service.serviceContractAccountStatusType?.technicalName,
                      'common.accountStatuses',
                    ),
                  ) || '-'
                }
              >
                {translate(
                  createTranslationKey(
                    service.serviceContractAccountStatusType?.technicalName,
                    'common.accountStatuses',
                  ),
                ) || '-'}
              </Text>
            </GridColumn>
          </Fragment>
        ))}
      </Grid>
    </RouteTemplateBuilderServiceDetailsWrapper>
  );
};
