import humps from 'humps';
import { size } from 'lodash-es';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { clearCommunityinsightsMapSelectedFeature } from 'src/insights/ducks/mapControl';
import { CommunityInsightItem } from 'src/insights/interfaces/communityInsights';
import { getBlobIdFromUrl } from 'src/utils/imageHelpers';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
} from 'src/common/components/map/MapGLPopup';
import { INSIGHT_ICONS } from 'src/common/constants';
import {
  MapInfoImage,
  MapInfoImageWrapper,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowIcon,
} from 'src/core/components/styled';
import { MATERIAL_CONTAMINATION_ID } from 'src/insights/constants';
import { RouteImagesModal } from 'src/routes/components/modals';
import { useSelector } from 'src/core/hooks/useSelector';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

const CommunityInsightsMapGLPopup: FC = () => {
  const communityInsights = useSelector(state => state.insights.communityInsights.insights.list);
  const { selectedFeature } = useSelector(state => state.insights.mapControl);
  const [insight, setInsight] = useState<CommunityInsightItem>();
  const dispatch = useDispatch();
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

  const toggleImageModal = () => {
    setIsImageModalOpen(!isImageModalOpen);
  };

  useEffect(() => {
    if (!!selectedFeature) {
      setInsight(communityInsights.find(insight => insight.id === selectedFeature.id));
    }
  }, [communityInsights, selectedFeature]);

  if (!insight || !selectedFeature) return null;

  const {
    address,
    contaminationSubTypes,
    images,
    imageUrl,
    locationFlaggingType,
    locationFlagTimeStamp,
    rawImageUrl,
    routeDate,
    routeName,
    statusId,
    vehicleName,
  } = insight;

  const locationFlaggingTypeName = translate(
    createTranslationKey(locationFlaggingType.technicalName, 'vendors.locationFlags'),
  );

  const isMaterialContamination = locationFlaggingType.id === MATERIAL_CONTAMINATION_ID;
  const hasMaterialSubtypes = isMaterialContamination && size(contaminationSubTypes) > 0;
  const contaminationSubTypesName =
    hasMaterialSubtypes && contaminationSubTypes
      ? contaminationSubTypes
          .map(contaminationSubType =>
            translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
          )
          .join(', ')
      : undefined;
  const contaminationSubTypeFormatted = hasMaterialSubtypes && `: ${contaminationSubTypesName}`;

  const imageInfo = {
    contaminationSubTypesName,
    issueName: locationFlaggingTypeName,
    locationName: address,
    routeName,
    timeStamp: locationFlagTimeStamp,
  };

  const issueImages = !!images?.length
    ? images.map((image: any) => ({
        ...imageInfo,
        imageBlobId: image.imageBlobId,
        imageUrl: image.imageUrl,
        rawImageUrl: image?.rawImageUrl || image.imageUrl,
      }))
    : !!imageUrl
    ? [
        {
          ...imageInfo,
          imageUrl,
          rawImageUrl: rawImageUrl || imageUrl,
          imageBlobId: getBlobIdFromUrl(imageUrl),
        },
      ]
    : [];

  const titleFormatted = !!contaminationSubTypeFormatted
    ? locationFlaggingTypeName.concat(contaminationSubTypeFormatted)
    : locationFlaggingTypeName;

  const beforeTitleContent = (
    <MapInfoWindowIcon size="small" src={INSIGHT_ICONS[humps.camelize(locationFlaggingType.technicalName)]} />
  );

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={insight.latitude}
        longitude={insight.longitude}
        onClose={() => dispatch(clearCommunityinsightsMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupContent verticalAllign padding="no xSmall">
            <MapGLPopupTitle title={titleFormatted} beforeTitleContent={beforeTitleContent} />
            <MapInfoWindowDetails padding="xSmall small no no">
              {!!routeDate && (
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('insights.date')}:</MapInfoWindowDetailLabel>
                  {moment(routeDate).format('MM/DD/YYYY')}
                </MapInfoWindowDetail>
              )}
              {!!address && (
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('insights.address')}:</MapInfoWindowDetailLabel>
                  {address}
                </MapInfoWindowDetail>
              )}
              {!!routeName && (
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('insights.route')}:</MapInfoWindowDetailLabel>
                  {routeName}
                </MapInfoWindowDetail>
              )}
              {!!vehicleName && (
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('insights.vehicle')}:</MapInfoWindowDetailLabel>
                  {vehicleName}
                </MapInfoWindowDetail>
              )}
              {statusId >= 0 && (
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('insights.status')}:</MapInfoWindowDetailLabel>
                  {statusId === 1 ? translate('insights.cleared') : translate('insights.open')}
                </MapInfoWindowDetail>
              )}
            </MapInfoWindowDetails>

            {!!issueImages.length && (
              <MapInfoImageWrapper
                overlay={issueImages?.length > 1 ? issueImages.length : undefined}
                onClick={toggleImageModal}
              >
                <MapInfoImage src={issueImages[0].imageUrl} />
              </MapInfoImageWrapper>
            )}
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {!!isImageModalOpen && <RouteImagesModal closeModal={toggleImageModal} images={issueImages} />}
    </>
  );
};

export default CommunityInsightsMapGLPopup;
