import React from 'react';
import { useDispatch } from 'react-redux';
import { loadVendor } from 'src/vendors/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import EditDeviceDetailsModal from './EditDeviceDetailsModal';

interface Props {
  closeModal(): void;
  deviceId?: string;
  deviceOwnerId?: number;
  phoneName?: string;
  serialNumber?: string;
  vendorId?: number;
}

const EditDeviceDetailsModalResolver: React.FC<Props> = ({
  closeModal,
  deviceId,
  deviceOwnerId,
  phoneName,
  serialNumber,
  vendorId,
}) => {
  const dispatch = useDispatch();
  const loadDependencies = React.useCallback(() => {
    const dependencies = [];
    if (vendorId) {
      dependencies.push(loadVendor(vendorId)(dispatch));
    }
    return Promise.all(dependencies);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      successComponent={EditDeviceDetailsModal}
      loadingComponent={PageLoadingOverlay}
      successProps={{ deviceId, closeModal, deviceOwnerId, phoneName, serialNumber, vendorId }}
      resolve={loadDependencies}
    />
  );
};

export default EditDeviceDetailsModalResolver;
