import { WrappedFieldProps, Validator } from 'redux-form';

import { FormError } from 'src/core/components/styled';
import { MaterialPickupSettings } from 'src/vendors/interfaces/BulkyItemScheduler';
import translate from 'src/core/services/translate';

interface Props extends WrappedFieldProps {
  showErrorBeforeSubmit?: boolean;
}

const BulkyItemPickupMaterialsAndPricingValidationField: React.FC<Props> = ({
  showErrorBeforeSubmit = false,
  meta: { submitFailed, error },
}) => {
  if ((submitFailed || showErrorBeforeSubmit) && error) {
    return <FormError>{error}</FormError>;
  }

  return null;
};

export default BulkyItemPickupMaterialsAndPricingValidationField;

export const bulkyItemPickupMaterialsAndPricingValidate: Validator = (_, { materialPickupSettings }) => {
  const isMaterialPickupSettingsEnabled = materialPickupSettings?.filter(
    (materialPickupSetting: MaterialPickupSettings) => materialPickupSetting?.categorySettings?.isEnabled,
  )?.length;

  if (!!isMaterialPickupSettingsEnabled) {
    return;
  }

  return translate('vendors.bulkyItemScheduler.atLeastOnePickupMaterialAndPricing');
};
