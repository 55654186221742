import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import * as PMDucks from '../../ducks/preventativeMaintenance';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadDeviceManufacturerTypeList } from 'src/fleet/ducks/vehicles';
import { loadVehicle, loadVehicleTypesForVendor, loadVehicleTypes, loadBinColors } from '../../ducks';
import { loadVehicleIssueStatuses, loadVehicleDrivers, loadVehicleIssueSubcategories } from '../../ducks/vehicleIssues';
import { loadVendor, loadFeatures, loadFleetMaintenance, loadYData } from 'src/vendors/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import {
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM,
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO,
} from 'src/fleet/constants/vehicle';
import { Y_DATA } from 'src/vendors/ducks/features';
import translate from '../../../core/services/translate';
import VehiclePage from './VehiclePage';

interface RouteParams {
  vehicleId: string;
}

const VehiclePageResolver: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: { vehicleId },
  },
  location: { search },
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const [isFleetMaintenanceFeatureEnabled, setIsFleetMaintenanceFeatureEnabled] = React.useState<boolean>(false);

  let { startDate, endDate } = getQueryParams(search);

  if (!startDate && !endDate) {
    startDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM;
    endDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO;
  }

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const { vehicleTypeId } = await loadVehicle(+vehicleId)(dispatch);
    const vehicleTypes = await loadVehicleTypesForVendor(vendorId)(dispatch);

    const dependencies: Promise<unknown>[] = [
      loadVehicleIssueStatuses()(dispatch),
      loadVehicleDrivers(+vehicleId)(dispatch),
      loadVehicleIssueSubcategories(+vehicleId)(dispatch),
      loadVehicleTypes()(dispatch),
      loadBinColors()(dispatch),
      loadDeviceManufacturerTypeList()(dispatch),
      PMDucks.loadVehiclePMStatus(+vehicleId)(dispatch),
      PMDucks.loadVehiclePMLogs(+vehicleId)(dispatch),
      loadVendor(vendorId)(dispatch),
      loadFeatures(vendorId)(dispatch).then(response => {
        const fleetMaintenanceFeature = (response || []).find(f => f.code === 'FleetMaintenance' && f.enabled);
        if (fleetMaintenanceFeature) {
          setIsFleetMaintenanceFeatureEnabled(fleetMaintenanceFeature.enabled);
          loadFleetMaintenance(vendorId)(dispatch);
        }

        const isYDataEnabled = (response || []).find(f => f.code === Y_DATA && f.enabled);
        if (isYDataEnabled) loadYData()(dispatch);
      }),
    ];

    const vehicleTypeIsActive = !!vehicleTypes.find(vehicle => vehicle.id === vehicleTypeId);
    const vehicleTypeHasActivePM = await PMDucks.checkForActivePM(vendorId, vehicleTypeId)(dispatch);

    if (vehicleTypeIsActive && vehicleTypeHasActivePM) {
      dependencies.push(PMDucks.loadVehicleTypeLevelPM(vendorId, vehicleTypeId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('vehicles.vehicleDetails')}</DocumentTitle>

      <TypedResolver
        successComponent={VehiclePage}
        successProps={{
          isFleetMaintenanceFeatureEnabled,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={loadDependencies}
        redirectOnError="/fleet/vehicles"
      />
    </>
  );
};

export default VehiclePageResolver;
