import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';
import doLoadUserTypes from '../services/userTypes';
import { UserType } from '../interfaces/UserType';

// Constants
const START_LOAD = 'common/userTypes/START_LOAD';
const COMPLETE_LOAD = 'common/userTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/userTypes/FAIL_LOAD';
const FAIL_SAVE = 'common/userTypes/FAIL_SAVE';
const START_SAVE = 'common/userTypes/START_SAVES';
const COMPLETE_SAVE = 'common/userTypes/COMPLETE_SAVE';
const RESET = 'common/userTypes/RESET';

// Initial State
const initialState = {
  isLoading: false,
  userTypes: [],
};

interface UserTypes {
  isLoading: boolean;
  userTypes: UserType[] | [];
}

// Reducer
export const reducer = (state: UserTypes = initialState, action: AnyAction): UserTypes => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case START_SAVE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          userTypes: action.userTypes,
        },
      });
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          userTypes: [],
        },
      });
    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case RESET:
      return update(state, {
        $merge: initialState,
      });
    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (userTypes: UserTypes[]) => ({
  type: COMPLETE_LOAD,
  userTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const reset = () => {
  return {
    type: RESET,
  };
};

// Async Actions
export const loadUserTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadUserTypesPromise = doLoadUserTypes();
  loadUserTypesPromise.then(userTypes => dispatch(completeLoad(userTypes))).catch(() => dispatch(failLoad()));
  return loadUserTypesPromise;
};
