import { http } from '../../core/services/http';
import { Container, ContainerToSave } from '../interfaces/containers';
import { transformLoadContainerHistory } from './transformContainer';

export const createContainer = (container: ContainerToSave) => http.post('/inventories', container);

export const updateContainer = (container: Container) => http.put(`/inventories/${container.id}`, container);

export const loadContainerNew = (containerId: number) =>
  http.get(`/inventories/${containerId}`).then(response => response.data);

export const deleteContainer = (containerId: number) => http.delete(`/inventories/${containerId}`);

export const loadContainerHistory = (containerId: number, startDate: string, endDate: string) =>
  http
    .get(`/inventories/container/${containerId}`, { params: { startDate, endDate } })
    .then(response => transformLoadContainerHistory(response.data));

export const loadContainerActivity = (containerId: number) =>
  http.get(`/inventories/container/${containerId}/activity`).then(response => response.data);
