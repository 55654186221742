export const ROUTE_BUILDER_SETTINGS_FORM_NAME = 'routeBuilderParametersForm';

export const ROUTE_SEQUENCE_SETTINGS_FORM_NAME = 'routeSequenceParametersForm';

type NumberKeyMap = {
  [key: number]: string;
};

export const TURN_TYPES: NumberKeyMap = {
  1: 'local',
  2: 'arterial',
  3: 'highway',
  4: 'uTurn',
};

export const DIRECTIONS: NumberKeyMap = {
  1: 'straight',
  2: 'right',
  3: 'left',
};
