import { isEqual } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { MapGL } from 'src/common/components/map/MapGL';
import { getFeatureCollection, getLineStringFeature, getMapBounds } from 'src/common/components/map/util';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { theme } from 'src/core/styles';
import { MapGLWrapper } from 'src/customers/components/styled';
import { MAP_CITY_ZOOM_IN } from 'src/core/constants';

interface Props {
  address: number[][][];
  isMarker?: boolean;
  mapZoom?: number;
}

const OverviewMapGL: FC<Props> = ({ address, isMarker, mapZoom }) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);

  const [viewport, setViewport] = useState<MapGLViewport>({});

  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);

  const handleSatelliteViewChange = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  // fit map bounds

  useEffect(() => {
    const middleSegment = address[Math.round((address.length - 1) / 2)];
    const points: { latitude: number; longitude: number }[] =
      [
        {
          latitude: middleSegment[Math.round((middleSegment.length - 1) / 2)][1],
          longitude: middleSegment[Math.round((middleSegment.length - 1) / 2)][0],
        },
      ] || [];

    if (points.length) {
      const bounds = getMapBounds(points, {
        padding: 10,
        capZoom: mapZoom || MAP_CITY_ZOOM_IN,
      });
      !isEqual(viewport, bounds) &&
        setViewport({
          ...viewport,
          ...bounds,
        });
    }
  }, [viewport, address, isMarker, mapZoom]);

  // Add a marker/segment to the map

  useEffect(() => {
    if (map && isMarker)
      new mapboxgl.Marker({ color: theme.brandPrimary }).setLngLat([address[0][0][0], address[0][0][1]]).addTo(map);
    else {
      const addressesGeoJson = getFeatureCollection(
        address.map((segment, index) => getLineStringFeature(index, segment, { segmentNumber: index })),
      );

      map?.on('load', () => {
        if (!map.getSource(`overview-route`))
          map.addSource(`overview-route`, {
            type: 'geojson',
            data: addressesGeoJson,
          });
        if (!map.getLayer(`overview-line`))
          map.addLayer({
            id: `overview-line`,
            type: 'line',
            source: `overview-route`,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': theme.brandGreenDark,
              'line-width': 4,
              'line-opacity': 0.45,
            },
          });
      });
    }
  }, [address, isMarker, map]);

  return (
    <MapGLWrapper>
      <MapGL
        key="overview-map"
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={handleSatelliteViewChange}
      />
    </MapGLWrapper>
  );
};

export default OverviewMapGL;
