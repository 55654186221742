import React from 'react';
import { useState } from 'react';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { Driver } from 'src/fleet/interfaces/Driver';
import { DropdownOption } from 'src/core/components/Dropdown';
import { FILTER_VEHICLE_TYPE_ID } from 'src/common/constants';
import { getExcludedFiltersIds, getGlobalLockedFiltersIds } from 'src/common/utils/filters';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { loadRoutesTrackerForMessages } from 'src/routes/services/routeTracker';
import { loadVehicleTypesForVendor } from 'src/fleet/services/vehicleTypes';
import { MessageType } from '../interfaces/MessateType';
import { ROUTE } from 'src/fleet/constants';
import { RouteForMessages } from 'src/routes/interfaces/Route';
import { Text } from 'src/core/components/styled';
import { UnconnectedDropdown } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehicleType } from 'src/fleet/interfaces/VehicleTypes';
import loadDrivers from 'src/fleet/services/loadDrivers';
import translate from 'src/core/services/translate';

const messageRecipientOptions: DropdownOption[] = [
  {
    value: MessageType.All,
    label: translate('messaging.messageTypes.all'),
  },
  {
    value: MessageType.Direct,
    label: translate('messaging.messageTypes.driver'),
  },
  {
    value: MessageType.VehicleType,
    label: translate('messaging.messageTypes.vehicleType'),
  },
  {
    value: MessageType.Route,
    label: translate('messaging.messageTypes.route'),
  },
];

const placeholders = {
  [MessageType.All]: translate('messaging.recipientPlaceholders.all'),
  [MessageType.Direct]: translate('messaging.recipientPlaceholders.driver'),
  [MessageType.VehicleType]: translate('messaging.recipientPlaceholders.vehicleType'),
  [MessageType.Route]: translate('messaging.recipientPlaceholders.route'),
};

export const MessageRecipient: React.FC<{
  messageType: MessageType;
  messageRecipient?: number;
  onChange: (messageType: MessageType, messageRecipient: number) => void;
}> = ({ onChange, messageType, messageRecipient }) => {
  const vendorId = useSelector(currentVendorId);
  const filtersPreferences = useSelector(s => s.common.filters.filters);

  const [isLoadingSecondaryInformation, setIsLoadingSecondaryInformation] = useState(false);
  const [secondaryOptions, setSecondaryOptions] = useState<DropdownOption[]>([]);

  const changeMessageType = (messageType: number) => {
    onChange(messageType, 0);

    if (messageType !== MessageType.All) setIsLoadingSecondaryInformation(true);

    if (messageType === MessageType.Route) {
      loadRoutesTrackerForMessages(
        vendorId,
        undefined,
        getGlobalLockedFiltersIds(filtersPreferences, FILTER_VEHICLE_TYPE_ID).join(','),
      ).then(info => {
        setSecondaryOptions(info.map((r: RouteForMessages) => ({ value: r.id, label: r.name })));
        setIsLoadingSecondaryInformation(false);
      });
    }

    if (messageType === MessageType.Direct) {
      loadDrivers(vendorId).then((drivers: Driver[]) => {
        setSecondaryOptions(drivers.filter(dr => dr.isActiveBoolean).map(d => ({ value: d.id, label: d.name })));
        setIsLoadingSecondaryInformation(false);
      });
    }

    if (messageType === MessageType.VehicleType) {
      loadVehicleTypesForVendor(vendorId).then((vehicleTypes: VehicleType[]) => {
        const excludedVehicleFiltersIds = getExcludedFiltersIds(
          vehicleTypes,
          filtersPreferences,
          FILTER_VEHICLE_TYPE_ID,
        );
        setSecondaryOptions(
          vehicleTypes
            .filter(vt => vt.vehicleRoleType === ROUTE && !excludedVehicleFiltersIds.includes(vt.id))
            .map(vt => ({ value: vt.id, label: vt.name })),
        );
        setIsLoadingSecondaryInformation(false);
      });
    }
  };

  const getSecondaryContent = () => {
    if (!messageType || messageType === MessageType.All) return null;

    if (isLoadingSecondaryInformation) {
      return (
        <Text margin="no medium">
          <Loader />
        </Text>
      );
    }

    return (
      <UnconnectedDropdown
        value={messageRecipient}
        margin="no no no small"
        width="180px"
        options={secondaryOptions}
        placeholder={placeholders[messageType]}
        onChange={v => onChange(messageType, v)}
      />
    );
  };

  return (
    <>
      <UnconnectedDropdown
        disabled={isLoadingSecondaryInformation}
        placeholder={translate('messaging.selectRecipient')}
        margin="no no no small"
        width="180px"
        value={messageType}
        options={messageRecipientOptions}
        onChange={changeMessageType}
      />
      {getSecondaryContent()}
    </>
  );
};
