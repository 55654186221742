import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ActionButtonTooltip, UnconnectedCheckbox } from '../../../../core/components';
import { AppState } from '../../../../store';
import { FLEET_VEHICLES_DELETE } from '../../../../account/constants';
import {
  PM_STATUS_DUE,
  PM_STATUS_PAST_DUE,
  PM_TYPE_MILEAGE,
  PM_TYPE_ENGINE_HOURS,
  PM_TYPE_DATE_CHECKS,
} from '../../../constants/preventativeMaintenance';
import { PermissionGuard } from '../../../../account/components';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { useVehiclesTableColumns } from '../../utils/vehiclesPageHooks';
import { Vehicle } from '../../../interfaces/Vehicles';
import translate from '../../../../core/services/translate';

interface Props extends Vehicle {
  deleteVehicle(id: number): void;
  isEngineHoursDataVisible: boolean;
  isFuelDataVisible: boolean;
  isNotSupportAndIsNotViewOnly: boolean;
  isOdometerReadingDataVisible?: boolean;
  onCheckChange: (event: any, id: number) => void;
}

const VehiclesPageTableRow: React.FC<Props> = ({
  deleteVehicle,
  isEngineHoursDataVisible,
  isFuelDataVisible,
  isNotSupportAndIsNotViewOnly,
  isOdometerReadingDataVisible,
  onCheckChange,
  ...vehicle
}) => {
  const dispatch = useDispatch();
  const { isAdmin, widths, columns } = useVehiclesTableColumns(
    isNotSupportAndIsNotViewOnly,
    undefined,
    undefined,
    undefined,
    isEngineHoursDataVisible,
    isFuelDataVisible,
    isOdometerReadingDataVisible,
  );

  const vendorHasActivePM = useSelector((state: AppState) => state.fleet.preventativeMaintenance.vendorHasActivePM);

  let warningVisible = false;
  let warningType: string | undefined = undefined;
  let warningDescriptor: string | undefined = undefined;

  if (vehicle.preventativeMaintenance) {
    const {
      preventativeMaintenanceType: { id: typeId },
      preventativeMaintenanceStatusType: { id: statusTypeId },
      mileage,
      engineHours,
      date,
    } = vehicle.preventativeMaintenance;

    warningVisible = [PM_STATUS_DUE, PM_STATUS_PAST_DUE].indexOf(statusTypeId) !== -1;

    if (warningVisible) {
      if (typeId !== PM_TYPE_DATE_CHECKS) {
        if (statusTypeId === PM_STATUS_DUE) {
          warningType = 'xDueAt';
        } else if (statusTypeId === PM_STATUS_PAST_DUE) {
          warningType = 'xPastDueAt';
        }
      } else {
        if (statusTypeId === PM_STATUS_DUE) {
          warningType = 'xDueOn';
        } else if (statusTypeId === PM_STATUS_PAST_DUE) {
          warningType = 'xPastDueOn';
        }
      }

      if (typeId === PM_TYPE_MILEAGE && mileage) {
        warningDescriptor = `${mileage} ${translate('vehicles.preventativeMaintenanceLabels.miles').toLowerCase()}`;
      } else if (typeId === PM_TYPE_ENGINE_HOURS && engineHours) {
        warningDescriptor = `${engineHours} ${translate('vehicles.preventativeMaintenanceLabels.hours').toLowerCase()}`;
      } else if (typeId === PM_TYPE_DATE_CHECKS && date) {
        warningDescriptor = moment(date).format('MM/DD/YYYY');
      }
    }
  }

  const numberOfOpenIssues = translate(
    `vehicles.${vehicle.numberOfOpenIssues === 1 ? 'openIssueNumber' : 'openIssuesNumber'}`,
    {
      count: vehicle.numberOfOpenIssues,
    },
  );

  const numberOfPendingIssues = translate(
    `vehicles.${vehicle.numberOfPendingIssues === 1 ? 'pendingIssueNumber' : 'pendingIssuesNumber'}`,
    {
      count: vehicle.numberOfPendingIssues,
    },
  );

  const vehicleRegplate = vehicle.regplate;
  const vehicleIsAvailable = vehicle.isAvailable;

  return (
    <TableRow height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width={isNotSupportAndIsNotViewOnly ? widths[0] : '0'} justifyContent="center">
        {isNotSupportAndIsNotViewOnly && (
          <UnconnectedCheckbox
            onChange={event => onCheckChange(event, vehicle.id)}
            checked={vehicle.checked}
            size="small"
          />
        )}
      </TableCell>
      <TableCell width={widths[1]} title={vehicleRegplate}>
        {vehicleRegplate.length > 35 ? vehicleRegplate.substr(0, 35 - 1) + '...' : vehicleRegplate}
      </TableCell>

      <TableCell flex={isOdometerReadingDataVisible ? widths[2] : widths[12]}>
        {vehicle.vehicleType ? vehicle.vehicleType.name : ''}
      </TableCell>

      {isOdometerReadingDataVisible && (
        <TableCell flex={widths[3]} hidden={columns[3].visibility === 'none'}>
          {vehicle.mileage || '-'}
        </TableCell>
      )}

      {isEngineHoursDataVisible && (
        <TableCell flex={isFuelDataVisible ? widths[4] : widths[10]} hidden={columns[4].visibility === 'none'}>
          {vehicle.engineHours || '-'}
        </TableCell>
      )}

      {isFuelDataVisible && (
        <TableCell flex={isEngineHoursDataVisible ? widths[5] : widths[10]} hidden={columns[5].visibility === 'none'}>
          {vehicle.fuelEfficiency?.toFixed(1) ?? '-'}
        </TableCell>
      )}

      <TableCell
        width={!isEngineHoursDataVisible && !isFuelDataVisible ? widths[11] : widths[6]}
        hidden={columns[6].visibility === 'none'}
      >
        <Text weight="size" singleLine>
          {!!vehicle.yDeviceId && (
            <>
              <Text margin="no xSmall no no" color="grayDark" weight="medium">
                Y
              </Text>
              <Text size="medium" title={isAdmin ? vehicle.yDeviceId : ''}>
                {isAdmin && vehicle.yDeviceId}
              </Text>
              <br />
            </>
          )}
          {!!vehicle.zDeviceId && (
            <>
              <Text margin="no xSmall no no" color="grayDark" weight="medium">
                Z
              </Text>
              <Text size="medium" title={isAdmin ? vehicle.zDeviceId : ''}>
                {isAdmin && vehicle.zDeviceId}
              </Text>
            </>
          )}
          {!vehicle.yDeviceId && !vehicle.zDeviceId && '-'}
        </Text>
      </TableCell>

      <TableCell flex={widths[7]} wrap="wrap">
        <Text>
          {vehicle.isActive ? translate('common.active') : translate('common.inactive')}
          <br />
          <Text size="small" color={vehicleIsAvailable ? 'success' : 'alert'}>
            {vehicleIsAvailable ? translate('common.available') : translate('common.unavailable')}
          </Text>
        </Text>
      </TableCell>

      <TableCell width={widths[8]} vertical align="center">
        <Text size="sMedium" singleLine>
          {!!vehicle.numberOfOpenIssues && (
            <Text title={numberOfOpenIssues}>
              {numberOfOpenIssues}
              <br />
            </Text>
          )}

          {!!vehicle.numberOfPendingIssues && (
            <Text color="grayDarker" title={numberOfPendingIssues}>
              {numberOfPendingIssues}
            </Text>
          )}
        </Text>
      </TableCell>

      <TableCell flex={widths[9]} align="right">
        {vehicle.locationName && (
          <TableActionButton color="grayDarker">
            <ActionButtonTooltip
              icon="facility"
              iconSize="sMedium"
              customViewBox="0 5 15 10"
              tooltip={`${vehicle.locationName} #${vehicle.locationId}`}
              tooltipAsString
            />
          </TableActionButton>
        )}

        {vendorHasActivePM && warningVisible && (
          <TableActionButton color="grayDarker">
            <ActionButtonTooltip
              icon="exclamationTriangleV2"
              iconSize="sMedium"
              tooltip={warningType}
              tooltipProps={{ descriptor: warningDescriptor }}
            />
          </TableActionButton>
        )}

        <TableActionButton
          color="grayDarker"
          id={`open-vehicle-details-${vehicle.id}-button`}
          onClick={event => {
            event.stopPropagation();
            dispatch(push(`/fleet/vehicles/${vehicle.id}`));
          }}
        >
          <ActionButtonTooltip
            icon="vehicleInspection"
            iconSize="sMedium"
            tooltip="vehicleDetails"
            customViewBox="0 0 24 24"
          />
        </TableActionButton>

        <PermissionGuard permission={FLEET_VEHICLES_DELETE}>
          <TableActionButton
            color="grayDarker"
            id={`delete-vehicle-${vehicle.id}-button`}
            onClick={event => {
              event.stopPropagation();
              deleteVehicle(vehicle.id);
            }}
          >
            <ActionButtonTooltip icon="delete" iconSize="sMedium" tooltip="delete" />
          </TableActionButton>
        </PermissionGuard>
      </TableCell>
    </TableRow>
  );
};

export default VehiclesPageTableRow;
