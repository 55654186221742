import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { Dropdown, TypedField } from '../../core/components';
import translate from '../../core/services/translate';
import { AppState } from '../../store';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../ducks';

interface VehicleTypeForVendorDropdownProps extends RouteComponentProps, DropDownFieldProps {
  acceptedVehicleTypes?: string[];
  excludeVehicleTypes?: string[];
  label?: string;
  placeholder?: string;
  vehicleRoleTypeId?: string[];
  vehicleTypesForVendor?: TechnicalType[];
  withLabel?: boolean;
  withPlaceholder?: boolean;
  withTechnicalName?: boolean;
  disabled?: boolean;
  minWidth?: string;
}

class VehicleTypeForVendorDropdown extends PureComponent<VehicleTypeForVendorDropdownProps> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: any) => this.props.input.onChange(value);

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      vehicleTypesForVendor = [],
      dropdownProps,
      withTechnicalName,
      disabled,
      minWidth,
    } = this.props;

    const vehicleTypesOptions = vehicleTypesForVendor.map((vehicleType: TechnicalType) => ({
      label: vehicleType.name,
      value: withTechnicalName ? vehicleType.technicalName : vehicleType.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          disabled,
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicleType') : undefined),
          label: label || (withLabel ? translate('vehicles.vehicleType') : undefined),
          options: vehicleTypesOptions,
          width: minWidth ? minWidth : undefined,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: VehicleTypeForVendorDropdownProps) => {
  const vehicleTypesForVendor =
    (vehicleTypesForVendorWithRoleTypeSelector as any)(
      state.fleet.vehicleTypesForVendor,
      ownProps.vehicleRoleTypeId,
      ownProps.excludeVehicleTypes,
      ownProps.acceptedVehicleTypes,
    ) || [];

  return {
    vehicleTypesForVendor,
  };
};

export default withRouter(connect(mapStateToProps)(VehicleTypeForVendorDropdown));
