import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { get, identity } from 'lodash-es';
import { AnyAction } from 'redux';

// Actions
const PUSH = 'core/lastLocations/PUSH';
const REMOVE = 'core/lastLocations/REMOVE';

interface LastLocationsState {
  [pathname: string]: string;
}

// Initial state
const initialState: LastLocationsState = {};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case PUSH:
      return update(state, {
        [action.pathname]: { $set: action.url },
      });
    case REMOVE:
      return update(state, {
        $unset: [action.location],
      });

    default:
      return state;
  }
};

// Action creators
export const addLastLocation = (pathname: string, url: string) => ({
  type: PUSH,
  pathname,
  url,
});

// Action creators
export const removeLastLocation = (location: string) => ({
  type: REMOVE,
  location,
});

// Selectors
const getLastUrl = (coreState: LastLocationsState, pathname: string) =>
  get(coreState.lastLocations, pathname, pathname);

export const getLastUrlSelector = createSelector(getLastUrl, identity);
