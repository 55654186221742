import { identity, get, pick, has } from 'lodash-es';
import { createSelector } from 'reselect';
import { UNKNOWN_ID } from 'src/common/constants/wasteTypes';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { Group } from '../interfaces/Groups';

const getSnowOrSweeperRouteTemplateEditorFomInitialValuesSelector = (
  routeTemplateState: any,
  geoFenceState: any,
  shouldRecreateRoutes: boolean,
  isSnowPlowRoute: boolean,
) => {
  const routeTemplate = get(routeTemplateState, 'routeTemplate', {});
  return {
    ...pick(
      get(routeTemplateState, 'routeTemplate', {}),
      'routeTemplateName',
      'startDate',
      'startingLocationId',
      'endingLocationId',
      'vendorServiceZoneId',
      'intermediateLocationId',
      'supervisorId',
      'routePriorityTypeId',
    ),
    groupIds: get(routeTemplateState, 'routeTemplate.groups', []).map((group: Group) => group.id),
    shouldRecreateRoutes,
    isEnabled: has(routeTemplate, 'isEnabled') ? routeTemplate.isEnabled : true,
    vehicleTypeId: isSnowPlowRoute ? SNOW_PLOW_ID : STREET_SWEEPER_ID,
    wasteMaterialTypeId: UNKNOWN_ID,
    geoFence:
      !!routeTemplateState.routeTemplate && geoFenceState.geoFence && routeTemplateState.routeTemplate.id
        ? geoFenceState.geoFence
        : {
            geoFenceCoordinates: [],
          },
  };
};

const snowOrSweeperRouteTemplateEditorFomInitialValuesSelector = createSelector(
  getSnowOrSweeperRouteTemplateEditorFomInitialValuesSelector,
  identity,
);

export default snowOrSweeperRouteTemplateEditorFomInitialValuesSelector;
