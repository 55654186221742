import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { ReportsList } from '../interfaces/reportsList';

import doLoadReports from '../services/loadReports';
import { createReports as doCreateReports } from '../services/exportReports';

// Actions
const START_LOAD = 'insights/reportsList/START_LOAD';
const COMPLETE_LOAD = 'insights/reportsList/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/reportsList/FAIL_LOAD';
const START_EXPORT = 'insights/reportsList/START_EXPORT';
const COMPLETE_EXPORT = 'insights/reportsList/COMPLETE_EXPORT';
const FAIL_EXPORT = 'insights/reportsList/FAIL_EXPORT';
// Initial state
const initialState = {
  isLoading: false,
  reports: undefined,
  isExporting: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          reports: action.reports,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (reports: ReportsList[]) => ({
  type: COMPLETE_LOAD,
  reports,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});
const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const loadReportsList = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadReportsPromise = doLoadReports(vendorId);
  loadReportsPromise
    .then(reports => {
      dispatch(completeLoad(reports));
    })
    .catch(() => dispatch(failLoad()));
  return loadReportsPromise;
};

export const createExports =
  (vendorId: number, reportTypeId: number, emailAddresses: string[], configuration: any) => (dispatch: Dispatch) => {
    dispatch(startExport());
    const createReportsPromise = doCreateReports(vendorId, reportTypeId, emailAddresses, configuration);
    createReportsPromise
      .then(() => {
        dispatch(completeExport());
      })
      .catch(() => dispatch(failExport()));
    return createReportsPromise;
  };
