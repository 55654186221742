import { filter, get, map, omit, pickBy, size, uniq } from 'lodash-es';
import { formValueSelector, isDirty, reset, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import update from 'immutability-helper';

import { BILLING_MODULE } from 'src/vendors/ducks/features';
import {
  BinLocationMappingTypeId,
  COLLECTION_POINT,
  CURBSIDE_LOOKUP,
  LOCATION_ADDRESS,
  SET_BY_USER,
} from 'src/common/constants/binMappingTypes';
import { BULK_ID, RESIDENTIAL_ID, STREET_ID, STREET_SWEEPING_ID } from 'src/common/constants/serviceTypes';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { CustomerLocation, CustomerLocationServiceContract } from 'src/customers/interfaces/Customers';
import { deleteCollectionPoints, resetService, saveService, serviceDetailsSelector } from 'src/customers/ducks';
import { EditableServiceLocationMap } from 'src/customers/components/EditableServiceLocationMap';
import { formatDaysOfServiceByFrequencyType } from 'src/customers/services/daysOfServiceByFrequencyType';
import { getPrimaryLocationCoordinates } from '../PickupLocationEditorMapbox';
import { getTypesAsString } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapRouteSegmentsGLPopup';
import { MONTHLY } from 'src/common/constants';
import { MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH } from 'src/customers/constants';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { Panel, PanelSection } from 'src/core/components/styled';
import { SERVICE_DETAILS_EDITOR_FORM } from '../forms/ServiceDetailsEditorForm';
import { Service, ServiceContainer, ServicePickupLocation } from 'src/customers/interfaces/Services';
import { ServiceDetailsEditorForm } from '../forms';
import { ServiceEditorRouteParams } from './ServiceDetailsEditorPageResolver';
import { technicalNameByPickupFrequencyTypeIdSelector } from 'src/common/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import RatesFeesSection from './servicesPageSections/RatesFeesSection';
import ServiceDetailsSubmitSection from './servicesPageSections/ServiceDetailsSubmitSection';
import StreetServiceDetailsEditorForm from '../forms/StreetServiceDetailsEditorForm';
import translate from 'src/core/services/translate';
import WorkOrdersSection from './servicesPageSections/WorkOrdersSection';

const formSelector = formValueSelector(SERVICE_DETAILS_EDITOR_FORM);

const ServiceDetailsEditorPage = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();

  const billingModuleFeature = useSelector(state =>
    state.vendors.features.features.find(feature => feature.code === BILLING_MODULE),
  );
  const billingModuleEnabled = get(billingModuleFeature, 'enabled', false);

  const isServiceFormDirty = useSelector(isDirty(SERVICE_DETAILS_EDITOR_FORM));
  const customerLocation: CustomerLocation | undefined = useSelector(state => state.customers.location.location);
  const isSaving: boolean = useSelector(state => state.customers.services.isSaving);
  const pickupFrequencyTypeName: string = useSelector(state => {
    const pickupFrequencyTypeId = formSelector(state, 'pickupFrequencyTypeId');
    return technicalNameByPickupFrequencyTypeIdSelector(state.common.pickupFrequencyTypes, pickupFrequencyTypeId) || '';
  });
  const rates = useSelector(state => formSelector(state, 'rates')) || [];

  const vehicleTypeId = useSelector(
    state => state.routes.route?.route?.vehicleTypeId || state.routes.routeTemplate?.routeTemplate?.vehicleTypeId,
  );

  const service = useSelector(state => serviceDetailsSelector(state.customers.service)) as unknown as
    | Service
    | undefined;
  const vendorId: number | undefined = useSelector(state =>
    currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  );
  const isContainerManagementFeatureEnabled = useSelector(
    state => !!(state.vendors.features.features || []).find(f => f.code === 'ContainerManagement' && f.enabled),
  );

  const customer = useSelector(state => state.customers.customer.customer);

  const { locationId, serviceId } = useParams<ServiceEditorRouteParams>();

  useEffect(() => {
    return () => {
      resetService();
    };
  }, []);

  const initialPickupLocation: ServicePickupLocation | undefined =
    service && service.pickupLocation
      ? service.pickupLocation
      : customerLocation
      ? {
          addressLatitude: customerLocation ? customerLocation.address.latitude : 0, // this is needed for TS
          addressLongitude: customerLocation ? customerLocation.address.longitude : 0, // this is needed for TS
          curbsideLatitude: customerLocation.address.binLatitude,
          curbsideLongitude: customerLocation.address.binLongitude,
          binLocationMappingTypeId: 1,
        }
      : undefined;

  if (service && !service.pickupLocation && initialPickupLocation) {
    initialPickupLocation.binLocationMappingTypeId =
      initialPickupLocation.curbsideLatitude &&
      (service.serviceTypeId === RESIDENTIAL_ID || service.serviceTypeId === BULK_ID)
        ? CURBSIDE_LOOKUP
        : LOCATION_ADDRESS;
  }

  const [collectionPointsToDelete, setCollectionPointsToDelete] = useState<number[]>([]);
  const [isEditingPickupLocation, setIsEditingPickupLocation] = useState<boolean>(false);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [pickupLocation, setPickupLocation] = useState<ServicePickupLocation | undefined>(initialPickupLocation);
  const [pickupLocationsDirty, setPickupLocationsDirty] = useState<boolean>(false);
  const [serviceContainers, setServiceContainers] = useState<ServiceContainer[]>(service?.containers || []);
  const [serviceTypeId, setServiceTypeId] = useState<number | undefined>(service?.serviceTypeId);
  const [isServiceTypeChanged, setIsServiceTypeChanged] = useState<boolean>(false);

  // shouldn't come to this, we load data in the Resolver
  if (!pickupLocation || !customerLocation) return null;

  const onCancel = async (isSave?: boolean) => {
    if (isServiceFormDirty && !isSave) {
      if (await confirm(translate('common.alertMessages.leavePageWithoutSaving'))) {
        reset(SERVICE_DETAILS_EDITOR_FORM);
        return goBack();
      } else {
        return null;
      }
    } else return goBack();
  };

  const onAddressChange = (
    lat: number,
    lng: number,
    index: number,
    binLocationMappingTypeId?: BinLocationMappingTypeId,
  ) => {
    const updatedServiceContainers: ServiceContainer[] = size(serviceContainers)
      ? update(serviceContainers, {
          [index]: {
            lat: { $set: lat },
            lng: { $set: lng },
            binMappingTypeId: { $set: binLocationMappingTypeId || 1 },
          },
        })
      : [{ id: 0, lat, lng }];
    dispatch(change(SERVICE_DETAILS_EDITOR_FORM, 'containers', updatedServiceContainers));
    setServiceContainers(updatedServiceContainers);
  };

  const onSubmit = async (formData: any) => {
    const locId = locationId || (service && service.locationId);
    const futureAccountStatus = get(formData, 'futureAccountStatus', {});

    let containerLatitude = 0;
    let containerLongitude = 0;

    if (
      pickupLocation.binLocationMappingTypeId === SET_BY_USER &&
      pickupLocation.userGeneratedLatitude &&
      pickupLocation.userGeneratedLongitude
    ) {
      containerLatitude = pickupLocation.userGeneratedLatitude;
      containerLongitude = pickupLocation.userGeneratedLongitude;
    } else if (
      pickupLocation.binLocationMappingTypeId === CURBSIDE_LOOKUP &&
      pickupLocation.curbsideLatitude &&
      pickupLocation.curbsideLongitude
    ) {
      containerLatitude = pickupLocation.curbsideLatitude;
      containerLongitude = pickupLocation.curbsideLongitude;
    } else if (pickupLocation.binLocationMappingTypeId === COLLECTION_POINT && pickupLocation.collectionWaypoint) {
      containerLatitude = pickupLocation.collectionWaypoint.latitude;
      containerLongitude = pickupLocation.collectionWaypoint.longitude;
    } else {
      containerLatitude = pickupLocation.addressLatitude;
      containerLongitude = pickupLocation.addressLongitude;
    }

    const serviceData: ServiceContainer = {
      id: 0, // to conform to the interface
      lat: containerLatitude,
      lng: containerLongitude,
      binMappingTypeId: pickupLocation.binLocationMappingTypeId,
    };

    const contArr: ServiceContainer[] = !serviceContainers.length ? [serviceData] : serviceContainers;
    const containers = contArr.map(c => ({
      ...c,
      ...(isContainerManagementFeatureEnabled
        ? { binNumber: service?.binNumber || null }
        : { binNumber: formData.binNumber || null }),
      streetSegmentActivitySettings: formData.containers[0]?.streetSegmentActivitySettings,
    }));

    const { weekOfService, everyXMonths, monthlyServicesWeeklyFrequencyId: dayOfWeek } = formData;

    const formattedMonthlyServicesMonthlyFrequencyIds =
      typeof formData.monthlyServicesMonthlyFrequencyIds === 'number'
        ? [formData.monthlyServicesMonthlyFrequencyIds]
        : formData.monthlyServicesMonthlyFrequencyIds;

    let data: any = {
      ...omit(formData, 'positionTypeId'),
      binNumber: (isContainerManagementFeatureEnabled ? service?.binNumber : formData.binNumber) || null,
      containers,
      monthly:
        pickupFrequencyTypeName === MONTHLY
          ? {
              dayOfWeek,
              everyXMonths,
              weekOfService,
            }
          : null,
      dayOfServices:
        pickupFrequencyTypeName === MONTHLY
          ? formatDaysOfServiceByFrequencyType(
              formData.dayOfServices,
              formData.dayOfService,
              pickupFrequencyTypeName,
              formData.positionTypeId,
              formattedMonthlyServicesMonthlyFrequencyIds?.includes(MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH),
            )
          : formatDaysOfServiceByFrequencyType(
              formData.dayOfServices,
              formData.dayOfService,
              pickupFrequencyTypeName,
              formData.positionTypeId,
              formattedMonthlyServicesMonthlyFrequencyIds?.includes(MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH),
            ),
      vendorContainers: isContainerManagementFeatureEnabled
        ? formData.binNumberToSend && formData.binNumberToSend.length
          ? formData.binNumberToSend
          : [
              ...(map(formData.binNumber, (el: string) => {
                return { id: parseInt(el), locationId: 0 };
              }) || []),
            ]
        : [],
      futureAccountStatus:
        !(size(pickBy(futureAccountStatus)) > 0) || !futureAccountStatus?.accountStatusId ? null : futureAccountStatus,
      pickupLocation,
      monthlyServicesMonthlyFrequencyIds: formattedMonthlyServicesMonthlyFrequencyIds,
    };

    setIsSavingForm(true);

    try {
      await saveService({
        ...data,
        locationId: Number(locId) || 0,
        vendorId,
      })(dispatch);

      const collectionPointsToDeleteIds = filter(collectionPointsToDelete, id => !!id);
      if (collectionPointsToDeleteIds.length && vendorId) {
        deleteCollectionPoints(vendorId, collectionPointsToDeleteIds)(dispatch);
      }
      createSuccessNotification(`${translate('customers.alertMessages.serviceSaved')}`);

      setTimeout(() => {
        onCancel(true);
      }, 2000);
    } catch (e: any) {
      createErrorNotificationIncludingTechnicalMessage(
        e,
        translate('customers.alertMessages.serviceSaveError'),
        'customers.alertMessages',
      );
      setIsSavingForm(false);
    }
  };

  const onConfirmServiceTypeChange = async () => {
    let shouldUpdate = true;
    if (serviceTypeId) {
      if (pickupLocationsDirty)
        shouldUpdate = await confirm(
          translate('customers.pickupLocations.changeServiceTypeWarningTitle'),
          translate('customers.pickupLocations.changeServiceTypeWarningBody'),
        );

      if (!!rates.length) {
        shouldUpdate = await confirm(
          translate('customers.pickupLocations.changeServiceTypeWarningTitle'),
          translate('finance.rateManager.alertMessages.changeServiceTypeWarningBody'),
        );
      }
    }

    setIsServiceTypeChanged(shouldUpdate);

    setTimeout(() => {
      setIsServiceTypeChanged(false);
    });

    return shouldUpdate;
  };

  const onServiceTypeChanged = (newServiceTypeId: number) => {
    const newPickupLocation = { ...pickupLocation };
    newPickupLocation.binLocationMappingTypeId = LOCATION_ADDRESS;
    let lat = newPickupLocation.addressLatitude;
    let long = newPickupLocation.addressLongitude;
    if (newServiceTypeId === BULK_ID || newServiceTypeId === RESIDENTIAL_ID) {
      if (newPickupLocation.curbsideLatitude && newPickupLocation.curbsideLongitude) {
        newPickupLocation.binLocationMappingTypeId = CURBSIDE_LOOKUP;
        lat = newPickupLocation.curbsideLatitude;
        long = newPickupLocation.curbsideLongitude;
      }
    }
    delete newPickupLocation.userGeneratedLatitude;
    delete newPickupLocation.userGeneratedLongitude;
    delete newPickupLocation.collectionWaypoint;
    onAddressChange(lat, long, 0, newPickupLocation.binLocationMappingTypeId);
    setServiceTypeId(newServiceTypeId);
    setPickupLocationsDirty(false);
    setPickupLocation(newPickupLocation);
  };

  const onUpdatePickupLocations = (newPickupLocation: ServicePickupLocation) => {
    const primaryLocation = getPrimaryLocationCoordinates(newPickupLocation);

    if (!primaryLocation || !primaryLocation.lat || !primaryLocation.lng) {
      return;
    }

    onAddressChange(primaryLocation.lat, primaryLocation.lng, 0, newPickupLocation.binLocationMappingTypeId);
    setPickupLocationsDirty(true);
    setPickupLocation(newPickupLocation);
  };

  const resetDeletedCollectionPoints = () => {
    setPickupLocation(pickupLocation);
    setCollectionPointsToDelete([]);
  };

  const streetSweeperBroomSideTypes = getTypesAsString(
    customerLocation?.streetSegment?.streetSweeperBroomSideTypes || [],
    true,
  );
  const streetSegmentServiceTypes = getTypesAsString(customerLocation?.streetSegment?.streetSegmentServiceTypes || []);
  const initialValues = {
    ...service,
    streetId: service?.streetId || '-',
    streetSweeperBroomSideTypes,
    streetSegmentServiceTypes,
  };

  const isSnowPlowOrStreetSweeperRoute =
    service?.serviceTypeId === STREET_ID || service?.serviceTypeId === STREET_SWEEPING_ID;

  const handleCollectionPointsToDelete = (collectionPointIds: number[]) => {
    setCollectionPointsToDelete(uniq([...collectionPointsToDelete, ...collectionPointIds]));
  };

  const defaultLatLng = pickupLocation
    ? getPrimaryLocationCoordinates(pickupLocation)
    : {
        lat: customerLocation.address.latitude || 0,
        lng: customerLocation.address.longitude || 0,
      };

  const selectedServiceContract = (customerLocation.serviceContracts as any as CustomerLocationServiceContract[]).find(
    sc => sc.id === Number(serviceId),
  );

  const renderLocationMap = () => (
    <EditableServiceLocationMap
      handleCollectionPointsToDelete={handleCollectionPointsToDelete}
      deletedCollectionPoints={collectionPointsToDelete}
      vendorId={vendorId}
      serviceId={Number(serviceId)}
      serviceTypeId={serviceTypeId}
      onUpdatePickupLocation={onUpdatePickupLocations}
      onEditPickupLocation={setIsEditingPickupLocation}
      defaultLatLng={defaultLatLng}
      serviceContainers={serviceContainers}
      pickupLocations={pickupLocation}
      onAddressChanged={onAddressChange}
      resetDeletedCollectionPoints={resetDeletedCollectionPoints}
      isServiceTypeChanged={isServiceTypeChanged}
    />
  );

  const isEditMode = !!service;

  return (
    <PageContent isLoading={isSaving || isSavingForm}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction id="back-button" onClick={() => onCancel(false)}>
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>
              {!!service ? translate('common.edit') : translate('common.createNew')} {translate('common.service')}
            </PageTitle>

            <PageSubtitle margin="no no xSmall no">
              <b>{translate('customers.customerName')}: </b>
              {customer?.name}
            </PageSubtitle>
            <PageSubtitle margin="no no xSmall no">
              <b>{translate('customers.locationName')}: </b>
              {customerLocation?.name}
              {'  |  '}
              <b>{translate('customers.locationAddress')}: </b>
              {customerLocation?.address?.line1}
            </PageSubtitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>

      <Panel>
        <PanelSection padding="medium medium medium sMedium" fluid withBorder>
          {isSnowPlowOrStreetSweeperRoute ? (
            <StreetServiceDetailsEditorForm
              initialValues={initialValues}
              isReadonly={isEditingPickupLocation}
              onSubmit={onSubmit}
              renderLocationMap={renderLocationMap}
              serviceTypeId={serviceTypeId}
            />
          ) : (
            <ServiceDetailsEditorForm
              isReadonly={isEditingPickupLocation}
              onConfirmServiceTypeChange={onConfirmServiceTypeChange}
              onServiceTypeChanged={onServiceTypeChanged}
              onSubmit={onSubmit}
              isEditMode={isEditMode}
              serviceId={Number(serviceId)}
              renderLocationMap={renderLocationMap}
            />
          )}
        </PanelSection>

        {!!service && billingModuleEnabled && (
          <WorkOrdersSection vehicleTypeId={vehicleTypeId} selectedServiceContract={selectedServiceContract} />
        )}

        {billingModuleEnabled && (
          <RatesFeesSection
            isServiceTypeChanged={isServiceTypeChanged}
            serviceId={Number(serviceId)}
            serviceTypeId={service?.serviceTypeId || serviceTypeId}
          />
        )}

        <ServiceDetailsSubmitSection
          isSaving={isSavingForm}
          onCancel={() => onCancel(false)}
          isSnowPlowOrStreetSweeperRoute={isSnowPlowOrStreetSweeperRoute}
        />
      </Panel>
    </PageContent>
  );
};

export default ServiceDetailsEditorPage;
