import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';
import { RoutePlannerTemplatesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { loadRoutePlannerRouteTemplateDrivers as doLoadRoutePlannerRouteTemplateDrivers } from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerRouteTemplateDrivers/START_LOAD';
const COMPLETE_LOAD = 'routePlannerRouteTemplateDrivers/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerRouteTemplateDrivers/FAIL_LOAD';
const RESET = 'routePlannerRouteTemplateDrivers/RESET';

interface State {
  isLoading: boolean;
  drivers: RoutePlannerRouteTemplateDriver[];
}

const initialState: State = {
  isLoading: false,
  drivers: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivers: action.drivers,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivers: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (drivers: RoutePlannerRouteTemplateDriver[]) => ({
  type: COMPLETE_LOAD,
  drivers,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetRoutePlannerRouteTemplateDrivers = () => ({
  type: RESET,
});

export const loadRoutePlannerRouteTemplateDrivers = (params: RoutePlannerTemplatesPayload) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePlannerRouteTemplateDriversPromise = doLoadRoutePlannerRouteTemplateDrivers(params);
  loadRoutePlannerRouteTemplateDriversPromise
    .then((drivers: RoutePlannerRouteTemplateDriver[]) => {
      dispatch(completeLoad(drivers));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return loadRoutePlannerRouteTemplateDriversPromise;
};
