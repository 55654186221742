import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadReceivedViaOptions as doLoadReceivedViaOptions } from '../services/receivedViaOptions';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'finance/applyChecks/receivedViaOptions/START_LOAD';
const COMPLETE_LOAD = 'finance/applyPayemnts/receivedViaOptions/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/receivedViaOptions/FAIL_LOAD';
const RESET = 'finance/applyChecks/receivedViaOptions/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  receivedViaOptions: TechnicalType[];
} = {
  isLoading: false,
  receivedViaOptions: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          receivedViaOptions: action.receivedViaOptions,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          receivedViaOptions: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (receivedViaOptions: any) => ({
  type: COMPLETE_LOAD,
  receivedViaOptions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadReceivedViaOptions = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadReceivedViaOptionsPromise = doLoadReceivedViaOptions();
  loadReceivedViaOptionsPromise
    .then((receivedViaOptions: any) => dispatch(completeLoad(receivedViaOptions)))
    .catch(() => dispatch(failLoad()));
  return loadReceivedViaOptionsPromise;
};

export const resetReceivedViaOptions = () => ({
  type: RESET,
});
