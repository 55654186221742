import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const AGREEMENT = "Agreement";
export const COMPLIANCE = "Compliance";
export const CERTIFICATIONS = "Certifications";
export const DOCUMENT_DESCRIPTION = "Self Certification";
export const FILE_NAME = "Self_Certificate";
export const MSA = 'MSA';
export const COI = 'COI';
export const RTA = 'RTA';
export const SC = 'SC';
export const W9 = 'VW9';
export const OTH = 'VOA';


export const DOCUMENT_TYPE_OPTIONS = mapKeys(
  [
    { id: MSA, name: translate('haulerProfile.documentType.msa') },
    { id: RTA, name: translate('haulerProfile.documentType.rateAgreement') },
    { id: OTH, name: translate('haulerProfile.documentType.other') },
  ],
  'id',
);
