import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { Button, ButtonSet, Text } from 'src/core/components/styled';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { deleteWaterFill, loadWaterFills, loadRouteDetails, loadSnowSweeperRouteDetails } from 'src/routes/ducks';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { Table } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import WaterFillEditorModalResolver from '../WaterFillEditorModalResolver';
import WaterFillsTableRow from './WaterFillsTableRow';

export const getWaterFillsTableCellWidths = () => ['28%', '16%', '11%', '14%', '11%', '8%', '12%'];

const getWaterFillsTableCells = () => {
  const waterFillsTableCellWidths = getWaterFillsTableCellWidths();

  const waterFillsTableCells = [
    {
      name: 'location',
      label: translate('common.materialTickets.location'),
      width: waterFillsTableCellWidths[0],
    },
    {
      name: 'material',
      label: translate('common.material'),
      width: waterFillsTableCellWidths[1],
    },
    {
      name: 'quantity',
      label: translate('common.materialTickets.quantity'),
      width: waterFillsTableCellWidths[2],
    },
    {
      name: 'uom',
      label: translate('common.materialTickets.uom'),
      width: waterFillsTableCellWidths[3],
    },
    {
      name: 'timestamp',
      label: translate('common.materialTickets.materialTicketDate'),
      width: waterFillsTableCellWidths[4],
    },
    {
      name: 'images',
      label: translate('common.image'),
      width: waterFillsTableCellWidths[5],
    },
    {
      name: 'options',
      label: translate('common.options'),
      width: waterFillsTableCellWidths[6],
      align: 'right',
    },
  ];

  return waterFillsTableCells;
};

export default function WaterFillsSection() {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { waterFills } = useSelector(state => state.routes.waterFills) || [];
  const vendorId = useSelector(currentVendorId);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary?.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const [isWaterFillEditorModalOpen, setIsWaterFillEditorModalOpen] = useState(false);
  const [selectedWaterFill, setSelectedWaterFill] = useState<number>();

  if (!routeSummary) return null;

  const openWaterFillEditorModalOpen = (waterFillId?: number) => {
    if (waterFillId) setSelectedWaterFill(waterFillId);
    setIsWaterFillEditorModalOpen(true);
  };

  const closeWaterFillEditorModal = () => {
    setIsWaterFillEditorModalOpen(false);
    setSelectedWaterFill(undefined);
  };

  const handleDeleteWaterFill = async (waterFillId: number) => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteWaterFill')))) {
      return;
    }

    deleteWaterFill(waterFillId)(dispatch)
      .then(() => {
        loadWaterFills(routeSummary.routeId)(dispatch);

        const noLoading = true;
        isSnowOrSweeperRoute
          ? loadSnowSweeperRouteDetails(
              vendorId,
              routeSummary.routeId,
              segmentsFilterStartDate,
              segmentsFilterEndDate,
              noLoading,
            )(dispatch)
          : loadRouteDetails(vendorId, routeSummary.routeId, noLoading)(dispatch);

        createSuccessNotification(translate('routes.alertMessages.waterFillDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.waterFillDeleteError'));
      });
  };

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  return (
    <>
      {waterFills && waterFills.length ? (
        <Table
          cells={getWaterFillsTableCells()}
          rows={waterFills}
          rowComponent={WaterFillsTableRow}
          rowProps={{
            editWaterFill: openWaterFillEditorModalOpen,
            deleteWaterFill: handleDeleteWaterFill,
          }}
        />
      ) : (
        <Text block align="center" margin="medium no">
          {translate('common.waterFill.noWaterFill')}
        </Text>
      )}
      {!isSupport && !isViewOnly && (
        <ButtonSet margin="sMedium no">
          <Button type="button" color="primary" line onClick={() => openWaterFillEditorModalOpen(undefined)}>
            + {translate('common.waterFill.addWaterFill')}
          </Button>
        </ButtonSet>
      )}

      {isWaterFillEditorModalOpen && (
        <WaterFillEditorModalResolver
          routeId={routeSummary.routeId}
          waterFillId={selectedWaterFill}
          onClose={closeWaterFillEditorModal}
        />
      )}
    </>
  );
}
