import { intersection, castArray } from 'lodash-es';

import { http } from '../../core/services/http';
import { ADMIN, VENDOR, VENDOR_MANAGER } from '../constants';
import { createSession, getSessionValue, destroySession } from './session';
import { deleteSelfCreateUser } from './selfCreateUser';
import transformLogin from './transformLogin';

export const login = async (email?: string, password?: string, vendorId?: number) => {
  const response = await http.post('portalAccount/login', { email, password, vendorId });
  const user = transformLogin(response.data);
  createSession(response.headers.authorization, user);
  return user;
};

export const loginWithSSOToken = async (token: string, vendorId?: number) => {
  const response = await http.post(
    'portalAccount/login/sso',
    { vendorId },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  const user = transformLogin(response.data);
  createSession(response.headers.authorization, user);
  return user;
};

export const updateTokerSuperAdmin = async (vendorId?: number) => {
  const response = await http.post(`portalAccount/vendor?vendorId=${vendorId}`);
  const user = transformLogin(response.data);
  createSession(response.headers.authorization, user);
  return user;
};

export const changePassword = (email: string, currentPassword: string, newPassword: string) =>
  http
    .post('portalAccount/changePassword', { email, password: currentPassword, newPassword })
    .then(response => response.data);

export const getAuthToken = () => getSessionValue('authToken');
export const getUser = () => getSessionValue('user');
export const hasRole = (roles: string | string[]) =>
  !!intersection(getSessionValue('user.roles'), castArray(roles)).length;
export const isAdmin = () => hasRole(ADMIN);
export const isVendor = () => hasRole(VENDOR);
export const isVendorManager = () => hasRole(VENDOR_MANAGER);

export const logout = () => {
  destroySession();
  deleteSelfCreateUser();
};
