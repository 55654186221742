import update from 'immutability-helper';
import doLoadEquipmentConditions from '../services/loadEquipmentConditions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Actions
const START_LOAD = 'common/equipmentConditions/START_LOAD';
const COMPLETE_LOAD = 'common/equipmentConditions/COMPLETE_LOAD';
const FAIL_LOAD = 'common/equipmentConditions/FAIL_LOAD';
const RESET = 'common/equipmentConditions/RESET';

// Initial state
const initialState: EquipmentConditionsState = {
  isLoading: false,
};

interface EquipmentConditionsState {
  isLoading: boolean;
  equipmentConditions?: any[];
}

// Reducer
export const reducer = (
  state: EquipmentConditionsState = initialState,
  action: AnyAction,
): EquipmentConditionsState => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentConditions: action.equipmentConditions,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentConditions: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (equipmentConditions: any[]) => ({
  type: COMPLETE_LOAD,
  equipmentConditions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadEquipmentConditions = () => (dispatch: ThunkDispatch<EquipmentConditionsState, any, AnyAction>) => {
  dispatch(startLoad());
  const loadEquipmentConditionsPromise = doLoadEquipmentConditions();
  loadEquipmentConditionsPromise
    .then(equipmentConditions => dispatch(completeLoad(equipmentConditions)))
    .catch(() => dispatch(failLoad()));
  return loadEquipmentConditionsPromise;
};

export const resetEquipmentConditions = () => ({
  type: RESET,
});
