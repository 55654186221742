import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { ROUTE_MAP_CLUSTERS_SOURCE, ROUTE_MAP_CITY_INSIGHTS_LAYER } from '../constants';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import RouteMapCityInsightsGLLayers from './RouteMapCityInsightsGLLayers';
import RouteMapCityInsightsGLPopup from './RouteMapCityInsightsGLPopup';

const mapImages = INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: ROUTE_MAP_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
};

export default function RouteMapCityInsightsGL({ map }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_CLUSTERS_SOURCE &&
              feature.properties?.layer === ROUTE_MAP_CITY_INSIGHTS_LAYER,
          );

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.cityInsights, feature.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <RouteMapCityInsightsGLLayers />
      <RouteMapCityInsightsGLPopup />
    </>
  );
}
