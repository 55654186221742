import { AnyLayout } from 'mapbox-gl';
import { FC, memo } from 'react';
import { Layer } from 'react-map-gl';

import { getAlertPaint, getAlertLayout } from 'src/fleet/components/pages/locationAndCityAlertsSections/utils';
import { ROUTE_MAP_CITY_ALERTS_LAYER, ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';

const CityAlertsGLLayer: FC = memo(() => (
  <Layer
    id={ROUTE_MAP_CITY_ALERTS_LAYER}
    type="symbol"
    source={ROUTE_MAP_CLUSTERS_SOURCE}
    filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], ROUTE_MAP_CITY_ALERTS_LAYER]]}
    paint={getAlertPaint()}
    layout={getAlertLayout(['get', 'icon'], ['get', 'count']) as AnyLayout}
  />
));

export default CityAlertsGLLayer;
