import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import { Switch, TypedField } from 'src/core/components';
import { Button, Grid, GridColumn, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { RoutePlannerDriverStatus } from 'src/routes/interfaces/routePlanner/RoutePlannerDriverStatus';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';
import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { RoutePlannerVehicleStatus } from 'src/routes/interfaces/routePlanner/RoutePlannerVehicleStatus';

interface PropsWithoutReduxForm {
  vehicle?: RoutePlannerRouteVehicle | RoutePlannerRouteTemplateVehicle;
  driver?: RoutePlannerRouteDriver | RoutePlannerRouteTemplateDriver;
}

export interface UpdateStatusFormValues {
  currentStatus: boolean;
  updatedStatus: boolean;
  vehicleStatus?: RoutePlannerVehicleStatus;
  driverStatus?: RoutePlannerDriverStatus;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<UpdateStatusFormValues, PropsWithoutReduxForm>;

const DriverOrVehicleStatusSingleForm = ({ vehicle, driver, handleSubmit, form }: Props) => {
  const title = vehicle ? vehicle.vehicleName : driver ? driver.driverName : '';
  const { updatedStatus, currentStatus } = useSelector(getFormValues(form)) as UpdateStatusFormValues;

  return (
    <form onSubmit={handleSubmit}>
      <Text size="large" weight="medium">
        {title}
      </Text>
      <Grid multiLine margin="medium small small">
        <GridColumn size="6/12">
          <Text size="small" weight="medium">
            {translate('routes.planner.currentStatus')}
          </Text>
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="currentStatus"
            component={Switch}
            props={{
              margin: 'no',
              label: translate('common.available'),
              disabled: true,
            }}
          />
        </GridColumn>
        <GridColumn size="6/12" margin="small no">
          <Text size="small" weight="medium">
            {translate('routes.planner.updatedStatus')}
          </Text>
        </GridColumn>
        <GridColumn size="6/12" margin="small no">
          <TypedField
            name="updatedStatus"
            component={Switch}
            props={{
              margin: 'no',
              label: translate('common.available'),
            }}
          />
        </GridColumn>
      </Grid>
      <Button type="submit" color="primary" size="small" disabled={updatedStatus === currentStatus}>
        {translate('common.save')}
      </Button>
    </form>
  );
};

export default reduxForm<UpdateStatusFormValues, PropsWithoutReduxForm>({
  enableReinitialize: true,
})(DriverOrVehicleStatusSingleForm);
