import React, { MouseEvent } from 'react';
import { ThumbnailSet as ThumbnailSetContainer, Thumbnail, ThumbnailSetOverlay } from './styled';

const ThumbnailSet: React.FC<{
  id?: string;
  images: string[];
  onClick: (e: MouseEvent) => void;
  size?: string;
}> = ({ id, images, ...props }) => (
  <ThumbnailSetContainer id={id} {...props}>
    <Thumbnail src={images[0]} />
    {images.length > 1 && <ThumbnailSetOverlay>{images.length}</ThumbnailSetOverlay>}
  </ThumbnailSetContainer>
);

export default ThumbnailSet;
