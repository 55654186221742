import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import * as BilledChargesDucks from 'src/finance/ducks/billDetails';
import * as UnbilledChargesDucks from 'src/finance/ducks/unbilledCharges';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import BillDetailsPage from './BillDetailsPage';

interface BillDetailsParams {
  billId: string;
}

const BillDetailsPageResolver: React.FC<RouteComponentProps<BillDetailsParams>> = ({
  match: {
    params: { billId },
  },
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    if (!billId) {
      return Promise.reject();
    }

    return Promise.all([
      BilledChargesDucks.loadPaymentInfo(billId)(dispatch),
      BilledChargesDucks.loadBillDetails(vendorId, +billId)(dispatch),
      UnbilledChargesDucks.loadUnbilledChargesList(vendorId, +billId, true)(dispatch),
    ]);
  };

  return (
    <Fragment>
      <DocumentTitle>{translate('finance.billing')}</DocumentTitle>
      <TypedResolver
        successComponent={BillDetailsPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/dashboard"
        successProps={billId}
      />
    </Fragment>
  );
};

export default BillDetailsPageResolver;
