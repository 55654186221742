import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { filter, size } from 'lodash-es';
import { push } from 'connected-react-router';

import { AppState } from '../../../store';
import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { SortableTable } from '../../../core/components';
import { DayOfServiceMultiSelect } from '../../../routes/components';
import {
  Button,
  Grid,
  GridColumn,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from '../../../core/components/styled';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { FLEET_INSIGHTS, STREET_SWEEPING_INSIGHTS, WASTE_AUDIT_INSIGHTS, SNOW_PLOW_INSIGHTS } from '../../constants';
import {
  insightsDashboardPreferencesSelector,
  loadInsightDashboardPreferences,
  reorderInsightDashboardPreferences,
  resetInsightDashboardPreferences,
  saveInsightDashboardPreferences,
  toggleInsightDashboardReportType,
  insightDashboardDaysOfOperationSelector,
} from '../../ducks';
import { InsightDashboardPreferencesPageTableRow } from './';
import { getQueryParams } from '../../../utils/services/queryParams';
import translate from '../../../core/services/translate';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, WASTE_AUDIT_ID } from 'src/fleet/constants';

interface Props {
  fleetInsightsDashboardPreferences?: any[];
  initialValues: any;
  insightsDashboardPreferences: any[];
  isLoading: boolean;
  isSaving: boolean;
  location: any;
  push: any;
  reorderInsightDashboardPreferences: any;
  resetInsightDashboardPreferences: any;
  saveInsightDashboardPreferences: any;
  snowPlowInsightsDashboardPreferences?: any[];
  streetSweeperInsightsDashboardPreferences?: any[];
  toggleInsightDashboardReportType: any;
  vendorId: number;
  wasteAuditInsightsDashboardPreferences?: any[];
  filtersPreferences: FilterSetting[];
}

interface State {
  selectedDays: any[];
}

class InsightDashboardPreferencesPage extends PureComponent<Props & InjectedFormProps<any, Props>, State> {
  constructor(props: Props & InjectedFormProps<any, Props>) {
    super(props);

    this.state = {
      selectedDays: props.initialValues.daysOfOperation,
    };
  }

  componentWillUnmount() {
    const { resetInsightDashboardPreferences } = this.props;
    resetInsightDashboardPreferences();
  }

  onFleetInsightDashboardPreferenceReorder = (fromIndex: number, toIndex: number) => {
    this.props.reorderInsightDashboardPreferences(FLEET_INSIGHTS, fromIndex, toIndex);
  };

  onWasteAuditInsightDashboardPreferenceReorder = (fromIndex: number, toIndex: number) => {
    this.props.reorderInsightDashboardPreferences(WASTE_AUDIT_INSIGHTS, fromIndex, toIndex);
  };

  onStreetSweeperInsightDashboardPreferenceReorder = (fromIndex: number, toIndex: number) => {
    this.props.reorderInsightDashboardPreferences(STREET_SWEEPING_INSIGHTS, fromIndex, toIndex);
  };

  onSnowPlowInsightDashboardPreferenceReorder = (fromIndex: number, toIndex: number) => {
    this.props.reorderInsightDashboardPreferences(SNOW_PLOW_INSIGHTS, fromIndex, toIndex);
  };

  onDaysOfServiceChanged = (selectedDays: any[]) => {
    this.setState({ selectedDays });
  };

  saveInsightDashboardPreferences = async (previousPageUrl: string) => {
    const { vendorId, insightsDashboardPreferences, push, saveInsightDashboardPreferences } = this.props;
    const { selectedDays } = this.state;

    await saveInsightDashboardPreferences(vendorId, insightsDashboardPreferences, selectedDays);
    push(previousPageUrl);
  };

  render() {
    const {
      fleetInsightsDashboardPreferences,
      isLoading,
      isSaving,
      location,
      snowPlowInsightsDashboardPreferences,
      streetSweeperInsightsDashboardPreferences,
      toggleInsightDashboardReportType,
      wasteAuditInsightsDashboardPreferences,
      filtersPreferences,
    } = this.props;

    const params = getQueryParams(location.search);
    const previousPageUrl = `/insights/${params.source}?date=${params.date}`;

    const insightDashboardPreferencesTableCells = (section: string) => [
      {
        name: 'reportTypeName',
        label: translate('insights.reportType'),
        width: '85%',
        id: `insights-settings-${section}-header-report-type`,
      },
      {
        name: 'switch',
        label: translate('insights.displayInsights'),
        width: '15%',
        align: 'right',
        id: `insights-settings-${section}-header-display`,
      },
    ];

    const showStreetSweeper = () =>
      !!size(filter(streetSweeperInsightsDashboardPreferences, { isEnabled: true })) &&
      hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: STREET_SWEEPER_ID });
    const showFleet = () => !!size(filter(fleetInsightsDashboardPreferences, { isEnabled: true }));
    const showWasteAudit = () =>
      !!size(filter(wasteAuditInsightsDashboardPreferences, { isEnabled: true })) &&
      hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: WASTE_AUDIT_ID });
    const showSnowPlow = () =>
      !!size(filter(snowPlowInsightsDashboardPreferences, { isEnabled: true })) &&
      hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: SNOW_PLOW_ID });

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to={previousPageUrl}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle id="insights-settings-title">{translate('insights.insightsSettings')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button
              color="primary"
              id="insights-settings-save-button"
              onClick={() => this.saveInsightDashboardPreferences(previousPageUrl)}
            >
              {translate('common.save')}
            </Button>
          </PageActions>
        </PageHeader>
        <Grid>
          <GridColumn size="10/12" />
          <GridColumn size="2/12">
            <Field
              name="daysOfOperation"
              component={DayOfServiceMultiSelect}
              multiSelectProps={{
                margin: 'no',
                onChange: this.onDaysOfServiceChanged,
                id: 'insights-settings-days-of-operation',
              }}
              label={translate('facilities.daysOfOperation')}
            />
          </GridColumn>
        </Grid>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isSaving}>
            {showFleet() && (
              <PanelSection vertical>
                <PanelSectionTitle margin="sMedium no sMedium sMedium" id="insights-settings-fleet-header">
                  {translate('insights.fleetInsights')}
                </PanelSectionTitle>
                <SortableTable
                  sort={this.onFleetInsightDashboardPreferenceReorder}
                  cells={insightDashboardPreferencesTableCells('fleet')}
                  rows={fleetInsightsDashboardPreferences}
                  rowComponent={InsightDashboardPreferencesPageTableRow}
                  rowProps={{ toggleInsightDashboardReportType }}
                />
              </PanelSection>
            )}

            {showStreetSweeper() && (
              <PanelSection vertical>
                <PanelSectionTitle margin="sMedium no sMedium sMedium" id="insights-settings-sweeper-header">
                  {translate('insights.streetSweepingInsights')}
                </PanelSectionTitle>
                <SortableTable
                  sort={this.onStreetSweeperInsightDashboardPreferenceReorder}
                  cells={insightDashboardPreferencesTableCells('sweeper')}
                  rows={streetSweeperInsightsDashboardPreferences}
                  rowComponent={InsightDashboardPreferencesPageTableRow}
                  rowProps={{ toggleInsightDashboardReportType }}
                />
              </PanelSection>
            )}

            {showSnowPlow() && (
              <PanelSection vertical>
                <PanelSectionTitle margin="sMedium no sMedium sMedium" id="insights-settings-sweeper-header">
                  {translate('insights.snowPlowInsights')}
                </PanelSectionTitle>
                <SortableTable
                  sort={this.onStreetSweeperInsightDashboardPreferenceReorder}
                  cells={insightDashboardPreferencesTableCells('sweeper')}
                  rows={snowPlowInsightsDashboardPreferences}
                  rowComponent={InsightDashboardPreferencesPageTableRow}
                  rowProps={{ toggleInsightDashboardReportType }}
                />
              </PanelSection>
            )}

            {showWasteAudit() && (
              <PanelSection vertical>
                <PanelSectionTitle margin="sMedium no sMedium sMedium" id="insights-settings-waste-header">
                  {translate('insights.wasteAuditInsights')}
                </PanelSectionTitle>
                <SortableTable
                  sort={this.onWasteAuditInsightDashboardPreferenceReorder}
                  cells={insightDashboardPreferencesTableCells('waste')}
                  rows={wasteAuditInsightsDashboardPreferences}
                  rowComponent={InsightDashboardPreferencesPageTableRow}
                  rowProps={{ toggleInsightDashboardReportType }}
                />
              </PanelSection>
            )}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { insightDashboardPreferences } = state.insights;
  const initialValues = insightDashboardDaysOfOperationSelector(
    insightDashboardPreferences.insightDashboardDaysOfOperation,
  );

  return {
    fleetInsightsDashboardPreferences: insightsDashboardPreferencesSelector(
      insightDashboardPreferences,
      FLEET_INSIGHTS,
    ),
    initialValues,
    insightsDashboardPreferences: insightDashboardPreferences.insightDashboardPreferences,
    isLoading: insightDashboardPreferences.isLoading,
    isSaving: insightDashboardPreferences.isSaving,
    location: state.router.location,
    streetSweeperInsightsDashboardPreferences: insightsDashboardPreferencesSelector(
      insightDashboardPreferences,
      STREET_SWEEPING_INSIGHTS,
    ),
    snowPlowInsightsDashboardPreferences: insightsDashboardPreferencesSelector(
      insightDashboardPreferences,
      SNOW_PLOW_INSIGHTS,
    ),
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    wasteAuditInsightsDashboardPreferences: insightsDashboardPreferencesSelector(
      insightDashboardPreferences,
      WASTE_AUDIT_INSIGHTS,
    ),
    filtersPreferences: state.common.filters.filters,
  };
};

const mapDispatchToProps = {
  loadInsightDashboardPreferences,
  push,
  reorderInsightDashboardPreferences,
  resetInsightDashboardPreferences,
  saveInsightDashboardPreferences,
  toggleInsightDashboardReportType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<{}, Props>({
    form: 'insightDashboardPreferencesPageForm',
    enableReinitialize: true,
  })(InsightDashboardPreferencesPage),
);
