import { difference } from 'lodash-es';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'src/core/components';
import { BlockMultiSelect } from 'src/core/components/BlockMultiSelect';
import { DropdownOption } from 'src/core/components/Dropdown';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { assignPaymentToLocation } from 'src/customers/ducks/paymentMethods';

export const PaymentLocationAssignmentModal: FC<{
  onClose: () => void;
  assignedLocationIds: number[];
  customerId: number;
  customerExternalPaymentMethodId: number;
}> = ({ onClose, assignedLocationIds, customerId, customerExternalPaymentMethodId }) => {
  const customerLocations = useSelector(s => s.customers.locations.locations || []);

  const dispatch = useDispatch();

  const allOptions = useMemo(() => customerLocations.map(l => ({ value: l.id, label: l.name })), [customerLocations]);

  const blocks = useMemo(() => {
    const block1Items: DropdownOption[] = [];
    const block2Items: DropdownOption[] = [];
    allOptions.forEach(o => {
      if (!assignedLocationIds.includes(o.value)) {
        block1Items.push(o);
      } else {
        block2Items.push(o);
      }
    });
    return { block1Items, block2Items };
  }, [allOptions, assignedLocationIds]);

  const [isLoading, setIsLoading] = useState(false);

  const onChange = (options: DropdownOption[], moveToAssigned: boolean) => {
    setIsLoading(true);
    const movedLocations = options.map(o => o.value);
    const assignedLocations = blocks.block2Items.map(o => o.value);
    let newAssignedLocations: number[] = [];
    if (moveToAssigned) {
      newAssignedLocations = [...assignedLocations, ...movedLocations];
    } else {
      newAssignedLocations = difference(assignedLocations, movedLocations);
    }
    assignPaymentToLocation(
      customerId,
      newAssignedLocations,
      customerExternalPaymentMethodId,
    )(dispatch).finally(() => setIsLoading(false));
  };

  return (
    <Modal isLoading={isLoading} title={translate('finance.paymentMethods.assignToLocations')} onClose={onClose}>
      <BlockMultiSelect
        block1Label={translate('finance.paymentMethods.available')}
        block2Label={translate('finance.paymentMethods.assigned')}
        {...blocks}
        onChange={onChange}
      />
    </Modal>
  );
};
