import {
  SIZE_AUTO,
  SIZE_NO,
  SIZE_XX_SMALL,
  SIZE_X_SMALL,
  SIZE_SMALL,
  SIZE_S_MEDIUM,
  SIZE_SS_MEDIUM,
  SIZE_MEDIUM,
  SIZE_L_MEDIUM,
  SIZE_LARGE,
  SIZE_X_LARGE,
  SIZE_XX_LARGE,
  SIZE_GRANDE,
  SIZE_X_GRANDE,
  SIZE_XX_GRANDE,
  SIZE_DEFAULT_CELL_VERTICAL,
  SIZE_DEFAULT_CELL_HORIZONTAL,
  SIZE_NEG_SMALL,
  SIZE_XXX_GRANDE,
  SIZE_NEG_MEDIUM,
  SIZE_NEG_LARGE,
} from '../../core/styles';

import mapper from './mapper';

const SIZE_MAP = {
  [SIZE_AUTO]: 'auto',
  [SIZE_NO]: '0',
  [SIZE_XX_SMALL]: '5px',
  [SIZE_X_SMALL]: '10px',
  [SIZE_SMALL]: '15px',
  [SIZE_NEG_SMALL]: '-15px',
  [SIZE_NEG_MEDIUM]: '-30px',
  [SIZE_NEG_LARGE]: '-50px',
  [SIZE_S_MEDIUM]: '20px',
  [SIZE_SS_MEDIUM]: '25px',
  [SIZE_MEDIUM]: '30px',
  [SIZE_L_MEDIUM]: '40px',
  [SIZE_LARGE]: '45px',
  [SIZE_X_LARGE]: '60px',
  [SIZE_XX_LARGE]: '90px',
  [SIZE_GRANDE]: '120px',
  [SIZE_X_GRANDE]: '150px',
  [SIZE_XX_GRANDE]: '180px',
  [SIZE_XXX_GRANDE]: '300px',
  [SIZE_DEFAULT_CELL_VERTICAL]: '8px',
  [SIZE_DEFAULT_CELL_HORIZONTAL]: '18px',
};

const sizeMapper = (size?: string, defaultSize: string = SIZE_MEDIUM) => mapper(size, SIZE_MAP, defaultSize);

export default sizeMapper;
