import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { RouteSequenceSettingsState } from '../interfaces/RouteSequenceSettings';
import { RouteSettings } from '../interfaces/RouteSettings';
import {
  loadRouteSequenceSettings as doLoadRouteSequenceSettings,
  saveRouteSequenceSettings as doSaveRouteSequenceSettings,
} from '../services/routeSequenceSettings';

// Actions
const COMPLETE_LOAD = 'vendors/routeSequenceSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/routeSequenceSettings/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/routeSequenceSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/routeSequenceSettings/FAIL_SAVE';
const RESET = 'vendors/routeSequenceSettings/RESET';
const START_LOAD = 'vendors/routeSequenceSettings/START_LOAD';
const START_SAVE = 'vendors/routeSequenceSettings/START_SAVE';

// Initial state
const initialState: RouteSequenceSettingsState = {
  isLoading: false,
  isSaving: false,
  routeSequenceSettings: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSequenceSettings: action.routeSequenceSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeSequenceSettings: RouteSettings[]) => ({
  type: COMPLETE_LOAD,
  routeSequenceSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadRouteSequenceSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadRouteSequenceSettingsPromise = doLoadRouteSequenceSettings(vendorId);

  loadRouteSequenceSettingsPromise
    .then(routeSequenceSettings => dispatch(completeLoad(routeSequenceSettings)))
    .catch(() => dispatch(failLoad()));

  return loadRouteSequenceSettingsPromise;
};

export const saveRouteSequenceSettings =
  (vendorId: number, routeSequenceSettings: RouteSettings[]) => (dispatch: Dispatch) => {
    dispatch(startSave());

    const saveRouteSequenceSettingsPromise = doSaveRouteSequenceSettings(vendorId, routeSequenceSettings);

    saveRouteSequenceSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveRouteSequenceSettingsPromise;
  };

export const resetRouteSequenceSettings = () => ({
  type: RESET,
});
