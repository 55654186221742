import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadCityAlerts as doLoadCityAlerts,
  saveCityAlert as doSaveCityAlert,
  loadCityAlertImages as doLoadCityAlertImages,
  loadCityAlertsForRoute as doLoadCityAlertsForRoute,
} from '../services/cityAlerts';
import { CityAlert, CityAlertSave } from '../interfaces/CityAlert';
import { Image } from 'src/routes/interfaces/RouteImages';

//Actions
const START_LOAD = 'vendors/cityAlerts/START_LOAD';
const COMPLETE_LOAD = 'vendors/cityAlerts/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/cityAlerts/FAIL_LOAD';
const START_SAVE = 'vendors/cityAlerts/START_SAVE';
const COMPLETE_SAVE = 'vendors/cityAlerts/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/cityAlerts/FAIL_SAVE';
const START_LOAD_IMAGES = 'vendors/cityAlerts/START_LOAD_IMAGES';
const COMPLETE_LOAD_IMAGES = 'vendors/cityAlerts/COMPLETE_LOAD_IMAGES';
const FAIL_LOAD_IMAGES = 'vendors/cityAlerts/FAIL_LOAD_IMAGES';
const RESET_IMAGES = 'vendors/cityAlerts/RESET_IMAGES';
const START_LOAD_ALERTS_FOR_ROUTE = 'vendors/cityAlerts/START_LOAD_IMAGES';
const COMPLETE_LOAD_ALERTS_FOR_ROUTE = 'vendors/cityAlerts/COMPLETE_LOAD_ALERTS_FOR_ROUTE';
const FAIL_LOAD_ALERTS_FOR_ROUTE = 'vendors/cityAlerts/FAIL_LOAD_ALERTS_FOR_ROUTE';

const RESET = 'vendors/cityAlerts/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadingImages: false,
  isLoadingAlertsForRoute: false,
  isSaving: false,
  cityAlerts: [] as CityAlert[],
  images: [] as Image[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          cityAlerts: action.cityAlerts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_IMAGES:
      return update(state, {
        $merge: {
          isLoadingImages: action.noLoadingIndicator ? false : true,
        },
      });

    case COMPLETE_LOAD_IMAGES:
      return update(state, {
        $merge: {
          isLoadingImages: false,
          images: action.images,
        },
      });

    case FAIL_LOAD_IMAGES:
      return update(state, {
        $merge: {
          isLoadingImages: false,
        },
      });

    case RESET_IMAGES:
      return update(state, {
        $merge: {
          images: [],
        },
      });

    case START_LOAD_ALERTS_FOR_ROUTE:
      return update(state, {
        $merge: {
          isLoadingAlertsForRoute: true,
        },
      });

    case COMPLETE_LOAD_ALERTS_FOR_ROUTE:
      return update(state, {
        $merge: {
          isLoadingAlertsForRoute: false,
          cityAlerts: action.cityAlerts,
        },
      });

    case FAIL_LOAD_ALERTS_FOR_ROUTE:
      return update(state, {
        $merge: {
          isLoadingAlertsForRoute: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({ type: START_LOAD });
const completeLoad = (cityAlerts: CityAlert[]) => ({ type: COMPLETE_LOAD, cityAlerts });
const failLoad = () => ({ type: FAIL_LOAD });
const startSave = () => ({ type: START_SAVE });
const completeSave = () => ({ type: COMPLETE_SAVE });
const failSave = () => ({ type: FAIL_SAVE });
export const resetCityAlerts = () => ({ type: RESET });
const startLoadImages = (noLoadingIndicator?: boolean) => ({ type: START_LOAD_IMAGES, noLoadingIndicator });
const completeLoadImages = (images: Image[]) => ({ type: COMPLETE_LOAD_IMAGES, images });
const failLoadImages = () => ({ type: FAIL_LOAD_IMAGES });
export const resetCityAlertImages = () => ({ type: RESET_IMAGES });
const startLoadAlertsForRoute = () => ({ type: START_LOAD_ALERTS_FOR_ROUTE });
const completeLoadAlertsForRoute = (cityAlerts: CityAlert[]) => ({ type: COMPLETE_LOAD_ALERTS_FOR_ROUTE, cityAlerts });
const failLoadAlertsForRoute = () => ({ type: FAIL_LOAD_ALERTS_FOR_ROUTE });

//Thunk

export const loadCityAlerts =
  (cityAlertTypeIds?: number[], routeId?: number, shouldLoadImages?: boolean) => async (dispatch: Dispatch) => {
    dispatch(startLoad());

    const loadCityAlertsPromise = doLoadCityAlerts(cityAlertTypeIds, routeId, shouldLoadImages);

    loadCityAlertsPromise
      .then((response: CityAlert[]) => {
        dispatch(completeLoad(response));
      })
      .catch(() => {
        dispatch(failLoad());
      });

    return loadCityAlertsPromise;
  };

export const saveCityAlert = (cityAlert: CityAlertSave) => async (dispatch: Dispatch) => {
  dispatch(startSave());

  const saveCityAlertPromise = doSaveCityAlert(cityAlert);

  saveCityAlertPromise
    .then(() => {
      dispatch(completeSave());
    })
    .catch(() => {
      dispatch(failSave());
    });

  return saveCityAlertPromise;
};

export const loadCityAlertImages =
  (cityAlertId: number, noLoadingIndicator?: boolean) => async (dispatch: Dispatch) => {
    dispatch(startLoadImages(noLoadingIndicator));

    const loadCityAlertImagesPromise = doLoadCityAlertImages(cityAlertId);

    loadCityAlertImagesPromise
      .then((response: Image[]) => {
        dispatch(completeLoadImages(response));
      })
      .catch(() => {
        dispatch(failLoadImages());
      });

    return loadCityAlertImagesPromise;
  };

export const loadCityAlertsForRoute = (routeId?: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadAlertsForRoute());

  const loadCityAlertImagesPromise = doLoadCityAlertsForRoute(routeId);

  loadCityAlertImagesPromise
    .then((response: CityAlert[]) => {
      dispatch(completeLoadAlertsForRoute(response));
    })
    .catch(() => {
      dispatch(failLoadAlertsForRoute());
    });

  return loadCityAlertImagesPromise;
};
