import update from 'immutability-helper';
import { find, findIndex, identity, indexOf } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  loadWasteAuditConfiguration as doLoadWasteAuditConfiguration,
  saveWasteAuditConfiguration as doSaveWasteAuditConfiguration,
} from '../services/wasteAuditConfiguration';

// Actions
const START_LOAD = 'vendors/wasteAuditConfiguration/START_LOAD';
const COMPLETE_LOAD = 'vendors/wasteAuditConfiguration/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/wasteAuditConfiguration/FAIL_LOAD';
const START_SAVE = 'vendors/wasteAuditConfiguration/START_SAVE';
const COMPLETE_SAVE = 'vendors/wasteAuditConfiguration/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/wasteAuditConfiguration/FAIL_SAVE';
const RESET = 'vendors/wasteAuditConfiguration/RESET';
const SORT_ORDER_CHANGE = 'vendors/wasteAuditConfiguration/SORT_ORDER_CHANGE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  wasteAuditConfiguration: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteAuditConfiguration: action.wasteAuditConfiguration,
        },
      });

    case SORT_ORDER_CHANGE: {
      const index = findIndex(state.wasteAuditConfiguration, ({ wasteAuditTypeId }) => wasteAuditTypeId === 1);
      return update(state, {
        wasteAuditConfiguration: {
          [`${index}`]: { vendorWasteAuditConfigurationTypes: { $set: action.vendorWasteAuditConfigurationTypes } },
        },
      } as any);
    }

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (wasteAuditConfiguration: any) => ({
  type: COMPLETE_LOAD,
  wasteAuditConfiguration,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadWasteAuditConfiguration = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadWasteAuditConfigurationPromise = doLoadWasteAuditConfiguration(vendorId);
  loadWasteAuditConfigurationPromise
    .then(wasteAuditConfiguration => dispatch(completeLoad(wasteAuditConfiguration)))
    .catch(() => dispatch(failLoad()));

  return loadWasteAuditConfigurationPromise;
};

export const resetWasteAuditConfiguration = () => ({
  type: RESET,
});

export const saveWasteAuditConfiguration = (vendorId: number, wasteAuditConfig: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveWasteAuditConfigurationPromise = doSaveWasteAuditConfiguration(vendorId, wasteAuditConfig);
  saveWasteAuditConfigurationPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveWasteAuditConfigurationPromise;
};

export const handleSortOrderChange = (vendorWasteAuditConfigurationTypes: any) => ({
  type: SORT_ORDER_CHANGE,
  vendorWasteAuditConfigurationTypes,
});

// Selectors
const getWasteAuditConfigurationIndexByType = (wasteAuditState: any, wasteAuditConfigurationType: any) =>
  (indexOf as any)(
    wasteAuditState.wasteAuditConfiguration,
    find(wasteAuditState.wasteAuditConfiguration, { name: wasteAuditConfigurationType }),
    'vendorWasteAuditConfigurationTypes',
  );

export const wasteAuditConfigurationIndexByTypeSelector = createSelector(
  getWasteAuditConfigurationIndexByType,
  identity,
);
