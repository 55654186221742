import { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';

import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { loadPermissions, shouldLoadPermissionsSelector } from '../../../../account/ducks';
import { DocumentTitle } from '../../../../common/components';
import { PageLoading } from '../../../../common/components/styled';
import { DuckFunction } from '../../../../contracts/ducks';
import { Resolver } from '../../../../core/components';
import translate from '../../../../core/services/translate';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { AppState } from '../../../../store';
import { loadFeatures } from '../../../../vendors/ducks';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE } from '../../../constants';
import {
  loadGeoFenceFilterOptions,
  loadRouteSequenceHistory,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  loadTravelPathStatusDetails,
} from '../../../ducks';
import { loadRouteGeoFence } from '../../../ducks/routeGeoFence';
import { cancelBatchedLocationsLoading } from '../../../ducks/routeTemplate';
import { loadSupervisors } from '../../../ducks/supervisors';
import RouteTemplatePage from './RouteTemplatePage';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';

const batchedLocationsRequesterId = 'routeTemplatePageResolver';

interface RouteTemplateParams {
  routeTemplateId: string;
}

interface RouteTemplatePageResolverProps extends RouteComponentProps<RouteTemplateParams> {
  displayUnscheduledStops?: boolean;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadRouteTemplateOptimized: DuckFunction<typeof loadRouteTemplateOptimized>;
  loadRouteTemplateLocationsBatched: DuckFunction<typeof loadRouteTemplateLocationsBatched>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadRouteSequenceHistory: DuckFunction<typeof loadRouteSequenceHistory>;
  loadRouteGeoFence: DuckFunction<typeof loadRouteGeoFence>;
  loadSupervisors: DuckFunction<typeof loadSupervisors>;
  loadGeoFenceFilterOptions: DuckFunction<typeof loadGeoFenceFilterOptions>;
  loadTravelPathStatusDetails: DuckFunction<typeof loadTravelPathStatusDetails>;
  push: (url: string) => void;
  shouldLoadPermissions: boolean;
  vendorId: number;
  isTravelPathFeatureEnabled: boolean;
  isNaviV3FeatureEnabled: boolean;
}

class RouteTemplatePageResolver extends PureComponent<RouteTemplatePageResolverProps> {
  componentDidMount() {
    const { loadFeatures, vendorId } = this.props;
    loadFeatures(vendorId);
  }

  componentWillUnmount() {
    cancelBatchedLocationsLoading(batchedLocationsRequesterId);
  }

  loadDependencies = async () => {
    const {
      shouldLoadPermissions,
      loadRouteTemplateOptimized,
      loadRouteTemplateLocationsBatched,
      loadPermissions,
      loadVehicleTypesForVendor,
      loadRouteSequenceHistory,
      loadRouteGeoFence,
      loadSupervisors,
      loadGeoFenceFilterOptions,
      loadTravelPathStatusDetails,
      push,
      vendorId,
      isTravelPathFeatureEnabled,
      isNaviV3FeatureEnabled,
      match: {
        params: { routeTemplateId },
      },
    } = this.props;

    const { id, vehicleTypeId } = await loadRouteTemplateOptimized(routeTemplateId);

    // redirect to new page if is street sweeper or snow plow
    if (vehicleTypeId === STREET_SWEEPER_ID) {
      push(`/routes/route-templates/street-sweeper/${routeTemplateId}`);
      return;
    } else if (vehicleTypeId === SNOW_PLOW_ID) {
      push(`/routes/route-templates/snow-plow/${routeTemplateId}`);
      return;
    }

    const { total } = await loadRouteTemplateLocationsBatched(batchedLocationsRequesterId, id);
    loadVehicleTypesForVendor(vendorId);

    const isTemplate = true;
    const dependencies = [
      loadRouteSequenceHistory(id, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE),
      loadRouteGeoFence(parseFloat(routeTemplateId), isTemplate),
      loadSupervisors(vendorId),
      loadGeoFenceFilterOptions({ vendorId, routeTemplateId: +routeTemplateId }),
    ];

    if (total >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled)) {
      dependencies.push(loadTravelPathStatusDetails(undefined, id));
    }

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>
        <Resolver
          loadingComponent={PageLoading}
          redirectOnError="/routes/route-templates"
          resolve={this.loadDependencies}
          successComponent={RouteTemplatePage}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  isTravelPathFeatureEnabled: isTravelPathNavigationFeatureEnabled(state),
  isNaviV3FeatureEnabled: isNavi3FeatureEnabled(state),
});

const mapDispatchToProps = {
  loadFeatures,
  loadPermissions,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  loadVehicleTypesForVendor,
  loadRouteSequenceHistory,
  loadRouteGeoFence,
  loadSupervisors,
  loadGeoFenceFilterOptions,
  loadTravelPathStatusDetails,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTemplatePageResolver));
