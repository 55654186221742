import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadRouteStops as doLoadRouteStops,
  loadRouteStopsForSequence as doLoadRouteStopsForSequence,
  updateRouteStops as doUpdateRouteStops,
  loadRouteLocationDetails as doLoadRouteLocationDetails,
  loadWasteAuditNotes as doLoadWasteAuditNotes,
  loadUnscheduledStops as doLoadUnscheduledStops,
} from '../services/routeStops';
import {
  RouteStop,
  RouteStopsPayLoad,
  RouteLocationDetails,
  WasteAuditNotes,
  UnscheduledStops,
} from '../interfaces/RouteStop';

const START_LOAD = 'routes/routeStops/START_LOAD';
const COMPLETE_LOAD = 'routes/routeStops/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeStops/FAIL_LOAD';
const START_LOAD_STOPS_FOR_MAP = 'routes/routeStops/START_LOAD_STOPS_FOR_MAP';
const COMPLETE_LOAD_STOPS_FOR_MAP = 'routes/routeStops/COMPLETE_LOAD_STOPS_FOR_MAP';
const FAIL_LOAD_STOPS_FOR_MAP = 'routes/routeStops/FAIL_LOAD_STOPS_FOR_MAP';
const START_LOAD_STOPS_FOR_SEQUENCE = 'routes/routeStops/START_LOAD_STOPS_FOR_SEQUENCE';
const COMPLETE_LOAD_STOPS_FOR_SEQUENCE = 'routes/routeStops/COMPLETE_LOAD_STOPS_FOR_SEQUENCE';
const FAIL_LOAD_STOPS_FOR_SEQUENCE = 'routes/routeStops/FAIL_LOAD_STOPS_FOR_SEQUENCE';
const START_SAVE = 'routes/routeStops/START_SAVE';
const COMPLETE_SAVE = 'routes/routeStops/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/routeStops/FAIL_SAVE';
const RESET_IS_LOADED = 'routes/routeStops/RESET_IS_LOADED';
const RESET = 'routes/routeStops/RESET';
const START_SAVE_ROUTE_LOCATION_DETAILS = 'routes/routeStops/START_SAVE_ROUTE_LOCATION_DETAILS';
const COMPLETE_SAVE_ROUTE_LOCATION_DETAILS = 'routes/routeStops/COMPLETE_SAVE_ROUTE_LOCATION_DETAILS';
const FAIL_SAVE_ROUTE_LOCATION_DETAILS = 'routes/routeStops/FAIL_SAVE_ROUTE_LOCATION_DETAILS';
const START_LOAD_WASTE_AUDIT_NOTES = 'routes/routeStops/START_LOAD_WASTE_AUDIT_NOTES';
const COMPLETE_LOAD_WASTE_AUDIT_NOTES = 'routes/routeStops/COMPLETE_LOAD_WASTE_AUDIT_NOTES';
const FAIL_LOAD_WASTE_AUDIT_NOTES = 'routes/routeStops/FAIL_LOAD_WASTE_AUDIT_NOTES';
const START_LOAD_UNSCHEDULED_STOPS = 'routes/routeStops/START_LOAD_UNSCHEDULED_STOPS';
const COMPLETE_LOAD_UNSCHEDULED_STOPS = 'routes/routeStops/COMPLETE_LOAD_UNSCHEDULED_STOPS';
const FAIL_LOAD_UNSCHEDULED_STOPS = 'routes/routeStops/FAIL_LOAD_UNSCHEDULED_STOPS';

interface State {
  isLoaded: boolean;
  isLoading: boolean;
  isLoadingStopsForMap: boolean;
  isLoadingStopsForSequence: boolean;
  isSaving: boolean;
  routeLocationDetails: RouteLocationDetails;
  routeStops: RouteStop[];
  routeStopsForMap: RouteStop[];
  routeStopsForSequence: RouteStop[];
  unscheduledStops: UnscheduledStops[];
  wasteAuditNotes: WasteAuditNotes[];
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  isLoadingStopsForMap: false,
  isLoadingStopsForSequence: false,
  isSaving: false,
  routeLocationDetails: {} as RouteLocationDetails,
  routeStops: [],
  routeStopsForMap: [],
  routeStopsForSequence: [],
  unscheduledStops: [],
  wasteAuditNotes: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoaded: true,
          isLoading: false,
          routeStops: action.routeStops,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeStops: [],
        },
      });

    case START_LOAD_STOPS_FOR_MAP:
      return update(state, {
        $merge: {
          isLoadingStopsForMap: true,
        },
      });

    case COMPLETE_LOAD_STOPS_FOR_MAP:
      return update(state, {
        $merge: {
          isLoadingStopsForMap: false,
          routeStopsForMap: action.routeStopsForMap,
        },
      });

    case FAIL_LOAD_STOPS_FOR_MAP:
      return update(state, {
        $merge: {
          isLoadingStopsForMap: false,
          routeStopsForMap: [],
        },
      });

    case START_LOAD_STOPS_FOR_SEQUENCE:
      return update(state, {
        $merge: {
          isLoadingStopsForSequence: true,
        },
      });

    case COMPLETE_LOAD_STOPS_FOR_SEQUENCE:
      return update(state, {
        $merge: {
          isLoadingStopsForSequence: false,
          routeStopsForSequence: action.routeStopsForSequence,
        },
      });

    case FAIL_LOAD_STOPS_FOR_SEQUENCE:
      return update(state, {
        $merge: {
          isLoadingStopsForSequence: false,
          routeStopsForSequence: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_SAVE_ROUTE_LOCATION_DETAILS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_SAVE_ROUTE_LOCATION_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
          routeLocationDetails: action.routeLocationDetails,
        },
      });

    case FAIL_SAVE_ROUTE_LOCATION_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_WASTE_AUDIT_NOTES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_WASTE_AUDIT_NOTES:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteAuditNotes: action.wasteAuditNotes,
        },
      });

    case FAIL_LOAD_WASTE_AUDIT_NOTES:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_UNSCHEDULED_STOPS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_UNSCHEDULED_STOPS:
      return update(state, {
        $merge: {
          isLoading: false,
          unscheduledStops: action.unscheduledStops,
        },
      });

    case FAIL_LOAD_UNSCHEDULED_STOPS:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case RESET_IS_LOADED:
      return update(state, {
        $merge: {
          isLoaded: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeStops: RouteStop[]) => ({
  type: COMPLETE_LOAD,
  routeStops,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadStopsForMap = () => ({
  type: START_LOAD_STOPS_FOR_MAP,
});

const completeLoadStopsForMap = (routeStopsForMap: RouteStop[]) => ({
  type: COMPLETE_LOAD_STOPS_FOR_MAP,
  routeStopsForMap,
});

const failLoadStopsForMap = () => ({
  type: FAIL_LOAD_STOPS_FOR_MAP,
});

const startLoadStopsForSequence = () => ({
  type: START_LOAD_STOPS_FOR_SEQUENCE,
});

const completeLoadStopsForSequence = (routeStopsForSequence: RouteStop[]) => ({
  type: COMPLETE_LOAD_STOPS_FOR_SEQUENCE,
  routeStopsForSequence,
});

const failLoadStopsForSequence = () => ({
  type: FAIL_LOAD_STOPS_FOR_SEQUENCE,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startSaveRouteLocationDetails = () => ({
  type: START_SAVE_ROUTE_LOCATION_DETAILS,
});

const completeSaveRouteLocationDetails = (routeLocationDetails: RouteLocationDetails) => ({
  type: COMPLETE_SAVE_ROUTE_LOCATION_DETAILS,
  routeLocationDetails,
});

const failSaveRouteLocationDetails = () => ({
  type: FAIL_SAVE_ROUTE_LOCATION_DETAILS,
});

const startLoadWasteAuditNotes = () => ({
  type: START_LOAD_WASTE_AUDIT_NOTES,
});

const completeLoadWasteAuditNotes = (wasteAuditNotes: WasteAuditNotes[]) => ({
  type: COMPLETE_LOAD_WASTE_AUDIT_NOTES,
  wasteAuditNotes,
});

const failLoadWasteAuditNotes = () => ({
  type: FAIL_LOAD_WASTE_AUDIT_NOTES,
});

const startLoaUnscheduledStops = () => ({
  type: START_LOAD_UNSCHEDULED_STOPS,
});

const completeLoadUnscheduledStops = (unscheduledStops: UnscheduledStops[]) => ({
  type: COMPLETE_LOAD_UNSCHEDULED_STOPS,
  unscheduledStops,
});

const failLoadUnscheduledStops = () => ({
  type: FAIL_LOAD_UNSCHEDULED_STOPS,
});

export const loadRouteStops =
  (
    vendorId: number,
    routeId: number,
    routeStopsStatusIds?: number[],
    includeLocationAlerts?: boolean,
    noLoadingIndicator?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoad());
    const loadRouteStopsPromise = doLoadRouteStops(vendorId, routeId, routeStopsStatusIds, includeLocationAlerts);
    loadRouteStopsPromise
      .then(routeStops =>
        dispatch(
          completeLoad(
            routeStops.sort((a, b) => {
              return a.orderNo > 0 ? a.orderNo - b.orderNo : b.orderNo - a.orderNo;
            }),
          ),
        ),
      )
      .catch(() => dispatch(failLoad()));
    return loadRouteStopsPromise;
  };

export const loadRouteStopsForSequence = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadStopsForSequence());
  const loadRouteStopsForSequencePromise = doLoadRouteStopsForSequence(vendorId, routeId);
  loadRouteStopsForSequencePromise
    .then(routeStopsForSequence =>
      dispatch(
        completeLoadStopsForSequence(
          routeStopsForSequence.sort((a, b) => {
            return a.orderNo > 0 ? a.orderNo - b.orderNo : b.orderNo - a.orderNo;
          }),
        ),
      ),
    )
    .catch(() => dispatch(failLoadStopsForSequence()));
  return loadRouteStopsForSequencePromise;
};

export const loadRouteStopsForMap =
  (vendorId: number, routeId: number, routeStopsStatusIds?: number[], includeLocationAlerts?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadStopsForMap());
    const loadRouteStopsPromise = doLoadRouteStops(vendorId, routeId, routeStopsStatusIds, includeLocationAlerts);
    loadRouteStopsPromise
      .then(routeStops =>
        dispatch(
          completeLoadStopsForMap(
            routeStops.sort((a, b) => {
              return a.orderNo > 0 ? a.orderNo - b.orderNo : b.orderNo - a.orderNo;
            }),
          ),
        ),
      )
      .catch(() => dispatch(failLoadStopsForMap()));
    return loadRouteStopsPromise;
  };

export const updateRouteStops =
  (
    vendorId: number,
    routeId: number,
    routeStops: RouteStopsPayLoad,
    shouldCreateTravelPath?: boolean,
    isOrderNumberChangedByUser?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const updateRouteStopsPromise = doUpdateRouteStops(
      vendorId,
      routeId,
      routeStops,
      shouldCreateTravelPath,
      isOrderNumberChangedByUser,
    );
    updateRouteStopsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return updateRouteStopsPromise;
  };

export const loadRouteLocationDetails =
  (vendorId: number, routeId: number, routeLocationId: number) => (dispatch: Dispatch) => {
    dispatch(startSaveRouteLocationDetails());
    const loadRouteLocationDetailsPromise = doLoadRouteLocationDetails(vendorId, routeId, routeLocationId);
    loadRouteLocationDetailsPromise
      .then((routeLocationDetails: RouteLocationDetails) =>
        dispatch(completeSaveRouteLocationDetails(routeLocationDetails)),
      )
      .catch(() => dispatch(failSaveRouteLocationDetails()));
    return loadRouteLocationDetailsPromise;
  };

export const loadWasteAuditNotes = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadWasteAuditNotes());
  const loadWasteAuditNotesPromise = doLoadWasteAuditNotes(vendorId, routeId);
  loadWasteAuditNotesPromise
    .then((wasteAuditNotes: WasteAuditNotes[]) => dispatch(completeLoadWasteAuditNotes(wasteAuditNotes)))
    .catch(() => dispatch(failLoadWasteAuditNotes()));
  return loadWasteAuditNotesPromise;
};

export const loadUnscheduledStops =
  (vendorId: number, routeId: number, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoaUnscheduledStops());
    const loadWasteAuditNotesPromise = doLoadUnscheduledStops(vendorId, routeId);
    loadWasteAuditNotesPromise
      .then((unscheduledStops: UnscheduledStops[]) => dispatch(completeLoadUnscheduledStops(unscheduledStops)))
      .catch(() => dispatch(failLoadUnscheduledStops()));
    return loadWasteAuditNotesPromise;
  };

export const resetRouteStops = () => ({
  type: RESET,
});
export const resetIsLoaded = () => ({
  type: RESET_IS_LOADED,
});
