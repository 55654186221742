import styled from 'styled-components';

import green from 'src/common/assets/img/common/greenFlag.png';
import red from 'src/common/assets/img/common/redFlag.png';

interface TravelPathDrawingInstructionsContainerProps {
  isOpen?: boolean;
}

export const TravelPathDrawingInstructionsContainer = styled.div<TravelPathDrawingInstructionsContainerProps>`
  position: absolute;
  left: 75px;
  top: 20px;
  padding: 10px;
  background-color: rgb(255, 255, 255, 0.9);
  font-size: 12px;
  border-radius: 7px;
  cursor: ${props => (props.isOpen ? 'default' : 'pointer')} !important;

  & > div:first-child {
    cursor: pointer !important;
  }

  & > ol {
    padding-left: 20px;
  }
`;

export const TravelPathWarningMapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-align: center;
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  }
`;

export const TravelPathModalTitleWrapper = styled.div`
  position: absolute;
  left: 20px;
  z-index: 999;
`;

export const TravelPathBuilderActionsWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 300px);
`;

export const TravelPathErrorWrapper = styled.div`
  position: absolute;
  bottom: 120px;
  left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 300px);

  & span {
    /* From https://css.glass */
    background: rgba(227, 18, 0, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(227, 18, 0, 0.07);

    padding: 10px 20px;
  }
`;

export const TravelPathSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  pointer-events: none;
`;

export const TravelPathSliderTrack = styled.div`
  position: absolute;
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
  height: 30px;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

export const TravelPathSliderRange = styled.div`
  position: relative;
  background: ${props => props.theme.brandPrimary};
  z-index: 2;
  height: 30px;
  border-radius: 15px;
`;

const TravelPathSliderThumb = styled.input`
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  appearance: none;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border: none;
    cursor: ew-resize;
    height: 60px;
    width: 15px;
    margin-top: 5px;
    pointer-events: all;
    position: relative;
  }
`;

const TravelPathSliderText = styled.div`
  position: absolute;
  font-weight: 600;
  padding: 5px;
  bottom: 65px;
  background: rgba(255, 255, 255, 0.43);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.9px);
  -webkit-backdrop-filter: blur(11.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
`;

export const TravelPathSliderTextLeft = styled(TravelPathSliderText)`
  left: -17px;
  font-size: 12px;
  color: green;
`;

export const TravelPathSliderTextRight = styled(TravelPathSliderText)`
  right: -50px;
  font-size: 12px;
  color: red;
`;

export const TravelPathSliderFlag = styled.div`
  position: absolute;
  height: 67px;
  width: 50px;
  bottom: -3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const TravelPathSliderFlagLeft = styled(TravelPathSliderFlag)`
  left: -5px;
  background-image: url(${green});
`;

export const TravelPathSliderFlagRight = styled(TravelPathSliderFlag)`
  right: -39px;
  background-image: url(${red});
`;

export const TravelPathSliderThumbLeft = styled(TravelPathSliderThumb)`
  z-index: 3;
`;

export const TravelPathSliderThumbRight = styled(TravelPathSliderThumb)`
  z-index: 4;
`;
