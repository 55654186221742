import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { loadPermissions, shouldLoadPermissionsSelector } from 'src/account/ducks';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE } from 'src/routes/constants';
import {
  loadRouteSequence,
  loadRouteSequenceStatus,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
} from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RouteTemplateSequenceEditorPageNew from './RouteTemplateSequenceEditorPageNew';

interface RouteParams {
  routeTemplateId: string;
}

const RouteTemplateSequenceEditorPageResolverNew = () => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const routeTemplateId = Number(params.routeTemplateId);
  const vendorId = useSelector(currentVendorId);

  const shouldLoadPermissions = useSelector(state =>
    shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  );

  const batchedLocationsRequesterId = 'routeTemplatePageResolver';

  const loadDependencies = async () => {
    if (!isFinite(routeTemplateId) || !vendorId) return Promise.reject();

    const dependencies: Promise<any>[] = [
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadRouteSequenceStatus([routeTemplateId], SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE)(dispatch),
      loadRouteSequence(
        routeTemplateId,
        SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
      )(dispatch).then(data => {
        if (!data || !data.sequence || !data.sequence.length) {
          dispatch(push(`/routes/route-templates/${routeTemplateId}`));
        }
      }),
    ];

    const { id } = await loadRouteTemplateOptimized(routeTemplateId)(dispatch);

    loadRouteTemplateLocationsBatched(batchedLocationsRequesterId, id)(dispatch);

    if (shouldLoadPermissions) dependencies.push(loadPermissions()(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>
      <TypedResolver
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={RouteTemplateSequenceEditorPageNew}
        redirectOnError="/routes/route-templates"
      />
    </>
  );
};

export default RouteTemplateSequenceEditorPageResolverNew;
