import { filter, reduce, size } from 'lodash-es';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ModalImage } from 'src/routes/interfaces/RouteImages';
import { RouteLocationImage } from 'src/routes/interfaces/RouteLocationImage';
import { AppState } from '../../../store';
import RouteImagesModal from './RouteImagesModal';

interface Props {
  closeModal: () => void;
  imageId?: number;
  imageIds?: number[];
  routeLocationImages: RouteLocationImage[];
}

interface State {
  images: ModalImage[];
}

class RouteLocationImagesModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { routeLocationImages, imageId, imageIds = [] } = props;

    const filteredRouteLocationImages = !!imageIds.length
      ? filter(routeLocationImages, routeLocationImage => {
          return !!size(routeLocationImage.images) && imageIds.includes(routeLocationImage.images[0].imageId);
        })
      : !!imageId
      ? filter(
          routeLocationImages,
          routeLocationImage => !!size(routeLocationImage.images) && routeLocationImage.images[0].imageId === imageId,
        )
      : routeLocationImages;

    const reduceExceptionImages = (images: ModalImage[], exceptionImages: RouteLocationImage) => {
      const { customerName, customerLocationAddress, pickupIssueReportedReasonTypeName, timeStamp } = exceptionImages;
      return reduce(
        exceptionImages.images,
        (images, image) => {
          images.push({
            customerName,
            timeStamp,
            locationName: customerLocationAddress,
            issueName: pickupIssueReportedReasonTypeName,
            imageUrl: image.url,
            rawImageUrl: image?.rawUrl || image.url,
            imageBlobId: image.blobId,
            imageTimingType: image.imageTimingType ? image.imageTimingType.technicalName : undefined,
          });
          return images;
        },
        images,
      );
    };

    const images = reduce(filteredRouteLocationImages, reduceExceptionImages, []);

    this.state = { images };
  }

  render() {
    const { closeModal } = this.props;
    const { images } = this.state;

    return <RouteImagesModal images={images} closeModal={closeModal} />;
  }
}

const mapStateToProps = (state: AppState) => ({
  routeLocationImages: state.routes.routeLocationImages.routeLocationImages,
});

export default connect(mapStateToProps)(RouteLocationImagesModal);
