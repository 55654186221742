import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, WrappedFieldInputProps } from 'redux-form';
import { orderBy } from 'lodash-es';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../store';
import { Dropdown } from '../../core/components';
import insightsFormInitialValuesSelector from '../services/insightsFormInitialValues';
import translate from '../../core/services/translate';

interface ComponentProps extends RouteComponentProps {
  input: WrappedFieldInputProps;
  placeholder?: string;
  label?: string;
  withPlaceholder?: boolean;
  withLabel?: boolean;
  obstacles?: any[];
  dropdownProps?: any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class ObstaclesStreetSweepingDropdown extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      label,
      withLabel,
      placeholder,
      withPlaceholder,
      dropdownProps,
      obstacles = orderBy(
        [
          { label: translate('insights.obstacles.vehicle'), value: 'Vehicle' },
          { label: translate('insights.obstacles.wasteContainer'), value: 'Waste container' },
          { label: translate('insights.obstacles.person'), value: 'Person' },
          { label: translate('insights.obstacles.tree'), value: 'Tree' },
          { label: translate('insights.obstacles.sign'), value: 'Sign' },
          { label: translate('insights.obstacles.construction'), value: 'Construction' },
          { label: translate('insights.obstacles.basketballHoop'), value: 'Basketball Hoop' },
          { label: translate('insights.obstacles.unknown'), value: 'Unknown' },
          { label: translate('insights.obstacles.other'), value: 'Other' },
        ],
        'label',
        'asc',
      ),
    } = this.props;

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('insights.obstacle') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('insights.obstacle') : undefined)}
        component={Dropdown}
        options={obstacles}
        onChange={this.onChange}
        dropdownProps={{ id: dropdownProps.id }}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const initialValues = (insightsFormInitialValuesSelector as any)(ownProps.location.search);

  return {
    initialValues,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'obstaclesDropdown',
      enableReinitialize: true,
    })(ObstaclesStreetSweepingDropdown),
  ),
);
