import { Resizable } from 're-resizable';
import { useState } from 'react';
import { Accordion, AccordionSection, MapDragHandle } from 'src/core/components';

import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { Grid, GridColumn, Panel, PanelSectionGroup } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_SMALLER } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { RoutePlannerRoute } from 'src/routes/interfaces/routePlanner/RoutePlannerRoute';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteTemplate } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplate';
import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';
import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import FilterableTableSection from './FilterableTableSection';
import RoutePlannerDriverTableRow from './RoutePlannerDriverTableRow';
import RoutePlannerRouteBuilderTableRow from './RoutePlannerRouteBuilderTableRow';
import RoutePlannerTemplateBuilderTableRow from './RoutePlannerTemplateBuilderTableRow';
import RoutePlannerVehicleTableRow from './RoutePlannerVehicleTableRow';

type Props = {
  drivers: RoutePlannerRouteDriver[] | RoutePlannerRouteTemplateDriver[];
  isDaily: boolean;
  isLoadingDrivers: boolean;
  isLoadingRoutes?: boolean;
  isLoadingTemplates?: boolean;
  isLoadingVehicles: boolean;
  onAssignDriver: (driverId: number, routeId: number, isUnassignedFrom?: number) => void;
  onAssignVehicle: (vehicleId: number, routeId: number, isUnassignedFrom?: number) => void;
  onEditAvailability?: (event: any, driverId?: number, vehicleId?: number) => void;
  onUnassignDriver: (driverId: number, routeId: number, softRemove?: boolean) => void;
  onUnassignVehicle: (vehicleId: number, routeId: number, softRemove?: boolean) => void;
  openAvailabilityStatusBulkEditor?: (isDrivers: boolean) => void;
  routes: RoutePlannerRoute[];
  routeTemplates: RoutePlannerRouteTemplate[];
  setNoDriver: (noDriver: boolean) => void;
  setNoVehicle: (noVehicles: boolean) => void;
  setSearchTerm: (searchTerm: string) => void;
  setUnavailableVehiclesOrDrivers?: (unavailableVehiclesOrDrivers: boolean) => void;
  vehicles: RoutePlannerRouteVehicle[] | RoutePlannerRouteTemplateVehicle[];
};

const AssigningDriversAndVehiclesSection = ({
  drivers,
  isDaily,
  isLoadingDrivers,
  isLoadingRoutes,
  isLoadingTemplates,
  isLoadingVehicles,
  onAssignDriver,
  onAssignVehicle,
  onEditAvailability,
  onUnassignDriver,
  onUnassignVehicle,
  openAvailabilityStatusBulkEditor,
  routes,
  routeTemplates,
  setNoDriver,
  setNoVehicle,
  setSearchTerm,
  setUnavailableVehiclesOrDrivers,
  vehicles,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [draggedVehicle, setDraggedVehicle] = useState<number | null>(null);
  const [draggedDriver, setDraggedDriver] = useState<number | null>(null);
  const [routeIdToRemoveDriverOrVehicle, setRouteIdToRemoveDriverOrVehicle] = useState<number | null>(null);
  const [driversSectionHeight, setDriversSectionHeight] = useState(400);

  const isViewOnlyOrSupport = checkIfViewOnly() || checkIfSupport();

  const onDragStartVehicle = (vehicleId: number, routeId?: number) => {
    setIsDragging(true);
    setDraggedVehicle(vehicleId);
    if (routeId) setRouteIdToRemoveDriverOrVehicle(routeId);
  };

  const onDragStartDriver = (driverId: number, routeId?: number) => {
    setIsDragging(true);

    setDraggedDriver(driverId);
    if (routeId) setRouteIdToRemoveDriverOrVehicle(routeId);
  };

  const onDragEnd = () => {
    setRouteIdToRemoveDriverOrVehicle(null);
    setIsDragging(false);
    setDraggedVehicle(null);
    setDraggedDriver(null);
  };

  const handleResizeDriversSection = (e: any, direction: any, ref: any, d: any) => {
    setDriversSectionHeight(driversSectionHeight + d.height);
  };

  const driversVisibleRows = Math.floor((driversSectionHeight - 30) / TABLE_ROW_HEIGHT_SMALLER);
  const vehiclesVisibleRows = 15 - driversVisibleRows;

  const filters = [
    {
      label: translate('routes.planner.noDriver'),
      condition: isDaily ? (el: RoutePlannerRoute) => !el.driverId : (el: RoutePlannerRouteTemplate) => !el.driverId,
      onChange: (noDriver: boolean) => setNoDriver(noDriver),
    },
    {
      label: translate('routes.planner.noVehicle'),
      condition: isDaily ? (el: RoutePlannerRoute) => !el.vehicleId : (el: RoutePlannerRouteTemplate) => !el.vehicleId,
      onChange: (noVehicle: boolean) => setNoVehicle(noVehicle),
    },
  ];

  isDaily &&
    setUnavailableVehiclesOrDrivers &&
    filters.push({
      label: translate('routes.planner.unavailableVehiclesOrDrivers'),
      condition: (el: RoutePlannerRoute) => el.isDriverUnavailable || el.isVehicleUnavailable || false,
      onChange: (unavailableVehiclesOrDrivers: boolean) =>
        setUnavailableVehiclesOrDrivers(unavailableVehiclesOrDrivers),
    });

  return (
    <PanelSectionGroup>
      <Panel>
        <Accordion margin="no" openedSections={['assignmentSection']}>
          <AccordionSection
            name="assignmentSection"
            title={translate('customers.routeRecommendations.routeAssignment')}
            titleSize="xxLarge"
            titleWeight="medium"
            isOpen
          >
            <Grid>
              <GridColumn size="4/12">
                <Grid column>
                  <Resizable
                    minWidth="100%"
                    minHeight={100}
                    handleComponent={{ bottom: <MapDragHandle /> }}
                    onResizeStop={handleResizeDriversSection}
                  >
                    <GridColumn
                      withBoxShadow
                      minHeight={driversSectionHeight + 80}
                      isLoading={isLoadingDrivers}
                      color="white"
                    >
                      <FilterableTableSection
                        title={translate('drivers.drivers')}
                        rowComponent={RoutePlannerDriverTableRow}
                        rows={drivers}
                        rowComponentProps={{
                          onDragStart: onDragStartDriver,
                          onDragEnd: onDragEnd,
                          onEditAvailability,
                          isViewOnlyOrSupport,
                          isDragging,
                          draggedDriver,
                          isDaily,
                        }}
                        onGroupEdit={
                          openAvailabilityStatusBulkEditor && isDaily && !isViewOnlyOrSupport
                            ? () => openAvailabilityStatusBulkEditor(true)
                            : null
                        }
                        visibleRows={driversVisibleRows}
                        filters={[
                          {
                            label: translate('common.unassigned'),
                            condition: isDaily
                              ? (driver: RoutePlannerRouteDriver) => !driver.routes.length
                              : (driver: RoutePlannerRouteTemplateDriver) => !driver.routesTemplates.length,
                          },
                        ]}
                        searchFormFilterCondition={{
                          condition: isDaily
                            ? (row: RoutePlannerRouteDriver, search: string) =>
                                row.driverName.toLowerCase().includes(search.toLowerCase())
                            : (row: RoutePlannerRouteTemplateDriver, search: string) =>
                                row.driverName.toLowerCase().includes(search.toLowerCase()),
                        }}
                      />
                    </GridColumn>
                  </Resizable>
                  <GridColumn
                    withBoxShadow
                    minHeight={800 - driversSectionHeight > 100 ? 800 - driversSectionHeight : 100}
                    isLoading={isLoadingVehicles}
                    color="white"
                  >
                    <FilterableTableSection
                      title={translate('vehicles.vehicles')}
                      rowComponent={RoutePlannerVehicleTableRow}
                      rows={vehicles}
                      visibleRows={vehiclesVisibleRows}
                      rowComponentProps={{
                        onDragStart: onDragStartVehicle,
                        onDragEnd: onDragEnd,
                        onEditAvailability,
                        isViewOnlyOrSupport,
                        isDragging,
                        draggedVehicle,
                        isDaily,
                      }}
                      onGroupEdit={
                        openAvailabilityStatusBulkEditor && isDaily && !isViewOnlyOrSupport
                          ? () => openAvailabilityStatusBulkEditor(false)
                          : null
                      }
                      filters={[
                        {
                          label: translate('common.unassigned'),
                          condition: isDaily
                            ? (vehicle: RoutePlannerRouteVehicle) => !vehicle.routes.length
                            : (vehicle: RoutePlannerRouteTemplateVehicle) => !vehicle.routesTemplates.length,
                        },
                      ]}
                      searchFormFilterCondition={{
                        condition: isDaily
                          ? (row: RoutePlannerRouteVehicle, search: string) =>
                              row.vehicleName.toLowerCase().includes(search.toLowerCase())
                          : (row: RoutePlannerRouteTemplateVehicle, search: string) =>
                              row.vehicleName.toLowerCase().includes(search.toLowerCase()),
                      }}
                    />
                  </GridColumn>
                </Grid>
              </GridColumn>

              <GridColumn size="8/12" withBoxShadow isLoading={isLoadingRoutes || isLoadingTemplates}>
                <FilterableTableSection
                  title={translate('routes.routes')}
                  rowComponent={isDaily ? RoutePlannerRouteBuilderTableRow : RoutePlannerTemplateBuilderTableRow}
                  rowComponentProps={{
                    drivers,
                    vehicles,
                    draggedDriver,
                    draggedVehicle,
                    routeIdToRemoveDriverOrVehicle,
                    isViewOnlyOrSupport,
                    onDragStartDriver,
                    onDragStartVehicle,
                    onDragEnd,
                    onAssignDriver,
                    onAssignVehicle,
                    onUnassignDriver,
                    onUnassignVehicle,
                  }}
                  rows={isDaily ? routes : routeTemplates}
                  visibleRows={11}
                  itemSize={TABLE_ROW_HEIGHT_LARGE}
                  shadowedTable
                  filters={filters}
                  searchFormFilterCondition={{
                    condition: isDaily
                      ? (row: RoutePlannerRoute, search: string) => {
                          return row.routeName.toLowerCase().includes(search.toLowerCase());
                        }
                      : (row: RoutePlannerRouteTemplate, search: string) => {
                          return row.routeTemplateName.toLowerCase().includes(search.toLowerCase());
                        },
                    onChange: (searchTerm: string) => setSearchTerm(searchTerm),
                  }}
                />
              </GridColumn>
            </Grid>
          </AccordionSection>
        </Accordion>
      </Panel>
    </PanelSectionGroup>
  );
};

export default AssigningDriversAndVehiclesSection;
