import { find } from 'lodash-es';
import { useMemo, useState } from 'react';

import { Box } from 'src/core/components/styled/Box';
import { FilterableTableSectionTableRowWrapper } from 'src/routes/components/styled';
import { Grid, GridColumn, Label, TableRow, Text } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import { LABEL_COLORS, ROUTE_STATUSES_BY_ID, SCHEDULED } from 'src/routes/constants';
import { RoutePlannerRoute } from 'src/routes/interfaces/routePlanner/RoutePlannerRoute';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import confirm from 'src/core/services/confirm';
import PillAttribute from './PillAttribute';
import RoutePlannerVehiclesAndDriversSearchForm from 'src/routes/components/forms/routePlanner/RoutePlannerVehiclesAndDriversSearchForm';
import translate from 'src/core/services/translate';

interface RoutePlannerRouteBuilderTableRowProps extends RoutePlannerRoute {
  drivers: RoutePlannerRouteDriver[];
  vehicles: RoutePlannerRouteVehicle[];
  draggedDriver: number | null;
  draggedVehicle: number | null;
  routeIdToRemoveDriverOrVehicle: number | null;
  isViewOnlyOrSupport: boolean;
  onDragStartDriver: (driverId: number, routeId: number) => void;
  onDragStartVehicle: (vehicleId: number, routeId: number) => void;
  onDragEnd: () => void;
  onAssignDriver: (driverId: number, routeId: number, isUnassignedFrom?: number) => void;
  onAssignVehicle: (vehicleId: number, routeId: number, isUnassignedFrom?: number) => void;
  onUnassignDriver: (driverId: number, routeId: number, softRemove?: boolean) => void;
  onUnassignVehicle: (vehicleId: number, routeId: number, softRemove?: boolean) => void;
}

const RoutePlannerRouteBuilderTableRow = ({
  routeName,
  routeId,
  vehicleId,
  vehicleName,
  driverId,
  driverName,
  vehicleTypeId,
  drivers,
  vehicles,
  draggedDriver,
  draggedVehicle,
  routeStatusTypeId,
  routeIdToRemoveDriverOrVehicle,
  isUpdating,
  isViewOnlyOrSupport,
  onDragStartDriver,
  onDragStartVehicle,
  onDragEnd,
  onAssignDriver,
  onAssignVehicle,
  onUnassignDriver,
  onUnassignVehicle,
  isDriverUnavailable,
  isVehicleUnavailable,
}: RoutePlannerRouteBuilderTableRowProps) => {
  const [isReadyToDrop, setIsReadyToDrop] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const cellHeight = `${TABLE_ROW_HEIGHT_LARGE}px`;
  const hasDriverOrVehicle = !!(driverId || vehicleId);

  const vehiclesAndDriversOptions = useMemo(() => {
    const driversOptions = drivers.map((driver: RoutePlannerRouteDriver) => ({
      label: driver.driverName,
      value: driver.driverId,
      isDriver: true,
      isDoubleBooked: driver.routes.length > 1,
      isBooked: driver.routes.length > 0,
      disabled: !driver.routes.length,
    }));

    const vehiclesOptions = vehicles.map(vehicle => {
      const typeName = VEHICLE_TYPE_IDS[vehicle.vehicleTypeId]?.name;
      return {
        label: `${vehicle.vehicleName} • ${typeName}`,
        value: vehicle.vehicleId,
        isDriver: false,
        isDoubleBooked: vehicle.routes.length > 1,
        isBooked: !!vehicle.routes.length,
        disabled: !vehicle.routes.length,
      };
    });

    return [
      { label: translate('drivers.drivers'), options: driversOptions },
      { label: translate('vehicles.vehicles'), options: vehiclesOptions },
    ];
  }, [drivers, vehicles]);

  const vehicleTypeName = VEHICLE_TYPE_IDS[vehicleTypeId]?.name;

  const onRemoveDriver = (softRemove?: boolean) => {
    driverId && onUnassignDriver(driverId, routeId, softRemove);
  };

  const onRemoveVehicle = (softRemove?: boolean) => {
    if (vehicleId) onUnassignVehicle(vehicleId, routeId, softRemove);
  };

  const onAssignDriverOrVehicle = async (id: number, isDriver: boolean, isUnassignedFrom?: number) => {
    if (isDriver)
      if (driverId) {
        const newDriver = find(drivers, { driverId: id });
        if (
          await confirm(
            translate('routes.planner.replaceDriverRoute', {
              assignedDriver: driverName,
              newDriver: newDriver?.driverName,
            }),
          )
        ) {
          const softRemove = true;
          onRemoveDriver(softRemove);
          onAssignDriver(id, routeId, isUnassignedFrom);
        } else return;
      } else {
        onAssignDriver(id, routeId, isUnassignedFrom);
      }
    else if (vehicleId) {
      const newVehicle = find(vehicles, { vehicleId: id });
      if (
        await confirm(
          translate('routes.planner.replaceVehicleRoute', {
            assignedVehicle: vehicleName,
            newVehicle: newVehicle?.vehicleName,
          }),
        )
      ) {
        const softRemove = true;
        onRemoveVehicle(softRemove);
        onAssignVehicle(id, routeId, isUnassignedFrom);
      } else return;
    } else {
      onAssignVehicle(id, routeId, isUnassignedFrom);
    }
  };

  const onDropDriverOrVehicle = () => {
    if (isReadyToDrop) {
      if (draggedDriver) {
        const isDriver = true;
        if (draggedDriver && routeIdToRemoveDriverOrVehicle)
          onAssignDriverOrVehicle(draggedDriver, isDriver, routeIdToRemoveDriverOrVehicle);
        else onAssignDriverOrVehicle(draggedDriver, isDriver);
      } else if (draggedVehicle) {
        const isDriver = false;
        if (draggedVehicle && routeIdToRemoveDriverOrVehicle)
          onAssignDriverOrVehicle(draggedVehicle, false, routeIdToRemoveDriverOrVehicle);
        else onAssignDriverOrVehicle(draggedVehicle, isDriver);
      }
      onDragEnd();
      setIsReadyToDrop(false);
    }
  };

  const canPlanRoute = routeStatusTypeId === SCHEDULED;

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE} minHeight={cellHeight} padding="10px" backgroundColor=" #f9f9f9">
      <FilterableTableSectionTableRowWrapper
        isReadyToDrop={isReadyToDrop}
        dropZone={canPlanRoute && isReadyToDrop}
        onDragEnter={event => {
          event.preventDefault();
          setIsReadyToDrop(true);
        }}
        canPlanRoute={canPlanRoute}
        onDragLeave={event => {
          event.preventDefault();
          setIsReadyToDrop(false);
        }}
        onDragOver={canPlanRoute ? e => e.preventDefault() : undefined}
        onDrop={canPlanRoute ? onDropDriverOrVehicle : undefined}
      >
        <Grid align="center">
          <GridColumn size={isViewOnlyOrSupport ? '6/12' : '3/12'} verticalAlign="center">
            <Text size="medium" weight="medium" title={routeName} singleLine>
              {routeName}
            </Text>
          </GridColumn>
          <GridColumn size="6/12" verticalAlign="center" isLoading={isUpdating}>
            {hasDriverOrVehicle ? (
              <>
                {driverName && driverId && (
                  <PillAttribute
                    title={driverName}
                    onRemoveAttribute={canPlanRoute && !isViewOnlyOrSupport ? () => onRemoveDriver() : undefined}
                    onDragStart={
                      canPlanRoute && !isViewOnlyOrSupport
                        ? () => {
                            onDragStartDriver(driverId, routeId);
                            setIsDragging(true);
                          }
                        : undefined
                    }
                    onDragEnd={
                      canPlanRoute && !isViewOnlyOrSupport
                        ? () => {
                            onDragEnd();
                            setIsDragging(false);
                          }
                        : undefined
                    }
                    showTooltip={!isDragging}
                    tooltipText={`${translate('drivers.driver')}: ${driverName}`}
                    isDriverUnavailable={isDriverUnavailable}
                  />
                )}
                {vehicleName && vehicleId && (
                  <PillAttribute
                    title={vehicleName}
                    subtitle={vehicleTypeName}
                    onRemoveAttribute={canPlanRoute && !isViewOnlyOrSupport ? () => onRemoveVehicle() : undefined}
                    onDragStart={
                      canPlanRoute && !isViewOnlyOrSupport
                        ? () => {
                            setIsDragging(true);
                            onDragStartVehicle(vehicleId, routeId);
                          }
                        : undefined
                    }
                    onDragEnd={
                      canPlanRoute && !isViewOnlyOrSupport
                        ? () => {
                            onDragEnd();
                            setIsDragging(false);
                          }
                        : undefined
                    }
                    showTooltip={!isDragging}
                    tooltipText={`${translate('vehicles.vehicle')}: ${vehicleName} • ${vehicleTypeName}`}
                    isVehicleUnavailable={isVehicleUnavailable}
                  />
                )}
              </>
            ) : !isViewOnlyOrSupport ? (
              <Box display="flex" alignItems="center">
                <Icon icon="dragAndDrop" width="25px" height="25px" customViewBox="0 0 21 18" color="#76768c" />
                <Text
                  size="small"
                  color="grayDarker"
                  block
                  title={translate('routes.planner.dragAndDropHint')}
                  padding="no no no small"
                  align="left"
                >
                  {translate('routes.planner.dragAndDropHint')}
                </Text>
              </Box>
            ) : null}
          </GridColumn>
          {!isViewOnlyOrSupport && (
            <GridColumn size="3/12" verticalAlign="center" padding="no">
              {canPlanRoute ? (
                <RoutePlannerVehiclesAndDriversSearchForm
                  onOptionCLicked={(o: any) => {
                    onAssignDriverOrVehicle(o.value, o.isDriver);
                  }}
                  options={vehiclesAndDriversOptions}
                  form={`routePlannerRouteBuilderTableRow-${routeName}`}
                />
              ) : (
                <Label color={LABEL_COLORS[routeStatusTypeId]}>{ROUTE_STATUSES_BY_ID[routeStatusTypeId].name}</Label>
              )}
            </GridColumn>
          )}
        </Grid>
      </FilterableTableSectionTableRowWrapper>
    </TableRow>
  );
};

export default RoutePlannerRouteBuilderTableRow;
