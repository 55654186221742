import React from 'react';

import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorId } from '../../services/currentVendorSelector';
import { Districts } from '../../interfaces/DivisionAccess';
import { loadDistricts, districtsUserIdSelector } from './../../ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import DivisionAccessModal from './DivisionAccessModal';

interface Props extends ModalProps {
  userId?: string;
  districts?: Districts[];
  currentUserDistricts?: string;
}

const DivisionAccessModalResolver: React.FC<Props> = ({ closeModal, userId, districts, currentUserDistricts }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const isSameUserDistricts = currentUserDistricts === userId;
    if (districts && isSameUserDistricts && userId) {
      return Promise.resolve();
    }

    return Promise.all([loadDistricts(vendorId, userId)(dispatch)]);
  }, [currentUserDistricts, dispatch, districts, userId, vendorId]);

  return (
    <Resolver
      successComponent={DivisionAccessModal}
      successProps={{ closeModal, districts }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  currentUserDistricts: districtsUserIdSelector(state),
});

export default connect(mapStateToProps)(DivisionAccessModalResolver);
