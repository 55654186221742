import { size } from 'lodash-es';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Table } from 'src/core/components';
import { Button, ButtonSet, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { deleteFuelTicket, loadFuelTickets } from 'src/routes/ducks';
import FuelTicketEditorModalResolver from '../FuelTicketEditorModalResolver';
import FuelTicketTableRow from './FuelTicketTableRow';

const FuelTicketsSection = () => {
  const dispatch = useDispatch();

  const [isFuelTicketEditorModalOpen, setIsFuelTicketEditorModalOpen] = useState(false);
  const [selectedFuelTicket, setSelectedFuelTicket] = useState<number | undefined>(undefined);

  const fuelTickets = useSelector(state => state.routes.fuelTickets.fuelTickets);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  if (!routeSummary) return null;

  const openFuelTicketEditorModal = (fuelTicketId?: number) => {
    setSelectedFuelTicket(fuelTicketId);
    setIsFuelTicketEditorModalOpen(true);
  };

  const closeFuelTicketEditorModal = () => {
    setIsFuelTicketEditorModalOpen(false);
    setSelectedFuelTicket(undefined);
  };

  const handleDeleteFuelTicket = async (fuelTicketId: number) => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteFuelTicket')))) return;

    deleteFuelTicket(fuelTicketId)(dispatch)
      .then(() => {
        loadFuelTickets(routeSummary.routeId)(dispatch);
        createSuccessNotification(translate('routes.alertMessages.fuelTicketDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.fuelTicketDeleteError'));
      });
  };

  const tableCells = [
    { name: 'fuelingDateTime', label: translate('common.timestamp'), width: '12%' },
    { name: 'locationId', label: translate('routes.fuelingLocation'), width: '38%' },
    { name: 'fuelAmount', label: translate('routes.fuelAmount'), width: '12%' },
    { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '10%' },
    { name: 'fuelCost', label: translate('routes.fuelCost'), width: '10%' },
    { name: 'image', label: translate('common.image'), width: '8%' },
    { name: 'actions', label: translate('common.options'), width: '10%', align: 'right' },
  ];

  return (
    <>
      {size(fuelTickets) ? (
        <Table
          cells={tableCells}
          rows={fuelTickets}
          rowComponent={FuelTicketTableRow}
          rowProps={{
            deleteFuelTicket: handleDeleteFuelTicket,
            editFuelTicket: openFuelTicketEditorModal,
          }}
        />
      ) : (
        <Text block align="center" margin="medium no">
          {translate('routes.noFuelTickets')}
        </Text>
      )}

      <ButtonSet margin="sMedium no">
        <Button type="button" color="primary" line onClick={() => openFuelTicketEditorModal(undefined)}>
          + {translate('routes.addFuelTicket')}
        </Button>
      </ButtonSet>

      {isFuelTicketEditorModalOpen && (
        <FuelTicketEditorModalResolver
          fuelTicketId={selectedFuelTicket}
          onClose={closeFuelTicketEditorModal}
          routeId={routeSummary.routeId}
        />
      )}
    </>
  );
};

export default FuelTicketsSection;
