import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import {
  Button,
  GridColumn,
  ModalFixedHeader,
  ModalTitle,
  ModalFixedFooter,
  ModalClose,
  ModalCloseIcon,
  Message,
} from '../../../core/components/styled';
import { ContainerListItem } from 'src/customers/interfaces/ContainerListItem';
import { createSuccessNotification } from 'src/core/services/createNotification';
import { PanelSearch, Modal, Table } from '../../../core/components';
import { TABLE_ROW_HEIGHT_SMALLEST } from 'src/core/constants';
import SelectContainersTableRow from './SelectContainersTableRow';
import translate from '../../../core/services/translate';
import {
  CART_CONTAINER_ID,
  CART_CONTAINER,
  COMMERCIAL_CONTAINER_ID,
  COMMERCIAL_CONTAINER,
  ROLL_OFF_CONTAINER_ID,
  ROLL_OFF_CONTAINER,
} from 'src/fleet/constants';

interface FormValues {
  searchTerm: string;
}

interface PropsWithoutReduxForm {
  closeModal: () => void;
  containerList: ContainerListItem[];
  isLoading: boolean;
  onCheckboxChange: (isSelected: boolean, id: number) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const SelectContainersModal: React.FC<Props> = ({ closeModal, containerList, isLoading, onCheckboxChange }) => {
  const [containerListFiltered, setContainerListFiltered] = useState(containerList);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setContainerListFiltered(
      containerList.filter(container => container.containerNumber.toUpperCase().includes(searchTerm.toUpperCase())),
    );
  }, [containerList, onCheckboxChange, searchTerm]);

  const getContainerListTableCells = (containerTypeId: number) => {
    return [
      { name: 'checked', label: translate('common.selected'), width: '20%' },
      {
        name: 'name',
        label: `${
          containerTypeId === ROLL_OFF_CONTAINER_ID
            ? ROLL_OFF_CONTAINER
            : containerTypeId === COMMERCIAL_CONTAINER_ID
            ? COMMERCIAL_CONTAINER
            : CART_CONTAINER
        } ${translate('containers.containers')}`,
        width: '80%',
      },
    ];
  };

  const onApplyChanges = () => {
    closeModal();
    createSuccessNotification(translate('customers.alertMessages.confirmContainersApplyChanges'));
  };

  const onCloseModal = () => {
    closeModal();
    setSearchTerm('');
  };

  const getVirtualizedProps = (containerListFiltered: ContainerListItem[]) => {
    return {
      height:
        containerListFiltered.length > 8
          ? Math.min(containerListFiltered.length * TABLE_ROW_HEIGHT_SMALLEST, TABLE_ROW_HEIGHT_SMALLEST * 8) || 1
          : containerListFiltered.length * TABLE_ROW_HEIGHT_SMALLEST,
      itemSize: TABLE_ROW_HEIGHT_SMALLEST,
    };
  };

  const getNoContainersMessage = (containerListFiltered: ContainerListItem[], containerTypeId: number) => {
    return (
      ((searchTerm !== '' && !containerListFiltered.length) ||
        !containerList.filter(container => container.containerTypeId === containerTypeId).length) && (
        <Message padding="xSmall no no no">{translate('containers.noContainers')}</Message>
      )
    );
  };

  const getContainerList = (containerTypeId: number) => {
    return containerListFiltered.filter(container => container.containerTypeId === containerTypeId);
  };

  const onSearchTermChange = debounce((e: any, searchTerm: string) => {
    setContainerListFiltered(
      containerList.filter(container => container.containerNumber.toUpperCase().includes(searchTerm.toUpperCase())),
    );
    setSearchTerm(searchTerm);
  }, 200);

  const containerListRollOff = getContainerList(ROLL_OFF_CONTAINER_ID);
  const containerListCommercial = getContainerList(COMMERCIAL_CONTAINER_ID);
  const containerListCart = getContainerList(CART_CONTAINER_ID);

  return (
    <Modal padding="no" size="smallMedium" isLoading={isLoading}>
      <ModalFixedHeader padding="no">
        <GridColumn size={'12/12'} padding="medium">
          <ModalTitle>{translate('containers.selectContainers')}</ModalTitle>
          <ModalClose onClick={onCloseModal}>
            <ModalCloseIcon />
          </ModalClose>
          <form>
            <Field name="searchTerm" component={PanelSearch} onChange={onSearchTermChange} />
          </form>
        </GridColumn>
      </ModalFixedHeader>

      <GridColumn size={'12/12'}>
        <Table
          cells={getContainerListTableCells(1)}
          rows={containerListRollOff}
          rowComponent={SelectContainersTableRow}
          virtualized
          virtualizedProps={getVirtualizedProps(containerListRollOff)}
          rowProps={{ onCheckboxChange }}
          noOverflow
        />
        {getNoContainersMessage(containerListRollOff, 1)}
      </GridColumn>

      <GridColumn size={'12/12'} margin="medium no no no">
        <Table
          cells={getContainerListTableCells(2)}
          rows={containerListCommercial}
          rowComponent={SelectContainersTableRow}
          virtualized
          virtualizedProps={getVirtualizedProps(containerListCommercial)}
          rowProps={{ onCheckboxChange }}
          noOverflow
        />
        {getNoContainersMessage(containerListCommercial, 2)}
      </GridColumn>

      <GridColumn size={'12/12'} margin="medium no no no">
        <Table
          cells={getContainerListTableCells(3)}
          rows={containerListCart}
          rowComponent={SelectContainersTableRow}
          virtualized
          virtualizedProps={getVirtualizedProps(containerListCart)}
          rowProps={{ onCheckboxChange }}
          noOverflow
        />
        {getNoContainersMessage(containerListCart, 3)}
      </GridColumn>

      <ModalFixedFooter padding="sMedium small" justifyContent="space-around">
        <GridColumn size={'12/12'} align="center">
          <Button id="apply-container-button" color="primary" onClick={onApplyChanges}>
            {translate('common.apply')}
          </Button>
        </GridColumn>
      </ModalFixedFooter>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoading: state.fleet.containerFacilities.isLoading,
});

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'selectContainers',
  })(SelectContainersModal),
);
