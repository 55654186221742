import { FC, memo, useEffect, useMemo, useState } from 'react';
import { map as _map } from 'lodash-es';

import { useSelector } from 'src/core/hooks/useSelector';
import TravelPathGeoFencesGLSource from './TravelPathGeoFencesGLSource';
import useGeoFenceControllerMapbox from 'src/common/hooks/geoFenceControllerMapbox';
import { useDispatch } from 'react-redux';
import { resetGeoFences } from 'src/routes/ducks';
import { getFeatureCollection } from 'src/common/components/map/util';

interface Props {
  isSatellite: boolean;
  map: mapboxgl.Map;
  showRouteGeoFence?: boolean;
}

const TravelPathGeoFencesGL: FC<Props> = ({ isSatellite, map, showRouteGeoFence }) => {
  const dispatch = useDispatch();
  const geoFences = useSelector(state => state.routes.travelPathBuildAndEdit.geoFences);
  const [mapZoom, setMapZoom] = useState(map.getZoom());

  const { geoFence } = useSelector(state => state.routes.geoFence);

  const { bulkAddGeoFences, emptyGeoFences, allGeoFencesGeoJson } = useGeoFenceControllerMapbox();

  // adding geoFences to controller
  useEffect(() => {
    emptyGeoFences();
    const bulkToSet = _map(geoFences, (geoFence: any) => {
      const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
      return {
        ...geoFence,
        geoFenceCoordinates: parsedGeoFenceJson?.geometry?.coordinates,
      };
    });

    if (showRouteGeoFence && geoFence) {
      bulkToSet.push({
        ...geoFence,
        geoFenceCoordinates: geoFence?.geoFenceCoordinates,
      });
    }

    bulkToSet.length && bulkAddGeoFences(bulkToSet);
  }, [geoFence, geoFences, showRouteGeoFence]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    map?.once('load', () => {
      map.on('zoom', () => {
        setMapZoom(map.getZoom());
      });
    });
  }, [map]);

  const geoJSON = useMemo(() => getFeatureCollection(allGeoFencesGeoJson), [allGeoFencesGeoJson]);

  useEffect(() => {
    dispatch(resetGeoFences());
  }, [dispatch]);

  if (!geoJSON) return null;

  return <TravelPathGeoFencesGLSource geoJSON={geoJSON} mapZoom={mapZoom} isSatellite={isSatellite} />;
};

export default memo(TravelPathGeoFencesGL);
