import * as H from 'history';
import { camelCase } from 'lodash-es';
import { getFormValues } from 'redux-form';
import { useMemo } from 'react';

import { calculateIsDriverExperienceSimple } from 'src/customers/components/pages/streetNetwork/utils';
import { createUrl, getQueryParams } from '../../../../../utils/services/queryParams';
import {
  DisposalValue,
  IssueValue,
  IssueValues,
  LinkContainer,
  RouteSeeDetails,
  RouteTrackerSummary as RouteTrackerSummaryContainer,
} from '../../../styled';
import { Grid, GridColumn, Text } from '../../../../../core/components/styled';
import { IN_PROGRESS } from 'src/routes/constants/routeSequenceStatuses';
import { kFormatter } from '../../../../../utils/services/formatter';
import { RoutesSummary, Tickets } from '../../../../interfaces/Route';
import { RouteTrackerChartsWrapper } from 'src/routes/components/styled/RouteTracker';
import { RouteTrackerFormValues } from 'src/routes/components/forms/RouteTrackerForm';
import { ServiceConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { useSelector } from 'src/core/hooks/useSelector';
import DoughnutChart from 'src/core/components/DoughnutChart';
import StatisticsTable from 'src/fleet/components/pages/containersPageSections/StatisticsTable';
import translate from '../../../../../core/services/translate';

interface RouteTrackerSummaryProps {
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  location: H.Location<any>;
  routesSummary: RoutesSummary;
}

type Props = RouteTrackerSummaryProps;

const RouteTrackerSummary = ({ isSnowPlowRoute, isStreetSweeperRoute, location, routesSummary }: Props) => {
  const thisFormValues = useSelector(getFormValues('routeTracker')) as RouteTrackerFormValues;

  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const routeTrackerFormValues = isSnowOrSweeperRoute
    ? {
        routeFormEndDate: thisFormValues?.date.to,
        routeFormSearchTerm: thisFormValues?.searchTerm,
        routeFormStartDate: thisFormValues?.date?.from,
        routeFormStatusTypeIds: thisFormValues?.routeStatusTypeIds,
      }
    : undefined;

  const {
    searchTerm,
    startDate,
    endDate,
    vehicleTypeIds,
    routeStatusTypeIds,
    supervisors,
    serviceZones,
    groupIds,
    priorityTypeIds,
    agingIntervalIds,
  } = getQueryParams(location.search);
  const searchParams = {
    startDate: startDate || routeTrackerFormValues?.routeFormStartDate,
    endDate: endDate || routeTrackerFormValues?.routeFormEndDate,
    vehicleTypeIds,
    routeStatusTypeIds: routeStatusTypeIds || routeTrackerFormValues?.routeFormStatusTypeIds,
    searchTerm: searchTerm || routeTrackerFormValues?.routeFormSearchTerm,
    supervisors,
    serviceZones,
    groupIds,
    priorityTypeIds,
    agingIntervalIds,
  };

  const issuesReportedUrl = createUrl('/routes/issues-reported', undefined, {
    ...searchParams,
    isSnowPlowRoute,
    isStreetSweeperRoute,
  });
  const disposalsDetailsUrl = createUrl('/routes/disposals-details', undefined, searchParams);
  const snowMaterialTypesUrl = createUrl('/routes/material-pickups-summary', undefined, searchParams);
  const sweeperMaterialTypesUrl = createUrl('/routes/disposals-summary', undefined, searchParams);
  const sweeperWaterFillsUrl = createUrl('/routes/water-fills-summary', undefined, searchParams);

  const displayAgingIntervalsPieChartPlaceHolder =
    routesSummary.agingIntervals?.filter(agingInterval => agingInterval.value > 0).length > 0;

  const activeAgingIntervals = routesSummary.agingIntervals;
  const totalAgingIntervalsSegments = activeAgingIntervals?.reduce((acc, curr) => {
    return acc + curr.value;
  }, 0);

  const materialTypes = routesSummary.tickets?.reduce((acc: Tickets[], curr: Tickets) => {
    const index = acc.findIndex(
      (item: Tickets) =>
        item.materialType.id === curr.materialType.id && item.unitOfMeasureType.id === curr.unitOfMeasureType.id,
    );
    index > -1
      ? (acc[index].totalMaterialAmount += curr.totalMaterialAmount)
      : acc.push({
          materialType: curr.materialType,
          totalMaterialAmount: curr.totalMaterialAmount,
          unitOfMeasureType: curr.unitOfMeasureType,
          ticketsCount: curr.ticketsCount,
        });
    return acc;
  }, []);

  const { streetSweepingSettings } = useSelector(state => state.vendors.streetSweepingSettings);
  const { snowPlowSettings } = useSelector(state => state.vendors.snowPlowSettings);

  const altFleetSettings = isSnowOrSweeperRoute
    ? isSnowPlowRoute
      ? snowPlowSettings.serviceConfiguration.filter(
          (serviceConfiguration: ServiceConfiguration) => !serviceConfiguration.isNonServiceActivity,
        )
      : streetSweepingSettings.serviceConfiguration.filter(
          (serviceConfiguration: ServiceConfiguration) => !serviceConfiguration.isNonServiceActivity,
        )
    : undefined;

  const chartsMemo = useMemo(() => {
    if (!routesSummary) {
      return null;
    }

    const isDriverExperienceSimple = calculateIsDriverExperienceSimple(isSnowPlowRoute);
    const segmentsProgress = isDriverExperienceSimple
      ? routesSummary?.segmentsProgress?.filter(progress => progress.technicalName !== IN_PROGRESS)
      : routesSummary?.segmentsProgress;
    const jobsProgress = routesSummary?.jobsProgress;

    const segmentsOrJobsProgressValues = isSnowOrSweeperRoute
      ? segmentsProgress?.map(el => el?.value) || []
      : jobsProgress?.map(el => el.value);
    const segmentsOrJobsProgressLabels = isSnowOrSweeperRoute
      ? segmentsProgress?.map(el => el?.label) || []
      : jobsProgress?.map(el => el.label);
    const segmentsOrJobsProgressColors = isSnowOrSweeperRoute
      ? segmentsProgress?.map(el => el?.color) || []
      : jobsProgress?.map(el => el.color);
    const segmentsOrJobsProgressDatas = isSnowOrSweeperRoute
      ? segmentsProgress?.map(el => (el?.value * 100) / routesSummary?.totalSegments!) || []
      : jobsProgress?.map(el => (el.value * 100) / routesSummary.totalJobs);

    const routeProgressValues = routesSummary.routeProgress?.map(el => el.value);
    const routeProgressLabels = routesSummary.routeProgress?.map(el => el.label);
    const routeProgressColors = routesSummary.routeProgress?.map(el => el.color);
    const routeProgressDatas = routesSummary.routeProgress?.map(el => (el.value * 100) / routesSummary.totalRoutes);

    const activeAgingIntervalsValues = activeAgingIntervals?.map(el => el.value);
    const activeAgingIntervalsLabels = activeAgingIntervals?.map(el => el?.label);
    const activeAgingIntervalsColors = activeAgingIntervals?.map(el => el?.color);
    const activeAgingIntervalsDatas = activeAgingIntervals?.map(el => (el.value * 100) / totalAgingIntervalsSegments);

    return (
      <>
        <RouteTrackerChartsWrapper hasBorderRight isWider={!isSnowOrSweeperRoute}>
          <DoughnutChart
            showLegend
            showSummaryLegend
            showSummaryOnHover
            showWholeLegend
            legendTitle={translate('routes.routes')}
            title={kFormatter(routesSummary.totalRoutes, { decimals: 0, kThreshold: 10000 })?.toString()}
            doughnutSize={88}
            dataset={{
              data: routeProgressDatas,
              backgroundColor: routeProgressColors,
            }}
            rawDataValues={routeProgressValues}
            labels={routeProgressLabels}
          />
        </RouteTrackerChartsWrapper>
        <RouteTrackerChartsWrapper hasBorderRight isWider={!isSnowOrSweeperRoute}>
          <DoughnutChart
            showLegend
            showSummaryLegend
            showSummaryOnHover
            showWholeLegend
            legendTitle={isSnowOrSweeperRoute ? translate('routes.segments') : translate('routes.stops')}
            title={kFormatter(isSnowOrSweeperRoute ? routesSummary?.totalSegments || 0 : routesSummary.totalJobs, {
              decimals: 0,
              kThreshold: 10000,
            }).toString()}
            doughnutSize={88}
            dataset={{
              data: segmentsOrJobsProgressDatas,
              backgroundColor: segmentsOrJobsProgressColors,
            }}
            rawDataValues={segmentsOrJobsProgressValues}
            labels={segmentsOrJobsProgressLabels}
          />
        </RouteTrackerChartsWrapper>
        {isSnowOrSweeperRoute && (
          <RouteTrackerChartsWrapper hasBorderRight>
            <DoughnutChart
              showLegend
              showSummaryLegend
              showSummaryOnHover
              showWholeLegend
              legendTitle={translate('routes.latestActivity')}
              title={kFormatter(displayAgingIntervalsPieChartPlaceHolder ? totalAgingIntervalsSegments : 0, {
                decimals: 0,
                kThreshold: 10000,
              }).toString()}
              doughnutSize={88}
              dataset={{
                data: activeAgingIntervalsDatas,
                backgroundColor: activeAgingIntervalsColors,
              }}
              rawDataValues={activeAgingIntervalsValues}
              labels={activeAgingIntervalsLabels}
            />
          </RouteTrackerChartsWrapper>
        )}
      </>
    );
  }, [
    activeAgingIntervals,
    displayAgingIntervalsPieChartPlaceHolder,
    isSnowOrSweeperRoute,
    isSnowPlowRoute,
    routesSummary,
    totalAgingIntervalsSegments,
  ]);

  return (
    <RouteTrackerSummaryContainer>
      <Grid padding="no">
        {chartsMemo}

        <GridColumn
          size={
            isSnowOrSweeperRoute &&
            altFleetSettings?.find((altFleetSetting: ServiceConfiguration) => altFleetSetting.isActive)
              ? '1/12'
              : '3/12'
          }
          alignVerticalCenter
          borderRight
        >
          <Text block weight="medium" size="mLarge" align="center" margin="no no small">
            {translate('tooltips.issues')}
          </Text>
          <IssueValues>
            <IssueValue>
              {isSnowOrSweeperRoute ? routesSummary.totalIssues : routesSummary.totalIssuesReported}
            </IssueValue>
          </IssueValues>
          <LinkContainer margin="xxSmall no no">
            <RouteSeeDetails id="see-issues-details-button" to={issuesReportedUrl}>
              {translate('common.seeDetails')}
            </RouteSeeDetails>
          </LinkContainer>
        </GridColumn>

        {isSnowOrSweeperRoute ? (
          <>
            <GridColumn size="2/12" alignVerticalCenter borderRight padding="no xxSmall">
              <StatisticsTable
                title={translate('routes.materialTrips.materialTrips')}
                noViewAllButton
                isCentered
                isSmaller
                isTitleSmaller
                displayAllData
                rows={
                  (!!materialTypes?.length &&
                    materialTypes?.map(materialType => {
                      return {
                        label: translate(
                          `common.materialTickets.materialTypeValues.${camelCase(
                            materialType.materialType.technicalName,
                          )}`,
                        ),
                        value:
                          materialType.totalMaterialAmount +
                          ' ' +
                          translate(
                            `common.materialTickets.unitOfMeasureValues.${camelCase(
                              materialType.unitOfMeasureType.technicalName,
                            )}`,
                          ),
                      };
                    })) ||
                  []
                }
              />
              <LinkContainer margin="xxSmall no no">
                {isSnowPlowRoute ? (
                  <RouteSeeDetails id="see-snow-details-button" to={snowMaterialTypesUrl}>
                    {translate('common.seeDetails')}
                  </RouteSeeDetails>
                ) : (
                  <>
                    <RouteSeeDetails id="see-sweeper-details-button" to={sweeperMaterialTypesUrl}>
                      {translate('common.seeDetailsMaterialPickups')}
                    </RouteSeeDetails>
                    <br />
                    <RouteSeeDetails id="see-sweeper-water-fills-details-button" to={sweeperWaterFillsUrl}>
                      {translate('common.seeDetailsWaterFillUps')}
                    </RouteSeeDetails>
                  </>
                )}
              </LinkContainer>
            </GridColumn>
          </>
        ) : (
          <GridColumn
            size={
              isSnowOrSweeperRoute &&
              altFleetSettings?.find((altFleetSetting: ServiceConfiguration) => altFleetSetting.isActive)
                ? '2/12'
                : '4/12'
            }
            alignVerticalCenter
          >
            <Text block weight="medium" size="mLarge" align="center" margin="no no small">
              {translate('insights.disposal')}
            </Text>
            <IssueValues>
              <DisposalValue>
                {routesSummary.totalDisposalTrips} {translate('common.trips')}
              </DisposalValue>
              <DisposalValue>
                {routesSummary.totalTons?.toFixed(2)} {translate('common.tons')}{' '}
              </DisposalValue>
            </IssueValues>
            <LinkContainer margin="xxSmall no no">
              <RouteSeeDetails id="see-disposals-details-button" to={disposalsDetailsUrl}>
                {translate('common.seeDetails')}
              </RouteSeeDetails>
            </LinkContainer>
          </GridColumn>
        )}
        {!!altFleetSettings && (
          <GridColumn alignVerticalCenter width="125px">
            {isSnowPlowRoute ? (
              <>
                {altFleetSettings[0].isActive && (
                  <Text flex justifyContent="space-between">
                    <Text block weight="medium" size="small" align="center" margin="no no small">
                      {translate('customers.streetNetwork.serviceActivity.plowed')}
                    </Text>
                    <Text block weight="medium" size="large" align="center" margin="no no small">
                      {Math.round((routesSummary?.plowed! * 100) / routesSummary?.totalSegments!) || 0}%
                    </Text>
                  </Text>
                )}
                {altFleetSettings[1].isActive && (
                  <Text flex justifyContent="space-between">
                    <Text block weight="medium" size="small" align="center" margin="no no small">
                      {translate('customers.streetNetwork.serviceActivity.salted')}
                    </Text>
                    <Text block weight="medium" size="large" align="center" margin="no no small">
                      {Math.round((routesSummary?.salted! * 100) / routesSummary?.totalSegments!) || 0}%
                    </Text>
                  </Text>
                )}
                {altFleetSettings[2].isActive && (
                  <Text flex justifyContent="space-between">
                    <Text block weight="medium" size="small" align="center" margin="no no small">
                      {translate('customers.streetNetwork.serviceActivity.brined')}
                    </Text>
                    <Text block weight="medium" size="large" align="center" margin="no no small">
                      {Math.round((routesSummary?.brined! * 100) / routesSummary?.totalSegments!) || 0}%
                    </Text>
                  </Text>
                )}
              </>
            ) : (
              altFleetSettings[0].isActive && (
                <Text flex justifyContent="space-between">
                  <Text block weight="medium" size="small" align="center" margin="no no small">
                    {translate('customers.streetNetwork.serviceActivity.swept')}
                  </Text>
                  <Text block weight="medium" size="large" align="center" margin="no no small">
                    {Math.round((routesSummary?.swept! * 100) / routesSummary?.totalSegments!) || 0}%
                  </Text>
                </Text>
              )
            )}
          </GridColumn>
        )}
      </Grid>
    </RouteTrackerSummaryContainer>
  );
};

export default RouteTrackerSummary;
