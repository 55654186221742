import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from 'src/contracts/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { loadRouteTrackerIssues } from '../../ducks';
import RouteTrackerIssuesModal from './RouteTrackerIssuesModal';

interface Props {
  closeModal: () => void;
  loadRouteTrackerIssues: DuckFunction<typeof loadRouteTrackerIssues>;
  routeId?: number;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
}

class RouteTrackerIssuesModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { routeId, loadRouteTrackerIssues } = this.props;
    return loadRouteTrackerIssues(routeId);
  };

  render() {
    const { closeModal, isSnowPlowRoute, isStreetSweeperRoute } = this.props;

    return (
      <Resolver
        successComponent={RouteTrackerIssuesModal}
        successProps={{
          closeModal,
          isSnowPlowRoute,
          isStreetSweeperRoute,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = { loadRouteTrackerIssues };

export default connect(undefined, mapDispatchToProps)(RouteTrackerIssuesModalResolver);
