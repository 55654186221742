import React from 'react';
import humps from 'humps';

import { TableRow, TableCell, Text } from '../../../../core/components/styled';
import { dateAndTime } from '../../../../utils/services/formatter';
import translate from '../../../../core/services/translate';
import { DownloadFile } from '../../styled/Customers';

interface Props {
  date: Date | string;
  errorFileDownloadUrl: string;
  errorFileName: string;
  fileName: string;
  fileDownloadUrl: string;
  failedRecordsCount: number;
  importedRecordsCount: number;
  totalRecordsCount: number;
  successRecordsCount: number;
  status: string;
}

export const EsriImportModalTableRow: React.SFC<Props> = ({
  date: importDate,
  errorFileDownloadUrl,
  fileName,
  fileDownloadUrl,
  failedRecordsCount,
  importedRecordsCount,
  status,
  successRecordsCount,
  totalRecordsCount,
}) => (
  <TableRow>
    <TableCell width={'24%'}>
      {fileDownloadUrl ? <DownloadFile href={fileDownloadUrl}>{fileName}</DownloadFile> : <Text>{fileName}</Text>}
    </TableCell>
    <TableCell width={'11%'}>
      <Text>{translate(`customers.customerUpload.statuses.${humps.camelize(status)}`)}</Text>
    </TableCell>
    <TableCell width={'10%'}>
      <Text>{totalRecordsCount}</Text>
    </TableCell>
    <TableCell width={'10%'}>
      <Text>{importedRecordsCount}</Text>
    </TableCell>
    <TableCell width={'10%'}>
      <Text>{successRecordsCount}</Text>
    </TableCell>
    <TableCell width={'9%'}>
      <Text>{failedRecordsCount}</Text>
    </TableCell>
    <TableCell width={'11%'}>
      {errorFileDownloadUrl && <DownloadFile href={errorFileDownloadUrl}>{translate('common.download')}</DownloadFile>}
    </TableCell>
    <TableCell width={'12%'}>
      <Text>{dateAndTime(importDate)}</Text>
    </TableCell>
  </TableRow>
);
