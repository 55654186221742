import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { ContainerColorConfiguration } from 'src/vendors/interfaces/ContainerColorConfiguration';
import { ContainerColorConfigurationForm } from '../forms';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { saveContainerColorConfiguration } from '../../ducks';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import translate from '../../../core/services/translate';

interface Props {
  binColors: TechnicalType[];
  closeModal: (pristine: boolean) => void;
  containerColorConfiguration: ContainerColorConfiguration[];
  isLoading: boolean;
  isSaving: boolean;
  saveContainerColorConfiguration: DuckFunction<typeof saveContainerColorConfiguration>;
  vendorId: number;
}

class ContainerColorConfigurationModal extends PureComponent<Props> {
  onSubmit = (containerColorConfiguration: any) => {
    const { vendorId, saveContainerColorConfiguration, closeModal } = this.props;

    const containerColorConfigurationFormatted = containerColorConfiguration.containerColorConfiguration.map(
      (containerColorConfiguration: ContainerColorConfiguration) => {
        return containerColorConfiguration.binColor && containerColorConfiguration.binColor.id === null
          ? {
              ...containerColorConfiguration,
              binColor: null,
            }
          : containerColorConfiguration;
      },
    );

    saveContainerColorConfiguration(vendorId, containerColorConfigurationFormatted)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.containerColorConfigurationSaved'));
        closeModal(true);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.alertMessages.containerColorConfigurationError'));
      });
  };

  render() {
    const { binColors, closeModal, containerColorConfiguration, isLoading, isSaving } = this.props;

    return (
      <Modal padding="no" size="small" isLoading={isLoading || isSaving}>
        <ModalSection padding="medium no no">
          <ModalTitle>{translate('vendors.featureCodes.containerColorConfiguration')}</ModalTitle>
        </ModalSection>
        <ModalSection>
          <ContainerColorConfigurationForm
            binColors={binColors}
            containerColorConfiguration={containerColorConfiguration}
            initialValues={{ containerColorConfiguration }}
            onCancel={closeModal}
            onSubmit={this.onSubmit}
          />
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  binColors: state.fleet.vehicle.binColors,
  containerColorConfiguration: state.vendors.containerColorConfiguration.containerColorConfiguration,
  isLoading: state.vendors.containerColorConfiguration.isLoading,
  isSaving: state.vendors.containerColorConfiguration.isSaving,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { saveContainerColorConfiguration };

export default connect(mapStateToProps, mapDispatchToProps)(ContainerColorConfigurationModal);
