import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Popup } from 'react-map-gl';

import { RTB_TEMPLATE_STATUSES_MAP, RTBTemplateStatuses } from '../../constants/routeTemplateBuilder';
import { rotate } from '../../../core/styles';
import { Text, Button, FormGroup } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import { sizeMapper } from 'src/utils/styles';

interface DrawingInstructionsContainerProps {
  isOpen?: boolean;
}

export const DrawingInstructionsContainer = styled.div<DrawingInstructionsContainerProps>`
  position: absolute;
  left: 75px;
  top: 20px;
  padding: 10px;
  background-color: rgb(255, 255, 255, 0.5);
  font-size: 12px;
  border-radius: 7px;
  cursor: ${props => (props.isOpen ? 'default' : 'pointer')} !important;

  & > div:first-child {
    cursor: pointer !important;
  }
`;

export const Wrapper = styled.div<{ isDrawing: boolean; drawingEnabled: boolean; hidden?: boolean }>`
  height: 100%;
  position: relative;

  & .overlays {
    cursor: ${p => (p.isDrawing ? 'crosshair' : 'initial')};
    pointer-events: ${p => (p.drawingEnabled ? 'all' : 'none !important')};

    & > :not(#editor) {
      pointer-events: all;
    }
  }

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    display: none;
  }

  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`;

export const SelectionInfoContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background: white;
  z-index: 2;
`;

export const SelectionInfoText = styled.div`
  flex: 1;
`;

export const SelectionInfoButtons = styled.div`
  display: flex;
  align-items: center;
`;

interface RouteTemplateBuilderDataContainerProps {
  scroll?: boolean;
}

export const RouteTemplateBuilderDataContainer = styled.div<RouteTemplateBuilderDataContainerProps>`
  height: calc(100vh - 250px);
  width: 100%;
  position: relative;

  ${props =>
    props.scroll &&
    css`
      overflow: auto;
    `}
`;

export const RouteTemplateBuilderMapInfoOverlay = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  opacity: 0.7;

  h4 {
    text-align: center;
    margin-top: 200px;
  }
`;

export const RouteTemplateBuilderLegendItem = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;
  font-size: 12px;

  &:not(:first-child) {
    margin: 5px 0 0;
  }
`;

export const RouteTemplateBuilderLegendItems = styled.div`
  flex: 1;
  overflow: auto;
  padding: 7px 15px 15px;
`;

export const RouteTemplateBuilderLegendItemColor = styled.div<{ color: string; hasBorder?: boolean }>`
  height: 12px;
  width: 12px;
  background: ${p => p.color};
  margin-right: 8px;
  border-radius: 15px;

  ${props =>
    props.hasBorder &&
    css`
      border: 1px solid black;
    `}
`;

export const RouteTemplateBuilderMapControlToggle = styled.div`
  cursor: pointer;
  display: flex;
  padding: 15px;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

export const ChangeLogVersionButton = styled.div`
  padding: 0 10px;
  text-align: left;
`;

interface RouteTemplateBuilderMapControlContainerProps {
  isOpen?: boolean;
  disabled?: boolean;
}

export const RouteTemplateBuilderMapControlContainer = styled.div<RouteTemplateBuilderMapControlContainerProps>`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  ${p =>
    p.isOpen &&
    css`
      width: 330px;
      height: 320px;
    `}

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const ChangeLogChangesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

interface RouteTemplateStatusProps {
  inherit?: boolean;
  inverted?: boolean;
  margin?: string;
  neutral?: boolean;
  statusId?: RTBTemplateStatuses;
}

export const RouteTemplateStatus = styled.span<RouteTemplateStatusProps>`
  margin-left: 8px;
  border-radius: 4px;
  padding: 2px 3px;
  font-size: 10px;

  ${props =>
    typeof props.statusId === 'number' &&
    css`
      color: ${RTB_TEMPLATE_STATUSES_MAP[props.statusId].color};
      border: 1px solid ${RTB_TEMPLATE_STATUSES_MAP[props.statusId].color};
    `}

  ${props =>
    props.neutral &&
    css`
      color: ${props.theme.grayDarker};
      border: 1px solid ${props.theme.grayDarker};
    `}

  ${props =>
    props.inherit &&
    css`
      color: inherit;
      border: 1px solid ${props.theme.grayDarker};
    `}

  ${props =>
    props.margin?.length &&
    css`
      margin: ${sizeMapper(props.margin)};
    `}

  ${props =>
    props.inverted &&
    css`
      color: white;
      border: 1px solid white;
    `}
`;

export const ChangeLogRowIcon = styled(Icon).attrs({ icon: 'arrowRight' })`
  flex: 0 0 14px;
  visibility: hidden;
  align-self: center;
`;

interface ChangeLogRowProps {
  noBorder?: boolean;
  noInteraction?: boolean;
  highlight?: boolean;
  isLoading?: boolean;
  visibleButtons?: boolean;
}

export const ChangeLogRow = styled.div<ChangeLogRowProps>`
  padding: 0;
  text-align: left;
  padding: 10px 15px;
  position: relative;

  ${props =>
    !props.noInteraction &&
    css`
      cursor: pointer;

      ${!props.noBorder &&
      css`
        &:not(:first-child):before {
          content: '';
          position: absolute;
          top: 0;
          left: 15px;
          right: 15px;
          height: 1px;
          background: rgba(0, 0, 0, 0.05);
        }
      `}

      &:hover:before,
      &:hover + &:before {
        display: none;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.02);

        & ${ChangeLogRowIcon} {
          visibility: visible;
        }
      }
    `}

  ${props =>
    props.highlight &&
    css`
      &,
      &:hover {
        background: ${transparentize(0.95, props.theme.brandPrimary)};
      }

      &:before,
      & + &:before {
        display: none;
      }
    `}

  & ${Button} {
    visibility: ${p => (p.visibleButtons ? 'visible' : 'hidden')};
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &:hover ${Button} {
    visibility: visible;
  }

  ${props =>
    props.isLoading &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: 10px;
        right: 15px;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: 2px solid ${props.theme.brandPrimary};
        border-right-color: transparent;
        animation: ${rotate} 1s linear infinite;
        z-index: 1;
      }
    `}
`;

export const ChangeLogRowServicesText = styled(Text).attrs({ block: true, size: 'xSmall' })`
  margin-top: 5px;
`;

export const ChangeLogDetailsContainer = styled.div`
  display: flex;
`;

export const ChangeLogDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ChangeLogRouteTemplateTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChangeLogRouteTemplateEditButton = styled.button<{ margin?: string }>`
  appearance: none;
  border: 0;
  background: 0;
  cursor: pointer;
  padding: 0;
  flex: 0 0 14px;
  align-self: center;
  margin: ${p => sizeMapper(p.margin, 'no')};
`;

export const MapPopup = styled(Popup)`
  .mapboxgl-popup-content {
    border-radius: 7px;
    padding: 20px 12px 12px;

    .mapboxgl-popup-close-button {
      border-radius: 0 7px 0 7px;
    }
  }

  &.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
    border-top-left-radius: 0;
  }

  &.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
    border-top-right-radius: 0;
  }

  &.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    border-bottom-right-radius: 0;
  }

  &.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    border-bottom-left-radius: 0;
  }
`;

const servicePopupWidth = 400;
const servicePopupMinHeight = 93;

export const ServicePopupLoading = styled.div`
  position: relative;
  width: ${servicePopupWidth}px;
  height: ${servicePopupMinHeight}px;

  &:after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    top: calc(50% - 9.5px);
    left: calc(50% - 9.5px);
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid ${props => props.theme.brandPrimary};
    border-right-color: transparent;
    animation: ${rotate} 1s linear infinite;
    z-index: 1;
  }
`;

export const ServicePopupContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${servicePopupWidth}px;
  min-height: ${servicePopupMinHeight}px;

  & > ${Text} {
    flex: 0 0 100%;
  }
`;

export const ServicePopupDataWrap = styled.div`
  margin: 0 -5px;
  width: calc(100% + 10px);
  display: flex;
  flex-wrap: wrap;

  & > ${FormGroup} {
    margin: 10px 5px 0;
  }
`;

export const ServicePopupTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ServicePopupRouteEntry = styled(Text).attrs({
  block: true,
  size: 'xSmall',
  weight: 'light',
  color: 'black',
})`
  padding: 1px 0;
`;
