import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface PreventativeMaintenanceFormPieceWrapperProps {
  isActive?: boolean;
}

export const PreventativeMaintenanceFormPieceWrapper = styled.div<PreventativeMaintenanceFormPieceWrapperProps>`
  position: relative;

  ${props =>
    props.isActive &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: -20px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        background: ${transparentize(0.97, props.theme.brandPrimary)};
        box-shadow: 0 0 0 3px ${props.theme.brandPrimary};
      }
    `}
`;
