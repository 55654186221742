import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import DashboardHaulerLocationsGLLayers from './DashboardHaulerLocationsGLLayers';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { VENDOR_LOCATION_ICON_TYPES_NEW } from 'src/common/constants/vendorLocationIcons';
import DashboardHaulerLocationsGLPopup from './DashboardHaulerLocationsGLPopup';
import { DASHBOARD_CLUSTERS_SOURCE, DASHBOARD_HAULER_LOCATIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';

const mapImages = VENDOR_LOCATION_ICON_TYPES_NEW.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: DASHBOARD_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const DashboardHaulerLocationsGL: React.FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_CLUSTERS_SOURCE &&
              feature.properties?.layer === DASHBOARD_HAULER_LOCATIONS_LAYER,
          );

        if (!!feature) {
          dispatch(setDashboardSelectedFeature('vendorLocations', feature.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <DashboardHaulerLocationsGLLayers />
      <DashboardHaulerLocationsGLPopup />
    </>
  );
};

export default DashboardHaulerLocationsGL;
