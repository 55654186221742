import { ModalImage } from 'src/routes/interfaces/RouteImages';
import { getBlobIdFromUrl } from 'src/utils/imageHelpers';
import { http } from '../../core/services/http';
import { CustomerLocationEmail } from '../interfaces/CustomerLocationEmail';
import { Email } from '../interfaces/Email';

export const loadCustomerCommunication = (customerId: number, routeLocationId?: string) => {
  return http.get<Email[]>('/customers/emails', { params: { customerId, routeLocationId } }).then(response => {
    return response.data;
  });
};

interface CustomerEmail {
  emailMessage: string;
  emailSubject: string;
  fromEmail: string;
  toEmail: string;
  imageUrl?: string;
  images?: ModalImage[];
  imageBlobIds?: string[];
  locationId?: number;
  vehicleTypeId?: number;
  routeLocationId?: number;
  routeLocationDetailPickupExceptionId?: number;
  customerId?: number;
  isInfoEmail?: boolean;
  routeId?: number;
}

export const sendCustomerEmail = ({
  emailMessage,
  emailSubject,
  fromEmail,
  toEmail,
  imageUrl,
  images,
  locationId,
  imageBlobIds = [],
  vehicleTypeId,
  routeLocationId,
  routeLocationDetailPickupExceptionId,
  customerId,
  isInfoEmail,
  routeId,
}: CustomerEmail) => {
  if (!imageBlobIds.length) {
    if (images && images.length) {
      imageBlobIds = images.map(image => getBlobIdFromUrl(image.imageUrl));
    } else if (imageUrl) {
      imageBlobIds.push(getBlobIdFromUrl(imageUrl));
    }
  }

  const basePayload = {
    emailMessage,
    emailSubject,
    fromEmail,
    toEmail,
    imageUrl: images && images.length ? images[0].imageUrl : imageUrl,
    imageBlobIds,
    vehicleTypeId,
    routeLocationDetailPickupExceptionId,
    routeLocationId,
  };

  if (isInfoEmail) {
    return http.post('/customers/sendEmail', {
      ...basePayload,
      locationId,
      customerId,
      routeId,
    });
  }

  return http.post('/customers/sendServiceIssueEmail', {
    ...basePayload,
    routeId,
    routeLocationId,
    routeLocationDetailPickupExceptionId,
  });
};

export const getCustomerEmail = (customerId?: number, routeLocationId?: number): Promise<CustomerLocationEmail[]> => {
  return http
    .get<CustomerLocationEmail[]>('/customers/emailAddresses', { params: { customerId, routeLocationId } })
    .then(response => response.data);
};
