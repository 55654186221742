import { formValueSelector } from 'redux-form';

import {
  checkIfSnowPlowIsEnabled,
  checkIfSnowPlowConditionsAreVisible,
  checkIfRoadConditionsAreEnabled,
} from 'src/vendors/ducks/features';
import { ContainerInsights } from 'src/routes/components/mapWithTimeline/Interfaces';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';
import { useSelector } from 'src/core/hooks/useSelector';
import { SNOW_ROAD_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';
import { floor } from 'lodash-es';

const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);

export const useCheckIfContainerInsightsAreVisible = () => {
  const dataIsLoaded = useSelector(
    state =>
      !!(((state.dashboard.mapInsights?.mapInsights as any)?.containerInsights.insights || []) as ContainerInsights[])
        .length,
  );

  return dataIsLoaded;
};

export const useCheckIfRoadConditionsAreVisible = () => {
  const filter = useSelector(state => formSelector(state, 'roadConditions.filters') || {});
  const dataIsLoaded = useSelector(state => !!state.dashboard.roadConditions.roadConditions.length);
  const roadConditionsEnabledInAppSettings = useSelector(checkIfRoadConditionsAreEnabled);
  const roadConditionsEnabledInDashboardFilters = filter.showRoadConditions;

  return roadConditionsEnabledInAppSettings && roadConditionsEnabledInDashboardFilters && dataIsLoaded;
};

export const useCheckIfSnowRoadConditionsAreVisible = () => {
  const filter = useSelector(state => formSelector(state, SNOW_ROAD_CONDITIONS) || {});
  const dataIsLoaded = useSelector(state => !!state.dashboard.snowRoadConditions.snowRoadConditions.length);
  const snowRoadConditionsEnabledInAppSettings = useSelector(
    state => checkIfSnowPlowIsEnabled(state) && checkIfSnowPlowConditionsAreVisible(state),
  );
  const snowRoadConditionsEnabledInFilters = filter.showSnowRoadConditions;

  return snowRoadConditionsEnabledInAppSettings && snowRoadConditionsEnabledInFilters && dataIsLoaded;
};

export const getTimeFromMinutes = (minutes: number) => {
  minutes = Math.abs(minutes);

  const hours = floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const hourString = `${hours}h`;
  const minuteString = `${remainingMinutes}m`;

  return `${hourString} ${minuteString}`;
};
