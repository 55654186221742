import moment from 'moment';

export function expire(key: string, expirationTime: string) {
  localStorage.setItem(`${key}Exp`, expirationTime);
}

export function setItem(key: string, value: string, expirationTime?: string) {
  localStorage.setItem(key, value);
  if (expirationTime) {
    expire(key, expirationTime);
  }
}

export function removeItem(key: string) {
  localStorage.removeItem(key);
  localStorage.removeItem(`${key}Exp`);
}

export function getItem(key: string) {
  const storageTime = localStorage.getItem(`${key}Exp`);
  const expirationTime = storageTime ? moment(new Date(storageTime)) : undefined;
  if (expirationTime && moment().isAfter(expirationTime)) {
    removeItem(key);
  }
  return localStorage.getItem(key);
}

export function clear() {
  localStorage.clear();
}
