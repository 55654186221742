import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { InferenceAuditForm } from '../forms';
import { ImageModalToggle } from '../styled/InferenceAuditPage';
import { InferenceAudit } from '../../interfaces/interfaces';

interface Props extends InferenceAudit {
  handleSave: (audit: InferenceAudit) => void;
  index: number;
  savingAuditIndex?: number;
  toggleModal: (isOpen: boolean, index: number) => void;
}

const InferenceAuditPageTableRow: React.SFC<Props> = props => {
  const { handleSave, index, toggleModal, savingAuditIndex, ...audit } = props;

  const { id, image, modelClassName, detectionNames } = audit;

  return (
    <TableRow key={id}>
      <TableCell width="50%" padding="sMedium small sMedium" vertical>
        <ImageModalToggle role="button" tabIndex={index} onClick={() => toggleModal(true, index)}>
          <img src={image} alt={`${modelClassName}: ${detectionNames}`} width="100%" />
        </ImageModalToggle>
      </TableCell>
      <TableCell width="50%" padding="sMedium no sMedium">
        <InferenceAuditForm
          auditIndex={index}
          form={`inferenceAudit-${index}`}
          key={`inferenceAudit-${index}`}
          isLoading={index === savingAuditIndex}
          onSubmit={handleSave}
        />
      </TableCell>
    </TableRow>
  );
};

export default InferenceAuditPageTableRow;
