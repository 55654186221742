import { identity } from 'lodash-es';
import { createSelector } from 'reselect';
import moment from 'moment';

import { getQueryParams } from '../../utils/services/queryParams';
import { TODAY_FORMATTED } from '../../core/constants';

const getUnassignedWeightTicketsFormInitialValues = (queryString: string) => {
  const { searchTerm, startDate, endDate } = getQueryParams(queryString);
  const from = moment().subtract(3, 'days').toDate();
  return {
    searchTerm,
    date: {
      from: startDate || moment(from).format('MM/DD/YYYY'),
      to: endDate || TODAY_FORMATTED,
    },
  };
};

const unassignedWeightTicketsFormInitialValuesSelector = createSelector(
  getUnassignedWeightTicketsFormInitialValues,
  identity,
);
export default unassignedWeightTicketsFormInitialValuesSelector;
