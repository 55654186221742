import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { size, difference, filter } from 'lodash-es';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { FlagLocationForm } from '../forms';
import { LocationFlags } from '../../interfaces/LocationFlags';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveLocationFlags, resetLocationFlags } from '../../ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  locationFlags?: LocationFlags[];
  resetLocationFlags: DuckAction<typeof resetLocationFlags>;
  saveLocationFlags: DuckFunction<typeof saveLocationFlags>;
  vendorId: number;
}

interface State {
  locationFlags?: LocationFlags[];
}

class FlagLocationModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locationFlags: props.locationFlags,
    };
  }

  componentWillUnmount() {
    this.props.resetLocationFlags();
  }

  onSubmit = (locationFlags: any) => {
    const { vendorId, saveLocationFlags, closeModal } = this.props;
    const activeLocationsFlags = size(filter(locationFlags, ({ isEnabled }) => isEnabled));

    if (activeLocationsFlags > 16) {
      createErrorNotification(translate('vendors.alertMessages.locationFlagsMax16'));
      return;
    }

    if (activeLocationsFlags < 1) {
      createErrorNotification(translate('vendors.alertMessages.locationFlagsMin1'));
      return;
    }

    saveLocationFlags(vendorId, locationFlags)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.flagLocationConfigurationSaved'));
        closeModal(true);
      })
      .catch(({ code }) => createErrorNotification(translate(createTranslationKey(code, 'vendors.alertMessages'))));
  };

  closeModal = () => {
    const { locationFlags, closeModal } = this.props;
    closeModal(!size(difference(locationFlags, this.state.locationFlags || [])));
  };

  render() {
    return (
      <Modal title={translate('vendors.featureCodes.flagLocation')} onClose={this.closeModal}>
        <FlagLocationForm onSubmit={this.onSubmit} onCancel={this.closeModal} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  locationFlags: state.vendors.locationFlags.locationFlags,
});

const mapDispatchToProps = { saveLocationFlags, resetLocationFlags };

export default connect(mapStateToProps, mapDispatchToProps)(FlagLocationModal);
