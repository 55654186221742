import { map, debounce } from 'lodash-es';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import { time } from 'src/utils/services/formatter';

/**
 * The service for the travel path builder. It handles the selection of breadcrumbs and the creation of the travel path.
 */
const useTravelPathBuilderService = () => {
  const { routeVehicleBreadCrumbs, isDoingTravelPathBuilderTracing } = useSelector(
    state => state.routes.travelPathBuildAndEdit,
  );
  const [minSelected, setMinSelected] = useState(0);
  const [maxSelected, setMaxSelected] = useState(0);

  /**
   * The breadcrumbs for the travel path
   */
  const breadCrumbs = useMemo(() => {
    if (!routeVehicleBreadCrumbs || routeVehicleBreadCrumbs.vehicles.length === 0) return [];

    const firstVehicle = routeVehicleBreadCrumbs.vehicles[0];

    return map(firstVehicle.coords, tracking => {
      return map(tracking, coord => {
        return {
          latitude: coord.lat,
          longitude: coord.lng,
          vehicleSpeed: coord.sp,
          bearing: coord.bg,
          reportedDate: moment(coord.ts).format('YYYY-MM-DD HH:mm:ss.SSS'),
        };
      });
    }).reduce((acc, curr) => [...acc, ...curr], []);
  }, [routeVehicleBreadCrumbs]);

  //change the max and min selected to the index
  useEffect(() => {
    setMaxSelected(breadCrumbs.length - 1);
    setMinSelected(0);
  }, [breadCrumbs.length]);

  /**
   * The GeoJSON for the minimum and maximum Flags
   */

  const minAndMaxCoordinatesGeoJSON = useMemo(() => {
    if (!routeVehicleBreadCrumbs || routeVehicleBreadCrumbs.vehicles.length === 0) return null;

    const minBreadCrumb = breadCrumbs[minSelected];
    if (!minBreadCrumb) {
      return null;
    }

    const minFeature = getPointFeature(minSelected, [minBreadCrumb.longitude, minBreadCrumb.latitude], {
      ...minBreadCrumb,
      reportedDateTime: time(minBreadCrumb.reportedDate),
      id: minSelected,
      icon: 'greenFlag',
      isMin: minSelected === 0,
    });

    const maxBreadCrumb = breadCrumbs[maxSelected];
    if (!maxBreadCrumb) {
      return null;
    }

    const maxFeature = getPointFeature(maxSelected, [maxBreadCrumb.longitude, maxBreadCrumb.latitude], {
      ...maxBreadCrumb,
      reportedDateTime: time(maxBreadCrumb.reportedDate),
      id: maxSelected,
      icon: 'redFlag',
      isMax: maxSelected === breadCrumbs.length - 1,
    });

    return getFeatureCollection<GeoJSON.Point, any>([minFeature, maxFeature]);
  }, [breadCrumbs, maxSelected, minSelected, routeVehicleBreadCrumbs]);

  const getBreadCrumbsForTravelPathTransaction = () => breadCrumbs.slice(minSelected, maxSelected + 1);

  const validationErrors = useMemo(() => {
    const min120BreadCrumbsSelected = maxSelected - minSelected < 120;
    return {
      min120BreadCrumbsSelected,
    };
  }, [minSelected, maxSelected]);

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      minSelected === maxSelected ||
      breadCrumbs.length === 0 ||
      !minAndMaxCoordinatesGeoJSON ||
      !breadCrumbs ||
      isDoingTravelPathBuilderTracing ||
      validationErrors.min120BreadCrumbsSelected
    );
  }, [
    minSelected,
    maxSelected,
    breadCrumbs,
    minAndMaxCoordinatesGeoJSON,
    isDoingTravelPathBuilderTracing,
    validationErrors.min120BreadCrumbsSelected,
  ]);

  const setMinDebounced = debounce(setMinSelected, 500);
  const setMaxDebounced = debounce(setMaxSelected, 500);

  return {
    setMinSelected: setMinDebounced,
    minSelected,
    setMaxSelected: setMaxDebounced,
    maxSelected,
    minAndMaxCoordinatesGeoJSON,
    getBreadCrumbsForTravelPathTransaction,
    isSubmitButtonDisabled,
    parsedBreadCrumbsLength: breadCrumbs.length,
    validationErrors,
  };
};

export default useTravelPathBuilderService;
