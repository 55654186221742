import React from 'react';

import { ContainerListItem } from 'src/customers/interfaces/ContainerListItem';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import SelectContainersModal from './SelectContainersModal';

interface Props {
  closeModal: () => void;
  onCheckboxChange: (isSelected: boolean, id: number) => void;
  containerList: ContainerListItem[];
}

const SelectContainersModalResolver: React.FC<Props> = ({ closeModal, containerList, onCheckboxChange }) => {
  const loadDependencies = async () => {
    const dependencies: Promise<any>[] = [];
    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={SelectContainersModal}
      successProps={{ closeModal, containerList, onCheckboxChange }}
      loadingComponent={PageLoadingOverlay}
      onError={closeModal}
      resolve={loadDependencies}
    />
  );
};

export default SelectContainersModalResolver;
