import { camelCase, map, size } from 'lodash-es';
import translate from '../../core/services/translate';

export const transformCompliance = (compliance: any) => ({
  compliance: map(compliance, (complianceObj, key) => {
    let newComplianceObj = { ...complianceObj };

    if (complianceObj && complianceObj.documentType === 'SC') {
      newComplianceObj = { ...complianceObj, selfCertificationDocumentUrl: compliance.selfCertificationDocumentUrl };
    }
    if (complianceObj && complianceObj.documentType === 'COI') {
      newComplianceObj = { ...complianceObj, isDocumentUploaded: size(complianceObj.documents) ? true : false };
    }

    return {
      ...newComplianceObj,
      displayTitle: translate(`haulerProfile.complianceDocument.${camelCase(key)}`),
    };
  }),
});

export const transformCOIStatus = (coiResponse: any) => {
  return {
    ...coiResponse,
    coiComplianceStatus: map(coiResponse.coiComplianceStatus, (complianceStatusObj, key) => {
      return {
        displayTitle: translate(`haulerProfile.complianceStatus.${camelCase(key)}`),
        status: complianceStatusObj === '' ? null : complianceStatusObj,
      };
    }),
  };
};
