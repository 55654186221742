import React, { Fragment, useState } from 'react';

import { change, FieldArray, getFormValues, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';

import { Button, FormGroup, FormLabel, Grid, GridColumn, ModalSection, Text } from 'src/core/components/styled';
import { dateFormat, isRequired, isRequiredOption, timeFormat } from 'src/utils/services/validator';
import { Checkbox, DatePicker, Dropdown, Input, TypedField } from 'src/core/components';
import {
  CAMERAS_LIST,
  DURATION_LIST,
  DURATION_LIST_RUBICON,
  VIDEO_DEVICE_RUBICON_ALL_IN_ONE_TYPE_ID,
  VIDEO_DEVICE_SURFSIGHT_TYPE_ID,
} from 'src/routes/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import CheckboxGroup from 'src/core/components/CheckboxGroup';
import TimePickerField from 'src/core/components/TimePickerField';
import translate from 'src/core/services/translate';

const VIDEO_REQUEST_FORM = 'videoRequestForm';

const CameraConfigurationsFieldArray: React.FC<WrappedFieldArrayProps> = ({ fields }) => {
  return (
    <Fragment>
      {fields.map((fieldName: string, index: number) => (
        <div key={index}>
          <TypedField
            name={`${fieldName}.isActive`}
            component={Checkbox}
            props={{
              label: fields.get(index).displayName,
              margin: 'no xSmall xSmall no',
            }}
          />
        </div>
      ))}
    </Fragment>
  );
};

export interface CameraConfigurations {
  displayName: string;
  id: number;
  name?: string;
  vehicleCameraConfigurationTypeId: number;
  isActive?: boolean;
}

export interface VideoRequestFormValues {
  date?: Date | string;
  driverId?: number;
  routeId?: number;
  seconds?: number;
  time?: string;
  timeWindow?: string;
  userId?: string;
  vehicleCameraConfigurationTypeIds?: number[];
  cameras?: CameraConfigurations[];
  vehicleId: number;
  vehicleName?: string;
  vendorId?: number;
  videoDeviceTypeId?: number;
  deviceId?: string;
}

interface PropsWithoutReduxForm {
  change: any | InjectedFormProps['change'];
  closeModal: () => void;
  initialValues: VideoRequestFormValues;
}

type RouteReschedulerFormProps = PropsWithoutReduxForm &
  InjectedFormProps<VideoRequestFormValues, PropsWithoutReduxForm>;

const VideoRequestForm: React.FC<RouteReschedulerFormProps> = ({ closeModal, handleSubmit, initialValues, change }) => {
  const [timeWindow, setTimeWindow] = useState<string>('-');

  const formValues = useSelector(getFormValues(VIDEO_REQUEST_FORM)) as VideoRequestFormValues;

  const durationOptions = (
    initialValues.videoDeviceTypeId === VIDEO_DEVICE_RUBICON_ALL_IN_ONE_TYPE_ID ? DURATION_LIST_RUBICON : DURATION_LIST
  ).map(option => ({
    label: option.name,
    value: option.id,
  }));

  const camerasOptions = CAMERAS_LIST.map((option: any) => ({
    label: option.name,
    value: option.id,
  }));

  const setTime = (seconds: number = 0, time?: string) => {
    const startTime =
      initialValues.videoDeviceTypeId === VIDEO_DEVICE_RUBICON_ALL_IN_ONE_TYPE_ID
        ? moment(time, timeFormat).startOf('minute').format(timeFormat)
        : moment(time, timeFormat).format(timeFormat);

    const endTime =
      initialValues.videoDeviceTypeId === VIDEO_DEVICE_RUBICON_ALL_IN_ONE_TYPE_ID
        ? moment(time, timeFormat).add(seconds, 'seconds').startOf('minute').format(timeFormat)
        : moment(time, timeFormat).add(seconds, 'seconds').format(timeFormat);

    setTimeWindow(`${startTime} - ${endTime}`);

    let timeStampStart = `${formValues.date} ${startTime}`;
    let timeStampEnd = `${formValues.date} ${endTime}`;

    const isAfter = moment(timeStampStart).isAfter(timeStampEnd);
    if (isAfter) {
      const currentDateAndTime = `${formValues.date} ${time}`;
      const timeType = moment(currentDateAndTime).format('A');

      if (timeType === 'PM') {
        const endDate = moment(formValues.date, dateFormat).add(1, 'days').format(dateFormat);
        timeStampEnd = `${endDate} ${endTime}`;
      } else {
        const startDate = moment(formValues.date, dateFormat).subtract(1, 'days').format(dateFormat);
        timeStampStart = `${startDate} ${startTime}`;
      }
    }

    change('timeStampStart', timeStampStart);
    change('timeStampEnd', timeStampEnd);
  };

  const onDurationChange = (event: any | number) => {
    formValues.time && setTime(event, formValues.time);
  };

  const onTimeChange = (time: string) => {
    formValues.seconds && setTime(formValues.seconds, time);
  };

  return (
    <form onSubmit={handleSubmit}>
      <ModalSection minHeight="360px">
        <FormGroup margin="small no xxSmall no">
          <FormLabel noTransform>{translate('common.vehicleName')}</FormLabel>
        </FormGroup>
        <Text>{initialValues.vehicleName}</Text>

        <Grid margin="medium no no no">
          <GridColumn size="6/12" padding="no xSmall no no">
            <TypedField
              name="date"
              component={DatePicker}
              props={{
                raisedLabel: true,
                label: translate('common.date'),
              }}
              validate={[isRequired]}
            />
          </GridColumn>

          <GridColumn size="6/12" padding="no no no xSmall">
            <TimePickerField
              defaultValue={initialValues.time}
              formName={VIDEO_REQUEST_FORM}
              labelName={translate('common.time')}
              name="time"
              validate={[isRequired]}
              timeFormat={timeFormat}
              onTimePickerChange={onTimeChange}
            />
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn size="6/12" padding="no xSmall no no">
            <TypedField
              name="seconds"
              component={Dropdown}
              props={{
                label: translate('common.duration'),
                options: durationOptions,
                margin: 'xSmall no no no',
                menuPosition: 'fixed',
              }}
              validate={[isRequired]}
              onChange={onDurationChange}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no no no xSmall">
            <FormGroup margin="small no xxSmall no">
              <FormLabel noTransform>{translate('routes.videoRequest.timeWindow')}</FormLabel>
            </FormGroup>
            <Text>{timeWindow}</Text>

            <TypedField
              name="timeStampStart"
              component={Input}
              props={{
                margin: 'no',
                type: 'hidden',
              }}
            />
            <TypedField
              name="timeStampEnd"
              component={Input}
              props={{
                margin: 'no',
                type: 'hidden',
              }}
            />
          </GridColumn>
        </Grid>

        {initialValues?.videoDeviceTypeId === VIDEO_DEVICE_SURFSIGHT_TYPE_ID && (
          <TypedField
            name="vehicleCameraConfigurationTypeIds"
            component={CheckboxGroup}
            props={{
              items: camerasOptions,
              margin: 'xSmall no no no',
              padding: 'no no xSmall no',
              label: translate('routes.videoRequest.cameras.cameras'),
              withLabel: true,
              formGroupProps: {
                grid: true,
                gridColumns: 'repeat(3, 1fr)',
              },
            }}
            validate={[isRequiredOption]}
          />
        )
        }
        {
          
            initialValues?.videoDeviceTypeId === VIDEO_DEVICE_RUBICON_ALL_IN_ONE_TYPE_ID &&
            <>
              <GridColumn size="12/12" padding="small no">
                <Text margin={'small no'}>{translate('routes.videoRequest.cameras.cameras')}</Text>
              </GridColumn>
              <FieldArray name="cameras" component={CameraConfigurationsFieldArray} props={{}} />
            </>
          
        }

      </ModalSection>

      <Grid>
        <GridColumn size="12/12" align="center" padding="no no medium no">
          <Button id="cancel-video-request-button" color="secondary" onClick={closeModal} margin="no small no no">
            {translate('common.cancel')}
          </Button>
          <Button id="submit-video-request-button" color="primary" type="submit" margin="no no no small">
            {translate('common.submit')}
          </Button>
        </GridColumn>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = {
  change,
};

export default connect(
  undefined,
  mapDispatchToProps,
)(
  reduxForm<VideoRequestFormValues, PropsWithoutReduxForm>({
    form: VIDEO_REQUEST_FORM,
  })(VideoRequestForm),
);
