import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { date } from '../../../utils/services/formatter';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { loadLoadsDumped, loadRouteDetails, loadSnowSweeperRouteDetails, saveLoadDumped } from '../../ducks';
import { Modal } from '../../../core/components';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { uploadLoadsDumpedImage } from 'src/routes/ducks/loadsDumped';
import LoadDumpedEditorForm, { LoadDumpedFormValues } from '../forms/LoadDumpedEditorForm';
import translate from '../../../core/services/translate';

type Props = {
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  loadDumpedId?: number;
};

export default function LoadDumpedEditorModal({ onClose, routeId, loadDumpedId }: Props) {
  const dispatch = useDispatch();
  const isEditMode = loadDumpedId ? true : false;

  const [uploadedImageId, setUploadedImageId] = useState(null);
  const [uploadedImageBlob, setUploadedImageBlob] = useState(undefined);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);

  const {
    isSaving,
    isUploadingLoadsDumpedImage,
    loadsDumped: allLoadsDumped,
  } = useSelector((state: AppState) => state.routes.loadsDumped);
  const { routeSummary } = useSelector((state: AppState) => state.routes.routeSummary);
  const routeDate = useSelector((state: AppState) => state.routes.route.route?.routeDate);
  const vendorId = useSelector(currentVendorId);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary?.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const rawLoadDumped = allLoadsDumped.find(mt => mt.id === loadDumpedId);
  let loadDumpedInitialValues = {};
  const getLoadDumpedDateTime = (dumpedDate?: Date | string, dumpedTime?: Date | string) => {
    return {
      loadDumpedDate: date(dumpedDate),
      loadDumpedTime: moment(dumpedTime).format('HH:mm'),
    };
  };

  if (rawLoadDumped) {
    loadDumpedInitialValues = {
      ...rawLoadDumped,
      ...getLoadDumpedDateTime(rawLoadDumped.pickUpDateTimeLocal, rawLoadDumped.pickUpDateTimeLocal),
    };
  } else {
    loadDumpedInitialValues = {
      ...getLoadDumpedDateTime(routeDate),
    };
  }

  const handleSubmitLoadDumped = async (formData: LoadDumpedFormValues) => {
    const { loadDumpedDate, loadDumpedTime } = formData;
    const date = moment(loadDumpedDate).format('YYYY-MM-DD');
    const loadDumpedDateTime = moment(date + loadDumpedTime, 'YYYY-MM-DDLT');

    const currentFormData = {
      ...formData,
      pickUpDateTimeLocal: moment(loadDumpedDateTime).format('YYYY-MM-DD HH:mm:ss'),
    };

    delete currentFormData.locationNameAddress;
    const loadDumped = loadDumpedId
      ? {
          ...rawLoadDumped,
          ...currentFormData,
        }
      : {
          vehicleId: routeSummary?.vehicleId,
          ...currentFormData,
        };

    if (uploadedImageId && uploadedImageUrl) {
      loadDumped.image = {
        blobId: uploadedImageBlob,
        imageId: uploadedImageId,
        url: uploadedImageUrl,
      };
    }

    saveLoadDumped(
      routeId,
      loadDumped,
      vendorId,
    )(dispatch)
      .then(() => {
        loadLoadsDumped(vendorId, routeId)(dispatch);

        const noLoading = true;
        isSnowOrSweeperRoute
          ? loadSnowSweeperRouteDetails(
              vendorId,
              routeId,
              segmentsFilterStartDate,
              segmentsFilterEndDate,
              noLoading,
            )(dispatch)
          : loadRouteDetails(vendorId, routeId, noLoading)(dispatch);

        createSuccessNotification(translate('routes.alertMessages.loadDumpedSaved'));
        onClose(true);
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.loadDumpedSaveError'));
      });
  };

  const onClearFileName = () => {
    setFileName(undefined);
    setUploadedImageBlob(undefined);
    setUploadedImageId(null);
    setUploadedImageUrl(undefined);
  };

  const onImageUpload = (files: FileList | null) => {
    files &&
      !!files.length &&
      uploadLoadsDumpedImage(
        files[0],
        routeId,
      )(dispatch)
        .then(async (response: any[]) => {
          setFileName(response[0].imageFileName);
          setUploadedImageBlob(response[0].imageBlobId);
          setUploadedImageId(response[0].id);
          setUploadedImageUrl(response[0].imageUrl);

          createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
        })
        .catch(() => {
          createErrorNotification(translate('common.alertMessages.invalidImageFormat'));
        });
  };

  return (
    <Modal
      size="large"
      isLoading={isSaving || isUploadingLoadsDumpedImage}
      onClose={onClose}
      title={translate(`common.loadsDumped.${isEditMode ? 'edit' : 'add'}LoadsDumped`)}
    >
      <LoadDumpedEditorForm
        initialValues={loadDumpedInitialValues}
        onClearfileName={onClearFileName}
        onClose={onClose}
        onImageUpload={onImageUpload}
        onSubmit={handleSubmitLoadDumped}
        selectedFileName={fileName}
      />
    </Modal>
  );
}
