import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadEquipments as doLoadEquipments } from '../services/equipments';

// Actions
const START_LOAD = 'haulerProfile/equipments/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/equipments/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/equipments/FAIL_LOAD';

// Initial state
const initialState = {
  isLoading: false,
  equipments: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipments: action.equipments,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (equipments: any) => ({
  type: COMPLETE_LOAD,
  equipments,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadEquipments = (haulerId: string, divisionId?: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadEquipmentsPromise = doLoadEquipments(haulerId, divisionId);
  loadEquipmentsPromise.then(equipments => dispatch(completeLoad(equipments))).catch(() => dispatch(failLoad()));
  return loadEquipmentsPromise;
};
