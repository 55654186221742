import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { UnconnectedSwitch } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  insightType: TechnicalType;
  isActive: boolean;
  toggleInsightConfiguration: (id: number, isChecked: any | boolean) => void;
}

const InsightConfigurationTableRow: React.FC<Props> = ({ insightType, isActive, toggleInsightConfiguration }) => {
  return (
    <TableRow>
      <TableCell width="85%">
        {translate(createTranslationKey(insightType.technicalName, 'insights.reportTypes'))}
      </TableCell>
      <TableCell width="15%" align="right">
        <UnconnectedSwitch
          checked={isActive}
          id={`insights-settings-${insightType.technicalName}-switch`}
          onChange={(isChecked: boolean) => toggleInsightConfiguration(insightType.id, isChecked)}
        />
      </TableCell>
    </TableRow>
  );
};

export default InsightConfigurationTableRow;
