import React, { ChangeEvent, PureComponent } from 'react';

import { map, size } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { MultiSelect, TypedField } from '../../core/components';
import { FieldProps } from '../../core/components/FieldProps';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import translate from '../../core/services/translate';
import { PICKUP_STATUSES } from '../constants';

const formatText = (selectedOptions: string[], checkAll: boolean) => {
  if (checkAll) {
    return translate('common.allStatuses');
  }

  return size(selectedOptions) === 1
    ? PICKUP_STATUSES[selectedOptions[0]].name
    : translate('common.xStatusesSelected', { selected: size(selectedOptions) });
};

interface Props extends FieldProps {
  id?: string,
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
}

class PickupStatusesMultiSelect extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      multiSelectProps,
    } = this.props;

    const pickupStatusesOptions = map(PICKUP_STATUSES, pickupStatus => ({
      label: pickupStatus.name,
      value: pickupStatus.id,
    }));
    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.pickupStatus') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.pickupStatus') : undefined),
          options: pickupStatusesOptions,
          formatText,
          ...multiSelectProps,
        }}
      />
    );
  }
}

export default PickupStatusesMultiSelect;
