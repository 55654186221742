import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { omit } from 'lodash-es';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { Grid, GridColumn } from '../../../core/components/styled';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadServiceChangeRequests } from '../../ducks';
import { Modal } from '../../../core/components';
import { RequestServiceChangesForm } from '../forms';
import translate from '../../../core/services/translate';
import { updateServiceChangeRequest } from '../../ducks';
import { YES } from '../../../fleet/constants/status';
import { useIsMobileView } from 'src/utils/hooks';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';
interface RouteParams {
  token: string;
}

interface ComponentProps {
  caseId: number;
  equipmentSubType: string;
  itemId: number;
  location: any;
  offer: any;
  userId: string;
  vendorId: number;
  onCancel(pristine: boolean): void;
}

interface ReduxProps {
  response: any;
  requestedChanges: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadServiceChangeRequests: DuckFunction<typeof loadServiceChangeRequests>;
  updateServiceChangeRequest: DuckFunction<typeof updateServiceChangeRequest>;
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const RequestServiceChangesModal: React.FC<Props> = props => {
  const { onCancel } = props;

  const isMobile = useIsMobileView();

  const onSubmit = React.useCallback(async () => {
    const {
      itemId,
      loadDispatchOpportunitiesCount,
      loadServiceChangeRequests,
      location: { search },
      match: {
        params: { token },
      },
      onCancel,
      requestedChanges,
      userId,
      vendorId,
      updateServiceChangeRequest,
      closeMobilePageDetailOverlay,
    } = props;

    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    const requestOpportunityObj: any = {};
    requestOpportunityObj.itemId = itemId;
    requestOpportunityObj.Response = 'RequestChanges';
    requestOpportunityObj.RequestedChanges = omit(requestedChanges, ['displayFees']);
    requestOpportunityObj.RequestedChanges.deliveryDate = moment(requestedChanges.deliveryDate).format('YYYY-MM-DD');
    requestOpportunityObj.RequestedChanges.swapRequired = false;
    if (requestedChanges.schedule) {
      requestOpportunityObj.RequestedChanges.schedule = Object.assign(
        {},
        ...requestOpportunityObj.RequestedChanges.schedule.map((d: any) => ({ [d[0]]: true })),
      );
    }

    requestOpportunityObj.RequestedChanges.serviceRecommendation = requestedChanges.serviceRecommendation
      ? [requestedChanges.serviceRecommendation]
      : [];

    const promise = updateServiceChangeRequest(requestOpportunityObj, userId, token, vendorId);

    if (!promise) {
      return;
    }

    await promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        loadServiceChangeRequests(userId, token, vendorId, startDate, endDate, isActionRequired);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);

  return (
    <Modal title={translate('opportunity.requestChanges')} size="small">
      <Grid centered>
        <GridColumn size={isMobile ? '12/12' : '10/12'}>
          <RequestServiceChangesForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('requestServiceChanges');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `changeRequests.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `changeRequests.settings${ownProps.caseId}.requestedChanges`),
});

const mapDispatchToProps = {
  loadDispatchOpportunitiesCount,
  loadServiceChangeRequests,
  updateServiceChangeRequest,
  closeMobilePageDetailOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestServiceChangesModal);
