import { map, orderBy } from 'lodash-es';

import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { VehicleType } from '../interfaces/VehicleTypes';

export const transformLoadVehicleTypes = (vehicleTypes: VehicleType[]) =>
  map(vehicleTypes, vehicleType => ({
    ...vehicleType,
    name: translate(createTranslationKey(vehicleType.technicalName, 'vehicles.vehicleTypes')),
    subTypes: map(vehicleType.subTypes, vehicleSubType => ({
      ...vehicleSubType,
      name: translate(createTranslationKey(vehicleSubType.technicalName, 'vehicles.vehicleTypes')),
    })),
  }));

export const transformLoadVehicleTypesForVendor = (vehicleTypesForVendor: any[]) => {
  const vehicleTypes = map(vehicleTypesForVendor, vehicleType => ({
    ...vehicleType,
    name: translate(createTranslationKey(vehicleType.technicalName, 'vehicles.vehicleTypes')),
  }));

  return orderBy(vehicleTypes, ['name'], ['asc']);
};
