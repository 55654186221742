import { size } from 'lodash-es';
import { getRoutePlannerPersistentFilters } from './routePlannerFilterStorage';

export const getRoutePlannerFilters = (search: string) => {
  const routePlannerFiltersFromUrl = new URLSearchParams(search);
  const entries = routePlannerFiltersFromUrl.entries();
  const { date, ...rest } = Object.fromEntries(entries);
  const valuesFromUrl = rest;
  const routePlannerFiltersFromStorage = getRoutePlannerPersistentFilters();

  return Object.fromEntries(
    Object.entries({ ...routePlannerFiltersFromStorage, ...valuesFromUrl }).map(([k, v], i) => {
      return [
        k,
        v !== undefined && v !== null ? (size(v as string) ? (v as string).split(',').map(Number) : []) : null,
      ];
    }),
  );
};
