import { InfoIcon } from 'src/routes/components/styled';
import { Text } from 'src/core/components/styled';
import translate from '../../core/services/translate';

export const getFutureAccountStatusCanceledMessagge = () => {
  return (
    <Text block margin="small no no no" fontStyle="italic">
      <InfoIcon />
      <Text margin="no no no xxSmall">{translate('customers.futureAccountStatusCanceled')}</Text>
    </Text>
  );
};
