import { some, flatten, uniq } from 'lodash-es';
import { Source, Layer } from 'react-map-gl';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FeaturePoint } from '../../../../../common/components/map/FeaturePoint';
import { getFeatureCollection } from '../../../../../common/components/map/util';
import { MarkerSourceComponentProps } from '../../../../../common/components/map/sources/MarkerSourceComponentProps';
import {
  RouteTemplateBuilderService,
  RouteTemplateBuilderServiceFeatureProperties,
} from '../../../../interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import {
  ASSIGNED_FILTER,
  UNASSIGNED_FILTER,
  SOURCE_UNIQUE_KEY,
  DRAFT,
  NEW,
  RTB_TEMPLATE_STATUSES_MAP,
  UNASSIGNED_COLOR,
  UNASSIGNED_COLOR_SATELLITE,
} from '../../../../constants/routeTemplateBuilder';
import { AppState } from 'src/store';
import useSelectedDraftStops from 'src/routes/hooks/useSelectedDraftStops';

interface RouteTemplateBuilderServiceMarkerSourceProps
  extends Omit<MarkerSourceComponentProps<RouteTemplateBuilderService>, 'markers' | 'onAddInteractiveLayers'> {
  services: RouteTemplateBuilderService[];
  selectedPoints: number[];
  selectedRouteTemplateIds: string[];
  isSatelliteView?: boolean;
}

type Marker = RouteTemplateBuilderService & {
  id: number;
  latitude: number;
  longitude: number;
};

const getMarkerData = (markers: Marker[], currentDaysOfService: number[], serviceTypesFilter: number[]) => {
  const featurePoints: GeoJSON.Feature[] = markers.map(marker => {
    const featurePoint = new FeaturePoint(marker.latitude, marker.longitude);
    const assigned = some(
      (marker.daysOfService || []).filter(
        d =>
          currentDaysOfService.indexOf(d.id) !== -1 &&
          (marker.serviceTypeId ? serviceTypesFilter.includes(marker?.serviceTypeId) : ''),
      ),
      d => !!d.routeTemplateId || !!d.draftRouteTemplateId,
    );
    const unassigned = some(
      (marker.daysOfService || []).filter(
        d =>
          currentDaysOfService.indexOf(d.id) !== -1 &&
          (marker.serviceTypeId ? serviceTypesFilter.includes(marker?.serviceTypeId) : ''),
      ),
      d => !d.routeTemplateId && !d.draftRouteTemplateId,
    );

    const properties: RouteTemplateBuilderServiceFeatureProperties = {
      id: marker.id,
      materialTypeId: marker.materialTypeId,
      daysOfService: marker.daysOfService?.map(d => d.id) || [],
      routeTemplateIds: flatten(
        (marker.daysOfService || [])
          .filter(d => !!d.routeTemplateId || !!d.draftRouteTemplateId)
          .map(d => [d.routeTemplateId, d.draftRouteTemplateId]),
      ).filter(id => !!id) as any as number[],
      routeNames: flatten(
        (marker.daysOfService || []).filter(d => d.routeName).map(d => [d.routeName]),
      ) as any as string[],
      assigned,
      unassigned,
      lat: marker.latitude,
      lng: marker.longitude,
    };

    featurePoint.id = marker.id;
    featurePoint.properties = properties;

    return featurePoint;
  });

  return getFeatureCollection(featurePoints);
};

export const RouteTemplateBuilderServiceMarkerSource: React.FC<RouteTemplateBuilderServiceMarkerSourceProps> = ({
  services,
  selectedPoints,
  selectedRouteTemplateIds,
  map,
  isSatelliteView = false,
}) => {
  const selectedDraftStops = useSelectedDraftStops();
  const routeColorsMap = useSelector((s: AppState) => s.routes.routeTemplateBuilder.routeColorsMap);
  const materialTypesFilter = useSelector((s: AppState) => s.routes.routeTemplateBuilder.mapFilters.materialTypeIds);
  const daysOfServiceFilter = useSelector((s: AppState) => s.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds);
  const stopTypeFilter = useSelector((s: AppState) => s.routes.routeTemplateBuilder.mapFilters.stopFilterType);
  const serviceTypesFilter = useSelector((s: AppState) => s.routes.routeTemplateBuilder.mapFilters.serviceTypeIds);
  const routeTemplateIds = useSelector((s: AppState) => s.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);

  const data = useMemo(
    () => getMarkerData(services, daysOfServiceFilter, serviceTypesFilter),
    [services, daysOfServiceFilter, serviceTypesFilter],
  );

  const mapStateHandler = React.useCallback(() => {
    if (!map.getSource(SOURCE_UNIQUE_KEY)) {
      return;
    }

    const unassignedColor = isSatelliteView ? UNASSIGNED_COLOR_SATELLITE : UNASSIGNED_COLOR;

    services.forEach(service => {
      const publishedStatus = some(service.daysOfService, d => !!d.routeTemplateId) ? DRAFT : NEW;
      const routeDraftColor = RTB_TEMPLATE_STATUSES_MAP[publishedStatus].color;

      const potentialStopColors = uniq(
        flatten(
          (service.daysOfService || [])
            .filter(d => daysOfServiceFilter.indexOf(d.id) !== -1)
            .map(d => {
              const potentialTemplateIds: (string | null)[] = [];
              const { routeName } = d;

              if (!!routeName && routeTemplateIds.indexOf(routeName) !== -1) {
                potentialTemplateIds.push(routeName);
              }

              if (!routeName) {
                potentialTemplateIds.push(null);
              }

              return potentialTemplateIds;
            }),
        ),
      )
        .map(templateId => {
          if (!!templateId && templateId in routeColorsMap) {
            return stopTypeFilter !== 'unassigned' ? routeColorsMap[templateId].color : null;
          }

          return stopTypeFilter !== 'assigned' ? unassignedColor : null;
        })
        .filter(color => !!color) as string[];

      const mostPotentialColor = potentialStopColors[0];
      const isAssignedByMostPotentialColor =
        mostPotentialColor !== UNASSIGNED_COLOR && mostPotentialColor !== UNASSIGNED_COLOR_SATELLITE;

      map.setFeatureState(
        {
          id: service.id,
          source: SOURCE_UNIQUE_KEY,
        },
        {
          color: mostPotentialColor,
          draftColor: routeDraftColor,
          borderOpacity: isSatelliteView && !isAssignedByMostPotentialColor ? 1 : 0,
        },
      );
    });
  }, [map, services, routeColorsMap, daysOfServiceFilter, routeTemplateIds, isSatelliteView, stopTypeFilter]);

  React.useEffect(() => {
    if (!map || !services.length) {
      return;
    }

    mapStateHandler();
    map.on('styledata', mapStateHandler);
  }, [map, services, mapStateHandler]);

  return (
    <Source type="geojson" id={SOURCE_UNIQUE_KEY} data={data}>
      {!selectedDraftStops && (
        <Layer
          id="mainFilterStops"
          source={SOURCE_UNIQUE_KEY}
          type="circle"
          filter={[
            'all',
            ['!', ['in', ['get', 'id'], ['literal', selectedPoints]]],
            ['in', ['get', 'materialTypeId'], ['literal', materialTypesFilter]],
            ['any', ...daysOfServiceFilter.map(id => ['in', id, ['get', 'daysOfService']])],
            stopTypeFilter !== UNASSIGNED_FILTER
              ? [
                  'case',
                  ['==', ['get', UNASSIGNED_FILTER], true],
                  true,
                  ['any', ...selectedRouteTemplateIds.map(id => ['in', id, ['get', 'routeNames']])],
                ]
              : true,
            stopTypeFilter === UNASSIGNED_FILTER ? ['==', ['get', UNASSIGNED_FILTER], true] : true,
            stopTypeFilter === ASSIGNED_FILTER ? ['==', ['get', ASSIGNED_FILTER], true] : true,
          ]}
          paint={{
            'circle-color': ['feature-state', 'color'],
            'circle-opacity': 1,
            'circle-radius': 7,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#000000',
            'circle-stroke-opacity': ['feature-state', 'borderOpacity'],
          }}
        />
      )}

      {!!selectedDraftStops && (
        <Layer
          id="selectedDraftStops"
          source={SOURCE_UNIQUE_KEY}
          filter={['!', ['in', ['get', 'id'], ['literal', selectedPoints]]]}
          type="circle"
          paint={{
            'circle-color': ['feature-state', 'draftColor'],
            'circle-opacity': 1,
            'circle-radius': 7,
          }}
        />
      )}

      <Layer
        id="selectedStops"
        source={SOURCE_UNIQUE_KEY}
        filter={['in', ['get', 'id'], ['literal', selectedPoints]]}
        type="circle"
        paint={{
          'circle-color': '#009688',
          'circle-opacity': 1,
          'circle-radius': 7,
        }}
      />
    </Source>
  );
};
