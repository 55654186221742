import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { difference, uniq, uniqBy, upperFirst, xor } from 'lodash-es';
import { camelize } from 'humps';

import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';
import { SmartFilters } from '../../../common/interfaces/SmartFilters';
import { PanelSection, Text, PanelSectionGroup, Grid, GridColumn, Button } from '../../../core/components/styled';
import { useSmartFilters } from '../../../common/hooks/smartFilters';
import {
  DetailsContainer,
  StatusFiltersContainer,
  StatusFilterButtonWithLeeway,
} from '../../../fleet/components/styled/RubiconDispatches';
import { ButtonWrapperWithPopover } from '../../../common/components/styled';
import {
  SimpleFilterWrapper,
  SimpleFilterButton,
  RouteTemplateBuilderFiltersReadOnlyWrapper,
  RouteTemplateBuilderFiltersContainer,
  RouteTemplateBuilderEmptyFiltersNotice,
  /**
   * TODO
   * RVP 71 • Temporarily Hidden
   */
  // WorkSpaceLayouts,
  // WorkSpaceLayoutButton,
} from '../styled/RouteTemplateBuilderFilters';
import {
  TypedField,
  Dropdown,
  MultiSelect,
  /**
   * TODO
   * RVP 71 • Temporarily Hidden
   */
  // Icon
} from '../../../core/components';
import translate from '../../../core/services/translate';
import { WEEKDAYS, WEEKDAYS_BY_ID } from '../../../core/constants/weekdays';
import {
  loadRouteTemplateBuilderFilters,
  setFilterMaterial,
  loadRouteTemplateBuilderServices,
  setStopFilterType,
  setFilterDaysOfService,
  setFilterRouteTemplates,
  setFilterVehicle,
  setFilterServiceTypes,
  setFilterEquipmentTypes,
  setFilterEquipmentSizes,
  setFilterWasteMaterialType,
  setFilterGroupIds,
  setFilterServiceZone,
  restoreLastFilters,
  MapFilters,
  /**
   * TODO
   * RVP 71 • Temporarily Hidden
   */
  // setLayout,
} from '../../ducks/routeTemplateBuilder';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import {
  ALL_STOPS_FILTER,
  ASSIGNED_FILTER,
  UNASSIGNED_FILTER,
  RTB_TEMPLATE_STATUSES_MAP,
  PUBLISHED,
  NEW,
  ROUTE_TEMPLATE_BUILDER_WORK_SESSION_FILTERS_FORM,
  /**
   * TODO
   * RVP 71 • Temporarily Hidden
   */
  // WorkSessionViews,
} from '../../constants/routeTemplateBuilder';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { RouteTemplateBuilderMapInfoOverlay, RouteTemplateStatus } from '../styled/RouteTemplateBuilderMap';
import useSelectedDaysOfService from 'src/routes/hooks/useSelectedDaysOfService';
import useWorkSessionRouteTemplatesFilter from 'src/routes/hooks/useWorkSessionRouteTemplatesFilter';
/**
 * TODO
 * RVP 71 • Temporarily Hidden
 */
// import { TooltipButtonTooltip } from 'src/core/components/styled/TooltipButtonStyles';
import { useSelector } from 'src/core/hooks/useSelector';
import { MultiSelectOption } from 'src/core/components/MultiSelect';
import { SNOW_PLOW, STREET_SWEEPER } from 'src/fleet/constants';
import { SERVICE_TYPES_LIST } from 'src/common/constants/serviceTypes';
import WasteTypeDropdown from 'src/common/components/WasteTypeDropdown';
import GroupsMultiSelect from '../GroupsMultiSelect';
import ServiceZoneDropdown from '../ServiceZoneDropdown';

interface PropsWithoutReduxForm {
  currentFilters: MapFilters;
  setCurrentFilters: ({ ...formData }: MapFilters | any) => void;
  needsRefresh: boolean;
  setNeedsRefresh: (val: boolean) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const RouteTemplateBuilderFilters: React.FC<Props> = ({
  change,
  currentFilters,
  setCurrentFilters,
  needsRefresh,
  setNeedsRefresh,
}) => {
  const { availableDayOfServiceIds, selectedDaysOfServiceBundle } = useSelectedDaysOfService();

  const services = useSelector(s => s.routes.routeTemplateBuilder.services);

  useWorkSessionRouteTemplatesFilter(selectedDaysOfServiceBundle?.routeTemplates.map(rt => rt.name));

  const dispatch = useDispatch();
  const [shadowVisible, setShadowVisible] = React.useState<boolean>(true);
  const [didSearch, setDidSearch] = React.useState<boolean>(false);

  const vendorId = useSelector(currentVendorId);
  const filtersLoading = useSelector(state => state.routes.routeTemplateBuilder.filtersLoading);
  const isChangeLogLoading = useSelector(state => state.routes.routeTemplateBuilder.changeLogLoading);

  const vehicleTypesForRTB = useSelector(state =>
    (state.fleet.vehicleTypesForVendor.vehicleTypesForVendor || []).filter(
      type => type.technicalName !== SNOW_PLOW && type.technicalName !== STREET_SWEEPER,
    ),
  );
  const selectedVehicleTypeId = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.vehicleTypeId);
  const selectedWasteMaterialTypeId = useSelector(
    state => state.routes.routeTemplateBuilder.mapFilters.wasteMaterialTypeId,
  );
  const selectedGroupIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.groupIds);
  const selectedServiceZoneId = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.serviceZoneId);
  const selectedMaterialTypeIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.materialTypeIds);
  const stopFilterType = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.stopFilterType);
  const materialTypes = useSelector(state => state.routes.routeTemplateBuilder.filters?.wasteMaterialTypes || []);
  const bundlesLoadedByMaterialTypeId = useSelector(
    state => state.routes.routeTemplateBuilder.bundlesLoadedByMaterialTypeId,
  );
  const servicesLoadingForMaterialTypeIds = useSelector(
    state => state.routes.routeTemplateBuilder.servicesLoadingForMaterialTypeIds,
  );
  const selectedDayOfServiceIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds);
  const workSessionId = useSelector(state => state.routes.routeTemplateBuilder.workSession?.id);
  const selectedDraft = useSelector(state => state.routes.routeTemplateBuilder.selectedDraft);

  const selectedServiceTypeIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.serviceTypeIds);
  const serviceTypeIds = uniq(
    selectedDaysOfServiceBundle?.routeTemplates.map(route => route.serviceContracts.map(serv => serv.s)).flat(),
  );

  const equipmentTypes = useSelector(s => s.common.equipmentTypes.equipmentTypes);
  const equipmentSizes = useSelector(s => s.common.equipmentSizes.equipmentSizes);
  const selectedEquipmentTypes = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.equipmentTypeIds);
  const lastMapFilters = useSelector(s => s.routes.routeTemplateBuilder.lastMapFilters);
  const isApplyingLastFilters = useSelector(s => s.routes.routeTemplateBuilder.isApplyingLastFilters);

  /**
   * TODO
   * RVP 71 • Temporarily Hidden
   */
  // const layout = useSelector(state => state.routes.routeTemplateBuilder.layout);

  const smartFilters = React.useMemo(
    (): SmartFilters<any, number> =>
      materialTypes.map((type, index) => ({
        id: type.id,
        title: translate(createTranslationKey(type.name, 'common.wasteTypes')),
        dynamicContent: () => (
          <>
            <Text size="small" margin="xxSmall no no" weight="medium">
              {type.routeTemplatesCount} {translate('routeTemplateBuilder.routes')} • {type.stopsCount}{' '}
              {translate('routes.stops')}
            </Text>

            <Text size="xSmall" margin="xxSmall no no" weight="light" color="grayDarker">
              {type.assignedStopsCount} {translate('common.assigned')} • {type.unassignedStopsCount}{' '}
              {translate('routeTemplateBuilder.unassigned')}
            </Text>
          </>
        ),
      })),
    [materialTypes],
  );

  const { filters } = useSmartFilters(smartFilters, []);

  const onFiltersScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    const { scrollWidth, scrollLeft, offsetWidth } = event.currentTarget;

    setShadowVisible(scrollWidth >= scrollLeft + offsetWidth + 10);
  };

  const searchFilters = () => {
    if (
      !workSessionId ||
      !selectedVehicleTypeId ||
      (selectedVehicleTypeId &&
        !selectedWasteMaterialTypeId &&
        !selectedGroupIds.length &&
        selectedServiceZoneId === null)
    ) {
      return;
    }
    setCurrentFilters({
      ...currentFilters,
      vehicleTypeId: selectedVehicleTypeId,
      wasteMaterialTypeId: selectedWasteMaterialTypeId,
      serviceZoneId: selectedServiceZoneId,
      groupIds: selectedGroupIds,
    });

    loadRouteTemplateBuilderFilters(
      vendorId,
      workSessionId,
      selectedVehicleTypeId,
      selectedServiceZoneId,
      selectedWasteMaterialTypeId,
      selectedGroupIds,
    )(dispatch);

    if (selectedWasteMaterialTypeId) {
      loadRouteTemplateBuilderServices(
        vendorId,
        workSessionId,
        selectedVehicleTypeId,
        selectedWasteMaterialTypeId,
        selectedServiceZoneId,
        selectedGroupIds,
      )(dispatch);
    }
    setDidSearch(true);
    setNeedsRefresh(false);
  };

  React.useEffect(() => {
    const [firstAvailableDayId] = availableDayOfServiceIds;
    const currentDayIdIsAvailable = availableDayOfServiceIds.find(
      dayId => selectedDayOfServiceIds.indexOf(dayId) !== -1,
    );

    if (currentDayIdIsAvailable || !firstAvailableDayId) {
      return;
    }

    dispatch(setFilterDaysOfService([firstAvailableDayId]));
    change('dayOfServiceIds', [firstAvailableDayId]);
  }, [availableDayOfServiceIds, selectedDayOfServiceIds, change, dispatch]);

  React.useEffect(() => {
    if (!workSessionId) {
      return;
    }

    const materialTypesThatNeedLoading = difference(
      selectedMaterialTypeIds,
      bundlesLoadedByMaterialTypeId,
      servicesLoadingForMaterialTypeIds,
    );

    const isToLoadExtraData = true;

    materialTypesThatNeedLoading
      .filter(materialTypeId => typeof materialTypeId === 'number')
      .forEach(materialTypeId =>
        loadRouteTemplateBuilderServices(
          vendorId,
          workSessionId,
          selectedVehicleTypeId,
          materialTypeId,
          selectedServiceZoneId,
          selectedGroupIds,
          isToLoadExtraData,
        )(dispatch),
      );
  }, [
    vendorId,
    workSessionId,
    selectedVehicleTypeId,
    selectedMaterialTypeIds,
    dispatch,
    bundlesLoadedByMaterialTypeId,
    servicesLoadingForMaterialTypeIds,
    selectedServiceTypeIds,
    selectedGroupIds,
    selectedServiceZoneId,
    didSearch,
    selectedWasteMaterialTypeId,
    filters,
  ]);

  const routesOptions = useMemo(() => {
    let lastDayOfServiceId = -1;
    const options: MultiSelectOption[] = [];
    (selectedDaysOfServiceBundle?.routeTemplatesWithDayOfService || [])
      .sort((a, b) => a.dayOfServiceId - b.dayOfServiceId)
      .forEach(template => {
        if (template.dayOfServiceId > lastDayOfServiceId) {
          options.push({
            label: WEEKDAYS_BY_ID[template.dayOfServiceId].name,
            value: 'SEPARATOR',
            unselectable: true,
            renderCustomOption: () => <div>- {WEEKDAYS_BY_ID[template.dayOfServiceId].name} -</div>,
          });
          lastDayOfServiceId = template.dayOfServiceId;
        }
        options.push({
          label: template.name,
          value: template.name,
          renderCustomOption: () => (
            <>
              {template.name}

              {!template.isRouteTemplateEnabled && template.statusId !== NEW && (
                <RouteTemplateStatus neutral margin="xxSmall no xxSmall xxSmall">
                  {translate('common.inactive')}
                </RouteTemplateStatus>
              )}

              {template.statusId !== PUBLISHED && (
                <RouteTemplateStatus statusId={template.statusId} margin="xxSmall no xxSmall xxSmall">
                  {RTB_TEMPLATE_STATUSES_MAP[template.statusId].name}
                </RouteTemplateStatus>
              )}
            </>
          ),
        });
      });

    return options;
  }, [selectedDaysOfServiceBundle?.routeTemplatesWithDayOfService]);

  const serviceOptions = useMemo(() => {
    const options: MultiSelectOption[] = [];
    serviceTypeIds.map(el =>
      options.push({
        label: SERVICE_TYPES_LIST.find(st => st.id === el)?.name || translate('common.serviceTypes.unknown'),
        value: el,
      }),
    );

    return options;
  }, [serviceTypeIds]);

  const showSecondaryLevelFilters = !!selectedVehicleTypeId && !!filters.length;

  const equipmentTypeOptions = useMemo(() => {
    const options: MultiSelectOption[] = [];
    let lastMaterialTypeId = 0;
    uniqBy(services, s => `${s.materialTypeId} - ${s.equipmentTypeId}`)
      .sort((a, b) => a.materialTypeId - b.materialTypeId)
      .filter(s => !s.serviceTypeId || selectedServiceTypeIds.includes(s.serviceTypeId))
      .forEach(s => {
        const equipmentType = equipmentTypes.find(et => et.id === s.equipmentTypeId);

        if (equipmentType) {
          if (s.materialTypeId !== lastMaterialTypeId) {
            const materialTypeName = materialTypes.find(m => m.id === s.materialTypeId)?.name || '';
            options.push({
              label: materialTypeName,
              value: 'SEPARATOR',
              unselectable: true,
              renderCustomOption: () => <div>- {materialTypeName} -</div>,
            });
            lastMaterialTypeId = s.materialTypeId;
          }
          options.push({
            label: equipmentType.name,
            value: equipmentType.id,
          });
        }
      });

    const equipmentTypeIds = options.filter(o => !o.unselectable).map(o => o.value);

    if (equipmentTypeIds.length && showSecondaryLevelFilters && !isApplyingLastFilters) {
      dispatch(setFilterEquipmentTypes(equipmentTypeIds));
    }

    return options;
  }, [
    dispatch,
    equipmentTypes,
    isApplyingLastFilters,
    materialTypes,
    selectedServiceTypeIds,
    services,
    showSecondaryLevelFilters,
  ]);

  const containerSizeOptions = useMemo(() => {
    const options: MultiSelectOption[] = [];
    let lastMaterialTypeId = 0;
    uniqBy(services, s => `${s.materialTypeId} - ${s.equipmentSizeId}`)
      .sort((a, b) => a.materialTypeId - b.materialTypeId)
      .filter(
        s =>
          (!s.serviceTypeId || selectedServiceTypeIds.includes(s.serviceTypeId)) &&
          selectedEquipmentTypes.includes(s.equipmentTypeId),
      )
      .forEach(s => {
        const equipmentSize = equipmentSizes.find(et => et.id === s.equipmentSizeId);

        if (equipmentSize) {
          if (s.materialTypeId !== lastMaterialTypeId) {
            const materialTypeName = materialTypes.find(m => m.id === s.materialTypeId)?.name || '';
            options.push({
              label: materialTypeName,
              value: 'SEPARATOR',
              unselectable: true,
              renderCustomOption: () => <div>- {materialTypeName} -</div>,
            });
            lastMaterialTypeId = s.materialTypeId;
          }

          const size = getEquipmentSize(equipmentSize.technicalName, equipmentSize.name);
          const sizeTranslated = translate(
            `common.equipmentSizes.x${upperFirst(
              camelize(equipmentSize.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
            )}`,
            {
              size: size ? getEquipmentSize(equipmentSize.technicalName, equipmentSize.name) : 0,
            },
          );

          options.push({
            label: sizeTranslated,
            value: equipmentSize.id,
          });
        }
      });

    const containerSizeOptionIds = options.filter(o => !o.unselectable).map(o => o.value);
    if (containerSizeOptionIds.length && showSecondaryLevelFilters && !isApplyingLastFilters) {
      dispatch(setFilterEquipmentSizes(containerSizeOptionIds));
    }

    return options;
  }, [
    dispatch,
    equipmentSizes,
    isApplyingLastFilters,
    materialTypes,
    selectedEquipmentTypes,
    selectedServiceTypeIds,
    services,
    showSecondaryLevelFilters,
  ]);

  useEffect(() => {
    const needsRefresh =
      didSearch &&
      (selectedVehicleTypeId !== currentFilters.vehicleTypeId ||
        selectedWasteMaterialTypeId !== currentFilters.wasteMaterialTypeId ||
        selectedServiceZoneId !== currentFilters.serviceZoneId ||
        !!xor(selectedGroupIds, currentFilters.groupIds).length);

    setNeedsRefresh(needsRefresh);
  }, [
    currentFilters,
    didSearch,
    selectedGroupIds,
    selectedServiceZoneId,
    selectedVehicleTypeId,
    selectedWasteMaterialTypeId,
    setNeedsRefresh,
  ]);

  return (
    <form>
      <RouteTemplateBuilderFiltersContainer>
        {(selectedDraft || !!servicesLoadingForMaterialTypeIds.length) && (
          <RouteTemplateBuilderFiltersReadOnlyWrapper />
        )}

        <PanelSection withBorder>
          <PanelSectionGroup vertical withBorder width="100%">
            <PanelSection withBorder={!!selectedVehicleTypeId} isLoading={filtersLoading || isChangeLogLoading}>
              <Grid multiLine>
                <GridColumn verticalAlign="center" width="20%" padding="small xSmall">
                  <TypedField
                    name="vehicleTypeId"
                    component={Dropdown}
                    props={{
                      width: '100%',
                      margin: 'no',
                      placeholder: translate('routeTemplateBuilder.vehicleType'),
                      options: vehicleTypesForRTB.map(type => ({
                        label: translate(createTranslationKey(type.technicalName, 'vehicles.vehicleTypes')),
                        value: type.id,
                      })),
                    }}
                    onChange={(event, newVehicleTypeId) => {
                      dispatch(setFilterVehicle(newVehicleTypeId));
                    }}
                  />
                </GridColumn>
                <GridColumn verticalAlign="center" width="20%" padding="small xSmall">
                  <TypedField
                    name="wasteMaterialTypeId"
                    component={WasteTypeDropdown}
                    onChange={(e, wasteTypeId) => {
                      dispatch(setFilterWasteMaterialType(wasteTypeId));
                    }}
                    props={{
                      dropdownProps: {
                        isClearable: true,
                        width: '100%',
                        margin: 'no',
                        placeholder: translate('common.wasteType'),
                      },
                    }}
                  />
                </GridColumn>
                <GridColumn verticalAlign="center" width="20%" padding="small xSmall">
                  <TypedField
                    name="groupIds"
                    component={GroupsMultiSelect}
                    onChange={(e, groupIds) => {
                      dispatch(setFilterGroupIds(groupIds));
                    }}
                    props={
                      {
                        defaultToAll: false,
                        inputWidth: '100%',
                        margin: 'no',
                        placeholder: translate('routes.groups.groups'),
                        includeNoneOption: true,
                      } as any
                    }
                  />
                </GridColumn>
                <GridColumn verticalAlign="center" width="20%" padding="small xSmall">
                  <TypedField
                    name="serviceZoneId"
                    component={ServiceZoneDropdown}
                    onChange={(e, serviceZoneId) => {
                      dispatch(setFilterServiceZone(serviceZoneId));
                    }}
                    props={{
                      dropdownProps: {
                        placeholder: translate('routes.serviceZone'),
                        width: '100%',
                        margin: 'no',
                      },
                      includeNoneOption: true,
                    }}
                  />
                </GridColumn>
                <GridColumn verticalAlign="center" align="center" width="20%" padding="small xSmall">
                  <Button
                    color="primary"
                    type="button"
                    disabled={
                      !selectedVehicleTypeId ||
                      (!!selectedVehicleTypeId &&
                        !selectedWasteMaterialTypeId &&
                        selectedServiceZoneId === null &&
                        !selectedGroupIds.length) ||
                      (didSearch && !needsRefresh)
                    }
                    onClick={searchFilters}
                  >
                    {translate('common.search')}
                  </Button>
                </GridColumn>
              </Grid>
            </PanelSection>

            {!!selectedVehicleTypeId && didSearch && (
              <PanelSection isLoading={filtersLoading} minHeight={110}>
                {needsRefresh && <RouteTemplateBuilderMapInfoOverlay />}
                <DetailsContainer padding="no" noShadow={!shadowVisible}>
                  <StatusFiltersContainer
                    requiresRightMargin
                    padding="xxSmall no xxSmall xxSmall"
                    onScroll={onFiltersScroll}
                    maxFilterWidth={5}
                    filterWidth={4}
                  >
                    {filters.map(filter => (
                      <ButtonWrapperWithPopover key={filter.id}>
                        <StatusFilterButtonWithLeeway
                          type="button"
                          isActive={selectedMaterialTypeIds.indexOf(filter.id) !== -1}
                          isLoading={servicesLoadingForMaterialTypeIds.indexOf(filter.id) !== -1}
                          onClick={() => {
                            dispatch(setFilterMaterial(filter.id));
                          }}
                        >
                          <Text singleLine uppercase size="small" weight="medium" margin="no" color="primary">
                            {filter.title}
                          </Text>

                          {!!filter.dynamicContent && filter.dynamicContent()}
                        </StatusFilterButtonWithLeeway>
                      </ButtonWrapperWithPopover>
                    ))}
                  </StatusFiltersContainer>
                </DetailsContainer>

                {!filters.length && (
                  <RouteTemplateBuilderEmptyFiltersNotice>
                    <Text size="small" weight="light">
                      {translate('routeTemplateBuilder.noMaterialTypes')}
                    </Text>
                  </RouteTemplateBuilderEmptyFiltersNotice>
                )}
              </PanelSection>
            )}
          </PanelSectionGroup>
        </PanelSection>

        {showSecondaryLevelFilters && didSearch && (
          <PanelSection
            vertical
            lightDark
            withBorder
            isLoading={filtersLoading || !!servicesLoadingForMaterialTypeIds.length}
          >
            {needsRefresh && <RouteTemplateBuilderMapInfoOverlay />}
            <PanelSectionGroup vertical centered>
              <Grid padding="xSmall xSmall no xSmall">
                <GridColumn width="20%" padding="no xxSmall">
                  <TypedField
                    name="dayOfServiceIds"
                    component={MultiSelect}
                    onChange={(_, newValue: number[]) => {
                      dispatch(setFilterDaysOfService(newValue));
                      dispatch(setStopFilterType(ALL_STOPS_FILTER));
                    }}
                    props={{
                      margin: 'no',
                      placeholder: translate('routeTemplateBuilder.dayOfService'),
                      options: WEEKDAYS.map(day => ({
                        label: day.name,
                        value: day.id,
                        isDisabled: !availableDayOfServiceIds.find(availableDayId => availableDayId === day.id),
                      })),
                      normalizeValues: value => +value,
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('routeTemplateBuilder.allDaysOfService')
                          : selectedOptions.length === 1
                          ? WEEKDAYS_BY_ID[selectedOptions[0]].name
                          : translate('routeTemplateBuilder.xDaysOfServiceSelected', {
                              selected: selectedOptions.length,
                            });
                      },
                    }}
                  />
                </GridColumn>

                <GridColumn width="20%" padding="no xxSmall">
                  <TypedField
                    name="routeTemplateIds"
                    component={MultiSelect}
                    onChange={(_, newValue: string[]) => {
                      dispatch(setFilterRouteTemplates(newValue));
                      dispatch(setStopFilterType(ALL_STOPS_FILTER));
                    }}
                    props={{
                      noWrap: true,
                      margin: 'no',
                      placeholder: translate('routeTemplateBuilder.routes'),
                      options: routesOptions,
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('routeTemplateBuilder.allRoutes')
                          : translate('routeTemplateBuilder.xRoutesSelected', { selected: selectedOptions.length });
                      },
                    }}
                  />
                </GridColumn>
                <GridColumn width="20%" padding="no xxSmall">
                  <TypedField
                    name="serviceTypeIds"
                    component={MultiSelect}
                    onChange={(_, newValue: number[]) => {
                      dispatch(setFilterServiceTypes(newValue));
                      dispatch(setStopFilterType(ALL_STOPS_FILTER));
                    }}
                    props={{
                      noWrap: true,
                      margin: 'no',
                      placeholder: translate('routeTemplateBuilder.serviceTypes'),
                      options: serviceOptions,
                      normalizeValues: value => +value,
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('routeTemplateBuilder.allServiceTypes')
                          : translate('routeTemplateBuilder.xServiceTypesSelected', {
                              selected: selectedOptions.length,
                            });
                      },
                    }}
                  />
                </GridColumn>

                <GridColumn width="20%">
                  <TypedField
                    name="equipmentTypeIds"
                    component={MultiSelect}
                    onChange={(_, newValue: number[]) => {
                      dispatch(setFilterEquipmentTypes(newValue));
                      dispatch(setStopFilterType(ALL_STOPS_FILTER));
                    }}
                    props={{
                      margin: 'no',
                      placeholder: translate('common.equipmentType'),
                      options: equipmentTypeOptions,
                      normalizeValues: value => +value,
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('routeTemplateBuilder.allEquipmentTypes')
                          : translate('routeTemplateBuilder.xEquipmentTypes', {
                              count: selectedOptions.length,
                            });
                      },
                    }}
                  />
                </GridColumn>

                <GridColumn width="20%" padding="no xxSmall">
                  <TypedField
                    name="containerSizeIds"
                    component={MultiSelect}
                    onChange={(_, newValue: number[]) => {
                      dispatch(setFilterEquipmentSizes(newValue));
                      dispatch(setStopFilterType(ALL_STOPS_FILTER));
                    }}
                    props={{
                      noWrap: true,
                      margin: 'no',
                      placeholder: translate('routeTemplateBuilder.containerSize'),
                      options: containerSizeOptions,
                      normalizeValues: value => +value,
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('routeTemplateBuilder.allContainerSizes')
                          : translate('routeTemplateBuilder.xContainerSizes', {
                              count: selectedOptions.length,
                            });
                      },
                    }}
                  />
                </GridColumn>
              </Grid>
            </PanelSectionGroup>

            <PanelSectionGroup>
              <SimpleFilterWrapper>
                <SimpleFilterButton
                  grow
                  isActive={stopFilterType === ALL_STOPS_FILTER}
                  onClick={event => {
                    event.preventDefault();
                    dispatch(setStopFilterType(ALL_STOPS_FILTER));
                  }}
                >
                  {translate('routeTemplateBuilder.allStops')}
                </SimpleFilterButton>

                <SimpleFilterButton
                  grow
                  isActive={stopFilterType === ASSIGNED_FILTER}
                  onClick={event => {
                    event.preventDefault();
                    dispatch(setStopFilterType(ASSIGNED_FILTER));
                  }}
                >
                  {translate('routeTemplateBuilder.xAssignedStops', {
                    count: selectedDaysOfServiceBundle?.assignedStopsCount || 0,
                  })}
                </SimpleFilterButton>

                <SimpleFilterButton
                  grow
                  isActive={stopFilterType === UNASSIGNED_FILTER}
                  onClick={event => {
                    event.preventDefault();
                    dispatch(setStopFilterType(UNASSIGNED_FILTER));
                  }}
                >
                  {translate('routeTemplateBuilder.xUnassignedStops', {
                    count: selectedDaysOfServiceBundle?.unassignedStopsCount || 0,
                  })}
                </SimpleFilterButton>
                {lastMapFilters && (
                  <Button
                    onClick={() => {
                      //After transfering stops some options might be missing, so when restoring last filters we need to check if they are still available
                      dispatch(restoreLastFilters());
                    }}
                    type="button"
                    color="primary"
                  >
                    {translate('routeTemplateBuilder.applyLastFilters')}
                  </Button>
                )}
              </SimpleFilterWrapper>
            </PanelSectionGroup>
          </PanelSection>
        )}
      </RouteTemplateBuilderFiltersContainer>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: ROUTE_TEMPLATE_BUILDER_WORK_SESSION_FILTERS_FORM,
  enableReinitialize: true,
})(RouteTemplateBuilderFilters);
