import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

export const TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_SOURCE = 'TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_SOURCE';
export const TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER = 'TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER';

interface Props {
  geoJson: GeoJSON.FeatureCollection<GeoJSON.Point>;
}

const VehiclePositionsGLSource: FC<Props> = ({ geoJson }) => {
  return (
    <Source id={TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_SOURCE} type="geojson" data={geoJson}>
      <Layer
        id={TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER}
        type="symbol"
        source={TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_SOURCE}
        paint={{}}
        layout={{
          'icon-image': ['get', 'icon'],
          'icon-size': ['case', ['==', ['get', 'isPickupTruck'], true], 0.55, 0.75],
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
        }}
      />
    </Source>
  );
};

export default memo(VehiclePositionsGLSource);
