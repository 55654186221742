import update from 'immutability-helper';
import { reverse } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import {
  doDeleteGeoFences,
  doLoadGeoFence,
  doLoadGeoFenceAlert,
  doLoadGeoFences,
  doSaveGeoFence,
  doSaveGeoFenceAlert,
  doSaveGeoFences,
  doUpdateGeoFence,
  doLoadGeoFenceBasicList,
} from '../services/geoFences';

// Interfaces
export interface GeoFenceZoneType {
  id: number;
  name: string;
  technicalName: string;
}

export interface GeoFence {
  geoFenceJson: any;
  geoFenceName: string;
  geoFenceZoneType: GeoFenceZoneType;
  id: number;
  routeId?: number;
  routeName?: string;
  routeTemplateId?: number;
  startingLocationName?: string;
}

export type GeoFenceJsonList = {
  geoFenceJson: any;
  id: number;
  name: string;
}[];

export interface GeoQueryPayload {
  vendorId: number;
  geoFenceZoneTypeIds?: string;
  geoFenceName?: string;
  page?: number;
  limit?: number;
  sortOrder?: string;
  sortedBy?: string;
  geoFenceIdsCSV?: string;
  routeDate?: Date | string;
  noLoadingIndicator?: boolean;
}

export type GeoFenceBasicList = {
  id: number;
  geoFenceName: string;
}[];

// Actions
const START_LOAD_GEO_FENCES = 'routes/geoFences/START_LOAD_GEO_FENCES';
const COMPLETE_LOAD_GEO_FENCES = 'routes/geoFences/COMPLETE_LOAD_GEO_FENCES';
const FAIL_LOAD_GEO_FENCES = 'routes/geoFences/FAIL_LOAD_GEO_FENCES';
const START_LOAD_GEO_FENCE = 'routes/geoFences/START_LOAD_GEO_FENCE';
const COMPLETE_LOAD_GEO_FENCE = 'routes/geoFences/COMPLETE_LOAD_GEO_FENCE';
const FAIL_LOAD_GEO_FENCE = 'routes/geoFences/FAIL_LOAD_GEO_FENCE';
const START_DELETE_GEO_FENCES = 'routes/geoFences/START_DELETE_GEO_FENCES';
const COMPLETE_DELETE_GEO_FENCES = 'routes/geoFences/COMPLETE_DELETE_GEO_FENCES';
const FAIL_DELETE_GEO_FENCES = 'routes/geoFences/FAIL_DELETE_GEO_FENCES';
const START_SAVE_GEO_FENCE = 'routes/geoFences/START_SAVE_GEO_FENCE';
const COMPLETE_SAVE_GEO_FENCE = 'routes/geoFences/COMPLETE_SAVE_GEO_FENCE';
const FAIL_SAVE_GEO_FENCE = 'routes/geoFences/FAIL_SAVE_GEO_FENCE';
const START_SAVE_GEO_FENCES = 'routes/geoFences/START_SAVE_GEO_FENCES';
const COMPLETE_SAVE_GEO_FENCES = 'routes/geoFences/COMPLETE_SAVE_GEO_FENCES';
const FAIL_SAVE_GEO_FENCES = 'routes/geoFences/FAIL_SAVE_GEO_FENCES';
const START_LOAD_GEO_FENCE_ALERT = 'routes/geoFences/START_LOAD_GEO_FENCE_ALERT';
const COMPLETE_LOAD_GEO_FENCE_ALERT = 'routes/geoFences/COMPLETE_LOAD_GEO_FENCE_ALERT';
const FAIL_LOAD_GEO_FENCE_ALERT = 'routes/geoFences/FAIL_LOAD_GEO_FENCE_ALERT';
const START_SAVE_GEO_FENCE_ALERT = 'routes/geoFences/START_SAVE_GEO_FENCE_ALERT';
const COMPLETE_SAVE_GEO_FENCE_ALERT = 'routes/geoFences/COMPLETE_SAVE_GEO_FENCE_ALERT';
const FAIL_SAVE_GEO_FENCE_ALERT = 'routes/geoFences/FAIL_SAVE_GEO_FENCE_ALERT';
const START_LOAD_GEO_FENCE_BASIC_LIST = 'routes/geoFences/START_LOAD_GEO_FENCE_BASIC_LIST';
const COMPLETE_LOAD_GEO_FENCE_BASIC_LIST = 'routes/geoFences/COMPLETE_LOAD_GEO_FENCE_BASIC_LIST';
const FAIL_LOAD_GEO_FENCE_BASIC_LIST = 'routes/geoFences/FAIL_LOAD_GEO_FENCE_BASIC_LIST';
const RESET_GEO_FENCES = 'routes/geoFences/RESET_GEO_FENCES';

// Initial state =
const initialState: any = {
  geoFence: {
    geoFenceCoordinates: [],
  },
  geoFenceAlert: [],
  geoFences: {},
  geoFences2: {},
  geoFenceBasicList: [] as GeoFenceBasicList,
  isLoadingGeoFenceBasicList: false,
  isDeleting: false,
  isLoading: false,
  isLoadingAlert: false,
  isSaving: false,
  isSavingAlert: false,
  isSavingGeoFences: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_GEO_FENCES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_GEO_FENCES:
      return update(state, {
        $merge: {
          isLoading: false,
          geoFences: reverse(action.geoFences.geoFences),
        },
      });

    case FAIL_LOAD_GEO_FENCES:
      return update(state, {
        $merge: {
          isLoading: false,
          geoFences: undefined,
        },
      });

    case START_LOAD_GEO_FENCE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_GEO_FENCE:
      return update(state, {
        $merge: {
          isLoading: false,
          geoFence: action.geoFence,
        },
      });

    case FAIL_LOAD_GEO_FENCE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_DELETE_GEO_FENCES:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE_GEO_FENCES: {
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });
    }

    case FAIL_DELETE_GEO_FENCES:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_SAVE_GEO_FENCE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE_GEO_FENCE: {
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    }

    case FAIL_SAVE_GEO_FENCE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_SAVE_GEO_FENCES:
      return update(state, {
        $merge: {
          isSavingGeoFences: true,
        },
      });

    case COMPLETE_SAVE_GEO_FENCES: {
      return update(state, {
        $merge: {
          isSavingGeoFences: false,
        },
      });
    }

    case FAIL_SAVE_GEO_FENCES:
      return update(state, {
        $merge: {
          isSavingGeoFences: false,
        },
      });

    case START_LOAD_GEO_FENCE_ALERT:
      return update(state, {
        $merge: {
          isLoadingAlert: true,
        },
      });

    case COMPLETE_LOAD_GEO_FENCE_ALERT:
      return update(state, {
        $merge: {
          isLoadingAlert: false,
          geoFenceAlert: action.geoFenceAlert.getGeoFenceSettings,
        },
      });

    case FAIL_LOAD_GEO_FENCE_ALERT:
      return update(state, {
        $merge: {
          isLoadingAlert: false,
          geoFenceAlert: undefined,
        },
      });

    case START_SAVE_GEO_FENCE_ALERT:
      return update(state, {
        $merge: {
          isSavingAlert: true,
        },
      });

    case COMPLETE_SAVE_GEO_FENCE_ALERT:
      return update(state, {
        $merge: {
          isSavingAlert: false,
        },
      });

    case FAIL_SAVE_GEO_FENCE_ALERT:
      return update(state, {
        $merge: {
          isSavingAlert: false,
        },
      });

    case START_LOAD_GEO_FENCE_BASIC_LIST:
      return update(state, {
        $merge: {
          isLoadingBasicGeoFenceList: true,
        },
      });

    case COMPLETE_LOAD_GEO_FENCE_BASIC_LIST:
      return update(state, {
        $merge: {
          isLoadingBasicGeoFenceList: false,
          geoFenceBasicList: action.geoFenceBasicList,
        },
      });

    case FAIL_LOAD_GEO_FENCE_BASIC_LIST:
      return update(state, {
        $merge: {
          isLoadingBasicGeoFenceList: false,
          geoFences: undefined,
        },
      });

    case RESET_GEO_FENCES:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoadGeoFences = () => ({
  type: START_LOAD_GEO_FENCES,
});

const completeLoadGeoFences = (geoFences: any) => ({
  type: COMPLETE_LOAD_GEO_FENCES,
  geoFences,
});

const failLoadGeoFences = () => ({
  type: FAIL_LOAD_GEO_FENCES,
});

const startLoadGeoFence = () => ({
  type: START_LOAD_GEO_FENCE,
});

const completeLoadGeoFence = (geoFence: GeoFence) => ({
  type: COMPLETE_LOAD_GEO_FENCE,
  geoFence,
});

const failLoadGeoFence = () => ({
  type: FAIL_LOAD_GEO_FENCE,
});

const startDeleteGeoFences = () => ({
  type: START_DELETE_GEO_FENCES,
});

const completeDeleteGeoFences = () => ({
  type: COMPLETE_DELETE_GEO_FENCES,
});

const failDeleteGeoFences = () => ({
  type: FAIL_DELETE_GEO_FENCES,
});

const startSaveGeoFence = () => ({
  type: START_SAVE_GEO_FENCE,
});

const completeSaveGeoFence = () => ({
  type: COMPLETE_SAVE_GEO_FENCE,
});

const failSaveGeoFence = () => ({
  type: FAIL_SAVE_GEO_FENCE,
});

const startSaveGeoFences = () => ({
  type: START_SAVE_GEO_FENCES,
});

const completeSaveGeoFences = () => ({
  type: COMPLETE_SAVE_GEO_FENCES,
});

const failSaveGeoFences = () => ({
  type: FAIL_SAVE_GEO_FENCES,
});

const startLoadGeoFenceAlert = () => ({
  type: START_LOAD_GEO_FENCE_ALERT,
});

const completeLoadGeoFenceAlert = (geoFenceAlert: any) => ({
  type: COMPLETE_LOAD_GEO_FENCE_ALERT,
  geoFenceAlert,
});

const failLoadGeoFenceAlert = () => ({
  type: FAIL_LOAD_GEO_FENCE_ALERT,
});

const startSaveGeoFenceAlert = () => ({
  type: START_SAVE_GEO_FENCE_ALERT,
});

const completeSaveGeoFenceAlert = () => ({
  type: COMPLETE_SAVE_GEO_FENCE_ALERT,
});

const failSaveGeoFenceAlert = () => ({
  type: FAIL_SAVE_GEO_FENCE_ALERT,
});

const startLoadGeoFenceBasicList = () => ({
  type: START_LOAD_GEO_FENCE_BASIC_LIST,
});

const completeLoadGeoFenceBasicList = (geoFenceBasicList: GeoFenceBasicList) => ({
  type: COMPLETE_LOAD_GEO_FENCE_BASIC_LIST,
  geoFenceBasicList,
});

const failLoadGeoFenceBasicList = () => ({
  type: FAIL_LOAD_GEO_FENCE_BASIC_LIST,
});

export const resetGeoFences = () => ({ type: RESET_GEO_FENCES });

export const loadGeoFences = (payload: GeoQueryPayload) => (dispatch: Dispatch) => {
  !payload.noLoadingIndicator && dispatch(startLoadGeoFences());
  const loadGeoFencesPromise = doLoadGeoFences(payload);
  loadGeoFencesPromise
    .then(geoFences => dispatch(completeLoadGeoFences(geoFences)))
    .catch(() => dispatch(failLoadGeoFences()));
  return loadGeoFencesPromise;
};

export const loadGeoFence = (geoFenceId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadGeoFence());
  const loadGeoFencePromise = doLoadGeoFence(geoFenceId);
  loadGeoFencePromise
    .then(geoFence => dispatch(completeLoadGeoFence(geoFence)))
    .catch(() => dispatch(failLoadGeoFence()));
  return loadGeoFencePromise;
};

export const deleteGeoFences = (vendorId: number, geoFencesIds: string) => (dispatch: Dispatch) => {
  dispatch(startDeleteGeoFences());
  const deleteGeoFencesPromise = doDeleteGeoFences(vendorId, geoFencesIds);
  deleteGeoFencesPromise.then(() => dispatch(completeDeleteGeoFences())).catch(() => dispatch(failDeleteGeoFences()));
  return deleteGeoFencesPromise;
};

export const saveGeoFence = (geoFence: GeoFence, vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startSaveGeoFence());
  const saveGeoFencePromise = geoFence.id ? doUpdateGeoFence(geoFence, vendorId) : doSaveGeoFence(geoFence, vendorId);
  saveGeoFencePromise.then(() => dispatch(completeSaveGeoFence())).catch(() => dispatch(failSaveGeoFence()));
  return saveGeoFencePromise;
};

export const saveGeoFences = (geoFences: GeoFenceJsonList, vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startSaveGeoFences());
  const saveGeoFencesPromise = doSaveGeoFences(geoFences, vendorId);
  saveGeoFencesPromise.then(() => dispatch(completeSaveGeoFences())).catch(() => dispatch(failSaveGeoFences()));
  return saveGeoFencesPromise;
};

export const loadGeoFenceAlert = (geoFenceId: number, vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadGeoFenceAlert());
  const loadGeoFenceAlertPromise = doLoadGeoFenceAlert(geoFenceId, vendorId);
  loadGeoFenceAlertPromise
    .then(geoFenceAlert => dispatch(completeLoadGeoFenceAlert(geoFenceAlert)))
    .catch(() => dispatch(failLoadGeoFenceAlert()));
  return loadGeoFenceAlertPromise;
};

export const saveGeoFenceAlert = (geoFenceAlert: any, geoFenceId: number) => (dispatch: Dispatch) => {
  dispatch(startSaveGeoFenceAlert());
  const saveGeoFenceAlertPromise = doSaveGeoFenceAlert(geoFenceAlert, geoFenceId);
  saveGeoFenceAlertPromise
    .then(() => dispatch(completeSaveGeoFenceAlert()))
    .catch(() => dispatch(failSaveGeoFenceAlert()));
  return saveGeoFenceAlertPromise;
};

export const loadGeoFenceBasicList = (vendorId: number, geoFenceZoneTypeIds?: string) => (dispatch: Dispatch) => {
  dispatch(startLoadGeoFenceBasicList());
  const loadGeoFenceBasicListPromise = doLoadGeoFenceBasicList(vendorId, geoFenceZoneTypeIds);
  loadGeoFenceBasicListPromise
    .then(geoFenceBasicList => dispatch(completeLoadGeoFenceBasicList(geoFenceBasicList)))
    .catch(() => dispatch(failLoadGeoFenceBasicList()));
  return loadGeoFenceBasicListPromise;
};
