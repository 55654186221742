import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadSurfsightConfiguration } from '../../ducks/surfsightConfiguration';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import SurfsightConfigurationModal from './SurfsightConfigurationModal';
import { TypedResolver } from '../../../core/components';

const SurfsightDataModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [loadSurfsightConfiguration(vendorId)(dispatch)];

    return Promise.all(dependencies);
  }, [vendorId, dispatch]);

  return (
    <TypedResolver
      successComponent={SurfsightConfigurationModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default SurfsightDataModalResolver;
