import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { DuckFunction } from '../../../contracts/ducks';
import { LanguageOptions } from '../../interfaces/UserEditor';
import { loadLanguageOptions, loadUserTypes } from '../../../common/ducks';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalTitle } from '../../../core/components/styled';
import { UserEditorForm } from '../forms';
import translate from '../../../core/services/translate';
import { UserType } from 'src/common/interfaces/UserType';

interface Props extends ModalProps {
  isSaving: boolean;
  isUserSuperAdmin: boolean;
  languageOptions: LanguageOptions[];
  userTypes: UserType[];
  loadLanguageOptions: DuckFunction<typeof loadLanguageOptions>;
  loadUserTypes: DuckFunction<typeof loadUserTypes>;
  onSaveUser: () => void;
  userId?: string;
}

class UserEditorModal extends PureComponent<Props> {
  componentDidMount() {
    const { loadLanguageOptions, loadUserTypes } = this.props;
    loadLanguageOptions();
    loadUserTypes();
  }

  render() {
    const { isSaving, isUserSuperAdmin, onSaveUser, closeModal, userId, languageOptions, userTypes } = this.props;

    return (
      <Modal padding="medium" isLoading={isSaving} size="small">
        <ModalTitle>{translate(`vendors.${userId ? 'edit' : 'create'}User`)}</ModalTitle>
        <UserEditorForm
          onSubmit={onSaveUser}
          isUserSuperAdmin={isUserSuperAdmin}
          userId={userId}
          onCancel={closeModal}
          languageOptions={languageOptions}
          userTypes={userTypes}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isSaving: state.vendors.users.isSaving,
  languageOptions: state.common.languageOptions.languageOptions,
  userTypes: state.common.userTypes.userTypes,
});

const mapDispatchToProps = {
  loadLanguageOptions,
  loadUserTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditorModal);
