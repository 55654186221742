import { Component, ErrorInfo } from 'react';
import { AccordionSection } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { ErrorBoundaryWrapper } from './styled/ErrorBoundary';

class ErrorBoundary extends Component<{}, { error: Error | null; errorInfo: ErrorInfo | null; isOpen: boolean }> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { error: null, errorInfo: null, isOpen: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  toggleDetails = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  render() {
    if (this.state.errorInfo) {
      // Error path
      const stackTrace = this.state.errorInfo.componentStack
        .split('\n')
        .map((line, index) => <div key={index}>{line}</div>);
      return (
        <ErrorBoundaryWrapper>
          <Text size="xLarge" color="alert" block margin="medium">
            {translate('common.alertMessages.somethingWentWrong')}
          </Text>
          <AccordionSection
            name="error"
            title={`${translate('common.errorDetails')}: ${
              this.state.error && this.state.error.toString()
            }.... ${translate('common.clickForDetails')}`}
            titleSize="medium"
            isOpen={this.state.isOpen}
            toggle={this.toggleDetails}
          >
            <Text size="medium" color="" block margin="small medium"></Text>

            <Text size="small" color="alert" block margin="small medium">
              {stackTrace}
            </Text>
          </AccordionSection>
        </ErrorBoundaryWrapper>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
