import moment from 'moment';
import { Modal } from 'src/core/components';
import { ModalSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { dateFormat } from 'src/utils/services/validator';
import RouteHistoryMapGL from './RouteHistoryMapGL';

export interface Props {
  closeModal: () => void;
}
const RouteHistoryModal = ({ closeModal }: Props) => {
  const { routeHistoryItem } = useSelector(state => state.dashboard.routeHistoryData);

  return (
    <Modal
      size="large"
      padding="medium no no"
      title={moment(routeHistoryItem?.routeDate).format(dateFormat)}
      onClose={closeModal}
    >
      <ModalSection height="700px" width="1000px" padding="small">
        <RouteHistoryMapGL />
      </ModalSection>
    </Modal>
  );
};

export default RouteHistoryModal;
