import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { TechnicalType } from '../interfaces/TechnicalType';

const transformRequirementsTypes = (requirementsTypes: TechnicalType[]) =>
  map(requirementsTypes, requirementsType => ({
    ...requirementsType,
    name: translate(createTranslationKey(requirementsType.technicalName, 'common.requirementTypes')),
  }));

export default transformRequirementsTypes;
