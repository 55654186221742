import moment from 'moment';
import createFormattedAddress from '../../utils/services/createFormattedAddress';

export const transformLoadRouteImportDetails = (routeImportDetails: any) => ({
  ...routeImportDetails,
  effectiveDate: moment(routeImportDetails.effectiveDate, 'MM/DD/YYYY').format('MM/DD/YYYY'),
  address: {
    ...routeImportDetails.address,
    formattedAddress: routeImportDetails.address.line1 || createFormattedAddress(routeImportDetails.address),
  },
});

export const transformSaveRouteImportDetails = (routeImportDetails: any) => ({
  ...routeImportDetails,
  address: {
    ...routeImportDetails.address,
    line1: routeImportDetails.address.formattedAddress,
  },
});
