import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { mapper, sizeMapper } from '../../../utils/styles';

export interface SimpleInputProps {
  withBoxShadow?: boolean;
  withBorder?: boolean;
  padding?: string;
  size?: string;
}

const SimpleInput = styled.input<SimpleInputProps>`
  width: 100%;
  padding: 2px 2px 2px 0;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 0;
  line-height: 22px;
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px' })};

  ${props =>
    props.withBorder &&
    css`
      border: 1px solid ${props.theme.gray};
    `}

  ${props =>
    props.withBoxShadow &&
    css`
      box-shadow: 0 0 4px ${props => transparentize(0.9, props.theme.grayBase)};
    `};

  ${props =>
    props.padding &&
    css`
      padding: ${sizeMapper(props.padding)};
    `}

  &:disabled {
    color: ${props => props.theme.grayDark};
  }

  &::placeholder {
    color: ${props => props.theme.grayDark};
    font-size: 14px;
  }
`;

export default SimpleInput;
