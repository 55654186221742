import { find } from 'lodash-es';
import { useMemo } from 'react';
import { Modal } from 'src/core/components';
import { ModalSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import ModalMapGL from './ModalMapGL';

export interface Props {
  closeModal: () => void;
}
const MapModal = ({ closeModal }: Props) => {
  const vehiclePositions = useSelector(state => state.dashboard.vehiclesData.vehiclesList);
  const routeVehiclePositions = useSelector(state => state.dashboard.routesData.routeVehiclePositions);

  const { vehicleId, isSourceRoute } = useSelector(state => state.dashboard.mapControls.mapModalData);

  const selectedVehicle = useMemo(() => {
    if (!vehicleId) {
      return null;
    }
    if (isSourceRoute) {
      const vehiclePos = find(routeVehiclePositions, { vehicleId });
      return {
        ...vehiclePos,
        name: vehiclePos?.vehicleName,
      };
    }

    return find(vehiclePositions, { id: vehicleId });
  }, [isSourceRoute, routeVehiclePositions, vehicleId, vehiclePositions]);

  return (
    <Modal size="large" padding="medium no no" title={selectedVehicle?.name} onClose={closeModal}>
      <ModalSection height="700px" width="1000px" padding="small">
        <ModalMapGL />
      </ModalSection>
    </Modal>
  );
};

export default MapModal;
