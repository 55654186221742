import React from 'react';

import translate from '../../../../../core/services/translate';
import { DrawingInstructionsContainer } from '../../../styled/RouteTemplateBuilderMap';
import { IconButtonIcon, Text } from 'src/core/components/styled';
import { setMapDrawingInstructionsOpen } from 'src/routes/ducks/routeTemplateBuilder';
import { useSelector } from 'src/core/hooks/useSelector';
import { useDispatch } from 'react-redux';

export const DrawingInstructions: React.FC = () => {
  const dispatch = useDispatch();
  const mapDrawingInstructionsOpen = useSelector(state => state.routes.routeTemplateBuilder.mapDrawingInstructionsOpen);

  return (
    <DrawingInstructionsContainer
      isOpen={mapDrawingInstructionsOpen}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();

        if (!mapDrawingInstructionsOpen) {
          dispatch(setMapDrawingInstructionsOpen(true));
        }
      }}
    >
      {mapDrawingInstructionsOpen ? (
        <>
          <div onClick={() => dispatch(setMapDrawingInstructionsOpen(false))}>
            <IconButtonIcon icon="xMark" margin="no no xSmall" />
          </div>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.createPoints')}
          </Text>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.createPolygon')}
          </Text>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.finishDrawing')}
          </Text>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.editPolygon')}
          </Text>
        </>
      ) : (
        <IconButtonIcon icon="info" margin="no" />
      )}
    </DrawingInstructionsContainer>
  );
};
