export const SIZE_AUTO = 'auto';
export const SIZE_NO = 'no';
export const SIZE_XX_SMALL = 'xxSmall';
export const SIZE_X_SMALL = 'xSmall';
export const SIZE_SMALL = 'small';
export const SIZE_NEG_SMALL = 'negSmall';
export const SIZE_NEG_MEDIUM = 'negMedium';
export const SIZE_NEG_LARGE = 'negLarge';
export const SIZE_S_MEDIUM = 'sMedium';
export const SIZE_SS_MEDIUM = 'ssMedium';
export const SIZE_MEDIUM = 'medium';
export const SIZE_L_MEDIUM = 'lMedium';
export const SIZE_LARGE = 'large';
export const SIZE_X_LARGE = 'xLarge';
export const SIZE_XX_LARGE = 'xxLarge';
export const SIZE_GRANDE = 'grande';
export const SIZE_X_GRANDE = 'xGrande';
export const SIZE_XX_GRANDE = 'xxGrande';
export const SIZE_XXX_GRANDE = 'xxxGrande';
export const SIZE_DEFAULT_CELL_VERTICAL = 'defaultCellVertical';
export const SIZE_DEFAULT_CELL_HORIZONTAL = 'defaultCellHorizontal';
