import { SnowRoadSegmentCondition, SnowRoadSegmentConditionUpdates } from '../interfaces/snowRoadConditions';

export const updateSnowRoadConditions = (
  segments: SnowRoadSegmentCondition[],
  updates: SnowRoadSegmentConditionUpdates[],
): SnowRoadSegmentCondition[] =>
  segments.map(segment => {
    const update = updates.find(update => update.segmentId === segment.segmentId);

    if (!update) {
      return segment;
    }

    const { lastPassDateTime, lastForwardPassDateTime, lastReversePassDateTime } = update;

    return {
      ...segment,
      lastPassDateTime,
      lastForwardPassDateTime,
      lastReversePassDateTime,
    };
  });
