import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { DATE_RANGE_PICKER_30_60_90 } from '../../../core/constants';
import { DateRangePicker } from '../../../core/components';
import { PanelSection, Grid, GridColumn, Button } from '../../../core/components/styled';
import containerHistoryFormInitialValuesSelector from '../../services/containerHistoryFormInitialValuesSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

class ContainerHistoryForm extends PureComponent<InjectedFormProps> {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit} noValidate>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="dateRange"
                props={{ hasMarginLeft: 'smaller' }}
                component={DateRangePicker}
                options={DATE_RANGE_PICKER_30_60_90}
                margin="no"
              />
            </GridColumn>

            <GridColumn size="9/12" align="right">
              <Button color="primary">{translate('common.search')}</Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps) => ({
  initialValues: containerHistoryFormInitialValuesSelector(ownProps.location.search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'containerHistoryForm',
      onSubmitFail: focusFirstInvalidField,
    })(ContainerHistoryForm),
  ),
);
