import { useEffect } from 'react';
import useWindowFocusChange from './useWindowFocusChange';

interface useRefreshDataIntervalProps {
  callback: () => void;
  interval: number;
  refreshOnWindowFocus?: boolean;
}

const useRefreshDataInterval = ({ callback, interval, refreshOnWindowFocus }: useRefreshDataIntervalProps) => {
  const { isWindowFocused } = useWindowFocusChange({
    revisitAction: () => {
      if (refreshOnWindowFocus) callback();
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isWindowFocused) {
        callback();
      }
    }, interval);
    return () => clearInterval(intervalId);
  }, [isWindowFocused, callback, interval]);
};

export default useRefreshDataInterval;
