import { camelCase } from 'lodash-es';
import { Dispatch } from 'redux';
import { find } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { AppState } from 'src/store';
import { checkIfSnowPlowIsEnabled } from 'src/vendors/ducks/features';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { Grid, GridColumn } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import { isFuelTicketFeatureEnabled } from 'src/vendors/ducks/features';
import {
  loadDisposalTickets,
  loadDisposalTrips,
  resetDisposalTickets,
  resetDisposalTrips,
} from 'src/routes/ducks/disposals';
import { loadFuelTicketUnitOfMeasure, loadFuelTickets, loadFuelingStation, resetFuelTickets } from 'src/routes/ducks';
import { loadMaterialTickets, resetMaterialTickets } from 'src/routes/ducks/materialTickets';
import {
  RouteDetail,
  RouteDetailLabel,
  RouteDetailValue,
  RouteDetailValueWithIcon,
  RouteDetailsWrapper,
} from 'src/routes/components/styled/RouteDetails';
import { RouteLocationImagesModalResolver } from '..';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, WASTE_AUDIT_ID } from 'src/fleet/constants';
import { Tickets } from 'src/routes/interfaces/Route';
import { useSelector } from 'src/core/hooks/useSelector';
import DisposalTicketsSection from '../routeModalSections/DisposalTicketsSection';
import DisposalTripsSection from '../routeModalSections/DisposalTripsSection';
import FuelTicketsSection from '../routeModalSections/FuelTicketsSection';
import MaterialTicketsSection from '../routeModalSections/MaterialTicketsSection';
import RouteModalSection from '../routeModalSections/RouteModalSection';
import StopConfirmationSection from '../routeModalSections/StopConfirmationSection';
import translate from 'src/core/services/translate';
import { loadMaterialPickups } from 'src/routes/ducks/materialTickets';

type Props = {
  isDriverExperienceSimple?: boolean;
  isNoRoute?: boolean;
};

export default function ServiceTab({ isDriverExperienceSimple, isNoRoute }: Props) {
  const { routeSummary } = useSelector((state: AppState) => state.routes.routeSummary);
  const { serviceDetails } = useSelector((state: AppState) => state.routes.serviceDetails);
  const { isLoadingTickets, isLoadingTrips } = useSelector(state => state.routes.disposals);
  const { isLoadingFuelTickets, isLoadingFuelingStations } = useSelector(state => state.routes.fuelTickets);
  const {
    isLoading: isLoadingMaterialTickets,
    isDeleting: isDeletingMaterialTickets,
    isLoadingMaterialPickups,
  } = useSelector(state => state.routes.materialTickets);
  const { fuelingTicketsSettings } = useSelector(state => state.vendors.fuelingTicketsSettings);
  const isFuelTicketEnabled = useSelector(isFuelTicketFeatureEnabled);
  const isSnowPlowFeatureEnabled = useSelector(state => checkIfSnowPlowIsEnabled(state));
  const snowPlowSettings = useSelector(state => state.vendors.snowPlowSettings.snowPlowSettings);

  const [isRouteImagesModalOpen, setRouteImagesModalOpen] = useState(false);

  const displayFuelingTicketsSettings = useMemo(() => {
    return (
      isFuelTicketEnabled &&
      fuelingTicketsSettings &&
      !!find(fuelingTicketsSettings, { vehicleTypeId: routeSummary?.vehicleTypeId })?.isActive
    );
  }, [fuelingTicketsSettings, isFuelTicketEnabled, routeSummary?.vehicleTypeId]);

  const loadFuelTicketsCallback = useCallback(
    (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
      return Promise.all([
        loadFuelTickets(routeId)(dispatch),
        loadFuelingStation(vendorId)(dispatch),
        loadFuelTicketUnitOfMeasure()(dispatch),
      ]);
    },
    [],
  );

  if (!routeSummary || !serviceDetails) return null;

  const isSnowPlowRoute = routeSummary.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const isWasteAuditRoute = routeSummary.vehicleTypeId === WASTE_AUDIT_ID;

  const notServicedSegmentsCount =
    serviceDetails.segments?.find(segment => segment.pickupStatusTypeId === SCHEDULED)?.segmentsCount || 0;
  const partiallyServicedSegmentsCount =
    serviceDetails.segments?.find(segment => segment.pickupStatusTypeId === IN_PROGRESS)?.segmentsCount || 0;
  const servicedSegmentsCount =
    serviceDetails.segments?.find(segment => segment.pickupStatusTypeId === COMPLETED)?.segmentsCount || 0;

  const materialTypes = serviceDetails.tickets?.reduce((acc: Tickets[], curr: Tickets) => {
    const index = acc.findIndex(
      (item: Tickets) =>
        item.materialType.id === curr.materialType.id && item.unitOfMeasureType.id === curr.unitOfMeasureType.id,
    );
    index > -1
      ? (acc[index].totalMaterialAmount += curr.totalMaterialAmount)
      : acc.push({
          materialType: curr.materialType,
          totalMaterialAmount: curr.totalMaterialAmount,
          unitOfMeasureType: curr.unitOfMeasureType,
          ticketsCount: curr.ticketsCount,
        });
    return acc;
  }, []);

  return (
    <>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>
            {translate(isSnowOrSweeperRoute ? 'routes.totalSegments' : 'insights.totalStops')}
          </RouteDetailLabel>
          <RouteDetailValue>
            {isSnowOrSweeperRoute ? serviceDetails.routeSegmentCount : serviceDetails.totalStopsCount}
          </RouteDetailValue>
        </RouteDetail>

        {isSnowOrSweeperRoute ? (
          <>
            {isSnowPlowRoute && (
              <>
                <RouteDetail>
                  <RouteDetailLabel>{translate('customers.streetNetwork.serviceActivity.salted')}</RouteDetailLabel>
                  <RouteDetailValue>
                    {Math.round((serviceDetails?.salted! * 100) / serviceDetails?.routeSegmentCount!) || 0}%
                  </RouteDetailValue>
                </RouteDetail>
                <RouteDetail>
                  <RouteDetailLabel>{translate('customers.streetNetwork.serviceActivity.brined')}</RouteDetailLabel>
                  <RouteDetailValue>
                    {Math.round((serviceDetails?.brined! * 100) / serviceDetails?.routeSegmentCount!) || 0}%
                  </RouteDetailValue>
                </RouteDetail>
                <RouteDetail>
                  <RouteDetailLabel>{translate('customers.streetNetwork.serviceActivity.plowed')}</RouteDetailLabel>
                  <RouteDetailValue>
                    {Math.round((serviceDetails?.plowed! * 100) / serviceDetails?.routeSegmentCount!) || 0}%
                  </RouteDetailValue>
                </RouteDetail>
              </>
            )}
            {isStreetSweeperRoute && (
              <>
                <RouteDetail>
                  <RouteDetailLabel>{translate('customers.streetNetwork.serviceActivity.swept')}</RouteDetailLabel>
                  <RouteDetailValue>
                    {Math.round((serviceDetails?.swept! * 100) / serviceDetails?.routeSegmentCount!) || 0}%
                  </RouteDetailValue>
                </RouteDetail>
              </>
            )}
          </>
        ) : (
          <>
            <RouteDetail>
              <RouteDetailLabel>{translate('common.pickupStatuses.scheduled')}</RouteDetailLabel>
              <RouteDetailValue>{serviceDetails.scheduledStopsCount}</RouteDetailValue>
            </RouteDetail>
            <RouteDetail>
              <RouteDetailLabel>{translate('common.pickupStatuses.inProgress')}</RouteDetailLabel>
              <RouteDetailValue>{serviceDetails.inProgressStopsCount}</RouteDetailValue>
            </RouteDetail>
            <RouteDetail>
              <RouteDetailLabel>{translate('common.pickupStatuses.completed')}</RouteDetailLabel>
              <RouteDetailValue>
                {serviceDetails.completedStopsCount +
                  serviceDetails.issueReportedStopsCount +
                  serviceDetails.servicedStopsCount +
                  serviceDetails.cancelledStopsCount}
              </RouteDetailValue>
            </RouteDetail>
          </>
        )}
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.issues')}</RouteDetailLabel>
          <RouteDetailValue>
            {isSnowOrSweeperRoute ? serviceDetails.exceptions.length : serviceDetails.routeIssuesCount}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('common.images')}</RouteDetailLabel>
          <RouteDetailValueWithIcon
            isActive={!!serviceDetails.routeImagesCount}
            onClick={serviceDetails.routeImagesCount ? () => setRouteImagesModalOpen(true) : () => {}}
          >
            <Icon icon="image" width="18px" height="18px" />
            <RouteDetailValue>{serviceDetails.routeImagesCount ? serviceDetails.routeImagesCount : 0}</RouteDetailValue>
          </RouteDetailValueWithIcon>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.weightTickets')}</RouteDetailLabel>
          <RouteDetailValue>{serviceDetails.weightTicketsCount}</RouteDetailValue>
        </RouteDetail>
        {!isSnowOrSweeperRoute && (
          <RouteDetail>
            <RouteDetailLabel>{translate('routes.disposalWeight')}</RouteDetailLabel>
            <RouteDetailValue>{`${serviceDetails.weightTicketsWeightTotal.toFixed(2)} ${translate(
              'common.tons',
            )}`}</RouteDetailValue>
          </RouteDetail>
        )}
        {isSnowOrSweeperRoute && (
          <RouteDetail>
            <RouteDetailLabel>{translate('routes.materialSummary')}</RouteDetailLabel>

            {!!materialTypes?.length
              ? materialTypes?.map((materialType, index) => {
                  return (
                    <RouteDetailValue key={index} isSizeSmaller noWrap>
                      {translate(
                        `common.materialTickets.materialTypeValues.${camelCase(
                          materialType.materialType.technicalName,
                        )}`,
                      )}
                      : {materialType.totalMaterialAmount}{' '}
                      {translate(
                        `common.materialTickets.unitOfMeasureValues.${camelCase(
                          materialType.unitOfMeasureType.technicalName,
                        )}`,
                      )}
                    </RouteDetailValue>
                  );
                })
              : '-'}
          </RouteDetail>
        )}
      </RouteDetailsWrapper>
      <Grid multiLine>
        {!isNoRoute && (
          <GridColumn size="12/12">
            <RouteModalSection
              isLoading={false}
              title={translate(isSnowOrSweeperRoute ? 'routes.streetSegmentConfirmation' : 'routes.stopConfirmation')}
              content={
                <StopConfirmationSection
                  isDriverExperienceSimple={isDriverExperienceSimple}
                  notServicedSegmentsCount={notServicedSegmentsCount}
                  partiallyServicedSegmentsCount={partiallyServicedSegmentsCount}
                  servicedSegmentsCount={servicedSegmentsCount}
                />
              }
              defaultOpen
            />
          </GridColumn>
        )}
        {!isWasteAuditRoute && (
          <GridColumn size="12/12">
            <RouteModalSection
              isLoading={isLoadingTickets}
              title={translate('routes.disposalTickets')}
              content={<DisposalTicketsSection />}
              loadDataFunction={loadDisposalTickets}
              resetDataAction={resetDisposalTickets}
            />
          </GridColumn>
        )}

        {isSnowPlowRoute && isSnowPlowFeatureEnabled && snowPlowSettings?.materialPickUpTicket && (
          <GridColumn size="12/12">
            <RouteModalSection
              isLoading={isLoadingMaterialTickets || isDeletingMaterialTickets}
              title={translate('routes.materialTickets')}
              content={<MaterialTicketsSection />}
              loadDataFunctionWithoutVendor={loadMaterialTickets}
              resetDataAction={resetMaterialTickets}
            />
          </GridColumn>
        )}

        {!(isStreetSweeperRoute || isWasteAuditRoute) && (
          <GridColumn size="12/12">
            <RouteModalSection
              isLoading={isSnowPlowRoute ? isLoadingMaterialPickups : isLoadingTrips}
              title={translate(isSnowPlowRoute ? 'routes.materialTrips.materialTrips' : 'routes.disposalTrips')}
              content={<DisposalTripsSection isSnowPlowRoute={isSnowPlowRoute} />}
              loadDataFunction={isSnowPlowRoute ? loadMaterialPickups : loadDisposalTrips}
              resetDataAction={resetDisposalTrips}
            />
          </GridColumn>
        )}

        {displayFuelingTicketsSettings && (
          <GridColumn size="12/12">
            <RouteModalSection
              isLoading={isLoadingFuelTickets || isLoadingFuelingStations}
              title={translate('tooltips.fuelTickets')}
              content={<FuelTicketsSection />}
              loadDataFunction={loadFuelTicketsCallback}
              resetDataAction={resetFuelTickets}
            />
          </GridColumn>
        )}
      </Grid>

      {isRouteImagesModalOpen && (
        <RouteLocationImagesModalResolver
          routeId={routeSummary.routeId}
          closeModal={() => setRouteImagesModalOpen(false)}
        />
      )}
    </>
  );
}
