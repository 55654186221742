import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadSurfsightConfiguration as doLoadSurfsightConfiguration,
  saveSurfsightConfiguration as doSaveSurfsightConfiguration,
} from '../services/surfsightConfiguration';

// Actions
const START_LOAD = 'vendors/surfsightData/START_LOAD';
const COMPLETE_LOAD = 'vendors/surfsightData/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/surfsightData/FAIL_LOAD';
const START_SAVE = 'vendors/surfsightData/START_SAVE';
const COMPLETE_SAVE = 'vendors/surfsightData/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/surfsightData/FAIL_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  surfsightConfiguration: {} as any,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          surfsightConfiguration: action.surfsightData,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (surfsightData: any) => ({
  type: COMPLETE_LOAD,
  surfsightData,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadSurfsightConfiguration = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadSurfsightConfigPromise = doLoadSurfsightConfiguration(vendorId);
  loadSurfsightConfigPromise
    .then((surfsightConfig: any) => {
      dispatch(completeLoad(surfsightConfig));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadSurfsightConfigPromise;
};

export const saveSurfsightConfiguration = (surfsightConfiguration: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveSurfsightConfigPromise = doSaveSurfsightConfiguration(surfsightConfiguration);
  saveSurfsightConfigPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveSurfsightConfigPromise;
};
