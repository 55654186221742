import styled, { css } from 'styled-components';

interface Props {
  isVisible?: boolean;
}

export const AddLocationFormWrapper = styled.div<Props>`
  transition: 0.7s;
  opacity: 0;
  visibility: hidden;
  height: 0;

  ${props =>
    props.isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      height: auto;
    `}
`;
