import { httpInsightsReporting } from 'src/core/services/http';
import { transformLoadMapboxInsights } from './transformLoadMapboxInsights';

export const loadCityInsightsMapbox = (date: Date | string, cityInsights?: string[]) =>
  httpInsightsReporting
    .get(`/dashboard/insights/city-insights`, {
      params: {
        date,
        cityInsights: cityInsights?.join(','),
      },
    })
    .then(response => transformLoadMapboxInsights(response.data));
