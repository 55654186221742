import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import { Button, Grid, GridColumn, Text } from 'src/core/components/styled';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
  createWarningNotification,
} from 'src/core/services/createNotification';
import { currency } from 'src/utils/services/formatter';
import { finalizePayment } from 'src/finance/ducks';
import { InvoiceDetails, PaymentMethod } from 'src/finance/interfaces/PaymentManagement';
import { PAYMENT_STATUS_PROCESSING, PAYMENT_STATUS_SUCCEEDED } from 'src/finance/constants/paymentStatuses';
import { PAYMENT_TYPE_US_BANK_ACCOUNT } from 'src/finance/constants/paymentMethod';
import { PayNowRouteParams } from './PayNowPageResolver';
import { UnconnectedDropdown } from 'src/core/components';
import translate from 'src/core/services/translate';
import { INVOICE_PAYMENT_STATUS_PROCESSING_ID } from 'src/finance/constants/invoicePaymentStatuses';
import { loadPaymentFeeEstimation, resetPaymentEstimationFeeData } from 'src/finance/ducks/paymentManagement';

interface PaymentSectionProps {
  addPaymentMethod: () => void;
  invoiceDetails: InvoiceDetails;
  paymentMethods: PaymentMethod[];
  totalAmountWithFees: number;
  hasProcessingFee: boolean;
}

const PaymentMethodSection = ({
  invoiceDetails,
  totalAmountWithFees,
  paymentMethods,
  addPaymentMethod,
  hasProcessingFee,
}: PaymentSectionProps) => {
  const dispatch = useDispatch();
  const { invoiceId } = useParams<PayNowRouteParams>();

  const paymentOptions = paymentMethods
    .filter(pm => pm.paymentConnectPaymentMethodId)
    .map(({ achBankName, achNumber, cardBrand, cardNumber, paymentConnectPaymentMethodId, type }) => {
      const label =
        type === PAYMENT_TYPE_US_BANK_ACCOUNT
          ? achBankName && `${achBankName.toUpperCase()} **** **** **** ${achNumber}`
          : cardBrand && `${cardBrand.toUpperCase()} **** **** **** ${cardNumber}`;

      return {
        label,
        value: paymentConnectPaymentMethodId,
      };
    });

  const [isSaving, setIsSaving] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string>(paymentOptions[0]?.value);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  useEffect(() => {
    if (paymentMethodId && hasProcessingFee) {
      const feeEstimationParams = {
        paymentConnectPaymentMethodId: paymentMethodId,
        inputAmount: invoiceDetails.balance,
      };
      dispatch(loadPaymentFeeEstimation(feeEstimationParams));
    }

    return () => {
      dispatch(resetPaymentEstimationFeeData());
    }
  }, [
    paymentMethodId,
    hasProcessingFee,
    invoiceDetails.balance,
    dispatch,
  ]);

  useEffect(() => {
    if (!totalAmount && invoiceDetails.balance) {
      setTotalAmount(invoiceDetails.balance);
    }

    if (totalAmountWithFees && totalAmount !== totalAmountWithFees) {
      setTotalAmount(totalAmountWithFees);
    }
  }, [totalAmount, invoiceDetails.balance, totalAmountWithFees]);

  const completePayment = async () => {
    const dataToSend = {
      amount: totalAmount,
      invoiceId,
      paymentConnectPaymentMethodId: paymentMethodId,
    };

    setIsSaving(true);

    try {
      const response = await finalizePayment(dataToSend)(dispatch);

      if (response?.status === PAYMENT_STATUS_PROCESSING) {
        createWarningNotification(translate('finance.alertMessages.paymentIsProcessing'), 10000);
        return;
      }

      if (PAYMENT_STATUS_SUCCEEDED !== response?.status) {
        throw new Error(response?.lastPaymentError);
      }

      createSuccessNotification(translate('finance.alertMessages.paymentApplied'));
    } catch (error) {
      createErrorNotificationIncludingTechnicalMessage(error, translate('finance.alertMessages.paymentSaveError'));
      setIsSaving(false);
    }
  };

  const disableButtons = isSaving ||
    totalAmount <= 0 ||
    invoiceDetails.paymentStatusId === INVOICE_PAYMENT_STATUS_PROCESSING_ID;

  return (
    <Grid padding="no">
      <GridColumn size="6/12">
        <UnconnectedDropdown
          options={paymentOptions}
          onChange={id => setPaymentMethodId(id)}
          label={translate('finance.paymentManagement.selectPaymentMethod')}
          width="100%"
          value={paymentMethodId}
          disabled={disableButtons}
        />
      </GridColumn>
      <GridColumn size="3/12" padding="small">
        <Button type="button" color="primary" line onClick={addPaymentMethod} disabled={disableButtons}>
          {translate('finance.paymentManagement.addPaymentMethod')}
        </Button>
      </GridColumn>
      <GridColumn size="3/12" flexDirection="column" padding="small no" marginLeft="auto">
        <Text size="xxLarge" block>
          {`${translate('finance.paymentManagement.amount')}: `}
          <Text size="xxLarge" color="primary">
            {currency(totalAmount)}
          </Text>
          {hasProcessingFee ? (
            <Text size="small" block>
              {translate('finance.paymentManagement.includesFees')}
            </Text>
          ) : null}
        </Text>
        <Button
          type="button"
          color="primary"
          margin="small no"
          padding="medium"
          onClick={completePayment}
          disabled={disableButtons}
        >
          {translate('finance.paymentManagement.completePayment')}
        </Button>
      </GridColumn>
    </Grid>
  );
};

export default PaymentMethodSection;
