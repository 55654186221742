import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useSelector } from 'src/core/hooks/useSelector';
import CreateCardPayment from './CreateCardPayment';
import { getUIConfig } from 'src/finance/ducks/paymentManagement';

interface Props {
  onCancel: () => void;
  returnToPreviousPage?: () => void;
}

const NewPaymentForm = (props: Props) => {
  const dispatch = useDispatch();

  const { stripeClientSecret: clientSecret, stripeAccountId: stripeAccount } = useSelector(
    state => state.finance.paymentManagement.stripePaymentData,
  );

  const { publishableKey: stripeKey } = useSelector(
    state => state.finance.paymentManagement.uiConfig,
  );

  useEffect(() => {
    dispatch(getUIConfig());
  }, [dispatch])

  const stripePromise = useMemo(() => {
    if (!stripeKey || !stripeAccount) return null;
    return loadStripe(stripeKey, { stripeAccount });
  }, [stripeKey, stripeAccount]);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
      }}
    >
      <CreateCardPayment {...props} />
    </Elements>
  );
};

export default NewPaymentForm;
