import styled from 'styled-components';

import { Devices } from '../../../core/styles';
import theme from '../../../core/styles/theme';

export const NewsComponentContainer = styled.div`
  display: block;
`;

export const NewsSection = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 47%;
  margin-right: 3%;
  @media ${Devices.laptop} {
    width: 100%;
    margin-bottom: 50px;
  }
`;
export const BlogSection = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 50%;
  @media ${Devices.laptop} {
    width: 100%;
  }
`;

export const NewsSectionTitle = styled.h1`
  font-size: 28px;
  color: ${theme.brandGreenDark};
  font-weight: 500;
  line-height: 34px;
  @media ${Devices.mobile} {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const NewsFeedAreaWrapper = styled.div`
  margin: 0 auto;
  height: auto;
  width: 1640px;
  padding: 50px 100px 80px;
  background: ${theme.grayDarkest};
  display: block;
  @media screen and (max-width: 1640px) {
    width: 100%;
  }

  @media ${Devices.mobile} {
    padding: 100px 30px 20px;
  }
`;

export const SocialMediaAreaWrapper = styled.div`
  width: 100%;
  background: ${theme.brandWhite};
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 30px 0;
`;

export const TwitterFeedAreaWrapper = styled.div`
  width: 50%;
  display: inline-block;
  @media screen and (max-width: 1640px) {
    padding: 0 30px;
  }
  @media ${Devices.laptop} {
    padding: 0 30px;
  }
  @media ${Devices.tablet} {
    margin: 0 0 30px;
    width: 100%;
  }
`;
