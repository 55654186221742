import { FC, useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { loadMaterialTrips } from 'src/routes/ducks';
import { Message, Panel, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { RouteTrackerForm } from '../../forms';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import MaterialTripsSummaryTableRow from './MaterialTripsSummaryTableRow';
import Table from 'src/core/components/Table';
import translate from 'src/core/services/translate';

interface Props {
  isLoadsDumped: boolean;
  isSnowPlowRoute: boolean;
  summaryPageTitle: string;
  vendorId: number;
}

const MaterialTripsSummaryPage: FC<Props> = ({ isLoadsDumped, isSnowPlowRoute, summaryPageTitle, vendorId }) => {
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const { materialTrips, isLoading } = useSelector(state => state.routes.materialTrips);

  const {
    startDate,
    endDate,
    vehicleTypeIds,
    searchTerm,
    routeStatusTypeIds,
    supervisors,
    serviceZones,
    groupIds,
    agingIntervalIds,
    priorityTypeIds,
  } = getQueryParams(search);

  const handleSubmit = useCallback(
    ({
      date,
      vehicleTypeIds,
      searchTerm,
      routeStatusTypeIds,
    }: {
      date: DateRangeOptionValue;
      vehicleTypeIds: number[];
      searchTerm: string;
      routeStatusTypeIds: number[];
    }) => {
      const { from, to } = date;

      dispatch(
        push(
          createUrl(pathname, undefined, {
            startDate: from,
            endDate: to,
            routeStatusTypeIds: routeStatusTypeIds && routeStatusTypeIds.toString(),
            vehicleTypeIds: vehicleTypeIds && vehicleTypeIds.toString(),
            searchTerm,
          }),
        ),
      );
    },
    [dispatch, pathname],
  );

  const handleBackAction = useCallback(() => {
    const searchParams = {
      startDate,
      endDate,
      vehicleTypeIds,
      searchTerm,
      routeStatusTypeIds,
      supervisors,
      groupIds,
      serviceZones,
      agingIntervalIds,
      priorityTypeIds,
    };

    const isSnowPlowRoute = Number(vehicleTypeIds) === SNOW_PLOW_ID;
    const routeUrl = isSnowPlowRoute ? '/routes/snow-tracker' : '/routes/sweeper-tracker';

    dispatch(push(createUrl(routeUrl, undefined, searchParams)));
  }, [
    dispatch,
    endDate,
    routeStatusTypeIds,
    searchTerm,
    groupIds,
    serviceZones,
    startDate,
    supervisors,
    vehicleTypeIds,
    agingIntervalIds,
    priorityTypeIds,
  ]);

  useEffect(() => {
    const searchParams = {
      startDate,
      endDate,
      vehicleTypeIds,
      searchTerm,
      routeStatusTypeIds,
      supervisors,
      groupIds,
      serviceZones,
      agingIntervalIds,
      priorityTypeIds,
      vendorId,
    };

    switch (pathname) {
      case '/routes/material-pickups-summary':
        loadMaterialTrips('materialPickupTickets', searchParams)(dispatch);
        break;

      case '/routes/disposals-summary':
        loadMaterialTrips('disposalTickets', searchParams)(dispatch);
        break;

      case '/routes/water-fills-summary':
        loadMaterialTrips('waterFillUpTickets', searchParams)(dispatch);
        break;

      default:
        break;
    }
  }, [
    dispatch,
    pathname,
    endDate,
    routeStatusTypeIds,
    searchTerm,
    groupIds,
    serviceZones,
    startDate,
    supervisors,
    vehicleTypeIds,
    agingIntervalIds,
    priorityTypeIds,
    vendorId,
  ]);

  const materialTripsSummaryTableCells = [
    { name: 'routeDate', label: translate('routes.routeDate'), width: isSnowPlowRoute ? '10%' : '14%', sortable: true },
    {
      name: 'ticketNumber',
      label: translate('common.ticketNumber'),
      width: '9%',
      sortable: true,
    },
    {
      name: 'vehicleName',
      label: translate('vehicles.vehicle'),
      width: isSnowPlowRoute ? '12%' : '16%',
      sortable: true,
    },
    { name: 'routeName', label: translate('routes.route'), width: isSnowPlowRoute ? '12%' : '16%', sortable: true },
    { name: 'materialTypeTechnicalName', label: translate('routes.material'), width: '9%', sortable: true },
    { name: 'materialAmount', label: translate('common.materialTickets.quantity'), width: '9%', sortable: true },
    {
      name: 'unitOfMeasureTypeTechnicalName',
      label: translate('common.materialTickets.uom'),
      width: '9%',
      sortable: true,
    },
    {
      name: 'activityTimeLocal',
      label: isLoadsDumped ? translate('common.date') : translate('common.materialTickets.pickupDate'),
      width: '11%',
      sortable: true,
    },
    {
      name: 'locationName',
      label: isSnowPlowRoute ? translate('common.facility') : translate('common.location'),
      width: isSnowPlowRoute ? '11%' : '16%',
      sortable: true,
    },
    {
      name: 'image',
      label: translate('common.image'),
      width: '8%',
      align: 'center',
    },
  ];

  if (!isSnowPlowRoute) {
    materialTripsSummaryTableCells.splice(1, 1);
    materialTripsSummaryTableCells.splice(8, 1);
  }

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction id="back-button" onClick={handleBackAction}>
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{summaryPageTitle}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      <Panel>
        <PanelSectionGroup isLoading={isLoading}>
          <RouteTrackerForm onSubmit={handleSubmit} isVehicleTypeFilterVisible={false} />
          <PanelSection>
            {materialTrips.length ? (
              <Table
                cells={materialTripsSummaryTableCells}
                rows={materialTrips}
                rowComponent={MaterialTripsSummaryTableRow}
                rowProps={{ isSnowPlowRoute }}
              />
            ) : (
              <Message padding="sMedium">{translate('insights.noReportsFound')}</Message>
            )}
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default MaterialTripsSummaryPage;
