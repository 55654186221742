import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import {
  MANUAL_STOP_ID,
  MANUAL_STOP,
  MOTION_STOP_ID,
  MOTION_STOP,
  MULTIPLE_TRIGGERS_ID,
  PROXIMITY_ID,
  PROXIMITY,
  STATIONARY_ID,
  STATIONARY,
  TRIGGER_MAP_LAYER,
  TRIGGERS_MAP_COLORS,
} from 'src/routes/constants/triggerMap';
import { Trigger } from 'src/routes/interfaces/Trigger';

export type TriggerMapFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  color: string;
  isSelected?: boolean;
  icon?: string;
};

export const getTriggerGeoJSON = (triggers: Trigger[]) =>
  getFeatureCollection<GeoJSON.Point, TriggerMapFeatureProperties>(
    triggers.map(trigger => {
      let triggerType = MULTIPLE_TRIGGERS_ID;

      if (trigger.triggers.length === 1) {
        switch (trigger.triggers[0].name) {
          case MANUAL_STOP:
            triggerType = MANUAL_STOP_ID;
            break;

          case PROXIMITY:
            triggerType = PROXIMITY_ID;
            break;

          case STATIONARY:
            triggerType = STATIONARY_ID;
            break;

          case MOTION_STOP:
            triggerType = MOTION_STOP_ID;
            break;
        }
      }

      return getPointFeature(trigger.orderNo, [trigger.longitude, trigger.latitude], {
        id: trigger.orderNo,
        clickable: true,
        layer: TRIGGER_MAP_LAYER,
        color: '#51bbd6',
        icon: TRIGGERS_MAP_COLORS[triggerType].iconType,
      });
    }),
  );
