import { mapKeys } from 'lodash-es';

const JANUARY = 'January';
const FEBRUARY = 'February';
const MARCH = 'March';
const APRIL = 'April';
const MAY = 'May';
const JUNE = 'June';
const JULY = 'July';
const AUGUST = 'Augutst';
const SEPTEMBER = 'September';
const OCTOBER = 'October';
const NOVEMBER = 'November';
const DECEMBER = 'December';

const MONTHS_SHORT = mapKeys(
  [
    { id: JANUARY, translationKey: 'common.monthsShort.january' },
    { id: FEBRUARY, translationKey: 'common.monthsShort.february' },
    { id: MARCH, translationKey: 'common.monthsShort.march' },
    { id: APRIL, translationKey: 'common.monthsShort.april' },
    { id: MAY, translationKey: 'common.monthsShort.may' },
    { id: JUNE, translationKey: 'common.monthsShort.june' },
    { id: JULY, translationKey: 'common.monthsShort.july' },
    { id: AUGUST, translationKey: 'common.monthsShort.august' },
    { id: SEPTEMBER, translationKey: 'common.monthsShort.september' },
    { id: OCTOBER, translationKey: 'common.monthsShort.october' },
    { id: NOVEMBER, translationKey: 'common.monthsShort.november' },
    { id: DECEMBER, translationKey: 'common.monthsShort.december' },
  ],
  'id',
);

export default MONTHS_SHORT;
