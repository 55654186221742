import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorId } from '../../services/currentVendorSelector';
import { loadVehicleTrackingSettings, loadNavigationLineTypes } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import VehicleTrackingModal from './VehicleTrackingModal';

type Props = {
  closeModal: () => void;
};

export default function VehicleTrackingModalResolver({ closeModal }: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const loadDependencies = () => {
    return Promise.all([loadVehicleTrackingSettings(vendorId)(dispatch), loadNavigationLineTypes()(dispatch)]);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={VehicleTrackingModal}
      successProps={{ closeModal, vendorId }}
    />
  );
}
