import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { createSelector } from 'reselect';
import {
  loadRouteImportDetails as doLoadRouteImportDetails,
  saveRouteImportDetails as doSaveRouteImportDetails,
} from '../services/routeImportDetails';

// Actions
const START_LOAD = 'routeImport/routeImportDetails/START_LOAD';
const COMPLETE_LOAD = 'routeImport/routeImportDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'routeImport/routeImportDetails/FAIL_LOAD';
const START_SAVE = 'routeImport/routeImportDetails/START_SAVE';
const COMPLETE_SAVE = 'routeImport/routeImportDetails/COMPLETE_SAVE';
const FAIL_SAVE = 'routeImport/routeImportDetails/FAIL_SAVE';
const RESET = 'routeImport/routeImportDetails/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  routeImportDetails: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeImportDetails: action.routeImportDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeImportDetails: any) => ({
  type: COMPLETE_LOAD,
  routeImportDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadRouteImportDetails = (routeImportDetailId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadRouteImportDetailsPromise = doLoadRouteImportDetails(routeImportDetailId);

  loadRouteImportDetailsPromise
    .then(routeImportDetails => dispatch(completeLoad(routeImportDetails)))
    .catch(() => dispatch(failLoad()));

  return loadRouteImportDetailsPromise;
};

export const saveRouteImportDetails = (routeImportDetailId: number, routeImportDetails: any) => (
  dispatch: Dispatch,
) => {
  dispatch(startSave());

  const saveRouteImportDetailsPromise = doSaveRouteImportDetails(routeImportDetailId, routeImportDetails);

  saveRouteImportDetailsPromise.then(routeImportDetails => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveRouteImportDetailsPromise;
};

export const resetRouteImportDetails = () => ({
  type: RESET,
});

// Selectors
const getVendorId = (routeImportDetailsState: any) => get(routeImportDetailsState, 'routeImportDetails.vendorId');

export const vendorIdSelector = createSelector(getVendorId, identity);

const getRouteImportId = (routeImportDetailsState: any) =>
  get(routeImportDetailsState, 'routeImportDetails.vendorRouteImportId');

export const routeImportIdSelector = createSelector(getRouteImportId, identity);

const getRouteImportStatusTypeId = (routeImportDetailsState: any) =>
  get(routeImportDetailsState, 'routeImportDetails.vendorRouteImportStatusTypeId');

export const routeImportStatusTypeIdSelector = createSelector(getRouteImportStatusTypeId, identity);
