import React, { ChangeEvent } from 'react';

import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { isDateValidValidator } from 'src/utils/services/validator';

import { DatePicker, Dropdown } from '../../../core/components';
import { DropdownOption } from '../../../core/components/Dropdown';
import { Grid, GridColumn } from '../../../core/components/styled';
import { TODAY } from '../../../core/constants';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  handleNumberOfRoutesChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleRouteDateChange: (e: any, date: Date | string) => void;
  numberOfRoutesOptions: DropdownOption[];
}

interface FormData {
  numberOfRoutes: number;
  routeDate: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const DispatchBoardRouteBuilderOptionsForm = (props: Props) => {
  const { handleNumberOfRoutesChange, handleRouteDateChange, numberOfRoutesOptions } = props;

  return (
    <form onSubmit={e => e.preventDefault()} id="dispatchBoardRouteBuilderOptionsForm">
      <Grid margin="no no small">
        <GridColumn size="2/12" />
        <GridColumn size="4/12">
          <Field
            name="numberOfRoutes"
            component={Dropdown}
            margin="no"
            options={numberOfRoutesOptions}
            label={translate('dispatchBoard.routeBuilder.numberOfRoutes')}
            onChange={handleNumberOfRoutesChange}
          />
        </GridColumn>
        <GridColumn size="4/12">
          <Field
            component={DatePicker}
            disabledDays={[{ before: TODAY }]}
            label={translate('dispatchBoard.routeBuilder.routeDate')}
            margin="no"
            name="routeDate"
            onChange={handleRouteDateChange}
            validate={[isDateValidValidator]}
          />
        </GridColumn>
        <GridColumn size="2/12" />
      </Grid>
    </form>
  );
};

export default reduxForm<FormData, PropsWithoutReduxForm>({
  form: 'dispatchBoardRouteBuilderOptionsForm',
  enableReinitialize: true,
})(DispatchBoardRouteBuilderOptionsForm);
