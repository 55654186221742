import { useDispatch } from 'react-redux';

import { MapGLPopup, MapGLPopupContent, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { ContainerFacility } from 'src/fleet/interfaces/containers';
import { Grid, GridColumn } from 'src/core/components/styled';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';
import { Facility } from 'src/common/interfaces/Facility';

type Props = {
  facility: ContainerFacility | Facility;
};

export default function FacilityPopup({ facility }: Props) {
  const dispatch = useDispatch();

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={facility.address.latitude}
      longitude={facility.address.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupContent>
          <Grid multiLine padding="small">
            <GridColumn size="12/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('facilities.facilityName')}
                value={facility.name}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('facilities.facilityType')}
                value={facility.facilityType.name}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('facilities.facilitySubType')}
                value={facility.facilitySubType.name}
              />
            </GridColumn>
            <GridColumn size="12/12" margin="xxSmall no">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.address')}
                value={facility.address.line1}
              />
            </GridColumn>
          </Grid>
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
