import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { map } from 'lodash-es';

import { createErrorNotification } from '../../../../core/services/createNotification';
import confirm from '../../../../core/services/confirm';
import { dismissErrors, loadServiceRequestErrors } from '../../../ducks/open311ConfigErrors';
import ServiceRequestErrorsTableRow from './ServiceRequestErrorsTableRow';
import { Table, UnconnectedCheckbox } from 'src/core/components';
import { Button, ButtonSet, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';

export default function ServiceRequestErrorsSection() {
  const dispatch = useDispatch();
  const serviceRequestErrors = useSelector(state => state.vendors.open311ConfigErrors.serviceRequestErrors || []);
  const [allErrorsChecked, setAllErrorsChecked] = React.useState<boolean>(false);
  const [selectedErrors, setSelectedErrors] = React.useState<number[]>([]);

  const checkAllConfigError = () => {
    const configErrorList: number[] = [];
    serviceRequestErrors.forEach(configError => {
      if (selectedErrors.length === 0) {
        configErrorList.push(configError.id);
      }
    });

    setAllErrorsChecked(!allErrorsChecked);
    setSelectedErrors(configErrorList);
  };

  const checkConfigError = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const updatedSelectedErrors = [...selectedErrors];
    const indexDelete = updatedSelectedErrors.indexOf(id);
    if (indexDelete > -1) {
      updatedSelectedErrors.splice(indexDelete, 1);
    } else {
      updatedSelectedErrors.push(id);
    }
    setSelectedErrors(updatedSelectedErrors);
  };

  const getServiceRequestsErrorTableCells = [
    {
      name: 'selectAll',
      component: UnconnectedCheckbox,
      componentProps: {
        onChange: checkAllConfigError,
        checked: selectedErrors.length === serviceRequestErrors.length,
        partial: 0 < selectedErrors.length && selectedErrors.length < serviceRequestErrors.length,
      },
      width: '5%',
      padding: 'defaultCellVertical xSmall',
    },
    {
      name: 'date',
      label: translate('vendors.open311.createdOn'),
      width: '25%',
      sortable: false,
    },
    {
      name: 'address',
      label: translate('vendors.open311.serviceRequestId'),
      width: '25%',
      sortable: false,
    },
    {
      name: 'reason',
      label: translate('vendors.open311.error'),
      width: '45%',
      sortable: false,
    },
  ];

  const serviceRequestErrorsList = map(serviceRequestErrors, requestError => {
    return {
      ...requestError,
      isChecked: selectedErrors.includes(requestError.id),
    };
  });

  const dismissError = async () => {
    if (!(await confirm(translate('vendors.open311.alertMessages.dismissErrorMessage')))) {
      return;
    }
    dismissErrors(
      1157,
      selectedErrors,
    )(dispatch)
      .then(() => {
        loadServiceRequestErrors(1157)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.open311.alertMessages.serviceRequestDismissError'));
      });
  };
  return (
    <>
      {serviceRequestErrors.length ? (
        <Table
          cells={getServiceRequestsErrorTableCells}
          rows={serviceRequestErrorsList}
          rowProps={{
            checkConfigError: checkConfigError,
          }}
          rowComponent={ServiceRequestErrorsTableRow}
        />
      ) : (
        <Text block align="center">
          {translate('vendors.open311.noServiceRequestErrors')}
        </Text>
      )}
      {!!serviceRequestErrors.length && (
        <ButtonSet margin="sMedium no">
          <Button type="button" color="alert" line onClick={dismissError}>
            {translate('vendors.open311.dismissError')}
          </Button>
        </ButtonSet>
      )}
    </>
  );
}
