import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadVehicleBreadCrumbsByRoutes, loadVehiclesFullTrackings } from 'src/dashboard/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import MapModal from './MapModal';

interface Props {
  closeModal: () => void;
}

const MapModalResolver = ({ closeModal }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const { date, vehicleId, isSourceRoute } = useSelector(state => state.dashboard.mapControls.mapModalData);

  const loadDependencies = async () => {
    if (!vendorId || !vehicleId || !date) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [];
    if (!isSourceRoute) dependencies.push(loadVehicleBreadCrumbsByRoutes(vehicleId, date)(dispatch));
    else dependencies.push(loadVehiclesFullTrackings(vehicleId, date)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={MapModal}
      successProps={{ closeModal }}
      loadingComponent={PageLoadingOverlay}
      onError={closeModal}
      resolve={loadDependencies}
    />
  );
};

export default MapModalResolver;
