import styled, { css } from 'styled-components';
import { InputStyle } from '.';

const TextArea = styled.textarea<{ noResize?: boolean; scrollYVisible?: boolean }>`
  ${InputStyle};
  resize: ${props => (props.noResize ? 'none' : 'vertical')};
  ${props =>
    props.scrollYVisible &&
    css`
      overflow-y: scroll;
    `};
`;

export default TextArea;
