import React, { useMemo, useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { debounce, head, last } from 'lodash-es';

import { VehicleTracking, TimelineTracking, StationaryTimeMap, TripTimeDetails } from './Interfaces';
import {
  renderRouteTimeline,
  renderTimelineLegendItem,
  getTimelineLegendItems,
} from '../../../insights/services/reportingDetailsOptions';
import { Play, FastForward, FastReverse } from './MediaControls';

import {
  normalizeTrackingDistribution,
  getStationaryTimeInfo,
  getTimelineUnitWidth,
  timelineWidth,
  getAccurateTimelineUnitWidth,
} from './TimelineUtils';
import { time } from '../../../utils/services/formatter';
import { useInterval } from '../../../utils/hooks';
import {
  TimelineContainer,
  Timeline,
  TimelineCursor,
  TimelineCursorInnerContainer,
  Legend,
  TimelineControlsContainer,
} from '../styled/MapWithTimeline';
import { StationaryTimeTimelineMarker } from './StationaryTimeIndicator';
import translate from '../../../core/services/translate';

interface MapTimelineProps {
  runTimeline: (vehicleTracking: TimelineTracking) => void;
  tripTimeDetails: TripTimeDetails;
  vehicleTracking: VehicleTracking;
}

export const renderStationaryTime = (stationaryTimeMap: StationaryTimeMap, totalTripTimeInSeconds: number) => (
  <>
    {Object.keys(stationaryTimeMap)
      .map(Number)
      .map(k => (
        <StationaryTimeTimelineMarker
          stationaryTimeStartIndex={k}
          stationaryTimeMap={stationaryTimeMap}
          key={k}
          timelineUnitWidth={getTimelineUnitWidth(totalTripTimeInSeconds)}
        />
      ))}
  </>
);

export const renderMapLegend = (
  totalStationaryTime: number,
  isSnowPlowRoute?: boolean,
  isStreetSweeperRoute?: boolean,
) => (
  <Legend>
    {getTimelineLegendItems(totalStationaryTime, isSnowPlowRoute, isStreetSweeperRoute).map(renderTimelineLegendItem)}
  </Legend>
);

export const MapTimeline: React.SFC<MapTimelineProps> = ({ vehicleTracking, runTimeline, tripTimeDetails }) => {
  const [isTimelineRunning, setIsTimelineRunning] = useState(false);
  const [currentVehiclePositionIndex, setCurrentVehiclePositionIndex] = useState<number>(0);
  const [isFastForward, setIsFastForward] = useState(false);
  const [isFastReverse, setIsFastReverse] = useState(false);

  const vehicleTrackings: TimelineTracking[] = useMemo(
    () =>
      normalizeTrackingDistribution(
        vehicleTracking,
        head(tripTimeDetails.tripTimeDetails)
          ? head(tripTimeDetails.tripTimeDetails).startTime
          : vehicleTracking.coordinateGroups[0].startTimestamp,
        last(tripTimeDetails.tripTimeDetails)
          ? last(tripTimeDetails.tripTimeDetails).endTime
          : vehicleTracking.coordinateGroups[vehicleTracking.coordinateGroups.length - 1].endTimestamp,
      ),
    [vehicleTracking, tripTimeDetails],
  );

  const stationaryTimeInfo = useMemo(() => {
    const routeStartTime =
      tripTimeDetails.tripTimeDetails && tripTimeDetails.tripTimeDetails.length
        ? tripTimeDetails.tripTimeDetails[0].startTime
        : vehicleTrackings[0].timestamp;
    return getStationaryTimeInfo(tripTimeDetails.tripTimeIncidents, routeStartTime);
  }, [tripTimeDetails, vehicleTrackings]);

  useEffect(() => {
    moveVehicle(currentVehiclePosition, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(
    () => {
      let ammount = 1;
      if (isFastReverse) {
        ammount = -5;
      } else if (isFastForward) {
        ammount = 5;
      }
      let newVehiclePosition = currentVehiclePositionIndex + ammount;
      if (newVehiclePosition < 0 || newVehiclePosition >= vehicleTrackings.length) {
        newVehiclePosition = 0;
        setIsTimelineRunning(false);
      }
      moveVehicle(vehicleTrackings[newVehiclePosition], newVehiclePosition);
    },
    isTimelineRunning ? 100 : null,
  );

  const currentVehiclePosition = vehicleTrackings[currentVehiclePositionIndex];

  const timelineUnitWidth = getAccurateTimelineUnitWidth(vehicleTrackings.length);

  const moveVehicle = (vehicleTracking: TimelineTracking, vehicleTrackingIndex: number) => {
    runTimeline(vehicleTracking);
    setCurrentVehiclePositionIndex(vehicleTrackingIndex);
  };

  const moveCursorOnTimeline = (position: number) => {
    const tracking = vehicleTrackings[position];
    if (tracking) {
      moveVehicle(vehicleTrackings[position], position);
    }
  };

  const getPosition = (x: number) => {
    const trackingWidth = timelineWidth / vehicleTrackings.length;
    return Math.round(x / trackingWidth);
  };

  const moveVehicleDebounced = useRef(
    debounce((x: number) => {
      moveCursorOnTimeline(getPosition(x));
    }, 200),
  ).current;

  const moveToTimelineClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const xPosition = event.clientX - event.currentTarget.getBoundingClientRect().left;
    if (isTimelineRunning) {
      setIsTimelineRunning(false);
    }
    moveCursorOnTimeline(getPosition(xPosition));
  };

  const fastForward = () => {
    setIsFastReverse(false);
    setIsFastForward(true);
    setIsTimelineRunning(true);
  };

  const fastReverse = () => {
    setIsFastForward(false);
    setIsFastReverse(true);
    setIsTimelineRunning(true);
  };

  const playPause = () => {
    setIsFastReverse(false);
    setIsFastForward(false);
    setIsTimelineRunning(!isTimelineRunning);
  };

  return !vehicleTrackings.length || !currentVehiclePosition ? (
    <TimelineContainer center width={timelineWidth}>
      {translate('routeTimeline.vehicleTrackingsNotFound')}
    </TimelineContainer>
  ) : (
    <TimelineContainer width={timelineWidth}>
      <Timeline onClick={moveToTimelineClick}>
        <Draggable
          bounds={{ left: 0, right: timelineWidth - 2, top: 0, bottom: 0 }}
          onDrag={(e, data) => {
            if (isTimelineRunning) {
              setIsTimelineRunning(false);
            }
            moveVehicleDebounced(data.x);
          }}
          position={{ x: timelineUnitWidth * currentVehiclePositionIndex, y: 0 }}
          axis="x"
        >
          <TimelineCursor>
            <TimelineCursorInnerContainer>
              <span>{time(currentVehiclePosition.timestamp)}</span>
              <div></div>
            </TimelineCursorInnerContainer>
          </TimelineCursor>
        </Draggable>
        {renderRouteTimeline(tripTimeDetails.totalTripTime, tripTimeDetails.tripTimeDetails, 35)}
        {renderStationaryTime(stationaryTimeInfo.map, tripTimeDetails.totalTripTime)}
      </Timeline>
      {renderMapLegend(stationaryTimeInfo.total)}
      <TimelineControlsContainer>
        <FastReverse onClick={fastReverse} id="fast-reverse-button" />
        <Play isPlaying={isTimelineRunning} onClick={playPause} id="play-button" />
        <FastForward onClick={fastForward} id="fast-forward-button" />
      </TimelineControlsContainer>
    </TimelineContainer>
  );
};
