import humps from 'humps';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { ROUTE_MAP_CITY_INSIGHTS_LAYER } from '../constants';
import { RouteMapCityInsight } from 'src/routes/interfaces/RouteMapCityInsights';
import store from 'src/store';

export const getCityInsightId = (cityInsight: RouteMapCityInsight) =>
  Number(cityInsight.mapSelectorId.replace('S:', ''));

export type CityInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getCityInsightIconType = (cityInsight: RouteMapCityInsight) => {
  const state = store.getState();
  const { locationFlagTypes } = state.common.locationFlagTypes;
  const locationFlagType = locationFlagTypes.find(
    locationFlagType => locationFlagType.id === cityInsight.locationFlaggingTypeId,
  );
  const insightType = humps.camelize(locationFlagType?.technicalName || '');
  const iconType = INSIGHT_ICON_TYPES.find(({ types }) => types.indexOf(insightType) !== -1);
  return iconType;
};

export const getCityInsightsGeoJSON = (cityInsights: RouteMapCityInsight[]) =>
  getFeatureCollection<GeoJSON.Point, CityInsightFeatureProperties>(
    cityInsights.map(cityInsight =>
      getPointFeature(getCityInsightId(cityInsight), [cityInsight.longitude, cityInsight.latitude], {
        id: getCityInsightId(cityInsight),
        clickable: true,
        layer: ROUTE_MAP_CITY_INSIGHTS_LAYER,
        icon: getCityInsightIconType(cityInsight)?.id,
      }),
    ),
  );
