import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { SupervisorsState, Supervisor } from '../interfaces/Supervisors';
import * as Services from '../services/supervisors';

// Actions
const SET_SUPERVISORS = 'routes/supervisors/SET_SUPERVISORS';
const SET_SUPERVISORS_LOADING = 'routes/supervisors/SET_SUPERVISORS_LOADING';

// Initial state
const initialState: SupervisorsState = {
  supervisors: [],
  supervisorsLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SUPERVISORS:
      return update(state, {
        $merge: {
          supervisors: action.supervisors,
        },
      });

    case SET_SUPERVISORS_LOADING:
      return update(state, {
        $merge: {
          supervisorsLoading: action.loading,
        },
      });

    default:
      return state;
  }
};

// Action creators
const setSupervisors = (supervisors: Supervisor[]) => ({
  type: SET_SUPERVISORS,
  supervisors,
});

const setSupervisorsLoading = (loading: boolean) => ({
  type: SET_SUPERVISORS_LOADING,
  loading,
});

export const loadSupervisors = (vendorId: number, date?: Date | string) => (dispatch: Dispatch) => {
  dispatch(setSupervisorsLoading(true));
  const promise = Services.loadSupervisors(vendorId, date);
  promise
    .then(supervisors => {
      dispatch(setSupervisors(supervisors));
    })
    .finally(() => {
      dispatch(setSupervisorsLoading(false));
    });

  return promise;
};
