import React, { ChangeEvent } from 'react';
import { filter, map, toLower } from 'lodash-es';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { AVAILABILITY_ID, availableLabel, unavailableLabel } from 'src/fleet/components/utils/vehiclesPageHooks';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { NONE_ID } from '../constants/dispatchBoard';
import { Supervisor } from '../interfaces/Supervisors';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  excludeSupervisorsIds?: number[];
  shouldGroupByAvailability?: boolean;
  supervisors: Supervisor[];
}

const SupervisorDropdown: React.FC<Props> = ({
  dropdownProps,
  excludeSupervisorsIds,
  input: { name, onChange },
  label,
  placeholder,
  shouldGroupByAvailability,
  supervisors,
  withLabel,
  withPlaceholder,
}) => {
  const onChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    onChange(value);
  };

  const supervisorsOptions =
    excludeSupervisorsIds && excludeSupervisorsIds.length
      ? filter(
          supervisors,
          supervisor => !excludeSupervisorsIds.includes(supervisor.id) && supervisor.id !== NONE_ID,
        ).map(supervisor => ({
          label: `${supervisor.firstName} ${supervisor.lastName}`,
          value: supervisor.id,
          isAvailable: supervisor.isAvailable,
        }))
      : map(
          filter(supervisors, sup => sup.id !== NONE_ID),
          supervisor => ({
            label: `${supervisor.firstName} ${supervisor.lastName}`,
            value: supervisor.id,
            isAvailable: supervisor.isAvailable,
          }),
        );

  let allSupervisorsOptions = [];

  if (shouldGroupByAvailability) {
    const supervisorsAvailable = supervisorsOptions.filter(vehicle => vehicle.isAvailable);
    const supervisorsUnavailable = supervisorsOptions.filter(vehicle => !vehicle.isAvailable);

    const allSupervisorsAvailable = supervisorsAvailable.map(({ label, value }) => ({
      label: label,
      value: value,
      isDisabled: false,
    }));
    const allSupervisorsUnavailable = supervisorsUnavailable.map(({ label, value, isAvailable }) => ({
      label: label,
      value: value,
      isDisabled: true,
      isActive: isAvailable,
    }));

    allSupervisorsOptions = !!supervisorsUnavailable.length
      ? [availableLabel, ...allSupervisorsAvailable, unavailableLabel, ...allSupervisorsUnavailable]
      : [...allSupervisorsAvailable, ...allSupervisorsUnavailable];
  } else {
    allSupervisorsOptions = supervisorsOptions;
  }

  const filterOption = (option: any, input: any) => {
    return option.data.value === AVAILABILITY_ID || toLower(option.label).includes(toLower(input));
  };

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChangeValue}
      props={{
        label: label || (withLabel ? translate('routes.supervisor') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('routes.supervisor') : undefined),
        options: allSupervisorsOptions,
        isClearable: true,
        filterOption,
        ...dropdownProps,
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  supervisors: state.routes.supervisors.supervisors || [],
});

export default connect(mapStateToProps)(SupervisorDropdown);
