import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_LAYER,
  DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE,
  DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_TWO_WAY_LAYER,
} from 'src/dashboard/constants/dashboardMapGL';
import { AlternativeFleetStreetSegmentFeature } from 'src/dashboard/interfaces/alterativeFleetOps';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString, AlternativeFleetStreetSegmentFeature>;
};

export default memo(function DashboardSnowSweeperStreetSegmentsGLSource({ geoJSON }: Props) {
  return (
    <Source type="geojson" id={DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id={DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_LAYER}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE}
        paint={{
          'line-color': ['case', ['coalesce', ['feature-state', 'selected'], false], '#ff5252', ['get', 'color']],
          'line-width': 6,
          'line-opacity': ['case', ['coalesce', ['feature-state', 'selected'], false], 1, ['get', 'opacity']],
          'line-offset': ['get', 'lineOffset'],
        }}
        filter={['all', ['!=', ['get', 'hasForwardPass'], false]]}
      />
      <Layer
        id={DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_TWO_WAY_LAYER}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE}
        filter={['all', ['!=', ['get', 'hasReversePass'], false]]}
        paint={{
          'line-color': [
            'case',
            ['coalesce', ['feature-state', 'selected'], false],
            '#ff5252',
            ['get', 'reverseSegmentColor'],
          ],
          'line-width': 6,
          'line-opacity': ['case', ['coalesce', ['feature-state', 'selected'], false], 1, ['get', 'opacity']],
          'line-offset': ['get', 'lineOffsetNegative'],
        }}
      />
    </Source>
  );
});
