import { useDispatch } from 'react-redux';

import { AppState } from 'src/store';
import translate from 'src/core/services/translate';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { timeStamp } from 'src/utils/services/formatter';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
} from 'src/common/components/map/MapGLPopup';
import { useSelector } from 'src/core/hooks/useSelector';
import { APPLICATION_STATUSES } from 'src/common/constants';
import { getSpeedInformation } from 'src/common/services/getSpeedInformation';
import LabeledDataView from 'src/core/components/LabeledDataView';

export default function RouteMapDebugVehicleTrackingsGLPopup() {
  const dispatch = useDispatch();
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { unitOfMeasure } = useSelector(state => state.routes.routeMapVehicleData);
  const vehicleNames = useSelector((state: AppState) => state.routes.routeMapDebug.vehicleNames);
  if (
    !selectedFeature ||
    selectedFeature.feature !== RouteMapFeature.vehicleDebugTrackings ||
    !selectedFeature.coordinates ||
    !selectedFeature.coordinateGroup
  )
    return null;

  const meta = JSON.parse(selectedFeature.coordinateGroup);

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedFeature.coordinates.lat}
      longitude={selectedFeature.coordinates.lng}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle title={`${vehicleNames[selectedFeature.id]} (${selectedFeature.id})`} />

        <MapGLPopupContent>
          {!!meta.endTimestamp && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.timestamp')}
              value={timeStamp(meta.endTimestamp)}
            />
          )}

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.appStatus')}
            value={APPLICATION_STATUSES[meta.applicationModeStatusId].name}
          />

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.speed')}
            value={getSpeedInformation(meta.speed, unitOfMeasure)}
          />
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
