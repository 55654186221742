import React, { useEffect, useState } from 'react';

import { size } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../../store';
import { Button, ButtonSet, Message, PanelSection } from '../../../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../../../core/services/createNotification';
import { deleteWeightTicket, loadRoute } from '../../../../ducks';
import { PermissionGuard } from '../../../../../account/components';
import { ROLL_OFF } from '../../../../../fleet/constants';
import { ROUTES_TRACKER_VIEW_ADD_WEIGHT_TICKET } from '../../../../../account/constants';
import { Table } from '../../../../../core/components';
import { technicalNameByVehicleTypeIdSelector } from '../../../../../fleet/ducks';
import { WeightTicketEditorModal } from '../../../modals';
import confirm from '../../../../../core/services/confirm';
import translate from '../../../../../core/services/translate';
import WeightTicketsTableRow from './WeightTicketsTableRow';

const weightTicketsRollOffTableCells = [
  { name: 'ticketNumber', label: translate('routes.ticketNumber'), width: '9%' },
  { name: 'locationId', label: translate('common.disposalSite'), width: '18%' },
  { name: 'wasteType', label: translate('common.wasteType'), width: '12%' },
  { name: 'weight', label: translate('common.weight'), width: '8%' },
  { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '8%' },
  { name: 'activityTimeLocal', label: translate('common.weightTickets.disposalDate'), width: '13%' },
  { name: 'customer', label: translate('common.customer'), width: '14%' },
  { name: 'images', label: translate('common.image'), width: '8%' },
  { name: 'options', label: translate('common.options'), width: '10%', align: 'right' },
];

const weightTicketsOtherTableCells = [
  { name: 'ticketNumber', label: translate('routes.ticketNumber'), width: '9%' },
  { name: 'locationId', label: translate('common.disposalSite'), width: '32%' },
  { name: 'wasteType', label: translate('common.wasteType'), width: '12%' },
  { name: 'weight', label: translate('common.weight'), width: '8%' },
  { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '8%' },
  { name: 'activityTimeLocal', label: translate('common.weightTickets.disposalDate'), width: '13%' },
  { name: 'images', label: translate('common.image'), width: '8%' },
  { name: 'options', label: translate('common.options'), width: '10%', align: 'right' },
];

export default function WeightTickets({ onLoadTab }: { onLoadTab?: () => void }) {
  useEffect(() => {
    !!onLoadTab && onLoadTab();
  }, [onLoadTab]);

  const dispatch = useDispatch();

  const routeId = useSelector((state: AppState) => (state.routes.route.route as any)?.id);
  const { isDeleting, weightTickets: weightTicketsTableRows } = useSelector(
    (state: AppState) => state.routes.weightTickets,
  );
  const vehicleTypeTechnicalName = useSelector((state: AppState) =>
    technicalNameByVehicleTypeIdSelector(
      state.fleet.vehicleTypesForVendor,
      (state.routes.route.route as any).vehicleTypeId,
    ),
  );

  const weightTicketsTableCells =
    vehicleTypeTechnicalName === ROLL_OFF ? weightTicketsRollOffTableCells : weightTicketsOtherTableCells;

  const [isWeightTicketEditorModalOpen, setIsWeightTicketEditorModalOpen] = useState(false);
  const [selectedWeightTicketId, setSelectedWeightTicketId] = useState<number | undefined>();

  const openWeightTicketEditorModal = (weightTicketId: number | undefined) => {
    setIsWeightTicketEditorModalOpen(true);
    setSelectedWeightTicketId(weightTicketId);
  };

  const closeWeightTicketEditorModal = async (formPristine?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    setIsWeightTicketEditorModalOpen(false);
    setSelectedWeightTicketId(undefined);
  };

  const removeWeightTicket = async (weightTicketId: number) => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteWeightTicket')))) {
      return;
    }

    deleteWeightTicket(weightTicketId)(dispatch)
      .then(() => {
        loadRoute(routeId)(dispatch);
        createSuccessNotification(translate('routes.alertMessages.weightTicketDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.weightTicketDeleteError'));
      });
  };

  return (
    <React.Fragment>
      <PanelSection vertical isLoading={isDeleting} id={`route-${routeId}-weight-tickets-form`}>
        {!!size(weightTicketsTableRows) && (
          <Table
            cells={weightTicketsTableCells}
            rows={weightTicketsTableRows}
            rowComponent={WeightTicketsTableRow}
            rowProps={{
              isRollOff: vehicleTypeTechnicalName === ROLL_OFF,
              routeId,
              editWeightTicket: openWeightTicketEditorModal,
              deleteWeightTicket: removeWeightTicket,
            }}
          />
        )}

        {!size(weightTicketsTableRows) && <Message padding="sMedium">{translate('routes.noWeightTickets')}</Message>}

        <ButtonSet margin="sMedium no">
          <PermissionGuard permission={ROUTES_TRACKER_VIEW_ADD_WEIGHT_TICKET}>
            <Button
              id={`route-${routeId}-weight-tickets-add-button`}
              type="button"
              color="primary"
              line
              onClick={() => openWeightTicketEditorModal(undefined)}
            >
              + {translate('routes.addWeightTicket')}
            </Button>
          </PermissionGuard>
        </ButtonSet>

        {isWeightTicketEditorModalOpen && (
          <WeightTicketEditorModal
            routeId={routeId}
            weightTicketId={selectedWeightTicketId}
            isRollOff={vehicleTypeTechnicalName === ROLL_OFF}
            onClose={closeWeightTicketEditorModal}
          ></WeightTicketEditorModal>
        )}
      </PanelSection>
    </React.Fragment>
  );
}
