import moment from 'moment';

import { dateFormat } from 'src/utils/services/validator';
import { Driver } from '../interfaces/Driver';
import { http } from '../../core/services/http';
import { OPERATIONAL_FACILITY } from 'src/common/constants';
import { TODAY } from 'src/core/constants';

export const loadDriver = (driverId: number) => http.get(`drivers/${driverId}`).then(response => response.data.driver);

export const uploadDriverProfileImage = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return http
    .post('drivers/profileImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};

export const createDriver = (driver: Partial<Driver>) => http.post(`vendors/${driver.vendorId}/drivers`, driver);

export const updateDriver = (driver: Partial<Driver>) => http.put(`drivers/${driver.id}`, driver);

export const deleteDriver = (driverId: number) => http.delete(`drivers/${driverId}`).then(response => response.data);

export const loadDriverFacilites = (vendorId: number) =>
  http
    .get('facilities', {
      params: {
        vendorId,
        facilityTypeId: OPERATIONAL_FACILITY,
      },
    })
    .then(response => response.data);

export const deleteResources = (vendorId: number, deletedResourceIds: number[]) =>
  http
    .delete(`/vendors/${vendorId}/drivers`, {
      params: {
        driverIds: deletedResourceIds.toString(),
      },
    })
    .then(response => response.data);

export const assignResources = (vendorId: number, resources: Driver[]) =>
  http.put(`vendors/${vendorId}/drivers`, resources);

export const checkSupervisorsHaveRoutesInRange = (
  vendorId: number,
  supervisorIds: number[],
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get<any[]>(`vendors/${vendorId}/supervisorsHaveRoutesInRange`, {
      params: {
        supervisorIds: supervisorIds.join(','),
        startDate: startDate ? moment(startDate).format(dateFormat) : TODAY,
        endDate: endDate ? moment(endDate).format(dateFormat) : undefined,
      },
    })
    .then(response => response.data);
