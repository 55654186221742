import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { Button, ButtonSet, Text } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { deleteWeightTicket, loadServiceDetails, loadSnowSweeperServiceDetails } from 'src/routes/ducks';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { loadDisposalTickets } from 'src/routes/ducks/disposals';
import { loadRouteStops } from 'src/routes/ducks';
import { PermissionGuard } from 'src/account/components';
import { ROLL_OFF_ID, SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { ROUTES_TRACKER_VIEW_ADD_WEIGHT_TICKET } from 'src/account/constants';
import { Table } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import DisposalsTableRow from './DisposalsTableRow';
import translate from 'src/core/services/translate';
import WeightTicketEditorModalResolver from '../WeightTicketEditorModalResolver';

export const getDisposalsTableCellWidths = (vehicleTypeId: number) =>
  vehicleTypeId === ROLL_OFF_ID
    ? ['10%', '13%', '10%', '10%', '9%', '8%', '13%', '10%', '8%', '9%']
    : ['9%', '16%', '14%', '12%', '10%', '8%', '13%', undefined, '8%', '10%'];

const getDisposalsTableCells = (vehicleTypeId: number) => {
  const disposalsTableCellWidths = getDisposalsTableCellWidths(vehicleTypeId);

  const disposalsTableCells = [
    {
      name: 'ticketNumber',
      label: translate('common.ticketNumber'),
      width: disposalsTableCellWidths[0],
    },
    {
      name: 'disposalSite',
      label: translate('common.disposalSite'),
      width: disposalsTableCellWidths[1],
    },
    {
      name: 'vehicleDriver',
      label: translate('common.vehicleDriver'),
      width: disposalsTableCellWidths[2],
    },
    {
      name: 'wasteType',
      label: translate('common.wasteType'),
      width: disposalsTableCellWidths[3],
    },
    {
      name: 'weight',
      label: translate('common.weight'),
      width: disposalsTableCellWidths[4],
    },
    {
      name: 'unitOfMeasureTypeId',
      label: translate('common.unit'),
      width: disposalsTableCellWidths[5],
    },
    {
      name: 'timestamp',
      label: translate('common.weightTickets.disposalDate'),
      width: disposalsTableCellWidths[6],
    },
    {
      name: 'images',
      label: translate('common.image'),
      width: disposalsTableCellWidths[8],
    },
    {
      name: 'options',
      label: translate('common.options'),
      width: disposalsTableCellWidths[9],
      align: 'right',
    },
  ];

  if (vehicleTypeId === ROLL_OFF_ID) {
    disposalsTableCells.splice(7, 0, {
      name: 'customer',
      label: translate('common.customer'),
      width: disposalsTableCellWidths[7],
    });
  }

  return disposalsTableCells;
};

export default function DisposalTicketsSection() {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { disposalTickets } = useSelector(state => state.routes.disposals);
  const routeStops = useSelector(state => state.routes.routeStops?.routeStops);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary?.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const [isWeightTicketEditorModalOpen, setWeightTicketEditorModalOpen] = useState(false);
  const [selectedWeightTicket, setSelectedWeightTicket] = useState<number>();

  if (!routeSummary) return null;

  const openWeightTicketEditorModalOpen = (weightTicketId?: number) => {
    if (weightTicketId) setSelectedWeightTicket(weightTicketId);
    if (!routeStops.length) loadRouteStops(vendorId, routeSummary.routeId)(dispatch);
    setWeightTicketEditorModalOpen(true);
  };

  const closeWeightTicketEditorModal = () => {
    setWeightTicketEditorModalOpen(false);
    setSelectedWeightTicket(undefined);
  };

  const handleDeleteWeightTicket = async (weightTicketId: number) => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteWeightTicket')))) {
      return;
    }

    deleteWeightTicket(weightTicketId)(dispatch)
      .then(() => {
        loadDisposalTickets(vendorId, routeSummary.routeId)(dispatch);

        isSnowOrSweeperRoute
          ? loadSnowSweeperServiceDetails(
              vendorId,
              routeSummary.routeId,
              segmentsFilterStartDate,
              segmentsFilterEndDate,
            )(dispatch)
          : loadServiceDetails(vendorId, routeSummary.routeId)(dispatch);

        createSuccessNotification(translate('routes.alertMessages.weightTicketDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.weightTicketDeleteError'));
      });
  };

  return (
    <>
      {disposalTickets.length ? (
        <Table
          cells={getDisposalsTableCells(routeSummary.vehicleTypeId)}
          rows={disposalTickets}
          rowComponent={DisposalsTableRow}
          rowProps={{ editWeightTicket: openWeightTicketEditorModalOpen, deleteWeightTicket: handleDeleteWeightTicket }}
        />
      ) : (
        <Text block align="center" margin="medium no">
          {translate('routes.noDisposalTickets')}
        </Text>
      )}
      <ButtonSet margin="sMedium no">
        <PermissionGuard permission={ROUTES_TRACKER_VIEW_ADD_WEIGHT_TICKET}>
          <Button type="button" color="primary" line onClick={() => openWeightTicketEditorModalOpen(undefined)}>
            + {translate('common.weightTickets.addWeightTicket')}
          </Button>
        </PermissionGuard>
      </ButtonSet>

      {isWeightTicketEditorModalOpen && (
        <WeightTicketEditorModalResolver
          routeId={routeSummary.routeId}
          weightTicketId={selectedWeightTicket}
          isRollOff={routeSummary.vehicleTypeId === ROLL_OFF_ID}
          onClose={closeWeightTicketEditorModal}
        />
      )}
    </>
  );
}
