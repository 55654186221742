import { assign, replace, map } from 'lodash-es';

import createTransformer from '../../utils/services/createTransformer';

const transformLoadMapInsights = (values: any) => {
  const vehicleInsights = createTransformer(values.vehicleInsights);
  const containerInsights = createTransformer(values.binInsights);
  const cityInsights = createTransformer(values.cityInsights);

  return {
    vehicleInsights: {
      insightTypes: vehicleInsights.get('insightTypes').orderBy(['insightType', 'displayOrder']).value,
      insights: vehicleInsights.get('insights').value,
    },
    containerInsights: {
      insightTypes: containerInsights
        .get('insightTypes')
        .map((insightType: any) =>
          assign(insightType, {
            displayname: replace(insightType.displayname, 'Bin', 'Container'),
            name: replace(insightType.name, 'Bin', 'Container'),
          }),
        )
        .orderBy(['insightType', 'displayOrder']).value,
      insights: containerInsights.get('insights').map((insight: any) => ({
        ...insight,
        insightMessageLabel: replace(insight.insightMessageLabel, 'Bin', 'Container'),
        insightSubTypes: map(insight.insightSubTypes, insightSubType => ({
          ...insightSubType,
          displayName: replace(insightSubType.displayName, 'Bin', 'Container'),
          name: replace(insightSubType.name, 'Bin', 'Container'),
        })),
      })).value,
    },
    cityInsights: {
      insightTypes: cityInsights.get('insightTypes').orderBy(['insightType', 'displayOrder']).value,
      insights: cityInsights.get('mapData').value,
    },
  };
};

export default transformLoadMapInsights;
