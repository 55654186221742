import { mapKeys } from 'lodash-es';

const SUNDAY = 'Su';
const MONDAY = 'Mo';
const TUESDAY = 'Tu';
const WEDNESDAY = 'We';
const THURSDAY = 'Th';
const FRIDAY = 'Fr';
const SATURDAY = 'Sa';

const CALENDAR_WEEKDAYS = mapKeys(
  [
    { id: SUNDAY, translationKey: 'common.calendarWeekdays.sunday' },
    { id: MONDAY, translationKey: 'common.calendarWeekdays.monday' },
    { id: TUESDAY, translationKey: 'common.calendarWeekdays.tuesday' },
    { id: WEDNESDAY, translationKey: 'common.calendarWeekdays.wednesday' },
    { id: THURSDAY, translationKey: 'common.calendarWeekdays.thursday' },
    { id: FRIDAY, translationKey: 'common.calendarWeekdays.friday' },
    { id: SATURDAY, translationKey: 'common.calendarWeekdays.saturday' },
  ],
  'id',
);

export default CALENDAR_WEEKDAYS;
