import { camelCase, map } from 'lodash-es';
import translate from '../../core/services/translate';

interface GetFormattedAddressProps {
  streetAndNumber: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

const getFormattedAddress = (address: GetFormattedAddressProps) => {
  const { streetAndNumber, city, state, country, zip } = address;

  if (!streetAndNumber || !city || !state || !country || !zip) {
    return '';
  }

  return `${streetAndNumber ? `${streetAndNumber}, ` : ' '}${city ? `${city}, ` : ' '}${state ? `${state}, ` : ' '}${
    zip ? `${zip}, ` : ' '
  }${country ? `${country}` : ' '} `;
};

/* eslint no-bitwise: [2, { allow: ["~"] }] */
export const transformLoadBusinessInfo = (businessInfo: any) => ({
  ...businessInfo,
  address: {
    ...businessInfo.address,
    formattedAddress: getFormattedAddress(businessInfo.address),
  },
});

export const transformSaveBusinessInfo = (businessInfo: any) => ({
  ...businessInfo,
  address: {
    ...businessInfo.address,
    streetAndNumber:
      !businessInfo.address.streetNumber && !businessInfo.address.street
        ? businessInfo.address.streetAndNumber
        : `${businessInfo.address.streetNumber || ''} ${businessInfo.address.street || ''}`,
  },
});

export const transformCertifications = (certs: any) =>
  map(certs.certificates, certification => ({
    ...certification,
    displayTitle: translate(`haulerProfile.classifications.${camelCase(certification.title)}`),
  }));
