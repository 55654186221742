import {
  RawVehicleNotificationsConfiguration,
  VehicleNotificationsConfiguration,
} from '../interfaces/VehicleNotifications';

export const transformRawVehicleNotificationsConfiguration = ({
  emailAddressesCSV,
  ...configuration
}: RawVehicleNotificationsConfiguration): VehicleNotificationsConfiguration => ({
  ...configuration,
  emailAddressesCSV: emailAddressesCSV.split(';').filter(emailAddress => !!emailAddress),
});

export const transformRawVehicleNotificationsConfigurations = (
  configurations: RawVehicleNotificationsConfiguration[],
) => configurations.map(transformRawVehicleNotificationsConfiguration);

export const transformVehicleNotificationsConfiguration = ({
  emailAddressesCSV,
  ...configuration
}: VehicleNotificationsConfiguration): RawVehicleNotificationsConfiguration => ({
  ...configuration,
  emailAddressesCSV: emailAddressesCSV.join(';'),
});

export const transformVehicleNotificationsConfigurations = (configurations: VehicleNotificationsConfiguration[]) =>
  configurations.map(transformVehicleNotificationsConfiguration);
