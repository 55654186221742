import React, { MouseEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { findIndex, get, map, orderBy, size } from 'lodash-es';
import { formValueSelector, isPristine, isValid, reset as resetForm, submit } from 'redux-form';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import update from 'immutability-helper';

import { AppState } from 'src/store';
import {
  ACCOUNT_STATUSES,
  routeFormKeys,
  ROUTE_PICKUP_TYPE_IDS,
  WASTE_AUDIT_EXCEPTION_TYPES,
} from '../../../constants';
import { AddRouteLocationForm, RouteEditorForm } from '../../forms';
import { Button, PanelSection, PanelSectionGroup, PanelSectionLoading } from '../../../../core/components/styled';
import { COMPLETED, SCHEDULED } from '../../../../common/constants';
import {
  resetRoute,
  resetRoutesForReassignment,
  routeLocationAddressChange,
  saveRoute,
  transferRouteLocations,
} from '../../../ducks';
import { createSuccessNotification } from '../../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DuckFunction } from 'src/contracts/ducks';
import { isUserManagerSelector } from '../../../../vendors/ducks';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { JobStatusModalResolver, TransferRouteLocationsModal } from '../../modals';
import { loadRouteHasStopsInPendingOptimization } from 'src/routes/ducks/dispatchBoard/dispatchBoardRouteJob';
import { LocationsInfo, Route } from 'src/routes/interfaces/Route';
import { Option } from 'src/common/interfaces/Option';
import { PageBackButtonAction, PageBackButtonIcon } from '../../../../common/components/styled';
import { resetDisposalSiteLocations } from '../../../../common/ducks';
import { resetGeoFence } from '../../../ducks/routeGeoFence';
import { resetVehicles, resetVehicleTypesForVendor } from '../../../../fleet/ducks';
import { RouteLocation, WasteAuditStatus } from 'src/routes/interfaces/RouteLocation';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { SortableTable, Table } from '../../../../core/components';
import { TABLE_ROW_HEIGHT } from '../../../../core/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import BaseRouteEntityEditorPage, { RouteEntityLocation, TransferDispatch } from '../common/BaseRouteEntityEditorPage';
import confirm from '../../../../core/services/confirm';
import loadPickupTypes from '../../../services/loadPickupTypes';
import RouteLocationsTableRow, { UnsortableRouteLocationsTableRow } from './RouteLocationsTableRow';
import RouteLocationsWithZeroStopTableRow from './RouteLocationsWithZeroStopTableRow';
import translate from '../../../../core/services/translate';

const routeLocationTableCells = [
  { name: 'customer', label: translate('common.customer'), width: '17%' },
  { name: 'jobStatus', label: translate('routes.jobStatus'), width: '15%' },
  { name: 'accountStatus', label: translate('routes.accountStatus'), width: '15%' },
  { name: 'pickupType', label: translate('routes.pickupType'), width: '15%' },
  { name: 'service', label: translate('common.service'), width: '10%' },
  { name: 'options', label: translate('common.options'), width: '10%' },
];

interface Props extends RouteComponentProps<{ routeId: string }> {
  batchTransfererInProgress: boolean;
  batchTransfererProgress: number;
  isJobStatusFormPristine: boolean;
  isLoading: boolean;
  isRouteEntityFormPristine: boolean;
  isRouteEntityFormValid: boolean;
  isSaving: boolean;
  isServiceFormPristine: boolean;
  isSnowPlowRoute: boolean;
  isVendorManager?: boolean;
  loadRouteHasStopsInPendingOptimization: DuckFunction<typeof loadRouteHasStopsInPendingOptimization>;
  locationsInfo: LocationsInfo;
  push: (url: string) => void;
  resetDisposalSiteLocations: () => void;
  resetForm: (formKey: string) => void;
  resetGeoFence: () => void;
  resetRoute: () => void;
  resetRoutesForReassignment: () => void;
  resetVehicles: () => void;
  resetVehicleTypesForVendor: () => void;
  routeDate: Date | string;
  routeEntity?: Route;
  routeEntityLocationAddressChange: DuckFunction<typeof routeLocationAddressChange>;
  saveRouteEntity: DuckFunction<typeof saveRoute>;
  submit: (formKey: string) => void;
  transferRouteLocations: DuckFunction<typeof transferRouteLocations>;
  vehicleTypeId?: number;
  vendorId: number;
}

interface State {
  isJobStatusModalOpen: boolean;
  pickupTypes: TechnicalType[];
  routeLocation?: RouteEntityLocation;
  selectedCustomerId?: number;
  selectedLocation?: RouteEntityLocation;
}

class RouteEditorPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isJobStatusModalOpen: false,
      pickupTypes: [],
      routeLocation: undefined,
      selectedCustomerId: undefined,
      selectedLocation: undefined,
    };
  }

  private baseEditorRef?: any;

  componentDidMount() {
    this.loadPickupTypes();
  }

  componentDidUpdate(prevProps: Props) {
    const { push, vehicleTypeId, isSnowPlowRoute } = this.props;
    const { vehicleTypeId: prevVehicleTypeId } = prevProps;

    if (vehicleTypeId !== prevVehicleTypeId) {
      this.loadPickupTypes();

      if (isSnowPlowRoute) {
        const routeId = Number(this.props.match.params.routeId);
        push(`/routes/route-tracker/${routeId}`);
      }
    }
  }

  componentWillUnmount() {
    const { resetVehicles, resetRoute, resetGeoFence } = this.props;

    resetVehicles();
    resetRoute();
    resetGeoFence();
  }

  setBaseEditorRef = (ref: any) => {
    this.baseEditorRef = ref;
  };

  getRouteEditorDisabledFields = (hasLocations: boolean, routeStatus?: number) => {
    let routeEditorFormDisabledFields: { [key: string]: boolean } = {};

    if (hasLocations) {
      routeEditorFormDisabledFields = {
        vehicleTypeId: true,
        wasteMaterialTypeId: true,
      };
    }

    if (routeStatus && routeStatus !== SCHEDULED) {
      routeEditorFormDisabledFields = {
        ...routeEditorFormDisabledFields,
        routeConfirmationTypeId: routeStatus === COMPLETED,
        routeDate: true,
        startingLocationId: routeStatus === COMPLETED,
        vehicleId: routeStatus === COMPLETED,
        vehicleTypeId: routeStatus === COMPLETED,
        wasteMaterialTypeId: routeStatus === COMPLETED,
      };
    }
    return routeEditorFormDisabledFields;
  };

  getStopId = (routeLocation: RouteLocation) => routeLocation.id;

  transferLocations = async (
    baseTransferLocations: (
      transferDispatch: TransferDispatch,
      getStopId: (routeLocation: RouteLocation) => number,
      params: any,
    ) => void,
    params: any,
  ) => {
    const { loadRouteHasStopsInPendingOptimization } = this.props;

    const hasStopsInPendingOptimization = await loadRouteHasStopsInPendingOptimization(params.targetRouteId.id);
    if (hasStopsInPendingOptimization)
      if (
        !(await confirm(
          translate('routes.alertMessages.routeHasStopsInOptimization'),
          '',
          translate('common.cancel'),
          translate('common.continue'),
        ))
      )
        return;

    baseTransferLocations(this.props.transferRouteLocations, this.getStopId, params);
  };

  handleBackAction = () => {
    const {
      history: { length, goBack },
      push,
    } = this.props;

    return length > 1 ? goBack() : push('/routes/route-tracker');
  };

  openJobStatusModal = (event: MouseEvent<HTMLElement>, routeLocation: RouteEntityLocation) => {
    const { routeEntity } = this.props;
    if (routeEntity && routeEntity.routeStatusTypeId !== SCHEDULED) {
      event.stopPropagation();
      this.setState({
        isJobStatusModalOpen: true,
        routeLocation,
      });
    }
  };

  closeJobStatusModal = async ({
    newException,
    pickupStatusId,
    routeLocationId,
    saveInProgress,
    wasteAuditExceptions,
  }: {
    newException: any;
    pickupStatusId: number;
    routeLocationId: number;
    saveInProgress: boolean;
    wasteAuditExceptions?: number[];
  }) => {
    const { isJobStatusFormPristine, resetForm } = this.props;

    if (!isJobStatusFormPristine && !saveInProgress) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    if (saveInProgress && this.baseEditorRef) {
      const { filteredRouteEntityLocations, routeEntityLocations } = this.baseEditorRef.state;
      const routeLocationIndex = findIndex(routeEntityLocations, { id: routeLocationId });
      const filteredRouteLocationIndex = findIndex(filteredRouteEntityLocations, { id: routeLocationId });
      const pickupExceptions = routeEntityLocations[routeLocationIndex].pickupExceptions;

      const wasteAuditStatuses: WasteAuditStatus[] = [];
      if (wasteAuditExceptions) {
        wasteAuditExceptions.forEach(exception => {
          wasteAuditStatuses.push(WASTE_AUDIT_EXCEPTION_TYPES[exception]);
        });
      }

      this.baseEditorRef.setState({
        filteredRouteEntityLocations: update(filteredRouteEntityLocations, {
          [filteredRouteLocationIndex]: {
            pickupExceptions: { $set: [...pickupExceptions, newException] },
            pickupStatusId: { $set: pickupStatusId },
            wasteAuditStatuses: { $set: wasteAuditStatuses },
          },
        }),
        mapShouldFitBounds: false,
        polygonPathShouldReset: false,
        routeEntityLocations: update(routeEntityLocations, {
          [routeLocationIndex]: {
            pickupExceptions: { $set: [...pickupExceptions, newException] },
            pickupStatusId: { $set: pickupStatusId },
            wasteAuditStatuses: { $set: wasteAuditStatuses },
          },
        }),
        saveStops: true,
      });
    }

    resetForm('jobStatusEditor');

    this.setState({
      isJobStatusModalOpen: false,
      routeLocation: undefined,
    });
  };

  loadPickupTypes = async () => {
    if (this.props.vehicleTypeId) {
      const pickupTypesPromise = await loadPickupTypes(Number(this.props.vehicleTypeId));
      this.setState({
        pickupTypes: pickupTypesPromise[0].pickupTypes,
      });
    }
  };

  render() {
    const { isVendorManager, routeEntity, vehicleTypeId, routeDate, vendorId } = this.props;
    const { isJobStatusModalOpen, pickupTypes, routeLocation } = this.state;
    const routeStatus = get(this.props.routeEntity, 'routeStatusTypeId');

    const accountStatusOptions = map(ACCOUNT_STATUSES, ({ name, id }) => ({
      label: name,
      value: id,
    }));

    let routePickupTypeOptions: Option[] = [];

    if (pickupTypes.length) {
      routePickupTypeOptions = map(pickupTypes, pickupType => ({
        label: ROUTE_PICKUP_TYPE_IDS[pickupType.id].name,
        value: pickupType.id,
      }));
    }

    let equipmentTypeNameSize;
    let wasteMaterialTypeName;

    if (routeLocation && routeLocation.service) {
      const { service } = routeLocation;
      equipmentTypeNameSize = `${service.equipmentTypeName} / ${service.equipmentSize}`;
      wasteMaterialTypeName = service.wasteMaterialTypeName;
    }

    return (
      <BaseRouteEntityEditorPage
        additionalRouteEntityLocationsTableCells={routeLocationTableCells}
        displayRouteEntityUrl="/routes/route-tracker/"
        formKey={routeFormKeys.route}
        formsToResetOnLocationAdd={['addRouteLocation', 'routeEditorLocationSearch']}
        locationsAccessorKey="routeLocations"
        ref={this.setBaseEditorRef}
        routeEntityId={Number(this.props.match.params.routeId)}
        routeEntityLocationIdAccessor={!Number(this.props.match.params.routeId) ? 'stopId' : 'id'}
        routeEntityLocations={routeEntity && routeEntity.routeLocations}
        routeEntityPayloadIdAccessor="routeLocationId"
        routeEntitySavedTranslationKey="routes.alertMessages.routeSaved"
        mapEditButtonId="route-map-edit-button"
        {...this.props}
        renderBackButton={() => (
          <PageBackButtonAction onClick={this.handleBackAction} id="back-button">
            <PageBackButtonIcon />
          </PageBackButtonAction>
        )}
        renderEditorForm={(locations, transferredStops, onSubmit) => (
          <RouteEditorForm
            disabledFields={this.getRouteEditorDisabledFields(size(locations) > 0, routeStatus)}
            isCreateMode={!routeEntity}
            onSubmit={onSubmit}
            routeLocations={locations}
            transferredStops={transferredStops}
            vendorId={vendorId}
          />
        )}
        renderAddRouteEntityLocationForm={(onSubmit, ref) => {
          const newOptimizedRouteLocationsLength =
            ref.state.routeEntityLocations.filter(
              (routeLocation: RouteLocation) =>
                routeLocation.id && routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
            ).length || 0;

          return !!vehicleTypeId && !!routeDate ? (
            <AddRouteLocationForm
              routeDate={routeDate}
              newOptimizedRouteLocationsLength={newOptimizedRouteLocationsLength}
              onSubmit={({
                routeLocation,
                pickupTypeId,
                pinAddress,
                reasonCodeTypeId,
                wasteMaterialTypeId,
                positionTypeId,
              }: any) => {
                let location: RouteLocation = pinAddress || routeLocation;

                // create the data structure to create the RouteLocationsTableRow with location pinned on map
                if (pinAddress) {
                  const addressObj = {
                    binLatitude: pinAddress.binLatitude || pinAddress.latitude,
                    binLongitude: pinAddress.binLongitude || pinAddress.longitude,
                    city: pinAddress.city,
                    country: pinAddress.country,
                    countryId: pinAddress.countryId,
                    latitude: pinAddress.latitude,
                    line1: pinAddress.line1 || pinAddress.formattedAddress,
                    line2: pinAddress.line2,
                    longitude: pinAddress.longitude,
                    state: pinAddress.state,
                    stateId: pinAddress.stateId,
                    street: pinAddress.street,
                    streetNumber: pinAddress.streetNumber,
                    zip: pinAddress.zip,
                  };

                  location = {
                    ...location,
                    customer: {
                      id: 0,
                      name: `${addressObj.streetNumber}${addressObj.street}`,
                    },
                    pinnedAddress: addressObj,
                    location: {
                      address: addressObj,
                      id: 0,
                      name: addressObj.line1,
                    },
                    service: {
                      id: 0,
                      serviceContractBinDetails: [
                        {
                          binLatitude: pinAddress.latitude,
                          binLocationMappingTypeId: 0,
                          binLongitude: pinAddress.longitude,
                          binNumber: '',
                          displayLatitude: pinAddress.latitude,
                          displayLongitude: pinAddress.longitude,
                          forwardPasses: 0,
                          id: 0,
                          reversePasses: 0,
                          serviceContractId: 0,
                        },
                      ],
                    },
                    wasteMaterialTypeId,
                  };
                }

                onSubmit({
                  ...location,
                  pickupTypeId,
                  pickupStatusId: SCHEDULED,
                  isChecked: false,
                  reasonCodeTypeId,
                  positionTypeId: positionTypeId || 1,
                });
              }}
            />
          ) : null;
        }}
        renderLocationsTable={ref => {
          if (!ref.state.filteredRouteEntityLocations.length) {
            return <PanelSectionLoading />;
          }

          const routeLocationsWithStopNumber = orderBy(
            ref.state.filteredRouteEntityLocations.filter(
              (routeLocation: RouteEntityLocation) => routeLocation.orderNumber > 0,
            ),
            ['orderNumber'],
            ['asc'],
          );

          const optimizedRouteLocations = ref.state.filteredRouteEntityLocations.filter(
            (routeLocation: RouteEntityLocation) => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
          );
          const newOptimizedRouteLocationsLength = ref.state.routeEntityLocations.filter(
            (routeLocation: RouteEntityLocation) =>
              routeLocation.id && routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
          ).length;
          const currentOptimizedRouteLocationsLength = ref.state.filteredRouteEntityLocations.filter(
            (routeLocation: RouteEntityLocation) => !routeLocation.id,
          ).length;

          const routeLocationsWithZeroStopNumber = ref.state.filteredRouteEntityLocations.filter(
            (routeLocation: RouteEntityLocation) => routeLocation.orderNumber === 0,
          );

          let optimizedCanceledRouteLocations: RouteEntityLocation[] = [];
          const cancelOptimization = async () => {
            if (!(await confirm(translate('routes.alertMessages.confirmCancelOptimization')))) {
              return;
            }

            optimizedRouteLocations.forEach((routeLocation: RouteEntityLocation, index: number) => {
              optimizedCanceledRouteLocations.push({
                ...routeLocation,
                orderNumber:
                  ref.state.filteredRouteEntityLocations.filter(
                    (routeLocation: any) => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
                  ).length +
                  index +
                  1,
              });
            });

            this.baseEditorRef.setState(
              {
                filteredRouteEntityLocations: [
                  ...ref.state.filteredRouteEntityLocations.filter(
                    (routeLocation: RouteEntityLocation) => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
                  ),
                  ...optimizedCanceledRouteLocations,
                ],
                routeEntityLocations: [
                  ...ref.state.routeEntityLocations.filter(
                    (routeLocation: RouteEntityLocation) => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
                  ),
                  ...optimizedCanceledRouteLocations,
                ],
                saveStops: true,
              },
              () => {
                createSuccessNotification(translate('routes.alertMessages.confirmCancelOptimizationSucess'));
              },
            );
          };

          return (
            <PanelSectionGroup width="100%">
              {!!size(optimizedRouteLocations) && (
                <PanelSection>
                  <Table
                    cells={ref.getRouteLocationsTableCells(newOptimizedRouteLocationsLength)}
                    rowComponent={UnsortableRouteLocationsTableRow}
                    rows={optimizedRouteLocations}
                    tableHeading={translate(
                      currentOptimizedRouteLocationsLength
                        ? 'routes.pendingOptimizationWillStartAfterSave'
                        : 'routes.pendingOptimization',
                    )}
                    tableHeadingAction={
                      newOptimizedRouteLocationsLength > 0 && (
                        <Button
                          type="button"
                          color="primary"
                          size="xSmall"
                          margin="no no no xSmall"
                          onClick={() => cancelOptimization()}
                        >
                          {translate('routes.cancelOptimization')}
                        </Button>
                      )
                    }
                    rowProps={{
                      accountStatusOptions,
                      deleteRouteLocation: ref.deleteRouteEntityLocation,
                      editJobStatus: this.openJobStatusModal,
                      editService: ref.openServiceDetailsModal,
                      isChecked: false,
                      isSearchInitialized: ref.state.isSearchInitialized,
                      isVendorManager,
                      onAccountStatusChange: (value: string | number, locationId: number, stopId: string) =>
                        ref.updateRouteProperty(Number(value), locationId, 'accountStatusId', stopId),
                      onLocationSelected: ref.onRouteEntityLocationSelected,
                      onRoutePickupTypeChange: (value: string, locationId: number, stopId: string) =>
                        ref.updateRouteProperty(Number(value), locationId, 'pickupTypeId', stopId),
                      openOrderNumberPopover: ref.openOrderNumberPopover,
                      routeId: routeEntity && routeEntity.id,
                      routePickupTypeOptions,
                      routeStatus,
                      selectLocation: ref.selectLocation,
                      newOptimizedRouteLocationsLength: newOptimizedRouteLocationsLength,
                    }}
                  />
                </PanelSection>
              )}
              {!!size(routeLocationsWithZeroStopNumber) && (
                <PanelSection>
                  <Table
                    cells={
                      size(optimizedRouteLocations)
                        ? []
                        : ref.getRouteLocationsTableCells(newOptimizedRouteLocationsLength)
                    }
                    rowComponent={RouteLocationsWithZeroStopTableRow}
                    rows={routeLocationsWithZeroStopNumber}
                    rowProps={{
                      accountStatusOptions,
                      deleteRouteLocation: ref.deleteRouteEntityLocation,
                      editJobStatus: this.openJobStatusModal,
                      editService: ref.openServiceDetailsModal,
                      isChecked: false,
                      isSearchInitialized: ref.state.isSearchInitialized,
                      isVendorManager,
                      onAccountStatusChange: (value: string | number, locationId: number) =>
                        ref.updateRouteProperty(Number(value), locationId, 'accountStatusId'),
                      onLocationSelected: ref.onRouteEntityLocationSelected,
                      onRoutePickupTypeChange: (value: string | number, locationId: number) =>
                        ref.updateRouteProperty(Number(value), locationId, 'pickupTypeId'),
                      openOrderNumberPopover: ref.openOrderNumberPopover,
                      routeId: routeEntity && routeEntity.id,
                      routePickupTypeOptions,
                      routeStatus,
                      selectLocation: ref.selectLocation,
                    }}
                    tableHeading={translate('routes.unableToSequenceRoute')}
                  />
                </PanelSection>
              )}
              {!!size(routeLocationsWithStopNumber) && (
                <PanelSection>
                  <SortableTable
                    cells={
                      size(routeLocationsWithZeroStopNumber) || size(optimizedRouteLocations)
                        ? []
                        : ref.getRouteLocationsTableCells(newOptimizedRouteLocationsLength)
                    }
                    noOverflow
                    rowComponent={RouteLocationsTableRow}
                    rowProps={{
                      accountStatusOptions,
                      deleteRouteLocation: ref.deleteRouteEntityLocation,
                      editJobStatus: this.openJobStatusModal,
                      editService: ref.openServiceDetailsModal,
                      isChecked: false,
                      isSearchInitialized: ref.state.isSearchInitialized,
                      isVendorManager,
                      onAccountStatusChange: (value: string | number, locationId: number, stopId: string) =>
                        ref.updateRouteProperty(Number(value), locationId, 'accountStatusId', stopId),
                      onLocationSelected: ref.onRouteEntityLocationSelected,
                      onRouteLocationDrop: size(routeLocationsWithZeroStopNumber) ? ref.onRouteLocationDrop : undefined,
                      onRoutePickupTypeChange: (value: string, locationId: number, stopId: string) =>
                        ref.updateRouteProperty(Number(value), locationId, 'pickupTypeId', stopId),
                      openOrderNumberPopover: ref.openOrderNumberPopover,
                      routeId: routeEntity && routeEntity.id,
                      routePickupTypeOptions,
                      routeStatus,
                      selectLocation: ref.selectLocation,
                      newOptimizedRouteLocationsLength: newOptimizedRouteLocationsLength,
                    }}
                    rows={routeLocationsWithStopNumber}
                    sort={ref.onSortOrderChange}
                    virtualized
                    virtualizedProps={{
                      height:
                        Math.min(routeLocationsWithStopNumber.length * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 8) || 1,
                      itemSize: TABLE_ROW_HEIGHT,
                    }}
                    withClickableRows
                  />
                </PanelSection>
              )}
            </PanelSectionGroup>
          );
        }}
        renderTransferRouteEntityLocationsModal={(onClose, baseTransferLocations) => (
          <TransferRouteLocationsModal
            onTransferRouteLocationsSubmit={({
              targetRouteId,
              routeDate,
              positionTypeId,
              hasStopsInPendingOptimization,
            }: {
              targetRouteId: number;
              routeDate: Date | string;
              positionTypeId: number;
              hasStopsInPendingOptimization: boolean;
            }) =>
              this.transferLocations(baseTransferLocations, {
                targetRouteId,
                routeDate,
                positionTypeId,
                hasStopsInPendingOptimization,
              })
            }
            closeModal={onClose}
          />
        )}
      >
        {!!isJobStatusModalOpen && routeEntity && routeLocation && (
          <JobStatusModalResolver
            closeModal={this.closeJobStatusModal}
            routeId={routeEntity.id}
            routeLocation={routeLocation}
            routeLocationInfo={{
              equipmentTypeNameSize,
              jobStatusId: routeLocation?.pickupStatusId,
              vehicleTypeId: routeEntity?.vehicleTypeId,
              wasteAuditTypeId: routeEntity?.wasteAuditType && routeEntity?.wasteAuditType.id,
              wasteMaterialTypeName,
            }}
          />
        )}
      </BaseRouteEntityEditorPage>
    );
  }
}

const routeFormSelector = formValueSelector('routeEditor');

const mapStateToProps = (state: AppState) => {
  const routeDate = routeFormSelector(state, 'routeDate') || moment().format('MM/DD/YYYY');
  const vehicleTypeId = routeFormSelector(state, 'vehicleTypeId');
  const vendorId = currentVendorIdSelector(state.account.login, state.vendors.defaultVendor);

  return {
    batchTransfererInProgress: state.routes.route.batchTransfererInProgress,
    batchTransfererProgress: state.routes.route.batchTransfererProgress,
    isJobStatusFormPristine: isPristine('jobStatusEditor')(state),
    isLoading: state.routes.route.isLoading,
    isRouteEntityFormPristine: isPristine('routeEditor')(state),
    isRouteEntityFormValid: isValid('routeEditor')(state),
    isSaving: state.routes.route.isSaving,
    isServiceFormPristine: isPristine('serviceDetailsEditor')(state),
    isVendorManager: isUserManagerSelector(state.vendors.users),
    routeDate,
    routeEntity: state.routes.route.route as Route,
    vehicleTypeId,
    isSnowPlowRoute: vehicleTypeId === SNOW_PLOW_ID,
    vendorId,
  };
};

const mapDispatchToProps = {
  loadRouteHasStopsInPendingOptimization,
  push,
  resetDisposalSiteLocations,
  resetForm,
  resetGeoFence,
  resetRoute,
  resetRoutesForReassignment,
  resetVehicles,
  resetVehicleTypesForVendor,
  routeEntityLocationAddressChange: routeLocationAddressChange,
  saveRouteEntity: saveRoute,
  submit,
  transferRouteLocations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteEditorPage));
