import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadBusinessProfile as doLoadBusinessInfo,
  saveBusinessProfile as doSaveBusinessProfile,
} from '../services/overview';

// Actions
const START_LOAD = 'haulerProfile/businessInfo/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/businessInfo/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/businessInfo/FAIL_LOAD';
const START_SAVE = 'haulerProfile/businessInfo/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/businessInfo/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/businessInfo/FAIL_SAVE';
const RESET = 'haulerProfile/businessInfo/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  businessInfo: undefined,
  isValidGusId: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          businessInfo: action.businessInfo,
          isValidGusId: true,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          ...initialState,
          isValidGusId: state.isValidGusId,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (businessInfo: any) => ({
  type: COMPLETE_LOAD,
  businessInfo,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadBusinessProfile = (haulerId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadBusinessInfo(haulerId)
    .then(businessInfo => dispatch(completeLoad(businessInfo)))
    .catch(() => dispatch(failLoad()));
};

export const saveBusinessProfile = (haulerId: string, data: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveBusinessInfoPromise = doSaveBusinessProfile(haulerId, data);
  saveBusinessInfoPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveBusinessInfoPromise;
};

export const resetBusinessProfile = () => ({
  type: RESET,
});
