import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GeoJSONSource } from 'mapbox-gl';

import { setRouteMapViewport } from 'src/routes/ducks';
import { setMapGeoJson } from 'src/routes/ducks/routeMapSettings';
import { ContainerFacility, ContainerLocationForMap } from 'src/fleet/interfaces/containers';
import { getFeatureCollection } from 'src/common/components/map/util';
import { getContainersGeoJSON, getFacilitiesGeoJSON } from './utils';
import { useSelector } from 'src/core/hooks/useSelector';
import { CONTAINERS_MAP_CLUSTER_LAYER, CONTAINERS_MAP_CLUSTER_SOURCE } from 'src/fleet/constants/containersMap';
import ContainersClustersGLSource from './ContainersClustersGLSource';

type Props = {
  map: mapboxgl.Map;
  containerLocations: ContainerLocationForMap[];
  facilities: ContainerFacility[];
  selectedContainers: number[];
  isFacilitiesDisplayed: boolean;
};

export default function ContainersClustersGL({
  map,
  containerLocations,
  facilities,
  selectedContainers,
  isFacilitiesDisplayed,
}: Props) {
  const dispatch = useDispatch();
  const geoJson = useSelector(s => s.routes.routeMapSettings.mapGeoJson);

  useEffect(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (containerLocations.length && !isFacilitiesDisplayed) {
      const containersCollection = getContainersGeoJSON(containerLocations, selectedContainers);
      collection.features = collection.features.concat(containersCollection.features);
    }

    if (facilities.length && isFacilitiesDisplayed) {
      const facilitiesCollection = getFacilitiesGeoJSON(facilities);
      collection.features = collection.features.concat(facilitiesCollection.features);
    }

    dispatch(setMapGeoJson(collection));
  }, [containerLocations, facilities, dispatch, selectedContainers, isFacilitiesDisplayed]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', CONTAINERS_MAP_CLUSTER_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [CONTAINERS_MAP_CLUSTER_LAYER],
          })
          .filter(feature => feature.source === CONTAINERS_MAP_CLUSTER_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(CONTAINERS_MAP_CLUSTER_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setRouteMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <ContainersClustersGLSource geoJSON={geoJson} />;
}
