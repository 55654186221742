import { map } from 'lodash-es';

import { http } from '../../core/services/http';
import { FuelTicketSettingItem } from 'src/routes/interfaces/FuelTicket';

export const loadFuelingTicketsSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/fuellingSettings`).then(response => response.data);

export const saveFuelingTicketsSettings = (vendorId: number, fuelingTicketsSettings: FuelTicketSettingItem[]) =>
  http
    .post(`/vendors/${vendorId}/fuellingSettings`, map(fuelingTicketsSettings))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
