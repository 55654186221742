import styled from 'styled-components';

import { sizeMapper } from '../../../utils/styles';

export const ZipCodeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ZipCode = styled.div<{ margin: string }>`
  display: inline-block;
  margin: ${props => sizeMapper(props.margin, 'no')};
`;
