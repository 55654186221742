import { CreateEditPaymentData } from 'src/finance/interfaces/ApplyChecks';
import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import { http } from 'src/core/services/http';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import translate from 'src/core/services/translate';

export interface LoadPaymentsParams {
  customerId?: number;
  endDate?: StringOrDate;
  locationId?: number;
  noLoadingIndicator?: boolean;
  paymentMethodIds?: number[] | string | string[];
  paymentNumber?: string;
  paymentStatusIds?: number[] | string | string[];
  searchText?: string;
  startDate?: StringOrDate;
  vendorId: number;
}

export const loadPayments = (params: LoadPaymentsParams) =>
  http.get('reconciliations/payments', { params }).then(response => response.data);

export const exportPayments = ({
  vendorId,
  paymentStatusIds,
  paymentMethodIds,
  startDate,
  endDate,
  searchText,
}: LoadPaymentsParams) =>
  downloadExcelFile(
    `reconciliations/payments/export`,
    `${translate('finance.applyChecks.payments')}-${translate('finance.report')}`,
    {
      vendorId,
      paymentStatusIds,
      paymentMethodIds,
      startDate,
      endDate,
      searchText,
    },
  );

export const savePayment = (formData: FormData) =>
  http.post(`reconciliations/payments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updatePayment = (paymentId: number, data: CreateEditPaymentData) =>
  http.put(`reconciliations/payments/${paymentId}`, data);

export const deletePayment = (paymentId: number) => http.delete(`reconciliations/payments/${paymentId}`);
