import { DragHandleIcon } from 'src/core/components/styled';
import { theme } from 'src/core/styles';
import { WidgetDragHandleBackground, WidgetDragHandleContainer } from 'src/dashboard/components/styled';

const WidgetResizeHandle = () => (
  <WidgetDragHandleBackground>
    <WidgetDragHandleContainer>
      <DragHandleIcon color={theme.brandPrimary} cursor="row-resize" />
    </WidgetDragHandleContainer>
  </WidgetDragHandleBackground>
);

export default WidgetResizeHandle;
