import { orderBy } from 'lodash';

import { AmountReport } from 'src/finance/interfaces/BillCreateSummary';
import { formatNumber } from 'src/utils/services/formatter';

export const getChartData = (billCreateReport?: AmountReport) => {
  if (!billCreateReport) {
    return null;
  }

  const data: any[] = [];
  billCreateReport.reportData.map(report =>
    data.push({
      label: report.label,
      value: report.value,
      rawValue: `$${formatNumber(report.value)}`,
    }),
  );

  const ordered = orderBy(data, 'value', 'desc');

  return {
    labels: ordered.map(item => item.label),
    data: ordered.map(item => item.value),
    rawDataValues: ordered.map(item => item.rawValue),
  };
};
