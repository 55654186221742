import styled, { css } from 'styled-components';

const PinOnMapContainer = styled.div<{ modalHasTitle?: boolean; sourceIsProximity?: boolean }>`
  width: 100%;
  height: calc(100% - ${props => (props.modalHasTitle ? '185' : '135')}px);
  min-height: ${props => (props.modalHasTitle ? '235px' : '400px')};

  *:focus {
    outline: none;
  }

  ${props =>
    props.sourceIsProximity &&
    css`
      height: 300px;
      min-height: 1px;
    `};
`;

export default PinOnMapContainer;
