import { combineReducers } from 'redux';

import { reducer as deviceStatusesReducer } from './deviceStatuses';
import { reducer as lastKnownLocationReducer } from './lastKnownLocation';
import { reducer as proximitySettingsReducer } from './proximitySettings';
import { reducer as releaseNotesReducer } from './releaseNotes';
import { reducer as trackingSettingsReducer } from './trackingSettings';
import { reducer as versionStatusesReducer } from './versionStatuses';

export { loadTrackingSettings, saveTrackingSettings, resetTrackingSettings } from './trackingSettings';
export { loadProximitySettings, saveProximitySettings, resetProximitySettings } from './proximitySettings';
export {
  loadVersionStatuses,
  currentApplicationBuildNumberSelector,
  appVersionsSelector,
  resetVersionStatuses,
} from './versionStatuses';
export {
  exportDeviceStatuses,
  deviceStatusesSelector,
  deviceStatusCountSelector,
  resetDeviceStatuses,
} from './deviceStatuses';
export { loadLastKnownLocation } from './lastKnownLocation';

export const reducer = combineReducers({
  deviceStatuses: deviceStatusesReducer,
  lastKnownLocation: lastKnownLocationReducer,
  proximitySettings: proximitySettingsReducer,
  releaseNotes: releaseNotesReducer,
  trackingSettings: trackingSettingsReducer,
  versionStatuses: versionStatusesReducer,
});
