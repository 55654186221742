import { map } from 'lodash-es';

import { getFeatureCollection, getLineStringFeature, getPointFeature } from 'src/common/components/map/util';
import { POLYLINE_COLORS } from 'src/common/constants';
import { getIconTypeForVehiclesListPosition } from 'src/common/constants/vehicleIcons';
import {
  RouteVehicleCoord,
  RouteVehiclePosition,
  RouteVehiclesBreadCrumbs,
  RouteVehiclesBreadCrumbsItem,
} from 'src/dashboard/interfaces/routesData';
import { PICKUP_TRUCK } from 'src/fleet/constants';
import { TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER } from './vehiclePositions/VehiclePositionsGLSource';

type VehiclePositionFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export type VehicleTrackingFeatureProperties = {
  id: number;
  clickable: boolean;
  vehicleId: number;
  opacity?: number;
  color: string;
};

export const getMapboxTrackingForVehicleGroupId = (tracking: RouteVehiclesBreadCrumbsItem) => +`${tracking.id}`;

export const getTravelPathBuilderVehiclesPositionsGeoJSON = (vehiclePositions: RouteVehiclePosition[]) =>
  getFeatureCollection<GeoJSON.Point, VehiclePositionFeatureProperties>(
    vehiclePositions.map(position => {
      const id = position.vehicleId;

      return getPointFeature(id, [position.longitude, position.latitude], {
        id,
        clickable: true,
        layer: TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER,
        icon: getIconTypeForVehiclesListPosition(position)?.id,
        isPickupTruck: position.type === PICKUP_TRUCK,
      });
    }),
  );

export const getTravelPathVehicleBreadCrumbsLineGeoJSON = (
  vehicleBreadcrumbs: RouteVehiclesBreadCrumbs,
  minSelected: number,
  maxSelected: number,
) => {
  const firstVehicle = vehicleBreadcrumbs.vehicles.length && vehicleBreadcrumbs.vehicles[0];

  if (!firstVehicle) {
    return null;
  }

  // split in groups where the min and max selected are
  let groupedCoords: RouteVehicleCoord[][] = [];
  let accumulatedTotal = 0;
  let minIndex = 0;
  let maxIndex = 0;

  firstVehicle.coords.forEach((group, grIndex: number) => {
    const itemsInThisGroup = group.length;
    // this group is before the min selected
    accumulatedTotal += itemsInThisGroup;

    const firstItemIndexInGroup = accumulatedTotal - itemsInThisGroup;
    const lastItemIndexInGroup = accumulatedTotal - 1;

    // if min is not in this group, add this group
    if (minSelected > lastItemIndexInGroup) {
      groupedCoords.push(group);
      return;
    }

    // group contains both min and max
    if (firstItemIndexInGroup <= minSelected && lastItemIndexInGroup >= maxSelected) {
      // if min and max are the ends of this group, add the entire group
      if (minSelected === firstItemIndexInGroup && maxSelected === lastItemIndexInGroup) {
        // min and max are the ends of this group add the entire group
        groupedCoords.push(group);
        minIndex = groupedCoords.length - 1;
        maxIndex = groupedCoords.length - 1;
      } else if (minSelected === firstItemIndexInGroup && maxSelected !== lastItemIndexInGroup) {
        // min is at the beginning of this group, split the group at max
        const groupBeforeMax = group.slice(0, maxSelected - firstItemIndexInGroup + 1);
        const groupAfterMax = group.slice(maxSelected - firstItemIndexInGroup + 1);
        groupedCoords.push(groupBeforeMax, groupAfterMax);
        minIndex = groupedCoords.length - 2;
        maxIndex = groupedCoords.length - 1;
      } else if (minSelected !== firstItemIndexInGroup && maxSelected === lastItemIndexInGroup) {
        // max is at the end of this group, split the group at min
        const groupBeforeMin = group.slice(0, minSelected - firstItemIndexInGroup);
        const groupAfterMin = group.slice(minSelected - firstItemIndexInGroup);
        groupedCoords.push(groupBeforeMin, groupAfterMin);
        minIndex = groupedCoords.length - 1;
        maxIndex = groupedCoords.length - 1;
      } else {
        // min and max are somewhere inside this group, split the group at min and max
        const groupBeforeMin = group.slice(0, minSelected - firstItemIndexInGroup);
        const groupBetweenMinAndMax = group.slice(
          minSelected - firstItemIndexInGroup,
          maxSelected - firstItemIndexInGroup + 1,
        );
        const groupAfterMax = group.slice(maxSelected - firstItemIndexInGroup + 1);
        groupedCoords.push(groupBeforeMin, groupBetweenMinAndMax, groupAfterMax);
        minIndex = groupedCoords.length - 2;
        maxIndex = groupedCoords.length - 1;
      }
    } else if (firstItemIndexInGroup <= minSelected && lastItemIndexInGroup < maxSelected) {
      // check if min is at the beginning of this group, if so, add the entire group
      if (minSelected === firstItemIndexInGroup) {
        groupedCoords.push(group);
        minIndex = groupedCoords.length - 1;
      } else {
        // min is somewhere inside this group, split the group at min
        const groupBeforeMin = group.slice(0, minSelected - firstItemIndexInGroup);
        const groupAfterMin = group.slice(minSelected - firstItemIndexInGroup);
        groupedCoords.push(groupBeforeMin, groupAfterMin);
        minIndex = groupedCoords.length - 1;
      }
    } else if (
      firstItemIndexInGroup > minSelected &&
      lastItemIndexInGroup >= maxSelected &&
      maxSelected >= firstItemIndexInGroup
    ) {
      // check if max is at the end of this group, if so, add the entire group
      if (maxSelected === lastItemIndexInGroup) {
        groupedCoords.push(group);
        maxIndex = groupedCoords.length;
      } else {
        // max is somewhere inside this group, split the group at max
        const groupBeforeMax = group.slice(0, maxSelected - firstItemIndexInGroup + 1);
        const groupAfterMax = group.slice(maxSelected - firstItemIndexInGroup + 1);
        groupedCoords.push(groupBeforeMax, groupAfterMax);
        maxIndex = groupedCoords.length - 1;
      }
    } else {
      groupedCoords.push(group);
    }
  });

  return getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    map(groupedCoords, (vehicleTracking: RouteVehicleCoord[], coordinateGroupIndex: number) =>
      getLineStringFeature(
        getMapboxTrackingForVehicleGroupId(firstVehicle),
        vehicleTracking.map(({ lat, lng }: RouteVehicleCoord) => [lng, lat]),
        {
          id: getMapboxTrackingForVehicleGroupId(firstVehicle),
          clickable: true,
          vehicleId: firstVehicle.id,
          groupIndex: coordinateGroupIndex,
          color: POLYLINE_COLORS[0], // blue
          opacity:
            minIndex === maxIndex && maxIndex === coordinateGroupIndex
              ? 1
              : coordinateGroupIndex >= minIndex && coordinateGroupIndex < maxIndex
              ? 1
              : 0.1,
        },
      ),
    ),
  );
};

export const getTravelPathVehicleBreadCrumbsPointsGeoJSON = (
  vehicleBreadcrumbs: RouteVehiclesBreadCrumbs,
  mapBearing: number,
  minSelected: number,
  maxSelected: number,
) => {
  const firstVehicle = vehicleBreadcrumbs.vehicles.length && vehicleBreadcrumbs.vehicles[0];

  if (!firstVehicle) {
    return null;
  }

  return getFeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>(
    map(firstVehicle.coords, (vehicleTracking: RouteVehicleCoord[], coordinateGroupIndex: number) =>
      map(vehicleTracking, ({ lat, lng, bg }: RouteVehicleCoord) => {
        const id = getMapboxTrackingForVehicleGroupId(firstVehicle);
        return getPointFeature(id, [lng, lat], {
          id,
          clickable: true,
          vehicleId: firstVehicle.id,
          groupIndex: coordinateGroupIndex,
          color: POLYLINE_COLORS[0], // blue
          bearing: bg ? bg - mapBearing - 90 : 0,
        });
      }),
    )
      .reduce((acc, current) => acc.concat(current), [])
      .map((feature, index) => ({
        ...feature,
        properties: {
          ...feature.properties,
          opacity: index >= minSelected && index <= maxSelected ? 1 : 0.1,
        },
      })),
  );
};
