import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import { Page } from '../../common/components';
import { AuthRoute, RestrictedRoute } from '../../account/components';
import {
  DriverEditorPageResolver,
  FacilitiesPageResolver,
  RubiconDispatchesPageResolver,
  UnassignedWeightTicketsPageResolver,
  VehicleEditorPageResolver,
  VehicleInspectionDataResolver,
  VehicleTripInspectionDetailsResolver,
  VehicleTypesPageResolver,
} from './pages';
import {
  FLEET_DRIVERS_EDIT,
  FLEET_DRIVERS_CREATE,
  FLEET_VEHICLES_EDIT,
  FLEET_VEHICLES_CREATE,
  FLEET_VEHICLES_CONFIGURE_VEHICLE_TYPES,
} from '../../account/constants';
import VehiclesPageResolver from './pages/VehiclesPageResolver';
import VehiclePageResolver from './pages/VehiclePageResolver';
import { GeoFencesPageResolver, GeoFenceEditorPageResolver } from '../../routes/components/pages';
import ResourcesPageResolver from './pages/ResourcesPageResolver';
import NewContainersPageResolver from './pages/NewContainersPageResolver';
import DriverInspectionLogPageResolver from './pages/DriverInspectionLogPageResolver';
import DriverInspectionPageResolver from './pages/DriverInspectionPageResolver';
import LocationAndCityAlertsPageResolver from './pages/LocationAndCityAlertsPageResolver';

const Fleet: React.FC = () => {
  return (
    <Page>
      <Switch>
        <AuthRoute exact path="/fleet/resources" component={ResourcesPageResolver} />
        <AuthRoute
          exact
          path="/fleet/resources/:driverId/driverInspectionLog"
          component={DriverInspectionLogPageResolver}
        />
        <AuthRoute
          exact
          path="/fleet/resources/:driverId/driverInspectionLog/:id"
          component={DriverInspectionPageResolver}
        />
        <RestrictedRoute
          permission={FLEET_DRIVERS_EDIT}
          exact
          path="/fleet/resources/:driverId/edit"
          component={DriverEditorPageResolver}
        />
        <RestrictedRoute
          permission={FLEET_DRIVERS_CREATE}
          exact
          path="/fleet/resources/create"
          component={DriverEditorPageResolver}
        />
        <AuthRoute exact path="/fleet/vehicles" component={VehiclesPageResolver} />
        <RestrictedRoute
          permission={FLEET_VEHICLES_EDIT}
          exact
          path="/fleet/vehicles/:vehicleId/edit"
          component={VehicleEditorPageResolver}
        />
        <AuthRoute exact path="/fleet/vehicles/:vehicleId/inspection-data" component={VehicleInspectionDataResolver} />
        <AuthRoute
          exact
          path="/fleet/vehicles/:vehicleId/inspection-data/:tripInspectionId"
          component={VehicleTripInspectionDetailsResolver}
        />
        <RestrictedRoute
          permission={FLEET_VEHICLES_CREATE}
          exact
          path="/fleet/vehicles/create"
          component={VehicleEditorPageResolver}
        />
        <RestrictedRoute
          permission={FLEET_VEHICLES_CONFIGURE_VEHICLE_TYPES}
          exact
          path="/fleet/vehicles/config-vehicle-types"
          component={VehicleTypesPageResolver}
        />
        <AuthRoute exact path="/fleet/vehicles/:vehicleId" component={VehiclePageResolver} />
        <AuthRoute exact path="/fleet/containers" component={NewContainersPageResolver} />
        <Route exact path="/fleet/autodispatch/rubicondispatches/:token" component={RubiconDispatchesPageResolver} />
        <AuthRoute exact path="/fleet/facilities" component={FacilitiesPageResolver} />
        <AuthRoute
          exact
          path="/fleet/autodispatch/unassigned-weight-tickets"
          component={UnassignedWeightTicketsPageResolver}
        />
        <Route
          exact
          path="/fleet/autodispatch/unassigned-weight-tickets/:token"
          component={UnassignedWeightTicketsPageResolver}
        />

        <Route exact path="/fleet/geo-fences" component={GeoFencesPageResolver} />
        <Route exact path="/fleet/geo-fences/create" component={GeoFenceEditorPageResolver} />
        <Route exact path="/fleet/geo-fences/:id/edit" component={GeoFenceEditorPageResolver} />

        <Route exact path="/fleet/alerts" component={LocationAndCityAlertsPageResolver} />
        <Redirect exact from="/fleet/location-and-city-alerts" to="/fleet/alerts" />

        <Redirect to="/fleet/vehicles" />
      </Switch>
    </Page>
  );
};

export default Fleet;
