import React, { CSSProperties, useState } from 'react';
import { flatten } from 'lodash-es';
import { transparentize } from 'polished';
import Select, { SelectComponentsConfig, StylesConfig } from 'react-select';
import { WrappedFieldProps } from 'redux-form';

import { EnhancedOption, EnhancedOptionLabel, EnhancedOptionValue } from 'src/common/interfaces/Option';
import { theme } from '../styles';
import { FormError, FormGroup, FormLabel } from './styled';

const getControlBackgroundImage = (state: any) => {
  if (state.isFocused) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")';
  }

  if (state.isDisabled) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiM5Njk2OTYiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")';
  }

  return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2Utb3BhY2l0eT0iLjcwNCIvPjwvc3ZnPg==")';
};

const dropdownStyles: StylesConfig<EnhancedOption, false> = {
  control: (baseStyle: CSSProperties, state: any) => ({
    width: '100%',
    paddingRight: '26px',
    position: 'relative',
    background: 'right 2px center no-repeat',
    backgroundImage: getControlBackgroundImage(state),
    outline: 'none',
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
    borderColor: state.isFocused ? theme.brandPrimary : theme.grayLight,
    lineHeight: '22px',
    fontSize: '14px',
    color: theme.grayBase,
  }),
  valueContainer: (baseStyle: CSSProperties) => ({ ...baseStyle, padding: '0px 6px 2px 0' }),
  singleValue: (baseStyle: CSSProperties) => ({ ...baseStyle, top: '3px', transform: 'none' }),
  placeholder: (baseStyle: CSSProperties, state: any) => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    transform: 'none',
    textTransform: 'capitalize',
    color: theme.grayDark,
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    opacity: state.isDisabled ? '.4' : '1',
    fontSize: '14px',
    margin: '0',
  }),
  indicatorsContainer: () => ({ position: 'absolute', top: '4px', right: '22px' }),
  dropdownIndicator: () => ({ display: 'none' }),
  clearIndicator: (baseStyle: CSSProperties, state: any) => ({
    ...baseStyle,
    '&::after': {
      display: 'inline-block',
      width: '10px',
      height: '10px',
      content: '""',
      backgroundColor: state.isDisabled ? theme.grayDark : theme.brandPrimary,
      backgroundImage:
        'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01LjkxOC45TDUuMS4wODJhLjI2NC4yNjQgMCAwIDAtLjM4MiAwTDMgMS44IDEuMjgyLjA4MmEuMjY0LjI2NCAwIDAgMC0uMzgyIDBMLjA4Mi45YS4yNjQuMjY0IDAgMCAwIDAgLjM4MkwxLjggMyAuMDgyIDQuNzE4YS4yNjQuMjY0IDAgMCAwIDAgLjM4MmwuODE4LjgxOGMuMTEuMTEuMjczLjExLjM4MiAwTDMgNC4ybDEuNzE4IDEuNzE4QS4yOTUuMjk1IDAgMCAwIDQuOTEgNmEuMjk1LjI5NSAwIDAgMCAuMTkxLS4wODJsLjgxOC0uODE4YS4yNjQuMjY0IDAgMCAwIDAtLjM4Mkw0LjIgM2wxLjcxOC0xLjcxOGEuMjY0LjI2NCAwIDAgMCAwLS4zODJ6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=)',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    '& > svg': { display: 'none' },
  }),
  noOptionsMessage: (baseStyles: any) => ({
    ...baseStyles,
    textTransform: 'capitalize',
    lineHeight: '18px',
    fontSize: '12px',
  }),
  menu: (baseStyle: CSSProperties) => ({
    ...baseStyle,
    left: 'initial',
    top: 'initial',
    zIndex: 10001,
    width: 'auto',
    minWidth: '100%',
    marginTop: 0,
    borderRadius: '2px',
    boxShadow: `0 4px 8px ${transparentize(0.9, theme.grayBase)}`,
  }),
  option: (baseStyle: CSSProperties, state: any) => ({
    ...baseStyle,
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px 0 12px',
    minHeight: '30px',
    ':active': theme.grayLight,
    paddingRight: '30px',
    backgroundColor:
      (state.isSelected && transparentize(0.2, theme.brandPrimary)) ||
      (state.isFocused && theme.grayLight) ||
      'transparent',
    fontSize: '12px',
  }),
};

function revealOptions<V = EnhancedOptionValue, L = EnhancedOptionLabel>(
  options: EnhancedOption<V, L>[],
): EnhancedOption<V, L>[] {
  return flatten(options.map(option => [option].concat(revealOptions(option.options || []))));
}

export interface EnhancedDropdownProps<V = EnhancedOptionValue, L = EnhancedOptionLabel> extends WrappedFieldProps {
  options: EnhancedOption<V, L>[] | ((stage: 'enum' | 'singleValue') => EnhancedOption<V, L>[]);
  components?: SelectComponentsConfig<EnhancedOption<V, L>, false>;
  disabled?: boolean;
  fieldNotTouched?: boolean;
  id?: string;
  inline?: boolean;
  isClearable?: boolean;
  label?: string;
  margin?: string;
  defaultValue?: EnhancedOption<V, L>;
  placeholder?: string;
  raisedLabel?: boolean;
  selfFlexStart?: boolean;
  showErrorBeforeSubmit?: boolean;
  width?: string;
  isOptionDisabled?: (option: EnhancedOption<V, L>) => boolean;
  onConfirmChange?: (value?: V) => Promise<boolean>;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
}

export default function EnhancedDropdown<V = EnhancedOptionValue, L = EnhancedOptionLabel>({
  options: rawOptions,
  components,
  disabled,
  fieldNotTouched,
  label,
  margin,
  showErrorBeforeSubmit,
  width,
  raisedLabel,
  inline,
  selfFlexStart,
  isOptionDisabled,
  onConfirmChange,
  defaultValue,
  placeholder = '',
  input: { value, name, onChange },
  meta: { submitFailed, error },
}: EnhancedDropdownProps<V, L>) {
  const [inputText, setInputText] = useState<string>('');

  const onChangeHandler = async (option: EnhancedOption<V, L> | null | undefined) => {
    if (!!onConfirmChange && !(await onConfirmChange(option?.value))) {
      return;
    }

    onChange(option?.value);
  };

  const onInputChangeHandler = (inputText: string) => setInputText(inputText);

  const singleValueOptions = React.useMemo(
    () => (typeof rawOptions === 'function' ? rawOptions('singleValue') : rawOptions),
    [rawOptions],
  );

  const enumOptions = React.useMemo(
    () => (typeof rawOptions === 'function' ? rawOptions('enum') : rawOptions),
    [rawOptions],
  );

  const currentValue = React.useMemo(
    () => revealOptions(singleValueOptions).find(option => option.value === value) || defaultValue,
    [singleValueOptions, value, defaultValue],
  );

  return (
    <FormGroup
      margin={margin}
      hasValue={value || inputText}
      width={width}
      raisedLabel={raisedLabel}
      inline={inline}
      disabled={disabled}
      selfFlexStart={selfFlexStart}
    >
      {!!label && <FormLabel>{label}</FormLabel>}

      <Select
        inputId={name}
        isDisabled={disabled}
        components={components}
        options={enumOptions}
        styles={dropdownStyles as any}
        placeholder={placeholder}
        value={currentValue}
        onChange={onChangeHandler as any}
        onInputChange={onInputChangeHandler}
        isOptionDisabled={isOptionDisabled}
        filterOption={({ data }: { data: EnhancedOption<V, L> }, rawSearchValue) => {
          if (!rawSearchValue) {
            return true;
          }

          let searchValue = rawSearchValue.replace(/^\s+|\s+$/g, '').toLowerCase();
          let candidate = (typeof data.label === 'string' ? data.label : data.textSearchValue || '')
            .replace(/^\s+|\s+$/g, '')
            .toLowerCase();

          return candidate.indexOf(searchValue) !== -1;
        }}
      />

      {(submitFailed || showErrorBeforeSubmit) && error && !fieldNotTouched && <FormError>{error}</FormError>}
    </FormGroup>
  );
}
