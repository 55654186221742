import { createSelector } from 'reselect';
import { reduce, set, identity } from 'lodash-es';

import { State } from '../ducks/suspendedLocations';

const getSuspendedLocationsInitialValues = (suspendedLocationsState: State) => ({
  suspendedLocations: reduce(
    suspendedLocationsState.suspendedLocations,
    (initialValues, { customer, location, address, service, status, isChanged }) =>
      set(initialValues, `suspendedLocations${customer.id + location.id + service.id}`, {
        customer,
        location,
        address,
        service,
        status,
        isChanged,
      }),
    {},
  ),
});

const suspendedLocationsInitialValuesSelector = createSelector(
  getSuspendedLocationsInitialValues,
  identity,
);

export default suspendedLocationsInitialValuesSelector;
