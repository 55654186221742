import { connect, useDispatch } from 'react-redux';
import { formValueSelector, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { debounce, map } from 'lodash-es';

import { AppState } from 'src/store';
import { Button, ButtonSet, Grid, GridColumn, ModalSection } from '../../../core/components/styled';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DatePicker, Dropdown, Input, Switch, TypeAhead, TypedField } from 'src/core/components';
import { EquipmentConditionDropdown, EquipmentSizeDropdown, WasteTypeDropdown } from 'src/common/components';
import { getServiceName } from 'src/fleet/services/transformContainersLocationsForCreateEdit';
import { isRequired } from 'src/utils/services/validator';
import { loadContainersLocationsForCreateEdit } from 'src/fleet/ducks';
import { LOCATION_CUSTOMER_TYPE_ID } from 'src/common/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import containerEditorNewFormInitialValuesSelector from 'src/fleet/services/containerEditorNewFormInitialValuesSelector';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

export interface CreateEditContainerFormValues {
  address?: string;
  containerLocationType?: TechnicalType;
  containerNumber?: string;
  customer?: {
    equipmentSize?: TechnicalType;
    equipmentType?: TechnicalType;
    serviceContractId?: number;
    serviceType?: TechnicalType;
    wasteMaterialType?: TechnicalType;
  };
  customerName?: string;
  equipmentCondition?: TechnicalType;
  equipmentConditionId?: number;
  equipmentSize?: TechnicalType;
  equipmentType?: TechnicalType;
  equipmentTypeId?: number;
  locationId?: number | { locationId: number; serviceContractId: number };
  locationName?: string;
  serviceType?: TechnicalType;
  vendorId?: number;
  wasteMaterialType?: TechnicalType;
  wasteMaterialTypeId?: number;
}

interface ComponentProps extends CreateEditContainerFormValues {
  containerId?: number;
  vendorId?: number;
  initialValues?: CreateEditContainerFormValues;
  closeModal: () => void;
}

type Props = ComponentProps & InjectedFormProps<CreateEditContainerFormValues, ComponentProps>;

const CreateEditContainerForm: React.FC<Props> = ({ vendorId, equipmentTypeId, initialValues, handleSubmit }) => {
  const containerTypes = useSelector(state => state.common.containerTypes.containerTypes || []);
  const dispatch = useDispatch();

  const { customer } = initialValues;

  const serviceNameLabel =
    initialValues.containerLocationType?.id === LOCATION_CUSTOMER_TYPE_ID
      ? getServiceName(
          customer?.equipmentType?.technicalName,
          customer?.equipmentType?.name,
          customer?.equipmentSize?.technicalName,
          customer?.equipmentSize?.name,
          customer?.serviceType?.name,
          customer?.wasteMaterialType?.technicalName,
        )
      : initialValues.customerName;

  const defaultLocationIdOption =
    serviceNameLabel && initialValues.locationId
      ? { label: serviceNameLabel, value: initialValues.locationId }
      : undefined;

  const containerTypeOptions = map(containerTypes, type => ({
    label: type.name,
    options: map(type.subTypes, sybType => ({
      label: sybType.name,
      value: sybType.id,
    })),
  }));

  const loadContainersLocationsOptions = debounce((searchTerm, onOptionsLoaded) => {
    if (!vendorId || searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    loadContainersLocationsForCreateEdit(
      vendorId,
      undefined,
      undefined,
      undefined,
      searchTerm,
    )(dispatch).then(onOptionsLoaded);
  }, 500);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(submit('createEditContainerForm'));
      }}
    >
      <ModalSection padding="medium" overflow="visible">
        <Grid multiLine>
          <GridColumn size="12/12" padding="no small">
            <TypedField
              component={Input}
              name="containerNumber"
              validate={[isRequired]}
              props={{ label: translate('containers.containerNumber') }}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no small">
            <TypedField
              name="equipmentTypeId"
              component={Dropdown}
              props={{ label: translate('common.type'), options: containerTypeOptions as any }}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no small">
            <TypedField
              component={EquipmentSizeDropdown}
              name="equipmentSizeId"
              props={{
                equipmentTypeId: equipmentTypeId,
                dropdownProps: {
                  label: translate('common.size'),
                },
              }}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no small">
            <TypedField
              component={EquipmentConditionDropdown}
              name="equipmentConditionId"
              props={{
                label: translate('common.equipmentCondition'),
              }}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no small">
            {equipmentTypeId === 9 && (
              <TypedField
                component={WasteTypeDropdown}
                name="wasteMaterialTypeId"
                props={{
                  label: translate('common.wasteType'),
                }}
                validate={[isRequired]}
              />
            )}
          </GridColumn>
          <GridColumn size="12/12" padding="no small">
            <TypedField
              name="locationId"
              component={TypeAhead}
              props={{
                defaultOptions: defaultLocationIdOption ? [defaultLocationIdOption] : undefined,
                defaultValue: defaultLocationIdOption,
                getOptions: loadContainersLocationsOptions,
                label: `${translate('common.locationTypes.containerLocation')}`,
                isClearable: true,
              }}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no small">
            <TypedField component={Input} name="manufacturer" props={{ label: translate('containers.manufacturer') }} />
          </GridColumn>
          <GridColumn size="6/12" padding="no small">
            <TypedField
              name="warrantyExpirationDate"
              component={DatePicker}
              props={{
                disabledDays: [{ before: TODAY }],
                tabletAlignLeft: true,
                disableFormSubmitOnDateChange: true,
                margin: 'no',
                label: translate('containers.warrantyExpiration'),
              }}
            />
          </GridColumn>
          <GridColumn size="6/12" padding="no small">
            <TypedField
              name="isUnavailable"
              component={Switch}
              props={{
                label: translate('containers.unavailable'),
              }}
            />
          </GridColumn>
          <GridColumn size="12/12">
            <ButtonSet margin="large no no">
              <Button type="submit" color="primary" id="save-container-button">
                {translate('common.save')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </ModalSection>
    </form>
  );
};

const formSelector = formValueSelector('createEditContainerForm');
const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const vendorId = currentVendorIdSelector(state.account.login, state.vendors.defaultVendor);

  return {
    initialValues: ownProps.containerId
      ? containerEditorNewFormInitialValuesSelector(state.fleet.container, vendorId)
      : { vendorId },
    equipmentTypeId: formSelector(state, 'equipmentTypeId'),
    vendorId,
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'createEditContainerForm',
    onSubmitFail: focusFirstInvalidField,
    enableReinitialize: true,
  })(CreateEditContainerForm),
);
