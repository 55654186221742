import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const ROUTE_PLANNER_VEHICLE_TYPES_COOKIE = 'routePlannerVehicleTypes';
export const ROUTE_PLANNER_VEHICLE_TYPE_COOKIE = 'routePlannerVehicleType';
export const ROUTE_PLANNER_DAYS_OF_SERVICE_COOKIE = 'routePlannerDaysOfService';
export const ROUTE_PLANNER_SELECTED_DATE = 'routePlannerSelectedDate';

export const ROUTE_PLANNER_VEHICLE_TYPES_COOKIE_EXPIRATION = 365;

export const ROUTE_PLANNER_VEHICLE_TYPES_FORM_NAME = 'routePlannerVehicleTypesForm';

export const DAILY_REOCCURRING_FILTERS_FORM = 'dailyAndReoccurringStatisticsFiltersForm';

const ROUTES_METRIC_ID = 1;
const DRIVERS_METRIC_ID = 2;
const VEHICLES_METRIC_ID = 3;
const SERVICE_ZONE_METRIC_ID = 4;
const SUPERVISORS_METRIC_ID = 5;
const STATUS_METRIC_ID = 6;
const VEHICLE_TYPE_METRIC_ID = 7;

export const ROUTE_PLANNER_METRICS = mapKeys(
  [
    { metricTypeId: ROUTES_METRIC_ID, name: translate('routes.routes') },
    { metricTypeId: DRIVERS_METRIC_ID, name: translate('vehicles.drivers') },
    { metricTypeId: VEHICLES_METRIC_ID, name: translate('vehicles.vehicles') },
    { metricTypeId: SERVICE_ZONE_METRIC_ID, name: translate('routes.serviceZone') },
    { metricTypeId: SUPERVISORS_METRIC_ID, name: translate('common.supervisors') },
    { metricTypeId: STATUS_METRIC_ID, name: translate('common.status') },
    { metricTypeId: VEHICLE_TYPE_METRIC_ID, name: translate('common.vehicleType') },
  ],
  'metricTypeId',
);
