import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, WrappedFieldInputProps } from 'redux-form';
import { orderBy } from 'lodash-es';

import { Dropdown } from '../../core/components';
import { wasteAuditExceptionsSelector } from '../ducks';
import translate from '../../core/services/translate';
import { AppState } from '../../store';

interface ComponentProps {
  input: WrappedFieldInputProps;
  placeholder?: string;
  withPlaceholder?: boolean;
  label?: string;
  withLabel?: boolean;
  dropdownProps?: any;
}

interface Props extends ComponentProps {
  exceptions?: any[];
}

class ExceptionDropdown extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      label,
      withLabel,
      placeholder,
      withPlaceholder,
      exceptions = [],
      dropdownProps,
    } = this.props;

    const exceptionsOptions = orderBy(
      exceptions.filter(exception => exception.isEnabled),
      ['label'],
    );

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.exception') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.exception') : undefined)}
        component={Dropdown}
        options={exceptionsOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  exceptions: wasteAuditExceptionsSelector(state.insights.wasteAuditConfiguration),
});

export default connect(mapStateToProps)(ExceptionDropdown);
