import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadStreetSweeperSegmentList as doLoadStreetSweeperSegmentList } from '../services/streetSweeperSegmentList';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';

// Constants
const START_LOAD = 'routes/streetSweeperSegmentList/START_LOAD';
const COMPLETE_LOAD = 'routes/streetSweeperSegmentList/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/streetSweeperSegmentList/FAIL_LOAD';
const RESET = 'routes/streetSweeperSegmentList/RESET';

// Initial State
type State = {
  streetSweeperSegmentList: StreetNetwork[];
  isLoading: boolean;
};

const initialState: State = {
  streetSweeperSegmentList: [],
  isLoading: false,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSweeperSegmentList: action.streetSweeperSegmentList,
        },
      });
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSweeperSegmentList: [],
        },
      });
    case RESET:
      return update(state, {
        $merge: initialState,
      });
    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (streetSweeperSegmentList: StreetNetwork) => ({
  type: COMPLETE_LOAD,
  streetSweeperSegmentList,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const reset = () => {
  return {
    type: RESET,
  };
};

// Async Actions
export const loadStreetSweeperSegmentList =
  (vendorId: number, segmentIds: number[], noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoad());
    const loadStreetSweeperSegmentListPromise = doLoadStreetSweeperSegmentList(vendorId, segmentIds);
    loadStreetSweeperSegmentListPromise
      .then((streetSweeperSegmentList: StreetNetwork) => dispatch(completeLoad(streetSweeperSegmentList)))
      .catch(() => dispatch(failLoad()));
    return loadStreetSweeperSegmentListPromise;
  };
