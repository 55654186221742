import { camelCase } from 'lodash';

import { CITY_ALERT_TYPES } from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlert } from '../interfaces/CityAlert';
import { CityAlertSetting, CityAlertSettings } from '../interfaces/CityAlertSettings';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

export const transformLoadCityAlertSettings = (data: CityAlertSettings) => {
  return {
    ...data,
    cityAlerts: data.cityAlertTypes.map((cityAlert: CityAlertSetting) => {
      return {
        ...cityAlert,
        cityAlertType: {
          ...cityAlert.cityAlertType,
          name: translate(createTranslationKey(cityAlert.cityAlertType.technicalName, 'vendors.cityAlerts')),
        },
      };
    }),
  };
};

export const transformLoadCityAlerts = (data: CityAlert[]) => {
  return data.map((cityAlert: CityAlert) => {
    return {
      ...cityAlert,
      cityAlertType: {
        name: cityAlert.cityAlertTypeId && CITY_ALERT_TYPES[cityAlert.cityAlertTypeId].name,
        technicalName: camelCase(cityAlert.cityAlertTypeId && CITY_ALERT_TYPES[cityAlert.cityAlertTypeId].name),
        id: cityAlert.cityAlertTypeId,
      },
    };
  });
};
