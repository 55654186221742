import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { PanelSection, Text, Grid, GridColumn } from '../../../core/components/styled';
import { loadProcessedServices } from '../../ducks/processedServices';
import pricingInfoIntialValueSelector from '../../services/pricingInfoIntialValueSelector';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface ComponentProps extends RouteComponentProps<RouteParams> {
  vendorId: number;
}

interface PropsWithoutReduxForm extends ComponentProps {
  pricingInfo: any;
  initialValues: any;
  loadProcessedServices(...args: any[]): any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class PricingInfoProcessedForm extends PureComponent<Props> {
  handleSubmit = async (event: any) => {
    const {
      match: {
        params: { token },
      },
      vendorId,
    } = this.props;
    const date = moment(event).format('MM/DD/YYYY');

    await this.props.loadProcessedServices(vendorId, token, date);
  };

  render() {
    const { pricingInfo } = this.props;

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <PanelSection centered withBorder padding="sMedium">
          <Grid padding="no small no no" multiLine>
            {/* <OpportunityInfoIcon color="primary" margin="xSmall xSmall xxSmall" /> */}

            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block weight="medium" margin="no no xxSmall">
                  {translate('Recurring Services Total')}:
                </Text>
                <Text block weight="medium" margin="no xSmall xxSmall">
                  ${pricingInfo.recurringServicesTotal}
                </Text>
              </PanelSection>
            </GridColumn>
            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block weight="medium" margin="no no xxSmall">
                  {translate('On-Call Services Total')}:
                </Text>
                <Text block weight="medium" margin="no xSmall xxSmall">
                  ${pricingInfo.onCallServiceTotal}
                </Text>
              </PanelSection>
            </GridColumn>
            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block weight="medium" margin="no no xxSmall">
                  {translate('Grand Total')}:
                </Text>
                <Text block weight="medium" margin="no xSmall xxSmall">
                  ${pricingInfo.grandTotal}
                </Text>
              </PanelSection>
            </GridColumn>

            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block margin="no no xxSmall" weight="light" size="small">
                  {translate('Recurring Services Total Unpaid')}:
                </Text>
                <Text block margin="no xSmall xxSmall" weight="light" size="small">
                  ${pricingInfo.recurringServicesTotalUnPaid}
                </Text>
              </PanelSection>
            </GridColumn>
            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block margin="no no xxSmall" weight="light" size="small">
                  {translate('On-Call Services Total Unpaid')}:
                </Text>
                <Text block margin="no xSmall xxSmall" weight="light" size="small">
                  ${pricingInfo.onCallServicesTotalUnPaid}
                </Text>
              </PanelSection>
            </GridColumn>
            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block margin="no no xxSmall" weight="light" size="small">
                  {translate('Total Unpaid')}:
                </Text>
                <Text block margin="no xSmall xxSmall" weight="light" size="small">
                  ${pricingInfo.totalUnPaid}
                </Text>
              </PanelSection>
            </GridColumn>

            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block margin="no no xxSmall" weight="light" size="small">
                  {translate('Recurring Services Total Paid')}:
                </Text>
                <Text block margin="no xSmall xxSmall" weight="light" size="small">
                  ${pricingInfo.recurringServicesTotalPaid}
                </Text>
              </PanelSection>
            </GridColumn>
            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block margin="no no xxSmall" weight="light" size="small">
                  {translate('On-Call Services Total Paid')}:
                </Text>
                <Text block margin="no xSmall xxSmall" weight="light" size="small">
                  ${pricingInfo.onCallServicesTotalPaid}
                </Text>
              </PanelSection>
            </GridColumn>
            <GridColumn padding="no no no" size="4/12" align="left">
              <PanelSection>
                <Text block margin="no no xxSmall" weight="light" size="small">
                  {translate('Total Paid')}:
                </Text>
                <Text block margin="no xSmall xxSmall" weight="light" size="small">
                  $ {pricingInfo.totalPaid}
                </Text>
              </PanelSection>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  pricingInfo: state.payments.processedServices.pricingInfo,
  initialValues: pricingInfoIntialValueSelector(state.payments.processedServices.pricingInfo),
});

const mapDispatchToProps = { loadProcessedServices };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'pricingInfoProcessed',
      enableReinitialize: true,
    })(PricingInfoProcessedForm),
  ),
);
