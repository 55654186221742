import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CITY_ALERTS_ICON_TYPES } from 'src/common/constants';
import {
  LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE,
  LOCATION_AND_CITY_ALERTS_LAYER,
} from 'src/fleet/constants/locationAndCityAlerts';
import { RouteMapFeature, clearRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import CityAlertsGLPopupResolver from 'src/routes/components/pages/routes/routePageSections/routeMap/cityAlerts/CityAlertsGLPopupResolver';
import LocationAndCityAlertsGLLayer from './LocationAndCityAlertsGLLayer';

const mapImages = CITY_ALERTS_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const LocationAndCityAlertsGL: FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        dispatch(clearRouteMapSelectedFeature());

        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE &&
              feature.properties?.layer === LOCATION_AND_CITY_ALERTS_LAYER,
          );

        if (!!feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.cityAlert, Number(feature.id)));
        }
      });

      map.on('mousemove', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true || !!feature.properties?.cluster_id,
        );

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <LocationAndCityAlertsGLLayer />
      <CityAlertsGLPopupResolver isSourceLocationAndCityAlerts />
    </>
  );
};

export default LocationAndCityAlertsGL;
