import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const EC = 1;
export const FC = 2;
export const MC = 3;

const CHANGE_REQUESTS_TYPE = mapKeys(
  [
    { id: EC, name: translate('opportunity.requestType.ec') },
    { id: FC, name: translate('opportunity.requestType.fc') },
    { id: MC, name: translate('opportunity.requestType.mc') },
  ],
  'id',
);

export default CHANGE_REQUESTS_TYPE;
