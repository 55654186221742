import { Dispatch, AnyAction } from 'redux';
import { findIndex } from 'lodash-es';
import update from 'immutability-helper';

import { MaterialTicketsState, MaterialTicket } from '../interfaces/MaterialTickets';
import {
  createMaterialTicket,
  updateMaterialTicket,
  deleteMaterialTicket as doDeleteMaterialTicket,
  loadMaterialLocations as doLoadMaterialLocations,
  loadMaterialTicket as doLoadMaterialTicket,
  loadMaterialTickets as doLoadMaterialTickets,
  loadMaterialPickups as doLoadMaterialPickups,
  loadMaterialTypeValues as doLoadMaterialTypeValues,
  loadUnitOfMeasureValues as doLoadUnitOfMeasureValues,
  uploadMaterialTicketImage as doUploadMaterialTicketImage,
} from '../services/materialTickets';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Facility } from 'src/common/interfaces/Facility';

// Actions
const START_LOAD = 'routes/materialTickets/START_LOAD';
const COMPLETE_LOAD = 'routes/materialTickets/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/materialTickets/FAIL_LOAD';
const START_LOAD_MATERIAL_PICKUPS = 'routes/materialTickets/START_LOAD_MATERIAL_PICKUPS';
const COMPLETE_LOAD_MATERIAL_PICKUPS = 'routes/materialTickets/COMPLETE_LOAD_MATERIAL_PICKUPS';
const FAIL_LOAD_MATERIAL_PICKUPS = 'routes/materialTickets/FAIL_LOAD_MATERIAL_PICKUPS';
const START_SAVE = 'routes/materialTickets/START_SAVE';
const COMPLETE_SAVE = 'routes/materialTickets/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/materialTickets/FAIL_SAVE';
const START_LOAD_MATERIAL_TICKET = 'routes/materialTickets/START_LOAD_MATERIAL_TICKET';
const COMPLETE_LOAD_MATERIAL_TICKET = 'routes/materialTickets/COMPLETE_LOAD_MATERIAL_TICKET';
const FAIL_LOAD_MATERIAL_TICKET = 'routes/materialTickets/FAIL_LOAD_MATERIAL_TICKET';
const START_DELETE = 'routes/materialTickets/START_DELETE';
const COMPLETE_DELETE = 'routes/materialTickets/COMPLETE_DELETE';
const FAIL_DELETE = 'routes/materialTickets/FAIL_DELETE';
const RESET = 'routes/materialTickets/RESET';
const START_LOAD_UNIT_OF_MEASURE = 'routes/materialTickets/START_LOAD_UNIT_OF_MEASURE';
const COMPLETE_LOAD_UNIT_OF_MEASURE = 'routes/materialTickets/COMPLETE_LOAD_UNIT_OF_MEASURE';
const FAIL_LOAD_UNIT_OF_MEASURE = 'routes/materialTickets/FAIL_LOAD_UNIT_OF_MEASURE';
const START_LOAD_MATERIAL_TYPE = 'routes/materialTickets/START_LOAD_MATERIAL_TYPE';
const COMPLETE_LOAD_MATERIAL_TYPE = 'routes/materialTickets/COMPLETE_LOAD_MATERIAL_TYPE';
const FAIL_LOAD_MATERIAL_TYPE = 'routes/materialTickets/FAIL_LOAD_MATERIAL_TYPE';
const START_LOAD_MATERIAL_LOCATIONS = 'routes/materialTickets/START_LOAD_MATERIAL_LOCATIONS';
const COMPLETE_LOAD_MATERIAL_LOCATIONS = 'routes/materialTickets/COMPLETE_LOAD_MATERIAL_LOCATIONS';
const FAIL_LOAD_MATERIAL_LOCATIONS = 'routes/materialTickets/FAIL_LOAD_MATERIAL_LOCATIONS';
const START_UPLOAD_MATERIAL_IMAGE = 'routes/materialTickets/START_UPLOAD_MATERIAL_IMAGE';
const COMPLETE_UPLOAD_MATERIAL_IMAGE = 'routes/materialTickets/COMPLETE_UPLOAD_MATERIAL_IMAGE';
const FAIL_UPLOAD_MATERIAL_IMAGE = 'routes/materialTickets/FAIL_UPLOAD_MATERIAL_IMAGE';

// Initial state
const initialState: MaterialTicketsState = {
  isDeleting: false,
  isLinkingMaterialTicketImage: false,
  isLoading: false,
  isLoadingMaterialLocations: false,
  isLoadingMaterialPickups: false,
  isLoadingMaterialTicket: false,
  isLoadingMaterialType: false,
  isLoadingUnitOfMeasure: false,
  isSaving: false,
  isUploadingMaterialImage: false,
  isUploadingMaterialTicketImage: false,
  materialLocations: [],
  materialPickups: [],
  materialTicket: {} as MaterialTicket,
  materialTickets: [],
  materialTypeValues: [],
  unitOfMeasureValues: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          materialTickets: action.materialTickets,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          materialTickets: undefined,
        },
      });

    case START_LOAD_MATERIAL_PICKUPS:
      return update(state, {
        $merge: {
          isLoadingMaterialPickups: true,
        },
      });

    case COMPLETE_LOAD_MATERIAL_PICKUPS:
      return update(state, {
        $merge: {
          isLoadingMaterialPickups: false,
          materialPickups: action.materialPickups,
        },
      });

    case FAIL_LOAD_MATERIAL_PICKUPS:
      return update(state, {
        $merge: {
          isLoadingMaterialPickups: false,
          materialPickups: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          materialTicket: action.materialTicket,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          materialTicket: undefined,
        },
      });

    case START_LOAD_MATERIAL_TICKET:
      return update(state, {
        $merge: {
          isLoadingMaterialTicket: true,
        },
      });

    case COMPLETE_LOAD_MATERIAL_TICKET:
      return update(state, {
        $merge: {
          isLoadingMaterialTicket: false,
          materialTicket: action.materialTicket,
        },
      });

    case FAIL_LOAD_MATERIAL_TICKET:
      return update(state, {
        $merge: {
          isLoadingMaterialTicket: false,
          materialTickets: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const materialTicketIndex = findIndex(state.materialTickets, { id: action.materialTicketId });
      return update(state, {
        materialTickets: { $splice: [[materialTicketIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_LOAD_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: true,
        },
      });

    case COMPLETE_LOAD_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: false,
          unitOfMeasureValues: action.unitOfMeasureValues,
        },
      });

    case FAIL_LOAD_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: false,
          unitOfMeasureValues: undefined,
        },
      });

    case START_LOAD_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: true,
        },
      });

    case COMPLETE_LOAD_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: false,
          materialTypeValues: action.materialTypeValues,
        },
      });

    case FAIL_LOAD_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: false,
          materialTypeValues: undefined,
        },
      });

    case START_LOAD_MATERIAL_LOCATIONS:
      return update(state, {
        $merge: {
          isLoadingMaterialLocations: true,
        },
      });

    case COMPLETE_LOAD_MATERIAL_LOCATIONS:
      return update(state, {
        $merge: {
          isLoadingMaterialLocations: false,
          materialLocations: action.materialLocations,
        },
      });

    case FAIL_LOAD_MATERIAL_LOCATIONS:
      return update(state, {
        $merge: {
          isLoadingMaterialLocations: false,
          materialLocations: undefined,
        },
      });

    case START_UPLOAD_MATERIAL_IMAGE:
      return update(state, {
        $merge: {
          isUploadingMaterialImage: true,
        },
      });

    case COMPLETE_UPLOAD_MATERIAL_IMAGE:
      return update(state, {
        $merge: {
          isUploadingMaterialImage: false,
        },
      });

    case FAIL_UPLOAD_MATERIAL_IMAGE:
      return update(state, {
        $merge: {
          isUploadingMaterialImage: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (materialTickets: MaterialTicket[]) => ({
  type: COMPLETE_LOAD,
  materialTickets,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadMaterialPickups = () => ({
  type: START_LOAD_MATERIAL_PICKUPS,
});

const completeLoadMaterialPickups = (materialPickups: MaterialTicket[]) => ({
  type: COMPLETE_LOAD_MATERIAL_PICKUPS,
  materialPickups,
});

const failLoadMaterialPickups = () => ({
  type: FAIL_LOAD_MATERIAL_PICKUPS,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (materialTicket: MaterialTicket) => ({
  type: COMPLETE_SAVE,
  materialTicket,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadMaterialTicket = () => ({
  type: START_LOAD_MATERIAL_TICKET,
});

const completeLoadMaterialTicket = (materialTicket: MaterialTicket) => ({
  type: COMPLETE_LOAD_MATERIAL_TICKET,
  materialTicket,
});

const failLoadMaterialTicket = () => ({
  type: FAIL_LOAD_MATERIAL_TICKET,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (materialTicketId: number) => ({
  type: COMPLETE_DELETE,
  materialTicketId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startLoadUnitOfMeasureValues = () => ({
  type: START_LOAD_UNIT_OF_MEASURE,
});

const completeLoadUnitOfMeasureValues = (unitOfMeasureValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_UNIT_OF_MEASURE,
  unitOfMeasureValues,
});

const failLoadUnitOfMeasureValues = () => ({
  type: FAIL_LOAD_UNIT_OF_MEASURE,
});

const startLoadMaterialTypeValues = () => ({
  type: START_LOAD_MATERIAL_TYPE,
});

const completeLoadMaterialType = (materialTypeValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_MATERIAL_TYPE,
  materialTypeValues,
});

const failLoadMaterialType = () => ({
  type: FAIL_LOAD_MATERIAL_TYPE,
});

const startLoadMaterialLocations = () => ({
  type: START_LOAD_MATERIAL_LOCATIONS,
});

const completeLoadMaterialLocations = (materialLocations: Facility[]) => ({
  type: COMPLETE_LOAD_MATERIAL_LOCATIONS,
  materialLocations,
});

const failLoadMaterialLocations = () => ({
  type: FAIL_LOAD_MATERIAL_LOCATIONS,
});

const startUploadMaterialTicketImage = () => ({
  type: START_UPLOAD_MATERIAL_IMAGE,
});

const completeUploadMaterialTicketImage = () => ({
  type: COMPLETE_UPLOAD_MATERIAL_IMAGE,
});

const failUploadMaterialTicketImage = () => ({
  type: FAIL_UPLOAD_MATERIAL_IMAGE,
});

export const saveMaterialTicket = (routeId: number, materialTicket: MaterialTicket) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveMaterialTicketPromise = materialTicket.id
    ? updateMaterialTicket(materialTicket)
    : createMaterialTicket(routeId, materialTicket);

  saveMaterialTicketPromise
    .then((materialTicket: MaterialTicket) => dispatch(completeSave(materialTicket)))
    .catch(() => dispatch(failSave()));
  return saveMaterialTicketPromise;
};

export const loadMaterialTickets = (routeId: number, routeLocationId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadMaterialTicketsPromise = doLoadMaterialTickets(routeId, routeLocationId);
  loadMaterialTicketsPromise
    .then(materialTickets => dispatch(completeLoad(materialTickets)))
    .catch(() => dispatch(failLoad()));
  return loadMaterialTicketsPromise;
};

export const loadMaterialPickups = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadMaterialPickups());
  const loadMaterialPickupsPromise = doLoadMaterialPickups(vendorId, routeId);
  loadMaterialPickupsPromise
    .then(materialPickups => dispatch(completeLoadMaterialPickups(materialPickups)))
    .catch(() => dispatch(failLoadMaterialPickups()));
  return loadMaterialPickupsPromise;
};

export const loadMaterialTicket = (materialTicketId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadMaterialTicket());
  const loadMaterialTicketPromise = doLoadMaterialTicket(materialTicketId);
  loadMaterialTicketPromise
    .then(materialTicket => dispatch(completeLoadMaterialTicket(materialTicket)))
    .catch(() => dispatch(failLoadMaterialTicket()));
  return loadMaterialTicketPromise;
};

export const deleteMaterialTicket = (materialTicketId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteMaterialTicketPromise = doDeleteMaterialTicket(materialTicketId);
  deleteMaterialTicketPromise
    .then(() => dispatch(completeDelete(materialTicketId)))
    .catch(() => dispatch(failDelete()));
  return deleteMaterialTicketPromise;
};

export const loadUnitOfMeasureValues = () => (dispatch: Dispatch) => {
  dispatch(startLoadUnitOfMeasureValues());
  const loadUnitOfMeasureValuesPromise = doLoadUnitOfMeasureValues();
  loadUnitOfMeasureValuesPromise
    .then((unitOfMeasureValues: TechnicalType[]) => dispatch(completeLoadUnitOfMeasureValues(unitOfMeasureValues)))
    .catch(() => dispatch(failLoadUnitOfMeasureValues()));
  return loadUnitOfMeasureValuesPromise;
};

export const loadMaterialTypeValues = () => (dispatch: Dispatch) => {
  dispatch(startLoadMaterialTypeValues());
  const loadMaterialTypePromise = doLoadMaterialTypeValues();
  loadMaterialTypePromise
    .then((materialTypeValues: TechnicalType[]) => dispatch(completeLoadMaterialType(materialTypeValues)))
    .catch(() => dispatch(failLoadMaterialType()));
  return loadMaterialTypePromise;
};

export const loadMaterialLocations = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadMaterialLocations());
  const loadMaterialLocationsPromise = doLoadMaterialLocations(vendorId);
  loadMaterialLocationsPromise
    .then((materialLocations: Facility[]) => dispatch(completeLoadMaterialLocations(materialLocations)))
    .catch(() => dispatch(failLoadMaterialLocations()));
  return loadMaterialLocationsPromise;
};

export const uploadMaterialTicketImage = (file: File, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startUploadMaterialTicketImage());

  const uploadMaterialTicketImagePromise = doUploadMaterialTicketImage(file, routeId);
  uploadMaterialTicketImagePromise
    .then(() => dispatch(completeUploadMaterialTicketImage()))
    .catch(() => dispatch(failUploadMaterialTicketImage()));
  return uploadMaterialTicketImagePromise;
};

export const resetMaterialTickets = () => ({
  type: RESET,
});
