import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { date } from '../../utils/services/formatter';
import { VendorEditorFormValues } from '../components/forms/VendorEditorForm';
import {
  RUBICON_X,
  RUBICON_XY,
  RUBICON_XYZ,
  RUBICON_XZ,
  RUBICON_Y,
  RUBICON_YZ,
  RUBICON_Z,
  TIME_12AM,
  TZ_EST,
} from '../constants';
import { Vendor } from '../interfaces/Vendors';

export const breakDownDeviceRoleTypeId = (deviceRoleTypeId?: number) => {
  switch (deviceRoleTypeId) {
    case RUBICON_X:
      return [RUBICON_X];
    case RUBICON_Y:
      return [RUBICON_Y];
    case RUBICON_XY:
      return [RUBICON_X, RUBICON_Y];
    case RUBICON_Z:
      return [RUBICON_Z];
    case RUBICON_XZ:
      return [RUBICON_X, RUBICON_Z];
    case RUBICON_YZ:
      return [RUBICON_Y, RUBICON_Z];
    case RUBICON_XYZ:
      return [RUBICON_X, RUBICON_Y, RUBICON_Z];
    default:
      return [];
  }
};

const getVendorEditorFormInitialValues = ({
  isActive,
  startDate,
  endDate,
  speedingThreshold,
  isAutoSnapToCurb,
  ...vendor
}: Vendor): VendorEditorFormValues => ({
  isActive: isActive !== undefined && isActive !== null ? isActive : true,
  noOfLicenses: 0,
  routeStartTime: TIME_12AM,
  speedingThreshold: speedingThreshold || 10,
  systemOfMeasurementId: 1,
  timeZone: TZ_EST,
  deviceRoles: vendor.deviceRoleTypeId ? breakDownDeviceRoleTypeId(vendor.deviceRoleTypeId) : undefined,
  startDate: startDate ? date(startDate) : undefined,
  endDate: endDate ? date(endDate) : undefined,
  xDeviceTypeId: vendor.xDeviceType?.id,
  isAutoSnapToCurb: isAutoSnapToCurb || false,
  ...vendor,
});

const vendorEditorFormInitialValuesSelector = createSelector(getVendorEditorFormInitialValues, identity);

export default vendorEditorFormInitialValuesSelector;
