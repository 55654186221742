import { GeoFenceAlert } from '../interfaces/GeoFenceSettings';

export const OFF_ROUTE = 0;
export const OFF_ROUTE_STATIONARY = 1;
export const OUTSIDE_GEO_FENCE = 2;
export const INSIDE_GEO_FENCE = 3;

const OFF_ROUTE_GEO_FENCE_ALERT = {
  id: OFF_ROUTE,
  translationKey: 'offRoute',
  translationKeyAlert: 'offRouteAlert',
  timeFields: [{ name: 'offRouteAlertTime' }],
};

const OFF_ROUTE_STATIONARY_GEO_FENCE_ALERT = {
  id: OFF_ROUTE_STATIONARY,
  translationKey: 'offRouteStationary',
  translationKeyAlert: 'offRouteStationaryAlert',
  timeFields: [{ name: 'offRouteStationaryAlertTime' }],
};

const OUTSIDE_GEO_FENCE_ALERT = {
  id: OUTSIDE_GEO_FENCE,
  translationKey: 'outsideGeoFence',
  timeFields: [
    { name: 'countTimeAfter', translationKey: 'countTimeAfter' },
    { name: 'alertUserAfter', translationKey: 'alertUserAfter' },
  ],
};

const INSIDE_GEO_FENCE_ALERT = {
  id: INSIDE_GEO_FENCE,
  translationKey: 'insideGeoFence',
  timeFields: [{ name: 'alertUserAfter', translationKey: 'alertUserAfter' }],
};

export const GEO_FENCE_ALERTS: GeoFenceAlert[] = [
  OFF_ROUTE_GEO_FENCE_ALERT,
  OFF_ROUTE_STATIONARY_GEO_FENCE_ALERT,
  OUTSIDE_GEO_FENCE_ALERT,
  INSIDE_GEO_FENCE_ALERT,
];

export const OUTSIDE_GEO_FENCE_GENERAL_ID = 0;
export const INSIDE_GEO_FENCE_GENERAL_ID = 1;

const OUTSIDE_GEO_FENCE_GENERAL_ALERT = {
  id: OUTSIDE_GEO_FENCE_GENERAL_ID,
  translationKey: 'outsideGeoFence',
  timeFields: [
    { name: 'countTimeAfter', translationKey: 'countTimeAfter' },
    { name: 'alertUserAfter', translationKey: 'alertUserAfter' },
  ],
};

const INSIDE_GEO_FENCE_GENERAL_ALERT = {
  id: INSIDE_GEO_FENCE_GENERAL_ID,
  translationKey: 'insideGeoFence',
  timeFields: [{ name: 'alertUserAfter', translationKey: 'alertUserAfter' }],
};

export const GEO_FENCE_GENERAL_ALERTS: GeoFenceAlert[] = [
  OUTSIDE_GEO_FENCE_GENERAL_ALERT,
  INSIDE_GEO_FENCE_GENERAL_ALERT,
];

export const ROUTE_GEO_FENCE_GENERAL_ALERTS: GeoFenceAlert[] = [
  OFF_ROUTE_GEO_FENCE_ALERT,
  OFF_ROUTE_STATIONARY_GEO_FENCE_ALERT,
];
