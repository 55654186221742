import update from 'immutability-helper';
import { find, findIndex, identity } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { TODAY_FORMATTED } from 'src/core/constants';
import {
  LoadRoutesParams,
  deleteRoutes as doDeleteRoutes,
  loadRoutes as doLoadRoutes,
} from 'src/routes/services/dispatchBoardRoutes';

// Actions
const START_LOAD = 'dispatchBoard/targetRoutes/START_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/targetRoutes/COMPLETE_LOAD';
const FAIL_LOAD = 'dispatchBoard/targetRoutes/FAIL_LOAD';
const ADD_ROUTE = 'dispatchBoard/targetRoutes/ADD_ROUTE';
const UPDATE_ROUTE = 'dispatchBoard/targetRoutes/UPDATE_ROUTE';
const START_DELETE_ROUTE = 'dispatchBoard/targetRoutes/START_DELETE_ROUTE';
const COMPLETE_DELETE_ROUTE = 'dispatchBoard/targetRoutes/COMPLETE_DELETE_ROUTE';
const FAIL_DELETE_ROUTE = 'dispatchBoard/targetRoutes/FAIL_DELETE_ROUTE';
const REMOVE_ROUTE = 'dispatchBoard/targetRoutes/REMOVE_ROUTE';
const RESET = 'dispatchBoard/targetRoutes/RESET';
const SET_TARGET_DATE = 'dispatchBoard/targetRoutes/SET_TARGET_DATE';

interface State {
  isLoading: boolean;
  isDeletingRoute: boolean;
  targetRoutes: any[];
  selectedDate: Date | string;
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  isDeletingRoute: false,
  targetRoutes: [],
  selectedDate: TODAY_FORMATTED,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          targetRoutes: action.targetRoutes.sort((a: any, b: any) => (a.name > b.name ? 1 : -1)),
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          targetRoutes: [],
        },
      });

    case ADD_ROUTE:
      return update(state, {
        targetRoutes: { $push: action.route },
      });

    case UPDATE_ROUTE: {
      const index = findIndex(state.targetRoutes, { id: action.route.id } as any);
      if (index === -1) return state;

      return update(state, {
        targetRoutes: {
          [index]: { $set: action.route } as any,
        },
      });
    }

    case START_DELETE_ROUTE:
      return update(state, {
        $merge: { isDeletingRoute: true },
      });

    case COMPLETE_DELETE_ROUTE: {
      const index = findIndex(state.targetRoutes, { id: action.routeId } as any);
      if (index === -1) return state;

      return update(state, { isDeletingRoute: { $set: false }, targetRoutes: { $splice: [[index, 1]] } });
    }

    case FAIL_DELETE_ROUTE:
      return update(state, {
        $merge: { isDeletingRoute: false },
      });

    case REMOVE_ROUTE: {
      const index = findIndex(state.targetRoutes, { id: action.routeId } as any);
      if (index === -1) return state;

      return update(state, { targetRoutes: { $splice: [[index, 1]] } });
    }

    case RESET:
      return update(state, { $merge: initialState });

    case SET_TARGET_DATE:
      return update(state, { $merge: { selectedDate: action.date } });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (targetRoutes: any[]) => ({
  type: COMPLETE_LOAD,
  targetRoutes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDeleteRoute = () => ({
  type: START_DELETE_ROUTE,
});

const completeDeleteRoute = (routeId: number) => ({
  type: COMPLETE_DELETE_ROUTE,
  routeId,
});

const failDeleteRoute = () => ({
  type: FAIL_DELETE_ROUTE,
});

export const removeDispatchBoardTargetRoute = (routeId: number) => ({
  type: REMOVE_ROUTE,
  routeId,
});

export const loadDispatchBoardTargetRoutes = (params: LoadRoutesParams) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const promise = doLoadRoutes(params);
  promise.then(routes => dispatch(completeLoad(routes))).catch(() => dispatch(failLoad()));
  return promise;
};

export const addDispatchBoardTargetRoute = (route: any) => ({
  type: ADD_ROUTE,
  route,
});

export const updateDispatchBoardTargetRoute = (route: any) => ({
  type: UPDATE_ROUTE,
  route,
});

export const deleteDispatchBoardTargetRoute = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startDeleteRoute());
  const promise = doDeleteRoutes([routeId]);
  promise.then(() => dispatch(completeDeleteRoute(routeId))).catch(() => dispatch(failDeleteRoute()));
  return promise;
};

export const resetDispatchBoardTargetRoutes = () => ({
  type: RESET,
});

// Selector
const getDispatchBoardTargetRouteById = (dispatchBoardTargetRoutesState: any, routeId: number) =>
  find(dispatchBoardTargetRoutesState.targetRoutes, ({ id }) => id === routeId);

export const dispatchBoardTargetRouteByIdSelector = createSelector(getDispatchBoardTargetRouteById, identity);

export const setTargetDate = (date: Date | string) => ({
  type: SET_TARGET_DATE,
  date,
});
