import React, { PureComponent } from 'react';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import moment from 'moment';

import {
  loadRecurringServices,
  updateRecurringServices,
  updateAcceptSelectedRecurringPaymentServices,
  resetRecurringService,
} from '../../ducks';
import { AcceptPaymentForSelectedRecurringModal } from '../modal';
import { RecurringServicesForm, PricingInfoForm, PricingInfoFormFooter, PaymentReportDateForm } from '../forms';
import pricingInfoIntialValueSelector from '../../services/pricingInfoIntialValueSelector';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageSubtitle,
} from '../../../common/components/styled';
import {
  Panel,
  PanelSectionGroup,
  PanelSection,
  Text,
  Grid,
  GridColumn,
  Button,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import confirm from '../../../core/services/confirm';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  pricingInfo: any;
  isLoading: boolean;
  isSaving: boolean;
  vendorId: number;
  haulerName?: string;
  workOrdersInfo?: any[];
  errorMessage?: string;
  reset: any;
  push: any;
  loadRecurringServices(...args: any[]): any;
  updateRecurringServices(...args: any[]): any;
  updateAcceptSelectedRecurringPaymentServices(...args: any[]): any;
  resetRecurringService(...args: any[]): any;
}

interface State {
  isAcceptPaymentForSelectedRecurringModalOpen: boolean;
  isExpanded: boolean;
}

const AcceptPaymentForSelectedRecurringModalMock = AcceptPaymentForSelectedRecurringModal as any;

class RecurringServicesPage extends PureComponent<Props, State> {
  readonly state = {
    isAcceptPaymentForSelectedRecurringModalOpen: false,
    isExpanded: false,
  };

  componentWillUnmount() {
    const { resetRecurringService } = this.props;

    resetRecurringService();
  }

  onDateChange = async (event: any) => {
    const { match, vendorId, loadRecurringServices } = this.props;
    const token = match.params.token;
    const date = moment(event).format('MM/DD/YYYY');

    await loadRecurringServices(vendorId, token, date);
  };

  // Accept
  openAcceptPaymentForSelectedRecurringModal = (event: any) => {
    this.setState({ isAcceptPaymentForSelectedRecurringModalOpen: true });
    event.stopPropagation();
    return false;
  };

  closeAcceptPaymentForSelectedRecurringModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('acceptPaymentForSelected');
    this.setState({ isAcceptPaymentForSelectedRecurringModalOpen: false });
  };

  toggle = () => {
    this.setState(({ isExpanded }) => ({
      isExpanded: !isExpanded,
    }));
  };

  render() {
    const { pricingInfo, isLoading, isSaving, haulerName, errorMessage, vendorId } = this.props;

    return (
      <PageContent>
        <PageHeader withSubTitle>
          <PageDetails>
            <PageTitleContainer>
              {/* {(!errorMessage || (!!size(workOrdersInfo) || workOrdersInfo.length > 1)) && (
                // <PageTitle>{translate('opportunity.opportunities.currentOpportunities')}</PageTitle>
              )} */}
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>
        <PageTitleContainer>
          <PageSubtitle size="medium" margin="small small small">
            {haulerName}
          </PageSubtitle>
        </PageTitleContainer>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <PaymentReportDateForm {...this.props} onDateChange={this.onDateChange} />
            <Panel>
              {!!size(pricingInfo) && <PricingInfoForm vendorId={vendorId} />}
              {(!size(pricingInfo) || pricingInfo.length < 1) && (
                <Text block weight="medium" size="large" margin="xxLarge xxGrande large ">
                  {translate('payment.noRecord')}
                </Text>
              )}
            </Panel>
            {errorMessage && (
              <Panel>
                <Text block weight="medium" size="large" color="alert" margin="xxLarge xxGrande large ">
                  Error:
                </Text>
                <Text block weight="medium" size="small" color="gray">
                  {errorMessage}
                </Text>
              </Panel>
            )}
          </PanelSectionGroup>
        </Panel>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isSaving}>
            <PanelSection centered>{!!size(pricingInfo) && <RecurringServicesForm vendorId={vendorId} />}</PanelSection>
          </PanelSectionGroup>
        </Panel>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <Grid padding="no no no no" multiLine>
              {!!size(pricingInfo) && (
                <GridColumn size="12/12" align="right">
                  {!!size(pricingInfo) && <PricingInfoFormFooter />}
                  <Button
                    background-color="transparent"
                    color="primary"
                    size="small"
                    onClick={this.openAcceptPaymentForSelectedRecurringModal}
                    disabled={pricingInfo.acceptedWOCount <= 0}
                    margin="small xSmall small"
                  >
                    {translate('payment.acceptPaymentForSelected')}
                  </Button>{' '}
                  {this.state.isAcceptPaymentForSelectedRecurringModalOpen && (
                    <AcceptPaymentForSelectedRecurringModalMock
                      {...this.props}
                      onCancel={this.closeAcceptPaymentForSelectedRecurringModal}
                    />
                  )}
                </GridColumn>
              )}
            </Grid>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  workOrdersInfo: state.payments.recurringServices.workOrdersInfo,
  pricingInfo: state.payments.recurringServices.pricingInfo,
  paymentInfo: state.payments.recurringServices.paymentInfo,
  issueType: state.payments.recurringServices.issueType,
  haulerName: state.payments.recurringServices.haulerName,
  errorMessage: state.payments.recurringServices.errorMessage,
  isLoading: state.payments.recurringServices.isLoading,
  isSaving: state.payments.recurringServices.isSaving,
  initialValues: pricingInfoIntialValueSelector(state.payments.recurringServices.pricingInfo),
});

const mapDispatchToProps = {
  loadRecurringServices,
  updateRecurringServices,
  updateAcceptSelectedRecurringPaymentServices,
  resetRecurringService,
  push,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecurringServicesPage));
