import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { loadRouteTemplateUnassignedLocations as doLoadRouteTemplateUnassignedLocations } from '../services/loadRouteTemplateUnassignedLocations';

// Actions
const START_LOAD = 'routes/unassignedLocations/START_LOAD';
const COMPLETE_LOAD = 'routes/unassignedLocations/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/unassignedLocations/FAIL_LOAD';
const RESET = 'routes/unassignedLocations/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  unassignedLocations?: any[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  unassignedLocations: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedLocations: action.unassignedLocations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (unassignedLocations: any[]) => ({
  type: COMPLETE_LOAD,
  unassignedLocations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteTemplateUnassignedLocations =
  (vendorId: number, vehicleTypeId: number, scheduledDay: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRouteTemplateUnassignedLocationsPromise = doLoadRouteTemplateUnassignedLocations(
      vendorId,
      vehicleTypeId,
      scheduledDay,
    );
    loadRouteTemplateUnassignedLocationsPromise
      .then(routeTemplateUnassignedLocations => dispatch(completeLoad(routeTemplateUnassignedLocations)))
      .catch(() => dispatch(failLoad()));
    return loadRouteTemplateUnassignedLocationsPromise;
  };
export const resetRouteTemplateUnassignedLocations = () => ({
  type: RESET,
});
