import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DuckFunction } from 'src/contracts/ducks';
import { loadLowInventoryPartHistory } from 'src/fleet/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import LowInventoryPartHistoryModal from './LowInventoryPartHistoryModal';

interface Props {
  closeModal: () => void;
  id: number;
  loadLowInventoryPartHistory: DuckFunction<typeof loadLowInventoryPartHistory>;
  partName: string | number;
  vendorId: number;
}

class LowInventoryPartHistoryModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadLowInventoryPartHistory, vendorId, id } = this.props;
    const promises = [loadLowInventoryPartHistory(vendorId, id)];
    return Promise.all(promises);
  };

  render() {
    const { closeModal, partName } = this.props;

    return (
      <Resolver
        successComponent={LowInventoryPartHistoryModal}
        successProps={{
          closeModal,
          partName,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadLowInventoryPartHistory };

export default connect(mapStateToProps, mapDispatchToProps)(LowInventoryPartHistoryModalResolver);
