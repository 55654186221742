import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { TypedField, DateRangePicker } from 'src/core/components';
import { DEFAULT_MAX_INTERVAL_AMOUNT } from 'src/core/constants/weekdays';

export interface DateRangeFormValues {
  dateRange: {
    from: string;
    to: string;
  };
}

interface PropsWithoutReduxForm {
  intervalAmount?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<DateRangeFormValues, PropsWithoutReduxForm>;

const DateRangeForm: React.FC<Props> = ({ intervalAmount }) => (
  <TypedField
    name="dateRange"
    component={DateRangePicker}
    props={{
      hasMarginLeft: 'smaller',
      alignRight: true,
      margin: 'no',
      width: '200px',
      maxInterval: { amount: intervalAmount || DEFAULT_MAX_INTERVAL_AMOUNT, unit: 'days' },
    }}
  />
);

export default reduxForm<DateRangeFormValues, PropsWithoutReduxForm>({
  form: 'DateRange',
  enableReinitialize: true,
})(DateRangeForm);
