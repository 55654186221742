import { DragEvent, MouseEvent, useEffect, useRef, useState } from 'react';

import { find, identity, sum, take } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import { createSelector } from 'reselect';

import { TABLE_ROW_HEIGHT_EXPANDED, TABLE_ROW_HEIGHT_LARGER, TABLE_ROW_MAX_ITEMS } from 'src/core/constants';
import { REACT_WINDOW_CLASS_NAME } from 'src/core/constants/reactWindow';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { DispatchBoardScrollMarker, DispatchBoardSubPanel } from 'src/routes/components/styled';
import { JobTypes, SELECTED_TRANSFERRED_STOPS } from 'src/routes/constants';
import { UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import {
  deleteDispatchBoardUnassignedJob,
  loadDispatchBoardUnassignedJobsCount,
  openMap,
  openMapWithRoute,
} from 'src/routes/ducks/dispatchBoard';
import { setSelectedJobIds, setSelectedJobIndexes } from 'src/routes/ducks/dispatchBoard/dispatchBoardUnassignedJobs';
import { DispatchBoardRouteJob } from 'src/routes/interfaces/DispatchBoardRouteJob';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import DispatchBoardDroppableList from './DispatchBoardDroppableList';
import { createDispatchBoardMultipleJobsDragGhost } from './DispatchBoardJobGhost';
import DispatchBoardUnassignedJob from './DispatchBoardUnassignedJob';
import DispatchBoardModals, { ModalTypes } from './common/ModalContainer';
import RouteHeaderContainer from './common/RouteHeaderContainer';
import { FilterFunctions, UnassignedJobsFilters } from './common/useDispatchBoardFilters';
import { getMultiSelectedJobs, sendJobSelectionNotifications } from './common/utils';

const containerHeightSelector = createSelector(
  (elementHeights: number[]) => sum(take(elementHeights, TABLE_ROW_MAX_ITEMS)),
  identity,
);

export const numberOfJobsText = (numberOfJobs: number) =>
  `${numberOfJobs} ${numberOfJobs === 1 ? translate('routes.job') : translate('routes.jobs')}`;

interface DispatchBoardUnassignedJobsProps {
  dispatchBoardSearchFilters: UnassignedJobsFilters;
  filteredUnassignedJobs: DispatchBoardRouteJob[];
  filterFunctions: FilterFunctions;
  isLoading: boolean;
  jobsTransferring: boolean;
  onClose: () => void;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
  refreshUnassignedJobs: () => void;
  toggleJobTransferring: (value: boolean) => void;
  toggleRouteEditorModal: (value: boolean, isCreate?: boolean) => void;
  unassignedJobsCount: number;
  vehicleTypeId: number;
}

const DispatchBoardUnassignedJobs = (props: DispatchBoardUnassignedJobsProps) => {
  const didMountRef = useRef(false);

  const dispatch = useDispatch();

  const {
    dispatchBoardSearchFilters,
    filteredUnassignedJobs,
    filterFunctions,
    isLoading,
    jobsTransferring,
    onClose,
    onJobDrop,
    refreshUnassignedJobs,
    toggleJobTransferring,
    toggleRouteEditorModal,
    unassignedJobsCount,
    vehicleTypeId,
  } = props;

  const { selectedJobIndexes: selectedUnassignedJobs, selectedJobIds: selectedUnassignedJobsId } = useSelector(
    s => s.routes.dispatchBoard.unassignedJobs,
  );
  const vendorId: number = useSelector(state =>
    currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  );

  const [modalsOpen, setModalsOpen] = useState<{
    jobEditor: boolean;
    routeEditor: boolean;
    routeLocationImages: boolean;
    routeLocationIssues: boolean;
    routeLocationNotes: boolean;
    weightTicket: boolean;
  }>({
    jobEditor: false,
    routeEditor: false,
    routeLocationImages: false,
    routeLocationIssues: false,
    routeLocationNotes: false,
    weightTicket: false,
  });
  const [openedJobId, setOpenedJobId] = useState<number | undefined>(undefined);

  const openedJob: DispatchBoardRouteJob | undefined = find(filteredUnassignedJobs, { id: openedJobId });

  const listRef = useRef<List | null>(null);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      refreshUnassignedJobs();
    }
  }, [refreshUnassignedJobs]);

  useEffect(() => {
    setOpenedJobId(undefined);
  }, [dispatchBoardSearchFilters, filteredUnassignedJobs.length]);

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [openedJobId]);

  useEffect(() => {
    if (jobsTransferring === false) {
      // clear selected jobs when transfer is complete
      dispatch(setSelectedJobIds([]));
      dispatch(setSelectedJobIndexes([]));
    }
  }, [dispatch, jobsTransferring]);

  // Reset openedJobId when unassigned jobs are refreshed
  const refreshAndResetOpenedJob = () => {
    setOpenedJobId(undefined);
    refreshUnassignedJobs();
  };

  const toggleJob = (event: MouseEvent, jobId: number) => {
    event.stopPropagation();
    setOpenedJobId(prevOpenedJobId => (prevOpenedJobId !== jobId ? jobId : undefined));
  };

  const toggleModal = (modal: ModalTypes, open: boolean = true, isHeader: boolean = false) => {
    setOpenedJobId(isHeader ? undefined : openedJobId);
    setModalsOpen({
      ...modalsOpen,
      [modal]: open,
    });

    if (!open) refreshAndResetOpenedJob();
  };

  const deleteJobs = async (jobsIds: number[]) => {
    if (
      !(await confirm(
        translate(`routes.alertMessages.${jobsIds.length === 1 ? 'confirmDeleteJob' : 'confirmDeleteJobs'}`),
      ))
    ) {
      return;
    }

    await deleteDispatchBoardUnassignedJob(jobsIds)(dispatch);
    await loadDispatchBoardUnassignedJobsCount(
      vendorId,
      vehicleTypeId,
      dispatchBoardSearchFilters.searchTerm,
    )(dispatch);

    createSuccessNotification(`${translate('routes.alertMessages.routeJobDeleted')}`);

    dispatch(setSelectedJobIds([]));
    dispatch(setSelectedJobIndexes([]));
  };

  const handleDeleteJob = (jobId: number) => {
    deleteJobs([jobId]);
  };

  const handleDeleteJobs = () => {
    deleteJobs(selectedUnassignedJobsId);
  };

  const handleJobSelection = (event: MouseEvent | any, index: number) => {
    const canSelect = true;
    const isCheckboxClicked = event.target.id.includes('actionButton');

    const jobIdsSelected: number[] = [];
    const jobIndexesSelected = getMultiSelectedJobs(
      index,
      selectedUnassignedJobs,
      SELECTED_TRANSFERRED_STOPS,
      event,
      canSelect,
      isCheckboxClicked,
    );

    if (jobIndexesSelected.length && filteredUnassignedJobs.length) {
      jobIndexesSelected.forEach(jobIndex => {
        jobIdsSelected.push((filteredUnassignedJobs[jobIndex] as DispatchBoardRouteJob).id);
      });
    }
    dispatch(setSelectedJobIndexes(jobIndexesSelected));
    dispatch(setSelectedJobIds(jobIdsSelected));
    sendJobSelectionNotifications(jobIndexesSelected.length, SELECTED_TRANSFERRED_STOPS);
  };

  const handleToggleSelectAllJobs = (isClear?: boolean) => {
    const jobIdsSelected: number[] = [];
    const jobIndexesSelected: number[] = [];

    if (!isClear) {
      filteredUnassignedJobs.forEach((job, index) => {
        jobIdsSelected.push(job.id);
        jobIndexesSelected.push(index);
      });
      sendJobSelectionNotifications(filteredUnassignedJobs.length, SELECTED_TRANSFERRED_STOPS);
    }
    dispatch(setSelectedJobIds(jobIdsSelected));
    dispatch(setSelectedJobIndexes(jobIndexesSelected));
  };

  const handleDragStart = (event: DragEvent<HTMLDivElement>, jobId: number, serviceContractId: number) => {
    const dt = event.nativeEvent.dataTransfer;

    const jobsSelected = [];

    if (filteredUnassignedJobs.length && selectedUnassignedJobs.length) {
      if (selectedUnassignedJobs.length > 1 && dt) {
        createDispatchBoardMultipleJobsDragGhost(dt);
      }
      selectedUnassignedJobs.forEach((jobIndex: number) => {
        jobsSelected.push({
          jobId: filteredUnassignedJobs[jobIndex].id,
          serviceContractId: filteredUnassignedJobs[jobIndex].serviceContractId,
        });
      });
    } else {
      jobsSelected.push({ jobId, serviceContractId });
    }

    dt && dt.setData('jobsSelected', JSON.stringify(jobsSelected));

    toggleJobTransferring(true);
  };

  const onMapClick = () => {
    dispatch(
      openMapWithRoute({
        id: UNASSIGNED_ROUTE_ID,
        name: translate('routes.unassignedJobs'),
        totalNumberOfJobs: unassignedJobsCount,
      }),
    );
    dispatch(openMap());
  };

  const containerHeight =
    containerHeightSelector(
      filteredUnassignedJobs.map(j => (j.id === openedJobId ? TABLE_ROW_HEIGHT_EXPANDED : TABLE_ROW_HEIGHT_LARGER)),
    ) || TABLE_ROW_HEIGHT_LARGER;

  const maxHeight = TABLE_ROW_MAX_ITEMS * TABLE_ROW_HEIGHT_LARGER;

  const getItemSize = (index: number) =>
    filteredUnassignedJobs[index].id === openedJobId ? TABLE_ROW_HEIGHT_EXPANDED : TABLE_ROW_HEIGHT_LARGER;

  return (
    <DispatchBoardSubPanel isLoading={isLoading} isFilterFocused>
      <RouteHeaderContainer
        dispatchBoardSearchFilters={dispatchBoardSearchFilters}
        filterFunctions={filterFunctions}
        handleDeleteJobs={handleDeleteJobs}
        jobsCount={filteredUnassignedJobs.length}
        jobsCountText={numberOfJobsText(filteredUnassignedJobs.length)}
        name={translate('routes.unassignedJobs')}
        onClose={onClose}
        onMapClick={onMapClick}
        onToggleSelectAllJobs={handleToggleSelectAllJobs}
        selectedJobsCount={selectedUnassignedJobs.length}
        toggleRouteEditorModal={toggleRouteEditorModal}
        type={JobTypes.unassigned}
        vehicleTypeId={vehicleTypeId}
      />

      <DispatchBoardDroppableList
        insertAtIndex={unassignedJobsCount + 1}
        maxHeight={containerHeight > maxHeight ? containerHeight : maxHeight}
        onJobDrop={onJobDrop}
        routeId={UNASSIGNED_ROUTE_ID}
      >
        {!!filteredUnassignedJobs.length && (
          <>
            <List
              ref={listRef}
              itemData={{
                jobs: filteredUnassignedJobs,
                handleDeleteJob,
                openedJobId,
                selectedJobs: selectedUnassignedJobs,
                handleJobSelection,
                handleDragStart,
                onJobDrop,
                toggleJob,
                toggleModal,
              }}
              itemCount={filteredUnassignedJobs.length}
              width="100%"
              height={containerHeight || 1}
              itemSize={getItemSize}
              className={REACT_WINDOW_CLASS_NAME}
              style={filteredUnassignedJobs.length > TABLE_ROW_MAX_ITEMS - 1 ? { paddingBottom: '60px' } : undefined}
            >
              {DispatchBoardUnassignedJob}
            </List>
            {filteredUnassignedJobs.length > TABLE_ROW_MAX_ITEMS - 1 && <DispatchBoardScrollMarker />}
          </>
        )}
      </DispatchBoardDroppableList>

      <DispatchBoardModals
        modalsOpen={modalsOpen}
        refreshUnassignedJobs={refreshUnassignedJobs}
        selectedJob={openedJob}
        toggleModal={toggleModal}
        vehicleTypeId={vehicleTypeId}
        disabledFields={['vehicleTypeId']}
      />
    </DispatchBoardSubPanel>
  );
};

export default DispatchBoardUnassignedJobs;
