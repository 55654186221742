import React from 'react';

const renderIcon = (icon: string) => {
  switch (icon) {
    case 'cart':
      return (
        <g transform="translate(0 1)" fill="currentColor" fillRule="evenodd">
          <path
            d="M9.875 1.5h-8.75v-1a.5.5 0 0 1 .5-.5h6.75a1.5 1.5 0 0 1 1.5 1.5zM3.693 12.5a2.25 2.25 0 0 0-2.121-3.486V3h7.553l-2.343 9.5H3.693z"
            stroke="currentColor"
            strokeWidth=".5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="1.5" cy="11.5" r="1.5" />
        </g>
      );

    case 'ticket':
      return (
        <g fill="currentColor" fillRule="evenodd">
          <path d="M6.94.096L9 .109c.16 0 .29.132.29.293l-.083 12.833a.292.292 0 0 1-.294.29l-2.062-.013a.292.292 0 0 1-.287-.252 1.75 1.75 0 0 0-3.465-.022.292.292 0 0 1-.29.248l-2.062-.013a.292.292 0 0 1-.29-.294L.54.346a.292.292 0 0 1 .294-.29L2.889.07a.287.287 0 0 1 .29.239 1.75 1.75 0 0 0 3.469.022c.025-.14.15-.24.293-.235zm-4.462 9.84a.5.5 0 0 0 .497.502l4.109.027a.5.5 0 0 0 .503-.497l.038-6.025a.5.5 0 0 0-.496-.503l-4.11-.026a.5.5 0 0 0-.502.497l-.039 6.024z" />
          <path d="M3.483 9.175l.028-4.409a.3.3 0 0 1 .302-.298l2.46.016a.3.3 0 0 1 .297.302l-.028 4.409a.3.3 0 0 1-.302.298l-2.46-.016a.3.3 0 0 1-.297-.302z" />
        </g>
      );

    default:
      return false;
  }
};

const IconMedium: React.SFC<{ className?: string; icon: string }> = ({ className, icon }) => (
  <svg viewBox="0 0 11 16" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" className={className}>
    {renderIcon(icon)}
  </svg>
);

export default IconMedium;
