import { useDispatch } from 'react-redux';
import humps from 'humps';

import { Button, TableCell, TableRow, Text } from 'src/core/components/styled';
import { CustomerImportFile } from 'src/customers/interfaces/CustomerImport';
import { dateAndTime } from 'src/utils/services/formatter';
import { downloadAlertsImportFile } from 'src/fleet/ducks/locationAndCityAlertsImports';
import { getStatusColor } from 'src/customers/components/modals/customerImportModal/CustomerImportModalTableRow';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import translate from 'src/core/services/translate';

interface Props extends CustomerImportFile {
  isAccountingCodeImport: boolean;
  isRateCodeImport: boolean;
  loading: boolean;
  status: string;
  tableCellsRateWidths: string[];
  tableCellsWidths: string[];
}

export const RateManagerImportModalTableRow = ({
  date,
  errorFileDownloadUrl,
  errorFileName,
  fileDownloadUrl,
  fileName,
  id,
  invalidRecordsCount,
  isAccountingCodeImport,
  isRateCodeImport,
  loading,
  status,
  tableCellsRateWidths,
  tableCellsWidths,
  totalRecordsCount,
  uploadedRecordsCount,
  validRecordsCount,
}: Props) => {
  const dispatch = useDispatch();

  const statusColor = getStatusColor(status);

  const showLoading = status !== 'ValidationFailed' && status !== 'Uploaded' && loading;

  const handleDownloadFile = (fileUrl: string, fileName: string, date?: string) => {
    downloadAlertsImportFile(fileUrl, fileName, date)(dispatch);
  };

  const isRateCodeOrAccountingCode = isAccountingCodeImport || isRateCodeImport;
  const correctTableCells = isRateCodeOrAccountingCode ? tableCellsWidths : tableCellsRateWidths;

  return (
    <TableRow key={id} height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width={correctTableCells[0]}>
        {fileDownloadUrl ? (
          <Button
            fullWidth
            text
            noCapitalize
            color="primary"
            onClick={() => handleDownloadFile(fileDownloadUrl, fileName)}
          >
            <Text align="left" block singleLine title={fileName}>
              {fileName}
            </Text>
          </Button>
        ) : (
          <Text align="left" block singleLine title={fileName}>
            {fileName}
          </Text>
        )}
      </TableCell>
      <TableCell width={correctTableCells[1]}>
        {showLoading ? (
          <Loader />
        ) : status ? (
          <Text color={statusColor}>{translate(`customers.customerUpload.statuses.${humps.camelize(status)}`)}</Text>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell width={correctTableCells[2]}>
        <Text>{totalRecordsCount}</Text>
      </TableCell>
      {!isRateCodeOrAccountingCode && (
        <>
          <TableCell width={correctTableCells[3]}>
            <Text>{uploadedRecordsCount}</Text>
          </TableCell>
          <TableCell width={correctTableCells[4]}>
            <Text>{validRecordsCount}</Text>
          </TableCell>
          <TableCell width={correctTableCells[5]}>
            <Text>{invalidRecordsCount}</Text>
          </TableCell>
        </>
      )}

      <TableCell width={correctTableCells[isRateCodeOrAccountingCode ? 3 : 6]}>
        {errorFileDownloadUrl ? (
          <Button text color="primary" onClick={() => handleDownloadFile(errorFileDownloadUrl, errorFileName, date)}>
            {translate('common.download')}
          </Button>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell width={correctTableCells[isRateCodeOrAccountingCode ? 4 : 7]}>
        <Text>{dateAndTime(date)}</Text>
      </TableCell>
    </TableRow>
  );
};
