import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '../../../../core/components/styled/Button';
import { MapFilters, resetWorkSessionState, setFullScreen } from 'src/routes/ducks/routeTemplateBuilder';
import { Panel, PanelSection } from '../../../../core/components/styled/Panel';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitleContainer,
  SlimPageTitle,
  SlimPageSubtitle,
} from '../../../../common/components/styled/Page';
import { RouteTemplateBuilderStopsPerDay } from './routeTemplateBuilderSections/RouteTemplateBuilderStopsPerDay';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import history from 'src/core/services/history';
import Icon from 'src/core/components/Icon';
import RouteTemplateBuilderCreateWorkSessionModal from './routeTemplateBuilderModals/RouteTemplateBuilderCreateWorkSessionModal';
import RouteTemplateBuilderFilters from '../../forms/RouteTemplateBuilderFilters';
import RouteTemplateBuilderWorkSessionDataContainer from './routeTemplateBuilderSections/RouteTemplateBuilderWorkSessionDataContainer';
import translate from '../../../../core/services/translate';

export const RouteTemplateBuilderWorkSessionPage: React.FC = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.routes.routeTemplateBuilder.isLoading);
  const changeLogLoading = useSelector(state => state.routes.routeTemplateBuilder.changeLogLoading);
  const workSession = useSelector(state => state.routes.routeTemplateBuilder.workSession);
  const mapFilters = useSelector(state => state.routes.routeTemplateBuilder.mapFilters);
  const fullScreen = useSelector(state => state.routes.routeTemplateBuilder.fullScreen);

  const [editWorkSessionTitleModalOpen, setEditWorkSessionTitleModalOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<MapFilters>(mapFilters);
  const [needsRefresh, setNeedsRefresh] = useState(false);

  useEffect(
    () => () => {
      dispatch(resetWorkSessionState());
    },
    [dispatch],
  );

  return (
    <PageContent fullScreen={fullScreen} isLoading={isLoading || changeLogLoading}>
      <PageHeader>
        <PageDetails withBackButton flex>
          <PageTitleContainer>
            <PageBackButtonAction onClick={() => history.goBack()}>
              <PageBackButtonIcon />
            </PageBackButtonAction>

            <SlimPageTitle>{translate('routeTemplateBuilder.workSession')}</SlimPageTitle>

            <SlimPageSubtitle>
              {workSession?.name}{' '}
              <Button margin="no xSmall" padding="no" text onClick={() => setEditWorkSessionTitleModalOpen(true)}>
                <Icon width="12px" icon="edit" />
              </Button>
            </SlimPageSubtitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions flex align="right">
          <RouteTemplateBuilderStopsPerDay currentFilters={currentFilters || ({} as MapFilters)} />

          <Button margin="no small" padding="no" text onClick={() => dispatch(setFullScreen(!fullScreen))}>
            <Icon width="12px" icon={fullScreen ? 'fullscreenOff' : 'fullscreenOn'} />
            <Text margin="no xxSmall">{translate('common.fullScreen')}</Text>
          </Button>

          <Link to="/routes/route-template-builder">
            <Button noWrap color="primary">
              {translate('routeTemplateBuilder.exitSession')}
            </Button>
          </Link>
        </PageActions>
      </PageHeader>

      <Panel>
        <RouteTemplateBuilderFilters
          needsRefresh={needsRefresh}
          setNeedsRefresh={setNeedsRefresh}
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          initialValues={{
            vehicleTypeId: mapFilters.vehicleTypeId,
            dayOfServiceIds: mapFilters.dayOfServiceIds,
            serviceTypeIds: mapFilters.serviceTypeIds,
            routeTemplateIds: mapFilters.routeTemplateIds,
            wasteMaterialTypeId: mapFilters.wasteMaterialTypeId,
            groupIds: mapFilters.groupIds,
            serviceZoneId: mapFilters.serviceZoneId,
            equipmentTypeIds: mapFilters.equipmentTypeIds,
            containerSizeIds: mapFilters.equipmentSizeIds,
          }}
        />

        <PanelSection>
          <RouteTemplateBuilderWorkSessionDataContainer needsRefresh={needsRefresh} currentFilters={currentFilters} />
        </PanelSection>
      </Panel>

      {editWorkSessionTitleModalOpen && (
        <RouteTemplateBuilderCreateWorkSessionModal
          initialValues={{ workSessionName: workSession?.name }}
          onClose={() => setEditWorkSessionTitleModalOpen(false)}
        />
      )}
    </PageContent>
  );
};
