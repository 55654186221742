import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { DuckAction } from '../../../contracts/ducks';
import { DragHandle, UnconnectedSwitch } from '../../../core/components';
import { TableCell, TableRow } from '../../../core/components/styled';
import { toggleInsightDashboardReportType } from '../../ducks';

interface Props {
  isActive: boolean;
  isEnabled: boolean;
  reportType: string;
  reportTypeName: string;
  toggleInsightDashboardReportType: DuckAction<typeof toggleInsightDashboardReportType>;
}

const InsightDashboardPreferencesPageTableRow: React.FC<Props> = ({
  isActive,
  isEnabled,
  reportType,
  reportTypeName,
  toggleInsightDashboardReportType,
}) => {
  return (
    <TableRow>
      <TableCell width="85%">
        <DragHandle margin="no small no no" id={`insights-settings-${reportType}-drag-handle`} />
        <span id={`insights-settings-${reportType}-report-name`}>{reportTypeName}</span>
      </TableCell>
      <TableCell width="15%" align="right">
        <UnconnectedSwitch
          checked={isActive}
          disabled={!isEnabled}
          id={`insights-settings-${reportType}-switch`}
          onChange={(value: any) => toggleInsightDashboardReportType(reportType, value)}
        />
      </TableCell>
    </TableRow>
  );
};

export default SortableElement(InsightDashboardPreferencesPageTableRow);
