import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { Option } from '../interfaces/Option';
import doLoadFeeStructure from '../services/feeStructure';

const START_LOAD = 'common/feeStructure/START_LOAD';
const COMPLETE_LOAD = 'common/feeStructure/COMPLETE_LOAD';
const FAIL_LOAD = 'common/feeStructure/FAIL_LOAD';
const RESET = 'common/feeStructure/RESET';

type State = {
  feeStructure: Option[];
  isLoading: boolean;
};

const initialState: State = {
  feeStructure: [],
  isLoading: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          feeStructure: action.feeStructure,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          feeStructure: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (feeStructure: Option[]) => ({
  type: COMPLETE_LOAD,
  feeStructure,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadFeeStructure = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadFeeStructurePromise = doLoadFeeStructure();
  loadFeeStructurePromise.then(feeStructure => dispatch(completeLoad(feeStructure))).catch(() => dispatch(failLoad()));
  return loadFeeStructurePromise;
};

export const resetFeeStructure = () => ({
  type: RESET,
});
