import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadEsriSyncSettings as doLoadEsriSyncSettings,
  saveEsriSyncSettings as doSaveEsriSyncSettings,
  validateEsriSyncSettings as doValidateEsriSyncSettings,
} from '../services/esriSyncSettings';
import { EsriSyncSettings, EsriSyncValidation } from '../interfaces/EsriSyncSettings';
// Actions
const COMPLETE_LOAD = 'vendors/esriSyncSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/esriSyncSettings/COMPLETE_SAVE';
const COMPLETE_VALIDATION = 'vendors/esriSyncSettings/COMPLETE_VALIDATION';
const FAIL_LOAD = 'vendors/esriSyncSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/esriSyncSettings/FAIL_SAVE';
const FAIL_VALIDATION = 'vendors/esriSyncSettings/FAIL_VALIDATION';
const START_LOAD = 'vendors/esriSyncSettings/START_LOAD';
const START_SAVE = 'vendors/esriSyncSettings/START_SAVE';
const START_VALIDATION = 'vendors/esriSyncSettings/START_VALIDATION';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  isValidating: false,
  esriSyncSettings: {} as EsriSyncSettings,
  esriSyncValidation: {} as EsriSyncValidation,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          esriSyncSettings: action.esriSyncSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    case START_VALIDATION:
      return update(state, {
        $merge: {
          isValidating: true,
        },
      });
    case COMPLETE_VALIDATION:
      return update(state, {
        $merge: {
          isValidating: false,
          esriSyncValidation: action.esriSyncValidation,
        },
      });
    case FAIL_VALIDATION:
      return update(state, {
        $merge: {
          isValidating: false,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (esriSyncSettings: EsriSyncSettings) => ({
  type: COMPLETE_LOAD,
  esriSyncSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startValidation = () => ({
  type: START_VALIDATION,
});

const completeValidation = (esriSyncValidation: EsriSyncValidation) => ({
  type: COMPLETE_VALIDATION,
  esriSyncValidation,
});

const failValidation = () => ({
  type: FAIL_VALIDATION,
});

export const loadEsriSyncSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadEsriSyncSettingsPromise = doLoadEsriSyncSettings(vendorId);
  loadEsriSyncSettingsPromise
    .then(esriSyncSettings => {
      dispatch(completeLoad(esriSyncSettings));
    })
    .catch(() => dispatch(failLoad()));

  return loadEsriSyncSettingsPromise;
};

export const saveEsriSyncSettings = (vendorId: number, esriSyncSettings: EsriSyncSettings) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveSnowPlowSettingsPromise = doSaveEsriSyncSettings(vendorId, esriSyncSettings);
  saveSnowPlowSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveSnowPlowSettingsPromise;
};

export const validateEsriSyncSettings =
  (vendorId: number, esriSyncSettings: EsriSyncSettings) => (dispatch: Dispatch) => {
    dispatch(startValidation());
    const validateEsriSyncSettingsPromise = doValidateEsriSyncSettings(vendorId, esriSyncSettings);
    validateEsriSyncSettingsPromise
      .then(esriSyncValidation => dispatch(completeValidation(esriSyncValidation)))
      .catch(() => dispatch(failValidation()));

    return validateEsriSyncSettingsPromise;
  };
