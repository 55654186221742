import mapboxgl, { AnyLayout } from 'mapbox-gl';
import { Layer } from 'react-map-gl';

import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { ROUTE_MAP_CLUSTERS_SOURCE, ROUTE_MAP_ROUTE_STOPS_LAYER } from '../layerIds';
import { memo } from 'react';

type Props = {
  isEditMode?: boolean;
  map: mapboxgl.Map;
  showOrderNumbers?: boolean;
  zoomLevel?: number;
};

const StopLayer: React.FC<Props> = ({ showOrderNumbers, zoomLevel }) => {
  let layoutForStops: AnyLayout = {
    'icon-image': ['get', 'icon'],
    'icon-size': 1,
    'icon-allow-overlap': true,
    'symbol-z-order': 'source',
    'symbol-sort-key': 1,
  };

  return (
    <>
      <Layer
        key="stopsLayer"
        id="stopsLayer"
        type="symbol"
        source={ROUTE_MAP_CLUSTERS_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], ROUTE_MAP_ROUTE_STOPS_LAYER]]}
        paint={{}}
        layout={layoutForStops}
      />
      {showOrderNumbers && (
        <Layer
          key="stopsLayerOrderNumber"
          id="stopsLayerOrderNumber"
          type="symbol"
          minzoom={zoomLevel || 15}
          source={ROUTE_MAP_CLUSTERS_SOURCE}
          filter={[
            'all',
            ['!', ['has', 'point_count']],
            ['!=', ['get', 'orderNo'], JOB_PENDING_OPTIMIZATION_ID],
            ['==', ['get', 'layer'], ROUTE_MAP_ROUTE_STOPS_LAYER],
          ]}
          paint={{ 'text-halo-color': '#fff', 'text-halo-width': 10 }}
          layout={{
            'symbol-z-order': 'source',
            'icon-image': ['get', 'icon'],
            'icon-size': 1,
            'text-field': showOrderNumbers ? ['get', 'orderNo'] : '',
            'text-offset': [0, 1.25],
            'text-size': 15,
          }}
        />
      )}
    </>
  );
};

export default memo(function RouteMapRouteStopsGLLayers(props: Props) {
  return <StopLayer {...props} />;
});
