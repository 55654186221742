import { useMemo, useState } from 'react';
import { ActionButtonTooltip } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import PaymentAttachmentsModalResolver from 'src/finance/components/modals/PaymentAttachmentsModalResolver';
import {

  PAYMENT_STATUS_UNAPPLIED_ID,
} from 'src/finance/constants/paymentStatuses';
import { Payment, TypeAheadOption } from 'src/finance/interfaces/ApplyChecks';
import { currency, date } from 'src/utils/services/formatter';

interface ComponentProps extends Payment {
  handleEditPaymentClick: (paymentId: number, paymentCustomerId: number) => void;
  handleDeletePayment: (paymentId: number, paymentCustomerId: number) => void;
  appliedAmount: number;
  unappliedAmount: number;
  tableCellWidths: { [key: string]: string };
  paymentMethodOptions: TypeAheadOption[];
}

const PAYMENT_METHOD_ACH = 1;

const PaymentsTableRow: React.FC<ComponentProps> = ({
  paymentNumber,
  paymentDate,
  paymentTotal,
  paymentStatusId,
  paymentMethodId,
  paymentMethodCode,
  customerName,
  unappliedAmount,
  id,
  customerId,
  handleEditPaymentClick,
  handleDeletePayment,
  tableCellWidths,
  attachmentsCount,
  paymentMethodOptions,
}) => {

  const paymentStatusOptions = useSelector(state => state.finance.paymentStatuses.paymentStatuses);

  const paymentMethod = useMemo(() => {
    return paymentMethodOptions?.find(item => item.value === paymentMethodId)?.label || "-";
  }, [paymentMethodOptions, paymentMethodId]);

  const paymentStatus = useMemo(() => {
    return paymentStatusOptions?.find(item => item.value === paymentStatusId)?.label || "-";
  }, [paymentStatusOptions, paymentStatusId]);

  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);

  return (
    <>
      <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
        <TableCell
          id={`payment-${paymentNumber}-payment-cell`}
          justifyContent="center"
          vertical
          width={tableCellWidths.paymentNumber}
          padding="defaultCellVertical xSmall"
        >
          <Text>{paymentNumber || '–'}</Text>
        </TableCell>
        <TableCell
          id={`payment-${paymentNumber}-paymentDate-cell`}
          width={tableCellWidths.paymentDate}
          padding="defaultCellVertical xSmall"
        >
          <Text>{paymentDate ? date(paymentDate) : '–'}</Text>
        </TableCell>
        <TableCell
          id={`payment-${paymentNumber}-paymentMethod-cell`}
          justifyContent="center"
          vertical
          width={tableCellWidths.paymentMethod}
          padding="defaultCellVertical xSmall"
        >
          <Text>{paymentMethodId === PAYMENT_METHOD_ACH ? paymentMethodCode : paymentMethod}</Text>
        </TableCell>
        <TableCell
          id={`payment-${paymentNumber}-customer-cell`}
          justifyContent="center"
          vertical
          width={tableCellWidths.customerName}
          padding="defaultCellVertical xSmall"
        >
          <Text>{customerName || '–'}</Text>
        </TableCell>
        <TableCell
          id={`payment-${paymentNumber}-paymentTotal-cell`}
          width={tableCellWidths.paymentTotal}
          padding="defaultCellVertical xSmall"
        >
          <Text>{currency(paymentTotal || 0)}</Text>
        </TableCell>
        <TableCell
          id={`payment-${paymentNumber}-unappliedAmount-cell`}
          width={tableCellWidths.unappliedAmount}
          padding="defaultCellVertical xSmall"
        >
          <Text>{currency(unappliedAmount || 0)} </Text>
        </TableCell>
        <TableCell
          id={`payment-${paymentNumber}-status-cell`}
          width={tableCellWidths.paymentStatus}
          padding="defaultCellVertical xSmall"
        >
          <Text>{paymentStatus || '–'}</Text>
        </TableCell>
        <TableCell width={tableCellWidths.options} align="left">

          <TableActionButton
            onClick={() => handleEditPaymentClick(id, customerId)}
            id={`payment-${paymentNumber}-edit-button`}
          >
            <ActionButtonTooltip
              icon={paymentStatusId === PAYMENT_STATUS_UNAPPLIED_ID ? 'edit' : 'redeye'}
              tooltip={paymentStatusId === PAYMENT_STATUS_UNAPPLIED_ID ? 'edit' : 'view'}
            />
          </TableActionButton>


          {paymentStatusId === PAYMENT_STATUS_UNAPPLIED_ID && (
            <TableActionButton
              onClick={() => handleDeletePayment(id, customerId)}
              id={`payment-${paymentNumber}-delete-button`}
            >
              <ActionButtonTooltip icon="delete" tooltip="delete" />
            </TableActionButton>
          )}

          {!!attachmentsCount && (
            <TableActionButton onClick={() => setIsFilesModalOpen(true)} id={`payment-${paymentNumber}-edit-button`}>
              <ActionButtonTooltip icon={'files'} content={attachmentsCount} tooltip={'attachments'} />
            </TableActionButton>
          )}
        </TableCell>
      </TableRow>

      {isFilesModalOpen && (
        <PaymentAttachmentsModalResolver paymentId={id} closeModal={() => setIsFilesModalOpen(false)} />
      )}
    </>
  );
};

export default PaymentsTableRow;
