import moment, { DurationInputArg2 } from 'moment';

import translate from '../../core/services/translate';

moment.updateLocale('today-tommorow', {
  calendar: {
    sameDay: translate('common.today'),
    nextDay: translate('common.tommorow'),
  },
});

export const number = (number: any) => Number(number);
export const decimal = (number: any, precision = 2) => Number(number).toFixed(precision);
export const coordinate = (number: any, precision = 4) => Number(number).toFixed(precision);
export const date = (date: any = new Date(), format = 'L') => moment(new Date(date)).format(format);
export const time = (date: any) => (!!date ? moment(date).format('hh:mm A') : '');
export const timeStamp = (date: any) => (!!date ? moment(date).format('hh:mm:ss A') : '');
export const dateAndTime = (givenDate: Date | string) => `${date(givenDate)} ${time(givenDate)}`;
export const dateAndTimeStamp = (givenDate: Date | string) => `${date(givenDate)} ${timeStamp(givenDate)}`;
export const duration = (duration: number | string, unit: DurationInputArg2 = 'seconds', template = 'hh:mm:ss') =>
  (moment.duration(duration, unit) as any).format(template, { trim: false });

export const humanizeDate = (date: any) => {
  const dateAsMoment = moment(date);
  const today = moment().endOf('day');
  const yesterday = moment().subtract(1, 'day').endOf('day');
  const tomorrow = moment().add(1, 'day').endOf('day');

  if (dateAsMoment < today && dateAsMoment > yesterday) return translate('common.today');
  if (dateAsMoment < tomorrow && dateAsMoment > today) return translate('common.tommorow');
  return dateAsMoment.format('MM/DD/YYYY');
};

const optionalDecimals = (value: number) => {
  return Math.floor(value) === value ? value.toFixed(0) : value;
};

const toPrecision = (value: number, decimals = 1) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
  const match = value.toString().match(re);

  if (!match) {
    return value;
  }

  return +match[0];
};

export const kFormatter = (
  val: number,
  {
    decimals = 1,
    kThreshold,
    mThreshold,
    bThreshold,
  }: {
    decimals?: number;
    kThreshold?: number;
    mThreshold?: number;
    bThreshold?: number;
  } = {},
) => {
  const bVal = toPrecision(val / 10 ** 9, decimals);
  const mVal = toPrecision(val / 10 ** 6, decimals);
  const kVal = toPrecision(val / 10 ** 3, decimals);

  if (val >= 10 ** 9 && (!bThreshold || val >= bThreshold)) {
    return `${optionalDecimals(bVal)}B`;
  }

  if (val >= 10 ** 6 && (!mThreshold || val >= mThreshold)) {
    return `${optionalDecimals(mVal)}M`;
  }

  if (val >= 10 ** 3 && (!kThreshold || val >= kThreshold)) {
    return `${optionalDecimals(kVal)}K`;
  }

  return val;
};

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const currency = (amount: number) => currencyFormatter.format(amount);

const numberFormatter = new Intl.NumberFormat('en-US');
export const formatNumber = (amount: number) => numberFormatter.format(amount);
