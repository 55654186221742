import React from 'react';

import 'rc-slider/assets/index.css';
import { theme } from '../styles';
import Slider, { createSliderWithTooltip } from 'rc-slider';

const CurrentSliderWithTooltip = createSliderWithTooltip(Slider);

interface Props {
  disabled?: boolean;
  max: number;
  min: number;
  onChange: (value: number) => void;
  startPoint: number;
  tipFormatter?: (value: number) => string;
  value: number;
}

const SliderWithTooltip: React.FC<Props> = ({
  disabled,
  max,
  min,
  onChange,
  startPoint,
  tipFormatter,
  value,
}) => (
  <CurrentSliderWithTooltip
    startPoint={startPoint}
    min={min}
    max={max}
    value={value}
    onChange={onChange}
    disabled={disabled}
    handleStyle={{
      height: 16,
      width: 16,
      backgroundColor: theme.brandPrimary,
      border: 0,
    }}
    trackStyle={{
      background: theme.brandPrimary,
    }}
    tipFormatter={tipFormatter}
    tipProps={{ visible: true }}
  />
);

export default SliderWithTooltip;
