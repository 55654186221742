import React, { Fragment } from 'react';
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form';

import { Checkbox, Input } from '../../../../core/components';
import { Grid, GridColumn, Message } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';

const ClassificationsFieldArray: React.FC<WrappedFieldArrayProps> = ({ fields }) => (
  <Fragment>
    <Grid multiLine>
      {fields.map((field, index) => (
        <Fragment key={index}>
          <GridColumn size="12/12" padding="no medium medium no" tabletPadding="no medium small no">
            <Field name={`${field}.isSelected`} component={Checkbox} label={fields.get(index).displayTitle} />
          </GridColumn>

          {fields.get(index).supportsNote && fields.get(index).isSelected && (
            <GridColumn size="12/12" padding="no">
              <Field name={`${field}.userNote`} component={Input} label={translate('haulerProfile.userNote')} />
            </GridColumn>
          )}
        </Fragment>
      ))}
    </Grid>

    {!fields.length && <Message padding="no">{translate('haulerProfile.noClassifications')}</Message>}
  </Fragment>
);

const Classifications = () => <FieldArray name="classifications" component={ClassificationsFieldArray} props={{}} />;

export default Classifications;
