import { size } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import arrow from 'src/common/assets/img/common/arrowSmall.png';
import dot from 'src/common/assets/img/common/dot.png';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  DashboardMapboxVehicleDetailsFormValues,
  DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM,
} from 'src/dashboard/components/forms/DashboardMapboxVehicleDetailsForm';
import { DASHBOARD_CLUSTERS_SOURCE } from 'src/dashboard/constants/dashboardMapGL';
import {
  DASHBOARD_VEHICLE_TRACKINGS_POINTS_SOURCE,
  DASHBOARD_VEHICLE_TRACKINGS_SOURCE,
} from 'src/dashboard/constants/vehicleTrackings';
import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { BreadCrumb, VehicleBreadcrumbs } from 'src/dashboard/interfaces/vehiclePositions';
import DashboardVehicleTrackingsGLPopupResolver from './DashboardVehicleTrackingsGLPopupResolver';
import DashboardVehicleTrackingsGLSource from './DashboardVehicleTrackingsGLSource';
import {
  getMapboxVehicleTrackingsForRouteGeoJSON,
  getMapboxVehicleTrackingsForVehicleGeoJSON,
  getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON,
  getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON,
  VehicleTrackingFeatureProperties,
} from './utils';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';

const mapImages = [
  {
    id: 'arrow',
    url: arrow,
    sourceId: DASHBOARD_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
  {
    id: 'dot',
    url: dot,
    sourceId: DASHBOARD_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
];

const mapImagesOptions = { addLayers: false };

interface Props {
  isOnDemandVideoDownloadEnabled: boolean;
  map: mapboxgl.Map;
  selectedVehicleId?: number;
}

interface FeatureWithExtraProps extends GeoJSON.Feature<GeoJSON.LineString, VehicleTrackingFeatureProperties> {
  source?: string;
}

const DashboardVehicleTrackingsGL: React.FC<Props> = ({ isOnDemandVideoDownloadEnabled, map, selectedVehicleId }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  const vehicleDetailsFormValues = useSelector(
    getFormValues(DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM),
  ) as DashboardMapboxVehicleDetailsFormValues;

  // vehiclesDetails screen
  const vehicleTrackings = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs as VehicleBreadcrumbs);
  const vehiclePositions = useSelector(state => state.dashboard.vehiclesData.vehiclesList);

  const geoJSONForVehicle = useMemo(() => {
    const trackings = vehicleDetailsFormValues?.displayBreadCrumbs
      ? vehicleTrackings
      : ({ coords: [[]] as BreadCrumb[][] } as VehicleBreadcrumbs);
    return {
      lineFeature: getMapboxVehicleTrackingsForVehicleGeoJSON(trackings),
      pointFeature: getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON(trackings),
    };
  }, [vehicleDetailsFormValues?.displayBreadCrumbs, vehicleTrackings]);

  // routesDetails screen
  const vehiclePositionsForRoute = useSelector(state => state.dashboard.routesData.routeDriversVehicles);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);
  const routeVehicles = useSelector(state => state.dashboard.routesData.routeDriversVehicles);

  const geoJSONForRoute = useMemo(() => {
    return {
      lineFeature: getMapboxVehicleTrackingsForRouteGeoJSON(vehicleTrackingsForRoute?.vehicles || [], routeVehicles),
      pointFeature: getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON(
        vehicleTrackingsForRoute?.vehicles || [],
        routeVehicles,
      ),
    };
  }, [routeVehicles, vehicleTrackingsForRoute?.vehicles]);

  const showVehicleTracking =
    (!!size(geoJSONForVehicle.lineFeature.features) && size(vehiclePositions) > 0) ||
    (!!size(geoJSONForRoute.lineFeature.features) && size(vehiclePositionsForRoute) > 0);

  useEffect(() => {

    map.once('load', () => {
      /**
          // Saving this for later if we want to add onhover effect on the line to highlight so 
          // it is easier for users to select the line
            map.on('mousemove', 'allVehicleTrackingsLine', (e) => {
              if (e && e.features && e?.features?.length > 0) {
                  if (hoveredPolygonId !== null) {
                      map.setFeatureState(
                          { source: DASHBOARD_VEHICLE_TRACKINGS_SOURCE, id: hoveredPolygonId },
                          { hover: false }
                      );
                  }
                  hoveredPolygonId = e?.features[0]?.id;
                  map.setFeatureState(
                      { source: DASHBOARD_VEHICLE_TRACKINGS_SOURCE, id: hoveredPolygonId },
                      { hover: true }
                  );
              }
            })
      
            map.on('mouseleave', 'allVehicleTrackingsLine', () => {
              if (hoveredPolygonId !== null) {
                  map.setFeatureState(
                      { source: DASHBOARD_VEHICLE_TRACKINGS_SOURCE, id: hoveredPolygonId },
                      { hover: false }
                  );
              }
              hoveredPolygonId = null;
          });
      */
      map.on('click', event => {

        const feature = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_VEHICLE_TRACKINGS_POINTS_SOURCE ||
              feature.source === DASHBOARD_VEHICLE_TRACKINGS_SOURCE,
          )[0] as any as FeatureWithExtraProps | undefined;

        const [otherFeatures] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === DASHBOARD_CLUSTERS_SOURCE);
        if (!!feature && !otherFeatures) {
          /**
           * Yes, setting the id from the properties since Mapbox removes the floating numbers
           * from the main id of the feature. We need to set some other ids for these things,
           * but API changes will be necessary.
           */

          dispatch(clearDashboardSelectedFeature());
          dispatch(
            setDashboardSelectedFeature('vehicleTrackings', feature.properties.vehicleId, {
              coordinates: event.lngLat,
              vehicleTrackingGroupIndex: feature.properties.groupIndex,
              vehicleTrackingsSource: feature.properties.source,
              sourceId: feature.source
            }),
          );
        }
      });
    });
  }, [map, dispatch, vehicleTrackings]);

  return showVehicleTracking ? (
    <>
      <DashboardVehicleTrackingsGLSource
        geoJSONForLine={selectedVehicleId ? geoJSONForVehicle.lineFeature : geoJSONForRoute.lineFeature}
        geoJsonForPoints={selectedVehicleId ? geoJSONForVehicle.pointFeature : geoJSONForRoute.pointFeature}
      />
      <DashboardVehicleTrackingsGLPopupResolver isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled} />
    </>
  ) : null;
};

export default DashboardVehicleTrackingsGL;
