import React from 'react';

import { date, timeStamp } from '../../../../../utils/services/formatter';
import { Grid, GridColumn, TableCell, TableRow, Text } from '../../../../../core/components/styled';
import { SessionDetail } from '../../../../interfaces/EventLog';
import { TABLE_ROW_HEIGHT } from '../../../../../core/constants';

const EventLogTableRow = ({
  actionName,
  actionTime,
  batteryLevel,
  cpuUsage,
  customerName,
  locationName,
  memory,
  stopOrderNo,
}: SessionDetail) => (
  <TableRow height={TABLE_ROW_HEIGHT}>
    <TableCell width="10%">{stopOrderNo}</TableCell>
    <TableCell width="28%">
      <Grid>
        <GridColumn width="100%" padding="no">
          <Text block weight="medium" margin="no no xxSmall">
            {customerName}
          </Text>
          <Text block size="small">
            {locationName}
          </Text>
        </GridColumn>
      </Grid>
    </TableCell>

    <TableCell width="15%">{actionName}</TableCell>

    <TableCell width="10%">{batteryLevel}</TableCell>
    <TableCell width="10%">{cpuUsage}</TableCell>

    <TableCell width="15%">{memory}</TableCell>

    <TableCell width="12%" align="right">
      <Grid>
        <GridColumn width="100%" padding="no">
          <Text block margin="no no xxSmall">
            {date(actionTime)}
          </Text>
          <Text block size="small">
            {timeStamp(actionTime)}
          </Text>
        </GridColumn>
      </Grid>
    </TableCell>
  </TableRow>
);

export default EventLogTableRow;
