import { mapKeys } from 'lodash-es';

import facilityContainer from 'src/common/assets/img/common/facilityContainer.png';
import facilityTruck from 'src/common/assets/img/common/facilityTruck.png';
import frontLoadContainerIcon from 'src/common/assets/img/containers/containerFrontLoad.png';
import openTopContainerIcon from 'src/common/assets/img/containers/containerOpenTop.png';
import receiverBoxContainerIcon from 'src/common/assets/img/containers/containerReceiverBox.png';
import rearLoadContainerIcon from 'src/common/assets/img/containers/containerRearLoad.png';
import selfContainedContainerIcon from 'src/common/assets/img/containers/containerSelfContained.png';
import unknownContainerIcon from 'src/common/assets/img/containers/containerUnknown.png';
import verticalCompactorContainerIcon from 'src/common/assets/img/containers/containerVerticalCompactor.png';
import stationaryCompactorContainerIcon from 'src/common/assets/img/containers/containerStationaryCompactor.png';
import toterContainerIcon from 'src/common/assets/img/containers/containerToter.png';
import recyclerContainerIcon from 'src/common/assets/img/containers/containerRecycler.png';
import bagsContainerIcon from 'src/common/assets/img/containers/containerBags.png';
import publicContainerContainerIcon from 'src/common/assets/img/containers/containerPublicContainer.png';
import portableToiletContainerIcon from 'src/common/assets/img/containers/containerPortableToilet.png';
import portableSinkContainerIcon from 'src/common/assets/img/containers/containerPortableSink.png';
import portableRestroomTrailerContainerIcon from 'src/common/assets/img/containers/containerPortableRestroomTrailer.png';
import bulkItemContainerIcon from 'src/common/assets/img/containers/containerBulkItem.png';
import waterToteContainerIcon from 'src/common/assets/img/containers/containerWaterTote.png';
import holdingTankContainerIcon from 'src/common/assets/img/containers/containerHoldingTank.png';
import noneContainerIcon from 'src/common/assets/img/containers/containerNone.png';
import multipleContainerIcon from 'src/common/assets/img/containers/containerMultiple.png';
import IconWithTypes from 'src/common/interfaces/IconWithTypes';

const FRONT_LOAD = 1;
const OPEN_TOP = 2;
const RECEIVER_BOX = 3;
const REAR_LOAD = 4;
const SELF_CONTAINED = 5;
const UNKNOWN = 6;
const VERTICAL_COMPACTOR = 7;
const STATIONARY_COMPACTOR = 8;
const TOTER = 9;
const RECYCLER = 10;
const BAGS = 11;
const PUBLIC_CONTAINER = 12;
const PORTABLE_TOILET = 13;
const PORTABLE_SINK = 14;
const PORTABLE_RESTROOM_TRAILER = 15;
const BULK_ITEM = 16;
const WATER_TOTE = 17;
const HOLDING_TANK = 18;
const NONE = 19;
export const MULTIPLE = 20;

export const CONTAINERS_MAP_ICONS = [
  { id: FRONT_LOAD, color: '#ff0000', icon: new IconWithTypes('frontLoadContainer', frontLoadContainerIcon, []) },
  { id: OPEN_TOP, color: '#ff8000', icon: new IconWithTypes('openTopContainer', openTopContainerIcon, []) },
  { id: RECEIVER_BOX, color: '#ffbf00', icon: new IconWithTypes('receiverBoxContainer', receiverBoxContainerIcon, []) },
  { id: REAR_LOAD, color: '#ffff00', icon: new IconWithTypes('rearLoadContainer', rearLoadContainerIcon, []) },
  {
    id: SELF_CONTAINED,
    color: '#bfff00',
    icon: new IconWithTypes('selfContainedContainer', selfContainedContainerIcon, []),
  },
  { id: UNKNOWN, color: '#00ff00', icon: new IconWithTypes('unknownContainer', unknownContainerIcon, []) },
  {
    id: VERTICAL_COMPACTOR,
    color: '#00ff80',
    icon: new IconWithTypes('verticalCompactorContainer', verticalCompactorContainerIcon, []),
  },
  {
    id: STATIONARY_COMPACTOR,
    color: '#873e23',
    icon: new IconWithTypes('stationaryCompactorContainer', stationaryCompactorContainerIcon, []),
  },
  { id: TOTER, color: '#3e50b9', icon: new IconWithTypes('toterContainer', toterContainerIcon, []) },
  { id: RECYCLER, color: '#00bfff', icon: new IconWithTypes('recyclerContainer', recyclerContainerIcon, []) },
  { id: BAGS, color: '#0080ff', icon: new IconWithTypes('bagsContainer', bagsContainerIcon, []) },
  {
    id: PUBLIC_CONTAINER,
    color: '#4000ff',
    icon: new IconWithTypes('publicContainerContainer', publicContainerContainerIcon, []),
  },
  {
    id: PORTABLE_TOILET,
    color: '#0040ff',
    icon: new IconWithTypes('portableToiletContainer', portableToiletContainerIcon, []),
  },
  {
    id: PORTABLE_SINK,
    color: '#8000ff',
    icon: new IconWithTypes('portableSinkContainer', portableSinkContainerIcon, []),
  },
  {
    id: PORTABLE_RESTROOM_TRAILER,
    color: '#bf00ff',
    icon: new IconWithTypes('portableRestroomTrailerContainer', portableRestroomTrailerContainerIcon, []),
  },
  { id: BULK_ITEM, color: '#ff00ff', icon: new IconWithTypes('bulkItemContainer', bulkItemContainerIcon, []) },
  { id: WATER_TOTE, color: '#ff0080', icon: new IconWithTypes('waterToteContainer', waterToteContainerIcon, []) },
  { id: HOLDING_TANK, color: '#ff0040', icon: new IconWithTypes('holdingTankContainer', holdingTankContainerIcon, []) },
  { id: NONE, color: '#ffea00', icon: new IconWithTypes('noneContainer', noneContainerIcon, []) },
  { id: MULTIPLE, color: '#00FFFF', icon: new IconWithTypes('multipleContainer', multipleContainerIcon, []) },
];

export const CONTAINERS_MAP_ICONS_BY_ID = mapKeys(CONTAINERS_MAP_ICONS, 'id');

export const CONTAINERS_MAP_CLUSTER_LAYER = 'containersMapClusterLayer';
export const CONTAINERS_MAP_CLUSTER_SOURCE = 'containersMapClusterSource';
export const CONTAINERS_MAP_CONTAINERS_LAYER = 'containersMapContainersLayer';
export const CONTAINERS_MAP_CONTAINERS_SOURCE = 'containersMapContainersSource';
export const CONTAINERS_MAP_FACILITIES_LAYER = 'containersMapFacilitiesLayer';
export const CONTAINERS_MAP_FACILITIES_SOURCE = 'containersMapFacilitiesSource';
export const CONTAINERS_MAP_CLUSTER_COUNTERS_LAYER = 'containersMapClustersCountersLayer';

const FACILITY_CONTAINER_YARD = 7;
const FACILITY_TRUCK_YARD = 8;

export const FACILITIES_MAP_COLORS = [
  { id: FACILITY_CONTAINER_YARD, color: '#bd0fe0', icon: facilityContainer, iconType: 'facilityYard' },
  { id: FACILITY_TRUCK_YARD, color: '#00a69a', icon: facilityTruck, iconType: 'facilityTruck' },
];

export const FACILITIES_MAP_COLORS_BY_ID = mapKeys(FACILITIES_MAP_COLORS, 'id');
