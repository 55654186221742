import qs from 'qs';

import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import { LIMIT_PER_PAGE, PAGE } from '../../core/constants';
import { RouteTemplateScheduler, RouteTemplatesQueryParams } from '../interfaces/RouteTemplates';
import transformLoadRouteTemplates from './transformLoadRouteTemplates';
import translate from '../../core/services/translate';

export const loadRouteTemplates = (queryParams: RouteTemplatesQueryParams) =>
  http
    .get('/routeTemplates', {
      params: {
        ...queryParams,
        scheduledDays: queryParams.daysOfServiceIds,
        isEnabled: queryParams.routeStatus,
        page: queryParams.page || PAGE,
        limit: queryParams.limit || LIMIT_PER_PAGE,
        sortDirection: queryParams.sortOrder,
        sortOrder: queryParams.sortedBy,
      },
    })
    .then(response => transformLoadRouteTemplates(response.data));

export const loadRouteTemplatesForHolidayPlanner = (
  vendorId: number,
  routeTemplateName: string,
  daysOfServiceIds: any[],
  vehicleTypeIds: string,
  routeStatus: number,
  page: number = PAGE,
  limit: number = LIMIT_PER_PAGE,
  sortOrder: string,
  sortedBy: string,
  holidayId: number,
  isDefaultHoliday: boolean,
) =>
  http
    .get('/routeTemplates/forHolidayPlanner', {
      params: {
        vendorId,
        routeTemplateName,
        scheduledDays: daysOfServiceIds,
        vehicleTypeIds,
        isEnabled: routeStatus,
        page,
        limit,
        sortDirection: sortOrder,
        sortOrder: sortedBy,
        holidayId,
        isDefaultHoliday,
      },
    })
    .then(response => transformLoadRouteTemplates(response.data));

export const exportRouteTemplates = (
  vendorId: number,
  routeTemplateName: string,
  daysOfServiceIds: any[],
  vehicleTypeIds: number[],
  routeStatus: any,
  page: number = PAGE,
  limit: number = LIMIT_PER_PAGE,
  sortOrder: string,
  sortedBy: string,
  serviceZoneIdsCSV?: string,
  supervisorIdsCSV?: string,
  groupIds: number[] = [],
) =>
  downloadExcelFile('/routeTemplates/export', translate('routes.routeTemplates'), {
    vendorId,
    routeTemplateName,
    scheduledDays: daysOfServiceIds,
    vehicleTypeIds: vehicleTypeIds.toString(),
    isEnabled: routeStatus,
    page,
    limit,
    sortDirection: sortOrder,
    sortOrder: sortedBy,
    serviceZoneIdsCSV,
    supervisorIdsCSV,
    groupIds
  });

export const createRouteFromRouteTemplate = (vendorId: number, routeTemplateId: number, date: Date | string) =>
  http.post('routes/createFromTemplate', { vendorId, routeTemplateId, date }).then(response => response.data);

export const fetchRouteTemplatesSchedulers = (routeTemplateIds: number[], date: Date | string, vendorId: number) =>
  http
    .get<RouteTemplateScheduler[]>('/routeTemplates/schedulers', {
      params: { routeTemplateIds, date, vendorId },
      paramsSerializer(params) {
        return qs.stringify(params);
      },
    })
    .then(response => response.data);

export const scheduleRouteTemplates = (
  routeTemplateIds: number[],
  date: Date | string,
  vendorId: number,
  skipOtherSchedulersDuringCurrentWeek: boolean,
  groupIds: number[],
) =>
  http.post('/routeTemplates/schedulers', {
    routeTemplateIds,
    date,
    vendorId,
    skipOtherSchedulersDuringCurrentWeek,
    groupIds,
  });

export const scheduleAllSnowRouteTemplates = (
  date: Date | string,
  vendorId: number,
  skipOtherSchedulersDuringCurrentWeek: boolean,
  groupIds: number[],
) =>
  http.post('routeTemplates/scheduleAllSnowPlowRoutes', {
    date,
    vendorId,
    skipOtherSchedulersDuringCurrentWeek,
    groupIds,
  });

export const scheduleAllStreetSweeperRouteTemplates = (
  date: Date | string,
  vendorId: number,
  skipOtherSchedulersDuringCurrentWeek: boolean,
  groupIds: number[],
) =>
  http.post('routeTemplates/scheduleAllStreetSweeperRoutes', {
    date,
    vendorId,
    skipOtherSchedulersDuringCurrentWeek,
    groupIds,
  });
