import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PageLoading } from 'src/common/components/styled';
import {
  loadContainerTypes,
  loadMonthlyServicesMonthlyFrequencyTypes,
  loadMonthlyServicesWeeklyFrequencyTypes,
  loadMonthlyServicesYearlyFrequencyTypes,
  loadPickupFrequencyTypes,
  loadServiceTypes,
  loadUnitOfMeasureTypes,
  loadWasteTypes,
} from 'src/common/ducks';
import { Resolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  loadContainerList,
  loadCustomer,
  loadLocation,
  loadRouteTemplatesByScheduledDays,
  loadService,
  resetService,
  loadServiceTypesForLocation,
  loadServiceWorkOrders,
  serviceTypeIdSelector,
} from 'src/customers/ducks';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import ServiceDetailsEditorPage from './ServiceDetailsEditorPage';
import { loadRateTypes } from 'src/finance/ducks';

export interface ServiceEditorRouteParams {
  locationId: string;
  serviceId: string;
  customerId: string;
}

const ServiceDetailsEditorPageResolver = () => {
  const dispatch = useDispatch();
  const { customerId: cId, locationId: lId, serviceId: sId } = useParams<ServiceEditorRouteParams>();

  const customerId = Number(cId);
  const locationId = Number(lId);
  const serviceId = Number(sId) !== -1 ? Number(sId) : undefined;

  const vendorId = useSelector(state => currentVendorIdSelector(state.account.login, state.vendors.defaultVendor));
  const serviceTypeId = useSelector(state => (serviceTypeIdSelector as any)(state.customers.services, serviceId));
  const isContainerManagementFeatureEnabled = useSelector(
    state => !!(state.vendors.features.features || []).find(f => f.code === 'ContainerManagement' && f.enabled),
  );

  const loadDependencies = async () => {
    const promises: Promise<any>[] = [
      loadCustomer(customerId)(dispatch),
      loadLocation(locationId)(dispatch),
      loadPickupFrequencyTypes()(dispatch),
      loadRateTypes()(dispatch),
      loadServiceTypes()(dispatch),
      loadUnitOfMeasureTypes()(dispatch),
      loadWasteTypes()(dispatch),
      loadMonthlyServicesYearlyFrequencyTypes()(dispatch),
      loadMonthlyServicesMonthlyFrequencyTypes()(dispatch),
      loadMonthlyServicesWeeklyFrequencyTypes()(dispatch),
      loadRouteTemplatesByScheduledDays(vendorId, serviceTypeId)(dispatch),
      loadServiceTypesForLocation(locationId, serviceId)(dispatch),
      loadContainerTypes()(dispatch),
    ];

    if (isContainerManagementFeatureEnabled) {
      promises.push(loadContainerList(vendorId)(dispatch));
    }

    if (serviceId) {
      promises.push(loadService(serviceId)(dispatch));
      promises.push(loadServiceWorkOrders(serviceId)(dispatch));
    }

    return Promise.all(promises);
  };

  useEffect(() => {
    return resetService()(dispatch);
  });

  return (
    <Fragment>
      <Resolver successComponent={ServiceDetailsEditorPage} loadingComponent={PageLoading} resolve={loadDependencies} />
    </Fragment>
  );
};

export default ServiceDetailsEditorPageResolver;
