import { downloadInsightsExcelFile } from '../../utils/services/downloadExcelFile';
import { httpInsightsReporting } from '../../core/services/http';
import transformLoadReportingFaultCodeDetails from './transformLoadReportingFaultCodeDetails';
import translate from '../../core/services/translate';

export const loadReportingFaultCodeDetails = (vendorId: number, date: Date | string, vehicleId: number, vehicleName: string) =>
  httpInsightsReporting
    .get('/reporting/reportingfaultcodesdetails', { params: { vendorId, date, vehicleId, vehicleName } })
    .then(response => transformLoadReportingFaultCodeDetails(response.data));

export const exportReportingFautlCodeDetails = (
  vendorId: number,
  date: Date | string,
  vehicleId: number,
  vehicleName: string,
) =>
  downloadInsightsExcelFile(
    'export/reportingfaultcodesdetails',
    `${translate('insights.faultCodesDetails')}-${translate('insights.report')}`,
    { vendorId, date, vehicleId, vehicleName },
  );
