import { PureComponent } from 'react';

import { MaterialTicketImage } from 'src/routes/interfaces/MaterialTickets';
import { Modal, ImageSlider } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: () => void;
  materialTicket: MaterialTicketImage;
}

class MaterialTicketImagesModal extends PureComponent<Props> {
  render() {
    const {
      materialTicket: { url, rawUrl, ticketNumber, materialPickUpLocationName = '' },
      closeModal,
    } = this.props;

    return (
      <Modal
        title={materialPickUpLocationName}
        subTitle={`${translate('routes.ticketNumber')} : ${ticketNumber}`}
        onClose={closeModal}
        padding="medium no no"
      >
        <ImageSlider
          margin="small no no"
          images={[url]}
          imagesToDownload={[rawUrl || url]}
          imageTitle={materialPickUpLocationName}
        />
      </Modal>
    );
  }
}

export default MaterialTicketImagesModal;
