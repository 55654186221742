import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const TRAVEL_PATH_LOCK_TYPE_EDIT_ID = 1;
export const TRAVEL_PATH_LOCK_TYPE_BUILD_ID = 2;

export const DEADHEAD_ID1 = 0;
export const DEADHEAD_ID2 = 8;
export const DEADHEAD_ID3 = 9;
export const FROM_TIP_ID = -4;
export const TO_TIP_ID = -2;
export const TO_DEPOT_ID = -3;
export const FROM_DEPOT_ID = -1;
export const SINGLE_SIDE_SERVICE = 1;
export const DOUBLE_SIDE_SERVICE = 2;

export const TRAVEL_PATH_SERVICE_TYPES = [
  {
    id: DEADHEAD_ID2,
    name: translate('routes.travelPath.deadHead'),
    color: '#000000', // black
  },
  {
    id: TO_TIP_ID,
    name: translate('routes.travelPath.toEndingLocation'),
    color: '#a8329e', // purple
  },
  {
    id: DEADHEAD_ID3,
    name: translate('routes.travelPath.deadHead'),
    color: '#000000', // black
  },

  {
    id: TO_DEPOT_ID,
    name: translate('routes.travelPath.toEndingLocation'),
    color: '#a8329e', // purple
  },
  {
    id: FROM_TIP_ID,
    name: translate('routes.travelPath.fromStartingLocation'),
    color: '#01ffff', // light blue
  },
  {
    id: FROM_DEPOT_ID,
    name: translate('routes.travelPath.fromStartingLocation'),
    color: '#01ffff', // light blue
  },
  {
    id: DEADHEAD_ID1,
    name: translate('routes.travelPath.deadHead'),
    color: '#000000', // black
  },

  {
    id: SINGLE_SIDE_SERVICE,
    name: translate('common.service'),
    color: '#7cc5ff', // blue
  },
  {
    id: DOUBLE_SIDE_SERVICE,
    name: translate('common.service'),
    color: '#7cc5ff', // blue
  },
];

export const TRAVEL_PATH_SERVICE_TYPES_IDS = mapKeys(TRAVEL_PATH_SERVICE_TYPES, 'id');
