import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  routeSequence as doRouteSequence,
  loadRouteSequenceStatus as doLoadRouteSequenceStatus,
  loadRouteSequenceStatusByVendor as doLoadRouteSequenceStatusByVendor,
  loadRouteSequence as doLoadRouteSequence,
  updateRouteSequenceStatus as doUpdateRouteSequenceStatus,
  loadRouteSequenceHistory as doLoadRouteSequenceHistory,
} from '../services/routeSequence';
import { RouteStatuses, RouteSequenceHistory } from 'src/routes/interfaces/Route';
import { SEQUENCE_SOURCE_TYPE_DAILY_ROUTE, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE } from '../constants';
import { size } from 'lodash-es';

const START_ROUTE_SEQUENCE = 'routes/route/START_ROUTE_SEQUENCE';
const COMPLETE_ROUTE_SEQUENCE = 'routes/route/COMPLETE_ROUTE_SEQUENCE';
const FAIL_ROUTE_SEQUENCE = 'routes/route/FAIL_ROUTE_SEQUENCE';
const START_LOAD_ROUTE_SEQUENCE_STATUS = 'routes/route/START_LOAD_ROUTE_SEQUENCE_STATUS';
const COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS = 'routes/route/COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS';
const FAIL_LOAD_ROUTE_SEQUENCE_STATUS = 'routes/route/FAIL_LOAD_ROUTE_SEQUENCE_STATUS';
const START_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED = 'routes/route/START_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED';
const COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED = 'routes/route/COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED';
const FAIL_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED = 'routes/route/FAIL_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED';
const START_LOAD_ROUTE_SEQUENCE = 'routes/route/START_LOAD_ROUTE_SEQUENCE';
const COMPLETE_LOAD_ROUTE_SEQUENCE = 'routes/route/COMPLETE_LOAD_ROUTE_SEQUENCE';
const FAIL_LOAD_ROUTE_SEQUENCE = 'routes/route/FAIL_LOAD_ROUTE_SEQUENCE';
const START_UPDATE_ROUTE_SEQUENCE_STATUS = 'routes/route/START_UPDATE_ROUTE_SEQUENCE_STATUS';
const COMPLETE_UPDATE_ROUTE_SEQUENCE_STATUS = 'routes/route/COMPLETE_UPDATE_ROUTE_SEQUENCE_STATUS';
const FAIL_UPDATE_ROUTE_SEQUENCE_STATUS = 'routes/route/FAIL_UPDATE_ROUTE_SEQUENCE_STATUS';
const START_LOAD_ROUTE_SEQUENCE_HISTORY = 'routes/route/START_LOAD_ROUTE_SEQUENCE_HISTORY';
const COMPLETE_LOAD_ROUTE_SEQUENCE_HISTORY = 'routes/route/COMPLETE_LOAD_ROUTE_SEQUENCE_HISTORY';
const FAIL_LOAD_ROUTE_SEQUENCE_HISTORY = 'routes/route/FAIL_LOAD_ROUTE_SEQUENCE_HISTORY';

interface State {
  isLoading: boolean;
  isUpdatingSequenceStatus: boolean;
  maxRouteTime?: number;
  routeSequence?: any[];
  routeSequenceHistory?: RouteSequenceHistory;
  routeSequenceHistoryIsLoading: boolean;
  routeStatuses?: RouteStatuses[];
  totalRouteTime?: number;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isUpdatingSequenceStatus: false,
  maxRouteTime: undefined,
  routeSequence: undefined,
  routeSequenceHistory: undefined,
  routeSequenceHistoryIsLoading: false,
  routeStatuses: undefined,
  totalRouteTime: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_ROUTE_SEQUENCE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_ROUTE_SEQUENCE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_ROUTE_SEQUENCE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_ROUTE_SEQUENCE_STATUS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS:
      return update(state, {
        $merge: {
          isLoading: false,
          routeStatuses: action.routeStatuses,
        },
      });

    case FAIL_LOAD_ROUTE_SEQUENCE_STATUS:
      return update(state, {
        $merge: {
          isLoading: false,
          routeStatuses: undefined,
        },
      });

    case START_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED:
      return update(state, {
        $merge: {
          isLoading: false,
          routeStatuses: action.routeStatuses,
        },
      });

    case FAIL_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED:
      return update(state, {
        $merge: {
          isLoading: false,
          routeStatuses: undefined,
        },
      });

    case START_LOAD_ROUTE_SEQUENCE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_SEQUENCE:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSequence: action.routeSequence.sequence,
          totalRouteTime: action.routeSequence.totalRouteTime,
          maxRouteTime: action.routeSequence.maxRouteTime,
        },
      });

    case FAIL_LOAD_ROUTE_SEQUENCE:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSequence: undefined,
        },
      });

    case START_UPDATE_ROUTE_SEQUENCE_STATUS:
      return update(state, {
        $merge: {
          isLoading: true,
          isUpdatingSequenceStatus: true,
        },
      });

    case COMPLETE_UPDATE_ROUTE_SEQUENCE_STATUS:
      return update(state, {
        $merge: {
          isLoading: false,
          isUpdatingSequenceStatus: false,
        },
      });

    case FAIL_UPDATE_ROUTE_SEQUENCE_STATUS:
      return update(state, {
        $merge: {
          isLoading: false,
          isUpdatingSequenceStatus: false,
        },
      });

    case START_LOAD_ROUTE_SEQUENCE_HISTORY:
      return update(state, {
        $merge: {
          routeSequenceHistoryIsLoading: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_SEQUENCE_HISTORY:
      return update(state, {
        $merge: {
          routeSequenceHistoryIsLoading: false,
          routeSequenceHistory: action.routeSequenceHistory,
        },
      });

    case FAIL_LOAD_ROUTE_SEQUENCE_HISTORY:
      return update(state, {
        $merge: {
          routeSequenceHistoryIsLoading: false,
          routeSequenceHistory: undefined,
        },
      });

    default:
      return state;
  }
};

const startRouteSequence = () => ({
  type: START_ROUTE_SEQUENCE,
});

const completeRouteSequence = () => ({
  type: COMPLETE_ROUTE_SEQUENCE,
});

const failRouteSequence = () => ({
  type: FAIL_ROUTE_SEQUENCE,
});

const startLoadRouteSequenceStatus = () => ({
  type: START_LOAD_ROUTE_SEQUENCE_STATUS,
});

const completeLoadRouteSequenceStatus = (routeStatuses: RouteStatuses[]) => ({
  type: COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS,
  routeStatuses,
});

const failLoadRouteSequenceStatus = () => ({
  type: FAIL_LOAD_ROUTE_SEQUENCE_STATUS,
});

const startLoadRouteSequenceStatusCombined = () => ({
  type: START_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED,
});

const completeLoadRouteSequenceStatusCombined = (routeStatuses: RouteStatuses[]) => ({
  type: COMPLETE_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED,
  routeStatuses,
});

const failLoadRouteSequenceStatusCombined = () => ({
  type: FAIL_LOAD_ROUTE_SEQUENCE_STATUS_COMBINED,
});

const startLoadRouteSequence = () => ({
  type: START_LOAD_ROUTE_SEQUENCE,
});

const completeLoadRouteSequence = (routeSequence: any[]) => ({
  type: COMPLETE_LOAD_ROUTE_SEQUENCE,
  routeSequence,
});

const failLoadRouteSequence = () => ({
  type: FAIL_LOAD_ROUTE_SEQUENCE,
});

const startUpdateRouteSequenceStatus = () => ({
  type: START_UPDATE_ROUTE_SEQUENCE_STATUS,
});

const completeUpdateRouteSequenceStatus = () => ({
  type: COMPLETE_UPDATE_ROUTE_SEQUENCE_STATUS,
});

const failUpdateRouteSequenceStatus = () => ({
  type: FAIL_UPDATE_ROUTE_SEQUENCE_STATUS,
});

const startLoadRouteSequenceHistory = () => ({
  type: START_LOAD_ROUTE_SEQUENCE_HISTORY,
});

const completeLoadRouteSequenceHistory = (routeSequenceHistory: RouteSequenceHistory) => ({
  type: COMPLETE_LOAD_ROUTE_SEQUENCE_HISTORY,
  routeSequenceHistory,
});

const failLoadRouteSequenceHistory = () => ({
  type: FAIL_LOAD_ROUTE_SEQUENCE_HISTORY,
});

export const routeSequence = (route: any) => (dispatch: Dispatch) => {
  dispatch(startRouteSequence());
  const routeSequencePromise = doRouteSequence(route);
  routeSequencePromise.then(() => dispatch(completeRouteSequence())).catch(() => dispatch(failRouteSequence()));
  return routeSequencePromise;
};

export const loadRouteSequenceStatus =
  (routeIds: number[] | string[], sequenceSourceTypeId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadRouteSequenceStatus());
    const loadRouteSequenceStatusPromise = doLoadRouteSequenceStatus(routeIds, sequenceSourceTypeId);
    loadRouteSequenceStatusPromise
      .then(routeStatuses => dispatch(completeLoadRouteSequenceStatus(routeStatuses)))
      .catch(() => dispatch(failLoadRouteSequenceStatus()));
    return loadRouteSequenceStatusPromise;
  };

export const loadRouteSequenceStatusByVendor =
  (vendorId: number, sequenceSourceTypeId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadRouteSequenceStatus());
    const loadRouteSequenceStatusByVendorPromise = doLoadRouteSequenceStatusByVendor(vendorId, sequenceSourceTypeId);
    loadRouteSequenceStatusByVendorPromise
      .then(routeStatuses => dispatch(completeLoadRouteSequenceStatus(routeStatuses)))
      .catch(() => dispatch(failLoadRouteSequenceStatus()));
    return loadRouteSequenceStatusByVendorPromise;
  };

export const loadRouteSequenceStatusCombined =
  (routeIds: number[], templateIds: number[]) => async (dispatch: Dispatch) => {
    dispatch(startLoadRouteSequenceStatusCombined());
    try {
      const routeStatus = size(routeIds)
        ? (await doLoadRouteSequenceStatus(routeIds, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)) || []
        : [];
      const templateStatus = size(templateIds)
        ? (await doLoadRouteSequenceStatus(templateIds, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE)) || []
        : [];
      const combinedStatus = routeStatus.concat(templateStatus);
      dispatch(completeLoadRouteSequenceStatusCombined(combinedStatus));
    } catch (e) {
      dispatch(failLoadRouteSequenceStatusCombined());
    }
  };

export const loadRouteSequence = (routeId: number | string, sequenceSourceTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteSequence());
  const loadRouteSequencePromise = doLoadRouteSequence(routeId, sequenceSourceTypeId);
  loadRouteSequencePromise
    .then(routeSequence => dispatch(completeLoadRouteSequence(routeSequence)))
    .catch(() => dispatch(failLoadRouteSequence()));
  return loadRouteSequencePromise;
};

export const updateRouteSequenceStatus = (routeSequenceStatus: any) => (dispatch: Dispatch) => {
  dispatch(startUpdateRouteSequenceStatus());
  const updateRouteSequenceStatusPromise = doUpdateRouteSequenceStatus(routeSequenceStatus);
  updateRouteSequenceStatusPromise
    .then(() => dispatch(completeUpdateRouteSequenceStatus()))
    .catch(() => dispatch(failUpdateRouteSequenceStatus()));
  return updateRouteSequenceStatusPromise;
};

export const loadRouteSequenceHistory = (routeId: number, sequenceSourceTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteSequenceHistory());

  const loadRouteSequenceHistoryPromise = doLoadRouteSequenceHistory(routeId, sequenceSourceTypeId);

  loadRouteSequenceHistoryPromise
    .then(routeSequenceHistory => dispatch(completeLoadRouteSequenceHistory(routeSequenceHistory)))
    .catch(() => dispatch(failLoadRouteSequenceHistory()));

  return loadRouteSequenceHistoryPromise;
};
