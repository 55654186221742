import styled, { css } from 'styled-components';
import { Icon } from '../../../../core/components';
import { BareButtonStyle } from '../../../../core/components/styled';
import { fadeAndSlideInDown } from '../../../../core/styles';
import { sizeMapper } from '../../../../utils/styles';

interface IsOpenProps {
  isOpen?: boolean;
}

export const RouteHeaderContainerDiv = styled.div<IsOpenProps>`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;

  ${props =>
    props.isOpen
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
        `}
`;

export const ContentContainer = styled.div<{
  margin?: string;
}>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: ${props => sizeMapper(props.margin, 'no no xSmall')};
`;

export const Content = styled.div<{ column?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
  ${p =>
    p.column &&
    css`
      flex-direction: column;
    `}
`;

export const PrimaryDetails = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export const SecondaryDetails = styled.div<IsOpenProps>`
  margin-top: 10px;
  display: flex;
  text-align: right;

  ${props =>
    props.isOpen
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
        `}
`;

export const MapIconContainer = styled.div`
  color: ${props => props.theme.brandPrimary};
  margin-top: -3px;

  svg {
    cursor: pointer;
  }
`;

export const MapIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

export const UnassignedJobsFilterContainer = styled.div<{
  padding?: string;
}>`
  flex: 1;
  padding: ${props => sizeMapper(props.padding, 'no no no small')};
`;

export const FilterInlineWrapper = styled.div<{
  padding?: string;
  alignItems?: string;
  flexWrap?: boolean;
}>`
  display: flex;
  padding: ${props => sizeMapper(props.padding, 'xSmall no no')};

  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  ${props =>
    props.flexWrap &&
    css`
      flex-wrap: wrap;
    `}
`;

export const FilterContainer = styled.div<
  IsOpenProps & {
    padding?: string;
  }
>`
  padding: ${props => sizeMapper(props.padding, 'xSmall no no')};
  ${props =>
    props.isOpen
      ? css`
          display: block;
          animation: ${fadeAndSlideInDown} 0.3s ease-out;
        `
      : css`
          display: none;
        `}
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
`;

export const Action = styled.button`
  ${BareButtonStyle}
  margin-left: 6px;
  padding: 4px;
  color: ${props => props.theme.gray};
  transition: color 0.3s ease-out;

  &:hover {
    color: ${props => props.theme.grayDarker};
  }
`;

export const ActionIcon = styled(Icon)`
  width: 14px;
  height: 14px;
`;

export const RouteDate = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};
`;

export const JobsCount = styled.div`
  margin: 5px 0 10px;
  font-size: 13px;
`;

export const RouteName = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
  padding-top: 5px;
`;

export const ToggleContainer = styled.div<{
  margin?: string;
}>`
  padding-top: 9px;
  cursor: pointer;
  margin: ${props => sizeMapper(props.margin, 'no no no xSmall')};
`;

export const DispatchBoardFilterContainer = styled.div<
  IsOpenProps & {
    padding?: string;
  }
>`
  padding: ${props => sizeMapper(props.padding, 'xSmall no no')};
  ${props =>
    props.isOpen
      ? css`
          display: fllex;
          animation: ${fadeAndSlideInDown} 0.3s ease-out;
          width: 100%;
        `
      : css`
          display: none;
        `}
`;
