import React from 'react';
import { Switch, Redirect, Route } from 'react-router';

import { Page } from '../../../common/components';
import { PageContent, PageHeader, PageDetails, PageTitleContainer, PageTitle } from '../../../common/components/styled';
import { Panel } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import RecurringServicesPageResolver from './RecurringServicesPageResolver';
import OnCallServicesPageResolver from './OnCallServicesPageResolver';
import ProcessedServicesPageResolver from './ProcessedServicesPageResolver';

const PaymentsPage = () => (
  <Page>
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('payment.payments')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>

      <Panel>
        <Switch>
          <Route exact path="/payments/recurring-service/:token" component={RecurringServicesPageResolver} />
          <Route exact path="/payments/oncall-service/:token" component={OnCallServicesPageResolver} />
          <Route exact path="/payments/processed-service/:token" component={ProcessedServicesPageResolver} />
          <Redirect to="/account/login" />
        </Switch>
      </Panel>
    </PageContent>
  </Page>
);

export default PaymentsPage;
