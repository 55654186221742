import { MouseEvent } from 'react';

import { uniq } from 'lodash-es';

import { createErrorNotification, createSuccessNotification } from '../../../../../../core/services/createNotification';
import translate from '../../../../../../core/services/translate';

export const sendJobSelectionNotifications = (noSelectedJobs: number, maxSelected: number) => {
  let message = translate('routes.alertMessages.routeLocationNotSelected');
  let notification = createSuccessNotification;
  if (noSelectedJobs === 1) {
    message = translate('routes.alertMessages.routeLocationSelected', { numberOfJobs: noSelectedJobs });
  }
  if (noSelectedJobs > 1 && noSelectedJobs < maxSelected) {
    message = translate('routes.alertMessages.routeLocationsSelected', { numberOfJobs: noSelectedJobs });
  }
  if (noSelectedJobs === maxSelected) {
    message = translate('routes.alertMessages.routeLocationsMaxSelected', {
      numberOfJobs: noSelectedJobs,
      SELECTED_TRANSFERRED_STOPS: maxSelected,
    });
  }
  if (noSelectedJobs > maxSelected) {
    message = translate('routes.alertMessages.routeLocationsMoreThan', {
      SELECTED_TRANSFERRED_STOPS: maxSelected,
    });
    notification = createErrorNotification;
  }

  notification(message);
};

export const getMultiSelectedJobs = (
  index: number,
  selectedJobs: number[],
  maxSelected: number,
  event: MouseEvent,
  canSelect: boolean = true,
  isCheckboxClicked: boolean = false,
) => {
  let selectedJobsCopy = [...selectedJobs];
  // modifiers
  const { ctrlKey, metaKey, shiftKey } = event;
  const noModifier = !ctrlKey && !metaKey && !shiftKey;
  const ctrlCmd = ctrlKey || metaKey;

  const selectedJobIndex = selectedJobsCopy.indexOf(index);

  if (canSelect) {
    if (isCheckboxClicked) {
      if (selectedJobIndex < 0) {
        selectedJobsCopy.push(index);
      } else {
        selectedJobsCopy.splice(selectedJobIndex, 1);
      }
    } else if (noModifier) {
      if (selectedJobsCopy.length === 1 && selectedJobIndex > -1) {
        // if it's the only selected job, clear the state
        selectedJobsCopy = [];
      } else {
        selectedJobsCopy = [index];
      }
    } else if (ctrlCmd) {
      if (selectedJobIndex > -1) {
        // remove index
        selectedJobsCopy.splice(selectedJobIndex, 1);
      } else if (selectedJobsCopy.length < maxSelected) {
        // add index
        selectedJobsCopy.push(index);
      }
    } else if (shiftKey) {
      const lastSelectedJob = selectedJobsCopy[selectedJobsCopy.length - 1];

      if (index !== lastSelectedJob) {
        for (let i = Math.min(index, lastSelectedJob); i < Math.max(index, lastSelectedJob) + 1; i++) {
          if (selectedJobsCopy.length <= maxSelected) {
            selectedJobsCopy.push(i);
          }
        }
      } else {
        selectedJobsCopy = [index];
      }
    }
  }

  const unique = uniq(selectedJobsCopy); // remove duplicates
  selectedJobsCopy = [...unique].sort((a, b) => a - b); // back to array and sort

  return selectedJobsCopy;
};
