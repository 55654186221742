import { darken, transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon } from '../../../core/components';
import { BareButtonStyle } from '../../../core/components/styled';
import { loadingOverlay } from '../../../core/styles';
import { mapper } from '../../../utils/styles';

interface Props {
  isCursorPointer?: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  sortOrder?: string;
}

export const InsightCardTitle = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InsightCardInfoIcon = styled(Icon).attrs({ icon: 'info' })`
  width: 100%;
  height: 100%;
  color: ${props => props.theme.grayDark};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const InsightCardInfo = styled.span`
  position: relative;
  width: 14px;
  height: 14px;
`;

export const InsightCardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const InsightCardValue = styled.span`
  display: block;
  margin-bottom: 4px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 36px;
`;

export const InsightCardValueUnit = styled.span`
  display: block;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-size: 12px;
`;

export const InsightCardValueExplain = styled.span`
  display: block;
  text-transform: lowercase;
  font-size: 12px;
  color: ${props => props.theme.grayDark};
`;

export const InsightCardSeeDetails = styled(Link)`
  text-transform: capitalize;
  color: ${props => props.theme.brandPrimary};

  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
  }
`;

export const InsightCardValues = styled.div``;

export const InsightCardDetails = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const InsightCardMoreDataAvailable = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  transform: rotate(90deg);
  background-color: ${p => p.theme.brandPrimary};
  padding: 5px;
  color: white;
`;

export const InsightCardChartContainer = styled.div<Props>`
  position: relative;
  ${props =>
    props.isCursorPointer &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
  display: flex;
  justify-content: center;
  padding: 0 20px 10px;
`;

export const InsightCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 485px;
  background: #fff;
  box-shadow: 0 3px 6px ${props => transparentize(0.98, props.theme.grayBase)};
  transition: box-shadow 0.3s ease-out;

  &:hover {
    box-shadow: 0 4px 12px ${props => transparentize(0.92, props.theme.grayBase)};
  }
`;

export const InsightCardTableCell = styled.div`
  padding: 10px 10px;
  line-height: 16px;
`;

export const InsightCardTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const InsightCardTableHead = styled.div`
  color: ${props => props.theme.grayDark};
`;

export const InsightCardTableSortIcon = styled(Icon).attrs({ icon: 'arrowDownFill' })<Props>`
  width: 10px;
  height: 10px;
  margin-left: 6px;
  transform: ${props => mapper(props.sortOrder, { asc: 'none', desc: 'rotate(180deg)' })};
`;

export const InsightCardTableSort = styled.button<Props>`
  ${BareButtonStyle};

  ${InsightCardTableSortIcon} {
    transform: ${props => mapper(props.sortOrder, { asc: 'rotate(180deg)', desc: 'none' })};
  }
`;

export const InsightCardTableBody = styled.div`
  height: 108px;
  overflow: hidden;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABICAYAAAA3Qp8tAAAABGdBTUEAALGPC/xhBQAAABxJREFUGBlj+PHr138mBiAYOgTDfyAYOs4FuxQAoCwIdGtMvrcAAAAASUVORK5CYII=')
    repeat;
`;

export const InsightCardTableToggle = styled.button`
  ${BareButtonStyle};
  text-transform: capitalize;
  font-size: 14px;
  color: ${props => props.theme.brandPrimary};

  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
  }
`;

export const InsightCardTableToggleContainer = styled.div`
  height: 60px;
  padding: 20px 0;
  text-align: center;
`;

export const InsightCardTable = styled.div`
  flex: 1;
  overflow-y: auto;
  font-size: 13px;
`;

export const InsightCardTableContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${props =>
    props.isExpanded &&
    css`
      height: 100%;

      ${InsightCardTableBody} {
        height: auto;
      }
    `};
`;

export const InsightCard = styled.div`
  width: 32%;
  margin: 0 2% 40px 0;

  &:nth-child(3n) {
    margin-right: 0;
  }
`;

export const InsightCards = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;
