import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

const getReportDate = (paymentMethodStates: any) => {
  let paymentReportDate = '';
  paymentReportDate = paymentMethodStates.recurringServices.pricingInfo
    ? paymentMethodStates.recurringServices.pricingInfo.reportDate
    : paymentReportDate;
  paymentReportDate =
    !paymentReportDate && paymentMethodStates.onCallServices.pricingInfo
      ? paymentMethodStates.onCallServices.pricingInfo.reportDate
      : paymentReportDate;
  paymentReportDate =
    !paymentReportDate && paymentMethodStates.processedServices.pricingInfo
      ? paymentMethodStates.processedServices.pricingInfo.reportDate
      : paymentReportDate;

  return paymentReportDate;
};

export const reportDateSelector = createSelector(getReportDate, identity);
