import { map } from 'lodash-es';

import { http } from '../../core/services/http';
import { ISystemTypeDataView } from 'src/vendors/interfaces/BillingModule';

export const transformSystemTypeDataView = (viaType: any) =>
  map(viaType, e => ({
    label: e.name,
    value: e.id,
    technicalName: e.technicalName,
    name: e.name,
    id: e.id,
  }));

const doLoadFeeStructure = () =>
  http
    .get<ISystemTypeDataView[]>('/common/systemTypes/feeStructures')
    .then(response => transformSystemTypeDataView(response.data));

export default doLoadFeeStructure;
