import React, { useCallback, useState } from 'react';
import {  useSelector } from 'react-redux';
import styled from 'styled-components';

import AdminGuard from '../../account/components/AdminGuard';
import VendorWithGusIdGuard from '../../account/components/VendorWithGusIdGuard';
import translate from '../../core/services/translate';
import LanguageSettingsModal from '../../settings/components/LanguageSettingsModal';
import { AppState } from '../../store';
import {
  MainNavigationButton,
  MainNavigationIcon,
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuItemButton,
} from './styled/NavigationBar';

interface Props {
  isLoggedIn: boolean;
  signInNonTechHaulerUrl: string;
}

const ReleaseNotesNode = styled.span`
  position: relative;
`;

const ReleaseNotesNodeNotification = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  background: red;
  border-radius: 10px;
  margin-left: 5px;
  right: -20px;
  top: 4px;
  color: white;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Notification = styled.div`
  position: absolute;
  right: -8px;
  top: 17px;
  height: 20px;
  width: 20px;
  background: red;
  border-radius: 10px;
  border: 1px solid #666;
`;

export const NavigationBarSettingsGear: React.FC<Props> = ({ isLoggedIn, signInNonTechHaulerUrl }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageSettingsModalOpen, setIsLanguageSettingsModalOpen] = useState(false);

  const showReleaseNotesNotification = useSelector((s: AppState) => s.settings.releaseNotes.showNotification);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    document.removeEventListener('click', closeMenu);
  }, []);

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
    document.addEventListener('click', closeMenu);
  };

  return (
    <>
      <MainNavigationButton onClick={openMenu} isHighlighted={isMenuOpen} id="menu-settings-button">
        {showReleaseNotesNotification && <Notification id="menu-settings-button-notification">!</Notification>}
        <MainNavigationIcon icon="settingsCircle" />
        <NavigationMenu isOpen={isMenuOpen}>
          {!isLoggedIn ? (
            <NavigationMenuItem to={signInNonTechHaulerUrl} id="menu-logout-button">
              {translate('account.login')}
            </NavigationMenuItem>
          ) : (
            <div>
              <NavigationMenuItemButton
                type="button"
                onClick={() => setIsLanguageSettingsModalOpen(true)}
                id="menu-language-button"
              >
                {translate('account.languageSettings')}
              </NavigationMenuItemButton>
              <NavigationMenuItem to="/account-settings" id="menu-account-button">
                {translate('common.applicationSettings')}
              </NavigationMenuItem>
              <AdminGuard>
                <NavigationMenuItem to="/settings/tracking" id="menu-application-button">
                  {translate('common.adminSettings')}
                </NavigationMenuItem>
              </AdminGuard>
                <VendorWithGusIdGuard>
                  <NavigationMenuItem to="/hauler-profile/overview" id="menu-account-button">
                    {translate('haulerProfile.haulerProfile')}
                  </NavigationMenuItem>
                </VendorWithGusIdGuard>
              <NavigationMenuItem to="/release-notes" id="menu-release-notes">
                <ReleaseNotesNode>
                  {showReleaseNotesNotification && (
                    <ReleaseNotesNodeNotification id="menu-release-notes-notification">!</ReleaseNotesNodeNotification>
                  )}
                  {translate('versioning.releaseNotes')}
                </ReleaseNotesNode>
              </NavigationMenuItem>
              <NavigationMenuItem to="/account/logout" id="menu-logout-button">
                {translate('account.logout')}
              </NavigationMenuItem>
            </div>
          )}
        </NavigationMenu>
      </MainNavigationButton>
      {isLanguageSettingsModalOpen && (
        <LanguageSettingsModal onCloseModal={() => setIsLanguageSettingsModalOpen(false)} />
      )}
    </>
  );
};
