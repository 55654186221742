import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createErrorNotificationIncludingTechnicalMessage } from 'src/core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { loadBillingModule } from 'src/vendors/ducks';
import { loadFeeStructure } from 'src/common/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import BillingModuleModal from './BillingModuleModal';
import { loadRateAccountingCodes, loadRateCodeTypes } from 'src/common/ducks';


const BillingModuleModalResolver: FC<ModalProps> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadBillingModule(vendorId)(dispatch),
    loadFeeStructure()(dispatch),
    loadRateCodeTypes()(dispatch),
    loadRateAccountingCodes()(dispatch)]);
  }, [vendorId, dispatch]);

  const onError = useCallback(
    e => {
      closeModal();
      createErrorNotificationIncludingTechnicalMessage(e, e.code);
    },
    [closeModal],
  );

  return (
    <TypedResolver
      successComponent={BillingModuleModal}
      successProps={{ closeModal }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      onError={onError}
    />
  );
};

export default BillingModuleModalResolver;
