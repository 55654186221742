import translate from "src/core/services/translate";

export const billingStatus = [
  {
    label: translate('billing.draft'),
    value: false,
  },
  {
    label: translate('billing.billed'),
    value: true,
  },
];
