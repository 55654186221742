import { customerLocationByIdSelector, State } from '../ducks/locations';
import { ACTIVE } from 'src/common/constants/accountStatuses';
import { CustomerLocationAddress } from '../interfaces/Customers';

const customerLocationEditorFormInitialValues = (
  customerLocationsState: State,
  customerLocationId: number | undefined,
  serviceContractLocation: string,
  serviceContractFullAddress: CustomerLocationAddress,
) => {
  if (customerLocationsState && customerLocationId) {
    const customerLocationById = (customerLocationByIdSelector as any)(customerLocationsState, customerLocationId);
    if (customerLocationById) {
      return {
        ...customerLocationById,
        accountStatusId: customerLocationById.accountStatusId || ACTIVE,
        accountStatusTypeId: customerLocationById.accountStatusTypeId || ACTIVE,
        locationName: customerLocationById.name,
      };
    }
  }

  if (serviceContractLocation && serviceContractFullAddress) {
    return {
      accountStatusId: ACTIVE,
      locationName: serviceContractLocation,
      address: {
        ...serviceContractFullAddress,
        formattedAddress: serviceContractFullAddress ? serviceContractFullAddress.line1 : undefined,
      },
    };
  }

  return {
    accountStatusId: ACTIVE,
  };
};

export default customerLocationEditorFormInitialValues;
