import { change, Field, formValueSelector, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import { find, last, map, size, uniq } from 'lodash-es';
import { PureComponent } from 'react';

import { AppState } from 'src/store';
import { Button, ButtonSet, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import {
  COMPLETED,
  EXCEPTION_CONTAMINATED_UNACCEPTABLE,
  EXCEPTION_EXTRA,
  EXCEPTION_LABOR_TIME,
  EXCEPTION_WAIT_TIME,
  EXCEPTIONS,
  ISSUE_REPORTED,
  PARTICIPATION,
  REASON_IMPROPER_SETOUT_OF_LOOSE_TRASH,
  REASON_TRASH_CONTAINER_UNACCEPTABLE,
  ROUTE_PICKUP_TYPE_IDS,
} from '../../constants';
import { DEFAULT_TIME } from '../../../core/constants';
import { DETAIL_NONE, DETAIL_REQUIRED } from 'src/routes/constants/canAddDetails';
import {
  ExceptionsDropdown,
  ExceptionsForWasteAuditMultiSelect,
  JobStatusDropdown,
  ReasonsDropdown,
  WasteAuditParticipationDropdown,
} from '..';
import { Input, InputFile } from '../../../core/components';
import { isMultiSelectRequired, isRequired, isTimeValid } from '../../../utils/services/validator';
import { VEHICLE_TYPE_IDS, WASTE_AUDIT_ID } from '../../../fleet/constants';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface ComponentProps {
  change?: any;
  elapsedTime?: string;
  exception?: number;
  exceptions?: any[];
  jobStatusId?: number;
  routeLocation: any;
  routeLocationInfo: {
    equipmentTypeNameSize?: string;
    jobStatusId?: number;
    pickupExceptions?: any[];
    vehicleTypeId?: number;
    wasteAuditTypeId?: number;
    wasteMaterialTypeName?: string;
  };
  selectedException?: number;
  submit?: any;
  wasteAuditParticipationStatuses?: any[];
}

interface State {
  currentTime?: string;
  fieldNumericRequired?: boolean;
  fieldNumericVisible?: boolean;
  fieldTimeVisible?: boolean;
  jobStatusChanged?: boolean;
  jobStatusIsCompleted: boolean;
  jobStatusIsIssueReported: boolean;
  reasons?: any[];
  routeIsExceptions: boolean;
  routeIsParticipation: boolean;
  time?: string;
  uploadImageVisible?: boolean;
  uploadWaImageVisible?: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class JobStatusForm extends PureComponent<Props, State> {
  static defaultProps = {
    routeLocationInfo: {
      pickupExceptions: [],
    },
  };

  constructor(props: Props) {
    super(props);
    const { elapsedTime, routeLocationInfo } = this.props;
    const { jobStatusId, wasteAuditTypeId } = routeLocationInfo;

    this.state = {
      fieldNumericRequired: undefined,
      fieldNumericVisible: undefined,
      fieldTimeVisible: undefined,
      jobStatusChanged: true,
      jobStatusIsCompleted: jobStatusId === COMPLETED,
      jobStatusIsIssueReported: jobStatusId === ISSUE_REPORTED,
      reasons: undefined,
      routeIsExceptions: wasteAuditTypeId === EXCEPTIONS,
      routeIsParticipation: wasteAuditTypeId === PARTICIPATION,
      time: elapsedTime,
      uploadImageVisible: undefined,
      uploadWaImageVisible: undefined,
    };
  }

  componentDidMount = () => {
    const { elapsedTime, exception, exceptions, routeLocationInfo } = this.props;
    const { jobStatusId } = routeLocationInfo;
    if (exceptions) {
      const isOnPageLoad = true;
      this.onChangeException(undefined, exception, exceptions, isOnPageLoad);
    }

    this.updateFormValue('elapsedTime', elapsedTime);

    this.setNotesAndImageMandatory(jobStatusId);
  };

  componentDidUpdate(prevProps: Props) {
    const { exception, exceptions } = this.props;
    if (exceptions !== prevProps.exceptions) {
      const isOnPageLoad = true;
      this.onChangeException(undefined, exception, exceptions, isOnPageLoad);
    }
  }

  onChangeJobStatus = async (event: any, value: number) => {
    const shouldResetException = true;
    await this.resetFormFields(shouldResetException);

    this.setState({
      fieldNumericVisible: false,
      fieldTimeVisible: false,
      jobStatusChanged: true,
      jobStatusIsCompleted: value === COMPLETED,
      jobStatusIsIssueReported: value === ISSUE_REPORTED,
      uploadImageVisible: false,
    });

    this.setNotesAndImageMandatory(value);
  };

  onChangeException = async (event: any, value: any, pickupExceptions?: any[], isOnPageLoad?: boolean) => {
    const { exceptions } = this.props;
    const currentExceptions = exceptions || pickupExceptions;
    const filteredCurrentExceptions = currentExceptions?.filter(exception => exception.id === value);
    const IMAGE_REQUIRED = 1;
    const IMAGE_OPTIONAL = 3;

    const reasons =
      currentExceptions && value && size(filteredCurrentExceptions) > 0
        ? currentExceptions.filter(exception => exception.id === value)[0].pickupExceptionSubTypes
        : undefined;
    const uploadImageVisible =
      currentExceptions && value && size(filteredCurrentExceptions) > 0
        ? currentExceptions.filter(exception => exception.id === value)[0].canTakePicture === IMAGE_REQUIRED ||
          currentExceptions.filter(exception => exception.id === value)[0].canTakePicture === IMAGE_OPTIONAL
        : undefined;

    if (isOnPageLoad !== true) {
      await this.resetFormFields();
    }

    this.setState({
      fieldNumericVisible:
        value === EXCEPTION_CONTAMINATED_UNACCEPTABLE || value === EXCEPTION_EXTRA || value === EXCEPTION_LABOR_TIME,
      fieldTimeVisible: value === EXCEPTION_WAIT_TIME || value === EXCEPTION_LABOR_TIME,
      jobStatusChanged: false,
      reasons,
      uploadImageVisible,
    });
  };

  onChangeReason = (value: number) => {
    this.setState({
      fieldNumericRequired:
        value !== REASON_TRASH_CONTAINER_UNACCEPTABLE && value !== REASON_IMPROPER_SETOUT_OF_LOOSE_TRASH,
    });
  };

  onNumericValueChange = (event: any, value: string) => {
    setTimeout(() => {
      this.updateFormValue('numericValue', parseFloat(value) || null);
    });
  };

  onTimeChange = async (event: any) => {
    const currentTime = event.target.value;

    await this.setState({ currentTime });
    this.updateFormValue('elapsedTime', currentTime);
  };

  setNotesAndImageMandatory = (value?: number) => {
    const { vehicleTypeId } = this.props.routeLocationInfo;
    const { routeIsExceptions, routeIsParticipation } = this.state;
    const fieldImageVisible =
      (vehicleTypeId === WASTE_AUDIT_ID && value === COMPLETED && routeIsExceptions) ||
      (vehicleTypeId === WASTE_AUDIT_ID && value === COMPLETED && routeIsParticipation);

    this.setState({
      uploadWaImageVisible: fieldImageVisible,
    });
  };

  resetFormFields = (shouldResetException?: boolean) => {
    if (shouldResetException) {
      this.updateFormValue('exception', null);
    }
    this.updateFormValue('reason', null);
    this.updateFormValue('numericValue', null);
    this.updateFormValue('notes', null);

    this.setState({ time: DEFAULT_TIME });
    this.updateFormValue('elapsedTime', DEFAULT_TIME);
  };

  updateFormValue = (name: string, value: any) => {
    this.props.change(name, value);
  };

  handleSubmit = () => {
    this.props.submit('serviceDetailsEditor');
  };

  render() {
    const { exceptions, handleSubmit, routeLocationInfo, wasteAuditParticipationStatuses, selectedException } =
      this.props;
    const { vehicleTypeId, pickupExceptions } = routeLocationInfo;
    const {
      fieldNumericRequired,
      fieldNumericVisible,
      fieldTimeVisible,
      jobStatusChanged,
      jobStatusIsCompleted,
      jobStatusIsIssueReported,
      reasons,
      routeIsExceptions,
      routeIsParticipation,
      time,
      uploadImageVisible,
      uploadWaImageVisible,
    } = this.state;

    const pickupExceptionType = uniq(
      map(pickupExceptions, pickupException => pickupException.pickupExceptionType.id),
    )[0];
    const canAddDetail =
      jobStatusIsIssueReported &&
      !jobStatusChanged &&
      find(exceptions, exception => exception.id === pickupExceptionType || exception.id === selectedException)
        ?.canAddDetail !== DETAIL_NONE;
    const canAddDetailIsRequired =
      jobStatusIsIssueReported &&
      !jobStatusChanged &&
      find(exceptions, exception => exception.id === pickupExceptionType || exception.id === selectedException)
        ?.canAddDetail === DETAIL_REQUIRED;

    return (
      <PanelSection padding="small xSmall">
        <form noValidate onSubmit={handleSubmit}>
          <Grid multiLine>
            <GridColumn size="6/12">
              <Field
                component={Input}
                disabled
                label={translate('routes.editJobStatus.customerName')}
                margin="small no"
                name="customerName"
                opacity={1}
              />
              <Field
                component={Input}
                disabled
                label={translate('routes.editJobStatus.equipmentTypeSize')}
                margin="small no"
                name="equipmentTypeSize"
                opacity={1}
              />
              <Field
                component={JobStatusDropdown as any}
                label={translate('routes.editJobStatus.jobStatus')}
                name="jobStatus"
                onChange={this.onChangeJobStatus}
                vehicleTypeId={vehicleTypeId && VEHICLE_TYPE_IDS[vehicleTypeId].id}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                component={Input}
                disabled
                label={translate('routes.pickupType')}
                margin="small no"
                name="pickupType"
                opacity={1}
              />
              <Field
                component={Input}
                disabled
                label={translate('common.wasteType')}
                margin="small no"
                name="wasteMaterialTypeName"
                opacity={1}
              />
              {vehicleTypeId === WASTE_AUDIT_ID && jobStatusIsCompleted && routeIsExceptions && (
                <Field
                  component={ExceptionsForWasteAuditMultiSelect}
                  label={translate('routes.editJobStatus.exceptions')}
                  multiSelectProps={{ margin: 'small no' }}
                  name="wasteAuditExceptions"
                  placeholder={translate('routes.editJobStatus.selectExceptions')}
                  validate={[isMultiSelectRequired]}
                />
              )}
              {vehicleTypeId === WASTE_AUDIT_ID && jobStatusIsCompleted && routeIsParticipation && (
                <Field
                  component={WasteAuditParticipationDropdown}
                  label={translate('routes.editJobStatus.exception')}
                  name="wasteAuditParticipation"
                  validate={[isRequired]}
                  wasteAuditParticipationStatuses={wasteAuditParticipationStatuses}
                />
              )}
              {jobStatusIsIssueReported && (
                <Field
                  component={ExceptionsDropdown as any}
                  exceptions={exceptions}
                  label={translate('routes.editJobStatus.exception')}
                  name="exception"
                  onChange={this.onChangeException as any}
                  validate={[isRequired]}
                />
              )}
            </GridColumn>
            {jobStatusIsIssueReported && size(reasons) > 0 && !jobStatusChanged && (
              <GridColumn size="6/12">
                <Field
                  component={ReasonsDropdown as any}
                  label={translate('routes.editJobStatus.reason')}
                  name="reason"
                  reasons={reasons}
                  onChange={this.onChangeReason as any}
                  validate={[isRequired]}
                />
              </GridColumn>
            )}
            {jobStatusIsIssueReported && fieldNumericVisible && !jobStatusChanged && (
              <GridColumn size="6/12">
                <Field
                  component={Input}
                  label={translate('routes.editJobStatus.value')}
                  name="numericValue"
                  onChange={this.onNumericValueChange}
                  type="text"
                  validate={fieldNumericRequired ? [isRequired] : []}
                />
              </GridColumn>
            )}
            {jobStatusIsIssueReported && fieldTimeVisible && !jobStatusChanged && (
              <GridColumn size="6/12">
                <Field
                  component={Input}
                  isTimeField
                  label={translate('routes.editJobStatus.elapsedTime')}
                  name="elapsedTime"
                  onTimeChange={this.onTimeChange}
                  time={time}
                  validate={[isRequired, isTimeValid]}
                />
              </GridColumn>
            )}
            {canAddDetail && (
              <GridColumn size="12/12">
                <Field
                  component={Input}
                  label={translate('routes.editJobStatus.details')}
                  name="notes"
                  validate={canAddDetailIsRequired ? [isRequired] : []}
                />
              </GridColumn>
            )}
            {((jobStatusIsIssueReported && uploadImageVisible && !jobStatusChanged) || uploadWaImageVisible) && (
              <GridColumn size="12/12">
                <Field
                  name="file"
                  multiple={vehicleTypeId !== WASTE_AUDIT_ID}
                  component={InputFile}
                  accept=".jpg"
                  type="file"
                />
              </GridColumn>
            )}
          </Grid>
          <Grid centered>
            <GridColumn size="6/12">
              <ButtonSet margin="large no no">
                <Button type="button" color="primary" onClick={() => this.handleSubmit()}>
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </form>
      </PanelSection>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const formSelector = formValueSelector('jobStatusEditor');

  const { routeLocation, routeLocationInfo } = ownProps;
  const {
    equipmentTypeNameSize,
    jobStatusId,
    pickupExceptions,
    vehicleTypeId,
    wasteAuditTypeId,
    wasteMaterialTypeName,
  } = routeLocationInfo;

  const lastPickupException = last(pickupExceptions);
  const elapsedTime = lastPickupException ? lastPickupException.elapsedTime : DEFAULT_TIME;
  const wasteAuditStatuses = routeLocation.wasteAuditStatuses as any[];

  let notes;
  if (lastPickupException) {
    notes = lastPickupException.description;
  }

  const wasteAuditExceptions: any[] = [];
  if (wasteAuditStatuses) {
    wasteAuditStatuses.forEach(status => {
      if (status.type === PARTICIPATION) {
        wasteAuditExceptions.push(`status-${status.id}`);
      } else {
        wasteAuditExceptions.push(status.id);
      }
    }, wasteAuditStatuses);
  }

  return {
    selectedException: formSelector(state, 'exception'),
    elapsedTime,
    exception: lastPickupException ? lastPickupException.pickupExceptionType.id : null,
    exceptions: state.routes.exceptions.exceptions,
    initialValues: {
      customerName: routeLocation.customerName,
      elapsedTime,
      equipmentTypeSize: equipmentTypeNameSize,
      exception: lastPickupException ? lastPickupException.pickupExceptionType.id : null,
      jobStatus: jobStatusId,
      wasteMaterialTypeName,
      notes,
      numericValue: lastPickupException ? lastPickupException.numericValue : null,
      pickupType: translate(
        createTranslationKey(ROUTE_PICKUP_TYPE_IDS[routeLocation.pickupTypeId].technicalName, 'routes.pickupTypes'),
      ),
      reason:
        lastPickupException && lastPickupException.pickupExceptionSubType
          ? lastPickupException.pickupExceptionSubType.id
          : null,
      wasteAuditExceptions,
      wasteAuditParticipation:
        wasteAuditTypeId === PARTICIPATION && last(routeLocation.wasteAuditStatuses)
          ? (last(routeLocation.wasteAuditStatuses) as any).id
          : null,
    },
    jobStatusId,
    vehicleTypeId,
    wasteAuditParticipationStatuses: state.vendors.wasteAuditConfiguration.wasteAuditConfiguration
      ? (
          state.vendors.wasteAuditConfiguration.wasteAuditConfiguration![0] as any
        ).vendorWasteAuditConfigurationStatusTypes.filter(
          (exceptionForWasteAudit: any) => exceptionForWasteAudit.isActive === true,
        )
      : null,
    wasteAuditTypeId,
  };
};

const mapDispatchToProps = {
  change,
  submit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    enableReinitialize: true,
    form: 'jobStatusEditor',
    onSubmitFail: focusFirstInvalidField,
  })(JobStatusForm),
);
