import { connect, useDispatch } from 'react-redux';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Field, InjectedFormProps, change, reduxForm } from 'redux-form';
import { isEqual, size } from 'lodash-es';
import mapboxgl from 'mapbox-gl';

import { ActionButtonTooltip, LocationPicker } from 'src/core/components';
import { Button, ButtonSet, Grid, GridColumn, IconButtonIcon, PinOnMapContainer } from 'src/core/components/styled';
import { ComplexMapControl } from './styled/RouteMap';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { CustomerLocationAddress, PinLocation } from 'src/customers/interfaces/Customers';
import { CustomerProximityMapGLSource } from 'src/customers/components/forms';
import { GeoFence } from '../ducks/geoFences';
import { getMapBounds } from 'src/common/components/map/util';
import { getMapZoom } from 'src/customers/components/forms/CustomerProximityMapGL';
import { loadPinOnMapGeoFences, resetPinOnMapGeoFences } from '../ducks';
import { MAP_CITY_ZOOM, MAP_CITY_ZOOM_IN, MAP_CITY_ZOOM_IN_BIGGER } from 'src/core/constants/mapOptions';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLWrapper } from 'src/customers/components/styled';
import { PinOnMapWrapper, SelectOnMap } from './styled';
import { saveGeocode } from 'src/customers/services/locations';
import { theme } from 'src/core/styles';
import { useSelector } from 'src/core/hooks/useSelector';
import BackButton from './pages/dispatchBoard/dispatchBoardPageSections/common/BackButton';
import OnMapFiltersForm, {
  OnMapFiltersFormValues,
} from './pages/dispatchBoard/dispatchBoardMapSections/map/OnMapFiltersForm';
import RouteMapGeoFencesGL from './pages/routes/routePageSections/routeMap/geoFences/RouteMapGeoFencesGL';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import translate from 'src/core/services/translate';
import useGeoFenceControllerMapbox from 'src/common/hooks/geoFenceControllerMapbox';

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

interface ComponentProps {
  change: any;
  handleBackClick: () => void;
  handlePinSelection: (pinSelection: CustomerLocationAddress) => void;
  handleProximitySearchClickEvent?: () => void;
  mapCenterByVendor?: {
    lat: any;
    lng: any;
  };
  modalHasTitle: boolean;
  setPinLocationOnMap?: (pinLocation: any) => void;
  sourceIsProximity?: boolean;
  circleOptions?: any;
  submitButtonTranslationKey?: string;
  mapMarker?: {
    address: {
      latitude: any;
      longitude: any;
    };
  }[];
  shouldBeDraggableByDefault?: boolean;
  shouldHideMapFilters?: boolean;
  radius?: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const PinOnMapMapbox: FC<Props> = ({
  change,
  circleOptions,
  handleBackClick,
  handlePinSelection,
  handleProximitySearchClickEvent,
  mapCenterByVendor,
  mapMarker: mapMarkerFromProps,
  modalHasTitle,
  radius,
  setPinLocationOnMap,
  shouldBeDraggableByDefault,
  shouldHideMapFilters,
  sourceIsProximity,
  submitButtonTranslationKey,
}) => {
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();
  const { homeAddress: vendorAddress } = useSelector(state => state.vendors.vendor.vendor);

  const marker = useRef<mapboxgl.Marker | null>(
    mapMarkerFromProps?.length
      ? new mapboxgl.Marker({ draggable: shouldBeDraggableByDefault, color: theme.brandPrimary }).setLngLat([
          mapMarkerFromProps[0].address.longitude,
          mapMarkerFromProps[0].address.latitude,
        ])
      : null,
  );

  const [isFetchingAddress, setIsFetchingAddress] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<CustomerLocationAddress | undefined>();
  const [isMapFiltersOpen, setIsMapFiltersOpen] = useState(false);
  const [mapRef, setMapRef] = useState<mapboxgl.Map | null>(null);

  const [dragPan, setDragPan] = useState(true);

  const initialLatitude =
    circleOptions && size(circleOptions.proximitySearchResults)
      ? circleOptions.pinLocation.lat
      : mapCenterByVendor
      ? typeof mapCenterByVendor.lat === 'function'
        ? mapCenterByVendor.lat()
        : mapCenterByVendor.lat
      : vendorAddress.latitude;

  const initialLongitude =
    circleOptions && size(circleOptions.proximitySearchResults)
      ? circleOptions.pinLocation.lng
      : mapCenterByVendor
      ? typeof mapCenterByVendor.lng === 'function'
        ? mapCenterByVendor.lng()
        : mapCenterByVendor.lng
      : vendorAddress.longitude;

  const initialZoom = circleOptions && size(circleOptions.proximitySearchResults) ? MAP_CITY_ZOOM_IN : MAP_CITY_ZOOM;

  const [viewport, setViewport] = useState<any>({
    latitude: initialLatitude,
    longitude: initialLongitude,
    zoom: initialZoom,
  });

  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });

  const [lineAddress, setLineAddress] = useState();
  const [coordinates, setCoordinates] = useState<PinLocation>();

  const { geoFences: geoFenceJsonList } = useSelector(state => state.routes.pinOnMapGeoFences.geoFences);

  const { getAllCoords, allGeoFencesGeoJson, bulkAddGeoFences, emptyGeoFences } = useGeoFenceControllerMapbox();

  // add all the geoFences to the controller
  useEffect(() => {
    const bulkToSet = (geoFenceJsonList || []).map((geoFence: any) => {
      const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
      return {
        ...geoFence,
        geoFenceCoordinates: parsedGeoFenceJson?.geometry?.coordinates,
      };
    });
    bulkToSet.length && bulkAddGeoFences(bulkToSet);
  }, [geoFenceJsonList, bulkAddGeoFences]);

  useEffect(
    () => () => {
      dispatch(resetPinOnMapGeoFences());
    },
    [dispatch],
  );

  useEffect(() => {
    // enable the Next button when there is a selected address and it's not fetching for a new one
    if (lineAddress && !isFetchingAddress) setIsNextDisabled(false);
  }, [lineAddress, isFetchingAddress]);

  useEffect(() => {
    if (coordinates && coordinates.latitude && coordinates.longitude) {
      if (marker && marker.current) {
        marker.current.setLngLat([coordinates.longitude, coordinates.latitude]);
      } else {
        marker.current = new mapboxgl.Marker({
          draggable: true,
          color: theme.brandPrimary,
        }).setLngLat([coordinates.longitude, coordinates.latitude]);
      }
    }
  }, [coordinates]);

  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];
    if (geoFenceJsonList?.length > 0) {
      points.push(...getAllCoords);
    }
    if (marker && marker.current) {
      points.push({
        latitude: marker.current.getLngLat().lat,
        longitude: marker.current.getLngLat().lng,
      });
    }

    if (points.length > 0) {
      const capZoom = radius ? getMapZoom(radius || 0) : MAP_CITY_ZOOM_IN_BIGGER;
      const bounds = getMapBounds(points, {
        capZoom: shouldBeDraggableByDefault ? MAP_CITY_ZOOM : capZoom,
      });

      !isEqual(viewport, bounds) &&
        setViewport({
          ...viewport,
          ...bounds,
        });
    }
  }, [marker, getAllCoords, geoFenceJsonList?.length, viewport, shouldBeDraggableByDefault, radius]);

  const updatePinAddress = useCallback(
    (latitude: number, longitude: number) => {
      setIsFetchingAddress(true);
      saveGeocode(latitude, longitude)
        .then(loc => {
          const location = {
            ...loc,
            latitude,
            longitude,
          };
          setLineAddress(location.line1);
          setSelectedLocation(location);
          if (setPinLocationOnMap) setPinLocationOnMap(location);
          change('pinnedAddress', location.line1);
          setIsFetchingAddress(false);
        })
        .catch(err => {
          console.error('Reverse geocode failed', err);
          setIsFetchingAddress(false);
        });
    },
    [change, setPinLocationOnMap],
  );

  const onDragEnd = useCallback(() => {
    if (!marker || !marker.current) return;

    const lngLat = marker.current.getLngLat();
    setCoordinates({ latitude: lngLat.lat, longitude: lngLat.lng });
    updatePinAddress(lngLat.lat, lngLat.lng);
    !dragPan && setDragPan(true);
  }, [dragPan, updatePinAddress]);

  const setEmptyValue = () => {
    marker.current?.remove();
    marker.current = null;
    setIsNextDisabled(true);
  };

  const handleEnteredAddress = (address: any) => {
    // do nothing if address is empty
    if (!address) return;

    const { latitude, longitude } = address;

    setSelectedLocation(address);
    setCoordinates({ latitude, longitude });
    setIsNextDisabled(false);

    if (marker && marker.current) {
      marker.current.setLngLat([longitude, latitude]);
    } else {
      marker.current = new mapboxgl.Marker({
        draggable: true,
        color: theme.brandPrimary,
      }).setLngLat([longitude, latitude]);
    }

    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: MAP_CITY_ZOOM_IN,
    });

    if (setPinLocationOnMap) setPinLocationOnMap(address);
  };

  const setPinOnMap = useCallback(
    (clickedAddress: any) => {
      let latitude;
      let longitude;

      if (clickedAddress && clickedAddress.lngLat) {
        latitude = clickedAddress.lngLat.lat;
        longitude = clickedAddress.lngLat.lng;
        setCoordinates({ latitude, longitude });
        updatePinAddress(latitude, longitude);
      }
    },
    [updatePinAddress],
  );

  // prevent default of enter
  const handleKeyPress = (e: any) => e.key === 'Enter' && e.preventDefault();

  const handleProximitySearchIconClick = () => {
    change('pinnedAddress', null);
    if (handleProximitySearchClickEvent) handleProximitySearchClickEvent();
  };

  const handleSubmitGeoFenceFilters = (formData: OnMapFiltersFormValues) => {
    const normalizedGeoFencesIds = normalizeFilterArray(formData.geoFenceSubFilters);
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);

    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      dispatch(
        loadPinOnMapGeoFences({
          vendorId,
          geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
          limit: 200,
          geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
        }),
      );
    } else {
      emptyGeoFences();
    }
    setIsMapFiltersOpen(false);
  };

  useEffect(() => {
    if (!geoFenceJsonList?.length) {
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      let shouldFitBounds = false;
      geoFenceJsonList.forEach(({ id, geoFenceJson, geoFenceName }: GeoFence) => {});

      mapRef && shouldFitBounds && mapRef.fitBounds(bounds);
    }
  }, [geoFenceJsonList, mapRef]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: mapRef?.getCenter(),
      mapZoom: mapRef?.getZoom(),
    });
  };

  useEffect(() => {
    if (marker && marker.current) {
      marker.current.on('dragend', onDragEnd);
    }

    return () => {
      if (marker && marker.current) {
        marker.current.off('dragend', onDragEnd);
      }
    };
  }, [onDragEnd]);

  useEffect(() => {
    if (marker.current && mapRef) {
      marker.current.remove();
      marker.current.addTo(mapRef);

      const markerElement = marker.current.getElement();
      markerElement.style.cursor = 'default';

      markerElement.addEventListener('mouseover', () => {
        markerElement.style.cursor = 'move';
      });

      markerElement.addEventListener('mousedown', () => {
        markerElement.style.cursor = 'move';
        setDragPan(false);
      });

      markerElement.addEventListener('mouseup', () => {
        markerElement.style.cursor = 'default';
        !dragPan && setDragPan(true);
      });
    }
  }, [dragPan, mapRef, marker, setPinOnMap]);

  useEffect(() => {
    if (mapRef) {
      mapRef.once('load', () => {
        //count for how long is the mouse down on the map
        let mouseDownTime = 0;
        let mouseDownInterval: any = null;

        const handleMouseDown = () => {
          mouseDownTime = 0;
          mouseDownInterval = setInterval(() => {
            mouseDownTime += 10;
          }, 10);
        };

        const handleMouseUp = (e: any) => {
          clearInterval(mouseDownInterval);
          if (mouseDownTime < 200) setPinOnMap(e);
        };

        mapRef.on('mousedown', handleMouseDown);
        mapRef.on('mouseup', handleMouseUp);
      });
    }
  }, [mapRef, setPinOnMap]);

  return (
    <>
      <PinOnMapWrapper sourceIsProximity={sourceIsProximity}>
        <Grid multiLine>
          {!sourceIsProximity && (
            <GridColumn size="1/12">
              <BackButton onClick={handleBackClick} />
            </GridColumn>
          )}

          <GridColumn size="11/12" padding={sourceIsProximity ? 'no small no no' : 'no xSmall'}>
            <Field
              component={LocationPicker}
              isLoading={isFetchingAddress}
              name="pinnedAddress"
              onChange={handleEnteredAddress}
              onKeyPress={handleKeyPress}
              setEmptyValue={setEmptyValue}
            />
          </GridColumn>

          {sourceIsProximity && (
            <GridColumn size="1/12" align="right" padding={sourceIsProximity ? 'no' : 'no xSmall'}>
              <SelectOnMap onClick={handleProximitySearchIconClick}>
                <ActionButtonTooltip icon="search" tooltip="searchCustomerLocations" size="medium" />
              </SelectOnMap>
            </GridColumn>
          )}
        </Grid>
      </PinOnMapWrapper>

      <PinOnMapContainer modalHasTitle={modalHasTitle} sourceIsProximity={sourceIsProximity}>
        <MapGLWrapper>
          <MapGL
            key="pin-on-map"
            disableDefaultSatelliteView
            enableNewSatelliteView
            disableDefaultNavigationControl
            enableNewNavigationControl
            onMapRefLoaded={setMapRef}
            viewport={viewport}
            dragPan={dragPan}
            setIsSatelliteViewEnabled={handleMapViewStyleChange}
          >
            {!shouldHideMapFilters && (
              <>
                <ComplexMapControl position="top-right" vertical>
                  <TooltipIconButton
                    tooltip="filters"
                    tooltipPosition="left"
                    tooltipColor="grayDarker"
                    color="secondary"
                    margin="no"
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation();
                      setIsMapFiltersOpen(true);
                    }}
                  >
                    <IconButtonIcon icon="filter" color="primary" />
                  </TooltipIconButton>
                </ComplexMapControl>
              </>
            )}
            {mapRef && (
              <RouteMapGeoFencesGL
                map={mapRef}
                geoFencesGeoJSON={allGeoFencesGeoJson || []}
                isSatellite={mapViewStyle.isSatelliteEnabled}
              />
            )}

            {coordinates && coordinates.latitude && coordinates.longitude && radius && (
              <CustomerProximityMapGLSource pinLocation={coordinates} radius={radius} />
            )}
          </MapGL>

          <OnMapFiltersForm
            isOnMapFiltersFormValuesOpen={isMapFiltersOpen}
            closeRouteMapFilters={() => setIsMapFiltersOpen(false)}
            initialValues={{ geoFencesTypesFilters: [], geoFenceSubFilters: [], geoFenceSearchTerm: '' }}
            onSubmit={handleSubmitGeoFenceFilters}
            isOnRightSide
          />
        </MapGLWrapper>
      </PinOnMapContainer>

      {!sourceIsProximity && (
        <GridColumn size="12/12">
          <ButtonSet margin="large auto no">
            <Button
              onClick={() => !!selectedLocation && handlePinSelection(selectedLocation)}
              disabled={isNextDisabled}
              color="primary"
            >
              {translate(submitButtonTranslationKey)}
            </Button>
          </ButtonSet>
        </GridColumn>
      )}
    </>
  );
};

PinOnMapMapbox.defaultProps = {
  handleProximitySearchClickEvent: () => {},
  setPinLocationOnMap: () => {},
  sourceIsProximity: false,
  submitButtonTranslationKey: 'common.next',
};

const mapDispatchToProps = {
  change,
};

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    enableReinitialize: true,
    form: 'PinOnMapForm',
  })(PinOnMapMapbox),
);
