import React, { ChangeEvent } from 'react';

import { connect } from 'react-redux';
import { Fragment, PureComponent } from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import { Button, ButtonSet, Container, TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { DateTimeDisplay, InlineEditor, TextArea, TextDisplay, ActionButtonTooltip } from '../../../core/components';
import { deleteVehicleNote, saveVehicleNote, loadVehicleNotes } from 'src/fleet/ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { VehicleNotes } from 'src/fleet/interfaces/VehicleNotes';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';

const validateVehicleNote = ({ note }: VehicleNotes) => !!note;

interface Props extends WrappedFieldArrayProps {
  change: any;
  deleteVehicleNote: DuckFunction<typeof deleteVehicleNote>;
  fieldArrayKey: string;
  fleetMaintenanceConfigIsOptional: boolean;
  index: number;
  isPendingOrClosingProcess: boolean;
  loadVehicleNotes: DuckFunction<typeof loadVehicleNotes>;
  newIssueStatus: number;
  saveVehicleNote: DuckFunction<typeof saveVehicleNote>;
  tripInspectionDetailId: number;
  updateVehicleIssue: (status: number, vehicleNote?: string) => void;
  vehicleId: number;
}

interface State {
  vehicleNote: any;
  isInEditMode: boolean;
}

class VehicleNotesFormTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { index, fields } = props;
    const vehicleNote = fields.get(index);

    this.state = { vehicleNote, isInEditMode: !vehicleNote.id };
  }

  startEditing = () => {
    this.setState({ isInEditMode: true });
  };

  cancelEditing = () => {
    const { index, fields, change } = this.props;
    const { vehicleNote } = this.state;

    const isExisting = !!fields.get(index).id;

    if (!isExisting) {
      fields.remove(index);
      return;
    }

    change(`vehicleNotes[${index}].note`, vehicleNote.note);
    this.setState({ isInEditMode: false });
  };

  completeEditing = (event: ChangeEvent, fleetMaintenanceConfigIsOptional?: boolean) => {
    const {
      fields,
      index,
      isPendingOrClosingProcess,
      newIssueStatus,
      saveVehicleNote,
      tripInspectionDetailId,
      updateVehicleIssue,
      vehicleId,
      loadVehicleNotes,
    } = this.props;
    const vehicleNote = fields.get(index);

    if ((!fleetMaintenanceConfigIsOptional || !isPendingOrClosingProcess) && !validateVehicleNote(vehicleNote)) {
      createErrorNotification(translate('routes.alertMessages.routeNoteValidationFailed'));
      return;
    }

    this.setState({ vehicleNote, isInEditMode: false });

    if (isPendingOrClosingProcess) {
      updateVehicleIssue(newIssueStatus, vehicleNote.note);
    } else {
      if (validateVehicleNote(vehicleNote)) {
        saveVehicleNote(vehicleId, tripInspectionDetailId, vehicleNote.note, newIssueStatus, vehicleNote.id)
          .then(() => {
            loadVehicleNotes(vehicleId, tripInspectionDetailId).then(() => {
              createSuccessNotification(translate('vehicles.alertMessages.vehicleNoteSaved'));
              this.setState({ vehicleNote, isInEditMode: false });
            });
          })
          .catch(() => {
            createErrorNotification(translate('vehicles.alertMessages.vehicleNoteSaveError'));
          });
      }
    }
  };

  deleteVehicleNote = async () => {
    const { index, fields, deleteVehicleNote, vehicleId } = this.props;
    const vehicleNote = fields.get(index);

    if (!(await confirm(translate('vehicles.alertMessages.confirmDeleteVehicleNote')))) {
      return;
    }

    deleteVehicleNote(vehicleId, vehicleNote.id).then(() => {
      createSuccessNotification(translate('vehicles.alertMessages.vehicleNoteDeleted'));
      fields.remove(index);
    });
  };

  render() {
    const { fieldArrayKey, fleetMaintenanceConfigIsOptional, isPendingOrClosingProcess } = this.props;
    const { isInEditMode } = this.state;

    return (
      <Fragment>
        <TableRow isHighlighted={isInEditMode}>
          <TableCell width="27%">
            <Container>
              <Field
                name={`${fieldArrayKey}.note`}
                component={InlineEditor}
                isInEditMode={isInEditMode}
                inputComponent={TextArea}
                inputProps={{
                  placeholder: translate('common.note'),
                  margin: 'no',
                  id: `${fieldArrayKey}-note`,
                }}
              />
            </Container>
          </TableCell>

          <TableCell width="15%">
            <Field name={`${fieldArrayKey}.modifiedBy`} component={TextDisplay} block margin="no no xxSmall" />
          </TableCell>

          <TableCell width="18%" vertical justifyContent="center">
            <Field name={`${fieldArrayKey}.modifiedOn`} component={DateTimeDisplay} />
          </TableCell>

          <TableCell width="15%">
            <Field
              name={
                isPendingOrClosingProcess
                  ? translate(`${fieldArrayKey}.status`)
                  : translate(`${fieldArrayKey}.tripInspectionIssueStatusType.technicalName`)
              }
              component={TextDisplay}
            />
          </TableCell>

          <TableCell width="25%" align="right">
            {!isInEditMode && (
              <Fragment>
                <TableActionButton onClick={this.startEditing}>
                  <ActionButtonTooltip icon="edit" tooltip="edit" />
                </TableActionButton>

                <TableActionButton onClick={this.deleteVehicleNote}>
                  <ActionButtonTooltip icon="delete" tooltip="delete" />
                </TableActionButton>
              </Fragment>
            )}

            {isInEditMode && (
              <ButtonSet vertical margin="no" flexFlow="wrap" align="right">
                {!isPendingOrClosingProcess && (
                  <Button
                    type="button"
                    size="small"
                    color="secondary"
                    margin="no xxSmall no no"
                    onClick={this.cancelEditing}
                    id={`${fieldArrayKey}-cancel`}
                  >
                    {translate('common.cancel')}
                  </Button>
                )}

                {fleetMaintenanceConfigIsOptional && isPendingOrClosingProcess && (
                  <ActionButtonTooltip
                    tooltipAsString
                    margin="xSmall xSmall no no"
                    icon="info"
                    color="grayDarker"
                    iconSize="sMedium"
                    tooltip={translate('vehicles.noteIsNotRequired')}
                  />
                )}

                <Button
                  type="button"
                  size="small"
                  color="primary"
                  onClick={(event: ChangeEvent) => this.completeEditing(event, fleetMaintenanceConfigIsOptional)}
                  id={`${fieldArrayKey}-save`}
                >
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            )}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

const mapDispatchToProps = { deleteVehicleNote, saveVehicleNote, loadVehicleNotes };

export default connect(undefined, mapDispatchToProps)(VehicleNotesFormTableRow);
