import * as signalR from '@microsoft/signalr';
import { http } from 'src/core/services/http';

import { getCommunicationApiUrl } from 'src/core/services/environment';
import { Message } from '../interfaces/Message';
import { MessageReceipt } from '../interfaces/MessageReceipt';
import { MessageThread } from '../interfaces/MessageThread';
import { SentMessage } from '../interfaces/SentMessage';
import { transformMessageThreads } from './transformMessageThreads';
import { getAuthToken } from 'src/account/services/auth';

export const getMessageReadReceipts = (messageId: number) => {};

export const getMessageThreads = (vendorId: number) =>
  http
    .get<MessageThread[]>('messages/threads', { params: { vendorId } })
    .then(d => transformMessageThreads(d.data || []));

export const getThreadMessages = (vendorId: number, threadId: string) =>
  http
    .get<Message[]>('messages/threadMessages', {
      params: {
        vendorId,
        threadId,
      },
    })
    .then(d => d.data || []);

let signalRConnection: signalR.HubConnection;

const startConnection = () => {
  if (signalRConnection) {
    signalRConnection.start().catch(() => {
      setTimeout(() => startConnection(), 5000);
    });
  }
};

export const connectToMessagesWebsocket = (
  userId: string,
  messageHandler: (message: Message) => void,
  readReceiptHandler: (readReceipt: MessageReceipt) => void,
  messageSentHandler: (message: Message) => void,
  messageReadHandler: (readReceipt: MessageReceipt) => void,
) => {
  if (signalRConnection) {
    signalRConnection.start();
  } else {
    signalRConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Trace)
      .withUrl(`${getCommunicationApiUrl()}/hubs/messagesHub?userId=${userId}`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: getAuthToken,
      })
      .withAutomaticReconnect()
      .build();
    signalRConnection.on('NewMessage', messageHandler);
    signalRConnection.on('ReadReceipt', readReceiptHandler);
    signalRConnection.on('MessageSent', messageSentHandler);
    signalRConnection.on('MessageRead', messageReadHandler);
    startConnection();
  }
};

export const disconnectMessagingWebsocket = () => {
  if (signalRConnection) {
    signalRConnection.stop();
  }
};

export const postMessage = (message: SentMessage) => http.post<Message>('messages/send', message).then(d => d.data);

export const postReadMessage = (messageId: number, sessionId: string) =>
  http.post('messages/read', { messageId, sessionId }).then(d => d.data);
