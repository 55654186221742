import React from 'react';

import { useSelector } from 'src/core/hooks/useSelector';
import {
  RouteTemplateBuilderDataContainer,
  RouteTemplateBuilderMapInfoOverlay,
} from '../../../styled/RouteTemplateBuilderMap';
import { RouteTemplateBuilderMapGL } from '../routeTemplateBuilderMap/RouteTemplateBuilderMapGL';
import translate from 'src/core/services/translate';
import RouteTemplateBuilderWorkSessionListView from './RouteTemplateBuilderWorkSessionListView';
import { WorkSessionViews } from 'src/routes/constants/routeTemplateBuilder';
import { MapFilters } from 'src/routes/ducks/routeTemplateBuilder';

interface Props {
  currentFilters?: MapFilters;
  needsRefresh: boolean;
}

export default function RouteTemplateBuilderWorkSessionDataContainer({ currentFilters, needsRefresh }: Props) {
  const filters = useSelector(state => state.routes.routeTemplateBuilder.filters);
  const layout = useSelector(state => state.routes.routeTemplateBuilder.layout);
  const selectedDraft = useSelector(state => state.routes.routeTemplateBuilder.selectedDraft);

  return (
    <RouteTemplateBuilderDataContainer scroll={layout === WorkSessionViews.List}>
      <RouteTemplateBuilderMapGL hidden={layout !== WorkSessionViews.Map} currentFilters={currentFilters} />

      {layout === WorkSessionViews.List && <RouteTemplateBuilderWorkSessionListView />}

      {!filters && !selectedDraft && (
        <RouteTemplateBuilderMapInfoOverlay>
          <h4>{translate('routeTemplateBuilder.selectFiltersMapMessage')}</h4>
        </RouteTemplateBuilderMapInfoOverlay>
      )}

      {needsRefresh && (
        <RouteTemplateBuilderMapInfoOverlay>
          <h4>{translate('routeTemplateBuilder.refreshNeeded')}</h4>
        </RouteTemplateBuilderMapInfoOverlay>
      )}
    </RouteTemplateBuilderDataContainer>
  );
}
