import { http } from '../../core/services/http';
import { transformRecurringServices, transformRecurringServicesIssueType } from './transformRecurringServices';

export const loadRecurringServices = (token: string, date: Date | string, page: number, limit: number) =>
  http
    .get(`gus/vendor/recurring/services/${token}`, { params: { date, page, limit } })
    .then(response => transformRecurringServices(response.data));

export const loadRecurringServicesByVendorId = (vendorId: number) =>
  http.get(`gus/vendor/recurring/services/${vendorId}`).then(response => transformRecurringServices(response.data));

export const loadRecurringServicesIssueType = (token: string) =>
  http.get(`gus/vendor/report/lookup/${token}`).then(response => transformRecurringServicesIssueType(response.data));

export const updateRecurringServices = (requestRecurringServicesObj: any, token: string, page: number, limit: number) =>
  http
    .post(`gus/vendor/workOrder/Accept/${token}`, requestRecurringServicesObj, { params: { page, limit } })
    .then(response => transformRecurringServices(response.data));

export const updateRecurringServicesByVendorId = (requestRecurringServicesObj: any, vendorId: number) =>
  http
    .put(`gus/vendor/workOrder/Accept/${vendorId}`, [requestRecurringServicesObj])
    .then(response => transformRecurringServices(response.data));

// Issue Report
export const updateIssueReportRecurringServices = (
  requestRecurringServicesObj: any,
  token: string,
  page: number,
  limit: number,
) =>
  http
    .post(`gus/vendor/workOrder/report/${token}`, requestRecurringServicesObj, { params: { page, limit } })
    .then(response => transformRecurringServices(response.data));

export const updateIssueReportRecurringServicesByVendorId = (requestRecurringServicesObj: any, vendorId: number) =>
  http
    .put(`gus/vendor/workOrder/report/${vendorId}`, requestRecurringServicesObj)
    .then(response => transformRecurringServices(response.data));

// Undo
export const updateUndoReportRecurringServices = (
  requestRecurringServicesObj: any,
  token: string,
  page: number,
  limit: number,
) =>
  http
    .post(`gus/vendor/workOrder/report/undo/${token}`, requestRecurringServicesObj, { params: { page, limit } })
    .then(response => transformRecurringServices(response.data));

export const updateUndoReportRecurringServicesByVendorId = (requestRecurringServicesObj: any, vendorId: number) =>
  http
    .put(`gus/vendor/workOrder/report/undo/${vendorId}`, requestRecurringServicesObj)
    .then(response => transformRecurringServices(response.data));

export const updateUndoAcceptRecurringServices = (
  requestRecurringServicesObj: any,
  token: string,
  page: number,
  limit: number,
) =>
  http
    .post(`gus/vendor/workOrder/Accept/undo/${token}`, requestRecurringServicesObj, { params: { page, limit } })
    .then(response => transformRecurringServices(response.data));

export const updateUndoAcceptRecurringServicesByVendorId = (requestRecurringServicesObj: any, vendorId: number) =>
  http
    .put(`gus/vendor/workOrder/Accept/undo/${vendorId}`, requestRecurringServicesObj)
    .then(response => transformRecurringServices(response.data));

export const updateAcceptSelectedPaymentServices = (
  requestRecurringServicesObj: any,
  token: string,
  page: number,
  limit: number,
) =>
  http
    .post(`gus/vendor/Invoice/process/${token}`, requestRecurringServicesObj, { params: { page, limit } })
    .then(response => transformRecurringServices(response.data));
