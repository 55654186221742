import { arrayMove } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { difference, get, map, size } from 'lodash-es';
import { PureComponent } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { AppState } from '../../../store';
import { Button, Grid, GridColumn, Message, ModalFixedFooter } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import {
  DELIVERY_UTILITY,
  FRONT_LOAD,
  RESIDENTIAL,
  ROLL_OFF,
  SNOW_PLOW,
  STREET_SWEEPER,
  TOTER,
} from '../../../fleet/constants';
import { DownArrow } from 'src/vendors/components/styled';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import {
  handleSortOrderChange,
  loadTripConfigurationForVendor,
  resetTripConfigurationsForVendor,
  saveTripConfigurationsForVendor,
  toggleTripConfigurationIsActive,
  toggleTripConfigurationIsRequired,
  toggleTripConfigurationPicture,
  toggleTripConfigurationPictureNotFlagged,
  toggleTripConfigurationSendEmailNotification,
} from '../../ducks';
import { Modal, SortableTable } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { TripConfiguration, TripInspectionCategories } from '../../interfaces/TripConfiguration';
import { TripConfigurationModalTableRow } from './';
import {
  TRIP_CONFIGURATION_EXTERIOR_ID,
  TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID,
  TRIP_CONFIGURATION_INTERIOR_ID,
} from 'src/vendors/constants/tripInspection';
import { TypedField } from '../../../core/components';
import { VehicleTypeForVendorDropdown } from '../../../fleet/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

const modalId = 'trip-configuration-modal';

interface Props extends ModalProps {
  additionalTripConfiguration: any;
  change: InjectedFormProps['change'];
  handleSortOrderChange: DuckAction<typeof handleSortOrderChange>;
  isLoading: boolean;
  isSaving: boolean;
  loadTripConfigurationForVendor: DuckFunction<typeof loadTripConfigurationForVendor>;
  resetTripConfigurationsForVendor: DuckAction<typeof resetTripConfigurationsForVendor>;
  saveTripConfigurationsForVendor: DuckFunction<typeof saveTripConfigurationsForVendor>;
  toggleTripConfigurationIsActive: DuckAction<typeof toggleTripConfigurationIsActive>;
  toggleTripConfigurationIsRequired: DuckAction<typeof toggleTripConfigurationIsRequired>;
  toggleTripConfigurationPicture: DuckAction<typeof toggleTripConfigurationPicture>;
  toggleTripConfigurationPictureNotFlagged: DuckAction<typeof toggleTripConfigurationPictureNotFlagged>;
  toggleTripConfigurationSendEmailNotification: DuckAction<typeof toggleTripConfigurationSendEmailNotification>;
  tripConfiguration: TripConfiguration;
  tripConfigurationExterior: TripInspectionCategories;
  tripConfigurationInterior: TripInspectionCategories;
  tripConfigurationVehicleSpecific: TripInspectionCategories;
  tripInspectionTypeId: number;
  vendorId: number;
}

interface State {
  exteriorVehicleChecksVisibility: boolean;
  initialValuesLoaded: boolean;
  interiorVehicleChecksVisibility: boolean;
  specificVehicleChecksVisibility: boolean;
  tripConfiguration?: TripConfiguration;
  vehicleTypeId?: number;
}

class TripConfigurationModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      exteriorVehicleChecksVisibility: false,
      initialValuesLoaded: false,
      interiorVehicleChecksVisibility: false,
      specificVehicleChecksVisibility: false,
      tripConfiguration: undefined,
      vehicleTypeId: undefined,
    };
  }

  componentDidUpdate() {
    if (!!size(this.props.tripConfiguration) && !this.state.initialValuesLoaded) {
      this.setState({ initialValuesLoaded: true, tripConfiguration: this.props.tripConfiguration });
    }
    this.props.change('vehicleTypeId', this.state.vehicleTypeId);
  }

  componentWillUnmount() {
    this.props.resetTripConfigurationsForVendor();
  }

  onSortOrderChange = (oldIndex: number, newIndex: number, count: number) => {
    const { handleSortOrderChange, tripConfiguration } = this.props;
    const reorderedTripConfigurations = arrayMove(
      tripConfiguration.tripInspectionCategories[count].tripInspectionSubCategories,
      oldIndex,
      newIndex,
    );
    const tripConfigurations = this.resetOrderNumbers(reorderedTripConfigurations);
    handleSortOrderChange(tripConfigurations, count);
  };

  onSortOrderChangeExterior = (oldIndex: number, newIndex: number) => {
    this.onSortOrderChange(oldIndex, newIndex, TRIP_CONFIGURATION_EXTERIOR_ID);
  };

  onSortOrderChangeVehicleSpecific = (oldIndex: number, newIndex: number) => {
    this.onSortOrderChange(oldIndex, newIndex, TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID);
  };

  onSortOrderChangeInterior = (oldIndex: number, newIndex: number) => {
    this.onSortOrderChange(oldIndex - 1, newIndex - 1, TRIP_CONFIGURATION_INTERIOR_ID);
  };

  onVehicleTypeIdChange = (vehicleTypeId: any) => {
    const { vendorId, tripInspectionTypeId, loadTripConfigurationForVendor } = this.props;
    loadTripConfigurationForVendor(vendorId, tripInspectionTypeId, vehicleTypeId).then(() => {
      this.props.change('vehicleTypeId', vehicleTypeId);
      this.setState({ initialValuesLoaded: false, vehicleTypeId });
    });
  };

  resetOrderNumbers = (tripConfigurations: any) =>
    map(tripConfigurations, (tripConfiguration, index) => ({
      ...tripConfiguration,
      orderNo: index + 1,
    }));

  saveTripConfigurationsForVendor = () => {
    const { vendorId, tripConfiguration, saveTripConfigurationsForVendor, closeModal } = this.props;
    const { tripInspectionType } = tripConfiguration;
    const { vehicleTypeId } = this.state;

    scrollToTopOfModal(modalId);

    saveTripConfigurationsForVendor(vendorId, tripConfiguration, tripInspectionType.id, vehicleTypeId)
      .then(() => {
        createSuccessNotification(translate('vendors.tripInspection.tripConfigurationSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.tripInspection.saveTripConfigurationError')));
  };

  closeModal = () => {
    const { tripConfiguration, closeModal } = this.props;
    const tripConfigurationCategoriesHasChanged = !size(
      difference(
        get(tripConfiguration, 'tripInspectionCategories'),
        get(this.state.tripConfiguration || [], 'tripInspectionCategories'),
      ),
    );
    const additionalTripConfigurationHasChanged =
      get(tripConfiguration, 'additionalTripInspectionItem') ===
      get(this.state.tripConfiguration, 'additionalTripInspectionItem');

    closeModal(tripConfigurationCategoriesHasChanged && additionalTripConfigurationHasChanged);
  };

  toggleArrowInterior = () => {
    const { interiorVehicleChecksVisibility } = this.state;
    return (
      <DownArrow
        role="button"
        orientation={!interiorVehicleChecksVisibility ? 'top' : ''}
        onClick={() => this.handleToggleClick(TRIP_CONFIGURATION_INTERIOR_ID)}
        tabIndex={0}
      >
        ▼
      </DownArrow>
    );
  };

  toggleArrowExterior = () => {
    const { exteriorVehicleChecksVisibility } = this.state;
    return (
      <DownArrow
        onClick={() => this.handleToggleClick(TRIP_CONFIGURATION_EXTERIOR_ID)}
        role="button"
        tabIndex={0}
        orientation={!exteriorVehicleChecksVisibility ? 'top' : ''}
      >
        ▼
      </DownArrow>
    );
  };

  toggleArrowVehicleSpecific = () => {
    const { specificVehicleChecksVisibility } = this.state;
    return (
      <DownArrow
        onClick={() => this.handleToggleClick(TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID)}
        role="button"
        tabIndex={0}
        orientation={!specificVehicleChecksVisibility ? 'top' : ''}
      >
        ▼
      </DownArrow>
    );
  };

  handleToggleClick = (count: number) => {
    const { interiorVehicleChecksVisibility, exteriorVehicleChecksVisibility, specificVehicleChecksVisibility } =
      this.state;

    switch (count) {
      case TRIP_CONFIGURATION_INTERIOR_ID:
        return this.setState({ interiorVehicleChecksVisibility: !interiorVehicleChecksVisibility });
      case TRIP_CONFIGURATION_EXTERIOR_ID:
        return this.setState({ exteriorVehicleChecksVisibility: !exteriorVehicleChecksVisibility });
      case TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID:
        return this.setState({ specificVehicleChecksVisibility: !specificVehicleChecksVisibility });
      default:
        break;
    }
  };

  render() {
    const {
      additionalTripConfiguration,
      featureCode,
      handleSortOrderChange,
      isLoading,
      isSaving,
      toggleTripConfigurationIsActive,
      toggleTripConfigurationIsRequired,
      toggleTripConfigurationSendEmailNotification,
      toggleTripConfigurationPicture,
      toggleTripConfigurationPictureNotFlagged,
      tripConfigurationExterior,
      tripConfigurationInterior,
      tripConfigurationVehicleSpecific,
    } = this.props;

    const { exteriorVehicleChecksVisibility, interiorVehicleChecksVisibility, specificVehicleChecksVisibility } =
      this.state;

    const tableCellNameTripInterior = {
      name: 'technicalName',
      label: translate('vendors.tripInspection.interiorVehicleChecks'),
      width: '32%',
    };
    const tableCellNameTripExterior = {
      name: 'technicalName',
      label: translate('vendors.tripInspection.exteriorVehicleChecks'),
      width: '32%',
    };
    const tableCellNameTripVehicleSpecific = {
      name: 'technicalName',
      label: translate('vendors.tripInspection.vehicleSpecificChecks'),
      width: '32%',
    };
    const tableCellStatus = {
      name: 'status',
      label: translate('vendors.tripInspection.active'),
      width: '5%',
      align: 'left',
      padding: 'defaultCellVertical no',
      noPaddingRight: true,
    };
    const tableCellPicture = {
      name: 'picture',
      label: translate('vendors.tripInspection.pictureIfIssueFlagged'),
      width: '15%',
      align: 'left',
    };
    const tableCellPictureNotFlagged = {
      name: 'pictureNotFlagged',
      label: translate('vendors.tripInspection.pictureIfIssueNotFlagged'),
      width: '15%',
      align: 'left',
    };
    const tableCellRequired = {
      name: 'required',
      label: translate('vendors.tripInspection.mandatoryInspectionItem'),
      width: '14%',
      align: 'left',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
    };
    const tableCellEmailNotifications = {
      name: 'sendEmailNotifications',
      label: translate('vendors.tripInspection.emailNotifications'),
      width: '12%',
      align: 'left',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
    };
    const tableCellToggleInterior = {
      name: 'collapseOrExpand',
      component: this.toggleArrowInterior,
      width: '5%',
      align: 'right',
      padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical no',
    };
    const tableCellToggleExterior = {
      name: 'collapseOrExpand',
      component: this.toggleArrowExterior,
      width: '5%',
      align: 'right',
      padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical no',
    };
    const tableCellToggleVehicleSpecific = {
      name: 'collapseOrExpand',
      component: this.toggleArrowVehicleSpecific,
      width: '5%',
      align: 'right',
      padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical no',
    };

    const tripConfigurationBaseTableCells = [
      tableCellStatus,
      tableCellPicture,
      tableCellPictureNotFlagged,
      tableCellRequired,
      tableCellEmailNotifications,
    ];

    const tripConfigurationInteriorTableCells = [
      tableCellNameTripInterior,
      ...tripConfigurationBaseTableCells,
      tableCellToggleInterior,
    ];

    const tripConfigurationExteriorTableCells = [
      tableCellNameTripExterior,
      ...tripConfigurationBaseTableCells,
      tableCellToggleExterior,
    ];

    const tripConfigurationVehicleSpecificTableCells = [
      tableCellNameTripVehicleSpecific,
      ...tripConfigurationBaseTableCells,
      tableCellToggleVehicleSpecific,
    ];

    const additionalTripConfigurationItem = [
      {
        tripInspectionSubCategory: additionalTripConfiguration
          ? additionalTripConfiguration.additionalTripInspectionItem
          : undefined,
        isActive: additionalTripConfiguration ? additionalTripConfiguration.isActive : undefined,
        isMileage: true,
      },
    ];

    const tripConfigurationInteriorSubCategories = tripConfigurationInterior
      ? tripConfigurationInterior.tripInspectionSubCategories
      : [];

    const tripInspectionSubCategories = [...additionalTripConfigurationItem, ...tripConfigurationInteriorSubCategories];

    const virtualizedProps = (rows: any[]) => {
      return {
        itemSize: TABLE_ROW_HEIGHT_SMALL,
        height: Math.min(rows.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 6) || 1,
      };
    };

    return (
      <Modal
        isLoading={isLoading || isSaving}
        onClose={this.closeModal}
        size="xLarge"
        title={translate(createTranslationKey(featureCode || '', 'vendors.featureCodes'))}
        padding="medium no no"
        minHeight="370px"
        overflow={isLoading || isSaving ? 'hidden' : 'auto'}
        id={modalId}
      >
        <Grid centered>
          <GridColumn size="2/12">
            <TypedField
              component={VehicleTypeForVendorDropdown}
              name="vehicleTypeId"
              onChange={this.onVehicleTypeIdChange}
              props={{
                dropdownProps: {
                  margin: 'xSmall no small no',
                },
                withLabel: true,
                acceptedVehicleTypes: [
                  DELIVERY_UTILITY,
                  FRONT_LOAD,
                  RESIDENTIAL,
                  ROLL_OFF,
                  SNOW_PLOW,
                  STREET_SWEEPER,
                  TOTER,
                ],
              }}
            />
          </GridColumn>
        </Grid>
        {tripConfigurationExterior && !!size(tripConfigurationExterior.tripInspectionSubCategories) && (
          <SortableTable
            cells={tripConfigurationExteriorTableCells}
            rowComponent={TripConfigurationModalTableRow}
            rows={tripConfigurationExterior.tripInspectionSubCategories}
            rowProps={{
              handleSortOrderChange,
              toggleTripConfigurationIsActive,
              toggleTripConfigurationIsRequired,
              toggleTripConfigurationSendEmailNotification,
              toggleTripConfigurationPicture,
              toggleTripConfigurationPictureNotFlagged,
              tripConfigurationId: TRIP_CONFIGURATION_EXTERIOR_ID,
            }}
            sort={this.onSortOrderChangeExterior}
            visibility={!exteriorVehicleChecksVisibility ? 'none' : ''}
            virtualized
            virtualizedProps={virtualizedProps(tripConfigurationExterior.tripInspectionSubCategories)}
          />
        )}
        {tripConfigurationExterior && !size(tripConfigurationExterior.tripInspectionSubCategories) && (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}

        {tripConfigurationVehicleSpecific && !!size(tripConfigurationVehicleSpecific.tripInspectionSubCategories) && (
          <SortableTable
            cells={tripConfigurationVehicleSpecificTableCells}
            rowComponent={TripConfigurationModalTableRow}
            rows={tripConfigurationVehicleSpecific.tripInspectionSubCategories}
            rowProps={{
              handleSortOrderChange,
              toggleTripConfigurationIsActive,
              toggleTripConfigurationIsRequired,
              toggleTripConfigurationSendEmailNotification,
              toggleTripConfigurationPicture,
              toggleTripConfigurationPictureNotFlagged,
              tripConfigurationId: TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID,
            }}
            sort={this.onSortOrderChangeVehicleSpecific}
            visibility={!specificVehicleChecksVisibility ? 'none' : ''}
            virtualized
            virtualizedProps={virtualizedProps(tripConfigurationVehicleSpecific.tripInspectionSubCategories)}
          />
        )}
        {tripConfigurationVehicleSpecific && !size(tripConfigurationVehicleSpecific.tripInspectionSubCategories) && (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}

        {tripConfigurationInterior && !!size(tripInspectionSubCategories) && (
          <SortableTable
            cells={tripConfigurationInteriorTableCells}
            rowComponent={TripConfigurationModalTableRow}
            rows={tripInspectionSubCategories}
            rowProps={{
              handleSortOrderChange,
              toggleTripConfigurationIsActive,
              toggleTripConfigurationIsRequired,
              toggleTripConfigurationSendEmailNotification,
              toggleTripConfigurationPicture,
              toggleTripConfigurationPictureNotFlagged,
              tripConfigurationId: TRIP_CONFIGURATION_INTERIOR_ID,
            }}
            sort={this.onSortOrderChangeInterior}
            visibility={!interiorVehicleChecksVisibility ? 'none' : ''}
            virtualized
            virtualizedProps={virtualizedProps(tripInspectionSubCategories)}
          />
        )}
        {tripConfigurationInterior && !size(tripConfigurationInterior.tripInspectionSubCategories) && (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}

        {(tripConfigurationInterior || tripConfigurationExterior || tripConfigurationVehicleSpecific) && (
          <ModalFixedFooter margin="medium no no" isShadowed>
            <Button type="button" margin="no small" color="secondary" onClick={this.closeModal}>
              {translate('common.cancel')}
            </Button>
            <Button color="primary" onClick={this.saveTripConfigurationsForVendor}>
              {translate('common.save')}
            </Button>
          </ModalFixedFooter>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const tripConfigurationForVendor = state.vendors.tripConfiguration.tripConfigurationForVendor;
  const tripConfigurationExterior = tripConfigurationForVendor?.tripConfigurationExterior;
  const tripConfigurationVehicleSpecific = tripConfigurationForVendor?.tripConfigurationVehicleSpecific;
  const tripConfigurationInterior = tripConfigurationForVendor?.tripConfigurationInterior;

  let initialValuesAsObject;

  if (tripConfigurationForVendor) {
    const initialValuesExterior = map(tripConfigurationExterior.tripInspectionSubCategories, subCategory => ({
      [`${TRIP_CONFIGURATION_EXTERIOR_ID}-${subCategory.tripInspectionSubCategory.technicalName}`]:
        subCategory.canTakePicture,
    }));
    const initialValuesExteriorNotFlagged = map(tripConfigurationExterior.tripInspectionSubCategories, subCategory => ({
      [`${TRIP_CONFIGURATION_EXTERIOR_ID}-${subCategory.tripInspectionSubCategory.technicalName}-notFlagged`]:
        subCategory.canTakePictureIfIssueNotFlagged,
    }));

    const initialValuesVehicleSpecific = map(
      tripConfigurationVehicleSpecific.tripInspectionSubCategories,
      subCategory => ({
        [`${TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID}-${subCategory.tripInspectionSubCategory.technicalName}`]:
          subCategory.canTakePicture,
      }),
    );
    const initialValuesVehicleSpecificNotFlagged = map(
      tripConfigurationVehicleSpecific.tripInspectionSubCategories,
      subCategory => ({
        [`${TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID}-${subCategory.tripInspectionSubCategory.technicalName}-notFlagged`]:
          subCategory.canTakePictureIfIssueNotFlagged,
      }),
    );

    const initialValuesInterior = map(tripConfigurationInterior.tripInspectionSubCategories, subCategory => ({
      [`${TRIP_CONFIGURATION_INTERIOR_ID}-${subCategory.tripInspectionSubCategory.technicalName}`]:
        subCategory.canTakePicture,
    }));
    const initialValuesInteriorNotFlagged = map(tripConfigurationInterior.tripInspectionSubCategories, subCategory => ({
      [`${TRIP_CONFIGURATION_INTERIOR_ID}-${subCategory.tripInspectionSubCategory.technicalName}-notFlagged`]:
        subCategory.canTakePictureIfIssueNotFlagged,
    }));

    const initialValues = [
      ...initialValuesExterior,
      ...initialValuesExteriorNotFlagged,
      ...initialValuesVehicleSpecific,
      ...initialValuesVehicleSpecificNotFlagged,
      ...initialValuesInterior,
      ...initialValuesInteriorNotFlagged,
    ];

    initialValuesAsObject = Object.assign(
      {},
      ...initialValues.map(item => ({ [Object.keys(item).toString()]: parseFloat(Object.values(item).toString()) })),
    );
  }

  return {
    isLoading: state.vendors.tripConfiguration.isLoading,
    isSaving: state.vendors.tripConfiguration.isSaving,
    tripConfiguration: tripConfigurationForVendor,
    tripConfigurationExterior: tripConfigurationForVendor
      ? tripConfigurationForVendor.tripInspectionCategories[TRIP_CONFIGURATION_EXTERIOR_ID]
      : undefined,
    tripConfigurationVehicleSpecific: tripConfigurationForVendor
      ? tripConfigurationForVendor.tripInspectionCategories[TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID]
      : undefined,
    tripConfigurationInterior: tripConfigurationForVendor
      ? tripConfigurationForVendor.tripInspectionCategories[TRIP_CONFIGURATION_INTERIOR_ID]
      : undefined,
    additionalTripConfiguration: tripConfigurationForVendor
      ? tripConfigurationForVendor.additionalTripInspectionItem
      : undefined,
    initialValues: initialValuesAsObject || undefined,
  };
};

const mapDispatchToProps = {
  handleSortOrderChange,
  loadTripConfigurationForVendor,
  resetTripConfigurationsForVendor,
  saveTripConfigurationsForVendor,
  toggleTripConfigurationIsActive,
  toggleTripConfigurationIsRequired,
  toggleTripConfigurationSendEmailNotification,
  toggleTripConfigurationPicture,
  toggleTripConfigurationPictureNotFlagged,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'tripConfiguration',
    enableReinitialize: true,
  })(TripConfigurationModal),
);
