import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const AllSelectorWrapper = styled.div`
  display: flex;
  align-items: center;

  & label {
    margin: 0;
  }
`;

export const AssignToInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.brandPrimary};
  margin-right: 10px;
  margin-left: 10px;
  font-size: 12px;
  max-width: 100px;
`;

export const Form = styled.form`
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
