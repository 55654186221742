import { change, Field, formValueSelector, InjectedFormProps, isDirty, reduxForm, submit } from 'redux-form';
import { ChangeEvent, Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { debounce, filter, find, includes, isArray, map, size, sortBy, uniqBy } from 'lodash-es';
import moment from 'moment';

import { ACCOUNT_STATUSES, ACTIVE, CLOSED, NEW, ON_HOLD, SUSPENDED } from 'src/common/constants/accountStatuses';
import {
  AccountStatusDropdown,
  EquipmentSizeDropdown,
  EquipmentTypeDropdown,
  PickupFrequencyTypeDropdown,
  WasteTypeDropdown,
} from 'src/common/components';
import { AccountStatusToggle, AccountStatusToggleIcon, ContractCodeIcon, ContractCodeIconPopup } from '../styled';
import {
  ActionButtonTooltip,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  PopoverWrapper,
  Switch,
  TextArea,
  TypedField,
  UpdateTrackerRouteSwitch,
} from 'src/core/components';
import { AppState } from 'src/store';
import { automaticAccountNumberGenerationSelector, billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import {
  checkRouteIdsHasScheduledDailyRoutes,
  checkRouteIdsHasScheduledDailyRoutesWithService,
} from 'src/routes/services/routeTemplate';
import { Container, ContainerToSave } from 'src/fleet/interfaces/containers';
import { ContainerListItem } from 'src/customers/interfaces/ContainerListItem';
import { CreateContainerToggle } from '../styled/AccountStatus';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { Customer, CustomerLocation, DayOfServices } from 'src/customers/interfaces/Customers';
import { DuckFunction } from 'src/contracts/ducks';
import {
  EVERY_1_MONTH,
  EVERY_OTHER_WEEK,
  EVERY_WEEK,
  EVERY_X_DAYS,
  EVERY_X_WEEKS,
  MONTHLY,
  MONTHLY_ID,
  X_TIMES_PER_WEEK,
} from 'src/common/constants';
import { FieldSet, FormContainer } from 'src/core/components/styled/Form';
import {
  FRIDAY,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TODAY,
  TUESDAY,
  WEDNESDAY,
  WEEKDAYS_BY_ID,
} from 'src/core/constants/weekdays';
import {
  FutureAccountStatusDropdown,
  MonthlyServicesMonthlyFrequencyDropdownOrMultiSelect,
  MonthlyServicesWeeklyFrequencyDropdown,
  MonthlyServicesYearlyFrequencyDropdown,
  ServiceRouteTemplatesMultiSelect,
} from '..';
import { getFutureAccountStatusCanceledMessagge } from 'src/customers/constants/customers';
import { Grid, GridColumn, PanelFooter, PanelSectionSubTitle, Popover, Text } from 'src/core/components/styled';
import { humanizeDate } from 'src/utils/services/formatter';
import { IN_USE } from 'src/common/constants/containerStatusTypes';
import {
  isDateValidValidator,
  isInteger,
  isMultiSelectOrDropdownRequired,
  isNumber,
  isNumberOrZero,
  isRequired,
  maxLength120,
  maxLength250,
  maxLength500,
  maxValueNumeric31,
  maxValueNumeric365,
  maxValueNumeric999,
  minValueNumeric0,
  minValueNumeric1,
} from 'src/utils/services/validator';
import { isSmartCitySelector } from 'src/account/ducks';
import { loadContainerList, loadRouteTemplatesByScheduledDays } from 'src/customers/ducks';
import {
  loadMonthlyServicesMonthlyFrequencyTypes,
  loadMonthlyServicesWeeklyFrequencyTypes,
  loadMonthlyServicesYearlyFrequencyTypes,
  technicalNameByPickupFrequencyTypeIdSelector,
} from 'src/common/ducks';
import { loadRouteTemplatesHaveStopsInPendingOptimization } from 'src/routes/ducks/dispatchBoard/dispatchBoardRouteJob';
import {
  MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH,
  MONTHLY_SERVICES_WEEKLY_FRIDAY,
  MONTHLY_SERVICES_WEEKLY_MONDAY,
  MONTHLY_SERVICES_WEEKLY_SATURDAY,
  MONTHLY_SERVICES_WEEKLY_SUNDAY,
  MONTHLY_SERVICES_WEEKLY_THURSDAY,
  MONTHLY_SERVICES_WEEKLY_TUESDAY,
  MONTHLY_SERVICES_WEEKLY_WEDNESDAY,
} from 'src/customers/constants';
import { NEW_SERVICE_ID } from 'src/customers/constants/serviceStatuses';
import { RouteTemplateByScheduledDays } from 'src/customers/ducks/routeTemplatesByScheduledDays';
import { RouteWithStopsInOptimization } from 'src/routes/interfaces/RouteStop';
import { saveContainer } from 'src/fleet/ducks';
import { Service } from 'src/customers/interfaces/Services';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { UNKNOWN_ID, STREET_SWEEPING_ID, STREET_ID, ROLL_OFF_ID } from 'src/common/constants/serviceTypes';
import confirm from 'src/core/services/confirm';
import CreateEditContainerModalResolver from 'src/fleet/components/modal/CreateEditContainerModalResolver';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import SelectContainersModalResolver from 'src/fleet/components/modal/SelectContainersModalResolver';
import serviceDetailsEditorFormInitialValuesSelector from 'src/customers/services/serviceDetailsEditorFormInitialValuesSelector';
import TransferContainerModalResolver from '../modals/TransferContainerModalResolver';
import translate from 'src/core/services/translate';

export const SERVICE_DETAILS_EDITOR_FORM = 'serviceDetailsEditorForm';

const formSelector = formValueSelector(SERVICE_DETAILS_EDITOR_FORM);

const dayOfWeekOptions = map(WEEKDAYS_BY_ID);
const dayOfWeekByMonthlyServicesWeeklyFrequency = (monthlyServicesWeeklyFrequencyId?: number) => {
  switch (monthlyServicesWeeklyFrequencyId) {
    case MONTHLY_SERVICES_WEEKLY_SUNDAY:
      return WEEKDAYS_BY_ID[SUNDAY];
    case MONTHLY_SERVICES_WEEKLY_MONDAY:
      return WEEKDAYS_BY_ID[MONDAY];
    case MONTHLY_SERVICES_WEEKLY_TUESDAY:
      return WEEKDAYS_BY_ID[TUESDAY];
    case MONTHLY_SERVICES_WEEKLY_WEDNESDAY:
      return WEEKDAYS_BY_ID[WEDNESDAY];
    case MONTHLY_SERVICES_WEEKLY_THURSDAY:
      return WEEKDAYS_BY_ID[THURSDAY];
    case MONTHLY_SERVICES_WEEKLY_FRIDAY:
      return WEEKDAYS_BY_ID[FRIDAY];
    case MONTHLY_SERVICES_WEEKLY_SATURDAY:
      return WEEKDAYS_BY_ID[SATURDAY];
    default:
      return undefined;
  }
};

interface FormValues extends Service {
  facilityTypeId: number;
  binNumberToSend: any[];
}

interface OwnProps {
  isReadonly?: boolean;
  isEditMode?: boolean;
  newCustomerLocation?: CustomerLocation;
  onConfirmServiceTypeChange: () => Promise<boolean>;
  onServiceTypeChanged: (serviceType: number) => void;
  serviceContractCode?: string;
  serviceContractDetails?: any;
  serviceId?: number;
  hasUpdateContract?: boolean;
  isBillingFeatureActive?: boolean;
  renderLocationMap: () => JSX.Element;
}

interface MappedStateProps {
  binNumberInitialValue: number[] | number;
  containerList: ContainerListItem[];
  currentFormValues: {
    serviceTypeId?: number;
    pickupFrequencyTypeId?: number;
    equipmentTypeId?: number;
    equipmentSizeId?: number;
    dayOfServices?: DayOfServices[];
    notifyServiceConfirmation?: boolean;
    monthlyServicesWeeklyFrequencyId?: number;
    binNumber?: any;
    numberOfContainers?: number;
  };
  currentServiceContainers: ContainerListItem[] | any[];
  customer?: Customer;
  customerLocation?: CustomerLocation;
  equipmentConditions: any[];
  equipmentTypes: TechnicalType[];
  futureAccountStatusFromDate?: string;
  futureAccountStatusId: number;
  futureAccountStatusToDate?: string;
  isAutomaticAccountNumberGenerationFeatureActive?: boolean;
  isContainerManagementFeatureEnabled: boolean;
  isEsriRecord?: boolean;
  isFormDirty?: boolean;
  isSmartCity?: boolean;
  locationServiceTypes: any[];
  monthlyServicesMonthlyFrequencyIds?: number[];
  pickupFrequencyTypeName?: string;
  routeTemplatesByScheduledDays: RouteTemplateByScheduledDays[];
  serviceContractAccountStatusTypeId?: number;
  serviceEffectiveDate?: string;
  serviceExpirationDate?: string;
  serviceLocationId?: number;
  vehicleTypeId?: number;
  vendorContainers: any;
  vendorId: number;
}

interface MappedDispatchProps {
  change: any;
  loadContainerList: DuckFunction<typeof loadContainerList>;
  loadMonthlyServicesMonthlyFrequencyTypes: DuckFunction<typeof loadMonthlyServicesMonthlyFrequencyTypes>;
  loadMonthlyServicesWeeklyFrequencyTypes: DuckFunction<typeof loadMonthlyServicesWeeklyFrequencyTypes>;
  loadMonthlyServicesYearlyFrequencyTypes: DuckFunction<typeof loadMonthlyServicesYearlyFrequencyTypes>;
  loadRouteTemplatesByScheduledDays: DuckFunction<typeof loadRouteTemplatesByScheduledDays>;
  loadRouteTemplatesHaveStopsInPendingOptimization: DuckFunction<
    typeof loadRouteTemplatesHaveStopsInPendingOptimization
  >;
  saveContainer: DuckFunction<typeof saveContainer>;
  submit: any;
}

type CombinedProps = OwnProps & MappedStateProps & MappedDispatchProps;

type Props = CombinedProps & InjectedFormProps<FormValues, CombinedProps>;

interface State {
  accountStatusChanged: boolean;
  binNumberToSend: any[];
  changedInput?: string;
  containerListFormatted: ContainerListItem[];
  containerValue: any;
  currentBinNumberValue: any;
  currentValues?: any;
  hasScheduledDailyRoutes: boolean;
  isCreateContainerModalOpen: boolean;
  isModalOpen: boolean;
  isSelectContainersModalOpen: boolean;
  isUpdateAccountStatusSectionOpen: boolean;
  modalMessage: string;
  monthlyServicesWeeklyFrequencyDayOfServiceDay?: {
    id: number;
    shortCode: string;
    name: any;
    shortCodeName: any;
  };
  monthlyServicesWeeklyFrequencyDayOfServiceIndex: number;
  prevValues?: any;
  selectedContainer: ContainerListItem;
  selectedRouteTemplateIds: any[];
  selectedRouteTemplateIdsAreSet: boolean;
  selectedRouteTemplateIdsOnLoad: any[];
}

class ServiceDetailsEditorForm extends Component<Props, State> {
  readonly state: State = {
    accountStatusChanged: false,
    binNumberToSend: [],
    changedInput: '',
    containerListFormatted: this.props.containerList.map(container => ({
      ...container,
      isChecked: isArray(this.props.binNumberInitialValue)
        ? this.props.binNumberInitialValue.includes(container.id)
        : false,
    })),
    containerValue:
      typeof this.props.initialValues.binNumber === 'string'
        ? this.props.initialValues.binNumber
        : ((this.props.initialValues.binNumber as unknown as number[]) || [])?.map(
            (el: number) => find(this.props.containerList, { id: el })?.containerNumber,
          ),
    currentBinNumberValue:
      typeof this.props.initialValues.binNumber === 'string'
        ? this.props.initialValues.binNumber
        : ((this.props.initialValues.binNumber as unknown as number[]) || [])?.map(
            (el: number) => find(this.props.containerList, { id: el })?.id,
          ),
    hasScheduledDailyRoutes: false,
    isCreateContainerModalOpen: false,
    isModalOpen: false,
    isSelectContainersModalOpen: false,
    isUpdateAccountStatusSectionOpen: false,
    modalMessage: '',
    monthlyServicesWeeklyFrequencyDayOfServiceIndex: -1,
    selectedContainer: {} as ContainerListItem,
    selectedRouteTemplateIds: [],
    selectedRouteTemplateIdsAreSet: false,
    selectedRouteTemplateIdsOnLoad: [],
    prevValues: {
      from: '',
      to: '',
      effectiveDate: '',
      expirationDate: '',
    },
    currentValues: {
      from: this.props.futureAccountStatusFromDate,
      to: this.props.futureAccountStatusToDate,
      effectiveDate: this.props.serviceEffectiveDate,
      expirationDate: this.props.serviceExpirationDate,
    },
  };

  componentDidMount() {
    this.onFormValueDayOfServicesChange();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      containerList,
      currentFormValues,
      futureAccountStatusFromDate,
      futureAccountStatusToDate,
      serviceEffectiveDate,
      serviceExpirationDate,
    } = this.props;
    const { selectedRouteTemplateIdsAreSet, changedInput, currentValues, prevValues } = this.state;
    const existEffectiveDates = serviceExpirationDate && serviceEffectiveDate;
    const existsPrevEffectiveDateValues = prevValues.effectiveDate && prevValues.expirationDate;
    const dayDifference = moment(futureAccountStatusToDate).diff(moment(futureAccountStatusFromDate), 'days') < 0;
    const existsValues = prevValues.to && prevValues.from;

    if (serviceEffectiveDate && !currentValues.effectiveDate) {
      this.setState({
        currentValues: { effectiveDate: serviceEffectiveDate },
      });
    }

    if (
      changedInput === 'expirationDate' &&
      (serviceEffectiveDate || currentValues.effectiveDate) &&
      serviceExpirationDate &&
      moment(serviceExpirationDate).diff(moment(serviceEffectiveDate), 'days') < 0 &&
      moment(prevValues.expirationDate).format('MM/DD/YYYY') === moment(serviceExpirationDate).format('MM/DD/YYYY')
    ) {
      this.setState({
        prevValues: {
          effectiveDate: currentValues.effectiveDate,
          expirationDate: null,
        },
        currentValues: { effectiveDate: currentValues.effectiveDate, expirationDate: null },
      });
      this.updateFormValue('cancellationDate', null);
    }

    if (
      (!currentValues.effectiveDate || currentValues.effectiveDate === 'Invalid date') &&
      currentValues.expirationDate
    ) {
      this.setState({
        prevValues: {
          effectiveDate: currentValues.effectiveDate,
          expirationDate: null,
        },
        currentValues: { effectiveDate: currentValues.effectiveDate, expirationDate: null },
      });
      this.updateFormValue('cancellationDate', null);
    }

    if (
      (prevValues.effectiveDate !== currentValues.effectiveDate ||
        prevValues.expirationDate !== currentValues.expirationDate) &&
      ((existEffectiveDates && existsPrevEffectiveDateValues) || (dayDifference && existsValues))
    ) {
      if (
        changedInput === 'effectiveDate' &&
        prevValues.effectiveDate &&
        moment(currentValues.effectiveDate).diff(moment(currentValues.expirationDate), 'days') > 0
      ) {
        this.setState({
          prevValues: {
            effectiveDate: currentValues.effectiveDate,
            expirationDate: null,
          },
          currentValues: { effectiveDate: currentValues.effectiveDate, expirationDate: null },
        });
        this.updateFormValue('cancellationDate', null);
      } else if (
        changedInput === 'expirationDate' &&
        prevValues.expirationDate &&
        moment(serviceExpirationDate).diff(moment(serviceEffectiveDate), 'days') < 0
      ) {
        this.setState({
          prevValues: {
            effectiveDate: prevValues.effectiveDate,
            expirationDate: prevValues.expirationDate,
          },
          currentValues: { effectiveDate: currentValues.effectiveDate, expirationDate: prevValues.expirationDate },
        });

        this.updateFormValue('cancellationDate', moment(prevValues.expirationDate));
      } else if (changedInput === 'to' && prevValues.to) {
        this.updateFormValue('futureAccountStatus.toDate', moment(prevValues.to));
      } else if (changedInput === 'from' && prevValues.from) {
        this.updateFormValue('futureAccountStatus.fromDate', moment(prevValues.from));
      }
    }

    if (
      !prevProps.currentFormValues.monthlyServicesWeeklyFrequencyId &&
      currentFormValues.monthlyServicesWeeklyFrequencyId
    ) {
      this.onFormValueMonthlyServicesWeeklyFrequencyIdChange(currentFormValues.monthlyServicesWeeklyFrequencyId);
    }

    if (
      currentFormValues.pickupFrequencyTypeId === MONTHLY_ID &&
      prevProps.currentFormValues.pickupFrequencyTypeId !== currentFormValues.pickupFrequencyTypeId
    ) {
      const {
        loadMonthlyServicesYearlyFrequencyTypes,
        loadMonthlyServicesMonthlyFrequencyTypes,
        loadMonthlyServicesWeeklyFrequencyTypes,
      } = this.props;

      loadMonthlyServicesYearlyFrequencyTypes();
      loadMonthlyServicesMonthlyFrequencyTypes();
      loadMonthlyServicesWeeklyFrequencyTypes();
    }

    if (
      !selectedRouteTemplateIdsAreSet &&
      prevProps.currentFormValues.dayOfServices !== currentFormValues.dayOfServices
    ) {
      this.setState({
        selectedRouteTemplateIdsOnLoad: this.getSelectedRouteTemplateIds(),
        selectedRouteTemplateIdsAreSet: true,
      });
    }

    if (
      prevProps.currentFormValues.binNumber &&
      prevProps.currentFormValues.binNumber !== currentFormValues.binNumber
    ) {
      const containerListString = containerList
        .filter(container => currentFormValues.binNumber?.includes(container.id))
        .map(container => container.containerNumber)
        .join(', ');
      this.updateFormValue('binNumberString', containerListString);
    }
  }

  onChangeServiceTypeId = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    const { loadRouteTemplatesByScheduledDays, onServiceTypeChanged, vendorId } = this.props;

    onServiceTypeChanged(value);
    loadRouteTemplatesByScheduledDays(vendorId, value);

    let allRouteVehicleTypes = [] as any[];
    loadRouteTemplatesByScheduledDays(vendorId, value).then(res => allRouteVehicleTypes.push(...res));

    const resetEquipment = true;
    this.resetServiceTypeRelatedFields(resetEquipment);
  };

  onChangeEquipmentTypeId = () => {
    this.updateFormValue('equipmentSizeId', null);
  };

  onChangePickupFrequencyType = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.resetServiceTypeRelatedFields();

    if (value === MONTHLY_ID) {
      const { loadMonthlyServicesYearlyFrequencyTypes, loadMonthlyServicesMonthlyFrequencyTypes } = this.props;
      loadMonthlyServicesYearlyFrequencyTypes();
      loadMonthlyServicesMonthlyFrequencyTypes();
    } else {
      this.clearMonthlyFrequencyFields();
      this.updateFormValue('monthlyServicesYearlyFrequencyId', EVERY_1_MONTH);
    }
    this.updateFormValue('positionTypeId', undefined);
  };

  onChangePickupEveryMonthId = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    if (!value) {
      this.clearMonthlyFrequencyFields();
    }
  };

  onChangeDayOfMonth = (event: ChangeEvent<HTMLInputElement>, value: number[]) => {
    const { loadMonthlyServicesWeeklyFrequencyTypes } = this.props;
    const isDayOfMonthSelected = includes(value, MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH);

    if (!isDayOfMonthSelected) loadMonthlyServicesWeeklyFrequencyTypes();
    this.updateFormValue('dayOfService', null);
    this.updateFormValue('monthlyServicesWeeklyFrequencyId', null);
  };

  onFormValueMonthlyServicesWeeklyFrequencyIdChange = (value: number) => {
    const { change, currentFormValues } = this.props;
    const day = dayOfWeekByMonthlyServicesWeeklyFrequency(value);

    this.setState({
      monthlyServicesWeeklyFrequencyDayOfServiceDay: day,
    });

    if (currentFormValues.dayOfServices) {
      map(currentFormValues.dayOfServices, (dayOfService, index) => {
        if (day) {
          if (dayOfService.id === day.id) {
            change(`dayOfServices[${index}].checked`, true);
            this.setState({
              monthlyServicesWeeklyFrequencyDayOfServiceIndex: index,
            });
          } else {
            change(`dayOfServices[${index}].checked`, false);
          }
        }

        change(`dayOfServices[${index}].routeTemplateIds`, undefined);
      });
    }
  };

  onChangeMonthlyServicesWeeklyFrequencyId = (event: ChangeEvent<HTMLInputElement>, value: number) =>
    this.onFormValueMonthlyServicesWeeklyFrequencyIdChange(value);

  getSelectedRouteTemplateIds = (shouldGetName?: boolean) => {
    const { currentFormValues, routeTemplatesByScheduledDays } = this.props;
    const selectedRouteTemplateIds: number[] = [];
    const selectedRouteTemplateNames: any[] = [];

    if (currentFormValues.dayOfServices) {
      currentFormValues.dayOfServices.forEach(dayOfService => {
        if (dayOfService.routeTemplateIds && dayOfService.routeTemplateIds.length) {
          dayOfService.routeTemplateIds.forEach((routeTemplate: number) => {
            selectedRouteTemplateIds.push(Number(routeTemplate));
            selectedRouteTemplateNames.push({
              id: dayOfService.id,
              routeTemplateId: Number(routeTemplate),
              routeTemplateName: (routeTemplatesByScheduledDays as RouteTemplateByScheduledDays[]).find(
                routeTemplateByScheduledDays => routeTemplateByScheduledDays.value === Number(routeTemplate),
              )?.label,
              selectedVehicleTypeID: (routeTemplatesByScheduledDays as RouteTemplateByScheduledDays[]).find(
                routeTemplateByScheduledDays => routeTemplateByScheduledDays.value === Number(routeTemplate),
              )?.vehicleTypeId,
              hasStopsInPendingOptimization: (routeTemplatesByScheduledDays as RouteTemplateByScheduledDays[]).find(
                routeTemplateByScheduledDays => routeTemplateByScheduledDays.value === Number(routeTemplate),
              )?.hasStopsInPendingOptimization,
            });
          });
        }
      });
    }

    return shouldGetName ? selectedRouteTemplateNames : selectedRouteTemplateIds;
  };

  onFormValueDayOfServicesChange = debounce((value?: any) => {
    const { selectedRouteTemplateIdsOnLoad } = this.state;

    const { serviceId, vendorId } = this.props;
    const selectedRouteTemplateIds = this.getSelectedRouteTemplateIds();
    const selectedRouteTemplateNames = this.getSelectedRouteTemplateIds(true);

    this.setState({
      selectedRouteTemplateIds: selectedRouteTemplateNames.filter(
        routeTemplate => !selectedRouteTemplateIdsOnLoad.includes(routeTemplate.routeTemplateId),
      ),
    });

    if (serviceId) {
      checkRouteIdsHasScheduledDailyRoutesWithService(serviceId, selectedRouteTemplateIds, vendorId).then(response => {
        this.setState({ hasScheduledDailyRoutes: response });

        if (!response) this.clearTrackerRouteSwitch();
      });
    } else {
      checkRouteIdsHasScheduledDailyRoutes(selectedRouteTemplateIds, vendorId).then(response => {
        this.setState({ hasScheduledDailyRoutes: response });
        if (!response) this.clearTrackerRouteSwitch();
      });
    }
  }, 500);

  resetServiceTypeRelatedFields = (resetEquipment?: boolean) => {
    const { change, currentFormValues } = this.props;
    change('dayOfService', null);
    if (currentFormValues.dayOfServices) {
      map(currentFormValues.dayOfServices, (_, index) => {
        change(`dayOfServices[${index}].checked`, false);
        change(`dayOfServices[${index}].routeTemplateIds`, null);
      });
    }

    this.onFormValueDayOfServicesChange();

    if (resetEquipment) {
      this.updateFormValue('equipmentTypeId', null);
      this.updateFormValue('equipmentSizeId', null);
      this.updateFormValue('createDeliveryWorkOrder', false);
    }
  };

  clearTrackerRouteSwitch = () => this.props.change('shouldRecreateRoutes', false);

  clearMonthlyFrequencyFields = () => {
    this.updateFormValue('monthlyServicesYearlyFrequencyId', null);
    this.updateFormValue('monthlyServicesMonthlyFrequencyIds', null);
    this.updateFormValue('monthlyServicesWeeklyFrequencyId', null);
    this.updateFormValue('dayOfService', null);
    this.resetServiceTypeRelatedFields();
  };

  resetRouteTemplatesValue = (name: string, value: any) => {
    this.props.change(name, value);
  };

  handleSubmit = async () => {
    const { submit, loadRouteTemplatesHaveStopsInPendingOptimization } = this.props;
    const { selectedRouteTemplateIds } = this.state;

    const currentSelectedIds = selectedRouteTemplateIds.map(
      selectedRouteTemplateId => selectedRouteTemplateId.routeTemplateId,
    );

    const currentRouteTemplates = await loadRouteTemplatesHaveStopsInPendingOptimization(currentSelectedIds.toString());

    const routesWithStopsInPendingOptimization = selectedRouteTemplateIds
      .filter(selectedRouteTemplateId =>
        currentRouteTemplates.find(
          (currentRouteTemplate: RouteWithStopsInOptimization) =>
            currentRouteTemplate.routeTemplateId === selectedRouteTemplateId.routeTemplateId &&
            currentRouteTemplate.hasStopsInPendingOptimization,
        ),
      )
      .map(routeWithStopsInPendingOptimization => routeWithStopsInPendingOptimization.routeTemplateName);

    if (!!routesWithStopsInPendingOptimization.length)
      if (
        !(await confirm(
          translate('routes.alertMessages.routeTemplatesHasStopsInOptimization', {
            selectedRouteTemplates: routesWithStopsInPendingOptimization.toString(),
          }),
          '',
          translate('common.cancel'),
          translate('common.continue'),
        ))
      )
        return;

    submit('serviceDetailsEditor');
  };

  toggle = (toggleContainer?: boolean) => {
    if (toggleContainer) {
      this.setState({ isCreateContainerModalOpen: true });
    } else
      this.setState(prevState => ({
        isUpdateAccountStatusSectionOpen: !prevState.isUpdateAccountStatusSectionOpen,
      }));
  };

  updateFormValue = (name: string, value: any) => {
    this.props.change(name, value);
  };

  renderSelectedRouteTemplateIds = (selectedRouteTemplates: any[], index: number) => {
    const { vehicleTypeId } = this.props;

    return map(
      sortBy(
        selectedRouteTemplates.filter(selectedRouteTemplate => selectedRouteTemplate.id === index + 1),
        'routeTemplateName',
      ),
      selectedRouteTemplate => (
        <Grid key={selectedRouteTemplate.routeTemplateId} margin="xSmall no no no">
          <GridColumn verticalAlign="center" size="6/12" padding="no xSmall no large">
            <Text wordWrap="anywhere">{selectedRouteTemplate.routeTemplateName}</Text>
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              isOptimizedOptionHidden={
                vehicleTypeId === ROLL_OFF_ID || selectedRouteTemplate.selectedVehicleTypeID === ROLL_OFF_ID
              }
              withLabel
              name={`positionTypeId[${selectedRouteTemplate.routeTemplateId}]`}
              component={JobPositionTypeDropdown as any}
              validate={[isRequired]}
              dropdownProps={{ margin: 'no' }}
            />
          </GridColumn>
        </Grid>
      ),
    );
  };

  accountStatusChange = () => {
    const { change, futureAccountStatusId, futureAccountStatusFromDate } = this.props;

    this.setState({ accountStatusChanged: (!!futureAccountStatusId && !!futureAccountStatusFromDate) || false }, () => {
      change('futureAccountStatus.accountStatusId', null);
      change('futureAccountStatus.fromDate', null);
    });
  };

  render() {
    const {
      containerList,
      currentFormValues,
      currentServiceContainers,
      customer,
      customerLocation,
      equipmentConditions,
      futureAccountStatusFromDate,
      futureAccountStatusId,
      futureAccountStatusToDate,
      handleSubmit,
      isAutomaticAccountNumberGenerationFeatureActive,
      isBillingFeatureActive,
      isContainerManagementFeatureEnabled,
      isEditMode,
      isEsriRecord,
      isFormDirty,
      isReadonly,
      isSmartCity,
      loadContainerList,
      locationServiceTypes,
      monthlyServicesMonthlyFrequencyIds,
      newCustomerLocation,
      onConfirmServiceTypeChange,
      pickupFrequencyTypeName,
      renderLocationMap,
      saveContainer,
      serviceContractAccountStatusTypeId,
      serviceContractCode,
      serviceEffectiveDate,
      serviceId,
      serviceLocationId,
      vendorId,
    } = this.props;

    const {
      accountStatusChanged,
      containerListFormatted,
      containerValue,
      currentBinNumberValue,
      currentValues,
      hasScheduledDailyRoutes,
      isCreateContainerModalOpen,
      isModalOpen,
      isSelectContainersModalOpen,
      isUpdateAccountStatusSectionOpen,
      monthlyServicesWeeklyFrequencyDayOfServiceDay,
      monthlyServicesWeeklyFrequencyDayOfServiceIndex,
      selectedContainer,
      selectedRouteTemplateIds,
    } = this.state;

    const customerAccountStatusTypeId = customer ? customer.customerAccountStatusTypeId : undefined;

    const accountStatusTypeId = newCustomerLocation
      ? newCustomerLocation.accountStatusTypeId
      : customerLocation?.accountStatusTypeId;

    const serviceTypeOptionsPre = locationServiceTypes.map(serviceType => ({
      label: serviceType.name,
      value: serviceType.id,
    }));

    const isAllowCreateDeliveryWorkorderVisible =
      locationServiceTypes.find(locationServiceType => locationServiceType.id === currentFormValues.serviceTypeId)
        ?.allowCreateDeliveryWorkorder || !currentFormValues.serviceTypeId;

    const serviceTypeOptions =
      serviceId === NEW_SERVICE_ID
        ? serviceTypeOptionsPre.filter(
            serviceTypeOption =>
              serviceTypeOption.value !== UNKNOWN_ID &&
              serviceTypeOption.value !== STREET_SWEEPING_ID &&
              serviceTypeOption.value !== STREET_ID,
          )
        : serviceTypeOptionsPre;

    const accountStatusMessageFuture =
      futureAccountStatusId && futureAccountStatusFromDate && moment(futureAccountStatusFromDate).toDate() > TODAY
        ? 'customers.futureAccountStatus'
        : undefined;

    const accountStatusMessageCurrent =
      futureAccountStatusFromDate &&
      moment(futureAccountStatusFromDate).toDate() <= TODAY &&
      futureAccountStatusToDate &&
      moment(futureAccountStatusToDate).toDate() >= TODAY
        ? 'customers.currentAccountStatus'
        : undefined;

    const formattedMonthlyServicesMonthlyFrequencyIds =
      typeof monthlyServicesMonthlyFrequencyIds === 'number'
        ? [monthlyServicesMonthlyFrequencyIds]
        : monthlyServicesMonthlyFrequencyIds;

    const isDayOfMonthSelected = includes(formattedMonthlyServicesMonthlyFrequencyIds, 1);
    const isAnyDayOfMonthSelected = size(formattedMonthlyServicesMonthlyFrequencyIds) > 0;
    const isMonthlyServicesWeeklyFrequencyIdAWeekDay =
      currentFormValues.monthlyServicesWeeklyFrequencyId === MONTHLY_SERVICES_WEEKLY_SUNDAY ||
      currentFormValues.monthlyServicesWeeklyFrequencyId === MONTHLY_SERVICES_WEEKLY_MONDAY ||
      currentFormValues.monthlyServicesWeeklyFrequencyId === MONTHLY_SERVICES_WEEKLY_TUESDAY ||
      currentFormValues.monthlyServicesWeeklyFrequencyId === MONTHLY_SERVICES_WEEKLY_WEDNESDAY ||
      currentFormValues.monthlyServicesWeeklyFrequencyId === MONTHLY_SERVICES_WEEKLY_THURSDAY ||
      currentFormValues.monthlyServicesWeeklyFrequencyId === MONTHLY_SERVICES_WEEKLY_FRIDAY ||
      currentFormValues.monthlyServicesWeeklyFrequencyId === MONTHLY_SERVICES_WEEKLY_SATURDAY;

    const containers = currentFormValues.binNumber;

    const isNewServiceContract = serviceId === NEW_SERVICE_ID || !isBillingFeatureActive;

    const addContainer = (containerId: number, containerNumber?: string) => {
      const updatedBinNumber = [...containers, containerId];
      const updatedContainerValue = [
        ...containerValue,
        ...(containerNumber
          ? [containerNumber]
          : containerList.filter((el: ContainerListItem) => el.id === containerId).map(el => el.containerNumber)),
      ];

      this.setState({
        containerValue: updatedContainerValue,
        currentBinNumberValue: updatedBinNumber,
      });
      this.updateFormValue('numberOfContainers', updatedContainerValue.length);
      this.updateFormValue('binNumber', updatedBinNumber);
    };

    const transferContainer = (containerId: number) => {
      const containerToRemove = containerList.filter(el => el.id === containerId).map(el => el.containerNumber)[0];
      const updatedBinNumber = [
        ...containers.filter((el: any) =>
          typeof el === 'number' ? el !== containerId : el !== containerId.toString(),
        ),
      ];

      const updatedContainerValue = [...containerValue.filter((el: any) => el !== containerToRemove)];
      this.updateFormValue('numberOfContainers', updatedContainerValue.length);

      this.setState({
        containerValue: updatedContainerValue,
        currentBinNumberValue: updatedBinNumber,
      });
      this.updateFormValue('binNumber', updatedBinNumber);
    };

    const setIsChecked = (isChecked: boolean, id: number, containerList?: ContainerListItem[]) => {
      const currentContainerList = (containerList || containerListFormatted).map(container =>
        container.id === id ? { ...container, isChecked: isChecked } : { ...container },
      );

      this.setState({
        containerListFormatted: currentContainerList,
      });
    };

    const containerFieldCondition = async (isSelected: boolean, id: number) => {
      const selectedContainer = containerList.filter(
        (container: ContainerListItem) => container.id === id,
      )[0] as ContainerListItem;

      const containerIsUsed = selectedContainer?.statusTypeId === IN_USE;
      const containerMatchesServiceDetails = containerList?.some((container: ContainerListItem) => {
        return (
          container.id === id &&
          container.equipmentTypeId === currentFormValues.equipmentTypeId &&
          container.equipmentSizeId === currentFormValues.equipmentSizeId
        );
      });
      this.setState({
        selectedContainer,
      });

      if (isSelected) {
        setIsChecked(true, id);

        if (!containerMatchesServiceDetails) {
          if (containerIsUsed) {
            if (
              await confirm(translate('customers.alertMessages.selectedContainerDoesntMatcheServiceContractDetails'))
            ) {
              if (await confirm(translate('customers.alertMessages.selectedContainerIsAlreadyAssigned'))) {
                setIsChecked(true, id);
                addContainer(id);
              } else {
                setIsChecked(false, id);
              }
            } else {
              setIsChecked(false, id);
            }
          } else {
            if (
              await confirm(translate('customers.alertMessages.selectedContainerDoesntMatcheServiceContractDetails'))
            ) {
              setIsChecked(true, id);
              addContainer(id);
            } else {
              setIsChecked(false, id);
            }
          }
        } else if (containerMatchesServiceDetails && containerIsUsed) {
          if (await confirm(translate('customers.alertMessages.selectedContainerIsAlreadyAssigned'))) {
            setIsChecked(true, id);
            addContainer(id);
          } else {
            setIsChecked(false, id);
          }
        } else {
          setIsChecked(true, id);
          addContainer(id);
        }
      } else {
        setIsChecked(false, id);
        if (currentServiceContainers.some((container: ContainerListItem) => container.id === id)) {
          this.setState({ isModalOpen: true });
        } else {
          setIsChecked(false, id);
          transferContainer(selectedContainer.id);
        }
      }
    };

    const handleContainerTransfer = (values: any) => {
      if (selectedContainer) {
        transferContainer(selectedContainer.id);
        setIsChecked(false, selectedContainer.id);

        const binNumberToSend = map(currentBinNumberValue, (el: string) => ({
          id: parseInt(el),
          locationId: parseInt(el) === selectedContainer.id ? values.facilityTypeId : 0,
        }));

        const currentBinNumberValueAfterTransfer = filter(
          currentBinNumberValue,
          value => value !== selectedContainer.id,
        );

        this.updateFormValue('facilityTypeId', values.facilityTypeId);
        this.updateFormValue('binNumberToSend', [...(this.state.binNumberToSend || []), ...(binNumberToSend || [])]);

        this.setState({
          binNumberToSend: [...this.state.binNumberToSend, ...binNumberToSend],
          currentBinNumberValue: currentBinNumberValueAfterTransfer,
        });
      }

      this.setState({ isModalOpen: false });
    };

    const handleSubmitCreateEditContainer = async (values: Container | ContainerToSave) => {
      await saveContainer({
        ...values,
        ...(!values.id
          ? { equipmentCondition: find(equipmentConditions, { id: values.equipmentConditionId }).name }
          : {}),
        ...(typeof values.locationId === 'object'
          ? { locationId: values.locationId.locationId, serviceContractId: values.locationId.serviceContractId }
          : {}),
      })
        .then(res => {
          createSuccessNotification(translate('containers.alertMessages.containerSaved'));
          loadContainerList(vendorId).then(response => {
            const newContainer = JSON.parse(res.config.data);
            const newSelectedContainer = response.filter(
              (container: ContainerListItem) => container.containerNumber === newContainer?.containerNumber,
            );

            const containerNumber = newSelectedContainer.map((el: ContainerListItem) => el)[0].containerNumber;
            const containerId = newSelectedContainer.map((el: ContainerListItem) => el)[0].id;
            const containerList = [...containerListFormatted, ...newSelectedContainer];

            this.setState({
              containerListFormatted: containerList,
            });

            if (newContainer.locationId && newContainer.locationId === serviceLocationId) {
              addContainer(containerId, containerNumber);
              setIsChecked(true, containerId, containerList);
            }
          });

          this.setState({ isCreateContainerModalOpen: false });
        })
        .catch(() => {
          createErrorNotification(translate('containers.alertMessages.containerAlreadyExists'));
        });
    };

    const numberOfContainersValidation = isContainerManagementFeatureEnabled
      ? [isNumberOrZero, minValueNumeric0, maxValueNumeric999]
      : [isRequired, isInteger, minValueNumeric1, maxValueNumeric999];

    const isSupport = checkIfSupport();
    const isViewOnly = checkIfViewOnly();

    return (
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <Grid padding="no " multiLine>
            <GridColumn size="6/12" padding="no small no no">
              <FieldSet disabled={isReadonly}>
                <Grid padding="no" multiLine>
                  <GridColumn size="12/12">
                    <PanelSectionSubTitle>{translate('customers.serviceDetails')}</PanelSectionSubTitle>
                  </GridColumn>
                  <GridColumn size="6/12">
                    <TypedField
                      name="serviceTypeId"
                      component={Dropdown}
                      onChange={this.onChangeServiceTypeId}
                      validate={[isRequired]}
                      props={{
                        disabled: isEditMode,
                        isClearable: true,
                        label: translate('common.serviceType'),
                        margin: 'no',
                        onConfirmChange: onConfirmServiceTypeChange,
                        options: serviceTypeOptions,
                      }}
                    />
                  </GridColumn>
                  <GridColumn size="6/12">
                    <Field
                      name="equipmentTypeId"
                      component={EquipmentTypeDropdown}
                      serviceTypeId={currentFormValues.serviceTypeId}
                      label={translate('common.equipmentType')}
                      validate={[isRequired]}
                      dropdownProps={{ isClearable: true, disabled: !isNewServiceContract || isEditMode }}
                      onChange={this.onChangeEquipmentTypeId}
                    />
                  </GridColumn>
                  <GridColumn size="6/12">
                    <Field
                      name="equipmentSizeId"
                      component={EquipmentSizeDropdown}
                      equipmentTypeId={currentFormValues.equipmentTypeId}
                      label={translate('common.equipmentSize')}
                      validate={[isRequired]}
                      dropdownProps={{ isClearable: true, disabled: isEsriRecord || !isNewServiceContract }}
                    />
                  </GridColumn>
                  <GridColumn size="6/12">
                    <Field
                      name="wasteMaterialTypeId"
                      component={WasteTypeDropdown}
                      label={translate('common.wasteType')}
                      validate={[isRequired]}
                      dropdownProps={{ isClearable: true, disabled: !isNewServiceContract || isEditMode }}
                    />
                  </GridColumn>
                  <GridColumn size="6/12">
                    <Field
                      name="numberOfContainers"
                      component={Input}
                      label={translate('common.numberOfContainers')}
                      validate={numberOfContainersValidation}
                      disabled={!!isContainerManagementFeatureEnabled || !isNewServiceContract}
                      props={{ id: 'edit-service-number-of-containers' }}
                    />
                  </GridColumn>
                  <GridColumn size={`${isContainerManagementFeatureEnabled && isNewServiceContract ? '4/12' : '6/12'}`}>
                    <Field
                      name="binNumber"
                      component={Input}
                      label={translate(
                        isContainerManagementFeatureEnabled ? 'containers.containers' : 'common.binNumber',
                      )}
                      validate={[maxLength120]}
                      disabled={isContainerManagementFeatureEnabled || isEsriRecord || !isNewServiceContract}
                      props={{ id: 'edit-service-container-no', isHidden: isContainerManagementFeatureEnabled }}
                    />
                    {isContainerManagementFeatureEnabled && (
                      <Field
                        name="binNumberString"
                        component={Input}
                        label={translate('containers.containers')}
                        disabled={true}
                      />
                    )}
                  </GridColumn>
                  {isContainerManagementFeatureEnabled && !isSupport && !isViewOnly && isNewServiceContract && (
                    <>
                      <GridColumn size="1/12">
                        <CreateContainerToggle onClick={() => this.setState({ isSelectContainersModalOpen: true })}>
                          <ActionButtonTooltip size="sMedium" icon="select" tooltip="selectContainers" />
                        </CreateContainerToggle>
                      </GridColumn>
                      <GridColumn size="1/12">
                        <CreateContainerToggle onClick={() => this.toggle(true)}>
                          <ActionButtonTooltip size="medium" icon="plus" tooltip="addNewContainer" />
                        </CreateContainerToggle>
                      </GridColumn>
                    </>
                  )}
                  <GridColumn size={isBillingFeatureActive ? '6/12' : '12/12'} padding="medium xSmall">
                    <Field
                      name="isTemporaryContainer"
                      component={Checkbox}
                      label={translate('customers.temporaryContainer')}
                      props={{ id: 'edit-service-is-temporary-container' }}
                      disabled={!isNewServiceContract}
                    />
                  </GridColumn>
                  {isBillingFeatureActive && (
                    <GridColumn size="6/12" padding="medium xSmall">
                      {isAllowCreateDeliveryWorkorderVisible && (
                        <Field
                          name="createDeliveryWorkOrder"
                          component={Checkbox}
                          label={translate('customers.createDeliveryWorkOrder')}
                          props={{ id: 'edit-service-createDeliveryWorkOrder' }}
                          disabled={!isNewServiceContract}
                        />
                      )}
                    </GridColumn>
                  )}
                  <GridColumn size="6/12">
                    <Field
                      onChange={(e: any) => {
                        this.setState(prev => ({
                          changedInput: 'effectiveDate',
                          prevValues: {
                            effectiveDate: prev.currentValues.effectiveDate
                              ? prev.currentValues.effectiveDate
                              : moment(e).format('MM/DD/YYYY'),
                            expirationDate: currentValues.expirationDate,
                          },
                          currentValues: {
                            effectiveDate: moment(e).format('MM/DD/YYYY'),
                            expirationDate: currentValues.expirationDate,
                          },
                        }));
                      }}
                      name="effectiveDate"
                      component={DatePicker}
                      label={translate('customers.serviceEffectiveDate')}
                      validate={[isRequired, isDateValidValidator]}
                      disabled={isEsriRecord || !isNewServiceContract}
                      props={{ name: 'effectiveDate' }}
                      isClearable
                    />
                  </GridColumn>
                  <GridColumn size="6/12">
                    <Field
                      onChange={(e: any) => {
                        this.setState(prev => ({
                          changedInput: 'expirationDate',
                          prevValues: {
                            effectiveDate: currentValues.effectiveDate,
                            expirationDate: prev.currentValues.expirationDate
                              ? prev.currentValues.expirationDate
                              : moment(e, 'MM/DD/YYYY'),
                          },
                          currentValues: {
                            effectiveDate: currentValues.effectiveDate,
                            expirationDate: moment(e).format('MM/DD/YYYY'),
                          },
                        }));
                      }}
                      name="cancellationDate"
                      component={DatePicker}
                      label={translate('customers.serviceExpirationDate')}
                      disabled={isReadonly}
                      disabledDays={[
                        {
                          before: moment(serviceEffectiveDate).add(1, 'days').toDate(),
                        },
                      ]}
                      props={{ name: 'cancellationDate' }}
                      isClearable
                      validate={[isDateValidValidator]}
                    />
                  </GridColumn>
                  <GridColumn size="5/12">
                    <Field
                      name="serviceContractAccountStatusTypeId"
                      component={AccountStatusDropdown}
                      label={translate('common.accountStatus')}
                      dropdownProps={{
                        disabled:
                          !isNewServiceContract ||
                          customerAccountStatusTypeId === ON_HOLD ||
                          customerAccountStatusTypeId === SUSPENDED ||
                          customerAccountStatusTypeId === CLOSED ||
                          accountStatusTypeId === ON_HOLD ||
                          accountStatusTypeId === SUSPENDED ||
                          accountStatusTypeId === CLOSED,
                      }}
                      onChange={this.accountStatusChange}
                    />
                  </GridColumn>
                  <GridColumn size="1/12">
                    {serviceId && (
                      <AccountStatusToggle
                        onClick={() => this.toggle()}
                        disabled={
                          !isNewServiceContract ||
                          customerAccountStatusTypeId === ON_HOLD ||
                          customerAccountStatusTypeId === SUSPENDED ||
                          customerAccountStatusTypeId === CLOSED ||
                          accountStatusTypeId === ON_HOLD ||
                          accountStatusTypeId === SUSPENDED ||
                          accountStatusTypeId === CLOSED
                        }
                      >
                        <AccountStatusToggleIcon isOpen={isUpdateAccountStatusSectionOpen} />
                      </AccountStatusToggle>
                    )}
                  </GridColumn>
                  <GridColumn size="5/12">
                    <Field
                      name="haulerAccountNo"
                      component={Input}
                      label={translate('common.serviceAccountNumber')}
                      validate={[maxLength250]}
                      props={{ id: 'edit-service-account-no' }}
                      disabled={!isNewServiceContract || isAutomaticAccountNumberGenerationFeatureActive}
                    />
                  </GridColumn>
                  {serviceContractCode && isSmartCity && (
                    <GridColumn size="1/12">
                      <ContractCodeIconPopup>
                        <PopoverWrapper
                          triggerButton={<ContractCodeIcon />}
                          popoverContent={
                            <Popover>
                              {translate('customers.rubiconServiceAccount')}
                              {serviceContractCode}
                            </Popover>
                          }
                          size="large"
                        />
                      </ContractCodeIconPopup>
                    </GridColumn>
                  )}
                  {!(
                    customerAccountStatusTypeId === ON_HOLD ||
                    customerAccountStatusTypeId === SUSPENDED ||
                    customerAccountStatusTypeId === CLOSED ||
                    accountStatusTypeId === ON_HOLD ||
                    accountStatusTypeId === SUSPENDED ||
                    accountStatusTypeId === CLOSED
                  ) && (
                    <GridColumn size="6/12">
                      {!isUpdateAccountStatusSectionOpen &&
                        accountStatusChanged &&
                        getFutureAccountStatusCanceledMessagge()}
                      {!isUpdateAccountStatusSectionOpen &&
                        (accountStatusMessageFuture || accountStatusMessageCurrent) &&
                        !accountStatusChanged && (
                          <Text block margin="small no no no">
                            {translate(accountStatusMessageFuture || accountStatusMessageCurrent, {
                              label: ACCOUNT_STATUSES[futureAccountStatusId].name,
                              date: humanizeDate(
                                accountStatusMessageFuture ? futureAccountStatusFromDate : futureAccountStatusToDate,
                              ),
                            })}
                          </Text>
                        )}
                    </GridColumn>
                  )}
                  {(customerAccountStatusTypeId === ON_HOLD ||
                    customerAccountStatusTypeId === SUSPENDED ||
                    customerAccountStatusTypeId === CLOSED) &&
                    !(
                      accountStatusTypeId === ON_HOLD ||
                      accountStatusTypeId === SUSPENDED ||
                      accountStatusTypeId === CLOSED
                    ) && (
                      <GridColumn size="6/12">
                        <Text block margin="small no no no">
                          {translate(
                            customerAccountStatusTypeId === ON_HOLD
                              ? 'customers.serviceCustomerLevelDisabledForOnHold'
                              : 'customers.serviceCustomerLevelDisabledForSuspendedOrClosed',
                            {
                              label: ACCOUNT_STATUSES[customerAccountStatusTypeId].name,
                            },
                          )}
                        </Text>
                      </GridColumn>
                    )}
                  {(accountStatusTypeId === ON_HOLD ||
                    accountStatusTypeId === SUSPENDED ||
                    accountStatusTypeId === CLOSED) && (
                    <GridColumn size="6/12">
                      <Text block margin="small no no no">
                        {translate(
                          accountStatusTypeId === ON_HOLD
                            ? 'customers.serviceLocationLevelDisabledForOnHold'
                            : 'customers.serviceLocationLevelDisabledForSuspendedOrClosed',
                          {
                            label: ACCOUNT_STATUSES[accountStatusTypeId].name,
                          },
                        )}
                      </Text>
                    </GridColumn>
                  )}

                  <GridColumn size="12/12">
                    {isUpdateAccountStatusSectionOpen && (
                      <Fragment>
                        <Grid padding="no" multiLine>
                          <GridColumn size="4/12" padding="no">
                            <Field
                              onChange={(e: any) => {
                                if (e === ACTIVE || e === NEW || e === CLOSED) {
                                  this.setState({
                                    prevValues: { from: currentValues.from, to: null },
                                    currentValues: {
                                      from: currentValues.from,
                                      to: null,
                                    },
                                  });
                                  this.updateFormValue('futureAccountStatus.toDate', null);
                                }
                              }}
                              name="futureAccountStatus.accountStatusId"
                              component={FutureAccountStatusDropdown}
                              margin="no"
                              accountStatusId={serviceContractAccountStatusTypeId}
                              label={translate('common.updateStatusTo')}
                              validate={[isRequired]}
                              disabled={!isNewServiceContract}
                            />
                          </GridColumn>
                        </Grid>
                        <Grid padding="no" multiLine>
                          <GridColumn size="4/12" padding="no">
                            <Field
                              onChange={(e: any) => {
                                this.setState(prev => ({
                                  changedInput: 'from',
                                  prevValues: {
                                    from: prev.currentValues.from
                                      ? prev.currentValues.from
                                      : moment(e).format('MM/DD/YYYY'),
                                    to: currentValues.to,
                                  },
                                  currentValues: { from: moment(e).format('MM/DD/YYYY'), to: currentValues.to },
                                }));
                              }}
                              name="futureAccountStatus.fromDate"
                              component={DatePicker}
                              disabledDays={[
                                {
                                  before: moment().add(1, 'days').toDate(),
                                  after: futureAccountStatusToDate
                                    ? moment(futureAccountStatusToDate).subtract(1, 'days').toDate()
                                    : undefined,
                                },
                              ]}
                              validate={[isRequired, isDateValidValidator]}
                              margin="no"
                              label={translate('common.from')}
                              isClearable
                              disabled={isReadonly || !isNewServiceContract}
                            />
                          </GridColumn>
                          {(futureAccountStatusId === SUSPENDED || futureAccountStatusId === ON_HOLD) && (
                            <GridColumn size="4/12">
                              <Field
                                onChange={(e: any) => {
                                  this.setState(prev => ({
                                    changedInput: 'to',
                                    prevValues: {
                                      from: currentValues.from,
                                      to: prev.currentValues.to
                                        ? prev.currentValues.to
                                        : moment(e).format('MM/DD/YYYY'),
                                    },
                                    currentValues: { from: currentValues.from, to: moment(e).format('MM/DD/YYYY') },
                                  }));
                                }}
                                name="futureAccountStatus.toDate"
                                component={DatePicker}
                                disabledDays={[
                                  {
                                    before: futureAccountStatusFromDate
                                      ? moment(futureAccountStatusFromDate).add(1, 'days').toDate()
                                      : moment().add(2, 'days').toDate(),
                                    after: undefined,
                                  },
                                ]}
                                margin="no"
                                label={translate('common.to')}
                                isClearable
                                validate={[isDateValidValidator]}
                                disabled={isReadonly || !isNewServiceContract}
                              />
                            </GridColumn>
                          )}
                        </Grid>
                      </Fragment>
                    )}
                  </GridColumn>
                  <GridColumn size="12/12" padding="medium xSmall">
                    <Field
                      name="isServiceProvided"
                      component={Checkbox}
                      label={translate('customers.isRubiconCustomer')}
                      props={{ id: 'edit-service-is-rubicon-customer' }}
                      disabled={isReadonly || !isNewServiceContract}
                    />
                  </GridColumn>
                  <GridColumn size="12/12">
                    <Field
                      name="placementInstructions"
                      component={TextArea}
                      rows="3"
                      scrollYVisible
                      validate={[maxLength500]}
                      maxLength={500}
                      label={translate('customers.serviceNotes')}
                      props={{ id: 'edit-service-notes' }}
                    />
                  </GridColumn>
                </Grid>
              </FieldSet>
            </GridColumn>
            <GridColumn size="6/12" padding="no">
              {renderLocationMap()}
            </GridColumn>
            <GridColumn size="12/12" padding="sMedium xSmall">
              <Field
                name="notifyServiceConfirmation"
                component={Switch}
                label={translate('customers.customerNotifications')}
                props={{ id: 'edit-service-customer-notifications' }}
                disabled={isReadonly || !isNewServiceContract}
              />
            </GridColumn>
            {!!currentFormValues.notifyServiceConfirmation && (
              <Fragment>
                <GridColumn size="6/12" padding="no xSmall small xSmall">
                  <Field
                    name="notifyByEmail"
                    component={Checkbox}
                    label={translate('customers.notifyByEmail')}
                    props={{ id: 'edit-service-notify-email' }}
                    disabled={!isNewServiceContract}
                  />
                </GridColumn>
                <GridColumn size="6/12" padding="no xSmall small xSmall">
                  <Field
                    name="notifyByText"
                    component={Checkbox}
                    label={translate('customers.notifyByText')}
                    props={{ id: 'edit-service-notify-text' }}
                    disabled={!isNewServiceContract}
                  />
                </GridColumn>
              </Fragment>
            )}
          </Grid>
          <Grid padding="no" multiLine>
            <GridColumn size="6/12">
              <TypedField
                name="pickupFrequencyTypeId"
                component={PickupFrequencyTypeDropdown}
                onChange={this.onChangePickupFrequencyType}
                validate={[isRequired]}
                props={{
                  label: translate('common.pickupFrequencyType'),
                  dropdownProps: {
                    isClearable: true,
                    id: 'edit-service-pickup-frequency-type',
                    disabled: !isNewServiceContract,
                  },
                }}
              />
            </GridColumn>
            {pickupFrequencyTypeName === X_TIMES_PER_WEEK && (
              <GridColumn size="6/12">
                <Field
                  name="dayOfService"
                  component={Input}
                  label={translate('common.dayOfService')}
                  validate={[isRequired]}
                  disabled={!isNewServiceContract}
                />
              </GridColumn>
            )}
            {pickupFrequencyTypeName === EVERY_X_DAYS && (
              <GridColumn size="6/12">
                <Field
                  name="dayOfService"
                  component={Input}
                  label={translate('common.pickupFrequencyTypes.everyXDays')}
                  validate={[isRequired, maxValueNumeric365]}
                  disabled={!isNewServiceContract}
                />
              </GridColumn>
            )}
            {pickupFrequencyTypeName === EVERY_X_WEEKS && (
              <GridColumn size="6/12">
                <Field
                  name="dayOfService"
                  component={Input}
                  label={translate('common.pickupFrequencyTypes.everyXWeeks')}
                  validate={[isRequired]}
                  type="number"
                  min="0"
                  disabled={!isNewServiceContract}
                />
              </GridColumn>
            )}
            {pickupFrequencyTypeName === MONTHLY && (
              <Fragment>
                <GridColumn size="6/12">
                  <TypedField
                    name="monthlyServicesYearlyFrequencyId"
                    component={MonthlyServicesYearlyFrequencyDropdown}
                    onChange={this.onChangePickupEveryMonthId}
                    validate={[isRequired]}
                    props={{
                      dropdownProps: {
                        isClearable: true,
                        id: 'edit-service-every-month',
                        disabled: !isNewServiceContract,
                      },
                      label: translate('customers.everyMonth'),
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="monthlyServicesMonthlyFrequencyIds"
                    component={MonthlyServicesMonthlyFrequencyDropdownOrMultiSelect}
                    validate={[isMultiSelectOrDropdownRequired]}
                    onChange={this.onChangeDayOfMonth}
                    props={{
                      placeholder: translate('customers.selectDays'),
                      dropdownProps: isBillingFeatureActive
                        ? {
                            margin: 'no',
                            disabled: !isNewServiceContract,
                          }
                        : undefined,
                      multiSelectProps: isBillingFeatureActive
                        ? undefined
                        : {
                            margin: 'no',
                            normalizeValues: Number,
                            canCheckAll: false,
                            disabled: !isNewServiceContract,
                          },
                      label: translate('customers.dayOfMonth'),
                      withPlaceholder: true,
                    }}
                  />
                </GridColumn>
                {isDayOfMonthSelected ? (
                  <GridColumn size="6/12">
                    <Field
                      name="dayOfService"
                      component={Input}
                      label={translate('customers.dayOfMonth')}
                      validate={[isRequired, isNumber, maxValueNumeric31]}
                      disabled={!isNewServiceContract}
                    />
                  </GridColumn>
                ) : (
                  isAnyDayOfMonthSelected && (
                    <>
                      <GridColumn size="6/12">
                        <TypedField
                          name="monthlyServicesWeeklyFrequencyId"
                          component={MonthlyServicesWeeklyFrequencyDropdown}
                          onChange={this.onChangeMonthlyServicesWeeklyFrequencyId}
                          validate={[isRequired]}
                          props={{
                            label: translate('customers.dayOfMonth'),
                            dropdownProps: {
                              isClearable: true,
                              disabled: !isNewServiceContract,
                            },
                          }}
                        />
                      </GridColumn>
                      {isMonthlyServicesWeeklyFrequencyIdAWeekDay && (
                        <Grid multiLine margin="no no small no">
                          <GridColumn size="6/12">
                            <Field
                              name={`dayOfServices[${monthlyServicesWeeklyFrequencyDayOfServiceIndex}].routeTemplateIds`}
                              component={ServiceRouteTemplatesMultiSelect}
                              placeholder={translate('routes.routeTemplates')}
                              multiSelectProps={{
                                disabled: !isNewServiceContract,
                                isClearable: true,
                                id: `edit-service-monthlyServicesWeeklyFrequencyId-day-of-service-template`,
                                onChange: this.onFormValueDayOfServicesChange,
                              }}
                              index={monthlyServicesWeeklyFrequencyDayOfServiceIndex}
                              day={
                                monthlyServicesWeeklyFrequencyDayOfServiceDay
                                  ? monthlyServicesWeeklyFrequencyDayOfServiceDay.id
                                  : -1
                              }
                              resetRouteTemplatesValue={this.resetRouteTemplatesValue}
                            />
                          </GridColumn>
                          {this.renderSelectedRouteTemplateIds(
                            selectedRouteTemplateIds,
                            monthlyServicesWeeklyFrequencyDayOfServiceIndex,
                          )}
                        </Grid>
                      )}
                    </>
                  )
                )}
              </Fragment>
            )}
            {(pickupFrequencyTypeName === EVERY_X_WEEKS ||
              pickupFrequencyTypeName === EVERY_WEEK ||
              pickupFrequencyTypeName === EVERY_OTHER_WEEK) && (
              <Grid multiLine padding="no">
                <GridColumn size="12/12" margin="no no sMedium" padding="medium xSmall no">
                  <PanelSectionSubTitle>{translate('routes.routes')}</PanelSectionSubTitle>
                </GridColumn>
                {!!size(currentFormValues.dayOfServices) &&
                  map(dayOfWeekOptions, (weekday, index) => (
                    <GridColumn
                      size="4/12"
                      padding={`no ${(index + 1) % 3 !== 0 ? 'sMedium' : 'no'} no no`}
                      marginRight={(index + 1) % 3 !== 0 ? '20px' : '0px'}
                      borderRight={(index + 1) % 3 !== 0}
                      key={weekday.shortCode}
                    >
                      <Grid multiLine>
                        <GridColumn size="5/12" verticalAlign="center">
                          <Field
                            component={Checkbox}
                            name={`dayOfServices[${index}].checked`}
                            label={weekday.name}
                            props={{ id: `edit-service-day-of-service-${index}-checkbox` }}
                            onChange={this.onFormValueDayOfServicesChange}
                            disabled={!isNewServiceContract}
                          />
                        </GridColumn>
                        <GridColumn size="7/12">
                          <Field
                            component={ServiceRouteTemplatesMultiSelect}
                            name={`dayOfServices[${index}].routeTemplateIds`}
                            placeholder={translate('routes.routeTemplates')}
                            multiSelectProps={{
                              disabled:
                                !currentFormValues.dayOfServices || !currentFormValues.dayOfServices[index].checked,
                              isClearable: true,
                              id: `edit-service-day-of-service-${index}-template`,
                              onChange: this.onFormValueDayOfServicesChange,
                            }}
                            index={index}
                            day={weekday.id}
                            resetRouteTemplatesValue={this.resetRouteTemplatesValue}
                          />
                        </GridColumn>
                        <Grid multiLine margin="no no small no">
                          {this.renderSelectedRouteTemplateIds(selectedRouteTemplateIds, index)}
                        </Grid>
                      </Grid>
                    </GridColumn>
                  ))}
              </Grid>
            )}
          </Grid>
        </form>
        <PanelFooter padding="sMedium small sMedium xSmall">
          <UpdateTrackerRouteSwitch
            topMargin="small"
            hasScheduledDailyRoutes={hasScheduledDailyRoutes}
            isFormDirty={isFormDirty}
            wrapperMargin="no medium no no"
          />
        </PanelFooter>

        {isModalOpen && (
          <TransferContainerModalResolver
            handleContainerTransfer={handleContainerTransfer}
            title={translate('customers.alertMessages.containerPlacement')}
            closeModal={() => {
              if (selectedContainer) {
                setIsChecked(true, selectedContainer.id);
              }

              this.setState({ isModalOpen: false });
            }}
          />
        )}

        {isCreateContainerModalOpen && (
          <CreateEditContainerModalResolver
            closeModal={() => this.setState({ isCreateContainerModalOpen: false })}
            onSubmit={handleSubmitCreateEditContainer}
          />
        )}

        {isSelectContainersModalOpen && (
          <SelectContainersModalResolver
            closeModal={() => this.setState({ isSelectContainersModalOpen: false })}
            containerList={containerListFormatted}
            onCheckboxChange={containerFieldCondition}
          />
        )}
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const currentFormValues = formSelector(
    state,
    'serviceTypeId',
    'pickupFrequencyTypeId',
    'equipmentTypeId',
    'equipmentSizeId',
    'dayOfService',
    'dayOfServices',
    'notifyServiceConfirmation',
    'monthlyServicesWeeklyFrequencyId',
    'binNumber',
    'numberOfContainers',
  );

  const isBillingFeatureActive = billingFeatureStatusSelector(state.vendors.features.features);

  const pickupFrequencyTypeName = technicalNameByPickupFrequencyTypeIdSelector(
    state.common.pickupFrequencyTypes,
    currentFormValues.pickupFrequencyTypeId,
  );

  const initialValues = (serviceDetailsEditorFormInitialValuesSelector as any)(
    state.customers.service.service,
    state.common.pickupFrequencyTypes,
    isBillingFeatureActive,
  );
  const isContainerManagementFeatureEnabled = !!(state.vendors.features.features || []).find(
    f => f.code === 'ContainerManagement' && f.enabled,
  );
  const serviceContractDetailsInitialValues = ownProps.serviceContractDetails
    ? {
        equipmentSizeId: ownProps.serviceContractDetails.equipmentSizeId,
        equipmentTypeId: ownProps.serviceContractDetails.equipmentTypeId,
        serviceTypeId: ownProps.serviceContractDetails.serviceTypeId,
        wasteMaterialTypeId: ownProps.serviceContractDetails.wasteMaterialTypeId,
      }
    : undefined;

  const vendorContainers = state.customers.services.services;
  const binNumberInitialValue = isContainerManagementFeatureEnabled
    ? initialValues.vendorContainers && initialValues.vendorContainers.length
      ? state.customers.containerList.containerList
          .filter(container => initialValues.vendorContainers.some((el: ContainerListItem) => el.id === container.id))
          .map(el => el.id)
      : []
    : initialValues.binNumber || '';

  const containerListString = state.customers.containerList.containerList
    .filter(container => binNumberInitialValue?.includes(container.id))
    .map(container => container.containerNumber)
    .join(', ');

  const numberOfContainers = isContainerManagementFeatureEnabled
    ? initialValues.vendorContainers
      ? initialValues.vendorContainers.length
      : 0
    : initialValues.numberOfContainers;

  const equipmentTypes = uniqBy(
    map(state.common.containerTypes.containerTypes, containerType => containerType),
    'id',
  );

  return {
    binNumberInitialValue,
    containerList: state.customers.containerList.containerList,
    currentFormValues,
    currentServiceContainers: state.customers.service.service?.vendorContainers || [],
    customer: state.customers.customer.customer,
    customerLocation: state.customers.location.location,
    equipmentConditions: state.common.equipmentConditions.equipmentConditions || [],
    equipmentTypes,
    futureAccountStatusFromDate: formSelector(state, 'futureAccountStatus.fromDate'),
    futureAccountStatusId: formSelector(state, 'futureAccountStatus.accountStatusId'),
    futureAccountStatusToDate: formSelector(state, 'futureAccountStatus.toDate'),
    isBillingFeatureActive,
    isContainerManagementFeatureEnabled,
    vendorContainers,
    initialValues: {
      ...initialValues,
      ...serviceContractDetailsInitialValues,
      binNumber: binNumberInitialValue,
      binNumberString: containerListString,
      numberOfContainers,
    },
    isEsriRecord: initialValues.isEsriRecord,
    isFormDirty: isDirty(SERVICE_DETAILS_EDITOR_FORM)(state),
    isAutomaticAccountNumberGenerationFeatureActive: automaticAccountNumberGenerationSelector(
      state.vendors.features.features,
    ),
    isSmartCity: isSmartCitySelector(state.account.login, state.vendors.defaultVendor),
    locationServiceTypes: state.customers.locationServiceTypes.locationServiceTypes as any,
    monthlyServicesMonthlyFrequencyIds: formSelector(state, 'monthlyServicesMonthlyFrequencyIds'),
    pickupFrequencyTypeName,
    routeTemplatesByScheduledDays: state.customers.routeTemplatesByScheduledDays.routeTemplatesByScheduledDays,
    serviceContractAccountStatusTypeId: formSelector(state, 'serviceContractAccountStatusTypeId'),
    serviceEffectiveDate: formSelector(state, 'effectiveDate'),
    serviceExpirationDate: formSelector(state, 'cancellationDate'),
    serviceLocationId: state.customers.service.service?.locationId,
    vehicleTypeId: state.routes.route?.route?.vehicleTypeId || state.routes.routeTemplate?.routeTemplate?.vehicleTypeId,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
  };
};

const mapDispatchToProps = {
  change,
  loadContainerList,
  loadMonthlyServicesMonthlyFrequencyTypes,
  loadMonthlyServicesWeeklyFrequencyTypes,
  loadMonthlyServicesYearlyFrequencyTypes,
  loadRouteTemplatesByScheduledDays,
  loadRouteTemplatesHaveStopsInPendingOptimization,
  saveContainer,
  submit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<FormValues, CombinedProps>({
    form: SERVICE_DETAILS_EDITOR_FORM,
    onSubmitFail: focusFirstInvalidField,
    enableReinitialize: true,
  })(ServiceDetailsEditorForm),
);
