import React, { PureComponent } from 'react';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { Container, ButtonSet, Button } from '../../../core/components/styled';
import { DatePicker, Dropdown, Input } from '../../../core/components';
import {
  REASONS,
  RUBICON_DISPATCHES_REASONS,
  CONTAINER_PLACEMENT_ISSUE,
  LOCATION_INACCESSIBLE,
  PERSONNEL_ISSUE,
} from '../../constants';
import translate from '../../../core/services/translate';
import deliveryFormInitialValueSelector from '../../services/deliveryFormInitialValueSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { AppState } from '../../../store';
import { DECLINE, REQUEST_CHANGE } from '../../../fleet/constants/rubiconDisptachesStatus';

const formSelector = formValueSelector('serviceUnableToDeliver');

interface ComponentProps {
  action: string;
  afterDeliveryDate?: string;
  beforeDeliveryDate?: string;
  caseId: number;
  liftAndShiftVendor?: boolean;
  statusId: number;
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  CanNotDeliver: {
    reason?: any;
    notes?: any;
  };
  response?: any;
}

class ServiceUnableToDeliverForm extends PureComponent<Props, State> {
  readonly state: State = {
    response: undefined,
    CanNotDeliver: { reason: undefined, notes: undefined },
  };

  onChangeReason = (event: any, value: any) => {
    this.setState({ CanNotDeliver: { reason: value, notes: undefined } });
  };

  render() {
    const {
      action,
      afterDeliveryDate,
      beforeDeliveryDate,
      caseId,
      handleSubmit,
      liftAndShiftVendor,
      onCancel,
      pristine,
    } = this.props;

    const { CanNotDeliver } = this.state;
    let reasonsSelectOption;
    if (liftAndShiftVendor) {
      reasonsSelectOption = map(RUBICON_DISPATCHES_REASONS, reasonOption => ({
        label: reasonOption.name,
        value: reasonOption.id,
      }));
    } else {
      reasonsSelectOption = map(REASONS, reasonOption => ({
        label: reasonOption.name,
        value: reasonOption.id,
      }));
    }
    let isNewDeliveryDateVisible: boolean;
    if (action === DECLINE) isNewDeliveryDateVisible = false;
    else if (action === REQUEST_CHANGE) isNewDeliveryDateVisible = true;
    else
      isNewDeliveryDateVisible =
        CanNotDeliver.reason === CONTAINER_PLACEMENT_ISSUE ||
        CanNotDeliver.reason === LOCATION_INACCESSIBLE ||
        CanNotDeliver.reason === PERSONNEL_ISSUE;

    const isNoteRequired = this.state.CanNotDeliver.reason === 'Other' ? [isRequired] : undefined;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <Field
            name={`deliveries.settings${caseId}.CanNotDeliver.reason`}
            component={Dropdown as any}
            options={reasonsSelectOption}
            validate={[isRequired]}
            onChange={this.onChangeReason}
            label={translate('opportunity.selectReasonType')}
            margin="medium small small"
          />
        </Container>
        {liftAndShiftVendor && isNewDeliveryDateVisible && (
          <Container>
            <Field
              name={`deliveries.settings${caseId}.CanNotDeliver.deliveryDate`}
              component={DatePicker}
              disabledDays={[
                {
                  before: moment(beforeDeliveryDate, 'MM/DD/YYYY').toDate(),
                  after: moment(afterDeliveryDate, 'MM/DD/YYYY').toDate(),
                },
                { daysOfWeek: [0, 6] },
              ]}
              label={translate('opportunity.newDeliveryDate')}
              validate={[isRequired, isDateValidValidator]}
              margin="medium small small"
            />
          </Container>
        )}
        <Container>
          {(this.state.CanNotDeliver.reason === 'Other' || liftAndShiftVendor) && (
            <Field
              name={`deliveries.settings${caseId}.CanNotDeliver.notes`}
              component={Input}
              type="text"
              label={translate('routes.addNote')}
              size="small"
              validate={isNoteRequired}
              margin="medium small large"
            />
          )}
        </Container>
        <ButtonSet margin="xxLarge no medium">
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: deliveryFormInitialValueSelector(
    state.opportunity.opportunity.deliveries,
    state.fleet.rubiconDispatches.rubiconDispatches,
  ),
  response: formSelector(state, `deliveries.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `deliveries.settings${ownProps.caseId}.requestedChanges`),
  WasDelivered: formSelector(state, `deliveries.settings${ownProps.caseId}.WasDelivered`),
  CanNotDeliver: formSelector(state, `deliveries.settings${ownProps.caseId}.CanNotDeliver`),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'serviceUnableToDeliver',
    onSubmitFail: focusFirstInvalidField,
  })(ServiceUnableToDeliverForm),
);
