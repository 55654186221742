import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

const transformLoadCustomers = (response: any) => ({
  ...response,
  customers: map(response.customers, customer => ({
    ...customer,
    customerType: translate(createTranslationKey(customer.customerTypeName, 'customers.customerTypes')),
  })),
});

export default transformLoadCustomers;
