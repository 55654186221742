import { Action, Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import {
  loadFleetDetails,
  loadRouteDetails,
  loadServiceDetails,
  loadSnowSweeperRouteDetails,
  loadSnowSweeperServiceDetails,
  resetFleetDetails,
  resetRouteDetails,
  resetServiceDetails,
} from 'src/routes/ducks';
import { loadFuelingTicketsSettings } from 'src/vendors/ducks';
import { Modal } from 'src/core/components';
import { ModalSection, Tab, Tabs } from 'src/core/components/styled';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import FleetTab from './routeModalTabs/FleetTab';
import RouteTab from './routeModalTabs/RouteTab';
import ServiceTab from './routeModalTabs/ServiceTab';
import translate from 'src/core/services/translate';

export enum ROUTE_TAB {
  ROUTE = 'ROUTE',
  FLEET = 'FLEET',
  SERVICE = 'SERVICE',
}

type Props = {
  closeModal: () => void;
  isDriverExperienceSimple?: boolean;
  isNoRoute?: boolean;
};

export default function RouteDetailsModal({ closeModal, isDriverExperienceSimple, isNoRoute }: Props) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { isLoading: isRouteDetailsLoading } = useSelector(state => state.routes.routeDetails);
  const { isLoading: isFleetDetailsLoading } = useSelector(state => state.routes.fleetDetails);
  const { isLoading: isServiceDetailsLoading } = useSelector(state => state.routes.serviceDetails);
  const isSnowPlowFeatureEnabled = useSelector(state => checkIfSnowPlowIsEnabled(state));
  const isStreetSweepingFeatureEnabled = useSelector(state => checkIfStreetSweepingIsEnabled(state));

  const [activeTab, setActiveTab] = useState(ROUTE_TAB.SERVICE);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary?.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const loadServiceTabData = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
    loadFuelingTicketsSettings(vendorId)(dispatch);

    (isSnowPlowFeatureEnabled || isStreetSweepingFeatureEnabled) && isSnowOrSweeperRoute
      ? loadSnowSweeperServiceDetails(vendorId, routeId, segmentsFilterStartDate, segmentsFilterEndDate)(dispatch)
      : loadServiceDetails(vendorId, routeId)(dispatch);
  };

  const loadFleetTabData = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
    loadFleetDetails(vendorId, routeId)(dispatch);
  };

  const loadRouteTabData = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
    (isSnowPlowFeatureEnabled || isStreetSweepingFeatureEnabled) && isSnowOrSweeperRoute
      ? loadSnowSweeperRouteDetails(vendorId, routeId, segmentsFilterStartDate, segmentsFilterEndDate)(dispatch)
      : loadRouteDetails(vendorId, routeId)(dispatch);
  };

  useEffect(() => {
    if (!routeSummary?.routeId) return;

    let loadDataFunction;
    let resetDataAction: () => Action;

    switch (activeTab) {
      case ROUTE_TAB.SERVICE:
        loadDataFunction = loadServiceTabData;
        resetDataAction = resetServiceDetails;
        break;
      case ROUTE_TAB.FLEET:
        loadDataFunction = loadFleetTabData;
        resetDataAction = resetFleetDetails;
        break;
      case ROUTE_TAB.ROUTE:
        loadDataFunction = loadRouteTabData;
        resetDataAction = resetRouteDetails;
        break;
    }

    loadDataFunction(vendorId, routeSummary.routeId)(dispatch);

    return () => {
      dispatch(resetDataAction());
    };
  }, [// eslint-disable-line react-hooks/exhaustive-deps
    activeTab,
    dispatch,
    isSnowPlowFeatureEnabled,
    isStreetSweepingFeatureEnabled,
    routeSummary?.routeId,
    vendorId,
  ]);

  const renderTab = () => {
    switch (activeTab) {
      case ROUTE_TAB.SERVICE:
        return <ServiceTab isDriverExperienceSimple={isDriverExperienceSimple} isNoRoute={isNoRoute} />;
      case ROUTE_TAB.FLEET:
        return <FleetTab />;
      case ROUTE_TAB.ROUTE:
        return <RouteTab isNoRoute={isNoRoute} />;
    }
  };

  if (!routeSummary) return null;

  return (
    <Modal
      title={`${routeSummary.name} ${translate('haulerProfile.details')}`}
      size="xLarge"
      onClose={closeModal}
      isLoading={isRouteDetailsLoading || isFleetDetailsLoading || isServiceDetailsLoading}
    >
      <ModalSection margin="medium no no" align="center">
        <Tabs fullWidth>
          <Tab flexDisplay isSelected={activeTab === ROUTE_TAB.SERVICE} onClick={() => setActiveTab(ROUTE_TAB.SERVICE)}>
            {translate('common.service')}
          </Tab>
          <Tab flexDisplay isSelected={activeTab === ROUTE_TAB.FLEET} onClick={() => setActiveTab(ROUTE_TAB.FLEET)}>
            {translate('common.fleet')}
          </Tab>
          <Tab flexDisplay isSelected={activeTab === ROUTE_TAB.ROUTE} onClick={() => setActiveTab(ROUTE_TAB.ROUTE)}>
            {translate('common.route')}
          </Tab>
        </Tabs>
      </ModalSection>
      <ModalSection overflow="visible" margin="small no no">
        {renderTab()}
      </ModalSection>
    </Modal>
  );
}
