import React from 'react';
import { Layer } from 'react-map-gl';

import { ROUTE_MAP_APPLICATION_STATUS_LAYER, ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';

export default React.memo(function RouteMapApplicationStatusGLLayers() {
  return (
    <Layer
      type="symbol"
      source={ROUTE_MAP_CLUSTERS_SOURCE}
      filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], ROUTE_MAP_APPLICATION_STATUS_LAYER]]}
      paint={{
        'icon-color': ['get', 'color'],
        'icon-halo-color': '#ffffff',
        'icon-halo-width': 10,
        'icon-halo-blur': 10,
      }}
      layout={{
        'icon-image': ['get', 'icon'],
        'icon-size': 1,
        'icon-allow-overlap': true,
      }}
      minzoom={15}
    />
  );
});
