import { get } from 'lodash-es';
import moment from 'moment';
import React, { Fragment } from 'react';

import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form';
import { Checkbox, HourPicker, TextDisplay } from '../../../../core/components';
import { Grid, GridColumn } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';
import { isRequired } from '../../../../utils/services/validator';

const validate = (value: string, formValues: any, props: any, fieldName: string) => {
  const fieldValues = get(formValues, fieldName.split('.')[0]);
  const openTime = moment(fieldValues.fromHoursFormatted, 'LT');
  const closeTime = moment(fieldValues.toHoursFormatted, 'LT');

  if (openTime.isAfter(closeTime)) {
    return translate('haulerProfile.alertMessages.openHourIsAfterCloseHour');
  }
  return undefined;
};

const HoursOfOperationFieldArray: React.FC<WrappedFieldArrayProps> = ({ fields }) => {
  return (
    <Fragment>
      {fields.map((field, index) => (
        <Grid multiLine margin="no no small no" key={index}>
          <GridColumn size="1/12" padding="no no small no">
            <Field name={`${field}.isActive`} label="" component={Checkbox} />
          </GridColumn>

          <GridColumn size="11/12" padding="no no small no">
            <Field name={`${field}.titleKey`} component={TextDisplay} />
          </GridColumn>

          {!!fields.get(index).isActive && (
            <Fragment>
              <GridColumn size="5/12" padding="no">
                <Field
                  name={`${field}.fromHoursFormatted`}
                  component={HourPicker}
                  label={translate('common.open')}
                  validate={[isRequired, validate]}
                />
              </GridColumn>

              <GridColumn size="6/12" shift="1/12">
                <Field
                  name={`${field}.toHoursFormatted`}
                  component={HourPicker}
                  label={translate('common.close')}
                  validate={[isRequired]}
                />
              </GridColumn>
            </Fragment>
          )}
        </Grid>
      ))}
    </Fragment>
  );
};

const HoursOfOperation = () => (
  <FieldArray name="hoursOfOperation" component={HoursOfOperationFieldArray} props={{}} rerenderOnEveryChange />
);

export default HoursOfOperation;
