import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { ROUTE_BUILDER_COMPLETE, ROUTE_BUILDER_FAILED } from '../../../core/constants';
import {
  checkActiveRouteBuilder as doCheckActiveRouteBuilder,
  checkRouteBuilderStatus as doCheckRouteBuilderStatus,
  createRouteBuilderRoutes as doCreateRouteBuilderRoutes,
  finishRouteBuilderJob as doFinishRouteBuilderJob,
} from '../../services/dispatchBoardRouteBuilder';

// Actions
const START_LOAD = 'dispatchBoard/routeBuilder/START_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/routeBuilder/COMPLETE_LOAD';
const FAIL_LOAD = 'dispatchBoard/routeBuilder/FAIL_LOAD';
const START_WAITING = 'dispatchBoard/routeBuilder/START_WAITING';
const COMPLETE_WAITING = 'dispatchBoard/routeBuilder/COMPLETE_WAITING';
const RESET = 'dispatchBoard/routeBuilder/RESET';

const INTERVAL = 5000;
const checkBuilderIntervalIds: any[] = [];

interface State {
  errorMessage?: string;
  isLoading: boolean;
  isWaitingAfterRouteBuilder: boolean;
  jobId?: number;
  routes: any[];
  statusId?: number;
  jobsNotRoutedCount?: number;
  jobsNotRoutedFileDownloadUrl?: string;
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState: State = {
  errorMessage: undefined,
  isLoading: false,
  isWaitingAfterRouteBuilder: false,
  jobId: undefined,
  routes: [],
  statusId: undefined,
  jobsNotRoutedCount: undefined,
  jobsNotRoutedFileDownloadUrl: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          errorMessage: action.errorMessage,
          isLoading: false,
          jobId: action.jobId,
          statusId: action.statusId,
          jobsNotRoutedCount: action.jobsNotRoutedCount,
          jobsNotRoutedFileDownloadUrl: action.jobsNotRoutedFileDownloadUrl,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_WAITING:
      return update(state, {
        $merge: { isWaitingAfterRouteBuilder: true },
      });

    case COMPLETE_WAITING:
      return update(state, {
        $merge: {
          isWaitingAfterRouteBuilder: false,
          routes: action.routes,
          jobsNotRoutedCount: action.jobsNotRoutedCount,
          jobsNotRoutedFileDownloadUrl: action.jobsNotRoutedFileDownloadUrl,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startWaiting = () => ({
  type: START_WAITING,
});

const completeWaiting = (routes?: any[], jobsNotRoutedCount?: number, jobsNotRoutedFileDownloadUrl?: string) => {
  checkBuilderIntervalIds.forEach(intervalId => clearInterval(intervalId));
  checkBuilderIntervalIds.splice(0);
  return {
    type: COMPLETE_WAITING,
    routes: routes || [],
    jobsNotRoutedCount,
    jobsNotRoutedFileDownloadUrl,
  };
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (
  jobId?: number,
  statusId?: number,
  errorMessage?: string,
  jobsNotRoutedCount?: number,
  jobsNotRoutedFileDownloadUrl?: string,
) => ({
  type: COMPLETE_LOAD,
  jobId,
  statusId,
  errorMessage,
  jobsNotRoutedCount,
  jobsNotRoutedFileDownloadUrl,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const reset = () => ({
  type: RESET,
});

export const finishRouteBuilderJob = (vendorId: number, jobId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const createRouteBuilderRoutesPromise = doFinishRouteBuilderJob(vendorId, jobId);
  createRouteBuilderRoutesPromise
    .then(() => {
      dispatch(reset());
    })
    .catch(() => dispatch(failLoad()));
  return createRouteBuilderRoutesPromise;
};

const checkStatus = async ({ vendorId, jobId, dispatch }: any) => {
  const response = await doCheckRouteBuilderStatus(vendorId, jobId);
  const {
    errorMessage,
    jobId: respJobId,
    statusId,
    createdRoutes,
    jobsNotRoutedCount,
    jobsNotRoutedFileDownloadUrl,
  } = response;
  if (statusId === ROUTE_BUILDER_COMPLETE && createdRoutes && createdRoutes.length) {
    dispatch(completeWaiting(createdRoutes, jobsNotRoutedCount, jobsNotRoutedFileDownloadUrl));
  } else if (statusId === ROUTE_BUILDER_FAILED) {
    dispatch(completeWaiting());
    dispatch(finishRouteBuilderJob(vendorId, respJobId));
  }
  dispatch(completeLoad(respJobId, statusId, errorMessage, jobsNotRoutedCount, jobsNotRoutedFileDownloadUrl));
};

const startBuilderInterval = (vendorId: number, jobId: number, dispatch: Dispatch) => {
  dispatch(startWaiting());
  const intervalId = setInterval(checkStatus, INTERVAL, { vendorId, jobId, dispatch });
  checkBuilderIntervalIds.push(intervalId);
};

export const checkActiveRouteBuilder = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const checkActiveRouteBuilderPromise = doCheckActiveRouteBuilder(vendorId);
  checkActiveRouteBuilderPromise
    .then(response => {
      if (response.jobId) {
        // start an interval to poll the API
        startBuilderInterval(vendorId, response.jobId, dispatch);
        dispatch(completeLoad(response.jobId, response.statusId, response.errorMessage));
      } else {
        dispatch(completeLoad());
      }
    })
    .catch(() => dispatch(failLoad()));
  return checkActiveRouteBuilderPromise;
};

export const createRouteBuilderRoutes = (routeData: any) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const createRouteBuilderRoutesPromise = doCreateRouteBuilderRoutes(routeData);
  createRouteBuilderRoutesPromise
    .then(response => {
      if (response.jobId) {
        // start an interval to poll the API
        startBuilderInterval(routeData.vendorId, response.jobId, dispatch);
        dispatch(completeLoad(response.jobId, response.statusId, response.errorMessage));
      } else {
        dispatch(completeLoad());
      }
    })
    .catch(() => dispatch(failLoad()));
  return createRouteBuilderRoutesPromise;
};

export const resetRouteBuilder = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
