import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import {
  CONTAINERS_MAP_ICONS_BY_ID,
  CONTAINERS_MAP_CONTAINERS_LAYER,
  CONTAINERS_MAP_FACILITIES_LAYER,
  FACILITIES_MAP_COLORS_BY_ID,
  MULTIPLE,
} from 'src/fleet/constants/containersMap';
import { ContainerFacility, ContainerLocationForMap } from 'src/fleet/interfaces/containers';

export type ContainerInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  color: string;
  isSelected?: boolean;
  icon?: string;
};

export const getContainersGeoJSON = (locations: ContainerLocationForMap[], selectedContainers: number[]) =>
  getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
    locations.map(loc =>
      getPointFeature(loc.lid, [loc.lo, loc.la], {
        id: loc.lid,
        clickable: true,
        layer: CONTAINERS_MAP_CONTAINERS_LAYER,
        color:
          loc.con && loc.con.length === 1
            ? CONTAINERS_MAP_ICONS_BY_ID[loc.con[0].t].color
            : CONTAINERS_MAP_ICONS_BY_ID[MULTIPLE].color,
        isSelected: selectedContainers.some(s => loc.con.map(c => c.id).includes(s)),
        icon:
          loc.con && loc.con.length === 1
            ? CONTAINERS_MAP_ICONS_BY_ID[loc.con[0].t].icon.id
            : CONTAINERS_MAP_ICONS_BY_ID[MULTIPLE].icon.id,
      }),
    ),
  );

export const getFacilitiesGeoJSON = (facilities: ContainerFacility[]) =>
  getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
    facilities.map(facility =>
      getPointFeature(facility.id, [facility.address.longitude, facility.address.latitude], {
        id: facility.id,
        clickable: true,
        layer: CONTAINERS_MAP_FACILITIES_LAYER,
        color: FACILITIES_MAP_COLORS_BY_ID[facility.facilitySubType.id].color,
        icon: FACILITIES_MAP_COLORS_BY_ID[facility.facilitySubType.id].iconType,
      }),
    ),
  );
