import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const AVAILABLE = 1;
export const IN_USE = 2;
export const UNAVAILABLE = 3;

const CONTAINER_STATUS_TYPES = mapKeys(
  [
    { id: AVAILABLE, name: translate('containers.containerStatusTypes.available') },
    { id: IN_USE, name: translate('containers.containerStatusTypes.inUse') },
    { id: UNAVAILABLE, name: translate('containers.containerStatusTypes.unavailable') },
  ],
  'id',
);

export default CONTAINER_STATUS_TYPES;
