import { CreateEditContainerForm } from '../forms';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  containerId?: number;
  closeModal: () => void;
  onSubmit: (values: any) => void;
}

const CreateEditContainerModal: React.FC<Props> = ({ containerId, closeModal, onSubmit }) => {
  const modalTitle = containerId ? translate('containers.editContainer') : translate('containers.createContainer');
  return (
    <Modal padding="medium" size="smallMedium" title={modalTitle} onClose={closeModal} overflow="visible">
      <CreateEditContainerForm containerId={containerId} closeModal={closeModal} onSubmit={onSubmit} />
    </Modal>
  );
};

export default CreateEditContainerModal;
