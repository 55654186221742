import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const EXCEPTION = 1;
export const MANUAL = 2;

export const CONFIRMATION_TYPES = mapKeys(
  [
    { id: EXCEPTION, name: translate('routes.confirmationTypes.exception') },
    { id: MANUAL, name: translate('routes.confirmationTypes.manual') },
  ],
  'id',
);
