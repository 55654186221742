import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { PanelSectionTitle } from '../../../core/components/styled';
import { HaulerProfileGrid, HaulerProfileGridColumn } from '../styled';
import { CompanyHeadquarters, HoursOfOperation, Contacts } from './companyInfoFormSections';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import companyInfoFormInitialValuesSelector from '../../services/companyInfoFormInitialValuesSelector';
import { AppState } from '../../../store';

interface PropsWithoutReduxForm extends RouteComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const CompanyInfoForm: React.FC<Props> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <HaulerProfileGrid>
      <HaulerProfileGridColumn padding="large medium" width="50%">
        <PanelSectionTitle>{translate('haulerProfile.companyHeadquarters')}</PanelSectionTitle>
        <CompanyHeadquarters />
      </HaulerProfileGridColumn>
      <HaulerProfileGridColumn padding="large medium" width="50%" lastColumn>
        <PanelSectionTitle>{translate('haulerProfile.hoursOfOperation')}</PanelSectionTitle>
        <HoursOfOperation />
      </HaulerProfileGridColumn>
    </HaulerProfileGrid>
    <Contacts />
  </form>
);

const mapStateToProps = (state: AppState) => ({
  initialValues: companyInfoFormInitialValuesSelector(state.haulerProfile.companyInfo.companyInfo),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'companyInfo',
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(CompanyInfoForm),
  ),
);
