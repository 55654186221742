import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorId } from '../../services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { checkIfVendorHasNetworkFile, loadFeatures, loadVendor, loadVendors } from '../../ducks';
import { loadPermissions, shouldLoadPermissions } from '../../../account/ducks/permissions';
import { loadUserById } from '../../../vendors/ducks';
import { PageLoading } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import AccountSettingsPage from './AccountSettingsPage';
import translate from '../../../core/services/translate';
import { loadFleetRouteInstances } from 'src/common/ducks';

const AccountSettingsPageResolver: React.FC = () => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const permissionsShallBeLoaded = useSelector(shouldLoadPermissions);
  const user = useSelector((state: AppState) => state.account.login.user);

  const loadDependencies = React.useCallback(() => {
    const dependencies = [loadVendors()(dispatch)];

    if (vendorId) {
      dependencies.push(
        checkIfVendorHasNetworkFile(vendorId)(dispatch),
        loadVendor(vendorId)(dispatch),
        loadFeatures(vendorId)(dispatch),
        loadFleetRouteInstances()(dispatch),
        loadUserById(vendorId, user.userId)(dispatch),
      );
    }

    if (permissionsShallBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    return Promise.all(dependencies);
  }, [vendorId, permissionsShallBeLoaded, user.userId, dispatch]);

  return (
    <>
      <DocumentTitle>{translate('common.applicationSettings')}</DocumentTitle>

      <TypedResolver successComponent={AccountSettingsPage} loadingComponent={PageLoading} resolve={loadDependencies} />
    </>
  );
};

export default AccountSettingsPageResolver;
