import styled from 'styled-components';

interface Props {
  src: string;
}

export const PaymentDetailsImage = styled.div<Props>`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 350px;
  width: 100%;
`;
