import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { HaulerLocation } from '../interfaces/haulerLocations';
import {
  loadVendorLocations as doLoadVendorLocations,
  loadHaulerLocationsForMapbox as doLoadHaulerLocationsForMapbox,
} from '../services/loadVendorLocations';

// Actions
const START_LOAD = 'dashboard/vendorLocations/START_LOAD';
export const COMPLETE_LOAD = 'dashboard/vendorLocations/COMPLETE_LOAD';
const FAIL_LOAD = 'dashboard/vendorLocations/FAIL_LOAD';

const START_LOAD_HAULER_LOCATIONS_NEW = 'dashboard/vendorLocations/START_LOAD_HAULER_LOCATIONS_NEW';
const COMPLETE_LOAD_HAULER_LOCATIONS_NEW = 'dashboard/vendorLocations/COMPLETE_LOAD_HAULER_LOCATIONS_NEW';
const FAIL_LOAD_HAULER_LOCATIONS_NEW = 'dashboard/vendorLocations/FAIL_LOAD_HAULER_LOCATIONS_NEW';

export const SET_VENDOR_LOCATIONS_TO_DISPLAY = 'dashboard/vendorLocations/SET_VENDOR_LOCATIONS_TO_DISPLAY';
const RESET = 'dashboard/vendorLocations/RESET';

interface State {
  isLoading: boolean;
  vendorLocations: HaulerLocation[];
  vendorLocationsForMapbox: HaulerLocation[];
  vendorLocationsToDisplay: HaulerLocation[];
}

// Initial state
const initialState: State = {
  isLoading: false,
  vendorLocations: [],
  vendorLocationsForMapbox: [],
  vendorLocationsToDisplay: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vendorLocations: action.vendorLocations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vendorLocations: [],
        },
      });

    case SET_VENDOR_LOCATIONS_TO_DISPLAY:
      return update(state, {
        $merge: {
          vendorLocationsToDisplay: action.vendorLocationsToDisplay,
        },
      });

    case START_LOAD_HAULER_LOCATIONS_NEW:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_HAULER_LOCATIONS_NEW:
      return update(state, {
        $merge: {
          isLoading: false,
          vendorLocationsForMapbox: action.vendorLocationsForMapbox,
        },
      });

    case FAIL_LOAD_HAULER_LOCATIONS_NEW:
      return update(state, {
        $merge: {
          isLoading: false,
          vendorLocationsForMapbox: [],
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vendorLocations: any) => ({
  type: COMPLETE_LOAD,
  vendorLocations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const startLoadHaulerLocationsNew = () => ({
  type: START_LOAD_HAULER_LOCATIONS_NEW,
});

export const completeLoadHaulerLocationsNew = (vendorLocationsForMapbox: any) => ({
  type: COMPLETE_LOAD_HAULER_LOCATIONS_NEW,
  vendorLocationsForMapbox,
});

export const failLoadHaulerLocationsNew = () => ({
  type: FAIL_LOAD_HAULER_LOCATIONS_NEW,
});

export const loadVendorLocations =
  (vendorId: number, vendorLocationTypes: any, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoad());
    return doLoadVendorLocations(vendorId, vendorLocationTypes)
      .then(vendorLocations => dispatch(completeLoad(vendorLocations)))
      .catch(() => dispatch(failLoad()));
  };

export const loadHaulerLocationsForMapbox = (vendorLocationTypes?: string) => (dispatch: Dispatch) => {
  dispatch(startLoadHaulerLocationsNew());
  const loadHaulerLocationsPromise = doLoadHaulerLocationsForMapbox(vendorLocationTypes);

  loadHaulerLocationsPromise
    .then(vendorLocationsForMapbox => dispatch(completeLoadHaulerLocationsNew(vendorLocationsForMapbox)))
    .catch(() => dispatch(failLoadHaulerLocationsNew()));

  return loadHaulerLocationsPromise;
};

export const setVendorLocationsToDisplay = (vendorLocationsToDisplay: any) => ({
  type: SET_VENDOR_LOCATIONS_TO_DISPLAY,
  vendorLocationsToDisplay,
});

export const resetVendorLocations = () => ({
  type: RESET,
});

// Selectors
const getVendorLocations = (vendorLocationsState: any) => get(vendorLocationsState, 'vendorLocations');
export const vendorLocationsSelector = createSelector(getVendorLocations, identity);
