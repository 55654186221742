import { reduce, identity, set, size } from 'lodash-es';
import { createSelector } from 'reselect';

import { DELIVERY } from '../constants/openDispatachesServices';

const getOpenDispatchesFormInitialValues = (openDispatchesState: any, rubiconDispatchesState: any[]) => {
  const dispatchState =
    (size(rubiconDispatchesState) > 0 &&
      rubiconDispatchesState.filter(disptach => disptach.pickupTypeId !== DELIVERY)) ||
    openDispatchesState;
  const opportunities = reduce(
    dispatchState,
    (
      initialValues,
      {
        rubiconPONbr,
        customerName,
        customerAddress,
        equipmentType,
        material,
        pickupType,
        requestedServiceDate,
        serviceDate,
        completedDate,
        customerPONbr,
        portalStatus,
        daysLeft,
        note,
        reasonCode,
        documentType,
        weightTicket,
        uom,
        fileDetails,
        svcId,
        svcOrd,
        lastUpdatedNote,
        lastUpdatedReasonCode,
        siteCode,
        siteName,
        isVendorModifiedNote,
      },
    ) =>
      set(initialValues, `settings${rubiconPONbr}${serviceDate}`, {
        customerName,
        customerAddress,
        equipmentType,
        material,
        pickupType,
        requestedServiceDate,
        serviceDate,
        completedDate: serviceDate,
        customerPONbr,
        portalStatus,
        daysLeft,
        note,
        reasonCode,
        documentType,
        weightTicket,
        uom,
        fileDetails,
        svcId,
        svcOrd,
        lastUpdatedNote,
        lastUpdatedReasonCode,
        siteCode,
        siteName,
        isVendorModifiedNote,
      }),
    {},
  );
  return {
    opportunities,
  };
};

const openDispatchesFormInitialValueSelector = createSelector(getOpenDispatchesFormInitialValues, identity);

export default openDispatchesFormInitialValueSelector;
