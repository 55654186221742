import React, { Fragment, PureComponent, ChangeEvent } from 'react';

import { arrayMove } from 'react-sortable-hoc';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { findIndex, map, size } from 'lodash-es';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
} from '../../../core/components/styled';
import { Dropdown, SortableTable } from '../../../core/components';
import { handleSortOrderChange, wasteAuditConfigurationIndexByTypeSelector } from '../../ducks/wasteAuditConfiguration';
import { PARTICIPATION, WASTE_AUDIT_CONFIGURATION_TYPES } from '../../constants';
import { TypedField } from '../../../core/components';
import { VendorWasteAuditConfigurationStatusTypes } from '../';
import { WasteAudit, WasteAuditConfiguration } from '../../interfaces/WasteAudit';
import { WasteAuditFormTableRow } from './';
import translate from '../../../core/services/translate';
import wasteAuditFormInitialValuesSelector from '../../services/wasteAuditFormInitialValuesSelector';

interface ComponentProps {
  formWasteAuditConfig: WasteAudit[];
  handleSortOrderChange: (vendorWasteAuditConfigurationTypes: any) => void;
  onCancel: (pristine: boolean) => void;
  vendorWasteAuditConfigurationIndex: number;
  wasteAuditConfiguration?: any[];
  wasteAuditConfigurationType?: string;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class WasteAuditForm extends PureComponent<Props> {
  onWasteAuditConfigurationTypeChange = (event: ChangeEvent, wasteAuditConfigurationType: string) => {
    const { wasteAuditConfiguration, change, vendorWasteAuditConfigurationIndex } = this.props;
    const selectedConfigurationTypeIndex = findIndex(
      wasteAuditConfiguration,
      ({ name }) => name === wasteAuditConfigurationType,
    );
    change(`wasteAuditConfiguration.${selectedConfigurationTypeIndex}.isEnabled`, true);
    change(`wasteAuditConfiguration.${vendorWasteAuditConfigurationIndex}.isEnabled`, false);
  };

  onSortOrderChange = (oldIndex: number, newIndex: number) => {
    const { handleSortOrderChange, formWasteAuditConfig, change } = this.props;
    const reorderedWasteAuditConfiguration = arrayMove(
      formWasteAuditConfig[1].vendorWasteAuditConfigurationTypes,
      oldIndex,
      newIndex,
    );
    const vendorWasteAuditConfigurationTypes = this.resetOrderNumbers(reorderedWasteAuditConfiguration);
    change('wasteAuditConfiguration[1].vendorWasteAuditConfigurationTypes', vendorWasteAuditConfigurationTypes);
    handleSortOrderChange(vendorWasteAuditConfigurationTypes);
  };

  resetOrderNumbers = (wasteAuditConfigurations: WasteAuditConfiguration[]) =>
    map(wasteAuditConfigurations, (wasteAuditConfiguration, index) => ({
      ...wasteAuditConfiguration,
      wasteAuditTypeOptionOrderNo: index + 1,
    }));

  render() {
    const {
      vendorWasteAuditConfigurationIndex,
      wasteAuditConfigurationType,
      wasteAuditConfiguration,
      handleSubmit,
      onCancel,
      pristine,
      handleSortOrderChange,
    } = this.props;

    const wasteAuditConfigurationOptions = map(WASTE_AUDIT_CONFIGURATION_TYPES, ({ id, name }) => ({
      label: name,
      value: id,
    }));

    const wasteAuditConfigurationTableCells = [
      { name: 'description', label: translate('common.description'), width: '60%' },
      { name: 'isActive', label: translate('common.active'), width: '20%' },
      { name: 'requirePicture', label: translate('vendors.requirePicture'), width: '20%', align: 'right' },
    ];

    return (
      <Fragment>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit}>
          <PanelSection vertical>
            <Grid centered>
              <GridColumn size="5/12">
                <TypedField
                  name="wasteAuditConfigurationType"
                  component={Dropdown}
                  onChange={this.onWasteAuditConfigurationTypeChange}
                  props={{
                    margin: 'medium',
                    options: wasteAuditConfigurationOptions,
                    label: translate('vendors.wasteAuditConfigurationType'),
                  }}
                />
              </GridColumn>
            </Grid>
            {wasteAuditConfigurationType !== PARTICIPATION && <VendorWasteAuditConfigurationStatusTypes />}
            {wasteAuditConfiguration &&
              !!wasteAuditConfiguration[vendorWasteAuditConfigurationIndex] &&
              !!size(
                wasteAuditConfiguration[vendorWasteAuditConfigurationIndex].vendorWasteAuditConfigurationTypes,
              ) && (
                <SortableTable
                  cells={wasteAuditConfigurationTableCells}
                  rows={wasteAuditConfiguration[vendorWasteAuditConfigurationIndex].vendorWasteAuditConfigurationTypes}
                  rowComponent={WasteAuditFormTableRow}
                  rowProps={{ handleSortOrderChange, vendorWasteAuditConfigurationIndex, wasteAuditConfiguration }}
                  sort={this.onSortOrderChange}
                />
              )}

            {wasteAuditConfiguration &&
              !!wasteAuditConfiguration[vendorWasteAuditConfigurationIndex] &&
              !size(wasteAuditConfiguration[vendorWasteAuditConfigurationIndex].vendorWasteAuditConfigurationTypes) &&
              (wasteAuditConfigurationType === PARTICIPATION ||
                !size(
                  wasteAuditConfiguration[vendorWasteAuditConfigurationIndex].vendorWasteAuditConfigurationStatusTypes,
                )) && <Message padding="sMedium">{translate('vendors.noWasteAuditConfiguration')}</Message>}
            <Grid centered>
              <GridColumn size="5/12">
                <ButtonSet margin="large no">
                  <Button type="submit" color="primary">
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            </Grid>
          </PanelSection>
        </form>
      </Fragment>
    );
  }
}

const formSelector = formValueSelector('wasteAudit');
const mapStateToProps = (state: AppState) => {
  const { wasteAuditConfiguration, wasteAuditConfigurationType } = wasteAuditFormInitialValuesSelector(
    state.vendors.wasteAuditConfiguration,
  ) as any;
  const vendorWasteAuditConfigurationIndex = wasteAuditConfigurationIndexByTypeSelector(
    state.vendors.wasteAuditConfiguration,
    formSelector(state, 'wasteAuditConfigurationType') || wasteAuditConfigurationType,
  );
  return {
    vendorWasteAuditConfigurationIndex: vendorWasteAuditConfigurationIndex || 0,
    wasteAuditConfigurationType: formSelector(state, 'wasteAuditConfigurationType') || wasteAuditConfigurationType,
    wasteAuditConfiguration: state.vendors.wasteAuditConfiguration.wasteAuditConfiguration,
    formWasteAuditConfig:
      (state.form.wasteAudit && state.form.wasteAudit.values?.wasteAuditConfiguration) ||
      state.vendors.wasteAuditConfiguration.wasteAuditConfiguration,
    initialValues: {
      wasteAuditConfiguration,
      wasteAuditConfigurationType,
    },
  };
};

const mapDispatchToProps = {
  handleSortOrderChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    form: 'wasteAudit',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(WasteAuditForm),
);
