import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadRouteSegments as doLoadRouteSegments,
  loadRouteSegmentsForDashboard as doLoadRouteSegmentsForDashboard,
} from '../services/routeSegments';
import { RouteSegment, RouteSegmentsQueryParams } from '../interfaces/RouteSegment';

const START_LOAD = 'routes/routeSegments/START_LOAD';
const COMPLETE_LOAD = 'routes/routeSegments/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeSegments/FAIL_LOAD';
const RESET = 'routes/routeSegments/RESET';

interface State {
  isLoaded: boolean;
  isLoading: boolean;
  routeSegments: RouteSegment[];
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  routeSegments: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoaded: true,
          isLoading: false,
          routeSegments: action.routeSegments,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeSegments: [],
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeSegments: RouteSegment[]) => ({
  type: COMPLETE_LOAD,
  routeSegments,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteSegments = (params: RouteSegmentsQueryParams) => (dispatch: Dispatch) => {
  !params.noLoadingIndicator && dispatch(startLoad());
  const loadRouteSegmentsPromise = params.isForDashboard
    ? doLoadRouteSegmentsForDashboard(params)
    : doLoadRouteSegments(params);
  loadRouteSegmentsPromise
    .then(routeSegments => dispatch(completeLoad(routeSegments)))
    .catch(() => dispatch(failLoad()));
  return loadRouteSegmentsPromise;
};

export const resetRouteSegments = () => ({
  type: RESET,
});
