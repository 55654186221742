import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { SOSAlertSettings, SOSAlertSettingsState } from '../interfaces/SOSAlertSettings';
import {
  loadSOSAlertSettings as doLoadSOSAlertSettings,
  saveSOSAlertSettings as doSaveSOSAlertSettings,
} from '../services/sosAlertSettings';

// Actions
const START_LOAD = 'vendors/sosAlertSettings/START_LOAD';
const COMPLETE_LOAD = 'vendors/sosAlertSettings/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/sosAlertSettings/FAIL_LOAD';
const START_SAVE = 'vendors/sosAlertSettings/START_SAVE';
const COMPLETE_SAVE = 'vendors/sosAlertSettings/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/sosAlertSettings/FAIL_SAVE';
const RESET = 'vendors/sosAlertSettings/RESET';

// Initial state
const initialState: SOSAlertSettingsState = {
  isLoading: false,
  isSaving: false,
  sosAlertSettings: {} as SOSAlertSettings,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          sosAlertSettings: action.sosAlertSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (sosAlertSettings: SOSAlertSettings) => ({
  type: COMPLETE_LOAD,
  sosAlertSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadSOSAlertSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadSOSAlertSettingPromise = doLoadSOSAlertSettings(vendorId);
  loadSOSAlertSettingPromise
    .then(sosAlertSettings => dispatch(completeLoad(sosAlertSettings)))
    .catch(() => dispatch(failLoad()));

  return loadSOSAlertSettingPromise;
};

export const saveSOSAlertSettings = (sosAlertSettings: SOSAlertSettings) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const saveSOSAlertSettingPromise = doSaveSOSAlertSettings(sosAlertSettings);
  saveSOSAlertSettingPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveSOSAlertSettingPromise;
};

export const resetSOSAlertSetting = () => ({
  type: RESET,
});
