import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { currentVendorGusIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { getQueryParams } from 'src/utils/services/queryParams';
import { PageLoading } from 'src/common/components/styled';
import { loadRubiconServicesOptions } from 'src/customers/ducks/rubiconServicesOptions';
import { loadEndDestinations, loadRubiconServices } from 'src/customers/ducks/rubiconServices';
import { LIMIT_PER_PAGE } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { TypedResolver } from 'src/core/components';

import RubiconServicesPage from './RubiconServicesPage';
import { loadEquipmentTypes } from 'src/common/ducks';

interface Props extends RouteComponentProps {}

const RubiconServicesPageResolver: React.FC<Props> = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const login = useSelector((state: AppState) => state.account.login);
  const defaultVendor = useSelector((state: AppState) => state.vendors.defaultVendor);
  const gusId = currentVendorGusIdSelector(login, defaultVendor);
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector((state: AppState) => state.account.login.user.userId);

  const {
    customerName,
    destinationId,
    isTemp,
    startDate,
    endDate,
    divisionId,
    serviceId,
    equipmentType,
    materialType,
    page,
    servciceStatusIds,
  } = getQueryParams(search);

  const loadDependencies = () => {
    return Promise.all([
      loadEndDestinations(vendorId, userId)(dispatch),
      loadEquipmentTypes()(dispatch),
      loadRubiconServicesOptions(gusId)(dispatch),
      loadRubiconServices(
        vendorId,
        userId,
        customerName,
        divisionId,
        serviceId,
        equipmentType,
        materialType,
        servciceStatusIds,
        destinationId,
        isTemp,
        startDate,
        endDate,
        page,
        LIMIT_PER_PAGE,
      )(dispatch),
    ]);
  };

  return (
    <>
      <DocumentTitle>{translate('customers.rubiconServices')}</DocumentTitle>
      <TypedResolver
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={RubiconServicesPage}
        redirectOnError="/dashboard"
      />
    </>
  );
};

export default withRouter(RubiconServicesPageResolver);
