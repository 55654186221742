import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import {
  loadFacilityRateTypes as doLoadFacilityRateTypes,
  loadFacilityTypes as doLoadFacilityTypes,
} from '../services/facilities';

// Actions
const START_LOAD = 'fleet/facilityTypes/START_LOAD';
export const COMPLETE_LOAD = 'fleet/facilityTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/facilityTypes/FAIL_LOAD';
const START_RATES_LOAD = 'fleet/facilityRateTypes/START_RATES_LOAD';
export const COMPLETE_RATES_LOAD = 'fleet/facilityRateTypes/COMPLETE_RATES_LOAD';
const FAIL_RATES_LOAD = 'fleet/facilityRateTypes/FAIL_RATES_LOAD';

// Initial state =
const initialState = {
  isLoading: false,
  facilityTypes: [],
  facilityRateTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          facilityTypes: action.facilityTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          facilityRateTypes: [],
        },
      });
    case START_RATES_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_RATES_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          facilityRateTypes: action.facilityRateTypes,
        },
      });

    case FAIL_RATES_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          facilityRateTypes: [],
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (facilityTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  facilityTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startRatesLoad = () => ({
  type: START_RATES_LOAD,
});

const completeRatesLoad = (facilityRateTypes: any) => ({
  type: COMPLETE_RATES_LOAD,
  facilityRateTypes,
});

const failRatesLoad = () => ({
  type: FAIL_RATES_LOAD,
});

export const loadFacilityTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadFacilityTypes()
    .then(facilityTypes => dispatch(completeLoad(facilityTypes)))
    .catch(() => dispatch(failLoad()));
};

export const loadFacilityRateTypes = () => (dispatch: Dispatch) => {
  dispatch(startRatesLoad());
  return doLoadFacilityRateTypes()
    .then(facilityRateTypes => dispatch(completeRatesLoad(facilityRateTypes)))
    .catch(() => dispatch(failRatesLoad()));
};
