import { http } from '../../core/services/http';
import { transformLoadAdhocServiceHistory } from './transformServiceHistory';
import { transformSearchVendorLocations } from './transformAdhocServiceHistory';
import { TODAY_FORMATTED, SEVEN_DAYS_BEFORE_TODAY } from '../../core/constants';

export const loadAdhocServiceHistory = (
  vendorId: number,
  locationId: string,
  pickupStatusTypeIds: string,
  startDate: Date | string = SEVEN_DAYS_BEFORE_TODAY,
  endDate: Date | string = TODAY_FORMATTED,
  workOrderNumber: string | undefined,
) =>
  http
    .get(`vendors/${vendorId}/customers/adhoc-service-history`, {
      params: {
        locationId,
        workOrderNumber,
        pickupStatusTypeIds,
        startDate,
        endDate,
      },
    })
    .then(response => transformLoadAdhocServiceHistory(response.data));

const searchVendorLocations = (vendorId: number, searchString?: string) =>
  http
    .get(`/vendors/${vendorId}/ad-hoc-locations/quick-search`, { params: { searchString } })
    .then(response => transformSearchVendorLocations(response.data));

export default searchVendorLocations;
