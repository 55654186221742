import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { addLastLocation } from '../ducks';

interface Props extends RouteComponentProps {
  children: any;
  addLastLocation: (pathname: any, url: any) => void;
}

class LastLocationsProvider extends PureComponent<Props> {
  componentDidMount() {
    const {
      history,
      location: { pathname, search },
      addLastLocation,
    } = this.props;
    addLastLocation(pathname, `${pathname}${search}`);
    history.listen(({ pathname, search }) => addLastLocation(pathname, `${pathname}${search}`));
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = { addLastLocation };

export default withRouter(connect(undefined, mapDispatchToProps)(LastLocationsProvider));
