import moment from 'moment';
import { groupBy, map, size } from 'lodash-es';
import {
  CANCELLED,
  CONFIRM_SERVICE,
  ON_SCHEDULE,
  PENDING,
  REQUESTED,
  RUBICON_DISPATCHES_STATUS,
  SCHEDULED,
  SMART_FILTER_OPTIONS,
  WORK_ORDER_COMPLETE,
} from '../constants/rubiconDisptachesStatus';
import { TONS, WEIGHT_TICKET } from '../constants';
import { validDaysThreshold } from '../../opportunity/services/transformOpportunity';
import translate from '../../core/services/translate';

export const transformAgreement = (a: any) => ({
  ...a,
  deliveryDate: moment(a.deliveryDate).format('MM/DD/YYYY'),
});

export const transformSmartFilterOptions = (rubiconDispatches: any) => {
  const today = moment().format('MM/DD/YYYY');
  const dispatchesGroupByStatusId = groupBy(rubiconDispatches, ({ statusV2 }) => statusV2.id);
  const smartFilterOptions = map(SMART_FILTER_OPTIONS, ({ id, name }) => {
    let workOrderCount = 0;
    let overdueCount = 0;

    if (id === REQUESTED) {
      const requestedList = rubiconDispatches.filter((x: any) => x.statusV2.id === REQUESTED);
      workOrderCount = size(requestedList);
      overdueCount = size(requestedList) && size(requestedList.filter((x: any) => x.action.isRequired));
    } else if (id === ON_SCHEDULE) {
      const onSheduleList = rubiconDispatches.filter((x: any) => {
        const scheduledDate = moment(x.dates.scheduledDate).format('MM/DD/YYYY');
        return (x.statusV2.id === SCHEDULED || x.statusV2.id === PENDING) && moment(scheduledDate).isAfter(today);
      });
      workOrderCount = size(onSheduleList);
      overdueCount = size(onSheduleList) && size(onSheduleList.filter((x: any) => x.action.isRequired));
    } else if (id === CONFIRM_SERVICE) {
      const confirmServiceList = rubiconDispatches.filter((x: any) => {
        const scheduledDate = moment(x.dates.scheduledDate).format('MM/DD/YYYY');
        return (
          (x.statusV2.id === SCHEDULED || x.statusV2.id === PENDING) && moment(scheduledDate).isSameOrBefore(today)
        );
      });
      workOrderCount = size(confirmServiceList);
      overdueCount = size(confirmServiceList) && size(confirmServiceList.filter((x: any) => x.action.isRequired));
    } else if (id === WORK_ORDER_COMPLETE) {
      const completedAndCancelledList = rubiconDispatches.filter(
        (x: any) => x.statusV2.id === WORK_ORDER_COMPLETE || x.statusV2.id === CANCELLED,
      );
      workOrderCount = size(completedAndCancelledList);
      overdueCount =
        size(completedAndCancelledList) && size(completedAndCancelledList.filter((x: any) => x.action.isRequired));
    } else {
      workOrderCount = size(dispatchesGroupByStatusId[id]);
      overdueCount =
        size(dispatchesGroupByStatusId[id]) &&
        size(dispatchesGroupByStatusId[id].filter((x: any) => x.action.isRequired));
    }

    return {
      label: name,
      value: id,
      count: workOrderCount,
      overdue: overdueCount,
    };
  });
  return smartFilterOptions;
};

export const transformDispatches = (rubiconDispatches: any) =>
  map(rubiconDispatches, (s, i) => {
    return {
      action: s.action,
      actionRequired: s.action && s.action.isRequired,
      afterDeliveryDate: s.agreement && validDaysThreshold(s.agreement.deliveryDate, 1),
      agreement: s.agreement && transformAgreement(s.agreement),
      beforeDeliveryDate: s.agreement && moment(s.agreement.deliveryDate).format('MM/DD/YYYY'),
      businessType: s.businessType,
      caseId: s.caseId,
      completedDate: s.dates.completedDate && moment(s.dates.completedDate).format('MM/DD/YYYY'),
      customerAddress: s.customerAddress,
      customerName: s.customerName,
      customerPONbr: null,
      dates: s.dates,
      daysInWeekSchedule: s.daysInWeekSchedule,
      daysLeft: 0,
      daysOverDue: s.action && s.action.numberOfDaysOverdue,
      defaultUom: s.defaultUom,
      deliveryDaysThreshold: s.deliveryDaysThreshold,
      documentType: WEIGHT_TICKET,
      equipment: s.equipment,
      equipmentType: s.equipment && s.equipment.type,
      fileDetails: s.documents,
      hasVendorNotes: s.hasVendorNotes,
      hasWorkOrdersNotes: s.hasWorkOrdersNotes,
      isActionRequired: s.isActionRequired,
      isRollOff: s.isRollOff,
      isTemp: s.isTemp,
      isVendorModifiedNote: s.isVendorModifiedNote,
      itemId: s.itemId,
      lastUpdatedNote: s.note,
      lastUpdatedReasonCode: null,
      latestDate: s.dates.latestDate && moment(s.dates.latestDate).format('MM/DD/YYYY'),
      material: s.equipment && s.equipment.wasteMaterial,
      note: s.note,
      numberOfHoursLeft: s.action && s.action.numberOfHoursLeft,
      options: s.options,
      pickupType: s.pickupType.name,
      pickupTypeId: s.pickupType.id,
      pickupTypeName: s.pickupType.name,
      pickupTypeTechnicalName: s.pickupType.technicalName,
      portalStatus: null,
      reasonCode: s.reasonCode,
      requestedServiceDate: s.dates.requestedDate && moment(s.dates.requestedDate).format('MM/DD/YYYY'),
      rubiconPONbr: s.workorderNumber,
      serviceDate: s.dates.scheduledDate && moment(s.dates.scheduledDate).format('MM/DD/YYYY'),
      serviceNotes: s.serviceNotes,
      siteCode: s.siteCode,
      siteName: s.siteName,
      status: s.status,
      statusId: s.statusV2 && s.statusV2.id,
      statusName: s.statusV2 && RUBICON_DISPATCHES_STATUS[s.statusV2.id].name,
      svcId: s.svcId,
      svcOrd: s.svcOrd,
      type: s.type,
      uom: s.defaultUom || TONS,
      vendID: s.vendID,
      vendorName: s.vendorName,
      weightTicket: s.weightTicket,
      workorderNumber: s.workorderNumber,
      workOrderType: s.workOrderType,
    };
  });

export const transformEquipments = (equipmentSubTypes: any[]) =>
  map(equipmentSubTypes, equipmentSubType => ({
    id: equipmentSubType,
    name: translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentSubType}`),
  }));

export const transformRubiconDispatches = (rubiconDispatches: any) => ({
  ...rubiconDispatches,
  divisions: rubiconDispatches.divisions,
  equipmentSubTypes: transformEquipments(rubiconDispatches.equipmentSubTypes),
  rubiconDispatches: transformDispatches(rubiconDispatches.dispatches),
  smartFilterOptions: transformSmartFilterOptions(rubiconDispatches.dispatches),
});
