import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getIconTypeForVehiclesListPosition } from 'src/common/constants/vehicleIcons';
import { DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { RouteVehiclePosition } from 'src/dashboard/interfaces/routesData';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';

export type VehiclePositionFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getVehiclesListPositionsGeoJSON = (vehiclePositions: (VehiclePosition | RouteVehiclePosition)[]) =>
  getFeatureCollection<GeoJSON.Point, VehiclePositionFeatureProperties>(
    vehiclePositions.map(position => {
      const id = 'id' in position ? position.id : position.vehicleId;

      return getPointFeature(id, [position.longitude, position.latitude], {
        id,
        clickable: true,
        layer: DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER,
        icon: getIconTypeForVehiclesListPosition(position)?.id,
        source: 'vehicle',
      });
    }),
  );
