import { push } from 'connected-react-router';
import { filter, forEach, includes, isEqual, map, orderBy, size } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { Facility } from 'src/common/interfaces/Facility';
import { getExcludedFiltersIds } from 'src/common/utils/filters';
import { DropdownOption } from 'src/core/components/Dropdown';
import {
  Button,
  ButtonIcon,
  Grid,
  GridColumn,
  IconButtonIcon,
  Panel,
  PanelSectionGroup,
  Text,
} from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { RoutePlannerVehicleTypesForm } from 'src/routes/components/forms';
import DailyAndReoccurringFilterByModal from 'src/routes/components/modals/routePlanner/DailyAndReoccurringFilterByModal';
import {
  PillClearWrapper,
  PillContainerWrapper,
} from 'src/routes/components/styled/RoutePlanner/RoutePlannerDailyAndReoccurring';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { getRoutePlannerFilters } from 'src/routes/services/routePlannerFilters';
import { setRoutePlannerPersistentFilters } from 'src/routes/services/routePlannerFilterStorage';
import { getRoutePlannerOptions } from 'src/routes/services/routePlannerVehicleTypesFormInitialValuesSelector';
import { createUrl } from 'src/utils/services/queryParams';
import PillAttribute from './PillAttribute';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';
import { Box } from 'src/core/components/styled/Box';
import { Group } from 'src/routes/interfaces/Groups';
import { RouteTemplateStatus } from 'src/routes/components/styled/RouteTemplateBuilderMap';

const DailyAndReoccurringFiltersSection: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [isFilterByModalOpen, setIsFilterByModalOpen] = useState<boolean>(false);
  const isDailyRoutesPage = pathname.includes('daily');

  const filters = getRoutePlannerFilters(search);

  const [routePlannerFilters, setRoutePlannerFilters] = useState(filters);

  const openFilterByModal = () => {
    setIsFilterByModalOpen(true);
  };
  const closeFilterByModal = () => {
    setIsFilterByModalOpen(false);
  };

  const materialTypes = useSelector(state => state.common.wasteTypes.wasteTypes);
  const supervisors = useSelector(state => state.routes.supervisors.supervisors);
  const serviceZones = useSelector(state => state.routes.serviceZones.serviceZones) as unknown as ServiceZone[];
  const vehicleTypes = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const filterPreferences = useSelector(state => state.common.filters.filters);
  const facilities = useSelector(state => state.fleet.facilities.operationalFacilities);
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const groups = useSelector(state => state.routes.groups.groups);

  const excludedServiceZones = getExcludedFiltersIds(serviceZones, filterPreferences, FILTER_SERVICE_ZONE_ID);
  const excludedSupervisors = getExcludedFiltersIds(supervisors, filterPreferences, FILTER_SUPERVISOR_ID);

  const materialTypesOptions = useMemo(
    () =>
      map(materialTypes, (materialType: TechnicalType) => ({
        label: materialType.name,
        value: materialType.id,
      })),
    [materialTypes],
  );

  const supervisorsOptions = useMemo(() => {
    const options = map(
      filter(supervisors, s => !excludedSupervisors.includes(s.id)),
      (supervisor: Supervisor) => ({
        label: supervisor.firstName + ' ' + supervisor.lastName,
        value: supervisor.id,
      }),
    );
    options.unshift({ label: translate('routes.none'), value: NONE_ID });

    return options;
  }, [supervisors, excludedSupervisors]);

  const serviceZonesOptions = useMemo(() => {
    const options = map(
      filter(serviceZones, s => !excludedServiceZones.includes(s.id)),
      (serviceZone: ServiceZone) => ({
        label: serviceZone.name,
        value: serviceZone.id,
      }),
    );
    options.unshift({ label: translate('routes.none'), value: NONE_ID });
    return options;
  }, [serviceZones, excludedServiceZones]);

  const groupIdsOptions = useMemo(() => {
    const options = map(orderBy(groups, ['isActive', 'groupName'], ['desc', 'asc']), (group: Group) => ({
      label: group.groupName,
      value: group.id,
      renderCustomOption: () => (
        <>
          {group.groupName}

          {!group.isActive && (
            <RouteTemplateStatus neutral margin="xxSmall no xxSmall xxSmall">
              {translate('common.inactive')}
            </RouteTemplateStatus>
          )}
        </>
      ),
    }));
    options.unshift({
      label: translate('routes.none'),
      value: NONE_ID,
      renderCustomOption: () => <>{translate('routes.none')}</>,
    });
    return options;
  }, [groups]);

  const facilityOptions = useMemo(
    () =>
      map(facilities, (facility: Facility) => ({
        label: facility.name,
        value: facility.locationId,
      })),
    [facilities],
  );

  const routeStatusesOptions = useMemo(
    () => [
      { label: translate('common.inactive'), value: 0 },
      { label: translate('common.active'), value: 1 },
    ],
    [],
  );

  const filtersOptions = {
    materialTypesOptions,
    supervisorsOptions,
    serviceZonesOptions,
    facilityOptions,
    routeStatusesOptions: isDailyRoutesPage ? [] : routeStatusesOptions,
    groupIdsOptions,
  };

  useEffect(() => {
    if (!isEqual(routePlannerFilters, filters)) {
      setRoutePlannerFilters(filters);
    }
  }, [filters, routePlannerFilters]);

  const { facilityTypeIds, materialTypeIds, routeStatusesIds, serviceZonesIds, supervisorsIds, groupIds } =
    routePlannerFilters;

  const mergedSelectedFilters = useMemo(
    () => [
      ...map(
        filter(materialTypesOptions, (materialTypesOption: DropdownOption) => {
          return includes(materialTypeIds, materialTypesOption.value);
        }),
        (filterOption: DropdownOption) => ({ ...filterOption, filterName: 'materialTypeIds' }),
      ),

      ...map(
        filter(supervisorsOptions, (supervisorOption: DropdownOption) => {
          return includes(supervisorsIds, supervisorOption.value) && supervisorEnabled;
        }),
        (filterOption: DropdownOption) => ({ ...filterOption, filterName: 'supervisorsIds' }),
      ),

      ...map(
        filter(serviceZonesOptions, (serviceZonesOption: DropdownOption) => {
          return includes(serviceZonesIds, serviceZonesOption.value);
        }),
        (filterOption: DropdownOption) => ({ ...filterOption, filterName: 'serviceZonesIds' }),
      ),

      ...map(
        filter(facilityOptions, (facilityOption: DropdownOption) => {
          return includes(facilityTypeIds, facilityOption.value);
        }),
        (filterOption: DropdownOption) => ({ ...filterOption, filterName: 'facilityTypeIds' }),
      ),

      ...map(
        filter(routeStatusesOptions, (routeStatusesOption: DropdownOption) => {
          return includes(routeStatusesIds, routeStatusesOption.value) && !isDailyRoutesPage;
        }),
        (filterOption: DropdownOption) => ({ ...filterOption, filterName: 'routeStatusesIds' }),
      ),

      ...map(
        filter(groupIdsOptions, (groupIdsOption: DropdownOption) => {
          return includes(groupIds, groupIdsOption.value);
        }),
        (filterOption: DropdownOption) => ({ ...filterOption, filterName: 'groupIds' }),
      ),
    ],
    [
      materialTypesOptions,
      supervisorsOptions,
      serviceZonesOptions,
      facilityOptions,
      routeStatusesOptions,
      groupIdsOptions,
      materialTypeIds,
      supervisorsIds,
      supervisorEnabled,
      serviceZonesIds,
      facilityTypeIds,
      routeStatusesIds,
      isDailyRoutesPage,
      groupIds,
    ],
  );

  const removeFilterPill = useCallback(
    (filter: any) => {
      let filterInitialValues = routePlannerFilters;
      const updatedFilters = forEach(filterInitialValues, (_o, key, value) => {
        if (filter.filterName === key && value[key]?.includes(filter.value)) {
          const newValue = value[key]?.filter((v: number) => v !== filter.value) as number[];
          return (filterInitialValues[key] = newValue);
        }

        return (filterInitialValues[key] = value[key]);
      });

      const { materialTypeIds, supervisorsIds, serviceZonesIds, facilityTypeIds, routeStatusesIds, groupIds } =
        updatedFilters;
      let filtersToPass = {};
      if (updatedFilters) {
        filtersToPass = {
          materialTypeIds: materialTypeIds?.length ? materialTypeIds?.toString() : '',
          supervisorsIds: supervisorsIds?.length && supervisorEnabled ? supervisorsIds?.toString() : '',
          serviceZonesIds: serviceZonesIds?.length ? serviceZonesIds?.toString() : '',
          facilityTypeIds: facilityTypeIds?.length ? facilityTypeIds?.toString() : '',
          routeStatusesIds: !isDailyRoutesPage && routeStatusesIds?.length ? routeStatusesIds?.toString() : '',
          groupIds: groupIds?.length ? groupIds?.toString() : '',
        };
      }

      setRoutePlannerFilters(updatedFilters);
      setRoutePlannerPersistentFilters(filtersToPass);
      dispatch(push(createUrl(pathname, search, filtersToPass)));
    },
    [routePlannerFilters, dispatch, pathname, search, supervisorEnabled, isDailyRoutesPage],
  );

  const handleRemoveAllFilters = useCallback(() => {
    let filtersToPass = {};
    if (routePlannerFilters) {
      filtersToPass = {
        materialTypeIds: '',
        supervisorsIds: '',
        serviceZonesIds: '',
        facilityTypeIds: '',
        routeStatusesIds: '',
        groupIds: '',
      };
    }
    setRoutePlannerFilters(filtersToPass);
    setRoutePlannerPersistentFilters(filtersToPass);
    dispatch(push(createUrl(pathname, search, filtersToPass)));
  }, [routePlannerFilters, dispatch, pathname, search]);

  const filterPills = useMemo(() => {
    return map(mergedSelectedFilters, (selectedFilter: any, i: number) => {
      return (
        <PillAttribute
          key={i}
          title={selectedFilter.renderCustomOption ? selectedFilter.renderCustomOption() : selectedFilter.label}
          onRemoveAttribute={() => removeFilterPill(selectedFilter)}
          margin="xxSmall xSmall xxSmall no"
        />
      );
    });
  }, [mergedSelectedFilters, removeFilterPill]);

  const initialValues = useMemo(() => {
    return getRoutePlannerOptions(vehicleTypes, filterPreferences, search);
  }, [filterPreferences, vehicleTypes, search]);

  return (
    <>
      <PanelSectionGroup>
        <Panel padding="small sMedium no sMedium">
          <Grid>
            <GridColumn padding="no" size="10/12">
              <Grid>
                {!isDailyRoutesPage && (
                  <GridColumn width="fit-content">
                    <RoutePlannerVehicleTypesForm initialValues={initialValues} />
                  </GridColumn>
                )}
                <GridColumn size="7/12" verticalAlign="flex-start">
                  {isDailyRoutesPage && <RoutePlannerVehicleTypesForm initialValues={initialValues} />}
                  <Button
                    onClick={openFilterByModal}
                    margin="small no no no"
                    padding="xSmall sMedium"
                    line
                    color="black"
                    borderColor="gray"
                  >
                    <ButtonIcon verticalAlignCenter size="xLarge" icon="filter" />
                    {translate('common.filterBy')}
                    <ButtonIcon
                      margin="no no no lMedium"
                      verticalAlignCenter
                      color="primary"
                      size="medium"
                      icon="arrowDown"
                    />
                  </Button>
                </GridColumn>
              </Grid>
            </GridColumn>
            <GridColumn align="right" padding="no" size="3/12">
              <Button
                padding="xSmall sMedium"
                margin="small no no no"
                line
                color="black"
                borderColor="gray"
                onClick={() =>
                  dispatch(
                    push({
                      pathname: isDailyRoutesPage
                        ? '/routes/route-planner/daily-routes/settings'
                        : '/routes/route-planner/reoccurring-routes/settings',
                    }),
                  )
                }
              >
                <ButtonIcon verticalAlignCenter size="xLarge" icon="settingsWithoutCircle" />
                {translate('routes.planner.userPreferences')}
              </Button>
            </GridColumn>
          </Grid>
        </Panel>
        {!!mergedSelectedFilters.length && (
          <Panel>
            <Box display="flex">
              <PillContainerWrapper>{filterPills}</PillContainerWrapper>
              <PillClearWrapper>
                {size(filterPills) > 1 && (
                  <Button size="xSmall" color="alert" line onClick={handleRemoveAllFilters}>
                    <Box display="flex" alignItems="center">
                      <IconButtonIcon size="large" icon="cancelClear" color="alert" customViewBox="0 -2 32 32" />
                      <Text color="alert" weight="normal">
                        {translate('tooltips.clear')}
                      </Text>
                    </Box>
                  </Button>
                )}
              </PillClearWrapper>
            </Box>
          </Panel>
        )}
      </PanelSectionGroup>
      {isFilterByModalOpen && (
        <DailyAndReoccurringFilterByModal
          filtersOptions={filtersOptions}
          initialValues={routePlannerFilters}
          closeModal={closeFilterByModal}
        />
      )}
    </>
  );
};

export default DailyAndReoccurringFiltersSection;
