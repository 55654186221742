import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { ActionButtonTooltip } from 'src/core/components';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { dateAndTime } from 'src/utils/services/formatter';
import { downloadReport } from '../../services/opportunities';
import { TableActionButton, TableRow, TableCell, Text } from 'src/core/components/styled';

interface Props {
  createdDate: Date | string;
  id: number;
  importedFile: string;
  failedCount: number;
  totalRecords: number;
  successfulCount: number;
  status: string;
}

export const OpenBidsUploadModalTableRow: React.FC<Props> = ({
  createdDate: importDate,
  id,
  failedCount,
  importedFile,
  status,
  successfulCount,
  totalRecords,
}) => {
  const vendorId = useSelector((state: AppState) => currentVendorId(state));
  const exportReport = (id: number) => {
    downloadReport(id, vendorId);
  };
  return (
    <TableRow>
      <TableCell width={'28%'}>
        <Text>{importedFile}</Text>
      </TableCell>
      <TableCell width={'14%'}>
        <Text>{status}</Text>
      </TableCell>
      <TableCell width={'13%'}>
        <Text>{totalRecords}</Text>
      </TableCell>
      <TableCell width={'11%'}>
        <Text>{successfulCount}</Text>
      </TableCell>
      <TableCell width={'8%'}>
        <Text>{failedCount}</Text>
      </TableCell>
      <TableCell width="10%">
        {!!failedCount && (
          <TableActionButton onClick={() => exportReport(id)}>
            <ActionButtonTooltip size="sMedium" icon="download" tooltip="downloadReport" />
          </TableActionButton>
        )}
      </TableCell>
      <TableCell width={'16%'}>
        <Text>{dateAndTime(importDate)}</Text>
      </TableCell>
    </TableRow>
  );
};
