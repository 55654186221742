import qs from 'qs';
import FileSaver from 'file-saver';
import { AxiosInstance } from 'axios';

import { http, httpInsightsReporting } from '../../core/services/http';
import { EXCEL_MIME_TYPES } from '../../core/constants';

export const downloadExcel = (
  httpClient: AxiosInstance,
  route: string,
  fileName: string,
  params?: any,
  omitExtension?: boolean,
) =>
  httpClient
    .get(route, {
      params,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      responseType: 'arraybuffer',
    })
    .then(response => {
      const blob = new Blob([response.data], {
        type: EXCEL_MIME_TYPES,
      });
      FileSaver.saveAs(blob, `${fileName || 'Download'}${omitExtension ? '' : '.xlsx'}`);
    });

const downloadExcelbyPost = (
  httpClient: AxiosInstance,
  route: string,
  fileName: string,
  params?: any,
  omitExtension?: boolean,
) =>
  httpClient.post(route, params, { responseType: 'arraybuffer' }).then(response => {
    const blob = new Blob([response.data], {
      type: EXCEL_MIME_TYPES,
    });
    FileSaver.saveAs(blob, `${fileName || 'Download'}${omitExtension ? '' : '.xlsx'}`);
  });

export const previewPDFFile = (route: string) =>
  http.get(route, { responseType: 'blob' }).then(blob => {
    const file = new Blob([blob.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  });

export const previewPDFFilePOST = <T,>(route: string, payload: T): any =>
  http.post(route, payload, {
    responseType: 'blob'
  }).then(blob => {
    const file = new Blob([blob.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  });

export const downloadExcelFile = (route: string, fileName: string, params?: any, omitExtension?: boolean) =>
  downloadExcel(http, route, fileName, params, omitExtension);

export const downloadInsightsExcelFile = (route: string, fileName: string, params?: any, omitExtension?: boolean) =>
  downloadExcel(httpInsightsReporting, route, fileName, params, omitExtension);

export const downloadExcelFilebyPost = (route: string, fileName: string, params?: any, omitExtension?: boolean) =>
  downloadExcelbyPost(http, route, fileName, params, omitExtension);
