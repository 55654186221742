import { AnyAction, Dispatch } from 'redux';

import { VisionConfigurationParams, VisionConfigurationResponse } from '../interfaces/VisionConfiguration';
import {
  clearVisionConfiguration as doClearConfig,
  loadVisionConfigurationForVehicle as doLoadVisionConfigurationForVehicle,
  loadVisionConfigurationWithVehicleTypeId as doLoadVisionConfigurationWithVehicleTypeId,
  saveVisionConfigurationForVehicle as doSaveVisionConfigurationForVehicle,
  saveVisionConfigurationWithVehicleTypeId as doSaveVisionConfigurationWithVehicleTypeId,
} from '../services/visionConfiguration';

// Types
const START_LOAD_DEFAULT = 'fleet/visionConfiguration/START_LOAD_DEFAULT';
const COMPLETE_LOAD_DEFAULT = 'fleet/visionConfiguration/COMPLETE_LOAD_DEFAULT';
const FAIL_LOAD_DEFAULT = 'fleet/visionConfiguration/FAIL_LOAD_DEFAULT';
const START_SAVE = 'fleet/visionConfiguration/START_SAVE';
const COMPLETE_SAVE = 'fleet/visionConfiguration/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/visionConfiguration/FAIL_SAVE';
const START_CLEAR_CONFIG = 'fleet/visionConfiguration/START_CLEAR_CONFIG';
const COMPLETE_CLEAR_CONFIG = 'fleet/visionConfiguration/COMPLETE_CLEAR_CONFIG';
const FAIL_CLEAR_CONFIG = 'fleet/visionConfiguration/FAIL_CLEAR_CONFIG';

// Initial State
const initialState: any = {
  isLoading: false,
  isSaving: false,
  isClearing: false,
};

// Reducer
const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_DEFAULT:
      return {
        ...state,
        isLoading: true,
      };
    case COMPLETE_LOAD_DEFAULT:
      return {
        ...state,
        isLoading: false,
        vehicle: action.vehicle,
      };
    case FAIL_LOAD_DEFAULT:
      return {
        ...state,
        isLoading: false,
        visionConfigurations: null,
      };
    case START_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case COMPLETE_SAVE:
      return {
        ...state,
        isSaving: false,
        vehicle: action.vehicle,
      };
    case FAIL_SAVE:
      return {
        ...state,
        isSaving: false,
      };

    case START_CLEAR_CONFIG:
      return {
        ...state,
        isClearing: true,
      };

    case COMPLETE_CLEAR_CONFIG:
      return {
        ...state,
        isClearing: false,
        vehicle: {
          ...state.vehicle,
          visionConfigurations: null,
        },
      };

    case FAIL_CLEAR_CONFIG:
      return {
        ...state,
        isClearing: false,
      };
    default:
      return state;
  }
};

// Action Creators

const startLoad = () => ({
  type: START_LOAD_DEFAULT,
});

const completeLoad = (vehicle: any) => ({
  type: COMPLETE_LOAD_DEFAULT,
  vehicle,
});

const failLoad = () => ({
  type: FAIL_LOAD_DEFAULT,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (vehicle: any) => ({
  type: COMPLETE_SAVE,
  vehicle,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startClearConfig = () => ({
  type: START_CLEAR_CONFIG,
});

const completeClearConfig = (vehicle: any) => ({
  type: COMPLETE_CLEAR_CONFIG,
  vehicle,
});

const failClearConfig = () => ({
  type: FAIL_CLEAR_CONFIG,
});

// Async Actions
export const loadVisionConfigurationForVehicle =
  (loadVisionParams: VisionConfigurationParams) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadVehiclePromise = doLoadVisionConfigurationForVehicle(loadVisionParams);
    loadVehiclePromise.then(vehicle => dispatch(completeLoad(vehicle))).catch(() => dispatch(failLoad()));
    return loadVehiclePromise;
  };

export const loadVisionConfigurationWithVehicleTypeId =
  (loadVisionParams: VisionConfigurationParams) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadVehiclePromise = doLoadVisionConfigurationWithVehicleTypeId(loadVisionParams);
    loadVehiclePromise.then(vehicle => dispatch(completeLoad(vehicle))).catch(() => dispatch(failLoad()));
    return loadVehiclePromise;
  };

export const clearVisionConfiguration = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startClearConfig());
  const clearConfigPromise = doClearConfig(vehicleId);
  clearConfigPromise.then(vehicle => dispatch(completeClearConfig(vehicle))).catch(() => dispatch(failClearConfig()));
  return clearConfigPromise;
};

export const saveVisionConfigurationForVehicle =
  (visionConfiguration: VisionConfigurationResponse) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveVehiclePromise = doSaveVisionConfigurationForVehicle(
      visionConfiguration.sections,
      visionConfiguration.vehicleId,
      visionConfiguration.id,
    );
    saveVehiclePromise
      .then(({ data }) => dispatch(completeSave(data)))
      .catch(() => {
        dispatch(failSave());
      });
    return saveVehiclePromise;
  };

export const saveVisionConfigurationWithVehicleTypeId =
  (visionConfiguration: VisionConfigurationResponse) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveVehiclePromise = doSaveVisionConfigurationWithVehicleTypeId(
      visionConfiguration.sections,
      visionConfiguration.vendorId,
      visionConfiguration.vehicleTypeId,
      visionConfiguration.id,
    );
    saveVehiclePromise
      .then(({ data }) => dispatch(completeSave(data)))
      .catch(() => {
        dispatch(failSave());
      });
    return saveVehiclePromise;
  };

// Selectors
export const visionConfigurationSelector = (state: any) =>
  state.fleet.visionConfiguration.vehicle && state.fleet.visionConfiguration.vehicle.sections;

export default reducer;
