import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SortOrder } from 'src/core/components/Table';
import { Customer, CustomerLocation, SuspendedLocation } from '../interfaces/Customers';
import { Service } from '../interfaces/Services';
import {
  exportSuspendedLocations as doExportSuspendedLocations,
  loadSuspendedLocations as doLoadSuspendedLocations,
  saveSuspendedLocations as doSaveSuspendedLocations,
} from '../services/suspendedLocations';
import { transformAddSuspendLocation } from '../services/transformSuspendedLocations';

// Actions
const START_LOAD = 'customer/suspendedLocations/START_LOAD';
export const COMPLETE_LOAD = 'customer/suspendedLocations/COMPLETE_LOAD';
const FAIL_LOAD = 'customer/suspendedLocations/FAIL_LOAD';
const START_SAVE = 'customer/suspendedLocations/START_SAVE';
export const COMPLETE_SAVE = 'customer/suspendedLocations/COMPLETE_SAVE';
const FAIL_SAVE = 'customer/suspendedLocations/FAIL_SAVE';
const RESET = 'customer/suspendedLocations/RESET';
export const ADD_SUSPENDED_LOCATION = 'customer/suspendedLocations/ADD_SUSPENDED_LOCATION';
export const EDIT_SUSPENDED_LOCATION = 'customer/suspendedLocations/EDIT_SUSPENDED_LOCATION';
const START_EXPORT = 'customer/suspendedLocations/START_EXPORT';
const COMPLETE_EXPORT = 'customer/suspendedLocations/COMPLETE_EXPORT';
const FAIL_EXPORT = 'customer/suspendedLocations/FAIL_EXPORT';

export interface State {
  isExporting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  suspendedLocations: SuspendedLocation[];
  total: number;
}

// Initial state
const initialState: State = {
  isExporting: false,
  isLoading: false,
  isSaving: false,
  suspendedLocations: [],
  total: 0,
};

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          suspendedLocations: action.suspendedLocations,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case ADD_SUSPENDED_LOCATION:
      return update(state, {
        suspendedLocations: { $unshift: [action.suspendedLocation] },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (suspendedLocations: SuspendedLocation[], total: number) => ({
  type: COMPLETE_LOAD,
  suspendedLocations,
  total,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const addSuspendLocation = (suspendedLocation: SuspendedLocation) => ({
  type: ADD_SUSPENDED_LOCATION,
  suspendedLocation,
});

export const suspendLocation =
  (customer: Customer, location: CustomerLocation, serviceContract: Service) => (dispatch: Dispatch) =>
    dispatch(addSuspendLocation(transformAddSuspendLocation(customer, location, serviceContract)));

export const loadSuspendedLocations =
  (
    vendorId: number,
    searchTerm?: string,
    accountStatusTypeId?: string,
    sortedBy?: string,
    sortOrder?: SortOrder,
    page?: number,
    limit?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadSuspendedLocationsPromise = doLoadSuspendedLocations(
      vendorId,
      searchTerm,
      accountStatusTypeId,
      sortedBy,
      sortOrder,
      page,
      limit,
    );
    loadSuspendedLocationsPromise
      .then(({ suspendedStops, total }) => dispatch(completeLoad(suspendedStops, total)))
      .catch(() => dispatch(failLoad()));
    return loadSuspendedLocationsPromise;
  };

const resetSuspendedLocationsAction = () => ({
  type: RESET,
});

export const resetSuspendedLocations = () => (dispatch: Dispatch) => dispatch(resetSuspendedLocationsAction());

export const editSuspendedLocation = () => ({
  type: EDIT_SUSPENDED_LOCATION,
});

export const exportSuspendedLocations =
  (vendorId: number, searchTerm?: string, accountStatusTypeId?: string) => (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportSuspendedLocationsPromise = doExportSuspendedLocations(vendorId, searchTerm, accountStatusTypeId);
    exportSuspendedLocationsPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportSuspendedLocationsPromise;
  };

export const saveSuspended =
  (suspendedLocations: SuspendedLocation[], shouldUpdateRoutes?: boolean) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const suspendPromise = doSaveSuspendedLocations(suspendedLocations, shouldUpdateRoutes);
    suspendPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return suspendPromise;
  };
