import { AnyAction, Dispatch } from 'redux';

import update from 'immutability-helper';
import { find, get, identity, size } from 'lodash-es';
import { createSelector } from 'reselect';

import { isAdminSelector } from '.';
import { AppState } from '../../store';
import {
  loadPermissions as doLoadPermissions,
  savePermissions,
  shouldLoadPermissions as doShouldLoadPermissions,
} from '../services/permissions';
import { LoginState } from './login';

// Actions
const START_LOAD_PERMISSIONS = 'account/permissions/START_LOAD_PERMISSIONS';
const COMPLETE_LOAD_PERMISSIONS = 'account/permissions/COMPLETE_LOAD_PERMISSIONS';
const FAIL_LOAD_PERMISSIONS = 'account/permissions/FAIL_LOAD_PERMISSIONS';
const RESET = 'account/permissions/RESET';

// Initial state
const initialState: any = {
  isLoading: false,
  permissions: doShouldLoadPermissions(),
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_PERMISSIONS:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD_PERMISSIONS:
      return update(state, {
        $merge: { isLoading: false, permissions: action.permissions },
      });

    case FAIL_LOAD_PERMISSIONS:
      return update(state, {
        $merge: { isLoading: false, permissions: undefined },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoadPermissions = () => ({
  type: START_LOAD_PERMISSIONS,
});

const completeLoadPermissions = (permissions: any) => ({
  type: COMPLETE_LOAD_PERMISSIONS,
  permissions,
});

const failLoadPermissions = () => ({
  type: FAIL_LOAD_PERMISSIONS,
});

export const loadPermissions = () => (dispatch: Dispatch) => {
  dispatch(startLoadPermissions());
  return doLoadPermissions()
    .then(permissions => {
      savePermissions(permissions);
      dispatch(completeLoadPermissions(permissions));
    })
    .catch(() => dispatch(failLoadPermissions()));
};

export const resetPermissions = () => ({
  type: RESET,
});

// Selectors
const hasPermissions = (permissionsState: any, action: AnyAction): boolean =>
  get(
    find(get(permissionsState, 'permissions'), permission => permission.name === action),
    'isActive',
    true,
  );

export const hasPermissionSelector = createSelector<any, any, boolean, boolean>(hasPermissions, identity);

const hasPermissionsLoaded = (loginState: LoginState, permissionsState: any) =>
  !isAdminSelector(loginState) && !size(get(permissionsState, 'permissions'));

export const shouldLoadPermissionsSelector = createSelector<any, any, boolean, boolean>(hasPermissionsLoaded, identity);

export const shouldLoadPermissions = (state: AppState) =>
  shouldLoadPermissionsSelector(state.account.login, state.account.permissions);
