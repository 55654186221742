import { Fragment, PureComponent } from 'react';

import { Field } from 'redux-form';
import moment from 'moment';

import TimePickerInput from 'src/core/components/TimePicker';
import { Input } from '../../core/components';
import {
  Button,
  FormError,
  Grid as GridUntyped,
  GridColumn as GridColumnUntyped,
  TableCell,
  Text,
} from '../../core/components/styled';
import translate from '../../core/services/translate';
import { RESIDENTIAL_ID } from '../../fleet/constants';
import {
  isOptionalDecimalUpTo1OrNull,
  isRequired,
  maxLength6,
  maxValueNumeric9999,
} from '../../utils/services/validator';
import { loadRouteSequenceDefaultSettings } from '../services/routeSequenceSettings';
import { getTotalBreakTimeDate } from './forms/RouteSettingsForm';

const Grid = GridUntyped as any;
const GridColumn = GridColumnUntyped as any;

interface Props {
  bothSidesServiceVisible?: boolean;
  change: (name: string, time: Date) => void;
  routeStartTime: string;
  serviceTimePerStopVisible?: boolean;
  vehicleTypeId: number;
  showTruckCapacity?: boolean;
  showIntermediateFacility?: boolean;
  disposalFacilities: any;
  amBreakTime?: Date;
  pmBreakTime?: Date;
  lunchBreakTime?: Date;
  maxRouteTime?: Date;
  hasError: boolean[];
  setHasError: (index: number, value: boolean) => void;
}

interface State {
  defaultRouteStartTime: string;
  error: string;
}

class RouteSequenceConfiguration extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      defaultRouteStartTime: props.routeStartTime,
      error: '',
    };
  }

  static defaultProps = {
    serviceTimePerStopVisible: false,
  };

  restoreToDefaults = (vehicleTypeId: number) => {
    loadRouteSequenceDefaultSettings(vehicleTypeId).then(defaults => {
      this.resetFormFields(defaults, vehicleTypeId);
    });
  };

  resetFormFields = (defaultConfig: any, vehicleTypeId: number) => {
    this.updateFormValue(`${vehicleTypeId}.routeStartTime`, defaultConfig.routeStartTime);
    this.updateFormValue(`${vehicleTypeId}.maxRouteTime`, defaultConfig.maxRouteTime);
    this.updateFormValue(`${vehicleTypeId}.singleSideServiceTimePerStop`, defaultConfig.singleSideServiceTimePerStop);
    this.updateFormValue(`${vehicleTypeId}.bothSidesServiceTimePerStop`, defaultConfig.bothSidesServiceTimePerStop);
    this.updateFormValue(`${vehicleTypeId}.serviceTimePerStop`, defaultConfig.serviceTimePerStop);
    this.updateFormValue(`${vehicleTypeId}.preStartTime`, defaultConfig.preStartTime);
    this.updateFormValue(`${vehicleTypeId}.postEndTime`, defaultConfig.postEndTime);

    this.setState({ defaultRouteStartTime: defaultConfig.routeStartTime });
  };

  updateFormValue = (name: string, value: any) => {
    this.props.change(name, value);
  };

  onTimeComplexChange = (name: string, value: Date | string) => {
    const dateTime = moment(value, 'HH:mm:ss');
    this.props.change(name, dateTime.toDate());
  };

  componentDidUpdate(prevProps: any) {
    const { amBreakTime, pmBreakTime, lunchBreakTime, vehicleTypeId, maxRouteTime, hasError, setHasError } = this.props;
    const { error } = this.state;

    if (
      prevProps.amBreakTime !== amBreakTime ||
      prevProps.pmBreakTime !== pmBreakTime ||
      prevProps.lunchBreakTime !== lunchBreakTime
    ) {
      const totalBreakTime = getTotalBreakTimeDate(amBreakTime, pmBreakTime, lunchBreakTime);
      this.updateFormValue(`${vehicleTypeId}.totalBreakTime`, totalBreakTime);

      // validation for totalBreakTime field
      if (maxRouteTime && totalBreakTime > maxRouteTime) {
        !error && this.setState({ error: translate('routes.routeSettings.totalBreakTimeHasToBeLessThanMaxRouteTime') });
        if (hasError && !hasError[vehicleTypeId]) setHasError(vehicleTypeId, true);
      } else {
        error && this.setState({ error: '' });
        if (hasError && hasError[vehicleTypeId || 0]) setHasError(vehicleTypeId, false);
      }
    }
  }

  render() {
    const { bothSidesServiceVisible, serviceTimePerStopVisible, vehicleTypeId, showTruckCapacity } = this.props;
    const { defaultRouteStartTime, error } = this.state;

    return (
      <Grid multiLine>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%">
            <Text>{translate('routes.routeStartTime')}</Text>
          </TableCell>
          <TableCell width="40%">
            <Field
              time={defaultRouteStartTime}
              name={`${vehicleTypeId}.routeStartTime`}
              props={{
                name: `${vehicleTypeId}.routeStartTime`,
                errorOnSingleLine: true,
                format: 'hh:mm:ss a',
                maxDetail: 'second',
                margin: 'no',
                onTimeChange: (value: string | Date) =>
                  this.onTimeComplexChange(`${vehicleTypeId}.routeStartTime`, value),
              }}
              component={TimePickerInput}
              validate={[isRequired]}
            />
          </TableCell>
        </GridColumn>

        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%">
            <Text>{translate('routes.maxRouteTime')}</Text>
          </TableCell>

          <TableCell width="40%">
            <Field
              name={`${vehicleTypeId}.maxRouteTime`}
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                errorOnSingleLine: true,
                format: 'HH:mm:ss',
                maxDetail: 'second',
                margin: 'no',
                onTimeChange: (value: string | Date) =>
                  this.onTimeComplexChange(`${vehicleTypeId}.maxRouteTime`, value),
              }}
            />
          </TableCell>
        </GridColumn>

        {vehicleTypeId === RESIDENTIAL_ID && !serviceTimePerStopVisible && (
          <GridColumn size="12/12" verticalAlign>
            <TableCell width="60%">
              <Text>{translate('routes.serviceTimePerStop')}</Text>
            </TableCell>

            <TableCell width="40%">
              <Field
                name={`${vehicleTypeId}.singleSideServiceTimePerStop`}
                component={TimePickerInput}
                validate={[isRequired]}
                props={{
                  errorOnSingleLine: true,
                  format: 'HH:mm:ss',
                  maxDetail: 'second',
                  margin: 'no',
                  onTimeChange: (value: string | Date) =>
                    this.onTimeComplexChange(`${vehicleTypeId}.singleSideServiceTimePerStop`, value),
                }}
              />
            </TableCell>
          </GridColumn>
        )}

        {vehicleTypeId === RESIDENTIAL_ID ? (
          <Fragment>
            <GridColumn size="12/12" verticalAlign>
              <TableCell width="60%">
                <Text>
                  {serviceTimePerStopVisible
                    ? translate('routes.serviceTimePerStop')
                    : translate('routes.bothSidesServiceTimePerStop')}
                </Text>
              </TableCell>
              <TableCell width="40%">
                {serviceTimePerStopVisible && !bothSidesServiceVisible ? (
                  <Field
                    name={`${vehicleTypeId}.singleSideServiceTimePerStop`}
                    component={TimePickerInput}
                    validate={[isRequired]}
                    props={{
                      errorOnSingleLine: true,
                      format: 'HH:mm:ss',
                      maxDetail: 'second',
                      margin: 'no',
                      onTimeChange: (value: string | Date) =>
                        this.onTimeComplexChange(`${vehicleTypeId}.singleSideServiceTimePerStop`, value),
                    }}
                  />
                ) : (
                  <Field
                    name={`${vehicleTypeId}.bothSidesServiceTimePerStop`}
                    margin="no"
                    type="number"
                    component={TimePickerInput}
                    validate={[isRequired]}
                    props={{
                      errorOnSingleLine: true,
                      format: 'HH:mm:ss',
                      maxDetail: 'second',
                      margin: 'no',
                      onTimeChange: (value: string | Date) =>
                        this.onTimeComplexChange(`${vehicleTypeId}.bothSidesServiceTimePerStop`, value),
                    }}
                  />
                )}
              </TableCell>
            </GridColumn>
          </Fragment>
        ) : (
          <GridColumn size="12/12" verticalAlign>
            <TableCell width="60%">
              <Text>{translate('routes.serviceTimePerStop')}</Text>
            </TableCell>

            <TableCell width="40%">
              <Field
                name={`${vehicleTypeId}.serviceTimePerStop`}
                component={TimePickerInput}
                validate={[isRequired]}
                props={{
                  errorOnSingleLine: true,
                  format: 'HH:mm:ss',
                  maxDetail: 'second',
                  margin: 'no',
                  onTimeChange: (value: string | Date) =>
                    this.onTimeComplexChange(`${vehicleTypeId}.serviceTimePerStop`, value),
                }}
              />
            </TableCell>
          </GridColumn>
        )}

        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%">
            <Text>{translate('routes.preTripTime')}</Text>
          </TableCell>

          <TableCell width="40%">
            <Field
              name={`${vehicleTypeId}.preStartTime`}
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                errorOnSingleLine: true,
                format: 'HH:mm:ss',
                maxDetail: 'second',
                margin: 'no',
                onTimeChange: (value: string | Date) =>
                  this.onTimeComplexChange(`${vehicleTypeId}.preStartTime`, value),
              }}
            />
          </TableCell>
        </GridColumn>

        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%">
            <Text>{translate('routes.postTripTime')}</Text>
          </TableCell>

          <TableCell width="40%">
            <Field
              name={`${vehicleTypeId}.postEndTime`}
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                errorOnSingleLine: true,
                format: 'HH:mm:ss',
                maxDetail: 'second',
                margin: 'no',
                onTimeChange: (value: string | Date) => this.onTimeComplexChange(`${vehicleTypeId}.postEndTime`, value),
              }}
            />
          </TableCell>
        </GridColumn>

        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%">
            <Text>{translate('routes.routeSettings.totalBreakTime')}</Text>
          </TableCell>

          <TableCell width="40%">
            <Field
              name={`${vehicleTypeId}.totalBreakTime`}
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                errorOnSingleLine: true,
                format: 'HH:mm:ss',
                maxDetail: 'second',
                margin: 'no',
                readOnly: true,
                onTimeChange: (value: string | Date) =>
                  this.onTimeComplexChange(`${vehicleTypeId}.totalBreakTime`, value),
              }}
            />
          </TableCell>
        </GridColumn>
        {error && (
          <GridColumn size="12/12" verticalAlign padding="no medium">
            <FormError>{error}</FormError>
          </GridColumn>
        )}
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%" padding="no no no large">
            <Text>{translate('routes.routeSettings.amBreakTime')}</Text>
          </TableCell>

          <TableCell width="40%">
            <Field
              name={`${vehicleTypeId}.amBreakTime`}
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                errorOnSingleLine: true,
                format: 'HH:mm:ss',
                maxDetail: 'second',
                margin: 'no',
                onTimeChange: (value: string | Date) => this.onTimeComplexChange(`${vehicleTypeId}.amBreakTime`, value),
              }}
            />
          </TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%" padding="no no no large">
            <Text>{translate('routes.routeSettings.lunchBreakTime')}</Text>
          </TableCell>

          <TableCell width="40%">
            <Field
              name={`${vehicleTypeId}.lunchBreakTime`}
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                errorOnSingleLine: true,
                format: 'HH:mm:ss',
                maxDetail: 'second',
                margin: 'no',
                onTimeChange: (value: string | Date) =>
                  this.onTimeComplexChange(`${vehicleTypeId}.lunchBreakTime`, value),
              }}
            />
          </TableCell>
        </GridColumn>
        <GridColumn size="12/12" verticalAlign>
          <TableCell width="60%" padding="no no no large">
            <Text>{translate('routes.routeSettings.pmBreakTime')}</Text>
          </TableCell>

          <TableCell width="40%">
            <Field
              name={`${vehicleTypeId}.pmBreakTime`}
              component={TimePickerInput}
              validate={[isRequired]}
              props={{
                errorOnSingleLine: true,
                format: 'HH:mm:ss',
                maxDetail: 'second',
                margin: 'no',
                onTimeChange: (value: string | Date) => this.onTimeComplexChange(`${vehicleTypeId}.pmBreakTime`, value),
              }}
            />
          </TableCell>
        </GridColumn>

        {showTruckCapacity && (
          <GridColumn size="12/12" verticalAlign>
            <TableCell width="60%">
              <Text>{translate('routes.truckCapacity')}</Text>
            </TableCell>

            <TableCell width="40%">
              <Field
                name={`${vehicleTypeId}.vehicleCapacity`}
                component={Input}
                placeholder={'capacity'}
                margin="no"
                type="number"
                props={{ errorOnSingleLine: false }}
                validate={[isOptionalDecimalUpTo1OrNull, maxLength6, maxValueNumeric9999]}
              />
            </TableCell>
          </GridColumn>
        )}

        {!serviceTimePerStopVisible && (
          <GridColumn size="12/12" verticalAlign>
            <TableCell width="100%">
              <Button
                type="button"
                color="secondary"
                margin="no no ssMedium no"
                onClick={() => this.restoreToDefaults(vehicleTypeId)}
              >
                {translate('routes.restoreToDefaults')}
              </Button>
            </TableCell>
          </GridColumn>
        )}
      </Grid>
    );
  }
}

export default RouteSequenceConfiguration;
