import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { loadBillingMethods } from 'src/common/ducks';
import { TypedResolver } from 'src/core/components';
import translate from 'src/core/services/translate';
import { loadCustomerTypes, loadPaymentTerms } from 'src/customers/ducks';
import { loadContainerTypes, loadCustomerAccountStatuses, loadWorkFlowTypes } from 'src/finance/ducks';
import BillCreatePage from './BillCreatePage';

const BillCreatePageResolver = () => {
  const dispatch = useDispatch();

  const loadDependencies = () => {
    return Promise.all([
      loadBillingMethods()(dispatch),
      loadContainerTypes()(dispatch),
      loadCustomerAccountStatuses()(dispatch),
      loadCustomerTypes()(dispatch),
      loadPaymentTerms()(dispatch),
      loadWorkFlowTypes()(dispatch),
    ]);
  };

  return (
    <Fragment>
      <DocumentTitle>{translate('finance.billing')}</DocumentTitle>
      <TypedResolver
        successComponent={BillCreatePage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/dashboard"
      />
    </Fragment>
  );
};

export default BillCreatePageResolver;
