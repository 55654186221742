import { identity, mapKeys } from 'lodash-es';
import { createSelector } from 'reselect';
import { DriverSafetyInspectionSettings } from '../interfaces/SupervisorExperienceSettings';

const getDriverSafetyInspectionSettingsFormInitialValuesSelector = (
  driverSafetyInspectionSettings: DriverSafetyInspectionSettings[],
) =>
  mapKeys(driverSafetyInspectionSettings, ({ driverSafetyInspectionType }) => driverSafetyInspectionType.technicalName);

const driverSafetyInspectionSettingsFormInitialValuesSelector = createSelector(
  getDriverSafetyInspectionSettingsFormInitialValuesSelector,
  identity,
);

export default driverSafetyInspectionSettingsFormInitialValuesSelector;
