import React from 'react';
import { Source, Layer } from 'react-map-gl';

import {
  ROUTE_MAP_CLUSTERS_SOURCE,
  ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER,
  ROUTE_MAP_CLUSTERS_CLUSTER_COUNTERS_LAYER,
} from '../constants';

type Props<P extends object = {}> = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
};

export default React.memo(function RouteMapClustersGLSource({ geoJSON }: Props) {
  return (
    <Source cluster type="geojson" id={ROUTE_MAP_CLUSTERS_SOURCE} data={geoJSON} tolerance={0.0001} clusterMaxZoom={11}>
      <Layer
        id={ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER}
        type="circle"
        source={ROUTE_MAP_CLUSTERS_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        }}
      />

      <Layer
        id={ROUTE_MAP_CLUSTERS_CLUSTER_COUNTERS_LAYER}
        type="symbol"
        source={ROUTE_MAP_CLUSTERS_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'text-color': 'white',
        }}
        layout={{
          'text-field': '{point_count_abbreviated}',
          'text-size': 12,
          'text-allow-overlap': true,
        }}
      />
    </Source>
  );
});
