import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { ROContainerManagementForm } from '../forms';
import { RollOffContainerManagementSettings } from '../../interfaces/RollOffContainerManagement';
import { saveRollOffContainerMangementSettings, resetRollOffContainerManagementSettings } from '../../ducks';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  isLoading: boolean;
  isSaving: boolean;
  resetRollOffContainerManagementSettings: DuckAction<typeof resetRollOffContainerManagementSettings>;
  rollOffContainerManagementSettings?: RollOffContainerManagementSettings;
  saveRollOffContainerMangementSettings: DuckFunction<typeof saveRollOffContainerMangementSettings>;
  vendorId: number;
}

class ROContainerManagementModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetRollOffContainerManagementSettings();
  }

  onSubmit = (rollOffContainerManagementSettings: RollOffContainerManagementSettings) => {
    const { vendorId, saveRollOffContainerMangementSettings, closeModal } = this.props;
    saveRollOffContainerMangementSettings(vendorId, rollOffContainerManagementSettings)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.rollOffContainerManagementSettingsSaved'));
        closeModal(true);
      })
      .catch(() =>
        createErrorNotification(translate('vendors.alertMessages.saveRollOffContainerManagementSettingsError')),
      );
  };

  render() {
    const { rollOffContainerManagementSettings, isLoading, isSaving, closeModal } = this.props;

    return (
      <Modal padding="no" size="small">
        <ModalSection padding="medium medium small">
          <ModalTitle>{translate('vendors.featureCodes.rOContainerManagement')}</ModalTitle>
        </ModalSection>
        <ModalSection isLoading={isLoading || isSaving}>
          <ROContainerManagementForm
            onSubmit={this.onSubmit}
            initialValues={rollOffContainerManagementSettings}
            onCancel={closeModal}
          />
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.vendors.rollOffContainerManagementSettings.isLoading,
  isSaving: state.vendors.rollOffContainerManagementSettings.isSaving,
  rollOffContainerManagementSettings:
    state.vendors.rollOffContainerManagementSettings.rollOffContainerManagementSettings,
});

const mapDispatchToProps = {
  saveRollOffContainerMangementSettings,
  resetRollOffContainerManagementSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ROContainerManagementModal);
