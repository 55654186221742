import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import {
  loadZDeviceConfigurations as doLoadZDeviceConfigurations,
  saveZDeviceConfigurations as doSaveZDeviceConfigurations,
} from '../services/zDeviceConfigurations';

// Actions
const START_LOAD = 'vendors/zDeviceConfigurations/START_LOAD';
const COMPLETE_LOAD = 'vendors/zDeviceConfigurations/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/zDeviceConfigurations/FAIL_LOAD';
const VERSION_CHANGE = 'vendors/zDeviceConfigurations/VERSION_CHANGE';
const TOGGLE_Z_DEVICE_CONFIGURATION = 'vendors/zDeviceConfigurations/TOGGLE_Z_DEVICE_CONFIGURATION';
const START_SAVE = 'vendors/zDeviceConfigurations/START_SAVE';
const COMPLETE_SAVE = 'vendors/zDeviceConfigurations/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/zDeviceConfigurations/FAIL_SAVE';
const RESET = 'vendors/zDeviceConfigurations/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  zDeviceConfigurations: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          zDeviceConfigurations: action.zDeviceConfigurations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case TOGGLE_Z_DEVICE_CONFIGURATION: {
      const { zDeviceTypeId, enabled } = action;
      const configurationIndex = (state.zDeviceConfigurations as any).deviceServices.find((val: any) => {
        if (val.id === zDeviceTypeId) return val;
        return null;
      });
      configurationIndex.isEnabled = enabled;
      return { ...state };
    }

    case VERSION_CHANGE: {
      const { zDeviceTypeId, versionNumber } = action;
      const configurationIndex = findIndex(state.zDeviceConfigurations, { zDeviceTypeId });

      return update(state, {
        zDeviceConfigurations: {
          [configurationIndex]: { versionNumber: { $set: versionNumber } },
        },
      } as any);
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (zDeviceConfigurations: any) => ({
  type: COMPLETE_LOAD,
  zDeviceConfigurations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadZDeviceConfigurations = (vendorId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadZDeviceConfigurationsPromise = doLoadZDeviceConfigurations(vendorId, vehicleTypeId);
  loadZDeviceConfigurationsPromise
    .then(zDeviceConfigurations => dispatch(completeLoad(zDeviceConfigurations)))
    .catch(() => dispatch(failLoad()));

  return loadZDeviceConfigurationsPromise;
};

export const toggleZDeviceConfiguration = (zDeviceTypeId: number, enabled: boolean) => ({
  type: TOGGLE_Z_DEVICE_CONFIGURATION,
  zDeviceTypeId,
  enabled,
});

export const onZDeviceConfigurationVersionChange = (zDeviceTypeId: number, versionNumber: any) => ({
  type: VERSION_CHANGE,
  zDeviceTypeId,
  versionNumber,
});

export const saveZDeviceConfigurations = (deviceServices: any, commands: any) => (
  dispatch: Dispatch,
  getState: any,
) => {
  const zDeviceConfigurations = getState().vendors.zDeviceConfigurations.zDeviceConfigurations;
  const newZDeviceConfigurations = { ...zDeviceConfigurations, deviceServices, commands };
  dispatch(startSave());
  const saveZDeviceConfigurationsPromise = doSaveZDeviceConfigurations(newZDeviceConfigurations);
  saveZDeviceConfigurationsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveZDeviceConfigurationsPromise;
};

export const resetZDeviceConfigurations = () => ({
  type: RESET,
});

// Selectors

export const selectZDeviceServices = (state: any) =>
  state.vendors.zDeviceConfigurations.zDeviceConfigurations &&
  state.vendors.zDeviceConfigurations.zDeviceConfigurations.deviceServices;

export const selectCommands = (state: any) =>
  state.vendors.zDeviceConfigurations.zDeviceConfigurations &&
  state.vendors.zDeviceConfigurations.zDeviceConfigurations.commands;
