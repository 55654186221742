import { http } from 'src/core/services/http';
import { RoutePlannerDriverStatus } from '../interfaces/routePlanner/RoutePlannerDriverStatus';
import {
  RoutePlannerCalendarPayload,
  RoutePlannerRoutesPayload,
  RoutePlannerTablePayload,
  RoutePlannerTemplatesPayload,
} from '../interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { RoutePlannerVehicleStatus } from '../interfaces/routePlanner/RoutePlannerVehicleStatus';

// Main Page (calendar & table)

export const loadRoutePlannerCalendarData = (params: RoutePlannerCalendarPayload) =>
  http.get(`${params.vendorId}/routePlanner/calendar`, { params }).then(response => response.data);

export const loadRoutePlannerTableData = (params: RoutePlannerTablePayload) =>
  http.get(`${params.vendorId}/routePlanner/table`, { params }).then(response => response.data);

//  Daily Routes Page

export const loadRoutePlannerRoutes = (params: RoutePlannerRoutesPayload) =>
  http
    .get(`${params.vendorId}/routePlanner/routes`, {
      params,
    })
    .then(response => {
      return response.data;
    });

export const loadRoutePlannerRouteDrivers = (params: RoutePlannerRoutesPayload) =>
  http
    .get(`${params.vendorId}/routePlanner/routes/drivers`, {
      params,
    })
    .then(response => {
      return response.data;
    });

export const loadRoutePlannerRouteVehicles = (params: RoutePlannerRoutesPayload) =>
  http
    .get(`${params.vendorId}/routePlanner/routes/vehicles`, {
      params,
    })
    .then(response => {
      return response.data;
    });

export const assignDriverToRoute = (vendorId: number, routeId: number, driverId: number) =>
  http.put(`${vendorId}/routePlanner/routes/assignDriver`, { routeId, driverId }).then(response => response.data);

export const removeDriverFromRoute = (vendorId: number, routeId: number, driverId: number) =>
  http.put(`${vendorId}/routePlanner/routes/removeDriver`, { routeId, driverId }).then(response => response.data);

export const assignVehicleToRoute = (vendorId: number, routeId: number, vehicleId: number) =>
  http.put(`${vendorId}/routePlanner/routes/assignVehicle`, { routeId, vehicleId }).then(response => response.data);

export const removeVehicleFromRoute = (vendorId: number, routeId: number, vehicleId: number) =>
  http.put(`${vendorId}/routePlanner/routes/removeVehicle`, { routeId, vehicleId }).then(response => response.data);

export const bulkDeleteRoutes = (vendorId: number, routeIds: number[]) =>
  http
    .delete(`${vendorId}/routePlanner/routes`, {
      data: {
        vendorId,
        routeIds,
      },
    })
    .then(response => response.data);

export const loadVehicleStatus = (vendorId: number, vehicleId: number, date: string) =>
  http
    .get(`${vendorId}/routePlanner/routes/vehicleStatus`, {
      params: {
        vendorId,
        vehicleId,
        date,
      },
    })
    .then(response => response.data);

export const updateVehiclesStatus = (vendorId: number, vehicleStatuses: RoutePlannerVehicleStatus[]) =>
  http.put(`${vendorId}/routePlanner/routes/vehiclesStatus`, vehicleStatuses).then(response => response.data);

export const loadDriverStatus = (vendorId: number, driverId: number, date: string) =>
  http
    .get(`${vendorId}/routePlanner/routes/driverStatus`, {
      params: {
        vendorId,
        driverId,
        date,
      },
    })
    .then(response => response.data);

export const updateDriversStatus = (vendorId: number, drivers: RoutePlannerDriverStatus[]) =>
  http.put(`${vendorId}/routePlanner/routes/driversStatus`, drivers).then(response => response.data);

// Route Templates Page

export const loadRoutePlannerRouteTemplates = (params: RoutePlannerTemplatesPayload) =>
  http
    .get(`${params.vendorId}/routePlanner/routeTemplates`, {
      params,
    })
    .then(response => {
      return response.data;
    });

export const loadRoutePlannerRouteTemplateVehicles = (params: RoutePlannerTemplatesPayload) =>
  http
    .get(`${params.vendorId}/routePlanner/routeTemplates/vehicles`, {
      params,
    })
    .then(response => {
      return response.data;
    });

export const loadRoutePlannerRouteTemplateDrivers = (params: RoutePlannerTemplatesPayload) =>
  http
    .get(`${params.vendorId}/routePlanner/routeTemplates/drivers`, {
      params,
    })
    .then(response => {
      return response.data;
    });

export const assignDriverToRouteTemplate = (vendorId: number, routeTemplateId: number, driverId: number) =>
  http
    .put(`${vendorId}/routePlanner/routeTemplates/assignDriver`, { routeTemplateId, driverId })
    .then(response => response.data);

export const removeDriverFromRouteTemplate = (vendorId: number, routeTemplateId: number, driverId: number) =>
  http.put(`${vendorId}/routePlanner/routeTemplates/removeDriver`, { routeTemplateId, driverId });

export const assignVehicleToRouteTemplate = (vendorId: number, routeTemplateId: number, vehicleId: number) =>
  http
    .put(`${vendorId}/routePlanner/routeTemplates/assignVehicle`, { routeTemplateId, vehicleId })
    .then(response => response.data);

export const removeVehicleFromRouteTemplate = (vendorId: number, routeTemplateId: number, vehicleId: number) =>
  http
    .put(`${vendorId}/routePlanner/routeTemplates/removeVehicle`, { routeTemplateId, vehicleId })
    .then(response => response.data);

export const bulkDeleteRouteTemplates = (vendorId: number, routeTemplateIds: number[]) =>
  http
    .delete(`${vendorId}/routePlanner/routeTemplates`, {
      data: {
        vendorId,
        routeTemplateIds,
      },
    })
    .then(response => response.data);
