import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';

const getInsightsFormInitialValues = (
  queryString: string,
  vehicleType: string | null,
  inputVehicleId: number,
  routeNameId: number,
) => {
  const { materialContaminationType, obstacleType, routeId, vehicleId, vehicleTypeId } = getQueryParams(queryString);

  return {
    materialContaminationType,
    obstacleType,
    routeId: routeNameId || (routeId && Number(routeId)),
    vehicleId: inputVehicleId || (vehicleId && Number(vehicleId)),
    vehicleTypeId: (vehicleTypeId && Number(vehicleTypeId)) || vehicleType,
  };
};

const insightsFormInitialValuesSelector = createSelector(getInsightsFormInitialValues, identity);

export default insightsFormInitialValuesSelector;
