import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { size } from 'lodash-es';

import { AppState } from '../../../store';
import { PanelSection, Message } from '../../../core/components/styled';
import { AgreementsListTableRow } from '.';
import { HaulerProfileGrid, HaulerProfileGridColumn } from '../styled';
import { Table } from '../../../core/components';
import translate from '../../../core/services/translate';

const PanelSectionMock = PanelSection as any;

interface ComponentProps {
  tableData: any[];
}

interface Props extends ComponentProps {
  isExporting: boolean;
}

class Documents extends PureComponent<Props> {
  render() {
    const { isExporting, tableData } = this.props;

    const agreementsListTableCells = [
      { name: 'fileName', label: translate('autoDispatch.fileName'), width: '30%' },
      { name: 'type', label: translate('autoDispatch.documentType'), width: '16%' },
      { name: 'desription', label: translate('haulerProfile.documentDescription'), width: '20%' },
      { name: 'startDate', label: translate('common.startDate'), width: '12%' },
      { name: 'endDate', label: translate('common.endDate'), width: '12%' },
      { name: 'downloadLink', label: translate('common.options'), width: '10%', align: 'center' },
    ];

    return (
      <HaulerProfileGrid>
        <HaulerProfileGridColumn lastColumn padding="no" width="100%">
          <PanelSectionMock isLoading={isExporting} vertical fullWidth padding="no">
            {size(tableData) ? (
              <Table
                cells={agreementsListTableCells}
                rows={tableData}
                rowComponent={AgreementsListTableRow}
                withTopBorder
              />
            ) : (
              <Message padding="sMedium">{translate('haulerProfile.noDocumentsUploaded')}</Message>
            )}
          </PanelSectionMock>
        </HaulerProfileGridColumn>
      </HaulerProfileGrid>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isExporting: state.haulerProfile.files.isExporting,
});

export default connect(mapStateToProps)(Documents);
