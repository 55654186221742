import { FC, useEffect, useMemo, useState } from 'react';
import { DashboardCustomLoadingOverlay } from '../../styled';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

interface DashboardMapLoadingOverlayProps {
  isLoading: boolean;
  isStreetNetwork?: boolean;
}

export const DashboardMapLoadingOverlay: FC<DashboardMapLoadingOverlayProps> = ({ isLoading, isStreetNetwork }) => {
  // calculate the time for the loading overlay to be displayed
  const [loadingTime, setLoadingTime] = useState(0);
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (isLoading) {
      timer = setInterval(() => {
        setLoadingTime(prevLoadingTime => prevLoadingTime + 1);
        setDotCount(prevDotCount => (prevDotCount < 5 ? prevDotCount + 1 : 1));
      }, 1000);
    } else {
      setLoadingTime(0);
      setDotCount(1);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isLoading]);

  const messageNumber = useMemo(() => {
    if (loadingTime < 5) return 1;
    if (loadingTime < 10) return 2;
    if (loadingTime < 15) return 3;
    if (loadingTime >= 15) return isStreetNetwork ? 3 : 4;
    return 1;
  }, [isStreetNetwork, loadingTime]);

  const renderDots = (dot: number) => '. '.repeat(dot);

  if (!isLoading) return null;

  return (
    <DashboardCustomLoadingOverlay>
      <Text size="large" fontStyle="italic" color="primary" margin="grande no no">
        <b>
          {translate(`dashboard.loadingMessages.message${messageNumber}`)} {renderDots(dotCount)}
        </b>
      </Text>
    </DashboardCustomLoadingOverlay>
  );
};

export default DashboardMapLoadingOverlay;
