import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadReportingFaultCodeDetails } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import FaultCodeDetailsPage from './FaultCodeDetailsPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  vendorId: number;
  loadReportingFaultCodeDetails: DuckFunction<typeof loadReportingFaultCodeDetails>;
}

class FaultCodeDetailsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { vendorId, location, loadReportingFaultCodeDetails } = this.props;
    const { date, vehicleId, vehicleName } = getQueryParams(location.search);

    return Promise.all([loadReportingFaultCodeDetails(vendorId, date, vehicleId, vehicleName)]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('insights.faultCodeDetails')}</DocumentTitle>
        <Resolver
          successComponent={FaultCodeDetailsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadReportingFaultCodeDetails };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FaultCodeDetailsPageResolver),
);
