import { useMemo } from 'react';
import { find } from 'lodash-es';

import { useSelector } from 'src/core/hooks/useSelector';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import RouteMapRouteStopsGLPopup from './RouteMapRouteStopsGLPopup';

type Props = {
  isEditMode?: boolean;
  routeStops?: RouteLocation[];
};

export default function RouteMapRouteStopsGLPopupResolver({ isEditMode, routeStops }: Props) {
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const routeStop: RouteLocation | null = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeStops) {
      return null;
    }
    if (!isEditMode) {
      return (
        find(
          routeStops,
          routeStop =>
            ((routeStop.serviceContractRouteTemplateId || routeStop.stopId) as number) === selectedFeature.id,
        ) || null
      );
    }

    return null;
  }, [selectedFeature, routeStops, isEditMode]);

  if (!routeStop) {
    return null;
  }

  return <RouteMapRouteStopsGLPopup routeStop={routeStop} />;
}
