import { MobileModal } from 'src/core/components/MobileModal';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import ServiceChangeRequestsFilterForm, {
  ServiceChangeRequestsFilterFormProps,
} from '../forms/ServiceChangeRequestsFilterForm';

interface Props extends ServiceChangeRequestsFilterFormProps {
  onClose: () => void;
  hidden: boolean;
}

export const ServiceChangeRequestsMobileFiltersModal: React.FC<Props> = ({ onClose, hidden, ...props }) => {
  const isLoading = useSelector(s => s.fleet.rubiconDispatches.isLoading);

  return (
    <MobileModal
      isLoading={isLoading}
      flex
      flexDirection="column"
      onClose={onClose}
      title={translate('common.filters')}
      hidden={hidden}
    >
      <ServiceChangeRequestsFilterForm {...props} />
    </MobileModal>
  );
};
