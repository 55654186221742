import { map } from 'lodash-es';

import { getFeatureCollection, getLineStringFeature, getPointFeature } from 'src/common/components/map/util';
import { POLYLINE_COLORS } from 'src/common/constants';
import {
  VehicleBreadcrumbs,
  VehicleBreadCrumbsByRoutes,
  VehicleRouteBreadCrumbs,
} from 'src/dashboard/interfaces/vehiclePositions';

export const getMapboxTrackingForRouteGroupId = (tracking: VehicleRouteBreadCrumbs, groupIndex: number) =>
  +`${tracking.id}.${groupIndex}`;

export const getMapboxTrackingForVehicleGroupId = (tracking: VehicleBreadcrumbs, groupIndex: number) =>
  +`${tracking.id}.${groupIndex}`;

export type VehicleTrackingFeatureProperties = {
  id: number;
  clickable: boolean;
  routeId?: number;
  vehicleId?: number;
  groupIndex: number;
  color: string;
  source?: 'vehicle' | 'route';
};

export const getVehicleBreadcrumbsByRoutesGeoJSON = (routeVehiclesBreadCrumbs: VehicleBreadCrumbsByRoutes) =>
  getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    map(routeVehiclesBreadCrumbs.routes, (vehicleBreadcrumbs, index: number) => {
      let color = index;
      if (color > POLYLINE_COLORS.length - 1) color = 1;

      return map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
        getLineStringFeature(
          getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
          vehicleTracking.map(({ lat, lng }: any) => [lng, lat]),
          {
            id: getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
            clickable: true,
            routeId: vehicleBreadcrumbs.id,
            vehicleId: routeVehiclesBreadCrumbs.id,
            groupIndex: coordinateGroupIndex,
            color: POLYLINE_COLORS[color],
            source: 'vehicle' as 'vehicle',
            bearing: vehicleTracking.bg ? vehicleTracking.bg - 90 : undefined,
          },
        ),
      );
    }).reduce((acc, current) => acc.concat(current), []),
  );

export const getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON = (
  routeVehiclesBreadCrumbs: VehicleBreadCrumbsByRoutes,
) =>
  getFeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>(
    map(routeVehiclesBreadCrumbs.routes, (vehicleBreadcrumbs, index: number) => {
      let color = index;
      if (color > POLYLINE_COLORS.length - 1) color = 1;

      return map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
        vehicleTracking.map(({ lat, lng, bg }: any) =>
          getPointFeature(getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex), [lng, lat], {
            id: getMapboxTrackingForRouteGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
            clickable: true,
            routeId: vehicleBreadcrumbs.id,
            vehicleId: routeVehiclesBreadCrumbs.id,
            groupIndex: coordinateGroupIndex,
            color: POLYLINE_COLORS[color],
            source: 'vehicle' as 'vehicle',
            bearing: bg ? bg - 90 : undefined,
          }),
        ),
      ).reduce((acc, current) => {
        acc.push(...current);
        return acc;
      }, []);
    }).reduce((acc, current) => {
      acc.push(...current);
      return acc;
    }, []),
  );

export const getMapboxVehicleTrackingsForVehicleGeoJSON = (vehicleBreadcrumbs: VehicleBreadcrumbs) =>
  getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
      getLineStringFeature(
        getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
        vehicleTracking.map(({ lat, lng }: any) => [lng, lat]),
        {
          id: getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
          clickable: true,
          vehicleId: vehicleBreadcrumbs.id,
          groupIndex: coordinateGroupIndex,
          color: POLYLINE_COLORS[0],
          source: 'route' as 'route',
          bearing: vehicleTracking.bg ? vehicleTracking.bg - 90 : undefined,
        },
      ),
    ),
  );

export const getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON = (vehicleBreadcrumbs: VehicleBreadcrumbs) =>
  getFeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>(
    map(vehicleBreadcrumbs.coords, (vehicleTracking: any, coordinateGroupIndex: number) =>
      vehicleTracking.map(({ lat, lng, bg }: any) =>
        getPointFeature(getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex), [lng, lat], {
          id: getMapboxTrackingForVehicleGroupId(vehicleBreadcrumbs, coordinateGroupIndex),
          clickable: true,
          vehicleId: vehicleBreadcrumbs.id,
          groupIndex: coordinateGroupIndex,
          color: POLYLINE_COLORS[0],
          source: 'route' as 'route',
          bearing: bg ? bg - 90 : undefined,
        }),
      ),
    ).reduce((acc, current) => acc.concat(current), []),
  );
