import { createSelector } from 'reselect';
import { reduce, set, identity } from 'lodash-es';

interface ProximitySettingsState {
  proximitySettings: {
    vendorId: number;
    proximitySettings: {
      id: string | number;
      distance: number;
      measurementType: string;
      vehicleTypeId: number;
      locationType: string;
      customerTypeId: number;
    }[];
  };
}

const getProximitySettingsFormInitialValues = (proximitySettingsState: ProximitySettingsState) => ({
  vendorId: proximitySettingsState.proximitySettings.vendorId,
  proximitySettings: reduce(
    proximitySettingsState.proximitySettings.proximitySettings,
    (initialValues, { id, distance, measurementType, vehicleTypeId, locationType, customerTypeId }) =>
      set(initialValues, `settings${id}`, {
        distance,
        measurementType,
        vehicleTypeId,
        locationType,
        customerTypeId,
      }),
    {},
  ),
});

const proximitySettingsFormInitialValuesSelector = createSelector(getProximitySettingsFormInitialValues, identity);

export default proximitySettingsFormInitialValuesSelector;
