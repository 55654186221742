import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { DATE_RANGE_PICKER_3_7_30, TODAY } from '../../../core/constants';
import { DateRangePicker, PanelSearch } from '../../../core/components';
import translate from '../../../core/services/translate';
import unassignedWeightTicketsFormInitialValuesSelector from '../../services/unassignedWeightTicketsFormInitialValuesSelector';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

class UnassignedWeightTicketsForm extends PureComponent<InjectedFormProps> {
  render() {
    const { handleSubmit } = this.props;
    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <form onSubmit={handleSubmit}>
            <PanelSection padding="small xSmall">
              <Grid multiLine>
                <GridColumn size={isMobile ? '12/12' : '6/12'}>
                  <Field name="searchTerm" component={PanelSearch} />
                </GridColumn>

                <GridColumn margin={isMobile ? 'xSmall no' : undefined} size={isMobile ? '12/12' : '3/12'}>
                  <Field
                    name="date"
                    component={DateRangePicker}
                    numberOfMonths={isMobile ? 1 : undefined}
                    options={DATE_RANGE_PICKER_3_7_30}
                    margin="no"
                    maxInterval={{ amount: 30, unit: 'days' }}
                    props={{ hasMarginLeft: 'smaller' }}
                    disabledDays={[
                      {
                        after: TODAY,
                      },
                    ]}
                  />
                </GridColumn>
                <GridColumn size={isMobile ? '12/12' : '3/12'} align="right">
                  <Button color="primary">{translate('common.search')}</Button>
                </GridColumn>
              </Grid>
            </PanelSection>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = (_: unknown, ownProps: RouteComponentProps) => ({
  initialValues: unassignedWeightTicketsFormInitialValuesSelector(ownProps.location.search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'unassignedWeightTickets',
      enableReinitialize: true,
    })(UnassignedWeightTicketsForm),
  ),
);
