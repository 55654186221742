import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { filter, size } from 'lodash-es';
import humps from 'humps';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentUserIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { deleteUploadedDocument, loadCertifications, exportFile } from '../../ducks';
import { DuckFunction } from '../../../contracts/ducks';
import { Message, ModalTitle, ModalSubtitle, ModalSection } from '../../../core/components/styled';
import { Modal, Table } from '../../../core/components';
import { VIEW_TITLE } from '../../constants/modalTitles';
import { W9 } from '../../constants/documentTypesOption';
import confirm from '../../../core/services/confirm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import getModalTitle from '../../services/getModalTitle';
import translate from '../../../core/services/translate';
import ViewCertificationsTableRows from '../pages/ViewCertificationsTableRows';

interface Props extends RouteComponentProps {
  closeModal: (documentDeleted: boolean) => void;
  deleteUploadedDocument: DuckFunction<typeof deleteUploadedDocument>;
  loadCertifications: DuckFunction<typeof loadCertifications>;
  divisionId?: string;
  documentList: any[];
  documentType: string;
  exportFile(...args: any[]): any;
  gusId: string;
  userId: string;
  isviewCertificateNo: boolean;
  vendorGroupId?: string;
}

interface State {
  documentDeleted: boolean;
  documents: any[];
  isDeleting: boolean;
  isDownloading: boolean;
}

class ViewCertificationsModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      documentDeleted: false,
      documents: props.documentList,
      isDeleting: false,
      isDownloading: false,
    };
  }

  downloadCertification = async (event: any, fileId: number, fileName: string) => {
    const { exportFile, divisionId, vendorGroupId } = this.props;

    this.setState({ isDownloading: true });

    await exportFile(fileId, fileName, vendorGroupId, divisionId)
      .then(() => createSuccessNotification(translate('common.alertMessages.downloadCompleted')))
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.downloadFailed'));
      });

    this.setState({ isDownloading: false });
  };

  deleteDocument = async (documentId: number) => {
    const { divisionId, gusId, userId, deleteUploadedDocument, loadCertifications } = this.props;
    if (!(await confirm(translate('haulerProfile.alertMessages.confirmDeleteDocument')))) {
      return;
    }

    this.setState({ isDeleting: true });

    await deleteUploadedDocument(gusId, documentId, userId, divisionId)
      .then(() => {
        createSuccessNotification(translate('haulerProfile.alertMessages.deleteCompleted'));

        loadCertifications(gusId, divisionId);

        const { documents } = this.state;
        const filtereddocuments = filter(documents, (document: any) => document.id !== documentId);
        this.setState({ documents: filtereddocuments, documentDeleted: true });
      })
      .catch((e: any) => {
        const errorMessageCode =
          e && e.response && e.response.data && e.response.data.message && humps.camelize(e.response.data.message);

        createErrorNotification(
          errorMessageCode
            ? translate(createTranslationKey(errorMessageCode, 'haulerProfile.alertMessages'))
            : translate('haulerProfile.alertMessages.deleteFailed'),
        );
      });

    this.setState({ isDeleting: false });
  };

  render() {
    const { closeModal, documentType, isviewCertificateNo, userId } = this.props;
    const { isDownloading, documents, documentDeleted, isDeleting } = this.state;

    const viewCertificationsTableCells = [
      { name: 'description', label: translate('common.description'), width: '30%' },
      { name: 'certification', label: translate('haulerProfile.certificationNo'), width: '20%' },
      { name: 'startDate', label: translate('common.startDate'), width: '15%' },
      { name: 'endDate', label: translate('common.endDate'), width: '15%' },
      { name: 'actions', label: translate('common.options'), width: '20%', align: 'right' },
    ];

    const viewComplianceTableCells = [
      { name: 'description', label: translate('common.description'), width: '50%' },
      { name: 'startDate', label: translate('common.startDate'), width: '15%' },
      { name: 'endDate', label: translate('common.endDate'), width: '15%' },
      { name: 'actions', label: translate('common.options'), width: '20%', align: 'right' },
    ];

    const viewComplianceW9TableCells = [
      { name: 'description', label: translate('common.description'), width: '80%' },
      { name: 'actions', label: translate('common.options'), width: '20%', align: 'right' },
    ];

    const documentsTableCells = isviewCertificateNo
      ? viewCertificationsTableCells
      : documentType === W9
      ? viewComplianceW9TableCells
      : viewComplianceTableCells;

    return (
      <Modal
        onClose={() => closeModal(documentDeleted)}
        padding="medium"
        size="mediumLarge"
        isLoading={isDownloading || isDeleting}
      >
        <ModalTitle>{getModalTitle(VIEW_TITLE, documentType)}</ModalTitle>
        <ModalSubtitle>{translate('haulerProfile.xUploaded', { count: size(documents) })}</ModalSubtitle>
        <ModalSection margin="medium no no">
          {size(documents) ? (
            <Table
              cells={documentsTableCells}
              rows={documents}
              rowComponent={ViewCertificationsTableRows}
              rowProps={{
                deleteDocument: this.deleteDocument,
                downloadCertification: this.downloadCertification,
                isviewCertificateNo,
                userId,
                documentType,
              }}
            />
          ) : (
            <Message padding="sMedium">{translate('haulerProfile.noDocumentsUploaded')}</Message>
          )}
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorGroupId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor) as any as string,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor) as any as string,
});

const mapDispatchToProps = {
  deleteUploadedDocument,
  exportFile,
  loadCertifications,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewCertificationsModal));
