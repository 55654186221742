import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { PickupStatusesMultiSelect } from 'src/common/components';
import { DateRangePicker, Input, TypeAhead, TypedField } from 'src/core/components';
import { Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import searchVendorLocations from 'src/customers/services/adhocServiceHistory';
import translate from 'src/core/services/translate';

interface FormValues {
  locationId: string;
  dateRange: { from: string; to: string };
  pickupStatusTypeIds: string[];
  workOrderNumber: string;
}

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const AdhocServiceHistoryForm: React.FC<Props> = ({ handleSubmit }) => {
  const vendorId = useSelector(currentVendorId);
  const loadVendorByLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    searchVendorLocations(vendorId, searchTerm).then(onOptionsLoaded);
  }, 500);
  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="small xSmall" withBorder>
        <Grid>
          <GridColumn size="3/12">
            <TypedField
              name="locationId"
              component={TypeAhead}
              props={{
                getOptions: loadVendorByLocations,
                id: `adhoc-service-history-form-location-id-field`,
                isClearable: true,
                margin: 'no',
                placeholder: `${translate('common.address')}`,
              }}
            />
          </GridColumn>
          <GridColumn>
            <TypedField
              name="workOrderNumber"
              component={Input}
              props={{
                placeholder: translate('autoDispatch.workOrder'),
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="2/12">
            <TypedField
              name="pickupStatusTypeIds"
              component={PickupStatusesMultiSelect}
              props={{
                placeholder: translate('common.allStatuses'),
                multiSelectProps: { margin: 'no', normalizeValues: Number },
              }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="dateRange"
              component={DateRangePicker}
              props={{
                maxInterval: { amount: 90, unit: 'days' },
                margin: 'no',
                hasMarginLeft: 'smaller',
                middleLineHasDifferentMargin: true,
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'adhocServiceHistory',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(AdhocServiceHistoryForm);
