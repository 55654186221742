import { map, filter } from 'lodash-es';

export const transformSearchCustomerLocations = (customers: any[]) => {
  const result: any[] = [];
  map(
    filter(customers, customer => customer.locations.length),
    customer => {
      map(customer.locations, location => {
        const label = `${customer.name} > ${location.address.line1}`;
        const options = [
          {
            label: location.name,
            value: location.id,
          },
        ];
        result.push({ label, options });
      });
    },
  );
  return result;
};
