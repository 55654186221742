import styled from 'styled-components';

export const ResourceEditorFormElement = styled.form`
  width: 100%;
`;

export const SectionWrapper = styled.div`
  max-height: 300px;
  overflow: auto;
`;
