import { Fragment, PureComponent } from 'react';

import { Field } from 'redux-form';

import { ActionButtonTooltip, LocationPicker, TypeAhead } from '../../core/components';
import { GridColumn, GridFieldWrapper, FormGroupClearContainer, FormGroupClear } from '../../core/components/styled';
import { isRequired } from '../../utils/services/validator';
import { SelectOnMap } from './styled';
import translate from '../../core/services/translate';

interface Props {
  colSize?: number;
  fieldName: string;
  formShouldRefresh?: boolean;
  getOptions?: (searchTerm: string, onOptionsLoaded: () => void) => void;
  handleClearAddress?: () => void;
  handleEnteredAddress?: (address: any) => void;
  handlePinClick: () => void;
  hasLocationSelected?: boolean;
  holdSelectReference?: (ref: TypeAhead) => void;
  isDeliveryUtility?: boolean;
  isFieldFullWidth?: boolean;
  isPinIconAbsolute?: boolean;
  isPinOnMapSelected: boolean;
  margin?: string;
  pinOnMapTooltip?: string;
  renderCustomerLocationOptionLabel?: (option: any) => void;
}

class CustomerLocationWithPin extends PureComponent<Props> {
  handleEnteredAddress = (address: any) => {
    const { handleEnteredAddress } = this.props;
    handleEnteredAddress && handleEnteredAddress(address);
  };

  handleKeyPress = (e: any) => e.key === 'Enter' && e.preventDefault();

  render() {
    const {
      colSize = 6,
      fieldName,
      formShouldRefresh,
      getOptions,
      handleClearAddress,
      handlePinClick,
      hasLocationSelected,
      holdSelectReference,
      isDeliveryUtility,
      isFieldFullWidth,
      isPinIconAbsolute,
      isPinOnMapSelected,
      margin = 'sMedium no no',
      pinOnMapTooltip,
      renderCustomerLocationOptionLabel,
    } = this.props;

    const validation = isPinOnMapSelected ? [] : [isRequired];

    const handlePinClickEvent = (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      handlePinClick();
    };

    return (
      <Fragment>
        {isFieldFullWidth ? (
          <>
            <GridColumn size={`${colSize - 2}/12`}>
              <Field
                component={LocationPicker}
                name="locationNameAddress"
                label={translate('common.materialTickets.location')}
                validate={[isRequired]}
                onChange={this.handleEnteredAddress}
                onKeyPress={this.handleKeyPress}
              />
            </GridColumn>
            <GridColumn
              isGridWithIconAbsolute={isPinIconAbsolute}
              isPinIconAbsolute={isPinIconAbsolute}
              isFieldFullWidth={isFieldFullWidth}
              margin={margin}
              padding="no"
              size="1/12"
            >
              {hasLocationSelected && (
                <FormGroupClearContainer>
                  <FormGroupClear onClick={handleClearAddress} />
                </FormGroupClearContainer>
              )}
            </GridColumn>
          </>
        ) : (
          <>
            {isPinOnMapSelected ? (
              <Fragment>
                <GridColumn size={`${colSize - 2}/12`}>
                  <GridFieldWrapper isPinOnMapSelected={isPinOnMapSelected} isPinIconAbsolute={isPinIconAbsolute}>
                    <Field
                      name="pinAddress"
                      component={LocationPicker}
                      disabled
                      disabledDarker
                      props={{
                        margin,
                      }}
                    />
                  </GridFieldWrapper>
                </GridColumn>
                <GridColumn
                  isGridWithIconAbsolute={isPinIconAbsolute}
                  isPinIconAbsolute={isPinIconAbsolute}
                  margin={margin}
                  padding="no"
                  size="1/12"
                >
                  <FormGroupClearContainer>
                    <FormGroupClear onClick={handleClearAddress} />
                  </FormGroupClearContainer>
                </GridColumn>
              </Fragment>
            ) : (
              <GridColumn margin={margin} size={`${colSize - 1}/12`}>
                <GridFieldWrapper isPinIconAbsolute={isPinIconAbsolute}>
                  <Field
                    component={TypeAhead}
                    getOptions={getOptions}
                    name={fieldName}
                    isClearable
                    placeholder={`${translate('common.customer')} / ${translate('common.location')}`}
                    validate={validation}
                    props={{
                      getOptionLabel: renderCustomerLocationOptionLabel,
                      ref: holdSelectReference,
                      formShouldRefresh,
                      id: 'route-add-location',
                      margin: 'no',
                    }}
                  />
                </GridFieldWrapper>
              </GridColumn>
            )}
          </>
        )}

        <GridColumn
          isPinIconAbsolute={isPinIconAbsolute}
          isDeliveryUtility={isDeliveryUtility}
          isPinOnMapSelected={isPinOnMapSelected}
          isFieldFullWidth={isFieldFullWidth}
          margin={margin}
          padding="no"
          size="1/12"
        >
          <SelectOnMap onClick={handlePinClickEvent}>
            <ActionButtonTooltip icon="pinOnMap" tooltip={pinOnMapTooltip || 'pinJobLocationOnMap'} size="medium" />
          </SelectOnMap>
        </GridColumn>
      </Fragment>
    );
  }
}

export default CustomerLocationWithPin;
