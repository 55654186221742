import { find, map, reduce, size } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { Checkbox, Icon, PanelSearch, TypedField } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import translate from 'src/core/services/translate';
import { theme } from 'src/core/styles';
import {
  DashboardExtraFiltersPanelBody,
  DashboardExtraFiltersPanelContainer,
  DashboardExtraFiltersPanelHeader,
  DashboardExtraFiltersPanelItem,
  DashboardExtraFiltersPanelSearch,
} from '../../styled/DashboardFilterMapbox';

interface DashboardExtraFiltersPanelProps {
  formName: string;
  title: string;
  icon: string;
  name: string;
  filters: {
    label: string;
    name: string;
    disabled?: boolean;
    subFilters?: {
      name: string;
      label: string;
      disabled?: boolean;
      subFilters?: {
        name: string;
        label: string;
        disabled?: boolean;
      }[];
    }[];
  }[];
  isSearchable?: boolean;
  isLoading?: boolean;
  customNoDataMessage?: string;
  toggleName?: string;
}

const DashboardExtraFiltersPanel = ({
  formName,
  title,
  icon,
  name,
  filters,
  isSearchable,
  isLoading,
  customNoDataMessage,
  toggleName,
}: DashboardExtraFiltersPanelProps) => {
  const dispatch = useDispatch();

  const handleGroupSelect: TypedFieldOnChangeFunction = (_, value, __, fieldName) => {
    const splitFieldName = fieldName.split('.');
    const individualElementFieldName =
      splitFieldName.length > 1 ? splitFieldName[splitFieldName.length - 2] : undefined;

    const groupFilters = filters.find(
      filter => `${name}.groups.${filter.name}.isGroupSelected` === fieldName,
    )?.subFilters;

    const subFilters = find(filters, filter => {
      return !!find(filter.subFilters, subFilter => {
        return `${name}.groups.${filter.name}.groupFilters.${subFilter.name}.isGroupSelected` === fieldName;
      });
    })?.subFilters?.find(subFilter => {
      return subFilter.name === individualElementFieldName;
    })?.subFilters;

    if (groupFilters) {
      const fieldNameShort = fieldName.substring(0, fieldName.lastIndexOf('.'));

      const updateAllValues = reduce(
        groupFilters,
        (acc, subFilter) => {
          if (!!size(subFilter.subFilters)) {
            acc[`${subFilter.name}`] = {
              groupFilters: reduce(
                subFilter.subFilters,
                (accSub, subSubFilter) => {
                  accSub[`${subSubFilter.name}`] = value;
                  return accSub;
                },
                {} as any,
              ),
              isGroupSelected: value,
            };
          } else {
            acc[`${subFilter.name}`] = value;
          }
          return acc;
        },
        {} as any,
      );

      dispatch(change(formName, `${fieldNameShort}.groupFilters`, updateAllValues));
      toggleName && value && dispatch(change(formName, toggleName, true));
    } else if (subFilters) {
      const fieldNameShort = fieldName.substring(0, fieldName.lastIndexOf('.'));

      const updateAllValues = reduce(
        subFilters,
        (acc, subSubFilter) => {
          acc[`${subSubFilter.name}`] = value;
          return acc;
        },
        {} as any,
      );

      dispatch(change(formName, `${fieldNameShort}.groupFilters`, updateAllValues));

      toggleName && value && dispatch(change(formName, toggleName, true));
    }
  };

  const handleItemSelect: TypedFieldOnChangeFunction = (_, value, __) => {
    toggleName && value && dispatch(change(formName, toggleName, true));
  };

  return (
    <DashboardExtraFiltersPanelContainer>
      <DashboardExtraFiltersPanelHeader>
        <Icon width="30px" height="30px" icon={icon} color={theme.brandPrimary} />
        <Text size="large">{title}</Text>
      </DashboardExtraFiltersPanelHeader>
      {isSearchable && (
        <DashboardExtraFiltersPanelSearch>
          <TypedField
            name={`${name}.searchTerm`}
            component={PanelSearch}
            props={{ margin: 'no no', isClearable: true }}
          />
        </DashboardExtraFiltersPanelSearch>
      )}

      <DashboardExtraFiltersPanelBody isLoading={isLoading}>
        {size(filters) > 0 ? (
          map(filters, filter => (
            <Box key={filter.name} display="block">
              <DashboardExtraFiltersPanelItem>
                <TypedField
                  name={`${name}.groups.${filter.name}.isGroupSelected`}
                  component={Checkbox}
                  onChange={handleGroupSelect}
                  props={{ margin: 'no', label: filter.label, block: true, disabled: !!filter.disabled }}
                />
              </DashboardExtraFiltersPanelItem>
              {filter.subFilters && !size(filter.subFilters) && (
                <Text margin="small" size="xSmall" block>
                  <em>{translate('routes.planner.noDataMatches')}</em>
                </Text>
              )}
              {map(filter.subFilters, subFilter => (
                <Box display="block" key={subFilter.name}>
                  <DashboardExtraFiltersPanelItem isSubFilterHeader={!!subFilter.subFilters} subFilterLevel={1}>
                    <TypedField
                      name={
                        !!subFilter.subFilters
                          ? `${name}.groups.${filter.name}.groupFilters.${subFilter.name}.isGroupSelected`
                          : `${name}.groups.${filter.name}.groupFilters.${subFilter.name}`
                      }
                      component={Checkbox}
                      onChange={!!subFilter.subFilters ? handleGroupSelect : handleItemSelect}
                      props={{
                        margin: 'no',
                        label: subFilter.label,
                        block: true,
                        size: 'small',
                        disabled: !!subFilter.disabled || !!filter.disabled,
                      }}
                    />
                  </DashboardExtraFiltersPanelItem>
                  {map(subFilter.subFilters, subSubFilter => (
                    <DashboardExtraFiltersPanelItem subFilterLevel={2} key={subSubFilter.name}>
                      <TypedField
                        key={subSubFilter.name}
                        name={`${name}.groups.${filter.name}.groupFilters.${subFilter.name}.groupFilters.${subSubFilter.name}`}
                        component={Checkbox}
                        onChange={handleItemSelect}
                        props={{
                          margin: 'no',
                          label: subSubFilter.label,
                          block: true,
                          size: 'small',
                          disabled: !!subSubFilter.disabled || !!filter.disabled || !!subFilter.disabled,
                        }}
                      />
                    </DashboardExtraFiltersPanelItem>
                  ))}

                  {subFilter.subFilters && !size(subFilter.subFilters) && (
                    <Text key={`${subFilter.name}_message`} margin="xxSmall no small medium" size="xSmall" block>
                      <em>{translate('routes.planner.noDataMatches')}</em>
                    </Text>
                  )}
                </Box>
              ))}
            </Box>
          ))
        ) : (
          <Text margin="small" block>
            <em>{customNoDataMessage ? customNoDataMessage : translate('routes.planner.noData')}</em>
          </Text>
        )}
      </DashboardExtraFiltersPanelBody>
    </DashboardExtraFiltersPanelContainer>
  );
};

export default DashboardExtraFiltersPanel;
