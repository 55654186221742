import { change, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { ChangeEvent } from 'react';
import { connect, useDispatch } from 'react-redux';

import { AdvancedSearchFormValues } from 'src/dashboard/interfaces/routesData';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { Input, TypedField } from '../../../core/components';
import { minLength3 } from 'src/utils/services/validator';
import { useSelector } from 'src/core/hooks/useSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<AdvancedSearchFormValues, PropsWithoutReduxForm>;

const ADVANCED_SEARCH_FORM = 'AdvancedSearchForm';

const AdvancedSearchForm: React.FC<Props> = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const formValues = useSelector(getFormValues(ADVANCED_SEARCH_FORM)) as AdvancedSearchFormValues;

  const onSearchTermChange = (event: ChangeEvent) => {
    dispatch(change(ADVANCED_SEARCH_FORM, 'accountNumber', undefined));
  };

  const onAccountNumberChange = (event: ChangeEvent) => {
    dispatch(change(ADVANCED_SEARCH_FORM, 'customerLocationAddress', undefined));
  };

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="small xSmall no">
        <Grid multiLine>
          <GridColumn size="5/12">
            <TypedField
              name="customerLocationAddress"
              component={Input}
              validate={[minLength3]}
              props={{
                label: translate('customers.customerSearch'),
                disabled: !!formValues?.accountNumber,
              }}
              onChange={event => onSearchTermChange(event)}
            />
          </GridColumn>
          <GridColumn size="5/12">
            <TypedField
              name="accountNumber"
              component={Input}
              validate={[minLength3]}
              props={{
                label: translate('customers.accountNumber'),
                disabled: !!formValues?.customerLocationAddress,
              }}
              onChange={event => onAccountNumberChange(event)}
            />
          </GridColumn>

          <GridColumn size="2/12" alignVerticalCenter>
            <Button type="submit" color="primary">
              {translate('common.search')}
            </Button>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};
export default connect()(
  reduxForm<AdvancedSearchFormValues, PropsWithoutReduxForm>({
    form: ADVANCED_SEARCH_FORM,
    onSubmitFail: focusFirstInvalidField,
  })(AdvancedSearchForm),
);
