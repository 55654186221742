import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadFuelingTicketsSettings, loadSnowPlowSettings, loadStreetSweepingSettings } from 'src/vendors/ducks';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { loadPermissions, shouldLoadPermissionsSelector } from '../../../../account/ducks';
import { DocumentTitle } from '../../../../common/components';
import { PageLoading } from '../../../../common/components/styled';
import { DuckFunction } from '../../../../contracts/ducks';
import { TypedResolver } from '../../../../core/components';
import translate from '../../../../core/services/translate';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import store, { AppState } from '../../../../store';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadFeatures } from '../../../../vendors/ducks';
import { currentUserIdSelector, currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { SEQUENCE_SOURCE_TYPE_DAILY_ROUTE } from '../../../constants';
import { loadGroups, loadRouteSequenceStatusByVendor, loadRoutesTracker, loadServiceZones } from '../../../ducks';
import { loadSupervisors } from '../../../ducks/supervisors';
import RouteTrackerPage from './RouteTrackerPage';

interface Props extends RouteComponentProps {
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadFuelingTicketsSettings: DuckFunction<typeof loadFuelingTicketsSettings>;
  loadGroups: DuckFunction<typeof loadGroups>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadRouteSequenceStatusByVendor: DuckFunction<typeof loadRouteSequenceStatusByVendor>;
  loadRoutesTracker: DuckFunction<typeof loadRoutesTracker>;
  loadServiceZones: DuckFunction<typeof loadServiceZones>;
  loadSnowPlowSettings: DuckFunction<typeof loadSnowPlowSettings>;
  loadStreetSweepingSettings: DuckFunction<typeof loadStreetSweepingSettings>;
  loadSupervisors: DuckFunction<typeof loadSupervisors>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  shouldLoadPermissions: boolean;
  userId: string;
  vendorId: number;
}

class RouteTrackerPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      loadFeatures,
      loadFuelingTicketsSettings,
      loadFiltersSettings,
      loadGroups,
      loadPermissions,
      loadRouteSequenceStatusByVendor,
      loadRoutesTracker,
      loadServiceZones,
      loadSnowPlowSettings,
      loadStreetSweepingSettings,
      loadSupervisors,
      loadVehicleTypesForVendor,
      location: { search },
      shouldLoadPermissions,
      userId,
      vendorId,
    } = this.props;

    const { endDate, routeStatusTypeIds, searchTerm, serviceZones, startDate, supervisors, vehicleTypeIds, groupIds } =
      getQueryParams(search);

    await loadFeatures(vendorId);

    const promises = [
      loadRoutesTracker({
        vendorId,
        searchTerm,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        serviceZones,
        supervisors,
        groupIds,
        useUserGlobalFilters: !(serviceZones || supervisors || vehicleTypeIds),
      }),
      loadRouteSequenceStatusByVendor(vendorId, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE),
      loadServiceZones(vendorId),
      loadSupervisors(vendorId),
      loadGroups(vendorId),
      loadFiltersSettings(vendorId, userId),
      loadFuelingTicketsSettings(vendorId),
      loadVehicleTypesForVendor(vendorId),
    ];

    const isSnowPlowFeatureEnabled = checkIfSnowPlowIsEnabled(store.getState());
    const isStreetSweepingFeatureEnabled = checkIfStreetSweepingIsEnabled(store.getState());

    if (isSnowPlowFeatureEnabled) promises.push(loadSnowPlowSettings(vendorId));
    if (isStreetSweepingFeatureEnabled) promises.push(loadStreetSweepingSettings(vendorId));

    if (shouldLoadPermissions) promises.push(loadPermissions());

    return Promise.all(promises);
  };
  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.tracker')}</DocumentTitle>
        <TypedResolver
          successComponent={RouteTrackerPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/dashboard"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  userId: currentUserIdSelector(state.account.login) as any,
});

const mapDispatchToProps = {
  loadFeatures,
  loadFiltersSettings,
  loadFuelingTicketsSettings,
  loadGroups,
  loadPermissions,
  loadRouteSequenceStatusByVendor,
  loadRoutesTracker,
  loadServiceZones,
  loadSnowPlowSettings,
  loadStreetSweepingSettings,
  loadSupervisors,
  loadVehicleTypesForVendor,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTrackerPageResolver));
