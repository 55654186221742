import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, RouteComponentProps, RouteProps } from 'react-router';

import { hasPermissionSelector } from '../ducks';
import { AppState } from '../../store';

type Base = RouteProps & RouteComponentProps;

interface ComponentProps extends Base {
  permission: any;
}

interface Props extends ComponentProps {
  hasPermission?: boolean;
}

const RestrictedRoute: React.FC<Props> = ({ hasPermission, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      hasPermission && Component ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const mapStateToProps = (state: AppState, { permission }: ComponentProps) => ({
  hasPermission: (hasPermissionSelector as any)(state.account.permissions, permission),
});

export default withRouter(connect(mapStateToProps)(RestrictedRoute));
