import { AnyAction, Dispatch } from 'redux';
import Cookie from 'js-cookie';
import moment from 'moment';
import update from 'immutability-helper';

import {
  connectToMessagesWebsocket,
  getMessageThreads,
  getThreadMessages,
  postMessage,
  postReadMessage,
} from '../services/messaging';
import { createErrorNotification } from 'src/core/services/createNotification';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { GetStateFunction } from 'src/contracts/ducks';
import { Message } from '../interfaces/Message';
import { MessageReceipt } from '../interfaces/MessageReceipt';
import { MessageThread } from '../interfaces/MessageThread';
import { SELECTED_VENDOR_ID_COOKIE } from 'src/common/constants';
import { SentMessage } from '../interfaces/SentMessage';
import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import translate from 'src/core/services/translate';

const sessionId = 'messagingSession_' + Math.random();

// Actions
const COMPLETE_LOAD_THREAD_MESSAGES = 'messaging/COMPLETE_LOAD_THREAD_MESSAGES';
const COMPLETE_LOAD_THREADS = 'messaging/COMPLETE_LOAD_THREADS';
const FAIL_LOAD_THREAD_MESSAGES = 'messaging/FAIL_LOAD_THREAD_MESSAGES';
const FAIL_LOAD_THREADS = 'messaging/FAIL_LOAD';
const RESET_THREAD_MESSAGES = 'messaging/RESET_THREAD_MESSAGES';
const SET_ACTIVE_THREAD = 'messaging/SET_ACTIVE_THREAD';
const SET_SEND_NEW_MESSAGE = 'messaging/SET_SEND_NEW_MESSAGE';
const START_LOAD_THREAD_MESSAGES = 'messaging/START_LOAD_THREAD_MESSAGES';
const START_LOAD_THREADS = 'messaging/START_LOAD_THREADS';

const ADD_MESSAGE_TO_THREAD = 'messaging/ADD_MESSAGE_TO_THREAD';
const REPLACE_TEMPORARY_MESSAGE_IN_THREAD = 'messaging/REPLACE_TEMPORARY_MESSAGE_IN_THREAD';
const READ_THREAD = 'messaging/READ_THREAD';

const ADD_RECEIVED_MESSAGE = 'messaging/ADD_RECEIVED_MESSAGE';
const ADD_READ_RECEIPT = 'messaging/ADD_READ_RECEIPT';

interface State {
  activeThread?: MessageThread;
  activeThreadMessages: Message[];
  connectionToSignalRFailed: boolean;
  hasFailedLoadingThreadMessagesMessages: boolean;
  hasFailedLoadingThreads: boolean;
  isLoadingMessages: boolean;
  isLoadingThreads: boolean;
  loadMessagesFailed: boolean;
  loadThreadsFailed: boolean;
  messageThreads: MessageThread[];
  sendNewMessage: boolean;
}

// Initial state
const initialState: State = {
  activeThreadMessages: [],
  connectionToSignalRFailed: false,
  hasFailedLoadingThreadMessagesMessages: false,
  hasFailedLoadingThreads: false,
  isLoadingMessages: false,
  isLoadingThreads: false,
  loadMessagesFailed: false,
  loadThreadsFailed: false,
  messageThreads: [],
  sendNewMessage: false,
};

const sortMessages = (messageList: Message[]) =>
  messageList.sort((a, b) => (a.sentDate! < b.sentDate! ? -1 : a.sentDate! > b.sentDate! ? 1 : 0));

const sortThreads = (threadList: MessageThread[]) =>
  threadList.sort((a, b) => (a.sentDate! < b.sentDate! ? 1 : a.sentDate! > b.sentDate! ? -1 : 0));

const _7daysMs = 604800000;
let threadCleanupTimeoutId: number;

const setThreadCleanupTimeout = (threads: MessageThread[], vendorId: number, dispatch: Dispatch) => {
  if (threads.length) {
    const lastThread = threads[threads.length - 1];
    const lastThreadDateMs = moment.utc(lastThread.sentDateUtc).local().valueOf();
    if (threadCleanupTimeoutId) {
      window.clearTimeout(threadCleanupTimeoutId);
    }
    threadCleanupTimeoutId = window.setTimeout(() => {
      loadMessageThreads(vendorId)(dispatch);
    }, _7daysMs - (Date.now() - lastThreadDateMs));
  }
};

const updateThread = (message: Message, state: State) => {
  const messageThread = state.messageThreads.find(t => t.threadId === message.threadId);
  if (messageThread) {
    if (message.fromDriverId) {
      messageThread.numberOfUnreadMessages++;
    }
    messageThread.sentDate = message.sentDate;
    messageThread.lastMessageText = message.messageText;
  }
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_THREADS:
      return update(state, {
        $merge: {
          isLoadingThreads: true,
          hasFailedLoadingThreads: false,
        },
      });

    case SET_SEND_NEW_MESSAGE:
      return update(state, {
        $merge: {
          sendNewMessage: action.sendNewMessage,
        },
      });

    case COMPLETE_LOAD_THREADS:
      return update(state, {
        $merge: {
          isLoadingThreads: false,
          messageThreads: action.messageThreads,
        },
      });

    case FAIL_LOAD_THREADS:
      return update(state, {
        $merge: {
          isLoadingThreads: false,
          hasFailedLoadingThreads: true,
        },
      });

    case START_LOAD_THREAD_MESSAGES:
      return update(state, {
        $merge: {
          isLoadingMessages: true,
          hasFailedLoadingThreadMessagesMessages: false,
        },
      });

    case COMPLETE_LOAD_THREAD_MESSAGES:
      return update(state, {
        $merge: {
          isLoadingMessages: false,
          activeThreadMessages: action.activeThreadMessages || [],
        },
      });

    case FAIL_LOAD_THREAD_MESSAGES:
      return update(state, {
        $merge: {
          isLoadingMessages: false,
          hasFailedLoadingThreadMessagesMessages: true,
        },
      });

    case SET_ACTIVE_THREAD:
      return update(state, {
        $merge: {
          sendNewMessage: false,
          activeThread: action.activeThread,
          activeThreadMessages: [],
        },
      });

    case ADD_MESSAGE_TO_THREAD:
      return update(state, {
        $merge: {
          activeThreadMessages: [...state.activeThreadMessages, action.message],
        },
      });

    case REPLACE_TEMPORARY_MESSAGE_IN_THREAD:
      const newMessages = [...state.activeThreadMessages];
      const temporaryMessageIndex = newMessages.findIndex(m => m.messageId === action.temporaryMessageId);
      if (~temporaryMessageIndex) {
        newMessages[temporaryMessageIndex] = action.message;
        sortMessages(newMessages);
        updateThread(newMessages.slice(-1)[0], state);
      }
      return update(state, {
        $merge: {
          messageThreads: sortThreads(state.messageThreads.slice()),
          activeThreadMessages: newMessages,
        },
      });

    case READ_THREAD:
      const lastReadMesage = action.lastReadMessage;
      const thread = state.messageThreads.find(t => t.threadId === lastReadMesage.threadId);
      let activeMessages = state.activeThreadMessages;
      if (activeMessages[0] && activeMessages[0].threadId === lastReadMesage.threadId) {
        activeMessages = state.activeThreadMessages.map(m => {
          if (m.sentDate <= lastReadMesage.sentDate) {
            m.isMessageRead = true;
          }
          return m;
        });
      }
      if (thread) {
        thread.numberOfUnreadMessages = 0;
      }
      return update(state, {
        $merge: {
          messageThreads: state.messageThreads.slice(),
          activeThreadMessages: activeMessages,
        },
      });

    case ADD_RECEIVED_MESSAGE:
      const message = action.message as Message;
      let allMessages = state.activeThreadMessages;
      if (state.activeThread && state.activeThread.threadId === message.threadId) {
        allMessages = [...state.activeThreadMessages, message];
      }
      updateThread(message, state);
      return update(state, {
        $merge: {
          messageThreads: sortThreads(state.messageThreads.slice()),
          activeThreadMessages: sortMessages(allMessages),
        },
      });

    case ADD_READ_RECEIPT:
      const receipt = action.receipt as MessageReceipt;
      let threadMessages = state.activeThreadMessages;
      if (state.activeThread && state.activeThread.threadId === receipt.threadId) {
        const msg = threadMessages.find(m => m.messageId === receipt.messageId);

        if (msg) {
          threadMessages.forEach(m => {
            if (m.sentDate <= msg.sentDate) {
              m.recipients = m.recipients || [];
              if (!m.recipients.find(r => r.name === receipt.name)) m.recipients.push(receipt);
            }
          });
        }
        threadMessages = threadMessages.slice();
      }

      return update(state, {
        $merge: {
          activeThreadMessages: sortMessages(threadMessages),
        },
      });

    case RESET_THREAD_MESSAGES:
      return update(state, {
        $merge: {
          activeThreadMessages: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoadThreads = () => ({
  type: START_LOAD_THREADS,
});

const completeLoadThreads = (messageThreads: MessageThread[]) => ({
  type: COMPLETE_LOAD_THREADS,
  messageThreads,
});

const failLoadThreads = () => ({
  type: FAIL_LOAD_THREADS,
});

const startLoadThreadMesages = () => ({
  type: START_LOAD_THREAD_MESSAGES,
});

const completeLoadThreadMessages = (activeThreadMessages: Message[]) => ({
  type: COMPLETE_LOAD_THREAD_MESSAGES,
  activeThreadMessages,
});

const failLoadThreadMessages = () => ({
  type: FAIL_LOAD_THREAD_MESSAGES,
});

export const setSendNewMessage = (sendNewMessage: boolean) => ({
  type: SET_SEND_NEW_MESSAGE,
  sendNewMessage,
});

const setActiveThread = (activeThread?: MessageThread) => ({
  type: SET_ACTIVE_THREAD,
  activeThread,
});

const addMessageToThread = (message: Message) => ({
  type: ADD_MESSAGE_TO_THREAD,
  message,
});

const readThread = (lastReadMessage: Message) => ({
  type: READ_THREAD,
  lastReadMessage,
});

const replaceTemporaryMessageInThread = (message: Message, temporaryMessageId: number) => ({
  type: REPLACE_TEMPORARY_MESSAGE_IN_THREAD,
  message,
  temporaryMessageId,
});

const addReceivedMessage = (message: Message) => ({
  type: ADD_RECEIVED_MESSAGE,
  message,
});

const addReadReceipt = (receipt: MessageReceipt) => ({
  type: ADD_READ_RECEIPT,
  receipt,
});

const resetThreadMessages = () => ({
  type: RESET_THREAD_MESSAGES,
});

export const connectToMessagingSocket =
  (vendorId: number, userId: string) => (dispatch: Dispatch, getState: GetStateFunction) => {
    connectToMessagesWebsocket(
      userId,
      message => {
        dispatch(addReceivedMessage(message));
        if (!getState().messaging.messageThreads.find(t => t.threadId === message.threadId)) {
          loadMessageThreads(vendorId)(dispatch);
        }
      },
      readReceipt => {
        dispatch(addReadReceipt(readReceipt));
      },
      message => {
        if (message.sessionId !== sessionId) {
          loadMessageThreads(vendorId)(dispatch);
          if (getState().messaging.activeThread?.threadId === message.threadId) {
            loadThreadMessages(vendorId, message.threadId!)(dispatch);
          }
        }
      },
      readReceipt => {
        if (readReceipt.sessionId !== sessionId) {
          loadMessageThreads(vendorId)(dispatch);
        }
      },
    );
  };

export const loadMessageThreads = (vendorId: number) => (dispatch: Dispatch) => {
  let messageThreadPromise: any;
  if (Cookie.get(SESSION_COOKIE_KEY)) {
    // if the user can change the vendor a cookie will be stored for the selected vendor
    if (Cookie.get(SELECTED_VENDOR_ID_COOKIE)) {
      if (getIsVendorNotChanged(vendorId)) {
        dispatch(startLoadThreads());
        messageThreadPromise = getMessageThreads(vendorId);
        messageThreadPromise
          .then((threads: MessageThread[]) => {
            setThreadCleanupTimeout(threads, vendorId, dispatch);
            dispatch(completeLoadThreads(threads));
          })
          .catch(() => {
            dispatch(failLoadThreads());
            setTimeout(() => loadMessageThreads(vendorId)(dispatch), 5000);
          });
        return messageThreadPromise;
      }
    } else {
      dispatch(startLoadThreads());
      messageThreadPromise = getMessageThreads(vendorId);
      messageThreadPromise
        .then((threads: MessageThread[]) => {
          setThreadCleanupTimeout(threads, vendorId, dispatch);
          dispatch(completeLoadThreads(threads));
        })
        .catch(() => {
          dispatch(failLoadThreads());
          setTimeout(() => loadMessageThreads(vendorId)(dispatch), 5000);
        });
      return messageThreadPromise;
    }
  }
};

export const loadThreadMessages = (vendorId: number, threadId: string) => (dispatch: Dispatch) => {
  dispatch(startLoadThreadMesages());
  const threadMessagesPromise = getThreadMessages(vendorId, threadId);
  threadMessagesPromise
    .then(messages => dispatch(completeLoadThreadMessages(messages)))
    .catch(() => {
      dispatch(failLoadThreadMessages());
      createErrorNotification(translate('messaging.loadMessagesError'));
    });
  return threadMessagesPromise;
};

export const resetActiveThreadMessages = () => (dispatch: Dispatch) => {
  dispatch(resetThreadMessages());
};

export const openThread = (thread: MessageThread) => (dispatch: Dispatch) => {
  dispatch(setActiveThread(thread));
};

export const closeThread = () => (dispatch: Dispatch) => {
  dispatch(setActiveThread());
};

let temporaryMessageId = 0;

export const sendMessageToExistingThread =
  (message: SentMessage, retryTempMessageId?: number) => (dispatch: Dispatch) => {
    let tempMessageId = retryTempMessageId;

    if (!retryTempMessageId) {
      temporaryMessageId--;
      tempMessageId = temporaryMessageId;
      dispatch(
        addMessageToThread({
          messageId: tempMessageId,
          messageText: message.messageText,
          threadId: message.threadId!,
          messageTypeId: message.messageTypeId,
          isLoading: true,
          sentDate: moment().toISOString(),
          isMessageRead: true,
        }),
      );
    }

    const promise = postMessage({ ...message, sessionId });
    promise
      .then(result => {
        dispatch(replaceTemporaryMessageInThread(result, tempMessageId!));
      })
      .catch(() => createErrorNotification(translate('messaging.sendMessageError')));
    return { temporaryMessageId, promise };
  };

export const sendNewMessage = (message: SentMessage) => (dispatch: Dispatch) => {
  const promise = postMessage({ ...message, sessionId });
  promise
    .then(result => {
      loadMessageThreads(message.vendorId)(dispatch).then((threads: MessageThread[]) => {
        openThread(threads.find((t: MessageThread) => t.threadId === result.threadId)!)(dispatch);
      });
    })
    .catch(() => createErrorNotification(translate('messaging.sendMessageError')));
  return promise;
};

export const readMessage = (message: Message) => (dispatch: Dispatch) => {
  const promise = postReadMessage(message.messageId, sessionId);
  promise.then(() => {
    dispatch(readThread(message));
  });
  return promise;
};
