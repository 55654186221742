import { map } from 'lodash-es';

import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadRouteTemplates = (routeTemplates: any) => ({
  ...routeTemplates,
  routeTemplates: map(routeTemplates.routeTemplates, routeTemplate => ({
    ...routeTemplate,
    routeDayOfService: translate(createTranslationKey(routeTemplate.routeDate, 'common.weekdays')),
    routeStatus: translate(routeTemplate.isEnabled ? translate('common.active') : translate('common.inactive')),
    vehicleTypeName: translate(createTranslationKey(routeTemplate.vehicleTypeName, 'vehicles.vehicleTypes')),
    wasteMaterialTypeName: translate(
      createTranslationKey(routeTemplate.wasteMaterialTypeTechnicalName, 'common.wasteTypes'),
    ),
  })),
});

export default transformLoadRouteTemplates;
