import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { TechnicalType } from '../interfaces/TechnicalType';
import { loadWasteMaterialTypes as doLoadWasteMaterialTypes } from '../services/wasteAuditTypes';

// Actions
const START_LOAD = 'common/loadWasteMaterialTypes/START_LOAD';
const COMPLETE_LOAD = 'common/loadWasteMaterialTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/loadWasteMaterialTypes/FAIL_LOAD';

interface IState {
  isLoading: boolean;
  wasteMaterialTypes: TechnicalType[]
}

// Initial state
const initialState: IState = {
  isLoading: false,
  wasteMaterialTypes: [],
};

// Reducer
export const reducer = (state: IState = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteMaterialTypes: action.wasteMaterialTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (wasteMaterialTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  wasteMaterialTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadWasteMaterialTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const promise = doLoadWasteMaterialTypes();

  promise
    .then(wasteMaterialTypes => {
      dispatch(completeLoad(wasteMaterialTypes));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};
