import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { useIsMobileView } from 'src/utils/hooks';

import { isAdminSelector, isAdminVendorSelector } from '../../account/ducks';
import { getCurrentLanguageId, getInitialLocale } from '../../core/services/i18n';
import { AppState } from '../../store';
import { Vendor } from '../../vendors/interfaces/Vendors';
import FeedbackContainer from './FeedbackContainer';
import NavigationBar from './NavigationBar';
import { Page as PageContainer } from './styled';

interface Props extends RouteComponentProps {
  isAdmin: boolean;
  isAdminVendor: boolean;
  defaultVendor: Vendor;
  children: any;
}

export const Page: React.FC<Props> = ({ location, children, defaultVendor, isAdmin, isAdminVendor }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const isMobileView = useIsMobileView(true);

  const initialLocale = getInitialLocale();
  const languageId = getCurrentLanguageId();
  if (languageId && languageId !== initialLocale) {
    window.location.reload();
    return null;
  }
  if (isAdmin && !defaultVendor && !isAdminVendor) return <Redirect to="/select-default-vendor" />;

  return (
    <PageContainer mobile={isMobileView}>
      <NavigationBar />
      {children}
      <DesktopWidthView>
        <FeedbackContainer />
      </DesktopWidthView>
    </PageContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAdmin: isAdminSelector(state.account.login),
  isAdminVendor: isAdminVendorSelector(state.account.login),
  defaultVendor: state.vendors.defaultVendor.defaultVendor,
});

export default withRouter(connect(mapStateToProps)(Page));
