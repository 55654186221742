import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentTitle } from '../../../../common/components';
import { PageLoading } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import translate from '../../../../core/services/translate';
import RouteTemplateBuilderWorkSessionsPage from './RouteTemplateBuilderWorkSessionsPage';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { loadRouteTemplateBuilderWorkSessions } from '../../../ducks/routeTemplateBuilder';
import { loadWasteTypes } from 'src/common/ducks';

const RouteTemplateBuilderWorkSessionsPageResolver: React.FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    return Promise.all([
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadRouteTemplateBuilderWorkSessions(vendorId)(dispatch),
    ]);
  };

  return (
    <>
      <DocumentTitle>{translate('routeTemplateBuilder.routeTemplateBuilder')}</DocumentTitle>

      <TypedResolver
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={RouteTemplateBuilderWorkSessionsPage}
        redirectOnError="/routes/route-templates"
      />
    </>
  );
};

export default RouteTemplateBuilderWorkSessionsPageResolver;
