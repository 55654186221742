import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { chunk, defaultsDeep, some, uniqueId } from 'lodash-es';

import {
  DoughnutChartWrapper,
  DoughnutChartDrawingWrapper,
  DoughnutChartLegendWrapper,
  DoughnutChartTitle,
  DoughnutChartLegendItem,
  DoughnutChartLegendItemColor,
  DoughnutChartTitleWithPopOver,
  DoughnutChartTitlePopOverWrapper,
  DoughnutTopChartTitle,
  DoughnutChartLegendTitle,
} from './styled/DoughnutChartStyles';
import { theme } from '../styles';
import ChartTooltipModel from '../../common/interfaces/ChartTooltipModel';
import { ChartTooltip, ChartTooltipCaret } from './styled/ChartTooltip';
import { Popover, Text } from './styled';
import { Box } from './styled/Box';
import PopoverWrapper from './PopoverWrapper';
import { PARTIALLY_SERVICED } from 'src/common/constants';

const doughnutChartOptions = {
  cutoutPercentage: 75,
  responsive: false,
  maintainAspectRatio: false,
  aspectRatio: 1,
  legend: {
    display: false,
  },
};

type Legend = {
  label: string;
  color: string;
  rawValue?: number;
}[];

interface DataSet {
  data: number[];
  hidden?: boolean;
  backgroundColor?: (string | undefined)[];
  borderAlign?: 'center' | 'inner';
  borderColor?: string[];
  borderWidth?: number;
  hoverBackgroundColor?: string[];
  hoverBorderColor?: string[];
  hoverBorderWidth?: number;
  weight?: number;
}

interface ExtendedDataSet extends DataSet {
  _meta: {
    [k: string]: {
      data: {
        hidden: boolean;
        _model: {
          backgroundColor: string;
          borderAlign: string;
          borderColor: string;
          borderWidth: number;
          circumference: number;
          endAngle: number;
          innerRadius: number;
          label: string;
          outerRadius: number;
          startAngle: number;
          x: number;
          y: number;
        };
      }[];
    };
  };
}

interface Tooltip {
  x: number;
  y: number;
  caretX: number;
  caretY: number;
  items: {
    label: string;
    percentage: number;
  }[];
}

interface Props {
  dataset?: DataSet;
  datasets?: DataSet[];
  doughnutSize?: number;
  hasMarginLeft?: boolean;
  labels?: string[];
  legendTitle?: string;
  options?: {
    cutoutPercentage?: number;
    responsive?: boolean;
    maintainAspectRatio?: boolean;
    aspectRatio?: number;
    tooltips?: {
      enabled?: boolean;
      backgroundColor?: string;
      usePointStyle?: boolean;
      boxWidth?: number;
      borderWidth?: number;
    };
  };
  rawDataValues?: number[];
  showLegend?: boolean;
  showLegendScrolled?: boolean;
  showSummaryLegend?: boolean;
  showSummaryOnHover?: boolean;
  showWholeLegend?: boolean;
  title?: string;
  topTitle?: string;
}

const blankDatasetLabel = 'blank-dataset-label';

const blankDataset: DataSet = {
  data: [100],
  backgroundColor: [theme.grayLight],
  borderColor: ['transparent'],
  borderWidth: 0,
  hoverBackgroundColor: [theme.grayLight],
  hoverBorderColor: ['transparent'],
  hoverBorderWidth: 0,
};

const DoughnutChart: React.FC<Props> = ({
  dataset,
  datasets: rawDatasets,
  doughnutSize = 90,
  hasMarginLeft,
  labels = [],
  legendTitle,
  options: rawOptions,
  rawDataValues = [],
  showLegend = false,
  showSummaryLegend = false,
  showSummaryOnHover = false,
  showWholeLegend = false,
  showLegendScrolled = false,
  title,
  topTitle,
}) => {
  const biggerDoughnutChartTitleFontSize = 105;

  const datasets = React.useMemo(
    () => (!rawDatasets && !!dataset ? [dataset] : rawDatasets || []),
    [dataset, rawDatasets],
  );

  const [chartRef, setChartRef] = React.useState<Doughnut | null>(null);
  const [legend, setLegend] = React.useState<Legend>([]);
  const [tooltip, setTooltip] = React.useState<Tooltip | null>(null);

  const datasetsTotal = React.useMemo(
    () => datasets.map(dataset => dataset.data.reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0),
    [datasets],
  );

  const legendCallback = React.useCallback((chart: any) => {
    const legend: Legend = [];
    const { datasets } = chart.data;
    const set = datasets[0] as ExtendedDataSet;
    const metaKeys = Object.keys(set._meta);
    const metaKey = !!metaKeys.length ? metaKeys[0] : null;

    if (!set || !metaKey || !set._meta[metaKey]) {
      return null;
    }

    set._meta[metaKey].data.forEach((meta, i) => {
      const { label, backgroundColor } = meta._model;

      if (!!label && label !== blankDatasetLabel) {
        legend.push({
          label,
          color: backgroundColor,
        });
      }
    });

    setLegend(legend);

    return null;
  }, []);

  const tooltipCallback = React.useCallback(
    (model: ChartTooltipModel) => {
      if (!model.dataPoints || !model.dataPoints.length || !model.opacity) {
        setTooltip(null);
        return;
      }

      const newDataPoints: Tooltip['items'] = [];

      model.dataPoints.forEach(point => {
        const dataset = datasets[point.datasetIndex];

        if (!dataset) {
          return;
        }

        const pointValue = dataset.data[point.index];
        const datasetTotal = dataset.data.reduce((a, b) => a + b, 0);

        newDataPoints.push({
          label: labels[point.index],
          percentage: (pointValue * 100) / datasetTotal,
        });
      });

      const isPartiallyServicedLabel = model.body[0]?.lines[0]?.includes(PARTIALLY_SERVICED);

      setTooltip({
        x: model.caretX + (hasMarginLeft && isPartiallyServicedLabel ? 50 : 0),
        y: model.caretY,
        caretX: model.caretX,
        caretY: model.caretY,
        items: newDataPoints,
      });
    },
    [datasets, labels, hasMarginLeft],
  );

  React.useEffect(() => {
    if (!chartRef) {
      return;
    }

    chartRef.chartInstance.generateLegend();
  }, [datasets, labels, chartRef]);

  const options = React.useMemo(
    () =>
      defaultsDeep(
        {
          ...rawOptions,
          legendCallback,
          tooltips: {
            enabled: false,
            custom: tooltipCallback,
          },
        },
        doughnutChartOptions,
      ),
    [rawOptions, legendCallback, tooltipCallback],
  );

  return (
    <DoughnutChartWrapper>
      {topTitle && <DoughnutTopChartTitle size={doughnutSize}>{topTitle}</DoughnutTopChartTitle>}
      <DoughnutChartDrawingWrapper size={doughnutSize}>
        <Doughnut
          ref={setChartRef}
          height={doughnutSize}
          width={doughnutSize}
          data={{
            datasets: !datasetsTotal
              ? [...datasets.map(dataset => ({ ...dataset, hidden: true })), blankDataset]
              : datasets,
            labels: !datasetsTotal ? [...labels, blankDatasetLabel] : labels,
          }}
          datasetKeyProvider={() => btoa(Math.random().toString()).substring(0, 12)}
          options={options}
        />

        {!!title && !showSummaryOnHover && <DoughnutChartTitle size={doughnutSize}>{title}</DoughnutChartTitle>}

        {!!title && showSummaryOnHover && (
          <DoughnutChartTitlePopOverWrapper size={doughnutSize}>
            <PopoverWrapper
              size={legend.length > 8 || some(legend, l => l.label.length > 18) ? 'xLarge' : 'small'}
              triggerButton={
                <DoughnutChartTitleWithPopOver size={!!legendTitle ? biggerDoughnutChartTitleFontSize : doughnutSize}>
                  {title}
                </DoughnutChartTitleWithPopOver>
              }
              maxContentWidth
              popoverContent={
                !!legend.length && (
                  <Popover>
                    {
                      <Box display="flex" justifyContent="center" alignItems="center">
                        {chunk(legend, legend.length > 8 ? Math.round(legend.length / 2) : legend.length).map(
                          (el, i) => (
                            <Box
                              key={uniqueId('popover_in_')}
                              display="flex"
                              flexDirection="column"
                              boxShadow={i === 0 && legend.length > 8 ? '1px 0 #888888' : 'none'}
                              margin="xSmall"
                            >
                              {el.map(({ label, color }, index) => (
                                <DoughnutChartLegendItem key={index}>
                                  <DoughnutChartLegendItemColor color={color} />
                                  <Box display="flex" justifyContent="space-between" width={'100%'}>
                                    <Text>{label}</Text>
                                    <Text weight="medium" padding="no small">
                                      {rawDataValues && rawDataValues[i === 0 ? index : index + el.length]}
                                    </Text>
                                  </Box>
                                </DoughnutChartLegendItem>
                              ))}
                            </Box>
                          ),
                        )}
                      </Box>
                    }
                  </Popover>
                )
              }
            />
          </DoughnutChartTitlePopOverWrapper>
        )}

        {!!datasetsTotal && !!tooltip && (
          <>
            <ChartTooltip x={tooltip.x} y={tooltip.y}>
              {tooltip.items.map((item, index) => (
                <React.Fragment key={index}>
                  {item.label}: {item.percentage.toFixed(2)}%{index !== tooltip.items.length - 1 && <br />}
                </React.Fragment>
              ))}
            </ChartTooltip>

            <ChartTooltipCaret x={tooltip.caretX} y={tooltip.caretY} />
          </>
        )}
      </DoughnutChartDrawingWrapper>

      {showLegend && (
        <DoughnutChartLegendWrapper showLegendScrolled={showLegendScrolled}>
          {legendTitle && <DoughnutChartLegendTitle>{legendTitle}</DoughnutChartLegendTitle>}
          {(showSummaryOnHover && !showWholeLegend ? legend.slice(0, 5) : legend).map((item, index) => (
            <DoughnutChartLegendItem key={index}>
              <DoughnutChartLegendItemColor color={item.color} />
              <Box display="flex" justifyContent="space-between" width={'100%'}>
                <Text size={!!legendTitle ? 'sMedium' : undefined}> {item.label}</Text>
                {showSummaryLegend ? (
                  <Text size={!!legendTitle ? 'sMedium' : undefined} weight="medium" padding="no small">
                    {rawDataValues && rawDataValues[index]}
                  </Text>
                ) : null}
              </Box>
            </DoughnutChartLegendItem>
          ))}
        </DoughnutChartLegendWrapper>
      )}
    </DoughnutChartWrapper>
  );
};

export default DoughnutChart;
