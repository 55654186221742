import { debounce, size } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';

import { Modal, UnconnectedInput } from 'src/core/components';
import Table, { TableCell } from 'src/core/components/Table';
import { ModalSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import RouteTemplateBuilderServiceTableRow from './RouteTemplateBuilderServiceTableRow';

interface Props {
  closeModal: () => void;
  serviceIds: number[];
}

const RouteTemplateBuilderServicesModal: FC<Props> = ({ closeModal, serviceIds }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const selectedServicesExtraData = useSelector(state => state.routes.routeTemplateBuilder.selectedServicesExtraData);

  const customerLocationsTableCells: TableCell[] = [
    { name: 'customerName', label: translate('common.customerName'), width: '25%', sortable: true },
    { name: 'locationName', label: translate('common.locationName'), width: '25%', sortable: true },
    { name: 'locationAddress', label: translate('common.address'), width: '28%', sortable: true },
    {
      name: 'serviceContractsAtLocation',
      label: `${translate('common.selected')} ${translate('common.services')} #`,
      width: '20%',
      sortable: true,
    },
  ];

  const debouncedSearchTermUntyped = useMemo(() => debounce((value: string) => setSearchTerm(value), 500), []);

  const debouncedSearchTerm = useCallback(v => debouncedSearchTermUntyped(v), [debouncedSearchTermUntyped]);

  const filteredServices = useMemo(() => {
    if (!searchTerm) {
      return selectedServicesExtraData;
    }

    return selectedServicesExtraData.filter(service => {
      return (
        service.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.locationAddress.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [searchTerm, selectedServicesExtraData]);

  return (
    <Modal
      hasBorderRadius
      size="large"
      padding="medium small"
      title={translate('common.services')}
      onClose={closeModal}
    >
      {size(selectedServicesExtraData) > 4 && (
        <ModalSection>
          <UnconnectedInput type="text" label={translate('common.search')} width="40%" onChange={debouncedSearchTerm} />
        </ModalSection>
      )}
      <ModalSection>
        <Table
          cells={customerLocationsTableCells}
          rowComponent={RouteTemplateBuilderServiceTableRow}
          rows={filteredServices}
          rowProps={{ serviceIds }}
          height="medium"
          scrollMarker
          tableHeadProps={{
            sticky: true,
          }}
        />
      </ModalSection>
    </Modal>
  );
};

export default RouteTemplateBuilderServicesModal;
