import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { Resolver } from '../../../core/components';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { reportDateSelector } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { PageLoading } from '../../../common/components/styled';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadRecurringServices, loadRecurringServicesIssueType } from '../../ducks/recurringServices';
import RecurringServicesPage from './RecurringServicesPage';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  vendorId?: number;
  date?: string;
  loadRecurringServices(...args: any[]): any;
  loadRecurringServicesIssueType(...args: any[]): any;
}

class RecurringServicesPageResolver extends PureComponent<Props> {
  loadDependancies = () => {
    const {
      vendorId,
      date,
      location: { search },
      loadRecurringServices,
      loadRecurringServicesIssueType,
      match: {
        params: { token },
      },
    } = this.props;

    const {
      page,
      limit,

      // sortOrder,
      // sortedBy,
    } = getQueryParams(search);

    return Promise.all([
      loadRecurringServices(vendorId, token, date, page, limit),
      loadRecurringServicesIssueType(vendorId, token),
    ]);
  };
  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('payment.payments')}</DocumentTitle>
        <Resolver
          successComponent={RecurringServicesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependancies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  date: reportDateSelector(state.payments),
});

const mapDispatchToProps = {
  loadRecurringServices,
  loadRecurringServicesIssueType,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecurringServicesPageResolver));
