import React from 'react';
import { Layer, Source } from 'react-map-gl';

import { GEO_FENCE_OPTIONS_SAT } from 'src/core/constants';
import { theme } from 'src/core/styles';
import { DASHBOARD_GEO_FENCES_SOURCE } from 'src/dashboard/constants/dashboardMapGL';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
  isSatellite: boolean;
  mapZoom: number;
};

export default React.memo(function DashboardGeoFencesGLSource({ geoJSON, isSatellite, mapZoom = 10 }: Props) {
  const labelPlacement = mapZoom < 14 ? 'point' : 'line';
  const allowOverlap = mapZoom >= 14;

  return (
    <Source type="geojson" id={DASHBOARD_GEO_FENCES_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id="geoFence"
        type="fill"
        source={DASHBOARD_GEO_FENCES_SOURCE}
        paint={{
          'fill-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'fill-opacity': 0.1,
        }}
        layout={{}}
      />
      <Layer
        id="outline"
        type="line"
        source={DASHBOARD_GEO_FENCES_SOURCE}
        paint={{
          'line-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'line-width': 1,
        }}
        layout={{}}
      />

      <Layer
        id="geoFenceName"
        type="symbol"
        source={DASHBOARD_GEO_FENCES_SOURCE}
        paint={{
          'text-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'text-halo-color': isSatellite ? '#000' : '#fff',
          'text-halo-width': 2,
        }}
        layout={{
          'symbol-avoid-edges': true,
          'symbol-placement': labelPlacement,
          'text-allow-overlap': allowOverlap,
          'text-ignore-placement': true,
          'text-field': ['get', 'name'],
          'text-keep-upright': true,
          'text-offset': [0, 1],
          'text-rotation-alignment': 'map',
          visibility: mapZoom >= 10 ? 'visible' : 'none',
          'text-size': allowOverlap ? ['interpolate', ['linear'], ['zoom'], 14, 12, 18, 16] : 12,
        }}
      />
    </Source>
  );
});
