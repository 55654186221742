import {
  RED,
  GREEN,
  BLUE,
  YELLOW,
  NOT_SWEEPING,
  ON_CURB,
  ON_CURB_ICON,
  OFF_CURB_ICON,
  OFF_CURB,
  NOT_SWEEPING_ICON,
  OFF_CURB_OBSTACLE,
  OFF_CURB_OBSTACLE_ICON,
} from '../constants';

export const getColorIndexByServiceMode = (serviceMode: number) => {
  switch (serviceMode) {
    case NOT_SWEEPING:
      return BLUE;
    case ON_CURB:
      return GREEN;
    case OFF_CURB:
      return RED;
    default:
      return YELLOW;
  }
};

export const getServiceModeIcon = (serviceMode: number) => {
  switch (serviceMode) {
    case NOT_SWEEPING:
      return NOT_SWEEPING_ICON;
    case OFF_CURB_OBSTACLE:
      return OFF_CURB_OBSTACLE_ICON;
    case ON_CURB:
      return ON_CURB_ICON;
    default:
      return OFF_CURB_ICON;
  }
};
