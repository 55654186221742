import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from '../../utils/services/queryParams';
import { TODAY_FORMATTED } from '../../core/constants';

const getInferenceAuditFormInitialValues = (queryString: string) => {
  const { date, vehicleId, status } = getQueryParams(queryString);

  return {
    date: date || TODAY_FORMATTED,
    vehicleId: Number(vehicleId),
    status,
  };
};

const inferenceAuditFormInitialValuesSelector = createSelector(
  getInferenceAuditFormInitialValues,
  identity,
);

export default inferenceAuditFormInitialValuesSelector;
