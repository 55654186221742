import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { map, omit } from 'lodash-es';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { formValueSelector, submit } from 'redux-form';

import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from 'src/account/constants';
import { hasPermissionSelector } from 'src/account/ducks';
import { ConfirmationModal, ProgressPopup } from 'src/common/components';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { getServiceZonesFiltersPreferencesIds, getSupervisorsFiltersPreferencesIds } from 'src/common/utils/filters';
import { DuckAction, DuckFunction } from 'src/contracts/ducks';
import { MoreButton } from 'src/core/components';
import { MoreButtonItem } from 'src/core/components/MoreButton';
import { Button, ButtonIcon, Container, Grid, GridColumn, Panel, PanelSection } from 'src/core/components/styled';
import { ROUTE_BUILDER_COMPLETE, ROUTE_BUILDER_FAILED, TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification,
} from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { ServiceContractWorkflowModalResolver } from 'src/customers/components/modals/serviceContractWorkflowModal/ServiceContractWorkflowModalResolver';
import { DELIVERY_UTILITY_ID, ROLL_OFF_ID } from 'src/fleet/constants';
import { vehicleTypesForVendorWithRoleTypeSelector } from 'src/fleet/ducks';
import { getTextWithLinks } from 'src/landing/components/services/Utils';
import { DispatchBoardForm } from 'src/routes/components/forms';
import { DISPATCH_BOARD_SOURCE_FORM_NAME } from 'src/routes/components/forms/DispatchBoardSourceForm';
import { DISPATCH_BOARD_TARGET_FORM_NAME } from 'src/routes/components/forms/DispatchBoardTargetForm';
import {
  DispatchBoardDuplicateJobConfirmationModal,
  DispatchBoardJobEditorModalResolver,
  DispatchBoardRouteBuilderModalResolver,
  DispatchBoardRouteEditorModalResolver,
  ExceptionManager,
  RouteImagesModal,
  RouteUploaderModal,
} from 'src/routes/components/modals';
import {
  DispatchBoardPanelContainer,
  DispatchBoardPanelTitle,
  RouteBuilderFailedStops,
  RouteBuilderSuccessMessage,
} from 'src/routes/components/styled';
import { SELECTED_TRANSFERRED_STOPS } from 'src/routes/constants';
import { DISPATCH_BOARD_FORM_NAME, ON_HOLD_ROUTE_ID, UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import {
  assignDispatchBoardUnassignedJobToRoute,
  checkActiveRouteBuilder,
  clearSelectedRoutes,
  createRouteBuilderRoutes,
  finishRouteBuilderJob,
  hideImages,
  loadDispatchBoardSourceRoutes,
  loadDispatchBoardTargetRoutes,
  loadDispatchBoardUnassignedJobs,
  loadDispatchBoardUnassignedJobsCount,
  openMap,
  resetDispatchBoardOnHoldJobs,
  resetDispatchBoardSourceRouteJobs,
  resetDispatchBoardSourceRoutes,
  resetDispatchBoardTargetRoutes,
  resetDispatchBoardUnassignedJobs,
  resetRouteBuilder,
  toggleRoute,
  toggleRoutes,
  transferDispatchBoardSourceRouteJob,
} from 'src/routes/ducks/dispatchBoard';
import {
  loadDispatchBoardOnHoldJobs,
  loadDispatchBoardOnHoldJobsCount,
} from 'src/routes/ducks/dispatchBoard/dispatchBoardOnHoldJobs';
import { loadRouteHasStopsInPendingOptimization } from 'src/routes/ducks/dispatchBoard/dispatchBoardRouteJob';
import { setSelectedJobIds, setSelectedJobIndexes } from 'src/routes/ducks/dispatchBoard/dispatchBoardUnassignedJobs';
import { DispatchBoardRouteJob, DuplicateJobs, ResponseError } from 'src/routes/interfaces/DispatchBoardRouteJob';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import dispatchBoardFilteredUnassignedJobsSelector from 'src/routes/services/dispatchBoardFilteredUnassignedJobsSelector';
import dispatchBoardFormInitialValuesSelector, {
  getFirstAcceptedVehicleTypeId,
} from 'src/routes/services/dispatchBoardFormInitialValuesSelector';
import { checkDuplicateJobs } from 'src/routes/services/dispatchBoardRouteJobs';
import { isRouteTemplate } from 'src/routes/services/routeTemplateHelper';
import { RESPONSE_TYPES, displayMessageOnResponseError } from 'src/routes/utils/dispatchBoard';
import { AppState } from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import {
  billingFeatureStatusSelector,
  isServiceContractWorkflowFeatureEnabled,
  routeBuilderStatusSelector,
} from 'src/vendors/ducks/features';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { MaterialTypes } from 'src/vendors/interfaces/MaterialTypes';
import { RouteSettings } from 'src/vendors/interfaces/RouteSettings';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { normalizeTimeDateToObject } from 'src/vendors/services/transformRouteSettings';
import DispatchBoardMapResolver from './dispatchBoardMapSections/DispatchBoardMapResolver';
import { DispatchBoardSources, DispatchBoardTargets } from './dispatchBoardPageSections';
import useDispatchBoardFilters from './dispatchBoardPageSections/common/useDispatchBoardFilters';

export interface DispatchBoardQueryParams {
  date: Date | string;
  groupIds: number[];
  routeStatuses: number[];
  searchTerm: string;
  serviceZones: number[];
  sourceRouteId: number;
  supervisors: number[];
  targetDate: Date | string;
  targetRouteId: number;
  targetRouteStatuses: number[];
  targetSearchTerm: string;
  targetServiceZones: number[];
  targetSupervisors: number[];
  targetGroupIds: number[];
  vehicleTypeId: number;
}

interface Props extends RouteComponentProps {
  allMaterialTypes: MaterialTypes[];
  allServiceZones: ServiceZone[];
  allSupervisors: Supervisor[];
  assignDispatchBoardUnassignedJobToRoute: DuckFunction<typeof assignDispatchBoardUnassignedJobToRoute>;
  batchTransfererProgress: number;
  checkActiveRouteBuilder: DuckFunction<typeof checkActiveRouteBuilder>;
  clearSelectedRoutes: DuckAction<typeof clearSelectedRoutes>;
  createRouteBuilderRoutes: DuckFunction<typeof createRouteBuilderRoutes>;
  filtersPreferences: FilterSetting[];
  finishRouteBuilderJob: DuckFunction<typeof finishRouteBuilderJob>;
  hasPermissionToManageScheduledJobs?: boolean;
  hasPermissionToManageUnscheduledJobs?: boolean;
  hideImages: DuckFunction<typeof hideImages>;
  imagesForSelectedJob?: any[];
  isLoadingSourceRoutes: boolean;
  isLoadingTargetRoutes: boolean;
  isMapActive: boolean;
  isTransferringJob: boolean;
  loadDispatchBoardSourceRoutes: DuckFunction<typeof loadDispatchBoardSourceRoutes>;
  loadDispatchBoardTargetRoutes: DuckFunction<typeof loadDispatchBoardTargetRoutes>;
  loadDispatchBoardUnassignedJobs: DuckFunction<typeof loadDispatchBoardUnassignedJobs>;
  loadDispatchBoardUnassignedJobsCount: DuckFunction<typeof loadDispatchBoardUnassignedJobsCount>;
  loadDispatchBoardOnHoldJobs: DuckFunction<typeof loadDispatchBoardOnHoldJobs>;
  loadDispatchBoardOnHoldJobsCount: DuckFunction<typeof loadDispatchBoardOnHoldJobsCount>;
  loadRouteHasStopsInPendingOptimization: DuckFunction<typeof loadRouteHasStopsInPendingOptimization>;
  openMap: DuckAction<typeof openMap>;
  resetDispatchBoardSourceRouteJobs: DuckAction<typeof resetDispatchBoardSourceRouteJobs>;
  resetDispatchBoardSourceRoutes: DuckAction<typeof resetDispatchBoardSourceRoutes>;
  resetDispatchBoardTargetRoutes: DuckAction<typeof resetDispatchBoardTargetRoutes>;
  resetDispatchBoardUnassignedJobs: DuckAction<typeof resetDispatchBoardUnassignedJobs>;
  resetDispatchBoardOnHoldJobs: DuckAction<typeof resetDispatchBoardOnHoldJobs>;
  resetRouteBuilder: DuckFunction<typeof resetRouteBuilder>;
  routeBuilderEnabled?: boolean;
  routeBuilderJobErrorMessage?: string;
  routeBuilderJobId?: number;
  routeBuilderJobsNotRoutedCount?: number;
  routeBuilderJobsNotRoutedFileDownloadUrl?: string;
  routeBuilderJobStatusId?: number;
  routeBuilderRoutes: any[];
  routeBuilderWaiting: boolean;
  serviceZonesFiltersPreferencesIds: number[];
  submit: DuckAction<typeof submit>;
  supervisorsFiltersPreferencesIds: number[];
  toggleRoute: DuckAction<typeof toggleRoute>;
  toggleRoutes: DuckAction<typeof toggleRoutes>;
  transferDispatchBoardSourceRouteJob: DuckFunction<typeof transferDispatchBoardSourceRouteJob>;
  unassignedJobs: any;
  vehicleFiltersPreferencesIds: number[];
  vehicleTypeId: number;
  vehicleTypesForVendor?: any[];
  vendorId: number;
  isServiceContractWorkflowFeatureEnabled: boolean;
  targetRoutes: any[];
  isBillingFeatureActive?: boolean;
}

const DispatchBoardPage = (props: Props) => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);

  const {
    allMaterialTypes,
    allServiceZones,
    allSupervisors,
    assignDispatchBoardUnassignedJobToRoute,
    batchTransfererProgress,
    checkActiveRouteBuilder,
    clearSelectedRoutes,
    createRouteBuilderRoutes,
    filtersPreferences,
    finishRouteBuilderJob,
    hasPermissionToManageScheduledJobs,
    hasPermissionToManageUnscheduledJobs,
    hideImages,
    imagesForSelectedJob,
    isLoadingSourceRoutes,
    isLoadingTargetRoutes,
    isMapActive,
    isTransferringJob,
    loadDispatchBoardSourceRoutes,
    loadDispatchBoardTargetRoutes,
    loadDispatchBoardUnassignedJobs,
    loadDispatchBoardUnassignedJobsCount,
    loadDispatchBoardOnHoldJobs,
    loadDispatchBoardOnHoldJobsCount,
    loadRouteHasStopsInPendingOptimization,
    location: { search },
    openMap,
    resetDispatchBoardSourceRouteJobs,
    resetDispatchBoardSourceRoutes,
    resetDispatchBoardTargetRoutes,
    resetDispatchBoardUnassignedJobs,
    resetDispatchBoardOnHoldJobs,
    resetRouteBuilder,
    routeBuilderEnabled,
    routeBuilderJobErrorMessage,
    routeBuilderJobId,
    routeBuilderJobsNotRoutedCount,
    routeBuilderJobsNotRoutedFileDownloadUrl,
    routeBuilderJobStatusId,
    routeBuilderRoutes,
    routeBuilderWaiting,
    serviceZonesFiltersPreferencesIds,
    submit,
    supervisorsFiltersPreferencesIds,
    toggleRoute,
    toggleRoutes,
    transferDispatchBoardSourceRouteJob,
    unassignedJobs,
    vehicleTypeId,
    vehicleTypesForVendor,
    vendorId,
    isServiceContractWorkflowFeatureEnabled,
    targetRoutes,
    isBillingFeatureActive,
  } = props;

  const { selectedJobIds: selectedUnassignedJobIds } = useSelector(s => s.routes.dispatchBoard.unassignedJobs);

  const {
    date = TODAY_FORMATTED,
    searchTerm,
    sourceRouteId,
    targetDate = TODAY_FORMATTED,
    targetSearchTerm,
  } = getQueryParams<DispatchBoardQueryParams>(search);
  const {
    groupIds,
    routeStatuses,
    serviceZones = serviceZonesFiltersPreferencesIds,
    supervisors = supervisorsFiltersPreferencesIds,
    targetRouteStatuses,
    targetServiceZones = serviceZonesFiltersPreferencesIds,
    targetSupervisors = supervisorsFiltersPreferencesIds,
    targetGroupIds,
  } = getQueryParams<DispatchBoardQueryParams>(search, { mapTypeIdsToArray: true });

  const { dispatchBoardSearchFilters, isFilterFocused, filterFunctions } = useDispatchBoardFilters();

  const [areUnassignedJobsLoading, setAreUnassignedJobsLoading] = useState(false);
  const [areOnHoldJobsLoading, setAreOnHoldJobsLoading] = useState(false);
  const [duplicateJobConfirmationData, setDuplicateJobConfirmationData] = useState({});
  const [duplicateJobConfirmationModalOpen, setDuplicateJobConfirmationModalOpen] = useState(false);
  const [errorShowing, setErrorShowing] = useState(false);
  const [jobEditorModalOpen, setJobEditorModalOpen] = useState(false);
  const [jobsTransferring, setJobsTransferring] = useState(false);
  const [routeBuilderConfirmationModalOpen, setRouteBuilderConfirmationModalOpen] = useState(false);
  const [routeBuilderModalOpen, setRouteBuilderModalOpen] = useState(false);
  const [routeEditorModalOpen, setRouteEditorModalOpen] = useState(false);
  const [isCreateRoute, setIsCreateRoute] = useState(false);
  const [uploadRouteModalIsVisible, setUploadRouteModalIsVisible] = useState(false);
  const [isUnassignedRouteEditorOpen, setIsUnassignedRouteEditorOpen] = useState(false);
  const [isServiceUpdateModalOpen, setIsServiceUpdateModalOpen] = useState(false);

  const filteredUnassignedJobs = useMemo(() => {
    const fj =
      dispatchBoardFilteredUnassignedJobsSelector(
        unassignedJobs.unassignedJobs,
        dispatchBoardSearchFilters,
        allServiceZones.length,
        allSupervisors.length,
        allMaterialTypes.length,
      ) || [];
    return fj as DispatchBoardRouteJob[];
  }, [
    allMaterialTypes.length,
    allServiceZones.length,
    allSupervisors.length,
    dispatchBoardSearchFilters,
    unassignedJobs.unassignedJobs,
  ]);

  const isDeliveryUtilityRoute = vehicleTypeId === DELIVERY_UTILITY_ID;

  useEffect(() => {
    filterFunctions.onResetMaterialTypesFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypeId]);

  useEffect(() => {
    if (!isDeliveryUtilityRoute) {
      resetDispatchBoardOnHoldJobs();
    }
  }, [isDeliveryUtilityRoute, resetDispatchBoardOnHoldJobs]);

  useEffect(() => {
    dispatch(setSelectedJobIndexes([]));
    dispatch(setSelectedJobIds([]));
  }, [dispatch, dispatchBoardSearchFilters]);

  useEffect(() => {
    // set to true if not the 1st render
    if (!didMountRef.current) {
      didMountRef.current = true;
      // check route builder on 1st render only
      checkActiveRouteBuilder(vendorId);
    }
    return () => {
      // equivalent of componentWillUnmount do cleanup here
      resetDispatchBoardSourceRouteJobs();
      resetDispatchBoardSourceRoutes();
      resetDispatchBoardTargetRoutes();
      resetDispatchBoardUnassignedJobs();
      resetDispatchBoardOnHoldJobs();
      resetRouteBuilder();
    };
  }, [
    checkActiveRouteBuilder,
    resetDispatchBoardSourceRouteJobs,
    resetDispatchBoardSourceRoutes,
    resetDispatchBoardTargetRoutes,
    resetDispatchBoardUnassignedJobs,
    resetDispatchBoardOnHoldJobs,
    resetRouteBuilder,
    vendorId,
  ]);

  useEffect(() => {
    if (routeBuilderJobStatusId === ROUTE_BUILDER_COMPLETE && routeBuilderRoutes.length) {
      toggleRoutes(routeBuilderRoutes);
      if (routeBuilderModalOpen) {
        setTimeout(() => {
          setRouteBuilderModalOpen(false);
          setRouteBuilderConfirmationModalOpen(true);
        }, 1000);
      } else {
        setRouteBuilderConfirmationModalOpen(true);
      }
    } else if (routeBuilderJobStatusId === ROUTE_BUILDER_FAILED) {
      if (routeBuilderModalOpen) {
        setRouteBuilderModalOpen(false);
      }
      if (!errorShowing) {
        // for logging purposes,
        // this will be replaced with translated error codes in the future
        // eslint-disable-next-line no-console
        console.error(routeBuilderJobErrorMessage);
        createErrorNotification(translate('dispatchBoard.routeBuilder.jobFailed'), 6000);
        setErrorShowing(true);
        setTimeout(() => {
          setErrorShowing(false);
        }, 6000);
      }
    }
  }, [
    errorShowing,
    routeBuilderJobErrorMessage,
    routeBuilderJobStatusId,
    routeBuilderModalOpen,
    routeBuilderRoutes,
    routeBuilderWaiting,
    toggleRoutes,
  ]);

  const onRouteJobDropped = async (targetRouteId: number, jobIds: any[], position: number, isOnHoldJob?: boolean) => {
    try {
      await transferDispatchBoardSourceRouteJob(targetRouteId, jobIds, position, isOnHoldJob);
      submit(DISPATCH_BOARD_SOURCE_FORM_NAME);
      submit(DISPATCH_BOARD_TARGET_FORM_NAME);
      setJobsTransferring(false);
      const successMessage =
        jobIds.length > 1
          ? translate('routes.jobsWereTransferred', { jobCount: jobIds.length })
          : translate('routes.jobWasTransferred');
      createSuccessNotification(successMessage);
    } catch (error) {
      displayMessageOnResponseError(error as ResponseError, RESPONSE_TYPES.transfer);
    }

    if (duplicateJobConfirmationModalOpen) {
      setDuplicateJobConfirmationModalOpen(false);
    }
  };

  const onUnassignedJobDropped = async (targetRouteId: number, jobIds: any[], position: number) => {
    try {
      await assignDispatchBoardUnassignedJobToRoute(jobIds, targetRouteId, position);
      submit(DISPATCH_BOARD_SOURCE_FORM_NAME);
      submit(DISPATCH_BOARD_TARGET_FORM_NAME);
      setJobsTransferring(false);
      const successMessage =
        jobIds.length > 1
          ? translate('routes.jobsWereTransferred', { jobCount: jobIds.length })
          : translate('routes.jobWasTransferred');
      createSuccessNotification(successMessage);
    } catch (error) {
      displayMessageOnResponseError(error as ResponseError, RESPONSE_TYPES.transfer);
    }

    if (duplicateJobConfirmationModalOpen) {
      setDuplicateJobConfirmationModalOpen(false);
    }
  };

  const onJobDrop = async (
    sourceRouteId: any,
    targetRouteId: number = UNASSIGNED_ROUTE_ID,
    jobsSelected: any[],
    position?: number,
    type?: string,
  ) => {
    const jobIds = map(jobsSelected, job => job.jobId);

    const hasStopsInPendingOptimization = await loadRouteHasStopsInPendingOptimization(targetRouteId);
    if (hasStopsInPendingOptimization)
      if (
        !(await confirm(
          translate('routes.alertMessages.routeHasStopsInOptimization'),
          '',
          translate('common.cancel'),
          translate('common.continue'),
        ))
      )
        return;

    if (!hasPermissionToManageScheduledJobs) {
      createErrorNotification(translate('dispatchBoard.cannotModifyRoute'));
      return;
    } else if (jobsSelected.length > SELECTED_TRANSFERRED_STOPS) {
      createErrorNotification(
        translate('routes.alertMessages.routeLocationsMoreThan', {
          SELECTED_TRANSFERRED_STOPS,
        }),
      );
      return;
    }

    const jobRouteId = targetRouteId;
    const isTemplate = isRouteTemplate(targetRouteId.toString());
    const routeId = !isTemplate ? jobRouteId : null;
    const routeTemplateId = isTemplate ? jobRouteId : null;

    const duplicateJobs = await checkDuplicateJobs({
      jobs: jobsSelected.map(j => ({ id: j.jobId, serviceContractId: j.serviceContractId })),
      routeId: routeId === UNASSIGNED_ROUTE_ID ? null : routeId,
      routeTemplateId,
    } as DuplicateJobs);

    if (duplicateJobs.length) {
      setDuplicateJobConfirmationModalOpen(true);
      setDuplicateJobConfirmationData({
        targetRouteId,
        sourceRouteId,
        jobIds,
        position,
      });
    } else if (sourceRouteId && sourceRouteId !== UNASSIGNED_ROUTE_ID) {
      const isOnHoldJob = sourceRouteId === ON_HOLD_ROUTE_ID;
      const targetRoutesLength =
        targetRoutes?.find(targetRoute => targetRoute.id === targetRouteId)?.totalNumberOfJobs || 0;
      const newPosition = isOnHoldJob && !position ? targetRoutesLength + 1 : position || 1;

      onRouteJobDropped(targetRouteId, jobIds, newPosition, isOnHoldJob);
    } else {
      onUnassignedJobDropped(targetRouteId, jobIds, position || 1);
    }
  };

  const closeDuplicateJobConfirmationModal = () => {
    setDuplicateJobConfirmationModalOpen(false);
  };

  const toggleJobTransferring = (value: any) => {
    setJobsTransferring(value);
  };

  const reloadSourceRoutes = () => {
    loadDispatchBoardSourceRoutes({
      vendorId,
      vehicleTypeId,
      date,
      routeStatuses,
      searchTerm,
      serviceZones,
      supervisors,
      groupIds,
    });
  };

  const reloadTargetRoutes = () => {
    loadDispatchBoardTargetRoutes({
      vendorId,
      vehicleTypeId,
      date: targetDate,
      routeStatuses: targetRouteStatuses,
      searchTerm: targetSearchTerm,
      serviceZones: targetServiceZones,
      supervisors: targetSupervisors,
      groupIds: targetGroupIds,
    });
  };

  const reloadUnassignedJobsCount = () => {
    loadDispatchBoardUnassignedJobsCount(vendorId, vehicleTypeId, searchTerm);
    loadDispatchBoardUnassignedJobs(vendorId, vehicleTypeId, searchTerm);
  };

  const reloadOnHoldJobsCount = () => {
    loadDispatchBoardOnHoldJobsCount(vendorId, date, [], searchTerm);
    loadDispatchBoardOnHoldJobs(vendorId, date, [], searchTerm);
  };

  const refreshData = () => {
    reloadSourceRoutes();
    reloadTargetRoutes();
    reloadUnassignedJobsCount();
    reloadOnHoldJobsCount();
  };

  const onRouteUploaderModalClose = (hasSuccessfulUploads: boolean) => {
    if (hasSuccessfulUploads) {
      refreshData();
    }

    setUploadRouteModalIsVisible(false);
  };

  const onSubmitRouteBuilder = (values: RouteSettings) => {
    const options = {
      vendorId,
      ...omit(values, ['pmBreakTime', 'amBreakTime', 'lunchBreakTime', 'totalBreakTime']),
      routeStartTime: normalizeTimeDateToObject(values.routeStartTime),
      maxRouteTime: normalizeTimeDateToObject(values.maxRouteTime),
      pUCapacityAllotment: Number(values.pUCapacityAllotment),
      postEndTime: normalizeTimeDateToObject(values.postEndTime),
      preStartTime: normalizeTimeDateToObject(values.preStartTime),
      serviceTimePerStop: normalizeTimeDateToObject(values.serviceTimePerStop),
      breaks: [
        { ...values.breaks[0], time: normalizeTimeDateToObject(values.amBreakTime) },
        { ...values.breaks[1], time: normalizeTimeDateToObject(values.lunchBreakTime) },
        { ...values.breaks[2], time: normalizeTimeDateToObject(values.pmBreakTime) },
      ],
      vehicleCapacity:
        values.vehicleCapacity === null || values.vehicleCapacity === '' ? null : Number(values.vehicleCapacity),
    };
    createRouteBuilderRoutes(options);
    createWarningNotification(translate('dispatchBoard.routeBuilder.routesProcessing'));
  };

  const closeRouteBuilderConfirmationModal = async (resetSelectedRoutes = true) => {
    await finishRouteBuilderJob(vendorId, routeBuilderJobId);
    if (resetSelectedRoutes) {
      clearSelectedRoutes();
    }
    resetRouteBuilder();
    refreshData();
    setRouteBuilderConfirmationModalOpen(false);
  };

  const openTheMap = () => {
    closeRouteBuilderConfirmationModal(false);
    openMap();
  };

  const openServiceUpdateModal = () => {
    setIsServiceUpdateModalOpen(!isServiceUpdateModalOpen);
  };

  const openRouteEditor = (isOpen: boolean, isCreate?: boolean, isUnassignedEditor?: boolean) => {
    setIsUnassignedRouteEditorOpen(!!isUnassignedEditor);
    if (isOpen && isCreate) {
      setIsCreateRoute(isCreate);
    }
    setRouteEditorModalOpen(isOpen);
  };

  const loadUnassignedJobs = () => {
    setAreUnassignedJobsLoading(true);
    loadDispatchBoardUnassignedJobs(vendorId, vehicleTypeId, searchTerm).then(() => {
      setAreUnassignedJobsLoading(false);
    });
  };

  const loadOnHoldJobs = () => {
    setAreOnHoldJobsLoading(true);
    loadDispatchBoardOnHoldJobs(vendorId, date, [], searchTerm).then(() => {
      setAreOnHoldJobsLoading(false);
    });
  };

  const formInitialValues = dispatchBoardFormInitialValuesSelector(vehicleTypesForVendor, vendorId, filtersPreferences);
  const isViewOnly = !hasPermissionToManageScheduledJobs && !hasPermissionToManageUnscheduledJobs;
  const isViewOnlyOrSupport = !hasPermissionToManageScheduledJobs;
  const uploadRouteButtonIsVisible =
    !isViewOnlyOrSupport && (vehicleTypeId === ROLL_OFF_ID || vehicleTypeId === DELIVERY_UTILITY_ID);
  const routerBuilderButtonIsVisible = !isViewOnly && routeBuilderEnabled && vehicleTypeId === DELIVERY_UTILITY_ID;

  const hasWorkflowAccess = isViewOnlyOrSupport ? false : isServiceContractWorkflowFeatureEnabled;

  const moreButtonItems: MoreButtonItem[] = [];

  if (hasWorkflowAccess && !isBillingFeatureActive)
    moreButtonItems.push({
      text: translate('customers.serviceUpdate'),
      id: 'service-update-button',
      handler: openServiceUpdateModal,
    });

  if (uploadRouteButtonIsVisible)
    moreButtonItems.push({
      text: translate('dispatchBoard.routeUploader.buttonTitle'),
      id: 'upload-route-button',
      handler: () => setUploadRouteModalIsVisible(true),
    });

  if (routerBuilderButtonIsVisible)
    moreButtonItems.push({
      text: translate('routes.routeBuilder'),
      id: 'route-builder-button',
      disabled: routeBuilderWaiting,
      endAdornment: routeBuilderWaiting && <ButtonIcon icon="rotateClockwise" size="medium" rotate margin="no" />,
      handler: () => setRouteBuilderModalOpen(true),
    });

  const defaultVehicleType = getFirstAcceptedVehicleTypeId(vehicleTypesForVendor, filtersPreferences);

  return (
    <PageContent positionUnset={true}>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('routes.dispatchBoard')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions width="15%" shouldHide={isMapActive}>
          <Container>
            <DispatchBoardForm initialValues={formInitialValues} />
          </Container>
        </PageActions>
      </PageHeader>

      {(!!vehicleTypeId || defaultVehicleType) && !isMapActive && (
        <Fragment>
          <Grid>
            <GridColumn size="6/12" marginRight="15px">
              <DispatchBoardPanelContainer>
                <DispatchBoardPanelTitle>{translate('routes.jobs')}</DispatchBoardPanelTitle>
                <ExceptionManager onClose={refreshData} isViewOnly={isViewOnly} />
                {!isViewOnly && (
                  <Button onClick={() => setJobEditorModalOpen(true)} line color="primary" id="add-job-button">
                    + {translate('routes.addJob')}
                  </Button>
                )}
              </DispatchBoardPanelContainer>
            </GridColumn>
            <GridColumn size="6/12" marginLeft="15px">
              <DispatchBoardPanelContainer>
                <DispatchBoardPanelTitle>{translate('routes.routes')}</DispatchBoardPanelTitle>

                {!!moreButtonItems.length && <MoreButton margin="no small no no" items={moreButtonItems} />}

                {!isViewOnly && (
                  <Button line color="primary" id="add-route-button" onClick={() => openRouteEditor(true)}>
                    + {translate('routes.addRoute')}
                  </Button>
                )}
              </DispatchBoardPanelContainer>
            </GridColumn>
          </Grid>
          <Panel noBoxShadow noBackground>
            <PanelSection minHeight={500} padding="no">
              <Grid>
                <GridColumn size="6/12" marginRight="15px" padding="no" color="#fff" withBoxShadow>
                  <DispatchBoardSources
                    isLoading={isLoadingSourceRoutes || unassignedJobs.isLoading || unassignedJobs.isDeletingJob}
                    jobsTransferring={jobsTransferring}
                    onJobDrop={onJobDrop}
                    toggleJobTransferring={toggleJobTransferring}
                    toggleRoute={toggleRoute}
                    toggleRouteEditorModal={(value: boolean, isCreate?: boolean) =>
                      openRouteEditor(value, isCreate, true)
                    }
                    unassignedProps={{
                      dispatchBoardSearchFilters,
                      filteredUnassignedJobs,
                      filterFunctions,
                      isFilterFocused,
                      isLoading: areUnassignedJobsLoading || areOnHoldJobsLoading,
                      refreshUnassignedJobs: loadUnassignedJobs,
                      refreshOnHoldJobs: loadOnHoldJobs,
                    }}
                    vehicleTypeId={vehicleTypeId}
                    vendorId={vendorId}
                  />
                </GridColumn>

                <GridColumn size="6/12" marginLeft="15px" padding="no" color="#fff" withBoxShadow>
                  <DispatchBoardTargets
                    isLoading={isLoadingTargetRoutes || unassignedJobs.isAssigningJob || isTransferringJob}
                    jobsTransferring={jobsTransferring}
                    onJobDrop={onJobDrop}
                    toggleJobTransferring={toggleJobTransferring}
                    vehicleTypeId={vehicleTypeId}
                    vendorId={vendorId}
                  />
                </GridColumn>

                {duplicateJobConfirmationModalOpen && (
                  <DispatchBoardDuplicateJobConfirmationModal
                    closeModal={closeDuplicateJobConfirmationModal}
                    onRouteJobDropped={onRouteJobDropped}
                    onUnassignedJobDropped={onUnassignedJobDropped}
                    duplicateJobConfirmationData={duplicateJobConfirmationData}
                  />
                )}

                {routeBuilderModalOpen && (
                  <DispatchBoardRouteBuilderModalResolver
                    closeModal={() => setRouteBuilderModalOpen(false)}
                    onSubmitRouteBuilder={onSubmitRouteBuilder}
                    vehicleTypeId={vehicleTypeId}
                    vendorId={vendorId}
                    finishBuilding={Boolean(routeBuilderRoutes.length)}
                  />
                )}

                {routeBuilderConfirmationModalOpen && (
                  <ConfirmationModal
                    closeModal={closeRouteBuilderConfirmationModal}
                    action={openTheMap}
                    actionText={translate('dispatchBoard.routeBuilder.viewRoutes')}
                    timeOut={30000}
                    title={translate('dispatchBoard.routeBuilder.routesCreated')}
                    message={
                      <RouteBuilderSuccessMessage>
                        <div>{translate('dispatchBoard.routeBuilder.routesCreatedSuccess')}</div>
                        {!!routeBuilderJobsNotRoutedCount && !!routeBuilderJobsNotRoutedFileDownloadUrl && (
                          <RouteBuilderFailedStops>
                            <div>
                              {translate('dispatchBoard.routeBuilder.jobsNotRoutedMessage', {
                                numberOfJobs: routeBuilderJobsNotRoutedCount,
                              })}
                            </div>
                            <div>
                              {getTextWithLinks(
                                translate('dispatchBoard.routeBuilder.downloadJobsNotRouted'),
                                routeBuilderJobsNotRoutedFileDownloadUrl,
                              )}
                            </div>
                          </RouteBuilderFailedStops>
                        )}
                      </RouteBuilderSuccessMessage>
                    }
                  />
                )}
              </Grid>
            </PanelSection>

            <ProgressPopup
              isVisible={isTransferringJob || unassignedJobs.isAssigningJob}
              message={translate('dispatchBoard.batchTransfer')}
              progress={isTransferringJob ? batchTransfererProgress : unassignedJobs.batchAssignerProgress}
            />
          </Panel>
        </Fragment>
      )}

      {isMapActive && <DispatchBoardMapResolver searchTerm={searchTerm} date={date} vehicleTypeId={vehicleTypeId} />}

      {routeEditorModalOpen && (
        <DispatchBoardRouteEditorModalResolver
          closeModal={() => setRouteEditorModalOpen(false)}
          jobIds={isUnassignedRouteEditorOpen && isCreateRoute ? selectedUnassignedJobIds : []}
          disableVehicleType={isUnassignedRouteEditorOpen}
          onSuccess={() => {
            dispatch(setSelectedJobIndexes([]));
            dispatch(setSelectedJobIds([]));
          }}
        />
      )}

      {jobEditorModalOpen && (
        <DispatchBoardJobEditorModalResolver
          routeId={sourceRouteId}
          vehicleTypeId={vehicleTypeId}
          closeModal={() => setJobEditorModalOpen(false)}
          date={date}
          refreshUnassignedJobs={loadUnassignedJobs}
        />
      )}

      {!vehicleTypeId && <div>{translate('dispatchBoard.noVehicleType')}</div>}

      {uploadRouteModalIsVisible && <RouteUploaderModal vendorId={vendorId} onClose={onRouteUploaderModalClose} />}

      {imagesForSelectedJob && <RouteImagesModal images={imagesForSelectedJob} closeModal={hideImages} />}

      {isServiceUpdateModalOpen && <ServiceContractWorkflowModalResolver closeModal={openServiceUpdateModal} />}
    </PageContent>
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    account,
    fleet,
    routes: { dispatchBoard },
    vendors,
  } = state;

  const { routeBuilder, sourceRouteJobs, unassignedJobs } = dispatchBoard;

  const formSelector = formValueSelector(DISPATCH_BOARD_FORM_NAME);

  return {
    allMaterialTypes: state.vendors.materialTypes.materialTypes || [],
    allServiceZones: state.routes.serviceZones.serviceZones || [],
    allSupervisors: state.routes.supervisors.supervisors || [],
    batchTransfererProgress: (sourceRouteJobs as any).batchTransfererProgress,
    hasPermissionToManageScheduledJobs: hasPermissionSelector(
      state.account.permissions,
      ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
    ),
    hasPermissionToManageUnscheduledJobs: hasPermissionSelector(
      state.account.permissions,
      ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
    ),
    imagesForSelectedJob: dispatchBoard.sourceRoutes.imagesForSelectedJob,
    isLoadingSourceRoutes: dispatchBoard.sourceRoutes.isLoading,
    isLoadingTargetRoutes: dispatchBoard.targetRoutes.isLoading,
    isMapActive: dispatchBoard.map.isMapActive,
    isTransferringJob: (sourceRouteJobs as any).isTransferringJob,
    routeBuilderEnabled: routeBuilderStatusSelector(vendors.features.features),
    routeBuilderJobErrorMessage: routeBuilder.errorMessage,
    routeBuilderJobId: routeBuilder.jobId,
    routeBuilderJobsNotRoutedCount: routeBuilder.jobsNotRoutedCount,
    routeBuilderJobsNotRoutedFileDownloadUrl: routeBuilder.jobsNotRoutedFileDownloadUrl,
    routeBuilderJobStatusId: routeBuilder.statusId,
    routeBuilderRoutes: routeBuilder.routes,
    routeBuilderWaiting: routeBuilder.isLoading || routeBuilder.isWaitingAfterRouteBuilder,
    unassignedJobs,
    vehicleTypeId: formSelector(state, 'vehicleTypeId'),
    vehicleTypesForVendor: vehicleTypesForVendorWithRoleTypeSelector(fleet.vehicleTypesForVendor),
    vendorId: currentVendorIdSelector(account.login, vendors.defaultVendor),
    serviceZonesFiltersPreferencesIds: getServiceZonesFiltersPreferencesIds(state.common.filters.filters),
    supervisorsFiltersPreferencesIds: getSupervisorsFiltersPreferencesIds(state.common.filters.filters),
    filtersPreferences: state.common.filters.filters,
    isServiceContractWorkflowFeatureEnabled: isServiceContractWorkflowFeatureEnabled(state),
    targetRoutes: state.routes.dispatchBoard.targetRoutes.targetRoutes,
    isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
  };
};

const mapDispatchToProps = {
  assignDispatchBoardUnassignedJobToRoute,
  checkActiveRouteBuilder,
  clearSelectedRoutes,
  createRouteBuilderRoutes,
  finishRouteBuilderJob,
  hideImages,
  loadDispatchBoardSourceRoutes,
  loadDispatchBoardTargetRoutes,
  loadDispatchBoardUnassignedJobs,
  loadDispatchBoardUnassignedJobsCount,
  loadDispatchBoardOnHoldJobs,
  loadDispatchBoardOnHoldJobsCount,
  loadRouteHasStopsInPendingOptimization,
  openMap,
  resetDispatchBoardSourceRouteJobs,
  resetDispatchBoardSourceRoutes,
  resetDispatchBoardTargetRoutes,
  resetDispatchBoardUnassignedJobs,
  resetDispatchBoardOnHoldJobs,
  resetRouteBuilder,
  submit,
  toggleRoute,
  toggleRoutes,
  transferDispatchBoardSourceRouteJob,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DispatchBoardPage));
