import { httpCustomerCore } from '../../core/services/http';

export const loadTwitterPosts = () =>
  httpCustomerCore.get('social-media/twitter/posts').then(response => response.data);

export const loadBlogPosts = () => httpCustomerCore.get('social-media/wordpress/posts').then(response => response.data);

export const loadEnvironmentSafetyBlogPosts = () =>
  httpCustomerCore.get('social-media/environment-and-safety/posts').then(response => response.data);

export const loadPublicPolicyPosts = () =>
  httpCustomerCore.get('social-media/public-policy/hauler').then(response => response.data);
