import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getIconTypeForCityAlert } from 'src/common/constants/cityAlertsIcons';
import { ROUTE_MAP_CITY_ALERTS_LAYER } from '../constants';

export type CityAlertProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getCityAlertsGeoJSON = (cityAlerts: CityAlert[]) =>
  getFeatureCollection<GeoJSON.Point, CityAlertProperties>(
    cityAlerts.map(alert =>
      getPointFeature(alert.id || 0, [alert.longitude, alert.latitude], {
        id: alert.id || 0,
        clickable: true,
        count: alert.totalCount,
        layer: ROUTE_MAP_CITY_ALERTS_LAYER,
        icon: getIconTypeForCityAlert(alert)?.id,
      }),
    ),
  );
