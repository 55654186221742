export const CONTAINER_BLOCKED = 'CONBLOCK';
export const SITE_CONTAINER_INACCESSIBLE = 'STCONINAC';
export const SERVICE_ON_SUSPENSION = 'SVCONSUSP';
export const SITE_UNSAFE = 'STUNSAFE';
export const SERVICE_COMPLETED = 'SVCCOMPL';
export const CONTAINER_OVERFLOWING = 'CONOVERFL';
export const CONTAINER_CONTAMINATED = 'CONCONTAM';
export const NOT_SERVICE_PROVIDER = 'NOTSVCPROV';
export const HOLIDAY = 'HOLIDAY';
export const DRIVER_MISSED = 'DVMISSED';
export const INCORRECT_SERVICE_DAYS = 'INCSVCDAY';
export const INTERNAL_TRUCK_PERSONNEL_ISSUE = 'TRPERSISS';
export const ROUTE_DELAY = 'RTDELAY';
export const WEATHER_EVENT = 'WEATHEREV';
