import { connect } from 'react-redux';
import { PureComponent } from 'react';

import { CustomerProximitySearchForm } from '../forms';
import { DuckFunction } from '../../../contracts/ducks';
import { getVehiclesInProximity } from '../../ducks';
import { Modal } from '../../../core/components';
import { PinLocation } from 'src/customers/interfaces/Customers';

interface Props {
  defaultDate: Date | string;
  defaultDateIsSelected: boolean;
  getVehiclesInProximity: DuckFunction<typeof getVehiclesInProximity>;
  locationId: number;
  onCancel: () => void;
}

class CustomerProximitySearchModal extends PureComponent<Props> {
  onSubmit = (
    customerDate: Date | string | undefined,
    customerTime: string | undefined,
    pinLocation: PinLocation | undefined,
    proximitySetting: number,
    vendorId: number,
  ) => {
    const { getVehiclesInProximity } = this.props;
    const customerTimeSelected = customerTime?.split(' - ');

    if (pinLocation) {
      getVehiclesInProximity({
        latitude: pinLocation.latitude || 0,
        longitude: pinLocation.longitude || 0,
        radius: proximitySetting,
        startTime: customerTimeSelected ? customerTimeSelected[0] : undefined,
        endTime: customerTimeSelected ? customerTimeSelected[1] : undefined,
        date: customerDate,
        vendorId,
      });
    }
  };

  render() {
    const { defaultDate, defaultDateIsSelected, locationId, onCancel } = this.props;

    return (
      <Modal onClose={onCancel} padding="no" size="mediumLarge">
        <CustomerProximitySearchForm
          defaultDate={defaultDate}
          defaultDateIsSelected={defaultDateIsSelected}
          locationId={locationId}
          onFormSubmit={this.onSubmit}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  getVehiclesInProximity,
};

export default connect(undefined, mapDispatchToProps)(CustomerProximitySearchModal);
