import { WidgetsContainer } from 'src/dashboard/components/styled';
import DashboardWidget from './DashboardWidget';
import translate from 'src/core/services/translate';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ActiveVehicleWidgetContent from './ActiveVehicleWidgetContent';
import ExtraMapLayersWidgetContent from './ExtraMapLayersWidgetContent';
import ExtraMapLayersHeaderMenu from './ExtraMapLayersHeaderMenu';

interface DashboardWidgetsProps {
  displayActiveVehicleTypes: boolean;
  isForSnowSweeper?: boolean;
}

export const FILTER_CATEGORIES = {
  cityInsights: 'cityInsights',
  cityAlerts: 'cityAlerts',
  geoFences: 'geoFences',
  vendorLocations: 'vendorLocations',
};

const DashboardWidgets: FC<DashboardWidgetsProps> = ({ displayActiveVehicleTypes, isForSnowSweeper }) => {
  const [isGeoFencesOpen, setIsGeoFencesOpen] = useState(true);
  const [isCityInsightsOpen, setIsCityInsightsOpen] = useState(false);
  const [isHaulerLocationsOpen, setIsHaulerLocationsOpen] = useState(false);
  const [isCityAlertsOpen, setIsCityAlertsOpen] = useState(false);

  const [isFirstWidgetOpen, setIsFirstWidgetOpen] = useState(false);
  const [isSecondWidgetOpen, setIsSecondWidgetOpen] = useState(true);

  const widgetsContainerRef = useRef<HTMLDivElement>(null);

  const availableHeight = (widgetsContainerRef.current?.clientHeight || 120) - 120;

  const [firstWidgetHeight, setFirstWidgetHeight] = useState(40);
  const [secondWidgetHeight, setSecondWidgetHeight] = useState(40);

  const maxHeightFirst = useMemo(() => {
    const height = availableHeight - secondWidgetHeight + 40;
    return height;
  }, [availableHeight, secondWidgetHeight]);

  const maxHeightSecond = useMemo(() => {
    const height = availableHeight - firstWidgetHeight + 40;
    return height;
  }, [availableHeight, firstWidgetHeight]);

  const toggleCategory = useCallback((category: string) => {
    switch (category) {
      case FILTER_CATEGORIES.cityInsights:
        setIsCityInsightsOpen(true);
        setIsGeoFencesOpen(false);
        setIsCityAlertsOpen(false);
        setIsHaulerLocationsOpen(false);
        break;
      case FILTER_CATEGORIES.geoFences:
        setIsGeoFencesOpen(true);
        setIsCityInsightsOpen(false);
        setIsCityAlertsOpen(false);
        setIsHaulerLocationsOpen(false);
        break;
      case FILTER_CATEGORIES.vendorLocations:
        setIsHaulerLocationsOpen(true);
        setIsCityInsightsOpen(false);
        setIsCityAlertsOpen(false);
        setIsGeoFencesOpen(false);
        break;
      case FILTER_CATEGORIES.cityAlerts:
        setIsCityAlertsOpen(true);
        setIsCityInsightsOpen(false);
        setIsGeoFencesOpen(false);
        setIsHaulerLocationsOpen(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!displayActiveVehicleTypes) {
      setSecondWidgetHeight(0);
      setIsSecondWidgetOpen(false);
    }
  }, [displayActiveVehicleTypes]);

  const firstWidgetInitialHeight = 0.6 * availableHeight;
  const secondWidgetInitialHeight = 0.2 * availableHeight;

  const toggleFirstWidget = useCallback(() => {
    // if there is not sufficient space for the widget, close the second widget
    if (firstWidgetInitialHeight > maxHeightFirst && isSecondWidgetOpen && !isFirstWidgetOpen) {
      setIsSecondWidgetOpen(false);
      setSecondWidgetHeight(40);
    }
    setIsFirstWidgetOpen(!isFirstWidgetOpen);
  }, [firstWidgetInitialHeight, isFirstWidgetOpen, isSecondWidgetOpen, maxHeightFirst]);

  const toggleSecondWidget = useCallback(() => {
    // if there is not sufficient space for the widget, close the first widget
    if (secondWidgetInitialHeight > maxHeightSecond && !isSecondWidgetOpen && isFirstWidgetOpen) {
      setIsFirstWidgetOpen(false);
      setFirstWidgetHeight(40);
    }
    setIsSecondWidgetOpen(!isSecondWidgetOpen);
  }, [isFirstWidgetOpen, isSecondWidgetOpen, maxHeightSecond, secondWidgetInitialHeight]);

  return (
    <WidgetsContainer position="right" ref={widgetsContainerRef}>
      <DashboardWidget
        topMargin={25}
        icon="layers"
        title={translate('dashboard.filterKeys.mapLayers')}
        setWidgetHeight={setFirstWidgetHeight}
        maxHeight={maxHeightFirst}
        initialHeight={firstWidgetInitialHeight}
        isHeaderOpen={isFirstWidgetOpen}
        setIsHeaderOpen={toggleFirstWidget}
        headerMenu={
          <ExtraMapLayersHeaderMenu
            isCityAlertsOpen={isCityAlertsOpen}
            isCityInsightsOpen={isCityInsightsOpen}
            isGeoFencesOpen={isGeoFencesOpen}
            isHaulerLocationsOpen={isHaulerLocationsOpen}
            toggleCategory={toggleCategory}
            isForSnowSweeper={isForSnowSweeper}
          />
        }
      >
        <ExtraMapLayersWidgetContent
          isHaulerLocationsOpen={isHaulerLocationsOpen}
          isCityInsightsOpen={isCityInsightsOpen}
          isGeoFencesOpen={isGeoFencesOpen}
          isCityAlertsOpen={isCityAlertsOpen}
          isForSnowSweeper={isForSnowSweeper}
        />
      </DashboardWidget>

      {displayActiveVehicleTypes && (
        <DashboardWidget
          initialHeight={secondWidgetInitialHeight}
          isHeaderOpen={isSecondWidgetOpen}
          setIsHeaderOpen={toggleSecondWidget}
          topMargin={0}
          icon="vehicle"
          size="small"
          maxHeight={maxHeightSecond}
          setWidgetHeight={setSecondWidgetHeight}
          title={translate('common.activeVehicleTypes')}
        >
          <ActiveVehicleWidgetContent />
        </DashboardWidget>
      )}
    </WidgetsContainer>
  );
};

export default DashboardWidgets;
