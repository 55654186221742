import { useDispatch } from 'react-redux';
import humps from 'humps';

import { Button, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { CustomerImportFile } from 'src/customers/interfaces/CustomerImport';
import { dateAndTime } from '../../../../utils/services/formatter';
import { downloadCustomersImportFile } from 'src/customers/ducks';
import { Loader } from '../../styled/CustomerLocations';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../../core/services/translate';

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'ValidationFailed':
      return 'alert';
    case 'Failed':
      return 'alert';
    case 'Completed':
      return 'primary';
    case 'Uploaded':
      return 'primary';
    case 'ValidationPassed':
      return 'success';
    case 'Queued':
      return 'info';
    case 'ReadyToExtractFile':
      return 'info';
    case 'Uploading':
      return 'info';
    case 'ValidationInProgress':
      return 'info';
    case 'ProcessingInProgress':
      return 'info';
    case 'PartiallyUploaded':
      return 'secondary';
    case 'CompletedPartially':
      return 'info';
    case 'Processing':
      return 'info';
    case 'Canceled':
      return 'info';
    default:
      return 'grayDarker';
  }
};

interface Props extends CustomerImportFile {
  loading: boolean;
}

export const CustomerImportModalTableRow = ({
  id,
  date,
  fileName,
  fileDownloadUrl,
  customerImportBatchStatusTypeId,
  errorFileName,
  errorFileDownloadUrl,
  totalRecordsCount,
  uploadedRecordsCount,
  failedRecordsCount,
  invalidRecordsCount,
  validRecordsCount,
  loading,
}: Props) => {
  const dispatch = useDispatch();

  const statuses: TechnicalType[] = useSelector(state => state.customers.customerImportStatusType.statusTypeValues);
  const status =
    statuses.find((status: TechnicalType) => status.id === customerImportBatchStatusTypeId)?.technicalName || '';

  const statusColor = getStatusColor(status);

  const showLoading = status !== 'ValidationFailed' && status !== 'Uploaded' && loading;

  const handleDownloadFile = (fileUrl: string, fileName: string, date?: string) => {
    downloadCustomersImportFile(fileUrl, fileName, date)(dispatch);
  };

  return (
    <TableRow key={id} height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width="13%">
        {fileDownloadUrl && (
          <Button
            fullWidth
            text
            noCapitalize
            color="primary"
            onClick={() => handleDownloadFile(fileDownloadUrl, fileName)}
          >
            <Text align="left" block singleLine title={fileName}>
              {fileName}
            </Text>
          </Button>
        )}
      </TableCell>
      <TableCell width="10%">
        {showLoading ? (
          <Loader />
        ) : (
          <Text color={statusColor}>{translate(`customers.customerUpload.statuses.${humps.camelize(status)}`)}</Text>
        )}
      </TableCell>
      <TableCell width="10%">
        <Text>{totalRecordsCount}</Text>
      </TableCell>
      <TableCell width="11%">
        <Text>{uploadedRecordsCount}</Text>
      </TableCell>
      <TableCell width="10%">
        <Text>{failedRecordsCount}</Text>
      </TableCell>
      <TableCell width="10%">
        <Text>{validRecordsCount}</Text>
      </TableCell>
      <TableCell width="10%">
        <Text>{invalidRecordsCount}</Text>
      </TableCell>
      <TableCell width="10%">
        {errorFileDownloadUrl && (
          <Button text color="primary" onClick={() => handleDownloadFile(errorFileDownloadUrl, errorFileName, date)}>
            {translate('common.download')}
          </Button>
        )}
      </TableCell>
      <TableCell width="16%">
        <Text>{dateAndTime(date)}</Text>
      </TableCell>
    </TableRow>
  );
};
