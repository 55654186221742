import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';

import { AppState } from 'src/store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { Modal } from '../../../core/components';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import { UnableToCompleteServiceConfirmationForm } from '../forms';
import { updateServiceConfirmation } from '../../ducks';
import translate from '../../../core/services/translate';
import { responseMissedPickup } from 'src/fleet/ducks/openDispatches';

type RouteParams = {
  token: string;
};

interface Props extends RouteComponentProps<RouteParams> {
  itemId: number;
  isSaving?: boolean;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  note?: string;
  onCancel: (pristine?: boolean, isReload?: boolean) => void;
  reasonCode?: string;
  reasonCodes?: ReasonCodeGroups;
  rubiconPONbr: string;
  serviceDate?: Date | string;
  status?: string;
  responseMissedPickup: DuckFunction<typeof responseMissedPickup>;
  updateServiceConfirmation: DuckFunction<typeof updateServiceConfirmation>;
  userId: string;
  vendID?: string;
  vendorId: number;
  weightTicket?: number;
  mobileVersion?: boolean;
  pickupTypeId: number;
}

class UnableToCompleteServiceConfirmationModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      onCancel,
      itemId,
      rubiconPONbr,
      status,
      serviceDate,
      note,
      reasonCode,
      weightTicket,
      userId,
      vendorId,
      loadDispatchOpportunitiesCount,
      match: {
        params: { token },
      },
      location: { search },
      pickupTypeId,
      vendID,
      responseMissedPickup,
      updateServiceConfirmation,
    } = this.props;

    const { dispatchStatus, startDate, endDate, workOrder, workOrderType, page, limit } = getQueryParams(search);
    const searchFilter = { dispatchStatus, startDate, endDate, workOrder, workOrderType, page, limit };
    const action = 'UnableToComplete';
    const responseType = 'SCHEDULED';
    const uom = 1;
    const weitghtTicketUom = undefined;

    const promise =
      pickupTypeId === MISSED_PICKUP && !!itemId
        ? responseMissedPickup(
            itemId,
            responseType,
            '',
            undefined,
            userId,
            token,
            vendorId,
            undefined,
            undefined,
            undefined,
            weitghtTicketUom,
            note,
          )
        : updateServiceConfirmation(
            rubiconPONbr,
            status,
            note,
            reasonCode,
            moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
            action,
            weightTicket,
            uom,
            userId,
            token,
            vendorId,
            vendID,
            weitghtTicketUom,
            searchFilter,
            null,
          );

    promise &&
      promise
        .then(() => {
          const isReload = true;
          loadDispatchOpportunitiesCount(userId, vendorId, token);
          createSuccessNotification(translate('opportunity.opportunitySave'));
          onCancel(true, isReload);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
  };

  render() {
    const { isSaving, mobileVersion } = this.props;

    return (
      <Modal
        overflow={mobileVersion ? 'visible' : undefined}
        minHeight={mobileVersion ? undefined : '415px'}
        size={mobileVersion ? undefined : 'small'}
        isLoading={isSaving}
        title={translate('autoDispatch.cannotComplete')}
      >
        <Grid centered>
          <GridColumn size={mobileVersion ? '12/12' : '8/12'}>
            <UnableToCompleteServiceConfirmationForm {...this.props} onSubmit={this.onSubmit} />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('unableToCompleteServiceConfirmation');

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  note: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.note`),
  reasonCode: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.reasonCode`),
  isSaving: state.fleet.openDispatches.isSaving,
});

export default connect(mapStateToProps)(UnableToCompleteServiceConfirmationModal);
