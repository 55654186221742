import { FC, memo } from 'react';
import { Layer } from 'react-map-gl';
import { theme } from 'src/core/styles';

import {
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE,
  STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER,
} from 'src/customers/constants';

const CustomerLocationsGLLayer: FC = () => {
  return (
    <Layer
      id={STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER}
      type="circle"
      source={STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE}
      filter={[
        'all',
        ['!', ['has', 'point_count']],
        ['==', ['get', 'layer'], STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER],
      ]}
      paint={{
        'circle-radius': 6,
        'circle-color': theme.brandPrimary,
        'circle-stroke-width': 2,
        'circle-stroke-color': theme.brandWhite,
      }}
    />
  );
};

export default memo(CustomerLocationsGLLayer);
