import { currency, date } from 'src/utils/services/formatter';
import { Icon } from 'src/core/components';
import { PaymentDetail } from 'src/finance/components/styled/PaymentDetail';
import { PaymentDetail as PaymentDetailInterface, PaymentStatusOptions } from 'src/finance/interfaces/ApplyChecks';
import { PaymentDetailLabel } from 'src/finance/components/styled/PaymentDetailLabel';
import { PaymentDetailsWrapper } from 'src/finance/components/styled/PaymentDetailsWrapper';
import { PaymentDetailValue, PaymentDetailValueWithIcon } from 'src/finance/components/styled/PaymentDetailValue';
import translate from 'src/core/services/translate';

interface Props {
  openEditPaymentDetailsModal(): void;
  paymentDetails?: PaymentDetailInterface;
  paymentStatusOptions: PaymentStatusOptions[];
}

const PaymentsDetailsSection = ({ openEditPaymentDetailsModal, paymentDetails, paymentStatusOptions }: Props) => {
  if (!paymentDetails) return null;

  const { customerName, customerId, paymentTotal, paymentNumber, paymentDate, paymentStatusId, imageUrl } =
    paymentDetails;

  const paymentStatus = paymentStatusOptions?.find(item => item.value === paymentStatusId)?.label || '-';

  const hasImage = imageUrl;

  return (
    <PaymentDetailsWrapper withBorder>
      <PaymentDetail margin="ssMedium no small">
        <PaymentDetailLabel>{translate('finance.applyChecks.paymentNumber')}</PaymentDetailLabel>
        <PaymentDetailValue>{paymentNumber || '-'}</PaymentDetailValue>
      </PaymentDetail>
      <PaymentDetail margin="ssMedium no small">
        <PaymentDetailLabel>{translate('finance.applyChecks.paymentDate')}</PaymentDetailLabel>
        <PaymentDetailValue>{date(paymentDate) || '-'}</PaymentDetailValue>
      </PaymentDetail>
      <PaymentDetail margin="ssMedium no small">
        <PaymentDetailLabel>{translate('finance.applyChecks.paymentAmount')}</PaymentDetailLabel>
        <PaymentDetailValue>{currency(paymentTotal || 0)}</PaymentDetailValue>
      </PaymentDetail>
      <PaymentDetail margin="ssMedium no small">
        <PaymentDetailLabel>{translate('finance.applyChecks.status')}</PaymentDetailLabel>
        <PaymentDetailValue>{paymentStatus || '-'}</PaymentDetailValue>
      </PaymentDetail>
      <PaymentDetail margin="ssMedium no small">
        <PaymentDetailLabel>{translate('finance.applyChecks.customer')}</PaymentDetailLabel>
        <PaymentDetailValue>{customerName || '-'}</PaymentDetailValue>
      </PaymentDetail>
      <PaymentDetail margin="ssMedium no small">
        <PaymentDetailLabel>{translate('finance.applyChecks.customerId')}</PaymentDetailLabel>
        <PaymentDetailValue>{customerId || '-'}</PaymentDetailValue>
      </PaymentDetail>

      <PaymentDetail clickable={!!hasImage} onClick={openEditPaymentDetailsModal} margin="ssMedium no small">
        <PaymentDetailLabel>{translate('finance.applyChecks.image')}</PaymentDetailLabel>
        {!!hasImage ? (
          <PaymentDetailValueWithIcon>
            <Icon icon="image" width="18px" height="18px" />
          </PaymentDetailValueWithIcon>
        ) : (
          '-'
        )}
      </PaymentDetail>
    </PaymentDetailsWrapper>
  );
};

export default PaymentsDetailsSection;
