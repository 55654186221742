import React, { Fragment } from 'react';

import { map, size } from 'lodash-es';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { TOTER, WASTE_AUDIT } from 'src/fleet/constants';
import { WasterRecorder } from 'src/routes/interfaces/WasterRecorder';

interface Props {
  wasterRecorders?: WasterRecorder[];
  wasteAuditStatuses?: Array<{
    id: number;
    name: string;
    type: number;
  }>;
  vehicleTypeTechnicalName: string;
  wasteAuditType?: TechnicalType;
}

const RoutePageStatusDetailsPopover = ({
  wasterRecorders = [],
  wasteAuditStatuses = [],
  vehicleTypeTechnicalName,
  wasteAuditType,
}: Props) => {
  return (
    <Fragment>
      {!size(wasterRecorders) && vehicleTypeTechnicalName === TOTER && (
        <Text weight="medium" size="small">
          {translate('routes.noCarts')}
        </Text>
      )}

      {!size(wasterRecorders) &&
        wasterRecorders?.map(({ equipmentTypeName, numberOfBins }) => (
          <Text block size="small" margin="no no xSmall" key={equipmentTypeName}>
            <Text weight="medium" size="small">
              {equipmentTypeName}
            </Text>
            : {numberOfBins}
          </Text>
        ))}

      {!wasteAuditStatuses.length && wasteAuditType && vehicleTypeTechnicalName === WASTE_AUDIT && (
        <Text weight="medium" size="small">
          {translate(`routes.noWasteAudit${wasteAuditType.technicalName}`)}
        </Text>
      )}

      {!!size(wasteAuditStatuses) &&
        map(wasteAuditStatuses, wasteAuditStatus => (
          <Text block weight="medium" size="small" margin="no no xSmall" key={wasteAuditStatus.id}>
            {wasteAuditStatus.name}
          </Text>
        ))}
    </Fragment>
  );
};

export default RoutePageStatusDetailsPopover;
