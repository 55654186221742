import { httpInsightsReporting } from '../../core/services/http';
import { RoadSegmentCondition } from '../interfaces/roadConditions';

export const loadRoadConditions = (vendorId: number) =>
  httpInsightsReporting
    .get<GeoJSON.FeatureCollection<GeoJSON.LineString, RoadSegmentCondition>>(`vendors/${vendorId}/roadconitions`)
    /**
     * TODO
     * It should look like this after BE changes things.
     */
    // .then(response => response.data);
    .then(response => response.data.features);
