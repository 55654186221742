import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { LowInventoryPartPartHistory, LowInventoryParts } from '../interfaces/ContainerStatistics';

import {
  loadLowInventoryParts as doLoadLowInventoryParts,
  loadLowInventoryPartHistory as doLoadLowInventoryPartHistory,
  saveLowInventoryPart as doSaveLowInventoryPart,
  editLowInventoryPart as doEditLowInventoryPart,
  deleteLowInventoryPart as doDeleteLowInventoryPart,
  editInventoryPart as doEditInventoryPart,
} from '../services/lowInventoryParts';

// Actions
const START_LOAD = 'fleet/lowInventoryParts/START_LOAD';
const COMPLETE_LOAD = 'fleet/lowInventoryParts/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/lowInventoryParts/FAIL_LOAD';
const START_LOAD_PART_HISTORY = 'fleet/lowInventoryParts/START_LOAD_PART_HISTORY';
const COMPLETE_LOAD_PART_HISTORY = 'fleet/lowInventoryParts/COMPLETE_LOAD_PART_HISTORY';
const FAIL_LOAD_PART_HISTORY = 'fleet/lowInventoryParts/FAIL_LOAD_PART_HISTORY';
const START_SAVE = 'fleet/lowInventoryParts/START_SAVE';
const COMPLETE_SAVE = 'fleet/lowInventoryParts/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/lowInventoryParts/FAIL_SAVE';
const START_DELETE = 'fleet/lowInventoryParts/START_DELETE';
const COMPLETE_DELETE = 'fleet/lowInventoryParts/COMPLETE_DELETE';
const FAIL_DELETE = 'fleet/lowInventoryParts/FAIL_DELETE';
const START_EDIT = 'fleet/lowInventoryParts/START_EDIT';
const COMPLETE_EDIT = 'fleet/lowInventoryParts/COMPLETE_EDIT';
const FAIL_EDIT = 'fleet/lowInventoryParts/FAIL_EDIT';

// Initial state
const initialState = {
  isEditing: false,
  isDeleting: false,
  isLoading: false,
  isLoadingHistory: false,
  isSaving: false,
  lowInventoryPartHistory: undefined,
  lowInventoryParts: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          lowInventoryParts: action.lowInventoryParts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          lowInventoryParts: undefined,
        },
      });

    case START_LOAD_PART_HISTORY:
      return update(state, {
        $merge: {
          isLoadingHistory: true,
        },
      });

    case COMPLETE_LOAD_PART_HISTORY:
      return update(state, {
        $merge: {
          isLoadingHistory: false,
          lowInventoryPartHistory: action.lowInventoryPartHistory,
        },
      });

    case FAIL_LOAD_PART_HISTORY:
      return update(state, {
        $merge: {
          isLoadingHistory: false,
          lowInventoryPartHistory: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_EDIT:
      return update(state, {
        $merge: {
          isEditing: true,
        },
      });

    case COMPLETE_EDIT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case FAIL_EDIT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (lowInventoryParts: LowInventoryParts[]) => ({
  type: COMPLETE_LOAD,
  lowInventoryParts,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadPartHistory = () => ({
  type: START_LOAD_PART_HISTORY,
});

const completeLoadPartHistory = (lowInventoryPartHistory: LowInventoryPartPartHistory[]) => ({
  type: COMPLETE_LOAD_PART_HISTORY,
  lowInventoryPartHistory,
});

const failLoadPartHistory = () => ({
  type: FAIL_LOAD_PART_HISTORY,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

export const loadLowInventoryParts = (vendorId: number, containerTypeId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadLowInventoryParts(vendorId, containerTypeId)
    .then((lowInventoryParts: LowInventoryParts[]) => dispatch(completeLoad(lowInventoryParts)))
    .catch(() => dispatch(failLoad()));
};

export const loadLowInventoryPartHistory = (vendorId: number, id: number) => (dispatch: Dispatch) => {
  dispatch(startLoadPartHistory());
  return doLoadLowInventoryPartHistory(vendorId, id)
    .then((lowInventoryPartHistory: LowInventoryPartPartHistory[]) =>
      dispatch(completeLoadPartHistory(lowInventoryPartHistory)),
    )
    .catch(() => dispatch(failLoadPartHistory()));
};

export const saveLowInventoryPart = (vendorId: number, payLoad: any, id?: number) => (dispatch: Dispatch) => {
  dispatch(startSave());
  return (id ? doEditLowInventoryPart(vendorId, payLoad, id) : doSaveLowInventoryPart(vendorId, payLoad))
    .then(() => dispatch(completeSave()))
    .catch(() => dispatch(failSave()));
};

export const deleteLowInventoryPart = (vendorId: number, id: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  return doDeleteLowInventoryPart(vendorId, id)
    .then(() => dispatch(completeDelete()))
    .catch(() => dispatch(failDelete()));
};

export const editLowInventoryPart = (vendorId: number, id: number, partName: string) => (dispatch: Dispatch) => {
  dispatch(startEdit());
  return doEditInventoryPart(vendorId, id, partName)
    .then(() => dispatch(completeEdit()))
    .catch(() => dispatch(failEdit()));
};
