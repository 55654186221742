import { PureComponent } from 'react';

import { Field } from 'redux-form';

import { Switch } from '../../core/components';
import { TableCell, TableRow } from '../../core/components/styled';
import { VEHICLE_TYPE_IDS } from '../../fleet/constants';

interface Props {
  vehicleTypeId: number;
  isDisabled?: boolean;
}

class VehicleTypesTableRow extends PureComponent<Props> {
  render() {
    const { vehicleTypeId, isDisabled } = this.props;

    return (
      <>
        <TableRow>
          <TableCell width="80%">{VEHICLE_TYPE_IDS[vehicleTypeId].name}</TableCell>
          <TableCell width="20%">
            <Field component={Switch} name={`${vehicleTypeId}.isActive`} disabled={isDisabled} />
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default VehicleTypesTableRow;
