import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { AppState } from 'src/store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { Modal } from '../../../core/components';
import { NON_SCHEDULED, LIMIT_PER_PAGE } from '../../constants';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import { RequestDateChangeForOpenDispatchesForm } from '../forms';
import { updateOpenDispatches } from '../../ducks/openDispatches';
import translate from '../../../core/services/translate';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

type RouteParams = {
  token: string;
};

interface Props extends RouteComponentProps<RouteParams> {
  completedDate?: Date | string;
  isSaving?: boolean;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  note: string;
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  reasonCode: string;
  reasonCodes?: ReasonCodeGroups;
  requestedServiceDate: Date | string;
  rubiconPONbr: string;
  serviceDate: Date | string;
  status: string;
  updateOpenDispatches: DuckFunction<typeof updateOpenDispatches>;
  userId: string;
  vendID: string;
  vendorId: number;
}

class RequestDateChangeForOpenDispatchesModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      completedDate,
      loadDispatchOpportunitiesCount,
      location: { search },
      match: {
        params: { token },
      },
      note,
      onCancel,
      reasonCode,
      rubiconPONbr,
      serviceDate,
      status,
      updateOpenDispatches,
      userId,
      vendID,
      vendorId,
    } = this.props;

    const { dispatchStatus, startDate, endDate, workOrder, page } = getQueryParams(search);
    const searchFilter = {
      dispatchStatus,
      startDate,
      endDate,
      workOrder,
      workOrderType: NON_SCHEDULED,
      page,
      limit: LIMIT_PER_PAGE,
    };
    const action = 'RequestDateChange';
    const weightTicketUom = undefined;
    const promise = updateOpenDispatches(
      rubiconPONbr,
      status,
      note,
      reasonCode,
      moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
      action,
      null,
      1,
      userId,
      token,
      vendorId,
      vendID,
      weightTicketUom,
      searchFilter,
      moment(completedDate).format('YYYY-MM-DD HH:mm:ss'),
    );

    promise &&
      promise
        .then(() => {
          const isReload = true;
          loadDispatchOpportunitiesCount(userId, vendorId, token);
          createSuccessNotification(translate('opportunity.opportunitySave'));
          onCancel(true, isReload);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
  };

  render() {
    const { isSaving } = this.props;
    return (
      <Modal size="small" isLoading={isSaving}>
        <ModalTitle>{translate('opportunity.requestChanges')}</ModalTitle>
        <ModalSubtitle />
        <Grid centered>
          <UseIsMobileWidthView
            render={isMobile => (
              <GridColumn size={isMobile ? '12/12' : '10/12'}>
                <RequestDateChangeForOpenDispatchesForm {...this.props} onSubmit={this.onSubmit} />
              </GridColumn>
            )}
          />
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('requestDateChangeForOpenDispatches');

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  note: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.note`),
  reasonCode: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.reasonCode`),
  requestedServiceDate: formSelector(
    state,
    `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.requestedServiceDate`,
  ),
  completedDate: formSelector(
    state,
    `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.completedDate`,
  ),
  serviceDate: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.serviceDate`),
  isSaving: state.fleet.openDispatches.isSaving,
});

export default connect(mapStateToProps)(RequestDateChangeForOpenDispatchesModal);
