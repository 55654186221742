import { filter, map } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { StylesConfig } from 'react-select';
import { change, InjectedFormProps, reduxForm } from 'redux-form';

import { PanelSearch, TypedField } from 'src/core/components';
import { dropdownStyles } from 'src/core/components/Dropdown';
import translate from 'src/core/services/translate';
import { RoutePlannerVehiclesAndDriversSearchFormWrapper, SearchDriverOrVehicleWrapper } from '../../styled';

interface PropsWithoutReduxForm {
  onOptionCLicked: (option: any) => void;
  formName?: string;
  options?: any[];
}

interface FormData {
  searchTerm?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const RoutePlannerVehiclesAndDriversSearchForm = (props: Props) => {
  const { options, handleSubmit, onOptionCLicked } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  const filteredOptions = useMemo(() => {
    if (!searchTerm) {
      return options;
    }
    return map(options, option => {
      const filteredOption = filter(option.options, (option: any) => {
        const { label } = option;
        return label?.toLowerCase()?.includes(searchTerm.toLowerCase());
      });

      return {
        ...option,
        options: filteredOption,
      };
    });
  }, [options, searchTerm]);

  const handleCLickOption = (option: any) => {
    onOptionCLicked(option);
    dispatch(change(props.form, 'searchTerm', ''));
    setSearchTerm('');
  };

  const dropDownStylesNoInput: StylesConfig<any, false> = {
    ...dropdownStyles,
    valueContainer: () => ({ display: 'none' }),
    indicatorsContainer: () => ({ position: 'absolute', top: '4px', right: '22px' }),
    dropdownIndicator: () => ({ display: 'none' }),
    option: (base, state: any) => ({
      ...(dropdownStyles.option && dropdownStyles.option(base, state)),
      color: state.data.isDoubleBooked ? 'red' : 'black',
      fontStyle: state.data.isDoubleBooked || state.data.isBooked ? 'italic' : 'normal',
      paddingLeft: '15px',
    }),
    groupHeading: (base, state: any) => ({
      ...(dropdownStyles.groupHeading && dropdownStyles.groupHeading(base, state)),
      fontWeight: 'bolder',
      padding: '5px 10px',
      backgroundColor: '#00a599',
      color: 'white',
    }),
  };

  return (
    <RoutePlannerVehiclesAndDriversSearchFormWrapper>
      <form onSubmit={handleSubmit}>
        <SearchDriverOrVehicleWrapper>
          <TypedField
            name="searchTerm"
            component={PanelSearch}
            onChange={(event: any, searchTerm: string) => setSearchTerm(searchTerm)}
            props={{
              margin: 'no',
              isClearable: true,
              placeholder: translate('routes.planner.searchForDriverOrVehicle'),
            }}
          />
        </SearchDriverOrVehicleWrapper>
        <Select
          options={filteredOptions}
          menuIsOpen={!!searchTerm.length}
          inputId={'searchTerm_select'}
          styles={dropDownStylesNoInput}
          value={null}
          onChange={handleCLickOption}
        />
      </form>
    </RoutePlannerVehiclesAndDriversSearchFormWrapper>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  enableReinitialize: true,
})(RoutePlannerVehiclesAndDriversSearchForm);
