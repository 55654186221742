import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadStreetSweepingSettings as doLoadStreetSweepingSettings,
  saveStreetSweepingSettings as doSaveStreetSweepingSettings,
  loadStreetSweepingDefaultSettings as doLoadStreetSweepingDefaultSettings,
} from '../services/streetSweepingSettings';
import { StreetSweepingSettings } from '../interfaces/StreetSweepingSettings';

// Actions
const COMPLETE_LOAD = 'vendors/streetSweepingSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/streetSweepingSettings/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/streetSweepingSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/streetSweepingSettings/FAIL_SAVE';
const START_LOAD = 'vendors/streetSweepingSettings/START_LOAD';
const START_SAVE = 'vendors/streetSweepingSettings/START_SAVE';
const START_LOAD_DEFAULT_SETTINGS = 'vendors/streetSweepingSettings/START_LOAD_DEFAULT_SETTINGS';
const COMPLETE_LOAD_DEFAULT_SETTINGS = 'vendors/streetSweepingSettings/COMPLETE_LOAD_DEFAULT_SETTINGS';
const FAIL_LOAD_DEFAULT_SETTINGS = 'vendors/streetSweepingSettings/FAIL_LOAD_DEFAULT_SETTINGS';

// Initial state
const initialState = {
  isLoading: false,
  isLoadingDefaultSettings: false,
  isSaving: false,
  streetSweepingSettings: {} as StreetSweepingSettings,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSweepingSettings: action.streetSweepingSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_DEFAULT_SETTINGS:
      return update(state, {
        $merge: {
          isLoadingDefaultSettings: true,
        },
      });

    case COMPLETE_LOAD_DEFAULT_SETTINGS:
      return update(state, {
        $merge: {
          isLoadingDefaultSettings: false,
        },
      });

    case FAIL_LOAD_DEFAULT_SETTINGS:
      return update(state, {
        $merge: {
          isLoadingDefaultSettings: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (streetSweepingSettings: StreetSweepingSettings) => ({
  type: COMPLETE_LOAD,
  streetSweepingSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadDefaultSettings = () => ({
  type: START_LOAD_DEFAULT_SETTINGS,
});

const completeLoadDefaultSettings = () => ({
  type: COMPLETE_LOAD_DEFAULT_SETTINGS,
});

const failLoadDefaultSettings = () => ({
  type: FAIL_LOAD_DEFAULT_SETTINGS,
});

export const loadStreetSweepingSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStreetSweepingSettingsPromise = doLoadStreetSweepingSettings(vendorId);
  loadStreetSweepingSettingsPromise
    .then(streetSweepingSettings => dispatch(completeLoad(streetSweepingSettings)))
    .catch(() => dispatch(failLoad()));

  return loadStreetSweepingSettingsPromise;
};

export const saveStreetSweepingSettings =
  (vendorId: number, streetSweepingSettings: StreetSweepingSettings) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveStreetSweepingSettingsPromise = doSaveStreetSweepingSettings(vendorId, streetSweepingSettings);
    saveStreetSweepingSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveStreetSweepingSettingsPromise;
  };

export const loadStreetSweepingDefaultSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadDefaultSettings());
  const loadStreetSweepingDefaultSettingsPromise = doLoadStreetSweepingDefaultSettings(vendorId);
  loadStreetSweepingDefaultSettingsPromise
    .then(() => dispatch(completeLoadDefaultSettings()))
    .catch(() => dispatch(failLoadDefaultSettings()));
  return loadStreetSweepingDefaultSettingsPromise;
};
