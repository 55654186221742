import React from 'react';

import { NewsSectionTitle } from './styled/News';
import { SocialMediaLinksWrapper, SocialMediaLink, SocialMediaIcon } from './styled/SocialMediaLinks';
import FacebookIcon from '../assets/facebook.svg';
import InstagramIcon from '../assets/instagram.svg';
import LinkedInIcon from '../assets/linkedIn.svg';
import translate from '../../core/services/translate';
import TwitterIcon from '../assets/twitter.svg';

const socialMedias = [
  { name: 'facebook', link: 'https://www.facebook.com/Rubicon/', icon: FacebookIcon },
  { name: 'linkedIn', link: 'https://www.linkedin.com/company/rubicon-global/', icon: LinkedInIcon },
  { name: 'instagram', link: 'https://www.instagram.com/rubicon/', icon: InstagramIcon },
  { name: 'twitter', link: 'https://twitter.com/Rubicon', icon: TwitterIcon },
];

export default function SocialMediaLinks() {
  return (
    <SocialMediaLinksWrapper>
      <NewsSectionTitle>{translate('account.joinOurConversation')}</NewsSectionTitle>
      {socialMedias.map(socialMedia => (
        <SocialMediaLink key={socialMedia.name} href={socialMedia.link} target="_blank">
          <SocialMediaIcon src={socialMedia.icon} />
        </SocialMediaLink>
      ))}
    </SocialMediaLinksWrapper>
  );
}
