import React, { ChangeEvent, PureComponent } from 'react';

import { DropDownFieldProps } from '../../../../common/components/DropDownFieldProps';
import { TechnicalType } from '../../../../common/interfaces/TechnicalType';
import { MultiSelect, TypedField } from '../../../../core/components';
import { MultiSelectProps } from '../../../../core/components/MultiSelect';
import translate from '../../../../core/services/translate';
import { size } from 'lodash-es';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('insights.allWasteTypes')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('insights.noWasteTypes')
    : translate('insights.xWasteTypesSelected', { selected: size(selectedOptions) });

interface WasteTypesMultiSelectProps extends DropDownFieldProps {
  label: string;
  multiSelectProps?: Partial<MultiSelectProps>;
  wasteTypes: TechnicalType[];
}

export const wasteTypeOptionMapper = (wasteTypes: TechnicalType[], objectAsValue?: boolean) =>
  wasteTypes.map(wt => ({
    label: wt.name,
    value: objectAsValue ? wt : wt.id.toString(),
  }));

class WasteTypesMultiSelect extends PureComponent<WasteTypesMultiSelectProps> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const { input, label, multiSelectProps, wasteTypes } = this.props;

    return (
      <TypedField
        name={input.name}
        component={MultiSelect}
        onChange={this.onChange as any}
        props={{
          label: label || translate('common.wasteType'),
          options: wasteTypeOptionMapper(wasteTypes),
          formatText,
          ...multiSelectProps,
        }}
      />
    );
  }
}

export default WasteTypesMultiSelect;
