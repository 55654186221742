import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { ContainerActivity } from '../interfaces/containers';

import { loadContainerActivity as doLoadContainerActivity } from '../services/container';

// Actions
const START_LOAD = 'fleet/containers/containerActivity/START_LOAD';
const COMPLETE_LOAD = 'fleet/containers/containerActivity/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/containerActivity/FAIL_LOAD';
const RESET = 'fleet/containers/containerActivity/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containerActivity: ContainerActivity | null;
} = {
  isLoading: false,
  containerActivity: null,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerActivity: action.containerActivity,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerActivity: null,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerActivity: ContainerActivity) => ({
  type: COMPLETE_LOAD,
  containerActivity,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainerActivity = (containerId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerActivityPromise = doLoadContainerActivity(containerId);
  loadContainerActivityPromise
    .then((containerActivity: ContainerActivity) => dispatch(completeLoad(containerActivity)))
    .catch(() => dispatch(failLoad()));
  return loadContainerActivityPromise;
};

export const resetContainerActivity = () => ({
  type: RESET,
});
