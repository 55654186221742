import { size } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { AppState } from '../../../store';
import {
  Button,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  ModalFixedHeader,
  ModalTitle,
  PanelSectionSubTitle,
} from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { FLEET, SNOW_PLOW, STREET_SWEEPER, WASTE_AUDIT } from 'src/insights/constants/insightsGroupIds';
import { InsightConfiguration } from 'src/vendors/interfaces/InsightConfiguration';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveInsightConfiguration } from 'src/vendors/ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import InsightConfigurationForm from '../forms/InsightConfigurationForm';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  closeModal: (pristine?: boolean) => void;
}

export default function InsightConfigurationModal({ closeModal }: Props) {
  const dispatch = useDispatch();

  const { insightConfiguration, isLoading, isSaving } = useSelector(
    (state: AppState) => state.vendors.insightConfiguration,
  );

  const [currentInsightConfiguration, setCurrentInsightConfiguration] = useState(
    insightConfiguration || ([] as InsightConfiguration[]),
  );

  const toggleInsightConfiguration = (id: number, isChecked: any | boolean) => {
    const newInsightConfiguration = [...currentInsightConfiguration] as InsightConfiguration[];
    const newConfig = newInsightConfiguration.map((val: InsightConfiguration) => {
      if (val.insightType.id === id) {
        const newValue = { ...val, isActive: isChecked };
        return newValue;
      }
      return val;
    });

    setCurrentInsightConfiguration(newConfig);
  };

  const saveConfiguration = () => {
    saveInsightConfiguration(currentInsightConfiguration)(dispatch)
      .then(() => {
        closeModal();
        createSuccessNotification(translate('vendors.alertMessages.insightConfigurationSaved'));
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.insightConfigurationSaveError')));
  };

  const fleetCurrentInsightConfiguration = currentInsightConfiguration.filter(
    config => config.dashboardInsightGroupId === FLEET,
  );

  const streetSweeperCurrentInsightConfiguration = currentInsightConfiguration.filter(
    config => config.dashboardInsightGroupId === STREET_SWEEPER,
  );

  const snowPlowCurrentInsightConfiguration = currentInsightConfiguration.filter(
    config => config.dashboardInsightGroupId === SNOW_PLOW,
  );

  const wasteAuditCurrentInsightConfiguration = currentInsightConfiguration.filter(
    config => config.dashboardInsightGroupId === WASTE_AUDIT,
  );

  return (
    <Modal isLoading={isLoading || isSaving} padding="no" size="smallMedium">
      <ModalFixedHeader padding="medium no no no">
        <ModalClose onClick={() => closeModal()}>
          <ModalCloseIcon />
        </ModalClose>
        <ModalTitle>{translate(createTranslationKey('insightConfiguration', 'vendors.featureCodes'))}</ModalTitle>
      </ModalFixedHeader>

      {size(fleetCurrentInsightConfiguration) && (
        <>
          <PanelSectionSubTitle margin="sMedium no no medium">
            {translate('insights.fleetInsights')}
          </PanelSectionSubTitle>
          <InsightConfigurationForm
            initialValues={insightConfiguration}
            currentInsightConfiguration={fleetCurrentInsightConfiguration}
            toggleInsightConfiguration={toggleInsightConfiguration}
          />
        </>
      )}

      {size(streetSweeperCurrentInsightConfiguration) && (
        <>
          <PanelSectionSubTitle margin="sMedium no no medium">
            {translate('insights.streetSweepingInsights')}
          </PanelSectionSubTitle>
          <InsightConfigurationForm
            initialValues={insightConfiguration}
            currentInsightConfiguration={streetSweeperCurrentInsightConfiguration}
            toggleInsightConfiguration={toggleInsightConfiguration}
          />
        </>
      )}

      {size(snowPlowCurrentInsightConfiguration) && (
        <>
          <PanelSectionSubTitle margin="sMedium no no medium">
            {translate('insights.snowPlowInsights')}
          </PanelSectionSubTitle>
          <InsightConfigurationForm
            initialValues={insightConfiguration}
            currentInsightConfiguration={snowPlowCurrentInsightConfiguration}
            toggleInsightConfiguration={toggleInsightConfiguration}
          />
        </>
      )}

      {size(wasteAuditCurrentInsightConfiguration) && (
        <>
          <PanelSectionSubTitle margin="sMedium no no medium">
            {translate('insights.wasteAuditInsights')}
          </PanelSectionSubTitle>
          <InsightConfigurationForm
            initialValues={insightConfiguration}
            currentInsightConfiguration={wasteAuditCurrentInsightConfiguration}
            toggleInsightConfiguration={toggleInsightConfiguration}
          />
        </>
      )}

      <ModalFixedFooter>
        <Button color="primary" margin="no" type="button" onClick={saveConfiguration}>
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </Modal>
  );
}
