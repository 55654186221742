import { AppState } from 'src/store';
import { LocationAndCityAlertsEditForm } from '../forms';
import { LocationAndCityAlertsFormValues } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { Modal } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';

interface Props {
  alertName: string;
  closeModal: () => void;
  initialValues: LocationAndCityAlertsFormValues;
  isCityAlert: boolean;
  onSubmit: (values: LocationAndCityAlertsFormValues) => void;
}

const LocationAndCityAlertsEditModal = ({ alertName, closeModal, initialValues, isCityAlert, onSubmit }: Props) => {
  const { isSaving } = useSelector((state: AppState) => state.fleet.locationAndCityAlerts);

  const onCloseModal = async (pristine: boolean) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
      return;
    }

    closeModal();
  };

  return (
    <Modal
      padding="medium no no"
      size="small"
      title={`${translate('common.edit')} ${alertName} ${translate('vendors.cityAlerts.alert')}`}
      minHeight="550px"
      isLoading={isSaving}
    >
      <LocationAndCityAlertsEditForm
        closeModal={onCloseModal}
        initialValues={initialValues}
        isCityAlert={isCityAlert}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default LocationAndCityAlertsEditModal;
