import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { InlineEditor, ActionButtonTooltip } from '../../../core/components';
import { TableCell, TableRow, Container, TableActionButton } from '../../../core/components/styled';
import { saveContact, updateContact, deleteContact } from '../../ducks';
import { TXTMS } from '../../../haulerProfile/constants';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { isPhone } from '../../../utils/services/validator';
import translate from '../../../core/services/translate';
import { DuckFunction } from '../../../contracts/ducks';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  change: any;
  deleteContact: DuckFunction<typeof deleteContact>;
  editingRow: number;
  fieldArrayKey: string;
  fields: any;
  gusId: string;
  index: number;
  saveContact: DuckFunction<typeof saveContact>;
  setEditingRow: (index: number | null) => void;
  updateContact: DuckFunction<typeof updateContact>;
  userName: string;
}

function PhoneNumbersFormTableRow({
  change,
  deleteContact,
  editingRow,
  fieldArrayKey,
  fields,
  gusId,
  index,
  saveContact,
  setEditingRow,
  updateContact,
  userName,
  match: {
    params: { token },
  },
}: Props) {
  const isAddMode = index === fields.length - 1;
  const isEditMode = index === editingRow;
  const isRowDisabled = editingRow !== null && !isEditMode;
  const [fieldBackup, setFieldBackup] = useState();

  const editRow = (value: any) => {
    change('phoneNumbers', fieldArrayKey, value);
  };

  const startEditing = () => {
    setEditingRow(index);
    setFieldBackup(fields.get(index));
  };

  const stopEditing = () => {
    setEditingRow(null);
    editRow(fieldBackup);
  };

  const savePhoneNumber = (event: any) => {
    event.stopPropagation();

    const { phoneNumber, firstName } = fields.get(index);
    const validationMessage = isPhone(phoneNumber);
    if (validationMessage) {
      createErrorNotification(validationMessage);
      return;
    }

    const data = {
      contactType: TXTMS,
      firstName,
      phoneNumber,
      updatedBy: userName,
    };

    saveContact(token, gusId, data)
      .then(({ data }) => {
        editRow(data);
        fields.push({});
        createSuccessNotification(`${translate('haulerProfile.alertMessages.contactSaved')}`);
      })
      .catch(() => {
        createErrorNotification(`${translate('haulerProfile.alertMessages.errorContactSave')}`);
      });
  };

  const updatePhoneNumber = (event: any) => {
    event.stopPropagation();

    const { id, phoneNumber, firstName } = fields.get(index);
    const validationMessage = isPhone(phoneNumber);
    if (validationMessage) {
      createErrorNotification(validationMessage);
      return;
    }

    const data = {
      id,
      contactType: TXTMS,
      firstName,
      phoneNumber,
      updatedBy: userName,
    };
    updateContact(token, gusId, data)
      .then(() => {
        setEditingRow(null);
        createSuccessNotification(`${translate('haulerProfile.alertMessages.contactUpdated')}`);
      })
      .catch(() => {
        createErrorNotification(`${translate('haulerProfile.alertMessages.errorContactSave')}`);
      });
  };

  const deletePhoneNumber = async (event: any) => {
    event.stopPropagation();

    if (!(await confirm(translate('haulerProfile.alertMessages.confirmDeleteContact')))) {
      return;
    }

    const { id } = fields.get(index);
    deleteContact(token, gusId, id)
      .then(() => {
        fields.remove(index);
        createSuccessNotification(`${translate('haulerProfile.alertMessages.contactDeleted')}`);
      })
      .catch(() => {
        createErrorNotification(`${translate('haulerProfile.alertMessages.errorContactDelete')}`);
      });
  };

  return (
    <TableRow isHighlighted={isEditMode || isAddMode}>
      <TableCell width="30%">
        <Container>
          <Field
            name={`${fieldArrayKey}.phoneNumber`}
            component={InlineEditor}
            isInEditMode={isEditMode || isAddMode}
            inputProps={{ placeholder: translate('autoDispatch.phoneNumber'), margin: 'no', disabled: isRowDisabled }}
          />
        </Container>
      </TableCell>

      <TableCell width="30%">
        <Container>
          <Field
            name={`${fieldArrayKey}.firstName`}
            component={InlineEditor}
            isInEditMode={isEditMode || isAddMode}
            inputProps={{ placeholder: translate('common.name'), margin: 'no', disabled: isRowDisabled }}
          />
        </Container>
      </TableCell>

      <TableCell width="30%" align="right">
        {isEditMode && !isAddMode && (
          <Fragment>
            <TableActionButton onClick={updatePhoneNumber} disabled={isRowDisabled}>
              <ActionButtonTooltip icon="check" tooltip="save" color="success" />
            </TableActionButton>
            <TableActionButton onClick={stopEditing} disabled={isRowDisabled}>
              <ActionButtonTooltip icon="close" tooltip="cancel" color="alert" />
            </TableActionButton>
          </Fragment>
        )}
        {!isEditMode && !isAddMode && (
          <Fragment>
            <TableActionButton onClick={startEditing} disabled={isRowDisabled}>
              <ActionButtonTooltip icon="edit" tooltip="edit" />
            </TableActionButton>
            <TableActionButton onClick={deletePhoneNumber} disabled={isRowDisabled}>
              <ActionButtonTooltip icon="delete" tooltip="delete" />
            </TableActionButton>
          </Fragment>
        )}
        {isAddMode && (
          <TableActionButton onClick={savePhoneNumber} disabled={isRowDisabled}>
            {translate('common.add')}
          </TableActionButton>
        )}
      </TableCell>
    </TableRow>
  );
}

const mapStateToProps = (state: AppState) => ({
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  userName: state.account.login.user ? state.account.login.user.email : 'PortalUser',
});

const mapDispatchToProps = {
  saveContact,
  updateContact,
  deleteContact,
  change,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhoneNumbersFormTableRow));
