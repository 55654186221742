import { push } from 'connected-react-router';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import DocumentTitle from 'src/common/components/DocumentTitle';
import translate from 'src/core/services/translate';
import { loadOpportunities, loadMarketSubTypes } from 'src/opportunity/ducks';
import { PageLoading } from '../../../common/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks/dispatchOpportunitiesMenuCount';
import { loadHolidays } from '../../../common/ducks/holidays';
import { TypedResolver } from '../../../core/components';
import { YES } from '../../../fleet/constants/status';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import OpportunitiesPage from './OpportunitiesPage';
import { loadRubiconServicesOptions } from 'src/customers/ducks/rubiconServicesOptions';
interface RouteParams {
  token: string;
}

type Props = RouteComponentProps<RouteParams>;

export const OpportunitiesPageResolver: React.FC<Props> = ({
  location: { pathname, search },
  match: {
    params: { token },
  },
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);
  const gusId = useSelector((state: AppState) =>
    currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  );

  const loadDependencies = async () => {
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES;

    let initialStartDate: string = '';
    let initialEndDate: string = '';

    if (!startDate && !endDate) {
      initialStartDate =
        showActionRequired === YES
          ? moment().subtract(60, 'days').format('MM/DD/YYYY')
          : moment().subtract(10, 'days').format('MM/DD/YYYY');
      initialEndDate =
        showActionRequired === YES
          ? moment().add(30, 'days').format('MM/DD/YYYY')
          : moment().add(45, 'days').format('MM/DD/YYYY');
      push(createUrl(pathname, search, { startDate: initialStartDate, endDate: initialEndDate, showActionRequired }));
    } else {
      initialStartDate = startDate;
      initialEndDate = endDate;
    }

    return Promise.all([
      loadHolidays()(dispatch),
      loadRubiconServicesOptions(gusId)(dispatch),
      loadMarketSubTypes()(dispatch),
      loadOpportunities(userId, vendorId, isActionRequired, initialStartDate, initialEndDate)(dispatch),
      loadDispatchOpportunitiesCount(userId, vendorId, token)(dispatch),
    ]);
  };

  return (
    <>
      <DocumentTitle>{translate('opportunity.opportunities.opportunities')}</DocumentTitle>
      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        successComponent={OpportunitiesPage}
      />
    </>
  );
};
