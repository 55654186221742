import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

export const BILLING_CYCLE_MONTHLY_ID = 1;
export const BILLING_CYCLE_WEEKLY_ID = 2;
export const BILLING_CYCLE_ANNUAL_ID = 3;
export const BILLING_CYCLE_BI_MONTHLY_1_15_ID = 4;
export const BILLING_CYCLE_BI_MONTHLY_5_20_ID = 5;
export const BILLING_CYCLE_BI_MONTHLY_10_25_ID = 6;
export const BILLING_CYCLE_QUATERLY_ID = 7;

export const BILLING_CYCLE_TYPES = mapKeys(
  [
    {
      id: BILLING_CYCLE_MONTHLY_ID,
      name: translate(createTranslationKey('Monthly', 'billing.billCycles')),
      technicalName: 'Monthly',
    },
    {
      id: BILLING_CYCLE_WEEKLY_ID,
      name: translate(createTranslationKey('Weekly', 'billing.billCycles')),
      technicalName: 'Weekly',
    },
    {
      id: BILLING_CYCLE_ANNUAL_ID,
      name: translate(createTranslationKey('Annual', 'billing.billCycles')),
      technicalName: 'Annual',
    },
    {
      id: BILLING_CYCLE_BI_MONTHLY_1_15_ID,
      name: translate(createTranslationKey('Monthly115', 'billing.billCycles')),
      technicalName: 'Monthly115',
    },
    {
      id: BILLING_CYCLE_BI_MONTHLY_5_20_ID,
      name: translate(createTranslationKey('Monthly520', 'billing.billCycles')),
      technicalName: 'Monthly520',
    },
    {
      id: BILLING_CYCLE_BI_MONTHLY_10_25_ID,
      name: translate(createTranslationKey('Monthly1025', 'billing.billCycles')),
      technicalName: 'Monthly1025',
    },
    {
      id: BILLING_CYCLE_QUATERLY_ID,
      name: translate(createTranslationKey('Quarterly', 'billing.billCycles')),
      technicalName: 'Quarterly',
    },
  ],
  'id',
);
