import { PopoverWrapper } from 'src/core/components';
import { CustomerLocationDropdownOption, CustomerLocationDropdownOptionExclamation } from '../../styled';
import { CustomerLocationPopover } from './CustomerLocationPopover';
import { Text } from 'src/core/components/styled';

interface CustomerLocationOption {
  label: string;
  isOnHold: boolean;
}

export const renderCustomerLocationOptionLabel = (option: CustomerLocationOption) => {
  const containerNumbers = option.label.substring(option.label.indexOf('(') + 1, option.label.lastIndexOf(')'));
  return (
    <CustomerLocationDropdownOption>
      {!!containerNumbers && containerNumbers.toString().split(',').length > 1 ? (
        <>
          {option.label?.split(',')[0]}
          <PopoverWrapper
            key={option.label}
            triggerButton={<Text margin="no">, ...{` )`}</Text>}
            popoverContent={<CustomerLocationPopover binNumbers={containerNumbers.toString().split(',').slice(1)} />}
          />
        </>
      ) : (
        option.label
      )}

      {!!option.isOnHold && <CustomerLocationDropdownOptionExclamation />}
    </CustomerLocationDropdownOption>
  );
};
