import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import translate from '../../core/services/translate';

export const uploadFile = (fileData: File, vendorId: number) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http.post('/accountStatusImport/upload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadSampleCustomerAccountStatus = () =>
  downloadExcelFile('/accountStatusImport/downloadTemplate', translate('customers.accountStatusUpload.sampleTemplate'));
