import { UnconnectedCheckbox } from '../../../core/components';
import { TableCell, TableRow, Text } from '../../../core/components/styled';

interface Props {
  containerNumber: string;
  id: number;
  isChecked: boolean;
  onCheckboxChange: (isSelected: boolean, id: number) => void;
}

function SelectContainersTableRow({ containerNumber, isChecked, id, onCheckboxChange }: Props) {
  const onChange = (isSelected: boolean, id: number) => {
    onCheckboxChange(!isSelected, id);
  };

  return (
    <TableRow>
      <TableCell width="20%" minHeight="40px">
        <UnconnectedCheckbox checked={isChecked} onChange={() => onChange(isChecked, id)} />
      </TableCell>
      <TableCell width="80%" minHeight="40px">
        <Text>{containerNumber}</Text>
      </TableCell>
    </TableRow>
  );
}

export default SelectContainersTableRow;
