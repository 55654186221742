import { useDispatch, useSelector } from 'react-redux';

import { find, forEach, map, size } from 'lodash-es';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { saveVehicleStrobeImagesSettings } from 'src/vendors/ducks';
import { VehicleStrobeImagesSettingsItem } from 'src/vendors/interfaces/VehicleStrobeImagesSettings';
import { Modal } from '../../../core/components';
import { ModalSection, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import { ModalProps } from '../../interfaces/ModalProps';
import { currentVendorId } from '../../services/currentVendorSelector';
import VehicleStrobeImagesSettingsForm from '../forms/VehicleStrobeImagesSettingsForm';

interface Props extends ModalProps {
  closeModal: (pristine?: boolean) => void;
}

export default function VehicleStrobeImagesModal({ closeModal, featureCode }: Props) {
  const dispatch = useDispatch();

  const { vehicleStrobeImagesSettings, isLoading, isSaving } = useSelector(
    (state: AppState) => state.vendors.vehicleStrobeImagesSettings,
  );
  const { vehicleStrobeImageFrequencyTypes } = useSelector(
    (state: AppState) => state.vendors.vehicleStrobeImageFrequencyTypes,
  );
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const saveStrobeImagesSettings = (values: any) => {
    if (!vendorId) {
      return;
    }
    const settingsToSave = map(
      vehicleStrobeImagesSettings,
      (vehicleStrobeImagesSetting: VehicleStrobeImagesSettingsItem) => {
        const vehicleStrobeImageFrequency = find(
          vehicleStrobeImageFrequencyTypes,
          (vehicleStrobeImageFrequencyType: any) =>
            vehicleStrobeImageFrequencyType.id === values[vehicleStrobeImagesSetting.vehicleTypeId],
        );
        return {
          ...vehicleStrobeImagesSetting,
          id: vehicleStrobeImagesSetting.vehicleTypeId,
          vehicleStrobeImageFrequency: {
            ...vehicleStrobeImageFrequency,
          },
        };
      },
    );

    saveVehicleStrobeImagesSettings(
      vendorId,
      settingsToSave,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.vehicleStrobeImagesSettingsSaved'));
        closeModal(true);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.alertMessages.vehicleStrobeImagesSettingsError'));
      });
  };

  const getStrobeSettingsInitialValues = () => {
    let intitialValues: any = {};
    forEach(vehicleStrobeImagesSettings, (setting: VehicleStrobeImagesSettingsItem) => {
      intitialValues[setting.vehicleTypeId] = setting.vehicleStrobeImageFrequency.id;
    });

    return intitialValues;
  };

  return (
    <Modal
      size="smallMedium"
      title={translate(createTranslationKey('vehicleStrobeImages', 'vendors.featureCodes'))}
      onClose={closeModal}
      isLoading={isLoading || isSaving}
    >
      <ModalSection padding="xxSmall" align="center">
        {size(vehicleStrobeImagesSettings) ? (
          <VehicleStrobeImagesSettingsForm
            initialValues={getStrobeSettingsInitialValues()}
            onSubmit={saveStrobeImagesSettings}
          />
        ) : (
          <Text>{translate('vendors.noStrobeImagesSettingsToConfigure')}</Text>
        )}
      </ModalSection>
    </Modal>
  );
}
