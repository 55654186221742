import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const ACTIVE = true;
export const INACTIVE = false;

export const FACILITY_STATUS = mapKeys(
  [
    { id: ACTIVE, name: translate('common.active') },
    { id: INACTIVE, name: translate('common.inactive') },
  ],
  'id',
);

export const FACILITY_SOURCE = 'FACILITY_SOURCE';
export const FACILITIES_LAYER = 'FACILITIES_LAYER';
export const FACILITY_CLUSTER_LAYER = 'FACILITY_CLUSTER_LAYER';
export const FACILITY_CLUSTER_COUNTERS_LAYER = 'FACILITY_CLUSTER_COUNTERS_LAYER';
export const FACILITY_GEO_FENCE_SOURCE = 'FACILITY_GEO_FENCE_SOURCE';
