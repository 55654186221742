import { MapContainer } from '../styled/TriggerMap';
import { Modal } from './../../../core/components';
import translate from './../../../core/services/translate';
import TriggerMapGL from './triggerMap/TriggerMapGL';

type Props = {
  closeModal: () => void;
};

export default function TriggerMapModal({ closeModal }: Props) {
  return (
    <Modal title={translate('routes.triggerMap')} size="large" verticalSize="medium" onClose={closeModal}>
      <MapContainer>
        <TriggerMapGL />
      </MapContainer>
    </Modal>
  );
}
