import { filter, find, get, includes, map, size, toLower } from 'lodash-es';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Cookie from 'js-cookie';
import moment from 'moment';

import {
  bulkDeleteRoutes,
  bulkDeleteRouteTemplates,
  loadRoutePlannerCalendarData,
  loadRoutePlannerTableData,
  resetRoutePlannerTableData,
} from 'src/routes/ducks/routePlanner';
import { Button, PanelSection, PanelSectionGroup, PanelSectionHeader, Text } from 'src/core/components/styled';
import {
  CANCELED,
  COMPLETE,
  PENDING,
  FAILED,
  FAILED_CONFIRMED,
  SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
  SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
} from 'src/routes/constants';
import { checkIfSupport, checkIfViewOnly, hasPermission } from 'src/account/utils/permissions';
import { CloneRouteData, RoutesResourceAvailability } from 'src/routes/interfaces/Route';
import {
  cloneRouteTracker,
  createRouteFromRouteTemplate,
  deleteRouteTemplate,
  deleteRouteTracker,
  loadRouteSequenceStatusCombined,
  updateRouteSequenceStatus,
  loadRoutesResourceAvailability,
  assignRoutesToGroups,
  assignTemplatesToGroups,
} from 'src/routes/ducks';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { CreateRouteFromTemplateForm, RouteCloneForm, RoutesOrTemplatesSearchForm } from 'src/routes/components/forms';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import {
  getIsDriverUnavailable,
  getIsVehicleUnavailable,
  getRoutesResourceAvailabilityTranslation,
} from 'src/routes/utils/routeDetails';
import { getRoutePlannerTableCells } from 'src/routes/utils/routePlanner';
import { NoDataMessageContainer } from 'src/routes/components/styled';
import { PageFooter } from 'src/common/components/styled';
import { RoutePlannerTableData } from 'src/routes/interfaces/routePlanner/RoutePlannerTableData';
import { ROUTES_PLANNER_SCHEDULE, ROUTES_TRACKER_SEQUENCE_ROUTE } from 'src/account/constants';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';
import { RouteTemplate } from 'src/routes/interfaces/RouteTemplates';
import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { TABLE_ROW_HEIGHT_SMALL, TOP, WEEKDAYS_BY_ID } from 'src/core/constants';
import { Icon, Table, UnconnectedSwitch } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import createPopover from 'src/core/services/createPopover';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import informationPrompt from 'src/core/services/informationPrompt';
import RoutePlannerRouteTableRow from './RoutePlannerRouteTableRow';
import translate from 'src/core/services/translate';
import { AddToGroupsModalResolver } from 'src/routes/components/modals';
import { AddToGroupsFormValues } from 'src/routes/components/forms/AddToGroupsForm';
import MoreButton, { MoreButtonItem } from 'src/core/components/MoreButton';

interface Props {
  date?: Date;
  calendarDate: Date;
  vehicleTypeIds?: number[];
  groupIds?: number[];
  openScheduler: (templates: { id: number; scheduledDay: number }[]) => void;
  openAddJobModal: (routeId?: number, vehicleTypeId?: number, date?: string) => void;
}

const RoutePlannerRoutes = ({
  date,
  calendarDate,
  vehicleTypeIds,
  groupIds,
  openScheduler,
  openAddJobModal,
}: Props) => {
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const hasRouteSequencePermission = hasPermission(ROUTES_TRACKER_SEQUENCE_ROUTE);
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const { tableData, isLoading: isLoadingTableData } = useSelector(
    state => state.routes.routePlanner.routePlannerTableData,
  );
  const { routeStatuses, isLoading: isLoadingRouteSequenceStatus } = useSelector(state => state.routes.routeSequence);
  const { isDeleting: isDeletingRoutes } = useSelector(state => state.routes.routePlanner.routePlannerRoutes);
  const { isDeleting: isDeletingRouteTemplates } = useSelector(
    state => state.routes.routePlanner.routePlannerRouteTemplates,
  );

  const [selectedRoutes, setSelectedRoutes] = useState<number[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<number[]>([]);
  const [activeRoutesOnly, setActiveRoutesOnly] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isAddToGroupsModalOpen, setIsAddToGroupsModalOpen] = useState<boolean>(false);
  const [isAddingRoutesToGroups, setIsAddingRoutesToGroups] = useState<boolean>(false);
  const [isAddingTemplatesToGroups, setIsAddingTemplatesToGroups] = useState<boolean>(false);

  let closePopover: () => void;

  // refresh the route sequence statuses
  useEffect(() => {
    const interval = setInterval(() => {
      if (tableData.length) {
        const templateIds = map(
          filter(tableData, r => !!r.routeTemplateId),
          (r: RoutePlannerTableData) => r.routeTemplateId as number,
        );
        const routeIds = map(
          filter(tableData, r => !!r.routeId),
          (r: RoutePlannerTableData) => r.routeId as number,
        );
        if (
          getIsVendorNotChanged(vendorId) &&
          Cookie.get(SESSION_COOKIE_KEY) &&
          (routeIds.length || templateIds.length)
        ) {
          loadRouteSequenceStatusCombined(routeIds, templateIds)(dispatch);
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, tableData, vendorId]);

  // load the route planner table data when date changes
  useEffect(() => {
    if (date) {
      loadRoutePlannerTableData({
        vendorId,
        date: moment(date).format('MM-DD-YYYY'),
        vehicleTypeIdsCSV: vehicleTypeIds?.join(','),
        showActiveOnly: activeRoutesOnly,
        groupIdsCSV: groupIds?.join(','),
      })(dispatch).then((rows: RoutePlannerTableData[]) => {
        // getting the sequence statuses
        const templateIds = map(
          filter(rows, r => !!r.routeTemplateId),
          (r: RoutePlannerTableData) => r.routeTemplateId as number,
        );
        const routeIds = map(
          filter(rows, r => !!r.routeId),
          (r: RoutePlannerTableData) => r.routeId as number,
        );
        if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
          loadRouteSequenceStatusCombined(routeIds, templateIds)(dispatch);
        }
      });

      setSelectedRoutes([]);
      setSelectedTemplates([]);
    } else {
      dispatch(resetRoutePlannerTableData());
    }
  }, [date, dispatch, vendorId, activeRoutesOnly, vehicleTypeIds, groupIds]);

  const filteredRoutes = useMemo(
    () =>
      map(
        filter(tableData, td => includes(toLower(td.name), toLower(searchTerm))),
        route => {
          const routeSequence = find(routeStatuses, r => r.routeId === (route.routeId || route.routeTemplateId));
          return {
            ...route,
            routeSequenceStatus: get(routeSequence, 'status'),
            jobId: get(routeSequence, 'jobId'),
            isChecked:
              (route.routeId && selectedRoutes.includes(route.routeId)) ||
              (route.routeTemplateId && selectedTemplates.includes(route.routeTemplateId)),
          };
        },
      ),
    [tableData, searchTerm, routeStatuses, selectedRoutes, selectedTemplates],
  );

  const toggleSelectAll = () => {
    if (selectedRoutes.length + selectedTemplates.length === size(filteredRoutes)) {
      setSelectedRoutes([]);
      setSelectedTemplates([]);
    } else {
      setSelectedRoutes(
        map(
          filter(filteredRoutes, route => route.routeId),
          'routeId',
        ),
      );
      setSelectedTemplates(
        map(
          filter(filteredRoutes, route => route.routeTemplateId && !route.routeId),
          'routeTemplateId',
        ),
      );
    }
  };

  const selectRoute = (routeId?: number, templateId?: number) => {
    if (routeId) {
      if (selectedRoutes.includes(routeId)) {
        setSelectedRoutes(filter(selectedRoutes, id => id !== routeId));
      } else {
        setSelectedRoutes([...selectedRoutes, routeId]);
      }
    } else if (templateId) {
      if (selectedTemplates.includes(templateId)) {
        setSelectedTemplates(filter(selectedTemplates, id => id !== templateId));
      } else {
        setSelectedTemplates([...selectedTemplates, templateId]);
      }
    }
  };

  const refreshData = () => {
    setSelectedRoutes([]);
    setSelectedTemplates([]);
    if (date) {
      loadRoutePlannerTableData({
        vendorId,
        date: moment(date).format('MM-DD-YYYY'),
        vehicleTypeIdsCSV: vehicleTypeIds?.join(','),
      })(dispatch);
    }
    const beginDate = moment(calendarDate).format('MM-DD-YYYY');
    const endDate = moment(calendarDate).add(6, 'days').format('MM-DD-YYYY');
    loadRoutePlannerCalendarData({ vendorId, beginDate, endDate })(dispatch);
  };

  const onDeleteRoutes = async () => {
    if (await confirm(translate('routes.planner.deleteRoutesConfirmation', { count: selectedRoutes.length }))) {
      bulkDeleteRoutes(
        vendorId,
        selectedRoutes,
      )(dispatch)
        .then(count => {
          createSuccessNotification(
            translate('routes.planner.deleteRoutesSuccess', { count, total: selectedRoutes.length }),
          );
          refreshData();
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteRoutesError'));
        });
    }
    return;
  };

  const onDeleteRouteTemplates = async () => {
    if (await confirm(translate('routes.planner.deleteReoccurringConfirmation', { count: selectedTemplates.length }))) {
      bulkDeleteRouteTemplates(
        vendorId,
        selectedTemplates,
      )(dispatch)
        .then(count => {
          createSuccessNotification(
            translate('routes.planner.deleteReoccurringSuccess', { count, total: selectedTemplates.length }),
          );
          refreshData();
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteReoccurringError'));
        });
    }
    return;
  };

  const onTableRowClick = async (
    isDaily: boolean,
    id: number,
    routeSequenceStatus: string,
    jobId: number,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => {
    const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
    const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;

    const getRedirectUrl = () => {
      if (isSnowPlowRoute)
        return `/routes/${isDaily ? 'route-tracker' : 'route-templates'}/snow-plow/${id}${
          isEdit ? (isDaily ? '' : '/edit') : ''
        }`;
      if (isStreetSweeperRoute) return `/routes/${isDaily ? 'route-tracker' : 'route-templates'}/street-sweeper/${id}`;
      return `/routes/${isDaily ? 'route-tracker' : 'route-templates'}/${id}/${isEdit ? (isDaily ? '' : 'edit') : ''}`;
    };

    if (routeSequencingEnabled && hasRouteSequencePermission) {
      switch (routeSequenceStatus) {
        case PENDING: {
          if (!(await confirm(translate('routes.alertMessages.confirmCancelSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: CANCELED,
            sequenceSourceTypeId: isDaily ? SEQUENCE_SOURCE_TYPE_DAILY_ROUTE : SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
          })(dispatch).then(() => dispatch(push(getRedirectUrl())));
          break;
        }
        case COMPLETE:
          dispatch(push(`/routes/${isDaily ? 'route-tracker' : 'route-templates'}/${id}/route-sequence`));
          break;
        case FAILED:
          if (!(await informationPrompt(translate('routes.alertMessages.confirmFailedRouteSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: id,
            jobId,
            status: FAILED_CONFIRMED,
            sequenceSourceTypeId: isDaily ? SEQUENCE_SOURCE_TYPE_DAILY_ROUTE : SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
          })(dispatch).then(() => dispatch(push(getRedirectUrl())));
          break;
        default:
          dispatch(push(getRedirectUrl()));
      }
    } else dispatch(push(getRedirectUrl()));
  };

  const deleteRoute = async (isDaily: boolean, id: number) => {
    if (isDaily) {
      if (!(await confirm(translate('routes.planner.deleteRoutesConfirmationSingle')))) {
        return;
      }
      deleteRouteTracker(id)(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.planner.deleteRoutesSuccessSingle'));
          refreshData();
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteRoutesError'));
        });
    } else {
      if (!(await confirm(translate('routes.planner.deleteReoccurringConfirmationSingle')))) {
        return;
      }
      deleteRouteTemplate(id)(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.planner.deleteReoccurringSuccessSingle'));
          refreshData();
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteReoccurringError'));
        });
    }
  };

  const onCloneDailyRoute = async (routeId: number, data: CloneRouteData) => {
    loadRoutesResourceAvailability([{ routeId, date: data.routeCloneDate }])(dispatch).then(
      async (routesResourceAvailability: RoutesResourceAvailability[]) => {
        const isDriverUnavailable = getIsDriverUnavailable(routesResourceAvailability);
        const isVehicleUnavailable = getIsVehicleUnavailable(routesResourceAvailability);

        if (isDriverUnavailable || isVehicleUnavailable) {
          if (!(await confirm(getRoutesResourceAvailabilityTranslation(isDriverUnavailable, isVehicleUnavailable)))) {
            return;
          }
        }

        cloneRouteTracker(
          routeId,
          data,
        )(dispatch)
          .then(() => {
            !!closePopover && closePopover();
            createSuccessNotification(translate('routes.alertMessages.routeCloned'));
            refreshData();
          })
          .catch(({ code }) => {
            createErrorNotification(
              `${translate(createTranslationKey(code, 'routes.alertMessages'), { routeDate: data.routeCloneDate })}`,
            );
          });
      },
    );
  };

  const onCloneRouteTemplate = async (id: number, scheduledDay: number, { date }: any) => {
    const dayOfWeek = moment(date, 'MM/DD/YYYY').day();
    const dayOfWeekId = dayOfWeek !== 0 ? dayOfWeek : 7;

    const { id: scheduledDayId, name: scheduledDayName } = WEEKDAYS_BY_ID[dayOfWeekId];
    const { id: originalScheduledDayId, name: originalScheduledDayName } = WEEKDAYS_BY_ID[scheduledDay];

    if (scheduledDayId !== originalScheduledDayId) {
      if (
        !(await confirm(
          translate('routes.alertMessages.confirmCreateRouteForAnotherDayOfWeek', {
            scheduledDayName,
            originalScheduledDayName,
          }),
        ))
      )
        return;
    }
    createRouteFromRouteTemplate(
      vendorId,
      id,
      date,
    )(dispatch)
      .then(({ id: routeId }) => {
        !!closePopover && closePopover();
        createSuccessNotification(translate('routes.alertMessages.routeCreatedFromTemplate'));
        dispatch(push(`/routes/route-tracker/${routeId}`));
      })
      .catch(() => createErrorNotification(translate('routes.alertMessages.routeCreationFromTemplateFailed')));
  };

  const cloneRoute = async (
    isDaily: boolean,
    id: number,
    scheduledDay: number,
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    if (isDaily) {
      closePopover = createPopover(
        event.currentTarget,
        RouteCloneForm,
        { onSubmit: (data: CloneRouteData) => onCloneDailyRoute(id, data) },
        { position: TOP, zIndex: 6000 },
      );
    } else {
      closePopover = createPopover(
        event.currentTarget,
        CreateRouteFromTemplateForm,
        { onSubmit: (data: RouteTemplate) => onCloneRouteTemplate(id, scheduledDay, data) },
        { position: TOP },
      );
    }
  };

  const handleScheduleClick = (templateId?: number) => {
    let templatesToSchedule: { id: number; scheduledDay: number }[] = [];
    if (templateId) {
      const template = find(tableData, { routeTemplateId: templateId });
      if (template && template.scheduledDay && template.isActive)
        templatesToSchedule = [{ id: templateId, scheduledDay: template.scheduledDay }];
    } else {
      templatesToSchedule = filter(
        map(selectedTemplates, id => {
          const template = find(tableData, { routeTemplateId: id });
          if (template && template.scheduledDay && template.isActive)
            return { id, scheduledDay: template.scheduledDay };
          return null;
        }),
        d => d !== null,
      ) as { id: number; scheduledDay: number }[];
    }

    openScheduler(templatesToSchedule);
  };

  const routesTableCells = getRoutePlannerTableCells(
    selectedRoutes.length + selectedTemplates.length === size(tableData),
    !!(selectedRoutes.length + selectedTemplates.length) &&
      selectedRoutes.length + selectedTemplates.length < size(tableData),
    toggleSelectAll,
  );

  const templatesToScheduleSize = size(
    filter(
      tableData,
      (td: RoutePlannerTableData) =>
        td.routeTemplateId && selectedTemplates.includes(td.routeTemplateId) && td.isActive && !!td.numberOfStops,
    ),
  );

  const toggleAddToGroupsModal = () => {
    setIsAddToGroupsModalOpen(false);
    setIsAddingRoutesToGroups(false);
    setIsAddingTemplatesToGroups(false);
    setSelectedRoutes([]);
    setSelectedTemplates([]);
  };

  const onAddToGroups = ({ groupIds }: AddToGroupsFormValues) => {
    if (groupIds.length === 0) return;
    if (isAddingRoutesToGroups) {
      assignRoutesToGroups(
        selectedRoutes,
        groupIds,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.alertMessages.routesAddedToGroup'));
        })
        .catch(() => {
          createErrorNotification(translate('routes.alertMessages.routesAddedToGroupError'));
        })
        .finally(() => {
          toggleAddToGroupsModal();
        });
    } else if (isAddingTemplatesToGroups) {
      assignTemplatesToGroups(
        selectedTemplates,
        groupIds,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.alertMessages.routeTemplatesAddedToGroup'));
        })
        .catch(() => {
          createErrorNotification(translate('routes.alertMessages.routeTemplatesAddedToGroupError'));
        })
        .finally(() => {
          toggleAddToGroupsModal();
        });
    }
  };

  const deleteMoreButtonItems: MoreButtonItem[] = [
    {
      handler: () => onDeleteRoutes(),
      id: 'delete-route-button',
      text: `${translate('routes.planner.deleteDailyRoute')} (${size(selectedRoutes)})`,
      disabled: !size(selectedRoutes),
      startAdornment: <Icon icon="trash" width="20px" height="20px" customViewBox="0 0 25 25" />,
    },
    {
      handler: () => onDeleteRouteTemplates(),
      id: 'delete-planner-button',
      text: `${translate('routes.planner.deleteReoccurringRoute')} (${size(selectedTemplates)})`,
      disabled: !size(selectedTemplates),
      startAdornment: <Icon icon="trash" width="20px" height="20px" customViewBox="0 0 25 25" />,
    },
  ];

  const addToGroupsMoreButtonItems: MoreButtonItem[] = [
    {
      handler: () => {
        setIsAddingRoutesToGroups(true);
        setIsAddToGroupsModalOpen(true);
      },
      id: 'add-routes-groups-button',
      text: `${translate('routes.groups.addDailyRoutesToGroups')} (${size(selectedRoutes)})`,
      disabled: !size(selectedRoutes),
      startAdornment: <Icon icon="groups" width="20px" height="20px" customViewBox="0 0 30 30" />,
    },
    {
      handler: () => {
        setIsAddingTemplatesToGroups(true);
        setIsAddToGroupsModalOpen(true);
      },
      id: 'add-templates-groups-button',
      text: `${translate('routes.groups.addReoccurringToGroups')} (${size(selectedTemplates)})`,
      disabled: !templatesToScheduleSize,
      startAdornment: <Icon icon="groups" width="20px" height="20px" customViewBox="0 0 25 25" />,
    },
  ];

  return (
    <PanelSectionGroup>
      <PanelSectionHeader>
        <Text size="large" weight="medium">
          {date ? moment(date).format('dddd MM/DD') : translate('routes.planner.dayPreview')}
        </Text>
        <UnconnectedSwitch
          checked={activeRoutesOnly}
          onChange={() => setActiveRoutesOnly(!activeRoutesOnly)}
          label={translate('routes.planner.activeRoutesOnly')}
        />
      </PanelSectionHeader>
      {!!size(tableData) && !isLoadingTableData && (
        <PanelSection padding="xSmall no">
          <RoutesOrTemplatesSearchForm onSearchTermChange={setSearchTerm} />
        </PanelSection>
      )}
      <PanelSection
        isLoading={isLoadingTableData || isLoadingRouteSequenceStatus || isDeletingRouteTemplates || isDeletingRoutes}
        minHeight={550}
      >
        {size(filteredRoutes) ? (
          <Table
            cells={routesTableCells}
            rows={filteredRoutes}
            rowComponent={RoutePlannerRouteTableRow}
            scrollMarker
            virtualized
            virtualizedProps={{
              height: Math.min(size(filteredRoutes) * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
              itemSize: TABLE_ROW_HEIGHT_SMALL,
            }}
            tableHeadProps={{
              padding: '0 0 0 0',
            }}
            rowProps={{
              selectRoute,
              onTableRowClick,
              deleteRoute,
              cloneRoute,
              handleScheduleClick,
              openAddJobModal,
            }}
            tableHeadRowProps={{
              padding: '0 20px 0 0',
            }}
          />
        ) : (
          <NoDataMessageContainer>
            <Text>
              {!!size(tableData) ? translate('routes.planner.noDataMatches') : translate('routes.planner.noData')}
            </Text>
          </NoDataMessageContainer>
        )}
      </PanelSection>
      {!checkIfViewOnly() && !checkIfSupport() && (!!size(selectedRoutes) || !!size(selectedTemplates)) && (
        <PanelSection centered padding="no">
          <PageFooter>
            {hasPermission(ROUTES_PLANNER_SCHEDULE) && (
              <Button
                id="schedule-route-button"
                color="primary"
                onClick={() => handleScheduleClick()}
                margin="no small no no"
                disabled={!templatesToScheduleSize}
              >
                {`${translate('routes.schedule')} (${templatesToScheduleSize})`}
              </Button>
            )}

            <MoreButton
              margin="no small no no"
              menuTop
              items={deleteMoreButtonItems}
              color="alert"
              buttonText={translate('common.delete')}
            />

            <MoreButton
              margin="no small no no"
              menuTop
              items={addToGroupsMoreButtonItems}
              buttonText={translate('routes.groups.addToGroups')}
            />
          </PageFooter>
        </PanelSection>
      )}
      {isAddToGroupsModalOpen && (
        <AddToGroupsModalResolver onAddToGroups={onAddToGroups} closeModal={toggleAddToGroupsModal} />
      )}
    </PanelSectionGroup>
  );
};

export default RoutePlannerRoutes;
