import { combineReducers } from 'redux';

import { reducer as recurringServicesReducer } from './recurringServices';
import { reducer as onCallServicesReducer } from './onCallServices';
import { reducer as processedServicesReducer } from './processedServices';

export {
  loadRecurringServices,
  //   loadRecurringServicesByVendorId,
  updateRecurringServices,
  //   updateRecurringServicesByVendorId,
  loadRecurringServicesIssueType,
  updateIssueReportRecurringServices,
  updateUndoAcceptRecurringServices,
  updateUndoReportRecurringServices,
  updateAcceptSelectedRecurringPaymentServices,
  COMPLETE_LOAD as COMPLETE_LOAD_OPPORTUNITY,
  vendorPaymentMethodTypes,
  resetRecurringService,
} from './recurringServices';

export {
  loadOnCallServices,
  //   loadOnCallServicesByVendorId,
  updateOnCallServices,
  //   updateOnCallServicesByVendorId,
  loadOnCallServicesIssueType,
  updateIssueReportOnCallServices,
  updateUndoAcceptOnCallServices,
  updateUndoReportOnCallServices,
  updateAcceptSelectedOnCallPaymentServices,
  resetOnCallService,
} from './onCallServices';

export {
  loadProcessedServices,
  //   loadOnCallServicesByVendorId,
  updateProcessedServices,
  //   updateOnCallServicesByVendorId,
  loadProcessedServicesIssueType,
  updateIssueReportProcessedServices,
  updateUndoAcceptProcessedServices,
  updateUndoReportProcessedServices,
  updateAcceptSelectedProcessedPaymentServices,
  resetProcessedService,
} from './processedServices';

export { reportDateSelector } from './reportDate';

export const reducer = combineReducers({
  recurringServices: recurringServicesReducer,
  onCallServices: onCallServicesReducer,
  processedServices: processedServicesReducer,
});
