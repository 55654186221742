import GoogleAnalyticsGtag, { trackEvent, trackPageView } from '@redux-beacon/google-analytics-gtag';
import { LOCATION_CHANGE } from 'connected-react-router';
import { decamelize, pascalize } from 'humps';
import { concat, get, map, size } from 'lodash-es';
import { createMiddleware } from 'redux-beacon';

import {
  ADD_SUSPENDED_LOCATION,
  COMPLETE_DELETE_CUSTOMER,
  COMPLETE_DELETE_LOCATION,
  COMPLETE_DELETE_SERVICE,
  COMPLETE_LOAD_CUSTOMER,
  COMPLETE_LOAD_CUSTOMERS,
  COMPLETE_LOAD_SUSPENDED_LOCATIONS,
  COMPLETE_SAVE_CUSTOMER,
  COMPLETE_SAVE_LOCATION,
  COMPLETE_SAVE_SERVICE,
  COMPLETE_SAVE_SUSPENDED_LOCATIONS,
  EDIT_SUSPENDED_LOCATION,
  SEARCH_LOCATIONS,
} from '../../customers/ducks';
import {
  COMPLETE_LOAD_MAP_INSIGHTS,
  COMPLETE_LOAD_VEHICLES,
  COMPLETE_LOAD_VEHICLE_POSITIONS,
  COMPLETE_LOAD_VEHICLE_TRACKINGS,
  TOGGLE_FLEET_INSIGHTS,
  TOGGLE_MAP_INSIGHTS,
} from '../../dashboard/ducks';
import {
  COMPLETE_DELETE_CONTAINER,
  COMPLETE_EXPORT_CONTAINER,
  COMPLETE_LOAD_CONTAINER,
  COMPLETE_LOAD_CONTAINERS,
  COMPLETE_LOAD_CONTAINER_HISTORY,
  COMPLETE_LOAD_DRIVER,
  COMPLETE_LOAD_VEHICLE,
  COMPLETE_SAVE_CONTAINER,
  COMPLETE_SAVE_DRIVER,
  COMPLETE_VEHICLE_SAVE,
  FILTER_DRIVERS,
  FILTER_VEHICLES,
  SEARCH_DRIVERS,
  SEARCH_VEHICLES,
  SORT_DRIVERS,
  SORT_VEHICLES,
  VIEW_CONTAINER_ON_MAP,
} from '../../fleet/ducks';
import { COMPLETE_LOAD_OPPORTUNITY } from '../../opportunity/ducks';
import {
  ADD_ROLLOFF_JOB,
  ADD_ROLLOFF_NOTE,
  COMPLETE_CLONE_ROUTE_TRACKER,
  COMPLETE_CREATE_ROUTE,
  COMPLETE_DELETE_ROLLOFF_JOBS,
  COMPLETE_DELETE_ROUTE_TRACKER,
  COMPLETE_DELETE_ROUTE_TEMPLATE,
  COMPLETE_DELETE_SERVICE_ZONES,
  COMPLETE_EXPORT_ROUTE,
  COMPLETE_EXPORT_ROUTES_TRACKER,
  COMPLETE_EXPORT_ROUTE_TEMPLATE,
  COMPLETE_EXPORT_ROUTE_TEMPLATES,
  COMPLETE_LOAD_ROLLOFF_JOBS,
  COMPLETE_LOAD_ROUTE,
  COMPLETE_LOAD_ROUTES_TRACKER,
  COMPLETE_LOAD_ROUTE_LOCATION_IMAGES,
  COMPLETE_LOAD_ROUTE_LOCATION_NOTES,
  COMPLETE_LOAD_ROUTE_TEMPLATE,
  COMPLETE_LOAD_ROUTE_TEMPLATES,
  COMPLETE_LOAD_SERVICE_ZONES,
  COMPLETE_SAVE_ROLLOFF_JOBS,
  COMPLETE_SAVE_ROUTE,
  COMPLETE_SAVE_ROUTE_NOTE,
  COMPLETE_SAVE_ROUTE_TEMPLATE,
  COMPLETE_SAVE_SERVICE_ZONES,
  COMPLETE_SAVE_WEIGHTTICKETS,
  COMPLETE_SCHEDULE_ROUTES,
  COMPLETE_TRANSFER_STOPS,
  COMPLETE_TRANSFER_STOPS_ROUTE_TEMPLATE,
} from '../../routes/ducks';
import { AppState } from '../../store';
import {
  COMPLETE_CHANGE_PASSWORD,
  COMPLETE_DELETE_USER,
  COMPLETE_LOAD_USERS,
  COMPLETE_SAVE_USER,
  COMPLETE_SAVE_VENDOR,
  VIEW_ADVANCED_PROFILE,
} from '../../vendors/ducks';
import { getBaseUrl } from './environment';

// the trackEvent function _needs_ to return an object with these keys
const emptyGATrack = {
  category: 'Discard',
  action: '',
};

interface Action {
  [key: string]: any;
}

const trackEventType = (category: string, action: string, label: string) =>
  trackEvent(() => ({
    category,
    action,
    label,
  }));

const trackDashboardSearchChange = trackEvent((_action: Action, prevState: AppState, nextState: AppState) => {
  const prevStateSearchedVehicleId = get(prevState, 'form.dashboardFilter.values.searchedVehicleId');
  const nextStateSearchedVehicleId = get(nextState, 'form.dashboardFilter.values.searchedVehicleId');

  return !!nextStateSearchedVehicleId && prevStateSearchedVehicleId !== nextStateSearchedVehicleId
    ? {
        category: 'Dashboard',
        action: 'Filter',
        label: 'Search',
      }
    : emptyGATrack;
});

const trackRoutesSearchChange = trackEvent((_action: Action, prevState: AppState, nextState: AppState) => {
  const prevStateSearchTerm = get(prevState, 'form.routes.values.searchTerm');
  const nextStateSearchTerm = get(nextState, 'form.routes.values.searchTerm');

  return !!nextStateSearchTerm && prevStateSearchTerm !== nextStateSearchTerm
    ? {
        category: 'Routes > Tracker',
        action: 'Search',
        label: 'Route List Search',
      }
    : emptyGATrack;
});

const trackRoutesFilterDateChange = trackEvent((_action: Action, prevState: AppState, nextState: AppState) => {
  const prevStateDate = get(prevState, 'form.routes.values.date');
  const nextStateDate = get(nextState, 'form.routes.values.date');

  return !!nextStateDate && prevStateDate !== nextStateDate
    ? {
        category: 'Routes > Tracker',
        action: 'Filter',
        label: 'Date',
      }
    : emptyGATrack;
});

const trackRoutesFilterStatusChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const nextStateRouteStatusTypeIds = get(nextState, 'form.routes.values.routeStatusTypeIds');
  return size(nextStateRouteStatusTypeIds) > 0
    ? {
        category: 'Routes > Tracker',
        action: 'Filter',
        label: 'Route Status',
      }
    : emptyGATrack;
});

const trackRoutesFilterVehiclesTypeChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const nextStateVehicleTypeIds = get(nextState, 'form.routes.values.vehicleTypeIds');

  return size(nextStateVehicleTypeIds) > 0
    ? {
        category: 'Routes > Tracker',
        action: 'Filter',
        label: 'Vehicle Type',
      }
    : emptyGATrack;
});

const trackRouteTemplatesSearchChange = trackEvent((_action: Action, prevState: AppState, nextState: AppState) => {
  const prevStateSearchTerm = get(prevState, 'form.routes.values.routeTemplateName');
  const nextStateSearchTerm = get(nextState, 'form.routes.values.routeTemplateName');

  return !!nextStateSearchTerm && prevStateSearchTerm !== nextStateSearchTerm
    ? {
        category: 'Routes > {Planner}',
        action: 'Search',
        label: 'Route Templates List Search',
      }
    : emptyGATrack;
});

const trackRouteTemplatesFilterDateChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const daysOfServiceIds = get(nextState, 'form.routes.values.daysOfServiceIds');

  return size(daysOfServiceIds) > 0
    ? {
        category: 'Routes > Planner',
        action: 'Filter',
        label: 'Date',
      }
    : emptyGATrack;
});

const trackRouteTemplatesFilterVehiclesTypeChange = trackEvent(
  (_action: Action, _prevState: AppState, nextState: AppState) => {
    const vehicleTypeIds = get(nextState, 'form.routes.values.vehicleTypeIds');
    return size(vehicleTypeIds) > 0
      ? {
          category: 'Routes > Planner',
          action: 'Filter',
          label: 'Vehicle Types',
        }
      : emptyGATrack;
  },
);

const trackRouteTemplatesFilterStatusChange = trackEvent(
  (_action: Action, _prevState: AppState, nextState: AppState) => {
    const routeStatus = get(nextState, 'form.routes.values.routeStatus');

    return !!routeStatus
      ? {
          category: 'Routes > Planner',
          action: 'Filter',
          label: 'Route Template Status',
        }
      : emptyGATrack;
  },
);

const tractrackDispatchBoardFilterDateChange = trackEvent(
  (_action: Action, prevState: AppState, nextState: AppState) => {
    const prevStateDate = get(prevState, 'form.dispatchBoardFilter.values.date');
    const nextStateDate = get(nextState, 'form.dispatchBoardFilter.values.date');

    return !!nextStateDate && prevStateDate !== nextStateDate
      ? {
          category: 'Routes > Dispatch Board',
          action: 'Filter',
          label: 'Date',
        }
      : emptyGATrack;
  },
);

const tractrackDispatchBoardFilterCustomerLocationChange = trackEvent(
  (_action: Action, _prevState: AppState, nextState: AppState) => {
    const customerLocation = get(nextState, 'form.dispatchBoardFilter.values.customerLocation');

    return !!customerLocation
      ? {
          category: 'Routes > Dispatch Board',
          action: 'Filter',
          label: 'CustomerLocation',
        }
      : emptyGATrack;
  },
);

const tractrackDispatchBoardFilterPickupStatusChange = trackEvent(
  (_action: Action, _prevState: AppState, nextState: AppState) => {
    const pickupStatusTypeId = get(nextState, 'form.dispatchBoardFilter.values.pickupTypeId');

    return !!pickupStatusTypeId
      ? {
          category: 'Routes > Dispatch Board',
          action: 'Filter',
          label: 'Pickup Type',
        }
      : emptyGATrack;
  },
);

const tractrackDispatchBoardPickupStatusTypeChange = trackEvent(
  (_action: Action, _prevState: AppState, nextState: AppState) => {
    const pickupStatusTypeIds = get(nextState, 'form.dispatchBoardFilter.values.pickupStatusTypeIds');

    return !!size(pickupStatusTypeIds)
      ? {
          category: 'Routes > DispatchBoard',
          action: 'Filter',
          label: 'Pickup Status Types',
        }
      : emptyGATrack;
  },
);

const tractrackDispatchBoardFilterRouteChange = trackEvent(
  (_action: Action, _prevState: AppState, nextState: AppState) => {
    const routeId = get(nextState, 'form.dispatchBoardFilter.values.routeId');
    return !!routeId
      ? {
          category: 'Routes > DispatchBoard',
          action: 'Filter',
          label: 'Route',
        }
      : emptyGATrack;
  },
);

const trackCustomersSearchTermChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const searchTerm = get(nextState, 'form.customers.values.searchTerm');
  return !!searchTerm
    ? {
        category: 'Customers',
        action: 'Filter',
        label: 'Search',
      }
    : emptyGATrack;
});

const trackCustomersCustomerTypeChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const customerTypeId = get(nextState, 'form.customers.values.customerTypeId');
  return !!customerTypeId
    ? {
        category: 'Customers',
        action: 'Filter',
        label: 'Customer Type',
      }
    : emptyGATrack;
});

const trackContainersSearchTermChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const searchTerm = get(nextState, 'form.containersForm.values.searchTerm');
  return !!searchTerm
    ? {
        category: 'Fleet > Containers',
        action: 'Search',
        label: 'Search Container List',
      }
    : emptyGATrack;
});

const trackContainersCustomerTypeChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const customerTypeId = get(nextState, 'form.containersForm.values.customerTypeId');
  return !!customerTypeId
    ? {
        category: 'Fleet > Containers',
        action: 'Filter',
        label: 'Customer Type',
      }
    : emptyGATrack;
});

const trackContainersEquipmentTypeChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const equipmentTypeId = get(nextState, 'form.containersForm.values.equipmentTypeId');
  return !!equipmentTypeId
    ? {
        category: 'Fleet > Containers',
        action: 'Filter',
        label: 'Equipment Type',
      }
    : emptyGATrack;
});

const trackContainersEquipmentSizeChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const equipmentSizeId = get(nextState, 'form.containersForm.values.equipmentSizeId');
  return !!equipmentSizeId
    ? {
        category: 'Fleet > Containers',
        action: 'Filter',
        label: 'Equipment Size',
      }
    : emptyGATrack;
});

const trackPageHistory = trackPageView(action => ({
  path: action.payload.pathname,
  location: `${getBaseUrl()}${action.payload.pathname}`,
  title: '',
}));

const eventsMapper = (action: Action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return [trackPageHistory];
    case COMPLETE_LOAD_VEHICLES:
      return trackEventType('Dashboard', 'Filter', 'Vehicles');
    case COMPLETE_LOAD_VEHICLE_TRACKINGS:
      return trackEventType('Dashboard', 'Filter', 'Vehicle Tracking');
    case COMPLETE_LOAD_VEHICLE_POSITIONS:
      return [trackEventType('Dashboard', 'Filter', 'Date'), trackDashboardSearchChange];
    case COMPLETE_LOAD_MAP_INSIGHTS: {
      const {
        vehicleInsightTypes,
        containerIssueInsightTypes,
        containerServiceInsightTypes,
        cityInsightTypes,
      } = action;

      const vehicleInsightEvents = map(vehicleInsightTypes, insightType =>
        trackEventType('Dashboard', 'Filter', `Vehicle Insights - ${decamelize(insightType, undefined)}`),
      );
      const containerIssueEvents = map(containerIssueInsightTypes, insightType =>
        trackEventType(
          'Dashboard',
          'Filter',
          `Container Insights - Issue Reported - ${decamelize(insightType, undefined)}`,
        ),
      );
      const containerServiceEvents = map(containerServiceInsightTypes, insightType =>
        trackEventType('Dashboard', 'Filter', `Container Insights  - ${decamelize(insightType, undefined)}`),
      );
      const cityInsightEvents = map(cityInsightTypes, insightType =>
        trackEventType('Dashboard', 'Filter', `City Insights - ${decamelize(insightType, undefined)}`),
      );
      return concat(vehicleInsightEvents, containerIssueEvents, containerServiceEvents, cityInsightEvents);
    }
    case TOGGLE_MAP_INSIGHTS:
      return trackEventType('Dashboard', action.isExpanded ? 'Expand' : 'Collapse', 'Summary');
    case TOGGLE_FLEET_INSIGHTS:
      return trackEventType('Dashboard', action.isExpanded ? 'Expand' : 'Collapse', 'Insights Feed');
    case COMPLETE_EXPORT_ROUTES_TRACKER:
      return trackEventType('Routes > Tracker', 'Export', 'Routes list export');
    case COMPLETE_CLONE_ROUTE_TRACKER:
      return trackEventType('Routes > Tracker', 'Copy', 'Copy Route');
    case COMPLETE_DELETE_ROUTE_TRACKER:
      return trackEventType('Routes > Tracker', 'Delete', 'Delete Route');
    case COMPLETE_LOAD_ROUTES_TRACKER: {
      const trackers = [
        trackRoutesSearchChange,
        trackRoutesFilterVehiclesTypeChange,
        trackRoutesFilterDateChange,
        trackRoutesFilterStatusChange,
      ];
      if (!!size(action.sortedBy))
        trackers.push(trackEventType('Routes > Tracker', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case COMPLETE_LOAD_ROUTE:
      return trackEventType('Routes > Tracker', 'Edit', 'Edit Route');
    case COMPLETE_EXPORT_ROUTE:
      return trackEventType('Routes > Tracker > View', 'Export', 'Route Details Export');
    case COMPLETE_SAVE_ROUTE:
      return trackEventType(
        'Routes > Tracker > Edit',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Route`,
      );
    case COMPLETE_TRANSFER_STOPS:
      return trackEventType('Routes > Tracker > Edit', 'Transfer', 'Transfer Route Stops');
    case COMPLETE_SAVE_WEIGHTTICKETS:
      return trackEventType('Routes > Tracker > View', 'Add', 'Add Ticket Button');
    case COMPLETE_LOAD_ROUTE_LOCATION_NOTES:
      return trackEventType('Routes > Tracker > View', 'Add', 'Route Notes Button');
    case COMPLETE_SAVE_ROUTE_NOTE:
      return trackEventType('Routes > Tracker > View', 'Add', 'Route Location Notes');
    case COMPLETE_LOAD_ROUTE_LOCATION_IMAGES:
      return trackEventType('Routes > Tracker > View', 'View', 'Images - Header');
    case COMPLETE_CREATE_ROUTE:
      return trackEventType('Routes > Planner', 'Create', 'Create Route');
    case COMPLETE_DELETE_ROUTE_TEMPLATE:
      return trackEventType('Routes > Planner', 'Delete', 'Delete Route');
    case COMPLETE_EXPORT_ROUTE_TEMPLATES:
      return trackEventType('Routes > Planner', 'Export', 'Export Route Teplates List');
    case COMPLETE_LOAD_ROUTE_TEMPLATES: {
      const trackers = [
        trackRouteTemplatesSearchChange,
        trackRouteTemplatesFilterDateChange,
        trackRouteTemplatesFilterVehiclesTypeChange,
        trackRouteTemplatesFilterStatusChange,
      ];
      if (!!size(action.sortedBy))
        trackers.push(trackEventType('Routes > Planner', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case COMPLETE_SCHEDULE_ROUTES:
      return trackEventType('Routes > Planner', 'Schedule', 'Schedule Route Teplates');
    case COMPLETE_LOAD_ROUTE_TEMPLATE:
      return trackEventType('Routes > Planner', 'View', 'Route Template');
    case COMPLETE_EXPORT_ROUTE_TEMPLATE:
      return trackEventType('Routes > Planner > View', 'Export', 'Export Route Template');
    case COMPLETE_SAVE_ROUTE_TEMPLATE:
      return trackEventType(
        'Routes > Planner > Edit',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Route Template`,
      );
    case COMPLETE_TRANSFER_STOPS_ROUTE_TEMPLATE:
      return trackEventType('Routes > Planner > Edit', 'Transfer', 'Transfer Route Template Stops');
    case COMPLETE_LOAD_SERVICE_ZONES:
      return trackEventType('Routes > Planner', 'View', 'Service Zones');
    case COMPLETE_DELETE_SERVICE_ZONES:
      return trackEventType('Routes > Planner > Service Zones', 'Delete', 'Delete Service Zone');
    case COMPLETE_SAVE_SERVICE_ZONES:
      return trackEventType(
        'Routes > Planner > Service Zones',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Service Zone`,
      );
    case COMPLETE_LOAD_ROLLOFF_JOBS:
      return [
        tractrackDispatchBoardFilterDateChange,
        tractrackDispatchBoardFilterCustomerLocationChange,
        tractrackDispatchBoardFilterPickupStatusChange,
        tractrackDispatchBoardPickupStatusTypeChange,
        tractrackDispatchBoardFilterRouteChange,
      ];
    case COMPLETE_SAVE_ROLLOFF_JOBS:
      return trackEventType('Routes > Dispatch Board', 'Save', 'Save Dispatch Board Button');
    case COMPLETE_DELETE_ROLLOFF_JOBS:
      return trackEventType('Routes > Dispatch Board', 'Delete', 'Delete Stop Button');
    case ADD_ROLLOFF_NOTE:
      return trackEventType('Routes > Dispatch Board', 'Add', 'Add Note Button');
    case ADD_ROLLOFF_JOB:
      return trackEventType('Routes > Dispatch Board', 'Add', 'Add Job Button');
    case COMPLETE_LOAD_CUSTOMERS: {
      const trackers = [
        trackEventType('Customers', 'Search', 'Customers List'),
        trackCustomersSearchTermChange,
        trackCustomersCustomerTypeChange,
      ];
      if (!!size(action.sortedBy)) trackers.push(trackEventType('Customers', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case COMPLETE_DELETE_CUSTOMER:
      return trackEventType('Customers', 'Delete', 'Delete Customer');
    case COMPLETE_LOAD_CUSTOMER:
      return trackEventType('Customer > Details', 'View', 'View Customer Details Page');
    case COMPLETE_SAVE_CUSTOMER:
      return trackEventType(
        'Customer > Details',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Customer`,
      );
    case COMPLETE_DELETE_LOCATION:
      return trackEventType('Customers > Details', 'Delete', 'Delete Customer Location Button');
    case COMPLETE_SAVE_LOCATION:
      return trackEventType(
        'Customers > Details',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Customer Location Button`,
      );
    case SEARCH_LOCATIONS:
      return trackEventType('Customers > Details', 'Search', 'Search Customer Locations');
    case COMPLETE_DELETE_SERVICE:
      return trackEventType('Customers > Location Details', 'Delete', 'Delete Service Button');
    case COMPLETE_SAVE_SERVICE:
      return trackEventType(
        'Customers > Location Details',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Service Button`,
      );
    case COMPLETE_LOAD_SUSPENDED_LOCATIONS: {
      const trackers = [trackEventType('Customers > Suspended Locations', 'Search', 'Suspended Locations List')];
      if (!!size(action.sortedBy)) trackers.push(trackEventType('Customers', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case COMPLETE_SAVE_SUSPENDED_LOCATIONS:
      return trackEventType('Customers > Suspended Locations', 'Save', 'Save Suspended Locations Button');
    case ADD_SUSPENDED_LOCATION:
      return trackEventType('Customers > Suspended Locations', 'Add', 'Add Suspended Locations Button');
    case EDIT_SUSPENDED_LOCATION:
      return trackEventType('Customers > Suspended Locations', 'Edit', 'Edit Suspended Locations Button');
    case SORT_VEHICLES: {
      const trackers = [];
      if (action.sortedBy) trackers.push(trackEventType('Fleet > Vehicles', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case SEARCH_VEHICLES:
      return trackEventType('Fleet > Vehicles', 'Search', 'Search Vehicle List');
    case FILTER_VEHICLES:
      return trackEventType('Fleet > Vehicles', 'Filter', 'Status');
    case COMPLETE_LOAD_VEHICLE:
      return trackEventType('Fleet > Vehicles', 'Edit', 'Edit Vehicle Button');
    case COMPLETE_VEHICLE_SAVE:
      return trackEventType(
        'Fleet > Vehicles',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Vehicle Button`,
      );
    case SORT_DRIVERS: {
      const trackers = [];
      if (action.sortedBy) trackers.push(trackEventType('Fleet > Drivers', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case SEARCH_DRIVERS:
      return trackEventType('Fleet > Drivers', 'Search', 'Search Drivers List');
    case FILTER_DRIVERS:
      return trackEventType('Fleet > Drivers', 'Filter', 'Status');
    case COMPLETE_LOAD_DRIVER:
      return trackEventType('Fleet > Drivers', 'Edit', 'Edit Driver Button');
    case COMPLETE_SAVE_DRIVER:
      return trackEventType(
        'Fleet > Drivers',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Driver Button`,
      );
    case COMPLETE_DELETE_CONTAINER:
      return trackEventType('Fleet > Containers', 'Delete', 'Delete Container Button');
    case COMPLETE_EXPORT_CONTAINER:
      return trackEventType('Fleet > Containers', 'Export', 'Export Container List Button');
    case COMPLETE_LOAD_CONTAINER:
      return trackEventType('Fleet > Containers', 'Edit', 'Edit Container Button');
    case COMPLETE_SAVE_CONTAINER:
      return trackEventType(
        'Fleet > Containers',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Container Button`,
      );
    case VIEW_CONTAINER_ON_MAP:
      return trackEventType('Fleet > Containers', 'View', 'View on Map Button');
    case COMPLETE_LOAD_CONTAINERS: {
      const trackers = [
        trackEventType('Fleet > Containers', 'View', 'View Containers List'),
        trackContainersSearchTermChange,
        trackContainersCustomerTypeChange,
        trackContainersEquipmentTypeChange,
        trackContainersEquipmentSizeChange,
      ];
      if (!!size(action.sortedBy))
        trackers.push(trackEventType('Fleet > Containers', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case COMPLETE_LOAD_CONTAINER_HISTORY:
      return trackEventType('Fleet > Containers', 'View', 'Container History Button');

    case COMPLETE_LOAD_USERS:
      return trackEventType('Account Settings', 'View', 'Manage Users');
    case COMPLETE_DELETE_USER:
      return trackEventType('Account Settings', 'Delete', 'Delete User');
    case COMPLETE_SAVE_USER:
      return trackEventType('Account Settings', action.saveType, `${action.saveType} User`);
    case COMPLETE_CHANGE_PASSWORD:
      return trackEventType('Account Settings', 'Edit', 'Change Password Button');
    case COMPLETE_SAVE_VENDOR:
      return trackEventType('Account Settings', 'Edit', 'Edit Vendor Info Button');
    case VIEW_ADVANCED_PROFILE:
      return trackEventType('Account Settings', 'View', 'Advanced Profile');
    case COMPLETE_LOAD_OPPORTUNITY:
      return trackEventType('Opportunities > Current Opportunities', 'View', 'View Page');
    default:
      return [];
  }
};

const ga = GoogleAnalyticsGtag('UA-45333213-4');

const gaMiddleware = createMiddleware(eventsMapper, ga);

export default gaMiddleware;
