import React from 'react';

import { Clusterer } from '@react-google-maps/marker-clusterer';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { size } from 'lodash-es';
import humps from 'humps';
import moment from 'moment';

import { CommunityInsightMapItem } from '../../insights/interfaces/communityInsights';
import { getBlobIdFromUrl } from 'src/utils/imageHelpers';
import { GOOGLE as google, INSIGHT_ICONS } from '../constants';
import {
  MapInfoImage,
  MapInfoImageWrapper,
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowDetailsContainer,
  MapInfoWindowIcon,
  MapInfoWindowTitle,
  MapInfoWindowTitleContainer,
} from '../../core/components/styled';
import { MATERIAL_CONTAMINATION_ID } from '../../insights/constants';
import createTranslationKey from '../../utils/services/createTranslationKey';
import RouteImagesModal from '../../routes/components/modals/RouteImagesModal';
import translate from '../../core/services/translate';

interface Props {
  cityInsight: CommunityInsightMapItem;
  isInfoWindowOpen?: boolean;
  clusterer: Clusterer;
  toggleInfoWindow: (message: string) => void;
}

const CityInsightMarker: React.FC<Props> = ({
  cityInsight,
  clusterer,
  isInfoWindowOpen,
  toggleInfoWindow: rawToggleInfoWindow,
}) => {
  const [isImageModalOpen, setIsImageModalOpen] = React.useState<boolean>(false);

  const toggleInfoWindow = React.useCallback(() => {
    rawToggleInfoWindow(`cityInsights.${cityInsight.mapSelectorId}`);
  }, [rawToggleInfoWindow, cityInsight]);

  const openImageModal = React.useCallback(() => {
    setIsImageModalOpen(true);
  }, [setIsImageModalOpen]);

  const closeImageModal = React.useCallback(() => {
    setIsImageModalOpen(false);
  }, [setIsImageModalOpen]);

  const onMarkerClick = React.useCallback(() => {
    if (cityInsight.isCluster) {
      openImageModal();
    } else {
      toggleInfoWindow();
    }
  }, [toggleInfoWindow, openImageModal, cityInsight]);

  const singleInsight = !cityInsight.isCluster ? cityInsight.communityInsights[0] : null;
  const insightTypeName = humps.camelize(cityInsight.locationFlaggingType.technicalName);

  const pixelOffset = new google.maps.Size(0, -10);

  const isMaterialContamination = singleInsight?.locationFlaggingType.id === MATERIAL_CONTAMINATION_ID;
  const hasMaterialSubtypes = isMaterialContamination && size(singleInsight?.contaminationSubTypes) > 0;
  const contaminationSubTypesName =
    hasMaterialSubtypes &&
    singleInsight?.contaminationSubTypes &&
    singleInsight.contaminationSubTypes
      .map(contaminationSubType =>
        translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
      )
      .join(', ');
  const contaminationSubTypeFormatted = hasMaterialSubtypes && `: ${contaminationSubTypesName}`;

  const markerPosition = {
    lat: cityInsight.latitude,
    lng: cityInsight.longitude,
  };

  const markerIcon = {
    url: INSIGHT_ICONS[insightTypeName],
    size: new google.maps.Size(15, 15),
    scaledSize: new google.maps.Size(15, 15),
  };

  let images: Array<{
    contaminationSubTypesName?: string;
    imageBlobId: string;
    imageUrl: string;
    rawImageUrl?: string;
    issueName: string;
    locationName: string;
    routeName?: string;
    timeStamp: string;
  }> = [];

  cityInsight.communityInsights.forEach(insight => {
    const contaminationSubTypesName =
      insight?.contaminationSubTypes &&
      insight.contaminationSubTypes
        .map(contaminationSubType =>
          translate(createTranslationKey(contaminationSubType, 'insights.contaminationSubType')),
        )
        .join(', ');

    const imageInfo = {
      issueName: translate(createTranslationKey(insight.locationFlaggingType.technicalName, 'vendors.locationFlags')),
      locationName: insight.address,
      routeName: insight.routeName,
      timeStamp: insight.locationFlagTimeStamp,
      contaminationSubTypesName,
    };

    images = !!insight.images?.length
      ? insight.images.map(image => ({
          ...imageInfo,
          imageUrl: image.imageUrl,
          rawImageUrl: image?.rawImageUrl || image.imageUrl,
          imageBlobId: image.imageId,
        }))
      : !!insight.imageUrl
      ? [
          {
            ...imageInfo,
            imageUrl: insight.imageUrl,
            rawImageUrl: insight?.rawImageUrl || insight.imageUrl,
            imageBlobId: getBlobIdFromUrl(insight.imageUrl),
          },
        ]
      : [];
  });

  return (
    <Marker
      noClustererRedraw
      key={cityInsight.mapSelectorId}
      icon={markerIcon}
      position={markerPosition}
      onClick={onMarkerClick}
      clusterer={clusterer}
    >
      {isInfoWindowOpen && markerPosition && singleInsight && (
        <InfoWindow onCloseClick={toggleInfoWindow} position={markerPosition} options={{ pixelOffset }}>
          <MapInfoWindow vertical>
            <MapInfoWindowTitleContainer>
              <MapInfoWindowIcon size="small" src={markerIcon.url} />

              <MapInfoWindowTitle>
                {translate(`dashboard.${insightTypeName}`)}
                {contaminationSubTypeFormatted}
              </MapInfoWindowTitle>
            </MapInfoWindowTitleContainer>

            <MapInfoWindowDetailsContainer>
              <MapInfoWindowDetails>
                {singleInsight.routeDate && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('insights.date')}:</MapInfoWindowDetailLabel>
                    {moment(singleInsight.routeDate).format('MM/DD/YYYY')}
                  </MapInfoWindowDetail>
                )}

                {singleInsight.address && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('insights.address')}:</MapInfoWindowDetailLabel>
                    {singleInsight.address}
                  </MapInfoWindowDetail>
                )}

                {!!singleInsight.routeName && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('routes.route')}:</MapInfoWindowDetailLabel>
                    {singleInsight.routeName}
                  </MapInfoWindowDetail>
                )}

                {!!singleInsight.vehicleName && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.truck')}:</MapInfoWindowDetailLabel>
                    {singleInsight.vehicleName}
                  </MapInfoWindowDetail>
                )}

                {singleInsight.statusId >= 0 && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('insights.status')}:</MapInfoWindowDetailLabel>
                    {singleInsight.statusId === 1 ? translate('insights.cleared') : translate('insights.open')}
                  </MapInfoWindowDetail>
                )}
              </MapInfoWindowDetails>

              {!!images.length && (
                <MapInfoImageWrapper overlay={images.length > 1 ? images.length : undefined} onClick={openImageModal}>
                  <MapInfoImage src={images[0].imageUrl} />
                </MapInfoImageWrapper>
              )}
            </MapInfoWindowDetailsContainer>
          </MapInfoWindow>
        </InfoWindow>
      )}

      {!!isImageModalOpen && <RouteImagesModal images={images} closeModal={closeImageModal} />}
    </Marker>
  );
};

export default CityInsightMarker;
