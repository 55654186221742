import React, { useState, Fragment } from 'react';

import { debounce, orderBy } from 'lodash-es';
import { reduxForm, InjectedFormProps, Field, submit } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { AccountSettingsModals } from '../pages/AccountSettingsPage';
import { ActionButtonTooltip, Checkbox } from '../../../core/components';
import { ALTERNATIVE_FLEET_FEATURE_ID } from 'src/vendors/constants/features';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { featuresForLoggedInUser } from 'src/vendors/ducks/features';
import confirm from 'src/core/services/confirm';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

interface PropsWithoutReduxForm {}

type AlternativeFleetFormProps = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const AlternativeFleetForm: React.FC<AlternativeFleetFormProps> = ({ form }) => {
  const dispatch = useDispatch();

  const features = useSelector(featuresForLoggedInUser);
  const alternativeFleetFeatures = orderBy(
    features.filter(feature => feature.parentId === ALTERNATIVE_FLEET_FEATURE_ID),
    ['name'],
    ['asc'],
  );

  const [selectedFeatureCode, setSelectedFeatureCode] = useState<string | undefined>();

  const closeFeatureModal = async (pristine = true) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
      return;
    }

    setSelectedFeatureCode(undefined);
  };

  const onChange = debounce(() => {
    dispatch(submit(form));
  }, 100);

  return (
    <form onChange={onChange} noValidate>
      <PanelSection padding="small" centered>
        <GridColumn size="6/12">
          <Grid centered multiLine>
            {alternativeFleetFeatures.map(feature => (
              <Fragment key={feature.code}>
                <GridColumn size="11/12" verticalAlign="center" padding="no" minHeight={42}>
                  <Field
                    component={Checkbox}
                    name={feature.code}
                    props={{
                      block: true,
                      margin: 'no',
                      size: 'medium',
                      label: feature.name,
                    }}
                  />
                </GridColumn>

                <GridColumn size="1/12" verticalAlign="center" padding="no" align="right" minHeight={42}>
                  {feature.canEditDetails && (
                    <Button
                      text
                      color="grayDark"
                      margin="no"
                      padding="no"
                      type="button"
                      disabled={!feature.enabled}
                      onClick={() => setSelectedFeatureCode(feature.code)}
                    >
                      <ActionButtonTooltip icon="edit" tooltip="editFeature" iconSize="sMedium" />
                    </Button>
                  )}
                </GridColumn>
              </Fragment>
            ))}
            <AccountSettingsModals featureCode={selectedFeatureCode} closeFeatureModal={closeFeatureModal} />
          </Grid>
        </GridColumn>
      </PanelSection>
    </form>
  );
};

export default reduxForm({ form: 'alternativeFleetForm', onSubmitFail: focusFirstInvalidField })(AlternativeFleetForm);
