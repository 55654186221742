import { Redirect, Switch, useLocation } from 'react-router';

import {
  ApplyChecksPageResolver,
  BillCreatePageResolver,
  BillDetailsPageResolver,
  BillingPageResolver,
  PaymentDetailsPageResolver,
  PayNowPageResolver,
  RateConfigurationPageResolver,
  StripeOnboardingPageResolver,
} from './pages';
import { BILLING_MODULE } from 'src/vendors/ducks/features';
import { FeatureRestrictedRoute } from 'src/account/components';

const Finance = () => {
  const { search } = useLocation();
  return (
    <Switch>
      <FeatureRestrictedRoute
        feature={BILLING_MODULE}
        exact
        path="/finance/onboarding"
        component={StripeOnboardingPageResolver}
      />
      <FeatureRestrictedRoute feature={BILLING_MODULE} exact path="/finance/billing" component={BillingPageResolver} />
      <FeatureRestrictedRoute
        feature={BILLING_MODULE}
        exact
        path="/finance/billing/create-new-bill"
        component={BillCreatePageResolver}
      />
      <FeatureRestrictedRoute
        feature={BILLING_MODULE}
        exact
        path="/finance/billing/bill-details/:billId"
        component={BillDetailsPageResolver}
      />
      <FeatureRestrictedRoute
        feature={BILLING_MODULE}
        exact
        path="/finance/payments"
        component={ApplyChecksPageResolver}
      />
      <FeatureRestrictedRoute
        feature={BILLING_MODULE}
        exact
        path="/finance/rate-configuration"
        component={RateConfigurationPageResolver}
      />
      <FeatureRestrictedRoute
        feature={BILLING_MODULE}
        exact
        path="/finance/payments/:paymentId/:paymentCustomerId"
        component={PaymentDetailsPageResolver}
      />
      <Redirect
        from="/finance/apply-payments/:paymentId/:paymentCustomerId"
        to={`/finance/payments/:paymentId/:paymentCustomerId${search}`}
      />
      <Redirect from="/finance/apply-payments/" to={`/finance/payments/${search}`} />
      <FeatureRestrictedRoute
        feature={BILLING_MODULE}
        exact
        path="/finance/pay-now/:invoiceId"
        component={PayNowPageResolver}
      />
      <Redirect to="/finance/billing" />
    </Switch>
  );
};

export default Finance;
