import moment from 'moment';
import { camelize } from 'humps';

import { Label, TableCell, TableRow, Text } from 'src/core/components/styled';
import { ServiceWorkOrder } from 'src/customers/interfaces/Services';
import { currency, dateAndTime } from 'src/utils/services/formatter';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

// TEMPORARY STUFF UNTILL WE ADD TECHNICAL TYPES TO THE PICKUP STATUS
const LABEL_COLORS: { [key: string]: string } = {
  completed: 'success',
  inProgress: 'warning',
  scheduled: 'info',
  unScheduled: 'alert',
};

const WorkOrdersTableRow = ({
  amount,
  cellWidths,
  invoiced,
  pickupDate,
  pickupStatus,
  routeName,
  scheduledDate,
  showOperationalWorkOrders,
  workFlow,
  workOrderNumber,
}: ServiceWorkOrder & {
  cellWidths: { [key: string]: string };
  showOperationalWorkOrders: boolean;
}) => {
  const ps = pickupStatus ? camelize(pickupStatus) : 'unScheduled';
  return (
    <TableRow>
      <TableCell width={cellWidths.date}>{scheduledDate && moment(scheduledDate).format('MM/DD/YYYY')}</TableCell>
      <TableCell width={cellWidths.workOrderNumber}>{workOrderNumber}</TableCell>
      {showOperationalWorkOrders ? (
        <>
          <TableCell width={cellWidths.workFlow}>
            {translate(createTranslationKey(workFlow, 'customers.serviceEditor.workFlows'))}
          </TableCell>
          <TableCell width={cellWidths.route}>{routeName}</TableCell>
          <TableCell width={cellWidths.serviceStatusId} vertical horizontalAlign="left" align="center">
            <Label color={LABEL_COLORS[ps]}>{translate(`common.pickupStatuses.${ps}`)}</Label>
            {pickupDate && (
              <Text block weight="medium" size="small">
                {dateAndTime(pickupDate)}
              </Text>
            )}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell width={cellWidths.invoicedStatus}>
            <Text block uppercase weight="medium" size="small">
              {invoiced}
            </Text>
          </TableCell>
          <TableCell width={cellWidths.amount} align="center">
            {amount === null ? translate('common.notAvailable') : !isNaN(amount) ? currency(amount) : '-'}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default WorkOrdersTableRow;
