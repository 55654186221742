import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import {
  RouteImportsPageResolver,
  RouteImportStatusesPageResolver,
  CreateRouteImportPageResolver,
  RouteImportDetailsPageResolver,
} from './pages';

const RouteImport = () => (
  <Switch>
    <Route exact path="/route-import" component={RouteImportsPageResolver} />
    <Route exact path="/route-import/statuses" component={RouteImportStatusesPageResolver} />
    <Route exact path="/route-import/create" component={CreateRouteImportPageResolver} />
    <Route exact path="/route-import/details" component={RouteImportDetailsPageResolver} />
    <Redirect to="/route-import" />
  </Switch>
);

export default RouteImport;
