import { size } from 'lodash-es';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { ROUTE_MAP_TRAVEL_PATH_SOURCE } from '../constants';
import RouteMapTravelPathGLPopup from './RouteMapTravelPathGLPopup';
import RouteMapTravelPathGLSource from './RouteMapTravelPathGLSource';
import TravelPathLegend from './TravelPathLegend';
import { getTravelPathArrowsGeoJSON, getTravelPathGeoJSON } from './utils';

interface Props {
  map: mapboxgl.Map;
}

const RouteMapTravelPathGL: FC<Props> = ({ map }) => {
  const dispatch = useDispatch();
  const { travelPathData, showTravelPath } = useSelector(state => state.routes.travelPath);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === ROUTE_MAP_TRAVEL_PATH_SOURCE);

        if (feature && feature.properties) {
          dispatch(
            setRouteMapSelectedFeature(RouteMapFeature.travelPath, feature.properties.id as number, {
              coordinates: {
                lat: event.lngLat.lat,
                lng: event.lngLat.lng,
              },
            }),
          );
        }
      });
    });
  }, [map, dispatch]);

  const linesGeoJson = useMemo(() => {
    if (!travelPathData || !showTravelPath) return null;
    return getTravelPathGeoJSON(travelPathData);
  }, [showTravelPath, travelPathData]);

  const arrowsGeoJson = useMemo(() => {
    if (!travelPathData || !showTravelPath) return null;
    return getTravelPathArrowsGeoJSON(travelPathData);
  }, [showTravelPath, travelPathData]);

  if (!linesGeoJson) return null;

  return (
    <>
      <RouteMapTravelPathGLSource linesGeoJson={linesGeoJson} arrowsGeoJson={arrowsGeoJson} />
      <RouteMapTravelPathGLPopup />
      {!!size(travelPathData?.features) && showTravelPath && <TravelPathLegend />}
    </>
  );
};

export default RouteMapTravelPathGL;
