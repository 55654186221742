import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getHaulerLocationIcon } from 'src/common/constants/vendorLocationIcons';
import {
  STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER,
  STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER,
} from 'src/customers/constants';
import { CustomerLocationSimple } from 'src/customers/interfaces/Customers';
import { HaulerLocation } from 'src/dashboard/interfaces/haulerLocations';

export type HaulerLocationFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getHaulerLocationsGeoJSON = (haulerLocations: HaulerLocation[]) =>
  getFeatureCollection<GeoJSON.Point, HaulerLocationFeatureProperties>(
    haulerLocations.map(location =>
      getPointFeature(location.id, [location.longitude, location.latitude], {
        id: location.id,
        clickable: true,
        layer: STREET_NETWORK_SERVICE_AREAS_HAULER_LOCATIONS_LAYER,
        icon: getHaulerLocationIcon(location),
      }),
    ),
  );

export const getCustomerLocationGeoJson = (customerLocations: CustomerLocationSimple[]) =>
  getFeatureCollection<GeoJSON.Point, HaulerLocationFeatureProperties>(
    customerLocations.map(location =>
      getPointFeature(location.locationId, [location.longitude, location.latitude], {
        id: location.locationId,
        name: location.locationName,
        clickable: true,
        layer: STREET_NETWORK_SERVICE_AREAS_CUSTOMER_LOCATIONS_LAYER,
      }),
    ),
  );

interface GetSelectedIdsOptions {
  prefix: string;
  hasAll?: boolean;
}

export const getSelectedIds = (values: any, options: GetSelectedIdsOptions) => {
  const { prefix } = options;

  const keys = Object.keys(values).filter(key => values[key]);
  const selectedIds = keys
    .filter(key => key.startsWith(prefix))
    .map(key => key.replace(prefix, ''))
    .map(Number);

  return selectedIds;
};
