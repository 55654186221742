import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadRouteTemplatesForReassignment } from 'src/routes/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import TransferSnowOrSweeperSegmentsModal from './TransferSnowOrSweeperSegmentsModal';

interface Props {
  closeModal: () => void;
  isSnowPlowRoute: boolean;
  onSubmit: (values: any) => void;
  sourceRouteTemplateId?: number;
}

const TransferSnowOrSweeperSegmentsModalResolver: React.FC<Props> = ({
  closeModal,
  isSnowPlowRoute,
  onSubmit,
  sourceRouteTemplateId,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId) as number;

  const loadDependencies = async () => {
    if (!vendorId || !sourceRouteTemplateId) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [
      loadRouteTemplatesForReassignment(
        vendorId,
        sourceRouteTemplateId,
        isSnowPlowRoute ? SNOW_PLOW_ID : STREET_SWEEPER_ID,
      )(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={TransferSnowOrSweeperSegmentsModal}
      successProps={{ closeModal, onSubmit }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default TransferSnowOrSweeperSegmentsModalResolver;
