import React, { PureComponent, Fragment } from 'react';

import RoadConditionMarker from './RoadConditionMarker';
import { RED, GREEN, YELLOW, SCORES, GOOGLE as google } from '../constants';

const isCoordinateGroupInsideViewBoundaries = (coordinateGroup: any[], mapBounds: any, mapZoom: number) => {
  if (mapZoom <= 10) return false;

  const groupBounds = new google.maps.LatLngBounds();
  coordinateGroup.forEach(position => {
    groupBounds.extend(new google.maps.LatLng(position[1], position[0]));
  });

  return mapBounds.intersects(groupBounds);
};

interface Props {
  coordinateGroup: any;
  mapZoom: number;
  mapBounds: any;
}

class RoadConditionGroup extends PureComponent<Props> {
  getColorIndex = (score: any) => {
    switch (true) {
      case score >= SCORES.severe && score <= SCORES.good:
        return YELLOW;
      case score < SCORES.severe:
        return RED;
      default:
        return GREEN;
    }
  };

  render() {
    const {
      coordinateGroup: {
        geometry: { coordinates },
        properties: { roadQualityScore, waySegmentId },
      },
      mapBounds,
      mapZoom,
    } = this.props;

    return (
      <Fragment>
        {isCoordinateGroupInsideViewBoundaries(coordinates, mapBounds, mapZoom) && (
          <RoadConditionMarker
            key={waySegmentId}
            colorIndex={this.getColorIndex(roadQualityScore)}
            coordinates={coordinates}
          />
        )}
      </Fragment>
    );
  }
}

export default RoadConditionGroup;
