import React from 'react';
import moment from 'moment';
import { date, dateAndTime } from '../../../utils/services/formatter';
import { TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import { XDevicesStatus } from '../../interfaces/XDevicesStatus';
import ActionButtonTooltip from '../../../core/components/ActionButtonTooltip';
import translate from '../../../core/services/translate';
import { BYOD, RUBICON, X_DEVICE_OWNERS } from 'src/settings/constants/xDeviceOwners';
import { APPLICATION_TYPES } from 'src/settings/constants/applicationTypes';

interface Props extends XDevicesStatus {
  isRubiconAdmin: boolean;
  isHaulerAdmin: boolean;
  vendorXDeviceTypeId: number;
  onDeleteDevice: (deviceId: string) => void;
  onEditDeviceOwner: (deviceId: string) => void;
  onOpenLastKnownPositionModal: (phoneName: string, deviceUniqueId: string, lastRecordedEvent: string) => void;
  onOpenEditDeviceOwnerModal: (
    deviceOwnerTypeId: number,
    phoneName: string,
    serialNumber: any,
    uniqueId: string,
  ) => void;
}

export const xDeviceStatusesPageTableRowHeight = 65;

const XDeviceStatusesPageTableRow: React.FC<Props> = ({
  deviceUniqueId,
  durationSinceLastCommunicationInDays,
  isRubiconAdmin,
  isHaulerAdmin,
  lastRecordedEvent,
  onDeleteDevice,
  onOpenLastKnownPositionModal,
  onOpenEditDeviceOwnerModal,
  phoneName,
  applicationTypeId,
  deviceOwnerTypeId,
  serialNumber,
  driverName,
  routeName,
}) => {
  const NA = translate('common.notAvailable');
  const today = moment().format('MM/DD/YYYY');
  const phoneNameFormatted = phoneName || NA;
  const lastRecordedEventDate = lastRecordedEvent ? date(lastRecordedEvent) : NA;
  const lastRecordedEventDateAndTime = lastRecordedEvent ? dateAndTime(lastRecordedEvent) : NA;
  const driverNameFormatted = driverName || NA;
  const routeNameFormatted = routeName || NA;
  const daySinceLastLogin =
    lastRecordedEvent === today || lastRecordedEvent === null ? 0 : durationSinceLastCommunicationInDays;
  let deviceOwner: string;

  if (deviceOwnerTypeId === BYOD) {
    deviceOwner = X_DEVICE_OWNERS[BYOD].name;
  } else {
    deviceOwner = X_DEVICE_OWNERS[RUBICON].name;
  }
  const serial = serialNumber || NA;

  const hasPermissionToEdit = isRubiconAdmin || (isHaulerAdmin && deviceOwnerTypeId === BYOD);
  return (
    <TableRow height={xDeviceStatusesPageTableRowHeight}>
      <TableCell width="14%">{phoneNameFormatted}</TableCell>
      <TableCell width="15%">{APPLICATION_TYPES[applicationTypeId].name}</TableCell>
      <TableCell width="10%">{deviceOwner}</TableCell>
      <TableCell width="11%">{lastRecordedEventDate}</TableCell>
      <TableCell width="10%">{daySinceLastLogin}</TableCell>
      <TableCell width="15%">{routeNameFormatted}</TableCell>
      <TableCell width="15%">{driverNameFormatted}</TableCell>
      <TableCell width="10%">
        {hasPermissionToEdit && (
          <TableActionButton
            onClick={() => onOpenEditDeviceOwnerModal(deviceOwnerTypeId, phoneNameFormatted, serial, deviceUniqueId)}
          >
            <ActionButtonTooltip icon="edit" tooltip={translate('settings.editDevice')} tooltipAsString />
          </TableActionButton>
        )}
        {hasPermissionToEdit && (
          <TableActionButton onClick={() => onDeleteDevice(deviceUniqueId)}>
            <ActionButtonTooltip icon="delete" tooltip={translate('settings.unregisterDevice')} tooltipAsString />
          </TableActionButton>
        )}

        <TableActionButton
          onClick={() => onOpenLastKnownPositionModal(phoneNameFormatted, deviceUniqueId, lastRecordedEventDateAndTime)}
        >
          <ActionButtonTooltip icon="map" tooltip={translate('settings.lastKnownLocation')} tooltipAsString />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
};

export default XDeviceStatusesPageTableRow;
