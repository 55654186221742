import { combineReducers } from 'redux';
import { reducer as aiModelConfigurationsReducer } from './aiModelConfigurations';
import { reducer as billingModuleReducer } from './billingModule';
import { reducer as bulkyItemSchedulerReducer } from './bulkyItemScheduler';
import { reducer as changePasswordReducer } from './changePassword';
import { reducer as cityAlertSettingsReducer } from './cityAlertSettings';
import { reducer as cityAlertsReducer } from './cityAlerts';
import { reducer as cityWorksReducer } from './cityWorksConfigurations';
import { reducer as containerManagementReducer } from './containerManagement';
import { reducer as customerNotificationsVehicleTypesReducer } from './customerNotificationsVehicleTypes';
import { reducer as dataRetentionReducer } from './dataRetention';
import { reducer as defaultVendorReducer } from './defaultVendor';
import { reducer as driverPickListDefaultsReducer } from './driverPickListDefaults';
import { reducer as driverSafetyReducer } from './driverSafety';
import { reducer as esriIntegrationConfigReducer } from './esriIntegrationConfiguration';
import { reducer as exceptionConfigurationsReducer } from './exceptionConfigurations';
import { reducer as exceptionManagementConfigurationReducer } from './exceptionManagementConfiguration';
import { reducer as excludeCustomerFromProcessingFeeReducer } from './excludeCustomerFromProcessingFee';
import { reducer as faultCodeEmailsReducer } from './faultCodeEmails';
import { reducer as featureConfigurationsReducer } from './featureConfigurations';
import { reducer as featuresReducer } from './features';
import { reducer as fleetMaintenanceReducer } from './fleetMaintenance';
import { reducer as fuelingTicketsSettingsReducer } from './fuelingTicketsSettings';
import { reducer as geoFenceSettingsReducer } from './geoFenceSettings';
import { reducer as insightConfigurationReducer } from './insightConfiguration';
import { reducer as loadBinSensorSettingsReducer } from './binSensorSettings';
import { reducer as loadContainerColorConfiguration } from './containerColorConfiguration';
import { reducer as loadEsriSyncSettings } from './esriSyncSettings';
import { reducer as loadNavigationSettings } from './navigationSettings';
import { reducer as loadRouteAssistSettings } from './routeAssistSettings';
import { reducer as loadRouteBuilderSettings } from './routeBuilderSettings';
import { reducer as loadRouteSequenceSettings } from './routeSequenceSettings';
import { reducer as loadSnowPlowSettings } from './snowPlowSettings';
import { reducer as loadStreetSweepingSettings } from './streetSweepingSettings';
import { reducer as loadTripConfigurationReducer } from './loadTripConfiguration';
import { reducer as loadVehicleStrobeImageFrequencyTypesReducer } from './vehicleStrobeImageFrequencyTypes';
import { reducer as loadVehicleStrobeImagesSettingsReducer } from './vehicleStrobeImagesSettings';
import { reducer as locationAlertsReducer } from './locationAlerts';
import { reducer as locationFlagsReducer } from './locationFlags';
import { reducer as materialTypesReducer } from './materialTypes';
import { reducer as notificationEmailTemplatesReducer } from './notificationEmailTemplates';
import { reducer as notificationTypesReducer } from './notificationTypes';
import { reducer as open311ConfigErrorsReducer } from './open311ConfigErrors';
import { reducer as open311SettingsReducer } from './open311Settings';
import { reducer as quickBooksReducer } from './quickBooks';
import { reducer as reasonTypesReducer } from './reasonTypes';
import { reducer as reportingConfigurationReducer } from './reportingConfiguration';
import { reducer as rollOffContainerManagementSettingsReducer } from './rollOffContainerManagementSettings';
import { reducer as sosAlertSettingsReducer } from './sosAlertSettings';
import { reducer as stationaryAlertsReducer } from './stationaryAlerts';
import { reducer as stripeAccountReducer } from './stripeAccount';
import { reducer as supervisorExperienceSettingsReducer } from './supervisorExperienceSettings';
import { reducer as surfsightConfigurationReducer } from './surfsightConfiguration';
import { reducer as timeManagementReducer } from './timeManagement';
import { reducer as usersReducer } from './users';
import { reducer as vehicleNotificationsReducer } from './vehicleNotifications';
import { reducer as vehicleTrackingReducer } from './vehicleTracking';
import { reducer as vehicleTypesForExceptionConfigurationReducer } from './vehicleTypesForExceptionConfiguration';
import { reducer as vendorAccessReducer } from './vendorAccess';
import { reducer as vendorReducer } from './vendor';
import { reducer as vendorsReducer } from './vendors';
import { reducer as wasteAuditConfigurationReducer } from './wasteAuditConfiguration';
import { reducer as yDataReducer } from './yData';
import { reducer as zDeviceConfigurationReducer } from './zDeviceConfigurations';

export { deleteDefaultVendor, resetDefaultVendor, setDefaultVendor } from './defaultVendor';

export { loadVendors, resetVendors, vendorByIdSelector } from './vendors';
export {
  loadAvailableVendors,
  loadAssignedVendors,
  saveVendorAccess,
  saveAssignedVendors,
  vendorAccessUserIdSelector,
} from './vendorAccess';

export {
  COMPLETE_DELETE as COMPLETE_DELETE_USER,
  COMPLETE_LOAD as COMPLETE_LOAD_USERS,
  COMPLETE_SAVE as COMPLETE_SAVE_USER,
  deleteUser,
  districtsUserIdSelector,
  gusIdSelector,
  isUserManagerSelector,
  loadDistricts,
  loadUserById,
  loadUsers,
  resetUsers,
  saveDivision,
  saveUser,
  updateDistricts,
  userIdSelector,
} from './users';

export {
  COMPLETE_SAVE as COMPLETE_SAVE_VENDOR,
  checkIfVendorHasNetworkFile,
  loadVendor,
  resetVendor,
  saveVendor,
  VIEW_ADVANCED_PROFILE,
  viewAdvancedProfile,
} from './vendor';

export {
  autoRefreshStatusSelector,
  displayUnscheduledStopsStatusSelector,
  featuresForLoggedInUserSelector,
  featureStatusSelector,
  flagLocationStatusSelector,
  geoFenceSelector,
  inferenceAuditStatusSelector,
  loadFeatures,
  resetFeatures,
  routeSequencingStatusSelector,
  saveFeatures,
  vehicleStrobeImagesStatusSelector,
  visionConfigurationStatusSelector,
  wasteAuditStatusSelector,
} from './features';

export { changePassword, COMPLETE_CHANGE_PASSWORD, resetChangePassword } from './changePassword';

export { loadLocationFlags, resetLocationFlags, saveLocationFlags } from './locationFlags';

export { loadFleetMaintenance, saveFleetMaintenance, resetFleetMaintenance } from './fleetMaintenance';

export {
  loadNotificationEmailTemplates,
  resetNotificationEmailTemplates,
  saveNotificationEmailTemplate,
} from './notificationEmailTemplates';

export { deleteFaultCodeEmail, loadFaultCodeEmails, resetFaultCodeEmails, saveFaultCodeEmail } from './faultCodeEmails';

export { deleteLocationAlert, loadLocationAlerts, resetLocationAlerts, saveLocationAlert } from './locationAlerts';

export {
  loadFeatureConfigurations,
  resetFeatureConfigurations,
  saveFeatureConfigurations,
  toggleFeatureConfiguration,
} from './featureConfigurations';

export {
  loadRollOffContainerManagementSettings,
  resetRollOffContainerManagementSettings,
  saveRollOffContainerMangementSettings,
} from './rollOffContainerManagementSettings';

export { loadStationaryAlert, resetStationaryAlerts, saveStationaryAlert } from './stationaryAlerts';

export {
  loadWasteAuditConfiguration,
  resetWasteAuditConfiguration,
  saveWasteAuditConfiguration,
  wasteAuditConfigurationIndexByTypeSelector,
} from './wasteAuditConfiguration';

export { loadCustomerNotificationsVehicleTypes } from './customerNotificationsVehicleTypes';

export {
  loadNotificationTypes,
  resetNotificationTypes,
  technicalNameByNotificationIdSelector,
} from './notificationTypes';

export { loadReasonTypes, resetReasonTypes } from './reasonTypes';

export {
  commandsConfigurationSelector,
  loadAiModelConfigurations,
  modelConfigurationSelector,
  onConfigurationVersionChange,
  resetAiModelConfigurations,
  saveAiModelConfigurations,
  toggleAiModelConfiguration,
} from './aiModelConfigurations';

export {
  loadZDeviceConfigurations,
  onZDeviceConfigurationVersionChange,
  resetZDeviceConfigurations,
  saveZDeviceConfigurations,
  selectCommands,
  selectZDeviceServices,
  toggleZDeviceConfiguration,
} from './zDeviceConfigurations';

export {
  loadDefaultExceptionConfigurations,
  loadExceptionConfigurations,
  resetExceptionConfigurations,
  saveExceptionConfigurations,
} from './exceptionConfigurations';

export {
  loadVehicleTypesForPickupExceptions,
  loadVehicleTypesForExceptionManagement,
  resetVehicleTypesForExceptionConfiguration,
  technicalNameByVehicleTypeIdSelector,
} from './vehicleTypesForExceptionConfiguration';

export {
  handleSortOrderChange,
  loadTripConfigurationForVendor,
  resetTripConfigurationsForVendor,
  saveTripConfigurationsForVendor,
  toggleTripConfigurationIsActive,
  toggleTripConfigurationIsRequired,
  toggleTripConfigurationSendEmailNotification,
  toggleTripConfigurationPicture,
  toggleTripConfigurationPictureNotFlagged,
} from './loadTripConfiguration';

export {
  loadRouteSequenceSettings,
  resetRouteSequenceSettings,
  saveRouteSequenceSettings,
} from './routeSequenceSettings';

export { loadSnowPlowSettings, saveSnowPlowSettings, loadSnowPlowDefaultSettings } from './snowPlowSettings';

export {
  loadStreetSweepingSettings,
  saveStreetSweepingSettings,
  loadStreetSweepingDefaultSettings,
} from './streetSweepingSettings';

export {
  checkIsBillingActive,
  completeStripeAccountSetup,
  createStripeAccount,
  refreshStripeAccountLink,
} from './stripeAccount';

export { loadBinSensorSettings } from './binSensorSettings';

export { loadEsriSyncSettings } from './esriSyncSettings';

export { loadRouteBuilderSettings, saveRouteBuilderSettings } from './routeBuilderSettings';

export { loadContainerColorConfiguration, saveContainerColorConfiguration } from './containerColorConfiguration';

export { loadNavigationSettings, saveNavigationSettings } from './navigationSettings';

export { loadRouteAssistSettings, saveRouteAssistSettings } from './routeAssistSettings';

export { loadGeoFenceSettings, saveGeoFenceSettings } from './geoFenceSettings';

export { loadSOSAlertSettings, saveSOSAlertSettings } from './sosAlertSettings';

export {
  loadSupervisorExperienceSettings,
  saveSupervisorExperienceSettings,
  loadDriverSafetyInspectionSettings,
  saveDriverSafetyInspectionSettings,
} from './supervisorExperienceSettings';

export { loadTimeManagementSettings, saveTimeManagementSettings } from './timeManagement';

export { loadVehicleTrackingSettings, saveVehicleTrackingSettings, loadNavigationLineTypes } from './vehicleTracking';

export { loadVehicleStrobeImagesSettings, saveVehicleStrobeImagesSettings } from './vehicleStrobeImagesSettings';
export { loadVehicleStrobeImageFrequencyTypes } from './vehicleStrobeImageFrequencyTypes';
export { loadInsightConfiguration, saveInsightConfiguration } from './insightConfiguration';
export { loadReportingConfiguration, saveReportingConfiguration } from './reportingConfiguration';
export { loadYData, saveYData } from './yData';
export { loadDataRetention, loadVendorDataRetention, saveDataRetention } from './dataRetention';
export {
  loadContainerManagementSettings,
  saveContainerManagementSettings,
  resetContainerManagementSettings,
} from './containerManagement';

export {
  loadBulkyItemScheduler,
  saveBulkyItemScheduler,
  loadBulkyCategoryTypes,
  loadBulkyItemTypes,
  loadPeriodTypes,
} from './bulkyItemScheduler';

export { loadFuelingTicketsSettings, saveFuelingTicketsSettings } from './fuelingTicketsSettings';

export {
  loadDriverSafetyConfigurations,
  saveDriverSafetyConfigurations,
  resetDriverSafetyConfigurations,
  loadDriverSafetyConfigurationsForVehicle,
  saveDriverSafetyConfigurationsForVehicle,
} from './driverSafety';

export {
  loadMaterialTypes,
  loadDispatchBoardMaterialTypes,
  saveMaterialTypes,
  resetForceMaterialTypes,
} from './materialTypes';

export { loadCityAlertSettings, resetCityAlertSettings, saveCityAlertSettings } from './cityAlertSettings';

export {
  loadCityAlertImages,
  loadCityAlerts,
  resetCityAlertImages,
  loadCityAlertsForRoute,
  resetCityAlerts,
  saveCityAlert,
} from './cityAlerts';

export { loadBillingModule, saveBillingModule } from './billingModule';

export { loadDriverPickListDefaults, saveDriverPickListDefaults } from './driverPickListDefaults';

export const reducer = combineReducers({
  aiModelConfigurations: aiModelConfigurationsReducer,
  billingModule: billingModuleReducer,
  binSensorSettings: loadBinSensorSettingsReducer,
  bulkyItemScheduler: bulkyItemSchedulerReducer,
  changePassword: changePasswordReducer,
  cityAlerts: cityAlertsReducer,
  cityAlertSettings: cityAlertSettingsReducer,
  cityWorks: cityWorksReducer,
  containerColorConfiguration: loadContainerColorConfiguration,
  containerManagementSettings: containerManagementReducer,
  customerNotificationsVehicleTypes: customerNotificationsVehicleTypesReducer,
  dataRetention: dataRetentionReducer,
  defaultVendor: defaultVendorReducer,
  driverPickListDefaults: driverPickListDefaultsReducer,
  driverSafety: driverSafetyReducer,
  esriIntegrationConfig: esriIntegrationConfigReducer,
  esriSyncSettings: loadEsriSyncSettings,
  exceptionConfigurations: exceptionConfigurationsReducer,
  exceptionManagementConfiguration: exceptionManagementConfigurationReducer,
  excludeCustomerFromProcessingFee: excludeCustomerFromProcessingFeeReducer,
  faultCodeEmails: faultCodeEmailsReducer,
  featureConfigurations: featureConfigurationsReducer,
  features: featuresReducer,
  fleetMaintenance: fleetMaintenanceReducer,
  fuelingTicketsSettings: fuelingTicketsSettingsReducer,
  geoFenceSettings: geoFenceSettingsReducer,
  insightConfiguration: insightConfigurationReducer,
  locationAlerts: locationAlertsReducer,
  locationFlags: locationFlagsReducer,
  materialTypes: materialTypesReducer,
  navigationSettings: loadNavigationSettings,
  notificationEmailTemplates: notificationEmailTemplatesReducer,
  notificationTypes: notificationTypesReducer,
  open311ConfigErrors: open311ConfigErrorsReducer,
  open311Settings: open311SettingsReducer,
  quickBooks: quickBooksReducer,
  reasonTypes: reasonTypesReducer,
  reportingConfiguration: reportingConfigurationReducer,
  rollOffContainerManagementSettings: rollOffContainerManagementSettingsReducer,
  routeAssistSettings: loadRouteAssistSettings,
  routeBuilderSettings: loadRouteBuilderSettings,
  routeSequenceSettings: loadRouteSequenceSettings,
  snowPlowSettings: loadSnowPlowSettings,
  sosAlertSettings: sosAlertSettingsReducer,
  stationaryAlerts: stationaryAlertsReducer,
  streetSweepingSettings: loadStreetSweepingSettings,
  stripeAccount: stripeAccountReducer,
  supervisorExperienceSettings: supervisorExperienceSettingsReducer,
  surfsightConfiguration: surfsightConfigurationReducer,
  timeManagementSettings: timeManagementReducer,
  tripConfiguration: loadTripConfigurationReducer,
  users: usersReducer,
  vehicleNotifications: vehicleNotificationsReducer,
  vehicleStrobeImageFrequencyTypes: loadVehicleStrobeImageFrequencyTypesReducer,
  vehicleStrobeImagesSettings: loadVehicleStrobeImagesSettingsReducer,
  vehicleTrackingSettings: vehicleTrackingReducer,
  vehicleTypesForExceptionConfiguration: vehicleTypesForExceptionConfigurationReducer,
  vendor: vendorReducer,
  vendorAccess: vendorAccessReducer,
  vendors: vendorsReducer,
  wasteAuditConfiguration: wasteAuditConfigurationReducer,
  yData: yDataReducer,
  zDeviceConfigurations: zDeviceConfigurationReducer,
});
