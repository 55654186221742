import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadTrackingSettings as doLoadTrackingSettings,
  saveTrackingSettings as doSaveTrackingSettings,
} from '../services/trackingSettings';

// Actions
const START_LOAD = 'settings/trackingSettings/START_LOAD';
const COMPLETE_LOAD = 'settings/trackingSettings/COMPLETE_LOAD';
const FAIL_LOAD = 'settings/trackingSettings/FAIL_LOAD';
const START_SAVE = 'settings/trackingSettings/START_SAVE';
const COMPLETE_SAVE = 'settings/trackingSettings/COMPLETE_SAVE';
const FAIL_SAVE = 'settings/trackingSettings/FAIL_SAVE';
const RESET = 'settings/trackingSettings/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  trackingSettings: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          trackingSettings: action.trackingSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          trackingSettings: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          isSaving: false,
          trackingSettings: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (trackingSettings: any) => ({
  type: COMPLETE_LOAD,
  trackingSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadTrackingSettings = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadTrackingSettingsPromise = doLoadTrackingSettings();
  loadTrackingSettingsPromise
    .then(trackingSettings => dispatch(completeLoad(trackingSettings)))
    .catch(() => dispatch(failLoad()));
  return loadTrackingSettingsPromise;
};

export const saveTrackingSettings = (trackingSettings: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveTrackingSettingsPromise = doSaveTrackingSettings(trackingSettings);
  saveTrackingSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveTrackingSettingsPromise;
};

export const resetTrackingSettings = () => ({
  type: RESET,
});
