import React, { PureComponent, Fragment } from 'react';
import { find } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, InjectedFormProps } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';

import { AppState } from '../../../store';
import { Container, ButtonSet, Button, Grid, GridColumn, Hide } from '../../../core/components/styled';
import { DatePicker, Dropdown, Input, TypedField } from '../../../core/components';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import opportunityFormInitialValueSelector from '../../services/opportunityFormInitialValueSelector';
import {
  OpportunityToggleContainer,
  OpportunityToggle,
  OpportunityArrowRightIcon,
  OpportunityArrowDownIcon,
} from '../styled';
import { PERCENT } from '../../constants';
import { THURSDAY, FRIDAY } from 'src/core/constants/weekdays';
import translate from '../../../core/services/translate';
import { vendorPaymentMethodTypes } from '../../ducks';

const formSelector = formValueSelector('requestChangesForOpportunity');

const CurrencyContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 12px;
`;

interface ComponentProps {
  caseId: number;
  isBiddingSourcing?: boolean;
  offer: any;
  endDestinations?: any;
  paymentInfo?: any;
  onCancel(pristine: boolean): void;
  isMobile: boolean;
}

interface PropsWithoutReduxForm extends ComponentProps {
  holidays: Date[];
  initialValues: any;
  response: any;
  requestedChanges: any;
  requestedChangesState: any;
  isSaving: boolean;
  paymentMethods: any;
  paymentInfo: any;
  change: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  isExpanded: boolean;
  requestedChanges: any;
}

class RequestChangesForOpportunityForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isExpanded: true,
      requestedChanges: props.requestedChanges,
    };
  }

  onServiceChangeCalculateTaxbasedPercentage = (serviceValue: number, ctrNamePath: string, value: number) => {
    const taxValue = serviceValue ? (serviceValue * (value || 0)) / 100 : undefined;

    this.props.change(ctrNamePath, parseFloat(taxValue as any) === 0 ? '' : parseFloat(taxValue as any).toFixed(2));

    return taxValue;
  };

  onPercentageChangeCalculateTaxInDollar = (name: any, value: number, id: any) => {
    const service = this.getServiceRate();

    const taxValue = service ? (service * (value || 0)) / 100 : undefined;
    const ctrName = name.currentTarget.name.split('.');
    const ctrNamePath = `${name.currentTarget.name}InDollar`;

    this.props.change(ctrNamePath, parseFloat(taxValue as any) === 0 ? '' : parseFloat(taxValue as any).toFixed(2));

    const totalMonthlyServiceFees = this.calculateTotalMonthlyServiceFee(
      ctrName[ctrName.length - 1],
      parseFloat(taxValue as any),
      id,
    );

    this.updateOverallServiceCharge(totalMonthlyServiceFees);
  };

  onTaxInDollarChangeCalculatePercentage = (name: any, value: number, id: any) => {
    const service = this.getServiceRate();
    const taxPercentage = service ? (100 * (value || 0)) / parseFloat(service) : undefined;

    const ctrNamePath = name.currentTarget.name.substr(0, name.currentTarget.name.length - 8);
    const ctrName = ctrNamePath.split('.');

    this.props.change(
      ctrNamePath,
      parseFloat(taxPercentage as any) === 0 || isNaN(taxPercentage as any)
        ? ''
        : parseFloat(taxPercentage as any).toFixed(2),
    );

    const totalMonthlyServiceFees = this.calculateTotalMonthlyServiceFee(
      ctrName[ctrName.length - 1],
      parseFloat(value as any) || 0,
      id,
    );

    this.updateOverallServiceCharge(totalMonthlyServiceFees);
  };

  onServiceChangeCalculateTotalMonthlyServiceFee = (name: any, value: any, rateCode: string) => {
    const totalMonthlyServiceFees = (this.calculateTotalMonthlyServiceFee as any)(rateCode, value);
    this.updateOverallServiceCharge(totalMonthlyServiceFees);
  };

  getServiceRate = () => {
    const objHaulerRateDetails = this.props.requestedChanges.haulerRateDetails.find(
      (haulerRateDetail: any) => haulerRateDetail.bundled && haulerRateDetail.inherit,
    );

    const service = objHaulerRateDetails && objHaulerRateDetails.rate ? objHaulerRateDetails.rate : 0;
    return service;
  };

  updateOverallServiceCharge = (totalMonthlyServiceFees: any) => {
    this.props.change(
      `${`opportunities.settings${this.props.caseId}.requestedChanges.overallserviceCharge`}`,
      parseFloat(totalMonthlyServiceFees) === 0 || isNaN(totalMonthlyServiceFees)
        ? ''
        : parseFloat(totalMonthlyServiceFees),
    );
  };

  calculateTotalMonthlyServiceFee = (ctrName: string, taxValue: any, id: any) => {
    let total = 0;
    this.props.requestedChanges.haulerRateDetails.forEach((fees: any, index: number) => {
      if (fees.bundled && fees.rateType === PERCENT && fees.inherit === false && fees.fee && ctrName !== 'service') {
        if (id === index) {
          total += parseFloat(taxValue);
        } else {
          total += parseFloat(fees.rateInDollar) || 0;
        }
      } else if (fees.bundled && fees.inherit) {
        if (ctrName === 'service') {
          total += parseFloat(taxValue);
        } else {
          total += parseFloat(fees.rate);
        }
      } else if (!fees.bundled && !fees.inherit && fees.isMonthlyFee) {
        if (ctrName === fees.rateCd) {
          total += parseFloat(taxValue);
        } else {
          total += parseFloat(fees.rate);
        }
      } else if (
        ctrName === 'service' &&
        fees.bundled &&
        fees.rateType === PERCENT &&
        fees.inherit === false &&
        fees.fee
      ) {
        total += (this.onServiceChangeCalculateTaxbasedPercentage as any)(
          taxValue,
          `${`opportunities.settings${this.props.caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}`,
          this.props.requestedChanges.haulerRateDetails[index].rate,
        );
      }
    });

    return parseFloat(total as any).toFixed(2);
  };

  toggle = (event: any) => {
    this.setState(({ isExpanded }) => ({
      isExpanded: !isExpanded,
    }));

    event.preventDefault();
  };

  render() {
    const {
      caseId,
      handleSubmit,
      holidays,
      endDestinations,
      offer,
      requestedChangesState,
      onCancel,
      pristine,
      isSaving,
      paymentMethods,
      paymentInfo,
      isMobile,
    } = this.props;
    const { isExpanded } = this.state;
    const { deliveryDate } = offer;
    const dayOfweek = moment(deliveryDate).day();
    const noOfDays = dayOfweek === THURSDAY || dayOfweek === FRIDAY ? 4 : 2;
    return (
      <Container isLoading={isSaving}>
        <form onSubmit={handleSubmit} noValidate>
          <Container>
            <TypedField
              name={`opportunities.settings${caseId}.requestedChanges.deliveryDate`}
              component={DatePicker}
              validate={[isDateValidValidator]}
              props={{
                raisedLabel: true,
                label: translate('routes.holidayPlanner.newServicDate'),
                placeholder: deliveryDate,
                margin: 'small no xSmall',
                disabledDays: [
                  {
                    before: moment(deliveryDate, 'MM/DD/YYYY').toDate(),
                    after: moment(deliveryDate).add(noOfDays, 'days').toDate(),
                  },
                  { daysOfWeek: [0, 6] },
                  ...holidays,
                ],
              }}
            />
            <Field
              name={`opportunities.settings${caseId}.requestedChanges.paymentMethod`}
              component={Dropdown}
              options={paymentMethods}
              label={translate('opportunity.opportunities.paymentMethod')}
              margin="medium no xSmall"
            />
          </Container>
          <Container>
            <Field
              name={`opportunities.settings${caseId}.requestedChanges.paymentId`}
              component={Dropdown}
              options={paymentInfo}
              label={translate('opportunity.opportunities.paymentInfo')}
              margin="medium no xSmall"
            />
          </Container>
          <Container>
            <Field
              name={`opportunities.settings${caseId}.requestedChanges.facilityId`}
              component={Dropdown}
              options={endDestinations}
              validate={isRequired}
              label={translate('opportunity.opportunities.endDestination')}
              margin="medium no xSmall"
            />
          </Container>

          <Container>
            {(requestedChangesState.overallserviceCharge || requestedChangesState.overallserviceCharge === 0) &&
              offer.isServiceBaseRate && (
                <Grid margin="medium no no">
                  <GridColumn size="12/12" padding="no no">
                    <CurrencyContainer>$</CurrencyContainer>
                    <Field
                      name={`opportunities.settings${caseId}.requestedChanges.overallserviceCharge`}
                      component={Input}
                      type="number"
                      label={translate('opportunity.overallMonthlyTotal')}
                      margin="no small"
                      disabled
                    />
                  </GridColumn>
                  <GridColumn size="2/12" padding="no">
                    {offer.isServiceBaseRate && (
                      <OpportunityToggleContainer>
                        <OpportunityToggle onClick={this.toggle} title={translate('common.edit')}>
                          {isExpanded ? (
                            <OpportunityArrowRightIcon icon="arrowRight" color="gray" />
                          ) : (
                            <OpportunityArrowDownIcon icon="arrowDown" color="gray" />
                          )}
                        </OpportunityToggle>
                      </OpportunityToggleContainer>
                    )}
                  </GridColumn>
                </Grid>
              )}
          </Container>

          <Container>
            {requestedChangesState.haulerRateDetails.map((fees: any, index: number) => (
              <Fragment key={index}>
                <Hide hide={isExpanded}>
                  {fees.bundled && fees.rateType === PERCENT && !fees.inherit && fees.fee && (
                    <Grid margin={isMobile ? 'no' : 'no medium no'} verticalAlign="end">
                      <GridColumn padding="no" size="7/12">
                        <CurrencyContainer>$</CurrencyContainer>
                        <Field
                          name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}
                          component={Input}
                          type="number"
                          label={fees.rateDesc}
                          margin="no small"
                          onChange={(name: any, value: any) =>
                            this.onTaxInDollarChangeCalculatePercentage(name, value, index)
                          }
                        />
                      </GridColumn>
                      <GridColumn size="5/12">
                        <Field
                          name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                          component={Input}
                          type="number"
                          label="%"
                          margin="no small"
                          onChange={(name: any, value: any) =>
                            this.onPercentageChangeCalculateTaxInDollar(name, value, index)
                          }
                        />
                      </GridColumn>
                    </Grid>
                  )}
                  {fees.bundled && fees.inherit && (
                    <Grid margin={isMobile ? 'no' : 'no medium no'}>
                      <GridColumn padding="no" size="12/12">
                        <CurrencyContainer>$</CurrencyContainer>
                        <Field
                          name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                          component={Input}
                          type="number"
                          label={`${fees.rateDesc} (${translate(
                            `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                          )})`}
                          validate={isRequired}
                          margin="no small"
                          onChange={(name: any, value: any) =>
                            this.onServiceChangeCalculateTotalMonthlyServiceFee(name, value, 'service')
                          }
                        />
                      </GridColumn>
                    </Grid>
                  )}
                  {!fees.bundled && !fees.inherit && fees.isMonthlyFee && (
                    <Grid margin={isMobile ? 'no' : 'no medium no'}>
                      <GridColumn padding="no" size="12/12">
                        <CurrencyContainer>$</CurrencyContainer>
                        <Field
                          name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                          component={Input}
                          type="number"
                          label={`${fees.rateDesc} (${translate(
                            `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                          )})`}
                          validate={isRequired}
                          margin="no small"
                          onChange={(name: any, value: any) =>
                            this.onServiceChangeCalculateTotalMonthlyServiceFee(name, value, fees.rateCd)
                          }
                        />
                      </GridColumn>
                    </Grid>
                  )}
                </Hide>
                {!fees.bundled && !fees.inherit && !fees.isMonthlyFee && (
                  <Grid>
                    <GridColumn padding="no" size="12/12">
                      <CurrencyContainer>$</CurrencyContainer>
                      <Field
                        name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                        component={Input}
                        type="number"
                        label={`${fees.rateDesc} (${translate(
                          `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                        )})`}
                        validate={isRequired}
                        margin="no small"
                      />
                    </GridColumn>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Container>

          <ButtonSet margin="large auto no">
            <Button type="submit" color="primary">
              {translate('opportunity.submit')}
            </Button>
            <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
              {translate('common.cancel')}
            </Button>
          </ButtonSet>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { isBiddingSourcing, caseId, paymentInfo } = ownProps;
  const opportunity = isBiddingSourcing
    ? (find as any)(state.opportunity.opportunities.opportunities, { caseId })
    : (find as any)(state.opportunity.opportunity.opportunities, { caseId });

  const opportunities = isBiddingSourcing
    ? state.opportunity.opportunities.opportunities
    : state.opportunity.opportunity.opportunities;
  return {
    holidays: state.common.holidays.holidays,
    initialValues: opportunityFormInitialValueSelector(opportunities),
    response: formSelector(state, `opportunities.settings${caseId}.response`),
    requestedChanges: formSelector(state, `opportunities.settings${caseId}.requestedChanges`),
    requestedChangesState: opportunity.requestedChanges,
    isSaving: state.opportunity.opportunity.isSaving,
    paymentMethods: paymentInfo.paymentMethods,
    paymentInfo: vendorPaymentMethodTypes(
      paymentInfo.vendorPayments,
      formSelector(state, `opportunities.settings${caseId}.requestedChanges.paymentMethod`),
    ),
  };
};

const mapDispatchToProps = {
  change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'requestChangesForOpportunity',
    enableReinitialize: true,
  })(RequestChangesForOpportunityForm),
);
