import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { date } from '../../../utils/services/formatter';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { loadWaterFills, saveWaterFill, loadRouteDetails, loadSnowSweeperRouteDetails } from '../../ducks';
import { Modal } from '../../../core/components';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { uploadWaterFillsImage } from 'src/routes/ducks/waterFills';
import translate from '../../../core/services/translate';
import WaterFillEditorForm, { WaterFillFormValues } from '../forms/WaterFillEditorForm';

type Props = {
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  waterFillId?: number;
};

export default function WaterFillEditorModal({ onClose, routeId, waterFillId }: Props) {
  const dispatch = useDispatch();
  const isEditMode = waterFillId ? true : false;

  const [uploadedImageId, setUploadedImageId] = useState(null);
  const [uploadedImageBlob, setUploadedImageBlob] = useState(undefined);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);

  const {
    isSaving,
    isUploadingWaterFillsImage,
    waterFills: allWaterFills,
  } = useSelector((state: AppState) => state.routes.waterFills);
  const { routeSummary } = useSelector((state: AppState) => state.routes.routeSummary);
  const routeDate = useSelector((state: AppState) => state.routes.route.route?.routeDate);
  const vendorId = useSelector(currentVendorId);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary?.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const rawWaterFill = allWaterFills.find(mt => mt.id === waterFillId);
  let waterFillInitialValues = {};
  const getWaterFillDateTime = (waterFillDate?: Date | string, waterFillTime?: Date | string) => {
    return {
      waterFillDate: date(waterFillDate),
      waterFillTime: moment(waterFillTime).format('HH:mm'),
    };
  };

  if (rawWaterFill) {
    waterFillInitialValues = {
      ...rawWaterFill,
      ...getWaterFillDateTime(rawWaterFill.pickUpDateTimeLocal, rawWaterFill.pickUpDateTimeLocal),
    };
  } else {
    waterFillInitialValues = {
      ...getWaterFillDateTime(routeDate),
    };
  }

  const handleSubmitWaterFill = async (formData: WaterFillFormValues) => {
    const { waterFillDate, waterFillTime } = formData;
    const date = moment(waterFillDate).format('YYYY-MM-DD');
    const waterFillDateTime = moment(date + waterFillTime, 'YYYY-MM-DDLT');

    const currentFormData = {
      ...formData,
      pickUpDateTimeLocal: moment(waterFillDateTime).format('YYYY-MM-DD HH:mm:ss'),
    };

    delete currentFormData.locationNameAddress;
    const waterFill = waterFillId
      ? {
          ...rawWaterFill,
          ...currentFormData,
        }
      : {
          vehicleId: routeSummary?.vehicleId,
          ...currentFormData,
        };

    if (uploadedImageId && uploadedImageUrl) {
      waterFill.image = {
        blobId: uploadedImageBlob,
        imageId: uploadedImageId,
        url: uploadedImageUrl,
      };
    }

    saveWaterFill(
      routeId,
      waterFill,
    )(dispatch)
      .then(() => {
        loadWaterFills(routeId)(dispatch);

        const noLoading = true;
        isSnowOrSweeperRoute
          ? loadSnowSweeperRouteDetails(
              vendorId,
              routeId,
              segmentsFilterStartDate,
              segmentsFilterEndDate,
              noLoading,
            )(dispatch)
          : loadRouteDetails(vendorId, routeId, noLoading)(dispatch);

        createSuccessNotification(translate('routes.alertMessages.waterFillSaved'));
        onClose(true);
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.waterFillSaveError'));
      });
  };

  const onClearFileName = () => {
    setFileName(undefined);
    setUploadedImageBlob(undefined);
    setUploadedImageId(null);
    setUploadedImageUrl(undefined);
  };

  const onImageUpload = (files: FileList | null) => {
    files &&
      !!files.length &&
      uploadWaterFillsImage(
        files[0],
        routeId,
      )(dispatch)
        .then(async (response: any[]) => {
          setFileName(response[0].imageFileName);
          setUploadedImageBlob(response[0].imageBlobId);
          setUploadedImageId(response[0].id);
          setUploadedImageUrl(response[0].imageUrl);

          createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
        })
        .catch(() => {
          createErrorNotification(translate('common.alertMessages.invalidImageFormat'));
        });
  };

  return (
    <Modal
      size="large"
      isLoading={isSaving || isUploadingWaterFillsImage}
      onClose={onClose}
      title={translate(`common.waterFill.${isEditMode ? 'edit' : 'add'}WaterFill`)}
    >
      <WaterFillEditorForm
        initialValues={waterFillInitialValues}
        onClearfileName={onClearFileName}
        onClose={onClose}
        onImageUpload={onImageUpload}
        onSubmit={handleSubmitWaterFill}
        selectedFileName={fileName}
      />
    </Modal>
  );
}
