import { ACTIVE, NEW } from 'src/common/constants/accountStatuses';
import { SearchType } from 'src/common/interfaces/SearchType';
import { SortOrder } from 'src/core/components/Table';
import { LIMIT_PER_PAGE, PAGE } from 'src/core/constants';
import { http } from 'src/core/services/http';
import transformLoadCustomers from './transformLoadCustomers';

export const loadCustomersSimplified = (
  vendorId: number,
  searchQuery: string,
  customerTypeIds?: string | number[],
  customerStatusTypeIds: string = `${ACTIVE},${NEW}`,
  isPaymentOverdue?: boolean,
  page: number = PAGE,
  limit: number = LIMIT_PER_PAGE,
  sortedBy?: string,
  sortOrder?: SortOrder,
  searchType?: SearchType,
) =>
  http
    .get('/customersQuick', {
      params: {
        vendorId,
        searchQuery,
        customerTypeIds,
        customerStatusTypeIds,
        isPaymentOverdue,
        page,
        limit,
        searchType,
        sortOrder: sortedBy,
        sortDirection: sortOrder,
      },
    })
    .then(response => transformLoadCustomers(response.data));

export const deleteCustomer = (
  customerId: number,
  shouldRecreateRoutes: boolean,
  moveContainersToFacilityId?: number,
) =>
  http
    .delete(`customers/${customerId}`, {
      params: { shouldRecreateRoutes, moveContainersToFacilityId },
    })
    .then(response => response.data);

export const loadCustomerLocationsForMap = (vendorId: number) =>
  http
    .get('customers/simple', {
      params: {
        vendorId,
      },
    })
    .then(response => response.data);
