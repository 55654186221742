import React from 'react';

import { WEEKDAYS_BY_ID } from 'src/core/constants/weekdays';
import translate from 'src/core/services/translate';
import { RouteTemplateBuilderDaysCounterWrapper } from '../../../styled/RouteTemplateBuilderDaysCounter';
import { Text } from '../../../../../core/components/styled';
import { RouteTemplateBuilderService } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { useSelector } from 'src/core/hooks/useSelector';
import { ALL_STOPS_FILTER, ASSIGNED_FILTER, UNASSIGNED_FILTER } from 'src/routes/constants/routeTemplateBuilder';

interface Props {
  services: RouteTemplateBuilderService[];
  forServiceActions?: boolean;
}

export default function RouteTemplateBuilderStopCountsPerDay({ services, forServiceActions }: Props) {
  const dayOfServiceIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds);
  const selectedDraft = useSelector(state => state.routes.routeTemplateBuilder.selectedDraft);
  const stopFilterType = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.stopFilterType);
  const availableDaysOfService = (selectedDraft ? [selectedDraft.dayOfService] : dayOfServiceIds).map(
    dayId => WEEKDAYS_BY_ID[dayId],
  );
  const routeTemplateIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);

  return (
    <RouteTemplateBuilderDaysCounterWrapper forServiceActions={forServiceActions}>
      <table>
        <tbody>
          <tr>
            <th>
              <Text size="small" weight="medium" color="grayDark" margin="no small no no">
                {translate('routeTemplateBuilder.stopsPerDay')}
              </Text>
            </th>

            {availableDaysOfService.map(day => (
              <th key={day.id}>
                <Text size="small" weight="medium" color="black" margin="no">
                  {translate(`routeTemplateBuilder.dayShortCodes.${day.shortCode}`)}
                </Text>
              </th>
            ))}
          </tr>
          {(stopFilterType === ALL_STOPS_FILTER || stopFilterType === ASSIGNED_FILTER) && (
            <tr>
              <td>
                <Text size="xSmall" weight="medium" color="primary">
                  {translate('common.assigned')}
                </Text>
              </td>

              {availableDaysOfService.map(day => {
                const assignedServices = services.filter(service => {
                  const currentDay = (service.daysOfService || []).find(d => d.id === day.id);
                  if (!currentDay) return false;
                  const isAssigned =
                    currentDay &&
                    (currentDay.routeTemplateId || currentDay.draftRouteTemplateId) &&
                    routeTemplateIds.indexOf(currentDay.routeName) !== -1;

                  return isAssigned;
                });

                return (
                  <td key={day.id}>
                    <Text size="xSmall" weight="medium" color="primary" margin="no xxSmall no no">
                      {assignedServices.length}
                    </Text>
                  </td>
                );
              })}
            </tr>
          )}

          {(stopFilterType === ALL_STOPS_FILTER || stopFilterType === UNASSIGNED_FILTER) && (
            <tr>
              <td>
                <Text size="xSmall" weight="medium" color="warning">
                  {translate('routeTemplateBuilder.unassigned')}
                </Text>
              </td>

              {availableDaysOfService.map(day => {
                const unassignedServices = services.filter(service => {
                  const currentDay = (service.daysOfService || []).find(d => d.id === day.id);
                  if (!currentDay) return false;
                  const isUnassigned = !currentDay.routeTemplateId && !currentDay.draftRouteTemplateId;
                  return isUnassigned;
                });

                return (
                  <td key={day.id}>
                    <Text size="xSmall" weight="medium" color="warning" margin="no xxSmall no no">
                      {unassignedServices.length}
                    </Text>
                  </td>
                );
              })}
            </tr>
          )}
        </tbody>
      </table>
    </RouteTemplateBuilderDaysCounterWrapper>
  );
}
