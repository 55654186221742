import { get } from 'lodash-es';
import { useDispatch } from 'react-redux';
import humps from 'humps';
import React from 'react';

import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { CustomerDetailsEditorModal } from '.';
import { getFutureAccountStatus } from 'src/customers/services/futureAccountStatusValidator';
import { loadCustomer, saveCustomer } from 'src/customers/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

interface Props {
  customerId: number;
  onClose(pristine: boolean): void;
}

export const CustomerDetailsEditorModalResolver: React.FC<Props> = ({ customerId, onClose }) => {
  const dispatch = useDispatch();

  const loadDependencies = async () => {
    const dependencies: Promise<unknown>[] = [loadCustomer(customerId)(dispatch)];

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoadingOverlay}
      successComponent={CustomerDetailsEditorModal}
      successProps={{
        customerId,
        onCancel: onClose,
        onSaveCustomer(data: any) {
          const futureAccountStatus = get(data, 'futureAccountStatus', {});
          const redirectToPage = false;
          const customer = {
            ...data,
            futureAccountStatus: getFutureAccountStatus(futureAccountStatus),
          };

          saveCustomer(
            customer,
            redirectToPage,
          )(dispatch)
            .then(() => {
              onClose(true);
              createSuccessNotification(translate('customers.alertMessages.customerSaved'));
            })
            .catch(e => {
              const errorMessageCode = !!e?.response?.data?.code && humps.camelize(e.response.data.code);

              createErrorNotification(
                errorMessageCode
                  ? translate(createTranslationKey(errorMessageCode, 'customers.alertMessages'))
                  : translate('customers.alertMessages.customerSaveError'),
              );
            });
        },
      }}
    />
  );
};
