import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import applyBatching from 'src/common/services/applyBatching';
import {
  assignUnassignedJob as doAssignUnassignedJob,
  deleteUnassignedJob as doDeleteUnassignedJob,
  loadUnassignedJobs as doLoadUnassignedJobs,
  loadUnassignedJobsCount as doLoadUnassignedJobsCount,
} from 'src/routes/services/dispatchBoardUnassignedJobs';
import { UnassignedJob } from 'src/routes/interfaces/DispatchBoardRouteJob';

// Actions
const START_LOAD = 'dispatchBoard/unassignedJobs/START_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/unassignedJobs/COMPLETE_LOAD';
const COMPLETE_LOAD_COUNT = 'dispatchBoard/unassignedJobs/COMPLETE_LOAD_COUNT';
const FAIL_LOAD = 'dispatchBoard/unassignedJobs/FAIL_LOAD';
const START_DELETE_JOB = 'dispatchBoard/unassignedJobs/START_DELETE_JOB';
const COMPLETE_DELETE_JOB = 'dispatchBoard/unassignedJobs/COMPLETE_DELETE_JOB';
const FAIL_DELETE_JOB = 'dispatchBoard/unassignedJobs/FAIL_ASSIGN_JOB';
const START_ASSIGN_JOB = 'dispatchBoard/unassignedJobs/START_ASSIGN_JOB';
const BATCH_UPDATE = 'dispatchBoard/unassignedJobs/BATCH_UPDATE';
const COMPLETE_ASSIGN_JOB = 'dispatchBoard/unassignedJobs/COMPLETE_ASSIGN_JOB';
const FAIL_ASSIGN_JOB = 'dispatchBoard/unassignedJobs/FAIL_ASSIGN_JOB';
const SET_SELECTED_JOB_IDS = 'dispatchBoard/unassignedJobs/SET_SELECTED_JOB_IDS';
const SET_SELECTED_JOB_INDEXES = 'dispatchBoard/unassignedJobs/SET_SELECTED_JOB_INDEXES';
const RESET = 'dispatchBoard/unassignedJobs/RESET';

interface State {
  isLoading: boolean;
  isDeletingJob: boolean;
  isAssigningJob: boolean;
  unassignedJobs: UnassignedJob[];
  batchAssignerProgress: number;
  unassignedJobsCount: {
    totalCount: number;
    overdueCount: number;
  };
  selectedJobIds: number[];
  selectedJobIndexes: number[];
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isDeletingJob: false,
  isAssigningJob: false,
  unassignedJobs: [],
  batchAssignerProgress: 0,
  unassignedJobsCount: {
    totalCount: 0,
    overdueCount: 0,
  },
  selectedJobIds: [],
  selectedJobIndexes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          unassignedJobs: action.unassignedJobs,
        },
      });

    case COMPLETE_LOAD_COUNT:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedJobsCount: action.unassignedJobsCount,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedJobs: [],
        },
      });

    case START_DELETE_JOB:
      return update(state, {
        $merge: { isDeletingJob: true },
      });

    case COMPLETE_DELETE_JOB: {
      const selectedUnassignedJobs = state.unassignedJobs.filter(
        (unassignedJob: UnassignedJob) => !action.jobIds.includes(unassignedJob.id),
      );

      return update(state, {
        $merge: {
          isDeletingJob: false,
          unassignedJobs: selectedUnassignedJobs,
        },
      });
    }

    case FAIL_DELETE_JOB:
      return update(state, {
        $merge: { isDeletingJob: false },
      });

    case START_ASSIGN_JOB:
      return update(state, {
        $merge: { isAssigningJob: true },
      });

    case BATCH_UPDATE:
      return update(state, {
        $merge: {
          batchAssignerProgress: action.progress,
        },
      });

    case COMPLETE_ASSIGN_JOB:
      return update(state, {
        $merge: { isAssigningJob: false },
      });

    case FAIL_ASSIGN_JOB:
      return update(state, {
        $merge: { isAssigningJob: false },
      });

    case SET_SELECTED_JOB_IDS:
      return update(state, {
        $merge: { selectedJobIds: action.selectedJobIds },
      });

    case SET_SELECTED_JOB_INDEXES:
      return update(state, {
        $merge: { selectedJobIndexes: action.selectedJobIndexes },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (unassignedJobs: UnassignedJob[]) => ({
  type: COMPLETE_LOAD,
  unassignedJobs,
});

const completeLoadCount = (unassignedJobsCount: number) => ({
  type: COMPLETE_LOAD_COUNT,
  unassignedJobsCount,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDeleteJob = () => ({
  type: START_DELETE_JOB,
});

const completeDeleteJob = (jobIds: number[]) => ({
  type: COMPLETE_DELETE_JOB,
  jobIds,
});

const failDeleteJob = () => ({
  type: FAIL_DELETE_JOB,
});

const startAssignJob = () => ({
  type: START_ASSIGN_JOB,
});

const batchUpdate = (progress: number) => ({
  type: BATCH_UPDATE,
  progress,
});

const completeAssignJob = () => ({
  type: COMPLETE_ASSIGN_JOB,
});

const failAssignJob = () => ({
  type: FAIL_ASSIGN_JOB,
});

export const setSelectedJobIds = (selectedJobIds: number[]) => ({
  type: SET_SELECTED_JOB_IDS,
  selectedJobIds,
});

export const setSelectedJobIndexes = (selectedJobIndexes: number[]) => ({
  type: SET_SELECTED_JOB_INDEXES,
  selectedJobIndexes,
});

export const loadDispatchBoardUnassignedJobsCount =
  (vendorId: number, vehicleTypeId: number, searchTerm: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const promise = doLoadUnassignedJobsCount(vendorId, vehicleTypeId, searchTerm);
    promise.then(routes => dispatch(completeLoadCount(routes))).catch(() => dispatch(failLoad()));
    return promise;
  };

export const loadDispatchBoardUnassignedJobs =
  (vendorId: number, vehicleTypeId: number, searchTerm?: string) => (dispatch: Dispatch) => {
    const promise = doLoadUnassignedJobs(vendorId, vehicleTypeId, searchTerm);
    promise.then(routes => dispatch(completeLoad(routes)));
    return promise;
  };

export const deleteDispatchBoardUnassignedJob = (jobIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startDeleteJob());
  const promise = doDeleteUnassignedJob(jobIds);
  promise.then(() => dispatch(completeDeleteJob(jobIds))).catch(() => dispatch(failDeleteJob()));
  return promise;
};

export const assignDispatchBoardUnassignedJobToRoute =
  (jobIds: any[], routeId: number, reqPosition: number) => async (dispatch: Dispatch) => {
    dispatch(startAssignJob());
    const batchSize = 50;
    let position = reqPosition;

    const promise = applyBatching(jobIds, batchSize, async (batchJobs, progress) => {
      await doAssignUnassignedJob(batchJobs, routeId, position);
      position += batchSize;
      dispatch(batchUpdate(progress));
    });

    promise.then(() => dispatch(completeAssignJob())).catch(() => dispatch(failAssignJob()));
    return promise;
  };

export const resetDispatchBoardUnassignedJobs = () => ({
  type: RESET,
});
