import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../contracts/ducks';
import { loadDispatchBoardUnassignedJobRouteNote } from '../../ducks/dispatchBoard';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import DispatchBoardUnassignedJobsNoteModal from './DisptachBoardUnassignedJobsNoteModal';

interface Props {
  closeModal: () => void;
  jobId?: number;
  loadDispatchBoardUnassignedJobRouteNote: DuckFunction<typeof loadDispatchBoardUnassignedJobRouteNote>;
}

class DispatchBoardUnassignedJobsNoteModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { jobId, loadDispatchBoardUnassignedJobRouteNote } = this.props;
    return loadDispatchBoardUnassignedJobRouteNote(jobId);
  };

  render() {
    const { jobId, closeModal } = this.props;

    return (
      <Resolver
        successComponent={DispatchBoardUnassignedJobsNoteModal}
        loadingComponent={PageLoadingOverlay}
        successProps={{ jobId, closeModal }}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = {
  loadDispatchBoardUnassignedJobRouteNote,
};

export default connect(undefined, mapDispatchToProps)(DispatchBoardUnassignedJobsNoteModalResolver);
