import { reduce, identity, set } from 'lodash-es';
import { createSelector } from 'reselect';

const getServiceChangeRequestFormInitialValues = (serviceChangeRequestState: any) => ({
  changeRequests: reduce(
    serviceChangeRequestState,
    (
      initialValues,
      {
        caseId,
        offer,
        hasCasters,
        hasLockbar,
        endDestinations,
        requestedChanges,
        respondedNo,
        response,
        status,
        workflowStatus,
        notifyPossibleCustomerApproval,
      },
    ) =>
      set(initialValues, `settings${caseId}`, {
        offer,
        hasCasters,
        hasLockbar,
        response,
        endDestinations,
        requestedChanges,
        respondedNo,
        status,
        workflowStatus,
        notifyPossibleCustomerApproval,
      }),
    {},
  ),
});

const serviceChangeRequestFormInitialValueSelector = createSelector(getServiceChangeRequestFormInitialValues, identity);

export default serviceChangeRequestFormInitialValueSelector;
