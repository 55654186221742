import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { Button } from 'src/core/components/styled/Button';
import { changeMobilePageDetailOverlayTitleContent } from 'src/core/ducks/mobilePage';
import { ModalTitle } from 'src/core/components/styled/Modal';

import Icon from 'src/core/components/Icon';
import styled from 'styled-components';
import translate from 'src/core/services/translate';
import { ServiceChangeRequest } from '../interfaces/ServiceChangeRequests';
import { MobileDataFields } from 'src/core/components/MobileDataFields';
import CHANGE_SERVICE_STATUS from '../constants/status';
import { size } from 'lodash-es';

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ServiceChangeRequestMobileListRowDetailsView: React.FC<
  ServiceChangeRequest & {
    onAcceptOpportunityModal: () => void;
    onDeclineServiceChangeModal: () => void;
    onRequestServiceChangesModal: () => void;
    onOpenNotesModal: () => void;
  }
> = ({
  customerAddress,
  customerName,
  siteName,
  offer: { service, overallserviceCharge },
  options: { canAccept, canDecline, canRequestDateChange },
  onAcceptOpportunityModal,
  onDeclineServiceChangeModal,
  onRequestServiceChangesModal,
  onOpenNotesModal,
  workOrder,
  portalStatus,
  hasVendorNotes,
  requestedChanges: { displayFees, serviceRecommendation },
  equipmentChanged,
  frequencyChanged,
  materialChanged,
  equipmentType,
  equipmentSize,
  equipmentSubType,
  equipmentUnits,
  material,
  frequency,
  quantity,
  currentServiceLevelSummary: { currentServiceLevel },
  serviceDescription,
}) => {
  const isNoteIconVisible = hasVendorNotes || size(serviceRecommendation) || displayFees;

  const fields = [
    { label: translate('autoDispatch.workOrder'), text: workOrder },
    {
      label: translate('opportunity.requestType.ec'),
      text: equipmentChanged ? translate('common.yes') : translate('common.no'),
    },
    {
      label: translate('opportunity.requestType.fc'),
      text: frequencyChanged ? translate('common.yes') : translate('common.no'),
    },
    {
      label: translate('opportunity.requestType.mc'),
      text: materialChanged ? translate('common.yes') : translate('common.no'),
    },
    {
      label: translate('opportunity.overallMonthlyCharges', { overallCharges: overallserviceCharge }),
      text: overallserviceCharge,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.requestedService')}: ${translate('common.equipmentType')}`,
      text: equipmentSubType
        ? translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentSubType}`)
        : equipmentType,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.requestedService')}: ${translate('common.equipmentSize')}`,
      text: equipmentSize ? `${parseInt(equipmentSize)} ${equipmentUnits}` : null,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.requestedService')}: ${translate(
        'customers.wasteMaterial',
      )}`,
      text: material,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.requestedService')}: ${translate('customers.frequency')}`,
      text: frequency,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.requestedService')}: ${translate('opportunity.quantity')}`,
      text: quantity || 0,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.currentService')}: ${translate('common.equipmentType')}`,
      text: currentServiceLevel.equipmentSubType
        ? translate(`haulerProfile.equipments.equipmentSubTypes.${currentServiceLevel.equipmentSubType}`)
        : currentServiceLevel.equipmentType,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.currentService')}: ${translate('common.equipmentSize')}`,
      text: `${parseInt(currentServiceLevel.equipmentSize)} ${currentServiceLevel.equipmentUnits}`,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.currentService')}: ${translate('customers.wasteMaterial')}`,
      text: currentServiceLevel.material,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.currentService')}: ${translate('customers.frequency')}`,
      text: currentServiceLevel.frequency,
    },
    {
      label: `${translate('opportunity.serviceChangeRequest.currentService')}: ${translate('opportunity.quantity')}`,
      text: currentServiceLevel.quantity || 0,
    },
    { label: translate('payment.serviceDescription'), text: serviceDescription },
    { label: translate('customers.customerName'), text: customerName },
    { label: translate('autoDispatch.siteName'), text: siteName },
    { label: translate('common.address'), text: customerAddress },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    changeMobilePageDetailOverlayTitleContent(
      <TitleSectionContainer>
        <TitleContainer>
          <div>
            <ModalTitle textAlign="left" margin="no xxSmall">
              {workOrder}
            </ModalTitle>
            <ModalTitle textAlign="left" fontSize="16px" margin="no xxSmall">
              {CHANGE_SERVICE_STATUS[portalStatus].name}
            </ModalTitle>
          </div>
        </TitleContainer>

        <Actions>
          {canAccept && (
            <Button padding="no" margin="no xSmall" text onClick={onAcceptOpportunityModal}>
              <Icon customViewBox="0 0 20 20" width="24px" icon="checkCircle" />
            </Button>
          )}
          {canDecline && (
            <Button padding="no" margin="no xSmall" text onClick={onDeclineServiceChangeModal}>
              <Icon customViewBox="0 0 22 22" width="26px" icon="closedIssue" />
            </Button>
          )}
          {canRequestDateChange && (
            <Button color="primary" padding="no" margin="no xSmall" text onClick={onRequestServiceChangesModal}>
              <Icon width="24px" icon="changeRequest" />
            </Button>
          )}
          {isNoteIconVisible && (
            <Button color="primary" padding="no" margin="no xSmall" text onClick={onOpenNotesModal}>
              <Icon customViewBox="0 0 18 18" width="24px" icon="page-content" />
            </Button>
          )}
        </Actions>
      </TitleSectionContainer>,
    )(dispatch);
  }, [
    canAccept,
    canDecline,
    canRequestDateChange,
    dispatch,
    onAcceptOpportunityModal,
    onDeclineServiceChangeModal,
    onRequestServiceChangesModal,
    onOpenNotesModal,
    portalStatus,
    service,
    workOrder,
    isNoteIconVisible,
  ]);

  return (
    <div>
      <MobileDataFields fields={fields} />
    </div>
  );
};
