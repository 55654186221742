import React from 'react';
import { Layer } from 'react-map-gl';

import { CONTAINERS_MAP_CLUSTER_SOURCE, CONTAINERS_MAP_FACILITIES_LAYER } from 'src/fleet/constants/containersMap';

export default React.memo(function FacilitiesGLLayer() {
  return (
    <>
      <Layer
        id={CONTAINERS_MAP_FACILITIES_LAYER}
        type="symbol"
        source={CONTAINERS_MAP_CLUSTER_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], CONTAINERS_MAP_FACILITIES_LAYER]]}
        paint={{}}
        layout={{
          'symbol-z-order': 'source',
          'symbol-sort-key': 1,
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        }}
      />
    </>
  );
});
