import { map, omit } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { DRIVING_COMPLEXITY_TYPES } from 'src/common/constants/drivingComplexity';
import { Modal } from '../../../core/components';
import { PICTURE_REQUIREMENT_TYPES } from 'src/vendors/constants';
import { saveStreetSweepingSettings } from '../../ducks';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { SegmentConfiguration, ServiceConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import {
  SERVICE_CONFIGURATION_TYPES,
  NON_SERVICE_CONFIGURATION_TYPES,
} from 'src/common/constants/serviceConfiguration';
import { StreetSweepingForm } from '../forms';
import { UnitConfiguration } from 'src/vendors/interfaces/StreetSweepingSettings';
import translate from '../../../core/services/translate';

export const streetSweepingModalId = 'street-sweeping-modal-id';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function StreetSweepingModal({ closeModal }: Props) {
  const dispatch = useDispatch();

  const { isSaving, isLoadingDefaultSettings, streetSweepingSettings } = useSelector(
    (state: AppState) => state.vendors.streetSweepingSettings,
  );
  const { isLoading } = useSelector((state: AppState) => state.common.streetSegmentAgingIntervals);
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const initialValues = {
    ...streetSweepingSettings,
    ...omit(streetSweepingSettings, 'segmentColorSettings'),
    drivingComplexityType: streetSweepingSettings?.drivingComplexityType?.id.toString(),
    displayConfiguration: {
      segmentColorSettings: map(streetSweepingSettings?.segmentColorSettings, (el: SegmentConfiguration) => ({
        ...el,
        enabled: !!el.enabled,
      })),
    },
    waterFillUps: streetSweepingSettings?.unitConfiguration[0]?.isActive,
    loadsDumped: streetSweepingSettings?.unitConfiguration[1]?.isActive,
    serviceSubTypes: streetSweepingSettings?.serviceConfiguration
      ?.filter(
        (el: ServiceConfiguration) =>
          !!SERVICE_CONFIGURATION_TYPES[el.alternativeFleetServiceType.technicalName] && el.isActive,
      )
      .map((el: ServiceConfiguration) => el.alternativeFleetServiceType.technicalName),
    nonServiceSubTypes: streetSweepingSettings?.serviceConfiguration
      ?.filter(
        (el: ServiceConfiguration) =>
          !!NON_SERVICE_CONFIGURATION_TYPES[el.alternativeFleetServiceType.technicalName] && el.isActive,
      )
      .map((el: ServiceConfiguration) => el.alternativeFleetServiceType.technicalName),
    lastServicedTimeFormatId:
      streetSweepingSettings.segmentColorSettings &&
      streetSweepingSettings.segmentColorSettings[0]?.streetSegmentAgingInterval.timeMeasurementType.id,
  };

  const handleSubmit = (formData: any) => {
    const drivingComplexityId = parseInt(formData.drivingComplexityType);
    const serviceConfigurationValues = formData.serviceSubTypes.concat(formData.nonServiceSubTypes);
    const unitConfigurationValues = [formData.waterFillUps, formData.loadsDumped];
    const newFormData = {
      ...omit(formData, 'displayConfiguration'),
      drivingComplexityType: {
        id: drivingComplexityId,
        name: DRIVING_COMPLEXITY_TYPES[drivingComplexityId].name,
        technicalName: DRIVING_COMPLEXITY_TYPES[drivingComplexityId].name,
      },
      serviceConfiguration: streetSweepingSettings?.serviceConfiguration.map((el: ServiceConfiguration) => ({
        ...el,
        isActive: serviceConfigurationValues.includes(el.alternativeFleetServiceType.technicalName),
      })),
      unitConfiguration: streetSweepingSettings?.unitConfiguration.map((el: UnitConfiguration, index) => ({
        ...el,
        isActive: unitConfigurationValues[index],
        unitOfMeasureType: formData.unitConfiguration[index].unitOfMeasureType,
        pictureRequirementType: PICTURE_REQUIREMENT_TYPES[formData.unitConfiguration[index].pictureRequirementType.id],
      })),
      segmentColorSettings: map(formData?.displayConfiguration.segmentColorSettings, (el: any) => {
        const id = el.streetSegmentAgingInterval.id;
        const enabled = el.enabled ? 1 : 0;
        const red = el.color ? el.color.r : el.red;
        const green = el.color ? el.color.g : el.green;
        const blue = el.color ? el.color.b : el.blue;

        return {
          ...omit(el, 'color'),
          enabled,
          red,
          green,
          blue,
          streetSegmentAgingInterval: {
            id,
            timeMeasurementType: {
              id: el.streetSegmentAgingInterval.timeMeasurementType.id,
            },
          },
        };
      }),
    };

    scrollToTopOfModal(streetSweepingModalId);

    saveStreetSweepingSettings(
      vendorId,
      newFormData as any,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.streetSweepingSettingSaved'));
        closeModal();
      })
      .catch(error => {
        const { code } = error;

        switch (code) {
          case 'StreetSegmentAgingIntervalMustBeConsecutive':
            createErrorNotification(translate('vendors.alertMessages.snowPlowSettingErrorIntervalMustBeConsecutive'));
            break;

          case 'ExactlyOneMandatoryEndingSegmentAgingIntervalMustBeSelected':
            createErrorNotification(translate('vendors.alertMessages.snowPlowSettingErrorAgingIntervalMustBeSelected'));
            break;
          case 'AtLeastOneServiceSubTypeAndOneNonServiceSubTypeMustBeSelected':
            createErrorNotification(
              translate('vendors.alertMessages.snowPlowSettingErrorServiceAndNonServiceSubTypes'),
            );
            break;

          default:
            createErrorNotification(translate('vendors.alertMessages.streetSweepingSettingError'));
            break;
        }
      });
  };

  const isLoadingModal = isLoading || isSaving || isLoadingDefaultSettings;

  return (
    <Modal
      size="smallMedium"
      title={translate('vendors.featureCodes.streetSweeping')}
      onClose={closeModal}
      padding="medium no no no"
      isLoading={isLoadingModal}
      id={streetSweepingModalId}
      overflow={isLoadingModal ? 'hidden' : 'auto'}
    >
      <StreetSweepingForm
        onCancel={closeModal}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        vendorId={vendorId}
      />
    </Modal>
  );
}
