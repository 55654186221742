import moment from 'moment';

import { PreventativeMaintenanceRawConfiguration } from '../interfaces/PreventativeMaintenance';

export const transformRawConfiguration = (
  configuration: PreventativeMaintenanceRawConfiguration,
): PreventativeMaintenanceRawConfiguration => {
  const newConfiguration = { ...configuration };

  if (!!newConfiguration.dateThresholdConfiguration) {
    const startAt =
      newConfiguration.dateThresholdConfiguration.startAt === '0001-01-01T00:00:00'
        ? moment().toString()
        : newConfiguration.dateThresholdConfiguration.startAt;

    newConfiguration.dateThresholdConfiguration.startAt = moment(startAt).format('MM/DD/YYYY');
  }

  return newConfiguration;
};
