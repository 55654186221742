import moment from 'moment';

import { MapGLPopup, MapGLPopupContent, MapGLPopupTitle } from 'src/common/components/map/MapGLPopup';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Label } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { getTypesAsString } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapRouteSegmentsGLPopup';
import { STREET_SEGMENT_SERVICE_SIDES } from 'src/customers/constants/streetNetwork';
import { AlternativeFleetStreetSegment } from 'src/dashboard/interfaces/alterativeFleetOps';
import { dateAndTime } from 'src/utils/services/formatter';

interface Props {
  segment: AlternativeFleetStreetSegment;
  isSnowPlow?: boolean;
}

export default function SnowSweeperStreetSegmentsGLPopup({ segment, isSnowPlow }: Props) {
  const routeSegment = useSelector(state => state.customers.streetNetwork.streetNetworkSegmentDetails) as any;

  let mostRecentPassDate;
  if (segment.lastForwardPassDateTime && segment.lastReversePassDateTime)
    mostRecentPassDate = moment(segment.lastForwardPassDateTime).isAfter(segment.lastReversePassDateTime)
      ? segment.lastForwardPassDateTime
      : segment.lastReversePassDateTime;
  else mostRecentPassDate = segment.lastForwardPassDateTime || segment.lastReversePassDateTime;

  if (!routeSegment) return null;

  const serviceSideTypeId = STREET_SEGMENT_SERVICE_SIDES.filter(side => side.id === routeSegment.serviceSideTypeId);

  return (
    <MapGLPopup minHeight={20}>
      <MapGLPopupTitle
        title={routeSegment.name || '-'}
        secondTitle={
          <Label color={routeSegment.isActive ? 'success' : 'info'}>
            {routeSegment.isActive
              ? translate('customers.streetNetwork.active')
              : translate('customers.streetNetwork.inactive')}
          </Label>
        }
      />
      <MapGLPopupContent>
        {!!routeSegment.streetId && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.streetID')}
            value={routeSegment.streetId}
          />
        )}
        {!!mostRecentPassDate && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('routes.snowPlow.mostRecentPass')}
            value={!!mostRecentPassDate ? dateAndTime(mostRecentPassDate) : '-'}
          />
        )}
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('routes.snowPlow.totalRecordedPasses')}
          value={(routeSegment.forwardPasses || 0) + (routeSegment.reversePasses + 0)}
        />
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('customers.streetNetwork.travelDirection')}
          value={translate(`customers.streetNetwork.${routeSegment.isOneWay ? 'oneWay' : 'twoWay'}`)}
        />
        {!!routeSegment.segmentWidth && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.segmentWidth')}
            value={routeSegment.segmentWidth}
          />
        )}
        {!!routeSegment.speedLimit && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.speedLimit')}
            value={routeSegment.speedLimit}
          />
        )}
        {!!serviceSideTypeId.length && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.serviceSide.serviceSide')}
            value={serviceSideTypeId[0].label}
          />
        )}
        {!!routeSegment.priorityTypeId && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.priority')}
            value={routeSegment.priorityTypeId}
          />
        )}
        {!!routeSegment.turnRestrictions && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.turnRestrictions')}
            value={routeSegment.turnRestrictions}
          />
        )}
        {!!routeSegment.streetSegmentServiceTypes && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.segmentType.segmentType')}
            value={getTypesAsString(routeSegment.streetSegmentServiceTypes)}
          />
        )}
        {!!routeSegment.streetSegmentServiceTypes && !isSnowPlow && (
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.sweeperSideType')}
            value={getTypesAsString(routeSegment.streetSegmentServiceTypes, true)}
          />
        )}
      </MapGLPopupContent>
    </MapGLPopup>
  );
}
