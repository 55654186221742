import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import translate from '../../core/services/translate';

export const uploadRoutesFile = (fileData: File, vendorId: number) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));
  return http.post('/routeImport/upload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadSampleRouteUploadTemplate = () =>
  downloadExcelFile('/routeImport/downloadTemplate', translate('dispatchBoard.routeUploader.sampleTemplate'));
