import { useCallback, useMemo, useState } from 'react';
import { InjectedFormProps, getFormValues } from 'redux-form';

import { Checkbox, Collapsible, SimpleInput } from 'src/core/components';
import {
  ExpandableMapFilterSection,
  ExpandableMapFilterSectionContent,
  ExpandableMapFilterSectionHeader,
  ExpandableMapFilterSectionIcon,
  ExpandableMapFilterSubSectionInline,
  ExpandableMapFilterSubSectionSearch,
  ExpandableMapFilterSubSectionSearchIcon,
} from '../styled/StreetNetwork';
import translate from 'src/core/services/translate';
import TypedField, { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { Text } from 'src/core/components/styled';
import { debounce, filter, reduce, some } from 'lodash-es';
import { multiWordAndSearch } from 'src/core/services/search';
import { useSelector } from 'src/core/hooks/useSelector';

type SubFilters = {
  name: string | number;
  label: string;
  rightLabel?: string;
}[];

type FiltersSectionProps = {
  change: InjectedFormProps['change'];
  filters?: {
    name: string;
    label: string;
    rightLabel?: string;
    justDisplayName?: boolean;
    subFilters?: SubFilters;
  }[];
  formName: string;
  hasCheckAll?: boolean;
  hasCheckAllPerFilter?: boolean;
  isOpen: boolean;
  name: string;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  noDataMessage?: string;
  searchAble?: boolean;
};

const ExpandableMapFilterSectionEnhanced = ({
  change,
  filters,
  hasCheckAll,
  hasCheckAllPerFilter,
  isOpen,
  name,
  setIsOpen,
  title,
  noDataMessage,
  searchAble,
  formName,
}: FiltersSectionProps) => {
  const thisFormValues = useSelector(getFormValues(formName)) as any;
  const thisSectionValues = thisFormValues?.[name] as any;

  const [searchTerm, setSearchTerm] = useState('');
  const filtersSize = useMemo(
    () => reduce(filters, (acc, filter) => acc + (filter.subFilters?.length || 1), 0),
    [filters],
  );

  const filteredFilters = useMemo(() => {
    if (!searchTerm || !searchAble) return filters;
    return filter(filters, filterItem =>
      filterItem.subFilters?.length ? true : multiWordAndSearch(filterItem.label, searchTerm),
    ).map(filterItem => {
      return filterItem.subFilters?.length
        ? {
            ...filterItem,
            subFilters: filter(filterItem.subFilters, subFilterItem =>
              multiWordAndSearch(subFilterItem.label, searchTerm),
            ),
          }
        : filterItem;
    });
  }, [filters, searchAble, searchTerm]);

  const handleSetSearchTerm = debounce((value: string) => setSearchTerm(value), 1200);

  const handleCheckParentFilter = useCallback(
    (_: any, isChecked: boolean, subFilters: SubFilters) => {
      // when checking a filter, we need to check all subfilters
      // when unchecking a filter, we need to uncheck all subfilters

      const newValues = subFilters.reduce((acc, subFilter) => {
        acc[`${subFilter.name}`] = isChecked;
        return acc;
      }, {} as any);
      change(_, {
        all: isChecked,
        ...newValues,
      });
    },
    [change],
  );

  const handleCheckSubFilter = useCallback(
    (_: any, isChecked: boolean, parentFilter: string, subFilters: SubFilters) => {
      // when checking a subfilter, we need to check if all subfilters are checked, and if so, check the main filter
      // when unchecking a subfilter, we need to uncheck the main filter if it was checked
      const parentFilterValue = thisSectionValues?.[parentFilter];

      const isAllChecked = parentFilterValue?.all;

      if (!isChecked) {
        isAllChecked && change(`${name}.${parentFilter}.all`, false);
      } else {
        const allSubFilters = parentFilterValue || {};

        const allSubFiltersChecked = subFilters.every(subFilter => allSubFilters[subFilter.name]);

        if (allSubFiltersChecked) {
          change(`${name}.${parentFilter}.all`, true);
        }
      }
    },
    [change, name, thisSectionValues],
  );

  const mappedFilters = useMemo(
    () =>
      filteredFilters?.map((filter, ind: number) => (
        <div key={`_${ind}`}>
          {filter.justDisplayName ? (
            <Text size="small" margin="xSmall no">
              {filter.label}
            </Text>
          ) : (
            <TypedField
              key={filter.name}
              name={`${name}.${filter.name}.all`}
              component={filter.label ? Checkbox : () => null}
              props={{
                label: filter.label,
                block: true,
                margin: 'xSmall no',
                size: 'small',
                labelRight: filter.rightLabel,
                withBottomBorder: true,
              }}
              onChange={(event, value, prev, fieldName) => {
                handleCheckParentFilter(`${name}.${filter.name}`, value, filter.subFilters || []);
              }}
            />
          )}
          {filter.subFilters && (
            <ExpandableMapFilterSubSectionInline noPaddingLeft={filter.justDisplayName}>
              {hasCheckAllPerFilter && (
                <TypedField
                  key={filter.name}
                  name={`${name}.${filter.name}.all`}
                  component={filter.label ? Checkbox : () => null}
                  props={{
                    label: translate('common.all'),
                    block: true,
                    margin: 'xSmall no',
                    size: 'small',
                    labelRight: some(filter.subFilters, 'rightLabel'),
                    withBottomBorder: true,
                  }}
                  onChange={(event, value, prev, fieldName) => {
                    handleCheckParentFilter(`${name}.${filter.name}`, value, filter.subFilters || []);
                  }}
                />
              )}
              {filter.subFilters.map(subFilter => (
                <TypedField
                  key={subFilter.name}
                  name={`${name}.${filter.name}.${subFilter.name}`}
                  component={Checkbox}
                  props={{
                    label: `${subFilter.label}`,
                    block: true,
                    margin: 'xSmall no',
                    size: 'small',
                    labelRight: subFilter.rightLabel,
                    withBottomBorder: true,
                  }}
                  onChange={(_, value, prev, fieldName) => {
                    handleCheckSubFilter(fieldName, value, `${filter.name}`, filter.subFilters || []);
                  }}
                />
              ))}
            </ExpandableMapFilterSubSectionInline>
          )}
        </div>
      )),
    [filteredFilters, handleCheckParentFilter, handleCheckSubFilter, hasCheckAllPerFilter, name],
  );

  if (!filters?.length) return null;

  const handleCheckAll: TypedFieldOnChangeFunction = (_: any, isChecked: boolean) => {
    filters &&
      filters.forEach((filter, index) => {
        change(`${name}[${filter.name}]`, isChecked);
      });
  };

  return (
    <>
      <ExpandableMapFilterSection isOpen={isOpen} hasMarginBottom>
        <ExpandableMapFilterSectionHeader onClick={() => setIsOpen(!isOpen)}>
          <ExpandableMapFilterSectionIcon /> {title}
        </ExpandableMapFilterSectionHeader>
        <ExpandableMapFilterSectionContent isVisible={isOpen}>
          <Collapsible isOpen={isOpen}>
            {searchAble && filtersSize > 5 && (
              <ExpandableMapFilterSubSectionSearch>
                <TypedField
                  name="searchTerm"
                  component={SimpleInput}
                  props={{
                    placeholder: translate('common.search'),
                    margin: 'xSmall no',
                  }}
                  onChange={e => handleSetSearchTerm(e.target.value)}
                />

                <ExpandableMapFilterSubSectionSearchIcon />
              </ExpandableMapFilterSubSectionSearch>
            )}
            {hasCheckAll && (
              <TypedField
                name={`${name}_checkAll`}
                component={Checkbox}
                onChange={handleCheckAll}
                props={{
                  label: translate('common.all'),
                  block: true,
                  margin: 'xSmall no',
                  size: 'small',
                  withBottomBorder: true,
                }}
              />
            )}

            {mappedFilters}

            {!filters?.length && (
              <Text color="grayDarker" margin="xSmall no" size="small" block>
                <em>{!!noDataMessage ? noDataMessage : translate('containers.noDataAvailable')}</em>
              </Text>
            )}
            {searchAble && !filteredFilters?.length && (
              <Text color="grayDarker" margin="xSmall no" size="small" block>
                <em>{translate('routes.planner.noDataMatches')}</em>
              </Text>
            )}
          </Collapsible>
        </ExpandableMapFilterSectionContent>
      </ExpandableMapFilterSection>
    </>
  );
};

export default ExpandableMapFilterSectionEnhanced;
