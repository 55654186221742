export const ROUTE_MAP_APPLICATION_STATUS_LAYER = 'routeMapApplicationStatusLayer';
export const ROUTE_MAP_CITY_INSIGHTS_LAYER = 'routeMapCityInsightsLayer';
export const ROUTE_MAP_CLUSTERS_CLUSTER_COUNTERS_LAYER = 'routeMapClustersCountersLayer';
export const ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER = 'routeMapClustersLayer';
export const ROUTE_MAP_CLUSTERS_SOURCE = 'routeMapClustersSource';
export const ROUTE_MAP_GEO_FENCES_SOURCE = 'routeMapGeoFencesSource';
export const ROUTE_MAP_HAULER_LOCATIONS_LAYER = 'routeMapHaulerLocationsLayer';
export const ROUTE_MAP_ROUTE_SEGMENTS_SOURCE = 'routeMapRouteSegmentsSource';
export const ROUTE_MAP_ROUTE_STOPS_LAYER = 'routeMapRouteStopsLayer';
export const ROUTE_MAP_VEHICLE_INSIGHTS_LAYER = 'routeMapVehicleInsightsLayer';
export const ROUTE_MAP_VEHICLE_POSITIONS_LAYER = 'routeMapVehiclePositionsLayer';
export const ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE = 'routeMapVehicleTrackingsSource';
export const ROUTE_MAP_VEHICLE_TRACKINGS_POINTS_SOURCE = 'routeMapVehicleTrackingsPointsSource';
export const ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_SOURCE = 'routeMapTimelineVehiclePositionsSource';
export const ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER = 'routeMapTimelineVehiclePositionsLayer';
export const ROUTE_MAP_TRAVEL_PATH_SOURCE = 'routeMapTravelPathSource';
export const ROUTE_MAP_TRAVEL_PATH_LINES_LAYER = 'routeMapTravelPathLinesLayer';
export const ROUTE_MAP_TRAVEL_PATH_ARROWS_SOURCE = 'routeMapTravelPathArrowsSource';
export const ROUTE_MAP_TRAVEL_PATH_ARROWS_LAYER = 'routeMapTravelPathArrowsLayer';
export const ROUTE_MAP_TRAVEL_PATH_DASHED_LINES_LAYER = 'routeMapTravelPathDashedLinesLayer';
export const ROUTE_MAP_TRAVEL_PATH_SEQUENCE_LAYER = 'routeMapTravelPathSequenceLayer';
export const ROUTE_MAP_CITY_ALERTS_LAYER = 'routeMapCityAlertsSource';
