import { FieldArray } from 'redux-form';

import { Box } from 'src/core/components/styled/Box';
import { date } from 'src/utils/services/formatter';
import { ModalSection, Text } from 'src/core/components/styled';
import { RateFormProps } from 'src/finance/interfaces/ServiceRates';
import { Table } from 'src/core/components';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { TableCell } from 'src/core/components/Table';
import BillFormTableRow from './BillFormTableRow';
import translate from 'src/core/services/translate';

const ratesTableCellWidths = {
  description: '25%',
  added: '10%',
  overridable: '12%',
  rate: '10%',
  amount: '10%',
  uom: '13%',
  quantity: '10%',
  action: '10px',
};

const rateCodeModalTableCells: TableCell[] = [
  {
    name: 'description',
    label: translate('finance.rateManager.rateCodeAndDescription'),
    width: ratesTableCellWidths.description,
    sortable: false,
  },
  {
    name: 'added',
    label: translate('finance.modal.added'),
    width: ratesTableCellWidths.added,
    sortable: false,
  },
  {
    name: 'overridable',
    label: translate('finance.modal.overridable'),
    width: ratesTableCellWidths.overridable,
    sortable: false,
  },
  {
    name: 'rate',
    label: translate('finance.modal.rate'),
    width: ratesTableCellWidths.rate,
    sortable: false,
  },
  {
    name: 'quantity',
    label: translate('finance.modal.qty'),
    width: ratesTableCellWidths.quantity,
    sortable: false,
  },
  {
    name: 'uom',
    label: translate('finance.modal.uom'),
    width: ratesTableCellWidths.uom,
    sortable: false,
  },
  {
    name: 'amount',
    label: translate('finance.modal.amount'),
    width: ratesTableCellWidths.amount,
    sortable: false,
  },
];

const getTableHeight = (ratesLength: number): number =>
  Math.min(ratesLength * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1;

const BillingTableForm = ({
  customerName,
  handleRemoveRate,
  isBillReleased,
  location,
  workOrderDate,
  workOrderId,
}: RateFormProps) => (
  <>
    <ModalSection flex flexDirection="row" margin="medium auto" overflow="visible">
      <Box margin="no medium no no">
        <Text block size="small">
          {translate('finance.modal.customerName')}
        </Text>
        <Text block size="medium" margin="xSmall no no no">
          {customerName || '-'}
        </Text>
      </Box>

      <Box margin="no medium">
        <Text block size="small">
          {translate('finance.modal.location')}
        </Text>
        <Text block size="medium" margin="xSmall no no no">
          {location || '-'}
        </Text>
      </Box>

      <Box margin="no medium">
        <Text block size="small">
          {translate('finance.modal.workOrderId')}
        </Text>
        <Text block size="medium" margin="xSmall no no no">
          {workOrderId || '-'}
        </Text>
      </Box>

      <Box margin="no no no medium">
        <Text block size="small">
          {translate('finance.modal.scheduleDate')}
        </Text>
        <Text block size="medium" margin="xxSmall no">
          {workOrderDate ? date(workOrderDate) : '-'}
        </Text>
      </Box>
    </ModalSection>

    <ModalSection overflow="visible" margin={isBillReleased ? 'no no grande no' : 'no'}>
      <Table cells={rateCodeModalTableCells} tableBodyProps={{ height: getTableHeight(6), noOverflow: true }}>
        <FieldArray
          name="rates"
          component={BillFormTableRow as any}
          handleRemoveRate={handleRemoveRate}
          cellWidths={ratesTableCellWidths}
          isBillReleased={isBillReleased}
        />
      </Table>
    </ModalSection>
  </>
);

export default BillingTableForm;
