import styled, { css } from 'styled-components';

const HorizontalRule = styled.span<{ text?: string }>`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0px 0px 15px;
  display: block;
  ${props =>
    props.text &&
    css`
      text-align: center;
      border: none;
    `};
`;

export default HorizontalRule;
