import React, { Fragment, useState } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { ActionRequiredContainer } from '../../../fleet/components/styled/RubiconDispatches';
import { DATE_RANGE_PICKER_TODAY_7_FUTURE } from '../../../core/constants/weekdays';
import { DateRangePicker, PanelSearch, MultiSelect, Switch, TypedField } from '../../../core/components';
import { Grid, GridColumn, PanelSection, PanelSectionGroup, Text, ToggleIcon } from '../../../core/components/styled';
import { REQUEST_TYPE_LIST } from '../../constants/requestType';
import translate from '../../../core/services/translate';
import { useIsMobileView } from 'src/utils/hooks';
import { ToggleContainer } from '../../../routes/components/styled';

interface FormValues {
  dateRange: { from: string; to: string };
  divisions: string[];
  equipmentSubTypeIds: string[];
  isActionRequired: boolean;
  materialTypeIds: string[];
  requestTypeIds: number[];
  searchTerm: string;
}
interface PropsWithoutReduxForm {
  disabledDivisionIds?: string[];
  disabledEquipmentSubTypeIds?: string[];
  disabledMaterialTypeIds?: string[];
  disabledServiceIds?: number[];
  disabledDateRange?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const OpportunitiesPageFilterForm: React.FC<Props> = ({
  disabledDivisionIds = [],
  disabledEquipmentSubTypeIds = [],
  disabledMaterialTypeIds = [],
  disabledServiceIds = [],
  disabledDateRange,
  handleSubmit,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const equipmentSubTypes = useSelector((state: AppState) => state.opportunity.opportunities.equipmentTypes);
  const materialTypes = useSelector((state: AppState) => state.opportunity.opportunities.materialTypes);
  const divisions = useSelector((state: AppState) => state.opportunity.opportunities.divisions);

  const notificationsCount = useSelector(
    (state: AppState) => state.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts,
  );
  const isMobile = useIsMobileView();

  const { bidOpportunities, opportunities } = notificationsCount;
  const actionableOpportunitiesCount = bidOpportunities + opportunities;

  const onToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Fragment>
        <PanelSectionGroup>
          <PanelSection padding="small xSmall" withBorder={!isMobile}>
            <Grid multiLine column={isMobile}>
              {!isMobile && (
                <GridColumn size="3/12">
                  <TypedField name="searchTerm" component={PanelSearch} />
                </GridColumn>
              )}

              <GridColumn size={isMobile ? '12/12' : '3/12'}>
                <TypedField
                  name="dateRange"
                  component={DateRangePicker}
                  props={{
                    hasMarginLeft: 'small',
                    options: DATE_RANGE_PICKER_TODAY_7_FUTURE,
                    disabledDays: [
                      {
                        before: moment().subtract(12, 'weeks').toDate(),
                      },
                      {
                        after: moment().add(12, 'weeks').toDate(),
                      },
                    ],
                    disabled: disabledDateRange,
                    maxInterval: { amount: 90, unit: 'days' },
                    numberOfMonths: isMobile ? 1 : undefined,
                    tabletAlignLeft: true,
                    margin: isMobile ? 'small' : 'no',
                  }}
                />
              </GridColumn>

              <GridColumn size={isMobile ? '12/12' : '3/12'}>
                <TypedField
                  name="requestTypeIds"
                  component={MultiSelect}
                  props={{
                    margin: isMobile ? 'small' : 'no',
                    placeholder: translate('common.requestTypes'),
                    options: REQUEST_TYPE_LIST.map(service => ({
                      label: service.name,
                      value: service.id,
                      isDisabled: disabledServiceIds.indexOf(service.id) !== -1,
                    })),
                    formatText(selectedOptions: any[], allSelected: boolean) {
                      return allSelected
                        ? translate('common.allRequestTypes')
                        : translate('common.xRequestTypesSelected', { selected: selectedOptions.length });
                    },
                    normalizeValues(value: string | number) {
                      return +value;
                    },
                    disabled: disabledDateRange,
                  }}
                />
              </GridColumn>

              {!isMobile && (
                <GridColumn size={isMobile ? '12/12' : '1/12'}>
                  <ToggleContainer id="toggle-filter-button" onClick={onToggle}>
                    <ToggleIcon size={10} isOpen={isExpanded} icon={isExpanded ? 'remove' : 'add'} />
                    {` ${translate('common.filter')}`}
                  </ToggleContainer>
                </GridColumn>
              )}
              <GridColumn size={isMobile ? '12/12' : '2/12'}>
                <ActionRequiredContainer>
                  <Text block size="small" weight="medium" margin="no no xxSmall">
                    {translate('autoDispatch.actionRequired')}
                  </Text>
                  <TypedField
                    name="isActionRequired"
                    component={Switch}
                    props={{
                      label: translate('opportunity.opportunities.opportunitiesCount', {
                        count: actionableOpportunitiesCount,
                      }),
                      margin: isMobile ? 'small' : 'no',
                      color: 'alert',
                    }}
                  />
                </ActionRequiredContainer>
              </GridColumn>
              {isMobile ||
                (isExpanded && (
                  <>
                    <GridColumn size={isMobile ? '12/12' : '3/12'}>
                      <TypedField
                        name="equipmentSubTypeIds"
                        component={MultiSelect}
                        props={{
                          margin: isMobile ? 'small' : 'no',
                          placeholder: translate('common.equipments'),
                          options: equipmentSubTypes.map(type => ({
                            label: type.name,
                            value: type.id,
                            isDisabled: disabledEquipmentSubTypeIds.indexOf(type.id) !== -1,
                          })),
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('common.allEquipments')
                              : translate('common.xEquipmentsSelected', { selected: selectedOptions.length });
                          },
                          isSearchable: true,
                        }}
                      />
                    </GridColumn>

                    <GridColumn size={isMobile ? '12/12' : '3/12'}>
                      <TypedField
                        name="materialTypeIds"
                        component={MultiSelect}
                        props={{
                          margin: isMobile ? 'small' : 'no',
                          placeholder: translate('common.materials'),
                          options: materialTypes.map(material => ({
                            label: material.name,
                            value: material.id,
                            isDisabled: disabledMaterialTypeIds.indexOf(material.id) !== -1,
                          })),
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('common.allMaterialTypes')
                              : translate('common.xMaterialTypesSelected', { selected: selectedOptions.length });
                          },
                          isSearchable: true,
                        }}
                      />
                    </GridColumn>
                    <GridColumn size={isMobile ? '12/12' : '3/12'}>
                      <TypedField
                        name="divisionIds"
                        component={MultiSelect}
                        props={{
                          margin: isMobile ? 'small' : 'no',
                          placeholder: translate('autoDispatch.divisions'),
                          options: divisions.map((division: any) => ({
                            label: `${division.id} (${division.name})`,
                            value: division.id,
                            isDisabled: disabledDivisionIds.indexOf(division.includes) !== -1,
                          })),
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('autoDispatch.allDivisions')
                              : translate('common.xDivisionsSelected', { selected: selectedOptions.length });
                          },
                          isSearchable: true,
                        }}
                      />
                    </GridColumn>
                  </>
                ))}
            </Grid>
          </PanelSection>
        </PanelSectionGroup>
      </Fragment>
    </form>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'opportunitiesPageFilter',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(OpportunitiesPageFilterForm);
