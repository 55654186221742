import { useMemo } from 'react';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { getFeatureCollection } from 'src/common/components/map/util';
import { VEHICLE_ICON_TYPES } from 'src/common/constants/vehicleIcons';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehiclePosition } from 'src/routes/components/mapWithTimeline/Interfaces';
import YRouteMapVehiclePositionsGLPopup from '../../../../yRoute/yRoutePageSections/yRouteMap/YRouteMapVehiclePositionsGLPopup';
import { ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';
import RouteMapVehiclePositionsGLPopup from '../vehiclePositions/RouteMapVehiclePositionsGLPopup';
import { getVehiclePositionsGeoJSON, getYVehiclePositionsGeoJSON } from '../vehiclePositions/utils';
import RouteMapTimelineVehiclePositionSource from './RouteMapTimelineVehiclePositionSource';

const mapImages = VEHICLE_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: ROUTE_MAP_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
  isYRoute?: boolean;
  currentVehiclePositions?: VehiclePosition[];
};

export default function RouteMapTimeLineVehiclePositions({ map, isYRoute, currentVehiclePositions }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);

  const { sosAlertVehicleId } = useSelector(state => state.routes.mapControls);
  const vehicleTypeName = useSelector(state => state.routes.route.route?.vehicleTypeName);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const geoJSON = useMemo(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);
    if (currentVehiclePositions && currentVehiclePositions.length) {
      const isPlayBackMode = true;
      const vehiclePositionsCollection = isYRoute
        ? getYVehiclePositionsGeoJSON(currentVehiclePositions as any, vehicleTypeName, isPlayBackMode)
        : getVehiclePositionsGeoJSON(currentVehiclePositions, routeSummary?.vehicleTypeName, isPlayBackMode);
      collection.features = collection.features.concat(vehiclePositionsCollection.features);
    }
    return collection;
  }, [currentVehiclePositions, isYRoute, vehicleTypeName, routeSummary?.vehicleTypeName]);

  return (
    <>
      <RouteMapTimelineVehiclePositionSource geoJSON={geoJSON} sosAlertVehicleId={sosAlertVehicleId} />
      {isYRoute ? (
        <YRouteMapVehiclePositionsGLPopup />
      ) : (
        <RouteMapVehiclePositionsGLPopup currentVehiclePositions={currentVehiclePositions} />
      )}
    </>
  );
}
