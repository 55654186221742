import { Message } from 'src/core/components/styled';
import { ServiceWorkOrder } from 'src/customers/interfaces/Services';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants/table';
import Table, { TableCell as TableCellInterface } from 'src/core/components/Table';
import translate from 'src/core/services/translate';
import WorkOrdersTableRow from './WorkOrdersTableRow';

const woTableCellWidths = {
  // common
  date: '15%',
  workOrderNumber: '15%',
  // Financial
  workFlow: '20%',
  route: '25%',
  serviceStatusId: '25%',
  // Operational
  invoicedStatus: '35%',
  amount: '35%',
};

interface Props {
  showOperationalWorkOrders: boolean;
  workOrders: ServiceWorkOrder[];
}

const WorkOrdersTable = ({ showOperationalWorkOrders, workOrders }: Props) => {
  const workOrdersTableCells: TableCellInterface[] = [
    {
      name: 'date',
      label: translate('common.date'),
      width: woTableCellWidths.date,
      sortable: true,
    },
    {
      name: 'workOrderNumber',
      label: translate('customers.serviceEditor.workOrderNo'),
      width: woTableCellWidths.workOrderNumber,
      sortable: true,
    },
  ];

  if (showOperationalWorkOrders) {
    workOrdersTableCells.push(
      {
        name: 'workFlow',
        label: translate('customers.serviceEditor.workFlow'),
        width: woTableCellWidths.workFlow,
        sortable: true,
      },
      {
        name: 'routeName',
        label: translate('routes.routeName'),
        width: woTableCellWidths.route,
        sortable: true,
      },
      {
        name: 'serviceStatusId',
        label: translate('common.serviceStatus'),
        width: woTableCellWidths.serviceStatusId,
        sortable: true,
      },
    );
  } else {
    workOrdersTableCells.push(
      {
        name: 'invoicedStatus',
        label: translate('customers.serviceEditor.invoiceStatus'),
        width: woTableCellWidths.invoicedStatus,
        sortable: true,
      },
      {
        name: 'amount',
        label: translate('finance.amount'),
        width: woTableCellWidths.amount,
        align: 'center',
        sortable: true,
      },
    );
  }

  return (
    <>
      {workOrders.length ? (
        <Table
          cells={workOrdersTableCells}
          rowComponent={WorkOrdersTableRow}
          rows={workOrders}
          rowProps={{
            cellWidths: woTableCellWidths,
            showOperationalWorkOrders,
          }}
          withClickableRows
          scrollMarker
          virtualized
          virtualizedProps={{
            height: Math.min(workOrders.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
            itemSize: TABLE_ROW_HEIGHT_SMALL,
          }}
        />
      ) : (
        <Message padding="sMedium xSmall sMedium no">{translate('customers.serviceEditor.noWorkOrdersFound')}</Message>
      )}
    </>
  );
};

export default WorkOrdersTable;
