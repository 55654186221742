import { MouseEvent } from 'react';

import { Box } from 'src/core/components/styled/Box';
import { IconButton, IconButtonIcon, Text, Popover } from 'src/core/components/styled';
import { InfoIcon, PillAttributeWrapper, PillDraggable } from 'src/routes/components/styled';
import { PopoverWrapper } from 'src/core/components';
import { TOP } from 'src/core/constants';
import createPopover from 'src/core/services/createPopover';
import translate from 'src/core/services/translate';

type Props = {
  isDriverUnavailable?: boolean;
  isVehicleUnavailable?: boolean;
  margin?: string;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  onRemoveAttribute?: () => void;
  showTooltip?: boolean;
  subtitle?: string;
  title: string;
  tooltipText?: string;
};

const PillAttribute = ({
  isDriverUnavailable,
  isVehicleUnavailable,
  margin,
  onDragEnd,
  onDragStart,
  onRemoveAttribute,
  showTooltip,
  subtitle,
  title,
  tooltipText,
}: Props) => {
  let closePopover = () => {};

  const openPopover = (event: MouseEvent<HTMLSpanElement>, content?: any) => {
    event.stopPropagation();
    if (content && content.length >= 17 && showTooltip)
      closePopover = createPopover(
        event.currentTarget,
        Text,
        { size: 'small', color: 'grayDarkest', weight: 'medium', children: content },
        { position: TOP, size: 'small' },
      );
  };

  const isUnavailable = isDriverUnavailable || isVehicleUnavailable;
  const isDraggable = !isDriverUnavailable && !isVehicleUnavailable;

  return (
    <PillAttributeWrapper
      margin={margin}
      onMouseEnter={e => openPopover(e, tooltipText)}
      onMouseLeave={() => closePopover()}
      isUnavailable={isUnavailable}
    >
      {onDragStart && (
        <PillDraggable
          draggable={isDraggable}
          onDragStart={() => {
            onDragStart();
            closePopover();
          }}
          onDragEnd={onDragEnd}
          onMouseLeave={() => closePopover()}
        />
      )}
      <Box width={isUnavailable ? '75%' : '85%'}>
        <Text block singleLine onMouseLeave={() => closePopover()} weight="normal">
          {title}
        </Text>
        {subtitle && (
          <Text block singleLine onMouseLeave={() => closePopover()}>
            {subtitle}
          </Text>
        )}
      </Box>

      {onRemoveAttribute && (
        <Box display="inline-flex" alignItems="center">
          <IconButton onClick={onRemoveAttribute} color="gray">
            <IconButtonIcon icon="close" />
          </IconButton>

          {isUnavailable && (
            <PopoverWrapper
              margin="no no no xxSmall"
              triggerButton={<InfoIcon />}
              popoverContent={<Popover>{translate('common.unavailable')}</Popover>}
              size="small"
            />
          )}
        </Box>
      )}
    </PillAttributeWrapper>
  );
};

export default PillAttribute;
