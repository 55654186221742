import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { TableActionButton, TableCell, TableRow } from '../../core/components/styled';
import { ActionButtonTooltip } from '../../core/components';
import { VehicleInspection } from '../../fleet/interfaces/VehicleInspections';
import { exportTripInspectionReportV2 } from '../../fleet/services/vehicleInspections';
import { currentVendorId } from '../../vendors/services/currentVendorSelector';
import { DriverInspectionLog } from 'src/fleet/interfaces/DriverInspectionLog';
import { exportDriverInspectionLog } from 'src/fleet/services/driverInspection';

type Props = { driverId?: number; driver?: string } & VehicleInspection & DriverInspectionLog;

const InspectionLogsTableRow: React.FC<Props> = ({
  id,
  driverId,
  vehicleId,
  routeDate,
  date,
  driverName,
  driver,
  endTime,
  numberOfIssues,
  startTime,
  tripInspectionType,
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const exportReport = () => {
    if (!vendorId) {
      return;
    }
    if (routeDate) {
      exportTripInspectionReportV2(vendorId, id);
    } else {
      exportDriverInspectionLog(vendorId, id);
    }
  };

  const formatedStartTime = startTime ? moment(startTime, 'hh:mm:ss').format('LT') : '-';
  const formatedEndTime = endTime ? moment(endTime, 'hh:mm:ss').format('LT') : '-';
  const inspectionDate = moment(date).format('MM/DD/YYYY');

  return (
    <TableRow>
      <TableCell
        width={`${routeDate ? '13%' : '15%'}`}
        padding="defaultCellVertical defaultCellHorizontal defaultCellVertical medium"
      >
        {routeDate || inspectionDate}
      </TableCell>

      <TableCell width="20%">{driverName || driver}</TableCell>

      {tripInspectionType && <TableCell width="15%">{tripInspectionType}</TableCell>}

      <TableCell width={`${routeDate ? '10%' : '15%'}`} align="center">
        {formatedStartTime}
      </TableCell>

      <TableCell width={`${routeDate ? '10%' : '15%'}`} align="center">
        {formatedEndTime}
      </TableCell>

      <TableCell width={`${routeDate ? '12%' : '15%'}`} align="center">
        {numberOfIssues}
      </TableCell>

      <TableCell
        width="20%"
        align="right"
        padding="defaultCellVertical medium defaultCellVertical defaultCellHorizontal"
      >
        <TableActionButton
          color="grayDarker"
          margin="no small no no"
          onClick={() =>
            dispatch(
              driverId
                ? push(`/fleet/resources/${driverId}/driverInspectionLog/${id}`)
                : push(`/fleet/vehicles/${vehicleId}/inspection-data/${id}`),
            )
          }
        >
          <ActionButtonTooltip iconSize="mMedium" icon="redeye" tooltip="viewDetails" />
        </TableActionButton>

        <TableActionButton color="grayDarker" onClick={exportReport}>
          <ActionButtonTooltip iconSize="sMedium" icon="download" tooltip="downloadReport" />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
};

export default InspectionLogsTableRow;
