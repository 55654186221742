import { http } from '../../core/services/http';
import transformLoadTripConfiguration from './transformTripConfiguration';

export const loadTripConfigurationForVendor = (vendorId: number, tripInspectionTypeId: number, vehicleTypeId: number) =>
  http
    .get(`vendors/${vendorId}/tripInspectionSettings`, {
      params: {
        tripInspectionTypeId,
        vehicleTypeId,
      },
    })
    .then(response => transformLoadTripConfiguration(response.data));

export const saveTripConfigurationsForVendor = (
  vendorId: number,
  tripConfigurations: any,
  tripInspectionTypeId: number,
  vehicleTypeId?: number,
) =>
  http
    .post(`/vendors/${vendorId}/tripInspectionSettings`, tripConfigurations, {
      params: {
        tripInspectionTypeId,
        vehicleTypeId,
      },
    })
    .then(response => response.data);
