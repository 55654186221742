import { DriverPickListDefaults } from '../interfaces/DriverPickListDefaults';
import { http } from '../../core/services/http';

export const loadDriverPickListDefaults = () =>
  http.get('/vendors/vendorDriverPickListDefaults').then(response => response.data);

export const saveDriverPickListDefaults = (driverPickListDefaults: DriverPickListDefaults[]) =>
  http
    .post('/vendors/vendorDriverPickListDefaults', driverPickListDefaults)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
