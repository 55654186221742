import { camelize } from 'humps';
import { map, upperFirst, omit, size } from 'lodash-es';
import moment from 'moment';

import { BULK, PORTABLE_TOILET, PORTABLE_RESTROOM_TRAILER } from '../../common/constants';
import { RouteTemplate } from '../interfaces/RouteTemplates';
import { WEEKDAYS_BY_ID } from '../../core/constants';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';
import { OPTIMIZED_JOB_POSITION_ID } from 'src/core/constants/jobPositionOptions';

const getEquipmentSize = (equipmentSize: string, equipmentName: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string, serviceTypeName: string, equipmentName: string) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

export const getServiceName = ({
  equipmentTypeTechnicalName,
  equipmentType,
  equipmentSizeTechnicalName,
  equipmentSize,
  serviceTypeName,
  wasteMaterialTypeTechnicalName,
}: any) => {
  const equipmentTypeShortCode = translate(
    createTranslationKey(equipmentTypeTechnicalName, 'common.equipmentTypeShortCodes'),
  );
  const equipmentSizeShortCode = translate(
    getEquipmentSizeTranslationKey(equipmentSizeTechnicalName, serviceTypeName, equipmentType),
    {
      size: getEquipmentSize(equipmentSize, equipmentSizeTechnicalName),
    },
  );
  const wasteMaterialTypeName = translate(createTranslationKey(wasteMaterialTypeTechnicalName, 'common.wasteTypes'));

  return `${equipmentSizeShortCode} - ${equipmentTypeShortCode} - ${wasteMaterialTypeName}`;
};

export const transformLoadRouteTemplate = ({
  locations,
  startDate,
  scheduledDay,
  vehicleTypeTechnicalName,
  ...routeTemplate
}: any) => ({
  routeLocations: [],
  ...routeTemplate,
  startDate: startDate && moment(startDate).format('MM/DD/YYYY'),
  scheduledDay: WEEKDAYS_BY_ID[scheduledDay].shortCode,
  vehicleTypeName: translate(createTranslationKey(vehicleTypeTechnicalName, 'vehicles.vehicleTypes')),
});

export const transformLoadOptimizedRouteTemplate = ({ limit, locations, page, total }: any) => ({
  limit,
  locations: map(locations, ({ orderNo, routeLocationAccountTypeId, serviceContract, customer, ...routeLocation }) => ({
    ...routeLocation,
    customer: { name: customer.name, id: customer.id },
    orderNumber: orderNo,
    accountStatusId: routeLocationAccountTypeId,
    service: serviceContract && {
      id: serviceContract.id,
      name: getServiceName(serviceContract),
      serviceContractBinDetails: serviceContract.serviceContractBinDetails,
    },
  })),
  page,
  total,
});

export const transformUpdateRouteTemplate = (routeTemplate: RouteTemplate) => ({
  ...omit(routeTemplate, 'locations'),
  noOfStops: size(routeTemplate.locations),
  scheduledDay: routeTemplate.scheduledDay,
  serviceContractRouteTemplates: map(
    routeTemplate.locations,
    ({ service, orderNumber, serviceContractRouteTemplateId, pickupTypeId, positionTypeId }) => ({
      serviceContractId: service.id,
      orderNo: orderNumber,
      id: serviceContractRouteTemplateId,
      pickupTypeId,
      isOptimal: positionTypeId === OPTIMIZED_JOB_POSITION_ID,
    }),
  ),
});

export const transformCreateRouteTemplate = (routeTemplate: RouteTemplate) => ({
  ...omit(routeTemplate, 'locations'),
  noOfStops: size(routeTemplate.locations),
  scheduledDay: routeTemplate.scheduledDay,
  serviceContractRouteTemplates: map(routeTemplate.locations, ({ service, orderNumber, pickupTypeId }) => ({
    serviceContractId: service.id,
    orderNo: orderNumber,
    pickupTypeId,
  })),
});

export const transformTransferRouteTemplateLocations = (
  sourceRouteTemplateId: number,
  targetRouteTemplateId: number,
  routeTemplateLocations: any[],
  positionTypeId: number,
) => ({
  sourceRouteTemplateId,
  targetRouteTemplateId,
  serviceContractIds: map(routeTemplateLocations, ({ service }) => service.id).toString(),
  positionTypeId,
});
