import { createSelector } from 'reselect';
import { filter, identity } from 'lodash-es';

import { FLEET_INSIGHTS, SNOW_PLOW_INSIGHTS, STREET_SWEEPING_INSIGHTS, WASTE_AUDIT_INSIGHTS } from '../constants';

interface InsightDashboardPreferences {
  insightDashboardPreferences: any[];
}

const getDashboardActiveCategory = (insightDashboardPreferencesState: InsightDashboardPreferences) => {
  const activeFleetInsights = filter(
    insightDashboardPreferencesState.insightDashboardPreferences,
    preference => preference.reportCategory === FLEET_INSIGHTS && preference.isActive === true,
  );
  const activeStreetSweepingInsights = filter(
    insightDashboardPreferencesState.insightDashboardPreferences,
    preference => preference.reportCategory === STREET_SWEEPING_INSIGHTS && preference.isActive === true,
  );
  const activeWasteAuditInsights = filter(
    insightDashboardPreferencesState.insightDashboardPreferences,
    preference => preference.reportCategory === WASTE_AUDIT_INSIGHTS && preference.isActive === true,
  );
  const activeSnowPlowInsights = filter(
    insightDashboardPreferencesState.insightDashboardPreferences,
    preference => preference.reportCategory === SNOW_PLOW_INSIGHTS && preference.isActive === true,
  );

  const fleetInsights = activeFleetInsights.length > 0;
  const snowPlowInsights = activeSnowPlowInsights.length > 0;
  const streetSweepingInsights = activeStreetSweepingInsights.length > 0;
  const wasteAuditInsights = activeWasteAuditInsights.length > 0;

  return {
    [FLEET_INSIGHTS]: fleetInsights,
    [SNOW_PLOW_INSIGHTS]: snowPlowInsights,
    [STREET_SWEEPING_INSIGHTS]: streetSweepingInsights,
    [WASTE_AUDIT_INSIGHTS]: wasteAuditInsights,
  };
};

export const dashboardActiveCategorySelector = createSelector(getDashboardActiveCategory, identity);
