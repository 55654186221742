import React, { PureComponent } from 'react';

import { dateAndTime } from '../../../utils/services/formatter';
import { Modal } from '../../../core/components';
import { Button, Grid, GridColumn, ModalSection, Text } from '../../../core/components/styled';
import { ImageSelectButtonContainer } from '../styled/UnassignedWeightTickets';
import {
  ImageSlider as ImageSliderContainer,
  ImageSliderContent,
  ImageSliderImage,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface Props {
  isModalView?: boolean;
  weightTicketDetails: any;
  closeModal: () => void;
  onSelectClick?(fileId: number, fileName: string): void;
}

class WeightTicketsPreviewImageModal extends PureComponent<Props> {
  onSelectClick = (fileId: number, fileName: string) => {
    if (this.props.onSelectClick) this.props.onSelectClick(fileId, fileName);
  };
  render() {
    const { isModalView, closeModal, weightTicketDetails } = this.props;
    const { id, url, driverName, timeStamp, mobileNumber, fileName } = weightTicketDetails;
    return (
      <Modal onClose={closeModal} padding="medium" size="medium">
        <ModalSection align="center">
          <Grid>
            <GridColumn padding="xxSmall" size="12/12" align="center">
              {!!mobileNumber && (
                <Text size="medium" margin="no no xSmall">
                  {translate('common.phone')} : {mobileNumber}
                </Text>
              )}
              <br />
              {!!driverName && (
                <Text size="small" margin="medium">
                  {translate('drivers.driverName')} : {driverName}
                </Text>
              )}
            </GridColumn>
          </Grid>
          <Grid>
            <GridColumn padding="xxSmall" size="12/12" align="center">
              <Text size="small" margin="no no xSmall">
                {dateAndTime(timeStamp)}
              </Text>
            </GridColumn>
          </Grid>
          <ImageSliderContainer>
            <ImageSliderContent>
              <ImageSliderImage src={url} />
            </ImageSliderContent>

            {isModalView && (
              <ImageSelectButtonContainer>
                <Button color="primary" onClick={() => this.onSelectClick(id, fileName)}>
                  {translate('tooltips.select')}
                </Button>
              </ImageSelectButtonContainer>
            )}
          </ImageSliderContainer>
        </ModalSection>
      </Modal>
    );
  }
}

export default WeightTicketsPreviewImageModal;
