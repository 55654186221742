import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CustomerLocation } from '../interfaces/Customers';
import doLoadLocation from '../services/loadLocation';

// Actions
const START_LOAD = 'customers/location/START_LOAD';
const COMPLETE_LOAD = 'customers/location/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/location/FAIL_LOAD';
const RESET = 'customers/location/RESET';

interface State {
  isLoading: boolean;
  location?: CustomerLocation;
}

// Initial state
const initialState: State = {
  isLoading: false,
  location: undefined,
};

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          location: action.location,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          location: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (location: CustomerLocation) => ({
  type: COMPLETE_LOAD,
  location,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadLocation = (locationId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadLocationPromise = doLoadLocation(locationId);
  loadLocationPromise.then(location => dispatch(completeLoad(location))).catch(() => dispatch(failLoad()));
  return loadLocationPromise;
};

export const resetLocation = () => ({
  type: RESET,
});
