import { http } from '../../core/services/http';
import { VehicleNotificationsConfiguration, VehicleNotificationsPayload } from '../interfaces/VehicleNotifications';
import {
  transformRawVehicleNotificationsConfigurations,
  transformVehicleNotificationsConfigurations,
} from './vehicleNotificationsTransformers';
import { TechnicalType } from '../../common/interfaces/TechnicalType';

export const loadVehicleNotificationsConfigurations = (vendorId: number) =>
  http
    .get<VehicleNotificationsPayload>(`/vendors/${vendorId}/vehicleNotifications`)
    .then(response => transformRawVehicleNotificationsConfigurations(response.data.vehicleNotificationSettings));

export const saveVehicleNotificationsConfigurations = (
  vendorId: number,
  configurations: VehicleNotificationsConfiguration[],
) =>
  http.put(`/vendors/${vendorId}/vehicleNotifications`, {
    vendorId,
    vehicleNotificationSettings: transformVehicleNotificationsConfigurations(configurations),
  });

export const loadVehicleNotificationsTypes = () =>
  http.get<TechnicalType[]>('/common/systemTypes/vehicleNotificationTypes').then(response => response.data);
