import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
} from '../../../core/components/styled';
import { FleetMaintenance } from 'src/vendors/interfaces/FleetMaintenance';
import { FleetMaintenanceTableRow } from './';
import { Table } from '../../../core/components';
import translate from '../../../core/services/translate';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
  fleetMaintenance?: FleetMaintenance;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class FleetMaintenanceForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine } = this.props;
    const { fleetMaintenance } = this.props;

    const fleetMaintenanceTableCells = [
      { name: 'vehicleIssues', label: translate('vendors.vehicleIssues'), width: '40%' },
      { name: 'noteIsChanged', label: translate('vendors.noteWhenIssueStatusIsChanged'), width: '60%' },
    ];

    const fleetMaintenanceRows = [];
    fleetMaintenanceRows.push({ id: fleetMaintenance?.tripIssuesNoteRequirementType.id });

    return (
      <form onSubmit={handleSubmit}>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>

        <PanelSection vertical>
          <Grid margin="medium no no no">
            {!!fleetMaintenance ? (
              <Table
                cells={fleetMaintenanceTableCells}
                rows={fleetMaintenanceRows}
                rowComponent={FleetMaintenanceTableRow}
              />
            ) : (
              <Message padding="sMedium">{translate('vendors.noFleetMaintenanceConfiguration')}</Message>
            )}
          </Grid>

          <Grid centered>
            <GridColumn size="5/12">
              <ButtonSet margin="medium no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
                <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
                  {translate('common.cancel')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const fleetMaintenance = state.vendors.fleetMaintenance.fleetMaintenance;

  return {
    fleetMaintenance,
    initialValues: { prePostTripIssues: fleetMaintenance.tripIssuesNoteRequirementType.id },
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'fleetMaintenance',
    enableReinitialize: true,
  })(FleetMaintenanceForm),
);
