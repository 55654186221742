import { size } from 'lodash-es';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { loadFiltersSettings } from 'src/common/ducks';
import { TypedResolver } from 'src/core/components';
import { TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  loadAlternativeFleetRoutes,
  loadAlternativeFleetSnowPlowServiceActivities,
  loadAlternativeFleetStreetSweeperServiceActivities,
  loadCityInsightTypes,
  loadFleetInsights,
  loadHaulerLocationsForMapbox,
} from 'src/dashboard/ducks';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { loadGeoFenceFilterOptions, loadGroups, loadServiceZones } from 'src/routes/ducks';
import store from 'src/store';
import {
  loadCityAlertSettings,
  loadSnowPlowSettings,
  loadStreetSweepingSettings,
  loadUserById,
  loadVendor,
} from 'src/vendors/ducks';
import {
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  isCityAlertFeatureEnabled,
  loadFeatures,
} from 'src/vendors/ducks/features';
import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import SnowSweeperDashboardPage from './SnowSweeperDashboardPage';

const SnowSweeperDashboardPageResolver: FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);

  const { serviceZones } = useSelector(state => state.routes.serviceZones);
  const { groups } = useSelector(state => state.routes.groups);

  const { pathname } = useLocation();

  const isSnowPlow = pathname.includes('snow-ops');
  const isStreetSweeper = pathname.includes('sweeper-ops');

  const loadDependencies = useCallback(async () => {
    await loadFeatures(vendorId)(dispatch);
    const isSnowPlowFeatureEnabled = checkIfSnowPlowIsEnabled(store.getState());
    const isStreetSweepingFeatureEnabled = checkIfStreetSweepingIsEnabled(store.getState());

    const isCityAlertEnabled = isCityAlertFeatureEnabled(store.getState());
    const cityAlertSettings = store.getState().vendors.cityAlertSettings.cityAlertSettings;

    if (isSnowPlow && !isSnowPlowFeatureEnabled)
      return Promise.reject(new Error(translate('routes.alertMessages.featureNotEnabled')));

    if (isStreetSweeper && !isStreetSweepingFeatureEnabled)
      return Promise.reject(new Error(translate('routes.alertMessages.featureNotEnabled')));

    const dependencies: Promise<unknown>[] = [
      loadHaulerLocationsForMapbox()(dispatch),
      loadCityInsightTypes(vendorId)(dispatch),
      loadGeoFenceFilterOptions({ vendorId, routeDate: TODAY_FORMATTED })(dispatch),
      loadVendor(vendorId)(dispatch),
    ];

    if (isCityAlertEnabled && !cityAlertSettings?.cityAlertTypes?.length) {
      dependencies.push(loadCityAlertSettings(vendorId)(dispatch));
    }

    if (userId)
      dependencies.push(loadFiltersSettings(vendorId, userId)(dispatch), loadUserById(vendorId, userId)(dispatch));

    if (isSnowPlow)
      dependencies.push(
        loadSnowPlowSettings(vendorId)(dispatch),
        loadFleetInsights(vendorId, TODAY_FORMATTED, 10, undefined, [SNOW_PLOW_ID])(dispatch),
        loadAlternativeFleetRoutes(vendorId, SNOW_PLOW_ID)(dispatch),
        loadAlternativeFleetSnowPlowServiceActivities(vendorId)(dispatch),
      );

    if (isStreetSweeper)
      dependencies.push(
        loadStreetSweepingSettings(vendorId)(dispatch),
        loadFleetInsights(vendorId, TODAY_FORMATTED, 10, undefined, [STREET_SWEEPER_ID])(dispatch),
        loadAlternativeFleetRoutes(vendorId, STREET_SWEEPER_ID)(dispatch),
        loadAlternativeFleetStreetSweeperServiceActivities(vendorId)(dispatch),
      );

    if (!size(serviceZones)) dependencies.push(loadServiceZones(vendorId)(dispatch));
    if (!size(groups)) dependencies.push(loadGroups(vendorId)(dispatch));

    return Promise.all(dependencies);
  }, [dispatch, groups, isSnowPlow, isStreetSweeper, serviceZones, userId, vendorId]);

  return (
    <>
      <DocumentTitle>{`${translate('dashboard.dashboard')} ${
        isSnowPlow ? translate('dashboard.snowOps') : translate('dashboard.sweeperOps')
      }`}</DocumentTitle>

      <TypedResolver
        successComponent={SnowSweeperDashboardPage}
        loadingComponent={PageLoading}
        successProps={{ isSnowPlow, isStreetSweeper }}
        resolve={loadDependencies}
        redirectOnError="/dashboard"
        key={pathname}
      />
    </>
  );
};

export default SnowSweeperDashboardPageResolver;
