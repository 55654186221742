import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import doLoadRouteTemplatesByScheduledDays from '../services/loadRouteTemplatesByScheduledDays';

// Actions
const START_LOAD = 'customers/routeTemplateByScheduledDays/START_LOAD';
const COMPLETE_LOAD = 'customers/routeTemplateByScheduledDays/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/routeTemplateByScheduledDays/FAIL_LOAD';
const RESET = 'customers/routeTemplateByScheduledDays/RESET';

export interface RouteTemplateByScheduledDays {
  hasStopsInPendingOptimization: boolean;
  label: string;
  scheduledDay: string;
  value: number;
  vehicleTypeId: number;
}

interface State {
  isLoading: boolean;
  routeTemplatesByScheduledDays: RouteTemplateByScheduledDays[];
}

// Initial state
const initialState: State = {
  isLoading: false,
  routeTemplatesByScheduledDays: [],
};

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplatesByScheduledDays: action.routeTemplatesByScheduledDays,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplatesByScheduledDays: [],
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeTemplatesByScheduledDays: RouteTemplateByScheduledDays[]) => ({
  type: COMPLETE_LOAD,
  routeTemplatesByScheduledDays,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteTemplatesByScheduledDays =
  (vendorId: number, serviceTypeId?: number, scheduledDays?: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRouteTemplatesByScheduledDaysPromise = doLoadRouteTemplatesByScheduledDays(
      vendorId,
      serviceTypeId,
      scheduledDays,
    );
    loadRouteTemplatesByScheduledDaysPromise
      .then(routeTemplatesByScheduledDays => dispatch(completeLoad(routeTemplatesByScheduledDays)))
      .catch(() => dispatch(failLoad()));
    return loadRouteTemplatesByScheduledDaysPromise;
  };

export const resetRouteTemplatesByScheduledDays = () => ({
  type: RESET,
});
