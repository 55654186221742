import { AnyAction } from 'redux';

const SET_PICKUP_STATUS = 'routes/filters/SET_PICKUP_STATUS';
const SET_JOB_PRIORITY = 'routes/filters/SET_JOB_PRIORITY';
const SET_SEARCH_TERM = 'routes/filters/SET_SEARCH_TERM';
const SET_SHOW_STOPS_WITH_LOCATION_ALERTS = 'routes/filters/SET_SHOW_STOPS_WITH_LOCATION_ALERTS';
const SET_SHOW_TEMPORARY_STOPS = 'routes/filters/SET_SHOW_TEMPORARY_STOPS';
const RESET = 'routes/filters/RESET';

interface State {
  pickupStatusIds?: number[];
  jobPriorityTypeIds?: number[];
  searchTerm?: string;
  showStopsWithLocationAlerts: boolean;
  showTemporaryStops: boolean;
}

const initialState: State = {
  pickupStatusIds: undefined,
  jobPriorityTypeIds: undefined,
  searchTerm: undefined,
  showStopsWithLocationAlerts: false,
  showTemporaryStops: false,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_PICKUP_STATUS:
      return { ...state, pickupStatusIds: action.pickupStatusIds };

    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };

    case SET_SHOW_STOPS_WITH_LOCATION_ALERTS:
      return { ...state, showStopsWithLocationAlerts: action.showStopsWithLocationAlerts };

    case SET_SHOW_TEMPORARY_STOPS:
      return { ...state, showTemporaryStops: action.showTemporaryStops };

    case SET_JOB_PRIORITY:
      return { ...state, jobPriorityTypeIds: action.jobPriorityTypeIds };

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export const setPickupStatusIds = (pickupStatusIds: number[]) => ({
  type: SET_PICKUP_STATUS,
  pickupStatusIds,
});

export const setJobPriorityTypeIds = (jobPriorityTypeIds: number[]) => ({
  type: SET_JOB_PRIORITY,
  jobPriorityTypeIds,
});

export const setSearchTerm = (searchTerm: string) => ({
  type: SET_SEARCH_TERM,
  searchTerm,
});

export const setShowStopsWithLocationAlerts = (showStopsWithLocationAlerts: boolean) => ({
  type: SET_SHOW_STOPS_WITH_LOCATION_ALERTS,
  showStopsWithLocationAlerts,
});

export const setShowTemporaryStops = (showTemporaryStops: boolean) => ({
  type: SET_SHOW_TEMPORARY_STOPS,
  showTemporaryStops,
});

export const resetRouteStopsFilters = () => ({
  type: RESET,
});
