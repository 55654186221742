import { debounce, filter, map, size } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { PanelSearch, TypedField } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { NameIdAndRouteType } from 'src/dashboard/interfaces/alterativeFleetOps';
import { DashboardExtraFiltersPanelSearch, SnowSweeperFiltersContainer } from '../../styled/DashboardFilterMapbox';
import SnowSweeperFilterItem from './SnowSweeperFilterItem';

interface FiltersOptions extends NameIdAndRouteType {
  rightContent?: any;
}
interface Props {
  filters: FiltersOptions[];
  filterName: string;
  formName: string;
  cantCheckAll?: boolean;
  maxOneSelectable?: boolean;
}

const SearchableFiltersSection: FC<Props> = ({ filters, filterName, formName, cantCheckAll, maxOneSelectable }) => {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues(formName)) as any;

  const allFilterName = `${filterName}.isAll_${filterName}`;

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFilters = useMemo(() => {
    return filter(filters, filter => {
      return filter.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [filters, searchTerm]);

  const handleCheckAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const newValues: any = {
        [`isAll_${filterName}`]: checked,
      };
      map(filters, filter => {
        newValues[`_${filter.id}`] = checked;
      });
      // the search term is not being used here
      newValues[`search`] = searchTerm;

      dispatch(change(formName, filterName, newValues));
    },
    [dispatch, filterName, filters, formName, searchTerm],
  );

  const handleCheckAny = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue, prevValue, name) => {
      // uncheck the _all checkbox if any of the other checkboxes are unchecked
      if (!newValue && !cantCheckAll && !maxOneSelectable) {
        dispatch(change(formName, allFilterName, false));
      }
      // check the _all checkbox if all of the other checkboxes are checked
      else if (
        !cantCheckAll &&
        !maxOneSelectable &&
        Object.values(formValues[filterName]).filter(Boolean).length === filters.length - 1
      ) {
        dispatch(change(formName, allFilterName, true));
      }

      if (maxOneSelectable) {
        const fixedName = name.split('_').pop();

        const newValues: any = {
          [`_${fixedName}`]: true,
        };
        dispatch(change(formName, allFilterName, false));
        dispatch(change(formName, filterName, newValues));
      }
    },
    [allFilterName, cantCheckAll, dispatch, filterName, filters.length, formName, formValues, maxOneSelectable],
  );

  // debounce for search
  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm: string) => {
        setSearchTerm(searchTerm);
      }, 200),
    [],
  );

  return (
    <>
      <DashboardExtraFiltersPanelSearch>
        <TypedField
          name={`searchTerm_${filterName}`}
          component={PanelSearch}
          props={{ margin: 'no no xSmall', isClearable: true }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>, searchTerm: string) => debouncedSearch(searchTerm)}
        />
      </DashboardExtraFiltersPanelSearch>

      {!size(filteredFilters) && size(filters) ? (
        <Text color="grayDarker" margin="small" block>
          <em>{translate('routes.planner.noDataMatches')}</em>
        </Text>
      ) : !size(filters) ? (
        <>
          <Text color="grayDarker" margin="small" block>
            <em>{translate('routes.planner.noData')}</em>
          </Text>
        </>
      ) : (
        <SnowSweeperFiltersContainer>
          {!cantCheckAll && !maxOneSelectable && (
            <SnowSweeperFilterItem name={allFilterName} label={translate('common.all')} onChange={handleCheckAll} />
          )}
          {map(filteredFilters, filter => (
            <SnowSweeperFilterItem
              name={`${filterName}._${filter.id}`}
              label={filter.name}
              key={filter.id}
              onChange={handleCheckAny}
              rightContent={filter.rightContent}
            />
          ))}
        </SnowSweeperFiltersContainer>
      )}
    </>
  );
};

export default SearchableFiltersSection;
