import React from 'react';
import { connect } from 'react-redux';

import { isVendorManagerSelector } from '../ducks';
import { AppState } from '../../store';

interface Props {
  isVendorManager?: boolean;
}

const VendorManagerGuard: React.FC<Props> = ({ isVendorManager, children }) => {
  if (isVendorManager && children) {
    return <>{children}</>;
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  isVendorManager: isVendorManagerSelector(state.account.login),
});

export default connect(mapStateToProps)(VendorManagerGuard);
