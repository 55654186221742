import React from 'react';
import { useDispatch } from 'react-redux';

import { CITY_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  loadCityAlertsImportUploadedFilesStatus,
  loadLocationAlertsImportUploadedFilesStatus,
} from 'src/fleet/ducks/locationAndCityAlertsImports';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import LocationAndCityAlertsImportModal from './LocationAndCityAlertsImportModal';

interface Props {
  closeModal: () => void;
  visibleModalType?: number;
}

export const LocationAndCityAlertsImportModalResolver: React.FC<Props> = ({ closeModal, visibleModalType }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const isCityAlertsModalDisplayed = visibleModalType === CITY_ALERT_TYPE_ID;

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }
    const dependencies = [
      isCityAlertsModalDisplayed
        ? loadCityAlertsImportUploadedFilesStatus(vendorId)(dispatch)
        : loadLocationAlertsImportUploadedFilesStatus(vendorId)(dispatch),
    ];

    return Promise.all(dependencies);
  }, [vendorId, dispatch, isCityAlertsModalDisplayed]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={LocationAndCityAlertsImportModal}
      successProps={{ closeModal, isCityAlertsModalDisplayed }}
    />
  );
};
