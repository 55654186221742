import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { Feature, ZipCodeBoundaries, ZipCodeBoundariesState } from '../interfaces/ZipCodeBoundaries';
import { loadZipCodeBoundaries as doLoadZipCodeBoundaries } from '../services/zipCodeBoundaries';

const mergeFeatures = (prevFeatures: Feature[], futures: Feature[]) => {
  const allFeatures = [...prevFeatures, ...futures];

  return allFeatures.filter(
    (feature, index, self) => index === self.findIndex(item => item.properties.zip === feature.properties.zip),
  );
};

// Actions
const START_LOAD = 'haulerProfile/zipCodeBoundaries/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/zipCodeBoundaries/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/zipCodeBoundaries/FAIL_LOAD';
const RESET = 'haulerProfile/zipCodeBoundaries/RESET';

// Initial state
const initialState: ZipCodeBoundariesState = {
  isLoading: false,
  zipCodeBoundaries: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          zipCodeBoundaries: {
            type: action.zipCodeBoundaries.type,
            features: mergeFeatures(state.zipCodeBoundaries?.features || [], action.zipCodeBoundaries.features),
          },
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (zipCodeBoundaries: ZipCodeBoundaries) => ({
  type: COMPLETE_LOAD,
  zipCodeBoundaries,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadZipCodeBoundaries = (zipCodes: string[]) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadZipCodeBoundariesPromise = doLoadZipCodeBoundaries(zipCodes);
  loadZipCodeBoundariesPromise
    .then(zipCodeBoundaries => dispatch(completeLoad(zipCodeBoundaries)))
    .catch(() => dispatch(failLoad()));
  return loadZipCodeBoundariesPromise;
};

export const resetZipCodeBoundaries = () => ({
  type: RESET,
});
