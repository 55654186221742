import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';

const getInsightHistoryFormInitialValues = (queryString: string) => ({ ...getQueryParams(queryString) });

const insightHistoryFormInitialValuesSelector = createSelector(
  getInsightHistoryFormInitialValues,
  identity,
);

export default insightHistoryFormInitialValuesSelector;
