import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { loadPermissions, shouldLoadPermissionsSelector } from 'src/account/ducks';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { loadJobPriorityTypes, loadReasonCodeTypes } from 'src/common/ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { Resolver } from 'src/core/components';
import { SortOrder } from 'src/core/components/Table';
import translate from 'src/core/services/translate';
import { loadCustomer, loadSearchedLocation, loadServiceHistory } from 'src/customers/ducks';
import { AppState } from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import ServiceHistoryPage from './ServiceHistoryPage';

interface MatchState {
  customerId: string;
}

interface LocationState {
  endDate: string;
  locationId: string;
  pickupStatusTypeIds: string;
  jobPriorityTypeIds: string;
  serviceContractIds: string;
  sortedBy: string;
  sortOrder: SortOrder;
  startDate: string;
}

interface Props extends RouteComponentProps<MatchState, {}, LocationState> {
  loadCustomer: DuckFunction<typeof loadCustomer>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadReasonCodeTypes: DuckFunction<typeof loadReasonCodeTypes>;
  loadSearchedLocation: DuckFunction<typeof loadSearchedLocation>;
  loadServiceHistory: DuckFunction<typeof loadServiceHistory>;
  loadJobPriorityTypes: DuckFunction<typeof loadJobPriorityTypes>;
  shouldLoadPermissions: boolean;
}

class ServiceHistoryPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadCustomer,
      loadPermissions,
      loadReasonCodeTypes,
      loadSearchedLocation,
      loadServiceHistory,
      loadJobPriorityTypes,
      location: { search },
      match: {
        params: { customerId },
      },
      shouldLoadPermissions,
    } = this.props;
    const { endDate, locationId, pickupStatusTypeIds, serviceContractIds, jobPriorityTypeIds, startDate } =
      getQueryParams(search);

    const dependencies = [
      loadServiceHistory(
        Number(customerId),
        locationId,
        serviceContractIds,
        pickupStatusTypeIds,
        jobPriorityTypeIds,
        startDate,
        endDate,
      ),
      loadCustomer(Number(customerId)),
      loadReasonCodeTypes(),
      loadJobPriorityTypes(),
    ];

    if (locationId) {
      dependencies.push(loadSearchedLocation(locationId));
    }

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('customers.serviceHistory')}</DocumentTitle>
        <Resolver
          successComponent={ServiceHistoryPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/customers/customers"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
});

const mapDispatchToProps = {
  loadServiceHistory,
  loadSearchedLocation,
  loadCustomer,
  loadPermissions,
  loadReasonCodeTypes,
  loadJobPriorityTypes,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceHistoryPageResolver));
