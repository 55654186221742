import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Driver } from '../interfaces/Driver';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  drivers: Driver[];
  withDriverName?: boolean;
}

class DriverDropdown extends PureComponent<Props> {
  onChange = (_: unknown, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      drivers,
      withDriverName,
      dropdownProps,
    } = this.props;

    const driverOptions = drivers.map(({ firstName, lastName, id }) => ({
      label: `${firstName} ${lastName}`,
      value: withDriverName ? `${firstName} ${lastName}` : id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('drivers.driver') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('drivers.driver') : undefined),
          options: driverOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  drivers: state.fleet.drivers.drivers,
});

export default connect(mapStateToProps)(DriverDropdown);
