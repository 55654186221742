import React from 'react';
import moment from 'moment';

import { TableCell, TableRow } from '../../../../core/components/styled';
import { PreventativeMaintenanceLog } from '../../../interfaces/PreventativeMaintenance';
import translate from '../../../../core/services/translate';
import { PM_TYPE_MILEAGE, PM_TYPE_ENGINE_HOURS } from '../../../constants/preventativeMaintenance';

type Props = PreventativeMaintenanceLog;

const VehiclePreventativeMaintenanceLogsTableRow: React.FC<Props> = ({
  completedDate,
  milestoneDate,
  mileage: { completedMileage, milestoneMileage },
  engineHours: { completedEngineHours, milestoneEngineHours },
  user,
  typeId,
}) => {
  let unit: string = '';
  let completed: number | undefined = undefined;
  let milestone: number | undefined = undefined;

  if (typeId === PM_TYPE_MILEAGE) {
    unit = translate('vehicles.preventativeMaintenanceLabels.miles');
    completed = completedMileage;
    milestone = milestoneMileage;
  } else if (typeId === PM_TYPE_ENGINE_HOURS) {
    unit = translate('vehicles.preventativeMaintenanceLabels.hours');
    completed = completedEngineHours;
    milestone = milestoneEngineHours;
  }

  return (
    <TableRow>
      <TableCell width="15%" padding="defaultCellVertical defaultCellHorizontal defaultCellVertical medium">
        {moment(completedDate).format('MM/DD/YYYY')}
      </TableCell>

      <TableCell width="20%">{completed && `${completed} ${unit}`}</TableCell>

      <TableCell width="15%">{milestoneDate && moment(milestoneDate).format('MM/DD/YYYY')}</TableCell>

      <TableCell width="20%">{milestone && `${milestone} ${unit}`}</TableCell>

      <TableCell
        width="30%"
        align="right"
        padding="defaultCellVertical medium defaultCellVertical defaultCellHorizontal"
      >
        {user}
      </TableCell>
    </TableRow>
  );
};

export default VehiclePreventativeMaintenanceLogsTableRow;
