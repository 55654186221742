import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getCustomerLocations } from './customers';
import { AppState } from '../../store';
import { DropdownOption } from '../../core/components/Dropdown';

// TODO fix getCustomerLocations typing
const customerLocationsSelector = createSelector<unknown, unknown, DropdownOption[]>(getCustomerLocations as any, identity);

export const customerLocationsFlashSelector = (state: AppState) =>
  customerLocationsSelector(state.fleet.facilities.operationalFacilities || []);
