import React, { PureComponent } from 'react';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { Marker, InfoWindow } from '@react-google-maps/api';

import {
  MapInfoWindow,
  MapInfoWindowDetails,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
} from '../../core/components/styled';
import { VENDOR_LOCATION_ICONS, CUSTOMER_SUBTYPES, GOOGLE as google } from '../constants';
import translate from '../../core/services/translate';

interface Props {
  vendorLocation: any;
  isInfoWindowOpen?: boolean;
  toggleInfoWindow: (key: string) => void;
  clusterer: Clusterer;
}

class VendorLocationMarker extends PureComponent<Props> {
  toggleInfoWindow = () => {
    const {
      toggleInfoWindow,
      vendorLocation: { id },
    } = this.props;

    toggleInfoWindow(`vendorLocations.${id}`);
  };

  render() {
    const { vendorLocation, isInfoWindowOpen, clusterer } = this.props;

    const markerPosition = {
      lat: parseFloat(vendorLocation.latitude),
      lng: parseFloat(vendorLocation.longitude),
    };
    const markerIcon = {
      url: VENDOR_LOCATION_ICONS[vendorLocation.customerSubTypeId],
      size: new google.maps.Size(40, 32),
      scaledSize: new google.maps.Size(40, 32),
    };
    const pixelOffset = new google.maps.Size(0, -37);

    return (
      <Marker
        icon={markerIcon}
        key={vendorLocation.id}
        position={markerPosition}
        onClick={this.toggleInfoWindow}
        clusterer={clusterer}
        noClustererRedraw
      >
        {isInfoWindowOpen && markerPosition && (
          <InfoWindow onCloseClick={this.toggleInfoWindow} position={markerPosition} options={{ pixelOffset }}>
            <MapInfoWindow>
              <MapInfoWindowDetails>
                {!!vendorLocation.customerType && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.locationType')}:</MapInfoWindowDetailLabel>
                    {CUSTOMER_SUBTYPES[vendorLocation.customerSubTypeId].name}
                  </MapInfoWindowDetail>
                )}

                {!!vendorLocation.locationName && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.locationName')}:</MapInfoWindowDetailLabel>
                    {vendorLocation.locationName}
                  </MapInfoWindowDetail>
                )}

                {!!vendorLocation.address && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.address')}:</MapInfoWindowDetailLabel>
                    {vendorLocation.address}
                  </MapInfoWindowDetail>
                )}
              </MapInfoWindowDetails>
            </MapInfoWindow>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default VendorLocationMarker;
