import { Resizable } from 're-resizable';
import { FC, useEffect, useRef, useState } from 'react';

import { IconButtonIcon, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { WidgetHeader, WidgetWrapper } from 'src/dashboard/components/styled';
import WidgetResizeHandle from './WidgetResizeHandle';

interface DashboardWidgetProps {
  title: string;
  icon: string;
  children?: JSX.Element;
  size?: 'small' | 'medium' | 'large';
  maxHeight?: number;
  headerMenu?: JSX.Element;
  topMargin?: number;
  initialHeight: number;
  setWidgetHeight: (height: number) => void;
  isHeaderOpen: boolean;
  setIsHeaderOpen: (isOpen: boolean) => void;
}

const DashboardWidget: FC<DashboardWidgetProps> = ({
  title,
  icon,
  children,
  size,
  maxHeight,
  headerMenu,
  initialHeight,
  topMargin,
  setWidgetHeight,
  isHeaderOpen,
  setIsHeaderOpen,
}) => {
  const titleRef = useRef<any>();

  // default to children height if open, 0 if closed
  const [height, setHeight] = useState(initialHeight);

  const widgetRef = useRef<any>();

  useEffect(() => {
    if (widgetRef.current) {
      setWidgetHeight(widgetRef.current.getBoundingClientRect().height);
    }
  }, [setWidgetHeight, widgetRef]);

  const handleHeaderClick = () => {
    setIsHeaderOpen(!isHeaderOpen);
  };

  useEffect(() => {
    if (!isHeaderOpen) {
      setHeight(0);
      setWidgetHeight(60);
    } else {
      setHeight(initialHeight);
      setWidgetHeight(initialHeight + 60);
    }
  }, [initialHeight, isHeaderOpen, setWidgetHeight]);

  const handleResizeStop = (e: any, direction: any, ref: any, d: { height: number }) => {
    setHeight(height + d.height);
    setWidgetHeight(widgetRef.current.getBoundingClientRect().height);
  };

  return (
    <WidgetWrapper
      ref={widgetRef}
      isOpen={isHeaderOpen}
      titleWidth={(titleRef?.current?.clientWidth || 0) + 10}
      marginTop={topMargin}
      size={size}
    >
      <WidgetHeader onClick={handleHeaderClick} isOpen={isHeaderOpen}>
        <Box display="inline-flex" alignItems="center" padding="no no">
          <IconButtonIcon margin="xSmall" size="large" icon={icon} />
          <Text ref={titleRef} id="widget-title" color="grayDarker" size="small" weight="medium">
            {title}
          </Text>
        </Box>
        {headerMenu}
      </WidgetHeader>
      <Resizable
        enable={{
          bottom: true,
        }}
        size={{
          height: isHeaderOpen ? height || 'auto' : 0,
          width: '100%',
        }}
        onResizeStop={handleResizeStop}
        handleStyles={{
          bottom: {
            display: isHeaderOpen ? 'block' : 'none',
          },
        }}
        style={{
          transition: 'unset',
          overflow: 'hidden',
          paddingBottom: isHeaderOpen ? 20 : 0,
        }}
        maxHeight={maxHeight}
        handleComponent={{ bottom: <WidgetResizeHandle /> }}
      >
        {children}
      </Resizable>
    </WidgetWrapper>
  );
};

export default DashboardWidget;
