import { getItem } from './persistentStorage';

declare global {
  interface Window {
    Rubicon: {
      environment: {
        getEnvironment: () => string;
        getApiBaseUrl: () => string;
        getApiOldUrl: () => string;
        getInsightsApiBaseUrl: () => string;
        getInsightsReportingApiBaseURL: () => string;
        getAugustusApiBaseURL: () => string;
      };
      user: {
        setUserInfo: (user: any) => void;
        userInfo: any;
      };
      vendor: {
        setVendorInfo: (user: any) => void;
        vendorInfo: any;
      };
    };
  }
}

const env = window.Rubicon.environment;

export const getBaseUrl = () => window.location.host;
export const getEnvironment = () => env.getEnvironment();
export const getApiBaseURL = () => process.env.REACT_APP_API_URL;
export const getInsightsApiBaseURL = () => process.env.REACT_APP_INSIGHTS_API_URL;
export const getInsightsReportingApiBaseURL = () => process.env.REACT_APP_INSIGHTS_REPORTING_API_URL;
export const getAugustusApiBaseURL = () => process.env.REACT_APP_AUGUSTUS_API_URL;
export const getMapboxApiKey = () => process.env.REACT_APP_MAPBOX_API_KEY;
export const getMapboxMonochromeThemeUrl = () => process.env.REACT_APP_MAPBOX_MONOCHROME_THEME_URL;
export const getMapboxSatelliteThemeUrl = () => process.env.REACT_APP_MAPBOX_SATELLITE_THEME_URL;
export const getCustomerCoreApiBaseURL = () => process.env.REACT_APP_CUSTOMER_CORE_API_URL;
export const getCommunicationApiUrl = () => process.env.REACT_APP_COMMUNICATION_API_URL;

export const getIsInMaintenanceMode = () =>
  !getItem('disableMaintenanceMode') && !!process.env.REACT_APP_MAINTENANCE_MODE;
