import { FC } from 'react';
import { Modal } from 'src/core/components';
import translate from 'src/core/services/translate';
import { AddToGroupsForm } from '../forms';
import { AddToGroupsFormValues } from '../forms/AddToGroupsForm';

interface AddToGroupsModalProps {
  closeModal: () => void;
  onAddToGroups: (v: AddToGroupsFormValues) => void;
}

const AddToGroupsModal: FC<AddToGroupsModalProps> = ({ closeModal, onAddToGroups }) => (
  <Modal title={translate('routes.groups.addToGroups')} size="xSmall">
    <AddToGroupsForm closeModal={closeModal} onSubmit={onAddToGroups} />
  </Modal>
);

export default AddToGroupsModal;
