import { http } from '../../core/services/http';
import transformLoadEventLog from './transformLoadEventLog';

const loadEventLog = (routeId: number, eventTypeIds?: number[]) =>
  http
    .get(`routeSession/${routeId}/details`, { params: { actionIds: eventTypeIds && eventTypeIds.toString() } })
    .then(response => transformLoadEventLog(response.data));

export const loadZEventLog = (routeId: number, eventTypeIds?: number[]) =>
  http
    .get(`routeSession/${routeId}/zDeviceDetails`, { params: { actionIds: eventTypeIds && eventTypeIds.toString() } })
    .then(response => transformLoadEventLog(response.data));

export default loadEventLog;
