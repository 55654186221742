import { reduxForm, InjectedFormProps, Field } from 'redux-form';

import {
  Grid,
  GridColumn,
  PanelSection,
  PanelSectionGroup,
} from '../../../core/components/styled';
import { Input, TypedField } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isRequired } from '../../../utils/services/validator';
import translate from 'src/core/services/translate';

function CityWorksGeneralSettingsForm({ form, handleSubmit }: InjectedFormProps) {

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="no">
        <PanelSectionGroup padding="no" width="100%">
          <Grid multiLine>

            <GridColumn size="2/12">
              <TypedField
                name="domainName"
                component={Input}
                validate={[isRequired]}
                props={{
                  label: translate('vendors.cityworks.domainName'),
                  margin: 'no',
                  disabled: true
                }}
              />
            </GridColumn>

            <GridColumn size="10/12">
              <TypedField
                name="baseAddress"
                component={Input}
                validate={[isRequired]}
                props={{
                  label: translate('vendors.cityworks.baseAddress'),
                  margin: 'no',
                  disabled: true
                }}
              />
            </GridColumn>
          </Grid>

          <Grid margin="small no no no" multiLine>

            <GridColumn size="4/12">
              <TypedField
                name="userName"
                component={Input}
                validate={[isRequired]}
                props={{
                  label: translate('vendors.cityworks.userName'),
                  margin: 'no',
                  disabled: true
                }}
              />
            </GridColumn>

            <GridColumn size="4/12">
              <Field
                name="password"
                component={Input}
                disabled
                label={translate('vendors.cityworks.password')}
                type="password"
              />
            </GridColumn>
          </Grid>
        </PanelSectionGroup>
      </PanelSection>
    </form>
  );
}
export default reduxForm({ form: 'cityWorksgeneralSettingsForm', onSubmitFail: focusFirstInvalidField })(CityWorksGeneralSettingsForm);
