import { AxiosResponse } from 'axios';
import flatten from 'lodash-es/flatten';

import { downloadInsightsExcelFile } from '../../utils/services/downloadExcelFile';
import { httpInsightsReporting } from '../../core/services/http';
import {
  CommunityInsightsResponse,
  CommunityInsightMapItem,
  CommunityInsightItem,
} from '../interfaces/communityInsights';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import translate from '../../core/services/translate';

export const loadCommunityInsights = (vendorId: number, startDate: string, endDate: string) =>
  httpInsightsReporting
    .get('/locationFlagging/communityLocationFlaggingWithMap', {
      params: {
        vendorId,
        startDatetime: startDate,
        endDateTime: endDate,
      },
    })
    .then((response: AxiosResponse<CommunityInsightsResponse>) => response.data);

export const clearInsight = (insightId: number, statusId: number = 1) =>
  httpInsightsReporting.put('locationFlagging/communityLocationFlagging', {
    id: insightId,
    statusId,
  });

export const exportCommunityInsights = (
  vendorId: number,
  locationFlagTypeIds: any[],
  statusIds: any[],
  insightName: string,
  startDate: string,
  endDate: string,
  sortOrder: string,
  sortedBy: string,
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  downloadInsightsExcelFile('export/communityLocationFlagging', `${translate('insights.communityInsights')}`, {
    vendorId,
    locationFlagTypeIdsCSV: locationFlagTypeIds,
    statusIdsCSV: statusIds,
    searchString: insightName,
    startDatetime: startDate,
    endDateTime: endDate,
    sortDirection: sortOrder,
    sortOrder: sortedBy,
    vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
    serviceZoneIdsCSV: filtersPreferencesIds.serviceZoneIdsCSV,
    supervisorIdsCSV: filtersPreferencesIds.supervisorIdsCSV,
  });

export const transformCommunityInsights = (mapList: CommunityInsightMapItem[]): CommunityInsightItem[] =>
  flatten(mapList.map(mapItem => mapItem.communityInsights));
