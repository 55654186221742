import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from '../../utils/services/queryParams';

const getGeoFenceSearchFormInitialValues = (queryString: string) => {
  const { geoFenceName } = getQueryParams(queryString);

  return {
    geoFenceName,
  };
};

const geoFenceSearchFormInitialValuesSelector = createSelector(getGeoFenceSearchFormInitialValues, identity);

export default geoFenceSearchFormInitialValuesSelector;
