import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  createWeightTicket,
  updateWeightTicket,
  uploadWeightTicketImage as doUploadWeightTicketImage,
  linkImageToWeightTicket as doLinkImageToWeightTicket,
} from '../services/weightTicket';

// Actions
const START_SAVE = 'routes/weightTicket/START_SAVE';
export const COMPLETE_SAVE = 'routes/weightTicket/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/weightTicket/FAIL_SAVE';
const RESET = 'routes/weightTicket/RESET';
const START_UPLOAD_WEIGHT_TICKET_IMAGE = 'routes/weightTicket/START_UPLOAD_WEIGHT_TICKET_IMAGE';
const COMPLETE_UPLOAD_WEIGHT_TICKET_IMAGE = 'routes/weightTicket/COMPLETE_UPLOAD_WEIGHT_TICKET_IMAGE';
const FAIL_UPLOAD_WEIGHT_TICKET_IMAGE = 'routes/weightTicket/FAIL_UPLOAD_WEIGHT_TICKET_IMAGE';
const START_LINK_WEIGHT_TICKET_IMAGE = 'routes/weightTicket/START_LINK_WEIGHT_TICKET_IMAGE';
const COMPLETE_LINK_WEIGHT_TICKET_IMAGE = 'routes/weightTicket/COMPLETE_LINK_WEIGHT_TICKET_IMAGE';
const FAIL_LINK_WEIGHT_TICKET_IMAGE = 'routes/weightTicket/FAIL_LINK_WEIGHT_TICKET_IMAGE';

interface State {
  isSaving: boolean;
  isUploadingWeightTicketImage: boolean;
  isLinkingWeightTicketImage: boolean;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isSaving: false,
  isUploadingWeightTicketImage: false,
  isLinkingWeightTicketImage: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_UPLOAD_WEIGHT_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isUploadingWeightTicketImage: true,
        },
      });

    case COMPLETE_UPLOAD_WEIGHT_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isUploadingWeightTicketImage: false,
        },
      });

    case FAIL_UPLOAD_WEIGHT_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isUploadingWeightTicketImage: false,
        },
      });

    case START_LINK_WEIGHT_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isLinkingWeightTicketImage: true,
        },
      });

    case COMPLETE_LINK_WEIGHT_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isLinkingWeightTicketImage: false,
        },
      });

    case FAIL_LINK_WEIGHT_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isLinkingWeightTicketImage: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (weightTicket: any) => ({
  type: COMPLETE_SAVE,
  weightTicket,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startUploadWeightTicketImage = () => ({
  type: START_UPLOAD_WEIGHT_TICKET_IMAGE,
});

const completeUploadWeightTicketImage = () => ({
  type: COMPLETE_UPLOAD_WEIGHT_TICKET_IMAGE,
});

const failUploadWeightTicketImage = () => ({
  type: FAIL_UPLOAD_WEIGHT_TICKET_IMAGE,
});

const startLinkWeightTicketImage = () => ({
  type: START_LINK_WEIGHT_TICKET_IMAGE,
});

const completeLinkWeightTicketImage = () => ({
  type: COMPLETE_LINK_WEIGHT_TICKET_IMAGE,
});

const failLinkWeightTicketImage = () => ({
  type: FAIL_LINK_WEIGHT_TICKET_IMAGE,
});

export const saveWeightTicket = (routeId: number, weightTicket: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveWeightTicketPromise = weightTicket.id
    ? updateWeightTicket(weightTicket)
    : createWeightTicket(routeId, weightTicket);

  saveWeightTicketPromise.then(weightTicket => dispatch(completeSave(weightTicket))).catch(() => dispatch(failSave()));
  return saveWeightTicketPromise;
};

export const resetWeightTicket = () => ({
  type: RESET,
});

export const uploadWeightTicketImage = (file: File, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startUploadWeightTicketImage());

  const uploadWeightTicketImagePromise = doUploadWeightTicketImage(file, routeId);
  uploadWeightTicketImagePromise
    .then(weightTicketImage => {
      dispatch(completeUploadWeightTicketImage());
      return weightTicketImage;
    })
    .catch(() => dispatch(failUploadWeightTicketImage()));
  return uploadWeightTicketImagePromise;
};

export const linkImageToWeightTicket = (imageId: number | null, weightTicketId: number) => (dispatch: Dispatch) => {
  dispatch(startLinkWeightTicketImage());

  const linkImageToWeightTicketPromise = doLinkImageToWeightTicket(imageId, weightTicketId);

  linkImageToWeightTicketPromise
    .then(() => {
      dispatch(completeLinkWeightTicketImage());
    })
    .catch(() => dispatch(failLinkWeightTicketImage()));

  return linkImageToWeightTicketPromise;
};
