import { camelCase, map } from 'lodash-es';
import { Field, reduxForm } from 'redux-form';

import { useSelector } from 'src/core/hooks/useSelector';
import { Dropdown, PanelSearch, TypedField } from '../../../core/components';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { FACILITY_STATUS } from '../../constants';

export const FACILITIES_SEARCH_FORM = 'facilitiesSearchForm';

const FacilitiesSearchForm = () => {
  const facilityTypes = useSelector(state => state.fleet.facilityTypes.facilityTypes);

  const facilityStatusOptions = map(FACILITY_STATUS, ({ id, name }) => ({
    value: id,
    label: name,
  }));

  const facilityTypesOptions = map(facilityTypes, ({ id, technicalName }) => ({
    value: id,
    label: translate(`facilities.facilityTypes.${camelCase(technicalName)}`),
  }));

  return (
    <form>
      <PanelSection padding="xSmall xSmall" withBorder>
        <Grid>
          <GridColumn size="7/12">
            <Field name="facilitySearchTerm" component={PanelSearch} margin="no" props={{ isClearable: true }} />
          </GridColumn>

          <GridColumn size="2/12">
            <TypedField
              name="facilityStatus"
              component={Dropdown}
              props={{
                margin: 'no',
                options: facilityStatusOptions,
                placeholder: translate('facilities.facilityStatus'),
                isClearable: true,
              }}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <TypedField
              name="facilityTypeDropdown"
              component={Dropdown}
              props={{
                margin: 'no',
                options: facilityTypesOptions,
                placeholder: translate('facilities.facilityType'),
                isClearable: true,
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default reduxForm({
  form: FACILITIES_SEARCH_FORM,
  initialValues: {
    facilitySearchTerm: undefined,
    facilityStatus: undefined,
    facilityTypeDropdown: undefined,
  },
})(FacilitiesSearchForm);
