import React, { Component } from 'react';

import { Radio as RadioContainer, RadioInput, RadioTextButton } from './styled';

interface Props {
  block?: boolean;
  color?: string;
  disabled?: boolean;
  input: any;
  label: string;
  margin?: string;
  border?: string;
  size?: string;
}

class RadioButton extends Component<Props> {
  render() {
    const { block, color, disabled, input, label, margin, border, size } = this.props;

    return (
      <RadioContainer block={block} isDisabled={disabled} margin={margin} size={size}>
        <RadioInput {...input} color={color} disabled={disabled} type="radio" />
        <RadioTextButton color={color} disabled={disabled} fullWidth={block} size={size} border={border}>
          {label}
        </RadioTextButton>
      </RadioContainer>
    );
  }
}

export default RadioButton;
