import React from 'react';

import { PopoverWrapper, UnconnectedCheckbox } from '../../../../core/components';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { Popover, TableCell, TableRow, Text } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';
import { Rates } from 'src/customers/interfaces/RateBuilder';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { getEquipmentSize, getEquipmentSizeTranslationKey } from 'src/customers/services/transformLoadServices';
import { RATE_TYPES } from 'src/customers/constants/rateTypes';

interface Props {
  tableWidths: any[];
  checkedServiceContractIds: number[];
  onCheckChange: (event: any, id: number) => void;

  serviceContractId: number;
  customerId: number;
  customerName: string;
  locationId: number;
  locationName: string;
  locationAddress: string;
  displayLatitude: number;
  displayLongitude: number;
  numberOfContainers: number;
  serviceType: TechnicalType;
  equipmentType: TechnicalType;
  equipmentSize: TechnicalType;
  pickupFrequencyType: TechnicalType;
  wasteMaterialType: TechnicalType;
  rates: Rates[];
}

const ServiceContractsTableRow: React.FC<Props> = ({
  onCheckChange,
  checkedServiceContractIds = [],
  tableWidths,
  // service contract props
  serviceContractId,
  customerName,
  locationName,
  locationAddress,
  numberOfContainers,
  serviceType,
  equipmentType,
  equipmentSize,
  pickupFrequencyType,
  wasteMaterialType,
  rates,
}) => {
  const isChecked = checkedServiceContractIds.includes(serviceContractId);

  return (
    <TableRow height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width={tableWidths[0]} justifyContent="center">
        <UnconnectedCheckbox
          onChange={event => onCheckChange(event, serviceContractId)}
          checked={isChecked}
          size="small"
        />
      </TableCell>

      <TableCell width={tableWidths[1]}>
        <Text size="small">{customerName}</Text>
      </TableCell>

      <TableCell width={tableWidths[2]}>
        <Text size="small">
          {locationName}
          <br />
          {locationAddress}
        </Text>
      </TableCell>

      <TableCell width={tableWidths[3]} vertical align="center">
        <Text block size="small">
          {equipmentType ? translate(createTranslationKey(equipmentType.technicalName, 'common.equipmentTypes')) : '-'}{' '}
          {(numberOfContainers || numberOfContainers === 0) && `(${numberOfContainers})`}
        </Text>
        <Text block size="small">
          {equipmentSize && serviceType && equipmentType
            ? translate(
                getEquipmentSizeTranslationKey(equipmentSize.technicalName, serviceType.name, equipmentType.name),
                {
                  size: getEquipmentSize(equipmentSize.name),
                },
              )
            : '-'}
        </Text>
        <Text block size="small">
          {pickupFrequencyType
            ? translate(createTranslationKey(pickupFrequencyType.name, 'common.pickupFrequencyTypes'))
            : '-'}
        </Text>
      </TableCell>

      <TableCell width={tableWidths[4]}>
        <Text block size="small">
          {wasteMaterialType
            ? translate(createTranslationKey(wasteMaterialType.technicalName, 'common.wasteTypes'))
            : '-'}
        </Text>
      </TableCell>

      <TableCell width={tableWidths[5]} vertical align="center">
        {rates.slice(0, 2).map((rate, index) => (
          <Text block size="xSmall" key={index}>
            {RATE_TYPES?.[rate.rateCodeId as keyof typeof RATE_TYPES]?.name || rate?.typeCode} - ${rate.rate}/
            {rate?.unitOfMeasureType?.name}
          </Text>
        ))}
        <PopoverWrapper
          margin="no"
          triggerButton={
            rates.length > 2 && (
              <Text block size="xSmall" color="primary" style={{ cursor: 'pointer' }}>
                {translate('common.showMore')}
              </Text>
            )
          }
          popoverContent={
            <Popover>
              {rates.map((rate, index) => (
                <Text block size="xSmall" key={index}>
                  {RATE_TYPES?.[rate.rateCodeId as keyof typeof RATE_TYPES]?.name || rate?.typeCode} - ${rate.rate}/
                  {rate?.unitOfMeasureType?.name}
                </Text>
              ))}
            </Popover>
          }
          size="medium"
          width="40%"
          align="center"
        />
      </TableCell>
    </TableRow>
  );
};

export default ServiceContractsTableRow;
