import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

const COMPLETED_NOTIFICATION = 'ServiceCompletedNotification';
const IN_PROGRESS_NOTIFICATION = 'ServiceInProgressNotification';
const ISSUE_REPORTED_NOTIFICATION = 'ServiceIssueReportedNotification';

export const CUSTOMER_NOTIFICATION_TEXT_MESSAGES = mapKeys(
  [
    { id: COMPLETED_NOTIFICATION, name: translate('vendors.textMessages.completed') },
    { id: IN_PROGRESS_NOTIFICATION, name: translate('vendors.textMessages.inProgress') },
    { id: ISSUE_REPORTED_NOTIFICATION, name: translate('vendors.textMessages.issueReported') },
  ],
  'id',
);
