import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';

import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { CONTAINER_PLACEMENT_ISSUE, LOCATION_INACCESSIBLE, PERSONNEL_ISSUE } from '../../constants';
import translate from '../../../core/services/translate';
import { ServiceUnableToDeliverForm } from '../forms';
import { AppState } from '../../../store';
import { DuckFunction } from '../../../contracts/ducks';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { updateHaulerOpportunity } from '../../ducks';
import { DELIVERY, REMOVAL } from '../../../fleet/constants/openDispatachesServices';
import { DECLINE, REQUEST_CHANGE } from '../../../fleet/constants/rubiconDisptachesStatus';
interface RouteParams {
  token: string;
}

interface ComponentProps {
  action: string;
  caseId: number;
  itemId: number;
  vendorId: number;
  userId: string;
  status: any;
  liftAndShiftVendor?: boolean;
  pickupTypeId: number;
  statusId: number;
  onCancel(pristine: boolean, isReload?: boolean): void;
}

interface ReduxProps {
  response: any;
  requestedChanges: any;
  WasDelivered: any;
  CanNotDeliver: any;
  isSaving: boolean;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const ServiceUnableToDeliverModal: React.FC<Props> = props => {
  const { action, isSaving, onCancel } = props;
  const modalTitle =
    action === DECLINE
      ? translate('opportunity.deliveries.declineDelivery')
      : action === REQUEST_CHANGE
      ? translate('opportunity.requestChanges')
      : translate('opportunity.deliveries.unableToComplete');

  const onSubmit = React.useCallback(async () => {
    const {
      action,
      itemId,
      match: {
        params: { token },
      },
      CanNotDeliver,
      status,
      userId,
      vendorId,
      liftAndShiftVendor,
      pickupTypeId,
      onCancel,
      loadDispatchOpportunitiesCount,
      updateHaulerOpportunity,
    } = props;

    const requestDeliveryObj: any = {};
    requestDeliveryObj.ItemId = itemId;
    requestDeliveryObj.liftAndShiftVendor = liftAndShiftVendor;

    if (action === REQUEST_CHANGE && pickupTypeId === DELIVERY) {
      requestDeliveryObj.Response = 'WillDeliverOnNewDate';
      requestDeliveryObj.CanNotDeliver = CanNotDeliver;
      if (CanNotDeliver.deliveryDate) {
        CanNotDeliver.deliveryDate = moment(CanNotDeliver.deliveryDate).format('YYYY-MM-DD');
      }
    } else if (pickupTypeId === REMOVAL) {
      requestDeliveryObj.Response = CanNotDeliver.deliveryDate ? 'WillRemoveOnNewDate' : 'CouldNotRemoveBin';
      requestDeliveryObj.cannotRemoveBin = CanNotDeliver;
      if (CanNotDeliver.deliveryDate) {
        requestDeliveryObj.cannotRemoveBin.deliveryDate = moment(CanNotDeliver.deliveryDate).format('YYYY-MM-DD');
      }
    } else {
      const responseStatus = status === 'AwaitingPreDeliveryConf' ? 'CannotDeliverOnTime' : 'CouldNotDeliver';
      requestDeliveryObj.Response = CanNotDeliver.deliveryDate ? 'WillDeliverOnNewDate' : responseStatus;
      requestDeliveryObj.CanNotDeliver = CanNotDeliver;
      CanNotDeliver.deliveryDate =
        CanNotDeliver.reason === CONTAINER_PLACEMENT_ISSUE ||
        CanNotDeliver.reason === LOCATION_INACCESSIBLE ||
        CanNotDeliver.reason === PERSONNEL_ISSUE
          ? CanNotDeliver.deliveryDate
          : undefined;
      if (CanNotDeliver.deliveryDate) {
        CanNotDeliver.deliveryDate = moment(CanNotDeliver.deliveryDate).format('YYYY-MM-DD');
      }
    }

    const promise = updateHaulerOpportunity(requestDeliveryObj, userId, token, vendorId);

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        const isReload = true;
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true, isReload);
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);
  return (
    <Modal size="small" isLoading={isSaving}>
      <ModalTitle>{modalTitle}</ModalTitle>
      <ModalSubtitle />
      <Grid>
        <GridColumn size="8/12" shift="2/12">
          <ServiceUnableToDeliverForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('serviceUnableToDeliver');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `deliveries.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `deliveries.settings${ownProps.caseId}.requestedChanges`),
  WasDelivered: formSelector(state, `deliveries.settings${ownProps.caseId}.WasDelivered`),
  CanNotDeliver: formSelector(state, `deliveries.settings${ownProps.caseId}.CanNotDeliver`),
  isSaving: state.opportunity.opportunity.isSaving,
});

export default connect(mapStateToProps)(ServiceUnableToDeliverModal);
