import React, { DragEvent, Fragment, MouseEvent, PureComponent } from 'react';

import { ACCOUNT_STATUSES } from '../../../constants';
import { Customer, Location } from 'src/routes/interfaces/Route';
import { DragHandle, UnconnectedDaysOfWeekPicker } from '../../../../core/components';
import { Service } from 'src/routes/interfaces/RouteLocation';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';

interface Props {
  accountStatusId: number;
  customer: Customer;
  location: Location;
  newOrderNumber: number;
  openOrderNumberPopover: (newOrderNumber: number, event: MouseEvent, id: number) => void;
  orderNumber: number;
  scheduledDays: any[];
  service: Service;
}

interface State {
  isRouteLocationNotesModalOpen: boolean;
}

class RouteTemplateSequenceEditorPageStopsWithZeroTableRow extends PureComponent<Props, State> {
  state = {
    isRouteLocationNotesModalOpen: false,
  };

  openRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: true });
  };

  closeRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: false });
  };

  handleDragStart = (id: number) => (event: DragEvent<HTMLDivElement>) => {
    const dt = event.nativeEvent.dataTransfer;
    dt?.setData('routeLocationId', id.toString());
  };

  render() {
    const {
      newOrderNumber,
      orderNumber,
      customer: { name: customerName, accountNumber },
      location: {
        id,
        name: locationName,
        vendorAccountNo,
        address: { street, streetNumber },
      },
      accountStatusId,
      service,
      openOrderNumberPopover,
      scheduledDays,
    } = this.props;

    const tableCellWidths = ['5%', '11%', '9%', '30%', '15%', '15%', '15%'];

    return (
      <Fragment>
        <TableRow height={70} draggable onDragStart={this.handleDragStart(id)}>
          <TableCell width={tableCellWidths[0]}>
            <DragHandle />
          </TableCell>
          <TableCell width={tableCellWidths[1]}>
            <TableActionButton onClick={event => openOrderNumberPopover(newOrderNumber, event, id)}>
              {newOrderNumber}
            </TableActionButton>
          </TableCell>
          <TableCell width={tableCellWidths[2]}>
            <TableActionButton>{orderNumber}</TableActionButton>
          </TableCell>
          <TableCell vertical width={tableCellWidths[3]}>
            <Text block weight="medium" margin="no no xxSmall">
              {customerName} {accountNumber && `(${accountNumber})`}
            </Text>
            <Text weight="light" size="small" margin="no no xxSmall">
              {locationName}, {streetNumber} {street} {vendorAccountNo && `(${vendorAccountNo})`}
            </Text>
          </TableCell>
          <TableCell width={tableCellWidths[4]}>
            {(!!accountStatusId && ACCOUNT_STATUSES[accountStatusId].name) || '-'}
          </TableCell>
          <TableCell width={tableCellWidths[5]}>{service && service.name}</TableCell>
          <TableCell width={tableCellWidths[6]}>
            <UnconnectedDaysOfWeekPicker
              selectedWeekdays={scheduledDays}
              isReadOnly
              multiple
              dayOfWeekProps={{ margin: "no xSmall no no'" }}
              daysOfWeekProps={{ margin: 'no' }}
            />
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

export default RouteTemplateSequenceEditorPageStopsWithZeroTableRow;
