import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { CUSTOMER_SUBTYPES } from 'src/common/constants';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { clearStreetNetworkServiceAreasSelectedFeature } from 'src/customers/ducks';

const HaulerLocationGLPopup: FC = () => {
  const dispatch = useDispatch();
  const haulerLocations = useSelector(state => state.customers.streetNetworkServiceAreas.haulerLocations);
  const selectedFeature = useSelector(state => state.customers.streetNetworkServiceAreas.selectedFeature);

  const haulerLocation = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'haulerLocations') {
      return undefined;
    }

    return haulerLocations.find(location => location.id === selectedFeature.id);
  }, [selectedFeature, haulerLocations]);

  if (!haulerLocation) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={haulerLocation.latitude}
      longitude={haulerLocation.longitude}
      onClose={() => dispatch(clearStreetNetworkServiceAreasSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle title={haulerLocation.locationName} />

        <MapGLPopupContent>
          {!!haulerLocation.customerType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.locationType')}
              value={CUSTOMER_SUBTYPES[haulerLocation.customerSubTypeId].name}
            />
          )}

          {!!haulerLocation.address && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('insights.address')}
              value={haulerLocation.address}
            />
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default HaulerLocationGLPopup;
