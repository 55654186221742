import React from 'react';
import { useDispatch } from 'react-redux';
import { Resolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import BillHistoryModal from './BillHistoryModal';
import { loadBillHistory } from 'src/finance/ducks/billHistory';

interface Props {
  closeModal: any;
  billInfo: {
    status: string,
    siteAddress: string,
    siteName: string,
    customerName: string, 
    billId: number,
  };
}

const BillHistoryModalResolver = ({
  closeModal,
  billInfo
}: Props) => {
  const dispatch = useDispatch();

  const loadDependencies = () => {
    const dependencies: Promise<unknown>[] = [loadBillHistory(billInfo.billId)(dispatch)];

    return Promise.all(dependencies);
  };

  return (
    <Resolver
      successComponent={BillHistoryModal}
      loadingComponent={PageLoadingOverlay}
      successProps={{
        onCancel: closeModal,
        billInfo,
      }}
      resolve={loadDependencies}
    />
  );
}

export default BillHistoryModalResolver;
