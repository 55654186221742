import { map } from 'lodash-es';

import { http } from '../../core/services/http';

export const loadRouteAssistSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/routeAssistSettings`).then(response => response.data);

export const saveRouteAssistSettings = (vendorId: number, routeAssistSettings: any) =>
  http
    .post(`/vendors/${vendorId}/routeAssistSettings`, map(routeAssistSettings))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
