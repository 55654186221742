import React, { Fragment, PureComponent } from 'react';
import { forEach, get, pick, size } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import {
  DisposalSiteLocationDropdown,
  DisposalSiteLocationText,
  WeightMeasurementUnitDropdown,
  WeightMeasurementUnitText,
} from '../../../common/components';
import { TONS, POUNDS, KILOGRAMS, WEIGHT_MEASUREMENT_UNITS } from '../../../common/constants';
import {
  ActionButtonTooltip,
  DateTimeDisplay,
  FileOpenDialogToggle,
  InlineEditor,
  ThumbnailSet,
} from '../../../core/components';
import { Button, Container, TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { ROLL_OFF, STREET_SWEEPER, WASTE_AUDIT } from '../../../fleet/constants';
import { DisplayRouteCustomerText, RouteCustomerDropdown } from '..';
import { WeightTicketImagesModal } from '../modals';
import { uploadWeightTicketImage, linkImageToWeightTicket, loadRoute, saveWeightTicket } from '../../ducks';
import { DuckFunction } from 'src/contracts/ducks';

const validateWeightTicket = ({ ticketNumber, locationId, weight, unitOfMeasureTypeId }: any) =>
  !!ticketNumber && !!locationId && !!weight && weight > 0 && !!unitOfMeasureTypeId;

const TONS_LIMIT = 55;
const POUNDS_LIMIT = 110000;
const KILOGRAMS_LIMIT = 49895;

const validateWeightTicketWeight = ({ weight, unitOfMeasureTypeId }: any) =>
  (weight <= TONS_LIMIT && unitOfMeasureTypeId === TONS) ||
  (weight <= POUNDS_LIMIT && unitOfMeasureTypeId === POUNDS) ||
  (weight <= KILOGRAMS_LIMIT && unitOfMeasureTypeId === KILOGRAMS);

interface ComponentProps extends WrappedFieldArrayProps {
  change: any;
  deleteWeightTicket: (ticketId: number) => void;
  fieldArrayKey: string;
  index: number;
  linkImageToWeightTicket: DuckFunction<typeof linkImageToWeightTicket>;
  loadRoute: DuckFunction<typeof loadRoute>;
  routeId: number;
  saveWeightTicket: DuckFunction<typeof saveWeightTicket>;
  uploadWeightTicketImage: DuckFunction<typeof uploadWeightTicketImage>;
  vehicleTypeTechnicalName: string;
}

interface State {
  isInEditMode: boolean;
  isWeightTicketImagesModalOpen: boolean;
  uploadedImageId?: number;
  uploadedImageUrl?: string;
  wasImageUploaded: boolean;
  weightTicket: any;
}

class WeightTicketsFormTableRow extends PureComponent<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);
    const { index, fields } = props;
    const weightTicket = fields.get(index);

    this.state = {
      isInEditMode: !weightTicket.id,
      isWeightTicketImagesModalOpen: false,
      wasImageUploaded: false,
      weightTicket,
    };
  }

  onImageUpload = (files: FileList | null) => {
    const { uploadWeightTicketImage, routeId } = this.props;
    if (files)
      uploadWeightTicketImage(files[0], routeId)
        .then(async response => {
          this.setState({
            wasImageUploaded: true,
            uploadedImageId: response[0].id,
            uploadedImageUrl: response[0].imageUrl,
          });
          createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
        })
        .catch(() => {
          createErrorNotification(translate('common.alertMessages.invalidImageFormat'));
        });
  };

  startEditing = () => {
    this.setState({ isInEditMode: true });
  };

  cancelEditing = () => {
    const { index, fields, change } = this.props;
    const { weightTicket } = this.state;

    const isExisting = !!fields.get(index).id;

    if (!isExisting) {
      fields.remove(index);
      return;
    }

    forEach(pick(weightTicket, 'ticketNumber', 'locationId', 'weight', 'unitOfMeasureTypeId'), (value, name) =>
      change(`weightTickets[${index}].${name}`, value),
    );

    this.setState({
      isInEditMode: false,
      wasImageUploaded: false,
      uploadedImageId: undefined,
      uploadedImageUrl: undefined,
    });
  };

  completeEditing = async () => {
    const { change, fields, index, linkImageToWeightTicket, loadRoute, routeId, saveWeightTicket } = this.props;
    const { uploadedImageId, uploadedImageUrl } = this.state;
    const weightTicket = fields.get(index);

    if (!validateWeightTicket(weightTicket)) {
      createErrorNotification(translate('routes.alertMessages.weightTicketValidationFailed'));
      return;
    }

    if (!validateWeightTicketWeight(weightTicket)) {
      switch (weightTicket.unitOfMeasureTypeId) {
        case TONS:
          return createErrorNotification(
            translate('routes.alertMessages.weightTicketWeightValidationFailed', {
              limit: TONS_LIMIT,
              unitOfMeasureType: WEIGHT_MEASUREMENT_UNITS[TONS].name,
            }),
          );
        case POUNDS:
          return createErrorNotification(
            translate('routes.alertMessages.weightTicketWeightValidationFailed', {
              limit: POUNDS_LIMIT,
              unitOfMeasureType: WEIGHT_MEASUREMENT_UNITS[POUNDS].name,
            }),
          );
        case KILOGRAMS:
          return createErrorNotification(
            translate('routes.alertMessages.weightTicketWeightValidationFailed', {
              limit: KILOGRAMS_LIMIT,
              unitOfMeasureType: WEIGHT_MEASUREMENT_UNITS[KILOGRAMS].name,
            }),
          );
        default:
          return;
      }
    }

    if (uploadedImageId) {
      weightTicket.image = {
        url: uploadedImageUrl,
      };
    }

    const { id, activityTimeLocal, routeLegId } = await saveWeightTicket(routeId, weightTicket);

    if (uploadedImageId) {
      await linkImageToWeightTicket(uploadedImageId, routeLegId);
    }

    if (!weightTicket.id) {
      change(`weightTickets[${index}].id`, id);
      change(`weightTickets[${index}].activityTimeLocal`, activityTimeLocal);
    }
    this.setState({
      weightTicket: { ...weightTicket, id, activityTimeLocal },
      isInEditMode: false,
      wasImageUploaded: false,
      uploadedImageId: undefined,
    });

    await loadRoute(routeId);
    createSuccessNotification(translate('routes.alertMessages.weightTicketSaved'));
  };

  deleteWeightTicket = async () => {
    const { routeId, loadRoute, index, fields, deleteWeightTicket } = this.props;
    const { id } = fields.get(index);

    if (!(await confirm(translate('routes.alertMessages.confirmDeleteWeightTicket')))) {
      return;
    }

    await deleteWeightTicket(id);
    await loadRoute(routeId);
    fields.remove(index);
  };

  openWeightTicketImagesModal = () => {
    this.setState({ isWeightTicketImagesModalOpen: true });
  };

  closeWeightTicketImagesModal = () => {
    this.setState({ isWeightTicketImagesModalOpen: false });
  };

  renderActionItems = () => {
    const { vehicleTypeTechnicalName, routeId } = this.props;
    const { wasImageUploaded, weightTicket } = this.state;

    const showImageUploadIcon =
      !wasImageUploaded && vehicleTypeTechnicalName !== STREET_SWEEPER && vehicleTypeTechnicalName !== WASTE_AUDIT;

    return (
      <Fragment>
        {showImageUploadIcon && (
          <FileOpenDialogToggle
            margin="no xSmall no no"
            icon="upload"
            accept=".jpg"
            onFilesSelected={this.onImageUpload}
          />
        )}

        <Button
          id={`route-${routeId}-weight-ticket-${weightTicket.id}-cancel-button`}
          type="button"
          size="small"
          color="secondary"
          margin="no xxSmall no no"
          onClick={this.cancelEditing}
        >
          {translate('common.cancel')}
        </Button>

        <Button
          id={`route-${routeId}-weight-ticket-${weightTicket.id}-save-button`}
          type="button"
          size="small"
          color="primary"
          onClick={this.completeEditing}
        >
          {translate('common.save')}
        </Button>
      </Fragment>
    );
  };

  render() {
    const { fieldArrayKey, routeId, vehicleTypeTechnicalName } = this.props;
    const { isInEditMode, isWeightTicketImagesModalOpen, weightTicket } = this.state;

    const { image } = weightTicket;
    const imageUrl = image ? [get(image, 'url')] : [];

    return (
      <Fragment>
        {vehicleTypeTechnicalName === ROLL_OFF ? (
          <TableRow id={`route-${routeId}-weight-ticket-${weightTicket.id}-table-row`} isHighlighted={isInEditMode}>
            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-ticket-number-cell`} width="10%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.ticketNumber`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  inputProps={{ placeholder: translate('routes.ticketNumber'), margin: 'no' }}
                />
              </Container>
            </TableCell>

            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-location-id-cell`} width="17%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.locationId`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  displayComponent={DisposalSiteLocationText}
                  inputComponent={DisposalSiteLocationDropdown}
                  inputProps={{ withPlaceholder: true, dropdownProps: { margin: 'no' } }}
                />
              </Container>
            </TableCell>

            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-weight-cell`} width="9%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.weight`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  inputProps={{ placeholder: translate('common.weight'), margin: 'no' }}
                />
              </Container>
            </TableCell>
            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-unit-of-measure-cell`} width="14%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.unitOfMeasureTypeId`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  displayComponent={WeightMeasurementUnitText}
                  inputComponent={WeightMeasurementUnitDropdown}
                  inputProps={{ withPlaceholder: true, dropdownProps: { margin: 'no' } }}
                />
              </Container>
            </TableCell>
            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-activity-time-cell`} width="13%" vertical>
              <Field name={`${fieldArrayKey}.activityTimeLocal`} component={DateTimeDisplay} />
            </TableCell>
            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-route-location-cell`} width="18%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.routeLocationId`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  displayComponent={DisplayRouteCustomerText}
                  inputComponent={RouteCustomerDropdown}
                  inputProps={{ withPlaceholder: true, dropdownProps: { margin: 'no' } }}
                />
              </Container>
            </TableCell>
            {!isInEditMode && (
              <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-image-cell`} width="8%">
                {!!size(imageUrl) && <ThumbnailSet images={imageUrl} onClick={this.openWeightTicketImagesModal} />}
              </TableCell>
            )}
            {!isInEditMode && (
              <TableCell
                id={`route-${routeId}-weight-ticket-${weightTicket.id}-action-buttons-cell`}
                width="12%"
                align="right"
              >
                <Fragment>
                  <TableActionButton
                    id={`route-${routeId}-weight-ticket-${weightTicket.id}-edit-button`}
                    onClick={this.startEditing}
                  >
                    <ActionButtonTooltip icon="edit" tooltip="edit" />
                  </TableActionButton>

                  <TableActionButton
                    id={`route-${routeId}-weight-ticket-${weightTicket.id}-delete-button`}
                    onClick={this.deleteWeightTicket}
                  >
                    <ActionButtonTooltip icon="delete" tooltip="delete" />
                  </TableActionButton>
                </Fragment>
              </TableCell>
            )}
            {isInEditMode && (
              <TableCell
                id={`route-${routeId}-weight-ticket-${weightTicket.id}-action-items-cell`}
                width="25%"
                align="right"
              >
                {this.renderActionItems()}
              </TableCell>
            )}
          </TableRow>
        ) : (
          <TableRow id={`route-${routeId}-weight-ticket-${weightTicket.id}-table-row`} isHighlighted={isInEditMode}>
            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-ticket-number-cell`} width="10%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.ticketNumber`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  inputProps={{ placeholder: translate('routes.ticketNumber'), margin: 'no' }}
                />
              </Container>
            </TableCell>

            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-location-id-cell`} width="34%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.locationId`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  displayComponent={DisposalSiteLocationText}
                  inputComponent={DisposalSiteLocationDropdown}
                  inputProps={{ withPlaceholder: true, dropdownProps: { margin: 'no' } }}
                />
              </Container>
            </TableCell>

            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-weight-cell`} width="9%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.weight`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  inputProps={{ placeholder: translate('common.weight'), margin: 'no' }}
                />
              </Container>
            </TableCell>

            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-unit-of-measure-cell`} width="14%">
              <Container>
                <Field
                  name={`${fieldArrayKey}.unitOfMeasureTypeId`}
                  component={InlineEditor}
                  isInEditMode={isInEditMode}
                  displayComponent={WeightMeasurementUnitText}
                  inputComponent={WeightMeasurementUnitDropdown}
                  inputProps={{ withPlaceholder: true, dropdownProps: { margin: 'no' } }}
                />
              </Container>
            </TableCell>

            <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-activity-time-cell`} width="13%" vertical>
              <Field name={`${fieldArrayKey}.activityTimeLocal`} component={DateTimeDisplay} />
            </TableCell>

            {!isInEditMode && (
              <TableCell id={`route-${routeId}-weight-ticket-${weightTicket.id}-image-cell`} width="8%">
                {!isInEditMode && !!size(imageUrl) && (
                  <ThumbnailSet images={imageUrl} onClick={this.openWeightTicketImagesModal} />
                )}
              </TableCell>
            )}
            {!isInEditMode && (
              <TableCell
                id={`route-${routeId}-weight-ticket-${weightTicket.id}-action-buttons-cell`}
                width="12%"
                align="right"
              >
                <Fragment>
                  <TableActionButton
                    id={`route-${routeId}-weight-ticket-${weightTicket.id}-edit-button`}
                    onClick={this.startEditing}
                  >
                    <ActionButtonTooltip icon="edit" tooltip="edit" />
                  </TableActionButton>

                  <TableActionButton
                    id={`route-${routeId}-weight-ticket-${weightTicket.id}-delete-button`}
                    onClick={this.deleteWeightTicket}
                  >
                    <ActionButtonTooltip icon="delete" tooltip="delete" />
                  </TableActionButton>
                </Fragment>
              </TableCell>
            )}
            {isInEditMode && (
              <TableCell
                id={`route-${routeId}-weight-ticket-${weightTicket.id}-action-items-cell`}
                width="20%"
                align="right"
              >
                {this.renderActionItems()}
              </TableCell>
            )}
          </TableRow>
        )}

        {!!isWeightTicketImagesModalOpen && (
          <WeightTicketImagesModal weightTicket={weightTicket} closeModal={this.closeWeightTicketImagesModal} />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  uploadWeightTicketImage,
  linkImageToWeightTicket,
};

export default connect(undefined, mapDispatchToProps)(WeightTicketsFormTableRow);
