import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { getFormValues, initialize } from 'redux-form';

import { saveProximitySettings, resetProximitySettings } from '../../ducks';
import { ProximitySettingsForm } from '../forms';
import { createSuccessNotification } from '../../../core/services/createNotification';
import getProximitySettings from '../../services/getProximitySettingsFormData';
import { AppState } from '../../../store';
import { Dispatch } from 'redux';

interface Props extends RouteComponentProps {
  formValues?: any;
  saveProximitySettings(...args: any[]): any;
  resetProximitySettings(...args: any[]): any;
}

class ProximitySettingsPage extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetProximitySettings();
  }

  onSubmit = async (formData: any, dispatch: Dispatch) => {
    await this.props.saveProximitySettings(getProximitySettings(formData));
    dispatch(initialize('proximitySettings', this.props.formValues));
    createSuccessNotification('Settings were saved.');
  };

  render() {
    return <ProximitySettingsForm onSubmit={this.onSubmit} />;
  }
}

const mapStateToProps = (state: AppState) => ({
  formValues: getFormValues('proximitySettings')(state),
});

const mapDispatchToProps = {
  saveProximitySettings,
  resetProximitySettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProximitySettingsPage));
