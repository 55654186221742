import { ChangeEvent } from 'react';
import { getFormValues } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Checkbox, Dropdown, Input, TypedField } from 'src/core/components';
import { FormError, TableCell, TableRow } from 'src/core/components/styled';
import { RATE_CONFIGURATION_EDIT_FORM_NAME } from '../../forms/RateConfigurationEditForm';
import { ChangedRateCodes, RateCodes, RateConfigurationEditFormValues } from 'src/finance/interfaces/RateManager';
import { setChangedRateCodes } from 'src/finance/ducks';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

enum OnChangeTrigger {
  ENABLED = 'enabled',
  ACCOUNTING_CODE = 'accountingCode',
  RATE_CODE = 'rateCode',
  RATE_CODE_DESCRIPTION = 'rateCodeDescription',
}

interface Props extends RateCodes {
  id: number;
  index: number;
}

const RateConfigurationEditTableRow = ({ id, index }: Props) => {
  const dispatch = useDispatch();

  const { billingUnitOfMeasureTypes, rateAccountingCodes, changedRateCodes } = useSelector(
    state => state.finance.rateManager,
  );
  const formValues = useSelector(state => getFormValues(RATE_CONFIGURATION_EDIT_FORM_NAME)(state));

  const isEnabled = formValues && !!(formValues as RateConfigurationEditFormValues[])[index]?.enabled;
  // const accountingCode = formValues && !!(formValues as RateConfigurationEditFormValues[])[index]?.accountingCode;
  const typeCode = formValues && !!(formValues as RateConfigurationEditFormValues[])[index]?.typeCode;
  const description = formValues && !!(formValues as RateConfigurationEditFormValues[])[index]?.description;
  const unitOfMeasureTypeIds =
    formValues && (formValues as RateConfigurationEditFormValues[])[index]?.unitOfMeasureTypeIds;
  const hasUnitOfMeasureTypeIds = (unitOfMeasureTypeIds as boolean[])?.filter((uom: boolean) => !!uom).length;

  const getUomTableCells = () => {
    const uomTableCells: any[] = [];

    billingUnitOfMeasureTypes?.forEach((uom, currentIndex) => {
      const onUnitOfMeasureTypeIdsChange = (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
        const newChangedRateCodes: ChangedRateCodes[] = [];

        const newChangedRateCode = changedRateCodes.find(changedRateCode => changedRateCode.id === id);
        if (newChangedRateCode) {
          newChangedRateCodes.push({
            ...newChangedRateCode,
            unitOfMeasureTypeIds: !!newChangedRateCode.unitOfMeasureTypeIds?.length
              ? [...newChangedRateCode.unitOfMeasureTypeIds, { index: currentIndex, value, id: uom.id }]
              : [{ index: currentIndex, value, id: uom.id }],
          });
        } else {
          newChangedRateCodes.push({ unitOfMeasureTypeIds: [{ index: currentIndex, value, id: uom.id }], id });
        }

        changedRateCodes.forEach(changedRateCode => {
          if (changedRateCode.id !== id) newChangedRateCodes.push(changedRateCode);
        });

        setChangedRateCodes(newChangedRateCodes)(dispatch);
      };

      const uomTableCell = (
        <TableCell key={uom.id} width={`${40 / billingUnitOfMeasureTypes?.length}%`}>
          <TypedField
            name={`[${index}].unitOfMeasureTypeIds.[${currentIndex}]`}
            component={Checkbox}
            props={{ disabled: isEnabled === false }}
            onChange={onUnitOfMeasureTypeIdsChange}
          />
          {currentIndex === 0 && isEnabled && !hasUnitOfMeasureTypeIds && (
            <FormError errorOnSingleLine errorTopPosition="50px">
              {translate('finance.rateManager.atLeastOneUom')}
            </FormError>
          )}
        </TableCell>
      );

      uomTableCells.push(uomTableCell);
    });

    return uomTableCells;
  };

  const rateAccountingCodeOptions = rateAccountingCodes.map(rateAccountingCode => ({
    label: rateAccountingCode.code,
    value: rateAccountingCode.id,
  }));

  const onChange = (value: boolean | string, trigger: string) => {
    const newChangedRateCodes: ChangedRateCodes[] = [];

    let triggerSource = {};

    switch (trigger) {
      case OnChangeTrigger.ENABLED:
        triggerSource = { enabled: value };
        break;

      case OnChangeTrigger.ACCOUNTING_CODE:
        triggerSource = { accountingCode: value };
        break;

      case OnChangeTrigger.RATE_CODE:
        triggerSource = { rateCode: value };
        break;

      case OnChangeTrigger.RATE_CODE_DESCRIPTION:
        triggerSource = { rateCodeDescription: value };
        break;

      default:
        break;
    }

    const newChangedRateCode = changedRateCodes.find(changedRateCode => changedRateCode.id === id);
    if (newChangedRateCode) {
      newChangedRateCodes.push({ ...newChangedRateCode, ...triggerSource });
    } else {
      newChangedRateCodes.push({ ...triggerSource, id });
    }

    changedRateCodes.forEach(changedRateCode => {
      if (changedRateCode.id !== id) newChangedRateCodes.push(changedRateCode);
    });

    setChangedRateCodes(newChangedRateCodes)(dispatch);
  };

  const onEnabledChange = (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
    onChange(value, OnChangeTrigger.ENABLED);
  };

  const onAccountingCodeChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value, OnChangeTrigger.ACCOUNTING_CODE);
  };

  const onRateCodeChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value, OnChangeTrigger.RATE_CODE);
  };

  const onRateCodeDescriptionChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value, OnChangeTrigger.RATE_CODE_DESCRIPTION);
  };

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
      <TableCell width="10%">
        <TypedField name={`[${index}].enabled`} component={Checkbox} onChange={onEnabledChange} />
      </TableCell>
      <TableCell width="15%">
        <TypedField
          name={`[${index}].typeCode`}
          component={Input}
          props={{
            margin: 'no',
            placeholder: translate('finance.rateManager.rateCode'),
          }}
          onChange={onRateCodeChange}
        />

        {!typeCode && (
          <FormError errorOnSingleLine errorTopPosition="50px" id="accountingCodeRequired">
            {translate('finance.rateManager.required')}
          </FormError>
        )}
      </TableCell>
      <TableCell width="20%">
        <TypedField
          name={`[${index}].description`}
          component={Input}
          props={{
            margin: 'no',
            placeholder: translate('finance.rateManager.rateCodeDescription'),
          }}
          onChange={onRateCodeDescriptionChange}
        />

        {!description && (
          <FormError errorOnSingleLine errorTopPosition="50px" id="accountingCodeRequired">
            {translate('finance.rateManager.required')}
          </FormError>
        )}
      </TableCell>
      <TableCell width="15%">
        <TypedField
          name={`[${index}].accountingCode`}
          component={Dropdown}
          props={{
            disabled: isEnabled === false,
            placeholder: translate('finance.rateManager.accountingCode'),
            options: rateAccountingCodeOptions,
            margin: 'no',
            isClearable: true,
            width: '100%',
            menuPosition: 'fixed',
          }}
          onChange={onAccountingCodeChange}
        />
      </TableCell>
      {getUomTableCells()}
    </TableRow>
  );
};

export default RateConfigurationEditTableRow;
