import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { map, filter } from 'lodash-es';

import { AppState } from 'src/store';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import translate from '../../core/services/translate';
import { NONE_ZERO_ID } from '../constants/routePriorityTypes';

interface Props extends DropDownFieldProps {
  serviceZones?: any[];
  excludeServiceZonesIds?: number[];
  includeNoneOption?: boolean;
}

class ServiceZoneDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      serviceZones,
      excludeServiceZonesIds,
      includeNoneOption,
    } = this.props;

    const serviceZoneOptions =
      excludeServiceZonesIds && excludeServiceZonesIds.length
        ? map(
            filter(
              serviceZones,
              serviceZone => !excludeServiceZonesIds.includes(serviceZone.id) && serviceZone.isActive,
            ),
            serviceZone => ({
              label: serviceZone.name,
              value: serviceZone.id,
            }),
          )
        : map(filter(serviceZones, { isActive: true }), serviceZone => ({
            label: serviceZone.name,
            value: serviceZone.id,
          }));
    if (includeNoneOption) {
      serviceZoneOptions.unshift({
        label: translate('routes.noServiceZone'),
        value: NONE_ZERO_ID,
      });
    }

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('routes.serviceZone') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('routes.serviceZone') : undefined),
          options: serviceZoneOptions,
          isClearable: true,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  serviceZones: state.routes.serviceZones.serviceZones,
});

export default connect(mapStateToProps)(ServiceZoneDropdown);
