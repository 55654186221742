import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { VehicleStrobeImagesSettingsItem } from '../interfaces/VehicleStrobeImagesSettings';

import {
  loadVehicleStrobeImagesSettings as doLoadVehicleStrobeImagesSettings,
  saveVehicleStrobeImagesSettings as doSaveVehicleStrobeImagesSettings,
} from '../services/vehicleStrobeImages';

// Actions
const START_LOAD = 'vendors/vehicleStrobeImagesSettings/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicleStrobeImagesSettings/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicleStrobeImagesSettings/FAIL_LOAD';
const START_SAVE = 'vendors/vehicleStrobeImagesSettings/START_SAVE';
const COMPLETE_SAVE = 'vendors/vehicleStrobeImagesSettings/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/vehicleStrobeImagesSettings/FAIL_SAVE';
const RESET = 'vendors/vehicleStrobeImagesSettings/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  vehicleStrobeImagesSettings: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleStrobeImagesSettings: action.vehicleStrobeImagesSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleStrobeImagesSettings: VehicleStrobeImagesSettingsItem[]) => ({
  type: COMPLETE_LOAD,
  vehicleStrobeImagesSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const reset = () => ({
  type: RESET,
});

export const loadVehicleStrobeImagesSettings = (vendorId: number) => (dispatch: any) => {
  dispatch(startLoad());
  const loadVehicleStrobeImagesSettingsPromise = doLoadVehicleStrobeImagesSettings(vendorId);
  loadVehicleStrobeImagesSettingsPromise
    .then((vehicleStrobeImagesSettings: any) => dispatch(completeLoad(vehicleStrobeImagesSettings)))
    .catch(() => dispatch(failLoad()));
  return loadVehicleStrobeImagesSettingsPromise;
};

export const saveVehicleStrobeImagesSettings =
  (vendorId: number, vehicleStrobeImagesSettings: VehicleStrobeImagesSettingsItem[]) => (dispatch: any) => {
    dispatch(startSave());
    const saveVehicleStrobeImagesSettingsPromise = doSaveVehicleStrobeImagesSettings(
      vendorId,
      vehicleStrobeImagesSettings,
    );
    saveVehicleStrobeImagesSettingsPromise
      .then(() => {
        dispatch(completeSave());
      })
      .catch(() => dispatch(failSave()));
    return saveVehicleStrobeImagesSettingsPromise;
  };

export const resetVehicleStrobeImagesSettings = () => (dispatch: any) => {
  dispatch(reset());
};
