import React, { useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';

import { DispatchBoardFlexOverflowWrapper, DispatchBoardFlexWrapper } from '../../styled';
import { DispatchBoardRouteBuilderFilterForm } from '../../forms';
import { DispatchBoardRouteJob } from '../../../interfaces/DispatchBoardRouteJob';
import { FormData as RouteBuilderFilters } from '../../forms/DispatchBoardRouteBuilderFilterForm';
import { REACT_WINDOW_CLASS_NAME } from '../../../../core/constants/reactWindow';
import { ROUTE_BUILDER_JOBS_LIST_HEIGHT } from '../../../../core/constants/routeBuilder';
import { TechnicalType } from '../../../../common/interfaces/TechnicalType';
import { UnconnectedCheckbox } from '../../../../core/components';
import DispatchBoardBuilderModalJob from '../../pages/dispatchBoard/dispatchBoardPageSections/DispatchBoardBuilderModalJob';

type ItemProps = {
  style: React.CSSProperties;
  index: number;
  data: {
    jobs: DispatchBoardRouteJob[];
    itemHeightExpanded: number;
    selectedJobs: number[];
    filterCount: number;
    expandedJobs: number[];
    handleCheck: (id: number, index: number) => void;
    toggleExpand: (id: number) => void;
  };
};

const Item: React.FC<ItemProps> = ({ style, index, data }) => {
  const { jobs = [], itemHeightExpanded, selectedJobs, filterCount, expandedJobs, handleCheck, toggleExpand } = data;
  const job = jobs[index];

  return (
    <div style={style}>
      <DispatchBoardFlexWrapper key={`${job.id}-${filterCount}`}>
        <UnconnectedCheckbox
          margin="sMedium no no"
          checked={selectedJobs.indexOf(job.id) > -1}
          onChange={() => handleCheck(job.id, index)}
        />
        <DispatchBoardBuilderModalJob
          expandedItemHeight={itemHeightExpanded}
          job={job}
          isExpanded={expandedJobs.indexOf(job.id) !== -1}
          toggleExpand={() => toggleExpand(job.id)}
        />
      </DispatchBoardFlexWrapper>
    </div>
  );
};

type Props = {
  allSelected: boolean;
  filterCount: number;
  handleCheck: (id: number, index: number) => void;
  handleCheckAll: () => void;
  handleDateFilterChange: (e: any, date: Date | string) => void;
  handleEquipmentFilterChange: (e: any, equipment: TechnicalType) => void;
  handleMaterialTypeFilterChange: (e: any, materialType: TechnicalType) => void;
  handlePickupTypeFilterChange: (event: React.ChangeEvent<HTMLInputElement>, pickupTypeId: number) => void;
  handleReasonCodeTypeFilterChange: (e: any, reasonCode: TechnicalType) => void;
  handleSearchTermFilterChange: (e: any, searchTerm: string) => void;
  itemHeightExpanded: number;
  jobs?: DispatchBoardRouteJob[];
  partial?: boolean;
  selectedJobs: number[];
  filters: RouteBuilderFilters;
};

const RouteBuilderFilter: React.FC<Props> = ({
  allSelected,
  filterCount,
  filters,
  handleCheck,
  handleCheckAll,
  handleDateFilterChange,
  handleEquipmentFilterChange,
  handleMaterialTypeFilterChange,
  handlePickupTypeFilterChange,
  handleReasonCodeTypeFilterChange,
  handleSearchTermFilterChange,
  itemHeightExpanded,
  jobs = [],
  partial,
  selectedJobs,
}) => {
  const [expandedJobs, setExpandedJobs] = React.useState<number[]>([]);
  const listRef = React.useRef<List>(null);

  const getElementSize = (index: number) => {
    if (index < 0 || index >= jobs.length) {
      return 0;
    }

    return expandedJobs.indexOf(jobs[index].id) !== -1 ? itemHeightExpanded : 90;
  };

  const getElementKey = (index: number) => jobs[index].id;

  const toggleExpand = (id: number) => {
    const copy = expandedJobs.slice();
    const jobIndex = copy.indexOf(id);

    if (jobIndex === -1) {
      copy.push(id);
    } else {
      copy.splice(jobIndex, 1);
    }

    setExpandedJobs([...copy]);
  };

  useEffect(() => {
    setExpandedJobs([]);
  }, [filters]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [expandedJobs.length]);

  return (
    <>
      <DispatchBoardRouteBuilderFilterForm
        allSelected={allSelected}
        handleCheckAll={handleCheckAll}
        handleDateFilterChange={handleDateFilterChange}
        handleEquipmentFilterChange={handleEquipmentFilterChange}
        handleMaterialTypeFilterChange={handleMaterialTypeFilterChange}
        handlePickupTypeFilterChange={handlePickupTypeFilterChange}
        handleReasonCodeTypeFilterChange={handleReasonCodeTypeFilterChange}
        handleSearchTermFilterChange={handleSearchTermFilterChange}
        numberOfJobs={jobs.length}
        partial={partial}
        initialValues={{ ...filters }}
      />
      <DispatchBoardFlexOverflowWrapper>
        <List
          ref={listRef}
          itemData={{
            jobs,
            itemHeightExpanded,
            selectedJobs,
            filterCount,
            expandedJobs,
            handleCheck,
            toggleExpand,
          }}
          itemCount={jobs.length}
          itemSize={getElementSize}
          itemKey={getElementKey}
          estimatedItemSize={90}
          className={REACT_WINDOW_CLASS_NAME}
          height={ROUTE_BUILDER_JOBS_LIST_HEIGHT}
          width="100%"
        >
          {Item}
        </List>
      </DispatchBoardFlexOverflowWrapper>
    </>
  );
};

RouteBuilderFilter.defaultProps = {
  partial: false,
};

export default RouteBuilderFilter;
