import React from 'react';
import { Modal } from 'src/core/components';
import { Button, ButtonSet, ModalFixedFooter, PanelSection, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { useDispatch } from 'react-redux';
import { ChangeEvent, RateBuilderRate } from 'src/customers/interfaces/Services';
import { arrayPush, InjectedFormProps, reduxForm } from 'redux-form';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TableCell } from 'src/core/components/Table';
import { NEW_SERVICE_ID } from 'src/customers/constants/serviceStatuses';
import AddNewRateForm from './AddNewRateForm';

interface RateCodeModalProps {
  onClose: () => void;
  formProps: {
    customerName?: string | undefined;
    location?: string | undefined;
    tableCells?: TableCell[];
    workOrderId?: number | string;
    workOrderDate?: StringOrDate;
  };
  onSubmit(values: RateBuilderRate[]): void;
}

const getNewRate = (): RateBuilderRate => ({
  overridable: false,
  id: NEW_SERVICE_ID,
  rate: 0,
  includedQuantity: 0,
  status: 'active',
});

export interface RateCodeFormValues {
  rates: RateBuilderRate[];
}
type Props = RateCodeModalProps & InjectedFormProps<RateCodeFormValues, RateCodeModalProps>;

const AddRateModal: React.FC<Props> = ({ onClose, handleSubmit, change }) => {
  const dispatch = useDispatch();

  const updateFormValue = ({ fieldName, value }: ChangeEvent) => change(fieldName, value);
  const handleAddNewRate = () => dispatch(arrayPush('rateBuilderAddRateCode', 'rates', getNewRate()));

  return (
    <Modal
      size="xxLarge"
      title={translate('customers.addRateCode')}
      onClose={onClose}
      isLoading={false}
      padding="medium no no no"
      id={'AddRateCodeModalId'}
    >
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="no small"></PanelSection>

        <AddNewRateForm updateFormValue={updateFormValue} customerName={'test'} location={'test'} />
        <ButtonSet margin="medium no xGrande no" align="center">
          <Button type="button" color="primary" line onClick={handleAddNewRate} margin="no">
            {`+ ${translate('common.new')}`}
          </Button>
        </ButtonSet>
        <Text block align="center" margin={'small'}>
          {translate('common.alertMessages.rateBuilderDisclaimer')}
        </Text>
        <ModalFixedFooter isShadowed>
          <Button type="button" color="secondary" margin="no xSmall no no" onClick={onClose}>
            {translate('common.cancel')}
          </Button>
          <Button type="submit" color="primary" margin="no no no xSmall">
            {translate('finance.modal.update')}
          </Button>
        </ModalFixedFooter>
      </form>
    </Modal>
  );
};

export default reduxForm<RateCodeFormValues, RateCodeModalProps>({
  form: 'rateBuilderAddRateCode',
  enableReinitialize: true,
})(AddRateModal);
