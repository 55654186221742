import { combineReducers } from 'redux';

import { reducer as routeReducer } from './dispatchBoardRoute';
import { reducer as sourceRoutesReducer } from './dispatchBoardSourceRoutes';
import { reducer as targetRoutesReducer } from './dispatchBoardTargetRoutes';
import { reducer as routeJobReducer } from './dispatchBoardRouteJob';
import { reducer as sourceRouteJobsReducer } from './dispatchBoardSourceRouteJobs';
import { reducer as targetRouteJobsReducer } from './dispatchBoardTargetRouteJobs';
import { reducer as unassignedJobReducer } from './dispatchBoardUnassignedJob';
import { reducer as unassignedJobsReducer } from './dispatchBoardUnassignedJobs';
import { reducer as onHoldJobsReducer } from './dispatchBoardOnHoldJobs';
import { reducer as unassignedJobsRouteNoteReducer } from './dispatchBoardUnassignedJobsRouteNote';
import { reducer as mapReducer } from './dispatchBoardMap';
import { reducer as routeBuilderReducer } from './dispatchBoardRouteBuilder';
import { reducer as exceptionsReducer } from './dispatchBoardExceptionManager';

export { loadDispatchBoardRoute, resetDispatchBoardRoute, saveDispatchBoardRoute } from './dispatchBoardRoute';
export {
  addDispatchBoardSourceRoute,
  deleteDispatchBoardSourceRoute,
  dispatchBoardSourceRouteByIdSelector,
  loadDispatchBoardSourceRoutes,
  removeDispatchBoardSourceRoute,
  resetDispatchBoardSourceRoutes,
  setSourceDate,
  updateDispatchBoardSourceRoute,
  showImagesForJob,
  hideImages,
} from './dispatchBoardSourceRoutes';
export {
  deleteDispatchBoardTargetRoute,
  dispatchBoardTargetRouteByIdSelector,
  loadDispatchBoardTargetRoutes,
  removeDispatchBoardTargetRoute,
  resetDispatchBoardTargetRoutes,
  setTargetDate,
} from './dispatchBoardTargetRoutes';
export {
  loadDispatchBoardRouteJob,
  resetDispatchBoardRouteJob,
  saveDispatchBoardRouteJob,
} from './dispatchBoardRouteJob';
export {
  deleteDispatchBoardSourceRouteJob,
  loadDispatchBoardSourceRouteJobs,
  moveDispatchBoardSourceRouteJobToPosition,
  resetDispatchBoardSourceRouteJobs,
  transferDispatchBoardSourceRouteJob,
} from './dispatchBoardSourceRouteJobs';
export {
  deleteDispatchBoardTargetRouteJob,
  loadDispatchBoardTargetRouteJobs,
  moveDispatchBoardTargetRouteJobToPosition,
  resetDispatchBoardTargetRouteJobs,
  transferDispatchBoardTargetRouteJob,
} from './dispatchBoardTargetRouteJobs';
export {
  loadDispatchBoardUnassignedJob,
  resetDispatchBoardUnassignedJob,
  saveDispatchBoardUnassignedJob,
} from './dispatchBoardUnassignedJob';
export {
  assignDispatchBoardUnassignedJobToRoute,
  deleteDispatchBoardUnassignedJob,
  loadDispatchBoardUnassignedJobs,
  loadDispatchBoardUnassignedJobsCount,
  resetDispatchBoardUnassignedJobs,
} from './dispatchBoardUnassignedJobs';
export { resetDispatchBoardOnHoldJobs } from './dispatchBoardOnHoldJobs';
export {
  loadDispatchBoardUnassignedJobRouteNote,
  saveDispatchBoardUnassignedJobRouteNote,
  resetDispatchBoardUnassignedJobRouteNote,
} from './dispatchBoardUnassignedJobsRouteNote';

export {
  assignJobsToRoute,
  assignJobsToRouteOrRouteTemplate,
  clearSelectedJobs,
  clearSelectedRoutes,
  closeMap,
  loadDispatchBoardJobs,
  openMap,
  openMapWithRoute,
  searchJobs,
  selectJobs,
  setDuplicateJobs,
  toggleRoute,
  toggleRoutes,
} from './dispatchBoardMap';

export {
  checkActiveRouteBuilder,
  createRouteBuilderRoutes,
  finishRouteBuilderJob,
  resetRouteBuilder,
} from './dispatchBoardRouteBuilder';

export {
  loadExceptions,
  clearException,
  loadRouteNames,
  saveExceptions,
  clearRoutes,
} from './dispatchBoardExceptionManager';

export { moveDispatchBoardAllJobsToPosition } from './dispatchBoardBaseJobs';

export const reducer = combineReducers({
  exceptions: exceptionsReducer,
  map: mapReducer,
  route: routeReducer,
  routeBuilder: routeBuilderReducer,
  routeJob: routeJobReducer,
  sourceRouteJobs: sourceRouteJobsReducer as any,
  sourceRoutes: sourceRoutesReducer,
  targetRouteJobs: targetRouteJobsReducer,
  targetRoutes: targetRoutesReducer,
  unassignedJob: unassignedJobReducer,
  unassignedJobs: unassignedJobsReducer,
  onHoldJobs: onHoldJobsReducer,
  unassignedJobsRouteNote: unassignedJobsRouteNoteReducer,
});
