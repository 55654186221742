import { ActionButtonTooltip, UnconnectedCheckbox, UnconnectedInput } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { PaymentAssignedBill } from 'src/finance/interfaces/ApplyChecks';
import { currency, date } from 'src/utils/services/formatter';

interface Props extends PaymentAssignedBill {
  handleUnassignOpenBill?: (openBillIds: number[]) => void;
  index: number;
  isEditable: boolean;
  onAmountToApplyChange?: (billId: number, paymentSubTotal: number) => void;
  tableCellWidths: { [key: string]: string };
  handleCheckboxOnChange?: (id: number, checked: boolean) => void;
  isSaving?: boolean;
  maxValue?: number;
}

const AssignedBillsTableRow = ({
  appliedAmountElsewhere,
  billDate,
  handleCheckboxOnChange,
  handleUnassignOpenBill,
  id,
  invoiceTotal,
  isEditable = true,
  isSaving = false,
  onAmountToApplyChange,
  paymentSubTotal,
  tableCellWidths,
  maxValue,
}: Props) => {
  const handleAmountToApplyChange = (value: number) => {
    onAmountToApplyChange && onAmountToApplyChange(id, Number(value));
  };

  const remainingBalance = invoiceTotal - appliedAmountElsewhere;
  const invoiceBalance = remainingBalance - paymentSubTotal;
  const max = maxValue ? Math.min(remainingBalance, maxValue) : remainingBalance;

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
      {tableCellWidths.checkbox ? (
        <TableCell id={`assignedBill-${id}-checkbox-cell`} width={tableCellWidths.checkbox}>
          {isEditable && (
            <UnconnectedCheckbox
              onChange={value =>
                handleCheckboxOnChange ? handleCheckboxOnChange(id, value.currentTarget.checked) : null
              }
            />
          )}
        </TableCell>
      ) : null}
      <TableCell id={`assignedBill-${id}-billId-cell`} width={tableCellWidths.id} align="left">
        <Text>{id || '–'}</Text>
      </TableCell>
      {tableCellWidths.billDate ? (
        <TableCell id={`assignedBill-${id}-billDate-cell`} width={tableCellWidths.billDate}>
          <Text>{billDate ? date(billDate) : ''}</Text>
        </TableCell>
      ) : null}
      <TableCell
        id={`assignedBill-${id}-appliedAmount-cell`}
        justifyContent="center"
        vertical
        width={tableCellWidths.appliedAmount}
      >
        <Text> {currency(appliedAmountElsewhere || 0)}</Text>
      </TableCell>

      {tableCellWidths.invoiceTotal ? (
        <TableCell
          id={`assignedBill-${id}-invoiceTotal-cell`}
          justifyContent="center"
          vertical
          width={tableCellWidths.invoiceTotal}
        >
          <Text> {currency(invoiceTotal || 0)}</Text>
        </TableCell>
      ) : null}

      <TableCell id={`assignedBill-${id}-invoiceBalance-cell`} width={tableCellWidths.invoiceBalance}>
        <Text> {currency(invoiceBalance || 0)}</Text>
      </TableCell>

      <TableCell id={`assignedBill-${id}-amountToApply-cell`} width={tableCellWidths.amountToApply}>
        {isEditable ? (
          <>
            <UnconnectedInput
              prepend="$"
              type="number"
              onChange={handleAmountToApplyChange}
              value={paymentSubTotal}
              min={0}
              max={max}
              preventNumberExponent
              disabled={isSaving}
              margin="no"
              width="100%"
              hasError={invoiceBalance < 0}
              error={translate('finance.alertMessages.amountToApplyTooLarge')}
            />

            {handleUnassignOpenBill && (
              <TableActionButton
                margin="no small"
                id={`assignedBill-${id}-amountToApply-cell`}
                onClick={() => handleUnassignOpenBill([id])}
                disabled={isSaving}
              >
                <ActionButtonTooltip icon="closedIssue" tooltip="unassignBill" />
              </TableActionButton>
            )}
          </>
        ) : (
          currency(paymentSubTotal || 0)
        )}
      </TableCell>
    </TableRow>
  );
};

export default AssignedBillsTableRow;
