import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadContainerLocationTypes as doLoadContainerLocationTypes } from '../services/containerLocationTypes';
import { TechnicalType } from '../interfaces/TechnicalType';

// Actions
const START_LOAD = 'common/containerLocationTypes/START_LOAD';
const COMPLETE_LOAD = 'common/containerLocationTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/containerLocationTypes/FAIL_LOAD';
const RESET = 'common/containerLocationTypes/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containerLocationTypes: TechnicalType[];
} = {
  isLoading: false,
  containerLocationTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerLocationTypes: action.containerLocationTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerLocationTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerLocationTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  containerLocationTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainerLocationTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerLocationTypesPromise = doLoadContainerLocationTypes();
  loadContainerLocationTypesPromise
    .then((containerLocationTypes: TechnicalType[]) => dispatch(completeLoad(containerLocationTypes)))
    .catch(() => dispatch(failLoad()));
  return loadContainerLocationTypesPromise;
};

export const resetContainerLocationTypes = () => ({
  type: RESET,
});
