import { Expression } from 'mapbox-gl';

import {
  CITY_ALERT_ACCIDENT_ID,
  CITY_ALERT_ICY_BRIDGE_ID,
  CITY_ALERT_SNOW_DRIFT_ID,
  CITY_ALERT_SLICK_SPOT_ID,
  CITY_ALERT_METAL_PLATES_ID,
  CITY_ALERT_SPEED_BUMP_ID,
  LOCATION_AND_CITY_ALERTS_LAYER,
  NEW_LOCATION_ALERT_ID,
} from 'src/fleet/constants/locationAndCityAlerts';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { LocationAndCityAlertsForMap } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { theme } from 'src/core/styles';
import accident from 'src/common/assets/img/cityAlerts/accident.png';
import IconWithTypes from 'src/common/interfaces/IconWithTypes';
import icyBridge from 'src/common/assets/img/cityAlerts/icyBridge.png';
import locationAlert from 'src/common/assets/img/cityAlerts/locationAlert.png';
import metalPlates from 'src/common/assets/img/cityAlerts/metalPlates.png';
import slickSpot from 'src/common/assets/img/cityAlerts/slickSpot.png';
import snowDrift from 'src/common/assets/img/cityAlerts/snowDrift.png';
import speedBump from 'src/common/assets/img/cityAlerts/speedBump.png';

export type CityAlertProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const LOCATION_AND_CITY_ALERTS_ICON_TYPES: IconWithTypes[] = [
  new IconWithTypes('locationAlert', locationAlert, [NEW_LOCATION_ALERT_ID]),
  new IconWithTypes('accident', accident, [CITY_ALERT_ACCIDENT_ID]),
  new IconWithTypes('icyBridge', icyBridge, [CITY_ALERT_ICY_BRIDGE_ID]),
  new IconWithTypes('metalPlates', metalPlates, [CITY_ALERT_METAL_PLATES_ID]),
  new IconWithTypes('slickSpot', slickSpot, [CITY_ALERT_SLICK_SPOT_ID]),
  new IconWithTypes('snowDrift', snowDrift, [CITY_ALERT_SNOW_DRIFT_ID]),
  new IconWithTypes('speedBump', speedBump, [CITY_ALERT_SPEED_BUMP_ID]),
];

export const getIconTypeForLocationAndCityAlert = (alert: LocationAndCityAlertsForMap) => {
  const insightType = alert.cityAlertTypeId;
  const iconType = LOCATION_AND_CITY_ALERTS_ICON_TYPES.find(
    type => type.types.indexOf(insightType ? insightType : NEW_LOCATION_ALERT_ID) !== -1,
  );

  return iconType;
};

export const getLocationAndCityAlertGeoJSON = (locationAndCityAlertsForMap: LocationAndCityAlertsForMap[]) =>
  getFeatureCollection<GeoJSON.Point, CityAlertProperties>(
    locationAndCityAlertsForMap.map(alert =>
      getPointFeature(alert.id || 0, [alert.longitude, alert.latitude], {
        id: alert.id || 0,
        clickable: true,
        count: alert.totalCount,
        layer: LOCATION_AND_CITY_ALERTS_LAYER,
        icon: getIconTypeForLocationAndCityAlert(alert)?.id,
      }),
    ),
  );

export const getLatLng = (value?: number) => (value ? Number(value.toFixed(10)) : undefined);

export const getAlertPaint = () => {
  return {
    'text-halo-color': theme.brandPrimary,
    'text-halo-width': 10,
    'text-color': theme.brandWhite,
  };
};

export const getAlertLayout = (icon: Expression, count: Expression) => {
  return {
    'icon-image': icon,
    'icon-size': 0.25,
    'icon-allow-overlap': true,
    'text-field': ['case', ['==', count, 0], '', count],
    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
    'text-size': 13,
    'text-offset': [0, 1.7],
    'text-allow-overlap': true,
    'text-ignore-placement': true,
  };
};
