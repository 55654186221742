import { includes, remove } from 'lodash-es';
import { useState } from 'react';
import { Modal, UnconnectedSwitch } from 'src/core/components';
import { Button, ModalFooter, ModalSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import styled from 'styled-components';

export const RouteContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  flex: 1;
`;

export const RouteTitleContainer = styled.div`
  flex: 1;
  text-align: right;
  margin-right: 10px;
`;

export const RouteTemplateBuilderUpdateTrackersModal: React.FC<{
  onClose: () => void;
  onPublish: (updateTrackerIds: number[]) => void;
  routeTemplates: { id: number; name: string }[];
}> = ({ onClose, onPublish, routeTemplates }) => {
  const [updateTrackerPageIds, setUpdateTrackerPageIds] = useState<number[]>([]);

  const toggleUpdateTrackerPage = (id: number) => {
    if (!includes(updateTrackerPageIds, id)) {
      updateTrackerPageIds.push(id);
    } else {
      remove(updateTrackerPageIds, i => i === id);
    }
    setUpdateTrackerPageIds([...updateTrackerPageIds]);
  };

  return (
    <Modal onClose={onClose} title={translate('common.updateTrackerRoute')}>
      <div>{translate('routes.alertMessages.routeSaveShouldRecreateRoutes')}</div>
      <ModalSection flexDirection="column" padding="medium">
        {routeTemplates.map(rt => (
          <RouteContainer>
            <RouteTitleContainer>{rt.name}</RouteTitleContainer>
            <ContentContainer>
              <UnconnectedSwitch
                margin="small no"
                checked={includes(updateTrackerPageIds, rt.id)}
                onChange={() => toggleUpdateTrackerPage(rt.id)}
              />
            </ContentContainer>
          </RouteContainer>
        ))}
      </ModalSection>
      <ModalFooter padding="small no no no" align="center">
        <Button margin="no small" color="primary" line onClick={onClose}>
          {translate('common.cancel')}
        </Button>
        <Button
          margin="no small"
          color="primary"
          onClick={() => {
            onPublish(updateTrackerPageIds);
            onClose();
          }}
        >
          {translate('routeTemplateBuilder.publish')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
