import { createSelector } from 'reselect';
import { identity, find } from 'lodash-es';
import { ALL, CRITICAL } from '../constants';

const getFaultCodeEditorFormInitialValues = (faultCodesState: any, faultCodeEmailId?: number) => {
  const faultCodeEmail = find(faultCodesState, faultCode => faultCode.id === faultCodeEmailId);

  return faultCodeEmail
    ? {
        ...faultCodeEmail,
        sendCriticalForDailyEmailSummary: faultCodeEmail.sendCriticalForDailyEmailSummary ? CRITICAL : ALL,
        sendCriticalForReoccurringEmailSummary: faultCodeEmail.sendCriticalForReoccurringEmailSummary ? CRITICAL : ALL,
      }
    : {
        isActive: true,
        sendCriticalForDailyEmailSummary: CRITICAL,
        sendCriticalForReoccurringEmailSummary: CRITICAL,
        sendDailyEmailSummary: true,
        sendReoccurringEmailSummary: false,
      };
};

const faultCodeEditorFormInitialValuesSelector = createSelector(getFaultCodeEditorFormInitialValues, identity);

export default faultCodeEditorFormInitialValuesSelector;
