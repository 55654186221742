import { WrappedFieldArrayProps } from 'redux-form';

import { Option } from 'src/common/interfaces/Option';
import { Dropdown, TypedField } from 'src/core/components';
import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { isRequired } from 'src/utils/services/validator';

interface Props extends WrappedFieldArrayProps {
  fieldArrayKey: string;
  index: number;
  facilityOptions: Option[];
}

const ContainerRelocationTableRow = ({ fieldArrayKey, index, fields, facilityOptions }: Props) => {
  const containerNumber = fields.get(index).containerNumber;

  return (
    <TableRow height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width="40%">
        <Text>{containerNumber}</Text>
      </TableCell>
      <TableCell width="60%">
        <TypedField
          name={`${fieldArrayKey}.locationId`}
          component={Dropdown}
          props={{
            margin: 'no',
            menuPosition: 'fixed',
            options: facilityOptions,
            placeholder: translate('containers.containerNewLocation'),
            width: '200px',
          }}
          validate={[isRequired]}
        />
      </TableCell>
    </TableRow>
  );
};

export default ContainerRelocationTableRow;
