import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import history from '../../../core/services/history';
import { TypedResolver } from '../../../core/components';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { PageLoading } from '../../../common/components/styled';
import { authenticateToken } from '../../../fleet/ducks';
import InvoicePage from './InvoicePage';
import translate from '../../../core/services/translate';
import { DuckFunction } from '../../../contracts/ducks';
import { AppState } from '../../../store';
import { FullStory } from '../../../fullstory';
import { loadInvoices } from '../../ducks';
import { InvoiceSearchParams } from '../../interfaces/InvoiceSearchParams';
import { ONE_MONTH_AGO, TODAY_FORMATTED } from '../../../core/constants/weekdays';
import { getQueryParams } from '../../../utils/services/queryParams';

interface RouteParams {
  token?: string;
  invoiceNumber?: string;
  accountNumber?: string;
  from?: string;
  to?: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  authenticateToken: DuckFunction<typeof authenticateToken>;
  loadInvoices: DuckFunction<typeof loadInvoices>;
  gusId: string;
}

const InvoicePageResolver: React.FC<Props> = ({ loadInvoices, authenticateToken, location: { search }, gusId }) => {
  const { invoiceNumber, accountNumber, from, to, token } = getQueryParams(search);

  const searchFormInitialValues = {
    vendorGroup: gusId,
    invoiceNumber,
    accountNumber,
    dateRange: {
      from: from || ONE_MONTH_AGO,
      to: to || TODAY_FORMATTED,
    },
  };

  const loadDependancies = () => {
    const defaultSearchParams: InvoiceSearchParams = {
      invoiceStartDate: searchFormInitialValues.dateRange.from,
      invoiceEndDate: searchFormInitialValues.dateRange.to,
      invoiceNumber,
      accountNumber,
      vendorGroup: gusId,
    };

    if (token) {
      FullStory.setTokenBasedUserInfo(token);
    }

    return token
      ? authenticateToken(token).then(response => {
          if (!response || response.isVPortalLoginRequired) {
            history.push({ pathname: '/account/logout', state: { from: history.location } });
          } else {
            if (response) {
              FullStory.setTokenBasedVendorInfo(response);
            }
            return Promise.all([loadInvoices(defaultSearchParams)]);
          }
        })
      : Promise.all([loadInvoices(defaultSearchParams)]);
  };

  return (
    <>
      <DocumentTitle>{translate('invoice.invoices')}</DocumentTitle>
      <TypedResolver
        successProps={{ searchFormInitialValues }}
        successComponent={InvoicePage}
        loadingComponent={PageLoading}
        resolve={loadDependancies}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  gusId: (currentVendorGusIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { authenticateToken, loadInvoices };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicePageResolver));
