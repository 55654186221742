import React from 'react';
import { Source, Layer } from 'react-map-gl';

import {
  DASHBOARD_CLUSTERS_SOURCE,
  DASHBOARD_CLUSTERS_CLUSTERS_LAYER,
  DASHBOARD_CLUSTERS_CLUSTER_COUNTERS_LAYER,
} from 'src/dashboard/constants/dashboardMapGL';

interface Props<P extends object = {}> {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
}

const DashboardClustersGLSource: React.FC<Props> = React.memo(({ geoJSON }) => (
  <Source cluster type="geojson" id={DASHBOARD_CLUSTERS_SOURCE} data={geoJSON} tolerance={0.0001} clusterMaxZoom={9}>
    <Layer
      id={DASHBOARD_CLUSTERS_CLUSTERS_LAYER}
      type="circle"
      source={DASHBOARD_CLUSTERS_SOURCE}
      filter={['has', 'point_count']}
      paint={{
        'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      }}
    />

    <Layer
      id={DASHBOARD_CLUSTERS_CLUSTER_COUNTERS_LAYER}
      type="symbol"
      source={DASHBOARD_CLUSTERS_SOURCE}
      filter={['has', 'point_count']}
      paint={{
        'text-color': 'white',
      }}
      layout={{
        'text-field': '{point_count_abbreviated}',
        'text-size': 12,
      }}
    />
  </Source>
));

export default DashboardClustersGLSource;
