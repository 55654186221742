import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getBillingMethods } from '../services/billingMethodOptions';

// Constants
const START_LOAD_BILLING_METHOD_OPTIONS = 'customers/billingMethodOptions/START_LOAD_BILLING_METHOD_OPTIONS';
const COMPLETE_LOAD_BILLING_METHOD_OPTIONS = 'customers/billingMethodOptions/COMPLETE_LOAD_BILLING_METHOD_OPTIONS';
const FAIL_LOAD_BILLING_METHOD_OPTIONS = 'customers/billingMethodOptions/FAIL_LOAD_BILLING_METHOD_OPTIONS';

interface State {
  isLoading: boolean;
  billingMethods: TechnicalType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial State
const initialState: State = {
  isLoading: false,
  billingMethods: [],
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD_BILLING_METHOD_OPTIONS,
});

const completeLoad = (billingMethods: TechnicalType[]) => ({
  type: COMPLETE_LOAD_BILLING_METHOD_OPTIONS,
  billingMethods,
});

const failLoad = () => ({
  type: FAIL_LOAD_BILLING_METHOD_OPTIONS,
});

// Async Actions
export const loadBillingMethods = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getBillingMethodsPromise = getBillingMethods();
  getBillingMethodsPromise.then((data: any[]) => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getBillingMethodsPromise;
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_BILLING_METHOD_OPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_BILLING_METHOD_OPTIONS:
      return {
        ...initialState,
        isLoading: false,
      };
    case COMPLETE_LOAD_BILLING_METHOD_OPTIONS:
      return {
        ...state,
        billingMethods: action.billingMethods,
        isLoading: false,
      };
    default:
      return state;
  }
};
