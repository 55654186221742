import { CancelToken } from 'axios';
import * as turf from '@turf/turf';

import { http } from 'src/core/services/http';
import { SNOW_SWEEPER_JOB_STATUSES } from 'src/routes/constants';
import { ServiceAreas } from '../interfaces/StreetNetwork';

export const loadStreetNetwork = (
  vendorId: number,
  routeId?: number,
  routeTemplateId?: number,
  includeRouteTemplateIds?: boolean,
  streetSegmentAssignedStatus?: boolean,
  streetSegmentAssignedTypesStatus?: string | number,
  streetSegmentStatus?: boolean,
  streetSegmentNumberOfLanes?: string,
  streetSegmentNumberOfPasses?: string,
  streetSegmentServiceSides?: string,
  driverIds?: string,
  pickupStatusTypeIds?: string,
  segmentsFilterEndDate?: Date | string,
  segmentsFilterStartDate?: Date | string,
  alternativeFleetServiceTypeIds?: string,
  vehicleTypeId?: number,
) => {
  const streetSegmentAssignedTypesStatusFormatted =
    typeof streetSegmentAssignedTypesStatus === 'string' && streetSegmentAssignedTypesStatus?.length
      ? streetSegmentAssignedTypesStatus
          .split(',')
          .map(status => Number(status) + 1)
          .toString()
      : streetSegmentAssignedTypesStatus;

  return http
    .get(`${vendorId}/streetNetwork/segments`, {
      params: {
        routeId,
        routeTemplateId,
        includeRouteTemplateIds,
        isAssigned: routeId ? streetSegmentAssignedStatus : undefined,
        isActive: streetSegmentStatus,
        lanesFilter: streetSegmentNumberOfLanes,
        passesFilter: streetSegmentNumberOfPasses,
        serviceSideTypeIds: streetSegmentServiceSides,
        assignmentTypes:
          routeTemplateId || routeTemplateId === 0 ? streetSegmentAssignedTypesStatusFormatted : undefined,
        driverIds,
        pickupStatusTypeIds,
        startDate: segmentsFilterStartDate,
        endDate: segmentsFilterEndDate,
        alternativeFleetServiceTypeIds,
        vehicleTypeId,
      },
    })
    .then(response => response.data);
};

export const loadStreetNetworkSegmentDetails = (
  vendorId: number,
  segmentId: number,
  routeId?: number,
  vehicleTypeId?: number,
  segmentsFilterEndDate?: Date | string,
  segmentsFilterStartDate?: Date | string,
) =>
  http
    .get(`${vendorId}/streetNetwork/segments/${segmentId}/attributes`, {
      params: { routeId, vehicleTypeId, startDate: segmentsFilterStartDate, endDate: segmentsFilterEndDate },
    })
    .then(response => {
      return {
        ...response.data,
        pickupStatusTypeName:
          response.data.pickupStatusTypeId && SNOW_SWEEPER_JOB_STATUSES[response.data.pickupStatusTypeId].name,
      };
    });

export const loadStreetNetworkSegmentDetailsForDailyRoute = (vendorId: number, segmentId: number, routeId: number) =>
  http.get(`${vendorId}/streetNetwork/segments/${segmentId}/attributes/${routeId}`).then(response => {
    return {
      ...response.data,
      pickupStatusTypeName:
        response.data.pickupStatusTypeId && SNOW_SWEEPER_JOB_STATUSES[response.data.pickupStatusTypeId].name,
    };
  });

export const loadStreetNetworkServiceAreas = (vendorId: number) =>
  http
    .get<ServiceAreas>(`${vendorId}/streetNetwork/serviceAreas`, { params: { vendorId } })
    .then(response => response.data);

export const saveStreetNetworkServiceAreas = (vendorId: number, serviceAreas: ServiceAreas) =>
  http.post(`${vendorId}/streetNetwork/serviceAreas`, serviceAreas).then(response => response.data);

export const updateStreetNetworkServiceAreas = (vendorId: number, serviceAreas: ServiceAreas) =>
  http.put(`${vendorId}/streetNetwork/serviceAreas`, serviceAreas).then(response => response.data);

export const loadStreetNetworkDataLayers = (
  vendorId: number,
  dataLayerType: string,
  cqlFilter?: string,
  bbox?: turf.BBox,
  cancelToken?: CancelToken,
) =>
  http
    .get(`${vendorId}/streetNetwork/dataLayer`, {
      params: {
        dataLayerType,
        cqlFilter,
        bbox: bbox ? `${bbox.join(',')},EPSG:4326` : undefined,
      },
      cancelToken,
    })
    .then(response => response.data);
