import React from 'react';
import { useSelector } from 'react-redux';
import { SuperAdminGuard } from '../../../account/components';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { ButtonLink, Message, Panel, PanelSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { ReleaseNotesPageContent } from '../ReleaseNotesPageContent';

export default function ReleaseNotesPage() {
  const { isLoading, releaseNotes } = useSelector((state: AppState) => state.settings.releaseNotes);

  return (
    <PageContent isLoading={isLoading}>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('versioning.releaseNotes')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <SuperAdminGuard>
            <ButtonLink to="/release-notes/manage" color="primary" id="manage-release-notes-button">
              {translate('versioning.manageNotes')}
            </ButtonLink>
          </SuperAdminGuard>
        </PageActions>
      </PageHeader>
      {!releaseNotes.length ? (
        <Panel>
          <PanelSection>
            <Message padding="sMedium">{translate('versioning.noNewNotes')}</Message>
          </PanelSection>
        </Panel>
      ) : (
        <ReleaseNotesPageContent notes={releaseNotes} />
      )}
    </PageContent>
  );
}
