import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const BIN_OVERFLOW = 'BinOverflowDetection';
export const BIN_DETECTION = 'BinDetection';
export const BIN_DETECTION_OVERFLOW = 'BinDetectionBinOverflow';
export const OFF_CURB = 'Curb Detection';
export const POTHOLE_DETECTION = 'Pothole Detection';
export const MATERIAL_CONTAMINATION = 'MaterialContamination';

const keys = [
  { technicalName: BIN_OVERFLOW, name: translate('inferenceAudit.inferenceAuditTypes.overflowing') },
  { technicalName: BIN_DETECTION, name: translate('inferenceAudit.inferenceAuditTypes.binDetection') },
  { technicalName: BIN_DETECTION_OVERFLOW, name: translate('inferenceAudit.inferenceAuditTypes.binDetectionOverflow') },
  { technicalName: OFF_CURB, name: translate('inferenceAudit.inferenceAuditTypes.offCurb') },
  { technicalName: POTHOLE_DETECTION, name: translate('inferenceAudit.inferenceAuditTypes.potholeDetection') },
  { technicalName: MATERIAL_CONTAMINATION, name: translate('inferenceAudit.inferenceAuditTypes.materialContamination') },
];

export const MODEL_CLASS_TYPES = mapKeys(keys, 'technicalName');
