import { useDispatch } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';

import { DocumentTitle } from 'src/common/components';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  loadRoutePlannerRouteMetricsSettings,
  loadRoutePlannerRouteTemplateMetricsSettings,
} from 'src/routes/ducks/routePlanner';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RoutePlannerSettingsPage from './RoutePlannerSettingsPage';

const RoutePlannerSettingsPageResolver: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const vendorId = useSelector(currentVendorId);

  const isReoccurringRoutesPage = pathname.includes('reoccurring');

  const loadDependencies = () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: any[] = [];

    if (isReoccurringRoutesPage) dependencies.push(loadRoutePlannerRouteTemplateMetricsSettings(vendorId)(dispatch));
    else dependencies.push(loadRoutePlannerRouteMetricsSettings(vendorId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.planner.routePlannerSettings')}</DocumentTitle>
      <TypedResolver
        successComponent={RoutePlannerSettingsPage}
        loadingComponent={PageLoadingOverlay}
        resolve={loadDependencies}
      />
    </>
  );
};

export default RoutePlannerSettingsPageResolver;
