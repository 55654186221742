import React, { PureComponent } from 'react';

import { dateAndTime } from '../../../utils/services/formatter';
import { Grid, GridColumn, Text } from '../../../core/components/styled';
import { LastKnowLocation } from '../../interfaces/LastKnowLocation';
import { LastKnownLocationMapContainer } from '../styled/LastKnownLocation';
import { MAP_CITY_ZOOM_IN } from '../../../core/constants/mapOptions';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import OverviewMapGL from 'src/haulerProfile/components/OverviewMapGL';

export interface LastKnownLocationModalCommonProps {
  closeModal(): void;
  deviceId?: string;
  deviceName?: string;
  isReverseGeocodeLoading: boolean;
  lastKnowLocationAddress?: string;
  lastKnownLocation?: LastKnowLocation;
}

export interface LastKnownLocationModalProps extends LastKnownLocationModalCommonProps {}

class LastKnownLocationModal extends PureComponent<LastKnownLocationModalProps> {
  na = translate('common.notAvailable');

  render() {
    const { closeModal, deviceId, deviceName, isReverseGeocodeLoading, lastKnowLocationAddress, lastKnownLocation } =
      this.props;

    const pinLocation = {
      lat: lastKnownLocation ? lastKnownLocation.latitude : 0,
      lng: lastKnownLocation ? lastKnownLocation.longitude : 0,
    };

    const pinLocationAvailable = pinLocation.lat !== 0 || pinLocation.lng !== 0;

    return (
      <Modal
        isLoading={isReverseGeocodeLoading}
        onClose={closeModal}
        padding="medium"
        title={translate('settings.lastKnownLocation')}
        verticalSize="medium"
      >
        {pinLocationAvailable && (
          <LastKnownLocationMapContainer>
            <OverviewMapGL isMarker address={[[[pinLocation.lng, pinLocation.lat]]]} mapZoom={MAP_CITY_ZOOM_IN} />
          </LastKnownLocationMapContainer>
        )}

        <Grid multiLine>
          <GridColumn size="4/12" padding="small no no no">
            <Text weight="medium">{translate('settings.deviceName')}: </Text>
          </GridColumn>
          <GridColumn size="8/12" padding="small no no no">
            <Text weight="light">{deviceName}</Text>
          </GridColumn>
          <GridColumn size="4/12" padding="small no no no">
            <Text weight="medium">{translate('settings.deviceIdentifier')}: </Text>
          </GridColumn>
          <GridColumn size="8/12" padding="small no no no">
            <Text weight="light">{deviceId}</Text>
          </GridColumn>
          <GridColumn size="4/12" padding="small no no no">
            <Text weight="medium">{translate('settings.lastKnownLocation')}: </Text>
          </GridColumn>
          <GridColumn size="8/12" padding="small no no no">
            <Text weight="light">{lastKnowLocationAddress || this.na}</Text>
          </GridColumn>
          <GridColumn size="4/12" padding="small no no no">
            <Text weight="medium">{translate('settings.lastLocationTimestamp')}: </Text>
          </GridColumn>
          <GridColumn size="8/12" padding="small no no no">
            <Text weight="light">
              {lastKnowLocationAddress && lastKnownLocation ? dateAndTime(lastKnownLocation.reportedDate) : this.na}
            </Text>
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

export default LastKnownLocationModal;
