import { camelize } from 'humps';
import { find, upperFirst } from 'lodash-es';
import { FC } from 'react';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';
import { DetailsListBodySection, DetailsListItemDescription, DetailsListItemTitle } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { CustomerServiceIcon } from 'src/customers/components/pages/customerDetailsPageSections/CustomerServiceIcon';
import { ServiceDetailsContainer } from 'src/customers/components/styled/CustomerLocations';
import { SERVICE_TYPE_ICONS } from 'src/customers/constants';
import { ServiceContractAtLocation } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';

const Icons = SERVICE_TYPE_ICONS as any;

interface Props {
  service: ServiceContractAtLocation;
  isHighlighted?: boolean;
}

const RouteTemplateBuilderServiceDetails: FC<Props> = ({ service, isHighlighted }) => {
  const equipmentSizes = useSelector(state => state.common.equipmentSizes.equipmentSizes);

  const containerSize = find(equipmentSizes, { id: service.equipmentSizeId }) as TechnicalType;
  const size = getEquipmentSize(containerSize?.technicalName, containerSize?.name);

  const sizeTranslated = translate(
    `common.equipmentSizes.x${upperFirst(
      camelize(containerSize?.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
    )}`,
    {
      size: size ? getEquipmentSize(containerSize?.technicalName, containerSize?.name) : 0,
    },
  );

  return (
    <ServiceDetailsContainer isHighlighted={isHighlighted}>
      <CustomerServiceIcon
        accountStatusId={service.serviceContractAccountStatusTypeId}
        src={Icons[camelize(service.equipmentType.technicalName)]}
      />
      <DetailsListBodySection>
        <DetailsListItemTitle size="small" color="secondary" margin="no no small">
          {translate('common.serviceType')}
        </DetailsListItemTitle>
        <DetailsListItemDescription width="100%">{service.serviceType.name}</DetailsListItemDescription>
      </DetailsListBodySection>
      <DetailsListBodySection>
        <DetailsListItemTitle size="small" color="secondary" margin="no no small">
          {translate('common.equipmentType')}
        </DetailsListItemTitle>
        <DetailsListItemDescription width="100%">{service.equipmentType.name}</DetailsListItemDescription>
      </DetailsListBodySection>
      <DetailsListBodySection>
        <DetailsListItemTitle size="small" color="secondary" margin="no no small">
          {translate('customers.wasteMaterial')}
        </DetailsListItemTitle>
        <DetailsListItemDescription width="100%">{service.wasteMaterialType.name}</DetailsListItemDescription>
      </DetailsListBodySection>
      <DetailsListBodySection>
        <DetailsListItemTitle size="small" color="secondary" margin="no no small">
          {translate('customers.equipmentSize')}
        </DetailsListItemTitle>
        <DetailsListItemDescription width="100%">{sizeTranslated}</DetailsListItemDescription>
      </DetailsListBodySection>
      <DetailsListBodySection>
        <DetailsListItemTitle size="small" color="secondary" margin="no no small">
          {translate('customers.containerCount')}
        </DetailsListItemTitle>
        <DetailsListItemDescription width="100%">{service.numberOfContainers}</DetailsListItemDescription>
      </DetailsListBodySection>
      <DetailsListBodySection>
        <DetailsListItemTitle size="small" color="secondary" margin="no no small">
          {translate('common.status')}
        </DetailsListItemTitle>
        <DetailsListItemDescription width="100%">
          {service.serviceContractAccountStatusType.name}
        </DetailsListItemDescription>
      </DetailsListBodySection>
    </ServiceDetailsContainer>
  );
};

export default RouteTemplateBuilderServiceDetails;
