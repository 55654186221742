import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getRouteStopsGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/routeStops/utils';
import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useSelector } from 'src/core/hooks/useSelector';
import TravelPathRouteStopsGLSource, { TRAVEL_PATH_ROUTE_STOPS_SOURCE } from './TravelPathRouteStopsGLSource';
import TravelPathRouteStopGLPopupResolver from './TravelPathRouteStopGLPopupResolver';

const mapImages = NEW_INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: TRAVEL_PATH_ROUTE_STOPS_SOURCE,
}));
const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
  routeId?: number;
  routeTemplateId?: number;
  showOrderNumbers: boolean;
}

const TravelPathRouteStopsGL: FC<Props> = ({ map, routeId, routeTemplateId, showOrderNumbers }) => {
  const dispatch = useDispatch();
  useMapImages(mapImages, map, mapImagesOptions);

  const routeStops = useSelector(state => state.routes.travelPathBuildAndEdit.routeStops);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === TRAVEL_PATH_ROUTE_STOPS_SOURCE);
        if (feature) {
          const isForTravelPath = true;
          dispatch(
            setRouteMapSelectedFeature(
              RouteMapFeature.routeStops,
              feature.properties?.id as number,
              undefined,
              isForTravelPath,
            ),
          );
        }
      });
    });
  }, [map, dispatch]);

  const geoJSON = useMemo(() => getRouteStopsGeoJSON(routeStops), [routeStops]);

  return (
    <>
      <TravelPathRouteStopsGLSource geoJSON={geoJSON} showOrderNumbers={showOrderNumbers} />
      <TravelPathRouteStopGLPopupResolver routeId={routeId} routeTemplateId={routeTemplateId} />
    </>
  );
};

export default TravelPathRouteStopsGL;
