import { http } from '../../core/services/http';
import transformLoadPermissions from './transformLoadPermissions';

const KEY = 'userPermissions';
const EXPIRATION = 3600 * 24 * 365 * 5;

export const savePermissions = (permissions: any) =>
  (localStorage as any).setItem(KEY, JSON.stringify(permissions), { expires: EXPIRATION });
export const shouldLoadPermissions = () => JSON.parse(localStorage.getItem(KEY) as any);
export const clearPermissions = () => localStorage.removeItem(KEY);
export const loadPermissions = () =>
  http.get('accessPermission/currentUserPermissions').then(res => transformLoadPermissions(res.data));
