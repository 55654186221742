import { http } from '../../core/services/http';
import { transformOpportunity } from './transformOpportunity';

export const loadOpportunity = (token: string, isActionRequired: boolean) =>
  http
    .get(`bpm/sourcing/hauler/opportunities/${token}`, { params: { isActionRequired } })
    .then(response => transformOpportunity(response.data));

export const loadOpportunityByUserId = (userId: number | string, vendorId: number, isActionRequired: boolean) =>
  http
    .get(`bpm/sourcing/hauler/opportunities/vendor/${vendorId}/user/${userId}`, { params: { isActionRequired } })
    .then(response => transformOpportunity(response.data));

export const updateOpportunity = (requestOpportunityObj: any, token: string) =>
  http
    .put(`bpm/sourcing/hauler/response/${token}`, [requestOpportunityObj])
    .then(response => transformOpportunity(response.data));

export const updateOpportunityByUserId = (
  requestOpportunityObj: any,
  userId: string | number,
  vendorId: number,
  isActionRequired?: boolean,
  startDate?: string,
  endDate?: string,
  includeAllStatus?: boolean,
) =>
  http
    .put(`bpm/sourcing/hauler/opportunities/vendor/${vendorId}/user/response/${userId}`, [requestOpportunityObj], {
      params: { isActionRequired, startDate, endDate, includeAllStatus },
    })
    .then(response => transformOpportunity(response.data));
