import { FC } from 'react';
import { omit } from 'lodash-es';
import { SubmissionError } from 'redux-form';
import { useDispatch } from 'react-redux';
import humps from 'humps';

import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { loadContacts, saveContact } from 'src/customers/ducks/contacts';
import { loadCustomerLocations } from 'src/customers/ducks';
import { loadCustomerPortalRoles } from 'src/customers/ducks/customerPortal';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { transformSaveContact } from 'src/customers/services/transformSaveContact';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import ContactsEditorModal, { contactsEditorModalId } from './ContactsEditorModal';
import translate from 'src/core/services/translate';

interface Props {
  customerId: number;
  customerName: string;
  locationId?: number;
  contactId?: number;
  onClose(pristine: boolean): void;
}
const ContactsEditorModalResolver: FC<Props> = ({ customerId, customerName, locationId, contactId, onClose }) => {
  const dispatch = useDispatch();
  const formAssignedLocations = useSelector(state => state.customers.contactLocations.assignedLocations) || [];

  const loadDependencies = async () => {
    const dependencies: Promise<unknown>[] = [];

    dependencies.push(loadCustomerPortalRoles()(dispatch));
    if (customerId && locationId) {
      dependencies.push(loadCustomerLocations(customerId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoadingOverlay}
      successComponent={ContactsEditorModal}
      successProps={{
        customerId,
        contactId,
        locationId,
        customerName,
        onCancel: onClose,
        onSaveContact(data: any) {
          if (!formAssignedLocations.length) {
            throw new SubmissionError({
              assignedLocations: 'At least one assigned location is required.',
              _error: 'Submission Failed.',
            });
          }

          scrollToTopOfModal(contactsEditorModalId);

          const transformedData = omit(transformSaveContact({ ...data, formAssignedLocations, customerId }), [
            'formAssignedLocations',
            'customerPortalRoleId',
            'isEnrolledInCustomerPortal',
          ]);
          saveContact(
            transformedData,
            customerId,
          )(dispatch)
            .then(() => {
              onClose(true);
              createSuccessNotification(translate('common.contact.alertMessages.contactSaved'));
              dispatch(loadContacts(customerId, locationId));
            })
            .catch(e => {
              const errorMessageCode =
                e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

              createErrorNotification(
                errorMessageCode
                  ? translate(`common.contact.alertMessages.${errorMessageCode}`)
                  : translate('common.contact.alertMessages.contactSaveError'),
              );
            });
        },
      }}
    />
  );
};

export default ContactsEditorModalResolver;
