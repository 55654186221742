import { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { AppState } from '../../../store';
import moment from 'moment';

import { ActionRequiredContainer } from '../styled/RubiconDispatches';
import { DATE_RANGE_PICKER_TODAY_7_FUTURE } from '../../../core/constants/weekdays';
import { DateRangePicker, PanelSearch, MultiSelect, Switch } from '../../../core/components';
import { Grid, GridColumn, PanelSection, PanelSectionGroup, Text, ToggleIcon } from '../../../core/components/styled';
import { ToggleContainer } from '../../../routes/components/styled';
import translate from '../../../core/services/translate';
import { OPEN_DISPATCHES_SERVICES_LIST } from '../../constants/openDispatachesServices';
import { MobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';

interface PropsWithoutReduxForm {
  divisions: any[];
  equipmentSubTypes: any[];
  materialTypes: any[];
  disabledDivisionIds?: string[];
  disabledEquipmentSubTypeIds?: string[];
  disabledMaterialTypeIds?: string[];
  disabledServiceIds?: number[];
  disabledDateRange: boolean;
  notificationsCount: any;
  mobileVersion?: boolean;
}

interface State {
  isExpanded: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class RubiconDispatchesFilterForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isExpanded: false };
  }

  onToggle = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const {
      divisions,
      equipmentSubTypes,
      materialTypes,
      notificationsCount,
      disabledDivisionIds = [],
      disabledEquipmentSubTypeIds = [],
      disabledMaterialTypeIds = [],
      disabledServiceIds = [],
      disabledDateRange,
      mobileVersion,
    } = this.props;
    const actionableWorkOrdersCount = (!!notificationsCount && notificationsCount.workOrders) || 0;
    const { isExpanded } = this.state;

    const getIsActionRequiredButton = () => (
      <GridColumn size={mobileVersion ? '12/12' : '2/12'}>
        <ActionRequiredContainer>
          <Text block size="small" weight="medium" margin="no no xxSmall">
            {translate('autoDispatch.actionRequired')}
          </Text>
          <Field
            name="isActionRequired"
            component={Switch}
            props={{
              label: translate('autoDispatch.workOrdersCount', { count: actionableWorkOrdersCount }),
              margin: mobileVersion ? 'small no' : 'no',
              color: 'alert',
            }}
          />
        </ActionRequiredContainer>
      </GridColumn>
    );
    return (
      <form>
        <Fragment>
          <PanelSectionGroup>
            <PanelSection padding="small xSmall">
              <Grid multiLine column={mobileVersion}>
                {!mobileVersion && (
                  <GridColumn size="3/12">
                    <Field name="searchTerm" component={PanelSearch as any} />
                  </GridColumn>
                )}

                <GridColumn size={mobileVersion ? '12/12' : '3/12'}>
                  <Field
                    name="dateRange"
                    component={DateRangePicker as any}
                    props={{
                      hasMarginLeft: 'normal',
                      numberOfMonths: mobileVersion ? 1 : 2,
                      options: DATE_RANGE_PICKER_TODAY_7_FUTURE,
                      disabledDays: [
                        {
                          before: moment().subtract(60, 'days').toDate(),
                        },
                        {
                          after: moment().add(10, 'days').toDate(),
                        },
                      ],
                      maxInterval: { amount: 31, unit: 'days' },
                      margin: mobileVersion ? 'small no' : 'no',
                      tabletAlignLeft: mobileVersion,
                    }}
                    disabled={disabledDateRange}
                  />
                </GridColumn>
                <GridColumn size={mobileVersion ? '12/12' : '3/12'}>
                  <Field
                    name="serviceIds"
                    component={MultiSelect as any}
                    props={{
                      margin: mobileVersion ? 'small no' : 'no',
                      placeholder: translate('customers.services'),
                      options: OPEN_DISPATCHES_SERVICES_LIST.map(service => ({
                        label: service.name,
                        value: service.id,
                        isDisabled: disabledServiceIds.indexOf(service.id) !== -1,
                      })),
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('customers.allServices')
                          : translate('common.xServicesSelected', { selected: selectedOptions.length });
                      },
                      normalizeValues(value: string | number) {
                        return +value;
                      },
                      isSearchable: true,
                    }}
                  />
                </GridColumn>

                {!mobileVersion && (
                  <GridColumn size={mobileVersion ? '12/12' : '1/12'}>
                    <ToggleContainer id="toggle-filter-button" onClick={this.onToggle}>
                      <ToggleIcon size={10} isOpen={isExpanded} icon={isExpanded ? 'remove' : 'add'} />
                      {` ${translate('common.filter')}`}
                    </ToggleContainer>
                  </GridColumn>
                )}

                <DesktopWidthView>{getIsActionRequiredButton()}</DesktopWidthView>

                {(mobileVersion || isExpanded) && (
                  <>
                    <GridColumn size={mobileVersion ? '12/12' : '3/12'}>
                      <Field
                        name="materialTypeIds"
                        component={MultiSelect as any}
                        props={{
                          margin: mobileVersion ? 'small no' : 'xSmall no',
                          placeholder: translate('common.materials'),
                          options: materialTypes.map(material => ({
                            label: material,
                            value: material,
                            isDisabled: disabledMaterialTypeIds.indexOf(material) !== -1,
                          })),
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('common.allMaterialTypes')
                              : translate('common.xMaterialTypesSelected', { selected: selectedOptions.length });
                          },
                          isSearchable: true,
                        }}
                      />
                    </GridColumn>
                    <GridColumn size={mobileVersion ? '12/12' : '3/12'}>
                      <Field
                        name="equipmentSubTypeIds"
                        component={MultiSelect as any}
                        props={{
                          margin: mobileVersion ? 'small no' : 'xSmall no',
                          placeholder: translate('common.equipments'),
                          options: equipmentSubTypes.map(type => ({
                            label: type.name,
                            value: type.id,
                            isDisabled: disabledEquipmentSubTypeIds.indexOf(type.id) !== -1,
                          })),
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('common.allEquipments')
                              : translate('common.xEquipmentsSelected', { selected: selectedOptions.length });
                          },
                          isSearchable: true,
                        }}
                      />
                    </GridColumn>
                    <GridColumn size={mobileVersion ? '12/12' : '3/12'}>
                      <Field
                        name="divisionIds"
                        component={MultiSelect as any}
                        props={{
                          margin: mobileVersion ? 'small no' : 'xSmall no',
                          placeholder: translate('autoDispatch.divisions'),
                          options: divisions.map(division => ({
                            label: `${division.label} (${division.value})`,
                            value: division.value,
                            isDisabled: disabledDivisionIds.indexOf(division.value) !== -1,
                          })),
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('autoDispatch.allDivisions')
                              : translate('common.xDivisionsSelected', { selected: selectedOptions.length });
                          },
                          isSearchable: true,
                        }}
                      />
                    </GridColumn>
                  </>
                )}
                <MobileWidthView>{getIsActionRequiredButton()}</MobileWidthView>
              </Grid>
            </PanelSection>
          </PanelSectionGroup>
        </Fragment>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  divisions: state.fleet.rubiconDispatches.divisions,
  equipmentSubTypes: state.fleet.rubiconDispatches.equipmentOptions,
  materialTypes: state.fleet.rubiconDispatches.materialTypes,
  notificationsCount: state.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts,
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'rubiconDispatchesFilter',
    enableReinitialize: true,
  })(RubiconDispatchesFilterForm),
);
