import FileSaver from 'file-saver';

import {
  AGING_REPORT,
  BILLING_REPORT,
  CART,
  COLLECTIONS_REPORT,
  CUSTOM_ROUTE_EXCEPTION,
  DISPOSAL_TONS,
  DRIVER_SAFETY_INSPECTION,
  LOCATION_ALERTS,
  ORPHAN_INVOICE_REPORT,
  PAYMENT_REPORT,
  PRE_POST_TRIP_INSPECTION,
  ROUTE_EXCEPTION,
} from '../constants/reportTypes';
import { EXCEL_MIME_TYPES, TODAY_FORMATTED } from '../../core/constants';
import { http, httpInsightsReporting } from '../../core/services/http';
import createTranslationKey from '../../utils/services/createTranslationKey';
import moment from 'moment';
import translate from '../../core/services/translate';

const LOCATION_ALERTS_START_DATE = '01/01/2015';

export const createReports = (vendorId: number, reportTypeId: number, emailAddresses: string[], configuration: any) => {
  return http.post(`reports/${vendorId}`, { reportTypeId, emailAddresses, configuration });
};

export const downloadReport = (fileDownloadUrl?: string, fileName?: string, vendorId?: number, fileId?: number) => {
  return http
    .get(fileId && vendorId ? `reports/${vendorId}/download/${fileId}` : fileDownloadUrl ? fileDownloadUrl : '', {
      responseType: 'arraybuffer',
    })
    .then(response => {
      const fileExtension = fileName?.split('.')[1];
      const blob =
        fileExtension === 'csv'
          ? new Blob([response.data], { type: 'text/csv;charset=utf-8' })
          : new Blob([response.data], {
              type: EXCEL_MIME_TYPES,
            });
      FileSaver.saveAs(
        blob,
        `${
          fileId && !fileName
            ? `${translate('vendors.driverSafetyReport.driverSafetyInspectionReport')}-${fileId}`
            : fileName
        }`,
      );
    });
};

const exportReports = (
  vendorId: number,
  reportType: string,
  vehicleType: string | null | any[],
  vehicleName: string | null | any[],
  routeName: string | null,
  driverName: string | null | any[],
  startDate: string,
  endDate: string,
  wasteMaterialType: string[],
  serviceType: string[],
  vehicleTypeIdsCSV: number[],
  serviceZoneIdsCSV: number[],
  supervisorIdsCSV: number[],
  customerId?: number,
  customerLocationId?: number,
) => {
  let reportPostUrl = '';
  let reportPostOptions = {};
  let httpUrl;

  switch (reportType) {
    case PRE_POST_TRIP_INSPECTION:
    case DRIVER_SAFETY_INSPECTION:
      reportPostUrl = `/reports/vendors/${vendorId}/${
        reportType === PRE_POST_TRIP_INSPECTION ? 'tripInspectionReport' : 'driverSafetyInspectionReport'
      }`;
      reportPostOptions = {
        vendorId,
        reportType,
        date: startDate,
        vehicleTypeIds: vehicleType && vehicleType.toString(),
        vehicleIds: vehicleName && vehicleName.toString(),
        vehicleTypeIdsCSV: vehicleTypeIdsCSV.toString(),
        serviceZoneIdsCSV: serviceZoneIdsCSV.toString(),
        supervisorIdsCSV: supervisorIdsCSV.toString(),
        driverIds: DRIVER_SAFETY_INSPECTION ? driverName && driverName.toString() : undefined,
      };
      httpUrl = http;
      break;

    case LOCATION_ALERTS:
      reportPostUrl = `/reports/${vendorId}/locationAlerts`;
      reportPostOptions = {
        vendorId,
        reportType,
        startDate: LOCATION_ALERTS_START_DATE,
        endDate: TODAY_FORMATTED,
      };
      httpUrl = http;
      break;

    case AGING_REPORT:
    case BILLING_REPORT:
    case COLLECTIONS_REPORT:
    case ORPHAN_INVOICE_REPORT:
    case PAYMENT_REPORT:
      reportPostUrl = '/reporting/report/billing';
      reportPostOptions = {
        vendorId,
        reportType,
        endDate,
        startDate,
        customerId,
        customerLocationId,
      };
      httpUrl = httpInsightsReporting;
      break;

    default:
      reportPostUrl = '/reporting/report';
      reportPostOptions = {
        vendorId,
        reportType,
        startDate,
        endDate,
        routeName,
        vehicleType: vehicleType && vehicleType.toString(),
        vehicleName: vehicleName && vehicleName.toString(),
        driverName: driverName && driverName.toString(),
        wasteMaterialType: wasteMaterialType && wasteMaterialType.toString(),
        serviceType: serviceType && serviceType.toString(),
        vehicleTypeIdsCSV: vehicleTypeIdsCSV.toString(),
        serviceZoneIdsCSV: serviceZoneIdsCSV.toString(),
        supervisorIdsCSV: supervisorIdsCSV.toString(),
      };
      httpUrl = httpInsightsReporting;
      break;
  }

  return httpUrl
    .post(reportPostUrl, reportPostOptions, {
      responseType: 'arraybuffer',
    })
    .then(response => {
      const blob =
        reportType === CUSTOM_ROUTE_EXCEPTION
          ? new Blob([response.data], { type: 'text/csv;charset=utf-8' })
          : new Blob([response.data], {
              type: EXCEL_MIME_TYPES,
            });
      const extension = reportType === CUSTOM_ROUTE_EXCEPTION ? 'csv' : 'xlsx';

      let reportLabel;
      switch (reportType) {
        case DISPOSAL_TONS:
          reportLabel = translate('insights.dailyReportTypes.disposalTonsReport');
          break;

        case ROUTE_EXCEPTION:
          reportLabel = translate('insights.dailyReportTypes.routeExceptionReport');
          break;

        case CART:
          reportLabel = translate('insights.dailyReportTypes.cartReport');
          break;

        default:
          reportLabel = `${translate(createTranslationKey(reportType, 'insights.dailyReportTypes'))} - ${translate(
            'insights.report',
          )}`;
          break;
      }

      const reportName = reportType === CUSTOM_ROUTE_EXCEPTION ? `RUB_XTRA_` + moment().format('YYMMDD') : reportLabel;

      FileSaver.saveAs(blob, `${reportName}.${extension}`);
    });
};

export default exportReports;
