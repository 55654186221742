import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RouteMapFeature, setRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { ContainerLocationForMap } from 'src/fleet/interfaces/containers';
import {
  CONTAINERS_MAP_CLUSTER_SOURCE,
  CONTAINERS_MAP_CONTAINERS_LAYER,
  CONTAINERS_MAP_ICONS,
} from 'src/fleet/constants/containersMap';
import ContainerGLLayer from './ContainerGLLayer';
import ContainerPopupResolver from './ContainerPopupResolver';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';

type Props = {
  map: mapboxgl.Map;
  containerLocations: ContainerLocationForMap[];
};

const mapImages = CONTAINERS_MAP_ICONS.map(container => ({
  id: container.icon.id,
  url: container.icon.iconUrl,
}));

const mapImagesOptions = { addLayers: false };

export default function ContainersGL({ map, containerLocations }: Props) {
  const dispatch = useDispatch();
  useMapImages(mapImages, map, mapImagesOptions);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === CONTAINERS_MAP_CLUSTER_SOURCE &&
              feature.properties?.layer === CONTAINERS_MAP_CONTAINERS_LAYER,
          );

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.containerLocation, feature.properties?.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <ContainerGLLayer />
      <ContainerPopupResolver containerLocations={containerLocations} />
    </>
  );
}
