import React from 'react';
import { CustomPicker } from 'react-color';
import { WrappedFieldProps } from 'redux-form';

import { Icon } from 'src/core/components';
import { FieldProps } from 'src/core/components/FieldProps';
import {
  ColorPickerAlphaWrapper,
  ColorPickerContainerWrapper,
  ColorPickerHueWrapper,
  ColorPickerSaturationWrapper,
  ColorPickerSelectButton,
  ColorPickerSelectButtonWrapper,
  ColorPickerSliderButton,
  ColorPickerUpperContainerWrapper,
  ColorPickerWrapper,
  OutsideContainer,
} from './ColorPicker';
let { Hue, Saturation, Alpha, EditableInput } = require('react-color/lib/components/common');

interface Props extends WrappedFieldProps, FieldProps {
  handleClose?: () => void;
  handleChange?: (value: any) => void;
  setBackgroundColor?: (value: any) => void;
  color?: any;
  disabled?: boolean;
}
class ColorPicker extends React.Component<Props> {
  rgba2hex(orig: string) {
    let a,
      rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i) as any,
      alpha = ((rgb && rgb[4]) || '').trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : orig;

    if (alpha !== '') {
      a = alpha;
    } else {
      a = 0o1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return hex;
  }
  render() {
    const hexValue = `#${this.rgba2hex(
      `rgba(${this.props.color.r}, ${this.props.color.g}, ${this.props.color.b}, ${this.props.color.a})`,
    )}`;
    return (
      <ColorPickerContainerWrapper>
        <ColorPickerUpperContainerWrapper>
          <ColorPickerSaturationWrapper>
            <Saturation {...this.props} pointer={Picker} />
          </ColorPickerSaturationWrapper>

          <ColorPickerHueWrapper className="huewrapper">
            <Hue {...this.props} style={{ borderRadius: '15px' }} pointer={Picker} direction="vertical" />
          </ColorPickerHueWrapper>

          <ColorPickerAlphaWrapper>
            <Alpha {...this.props} style={{ borderRadius: '15px' }} pointer={Picker} direction="vertical" />
          </ColorPickerAlphaWrapper>
        </ColorPickerUpperContainerWrapper>
        <EditableInput
          {...this.props}
          value={hexValue}
          style={{
            wrap: {
              position: 'relative',
              height: 50,
            },
            input: {
              position: 'absolute',
              paddingLeft: '10px',
              top: 17,
              height: 33,
              width: '100%',
              borderRadius: '5px',
              border: '1px solid #b4b4c4',
            },
          }}
        />
      </ColorPickerContainerWrapper>
    );
  }
}

const ColorPickerWrapped = CustomPicker(ColorPicker);

function Picker() {
  return <ColorPickerSliderButton />;
}

class CustomizedColorPicker extends React.Component<Props> {
  wrapperRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
  }

  state = {
    color: {
      r: this.props.color.r,
      g: this.props.color.g,
      b: this.props.color.b,
      a: 1,
    },
    displayColorPicker: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClose);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClose);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.color !== prevProps.color && !this.state.displayColorPicker) {
      this.setState({
        color: {
          r: this.props.color.r,
          g: this.props.color.g,
          b: this.props.color.b,
          a: 1,
        },
      });
    }
  }

  handleClick = (e: any) => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  onChange = (value: any) => {
    this.props.input.onChange(value);
  };

  handleClose = (e?: any) => {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(e.target)) {
      this.setState({ displayColorPicker: false });
    }
  };

  handleChange = (color: any) => {
    this.setState({ color: color.rgb });
    this.onChange(color.rgb);
  };

  getDropPickerIconColor = (obj: any) => {
    const Y = 0.2126 * obj.r + 0.7152 * obj.g + 0.0722 * obj.b;

    return Y < 128 && !this.props.disabled ? 'white' : 'black';
  };

  render() {
    const { disabled, meta, input } = this.props;
    const { color, displayColorPicker } = this.state;
    const { r, g, b, a } = color;
    const selectedColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    const dropPickerIconColor = this.getDropPickerIconColor(color);

    return (
      <>
        <ColorPickerSelectButtonWrapper isDisabled={disabled} onClick={(e: any) => !disabled && this.handleClick(e)}>
          <ColorPickerSelectButton
            name="colorPickerColor"
            isDisabled={disabled}
            disabled
            selectedColor={selectedColor}
            dropPickerIconColor={dropPickerIconColor}
          ></ColorPickerSelectButton>
          <Icon icon="dropPicker" color={dropPickerIconColor} height="20" />
        </ColorPickerSelectButtonWrapper>

        {displayColorPicker ? (
          <>
            <OutsideContainer>
              <ColorPickerWrapper
                ref={this.wrapperRef}
                onClick={(e: any) => this.handleClose(e)}
                className="colorPickerWrapper"
              >
                <ColorPickerWrapped
                  meta={meta}
                  input={input}
                  setBackgroundColor={e => this.setState({ setBackgroundColor: e })}
                  color={color}
                  onChange={this.handleChange}
                />
              </ColorPickerWrapper>
            </OutsideContainer>
          </>
        ) : null}
      </>
    );
  }
}

export default CustomizedColorPicker;
