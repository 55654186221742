import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getCustomerTypes } from '../services/customerTypesOptions';

// Constants
const START_LOAD_CUSTOMER = 'customers/customerTypesOptions/START_LOAD_CUSTOMER';
const COMPLETE_LOAD_CUSTOMER = 'customers/customerTypesOptions/COMPLETE_LOAD_CUSTOMER';
const FAIL_LOAD_CUSTOMER = 'customers/customerTypesOptions/FAIL_LOAD_CUSTOMER';

interface State {
  isLoading: boolean;
  customerTypes: TechnicalType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial State
const initialState: State = {
  isLoading: false,
  customerTypes: [],
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD_CUSTOMER,
});

const completeLoad = (customerTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_CUSTOMER,
  customerTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD_CUSTOMER,
});

// Async Actions
export const loadCustomerTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getCustomerTypesPromise = getCustomerTypes();
  getCustomerTypesPromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getCustomerTypesPromise;
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CUSTOMER:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_CUSTOMER:
      return {
        ...state,
        isLoading: false,
      };
    case COMPLETE_LOAD_CUSTOMER:
      return {
        ...state,
        customerTypes: action.customerTypes,
        isLoading: false,
      };
    default:
      return state;
  }
};
