import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { RouteMap } from '../../..';
import { MapContainer, Message, PanelSection } from '../../../../../core/components/styled';
import translate from '../../../../../core/services/translate';
import { AppState } from '../../../../../store';
import { Trigger } from '../../../../interfaces/Trigger';

interface TriggerMapProps {
  triggers?: Trigger[];
  routeId: number;
  onLoadTab?: () => void;
}

const TriggerMap = ({ onLoadTab, routeId, triggers = [] }: TriggerMapProps) => {
  useEffect(() => {
    !!onLoadTab && onLoadTab();
  }, [onLoadTab]);

  return (
    <PanelSection id={`route-${routeId}-trigger-map-form`}>
      {!!triggers.length && (
        <MapContainer>
          <RouteMap triggers={triggers} isGeoFenceHidden />
        </MapContainer>
      )}

      {!triggers.length && <Message padding="sMedium">{translate('routes.noTriggers')}</Message>}
    </PanelSection>
  );
};

const mapStateToProps = (state: AppState) => ({
  triggers: state.routes.triggers.triggers,
});

export default connect(mapStateToProps)(TriggerMap);
