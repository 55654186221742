import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import {
  Button,
  Grid,
  GridColumn,
  ModalFixedFooter,
  PanelSection,
  ModalSection,
} from '../../../core/components/styled';
import { ReportingConfiguration } from 'src/vendors/interfaces/ReportingConfiguration';
import { Table } from '../../../core/components';
import ReportingConfigurationTableRow from '../modals/ReportingConfigurationTableRow';
import translate from 'src/core/services/translate';

export const REPORTING_CONFIGURATION_FORM = 'ReportingConfigurationForm';

interface ComponentProps {
  currentReportingConfiguration: ReportingConfiguration[];
  toggleReportingConfiguration?: (id: number, isChecked: any | boolean) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const ReportingConfigurationForm: React.FC<Props> = ({
  handleSubmit,
  currentReportingConfiguration,
  toggleReportingConfiguration,
}) => {
  const reportingConfigurationTableCells = [
    {
      name: 'reportingName',
      label: translate('insights.reportName'),
      width: '70%',
      id: 'reporting-settings-header-report-type',
    },
    {
      name: 'switch',
      label: translate('insights.displayReport'),
      width: '30%',
      align: 'right',
      id: 'reporting-settings-header-display',
    },
  ];

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ModalSection padding="xxSmall" align="center">
        <PanelSection padding="small" centered>
          <GridColumn size="12/12">
            <Grid centered multiLine>
              <Table
                cells={reportingConfigurationTableCells}
                rows={currentReportingConfiguration}
                rowComponent={ReportingConfigurationTableRow}
                rowProps={{
                  toggleReportingConfiguration,
                }}
              />
            </Grid>
          </GridColumn>
        </PanelSection>
      </ModalSection>

      <ModalFixedFooter>
        <Button color="primary" margin="no" type="submit">
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<any, ComponentProps>({ form: REPORTING_CONFIGURATION_FORM })(ReportingConfigurationForm);
