import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { last } from 'lodash-es';

import { AppState } from 'src/store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { JobStatusForm } from '../forms';
import { Modal } from '../../../core/components';
import { PARTICIPATION } from '../../constants';
import { saveJobStatus, saveWasteAuditJobStatus } from '../../ducks';
import { WASTE_AUDIT_ID } from '../../../fleet/constants';
import translate from '../../../core/services/translate';

interface Props {
  routeId: number;
  isLoading?: boolean;
  isSaving?: boolean;
  closeModal: (data: any) => void;
  routeLocation?: any;
  changeStatus?: (value: number) => void;
  routeLocationInfo: {
    jobStatusId: number;
    equipmentTypeNameSize: string;
    pickupExceptions: any[];
    wasteAuditTypeId: number;
    wasteMaterialTypeName: string;
    vehicleTypeId: number;
  };
  saveJobStatus: DuckFunction<typeof saveJobStatus>;
  saveWasteAuditJobStatus: DuckFunction<typeof saveWasteAuditJobStatus>;
}

class JobStatusModal extends PureComponent<Props> {
  onSubmit = async (formData: any) => {
    const {
      closeModal,
      routeId,
      routeLocation,
      routeLocationInfo,
      saveJobStatus,
      saveWasteAuditJobStatus,
      changeStatus,
    } = this.props;

    const { vehicleTypeId, wasteAuditTypeId } = routeLocationInfo;

    const saveInProgress = true;

    const wasteAuditExceptions =
      wasteAuditTypeId === PARTICIPATION ? [formData.wasteAuditParticipation] : formData.wasteAuditExceptions;

    if (vehicleTypeId === WASTE_AUDIT_ID) {
      await saveWasteAuditJobStatus(formData, routeId, routeLocation.id)
        .then(() => {
          changeStatus && changeStatus(formData.jobStatus);
          createSuccessNotification(`${translate('customers.alertMessages.statusSaved')}`);
          closeModal({
            saveInProgress,
            routeLocationId: routeLocation.id,
            pickupStatusId: formData.jobStatus,
            wasteAuditExceptions,
          });
        })
        .catch(() => {
          createErrorNotification(`${translate('routes.editJobStatus.statusEditError')}`);
        });
    } else {
      await saveJobStatus(formData, routeId, routeLocation.id)
        .then(data => {
          const newException = last(data.data.pickupExceptions);
          changeStatus && changeStatus(formData.jobStatus);
          createSuccessNotification(`${translate('customers.alertMessages.statusSaved')}`);
          closeModal({
            saveInProgress,
            routeLocationId: routeLocation.id,
            pickupStatusId: formData.jobStatus,
            newException,
          });
        })
        .catch(() => {
          createErrorNotification(`${translate('routes.editJobStatus.statusEditError')}`);
        });
    }
  };

  render() {
    const { closeModal, isLoading, isSaving, routeLocationInfo, routeLocation } = this.props;
    const saveInProgress = false;
    return (
      <Modal
        title={translate('routes.editJobStatus.editJobStatus')}
        onClose={() => closeModal({ saveInProgress })}
        padding="medium"
        isLoading={isLoading || isSaving}
        size="small"
        verticalSize="mediumLargest"
      >
        <JobStatusForm onSubmit={this.onSubmit} routeLocation={routeLocation} routeLocationInfo={routeLocationInfo} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.routes.exceptions.isLoading,
  isSaving: state.routes.exceptions.isSaving,
});

const mapDispatchToProps = { saveJobStatus, saveWasteAuditJobStatus };

export default connect(mapStateToProps, mapDispatchToProps)(JobStatusModal);
