export const PM_CONFIGURATION_FORM = 'preventativeMaintenanceConfigurationForm';
export const PM_COMPLETE_MILESTONE_FORM = 'preventativeMaintenanceCompleteMilestoneForm';

export const PM_TYPE_MILEAGE = 1;
export const PM_TYPE_ENGINE_HOURS = 2;
export const PM_TYPE_DATE_CHECKS = 3;

export const PM_STATUS_ALMOST_DUE = 1;
export const PM_STATUS_DUE = 2;
export const PM_STATUS_PAST_DUE = 3;
export const PM_STATUS_UP_TO_DATE = 4;
