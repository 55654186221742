import { find, has, map, reduce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { OptionsType } from 'react-select';
import AsyncSelect from 'react-select/async';
import { WrappedFieldProps } from 'redux-form';

import translate from '../services/translate';
import { dropdownStyles } from './Dropdown';
import { FormError, FormGroup, FormLabel, TypeAheadGroupBadge, TypeAheadGroupContainer } from './styled';
import { theme } from '../styles';

const typeAheadEnhancedStyles = {
  ...dropdownStyles,
  option: (baseStyle: any, state: any) => ({
    ...baseStyle,
    ':active': '#f2f2f3',
    paddingRight: '30px',
    backgroundColor: (state.isFocused && '#f3f4f3') || 'transparent',
    color: '#000',
    fontSize: '12px',
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    fontSize: '14px',
    color: theme.grayDark,
    margin: 0,
  }),
};

const formatGroupLabel = (data: any) => (
  <TypeAheadGroupContainer>
    <span>{data.label}</span>
    <TypeAheadGroupBadge>{data.options.length}</TypeAheadGroupBadge>
  </TypeAheadGroupContainer>
);

interface Props extends WrappedFieldProps {
  isMulti?: boolean;
  defaultOptions?: any[];
  defaultValue?: object;
  disabled?: boolean;
  formShouldRefresh?: boolean;
  getOptionLabel?: (option: any) => string;
  getOptions: (searchTerm: string, onOptionsLoaded: (options: OptionsType<any>) => void) => void;
  id?: string;
  inputValue?: string;
  isClearable?: boolean;
  label?: string;
  margin?: string;
  placeholder?: string;
  inputDefaultValue?: any;
}

// TODO: UPDATE THIS COMPONENT TO allow paginated results
// when the user scrolls to the bottom of the list

const TypeAheadEnhanced: React.FC<Props> = ({
  input,
  isMulti,
  defaultOptions,
  defaultValue,
  disabled,
  formShouldRefresh,
  getOptionLabel,
  getOptions,
  id,
  inputValue,
  isClearable,
  label,
  margin,
  placeholder,
  inputDefaultValue,
  meta: { touched, error, submitFailed },
  ...rest
}) => {
  const [option, setOption] = useState<any>(null);
  const [inputText, setInputText] = useState<string>('');
  const [multipleValue, setMultipleValue] = useState<any>(null);

  const onInputChange = useCallback(
    (value: string) => {
      !isMulti && setInputText(value);
    },
    [isMulti],
  );

  const onChange = useCallback(
    (option: any) => {
      if (option && option.length === undefined) {
        const value = has(option, 'value') ? option.value : null;
        setOption(option);
        input.onChange(value);
      } else if (isClearable && !isMulti) {
        setInputText('');
        setOption(null);
        input.onChange(null);
      } else {
        setMultipleValue(option);
        input.onChange(map(option, 'value'));
      }
    },
    [isClearable, isMulti, input],
  );

  const findSelectedOption = useCallback(
    (value: any) => {
      if (value === '') return '';
      if (inputValue === null) return '';

      reduce(
        (option ? [option] : []).concat(defaultOptions || []),
        (selectedOption, option) => {
          if (selectedOption) return selectedOption;
          if (option.value && option.value === value) return option;
          return find(option.options, { value }) || '';
        },
        '',
      );
    },
    [inputValue, option, defaultOptions],
  );

  const noOptionMessage = useCallback(
    ({ inputValue }: any) =>
      inputValue.length < 3 ? translate('common.searchMinCharacters', { count: 3 }) : translate('common.noResults'),
    [],
  );

  const typeAheadValue = useMemo(
    () =>
      inputDefaultValue
        ? inputDefaultValue
        : isMulti
        ? multipleValue
        : formShouldRefresh
        ? findSelectedOption(input.value)
        : option || findSelectedOption(input.value),
    [inputDefaultValue, isMulti, multipleValue, formShouldRefresh, findSelectedOption, input.value, option],
  );

  return (
    <FormGroup
      margin={margin}
      hasValue={
        (typeof input.value === 'string' && input.value) ||
        (typeof input.value === 'number' && input.value) ||
        (typeof input.value === 'object' && !!Object.keys(input.value).length) ||
        inputText
      }
    >
      {!!label && <FormLabel>{label}</FormLabel>}
      <AsyncSelect
        {...rest}
        isMulti={isMulti}
        defaultOptions={!formShouldRefresh && option ? [option] : defaultOptions}
        defaultValue={defaultValue == null ? '' : defaultValue}
        isAsync
        isDisabled={disabled}
        styles={typeAheadEnhancedStyles}
        formatGroupLabel={formatGroupLabel}
        getOptionLabel={getOptionLabel}
        loadOptions={getOptions}
        noOptionsMessage={noOptionMessage}
        placeholder={placeholder || ''}
        value={typeAheadValue}
        onChange={onChange}
        onInputChange={onInputChange}
      />
      {submitFailed && error && <FormError>{error}</FormError>}
    </FormGroup>
  );
};

export default TypeAheadEnhanced;
