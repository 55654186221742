import React from 'react';
import { Layer } from 'react-map-gl';

import {
  DASHBOARD_CLUSTERS_SOURCE,
  DASHBOARD_GEO_FENCE_INCIDENTS_LAYER,
  DASHBOARD_VEHICLE_INSIGHTS_LAYER,
} from 'src/dashboard/constants/dashboardMapGL';

const DashboardGeoFenceIncidentsGLLayers: React.FC = React.memo(() => (
  <Layer
    id={DASHBOARD_GEO_FENCE_INCIDENTS_LAYER}
    type="symbol"
    source={DASHBOARD_CLUSTERS_SOURCE}
    filter={[
      'all',
      ['!', ['has', 'point_count']],
      ['in', ['get', 'layer'], ['literal', [DASHBOARD_GEO_FENCE_INCIDENTS_LAYER, DASHBOARD_VEHICLE_INSIGHTS_LAYER]]],
    ]}
    paint={{}}
    layout={{
      'icon-image': ['get', 'icon'],
      'icon-size': 1,
      'icon-allow-overlap': true,
    }}
  />
));

export default DashboardGeoFenceIncidentsGLLayers;
