import React from 'react';

import CityInsightMarker from './CityInsightMarker';
import { RubiconMarkerClusterer } from './RubiconMarkerClusterer';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';
import { CommunityInsightMapItem } from '../../insights/interfaces/communityInsights';

interface Props {
  cityInsights: CommunityInsightMapItem[];
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string) => void;
}

const CityInsights: React.FC<Props> = ({ cityInsights, openedInfoWindows, toggleInfoWindow }) => (
  <RubiconMarkerClusterer>
    {clusterer => (
      <>
        {cityInsights.map(cityInsight => (
          <CityInsightMarker
            key={cityInsight.mapSelectorId}
            cityInsight={cityInsight}
            isInfoWindowOpen={openedInfoWindows.cityInsights[cityInsight.mapSelectorId]}
            toggleInfoWindow={toggleInfoWindow}
            clusterer={clusterer}
          />
        ))}
      </>
    )}
  </RubiconMarkerClusterer>
);

export default CityInsights;
