import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { reset, getFormValues } from 'redux-form';
import { toLower } from 'lodash-es';
import moment from 'moment';

import { AppState } from '../../../store';
import {
  AGING_REPORT,
  BILLING_REPORT,
  COLLECTIONS_REPORT,
  COMMUNITY_PARTICIPATION,
  DAILY,
  DRIVER_SAFETY_INSPECTION,
  EXCEPTIONS,
  LOCATION_ALERTS,
  ORPHAN_INVOICE_REPORT,
  PAYMENT_REPORT,
  PRE_POST_TRIP_INSPECTION,
  ROLL_OF_SERVICE_HISTORY,
  STREET_SWEEPING_ACTIVITY_SUMMARY,
  STREET_SWEEPING_OBSTACLES,
  UNSERVICED_RESIDENTIAL,
  USER_ACTIVITY,
  Y_DEVICE_STATUS,
} from '../../constants/reportTypes';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { createExports, loadReportsList, resetDailyReportTypes } from '../../ducks';
import {
  getVehicleFiltersPreferencesIds,
  getServiceZonesFiltersPreferencesIds,
  getSupervisorsFiltersPreferencesIds,
} from 'src/common/utils/filters';
import {
  loadDrivers,
  loadVehicles,
  loadVehicleTypesForVendor,
  resetDrivers,
  resetVehicles,
  resetVehicleTypesForVendor,
  vehicleTypesForVendorWithRoleTypeSelector,
} from '../../../fleet/ducks';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import {
  Grid,
  GridColumn,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
  Text,
} from '../../../core/components/styled';
import { BetaBaseWrapper, BetaButton } from 'src/routes/components/styled/RouteTracker';
import { BetaReportsForm, VideoRequestsSearchForm } from '../forms';
import { createSuccessNotification } from 'src/core/services/createNotification';
import { downloadReport } from 'src/insights/services/exportReports';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { getQueryParams } from 'src/utils/services/queryParams';
import { getUser } from 'src/account/services/auth';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import { loadVideoRequests } from 'src/routes/ducks';
import { RecentExportsTableRow, VideoRequestsTableRow } from '../index';
import { RESIDENTIAL_ID, ROLL_OFF_ID, WASTE_AUDIT_ID, STREET_SWEEPER } from 'src/fleet/constants';
import { setReportsNewLayoutActive } from 'src/insights/services/reportsPageLayout';
import { START_DATE_BETA_REPORTS, WEEKDAYS } from 'src/core/constants/weekdays';
import { Table } from 'src/core/components';
import { VideoDownloadDetails } from 'src/common/interfaces/VideoDownloadDetails';
import { VideoRequestsSearchFormValues } from '../forms/VideoRequestsSearchForm';
import translate from '../../../core/services/translate';

interface ComponentProps {
  excludeVehicleTypes: string[];
  vehicleRoleTypeId: number[];
}

interface Props extends ComponentProps {
  createExports: DuckFunction<typeof createExports>;
  emailAddresses?: string[];
  isLoadingVideoRequests: boolean;
  isOnDemandVideoDownloadEnabled: boolean;
  loadDrivers: DuckFunction<typeof loadDrivers>;
  loadReportsList: DuckFunction<typeof loadReportsList>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadVideoRequests: DuckFunction<typeof loadVideoRequests>;
  reports?: any;
  reset: DuckAction<typeof reset>;
  resetDailyReportTypes: DuckAction<typeof resetDailyReportTypes>;
  resetDrivers: DuckAction<typeof resetDrivers>;
  resetVehicles: DuckAction<typeof resetVehicles>;
  resetVehicleTypesForVendor: DuckAction<typeof resetVehicleTypesForVendor>;
  serviceZoneIdsCSV: number[];
  supervisorIdsCSV: number[];
  userId: string;
  vehicles?: any[];
  vehicleTypeIdsCSV: number[];
  vehicleTypesForVendor?: any[];
  vendorId: number;
  videoId?: string;
  videoRequestEndDate?: Date | string;
  videoRequests: VideoDownloadDetails[];
  videoRequestsSearchFormValues: VideoRequestsSearchFormValues;
  videoRequestStartDate?: Date | string;
}

interface OnSubmitProps {
  customerId?: number;
  customerIds: number[];
  customerLocationId?: { locationId: number };
  dateRange: { from: string; to: string };
  driverName: string;
  personId: string;
  reportType: any;
  routeName: string;
  startDate: string;
  vehicleName: string[];
  vehicleType: string[];
  isWeekendOnly: boolean;
}

interface State {
  emailAddresses: string[];
  isInitialLoad: boolean;
  isLoadingExport: boolean;
  recentExportedFiles: any[];
}

class BetaReportPage extends PureComponent<Props, State> {
  readonly state: State = {
    emailAddresses: [],
    isInitialLoad: true,
    isLoadingExport: false,
    recentExportedFiles: this.props.reports?.reports,
  };

  timer?: number;
  timerInterval = 20000;

  componentDidMount = () => {
    const { vendorId } = this.props;
    const params = getQueryParams(window.location.search);
    if (params && params.fileId && params.fileName) {
      const fileId = parseInt(params.fileId);
      downloadReport('', params.fileName, vendorId, fileId);
    }

    this.initTimer();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.recentExportedFiles !== this.state.recentExportedFiles) this.getRecentExportReports();
    if (prevProps.videoRequests !== this.props.videoRequests) this.setState({ isInitialLoad: false });
    if (!getIsVendorNotChanged(this.props.vendorId)) clearInterval(this.timer);
  };

  componentWillUnmount() {
    const { resetDailyReportTypes, resetVehicleTypesForVendor, resetVehicles, resetDrivers } = this.props;

    resetDailyReportTypes();
    resetVehicleTypesForVendor();
    resetVehicles();
    resetDrivers();

    clearInterval(this.timer);
  }

  initTimer = () => {
    this.timer = window.setInterval(() => {
      const { loadVideoRequests, userId, vendorId, videoRequestsSearchFormValues } = this.props;
      const noLoading = true;

      if (getIsVendorNotChanged(vendorId)) {
        loadVideoRequests(
          vendorId,
          userId,
          videoRequestsSearchFormValues.dateRange.from,
          videoRequestsSearchFormValues.dateRange.to,
          videoRequestsSearchFormValues.videoStatus,
          noLoading,
        );
      }
    }, this.timerInterval);
  };

  getRecentExportReports = () => {
    const { reports, vendorId, loadReportsList } = this.props;
    const recentReports = reports.reports;

    const schduledReports = recentReports?.filter((r: any) => r.status?.id === 1);
    if (!!schduledReports.length) {
      setTimeout(async () => {
        await loadReportsList(vendorId).then(response => {
          this.setState({ isLoadingExport: false, recentExportedFiles: response.reports });
        });
      }, this.timerInterval);
    }
  };

  onSubmit = ({
    personId,
    reportType,
    vehicleType,
    vehicleName,
    routeName,
    driverName,
    dateRange: { from, to },
    customerIds,
    startDate,
    customerId,
    customerLocationId,
    isWeekendOnly
  }: OnSubmitProps) => {
    const { createExports, loadReportsList, vehicles = [], vehicleTypesForVendor = [], vendorId, reset } = this.props;
    const { emailAddresses } = this.state;
    const prePostTripVehicleName: any[] = [];

    if (vehicleName) {
      vehicleName.forEach(item => {
        const vehicle = vehicles.find(vehicle => vehicle.regplate === item);
        prePostTripVehicleName.push(vehicle.id);
      });
    }

    const prePostTripVehicleTypes: any[] = [];
    if (vehicleType) {
      vehicleType.forEach(item => {
        const vehicle = vehicleTypesForVendor.find(vehicle => vehicle.technicalName === item);
        prePostTripVehicleTypes.push(vehicle.id);
      });
    }

    const isSingleDateDisplayed =
      reportType === AGING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === DAILY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === Y_DEVICE_STATUS;

    const isDataForBillingReportsDisplayed =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT;

    const getVehicleName = () => {
      if (vehicleName && vehicleName.length === vehicles.length) {
        return null;
      }

      switch (reportType) {
        case PRE_POST_TRIP_INSPECTION:
        case DRIVER_SAFETY_INSPECTION:
          return prePostTripVehicleName.length ? prePostTripVehicleName : null;
        default:
          return vehicleName;
      }
    };

    const getVehicleType = () => {
      switch (reportType) {
        case STREET_SWEEPING_ACTIVITY_SUMMARY:
        case STREET_SWEEPING_OBSTACLES:
          return STREET_SWEEPER;
        case EXCEPTIONS:
        case COMMUNITY_PARTICIPATION:
          return WASTE_AUDIT_ID;
        case UNSERVICED_RESIDENTIAL:
          return RESIDENTIAL_ID;
        case ROLL_OF_SERVICE_HISTORY:
          return ROLL_OFF_ID;
        case PRE_POST_TRIP_INSPECTION:
        case DRIVER_SAFETY_INSPECTION:
          return prePostTripVehicleTypes.length ? prePostTripVehicleTypes : null;
        default:
          return vehicleType;
      }
    };

    const configuration = {
      vehicleType: reportType === DRIVER_SAFETY_INSPECTION ? undefined : getVehicleType()?.toString(),
      vehicleTypeIds: reportType === DRIVER_SAFETY_INSPECTION ? getVehicleType()?.toString() : undefined,
      vehicleName: reportType === DRIVER_SAFETY_INSPECTION ? undefined : getVehicleName()?.toString(),
      vehicleIds: reportType === DRIVER_SAFETY_INSPECTION ? getVehicleName()?.toString() : undefined,
      routeName: routeName?.toString(),
      driverName: driverName?.toString(),
      customerIds: customerIds,
      startDate:
        reportType === ROLL_OF_SERVICE_HISTORY || reportType === LOCATION_ALERTS
          ? START_DATE_BETA_REPORTS
          : isSingleDateDisplayed
            ? startDate
            : from,
      endDate:
        reportType === ROLL_OF_SERVICE_HISTORY || reportType === LOCATION_ALERTS
          ? moment().add(1, 'day').format('M-D-YYYY')
          : isSingleDateDisplayed
            ? undefined
            : to,
      date: reportType === DRIVER_SAFETY_INSPECTION ? startDate : undefined,
      customerId: isDataForBillingReportsDisplayed ? customerId : undefined,
      customerLocationId: isDataForBillingReportsDisplayed ? customerLocationId?.locationId : undefined,
      isWeekendOnly: isWeekendOnly ? isWeekendOnly : undefined
    };

    const userActivityConfiguration = {
      driverId: driverName?.toString(),
      personId: personId?.toString(),
      startDate: isSingleDateDisplayed ? startDate : from,
      endDate: isSingleDateDisplayed ? undefined : to,
    };

    const configurationOptions = reportType === USER_ACTIVITY ? userActivityConfiguration : configuration;

    this.setState({ isLoadingExport: true });
    createExports(vendorId, reportType, emailAddresses, configurationOptions).then(() => {
      loadReportsList(vendorId).then(response => {
        this.setState({ isLoadingExport: false, recentExportedFiles: response.reports });
        reset(toLower(translate('insights.reports')));
        createSuccessNotification(translate('insights.exportQueued'));
      });
    });
  };

  onVideoRequestsSearch = (formData: VideoRequestsSearchFormValues) => {
    const { loadVideoRequests, vendorId, userId } = this.props;
    loadVideoRequests(vendorId, userId, formData.dateRange.from, formData.dateRange.to, formData.videoStatus);
  };

  render() {
    const {
      isLoadingVideoRequests,
      isOnDemandVideoDownloadEnabled,
      reports,
      vendorId,
      videoId,
      videoRequestEndDate,
      videoRequests,
      videoRequestStartDate,
    } = this.props;

    const { isInitialLoad, isLoadingExport } = this.state;
    const { email } = getUser();

    const recentExportsTableCells = [
      { name: 'fileName', label: translate('autoDispatch.fileName'), width: '28%', sortable: true },
      { name: 'reportType', label: translate('insights.reportType'), width: '21%', sortable: true },
      { name: 'status', label: translate('common.status'), width: '13%', sortable: true },
      {
        name: 'totalRecords',
        label: translate('customers.customerUpload.numberOfRecords'),
        width: '13%',
        sortable: true,
      },
      { name: 'date', label: translate('common.date'), width: '15%', sortable: true },
      { name: 'options', label: translate('common.options'), width: '10%' },
    ];

    const videoRequestsTableCells = [
      { name: 'fileName', label: translate('autoDispatch.fileName'), width: '27%', sortable: true },
      { name: 'reportType', label: translate('insights.reportType'), width: '13%', sortable: true },
      { name: 'status', label: translate('common.status'), width: '12%', sortable: true },
      {
        name: 'totalRecords',
        label: translate('customers.customerUpload.numberOfRecords'),
        width: '13%',
        sortable: true,
      },
      { name: 'date', label: translate('routes.videoRequest.videoDate'), width: '12%', sortable: true },
      {
        name: 'videoRequestDate',
        label: translate('routes.videoRequest.videoRequestedDate'),
        width: '14%',
        sortable: true,
      },
      { name: 'options', label: translate('common.options'), width: '10%' },
    ];

    const filteredReports = reports.reports
      .filter(
        (el: any) => moment().diff(el.date, 'days') <= WEEKDAYS.length && toLower(el.createdByEmail) === toLower(email),
      )
      .reverse();

    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('insights.dataExport')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <BetaBaseWrapper inline>
              <BetaButton
                noUnderline
                align="right"
                padding="no"
                color="primary"
                margin="sMedium small no no"
                onClick={() => {
                  setReportsNewLayoutActive(false);
                  window.location.pathname = `/insights/reports`;
                }}
              >
                {translate('routes.previousLayout')}
              </BetaButton>
            </BetaBaseWrapper>
          </PageActions>
        </PageHeader>

        <Panel padding="sMedium">
          <PanelSectionGroup isLoading={isLoadingExport}>
            <Grid margin="no no medium">
              <GridColumn size="9/12" padding="no small">
                <PanelSectionTitle margin="no">{translate('insights.createExport')}</PanelSectionTitle>
              </GridColumn>
            </Grid>
            <GridColumn size="12/12" padding="no small">
              <BetaReportsForm
                getEmailAddresses={(value: string[]) => this.setState({ emailAddresses: value })}
                onSubmit={this.onSubmit}
              />
            </GridColumn>
          </PanelSectionGroup>
        </Panel>

        <Panel margin="sMedium no no no" padding="sMedium">
          <PanelSectionGroup>
            <Grid margin="no no medium">
              <GridColumn size="9/12" padding="no small">
                <PanelSectionTitle margin="no">{translate('insights.myRecentExports')}</PanelSectionTitle>
              </GridColumn>
            </Grid>
            {reports && reports.reports && (
              <PanelSection padding={filteredReports.length > 0 ? 'no' : 'small'}>
                {filteredReports.length > 0 ? (
                  <Table
                    cells={recentExportsTableCells}
                    rows={filteredReports}
                    rowComponent={RecentExportsTableRow}
                    rowProps={{ vendorId }}
                  />
                ) : (
                  <Text padding="no">{translate('insights.noRecentExports')}</Text>
                )}
              </PanelSection>
            )}
          </PanelSectionGroup>
        </Panel>

        {isOnDemandVideoDownloadEnabled && (
          <Panel margin="sMedium no no no" padding="sMedium">
            <PanelSectionGroup>
              <Grid margin="no no medium">
                <GridColumn size="9/12" padding="no small">
                  <PanelSectionTitle margin="no">{translate('routes.videoRequest.videoRequests')}</PanelSectionTitle>
                </GridColumn>
              </Grid>

              <GridColumn size="12/12" padding="no small">
                <VideoRequestsSearchForm
                  onSubmit={this.onVideoRequestsSearch}
                  videoRequestEndDate={videoRequestEndDate}
                  videoRequestStartDate={videoRequestStartDate}
                />
              </GridColumn>

              <PanelSection isLoading={isLoadingVideoRequests} minHeight={40}>
                {!!videoRequests.length ? (
                  <Table
                    cells={videoRequestsTableCells}
                    rows={videoRequests}
                    rowComponent={VideoRequestsTableRow}
                    rowProps={{ videoId, isInitialLoad }}
                  />
                ) : (
                  <Text padding="no no no small">{translate('routes.videoRequest.noVideoRequests')}</Text>
                )}
              </PanelSection>
            </PanelSectionGroup>
          </Panel>
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { filters } = state.common.filters;

  return {
    isLoadingVideoRequests: state.routes.videoRequest.isLoadingVideoRequests,
    isOnDemandVideoDownloadEnabled: isOnDemandVideoDownloadFeatureEnabled(state),
    reports: state.insights.reportsList.reports,
    serviceZoneIdsCSV: getServiceZonesFiltersPreferencesIds(filters),
    supervisorIdsCSV: getSupervisorsFiltersPreferencesIds(filters),
    userId: currentUserIdSelector(state.account.login) as any,
    vehicles: state.fleet.vehicles.vehicles,
    vehicleTypeIdsCSV: getVehicleFiltersPreferencesIds(filters),
    vehicleTypesForVendor: (vehicleTypesForVendorWithRoleTypeSelector as any)(
      state.fleet.vehicleTypesForVendor,
      ownProps.vehicleRoleTypeId,
      ownProps.excludeVehicleTypes,
    ),
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    videoRequests: state.routes.videoRequest.videoRequests,
    videoRequestsSearchFormValues: (getFormValues as any)('videoRequestsSearchForm')(state),
  };
};

const mapDispatchToProps = {
  createExports,
  loadDrivers,
  loadReportsList,
  loadVehicles,
  loadVehicleTypesForVendor,
  loadVideoRequests,
  reset,
  resetDailyReportTypes,
  resetDrivers,
  resetVehicles,
  resetVehicleTypesForVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetaReportPage);
