import { get } from 'lodash-es';

import { IBillingModule } from '../interfaces/BillingModule';

export const transformBillingModule = (data: IBillingModule) => {
  return {
    ...data,
    lateFeeSetting: {
      ...data.lateFeeSetting,
      feeStructure: get(data.lateFeeSetting, 'feeStructure.id', null),
      feeApplicationMethod: get(data.lateFeeSetting, 'feeApplicationMethod.id', null),
    },
  };
};
