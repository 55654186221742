import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles';
import {
  Confirmation as ConfirmationContainer,
  ConfirmationDialog,
  ConfirmationTitle,
  ConfirmationMessage,
  ButtonSet,
  Button,
} from './styled';
import translate from '../services/translate';

interface Props {
  resolve: (value: any) => void;
  reject: (value: any) => void;
  title: string;
  message?: string;
  cantReject?: boolean;
}

class InformationPopup extends React.Component<Props> {
  state = { show: true };

  componentDidMount() {
    document.addEventListener('keydown', this.keyboardHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardHandler, false);
  }

  keyboardHandler = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.cancel();
      return;
    }

    if (event.key === 'Enter') {
      this.accept();
    }
  };

  accept = (value?: any) => {
    this.setState({ show: false }, () => {
      this.props.resolve(value);
    });
  };

  cancel = (value?: any) => {
    if (this.props.cantReject) {
      return;
    }
    this.setState({ show: false }, () => {
      this.props.reject(value);
    });
  };

  render() {
    const { title, message } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <ConfirmationContainer>
          <ConfirmationDialog>
            <ConfirmationTitle>{title}</ConfirmationTitle>
            <ConfirmationMessage>{message}</ConfirmationMessage>
            <ButtonSet>
              <Button color="primary" onClick={this.accept}>
                {translate('common.ok')}
              </Button>
            </ButtonSet>
          </ConfirmationDialog>
        </ConfirmationContainer>
      </ThemeProvider>
    );
  }
}

export default InformationPopup;
