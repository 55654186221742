import React, { Fragment, PureComponent } from 'react';

import { size } from 'lodash-es';
import { observer } from 'mobx-react';

import { ApplicationModeChange } from '../../routes/components/mapWithTimeline/Interfaces';
import { GOOGLE as google } from '../constants';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';
import ApplicationModeChangeMarker from './ApplicationModeChangeMarker';

const isCoordinateGroupInsideViewBoundaries = (
  applicationModeChanges: ApplicationModeChange[],
  mapBounds: any,
  mapZoom: number,
) => {
  if (mapZoom <= 10) return false;

  const groupBounds = new google.maps.LatLngBounds();
  applicationModeChanges.forEach(coordinateGroup =>
    groupBounds.extend(
      new google.maps.LatLng(coordinateGroup.coordinates.latitude, coordinateGroup.coordinates.longitude),
    ),
  );

  return mapBounds.intersects(groupBounds);
};

interface Props {
  applicationModeChanges: ApplicationModeChange[];
  vehicleIndex: number;
  vehicleName: string;
  vehicleId: number;
  mapZoom: number;
  mapBounds: any;
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string, vehicleIndex: number, timestamp: string) => string;
  unitOfMeasure: string;
}

class ApplicationModeChangesGroup extends PureComponent<Props, { applicationModeChangeIds: any[] }> {
  readonly state = {
    applicationModeChangeIds: [],
  };

  componentDidMount() {
    const { applicationModeChanges } = this.props;
    if (applicationModeChanges) this.setApplicationModeChangeIds();
  }

  componentDidUpdate(prevProps: Props) {
    if (size(prevProps.applicationModeChanges) !== size(this.props.applicationModeChanges)) {
      this.setApplicationModeChangeIds();
    }
  }

  setApplicationModeChangeIds = () => {
    this.setState({
      applicationModeChangeIds: this.props.applicationModeChanges.map((group, i) => `applicationModeChange_${this.props.vehicleIndex}_${i + 1}`),
    });
  };

  getId = (index: number) => this.state.applicationModeChangeIds[index];

  render() {
    const {
      applicationModeChanges,
      mapBounds,
      mapZoom,
      vehicleIndex,
      vehicleId,
      vehicleName,
      openedInfoWindows,
      toggleInfoWindow,
      unitOfMeasure,
    } = this.props;

    return (
      <Fragment>
        {applicationModeChanges &&
          isCoordinateGroupInsideViewBoundaries(applicationModeChanges, mapBounds, mapZoom) &&
          applicationModeChanges.map((applicationModeChange, index) =>
            applicationModeChange.hideOnMap && applicationModeChange.hideOnMap.get() ? null : (
              <ApplicationModeChangeMarker
                applicationModeChange={applicationModeChange}
                applicationModeChangeId={this.getId(index)}
                isInfoWindowOpen={openedInfoWindows.applicationModeChanges[this.getId(index)]}
                key={index}
                mapZoom={mapZoom}
                strobeImageUrl={openedInfoWindows.imageUrl}
                toggleInfoWindow={toggleInfoWindow}
                unitOfMeasure={unitOfMeasure}
                vehicleId={vehicleId}
                vehicleIndex={vehicleIndex}
                vehicleName={vehicleName}
              />
            ),
          )}
      </Fragment>
    );
  }
}

export default observer(ApplicationModeChangesGroup);
