import { map, orderBy } from 'lodash-es';

import { CheckAlResourcesCheckbox } from '../pages/ResourcesPage';
import { Facility } from 'src/common/interfaces/Facility';
import { FACILITY_NONE_VALUE } from 'src/fleet/constants/drivers';
import { IMPERIAL } from 'src/vendors/constants';
import { isY, isX } from 'src/vendors/constants/deviceRoleTypes';
import { TableCell } from '../../../core/components/Table';
import { useSelector } from 'src/core/hooks/useSelector';
import { Vehicle } from 'src/fleet/interfaces/Vehicles';
import translate from '../../../core/services/translate';

interface VehiclesTableColumnsReturn {
  isAdmin: boolean;
  columns: TableCell[];
  widths: (string | undefined)[];
}

export const useVehiclesTableColumns = (
  isNotSupportAndIsNotViewOnly: boolean,
  checkAll?: () => void,
  allChecked?: boolean,
  partialChecked?: boolean,
  isEngineHoursDataVisible?: boolean,
  isFuelDataVisible?: boolean,
  isOdometerReadingDataVisible?: boolean,
): VehiclesTableColumnsReturn => {
  const vendorHasMileageInformation = useSelector(state => state.fleet.vehicles.vendorHasMileageInformation);
  const isRubiconAdmin: boolean = useSelector(state => (state.vendors.users.user as any)?.isRubiconAdmin || false);
  const isRubiconHauler: boolean | undefined = useSelector(state => (state.vendors.users.user as any)?.isRubiconHauler);
  const isRubiconSuperAdmin: boolean | undefined = useSelector(
    state => (state.vendors.users.user as any)?.isRubiconSuperAdmin,
  );
  const deviceRoleTypeId: number | undefined = useSelector(
    state => (state.vendors.vendor.vendor as any)?.deviceRoleTypeId,
  );

  const systemOfMeasurementId: number | undefined = useSelector(
    state => (state.vendors.vendor.vendor as any)?.systemOfMeasurementId,
  );

  const isYType = isY(deviceRoleTypeId);
  const isXType = isX(deviceRoleTypeId);

  const isImperialMeasurementSystem = systemOfMeasurementId === IMPERIAL;

  const widths = [
    isNotSupportAndIsNotViewOnly ? '5%' : '0 ',
    '11.5%',
    '11%',
    '9%',
    '7.9%',
    '10%',
    '10%',
    '10%',
    '11.6%',
    '14%',
    '17.9%',
    '27.9%',
    '20%',
  ];

  const columns: TableCell[] = [
    {
      name: 'selectAll',
      component: isNotSupportAndIsNotViewOnly ? CheckAlResourcesCheckbox : undefined,
      componentProps: {
        checkAll: checkAll,
        getChecked: () => allChecked,
        partialChecked: partialChecked,
      },
      align: 'center',
      noPaddingRight: true,
      flex: isNotSupportAndIsNotViewOnly ? widths[0] : '0',
    },
    {
      name: 'regplate',
      label: translate('vehicles.vehicle'),
      flex: widths[1],
      sortable: true,
    },
    {
      name: 'vehicleTypeName',
      label: translate('vehicles.vehicleType'),
      flex: isOdometerReadingDataVisible ? widths[2] : widths[12],
      sortable: true,
    },
    {
      name: 'deviceId',
      label: isRubiconAdmin ? translate('common.deviceId') : translate('common.devices'),
      flex: !isEngineHoursDataVisible && !isFuelDataVisible ? widths[11] : widths[6],
      visibility: !(isRubiconAdmin || isRubiconSuperAdmin) && isRubiconHauler ? 'none' : undefined,
    },
    {
      name: 'isActive',
      label: translate('common.status'),
      flex: widths[7],
      sortable: true,
    },
    {
      name: 'issues',
      label: translate('vehicles.issues'),
      flex: widths[8],
    },
    {
      name: 'options',
      label: translate('common.options'),
      flex: widths[9],
      align: 'right',
    },
  ];

  if (isOdometerReadingDataVisible)
    columns.splice(3, 0, {
      name: 'mileage',
      label: translate('vehicles.mileage'),
      flex: widths[3],
      sortable: true,
      visibility: isYType || (isXType && vendorHasMileageInformation) ? undefined : 'none',
    });

  if (isEngineHoursDataVisible)
    columns.splice(isOdometerReadingDataVisible ? 4 : 3, 0, {
      name: 'engineHours',
      label: translate('vehicles.engineHours'),
      flex: isFuelDataVisible ? widths[4] : widths[10],
      sortable: true,
      visibility: !isYType ? 'none' : undefined,
    });

  if (isFuelDataVisible)
    columns.splice(
      isEngineHoursDataVisible && isOdometerReadingDataVisible
        ? 5
        : !isEngineHoursDataVisible && !isOdometerReadingDataVisible
        ? 3
        : 4,
      0,
      {
        name: 'fuelEfficiency',
        label: translate(`vehicles.${isImperialMeasurementSystem ? 'fuelEfficiencyMPG' : 'fuelEfficiencyKPL'}`),
        flex: isEngineHoursDataVisible ? widths[5] : widths[10],
        sortable: true,
        visibility: !isYType ? 'none' : undefined,
      },
    );

  return { isAdmin: isRubiconAdmin, widths, columns };
};

export const getOperationalFacilityWithNoneOptions = (facilities: Facility[], shouldOrderFacilities?: boolean) => {
  const operationalFacility = map(facilities, ({ locationId, name, isActive }: Facility) => ({
    label: name,
    value: locationId,
    isActive: shouldOrderFacilities ? isActive : true,
  }));
  const facilityNull = {
    label: translate('routes.none'),
    value: FACILITY_NONE_VALUE,
    isActive: true,
  };

  operationalFacility.unshift(facilityNull);

  return shouldOrderFacilities ? orderBy(operationalFacility, 'isActive', 'desc') : operationalFacility;
};

export const getIsFacilityActive = (facilities: Facility[], formValues?: any, facilityLocationId?: number) => {
  return formValues?.locationId || (facilityLocationId && facilityLocationId !== FACILITY_NONE_VALUE)
    ? facilities.filter(
        facility => facility.locationId === formValues?.locationId || facility.locationId === facilityLocationId,
      )[0]?.isActive
    : true;
};

export const AVAILABILITY_ID = -1;

export const availableLabel = {
  label: translate('common.available'),
  value: AVAILABILITY_ID,
  isDisabled: true,
  isLabel: true,
};

export const unavailableLabel = {
  label: translate('common.unavailable'),
  value: AVAILABILITY_ID,
  isDisabled: true,
  isLabel: true,
};

export const getVehiclesWithLabels = (vehicles: Vehicle[], withVehicleName?: boolean) => {
  const vehiclesAvailable = vehicles.filter(vehicle => vehicle.isAvailable);
  const vehiclesUnavailable = vehicles.filter(vehicle => !vehicle.isAvailable);

  const allVehiclesAvailable = vehiclesAvailable.map(({ regplate, id }) => ({
    label: regplate,
    value: withVehicleName ? regplate : id,
    isDisabled: false,
  }));
  const allVehiclesUnavailable = vehiclesUnavailable.map(({ regplate, id, isAvailable }) => ({
    label: regplate,
    value: withVehicleName ? regplate : id,
    isDisabled: true,
    isActive: isAvailable,
  }));

  const vehicleOptions = !!vehiclesUnavailable.length
    ? [availableLabel, ...allVehiclesAvailable, unavailableLabel, ...allVehiclesUnavailable]
    : [...allVehiclesAvailable, ...allVehiclesUnavailable];

  return vehicleOptions;
};
