import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { Dropdown, Input, PopoverWrapper, TextArea } from '../../../core/components';
import { DuckFunction } from '../../../contracts/ducks';
import { duration } from '../../../utils/services/formatter';
import { InfoIcon } from '../../../routes/components/styled';
import { isRequired, isEmails } from '../../../utils/services/validator';
import { loadStationaryAlert } from '../../ducks';
import { VehicleTypeForVendorDropdown } from '../../../fleet/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
  Popover,
} from '../../../core/components/styled';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  vendorId: number;
  vehicleTypeId: number;
  loadStationaryAlert: DuckFunction<typeof loadStationaryAlert>;
  onCancel: (pristine?: boolean) => void;
  pristine: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class StationaryAlertsForm extends PureComponent<Props> {
  onVehicleTypeIdChange = async (_: any, vehicleTypeId: number) => {
    const { vendorId, loadStationaryAlert } = this.props;
    await loadStationaryAlert(vendorId, vehicleTypeId);
  };

  render() {
    const { vehicleTypeId, handleSubmit, onCancel, pristine } = this.props;
    const alertTimeOptions = map([15, 30, 45, 60, 75, 90, 105, 120], alertTime => ({
      label: duration(alertTime, 'minutes', 'hh:mm'),
      value: alertTime,
    }));

    return (
      <Fragment>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit}>
          <PanelSection padding="small xSmall">
            <Grid centered>
              <GridColumn size="6/12">
                <Field
                  name="vehicleTypeId"
                  component={VehicleTypeForVendorDropdown as any}
                  dropdownProps={{
                    margin: 'no',
                  }}
                  onChange={this.onVehicleTypeIdChange}
                  withLabel
                />
              </GridColumn>
            </Grid>
          </PanelSection>

          {vehicleTypeId && (
            <PanelSection vertical padding="small xSmall">
              <Grid centered>
                <GridColumn verticalAlign="center" size="6/12">
                  <Field
                    name="stationaryCountdownStartAt"
                    component={Input}
                    min={0}
                    type="number"
                    validate={[isRequired]}
                    props={{
                      label: translate('vendors.stationaryCountdownStartAt'),
                      margin: 'no',
                    }}
                  />
                  <PopoverWrapper
                    margin="medium no no xSmall"
                    triggerButton={<InfoIcon />}
                    popoverContent={<Popover>{translate('vendors.stationaryCountdownStartAtDescription')}</Popover>}
                    size="large"
                  />
                </GridColumn>
              </Grid>
              <Grid centered>
                <GridColumn size="6/12">
                  <Field
                    name="alertTime"
                    component={Dropdown}
                    options={alertTimeOptions}
                    margin="xSmall no"
                    label={translate('vendors.stationaryAlertsTime')}
                    validate={[isRequired]}
                  />
                  <Field
                    name="emailList"
                    component={TextArea}
                    margin="no"
                    label={translate('vendors.emailNotification')}
                    validate={[isEmails]}
                  />
                </GridColumn>
              </Grid>
              <Grid centered>
                <GridColumn size="6/12">
                  <ButtonSet margin="medium no medium no">
                    <Button type="submit" color="primary">
                      {translate('common.save')}
                    </Button>
                  </ButtonSet>
                </GridColumn>
              </Grid>
            </PanelSection>
          )}
        </form>
      </Fragment>
    );
  }
}

const formSelector = formValueSelector('stationaryAlerts');

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
  vehicleTypeId: formSelector(state, 'vehicleTypeId'),
  initialValues: state.vendors.stationaryAlerts.stationaryAlert,
});

const mapDispatchToProps = { loadStationaryAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'stationaryAlerts',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(StationaryAlertsForm),
);
