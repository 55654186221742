import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  downloadDocument as doDownloadDocument,
  loadServiceConfirmation as doloadServiceConfirmation,
  loadServiceConfirmationByUserId as doloadServiceConfirmationByUserId,
  updateServiceConfirmation as doupdateServiceConfirmation,
  updateServiceConfirmationByUserId as doupdateServiceConfirmationByUserId,
  uploadDocument as doUploadDocument,
  uploadDocumentByUserId as doUploadDocumentByUserId,
} from '../services/loadOpenDispatches';
import { transformUpdateOpenDispatches as dotransformUpdateOpenDispatches } from '../services/transformOpenDispatches';

//  Actions
const START_LOAD = 'fleet/serviceConfirmation/START_LOAD';
const COMPLETE_LOAD = 'fleet/serviceConfirmation/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/serviceConfirmation/FAIL_LOAD';
const FAIL_SAVE = 'fleet/serviceConfirmation/SAVE_FAIL';
const START_SAVE = 'fleet/serviceConfirmation/START_SAVE';
const COMPLETE_SAVE = 'fleet/serviceConfirmation/COMPLETE_SAVE';
const START_UPLOAD_DOCUMENT_IMAGE = 'fleet/serviceConfirmation/START_UPLOAD_DOCUMENT_IMAGE';
const COMPLETE_UPLOAD_DOCUMENT_IMAGE = 'fleet/serviceConfirmation/COMPLETE_UPLOAD_DOCUMENT_IMAGE';
const FAIL_UPLOAD_DOCUMENT_IMAGE = 'fleet/serviceConfirmation/FAIL_UPLOAD_DOCUMENT_IMAGE';

const START_EXPORT = 'fleet/serviceConfirmation/START_EXPORT';
const COMPLETE_EXPORT = 'fleet/serviceConfirmation/COMPLETE_EXPORT';
const FAIL_EXPORT = 'fleet/serviceConfirmation/FAIL_EXPORT';

//  Initial State
const initialState = {
  isLoading: false,
  isSaving: false,
  isUploadingDocumentImage: false,
  opportunities: undefined,
  reasonCodes: undefined,
  haulerName: undefined,
  errorMessage: undefined,
  isExporting: false,
  total: undefined,
};

//  Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunities: action.serviceConfirmation.opportunities,
          reasonCodes: action.serviceConfirmation.reasonCodes,
          haulerName: action.serviceConfirmation.haulerName,
          errorMessage: action.serviceConfirmation.errorMessage,
          total: action.serviceConfirmation.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunities: undefined,
          reasonCodes: undefined,
          haulerName: undefined,
          errorMessage: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          opportunities: action.serviceConfirmation.opportunities,
          reasonCodes: action.serviceConfirmation.reasonCodes,
          haulerName: action.serviceConfirmation.haulerName,
          errorMessage: action.serviceConfirmation.errorMessage,
          total: action.serviceConfirmation.total,
        },
      });

    case START_UPLOAD_DOCUMENT_IMAGE:
      return update(state, {
        $merge: {
          isUploadingDocumentImage: true,
        },
      });

    case COMPLETE_UPLOAD_DOCUMENT_IMAGE:
      return update(state, {
        $merge: {
          isUploadingDocumentImage: false,
        },
      });

    case FAIL_UPLOAD_DOCUMENT_IMAGE:
      return update(state, {
        $merge: {
          isUploadingDocumentImage: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceConfirmation: any) => ({
  type: COMPLETE_LOAD,
  serviceConfirmation,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (serviceConfirmation: any) => ({
  type: COMPLETE_SAVE,
  serviceConfirmation,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startUploadProfileImage = () => ({
  type: START_UPLOAD_DOCUMENT_IMAGE,
});

const completeUploadProfileImage = () => ({
  type: COMPLETE_UPLOAD_DOCUMENT_IMAGE,
});

const failUploadProfileImage = () => ({
  type: FAIL_UPLOAD_DOCUMENT_IMAGE,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const loadServiceConfirmation =
  (
    userId: string | undefined,
    token: string | undefined,
    vendorId: number | undefined,
    startDate: string,
    endDate: string,
    dispatchStatus: any,
    workOrder: any,
    workOrderType: any,
    page: number,
    limit: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    if (token) {
      const loadServiceConfirmationPromise = doloadServiceConfirmation(
        token,
        startDate,
        endDate,
        dispatchStatus,
        workOrder,
        workOrderType,
        page,
        limit,
      );
      loadServiceConfirmationPromise
        .then(serviceConfirmation => dispatch(completeLoad(serviceConfirmation)))
        .catch(() => dispatch(failLoad()));
      return loadServiceConfirmationPromise;
    } else if (userId && vendorId) {
      const loadServiceConfirmationByUserIdPromise = doloadServiceConfirmationByUserId(
        userId,
        vendorId,
        startDate,
        endDate,
        dispatchStatus,
        workOrder,
        workOrderType,
        page,
        limit,
      );
      loadServiceConfirmationByUserIdPromise
        .then(serviceConfirmation => dispatch(completeLoad(serviceConfirmation)))
        .catch(() => dispatch(failLoad()));
      return loadServiceConfirmationByUserIdPromise;
    }
  };

export const updateServiceConfirmation =
  (
    rubiconPONbr: any,
    status: any,
    note: any,
    reasonCode: any,
    date: Date | string,
    action: any,
    weightTicket: any,
    uom: any,
    userId: string,
    token: string,
    vendorId: number,
    vendId?: string,
    weightTicketUom?: string,
    searchFilter?: any,
    completedDate?: string | null,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    if (token) {
      const updateServiceConfirmationPromise = doupdateServiceConfirmation(
        dotransformUpdateOpenDispatches(
          rubiconPONbr,
          status,
          note,
          reasonCode,
          date,
          action,
          weightTicket,
          uom,
          token,
          vendId,
          weightTicketUom,
          searchFilter,
          completedDate,
        ),
      );
      updateServiceConfirmationPromise
        .then(opportunity => dispatch(completeSave(opportunity)))
        .catch(() => dispatch(failSave()));
      return updateServiceConfirmationPromise;
    } else if (userId) {
      const updateServiceConfirmationByUserIdPromise = doupdateServiceConfirmationByUserId(
        dotransformUpdateOpenDispatches(
          rubiconPONbr,
          status,
          note,
          reasonCode,
          date,
          action,
          weightTicket,
          uom,
          userId,
          vendId,
          weightTicketUom,
          searchFilter,
          completedDate,
        ),
        userId,
        vendorId,
      );
      updateServiceConfirmationByUserIdPromise
        .then(opportunity => dispatch(completeSave(opportunity)))
        .catch(() => dispatch(failSave()));
      return updateServiceConfirmationByUserIdPromise;
    }
  };

export const uploadDocumentImage =
  (file: any, token: string, workOrder: any, reportType: any, userId: string, vendorId: number, serviceDate: string) =>
  (dispatch: Dispatch) => {
    dispatch(startUploadProfileImage());
    if (token) {
      const uploadDServiceConfrirmationDocumentPromise = doUploadDocument(
        file,
        token,
        workOrder,
        reportType,
        serviceDate,
      );
      uploadDServiceConfrirmationDocumentPromise
        .then(response => {
          dispatch(completeUploadProfileImage());
          return response;
        })
        .catch(() => dispatch(failUploadProfileImage()));
      return uploadDServiceConfrirmationDocumentPromise;
    } else if (userId) {
      const uploadDServiceConfrirmationDocumentPromise = doUploadDocumentByUserId(
        file,
        userId,
        workOrder,
        reportType,
        userId,
        vendorId,
        serviceDate,
      );
      uploadDServiceConfrirmationDocumentPromise
        .then(response => {
          dispatch(completeUploadProfileImage());
          return response;
        })
        .catch(() => dispatch(failUploadProfileImage()));
      return uploadDServiceConfrirmationDocumentPromise;
    }
  };

export const downloadDocument = (fileId: number, fileName: string) => (dispatch: Dispatch) => {
  dispatch(startExport());
  return doDownloadDocument(fileId, fileName)
    .then(() => dispatch(completeExport()))
    .catch(() => dispatch(failExport()));
};
