import { FC, useEffect, useState, useMemo } from 'react';
import { isEqual } from 'lodash-es';
import mapboxgl from 'mapbox-gl';

import { CustomerProximityMapGLSource } from '.';
import { getMapBounds } from 'src/common/components/map/util';
import { MAP_CITY_ZOOM_IN, MAP_CITY_ZOOM_IN_BIGGER, MAP_CITY_ZOOM, MAP_CITY_ZOOM_SMALL } from 'src/core/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { MapGLWrapper } from 'src/customers/components/styled';
import { PinLocation } from 'src/customers/interfaces/Customers';
import { theme } from 'src/core/styles';

export const getMapZoom = (radius: number) =>
  radius <= 150
    ? MAP_CITY_ZOOM_IN_BIGGER
    : radius > 150 && radius < 500
    ? MAP_CITY_ZOOM_IN
    : radius >= 500 && radius < 1000
    ? MAP_CITY_ZOOM
    : MAP_CITY_ZOOM_SMALL;

interface Props {
  address?: PinLocation;
  customerLocationCleared: boolean;
  mapCenterByVendor?: PinLocation;
  radius: number;
}

const CustomerProximityMapGL: FC<Props> = ({ address, customerLocationCleared, mapCenterByVendor, radius }) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [viewport, setViewport] = useState<MapGLViewport>({});
  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);

  const handleSatelliteViewChange = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  const currentMarkers = useMemo(() => [] as any[], []);
  const pinLocation = address || mapCenterByVendor;

  useEffect(() => {
    if (map) {
      if (pinLocation && pinLocation.latitude && pinLocation.longitude) {
        const capZoom = getMapZoom(radius);
        const bounds = getMapBounds([{ latitude: pinLocation.latitude, longitude: pinLocation.longitude }], {
          capZoom,
        });

        if (address) {
          const marker = new mapboxgl.Marker({ color: theme.brandPrimary })
            .setLngLat([address?.longitude || 0, address?.latitude || 0])
            .addTo(map);
          currentMarkers.push(marker);
        }

        !isEqual(viewport, bounds) &&
          setViewport({
            ...viewport,
            ...bounds,
          });
      }

      if (customerLocationCleared && !address) {
        currentMarkers.forEach(marker => marker.remove());
      }
    }
  }, [address, currentMarkers, customerLocationCleared, map, mapCenterByVendor, pinLocation, viewport, radius]);

  return (
    <MapGLWrapper>
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={handleSatelliteViewChange}
      >
        {address && <CustomerProximityMapGLSource pinLocation={pinLocation} radius={radius} />}
      </MapGL>
    </MapGLWrapper>
  );
};

export default CustomerProximityMapGL;
