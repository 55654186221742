import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { debounce, get, findIndex } from 'lodash-es';
import { Field, reduxForm, getFormValues, reset, formValueSelector, change, InjectedFormProps } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';

import { AppState } from '../../../store';
import { BUSINESS } from '../../../common/constants';
import {
  Button,
  ButtonSet,
  DetailsListBodySection,
  DetailsListItemTitle,
  DetailsListItemDescription,
  DetailsListItemLink,
  Grid,
  GridColumn,
  PanelSection,
  PanelSectionGroup,
  ButtonLink,
} from '../../../core/components/styled';
import {
  CONTAINER_BLOCKED,
  CONTAINER_CONTAMINATED,
  CONTAINER_OVERFLOWING,
  DRIVER_MISSED,
  HOLIDAY,
  INTERNAL_TRUCK_PERSONNEL_ISSUE,
  INCORRECT_SERVICE_DAYS,
  NOT_SERVICE_PROVIDER,
  ROUTE_DELAY,
  SITE_CONTAINER_INACCESSIBLE,
  SERVICE_ON_SUSPENSION,
  SITE_UNSAFE,
  SERVICE_COMPLETED,
  WEATHER_EVENT,
} from 'src/fleet/constants/missedPickupReasonCodes';
import { createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { CUSTOMER_DETAILS_EDITOR_FORM } from 'src/customers/components/forms/CustomerDetailsEditorForm';
import { CustomerDetailsEditorModal, CustomerLocationsEditorModalResolver } from '../../../customers/components/modals';
import { customerDetailsEditorModalId } from 'src/customers/components/modals/CustomerDetailsEditorModal';
import {
  DatePicker,
  DaysOfWeekPicker,
  Dropdown,
  FileInput,
  TextArea,
  TypeAhead,
  TypedField,
} from '../../../core/components';
import { DuckFunction } from '../../../contracts/ducks';
import { FutureAccountStatus } from 'src/customers/interfaces/Services';
import { getEquipmentSize, getEquipmentSizeTranslationKey } from '../../../common/services/transformLoadServiceTypes';
import { getFutureAccountStatus } from 'src/customers/services/futureAccountStatusValidator';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import { saveCustomer } from '../../../customers/ducks';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { setNewServiceContractId } from '../../ducks/openDispatches';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import confirm from '../../../core/services/confirm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import searchCustomers from '../../services/searchCustomers';
import translate from '../../../core/services/translate';

interface ComponentProps extends RouteComponentProps {
  customerName?: string;
  customerAddress?: string;
  itemId?: number;
  pickupTypeId: number;
  requestedServiceDate?: string;
  reasonCodes: ReasonCodeGroups;
  serviceDescription?: string;
  daysInWeekSchedule?: number;
}

interface PropsWithoutReduxForm extends ComponentProps {
  change: any;
  formValues: any;
  hasGusServiceContract?: boolean;
  locations: any[];
  missedPickupReasonCodes: any[];
  reset: any;
  serviceContracts: any[];
  serviceContractCustomerName: any;
  serviceContractCustomerTypeId: any;
  serviceContractLocation: any;
  serviceContractAddress: any;
  serviceContractFullAddress: any;
  serviceContractType: any;
  serviceContractSize: any;
  serviceContractMaterialType: any;
  serviceContractDetails: any;
  vendorId?: number;
  onCancel(pristine: boolean): void;
  saveCustomer: DuckFunction<typeof saveCustomer>;
  setNewServiceContractId: DuckFunction<typeof setNewServiceContractId>;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  customerName?: string;
  fieldNotTouched: boolean;
  isCustomerDetailsModalOpen: boolean;
  isCustomerLocationsModalOpen: boolean;
  isServiceUpdated: boolean;
  locationName?: string;
  locationAddress?: string;
  newCustomerId?: number;
  newCustomerName?: string;
  newCustomerNameTemp?: string;
  newCustomerLocation?: string;
  newCustomerLocationName?: string;
  newCustomerLocationNameTemp?: string;
  newCustomerLocationAddress?: string;
  newCustomerLocationAddressTemp?: string;
  newCustomerServiceSizeName?: string;
  newCustomerServiceTypeName?: string;
  newCustomerServiceWasteName?: string;
  serviceName?: string;
  serviceMaterialType?: any;
  selectedCorrectCustomer: boolean;
}

class AcceptServiceForm extends Component<Props, State> {
  readonly state: State = {
    fieldNotTouched: false,
    isCustomerDetailsModalOpen: false,
    isCustomerLocationsModalOpen: false,
    isServiceUpdated: false,
    selectedCorrectCustomer: false,
  };

  onAccept = (event: any) => {
    event.preventDefault();
    const { handleSubmit } = this.props;
    (handleSubmit as any)();

    this.setState({ fieldNotTouched: false });
  };

  onSelectCorrectCustomer = () => {
    this.setState({ selectedCorrectCustomer: true });
  };

  onContinue = () => {
    const selectedCustomer = this.getFormValue('customer');
    const selectedCustomerLocation = this.getFormValue('location');
    const selectedCustomerService = this.getFormValue('serviceContract');
    const { setNewServiceContractId } = this.props;

    if (selectedCustomer && selectedCustomerLocation && selectedCustomerService) {
      setNewServiceContractId(selectedCustomerService.id);

      const size = getEquipmentSize(
        selectedCustomerService.equipmentSizeTechnicalName,
        selectedCustomerService.equipmentTypeName,
      );

      this.setState({
        customerName: selectedCustomer.name,
        locationName: selectedCustomerLocation.name,
        locationAddress: selectedCustomerLocation.address.line1,
        serviceName: `
          ${translate(
            createTranslationKey(selectedCustomerService.equipmentTypeTechnicalName, 'common.equipmentTypes'),
          )}
          - ${translate(
            getEquipmentSizeTranslationKey(
              selectedCustomerService.equipmentSizeTechnicalName,
              selectedCustomerService.serviceTypeName,
              selectedCustomerService.equipmentTypeName,
            ),
            { size },
          )}
        `,
        serviceMaterialType: translate(
          createTranslationKey(selectedCustomerService.wasteMaterialTypeTechnicalName, 'common.wasteTypes'),
        ),
        isServiceUpdated: true,
        fieldNotTouched: true,
        selectedCorrectCustomer: false,
      });

      createSuccessNotification(`${translate('routes.openDispatches.serviceUpdated')}`);
    }
  };

  onCreateNewCustomer = () => {
    this.setState({ isCustomerDetailsModalOpen: true });
  };

  onCreateNewLocation = () => {
    this.setState({ isCustomerLocationsModalOpen: true });
  };

  onCancelSaveCustomerDetails = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({ newCustomerName: undefined });
    this.closeCustomerDetailsEditorModal();
  };

  onCustomerEditorFormSubmit = async (data: any) => {
    const { saveCustomer, vendorId, reset } = this.props;

    const futureAccountStatus = get(data, 'futureAccountStatus', {} as FutureAccountStatus);
    const redirectToPage = false;
    const customer = {
      ...data,
      vendorId,
      futureAccountStatus: getFutureAccountStatus(futureAccountStatus),
    };

    scrollToTopOfModal(customerDetailsEditorModalId);

    await saveCustomer(customer, redirectToPage).then(data => {
      this.setState({
        newCustomerId: data.id,
        newCustomerName: data.name,
      });
    });

    createSuccessNotification(`${translate('customers.alertMessages.customerSaved')}`);
    reset(CUSTOMER_DETAILS_EDITOR_FORM);

    this.setState({
      isCustomerDetailsModalOpen: false,
      isCustomerLocationsModalOpen: true,
    });
  };

  onLocationEditorFormSubmit = (data: any) => {
    const selectedItem = data.length - 1;

    this.setState({
      isCustomerLocationsModalOpen: false,
      newCustomerLocation: data[selectedItem],
      newCustomerLocationName: data[selectedItem].name,
      newCustomerLocationAddress: data[selectedItem].address.line1,
    });
  };

  onCancelSaveCustomerLocation = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({
      isCustomerLocationsModalOpen: false,
      newCustomerName: undefined,
    });
  };

  onServiceEditorFormSubmit = (data: any[]) => {
    const selectedServiceId = Math.max(...data.map(item => item.id));
    const selectedItem = findIndex(data, { id: selectedServiceId });

    const { setNewServiceContractId } = this.props;
    const {
      newCustomerLocationName,
      newCustomerLocationAddress,
      newCustomerName,
      newCustomerLocationNameTemp,
      newCustomerLocationAddressTemp,
      newCustomerNameTemp,
    } = this.state;
    const size = getEquipmentSize(data[selectedItem].equipmentSizeTechnicalName, data[selectedItem].equipmentTypeName);

    setNewServiceContractId(data[selectedItem].id);

    this.setState({
      isServiceUpdated: true,
      fieldNotTouched: true,
      selectedCorrectCustomer: false,
      newCustomerServiceTypeName: translate(
        createTranslationKey(data[selectedItem].equipmentTypeTechnicalName, 'common.equipmentTypes'),
      ),
      newCustomerServiceSizeName: translate(
        getEquipmentSizeTranslationKey(
          data[selectedItem].equipmentSizeTechnicalName,
          data[selectedItem].serviceTypeName,
          data[selectedItem].equipmentTypeName,
        ),
        { size },
      ),
      newCustomerServiceWasteName: translate(
        createTranslationKey(data[selectedItem].wasteMaterialType.technicalName, 'common.wasteTypes'),
      ),
      newCustomerLocationName: newCustomerLocationName || newCustomerLocationNameTemp,
      newCustomerLocationAddress: newCustomerLocationAddress || newCustomerLocationAddressTemp,
      newCustomerName: newCustomerName || newCustomerNameTemp,
    });
  };

  onCancelSaveService = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({
      newCustomerName: undefined,
      newCustomerLocation: undefined,
      newCustomerLocationName: undefined,
      newCustomerLocationAddress: undefined,
    });
  };

  onCustomerChange = (event: any) => {
    const { change } = this.props;
    change('acceptServiceForm', 'location', undefined);
    change('acceptServiceForm', 'serviceContract', undefined);

    this.setState({
      newCustomerId: event && event.id,
      newCustomerNameTemp: event && event.name,
    });
  };

  onLocationChange = (event: any) => {
    this.setState({
      newCustomerLocation: event,
      newCustomerLocationNameTemp: event && event.name,
      newCustomerLocationAddressTemp: event && event.address ? event.address.line1 : undefined,
    });
  };

  onReasonChange = (event: any) => {
    const { change } = this.props;
    if (event === INCORRECT_SERVICE_DAYS || event === NOT_SERVICE_PROVIDER) {
      change('newServiceDate', undefined);
    }
  };

  getFormValue = (name: string) => get(this.props.formValues, name);

  closeCustomerDetailsEditorModal = () => {
    this.setState({ isCustomerDetailsModalOpen: false });
  };

  loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    const { vendorId } = this.props;

    if (!vendorId) {
      return;
    }

    searchCustomers(vendorId, searchTerm).then(onOptionsLoaded);
  }, 500);

  render() {
    const {
      handleSubmit,
      onCancel,
      itemId,
      pristine,
      pickupTypeId,
      requestedServiceDate,
      reasonCodes,
      hasGusServiceContract,
      locations,
      missedPickupReasonCodes,
      serviceContracts,
      serviceContractCustomerName,
      serviceContractCustomerTypeId,
      serviceContractLocation,
      serviceContractAddress,
      serviceContractFullAddress,
      serviceContractType,
      serviceContractSize,
      serviceContractMaterialType,
    } = this.props;

    const {
      customerName,
      fieldNotTouched,
      isCustomerDetailsModalOpen,
      isCustomerLocationsModalOpen,
      isServiceUpdated,
      locationName,
      locationAddress,
      newCustomerId,
      newCustomerName,
      newCustomerLocationName,
      newCustomerLocationAddress,
      newCustomerServiceSizeName,
      newCustomerServiceTypeName,
      newCustomerServiceWasteName,
      serviceName,
      serviceMaterialType,
      selectedCorrectCustomer,
    } = this.state;

    const newServiceName =
      newCustomerServiceSizeName && newCustomerServiceTypeName
        ? `${newCustomerServiceTypeName} - ${newCustomerServiceSizeName}`
        : undefined;

    const locationOptions = locations.map(location => ({
      label: `${location.name} / ${location.address.line1}`,
      value: location,
    }));

    const serviceContractsOptions = serviceContracts.map(service => {
      const size = getEquipmentSize(service.equipmentSizeTechnicalName, service.equipmentTypeName);

      return {
        label: `
          ${translate(createTranslationKey(service.equipmentTypeTechnicalName, 'common.equipmentTypes'))}
          - ${translate(
            getEquipmentSizeTranslationKey(
              service.equipmentSizeTechnicalName,
              service.serviceTypeName,
              service.equipmentTypeName,
            ),
            { size },
          )}
          - ${translate(createTranslationKey(service.wasteMaterialTypeTechnicalName, 'common.wasteTypes'))}
        `,
        value: service,
      };
    });

    const missedPickupReasonOptions = missedPickupReasonCodes.map(reasonCode => ({
      label: reasonCode.description,
      value: reasonCode.code,
    }));

    const hasCustomer = this.getFormValue('customer') != null;
    const hasCustomerLocation = this.getFormValue('location') != null;
    const reasonCodesOptions =
      pickupTypeId === MISSED_PICKUP && itemId ? missedPickupReasonOptions : reasonCodes.acceptRecovery;

    const reasonCode = this.getFormValue('reasonCode');
    const isNewServiceHidden = reasonCode === INCORRECT_SERVICE_DAYS || reasonCode === NOT_SERVICE_PROVIDER;
    const isDaysOfWeekVisible = reasonCode === INCORRECT_SERVICE_DAYS;

    const dateValidation =
      reasonCode === SERVICE_ON_SUSPENSION ? [isDateValidValidator] : [isRequired, isDateValidValidator];
    let disableDays: any;
    if (
      reasonCode === CONTAINER_BLOCKED ||
      reasonCode === SITE_CONTAINER_INACCESSIBLE ||
      reasonCode === SITE_UNSAFE ||
      reasonCode === SERVICE_COMPLETED ||
      reasonCode === CONTAINER_OVERFLOWING ||
      reasonCode === CONTAINER_CONTAMINATED
    ) {
      disableDays = {
        before: moment().toDate(),
        after: moment().add(7, 'days').toDate(),
      };
    } else if (
      reasonCode === INTERNAL_TRUCK_PERSONNEL_ISSUE ||
      reasonCode === DRIVER_MISSED ||
      reasonCode === ROUTE_DELAY
    ) {
      disableDays = {
        before: moment().toDate(),
        after: moment().add(48, 'hours').toDate(),
      };
    } else if (
      reasonCode === WEATHER_EVENT ||
      reasonCode === NOT_SERVICE_PROVIDER ||
      reasonCode === HOLIDAY ||
      reasonCode === SERVICE_ON_SUSPENSION
    ) {
      disableDays = {
        before: moment().toDate(),
        after: moment().add(5, 'days').toDate(),
      };
    } else {
      disableDays = {
        before: moment().toDate(),
        after: moment().add(2, 'days').toDate(),
      };
    }
    //const mustValidator = mustLength(daysInWeekSchedule || 0);

    const selectedCustomer = this.getFormValue('customer');
    const selectedCustomerLocation = this.getFormValue('location');
    return (
      <PanelSectionGroup>
        <form onSubmit={handleSubmit} noValidate>
          <PanelSection padding="small xSmall no">
            <UseIsMobileWidthView
              render={isMobile => (
                <Grid multiLine>
                  {pickupTypeId === MISSED_PICKUP && (
                    <Fragment>
                      <GridColumn
                        size={isMobile ? '12/12' : '4/12'}
                        padding={isMobile ? 'small xxSmall no' : 'no xSmall xSmall'}
                      >
                        <DetailsListItemTitle margin="no no xxSmall">
                          {translate('autoDispatch.originalServiceDate')}
                        </DetailsListItemTitle>
                      </GridColumn>
                      <GridColumn size="8/12" padding="no xSmall xSmall">
                        <DetailsListItemDescription>{requestedServiceDate}</DetailsListItemDescription>
                      </GridColumn>
                    </Fragment>
                  )}
                  <GridColumn
                    size={isMobile ? '12/12' : '4/12'}
                    padding={isMobile ? 'small xxSmall no' : 'small xSmall xSmall'}
                  >
                    <DetailsListItemTitle margin="no no xxSmall">
                      {translate('routes.openDispatches.customerName')}
                    </DetailsListItemTitle>
                  </GridColumn>
                  <GridColumn
                    size={isMobile ? '12/12' : '8/12'}
                    padding={isMobile ? 'no xxSmall' : 'small xSmall xSmall'}
                    margin={isMobile ? 'no no small' : undefined}
                  >
                    <DetailsListItemDescription>
                      {newCustomerName || customerName || serviceContractCustomerName}
                    </DetailsListItemDescription>
                  </GridColumn>
                  <GridColumn size={isMobile ? '12/12' : '4/12'} padding={isMobile ? 'xxSmall' : 'small xSmall xSmall'}>
                    <DetailsListItemTitle margin="no no xxSmall">
                      {translate('routes.openDispatches.locationDetails')}
                    </DetailsListItemTitle>
                  </GridColumn>
                  <GridColumn
                    size={isMobile ? '12/12' : '8/12'}
                    padding={isMobile ? 'no xxSmall' : 'small xSmall xSmall'}
                    margin={isMobile ? 'no no small' : undefined}
                  >
                    <DetailsListBodySection>
                      <DetailsListItemDescription>
                        {newCustomerLocationName || locationName || serviceContractLocation}
                      </DetailsListItemDescription>
                      <DetailsListItemDescription>
                        {newCustomerLocationAddress || locationAddress || serviceContractAddress}
                      </DetailsListItemDescription>
                    </DetailsListBodySection>
                  </GridColumn>
                  <GridColumn size={isMobile ? '12/12' : '4/12'} padding={isMobile ? 'xxSmall' : 'small xSmall xSmall'}>
                    <DetailsListItemTitle margin="no no xxSmall">
                      {translate('routes.openDispatches.serviceDetails')}
                    </DetailsListItemTitle>
                  </GridColumn>
                  <GridColumn
                    size={isMobile ? '12/12' : '8/12'}
                    padding={isMobile ? 'no xxSmall' : 'small xSmall xSmall'}
                    margin={isMobile ? 'no no small' : undefined}
                  >
                    <DetailsListBodySection>
                      <DetailsListItemDescription>
                        {newServiceName ||
                          serviceName ||
                          `${serviceContractType}${serviceContractSize ? ` - ${serviceContractSize}` : ''}`}
                      </DetailsListItemDescription>
                      <DetailsListItemDescription>
                        {newCustomerServiceWasteName || serviceMaterialType || serviceContractMaterialType}
                      </DetailsListItemDescription>
                    </DetailsListBodySection>
                  </GridColumn>
                  {hasGusServiceContract && !selectedCorrectCustomer && !isServiceUpdated && (
                    <GridColumn size="12/12" padding="small xSmall xSmall">
                      <DetailsListBodySection>
                        <DetailsListItemDescription size="small">
                          {translate('routes.openDispatches.clickHereMessagePart1')}{' '}
                          <DetailsListItemLink tabIndex={0} role="button" onClick={this.onSelectCorrectCustomer}>
                            <u>{translate('routes.openDispatches.clickHereMessagePart2')}</u>
                          </DetailsListItemLink>{' '}
                          {translate('routes.openDispatches.clickHereMessagePart3')}.
                        </DetailsListItemDescription>
                      </DetailsListBodySection>
                    </GridColumn>
                  )}
                  {pickupTypeId === MISSED_PICKUP &&
                    (hasGusServiceContract || isServiceUpdated) &&
                    !selectedCorrectCustomer && (
                      <Fragment>
                        <GridColumn size="12/12" padding="xSmall">
                          <TypedField
                            name={`reasonCode`}
                            component={Dropdown}
                            validate={[isRequired]}
                            onChange={this.onReasonChange}
                            props={{
                              label: translate('opportunity.selectReasonType'),
                              options: reasonCodesOptions,
                            }}
                          />
                        </GridColumn>
                        {!isNewServiceHidden && (
                          <GridColumn size="12/12" padding="xSmall">
                            <TypedField
                              name={`newServiceDate`}
                              component={DatePicker}
                              validate={dateValidation}
                              props={{
                                raisedLabel: true,
                                label: translate('routes.holidayPlanner.newServicDate'),
                                disabledDays: [disableDays],
                                isTodayWithNoStyle: true,
                              }}
                            />
                          </GridColumn>
                        )}
                        {isDaysOfWeekVisible && (
                          <TypedField
                            name={'dayOfWeek'}
                            component={DaysOfWeekPicker}
                            validate={[isRequired]}
                            props={{
                              label: translate('common.dayOfWeek'),
                              daysOfWeekProps: { margin: 'xSmall' },
                              dayOfWeekProps: { margin: 'xSmall' },
                              margin: 'no small no',
                              multiple: true,
                            }}
                          />
                        )}
                        <GridColumn size="12/12" padding="xSmall">
                          <Field
                            name="file"
                            component={FileInput}
                            accept={'.doc,.csv,.jpg,.png,.pdf,.xls,.xlsx'}
                            type="text"
                            margin="sMedium no sMedium no"
                          />
                        </GridColumn>
                      </Fragment>
                    )}
                  {(hasGusServiceContract || isServiceUpdated) && !selectedCorrectCustomer && (
                    <GridColumn size="12/12" padding="xSmall">
                      <Field
                        name="notes"
                        component={TextArea}
                        rows={2}
                        label={translate('routes.openDispatches.addNote')}
                        fieldNotTouched={fieldNotTouched}
                      />
                    </GridColumn>
                  )}
                  {((!hasGusServiceContract && !isServiceUpdated) || selectedCorrectCustomer) && (
                    <GridColumn size="12/12" padding="medium xSmall xSmall">
                      <DetailsListBodySection>
                        <DetailsListItemDescription width="100%" align="center">
                          {translate('routes.openDispatches.selectCorrespondingCustomerMessage')}
                        </DetailsListItemDescription>
                        <DetailsListItemDescription width="100%" align="center">
                          {!hasCustomer ? (
                            <Button
                              type="button"
                              color="primary"
                              margin="small no medium no"
                              onClick={this.onCreateNewCustomer}
                            >
                              {translate('routes.openDispatches.createNewsCustomer')}
                            </Button>
                          ) : (
                            <>
                              {!hasCustomerLocation ? (
                                <Button
                                  type="button"
                                  color="primary"
                                  margin="small no medium no"
                                  onClick={this.onCreateNewLocation}
                                >
                                  {translate('routes.openDispatches.createNewLocation')}
                                </Button>
                              ) : (
                                <ButtonLink
                                  id="add-service-button-link"
                                  color="primary"
                                  to={`/customers/customers/${selectedCustomer.id}/location/${selectedCustomerLocation.id}/service/-1`}
                                >
                                  {translate('routes.openDispatches.createNewService')}
                                </ButtonLink>
                              )}
                            </>
                          )}
                        </DetailsListItemDescription>
                      </DetailsListBodySection>
                      <Field
                        name="customer"
                        component={TypeAhead}
                        placeholder={`${translate('common.customer')}`}
                        getOptions={this.loadCustomerLocations}
                        isClearable
                        margin="small no no"
                        validate={[isRequired]}
                        onChange={this.onCustomerChange}
                      />
                      <Field
                        name="location"
                        placeholder={translate('common.location')}
                        isClearable
                        component={Dropdown}
                        margin="small no no"
                        options={locationOptions}
                        validate={[isRequired]}
                        disabled={!hasCustomer}
                        onChange={this.onLocationChange}
                      />
                      <Field
                        name="serviceContract"
                        placeholder={translate('common.service')}
                        isClearable
                        component={Dropdown}
                        margin="small no no"
                        options={serviceContractsOptions}
                        validate={[isRequired]}
                        disabled={!hasCustomerLocation}
                      />
                    </GridColumn>
                  )}
                </Grid>
              )}
            />
          </PanelSection>

          <ButtonSet margin="large auto no">
            {(hasGusServiceContract || isServiceUpdated) && !selectedCorrectCustomer ? (
              <Button type="submit" color="primary" onClick={() => this.onAccept}>
                {translate('common.accept')}
              </Button>
            ) : (
              <Button type="button" color="primary" onClick={this.onContinue}>
                {translate('common.continue')}
              </Button>
            )}
            <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
              {translate('common.cancel')}
            </Button>
          </ButtonSet>
        </form>

        {!!isCustomerDetailsModalOpen && (
          <CustomerDetailsEditorModal
            onCancel={this.onCancelSaveCustomerDetails}
            onSaveCustomer={this.onCustomerEditorFormSubmit}
            allowedCustomerTypeId={BUSINESS}
            serviceContractCustomerName={serviceContractCustomerName}
            serviceContractCustomerTypeId={
              serviceContractCustomerTypeId === BUSINESS ? serviceContractCustomerTypeId : undefined
            }
          />
        )}

        {!!isCustomerLocationsModalOpen && (
          <CustomerLocationsEditorModalResolver
            onCancel={this.onCancelSaveCustomerLocation}
            newCustomerId={newCustomerId}
            onSaveLocation={this.onLocationEditorFormSubmit}
            serviceContractLocation={serviceContractLocation}
            serviceContractFullAddress={serviceContractFullAddress}
          />
        )}
      </PanelSectionGroup>
    );
  }
}

const formSelector = formValueSelector('acceptServiceForm');

const mapStateToProps = (state: AppState, props: ComponentProps) => {
  const { customer, location } = formSelector(state, 'customer', 'location');
  const locations = customer ? customer.locations : [];
  const serviceContracts = location ? location.serviceContracts : [];
  const serviceContractByCode = state.fleet.openDispatches.serviceContract as any;
  const size = serviceContractByCode
    ? getEquipmentSize(
        serviceContractByCode.equipmentSize.technicalName,
        serviceContractByCode.equipmentSize.equipmentTypeName,
      )
    : null;

  return {
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    formValues: getFormValues('acceptServiceForm')(state),
    locations,
    missedPickupReasonCodes: state.fleet.rubiconDispatches.missedPickupReasonCodes,
    serviceContracts,
    serviceContractCustomerName: serviceContractByCode ? serviceContractByCode.customer.name : props.customerName,
    serviceContractCustomerTypeId: serviceContractByCode ? serviceContractByCode.customerTypeId : undefined,
    serviceContractLocation: serviceContractByCode ? serviceContractByCode.location.name : props.customerAddress,
    serviceContractAddress: serviceContractByCode ? serviceContractByCode.locationAddress.line1 : undefined,
    serviceContractFullAddress: serviceContractByCode ? serviceContractByCode.locationAddress : undefined,
    serviceContractType: serviceContractByCode
      ? translate(createTranslationKey(serviceContractByCode.equipmentType.technicalName, 'common.equipmentTypes'))
      : props.serviceDescription,
    serviceContractSize: serviceContractByCode
      ? translate(
          getEquipmentSizeTranslationKey(
            serviceContractByCode.equipmentSize.technicalName,
            serviceContractByCode.serviceType.name,
            serviceContractByCode.equipmentType.name,
          ),
          { size },
        )
      : undefined,
    serviceContractMaterialType: serviceContractByCode
      ? translate(createTranslationKey(serviceContractByCode.wasteMaterialType.technicalName, 'common.wasteTypes'))
      : undefined,
    serviceContractDetails: {
      equipmentSizeId: serviceContractByCode ? serviceContractByCode.equipmentSize.id : undefined,
      equipmentTypeId: serviceContractByCode ? serviceContractByCode.equipmentType.id : undefined,
      serviceTypeId: serviceContractByCode ? serviceContractByCode.serviceType.id : undefined,
      wasteMaterialTypeId: serviceContractByCode ? serviceContractByCode.wasteMaterialType.id : undefined,
    },
  };
};

const mapDispatchToProps = {
  reset,
  saveCustomer,
  change,
  setNewServiceContractId,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'acceptServiceForm',
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
    })(AcceptServiceForm),
  ),
);
