import { useCallback, useEffect, useRef } from 'react';

const useDataPolling = (
  fn: () => Promise<unknown> | void,
  ms: number,
  retries = 1,
  endCallback = () => console.warn('Polling ended.'),
) => {
  const timeout = useRef<number>();
  const countRef = useRef(0);

  const run = useCallback(
    (doNotCallFirstTime?: boolean) => {
      !doNotCallFirstTime && fn();
      countRef.current -= 1;
      if (countRef.current > 0) {
        timeout.current = window.setTimeout(() => run(), ms);
      } else {
        endCallback();
      }
    },
    [fn, ms], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    countRef.current = retries;
    run(true);
    return () => {
      countRef.current = 0;
      window.clearTimeout(timeout.current);
    };
  }, [run]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDataPolling;
