import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { Text } from '../../../core/components/styled';
import { date, time } from '../../../utils/services/formatter';

type Props = WrappedFieldProps;

const RubiconDispatchesNotesDateTimeDisplay: React.FC<Props> = ({ input: { value } }) =>
  value && (
    <>
      <Text block margin="no no xxSmall">
        {date(value)}
      </Text>

      <Text block size="small">
        {time(value)} EST
      </Text>
    </>
  );

export default RubiconDispatchesNotesDateTimeDisplay;
