import { AnyAction } from 'redux';
import update from 'immutability-helper';
import { orderBy, size } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';

import {
  loadRubiconDispatches as doLoadRubiconDispatches,
  loadRubiconDispatchesByToken as doLoadRubiconDispatchesByToken,
  loadMPUReasonCodes as doLoadMPUReasonCodes,
} from '../services/rubiconDispatches';
import { NA } from '../constants/rubiconDisptachesStatus';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';

// Actions
const START_LOAD = 'fleet/rubiconDispatches/START_LOAD';
const COMPLETE_LOAD = 'fleet/rubiconDispatches/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/rubiconDispatches/FAIL_LOAD';
const START_LOAD_REASON_CODES = 'fleet/rubiconDispatches/START_LOAD_REASON_CODES';
const COMPLETE_LOAD_REASON_CODES = 'fleet/rubiconDispatches/COMPLETE_LOAD_REASON_CODES';
const FAIL_LOAD_REASON_CODES = 'fleet/rubiconDispatches/FAIL_LOAD_REASON_CODES';
const RESET = 'fleet/rubiconDispatches/RESET';

interface RubiconDispatchesState {
  isLoading: boolean;
  isLoadingReasonCodes: boolean;
  rubiconDispatches: any[];
  divisions: any[];
  equipmentOptions: any[];
  materialTypes: any[];
  missedPickupReasonCodes: any[];
  smartFilterOptions: any[];
  reasonCodes: ReasonCodeGroups;
  totalWorkOrders: number;
}

const initialState: RubiconDispatchesState = {
  isLoading: false,
  isLoadingReasonCodes: false,
  rubiconDispatches: [],
  divisions: [],
  equipmentOptions: [],
  materialTypes: [],
  missedPickupReasonCodes: [],
  reasonCodes: {} as ReasonCodeGroups,
  smartFilterOptions: [],
  totalWorkOrders: 0,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rubiconDispatches:
            size(action.rubiconDispatches.rubiconDispatches) > 0
              ? action.rubiconDispatches.rubiconDispatches.filter((o: any) => o.statusId !== NA)
              : action.rubiconDispatches.rubiconDispatches,
          divisions: action.rubiconDispatches.divisions,
          equipmentOptions: orderBy(action.rubiconDispatches.equipmentSubTypes),
          materialTypes: orderBy(action.rubiconDispatches.materialTypes),
          reasonCodes: action.rubiconDispatches.groupedReasonCodes,
          smartFilterOptions: action.rubiconDispatches.smartFilterOptions,
          totalWorkOrders: action.isActionRequired
            ? size(action.rubiconDispatches.rubiconDispatches.filter((o: any) => o.statusId !== NA))
            : 0,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rubiconDispatches: [],
        },
      });

    case START_LOAD_REASON_CODES:
      return update(state, {
        $merge: {
          isLoadingReasonCodes: true,
        },
      });

    case COMPLETE_LOAD_REASON_CODES:
      return update(state, {
        $merge: {
          isLoadingReasonCodes: false,
          missedPickupReasonCodes: action.reasonCodes,
        },
      });

    case FAIL_LOAD_REASON_CODES:
      return update(state, {
        $merge: {
          isLoadingReasonCodes: false,
          missedPickupReasonCodes: [],
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rubiconDispatches: any, isActionRequired: boolean) => ({
  type: COMPLETE_LOAD,
  rubiconDispatches,
  isActionRequired,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadReasonCodes = () => ({
  type: START_LOAD_REASON_CODES,
});

const completeLoadReasonCodes = (reasonCodes: any) => ({
  type: COMPLETE_LOAD_REASON_CODES,
  reasonCodes,
});

const failLoadReasonCodes = () => ({
  type: FAIL_LOAD_REASON_CODES,
});

export const loadRubiconDispatches =
  (userId: string, token: string, vendorId: number, startDate: string, endDate: string, isActionRequired: boolean) =>
  (dispatch: ThunkDispatch<RubiconDispatchesState, any, AnyAction>) => {
    dispatch(startLoad());
    const fromDate = isActionRequired ? undefined : startDate;
    const toDate = isActionRequired ? undefined : endDate;
    if (token) {
      const promise = doLoadRubiconDispatchesByToken(isActionRequired, token, fromDate, toDate);

      promise
        .then(rubiconDispatches => {
          dispatch(completeLoad(rubiconDispatches, isActionRequired));
        })
        .catch(() => {
          dispatch(failLoad());
        });

      return promise;
    }

    const promise = doLoadRubiconDispatches(isActionRequired, userId, vendorId, fromDate, toDate);

    promise
      .then(rubiconDispatches => {
        dispatch(completeLoad(rubiconDispatches, isActionRequired));
      })
      .catch(() => {
        dispatch(failLoad());
      });

    return promise;
  };
export const loadMPUReasonCodes = () => (dispatch: ThunkDispatch<RubiconDispatchesState, any, AnyAction>) => {
  dispatch(startLoadReasonCodes());
  const promise = doLoadMPUReasonCodes();
  promise
    .then(reasonCodes => {
      dispatch(completeLoadReasonCodes(reasonCodes));
    })
    .catch(() => {
      dispatch(failLoadReasonCodes());
    });

  return promise;
};
export const resetRubiconDispatches = () => ({
  type: RESET,
});
