import React, { PureComponent } from 'react';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { push, Push } from 'connected-react-router';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';

import { createUrl } from '../../../utils/services/queryParams';
import { DATE_RANGE_PICKER_TODAY_3_7 } from '../../../core/constants';
import { DateRangePicker, Input, Dropdown, TypedField } from '../../../core/components';
import { Grid, GridColumn, PanelSection, Button } from '../../../core/components/styled';
import { isRequired } from '../../../utils/services/validator';
import { OPEN_DISPATCH_STATUS, WORKORDER_TYPE } from '../../constants';
import { Option } from 'src/common/interfaces/Option';
import OpenDispatchesSearchFilterFormInitialValuesSelector from '../../services/openDispatchesSearchFilterFormInitialValuesSelector';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm extends RouteComponentProps {
  filterStatus?: string[];
  push: Push;
  workOrderType?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

type State = {
  dispatchStatusOptions: Option[];
  workOrderType: Option[];
};

class OpenDispatchesSearchFilterForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const allowed = props.filterStatus;

    const filtered = Object.keys(OPEN_DISPATCH_STATUS)
      .filter(key => allowed?.includes(key))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: OPEN_DISPATCH_STATUS[key],
        }),
        {},
      );

    this.state = {
      dispatchStatusOptions: map(filtered, ({ name, technicalName }) => ({
        label: name,
        value: technicalName,
      })),
      workOrderType: map(WORKORDER_TYPE, ({ name, technicalName }) => ({
        label: name,
        value: technicalName,
      })),
    };
  }

  onWorkOrderChange = (_: unknown, workOrder: string) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { workOrder, page: undefined }));
  };

  onDateChange = (_: unknown, { from, to }: { from: string; to: string }) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { startDate: from, endDate: to, page: undefined }));
  };

  onDispatchStatusChange = (_: unknown, dispatchStatusId: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, { dispatchStatus: dispatchStatusId && dispatchStatusId.toString(), page: undefined }),
    );
  };

  onWorkOrderTypeChange = (_: unknown, workOrderTypeId: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;

    push(
      createUrl(pathname, search, { workOrderType: workOrderTypeId && workOrderTypeId.toString(), page: undefined }),
    );
  };

  clearFilter = () => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { workOrder: null, page: undefined }));
  };

  render() {
    const { workOrderType } = this.props;
    return (
      <form>
        <PanelSection>
          <Grid margin="small no no no">
            <GridColumn size="3/12">
              <TypedField
                name="dispatchStatus"
                component={Dropdown}
                onChange={this.onDispatchStatusChange}
                props={{
                  options: this.state.dispatchStatusOptions,
                  label: translate('common.status'),
                  margin: 'no',
                }}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="date"
                component={DateRangePicker}
                options={DATE_RANGE_PICKER_TODAY_3_7}
                onChange={this.onDateChange}
                label={translate('opportunity.scheduledDate')}
                disabledDays={[
                  {
                    after: moment().add(30, 'days').toDate(),
                  },
                ]}
                maxInterval={{ amount: 30, unit: 'days' }}
                margin="no"
              />
            </GridColumn>
            {workOrderType && (
              <GridColumn size="2/12">
                <TypedField
                  name="workOrderType"
                  component={Dropdown}
                  onChange={this.onWorkOrderTypeChange}
                  props={{
                    options: this.state.workOrderType,
                    label: translate('autoDispatch.workOrderType'),
                    margin: 'no',
                  }}
                />
              </GridColumn>
            )}
            <GridColumn size="2/12">
              <Field
                name="workOrder"
                component={Input}
                label={translate('autoDispatch.workOrder')}
                type="text"
                validate={[isRequired]}
                margin="no"
                onChange={this.onWorkOrderChange}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Button
                color="primary"
                background-color="transparent"
                size="medium"
                onClick={this.clearFilter}
                line
                margin="small"
              >
                {translate('autoDispatch.clearFilter')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (_: unknown, ownProps: RouteComponentProps) => ({
  initialValues: OpenDispatchesSearchFilterFormInitialValuesSelector(ownProps.location.search),
});

const mapDispatchToProps = { push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<{}, PropsWithoutReduxForm>({
      form: 'openDispatchesSearchFilter',
      enableReinitialize: true,
    })(OpenDispatchesSearchFilterForm),
  ),
);
