import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';

import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
// import pricingInfoIntialValueSelector from '../../services/pricingInfoIntialValueSelector';
import translate from '../../../core/services/translate';
import { AcceptPaymentForSelectedRecurringServiceForm } from '../forms';
import { RECURRING_SERVICE, LIMIT_PER_PAGE } from '../../constants';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface ComponentProps {
  pricingInfo: any;
  vendorId: number;
  updateAcceptSelectedRecurringPaymentServices(...args: any[]): any;
  onCancel(...args: any[]): any;
}

interface Props extends ComponentProps, RouteComponentProps<RouteParams> {
  workOrdersInfo: any;
  pricingInfo: any;
  selectedPaymentInfo: any;
  paymentInfo: any;
  isSaving: boolean;
}

class AcceptPaymentForSelectedRecurringModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      vendorId,
      selectedPaymentInfo,
      pricingInfo,
      location,
      onCancel,
      match: {
        params: { token },
      },
    } = this.props;
    const { page } = getQueryParams(location.search);
    const requestRecurringServicesObj: any = {};

    requestRecurringServicesObj.serviceType = RECURRING_SERVICE;
    requestRecurringServicesObj.date = moment(pricingInfo.reportDate).format('YYYY-MM-DD');
    requestRecurringServicesObj.paymentMethod = selectedPaymentInfo.paymentMethods;
    requestRecurringServicesObj.paymentId = selectedPaymentInfo.paymentId;
    requestRecurringServicesObj.rubiconInvoiceNo = selectedPaymentInfo.rubiconInvoiceNo;

    await this.props
      .updateAcceptSelectedRecurringPaymentServices(requestRecurringServicesObj, vendorId, token, page, LIMIT_PER_PAGE)
      .then(() => {
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  };

  stopPropagation = (event: any) => {
    event.preventDefault();
  };

  render() {
    const { isSaving, onCancel } = this.props;

    return (
      <Modal size="small" isLoading={isSaving}>
        <ModalTitle>{translate('payment.acceptPayment')}</ModalTitle>
        <ModalSubtitle />
        <Grid centered>
          <GridColumn size="8/12">
            <AcceptPaymentForSelectedRecurringServiceForm
              {...this.props}
              onSubmit={this.onSubmit}
              onCancel={onCancel}
            />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('acceptPaymentForSelectedRecurringService');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  workOrdersInfo: state.payments.recurringServices.workOrdersInfo,
  pricingInfo: state.payments.recurringServices.pricingInfo,
  selectedPaymentInfo: formSelector(state, `pricingInfo.settings${ownProps.pricingInfo.rubiconInvoiceNo}`),
  paymentInfo: state.payments.recurringServices.paymentInfo,
  isSaving: state.payments.recurringServices.isSaving,
});

export default connect(mapStateToProps, undefined)(AcceptPaymentForSelectedRecurringModal);
