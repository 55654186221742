import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

interface Props {
  linesGeoJson: any;
  arrowsGeoJson: any;
  uniquePrefix?: string;
}

export const TRAVEL_PATH_SOURCE = 'travelPathSource';
export const TRAVEL_PATH_ARROWS_SOURCE = 'travelPathArrowsSource';
export const TRAVEL_PATH_LINES_LAYER = 'travelPathLinesLayer';
export const TRAVEL_PATH_DASHED_LINES_LAYER = 'travelPathDashedLinesLayer';
export const TRAVEL_PATH_ARROWS_LAYER = 'travelPathArrowsLayer';
export const TRAVEL_PATH_SEQUENCE_LAYER = 'travelPathSequenceLayer';

const getId = (uniquePrefix: string, id: string) => `${uniquePrefix}${id}`;

const TravelPathGLSource: FC<Props> = ({ linesGeoJson, arrowsGeoJson, uniquePrefix = '' }) => {
  return (
    <>
      <Source type="geojson" data={linesGeoJson} id={getId(uniquePrefix, TRAVEL_PATH_SOURCE)}>
        {/* solid lines */}
        <Layer
          id={getId(uniquePrefix, TRAVEL_PATH_LINES_LAYER)}
          type="line"
          source={getId(uniquePrefix, TRAVEL_PATH_SOURCE)}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          filter={['!=', 'isDashed', true]}
          paint={{
            'line-color': ['get', 'color'],
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 5],
            'line-translate': [0, 0],
            'line-opacity': ['get', 'opacity'],
          }}
        />
        {/* dashed lines */}

        <Layer
          id={getId(uniquePrefix, TRAVEL_PATH_DASHED_LINES_LAYER)}
          type="line"
          source={getId(uniquePrefix, TRAVEL_PATH_SOURCE)}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          filter={['==', 'isDashed', true]}
          paint={{
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 5],
            'line-dasharray': [3, 2],
            'line-opacity': ['get', 'opacity'],
            'line-color': ['get', 'color'],
          }}
        />
        {/* sequence number */}
        <Layer
          id={getId(uniquePrefix, TRAVEL_PATH_SEQUENCE_LAYER)}
          type="symbol"
          source={getId(uniquePrefix, TRAVEL_PATH_SOURCE)}
          minzoom={17}
          layout={{
            'symbol-placement': 'line-center',
            'text-field': ['get', 'sequence'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 12, 22, 16],
            'text-allow-overlap': true,
            'text-ignore-placement': true,
          }}
          paint={{
            'text-color': ['get', 'color'],
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3,
            'text-opacity': ['get', 'opacity'],
          }}
        />
      </Source>
      <Source type="geojson" data={arrowsGeoJson} id={getId(uniquePrefix, TRAVEL_PATH_ARROWS_SOURCE)}>
        {/* arrows for directions */}
        <Layer
          id={getId(uniquePrefix, TRAVEL_PATH_ARROWS_LAYER)}
          type="symbol"
          source={getId(uniquePrefix, TRAVEL_PATH_ARROWS_SOURCE)}
          minzoom={15}
          layout={{
            'symbol-placement': 'point',
            'text-field': '▶',
            'text-anchor': 'center',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 20, 22, 40],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-rotate': ['get', 'rotate'],
          }}
          paint={{
            'text-color': ['get', 'color'],
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3,
            'text-opacity': ['get', 'opacity'],
          }}
        />
      </Source>
    </>
  );
};

export default memo(TravelPathGLSource);
