import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { DatePicker, TypedField } from '../../../core/components';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { RunningVehicle } from '../../interfaces/interfaces';
import { TODAY } from '../../../core/constants';
import inferenceAuditFormInitialValuesSelector from '../../services/inferenceAuditFormInitialValuesSelector';
import InferenceAuditRunningVehicleSelect from '../InferenceAuditRunningVehicleSelect';
import InferenceAuditStatusesSelect from '../InferenceAuditStatusesSelect';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  onAuditStatusTypeChange: (event: ChangeEvent, statusType: string) => void;
  onDateChange: (event: ChangeEvent, date: Date | string) => void;
  onVehicleChange: (event: ChangeEvent, vehicleId: number) => void;
  runningVehicles: RunningVehicle[];
}

class InferenceAuditFilterForm extends PureComponent<Props & InjectedFormProps<{}, Props>> {
  render() {
    const { onAuditStatusTypeChange, onDateChange, onVehicleChange, runningVehicles } = this.props;

    return (
      <form noValidate>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12">
              <TypedField
                name="date"
                component={DatePicker}
                onChange={onDateChange}
                validate={[isRequired, isDateValidValidator]}
                props={{
                  disabledDays: [{ after: TODAY }],
                  margin: 'no',
                  tabletAlignLeft: true,
                  disableFormSubmitOnDateChange: true,
                }}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <TypedField
                name="vehicleId"
                component={InferenceAuditRunningVehicleSelect}
                onChange={onVehicleChange}
                props={{
                  runningVehicles: runningVehicles,
                  dropdownProps: {
                    margin: 'no',
                    placeholder: translate('inferenceAudit.selectVehicle'),
                  },
                }}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <TypedField
                name="status"
                component={InferenceAuditStatusesSelect}
                onChange={onAuditStatusTypeChange}
                props={{
                  dropdownProps: {
                    margin: 'no',
                    placeholder: translate('inferenceAudit.selectStatus'),
                  },
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, { location: { search } }: Props) => ({
  initialValues: inferenceAuditFormInitialValuesSelector(search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<{}, Props>({
      form: 'inferenceAuditFilter',
      enableReinitialize: true,
    })(InferenceAuditFilterForm),
  ),
);
