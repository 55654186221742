import React from 'react';

import { UnconnectedCheckbox } from '../../../../core/components';
import { TableRow, TableCell } from '../../../../core/components/styled';

interface Props {
  id: number;
  isChecked: boolean;
  noOfStops: number;
  routeDayOfService: string;
  routeTemplateName: string;
  selectRouteTemplate: (event: any, id: number) => void;
  vehicleTypeName?: string;
  wasteMaterialTypeName?: string;
}

const RouteTemplateHolidayPlannerTableRow: React.SFC<Props> = ({
  id,
  isChecked,
  noOfStops,
  routeDayOfService,
  routeTemplateName,
  selectRouteTemplate,
  vehicleTypeName,
  wasteMaterialTypeName,
}) => (
  <TableRow>
    <TableCell width="5%">
      <UnconnectedCheckbox
        block
        size="small"
        id={`route-template-${id}-checkbox`}
        checked={isChecked}
        onChange={event => selectRouteTemplate(event, id)}
      />
    </TableCell>

    <TableCell width="30%" id={`route-template-${id}-name`}>
      {routeTemplateName}
    </TableCell>

    <TableCell width="15%" id={`route-template-${id}-material-type`}>
      {wasteMaterialTypeName}
    </TableCell>

    <TableCell width="15%" id={`route-template-${id}-day-of-service`}>
      {routeDayOfService}
    </TableCell>

    <TableCell width="15%" id={`route-template-${id}-no-of-stops`}>
      {noOfStops}
    </TableCell>

    <TableCell width="20%" id={`route-template-${id}-vehicle-type`}>
      {vehicleTypeName}
    </TableCell>
  </TableRow>
);

export default RouteTemplateHolidayPlannerTableRow;
