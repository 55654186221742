import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import doLoadRouteLocationIssues from '../services/loadRouteIssues';
import { RouteIssues } from '../interfaces/Route';

// Actions
const START_LOAD = 'routes/routeLocationIssues/START_LOAD';
const COMPLETE_LOAD = 'routes/routeLocationIssues/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeLocationIssues/FAIL_LOAD';
const RESET = 'routes/routeLocationIssues/RESET';

interface State {
  isLoading: boolean;
  routeLocationIssues: RouteIssues[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  routeLocationIssues: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeLocationIssues: action.routeLocationIssues,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeLocationIssues: RouteIssues[]) => ({
  type: COMPLETE_LOAD,
  routeLocationIssues,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteLocationIssues = (routeId: number, routeLocationId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const routeLocationIssuesPromise = doLoadRouteLocationIssues(routeId, routeLocationId);
  routeLocationIssuesPromise
    .then(routeLocationIssues => dispatch(completeLoad(routeLocationIssues)))
    .catch(() => dispatch(failLoad()));
  return routeLocationIssuesPromise;
};

export const resetRouteLocationIssues = () => ({
  type: RESET,
});
