import { findIndex, map, range } from 'lodash-es';
import { PureComponent } from 'react';

import { dateAndTimeStamp } from '../../utils/services/formatter';
import {
  DescriptionList,
  DescriptionListThumbItem,
  ImageSliderWrapper,
  ImageSlider as ImageSliderContainer,
  ThumbnailImageSlider as ThumbnailImageSliderContainer,
  ImageSliderContent,
  ThumbnailsImageSliderContent,
  ImageSliderImage,
  ThumbnailsImageSliderImage,
  ImageSliderLoading,
  ImageSliderNavigation,
  ImageSliderDownload,
  ImageSliderDownloadIcon,
  ImageSliderNavigationIcon,
  Text,
} from './styled';
import { downloadFile } from '../services/downloadFile';
import calculateImageSliderBoundaries from '../services/calculateCarouselmageSliderBoundaries';
import translate from '../services/translate';
import VehicleCameraViewDropdownComponent from './VehicleCameraViewDropdown';

interface Props {
  images: string[];
  onIndexChange?: (index: number, cameraSerialNumber: string) => void;
  onCameraViewChange?: (value: string) => void;
  margin?: string;
  imageTitle: string;
  timeStamp?: string;
}

interface State {
  index: number;
  isLoading: boolean;
  currentSlideIndex: number;
  imageUrls: string[];
  originalUrl: string[];
  cameraSerialNumber?: string;
}

class StrobeImagesSlider extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      index: findIndex(props.images, ['isSelectedImage', true]),
      isLoading: false,
      currentSlideIndex: findIndex(props.images, ['isSelectedImage', true]),
      imageUrls: map(props.images, 'thumbnailImageUrl'),
      originalUrl: map(props.images, 'imageUrl'),
      cameraSerialNumber: undefined,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyboardHandler, false);
  }

  componentDidUpdate(prevProps: Props) {
    const { images } = this.props;
    if (images !== prevProps.images) {
      this.initializeState();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardHandler, false);
  }

  onImageLoad = () => {
    this.setState({ isLoading: false });
  };

  onThumbnailClick = (indexKey: number) => {
    const { onIndexChange } = this.props;
    const { cameraSerialNumber } = this.state;
    this.setState(() => {
      if (onIndexChange) onIndexChange(indexKey, cameraSerialNumber!);
      return { index: indexKey, currentSlideIndex: indexKey };
    });
  };

  onVehicleCameraViewDropdownChange = (value: string) => {
    const { onCameraViewChange } = this.props;
    this.setState({ cameraSerialNumber: value });
    if (onCameraViewChange) {
      onCameraViewChange(value);
    }
  };

  initializeState = () => {
    const { images } = this.props;
    this.setState({
      imageUrls: map(images, 'thumbnailImageUrl'),
      originalUrl: map(images, 'imageUrl'),
      index: findIndex(images, ['isSelectedImage', true]),
      currentSlideIndex: findIndex(images, ['isSelectedImage', true]),
    });
  };

  moveNextSlide = () => {
    const { cameraSerialNumber } = this.state;
    const { onIndexChange } = this.props;
    this.setState(({ index, currentSlideIndex }) => {
      const nextCurrentSlideIndex = currentSlideIndex + 1;
      const nextIndex = index + 1;
      if (onIndexChange) onIndexChange(nextIndex, cameraSerialNumber!);
      return { index: nextIndex, currentSlideIndex: nextCurrentSlideIndex };
    });
  };

  movePrevSlide = () => {
    const { cameraSerialNumber } = this.state;
    const { onIndexChange } = this.props;
    this.setState(({ index, currentSlideIndex }) => {
      const nextCurrentSlideIndex = currentSlideIndex - 1;
      const nextIndex = index - 1;
      if (onIndexChange) onIndexChange(nextIndex, cameraSerialNumber!);
      return { index: nextIndex, currentSlideIndex: nextCurrentSlideIndex };
    });
  };

  goToNextImage = () => {
    const { cameraSerialNumber } = this.state;
    const { onIndexChange } = this.props;
    this.setState(({ index, currentSlideIndex }) => {
      const nextIndex = index + 1;
      const nextCurrentSlideIndex = currentSlideIndex + 1;
      if (onIndexChange) onIndexChange(nextIndex, cameraSerialNumber!);
      return { index: nextIndex, currentSlideIndex: nextCurrentSlideIndex, isLoading: true };
    });
  };

  goToPreviousImage = () => {
    const { onIndexChange } = this.props;
    const { cameraSerialNumber } = this.state;
    this.setState(({ index, currentSlideIndex }) => {
      const nextIndex = index - 1;
      const nextCurrentSlideIndex = currentSlideIndex - 1;
      if (onIndexChange) onIndexChange(nextIndex, cameraSerialNumber!);
      return { index: nextIndex, currentSlideIndex: nextCurrentSlideIndex, isLoading: true };
    });
  };

  keyboardHandler = (event: any) => {
    const { images } = this.props;
    const { index } = this.state;
    event.preventDefault();
    if (event.keyCode === 39 && index < images.length - 1) {
      this.goToNextImage();
      return;
    }

    if (event.keyCode === 37 && index > 0) {
      this.goToPreviousImage();
    }
  };

  render() {
    const { images, margin, imageTitle, timeStamp } = this.props;
    const { index, isLoading, currentSlideIndex, imageUrls, originalUrl } = this.state;
    const { firstSlide, lastSlide } = calculateImageSliderBoundaries(images.length, currentSlideIndex);
    return (
      <ImageSliderWrapper>
        <DescriptionList centered margin="no">
          <DescriptionListThumbItem>
            <VehicleCameraViewDropdownComponent handleChange={this.onVehicleCameraViewDropdownChange} />
            {timeStamp && (
              <Text margin="no xSmall no no" color="grayDark">
                {translate('common.timestamp')}:
              </Text>
            )}
            <Text margin="no xSmall no no">{timeStamp ? dateAndTimeStamp(timeStamp) : ''}</Text>
          </DescriptionListThumbItem>
        </DescriptionList>

        <ImageSliderContainer margin={margin}>
          <ImageSliderContent>
            <ImageSliderImage src={originalUrl[index]} onLoad={this.onImageLoad} />
          </ImageSliderContent>

          {isLoading && <ImageSliderLoading />}

          {index > 0 && (
            <ImageSliderNavigation previous onClick={this.goToPreviousImage} disabled={isLoading}>
              <ImageSliderNavigationIcon icon="arrowLeft" />
            </ImageSliderNavigation>
          )}

          {index < images.length - 1 && (
            <ImageSliderNavigation next onClick={this.goToNextImage} disabled={isLoading}>
              <ImageSliderNavigationIcon icon="arrowRight" />
            </ImageSliderNavigation>
          )}

          <ImageSliderDownload onClick={() => downloadFile(originalUrl[index], imageTitle)}>
            <ImageSliderDownloadIcon /> {translate('common.download')}
          </ImageSliderDownload>
        </ImageSliderContainer>

        <ThumbnailImageSliderContainer margin="xxSmall">
          {index > 0 && (
            <ImageSliderNavigation previous onClick={() => this.movePrevSlide()}>
              <ImageSliderNavigationIcon icon="arrowLeft" />
            </ImageSliderNavigation>
          )}

          {range(firstSlide, lastSlide + 1).map(indexKey => (
            <ThumbnailsImageSliderContent key={indexKey} isActive={currentSlideIndex === indexKey}>
              <ThumbnailsImageSliderImage src={imageUrls[indexKey]} onClick={() => this.onThumbnailClick(indexKey)} />
            </ThumbnailsImageSliderContent>
          ))}

          {index < images.length - 1 && (
            <ImageSliderNavigation next onClick={() => this.moveNextSlide()}>
              <ImageSliderNavigationIcon icon="arrowRight" />
            </ImageSliderNavigation>
          )}
        </ThumbnailImageSliderContainer>
      </ImageSliderWrapper>
    );
  }
}

export default StrobeImagesSlider;
