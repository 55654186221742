import { AnyAction } from 'redux';
import update from 'immutability-helper';

import { loadCityInsightsMapbox as doLoadCityInsightsMapbox } from '../services/loadCityInsightsMapbox';

const START_LOAD_MAP_INSIGHTS_MAPBOX = 'dashboard/mapInsightsMapbox/START_LOAD';
const COMPLETE_LOAD_MAP_INSIGHTS_MAPBOX = 'dashboard/mapInsightsMapbox/COMPLETE_LOAD';
const FAIL_LOAD_MAP_INSIGHTS_MAPBOX = 'dashboard/mapInsightsMapbox/FAIL_LOAD';
const RESET_MAP_INSIGHTS_MAPBOX = 'dashboard/mapInsightsMapbox/RESET';

// Initial state
const initialState = {
  isLoading: false,
  mapInsights: null as any,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_MAP_INSIGHTS_MAPBOX:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_MAP_INSIGHTS_MAPBOX:
      return update(state, {
        $merge: {
          isLoading: false,
          mapInsights: action.mapInsights,
        },
      });

    case FAIL_LOAD_MAP_INSIGHTS_MAPBOX:
      return update(state, {
        $merge: {
          isLoading: false,
          mapInsights: null,
        },
      });

    case RESET_MAP_INSIGHTS_MAPBOX:
      return update(state, {
        $merge: {
          isLoading: false,
          mapInsights: null,
        },
      });

    default:
      return state;
  }
};

// Action creators

const startLoadMapInsightsMapbox = () => ({
  type: START_LOAD_MAP_INSIGHTS_MAPBOX,
});

const completeLoadMapInsightsMapbox = (mapInsights: any, cityInsightTypes?: string[]) => ({
  type: COMPLETE_LOAD_MAP_INSIGHTS_MAPBOX,
  mapInsights,
  cityInsightTypes,
});

const failLoadMapInsightsMapbox = () => ({
  type: FAIL_LOAD_MAP_INSIGHTS_MAPBOX,
});

export const resetMapInsightsMapbox = () => ({
  type: RESET_MAP_INSIGHTS_MAPBOX,
});

export const loadMapInsightsMapbox = (date: Date | string, cityInsights?: string[]) => (dispatch: any) => {
  dispatch(startLoadMapInsightsMapbox());
  return doLoadCityInsightsMapbox(date, cityInsights)
    .then((mapInsights: any) => {
      dispatch(completeLoadMapInsightsMapbox(mapInsights));
    })
    .catch(() => {
      dispatch(failLoadMapInsightsMapbox());
    });
};
