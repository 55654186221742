import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { NINETY_DAYS_BEFORE_TODAY, FIFTEEN_DAYS_AFTER_TODAY } from 'src/core/constants';
import { getQueryParams } from 'src/utils/services/queryParams';

const getBillingFilterFormInitialValuesSelector = (queryString: string) => {
  const { billsSearchTerm, billStatusId, isOverdue ,startDate, endDate } = getQueryParams(queryString);
  return {
    billsSearchTerm,
    billStatusId,
    isOverdue,
    billDateRange: {
      from: startDate || NINETY_DAYS_BEFORE_TODAY,
      to: endDate || FIFTEEN_DAYS_AFTER_TODAY,
    },
  };
};

const billingFilterFormInitialValuesSelector = createSelector(getBillingFilterFormInitialValuesSelector, identity);

export default billingFilterFormInitialValuesSelector;
