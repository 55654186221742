import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { PanelSection, ModalClose, ModalCloseIcon, Button, ButtonSet } from '../../../core/components/styled';
import { NotificationPhoneNumbers } from '..';
import translate from '../../../core/services/translate';
import notificationPhoneNumbersFormInitialValuesSelector from '../../services/notificationPhoneNumbersFormInitialValuesSelector';
import { AppState } from '../../../store';

interface ComponentProps {
  closePopup: (pristine: boolean) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class NotificationPhoneNumbersForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, closePopup, pristine } = this.props;

    return (
      <form
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(event);
        }}
      >
        <ModalClose onClick={() => closePopup(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <PanelSection padding="xSmall">
          <NotificationPhoneNumbers />
        </PanelSection>
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { textNotificationPhones } = getFormValues('customerLocationEditor')(state) as any;
  return {
    initialValues: notificationPhoneNumbersFormInitialValuesSelector(textNotificationPhones),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'notificationPhoneNumbers',
  })(NotificationPhoneNumbersForm),
);
