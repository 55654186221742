import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const REQUESTED = 1;
export const PENDING = 2;
export const SCHEDULED = 3;
export const SERVICE_CONFIRMED = 4;
export const WORK_ORDER_COMPLETE = 5;
export const UNABLE_TO_COMPLETE = 6;
export const DECLINED = 7;
export const NA = 8;
export const CANCELLED = 9;
export const DELIVERY_SUSPENDED = 10;
export const CLOSED = 11;
export const ACTIONREQUIRED = 12;
export const ON_SCHEDULE = 13;

export const CONFIRM_SERVICE = 3;
export const DECLINE = 'Decline';
export const REQUEST_CHANGE = 'RequestChange';

export const RUBICON_DISPATCHES_STATUS = mapKeys(
  [
    { id: REQUESTED, name: translate('autoDispatch.requested') },
    { id: PENDING, name: translate('common.pending') },
    { id: SCHEDULED, name: translate('dashboard.scheduled') },
    { id: SERVICE_CONFIRMED, name: translate('common.serviceConfirmed') },
    { id: WORK_ORDER_COMPLETE, name: translate('autoDispatch.workOrderComplete') },
    { id: UNABLE_TO_COMPLETE, name: translate('common.unableToComplete') },
    { id: DECLINED, name: translate('common.declined') },
    { id: NA, name: translate('common.notAvailable') },
    { id: CANCELLED, name: translate('dashboard.cancelled') },
    { id: DELIVERY_SUSPENDED, name: translate('common.deliverySuspended') },
    { id: CLOSED, name: translate('common.accountStatuses.closed') },
  ],
  'id',
);

export const SMART_FILTER_OPTIONS = mapKeys(
  [
    { id: REQUESTED, name: translate('autoDispatch.requested') },
    { id: ON_SCHEDULE, name: translate('opportunity.deliveries.onSchedule') },
    { id: CONFIRM_SERVICE, name: translate('autoDispatch.confirmService') },
    { id: SERVICE_CONFIRMED, name: translate('autoDispatch.documentNeeded') },
    { id: WORK_ORDER_COMPLETE, name: translate('autoDispatch.completedCancelled') },
  ],
  'name',
);
