import { uniqBy } from 'lodash-es';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { http } from 'src/core/services/http';
import translate from 'src/core/services/translate';
import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import { SelectedBillsToAssign } from '../interfaces/BillDetails';
import { CalendarUnbilledCharge } from '../interfaces/Billing';

export const loadUnbilledChargesList = (vendorId: number, invoiceId: number, includeRates?: boolean) =>
  http
    .post(`statements/charges/unbilled`, {
      vendorId,
      invoiceId,
      includeRates,
    })
    .then(response => response.data);

export const loadUnbilledChargesByLocation = (
  vendorId: number,
  startDate: StringOrDate,
  endDate: StringOrDate,
  locationId?: number,
  billMethodIds?: number[],
  paymentTermsIds?: number[],
) =>
  http
    .post('statements/billPreview/unbilledCharges', {
      vendorId,
      startDate,
      endDate,
      locationId,
      billMethodIds: billMethodIds?.length ? billMethodIds : null,
      paymentTermsIds: paymentTermsIds?.length ? paymentTermsIds : null,
    })
    .then(response => response.data);

export const assignToBill = (workOrderId: number, billDate: StringOrDate, vendorLocationId: number, billId?: number, headerId?: number) =>
  http
    .post(`statements/${billId}/unbilledCharges/assign`, {
      workOrderId,
      billDate,
      vendorLocationId,
      chargeHeaderId: headerId
    })
    .then(response => response.data);

export const assignChargesToBill = (bills: SelectedBillsToAssign[], billId?: number) =>
  http
    .put(`statements/${billId}/unbilledCharges/assign`, { selectedBillsToAssign: bills })
    .then(response => response.data);

export const exportUnbilledCharges = (unbilledSearchTerm?: string, workOrderTypeId?: number, containerTypeId?: number, startDate?: StringOrDate,
  endDate?: StringOrDate) =>
  downloadExcelFile(
    'statements/exportUnBilledCharges',
    `${translate('finance.unbilledCharges')}-${translate('finance.report')}`,
    { unbilledSearchTerm, workOrderTypeId, containerTypeId, startDate, endDate },
  );

export const loadCalendarUnbilledCharges = (startDate: StringOrDate, endDate: StringOrDate) =>
  http
    .post('statements/unBilledCalendar', {
      startDate,
      endDate,
    })
    .then(response => uniqBy<CalendarUnbilledCharge>(response.data, 'date'));