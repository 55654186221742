import { FC, MouseEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from 'src/core/components/styled/Box';
import { CustomerLocationPaymentMethodTableRow } from './CustomerLocationPaymentMethodTableRow';
import { CustomerPaymentMethod } from 'src/customers/interfaces/CustomerPaymentMethod';
import { deleteCustomerPaymentMethod } from 'src/customers/ducks/paymentMethods';
import { PaymentLocationAssignmentModal } from './PaymentLocationAssignmentModal';
import { Table } from 'src/core/components';
import { TableRow, TableCell, Text, TableActionButton } from '../../../../core/components/styled';
import ActionButtonTooltip from 'src/core/components/ActionButtonTooltip';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';

interface Props extends CustomerPaymentMethod {
  customerId: number;
}

const tableCells = [
  {
    name: 'locations',
    label: translate('finance.paymentMethods.locations'),
    width: '85%',
  },
  {
    name: 'options',
    label: translate('common.options'),
    width: '15%',
    align: 'right',
  },
];

export const PaymentManagementTableRow: FC<Props> = ({
  paymentConnectPaymentMethodId,
  type,
  cardExpirationMonth,
  cardExpirationYear,
  cardNumber,
  achNumber,
  achBankName,
  customerLocationPaymentMethods,
  customerId,
  id,
}) => {
  const dispatch = useDispatch();

  const deleteMethod = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (await confirm(translate('finance.paymentMethods.confirmDeletePaymentMethod'))) {
      deleteCustomerPaymentMethod(customerId, id)(dispatch);
    }
  };

  const associateToLocations = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsAssignmentModalOpen(true);
  };

  const getDetails1 = useCallback(() => {
    return `****${type === 'card' ? `-${cardNumber}` : achNumber}`;
  }, [achNumber, cardNumber, type]);

  const getDetails2 = useCallback(() => {
    return type === 'card' ? `${cardExpirationMonth}/${cardExpirationYear}` : achBankName;
  }, [achBankName, cardExpirationMonth, cardExpirationYear, type]);

  const [isOpen, setIsOpen] = useState(false);
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);

  return (
    <>
      <TableRow onClick={customerLocationPaymentMethods.length ? () => setIsOpen(!isOpen) : undefined}>
        <TableCell width={'35%'}>
          <Text>{paymentConnectPaymentMethodId}</Text>
        </TableCell>
        <TableCell width={'25%'}>
          <Text>{translate(`finance.paymentTypes.${type}`)}</Text>
        </TableCell>
        <TableCell vertical width={'25%'}>
          <Text>{getDetails1()}</Text>
          <Text>{getDetails2()}</Text>
        </TableCell>
        <TableCell width={'15%'} align="right">
          <TableActionButton onClick={associateToLocations}>
            <ActionButtonTooltip
              icon="building"
              tooltip={translate('finance.paymentMethods.assignToLocations')}
              tooltipAsString
            />
          </TableActionButton>
          <TableActionButton onClick={deleteMethod}>
            <ActionButtonTooltip
              icon="delete"
              tooltip={translate('finance.paymentMethods.deletePayment')}
              tooltipAsString
            />
          </TableActionButton>
          {!!customerLocationPaymentMethods.length && (
            <TableActionButton>
              <ActionButtonTooltip icon={isOpen ? 'arrowUpFill' : 'arrowDownFill'} />
            </TableActionButton>
          )}
        </TableCell>
      </TableRow>

      {isOpen && (
        <Box margin="no no medium medium" display="block">
          <Table
            cells={tableCells}
            rows={customerLocationPaymentMethods}
            rowComponent={CustomerLocationPaymentMethodTableRow}
            rowProps={{ customerId, paymentConnectPaymentMethodId }}
          />
        </Box>
      )}

      {isAssignmentModalOpen && (
        <PaymentLocationAssignmentModal
          customerExternalPaymentMethodId={id}
          customerId={customerId}
          onClose={() => setIsAssignmentModalOpen(false)}
          assignedLocationIds={customerLocationPaymentMethods.map(i => i.locationId)}
        />
      )}
    </>
  );
};
