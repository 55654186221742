import { http } from '../../core/services/http';
import { transformOpenDispatches } from './transformOpenDispatches';
import { transformServiceConfirmation } from './transformServiceConfirmation';
import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { UserNotesResponse, AddNoteBody } from '../interfaces/RubiconDispatchesNotes';

export const authenticateToken = (token: string) =>
  http.get(`gus/workorderautodispatch/authenticate/${token}`).then(response => response.data);

export const loadOpenDispatches = (
  token: string,
  startDate: string,
  endDate: string,
  dispatchStatus: any,
  workOrder: any,
  workOrderType?: any,
  page?: number,
  limit?: number,
) =>
  http
    .get(`gus/workorderautodispatch/openDispatches/${token}`, {
      params: { startDate, endDate, dispatchStatus, workOrder, workOrderType, page, limit },
    })
    .then(response => transformOpenDispatches(response.data));

export const loadOpenDispatchesByUserId = (
  userId: string,
  vendorId: number,
  startDate: string,
  endDate: string,
  dispatchStatus: any,
  workOrder: any,
  workOrderType?: any,
  page?: number,
  limit?: number,
) =>
  http
    .get(`gus/workorderautodispatch/openDispatches/vendor/${vendorId}/user/${userId}`, {
      params: { startDate, endDate, dispatchStatus, workOrder, workOrderType, page, limit },
    })
    .then(response => transformOpenDispatches(response.data));

export const updateOpenDispatches = (requestOpportunityObj: any) =>
  http
    .post('gus/workorderautodispatch/update', requestOpportunityObj)
    .then(response => transformOpenDispatches(response.data));

export const updateOpenDispatchesByUserId = (requestOpportunityObj: any, userId: string, vendorId: number) =>
  http
    .post(`gus/workorderautodispatch/vendor/${vendorId}/update/user/${userId}`, requestOpportunityObj)
    .then(response => transformOpenDispatches(response.data));

export const responseMissedPickupByUserId = (
  itemId: number,
  responseType: string,
  serviceDate: Date | string | undefined,
  reasonCode: any,
  userId: string,
  vendorId: number,
  dayofWeek?: string[],
  weightTicketSize?: number,
  unitOfMeasureTypeId?: string | number,
  weightTicketUom?: string | number,
  note?: string,
  files?: any,
) => {
  const formData = new FormData();
  const isServiceCompleted = responseType === 'SCHEDULED' && !!serviceDate;

  formData.append('itemId', itemId.toString());
  formData.append('responseType', responseType);
  formData.append('reasonCode', reasonCode);
  formData.append('serviceCompleted', isServiceCompleted.toString());
  formData.append('files', files);
  formData.append('note', note || '');
  if (serviceDate) formData.append('serviceDate', serviceDate.toString());
  if (dayofWeek) formData.append('dayOfWeek', dayofWeek.toString());
  if (weightTicketSize) formData.append('weightTicketSize', weightTicketSize.toString());
  if (unitOfMeasureTypeId) formData.append('unitOfMeasureTypeId', unitOfMeasureTypeId.toString());
  if (weightTicketUom) formData.append('weightTicketUom', weightTicketUom.toString());

  return http
    .post(`bpm/sourcing/hauler/missedpickupV2/vendor/${vendorId}/user/${userId}/response`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => transformOpenDispatches(response.data));
};

export const responseMissedPickupByToken = (
  itemId: number,
  responseType: string,
  serviceDate: Date | string | undefined,
  reasonCode: any,
  token: string | undefined,
  dayOfWeek?: string[],
  weightTicketSize?: number,
  unitOfMeasureTypeId?: string | number,
  weightTicketUom?: string | number,
  note?: string,
  files?: any,
) => {
  const isServiceCompleted = responseType === 'SCHEDULED' && !!serviceDate;
  const formData = new FormData();

  formData.append('itemId', itemId.toString());
  formData.append('responseType', responseType);
  formData.append('reasonCode', reasonCode);
  formData.append('serviceCompleted', isServiceCompleted.toString());
  formData.append('files', files);
  formData.append('note', note || '');
  if (serviceDate) formData.append('serviceDate', serviceDate.toString());
  if (dayOfWeek) formData.append('dayOfWeek', dayOfWeek.toString());
  if (weightTicketSize) formData.append('weightTicketSize', weightTicketSize.toString());
  if (unitOfMeasureTypeId) formData.append('unitOfMeasureTypeId', unitOfMeasureTypeId.toString());
  if (weightTicketUom) formData.append('weightTicketUom', weightTicketUom.toString());

  return http
    .post(`bpm/sourcing/hauler/missedpickupV2/response/${token}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => transformOpenDispatches(response.data));
};

export const loadServiceConfirmation = (
  token: string,
  startDate: string,
  endDate: string,
  dispatchStatus: any,
  workOrder: any,
  workOrderType: any,
  page: number,
  limit: number,
) =>
  http
    .get(`gus/workorderautodispatch/serviceConfirmation/${token}`, {
      params: { startDate, endDate, dispatchStatus, workOrder, workOrderType, page, limit },
    })
    .then(response => transformServiceConfirmation(response.data));

export const loadServiceConfirmationByUserId = (
  userId: string,
  vendorId: number,
  startDate: string,
  endDate: string,
  dispatchStatus: any,
  workOrder: any,
  workOrderType: any,
  page: number,
  limit: number,
) =>
  http
    .get(`gus/workorderautodispatch/serviceConfirmation/vendor/${vendorId}/user/${userId}`, {
      params: { startDate, endDate, dispatchStatus, workOrder, workOrderType, page, limit },
    })
    .then(response => transformServiceConfirmation(response.data));

export const updateServiceConfirmation = (requestOpportunityObj: any) =>
  http
    .post('gus/workorderautodispatch/update', requestOpportunityObj)
    .then(response => transformOpenDispatches(response.data));

export const updateServiceConfirmationByUserId = (requestOpportunityObj: any, userId: string, vendorId: number) =>
  http
    .post(`gus/workorderautodispatch/vendor/${vendorId}/update/user/${userId}`, requestOpportunityObj)
    .then(response => transformOpenDispatches(response.data));

export const uploadDocument = (file: string, token: string, workOrder: any, reportType: any, serviceDate: string) => {
  return http
    .post(
      `gus/Customer/file/${file}?token=${token}&workOrder=${workOrder}&reportType=${reportType}&serviceDate=${serviceDate}`,
    )
    .then(response => response.data);
};

export const uploadDocumentByUserId = (
  file: string,
  token: string | number,
  workOrder: any,
  reportType: any,
  userId: string,
  vendorId: number,
  serviceDate: string,
) => {
  // const formData = new FormData();
  // formData.append('file', file);

  return http
    .post(
      `gus/vendor/${vendorId}/user/${userId}/Customer/file/${file}?token=${token}&workOrder=${workOrder}&reportType=${reportType}&serviceDate=${serviceDate}`,
    )
    .then(response => response.data);
};

export const downloadDocument = (fileId: number, fileName: string) =>
  downloadExcelFile(`gus/Customer/file/download/${fileId}`, fileName, undefined, true);

export const getServiceContractByCode = (serviceContractCode?: string) =>
  http.get(`autoDispatch/serviceContractByCode/${serviceContractCode}`).then(response => response.data);

export const createUnassignedJob = (
  vendorId: number,
  serviceContractId?: number,
  routeNote?: string,
  date?: Date | string,
  workOrderNumber?: number,
) =>
  http.post('autoDispatch/createUnassignedJob', {
    vendorId,
    serviceContractId,
    routeNote,
    date,
    gusWorkOrderNumber: workOrderNumber,
  }); // For Vendor Portal Side The WorkOrderNumber Is Referenced As GusWorkOrderNumber

export const saveServiceContract = (serviceContractId: number, serviceContractCode?: string) =>
  http.put(`autoDispatch/serviceContract/${serviceContractId}/${serviceContractCode}`);

export const getNotesByToken = (token: string, workOrder: any, serviceDate: Date | string) =>
  http
    .get<UserNotesResponse>(`gus/workorderautodispatch/notes/${token}`, { params: { workOrder, serviceDate } })
    .then(response => response.data);

export const getNotesByUserId = (userId: string, vendorId: number, workOrder: any, serviceDate: Date | string) =>
  http
    .get<UserNotesResponse>(`gus/workorderautodispatch/vendor/${vendorId}/notes/user/${userId}`, {
      params: { workOrder, serviceDate },
    })
    .then(response => response.data);

export const addNotesByToken = (body: AddNoteBody, token: string) =>
  http.post<UserNotesResponse>(`gus/workorderautodispatch/notes/${token}`, body).then(response => response.data);

export const addNotesByUserId = ({ body, userId, vendorId }: { body: AddNoteBody; userId: string; vendorId: number }) =>
  http
    .post<UserNotesResponse>(`gus/workorderautodispatch/vendor/${vendorId}/notes/user/${userId}`, body)
    .then(response => response.data);
