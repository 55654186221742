import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import EsriIntegrationConfigurationModal from './EsriIntegrationConfigurationModal';
import {
  loadEsriCredentials,
  loadEsriCustomerConfig,
  loadEsriGeoFenceConfig,
  loadEsriRouteConfig,
  loadEsriServiceConfig,
} from '../../ducks/esriIntegrationConfiguration';

interface Props {
  closeModal: () => void;
}

export default function EsriIntegrationConfigurationModalResolver(props: Props) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    const dependencies: Promise<any>[] = [];
    dependencies.push(
      loadEsriCredentials(vendorId)(dispatch),
      loadEsriCustomerConfig(vendorId)(dispatch),
      loadEsriGeoFenceConfig(vendorId)(dispatch),
      loadEsriRouteConfig(vendorId)(dispatch),
      loadEsriServiceConfig(vendorId)(dispatch),
    );
    return Promise.all(dependencies);
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={EsriIntegrationConfigurationModal}
      successProps={{ ...props, vendorId }}
    />
  );
}
