import styled, { css } from 'styled-components';

import { loadingOverlay } from '../../../core/styles';
import { mapper, sizeMapper } from '../../../utils/styles';

interface Props {
  align?: string;
  borderBottom?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderTop?: boolean;
  isLoading?: boolean;
  margin?: string;
  padding?: string;
  size?: string;
  vertical?: boolean;
  width?: string;
  wrap?: string;
}

export const FlexForm = styled.form<Props>`
  display: flex;
  width: 100%;
  position: relative;

  ${props =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `};

  ${props =>
    props.isLoading &&
    css`
      ${props => loadingOverlay('24px', props.theme.bodyBackgroundColor)};
    `};
`;

export const StatusWrapper = styled.div`
  text-align: center;
`;

export const InferenceListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

export const ImageModalToggle = styled.span`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  img {
    display: block;
  }
`;

export const InferenceAuditFormBlock = styled.div<Props>`
  display: inline-flex;
  align-items: center;
  width: ${props => `${props.width}`};
  min-height: 60px;
  word-break: break-word;
  line-height: 18px;
  font-size: 14px;

  ${props =>
    props.padding &&
    css`
      padding: ${sizeMapper(props.padding)};
    `};

  ${props =>
    props.borderRight &&
    css`
      border-right: 1px solid ${props.theme.grayLight};
    `};

  ${props =>
    props.borderLeft &&
    css`
      border-left: 1px solid ${props.theme.grayLight};
    `};

  ${props =>
    props.align &&
    css`
      justify-content: ${mapper(
        props.align,
        {
          left: 'flex-start',
          center: 'center',
          right: 'flex-end',
        },
        'left',
      )};
    `};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: ${mapper(
        props.align,
        {
          top: 'flex-start',
          middle: 'center',
          bottom: 'flex-end',
        },
        'center',
      )};
    `};
`;

export const InferenceAuditModalBlockWrapper = styled.div<Props>`
  width: 100%;
  display: flex;

  ${props =>
    props.padding &&
    css`
      padding: ${sizeMapper(props.padding)};
    `};

  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${props.theme.grayLight};
    `};
`;

export const InferenceAuditModalBlockLabel = styled.span<Props>`
  width: ${props => `${props.width}`};
  display: block;
  margin: '14px';
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px' })};
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid ${props.theme.grayLight};
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `};
`;
