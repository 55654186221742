import { FC, useEffect, useMemo, useState } from 'react';

import { MAP_CENTER, MAP_CITY_ZOOM_IN, MAP_DEFAULT_ZOOM_SMALL } from 'src/core/constants';
import { ServiceContainer } from 'src/customers/interfaces/Services';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { MapGLWrapper } from '../../styled';
import { MapGL } from 'src/common/components/map/MapGL';
import { getFeatureCollection, getMapBounds, getPointFeature } from 'src/common/components/map/util';
import mapboxgl from 'mapbox-gl';
import ServiceContainersClustersGL from './ServiceContainersClustersGL';
import ServiceContainersGL from './ServiceContainersGL';

interface Props {
  serviceContainers: ServiceContainer[];
  defaultLatLng?: mapboxgl.LngLat;
}

const ServicesMapGL: FC<Props> = ({ serviceContainers, defaultLatLng }) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);

  const [viewport, setViewport] = useState<MapGLViewport>({});

  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);

  const handleSatelliteViewChange = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  // fit map bounds
  useEffect(() => {
    const markerPositions: { latitude: number; longitude: number }[] = [];
    if (serviceContainers.length) {
      serviceContainers.map(serviceContainer =>
        markerPositions.push({
          latitude: serviceContainer.lat,
          longitude: serviceContainer.lng,
        }),
      );
    } else if (defaultLatLng) {
      markerPositions.push({
        latitude: defaultLatLng.lat,
        longitude: defaultLatLng.lng,
      });
    } else {
      markerPositions.push({
        latitude: MAP_CENTER.lat(),
        longitude: MAP_CENTER.lng(),
      });
    }

    if (markerPositions.length) {
      const bounds = getMapBounds(markerPositions, {
        padding: 10,
        capZoom: !!defaultLatLng ? MAP_CITY_ZOOM_IN : MAP_DEFAULT_ZOOM_SMALL,
      });
      setViewport(bounds);
    }
  }, [serviceContainers, defaultLatLng]);

  const geoJSON = useMemo(
    () =>
      getFeatureCollection<GeoJSON.Point, { id: number }>(
        serviceContainers.map((service, index) =>
          getPointFeature(service.id, [service.lng, service.lat], {
            id: service.id,
            orderNo: index + 1,
          }),
        ),
      ),
    [serviceContainers],
  );

  return (
    <MapGLWrapper>
      <MapGL
        key="services-containers-map"
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={handleSatelliteViewChange}
      >
        {!!map && (
          <>
            <ServiceContainersClustersGL geoJSON={geoJSON} map={map} setViewport={setViewport} />
            <ServiceContainersGL />
          </>
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default ServicesMapGL;
