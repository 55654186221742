import React from 'react';
import { Layer } from 'react-map-gl';

import { TRIGGER_MAP_LAYER, TRIGGER_MAP_CLUSTER_SOURCE } from 'src/routes/constants/triggerMap';

export default React.memo(function TriggerGLLayer() {
  return (
    <Layer
      id={TRIGGER_MAP_LAYER}
      type="symbol"
      source={TRIGGER_MAP_CLUSTER_SOURCE}
      filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], TRIGGER_MAP_LAYER]]}
      paint={{}}
      layout={{
        'symbol-z-order': 'source',
        'symbol-sort-key': 1,
        'icon-image': ['get', 'icon'],
        'icon-size': 1,
        'icon-allow-overlap': true,
      }}
    />
  );
});
