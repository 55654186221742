import { http } from 'src/core/services/http';
import {
  CompletePaymentData,
  CompletePaymentIntentData,
  PaymentFeeEstimationData,
  PaymentMethodAutoPayData,
  PaymentMethodDeleteData,
  PaymentMethodIsDefaultData,
} from 'src/finance/interfaces/PaymentManagement';

export const getPaymentSetup = (invoiceId: string) =>
  http.get(`payment/setup/${invoiceId}`).then(response => response.data);

// Not used anymore
// export const getPaymentSummaryForCustomer = (customerId: string | number) =>
//   http.get(`/payment/customer/${customerId}/paymentSummary`).then(response => response.data);

export const getPaymentMethodsByInvoice = (invoiceId: string) =>
  http.get(`payment/invoice/${invoiceId}/paymentMethods`).then(response => response.data);

export const getPaymentMethodsByLocation = (locationId: number) =>
  http.get(`payment/location/${locationId}/paymentMethods`).then(response => response.data);

export const getInvoicesByLocation = (customerId: number, locationId: number) =>
  http.get(`payment/customer/${customerId}/location/${locationId}/invoicePayments`).then(response => response.data);

export const getUIConfig = () =>
  http.get('payment/uiconfig').then(response => response.data);

// Not yet implemented, actual path may change
export const getAlertsByLocation = (customerId: string, locationId: string) =>
  http.get(`payment/customer/${customerId}/location/${locationId}/alerts`).then(response => response.data);

export const setupPaymentIntent = (invoiceId: string) =>
  http.post(`payment/setupIntent`, { invoiceId }).then(response => response.data);

export const setupPaymentIntentWithLocation = (locationId: string) =>
  http.post(`payment/location/setupIntent`, { locationId }).then(response => response.data);

export const setupPaymentIntentWithCustomer = (customerId: number) =>
  http.post(`payment/customer/setupIntent`, { customerId }).then(response => response.data);

export const completePaymentIntent = (data: CompletePaymentIntentData) =>
  http.post(`payment/setupIntent/setup-complete`, data).then(response => response.data);

export const completePayment = (data: CompletePaymentData) =>
  http.post(`payment/directCharge`, data).then(response => response.data);

export const setPaymentMethodAutoPay = (data: PaymentMethodAutoPayData) =>
  http.post(`payment/paymentmethod/autopay`, data).then(response => response.data);

export const setPaymentMethodAsDefault = (data: PaymentMethodIsDefaultData) =>
  http.post(`payment/paymentmethod/default`, data).then(response => response.data);

export const deletePaymentMethod = (data: PaymentMethodDeleteData) =>
  http.post(`payment/paymentmethod/deletion`, data).then(response => response.data);

export const getPaymentAlerts = (customerId: number, locationId?: number) =>
  http
    .get(`payment/customer/${customerId}/alerts${locationId ? `?locationId=${locationId}` : ''}`)
    .then(response => response.data);

export const getPaymentFeeEstimation = (data: PaymentFeeEstimationData) =>
  http.post(`payment/fee/estimation`, data).then(response => response.data);

export const getHasProcessingFee = (customerId: number) =>
  http.get(`payment/isProcessingFeePassed?customerId=${customerId}`).then(response => response.data);
