import { PureComponent } from 'react';

import { InjectedFormProps, reduxForm } from 'redux-form';

import { ButtonSet, Button } from '../../../core/components/styled';
import { isRequired } from '../../../utils/services/validator';
import { TypedField } from '../../../core/components';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import translate from '../../../core/services/translate';
import VendorDropdown from '../VendorDropdown';

interface ComponentProps {
  onDefaultVendorChange: (id: number) => void;
  vendorList?: Vendor[];
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class SelectDefaultVendorForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onDefaultVendorChange, vendorList } = this.props;

    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <form onSubmit={handleSubmit} noValidate>
            <TypedField
              name="vendorId"
              component={VendorDropdown}
              validate={[isRequired]}
              props={{
                dropdownProps: {
                  margin: isMobile ? 'no' : 'xLarge no xxLarge',
                  id: 'select-default-vendor-field',
                },
                withPlaceholder: true,
                vendorList,
              }}
              onChange={onDefaultVendorChange as any}
            />

            <ButtonSet>
              <Button type="submit" color="primary" id="select-vendor-button">
                {translate('common.apply')}
              </Button>
            </ButtonSet>
          </form>
        )}
      />
    );
  }
}

export default reduxForm<any, ComponentProps>({
  form: 'selectDefaultVendorForm',
})(SelectDefaultVendorForm);
