import {
  CUSTOMER_SUBTYPE_TRUCK_YARD,
  CUSTOMER_SUBTYPE_CONTAINER_YARD,
  CUSTOMER_SUBTYPE_LANDFILL,
} from '../../common/constants';
import { SNOW_ROAD_CONDITIONS, STREET_SWEEPER_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';

const DEFAULT_FILTERS = {
  vehicles: {
    canCheckAll: true,
    checkAllFormName: 'allVehicleTypes',
    filters: [],
  },
  vehicleTracking: {
    filters: [
      {
        label: 'dashboard.showVehiclePositions',
        formName: 'vehicleTracking.filters.showVehiclePositions',
      },
      {
        label: 'dashboard.showVehicleTracking',
        formName: 'vehicleTracking.filters.showVehicleTracking',
        unCheckedByDefault: true,
      },
      {
        label: 'dashboard.showAppStatus',
        formName: 'vehicleTracking.filters.showAppStatus',
        unCheckedByDefault: true,
      },
    ],
  },
  vehicleInsightTypes: {
    canCheckAll: true,
    checkAllFormName: 'allVehicleInsightTypes',
    filters: [],
  },
  containerInsightTypes: {
    canCheckAll: true,
    checkAllFormName: 'allContainerServiceInsightTypes',
    filters: [
      {
        label: 'dashboard.scheduled',
        formName: 'containerServiceInsightTypes.filters.scheduled',
      },
      {
        label: 'dashboard.inProgress',
        formName: 'containerServiceInsightTypes.filters.inProgress',
      },
      {
        label: 'dashboard.completed',
        formName: 'containerServiceInsightTypes.filters.completed',
      },
      {
        label: 'dashboard.placedOnHold',
        formName: 'containerServiceInsightTypes.filters.placedOnHold',
      },
      {
        label: 'dashboard.cancelled',
        formName: 'containerServiceInsightTypes.filters.cancelled',
      },
      {
        label: 'dashboard.issuesReported',
        formName: 'containerServiceInsightTypes.issuesReported',
        canCheckAll: true,
        checkAllFormName: 'allContainerIssueInsightTypes',
        hasSubFilters: true,
        filters: [],
      },
    ],
  },
  cityInsightTypes: {
    canCheckAll: true,
    checkAllFormName: 'allCityInsightTypes',
    filters: [],
  },
  roadConditions: {
    filters: [
      {
        label: 'dashboard.showRoadConditions',
        formName: 'roadConditions.filters.showRoadConditions',
        unCheckedByDefault: true,
      },
    ],
  },
  snowRoadConditions: {
    filters: [
      {
        label: `dashboard.${SNOW_ROAD_CONDITIONS}`,
        formName: `${SNOW_ROAD_CONDITIONS}.showSnowRoadConditions`,
        unCheckedByDefault: false,
        hasSubFilters: true,
        canCheckAll: true,
        checkAllFormName: 'snowRoadConditions.allPriorities',
        filters: [
          {
            label: 'dashboard.priority1',
            formName: 'snowRoadConditions.priority1',
          },
          {
            label: 'dashboard.priority2',
            formName: 'snowRoadConditions.priority2',
          },
          {
            label: 'dashboard.priority3',
            formName: 'snowRoadConditions.priority3',
          },
          {
            label: 'dashboard.none',
            formName: 'snowRoadConditions.none',
          },
        ],
      },
    ],
  },
  streetSweeperConditions: {
    filters: [
      {
        label: `dashboard.${STREET_SWEEPER_CONDITIONS}`,
        formName: `${STREET_SWEEPER_CONDITIONS}.showStreetSweeperConditions`,
        unCheckedByDefault: false,
        hasSubFilters: true,
        canCheckAll: true,
        checkAllFormName: 'streetSweeperConditions.allPriorities',
        filters: [
          {
            label: 'dashboard.priority1',
            formName: 'streetSweeperConditions.priority1',
          },
          {
            label: 'dashboard.priority2',
            formName: 'streetSweeperConditions.priority2',
          },
          {
            label: 'dashboard.priority3',
            formName: 'streetSweeperConditions.priority3',
          },
          {
            label: 'dashboard.none',
            formName: 'streetSweeperConditions.none',
          },
        ],
      },
    ],
  },
  vendorLocationTypes: {
    canCheckAll: true,
    checkAllFormName: 'allVendorLocationTypes',
    filters: [
      {
        label: 'facilities.facilitySubTypes.truckYard',
        formName: `vendorLocationTypes.filters.vendorLocationType:${CUSTOMER_SUBTYPE_TRUCK_YARD}`,
      },
      {
        label: 'facilities.facilitySubTypes.containerYard',
        formName: `vendorLocationTypes.filters.vendorLocationType:${CUSTOMER_SUBTYPE_CONTAINER_YARD}`,
      },
      {
        label: 'facilities.facilitySubTypes.landfill',
        formName: `vendorLocationTypes.filters.vendorLocationType:${CUSTOMER_SUBTYPE_LANDFILL}`,
      },
    ],
  },
};

export default DEFAULT_FILTERS;
