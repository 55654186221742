import { FC } from 'react';
import { Field } from 'redux-form';

import { FormLabel, Grid, GridColumn, IconButtonIcon, Popover, Separator, Text } from '../../../core/components/styled';
import {
  hasSafeCharactersUrl,
  isIntlPhone,
  isInvalidEmail,
  isRequired,
  maxLength200,
} from 'src/utils/services/validator';
import { Input, InputFieldLabel, IntlPhoneInput, PopoverWrapper, Switch } from 'src/core/components';
import { TypedField } from 'src/core/components';
import translate from 'src/core/services/translate';

type Props = {};

const BulkyItemSchedulerGeneralSection: FC<Props> = () => {
  return (
    <>
      <GridColumn size="12/12" padding="no xSmall xSmall xSmall">
        <Text block size="xLarge" weight="medium" margin="no no xSmall no">
          {translate('vendors.bulkyItemScheduler.general')}
        </Text>
        <Grid centered multiLine padding="no xSmall small xSmall">
          <GridColumn size="4/12" padding="no no sMedium no">
            <TypedField
              name="vendorUrl"
              component={InputFieldLabel}
              props={{
                label: translate('vendors.bulkyItemScheduler.vendorUrl'),
                margin: 'small no no no',
                inlineLabel: translate('vendors.bulkyItemScheduler.bulkyItemSchedulerURL'),
                isInlineLabelFirst: true,
                disabled: false,
              }}
              validate={[isRequired, hasSafeCharactersUrl]}
            />
          </GridColumn>

          <GridColumn size="4/12" padding="no">
            <TypedField
              name="contactEmail"
              component={Input}
              props={{
                label: translate('vendors.bulkyItemScheduler.contactEmail'),
                margin: 'small no no no',
              }}
              validate={[isRequired, isInvalidEmail]}
            />
          </GridColumn>
          <GridColumn size="4/12" padding="small no no xSmall">
            <FormLabel noTransform size="medium">
              {translate('vendors.bulkyItemScheduler.contactPhone')}
            </FormLabel>
            <Field name="contactPhone" component={IntlPhoneInput} validate={[isRequired, isIntlPhone]} />
          </GridColumn>

          <GridColumn size="12/12" padding="xSmall xSmall no no" verticalAlign="flex-start">
            <TypedField
              name="validateFullAddress"
              component={Switch}
              props={{
                label: translate('vendors.bulkyItemScheduler.enableAddressValidation'),
                margin: 'no',
              }}
            />

            <PopoverWrapper
              triggerButton={<IconButtonIcon icon="info" color="primary" size="large" />}
              popoverContent={
                <Popover>
                  <Text margin="no" block>
                    {translate('vendors.bulkyItemScheduler.enableAddressValidationPopover')}
                  </Text>
                </Popover>
              }
              size="medium"
              margin="no no no small"
            />
          </GridColumn>

          <Separator color="grayLight" size={1} margin="sMedium no xSmall no" />

          <GridColumn size="12/12" padding="no">
            <TypedField
              name="validationErrorMessage"
              component={Input}
              props={{
                label: translate('vendors.bulkyItemScheduler.validationErrorMessage'),
                margin: 'small no no no',
                placeholder: translate('vendors.bulkyItemScheduler.customValidationErrorMessage'),
                raisedLabel: true,
              }}
              validate={[isRequired, maxLength200]}
            />
          </GridColumn>
        </Grid>
      </GridColumn>

      <Separator color="grayLight" size={2} margin="no xSmall sMedium xSmall" />
    </>
  );
};

export default BulkyItemSchedulerGeneralSection;
