import { useEffect, useMemo } from "react";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import { useEsriMap } from "./EsriMapContext";
import { useSelector } from "src/core/hooks/useSelector";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import { map as _map, size } from 'lodash-es';
import LabelClass from "@arcgis/core/layers/support/LabelClass";
import { AppState } from "src/store";

export const EsriGeoFences = () => {
    const { map, view } = useEsriMap();
    const geoFences = useSelector((state : AppState) => state.routes.geoFences.geoFences.geoFences);

    const geoFencesGeoJSON = useMemo(() => {
        const allGeoFencesGeoJSON = _map(geoFences, (geoFence) => {
            const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
            return {
                ...parsedGeoFenceJson,
                id: geoFence.id,
                properties: {
                    ...parsedGeoFenceJson?.properties,
                    name: geoFence.geoFenceName,
                },
            };
        });

        return allGeoFencesGeoJSON;
    }, [geoFences]);

    const layers = useMemo(() => {

        return geoFencesGeoJSON.map((geoJSON) => {
            const blob = new Blob([JSON.stringify(geoJSON)], {
                type: "application/json"
            });
            const url = URL.createObjectURL(blob);

            const renderer = new SimpleRenderer({
                symbol: new SimpleFillSymbol({
                    color: [0, 165, 153, .20], // Green color with 50% opacity
                    outline: {
                        color: [0, 165, 153, 1],
                        width: .5,
                    },
                }),
            });

            const labelClass = new LabelClass({
                labelExpressionInfo: { expression: "$feature.name" },
                symbol: {
                    type: "text",
                    color: [0, 165, 153, 1],
                    haloColor: "white",
                    haloSize: "1px",
                    font: {
                        size: "12px",
                        family: "sans-serif"
                    }
                },
                labelPlacement: "center-center"
            });

            return new GeoJSONLayer({
                url,
                renderer,
                labelingInfo: [labelClass],
                listMode: "hide"
            });
        });
    }, [geoFencesGeoJSON]);
    useEffect(() => {
     
        if (!map) return;
        if (!size(geoFencesGeoJSON)) return;

        layers.forEach(layer => {
            map.add(layer);
        });

        return () => {
            layers.forEach(layer => {
                map.remove(layer);
            });
        };
    }, [map, view, layers, geoFencesGeoJSON]);

    return null;
};