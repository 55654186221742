import { find } from 'lodash-es';
import { useMemo, useState } from 'react';

import { Icon } from 'src/core/components';
import confirm from 'src/core/services/confirm';
import { Grid, GridColumn, TableRow, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import translate from 'src/core/services/translate';
import RoutePlannerVehiclesAndDriversSearchForm from 'src/routes/components/forms/routePlanner/RoutePlannerVehiclesAndDriversSearchForm';
import { FilterableTableSectionTableRowWrapper } from 'src/routes/components/styled';
import { RoutePlannerRouteTemplate } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplate';
import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';
import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import PillAttribute from './PillAttribute';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';

interface RoutePlannerRouteBuilderTableRowProps extends RoutePlannerRouteTemplate {
  drivers: RoutePlannerRouteTemplateDriver[];
  vehicles: RoutePlannerRouteTemplateVehicle[];
  draggedDriver: number | null;
  draggedVehicle: number | null;
  routeIdToRemoveDriverOrVehicle: number | null;
  isViewOnlyOrSupport: boolean;
  onDragStartDriver: (driverId: number, routeId: number) => void;
  onDragStartVehicle: (vehicleId: number, routeId: number) => void;
  onDragEnd: () => void;
  onAssignDriver: (driverId: number, routeId: number, isUnassignedFrom?: number) => void;
  onAssignVehicle: (vehicleId: number, routeId: number, isUnassignedFrom?: number) => void;
  onUnassignDriver: (driverId: number, routeId: number, isSoftRemove?: boolean) => void;
  onUnassignVehicle: (vehicleId: number, routeId: number, isSoftRemove?: boolean) => void;
}

const RoutePlannerTemplateBuilderTableRow = ({
  routeTemplateId,
  routeTemplateName,
  vehicleId,
  vehicleTypeId,
  vehicleName,
  driverId,
  driverName,
  drivers,
  vehicles,
  draggedDriver,
  draggedVehicle,
  routeIdToRemoveDriverOrVehicle,
  isUpdating,
  isViewOnlyOrSupport,
  onDragStartDriver,
  onDragStartVehicle,
  onDragEnd,
  onAssignDriver,
  onAssignVehicle,
  onUnassignDriver,
  onUnassignVehicle,
}: RoutePlannerRouteBuilderTableRowProps) => {
  const [isReadyToDrop, setIsReadyToDrop] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const cellHeight = `${TABLE_ROW_HEIGHT_LARGE}px`;
  const hasDriverOrVehicle = !!(driverId || vehicleId);

  const vehiclesAndDriversOptions = useMemo(() => {
    const driversOptions = drivers.map((driver: RoutePlannerRouteTemplateDriver) => ({
      label: driver.driverName,
      value: driver.driverId,
      isDriver: true,
      isDoubleBooked: driver.routesTemplates.length > 1,
      isBooked: driver.routesTemplates.length > 0,
      disabled: !driver.routesTemplates.length,
    }));

    const vehiclesOptions = vehicles.map(vehicle => {
      const typeName = VEHICLE_TYPE_IDS[vehicle.vehicleTypeId]?.name;
      return {
        label: `${vehicle.vehicleName} • ${typeName}`,
        value: vehicle.vehicleId,
        isDriver: false,
        isDoubleBooked: vehicle.routesTemplates?.length > 1,
        isBooked: !!vehicle.routesTemplates?.length,
        disabled: !vehicle.routesTemplates?.length,
      };
    });

    return [
      { label: translate('drivers.drivers'), options: driversOptions },
      { label: translate('vehicles.vehicles'), options: vehiclesOptions },
    ];
  }, [drivers, vehicles]);

  const vehicleTypeName = VEHICLE_TYPE_IDS[vehicleTypeId]?.name;

  const onRemoveDriver = (isSoftRemove?: boolean) => {
    driverId && onUnassignDriver(driverId, routeTemplateId, isSoftRemove);
  };

  const onRemoveVehicle = (isSoftRemove?: boolean) => {
    vehicleId && onUnassignVehicle(vehicleId, routeTemplateId, isSoftRemove);
  };

  const onAssignDriverOrVehicle = async (id: number, isDriver: boolean, isUnassignedFrom?: number) => {
    if (isDriver)
      if (driverId) {
        const newDriver = find(drivers, { driverId: id });
        if (
          await confirm(
            translate('routes.planner.replaceDriverTemplate', {
              assignedDriver: driverName,
              newDriver: newDriver?.driverName,
            }),
          )
        ) {
          const isSoftRemove = true;
          onRemoveDriver(isSoftRemove);
          onAssignDriver(id, routeTemplateId, isUnassignedFrom);
        } else return;
      } else {
        onAssignDriver(id, routeTemplateId, isUnassignedFrom);
      }
    else if (vehicleId) {
      const newVehicle = find(vehicles, { vehicleId: id });
      if (
        await confirm(
          translate('routes.planner.replaceVehicleTemplate', {
            assignedVehicle: vehicleName,
            newVehicle: newVehicle?.vehicleName,
          }),
        )
      ) {
        const isSoftRemove = true;
        onRemoveVehicle(isSoftRemove);
        onAssignVehicle(id, routeTemplateId, isUnassignedFrom);
      } else return;
    } else {
      onAssignVehicle(id, routeTemplateId, isUnassignedFrom);
    }
  };

  const onDropDriverOrVehicle = () => {
    if (isReadyToDrop) {
      if (draggedDriver) {
        const isDriver = true;
        if (draggedDriver && routeIdToRemoveDriverOrVehicle)
          onAssignDriverOrVehicle(draggedDriver, isDriver, routeIdToRemoveDriverOrVehicle);
        else onAssignDriverOrVehicle(draggedDriver, isDriver);
      } else if (draggedVehicle) {
        const isDriver = false;
        if (draggedVehicle && routeIdToRemoveDriverOrVehicle)
          onAssignDriverOrVehicle(draggedVehicle, false, routeIdToRemoveDriverOrVehicle);
        else onAssignDriverOrVehicle(draggedVehicle, isDriver);
      }
      onDragEnd();
      setIsReadyToDrop(false);
    }
  };

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE} minHeight={cellHeight} padding="10px" backgroundColor="#f9f9f9">
      <FilterableTableSectionTableRowWrapper
        isReadyToDrop={isReadyToDrop}
        dropZone={isReadyToDrop}
        canPlanRoute={true}
        onDragEnter={event => {
          event.preventDefault();
          setIsReadyToDrop(true);
        }}
        onDragLeave={event => {
          event.preventDefault();
          setIsReadyToDrop(false);
        }}
        onDragOver={e => e.preventDefault()}
        onDrop={onDropDriverOrVehicle}
      >
        <Grid align="center">
          <GridColumn size={isViewOnlyOrSupport ? '6/12' : '3/12'} verticalAlign="center">
            <Text size="medium" weight="medium" title={routeTemplateName} singleLine>
              {routeTemplateName}
            </Text>
          </GridColumn>
          <GridColumn size="6/12" verticalAlign="center" isLoading={isUpdating}>
            {hasDriverOrVehicle ? (
              <>
                {driverName && driverId && (
                  <PillAttribute
                    title={driverName}
                    onRemoveAttribute={isViewOnlyOrSupport ? undefined : onRemoveDriver}
                    onDragStart={() => {
                      onDragStartDriver(driverId, routeTemplateId);
                      setIsDragging(true);
                    }}
                    onDragEnd={() => {
                      onDragEnd();
                      setIsDragging(false);
                    }}
                    showTooltip={!isDragging}
                    tooltipText={`${translate('drivers.driver')}: ${driverName}`}
                  />
                )}
                {vehicleName && vehicleId && (
                  <PillAttribute
                    title={vehicleName}
                    subtitle={vehicleTypeName}
                    onRemoveAttribute={isViewOnlyOrSupport ? undefined : onRemoveVehicle}
                    onDragStart={() => {
                      setIsDragging(true);
                      onDragStartVehicle(vehicleId, routeTemplateId);
                    }}
                    onDragEnd={() => {
                      onDragEnd();
                      setIsDragging(false);
                    }}
                    showTooltip={!isDragging}
                    tooltipText={`${translate('vehicles.vehicle')}: ${vehicleName} • ${vehicleTypeName}`}
                  />
                )}
              </>
            ) : !isViewOnlyOrSupport ? (
              <Box display="flex" alignItems="center">
                <Icon icon="dragAndDrop" width="25px" height="25px" customViewBox="0 0 21 18" color="#76768c" />
                <Text
                  size="small"
                  color="grayDarker"
                  block
                  title={translate('routes.planner.dragAndDropHint')}
                  padding="no no no small"
                  align="left"
                >
                  {translate('routes.planner.dragAndDropHint')}
                </Text>
              </Box>
            ) : null}
          </GridColumn>
          {!isViewOnlyOrSupport && (
            <GridColumn size="3/12" verticalAlign="center" padding="no">
              <RoutePlannerVehiclesAndDriversSearchForm
                onOptionCLicked={(o: any) => {
                  onAssignDriverOrVehicle(o.value, o.isDriver);
                }}
                options={vehiclesAndDriversOptions}
                form={`routePlannerRouteBuilderTableRow-${routeTemplateName}`}
              />
            </GridColumn>
          )}
        </Grid>
      </FilterableTableSectionTableRowWrapper>
    </TableRow>
  );
};

export default RoutePlannerTemplateBuilderTableRow;
