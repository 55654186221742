import { http } from '../../core/services/http';
import { VehicleTrackingSettings } from '../interfaces/VehicleTracking';

export const loadVehicleTrackingSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/vendorVehicleTrackingSettings`).then(response => response.data);

export const saveVehicleTrackingSettings = (vendorId: number, vehicleTrackingSettings: VehicleTrackingSettings) =>
  http.post(`/vendors/${vendorId}/vendorVehicleTrackingSettings`, vehicleTrackingSettings);

export const loadNavigationLineTypes = () => http.get(`/common/navigationLineTypes`).then(response => response.data);
