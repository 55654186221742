import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../../../contracts/ducks';
import { loadRouteImages } from '../../../../ducks';
import { PanelSectionLoading } from '../../../../../core/components/styled';
import { TypedResolver } from '../../../../../core/components';
import RouteImages from './RouteImages';

interface Props {
  loadRouteImages: DuckFunction<typeof loadRouteImages>;
  onLoadTab?: () => void;
  routeId: number;
  isSnowPlowRoute: boolean;
}

class RouteImagesResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { routeId, loadRouteImages } = this.props;
    return loadRouteImages(routeId);
  };

  render() {
    const { onLoadTab, isSnowPlowRoute } = this.props;

    return (
      <TypedResolver
        successComponent={RouteImages}
        successProps={{
          onLoadTab,
          isSnowPlowRoute,
        }}
        loadingComponent={PanelSectionLoading}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = { loadRouteImages };

export default connect(undefined, mapDispatchToProps)(RouteImagesResolver);
