import React, { Fragment } from 'react';
import RoadConditionGroup from './RoadConditionGroup';

type RoadCondition = any;

interface Props {
  roadConditions: RoadCondition[];
  mapZoom: number;
  mapBounds: any;
}

const RoadConditions: React.SFC<Props> = ({ roadConditions, mapZoom, mapBounds }) => (
  <Fragment>
    {roadConditions.map((roadCondition, index) => (
      <RoadConditionGroup key={index} coordinateGroup={roadCondition} mapZoom={mapZoom} mapBounds={mapBounds} />
    ))}
  </Fragment>
);

export default RoadConditions;
