import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { CollectionWaypoint } from '../interfaces/Services';
import doDeleteCollectionPoints from '../services/deleteCollectionPoints';

const START_DELETE = 'customers/service/pickupLocation/collectionWaypoint/START_DELETE';
const COMPLETE_DELETE = 'customers/service/pickupLocation/collectionWaypoint/COMPLETE_DELETE';
const FAIL_DELETE = 'customers/service/pickupLocation/collectionWaypoint/FAIL_DELETE';

interface State {
  isDeleting: boolean;
  collectionWaypoint: CollectionWaypoint[];
}

// Initial state
const initialState: State = {
  isDeleting: false,
  collectionWaypoint: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const collectionPointIndex = findIndex(state.collectionWaypoint, { id: action.id });
      return update(state, {
        collectionWaypoint: { $splice: [[collectionPointIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    default:
      return state;
  }
};

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (collectionPointIds?: number[]) => ({
  type: COMPLETE_DELETE,
  collectionPointIds,
});

const failDelete = () => ({ type: FAIL_DELETE });

export const deleteCollectionPoints = (vendorId?: number, collectionPointIds?: number[]) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteCollectionPointsPromise = doDeleteCollectionPoints(vendorId, collectionPointIds);
  deleteCollectionPointsPromise
    .then(() => dispatch(completeDelete(collectionPointIds)))
    .catch(() => dispatch(failDelete()));
  return deleteCollectionPointsPromise;
};
