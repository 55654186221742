import { map } from 'lodash-es';

import { http } from 'src/core/services/http';
import { ServiceHistoryData } from '../interfaces/ServiceHistory';

export const loadServiceHistoryData = (
  vendorId: number,
  routeId: number,
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get<ServiceHistoryData[]>(`${vendorId}/routes/${routeId}/routeDetail/alternativeFleet/history`, {
      params: { startDate, endDate },
    })
    .then(response => {
      return map(response.data, (serviceHistory, index) => {
        return { ...serviceHistory, id: index };
      });
    });
