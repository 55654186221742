import React, { PureComponent } from 'react';
import {
  Banner as BannerContainer,
  BannerLink,
  BannerNavigation,
  BannerNavigationItem,
  BannerClose,
  BannerCloseIcon,
} from './styled';
import { closeBanner, shouldShowBanner } from '../services/banner';

const MAX_STEPS = 5;
const TIMER_INTERVAL = 5000;

class Banner extends PureComponent<{}, { step: number; showBanner: boolean }> {
  readonly state = { step: 0, showBanner: shouldShowBanner() };
  timer?: number;

  componentDidMount() {
    this.initTimer();
  }

  componentWillUnmount() {
    this.cancelTimer();
  }

  initTimer = () => {
    window.clearInterval(this.timer);
    this.timer = window.setInterval(this.goToNextStep, TIMER_INTERVAL);
  };

  cancelTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  };

  resetTimer = () => {
    this.cancelTimer();
    this.initTimer();
  };

  goToStep = (step: number) => {
    this.setState({ step });
    this.resetTimer();
  };

  goToNextStep = () => {
    this.setState(({ step }) => ({
      step: step < MAX_STEPS - 1 ? step + 1 : 0,
    }));
  };

  closeBanner = () => {
    closeBanner();
    this.setState({ showBanner: false });
  };

  steps = [
    { number: 0, link: 'https://www.commercialcreditgroup.com/rubicon-financing' },
    { number: 1, link: 'https://www.rubicon.com/rubiconselect-ioa/' },
    { number: 2, link: 'https://www.rubicon.com/rubiconselect-biobag/' },
    { number: 3, link: 'https://www.rubicon.com/rubiconselect-ace/' },
    { number: 4, link: 'https://www.rubicon.com/rubiconselect-concorde/' },
  ];

  render() {
    const { step: currentStep, showBanner } = this.state;

    return showBanner ? (
      <BannerContainer step={currentStep}>
        {this.steps.map(step => {
          const stepNumber = step.number;

          return (
            <BannerLink
              key={stepNumber}
              isVisible={stepNumber === currentStep}
              target="_self"
              href={'/rubicon-select'}
            />
          );
        })}

        <BannerNavigation>
          {this.steps.map(step => {
            const stepNumber = step.number;

            return (
              <BannerNavigationItem
                key={stepNumber}
                isActive={stepNumber === currentStep}
                onClick={() => this.goToStep(stepNumber)}
              />
            );
          })}
        </BannerNavigation>
        <BannerClose onClick={this.closeBanner}>
          <BannerCloseIcon />
        </BannerClose>
      </BannerContainer>
    ) : null;
  }
}

export default Banner;
