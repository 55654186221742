import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { getVehicleTrackingCoordinateGroupCenter } from './utils';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehicleTracking } from 'src/routes/components/mapWithTimeline/Interfaces';
import RouteTimelineMapVehicleTrackingsGLPopup from './RouteTimelineMapVehicleTrackingsGLPopup';

export default function RouteTimelineMapVehicleTrackingsGLPopupResolver() {
  const dispatch = useDispatch();

  const { unitOfMeasure, vehicleTrackings } = useSelector(state => state.routes.routeMapVehicleData);
  const {
    routeMapDetails: { vehicleTrackings: routeMapVehicleTrackings },
  } = useSelector(state => state.routes.routeMapDetails);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.feature !== RouteMapFeature.vehicleTrackings ||
      selectedFeature.vehicleTrackingCoordinateGroupIndex === undefined
    ) {
      return undefined;
    }

    const { id: vehicleId, vehicleTrackingCoordinateGroupIndex: coordinateGroupIndex } = selectedFeature;

    const currentVehicleTrackings = !!vehicleTrackings.length
      ? vehicleTrackings
      : routeMapVehicleTrackings && !!routeMapVehicleTrackings.length
      ? routeMapVehicleTrackings
      : [];
    const vehicleTracking = currentVehicleTrackings.find(
      (vehicleTracking: VehicleTracking) => vehicleTracking.vehicle.id === vehicleId,
    );
    const coordinateGroup = vehicleTracking?.coordinateGroups[coordinateGroupIndex];

    if (!vehicleTracking || !coordinateGroup) {
      return undefined;
    }

    return {
      unitOfMeasure,
      vehicle: vehicleTracking.vehicle,
      coordinateGroup,
      anchor: selectedFeature.coordinates
        ? {
            latitude: selectedFeature.coordinates.lat,
            longitude: selectedFeature.coordinates.lng,
          }
        : getVehicleTrackingCoordinateGroupCenter(coordinateGroup),
    };
  }, [selectedFeature, vehicleTrackings, unitOfMeasure, routeMapVehicleTrackings]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.anchor.latitude}
      longitude={selectedTracking.anchor.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingCoordinateGroupIndex}`}
        successComponent={RouteTimelineMapVehicleTrackingsGLPopup}
        successProps={{
          applicationModeStatusId: selectedTracking.coordinateGroup.applicationModeStatusId,
          shouldDisplayDownloadVideo: false,
          speed: selectedTracking.coordinateGroup.speed,
          timestamp: selectedTracking.coordinateGroup.endTimestamp,
          unitOfMeasure: selectedTracking.unitOfMeasure,
          vehicleId: selectedTracking.vehicle.id,
          vehicleName: selectedTracking.vehicle.name,
        }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 240 }}
      />
    </MapGLPopupWrapper>
  );
}
