import moment from 'moment';

import { RawVehicleInspection, VehicleInspection } from '../interfaces/VehicleInspections';
import translate from '../../core/services/translate';

export const transformRawVehicleInspection = (inspection: RawVehicleInspection): VehicleInspection => ({
  ...inspection,
  routeDate: moment(inspection.routeDate).format('MM/DD/YYYY'),
  tripInspectionType:
    inspection.tripInspectionTypeId === 1
      ? translate('vehicles.vehicleInspections.preTrip')
      : translate('vehicles.vehicleInspections.postTrip'),
});

export const transformRawVehicleInspections = (inspections: RawVehicleInspection[]): VehicleInspection[] =>
  inspections.map(inspection => transformRawVehicleInspection(inspection));
