import { FieldArray } from 'redux-form';

import { Table } from 'src/core/components';
import { ModalSection, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TableCell } from 'src/core/components/Table';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { RateFormProps } from 'src/customers/interfaces/Services';
import ServicesFormTableRow from './ServicesFormTableRow';

const getTableHeight = (ratesLength: number): number =>
  Math.min(ratesLength * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1;

const ratesTableCellWidths = {
  rateTypeId: '18%',
  overridable: '11%',
  quantity: '10%',
  uomId: '14%',
  rate: '7%',
  status: '11%',
  effectiveFromDate: '14%',
  effectiveToDate: '14%',
  action: '1%',
};

const ratesTableCells: TableCell[] = [
  {
    name: 'rateCodeId',
    label: translate('finance.rateManager.rateCodeAndDescription'),
    width: ratesTableCellWidths.rateTypeId,
    sortable: false,
  },
  {
    name: 'overridable',
    label: translate('customers.serviceEditor.allowEditsOnBill'),
    width: ratesTableCellWidths.overridable,
    sortable: false,
  },
  {
    name: 'rate',
    label: translate('customers.serviceEditor.rate'),
    width: ratesTableCellWidths.rate,
    sortable: false,
  },
  {
    name: 'uomId',
    label: translate('common.unitOfMeasure'),
    width: ratesTableCellWidths.uomId,
    sortable: false,
  },
  {
    name: 'includedQty',
    label: translate('customers.serviceEditor.includedQuantity'),
    width: ratesTableCellWidths.quantity,
    sortable: false,
  },
  {
    name: 'effectiveFromDate',
    label: translate('customers.serviceEditor.effectiveFrom'),
    width: ratesTableCellWidths.effectiveFromDate,
    sortable: false,
  },
  {
    name: 'effectiveToDate',
    label: translate('customers.serviceEditor.effectiveTo'),
    width: ratesTableCellWidths.effectiveToDate,
    sortable: false,
  },
  {
    name: 'status',
    label: translate('common.status'),
    width: ratesTableCellWidths.status,
    sortable: false,
  },
  {
    name: 'action',
    label: '',
    width: ratesTableCellWidths.action,
    align: 'center',
    sortable: false,
    noPaddingRight: true,
  },
];

const ServiceTableForm = ({ customerName, location, updateFormValue }: RateFormProps) => (
  <>
    <ModalSection flex flexDirection="row" margin="medium auto" overflow="visible">
      <Box margin="no medium no no">
        <Text block size="small">
          {translate('finance.modal.customerName')}
        </Text>
        <Text block size="medium" margin="xSmall no no no">
          {customerName || '-'}
        </Text>
      </Box>

      <Box margin="no no no medium">
        <Text block size="small">
          {translate('finance.locationName')}
        </Text>
        <Text block size="medium" margin="xSmall no no no">
          {location || '-'}
        </Text>
      </Box>
    </ModalSection>

    <ModalSection overflow="visible">
      <Table cells={ratesTableCells} tableBodyProps={{ height: getTableHeight(6), noOverflow: true }}>
        <FieldArray
          name="rates"
          component={ServicesFormTableRow as any}
          updateFormValue={updateFormValue}
          cellWidths={ratesTableCellWidths}
          rerenderOnEveryChange
        />
      </Table>
    </ModalSection>
  </>
);

export default ServiceTableForm;
