import React, { Fragment } from 'react';

import { VehicleTracking } from '../../routes/components/mapWithTimeline/Interfaces';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';
import ApplicationModeChangesGroup from './ApplicationModeChangesGroup';
import VehicleTrackingGroup from './VehicleTrackingGroup';

interface Props {
  isOnDemandVideoDownloadEnabled?: boolean;
  mapBounds: any;
  mapZoom: number;
  openedInfoWindows: OpenedInfoWindows;
  showAppStatus: boolean;
  showVehicleTracking: boolean;
  toggleInfoWindow: (key: string, vehicleIndex: number, timestamp: string) => any;
  unitOfMeasure: string;
  vehicleTrackings: VehicleTracking[];
}

const VehicleTrackings: React.SFC<Props> = ({
  isOnDemandVideoDownloadEnabled,
  mapBounds,
  mapZoom,
  openedInfoWindows,
  showAppStatus,
  showVehicleTracking,
  toggleInfoWindow,
  unitOfMeasure,
  vehicleTrackings,
}) => (
  <Fragment>
    {vehicleTrackings.map((vehicleTracking, index) => (
      <Fragment key={index}>
        {showVehicleTracking && (
          <VehicleTrackingGroup
            coordinateGroups={vehicleTracking.coordinateGroups}
            mapBounds={mapBounds}
            mapZoom={mapZoom}
            openedInfoWindows={openedInfoWindows}
            shouldDisplayDownloadVideo={
              vehicleTracking.vehicle.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled
            }
            toggleInfoWindow={toggleInfoWindow}
            unitOfMeasure={unitOfMeasure}
            vehicleId={vehicleTracking.vehicle.id}
            vehicleIndex={index}
            vehicleName={vehicleTracking.vehicle.name}
          />
        )}
        {showAppStatus && (
          <ApplicationModeChangesGroup
            vehicleIndex={index}
            applicationModeChanges={vehicleTracking.applicationModeChanges}
            vehicleId={vehicleTracking.vehicle.id}
            vehicleName={vehicleTracking.vehicle.name}
            unitOfMeasure={unitOfMeasure}
            mapZoom={mapZoom}
            mapBounds={mapBounds}
            openedInfoWindows={openedInfoWindows}
            toggleInfoWindow={toggleInfoWindow}
          />
        )}
      </Fragment>
    ))}
  </Fragment>
);

export default VehicleTrackings;
