import styled from 'styled-components';

export const PopoverUnbilledCharges = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OverdueContainer = styled.div`
  display: inline-block;
  position: absolute;
  margin-top: -5px;
  margin-left: 10px;
`;
