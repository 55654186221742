import React, { PureComponent } from 'react';
import { map, size } from 'lodash-es';
import { Field } from 'redux-form';

import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';
import { INFERENCE_AUDIT_STATUSES } from '../constants';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';

const formatText = (selectedOptions: string[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('inferenceAudit.allInferenceAuditStatuses')
    : translate('inferenceAudit.xStatusesSelected', { selected: size(selectedOptions) });

class InferenceAuditStatusesSelect extends PureComponent<DropDownFieldProps> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  parse = (value: number) => {
    if (value) return value.toString();
    return value;
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;

    const inferenceAuditStatusesOptions = map(INFERENCE_AUDIT_STATUSES, inferenceAuditStatus => ({
      label: inferenceAuditStatus.name,
      value: inferenceAuditStatus.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('inferenceAudit.inferenceAuditStatus') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('inferenceAudit.selectStatus') : undefined)}
        component={Dropdown as any}
        options={inferenceAuditStatusesOptions}
        parse={this.parse}
        onChange={this.onChange}
        formatText={formatText}
        props={{
          isClearable: true,
        }}
        {...dropdownProps}
      />
    );
  }
}

export default InferenceAuditStatusesSelect;
