import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const UNSCHEDULED = 0;
export const SCHEDULED = 1;
export const IN_PROGRESS = 2;
export const COMPLETED = 3;
export const CANCELED = 4;
export const REASSIGNED = 5;
export const ISSUE_REPORTED = 6;
export const SERVICED = 7;
export const PLACED_ON_HOLD = 8;

export const PARTIALLY_SERVICED = 'Partially Serviced';

export const PICKUP_STATUSES_LIST = [
  { id: UNSCHEDULED, technicalName: 'unScheduled', name: translate('common.pickupStatuses.unScheduled') },
  { id: SCHEDULED, technicalName: 'Scheduled', name: translate('common.pickupStatuses.scheduled') },
  { id: IN_PROGRESS, technicalName: 'InProgress', name: translate('common.pickupStatuses.inProgress') },
  { id: COMPLETED, technicalName: 'Completed', name: translate('common.pickupStatuses.completed') },
  { id: CANCELED, technicalName: 'Cancelled', name: translate('common.pickupStatuses.canceled') },
  { id: REASSIGNED, technicalName: 'Reassigned', name: translate('common.pickupStatuses.reassigned') },
  { id: ISSUE_REPORTED, technicalName: 'IssueReported', name: translate('common.pickupStatuses.issueReported') },
  { id: SERVICED, technicalName: 'Serviced', name: translate('common.pickupStatuses.serviced') },
  { id: PLACED_ON_HOLD, technicalName: 'OnHold', name: translate('common.pickupStatuses.placedOnHold') },
];

export const PICKUP_STATUSES = mapKeys(PICKUP_STATUSES_LIST, 'id');
export const PICKUP_STATUSES_BY_TECHNICAL_NAME = mapKeys(PICKUP_STATUSES_LIST, 'technicalName');

export const SNOW_OR_SWEEPER_ROUTE_STATUSES = mapKeys(
  [
    { id: SCHEDULED, technicalName: 'Scheduled', name: translate('common.pickupStatuses.notServiced') },
    { id: IN_PROGRESS, technicalName: 'InProgress', name: translate('common.pickupStatuses.partiallyServiced') },
    { id: COMPLETED, technicalName: 'Completed', name: translate('common.pickupStatuses.serviced') },
  ],
  'id',
);

export const SNOW_OR_SWEEPER_ROUTE_LIST_STATUSES = mapKeys(
  [
    { id: SCHEDULED, technicalName: 'NotServiced', name: translate('common.pickupStatuses.notServiced') },
    { id: IN_PROGRESS, technicalName: 'PartiallyServiced', name: translate('common.pickupStatuses.partiallyServiced') },
    { id: COMPLETED, technicalName: 'Serviced', name: translate('common.pickupStatuses.serviced') },
  ],
  'id',
);
