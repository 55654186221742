import React, { PureComponent } from 'react';

import { change, Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { debounce, filter, map, size } from 'lodash-es';
import Cookie from 'js-cookie';
import moment from 'moment';

import { AppState } from '../../../store';
import { Button, Grid, GridColumn, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { INSIGHTS_COOKIE_DATE_KEY, INSIGHTS_COOKIE_START_DATE_KEY } from '../../constants';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import {
  ACTIVITY_BY_ACCOUNTING_CODE,
  AGING_REPORT,
  BILLING_REPORT,
  CART,
  COLLECTIONS_REPORT,
  COMMUNITY_PARTICIPATION,
  DAILY,
  DELIVERY_UTILITY_JOB_HISTORY,
  DRIVER_PRODUCTIVITY,
  DRIVER_SAFETY_INSPECTION,
  DRIVER_SUMMARY,
  EXCEPTIONS,
  FAULT_CODES,
  FUEL_LOG,
  LOCATION_ALERTS,
  MULTIPLE_DAY_WEB_PAYMENT_REPORT,
  NEW_SERVICES_AUDIT_REPORT,
  ODAKYU_DAILY_ROUTE_SUMMARY,
  ORPHAN_INVOICE_REPORT,
  PARTICIPATIONS,
  PAYMENT_REPORT,
  PRE_POST_TRIP_INSPECTION,
  RESIDENTIAL_STOP,
  ROLL_OF_SERVICE_HISTORY,
  ROLL_OFF_JOB_HISTORY,
  ROUTE_PRODUCTIVITY,
  SET_OUT,
  SINGLE_DAY_WEB_PAYMENT_REPORT,
  SNOW_ROUTE_SUMMARY,
  STREET_SWEEPING_ACTIVITY_SUMMARY,
  STREET_SWEEPING_OBSTACLES,
  UNSERVICED_RESIDENTIAL,
  Y_DEVICE_STATUS,
} from '../../constants/reportTypes';
import { DailyReportTypeDropdown, VehicleTypesForVendorMultiSelect } from '..';
import { DATE_RANGE_PICKER_30_60_90, DATE_RANGE_PICKER_TODAY_3_7_PAST, TODAY, YESTERDAY } from '../../../core/constants/weekdays';
import { DatePicker, DateRangePicker, MultiSelect, TypeAhead } from '../../../core/components';
import { DateRangeOptionValue } from '../../../core/components/DateRangePicker';
import { DuckFunction } from '../../../contracts/ducks';

import { loadServiceTypes, loadWasteTypes } from '../../../common/ducks';
import { ROUTE } from '../../../fleet/constants';
import translate from '../../../core/services/translate';
import { getExcludedFiltersIds, getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import loadRoutes from '../../services/loadRoutes';
import { getExcludeVehicleFilters } from 'src/common/utils/filters';
import { FILTER_SERVICE_TYPE_ID, SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants';
import { ACTIVE, NEW, ON_HOLD, SUSPENDED, CLOSED } from 'src/common/constants/accountStatuses';
import { BUSINESS_TYPE_ID, STREET_TYPE_ID } from 'src/customers/constants/customerTypes';
import { MAX_LIMIT_PER_PAGE } from 'src/core/constants/pagination';
import { Customer } from 'src/customers/interfaces/Customers';
import { loadCustomersSimplified } from 'src/customers/ducks';

const getMultiSelectText = (
  selectedOptions: string[],
  allOptionsSelected: boolean,
  allText: string,
  seletedText: string,
  textTranslation?: string,
) => {
  if (allOptionsSelected) {
    return translate(allText);
  }

  return size(selectedOptions) === 1
    ? textTranslation
      ? translate(createTranslationKey(selectedOptions[0], textTranslation))
      : selectedOptions[0]
    : translate(seletedText, { selected: size(selectedOptions) });
};

const getVehiclesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(selectedOptions, allOptionsSelected, 'vehicles.allVehicles', 'vehicles.xVehiclesSelected');
};

const getWasteTypesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(
    selectedOptions,
    allOptionsSelected,
    'insights.allWasteTypes',
    'insights.xWasteTypesSelected',
  );
};

const getServiceTypesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(
    selectedOptions,
    allOptionsSelected,
    'insights.allServiceTypes',
    'insights.xServiceTypesSelected',
  );
};

const getVehicleTypesText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(
    selectedOptions,
    allOptionsSelected,
    'vehicles.allVehicleTypes',
    'vehicles.xVehicleTypesSelected',
    'vehicles.vehicleTypes',
  );
};

const getDriversText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(selectedOptions, allOptionsSelected, 'drivers.allDrivers', 'drivers.xDriversSelected');
};

const getRoutesNameText = (selectedOptions: string[], allOptionsSelected: boolean) => {
  return getMultiSelectText(selectedOptions, allOptionsSelected, 'insights.allRoutes', 'insights.xRoutesSelected');
};

const DATE_RANGE_6_DAYS = 6;
const DATE_RANGE_30_DAYS = 30;
const DATE_RANGE_90_DAYS = 90;
const DATE_RANGE_185_DAYS = 185;

interface Props {
  change: any;
  drivers: any[];
  excludeVehicleFilters: string[];
  excludeServiceTypes: number[];
  formValues?: any;
  isExporting?: boolean;
  loadServiceTypes: DuckFunction<typeof loadServiceTypes>;
  loadCustomersSimplified: DuckFunction<typeof loadCustomersSimplified>;
  loadRoutes: (
    vendorId: number,
    reportType?: string,
    vehicleType?: string,
    vehicleName?: string,
    routeName?: string,
    driverName?: string,
    fromDate?: string,
    to?: string,
    vehicleTypeIdsCSV?: string,
    serviceZoneIdsCSV?: string,
    supervisorIdsCSV?: string,
  ) => {};
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  serviceTypes: any[];
  vehicleFiltersPreferencesName: string[];
  vehicles: any[];
  vendorId: number;
  wasteTypes: any[];
}

interface State {
  routeNames: any[];
}

type ComponentProps = Props & InjectedFormProps<any, Props>;

class ReportsForm extends PureComponent<ComponentProps, State> {
  readonly state: State = {
    routeNames: [],
  };

  setDateRangeValue = (change: any, from?: string, to?: string) => {
    change('dateRange', {
      from,
      to,
    });
  };

  onReportTypeChange = (isDateDisabled: boolean, reportType: string) => {
    const { change, formValues, loadServiceTypes, loadWasteTypes, vendorId } = this.props;

    this.resetFormFields();

    if (reportType === DRIVER_PRODUCTIVITY || reportType === ROUTE_PRODUCTIVITY) {
      loadServiceTypes(formValues.vehicleType);
      loadWasteTypes();
      loadRoutes(vendorId, reportType, '', '', '', '', formValues.dateRange.from, formValues.dateRange.to).then(
        data => {
          this.setState({ routeNames: data });
        },
      );
    }

    if (isDateDisabled) {
      change('startDate', YESTERDAY);
    } else {
      const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
      const insightsStartDate = Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY);

      if (formValues && formValues.dateRange) {
        const {
          dateRange: { from, to },
        } = formValues;
        let newToDate;
        const dateDiff = moment(to, 'MM/DD/YYYY').diff(moment(from, 'MM/DD/YYYY'), 'days');
        const addDiff = (diff: number) => moment(from, 'MM/DD/YYYY').add(diff, 'days').format('MM/DD/YYYY');

        if (reportType === STREET_SWEEPING_OBSTACLES || reportType === STREET_SWEEPING_ACTIVITY_SUMMARY) {
          if (dateDiff > DATE_RANGE_6_DAYS) {
            newToDate = addDiff(DATE_RANGE_6_DAYS);
            this.setDateRangeValue(change, insightsStartDate || insightsDate, newToDate);
          }
        } else if (reportType === EXCEPTIONS) {
          if (dateDiff > DATE_RANGE_30_DAYS) {
            newToDate = addDiff(DATE_RANGE_30_DAYS);
            this.setDateRangeValue(change, insightsStartDate || insightsDate, newToDate);
          }
        } else if (
          reportType === COMMUNITY_PARTICIPATION ||
          reportType === DELIVERY_UTILITY_JOB_HISTORY ||
          reportType === DRIVER_SUMMARY ||
          reportType === LOCATION_ALERTS ||
          reportType === ROLL_OFF_JOB_HISTORY ||
          reportType === Y_DEVICE_STATUS
        ) {
          if (reportType === DRIVER_SUMMARY && !insightsDate && !insightsStartDate && to === from) {
            // there was no date set, so we set a default here
            newToDate = addDiff(DATE_RANGE_6_DAYS);
            const newFrom = moment(from, 'MM/DD/YYYY').subtract(DATE_RANGE_6_DAYS, 'days').format('MM/DD/YYYY');
            this.setDateRangeValue(change, newFrom, to);
          }
          if (dateDiff > DATE_RANGE_90_DAYS) {
            newToDate = addDiff(DATE_RANGE_90_DAYS);
            this.setDateRangeValue(change, insightsStartDate || insightsDate, newToDate);
          }
        } else {
          this.setDateRangeValue(change, insightsStartDate || insightsDate || YESTERDAY, insightsDate || YESTERDAY);
        }
      }

      change('startDate', insightsDate || YESTERDAY);
    }
  };

  onVehicleTypeChange = (value: string) => {
    const { vendorId, formValues } = this.props;
    this.setState({ routeNames: [] });

    loadRoutes(
      vendorId,
      formValues.reportType,
      value.toString(),
      formValues.vehicleName,
      '',
      formValues.driverName,
      formValues.dateRange.from,
      formValues.dateRange.to,
    ).then(data => {
      this.setState({ routeNames: data });
    });
  };

  onDriverNameChange = (event: any) => {
    const { vendorId, formValues } = this.props;
    this.setState({ routeNames: [] });

    loadRoutes(
      vendorId,
      formValues.reportType,
      formValues.vehicleType,
      formValues.vehicleName,
      '',
      event.toString(),
      formValues.dateRange.from,
      formValues.dateRange.to,
    ).then(data => {
      this.setState({ routeNames: data });
    });
  };

  resetFormFields = () => {
    const { change } = this.props;

    change('vehicleType', undefined);
    change('vehicleName', undefined);
    change('driverName', undefined);
    change('routeName', undefined);
    change('wasteMaterialType', undefined);
    change('serviceType', undefined);
  };

  onDateChanged = (event: any, { from, to }: DateRangeOptionValue) => {
    const eventIsValid = moment(event, 'MM/DD/YYYY', true).isValid();
    const fromIsValid = moment(from, 'MM/DD/YYYY', true).isValid();
    const toIsValid = moment(to, 'MM/DD/YYYY', true).isValid();

    if (eventIsValid || fromIsValid) Cookie.set(INSIGHTS_COOKIE_START_DATE_KEY, from && fromIsValid ? from : event);
    if (eventIsValid || toIsValid) Cookie.set(INSIGHTS_COOKIE_DATE_KEY, to && toIsValid ? to : event);
  };

  onSingleDateChanged = (event: any) => {
    const eventIsValid = moment(event, 'MM/DD/YYYY', true).isValid();

    if (eventIsValid) {
      Cookie.set(INSIGHTS_COOKIE_START_DATE_KEY, event);
      Cookie.set(INSIGHTS_COOKIE_DATE_KEY, event);
    }
  };

  loadRoutesOptions = debounce((routeName, onOptionsLoaded) => {
    if (routeName.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    const {
      dateRange: { from, to },
      driverName,
      reportType,
      startDate,
      vehicleName,
      vehicleType,
    } = this.props.formValues;
    const { vendorId } = this.props;

    const isSingleDateDisplayed =
      reportType === AGING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === DAILY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === Y_DEVICE_STATUS;

    loadRoutes(
      vendorId,
      reportType,
      vehicleType,
      vehicleName,
      routeName,
      driverName,
      isSingleDateDisplayed ? startDate : from,
      isSingleDateDisplayed ? startDate : to,
    ).then(onOptionsLoaded);
  }, 500);

  loadCustomersOptions = debounce((searchTerm, onOptionsLoaded) => {
    const customerTypeIds = `${ACTIVE}, ${NEW}, ${SUSPENDED}, ${ON_HOLD}, ${CLOSED},`;
    const customerStatusTypeIds = `${BUSINESS_TYPE_ID}, ${STREET_TYPE_ID}`;
    const sortedBy = 'name';
    const sortOrder = 'asc';
    const { loadCustomersSimplified, vendorId } = this.props;

    if (!vendorId || searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page: 1,
      limit: MAX_LIMIT_PER_PAGE,
      sortedBy,
      sortOrder,
      searchType: SEARCH_BY_NAME_OR_ADDRESS,
    }).then(
      res =>
        res &&
        onOptionsLoaded(
          res.customers.map((customer: Customer) => {
            return { value: customer.id, label: customer.name };
          }),
        ),
    );
  }, 500);

  loadCustomerLocationOptions = debounce((searchTerm, onOptionsLoaded) => {
    const customerTypeIds = undefined;
    const customerStatusTypeIds = undefined;
    const page = undefined;
    const limit = undefined;
    const sortedBy = undefined;
    const { loadCustomersSimplified, vendorId, formValues } = this.props;
    const { customerId } = formValues;

    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page,
      limit,
      sortedBy,
      searchType: SEARCH_BY_NAME_OR_ADDRESS,
    }).then(res => {
      const customerLocations = map(
        filter(res.customers, (customer: Customer) => customer.id === customerId),
        (customer: Customer) =>
          map(customer.locations, location => ({
            value: { locationId: location.id, locationName: location.name },
            label: location.name,
          })),
      );

      return onOptionsLoaded(...customerLocations);
    });
  }, 500);

  render() {
    const {
      drivers,
      excludeVehicleFilters,
      excludeServiceTypes,
      formValues,
      handleSubmit,
      isExporting,
      serviceTypes,
      vehicleFiltersPreferencesName,
      vehicles,
      wasteTypes,
    } = this.props;
    const { reportType, to, customerId } = formValues;
    const { routeNames } = this.state;

    const endDateAsDate = to && moment(to, 'MM/DD/YYYY').toDate();

    const vehicleOptions = vehicles.map(({ regplate }) => ({
      label: regplate,
      value: regplate,
    }));

    const wasteTypesOptions = wasteTypes.map(({ name, technicalName }) => ({
      label: name,
      value: technicalName,
    }));

    const serviceTypesOptions = map(
      filter(serviceTypes, serviceType => !excludeServiceTypes.includes(serviceType.id)),
      serviceType => ({
        label: serviceType.name,
        value: serviceType.technicalName,
      }),
    );

    const driversOptions = drivers.map(({ name }) => ({
      label: name,
      value: name,
    }));

    const routeNameOptions = routeNames.map(({ label, value }) => ({
      label: label,
      value: value,
    }));

    const isVehicleTypesDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === CART ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === EXCEPTIONS ||
      reportType === FUEL_LOG ||
      reportType === LOCATION_ALERTS ||
      reportType === ODAKYU_DAILY_ROUTE_SUMMARY ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PARTICIPATIONS ||
      reportType === PAYMENT_REPORT ||
      reportType === RESIDENTIAL_STOP ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === SET_OUT ||
      reportType === SNOW_ROUTE_SUMMARY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === Y_DEVICE_STATUS;

    const isVehiclesDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === CART ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === DRIVER_PRODUCTIVITY ||
      reportType === DRIVER_SUMMARY ||
      reportType === EXCEPTIONS ||
      reportType === LOCATION_ALERTS ||
      reportType === ODAKYU_DAILY_ROUTE_SUMMARY ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === ROUTE_PRODUCTIVITY ||
      reportType === Y_DEVICE_STATUS;

    const isDriversDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === CART ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === EXCEPTIONS ||
      reportType === FAULT_CODES ||
      reportType === FUEL_LOG ||
      reportType === LOCATION_ALERTS ||
      reportType === ODAKYU_DAILY_ROUTE_SUMMARY ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === RESIDENTIAL_STOP ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === ROUTE_PRODUCTIVITY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === Y_DEVICE_STATUS;

    const isRouteDisabled =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === DRIVER_SUMMARY ||
      reportType === EXCEPTIONS ||
      reportType === FAULT_CODES ||
      reportType === FUEL_LOG ||
      reportType === LOCATION_ALERTS ||
      reportType === ODAKYU_DAILY_ROUTE_SUMMARY ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === RESIDENTIAL_STOP ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === Y_DEVICE_STATUS;

    const isDateDisabled = reportType === ROLL_OF_SERVICE_HISTORY || reportType === Y_DEVICE_STATUS;
    const isDateRangeHidden = reportType === LOCATION_ALERTS;

    const isSingleDateDisplayed =
      reportType === AGING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === DAILY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === Y_DEVICE_STATUS;

    const isDataForBillingReportsDisplayed =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT;

    const isOnlyDateRangeFilter =
      reportType === ACTIVITY_BY_ACCOUNTING_CODE ||
      reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT ||
      reportType === NEW_SERVICES_AUDIT_REPORT ||
      reportType === SINGLE_DAY_WEB_PAYMENT_REPORT;

    const isWasteTypeVisible = reportType === DRIVER_PRODUCTIVITY || reportType === ROUTE_PRODUCTIVITY;
    const isRouteNameMultiSelect = reportType === DRIVER_PRODUCTIVITY || reportType === ROUTE_PRODUCTIVITY;

    const isServiceTypeVisible = reportType === ROUTE_PRODUCTIVITY;

    let dateRangeMaxInterval;
    if (
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === SINGLE_DAY_WEB_PAYMENT_REPORT
    ) {
      dateRangeMaxInterval = { amount: DATE_RANGE_6_DAYS, unit: 'days' };
    } else if (reportType === EXCEPTIONS || reportType === MULTIPLE_DAY_WEB_PAYMENT_REPORT) {
      dateRangeMaxInterval = { amount: DATE_RANGE_30_DAYS, unit: 'days' };
    } else if (
      reportType === COMMUNITY_PARTICIPATION ||
      reportType === DELIVERY_UTILITY_JOB_HISTORY ||
      reportType === DRIVER_SUMMARY ||
      reportType === LOCATION_ALERTS ||
      reportType === ROLL_OFF_JOB_HISTORY ||
      reportType === NEW_SERVICES_AUDIT_REPORT
    ) {
      dateRangeMaxInterval = { amount: DATE_RANGE_90_DAYS, unit: 'days' };
    } else if ( reportType === ACTIVITY_BY_ACCOUNTING_CODE ) {
      dateRangeMaxInterval = { amount: DATE_RANGE_185_DAYS, unit: 'days' };
    } else {
      dateRangeMaxInterval = undefined;
    }

    let dateRangeDisabledDays;
    if (reportType === DRIVER_PRODUCTIVITY || reportType === ROUTE_PRODUCTIVITY) {
      dateRangeDisabledDays = {
        before: moment().subtract(90, 'days').toDate(),
        after: moment().add(90, 'days').toDate(),
      };
    } else {
      dateRangeDisabledDays = {
        after: TODAY,
      };
    }

    if (isOnlyDateRangeFilter) {
      return (
        <form onSubmit={handleSubmit}>
        <PanelSectionGroup isLoading={isExporting}>
          <PanelSection padding="small xSmall">
            <Grid>
              <GridColumn size="3/12">
                <Field
                  name="reportType"
                  component={DailyReportTypeDropdown}
                  placeholder={translate('insights.report')}
                  validate={[isRequired]}
                  dropdownProps={{ margin: 'no', id: 'insights-report-type' }}
                  onChange={(repType: any) => this.onReportTypeChange(isDateDisabled, repType)}
                />
              </GridColumn>

              <GridColumn size="3/12">
                {!isDateRangeHidden && (
                  <Field
                    name="dateRange"
                    component={DateRangePicker as any}
                    tabletAlignLeft
                    options={reportType === NEW_SERVICES_AUDIT_REPORT
                      ? DATE_RANGE_PICKER_30_60_90
                      : DATE_RANGE_PICKER_TODAY_3_7_PAST
                    }
                    disabledDays={[dateRangeDisabledDays]}
                    maxInterval={dateRangeMaxInterval}
                    reportType={reportType}
                    isResetRangeVisible
                    margin="no"
                    disabled={isDateDisabled}
                    onChange={this.onDateChanged}
                    props={{ id: 'insights-report-date-range-picker', hasMarginLeft: 'smaller', totalWidth: '100%' }}
                  />
                )}
              </GridColumn>

              <GridColumn
                size={isDataForBillingReportsDisplayed ? '2/12' : isSingleDateDisplayed ? '7/12' : '6/12'}
                align="right"
              >
                <Button color="primary">{translate('common.export')}</Button>
              </GridColumn>
            </Grid>
          </PanelSection>
        </PanelSectionGroup>
      </form>
      )
    }

    return (
      <form onSubmit={handleSubmit}>
        <PanelSectionGroup isLoading={isExporting}>
          <PanelSection padding="small xSmall">
            <Grid>
              <GridColumn size="3/12">
                <Field
                  name="reportType"
                  component={DailyReportTypeDropdown}
                  placeholder={translate('insights.report')}
                  validate={[isRequired]}
                  dropdownProps={{ margin: 'no', id: 'insights-report-type' }}
                  onChange={(repType: any) => this.onReportTypeChange(isDateDisabled, repType)}
                />
              </GridColumn>

              <GridColumn size="3/12">
                <Field
                  name="vehicleType"
                  component={VehicleTypesForVendorMultiSelect}
                  reportType={reportType}
                  multiSelectProps={{
                    placeholder: translate('vehicles.allVehicleTypes'),
                    margin: 'no',
                    formatText: getVehicleTypesText,
                    onChange: this.onVehicleTypeChange,
                  }}
                  withTechnicalName
                  vehicleRoleTypeId={ROUTE}
                  disabled={isVehicleTypesDisabled}
                  excludeVehicleTypes={excludeVehicleFilters}
                  vehicleFiltersPreferencesName={vehicleFiltersPreferencesName}
                />
              </GridColumn>

              <GridColumn size="3/12">
                <Field
                  name="vehicleName"
                  component={MultiSelect}
                  options={vehicleOptions}
                  placeholder={translate('vehicles.allVehicles')}
                  margin="no"
                  isSearchable
                  formatText={getVehiclesText}
                  disabled={isVehiclesDisabled}
                />
              </GridColumn>

              <GridColumn size="3/12">
                {isRouteNameMultiSelect ? (
                  <Field
                    name="routeName"
                    component={MultiSelect}
                    options={routeNameOptions}
                    placeholder={translate('routes.route')}
                    margin="no"
                    isSearchable
                    formatText={getRoutesNameText}
                  />
                ) : (
                  <Field
                    name="routeName"
                    component={TypeAhead}
                    placeholder={translate('routes.route')}
                    getOptions={this.loadRoutesOptions}
                    margin="no"
                    isClearable
                    disabled={isRouteDisabled}
                    inputValue={isRouteDisabled ? null : undefined}
                  />
                )}
              </GridColumn>
            </Grid>
          </PanelSection>

          <PanelSection padding="small xSmall">
            <Grid>
              <GridColumn size="3/12">
                <Field
                  name="driverName"
                  placeholder={translate('drivers.allDrivers')}
                  component={MultiSelect}
                  options={isDriversDisabled ? [] : driversOptions}
                  margin="no"
                  isSearchable
                  formatText={getDriversText}
                  disabled={isDriversDisabled}
                  onChange={this.onDriverNameChange}
                />
              </GridColumn>
              {isWasteTypeVisible && (
                <GridColumn size={isServiceTypeVisible ? '2/12' : '3/12'}>
                  <Field
                    name="wasteMaterialType"
                    component={MultiSelect}
                    options={wasteTypesOptions}
                    placeholder={translate('common.wasteTypesTitle')}
                    margin="no"
                    isSearchable
                    formatText={getWasteTypesText}
                  />
                </GridColumn>
              )}
              {isServiceTypeVisible && (
                <GridColumn size="2/12">
                  <Field
                    name="serviceType"
                    component={MultiSelect}
                    options={serviceTypesOptions}
                    placeholder={translate('common.serviceTypesTitle')}
                    margin="no"
                    isSearchable
                    formatText={getServiceTypesText}
                  />
                </GridColumn>
              )}

              {isDataForBillingReportsDisplayed && (
                <>
                  <GridColumn size="3/12">
                    <Field
                      name="customerId"
                      component={TypeAhead}
                      getOptions={this.loadCustomersOptions}
                      placeholder={translate('customers.customers')}
                      isClearable={false}
                      margin="no"
                    />
                  </GridColumn>
                  <GridColumn size="3/12">
                    <Field
                      name="customerLocationId"
                      component={TypeAhead}
                      getOptions={this.loadCustomerLocationOptions}
                      placeholder={translate('customers.allLocations')}
                      isClearable={true}
                      margin="no"
                      disabled={!customerId}
                    />
                  </GridColumn>
                </>
              )}

              {isSingleDateDisplayed ? (
                <GridColumn size="2/12">
                  <Field
                    name="startDate"
                    component={DatePicker as any}
                    tabletAlignLeft
                    placeholder={translate('common.from')}
                    disabledDays={[{ after: endDateAsDate || TODAY }]}
                    margin="no"
                    validate={[isRequired, isDateValidValidator]}
                    disabled={isDateDisabled}
                    onChange={this.onSingleDateChanged}
                  />
                </GridColumn>
              ) : (
                <GridColumn size="3/12">
                  {!isDateRangeHidden && (
                    <Field
                      name="dateRange"
                      component={DateRangePicker as any}
                      tabletAlignLeft
                      options={DATE_RANGE_PICKER_TODAY_3_7_PAST}
                      disabledDays={[dateRangeDisabledDays]}
                      maxInterval={dateRangeMaxInterval}
                      reportType={reportType}
                      isResetRangeVisible
                      margin="no"
                      disabled={isDateDisabled}
                      onChange={this.onDateChanged}
                      props={{ id: 'insights-report-date-range-picker', hasMarginLeft: 'smaller', totalWidth: '100%' }}
                    />
                  )}
                </GridColumn>
              )}

              <GridColumn
                size={isDataForBillingReportsDisplayed ? '2/12' : isSingleDateDisplayed ? '7/12' : '6/12'}
                align="right"
              >
                <Button color="primary">{translate('common.export')}</Button>
              </GridColumn>
            </Grid>
          </PanelSection>
        </PanelSectionGroup>
      </form>
    );
  }
}

const mapDispatchToProps = {
  change,
  loadServiceTypes,
  loadWasteTypes,
  loadCustomersSimplified,
};

const mapStateToProps = (state: AppState) => {
  const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
  const insightsStartDate = Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY);

  const { filters } = state.common.filters;
  const vehicleFiltersPreferencesIds = getVehicleFiltersPreferencesIds(filters);
  const vehicleFiltersPreferencesName = vehicleFiltersPreferencesIds.map(
    vehicleId => VEHICLE_TYPE_IDS[vehicleId].technicalName,
  );

  return {
    drivers: state.fleet.drivers.drivers,
    excludeVehicleFilters: getExcludeVehicleFilters(filters),
    excludeServiceTypes: getExcludedFiltersIds(
      state.common.serviceTypes.serviceTypes || [],
      filters,
      FILTER_SERVICE_TYPE_ID,
    ),
    formValues: getFormValues('reports')(state) || {},
    isExporting: state.insights.reports.isExporting,
    initialValues: {
      dateRange: {
        from: insightsStartDate || insightsDate || YESTERDAY,
        to: insightsDate || YESTERDAY,
      },
      startDate: insightsDate || YESTERDAY,
      vehicleType: vehicleFiltersPreferencesName,
    },
    serviceTypes: state.common.serviceTypes.serviceTypes || [],
    vehicles: state.fleet.vehicles.vehicles,
    vehicleFiltersPreferencesName,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    wasteTypes: state.common.wasteTypes.wasteTypes || [],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'reports',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(ReportsForm),
);
