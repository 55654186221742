import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { IconButtonIcon, Text } from 'src/core/components/styled';
import { setMapDrawingInstructionsOpen } from 'src/routes/ducks/routeTemplateBuilder';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DrawingInstructionsContainer } from 'src/routes/components/styled/RouteTemplateBuilderMap';

export const ServiceAreaMapDrawingInstructions: FC = () => {
  const dispatch = useDispatch();
  const mapDrawingInstructionsOpen = useSelector(state => state.routes.routeTemplateBuilder.mapDrawingInstructionsOpen);

  return (
    <DrawingInstructionsContainer
      isOpen={mapDrawingInstructionsOpen}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();

        if (!mapDrawingInstructionsOpen) {
          dispatch(setMapDrawingInstructionsOpen(true));
        }
      }}
    >
      {mapDrawingInstructionsOpen ? (
        <>
          <div onClick={() => dispatch(setMapDrawingInstructionsOpen(false))}>
            <IconButtonIcon icon="xMark" margin="no no xSmall" />
          </div>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.clickToStart')}
          </Text>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.dragPoint')}
          </Text>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.saveServiceArea')}
          </Text>
        </>
      ) : (
        <IconButtonIcon icon="info" margin="no" />
      )}
    </DrawingInstructionsContainer>
  );
};
