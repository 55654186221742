import React, { PureComponent, Fragment } from 'react';

import { WEIGHT_MEASUREMENT_UNITS } from '../../../common/constants';
import { TableCell, TableRow, Text } from '../../../core/components/styled';
import { ThumbnailSet } from '../../../core/components';
import { ROLL_OFF_ID } from '../../../fleet/constants';
import { date, time } from '../../../utils/services/formatter';
import { WeightTicketImagesModal } from '../../../routes/components/modals';

interface Props {
  activityTimeLocal?: string;
  customerLocationAddress?: string;
  customerName?: string;
  disposalSiteAddress?: string;
  disposalSiteName: string;
  driverName?: string;
  image?: any;
  ticketNumber: string;
  unitOfMeasureTypeId: number;
  vehicleName?: string;
  vehicleTypeId?: number;
  weight: number;
}

class WeightTicketsTableRow extends PureComponent<Props, { isWeightTicketImageModalOpen: boolean }> {
  state = {
    isWeightTicketImageModalOpen: false,
  };

  openWeightTicketImageModal = (event: any) => {
    event.stopPropagation();
    this.setState({ isWeightTicketImageModalOpen: true });
  };

  closeWeightTicketImageModal = () => {
    this.setState({ isWeightTicketImageModalOpen: false });
  };

  render() {
    const {
      activityTimeLocal,
      customerLocationAddress,
      customerName,
      disposalSiteAddress,
      disposalSiteName,
      driverName,
      image,
      ticketNumber,
      unitOfMeasureTypeId,
      vehicleName,
      vehicleTypeId,
      weight,
    } = this.props;
    const { isWeightTicketImageModalOpen } = this.state;
    const tableCellWidths =
      vehicleTypeId === ROLL_OFF_ID
        ? ['11%', '17%', '17%', '10%', '8%', '13%', '14%', '10%']
        : ['11%', '25%', '16%', '11%', '13%', '13%', undefined, '11%'];
    const weightTicket = { ticketNumber, disposalSiteAddress, image, customerName };

    return (
      <Fragment>
        <TableRow>
          <TableCell width={tableCellWidths[0]}>
            <Text block size="medium">
              {ticketNumber}
            </Text>
          </TableCell>
          <TableCell width={tableCellWidths[1]} vertical justifyContent="center">
            <Text block weight="medium" margin="no no xxSmall" singleLine>
              {disposalSiteName}
            </Text>
            <Text weight="light" margin="no no xxSmall" title={disposalSiteAddress} singleLine>
              {disposalSiteAddress}
            </Text>
          </TableCell>
          <TableCell width={tableCellWidths[2]} vertical justifyContent="center">
            {vehicleName && driverName ? (
              <Text>
                <Text block weight="medium">
                  {vehicleName}
                </Text>
                <Text>{driverName}</Text>
              </Text>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell width={tableCellWidths[3]}>
            <Text block size="medium">
              {weight.toFixed(2)}
            </Text>
          </TableCell>
          <TableCell width={tableCellWidths[4]}>
            <Text block size="medium">
              {WEIGHT_MEASUREMENT_UNITS[unitOfMeasureTypeId].name}
            </Text>
          </TableCell>
          <TableCell width={tableCellWidths[5]} vertical justifyContent="center">
            <Text block>{date(activityTimeLocal)}</Text>
            <Text block size="small">
              {time(activityTimeLocal)}
            </Text>
          </TableCell>
          {vehicleTypeId === ROLL_OFF_ID && (
            <TableCell width={tableCellWidths[6]} vertical justifyContent="center">
              <Text block weight="medium" margin="no no xxSmall">
                {customerName}
              </Text>
              <Text block size="small">
                {customerLocationAddress}
              </Text>
            </TableCell>
          )}
          <TableCell width={tableCellWidths[7]}>
            {!!image && <ThumbnailSet images={[image.url]} onClick={this.openWeightTicketImageModal} />}
          </TableCell>
        </TableRow>
        {!!isWeightTicketImageModalOpen && (
          <WeightTicketImagesModal weightTicket={weightTicket} closeModal={this.closeWeightTicketImageModal} />
        )}
      </Fragment>
    );
  }
}

export default WeightTicketsTableRow;
