import { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'js-cookie';

import { ALL_ID } from 'src/finance/constants';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { FRONT_LOAD_ID } from 'src/fleet/constants/vehicleTypes';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadBillingUnitOfMeasureTypes, loadRateAccountingCodes, loadRateCodes } from 'src/finance/ducks';
import { loadServiceTypes } from 'src/common/ducks';
import { PageLoading } from 'src/common/components/styled';
import { RATE_CONFIGURATION_SERVCE_TYPE_ID_SELECTED_COOKIE } from 'src/finance/constants/rateManager';
import { TypedResolver } from 'src/core/components';
import RateConfigurationPage from './RateConfigurationPage';
import translate from 'src/core/services/translate';

const RateConfigurationPageResolver: FC<RouteComponentProps> = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const serviceTypeIdFromCookie = Cookie.get(RATE_CONFIGURATION_SERVCE_TYPE_ID_SELECTED_COOKIE);

  const { serviceTypeId, statusTypeId, searchText } = getQueryParams(search);
  const rateConfiguationSearchInitialValues = useMemo(() => {
    return {
      serviceTypeId: serviceTypeId
        ? Number(serviceTypeId)
        : serviceTypeIdFromCookie
        ? Number(serviceTypeIdFromCookie)
        : FRONT_LOAD_ID,
      statusTypeId: statusTypeId ? Number(statusTypeId) : ALL_ID,
      searchText: searchText || '',
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadServiceTypes()(dispatch),
      loadRateCodes()(dispatch),
      loadBillingUnitOfMeasureTypes()(dispatch),
      loadRateAccountingCodes()(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('finance.rateManager.rateConfiguration')}</DocumentTitle>
      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/finance/billing"
        resolve={loadDependencies}
        successComponent={RateConfigurationPage}
        successProps={{ rateConfiguationSearchInitialValues }}
      />
    </>
  );
};

export default RateConfigurationPageResolver;
