import React, { memo } from 'react';
import { Layer } from 'react-map-gl';
import { FACILITIES_LAYER, FACILITY_SOURCE } from 'src/fleet/constants/facilityStatus';

export default memo(function FacilityGLLayer() {
  return (
    <>
      <Layer
        id={FACILITIES_LAYER}
        type="circle"
        source={FACILITY_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], FACILITIES_LAYER]]}
        paint={{
          'circle-color': '#00a599',
          'circle-radius': 15,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#ffffff',
        }}
      />
      <Layer
        id="facility-label"
        type="symbol"
        source={FACILITY_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], FACILITIES_LAYER]]}
        paint={{
          'text-color': 'white',
          'text-halo-color': '#e9e9e9',
          'text-halo-width': 1,
        }}
        layout={{
          'symbol-z-order': 'source',
          'symbol-sort-key': 1,
          'text-field': ['get', 'tag'],
          'text-size': 13,
          // 'text-allow-overlap': true,
          'text-keep-upright': true,
        }}
      />
    </>
  );
});
