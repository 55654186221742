import humps from 'humps';
import { get, keys, pick, pickBy, map, reduce, flatten, filter, find } from 'lodash-es';

import { SNOW_ROAD_CONDITIONS, STREET_SWEEPER_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';

const getSelectedFilters = (formData: any, path: string) => keys(pickBy(get(formData, path)));

/* eslint-disable no-param-reassign */
export const getSelectedVehicleIdsByType = (formData: any) => {
  const selectedVehicleTypes = map(getSelectedFilters(formData, 'vehicleTypes.filters'), humps.camelize);
  const vehicleIdsByType = reduce(
    getSelectedFilters(formData, 'vehicles.filters'),
    (vehiclesByType, vehicle) => {
      const [vehicleType, vehicleId] = vehicle.split(':');
      vehiclesByType[vehicleType] = vehiclesByType[vehicleType] || [];
      vehiclesByType[vehicleType].push(vehicleId);
      return vehiclesByType;
    },
    {} as any,
  );

  return pick(vehicleIdsByType, selectedVehicleTypes);
};

export const getSelectedGeoFences = (formData: any) => {
  if (
    formData &&
    formData.geoFencesTypes &&
    formData.geoFencesTypes.filters &&
    formData.geoFencesSubTypes &&
    formData.geoFencesSubTypes.filters
  ) {
    if (!find(Object.keys(formData.geoFencesTypes.filters), key => formData.geoFencesTypes.filters[key] === true))
      return [];

    return filter(
      Object.keys(formData.geoFencesSubTypes?.filters),
      key => formData.geoFencesSubTypes?.filters[key] === true,
    );
  } else return [];
};
/* eslint-enable no-param-reassign */

export const getSelectedVehicleTrackingOptions = (formData: any) => get(formData, 'vehicleTracking.filters', {});

export const getSelectedRoadConditionsOptions = (formData: any) => get(formData, 'roadConditions.filters', {});

export const getSelectedSnowRoadConditionsOptions = (formData: any) => get(formData, SNOW_ROAD_CONDITIONS, {});
export const getSelectedStreetSweeperConditionsOptions = (formData: any) =>
  get(formData, STREET_SWEEPER_CONDITIONS, {});

export const getSelectedVehicleInsightTypes = (formData: any) => {
  const vehicleInsightTypes = getSelectedFilters(formData, 'vehicleInsightTypes.filters');
  const isHardDrivingSelected = get(formData, 'vehicleInsightTypes.hardDriving');
  const hardDrivingInsightTypes = isHardDrivingSelected
    ? getSelectedFilters(formData, 'vehicleInsightTypes.hardDrivingFilters')
    : [];

  return [...vehicleInsightTypes, ...hardDrivingInsightTypes];
};

export const getSelectedContainerServiceInsightTypes = (formData: any) =>
  getSelectedFilters(formData, 'containerServiceInsightTypes.filters');

export const getSelectedContainerIssueInsightTypes = (formData: any) => {
  const isIssuesReportedSelected = get(formData, 'containerServiceInsightTypes.issuesReported');
  return isIssuesReportedSelected
    ? getSelectedFilters(formData, 'containerServiceInsightTypes.issuesReportedFilters')
    : [];
};

export const getSelectedCityInsightTypes = (formData: any) => getSelectedFilters(formData, 'cityInsightTypes.filters');

export const getSelectedVendorLocationTypes = (formData: any) =>
  map(getSelectedFilters(formData, 'vendorLocationTypes.filters'), vendorLocationType =>
    vendorLocationType.replace('vendorLocationType:', ''),
  );

export const getSelectedStreetSweeper = (formData: any) => getSelectedFilters(formData, 'vehicleTypes.filters');

export const getSelectedStreetSweeperVehicle = (vehicleIdsByType: any) => {
  const streetSweeperTypeIds = get(vehicleIdsByType, 'streetSweeper', undefined);
  return streetSweeperTypeIds;
};

export const getSelectedVehicleForVehicleTrackings = (vehicleIdsByType: any) => {
  const { streetSweeper, ...vehiclesByType } = vehicleIdsByType;
  return flatten(map(vehiclesByType)) as number[];
};

export const getSelectedVehicleTypes = (formData: any) => {
  const selectedVehicleTypes = getSelectedFilters(formData, 'vehicleTypes.filters');
  return selectedVehicleTypes;
};
