import React, { useMemo, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  // Field, // used by the optimizeBy field
  FieldArray,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { Facility } from '../../../common/interfaces/Facility';
import {
  // Dropdown, // used by the optimizeBy field
  Table,
} from '../../../core/components';
import { TableCell, TableRow } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isUniqueRouteName } from '../../../utils/services/validator';
import RouteBuilderConfiguration from '../../../vendors/components/RouteBuilderConfiguration';
import { DownArrow } from '../../../vendors/components/styled';
import { routeBuilderSettingsFormInitialValuesSelector } from '../../../vendors/services/routeBuilderSettingsFormInitialValuesSelector';
import { Route } from '../../interfaces/Route';
import { RouteBuilderTableCell, RouteBuilderTableRow } from '../modals';
// used by the optimizeBy field
// import {
//   TableCell,
//   TableRow,
// } from '../../../core/components/styled';
import { DispatchBoardFlexOverflowWrapper } from '../styled';

interface PropsWithoutReduxForm {
  truckYardFacilities: Facility[];
  disposalFacilities: Facility[];
  initialValues: any;
  routeDate?: Date | string;
  vehicleTypeId: number;
  vendorId: number;
  maxRouteTime?: Date;
  amBreakTime?: Date;
  pmBreakTime?: Date;
  lunchBreakTime?: Date;
}

export interface RouteBuilderFormData {
  optimizeBy: string;
  routeDate?: Date | string;
  routes: Route[];
  unassignedJobIds: number[];
  totalBreakTime?: Date;
  maxRouteTime?: Date;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteBuilderFormData, PropsWithoutReduxForm>;

type RouteError = {
  [key in keyof Partial<Route>]: string;
};

type Errors = {
  routes?: (RouteError | null)[];
  optimizeBy?: string | null;
  routeDate?: Date | string | null;
  unassignedJobIds?: string | null;
  totalBreakTime?: string | null;
};

const asyncValidate = (
  values: RouteBuilderFormData,
  dispatch: Dispatch,
  props: PropsWithoutReduxForm,
  blurredField: string,
) =>
  new Promise<void>(async (resolve, reject) => {
    const { routes, totalBreakTime, maxRouteTime } = values;
    const errors: Errors = {};
    const routeNames: string[] = [];
    try {
      const routeArrayErrors = await Promise.all(
        routes.map(async ({ routeName, startingLocationId }) => {
          routeName && routeNames.push(routeName);
          const routeErrors: RouteError = {};
          if (!routeName) {
            routeErrors.routeName = translate('common.validationMessages.isRequired');
          } else if (!blurredField || (blurredField && blurredField.includes('routeName'))) {
            try {
              const err = await isUniqueRouteName(routeName, routeNames, props.routeDate, props.vendorId);
              if (err) {
                routeErrors.routeName = err;
              }
            } catch (e) {
              // swallow error
              // eslint-disable-next-line no-console
              console.error(e);
            }
          }
          if (!startingLocationId) {
            routeErrors.startingLocationId = translate('common.validationMessages.isRequired');
          }
          if (Object.keys(routeErrors).length > 0) return routeErrors;
          return null;
        }),
      );
      if (routeArrayErrors.filter(error => error !== null).length) {
        // we still have to add the original array,
        // to show the errors on the correct fields
        errors.routes = routeArrayErrors;
      }
      if (maxRouteTime && totalBreakTime && totalBreakTime > maxRouteTime) {
        errors.totalBreakTime = '';
      }
    } catch (e) {
      // swallow error
      // eslint-disable-next-line no-console
      console.error(e);
    }

    if (Object.keys(errors).length > 0) reject(errors);
    resolve();
  });

const DispatchBoardRouteBuilderForm = (props: Props) => {
  const dispatch = useDispatch();

  const routeBuilderSettings = useSelector(
    (state: AppState) => state.vendors.routeBuilderSettings.routeBuilderSettings,
  );
  /** TODO Intermediate Facility for Route Builder */
  // const disposalFacilities = useSelector((state: AppState) => state.fleet.facilities.disposalFacilities);

  const {
    change,
    truckYardFacilities,
    disposalFacilities,
    handleSubmit,
    vehicleTypeId,
    maxRouteTime,
    amBreakTime,
    pmBreakTime,
    lunchBreakTime,
    /** TODO Intermediate Facility for Route Builder */
    // disposalFacilities,
  } = props;

  const [sectionExpanded, setSectionExpanded] = useState(false);

  const placeholderDate = useMemo(() => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  }, []);
  const routeStartTime = routeBuilderSettings ? routeBuilderSettings.routeStartTime : placeholderDate;

  const handleToggleClick = () => {
    setSectionExpanded(!sectionExpanded);
  };

  const routeBuilderTableCells = useMemo(
    () => [
      {
        name: 'routeName',
        component: RouteBuilderTableCell,
        componentProps: {
          label: translate('dispatchBoard.routeBuilder.routeNameLabel'),
          tooltipText: translate('dispatchBoard.routeBuilder.routeNameTooltipText'),
        },
        padding: 'xSmall no xSmall small',
        width: '20%',
        /** TODO Intermediate Facility for Route Builder */
        // width: '20%',
        sortable: false,
      },
      {
        name: 'startingLocation',
        component: RouteBuilderTableCell,
        componentProps: {
          label: translate('routes.startingLocation'),
          tooltipText: translate('dispatchBoard.routeBuilder.startingLocationTooltipText'),
        },
        padding: 'xSmall no xSmall small',
        width: '20%',
        /** TODO Intermediate Facility for Route Builder */
        // width: '20%',
        sortable: false,
      },
      {
        name: 'endingLocation',
        component: RouteBuilderTableCell,
        componentProps: {
          label: translate('routes.endingLocation'),
          tooltipText: translate('dispatchBoard.routeBuilder.endingLocationTooltipText'),
        },
        padding: 'xSmall no xSmall small',
        width: '20%',
        /** TODO Intermediate Facility for Route Builder */
        // width: '20%',
        sortable: false,
      },
      /** TODO Intermediate Facility for Route Builder */
      // {
      //   name: 'intermediateLocation',
      //   component: RouteBuilderTableCell,
      //   componentProps: {
      //     label: translate('dispatchBoard.routeBuilder.intermediateLocationLabel'),
      //     tooltipText: translate('dispatchBoard.routeBuilder.intermediateLocationTooltipText'),
      //   },
      //   padding: 'xSmall no xSmall small',
      //   width: '25%',
      //   sortable: false,
      // },
      {
        name: 'serviceZone',
        component: RouteBuilderTableCell,
        componentProps: {
          label: translate('dispatchBoard.routeBuilder.serviceZoneLabel'),
          tooltipText: translate('dispatchBoard.routeBuilder.serviceZoneTooltipText'),
        },
        padding: 'xSmall no xSmall small',
        width: '20%',
        /** TODO Intermediate Facility for Route Builder */
        // width: '20%',
        sortable: false,
      },
      {
        name: 'vehicle',
        component: RouteBuilderTableCell,
        componentProps: {
          label: translate('dispatchBoard.routeBuilder.vehicleLabel'),
          tooltipText: translate('dispatchBoard.routeBuilder.vehicleTooltipText'),
        },
        padding: 'xSmall small',
        width: '20%',
        /** TODO Intermediate Facility for Route Builder */
        // width: '15%',
        sortable: false,
      },
    ],
    [],
  );

  return (
    <DispatchBoardFlexOverflowWrapper>
      <form onSubmit={handleSubmit} id="dispatchBoardRouteBuilderForm">
        <Table cells={routeBuilderTableCells}>
          <FieldArray
            name="routes"
            component={RouteBuilderTableRow}
            vehicleTypeId={vehicleTypeId}
            truckYardFacilities={truckYardFacilities}
            disposalFacilities={disposalFacilities}
            change={change}
            /** TODO Intermediate Facility for Route Builder */
            // disposalFacilities={disposalFacilities}
          />
          {/* We currently use the numberOfRoutes as default.
            <TableRow>
              <TableCell width="50%" />
              <TableCell width="50%" vertical="top">
                <Field
                  component={Dropdown}
                  label={translate('dispatchBoard.routeBuilder.optimizeRoutesBy')}
                  name="optimizeBy"
                  options={[
                    {
                      label: translate('dispatchBoard.routeBuilder.totalTime'),
                      value: 'totalTime',
                    },
                    {
                      label: translate('dispatchBoard.routeBuilder.numberOfRoutes'),
                      value: 'numberOfRoutes',
                    },
                  ]}
                  width="100%"
                />
              </TableCell>
            </TableRow>
            */}

          <TableRow borderWidth={'0'}>
            <TableCell width="100%" onClick={handleToggleClick}>
              <strong>{translate('routes.additionalConfigurations')}</strong>
              <DownArrow margin="no no no small" orientation={sectionExpanded ? 'bottom' : 'top'}>
                ▼
              </DownArrow>
            </TableCell>
          </TableRow>
          <TableRow hidden={!sectionExpanded} visible={sectionExpanded} borderWidth={'0'}>
            <RouteBuilderConfiguration
              hideRestoreToDefaultsButton
              change={(name: string, time: Date | null) => dispatch(change(name, time))}
              routeStartTime={routeStartTime}
              maxRouteTime={maxRouteTime}
              amBreakTime={amBreakTime}
              pmBreakTime={pmBreakTime}
              lunchBreakTime={lunchBreakTime}
            />
          </TableRow>
        </Table>
      </form>
    </DispatchBoardFlexOverflowWrapper>
  );
};

const formName = 'dispatchBoardRouteBuilderForm';
const formSelector = formValueSelector(formName);
const formFieldsThatShouldNotReset = [
  'routeStartTime',
  'maxRouteTime',
  'serviceTimePerStop',
  'preStartTime',
  'postEndTime',
  'vehicleCapacity',
  'pUCapacityAllotment',
  'amBreakTime',
  'pmBreakTime',
  'lunchBreakTime',
  'totalBreakTime',
];
let lastRouteDate: Date | string | undefined;
let lastRoutesLength: number;

const mapStateToProps = (state: AppState, ownProps: PropsWithoutReduxForm) => {
  const { routeDate, routes = [] } = ownProps.initialValues;
  const noReset = formFieldsThatShouldNotReset.slice();

  if (routeDate === lastRouteDate && routes.length === lastRoutesLength) {
    noReset.push('routes');
  }

  lastRouteDate = routeDate;
  lastRoutesLength = routes.length;

  return {
    initialValues: {
      ...ownProps.initialValues,
      ...routeBuilderSettingsFormInitialValuesSelector(state.vendors.routeBuilderSettings),
      ...formSelector(state, ...noReset),
    },
    maxRouteTime: formSelector(state, 'maxRouteTime'),
    amBreakTime: formSelector(state, 'amBreakTime'),
    pmBreakTime: formSelector(state, 'pmBreakTime'),
    lunchBreakTime: formSelector(state, 'lunchBreakTime'),
  };
};

export default connect(mapStateToProps)(
  reduxForm<RouteBuilderFormData, PropsWithoutReduxForm>({
    asyncValidate,
    enableReinitialize: true,
    form: formName,
    shouldAsyncValidate: () => true,
    onSubmitFail: focusFirstInvalidField,
  })(DispatchBoardRouteBuilderForm),
);
