import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import {
  DELIVERY_UTILITY_ID,
  FRONT_LOAD_ID,
  RESIDENTIAL_ID,
  ROLL_OFF_ID,
  SUPERVISOR_ID,
  TOTER_ID,
} from '../../constants';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import {
  loadDriver,
  loadDriverFacilites,
  loadDrivers,
  loadUsersForDriver,
  loadVehicles,
  loadVehicleTypesForVendor,
} from '../../ducks';
import { loadFeatures } from '../../../vendors/ducks';
import { loadRouteTemplates } from '../../../routes/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import DriverEditorPage from './DriverEditorPage';
import translate from '../../../core/services/translate';

interface RouteParams {
  driverId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  loadDriver: DuckFunction<typeof loadDriver>;
  loadDriverFacilites: DuckFunction<typeof loadDriverFacilites>;
  loadDrivers: DuckFunction<typeof loadDrivers>;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadRouteTemplates: DuckFunction<typeof loadRouteTemplates>;
  loadUsersForDriver: DuckFunction<typeof loadUsersForDriver>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  vendorId: number;
}

class DriverEditorPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadDriver,
      loadDriverFacilites,
      loadDrivers,
      loadFeatures,
      loadRouteTemplates,
      loadUsersForDriver,
      loadVehicles,
      loadVehicleTypesForVendor,
      match: {
        params: { driverId },
      },
      vendorId,
    } = this.props;

    const dependencies = [];

    if (vendorId) {
      const validVehicleTypeIds = [
        DELIVERY_UTILITY_ID,
        FRONT_LOAD_ID,
        RESIDENTIAL_ID,
        ROLL_OFF_ID,
        TOTER_ID,
      ].toString();

      dependencies.push(
        loadFeatures(vendorId),
        loadDrivers(vendorId),
        loadRouteTemplates({ vendorId, vehicleTypeIds: validVehicleTypeIds, page: 1, limit: 999999 }), // Display All Route Templates
        loadVehicles(vendorId, true, SUPERVISOR_ID),
        loadVehicleTypesForVendor(vendorId),
        loadUsersForDriver(vendorId),
        loadDriverFacilites(vendorId),
      );
    }

    if (driverId) {
      dependencies.push(loadDriver(+driverId));
    }

    return Promise.all(dependencies);
  };

  render() {
    const {
      match: {
        params: { driverId },
      },
      vendorId,
    } = this.props;

    return (
      <>
        <DocumentTitle>
          {driverId ? translate('resources.editResource') : translate('resources.createResource')}
        </DocumentTitle>

        <Resolver
          successComponent={DriverEditorPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/fleet/resources"
          successProps={{
            vendorId,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorId(state),
});

const mapDispatchToProps = {
  loadDriver,
  loadDriverFacilites,
  loadDrivers,
  loadFeatures,
  loadRouteTemplates,
  loadVehicles,
  loadVehicleTypesForVendor,
  loadUsersForDriver,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DriverEditorPageResolver));
