import { identity, get } from 'lodash-es';
import { createSelector } from 'reselect';

import {
  isAdminVendorSelector,
  isAdminSelector,
  vendorIdSelector,
  vendorTypeIdSelector,
  deviceRoleTypeIdSelector,
  userIdSelector,
} from '../../account/ducks';
import { LoginState } from '../../account/ducks/login';
import { DefaultVendorState } from '../ducks/defaultVendor';
import { AppState } from '../../store';

const getCurrentVendorId = (loginState: LoginState, defaultVendorState: DefaultVendorState) =>
  isAdminSelector(loginState) && !isAdminVendorSelector(loginState)
    ? defaultVendorState.defaultVendor && defaultVendorState.defaultVendor.id
    : vendorIdSelector(loginState);

export const currentVendorIdSelector = createSelector<
  LoginState,
  DefaultVendorState,
  number | undefined,
  number | undefined
>(getCurrentVendorId as any, identity) as any;

export const currentVendorId = (state: AppState) =>
  currentVendorIdSelector(state.account.login, state.vendors.defaultVendor);

const getCurrentDeviceRoleTypeId = (loginState: any, defaultVendorState: any) =>
  isAdminSelector(loginState) && !isAdminVendorSelector(loginState)
    ? defaultVendorState.defaultVendor && defaultVendorState.defaultVendor.deviceRoleTypeId
    : deviceRoleTypeIdSelector(loginState);

export const currentDeviceRoleTypeIdSelector = createSelector(getCurrentDeviceRoleTypeId, identity);

const getCurrentVendorGusId = (loginState: any, defaultVendorState: any) =>
  isAdminSelector(loginState) && !isAdminVendorSelector(loginState)
    ? defaultVendorState.defaultVendor && defaultVendorState.defaultVendor.gusId
    : get(loginState, 'user.vendor.gusId');

export const currentVendorGusIdSelector: any = createSelector(getCurrentVendorGusId, identity);

export const currentVendorGusId = (state: AppState) =>
  currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor);

const getCurrentVendorTypeId = (loginState: any, defaultVendorState: any) =>
  isAdminSelector(loginState) && !isAdminVendorSelector(loginState)
    ? defaultVendorState.defaultVendor && defaultVendorState.defaultVendor.vendorTypeId
    : vendorTypeIdSelector(loginState);

export const currentVendorTypeIdSelector = createSelector(getCurrentVendorTypeId, identity);

export const currentVendorTypeId = (state: AppState) =>
  currentVendorTypeIdSelector(state.account.login, state.vendors.defaultVendor);

const getCurrentUserId = (loginState: LoginState) => userIdSelector(loginState);

export const currentUserIdSelector = createSelector<LoginState, string | undefined, string | undefined>(
  getCurrentUserId,
  identity,
);

export const currentUserId = (state: AppState) => currentUserIdSelector(state.account.login);
