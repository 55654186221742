import { FC, useMemo } from 'react';
import { find } from 'lodash-es';
import { getFormValues } from 'redux-form';
import { useDispatch } from 'react-redux';
import humps from 'humps';

import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { loadVehicleInformation } from 'src/dashboard/ducks';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { RouteVehiclePosition } from 'src/dashboard/interfaces/routesData';
import { TODAY_FORMATTED } from 'src/core/constants';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { VehicleBreadcrumbs, VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import DashboardVehiclePositionsGLPopup from './DashboardVehiclePositionsGLPopup';

interface Props {
  isSnoSweeperDashboard?: boolean;
}

const DashboardVehiclePositionsGLPopupResolver: FC<Props> = ({ isSnoSweeperDashboard }) => {
  const dispatch = useDispatch();

  const vehiclePositions = useSelector(state => (state.dashboard.vehiclesData.vehiclesList || []) as VehiclePosition[]);

  const routeVehiclePositions = useSelector(
    state => (state.dashboard.routesData.routeVehiclePositions || []) as RouteVehiclePosition[],
  );

  const vehicleInformation = useSelector(state => state.dashboard.vehiclesData.vehicleInformation);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);
  const formValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as any;
  const { vehicleTypes } = useSelector(state => state.fleet.vehicleTypes);

  const vehicleTrackings = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs as VehicleBreadcrumbs);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);

  const vehiclePosition = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'vehiclePositions' || selectedFeature.doNotShowPopup) {
      return undefined;
    }

    const vehiclePosition = vehiclePositions.find(position => position.id === selectedFeature.id);
    const routeVehiclePosition = routeVehiclePositions.find(position => position.vehicleId === selectedFeature.id);

    if (routeVehiclePosition) {
      const vehicleSubTypes =
        vehicleTypes.find(vehicleType => vehicleType.id === routeVehiclePosition.vehicleTypeId)?.subTypes || [];
      const subType = vehicleSubTypes.find(
        vehicleSubType => vehicleSubType.id === vehicleInformation?.vehicleSubTypeId,
      )?.name;

      let lastReportedSpeed = null;
      if (vehicleTrackingsForRoute && vehicleTrackingsForRoute.vehicles.length > 0) {
        const vehicleTrackings = find(vehicleTrackingsForRoute.vehicles, {
          id: routeVehiclePosition.vehicleId,
        })?.coords;

        if (vehicleTrackings && vehicleTrackings.length > 0) {
          // last group of coordinates
          const lastGroup = vehicleTrackings[vehicleTrackings.length - 1];
          // last coordinate in the last group
          const lastCoordinate = lastGroup[lastGroup.length - 1];
          lastReportedSpeed = lastCoordinate.sp;
        }
      }

      return {
        ...routeVehiclePosition,
        name: routeVehiclePosition.vehicleName,
        type: VEHICLE_TYPE_IDS[routeVehiclePosition.vehicleTypeId]?.technicalName,
        vehicleSubType: subType,
        id: routeVehiclePosition.vehicleId,
        isRouteVehicle: true,
        lastReportedSpeed:
          vehicleInformation?.lastReportedSpeed || vehiclePosition?.lastReportedSpeed || lastReportedSpeed,
      };
    }

    let lastReportedSpeed = null;
    if (vehicleTrackings && vehicleTrackings.coords) {
      const vehicleCoords = vehicleTrackings.coords;

      if (vehicleCoords && vehicleCoords.length > 0) {
        // last group of coordinates
        const lastGroup = vehicleCoords[vehicleCoords.length - 1];
        // last coordinate in the last group
        const lastCoordinate = lastGroup[lastGroup.length - 1];
        lastReportedSpeed = lastCoordinate.sp;
      }
    }
    const vehicleSubTypes =
      vehicleTypes.find(vehicleType => vehicleType.technicalName === vehiclePosition?.type)?.subTypes || [];
    const subType = vehicleSubTypes.find(
      vehicleSubType => vehicleSubType.id === vehicleInformation?.vehicleSubTypeId,
    )?.technicalName;

    return {
      ...vehiclePosition,
      lastReportedSpeed:
        vehicleInformation?.lastReportedSpeed || vehiclePosition?.lastReportedSpeed || lastReportedSpeed,
      vehicleSubType: subType,
    };
  }, [
    routeVehiclePositions,
    selectedFeature,
    vehicleInformation,
    vehiclePositions,
    vehicleTrackings,
    vehicleTrackingsForRoute,
    vehicleTypes,
  ]) as any;

  if (!vehiclePosition || !vehicleInformation || !vehiclePosition.latitude || !vehiclePosition.longitude) return null;

  const vehicleType =
    vehiclePosition && vehiclePosition.vehicleSubType
      ? humps.camelize(vehiclePosition.vehicleSubType)
      : humps.camelize(vehiclePosition.type);

  const loadDependencies = () => {
    const promise = loadVehicleInformation(
      vehiclePosition.id,
      isSnoSweeperDashboard ? TODAY_FORMATTED : formValues.date,
    )(dispatch);

    return Promise.all([promise]);
  };

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={vehiclePosition.latitude}
        longitude={vehiclePosition.longitude}
        onClose={() => dispatch(clearDashboardSelectedFeature())}
      >
        <TypedResolver
          key={selectedFeature!.id}
          successComponent={DashboardVehiclePositionsGLPopup}
          successProps={{
            vehicleDateTime: vehicleInformation.reportDateTime,
            vehicleDriverName: vehicleInformation.driverName,
            vehicleId: selectedFeature!.id,
            vehicleMilesDriven: vehicleInformation.totalMilesDriven,
            vehicleName: vehiclePosition.name,
            vehicleOdometer: vehiclePosition.lastReportedSpeed,
            vehicleStatus: vehicleInformation.vehicleStatus,
            vehicleType,
            isRouteVehicle: vehiclePosition.isRouteVehicle,
          }}
          loadingComponent={MapGLPopupLoading}
          resolve={loadDependencies}
        />
      </MapGLPopupWrapper>
    </>
  );
};

export default DashboardVehiclePositionsGLPopupResolver;
