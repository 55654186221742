import update from 'immutability-helper';
import { findIndex, get, identity, map, omit, reduce } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { deleteContainer as doDeleteContainer } from '../services/container';
import {
  exportContainers as doExportContainers,
  transferContainersBatched as doTransferContainersBatched,
  deleteContainersBatched as doDeleteContainersBatched,
} from '../services/containers';

// Actions
const START_LOAD = 'fleet/containers/START_LOAD';
export const COMPLETE_LOAD = 'fleet/containers/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/FAIL_LOAD';
const START_DELETE = '/fleet/containers/START_DELETE';
export const COMPLETE_DELETE = '/fleet/containers/COMPLETE_DELETE';
const START_BATCH_DELETE = 'fleet/containers/START_BATCH_DELETE';
const COMPLETE_BATCH_DELETE = 'fleet/containers/COMPLETE_BATCH_DELETE';
const FAIL_BATCH_DELETE = 'fleet/containers/FAIL_BATCH_DELETE';
const START_EXPORT = 'fleet/containers/START_EXPORT';
export const COMPLETE_EXPORT = 'fleet/containers/COMPLETE_EXPORT';
const START_BATCH_TRANSFER = 'fleet/containers/START_BATCH_TRANSFER';
const COMPLETE_BATCH_TRANSFER = 'fleet/containers/COMPLETE_BATCH_TRANSFER';
const FAIL_BATCH_TRANSFER = 'fleet/containers/FAIL_BATCH_TRANSFER';
const FAIL_EXPORT = 'fleet/containers/FAIL_EXPORT';
export const VIEW_CONTAINER_ON_MAP = 'fleet/containers/VIEW_CONTAINER_ON_MAP';
const RESET = 'fleet/containers/RESET';

interface State {
  isLoading: boolean;
  isDeleting: boolean;
  isExporting: boolean;
  isTransferring: boolean;
  containers: any[];
  total?: number;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: any = {
  isLoading: false,
  isDeleting: false,
  isExporting: false,
  containers: [],
  total: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containers: action.containers,
          total: action.total,
        },
      });
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containers: undefined,
          total: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });
    case COMPLETE_DELETE: {
      const containerIndex = findIndex(state.containers, { id: action.containerId });
      return update(state, {
        containers: { $splice: [[containerIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }
    case START_BATCH_DELETE: {
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });
    }
    case COMPLETE_BATCH_DELETE: {
      const containerIndexes = map(action.containerIds, id => findIndex(state.containers, { id }));
      return update(state, {
        containers: { $splice: map(containerIndexes, index => [index, 1]) },
        $merge: { isDeleting: false },
      });
    }
    case FAIL_BATCH_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });
    case START_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          isTransferring: true,
        },
      });
    case COMPLETE_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          isTransferring: false,
        },
      });
    case FAIL_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          isTransferring: false,
        },
      });
    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startDeleteContainer = () => ({
  type: START_DELETE,
});

const startBatchDelete = () => ({
  type: START_BATCH_DELETE,
});

const completeBatchDelete = (containerIds: number[]) => ({
  type: COMPLETE_BATCH_DELETE,
  containerIds,
});
const failBatchDelete = () => ({
  type: FAIL_BATCH_DELETE,
});
const startBatchTransfer = () => ({
  type: START_BATCH_TRANSFER,
});
const completeBatchTransfer = () => ({
  type: COMPLETE_BATCH_TRANSFER,
});
const failBatchTransfer = () => ({
  type: FAIL_BATCH_TRANSFER,
});

const completeDeleteContainer = (containerId: number) => ({
  type: COMPLETE_DELETE,
  containerId,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const deleteContainer = (containerId: number) => async (dispatch: Dispatch) => {
  dispatch(startDeleteContainer());
  await doDeleteContainer(containerId);
  dispatch(completeDeleteContainer(containerId));
};

export const deleteContainersBatched = (vendorId: number, containerIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startBatchDelete());
  const deleteBatchedContainersPromise = doDeleteContainersBatched(vendorId, containerIds)
    .then((containerIds: number[]) => dispatch(completeBatchDelete(containerIds)))
    .catch(() => dispatch(failBatchDelete()));
  return deleteBatchedContainersPromise;
};

export const transferContainersBatched =
  (vendorId: number, locationId: number, containerIds: number[]) => (dispatch: Dispatch) => {
    dispatch(startBatchTransfer());
    const transferBatchedContainersPromise = doTransferContainersBatched(vendorId, locationId, containerIds)
      .then(() => dispatch(completeBatchTransfer()))
      .catch(() => dispatch(failBatchTransfer()));
    return transferBatchedContainersPromise;
  };

export const exportContainers =
  (
    vendorId: number,
    searchTerm?: string,
    containerTypeId?: number,
    locationId?: number,
    equipmentTypeId?: number,
    equipmentSizeId?: number,
    equipmentConditionId?: number,
    sortedBy?: string,
    sortOrder?: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportContainersPromise = doExportContainers(
      vendorId,
      searchTerm,
      containerTypeId,
      locationId,
      equipmentTypeId,
      equipmentSizeId,
      equipmentConditionId,
      sortedBy,
      sortOrder,
    );
    exportContainersPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportContainersPromise;
  };

export const resetContainers = () => ({
  type: RESET,
});

export const viewContainerOnMap = () => ({
  type: VIEW_CONTAINER_ON_MAP,
});

// Selectors
const getContainers = (containersState: State) => get(containersState, 'containers', []);
export const containersSelector = createSelector(getContainers, identity);

const getContainersCount = (containersState: State) => get(containersState, 'total', 0);
export const containersCountSelector = createSelector(getContainersCount, identity);

/* eslint-disable no-param-reassign */
const getContainersByLocation = (containersState: State) =>
  reduce(
    getContainers(containersState),
    (containersByLocation: any, container) => {
      const { lastLocation } = container;

      if (!lastLocation) {
        return containersByLocation;
      }

      if (!containersByLocation[lastLocation.locationId]) {
        containersByLocation[lastLocation.locationId] = {
          ...lastLocation,
          containers: [],
        };
      }

      containersByLocation[lastLocation.locationId].containers.push(omit(container, 'lastLocation'));
      return containersByLocation;
    },
    {},
  );
/* eslint-enable no-param-reassign */

export const containersByLocationSelector = createSelector(getContainersByLocation, identity);
