import { Button, ButtonIcon, ButtonSet } from 'src/core/components/styled';
import { CALENDAR_VIEW, LIST_VIEW, ViewType } from 'src/core/constants/calendarListView';
import translate from 'src/core/services/translate';

interface Props {
  currentView: ViewType;
  switchView(view: ViewType): void;
}

const CalendarListViewSwitch = ({ currentView, switchView }: Props) => {
  return (
    <ButtonSet margin="no" align="center" grouped>
      <Button
        type="button"
        line={currentView === LIST_VIEW}
        color="primary"
        margin="no"
        onClick={() => switchView(CALENDAR_VIEW)}
        id="calendar-view-button"
        size="cSmall"
      >
        <ButtonIcon icon="calendar" size="xLarge" margin="no xxSmall no no" />
        {translate('common.calendarView')}
      </Button>
      <Button
        type="button"
        line={currentView === CALENDAR_VIEW}
        color="primary"
        margin="no"
        onClick={() => switchView(LIST_VIEW)}
        id="list-view-button"
      >
        <ButtonIcon icon="listView" size="xLarge" margin="no xxSmall no no" />
        {translate('common.list')}
      </Button>
    </ButtonSet>
  );
};

export default CalendarListViewSwitch;
