import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadEquipmentSizes as doLoadEquipmentSizes } from '../services/equipmentSizes';
import { TechnicalType } from '../interfaces/TechnicalType';

const START_LOAD = 'common/equipmentSizes/START_LOAD';
const COMPLETE_LOAD = 'common/equipmentSizes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/equipmentSizes/FAIL_LOAD';
const RESET = 'common/equipmentSizes/RESET';

type State = {
  equipmentSizes: TechnicalType[];
  isLoading: boolean;
};

const initialState: State = {
  equipmentSizes: [],
  isLoading: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentSizes: action.equipmentSizes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentSizes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (equipmentSizes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  equipmentSizes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadEquipmentSizes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadEquipmentSizesPromise = doLoadEquipmentSizes();
  loadEquipmentSizesPromise
    .then(equipmentSizes => dispatch(completeLoad(equipmentSizes)))
    .catch(() => dispatch(failLoad()));
  return loadEquipmentSizesPromise;
};

export const resetEquipmentSizes = () => ({
  type: RESET,
});
