import React, { PureComponent } from 'react';

import { ActionButtonTooltip, Modal } from '../../../core/components';
import { Button, ButtonSet, ModalTitle, Panel, PanelSection, Table, TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import { UploadDocumentArea } from '../../../fleet/components/styled/UnassignedWeightTickets';
import translate from '../../../core/services/translate';
import { ProgressDoughnut } from 'src/common/components';
import { connect } from 'react-redux';
import { AppState } from 'src/store';
import { EXCEL_MIME_TYPES, DOCX_MIME_TYPES, DOC_MIME_TYPES, PDF_MIME_TYPES, PNG_MIME_TYPES } from 'src/core/constants';
import { createWarningNotification } from 'src/core/services/createNotification';
interface Props {
  vendorId: number;
  uploadFile: (files: any) => void;
  closeModal: () => void;
  isLoading: boolean;
  progress: number;
}

interface State {
  isValidHover: boolean;
  files: FileList[];
  isOver20Files: boolean;
}

class UploadWorkOrdersModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isValidHover: false,
      isOver20Files: false,
      files: []
    };
  }

  private uploadFileRef: any;

  setUploadFileRef = (ref: any) => {
    this.uploadFileRef = ref;
  };

  isValidFile = (items: any) => items && items[0].kind === 'file'

  onDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.isValidFile(e.dataTransfer.items)) {
      this.setState({ isValidHover: true });
    }
  };

  onDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.isValidHover && this.isValidFile(e.dataTransfer.items)) {
      this.setState({ isValidHover: true });
    }
  };

  onDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isValidHover: false });
  };



  onDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      this.onFileAdded(e.dataTransfer.files)
    }
  };


  openFileUploader = () => {
    this.uploadFileRef.click();
  };

  onFileInputChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.onFileAdded(e.target.files);
  };

  checkFileNumber = () => {
    const { files } = this.state
    if (files.length > 20) {
      this.setState({ isOver20Files: true })
    } else {
      this.setState({ isOver20Files: false })
    }
  }


  onFileAdded = (Files: FileList[]) => {
    const { files } = this.state
    let tempFile = [] as FileList[];
    let mimeTypes: Array<string> = [EXCEL_MIME_TYPES, DOCX_MIME_TYPES, DOC_MIME_TYPES, PDF_MIME_TYPES, PNG_MIME_TYPES]
    if (Files.length > 0) {
      Files.forEach((element: any) => {
        if (mimeTypes.some(item => item === element.type)) {
          tempFile.push(element)
        } else {
          createWarningNotification(translate('opportunity.acceptedFiles'))
        }

      });
    }
    tempFile = [...files, ...tempFile]
    this.setState({
      files: tempFile
    })
    if (tempFile.length > 20) {
      this.setState({ isOver20Files: true })
    }

  }

  onFileRemove = (index: number) => {
    const { files } = this.state
    let tempFiles = files as FileList[];
    tempFiles.splice(index, 1)
    this.setState({ files: [...tempFiles] })
    this.checkFileNumber()
  }

  onUploadFile = async () => {
    const { files } = this.state
    const { uploadFile, closeModal } = this.props
    await uploadFile(files)
    this.setState({ files: [] })
    closeModal()
  }

  render() {
    const { closeModal, isLoading, progress } = this.props;
    const { isValidHover, isOver20Files } = this.state;
    const { files } = this.state;
    const isNofileSelected = files.length === 0
    return (
      <Modal onClose={closeModal} padding="medium" size="medium">
        <ModalTitle>{translate('opportunity.invoiceUpload')}</ModalTitle>
        <PanelSection display="block" isLoading={isLoading}>
          <UploadDocumentArea
            isHoveringOver={isValidHover}
            height="xGrande"
            center
            isError={isOver20Files}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
          >
            <div>
              {this.state.files.length > 0 && <div>{`${this.state.files.length} ${this.state.files.length === 1 ? translate('opportunity.file') : translate('opportunity.files')} Selected`}</div>}
              <div>{translate('autoDispatch.dropZoneMessage')}</div>
              <Button color="primary" margin="small no no no" onClick={this.openFileUploader} type="button">
                {translate('common.chooseFile')}
              </Button>
              <div>
                <input
                  accept=".pdf,.xls,.xlsx,.docx,.doc"
                  id="fileSelect"
                  onChange={this.onFileInputChange}
                  ref={this.setUploadFileRef}
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                />
              </div>

              {isOver20Files && <Text margin='small no small no' block weight="medium" color="brandWarning">{translate('opportunity.maxFilesNote')}</Text>}
            </div>
          </UploadDocumentArea>
          <Table
            height='small'
          >
            {this.state.files.map((file: any, index: number) => {
              return (
                <TableRow height={40} id={`invoice-${index}`}>
                  <TableCell width="10%" id={`invoice-${index}`}>
                    <TableActionButton color="grayDarker" onClick={() => this.onFileRemove(index)}>
                      <ActionButtonTooltip iconSize="small" icon="delete" tooltip="remove" />
                    </TableActionButton>
                  </TableCell>

                  <TableCell width="95%">
                    <Text align='left' weight="light" singleLine id={`invoice-${index}`}>
                      {file.name}
                    </Text>
                  </TableCell>
                </TableRow>)
            })}
          </Table>
        </PanelSection>
        <ButtonSet align="right">
          <Panel margin="no small no no">
            {isLoading && <ProgressDoughnut size={35} progress={progress} />}
          </Panel>

          <Button onClick={closeModal} margin='no small no no' color="primary" line>
            {translate('common.cancel')}
          </Button>

          <Button onClick={() => this.onUploadFile()} disabled={isOver20Files || isLoading || isNofileSelected} color="primary" borderRight >
            {translate('common.upload')}
          </Button>
        </ButtonSet>

      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
  progress: state.opportunity.invoiceUpload.progress,
  isLoading: state.opportunity.invoiceUpload.isLoading,
});

export default connect(mapStateToProps)(UploadWorkOrdersModal);
