import React from 'react';

import { ProgressBar } from '../../common/components';
import { RouteLocationsLoaderContainer, TextContainer } from './styled/RouteLocationsLoader';
import translate from '../../core/services/translate';

interface Props {
  limit: number;
  loadedPages: number[];
  page?: number;
  total: number;
  isSnowPlowRoute?: boolean;
}

export const RouteLocationsLoader: React.FC<Props> = ({ limit, loadedPages, total, isSnowPlowRoute }) => {
  const remaining = limit * loadedPages.length;
  let text = '';
  if (remaining > total) {
    text = translate('routes.locationLoader.drawingMap');
  } else if (total) {
    text = translate(
      isSnowPlowRoute ? 'routes.locationLoader.loadingStreetSegments' : 'routes.locationLoader.loading',
      {
        remaining,
        total,
      },
    );
  } else {
    text = translate('routes.locationLoader.loadingNoInfo');
  }

  const percentage = (remaining * 100) / total;

  return (
    <RouteLocationsLoaderContainer>
      <TextContainer>{text}</TextContainer>
      <ProgressBar withBorder progress={percentage} />
    </RouteLocationsLoaderContainer>
  );
};
