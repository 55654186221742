import React, { Fragment, useEffect, useRef } from 'react';

import { Button, ButtonSet, Message } from '../../../core/components/styled';
import { ISSUE_STATUS_PENDING } from 'src/fleet/constants/vehicleIssues';
import { Table } from '../../../core/components';
import { VehicleNotesFormTableRow } from '.';
import { WrappedFieldArrayProps } from 'redux-form';
import translate from '../../../core/services/translate';

const vehiclesTableCells = [
  { name: 'notes', label: translate('common.note'), width: '27%' },
  { name: 'sourceName', label: translate('common.source'), width: '15%' },
  { name: 'date', label: translate('common.timestamp'), width: '18%' },
  { name: 'status', label: translate('common.status'), width: '15%' },
  { name: 'options', label: translate('common.options'), width: '25%', align: 'right' },
];

interface ComponentProps extends WrappedFieldArrayProps {
  change: any;
  fleetMaintenanceConfigIsOptional: boolean;
  isPendingOrClosingProcess: boolean;
  newIssueStatus: number;
  tripInspectionDetailId: number;
  updateVehicleIssue: (status: number, vehicleNote?: string) => void;
  vehicleId: number;
}

const VehicleNotesFormFieldArray: React.FC<ComponentProps> = ({
  change,
  fields,
  fleetMaintenanceConfigIsOptional,
  isPendingOrClosingProcess,
  newIssueStatus,
  tripInspectionDetailId,
  updateVehicleIssue,
  vehicleId,
}) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;

      isPendingOrClosingProcess &&
        fields.push({
          status:
            newIssueStatus === ISSUE_STATUS_PENDING ? translate('vehicles.pending') : translate('vehicles.closed'),
        });
    }
  }, [fields, isPendingOrClosingProcess, newIssueStatus]);

  const vehicleNotesTableRows = fields.map((fieldArrayKey, index, fields) => ({
    fieldArrayKey,
    index,
    fields,
  }));

  return (
    <Fragment>
      {!!vehicleNotesTableRows.length && (
        <Table
          withTopBorder
          cells={vehiclesTableCells}
          rows={vehicleNotesTableRows}
          rowComponent={VehicleNotesFormTableRow}
          rowProps={{
            change,
            fleetMaintenanceConfigIsOptional,
            isPendingOrClosingProcess,
            newIssueStatus,
            tripInspectionDetailId,
            updateVehicleIssue,
            vehicleId,
          }}
        />
      )}

      {!vehicleNotesTableRows.length && !isPendingOrClosingProcess && (
        <Message padding="no no sMedium sMedium">{translate('vehicles.noVehicleNotes')}</Message>
      )}

      {!isPendingOrClosingProcess && (
        <ButtonSet margin="sMedium no sMedium">
          <Button type="button" color="primary" line onClick={() => fields.push({})} id="add-vehicle-note">
            + {translate('vehicles.addVehicleNote')}
          </Button>
        </ButtonSet>
      )}
    </Fragment>
  );
};

export default VehicleNotesFormFieldArray;
