import { http } from '../../core/services/http';

export const loadContainerLocationsForMap = (
  vendorId: number,
  containerTypeId?: number,
  equipmentTypeId?: number,
  equipmentSizeId?: number,
  equipmentConditionId?: number,
  searchTerm?: string,
  locationId?: number,
) =>
  http
    .get(`/inventories/${vendorId}/containers/map`, {
      params: { containerTypeId, equipmentTypeId, equipmentSizeId, equipmentConditionId, searchTerm, locationId },
    })
    .then(response => response.data);
