import Cookie from 'js-cookie';

const COOKIE_KEY = 'defaultVendor';
const COOKIE_EXPIRATION = 7 * 365;

export const setDefaultVendor = (vendor: any) => {
  Cookie.set(COOKIE_KEY, JSON.stringify(vendor), { expires: COOKIE_EXPIRATION });
};

export const getDefaultVendor = () => {
  const cookieValue = Cookie.get(COOKIE_KEY);
  if (cookieValue) return JSON.parse(cookieValue);
};

export const deleteDefaultVendor = () => {
  Cookie.remove(COOKIE_KEY);
};
