import { http } from '../../core/services/http';
import {
  DriverSafetyInspectionSettings,
  SupervisorExperienceSettings,
} from '../interfaces/SupervisorExperienceSettings';
import { transformDriverSafetyInspectionSettings } from './transformDriverSafetyInspectionSettings';

export const loadSupervisorExperienceSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/vendorSupervisorSettings`).then(response => response.data);

export const saveSupervisorExperienceSettings = (vendorId: number, settings: SupervisorExperienceSettings) =>
  http.post(`/vendors/${vendorId}/vendorSupervisorSettings`, settings);

export const loadDriverSafetyInspectionSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/driverSafetyInspectionSettings`).then(response => response.data);

export const saveDriverSafetyInspectionSettings = (
  vendorId: number,
  driverSafetyInspectionSettings: DriverSafetyInspectionSettings[],
) =>
  http.post(
    `/vendors/${vendorId}/driverSafetyInspectionSettings`,
    transformDriverSafetyInspectionSettings(driverSafetyInspectionSettings),
  );
