import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../store';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { loadHolidays, loadRecurrenceDayTypes } from '../../../ducks/holidayPlanner';
import { PageLoading } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import HolidayPlannerPage from './HolidayPlannerPage';
import translate from '../../../../core/services/translate';

interface Props {
  onClose(): void;
}

const HolidayPlannerPageResolver: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.all([]);
    }

    return Promise.all([
      loadHolidays(vendorId)(dispatch),
      loadRecurrenceDayTypes()(dispatch),
    ]);
  }, [vendorId, dispatch]);

  return (
    <>
      <DocumentTitle>{translate('routes.holidayPlanner.holidayPlanner')}</DocumentTitle>

      <TypedResolver
        redirectOnError="/routes/route-templates"
        successComponent={HolidayPlannerPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
      />
    </>
  );
};

export default HolidayPlannerPageResolver;
