import { size } from 'lodash-es';

import translate from '../../core/services/translate';
import { ACCOUNT_STATUSES } from '../../common/constants';

export const accountStatusFormatText = (selectedOptions: string[], allOptionsSelected?: boolean) => {
  if (allOptionsSelected) {
    return translate('common.allStatuses');
  }

  return size(selectedOptions) === 1
    ? ACCOUNT_STATUSES[selectedOptions[0]].name
    : translate('common.xStatusesSelected', { selected: size(selectedOptions) });
};
