import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const LOW_PRIORITY_ID = 1;
export const MEDIUM_PRIORITY_ID = 2;
export const HIGH_PRIORITY_ID = 3;
export const NONE_ID = 4;
export const NONE_ZERO_ID = 0;

export const ROUTE_PRIORITY_TYPES_OPTIONS = [
  { value: LOW_PRIORITY_ID, label: translate('dashboard.priority1') },
  { value: MEDIUM_PRIORITY_ID, label: translate('dashboard.priority2') },
  { value: HIGH_PRIORITY_ID, label: translate('dashboard.priority3') },
];

export const ROUTE_PRIORITY_TYPES = mapKeys(ROUTE_PRIORITY_TYPES_OPTIONS, 'value');

export const ROUTE_ALL_PRIORITY_TYPES_OPTIONS = [
  { value: LOW_PRIORITY_ID, label: translate('dashboard.priority1') },
  { value: MEDIUM_PRIORITY_ID, label: translate('dashboard.priority2') },
  { value: HIGH_PRIORITY_ID, label: translate('dashboard.priority3') },
  { value: NONE_ZERO_ID, label: translate('dashboard.none') },
];
