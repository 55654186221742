import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { UnconnectedSwitch } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  isActive: boolean;
  toggleYData: (id: number, isChecked: any | boolean) => void;
  yDataType: TechnicalType;
}

const YDataTableRow: React.FC<Props> = ({ isActive, yDataType, toggleYData }) => {
  return (
    <TableRow>
      <TableCell width="85%">
        {translate(createTranslationKey(yDataType.technicalName, 'insights.yDataType'))}
      </TableCell>
      <TableCell width="15%" align="right">
        <UnconnectedSwitch
          checked={isActive}
          id={`reporting-settings-${yDataType.technicalName}-switch`}
          onChange={(isChecked: boolean) => toggleYData(yDataType.id, isChecked)}
        />
      </TableCell>
    </TableRow>
  );
};

export default YDataTableRow;
