import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { loadTrackingSettings } from '../../ducks';
import { PanelSectionLoading } from '../../../core/components/styled';
import { Resolver } from '../../../core/components';
import { DocumentTitle } from '../../../common/components';
import translate from '../../../core/services/translate';
import TrackingSettingsPage from './TrackingSettingsPage';

interface Props {
  loadTrackingSettings(...args: any[]): any;
}

class TrackingPageSettingsPageResolver extends PureComponent<Props> {
  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('settings.trackingSettings')}</DocumentTitle>
        <Resolver
          successComponent={TrackingSettingsPage}
          loadingComponent={PanelSectionLoading}
          resolve={this.props.loadTrackingSettings}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = { loadTrackingSettings };

export default connect(undefined, mapDispatchToProps)(TrackingPageSettingsPageResolver);
