import { omit } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

const transformLoadRouteImports = (haulerRouteImports: any[]) =>
  haulerRouteImports.map(routeImport => ({
    ...omit(routeImport, ['vendor', 'fileProcessingStatusType']),
    vendorName: routeImport.vendor.name,
    fileProcessingStatusTypeId: routeImport.fileProcessingStatusType.id,
    fileProcessingStatusName: translate(
      createTranslationKey(routeImport.fileProcessingStatusType.name, 'routeImport.fileImportProcessingStatuses'),
    ),
  }));

export default transformLoadRouteImports;
