import styled, { css } from 'styled-components';
import { darken, transparentize } from 'polished';

import { colorMapper, sizeMapper, mapper } from '../../../utils/styles';
import { BareButtonStyle, Popover } from '../../../core/components/styled';
import { Icon } from '../../../core/components';

export const OpportunityTitle = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 18px;
`;

interface OpportunityInfoFillIconProps {
  margin?: string;
  color?: string;
}

export const OpportunityInfoFillIcon = styled(Icon).attrs({ icon: 'infoFill' })<OpportunityInfoFillIconProps>`
  width: 80%;
  height: 80%;
  margin: ${props => sizeMapper(props.margin, 'no')};
  color: ${colorMapper};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

interface PaymentInfoFillIconProps {
  margin?: string;
}

export const PaymentInfoFillIcon = styled(Icon).attrs({ icon: 'infoFill' })<PaymentInfoFillIconProps>`
  width: 60%;
  height: 80%;
  margin: ${props => sizeMapper(props.margin, 'no')};
  color: ${colorMapper};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const PaymentInfoFillIconPopUpOpen = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  right: 0;
  z-index: 200;
  /* width: 16px; */
  height: 21px;
  padding-top: 4px;
  border-radius: 3px 3px 0 0;

  ${Popover} {
    display: none;
  }

  &:hover {
    ${Popover} {
      display: block;
    }
  }
`;

interface OpportunityInfoIconProps {
  margin?: string;
  color?: string;
}

export const OpportunityInfoIcon = styled(Icon).attrs({ icon: 'info' })<OpportunityInfoIconProps>`
width: 4%;
height: 4%;
 /* color: ${props => props.theme.gray}; */
 margin: ${props => sizeMapper(props.margin, 'no')};
color: ${colorMapper};
 cursor: pointer;

&:hover {
  color: ${props => props.theme.brandPrimary};
}
`;

interface OpportunityCompleteIconProps {
  margin?: string;
}

export const OpportunityCompleteIcon = styled(Icon).attrs({ icon: 'complete' })<OpportunityCompleteIconProps>`
  width: 12px;
  height: 12px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  color: ${colorMapper};
  cursor: pointer;
`;

export const OpportunityDeclineIcon = styled(Icon).attrs({ icon: 'decline' })<OpportunityCompleteIconProps>`
  width: 12px;
  height: 12px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  color: ${colorMapper};
  cursor: pointer;
`;

interface OpportunityPendingIconProps {
  margin?: string;
}

export const OpportunityPendingIcon = styled(Icon).attrs({ icon: 'pending' })<OpportunityPendingIconProps>`
  width: 12px;
  height: 12px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  color: ${colorMapper};
  cursor: pointer;
`;

interface OpportunityDisabledIconProps {
  margin?: string;
}

export const OpportunityDisabledIcon = styled(Icon).attrs({ icon: 'disabled' })<OpportunityDisabledIconProps>`
  width: 12px;
  height: 12px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  color: ${colorMapper};
  cursor: pointer;
`;

interface OpportunityEditIconProps {
  margin?: string;
}

export const OpportunityEditIcon = styled(Icon).attrs({ icon: 'edit' })<OpportunityEditIconProps>`
  width: 100%;
  height: 50%;
  color: ${colorMapper};
  margin: ${props => sizeMapper(props.margin, 'no')};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

export const OpportunityEditIconPopUpOpen = styled.a`
  ${BareButtonStyle};
  left: 200px;
  width: 25px;
  height: 32px;
  padding-top: 10px;
  border-radius: 3px 3px 0 0;
  align: right;
`;

export const OpportunityToggleContainer = styled.div`
  padding: 10px 0px 0px;
  text-align: left;
`;

export const OpportunityToggle = styled.button`
  ${BareButtonStyle};
  text-transform: capitalize;
  font-size: 14px;
  color: ${props => props.theme.brandPrimary};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};

  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
  }
`;

interface OpportunityContainerProps {
  margin?: string;
  padding?: string;
  vertical?: boolean;
}

export const OpportunityContainer = styled.div<OpportunityContainerProps>`
  position: relative;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};
`;

export const OpportunityWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
`;

interface InputMaskProps {
  margin?: string;
  top?: 'small' | 'medium' | 'large';
  size?: 'small' | 'medium' | 'large';
}

export const InputMask = styled.div<InputMaskProps>`
  position: relative;
  top: ${props => mapper(props.top, { small: '15px', medium: '25px', large: '50px' }, 'large')};
  width: 4px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px' }, 'medium')};
`;

export const OpportunityLink = styled.a`
  /* display: block; */
  font-weight: ${props => props.theme.fontWeightLight};
  width: 100%;
  height: 100%;
  color: ${props => props.theme.brandPrimary};
`;

interface OpportunityArrowRightIconProps {
  color: string;
}

export const OpportunityArrowRightIcon = styled(Icon).attrs({ icon: 'arrowRight' })<OpportunityArrowRightIconProps>`
  width: 50%;
  height: 50%;
  color: ${colorMapper};
  cursor: pointer;
`;

interface OpportunityArrowDownIconProps {
  color: string;
}

export const OpportunityArrowDownIcon = styled(Icon).attrs({ icon: 'arrowDown' })<OpportunityArrowDownIconProps>`
  width: 50%;
  height: 50%;
  color: ${colorMapper};
  cursor: pointer;
`;

interface OpportuniyGridColumnProps {
  margin?: string;
  padding?: string;
  width?: string | number;
  tabletSize?: boolean;
  lastColumn?: boolean;
}

export const OpportuniyGridColumn = styled.div<OpportuniyGridColumnProps>`
  width: 32%;
  margin: ${props => (props.margin ? sizeMapper(props.margin) : '2% 2% 2% 0')};
  background: #fff;
  padding: ${props => sizeMapper(props.padding, 'xSmall')};
  box-shadow: 0 4px 10px ${props => transparentize(0.95, props.theme.grayBase)};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.tabletSize &&
    css`
      @media (max-width: ${props => props.theme.tabletBreakpoint}) {
        width: 50%;
      }
    `};

  &:nth-child(3n) {
    margin-right: 0;
  }

  ${props =>
    props.lastColumn &&
    css`
      margin-right: auto;
    `};
`;

interface OpportunityGridProps {
  multiLine?: boolean;
}

export const OpportunityGrid = styled.div<OpportunityGridProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: ${props => props.theme.grayLighter};

  ${props =>
    props.multiLine &&
    css`
      flex-wrap: wrap;
    `};
`;

export const DetailsListBodySection = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  display: flex;
`;