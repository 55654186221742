import React from 'react';

import { ActionButtonTooltip, PopoverWrapper } from '../../../core/components';
import { TableActionButton, TableCell, TableRow, Popover } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface Props {
  deleteContact(event: any, id: number): void;
  editContact(event: any, id: number): void;
  emailAddress: string;
  firstName: string;
  id: number;
  isPrimary: boolean;
  phoneNumber?: string;
  selectedContactTypesDisplayName: string;
}

const ContactsPageTableRow: React.FC<Props> = ({
  deleteContact,
  editContact,
  emailAddress,
  firstName,
  id,
  isPrimary,
  phoneNumber,
  selectedContactTypesDisplayName,
}) => (
  <TableRow>
    <TableCell width="20%">{firstName}</TableCell>
    <TableCell width="15%">{phoneNumber}</TableCell>
    <TableCell width="25%">{emailAddress}</TableCell>
    <TableCell width="30%">{selectedContactTypesDisplayName}</TableCell>
    <TableCell width="10%" align="center">
      <TableActionButton onClick={event => editContact(event, id)} id={`edit-contact-${id}-button`}>
        <ActionButtonTooltip icon="edit" tooltip="edit" />
      </TableActionButton>
      <TableActionButton
        onClick={event => deleteContact(event, id)}
        id={`delete-contact-${id}-button`}
        disabled={isPrimary}
      >
        <PopoverWrapper
          triggerButton={<ActionButtonTooltip icon="delete" tooltip={!isPrimary ? 'delete' : undefined} />}
          popoverContent={
            isPrimary && <Popover>{translate('haulerProfile.alertMessages.primaryContactRemoval')}</Popover>
          }
        />
      </TableActionButton>
    </TableCell>
  </TableRow>
);

export default ContactsPageTableRow;
