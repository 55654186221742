import { filter } from 'lodash-es';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { Modal } from 'src/core/components';
import { WEEKDAYS_BY_ID, WEEKDAYS_BY_SHORTCODE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import {
  fetchRouteTemplatesSchedulers,
  resetSchedulers,
  scheduleAllSnowRouteTemplates,
  scheduleAllStreetSweeperRouteTemplates,
  scheduleRouteTemplates,
} from 'src/routes/ducks/routeTemplates';
import { RoutePlannerNewSchedulerFormValues, RouteTemplateSimplified } from 'src/routes/interfaces/RouteTemplates';
import { getIsAllSnowPlowOrStreetSweeperTemplates } from 'src/routes/utils/routePlanner';
import { dateFormat } from 'src/utils/services/validator';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { RoutePlannerNewSchedulerForm } from '../forms';
import {
  ROUTE_PLANNER_NEW_SCHEDULER_FORM_NAME,
  ROUTE_TYPES_FOR_SCHEDULER,
} from '../forms/RoutePlannerNewSchedulerForm';

interface Props {
  templates: RouteTemplateSimplified[];
  onClose(successful?: boolean): void;
  defaultDate?: Date;
}

const selector = formValueSelector(ROUTE_PLANNER_NEW_SCHEDULER_FORM_NAME);

const RoutePlannerNewSchedulerModal: FC<Props> = ({ templates, defaultDate, onClose: closeModal }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const date = useSelector(state => selector(state, 'date'));
  const keepOriginalServiceDate = useSelector(state => selector(state, 'keepOriginalServiceDate'));

  const { isScheduling } = useSelector(state => state.routes.routeTemplates);

  const templateIds = useMemo(() => templates.map(template => template.id), [templates]);

  const isScheduleAll = !templateIds.length;

  useEffect(() => {
    if (isScheduleAll || !date || !vendorId) {
      return;
    }
    fetchRouteTemplatesSchedulers(templateIds, moment(date).format(dateFormat), vendorId)(dispatch);
  }, [templateIds, date, dispatch, vendorId, isScheduleAll]);

  const onClose = useCallback(
    (successful = false) => {
      closeModal(successful);
      dispatch(resetSchedulers());
    },
    [closeModal, dispatch],
  );

  const handleSchedulePlannerRoutes = useCallback(
    async (values: RoutePlannerNewSchedulerFormValues) => {
      if (!vendorId) return;
      const dayOfWeek = moment(values.date, 'MM/DD/YYYY').day();
      const dayOfWeekId = dayOfWeek !== 0 ? dayOfWeek : 7;
      const { id: scheduledDayId, name: scheduledDayName } = WEEKDAYS_BY_ID[dayOfWeekId];

      const templatesWithDifferentDay = filter(templates, template =>
        typeof template?.scheduledDay === 'number'
          ? WEEKDAYS_BY_ID[template?.scheduledDay]?.id !== scheduledDayId
          : WEEKDAYS_BY_SHORTCODE[template?.scheduledDay]?.id !== scheduledDayId,
      ).length;

      const isAllSnowPlowOrStreetSweeperTemplates = getIsAllSnowPlowOrStreetSweeperTemplates(templates);
      if (
        !!templatesWithDifferentDay &&
        !isAllSnowPlowOrStreetSweeperTemplates &&
        !(await confirm(
          translate('routes.alertMessages.confirmCreateRoutesForAnotherDayOfWeek', {
            scheduledDayName,
          }),
        ))
      ) {
        return;
      }

      let promise;

      if (!isScheduleAll) {
        promise = scheduleRouteTemplates(
          templateIds,
          moment(values.date).format('MM-DD-YYYY'),
          vendorId,
          keepOriginalServiceDate === 'no',
        );
      } else
        switch (values.routeType) {
          case ROUTE_TYPES_FOR_SCHEDULER.SNOW_PLOW:
            promise = scheduleAllSnowRouteTemplates(
              moment(values.date).format('MM-DD-YYYY'),
              vendorId,
              keepOriginalServiceDate === 'no',
              values.groupIds,
            );
            break;
          case ROUTE_TYPES_FOR_SCHEDULER.STREET_SWEEPER:
            promise = scheduleAllStreetSweeperRouteTemplates(
              moment(values.date).format('MM-DD-YYYY'),
              vendorId,
              keepOriginalServiceDate === 'no',
              values.groupIds,
            );
            break;
        }
      promise &&
        (await promise(dispatch)
          .then(() => {
            onClose(true);
            createSuccessNotification(translate('routes.alertMessages.routeTemplatesScheduled'));
          })
          .catch(() => {
            createErrorNotification(translate('routes.alertMessages.routeTemplatesScheduleFailed'));
          }));
    },
    [dispatch, isScheduleAll, keepOriginalServiceDate, onClose, templateIds, templates, vendorId],
  );

  return (
    <Modal
      size="small"
      minHeight="400px"
      title={isScheduleAll ? translate('tooltips.scheduleAll') : translate('routes.schedule')}
      onClose={() => onClose(false)}
      isLoading={isScheduling}
      padding="medium no no no"
    >
      <RoutePlannerNewSchedulerForm
        initialValues={{
          keepOriginalServiceDate: 'no',
          date: defaultDate,
          routeType: undefined,
          groupIds: [],
        }}
        isScheduleAll={isScheduleAll}
        onSubmit={handleSchedulePlannerRoutes}
        templates={templates}
      />
    </Modal>
  );
};

export default RoutePlannerNewSchedulerModal;
