import { http } from '../../core/services/http';
import { transformLoadBusinessInfo, transformSaveBusinessInfo, transformCertifications } from './transformBusinessInfo';
import { transformLoadHoursOfOperation, transformSaveHoursOfOperation } from './transformHoursOfOperation';

export const loadBusinessProfile = (haulerId: string) =>
  http.get(`/haulerProfile/${haulerId}/businessInfo`).then(response => transformLoadBusinessInfo(response.data));

export const saveBusinessProfile = (haulerId: string, data: any) =>
  http.put(`/haulerProfile/${haulerId}/businessInfo`, transformSaveBusinessInfo(data));

export const loadHoursOfOperation = (haulerId: string) =>
  http.get(`/haulerProfile/${haulerId}/operationhours`).then(response => transformLoadHoursOfOperation(response.data));

export const saveHoursOfOperation = (haulerId: string, data: any) =>
  http.put(`/haulerProfile/${haulerId}/operationhours`, transformSaveHoursOfOperation(data));

export const loadCertifications = (haulerId: string, divisionId?: string) => {
  const url = divisionId
    ? `/haulerProfile/${haulerId}/certificates?divisionId=${divisionId}`
    : `/haulerProfile/${haulerId}/certificates`;
  return http.get(url).then(response => transformCertifications(response.data));
};
