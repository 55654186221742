import { http } from '../../core/services/http';
import { transformCompliance, transformCOIStatus } from './transformCompliance';
import { DOCUMENT_DESCRIPTION, FILE_NAME, SC } from '../constants/documentTypesOption';
import { ONE_YEAR_AFTER, TODAY_FORMATTED } from '../../core/constants';

export const loadCompliance = (userId: string, divisionId?: string) =>
  http
    .get(`/haulerProfile/${userId}/compliance?divisionId=${divisionId}`)
    .then(response => transformCompliance(response.data));

export const loadHaulerProfileDivisions = (vendorId: number) =>
  http.get(`vendors/${vendorId}/haulerProfileDivisions`).then(response => response.data);

export const uploadFromUrl = (
  userId: string,
  vendorGroupId: string,
  selfCertificationDocumentUrl: string,
  divisionId: string,
) => {
  const fileName = `${new Date().getTime()}_${FILE_NAME}.pdf`;

  const data = {
    documentDescription: DOCUMENT_DESCRIPTION,
    type: SC,
    name: fileName,
    showToCustomer: false,
    showToVendor: true,
    url: selfCertificationDocumentUrl,
    userId,
    validFrom: TODAY_FORMATTED,
    validTo: ONE_YEAR_AFTER,
    vendorGroupId,
  };

  const formData = new FormData();
  formData.append('ShowToCustomer', String(!!data.showToCustomer));
  formData.append('ShowToVendor', String(true));
  formData.append('DocumentType', data.type);
  formData.append('FileName', data.name);
  formData.append('Url', data.url);
  formData.append('VendorGroupId', vendorGroupId);
  formData.append('UserId', userId);
  formData.append('DocumentDescription', data.documentDescription);
  formData.append('ValidFrom', data.validFrom);
  formData.append('ValidTo', data.validTo);

  return http
    .post(
      `haulerprofile/${vendorGroupId}/user/${userId}/documents?downloadFromUrl=true&?divisionId=${divisionId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .catch(error => Promise.reject(error.response.data.message));
};

export const uploadSelfCertification = (
  userId: string,
  vendorGroupId: string,
  name: string,
  title: string,
  divisionId: string,
) => {
  const fileName = `${new Date().getTime()}_${FILE_NAME}.pdf`;

  const data = {
    documentDescription: DOCUMENT_DESCRIPTION,
    type: SC,
    name,
    showToCustomer: false,
    showToVendor: true,
    userId,
    validFrom: TODAY_FORMATTED,
    validTo: ONE_YEAR_AFTER,
    vendorGroupId,
    title,
  };

  const formData = new FormData();
  formData.append('ShowToCustomer', String(!!data.showToCustomer));
  formData.append('ShowToVendor', String(true));
  formData.append('DocumentType', data.type);
  formData.append('Name', data.name);
  formData.append('Title', data.title);
  formData.append('FileName', fileName);
  formData.append('VendorGroupId', vendorGroupId);
  formData.append('UserId', userId);
  formData.append('DocumentDescription', data.documentDescription);
  formData.append('ValidFrom', data.validFrom);
  formData.append('ValidTo', data.validTo);

  return http
    .post(`haulerprofile/${vendorGroupId}/user/${userId}/selfcertification?divisionId=${divisionId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch(error => Promise.reject(error.response.data.message));
};

export const loadCOIStatus = (gusId?: string, divisionId?: String) =>
  http
    .get(`/haulerprofile/${gusId}/selfcertification/coi-status?divisionId=${divisionId}`)
    .then(response => transformCOIStatus(response.data));
