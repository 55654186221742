import React, { useEffect } from 'react';
import { useSelector } from 'src/core/hooks/useSelector';
import Basemap from '@arcgis/core/Basemap';
import { useEsriMap } from './EsriMapContext';
import { AppState } from 'src/store';

interface MapWrapperProps {
  basemap: string;
}

const MapWrapper: React.FC<MapWrapperProps> = ({ basemap }) => {
  const { mapRef, createMapView, view, map } = useEsriMap();
  const viewport = useSelector((state: AppState) => state.dashboard.mapControls.viewport);
  const selectedFeature = useSelector((state: AppState) => state.dashboard.mapControls.selectedFeature);

  useEffect(() => {
    if (mapRef.current) {
      createMapView(mapRef.current, basemap);
    }
  }, [createMapView, mapRef, basemap]);

  useEffect(() => {
    if (map) {
      map.basemap = Basemap.fromId(basemap);
    }
  }, [basemap, map]);

  const zoomToLocation = (longitude: number, latitude: number, zoomLevel: number) => {
    if (view) {
      view.goTo({
        center: [longitude, latitude],
        zoom: zoomLevel,
      }).catch((error) => {
        console.error('Error in goTo:', error);
      });
    }
  };

  useEffect(() => {
    if (selectedFeature && viewport && view) {
      const { longitude, latitude, zoom } = viewport;
      if (longitude && latitude && zoom) {
        zoomToLocation(longitude, latitude, zoom);
      }
    }
    // eslint-disable-next-line
  }, [viewport, view, selectedFeature]);

  return (
    <div
      ref={mapRef}
      style={{ height: '100%', width: '100%' }}
    />
  );
};

export default MapWrapper;
