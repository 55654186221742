import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { loadCityAlertsForRoute } from 'src/vendors/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import RouteTrackerCityAlertsModal from './RouteTrackerCityAlertsModal';

interface Props {
  closeModal: () => void;
  routeId?: number;
}

const RouteTrackerCityAlertsModalResolver: FC<Props> = ({ routeId, closeModal }) => {
  const dispatch = useDispatch();
  const loadDependencies = () => {
    return Promise.all([loadCityAlertsForRoute(routeId)(dispatch)]);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={RouteTrackerCityAlertsModal}
      successProps={{ closeModal }}
    />
  );
};

export default RouteTrackerCityAlertsModalResolver;
