import styled from 'styled-components';
import { BareButtonStyle } from '../../../core/components/styled';
import { Icon } from '../../../core/components';

interface AccountStatusToggleIconProps {
  isOpen?: boolean;
}

export const AccountStatusToggleIcon = styled(Icon).attrs(({ isOpen }: AccountStatusToggleIconProps) => ({
  icon: isOpen ? 'minus' : 'plus',
}))<AccountStatusToggleIconProps>`
  width: 20px;
  height: 20px;
  transform: none;
  transition: transform 0.3s ease;
`;

export const AccountStatusToggle = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};

  position: absolute;
  right: 15px;
  top: 28px;
  font-size: 10px;
  color: #fff;
`;

export const CreateContainerToggle = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  right: -5px;
  top: 22px;
  font-size: 10px;
  color: #fff;
`;

export const CreateContainerToggleIcon = styled(Icon).attrs(({ isOpen }: AccountStatusToggleIconProps) => ({
  icon: 'plus',
}))<AccountStatusToggleIconProps>`
  width: 20px;
  height: 20px;
  transform: none;
  transition: transform 0.3s ease;
`;

export const RemovePhoneNumber = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  font-size: 10px;
  color: #fff;
`;

export const RemovePhoneNumberIcon = styled(Icon).attrs({
  icon: 'minus',
})`
  width: 20x;
  height: 20px;
  transform: none;
  transition: transform 0.3s ease;
`;
