import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorId } from '../../services/currentVendorSelector';
import { loadDriverSafetyInspectionSettings, loadSupervisorExperienceSettings } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import SupervisorExperienceSettingsModal from './SupervisorExperienceSettingsModal';

type Props = {
  closeModal: () => void;
};

export default function SupervisorExperienceSettingsModalResolver({ closeModal }: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const loadDependencies = () => {
    return Promise.all([
      loadSupervisorExperienceSettings(vendorId)(dispatch),
      loadDriverSafetyInspectionSettings(vendorId)(dispatch),
    ]);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={SupervisorExperienceSettingsModal}
      successProps={{ closeModal, vendorId }}
    />
  );
}
