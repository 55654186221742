import { parse, stringify } from 'qs';
import { pickBy, assign } from 'lodash-es';
import isFalsy from './isFalsy';

interface GetQueryOptions {
  parseAsString?: boolean;
  mapTypeIdsToArray?: boolean;
}

export const getQueryParams = <T extends {} = any>(
  queryString: string,
  options: GetQueryOptions = {
    parseAsString: false,
    mapTypeIdsToArray: false,
  },
) =>
  options.mapTypeIdsToArray
    ? (parse(queryString, {
        ignoreQueryPrefix: true,
        decoder(str, decoder, charset) {
          let safeStr: string = str.replace(/\+/g, ' ');
          const { parseAsString, mapTypeIdsToArray } = options;
          if (charset === 'iso-8859-1') {
            // unescape never throws, no try...catch needed:
            safeStr = safeStr.replace(/%[0-9a-f]{2}/gi, unescape);
          } else {
            safeStr = decodeURIComponent(safeStr);
          }

          if (!parseAsString && !mapTypeIdsToArray && !isNaN(parseFloat(safeStr)) && !safeStr.includes('/')) {
            return parseFloat(safeStr);
          }

          if (mapTypeIdsToArray && !safeStr.includes('/') && (!isNaN(parseFloat(safeStr)) || safeStr.includes(','))) {
            return safeStr.toString().split(',').map(parseFloat);
          }

          switch (safeStr) {
            case 'true':
              return true;
            case 'false':
              return false;
            case 'null':
              return null;
            case 'undefined':
              return undefined;
          }

          return safeStr;
        },
      }) as T)
    : (parse(queryString, { ignoreQueryPrefix: true }) as T);

export const setQueryParams = (queryString: string, params: any) =>
  stringify(
    pickBy(assign(getQueryParams(queryString), params), value => !isFalsy(value, { allowZero: true })),
    {
      addQueryPrefix: true,
      encode: true,
    },
  );

export const createUrl = (urlPath: string, queryString: any, params: any) =>
  `${urlPath}${setQueryParams(queryString, params)}`;

export const setQueryParamsWithBooleans = (queryString: string, params: any) =>
  stringify(
    pickBy(assign(getQueryParams(queryString), params), value => value !== undefined && value !== null && value !== ''),
    {
      addQueryPrefix: true,
      encode: true,
    },
  );

export const createUrlWithBooleans = (urlPath: string, queryString: any, params: any) =>
  `${urlPath}${setQueryParamsWithBooleans(queryString, params)}`;
