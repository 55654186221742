import { FC } from 'react';

import { camelCase, size } from 'lodash-es';
import { useSelector } from 'react-redux';
import { WrappedFieldInputProps, CommonFieldInputProps } from 'redux-form';

import { activeExceptionConfigurationsSelector } from '../../vendors/ducks/exceptionConfigurations';
import { AppState } from '../../store';
import { ExceptionType } from '../../routes/interfaces/Exceptions';
import { MultiSelect, TypedField } from '../../core/components';
import translate from '../../core/services/translate';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('routes.editJobStatus.allExceptions')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('routes.editJobStatus.noExceptions')
    : translate('routes.editJobStatus.xExceptionsSelected', { selected: size(selectedOptions) });

interface Props {
  exceptionTypes: ExceptionType[];
  input: WrappedFieldInputProps;
  dropdownProps?: CommonFieldInputProps;
  label?: string;
  placeholder?: string;
  withLabel?: boolean;
  withPlaceholder?: boolean;
}

const ExceptionTypeMultiSelect: FC<Props> = ({
  input,
  placeholder,
  label,
  withLabel,
  dropdownProps,
  withPlaceholder,
}) => {
  const exceptionTypes = useSelector((state: AppState) =>
    activeExceptionConfigurationsSelector(state),
  );

  const onChange = (event: any, value: number[] | string[]) => {
    input.onChange(value);
  };

  const getExceptionTypeOptions = () => {
    return exceptionTypes.map(et => ({
      label: translate(`routes.pickupIssueTypes.${camelCase(et.technicalName)}`),
      value: et.id.toString(),
    }));
  };

  return (
    <TypedField
      name={input.name}
      onChange={onChange}
      component={MultiSelect}
      props={{
        label: label || (withLabel ? translate('common.exceptionType') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('common.exceptionType') : undefined),
        options: getExceptionTypeOptions(),
        formatText,
        ...dropdownProps
      }}
    />
  );
}


export default ExceptionTypeMultiSelect;
