import { FC } from 'react';
import { getFormValues } from 'redux-form';
import { size } from 'lodash-es';

import { Button, Grid, ModalFixedFooter, Message, PanelSection, Text } from 'src/core/components/styled';
import { Modal, Table } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import AutomaticPaymentsTableRow, { AUTOMATIC_PAYMENTS_FORM } from './AutomaticPaymentsTableRow';
import translate from 'src/core/services/translate';

export const automaticPaymentsModalId = 'automatic-payments-modal-id';

const tableCells = [
  {
    name: 'automaticPayment',
    label: translate('finance.paymentManagement.setUpAutomaticPayments'),
    width: '30%',
  },
  {
    name: 'type',
    label: translate('common.type'),
    width: '30%',
  },
  {
    name: 'details',
    label: translate('finance.paymentManagement.details'),
    width: '40%',
  },
];

export const AutomaticPaymentsModal: FC<{
  closeModal: () => void;
  onSubmit: (paymentConnectPaymentMethodId?: string) => void;
  onUnenrollAutomaticPayments: () => void;
}> = ({ closeModal, onSubmit, onUnenrollAutomaticPayments }) => {
  const { isLoading, isSaving, paymentMethods } = useSelector(s => s.customers.paymentMethods);

  const formValues = useSelector(getFormValues(AUTOMATIC_PAYMENTS_FORM)) as any;
  const paymentConnectPaymentMethodId = formValues?.isDefaultAutoPay;

  const selectedDefaultPayment = (paymentMethods || []).filter(paymentMethod => !!paymentMethod.isDefaultAutoPay);
  const defaultAutomaticPayment = selectedDefaultPayment[0]?.paymentConnectPaymentMethodId;
  const isAutomaticPaymentSelected = selectedDefaultPayment?.length > 0;

  return (
    <Modal
      isLoading={isLoading || isSaving}
      onClose={closeModal}
      title={translate('finance.paymentManagement.automaticPayments')}
      padding="medium no no no"
      size="medium"
      id={automaticPaymentsModalId}
      overflow={isLoading || isSaving ? 'hidden' : 'auto'}
    >
      <PanelSection padding="small sMedium" minHeight={200} vertical>
        <Text size="large">{translate('finance.paymentManagement.automaticPaymentsMessage1')}</Text>
        <Text margin="small no" size="large">
          {translate('finance.paymentManagement.automaticPaymentsMessage2')}
        </Text>

        <Grid multiLine margin="no no medium no">
          {!!size(paymentMethods) ? (
            <Table
              cells={tableCells}
              rows={paymentMethods}
              rowComponent={AutomaticPaymentsTableRow}
              rowProps={{
                initialValues: { isDefaultAutoPay: defaultAutomaticPayment },
                defaultAutomaticPayment,
              }}
            />
          ) : (
            <Message padding="sMedium no">
              <em>{translate('customers.noPaymentMethods')}</em>
            </Message>
          )}
        </Grid>
      </PanelSection>

      <ModalFixedFooter isShadowed>
        <Button type="button" color="secondary" margin="no xSmall no no" onClick={() => closeModal()}>
          {translate('common.cancel')}
        </Button>

        <Button
          disabled={!isAutomaticPaymentSelected}
          onClick={() => onUnenrollAutomaticPayments()}
          color="primary"
          margin="no xSmall no xSmall"
        >
          {translate('finance.paymentManagement.unenrollFromAutomaticPayments')}
        </Button>
        <Button
          disabled={!paymentConnectPaymentMethodId}
          onClick={() => onSubmit(paymentConnectPaymentMethodId)}
          color="primary"
          margin="no no no xSmall"
        >
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </Modal>
  );
};
