import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadGroups } from 'src/routes/ducks';
import { RouteTemplateSimplified } from 'src/routes/interfaces/RouteTemplates';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RoutePlannerNewSchedulerModal from './RoutePlannerNewSchedulerModal';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';

interface Props {
  templates: RouteTemplateSimplified[];
  onClose(successful?: boolean): void;
  defaultDate?: Date;
}

const RoutePlannerNewSchedulerModalResolver: FC<Props> = ({ templates, defaultDate, onClose }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const vehicleTypesForVendor = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);

  const loadDependencies = useCallback(() => {
    const dependencies = [];
    if (templates.length === 0) {
      if (!vehicleTypesForVendor.length) {
        // we do this because in some are it triggers full page rerender which we don't want
        dependencies.push(loadVehicleTypesForVendor(vendorId)(dispatch));
      }
      dependencies.push(loadGroups(vendorId)(dispatch));
      return Promise.all(dependencies);
    } else return Promise.resolve();
  }, [dispatch, templates.length, vehicleTypesForVendor.length, vendorId]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={RoutePlannerNewSchedulerModal}
      successProps={{
        templates,
        defaultDate,
        onClose,
      }}
    />
  );
};

export default RoutePlannerNewSchedulerModalResolver;
