import { useDispatch } from 'react-redux';
import { PageLoading } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import RateBuilderPage from './RateBuilderPage';
import { loadEquipmentSizes, loadEquipmentTypes, loadPickupFrequencyTypes, loadServiceTypes } from 'src/common/ducks';
import { loadRateCodes, loadRateTypes, loadUOMs } from 'src/finance/ducks';
import { loadWasteMaterialTypes } from 'src/common/ducks/wasteMaterialTypes';

export interface RateBuilderRouteParams {}

const RateBuilderPageResolver = () => {
  const dispatch = useDispatch();

  const loadDependencies = () => {
    return Promise.all([
      loadPickupFrequencyTypes()(dispatch),
      loadServiceTypes()(dispatch),
      loadRateTypes()(dispatch),
      loadEquipmentTypes()(dispatch),
      loadEquipmentSizes()(dispatch),
      loadWasteMaterialTypes()(dispatch),
      loadRateCodes()(dispatch),
      loadUOMs()(dispatch),
    ]);
  };

  return <Resolver successComponent={RateBuilderPage} loadingComponent={PageLoading} resolve={loadDependencies} />;
};

export default RateBuilderPageResolver;
