import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from 'src/core/components/styled/Box';
import { CITY_ALERT_TYPES, CITY_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { getIconTypeForLocationAndCityAlert } from 'src/fleet/components/pages/locationAndCityAlertsSections/utils';
import { LocationAndCityAlert } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { MapGLPopup, MapGLPopupContent, MapGLPopupTitle } from 'src/common/components/map/MapGLPopup';
import { MapInfoImage, MapInfoImageWrapper } from 'src/core/components/styled';
import { ModalImage } from 'src/routes/interfaces/RouteImages';
import { resetCityAlertImages } from 'src/vendors/ducks';
import { RouteImagesModal } from 'src/routes/components/modals';
import { useSelector } from 'src/core/hooks/useSelector';
import CityAlertsGLPopupContent from './CityAlertsGLPopupContent';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  locationAndCityAlert: LocationAndCityAlert;
}

const CityAlertsGLPopup: FC<Props> = ({ locationAndCityAlert }) => {
  const dispatch = useDispatch();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const images = useSelector(state => state.vendors.cityAlerts.images);

  const isCityAlert = locationAndCityAlert.alertTypeId === CITY_ALERT_TYPE_ID;

  const cityAlertName =
    isCityAlert && locationAndCityAlert.cityAlertTypeId
      ? CITY_ALERT_TYPES[locationAndCityAlert.cityAlertTypeId].name
      : locationAndCityAlert.title;

  const modalImages: ModalImage[] = images.map(image => ({
    imageUrl: image.url,
    rawImageUrl: image.rawUrl,
    timeStamp: image.timeStamp || '',
    imageBlobId: image.blobId,
    routeName: cityAlertName,
    locationName: locationAndCityAlert.address,
  }));

  // clean up
  useEffect(
    () => () => {
      dispatch(resetCityAlertImages());
    },
    [dispatch],
  );

  return (
    <>
      <MapGLPopup width={250}>
        <MapGLPopupTitle
          beforeTitleContent={
            <Box mr={8} width={25} height={25}>
              {locationAndCityAlert && (
                <img
                  src={getIconTypeForLocationAndCityAlert(locationAndCityAlert)?.iconUrl}
                  width="25"
                  height="25"
                  alt=""
                />
              )}
            </Box>
          }
          title={cityAlertName}
        />

        <MapGLPopupContent>
          <CityAlertsGLPopupContent alert={locationAndCityAlert} isCityAlert={isCityAlert} />

          {!!images.length && (
            <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
              <Box mt={5}>
                <MapInfoImageWrapper
                  width="40%"
                  overlay={images.length > 1 ? images.length : undefined}
                  onClick={() => setIsImageModalOpen(true)}
                  noStyling
                >
                  <MapInfoImage src={images[0].url} />
                </MapInfoImageWrapper>
              </Box>
            </LabeledDataView>
          )}
        </MapGLPopupContent>
      </MapGLPopup>

      {!!isImageModalOpen && <RouteImagesModal images={modalImages} closeModal={() => setIsImageModalOpen(false)} />}
    </>
  );
};

export default CityAlertsGLPopup;
