import styled, { css } from 'styled-components';

import { Devices } from 'src/core/styles';
import { sizeMapper } from 'src/utils/styles';

interface Props {
  alignRight?: boolean;
  isSuccess?: boolean;
  margin?: string;
  withClose?: boolean;
  zIndex?: number;
}

export const SOSAlertWrapper = styled.div<Props>`
  position: fixed;
  left: calc(50% - 570px);
  top: 85px;
  width: 1140px;
  max-height: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: none;
  z-index: 6020;

  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `};

  @media ${Devices.laptop} {
    width: calc(100% - 30px);
    left: 15px;
  }
`;

export const SOSAlertContent = styled.div`
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  position: relative;
`;

export const SOSAlertInner = styled.div<Props>`
  width: 100%;
  margin-bottom: 15px;
  border: 10px solid ${props => (props.isSuccess ? props.theme.brandPrimary : props.theme.brandAlert)};
  border-top: 0;
  border-radius: 0 0 10px 10px;
  padding: 20px;
  background: #fff;
`;

export const Title = styled.div<Props>`
  background ${props => (props.isSuccess ? props.theme.brandPrimary : props.theme.brandAlert)};
  color: #fff;
  font-size: 22px;
  border-radius: 10px 10px 0 0;
  padding: 10px 10px;
`;

export const Address = styled.div`
  color: ${props => props.theme.brandPrimary};
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;

  span {
    cursor: pointer;
  }
`;

export const Name = styled.div<Props>`
  color: ${props => props.theme.brandDefault};
  margin: ${props => sizeMapper(props.margin, 'no')};
  font-size: 15px;
  font-weight: bold;
`;

export const LinkAlert = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const SOSIcon = styled.div<Props>`
  position: absolute;
  top: 12px;
  left: 10px;

  ${props =>
    props.alignRight &&
    css`
      left: auto;
      right: ${props.withClose ? '40px' : '10px'};
    `};
`;

export const SOSIconMap = styled.span`
  margin: 0 6px 0 0;
  position: relative;
  top: 4px;
`;
