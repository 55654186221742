import React, { PureComponent } from 'react';

import RouteLocationMarker from './RouteLocationMarker';
import { RubiconMarkerClusterer } from '../../common/components/RubiconMarkerClusterer';

interface Props {
  mapBounds?: any;
  currentMapZoom?: any;
  draggablePins?: any;
  geoFenceEditing?: boolean;
  onAddressChange?: (index?: number, serviceContractId?: number, updatedAddress?: google.maps.MapMouseEvent) => void;
  openedInfoWindows: any;
  isRoutePlannerPage?: boolean;
  routeLocations: any[];
  toggleInfoWindow: () => void;
}

class RouteLocations extends PureComponent<Props> {
  render() {
    const {
      draggablePins,
      geoFenceEditing,
      mapBounds,
      currentMapZoom,
      onAddressChange,
      openedInfoWindows,
      routeLocations,
      toggleInfoWindow,
      isRoutePlannerPage,
    } = this.props;

    return (
      <RubiconMarkerClusterer>
        {clusterer => (
          <>
            {routeLocations.map((routeLocation, index) => (
              <RouteLocationMarker
                isRoutePlannerPage={isRoutePlannerPage}
                mapBounds={mapBounds}
                currentMapZoom={currentMapZoom}
                clusterer={clusterer}
                geoFenceEditing={geoFenceEditing}
                index={index}
                isInfoWindowOpen={openedInfoWindows.routeLocations[index]}
                isPinDraggable={draggablePins}
                key={routeLocation.id || index}
                onAddressChange={onAddressChange}
                routeLocation={routeLocation}
                toggleInfoWindow={toggleInfoWindow}
              />
            ))}
          </>
        )}
      </RubiconMarkerClusterer>
    );
  }
}

export default RouteLocations;
