import React from 'react';
import { Source, Layer } from 'react-map-gl';

import { useSelector } from 'src/core/hooks/useSelector';
import {
  ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
  ROUTE_MAP_VEHICLE_TRACKINGS_POINTS_SOURCE,
  ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
} from '../constants';
import { VehicleTrackingFeatureProperties } from 'src/dashboard/components/pages/dashboardPageSections/mapGL/dashboardVehicleTrackings/utils';
import { Expression } from 'mapbox-gl';

type Props = {
  geoJSONForLine: GeoJSON.FeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>;
  geoJSONForPoints?: GeoJSON.FeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>;
};

export default React.memo(function RouteMapVehicleTrackingsGLSource({ geoJSONForLine, geoJSONForPoints }: Props) {
  const showSpeedColors = useSelector(state => state.routes.mapControls.showSpeedColors);

  const speedColorCondition: Expression = [
    'case',
    ['<', ['get', 'sp'], 8],
    'green',
    ['all', ['>=', ['get', 'sp'], 8], ['<=', ['get', 'sp'], 10]],
    'yellow',
    ['>', ['get', 'sp'], 10],
    'red',
    'gray',
  ];

  return (
    <>
      <Source type="geojson" id={ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE} data={geoJSONForLine} tolerance={0.0001}>
        <Layer
          beforeId={ROUTE_MAP_VEHICLE_POSITIONS_LAYER}
          id="allVehicleTrackingsLine"
          type="line"
          source={ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          paint={{
            'line-color': ['get', 'color'],
            'line-width': ['interpolate', ['exponential', 1], ['zoom'], 10, 1, 30, 4],
            'line-blur': 0.5,
          }}
        />
      </Source>
      {geoJSONForPoints && (
        <Source
          type="geojson"
          id={ROUTE_MAP_VEHICLE_TRACKINGS_POINTS_SOURCE}
          data={geoJSONForPoints}
          tolerance={0.0001}
        >
          <Layer
            id="allVehicleTrackingsPoints"
            type="circle"
            minzoom={15}
            source={ROUTE_MAP_VEHICLE_TRACKINGS_POINTS_SOURCE}
            paint={{
              'circle-radius': ['interpolate', ['exponential', 1], ['zoom'], 10, 5, 30, 10],
              'circle-color': showSpeedColors ? speedColorCondition : ['get', 'color'],
            }}
          />
          <Layer
            id="allVehicleTrackingsArrows"
            type="symbol"
            minzoom={15}
            source={ROUTE_MAP_VEHICLE_TRACKINGS_POINTS_SOURCE}
            layout={{
              'symbol-placement': 'point',
              'icon-allow-overlap': true,
              'icon-image': ['case', ['has', 'bearing'], 'arrow', 'dot'],
              'icon-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 0.8, 30, 1.5],
              'icon-rotate': ['case', ['has', 'bearing'], ['get', 'bearing'], 0],
            }}
            paint={{
              'icon-color': 'white',
            }}
          />
        </Source>
      )}
    </>
  );
});
