import { filter, map, orderBy, size } from 'lodash-es';
import { FC, useState } from 'react';

import { ActionButtonTooltip } from 'src/core/components';
import { TableActionButton, TableCell, TableRow } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { ExpandServices, ServicesContainer } from 'src/customers/components/styled/CustomerLocations';
import { ServiceContractExtraData } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import RouteTemplateBuilderServiceDetails from './RouteTemplateBuilderServiceDetails';

interface Props extends ServiceContractExtraData {
  index: number;
  serviceIds: number[];
}

const RouteTemplateBuilderServiceTableRow: FC<Props> = ({
  customerName,
  locationName,
  locationAddress,
  serviceContractsAtLocation,
  serviceIds,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow height={TABLE_ROW_HEIGHT_SMALL}>
        <TableCell width="25%" padding="defaultCellVertical small">
          {customerName}
        </TableCell>

        <TableCell width="25%" padding="defaultCellVertical small">
          {locationName}
        </TableCell>

        <TableCell width="25%" padding="defaultCellVertical small">
          {locationAddress}
        </TableCell>

        <TableCell width="15%" padding="defaultCellVertical small" align="center">
          {size(
            filter(
              serviceContractsAtLocation,
              serviceContract => !!serviceIds.includes(serviceContract.serviceContractId),
            ),
          )}
        </TableCell>

        <TableCell width="10%" padding="defaultCellVertical small defaultCellVertical no">
          {!!size(serviceContractsAtLocation) && (
            <ExpandServices>
              <TableActionButton
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                <ActionButtonTooltip
                  icon={isExpanded ? 'arrowUpFill' : 'arrowDownFill'}
                  tooltip={isExpanded ? 'hideServices' : 'showServices'}
                />
              </TableActionButton>
            </ExpandServices>
          )}
        </TableCell>
      </TableRow>

      {isExpanded && (
        <ServicesContainer withBorder>
          {map(
            orderBy(
              serviceContractsAtLocation,
              serviceContract => !serviceIds.includes(serviceContract.serviceContractId),
            ),
            (serviceContract) => (
              <RouteTemplateBuilderServiceDetails
                service={serviceContract}
                key={serviceContract.serviceContractId}
                isHighlighted={serviceIds.includes(serviceContract.serviceContractId)}
              />
            ),
          )}
        </ServicesContainer>
      )}
    </>
  );
};

export default RouteTemplateBuilderServiceTableRow;
