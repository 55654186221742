import React from 'react';
import { camelCase } from 'lodash-es';
import { change } from 'redux-form';
import { formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from 'src/store';
import { ActionButtonTooltip, Input, TypedField, Switch } from '../../../core/components';
import { BulkyItemTypes, CustomBulkyItemTypes } from 'src/vendors/interfaces/MaterialTypes';
import { Button, TableCell, TableRow, TableActionButton } from '../../../core/components/styled';
import { DELIVERY_UTILITY, FRONT_LOAD, RESIDENTIAL, ROLL_OFF, TOTER } from 'src/fleet/constants';
import { getExcludeVehicleFilters } from 'src/common/utils/filters';
import { isRequired } from 'src/utils/services/validator';
import { MATERIAL_TYPES_FORM } from 'src/vendors/constants/materialTypes';
import { OTHER_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { VehicleTypeForVendorMultiSelect } from 'src/fleet/components';
import translate from '../../../core/services/translate';

interface Props {
  bulkyItemTypes: BulkyItemTypes[];
  category: TechnicalType;
  index: number;
}

const formSelector = formValueSelector(MATERIAL_TYPES_FORM);

const MaterialTypesFormTableRow: React.FC<Props> = ({ bulkyItemTypes, category, index }) => {
  const dispatch = useDispatch();

  const filtersPreferences = useSelector((state: AppState) => state.common.filters.filters);
  const customBulkyItemTypes = useSelector(state => formSelector(state, `${OTHER_ID}.customBulkyItemTypes`)) || [];

  const excludeVehicleFilters = getExcludeVehicleFilters(filtersPreferences);

  const setNewBulkyItemType = (newBulkyItemType: CustomBulkyItemTypes[]) => {
    dispatch(change(MATERIAL_TYPES_FORM, `${OTHER_ID}.customBulkyItemTypes`, newBulkyItemType));
  };

  const addNewItem = () => {
    const newCustomBulkyItem = {
      customBulkyItemName: undefined,
      isActive: false,
      vehicleTypeId: undefined,
    };

    setNewBulkyItemType([...customBulkyItemTypes, newCustomBulkyItem]);
  };

  const deleteItem = (itemIndex: number) => {
    const newCustomBulkyItemTypes = customBulkyItemTypes.filter(
      (_: CustomBulkyItemTypes, index: number) => index !== itemIndex,
    );
    setNewBulkyItemType(newCustomBulkyItemTypes);
  };

  return (
    <>
      <TableRow>
        <TableCell width="100%">
          <b>{translate(`vendors.bulkyItemScheduler.categoryTypes.${camelCase(category.name)}`)}</b>
        </TableCell>
      </TableRow>

      {bulkyItemTypes &&
        !!bulkyItemTypes.length &&
        bulkyItemTypes.map((item, itemIndex) => {
          return (
            <TableRow key={`${index}_${itemIndex}`}>
              <TableCell width="5%"></TableCell>
              <TableCell width="35%">
                {translate(`vendors.bulkyItemScheduler.itemTypes.${camelCase(item.bulkyItem.technicalName)}`)}
              </TableCell>
              <TableCell width="25%" align="center">
                <TypedField name={`[${index}].bulkyItemTypes[${itemIndex}].isActive`} component={Switch} />
              </TableCell>
              <TableCell width="35%">
                <TypedField
                  component={VehicleTypeForVendorMultiSelect}
                  name={`[${index}].bulkyItemTypes[${itemIndex}].vehicleTypeId`}
                  props={{
                    withTechnicalName: false,
                    excludeVehicleTypes: excludeVehicleFilters,
                    multiSelectProps: {
                      defaultToAll: false,
                      margin: 'no',
                    },
                    acceptedVehicleTypes: [TOTER, DELIVERY_UTILITY, RESIDENTIAL, FRONT_LOAD, ROLL_OFF],
                  }}
                  validate={item.isActive ? [isRequired] : []}
                />
              </TableCell>
            </TableRow>
          );
        })}

      {customBulkyItemTypes &&
        !!customBulkyItemTypes.length &&
        category.id === OTHER_ID &&
        customBulkyItemTypes.map((_: CustomBulkyItemTypes, itemIndex: number) => {
          return (
            <TableRow key={`${index}_${itemIndex}`}>
              <TableCell width="5%"></TableCell>
              <TableCell width="35%">
                <TypedField
                  name={`[${index}].customBulkyItemTypes[${itemIndex}].customBulkyItemName`}
                  component={Input}
                  props={{
                    padding: 'no',
                    placeholder: translate('vendors.materialTypes.newMaterialType'),
                  }}
                  validate={[isRequired]}
                />
              </TableCell>
              <TableCell width="25%" align="center">
                <TypedField name={`[${index}].customBulkyItemTypes[${itemIndex}].isActive`} component={Switch} />
              </TableCell>
              <TableCell width="30%">
                <TypedField
                  component={VehicleTypeForVendorMultiSelect}
                  name={`[${index}].customBulkyItemTypes[${itemIndex}].vehicleTypeId`}
                  props={{
                    withTechnicalName: false,
                    excludeVehicleTypes: excludeVehicleFilters,
                    multiSelectProps: {
                      defaultToAll: false,
                      margin: 'no',
                      placeholder: translate('vendors.materialTypes.vehicleType'),
                    },
                    acceptedVehicleTypes: [TOTER, DELIVERY_UTILITY, RESIDENTIAL, FRONT_LOAD, ROLL_OFF],
                  }}
                  validate={[isRequired]}
                />
              </TableCell>

              <TableCell width="5%">
                <TableActionButton onClick={() => deleteItem(itemIndex)}>
                  <ActionButtonTooltip icon="delete" tooltip="delete" />
                </TableActionButton>
              </TableCell>
            </TableRow>
          );
        })}

      {category.id === OTHER_ID && (
        <TableRow>
          <TableCell width="5%"></TableCell>
          <TableCell width="30%">
            <Button color="primary" margin="no" type="button" size="small" onClick={addNewItem}>
              {translate('common.addNew')}
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MaterialTypesFormTableRow;
