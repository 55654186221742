import React, { PureComponent } from 'react';

import { Collapsible as CollapsibleContainer, CollapsibleContent } from '../../../../core/components/styled';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}

interface State {
  height: string | number;
}

class DashboardCollapsible extends PureComponent<Props, State> {
  contentRef: HTMLDivElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      height: props.isOpen ? 'auto' : 0,
    };
  }

  componentDidUpdate() {
    const { isOpen } = this.props;

    setTimeout(() => {
      this.setState({
        height: isOpen ? 'auto' : 0,
      });
    });
  }

  setContentRef = (element: HTMLDivElement | null) => {
    this.contentRef = element;
  };

  render() {
    return (
      <CollapsibleContainer style={{ height: this.state.height }}>
        <CollapsibleContent ref={this.setContentRef}>{this.props.children}</CollapsibleContent>
      </CollapsibleContainer>
    );
  }
}

export default DashboardCollapsible;
