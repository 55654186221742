import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getInvoiceStatuses } from 'src/finance/services/invoiceStatuses';

// Actions
const START_LOAD_INVOICE_STATUSES = 'finance/billing/invoiceStatuses/START_LOAD_INVOICE_STATUSES';
const COMPLETE_LOAD_INVOICE_STATUSES = 'finance/billing/invoiceStatuses/COMPLETE_LOAD_INVOICE_STATUSES';
const FAIL_LOAD_INVOICE_STATUSES = 'finance/billing/invoiceStatuses/FAIL_LOAD_INVOICE_STATUSES';

// Initial state
const initialState: {
  isLoading: boolean;
  invoiceStatuses: TechnicalType[];
} = {
  isLoading: false,
  invoiceStatuses: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_INVOICE_STATUSES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_INVOICE_STATUSES:
      return update(state, {
        $merge: {
          isLoading: false,
          invoiceStatuses: action.invoiceStatuses,
        },
      });

    case FAIL_LOAD_INVOICE_STATUSES:
      return update(state, {
        $merge: {
          isLoading: false,
          invoiceStatuses: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD_INVOICE_STATUSES,
});

const completeLoad = (invoiceStatuses: TechnicalType[]) => ({
  type: COMPLETE_LOAD_INVOICE_STATUSES,
  invoiceStatuses,
});

const failLoad = () => ({
  type: FAIL_LOAD_INVOICE_STATUSES,
});

export const loadInvoiceStatuses = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getInvoiceStatusesPromise = getInvoiceStatuses();
  getInvoiceStatusesPromise
    .then((invoiceStatuses: any[]) => dispatch(completeLoad(invoiceStatuses)))
    .catch(() => dispatch(failLoad()));
  return getInvoiceStatusesPromise;
};
