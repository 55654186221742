import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { TableActionButton } from '../../../../core/components/styled';
import { AppState } from '../../../../store';
import translate from '../../../../core/services/translate';
import { Table, ActionButtonTooltip } from '../../../../core/components';
import { VehicleTableWrapper } from '../../styled/VehiclesPage';
import VehicleMoreIndicator from './VehicleMoreIndicator';
import VehicleIssuesForm, { VehicleIssuesFormValues } from '../../forms/VehicleIssuesForm';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { Vehicle } from '../../../interfaces/Vehicle';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadVehicleIssues } from '../../../ducks/vehicleIssues';
import { issuesCells } from './vehiclePageCells';
import VehicleIssuesTableRow from './VehicleIssuesTableRow';
import { exportVehicleIssues } from '../../../services/vehicleIssues';
import VehiclePageSection from './VehiclePageSection';
import {
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM,
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO,
} from '../../../constants/vehicle';
import { useContainerScrollAtRockBottom } from '../../utils/vehiclePageHooks';

interface Props {
  isFleetMaintenanceFeatureEnabled: boolean;
}

const noop = () => {};

const VehicleIssuesSection: React.FC<RouteComponentProps & Props> = ({
  location: { search },
  isFleetMaintenanceFeatureEnabled,
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const issues = useSelector((state: AppState) => state.fleet.vehicleIssues.issues);
  const issuesLoading = useSelector((state: AppState) => state.fleet.vehicleIssues.issuesLoading);
  const issueIsUpdating = useSelector((state: AppState) => state.fleet.vehicleIssues.issueIsUpdating);
  const issueStatuses = useSelector((state: AppState) => state.fleet.vehicleIssues.issueStatuses);
  const drivers = useSelector((state: AppState) => state.fleet.vehicleIssues.drivers);
  const issueSubcategories = useSelector((state: AppState) => state.fleet.vehicleIssues.issueSubcategories);
  const vehicle: Vehicle | undefined = useSelector((state: AppState) => state.fleet.vehicle.vehicle);

  const { containerHasMaximumScroll, setRef, onScroll } = useContainerScrollAtRockBottom(issues.length);

  const { startDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM, endDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO } =
    getQueryParams(search);

  const allIssueStatuses = issueStatuses.map(status => status.id);
  const allDrivers = drivers;
  const allIssueSubcategories = issueSubcategories.map(category => category.id);

  const [issuesStatusIds, setIssuesStatusIds] = React.useState<number[]>(allIssueStatuses);
  const [issuesDrivers, setIssuesDrivers] = React.useState<string[]>(allDrivers);
  const [issuesSubcategoryIds, setIssuesSubcategoryIds] = React.useState<number[]>(allIssueSubcategories);

  const vehicleIssuesFormInitialValues: Partial<VehicleIssuesFormValues> = {
    statusIds: allIssueStatuses,
    driverNames: allDrivers,
    issueSubcategoryIds: allIssueSubcategories,
  };

  const vehicleId = !!vehicle ? vehicle.id : null;

  const onVehicleCriteriaChange = ({
    statusIds,
    driverNames,
    issueSubcategoryIds,
  }: Partial<VehicleIssuesFormValues>) => {
    setIssuesStatusIds(statusIds || []);
    setIssuesDrivers(driverNames || []);
    setIssuesSubcategoryIds(issueSubcategoryIds || []);
  };

  const reloadIssues = () => {
    if (!vehicle) {
      return;
    }

    loadVehicleIssues(vehicle.id, {
      startDate,
      endDate,
      statusIds: issuesStatusIds,
      driverNames: issuesDrivers,
      issueSubcategoryIds: issuesSubcategoryIds,
    })(dispatch);
  };

  const exportIssues = () => {
    if (!vehicle) {
      return;
    }

    exportVehicleIssues(vehicle.id, {
      startDate,
      endDate,
      statusIds: issuesStatusIds,
      driverNames: issuesDrivers,
      issueSubcategoryIds: issuesSubcategoryIds,
    });
  };

  React.useEffect(() => {
    if (!vendorId || !vehicleId) {
      return;
    }

    loadVehicleIssues(vehicleId, {
      startDate,
      endDate,
      statusIds: issuesStatusIds,
      driverNames: issuesDrivers,
      issueSubcategoryIds: issuesSubcategoryIds,
    })(dispatch);
  }, [dispatch, vendorId, vehicleId, startDate, endDate, issuesStatusIds, issuesDrivers, issuesSubcategoryIds]);

  return (
    <VehiclePageSection
      title={translate('vehicles.vehicleIssuesHeader')}
      sectionLoading={issuesLoading || issueIsUpdating}
      headerAction={
        <TableActionButton
          color="grayDarker"
          margin="no no no small"
          cursor="not-allowed"
          hoverColor={!issues.length ? 'grayDarker' : 'primary'}
          disabled={!issues.length}
          onClick={exportIssues}
        >
          <ActionButtonTooltip iconSize="sMedium" icon="download" tooltip="exportIssues" />
        </TableActionButton>
      }
      headerFilters={
        <VehicleIssuesForm
          initialValues={vehicleIssuesFormInitialValues}
          onSubmit={noop}
          onChange={onVehicleCriteriaChange}
        />
      }
      sectionDisabled={!issues.length}
      subtitle={
        !issues.length
          ? translate('vehicles.vehicleIssues.noIssues')
          : translate('vehicles.vehicleIssues.noOfIssues', { count: issues.length })
      }
      content={
        <>
          {!!issues.length && (
            <VehicleTableWrapper ref={setRef} onScroll={onScroll}>
              <Table
                cells={issuesCells}
                rows={issues}
                rowComponent={VehicleIssuesTableRow}
                rowProps={{
                  vehicleId: vehicle && vehicle.id,
                  reloadIssues,
                  isFleetMaintenanceFeatureEnabled,
                }}
              />
            </VehicleTableWrapper>
          )}

          <VehicleMoreIndicator disabled={containerHasMaximumScroll} />
        </>
      }
    />
  );
};

export default withRouter(VehicleIssuesSection);
