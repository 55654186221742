import { lineString } from '@turf/helpers';
import lineOffset from '@turf/line-offset';
import { filter, maxBy } from 'lodash-es';
import moment from 'moment';

import { getLastPassValue } from './alternativeFleet';
import { SegmentConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { SNOW_ROAD_CONDITIONS } from '../constants/cityInsightTypeConditions';
import { SnowRoadSegmentCondition } from '../interfaces/snowRoadConditions';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import store from 'src/store';

export const getNumberOfLanesForSegment = ({
  forwardPasses,
  reversePasses,
}: {
  forwardPasses: number;
  reversePasses: number;
}) => forwardPasses + reversePasses;

export const parseSegmentJSON = (lineSegment: string): number[][] => JSON.parse(lineSegment);

export const getSegmentLanes = (segment: SnowRoadSegmentCondition) => {
  const parsedSegment = parseSegmentJSON(segment.lineSegment);
  const noOfLanes = getNumberOfLanesForSegment(segment);
  const lanes: number[][][] = [];
  const forwardLanes: number[][][] = [];
  const reverseLanes: number[][][] = [];

  if (noOfLanes > 1) {
    const turfLine = lineString(parsedSegment);
    let offset = 4;

    for (let i = 1; i <= noOfLanes; i++) {
      const lane = lineOffset(turfLine, offset * (i % 2 === 0 ? -1 : 1), { units: 'meters' });

      lanes.push(lane.geometry.coordinates);

      if (i % 2 === 0) {
        offset += 8;
        reverseLanes.push(lane.geometry.coordinates);
      } else {
        forwardLanes.push(lane.geometry.coordinates);
      }
    }
  } else {
    lanes.push(parsedSegment);
    forwardLanes.push(parsedSegment);
  }

  return {
    allLanes: lanes,
    forwardLanes,
    reverseLanes,
  };
};

export const getSegmentCenter = (segment: number[][]) => segment[Math.floor(segment.length / 2)];

const rawGetSegmentColor = (lastPass?: string, rgba = false, conditionType?: string) => {
  const segmentConfiguration = filter(
    conditionType === SNOW_ROAD_CONDITIONS
      ? store.getState().vendors.snowPlowSettings.snowPlowSettings.displayConfiguration.segmentConfiguration
      : store.getState().vendors.streetSweepingSettings.streetSweepingSettings.segmentColorSettings,
    (configuration: SegmentConfiguration) => configuration.enabled,
  ) as SegmentConfiguration[];
  const { red, green, blue } = maxBy(
    segmentConfiguration,
    (configuration: SegmentConfiguration) => configuration.streetSegmentAgingInterval.minValue,
  ) as SegmentConfiguration;
  const defaultSegmentColor = rgba ? [red, green, blue] : `rgb(${red}, ${green}, ${blue})`;

  if (!lastPass) {
    return defaultSegmentColor;
  }

  const vendor = store.getState().vendors.vendor.vendor as undefined | Vendor;
  const lastPassInHours = moment(vendor?.currentVendorTime).diff(moment(lastPass), 'hours');
  const lastPassInDays = moment(vendor?.currentVendorTime).diff(moment(lastPass), 'days');
  const lastPassInWeeks = moment(vendor?.currentVendorTime).diff(moment(lastPass), 'weeks');
  const lastPassInMonths = moment(vendor?.currentVendorTime).diff(moment(lastPass), 'months');

  let segmentColor: string | number[] = defaultSegmentColor;

  const getColor = (r: number, g: number, b: number) => {
    return rgba ? [r, g, b] : `rgb(${r}, ${g}, ${b})`;
  };

  segmentConfiguration.forEach((segment: SegmentConfiguration) => {
    const {
      red,
      green,
      blue,
      streetSegmentAgingInterval: {
        minValue,
        maxValue,
        timeMeasurementType: { id: timeMeasurementTypeId },
      },
    } = segment;

    const lastPassValue = getLastPassValue(
      timeMeasurementTypeId,
      lastPassInHours,
      lastPassInDays,
      lastPassInWeeks,
      lastPassInMonths,
    );

    if (maxValue) {
      if (lastPassValue >= minValue && lastPassValue < maxValue) {
        segmentColor = getColor(red, green, blue);
      }
    } else if (lastPassValue >= minValue) {
      segmentColor = getColor(red, green, blue);
    }
  });

  return segmentColor;
};

export const getSegmentHexColor = (lastPass?: string, rgba = false) => rawGetSegmentColor(lastPass, false) as string;

export const getSegmentRGBColor = (lastPass?: string, conditionType?: string) =>
  rawGetSegmentColor(lastPass, true, conditionType) as [number, number, number];
