import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { map, size } from 'lodash-es';

import { DatePicker, Dropdown, Input, MultiSelect, TypedField } from 'src/core/components';
import { Button, ButtonSet, Grid, GridColumn, PanelSection, ToggleIcon } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';
import { SERVICE_STATUSES } from '../../constants/serviceStatuses';
import { SERVICE_TYPES } from '../../constants/serviceTypes';
import { ToggleContainer } from 'src/routes/components/styled';

interface FormValues {
  customerName: string;
  divisionId: string;
  serviceId: string;
  equipmentType: string;
  materialType: string;
  serviceStatusIds: string[];
  onExport: () => void;
}

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const RubiconServicesForm: React.FC<Props> = ({ handleSubmit }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [serviceType, setServiceType] = React.useState('' as string);
  const endDestinations = useSelector((state: AppState) => state.customers.rubiconServices.endDestinations);
  const rubiconServiceOptions = useSelector((state: AppState) => state.customers.rubiconTypes.rubiconServicesOptions);
  const divisionDropdown = rubiconServiceOptions.divisions ? rubiconServiceOptions.divisions : [];
  const equipmentDropdown = rubiconServiceOptions.equipments ? rubiconServiceOptions.equipments : [];
  const materialDropdown = rubiconServiceOptions.materials ? rubiconServiceOptions.materials : [];

  const endDestinationOptions = map(endDestinations, endDestination => ({
    label: endDestination.name,
    value: endDestination.facilityId,
  }));

  const serviceStatusTypeOptions = map(SERVICE_STATUSES, serviceStatusType => ({
    label: serviceStatusType.name,
    value: serviceStatusType.id,
  }));

  const serviceTypeOptions = map(SERVICE_TYPES, serviceType => ({
    label: serviceType.name,
    value: serviceType.id,
  }));

  const serviceStatusFormatText = (selectedOptions: string[], allOptionsSelected?: boolean) => {
    if (allOptionsSelected) {
      return translate('common.allStatuses');
    }

    return size(selectedOptions) === 1
      ? SERVICE_STATUSES[selectedOptions[0]].name
      : translate('common.xStatusesSelected', { selected: size(selectedOptions) });
  };

  const onToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const onServiceTypeChange = (event: any, serviceType: string) => {
    setServiceType(serviceType);
  };
  return (
    <form onSubmit={handleSubmit}>
      <PanelSection vertical padding="small xSmall" withBorder>
        <Grid>
          <GridColumn size="3/12">
            <TypedField
              name="divisionId"
              component={MultiSelect}
              props={{
                label: translate('haulerProfile.division'),
                options: divisionDropdown,
                isClearable: true,
                isSearchable: true,
              }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="customerName"
              component={Input}
              props={{ label: translate('customers.customerSearch'), isClearable: true }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="serviceId"
              component={Input}
              props={{ label: translate('customers.serviceId'), isClearable: true }}
            />
          </GridColumn>
          <GridColumn size={'1/12'}>
            <ToggleContainer id="toggle-filter-button" onClick={onToggle}>
              <ToggleIcon size={10} isOpen={isExpanded} icon={isExpanded ? 'remove' : 'add'} />
              {` ${translate('common.filter')}`}
            </ToggleContainer>
          </GridColumn>
          <GridColumn size="2/12" align="right">
            <ButtonSet margin="no no no">
              <Button type="submit" color="primary" id="save-container-button">
                {translate('common.search')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
        {!!isExpanded && (
          <>
            <Grid>
              <GridColumn size="3/12">
                <TypedField
                  name="equipmentType"
                  component={MultiSelect}
                  props={{
                    options: equipmentDropdown,
                    label: translate('common.equipmentType'),
                    isClearable: true,
                    isSearchable: true,
                  }}
                />
              </GridColumn>
              <GridColumn size="3/12">
                <TypedField
                  name="materialType"
                  component={MultiSelect}
                  props={{
                    options: materialDropdown,
                    label: translate('routes.materialType'),
                    isClearable: true,
                    isSearchable: true,
                  }}
                />
              </GridColumn>
              <GridColumn size="3/12">
                <TypedField
                  name="destinationId"
                  component={MultiSelect}
                  props={{
                    label: translate('opportunity.opportunities.endDestination'),
                    options: endDestinationOptions,
                    isClearable: true,
                    isSearchable: true,
                  }}
                />
              </GridColumn>
              <GridColumn size="3/12" verticalAlign="center" padding="no no no">
                <TypedField
                  component={MultiSelect}
                  name="serviceStatusIds"
                  props={{
                    margin: 'no',
                    options: serviceStatusTypeOptions,
                    placeholder: translate('common.status'),
                    formatText: serviceStatusFormatText,
                  }}
                />
              </GridColumn>
            </Grid>

            <Grid>
              <GridColumn size="3/12">
                <TypedField
                  component={Dropdown}
                  name="isTemp"
                  onChange={onServiceTypeChange}
                  props={{
                    options: serviceTypeOptions,
                    label: translate('common.serviceType'),
                    isClearable: true,
                  }}
                />
              </GridColumn>
              <GridColumn size="3/12">
                <TypedField
                  name="startDate"
                  component={DatePicker}
                  props={{ label: translate('common.startDate'), isClearable: true, disabled: !serviceType }}
                />
              </GridColumn>
              {serviceType === 'Y' && (
                <GridColumn size="3/12">
                  <TypedField
                    name="endDate"
                    component={DatePicker}
                    props={{ label: translate('common.endDate'), isClearable: true, disabled: !serviceType }}
                  />
                </GridColumn>
              )}
            </Grid>
          </>
        )}
      </PanelSection>
    </form>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'rubiconServicesHistory',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RubiconServicesForm);
