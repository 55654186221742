import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import doLoadDisposalSites from '../services/loadDisposalSites';

// Actions
const START_LOAD = 'insights/disposalSites/START_LOAD';
const COMPLETE_LOAD = 'insights/disposalSites/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/disposalSites/FAIL_LOAD';
const RESET = 'insights/disposalSites/RESET';

// Initial state
const initialState = {
  isLoading: false,
  disposalSites: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          disposalSites: action.disposalSites,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (disposalSites: any) => ({
  type: COMPLETE_LOAD,
  disposalSites,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDisposalSites = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDisposalSitesPromise = doLoadDisposalSites(vendorId);
  loadDisposalSitesPromise
    .then(disposalSites => dispatch(completeLoad(disposalSites)))
    .catch(() => dispatch(failLoad()));
  return loadDisposalSitesPromise;
};

export const resetDisposalSites = () => ({
  type: RESET,
});
