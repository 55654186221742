import styled, { css } from 'styled-components';
import { sizeMapper } from '../../../utils/styles';
import { loadingOverlay } from '../../styles';

interface ContainerProps {
  isLoading?: boolean;
  margin?: string;
  padding?: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const UploadButtonContainer = styled.div`
  position: relative;
  width: 80%;
`;

interface WrapperProps {
  height?: number;
  padding?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  height: ${p => p.height || 200}px;
  overflow-y: auto;
  padding: ${props => sizeMapper(props.padding, 'no')};
`;

export default Container;
