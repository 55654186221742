import React, { useState } from 'react';
import { FieldArray, reduxForm, WrappedFieldArrayProps } from 'redux-form';

import { Table } from '../../../core/components';
import { PhoneNumbersFormTableRow } from './';
import translate from './../../../core/services/translate';

const phoneNumbersTableCells = [
  { name: 'phoneNumber', label: translate('autoDispatch.phoneNumber'), width: '30%' },
  { name: 'firstName', label: translate('common.name'), width: '30%' },
  { name: 'options', label: translate('common.options'), width: '30%', align: 'right' },
];

function PhoneNumbersFormFieldArray({ fields }: WrappedFieldArrayProps) {
  const [editingRow, setEditingRow] = useState(null);
  const phoneNumbersTableRows = fields.map((fieldArrayKey, index, fields) => ({
    fieldArrayKey,
    index,
    fields,
  }));

  return (
    <Table
      cells={phoneNumbersTableCells}
      rows={phoneNumbersTableRows}
      rowComponent={PhoneNumbersFormTableRow}
      rowProps={{ editingRow, setEditingRow }}
    />
  );
}

function PhoneNumbersForm() {
  return (
    <form>
      <FieldArray name="phoneNumbers" component={PhoneNumbersFormFieldArray} props={{}} />
    </form>
  );
}

export default reduxForm<any, any>({
  form: 'phoneNumbers',
})(PhoneNumbersForm);
