import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import doLoadVehicleTrackings from '../services/loadVehicleTrackings';

// Actions
const START_LOAD = 'dashboard/vehicleTrackings/START_LOAD';
export const COMPLETE_LOAD = 'dashboard/vehicleTrackings/COMPLETE_LOAD';
const FAIL_LOAD = 'dashboard/vehicleTrackings/FAIL_LOAD';
const RESET = 'dashboard/vehicleTrackings/RESET';

// Initial state
const initialState = {
  isLoading: false,
  vehicleTrackings: [],
  showVehicleTrackingStatus: {
    showVehicleTracking: false,
    showAppStatus: false,
  },
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTrackings: action.vehicleTrackings,
          showVehicleTrackingStatus: {
            showVehicleTracking: action.showVehicleTracking,
            showAppStatus: action.showAppStatus,
          },
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTrackings: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTrackings: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleTrackings: any, showVehicleTracking: boolean, showAppStatus: boolean) => ({
  type: COMPLETE_LOAD,
  vehicleTrackings,
  showVehicleTracking,
  showAppStatus,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadVehicleTrackings =
  (vendorId: number, date: Date | string, vehicleIds: number[], showVehicleTracking: boolean, showAppStatus: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadVehicleTrackings(vendorId, date, vehicleIds)
      .then(vehicleTrackings => dispatch(completeLoad(vehicleTrackings, showVehicleTracking, showAppStatus)))
      .catch(() => dispatch(failLoad()));
  };

export const resetVehicleTrackings = () => ({
  type: RESET,
});

// Selectors
const getVehicleTrackings = (vehicleTrackingsState: any) => get(vehicleTrackingsState, 'vehicleTrackings') || [];
export const vehicleTrackingsSelector = createSelector(getVehicleTrackings, identity);

const getShowVehicleTrackingStatus = (vehicleTrackingsState: any) =>
  get(vehicleTrackingsState, 'showVehicleTrackingStatus', false) || {
    showVehicleTracking: false,
    showAppStatus: false,
  };
export const showVehicleTrackingStatusSelector = createSelector(getShowVehicleTrackingStatus, identity);
