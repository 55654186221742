import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RouteMapFeature, setRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { Trigger } from 'src/routes/interfaces/Trigger';
import {
  TRIGGERS_MAP_COLORS_LIST,
  TRIGGER_MAP_CLUSTER_SOURCE,
  TRIGGER_MAP_LAYER,
} from 'src/routes/constants/triggerMap';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import TriggerGLLayer from './TriggerGLLayer';
import TriggerMapGLPopup from './TriggerMapGLPopup';

type Props = {
  map: mapboxgl.Map;
  triggers: Trigger[];
};

const mapImages = TRIGGERS_MAP_COLORS_LIST.map(trigger => ({
  id: trigger.iconType,
  url: trigger.icon,
}));

const mapImagesOptions = { addLayers: false };

export default function TriggerGL({ map, triggers }: Props) {
  const dispatch = useDispatch();
  useMapImages(mapImages, map, mapImagesOptions);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature => feature.source === TRIGGER_MAP_CLUSTER_SOURCE && feature.properties?.layer === TRIGGER_MAP_LAYER,
          );

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.triggers, feature.properties?.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <TriggerGLLayer />
      <TriggerMapGLPopup triggers={triggers} />
    </>
  );
}
