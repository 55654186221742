import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { UpdateTrackerRouteSwitch } from '../../../core/components';
import { Grid, GridColumn, ButtonSet, Button } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface State {
  hasScheduledDailyRoutes: boolean;
}

interface PropsWithoutReduxForm extends RouteComponentProps {
  checkMethod: (selectedId: number, vendorId: number) => Promise<any>;
  onCancel: () => void;
  onDelete: (shouldRecreateRoutes: boolean) => void;
  selectedId: number;
  shouldRecreateRoutes: boolean;
  vendorId: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class DeleteCustomerLocationOrServiceForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasScheduledDailyRoutes: false,
    };
  }

  componentDidMount() {
    const { checkMethod, selectedId, vendorId } = this.props;

    checkMethod(selectedId, vendorId).then(response => {
      this.setState({ hasScheduledDailyRoutes: response });
    });
  }

  render() {
    const { onDelete, onCancel, shouldRecreateRoutes } = this.props;
    const { hasScheduledDailyRoutes } = this.state;

    return (
      <form>
        <Grid multiLine>
          <GridColumn size="12/12">
            <UpdateTrackerRouteSwitch align="center" hasScheduledDailyRoutes={hasScheduledDailyRoutes} />
          </GridColumn>
          <GridColumn size="12/12" verticalAlign="center">
            <ButtonSet margin="small auto no">
              <Button type="button" color="primary" onClick={() => onDelete(shouldRecreateRoutes)} id="yes-button">
                {translate('common.yes')}
              </Button>
              <Button type="button" color="secondary" margin="no no no small" id="no-button" onClick={() => onCancel()}>
                {translate('common.no')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = (getFormValues as any)('deleteCustomerLocationSeviceForm')(state);

  return {
    shouldRecreateRoutes: formValues ? formValues.shouldRecreateRoutes : false,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'deleteCustomerLocationSeviceForm',
      enableReinitialize: true,
    })(DeleteCustomerLocationOrServiceForm),
  ),
);
