import React, { PureComponent, Children, cloneElement } from 'react';
import { mapKeys, mapValues } from 'lodash-es';
import {
  Accordion as AccordionContainer,
  AccordionSection as AccordionSectionContainer,
  AccordionHead,
  AccordionTitle,
  AccordionToggleIcon,
} from './styled';
import Collapsible from './Collapsible';

interface Props {
  name: string;
  title: string;
  children: any;
  isOpen?: boolean;
  titleSize?: string;
  titleWeight?: string;
  toggle?: (name: string) => void;
}

export const AccordionSection: React.SFC<Props> = ({
  name,
  title,
  titleSize,
  titleWeight,
  children,
  isOpen,
  toggle,
}) => (
  <AccordionSectionContainer isOpen={isOpen}>
    <AccordionHead onClick={() => toggle && toggle(name)}>
      <AccordionTitle size={titleSize} weight={titleWeight}>
        {title}
      </AccordionTitle>
      <AccordionToggleIcon />
    </AccordionHead>
    <Collapsible isOpen={!!isOpen}>{children}</Collapsible>
  </AccordionSectionContainer>
);

interface AccordionProps {
  openedSections?: string[];
  canOpenMultiple?: boolean;
  margin?: string;
  children: any;
}

export class Accordion extends PureComponent<AccordionProps, { openedSections: { [key: string]: boolean } }> {
  constructor(props: AccordionProps) {
    super(props);

    const openedSections = mapValues(mapKeys(props.openedSections), () => true);
    this.state = { openedSections };
  }

  toggleSection = (section: string) => {
    this.setState(({ openedSections }) =>
      openedSections[section] || this.props.canOpenMultiple
        ? { openedSections: { ...openedSections, [section]: !openedSections[section] } }
        : { openedSections: { [section]: true } },
    );
  };

  render() {
    const { margin, children } = this.props;
    return (
      <AccordionContainer margin={margin}>
        {Children.map(children, child =>
          cloneElement(child, {
            isOpen: !!this.state.openedSections[child.props.name],
            toggle: this.toggleSection,
          }),
        )}
      </AccordionContainer>
    );
  }
}
