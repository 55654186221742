import { COI, SC, W9, OTH } from '../constants/documentTypesOption';
import translate from '../../core/services/translate';

const getModalTitle = (title: string, documentType?: string) => {
  const defaultTitle =
    title === 'Upload'
      ? translate('haulerProfile.uploadCertifications')
      : translate('haulerProfile.viewCertifications');

  switch (documentType) {
    case SC:
      return `${title} ${translate('haulerProfile.complianceDocument.selfCertification')}`;

    case W9:
      return `${title} ${translate('haulerProfile.complianceDocument.w9')}`;

    case COI:
      return `${title} ${translate('haulerProfile.complianceDocument.certificateOfInsurance')}`;

    case OTH:
      return `${translate('haulerProfile.addAgreement')}`;

    default:
      return defaultTitle;
  }
};

export default getModalTitle;
