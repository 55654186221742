import { http } from '../../core/services/http';

export const loadContainersList = (
  vendorId: number,
  containerTypeId?: number,
  equipmentTypeId?: number,
  equipmentSizeId?: number,
  equipmentConditionId?: number,
  searchTerm?: string,
  locationId?: number,
  cancelToken?: any,
) =>
  http
    .get(`/inventories/${vendorId}/containers/list`, {
      params: { containerTypeId, equipmentTypeId, equipmentSizeId, equipmentConditionId, searchTerm, locationId },
      cancelToken,
    })
    .then(response => response.data);
