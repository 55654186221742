import axios from 'axios';
import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import translate from 'src/core/services/translate';

import {
  TripInspection,
  VehicleBreadcrumbs,
  VehicleBreadCrumbsByRoutes,
  VehicleDevices,
  VehicleInsightDetails,
  VehiclePosition,
  VehicleRoutes,
  VehicleSafety,
  VehicleStatus,
} from 'src/dashboard/interfaces/vehiclePositions';
import { VehicleInspection } from 'src/fleet/interfaces/VehicleInspections';
import { dateTimeFormat } from 'src/utils/services/validator';
import { VehicleInformation } from '../interfaces/vehicleInformation';
import doLoadVehiclePositions from '../services/loadVehiclePositions';
import {
  loadDeviceData as doLoadDevices,
  loadRouteData as doLoadRouteData,
  loadVehicleBreadcrumbs as doLoadVehicleBreadcrumbs,
  loadVehicleInformation as doLoadVehicleInformation,
  loadVehicleInsightDetails as doLoadVehicleInsightDetails,
  loadVehicleInspection as doLoadVehicleInspection,
  loadVehicleSafetyData as doLoadVehicleSafety,
  loadVehiclesList as doLoadVehiclesList,
  loadVehicleStatus as doLoadVehicleStatus,
  loadVehicleBreadcrumbsByRoutes as doLoadVehicleBreadcrumbsByRoutes,
} from '../services/loadVehiclesList';

const CancelToken = axios.CancelToken;
let cancelLoadVehicles: any;

// Actions
const START_LOAD = 'dashboard/vehiclesData/START_LOAD';
export const COMPLETE_LOAD = 'dashboard/vehiclesData/COMPLETE_LOAD';
const RESET_VEHICLE_DETAILS = 'dashboard/vehiclesData/RESET_VEHICLE_DETAILS';
const FAIL_LOAD = 'dashboard/vehiclePositions/FAIL_LOAD';
const START_LOAD_VEHICLES_LIST = 'dashboard/vehiclePositions/START_LOAD_VEHICLES_LIST';
const COMPLETE_LOAD_VEHICLES_LIST = 'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLES_LIST';
const FAIL_LOAD_VEHICLES_LIST = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLES_LIST';
const START_LOAD_VEHICLE_INFORMATION = 'dashboard/vehiclePositions/START_LOAD_VEHICLE_INFORMATION';
const COMPLETE_LOAD_VEHICLE_INFORMATION = 'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLE_INFORMATION';
const FAIL_LOAD_VEHICLE_INFORMATION = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLE_INFORMATION';
const START_LOAD_VEHICLE_STATUS = 'dashboard/vehiclePositions/START_LOAD_VEHICLE_STATUS';
const COMPLETE_LOAD_VEHICLE_STATUS = 'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLE_STATUS';
const FAIL_LOAD_VEHICLE_STATUS = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLE_STATUS';
const START_LOAD_VEHICLE_BREADCRUMBS = 'dashboard/vehiclePositions/START_LOAD_VEHICLE_BREADCRUMBS';
const COMPLETE_LOAD_VEHICLE_BREADCRUMBS = 'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLE_BREADCRUMBS';
const FAIL_LOAD_VEHICLE_BREADCRUMBS = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLE_BREADCRUMBS';
const START_LOAD_VEHICLE_SAFETY = 'dashboard/vehiclePositions/START_LOAD_VEHICLE_SAFETY';
const COMPLETE_LOAD_VEHICLE_SAFETY = 'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLE_SAFETY';
const FAIL_LOAD_VEHICLE_SAFETY = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLE_SAFETY';
const START_LOAD_VEHICLE_INSIGHT_DETAILS = 'dashboard/vehiclePositions/START_LOAD_VEHICLE_INSIGHT_DETAILS';
const COMPLETE_LOAD_VEHICLE_INSIGHT_DETAILS = 'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLE_INSIGHT_DETAILS';
const FAIL_LOAD_VEHICLE_INSIGHT_DETAILS = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLE_INSIGHT_DETAILS';
const COMPLETE_SET_VEHICLE_INSIGHT_DETAILS = 'dashboard/vehiclePositions/COMPLETE_SET_VEHICLE_INSIGHT_DETAILS';
const START_LOAD_VEHICLE_INSPECTION = 'dashboard/vehiclePositions/START_LOAD_VEHICLE_INSPECTION';
const COMPLETE_LOAD_VEHICLE_INSPECTION = 'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLE_INSPECTION';
const FAIL_LOAD_VEHICLE_INSPECTION = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLE_INSPECTION';
const START_LOAD_ROUTE_DATA = 'dashboard/vehiclePositions/START_LOAD_ROUTE_DATA';
const COMPLETE_LOAD_ROUTE_DATA = 'dashboard/vehiclePositions/COMPLETE_LOAD_ROUTE_DATA';
const FAIL_LOAD_ROUTE_DATA = 'dashboard/vehiclePositions/FAIL_LOAD_ROUTE_DATA';
const START_LOAD_DEVICES = 'dashboard/vehiclePositions/START_LOAD_DEVICES';
const COMPLETE_LOAD_DEVICES = 'dashboard/vehiclePositions/COMPLETE_LOAD_DEVICES';
const FAIL_LOAD_DEVICES = 'dashboard/vehiclePositions/FAIL_LOAD_DEVICES';
const SET_SHOW_APP_STATUS = 'dashboard/vehiclePositions/SET_SHOW_APP_STATUS';
const START_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES =
  'dashboard/vehiclePositions/START_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES';
const COMPLETE_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES =
  'dashboard/vehiclePositions/COMPLETE_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES';
const FAIL_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES = 'dashboard/vehiclePositions/FAIL_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES';

const RESET = 'dashboard/vehiclesData/RESET';
const RESET_ACTIVE_VEHICLE_POSITIONS = 'dashboard/vehiclesData/RESET_ACTIVE_VEHICLE_POSITIONS';

// Initial state
const initialState = {
  devices: {} as VehicleDevices,
  isLoading: false,
  isLoadingDevices: false,
  isLoadingRouteData: false,
  isLoadingVehicleBreadcrumbs: false,
  isLoadingVehicleInformation: false,
  isLoadingVehicleInsightDetails: false,
  isLoadingVehicleInspection: false,
  isLoadingVehicleSafety: false,
  isLoadingVehicleStatus: false,
  isLoadingVehicleBreadcrumbsByRoutes: false,
  routeData: {} as VehicleRoutes,
  showAppStatus: false,
  vehicleBreadcrumbs: {} as VehicleBreadcrumbs,
  vehicleInformation: {} as VehicleInformation,
  vehicleInsightDetails: [] as VehicleInsightDetails[],
  vehicleInsights: undefined,
  vehicleInspection: [] as TripInspection[],
  vehiclePositions: undefined,
  vehicleSafety: {} as VehicleSafety,
  vehiclesList: [] as VehiclePosition[],
  lastRefreshed: '',
  vehicleStatus: {} as VehicleStatus,
  vehicleBreadcrumbsByRoutes: {} as VehicleBreadCrumbsByRoutes,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehiclePositions: action.vehiclePositions.vehiclePositions,
          vehicleInsights: action.vehiclePositions.vehicleInsights,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_LOAD_VEHICLES_LIST:
      if (action.noLoadingIndicator) return state;
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_VEHICLES_LIST:
      return update(state, {
        $merge: {
          isLoading: false,
          vehiclesList: action.vehiclesList,
          lastRefreshed: action.lastRefreshed,
        },
      });

    case FAIL_LOAD_VEHICLES_LIST:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_VEHICLE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingVehicleInformation: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingVehicleInformation: false,
          vehicleInformation: { ...action.vehicleInformation, date: action.date },
        },
      });

    case FAIL_LOAD_VEHICLE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingVehicleInformation: false,
        },
      });

    case START_LOAD_VEHICLE_STATUS:
      return update(state, {
        $merge: {
          isLoadingVehicleStatus: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_STATUS:
      return update(state, {
        $merge: {
          isLoadingVehicleStatus: false,
          vehicleStatus: action.vehicleStatus,
        },
      });

    case FAIL_LOAD_VEHICLE_STATUS:
      return update(state, {
        $merge: {
          isLoadingVehicleStatus: false,
        },
      });

    case START_LOAD_VEHICLE_BREADCRUMBS:
      return update(state, {
        $merge: {
          isLoadingVehicleBreadcrumbs: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_BREADCRUMBS:
      return update(state, {
        $merge: {
          isLoadingVehicleBreadcrumbs: false,
          vehicleBreadcrumbs: action.vehicleBreadcrumbs,
          lastRefreshed: action.lastRefreshed,
        },
      });

    case FAIL_LOAD_VEHICLE_BREADCRUMBS:
      return update(state, {
        $merge: {
          isLoadingVehicleBreadcrumbs: false,
        },
      });

    case START_LOAD_VEHICLE_SAFETY:
      return update(state, {
        $merge: {
          isLoadingVehicleSafety: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_SAFETY:
      return update(state, {
        $merge: {
          isLoadingVehicleSafety: false,
          vehicleSafety: action.vehicleSafety,
          lastRefreshed: action.lastRefreshed,
        },
      });

    case FAIL_LOAD_VEHICLE_SAFETY:
      return update(state, {
        $merge: {
          isLoadingVehicleSafety: false,
        },
      });

    case START_LOAD_VEHICLE_INSIGHT_DETAILS:
      return update(state, {
        $merge: {
          isLoadingVehicleInsightDetails: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_INSIGHT_DETAILS:
      return update(state, {
        $merge: {
          isLoadingVehicleInsightDetails: false,
          vehicleInsightDetails: [...state.vehicleInsightDetails, ...action.vehicleInsightDetails],
        },
      });

    case COMPLETE_SET_VEHICLE_INSIGHT_DETAILS:
      return update(state, {
        $merge: {
          vehicleInsightDetails: action.vehicleInsightDetails,
        },
      });

    case FAIL_LOAD_VEHICLE_INSIGHT_DETAILS:
      return update(state, {
        $merge: {
          isLoadingVehicleInsightDetails: false,
        },
      });

    case START_LOAD_VEHICLE_INSPECTION:
      return update(state, {
        $merge: {
          isLoadingVehicleInspection: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_INSPECTION:
      return update(state, {
        $merge: {
          isLoadingVehicleInspection: false,
          vehicleInspection: action.vehicleInspection,
        },
      });

    case FAIL_LOAD_VEHICLE_INSPECTION:
      return update(state, {
        $merge: {
          isLoadingVehicleInspection: false,
        },
      });

    case START_LOAD_ROUTE_DATA:
      return update(state, {
        $merge: {
          isLoadingRouteData: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_DATA:
      return update(state, {
        $merge: {
          isLoadingRouteData: false,
          routeData: action.routeData,
        },
      });

    case FAIL_LOAD_ROUTE_DATA:
      return update(state, {
        $merge: {
          isLoadingRouteData: false,
        },
      });

    case START_LOAD_DEVICES:
      return update(state, {
        $merge: {
          isLoadingDevices: true,
        },
      });

    case COMPLETE_LOAD_DEVICES:
      return update(state, {
        $merge: {
          isLoadingDevices: false,
          devices: action.devices,
        },
      });

    case SET_SHOW_APP_STATUS:
      return update(state, {
        $merge: {
          showAppStatus: action.showAppStatus,
        },
      });

    case START_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES:
      return update(state, {
        $merge: {
          isLoadingVehicleBreadcrumbsByRoutes: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES:
      return update(state, {
        $merge: {
          isLoadingVehicleBreadcrumbsByRoutes: false,
          vehicleBreadcrumbsByRoutes: action.vehicleBreadcrumbsByRoutes,
        },
      });

    case FAIL_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES:
      return update(state, {
        $merge: {
          isLoadingVehicleBreadcrumbsByRoutes: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    case RESET_ACTIVE_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          vehicleInsights: undefined,
        },
      });

    // Reset vehicle details except for vehicle list
    case RESET_VEHICLE_DETAILS:
      return update(state, {
        $merge: {
          routeData: {} as VehicleRoutes,
          vehicleBreadcrumbs: {} as VehicleBreadcrumbs,
          vehicleInsights: undefined,
          vehicleInspection: [] as TripInspection[],
          vehiclePositions: undefined,
          vehicleSafety: {} as VehicleSafety,
          devices: {} as VehicleDevices,
          showAppStatus: false,
          vehicleBreadcrumbsByRoutes: {} as VehicleBreadCrumbsByRoutes,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehiclePositions: VehiclePosition) => ({
  type: COMPLETE_LOAD,
  vehiclePositions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadVehiclesList = (noLoadingIndicator?: boolean) => ({
  type: START_LOAD_VEHICLES_LIST,
  noLoadingIndicator,
});

const completeLoadVehiclesList = (vehiclesList: any) => ({
  type: COMPLETE_LOAD_VEHICLES_LIST,
  vehiclesList,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadVehiclesList = () => ({
  type: FAIL_LOAD_VEHICLES_LIST,
});

const startLoadVehicleInformation = () => ({
  type: START_LOAD_VEHICLE_INFORMATION,
});

const completeLoadVehicleInformation = (vehicleInformation: VehicleInformation, date?: Date | string) => ({
  type: COMPLETE_LOAD_VEHICLE_INFORMATION,
  vehicleInformation,
  date,
});

const failLoadVehicleInformation = () => ({
  type: FAIL_LOAD_VEHICLE_INFORMATION,
});

const startLoadVehicleStatus = () => ({
  type: START_LOAD_VEHICLE_STATUS,
});

const completeLoadVehicleStatus = (vehicleStatus: VehicleStatus) => ({
  type: COMPLETE_LOAD_VEHICLE_STATUS,
  vehicleStatus,
});

const failLoadVehicleStatus = () => ({
  type: FAIL_LOAD_VEHICLE_STATUS,
});

const startLoadVehicleBreadcrumbs = () => ({
  type: START_LOAD_VEHICLE_BREADCRUMBS,
});

const completeLoadVehicleBreadcrumbs = (vehicleBreadcrumbs: VehicleBreadcrumbs) => ({
  type: COMPLETE_LOAD_VEHICLE_BREADCRUMBS,
  vehicleBreadcrumbs,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadVehicleBreadcrumbs = () => ({
  type: FAIL_LOAD_VEHICLE_BREADCRUMBS,
});

const startLoadVehicleSafety = () => ({
  type: START_LOAD_VEHICLE_SAFETY,
});

const completeLoadVehicleSafety = (vehicleSafety: VehicleSafety) => ({
  type: COMPLETE_LOAD_VEHICLE_SAFETY,
  vehicleSafety,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadVehicleSafety = () => ({
  type: FAIL_LOAD_VEHICLE_SAFETY,
});

const startLoadVehicleInsightDetails = () => ({
  type: START_LOAD_VEHICLE_INSIGHT_DETAILS,
});

const completeLoadVehicleInsightDetails = (vehicleInsightDetails: VehicleInsightDetails[]) => ({
  type: COMPLETE_LOAD_VEHICLE_INSIGHT_DETAILS,
  vehicleInsightDetails,
});

const failLoadVehicleInsightDetails = () => ({
  type: FAIL_LOAD_VEHICLE_INSIGHT_DETAILS,
});

const completeSetVehicleInsightDetails = (vehicleInsightDetails: VehicleInsightDetails[]) => ({
  type: COMPLETE_SET_VEHICLE_INSIGHT_DETAILS,
  vehicleInsightDetails,
});

const startLoadVehicleInspection = () => ({
  type: START_LOAD_VEHICLE_INSPECTION,
});

const completeLoadVehicleInspection = (vehicleInspection: VehicleInspection) => ({
  type: COMPLETE_LOAD_VEHICLE_INSPECTION,
  vehicleInspection,
});

const failLoadVehicleInspection = () => ({
  type: FAIL_LOAD_VEHICLE_INSPECTION,
});

const startLoadRouteData = () => ({
  type: START_LOAD_ROUTE_DATA,
});

const completeLoadRouteData = (routeData: any) => ({
  type: COMPLETE_LOAD_ROUTE_DATA,
  routeData,
});

const failLoadRouteData = () => ({
  type: FAIL_LOAD_ROUTE_DATA,
});

const startLoadDevices = () => ({
  type: START_LOAD_DEVICES,
});

const completeLoadDevices = (devices: any) => ({
  type: COMPLETE_LOAD_DEVICES,
  devices,
});

const failLoadDevices = () => ({
  type: FAIL_LOAD_DEVICES,
});

export const resetVehicleDetails = () => ({
  type: RESET_VEHICLE_DETAILS,
});

export const setShowVehicleAppStatus = (showAppStatus: boolean) => ({
  type: SET_SHOW_APP_STATUS,
  showAppStatus,
});

const startLoadVehicleBreadCrumbsByRoutes = () => ({
  type: START_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES,
});

const completeLoadVehicleBreadCrumbsByRoutes = (vehicleBreadcrumbsByRoutes: VehicleBreadCrumbsByRoutes) => ({
  type: COMPLETE_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES,
  vehicleBreadcrumbsByRoutes,
});

const failLoadVehicleBreadCrumbsByRoutes = () => ({
  type: FAIL_LOAD_VEHICLE_BREAD_CRUMBS_BY_ROUTES,
});

export const loadVehiclePositions =
  (vendorId: number, date: Date | string, vehicleIds: number[]) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadVehiclePositions(vendorId, date, vehicleIds)
      .then(vehiclePositions => dispatch(completeLoad(vehiclePositions)))
      .catch(() => dispatch(failLoad()));
  };

export const loadVehiclesList =
  (
    date: Date | string,
    includeInactive?: boolean,
    vehicleTypeIds?: string,
    supervisorIds?: string,
    searchTerm?: string,
    driverSearchTerm?: string,
    noLoadingIndicator?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    if (cancelLoadVehicles) cancelLoadVehicles(translate('common.inFlightRequestCanceled'));

    dispatch(startLoadVehiclesList(noLoadingIndicator));

    const cancelToken = new CancelToken(c => {
      cancelLoadVehicles = c;
    });

    return doLoadVehiclesList(
      date,
      includeInactive,
      vehicleTypeIds,
      supervisorIds,
      searchTerm,
      driverSearchTerm,
      cancelToken,
    )
      .then(vehiclesList => dispatch(completeLoadVehiclesList(vehiclesList.vehicles)))
      .catch(err => !axios.isCancel(err) && dispatch(failLoadVehiclesList()));
  };

export const loadVehicleInformation = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleInformation());
  return doLoadVehicleInformation(vehicleId, date)
    .then(vehicleInformation => dispatch(completeLoadVehicleInformation(vehicleInformation, date)))
    .catch(() => dispatch(failLoadVehicleInformation()));
};

export const loadVehicleStatus = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleStatus());
  return doLoadVehicleStatus(vehicleId, date)
    .then(vehicleStatus => dispatch(completeLoadVehicleStatus(vehicleStatus)))
    .catch(() => dispatch(failLoadVehicleStatus()));
};

export const loadVehicleBreadcrumbs = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleBreadcrumbs());
  return doLoadVehicleBreadcrumbs(vehicleId, date)
    .then(vehicleBreadcrumbs => dispatch(completeLoadVehicleBreadcrumbs(vehicleBreadcrumbs)))
    .catch(() => dispatch(failLoadVehicleBreadcrumbs()));
};

export const loadVehicleSafety = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleSafety());
  return doLoadVehicleSafety(vehicleId, date)
    .then(vehicleSafety => dispatch(completeLoadVehicleSafety(vehicleSafety)))
    .catch(() => dispatch(failLoadVehicleSafety()));
};

export const loadVehicleInsightDetails =
  (vehicleId?: number, insightTypeId?: number, date?: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoadVehicleInsightDetails());
    return doLoadVehicleInsightDetails(vehicleId, insightTypeId, date)
      .then(response => {
        const vehicleInsightDetails = response.insights.map((insight: VehicleInsightDetails) => {
          return {
            ...insight,
            count: response.count,
            insightTypeName: response.insightType.name,
            insightTypeId: response.insightType.id,
          };
        });

        return dispatch(completeLoadVehicleInsightDetails(vehicleInsightDetails));
      })
      .catch(() => dispatch(failLoadVehicleInsightDetails()));
  };

export const setVehicleInsightDetails = (vehicleInsightDetails: VehicleInsightDetails[]) => (dispatch: Dispatch) => {
  return dispatch(completeSetVehicleInsightDetails(vehicleInsightDetails));
};

export const loadVehicleInspection = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleInspection());
  return doLoadVehicleInspection(vehicleId, date)
    .then(vehicleInspection => dispatch(completeLoadVehicleInspection(vehicleInspection)))
    .catch(() => dispatch(failLoadVehicleInspection()));
};

export const loadRouteData = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteData());
  return doLoadRouteData(vehicleId, date)
    .then(routeData => dispatch(completeLoadRouteData(routeData)))
    .catch(() => dispatch(failLoadRouteData()));
};

export const loadDevices = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadDevices());
  return doLoadDevices(vehicleId)
    .then(devices => dispatch(completeLoadDevices(devices)))
    .catch(() => dispatch(failLoadDevices()));
};

export const loadVehicleBreadCrumbsByRoutes = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleBreadCrumbsByRoutes());
  return doLoadVehicleBreadcrumbsByRoutes(vehicleId, date)
    .then(vehicleBreadcrumbsByRoutes => dispatch(completeLoadVehicleBreadCrumbsByRoutes(vehicleBreadcrumbsByRoutes)))
    .catch(() => dispatch(failLoadVehicleBreadCrumbsByRoutes()));
};

export const resetVehiclePositions = () => ({
  type: RESET,
});

export const resetActiveVehiclePositions = () => ({
  type: RESET_ACTIVE_VEHICLE_POSITIONS,
});

// Selectors
const getVehiclePositions = (vehiclePositionsState: any) => get(vehiclePositionsState, 'vehiclePositions') || [];
export const vehiclePositionsSelector = createSelector(getVehiclePositions, identity);

const getVehicleInsights = (vehiclePositionsState: any) => get(vehiclePositionsState, 'vehicleInsights') || [];
export const vehicleInsightsSelector = createSelector(getVehicleInsights, identity);
