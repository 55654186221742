import React from 'react';

import { Table } from 'src/core/components';
import RouteTemplateBuilderWorkSessionListViewTableRow from './RouteTemplateBuilderWorkSessionListViewTableRow';
import translate from 'src/core/services/translate';
import { TableCell } from 'src/core/components/Table';
import useServicesAvailableForSelection from 'src/routes/hooks/useServicesAvailableForSelection';

const cells: TableCell[] = [
  {
    name: 'id',
    label: 'ID',
    padding: 'defaultCellVertical small',
    width: '17%',
    sortable: true,
  },
  {
    name: 'latitude',
    label: 'Latitude',
    padding: 'defaultCellVertical small',
    width: '17%',
    sortable: true,
  },
  {
    name: 'longitude',
    label: 'Longitude',
    padding: 'defaultCellVertical small',
    width: '17%',
    sortable: true,
  },
  {
    name: 'options',
    label: translate('routeTemplateBuilder.options'),
    padding: 'defaultCellVertical small defaultCellVertical no',
    width: '13%',
  },
];

export default function RouteTemplateBuilderWorkSessionListView() {
  const services = useServicesAvailableForSelection();

  return (
    <Table
      cells={cells}
      rows={services}
      rowComponent={RouteTemplateBuilderWorkSessionListViewTableRow}
      tableHeadProps={{ sticky: true }}
    />
  );
}
