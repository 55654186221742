import styled from 'styled-components';
import { transparentize } from 'polished';
import Icon from '../Icon';
import { sizeMapper } from '../../../utils/styles';

export const FileInputToggleInput = styled.input`
  display: none;
`;

export const FileInputToggleIcon = styled(Icon)`
  vertical-align: middle;
  width: 17px;
  height: 17px;
  color: ${props => props.theme.brandPrimary};
`;

export const FileInputToggleText = styled.span`
  vertical-align: middle;

  ${FileInputToggleIcon} + & {
    margin-left: 10px;
  }
`;

export const FileInputToggleLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 13px;
`;

export const FileInputToggle = styled.div<{ margin?: string }>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  color: ${props => props.theme.brandPrimary};
  background: ${props => transparentize(0.9, props.theme.brandPrimary)};
  padding: 20px 30px;
  border: 2px dashed ${props => props.theme.brandPrimary};
  border-radius: 5px;
  text-align: center;
`;
