import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';

interface Props {
  customerName: string;
  id: number;
  importStatusName: string;
  locationName: string;
  push: any;
}

const RouteImportStatusesPageTableRow: React.FC<Props> = ({
  id,
  customerName,
  locationName,
  importStatusName,
  push,
}) => (
  <TableRow onClick={() => push(`/route-import/details?importDetailId=${id}`)}>
    <TableCell width="35%">{customerName}</TableCell>
    <TableCell width="35%">{locationName}</TableCell>
    <TableCell width="30%">{importStatusName}</TableCell>
  </TableRow>
);

export default RouteImportStatusesPageTableRow;
