import { useDispatch } from 'react-redux';

import { deleteDefaultVendor } from 'src/vendors/ducks';
import { loadBlogPosts, loadPublicPolicyPosts, loadTwitterPosts, logout } from '../../ducks';
import { LoginPage } from './';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';

export default function LoginPageResolver() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.account.login.isLoggedIn);

  const loadDependencies = () => {
    const dependencies = [
      deleteDefaultVendor()(dispatch),
      loadBlogPosts()(dispatch),
      loadPublicPolicyPosts()(dispatch),
      loadTwitterPosts()(dispatch),
    ];

    if (isLoggedIn) dependencies.push(logout()(dispatch));

    return Promise.all(dependencies);
  };

  return <Resolver resolve={loadDependencies} successComponent={LoginPage} loadingComponent={PageLoading} />;
}
