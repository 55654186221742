import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash-es';

import { ACCOUNT_SETTINGS_MANAGE_USERS } from '../../../account/constants';
import { ADMIN } from '../../../vendors/constants/userRoles';
import { AppState } from '../../../store';
import { ButtonLink, Grid, GridColumn } from '../../../core/components/styled';
import { PermissionGuard } from '../../../account/components';
import { ROUTE_START_TIMES_LIST } from '../../constants/routeStartTimes';
import { TIME_ZONES_LIST } from '../../constants/timeZones';
import LabeledDataView from '../../../core/components/LabeledDataView';
import translate from '../../../core/services/translate';
import { Vendor } from '../../interfaces/Vendors';
import { isAdminSelector, isSuperAdminSelector } from '../../../account/ducks';
import { RUBICON_PRO_PORTAL, RUBICON_X } from '../../constants';
import { breakDownDeviceRoleTypeId } from '../../services/vendorEditorFormInitialValuesSelector';
import QuickBooksButton from './components/QuickBooksButton';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { checkIsBillingActive } from 'src/vendors/ducks';

interface Props {
  roleId?: number;
}

const timeZoneOptions = TIME_ZONES_LIST.map(zone => ({
  label: zone.name,
  value: zone.id,
}));

const routeStartTimeOptions = ROUTE_START_TIMES_LIST.map(time => ({
  label: time.name,
  value: time.id,
}));

const AccountSettingsVendorDisplay: React.FC<Props> = ({ roleId }) => {
  const dispatch = useDispatch();
  const vendor = useSelector((state: AppState) => state.vendors.vendor.vendor) as any as Vendor;

  const isRubiconAdmin = useSelector((state: AppState) => {
    const loginState = state.account.login;
    return isAdminSelector(loginState) || isSuperAdminSelector(loginState);
  });

  const isBillingFeatureActive = useSelector((state: AppState) =>
    billingFeatureStatusSelector(state.vendors.features.features),
  );
  const { isBillingActive } = useSelector((state: AppState) => state.vendors.stripeAccount);

  useEffect(() => {
    if (isBillingFeatureActive) {
      checkIsBillingActive(vendor.id)(dispatch);
    }
  }, [dispatch, isBillingFeatureActive, vendor.id]);

  const selectedTimeZone = timeZoneOptions.find(option => option.value === vendor?.timeZone);
  const selectedRouteStartTime = routeStartTimeOptions.find(option => option.value === vendor.routeStartTime);

  const isUserAdmin = roleId === ADMIN || isRubiconAdmin;

  return (
    <Grid multiLine>
      <GridColumn size="6/12" padding="no xSmall small no">
        <LabeledDataView label={translate('common.name')} value={vendor.name} />
      </GridColumn>

      {vendor.vendorTypeId !== RUBICON_PRO_PORTAL && (
        <GridColumn size="6/12" padding="no no small xSmall">
          <LabeledDataView
            noWrap
            label={translate('vendors.timeZone')}
            value={selectedTimeZone && selectedTimeZone.label}
          />
        </GridColumn>
      )}

      <GridColumn size="6/12" padding="no xSmall no no">
        <LabeledDataView
          noWrap
          label={translate('common.address')}
          value={vendor.homeAddress && vendor.homeAddress.formattedAddress}
        />
      </GridColumn>

      {vendor.vendorTypeId !== RUBICON_PRO_PORTAL && (
        <GridColumn size="6/12" padding="no no small xSmall">
          <LabeledDataView
            label={translate('vendors.routeStartTime')}
            value={selectedRouteStartTime && selectedRouteStartTime.label}
          />
        </GridColumn>
      )}

      <GridColumn size="12/12" padding="no no small no" verticalAlign="flex-end">
        {isBillingFeatureActive && <QuickBooksButton />}
        {isBillingFeatureActive && (
          <ButtonLink
            line
            to="/finance/onboarding"
            color="primary"
            disabled={isBillingActive}
            size="small"
            margin="no small"
          >
            {translate(
              `finance.stripeIntegration.${isBillingActive ? 'stripeAccountActive' : 'activateStripeAccount'}`,
            )}
          </ButtonLink>
        )}
        <PermissionGuard permission={ACCOUNT_SETTINGS_MANAGE_USERS}>
          <ButtonLink line to="/users" color="primary" size="small">
            {translate('vendors.manageUsers')}
          </ButtonLink>
        </PermissionGuard>
        {vendor.vendorTypeId !== RUBICON_PRO_PORTAL &&
          vendor.deviceRoleTypeId &&
          includes(breakDownDeviceRoleTypeId(vendor.deviceRoleTypeId), RUBICON_X) &&
          vendor.activateAppLicenses &&
          isUserAdmin && (
            <PermissionGuard permission={ACCOUNT_SETTINGS_MANAGE_USERS}>
              <ButtonLink margin="no small" line to="/account-settings/manage-devices" color="primary" size="small">
                {translate('vendors.manageDevices')}
              </ButtonLink>
            </PermissionGuard>
          )}
      </GridColumn>
    </Grid>
  );
};

export default AccountSettingsVendorDisplay;
