import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { EmailsRecipientsResponseData } from '../interfaces/sendBill';

import {
  loadEmailRecipients as doLoadEmailRecipients,
} from '../services/sendBill';

// Actions
const START_LOAD_EMAIL_RECIPIENTS = 'finance/sendBill/START_LOAD_EMAIL_RECIPIENTS';
const COMPLETE_LOAD_EMAIL_RECIPIENTS = 'finance/sendBill/sendBill/COMPLETE_LOAD_EMAIL_RECIPIENTS';
const FAIL_LOAD_EMAIL_RECIPIENTS = 'finance/sendBill/FAIL_LOAD_EMAIL_RECIPIENTS';
const RESET = 'finance/sendBill/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  emailRecipients?: EmailsRecipientsResponseData[];
} = {
  isLoading: false,
  emailRecipients: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_EMAIL_RECIPIENTS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_EMAIL_RECIPIENTS:
      return update(state, {
        $merge: {
          isLoading: false,
          emailRecipients: action.emailRecipients,
        },
      });

    case FAIL_LOAD_EMAIL_RECIPIENTS:
      return update(state, {
        $merge: {
          isLoading: false,
          emailRecipients: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoadEmailRecepients = () => ({
  type: START_LOAD_EMAIL_RECIPIENTS,
});

const completeLoadEmailRecepients = (emailRecipients: any) => ({
  type: COMPLETE_LOAD_EMAIL_RECIPIENTS,
  emailRecipients,
});

const failLoadEmailRecepients = () => ({
  type: FAIL_LOAD_EMAIL_RECIPIENTS,
});

export const loadEmailRecipients =
  (invoiceId: number) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadEmailRecepients());
    const loadOpenBillsPromise = doLoadEmailRecipients(invoiceId);
    loadOpenBillsPromise.then((emailRecipients: any) => dispatch(completeLoadEmailRecepients(emailRecipients))).catch(() => dispatch(failLoadEmailRecepients()));
    return loadOpenBillsPromise;
  };

export const resetOpenBills = () => ({
  type: RESET,
});
