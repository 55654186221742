import { useEffect } from 'react';

export type Images = {
  url: string;
  id: string;
  sourceId?: string;
  layout?: mapboxgl.AnyLayout;
  sdf?: boolean;
}[];

type Options = {
  addLayers?: boolean;
};

// Might need this later (who knows ...)
// map.on('style.load', () =>
//   images.forEach(img => {
//     if (!map.hasImage(img.id)) {
//       map.loadImage(img.url, (err: any, image: any) => {
//         if (err) console.log(img.url, err);
//         else {
//           if (!map.hasImage(img.id)) {
//             map.addImage(img.id, image, { sdf: img.sdf });
//           }

//           if (options.addLayers && !map.getLayer(img.id)) {
//             map.addLayer({
//               id: img.id,
//               type: 'symbol',
//               source: img.sourceId || img.id,
//               layout: {
//                 'symbol-placement': 'point',
//                 'icon-allow-overlap': true,
//                 'icon-size': 0.4,
//                 visibility: 'visible',
//                 ...(img.layout || {}),
//                 'icon-image': img.id,
//               },
//             });
//           }
//         }
//       });
//     }
//   }),
// );

export function useMapImages(images: Images, map: mapboxgl.Map, options: Options = { addLayers: true }) {
  useEffect(() => {
    map.on('styleimagemissing', e => {
      const img = images.find(image => image.id === e.id);
      img &&
        map.loadImage(img.url, (err: any, image: any) => {
          if (err) console.log(img.url, err);
          else {
            if (!map.hasImage(img.id)) {
              map.addImage(img.id, image, { sdf: img.sdf });

              if (options.addLayers && !map.getLayer(img.id)) {
                map.addLayer({
                  id: img.id,
                  type: 'symbol',
                  source: img.sourceId || img.id,
                  layout: {
                    'symbol-placement': 'point',
                    'icon-allow-overlap': true,
                    'icon-size': 0.4,
                    visibility: 'visible',
                    ...(img.layout || {}),
                    'icon-image': img.id,
                  },
                });
              }
            }
          }
        });
    });

    return () => {
      images.forEach(img => {
        if (map.isStyleLoaded() && options.addLayers && map.getLayer(img.id)) {
          map.removeLayer(img.id);
        }
      });
    };
  }, [images, map, options]);
}
