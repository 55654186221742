import styled from 'styled-components';
import { transparentize } from 'polished';
import { mapper, sizeMapper, colorMapper } from '../../../utils/styles';

const getCircleBackgroundColor = (props: any) => transparentize(0.5, colorMapper(props));

const Circle = styled.span<{ size?: string; margin?: string }>`
  display: inline-block;
  width: ${props => mapper(props.size, { small: '4px', medium: '8px', large: '12px' })};
  height: ${props => mapper(props.size, { small: '4px', medium: '8px', large: '12px' })};
  margin: ${props => sizeMapper(props.margin, 'no')};
  border-radius: 50%;
  background-color: ${getCircleBackgroundColor};
  cursor: pointer;
`;

export default Circle;
