import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { ServicesOffered, Materials } from './servicesFormSections';
import { HaulerProfileGrid } from '../styled';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import servicesFormInitialValuesSelector from '../../services/servicesFormInitialValuesSelector';
import { AppState } from '../../../store';

interface PropsWithoutReduxForm extends RouteComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const ServicesForm: React.FC<Props> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <HaulerProfileGrid multiLine>
      <ServicesOffered />
      <Materials />
    </HaulerProfileGrid>
  </form>
);

const mapStateToProps = (state: AppState) => ({
  initialValues: servicesFormInitialValuesSelector(state.haulerProfile.services.services),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'services',
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(ServicesForm),
  ),
);
