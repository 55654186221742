import FileSaver from 'file-saver';

import { EXCEL_MIME_TYPES } from '../../core/constants';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { httpInsightsReporting } from '../../core/services/http';
import { OFF_ROUTE, STATIONARY_OFF_ROUTE, TRIP_TIME } from '../constants';
import createTranslationKey from '../../utils/services/createTranslationKey';
import transformLoadReportingDetails from './transformLoadReportingDetails';
import translate from '../../core/services/translate';

const getReportingDetailsType = (reportType: string) => {
  if (reportType === TRIP_TIME) return 'tripTimeReportingDetails';
  if (reportType === OFF_ROUTE || reportType === STATIONARY_OFF_ROUTE) return 'offRouteReportingDetails';
  return 'reportingDetails';
};

export const loadReportingDetails = (
  vendorId: number,
  startDate: string,
  endDate: string,
  vehicleTypeId: number,
  vehicleId: number,
  searchString: string,
  wasteAuditType: string,
  reportType: string,
  sortedBy: string,
  sortOrder: string,
  obstacleType: string,
  materialContaminationType: string,
  routeId: number,
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get(`/reporting/${getReportingDetailsType(reportType)}`, {
      params: {
        vendorId,
        startDate,
        endDate,
        vehicleTypeId,
        vehicleId,
        searchString,
        wasteAuditType,
        reportType,
        sortDirection: sortOrder,
        sortOrder: sortedBy,
        obstacleType,
        materialContaminationType,
        routeId,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
      },
    })
    .then(response => transformLoadReportingDetails(response.data));

export const exportReportingDetails = (
  vendorId: number,
  startDate: string,
  endDate: string,
  vehicleTypeId: number,
  vehicleId: number,
  searchString: string,
  wasteAuditType: string,
  reportType: string,
  sortedBy: string,
  sortOrder: string,
  obstacleType: string,
  materialContaminationType: string,
  routeId: number,
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get(`/export/${getReportingDetailsType(reportType)}`, {
      params: {
        vendorId,
        startDate,
        endDate,
        vehicleTypeId,
        vehicleId,
        searchString,
        wasteAuditType,
        reportType,
        sortDirection: sortOrder,
        sortOrder: sortedBy,
        obstacleType,
        materialContaminationType,
        routeId,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
      },
      responseType: 'arraybuffer',
    })
    .then(response => {
      const blob = new Blob([response.data], {
        type: EXCEL_MIME_TYPES,
      });
      FileSaver.saveAs(
        blob,
        `${translate(createTranslationKey(reportType, 'insights.reportTypes'))}-${translate('insights.report')}.xlsx`,
      );
    });
