import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { size } from 'lodash-es';

import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { APPLICATION_STATUS_ICONS_LIST } from 'src/common/constants/applicationStatuses';
import { useSelector } from 'src/core/hooks/useSelector';
import { DASHBOARD_APPLICATION_STATUS_LAYER, DASHBOARD_CLUSTERS_SOURCE } from 'src/dashboard/constants/dashboardMapGL';
import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import DashboardApplicationStatusGLLayers from './DashboardApplicationStatusGLLayers';
import DashboardApplicationStatusGLPopupResolver from './DashboardApplicationStatusGLPopupResolver';
import { ApplicationStatusFeatureProperties } from './utils';

const mapImages = APPLICATION_STATUS_ICONS_LIST.map(status => ({
  id: status.iconType,
  url: status.icon,
  sdf: true,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  isOnDemandVideoDownloadEnabled: boolean;
  map: mapboxgl.Map;
}

const DashboardApplicationStatusGL: React.FC<Props> = ({ isOnDemandVideoDownloadEnabled, map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  const showVehicleAppStatus = useSelector(state => state.dashboard.vehiclesData.showAppStatus);
  const showRouteVehiclesAppStatus = useSelector(state => state.dashboard.routesData.showAppStatus);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const feature = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_CLUSTERS_SOURCE &&
              feature.properties?.layer === DASHBOARD_APPLICATION_STATUS_LAYER,
          )[0] as any as GeoJSON.Feature<GeoJSON.Point, ApplicationStatusFeatureProperties> | undefined;

        if (!!feature) {
          /**
           * Yes, setting the id from the properties since Mapbox removes the floating numbers
           * from the main id of the feature. We need to set some other ids for these things,
           * but API changes will be necessary.
           */
          dispatch(
            setDashboardSelectedFeature('applicationModeChanges', feature.properties.vehicleId, {
              coordinates: event.lngLat,
              vehicleTrackingApplicationStatusIndex: feature.properties.statusIndex,
            }),
          );
        }
      });
    });
  }, [map, dispatch]);

  if (!showVehicleAppStatus && !size(showRouteVehiclesAppStatus)) {
    return null;
  }

  return (
    <>
      <DashboardApplicationStatusGLLayers />
      <DashboardApplicationStatusGLPopupResolver isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled} />
    </>
  );
};

export default DashboardApplicationStatusGL;
