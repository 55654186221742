import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  exportDailyDisposalSummary as doExportDailyDisposalSummary,
  loadDailyDisposalSummary as doLoadDailyDisposalSummary,
} from '../services/dailyDisposalSummary';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';

// Actions
const START_LOAD = 'insights/dailyDisposalSummary/START_LOAD';
const COMPLETE_LOAD = 'insights/dailyDisposalSummary/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/dailyDisposalSummary/FAIL_LOAD';
const START_EXPORT = 'settings/dailyDisposalSummary/START_EXPORT';
const COMPLETE_EXPORT = 'settings/dailyDisposalSummary/COMPLETE_EXPORT';
const FAIL_EXPORT = 'settings/dailyDisposalSummary/FAIL_EXPORT';
const RESET = 'insights/dailyDisposalSummary/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isExporting: false,
  dailyDisposalSummary: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          dailyDisposalSummary: action.dailyDisposalSummary,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (dailyDisposalSummary: any) => ({
  type: COMPLETE_LOAD,
  dailyDisposalSummary,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const loadDailyDisposalSummary =
  (
    vendorId: number,
    startDate: string,
    endDate: string,
    vehicleTypeId: number,
    searchString: string,
    ticketNumber: number,
    disposalSiteLocationId: number,
    sortedBy: string,
    sortOrder: string,
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadDailyDisposalSummaryPromise = doLoadDailyDisposalSummary(
      vendorId,
      startDate,
      endDate,
      vehicleTypeId,
      searchString,
      ticketNumber,
      disposalSiteLocationId,
      sortedBy,
      sortOrder,
      filtersPreferencesIds,
    );
    loadDailyDisposalSummaryPromise
      .then(dailyDisposalSummary => dispatch(completeLoad(dailyDisposalSummary)))
      .catch(() => dispatch(failLoad()));
    return loadDailyDisposalSummaryPromise;
  };

export const exportDailyDisposalSummary =
  (
    vendorId: number,
    startDate: string,
    endDate: string,
    vehicleTypeId: number,
    searchString: string,
    ticketNumber: number,
    disposalSiteLocationId: number,
    sortedBy: string,
    sortOrder: string,
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportDailyDisposalSummaryPromise = doExportDailyDisposalSummary(
      vendorId,
      startDate,
      endDate,
      vehicleTypeId,
      searchString,
      ticketNumber,
      disposalSiteLocationId,
      sortedBy,
      sortOrder,
      filtersPreferencesIds,
    )
      .then(() => dispatch(completeExport()))
      .catch(() => dispatch(failExport()));
    return exportDailyDisposalSummaryPromise;
  };

export const resetDailyDisposalSummary = () => ({
  type: RESET,
});

// Selectors
const getDailyDisposalSummary = (dailyDisposalSummary: any) => get(dailyDisposalSummary, 'dailyDisposalSummary') || [];
export const dailyDisposalSummarySelector = createSelector(getDailyDisposalSummary, identity);

const getTotalDisposalCount = (dailyDisposalSummary: any) => get(dailyDisposalSummary, 'totalDisposalCount') || 0;
export const totalDisposalCountSelector = createSelector(getTotalDisposalCount, identity);
