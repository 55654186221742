import moment from 'moment';

import { RouteTemplateSimplified } from '../interfaces/RouteTemplates';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { UnconnectedCheckbox } from 'src/core/components';
import { VEHICLE_TYPES } from 'src/fleet/constants';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';

export const generateCalendarDropDownOptions = (referenceDate: Date) => {
  const weekRangesDates = [];
  const date = moment(referenceDate).add(-5, 'weeks');
  for (let i = 0; i <= 10; i++) {
    const startView = date.clone();
    const endView = date.clone().add(6, 'days');
    weekRangesDates.push({
      label: `${startView.format('MM/DD/YYYY')} - ${endView.format('MM/DD/YYYY')}`,
      value: startView.format('MM-DD-YYYY'),
    });
    date.add(1, 'week');
  }
  return weekRangesDates;
};

export const generateEvents = (events: any[], referenceDate: Date) => {
  const eventsForRanges = [];
  const date = moment(referenceDate).startOf('week');
  for (let i = 0; i <= 6; i++) {
    const eventOnThisDay = events.find(event => moment(event.date).isSame(date, 'day'));
    eventsForRanges.push({
      start: date.toDate(),
      end: date.toDate(),
      allDay: true,
      ...eventOnThisDay,
    });
    date.add(1, 'day');
  }
  return eventsForRanges;
};

export const getTimeDifference = (time1: string, time2: string) => {
  const difference = moment(time1, 'HH:mm:ss').diff(moment(time2, 'HH:mm:ss'));
  const duration = moment.duration(difference);
  const hours = duration.hours() < 10 ? `0${duration.hours()}` : duration.hours();
  const minutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
  return `${hours}:${minutes}`;
};

export const getTranslatedCalendarSection = (technicalName: string, entityName?: string) => {
  if (entityName) {
    switch (entityName) {
      case 'VehicleType':
        return VEHICLE_TYPES[technicalName]?.name;
      case 'WasteMaterialType':
        return translate(createTranslationKey(technicalName, 'common.wasteTypes'));
      default:
        return translate(`common.wasteTypes.unknown`);
    }
  } else {
    switch (technicalName) {
      case 'Available':
        return translate('common.available');
      case 'Unavailable':
        return translate('common.unavailable');
      case 'Assigned':
        return translate('common.assigned');
      case 'Unassigned':
        return translate('common.unassigned');
      case 'DoubleBooked':
        return translate('routes.planner.doubleBooked');
      case 'NoDriverAssigned':
        return translate('routes.planner.noDriverAssigned');
      case 'NoVehicleAssigned':
        return translate('routes.planner.noVehicleAssigned');
      case 'Planned':
        return translate('routes.planner.planned');

      default:
        return translate(`common.wasteTypes.unknown`);
    }
  }
};

export const getRoutePlannerTableCells = (checked: boolean, partial: boolean, onChange: (value: any) => void) => {
  const isViewOnlyOrSupport = checkIfViewOnly() || checkIfSupport();
  const cells: any = [
    {
      label: translate('routes.routeType'),
      name: 'vehicleTypeId',
      sortable: true,
      noPaddingRight: true,
      padding: !isViewOnlyOrSupport ? 'xxSmall xxSmall' : 'xxSmall xxSmall xxSmall medium',
      width: !isViewOnlyOrSupport ? routePlannerRoutesTableCellsWidths[1] : '9%',
    },
    {
      label: translate('routes.route'),
      name: 'name',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[2],
    },
    {
      label: translate('vehicles.vehicle'),
      name: 'vehicleName',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[3],
    },
    {
      label: translate('common.driver'),
      name: 'driverFirstName',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[4],
    },
    {
      label: translate('routes.materialType'),
      name: 'wasteMaterialTypeId',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[5],
    },
    {
      label: translate('common.description'),
      name: 'description',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[6],
    },
    {
      name: 'numberOfStops',
      label: translate('routes.numberOfStops'),
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[7],
      noPaddingRight: true,
      sortable: true,
    },
    {
      label: translate('routes.planner.routeStart'),
      name: 'startTime',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[8],
    },
    {
      label: translate('routes.planner.routeEnd'),
      name: 'endTime',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[9],
    },
    {
      label: translate('routes.planner.totalHours'),
      name: 'totalHours',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[10],
    },
    {
      label: translate('common.options'),
      name: 'options',
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: '15%',
      align: 'right',
    },
  ];

  // if is not view only or support add the checkbox first
  if (!isViewOnlyOrSupport) {
    cells.unshift({
      componentProps: {
        checked,
        onChange,
        partial,
      },
      component: UnconnectedCheckbox,
      name: 'selectAll',
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRoutesTableCellsWidths[0],
    });
  }

  return cells;
};

export const routePlannerRoutesTableCellsWidths = [
  '2%',
  '7%',
  '13%',
  '8%',
  '8%',
  '8%',
  '12%',
  '7%',
  '7%',
  '7%',
  '7%',
  '12%',
];

export const getRoutePlannerBuilderTableCells = (
  checked: boolean,
  partial: boolean,
  isDaily: boolean,
  onChange: (value: any) => void,
) => {
  const isViewOnlyOrSupport = checkIfViewOnly() || checkIfSupport();

  const cells: any = [
    {
      label: translate('routes.route'),
      name: isDaily ? 'routeName' : 'routeTemplateName',
      sortable: true,
      noPaddingRight: true,
      padding: !isViewOnlyOrSupport ? 'xxSmall xxSmall' : 'xxSmall xxSmall xxSmall medium',
      width: !isViewOnlyOrSupport ? routePlannerRouteBuilderTableCellsWidths[1] : '17%',
    },
    {
      label: translate('vehicles.vehicle'),
      name: 'vehicleName',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: isDaily ? routePlannerRouteBuilderTableCellsWidths[2] : '12%',
    },
    {
      label: translate('common.driver'),
      name: 'driverName',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[3],
    },
    {
      label: translate('routes.materialType'),
      name: 'materialTypeId',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[4],
    },
    {
      label: translate('common.description'),
      name: 'description',
      sortable: false,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: isDaily ? routePlannerRouteBuilderTableCellsWidths[5] : '12%',
    },
    {
      name: 'numberOfStops',
      label: translate('routes.numberOfStops'),
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[6],
      noPaddingRight: true,
      sortable: true,
    },

    {
      label: translate('routes.planner.routeStart'),
      name: 'startTime',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[8],
    },
    {
      label: translate('routes.planner.routeEnd'),
      name: 'endTime',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[9],
    },
    {
      label: translate('routes.planner.totalHours'),
      name: 'totalHours',
      sortable: true,
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[10],
    },
    {
      label: translate('common.options'),
      name: 'options',
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: isDaily ? routePlannerRouteBuilderTableCellsWidths[11] : '9%',
      align: 'right',
    },
  ];

  // if is not view only or support add the checkbox first
  if (!isViewOnlyOrSupport) {
    cells.unshift({
      componentProps: {
        checked,
        onChange,
        partial,
      },
      component: UnconnectedCheckbox,
      name: 'selectAll',
      noPaddingRight: true,
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[0],
    });
  }

  // if (isDaily)  add on the 7th column the route status type and move the rest up
  if (isDaily) {
    cells.splice(isViewOnlyOrSupport ? 6 : 7, 0, {
      name: 'routeStatusTypeId',
      label: translate('common.status'),
      padding: 'xxSmall xxSmall',
      width: routePlannerRouteBuilderTableCellsWidths[7],
      noPaddingRight: true,
      sortable: true,
    });
  }

  return cells;
};

export const routePlannerRouteBuilderTableCellsWidths = [
  '2%',
  '15%',
  '10%',
  '10%',
  '11%',
  '9%',
  '7%',
  '8%',
  '7%',
  '7%',
  '7%',
  '7%',
];

export const getIsAllSnowPlowOrStreetSweeperTemplates = (templates: RouteTemplateSimplified[]) => {
  const snowPlowTemplates = templates.filter(template => template.vehicleTypeId === SNOW_PLOW_ID);
  const streetSweeperTemplates = templates.filter(template => template.vehicleTypeId === STREET_SWEEPER_ID);
  const isAllSnowPlowOrStreetSweeperTemplates =
    snowPlowTemplates.length + streetSweeperTemplates.length === templates.length;

  return isAllSnowPlowOrStreetSweeperTemplates;
};
