import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import AiModelConfigurationModal from './AiModelConfigurationModal';

const AiModelConfigurationModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadVehicleTypesForVendor(vendorId)(dispatch)]);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      successComponent={AiModelConfigurationModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default AiModelConfigurationModalResolver;
