import styled, { css } from 'styled-components';
import { InputStyle } from './Input';

interface Props {
  fixedWidth?: boolean;
  fullWidth?: boolean;
}

export const TimePickerWrapper = styled.div<Props>`
  .react-time-picker {
    display: inline-flex;
    position: relative;
    width: 100% !important;
  }
  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
    padding: 4px 10px;
    margin-left: -10px;
    cursor: not-allowed;

    * {
      pointer-events: none;
    }

    .react-time-picker__inputGroup {
      padding: 0;
    }
  }
  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 0;
  }
  .react-time-picker__inputGroup {
    box-sizing: content-box;
    ${InputStyle};
  }
  .react-time-picker__inputGroup__divider {
    padding: 2px;
    white-space: pre;
  }
  .react-time-picker__inputGroup__input {
    min-width: 17px;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    &:focus {
      outline: none !important;
    }
  }

  .react-time-picker__inputGroup__input .react-time-picker__inputGroup__minute {
    width: 18px;
  }
  .react-time-picker__inputGroup__input .react-time-picker__inputGroup__second {
    width: 18px;
  }
  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-time-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-time-picker__button:enabled {
    cursor: pointer;
  }
  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7;
  }
  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-time-picker__button svg {
    display: inherit;
  }
  .react-time-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-time-picker__clock--closed {
    display: none;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: red;
  }

  .react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
    ${props =>
      props.fixedWidth &&
      css`
        width: 20px !important;
      `};
  }

  .react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
    width: 18px;

    ${props =>
      props.fixedWidth &&
      css`
        width: 32px !important;
      `};
  }
`;

export const TimePickerFieldWrapper = styled.div<Props>`
  padding-top: 4px;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  .ant-picker {
    width: 100%;
    border: 0;
    padding: 5px 0;
    box-shadow: none !important;
    font-weight: 300;
    border-bottom: 1px solid #f2f2f5;

    &:hover,
    &:focus {
      border-bottom: none;
    }

    &.ant-picker-disabled {
      background: none;
    }

    .ant-picker-input > input {
      font-weight: 300;
    }

    .ant-picker-suffix {
      display: none;
    }

    .ant-picker-clear {
      color: #00a599 !important;
      background: #fff !important;
      font-size: 14px;
      opacity: 1;

      > span {
        :before {
          content: '';
          width: 10px;
          height: 10px;
          display: inline-block;
          color: #fff;
          background-color: rgb(0, 165, 153);
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01LjkxOC45TDUuMS4wODJhLjI2NC4yNjQgMCAwIDAtLjM4MiAwTDMgMS44IDEuMjgyLjA4MmEuMjY0LjI2NCAwIDAgMC0uMzgyIDBMLjA4Mi45YS4yNjQuMjY0IDAgMCAwIDAgLjM4MkwxLjggMyAuMDgyIDQuNzE4YS4yNjQuMjY0IDAgMCAwIDAgLjM4MmwuODE4LjgxOGMuMTEuMTEuMjczLjExLjM4MiAwTDMgNC4ybDEuNzE4IDEuNzE4QS4yOTUuMjk1IDAgMCAwIDQuOTEgNmEuMjk1LjI5NSAwIDAgMCAuMTkxLS4wODJsLjgxOC0uODE4YS4yNjQuMjY0IDAgMCAwIDAtLjM4Mkw0LjIgM2wxLjcxOC0xLjcxOGEuMjY0LjI2NCAwIDAgMCAwLS4zODJ6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
          background-position: center center;
          background-repeat: no-repeat;
          border-radius: 100%;
        }

        svg {
          display: none;
        }
      }
    }
  }

  .ant-picker,
  .ant-picker-dropdown {
    font-feature-settings: normal;
  }
`;
