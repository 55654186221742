import { InjectedFormProps, reduxForm } from 'redux-form';

import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import DateRangePicker from 'src/core/components/DateRangePicker';
import {
  Grid,
  GridColumn,
  LabeledSwitch,
  LabeledSwitchBar,
  LabeledSwitchHandle,
  LabeledSwitchText,
  PanelSection,
} from 'src/core/components/styled';
import TypedField from 'src/core/components/TypedField';
import { DATE_RANGE_PICKER_30_60_90 } from 'src/core/constants/weekdays';
import translate from 'src/core/services/translate';

export interface WorkOrdersFilterFormValues {
  workOrdersListDateRange: { from: StringOrDate; to: StringOrDate };
}

export interface ComponentProps {
  effectiveStartDate: StringOrDate;
  setWorkOrdersListDateRange: (v: { from: StringOrDate; to: StringOrDate }) => void;
  showOperationalWorkOrders: boolean;
  toggleOperationalWorkOrders: () => void;
  workOrdersListDateRange: { from: StringOrDate; to: StringOrDate };
  billingModuleEnabled: boolean;
}

type Props = ComponentProps & InjectedFormProps<WorkOrdersFilterFormValues, ComponentProps>;

const WorkOrderListFilters = ({
  effectiveStartDate,
  handleSubmit,
  setWorkOrdersListDateRange,
  showOperationalWorkOrders,
  toggleOperationalWorkOrders,
  workOrdersListDateRange,
  billingModuleEnabled,
}: Props) => (
  <form onSubmit={handleSubmit} noValidate>
    <PanelSection padding="small no lMedium no">
      <Grid flexDirection="row-reverse">
        <GridColumn size="8/12" marginRight="small">
          <TypedField
            name="workOrdersListDateRange"
            component={DateRangePicker}
            onChange={(_, newValue) => setWorkOrdersListDateRange(newValue)}
            props={{
              placeholder: translate('common.date'),
              hasMarginLeft: 'smaller',
              options: DATE_RANGE_PICKER_30_60_90,
              margin: 'no',
              disabledDays: [
                {
                  before: new Date(effectiveStartDate),
                },
              ],
              maxInterval: { amount: 3, unit: 'months' },
            }}
          />
        </GridColumn>
        <GridColumn size="4/12" marginRight="auto" padding="no">
          {billingModuleEnabled && (
            <LabeledSwitch isActive={showOperationalWorkOrders}>
              <LabeledSwitchBar onClick={toggleOperationalWorkOrders}>
                <LabeledSwitchText isHighlighted={!showOperationalWorkOrders}>
                  {translate('customers.serviceEditor.billingWorkOrders')}
                </LabeledSwitchText>
                <LabeledSwitchText isHighlighted={showOperationalWorkOrders}>
                  {translate('customers.serviceEditor.operationalWorkOrders')}
                </LabeledSwitchText>
                <LabeledSwitchHandle />
              </LabeledSwitchBar>
            </LabeledSwitch>
          )}
        </GridColumn>
      </Grid>
    </PanelSection>
  </form>
);

export default reduxForm<WorkOrdersFilterFormValues, ComponentProps>({
  enableReinitialize: true,
  form: 'workOrdersFilterForm',
})(WorkOrderListFilters);
