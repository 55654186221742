import update from 'immutability-helper';
import { size } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  loadServiceChangeRequests as doLoadServiceChangeRequests,
  loadServiceChangeRequestsByUserId as doLoadServiceChangeRequestsByUserId,
  updateServiceChangeRequest as doUpdateServiceChangeRequest,
  updateServiceChangeRequestByUserId as doUpdateServiceChangeRequestByUserId,
} from '../services/serviceChangeRequests';

//  Actions
const START_LOAD = 'serviceChangeRequest/START_LOAD';
export const COMPLETE_LOAD = 'serviceChangeRequest/COMPLATE_LOAD';
const FAIL_LOAD = 'serviceChangeRequest/FAIL_LOAD';
const FAIL_SAVE = 'serviceChangeRequest/SAVE_FAIL';
const START_SAVE = 'serviceChangeRequest/START_SAVE';
const COMPLETE_SAVE = 'serviceChangeRequest/COMPLETE_SAVE';

interface serviceChangeRequestsState {
  divisions: any[];
  equipments: any[];
  isLoading: boolean;
  isSaving: boolean;
  materials: any[];
  paymentInfo: any;
  totalActionableRequest: number;
  serviceChangeRequests: any[];
}

//  Initial State
const intialState: serviceChangeRequestsState = {
  divisions: [],
  equipments: [],
  isLoading: false,
  isSaving: false,
  materials: [],
  paymentInfo: undefined,
  totalActionableRequest: 0,
  serviceChangeRequests: [],
};

//  Reducer
export const reducer = (state = intialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          divisions: action.changeRequests.divisions,
          equipments: action.changeRequests.equipments,
          materials: action.changeRequests.materials,
          paymentInfo: action.changeRequests.paymentInfo,
          serviceChangeRequests: action.changeRequests.changeRequests,
          totalActionableRequest: action.isActionRequired ? size(action.changeRequests.changeRequests) : 0,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          divisions: undefined,
          equipments: undefined,
          materials: undefined,
          paymentInfo: undefined,
          serviceChangeRequests: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (changeRequests: any, isActionRequired: boolean) => ({
  type: COMPLETE_LOAD,
  changeRequests,
  isActionRequired,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadServiceChangeRequests =
  (userId: string, token: string, vendorId: number, startDate: string, endDate: string, isActionRequired: boolean) =>
  (dispatch: ThunkDispatch<serviceChangeRequestsState, any, AnyAction>) => {
    dispatch(startLoad());
    const fromDate = isActionRequired ? undefined : startDate;
    const toDate = isActionRequired ? undefined : endDate;
    if (token) {
      const loadChangeRequestsPromise = doLoadServiceChangeRequests(token, fromDate, toDate, isActionRequired);
      loadChangeRequestsPromise
        .then(changeRequests => dispatch(completeLoad(changeRequests, isActionRequired)))
        .catch(() => dispatch(failLoad()));

      return loadChangeRequestsPromise;
    } else {
      const loadChangeRequestsPromise = doLoadServiceChangeRequestsByUserId(
        userId,
        vendorId,
        fromDate,
        toDate,
        isActionRequired,
      );
      loadChangeRequestsPromise
        .then(changeRequests => dispatch(completeLoad(changeRequests, isActionRequired)))
        .catch(() => dispatch(failLoad()));

      return loadChangeRequestsPromise;
    }
  };

export const updateServiceChangeRequest =
  (requestOpportunityObj: any, userId: string, token: string, vendorId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    if (token) {
      return doUpdateServiceChangeRequest(requestOpportunityObj, token)
        .then(() => dispatch(completeSave()))
        .catch(() => dispatch(failSave()));
    } else if (userId && vendorId) {
      return doUpdateServiceChangeRequestByUserId(requestOpportunityObj, userId, vendorId)
        .then(() => dispatch(completeSave()))
        .catch(() => dispatch(failSave()));
    }
  };
