import { reduce, identity, set } from 'lodash-es';
import { createSelector } from 'reselect';

const getBidFormInitialValues = (opportunitiesState: any) =>
  reduce(
    opportunitiesState,
    (
      initialValues,
      {
        caseId,
        offer,
        hasCasters,
        hasLockbar,
        endDestinations,
        requestedChanges,
        bidRateDetails,
        respondedNo,
        response,
        responseStatus,
        status,
        workflowStatus,
        notifyPossibleCustomerApproval,
        equipmentSubType,
        equipmentName,
        frequencyId,
      },
    ) =>
      set(initialValues, `settings${caseId}`, {
        offer,
        hasCasters,
        hasLockbar,
        response,
        endDestinations,
        requestedChanges,
        bidRateDetails,
        respondedNo,
        responseStatus,
        status,
        workflowStatus,
        notifyPossibleCustomerApproval,
        equipmentSubType,
        equipmentName,
        frequencyId,
      }),
    {},
  );

const enterBidFormInitialValueSelector = createSelector(getBidFormInitialValues, identity);

export default enterBidFormInitialValueSelector;
