import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { fadeAndSlideInDownFn, fadeAndSlideInLeftFn, fadeAndSlideInRightFn, fadeAndSlideInUpFn } from '../../styles';
import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';
import { BOTTOM, LEFT, RIGHT, TOP, TOP_LEFT } from '../../constants';

const animation = (props: any) => {
  switch (props.position) {
    case TOP:
      return fadeAndSlideInUpFn('-50%');
    case RIGHT:
      return fadeAndSlideInRightFn('-50%');
    case BOTTOM:
      return fadeAndSlideInDownFn('-50%');
    case LEFT:
      return fadeAndSlideInLeftFn('-50%');
    default:
      return fadeAndSlideInUpFn('-50%');
  }
};

export type PopoverPosition = typeof TOP | typeof RIGHT | typeof LEFT | typeof BOTTOM | typeof TOP_LEFT;

export interface PopoverProps {
  align?: string;
  animated?: boolean;
  hasCloseButton?: boolean;
  isOpened?: boolean;
  maxHeight?: number;
  padding?: string;
  position?: PopoverPosition;
  singleLine?: string;
  size?: string;
  tabletLeftAlign?: boolean;
  tabletRightAlign?: boolean;
  textTransform?: string;
  width?: string;
  zIndex?: number;
}

export const Popover = styled.div<PopoverProps>`
  position: absolute;
  z-index: ${props => (props.zIndex ? props.zIndex : 6002)};
  width: ${props =>
    mapper(
      props.size,
      { xSmall: '160px', small: '200px', medium: '280px', large: '360px', xLarge: '520px' },
      'medium',
    )};
  padding: ${props => sizeMapper(props.padding, 'small')};
  background-color: #fff;
  border: 1px solid ${props => props.theme.grayLight};
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};
  line-height: 18px;
  font-size: 12px;
  text-align: ${props => props.align || 'center'};
  text-transform: ${props => props.textTransform || 'none'};
  color: ${props => (props.color ? colorMapper(props) : 'inherit')};
  visibility: hidden;

  ${props =>
    props.isOpened &&
    css`
      visibility: visible;
    `};

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    left: auto;
    right: -8px;
    margin-left: 0;

    ${props =>
      props.tabletRightAlign === true &&
      css`
        right: 15px;
      `};

    ${props =>
      props.tabletLeftAlign === true &&
      css`
        right: auto;
        left: -8px;
      `};
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;

    @media (max-width: ${props => props.theme.desktopBreakpoint}) {
      left: auto;
      right: 5px;

      ${props =>
        props.tabletLeftAlign === true &&
        css`
          right: auto;
          left: 5px;
        `};
    }
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;

    @media (max-width: ${props => props.theme.desktopBreakpoint}) {
      left: auto;
      right: 5px;

      ${props =>
        props.tabletLeftAlign === true &&
        css`
          right: auto;
          left: 5px;
        `};
    }
  }

  ${props =>
    props.position === TOP &&
    css`
      bottom: calc(100% + 12px);
      left: 50%;
      transform: translateX(-50%);

      &:before {
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        border-top: 10px solid ${props => props.theme.grayLight};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }

      &:after {
        left: 50%;
        transform: translateX(-50%);
        bottom: -8px;
        border-top: 8px solid #fff;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    `};

  ${props =>
    props.position === `${TOP} ${LEFT}` &&
    css`
      bottom: calc(100% + 12px);
      right: 0%;

      &:before {
        right: 10%;
        bottom: -10px;
        border-top: 10px solid ${props => props.theme.grayLight};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }

      &:after {
        right: 10%;
        bottom: -8px;
        border-top: 8px solid #fff;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    `};

  ${props =>
    props.position === RIGHT &&
    css`
      left: calc(100% + 12px);
      top: 50%;
      transform: translateY(-50%);

      &:before {
        top: 50%;
        transform: translate(0, -50%);
        left: -10px;
        border-right: 10px solid ${props => props.theme.grayLight};
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      &:after {
        top: 50%;
        transform: translate(0, -50%);
        left: -8px;
        border-right: 8px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    `};

  ${props =>
    props.position === BOTTOM &&
    css`
      top: calc(100% + 12px);
      left: 50%;
      transform: translateX(-50%);

      &:before {
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 10px solid ${props => props.theme.grayLight};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }

      &:after {
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 8px solid #fff;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    `};

  ${props =>
    props.position === LEFT &&
    css`
      right: calc(100% + 12px);
      top: 50%;
      transform: translateY(-50%);

      &:before {
        top: 50%;
        transform: translate(0, -50%);
        right: -10px;
        border-left: 10px solid ${props => props.theme.grayLight};
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      &:after {
        top: 50%;
        transform: translate(0, -50%);
        right: -8px;
        border-left: 8px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    `};

  ${props =>
    props.position === LEFT &&
    props.singleLine &&
    css`
      white-space: nowrap;
      width: auto;
    `};

  ${props =>
    props.animated &&
    css`
      animation: ${animation} 0.3s ease-out;
    `};
`;

export const InnerPopover = styled.div<PopoverProps>`
  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
      overflow: auto;
      padding: 5px;
    `};
`;
