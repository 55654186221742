import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from '../interfaces/TechnicalType';
import { loadUnitOfMeasureTypes as doLoadUnitOfMeasureTypes } from '../services/unitOfMeasureTypes';

// Actions
const START_LOAD = 'common/unitOfMeasureTypes/START_LOAD';
const COMPLETE_LOAD = 'common/unitOfMeasureTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/unitOfMeasureTypes/FAIL_LOAD';

const RESET = 'common/unitOfMeasureTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  unitOfMeasureTypes: [] as TechnicalType[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unitOfMeasureTypes: action.unitOfMeasureTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unitOfMeasureTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (unitOfMeasureTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  unitOfMeasureTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

// Thunks

export const loadUnitOfMeasureTypes = () => async (dispatch: Dispatch) => {
  dispatch(startLoad());

  const unitOfMeasureTypesPromise = doLoadUnitOfMeasureTypes();

  unitOfMeasureTypesPromise
    .then(unitOfMeasureTypes => dispatch(completeLoad(unitOfMeasureTypes)))
    .catch(() => dispatch(failLoad()));

  return unitOfMeasureTypesPromise;
};
