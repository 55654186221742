import React from 'react';
import { useDispatch } from 'react-redux';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadCustomersImportUploadedFilesStatus } from 'src/customers/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import CustomerImportModal from './CustomerImportModal';

interface Props {
  closeModal: () => void;
}

export const CustomerImportModalResolver: React.FC<Props> = ({ closeModal }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }
    const dependencies = [loadCustomersImportUploadedFilesStatus(vendorId)(dispatch)];

    return Promise.all(dependencies);
  }, [vendorId, dispatch]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={CustomerImportModal}
      successProps={{ closeModal }}
    />
  );
};
