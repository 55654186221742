import { DragEvent, Fragment, useEffect, useRef } from 'react';

import { createSelector } from 'reselect';
import { identity, sum, take } from 'lodash-es';
import { VariableSizeList as List } from 'react-window';

import { COMPLETED, ISSUE_REPORTED, PICKUP_STATUSES } from 'src/common/constants';
import { DispatchBoardRouteJob } from '../../../../interfaces/DispatchBoardRouteJob';
import { DispatchBoardScrollMarker } from '../../../styled';
import { JobTypes } from '../../../../constants';
import { REACT_WINDOW_CLASS_NAME } from '../../../../../core/constants/reactWindow';
import DispatchBoardJob from './DispatchBoardJob';
import { TABLE_ROW_HEIGHT_EXPANDED_SMALL, TABLE_ROW_HEIGHT_EXPANDED_LARGE } from 'src/core/constants/table';
import { TABLE_ROW_HEIGHT_LARGER, TABLE_ROW_HEIGHT_EXPANDED, TABLE_ROW_MAX_ITEMS } from '../../../../../core/constants';
import { ON_HOLD_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import { createDispatchBoardMultipleJobsDragGhost } from './DispatchBoardJobGhost';

const JobList = ({ containerHeight, getElementSize, jobs, listRef, filteredJobs, itemData }: any) => (
  <List
    className={REACT_WINDOW_CLASS_NAME}
    height={containerHeight || 1}
    itemCount={filteredJobs.length}
    itemData={itemData}
    itemSize={getElementSize}
    ref={listRef}
    style={jobs.length > TABLE_ROW_MAX_ITEMS - 1 ? { paddingBottom: '60px' } : undefined}
    width="100%"
  >
    {DispatchBoardJobComponent}
  </List>
);

const DispatchBoardJobComponent = ({ style, index, data }: any) => {
  const {
    deleteJob,
    handleDragStart,
    handleJobSelection,
    jobs,
    onJobDrop,
    openedJobId,
    openJobStatusModal,
    openOrderNumberPopover,
    optimizedJobsLength,
    route,
    routeIsFiltered,
    selectedJobs,
    toggleJob,
    toggleModal,
    type,
  } = data;
  const job = jobs[index];

  return (
    <div style={style}>
      <DispatchBoardJob
        preventDrop
        deleteJob={deleteJob}
        draggable={job.isDraggable}
        isExpanded={job.id === openedJobId}
        isSelected={selectedJobs.indexOf(index) > -1}
        job={job}
        jobIndex={type !== JobTypes.unassigned ? job.orderNo - 1 : index}
        key={job.id}
        onClick={handleJobSelection(index, job.isDraggable)}
        onDragStart={handleDragStart(job.id, job.serviceContractId, type)}
        onJobDrop={onJobDrop}
        openJobStatusModal={openJobStatusModal}
        openOrderNumberPopover={openOrderNumberPopover}
        optimizedJobsLength={optimizedJobsLength}
        route={route}
        routeIsFiltered={routeIsFiltered}
        toggleJob={toggleJob}
        toggleModal={toggleModal}
        type={type}
        hideActions
        hideOrderNumber
      />
    </div>
  );
};

const containerHeightSelector = createSelector(
  elementHeights => sum(take(elementHeights as any, TABLE_ROW_MAX_ITEMS)),
  identity,
);

interface Props {
  jobs: any[];
  jobsTransferring: boolean;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
  selectedJobs: number[];
  openedJobId?: number;
  routeIsFiltered: boolean;
  searchTerm?: string;
  targetRouteId?: number;
  toggleJob: (e: MouseEvent, jobId: number) => void;
  toggleJobTransferring: (value: boolean) => void;
  type: string;
  handleJobSelection: (index: number, canSelect: boolean) => (e: any) => void;
}

const DispatchBoardOnHoldJobs = (props: Props) => {
  const {
    jobs,
    selectedJobs,
    handleJobSelection,
    openedJobId,
    routeIsFiltered,
    toggleJob,
    toggleJobTransferring,
    type,
  } = props;

  const listRef = useRef();

  useEffect(() => {
    if (listRef.current) {
      (listRef.current as any).resetAfterIndex(0, true);
    }
  }, [openedJobId, jobs.length]);

  const getElementSize = (index: number) =>
    jobs[index].id === openedJobId ? TABLE_ROW_HEIGHT_EXPANDED_SMALL : TABLE_ROW_HEIGHT_LARGER;

  const handleDragStart = (jobId: number, serviceContractId: number, type?: string) => (event: DragEvent) => {
    const dt = event.nativeEvent.dataTransfer;
    const jobsSelected = [];

    if (selectedJobs.length) {
      if (selectedJobs.length > 1 && dt) {
        createDispatchBoardMultipleJobsDragGhost(dt);
      }
      selectedJobs.forEach(jobIndex => {
        jobsSelected.push({ jobId: jobs[jobIndex].id, serviceContractId: jobs[jobIndex].serviceContractId });
      });
    } else {
      jobsSelected.push({ jobId, serviceContractId });
    }

    dt?.setData('jobsSelected', JSON.stringify(jobsSelected));
    dt?.setData('type', type || '');
    dt?.setData('routeId', ON_HOLD_ROUTE_ID.toString());

    toggleJobTransferring(true);
  };

  const containerHeight = containerHeightSelector(
    jobs.map(j =>
      j.id === openedJobId
        ? !!j.containerNumber || j.isTemporaryContainer
          ? TABLE_ROW_HEIGHT_EXPANDED_LARGE
          : TABLE_ROW_HEIGHT_EXPANDED
        : TABLE_ROW_HEIGHT_LARGER,
    ),
  );

  const filteredJobs = jobs.map(job => {
    const isDraggable =
      job.jobStatusId !== PICKUP_STATUSES[COMPLETED].id && job.jobStatusId !== PICKUP_STATUSES[ISSUE_REPORTED].id;
    return { ...job, isDraggable };
  });

  return (
    <Fragment>
      <JobList
        containerHeight={containerHeight && containerHeight + 29}
        filteredJobs={filteredJobs}
        getElementSize={getElementSize}
        itemData={{
          handleDragStart,
          handleJobSelection,
          jobs: filteredJobs,
          openedJobId,
          routeIsFiltered,
          selectedJobs,
          toggleJob,
          type,
        }}
        jobs={jobs}
        listRef={listRef}
        lockAxis="y"
        useDragHandle
      />
      {jobs.length > TABLE_ROW_MAX_ITEMS - 1 && <DispatchBoardScrollMarker />}
    </Fragment>
  );
};

export default DispatchBoardOnHoldJobs;
