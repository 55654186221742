import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { map } from 'lodash-es';

import { Grid, GridColumn, ButtonSet, Button } from '../../../core/components/styled';
import { CUSTOMER_TYPES, CUSTOMER_ACCOUNT_TYPES } from '../../../common/constants';
import { Input, Dropdown, LocationPicker, DatePicker, DaysOfWeekPicker } from '../../../core/components';
import {
  EquipmentSizeDropdown,
  EquipmentTypeDropdown,
  PickupFrequencyTypeDropdown,
  ServiceTypeDropdown,
  WasteTypeDropdown,
} from '../../../common/components';
import { VehicleTypeDropdown } from '../../../fleet/components';
import { isRequired, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber, isDateValidValidator } from '../../../utils/services/validator';
import translate from '../../../core/services/translate';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import routeImportDetailsFormInitialValuesSelector from '../../services/routeImportDetailsFormInitialValuesSelector';
import { AppState } from '../../../store';

interface PropsWithoutReduxForm {
  serviceTypeId: number;
  equipmentTypeId: number;
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class RouteImportDetailsForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, serviceTypeId, equipmentTypeId } = this.props;

    const customerTypeOptions = map(CUSTOMER_TYPES, customerType => ({
      value: customerType.id,
      label: customerType.name,
    }));

    const customerAccountTypeOptions = map(CUSTOMER_ACCOUNT_TYPES, customerAccountType => ({
      value: customerAccountType.id,
      label: customerAccountType.name,
    }));

    return (
      <Grid centered>
        <GridColumn size="4/12">
          <form onSubmit={handleSubmit} noValidate>
            <Field
              name="customerTypeId"
              component={Dropdown}
              options={customerTypeOptions}
              label={translate('common.customerType')}
              validate={[isRequired]}
              isClearable
            />

            <Field
              name="serviceTypeId"
              component={ServiceTypeDropdown}
              label={translate('common.serviceType')}
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="equipmentTypeId"
              component={EquipmentTypeDropdown}
              serviceTypeId={serviceTypeId}
              label={translate('common.equipmentType')}
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="equipmentSizeId"
              component={EquipmentSizeDropdown}
              equipmentTypeId={equipmentTypeId}
              label={translate('common.equipmentSize')}
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="wasteMaterialTypeId"
              component={WasteTypeDropdown}
              label={translate('common.wasteType')}
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="customerAccountTypeId"
              component={Dropdown}
              options={customerAccountTypeOptions}
              label={translate('common.customerAccountType')}
              isClearable
              validate={[isRequired]}
            />

            <Field
              name="vehicleTypeId"
              component={VehicleTypeDropdown}
              label={translate('vehicles.vehicleType')}
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="address"
              component={LocationPicker}
              label={translate('common.address')}
              validate={[isRequired, hasCity, hasCountry, hasStreetNumber, hasZip, hasStreet]}
            />

            <Field
              name="numberOfContainers"
              component={Input}
              label={translate('common.numberOfContainers')}
              validate={[isRequired]}
            />

            <Field
              name="locationName"
              component={Input}
              label={translate('common.locationName')}
              validate={[isRequired]}
            />

            <Field
              name="effectiveDate"
              component={DatePicker}
              label={translate('common.effectiveDate')}
              validate={[isRequired, isDateValidValidator]}
            />

            <Field
              name="pickupFrequencyTypeId"
              component={PickupFrequencyTypeDropdown}
              withLabel
              validate={[isRequired]}
              dropdownProps={{ isClearable: true }}
            />

            <Field
              name="dayOfWeek"
              component={DaysOfWeekPicker}
              label={translate('common.dayOfWeek')}
              validate={[isRequired]}
              daysOfWeekProps={{ margin: 'xSmall no xSmall' }}
              dayOfWeekProps={{ margin: 'no small no no' }}
            />

            <Field
              name="recurringRouteName"
              component={Input}
              label={translate('common.recurringRouteName')}
              validate={[isRequired]}
            />

            <Field
              name="orderNoForRecurringRoute"
              component={Input}
              label={translate('common.recurringRouteOrder')}
              validate={[isRequired]}
            />

            <ButtonSet margin="large no no">
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            </ButtonSet>
          </form>
        </GridColumn>
      </Grid>
    );
  }
}

const formSelector = formValueSelector('routeImportDetails');

const mapStateToProps = (state: AppState) => ({
  serviceTypeId: formSelector(state, 'serviceTypeId'),
  equipmentTypeId: formSelector(state, 'equipmentTypeId'),
  initialValues: routeImportDetailsFormInitialValuesSelector(state.routeImport.routeImportDetails),
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'routeImportDetails',
    onSubmitFail: focusFirstInvalidField,
  })(RouteImportDetailsForm),
);
