import { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  binMappingLocationTypeTranslationKeys,
  isPrimaryLocation,
  PickupLocationMarker,
} from './PickupLocationEditorMapbox';
import {
  BinLocationMappingTypeId,
  COLLECTION_POINT,
  CURBSIDE_LOOKUP,
  LOCATION_ADDRESS,
  SET_BY_USER,
} from 'src/common/constants/binMappingTypes';
import { clearServiceSelectedFeature } from '../ducks/services';
import { DeleteCollectionButton, SetAsPrimaryButton } from './styled/ServiceDetails';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupWrapper,
  MapGLPopupTitle,
} from 'src/common/components/map/MapGLPopup';
import { ServicePickupLocation } from '../interfaces/Services';
import { useSelector } from 'src/core/hooks/useSelector';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  handleRemoveCollectionPoints: (collectionPointId: number) => void;
  isEditingCollectionPoints: boolean;
  isReadOnly: boolean;
  pickupLocations: ServicePickupLocation;
  setAsPrimaryLocation: (marker: PickupLocationMarker) => void;
}

const PickupLocationEditorMapboxPopup: FC<Props> = ({
  handleRemoveCollectionPoints,
  isEditingCollectionPoints,
  isReadOnly,
  pickupLocations,
  setAsPrimaryLocation,
}) => {
  const dispatch = useDispatch();

  const { selectedFeature } = useSelector(state => state.customers.services);
  if (!selectedFeature) return null;

  const marker = selectedFeature.marker;

  const getAdditionalMarkerInformation = (
    marker: PickupLocationMarker,
    primaryLocation: BinLocationMappingTypeId,
    isPrimaryLocation: boolean,
  ) => {
    const usages: string[] = [];
    if (
      marker.markerType === SET_BY_USER ||
      (marker.markerType === LOCATION_ADDRESS && primaryLocation === LOCATION_ADDRESS)
    ) {
      usages.push(translate('customers.pickupLocations.serviceVerification'));
      usages.push(translate('customers.pickupLocations.mapDisplay'));
    } else if (
      (marker.markerType === CURBSIDE_LOOKUP && primaryLocation === CURBSIDE_LOOKUP) ||
      (marker.markerType === COLLECTION_POINT && primaryLocation === COLLECTION_POINT && isPrimaryLocation)
    ) {
      usages.push(translate('customers.pickupLocations.serviceVerification'));
    } else if (
      marker.markerType === LOCATION_ADDRESS &&
      (primaryLocation === CURBSIDE_LOOKUP || primaryLocation === COLLECTION_POINT)
    ) {
      usages.push(translate('customers.pickupLocations.mapDisplay'));
    }
    if (usages.length) {
      return (
        <div>
          {translate('customers.pickupLocations.usedFor')}: {usages.join(' & ')}
        </div>
      );
    }
    return null;
  };

  const additionalMarkerInformation = getAdditionalMarkerInformation(
    marker,
    pickupLocations.binLocationMappingTypeId,
    isPrimaryLocation(marker, pickupLocations),
  );

  const onSetAsPrimaryLocation = (marker: PickupLocationMarker) => {
    setAsPrimaryLocation(marker);
    dispatch(clearServiceSelectedFeature());
  };

  const onHandleRemoveCollectionPoints = (collectionPointId: number) => {
    handleRemoveCollectionPoints(collectionPointId);
    dispatch(clearServiceSelectedFeature());
  };

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      onClose={() => dispatch(clearServiceSelectedFeature())}
      latitude={marker.position.lat}
      longitude={marker.position.lng}
      hasNegativeMargin
    >
      <MapGLPopup minHeight={0}>
        <MapGLPopupTitle
          title={translate(`customers.pickupLocations.${binMappingLocationTypeTranslationKeys[marker.markerType]}`)}
        />

        <MapGLPopupContent>
          {additionalMarkerInformation && <LabeledDataView>{additionalMarkerInformation}</LabeledDataView>}

          {!isReadOnly && !isPrimaryLocation(marker, pickupLocations) && (
            <LabeledDataView>
              <SetAsPrimaryButton onClick={() => onSetAsPrimaryLocation(marker)}>
                {translate('customers.pickupLocations.setAsPrimaryLocation')}
              </SetAsPrimaryButton>
            </LabeledDataView>
          )}

          {marker.markerType === COLLECTION_POINT && isEditingCollectionPoints && (
            <LabeledDataView>
              <DeleteCollectionButton onClick={() => onHandleRemoveCollectionPoints(marker?.data?.id)}>
                {translate('pickupLocationEditor.deleteCollectionPoint')}
              </DeleteCollectionButton>
            </LabeledDataView>
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default PickupLocationEditorMapboxPopup;
