import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { ActiveVehicle } from '../interfaces/Vehicle';
import { loadActiveVehicles as doLoadActiveVehicles } from '../services/vehicles';

// Actions
const START_LOAD = 'fleet/activeVehicles/START_LOAD';
const COMPLETE_LOAD = 'fleet/activeVehicles/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/activeVehicles/FAIL_LOAD';
const RESET = 'fleet/activeVehicles/RESET';

// Initial state =
const initialState = {
  isLoading: false,
  activeVehicles: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          activeVehicles: action.activeVehicles,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          activeVehicles: [],
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (activeVehicles: ActiveVehicle[]) => ({
  type: COMPLETE_LOAD,
  activeVehicles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadActiveVehicles = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadActiveVehiclesPromise = doLoadActiveVehicles(vendorId);
  loadActiveVehiclesPromise
    .then(activeVehicles => dispatch(completeLoad(activeVehicles)))
    .catch(() => dispatch(failLoad()));
  return loadActiveVehiclesPromise;
};

export const resetActiveVehicles = () => ({
  type: RESET,
});
