import { http } from '../../core/services/http';
import {
  RouteGeoFenceResponse,
  RouteGeoFenceSettingsResponse,
  RouteGeoFenceSettings,
  RouteGeoFenceMultiPolygon,
} from '../interfaces/RouteGeoFence';
import { transformRawGeoFence, transformGeoFence } from './routeGeoFenceTransformers';

export const loadRouteGeoFence = (routeId: number | string, isTemplate: boolean = false) =>
  http
    .get<RouteGeoFenceResponse>(`${isTemplate ? 'routeTemplates' : 'routes'}/${routeId}/geoFence`)
    .then(response => transformRawGeoFence(response.data.geoFence));

export const saveRouteGeoFence = (routeId: number, geoFence: RouteGeoFenceMultiPolygon, isTemplate: boolean = false) =>
  http
    .put(`${isTemplate ? 'routeTemplates' : 'routes'}/${routeId}/geoFence`, transformGeoFence(geoFence))
    .then(response => response.data?.updateGeoFence);

export const loadRouteGeoFenceSettings = (geoFenceId: number, vendorId: number) =>
  http
    .get<RouteGeoFenceSettingsResponse>('/geoFences/geoFenceSettings', {
      params: { geoFenceId, vendorId },
    })
    .then(response => response.data.getGeoFenceSettings);

export const saveRouteGeoFenceSettings = (
  geoFenceId: number,
  vendorId: number,
  geoFenceSettings: RouteGeoFenceSettings[],
) =>
  http
    .put(`geoFences/${geoFenceId}/geoFenceSettings`, {
      vendorId,
      geoFenceSettings,
    })
    .then(response => response.status === 200);
