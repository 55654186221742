import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TemporaryAddress } from '../interfaces/Customers';
import doSaveTemporaryAddress from '../services/saveTemporaryAddress';

// Actions
const START_SAVE = 'customers/temporaryAddress/START_SAVE';
const COMPLETE_SAVE = 'customers/temporaryAddress/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/temporaryAddress/FAIL_SAVE';
const RESET = 'customers/temporaryAddress/RESET';

interface State {
  isSaving: boolean;
  temporaryAddress: TemporaryAddress;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isSaving: false,
  temporaryAddress: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          temporaryAddress: action.temporaryAddress,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          temporaryAddress: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_SAVE,
});

export const completeLoad = (temporaryAddress: TemporaryAddress) => ({
  type: COMPLETE_SAVE,
  temporaryAddress,
});

const failLoad = () => ({
  type: FAIL_SAVE,
});

export const saveTemporaryAddress = (temporaryAddress: TemporaryAddress) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const saveTemporaryAddress = doSaveTemporaryAddress(temporaryAddress);
  saveTemporaryAddress
    .then(address => {
      dispatch(completeLoad(address));
    })
    .catch(() => dispatch(failLoad()));
  return saveTemporaryAddress;
};

export const resetTemporaryAddress = () => ({
  type: RESET,
});
