import moment from 'moment';

import { WEEKDAYS_BY_SHORTCODE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { RouteTemplate } from 'src/routes/interfaces/RouteTemplates';
import {
  RouteDetail,
  RouteDetailLabel,
  RouteDetailsWrapper,
  RouteDetailValue,
  Wrapper,
} from '../../styled/RouteDetails';

const RouteTemplateSequenceSummaryPanel = () => {
  const route = useSelector(state => state.routes.routeTemplate.routeTemplate || ({} as RouteTemplate));
  const stopsTotal = useSelector(state => state.routes.routeTemplate.locationsTotal);

  if (!route) return null;

  const { vehicleTypeId, startDate, scheduledDay } = route;

  return (
    <Wrapper>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.vehicleType')}</RouteDetailLabel>
          <RouteDetailValue>{VEHICLE_TYPE_IDS[vehicleTypeId].name}</RouteDetailValue>
        </RouteDetail>
        {stopsTotal && (
          <RouteDetail>
            <RouteDetailLabel>{translate(`routes.stops`)}</RouteDetailLabel>
            <RouteDetailValue>{stopsTotal}</RouteDetailValue>
          </RouteDetail>
        )}
        {startDate && (
          <RouteDetail>
            <RouteDetailLabel>{translate('common.startDate')}</RouteDetailLabel>
            <RouteDetailValue>{moment(startDate).format('MM/DD/YYYY')} </RouteDetailValue>
          </RouteDetail>
        )}
        {scheduledDay && (
          <RouteDetail>
            <RouteDetailLabel>{translate('common.dayOfService')}</RouteDetailLabel>
            <RouteDetailValue>{WEEKDAYS_BY_SHORTCODE[scheduledDay].name} </RouteDetailValue>
          </RouteDetail>
        )}
      </RouteDetailsWrapper>
    </Wrapper>
  );
};

export default RouteTemplateSequenceSummaryPanel;
