import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { EXCEPTION_MANAGEMENT_CONFIGURATIONS_FORM } from '../../constants/exceptionManagementConfigurations';
import { ExceptionManagementConfigurationsFormValues } from '../../interfaces/ExceptionManagementConfigurations';
import { isPristine } from 'redux-form';
import { Message, ModalSection } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { VehicleTypeForm } from '../forms';
import * as Ducks from '../../ducks/exceptionManagementConfiguration';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import ExceptionManagementConfigurationsForm from '../forms/ExceptionManagementConfigurationsForm';
import translate from '../../../core/services/translate';

const ExceptionManagementConfigurationModal: React.FC<ModalProps> = ({ featureCode, closeModal }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const vehicles = useSelector((state: AppState) => state.vendors.vehicleTypesForExceptionConfiguration.vehicles);
  const vehicleTypeId = useSelector((state: AppState) => state.vendors.exceptionManagementConfiguration.vehicleTypeId);
  const reasonCodesCount = useSelector((state: AppState) => state.common.reasonCodeTypes.reasonCodeTypes.length);
  const configurations = useSelector(
    (state: AppState) => state.vendors.exceptionManagementConfiguration.configurations,
  );
  const isSaving = useSelector((state: AppState) => state.vendors.exceptionManagementConfiguration.isSaving);
  const isLoading = useSelector((state: AppState) => state.vendors.exceptionManagementConfiguration.isLoading);
  const formPristine = useSelector((state: AppState) => isPristine(EXCEPTION_MANAGEMENT_CONFIGURATIONS_FORM)(state));

  useEffect(() => {
    return function cleanup() {
      dispatch(Ducks.reset());
    };
  }, [dispatch]);

  const loadModelConfigurations = (vendorId: number, vehicleTypeId: number) => {
    return Ducks.loadExceptionManagementConfigurations(vendorId, vehicleTypeId, reasonCodesCount)(dispatch);
  };

  const onChangeVehicleTypeId = (vehicleTypeId: number) => {
    dispatch(Ducks.setVehicleTypeId(vehicleTypeId));
  };

  const onClose = (pristine = formPristine) => {
    closeModal(pristine);
  };

  const onSubmit = ({ configurations }: ExceptionManagementConfigurationsFormValues) => {
    if (!vendorId || !vehicleTypeId) {
      return;
    }

    Ducks.saveExceptionManagementConfigurations(
      vendorId,
      vehicleTypeId,
      configurations,
    )(dispatch).then(() => {
      createSuccessNotification(translate('vendors.alertMessages.exceptionManagementConfigurationSaved'));
      onClose(true);
    });
  };

  return (
    <Modal
      flex
      verticalSize="small"
      size="medium"
      overflow="hidden"
      padding="medium no no"
      flexDirection="column"
      title={translate(createTranslationKey(featureCode || '', 'vendors.featureCodes'))}
      onClose={() => onClose()}
    >
      <ModalSection flexGrow isLoading={isLoading || isSaving}>
        <VehicleTypeForm
          vehicles={vehicles}
          initialValues={{ vehicleTypeId }}
          loadModelConfigurations={loadModelConfigurations}
          onChangeVehicleTypeId={onChangeVehicleTypeId}
        />

        {!!vehicleTypeId && !configurations.length && (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}

        {!!configurations.length && (
          <ExceptionManagementConfigurationsForm initialValues={{ configurations }} onSubmit={onSubmit} />
        )}
      </ModalSection>
    </Modal>
  );
};

export default ExceptionManagementConfigurationModal;
