import React, { PureComponent } from 'react';
import Cookie from 'js-cookie';
import { connect } from 'react-redux';
import { filter, size } from 'lodash-es';

import { AppState } from '../../../store';
import { Button, Panel, PanelSection, PanelSectionGroup, Message } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { deleteContact, loadContacts } from '../../ducks';
import defaultDivisionFormInitialValuesSelector from '../../services/defaultDivisionFormInitialValuesSelector';
import { Districts } from 'src/vendors/interfaces/DivisionAccess';
// import DivisionForm from '../forms/DivisionForm';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { HPORT } from '../../constants/contactTypes';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Table } from '../../../core/components';
import confirm from '../../../core/services/confirm';
import ContactEditorModalResolver from '../modals/ContactEditorModalResolver';
import ContactsPageTableRow from './ContactsPageTableRow';
import translate from '../../../core/services/translate';

interface Props {
  isSaving: boolean;
  isLoading: boolean;
  vendorId?: number;
  gusId?: string;
  contacts: any;
  divisions: Districts[];
  formInitialValues: any;
  deleteContact(...args: any[]): any;
  loadContacts(...args: any[]): any;
}

interface State {
  contactList: any[];
  isContactsEditorModalOpen: boolean;
  isDeleting: boolean;
  selectedContactId: number;
}

class ContactsPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contactList: this.props.contacts,
      isContactsEditorModalOpen: false,
      isDeleting: false,
      selectedContactId: 0,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { contacts } = prevProps;

    if (contacts !== this.props.contacts) {
      this.setState({ contactList: this.props.contacts });
    }
  }

  openContactsEditorModal = (event: any, id: number) => {
    event.stopPropagation();
    this.setState({ isContactsEditorModalOpen: true, selectedContactId: id || 0 });
  };

  closeContactEditorModal = () => {
    this.setState({ isContactsEditorModalOpen: false, selectedContactId: 0 });
  };

  deleteContact = async (event: any, id: number) => {
    event.stopPropagation();

    if (!(await confirm(translate('haulerProfile.alertMessages.confirmDeleteContact')))) {
      return;
    }

    const { deleteContact, gusId } = this.props;
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    this.setState({ isDeleting: true });

    deleteContact(gusId, id, divisionIdFromCookie)
      .then(() => {
        const { contactList } = this.state;
        const filteredContacts = filter(contactList, contact => contact.id !== id);
        this.setState({ contactList: filteredContacts });
        createSuccessNotification(`${translate('haulerProfile.alertMessages.contactDeleted')}`);
      })
      .catch(() => {
        createErrorNotification(`${translate('haulerProfile.alertMessages.errorContactDelete')}`);
      })
      .finally(() => {
        this.setState({ isDeleting: false });
      });
  };

  onDivisionChange = (divisionId: string) => {
    const { gusId, loadContacts } = this.props;
    Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, { expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION });
    window.history.pushState(null, '', `?divisionId=${divisionId}`);
    loadContacts(gusId, HPORT, divisionId);
  };

  render() {
    const { formInitialValues, isLoading } = this.props;
    const { isContactsEditorModalOpen, selectedContactId, contactList, isDeleting } = this.state;

    const contactsTableCells = [
      { name: 'name', label: translate('common.name'), width: '20%' },
      { name: 'phoneNumber', label: translate('common.phone'), width: '15%' },
      { name: 'emailAddress', label: translate('common.email'), width: '25%' },
      { name: 'roles', label: translate('common.roles'), width: '30%' },
      { name: 'options', label: translate('common.options'), width: '10%', align: 'center' },
    ];
    const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues.divisionId;
    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('haulerProfile.contacts')}</PageTitle>
              {/* Hide DivisionForm for current Sprint-119 */}
              {/* <DivisionForm
                initialValues={formInitialValues}
                divisions={divisions}
                onDivisionChange={this.onDivisionChange}
              /> */}
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button color="primary" margin="no" onClick={this.openContactsEditorModal} id="add-contact-button">
              {translate('haulerProfile.addContact')}
            </Button>
          </PageActions>
        </PageHeader>

        <Panel>
          <PanelSectionGroup isLoading={isLoading || isDeleting}>
            {divisionId ? (
              <PanelSection>
                {!!size(contactList) && (
                  <Table
                    cells={contactsTableCells}
                    rows={contactList}
                    rowComponent={ContactsPageTableRow}
                    rowProps={{ deleteContact: this.deleteContact, editContact: this.openContactsEditorModal }}
                    withTopBorder
                  />
                )}

                {!size(contactList) && <Message padding="sMedium">{translate('haulerProfile.noContacts')}</Message>}
              </PanelSection>
            ) : (
              <Message padding="sMedium">{translate('common.alertMessages.invalidDivisionId')}</Message>
            )}

            {!!isContactsEditorModalOpen && (
              <ContactEditorModalResolver
                contactId={selectedContactId}
                onCancel={this.closeContactEditorModal}
                closeContactEditorModal={this.closeContactEditorModal}
                contactList={contactList}
              />
            )}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  formInitialValues: defaultDivisionFormInitialValuesSelector(state.haulerProfile.divisions),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  isLoading: state.haulerProfile.contacts.isLoading,
  contacts: state.haulerProfile.contacts.contacts,
  divisions: state.haulerProfile.divisions.divisions,
});

const mapDispatchToProps = {
  deleteContact,
  loadContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);
