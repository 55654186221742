import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { MarkerSource } from './MarkerSourceComponentProps';
import { DebugVehicleTrackingMarkerSource } from './DebugVehicleTrackingMarkerSource';
import { Dictionary } from '../../../interfaces/Dictionary';
import { VehicleSettings, VehicleTrackingRaw } from '../../../../routes/components/mapWithTimeline/Interfaces';

export enum DebugMapVehicleTrackingType {
  Raw = 'raw',
  Mobile = 'mobile',
}

interface Props extends MarkerSource {
  type: DebugMapVehicleTrackingType;
}

interface DebugTrackingsInfo {
  trackings?: Dictionary<VehicleTrackingRaw[]>;
  trapezoids?: Dictionary<VehicleTrackingRaw[]>;
  showTrackings: boolean;
  filteredVehicleIds: Dictionary<boolean>;
  colors: Dictionary<string>;
  vehicleSettings: Dictionary<VehicleSettings>;
  showCofirmationPolygon: Dictionary<boolean>;
  trapezoidFilters: Dictionary<number[]>;
}

export const DebugMapVehicleTrackingSources: React.FC<Props> = ({ type, map, ...props }) => {
  const allTrackingsInfo: DebugTrackingsInfo = useSelector((state: AppState) => {
    const isMobile = type === DebugMapVehicleTrackingType.Mobile;
    const debugState = state.routes.routeMapDebug;
    return {
      trackings: isMobile ? debugState.mobileVehicleTrackings : debugState.rawVehicleTrackings,
      trapezoids: isMobile ? debugState.mobileVehicleTrackingTrapezoids : debugState.rawVehicleTrackingTrapezoids,
      trapezoidFilters: isMobile
        ? debugState.mobileVehicleTrackingTrapezoidFilters
        : debugState.rawVehicleTrackingTrapezoidFilters,
      showTrackings: isMobile ? debugState.showMobileTrackings : debugState.showRawTrackings,
      filteredVehicleIds: isMobile ? debugState.mobileTrackingsVehicleIds : debugState.rawTrackingsVehicleIds,
      vehicleSettings: debugState.vehicleSettings,
      showCofirmationPolygon: debugState.showVehicleSettings,
      colors: debugState.vehicleTrackingColors,
    };
  });

  const {
    trackings,
    showTrackings,
    filteredVehicleIds,
    trapezoids,
    trapezoidFilters,
    vehicleSettings,
    showCofirmationPolygon,
    colors,
  } = allTrackingsInfo;

  return (
    <>
      {trackings &&
        Object.keys(trackings).map(vehicleId => {
          if (showTrackings && filteredVehicleIds[vehicleId]) {
            return (
              <DebugVehicleTrackingMarkerSource
                vehicleConfiguration={vehicleSettings[vehicleId]}
                showConfirmationPolygon={showCofirmationPolygon[vehicleId]}
                color={colors[vehicleId]}
                uniqueKey={vehicleId + type}
                vehicleId={Number(vehicleId)}
                key={vehicleId}
                markers={trackings[vehicleId]}
                trapezoids={trapezoids && trapezoids[vehicleId]}
                trapezoidFilters={trapezoidFilters[vehicleId]}
                map={map}
                {...props}
              />
            );
          }
          return null;
        })}
    </>
  );
};
