import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

export const TRAVEL_PATH_ROUTE_STOPS_SOURCE = 'travelPathRouteStopsSource';
export const TRAVEL_PATH_ROUTE_STOPS_LAYER = 'travelPathRouteStopsLayer';
export const TRAVEL_PATH_ROUTE_STOPS_NUMBER_LAYER = 'travelPathRouteStopsNumberLayer';

interface Props {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
  showOrderNumbers: boolean;
}

const TravelPathRouteStopsGLSource: FC<Props> = ({ geoJSON, showOrderNumbers }) => {
  return (
    <Source id={TRAVEL_PATH_ROUTE_STOPS_SOURCE} type="geojson" data={geoJSON}>
      <Layer
        id={TRAVEL_PATH_ROUTE_STOPS_LAYER}
        type="symbol"
        source={TRAVEL_PATH_ROUTE_STOPS_SOURCE}
        paint={{}}
        layout={{ 'icon-image': ['get', 'icon'], 'icon-size': 1, 'icon-allow-overlap': true }}
      />
      {showOrderNumbers && (
        <Layer
          id={TRAVEL_PATH_ROUTE_STOPS_NUMBER_LAYER}
          type="symbol"
          minzoom={15}
          source={TRAVEL_PATH_ROUTE_STOPS_SOURCE}
          paint={{ 'text-halo-color': '#fff', 'text-halo-width': 10 }}
          layout={{
            'symbol-z-order': 'source',
            'icon-image': ['get', 'icon'],
            'icon-size': 1,
            'text-field': showOrderNumbers ? ['get', 'orderNo'] : '',
            'text-offset': [0, 1.25],
            'text-size': 15,
          }}
        />
      )}
    </Source>
  );
};

export default memo(TravelPathRouteStopsGLSource);
