import { map, orderBy } from 'lodash-es';
import moment from 'moment';

import translate from '../../core/services/translate';
import { AWARD } from '../constants/requestType';
import {
  AWAITING_HAULER_APPROVAL,
  NO,
  NO_RESPONSE,
  REQUEST_CHANGE,
  REQUEST_CUSTOMER_APPROVAL,
  YES,
} from '../constants/status';
import {
  CHGHAULNG,
  CHGVHAULNG,
  CHGVLOCK,
  CHGLOCK,
  CHGVCASTOR,
  CHGCASTOR,
  CHGCASTERS,
  CHGLOCKFEE,
} from '../constants/opportunitiesStatus';
import { HaulerRateDetail } from '../interfaces/ChangeRequests';
import { MONTH } from '../constants/status';
import { dateTimeFormat } from 'src/utils/services/validator';

const status = (value: string, workflowStatus: string) => {
  if (value === NO) return translate('common.declined');
  else if (
    (value === YES || value === REQUEST_CHANGE) &&
    workflowStatus &&
    workflowStatus === REQUEST_CUSTOMER_APPROVAL
  ) {
    return translate('opportunity.opportunities.requireCustomerApproval');
  } else if (value === REQUEST_CHANGE) return translate('opportunity.opportunities.pendingApproval');
  else if (value === NO_RESPONSE) return translate('opportunity.opportunities.noResponse');
  else if (value === AWAITING_HAULER_APPROVAL) return translate('opportunity.opportunities.awaitingAcceptance');
  else if (!value) return '';
};

export const validDaysThreshold = (o: string | number | Date, deliveryDaysThreshold: number) => {
  let thresholdWeekDays;
  const dayOfWeek = moment(o).day();
  const totalDayOfWeekWithThreshold = dayOfWeek + deliveryDaysThreshold;

  if (dayOfWeek > 0) {
    if (deliveryDaysThreshold <= 0) {
      if (totalDayOfWeekWithThreshold <= 0 && totalDayOfWeekWithThreshold > -5) {
        thresholdWeekDays = deliveryDaysThreshold - 2;
      } else if (totalDayOfWeekWithThreshold < -5) {
        thresholdWeekDays = deliveryDaysThreshold - 4;
      } else {
        thresholdWeekDays = deliveryDaysThreshold;
      }
    } else if (deliveryDaysThreshold > 0) {
      if (totalDayOfWeekWithThreshold > 5 && totalDayOfWeekWithThreshold < 10) {
        thresholdWeekDays = deliveryDaysThreshold + 2;
      } else if (totalDayOfWeekWithThreshold > 10) {
        thresholdWeekDays = deliveryDaysThreshold + 4;
      } else {
        thresholdWeekDays = deliveryDaysThreshold;
      }
    }

    return moment(o).add(thresholdWeekDays, 'days').format('MM/DD/YYYY');
  }
};

const getEndDestinationForId = (e: any[], facilityId: number) => {
  const objEndDestination = e.find(obj => obj.id === facilityId);

  return objEndDestination ? objEndDestination.name : null;
};

export const transformEndDestinations = (o: any) =>
  map(o.endDestinations, e => ({
    ...e,
    label: `${e.name}: ${e.address}`,
    value: e.id,
  }));

const getServiceRate = (haulerRateDetails: any[]) => {
  const objHaulerRateDetails = haulerRateDetails?.find(
    haulerRateDetail => haulerRateDetail.bundled && haulerRateDetail.inherit,
  );

  const serviceBaseRate = !!(objHaulerRateDetails && objHaulerRateDetails.rate);
  return serviceBaseRate;
};

export const transformOffer = (o: any) => ({
  ...o,
  deliveryDate: moment(o.deliveryDate).format('MM/DD/YYYY'),
  serviceUom: o.serviceUom === 'Month' ? 'servicePerMonth' : 'servicePerEach',
  deliveryUom: o.deliveryUom === 'Month' ? 'deliveryPerMonth' : 'deliveryPerEach',
  lockbarUom: o.lockbarUom === 'Month' ? 'lockbarPerMonth' : 'lockbarPerEach',
  castersUom: o.castersUom === 'Month' ? 'castersPerMonth' : 'castersPerEach',
  extraPickupUom: o.extraPickupUom === 'Month' ? 'extraPickupPerMonth' : 'extraPickupPerEach',
  isServiceBaseRate: getServiceRate(o.haulerRateDetails),
});

export const transformRequestedChangesDisplayFees = (df: any, r: any) => [
  ...df,
  df.unshift({
    name: 'End Destination',
    value: getEndDestinationForId(r.endDestinations, r.requestedChanges.facilityId),
  }),
];

export const transformRequestedChanges = (r: any, requestType: number) => ({
  ...r.requestedChanges,
  haulerRateDetails:
    requestType === AWARD
      ? organizeHaulerRateDetails(r.requestedChanges.haulerRateDetails)
      : orderBy(r.requestedChanges.haulerRateDetails, ['isAdditionalCharge', 'rateType'], ['asc']),
});

export const transformServiceNotes = (o: any) =>
  map(o.serviceNotes, s => ({
    ...s,
    enteredOn: moment(s.enteredOn).format(dateTimeFormat),
  }));

const getDefaultPaymentmethod = (opportunity: any) => {
  const defaultPaymentMethod =
    opportunity.paymentInfo.paymentMethods && opportunity.paymentInfo.paymentMethods.length === 1
      ? opportunity.paymentInfo.paymentMethods[0]
      : undefined;

  return defaultPaymentMethod;
};

const getDefaultVendorPayments = (opportunity: any) => {
  const defaultVendorPayments =
    opportunity.paymentInfo.vendorPayments && opportunity.paymentInfo.vendorPayments.length === 1
      ? opportunity.paymentInfo.vendorPayments[0].paymentId
      : undefined;

  return defaultVendorPayments;
};

const organizeHaulerRateDetails = (haulerRateDetails: HaulerRateDetail[]) => {
  const rawHaulerRateDetails = map(haulerRateDetails, haulerRateDetail => ({
    ...haulerRateDetail,
    isMonthlyFee:
      haulerRateDetail.uom === MONTH &&
      (haulerRateDetail.rateCd === CHGHAULNG ||
        haulerRateDetail.rateCd === CHGVHAULNG ||
        haulerRateDetail.rateCd === CHGVLOCK ||
        haulerRateDetail.rateCd === CHGLOCK ||
        haulerRateDetail.rateCd === CHGVCASTOR ||
        haulerRateDetail.rateCd === CHGCASTOR ||
        haulerRateDetail.rateCd === CHGCASTERS ||
        haulerRateDetail.rateCd === CHGLOCKFEE),
  }));
  return orderBy(rawHaulerRateDetails, ['isMonthlyFee'], ['desc']);
};

export const transformOpportunities = (opportunity: any) =>
  map(opportunity.opportunities, o => ({
    ...o,
    caseId: o.caseId ? o.caseId : o.itemId + '-' + o.eventServiceId,
    bidRateDetails: o.offer.haulerRateDetails,
    responseStatus: status(o.response, o.workflowStatus),
    workflowStatus: o.workflowStatus,
    notifyPossibleCustomerApproval: o.notifyPossibleCustomerApproval,
    endDestinations: transformEndDestinations(o),
    requestedChanges: o.requestedChanges
      ? transformRequestedChanges(o, o.requestType.id)
      : {
          deliveryDate: moment(o.offer.deliveryDate).format('MM/DD/YYYY'),
          overallserviceCharge: o.offer.overallserviceCharge || '',
          haulerRateDetails:
            o.requestType.id === AWARD
              ? organizeHaulerRateDetails(o.offer.haulerRateDetails)
              : orderBy(o.offer.haulerRateDetails, ['isAdditionalCharge', 'rateType'], ['asc']),
          paymentMethod: opportunity.paymentInfo ? getDefaultPaymentmethod(opportunity) : undefined,
          paymentId: opportunity.paymentInfo ? getDefaultVendorPayments(opportunity) : undefined,
          equipmentSubType: o.equipment.subType,
          equipmentName: o.equipment.type,
          frequencyId: o.frequencyId,
          marketSubType: o.marketSubType,
          materialType: o.materialId,
          quantity: o.quantity,
          frequencyDays: o.frequencyDays,
        },
    offer: transformOffer(o.offer),
    afterDeliveryDate: validDaysThreshold(o.offer.deliveryDate, o.deliveryDaysThreshold),
    beforeDeliveryDate: validDaysThreshold(o.offer.deliveryDate, o.deliveryDaysThreshold * -1),
    serviceNotes: o.serviceNotes ? transformServiceNotes(o) : undefined,
    endDate: o.endDate ? moment(o.endDate).format('MM/DD/YYYY') : undefined,
    divisionId: o.vendorId,
    divisionName: o.vendorName,
    statusName: o.opportunityStatus.name,
  }));

export const transformAgreement = (a: any) => ({
  ...a,
  deliveryDate: moment(a.deliveryDate).format('MM/DD/YYYY'),
});

export const transformPaymentMethods = (p: any) =>
  map(p.paymentMethods, p => ({
    label: p,
    value: p,
  }));

export const transformVendorPayment = (vp: any) =>
  map(vp.vendorPayments, v => ({
    ...v,
    label: `${v.remitName !== undefined ? v.remitName : ''} ${v.remitName !== undefined ? ': ' : ''} ${
      v.remitAddr1 !== undefined ? v.remitAddr1 : ''
    } ${v.remitCity !== undefined ? v.remitCity : ''} ${v.remitState !== undefined ? v.remitState : ''} ${
      v.remitZip !== undefined ? v.remitZip : ''
    }`,
    value: v.paymentId,
  }));

export const transformDeliveries = (opportunity: any) =>
  map(opportunity.deliveries, d => ({
    ...d,
    agreement: transformAgreement(d.agreement),
    response: '',
    afterDeliveryDate: validDaysThreshold(d.agreement.deliveryDate, d.deliveryDaysThreshold),
    beforeDeliveryDate: validDaysThreshold(d.agreement.deliveryDate, d.deliveryDaysThreshold * -1),
    serviceNotes: d.serviceNotes ? transformServiceNotes(d) : undefined,
  }));

export const transformPaymentInfo = (o: any) => ({
  ...o.paymentInfo,
  paymentMethods: o.paymentInfo ? transformPaymentMethods(o.paymentInfo) : [],
  vendorPayments: o.paymentInfo ? transformVendorPayment(o.paymentInfo) : [],
});

export const transformOpportunity = (opportunity: any) => ({
  ...opportunity,
  opportunities: transformOpportunities(opportunity),
  deliveries: transformDeliveries(opportunity),
  paymentInfo: transformPaymentInfo(opportunity),
});

export const transformSourcingOpportunities = (opportunities: any) => ({
  ...opportunities,
  opportunities: transformOpportunities(opportunities),
  paymentInfo: transformPaymentInfo(opportunities),
  divisions: opportunities.divisions,
  equipmentSubTypes: transformEquipments(opportunities.equipmentSubTypes),
  isAutoRefresh: opportunities.autoRefresh,
});

export const transformEquipments = (equipmentSubTypes: any[]) =>
  map(equipmentSubTypes, equipmentSubType => ({
    id: equipmentSubType,
    name: translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentSubType}`),
  }));
