import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { ALL, CRITICAL } from '../../constants';
import { AppState } from '../../../store';
import { Grid, GridColumn, PanelSection, Button, ButtonSet } from '../../../core/components/styled';
import { Input, Switch, Checkbox, Dropdown } from '../../../core/components';
import { isRequired, isEmail } from '../../../utils/services/validator';
import { TypedField } from '../../../core/components';
import faultCodeEditorFormInitialValuesSelector from '../../services/faultCodeEditorFormInitialValuesSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

const MONTGOMERY_FAULT_CODE_OPTIONS = [
  {
    label: translate('common.all'),
    value: ALL,
  },
  {
    label: translate('common.critical'),
    value: CRITICAL,
  },
];

interface ComponentProps {
  faultCodeEmailId?: number;
  isActive?: boolean;
  sendDailyEmailSummary?: boolean;
  sendReoccurringEmailSummary?: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class FaultCodeEditorForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, sendDailyEmailSummary, sendReoccurringEmailSummary, isActive } = this.props;

    return (
      <PanelSection padding="small xSmall" vertical>
        <Grid centered>
          <GridColumn size="8/12">
            <form onSubmit={handleSubmit}>
              <TypedField
                name="email"
                component={Input}
                validate={[isRequired, isEmail]}
                props={{
                  margin: 'small',
                  placeholder: translate('common.email'),
                }}
              />
              <TypedField
                name="sendDailyEmailSummary"
                component={Checkbox}
                props={{
                  margin: 'small',
                  label: translate('vendors.dailySummary'),
                }}
              />
              {sendDailyEmailSummary && (
                <TypedField
                  name="sendCriticalForDailyEmailSummary"
                  component={Dropdown}
                  props={{
                    margin: 'small',
                    label: translate('vendors.summaryType'),
                    options: MONTGOMERY_FAULT_CODE_OPTIONS,
                    disabled: !isActive,
                  }}
                />
              )}
              <TypedField
                name="sendReoccurringEmailSummary"
                component={Checkbox}
                props={{
                  margin: 'small',
                  label: translate('vendors.reocurringSummary'),
                  disabled: !isActive,
                }}
              />
              {sendReoccurringEmailSummary && (
                <TypedField
                  name="sendCriticalForReoccurringEmailSummary"
                  component={Dropdown}
                  validate={[isRequired]}
                  props={{
                    margin: 'small',
                    label: translate('vendors.summaryType'),
                    disabled: !isActive,
                    options: MONTGOMERY_FAULT_CODE_OPTIONS,
                  }}
                />
              )}
              <TypedField
                name="isActive"
                component={Switch}
                props={{
                  margin: 'small',
                  label: translate('common.active'),
                }}
              />
              <ButtonSet margin="large no no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </form>
          </GridColumn>
        </Grid>
      </PanelSection>
    );
  }
}

const formSelector = formValueSelector('faultCodeEditor');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  sendDailyEmailSummary: formSelector(state, 'sendDailyEmailSummary'),
  sendReoccurringEmailSummary: formSelector(state, 'sendReoccurringEmailSummary'),
  isActive: formSelector(state, 'isActive'),
  initialValues: (faultCodeEditorFormInitialValuesSelector as any)(
    state.vendors.faultCodeEmails.faultCodeEmails,
    ownProps.faultCodeEmailId,
  ),
});

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'faultCodeEditor',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(FaultCodeEditorForm),
);
