import translate from '../../core/services/translate';

export const HOURS_ID = 3;
export const DAYS_ID = 4;
export const WEEKS_ID = 5;
export const MONTHS_ID = 6;

export const M_0_1_ID = 77;
export const M_0_2_ID = 78;
export const M_1_2_ID = 80;
export const M_2_3_ID = 81;
export const M_2_4_ID = 79;
export const M_3_4_ID = 82;
export const M_4_ID = 83;

export const W_0_1_ID = 64;
export const W_0_2_ID = 65;
export const W_0_3_ID = 66;
export const W_1_2_ID = 74;
export const W_1_3_ID = 67;
export const W_2_3_ID = 75;
export const W_2_4_ID = 68;
export const W_3_4_ID = 76;
export const W_4_ID = 86;

export const H_0_1_ID = 76;
export const H_0_2_ID = 1;
export const H_0_4_ID = 2;
export const H_1_3_ID = 75;
export const H_2_4_ID = 4;
export const H_3_6_ID = 76;
export const H_4_6_ID = 31;
export const H_4_8_ID = 5;
export const H_4_10_ID = 32;
export const H_6_8_ID = 33;
export const H_6_24_ID = 77;
export const H_8_ID = 11;
export const H_8_16_ID = 7;
export const H_10_14_ID = 39;
export const H_14_20_ID = 45;
export const H_16_24_ID = 10;
export const H_20_ID = 26;
export const H_24_ID = 14;

export const D_0_1_ID = 15;
export const D_1_3_ID = 18;
export const D_3_5_ID = 20;
export const D_5_7_ID = 22;
export const D_7_ID = 24;

export const TIME_MEASURMENT_TYPES_OPTIONS = [
  { id: HOURS_ID, name: translate('common.sosAlerts.hours') },
  { id: DAYS_ID, name: translate('routes.holidayPlanner.days') },
  { id: WEEKS_ID, name: translate('routes.holidayPlanner.weeks') },
  { id: MONTHS_ID, name: translate('routes.holidayPlanner.months') },
];
