import { PureComponent } from 'react';
import { map } from 'lodash-es';

import { DescriptionList, DescriptionListItem, ModalSection } from '../../../core/components/styled';
import { DriverSafetyEvent } from 'src/insights/services/reportingDetailsOptions';
import { ImageSlider, Modal } from '../../../core/components';
import { dateAndTime, duration } from 'src/utils/services/formatter';
import translate from 'src/core/services/translate';

export const DRIVER_FACING = translate('insights.driverSafety.driverFacing');
export const FRONT_FACING = translate('insights.driverSafety.frontFacing');

interface Props {
  closeModal: () => void;
  driverSafetyEvent: DriverSafetyEvent;
}

interface State {
  driverSafetyImages: any[];
  imageUrls: any[];
  imageToDownloadUrls: any[];
  index: number;
}

class DriverSafetyImageModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const driverSafetyImages = [];
    driverSafetyImages.push({
      imageUrl: props.driverSafetyEvent.driverFacingImageUrl,
      cameraType: DRIVER_FACING,
    });
    driverSafetyImages.push({
      imageUrl: props.driverSafetyEvent.frontFacingImageUrl,
      cameraType: FRONT_FACING,
    });

    this.state = {
      index: 0,
      driverSafetyImages,
      imageUrls: driverSafetyImages.length ? map(driverSafetyImages, 'imageUrl') : [],
      imageToDownloadUrls: driverSafetyImages.length ? map(driverSafetyImages, 'rawImageUrl') : [],
    };
  }

  onIndexChange = (index: number) => {
    this.setState({ index });
  };

  render() {
    const { closeModal, driverSafetyEvent } = this.props;
    const { imageUrls, imageToDownloadUrls, driverSafetyImages, index } = this.state;

    const imageDownloadUrls = imageToDownloadUrls.map((url, index) => (!url ? imageUrls[index] : url));

    return (
      <Modal title={driverSafetyEvent.driverName} onClose={closeModal} padding="medium no no">
        <ModalSection>
          <DescriptionList centered margin="small no no">
            {!!driverSafetyEvent.timeOfEvent && (
              <DescriptionListItem>
                <strong>{translate('insights.driverSafety.timeOfEvent')}</strong>:{' '}
                {dateAndTime(driverSafetyEvent.timeOfEvent)}
              </DescriptionListItem>
            )}
            {!!driverSafetyEvent.typeOfEvent && (
              <DescriptionListItem margin="no no no medium">
                <strong>{translate('insights.driverSafety.typeOfEvent')}</strong>:{' '}
                {translate(`insights.driverSafety.events.${driverSafetyEvent.typeOfEvent}`)}
              </DescriptionListItem>
            )}
            {!!driverSafetyEvent.durationInSeconds && (
              <DescriptionListItem margin="no no no medium">
                <strong>{translate('insights.driverSafety.duration')}</strong>:{' '}
                {duration(driverSafetyEvent.durationInSeconds)}
              </DescriptionListItem>
            )}
            <DescriptionListItem margin="no no no medium">
              <strong>{translate('insights.driverSafety.camera')}</strong>: {driverSafetyImages[index].cameraType}
            </DescriptionListItem>
          </DescriptionList>
        </ModalSection>

        <ImageSlider
          images={imageUrls}
          imagesToDownload={imageDownloadUrls}
          imageTitle={'test'}
          margin="small no no"
          onIndexChange={this.onIndexChange}
        />
      </Modal>
    );
  }
}

export default DriverSafetyImageModal;
