import { createSelector } from 'reselect';
import { filter, identity, map } from 'lodash-es';

interface InsightDashboardPreferences {
  insightDashboardPreferences: any[];
}

const getDashboardInsightTypeIdsByActiveState = (insightDashboardPreferencesState: InsightDashboardPreferences) => {
  const activeInsights = filter(
    insightDashboardPreferencesState.insightDashboardPreferences,
    preference => preference.isActive === true,
  );

  return map(activeInsights, insight => insight.dashboardInsightTypeId).toString();
};

export const dashboardInsightTypeIdsSelector = createSelector(
  getDashboardInsightTypeIdsByActiveState,
  identity,
);
