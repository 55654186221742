import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE } from 'src/dashboard/constants/dashboardMapGL';
import { setDashboardModalMapSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import SnowSweeperStreetSegmentsGLPopupResolver from './SnowSweeperStreetSegmentsGLPopupResolver';
import SnowSweeperStreetSegmentsGLSource from './SnowSweeperStreetSegmentsGLSource';
import { getDashboardSNowSweeperStreetSegmentsGeoJSON } from './utils';
import { SNOW_PLOW_ID } from 'src/fleet/constants';

interface Props {
  map: mapboxgl.Map;
}

const SnowSweeperStreetSegmentsGL: FC<Props> = ({ map }) => {
  const routeVehiclePositions = useSelector(state => state.dashboard.routeHistoryData.routeVehiclePositions);
  const { selectedModalMapFeature } = useSelector(state => state.dashboard.mapControls);
  const segments = useSelector(state => state.dashboard.routeHistoryData.routeSegments);
  const { streetNetworkSegmentDetails } = useSelector(state => state.customers.streetNetwork);

  const vehicleTypeId = useMemo(() => {
    if (!routeVehiclePositions.length) return SNOW_PLOW_ID;
    return routeVehiclePositions[0].vehicleTypeId;
  }, [routeVehiclePositions]);

  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const geoJson = useMemo(
    () =>
      getDashboardSNowSweeperStreetSegmentsGeoJSON(
        segments,
        vehicleTypeId,
        selectedModalMapFeature && streetNetworkSegmentDetails ? selectedModalMapFeature.id : 0,
      ),
    [segments, selectedModalMapFeature, streetNetworkSegmentDetails, vehicleTypeId],
  );

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE);

        if (!feature?.id) return;

        dispatch(setDashboardModalMapSelectedFeature('routeSegments', Number(feature.id)));
      });
    });
  }, [map, dispatch, vendorId]);

  return (
    <>
      <SnowSweeperStreetSegmentsGLSource geoJSON={geoJson} />
      <SnowSweeperStreetSegmentsGLPopupResolver segments={segments} isSnowPlow={vehicleTypeId === SNOW_PLOW_ID} />
    </>
  );
};

export default SnowSweeperStreetSegmentsGL;
