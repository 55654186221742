import { uniq } from 'lodash-es';

import {
  RouteTemplateBuilderService,
  RouteTemplateBuilderServiceDTO,
  RouteTemplateBuilderDayOfServiceDTO,
  RouteTemplateBuilderDayOfService,
} from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { Dictionary } from 'src/common/interfaces/Dictionary';

export const transformRouteTemplateBuilderServices = (
  routeTemplateBuilderServices: RouteTemplateBuilderServiceDTO[],
  routeNames: Dictionary<string>,
): RouteTemplateBuilderService[] =>
  routeTemplateBuilderServices.map(s => ({
    daysOfService: s.d?.map(d => ({
      id: d.i,
      routeTemplateId: d.r,
      draftRouteTemplateId: d.t,
      routeName: d.r ? routeNames[d.r] : d.t ? routeNames[d.t] : '',
    })),
    id: s.i,
    latitude: s.a,
    longitude: s.o,
    materialTypeId: s.m,
    serviceTypeId: s.s,
    equipmentSizeId: s.e,
    equipmentTypeId: s.t,
  }));

export const transformRouteTemplateBuilderDaysOfService = (
  daysOfService: RouteTemplateBuilderDayOfServiceDTO[],
): RouteTemplateBuilderDayOfService[] =>
  daysOfService.map(d => ({
    ...d,
    routeTemplates: d.routeTemplates.map(r => ({
      ...r,
      stopsCount: uniq(r.serviceContracts).length,
      serviceTypeId: d.serviceTypeId,
    })),
  }));
