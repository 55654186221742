import translate from "src/core/services/translate";

export const billThruMonthOptions = [
  {
    label: translate('billing.previousMonth'),
    value: -1,
  },
  {
    label: translate('billing.currentMonth'),
    value: 0,
  },
  {
    label: translate('billing.nextMonth'),
    value: 1,
  },
];
