import { Link as LinkComponent } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';

interface Props {
  align?: string;
  block?: boolean;
  color?: string;
  decoration?: string;
  disabled?: boolean;
  margin?: string;
  size?: string;
  weight?: string;
}

export const Link = styled(LinkComponent)<Props>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  font-weight: ${props => mapper(props.weight, { light: 300, normal: 400, medium: 500 }, 'normal')};
  font-size: ${props =>
    mapper(props.size, { inherit: 'inherit', small: '12px', medium: '14px', large: '16px' }, 'inherit')};
  text-decoration: ${props => props.decoration || 'underline'};

  ${props =>
    props.align &&
    css`
      float: ${props.align};
      padding-right: 30px;
    `};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      text-decoration: ${props.decoration || 'none'};
    `};
`;
