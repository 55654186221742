import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';

import { AppState } from '../../../store';
import { ButtonSet, Button } from '../../../core/components/styled';
import { DatePicker } from '../../../core/components';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import openDispatchesFormInitialValueSelector from '../../services/openDispatchesFormInitialValueSelector';
import translate from '../../../core/services/translate';
import { TODAY } from '../../../core/constants';

type PropsWithoutReduxForm = {
  mobileVersion?: boolean;
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  requestedServiceDate?: Date | string;
  rubiconPONbr: string;
  serviceDate?: Date | string;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

class CompletedServiceConfirmationForm extends PureComponent<Props> {
  render() {
    const { requestedServiceDate, rubiconPONbr, serviceDate, handleSubmit, onCancel, pristine, mobileVersion } =
      this.props;

    const isFutureDate = moment(requestedServiceDate).isAfter(moment().format('MM/DD/YYYY'));
    const disabledDaysObj = isFutureDate
      ? {
          before: moment(requestedServiceDate, 'MM/DD/YYYY').toDate(),
        }
      : {
          after: TODAY,
          before: moment(requestedServiceDate, 'MM/DD/YYYY').toDate(),
        };
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Field
          name={`opportunities.settings${rubiconPONbr}${serviceDate}.completedDate`}
          component={DatePicker}
          label={translate('common.date')}
          disabledDays={[disabledDaysObj]}
          margin="sMedium small sMedium"
          validate={[isRequired, isDateValidValidator]}
        />

        <ButtonSet margin={mobileVersion ? 'xxLarge no no' : 'xxLarge small large'}>
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: openDispatchesFormInitialValueSelector(
    state.fleet.serviceConfirmation.opportunities,
    state.fleet.rubiconDispatches.rubiconDispatches,
  ),
});

export default connect(mapStateToProps)(
  reduxForm<{}, PropsWithoutReduxForm>({
    form: 'completedServiceConfirmation',
    enableReinitialize: true,
  })(CompletedServiceConfirmationForm),
);
