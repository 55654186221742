import React from 'react';
import { useDispatch } from 'react-redux';

import { changeDaysOfServiceStats, MapFilters } from 'src/routes/ducks/routeTemplateBuilder';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { PanelSection, Text } from '../../../../../core/components/styled';
import { RouteTemplateBuilderDaysCounterWrapper } from '../../../styled/RouteTemplateBuilderDaysCounter';
import { UnconnectedSwitch } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { WEEKDAYS } from 'src/core/constants/weekdays';
import translate from 'src/core/services/translate';

interface Props {
  currentFilters?: MapFilters;
}

export const RouteTemplateBuilderStopsPerDay: React.FC<Props> = ({ currentFilters }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const stopsByDaysFilters = useSelector(state => state.routes.routeTemplateBuilder.filters);
  const containersByDaysOfServiceFilters = useSelector(
    state => state.routes.routeTemplateBuilder.containerByDaysOfServiceFilters,
  );
  const showContainers = useSelector(s => s.routes.routeTemplateBuilder.showContainersByDaysOfService);
  const isLoadingStats = useSelector(s => s.routes.routeTemplateBuilder.isLoadingContainersByDaysOfService);
  const workSessionId = useSelector(state => state.routes.routeTemplateBuilder.workSession?.id);

  const { vehicleTypeId, wasteMaterialTypeId, groupIds, serviceZoneId } = currentFilters as MapFilters;
  const switchToContainers = (showContainers: boolean) => {
    if (workSessionId) {
      changeDaysOfServiceStats(
        showContainers,
        vendorId,
        workSessionId,
        vehicleTypeId,
        serviceZoneId,
        wasteMaterialTypeId,
        groupIds,
      )(dispatch);
    }
  };

  const filters = showContainers ? containersByDaysOfServiceFilters : stopsByDaysFilters;

  return !!filters ? (
    <PanelSection vertical isLoading={isLoadingStats}>
      <UnconnectedSwitch
        margin="no no small no"
        label={translate('routeTemplateBuilder.showContainerStats')}
        checked={showContainers}
        onChange={switchToContainers}
      />
      <RouteTemplateBuilderDaysCounterWrapper>
        <table>
          <tbody>
            <tr>
              <th>
                <Text size="small" weight="medium" color="grayDark" margin="no small no no">
                  {showContainers
                    ? translate('routeTemplateBuilder.containersPerDay')
                    : translate('routeTemplateBuilder.stopsPerDay')}
                </Text>
              </th>

              {WEEKDAYS.map(day => (
                <th key={day.id}>
                  <Text size="small" weight="medium" color="black" margin="no">
                    {translate(`routeTemplateBuilder.dayShortCodes.${day.shortCode}`)}
                  </Text>
                </th>
              ))}
            </tr>

            <tr>
              <td>
                <Text size="xSmall" weight="medium" color="primary">
                  {translate(`routeTemplateBuilder.live`)}
                </Text>
              </td>

              {WEEKDAYS.map(day => {
                const dayEntry = filters.daysOfService.find(dos => dos.id === day.id);

                return (
                  <td key={day.id}>
                    <Text size="xSmall" weight="medium" color="primary" margin="no xxSmall no no">
                      {(showContainers ? dayEntry?.numberOfContainersCount : dayEntry?.stopsCount) || 0}
                    </Text>
                  </td>
                );
              })}
            </tr>

            <tr>
              <td>
                <Text size="xSmall" weight="medium" color="warning">
                  {translate(`routeTemplateBuilder.change`)}
                </Text>
              </td>

              {WEEKDAYS.map(day => {
                const dayEntry = filters.daysOfService.find(dos => dos.id === day.id);

                return (
                  <td key={day.id}>
                    <Text size="xSmall" weight="medium" color="warning" margin="no xxSmall no no">
                      {dayEntry?.workSessionStopsCount || 0}
                    </Text>
                  </td>
                );
              })}
            </tr>

            <tr>
              <td>
                <Text size="xSmall" weight="medium" color="warning">
                  {translate(`routeTemplateBuilder.workSession`)}
                </Text>
              </td>

              {WEEKDAYS.map(day => {
                const dayEntry = filters.daysOfService.find(dos => dos.id === day.id);

                return (
                  <td key={day.id}>
                    <Text size="xSmall" weight="medium" color="warning" margin="no xxSmall no no">
                      {((showContainers ? dayEntry?.numberOfContainersCount : dayEntry?.stopsCount) || 0) +
                        (dayEntry?.workSessionStopsCount || 0)}
                    </Text>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </RouteTemplateBuilderDaysCounterWrapper>
    </PanelSection>
  ) : null;
};
