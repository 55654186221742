import { createSelector } from 'reselect';
import { identity } from 'lodash-es';
import { RouteLocationNotes } from '../interfaces/RouteLocation';

const getRouteNotesFormInitialValues = (routeLocationNotesState: RouteLocationNotes) => ({
  routeNotes: routeLocationNotesState.routeLocationNotes.routeNotes,
});

const routeNotesFormInitialValuesSelector = createSelector(getRouteNotesFormInitialValues, identity);

export default routeNotesFormInitialValuesSelector;
