import {
  StreetServicingSideConfiguration,
  VehicleTrackingRaw,
} from '../../../routes/components/mapWithTimeline/Interfaces';
import destination from '@turf/destination';
import { point, Units } from '@turf/helpers';
import { LEFT } from '../../../vendors/constants';
import { MILES, YARDS } from '../../constants';

const calculateExteriorLength = (angle: number, innerLength: number, distance: number) => {
  const radAngle1 = ((90 - angle) * Math.PI) / 180;
  const radAngle2 = (angle * Math.PI) / 180;
  const a = Math.sin(radAngle1);
  const b = Math.sin(radAngle2);
  const c = distance * (a / b);
  const outerLength = 2 * c + innerLength;
  return outerLength;
};

const toMeters = (units: number, measurementType: string) => {
  if (measurementType === YARDS) {
    return units / 1.0936;
  } else if (measurementType === MILES) {
    return units / 0.00062137;
  } else if (measurementType === 'METERS') {
    return units;
  } else if (measurementType === 'KILOMETERS') {
    return units / 1000;
  } else {
    console.warn(
      `Conversion to meters of measurement type '${measurementType}' is not yet supported. Using original measuring units for trapezoid computation. Confirmation polygon size may not be accurate.`,
    );
    return units;
  }
};

export class TrapezoidPoint implements GeoJSON.Feature {
  public constructor(
    vehicleTracking: VehicleTrackingRaw,
    trapezoidConfiguration: StreetServicingSideConfiguration,
    reduced: boolean,
  ) {
    const locationPoint = point([vehicleTracking.longitude, vehicleTracking.latitude]);

    const confirmationDirection = trapezoidConfiguration.id === LEFT ? 180 : 0;
    const roadDepth = toMeters(trapezoidConfiguration.innerLength, trapezoidConfiguration.measurementType);
    const width = toMeters(
      reduced ? trapezoidConfiguration.binLocationReduced : trapezoidConfiguration.binLocation,
      trapezoidConfiguration.measurementType,
    );

    const exteriorDepth = calculateExteriorLength(trapezoidConfiguration.startAt, roadDepth, width);

    const options = { units: 'meters' as Units };

    const upDirection =
      vehicleTracking.bearing + confirmationDirection < 360
        ? vehicleTracking.bearing + confirmationDirection
        : vehicleTracking.bearing + confirmationDirection - 360;
    const firstPoint = destination(locationPoint, roadDepth / 2, upDirection, options);
    const leftDirection = upDirection + 90 < 360 ? upDirection + 90 : upDirection + 90 - 360;
    const secondPoint = destination(firstPoint, width, leftDirection, options);
    const downDirection = leftDirection + 90 < 360 ? leftDirection + 90 : leftDirection + 90 - 360;
    const thirdPoint = destination(secondPoint, roadDepth, downDirection, options);
    const rightDirection = downDirection + 90 < 360 ? downDirection + 90 : downDirection + 90 - 360;
    const fourthPoint = destination(thirdPoint, width, rightDirection, options);

    const fixedSecondPoint = destination(secondPoint, (exteriorDepth - roadDepth) / 2, upDirection, options);
    const fixedThirdPoint = destination(thirdPoint, (exteriorDepth - roadDepth) / 2, downDirection, options);

    this.geometry = {
      type: 'LineString',
      coordinates: [
        firstPoint.geometry.coordinates,
        fixedSecondPoint.geometry.coordinates,
        fixedThirdPoint.geometry.coordinates,
        fourthPoint.geometry.coordinates,
        [...firstPoint.geometry.coordinates],
      ],
    };
  }
  type: 'Feature' = 'Feature';
  geometry: GeoJSON.Geometry;
  properties: GeoJSON.GeoJsonProperties = {};
}
