import { map } from 'lodash-es';

import { DriverSafetyInspectionSettings } from '../interfaces/SupervisorExperienceSettings';

export const transformDriverSafetyInspectionSettings = (
  driverSafetyInspectionSettings: DriverSafetyInspectionSettings[],
) =>
  map(driverSafetyInspectionSettings, ({ ...driverSafetyInspectionSetting }) => ({
    ...driverSafetyInspectionSetting,
  }));
