import { useState } from 'react';

import { Table } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import SnowSweeperServiceHistoryTableRow from './SnowSweeperServiceHistoryTableRow';
import translate from 'src/core/services/translate';

export const serviceHistoryTableCellWidths = ['16%', '16%', '12%', '12%', '10%', '14%', '10%', '10%'];

const serviceHistoryTableCells = [
  {
    name: 'vehicleName',
    label: translate('routes.serviceHistoryData.vehicleName'),
    width: serviceHistoryTableCellWidths[0],
    sortable: true,
  },
  {
    name: 'activities',
    label: translate('routes.serviceHistoryData.serviceActivityType'),
    width: serviceHistoryTableCellWidths[1],
    sortable: true,
  },
  {
    name: 'sessionStartTime',
    label: translate('routes.serviceHistoryData.sessionStartTime'),
    width: serviceHistoryTableCellWidths[2],
    sortable: true,
  },
  {
    name: 'sessionEndTime',
    label: translate('routes.serviceHistoryData.sessionEndTime'),
    width: serviceHistoryTableCellWidths[3],
    sortable: true,
  },
  {
    name: 'totalTime',
    label: translate('routes.serviceHistoryData.totalTime'),
    width: serviceHistoryTableCellWidths[4],
    sortable: true,
  },
  {
    name: 'totalMilesDriven',
    label: translate('routes.totalCompletedMiles'),
    width: serviceHistoryTableCellWidths[5],
    sortable: true,
  },
  {
    name: 'servicedSegments',
    label: translate('routes.serviceHistoryData.servicedSegments'),
    width: serviceHistoryTableCellWidths[6],
    sortable: true,
  },
  {
    name: 'totalMaterialTrips',
    label: translate('routes.serviceHistoryData.totalMaterialTrips'),
    width: serviceHistoryTableCellWidths[7],
    sortable: true,
  },
];

export default function SnowSweeperServiceHistorySection() {
  const { serviceHistoryData } = useSelector(state => state.routes.serviceHistory);

  const [highlightedRows, setHighlightedRows] = useState([] as number[]);

  if (!serviceHistoryData) return null;

  const toggleHighlightedRow = (id: number) => {
    const newHighlightedRows = highlightedRows.includes(id)
      ? highlightedRows.filter(highlightedRow => highlightedRow !== id)
      : [...highlightedRows, id];

    setHighlightedRows(newHighlightedRows);
  };

  return (
    <>
      {serviceHistoryData.length ? (
        <Table
          cells={serviceHistoryTableCells}
          rows={serviceHistoryData}
          rowComponent={SnowSweeperServiceHistoryTableRow}
          rowProps={{ highlightedRows, toggleHighlightedRow }}
          sortedBy="activityInitiation"
        />
      ) : (
        <Text block align="center" margin="sMedium no small no">
          {translate('routes.noServiceHistoryDetails')}
        </Text>
      )}
    </>
  );
}
