import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { RouteMapFeature, setRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { ROUTE_MAP_CLUSTERS_SOURCE } from '../../routes/routePageSections/routeMap/constants';
import RouteSequenceStopsGLLayers from './RouteSequenceStopsGLLayers';
import RouteSequenceStopPopupResolver from './RouteSequenceStopPopupResolver';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE } from './constants';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';

const mapImages = NEW_INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: ROUTE_MAP_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;

  showOrderNumbers: boolean;
  zoomLevel: number;
  routeLocations?: RouteLocation[];
  routeStops?: RouteStop[];
  isTemplate?: boolean;
};

export default function RouteSequenceStopsGL({
  map,
  routeStops,
  routeLocations,
  isTemplate,
  showOrderNumbers,
  zoomLevel,
}: Props) {
  useMapImages(mapImages, map, mapImagesOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE);

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, feature.properties?.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <RouteSequenceStopsGLLayers map={map} showOrderNumbers={showOrderNumbers} zoomLevel={zoomLevel} />
      <RouteSequenceStopPopupResolver routeStops={routeStops} routeLocations={routeLocations} isTemplate={isTemplate} />
    </>
  );
}
