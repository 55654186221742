import { memo } from 'react';
import { Layer } from 'react-map-gl';

import { COMMUNITY_INSIGHTS_CLUSTERS_SOURCE } from './CommunityInsightsClustersGL';
import { INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';

const mapImages = INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: COMMUNITY_INSIGHTS_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

export const COMMUNITY_INSIGHTS_LAYER = 'communityInsightsLayer';

interface Props {
  map: mapboxgl.Map;
}
const CommunityInsightsGL = memo<Props>(({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  return (
    <Layer
      id={COMMUNITY_INSIGHTS_LAYER}
      type="symbol"
      source={COMMUNITY_INSIGHTS_CLUSTERS_SOURCE}
      paint={{}}
      filter={['!', ['has', 'point_count']]}
      layout={{
        'icon-image': ['get', 'icon'],
        'icon-size': 0.8,
        'icon-allow-overlap': true,
      }}
    />
  );
});

export default CommunityInsightsGL;
