import ReactPlayer from 'react-player';

import { dateAndTime, duration } from 'src/utils/services/formatter';
import {
  DescriptionList,
  DescriptionListItem,
  ImageSliderDownloadIcon,
  ModalSection,
  VideoDownload,
  VideoDownloadWrapper,
} from '../../../core/components/styled';
import { DriverSafetyEvent } from 'src/insights/services/reportingDetailsOptions';
import { Modal } from 'src/core/components';
import translate from 'src/core/services/translate';
import { DRIVER_FACING } from './DriverSafetyImageModal';
import useDownloadFileWithProgress from 'src/common/hooks/useDownloadFIleWithProgress';
import { ProgressDoughnut } from 'src/common/components';

interface Props {
  closeModal: () => void;
  driverSafetyEvent: DriverSafetyEvent;
}

const DriverSafetyVideoModal = ({ driverSafetyEvent, closeModal }: Props) => {
  const fileName = `${driverSafetyEvent.driverName} - ${driverSafetyEvent.typeOfEvent} - ${driverSafetyEvent.cameraType}`;
  const fileUrl =
    driverSafetyEvent.cameraType === DRIVER_FACING
      ? driverSafetyEvent.driverFacingVideoUrl
      : driverSafetyEvent.frontFacingVideoUrl;

  const { download, progress, isDownloading } = useDownloadFileWithProgress();

  const downloadVideo = (downloadUrl?: string) => {
    downloadUrl && download(downloadUrl, `${fileName}.mp4`);
  };

  return (
    <Modal title={driverSafetyEvent.driverName} onClose={closeModal} padding="medium no no">
      <ModalSection>
        <DescriptionList centered margin="small no no">
          {!!driverSafetyEvent.timeOfEvent && (
            <DescriptionListItem>
              <strong>{translate('insights.driverSafety.timeOfEvent')}</strong>:{' '}
              {dateAndTime(driverSafetyEvent.timeOfEvent)}
            </DescriptionListItem>
          )}
          {!!driverSafetyEvent.typeOfEvent && (
            <DescriptionListItem margin="no no no medium">
              <strong>{translate('insights.driverSafety.typeOfEvent')}</strong>:{' '}
              {translate(`insights.driverSafety.events.${driverSafetyEvent.typeOfEvent}`)}
            </DescriptionListItem>
          )}
          {!!driverSafetyEvent.durationInSeconds && (
            <DescriptionListItem margin="no no no medium">
              <strong>{translate('insights.driverSafety.duration')}</strong>:{' '}
              {duration(driverSafetyEvent.durationInSeconds)}
            </DescriptionListItem>
          )}
          <DescriptionListItem margin="no no no medium">
            <strong>{translate('insights.driverSafety.camera')}</strong>: {driverSafetyEvent.cameraType}
          </DescriptionListItem>
        </DescriptionList>
      </ModalSection>

      <VideoDownloadWrapper>
        <VideoDownload onClick={() => (isDownloading ? () => {} : downloadVideo(fileUrl))}>
          {isDownloading ? (
            <ProgressDoughnut progress={progress.percent} size={35} />
          ) : (
            <>
              <ImageSliderDownloadIcon /> {translate('common.download')}
            </>
          )}
        </VideoDownload>

        <ReactPlayer url={fileUrl} controls={true} width="100%" />
      </VideoDownloadWrapper>
    </Modal>
  );
};

export default DriverSafetyVideoModal;
