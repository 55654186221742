import { reduxForm, InjectedFormProps } from 'redux-form';
import { map } from 'lodash-es';

import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  PanelSection,
  PanelSectionGroup,
  PanelSectionSubTitle,
} from '../../../core/components/styled';
import { Dropdown, Input, TypedField } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isRequired, maxLength50, maxLength160 } from '../../../utils/services/validator';
import { SCHEDULED_DATE_TYPE } from 'src/core/constants/scheduledDateType';
import translate from 'src/core/services/translate';

function GeneralSettingsForm({ form, handleSubmit }: InjectedFormProps) {
  const dateTypeOptions = map(SCHEDULED_DATE_TYPE, date => ({
    label: date.name,
    value: date.id,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="no">
        <PanelSectionGroup padding="no" width="100%">
          <Grid multiLine>
            <GridColumn size="12/12">
              <PanelSectionSubTitle margin="small no small">
                {translate('vendors.open311.apiSettings')}
              </PanelSectionSubTitle>
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="apiUrl"
                component={Input}
                validate={[isRequired]}
                props={{
                  label: translate('vendors.apiURL'),
                  margin: 'no',
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="apiKey"
                component={Input}
                validate={[isRequired]}
                props={{
                  label: translate('vendors.apiKey'),
                  margin: 'no',
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSectionGroup>
      </PanelSection>
      <PanelSection padding="no">
        <PanelSectionGroup padding="small no" width="100%">
          <Grid margin="no" multiLine>
            <GridColumn size="12/12">
              <PanelSectionSubTitle margin="small no small">
                {translate('vendors.open311.fieldMappingSetting')}
              </PanelSectionSubTitle>
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="locationAccountNumber"
                component={Input}
                validate={[isRequired, maxLength50]}
                props={{
                  label: translate('vendors.open311.locationAccountNumber'),
                  margin: 'no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="locationAccountNumberFieldType"
                component={Dropdown}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.locationAccountNumberFieldType'),
                  margin: 'no',
                  options: dateTypeOptions,
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="customerAccountNumber"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.customerAccountNumber'),
                  margin: 'small no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="customerAccountNumberFieldType"
                component={Dropdown}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.customerAccountNumberFieldType'),
                  margin: 'small no',
                  options: dateTypeOptions,
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="serviceArea"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.serviceArea'),
                  margin: 'small no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="serviceAreaFieldType"
                component={Dropdown}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.serviceAreaFieldType'),
                  margin: 'small no',
                  options: dateTypeOptions,
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="siteLocation"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.siteLocation'),
                  margin: 'small no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="siteLocationFieldType"
                component={Dropdown}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.siteLocationFieldType'),
                  margin: 'small no',
                  options: dateTypeOptions,
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="adHocValue"
                component={Input}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.adhocValue'),
                  margin: 'small no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="adHocValueFieldType"
                component={Dropdown}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.adhocValueFieldType'),
                  margin: 'small no',
                  options: dateTypeOptions,
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="scheduledDate"
                component={Input}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.scheduledDate'),
                  margin: 'small no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="scheduledDateFieldType"
                component={Dropdown}
                validate={[maxLength160]}
                props={{
                  label: translate('vendors.open311.scheduledDateType'),
                  margin: 'small no',
                  options: dateTypeOptions,
                  defaultValue: 1,
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSectionGroup>
      </PanelSection>
      <PanelSection padding="no">
        <PanelSectionGroup padding="small no" width="100%">
          <Grid margin="no" multiLine>
            <GridColumn size="12/12">
              <PanelSectionSubTitle margin="no no small">
                {translate('vendors.open311.defaultCloseOutSettings')}
              </PanelSectionSubTitle>
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="closeOutCode"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.code'),
                  margin: 'no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="outcomeCode"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.outcomeCode'),
                  margin: 'no',
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="responseCode"
                component={Input}
                validate={[maxLength50]}
                props={{
                  label: translate('vendors.open311.responseCode'),
                  margin: 'small no',
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSectionGroup>
      </PanelSection>

      <Grid centered>
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </Grid>
    </form>
  );
}
export default reduxForm({ form: 'generalSettingsForm', onSubmitFail: focusFirstInvalidField })(GeneralSettingsForm);
