import { http } from 'src/core/services/http';

export const loadEmailRecipients = (
  invoiceId: number,
) =>
  http
    .get(`statements/${invoiceId}/invoiceEmailRecipients`)
    .then(response => response.data);

export const sendBill = (invoiceId: number,emails: string[]) =>
  http.put(`statements/${invoiceId}/mailInvoice`, emails);
