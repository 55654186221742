import { map, filter } from 'lodash-es';

import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

export const transformLoadBusinessType = (businessType: any) => ({
  ...businessType,
  categories: map(businessType.categories, category => ({
    ...category,
    displayTitle: translate(createTranslationKey(category.title, 'haulerProfile.businessCategories')),
  })),
  types: map(businessType.types, category => ({
    ...category,
    displayTitle: translate(createTranslationKey(category.title, 'haulerProfile.businessTypes')),
  })),
  classifications: map(businessType.classifications, category => ({
    ...category,
    displayTitle: translate(createTranslationKey(category.title, 'haulerProfile.classifications')),
  })),
});

export const transformSaveBusinessType = (businessType: any) => ({
  ...businessType,
  categories: map(filter(businessType.categories, { isSelected: true })),
  types: map(filter(businessType.types, { isSelected: true })),
  classifications: map(filter(businessType.classifications, { isSelected: true })),
});
