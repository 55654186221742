import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Grid, Message } from 'src/core/components/styled';
import { LowInventoryPartPartHistory } from 'src/fleet/interfaces/ContainerStatistics';
import { LowInventoryPartHistoryTableRow } from './';
import { Modal, Table } from 'src/core/components';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: () => void;
  lowInventoryPartHistory: LowInventoryPartPartHistory[];
  partName: string | number;
}

class LowInventoryPartHistoryModal extends PureComponent<Props> {
  render() {
    const { closeModal, lowInventoryPartHistory, partName } = this.props;

    const lowInventoryPartHistoryTableCells = [
      { name: 'date', label: translate('common.date'), width: '15%' },
      { name: 'user', label: translate('containers.user'), width: '25%' },
      { name: 'action', label: translate('containers.action'), width: '20%' },
      { name: 'quantity', label: translate('containers.quantity'), width: '20%' },
      { name: 'inventory', label: translate('containers.inventory'), width: '20%' },
    ];

    return (
      <Modal
        title={`${partName} ${translate('containers.history')}`}
        onClose={closeModal}
        padding="medium small no small"
      >
        <Grid margin="medium no no no">
          {lowInventoryPartHistory.length ? (
            <Table
              cells={lowInventoryPartHistoryTableCells}
              rows={lowInventoryPartHistory}
              rowComponent={LowInventoryPartHistoryTableRow}
              rowProps={{}}
            />
          ) : (
            <Message padding="sMedium">{translate('containers.noLowInventoryPartHistory')}</Message>
          )}
        </Grid>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  lowInventoryPartHistory: state.fleet.lowInventoryParts.lowInventoryPartHistory || [],
});

export default connect(mapStateToProps)(LowInventoryPartHistoryModal);
