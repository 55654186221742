import { http } from '../../core/services/http';

export const loadServiceAreas = (haulerId: string, divisionId?: string) =>
  http.get(`/haulerProfile/${haulerId}/serviceareas`).then(response => response.data);

export const loadServiceAreaOptions = (haulerId: string, divisionId?: string) =>
  http.get(`/haulerProfile/${haulerId}/servicearea/options`).then(response => response.data);

export const saveServiceArea = (haulerId: string, serviceArea: any) =>
  http.post(`/haulerProfile/${haulerId}/servicearea`, serviceArea);

export const deleteServiceArea = (haulerId: string, equipmentId: number, divisionId?: string) =>
  http.delete(`/haulerProfile/${haulerId}/servicearea/${equipmentId}`);
