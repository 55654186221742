import React, { Fragment, useState } from 'react';

import { debounce, size } from 'lodash-es';
import { Field, FieldArray, formValueSelector, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { ALL_EQUIPMENTS_TYPES } from '../../constants';
import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  FormError,
  Grid,
  GridColumn,
  IconButtonIcon,
  ModalFixedFooter,
  ModalFixedHeader,
  ModalSection,
  ModalTitle,
  TableActionButton,
  Text,
} from '../../../core/components/styled';
import { Dropdown, MultiSelect, TypeAhead } from '../../../core/components';
import { FormContainer } from 'src/core/components/styled/Form';
import {
  getAllEquipmentSizes,
  getEquipmentSizeOptions,
  getEquipmentTypeId,
  getEquipmentTypeOptions,
  getMaterialOptions,
} from '../../utils/equipments';
import { isMultiSelectRequired } from '../../../utils/services/validator';
import { loadZipCodeBoundaries } from '../../ducks';
import { loadZipCodes } from '../../services/zipCodeSearch';
import { ZipCode, ZipCodeWrapper } from '../styled';
import { ZipCodeBoundaries } from 'src/haulerProfile/interfaces/ZipCodeBoundaries';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import ServiceAreasMapGL from '../ServiceAreasMapGL';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector('serviceAreasEditorForm');

type ZipCodesFieldArrayProps = {
  clearZipCode: () => void;
  onChange: () => void;
  setIsZipCodeChanged: (isZipCodeChanged: boolean) => void;
};

function ZipCodesFieldArray({
  fields,
  clearZipCode,
  onChange,
  setIsZipCodeChanged,
}: ZipCodesFieldArrayProps & WrappedFieldArrayProps) {
  const dispatch = useDispatch();

  const zipCode = useSelector((state: AppState) => formSelector(state, 'zipCode'));
  const zipCodes = useSelector((state: AppState) => formSelector(state, 'zipCodes')) || [];

  const [isZipCodeErrorVisible, setIsZipCodeErrorVisible] = useState(false);

  const loadZipCodeOptions = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    loadZipCodes(searchTerm).then(zipCodesLoaded => {
      const currentZipCodes = zipCodesLoaded.filter((zipCodeLoaded: string) => zipCodes.indexOf(zipCodeLoaded) < 0);
      onOptionsLoaded(currentZipCodes.map((zipCodeLoaded: string) => ({ value: zipCodeLoaded, label: zipCodeLoaded })));
    });
  }, 500);

  const handleAddZipCodeClick = () => {
    if (zipCode) {
      fields.push(zipCode);
      clearZipCode();
      loadZipCodeBoundaries([...zipCodes, zipCode])(dispatch);
      setIsZipCodeErrorVisible(false);
    } else {
      setIsZipCodeErrorVisible(true);
    }
    onChange();
    setIsZipCodeChanged(true);
  };

  const onZipCodeChange = () => {
    onChange();
    setIsZipCodeErrorVisible(false);
  };

  const onRemoveField = (index: number) => {
    fields.remove(index);
    setIsZipCodeChanged(true);
  };

  return (
    <Fragment>
      <GridColumn size="9/12">
        <Field
          name="zipCode"
          component={TypeAhead}
          margin="small no no no"
          label={translate('haulerProfile.zipCode')}
          getOptions={loadZipCodeOptions}
          onChange={onZipCodeChange}
          isClearable
          maxMenuHeight={160}
        />
        {isZipCodeErrorVisible && <FormError>{translate('common.validationMessages.isRequired')}</FormError>}
      </GridColumn>

      <GridColumn size="3/12">
        <Button type="button" color="primary" margin="medium no no no" onClick={handleAddZipCodeClick} fullWidth>
          {translate('common.add')}
        </Button>
      </GridColumn>

      {!!size(fields) && (
        <GridColumn size="12/12" padding="small xSmall">
          <ZipCodeWrapper>
            {fields.map((_, index) => (
              <ZipCode key={index} margin="no small no no">
                <Text size="medium">{fields.get(index)}</Text>
                <TableActionButton onClick={() => onRemoveField(index)} color="primary">
                  <IconButtonIcon icon="cancelCircle" size="large" margin="xxSmall no no xxSmall" />
                </TableActionButton>
              </ZipCode>
            ))}
          </ZipCodeWrapper>
        </GridColumn>
      )}
    </Fragment>
  );
}

interface PropsWithoutReduxForm {
  closeModal: (formPristine?: boolean) => void;
  equipmentId?: number;
}
interface FormValues {
  equipmentTypes?: string;
  equipmentSizes?: string[];
  materials?: string[];
  zipCodes?: string[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

function ServiceAreaEditorForm({ change, closeModal, equipmentId, handleSubmit, pristine }: Props) {
  const isEditMode = !!equipmentId;

  const savedEquipments =
    useSelector((state: AppState) => state.haulerProfile.serviceAreas.serviceAreaOptions?.savedEquipments) || [];
  const savedMaterials =
    useSelector((state: AppState) => state.haulerProfile.serviceAreas.serviceAreaOptions?.savedMaterials) || [];
  const zipCodes = useSelector((state: AppState) => formSelector(state, 'zipCodes'));
  const equipmentTypes = useSelector((state: AppState) => formSelector(state, 'equipmentTypes'));
  const equipmentSizes = useSelector((state: AppState) => formSelector(state, 'equipmentSizes'));
  const { isLoading: isLoadingZipCodeBoundaries, zipCodeBoundaries } = useSelector(
    (state: AppState) => state.haulerProfile.zipCodeBoundaries,
  );
  const isFormSaving = useSelector((state: AppState) => state.haulerProfile.serviceAreas.isSaving);

  const [equipmentSizeOptions, setEquipmentSizeOptions] = useState(getEquipmentSizeOptions(savedEquipments, []));
  const [isZipCodeErrorVisible, setIsZipCodeErrorVisible] = useState(false);
  const [isZipCodeChanged, setIsZipCodeChanged] = useState(false);

  const equipmentTypeOptions = [
    { label: translate('common.all'), value: ALL_EQUIPMENTS_TYPES },
    ...getEquipmentTypeOptions(savedEquipments),
  ];
  const materialOptions = getMaterialOptions(savedMaterials);

  const selectedEquipmentSizes = equipmentSizeOptions
    .filter(equipmentSize => equipmentSizes?.includes(equipmentSize.value))
    .map(equipmentSize => equipmentSize.label);

  const handleChangeEquipmentTypes = (equipmentTypes: any) => {
    const equipmentSizes =
      equipmentTypes === ALL_EQUIPMENTS_TYPES
        ? getAllEquipmentSizes(savedEquipments)
        : savedEquipments.find(equipment => getEquipmentTypeId(equipment) === equipmentTypes)?.binTypes || [];

    const equipmentSizeOptions = equipmentSizes.map(equipmentSize => ({
      label: equipmentSize.other
        ? equipmentSize.other
        : translate(`haulerProfile.equipments.equipmentTypes.${equipmentSize.binClass}`),
      value: equipmentSize.vendorGroupEquipmentId,
    }));

    setEquipmentSizeOptions(equipmentSizeOptions);
    change(
      'equipmentSizes',
      equipmentSizes.map(equipmentSize => equipmentSize.vendorGroupEquipmentId),
    );
  };

  const formatEquipmentSizesText = (selectedOptions: number[], allOptionsSelected: string[]) => {
    if (allOptionsSelected) {
      return translate('common.all');
    }

    return selectedOptions
      .map(
        selectedOption =>
          equipmentSizeOptions.find(equipmentSizeOption => equipmentSizeOption.value === selectedOption)?.label,
      )
      .join(', ');
  };

  const formatMaterialsText = (selectedOptions: number[], allOptionsSelected: string[]) => {
    if (allOptionsSelected) {
      return translate('common.all');
    }

    return selectedOptions
      .map(selectedOption => materialOptions.find(materialOption => materialOption.value === selectedOption)?.label)
      .join(', ');
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (size(zipCodes)) {
      setIsZipCodeErrorVisible(false);
      handleSubmit(event);
    } else {
      setIsZipCodeErrorVisible(true);
      change('zipCode', '');
    }
  };

  const onZipCodeChange = () => {
    setIsZipCodeErrorVisible(false);
  };

  const onZipCodeClear = () => {
    change('zipCode', '');
  };

  const filteredZipFeatures = (zipCodes: string[], zipCodeBoundaries?: ZipCodeBoundaries) =>
    (zipCodeBoundaries?.features || []).filter(feature => zipCodes?.includes(feature.properties.zip)) || [];
  const filteredFeatures = filteredZipFeatures(zipCodes, zipCodeBoundaries);

  return (
    <Fragment>
      <ModalSection padding="no medium" fluid>
        <FormContainer>
          <ModalFixedHeader padding="sMedium no no no">
            <ModalTitle>{translate(`haulerProfile.${isEditMode ? 'edit' : 'create'}ServiceArea`)}</ModalTitle>
          </ModalFixedHeader>
          <form onSubmit={onSubmit}>
            <Grid padding="no small no no" margin="no no xxGrande no" multiLine>
              <GridColumn size="12/12">
                <Field
                  name="equipmentTypes"
                  component={Dropdown}
                  onChange={handleChangeEquipmentTypes}
                  margin="small no"
                  options={equipmentTypeOptions}
                  label={translate('common.equipmentType')}
                  disabled={isEditMode}
                />
              </GridColumn>

              <GridColumn size="12/12">
                <Field
                  name="equipmentSizes"
                  component={MultiSelect}
                  margin="small no"
                  normalizeValues={Number}
                  options={equipmentSizeOptions}
                  label={translate('common.equipmentSize')}
                  formatText={formatEquipmentSizesText}
                  disabled={isEditMode || equipmentTypes === ALL_EQUIPMENTS_TYPES}
                  validate={[isMultiSelectRequired]}
                  noOptionsMessage={translate('haulerProfile.noEquipmentConfigured')}
                />
              </GridColumn>

              <GridColumn size="12/12">
                <Field
                  name="materials"
                  component={MultiSelect}
                  margin="small no"
                  normalizeValues={Number}
                  options={materialOptions}
                  label={translate('common.material')}
                  formatText={formatMaterialsText}
                  validate={[isMultiSelectRequired]}
                  noOptionsMessage={translate('haulerProfile.noMaterialsConfigured')}
                />
              </GridColumn>

              <FieldArray
                name="zipCodes"
                component={ZipCodesFieldArray}
                onChange={() => onZipCodeChange()}
                clearZipCode={() => onZipCodeClear()}
                setIsZipCodeChanged={setIsZipCodeChanged}
              />

              {isZipCodeErrorVisible && (
                <GridColumn size="12/12">
                  <FormError>{translate('common.validationMessages.hasZipCode')}</FormError>
                </GridColumn>
              )}
            </Grid>
            <ModalFixedFooter padding="sMedium small" justifyContent="space-around">
              <ButtonSet align="center" margin="no small">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
                <Button type="button" margin="no small" color="secondary" onClick={() => closeModal(pristine)}>
                  {translate('common.cancel')}
                </Button>
              </ButtonSet>
            </ModalFixedFooter>
          </form>
        </FormContainer>
      </ModalSection>
      <ModalSection padding="no" fluid noOutline isLoading={isLoadingZipCodeBoundaries || isFormSaving}>
        <ServiceAreasMapGL
          equipmentSizes={selectedEquipmentSizes}
          equipmentTypes={equipmentTypes}
          filteredFeatures={filteredFeatures}
          isServiceAreaEditorModalOpen={true}
          isZipCodeChanged={isZipCodeChanged}
          setIsZipCodeChanged={setIsZipCodeChanged}
        />
      </ModalSection>
    </Fragment>
  );
}

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'serviceAreasEditorForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(ServiceAreaEditorForm);
