export const RED = 2;
export const YELLOW = 5;
export const GREEN = 3;
export const BLUE = 0;
export const POLYLINE_COLORS = [
  '#0000FF',
  '#FFA500',
  '#FF0000',
  '#008000',
  '#800080',
  '#FFF200',
  '#E15554',
  '#7768AE',
  '#FC814A',
  '#109648',
  '#FF007F',
];
export const SCORES = {
  good: 4.5,
  severe: 3.75,
};
