import { FC, useState } from 'react';

import { Grid, GridColumn, ButtonSet, Button, Text, ListBoxContainer, ListBoxItem } from './styled';
import { DropdownOption } from './Dropdown';

export const BlockMultiSelect: FC<{
  block1Label: string;
  block2Label: string;
  block1Items: DropdownOption[];
  block2Items: DropdownOption[];
  onChange: (options: DropdownOption[], inBlock2: boolean) => void;
}> = ({ block1Label, block2Label, block1Items, block2Items, onChange }) => {
  const [selectedItem, setSelectedItem] = useState<DropdownOption | undefined>();

  const move = (inBlock2: boolean) => {
    if (selectedItem) {
      onChange([selectedItem], inBlock2);
    }
  };

  const moveAll = (inBlock2: boolean) => {
    onChange(inBlock2 ? block1Items : block2Items, inBlock2);
  };

  return (
    <Grid centered multiLine>
      <GridColumn size="5/12">
        <GridColumn shift="5/12">
          <Text size="large">{block1Label}</Text>
        </GridColumn>
        <ListBoxContainer right>
          {block1Items.map(item => (
            <ListBoxItem
              selectedItem={selectedItem?.value}
              id={item.value}
              key={item.value}
              onClick={() => setSelectedItem(item)}
            >
              {item.label}
            </ListBoxItem>
          ))}
        </ListBoxContainer>
      </GridColumn>
      <GridColumn size="2/12">
        <ButtonSet vertical>
          <Button margin="xSmall" disabled={!block1Items.length} color="primary" onClick={() => moveAll(true)}>
            &gt;&gt;
          </Button>
          <Button
            margin="xSmall"
            disabled={selectedItem && !block1Items.includes(selectedItem)}
            color="primary"
            onClick={() => move(true)}
          >
            &gt;
          </Button>
          <Button
            margin="xSmall"
            disabled={selectedItem && !block2Items.includes(selectedItem)}
            color="primary"
            onClick={() => move(false)}
          >
            &lt;
          </Button>
          <Button margin="xSmall" disabled={!block2Items.length} color="primary" onClick={() => moveAll(false)}>
            &lt;&lt;
          </Button>
        </ButtonSet>
      </GridColumn>
      <GridColumn size="5/12">
        <GridColumn shift="2/12">
          <Text size="large">{block2Label}</Text>
        </GridColumn>
        <ListBoxContainer>
          {block2Items.map(item => (
            <ListBoxItem
              selectedItem={selectedItem?.value}
              id={item.value}
              key={item.value}
              onClick={() => setSelectedItem(item)}
            >
              {item.label}
            </ListBoxItem>
          ))}
        </ListBoxContainer>
      </GridColumn>
    </Grid>
  );
};
