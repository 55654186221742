import React from 'react';

import { Field } from 'redux-form';
import { WrappedFieldProps } from 'redux-form';

import { Switch } from '../../core/components';
import { TableCell, TableRow } from '../../core/components/styled';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

interface Props extends WrappedFieldProps {
  index: number;
  technicalName: string;
  vendorWasteAuditConfigurationIndex?: number;
}

const VendorWasteAuditConfigurationStatusTypesTableRow: React.FC<Props> = ({
  index,
  technicalName,
  vendorWasteAuditConfigurationIndex,
}) => (
  <TableRow>
    <TableCell width="80%">
      {translate(createTranslationKey(technicalName, 'routes.wasteAuditLocationStatusTypes'))}
    </TableCell>
    <TableCell width="20%" align="right">
      <Field
        name={`wasteAuditConfiguration.${vendorWasteAuditConfigurationIndex}.vendorWasteAuditConfigurationStatusTypes.${index}.allowPhotoUpload`}
        component={Switch}
      />
    </TableCell>
  </TableRow>
);

export default VendorWasteAuditConfigurationStatusTypesTableRow;
