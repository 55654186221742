import { map, size } from 'lodash-es';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';

import { MapGL } from 'src/common/components/map/MapGL';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { Button, IconButtonIcon, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { MapGLWrapper } from 'src/customers/components/styled';
import {
  TRAVEL_PATH_BUILDER_FILTERS_FORM,
  TravelPathBuilderFiltersFormValues,
} from 'src/routes/components/forms/travelPath/TravelPathBuilderFiltersForm';
import TravelPathEditorMapForm, {
  MapLayersFormValues,
} from 'src/routes/components/forms/travelPath/TravelPathEditorMapForm';
import {
  TravelPathBuilderActionsWrapper,
  TravelPathErrorWrapper,
  TravelPathWarningMapWrapper,
} from 'src/routes/components/styled';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import {
  downloadTravelPathGeoJsonFile,
  finishTravelPathBuildOrEdit,
  loadGeoFencesForTravelPathModal,
  loadHaulerLocationsForTravelPathModal,
  loadRouteSegmentsForTravelPathModal,
  loadRouteStopsForTravelPathModal,
  loadRouteVehiclePositionAndBreadcrumb,
  loadTravelPathForBuildOrEdit,
  publishEditsToTravelPath,
  resetMapFeatures,
  resetTravelPathBuildTracer,
  setShowTravelPath,
  travelPathBuilderDoTracing,
} from 'src/routes/ducks';
import { useTravelPathBuilderMapBounds } from 'src/routes/hooks/useTravelPathBuilderMapBounds';
import useTravelPathBuilderService from 'src/routes/hooks/useTravelPathBuilderService';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import TravelPathGeoFencesGL from '../../TravelPathEditor/travelPathEditorSections/geoFences/TravelPathGeoFencesGL';
import TravelPathHaulerLocationsGL from '../../TravelPathEditor/travelPathEditorSections/haulerLocations/TravelPathHaulerLocationsGL';
import { TRAVEL_PATH_HAULER_LOCATIONS_SOURCE } from '../../TravelPathEditor/travelPathEditorSections/haulerLocations/TravelPathHaulerLocationsGLSource';
import TravelPathRouteSegmentsGL from '../../TravelPathEditor/travelPathEditorSections/routeSegments/TravelPathRouteSegmentsGL';
import { TRAVEL_PATH_ROUTE_SEGMENTS_GL_LAYER } from '../../TravelPathEditor/travelPathEditorSections/routeSegments/TravelPathRouteSegmentsGLSource';
import TravelPathRouteStopsGL from '../../TravelPathEditor/travelPathEditorSections/routeStops/TravelPathRouteStopsGL';
import { TRAVEL_PATH_ROUTE_STOPS_LAYER } from '../../TravelPathEditor/travelPathEditorSections/routeStops/TravelPathRouteStopsGLSource';
import TravelPathGL from '../../TravelPathEditor/travelPathEditorSections/travelPath/TravelPathGL';
import TravelPathOriginalGL from '../../TravelPathEditor/travelPathEditorSections/travelPath/TravelPathOriginalGL';
import TravelPathBuilderRangeSelection from '../TravelPathBuilderRangeSelection';
import TravelPathBreadcrumbsFlagsGL from './breadCrumbsFlags/TravelPathBreadcrumbsFlagsGL';
import {
  TRAVEL_PATH_VEHICLE_BREADCRUMBS_ARROWS_LAYER,
  TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_LAYER,
} from './vehicleBreadCrumbs/TravelPathVehicleBreadcrumbGLSource';
import TravelPathVehicleBreadcrumbsGL from './vehicleBreadCrumbs/TravelPathVehicleBreadcrumbsGL';
import TravelPathVehiclePositionsGL from './vehiclePositions/TravelPathVehiclePositionsGL';
import { TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER } from './vehiclePositions/VehiclePositionsGLSource';
import { loadRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { Box } from 'src/core/components/styled/Box';
import { UnconnectedSwitch } from 'src/core/components';
import { TRAVEL_PATH_FLAGS_LAYER } from './breadCrumbsFlags/TravelPathBreadcrumbsFlagsGLSource';
import confirm from 'src/core/services/confirm';
import TravelPathBuilderInstructions from '../../TravelPathEditor/travelPathEditorSections/legends/TravelPathBuilderInstructions';

const normalizeFilterObjectAsNumbersAndRemovePrefix = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: number[], cur) => (cur[1] ? [...acc, Number(cur[0].replace('_', ''))] : acc), []);

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

interface Props {
  isAlternativeFleet?: boolean;
  isSnowPlow?: boolean;
  routeTemplateId?: number;
  closeModal: (shouldRefreshDisplayedTP: boolean) => void;
}

const TravelPathBuilderMapGL: FC<Props> = ({ isAlternativeFleet, isSnowPlow, routeTemplateId, closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);
  const [mapBearing, setMapBearing] = useState<number>(0);

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [showOrderNumbers, setShowOrderNumbers] = useState<boolean>(false);
  const [showVehicleTrackings, setShowVehicleTrackings] = useState<boolean>(true);

  const { routeTemplateIdForBuildOrEdit, isDownloadingGeoJsonFile, travelPathDataForBuildOrEdit } = useSelector(
    state => state.routes.travelPath,
  );

  const { geoFence } = useSelector(state => state.routes.geoFence);
  const [showRouteGeoFence, setShowRouteGeoFence] = useState(false);

  const filters = useSelector(getFormValues(TRAVEL_PATH_BUILDER_FILTERS_FORM)) as TravelPathBuilderFiltersFormValues;

  const {
    isLoadingMapFeatures,
    isApplyingEditsToTravelPath,
    isLoadingTravelPathEdited,
    isSavingEditsToTravelPath,
    isLoadingRouteVehiclePositionAndBreadcrumb,
    routeVehicleBreadCrumbs,
    isDoingTravelPathBuilderTracing,
    initEditConfigs,
  } = useSelector(state => state.routes.travelPathBuildAndEdit);

  const didDoAnyTravelPathBuilderTracing = useSelector(
    state => state.routes.travelPathBuildAndEdit.didDoAnyTravelPathBuilderTracing,
  );

  const hasSelectedRequiredFilters = useMemo(() => {
    return filters?.date && filters?.route && filters?.vehicle;
  }, [filters]);

  /**
   * Loading Vehicle Positions and BreadCrumbs
   */
  useEffect(() => {
    if (filters?.date && filters?.route && filters?.vehicle) {
      loadRouteVehiclePositionAndBreadcrumb(filters.route, filters.date, filters.vehicle)(dispatch);
    }
  }, [dispatch, filters?.date, filters?.route, filters?.vehicle]);

  /**
   * Loading map features for display
   */
  const handleSubmitLayersForm = async (formData: MapLayersFormValues) => {
    // geoFences
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);
    const normalizedGeoFencesIds = normalizeFilterObjectAsNumbersAndRemovePrefix(formData.geoFenceSubFilters);
    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      loadGeoFencesForTravelPathModal({
        vendorId,
        geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
        limit: 300,
        geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
      })(dispatch);
    } else {
      dispatch(resetMapFeatures('geoFences'));
    }

    if (geoFence && formData.geoFencesTypesFilters[geoFence.id] && routeTemplateId) {
      setShowRouteGeoFence(true);

      const isTemplate = true;
      await loadRouteGeoFence(routeTemplateId, isTemplate)(dispatch);
    } else if (!normalizedGeoFencesIds.length) {
      setShowRouteGeoFence(false);
    }

    // routeSegments
    if (formData.showRouteSegments) {
      loadRouteSegmentsForTravelPathModal(vendorId, undefined, routeTemplateId)(dispatch);
    } else dispatch(resetMapFeatures('routeSegments'));
    // routeStops
    if (formData.showRouteStops) loadRouteStopsForTravelPathModal(vendorId, undefined, routeTemplateId)(dispatch);
    else dispatch(resetMapFeatures('routeStops'));
    // haulerLocations
    const filterHaulerLocations = normalizeFilterObjectAsNumbersAndRemovePrefix(formData.haulerLocations);
    if (size(filterHaulerLocations)) {
      loadHaulerLocationsForTravelPathModal(filterHaulerLocations.toString())(dispatch);
    } else dispatch(resetMapFeatures('haulerLocations'));

    // previous travelPath
    if (formData.showTravelPath) {
      if (routeTemplateIdForBuildOrEdit !== routeTemplateId || !travelPathDataForBuildOrEdit)
        await downloadTravelPathGeoJsonFile(undefined, routeTemplateId, true)(dispatch);
      dispatch(setShowTravelPath(true));
    } else {
      dispatch(setShowTravelPath(false));
    }

    setShowOrderNumbers(formData.showOrderNumbers);
    setIsFormOpen(false);
  };

  /**
   * Map events
   */
  useEffect(() => {
    mapInstance?.once('load', () => {
      mapInstance.on('mousemove', event => {
        const features = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              [
                TRAVEL_PATH_ROUTE_STOPS_LAYER,
                TRAVEL_PATH_HAULER_LOCATIONS_SOURCE,
                TRAVEL_PATH_ROUTE_SEGMENTS_GL_LAYER,
                TRAVEL_PATH_BUILDER_VEHICLE_POSITIONS_LAYER,
                TRAVEL_PATH_VEHICLE_BREADCRUMBS_ARROWS_LAYER,
                TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_LAYER,
              ].includes(feature.layer.id) && feature?.properties?.clickable,
          );

        mapInstance.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      mapInstance.on('mouseleave', () => {
        mapInstance.getCanvas().style.cursor = '';
      });

      mapInstance.on('rotate', () => {
        setMapBearing(mapInstance.getBearing());
      });

      mapInstance.on('idle', () => {
        // move flags layer to top
        if (mapInstance.getLayer(TRAVEL_PATH_FLAGS_LAYER)) {
          mapInstance.moveLayer(TRAVEL_PATH_FLAGS_LAYER);
        }
      });
    });
  }, [mapInstance]);

  /**
   * TRAVEL PATH BUILDER FUNCTIONS
   */
  const {
    setMaxSelected,
    setMinSelected,
    minAndMaxCoordinatesGeoJSON,
    getBreadCrumbsForTravelPathTransaction,
    isSubmitButtonDisabled,
    parsedBreadCrumbsLength,
    validationErrors,
    minSelected,
    maxSelected,
  } = useTravelPathBuilderService();

  const { viewport } = useTravelPathBuilderMapBounds({ minAndMaxCoordinatesGeoJSON });

  const handleDoTravelPathBuilderTracing = useCallback(() => {
    const breadCrumbs = getBreadCrumbsForTravelPathTransaction();

    if (breadCrumbs && routeTemplateId) {
      travelPathBuilderDoTracing({
        routeTemplateId,
        vendorId,
        breadCrumbs,
      })(dispatch).then(res => {
        if (!res?.error?.code && initEditConfigs) {
          loadTravelPathForBuildOrEdit(vendorId, initEditConfigs)(dispatch);
        }
      });
    } else {
      createErrorNotification(translate('routes.travelPath.alertMessages.thereAreNoBreadCrumbsSelected'));
    }
  }, [dispatch, getBreadCrumbsForTravelPathTransaction, initEditConfigs, routeTemplateId, vendorId]);

  const handleClickBackButton = useCallback(() => {
    dispatch(resetTravelPathBuildTracer());
    setShowVehicleTrackings(true);
    dispatch(setShowTravelPath(false));
  }, [dispatch]);

  const handleClickSaveButton = useCallback(async () => {
    if (await confirm(translate('routes.travelPath.alertMessages.saveTravelPath'))) {
      publishEditsToTravelPath(
        vendorId,
        true,
        undefined,
        routeTemplateId,
      )(dispatch).then(res => {
        if (!res.error?.code) {
          finishTravelPathBuildOrEdit(
            vendorId,
            true,
            undefined,
            routeTemplateId,
          )(dispatch).then(res => {
            if (!res?.error?.code) {
              createSuccessNotification(translate('routes.travelPath.alertMessages.successSaveTravelPath'));
              closeModal(true);
            }
          });
        }
      });
    }
  }, [closeModal, dispatch, routeTemplateId, vendorId]);

  const hasNoBreadCrumbsInformation = useMemo(() => {
    if (routeVehicleBreadCrumbs?.vehicles && routeVehicleBreadCrumbs?.vehicles.length > 0) {
      if (parsedBreadCrumbsLength < 120) return true;
      else return routeVehicleBreadCrumbs.vehicles.every(vehicle => vehicle.coords.length === 0);
    } else if (routeVehicleBreadCrumbs?.vehicles && routeVehicleBreadCrumbs?.vehicles.length === 0) {
      return true;
    }
  }, [parsedBreadCrumbsLength, routeVehicleBreadCrumbs?.vehicles]);

  const isMapLoading =
    isLoadingMapFeatures ||
    isApplyingEditsToTravelPath ||
    isLoadingTravelPathEdited ||
    isSavingEditsToTravelPath ||
    isLoadingRouteVehiclePositionAndBreadcrumb ||
    isDoingTravelPathBuilderTracing ||
    isDownloadingGeoJsonFile;

  return (
    <MapGLWrapper isLoading={isMapLoading} height="70vh">
      <MapGL
        key="builderMap"
        dragPan
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        onMapRefLoaded={setMapInstance}
        viewport={viewport}
        setIsSatelliteViewEnabled={setIsSatelliteView}
      >
        <ComplexMapControl position="top-left" vertical>
          <TooltipIconButton
            tooltipAsString
            tooltip={translate('dashboard.filterKeys.mapLayers')}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color="secondary"
            margin="no"
            onClick={() => setIsFormOpen(true)}
          >
            <IconButtonIcon icon="layers" color="primary" />
          </TooltipIconButton>
        </ComplexMapControl>
        {mapInstance && (
          <>
            {/* other features that can be displayed on map */}

            <TravelPathGeoFencesGL
              map={mapInstance}
              isSatellite={isSatelliteView}
              showRouteGeoFence={showRouteGeoFence}
            />
            <TravelPathRouteStopsGL
              map={mapInstance}
              routeId={undefined}
              routeTemplateId={routeTemplateId}
              showOrderNumbers={showOrderNumbers}
            />
            <TravelPathRouteSegmentsGL map={mapInstance} isSnowPlow={isSnowPlow || false} />
            <TravelPathHaulerLocationsGL map={mapInstance} />

            {/* the original TravelPath from where choose start & end */}
            <TravelPathGL map={mapInstance} mapBearing={mapBearing} isDisplayOnly />

            {showVehicleTrackings && (
              <>
                <TravelPathVehicleBreadcrumbsGL
                  map={mapInstance}
                  mapBearing={mapBearing}
                  minSelected={minSelected}
                  maxSelected={maxSelected}
                />
                <TravelPathBreadcrumbsFlagsGL map={mapInstance} flagsGeoJSON={minAndMaxCoordinatesGeoJSON} />
              </>
            )}

            <TravelPathVehiclePositionsGL map={mapInstance} />

            {didDoAnyTravelPathBuilderTracing ? (
              <>
                <ComplexMapControl position="bottom-right" yOffset={50}>
                  <Box padding="xSmall" backgroundColor="#fff" borderRadius={5}>
                    <UnconnectedSwitch
                      checked={showVehicleTrackings}
                      size="small"
                      onChange={(value: boolean) => setShowVehicleTrackings(value)}
                      label={translate('routes.travelPath.showVehicleTrackings')}
                    />
                  </Box>
                </ComplexMapControl>
              </>
            ) : (
              <TravelPathOriginalGL mapBearing={mapBearing} />
            )}
          </>
        )}

        <TravelPathBuilderInstructions />

        {!hasSelectedRequiredFilters && (
          <TravelPathWarningMapWrapper>
            <Text block size="xLarge" weight="medium" color="info">
              {translate('routes.travelPath.alertMessages.selectFilters')}
            </Text>
          </TravelPathWarningMapWrapper>
        )}

        {hasNoBreadCrumbsInformation && (
          <TravelPathWarningMapWrapper>
            <Text block size="xLarge" weight="medium" color="info">
              <em> {translate('routes.travelPath.alertMessages.thereAreNoVehicleBreadcrumbs')}</em>
            </Text>
          </TravelPathWarningMapWrapper>
        )}

        <ComplexMapControl position="bottom-right">
          {!didDoAnyTravelPathBuilderTracing ? (
            <Button
              color="primary"
              margin="no"
              disabled={isSubmitButtonDisabled}
              onClick={handleDoTravelPathBuilderTracing}
            >
              {translate('common.submit')}
            </Button>
          ) : (
            <>
              <Button color="grayDarker" margin="no xSmall" onClick={handleClickBackButton}>
                {translate('common.back')}
              </Button>
              <Button color="primary" margin="no" onClick={handleClickSaveButton}>
                {translate('common.save')}
              </Button>
            </>
          )}
        </ComplexMapControl>
      </MapGL>

      {!didDoAnyTravelPathBuilderTracing && (
        <>
          <TravelPathErrorWrapper>
            {map(validationErrors, (value, key) => {
              if (value) {
                return (
                  <Text key={key} color="alert">
                    {translate(`routes.travelPath.alertMessages.${key}`)} <br />
                  </Text>
                );
              }
              return null;
            })}
          </TravelPathErrorWrapper>

          <TravelPathBuilderActionsWrapper>
            <TravelPathBuilderRangeSelection
              onMinChange={setMinSelected}
              onMaxChange={setMaxSelected}
              minSelected={minSelected}
              maxSelected={maxSelected}
            />
          </TravelPathBuilderActionsWrapper>
        </>
      )}

      <TravelPathEditorMapForm
        isAlternativeFleet={isAlternativeFleet}
        isPanelOpen={isFormOpen}
        closePanel={() => setIsFormOpen(false)}
        onSubmit={handleSubmitLayersForm}
        hasTravelPathFilter
        hideDailyRouteGeoFence
      />
    </MapGLWrapper>
  );
};

export default memo(TravelPathBuilderMapGL);
