import { CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION, OPERATIONAL_FACILITY } from 'src/common/constants';
import { http } from '../../core/services/http';
import { MaterialTicket } from '../interfaces/MaterialTickets';

export const createMaterialTicket = (routeId: number, materialTicket: MaterialTicket) =>
  http.post(`materialPickUpTicket?routeId=${routeId}`, { ...materialTicket }).then(response => response.data);

export const updateMaterialTicket = (materialTicket: MaterialTicket) =>
  http.put(`materialPickUpTicket/${materialTicket.id}`, materialTicket).then(response => response.data);

export const loadMaterialTicket = (materialTicketId: number) =>
  http.get(`materialPickUpTicket/${materialTicketId}`).then(response => response.data);

export const loadMaterialTickets = (routeId: number, routeLocationId?: number) =>
  http.get(`routes/${routeId}/materialPickUpTickets`, { params: { routeLocationId } }).then(response => response.data);

export const loadMaterialPickups = (vendorId: number, routeId?: number) =>
  http
    .get(`/${vendorId}/routes/${routeId}/routeDetail/alternativeFleet/materialPickups`)
    .then(response => response.data);

export const deleteMaterialTicket = (materialTicketId: number) =>
  http.delete(`materialPickUpTicket/${materialTicketId}`);

export const loadUnitOfMeasureValues = () =>
  http.get('common/systemTypes/pickUpTicketUnitOfMeasureTypeValues').then(response => response.data);

export const loadMaterialTypeValues = () =>
  http.get('common/systemTypes/pickUpTicketMaterialTypeValues').then(response => response.data);

export const loadMaterialLocations = (vendorId: number) =>
  http
    .get('facilities', {
      params: {
        vendorId,
        facilityTypeId: OPERATIONAL_FACILITY,
        facilitySubTypeIdsCSV: [CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION].toString(),
      },
    })
    .then(response => response.data);

export const uploadMaterialTicketImage = async (file: File, routeId: number) => {
  const formData = new FormData();
  const dataObject = JSON.stringify([{ RouteId: routeId, imageSourceTypeId: 1 }]);

  formData.append('Image', file);
  formData.append('data', dataObject);

  const response = await http.post('images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};
