import React, { useState } from 'react';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { PaymentAttachment } from 'src/routes/interfaces/PaymentAttachment';
import { getPaymentAttachments } from 'src/routes/services/paymentAttachments';
import { PaymentAttachmentsModal } from './PaymentAttachmentsModal';

interface Props {
  closeModal: () => void;
  paymentId: number;
}

const PaymentAttachmentsModalResolver: React.FC<Props> = ({ paymentId, closeModal }) => {
  const [files, setFiles] = useState<PaymentAttachment[]>([]);
  const loadDependencies = () => getPaymentAttachments(paymentId).then(r => setFiles(r));

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={PaymentAttachmentsModal}
      successProps={{ files, onClose: closeModal }}
    />
  );
};

export default PaymentAttachmentsModalResolver;
