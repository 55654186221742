import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import BinSensorForm from '../forms/BinSensorForm';
import { saveBinSensorSettings } from 'src/vendors/ducks/binSensorSettings';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function BigSensorModal({ closeModal }: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const binSensorSettings = useSelector((state: AppState) => state.vendors.binSensorSettings.binSensorSettings);
  const initialApiKey = binSensorSettings.apiKey;

  const handleSubmit = (formData: any) => {
    const { apiKey } = formData;
    const configurationId = binSensorSettings.id;
    const payload = {
      id: configurationId,
      vendorId: vendorId,
      apiKey: apiKey,
    };
    saveBinSensorSettings(
      configurationId,
      payload,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.bigBellySettingSaved'));
        closeModal();
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.bigBellySettingError')));
  };

  return (
    <Modal size="small" title={translate('vendors.bigBelly')} onClose={closeModal}>
      <ModalSection padding="no" isLoading={false}>
        <BinSensorForm onCancel={closeModal} initialValues={{ apiKey: initialApiKey }} onSubmit={handleSubmit} />
      </ModalSection>
    </Modal>
  );
}
