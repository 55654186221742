import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TimeManagementSettings } from '../interfaces/TimeManagement';
import {
  loadTimeManagementSettings as doLoadTimeManagementSettings,
  saveTimeManagementSettings as doSaveTimeManagementSettings,
} from '../services/timeManagement';

// Actions
const START_LOAD = 'vendors/timeManagementSettings/START_LOAD';
const COMPLETE_LOAD = 'vendors/timeManagementSettings/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/timeManagementSettings/FAIL_LOAD';
const START_SAVE = 'vendors/timeManagementSettings/START_SAVE';
const COMPLETE_SAVE = 'vendors/timeManagementSettings/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/timeManagementSettings/FAIL_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  timeManagementSettings: [] as any[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          timeManagementSettings: action.timeManagementSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (timeManagementSettings: TimeManagementSettings[]) => ({
  type: COMPLETE_LOAD,
  timeManagementSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadTimeManagementSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadTimeManagementSettingsPromise = doLoadTimeManagementSettings(vendorId);
  loadTimeManagementSettingsPromise
    .then(timeManagementSettings => dispatch(completeLoad(timeManagementSettings)))
    .catch(() => dispatch(failLoad()));

  return loadTimeManagementSettingsPromise;
};

export const saveTimeManagementSettings =
  (vendorId: number, timeManagementSettings: TimeManagementSettings[]) => (dispatch: Dispatch) => {
    dispatch(startSave());

    const saveTimeManagementSettingsPromise = doSaveTimeManagementSettings(vendorId, timeManagementSettings);
    saveTimeManagementSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveTimeManagementSettingsPromise;
  };
