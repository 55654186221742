import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { Field, FieldArray, change } from 'redux-form';

import { IntlPhoneInput } from '../../core/components';
import { isRequired, isIntlPhone } from '../../utils/services/validator';
import { Message, Grid, GridColumn, ButtonSet, Button, PanelSection } from '../../core/components/styled';
import { RemovePhoneNumber, RemovePhoneNumberIcon } from './styled';
import translate from '../../core/services/translate';

const NotificationPhoneNumbersFieldArray: React.FC<{ fields: any; change: any }> = ({ fields, change }) => (
  <Fragment>
    <PanelSection padding="no">
      <Grid multiLine>
        {fields.map((field: any, index: number) => (
          <Fragment key={index}>
            <GridColumn size="11/12" padding="small no">
              <Field
                name={`${field}.phoneNumber`}
                component={IntlPhoneInput}
                label={translate('common.phone')}
                changeFieldValue={(value: any) => {
                  change('notificationPhoneNumbers', `${field}.phoneNumber`, value);
                }}
                initialValue={fields.get(index).phoneNumber}
                validate={[isRequired, isIntlPhone]}
              />
            </GridColumn>
            <GridColumn size="1/12" padding="small no no medium">
              <RemovePhoneNumber onClick={() => fields.remove(index)}>
                <RemovePhoneNumberIcon />
              </RemovePhoneNumber>
            </GridColumn>
          </Fragment>
        ))}
        {!fields.length && (
          <GridColumn size="12/12" padding="no">
            <Message padding="no">{translate('customers.noNotificationPhoneNumbers')}</Message>
          </GridColumn>
        )}
        <GridColumn size="12/12" padding="no">
          <ButtonSet margin="sMedium no no" align="left">
            <Button type="button" color="primary" line onClick={() => fields.push({})}>
              + {translate('customers.addPhoneNumber')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </PanelSection>
  </Fragment>
);

const NotificationPhoneNumbers: React.FC<{ change: any }> = ({ change }) => (
  <FieldArray name="textNotificationPhones" component={NotificationPhoneNumbersFieldArray} change={change} />
);

const mapDispatchToProps = {
  change,
};

export default connect(undefined, mapDispatchToProps)(NotificationPhoneNumbers);
