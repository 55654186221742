import { filter, flatten, map, size } from 'lodash-es';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import { equipmentTypesSelector } from 'src/common/ducks';
import { EquipmentType } from 'src/common/interfaces/ServiceType';
import { TypedField } from 'src/core/components';
import MultiSelect, { MultiSelectProps } from 'src/core/components/MultiSelect';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';
import { DropDownFieldProps } from './DropDownFieldProps';
import { AppState } from 'src/store';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('common.allEquipments')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('haulerProfile.noEquipmentConfigured')
    : translate('common.xEquipmentsSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludeEquipmentIds?: number[];
  includeNoneOption?: boolean;
  equipmentTypeIds: number[];
  serviceTypeId?: number;
}

const getMatchingEquipmentSizesOptions = (data: any[] = [], idArray: number[] = []): any[] => {
  if (!Array.isArray(data) || data.length === 0 || !Array.isArray(idArray) || idArray.length === 0) {
    return [];
  }
  const matchedEquipmentSizes = filter(data, item => item?.id && idArray.includes(item.id));
  const flattenedEquipmentSizes = flatten(map(matchedEquipmentSizes, item => item?.equipmentSizes || []));
  return map(flattenedEquipmentSizes, equipmentSize => ({
    label: equipmentSize.name,
    value: equipmentSize.id,
  }));
};

const EquipmentSizesMultiSelect: FC<Props> = ({
  input,
  multiSelectProps,
  excludeEquipmentIds,
  label,
  withLabel,
  placeholder,
  withPlaceholder,
  equipmentTypeIds,
  serviceTypeId,
  ...rest
}) => {
  const EquipmentTypesUsingServiceTypeId: EquipmentType[] = useSelector(
    (state: AppState) => (equipmentTypesSelector as any)(state.common.serviceTypes.serviceTypes, serviceTypeId) || [],
  );

  const options = useMemo(() => {
    return getMatchingEquipmentSizesOptions(EquipmentTypesUsingServiceTypeId, equipmentTypeIds);
  }, [EquipmentTypesUsingServiceTypeId, equipmentTypeIds]);

  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: number) => {
      input.onChange(value);
      multiSelectProps?.onChange && multiSelectProps.onChange(value);
    },
    [input, multiSelectProps],
  );

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={handleChange}
      {...rest}
      props={{
        label: label || (withLabel ? translate('common.equipmentSize') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('common.equipmentSize') : undefined),
        options: options,
        canCheckAll: multiSelectProps?.canCheckAll || false,
        isClearable: true,
        normalizeValues: Number,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
};

export default EquipmentSizesMultiSelect;
