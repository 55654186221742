import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { remove } from 'lodash-es';
import { reset as resetForm, submit, FormAction, change, getFormValues } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';

import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import {
  exportSuspendedLocations,
  loadSuspendedLocations,
  resetSuspendedLocations,
  saveSuspended,
  suspendLocation,
  setCurrentCustomersPageUrl,
} from 'src/customers/ducks';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { SuspendedLocationsSearchForm, AddSuspendedLocationForm, SuspendedLocationsForm } from '../forms';
import suspendedLocationsInitialValuesSelector from 'src/customers/services/supendedLocationsInitialValuesSelector';
import translate from 'src/core/services/translate';
import { SortOrder } from 'src/core/components/Table';
import { DuckFunction } from 'src/contracts/ducks';
import { PermissionGuard } from 'src/account/components';
import { CUSTOMERS_SUSPENDED_LOCATIONS_DROPDOWN, CUSTOMERS_SUSPENDED_LOCATIONS_SAVE } from 'src/account/constants';
import { Button, Panel, PanelSectionGroup } from 'src/core/components/styled';
import { LIMIT_PER_PAGE } from 'src/core/constants';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { UpdateTrackerRouteSwitchWrapper } from 'src/core/components';
import { SUSPENDED } from 'src/routes/constants';
import { checkCustomerLocationHasScheduledDailyRoutes } from 'src/routes/services/routeTemplate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { AppState } from 'src/store';
import { Customer, CustomerLocation, SuspendedLocation } from 'src/customers/interfaces/Customers';
import { Service } from 'src/customers/interfaces/Services';

interface LocationState {
  customerTypeId: number;
  customerStatusTypeIds: string;
  page: number;
  sortedBy: string;
  sortOrder: SortOrder;
  accountNumber: string;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {
  change: any;
  customerName?: string;
  exportSuspendedLocations: DuckFunction<typeof exportSuspendedLocations>;
  isLoading: boolean;
  isSaving: boolean;
  loadSuspendedLocations: DuckFunction<typeof loadSuspendedLocations>;
  push: (url: string) => void;
  resetForm: (formName: string) => void;
  saveSuspended: DuckFunction<typeof saveSuspended>;
  setCurrentCustomersPageUrl: (currentCustomersPageUrl: string) => void;
  shouldRecreateRoutes: boolean;
  sortedBy?: string;
  sortOrder?: SortOrder;
  submit: (form: string) => FormAction;
  suspendLocation: DuckFunction<typeof suspendLocation>;
  vendorId: number;
}

interface State {
  hasScheduledDailyRoutes: boolean;
  customerIds: number[];
  locationIds: number[];
  serviceIds: number[];
  newCustomerIds: number[];
  newLocationIds: number[];
  newServiceIds: number[];
}

class SuspendedLocationsPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasScheduledDailyRoutes: false,
      customerIds: [],
      locationIds: [],
      serviceIds: [],
      newCustomerIds: [],
      newLocationIds: [],
      newServiceIds: [],
    };
  }

  onSuspendedLocationsFormSubmit = async ({ suspendedLocations }: { suspendedLocations: SuspendedLocation[] }) => {
    const { location, loadSuspendedLocations, saveSuspended, shouldRecreateRoutes, sortedBy, sortOrder, vendorId } =
      this.props;

    const { searchTerm, accountStatusTypeId } = getQueryParams(location.search);

    await saveSuspended(suspendedLocations, shouldRecreateRoutes)
      .then(() => {
        createSuccessNotification(translate('customers.alertMessages.suspendedLocationChangesSaved'));
        loadSuspendedLocations(vendorId, searchTerm, accountStatusTypeId, sortedBy, sortOrder, 1, LIMIT_PER_PAGE);
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'customers.alertMessages'))}`);
      })
      .finally(() => {
        this.clearTrackerRouteSwitch();
        this.setState({ hasScheduledDailyRoutes: false });
      });
  };

  exportLocations = () => {
    const { vendorId, exportSuspendedLocations, location } = this.props;

    const { searchTerm, accountStatusTypeId } = getQueryParams(location.search);

    exportSuspendedLocations(vendorId, searchTerm, accountStatusTypeId);
  };

  onSave = () => this.props.submit('suspendedLocations');

  onStatusChange = (key: string, customerId?: number, locationId?: number, serviceId?: number, value?: number) => {
    this.props.change('suspendedLocations', key, true);

    const { vendorId } = this.props;
    const { customerIds, locationIds, serviceIds } = this.state;

    if (value !== SUSPENDED) {
      if (serviceId) {
        if (!serviceIds.includes(serviceId)) serviceIds.push(serviceId);
      } else if (locationId) {
        if (!locationIds.includes(locationId)) locationIds.push(locationId);
      } else if (customerId) {
        if (!customerIds.includes(customerId)) customerIds.push(customerId);
      }
    } else {
      remove(customerIds, id => id === customerId);
      remove(locationIds, id => id === locationId);
      remove(serviceIds, id => id === serviceId);
    }

    this.setState({ customerIds, locationIds, serviceIds });

    checkCustomerLocationHasScheduledDailyRoutes(customerIds, locationIds, serviceIds, vendorId).then(response => {
      this.setState({ hasScheduledDailyRoutes: response });
    });
  };

  onCustomerChange = () => {
    const { change } = this.props;
    change('addSuspendedLocation', 'location', undefined);
    change('addSuspendedLocation', 'serviceContract', undefined);
  };

  onSortOrderChange = (sortedBy: string, sortOrder: SortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  addSuspendLocation = ({
    customer,
    location,
    serviceContract,
  }: {
    customer: Customer;
    location: CustomerLocation;
    serviceContract: Service;
  }) => {
    const { suspendLocation, resetForm, vendorId } = this.props;
    const { newCustomerIds, newLocationIds, newServiceIds, customerIds, locationIds, serviceIds } = this.state;

    suspendLocation(customer, location, serviceContract);
    resetForm('addSuspendedLocation');

    if (serviceContract && serviceContract.id) {
      if (!newServiceIds.includes(serviceContract.id)) newServiceIds.push(serviceContract.id);
    } else if (location && location.id) {
      if (!newLocationIds.includes(location.id)) newLocationIds.push(location.id);
    } else if (customer && customer.id) {
      if (!newCustomerIds.includes(customer.id)) newCustomerIds.push(customer.id);
    }

    this.setState({
      newCustomerIds,
      newLocationIds,
      newServiceIds,
      customerIds: [...newCustomerIds, ...customerIds],
      locationIds: [...newLocationIds, ...locationIds],
      serviceIds: [...newServiceIds, ...serviceIds],
    });

    checkCustomerLocationHasScheduledDailyRoutes(newCustomerIds, newLocationIds, newServiceIds, vendorId).then(
      response => {
        this.setState({ hasScheduledDailyRoutes: response });
      },
    );
  };

  clearTrackerRouteSwitch = () => {
    const { change } = this.props;
    return change('updateTrackerRouteSwitchWrapper', 'shouldRecreateRoutes', false);
  };

  render() {
    const { isLoading, isSaving, setCurrentCustomersPageUrl } = this.props;
    const { hasScheduledDailyRoutes } = this.state;

    const currentCustomersPageUrl = window.location.pathname + window.location.search;
    setCurrentCustomersPageUrl(currentCustomersPageUrl);

    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('customers.suspendedLocations')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions align="right">
            <Button id="export-routes-button" color="primary" onClick={this.exportLocations} margin="no xSmall no no">
              {translate('common.export')}
            </Button>
            <PermissionGuard permission={CUSTOMERS_SUSPENDED_LOCATIONS_SAVE}>
              <Button id="customers-suspended-locations-save-button" color="primary" onClick={this.onSave}>
                {translate('common.save')}
              </Button>
            </PermissionGuard>
            <UpdateTrackerRouteSwitchWrapper hasScheduledDailyRoutes={hasScheduledDailyRoutes} />
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isSaving}>
            <PermissionGuard permission={CUSTOMERS_SUSPENDED_LOCATIONS_DROPDOWN}>
              <AddSuspendedLocationForm onSubmit={this.addSuspendLocation} onCustomerChange={this.onCustomerChange} />
            </PermissionGuard>
            <SuspendedLocationsSearchForm />
            <SuspendedLocationsForm
              onSubmit={this.onSuspendedLocationsFormSubmit}
              onStatusChange={this.onStatusChange}
              onSortOrderChange={this.onSortOrderChange}
            />
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('updateTrackerRouteSwitchWrapper')(state) as { shouldRecreateRoutes: boolean };

  return {
    initialValues: suspendedLocationsInitialValuesSelector(state.customers.suspendedLocations),
    isLoading: state.customers.suspendedLocations.isLoading,
    isSaving: state.customers.suspendedLocations.isSaving,
    shouldRecreateRoutes: formValues?.shouldRecreateRoutes || false,
    total: state.customers.suspendedLocations.total,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  };
};

const mapDispatchToProps = {
  change,
  exportSuspendedLocations,
  loadSuspendedLocations,
  push,
  resetForm,
  resetSuspendedLocations,
  saveSuspended,
  setCurrentCustomersPageUrl,
  submit,
  suspendLocation,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuspendedLocationsPage));
