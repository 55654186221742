export const DASHBOARD_CLUSTERS_SOURCE = 'clustersSource';

export const DASHBOARD_CLUSTERS_CLUSTERS_LAYER = 'clustersLayer';

export const DASHBOARD_CLUSTERS_CLUSTER_COUNTERS_LAYER = 'clustersCountersLayer';

export const DASHBOARD_VEHICLE_POSITIONS_LAYER = 'vehiclePositionsLayer';

export const DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_SOURCE = 'modalMapVehiclePositionsSource';

export const DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER = 'modalMapVehiclePositionsLayer';

export const DASHBOARD_MAP_MODAL_APPLICATION_STATUS_SOURCE = 'modalMapApplicationStatusSource';

export const DASHBOARD_APPLICATION_STATUS_LAYER = 'applicationStatusLayer';

export const DASHBOARD_MAP_MODAL_APPLICATION_STATUS_LAYER = 'modalMapApplicationStatusLayer';

export const DASHBOARD_VEHICLE_INSIGHTS_LAYER = 'vehicleInsightsLayer';

export const DASHBOARD_CONTAINER_INSIGHTS_LAYER = 'containerInsightsLayer';

export const DASHBOARD_CITY_INSIGHTS_LAYER = 'cityInsightsLayer';

export const DASHBOARD_HAULER_LOCATIONS_LAYER = 'haulerLocationsLayer';

export const DASHBOARD_GEO_FENCES_SOURCE = 'geoFencesSource';

export const DASHBOARD_ROUTE_STOPS_LAYER = 'routeStopsLayer';

export const DASHBOARD_GEO_FENCE_INCIDENTS_LAYER = 'geoFenceIncidentsLayer';

export const DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_SOURCE = 'modalMapVehicleTrackingsSource';

export const DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_POINT_SOURCE = 'modalMapVehicleTrackingsPointSource';

export const DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_POINTS_LAYER = 'modalMapVehicleTrackingsPointsLayer';

export const DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_POINT_SYMBOL_LAYER = 'modalMapVehicleTrackingsPointSymbolLayer';

export const DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_LAYER = 'modalMapVehicleTrackingsLayer';

export const DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE = 'snowSweeperStreetSegmentsSource';

export const DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE = 'modalMapSnowSweeperStreetSegmentsSource';

export const DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_LAYER = 'modalMapSnowSweeperStreetSegmentsLayer';

export const DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_TWO_WAY_LAYER =
  'modalMapSnowSweeperStreetSegmentsTwoWayLayer';

export const DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_LAYER = 'snowSweeperStreetSegmentsLayer';

export const DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_TWO_WAY_LAYER = 'snowSweeperStreetSegmentsTwoWayLayer';

export const DASHBOARD_CITY_ALERTS_LAYER = 'cityAlertsLayer';
