import styled from 'styled-components';
import { mapper } from '../../../utils/styles';
import { fadeAndSlideInUp } from '../../../core/styles';

interface Props {
  size?: string;
}

export const InsightPieChartPlaceholderTooltip = styled.span`
  display: none;
  position: absolute;
  padding: 6px 10px;
  background-color: ${props => props.theme.brandPrimary};
  border-radius: 6px;
  font-size: 13px;
  color: #fff;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -8px;
    left: calc(50% - 8px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${props => props.theme.brandPrimary};
  }
`;

export const InsightPieChartPlaceholder = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => mapper(props.size, { small: '150px', medium: '350px', large: '550px' })};
  height: ${props => mapper(props.size, { small: '150px', medium: '350px', large: '550px' })};
  background-color: ${props => props.theme.grayLight};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    ${InsightPieChartPlaceholderTooltip} {
      display: block;
      animation: ${fadeAndSlideInUp} 0.3s ease-out;
    }
  }
`;
