import styled from 'styled-components';

export const IntlPhoneInputWrapper = styled.div`
  .PhoneInputInput {
    width: 100% !important;
    padding: 2px 2px 8px 0 !important;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid ${props => props.theme.grayLight} !important;
    border-radius: 0 !important;
    line-height: 22px !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    margin-left: 10px !important;
    margin-top: 1px !important;
  }

  .PhoneInputInput:focus {
    border-bottom-color: ${props => props.theme.brandPrimary} !important;
  }

  .PhoneInputInput:disabled {
    color: ${props => props.theme.grayDark} !important;
  }

  .PhoneInputInput::placeholder {
    text-transform: capitalize !important;
    color: ${props => props.theme.grayDark} !important;
  }

  .PhoneInputCountrySelectArrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.theme.brandPrimary};
    border-bottom: 0;
    margin-left: 5px;
    transform: none;
    background: none;
    opacity: 1;
  }
`;
