import styled, { css } from 'styled-components';

interface Props {
  hide?: boolean;
}

const Hide = styled.div<Props>`
  display: block;

  ${props =>
    props.hide &&
    css`
      display: none;
    `};
`;

export default Hide;
