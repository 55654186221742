import translate from "src/core/services/translate";

export const billingMethods = [
  {
    label: translate('billing.electronic'),
    value: true,
  },
  {
    label: translate('billing.paper'),
    value: false,
  },
];
