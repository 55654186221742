import React, { PureComponent } from 'react';

import { push } from 'connected-react-router';
import { debounce, get, map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { JobPriorityTypesMultiSelect, PickupStatusesMultiSelect } from 'src/common/components';
import { DateRangePicker, MultiSelect, TypeAhead, TypedField } from 'src/core/components';
import { Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { SearchedLocation } from 'src/customers/interfaces/ServiceHistory';
import searchCustomerServicesByLocation from 'src/customers/services/searchCustomerServicesByLocation';
import serviceHistoryFormInitialValuesSelector from 'src/customers/services/serviceHistoryFormInitialValuesSelector';
import { AppState } from 'src/store';
import { createUrl } from 'src/utils/services/queryParams';

const getServicesText = (selectedOptions: any[]) =>
  translate('common.xServicesSelected', { selected: size(selectedOptions) });

interface FormValues {
  locationId: number;
  serviceContractIds: number[];
  pickupStatusTypeIds: number[];
  jobPriorityTypeIds: number[];
  date: Date | string;
}

interface MatchState {
  customerId: string;
}

interface LocationState {
  locationId: string;
  serviceContractIds: string;
  page: string;
}

interface OwnProps extends RouteComponentProps<MatchState, {}, LocationState> {}

interface PropsWithoutReduxForm extends OwnProps {
  push: (url: string) => void;
  searchedLocation?: SearchedLocation;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

class ServiceHistoryForm extends PureComponent<Props> {
  onLocationChange = (event: any, locationId: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { locationId, serviceContractIds: null, page: undefined }));
  };

  onServiceChange = (event: any, serviceContractIds: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, {
        serviceContractIds: serviceContractIds && serviceContractIds.toString(),
        page: undefined,
      }),
    );
  };

  onPickupStatusChange = (event: any, pickupStatusTypeIds: number[]) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, {
        pickupStatusTypeIds: pickupStatusTypeIds && pickupStatusTypeIds.toString(),
        page: undefined,
      }),
    );
  };

  onJobPriorityChange = (event: any, jobPriorityTypeIds: number[]) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, {
        jobPriorityTypeIds: jobPriorityTypeIds && jobPriorityTypeIds.toString(),
        page: undefined,
      }),
    );
  };

  onDateChange = (event: any, { from, to }: any) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { startDate: from, endDate: to, page: undefined }));
  };

  loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    const {
      match: {
        params: { customerId },
      },
    } = this.props;
    searchCustomerServicesByLocation(Number(customerId), searchTerm).then(onOptionsLoaded);
  }, 500);

  render() {
    const {
      match: {
        params: { customerId },
      },
      searchedLocation,
    } = this.props;
    const defaultLocationOptions = searchedLocation
      ? [{ label: searchedLocation.name, value: searchedLocation.id }]
      : undefined;

    const serviceContracts = get(searchedLocation, 'serviceContracts');

    const serviceContractsOptions = map(serviceContracts, ({ id, name }) => ({
      label: name,
      value: id,
    }));

    return (
      <form>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12">
              <TypedField
                name="locationId"
                component={TypeAhead}
                onChange={this.onLocationChange}
                props={{
                  defaultOptions: defaultLocationOptions,
                  getOptions: this.loadCustomerLocations,
                  id: `customers-${customerId}-service-history-form-location-id-field`,
                  isClearable: true,
                  margin: 'no',
                  placeholder: `${translate('common.location')} / ${translate('common.address')}`,
                  defaultValue: searchedLocation && { label: searchedLocation?.name, value: searchedLocation?.id },
                }}
              />
            </GridColumn>
            {size(serviceContracts) !== 0 && (
              <GridColumn size="3/12">
                <TypedField
                  name="serviceContractIds"
                  component={MultiSelect}
                  onChange={this.onServiceChange}
                  props={{
                    formatText: getServicesText,
                    id: `customers-${customerId}-service-history-form-service-contract-ids-field`,
                    normalizeValues: Number,
                    options: serviceContractsOptions,
                    placeholder: translate('common.service'),
                  }}
                />
              </GridColumn>
            )}
            <GridColumn size="2/12">
              <TypedField
                name="pickupStatusTypeIds"
                component={PickupStatusesMultiSelect}
                onChange={this.onPickupStatusChange}
                props={{
                  placeholder: translate('common.allStatuses'),
                  multiSelectProps: { margin: 'no', normalizeValues: Number },
                  id: `customers-${customerId}-service-history-form-pickup-status-type-ids-field`,
                }}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <TypedField
                name="jobPriorityTypeIds"
                component={JobPriorityTypesMultiSelect}
                onChange={this.onJobPriorityChange}
                props={{
                  withPlaceholder: true,
                  includeNoneOption: true,
                  multiSelectProps: { margin: 'no xSmall', isClearable: true, canCheckAll: true },
                }}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="date"
                component={DateRangePicker}
                placeholder={translate('common.date')}
                onChange={this.onDateChange}
                margin="no"
                id={`customers-${customerId}-service-history-form-date-field`}
                props={{ hasMarginLeft: 'smaller', middleLineHasDifferentMargin: true, alignRight: true }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  initialValues: serviceHistoryFormInitialValuesSelector(ownProps.location.search),
  searchedLocation: state.customers.serviceHistory.searchedLocation,
});

const mapDispatchToProps = { push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<FormValues, PropsWithoutReduxForm>({
      form: 'serviceHistory',
      enableReinitialize: true,
    })(ServiceHistoryForm),
  ),
);
