import React from 'react';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import { ModalProps } from 'src/core/components/Modal';
import { useSelector } from 'src/core/hooks/useSelector';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import AddRateModal from './AddRateModal';
import * as Ducks from 'src/customers/ducks/service';
import { useDispatch } from 'react-redux';
import { loadUnitOfMeasureTypes } from 'src/common/ducks';

interface Props extends ModalProps {
  onSubmit(values: any): void;
  rateTypeId: number;
  checkedServiceContractIds: number[];
}
const AddRateModalResolver: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      Ducks.loadRateTypesByServiceType(props.rateTypeId)(dispatch),
      loadUnitOfMeasureTypes()(dispatch),
    ];
    return Promise.all(dependencies);
  }, [vendorId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Resolver
      successComponent={AddRateModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default AddRateModalResolver;
