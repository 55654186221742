import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { CityAlertSettings, CityAlertSettingsToSave } from '../interfaces/CityAlertSettings';
import {
  loadCityAlertSettings as doLoadCityAlertSettings,
  saveCityAlertSetting as doSaveCityAlertSetting,
} from '../services/cityAlertSettings';

// Actions
const START_LOAD_CITY_ALERT_SETTINGS = 'vendors/cityAlertSettings/START_LOAD_CITY_ALERT_SETTINGS';
const COMPLETE_LOAD_CITY_ALERT_SETTINGS = 'vendors/cityAlertSettings/COMPLETE_LOAD_CITY_ALERT_SETTINGS';
const FAIL_LOAD_CITY_ALERT_SETTINGS = 'vendors/cityAlertSettings/FAIL_LOAD_CITY_ALERT_SETTINGS';
const START_SAVE_CITY_ALERT_SETTINGS = 'vendors/cityAlertSettings/START_SAVE_CITY_ALERT_SETTINGS';
const COMPLETE_SAVE_CITY_ALERT_SETTINGS = 'vendors/cityAlertSettings/COMPLETE_SAVE_CITY_ALERT_SETTINGS';
const FAIL_SAVE_CITY_ALERT_SETTINGS = 'vendors/cityAlertSettings/FAIL_SAVE_CITY_ALERT_SETTINGS';

const RESET = 'vendors/cityAlertSettings/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  cityAlertSettings: undefined as CityAlertSettings | undefined,
};

// Reducer

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CITY_ALERT_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_CITY_ALERT_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: false,
          cityAlertSettings: action.cityAlertSettings,
        },
      });

    case FAIL_LOAD_CITY_ALERT_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE_CITY_ALERT_SETTINGS:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE_CITY_ALERT_SETTINGS:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE_CITY_ALERT_SETTINGS:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          cityAlertSettings: undefined,
        },
      });

    default:
      return state;
  }
};

// Action Creators
const startLoadCityAlertSettings = () => ({
  type: START_LOAD_CITY_ALERT_SETTINGS,
});

const completeLoadCityAlertSettings = (cityAlertSettings: CityAlertSettings) => ({
  type: COMPLETE_LOAD_CITY_ALERT_SETTINGS,
  cityAlertSettings,
});

const failLoadCityAlertSettings = () => ({
  type: FAIL_LOAD_CITY_ALERT_SETTINGS,
});

export const resetCityAlertSettings = () => ({
  type: RESET,
});

const startSaveCityAlertSettings = () => ({
  type: START_SAVE_CITY_ALERT_SETTINGS,
});

const completeSaveCityAlertSettings = () => ({
  type: COMPLETE_SAVE_CITY_ALERT_SETTINGS,
});

const failSaveCityAlertSettings = () => ({
  type: FAIL_SAVE_CITY_ALERT_SETTINGS,
});

// Thunks

export const loadCityAlertSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadCityAlertSettings());

  const cityAlertSettingsPromise = doLoadCityAlertSettings(vendorId);

  cityAlertSettingsPromise
    .then((cityAlertSettings: CityAlertSettings) => {
      dispatch(completeLoadCityAlertSettings(cityAlertSettings));
    })
    .catch(() => {
      dispatch(failLoadCityAlertSettings());
    });

  return cityAlertSettingsPromise;
};

export const saveCityAlertSettings =
  (vendorId: number, cityAlertSettings: CityAlertSettingsToSave) => (dispatch: Dispatch) => {
    dispatch(startSaveCityAlertSettings());

    const savePromise = doSaveCityAlertSetting(vendorId, cityAlertSettings);

    savePromise
      .then(() => {
        dispatch(completeSaveCityAlertSettings());
      })
      .catch(() => {
        dispatch(failSaveCityAlertSettings());
      });

    return savePromise;
  };
