import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadDriverPickListDefaults } from '../../ducks';
import { loadPickupTypes } from 'src/routes/ducks/pickupTypes';
import { ModalProps } from 'src/vendors/interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import DriverPickListDefaultsModal from './DriverPickListDefaultsModal';

interface Props extends ModalProps {}

const DriverPickListDefaultsModalResolver: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadDriverPickListDefaults()(dispatch), loadPickupTypes()(dispatch)]);
  }, [dispatch, vendorId]);

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={DriverPickListDefaultsModal}
      successProps={{ closeModal }}
    />
  );
};

export default DriverPickListDefaultsModalResolver;
