import { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { loadPermissions, shouldLoadPermissionsSelector } from 'src/account/ducks';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { SEARCH_BY_ACCOUNT_NUMBER, SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants/searchTypes';
import { SearchType } from 'src/common/interfaces/SearchType';
import { DuckFunction } from 'src/contracts/ducks';
import { Resolver } from 'src/core/components';
import { SortOrder } from 'src/core/components/Table';
import { LIMIT_PER_PAGE } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { loadCustomersSimplified } from 'src/customers/ducks';
import { AppState } from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadFeatures } from 'src/vendors/ducks';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import CustomersPage from './CustomersPage';

interface LocationState {
  searchTerm: string;
  customerTypeId: string;
  customerStatusTypeIds: string;
  page: string;
  sortedBy: string;
  sortOrder: SortOrder;
  accountNumber: string;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {
  loadCustomersSimplified: DuckFunction<typeof loadCustomersSimplified>;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  shouldLoadPermissions: boolean;
  vendorId: number;
}

class CustomersPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadCustomersSimplified, loadFeatures, loadPermissions, location, shouldLoadPermissions, vendorId } =
      this.props;
    const {
      searchTerm,
      customerTypeId,
      customerStatusTypeIds,
      page,
      sortedBy = 'name',
      sortOrder = 'asc',
      accountNumber,
    } = getQueryParams(location.search);
    let searchType: SearchType = searchTerm ? SEARCH_BY_NAME_OR_ADDRESS : SEARCH_BY_ACCOUNT_NUMBER;

    const promises = [
      loadCustomersSimplified({
        vendorId,
        searchTerm: searchTerm || accountNumber,
        customerTypeIds: customerTypeId,
        customerStatusTypeIds: customerStatusTypeIds,
        page,
        limit: LIMIT_PER_PAGE,
        sortedBy,
        sortOrder,
        searchType,
      }),
      loadFeatures(vendorId),
    ];

    if (shouldLoadPermissions) promises.push(loadPermissions());

    return Promise.all(promises);
  };

  render() {
    const { location } = this.props;
    const { showUploadStatus } = getQueryParams(location.search);
    return (
      <Fragment>
        <DocumentTitle>{translate('customers.customers')}</DocumentTitle>
        <Resolver
          successComponent={CustomersPage}
          successProps={{ showUploadStatus }}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/dashboard"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
});

const mapDispatchToProps = { loadCustomersSimplified, loadFeatures, loadPermissions };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersPageResolver));
