import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { get, identity } from 'lodash-es';
import update from 'immutability-helper';

import {
  createDriver,
  loadDriver as doLoadDriver,
  loadDriverFacilites as doLoadDriverFacilites,
  updateDriver,
  uploadDriverProfileImage as doUploadDriverProfileImage,
  checkSupervisorsHaveRoutesInRange as doCheckSupervisorsHaveRoutesInRange,
} from '../services/driver';
import { Driver } from '../interfaces/Driver';
import { Facility } from 'src/common/interfaces/Facility';

// Actions
const START_LOAD = 'fleet/driver/START_LOAD';
export const COMPLETE_LOAD = 'fleet/driver/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/driver/FAIL_LOAD';
const START_SAVE = 'fleet/driver/START_SAVE';
export const COMPLETE_SAVE = 'fleet/driver/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/driver/FAIL_SAVE';
const START_UPLOAD_PROFILE_IMAGE = 'fleet/driver/START_UPLOAD_PROFILE_IMAGE';
const COMPLETE_UPLOAD_PROFILE_IMAGE = 'fleet/driver/COMPLETE_UPLOAD_PROFILE_IMAGE';
const FAIL_UPLOAD_PROFILE_IMAGE = 'fleet/driver/FAIL_UPLOAD_PROFILE_IMAGE';
const START_LOAD_DRIVER_FACILITIES = 'fleet/driver/START_LOAD_DRIVER_FACILITIES';
const COMPLETE_LOAD_DRIVER_FACILITIES = 'fleet/driver/COMPLETE_LOAD_DRIVER_FACILITIES';
const FAIL_LOAD_DRIVER_FACILITIES = 'fleet/driver/FAIL_LOAD_DRIVER_FACILITIES';
const START_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE = 'fleet/drivers/START_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE';
const COMPLETE_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE = 'fleet/drivers/COMPLETE_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE';
const FAIL_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE = 'fleet/drivers/FAIL_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE';
const RESET = 'fleet/driver/RESET';

// Initial state =
const initialState = {
  driver: undefined,
  driverFacilities: [],
  isCheckingSupervisorsHaveRoutesInRange: false,
  isLoading: false,
  isLoadingDriverFacilities: false,
  isSaving: false,
  isUploadingProfileImage: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          driver: action.driver,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_UPLOAD_PROFILE_IMAGE:
      return update(state, {
        $merge: {
          isUploadingProfileImage: true,
        },
      });

    case COMPLETE_UPLOAD_PROFILE_IMAGE:
      return update(state, {
        $merge: {
          isUploadingProfileImage: false,
        },
      });

    case FAIL_UPLOAD_PROFILE_IMAGE:
      return update(state, {
        $merge: {
          isUploadingProfileImage: false,
        },
      });

    case START_LOAD_DRIVER_FACILITIES:
      return update(state, {
        $merge: {
          isLoadingDriverFacilities: true,
        },
      });

    case COMPLETE_LOAD_DRIVER_FACILITIES:
      return update(state, {
        $merge: {
          isLoadingDriverFacilities: false,
          driverFacilities: action.driverFacilities,
        },
      });

    case FAIL_LOAD_DRIVER_FACILITIES:
      return update(state, {
        $merge: {
          isLoadingDriverFacilities: false,
          driverFacilities: undefined,
        },
      });

    case START_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE:
      return update(state, {
        $merge: {
          isCheckingSupervisorsHaveRoutesInRange: true,
        },
      });

    case COMPLETE_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE: {
      return update(state, {
        $merge: {
          isCheckingSupervisorsHaveRoutesInRange: false,
        },
      });
    }

    case FAIL_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE:
      return update(state, {
        $merge: {
          isCheckingSupervisorsHaveRoutesInRange: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (driver: Driver) => ({
  type: COMPLETE_LOAD,
  driver,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (saveType: string) => ({
  type: COMPLETE_SAVE,
  saveType,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startUploadProfileImage = () => ({
  type: START_UPLOAD_PROFILE_IMAGE,
});

const completeUploadProfileImage = () => ({
  type: COMPLETE_UPLOAD_PROFILE_IMAGE,
});

const failUploadProfileImage = () => ({
  type: FAIL_UPLOAD_PROFILE_IMAGE,
});

const startLoadDriverFacilites = () => ({
  type: START_LOAD_DRIVER_FACILITIES,
});

const completeLoadDriverFacilites = (driverFacilities: Facility[]) => ({
  type: COMPLETE_LOAD_DRIVER_FACILITIES,
  driverFacilities,
});

const failLoadDriverFacilites = () => ({
  type: FAIL_LOAD_DRIVER_FACILITIES,
});

const startCheckSupervisorsHaveRoutesInRange = () => ({
  type: START_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE,
});

const completeCheckSupervisorsHaveRoutesInRange = () => ({
  type: COMPLETE_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE,
});

const failCheckSupervisorsHaveRoutesInRange = () => ({
  type: FAIL_CHECK_SUPERVISORS_HAVE_ROUTES_IN_RANGE,
});

export const loadDriver = (driverId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDriverPromise = doLoadDriver(driverId);
  loadDriverPromise.then(driver => dispatch(completeLoad(driver))).catch(() => dispatch(failLoad()));
  return loadDriverPromise;
};

export const uploadProfileImage = (file: File) => (dispatch: Dispatch) => {
  dispatch(startUploadProfileImage());
  const uploadDriverProfileImagePromise = doUploadDriverProfileImage(file);
  uploadDriverProfileImagePromise
    .then(profileImageId => {
      dispatch(completeUploadProfileImage());
      return profileImageId;
    })
    .catch(() => dispatch(failUploadProfileImage()));
  return uploadDriverProfileImagePromise;
};

export const saveDriver = (driver: Driver) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveDriverPromise = driver.id ? updateDriver(driver) : createDriver(driver);
  const saveType = driver.id ? 'Update' : 'Create';
  saveDriverPromise.then(() => dispatch(completeSave(saveType))).catch(() => dispatch(failSave()));
  return saveDriverPromise;
};

export const loadDriverFacilites = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadDriverFacilites());
  const loadDriverFacilitesPromise = doLoadDriverFacilites(vendorId);
  loadDriverFacilitesPromise
    .then((driverFacilities: Facility[]) => dispatch(completeLoadDriverFacilites(driverFacilities)))
    .catch(() => dispatch(failLoadDriverFacilites()));
  return loadDriverFacilitesPromise;
};

export const checkSupervisorsHaveRoutesInRange =
  (vendorId: number, supervisorIds: number[], startDate?: Date | string, endDate?: Date | string) =>
  (dispatch: Dispatch) => {
    dispatch(startCheckSupervisorsHaveRoutesInRange());
    const checkSupervisorsHaveRoutesInRangePromise = doCheckSupervisorsHaveRoutesInRange(
      vendorId,
      supervisorIds,
      startDate,
      endDate,
    );
    checkSupervisorsHaveRoutesInRangePromise
      .then(() => dispatch(completeCheckSupervisorsHaveRoutesInRange()))
      .catch(() => dispatch(failCheckSupervisorsHaveRoutesInRange()));
    return checkSupervisorsHaveRoutesInRangePromise;
  };

export const resetDriver = () => ({
  type: RESET,
});

// Selectors
const getProfileImageUrl = (driverState: any) => get(driverState, 'driver.profileImageUrl', undefined);
export const profileImageUrlSelector = createSelector(getProfileImageUrl, identity);
