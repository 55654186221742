import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadBusinessType as doLoadBusinessType,
  saveBusinessType as doSaveBusinessType,
} from '../services/businessType';

// Actions
const START_LOAD = 'haulerProfile/businessType/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/businessType/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/businessType/FAIL_LOAD';
const START_SAVE = 'haulerProfile/businessType/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/businessType/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/businessType/FAIL_SAVE';
const RESET = 'haulerProfile/businessType/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  businessType: undefined,
  isValidGusId: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          businessType: action.businessType,
          isValidGusId: true,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (businessType: any) => ({
  type: COMPLETE_LOAD,
  businessType,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadBusinessType = (haulerId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadBusinessTypePromise = doLoadBusinessType(haulerId);

  loadBusinessTypePromise.then(businessType => dispatch(completeLoad(businessType))).catch(() => dispatch(failLoad()));

  return loadBusinessTypePromise;
};

export const saveBusinessType = (haulerId: string, data: any) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const saveBusinessTypePromise = doSaveBusinessType(haulerId, data);

  saveBusinessTypePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveBusinessTypePromise;
};

export const resetBusinessType = () => ({
  type: RESET,
});
