import { http } from '../../core/services/http';
import transformLoadRouteImports from './transformLoadRouteImports';

const loadRouteImports = (vendorId: number, fileProcessingStatusTypeId: number, startDate: string, endDate: string) =>
  http
    .get('vendorRouteImport', {
      params: {
        vendorId,
        startDate,
        endDate,
        fileProcessingStatusTypeId,
      },
    })
    .then(response => transformLoadRouteImports(response.data));

export default loadRouteImports;
