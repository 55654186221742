import { http } from '../../core/services/http';
import { RouteStop, RouteStopsPayLoad } from '../interfaces/RouteStop';

export const loadRouteStops = (
  vendorId: number,
  routeId: number,
  routeStopsStatusIds?: number[],
  includeLocationAlerts: boolean = true,
) =>
  http
    .get<RouteStop[]>(`${vendorId}/routes/${routeId}/routeDetail/stops`, {
      params: routeStopsStatusIds
        ? { routeStopsStatusIds: String(routeStopsStatusIds), includeLocationAlerts }
        : { includeLocationAlerts },
    })
    .then(response => response.data);

export const loadRouteStopsForSequence = (vendorId: number, routeId: number) =>
  http.get<RouteStop[]>(`${vendorId}/routes/${routeId}/routeDetail/stopsForSequence`).then(response => response.data);

export const updateRouteStops = (
  vendorId: number,
  routeId: number,
  updatedRouteStops: RouteStopsPayLoad,
  shouldCreateTravelPath?: boolean,
  isOrderNumberChangedByUser?: boolean,
) =>
  http.put(`${vendorId}/routes/${routeId}/routeDetail/stops`, updatedRouteStops, {
    params: { shouldCreateTravelPath, isOrderNumberChangedByUser },
  });

export const loadRouteLocationDetails = (vendorId: number, routeId: number, routeLocationId: number) =>
  http
    .get(`${vendorId}/routes/${routeId}/routeDetail/stopMapDetails?routeLocationId=${routeLocationId}`)
    .then(response => response.data);

export const loadWasteAuditNotes = (vendorId: number, routeId: number) =>
  http.get(`${vendorId}/routes/${routeId}/routeDetail/wasteAuditNotes`).then(response => response.data);

export const loadUnscheduledStops = (vendorId: number, routeId: number) =>
  http.get(`${vendorId}/routes/${routeId}/routeDetail/unscheduledStops`).then(response => response.data);
