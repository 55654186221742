import React, { PureComponent } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { Grid, GridColumn, ButtonSet, Button } from '../../../core/components/styled';
import { Input, FileInput } from '../../../core/components';
import { VendorDropdown } from '../../../vendors/components';
import { isRequired, isFile } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

class CreateRouteImportForm extends PureComponent<InjectedFormProps> {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Grid centered>
        <GridColumn size="4/12">
          <form onSubmit={handleSubmit} noValidate>
            <Field name="vendorId" validate={[isRequired]} component={VendorDropdown} withLabel />

            <Field
              name="description"
              component={Input}
              label={translate('common.description')}
              type="text"
              validate={[isRequired]}
            />

            <Field name="file" component={FileInput} type="text" accept=".xls,.xlsx,.csv" validate={[isFile]} />

            <ButtonSet margin="large no no">
              <Button type="submit" color="primary">
                {translate('common.import')}
              </Button>
            </ButtonSet>
          </form>
        </GridColumn>
      </Grid>
    );
  }
}

export default reduxForm({
  form: 'createRouteImport',
  onSubmitFail: focusFirstInvalidField,
})(CreateRouteImportForm);
