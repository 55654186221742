import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { Material, MaterialsState } from '../interfaces/Materials';
import {
  loadMaterials as doLoadMaterials,
  saveMaterial as doSaveMaterial,
  updateMaterial as doUpdateMaterial,
} from '../services/materials';

// Actions
const START_LOAD = 'haulerProfile/materials/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/materials/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/materials/FAIL_LOAD';
const START_SAVE = 'haulerProfile/materials/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/materials/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/materials/FAIL_SAVE';
const RESET = 'haulerProfile/materials/RESET';

// Initial state
const initialState: MaterialsState = {
  isLoading: false,
  isSaving: false,
  materials: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          materials: action.materials,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (materials: Material[]) => ({
  type: COMPLETE_LOAD,
  materials,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadMaterials = (haulerId: string, divisionId?: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadMaterialsPromise = doLoadMaterials(haulerId, divisionId);
  loadMaterialsPromise.then(materials => dispatch(completeLoad(materials))).catch(() => dispatch(failLoad()));
  return loadMaterialsPromise;
};

export const saveMaterial = (haulerId: string, material: Material) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveMaterialPromise = material.id ? doUpdateMaterial(haulerId, material) : doSaveMaterial(haulerId, material);
  saveMaterialPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveMaterialPromise;
};

export const resetMaterials = () => ({
  type: RESET,
});
