import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Rates, SearchServiceRatesContracts, ServiceRateContractsResponse } from '../interfaces/RateBuilder';
import {
  saveServiceRatesContracts as doSaveServiceRatesContracts,
  loadServiceRatesContracts as doLoadServiceRatesContracts,
} from '../services/rateBuilder';
import { RateBuilderRate } from '../interfaces/Services';

// Actions
const START_LOAD = 'customers/rateBuilder/START_LOAD';
const COMPLETE_LOAD = 'customers/rateBuilder/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/rateBuilder/FAIL_LOAD';
const RESET = 'customers/rateBuilder/RESET';

const START_SAVE = 'customers/rateBuilder/START_SAVE';
const COMPLETE_SAVE = 'customers/rateBuilder/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/rateBuilder/FAIL_SAVE';

// Actions for rate entries
const ADD_RATE_ENTRY = 'customers/rateBuilder/ADD_RATE_ENTRY';
const REMOVE_RATE_ENTRY = 'customers/rateBuilder/REMOVE_RATE_ENTRY';
const UPDATE_RATE_ENTRY = 'customers/rateBuilder/UPDATE_RATE_ENTRY';
const RESET_RATE_ENTRIES = 'customers/rateBuilder/RESET_RATE_ENTRIES'; // New action for resetting rate entries

interface State {
  isLoading: boolean;
  serviceContracts: ServiceRateContractsResponse;
  rateEntries: RateBuilderRate[];
}

// Initial state
const initialState: State = {
  isLoading: false,
  serviceContracts: {} as ServiceRateContractsResponse,
  rateEntries: [], // Start with an empty array
};

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: { isLoading: false, serviceContracts: action.serviceContracts },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false, serviceContracts: {} as ServiceRateContractsResponse },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isLoading: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isLoading: false },
      });

    case ADD_RATE_ENTRY:
      return update(state, {
        rateEntries: {
          $push: [action.rateEntry],
        },
      });

    case REMOVE_RATE_ENTRY:
      return update(state, {
        rateEntries: {
          $apply: (entries: RateBuilderRate[]) => entries.filter(entry => entry.id !== action.id),
        },
      });

    case UPDATE_RATE_ENTRY:
      return update(state, {
        rateEntries: {
          $apply: (entries: RateBuilderRate[]) =>
            entries.map(entry => (entry.id === action.id ? { ...entry, ...action.payload } : entry)),
        },
      });

    case RESET_RATE_ENTRIES:
      return update(state, {
        rateEntries: { $set: [] }, // Reset rateEntries to an empty array
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
export const addRateEntry = (rateEntry: Rates) => ({
  type: ADD_RATE_ENTRY,
  rateEntry,
});

export const removeRateEntry = (id: number) => ({
  type: REMOVE_RATE_ENTRY,
  id,
});

export const updateRateEntry = (id: number, payload: Partial<Rates>) => ({
  type: UPDATE_RATE_ENTRY,
  id,
  payload,
});

export const resetRateEntries = () => ({
  type: RESET_RATE_ENTRIES, // Action for resetting rateEntries
});

export const resetRateBuilder = () => ({
  type: RESET,
});

export const loadServiceRatesContracts = (params: SearchServiceRatesContracts) => (dispatch: Dispatch) => {
  dispatch({ type: START_LOAD });

  return doLoadServiceRatesContracts(params)
    .then(serviceContracts => dispatch({ type: COMPLETE_LOAD, serviceContracts }))
    .catch(() => dispatch({ type: FAIL_LOAD }));
};

export const saveServiceRatesContracts =
  (vendorId: number, serviceContractIds: number[], rates: RateBuilderRate[]) => (dispatch: Dispatch) => {
    dispatch({ type: START_SAVE });

    return doSaveServiceRatesContracts(vendorId, serviceContractIds, rates)
      .then(() => {
        dispatch({ type: COMPLETE_SAVE });
      })
      .catch(() => {
        dispatch({ type: FAIL_SAVE });
      });
  };
