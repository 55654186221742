import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CITY_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { isCityAlertFeatureEnabled } from 'src/vendors/ducks/features';
import { loadCityAlertImages } from 'src/vendors/ducks';
import { loadLocationAndCityAlert } from 'src/fleet/ducks';
import { LocationAndCityAlertsForMap } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { RouteMapFeature, clearRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import CityAlertsGLPopup from './CityAlertsGLPopup';

interface Props {
  isSourceLocationAndCityAlerts?: boolean;
}

const CityAlertsGLPopupResolver: FC<Props> = ({ isSourceLocationAndCityAlerts }) => {
  const dispatch = useDispatch();

  const { cityAlerts } = useSelector(state => state.vendors.cityAlerts);
  const { locationAndCityAlert: alert, locationAndCityAlertsForMap } = useSelector(
    state => state.fleet.locationAndCityAlerts,
  );
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const isCityAlertEnabled = useSelector(isCityAlertFeatureEnabled);

  const locationAndCityAlert = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.cityAlert) {
      return undefined;
    }
    const currentAlert = isSourceLocationAndCityAlerts
      ? locationAndCityAlertsForMap.find((alert: LocationAndCityAlertsForMap) => alert.id === selectedFeature?.id)
      : cityAlerts.find((alert: CityAlert) => alert.id === selectedFeature?.id);

    return {
      id: currentAlert?.id,
      alertTypeId: currentAlert?.alertTypeId,
      latitude: currentAlert?.latitude,
      longitude: currentAlert?.longitude,
    };
  }, [selectedFeature, locationAndCityAlertsForMap, cityAlerts, isSourceLocationAndCityAlerts]);

  const loadDependencies = useCallback(() => {
    const dependencies = [];

    if (selectedFeature?.feature === RouteMapFeature.cityAlert && locationAndCityAlert?.id) {
      const noLoadingIndicator = true;
      dependencies.push(
        loadLocationAndCityAlert(
          locationAndCityAlert.id,
          locationAndCityAlert.alertTypeId || CITY_ALERT_TYPE_ID,
        )(dispatch),
      );

      isCityAlertEnabled &&
        locationAndCityAlert.alertTypeId === CITY_ALERT_TYPE_ID &&
        dependencies.push(loadCityAlertImages(locationAndCityAlert.id, noLoadingIndicator)(dispatch));
    } else return Promise.reject();

    return Promise.all(dependencies);
  }, [locationAndCityAlert, dispatch, selectedFeature?.feature, isCityAlertEnabled]);

  if (!locationAndCityAlert || !selectedFeature) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={locationAndCityAlert.latitude || 0}
      longitude={locationAndCityAlert.longitude || 0}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
      tipSize={15}
      anchor="bottom-right"
    >
      <TypedResolver
        resolve={loadDependencies}
        successComponent={CityAlertsGLPopup}
        successProps={{ locationAndCityAlert: alert }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 320, width: 250 }}
        onError={() => dispatch(clearRouteMapSelectedFeature())}
      />
    </MapGLPopupWrapper>
  );
};

export default CityAlertsGLPopupResolver;
