import { isEqual } from 'lodash-es';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { push } from 'connected-react-router';

import { isRoutePlannerNewLayoutActive } from 'src/routes/services/routePlannerPersistentBetaFlag';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { Icon } from '../../../../core/components';
import { Panel } from '../../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import translate from '../../../../core/services/translate';
import { AppState } from '../../../../store';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { FRESH_FORM_KEY } from '../../../constants/holidayPlanner';
import * as Ducks from '../../../ducks/holidayPlanner';
import { Holiday, HolidayPlannerSettings } from '../../../interfaces/HolidayPlanner';
import HolidayPlannerSettingsForm from '../../forms/HolidayPlannerSettingsForm';
import HolidayPlannerTableRow from '../../forms/HolidayPlannerTableRow';
import { HolidayPlannerAddButton } from '../../styled/HolidayPlanner';

const HolidayPlannerPage: React.FC = () => {
  const dispatch = useDispatch();

  const vendorId = useSelector((state: AppState) => currentVendorId(state));
  const holidays = useSelector((state: AppState) => state.routes.holidayPlanner.holidays);
  const holidaysLoading = useSelector((state: AppState) => state.routes.holidayPlanner.holidaysLoading);
  const settings = useSelector((state: AppState) => state.routes.holidayPlanner.settings);
  const settingsSaving = useSelector((state: AppState) => state.routes.holidayPlanner.settingsSaving);
  const freshHolidayValues: Holiday | undefined = useSelector(
    (state: AppState) => getFormValues(`holidayPlannerFor${holidays.length - 1}`)(state) as Holiday | undefined,
  );

  const freshHoliday = React.useMemo(() => holidays.find(holiday => holiday.isFresh), [holidays]);
  const freshHolidayExists = !!freshHoliday;

  const addHoliday = React.useCallback(() => {
    dispatch(Ducks.addFreshHoliday());
  }, [dispatch]);

  const saveSettings = React.useCallback(
    (updatedSettings: HolidayPlannerSettings) => {
      if (!vendorId || isEqual(settings, updatedSettings)) {
        return;
      }

      let freshHolidayCache: Holiday | undefined = undefined;

      if (freshHoliday && freshHolidayValues && freshHolidayValues.isFresh) {
        freshHolidayCache = {
          ...freshHoliday,
          ...freshHolidayValues,
        };
      }

      Ducks.saveSettings(
        vendorId,
        updatedSettings,
        freshHolidayCache,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.holidayPlanner.settingsSaved'));
        })
        .catch(() => {
          createErrorNotification(translate('routes.holidayPlanner.settingsNotSaved'));
        });
    },
    [dispatch, vendorId, settings, freshHoliday, freshHolidayValues],
  );

  const handleGoBack = React.useCallback(() => {
    const isRoutePlannerNewLayout = isRoutePlannerNewLayoutActive();
    dispatch(
      push(isRoutePlannerNewLayout ? '/routes/route-planner' : '/routes/route-templates', {
        prevPath: '/routes/holiday-planner',
      }),
    );
  }, [dispatch]);

  return (
    <PageContent isLoading={holidaysLoading || settingsSaving}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={handleGoBack} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate('routes.holidayPlanner.holidayPlanner')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>

      <Panel noBackground noBoxShadow>
        {holidays.map((holiday, index) => (
          <HolidayPlannerTableRow
            key={`${holiday.id}-${holiday.isDefault}` || FRESH_FORM_KEY}
            holiday={holiday}
            holidays={holidays}
            index={index}
            settingsSaving={settingsSaving}
          />
        ))}

        {!freshHolidayExists && (
          <HolidayPlannerAddButton onClick={addHoliday}>
            <Icon icon="add" width="16px" height="16px" />

            {translate('routes.holidayPlanner.addNewHoliday')}
          </HolidayPlannerAddButton>
        )}

        <HolidayPlannerSettingsForm initialValues={settings} onChange={saveSettings} />
      </Panel>
    </PageContent>
  );
};

export default HolidayPlannerPage;
