import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadContainerLocationsForMap as doLoadContainerLocationsForMap } from '../services/containersForMap';
import { ContainerLocationForMap } from '../interfaces/containers';

// Actions
const START_LOAD = 'fleet/containers/containerLocationsForMap/START_LOAD';
const COMPLETE_LOAD = 'fleet/containers/containerLocationsForMap/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/containerLocationsForMap/FAIL_LOAD';
const RESET = 'fleet/containers/containerLocationsForMap/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containerLocationsForMap: ContainerLocationForMap[];
} = {
  isLoading: false,
  containerLocationsForMap: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerLocationsForMap: action.containerLocationsForMap,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerLocationsForMap: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerLocationsForMap: ContainerLocationForMap[]) => ({
  type: COMPLETE_LOAD,
  containerLocationsForMap,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainerLocationsForMap =
  (
    vendorId: number,
    containerTypeId?: number,
    equipmentTypeId?: number,
    equipmentSizeId?: number,
    equipmentConditionId?: number,
    searchTerm?: string,
    locationId?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadContainerLocationsForMapPromise = doLoadContainerLocationsForMap(
      vendorId,
      containerTypeId,
      equipmentTypeId,
      equipmentSizeId,
      equipmentConditionId,
      searchTerm,
      locationId,
    );
    loadContainerLocationsForMapPromise
      .then((containerLocationsForMap: ContainerLocationForMap[]) => dispatch(completeLoad(containerLocationsForMap)))
      .catch(() => dispatch(failLoad()));
    return loadContainerLocationsForMapPromise;
  };

export const resetContainerLocationsForMap = () => ({
  type: RESET,
});
