import { FC } from 'react';

import { useSelector } from 'src/core/hooks/useSelector';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { StreetNetworkMapInformationWrapper } from '../../styled/StreetNetwork';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { camelCase } from 'lodash-es';
import { dateTimeFormat } from 'src/utils/services/validator';
import moment from 'moment';

interface Props {
  message?: string;
}

const StreetNetworkMapInformation: FC<Props> = ({ message }) => {
  const serviceAreas = useSelector(state => state.customers.streetNetworkServiceAreas.serviceAreas);
  const { streetNetwork, streetNetworkWithBbox } = useSelector(state => state.customers.streetNetworkDataLayer);

  const noStreetNetwork = !serviceAreas?.length;
  const errorGettingData =
    !!message &&
    !(!!streetNetwork?.length || !!streetNetworkWithBbox) &&
    !!serviceAreas?.length &&
    !serviceAreas[0]?.isLocked;
  const gettingDataInProgress = !!message && !!serviceAreas?.length && serviceAreas[0]?.isLocked;
  const successGettingData =
    (!!streetNetwork?.length || !!streetNetworkWithBbox) && !!serviceAreas?.length && !serviceAreas[0]?.isLocked;
  return (
    <ComplexMapControl position="top-left">
      <StreetNetworkMapInformationWrapper>
        {noStreetNetwork && (
          <Text color="alert" size="small" padding="xxSmall xSmall" singleLine>
            {translate('customers.streetNetwork.streetNetworkNotConfigured')}
          </Text>
        )}
        {errorGettingData && (
          <Text color="alert" size="small" padding="xxSmall xSmall" singleLine>
            {translate(`customers.streetNetwork.errorMessages.${camelCase(message)}`)}
          </Text>
        )}
        {gettingDataInProgress && (
          <Text color="warning" size="small" padding="xxSmall xSmall" singleLine>
            {translate(`customers.streetNetwork.errorMessages.streetNetworkRetrievalInProgress`)}
          </Text>
        )}
        {successGettingData && (
          <Text color="primary" size="small" padding="xxSmall xSmall" singleLine>
            {translate('customers.streetNetwork.lastUpdate') + ': '}
            {moment(streetNetwork[0]?.lastUpdate).format(dateTimeFormat)}
          </Text>
        )}
      </StreetNetworkMapInformationWrapper>
    </ComplexMapControl>
  );
};

export default StreetNetworkMapInformation;
