import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { loadCompanyInfo as doLoadCompanyInfo, saveCompanyInfo as doSaveCompanyInfo } from '../services/companyInfo';

// Actions
const START_LOAD = 'haulerProfile/companyInfo/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/companyInfo/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/companyInfo/FAIL_LOAD';
const START_SAVE = 'haulerProfile/companyInfo/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/companyInfo/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/companyInfo/FAIL_SAVE';
const RESET = 'haulerProfile/companyInfo/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  companyInfo: undefined,
  isValidGusId: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          companyInfo: action.companyInfo,
          isValidGusId: true,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          ...initialState,
          isValidGusId: state.isValidGusId,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (companyInfo: any) => ({
  type: COMPLETE_LOAD,
  companyInfo,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadCompanyInfo = (haulerId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadCompanyInfoPromise = doLoadCompanyInfo(haulerId);

  loadCompanyInfoPromise.then(companyInfo => dispatch(completeLoad(companyInfo))).catch(() => dispatch(failLoad()));

  return loadCompanyInfoPromise;
};

export const saveCompanyInfo = (haulerId: string, data: any) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const saveCompanyInfoPromise = doSaveCompanyInfo(haulerId, data);

  saveCompanyInfoPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveCompanyInfoPromise;
};

export const resetCompanyInfo = () => ({
  type: RESET,
});

const getCompanyInfoContactTypes = (companyInfo: any) => get(companyInfo, 'functionTypes', []);

export const contactTypesSelector = createSelector(getCompanyInfoContactTypes, identity);
