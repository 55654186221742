import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { Button, ModalSection } from '../../../core/components/styled';
import PreventativeMaintenanceForm from '../forms/PreventativeMaintenanceForm';
import { AppState } from '../../../store';
import { ShadowedButtonSet } from '../../../core/components/styled/ButtonSet';
import { PM_CONFIGURATION_FORM } from '../../constants/preventativeMaintenance';
import { PreventativeMaintenanceConfiguration } from '../../interfaces/PreventativeMaintenance';

export interface PreventativeMaintenanceModalProps {
  onlyAvailableConfiguration?: keyof PreventativeMaintenanceConfiguration;
  onClose(pristine?: boolean): void;
  onSubmit(configuration: PreventativeMaintenanceConfiguration): void;
}

const PreventativeMaintenanceModal: React.FC<PreventativeMaintenanceModalProps> = ({
  onlyAvailableConfiguration,
  onClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const configuration = useSelector((state: AppState) => state.fleet.preventativeMaintenance.configuration);
  const configurationSaving = useSelector((state: AppState) => state.fleet.preventativeMaintenance.configurationSaving);

  const closeModal = (pristine = false) => {
    onClose(pristine);
  };

  const submitForm = () => {
    dispatch(submit(PM_CONFIGURATION_FORM));
  };

  return (
    <Modal
      hasBorderRadius
      size="medium"
      padding="medium no no"
      title={translate('vehicles.preventativeMaintenance')}
      onClose={closeModal}
    >
      <ModalSection padding="medium" isLoading={configurationSaving}>
        <PreventativeMaintenanceForm
          onlyAvailableConfiguration={onlyAvailableConfiguration}
          initialValues={configuration}
          onSubmit={onSubmit}
        />
      </ModalSection>

      <ShadowedButtonSet>
        <Button color="primary" onClick={submitForm}>
          {translate('common.save')}
        </Button>
      </ShadowedButtonSet>
    </Modal>
  );
};

export default PreventativeMaintenanceModal;
