import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const FRONT_LOAD = 'FrontLoad';
export const ROLL_OFF = 'RollOff';
export const TOTER = 'Toter';
export const RESIDENTIAL = 'Residential';
export const WASTE_AUDIT = 'WasteAudit';
export const STREET_SWEEPER = 'StreetSweeper';
export const SNOW_PLOW = 'SnowPlow';
export const LIGHT_DUTY_VEHICLE = 'LightdutyVehicle';
export const PICKUP_TRUCK = 'PickupTruck';
export const SUPERVISOR = 'Supervisor';
export const SWEET_OFFICER = 'SweetOfficer';
export const DELIVERY_UTILITY = 'Delivery';
export const DUMP_TRUCK = 'DumpTruck';
export const CAR = 'Car';
export const TRACTOR = 'Tractor';

export const FRONT_LOAD_ID = 1;
export const ROLL_OFF_ID = 2;
export const TOTER_ID = 3;
export const RESIDENTIAL_ID = 4;
export const WASTE_AUDIT_ID = 5;
export const STREET_SWEEPER_ID = 6;
export const SNOW_PLOW_ID = 7;
export const LIGHT_DUTY_VEHICLE_ID = 8;
export const PICKUP_TRUCK_ID = 9;
export const DELIVERY_UTILITY_ID = 10;
export const SUPERVISOR_ID = 11;
export const SWEET_OFFICER_ID = 12;
export const DUMP_TRUCK_ID = 13;
export const CAR_ID = 14;
export const TRACTOR_ID = 15;

const vehicleTypes = [
  { technicalName: FRONT_LOAD, name: translate('vehicles.vehicleTypes.frontLoad'), id: FRONT_LOAD_ID },
  { technicalName: ROLL_OFF, name: translate('vehicles.vehicleTypes.rollOff'), id: ROLL_OFF_ID },
  { technicalName: TOTER, name: translate('vehicles.vehicleTypes.cart'), id: TOTER_ID },
  { technicalName: RESIDENTIAL, name: translate('vehicles.vehicleTypes.residential'), id: RESIDENTIAL_ID },
  { technicalName: WASTE_AUDIT, name: translate('vehicles.vehicleTypes.wasteAudit'), id: WASTE_AUDIT_ID },
  { technicalName: STREET_SWEEPER, name: translate('vehicles.vehicleTypes.streetSweeper'), id: STREET_SWEEPER_ID },
  { technicalName: SNOW_PLOW, name: translate('vehicles.vehicleTypes.snowPlow'), id: SNOW_PLOW_ID },
  {
    technicalName: LIGHT_DUTY_VEHICLE,
    name: translate('vehicles.vehicleTypes.lightdutyVehicle'),
    id: LIGHT_DUTY_VEHICLE_ID,
  },
  { technicalName: PICKUP_TRUCK, name: translate('vehicles.vehicleTypes.pickupTruck'), id: PICKUP_TRUCK_ID },
  { technicalName: SUPERVISOR, name: translate('vehicles.vehicleTypes.supervisor'), id: SUPERVISOR_ID },
  { technicalName: SWEET_OFFICER, name: translate('vehicles.vehicleTypes.sweetOfficer'), id: SWEET_OFFICER_ID },
  {
    technicalName: DELIVERY_UTILITY,
    name: translate('vehicles.vehicleTypes.deliveryUtility'),
    id: DELIVERY_UTILITY_ID,
  },
  { technicalName: DUMP_TRUCK, name: translate('vehicles.vehicleTypes.dumpTruck'), id: DUMP_TRUCK_ID },
  { technicalName: CAR, name: translate('vehicles.vehicleTypes.car'), id: CAR_ID },
  { technicalName: TRACTOR, name: translate('vehicles.vehicleTypes.tractor'), id: TRACTOR_ID },
];

export const VEHICLE_TYPES = mapKeys(vehicleTypes, 'technicalName');
export const VEHICLE_TYPE_IDS = mapKeys(vehicleTypes, 'id');
