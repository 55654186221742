import React, { PureComponent } from 'react';
import { map, size } from 'lodash-es';
import { Field } from 'redux-form';

import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';
import { RunningVehicle } from '../interfaces/interfaces';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';

const formatText = (selectedOptions: string[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('inferenceAudit.allVehicles')
    : translate('inferenceAudit.xVehiclesSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  runningVehicles: RunningVehicle[];
}

class InferenceAuditRunningVehicleSelect extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      runningVehicles,
    } = this.props;

    const runningVehicleOptions = map(runningVehicles, vehicle => ({
      label: vehicle.vehicleName,
      value: vehicle.vehicleId,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('inferenceAudit.inferenceAuditVehicle') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('inferenceAudit.selectVehicle') : undefined)}
        component={Dropdown as any}
        options={runningVehicleOptions}
        onChange={this.onChange}
        formatText={formatText}
        props={{
          isClearable: true,
        }}
        {...dropdownProps}
      />
    );
  }
}

export default InferenceAuditRunningVehicleSelect;
