import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { Holiday, HolidayPlannerSettings, HolidayPlannerState } from '../interfaces/HolidayPlanner';
import * as Services from '../services/holidayPlanner';
import * as Utils from '../utils/holidayPlanner';

/** Actions */
const START_LOAD_HOLIDAYS = 'routes/holidayPlanner/START_LOAD_HOLIDAYS';
const COMPLETE_LOAD_HOLIDAYS = 'routes/holidayPlanner/COMPLETE_LOAD_HOLIDAYS';
const FAIL_LOAD_HOLIDAYS = 'routes/holidayPlanner/FAIL_LOAD_HOLIDAYS';

const START_LOAD_RECURRENCE_TYPES = 'routes/holidayPlanner/START_LOAD_RECURRENCE_TYPES';
const COMPLETE_LOAD_RECURRENCE_TYPES = 'routes/holidayPlanner/COMPLETE_LOAD_RECURRENCE_TYPES';
const FAIL_LOAD_RECURRENCE_TYPES = 'routes/holidayPlanner/FAIL_LOAD_RECURRENCE_TYPES';

const ADD_FRESH_HOLIDAY = 'routes/holidayPlanner/ADD_FRESH_HOLIDAY';
const REMOVE_FRESH_HOLIDAY = 'routes/holidayPlanner/REMOVE_FRESH_HOLIDAY';

const START_SAVING_HOLIDAY = 'routes/holidayPlanner/START_SAVING_HOLIDAY';
const COMPLETE_SAVING_HOLIDAY = 'routes/holidayPlanner/COMPLETE_SAVING_HOLIDAY';
const FAIL_SAVING_HOLIDAY = 'routes/holidayPlanner/FAIL_SAVING_HOLIDAY';

const START_SAVING_FRESH_HOLIDAY = 'routes/holidayPlanner/START_SAVING_FRESH_HOLIDAY';
const COMPLETE_SAVING_FRESH_HOLIDAY = 'routes/holidayPlanner/COMPLETE_SAVING_FRESH_HOLIDAY';
const FAIL_SAVING_FRESH_HOLIDAY = 'routes/holidayPlanner/FAIL_SAVING_FRESH_HOLIDAY';
const RESTORE_FRESH_HOLIDAY = 'routes/holidayPlanner/RESTORE_FRESH_HOLIDAY';

const START_DELETING_HOLIDAY = 'routes/holidayPlanner/START_DELETING_HOLIDAY';
const COMPLETE_DELETING_HOLIDAY = 'routes/holidayPlanner/COMPLETE_DELETING_HOLIDAY';
const FAIL_DELETING_HOLIDAY = 'routes/holidayPlanner/FAIL_DELETING_HOLIDAY';

const START_SAVING_SETTINGS = 'routes/holidayPlanner/START_SAVING_SETTINGS';
const COMPLETE_SAVING_SETTINGS = 'routes/holidayPlanner/COMPLETE_SAVING_SETTINGS';
const FAIL_SAVING_SETTINGS = 'routes/holidayPlanner/FAIL_SAVING_SETTINGS';

const START_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES =
  'routes/holidayPlanner/START_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES';
const COMPLETE_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES =
  'routes/holidayPlanner/COMPLETE_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES';
const FAIL_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES = 'routes/holidayPlanner/FAIL_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES';

const MARK_HOLIDAY_FOR_ACTIVATION = 'routes/holidayPlanner/MARK_HOLIDAY_FOR_ACTIVATION';
const UNMARK_HOLIDAY_FROM_ACTIVATION = 'routes/holidayPlanner/UNMARK_HOLIDAY_FROM_ACTIVATION';

/** Initial State */
const initialState: HolidayPlannerState = {
  holidays: [],
  holidaysLoading: false,

  recurrenceTypes: [],
  recurrenceTypesLoading: false,

  settings: {
    id: 0,
    useBusinessDays: true,
  },
  settingsSaving: false,
  holidayPlannerRouteTemplatesSaving: false,
};

/** Reducer */
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_HOLIDAYS:
      return update(state, {
        $merge: {
          holidaysLoading: true,
        },
      });

    case COMPLETE_LOAD_HOLIDAYS:
      return update(state, {
        $merge: {
          holidays: action.holidays,
          holidaysLoading: false,
          settings: action.settings,
        },
      });

    case FAIL_LOAD_HOLIDAYS:
      return update(state, {
        $merge: {
          holidaysLoading: false,
        },
      });

    case START_LOAD_RECURRENCE_TYPES:
      return update(state, {
        $merge: {
          recurrenceTypesLoading: true,
        },
      });

    case COMPLETE_LOAD_RECURRENCE_TYPES:
      return update(state, {
        $merge: {
          recurrenceTypes: action.recurrenceTypes,
          recurrenceTypesLoading: false,
        },
      });

    case FAIL_LOAD_RECURRENCE_TYPES:
      return update(state, {
        $merge: {
          recurrenceTypesLoading: false,
        },
      });

    case ADD_FRESH_HOLIDAY:
      return update(state, {
        holidays: {
          $push: [
            {
              isDefault: false,
              isActive: true,
              saveInProgress: false,
              deleteInProgress: false,
              isRecurrent: false,
              isExactPostpone: false,
              targetYear: new Date().getFullYear(),
              name: '',
              originalName: '',
              isFresh: true,
              routeShiftingDays: 0,
            },
          ],
        },
      });

    case START_SAVING_HOLIDAY: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holidayId);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              saveInProgress: true,
            },
          },
        },
      });
    }

    case COMPLETE_SAVING_HOLIDAY: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holiday.id);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $set: {
              ...action.holiday,
              saveInProgress: false,
            },
          },
        },
      });
    }

    case FAIL_SAVING_HOLIDAY: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holidayId);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              saveInProgress: false,
            },
          },
        },
      });
    }

    case START_SAVING_FRESH_HOLIDAY: {
      const holidayIndex = Utils.fetchFreshHolidayIndex(state);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              saveInProgress: true,
            },
          },
        },
      });
    }

    case COMPLETE_SAVING_FRESH_HOLIDAY: {
      const holidayIndex = Utils.fetchFreshHolidayIndex(state);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $set: {
              ...action.holiday,
              saveInProgress: false,
            },
          },
        },
      });
    }

    case FAIL_SAVING_FRESH_HOLIDAY: {
      const holidayIndex = Utils.fetchFreshHolidayIndex(state);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              saveInProgress: false,
            },
          },
        },
      });
    }

    case RESTORE_FRESH_HOLIDAY: {
      if (!action.holiday) {
        return state;
      }

      return update(state, {
        holidays: {
          $push: [action.holiday],
        },
      });
    }

    case REMOVE_FRESH_HOLIDAY:
      return update(state, {
        holidays: {
          $splice: [[action.index, 1]],
        },
      });

    case START_DELETING_HOLIDAY: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holidayId);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              deleteInProgress: true,
            },
          },
        },
      });
    }

    case COMPLETE_DELETING_HOLIDAY: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holidayId);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          $splice: [[holidayIndex, 1]],
        },
      });
    }

    case FAIL_DELETING_HOLIDAY: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holidayId);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              deleteInProgress: false,
            },
          },
        },
      });
    }

    case START_SAVING_SETTINGS: {
      return update(state, {
        $merge: {
          settingsSaving: true,
        },
      });
    }

    case START_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES: {
      return update(state, {
        $merge: {
          holidayPlannerRouteTemplatesSaving: true,
        },
      });
    }

    case COMPLETE_SAVING_SETTINGS: {
      return update(state, {
        $merge: {
          settingsSaving: false,
        },
      });
    }

    case COMPLETE_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES: {
      return update(state, {
        $merge: {
          holidayPlannerRouteTemplatesSaving: false,
        },
      });
    }

    case FAIL_SAVING_SETTINGS: {
      return update(state, {
        $merge: {
          settingsSaving: false,
        },
      });
    }

    case FAIL_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES: {
      return update(state, {
        $merge: {
          holidayPlannerRouteTemplatesSaving: false,
        },
      });
    }

    case MARK_HOLIDAY_FOR_ACTIVATION: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holidayId);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              activateRequiresSchedule: true,
            },
          },
        },
      });
    }

    case UNMARK_HOLIDAY_FROM_ACTIVATION: {
      const holidayIndex = Utils.fetchHolidayIndexById(state, action.holidayId);

      if (holidayIndex === -1) {
        return state;
      }

      return update(state, {
        holidays: {
          [holidayIndex]: {
            $merge: {
              activateRequiresSchedule: undefined,
            },
          },
        },
      });
    }

    default:
      return state;
  }
};

/** Action Creators */
const startLoadHolidays = () => ({
  type: START_LOAD_HOLIDAYS,
});

const completeLoadHolidays = (
  holidays: HolidayPlannerState['holidays'],
  settings: HolidayPlannerState['settings'],
) => ({
  type: COMPLETE_LOAD_HOLIDAYS,
  holidays,
  settings,
});

const failLoadHolidays = () => ({
  type: FAIL_LOAD_HOLIDAYS,
});

const startLoadRecurrenceTypes = () => ({
  type: START_LOAD_RECURRENCE_TYPES,
});

const completeLoadRecurrenceTypes = (recurrenceTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_RECURRENCE_TYPES,
  recurrenceTypes,
});

const failLoadRecurrenceTypes = () => ({
  type: FAIL_LOAD_RECURRENCE_TYPES,
});

export const addFreshHoliday = () => ({
  type: ADD_FRESH_HOLIDAY,
});

export const removeFreshHoliday = (index: number) => ({
  type: REMOVE_FRESH_HOLIDAY,
  index,
});

const startSavingHoliday = (holidayId: number) => ({
  type: START_SAVING_HOLIDAY,
  holidayId,
});

const completeSavingHoliday = (holiday: Holiday) => ({
  type: COMPLETE_SAVING_HOLIDAY,
  holiday,
});

const failSavingHoliday = (holidayId: number) => ({
  type: FAIL_SAVING_HOLIDAY,
  holidayId,
});

const startSavingFreshHoliday = () => ({
  type: START_SAVING_FRESH_HOLIDAY,
});

const completeSavingFreshHoliday = (holiday: Holiday) => ({
  type: COMPLETE_SAVING_FRESH_HOLIDAY,
  holiday,
});

const failSavingFreshHoliday = () => ({
  type: FAIL_SAVING_FRESH_HOLIDAY,
});

const restoreFreshHoliday = (holiday: Holiday) => ({
  type: RESTORE_FRESH_HOLIDAY,
  holiday,
});

const startDeletingHoliday = (holidayId: number) => ({
  type: START_DELETING_HOLIDAY,
  holidayId,
});

const completeDeletingHoliday = (holidayId: number) => ({
  type: COMPLETE_DELETING_HOLIDAY,
  holidayId,
});

const failDeletingHoliday = (holidayId: number) => ({
  type: FAIL_DELETING_HOLIDAY,
  holidayId,
});

const startSavingSettings = () => ({
  type: START_SAVING_SETTINGS,
});

const startSavingHolidayPlannerRouteTemplates = () => ({
  type: START_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES,
});

const completeSavingSettings = () => ({
  type: COMPLETE_SAVING_SETTINGS,
});

const completeSavingHolidayPlannerRouteTemplates = () => ({
  type: COMPLETE_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES,
});

const failSavingSettings = () => ({
  type: FAIL_SAVING_SETTINGS,
});

const failSavingHolidayPlannerRouteTemplates = () => ({
  type: FAIL_SAVING_HOLIDAY_PLANNER_ROUTE_TEMPLATES,
});

export const markHolidayForActivation = (holidayId: number) => ({
  type: MARK_HOLIDAY_FOR_ACTIVATION,
  holidayId,
});

export const unmarkHolidayFromActivation = (holidayId: number) => ({
  type: UNMARK_HOLIDAY_FROM_ACTIVATION,
  holidayId,
});

export const loadHolidays = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadHolidays());

  const loadHolidaysPromise = Services.loadHolidays(vendorId);

  loadHolidaysPromise
    .then(holidays =>
      dispatch(
        completeLoadHolidays(Utils.transformRawHolidays(holidays.holidaysPlanner), holidays.holidayPlannerSettings),
      ),
    )
    .catch(() => {
      dispatch(failLoadHolidays());
    });

  return loadHolidaysPromise;
};

export const saveHoliday = (vendorId: number, updatedHoliday: Holiday) => (dispatch: Dispatch) => {
  const holiday = Utils.transformHoliday(updatedHoliday);
  const { id: holidayId } = holiday;

  if (holidayId) {
    dispatch(startSavingHoliday(holidayId));
  } else {
    dispatch(startSavingFreshHoliday());
  }

  const saveHolidayPromise = Services.saveHoliday(vendorId, holiday);

  saveHolidayPromise
    .then(rawHolidayWithId => {
      const holidayWithId = Utils.transformRawHoliday(rawHolidayWithId);

      if (holidayId) {
        dispatch(completeSavingHoliday(holidayWithId));
      } else {
        dispatch(completeSavingFreshHoliday(holidayWithId));
      }
    })
    .catch(() => {
      if (holidayId) {
        dispatch(failSavingHoliday(holidayId));
      } else {
        dispatch(failSavingFreshHoliday());
      }
    });

  return saveHolidayPromise;
};

export const deleteHoliday = (vendorId: number, holidayId: number) => (dispatch: Dispatch) => {
  dispatch(startDeletingHoliday(holidayId));

  const deleteHolidayPromise = Services.deleteHoliday(vendorId, holidayId);

  deleteHolidayPromise
    .then(() => {
      dispatch(completeDeletingHoliday(holidayId));
    })
    .catch(() => {
      dispatch(failDeletingHoliday(holidayId));
    });

  return deleteHolidayPromise;
};

export const saveSettings = (vendorId: number, settings: HolidayPlannerSettings, freshHoliday?: Holiday) => (
  dispatch: Dispatch,
) => {
  dispatch(startSavingSettings());

  const saveHolidayPromise = Services.saveSettings(vendorId, settings);

  saveHolidayPromise
    .then(() => {
      dispatch(completeSavingSettings());
      loadHolidays(vendorId)(dispatch).finally(() => {
        if (freshHoliday) {
          dispatch(restoreFreshHoliday(freshHoliday));
        }
      });
    })
    .catch(() => {
      dispatch(failSavingSettings());
    });

  return saveHolidayPromise;
};

export const loadRecurrenceDayTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadRecurrenceTypes());

  const loadRecurrenceDayTypesPromise = Services.loadRecurrenceDayTypes();

  loadRecurrenceDayTypesPromise
    .then(types => dispatch(completeLoadRecurrenceTypes(types)))
    .catch(() => dispatch(failLoadRecurrenceTypes()));

  return loadRecurrenceDayTypesPromise;
};

export const saveHolidayPlannerRouteTemplates = (
  vendorId: number,
  holidayId: number,
  isDefaultHoliday: boolean,
  routeTemplates: any[],
) => (dispatch: Dispatch) => {
  dispatch(startSavingHolidayPlannerRouteTemplates());
  const saveHolidayPlannerRouteTemplatesPromise = Services.saveHolidayPlannerRouteTemplates(
    vendorId,
    holidayId,
    isDefaultHoliday,
    routeTemplates,
  );
  saveHolidayPlannerRouteTemplatesPromise
    .then(() => dispatch(completeSavingHolidayPlannerRouteTemplates()))
    .catch(() => dispatch(failSavingHolidayPlannerRouteTemplates()));
  return saveHolidayPlannerRouteTemplatesPromise;
};
