import React, { Fragment, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../../store';
import { fuelEfficiencyLogCells } from './vehiclePageCells';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { IMPERIAL } from 'src/vendors/constants';
import { loadVehicleFuelEfficiencyLog } from 'src/fleet/ducks';
import { Table } from '../../../../core/components';
import { Text } from '../../../../core/components/styled';
import { useContainerScrollAtRockBottom } from '../../utils/vehiclePageHooks';
import { Vehicle } from '../../../interfaces/Vehicle';
import { VehicleTableWrapper } from '../../styled/VehiclesPage';
import translate from '../../../../core/services/translate';
import {
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM,
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO,
} from '../../../constants/vehicle';
import { VehicleFuelEfficiencyLogDetail } from 'src/fleet/interfaces/VehicleFuelEfficiencyLog';
import VehicleFillUpDetailsModal from '../../modal/VehicleFillUpDetailsModal';
import {
  VehicleFuelEfficiencyLogHeaderContent,
  VehicleFuelEfficiencyLogHeaderItemWrapper,
} from '../../styled/VehiclePage';
import VehicleFuelLogSectionTableRow from './VehicleFuelLogSectionTableRow';
import VehicleMoreIndicator from './VehicleMoreIndicator';
import VehiclePageSection from './VehiclePageSection';

export default withRouter(function VehicleFuelLogSection({ location: { search } }: RouteComponentProps) {
  const dispatch = useDispatch();

  const [selectedVehicleFillUp, setSelectedVehicleFillUp] = useState<VehicleFuelEfficiencyLogDetail | undefined>();

  const vehicle: Vehicle | undefined = useSelector((state: AppState) => state.fleet.vehicle.vehicle);
  const { fuelEfficiencyLog, fuelEfficiencyLogLoading } = useSelector(
    (state: AppState) => state.fleet.vehicleFuelEfficiencyLog,
  );
  const systemOfMeasurementId: number | undefined = useSelector(
    (state: AppState) => (state.vendors.vendor.vendor as any)?.systemOfMeasurementId,
  );

  const isImperialMeasurementSystem = systemOfMeasurementId === IMPERIAL;

  const { containerHasMaximumScroll, setRef, onScroll } = useContainerScrollAtRockBottom(
    fuelEfficiencyLog?.fuelEfficiencyLogDetails.length,
  );

  const { startDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM, endDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO } =
    getQueryParams(search);

  const vehicleId = vehicle?.id;

  const vehicleHasYDevice = !!vehicle?.deviceId;

  React.useEffect(() => {
    if (!vehicleId || !vehicleHasYDevice) {
      return;
    }

    loadVehicleFuelEfficiencyLog(vehicleId, startDate, endDate)(dispatch);
  }, [dispatch, vehicleId, startDate, endDate, vehicleHasYDevice]);

  const vehicleFuelEfficiencyLogHeaderData = [
    { id: 'fillUps', value: fuelEfficiencyLog?.fillUpsCount || '-', uom: 'fillUps' },
    {
      id: 'mpg',
      value: fuelEfficiencyLog?.milesPerGallonTotal ? fuelEfficiencyLog?.milesPerGallonTotal.toFixed(1) : '-',
      uom: isImperialMeasurementSystem ? 'mpg' : 'kpl',
    },
    {
      id: 'gallonsUsed',
      value: fuelEfficiencyLog?.gallonsUsedTotal ? fuelEfficiencyLog?.gallonsUsedTotal.toFixed(1) : '-',
      uom: isImperialMeasurementSystem ? 'gallonsUsed' : 'litersUsed',
    },
    {
      id: 'milesDriven',
      value: fuelEfficiencyLog?.distanceTravelledTotal ? fuelEfficiencyLog?.distanceTravelledTotal.toFixed(0) : '-',
      uom: isImperialMeasurementSystem ? 'milesDriven' : 'kilometersDriven',
    },
  ];

  return (
    <VehiclePageSection
      title={translate('vehicles.vehicleFuelLog')}
      sectionLoading={fuelEfficiencyLogLoading}
      sectionDisabled={!fuelEfficiencyLog?.fuelEfficiencyLogDetails.length}
      headerContent={
        <VehicleFuelEfficiencyLogHeaderContent>
          {vehicleFuelEfficiencyLogHeaderData.map(item => (
            <VehicleFuelEfficiencyLogHeaderItemWrapper key={item.id}>
              <Text size="xLarge" weight="medium" margin="no xxSmall">
                {item.value}
              </Text>
              <Text size="small">{translate(`vehicles.${item.uom}`)}</Text>
            </VehicleFuelEfficiencyLogHeaderItemWrapper>
          ))}
        </VehicleFuelEfficiencyLogHeaderContent>
      }
      content={
        <>
          {!!fuelEfficiencyLog?.fuelEfficiencyLogDetails.length && (
            <VehicleTableWrapper ref={setRef} onScroll={onScroll}>
              <Table
                cells={fuelEfficiencyLogCells(isImperialMeasurementSystem)}
                rows={fuelEfficiencyLog.fuelEfficiencyLogDetails}
                rowComponent={VehicleFuelLogSectionTableRow}
                rowProps={{
                  openVehicleFillUpDetailsModal: setSelectedVehicleFillUp,
                  isImperialMeasurementSystem,
                }}
              />
            </VehicleTableWrapper>
          )}

          <VehicleMoreIndicator disabled={containerHasMaximumScroll} />
          {!!selectedVehicleFillUp && (
            <VehicleFillUpDetailsModal
              vehicleFillUpDetails={selectedVehicleFillUp}
              closeModal={() => setSelectedVehicleFillUp(undefined)}
              isImperialMeasurementSystem={isImperialMeasurementSystem}
            />
          )}
        </>
      }
    />
  );
});
