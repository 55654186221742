import { http } from '../../core/services/http';
import { transformLoadCustomerLocations as transformSearchCustomerLocations } from './transformSearchCustomerLocations';

// NOTE: Temporarly we don't filter on waste type . See RVP-2001
const searchCustomerLocations = (
  vendorId: number,
  customerName: string,
  vehicleTypeId?: number,
  dayOfService?: string,
  date?: Date | string,
  customerId?: number,
) =>
  http
    .get('/customers/withLocationAndServices', {
      params: { vendorId, customerName, vehicleTypeId, dayOfService, date, customerId },
    })
    .then(response => transformSearchCustomerLocations(response.data.customers));

export default searchCustomerLocations;
