import { useMonthlyCalendar } from '@zach.codes/react-calendar';
import { addMonths, subMonths } from 'date-fns';
import moment from 'moment';
import translate from 'src/core/services/translate';
import { Button, ButtonIcon, ButtonSet } from './styled';
import { MonthlyNavLabel, MonthlyNavWrapper } from './styled/Calendar';

const now = new Date();
interface Props {
  lowerDateLimit?: Date;
  upperDateLimit?: Date;
}

const MonthlyNav = ({ lowerDateLimit, upperDateLimit }: Props) => {
  let { currentMonth, onCurrentMonthChange } = useMonthlyCalendar();
  const handleNextButtonClick = () =>
    onCurrentMonthChange(
      upperDateLimit
        ? addMonths(currentMonth, 1) > upperDateLimit
          ? upperDateLimit
          : addMonths(currentMonth, 1)
        : addMonths(currentMonth, 1),
    );

  const handlePrevButtonClick = () =>
    onCurrentMonthChange(
      lowerDateLimit
        ? subMonths(currentMonth, 1) < lowerDateLimit
          ? lowerDateLimit
          : subMonths(currentMonth, 1)
        : subMonths(currentMonth, 1),
    );

  const isPrevButtonDisabled = (): boolean => !!lowerDateLimit && subMonths(currentMonth, 1) <= lowerDateLimit;
  const isNextButtonDisabled = (): boolean => !!upperDateLimit && addMonths(currentMonth, 1) >= upperDateLimit;

  return (
    <MonthlyNavWrapper margin="no no medium">
      <MonthlyNavLabel size="xLarge">
        {moment(currentMonth).format(moment(currentMonth).year() === moment().year() ? 'MMMM' : 'MMMM YYYY')}
      </MonthlyNavLabel>
      <ButtonSet margin="no" align="left" grouped>
        <Button
          type="button"
          line
          color="primary"
          margin="no"
          padding="xxSmall"
          onClick={handlePrevButtonClick}
          id="calendar-previous-button"
          aria-label={translate('common.previous')}
          disabled={isPrevButtonDisabled()}
        >
          <ButtonIcon icon="arrowLeft" size="xLarge" margin="no xxSmall no no" />
        </Button>
        <Button
          type="button"
          line
          color="primary"
          margin="no"
          padding="xxSmall"
          onClick={() => onCurrentMonthChange(new Date(now.getFullYear(), now.getMonth(), 1))}
          id="calendar-previous-button"
          aria-label={translate('common.previous')}
        >
          {translate('common.currentMonth')}
        </Button>
        <Button
          type="button"
          line
          color="primary"
          margin="no"
          padding="xxSmall"
          onClick={handleNextButtonClick}
          id="calendar-next-button"
          aria-label={translate('common.next')}
          disabled={isNextButtonDisabled()}
        >
          <ButtonIcon icon="arrowRight" size="xLarge" margin="no xxSmall no no" />
        </Button>
      </ButtonSet>
    </MonthlyNavWrapper>
  );
};

export default MonthlyNav;
