import React, { Fragment, MouseEvent, PureComponent } from 'react';

import { size } from 'lodash-es';

import { Address } from 'src/common/interfaces/Facility';
import { Circle, Grid, GridColumn, Label, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { dateAndTime } from '../../../../utils/services/formatter';
import { PICKUP_STATUS_LABEL_COLORS, CUSTOMER_CIRCLE_COLOR } from '../../../constants';
import { PICKUP_STATUSES } from '../../../../common/constants';
import { TOP } from '../../../../core/constants';
import createPopover from '../../../../core/services/createPopover';
import YRoutePageStatusDetailsPopover from './YRoutePageStatusDetailsPopover';

interface Props {
  accountNumber?: number;
  accountStatusId: number;
  address: Address;
  customerName: string;
  id: number;
  loadYServices: (binInsightId: number) => Promise<any>;
  locationName: string;
  orderNumber: number;
  pickupStatusDate?: Date | string;
  pickupStatusId: number;
  routeId: number;
  selectLocation: (routeLocation: any, event: any) => void;
}

class YRoutePageTableRow extends PureComponent<Props> {
  showYRoutePageStatusDetailsPopover = (event: MouseEvent, binInsightId: number) => {
    event.stopPropagation();
    const { loadYServices } = this.props;
    const currentTarget = event.currentTarget;

    loadYServices(binInsightId).then((yRouteServices: any) => {
      if (size(yRouteServices.services)) {
        createPopover(
          currentTarget as any,
          YRoutePageStatusDetailsPopover,
          { yRouteServices },
          { position: TOP, size: 'small' },
        );
      }
    });
  };

  render() {
    const { routeId, selectLocation, ...routeLocation } = this.props;

    const {
      orderNumber,
      accountStatusId,
      customerName,
      accountNumber,
      locationName,
      pickupStatusId,
      pickupStatusDate,
      id,
    } = routeLocation;

    const tableCellWidths = ['8%', '60%', '32%'];

    return (
      <Fragment>
        <TableRow height={60} id={`stop-${id}`} onClick={event => selectLocation(routeLocation, event)}>
          <TableCell width={tableCellWidths[0]} id={`stop-${id}-number`}>
            {orderNumber}
          </TableCell>
          <TableCell width={tableCellWidths[1]}>
            <Grid>
              <GridColumn width="3%" padding="no">
                <Circle color={(CUSTOMER_CIRCLE_COLOR as any)[accountStatusId]} />
              </GridColumn>
              <GridColumn width="95%">
                <Text block singleLine weight="medium" margin="no no xxSmall" id={`stop-${id}-customer`}>
                  {customerName} {accountNumber && `(${accountNumber})`}
                </Text>
                <Text block singleLine size="small" id={`stop-${id}-location`}>
                  {locationName}
                </Text>
              </GridColumn>
            </Grid>
          </TableCell>

          <TableCell width={tableCellWidths[2]} vertical>
            <Label
              color={PICKUP_STATUS_LABEL_COLORS[pickupStatusId.toString()]}
              id={`stop-${id}-job-status`}
              onClick={event => this.showYRoutePageStatusDetailsPopover(event, id)}
            >
              {PICKUP_STATUSES[pickupStatusId].name}
            </Label>

            {!!pickupStatusDate && (
              <Text block size="small" margin="xxSmall no no">
                {dateAndTime(pickupStatusDate)}
              </Text>
            )}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

export default YRoutePageTableRow;
