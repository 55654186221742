import React, { PureComponent } from 'react';

import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ButtonSet, Button, Grid, GridColumn, Text } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { isRequiredOrZero, maxValueNumeric, minValueNumeric1 } from '../../../utils/services/validator';
import translate from '../../../core/services/translate';

interface ComponentProps {
  hasCreateSaveEditGeoFencePermission?: boolean;
  maxOrderNumber: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class OrderNumberForm extends PureComponent<Props> {
  validator: any;

  constructor(props: Props) {
    super(props);
    const maxValue = maxValueNumeric(this.props.maxOrderNumber);
    this.validator = [isRequiredOrZero, minValueNumeric1, maxValue];
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} noValidate>
        <Text block weight="medium" align="center" margin="no no small">
          {translate('routes.stopNumber')}
        </Text>

        <Grid centered>
          <GridColumn size="12/12">
            <Field name="orderNumber" component={Input} type="number" validate={this.validator} />
          </GridColumn>
        </Grid>
        <ButtonSet>
          <Button type="submit" id="stop-number-save-button" color="primary">
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

export default reduxForm<any, ComponentProps>({
  form: 'orderNumber',
})(OrderNumberForm);
