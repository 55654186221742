import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

export const MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH = 1;
export const MONTHLY_SERVICES_FREQUENCY_FIRST = 2;
export const MONTHLY_SERVICES_FREQUENCY_SECOND = 3;
export const MONTHLY_SERVICES_FREQUENCY_THIRD = 4;
export const MONTHLY_SERVICES_FREQUENCY_FOURTH = 5;
export const MONTHLY_SERVICES_FREQUENCY_LAST = 6;

export const MONTHLY_SERVICES_FREQUENCIES = mapKeys(
  [
    {
      id: MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH,
      name: translate(createTranslationKey('DayOfMonth', 'customers.dayOfMonthList')),
      technicalName: 'DayOfMonth',
    },
    {
      id: MONTHLY_SERVICES_FREQUENCY_FIRST,
      name: translate(createTranslationKey('First', 'customers.dayOfMonthList')),
      technicalName: 'First',
    },
    {
      id: MONTHLY_SERVICES_FREQUENCY_SECOND,
      name: translate(createTranslationKey('Second', 'customers.dayOfMonthList')),
      technicalName: 'Second',
    },
    {
      id: MONTHLY_SERVICES_FREQUENCY_THIRD,
      name: translate(createTranslationKey('Third', 'customers.dayOfMonthList')),
      technicalName: 'Third',
    },
    {
      id: MONTHLY_SERVICES_FREQUENCY_FOURTH,
      name: translate(createTranslationKey('Fourth', 'customers.dayOfMonthList')),
      technicalName: 'Fourth',
    },
    {
      id: MONTHLY_SERVICES_FREQUENCY_LAST,
      name: translate(createTranslationKey('Last', 'customers.dayOfMonthList')),
      technicalName: 'Last',
    },
  ],
  'id',
);
