import { map } from 'lodash-es';

import translate from '../../core/services/translate';

const transformLoadUsers = (users: any) =>
  map(users, user => ({
    ...user,
    isUserRegistered: user.registered ? translate('common.yes') : translate('common.no'),
  }));

export default transformLoadUsers;
