import { MouseEvent } from 'react';
import { map } from 'lodash-es';

import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { Contact } from 'src/customers/interfaces/Customers';

interface Props extends Contact {
  contactTypeNames: string[];
  openDeleteContactModal: (contactId: number, event: MouseEvent) => void;
  openContactEditorModal: (contactId: number, event: MouseEvent) => void;
}

const ContactsTableRow = (props: Props) => {
  const {
    id,
    name,
    title,
    contactTypeNames,
    phone,
    email,
    fax,
    isEmailNotificationEnabled,
    isTextNotificationEnabled,
    openDeleteContactModal,
    openContactEditorModal,
  } = props;

  return (
    <TableRow>
      <TableCell width="15%">
        <Text block margin="no no xxSmall" singleLine id={`contact-${id}-name`}>
          {name} {title}
        </Text>
      </TableCell>
      <TableCell width="15%" vertical justifyContent="center">
        {map(contactTypeNames, name => (
          <Text key={name} block margin="no no xxSmall" singleLine id={`contact-${id}-role-${name}`}>
            {name}
          </Text>
        ))}
      </TableCell>
      <TableCell width="15%">
        <Text block margin="no no xxSmall" singleLine id={`contact-${id}-email`}>
          {email || '-'}
        </Text>
      </TableCell>
      <TableCell width="15%">
        <Text block margin="no no xxSmall" singleLine id={`contact-${id}-phone`}>
          {phone || '-'}
        </Text>
      </TableCell>
      <TableCell width="15%">
        <Text block margin="no no xxSmall" singleLine id={`contact-${id}-fax`}>
          {fax || '-'}
        </Text>
      </TableCell>
      <TableCell width="15%" vertical justifyContent="center">
        <UnconnectedCheckbox
          disabled
          label="Text"
          checked={isTextNotificationEnabled}
          id={`contact-${id}-isTextNotificationEnabled`}
        />
        <UnconnectedCheckbox
          disabled
          label="Email"
          checked={isEmailNotificationEnabled}
          id={`contact-${id}-isEmailNotificationEnabled`}
        />
      </TableCell>
      <TableCell width="10%" align="right">
        <TableActionButton
          onClick={(event: MouseEvent<HTMLButtonElement>) => openContactEditorModal(id, event)}
          id={`contact-${id}-edit-button`}
        >
          <ActionButtonTooltip icon="edit" tooltip="edit" />
        </TableActionButton>
        <TableActionButton
          onClick={(event: MouseEvent<HTMLButtonElement>) => openDeleteContactModal(id, event)}
          id={`contact-${id}-delete-button`}
        >
          <ActionButtonTooltip icon="delete" tooltip="delete" />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
};

export default ContactsTableRow;
