import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadRouteSequenceSettings } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import RouteSequenceModal from './RouteSequenceModal';
import { AppState } from '../../../store';

interface Props {
  closeModal(pristine: boolean): void;
}

const RouteSequenceModalResolver: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.all([]);
    }

    return loadRouteSequenceSettings(vendorId)(dispatch);
  }, [vendorId, dispatch]);

  return (
    <TypedResolver
      successComponent={RouteSequenceModal}
      successProps={{ closeModal }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default RouteSequenceModalResolver;
