import React from 'react';
import humps from 'humps';
import { includes } from 'lodash-es';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { Button, ModalTitle } from '../../core/components/styled';
import { createErrorNotification } from '../../core/services/createNotification';
import { EXCEL_MIME_TYPES } from '../../core/constants';
import { getTextWithLinks } from '../../landing/components/services/Utils';
import { TextLoader } from '../../core/styles';
import { Modal } from '../../core/components';
import BackButton from '../../routes/components/pages/dispatchBoard/dispatchBoardPageSections/common/BackButton';
import translate from '../../core/services/translate';

export const DispatchBoardDownloadSampleButtonWrapper = styled.div`
  text-align: right;
`;

interface UploadDocumentAreaProps {
  isHoveringOver: boolean;
}

export const UploadDocumentArea = styled.div<UploadDocumentAreaProps>`
  height: 300px;
  width: 100%;
  border: 5px dashed ${p => p.theme.brandPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => (p.isHoveringOver ? transparentize(0.8, p.theme.brandPrimary) : 'inherit')};
  margin-bottom: 20px;

  a {
    color: ${p => p.theme.brandPrimary};
  }
`;

const getInitialState = () => ({
  isValidHover: false,
  isUploading: false,
  uploadResult: undefined,
});

export interface RecordsUploaderModalProps {
  parentTranslationsPath: string;
  vendorId: number;
  willUnmountAfterUpload?: boolean;
  showUploadingMessageOnlyOnLoader?: boolean;
  downloadSample: () => void;
  onClose: (hasSuccessfulUploadedResults: boolean) => void;
  uploadFile: (file: File, vendorId: number) => Promise<any>;
}

interface UploadResult {
  successfullyImportedCount: number;
  downloadUrl?: string;
  failedImportedCount?: number;
}

interface State {
  isValidHover: boolean;
  isUploading: boolean;
  uploadResult?: UploadResult;
}

type Drag = React.DragEvent<HTMLDivElement>;

class RecordsUploaderModal extends React.Component<RecordsUploaderModalProps, State> {
  state = getInitialState();
  uploadFileRef: HTMLInputElement | null = null;
  hasSuccessfulUploadedResults = false;

  onDragEnter = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (this.isValidFile(e.dataTransfer.items)) {
        this.setState({ isValidHover: true });
      }
    }
  };

  onDragOver = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (!this.state.isValidHover && this.isValidFile(e.dataTransfer.items)) {
        this.setState({ isValidHover: true });
      }
    }
  };

  onDragLeave = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isValidHover: false });
  };

  onDrop = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ uploadResult: undefined });

    if (e.dataTransfer) {
      if (this.isValidFile(e.dataTransfer.items)) {
        this.uploadFile(e.dataTransfer.files);
      }
    }
  };

  onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target?.files) {
      this.uploadFile(e.target.files);
    }
  };

  getTranslation = (key: string, translationMap?: any) =>
    translate(`${this.props.parentTranslationsPath}.${key}`, translationMap);

  setUploadFileRef = (ref: HTMLInputElement) => {
    this.uploadFileRef = ref;
  };

  uploadFile = (files: FileList) => {
    const partialState = { isUploading: false, isValidHover: false };
    if (!this.props.willUnmountAfterUpload) {
      this.props
        .uploadFile(files[0], this.props.vendorId)
        .then(result => {
          const { data: uploadResult } = result;
          if (uploadResult && uploadResult.successfullyImportedCount) {
            this.hasSuccessfulUploadedResults = true;
          }
          this.setState({
            ...partialState,
            uploadResult,
          });
        })
        .catch(e => {
          const errorMessageCode =
            e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

          createErrorNotification(
            errorMessageCode
              ? translate(`customers.alertMessages.${errorMessageCode}`)
              : this.getTranslation('errorMessage'),
          );

          this.setState(partialState);
        });
    } else {
      this.props.uploadFile(files[0], this.props.vendorId).catch(() => this.setState({ isUploading: false }));
    }

    this.setState({ isUploading: true });
  };

  isValidFile = (items: DataTransferItemList) =>
    items && items.length === 1 && items[0].kind === 'file' && includes([EXCEL_MIME_TYPES], items[0].type);

  closeModal = () => {
    this.props.onClose(this.hasSuccessfulUploadedResults);
  };

  openFileUploader = () => {
    !!this.uploadFileRef && this.uploadFileRef.click();
  };

  createModalContent = () => {
    const { uploadResult } = this.state;
    const result = uploadResult as any as UploadResult;

    return (
      <div>
        {!!uploadResult ? (
          <>
            {!!result.successfullyImportedCount && <div>{this.getTranslation('successfulRecords', result)}</div>}
            {!!result.failedImportedCount && <div>{this.getTranslation('failedRecords', result)}</div>}
            {!!result.failedImportedCount && !!result.downloadUrl && (
              <>
                <br />
                <div>{getTextWithLinks(this.getTranslation('downloadFailedRecords'), result.downloadUrl)}</div>
              </>
            )}
            <>
              <br />
              <div>
                <BackButton onClick={() => this.setState({ uploadResult: undefined })} />
                {this.getTranslation('goBack')}
              </div>
            </>
          </>
        ) : (
          <div>
            <div>{this.getTranslation('dropZoneMessage')}</div>
            <Button color="primary" margin="small no no no" onClick={this.openFileUploader} type="button">
              {translate('common.chooseFile')}
            </Button>
            <input
              accept={EXCEL_MIME_TYPES}
              id="fileSelect"
              onChange={this.onFileInputChange}
              ref={this.setUploadFileRef}
              style={{ display: 'none' }}
              type="file"
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { isUploading, isValidHover } = this.state;
    const { showUploadingMessageOnlyOnLoader } = this.props;

    return (
      <Modal onClose={this.closeModal}>
        <ModalTitle>{this.getTranslation('modalTitle')}</ModalTitle>

        <UploadDocumentArea
          isHoveringOver={isValidHover}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          {isUploading && !showUploadingMessageOnlyOnLoader
            ? this.getTranslation('uploadingMessage')
            : this.createModalContent()}
        </UploadDocumentArea>

        <DispatchBoardDownloadSampleButtonWrapper>
          <Button color="primary" line onClick={this.props.downloadSample}>
            {this.getTranslation('downloadSample')}
          </Button>
        </DispatchBoardDownloadSampleButtonWrapper>

        {isUploading && (
          <TextLoader>{showUploadingMessageOnlyOnLoader ? this.getTranslation('uploadingMessage') : null}</TextLoader>
        )}
      </Modal>
    );
  }
}

export default RecordsUploaderModal;
