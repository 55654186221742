import React from 'react';

import {
  SocialPostLink,
  SocialPulsePostAccount,
  SocialPulsePostContainer,
  SocialPulsePostContent,
  SocialPulsePostFollow,
  SocialPulsePostHeader,
  SocialPulsePostIdentity,
  SocialPulsePostLogo,
  SocialPulsePostSource,
  SocialPulsePostText,
} from './styled/SocialPulsePost';
import translate from '../../core/services/translate';
import { TwitterPostType } from '../interfaces/news';

type Props = {
  post?: TwitterPostType;
};

export default function SocialPulsePost({ post }: Props) {
  return post ? (
    <SocialPulsePostContainer>
      <SocialPulsePostLogo src={post.profileImage && post.profileImage.replace('http:', 'https:')} />
      <SocialPulsePostContent>
        <SocialPulsePostHeader>
          <SocialPulsePostIdentity>
            <SocialPulsePostSource>Twitter</SocialPulsePostSource>
            <SocialPulsePostAccount>@Rubicon</SocialPulsePostAccount>
          </SocialPulsePostIdentity>
          <SocialPulsePostFollow href={post.url} target="_blank">
            {translate('account.follow')}
          </SocialPulsePostFollow>
        </SocialPulsePostHeader>
        <SocialPostLink href={post.url} target="_blank">
          <SocialPulsePostText>{post.text}</SocialPulsePostText>
        </SocialPostLink>
      </SocialPulsePostContent>
    </SocialPulsePostContainer>
  ) : null;
}
