import { find, upperFirst } from 'lodash-es';
import { camelize } from 'humps';

import { PermissionGuard } from 'src/account/components';
import { FLEET_CONTAINERS_DELETE, FLEET_CONTAINERS_EDIT } from 'src/account/constants';
import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import { TableRow, TableCell, TableActionButton, Text, Grid, GridColumn } from 'src/core/components/styled';
import { ContainerNewSimplified } from '../../../interfaces/containers';
import { useSelector } from 'src/core/hooks/useSelector';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';
import { COLOR_ALERT, COLOR_BLACK, COLOR_PRIMARY } from 'src/core/styles';
import { getServiceName } from 'src/fleet/services/transformContainersLocationsForCreateEdit';
import translate from 'src/core/services/translate';
import moment from 'moment';

interface Props extends ContainerNewSimplified {
  rowHeight?: number;
  hasEditPermissions: boolean;
  selectContainer: (id: number) => void;
  editContainer: (id: number) => void;
  deleteContainer: (id: number) => void;
  viewContainerActivity: (id: number) => void;
  push: (url: string) => void;
}

const NewContainersPageTableRow = ({
  a,
  c,
  cn,
  id,
  lt,
  nr,
  s,
  st,
  t,
  sc,
  wed,
  isSelected,
  hasEditPermissions,
  rowHeight,
  deleteContainer,
  editContainer,
  selectContainer,
  viewContainerActivity,
  push,
}: Props) => {
  const locationTypes = useSelector(state => state.common.containerLocationTypes.containerLocationTypes);
  const equipmentSizes = useSelector(state => state.common.equipmentSizes.equipmentSizes);
  const containerStatuses = useSelector(state => state.common.containerStatuses.containerStatuses);
  const equipmentConditions = useSelector(state => state.common.equipmentConditions.equipmentConditions);
  const equipmentTypes = useSelector(state => state.common.equipmentTypes.equipmentTypes);
  const serviceTypes = useSelector(state => state.common.serviceTypes.serviceTypes);

  const equipmentSize = find(equipmentSizes, { id: s });
  const containerStatus = find(containerStatuses, { id: st });
  const equipmentType = find(equipmentTypes, { id: t });

  const translatedSize = translate(
    `common.equipmentSizes.x${upperFirst(
      camelize(equipmentSize?.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
    )}`,
    {
      size: equipmentSize ? getEquipmentSize(equipmentSize?.technicalName, equipmentSize?.name) : 0,
    },
  );
  const translatedEquipmentType = translate(`common.equipmentTypes.${camelize(equipmentType?.technicalName || '')}`);

  const equipmentSizeService = find(equipmentSizes, { id: sc?.s });
  const equipmentTypeService = find(equipmentTypes, { id: sc?.t });
  const serviceTypeService = find(serviceTypes, { id: sc?.st });

  const containerServiceContractInfo = getServiceName(
    equipmentTypeService?.technicalName,
    equipmentTypeService?.name,
    equipmentSizeService?.technicalName,
    equipmentSizeService?.name,
    serviceTypeService?.name,
  );

  const getContainerWarrantyIcon = () => {
    if (wed) {
      const warrantyExpirationDate = moment(wed || null);
      if (warrantyExpirationDate.isSameOrAfter(moment().add(-1, 'day').endOf('day'))) {
        return (
          <GridColumn verticalAlign="center" size="3/12" padding="no">
            <ActionButtonTooltip
              customViewBox="0 0 32 26"
              size="medium"
              icon="shieldPositive"
              color={COLOR_PRIMARY}
              tooltip="containerUnderWarranty"
            />
          </GridColumn>
        );
      } else {
        return (
          <GridColumn verticalAlign="center" size="3/12" padding="no">
            <ActionButtonTooltip
              customViewBox="0 0 32 26"
              size="medium"
              icon="shieldNegative"
              color={COLOR_ALERT}
              tooltip="containerWarrantyExpired"
            />
          </GridColumn>
        );
      }
    } else return null;
  };

  return (
    <TableRow height={rowHeight} onClick={() => hasEditPermissions && push(`/fleet/containers/${id}/edit`)}>
      <TableCell width="3%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
        {!checkIfViewOnly() && !checkIfSupport() && (
          <UnconnectedCheckbox
            block
            checked={isSelected}
            onChange={e => selectContainer(id)}
            onClick={e => e.stopPropagation()}
          />
        )}
      </TableCell>
      <TableCell width="8%" padding="defaultCellVertical xSmall">
        <Text singleLine title={nr}>
          {nr}
        </Text>
      </TableCell>
      <TableCell width="12%" padding="defaultCellVertical xSmall">
        {lt && (
          <Text block weight="medium">
            {find(locationTypes, { id: lt })?.name}
          </Text>
        )}
      </TableCell>
      <TableCell width="24.5%" padding="defaultCellVertical xSmall">
        <Grid>
          <GridColumn width="100%" padding="no">
            <Text title={cn ? cn : a} block singleLine weight="medium">
              {cn ? cn : a}
            </Text>
            <Text block singleLine title={containerServiceContractInfo}>
              {containerServiceContractInfo}
            </Text>
            <Text title={cn && a ? a : '-'} block singleLine>
              {cn && a ? a : '-'}
            </Text>
          </GridColumn>
        </Grid>
      </TableCell>
      <TableCell width="10%" padding="defaultCellVertical xSmall">
        <Text> {translatedEquipmentType}</Text>
      </TableCell>
      <TableCell width="11.6%" padding="defaultCellVertical xSmall">
        <Text singleLine title={translatedSize}>
          {translatedSize}
        </Text>
      </TableCell>
      <TableCell width="8%" padding="defaultCellVertical xSmall">
        <Text> {find(equipmentConditions, { id: c })?.name}</Text>
      </TableCell>
      <TableCell width="11.8%" padding="defaultCellVertical xSmall">
        <Grid>
          <GridColumn verticalAlign="center" size="9/12" padding="no">
            <Text color={COLOR_BLACK} singleLine title={containerStatus?.name} align="left">
              {containerStatus?.name}
            </Text>
          </GridColumn>
          {getContainerWarrantyIcon()}
        </Grid>
      </TableCell>
      <TableCell width="10%" padding="defaultCellVertical xSmall" align="right">
        <PermissionGuard permission={FLEET_CONTAINERS_EDIT}>
          <TableActionButton onClick={() => editContainer(id)}>
            <ActionButtonTooltip size="sMedium" icon="edit" tooltip="editContainer" />
          </TableActionButton>
        </PermissionGuard>
        <PermissionGuard permission={FLEET_CONTAINERS_DELETE}>
          <TableActionButton onClick={() => deleteContainer(id)}>
            <ActionButtonTooltip size="sMedium" icon="delete" tooltip="deleteContainer" />
          </TableActionButton>
        </PermissionGuard>
        <TableActionButton onClick={() => viewContainerActivity(id)}>
          <ActionButtonTooltip size="mMedium" icon="plowHistory" tooltip="viewHistory" />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
};

export default NewContainersPageTableRow;
