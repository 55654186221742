import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormGroup, FormError, Select as FormSelect } from './styled';

interface Props extends WrappedFieldProps {
  children: any;
  options: any[];
  margin?: string;
}

const Select: React.FC<Props> = ({
  input,
  children,
  options,
  meta: { asyncValidating, submitFailed, error },
  margin,
}) => (
  <FormGroup isLoading={asyncValidating} margin={margin}>
    <FormSelect {...input} isEmpty={!input.value}>
      {children}
      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </FormSelect>

    {submitFailed && error && <FormError>{error}</FormError>}
  </FormGroup>
);

Select.defaultProps = {
  options: [],
};

export default Select;
