import { http } from '../../core/services/http';

export const loadFaultCodeEmails = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/faultCodeSettings`).then(response => response.data);

export const createFaultCodeEmail = (vendorId: number, faultCodeEmail: any) =>
  http.post(`/vendors/${vendorId}/faultCodeSettings`, faultCodeEmail).then(response => response.data);

export const updateFaultCodeEmail = (vendorId: number, faultCodeEmail: any) =>
  http.put(`/vendors/${vendorId}/faultCodeSettings`, faultCodeEmail).then(response => response.data);

export const deleteFaultCodeEmail = (vendorId: number, faultCodeEmailId: number) =>
  http.delete(`/vendors/${vendorId}/faultCodeSettings/${faultCodeEmailId}`).then(response => response.data);
