import { Modal, Table } from 'src/core/components';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Grid, GridColumn, Message, ModalSection, ModalTitle, PanelSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';
import { size } from 'lodash-es';
import { TableCell } from 'src/core/components/Table';
import BillHistoryTableRow from '../pages/billing/billingPageSections/BillHistoryTableRow';

interface Props {
  onCancel: any;
  billInfo: {
    status: number;
    siteAddress: string;
    siteName: string;
    customerName: string;
    billId: number;
  };
}

const billHistoryTableCells: TableCell[] = [
  {
    name: 'date',
    label: 'Date',
    width: '15%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'action',
    label: 'Action',
    width: '15%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'details',
    label: 'Details',
    width: '70%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
];

const BillHistoryModal = ({ onCancel, billInfo }: Props) => {
  const { isLoading, billHistory } = useSelector(state => state.finance.billHistory);
  const billHistoryList = billHistory?.billHistoryList;

  return (
    <Modal padding="small no no no" isLoading={false} size="mediumLarge" onClose={onCancel}>
      <ModalTitle margin="small no">
        {`${translate('finance.bill')} #${billInfo.billId} ${translate('common.history')}`}
      </ModalTitle>
      <ModalSection padding="no" fluid>
        <PanelSection vertical padding="medium" isLoading={isLoading}>
          <Grid margin="no">
            <GridColumn size="2/12" padding="no small no no">
              <LabeledDataView label="customer name" value={billInfo.customerName || '–'} />
            </GridColumn>
            <GridColumn size="2/12" padding="no small no no">
              <LabeledDataView label="location" value={billInfo.siteName || '-'} />
            </GridColumn>
            <GridColumn size="6/12" padding="no small no no">
              <LabeledDataView label="Address" value={billInfo.siteAddress || '–'} />
            </GridColumn>
            <GridColumn size="2/12" padding="no small no no">
              <LabeledDataView label="Status" value={billInfo.status || '-'} />
            </GridColumn>
          </Grid>
        </PanelSection>
        <PanelSection padding="small medium">
          {!!size(billHistoryList) ? (
            <Table cells={billHistoryTableCells} rowComponent={BillHistoryTableRow} rows={billHistoryList} />
          ) : (
            <Message padding="sMedium">{translate('finance.noBillHistory')}</Message>
          )}
        </PanelSection>
      </ModalSection>
    </Modal>
  );
};

export default BillHistoryModal;
