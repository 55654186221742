import React from 'react';

import { connect } from 'react-redux';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import { ModalSection } from '../../../core/components/styled';
import { VehicleNotesFormFieldArray } from '.';
import vehicleNotesFormInitialValuesSelector from 'src/fleet/services/vehicleNotesFormInitialValuesSelector';

interface ComponentProps {
  change?: any;
  fleetMaintenanceConfigIsOptional: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isPendingOrClosingProcess: boolean;
  isSaving: boolean;
  newIssueStatus: number;
  tripInspectionDetailId: number;
  updateVehicleIssue: (status: number, vehicleNote?: string) => void;
  vehicleId: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const VehicleNotesForm: React.FC<Props> = ({
  change,
  fleetMaintenanceConfigIsOptional,
  isDeleting,
  isLoading,
  isPendingOrClosingProcess,
  isSaving,
  newIssueStatus,
  tripInspectionDetailId,
  updateVehicleIssue,
  vehicleId,
}) => (
  <form>
    <ModalSection padding="no" isLoading={isLoading || isDeleting || isSaving}>
      <FieldArray
        change={change}
        component={VehicleNotesFormFieldArray as any}
        fleetMaintenanceConfigIsOptional={fleetMaintenanceConfigIsOptional}
        isPendingOrClosingProcess={isPendingOrClosingProcess}
        name="vehicleNotes"
        newIssueStatus={newIssueStatus}
        tripInspectionDetailId={tripInspectionDetailId}
        updateVehicleIssue={updateVehicleIssue}
        vehicleId={vehicleId}
      />
    </ModalSection>
  </form>
);

const mapStateToProps = (state: AppState) => ({
  isDeleting: state.fleet.vehicleNotes.isDeleting,
  isLoading: state.fleet.vehicleNotes.isLoading,
  isSaving: state.fleet.vehicleNotes.isSaving,
  initialValues: vehicleNotesFormInitialValuesSelector(state.fleet.vehicleNotes as any) || [],
});

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'vehicleNotes',
    enableReinitialize: true,
  })(VehicleNotesForm),
);
