import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import translate from '../../../core/services/translate';
import { DocumentTitle } from '../../../common/components';
import { AccountPage, AccountPanel } from '../styled';
import { ChangePasswordForm } from '../forms';
import { ChangePasswordFormValues } from '../forms/ChangePasswordForm';
import { changePassword } from '../../ducks/login';
import { DuckFunction } from '../../../contracts/ducks';
import { AppState } from '../../../store';

interface Props extends RouteComponentProps {
  changePasswordLoading: boolean;
  changePassword: DuckFunction<typeof changePassword>;
}

const ChangePasswordPage: React.FC<Props> = ({ changePasswordLoading, changePassword }) => {
  const onSubmit = React.useCallback(
    ({ email, currentPassword, newPassword }: ChangePasswordFormValues) => {
      changePassword(email, currentPassword, newPassword);
    },
    [changePassword],
  );

  return (
    <>
      <DocumentTitle>{translate('account.changePassword')}</DocumentTitle>

      <AccountPage>
        <AccountPanel size="small" isLoading={changePasswordLoading}>
          <ChangePasswordForm onSubmit={onSubmit} />
        </AccountPanel>
      </AccountPage>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  changePasswordLoading: state.account.login.changePasswordLoading,
});

const mapDispatchToProps = {
  changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
