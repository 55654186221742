import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadCommunityInsights as doLoadCommunityInsights,
  clearInsight as doClearInsight,
  exportCommunityInsights as doExportCommunityInsights,
  transformCommunityInsights,
} from '../services/communityInsights';
import { INSIGHT_CLEARED } from '../constants';
import { CommunityInsightsState, CommunityInsightsResponse } from '../interfaces/communityInsights';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';

// Actions
const START_LOAD = 'insights/communityInsights/START_LOAD';
const COMPLETE_LOAD = 'insights/communityInsights/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/communityInsights/FAIL_LOAD';
const START_CLEAR_INSIGHT = 'insights/communityInsights/START_CLEAR_INSIGHT';
const COMPLETE_CLEAR_INSIGHT = 'insights/communityInsights/COMPLETE_CLEAR_INSIGHT';
const FAIL_CLEAR_INSIGHT = 'insights/communityInsights/FAIL_CLEAR_INSIGHT';
const START_EXPORT = 'insights/communityInsights/START_EXPORT';
const COMPLETE_EXPORT = 'insights/communityInsights/COMPLETE_EXPORT';
const FAIL_EXPORT = 'insights/communityInsights/FAIL_EXPORT';
const RESET = 'insights/communityInsights/RESET';

const initialState: CommunityInsightsState = {
  isLoading: true,
  isExporting: false,
  insights: {
    list: [],
    map: [],
  },
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD: {
      const { mapData: mapList } = action.insights as CommunityInsightsResponse;

      return update(state, {
        $merge: {
          isLoading: false,
          insights: {
            map: mapList,
            list: transformCommunityInsights(mapList),
          },
        },
      });
    }

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    case COMPLETE_CLEAR_INSIGHT: {
      const { map: mapList } = state.insights;

      const newMapList = mapList.map(mapItem => ({
        ...mapItem,
        communityInsights: mapItem.communityInsights.map(mapItemInsight => ({
          ...mapItemInsight,
          statusId: mapItemInsight.id === action.insightId ? INSIGHT_CLEARED : mapItemInsight.statusId,
        })),
      }));

      return update(state, {
        $merge: {
          insights: {
            map: newMapList,
            list: transformCommunityInsights(newMapList),
          },
        },
      });
    }

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (insights: CommunityInsightsResponse) => ({
  type: COMPLETE_LOAD,
  insights,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startClearInsight = () => ({
  type: START_CLEAR_INSIGHT,
});

const completeClearInsight = (insightId: number) => ({
  type: COMPLETE_CLEAR_INSIGHT,
  insightId,
});

const failClearInsight = () => ({
  type: FAIL_CLEAR_INSIGHT,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const resetCommunityInsights = () => ({
  type: RESET,
});

export const loadCommunityInsights = (vendorId: number, startDate: string, endDate: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadCommunityInsightsPromise = doLoadCommunityInsights(vendorId, startDate, endDate);

  loadCommunityInsightsPromise.then(insights => dispatch(completeLoad(insights))).catch(() => dispatch(failLoad()));

  return loadCommunityInsightsPromise;
};

export const clearInsight = (insightId: number) => (dispatch: Dispatch) => {
  dispatch(startClearInsight());

  const clearInsightPromise = doClearInsight(insightId);

  clearInsightPromise.then(() => dispatch(completeClearInsight(insightId))).catch(() => dispatch(failClearInsight()));

  return clearInsightPromise;
};

export const exportCommunityInsights =
  (
    vendorId: number,
    locationFlagTypeIds: any[],
    statusIds: any[],
    insightName: string,
    startDate: string,
    endDate: string,
    sortOrder: string,
    sortedBy: string,
    filtersPreferencesIds: FiltersPreferencesIds,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());

    const exportCommunityInsightsPromise = doExportCommunityInsights(
      vendorId,
      locationFlagTypeIds,
      statusIds,
      insightName,
      startDate,
      endDate,
      sortOrder,
      sortedBy,
      filtersPreferencesIds,
    );

    exportCommunityInsightsPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));

    return exportCommunityInsightsPromise;
  };
