import { getFeatureCollection, getLineStringFeature, getPointFeature } from 'src/common/components/map/util';
import { POLYLINE_COLORS } from 'src/common/constants';
import { ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE } from '../constants';
import { RouteMapVehicleCoordinateGroup, RouteMapVehicleTracking } from 'src/routes/interfaces/RouteMapVehicleData';
import { map } from 'lodash-es';

export const getTrackingCoordinateGroupId = (tracking: RouteMapVehicleTracking, coordinateGroupIndex: number) =>
  Number(`${tracking.vehicle.id}.${coordinateGroupIndex}`);

export type VehicleTrackingFeatureProperties = {
  id: number;
  clickable: boolean;
  vehicleId: number;
  coordinateGroupIndex: number;
  bearing?: number;
  color: string;
};

export const getVehicleTrackingsGeoJSON = (vehicleTrackings: RouteMapVehicleTracking[]) =>
  getFeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>(
    vehicleTrackings
      .map((vehicleTracking, index) => {
        let color = index + 1;
        if (color > POLYLINE_COLORS.length - 1) color = 1;

        return vehicleTracking.coordinateGroups?.map((coordinateGroup, coordinateGroupIndex) =>
          getLineStringFeature(
            getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex),
            coordinateGroup.coordinates.map(({ latitude, longitude }) => [longitude, latitude]),
            {
              id: getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex),
              clickable: false,
              vehicleId: vehicleTracking.vehicle.id,
              coordinateGroupIndex,
              color: vehicleTracking.vehicle.isAssisting ? POLYLINE_COLORS[color] : POLYLINE_COLORS[0],
            },
          ),
        );
      })
      .reduce((acc, current) => acc.concat(current), []),
  );

export const getVehicleTrackingsPointsGeoJSON = (vehicleTrackings: RouteMapVehicleTracking[]) =>
  getFeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>(
    map(vehicleTrackings, (vehicleTracking, index) => {
      let color = index + 1;
      if (color > POLYLINE_COLORS.length - 1) color = 1;
      return map(vehicleTracking.coordinateGroups, (coordinateGroup, coordinateGroupIndex) =>
        map(coordinateGroup.coordinates, ({ latitude, longitude, bearing }) =>
          getPointFeature(getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex), [longitude, latitude], {
            id: getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex),
            clickable: true,
            vehicleId: vehicleTracking.vehicle.id,
            coordinateGroupIndex,
            color: vehicleTracking.vehicle.isAssisting ? POLYLINE_COLORS[color] : POLYLINE_COLORS[0],
            bearing: bearing || bearing === 0 ? bearing - 90 : undefined,
          }),
        ),
      ).reduce((acc, current) => {
        acc.push(...current);
        return acc;
      }, []);
    }).reduce((acc, current) => {
      acc.push(...current);
      return acc;
    }, []),
  );

export const handleRouteMapVehicleTrackingsFeatureState = (
  map: mapboxgl.Map,
  vehicleTrackings: RouteMapVehicleTracking[],
) => {
  if (!map.getSource(ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE)) {
    return;
  }

  vehicleTrackings.forEach((vehicleTracking, vehicleTrackingIndex) => {
    vehicleTracking.coordinateGroups?.forEach((_, coordinateGroupIndex) => {
      map.setFeatureState(
        {
          id: getTrackingCoordinateGroupId(vehicleTracking, coordinateGroupIndex),
          source: ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
        },
        {
          color: POLYLINE_COLORS[0],
        },
      );
    });
  });
};

export const getVehicleTrackingCoordinateGroupCenter = (coordinateGroup: RouteMapVehicleCoordinateGroup) =>
  coordinateGroup.coordinates[Math.floor(coordinateGroup.coordinates.length / 2)];
