import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadRateCodeTypes as doLoadRateCodeTypes } from '../services/rateCodes';
import { RateType } from 'src/finance/interfaces/ServiceRates';

const START_LOAD = 'common/rateCodes/START_LOAD';
const COMPLETE_LOAD = 'common/rateCodes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/rateCodes/FAIL_LOAD';
const RESET = 'common/rateCodes/RESET';

type State = {
  rateCodes: RateType[];
  isLoading: boolean;
};

const initialState: State = {
  rateCodes: [],
  isLoading: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rateCodes: action.rateCodes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rateCodes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rateCodes: RateType[]) => ({
  type: COMPLETE_LOAD,
  rateCodes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRateCodeTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRateCodesPromise = doLoadRateCodeTypes();
  loadRateCodesPromise.then(rateCodes => dispatch(completeLoad(rateCodes))).catch(() => dispatch(failLoad()));
  return loadRateCodesPromise;
};

export const resetRateCodes = () => ({
  type: RESET,
});
