import React, { Fragment } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { Button, ButtonSet, PanelSection, Grid, GridColumn, Text } from '../../../core/components/styled';
import { Input, TypedField } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import translate from '../../../core/services/translate';

/*
const SurfSightFeaturesFieldArray: React.FC<{ fields: any }> = ({ fields }) => {
  return (
    <>
      <Grid multiLine>
        {fields.map((field: any, index: number) => {
          return (
            <Fragment key={index}>
              <Grid>
                <GridColumn size="9/12" verticalAlign="center" padding="small no small" color="#fafafa" borderBottom>
                  <Text uppercase size="small" weight="medium">
                  </Text>
                </GridColumn>
                <GridColumn size="3/12" verticalAlign="center" padding="small no small" color="#fafafa" borderBottom>
                  <Text uppercase size="small" weight="medium">
                    {translate('vendors.visionConfiguration.activeSettings')}
                  </Text>
                </GridColumn>
              </Grid>
              <Grid>
                <GridColumn size="9/12" verticalAlign="center" padding="no no small" borderBottom>
                  <Text size="medium" weight="normal">
                    {translate('vendors.surfsightConfiguration.enabled')}
                  </Text>
                </GridColumn>
                <GridColumn size="3/12" align="left" verticalAlign="center" padding="small no small" borderBottom>
                  <TypedField name={`${field}.enabled`} component={Switch} />
                </GridColumn>
              </Grid>
              <Grid>
                <GridColumn size="9/12" verticalAlign="center" padding="no no small" borderBottom>
                  <Text size="medium" weight="normal">
                    {translate('vendors.surfsightConfiguration.apply_blur')}
                  </Text>
                </GridColumn>
                <GridColumn size="3/12" align="left" verticalAlign="center" padding="small no small" borderBottom>
                  <TypedField name={`${field}.apply_blur`} component={Switch} />
                </GridColumn>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};*/

const GeneralSettings = () => (
  <>
    <Grid>
      <GridColumn size="12/12" verticalAlign="center" padding="small no small" color="#fafafa" borderBottom>
        <Text uppercase size="small" weight="medium">
          {translate('vendors.visionConfiguration.generalSettings')}
        </Text>
      </GridColumn>
    </Grid>
    <Grid>
      <GridColumn size="8/12" verticalAlign="center" padding="no" margin="no no xSmall" borderBottom>
        <Text size="medium" weight="normal">
          {translate('vendors.surfsightConfiguration.organizationId')}
        </Text>
      </GridColumn>
      <GridColumn size="4/12" verticalAlign="center" padding="no" margin="small no xSmall" borderBottom>
        <TypedField name={'organizationId'} component={Input} validate={[isRequired]} />
      </GridColumn>
    </Grid>
    {/* Hide for sprint-135 */}
    {/* <Grid>
      <GridColumn size="9/12" verticalAlign="center" padding="small no small" margin="no no xSmall" borderBottom>
        <Text size="medium" weight="normal">
          {translate('vendors.surfsightConfiguration.deleteSource')}
        </Text>
      </GridColumn>
      <GridColumn size="3/12" verticalAlign="center" padding="small no small" margin="no no xSmall" borderBottom>
        <TypedField name={`delete_source`} component={Switch} />
      </GridColumn>
    </Grid>
    <Grid>
      <GridColumn size="9/12" verticalAlign="center" padding="no" margin="no no xSmall" borderBottom>
        <Text size="medium" weight="normal">
          {translate('vendors.surfsightConfiguration.deleteSourceAfterXHours')}
        </Text>
      </GridColumn>
      <GridColumn size="3/12" verticalAlign="center" padding="no" margin="no no xSmall" borderBottom>
        <TypedField name={'delete_source_after_x_hours'} component={Input} />
      </GridColumn>
    </Grid>
    <Grid>
      <GridColumn size="9/12" verticalAlign="center" padding="small no small" margin="no no xSmall" borderBottom>
        <Text size="medium" weight="normal">
          {translate('vendors.surfsightConfiguration.pushToInsights')}
        </Text>
      </GridColumn>
      <GridColumn size="3/12" verticalAlign="center" padding="small no small" margin="no no xSmall" borderBottom>
        <TypedField name={'push_to_insights'} component={Switch} />
      </GridColumn>
    </Grid>
    <Grid>
      <GridColumn size="9/12" verticalAlign="center" padding="small no small" borderBottom>
        <Text size="medium" weight="normal">
          {translate('vendors.surfsightConfiguration.pushToTelematics')}
        </Text>
      </GridColumn>
      <GridColumn size="3/12" verticalAlign="center" padding="small no small" borderBottom>
        <TypedField name={'push_to_telematics'} component={Switch} />
      </GridColumn>
    </Grid> */}
  </>
);

function SurfSightConfigurationForm({ handleSubmit }: InjectedFormProps) {
  return (
    <form onSubmit={handleSubmit}>
      <PanelSection vertical padding="small">
        <GeneralSettings />
        {/* Hide for sprint-135 */}
        {/* <FieldArray name="features" component={SurfSightFeaturesFieldArray} props={{}} /> */}
        <Grid centered>
          <GridColumn size="6/12">
            <ButtonSet>
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
}

export default reduxForm({ form: 'surfSightConfigurationForm' })(SurfSightConfigurationForm);
