import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadEsriCredentials as doLoadEsriCredentials,
  loadEsriCustomerConfig as doLoadEsriCustomerConfig,
  loadEsriGeoFenceConfig as doLoadEsriGeoFenceConfig,
  loadEsriRouteConfig as doLoadEsriRouteConfig,
  loadEsriServiceConfig as doLoadEsriServiceConfig,
  saveEsriCredentials as doSaveEsriCredentials,
  saveEsriCustomerConfig as doSaveEsriCustomerConfig,
  saveEsriGeoFenceConfig as doSaveEsriGeoFenceConfig,
  saveEsriRouteConfig as doSaveEsriRouteConfig,
  saveEsriServiceConfig as doSaveEsriServiceConfig,
  validateEsriSyncSettings as doValidateEsriSyncSettings,
  validateEsriCredentials as doValidateEsriCredentials,
  validateEsriCustomerConfig as doValidateEsriCustomerConfig,
  validateEsriRouteConfig as doValidateEsriRouteConfig,
  validateEsriServiceConfig as doValidateEsriServiceConfig,
  validateEsriGeoFenceConfig as doValidateEsriGeoFenceConfig,
} from '../services/esriSyncSettings';
import { EsriCredentials, EsriConfig, EsriSyncSettings, EsriSyncValidation } from '../interfaces/EsriSyncSettings';
// Actions
const START_LOAD_CREDENTIALS = 'vendors/esriIntegrationConfiguration/START_LOAD_CREDENTIALS';
const COMPLETE_LOAD_CREDENTIALS = 'vendors/esriIntegrationConfiguration/COMPLETE_LOAD_CREDENTIALS';
const FAIL_LOAD_CREDENTIALS = 'vendors/esriIntegrationConfiguration/FAIL_LOAD_CREDENTIALS';
const START_LOAD_CUSTOMER_CONFIG = 'vendors/esriIntegrationConfiguration/START_LOAD_CUSTOMER_CONFIG';
const COMPLETE_LOAD_CUSTOMER_CONFIG = 'vendors/esriIntegrationConfiguration/COMPLETE_LOAD_CUSTOMER_CONFIG';
const FAIL_LOAD_CUSTOMER_CONFIG = 'vendors/esriIntegrationConfiguration/FAIL_LOAD_CUSTOMER_CONFIG';
const START_LOAD_ROUTE_CONFIG = 'vendors/esriIntegrationConfiguration/START_LOAD_ROUTE_CONFIG';
const COMPLETE_LOAD_ROUTE_CONFIG = 'vendors/esriIntegrationConfiguration/COMPLETE_LOAD_ROUTE_CONFIG';
const FAIL_LOAD_ROUTE_CONFIG = 'vendors/esriIntegrationConfiguration/FAIL_LOAD_ROUTE_CONFIG';
const START_LOAD_SERVICE_CONFIG = 'vendors/esriIntegrationConfiguration/START_LOAD_SERVICE_CONFIG';
const COMPLETE_LOAD_SERVICE_CONFIG = 'vendors/esriIntegrationConfiguration/COMPLETE_LOAD_SERVICE_CONFIG';
const FAIL_LOAD_SERVICE_CONFIG = 'vendors/esriIntegrationConfiguration/FAIL_LOAD_SERVICE_CONFIG';
const START_LOAD_GEO_FENCE_CONFIG = 'vendors/esriIntegrationConfiguration/START_LOAD_GEO_FENCE_CONFIG';
const COMPLETE_LOAD_GEO_FENCE_CONFIG = 'vendors/esriIntegrationConfiguration/COMPLETE_LOAD_GEO_FENCE_CONFIG';
const FAIL_LOAD_GEO_FENCE_CONFIG = 'vendors/esriIntegrationConfiguration/FAIL_LOAD_GEO_FENCE_CONFIG';

const START_SAVE = 'vendors/esriIntegrationConfiguration/START_SAVE';
const COMPLETE_SAVE = 'vendors/esriIntegrationConfiguration/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/esriIntegrationConfiguration/FAIL_SAVE';
const START_SAVE_CUSTOMER_CONFIG = 'vendors/esriIntegrationConfiguration/START_SAVE_CUSTOMER_CONFIG';
const COMPLETE_SAVE_CUSTOMER_CONFIG = 'vendors/esriIntegrationConfiguration/COMPLETE_SAVE_CUSTOMER_CONFIG';
const FAIL_SAVE_CUSTOMER_CONFIG = 'vendors/esriIntegrationConfiguration/FAIL_SAVE_CUSTOMER_CONFIG';
const START_SAVE_ROUTE_CONFIG = 'vendors/esriIntegrationConfiguration/START_SAVE_ROUTE_CONFIG';
const COMPLETE_SAVE_ROUTE_CONFIG = 'vendors/esriIntegrationConfiguration/COMPLETE_SAVE_ROUTE_CONFIG';
const FAIL_SAVE_ROUTE_CONFIG = 'vendors/esriIntegrationConfiguration/FAIL_SAVE_ROUTE_CONFIG';
const START_SAVE_SERVICE_CONFIG = 'vendors/esriIntegrationConfiguration/START_SAVE_SERVICE_CONFIG';
const COMPLETE_SAVE_SERVICE_CONFIG = 'vendors/esriIntegrationConfiguration/COMPLETE_SAVE_SERVICE_CONFIG';
const FAIL_SAVE_SERVICE_CONFIG = 'vendors/esriIntegrationConfiguration/FAIL_SAVE_SERVICE_CONFIG';
const START_VALIDATION = 'vendors/esriIntegrationConfiguration/START_VALIDATION';
const COMPLETE_VALIDATION = 'vendors/esriIntegrationConfiguration/COMPLETE_VALIDATION';
const FAIL_VALIDATION = 'vendors/esriIntegrationConfiguration/FAIL_VALIDATION';

// Initial state
const initialState = {
  isLoadingCredentials: false,
  isLoadingCustomerConfig: false,
  isLoadingGeoFenceConfig: false,
  isLoadingRouteConfig: false,
  isLoadingServiceConfig: false,
  isSavingCredentials: false,
  isSavingCustomerConfig: false,
  isSavingRouteConfig: false,
  isSavingServiceConfig: false,
  isValidatingCredentials: false,
  esriCredentials: {} as EsriCredentials,
  esriCustomerConfig: {} as EsriConfig,
  esriGeoFenceConfig: {} as EsriConfig,
  esriRouteConfig: {} as EsriConfig,
  esriServiceConfig: {} as EsriConfig,
  esriConfigValidation: {} as EsriSyncValidation,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CREDENTIALS:
      return update(state, {
        $merge: {
          isLoadingCredentials: true,
        },
      });

    case COMPLETE_LOAD_CREDENTIALS:
      return update(state, {
        $merge: {
          isLoadingCredentials: false,
          esriCredentials: action.esriCredentials,
        },
      });

    case FAIL_LOAD_CREDENTIALS:
      return update(state, {
        $merge: {
          isLoadingCredentials: false,
        },
      });

    case START_LOAD_CUSTOMER_CONFIG:
      return update(state, {
        $merge: {
          isLoadingCustomerConfig: true,
        },
      });

    case COMPLETE_LOAD_CUSTOMER_CONFIG:
      return update(state, {
        $merge: {
          isLoadingCustomerConfig: false,
          esriCustomerConfig: action.esriCustomerConfig,
        },
      });

    case FAIL_LOAD_CUSTOMER_CONFIG:
      return update(state, {
        $merge: {
          isLoadingCustomerConfig: false,
        },
      });

    case START_LOAD_SERVICE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingServiceConfig: true,
        },
      });

    case COMPLETE_LOAD_SERVICE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingServiceConfig: false,
          esriServiceConfig: action.esriServiceConfig,
        },
      });

    case FAIL_LOAD_SERVICE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingServiceConfig: false,
        },
      });

    case START_LOAD_ROUTE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingRouteConfig: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingRouteConfig: false,
          esriRouteConfig: action.esriRouteConfig,
        },
      });

    case FAIL_LOAD_ROUTE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingRouteConfig: false,
        },
      });

    case START_LOAD_GEO_FENCE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingGeoFenceConfig: true,
        },
      });

    case COMPLETE_LOAD_GEO_FENCE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingGeoFenceConfig: false,
          esriGeoFenceConfig: action.esriGeoFenceConfig,
        },
      });

    case FAIL_LOAD_GEO_FENCE_CONFIG:
      return update(state, {
        $merge: {
          isLoadingGeoFenceConfig: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSavingCredentials: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSavingCredentials: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSavingCredentials: false,
        },
      });

    case START_SAVE_CUSTOMER_CONFIG:
      return update(state, {
        $merge: {
          isSavingCustomerConfig: true,
        },
      });
    case COMPLETE_SAVE_CUSTOMER_CONFIG:
      return update(state, {
        $merge: {
          isSavingCustomerConfig: false,
        },
      });
    case FAIL_SAVE_CUSTOMER_CONFIG:
      return update(state, {
        $merge: {
          isSavingCustomerConfig: false,
        },
      });
    case START_SAVE_SERVICE_CONFIG:
      return update(state, {
        $merge: {
          isSavingServiceConfig: true,
        },
      });
    case COMPLETE_SAVE_SERVICE_CONFIG:
      return update(state, {
        $merge: {
          isSavingServiceConfig: false,
        },
      });
    case START_SAVE_ROUTE_CONFIG:
      return update(state, {
        $merge: {
          isSavingRouteConfig: true,
        },
      });
    case COMPLETE_SAVE_ROUTE_CONFIG:
      return update(state, {
        $merge: {
          isSavingRouteConfig: false,
        },
      });
    case START_VALIDATION:
      return update(state, {
        $merge: {
          isValidatingCredentials: true,
        },
      });
    case COMPLETE_VALIDATION:
      return update(state, {
        $merge: {
          isValidatingCredentials: false,
          esriConfigValidation: action.esriSyncValidation,
        },
      });
    case FAIL_VALIDATION:
      return update(state, {
        $merge: {
          isValidatingCredentials: false,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD_CREDENTIALS,
});

const completeLoad = (esriCredentials: EsriCredentials) => ({
  type: COMPLETE_LOAD_CREDENTIALS,
  esriCredentials,
});

const failLoad = () => ({
  type: FAIL_LOAD_CREDENTIALS,
});

const startLoadCustomerConfig = () => ({
  type: START_LOAD_CUSTOMER_CONFIG,
});

const completeLoadCustomerConfig = (esriCustomerConfig: EsriConfig) => ({
  type: COMPLETE_LOAD_CUSTOMER_CONFIG,
  esriCustomerConfig,
});

const failLoadCustomerConfig = () => ({
  type: FAIL_LOAD_CUSTOMER_CONFIG,
});

const startLoadServiceConfig = () => ({
  type: START_LOAD_SERVICE_CONFIG,
});

const completeLoadServiceConfig = (esriServiceConfig: EsriConfig) => ({
  type: COMPLETE_LOAD_SERVICE_CONFIG,
  esriServiceConfig,
});

const failLoadServiceConfig = () => ({
  type: FAIL_LOAD_SERVICE_CONFIG,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startSaveCustomerConfig = () => ({
  type: START_SAVE_CUSTOMER_CONFIG,
});

const completeSaveCustomerConfig = () => ({
  type: COMPLETE_SAVE_CUSTOMER_CONFIG,
});

const failSaveCustomerConfig = () => ({
  type: FAIL_SAVE_CUSTOMER_CONFIG,
});

const startSaveRouteConfig = () => ({
  type: START_SAVE_ROUTE_CONFIG,
});

const completeSaveRouteConfig = () => ({
  type: COMPLETE_SAVE_ROUTE_CONFIG,
});

const failSaveRouteConfig = () => ({
  type: FAIL_SAVE_ROUTE_CONFIG,
});

const startSaveServiceConfig = () => ({
  type: START_SAVE_SERVICE_CONFIG,
});

const completeSaveServiceConfig = () => ({
  type: COMPLETE_SAVE_SERVICE_CONFIG,
});

const failSaveServiceConfig = () => ({
  type: FAIL_SAVE_SERVICE_CONFIG,
});

const startLoadRouteConfig = () => ({
  type: START_LOAD_ROUTE_CONFIG,
});

const completeLoadRouteConfig = (esriRouteConfig: EsriConfig) => ({
  type: COMPLETE_LOAD_ROUTE_CONFIG,
  esriRouteConfig,
});

const failLoadRouteConfig = () => ({
  type: FAIL_LOAD_ROUTE_CONFIG,
});

const startLoadGeoFenceConfig = () => ({
  type: START_LOAD_GEO_FENCE_CONFIG,
});

const completeLoadGeoFenceConfig = (esriGeoFenceConfig: EsriConfig) => ({
  type: COMPLETE_LOAD_GEO_FENCE_CONFIG,
  esriGeoFenceConfig,
});

const failLoadGeoFenceConfig = () => ({
  type: FAIL_LOAD_GEO_FENCE_CONFIG,
});

const startValidation = () => ({
  type: START_VALIDATION,
});

const completeValidation = (esriSyncValidation: EsriSyncValidation) => ({
  type: COMPLETE_VALIDATION,
  esriSyncValidation,
});

const failValidation = () => ({
  type: FAIL_VALIDATION,
});

export const loadEsriCredentials = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadEsriCredentialsPromise = doLoadEsriCredentials(vendorId);
  loadEsriCredentialsPromise
    .then(esriCredentials => {
      dispatch(completeLoad(esriCredentials));
    })
    .catch(() => dispatch(failLoad()));

  return loadEsriCredentialsPromise;
};

export const loadEsriCustomerConfig = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadCustomerConfig());

  const loadEsriCustomerConfigPromise = doLoadEsriCustomerConfig(vendorId);
  loadEsriCustomerConfigPromise
    .then(esriCustomerConfig => {
      dispatch(completeLoadCustomerConfig(esriCustomerConfig));
    })
    .catch(() => dispatch(failLoadCustomerConfig()));

  return loadEsriCustomerConfigPromise;
};

export const loadEsriGeoFenceConfig = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadGeoFenceConfig());

  const loadEsriGeoFenceConfigPromise = doLoadEsriGeoFenceConfig(vendorId);
  loadEsriGeoFenceConfigPromise
    .then(esriGeoFenceConfig => {
      dispatch(completeLoadGeoFenceConfig(esriGeoFenceConfig));
    })
    .catch(() => dispatch(failLoadGeoFenceConfig()));
  return loadEsriGeoFenceConfigPromise;
};

export const loadEsriRouteConfig = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteConfig());

  const loadEsriRouteConfigPromise = doLoadEsriRouteConfig(vendorId);
  loadEsriRouteConfigPromise
    .then(esriRouteConfig => {
      dispatch(completeLoadRouteConfig(esriRouteConfig));
    })
    .catch(() => dispatch(failLoadRouteConfig()));

  return loadEsriRouteConfigPromise;
};

export const loadEsriServiceConfig = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadServiceConfig());

  const loadEsriServiceConfigPromise = doLoadEsriServiceConfig(vendorId);
  loadEsriServiceConfigPromise
    .then(esriServiceConfig => {
      dispatch(completeLoadServiceConfig(esriServiceConfig));
    })
    .catch(() => dispatch(failLoadServiceConfig()));

  return loadEsriServiceConfigPromise;
};

export const saveEsriCredentials = (vendorId: number, esriCredentials: EsriCredentials) => (dispatch: Dispatch) => {
  dispatch(startSaveServiceConfig());
  const saveCredentialsPromise = doSaveEsriCredentials(vendorId, esriCredentials);
  saveCredentialsPromise
    .then(() => dispatch(completeSaveServiceConfig()))
    .catch(() => dispatch(failSaveServiceConfig()));
  return saveCredentialsPromise;
};

export const saveEsriCustomerConfig = (vendorId: number, esriCustomerConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startSaveCustomerConfig());
  const saveEsriCustomerConfigPromise = doSaveEsriCustomerConfig(vendorId, esriCustomerConfig);
  saveEsriCustomerConfigPromise
    .then(() => dispatch(completeSaveCustomerConfig()))
    .catch(() => dispatch(failSaveCustomerConfig()));
  return saveEsriCustomerConfigPromise;
};

export const saveEsriGeoFenceConfig = (vendorId: number, esriGeoFenceConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveEsriGeoFenceConfigPromise = doSaveEsriGeoFenceConfig(vendorId, esriGeoFenceConfig);
  saveEsriGeoFenceConfigPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveEsriGeoFenceConfigPromise;
};

export const saveEsriRouteConfig = (vendorId: number, esriRouteConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startSaveRouteConfig());
  const saveEsriRouteConfigPromise = doSaveEsriRouteConfig(vendorId, esriRouteConfig);
  saveEsriRouteConfigPromise
    .then(() => dispatch(completeSaveRouteConfig()))
    .catch(() => dispatch(failSaveRouteConfig()));
  return saveEsriRouteConfigPromise;
};

export const saveEsriServiceConfig = (vendorId: number, esriServiceConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveEsriServiceConfigPromise = doSaveEsriServiceConfig(vendorId, esriServiceConfig);
  saveEsriServiceConfigPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveEsriServiceConfigPromise;
};

export const validateEsriSyncSettings =
  (vendorId: number, esriSyncSettings: EsriSyncSettings) => (dispatch: Dispatch) => {
    dispatch(startValidation());
    const validateEsriSyncSettingsPromise = doValidateEsriSyncSettings(vendorId, esriSyncSettings);
    validateEsriSyncSettingsPromise
      .then(esriSyncValidation => dispatch(completeValidation(esriSyncValidation)))
      .catch(() => dispatch(failValidation()));

    return validateEsriSyncSettingsPromise;
  };

export const validateEsriCredentials = (vendorId: number, esriCredentials: EsriCredentials) => (dispatch: Dispatch) => {
  dispatch(startValidation());
  const validateEsriCredentialsPromise = doValidateEsriCredentials(vendorId, esriCredentials);
  validateEsriCredentialsPromise
    .then(esriCredentialsValidation => dispatch(completeValidation(esriCredentialsValidation)))
    .catch(() => dispatch(failValidation()));

  return validateEsriCredentialsPromise;
};

export const validateEsriCustomerConfig = (vendorId: number, esriConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startValidation());
  const validateEsriCustomerPromise = doValidateEsriCustomerConfig(vendorId, esriConfig);
  validateEsriCustomerPromise
    .then(esriSCustomerValidation => dispatch(completeValidation(esriSCustomerValidation)))
    .catch(() => dispatch(failValidation()));

  return validateEsriCustomerPromise;
};

export const validateEsriRouteConfig = (vendorId: number, esriConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startValidation());
  const validateEsriRouteConfigPromise = doValidateEsriRouteConfig(vendorId, esriConfig);
  validateEsriRouteConfigPromise
    .then((esriServiceValidation: any) => dispatch(completeValidation(esriServiceValidation)))
    .catch(() => dispatch(failValidation()));

  return validateEsriRouteConfigPromise;
};

export const validateEsriServiceConfig = (vendorId: number, esriConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startValidation());
  const validateEsriServiceConfigPromise = doValidateEsriServiceConfig(vendorId, esriConfig);
  validateEsriServiceConfigPromise
    .then((esriServiceValidation: any) => dispatch(completeValidation(esriServiceValidation)))
    .catch(() => dispatch(failValidation()));

  return validateEsriServiceConfigPromise;
};

export const validateEsriGeoFenceConfig = (vendorId: number, esriConfig: EsriConfig) => (dispatch: Dispatch) => {
  dispatch(startValidation());
  const validateEsriGeoFenceConfigPromise = doValidateEsriGeoFenceConfig(vendorId, esriConfig);
  validateEsriGeoFenceConfigPromise
    .then((esriServiceValidation: any) => dispatch(completeValidation(esriServiceValidation)))
    .catch(() => dispatch(failValidation()));

  return validateEsriGeoFenceConfigPromise;
};
