import styled, { css } from 'styled-components';
import { mapper, sizeMapper } from 'src/utils/styles';
import { transparentize } from 'polished';

export const CalendarWrapper = styled.div`
  width: 100%;

  .rc-bg-white {
    background-color: ${props => transparentize(0.93, props.theme.gray)};

    .rc-grid {
      border: 1px solid ${props => transparentize(0.63, props.theme.brandPrimary)};
      border-radius: 4px;
      grid-gap: 0;
      padding: 0;

      > div {
        &[aria-label='Empty Day'] {
        }

        &[aria-label='Day of Week'] {
          background-color: ${props => transparentize(0.93, props.theme.brandPrimary)};
          border: 1px solid ${props => transparentize(0.63, props.theme.brandPrimary)};
          border-radius: 2px;
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }
  }
`;

type MonthlyNavWrapperProps = Partial<{ margin: string }>;

export const MonthlyNavWrapper = styled.div<MonthlyNavWrapperProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no no medium')};
`;

type MonthlyNavLabelProps = Partial<{
  margin: string;
  padding: string;
  size: string;
}>;

export const MonthlyNavLabel = styled.div<MonthlyNavLabelProps>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props =>
    mapper(props.size, {
      xSmall: '4px 10px',
      cSmall: '8px 8px',
      small: '8px 20px',
      xMedium: '8px 12px',
      medium: '8px 30px',
      xLarge: '8px 50px',
    })};
  line-height: ${props =>
    mapper(props.size, { xSmall: '12px', small: '15px', medium: '18px', xLarge: '18px', lMedium: '25px' })};
  font-size: ${props =>
    mapper(props.size, { xSmall: '11px', small: '12px', medium: '14px', xLarge: '18px', xxLarge: '30px' })};
  font-weight: ${props => props.theme.fontWeightLight};
`;

export const MonthlyEventItem = styled.li<Partial<{ padding: string }>>`
  display: block;
  padding: ${props => sizeMapper(props.padding, 'xxSmall no')};

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.gray};
  }
`;

export const MonthlyEventItemPopoverButtonWrapper = styled.div``;

type MonthlyEventHeaderProps = Partial<{
  align: string;
  margin: string;
}>;

export const MonthlyEventHeader = styled.div<MonthlyEventHeaderProps>`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  margin: ${props => sizeMapper(props.margin, 'no')};
  justify-content: ${props =>
    mapper(
      props.align,
      { left: 'flex-start', center: 'center', right: 'flex-end', around: 'space-around', between: 'space-between' },
      'between',
    )};
`;

export const DayNumber = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.brandPrimary};
`;

export const EventsList = styled.ul``;

export const MonthlyDayWrapper = styled.div<
  Partial<{
    height: string;
    highlight: boolean;
    noOverflow: boolean;
    hasContent: boolean;
  }>
>`
  background-color: ${props => props.theme.bodyBackgroundColor};
  border: 1px solid ${props => transparentize(0.63, props.theme.brandPrimary)};
  border-radius: 2px;
  height: ${props => props.height || '12rem'};
  display: flex;
  flex-direction: column;
  padding: ${sizeMapper('small xSmall')};
  overflow-y: ${props => (props.noOverflow ? 'hidden' : 'auto')};
  position: relative;

  ${props =>
    props.highlight &&
    css`
      background-color: ${props => transparentize(0.93, props.theme.brandDefault)};
      border-color: ${props => transparentize(0.7, props.theme.brandInfo)};
      border-width: 2px;
    `};

  &:hover {
    background-color: ${props => transparentize(0.63, props.theme.brandPrimary)};
  }
  ${props =>
    props.hasContent &&
    css`
      &:after {
        content: '...';
        height: 30%;
        width: 100%;
        background: linear-gradient(
          to bottom,
          ${props => transparentize(1, props.theme.bodyBackgroundColor)} 0%,
          ${props => transparentize(0.1, props.theme.bodyBackgroundColor)} 50%,
          ${props => props.theme.bodyBackgroundColor} 100%
        );
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    `};
`;
