import { useSelector, useDispatch } from 'react-redux';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';

import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import AddNewWorkOrderModal from './AddNewWorkOrderModal';
import { loadRubiconServices } from 'src/customers/ducks/rubiconServices';
import { RubiconServicesHistory } from 'src/customers/interfaces/RubiconServices';

interface Props {
  closeModal: () => void;
  setTicketConfirmationValues: (values: any) => void;
  toggleTicketConfirmation: () => void;
  hidden?: boolean;
  serviceId?: number;
  serviceStatus?: string;
  serviceHistory?: RubiconServicesHistory;
  isRubiconServicesPage: boolean;
}

export default function AddNewWorkOrderModalResolver(props: Props) {
  const {
    closeModal,
    setTicketConfirmationValues,
    toggleTicketConfirmation,
    hidden,
    serviceHistory,
    isRubiconServicesPage,
  } = props;
  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    if (isRubiconServicesPage) return Promise.resolve();

    return Promise.all([
      loadRubiconServices(
        vendorId,
        userId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        10000,
      )(dispatch),
    ]);
  };
  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={AddNewWorkOrderModal}
      successProps={{
        closeModal,
        setTicketConfirmationValues,
        toggleTicketConfirmation,
        serviceHistory,
        isRubiconServicesPage,
        hidden,
      }}
    />
  );
}
