import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import {
  Button,
  Grid,
  GridColumn,
  Message,
  PanelSection,
  PanelSectionSubTitle,
  Popover,
  Text,
} from 'src/core/components/styled';
import { NEW_SERVICE_ID } from 'src/customers/constants/serviceStatuses';
import { PopoverWrapper, UnconnectedDropdown } from 'src/core/components';
import { RATE_STATUSES_LIST } from 'src/customers/constants/rateStatuses';
import { Rate, RateStatus } from 'src/customers/interfaces/Services';
import { RateCodeFormValues } from '../../modals/RateCodeModal/RateCodeModal';
import { SERVICE_DETAILS_EDITOR_FORM } from '../../forms/ServiceDetailsEditorForm';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants/table';
import { useSelector } from 'src/core/hooks/useSelector';
import RateCodeModalResolver from '../../modals/RateCodeModal/RateCodeModalResolver';
import RatesTableRow from './RatesTableRow';
import Table, { TableCell as TableCellInterface } from 'src/core/components/Table';
import translate from 'src/core/services/translate';

const getNewRate = (): Rate => ({
  isManual: false,
  overridable: false,
  rate: 0,
  id: NEW_SERVICE_ID,
  includedQty: 0,
  status: 'active',
  markupTypeId: 1,
});

const ratesTableCellWidths = {
  rateTypeId: '14%',
  overridable: '13%',
  quantity: '12%',
  uomId: '10%',
  rate: '12%',
  status: '10%',
  effectiveFromDate: '14%',
  effectiveToDate: '15%',
};

const ratesTableCells: TableCellInterface[] = [
  {
    name: 'rateCodeId',
    label: translate('finance.rateManager.rateCodeAndDescription'),
    width: ratesTableCellWidths.rateTypeId,
    sortable: true,
  },
  {
    name: 'overridable',
    label: translate('customers.serviceEditor.allowEditsOnBill'),
    width: ratesTableCellWidths.overridable,
    sortable: false,
    noPaddingRight: true,
  },
  {
    name: 'rate',
    label: translate('customers.serviceEditor.rate'),
    width: ratesTableCellWidths.rate,
    sortable: true,
  },
  {
    name: 'uomId',
    label: translate('common.unitOfMeasure'),
    width: ratesTableCellWidths.uomId,
    sortable: true,
  },
  {
    name: 'includedQty',
    label: translate('customers.serviceEditor.includedQuantity'),
    width: ratesTableCellWidths.quantity,
    sortable: true,
  },

  {
    name: 'effectiveFromDate',
    label: translate('customers.serviceEditor.effectiveFrom'),
    width: ratesTableCellWidths.effectiveFromDate,
    sortable: true,
  },
  {
    name: 'effectiveToDate',
    label: translate('customers.serviceEditor.effectiveTo'),
    width: ratesTableCellWidths.effectiveToDate,
    sortable: true,
  },
  {
    name: 'status',
    label: translate('common.status'),
    width: ratesTableCellWidths.status,
    sortable: false,
  },
];

interface Props {
  allowEdit?: boolean;
  isServiceTypeChanged: boolean;
  serviceId: number;
  serviceTypeId?: number;
}

const RatesFeesSection = ({ allowEdit = true, isServiceTypeChanged, serviceId, serviceTypeId }: Props) => {
  const dispatch = useDispatch();

  const rates: Rate[] = useSelector(state => state.customers?.service?.service?.rates) || [];
  const customerName = useSelector(state => state.customers.customer.customer?.name || '');
  const customerLocation = useSelector(state => state.customers.location.location?.name || '');

  const [statusFilter, setStatusFilter] = useState<RateStatus | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isRateUpdated, setIsRateUpdated] = useState<boolean>(false);
  const [allRates, setAllRates] = useState<Rate[]>(rates);

  useEffect(() => {
    if (!!serviceTypeId && isServiceTypeChanged && serviceId === NEW_SERVICE_ID) {
      setIsRateUpdated(false);
      setAllRates([]);
      dispatch(change(SERVICE_DETAILS_EDITOR_FORM, 'rates', []));
    }
  }, [dispatch, serviceTypeId, isServiceTypeChanged, serviceId]);

  const filteredRates = statusFilter ? allRates?.filter(rate => rate.status === statusFilter) : allRates;

  const handleModalClose = () => setModalOpen(false);

  const onSubmitRateCodeForm = (data: RateCodeFormValues) => {
    dispatch(change(SERVICE_DETAILS_EDITOR_FORM, 'rates', data.rates));
    setIsRateUpdated(true);
    setAllRates(data.rates);
    handleModalClose();
  };

  const statusesOptions = RATE_STATUSES_LIST.map(({ name, technicalName }) => ({
    label: name,
    value: technicalName,
  }));

  return (
    <>
      <PanelSection padding="small small medium sMedium" fluid withBorder>
        <Grid padding="no" multiLine>
          <GridColumn size="12/12" margin="no ">
            <Grid padding="no">
              <GridColumn size="3/12" padding="no" verticalAlign="center">
                <PanelSectionSubTitle margin="no">
                  {translate('customers.serviceEditor.ratesFees')}
                </PanelSectionSubTitle>
              </GridColumn>
              {allRates.length ? (
                <GridColumn size="2/12" padding="no">
                  <UnconnectedDropdown
                    placeholder={translate('common.status')}
                    value={statusFilter}
                    options={statusesOptions}
                    margin="no"
                    onChange={value => {
                      setStatusFilter(value as RateStatus);
                    }}
                    isClearable={true}
                  />
                </GridColumn>
              ) : null}
              <GridColumn size={allRates.length ? '7/12' : '9/12'} padding="no" align="right">
                {allowEdit && (
                  <PopoverWrapper
                    triggerButton={
                      <Button
                        type="button"
                        line
                        color="primary"
                        margin="no xxSmall no small"
                        id="add-rate-button"
                        onClick={() => setModalOpen(true)}
                        disabled={!serviceTypeId}
                      >
                        {translate('customers.serviceEditor.addEdit')}
                      </Button>
                    }
                    popoverContent={
                      !serviceTypeId && (
                        <Popover>
                          <Text block weight="medium" margin="xxSmall no xxSmall">
                            {translate('finance.rateManager.alertMessages.selectServiceType')}
                          </Text>
                        </Popover>
                      )
                    }
                    size="large"
                  />
                )}
              </GridColumn>
            </Grid>
          </GridColumn>

          {isRateUpdated && (
            <GridColumn size="12/12" padding="sMedium xSmall xSmall xSmall">
              <Text color="warning" weight="medium">
                <em>{translate('customers.serviceEditor.saveRatesMessage')}</em>
              </Text>
            </GridColumn>
          )}

          <GridColumn size="12/12" padding="sMedium xSmall no xSmall">
            {filteredRates.length ? (
              <Table
                cells={ratesTableCells}
                rowComponent={RatesTableRow}
                rows={filteredRates}
                rowProps={{
                  cellWidths: ratesTableCellWidths,
                }}
                withClickableRows
                scrollMarker
                virtualized
                virtualizedProps={{
                  height: Math.min(filteredRates.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
                  itemSize: TABLE_ROW_HEIGHT_SMALL,
                }}
              />
            ) : (
              <Message padding="no xSmall sMedium no">{translate('customers.serviceEditor.noRatesFound')}</Message>
            )}
          </GridColumn>
        </Grid>
      </PanelSection>

      {modalOpen && (
        <RateCodeModalResolver
          getNewRate={getNewRate}
          closeModal={handleModalClose}
          formProps={{
            customerName,
            location: customerLocation,
          }}
          onSubmit={onSubmitRateCodeForm}
          initialValues={{
            rates: allRates && allRates.length ? allRates : [getNewRate()],
          }}
          serviceTypeId={serviceTypeId}
        />
      )}
    </>
  );
};

export default RatesFeesSection;
