import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../../../contracts/ducks';
import { loadWaterFills } from '../../../../ducks';
import { WaterFillModal } from '.';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { Resolver } from '../../../../../core/components';

interface WaterFillModalResolverProps {
  closeModal: () => void;
  loadWaterFills: DuckFunction<typeof loadWaterFills>;
  modalTitle?: string;
  routeId: number;
}

class WaterFillModalResolver extends PureComponent<WaterFillModalResolverProps> {
  loadDependencies = () => {
    const { routeId, loadWaterFills } = this.props;
    return Promise.all([loadWaterFills(routeId)]);
  };

  render() {
    const { modalTitle, closeModal } = this.props;
    return (
      <Resolver
        successComponent={WaterFillModal}
        successProps={{
          modalTitle,
          closeModal,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = { loadWaterFills };

export default connect(undefined, mapDispatchToProps)(WaterFillModalResolver);
