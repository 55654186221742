import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { NavigationForm } from '../forms';
import { saveNavigationSettings } from '../../ducks/navigationSettings';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';
import { NavigationSettings } from 'src/vendors/interfaces/NavigationSettings';

interface Props {
  closeModal: (pristine: boolean) => void;
  saveNavigationSettings: (vendorId: number, navigationSettings: NavigationSettings[]) => Promise<any>;
  vendorId?: number;
}

class NavigationModal extends PureComponent<Props> {
  onSubmit = (navigationSettings: NavigationSettings[]) => {
    const { vendorId, saveNavigationSettings, closeModal } = this.props;

    saveNavigationSettings(vendorId as number, navigationSettings as NavigationSettings[])
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.navigationSettingsSaved'));
        closeModal(true);
      })
      .catch(({ code }) =>
        createErrorNotification(
          code
            ? translate(createTranslationKey(code, 'vendors.alertMessages'))
            : translate('vendors.alertMessages.navigationSettingsError'),
        ),
      );
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Modal padding="no" size="large">
        <ModalSection padding="medium no no">
          <ModalTitle>{translate('vendors.featureCodes.navigation')}</ModalTitle>
        </ModalSection>
        <ModalSection>
          <NavigationForm onCancel={closeModal} onSubmit={this.onSubmit} />
        </ModalSection>
      </Modal>
    );
  }
}

const mapDispatchToProps = { saveNavigationSettings };

export default connect(null, mapDispatchToProps)(NavigationModal);
