import { http } from '../../core/services/http';
import {
  transformLoadInsightDashboardPreferences,
  transformSaveInsightDashboardPreferences,
} from './transformInsightDashboardPreferences';

export const loadInsightDashboardPreferences = (vendorId: number, insightGroupIds: any[] = []) =>
  http
    .get(`/vendors/${vendorId}/userDashboardPreferences?insightGroupIds=${insightGroupIds}`)
    .then(response => transformLoadInsightDashboardPreferences(response.data));

export const updateInsightDashboardPreferences = (
  vendorId: number,
  dashboardPreferences: any[],
  daysOfOperation: any[],
) =>
  http
    .put(`/vendors/${vendorId}/userDashboardPreferences`, {
      userDashboardPreferences: transformSaveInsightDashboardPreferences(dashboardPreferences),
      daysOfOperation,
    })
    .then(response => response.data);
