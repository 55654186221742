import { httpInsightsReporting } from '../../core/services/http';
import transformLoadRoutes from './transformLoadRoutes';

const loadRoutes = (
  vendorId: number,
  reportType: string,
  vehicleType: string,
  vehicleName: string,
  routeName: string,
  driverName: string,
  startDate: string,
  endDate: string,
  vehicleTypeIdsCSV?: string,
  serviceZoneIdsCSV?: string,
  supervisorIdsCSV?: string,
) =>
  httpInsightsReporting
    .post('/reporting/report/routes', {
      vendorId,
      reportType,
      routeName,
      startDate,
      endDate,
      vehicleType: vehicleType && vehicleType.toString(),
      vehicleName: vehicleName && vehicleName.toString(),
      driverName: driverName && driverName.toString(),
      vehicleTypeIdsCSV,
      serviceZoneIdsCSV,
      supervisorIdsCSV,
    })
    .then(response => transformLoadRoutes(response.data));

export default loadRoutes;
