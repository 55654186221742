import update from 'immutability-helper';
import { identity, reduce } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { LIMIT_PER_PAGE } from '../constants';
import {
  loadOnCallServices as doLoadOnCallServices,
  loadOnCallServicesByVendorId as doLoadOnCallServicesByVendorId,
} from '../services/loadOnCallServices';
import {
  loadRecurringServicesIssueType as doLoadOnCallServicesIssueType,
  updateAcceptSelectedPaymentServices as doUpdateAcceptSelectedPaymentServices,
  updateIssueReportRecurringServices as doUpdateIssueReportOnCallServices,
  updateIssueReportRecurringServicesByVendorId as doUpdateIssueReportOnCallServicesByVendorId,
  updateRecurringServices as doUpdateOnCallServices,
  updateRecurringServicesByVendorId as doUpdateOnCallServicesByVendorId,
  updateUndoAcceptRecurringServices as doUpdateUndoAcceptOnCallServices,
  updateUndoAcceptRecurringServicesByVendorId as doUpdateUndoAcceptOnCallServicesByVendorId,
  updateUndoReportRecurringServices as doUpdateUndoReportOnCallServices,
  updateUndoReportRecurringServicesByVendorId as doUpdateUndoReportOnCallServicesByVendorId,
} from '../services/loadRecurringServices';

//  Actions
const START_LOAD = 'payments/onCallServices/START_LOAD';
const COMPLETE_LOAD = 'payments/onCallServices/COMPLETE_LOAD';
const FAIL_LOAD = 'payments/onCallServices/FAIL_LOAD';
const FAIL_SAVE = 'payments/onCallServices/SAVE_FAIL';
const START_SAVE = 'payments/onCallServices/START_SAVE';
const COMPLETE_SAVE = 'payments/onCallServices/COMPLETE_SAVE';
const RESET = 'payments/onCallServices/RESET';

const START_TYPE_LOAD = 'payments/issueType/START_TYPE_LOAD';
const COMPLETE_TYPE_LOAD = 'payments/issueType/COMPLETE_TYPE_LOAD';
const FAIL_TYPE_LOAD = 'payments/issueType/FAIL_TYPE_LOAD';

//  Initial State
const initialState = {
  isLoading: false,
  isSaving: false,
  workOrdersInfo: undefined,
  pricingInfo: undefined,
  paymentInfo: undefined,
  haulerName: undefined,
  limit: undefined,
  page: undefined,
  total: undefined,
  // activitiBaseUrl: undefined,
  readonly: undefined,
  errorMessage: undefined,
  issueType: undefined,
};

//  Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          limit: action.onCallServices.limit,
          page: action.onCallServices.page,
          total: action.onCallServices.total,
          workOrdersInfo: action.onCallServices.workOrdersInfo,
          pricingInfo: action.onCallServices.pricingInfo,
          paymentInfo: action.onCallServices.paymentInfo,
          haulerName: action.onCallServices.haulerName,
          // activitiBaseUrl: action.onCallServices.activitiBaseUrl,
          readonly: action.onCallServices.readonly,
          errorMessage: action.onCallServices.errorMessage,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          limit: undefined,
          page: undefined,
          total: undefined,
          workOrdersInfo: undefined,
          pricingInfo: undefined,
          paymentInfo: undefined,
          haulerName: undefined,
          // activitiBaseUrl: undefined,
          readonly: undefined,
          errorMessage: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          limit: action.onCallServices.limit,
          page: action.onCallServices.page,
          total: action.onCallServices.total,
          workOrdersInfo: action.onCallServices.workOrdersInfo,
          pricingInfo: action.onCallServices.pricingInfo,
          paymentInfo: action.onCallServices.paymentInfo,
          haulerName: action.onCallServices.haulerName,
          // activitiBaseUrl: action.onCallServices.activitiBaseUrl,
          readonly: action.onCallServices.readonly,
          errorMessage: action.onCallServices.errorMessage,
        },
      });

    case START_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          issueType: action.issueType,
        },
      });

    case FAIL_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          issueType: action.issueType,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (onCallServices: any) => ({
  type: COMPLETE_LOAD,
  onCallServices,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (onCallServices: any) => ({
  type: COMPLETE_SAVE,
  onCallServices,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startTypeLoad = () => ({
  type: START_TYPE_LOAD,
});

const completeTypeLoad = (issueType: any) => ({
  type: COMPLETE_TYPE_LOAD,
  issueType,
});

const failTypeLoad = () => ({
  type: FAIL_TYPE_LOAD,
});

export const loadOnCallServicesIssueType = (vendorId: number, token: string) => (dispatch: Dispatch) => {
  dispatch(startTypeLoad());
  if (token) {
    return doLoadOnCallServicesIssueType(token)
      .then((issueType: any) => dispatch(completeTypeLoad(issueType)))
      .catch(() => dispatch(failTypeLoad()));
  }
};

export const loadOnCallServices = (vendorId: number, token: string, date: Date | string, page: number, limit: number) => (
  dispatch: Dispatch,
) => {
  dispatch(startLoad());
  if (token) {
    return doLoadOnCallServices(token, date || '', page || 1, limit || LIMIT_PER_PAGE)
      .then(onCallServices => dispatch(completeLoad(onCallServices)))
      .catch(() => dispatch(failLoad()));
  } else if (vendorId) {
    return doLoadOnCallServicesByVendorId(vendorId)
      .then(onCallServices => dispatch(completeLoad(onCallServices)))
      .catch(() => dispatch(failLoad()));
  }
};

export const updateOnCallServices = (
  requestOnCallServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateOnCallServices(requestOnCallServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateOnCallServicesByVendorId(requestOnCallServicesObj, vendorId)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateIssueReportOnCallServices = (
  requestOnCallServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateIssueReportOnCallServices(requestOnCallServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateIssueReportOnCallServicesByVendorId(requestOnCallServicesObj, vendorId)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateUndoReportOnCallServices = (
  requestOnCallServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateUndoReportOnCallServices(requestOnCallServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateUndoReportOnCallServicesByVendorId(requestOnCallServicesObj, vendorId)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateUndoAcceptOnCallServices = (
  requestOnCallServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateUndoAcceptOnCallServices(requestOnCallServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateUndoAcceptOnCallServicesByVendorId(requestOnCallServicesObj, vendorId)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateAcceptSelectedOnCallPaymentServices = (
  requestOnCallServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateAcceptSelectedPaymentServices(requestOnCallServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(onCallServices => dispatch(completeSave(onCallServices)))
      .catch(() => dispatch(failSave()));
  }
};

// Selectors
const getVendorPaymentMethodTypes = (paymentMethodStates: any, paymentMethodId: any) => {
  const vendorPaymentMethodTypes = reduce(
    paymentMethodStates,
    (paymentInfo, paymentMethodState) => {
      if (paymentMethodState.paymentMethod === paymentMethodId) {
        paymentInfo.push(paymentMethodState);
      }
      return paymentInfo;
    },
    [] as any[],
  );
  return vendorPaymentMethodTypes;
};

export const vendorPaymentMethodTypes = createSelector(getVendorPaymentMethodTypes, identity);

export const resetOnCallService = () => ({
  type: RESET,
});
