import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { AppState } from '../../store';
import { Dropdown } from '../../core/components';
import { DropDownFieldProps } from './DropDownFieldProps';
import translate from '../../core/services/translate';

interface ServiceType {
  name: string;
  id: number;
}

interface Props extends DropDownFieldProps {
  serviceTypes: ServiceType[];
}

class ServiceTypeDropdown extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      serviceTypes,
      dropdownProps,
    } = this.props;

    const serviceTypeOptions = serviceTypes.map(serviceType => ({
      label: serviceType.name,
      value: serviceType.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.serviceType') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.serviceType') : undefined)}
        component={Dropdown as any}
        options={serviceTypeOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  serviceTypes: state.common.serviceTypes.serviceTypes as any as ServiceType[],
});

export default connect(mapStateToProps)(ServiceTypeDropdown);
