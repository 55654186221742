import { filter, maxBy } from 'lodash-es';

import { getFeatureCollection, getLineStringFeature } from 'src/common/components/map/util';
import { MODERATE, SIMPLE } from 'src/common/constants/drivingComplexity';
import { ROUTE_SEGMENT_LANE_COLORS } from 'src/customers/components/pages/streetNetwork/utils';
import {
  AlternativeFleetStreetSegment,
  AlternativeFleetStreetSegmentFeature,
} from 'src/dashboard/interfaces/alterativeFleetOps';
import { getLastPassType, getLastPassValue } from 'src/dashboard/utils/alternativeFleet';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import store from 'src/store';
import { SegmentConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';

export const getSegmentCenter = (segment: number[][]) => segment[Math.floor(segment.length / 2)];

export const parseSegmentJSON = (lineSegment: string): number[][] => JSON.parse(lineSegment);

const getSegmentColorBasedOnLastPass = (vehicleTypeId: number, lastPassDateTime?: string) => {
  const segmentConfiguration = filter(
    vehicleTypeId === SNOW_PLOW_ID
      ? store.getState().vendors.snowPlowSettings.snowPlowSettings.displayConfiguration.segmentConfiguration
      : store.getState().vendors.streetSweepingSettings.streetSweepingSettings.segmentColorSettings,
    (configuration: SegmentConfiguration) => !!configuration.enabled,
  ) as SegmentConfiguration[];

  const { red, green, blue } = maxBy(
    segmentConfiguration,
    (configuration: SegmentConfiguration) => configuration.streetSegmentAgingInterval.minValue,
  ) as SegmentConfiguration;

  const defaultSegmentColor = `rgb(${red}, ${green}, ${blue})`;

  if (!lastPassDateTime) {
    return defaultSegmentColor;
  }

  const lastPassInHours = getLastPassType(lastPassDateTime, 'hours');
  const lastPassInDays = getLastPassType(lastPassDateTime, 'days');
  const lastPassInWeeks = getLastPassType(lastPassDateTime, 'weeks');
  const lastPassInMonths = getLastPassType(lastPassDateTime, 'months');

  let segmentColor: string | number[] = defaultSegmentColor;

  segmentConfiguration.forEach((segment: SegmentConfiguration) => {
    const {
      red,
      green,
      blue,
      streetSegmentAgingInterval: {
        minValue,
        maxValue,
        timeMeasurementType: { id: timeMeasurementTypeId },
      },
    } = segment;

    const lastPassValue = getLastPassValue(
      timeMeasurementTypeId,
      lastPassInHours,
      lastPassInDays,
      lastPassInWeeks,
      lastPassInMonths,
    );

    if (maxValue) {
      if (lastPassValue >= minValue && lastPassValue < maxValue) {
        segmentColor = `rgb(${red}, ${green}, ${blue})`;
      }
    } else if (lastPassValue >= minValue) {
      segmentColor = `rgb(${red}, ${green}, ${blue})`;
    }
  });

  return segmentColor;
};

const getSegmentColorBasedOnStatus = (segment: AlternativeFleetStreetSegment, status?: number) => {
  switch (status) {
    case COMPLETED:
      return ROUTE_SEGMENT_LANE_COLORS.green;
    case SCHEDULED:
      return ROUTE_SEGMENT_LANE_COLORS.blue;
    case IN_PROGRESS:
      return ROUTE_SEGMENT_LANE_COLORS.yellow;
    default:
      return ROUTE_SEGMENT_LANE_COLORS.blue;
  }
};

export const calculateIsDriverExperienceSimple = (vehicleTypeId?: number) => {
  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;

  const snowSettings = store.getState().vendors.snowPlowSettings.snowPlowSettings;
  const sweeperSettings = store.getState().vendors.streetSweepingSettings.streetSweepingSettings;
  return (
    (isSnowPlowRoute !== undefined && isSnowPlowRoute ? snowSettings : sweeperSettings)?.drivingComplexityType?.id ===
    SIMPLE
  );
};

export const calculateIsDriverExperienceModerate = (vehicleTypeId?: number) => {
  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;

  const snowSettings = store.getState().vendors.snowPlowSettings.snowPlowSettings;
  const sweeperSettings = store.getState().vendors.streetSweepingSettings.streetSweepingSettings;
  return (
    (isSnowPlowRoute !== undefined && isSnowPlowRoute ? snowSettings : sweeperSettings)?.drivingComplexityType?.id ===
    MODERATE
  );
};

export const getDashboardSnowSweeperStreetSegmentsGeoJSON = (
  segments: AlternativeFleetStreetSegment[],
  vehicleTypeId: number,
  isLastActivity = true,
) => {
  const isDriverExperienceSimple = calculateIsDriverExperienceSimple(vehicleTypeId);
  const isDriverExperienceModerate = calculateIsDriverExperienceModerate(vehicleTypeId);

  return getFeatureCollection<GeoJSON.LineString, AlternativeFleetStreetSegmentFeature>(
    segments.map(segment => {
      const parsedSegment = parseSegmentJSON(segment.lineSegment);
      const twoWayOffset = 5;

      const hasTwoLanes = (!segment.isOneWay || !segment?.reverseStatusId) && !isDriverExperienceSimple;

      const forwardStatusId = getForwardStatusId(
        segment.forwardStatusId,
        segment.reverseStatusId,
        isDriverExperienceSimple,
        isDriverExperienceModerate,
      );

      const reverseStatusId = getReverseStatusId(
        segment.forwardStatusId,
        segment.reverseStatusId,
        isDriverExperienceSimple,
        isDriverExperienceModerate,
      );

      const lineStringFeature = getLineStringFeature(segment.streetSegmentId, parsedSegment, {
        id: segment.streetSegmentId,
        clickable: true,
        segmentCenter: getSegmentCenter(parsedSegment),
        hasForwardPass: true,
        hasReversePass: hasTwoLanes,
        color: isLastActivity
          ? getSegmentColorBasedOnLastPass(vehicleTypeId, segment.lastForwardPassDateTime)
          : getSegmentColorBasedOnStatus(segment, forwardStatusId),
        reverseSegmentColor: isLastActivity
          ? getSegmentColorBasedOnLastPass(vehicleTypeId, segment.lastReversePassDateTime)
          : getSegmentColorBasedOnStatus(segment, reverseStatusId),
        opacity: 0.65,
        lineOffset: !hasTwoLanes ? 0 : twoWayOffset,
        lineOffsetNegative: !hasTwoLanes ? 0 : -twoWayOffset,
      });

      return lineStringFeature;
    }),
  );
};

export const getForwardStatusId = (
  forwardStatusId: number,
  reverseStatusId: number,
  isDriverExperienceSimple: boolean,
  isDriverExperienceModerate: boolean,
) => {
  if (isDriverExperienceSimple) {
    return forwardStatusId === SCHEDULED
      ? reverseStatusId === IN_PROGRESS
        ? COMPLETED
        : reverseStatusId
      : forwardStatusId === IN_PROGRESS
      ? COMPLETED
      : forwardStatusId;
  }

  if (isDriverExperienceModerate) {
    return forwardStatusId === IN_PROGRESS ? COMPLETED : forwardStatusId;
  }

  return forwardStatusId;
};

export const getReverseStatusId = (
  forwardStatusId: number,
  reverseStatusId: number,
  isDriverExperienceSimple: boolean,
  isDriverExperienceModerate: boolean,
) => {
  if (isDriverExperienceSimple) {
    return reverseStatusId === SCHEDULED
      ? forwardStatusId === IN_PROGRESS
        ? COMPLETED
        : forwardStatusId
      : reverseStatusId === IN_PROGRESS
      ? COMPLETED
      : reverseStatusId;
  }

  if (isDriverExperienceModerate) {
    return reverseStatusId === IN_PROGRESS ? COMPLETED : reverseStatusId;
  }

  return reverseStatusId;
};
