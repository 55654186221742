import React from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import {
  TableCell,
  Text,
  TableActionButton,
  Switch as SwitchContainer,
  SwitchBar,
  SwitchHandle,
} from '../../../core/components/styled';
import { ActionButtonTooltip } from '../../../core/components';
import { WEEKDAYS_STARTING_SUNDAY } from '../../../core/constants/weekdays';
import translate from '../../../core/services/translate';
import { Holiday } from '../../interfaces/HolidayPlanner';
import { HolidayPlannerTableRow, HolidayPlannerSpinner } from '../styled/HolidayPlanner';
import * as Ducks from '../../ducks/holidayPlanner';
import { AppState } from '../../../store';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import * as Validations from '../../utils/holidayPlannerValidations';
import confirm from '../../../core/services/confirm';

interface Props {
  holiday: Holiday;
  toggleEditMode(): void;
  setCurrentErrorCode(error?: string): void;
  setCurrentNumberOfDaysIsNull(isNull: boolean): void;
}

const HolidayPlannerModalTableRowView: React.FC<Props> = ({
  holiday,
  toggleEditMode,
  setCurrentErrorCode,
  setCurrentNumberOfDaysIsNull,
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  let recurrenceName = '–';
  let recurrenceDay = '–';
  let recurrenceMonth = '–';
  let holidayDate = '–';
  let serviceDate = '–';

  if (holiday.isRecurrent) {
    if (holiday.recurrenceDayType) {
      recurrenceName = translate(`routes.holidayPlanner.recurrenceTypes.${holiday.recurrenceDayType.technicalName}`);
    }

    if (typeof holiday.recurrenceDayOfWeek === 'number') {
      const weekday = WEEKDAYS_STARTING_SUNDAY.find(day => day.id === holiday.recurrenceDayOfWeek);

      if (weekday) {
        recurrenceDay = weekday.name;
      }
    }

    if (holiday.recurrenceMonthOfYear) {
      recurrenceMonth = moment(holiday.recurrenceMonthOfYear, 'M').format('MMMM');
    }
  }

  if (holiday.calculatedHolidayDate) {
    holidayDate = moment(holiday.calculatedHolidayDate, 'MM/DD/YYYY').format('MMMM Do');
  }

  if (holiday.calculatedPostponeDate) {
    serviceDate = moment(holiday.calculatedPostponeDate, 'MM/DD/YYYY').format('MMMM Do, YYYY');
  }

  const deleteHoliday = React.useCallback(async () => {
    if (!vendorId || !holiday.id) {
      return;
    }

    if (!(await confirm(translate('routes.holidayPlanner.confirmHolidayDelete', { holiday: holiday.name })))) {
      return;
    }

    Ducks.deleteHoliday(
      vendorId,
      holiday.id,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.holidayPlanner.holidayDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.holidayPlanner.holidayNotDeleted'));
      });
  }, [vendorId, holiday.id, holiday.name, dispatch]);

  const toggleActive = () => {
    if (!vendorId || !holiday.id) {
      return;
    }

    if (!holiday.isActive) {
      if (holiday.isExactPostpone) {
        if (
          !holiday.postponeToDate ||
          !!Validations.isRequired(holiday.postponeToDate) ||
          !!Validations.isFutureDate(holiday.postponeToDate) ||
          !!Validations.isOtherThanHolidayDate(holiday.postponeToDate, holiday)
        ) {
          toggleEditMode();
          dispatch(Ducks.markHolidayForActivation(holiday.id));
          return;
        }
      } else {
        if (
          !holiday.postponementType ||
          !holiday.postponeNumberOfDays ||
          !!Validations.isRequired(holiday.postponementType.id) ||
          !!Validations.isRequired(holiday.postponeNumberOfDays)
        ) {
          toggleEditMode();
          dispatch(Ducks.markHolidayForActivation(holiday.id));

          if (!holiday.postponeNumberOfDays) setCurrentNumberOfDaysIsNull(true);
          return;
        }
      }

      Ducks.saveHoliday(vendorId, { ...holiday, isActive: true })(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.holidayPlanner.holidaySaved'));
          setCurrentErrorCode(undefined);
        })
        .catch(error => {
          toggleEditMode();
          holiday && holiday.id && dispatch(Ducks.markHolidayForActivation(holiday.id));

          const errorCode = error && error.response && error.response.data && error.response.data.code;
          setCurrentErrorCode(errorCode);
        });
      return;
    }

    Ducks.saveHoliday(vendorId, { ...holiday, isActive: false })(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.holidayPlanner.holidaySaved'));
        setCurrentErrorCode(undefined);
      })
      .catch(() => {
        createErrorNotification(translate('routes.holidayPlanner.holidayNotSaved'));
      });
  };

  return (
    <HolidayPlannerTableRow isLoading={holiday.saveInProgress}>
      <TableCell width="5%" padding="no">
        <SwitchContainer margin="no" isActive={holiday.isActive} onClick={toggleActive}>
          <SwitchBar>
            <SwitchHandle />
          </SwitchBar>
        </SwitchContainer>
      </TableCell>

      <TableCell vertical align="center" width="23%" padding="no small no no">
        <Text block weight="light" align="left" size="small">
          {translate('routes.holidayPlanner.holidayName')}
        </Text>
        <Text block>{holiday.name}</Text>
      </TableCell>

      <TableCell vertical align="center" width="10%" padding="no small no no">
        <Text block weight="light" align="left" size="small">
          {translate('routes.holidayPlanner.recurrence')}
        </Text>
        <Text block>{recurrenceName}</Text>
      </TableCell>

      <TableCell vertical align="center" width="10%" padding="no small no no">
        <Text block weight="light" align="left" size="small">
          {translate('routes.holidayPlanner.day')}
        </Text>
        <Text block>{recurrenceDay}</Text>
      </TableCell>

      <TableCell vertical align="center" width="10%" padding="no small no no">
        <Text block weight="light" align="left" size="small">
          {translate('routes.holidayPlanner.month')}
        </Text>
        <Text block>{recurrenceMonth}</Text>
      </TableCell>

      <TableCell vertical align="center" width="18%" padding="no small no no">
        <Text block weight="light" align="left" size="small">
          {translate('routes.holidayPlanner.holidayDate')}
        </Text>
        <Text block>{holidayDate}</Text>
      </TableCell>

      <TableCell vertical align="center" width="18%" padding="no small no no">
        <Text block weight="light" align="left" size="small">
          {translate('common.serviceDate')}
        </Text>
        <Text block>{serviceDate}</Text>
      </TableCell>

      <TableCell width="6%" padding="no" align="right">
        {!holiday.isDefault &&
          (holiday.deleteInProgress ? (
            <HolidayPlannerSpinner />
          ) : (
            <TableActionButton onClick={deleteHoliday}>
              <ActionButtonTooltip iconSize="sMedium" icon="delete" tooltip="delete" />
            </TableActionButton>
          ))}

        <TableActionButton onClick={toggleEditMode}>
          <ActionButtonTooltip iconSize="sMedium" icon="edit" tooltip="edit" />
        </TableActionButton>
      </TableCell>
    </HolidayPlannerTableRow>
  );
};

export default HolidayPlannerModalTableRowView;
