import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { createErrorNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';

import {
  CompletePaymentData,
  CompletePaymentIntentData,
  InvoiceDetails,
  PaymentFeeEstimationData,
  PaymentMethod,
  PaymentMethodData,
  StripePaymentData,
  UIConfigData,
} from 'src/finance/interfaces/PaymentManagement';
import {
  completePayment as doCompletePayment,
  completePaymentIntent as doCompletePaymentIntent,
  getPaymentMethodsByInvoice as doGetPaymentMethodsByInvoice,
  getPaymentMethodsByLocation as doGetPaymentMethodsByLocation,
  getPaymentFeeEstimation as doGetPaymentFeeEstimation,
  getPaymentSetup as doGetPaymentSetup,
  getUIConfig as doGetUIConfig,
  getHasProcessingFee as doGetHasProcessingFee,
  setupPaymentIntent as doSetupPaymentIntent,
  setupPaymentIntentWithLocation as doSetupPaymentIntentWithLocation,
  setupPaymentIntentWithCustomer as doSetupPaymentIntentWithCustomer,
} from 'src/finance/services/paymentManagement';

// Actions
const START_LOAD_INVOICE_DETAILS = 'finance/paymentManagement/START_LOAD_INVOICE_DETAILS';
const COMPLETE_LOAD_INVOICE_DETAILS = 'finance/paymentManagement/COMPLETE_LOAD_INVOICE_DETAILS';
const FAIL_LOAD_INVOICE_DETAILS = 'finance/paymentManagement/FAIL_LOAD_INVOICE_DETAILS';

const START_LOAD_HAS_PROCESSING_FEE = 'finance/paymentManagement/START_LOAD_HAS_PROCESSING_FEE';
const COMPLETE_LOAD_HAS_PROCESSING_FEE = 'finance/paymentManagement/COMPLETE_LOAD_HAS_PROCESSING_FEE';
const FAIL_LOAD_HAS_PROCESSING_FEE = 'finance/paymentManagement/FAIL_LOAD_HAS_PROCESSING_FEE';

const START_LOAD_PAYMENT_ESTIMATION_FEE = 'finance/paymentManagement/START_LOAD_PAYMENT_ESTIMATION_FEE';
const COMPLETE_LOAD_PAYMENT_ESTIMATION_FEE = 'finance/paymentManagement/COMPLETE_LOAD_PAYMENT_ESTIMATION_FEE';
const FAIL_LOAD_PAYMENT_ESTIMATION_FEE = 'finance/paymentManagement/FAIL_LOAD_PAYMENT_ESTIMATION_FEE';

const START_LOAD_PAYMENT_METHODS_BY_INVOICE = 'finance/paymentManagement/START_LOAD_PAYMENT_METHODS_BY_INVOICE';
const COMPLETE_LOAD_PAYMENT_METHODS_BY_INVOICE = 'finance/paymentManagement/COMPLETE_LOAD_PAYMENT_METHODS_BY_INVOICE';
const FAIL_LOAD_PAYMENT_METHODS_BY_INVOICE = 'finance/paymentManagement/FAIL_LOAD_PAYMENT_METHODS_BY_INVOICE';

const START_LOAD_UI_CONFIG = 'finance/paymentManagement/START_LOAD_UI_CONFIG';
const COMPLETE_LOAD_UI_CONFIG = 'finance/paymentManagement/COMPLETE_LOAD_UI_CONFIG';
const FAIL_LOAD_UI_CONFIG = 'finance/paymentManagement/FAIL_LOAD_UI_CONFIG';

const START_LOAD_PAYMENT_METHODS_BY_LOCATION = 'finance/paymentManagement/START_LOAD_PAYMENT_METHODS_BY_LOCATION';
const COMPLETE_LOAD_PAYMENT_METHODS_BY_LOCATION = 'finance/paymentManagement/COMPLETE_LOAD_PAYMENT_METHODS_BY_LOCATION';
const FAIL_LOAD_PAYMENT_METHODS_BY_LOCATION = 'finance/paymentManagement/FAIL_LOAD_PAYMENT_METHODS_BY_LOCATION';

const START_SETUP_PAYMENT_INTENT = 'finance/paymentManagement/START_SETUP_PAYMENT_INTENT';
const COMPLETE_SETUP_PAYMENT_INTENT = 'finance/paymentManagement/COMPLETE_SETUP_PAYMENT_INTENT';
const FAIL_SETUP_PAYMENT_INTENT = 'finance/paymentManagement/FAIL_SETUP_PAYMENT_INTENT';

const START_COMPLETE_SETUP_INTENT = 'finance/paymentManagement/START_COMPLETE_SETUP_INTENT';
const COMPLETE_SETUP_INTENT = 'finance/paymentManagement/COMPLETE_SETUP_INTENT';
const FAIL_COMPLETE_SETUP_INTENT = 'finance/paymentManagement/FAIL_COMPLETE_SETUP_INTENT';

const START_SAVE = 'finance/paymentManagement/START_SAVE';
const COMPLETE_SAVE = 'finance/paymentManagement/COMPLETE_SAVE';
const FAIL_SAVE = 'finance/paymentManagement/FAIL_SAVE';

const RESET_PAYMENT_ESTIMATION_FEE = 'finance/paymentManagement/RESET_PAYMENT_ESTIMATION_FEE'
const RESET = 'finance/paymentManagement/RESET';

interface State {
  alerts?: any[]; // WE don't know the typing yet
  invoiceDetails?: InvoiceDetails;
  invoices?: InvoiceDetails[];
  isLoading: boolean;
  isSaving: boolean;
  hasProcessingFee: boolean;
  paymentMethodsByInvoice: PaymentMethod[];
  paymentMethodsByLocation: PaymentMethodData[];
  paymentFee: PaymentFeeEstimationData;
  stripePaymentData: StripePaymentData;
  uiConfig: UIConfigData;
}

// Initial state
const initialState: State = {
  isLoading: false,
  isSaving: false,
  hasProcessingFee: false,
  paymentMethodsByInvoice: [],
  paymentMethodsByLocation: [],
  paymentFee: {} as PaymentFeeEstimationData,
  stripePaymentData: {} as StripePaymentData,
  uiConfig: {} as UIConfigData,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_INVOICE_DETAILS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_INVOICE_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
          invoiceDetails: action.invoiceDetails,
        },
      });

    case FAIL_LOAD_INVOICE_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
          invoiceDetails: undefined,
        },
      });

    case START_LOAD_PAYMENT_METHODS_BY_INVOICE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_PAYMENT_METHODS_BY_INVOICE:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethodsByInvoice: action.paymentMethodsByInvoice,
        },
      });

    case FAIL_LOAD_PAYMENT_METHODS_BY_INVOICE:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethodsByInvoice: [],
        },
      });
    case START_SETUP_PAYMENT_INTENT:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_SETUP_PAYMENT_INTENT:
      return update(state, {
        $merge: {
          isLoading: false,
          stripePaymentData: action.stripePaymentData,
        },
      });
    case FAIL_SETUP_PAYMENT_INTENT:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case START_LOAD_HAS_PROCESSING_FEE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD_HAS_PROCESSING_FEE:
      return update(state, {
        $merge: {
          isLoading: false,
          hasProcessingFee: action.hasProcessingFee,
        },
      });
    case FAIL_LOAD_HAS_PROCESSING_FEE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case START_LOAD_UI_CONFIG:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD_UI_CONFIG:
      return update(state, {
        $merge: {
          isLoading: false,
          uiConfig: action.uiConfig,
        },
      });
    case FAIL_LOAD_UI_CONFIG:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case START_COMPLETE_SETUP_INTENT:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_SETUP_INTENT:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_COMPLETE_SETUP_INTENT:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_PAYMENT_METHODS_BY_LOCATION:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_PAYMENT_METHODS_BY_LOCATION:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethodsByLocation: action.paymentMethodsByLocation,
        },
      });

    case FAIL_LOAD_PAYMENT_METHODS_BY_LOCATION:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethodsByLocation: action.paymentMethodsByLocation,
        },
      });

    case START_LOAD_PAYMENT_ESTIMATION_FEE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_PAYMENT_ESTIMATION_FEE:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentFee: action.paymentFee,
        },
      });

    case FAIL_LOAD_PAYMENT_ESTIMATION_FEE:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentFee: action.paymentFee,
        },
      });

    case RESET_PAYMENT_ESTIMATION_FEE:
      return update(state, {
        $merge: {
          paymentFee: {} as PaymentFeeEstimationData,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    case RESET:
      return update(state, {
        $set: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoadInvoiceDetails = () => ({
  type: START_LOAD_INVOICE_DETAILS,
});

// revert changecback to InvoiceDetails, ts was breaking
const completeLoadInvoiceDetails = (invoiceDetails: InvoiceDetails) => ({
  type: COMPLETE_LOAD_INVOICE_DETAILS,
  invoiceDetails,
});

const failLoadInvoiceDetails = () => ({
  type: FAIL_LOAD_INVOICE_DETAILS,
});

const startLoadPaymentMethodsByInvoice = () => ({
  type: START_LOAD_PAYMENT_METHODS_BY_INVOICE,
});

const completeLoadPaymentMethodsByInvoice = (paymentMethodsByInvoice: PaymentMethod[]) => ({
  type: COMPLETE_LOAD_PAYMENT_METHODS_BY_INVOICE,
  paymentMethodsByInvoice,
});

const failLoadPaymentMethodsByInvoice = () => ({
  type: FAIL_LOAD_PAYMENT_METHODS_BY_INVOICE,
});

const startLoadPaymentMethodsByLocation = () => ({
  type: START_LOAD_PAYMENT_METHODS_BY_LOCATION,
});

const completeLoadPaymentMethodsByLocation = (paymentMethodsByLocation: PaymentMethodData[]) => ({
  type: COMPLETE_LOAD_PAYMENT_METHODS_BY_LOCATION,
  paymentMethodsByLocation,
});

const failLoadPaymentMethodsByLocation = () => ({
  type: FAIL_LOAD_PAYMENT_METHODS_BY_LOCATION,
});

const startLoadPaymentFeeEstimation = () => ({
  type: START_LOAD_PAYMENT_METHODS_BY_LOCATION,
});

const completeLoadPaymentFeeEstimation = (paymentFee: PaymentFeeEstimationData) => ({
  type: COMPLETE_LOAD_PAYMENT_ESTIMATION_FEE,
  paymentFee,
});

const failLoadPaymentFeeEstimation = () => ({
  type: FAIL_LOAD_PAYMENT_ESTIMATION_FEE,
});

const startLoadHasProcessingFee = () => ({
  type: START_LOAD_HAS_PROCESSING_FEE,
});

const completeLoadHasProcessingFee = (hasProcessingFee: boolean) => ({
  type: COMPLETE_LOAD_HAS_PROCESSING_FEE,
  hasProcessingFee,
});

const failLoadHasProcessingFee = () => ({
  type: FAIL_LOAD_HAS_PROCESSING_FEE,
});

const startLoadUIConfig = () => ({
  type: START_LOAD_UI_CONFIG,
});

const completeLoadUIConfig = (uiConfig: UIConfigData) => ({
  type: COMPLETE_LOAD_UI_CONFIG,
  uiConfig,
});

const failLoadUIConfig = () => ({
  type: FAIL_LOAD_UI_CONFIG,
});

const startSetupPaymentIntent = () => ({
  type: START_SETUP_PAYMENT_INTENT,
});

const completeSetupPaymentIntent = (stripePaymentData: StripePaymentData) => ({
  type: COMPLETE_SETUP_PAYMENT_INTENT,
  stripePaymentData,
});

const failSetupPaymentIntent = () => ({
  type: FAIL_SETUP_PAYMENT_INTENT,
});

const startCompleteSetupIntent = () => ({
  type: START_COMPLETE_SETUP_INTENT,
});

const completeSetupIntent = () => ({
  type: COMPLETE_SETUP_INTENT,
});

const failCompleteSetupIntent = () => ({
  type: FAIL_COMPLETE_SETUP_INTENT,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const resetPaymentEstimationFee = () => ({
  type: RESET_PAYMENT_ESTIMATION_FEE,
})

const resetData = () => ({
  type: RESET,
});

export const getPaymentSetup = (invoiceId: string) => (dispatch: Dispatch) => {
  dispatch(startLoadInvoiceDetails());
  const getPaymentSetupPromise = doGetPaymentSetup(invoiceId);
  getPaymentSetupPromise
    .then((invoiceDetails: InvoiceDetails) => dispatch(completeLoadInvoiceDetails(invoiceDetails)))
    .catch(() => dispatch(failLoadInvoiceDetails()));
  return getPaymentSetupPromise;
};

export const loadPaymentMethodsByInvoice = (invoiceId: string) => (dispatch: Dispatch) => {
  dispatch(startLoadPaymentMethodsByInvoice());
  const loadPaymentMethodsPromise = doGetPaymentMethodsByInvoice(invoiceId);

  loadPaymentMethodsPromise
    .then((paymentMethods: PaymentMethod[]) => dispatch(completeLoadPaymentMethodsByInvoice(paymentMethods)))
    .catch(() => dispatch(failLoadPaymentMethodsByInvoice()));
  return loadPaymentMethodsPromise;
};

export const getUIConfig = () => (dispatch: Dispatch) => {
  dispatch(startLoadUIConfig());
  const paymentIntentPromise = doGetUIConfig();
  paymentIntentPromise
    .then((uiConfig: UIConfigData) => dispatch(completeLoadUIConfig(uiConfig)))
    .catch(() => dispatch(failLoadUIConfig()));
  return paymentIntentPromise;
};

export const setupPaymentIntent = (invoiceId: string) => (dispatch: Dispatch) => {
  dispatch(startSetupPaymentIntent());
  const paymentIntentPromise = doSetupPaymentIntent(invoiceId);
  paymentIntentPromise
    .then((stripePaymentData: StripePaymentData) => dispatch(completeSetupPaymentIntent(stripePaymentData)))
    .catch(() => dispatch(failSetupPaymentIntent()));
  return paymentIntentPromise;
};

export const setupPaymentIntentWithLocation = (locationId: string) => (dispatch: Dispatch) => {
  dispatch(startSetupPaymentIntent());
  const paymentIntentPromise = doSetupPaymentIntentWithLocation(locationId);
  paymentIntentPromise
    .then((stripePaymentData: StripePaymentData) => dispatch(completeSetupPaymentIntent(stripePaymentData)))
    .catch(() => dispatch(failSetupPaymentIntent()));
  return paymentIntentPromise;
};

export const setupPaymentIntentWithCustomer = (customerId: number) => (dispatch: Dispatch) => {
  dispatch(startSetupPaymentIntent());
  const paymentIntentPromise = doSetupPaymentIntentWithCustomer(customerId);
  paymentIntentPromise
    .then((stripePaymentData: StripePaymentData) => dispatch(completeSetupPaymentIntent(stripePaymentData)))
    .catch(() => dispatch(failSetupPaymentIntent()));
  return paymentIntentPromise;
};

export const loadPaymentFeeEstimation = (data: PaymentFeeEstimationData) => (dispatch: Dispatch) => {
  dispatch(startLoadPaymentFeeEstimation());
  const getPaymentFeeEstimation = doGetPaymentFeeEstimation(data);
  getPaymentFeeEstimation
    .then((data: PaymentFeeEstimationData) => {
      dispatch(completeLoadPaymentFeeEstimation(data));
    })
    .catch(() => {
      dispatch(failLoadPaymentFeeEstimation());
      createErrorNotification(translate('finance.paymentManagement.loadPaymentMethodsError'));
    });
    return getPaymentFeeEstimation;
};

export const getHasProcessingFee = (customerId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadHasProcessingFee());
  const hasProcessingFee = doGetHasProcessingFee(customerId);
  hasProcessingFee
    .then((hasFeeActive: boolean) => dispatch(completeLoadHasProcessingFee(hasFeeActive)))
    .catch(() => dispatch(failLoadHasProcessingFee()));
  return hasProcessingFee;
};

export const finalizeSetupPaymentIntent = (data: CompletePaymentIntentData) => (dispatch: Dispatch) => {
  dispatch(startCompleteSetupIntent());
  const paymentIntentPromise = doCompletePaymentIntent(data);
  paymentIntentPromise.then(() => dispatch(completeSetupIntent())).catch(() => dispatch(failCompleteSetupIntent()));
  return paymentIntentPromise;
};

export const finalizePayment = (data: CompletePaymentData) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const finalizePaymentPromise = doCompletePayment(data);
  finalizePaymentPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return finalizePaymentPromise;
};

export const getPaymentMethodsByLocation = (locationId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadPaymentMethodsByLocation());
  const getPaymentMethodsByLocationPromise = doGetPaymentMethodsByLocation(locationId);
  getPaymentMethodsByLocationPromise
    .then((data: PaymentMethodData[]) => {
      dispatch(completeLoadPaymentMethodsByLocation(data));
    })
    .catch(() => {
      dispatch(failLoadPaymentMethodsByLocation());
      createErrorNotification(translate('finance.paymentManagement.loadPaymentMethodsError'));
    });
    return getPaymentMethodsByLocationPromise;
};

export const resetPaymentEstimationFeeData = () => (dispatch: Dispatch) => {
  dispatch(resetPaymentEstimationFee());
};

export const resetPaymentManagementData = () => (dispatch: Dispatch) => {
  dispatch(resetData());
};
