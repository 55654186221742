import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { initial } from 'lodash-es';

import { Feedback, FeedbackIcon, FeedbackText, FeedbackButton } from './styled';
import translate from '../../core/services/translate';
import { Button } from '../../core/components/styled';
import FeedbackForm from './FeedbackForm';
import { createSuccessNotification, createErrorNotification } from '../../core/services/createNotification';
import { sendFeedback } from '../ducks/sendFeedback';
import { currentVendorIdSelector } from '../../vendors/services/currentVendorSelector';
import { AppState } from '../../store';
import { setFeedbackMapControl } from 'src/dashboard/ducks/mapControls';
import { DuckAction } from 'src/contracts/ducks';

interface Props {
  vendorId: number;
  controlSpacing: number;
  setFeedbackMapControl: DuckAction<typeof setFeedbackMapControl>;
  sendFeedback: (vendorId: number, feedbackPage: string, feedback: string) => Promise<any>;
}

class FeedbackContainer extends PureComponent<Props, { isFeedbackFormOpen: boolean }> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isFeedbackFormOpen: false,
    };
  }

  onFeedbackSubmit = ({ feedbackText }: { feedbackText: string }) => {
    const feedbackUrl = window.location.href;
    const feedbackPageTitle = initial(document.title.split(' - ')).join(' - ');
    const feedbackPage = `${feedbackPageTitle} - ${feedbackUrl}`;
    const { vendorId, sendFeedback } = this.props;

    sendFeedback(vendorId, feedbackPage, feedbackText)
      .then(() => {
        createSuccessNotification(translate('common.alertMessages.feedbackSentSuccess'));
      })
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.feedbackSentFail'));
      })
      .finally(() => {
        this.closeFeedback();
      });
  };

  onCloseFeedback = () => {
    this.closeFeedback();
  };

  openFeedback = () => {
    this.setState({ isFeedbackFormOpen: !this.state.isFeedbackFormOpen });
  };

  closeFeedback = () => {
    this.setState({ isFeedbackFormOpen: false });
  };

  setFeedbackRef = (element: HTMLDivElement | null) => {
    const { setFeedbackMapControl } = this.props;

    if (element) {
      setFeedbackMapControl({ width: element.offsetHeight, height: element.offsetWidth, isVisible: true });
    } else {
      setFeedbackMapControl({ isVisible: false });
    }
  };

  render() {
    const { controlSpacing } = this.props;
    const { isFeedbackFormOpen } = this.state;

    return (
      <Feedback yOffset={controlSpacing} xOffset={controlSpacing}>
        <FeedbackForm
          onSubmit={this.onFeedbackSubmit}
          onCloseFeedback={this.onCloseFeedback}
          isFeedbackFormOpen={isFeedbackFormOpen}
        />

        <FeedbackButton isFeedbackFormOpen={isFeedbackFormOpen} ref={this.setFeedbackRef}>
          <Button color="primary" margin="no" type="button" onClick={this.openFeedback} id="feedback-button">
            <FeedbackIcon>{isFeedbackFormOpen ? <span>−</span> : <span>+</span>}</FeedbackIcon>
            <FeedbackText>{translate('common.feedback')}</FeedbackText>
          </Button>
        </FeedbackButton>
      </Feedback>
    );
  }
}

const mapDispatchToProps = {
  sendFeedback,
  setFeedbackMapControl,
};

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  controlSpacing: state.dashboard.mapControls.spacing,
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContainer);
