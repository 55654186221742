import { camelCase } from 'lodash-es';
import { useMemo, useState } from 'react';

import { AppState } from 'src/store';
import { ActionButtonTooltip } from 'src/core/components';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { COMPLETED } from 'src/routes/constants';
import {
  EditRouteButton,
  RouteDetail,
  RouteDetailLabel,
  RouteDetailsWrapper,
  RouteDetailValue,
  SeeMoreButton,
  Wrapper,
} from 'src/routes/components/styled/RouteDetails';
import { DRIVING_TO_FROM_FACILITY } from 'src/common/constants/serviceConfiguration';
import { duration } from 'src/utils/services/formatter';
import { IconButtonIcon, Popover, Text } from 'src/core/components/styled';
import { PopoverWrapper } from 'src/core/components';
import { RouteDetailsModalResolver } from '../../modals';
import { Segments } from 'src/routes/interfaces/RouteSummary';
import { ServiceConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { Tickets } from 'src/routes/interfaces/Route';
import { useSelector } from 'src/core/hooks/useSelector';
import CreateEditSnowSweeperRouteModalResolver from 'src/vendors/components/modals/CreateEditSnowSweeperRouteModalResolver';
import translate from 'src/core/services/translate';

type Props = {
  isDriverExperienceSimple?: boolean;
  isNoRoute?: boolean;
  onDateChange?: (startDate: any | Date | string, endDate: Date | string, isEditMode?: boolean) => void;
};

export default function SnowSweeperRouteSummaryDetails({ isDriverExperienceSimple, isNoRoute, onDateChange }: Props) {
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const [isEditRouteDetailsModalOpen, setEditRouteDetailsModalOpen] = useState(false);

  const { snowPlowSettings } = useSelector(state => state.vendors.snowPlowSettings);
  const { streetSweepingSettings } = useSelector(state => state.vendors.streetSweepingSettings);
  const { routeSummary } = useSelector((state: AppState) => state.routes.routeSummary);

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;

  const altFleetSettings = useMemo(() => {
    const settings = isSnowPlowRoute
      ? snowPlowSettings.serviceConfiguration?.filter(
          (serviceConfiguration: ServiceConfiguration) => serviceConfiguration.isNonServiceActivity,
        )
      : streetSweepingSettings.serviceConfiguration?.filter(
          (serviceConfiguration: ServiceConfiguration) => serviceConfiguration.isNonServiceActivity,
        );

    return settings;
  }, [isSnowPlowRoute, snowPlowSettings.serviceConfiguration, streetSweepingSettings.serviceConfiguration]);

  const tickets = useMemo(
    () =>
      routeSummary?.tickets?.reduce((acc: Tickets[], curr: Tickets) => {
        const index = acc.findIndex(
          (item: Tickets) =>
            item.materialType.id === curr.materialType.id && item.unitOfMeasureType.id === curr.unitOfMeasureType.id,
        );

        const setSumData = () => {
          acc[index].totalMaterialAmount += curr.totalMaterialAmount;
          acc[index].ticketsCount += curr.ticketsCount;
        };

        index > -1
          ? setSumData()
          : acc.push({
              materialType: curr.materialType,
              totalMaterialAmount: curr.totalMaterialAmount,
              unitOfMeasureType: curr.unitOfMeasureType,
              ticketsCount: curr.ticketsCount,
            });
        return acc;
      }, []),
    [routeSummary?.tickets],
  );

  if (!routeSummary) return null;

  const servicedSegmentsCount =
    (routeSummary.segments || []).find((segment: Segments) => segment.pickupStatusTypeId === COMPLETED)
      ?.segmentsCount || 0;
  const servicedSegmentsPercentage = ((servicedSegmentsCount / (routeSummary.routeSegmentCount || 1)) * 100).toFixed(2);

  const nowServicingFirstDriverVehicle =
    !!routeSummary.nowServicing.length &&
    `${routeSummary?.nowServicing[0].vehicleName} / ${routeSummary?.nowServicing[0].driverFirstName} ${routeSummary?.nowServicing[0].driverLastName}`;

  const nowServicingDriverVehicle = routeSummary.nowServicing.slice(1).map((service, index) => (
    <Text key={index} margin={index === 0 ? 'no' : 'xSmall no no no'} block singleLine>
      {service.vehicleName} / {service.driverFirstName} {service.driverLastName}
    </Text>
  ));

  const totalTickets = (tickets || []).map((ticket, index) => (
    <Text key={index} margin={index === 0 ? 'no' : 'xSmall no no no'} block>
      <b>{translate(`common.materialTickets.materialTypeValues.${camelCase(ticket.materialType.technicalName)}`)}</b>:{' '}
      {ticket.totalMaterialAmount}{' '}
      {translate(`common.materialTickets.unitOfMeasureValues.${camelCase(ticket.unitOfMeasureType.technicalName)}`)}
    </Text>
  ));

  let ticketsCount = 0;
  tickets?.forEach(ticket => (ticketsCount += ticket.ticketsCount));

  const isDrivingToAndFromFacilityConfigActive = !!altFleetSettings?.find(
    (altFleetSetting: ServiceConfiguration) =>
      altFleetSetting.alternativeFleetServiceType.technicalName === DRIVING_TO_FROM_FACILITY,
  )?.isActive;

  return (
    <Wrapper>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.vehicleNowServicing')}</RouteDetailLabel>
          <RouteDetailValue>
            {nowServicingFirstDriverVehicle || '-'}

            {routeSummary.nowServicing.length > 1 && (
              <PopoverWrapper
                triggerButton={
                  <Text margin="no no no xSmall" block color="primary">
                    <b>+{routeSummary.nowServicing.length - 1}</b>
                  </Text>
                }
                popoverContent={<Popover>{nowServicingDriverVehicle}</Popover>}
                size="medium"
              />
            )}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.totalVehicles')}</RouteDetailLabel>
          <RouteDetailValue>{routeSummary.routeVehicleCount}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.totalssues')}</RouteDetailLabel>
          <RouteDetailValue>{routeSummary.routeIssuesReportedCount}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.totalTrips')}</RouteDetailLabel>
          <RouteDetailValue>
            {ticketsCount}

            {ticketsCount > 0 && (
              <PopoverWrapper
                triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xSmall" />}
                popoverContent={<Popover>{totalTickets}</Popover>}
                size="medium"
              />
            )}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.totalTime')}</RouteDetailLabel>
          <RouteDetailValue>
            {duration(routeSummary.routeTotalTime || 0, 'seconds', 'hh:mm:ss')}

            {!!routeSummary.routeTotalTime && (
              <PopoverWrapper
                triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xSmall" />}
                popoverContent={
                  <Popover>
                    {isDrivingToAndFromFacilityConfigActive && (
                      <>
                        <Text margin="no no xSmall no" block>
                          <b>{translate('routes.routeStatusDetails.totalFacilityTransit')}</b>:{' '}
                          {routeSummary.routeTotalFacilityTransitTime
                            ? duration(routeSummary.routeTotalFacilityTransitTime, 'seconds', 'hh:mm:ss')
                            : '-'}
                        </Text>
                        <Text margin="no no xSmall no" block>
                          <b>{translate('routes.routeStatusDetails.totalFacilityTime')}</b>:{' '}
                          {routeSummary.routeTotalFacilityTime
                            ? duration(routeSummary.routeTotalFacilityTime, 'seconds', 'hh:mm:ss')
                            : '-'}
                        </Text>
                      </>
                    )}
                    <Text margin="no no xSmall no" block>
                      <b>{translate('routes.totalTransitTime')}</b>:{' '}
                      {routeSummary.routeTotalTransitTime
                        ? duration(routeSummary.routeTotalTransitTime, 'seconds', 'hh:mm:ss')
                        : '-'}
                    </Text>
                    <Text margin="no no xSmall no" block>
                      <b>{translate('routes.totalServiceTime')}</b>:{' '}
                      {routeSummary.routeTotalServiceTime
                        ? duration(routeSummary.routeTotalServiceTime, 'seconds', 'hh:mm:ss')
                        : '-'}
                    </Text>
                    <Text margin="no no xSmall no" block>
                      <b>{translate('routes.routeStatusDetails.totalNotServicingTime')}</b>:{' '}
                      {routeSummary.routeTotalNotServicingTime
                        ? duration(routeSummary.routeTotalNotServicingTime, 'seconds', 'hh:mm:ss')
                        : '-'}
                    </Text>
                  </Popover>
                }
                size="medium"
              />
            )}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.totalCompletedMiles')}</RouteDetailLabel>
          <RouteDetailValue>
            {routeSummary.routeTotalMilesDriven && routeSummary.routeTotalMilesDriven > 0
              ? routeSummary.routeTotalMilesDriven.toFixed(2)
              : '0'}
          </RouteDetailValue>
        </RouteDetail>
        {!isNoRoute && (
          <>
            <RouteDetail>
              <RouteDetailLabel>{translate('routes.snowPlow.streetSegments')}</RouteDetailLabel>
              <RouteDetailValue>
                {servicedSegmentsCount} / {routeSummary.routeSegmentCount}
              </RouteDetailValue>
            </RouteDetail>

            <RouteDetail>
              <RouteDetailLabel>{translate('vehicles.percentComplete')}</RouteDetailLabel>
              <RouteDetailValue>{servicedSegmentsPercentage}%</RouteDetailValue>
            </RouteDetail>
          </>
        )}

        {!isViewOnly && !isSupport && (
          <EditRouteButton onClick={() => setEditRouteDetailsModalOpen(true)}>
            <ActionButtonTooltip iconColor="grayDarker" icon="edit" tooltip="edit" size="sMedium" />
          </EditRouteButton>
        )}
      </RouteDetailsWrapper>
      <SeeMoreButton onClick={() => setIsSeeMoreModalOpen(true)}>{translate('routes.seeMoreDetails')}</SeeMoreButton>

      {isEditRouteDetailsModalOpen && (
        <CreateEditSnowSweeperRouteModalResolver
          closeModal={() => setEditRouteDetailsModalOpen(false)}
          onDateChange={onDateChange}
          routeSummary={routeSummary}
        />
      )}

      {isSeeMoreModalOpen && (
        <RouteDetailsModalResolver
          closeModal={() => setIsSeeMoreModalOpen(false)}
          isDriverExperienceSimple={isDriverExperienceSimple}
          isNoRoute={isNoRoute}
        />
      )}
    </Wrapper>
  );
}
