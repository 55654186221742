import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../../../contracts/ducks';
import { loadLoadsDumped } from '../../../../ducks';
import { LoadsDumpedModal } from '.';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { Resolver } from '../../../../../core/components';

interface LoadsDumpedModalResolverProps {
  closeModal: () => void;
  loadLoadsDumped: DuckFunction<typeof loadLoadsDumped>;
  modalTitle?: string;
  routeId: number;
  vendorId: number;
}

class LoadsDumpedModalResolver extends PureComponent<LoadsDumpedModalResolverProps> {
  loadDependencies = () => {
    const { routeId, loadLoadsDumped, vendorId } = this.props;
    return Promise.all([loadLoadsDumped(vendorId, routeId)]);
  };

  render() {
    const { modalTitle, closeModal } = this.props;
    return (
      <Resolver
        successComponent={LoadsDumpedModal}
        successProps={{
          modalTitle,
          closeModal,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorId(state),
});

const mapDispatchToProps = { loadLoadsDumped };

export default connect(mapStateToProps, mapDispatchToProps)(LoadsDumpedModalResolver);
