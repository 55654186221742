import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { VehicleNotificationsConfiguration, VehicleNotificationsState } from '../interfaces/VehicleNotifications';
import * as Services from '../services/vehicleNotifications';

// Actions
const START_LOAD = 'vendors/vehicleNotifications/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicleNotifications/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicleNotifications/FAIL_LOAD';
const START_SAVE = 'vendors/vehicleNotifications/START_SAVE';
const COMPLETE_SAVE = 'vendors/vehicleNotifications/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/vehicleNotifications/FAIL_SAVE';
const START_LOAD_TYPES = 'vendors/vehicleNotifications/START_LOAD_TYPES';
const COMPLETE_LOAD_TYPES = 'vendors/vehicleNotifications/COMPLETE_LOAD_TYPES';
const FAIL_LOAD_TYPES = 'vendors/vehicleNotifications/FAIL_LOAD_TYPES';

// Initial state
const initialState: VehicleNotificationsState = {
  configurations: [],
  configurationsLoading: false,
  configurationsSaving: false,

  types: [],
  typesLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          configurationsLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          configurations: action.configurations,
          configurationsLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          configurationsLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          configurationsSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          configurationsSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          configurationsSaving: false,
        },
      });

    case START_LOAD_TYPES:
      return update(state, {
        $merge: {
          typesLoading: true,
        },
      });

    case COMPLETE_LOAD_TYPES:
      return update(state, {
        $merge: {
          types: action.types,
          typesLoading: false,
        },
      });

    case FAIL_LOAD_TYPES:
      return update(state, {
        $merge: {
          typesLoading: false,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (configurations: VehicleNotificationsConfiguration[]) => ({
  type: COMPLETE_LOAD,
  configurations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadTypes = () => ({
  type: START_LOAD_TYPES,
});

const completeLoadTypes = (types: TechnicalType[]) => ({
  type: COMPLETE_LOAD_TYPES,
  types,
});

const failLoadTypes = () => ({
  type: FAIL_LOAD_TYPES,
});

export const loadVehicleNotificationsConfigurations = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const promise = Services.loadVehicleNotificationsConfigurations(vendorId);

  promise
    .then(configurations => {
      dispatch(completeLoad(configurations));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};

export const saveVehicleNotificationsConfigurations = (
  vendorId: number,
  configurations: VehicleNotificationsConfiguration[],
) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const promise = Services.saveVehicleNotificationsConfigurations(vendorId, configurations);

  promise
    .then(() => {
      dispatch(completeSave());
    })
    .catch(() => {
      dispatch(failSave());
    });

  return promise;
};

export const loadVehicleNotificationsTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadTypes());

  const promise = Services.loadVehicleNotificationsTypes();

  promise
    .then(types => {
      dispatch(completeLoadTypes(types));
    })
    .catch(() => {
      dispatch(failLoadTypes());
    });

  return promise;
};
