import { useDispatch } from 'react-redux';

import { loadTriggers } from 'src/routes/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { useSelector } from '../../../core/hooks/useSelector';
import TriggerMapModal from './TriggerMapModal';

type Props = {
  closeModal: () => void;
};

export default function TriggerMapModalResolver({ closeModal }: Props) {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  if (!routeSummary) return null;

  const loadDependencies = () => Promise.all([loadTriggers(routeSummary.routeId)(dispatch)]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={TriggerMapModal}
      successProps={{ closeModal }}
    />
  );
}
