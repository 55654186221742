import React from 'react';

interface ContainerScrollAtRockBottomReturn {
  containerHasMaximumScroll: boolean;
  setRef(instance: HTMLDivElement | null): void;
  onScroll(event: React.UIEvent<HTMLDivElement, UIEvent>): void;
}

export const useContainerScrollAtRockBottom = (listLength: number | undefined): ContainerScrollAtRockBottomReturn => {
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
  const [containerHasMaximumScroll, setContainerHasMaximumScroll] = React.useState<boolean>(false);

  const checkScroll = React.useCallback((element: HTMLDivElement) => {
    if (
      element.scrollHeight === element.offsetHeight ||
      element.scrollHeight === element.scrollTop + element.offsetHeight
    ) {
      setContainerHasMaximumScroll(true);
    } else {
      setContainerHasMaximumScroll(false);
    }
  }, []);

  const onScroll = React.useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      checkScroll(event.currentTarget);
    },
    [checkScroll],
  );

  React.useEffect(() => {
    if (!ref) {
      return;
    }

    checkScroll(ref);
  }, [ref, checkScroll, listLength]);

  return {
    containerHasMaximumScroll,
    onScroll,
    setRef,
  };
};
