import { arrayPush, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Button, ButtonSet, ModalFixedFooter, PanelSection } from 'src/core/components/styled';
import { ChangeEvent, Rate } from 'src/customers/interfaces/Services';
import { Modal } from 'src/core/components';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TableCell } from 'src/core/components/Table';
import translate from 'src/core/services/translate';
import { ServiceTableForm } from 'src/customers/components/modals/RateCodeModal';

export const RATE_CODE_EDITOR_FORM = 'rateCodeEditorForm';

interface RateCodeModalProps {
  closeModal: () => void;
  formProps: {
    customerName?: string | undefined;
    location?: string | undefined;
    tableCells?: TableCell[];
    workOrderId?: number | string;
    workOrderDate?: StringOrDate;
  };
  getNewRate: () => void;
  onSubmit: (data: RateCodeFormValues) => void;
}

export interface RateCodeFormValues {
  rates: Rate[];
}

type Props = RateCodeModalProps & InjectedFormProps<RateCodeFormValues, RateCodeModalProps>;

const RateCodeModal: React.FC<Props> = ({ change, closeModal, formProps, getNewRate, handleSubmit }) => {
  const dispatch = useDispatch();

  const updateFormValue = ({ fieldName, value }: ChangeEvent) => change(fieldName, value);
  const handleAddNewRate = () => dispatch(arrayPush('rateCodeEditorForm', 'rates', getNewRate()));

  return (
    <Modal onClose={closeModal} size="xLarge" title={translate('finance.modal.rateCode')} padding="medium no no no">
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall no" vertical>
          <ServiceTableForm
            updateFormValue={updateFormValue}
            customerName={formProps?.customerName}
            location={formProps?.location}
          />

          <ButtonSet margin="medium no xGrande no" align="center">
            <Button type="button" color="primary" line onClick={handleAddNewRate} margin="no">
              {`+ ${translate('finance.modal.newRate')}`}
            </Button>
          </ButtonSet>
        </PanelSection>

        <ModalFixedFooter isShadowed>
          <Button type="button" color="secondary" margin="no xSmall no no" onClick={closeModal}>
            {translate('common.cancel')}
          </Button>
          <Button type="submit" color="primary" margin="no no no xSmall">
            {translate('finance.modal.update')}
          </Button>
        </ModalFixedFooter>
      </form>
    </Modal>
  );
};

export default reduxForm<RateCodeFormValues, RateCodeModalProps>({
  form: RATE_CODE_EDITOR_FORM,
  enableReinitialize: true,
})(RateCodeModal);
