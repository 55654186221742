import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import translate from 'src/core/services/translate';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import NewPaymentForm from './NewPaymentMethodForm';
import { setupPaymentIntent } from 'src/finance/ducks';
import { setupPaymentIntentWithLocation } from 'src/finance/ducks/paymentManagement';

interface PayNowParams {
  invoiceId?: string;
  locationId?: string;
}

interface Props {
  successProps: {
    onCancel: () => void;
    returnToPreviousPage?: () => void;
  };
}

const PayNowPageResolver = ({ successProps }: Props) => {
  const dispatch = useDispatch();

  const { invoiceId, locationId } = useParams<PayNowParams>();

  const loadDependencies = async () => {
    const dependencies: Promise<any>[] = [];

    if (invoiceId) {
      dependencies.push(setupPaymentIntent(invoiceId)(dispatch));
    } else if (locationId) {
      dependencies.push(setupPaymentIntentWithLocation(locationId)(dispatch));
    }
    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('finance.billing')}</DocumentTitle>
      <TypedResolver
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={NewPaymentForm}
        successProps={successProps}
      />
    </>
  );
};

export default PayNowPageResolver;
