import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const PREVIEW = 1;
export const UNBILLED = 2;
export const UNPAID = 3;

export const BILLING_AGING_REPORTS_LIST = [
  { id: PREVIEW, name: translate('finance.billAgingReport.preview'), code: 'PV' },
  { id: UNBILLED, name: translate('finance.billAgingReport.unbilled'), code: 'UB' },
  { id: UNPAID, name: translate('finance.billAgingReport.unpaid'), code: 'UP' },
];

export const BILLING_AGING_REPORTS = mapKeys(BILLING_AGING_REPORTS_LIST, 'id');
