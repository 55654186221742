import moment from 'moment';

import { FuelingStation } from 'src/common/interfaces/Facility';
import { ImageSlider, Modal } from 'src/core/components';
import translate from 'src/core/services/translate';
import { FuelTicket } from 'src/routes/interfaces/FuelTicket';
import { dateTimeFormat } from 'src/utils/services/validator';

interface Props {
  closeModal: () => void;
  fuelTicket: FuelTicket;
  fuelingStation?: FuelingStation;
}

const FuelTicketImagesModal = ({ closeModal, fuelTicket, fuelingStation }: Props) => {
  return (
    <Modal
      title={fuelingStation?.fuelingStationName || ''}
      subTitle={translate('routes.fuelTicket')}
      onClose={closeModal}
      padding="medium no no"
    >
      <ImageSlider
        margin="small no no"
        images={[fuelTicket?.image?.url]}
        imagesToDownload={[fuelTicket?.image?.rawUrl || fuelTicket?.image?.url]}
        imageTitle={fuelTicket.image?.timeStamp ? moment(fuelTicket.image?.timeStamp).format(dateTimeFormat) : ''}
      />
    </Modal>
  );
};

export default FuelTicketImagesModal;
