import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { Modal, Table } from '../../../core/components';
import { ModalSection, Message } from '../../../core/components/styled';
import { SourcingNotesTableRow } from '../pages/SourcingNotesTableRow';
import translate from '../../../core/services/translate';

const tableCells = [
  { name: 'notes', label: translate('common.note'), width: '50%' },
  { name: 'sourceName', label: translate('common.source'), width: '25%' },
  { name: 'date', label: translate('common.timestamp'), width: '25%' },
];

interface Props {
  title: string;
  subTitle?: string;
  onCancel(): void;
}

const SourcingNotesModal: React.FC<Props> = ({ title, subTitle, onCancel }) => {
  const notes = useSelector((state: AppState) => state.opportunity.opportunities.notes);
  const isLoadingNotes = useSelector((state: AppState) => state.opportunity.opportunities.isLoadingNotes);

  return (
    <Modal padding="medium no no" size="medium" title={title} subTitle={subTitle} onClose={onCancel}>
      <ModalSection padding="no" isLoading={isLoadingNotes} overflow="visible">
        {!!notes.length && <Table withTopBorder cells={tableCells} rows={notes} rowComponent={SourcingNotesTableRow} />}

        {!notes.length && <Message padding="no no sMedium sMedium">{translate('autoDispatch.noNotes')}</Message>}
      </ModalSection>
    </Modal>
  );
};

export default SourcingNotesModal;
