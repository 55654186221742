import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { displayUnscheduledStopsStatusSelector, loadFeatures, loadRouteSequenceSettings } from 'src/vendors/ducks';
import { DocumentTitle } from 'src/common/components';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadRouteSummary } from 'src/routes/ducks/routeSummary';
import { loadRouteLocationDetails, loadRouteLocationIssues, loadRouteSequenceHistory } from 'src/routes/ducks';
import { PageLoading } from 'src/common/components/styled';
import { removePersistentMapFilters } from 'src/routes/services/persistentMapFilterSettingStorage';
import { SEQUENCE_SOURCE_TYPE_DAILY_ROUTE } from 'src/routes/constants';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RoutePage from './NewRoutePage';
import translate from 'src/core/services/translate';

export type RoutePageParams = {
  routeId?: string;
};

export default function NewRoutePageResolver() {
  const dispatch = useDispatch();

  const params = useParams<RoutePageParams>();
  const routeId = Number(params.routeId);

  const location = useLocation();
  const urlParams = getQueryParams(location.search);
  const routeLocationId = urlParams?.routeLocationId ? Number(urlParams.routeLocationId) : undefined;
  const shouldOpenImagesModal = urlParams?.focus === 'images';

  const vendorId = useSelector(currentVendorId);
  const isUnscheduledStopsVisible = useSelector(state =>
    displayUnscheduledStopsStatusSelector(state.vendors.features.features),
  );

  useEffect(() => {
    removePersistentMapFilters();
  }, []);

  const loadDependencies = async () => {
    if (!isFinite(routeId)) return Promise.reject();

    // redirect to new page if is street sweeper or snow plow
    const routeSummary = await loadRouteSummary(vendorId, routeId)(dispatch);

    const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;
    const isStreetSweeperRoute = routeSummary?.vehicleTypeId === STREET_SWEEPER_ID;

    if (isStreetSweeperRoute) {
      dispatch(push(`/routes/sweeper-tracker/${routeId}/${location.search}`));
    } else if (isSnowPlowRoute) {
      dispatch(push(`/routes/snow-tracker/${routeId}/${location.search}`));
    }

    const dependencies: any[] = [
      await loadFeatures(vendorId)(dispatch),
      loadRouteSequenceHistory(routeId, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)(dispatch),
      loadRouteSequenceSettings(vendorId)(dispatch),
    ];

    if (routeLocationId)
      dependencies.push([
        loadRouteLocationIssues(routeId, routeLocationId)(dispatch),
        loadRouteLocationDetails(vendorId, routeId, routeLocationId)(dispatch),
      ]);

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>

      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/routes/route-tracker"
        resolve={loadDependencies}
        successComponent={RoutePage}
        successProps={{ routeLocationId, shouldOpenImagesModal, isUnscheduledStopsVisible }}
      />
    </>
  );
}
