import { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';

import { NavigationPrompt, Table } from '../../../core/components';
import { Button, Grid, GridColumn, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { loadProximitySettings } from '../../ducks';
import proximitySettingsFormInitialValuesSelector from '../../services/proximitySettingsFromInitialValuesSelector';
import { ProximitySettingsFormTableRow } from '.';
import { AppState } from '../../../store';

interface ComponentProps extends RouteComponentProps {}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  proximitySettings: any;
  isLoading: boolean;
  isSaving: boolean;
  formValues?: any;
  loadProximitySettings(...args: any[]): any;
  push(...args: any[]): any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class ProximitySettingsForm extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { location, loadProximitySettings } = this.props;

    if (location.search !== prevProps.location.search) {
      const { vendorId } = getQueryParams(location.search);
      loadProximitySettings(vendorId);
    }
  }

  onVendorSelected = async (event: any, vendorId: number) => {
    const { push, location } = this.props;
    push(createUrl(location.pathname, location.search, { vendorId }));
  };

  getFormValue = (name: string, defaultValue?: string) => get(this.props.formValues, name, defaultValue);

  proximitySettingsTableCells = [
    { name: 'vehicleTypeName', label: translate('vehicles.vehicleType'), width: '20%', sortable: true },
    { name: 'customerTypeName', label: translate('common.customerType'), width: '20%' },
    { name: 'locationTypeName', label: translate('common.locationType'), width: '20%' },
    { name: 'distance', label: translate('common.distance'), width: '20%' },
    { name: 'measurementType', label: translate('common.measurementType'), width: '20%' },
  ];

  render() {
    const vendorId = this.getFormValue('vendorId');
    const { handleSubmit, proximitySettings, isLoading, isSaving, pristine } = this.props;

    return (
      <PanelSectionGroup isLoading={isLoading || isSaving}>
        <NavigationPrompt when={!pristine} />

        <form onSubmit={handleSubmit} noValidate>
          <PanelSection withBorder padding="small xSmall">
            <Grid>
              <GridColumn size="8/12" align="right">
                {!pristine && (
                  <Button type="submit" color="primary" disabled>
                    {translate('common.save')}
                  </Button>
                )}
              </GridColumn>
            </Grid>
          </PanelSection>

          <PanelSection>
            <Table
              cells={this.proximitySettingsTableCells}
              rows={proximitySettings}
              rowProps={{ vendorId }}
              rowComponent={ProximitySettingsFormTableRow}
              sortedBy="vehicleType"
              sortOrder="asc"
            />
          </PanelSection>
        </form>
      </PanelSectionGroup>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const initialValues = proximitySettingsFormInitialValuesSelector(state.settings.proximitySettings as any);
  const isLoading = state.settings.proximitySettings.isLoading;
  const isSaving = state.settings.proximitySettings.isSaving;
  const proximitySettings = (state.settings.proximitySettings as any).proximitySettings.proximitySettings;
  const formValues = getFormValues('proximitySettings')(state);

  return {
    initialValues,
    proximitySettings,
    formValues,
    isLoading,
    isSaving,
  };
};

const mapDispatchToProps = {
  loadProximitySettings,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'proximitySettings',
      enableReinitialize: true,
      onSubmitFail: focusFirstInvalidField,
    })(ProximitySettingsForm),
  ),
);
