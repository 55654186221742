import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { change, Field, reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { map, camelCase } from 'lodash-es';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import {
  CustomerNotificationsVehicleTypes,
  NotificationTypes,
  ReasonTypes,
} from '../../interfaces/CustomerNotifications';
import { Dropdown, Input, TextArea, Switch } from '../../../core/components';
import { Grid, GridColumn, PanelSection, ButtonSet, Text, ButtonIcon, Button } from '../../../core/components/styled';
import { isRequired, isEmails, maxLength160 } from '../../../utils/services/validator';
import { ISSUE_REPORTED_NOTIFICATION } from '../../constants';
import { technicalNameByVehicleTypeIdSelector } from '../../../fleet/ducks';
import {
  loadNotificationEmailTemplates,
  loadNotificationTypes,
  loadReasonTypes,
  technicalNameByNotificationIdSelector,
} from '../../ducks';
import { TypedField } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import customerNotificationFormInitialValuesSelector from '../../services/customerNotificationFormInitialValuesSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface ComponentProps {
  change: any;
  customerNotificationsVehicleTypes?: CustomerNotificationsVehicleTypes[];
  loadNotificationEmailTemplates: (
    vendorId: number,
    vehicleTypeId: number,
    name: number,
    pickupExceptionTypeId?: number,
  ) => void;
  loadNotificationTypes: (vendorId: number, vehicleTypeId: number) => void;
  loadReasonTypes: (vendorId: number, vehicleTypeId: number) => void;
  name: number;
  notificationTechnicalName?: string;
  notificationTypes?: NotificationTypes[];
  pickupExceptionTypeId: number;
  reasonTypes?: ReasonTypes[];
  toAdditionalCCAddress?: string;
  vehicleTypeId: number;
  vendorId: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class CustomerNotificationForm extends PureComponent<Props> {
  onVehicleTypeIdChange = (event: ChangeEvent, vehicleTypeId: number) => {
    const { vendorId, change, loadNotificationTypes, loadReasonTypes } = this.props;
    change('name', '');
    change('pickupExceptionTypeId', '');
    loadNotificationTypes(vendorId, vehicleTypeId);
    loadReasonTypes(vendorId, vehicleTypeId);
  };

  onNameChange = (event: ChangeEvent, name: number) => {
    const { vehicleTypeId, vendorId, loadNotificationEmailTemplates } = this.props;
    loadNotificationEmailTemplates(vendorId, vehicleTypeId, name);
  };

  onPickupIssueReportedReasonTypeIdChange = (event: ChangeEvent, pickupExceptionTypeId: number) => {
    const { vehicleTypeId, name, vendorId, loadNotificationEmailTemplates } = this.props;
    if (vehicleTypeId && name) {
      loadNotificationEmailTemplates(vendorId, vehicleTypeId, name, pickupExceptionTypeId);
    }
  };

  render() {
    const {
      vehicleTypeId,
      notificationTechnicalName,
      pickupExceptionTypeId,
      handleSubmit,
      notificationTypes,
      reasonTypes,
      customerNotificationsVehicleTypes,
      toAdditionalCCAddress,
    } = this.props;

    const notificationTypeOptions = map(notificationTypes, notificationType => ({
      label: translate(`vendors.notificationTypes.${camelCase(notificationType.technicalName)}`),
      value: notificationType.id,
    }));

    const pickupIssueReportedTypeOptions = map(reasonTypes, reasonType => ({
      label: translate(`vendors.pickupIssueReasonTypes.${camelCase(reasonType.technicalName)}`),
      value: reasonType.id,
    }));

    const customerNotificationsVehicleTypeOptions = map(customerNotificationsVehicleTypes, vehicleType => ({
      label: translate(createTranslationKey(vehicleType.technicalName, 'vehicles.vehicleTypes')),
      value: vehicleType.id,
    }));

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall">
          <Grid centered>
            <GridColumn size="4/12">
              <TypedField
                name="vehicleTypeId"
                component={Dropdown}
                onChange={this.onVehicleTypeIdChange}
                props={{
                  margin: 'no',
                  options: customerNotificationsVehicleTypeOptions,
                  label: translate('vehicles.vehicleType'),
                }}
              />
            </GridColumn>
            {vehicleTypeId && (
              <GridColumn size="4/12">
                <TypedField
                  name="name"
                  component={Dropdown}
                  onChange={this.onNameChange}
                  props={{
                    options: notificationTypeOptions,
                    label: translate('vendors.notificationType'),
                  }}
                />
              </GridColumn>
            )}
            {notificationTechnicalName === ISSUE_REPORTED_NOTIFICATION && (
              <GridColumn size="4/12">
                <TypedField
                  name="pickupExceptionTypeId"
                  component={Dropdown}
                  onChange={this.onPickupIssueReportedReasonTypeIdChange}
                  props={{
                    options: pickupIssueReportedTypeOptions,
                    label: translate('vendors.reasonType'),
                  }}
                />
              </GridColumn>
            )}
          </Grid>
        </PanelSection>
        {((vehicleTypeId && notificationTechnicalName && notificationTechnicalName !== ISSUE_REPORTED_NOTIFICATION) ||
          (vehicleTypeId && notificationTechnicalName === ISSUE_REPORTED_NOTIFICATION && pickupExceptionTypeId)) && (
          /* eslint-disable react/jsx-indent */
          <PanelSection vertical>
            <Grid centered>
              <GridColumn size="6/12" borderRight>
                <TypedField
                  name="fromAddress"
                  component={Input}
                  validate={[isRequired]}
                  props={{
                    margin: 'small',
                    label: translate('common.fromEmail'),
                  }}
                />
                <TypedField
                  name="subject"
                  component={Input}
                  validate={[isRequired]}
                  props={{
                    margin: 'small',
                    label: translate('common.emailSubject'),
                  }}
                />
                <Field
                  name="bodyContent"
                  component={TextArea}
                  validate={[isRequired]}
                  rows={4}
                  margin="small"
                  label={translate('common.emailMessage')}
                />
                <Field
                  name="textMessage"
                  component={TextArea}
                  rows={2}
                  margin="small"
                  label={translate('common.textMessage')}
                  validate={[isRequired, maxLength160]}
                />
              </GridColumn>
              <GridColumn size="6/12">
                <TypedField
                  name={'toAdditionalCCAddress'}
                  component={Input}
                  validate={[isEmails]}
                  props={{
                    margin: 'small',
                    label: translate('common.additionalEmails'),
                    withEllipsesText: true,
                  }}
                />
                <TypedField
                  name="sendDailySummaryEmail"
                  component={Switch}
                  props={{
                    margin: 'small',
                    label: translate('vendors.dailySummaryEmail'),
                    disabled: !toAdditionalCCAddress,
                  }}
                />
                <TypedField
                  name="sendIndividualEventEmail"
                  component={Switch}
                  props={{
                    margin: 'small',
                    label: translate('vendors.individualEventEmail'),
                    disabled: !toAdditionalCCAddress,
                  }}
                />
                <Grid>
                  <GridColumn size="1/12" padding="no">
                    <Button text color="primary" type="button" padding="no" margin="small no no small">
                      <ButtonIcon icon="info" size="medium" />
                    </Button>
                  </GridColumn>
                  <GridColumn size="11/12" padding="no">
                    <Text block weight="light" margin="small no small medium">
                      {translate('vendors.additionalEmailsInfo')}
                    </Text>
                  </GridColumn>
                </Grid>
              </GridColumn>
            </Grid>
            <ButtonSet margin="medium no medium no">
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            </ButtonSet>
          </PanelSection>
          /* eslint-enable react/jsx-indent */
        )}
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const vendorId = currentVendorIdSelector(state.account.login, state.vendors.defaultVendor);
  const formValues = (getFormValues('customerNotification')(state) || {}) as any;
  const { vehicleTypeId, name, pickupExceptionTypeId, toAdditionalCCAddress } = formValues;

  return {
    vendorId,
    vehicleTypeId,
    pickupExceptionTypeId,
    vehicleTechnicalName: technicalNameByVehicleTypeIdSelector(state.fleet.vehicleTypesForVendor, vehicleTypeId),
    name,
    notificationTechnicalName: technicalNameByNotificationIdSelector(state.vendors.notificationTypes, name),
    customerNotificationsVehicleTypes:
      state.vendors.customerNotificationsVehicleTypes.customerNotificationsVehicleTypes,
    initialValues: customerNotificationFormInitialValuesSelector(
      state.vendors.notificationEmailTemplates,
      formValues,
      state.vendors.notificationTypes.notificationTypes,
    ),
    notificationTypes: state.vendors.notificationTypes.notificationTypes,
    reasonTypes: state.vendors.reasonTypes.reasonTypes,
    toAdditionalCCAddress,
  };
};

const mapDispatchToProps = { change, loadNotificationEmailTemplates, loadNotificationTypes, loadReasonTypes };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    form: 'customerNotification',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(CustomerNotificationForm),
);
