import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import {
  AssignJobsModalContainerForm,
  AssignJobsFormFieldContainer,
  DispatchBoardContainerError,
  AssignJobsButtonContainer,
} from '../../../../styled';
import { augmentWithType } from '../../../../../services/routeTemplateHelper';
import { Button } from '../../../../../../core/components/styled';
import { DatePicker, Dropdown } from '../../../../../../core/components';
import { DELIVERY_UTILITY_ID, FRONT_LOAD_ID, RESIDENTIAL_ID } from 'src/fleet/constants/vehicleTypes';
import { getComboRouteOptions } from 'src/routes/components/forms/utils/CustomOptionsUtils';
import { Input, TypedField } from 'src/core/components';
import { isDateValidValidator, isRequired } from '../../../../../../utils/services/validator';
import { JOB_IS_NOT_PENDING_ID, JOB_IS_PENDING_ID } from 'src/routes/constants';
import { ROLL_OFF_ID } from 'src/common/constants/serviceTypes';
import { SCHEDULED } from 'src/routes/constants/routeStatuses';
import { UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import focusFirstInvalidField from '../../../../../../utils/services/focusFirstInvalidField';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import translate from '../../../../../../core/services/translate';

const mapRouteOptions = (routes: any[]) =>
  routes.map(route => ({
    label: route.name,
    value: augmentWithType(route.id, route.isTemplate),
    vehicleTypeId: route.vehicleTypeId,
    routeStatusTypeId: route.routeStatusTypeId,
    hasStopsInPendingOptimization: route.hasStopsInPendingOptimization,
  }));

interface ComponentProps {
  change?: any;
  duplicateRouteId?: number;
  hasDuplicateJobs: boolean;
  initialDate: Date | string;
  loadRoutes: (date?: Date | string) => Promise<any>;
  onCancel: () => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

interface State {
  groupedCustomOptions: any[];
  isJobPositionDropdownVisible: boolean;
  isLoading: boolean;
  isOptimizedOptionHidden: boolean;
  routeOptions: any[];
}

class AssignJobsModalForm extends PureComponent<Props, State> {
  state = {
    routeOptions: [],
    isLoading: false,
    groupedCustomOptions: [],
    isOptimizedOptionHidden: false,
    isJobPositionDropdownVisible: false,
  };

  componentDidMount = () => {
    this.loadRoutesForDate(this.props.initialDate);
  };

  onDateChange = (_: ChangeEvent<HTMLInputElement>, date?: Date | string) => {
    this.props.change('route', null);
    this.loadRoutesForDate(date);
  };

  loadRoutesForDate = (date?: Date | string) => {
    let isVehicleTypeIdCorrect: boolean;
    const unassignedOption = {
      label: `${translate('routes.unassigned')}`,
      value: UNASSIGNED_ROUTE_ID.toString(),
      vehicleTypeId: -1,
      routeStatusTypeId: 0,
      hasStopsInPendingOptimization: false,
    };

    this.props.loadRoutes(date).then(routes => {
      const routeOptions = mapRouteOptions(routes);
      routeOptions.unshift(unassignedOption);
      this.setState({
        routeOptions,
        isLoading: false,
      });
      isVehicleTypeIdCorrect = this.state.routeOptions.some(
        (el: any) =>
          el.vehicleTypeId === FRONT_LOAD_ID ||
          el.vehicleTypeId === RESIDENTIAL_ID ||
          el.vehicleTypeId === DELIVERY_UTILITY_ID,
      );

      const groupedCustomOptions = [
        unassignedOption,
        ...(isVehicleTypeIdCorrect ? getComboRouteOptions(FRONT_LOAD_ID, routes, true) : []),
      ];

      this.setState({
        groupedCustomOptions: isVehicleTypeIdCorrect ? groupedCustomOptions : [],
      });
    });
    this.setState({ isLoading: true });
  };

  changeTargetRouteId = (event: any, isVehicleTypeIdCorrect: boolean) => {
    let isOptimizedOptionHidden = false;
    let hasStopsInPendingOptimization = false;

    const { groupedCustomOptions, routeOptions } = this.state;
    const { change } = this.props;

    if (isVehicleTypeIdCorrect) {
      groupedCustomOptions.map((option: any) => {
        const currentRoute = option.options?.filter((route: any) => route.value === event);

        isOptimizedOptionHidden = currentRoute?.length
          ? currentRoute[0].routeStatusTypeId !== SCHEDULED || currentRoute[0].vehicleTypeId === ROLL_OFF_ID
          : isOptimizedOptionHidden;

        hasStopsInPendingOptimization = currentRoute?.length
          ? currentRoute[0].hasStopsInPendingOptimization
          : hasStopsInPendingOptimization;
        return isOptimizedOptionHidden;
      });
    } else {
      const currentRoute = (routeOptions as any).filter((route: any) => route.value === event)[0];

      isOptimizedOptionHidden =
        currentRoute.routeStatusTypeId !== SCHEDULED || currentRoute.vehicleTypeId === ROLL_OFF_ID;
      hasStopsInPendingOptimization = currentRoute.hasStopsInPendingOptimization;
    }

    this.setState({
      isOptimizedOptionHidden,
      isJobPositionDropdownVisible: Number(event) !== UNASSIGNED_ROUTE_ID,
    });

    change('hasStopsInPendingOptimization', hasStopsInPendingOptimization ? JOB_IS_PENDING_ID : JOB_IS_NOT_PENDING_ID);
  };

  render() {
    const { handleSubmit, hasDuplicateJobs, duplicateRouteId, onCancel } = this.props;
    const { groupedCustomOptions, isJobPositionDropdownVisible, isLoading, isOptimizedOptionHidden, routeOptions } =
      this.state;

    const isVehicleTypeIdCorrect = routeOptions.some(
      (el: any) =>
        el.vehicleTypeId === FRONT_LOAD_ID ||
        el.vehicleTypeId === RESIDENTIAL_ID ||
        el.vehicleTypeId === DELIVERY_UTILITY_ID,
    );

    return (
      <AssignJobsModalContainerForm onSubmit={handleSubmit} isLoading={isLoading}>
        <AssignJobsFormFieldContainer>
          <Field
            id="assign-jobs-date"
            name="date"
            component={DatePicker}
            label={translate('common.date')}
            validate={[isRequired, isDateValidValidator]}
            onChange={this.onDateChange}
          />
          <Field
            id="assign-jobs-route"
            name="route"
            component={Dropdown}
            label={translate('routes.route')}
            options={isVehicleTypeIdCorrect ? groupedCustomOptions : routeOptions}
            validate={[isRequired]}
            onChange={(event: any) => this.changeTargetRouteId(event, isVehicleTypeIdCorrect)}
          />
          <Field margin="no" name="hasStopsInPendingOptimization" component={Input} type="hidden" />

          {isJobPositionDropdownVisible && (
            <TypedField
              name="positionTypeId"
              component={JobPositionTypeDropdown}
              props={{
                isOptimizedOptionHidden: isOptimizedOptionHidden,
                withLabel: true,
                dropdownProps: {
                  margin: 'no',
                },
              }}
            />
          )}
        </AssignJobsFormFieldContainer>
        {hasDuplicateJobs && duplicateRouteId && (
          <DispatchBoardContainerError>
            <p>
              {translate('dispatchBoard.duplicateJobsSelectedWarning', {
                routeName:
                  routeOptions.find((r: any) => r.value === duplicateRouteId.toString()) &&
                  (routeOptions.find((r: any) => r.value === duplicateRouteId.toString()) as any).label,
              })}
            </p>
            <p>{translate('dispatchBoard.duplicateJobsSelectedConfirmation')}</p>
          </DispatchBoardContainerError>
        )}
        <AssignJobsButtonContainer hasDuplicateJobs={hasDuplicateJobs}>
          <Button id="assign-jobs-button" type="submit" color="primary">
            {hasDuplicateJobs ? translate('dispatchBoard.continueAssign') : translate('dispatchBoard.assign')}
          </Button>
          {hasDuplicateJobs && (
            <Button id="cancel-assign-jobs-button" margin="small no no no" color="secondary" onClick={onCancel}>
              {translate('common.cancel')}
            </Button>
          )}
        </AssignJobsButtonContainer>
      </AssignJobsModalContainerForm>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: {
    date: ownProps.initialDate,
    route: `${UNASSIGNED_ROUTE_ID}`,
  },
});

const mapDispatchToProps = {
  change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    form: 'assignJobsForm',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(AssignJobsModalForm),
);
