import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';
import { doLoadUsersForDriver } from '../services/usersForDriver';
import { UserForDriver } from '../interfaces/UserForDriver';

// Actions
const START_LOAD = 'vendors/vehicle/faultCodes/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicle/faultCodes/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicle/faultCodes/FAIL_LOAD';
const RESET = 'vendors/vehicle/faultCodes/RESET';

const initialState = {
  usersForDriver: [],
  isLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          usersForDriver: action.usersForDriver || [],
          isLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          usersForDriver: [],
          isLoading: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (usersForDriver: UserForDriver[] | null) => ({
  type: COMPLETE_LOAD,
  usersForDriver,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetUsersForDriver = () => ({
  type: RESET,
});

export const loadUsersForDriver = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const usersForDriverPromise = doLoadUsersForDriver(vendorId);

  usersForDriverPromise
    .then(usersForDriver => {
      dispatch(completeLoad(usersForDriver));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return usersForDriverPromise;
};
