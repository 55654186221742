import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http } from '../../core/services/http';
import translate from '../../core/services/translate';

export const uploadContainerFile = (fileData: any, vendorId: number) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http.post('/containerImport/batchUpload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getContainerUploadedFilesStatus = (vendorId: number): Promise<{ data: any[] }> =>
  http.get('/containerImport/batchList', { params: { vendorId } });

export const downloadSampleContainerTemplate = () =>
  downloadExcelFile('/containerImport/downloadTemplate', translate('containers.containerImport.sampleTemplate'));
