import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { Driver } from '../interfaces/Driver';
import {
  deleteDriver as doDeleteDriver,
  deleteResources as doDeleteResources,
  assignResources as doAssignResources,
} from '../services/driver';
import doLoadDrivers from '../services/loadDrivers';

// Actions
const START_LOAD = 'fleet/drivers/START_LOAD';
export const COMPLETE_LOAD = 'fleet/drivers/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/drivers/FAIL_LOAD';
export const SORT_DRIVERS = 'fleet/drivers/SORT_DRIVERS';
export const SEARCH_DRIVERS = 'fleet/drivers/SEARCH_DRIVERS';
export const FILTER_DRIVERS = 'fleet/drivers/FILTER_DRIVERS';
const START_DELETE = 'fleet/drivers/START_DELETE';
export const COMPLETE_DELETE = 'fleet/drivers/COMPLETE_DELETE';
const FAIL_DELETE = 'fleet/drivers/FAIL_DELETE';
const START_DELETE_RESOURCES = 'fleet/drivers/START_DELETE_RESOURCES';
const COMPLETE_DELETE_RESOURCES = 'fleet/drivers/COMPLETE_DELETE_RESOURCES';
const FAIL_DELETE_RESOURCES = 'fleet/drivers/FAIL_DELETE_RESOURCES';
const START_ASSIGN_RESOURCES = 'fleet/drivers/START_ASSIGN_RESOURCES';
const COMPLETE_ASSIGN_RESOURCES = 'fleet/drivers/COMPLETE_ASSIGN_RESOURCES';
const FAIL_ASSIGN_RESOURCES = 'fleet/drivers/FAIL_ASSIGN_RESOURCES';
const RESET = 'fleet/drivers/RESET';

type InitialState = {
  drivers: Driver[];
  isAssigningResources: boolean;
  isDeleting: boolean;
  isDeletingResources: boolean;
  isLoading: boolean;
};

// Initial state =
const initialState: InitialState = {
  drivers: [],
  isAssigningResources: false,
  isDeleting: false,
  isDeletingResources: false,
  isLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivers: action.drivers,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivers: [],
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const driverIndex = findIndex(state.drivers, { id: action.driverId });
      return update(state, {
        drivers: { $splice: [[driverIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_DELETE_RESOURCES:
      return update(state, {
        $merge: {
          isDeletingResources: true,
        },
      });

    case COMPLETE_DELETE_RESOURCES: {
      return update(state, {
        $merge: {
          isDeletingResources: false,
        },
      });
    }

    case FAIL_DELETE_RESOURCES:
      return update(state, {
        $merge: {
          isDeletingResources: false,
        },
      });

    case START_ASSIGN_RESOURCES:
      return update(state, {
        $merge: {
          isAssigningResources: true,
        },
      });

    case COMPLETE_ASSIGN_RESOURCES: {
      return update(state, {
        $merge: {
          isAssigningResources: false,
        },
      });
    }

    case FAIL_ASSIGN_RESOURCES:
      return update(state, {
        $merge: {
          isAssigningResources: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (drivers: Driver[]) => ({
  type: COMPLETE_LOAD,
  drivers,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const sortDrivers = (sortedBy: string) => ({
  type: SORT_DRIVERS,
  sortedBy,
});

export const searchDrivers = () => ({
  type: SORT_DRIVERS,
});

export const filterDrivers = () => ({
  type: FILTER_DRIVERS,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (driverId: number) => ({
  type: COMPLETE_DELETE,
  driverId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startDeleteResources = () => ({
  type: START_DELETE_RESOURCES,
});

const completeDeleteResources = () => ({
  type: COMPLETE_DELETE_RESOURCES,
});

const failDeleteResources = () => ({
  type: FAIL_DELETE_RESOURCES,
});

const startAssignResources = () => ({
  type: START_ASSIGN_RESOURCES,
});

const completeAssignResources = () => ({
  type: COMPLETE_ASSIGN_RESOURCES,
});

const failAssignResources = () => ({
  type: FAIL_ASSIGN_RESOURCES,
});

export const loadDrivers = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadDrivers(vendorId)
    .then(drivers => dispatch(completeLoad(drivers)))
    .catch(() => dispatch(failLoad()));
};

export const deleteDriver = (driverId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteDriverPromise = doDeleteDriver(driverId);
  deleteDriverPromise.then(() => dispatch(completeDelete(driverId))).catch(() => dispatch(failDelete()));
  return deleteDriverPromise;
};

export const deleteResources = (vendorId: number, deletedResourceIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startDeleteResources());
  const deleteResourcesPromise = doDeleteResources(vendorId, deletedResourceIds);
  deleteResourcesPromise.then(() => dispatch(completeDeleteResources())).catch(() => dispatch(failDeleteResources()));
  return deleteResourcesPromise;
};

export const assignResources = (vendorId: number, resources: Driver[]) => (dispatch: Dispatch) => {
  dispatch(startAssignResources());
  const assignResourcesPromise = doAssignResources(vendorId, resources);
  assignResourcesPromise.then(() => dispatch(completeAssignResources())).catch(() => dispatch(failAssignResources()));
  return assignResourcesPromise;
};

export const resetDrivers = () => ({
  type: RESET,
});
