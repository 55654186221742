import Cookie from 'js-cookie';
import { get } from 'lodash-es';

const COOKIE_KEY = 'selfCreateUser';

export const setSelfCreateUser = (user: any) => {
  Cookie.set(COOKIE_KEY, JSON.stringify({ user }));
};

export const getSelfCreateUser = (key: string) => get(JSON.parse(Cookie.get(COOKIE_KEY) || '{}'), key);

export const deleteSelfCreateUser = () => {
  Cookie.remove(COOKIE_KEY);
};
