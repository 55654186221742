import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { ModalTitle } from '../../../../../../core/components/styled';
import { Modal } from '../../../../../../core/components';
import { LoadingContainer } from '../../../../styled';

interface Props {
  title: string;
  closeModal: () => void;
  children: ReactNode;
}

const AssignJobsModal: React.FC<Props> = ({ title, closeModal, children }) => (
  <Modal onClose={closeModal} verticalSize="medium" size="small" flex>
    <Container>
      <ModalTitle>{title}</ModalTitle>
      {children}
    </Container>
  </Modal>
);

const Container = styled(LoadingContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default AssignJobsModal;
