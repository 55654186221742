import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { RouteStopItem } from 'src/dashboard/interfaces/routesData';
import { RouteSegmentExtended } from '../components/pages/streetNetwork/StreetNetworkMapGL';

import store from '../../store';
import { StreetNetwork, StreetNetworkParams } from '../interfaces/StreetNetwork';
import {
  loadStreetNetwork as doLoadStreetNetwork,
  loadStreetNetworkSegmentDetails as doLoadStreetNetworkSegmentDetails,
  loadStreetNetworkSegmentDetailsForDailyRoute as doLoadStreetNetworkSegmentDetailsForDailyRoute,
} from '../services/streetNetwork';

// Actions
const START_LOAD = 'customer/streetNetwork/START_LOAD';
const COMPLETE_LOAD = 'customer/streetNetwork/COMPLETE_LOAD';
const FAIL_LOAD = 'customer/streetNetwork/FAIL_LOAD';
const RESET_STREET_NETWORK = 'customer/streetNetwork/RESET_STREET_NETWORK';
const RESET_SNOW_OR_SWEEPER_STREET_NETWORK = 'customer/streetNetwork/RESET_SNOW_OR_SWEEPER_STREET_NETWORK';
const SET_CHECKED_STREET_NETWORK = 'customer/streetNetwork/SET_CHECKED_STREET_NETWORK';
const START_LOAD_SEGMENT_DETAILS = 'customer/streetNetwork/START_LOAD_SEGMENT_DETAILS';
const COMPLETE_LOAD_SEGMENT_DETAILS = 'customer/streetNetwork/COMPLETE_LOAD_SEGMENT_DETAILS';
const FAIL_LOAD_SEGMENT_DETAILS = 'customer/streetNetwork/FAIL_LOAD_SEGMENT_DETAILS';
const START_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE = 'customer/streetNetwork/START_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE';
const COMPLETE_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE =
  'customer/streetNetwork/COMPLETE_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE';
const FAIL_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE = 'customer/streetNetwork/FAIL_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE';
const SET_VIEWPORT = 'customer/streetNetwork/SET_VIEWPORT';
const SET_EDIT_GEO_FENCES_MODE = 'customer/streetNetwork/SET_EDIT_GEO_FENCES_MODE';
const SET_EDIT_ROUTE_GEO_FENCE_MODE = 'customer/streetNetwork/SET_EDIT_ROUTE_GEO_FENCE_MODE';
const SED_EDIT_SEGMENTS_MODE = 'customer/streetNetwork/SET_EDIT_SEGMENTS_MODE';
const SET_DRAW_SELECTION_MODE = 'customer/streetNetwork/SET_DRAW_SELECTION_MODE';
const SET_IS_LEGEND_COLLAPSED = 'customer/streetNetwork/SET_IS_LEGEND_COLLAPSED';
const SET_SEGMENT_COLOR_TYPE = 'customer/streetNetwork/SET_SEGMENT_COLOR_TYPE';

export interface State {
  driversColorMap?: { [key: string]: string };
  isDrawSelectionModeOn: boolean;
  isEditGeoFencesModeOn: boolean;
  isEditRouteGeoFenceModeOn: boolean;
  isEditSegmentsModeOn: boolean;
  isLegendCollapsed: boolean;
  isLoading: boolean;
  isLoadingSegmentDetails: boolean;
  isLoadingSegmentDetailsForDailyRoute: boolean;
  isSaving: boolean;
  segmentColorType?: 'segmentStatus' | 'agingInterval' | 'servicingDriver';
  streetNetwork: StreetNetwork[];
  streetNetworkForDashboardMap: RouteSegmentExtended[];
  streetNetworkSegmentDetails?: StreetNetwork;
  streetNetworkSegmentDetailsForDailyRoute?: StreetNetwork;
  viewport: MapGLViewport;
}

// Initial state
const initialState: State = {
  driversColorMap: {},
  isDrawSelectionModeOn: false, // to add or remove segments from the route with selection tool
  isEditGeoFencesModeOn: false, // on the street network map when edit geo fences
  isEditRouteGeoFenceModeOn: false, // on the route details page to enter in edit route geo fence mode
  isEditSegmentsModeOn: false, // on the route details page to enter in edit segments mode
  isLegendCollapsed: false,
  isLoading: false,
  isLoadingSegmentDetails: false,
  isLoadingSegmentDetailsForDailyRoute: false,
  isSaving: false,
  segmentColorType: 'segmentStatus',
  streetNetwork: [],
  streetNetworkForDashboardMap: [],
  streetNetworkSegmentDetails: undefined,
  streetNetworkSegmentDetailsForDailyRoute: undefined,
  viewport: {},
};

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetNetwork: action.streetNetwork,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET_STREET_NETWORK:
      return update(state, {
        $merge: {
          isLoading: false,
          streetNetworkForDashboardMap: [],
          streetNetworkSegmentDetails: undefined,
        },
      });

    case RESET_SNOW_OR_SWEEPER_STREET_NETWORK:
      return update(state, {
        $merge: {
          isLoading: false,
          streetNetwork: [],
          streetNetworkSegmentDetails: undefined,
        },
      });

    case SET_CHECKED_STREET_NETWORK:
      return update(state, {
        $merge: {
          streetNetworkForDashboardMap: action.streetNetworkForDashboardMap,
        },
      });

    case START_LOAD_SEGMENT_DETAILS:
      return update(state, {
        $merge: {
          isLoadingSegmentDetails: true,
        },
      });

    case COMPLETE_LOAD_SEGMENT_DETAILS:
      return update(state, {
        $merge: {
          isLoadingSegmentDetails: false,
          streetNetworkSegmentDetails: action.streetNetworkSegmentDetails,
        },
      });

    case FAIL_LOAD_SEGMENT_DETAILS:
      return update(state, {
        $merge: {
          isLoadingSegmentDetails: false,
        },
      });

    case START_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE:
      return update(state, {
        $merge: {
          isLoadingSegmentDetailsForDailyRoute: true,
        },
      });

    case COMPLETE_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE:
      return update(state, {
        $merge: {
          isLoadingSegmentDetailsForDailyRoute: false,
          streetNetworkSegmentDetailsForDailyRoute: action.streetNetworkSegmentDetailsForDailyRoute,
        },
      });

    case FAIL_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE:
      return update(state, {
        $merge: {
          isLoadingSegmentDetailsForDailyRoute: false,
        },
      });

    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    case SET_EDIT_GEO_FENCES_MODE:
      return update(state, {
        $merge: {
          isEditGeoFencesModeOn: action.isEditGeoFencesModeOn,
        },
      });

    case SET_EDIT_ROUTE_GEO_FENCE_MODE:
      return update(state, {
        $merge: {
          isEditRouteGeoFenceModeOn: action.isEditRouteGeoFenceModeOn,
        },
      });

    case SET_DRAW_SELECTION_MODE:
      return update(state, {
        $merge: {
          isDrawSelectionModeOn: action.isDrawSelectionModeOn,
        },
      });

    case SED_EDIT_SEGMENTS_MODE:
      return update(state, {
        $merge: {
          isEditSegmentsModeOn: action.isEditSegmentsModeOn,
        },
      });

    case SET_SEGMENT_COLOR_TYPE:
      return update(state, {
        $merge: {
          segmentColorType: action.segmentColorType,
        },
      });

    case SET_IS_LEGEND_COLLAPSED:
      return update(state, {
        $merge: {
          isLegendCollapsed: action.isLegendCollapsed,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (streetNetwork: StreetNetwork[]) => ({
  type: COMPLETE_LOAD,
  streetNetwork,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadSegmentDetails = () => ({
  type: START_LOAD_SEGMENT_DETAILS,
});

const completeLoadSegmentDetails = (streetNetworkSegmentDetails: StreetNetwork) => ({
  type: COMPLETE_LOAD_SEGMENT_DETAILS,
  streetNetworkSegmentDetails,
});

const failLoadSegmentDetails = () => ({
  type: FAIL_LOAD_SEGMENT_DETAILS,
});

const startLoadSegmentDetailsForDailyRoute = () => ({
  type: START_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE,
});

const completeLoadSegmentDetailsForDailyRoute = (streetNetworkSegmentDetailsForDailyRoute: StreetNetwork) => ({
  type: COMPLETE_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE,
  streetNetworkSegmentDetailsForDailyRoute,
});

const failLoadSegmentDetailsForDailyRoute = () => ({
  type: FAIL_LOAD_SEGMENT_DETAILS_FOR_DAILY_ROUTE,
});

export const setSegmentColoringType = (segmentColorType: 'segmentStatus' | 'agingInterval' | 'servicingDriver') => {
  return {
    type: SET_SEGMENT_COLOR_TYPE,
    segmentColorType,
  };
};

export const loadStreetNetwork =
  ({
    vendorId,
    routeId,
    routeTemplateId,
    includeRouteTemplateIds,
    streetSegmentAssignedStatus,
    streetSegmentAssignedTypesStatus,
    streetSegmentStatus,
    streetSegmentNumberOfLanes,
    streetSegmentNumberOfPasses,
    streetSegmentServiceSides,
    driverIds,
    pickupStatusTypeIds,
    segmentsFilterEndDate,
    segmentsFilterStartDate,
    noLoadingIndicator,
    alternativeFleetServiceTypeIds,
    vehicleTypeId,
  }: StreetNetworkParams) =>
  (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoad());
    const loadStreetNetworkPromise = doLoadStreetNetwork(
      vendorId,
      routeId,
      routeTemplateId,
      includeRouteTemplateIds,
      streetSegmentAssignedStatus,
      streetSegmentAssignedTypesStatus,
      streetSegmentStatus,
      streetSegmentNumberOfLanes,
      streetSegmentNumberOfPasses,
      streetSegmentServiceSides,
      driverIds,
      pickupStatusTypeIds,
      segmentsFilterEndDate,
      segmentsFilterStartDate,
      alternativeFleetServiceTypeIds,
      vehicleTypeId,
    );
    loadStreetNetworkPromise
      .then((streetNetwork: StreetNetwork[]) => dispatch(completeLoad(streetNetwork)))
      .catch(() => dispatch(failLoad()));
    return loadStreetNetworkPromise;
  };

export const loadStreetNetworkSegmentDetails =
  (vendorId: number, segmentId: number, routeId?: number, vehicleTypeId?: number) => (dispatch: Dispatch) => {
    dispatch(startLoadSegmentDetails());

    const state = store.getState();
    const segmentsFilterEndDate = state.form.snowSweeperDatePickerForm?.values?.startDate;
    const segmentsFilterStartDate = state.form.snowSweeperDatePickerForm?.values?.endDate;

    const loadStreetNetworkSegmentDetailsPromise = doLoadStreetNetworkSegmentDetails(
      vendorId,
      segmentId,
      routeId,
      vehicleTypeId,
      segmentsFilterEndDate,
      segmentsFilterStartDate,
    );
    loadStreetNetworkSegmentDetailsPromise
      .then((streetNetworkSegmentDetails: StreetNetwork) =>
        dispatch(completeLoadSegmentDetails(streetNetworkSegmentDetails)),
      )
      .catch(() => dispatch(failLoadSegmentDetails()));
    return loadStreetNetworkSegmentDetailsPromise;
  };

export const loadStreetNetworkSegmentDetailsForDailyRoute =
  (vendorId: number, segmentId: number, routeId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadSegmentDetailsForDailyRoute());

    const loadStreetNetworkSegmentDetailsForDailyRoutePromise = doLoadStreetNetworkSegmentDetailsForDailyRoute(
      vendorId,
      segmentId,
      routeId,
    );
    loadStreetNetworkSegmentDetailsForDailyRoutePromise
      .then((streetNetworkSegmentDetailsForDailyRoute: StreetNetwork) =>
        dispatch(completeLoadSegmentDetailsForDailyRoute(streetNetworkSegmentDetailsForDailyRoute)),
      )
      .catch(() => dispatch(failLoadSegmentDetailsForDailyRoute()));

    return loadStreetNetworkSegmentDetailsForDailyRoutePromise;
  };

export const setCheckedDashboardStreetNetwork = (streetNetworkForDashboardMap: RouteStopItem[]) => {
  return {
    type: SET_CHECKED_STREET_NETWORK,
    streetNetworkForDashboardMap,
  };
};

export const resetDashboardStreetNetwork = () => {
  return {
    type: RESET_STREET_NETWORK,
  };
};

export const resetSnowOrSweeperStreetNetwork = () => {
  return {
    type: RESET_SNOW_OR_SWEEPER_STREET_NETWORK,
  };
};

export const setStreetNetworkMapViewport = (viewport: MapGLViewport) => {
  return {
    type: SET_VIEWPORT,
    viewport,
  };
};

export const setIsEditGeoFencesModeOn = (isEditGeoFencesModeOn: boolean) => {
  return {
    type: SET_EDIT_GEO_FENCES_MODE,
    isEditGeoFencesModeOn,
  };
};

export const setIsEditRouteGeoFenceModeOn = (isEditRouteGeoFenceModeOn: boolean) => {
  return {
    type: SET_EDIT_ROUTE_GEO_FENCE_MODE,
    isEditRouteGeoFenceModeOn,
  };
};

export const setIsEditSegmentsModeOn = (isEditSegmentsModeOn: boolean) => {
  return {
    type: SED_EDIT_SEGMENTS_MODE,
    isEditSegmentsModeOn,
  };
};

export const setIsDrawSelectionModeOn = (isDrawSelectionModeOn: boolean) => {
  return {
    type: SET_DRAW_SELECTION_MODE,
    isDrawSelectionModeOn,
  };
};

export const setIsLegendCollapsed = (isLegendCollapsed: boolean) => {
  return {
    type: SET_IS_LEGEND_COLLAPSED,
    isLegendCollapsed,
  };
};
