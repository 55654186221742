import React, { Fragment, PureComponent } from 'react';

import humps from 'humps';
import { find, map } from 'lodash-es';

import { date, time } from '../../../utils/services/formatter';
import {
  EXCEPTIONS,
  PARTICIPATION,
  WASTE_AUDIT_EXCEPTION_TYPES,
  WASTE_AUDIT_LOCATION_STATUS_TYPES,
} from '../../constants';
import { Grid, GridColumn, TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import { Image } from 'src/routes/interfaces/RouteImages';
import { RouteLocationImage } from 'src/routes/interfaces/RouteLocationImage';
import { RouteLocationImagesModalResolver } from '.';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { ActionButtonTooltip, ThumbnailSet } from '../../../core/components';
import { WASTE_AUDIT } from '../../../common/constants';
import translate from '../../../core/services/translate';
import { CustomerEmailModalResolver } from 'src/customers/components/modals/CustomerEmailModalResolver';

interface Props {
  customerEmail?: string;
  description?: string;
  driverName?: string;
  elapsedTime?: string;
  id?: number;
  image?: Image;
  images?: Image[];
  numericValue?: number;
  pickupExceptionSubType?: TechnicalType;
  pickupIssueReportedReasonTypeName: string;
  routeLocationId?: number;
  routeLocationIssues: RouteLocationImage[];
  sourceName?: string;
  sourceTypeName?: string;
  timeStamp?: string;
  vehicleTypeId?: number;
  vehicleTypeTechnicalName?: string;
  wasteAuditType: TechnicalType;
}

interface State {
  isRouteIssueImagesModalOpen: boolean;
  isEmailModalOpen: boolean;
}

class RouteLocationIssuesModalTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isRouteIssueImagesModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  getLocationIssues = (
    vehicleTypeTechnicalName: string,
    routeLocationIssue: RouteLocationImage,
    wasteAuditType: TechnicalType,
    pickupIssueReportedReasonTypeName: string,
  ) => {
    if (vehicleTypeTechnicalName === WASTE_AUDIT) {
      switch (wasteAuditType.id) {
        case EXCEPTIONS: {
          return map(
            routeLocationIssue.wasteAuditStatuses,
            wasteAuditStatus => WASTE_AUDIT_EXCEPTION_TYPES[wasteAuditStatus].name,
          );
        }
        case PARTICIPATION:
          return map(
            routeLocationIssue.wasteAuditStatus,
            wasteAuditStatus => WASTE_AUDIT_LOCATION_STATUS_TYPES[wasteAuditStatus].name,
          );
        default:
          return [];
      }
    }
    return [pickupIssueReportedReasonTypeName];
  };

  openRouteLocationIssueImagesModal = () => {
    this.setState({
      isRouteIssueImagesModalOpen: true,
    });
  };

  closeRouteLocationIssueImagesModal = () => {
    this.setState({
      isRouteIssueImagesModalOpen: false,
    });
  };

  toggleEmailModal = () => {
    this.setState({ isEmailModalOpen: !this.state.isEmailModalOpen });
  };

  render() {
    const {
      customerEmail,
      description,
      driverName,
      elapsedTime,
      id: routeLocationDetailPickupExceptionId,
      image,
      images,
      numericValue,
      pickupExceptionSubType,
      pickupIssueReportedReasonTypeName,
      routeLocationId,
      routeLocationIssues,
      sourceName,
      sourceTypeName,
      timeStamp: dateTime,
      vehicleTypeId,
      vehicleTypeTechnicalName = '',
      wasteAuditType,
    } = this.props;

    const { isRouteIssueImagesModalOpen, isEmailModalOpen } = this.state;
    const imageUrls: string[] = [];
    const imageIds: number[] = [];

    const imagesForEmail = [];

    if (images?.length) {
      images.forEach(image => {
        imageUrls.push(image.url);
        imageIds.push(image.imageId);
        imagesForEmail.push({
          imageUrl: image.url,
          imageBlobId: image.blobId,
          timeStamp: image.timeStamp || '',
        });
      });
    } else if (image) {
      imageIds.push(image.imageId);
      imageUrls.push(image.url);
      imagesForEmail.push({
        imageUrl: image.url,
        imageBlobId: image.blobId,
        timeStamp: image.timeStamp || '',
      });
    }

    const routeLocationIssue = find(routeLocationIssues, { routeLocationId });

    if (!routeLocationIssue) return null;

    const routeId = routeLocationIssue?.routeId || 0;

    const locationIssues = this.getLocationIssues(
      vehicleTypeTechnicalName,
      routeLocationIssue,
      wasteAuditType,
      pickupIssueReportedReasonTypeName,
    );

    return (
      <Fragment>
        <TableRow>
          <TableCell width="15%">
            <Grid>
              <GridColumn width="100%" padding="no">
                <Text block margin="no no xxSmall">
                  {date(dateTime)}
                </Text>
                <Text block size="small">
                  {time(dateTime)}
                </Text>
              </GridColumn>
            </Grid>
          </TableCell>

          <TableCell width="20%">
            <Grid>
              <GridColumn width="100%" padding="no">
                {map(locationIssues, (issue, index) => (
                  <Text block size="medium" key={index}>
                    {issue}
                  </Text>
                ))}
                {pickupExceptionSubType && (
                  <Text block size="small">
                    {translate(
                      `routes.pickupIssueTypes.exceptionSubTypes.${humps.camelize(
                        pickupExceptionSubType.technicalName,
                      )}`,
                    )}
                  </Text>
                )}

                {elapsedTime && (
                  <Text block size="small">
                    {elapsedTime}
                  </Text>
                )}
                {numericValue && (
                  <Text block size="small">
                    {numericValue}
                  </Text>
                )}
              </GridColumn>
            </Grid>
          </TableCell>
          <TableCell width="20%">
            <Grid>
              <GridColumn width="100%" padding="no">
                <Text block margin="no no xxSmall">
                  {description || '-'}
                </Text>
              </GridColumn>
            </Grid>
          </TableCell>
          <TableCell width="17%">
            <Grid>
              <GridColumn width="100%" padding="no">
                <Text block margin="no no xxSmall">
                  {driverName || sourceName}
                </Text>
                <Text block size="small">
                  {sourceTypeName}
                </Text>
              </GridColumn>
            </Grid>
          </TableCell>
          <TableCell width="15%">
            <TableActionButton onClick={this.toggleEmailModal}>
              <ActionButtonTooltip size="medium" icon="email" tooltip="sendEmail" />
            </TableActionButton>
          </TableCell>
          <TableCell width="13%">
            {!!imageUrls.length && (
              <ThumbnailSet images={imageUrls} onClick={() => this.openRouteLocationIssueImagesModal()} />
            )}
          </TableCell>
        </TableRow>

        {isRouteIssueImagesModalOpen && (
          <RouteLocationImagesModalResolver
            imageIds={imageIds}
            routeId={routeId}
            routeLocationId={routeLocationId}
            closeModal={this.closeRouteLocationIssueImagesModal}
          />
        )}

        {isEmailModalOpen && (
          <CustomerEmailModalResolver
            routeLocationId={routeLocationId}
            onClose={this.toggleEmailModal}
            routeLocationDetailPickupExceptionId={routeLocationDetailPickupExceptionId}
            routeId={routeId}
            email={{
              toEmail: customerEmail,
              emailSubject: `${date(dateTime)}: ${locationIssues.join(', ')}`,
              images: imagesForEmail,
              vehicleTypeId,
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default RouteLocationIssuesModalTableRow;
