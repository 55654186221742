import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadContactTypes as doLoadContactTypes } from 'src/common/services/contactTypes';
import { ContactType } from '../interfaces/ContactType';

const START_LOAD = 'common/contactTypes/START_LOAD';
const COMPLETE_LOAD = 'common/contactTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/contactTypes/FAIL_LOAD';
const RESET = 'common/contactTypes/RESET';

type State = {
  contactTypes: ContactType[];
  isLoading: boolean;
};

const initialState: State = {
  contactTypes: [],
  isLoading: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          contactTypes: action.contactTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          contactTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (contactTypes: ContactType[]) => ({
  type: COMPLETE_LOAD,
  contactTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContactTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContactTypesPromise = doLoadContactTypes();
  loadContactTypesPromise.then(contactTypes => dispatch(completeLoad(contactTypes))).catch(() => dispatch(failLoad()));
  return loadContactTypesPromise;
};

export const resetContactTypes = () => ({
  type: RESET,
});
