import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const SCHEDULED = 1;
export const INPROGRESS = 2;
export const COMPLETED = 3;
export const ISSUE_REPORTED = 6;
export const SERVICED = 7;
export const PLACED_ON_HOLD = 8;

export const JOB_STATUSES = mapKeys(
  [
    { id: SCHEDULED, name: translate('common.pickupStatuses.scheduled') },
    { id: INPROGRESS, name: translate('common.pickupStatuses.inProgress') },
    { id: COMPLETED, name: translate('common.pickupStatuses.completed') },
    { id: ISSUE_REPORTED, name: translate('common.pickupStatuses.issueReported') },
    { id: SERVICED, name: translate('common.pickupStatuses.serviced') },
    { id: PLACED_ON_HOLD, name: translate('common.pickupStatuses.placedOnHold') },
  ],
  'id',
);

export const SNOW_SWEEPER_JOB_STATUSES = mapKeys(
  [
    { id: SCHEDULED, name: translate('common.snowSweeperPickupStatuses.scheduled') },
    { id: INPROGRESS, name: translate('common.snowSweeperPickupStatuses.inProgress') },
    { id: COMPLETED, name: translate('common.snowSweeperPickupStatuses.completed') },
  ],
  'id',
);

export const JOB_PENDING_OPTIMIZATION_ID = -1;

export const JOB_IS_PENDING_ID = 1;
export const JOB_IS_NOT_PENDING_ID = 0;
