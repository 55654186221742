import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadStreetsImportUploadedFilesStatus } from 'src/customers/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import StreetsImportModal from './StreetsImportModal';

interface Props {
  closeModal: () => void;
  isSnowPlow: boolean;
}

export const StreetsImportModalResolver: React.FC<Props> = ({ closeModal, isSnowPlow }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies = [loadStreetsImportUploadedFilesStatus()(dispatch)];

    return Promise.all(dependencies);
  }, [dispatch, vendorId]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={StreetsImportModal}
      successProps={{ closeModal, isSnowPlow }}
    />
  );
};
