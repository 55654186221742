import React from 'react';

import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { arrayOfNumbers } from '../../../utils/services/normalizer';
import { EventTypeMultiSelect } from './eventLogFormSections';

interface FormValues {
  eventTypeIds: number[];
}

interface PropsWithoutReduxForm {
  routeId: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const EventLogForm = ({ handleSubmit, routeId }: Props) => (
  <form onSubmit={handleSubmit}>
    <PanelSection padding="small xSmall" withBorder>
      <Grid>
        <GridColumn size="4/12">
          <Field
            name="eventTypeIds"
            component={EventTypeMultiSelect}
            placeholder={translate('routes.allEventTypes')}
            multiSelectProps={{ margin: 'no', normalize: arrayOfNumbers }}
          />
        </GridColumn>

        <GridColumn size="8/12" align="right">
          <Button id={`route-${routeId}-event-log-form-search-button`} color="primary">
            {translate('common.search')}
          </Button>
        </GridColumn>
      </Grid>
    </PanelSection>
  </form>
);

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'eventLog',
})(EventLogForm);
