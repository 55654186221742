import { useEffect } from 'react';

import { push, Push } from 'connected-react-router';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';

import { getServiceZonesFiltersPreferencesIds, getSupervisorsFiltersPreferencesIds } from 'src/common/utils/filters';
import { DuckAction, DuckFunction } from 'src/contracts/ducks';
import { TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { DispatchBoardTargetForm } from 'src/routes/components/forms';
import { DispatchBoardTargetFormFilters } from 'src/routes/components/forms/DispatchBoardTargetForm';
import { DispatchBoardFilters, DispatchBoardPanel, DispatchBoardPanelContent } from 'src/routes/components/styled';
import JobTypes from 'src/routes/constants/jobTypes';
import { ROUTE_STATUSES } from 'src/routes/constants/routeStatuses';
import {
  deleteDispatchBoardTargetRoute,
  deleteDispatchBoardTargetRouteJob,
  loadDispatchBoardSourceRouteJobs,
  loadDispatchBoardTargetRouteJobs,
  loadDispatchBoardTargetRoutes,
  loadDispatchBoardUnassignedJobs,
  moveDispatchBoardTargetRouteJobToPosition,
  resetDispatchBoardTargetRouteJobs,
  setTargetDate,
} from 'src/routes/ducks/dispatchBoard';
import { loadDispatchBoardOnHoldJobs } from 'src/routes/ducks/dispatchBoard/dispatchBoardOnHoldJobs';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';
import { getAllWithOptionNone } from 'src/routes/services/dispatchBoardGetAllWithOptionNone';
import { AppState } from 'src/store';
import { usePrevious } from 'src/utils/hooks';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { DispatchBoardQueryParams } from '../DispatchBoardPage';
import DispatchBoardRouteDetails from './DispatchBoardRouteDetails';
import DispatchBoardRoutes from './DispatchBoardRoutes';
import dispatchBoardFilteredSourceRouteJobsSelector from 'src/routes/services/dispatchBoardFilteredSourceRouteJobsSelector';

interface Props extends RouteComponentProps {
  isDeletingRoute: boolean;
  isLoading: boolean;
  isLoadingRouteJobs: boolean;
  jobsTransferring: boolean;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: any[],
    position?: number,
    type?: string,
  ) => void;
  push: Push;
  routeDetails: any;
  routeJobs: any[];
  serviceZonesFiltersPreferencesIds: number[];
  supervisorsFiltersPreferencesIds: number[];
  targetRoutes: any[];
  unassignedJobsTotalCount: number;
  vehicleTypeId: number;
  vendorId: number;

  deleteDispatchBoardTargetRoute: DuckFunction<typeof deleteDispatchBoardTargetRoute>;
  deleteDispatchBoardTargetRouteJob: DuckFunction<typeof deleteDispatchBoardTargetRouteJob>;
  loadDispatchBoardOnHoldJobs: DuckFunction<typeof loadDispatchBoardOnHoldJobs>;
  loadDispatchBoardSourceRouteJobs: DuckFunction<typeof loadDispatchBoardSourceRouteJobs>;
  loadDispatchBoardTargetRouteJobs: DuckFunction<typeof loadDispatchBoardTargetRouteJobs>;
  loadDispatchBoardTargetRoutes: DuckFunction<typeof loadDispatchBoardTargetRoutes>;
  loadDispatchBoardUnassignedJobs: DuckFunction<typeof loadDispatchBoardUnassignedJobs>;
  moveDispatchBoardTargetRouteJobToPosition: DuckFunction<typeof moveDispatchBoardTargetRouteJobToPosition>;
  resetDispatchBoardTargetRouteJobs: DuckAction<typeof resetDispatchBoardTargetRouteJobs>;
  setTargetDate: DuckAction<typeof setTargetDate>;
  toggleJobTransferring: (value: boolean) => void;
}

const DispatchBoardTargets = (props: Props) => {
  const {
    isDeletingRoute,
    isLoading,
    isLoadingRouteJobs,
    jobsTransferring,
    routeDetails,
    routeJobs,
    serviceZonesFiltersPreferencesIds,
    supervisorsFiltersPreferencesIds,
    targetRoutes,
    unassignedJobsTotalCount,
    vehicleTypeId,
    vendorId,

    deleteDispatchBoardTargetRoute,
    deleteDispatchBoardTargetRouteJob,
    loadDispatchBoardOnHoldJobs,
    loadDispatchBoardSourceRouteJobs,
    loadDispatchBoardTargetRouteJobs,
    loadDispatchBoardTargetRoutes,
    loadDispatchBoardUnassignedJobs,
    moveDispatchBoardTargetRouteJobToPosition,
    onJobDrop,
    push,
    resetDispatchBoardTargetRouteJobs,
    toggleJobTransferring,
  } = props;

  const { pathname, search } = useLocation<DispatchBoardQueryParams>();

  const allServiceZones = useSelector(state => getAllWithOptionNone(state.routes.serviceZones.serviceZones || []));
  const allSupervisors = useSelector(state => getAllWithOptionNone(state.routes.supervisors.supervisors || []));

  const {
    date: sourceDate = TODAY_FORMATTED,
    targetDate = TODAY_FORMATTED,
    searchTerm: sourceSearchTerm = '',
    targetRouteId: _targetRouteId,
    targetSearchTerm = '',
    targetGroupIds,
  } = getQueryParams<DispatchBoardQueryParams>(search);
  const {
    targetRouteStatuses = map(ROUTE_STATUSES, 'id'),
    targetServiceZones = serviceZonesFiltersPreferencesIds,
    targetSupervisors = supervisorsFiltersPreferencesIds,
  } = getQueryParams<DispatchBoardQueryParams>(search, { mapTypeIdsToArray: true });

  const targetRouteId = _targetRouteId ? Number(_targetRouteId) : undefined;

  const filters = {
    sourceSearchTerm,
    targetDate,
    targetRouteStatuses,
    targetSearchTerm,
    targetServiceZones,
    targetSupervisors,
    targetGroupIds,
  };

  const updateQueryParams = ({
    targetRouteStatuses,
    targetServiceZones,
    targetSupervisors,
    ...paramsToUpdate
  }: Partial<DispatchBoardQueryParams>) => {
    push(
      createUrl(pathname, search, {
        ...paramsToUpdate,
        targetRouteStatuses:
          targetRouteStatuses && ROUTE_STATUSES.length !== targetRouteStatuses.length
            ? targetRouteStatuses.toString()
            : undefined,
        serviceZones:
          targetServiceZones && allServiceZones?.length !== targetServiceZones.length
            ? targetServiceZones.toString()
            : undefined,
        supervisors:
          targetSupervisors && allSupervisors?.length !== targetSupervisors.length
            ? targetSupervisors.toString()
            : undefined,
      }),
    );
  };

  const handleLoadRoutes = (f: DispatchBoardTargetFormFilters = filters) => {
    loadDispatchBoardTargetRoutes({
      vendorId,
      vehicleTypeId,
      date: f.targetDate,
      routeStatuses: ROUTE_STATUSES.length === f.targetRouteStatuses?.length ? [] : f.targetRouteStatuses,
      searchTerm: f.targetSearchTerm,
      serviceZones: allServiceZones?.length === f.targetServiceZones?.length ? [] : f.targetServiceZones,
      supervisors: allSupervisors?.length === f.targetSupervisors?.length ? [] : f.targetSupervisors,
      groupIds: f.targetGroupIds,
    });

    if (targetRouteId !== undefined) {
      loadDispatchBoardTargetRouteJobs(targetRouteId, f.targetSearchTerm);
    }
  };

  const handleLoadUnassignedJobs = () =>
    loadDispatchBoardUnassignedJobs(vendorId, vehicleTypeId, filters.sourceSearchTerm);

  const handleLoadOnHoldJobs = () => loadDispatchBoardOnHoldJobs(vendorId, sourceDate, [], filters.sourceSearchTerm);

  const openRouteDetails = (route: DispatchBoardSimpleRoute) => {
    updateQueryParams({ targetRouteId: route.id });
  };

  const closeRouteDetails = () => {
    updateQueryParams({ targetRouteId: undefined });
  };

  useEffect(() => {
    if (vendorId != null && vehicleTypeId != null) handleLoadRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId, vehicleTypeId]);

  const prevVehicleTypeId: number = usePrevious<number>(vehicleTypeId);
  const prevTargetRoutesLength: number = usePrevious<number>(targetRoutes.length);

  useEffect(() => {
    if (!!prevVehicleTypeId && vehicleTypeId !== prevVehicleTypeId) {
      closeRouteDetails();
      handleLoadRoutes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypeId]);

  useEffect(() => {
    if (targetRouteId !== undefined && !!prevTargetRoutesLength && !targetRoutes.find(tR => tR.id === targetRouteId)) {
      closeRouteDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRouteId, targetRoutes]);

  const onTargetFormSubmit = (f: DispatchBoardTargetFormFilters) => {
    handleLoadRoutes(f);
    setTargetDate(f.targetDate);
    updateQueryParams(f);
  };

  const selectedTargetRoute = targetRoutes.find(tR => tR.id === targetRouteId);

  return (
    <DispatchBoardPanel isLoading={isLoading}>
      <DispatchBoardPanelContent isHidden={targetRouteId !== undefined}>
        <DispatchBoardFilters>
          <DispatchBoardTargetForm initialValues={filters} onSubmit={onTargetFormSubmit} />
        </DispatchBoardFilters>

        {!!targetRoutes.length && (
          <DispatchBoardRoutes
            hasUnassignedJobs={unassignedJobsTotalCount > 0}
            onJobDrop={onJobDrop}
            onRouteClick={openRouteDetails}
            routes={targetRoutes}
            type={JobTypes.target}
          />
        )}
      </DispatchBoardPanelContent>

      {targetRouteId !== undefined && (
        <DispatchBoardRouteDetails
          deleteJob={deleteDispatchBoardTargetRouteJob}
          deleteRoute={deleteDispatchBoardTargetRoute}
          filteredJobsSelector={dispatchBoardFilteredSourceRouteJobsSelector as any}
          isDeletingRoute={isDeletingRoute}
          isLoadingRouteJobs={isLoadingRouteJobs}
          jobsTransferring={jobsTransferring}
          loadOppositeRouteJobs={loadDispatchBoardSourceRouteJobs}
          loadRouteJobs={loadDispatchBoardTargetRouteJobs}
          moveJobToPosition={moveDispatchBoardTargetRouteJobToPosition}
          onClose={closeRouteDetails}
          onJobDrop={onJobDrop}
          refreshUnassignedJobs={handleLoadUnassignedJobs}
          refreshOnHoldJobs={handleLoadOnHoldJobs}
          resetRouteJobs={resetDispatchBoardTargetRouteJobs}
          route={selectedTargetRoute}
          routeDetails={routeDetails}
          routeJobs={routeJobs}
          routeSearchTerm={targetSearchTerm}
          targetDate={targetDate}
          targetRouteId={targetRouteId}
          toggleJobTransferring={toggleJobTransferring}
          type={JobTypes.target}
          vehicleTypeId={vehicleTypeId}
        />
      )}
    </DispatchBoardPanel>
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    routes: { dispatchBoard },
  } = state;
  const { filters } = state.common.filters;

  return {
    isDeletingRoute: dispatchBoard.targetRoutes.isDeletingRoute,
    isLoadingRouteJobs: dispatchBoard.targetRouteJobs.isLoading,
    routeDetails: dispatchBoard.targetRouteJobs.routeDetails,
    routeJobs: dispatchBoard.targetRouteJobs.routeJobs,
    serviceZonesFiltersPreferencesIds: getServiceZonesFiltersPreferencesIds(filters),
    supervisorsFiltersPreferencesIds: getSupervisorsFiltersPreferencesIds(filters),
    targetDate: dispatchBoard.targetRoutes.selectedDate,
    targetRoutes: dispatchBoard.targetRoutes.targetRoutes,
    unassignedJobsTotalCount: dispatchBoard.unassignedJobs.unassignedJobsCount.totalCount,
  };
};

const mapDispatchToProps = {
  deleteDispatchBoardTargetRoute,
  deleteDispatchBoardTargetRouteJob,
  loadDispatchBoardSourceRouteJobs,
  loadDispatchBoardTargetRouteJobs,
  loadDispatchBoardTargetRoutes,
  loadDispatchBoardUnassignedJobs,
  loadDispatchBoardOnHoldJobs,
  moveDispatchBoardTargetRouteJobToPosition,
  push,
  resetDispatchBoardTargetRouteJobs,
  setTargetDate,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DispatchBoardTargets));
