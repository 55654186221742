import { http } from '../../core/services/http';
import { WaterFills } from '../interfaces/WaterFills';

export const createWaterFill = (routeId: number, waterFill: WaterFills) =>
  http.post(`streetSweeperMaterialPickUpTicket`, { ...waterFill, routeId }).then(response => response.data);

export const updateWaterFill = (waterFill: WaterFills) =>
  http.put(`streetSweeperMaterialPickUpTicket/${waterFill.id}`, waterFill).then(response => response.data);

export const loadWaterFill = (waterFillId: number) =>
  http.get(`streetSweeperMaterialPickUpTicket/${waterFillId}`).then(response => response.data);

export const loadWaterFills = (routeId: number) =>
  http
    .get(`routes/${routeId}/streetSweeperMaterialPickUpTickets`, { params: { routeId } })
    .then(response => response.data);

export const deleteWaterFill = (waterFillId: number) => http.delete(`streetSweeperMaterialPickUpTicket/${waterFillId}`);

export const loadStreetSweeperUnitOfMeasureValuesWaterFill = () =>
  http
    .get('common/systemTypes/streetSweeperMaterialPickUpTicketUnitOfMeasureTypeValues')
    .then(response => response.data);

export const loadStreetSweeperMaterialTypeValuesWaterFill = () =>
  http.get('common/systemTypes/streetSweeperMaterialPickUpTicketMaterialTypeValues').then(response => response.data);

export const uploadWaterFillsImage = async (file: File, routeId: number) => {
  const formData = new FormData();
  const dataObject = JSON.stringify([{ routeId: routeId, imageSourceTypeId: 1 }]);

  formData.append('Image', file);
  formData.append('data', dataObject);

  const response = await http.post('images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};
