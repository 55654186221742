import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { colorMapper, sizeMapper } from '../../../utils/styles';

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 18px;
`;

export const ProgressBarContainer = styled.div<{ margin?: string; withBorder?: boolean }>`
  width: 80%;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.withBorder &&
    css`
      border: 1px solid ${props => props.theme.brandPrimary};
    `};
`;

export const ProgressBarDiv = styled.div<{ progress: number }>`
  background-color: ${props => props.theme.brandPrimary};
  width: ${props => `${props.progress}%`};
  transition: width 200ms;
  height: 18px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border: 1px solid ${props => props.theme.grayLight};
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};
  padding: 24px 46px;
`;

interface ProgressDoughnutProps {
  progress?: number;
  size: number;
  color?: string;
}

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  border: none;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: ${transparentize(0.4, '#000')};
`;

export const ProgressRoundedDoughnutWrapper = styled.div<ProgressDoughnutProps>`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;

  ${props =>
    props.color &&
    css`
      background-color: ${colorMapper(props)};
    `};
`;

export const ProgressRoundedDoughnut = styled.div<ProgressDoughnutProps>`
  position: absolute;
  bottom: 0;
  right: -1px;
  width: ${props => 101 - (props.progress || 0)}%;
  height: ${props => props.size + 2}px;
  border-radius: 50%;
  background-color: #fff;
  transition: width 200ms;
`;

export const ProgressRoundedDoughnutText = styled.div<ProgressDoughnutProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: ${props => props.size / 3.7}px;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  background-color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
