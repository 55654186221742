import React from 'react';
import TimePicker from 'react-time-picker';

import { FormGroup, FormLabel, FormError } from './styled';
import { WrappedFieldProps } from 'redux-form';
import FormMeta from './styled/FormMeta';
import { TimePickerWrapper } from './styled/TimePicker';

interface Props extends WrappedFieldProps {
  disabled?: boolean;
  errorOnSingleLine?: boolean;
  fieldNotTouched?: boolean;
  fixedWidth?: boolean;
  format?: string;
  isLoading?: boolean;
  isTimeField?: boolean;
  label?: string;
  margin?: string;
  maxDetail?: 'second' | 'minute' | 'hour';
  maxTime?: string;
  metaInfo?: string;
  name?: string;
  onTimeChange?: (value: Date | string) => void;
  placeholder?: string;
  raisedLabel?: boolean;
  readOnly?: boolean;
  showErrorBeforeSubmit?: boolean;
  width?: string;
}

const TimePickerInput: React.SFC<Props> = ({
  disabled,
  errorOnSingleLine,
  fieldNotTouched,
  fixedWidth,
  format,
  input,
  isLoading,
  isTimeField,
  label,
  margin,
  maxDetail,
  maxTime,
  meta: { asyncValidating, submitFailed, error },
  metaInfo,
  name,
  onTimeChange,
  placeholder,
  raisedLabel,
  readOnly,
  showErrorBeforeSubmit,
  width,
  ...props
}) => {
  return (
    <FormGroup
      hasValue={input.value}
      isLoading={isLoading || asyncValidating}
      margin={margin}
      width={width}
      raisedLabel={raisedLabel}
      disabled={disabled}
    >
      {!!label && <FormLabel>{label}</FormLabel>}

      <TimePickerWrapper fixedWidth={fixedWidth}>
        <TimePicker
          {...props}
          onChange={onTimeChange || input.onChange}
          value={input.value}
          disableClock={true}
          clearIcon={null}
          hourPlaceholder="hh"
          minutePlaceholder="mm"
          secondPlaceholder="ss"
          format={format}
          maxDetail={maxDetail}
          disabled={readOnly}
        />
      </TimePickerWrapper>

      {!!metaInfo && <FormMeta singleLine={errorOnSingleLine}>{metaInfo}</FormMeta>}

      {(submitFailed || showErrorBeforeSubmit) && error && !fieldNotTouched && (
        <FormError errorOnSingleLine={errorOnSingleLine}>{error}</FormError>
      )}
    </FormGroup>
  );
};

export default TimePickerInput;
