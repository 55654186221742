import React from 'react';
import { Redirect } from 'react-router';
import { reduce } from 'lodash-es';

const fallbackRedirectMap = {
  '/hauler-opportunities/': '/opportunities/hauler-opportunities/',
};

export const getFallbackUrl = (hash: string) =>
  reduce(
    fallbackRedirectMap,
    (fallbackUrl, currentUrl, previousUrl) =>
      hash.indexOf(previousUrl) === 0 ? hash.replace(previousUrl, currentUrl) : fallbackUrl,
    '',
  );

const DefaultRoute = () => {
  const hash = window.location.hash.replace('#', '');
  const fallbackUrl = hash && getFallbackUrl(hash);
  const redirectToUrl = fallbackUrl || '/dashboard';

  return <Redirect to={redirectToUrl} />;
};

export default DefaultRoute;
