import update from 'immutability-helper';
import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { orderBy, flatten, uniqBy, uniq } from 'lodash-es';

import { ALL_STOPS_FILTER, WorkSessionViews } from '../constants/routeTemplateBuilder';
import store, { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getRandomHexColor } from '../../utils/randomColor';
import { GetStateFunction } from 'src/contracts/ducks';
import { RouteTemplateBuilderOption } from '../interfaces/routeTemplateBuilder/RouteTemplateOptions';
import {
  RouteTemplateBuilderColorsMap,
  RouteTemplateBuilderColorMap,
} from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderColorsMap';
import { RouteTemplateBuilderFilters } from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderFilters';
import {
  RouteTemplateBuilderDayOfService,
  RouteTemplateBuilderService,
  RouteTemplateBuilderDraft,
  RouteTemplateBuilderServiceContractDetails,
  ServiceContractExtraData,
  ServicesTotalsCounts,
} from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import {
  RouteTemplateBuilderWorkSession,
  StopFilterType,
  WorkSessionRouteTemplate,
} from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderWorkSession';
import { WorkSessionChangeLog } from '../interfaces/routeTemplateBuilder/WorkSessionChangeLog';
import * as Services from '../services/routeTemplateBuilder';
import translate from 'src/core/services/translate';
import { Dictionary } from 'src/common/interfaces/Dictionary';

/**
 * Actions
 */
const COMPLETE_IS_LOADING = 'routeTemplateBuilder/COMPLETE_IS_LOADING';
const START_LOAD_WORK_SESSIONS = 'routeTemplateBuilder/START_LOAD_WORK_SESSIONS';
const COMPLETE_LOAD_WORK_SESSIONS = 'routeTemplateBuilder/COMPLETE_LOAD_WORK_SESSIONS';
const START_LOAD_WORK_SESSION = 'routeTemplateBuilder/START_LOAD_WORK_SESSION';
const COMPLETE_LOAD_WORK_SESSION = 'routeTemplateBuilder/COMPLETE_LOAD_WORK_SESSION';
const FAIL_LOAD = 'routeTemplateBuilder/FAIL_LOAD';
const RESET = 'routeTemplateBuilder/RESET';
const TOGGLE_WORK_SESSION_DELETING = 'routeTemplateBuilder/TOGGLE_WORK_SESSION_DELETING';
const REMOVE_IN_MEMORY_WORK_SESSION = 'routeTemplateBuilder/REMOVE_IN_MEMORY_WORK_SESSION';
const SET_EXPANDED_WORK_SESSION = 'routeTemplateBuilder/SET_EXPANDED_WORK_SESSION';
const SET_SERVICES = 'routeTemplateBuilder/SET_SERVICES';
const SET_SERVICES_META = 'routeTemplateBuilder/SET_SERVICES_META';
const SET_SERVICES_LOADING = 'routeTemplateBuilder/SET_SERVICES_LOADING';
const SET_FILTERS = 'routeTemplateBuilder/SET_FILTERS';
const SET_FILTERS_LOADING = 'routeTemplateBuilder/SET_FILTERS_LOADING';
const SET_FILTER_VEHICLE = 'routeTemplateBuilder/SET_FILTER_VEHICLE';
const SET_FILTER_MATERIAL = 'routeTemplateBuilder/SET_FILTER_MATERIAL';
const SET_FILTER_DAYS_OF_SERVICE = 'routeTemplateBuilder/SET_FILTER_DAYS_OF_SERVICE';
const SET_FILTER_ROUTE_TEMPLATES = 'routeTemplateBuilder/SET_FILTER_ROUTE_TEMPLATES';
const SET_FILTER_SERVICE_TYPES = 'routeTemplateBuilder/SET_FILTER_SERVICE_TYPES';
const SET_FILTER_EQUIPMENT_TYPES = 'routeTemplateBuilder/SET_FILTER_EQUIPMENT_TYPES';
const SET_FILTER_EQUIPMENT_SIZES = 'routeTemplateBuilder/SET_FILTER_EQUIPMENT_SIZES';
const SET_FILTER_WASTE_MATERIAL_TYPE = 'routeTemplateBuilder/SET_FILTER_WASTE_MATERIAL_TYPE';
const SET_FILTER_GROUP_IDS = 'routeTemplateBuilder/SET_FILTER_GROUP_IDS';
const SET_FILTER_SERVICE_ZONE = 'routeTemplateBuilder/SET_FILTER_SERVICE_ZONE';
const SET_MATERIAL_SERVICES_LOADED = 'routeTemplateBuilder/SET_MATERIAL_SERVICES_LOADED';
const SET_STOP_FILTER_TYPE = 'routeTemplateBuilder/SET_STOP_FILTER_TYPE';
const CLEAR_MATERIAL_SERVICES_LOADED = 'routeTemplateBuilder/CLEAR_MATERIAL_SERVICES_LOADED';
const SET_WORK_SESSION_INITIALIZING = 'routeTemplateBuilder/SET_WORK_SESSION_INITIALIZING';
const SET_CHANGE_LOG = 'routeTemplateBuilder/SET_CHANGE_LOG';
const SET_CHANGE_LOG_LOADING = 'routeTemplateBuilder/SET_CHANGE_LOG_LOADING';
const SET_ROUTE_TEMPLATE_OPTIONS = 'routeTemplateBuilder/SET_ROUTE_TEMPLATE_OPTIONS';
const SET_SERVICES_ASSIGN_TO_ROUTE_LOADING = 'routeTemplateBuilder/SET_SERVICES_ASSIGN_TO_ROUTE_LOADING';
const SET_SELECTED_DRAFT_LOADING = 'routeTemplateBuilder/SET_SELECTED_DRAFT_LOADING';
const SET_SELECTED_DRAFT_ID = 'routeTemplateBuilder/SET_SELECTED_DRAFT_ID';
const SET_SELECTED_DRAFT = 'routeTemplateBuilder/SET_SELECTED_DRAFT';
const CLEAR_SELECTED_DRAFT = 'routeTemplateBuilder/CLEAR_SELECTED_DRAFT';
const SET_MAP_DRAWING_ENABLED = 'routeTemplateBuilder/SET_MAP_DRAWING_ENABLED';
const SET_SELECTED_SERVICE_CONTRACTS = 'routeTemplateBuilder/SET_SELECTED_SERVICE_CONTRACTS';
const SET_SELECTED_SERVICE_CONTRACT_FEATURE = 'routeTemplateBuilder/SET_SELECTED_SERVICE_CONTRACT_FEATURE';
const SET_SELECTED_SERVICE_CONTRACT = 'routeTemplateBuilder/SET_SELECTED_SERVICE_CONTRACT';
const SET_LAYOUT = 'routeTemplateBuilder/SET_LAYOUT';
const SET_FULL_SCREEN = 'routeTemplateBuilder/SET_FULL_SCREEN';
const SET_MAP_DRAWING_INSTRUCTIONS_OPEN = 'routeTemplateBuilder/SET_MAP_DRAWING_INSTRUCTIONS_OPEN';
const RESET_FILTER_ROUTE_TEMPLATES = 'routeTemplateBuilder/RESET_FILTER_ROUTE_TEMPLATES';
const START_LOADING_CONTAINERS_DAYS_OF_SERVICE = 'routeTemplateBuilder/START_LOADING_CONTAINERS_DAYS_OF_SERVICE';
const COMPLETE_LOADING_CONTAINERS_DAYS_OF_SERVICE = 'routeTemplateBuilder/COMPLETE_LOADING_CONTAINERS_DAYS_OF_SERVICE';
const SWITCH_DAY_OF_SERVICE_STATS = 'routeTemplateBuilder/SWITCH_DAY_OF_SERVICE_STATS';
const LOAD_SELECTED_SERVICES_EXTRA_DATA = 'routeTemplateBuilder/LOAD_SELECTED_SERVICES_EXTRA_DATA';
const COMPLETE_LOAD_SELECTED_SERVICES_EXTRA_DATA = 'routeTemplateBuilder/COMPLETE_LOAD_SELECTED_SERVICES_EXTRA_DATA';
const FAIL_LOAD_SELECTED_SERVICES_EXTRA_DATA = 'routeTemplateBuilder/FAIL_LOAD_SELECTED_SERVICES_EXTRA_DATA';
const RESET_SELECTED_SERVICES_EXTRA_DATA = 'routeTemplateBuilder/RESET_SELECTED_SERVICES_EXTRA_DATA';
const SET_LAST_FILTERS = 'routeTemplateBuilder/SET_LAST_FILTERS';
const RESTORE_LAST_FILTERS = 'routeTemplateBuilder/RESTORE_LAST_FILTERS';
const START_LOAD_SELECTED_SERVICES_TOTALS_COUNT = 'routeTemplateBuilder/START_LOAD_SELECTED_SERVICES_TOTALS_COUNT';
const COMPLETE_LOAD_SELECTED_SERVICES_TOTALS_COUNT =
  'routeTemplateBuilder/COMPLETE_LOAD_SELECTED_SERVICES_TOTALS_COUNT';
const FAIL_LOAD_SELECTED_SERVICES_TOTALS_COUNT = 'routeTemplateBuilder/FAIL_LOAD_SELECTED_SERVICES_TOTALS_COUNT';
const RESET_SELECTED_SERVICES_TOTALS_COUNT = 'routeTemplateBuilder/RESET_SELECTED_SERVICES_TOTALS_COUNT';

export interface MapFilters {
  vehicleTypeId: number;
  materialTypeIds: number[];
  dayOfServiceIds: number[];
  routeTemplateIds: string[];
  routeTemplateIdsShouldReset: boolean;
  stopFilterType: StopFilterType;
  serviceTypeIds: number[];
  wasteMaterialTypeId: number;
  groupIds: number[];
  serviceZoneId: number | null;
  equipmentTypeIds: number[];
  equipmentSizeIds: number[];
}

interface RouteTemplateBuilderState {
  expandedWorkSessionId?: number;
  isLoading: boolean;
  workSession?: RouteTemplateBuilderWorkSession;
  workSessions: RouteTemplateBuilderWorkSession[];
  workSessionsRouteTemplates: WorkSessionRouteTemplate[];
  workSessionInitializing: boolean;
  workSessionIdsDeleting: number[];
  workSessionRouteTemplates: RouteTemplateBuilderOption[];
  routeTemplatesDictionary: Dictionary<string>;

  layout: WorkSessionViews;
  changeLogs: WorkSessionChangeLog[];
  changeLogLoading: boolean;
  selectedDraftLoading: boolean;
  selectedDraftId?: number;
  selectedDraftVersionId?: number;
  selectedDraft?: RouteTemplateBuilderDraft;
  selectedDraftIsLatestVersion: boolean;

  services: RouteTemplateBuilderService[];
  servicesLoading: boolean;
  servicesLoadingForMaterialTypeIds: number[];
  servicesMeta: {
    vehicleTypeId: number;
    materialTypeId: number;
    daysOfService: RouteTemplateBuilderDayOfService[];
    serviceTypeIds: number[];
  }[];
  routeColorsMap: RouteTemplateBuilderColorsMap;
  bundlesLoadedByMaterialTypeId: number[];
  serivcesAssignToRouteLoading: boolean;
  selectedServiceContracts: RouteTemplateBuilderService[];
  selectedServiceContractFeature?: mapboxgl.MapboxGeoJSONFeature;
  selectedServiceContract?: RouteTemplateBuilderServiceContractDetails;
  selectedServicesExtraData: ServiceContractExtraData[];
  selectedServicesExtraDataLoading: boolean;
  selectedServicesTotalsCounts: ServicesTotalsCounts | null;
  selectedServicesTotalsCountsLoading: boolean;

  filters?: RouteTemplateBuilderFilters;
  filtersLoading: boolean;
  mapFilters: MapFilters;
  lastMapFilters?: MapFilters;
  isApplyingLastFilters?: boolean;
  mapDrawingEnabled: boolean;
  mapDrawingInstructionsOpen: boolean;
  fullScreen: boolean;
  showContainersByDaysOfService: boolean;
  containerByDaysOfServiceFilters?: RouteTemplateBuilderFilters;
  isLoadingContainersByDaysOfService: boolean;
}

const CancelToken = axios.CancelToken;
let cancelSelectedServicesTotalsCountRequest: any;

export const unassignedKey = 'unassigned';

/**
 * Initial state
 */
const initialState: RouteTemplateBuilderState = {
  isLoading: false,
  workSessions: [],
  routeTemplatesDictionary: {},
  workSessionsRouteTemplates: [],
  workSessionInitializing: false,
  workSessionIdsDeleting: [],
  workSessionRouteTemplates: [],

  layout: WorkSessionViews.Map,
  changeLogs: [],
  changeLogLoading: false,
  selectedDraftLoading: false,
  selectedDraftIsLatestVersion: false,

  services: [],
  servicesLoading: false,
  servicesLoadingForMaterialTypeIds: [],
  servicesMeta: [],
  routeColorsMap: {},
  bundlesLoadedByMaterialTypeId: [],
  serivcesAssignToRouteLoading: false,
  selectedServiceContracts: [],
  selectedServicesExtraDataLoading: false,
  selectedServicesExtraData: [],
  selectedServicesTotalsCounts: null,
  selectedServicesTotalsCountsLoading: false,

  filtersLoading: false,
  mapFilters: {
    vehicleTypeId: 0,
    materialTypeIds: [],
    routeTemplateIds: [],
    routeTemplateIdsShouldReset: false,
    dayOfServiceIds: [1],
    stopFilterType: ALL_STOPS_FILTER,
    serviceTypeIds: [],
    wasteMaterialTypeId: 0,
    groupIds: [],
    serviceZoneId: null,
    equipmentTypeIds: [],
    equipmentSizeIds: [],
  },
  isApplyingLastFilters: false,
  mapDrawingEnabled: false,
  mapDrawingInstructionsOpen: true,
  fullScreen: false,
  showContainersByDaysOfService: false,
  isLoadingContainersByDaysOfService: false,
};

const getRouteColorsMap = (services: RouteTemplateBuilderService[], routeColorsMap: RouteTemplateBuilderColorsMap) => {
  services.forEach(s => {
    s.daysOfService?.forEach(d => {
      const routeName = d.routeName;

      if (routeName) {
        const entry: RouteTemplateBuilderColorMap | undefined = routeColorsMap[routeName];
        const color = entry?.color || getRandomHexColor();

        routeColorsMap[routeName] = {
          ...(entry || { color }),
        };
      }
    });
  });

  return routeColorsMap;
};

/**
 * Reducer
 * @param state
 * @param action
 */
export const routeTemplateBuilderReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_WORK_SESSIONS:
    case START_LOAD_WORK_SESSION:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_IS_LOADING:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case COMPLETE_LOAD_WORK_SESSIONS:
      const workSessions: RouteTemplateBuilderWorkSession[] = action.workSessions;

      return update(state, {
        $merge: {
          isLoading: false,
          workSessions,
          workSessionsRouteTemplates: flatten(workSessions.map(session => session.routeTemplates)),
        },
      });

    case COMPLETE_LOAD_WORK_SESSION:
      return update(state, {
        $merge: {
          isLoading: false,
          workSessionInitializing: false,
          workSession: action.workSession,
        },
      });

    case SET_LAST_FILTERS: {
      return update(state, {
        $merge: {
          lastMapFilters: state.mapFilters,
        },
      });
    }

    case RESTORE_LAST_FILTERS: {
      return update(state, {
        $merge: {
          mapFilters: { ...state.lastMapFilters! },
          lastMapFilters: undefined,
          isApplyingLastFilters: true,
        },
      });
    }

    case SET_FILTERS_LOADING:
      return update(state, {
        $merge: {
          filtersLoading: action.loading,
        },
      });

    case SET_FILTERS: {
      const { filters } = action;
      const { wasteMaterialTypes: materialTypes } = filters;
      const { materialTypeIds: currentMaterialTypeIds } = state.mapFilters;
      let materialTypeIds: number[] = currentMaterialTypeIds;

      if (materialTypes.length) {
        const currentMaterialTypeFound = !!(materialTypes as RouteTemplateBuilderFilters['wasteMaterialTypes']).find(
          type => currentMaterialTypeIds.indexOf(type.id) !== -1,
        );

        if (!currentMaterialTypeFound) {
          materialTypeIds = [materialTypes[0].id];
        }
      }

      return update(state, {
        $merge: {
          filters,
          mapDrawingEnabled: false,
          showContainersByDaysOfService: false,
        },
        mapFilters: {
          $merge: {
            materialTypeIds,
          },
        },
      });
    }

    case SET_FILTER_MATERIAL:
      const materialIndex = state.mapFilters.materialTypeIds.indexOf(action.materialTypeId);

      if (materialIndex === -1) {
        return update(state, {
          mapFilters: {
            materialTypeIds: { $push: [action.materialTypeId] },
          },
          $merge: {
            mapDrawingEnabled: false,
            selectedServiceContractFeature: undefined,
          },
        });
      }

      if (state.mapFilters.materialTypeIds.length === 1) {
        return state;
      }

      return update(state, {
        mapFilters: {
          materialTypeIds: { $splice: [[materialIndex, 1]] },
        },
        $merge: {
          mapDrawingEnabled: false,
          selectedServiceContractFeature: undefined,
        },
      });

    case SET_FILTER_VEHICLE:
      return update(state, {
        mapFilters: {
          $merge: {
            vehicleTypeId: action.vehicleTypeId,
            materialTypeIds: [],
          },
        },
        $merge: {
          mapDrawingEnabled: false,
          selectedServiceContractFeature: undefined,
          bundlesLoadedByMaterialTypeId: [],
        },
      });

    case SET_FILTER_DAYS_OF_SERVICE:
      return update(state, {
        mapFilters: {
          $merge: {
            dayOfServiceIds: action.dayOfServiceIds,
          },
        },
        $merge: {
          mapDrawingEnabled: false,
          selectedServiceContractFeature: undefined,
        },
      });

    case SET_FILTER_ROUTE_TEMPLATES:
      return update(state, {
        mapFilters: {
          $merge: {
            routeTemplateIds: action.routeTemplateIds,
            routeTemplateIdsShouldReset: false,
          },
        },
        $merge: {
          mapDrawingEnabled: false,
          selectedServiceContractFeature: undefined,
        },
      });

    case RESET_FILTER_ROUTE_TEMPLATES:
      return update(state, {
        mapFilters: {
          $merge: {
            routeTemplateIdsShouldReset: true,
          },
        },
      });

    case SET_FILTER_SERVICE_TYPES:
      return update(state, {
        mapFilters: {
          $merge: {
            serviceTypeIds: action.serviceTypeIds,
            routeTemplateIdsShouldReset: false,
          },
        },
      });

    case SET_FILTER_EQUIPMENT_TYPES:
      return update(state, {
        mapFilters: {
          $merge: {
            equipmentTypeIds: action.equipmentTypeIds,
            routeTemplateIdsShouldReset: false,
          },
        },
        isApplyingLastFilters: { $set: false },
      });

    case SET_FILTER_EQUIPMENT_SIZES:
      return update(state, {
        mapFilters: {
          $merge: {
            equipmentSizeIds: action.equipmentSizeIds,
            routeTemplateIdsShouldReset: false,
          },
        },
        isApplyingLastFilters: { $set: false },
      });

    case SET_FILTER_WASTE_MATERIAL_TYPE:
      return update(state, {
        mapFilters: {
          $merge: {
            wasteMaterialTypeId: action.wasteMaterialTypeId,
            routeTemplateIdsShouldReset: false,
          },
        },
      });

    case SET_FILTER_GROUP_IDS:
      return update(state, {
        mapFilters: {
          $merge: {
            groupIds: action.groupIds || [],
            routeTemplateIdsShouldReset: false,
          },
        },
      });

    case SET_FILTER_SERVICE_ZONE:
      return update(state, {
        mapFilters: {
          $merge: {
            serviceZoneId: action.serviceZoneId,
            routeTemplateIdsShouldReset: false,
          },
        },
      });

    case TOGGLE_WORK_SESSION_DELETING: {
      const workSessionIndex = state.workSessionIdsDeleting.indexOf(action.workSessionId);

      if (workSessionIndex === -1) {
        return update(state, {
          workSessionIdsDeleting: { $push: [action.workSessionId] },
        });
      }

      return update(state, {
        workSessionIdsDeleting: { $splice: [[workSessionIndex, 1]] },
      });
    }

    case REMOVE_IN_MEMORY_WORK_SESSION: {
      let workSessionIndex = -1;

      state.workSessions.forEach((workSession, index) => {
        if (workSession.id === action.workSessionId) {
          workSessionIndex = index;
        }
      });

      if (workSessionIndex === -1) {
        return state;
      }

      return update(state, {
        workSessions: { $splice: [[workSessionIndex, 1]] },
      });
    }

    case SET_SERVICES_LOADING: {
      if (action.loading) {
        return update(state, {
          $merge: {
            servicesLoading: action.loading,
          },
          servicesLoadingForMaterialTypeIds: {
            $push: [action.materialTypeId],
          },
        });
      }

      const index = state.servicesLoadingForMaterialTypeIds.indexOf(action.materialTypeId);

      return update(state, {
        $merge: {
          servicesLoading: action.loading,
        },
        servicesLoadingForMaterialTypeIds: {
          $splice: [[index, 1]],
        },
      });
    }

    case SET_SERVICES: {
      const { services: actualServices } = state;
      const services = uniqBy(actualServices.concat(action.services), 'id');
      const routeColorsMap = getRouteColorsMap(services, { ...state.routeColorsMap });

      return update(state, {
        $merge: {
          services,
          routeColorsMap,
        },
      });
    }

    case SET_SERVICES_META: {
      const { vehicleTypeId, materialTypeId, daysOfService, serviceTypeIds, routeTemplatesDictionary } = action;
      const servicesMeta = state.servicesMeta.slice();
      let materialIndex = -1;

      const newServiceMeta = {
        vehicleTypeId,
        materialTypeId,
        daysOfService,
        serviceTypeIds,
      };

      servicesMeta.forEach((meta, index) => {
        if (meta.vehicleTypeId === vehicleTypeId && meta.materialTypeId === materialTypeId) {
          materialIndex = index;
        }
      });

      if (materialIndex === -1) {
        servicesMeta.push(newServiceMeta);
      } else {
        servicesMeta[materialIndex] = newServiceMeta;
      }

      return update(state, {
        $merge: {
          servicesMeta,
          routeTemplatesDictionary,
        },
      });
    }

    case SET_MATERIAL_SERVICES_LOADED:
      const materialAlreadyLoaded = state.bundlesLoadedByMaterialTypeId.indexOf(action.materialTypeId) !== -1;

      if (materialAlreadyLoaded) {
        return state;
      }

      return update(state, {
        bundlesLoadedByMaterialTypeId: { $push: [action.materialTypeId] },
      });

    case CLEAR_MATERIAL_SERVICES_LOADED:
      return update(state, {
        $merge: {
          services: [],
          bundlesLoadedByMaterialTypeId: [],
        },
      });

    case SET_STOP_FILTER_TYPE:
      return update(state, {
        mapFilters: {
          $merge: {
            stopFilterType: action.stopFilterType,
          },
        },
        $merge: {
          mapDrawingEnabled: false,
          selectedServiceContractFeature: undefined,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case SET_EXPANDED_WORK_SESSION:
      return update(state, {
        $merge: {
          expandedWorkSessionId: action.workSessionId,
        },
      });

    case SET_WORK_SESSION_INITIALIZING:
      return update(state, {
        $merge: {
          workSessionInitializing: action.workSessionInitializing,
        },
      });

    case SET_CHANGE_LOG:
      return update(state, { $merge: { changeLogs: action.changeLogs } });

    case SET_ROUTE_TEMPLATE_OPTIONS:
      return update(state, {
        $merge: {
          workSessionRouteTemplates: action.routeTemplates,
        },
      });

    case SET_SERVICES_ASSIGN_TO_ROUTE_LOADING:
      return update(state, {
        $merge: {
          serivcesAssignToRouteLoading: action.loading,
        },
      });

    case SET_CHANGE_LOG_LOADING:
      return update(state, {
        $merge: {
          changeLogLoading: action.loading,
        },
      });

    case SET_SELECTED_DRAFT_LOADING:
      return update(state, {
        $merge: {
          selectedDraftLoading: action.loading,
        },
      });

    case SET_SELECTED_DRAFT_ID:
      return update(state, {
        $merge: {
          selectedDraftId: action.selectedDraftId,
          selectedDraftVersionId: action.selectedDraftVersionId,
          selectedDraftIsLatestVersion: action.selectedDraftIsLatestVersion,
        },
      });

    case SET_SELECTED_DRAFT: {
      const draft = action.selectedDraft as RouteTemplateBuilderDraft | null;

      if (!draft) {
        return update(state, {
          $merge: {
            selectedDraft: undefined,
          },
        });
      }

      return update(state, {
        $merge: {
          selectedDraft: draft,
          selectedDraftId: draft.id,
          selectedDraftVersionId: draft.changeLogId,
        },
      });
    }

    case CLEAR_SELECTED_DRAFT:
      return update(state, {
        $merge: {
          selectedDraftId: undefined,
          selectedDraftVersionId: undefined,
          selectedDraft: undefined,
        },
      });

    case SET_MAP_DRAWING_ENABLED:
      return update(state, {
        $merge: {
          mapDrawingEnabled: action.mapDrawingEnabled,
        },
      });

    case SET_SELECTED_SERVICE_CONTRACT_FEATURE:
      return update(state, {
        $merge: {
          selectedServiceContractFeature: action.serviceContractFeature,
        },
      });

    case SET_SELECTED_SERVICE_CONTRACT:
      return update(state, {
        $merge: {
          selectedServiceContract: action.serviceContract,
        },
      });

    case SET_SELECTED_SERVICE_CONTRACTS:
      return update(state, {
        $merge: {
          selectedServiceContracts: action.serviceContracts,
        },
      });

    case SET_LAYOUT:
      return update(state, {
        $merge: {
          layout: action.layout,
        },
      });

    case SET_FULL_SCREEN:
      return update(state, {
        $merge: {
          fullScreen: action.fullScreen,
        },
      });

    case SET_MAP_DRAWING_INSTRUCTIONS_OPEN:
      return update(state, {
        $merge: {
          mapDrawingInstructionsOpen: action.mapDrawingInstructionsOpen,
        },
      });

    case START_LOADING_CONTAINERS_DAYS_OF_SERVICE:
      return update(state, {
        $merge: {
          isLoadingContainersByDaysOfService: action.isLoading,
        },
      });

    case COMPLETE_LOADING_CONTAINERS_DAYS_OF_SERVICE:
      return update(state, {
        $merge: {
          isLoadingContainersByDaysOfService: false,
          containerByDaysOfServiceFilters: action.containerByDaysOfServiceFilters,
          showContainersByDaysOfService: true,
        },
      });

    case SWITCH_DAY_OF_SERVICE_STATS:
      return update(state, {
        $merge: {
          showContainersByDaysOfService: action.showContainers,
        },
      });

    case LOAD_SELECTED_SERVICES_EXTRA_DATA:
      return update(state, {
        $merge: {
          selectedServicesExtraDataLoading: true,
        },
      });

    case COMPLETE_LOAD_SELECTED_SERVICES_EXTRA_DATA:
      return update(state, {
        $merge: {
          selectedServicesExtraDataLoading: false,
          selectedServicesExtraData: action.selectedServicesExtraData,
        },
      });

    case FAIL_LOAD_SELECTED_SERVICES_EXTRA_DATA:
      return update(state, {
        $merge: {
          selectedServicesExtraDataLoading: false,
          selectedServicesExtraData: [],
        },
      });

    case RESET_SELECTED_SERVICES_EXTRA_DATA:
      return update(state, {
        $merge: {
          selectedServicesExtraData: [],
        },
      });

    case START_LOAD_SELECTED_SERVICES_TOTALS_COUNT:
      return update(state, {
        $merge: {
          selectedServicesTotalsCountsLoading: true,
        },
      });

    case COMPLETE_LOAD_SELECTED_SERVICES_TOTALS_COUNT:
      return update(state, {
        $merge: {
          selectedServicesTotalsCountsLoading: false,
          selectedServicesTotalsCounts: action.selectedServicesTotalsCounts,
        },
      });

    case FAIL_LOAD_SELECTED_SERVICES_TOTALS_COUNT:
      return update(state, {
        $merge: {
          selectedServicesTotalsCountsLoading: false,
          selectedServicesTotalsCounts: null,
        },
      });

    case RESET_SELECTED_SERVICES_TOTALS_COUNT:
      return update(state, {
        $merge: {
          selectedServicesTotalsCounts: null,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

/**
 * Action creators
 */
const startLoadWorkSessions = () => ({
  type: START_LOAD_WORK_SESSIONS,
});

const setWorkSessionInitializing = (workSessionInitializing: boolean) => ({
  type: SET_WORK_SESSION_INITIALIZING,
  workSessionInitializing,
});

const startLoadWorkSession = () => ({
  type: START_LOAD_WORK_SESSION,
});

const completeLoadWorkSessions = (workSessions: RouteTemplateBuilderWorkSession[]) => ({
  type: COMPLETE_LOAD_WORK_SESSIONS,
  workSessions,
});

const completeLoadWorkSession = (workSession: RouteTemplateBuilderWorkSession) => ({
  type: COMPLETE_LOAD_WORK_SESSION,
  workSession,
});

const setFilters = (filters: RouteTemplateBuilderFilters) => ({
  type: SET_FILTERS,
  filters,
});

const setFiltersLoading = (loading: boolean) => ({
  type: SET_FILTERS_LOADING,
  loading,
});

export const setFilterVehicle = (vehicleTypeId: number) => ({
  type: SET_FILTER_VEHICLE,
  vehicleTypeId,
});

export const setFilterMaterial = (materialTypeId: number) => ({
  type: SET_FILTER_MATERIAL,
  materialTypeId,
});

export const setFilterDaysOfService = (dayOfServiceIds: number[]) => ({
  type: SET_FILTER_DAYS_OF_SERVICE,
  dayOfServiceIds,
});

export const setFilterRouteTemplates = (routeTemplateIds: string[]) => ({
  type: SET_FILTER_ROUTE_TEMPLATES,
  routeTemplateIds,
});

export const setFilterServiceTypes = (serviceTypeIds: number[]) => ({
  type: SET_FILTER_SERVICE_TYPES,
  serviceTypeIds,
});

export const setFilterWasteMaterialType = (wasteMaterialTypeId: number) => ({
  type: SET_FILTER_WASTE_MATERIAL_TYPE,
  wasteMaterialTypeId,
});

export const setFilterGroupIds = (groupIds: number[]) => ({
  type: SET_FILTER_GROUP_IDS,
  groupIds,
});

export const setFilterServiceZone = (serviceZoneId: number) => ({
  type: SET_FILTER_SERVICE_ZONE,
  serviceZoneId,
});

export const setFilterEquipmentTypes = (equipmentTypeIds: number[]) => ({
  type: SET_FILTER_EQUIPMENT_TYPES,
  equipmentTypeIds,
});

export const setFilterEquipmentSizes = (equipmentSizeIds: number[]) => ({
  type: SET_FILTER_EQUIPMENT_SIZES,
  equipmentSizeIds,
});

export const resetFilterRouteTemplates = () => ({
  type: RESET_FILTER_ROUTE_TEMPLATES,
});

const toggleWorkSessionDeleting = (workSessionId: number) => ({
  type: TOGGLE_WORK_SESSION_DELETING,
  workSessionId,
});

const removeInMemoryWorkSession = (workSessionId: number) => ({
  type: REMOVE_IN_MEMORY_WORK_SESSION,
  workSessionId,
});

const setServicesLoading = (loading: boolean, materialTypeId: number) => ({
  type: SET_SERVICES_LOADING,
  loading,
  materialTypeId,
});

const setServices = (services: RouteTemplateBuilderService[]) => ({
  type: SET_SERVICES,
  services,
});

const setServicesMeta = (
  vehicleTypeId: number,
  materialTypeId: number,
  daysOfService: RouteTemplateBuilderDayOfService[],
  serviceTypeIds: number[],
  routeTemplatesDictionary: Dictionary<string>,
) => ({
  type: SET_SERVICES_META,
  vehicleTypeId,
  materialTypeId,
  daysOfService,
  serviceTypeIds,
  routeTemplatesDictionary,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const setMaterialServicesLoaded = (materialTypeId: number) => ({
  type: SET_MATERIAL_SERVICES_LOADED,
  materialTypeId,
});

const clearMaterialServicesLoaded = () => ({
  type: CLEAR_MATERIAL_SERVICES_LOADED,
});

export const setStopFilterType = (stopFilterType: 'all' | 'assigned' | 'unassigned') => ({
  type: SET_STOP_FILTER_TYPE,
  stopFilterType,
});

export const setExpandedWorkSession = (workSessionId?: number) => ({
  type: SET_EXPANDED_WORK_SESSION,
  workSessionId,
});

const setChangeLogs = (changeLogs: WorkSessionChangeLog[]) => ({
  type: SET_CHANGE_LOG,
  changeLogs,
});

const setChangeLogLoading = (loading: boolean) => ({
  type: SET_CHANGE_LOG_LOADING,
  loading,
});

const setRouteTemplateOptions = (routeTemplates: RouteTemplateBuilderOption[]) => ({
  type: SET_ROUTE_TEMPLATE_OPTIONS,
  routeTemplates,
});

const setServicesAssignToRouteLoading = (loading: boolean) => ({
  type: SET_SERVICES_ASSIGN_TO_ROUTE_LOADING,
  loading,
});

const setSelectedDraftLoading = (loading: boolean) => ({
  type: SET_SELECTED_DRAFT_LOADING,
  loading,
});

export const setSelectedDraftId = (
  selectedDraftId: number,
  selectedDraftVersionId?: number,
  selectedDraftIsLatestVersion = !selectedDraftVersionId,
) => ({
  type: SET_SELECTED_DRAFT_ID,
  selectedDraftId,
  selectedDraftVersionId,
  selectedDraftIsLatestVersion,
});

const setSelectedDraft = (selectedDraft: RouteTemplateBuilderDraft, versionId?: number) => ({
  type: SET_SELECTED_DRAFT,
  selectedDraft,
  versionId,
});

export const clearSelectedDraft = () => ({
  type: CLEAR_SELECTED_DRAFT,
});

export const backupFilters = () => ({
  type: SET_LAST_FILTERS,
});

export const restoreLastFilters = () => ({
  type: RESTORE_LAST_FILTERS,
});

export const completeIsLoading = () => ({
  type: COMPLETE_IS_LOADING,
});

export const setMapDrawingEnabled = (mapDrawingEnabled: boolean) => ({
  type: SET_MAP_DRAWING_ENABLED,
  mapDrawingEnabled,
});

export const setSelectedServiceContractFeature = (serviceContractFeature: mapboxgl.MapboxGeoJSONFeature) => ({
  type: SET_SELECTED_SERVICE_CONTRACT_FEATURE,
  serviceContractFeature,
});

export const clearSelectedServiceContractFeature = () => ({
  type: SET_SELECTED_SERVICE_CONTRACT_FEATURE,
  serviceContractFeature: undefined,
});

const setSelectedServiceContract = (serviceContract: RouteTemplateBuilderServiceContractDetails) => ({
  type: SET_SELECTED_SERVICE_CONTRACT,
  serviceContract,
});

export const clearSelectedServiceContract = () => ({
  type: SET_SELECTED_SERVICE_CONTRACT,
  serviceContract: undefined,
});

export const setSelectedServiceContracts = (serviceContracts: RouteTemplateBuilderService[]) => ({
  type: SET_SELECTED_SERVICE_CONTRACTS,
  serviceContracts,
});

export const setLayout = (layout: WorkSessionViews) => ({
  type: SET_LAYOUT,
  layout,
});

export const setFullScreen = (fullScreen: boolean) => ({
  type: SET_FULL_SCREEN,
  fullScreen,
});

export const setMapDrawingInstructionsOpen = (mapDrawingInstructionsOpen: boolean) => ({
  type: SET_MAP_DRAWING_INSTRUCTIONS_OPEN,
  mapDrawingInstructionsOpen,
});

export const loadContainersByDaysOfService = (isLoading: boolean) => ({
  type: START_LOADING_CONTAINERS_DAYS_OF_SERVICE,
  isLoading,
});

export const switchDaysOfServiceStats = (showContainers: boolean) => ({
  type: SWITCH_DAY_OF_SERVICE_STATS,
  showContainers,
});

export const completeLoadContainersByDaysOfService = (
  containerByDaysOfServiceFilters: RouteTemplateBuilderFilters,
) => ({
  type: COMPLETE_LOADING_CONTAINERS_DAYS_OF_SERVICE,
  containerByDaysOfServiceFilters,
});

export const resetWorkSessionState = () => ({ type: RESET });

const loadSelectedServicesExtraData = () => ({ type: LOAD_SELECTED_SERVICES_EXTRA_DATA });

const completeLoadSelectedServicesExtraData = (selectedServicesExtraData: ServiceContractExtraData[]) => ({
  type: COMPLETE_LOAD_SELECTED_SERVICES_EXTRA_DATA,
  selectedServicesExtraData,
});

const failLoadSelectedServicesExtraData = () => ({ type: FAIL_LOAD_SELECTED_SERVICES_EXTRA_DATA });

export const resetSelectedServicesExtraData = () => ({ type: RESET_SELECTED_SERVICES_EXTRA_DATA });

const startLoadSelectedServicesTotalsCounts = () => ({ type: START_LOAD_SELECTED_SERVICES_TOTALS_COUNT });

const completeLoadSelectedServicesTotalsCounts = (selectedServicesTotalsCounts: ServicesTotalsCounts) => ({
  type: COMPLETE_LOAD_SELECTED_SERVICES_TOTALS_COUNT,
  selectedServicesTotalsCounts,
});

const failLoadSelectedServicesTotalsCounts = () => ({ type: FAIL_LOAD_SELECTED_SERVICES_TOTALS_COUNT });

export const resetSelectedServicesTotalsCounts = () => ({ type: RESET_SELECTED_SERVICES_TOTALS_COUNT });

/**
 * Complex action creators
 */
export const loadRouteTemplateBuilderWorkSessions = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadWorkSessions());

  const promise = Services.loadRouteTemplateBuilderWorkSessions(vendorId);

  promise
    .then(sessions => {
      dispatch(completeLoadWorkSessions(sessions));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};

export const initializeRouteTemplateBuilderWorkSession =
  (vendorId: number, workSessionName: string) => (dispatch: Dispatch) => {
    dispatch(setWorkSessionInitializing(true));

    const promise = Services.initializeRouteTemplateBuilderWorkSession(vendorId, workSessionName);

    promise.finally(() => {
      dispatch(setWorkSessionInitializing(false));
    });

    return promise;
  };

export const changeDaysOfServiceStats =
  (
    showNumberOfContainers: boolean,
    vendorId: number,
    workSessionId: number,
    vehicleTypeId: number,
    serviceZoneId: number | null,
    wasteMaterialTypeId?: number,
    groupIds?: number[],
  ) =>
  (dispatch: Dispatch) => {
    if (showNumberOfContainers) {
      dispatch(loadContainersByDaysOfService(showNumberOfContainers));
      Services.getNumberOfContainersByDayOfService(
        vendorId,
        workSessionId,
        vehicleTypeId,
        serviceZoneId,
        wasteMaterialTypeId,
        groupIds,
      )
        .then(filters => {
          dispatch(completeLoadContainersByDaysOfService(filters));
        })
        .finally(() => dispatch(loadContainersByDaysOfService(false)));
    } else {
      dispatch(switchDaysOfServiceStats(showNumberOfContainers));
    }
  };

export const loadRouteTemplateBuilderWorkSession = (sessionId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadWorkSession());

  const promise = Services.loadRouteTemplateBuilderWorkSession(sessionId);

  promise
    .then(session => {
      dispatch(completeLoadWorkSession(session));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};

export const updateRouteTemplateBuilderWorkSession =
  (workSession: RouteTemplateBuilderWorkSession) => (dispatch: Dispatch) => {
    dispatch(setWorkSessionInitializing(true));

    const promise = Services.updateRouteTemplateBuilderWorkSession(workSession);

    promise
      .catch(() => {
        dispatch(failLoad());
      })
      .finally(() => {
        dispatch(setWorkSessionInitializing(false));
      });

    return promise;
  };

export const loadRouteTemplateBuilderFilters =
  (
    vendorId: number,
    workSessionId: number,
    vehicleTypeId: number,
    serviceZoneId: number | null,
    wasteMaterialTypeId?: number,
    groupIds?: number[],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(setFiltersLoading(true));
    dispatch(clearMaterialServicesLoaded());

    const promise = Services.loadRouteTemplateBuilderFilters(
      vendorId,
      workSessionId,
      vehicleTypeId,
      serviceZoneId,
      wasteMaterialTypeId,
      groupIds,
    );

    promise
      .then(filters => {
        const wasteMaterialTypes = orderBy(filters.wasteMaterialTypes, 'id', 'asc');

        dispatch(setFilters({ ...filters, wasteMaterialTypes }));
      })
      .finally(() => {
        dispatch(setFiltersLoading(false));
      });

    return promise;
  };

export const loadRouteTemplateBuilderServices =
  (
    vendorId: number,
    workSessionId: number,
    vehicleTypeId: number,
    materialTypeId: number,
    serviceZoneId: number | null,
    groupIds?: number[],
    isToLoadExtraData?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(setServicesLoading(true, materialTypeId));

    const promise = Services.loadRouteTemplateBuilderServices(
      vendorId,
      workSessionId,
      vehicleTypeId,
      materialTypeId,
      serviceZoneId,
      groupIds,
    );

    promise
      .then(({ serviceContracts: services, daysOfService, routeTemplatesDictionary }) => {
        dispatch(setMaterialServicesLoaded(materialTypeId));
        dispatch(setServices(services));

        if (isToLoadExtraData) {
          const storeState = store.getState();

          const storeEquipmentSizeIds = storeState.routes.routeTemplateBuilder.mapFilters.equipmentSizeIds;
          const storeEquipmentTypeIds = storeState.routes.routeTemplateBuilder.mapFilters.equipmentTypeIds;
          const storeServiceTypeIds = storeState.routes.routeTemplateBuilder.mapFilters.serviceTypeIds;

          const equipmentTypeIds = uniq([...services.map(s => s.equipmentTypeId), ...storeEquipmentTypeIds]);
          const equipmentSizeIds = uniq([...services.map(s => s.equipmentSizeId), ...storeEquipmentSizeIds]);
          const serviceTypeIds = uniq([
            ...services.filter(s => !!s.serviceTypeId).map(s => s.serviceTypeId!),
            ...storeServiceTypeIds,
          ]);

          dispatch(
            setServicesMeta(vehicleTypeId, materialTypeId, daysOfService, serviceTypeIds, routeTemplatesDictionary),
          );
          dispatch(setFilterServiceTypes(serviceTypeIds));
          dispatch(setFilterEquipmentSizes(equipmentSizeIds));
          dispatch(setFilterEquipmentTypes(equipmentTypeIds));
        } else {
          const equipmentTypeIds = uniq(services.map(s => s.equipmentTypeId));
          const equipmentSizeIds = uniq(services.map(s => s.equipmentSizeId));
          const serviceTypeIds = uniq(services.filter(s => !!s.serviceTypeId).map(s => s.serviceTypeId!));

          dispatch(
            setServicesMeta(vehicleTypeId, materialTypeId, daysOfService, serviceTypeIds, routeTemplatesDictionary),
          );
          dispatch(setFilterServiceTypes(serviceTypeIds));
          dispatch(setFilterEquipmentSizes(equipmentSizeIds));
          dispatch(setFilterEquipmentTypes(equipmentTypeIds));
        }
      })
      .finally(() => {
        dispatch(setServicesLoading(false, materialTypeId));
      });

    return promise;
  };

export const loadRouteTemplateBuilderWorkSessionChangeLog = (sessionId: number) => (dispatch: Dispatch) => {
  dispatch(setChangeLogLoading(true));

  const promise = Services.loadRouteTemplateBuilderWorkSessionChangeLog(sessionId);

  promise
    .then(changeLog => {
      dispatch(setChangeLogs(changeLog));
    })
    .catch(() => {
      dispatch(failLoad());
    })
    .finally(() => {
      dispatch(setChangeLogLoading(false));
    });

  return promise;
};

export const refreshWorkSession =
  (loadServices = true) =>
  (dispatch: Dispatch, getState: GetStateFunction) => {
    const state: AppState = getState();
    const vendorId = currentVendorId(state);
    const workSessionId = state.routes.routeTemplateBuilder.workSession?.id || 0;
    const { vehicleTypeId, materialTypeIds, wasteMaterialTypeId, groupIds, serviceZoneId } =
      state.routes.routeTemplateBuilder.mapFilters;

    if (!workSessionId) {
      return Promise.reject();
    }

    dispatch(backupFilters());

    const bundle: Promise<unknown>[] = [
      loadRouteTemplateBuilderWorkSessionChangeLog(workSessionId)(dispatch),
      loadRouteTemplateBuilderFilters(
        vendorId,
        workSessionId,
        vehicleTypeId,
        serviceZoneId,
        wasteMaterialTypeId,
        groupIds,
      )(dispatch),
    ];

    if (loadServices) {
      materialTypeIds.forEach(materialTypeId =>
        bundle.push(
          loadRouteTemplateBuilderServices(
            vendorId,
            workSessionId,
            vehicleTypeId,
            materialTypeId,
            serviceZoneId,
            groupIds,
          )(dispatch),
        ),
      );
    }

    return Promise.all(bundle);
  };

export const loadRouteTemplateDraftServices = (draftId: number, versionId?: number) => (dispatch: Dispatch) => {
  dispatch(setSelectedDraftLoading(true));

  const promise = Services.loadRouteTemplateDraftServices(draftId, versionId);

  promise
    .then(draft => {
      dispatch(setSelectedDraft(draft, versionId));
    })
    .finally(() => {
      dispatch(setSelectedDraftLoading(false));
    });

  return promise;
};

export const loadRouteTemplateOptionsForWorkSession =
  (workSessionId: number, vehicleTypeId: number, dayOfServiceId: number) => (dispatch: Dispatch) => {
    const promise = Services.loadRouteTemplateOptionsForWorkSession(workSessionId, vehicleTypeId, dayOfServiceId);

    promise.then(templates => {
      dispatch(setRouteTemplateOptions(templates));
    });

    return promise;
  };

export const upsertDraftRouteTemplateForWorkSession =
  (
    workSessionId: number,
    draftId: number | null,
    payload: {
      vehicleTypeId: number;
      wasteMaterialTypeId: number;
      dayOfService: number;
      serviceContracts: { id: number }[];
      name: string;
      routeTemplateId?: number;
      vendorServiceZoneId?: unknown;
      routeConfirmationTypeId?: unknown;
      startingLocationId?: number;
      endingLocationId?: number;
      startDate?: string;
      routeTemplateIds?: number[];
    },
    update?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(setServicesAssignToRouteLoading(true));

    const promise = Services.upsertDraftRouteTemplateForWorkSession(workSessionId, draftId, payload, update);

    promise.finally(() => {
      dispatch(setServicesAssignToRouteLoading(false));
    });

    return promise;
  };

export const deleteRouteTemplateBuilderWorkSession = (workSessionId: number) => (dispatch: Dispatch) => {
  dispatch(toggleWorkSessionDeleting(workSessionId));

  const promise = Services.deleteRouteTemplateBuilderWorkSession(workSessionId);

  promise
    .then(() => {
      dispatch(removeInMemoryWorkSession(workSessionId));
    })
    .finally(() => {
      dispatch(toggleWorkSessionDeleting(workSessionId));
    });

  return promise;
};

export const deleteWorkSessionRouteTemplateDraft = (templateId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadWorkSession());

  const promise = Services.deleteWorkSessionRouteTemplateDraft(templateId);

  promise.finally(() => dispatch(completeIsLoading()));

  return promise;
};

export const publishWorkSession =
  (workSessionId: number, updateRouteTrackerTemplateIds?: number[]) => (dispatch: Dispatch) => {
    dispatch(startLoadWorkSession());

    const promise = Services.publishWorkSession(workSessionId, updateRouteTrackerTemplateIds);

    promise.finally(() => dispatch(completeIsLoading()));

    return promise;
  };

export const publishWorkSessionRouteTemplateDraft = (templateId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadWorkSession());

  const promise = Services.publishWorkSessionRouteTemplateDraft(templateId);

  promise.finally(() => dispatch(completeIsLoading()));

  return promise;
};

export const loadServiceContract = (workSessionId: number, serviceContractId: number) => (dispatch: Dispatch) => {
  const promise = Services.loadServiceContract(workSessionId, serviceContractId);

  promise.then(serviceContract => {
    dispatch(setSelectedServiceContract(serviceContract));
  });

  return promise;
};

export const loadServiceContractsExtraData = (serviceContractIds: number[]) => (dispatch: Dispatch) => {
  dispatch(loadSelectedServicesExtraData());

  const promise = Services.loadRouteTemplateBuilderServicesExtraData(serviceContractIds);

  promise
    .then(extraData => {
      dispatch(completeLoadSelectedServicesExtraData(extraData));
    })
    .catch(er => {
      if (!axios.isCancel(er)) {
        dispatch(failLoadSelectedServicesExtraData());
      }
    });

  return promise;
};

export const loadServiceContractsTotalsCount = (serviceContractIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startLoadSelectedServicesTotalsCounts());

  if (cancelSelectedServicesTotalsCountRequest)
    cancelSelectedServicesTotalsCountRequest(translate('common.inFlightRequestCanceled'));

  const cancelToken = new CancelToken(c => {
    cancelSelectedServicesTotalsCountRequest = c;
  });

  const promise = Services.loadRouteTemplateBuilderServicesTotalsCounts(serviceContractIds, cancelToken);

  promise
    .then(totalsCount => {
      dispatch(completeLoadSelectedServicesTotalsCounts(totalsCount));
    })
    .catch(er => {
      if (!axios.isCancel(er)) {
        dispatch(failLoadSelectedServicesTotalsCounts());
      }
    });

  return promise;
};
