import { filter, map, find } from 'lodash-es';

import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID, FILTER_VEHICLE_TYPE_ID } from '../constants';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';

export const getExcludedFiltersIds = (
  allOptions?: any[],
  preferencesFilters?: FilterSetting[],
  filtersCategoryId?: number,
  justGlobalFilters?: boolean,
) => {
  if (!allOptions || !preferencesFilters) return [];

  const filtersInCategory = preferencesFilters.filter(filter => filter.userGlobalFilterTypeId === filtersCategoryId);
  const isSectionLocked = filtersInCategory.some(filter => filter.isLockedSection);

  const preferencesFiltersId = map(
    isSectionLocked || justGlobalFilters
      ? filter(filtersInCategory, filterItem => filterItem.isGlobal)
      : filtersInCategory,
    (filter: FilterSetting) => filter.filterValueId,
  );

  if ((!justGlobalFilters && !isSectionLocked) || !filtersInCategory.length || !preferencesFiltersId.length) return [];

  return map(
    filter(allOptions, option => !preferencesFiltersId.includes(option.id)),
    option => option.id,
  );
};

export const getExcludeVehicleFilters = (filtersPreferences?: FilterSetting[], justGlobalFilters?: boolean) => {
  const vehicleFiltersPreferences = filter(
    filtersPreferences,
    item => item.userGlobalFilterTypeId === FILTER_VEHICLE_TYPE_ID,
  );
  const isVehicleFiltersSectionLocked = !!find(vehicleFiltersPreferences, {
    isLockedSection: true,
  });

  const vehicleFiltersPreferencesIds = map(
    isVehicleFiltersSectionLocked || justGlobalFilters
      ? filter(vehicleFiltersPreferences, vf => vf.isGlobal)
      : vehicleFiltersPreferences,
    (filter: FilterSetting) => filter.filterValueId,
  );

  if ((!justGlobalFilters && !isVehicleFiltersSectionLocked) || !vehicleFiltersPreferencesIds.length) return [];

  return map(
    filter(VEHICLE_TYPE_IDS, type => !vehicleFiltersPreferencesIds.includes(type.id)),
    item => VEHICLE_TYPE_IDS[item.id]?.technicalName,
  );
};

export const getVehicleFiltersPreferencesIds = (filters?: FilterSetting[]) => {
  const vehicleFiltersPreferences = filter(
    filters,
    item => item.userGlobalFilterTypeId === FILTER_VEHICLE_TYPE_ID && !item.isPreferenceUnselected,
  );
  return map(vehicleFiltersPreferences, (filter: FilterSetting) => filter.filterValueId) as unknown as number[];
};

export const getServiceZonesFiltersPreferencesIds = (filters?: FilterSetting[]) => {
  const serviceZoneFiltersPreferences = filter(
    filters,
    item => item.userGlobalFilterTypeId === FILTER_SERVICE_ZONE_ID && !item.isPreferenceUnselected,
  );
  return map(serviceZoneFiltersPreferences, (filter: FilterSetting) => filter.filterValueId) as unknown as number[];
};

export const getSupervisorsFiltersPreferencesIds = (filters?: FilterSetting[]) => {
  const serviceZoneFiltersPreferences = filter(
    filters,
    item => item.userGlobalFilterTypeId === FILTER_SUPERVISOR_ID && !item.isPreferenceUnselected,
  );
  return map(serviceZoneFiltersPreferences, (filter: FilterSetting) => filter.filterValueId) as unknown as number[];
};

export const getAllFiltersPreferencesIds = (filters?: FilterSetting[]) => {
  // if all the preferences are unselected, return global ones or empty array
  return {
    serviceZoneIdsCSV: getServiceZonesFiltersPreferencesIds(filters).length
      ? getServiceZonesFiltersPreferencesIds(filters).toString()
      : getGlobalServiceZoneFiltersIds(filters || []).toString(),
    supervisorIdsCSV: getSupervisorsFiltersPreferencesIds(filters).length
      ? getSupervisorsFiltersPreferencesIds(filters).toString()
      : getGlobalSupervisorFiltersIds(filters || []).toString(),
    vehicleTypeIdsCSV: getVehicleFiltersPreferencesIds(filters).length
      ? getVehicleFiltersPreferencesIds(filters).toString()
      : getGlobalVehicleFiltersIds(filters || []).toString(),
  };
};

export const getGlobalVehicleFiltersIds = (filters: FilterSetting[]) => {
  const vehicleFiltersPreferences = filter(
    filters,
    item => item.userGlobalFilterTypeId === FILTER_VEHICLE_TYPE_ID && item.isGlobal,
  );
  return map(vehicleFiltersPreferences, (filter: FilterSetting) => filter.filterValueId) as unknown as number[];
};

export const getGlobalServiceZoneFiltersIds = (filters: FilterSetting[]) => {
  const serviceZoneFiltersPreferences = filter(
    filters,
    item => item.userGlobalFilterTypeId === FILTER_SERVICE_ZONE_ID && item.isGlobal,
  );
  return map(serviceZoneFiltersPreferences, (filter: FilterSetting) => filter.filterValueId) as unknown as number[];
};

export const getGlobalSupervisorFiltersIds = (filters: FilterSetting[]) => {
  const serviceZoneFiltersPreferences = filter(
    filters,
    item => item.userGlobalFilterTypeId === FILTER_SUPERVISOR_ID && item.isGlobal,
  );
  return map(serviceZoneFiltersPreferences, (filter: FilterSetting) => filter.filterValueId) as unknown as number[];
};

/**
  used on pages where filtering option for the category is not available
  we load filtered data only if the admin locked the section
 */
export const getGlobalLockedFiltersIds = (filters: FilterSetting[], filtersCategoryId: number) => {
  const filtersInCategory = filters.filter(
    filter => filter.userGlobalFilterTypeId === filtersCategoryId && filter.isGlobal,
  );
  const isSectionLocked = filtersInCategory.some(filter => filter.isLockedSection);

  if (!isSectionLocked) return [];

  return map(filtersInCategory, (filter: FilterSetting) => filter.filterValueId) as unknown as number[];
};

export const hasPermissionBasedOnProperties = (
  filters?: FilterSetting[],
  properties?: { vehicleTypeId?: number; serviceZoneId?: number; supervisorId?: number },
) => {
  const vehicleTypeIds = getGlobalVehicleFiltersIds(filters || []);
  const serviceZoneIds = getGlobalServiceZoneFiltersIds(filters || []);
  const supervisorIds = getGlobalSupervisorFiltersIds(filters || []);

  // if there are no filter preferences we assume he has the rights
  if (!filters || !filters.length || !properties) return true;
  const { vehicleTypeId, serviceZoneId, supervisorId } = properties;

  let vehiclePermission = true;
  let serviceZonePermission = true;
  let supervisorPermission = true;

  if (vehicleTypeId) {
    // has preferences set for vehicle types
    if (
      vehicleTypeIds.length &&
      filters
        .filter(filter => filter.userGlobalFilterTypeId === FILTER_VEHICLE_TYPE_ID)
        .some(filter => filter.isLockedSection)
    )
      vehiclePermission = vehicleTypeIds.includes(vehicleTypeId);
  }
  if (serviceZoneId) {
    // has preferences set for service zones
    if (
      serviceZoneIds.length &&
      filters
        .filter(filter => filter.userGlobalFilterTypeId === FILTER_SERVICE_ZONE_ID)
        .some(filter => filter.isLockedSection)
    )
      serviceZonePermission = serviceZoneIds.includes(serviceZoneId);
  }
  if (supervisorId) {
    // has preferences set for supervisors
    if (
      supervisorIds.length &&
      filters
        .filter(filter => filter.userGlobalFilterTypeId === FILTER_SUPERVISOR_ID)
        .some(filter => filter.isLockedSection)
    )
      supervisorPermission = supervisorIds.includes(supervisorId);
  }

  return vehiclePermission && serviceZonePermission && supervisorPermission;
};
