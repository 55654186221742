import { mapKeys } from 'lodash-es';

export const ACTIVITY_BY_ACCOUNTING_CODE = 'ActivityByAccountingCode';
export const AGING_REPORT = 'AgingReport';
export const BILLING_REPORT = 'BillingReport';
export const COLLECTIONS_REPORT = 'CollectionsReport';
export const ORPHAN_INVOICE_REPORT = 'OrphanInvoiceReport';
export const PAYMENT_REPORT = 'PaymentReport';
export const DEPOSIT_SLIP_REPORT = 'DepositSlipReport';
export const ACTIVITY_REPORT = 'ActivityReport';
export const AUDIT_RATE = 'AuditRate';
export const AUDIT_TIME = 'AuditTime';
export const CART = 'Cart';
export const COMMUNITY_PARTICIPATION = 'CommunityParticipation';
export const CUSTOM_ROUTE_EXCEPTION = 'CustomRouteException';
export const CUSTOMER_EXPORT = 'Customer';
export const DAILY = 'Daily';
export const DELIVERY_UTILITY_JOB_HISTORY = 'DeliveryUtilityJobHistory';
export const DISPOSAL_TONS = 'DisposalTons';
export const DRIVER_PRODUCTIVITY = 'DriverProductivity';
export const DRIVER_SAFETY = 'DriverSafety';
export const DRIVER_SAFETY_INSPECTION = 'DriverSafetyInspection';
export const DRIVER_SUMMARY = 'DriverSummary';
export const EXCEPTION_RATE = 'ExceptionRate';
export const EXCEPTIONS = 'Exceptions';
export const EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD = 'ExceptionsByContaminatedHousehold';
export const EXCEPTIONS_BY_HOUSEHOLD = 'ExceptionsByHousehold';
export const FAULT_CODES = 'FaultCodes';
export const FUEL_LOG = 'FuelLog';
export const GEO_FENCES_COVERAGE_STREET_SWEEPING = 'GeoFencesCoverageStreetSweeping';
export const GEO_FENCES_STREET_SWEEPING = 'GeoFencesStreetSweeping';
export const HARD_ACCELERATION = 'HardAcceleration';
export const HARD_BREAKING = 'HardBraking';
export const HARD_TURNING = 'HardTurning';
export const INSIGHT_HISTORY = 'InsightHistory';
export const LOCATION_ALERTS = 'LocationAlerts';
export const MATERIAL_CONTAMINATION = 'MaterialContamination';
export const MULTIPLE_DAY_WEB_PAYMENT_REPORT = 'MultipleDayWebPaymentReport';
export const NEW_SERVICES_AUDIT_REPORT = 'NewServicesAuditReport';
export const OBSTACLES_STREET_SWEEPING = 'ObstaclesStreetSweeping';
export const ODAKYU_DAILY_ROUTE_SUMMARY = 'OdakyuDailyRouteSummary';
export const OFF_CURB_OBSTACLES = 'OffCurbObstacles';
export const OFF_ROUTE = 'TotalOffRoute';
export const ON_CURB_STREET_SWEEPER = 'OnCurbStreetSweeping';
export const PARTICIPATION_RATE = 'ParticipationRate';
export const PARTICIPATIONS = 'Participations';
export const PICKUPS_PER_HOUR = 'PickupsPerHour';
export const PRE_POST_TRIP_INSPECTION = 'PrePostTripInspection';
export const RECYCLE_PARTICIPATION_RATE = 'RecycleParticipationRate';
export const RESIDENTIAL_STOP = 'ResidentialStop';
export const ROLL_OF_SERVICE_HISTORY = 'RollOffServiceHistory';
export const ROLL_OFF_JOB_HISTORY = 'RollOffJobHistory';
export const ROUTE_EXCEPTION = 'RouteException';
export const ROUTE_PRODUCTIVITY = 'RouteProductivity';
export const ROUTE_SUMMARY = 'RouteSummary';
export const SAFETY = 'Safety';
export const SET_OUT = 'SetOut';
export const SET_OUT_RATE = 'SetOutRate';
export const SINGLE_DAY_WEB_PAYMENT_REPORT = 'SingleDayWebPaymentReport';
export const SNOW_PLOW_CHART = 'SnowPlow';
export const SNOW_ROUTE_SUMMARY = 'SnowRouteSummary';
export const SPEEDING = 'Speeding';
export const STATIONARY = 'Stationary';
export const STATIONARY_OFF_ROUTE = 'StationaryOffRoute';
export const STREET_SWEEPER_ROUTE_SUMMARY = 'StreetSweeperRouteSummary';
export const STREET_SWEEPING_ACTIVITY_SUMMARY = 'StreetSweepingActivitySummary';
export const STREET_SWEEPING_COMPLETION = 'StreetSweeperCompletion';
export const STREET_SWEEPING_LOADS_DUMPED = 'StreetSweeperLoadsDumped';
export const STREET_SWEEPING_MILES_SWEPT = 'StreetSweepingMilesSwept';
export const STREET_SWEEPING_OBSTACLES = 'StreetSweepingObstacles';
export const STREET_SWEEPING_WATER_FILLUP = 'StreetSweeperWaterFillUp';
// export const STREET_SWEEPING_WATER_FILLUPS = 'StreetSweepingWaterFillUps';
export const TRIP_TIME = 'TripTime';
export const TRIP_TIME_STREET_SWEEPER = 'TripTimeStreetSweeping';
export const UNSERVICED_RESIDENTIAL = 'UnservicedResidential';
export const USER_ACTIVITY = 'UserActivity';
export const Y_DEVICE_STATUS = 'YDeviceStatus';

export const ROUTE_SUMMARY_ID = 1;
export const ROUTE_EXCEPTION_ID = 2;
export const INSIGHT_HISTORY_ID = 3;
export const DISPOSAL_TONS_ID = 5;
export const SAFETY_ID = 6;
export const STREET_SWEEPING_ACTIVITY_SUMMARY_ID = 7;
export const STREET_SWEEPING_OBSTACLES_ID = 8;
export const EXCEPTIONS_ID = 9;
export const UNSERVICED_RESIDENTIAL_ID = 12;
export const ROLL_OF_SERVICE_HISTORY_ID = 13;
export const FAULT_CODES_ID = 14;
export const PRE_POST_TRIP_INSPECTION_ID = 15;
export const DELIVERY_UTILITY_JOB_HISTORY_ID = 16;
export const COMMUNITY_PARTICIPATION_ID = 17;
export const Y_DEVICE_STATUS_ID = 18;
export const DRIVER_SUMMARY_ID = 19;
export const DRIVER_PRODUCTIVITY_ID = 20;
export const ROUTE_PRODUCTIVITY_ID = 21;
export const LOCATION_ALERTS_ID = 22;
export const FUEL_LOG_ID = 23;
export const CART_ID = 24;
export const DRIVER_SAFETY_INSPECTION_ID = 25;
export const CUSTOM_ROUTE_EXCEPTION_ID = 26;
export const ROLL_OFF_JOB_HISTORY_ID = 27;
export const DAILY_ID = 28;
export const CUSTOMER_EXPORT_ID = 29;
export const USER_ACTIVITY_REPORT_ID = 30;

export const REPORT_TYPES = mapKeys(
  [
    { id: ROUTE_SUMMARY_ID, technicalName: ROUTE_SUMMARY },
    { id: ROUTE_EXCEPTION_ID, technicalName: ROUTE_EXCEPTION },
    { id: INSIGHT_HISTORY_ID, technicalName: INSIGHT_HISTORY },
    { id: DISPOSAL_TONS_ID, technicalName: DISPOSAL_TONS },
    { id: SAFETY_ID, technicalName: SAFETY },
    { id: STREET_SWEEPING_ACTIVITY_SUMMARY_ID, technicalName: STREET_SWEEPING_ACTIVITY_SUMMARY },
    { id: STREET_SWEEPING_OBSTACLES_ID, technicalName: STREET_SWEEPING_OBSTACLES },
    { id: EXCEPTIONS_ID, technicalName: EXCEPTIONS },
    { id: UNSERVICED_RESIDENTIAL_ID, technicalName: UNSERVICED_RESIDENTIAL },
    { id: ROLL_OF_SERVICE_HISTORY_ID, technicalName: ROLL_OF_SERVICE_HISTORY },
    { id: FAULT_CODES_ID, technicalName: FAULT_CODES },
    { id: PRE_POST_TRIP_INSPECTION_ID, technicalName: PRE_POST_TRIP_INSPECTION },
    { id: DELIVERY_UTILITY_JOB_HISTORY_ID, technicalName: DELIVERY_UTILITY_JOB_HISTORY },
    { id: COMMUNITY_PARTICIPATION_ID, technicalName: COMMUNITY_PARTICIPATION },
    { id: Y_DEVICE_STATUS_ID, technicalName: Y_DEVICE_STATUS },
    { id: DRIVER_SUMMARY_ID, technicalName: DRIVER_SUMMARY },
    { id: DRIVER_PRODUCTIVITY_ID, technicalName: DRIVER_PRODUCTIVITY },
    { id: ROUTE_PRODUCTIVITY_ID, technicalName: ROUTE_PRODUCTIVITY },
    { id: LOCATION_ALERTS_ID, technicalName: LOCATION_ALERTS },
    { id: FUEL_LOG_ID, technicalName: FUEL_LOG },
    { id: CART_ID, technicalName: CART },
    { id: DRIVER_SAFETY_INSPECTION_ID, technicalName: DRIVER_SAFETY_INSPECTION },
    { id: CUSTOM_ROUTE_EXCEPTION_ID, technicalName: CUSTOM_ROUTE_EXCEPTION },
    { id: ROLL_OFF_JOB_HISTORY_ID, technicalName: ROLL_OFF_JOB_HISTORY },
    { id: DAILY_ID, technicalName: DAILY },
  ],
  'id',
);
