import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { httpInsightsReporting } from '../../core/services/http';

const searchReportingDetailsVehicles = (
  vendorId: number,
  startDate: string,
  endDate: string,
  reportType: string,
  filtersPreferencesIds: FiltersPreferencesIds,
) =>
  httpInsightsReporting
    .get('/reporting/reportingDetails/vehicles', {
      params: {
        vendorId,
        startDate,
        endDate,
        reportType,
        vehicleTypeIdsCSV: filtersPreferencesIds.vehicleTypeIdsCSV,
      },
    })
    .then(response => response.data);

export default searchReportingDetailsVehicles;
