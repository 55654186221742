import { map, flatten } from 'lodash-es';

import createTransformer from '../../utils/services/createTransformer';

const transformLoadVehiclePositions = (values: any) => {
  const vehiclePositions = createTransformer(values);

  return {
    ...vehiclePositions.pick('completedStops', 'totalStops').value,
    vehiclePositions: flatten(
      vehiclePositions.get('vehiclePositions').map((vehiclePosition: any) =>
        map(vehiclePosition.vehicles, vehicle => ({
          ...vehicle,
          vehicleType: vehiclePosition.vehicleType,
        })),
      ).value,
    ),

    vehicleInsights: vehiclePositions
      .get('vehiclePositions')
      .map((vehiclePosition: any) => ({
        ...vehiclePosition.insight,
        displayOrder: vehiclePosition.displayOrder,
        displayName: vehiclePosition.vehicleType.name,
        name: vehiclePosition.vehicleType.technicalName,
      }))
      .orderBy('displayOrder').value,
  };
};

export default transformLoadVehiclePositions;
