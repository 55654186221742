import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../contracts/ducks';
import { loadDispatchBoardUnassignedJobs } from '../../ducks/dispatchBoard';
import { loadDispatchBoardOnHoldJobs } from 'src/routes/ducks/dispatchBoard/dispatchBoardOnHoldJobs';
import { loadDisposalFacilities, loadOperationalFacilities, loadVehicles } from '../../../fleet/ducks';
import { loadRouteBuilderSettings } from '../../../vendors/ducks';
import { loadServiceTypes, loadWasteTypes } from '../../../common/ducks';
import { loadServiceZones } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { RouteSettings } from 'src/vendors/interfaces/RouteSettings';
import { TODAY_FORMATTED } from 'src/core/constants';
import DispatchBoardRouteBuilderModal from './DispatchBoardRouteBuilderModal';

/** TODO Intermediate Facility for Route Builder */
// import { loadDisposalFacilities } from '../../../fleet/ducks/facilities';

interface Props {
  closeModal: () => void;
  finishBuilding?: boolean;
  loadDispatchBoardUnassignedJobs: DuckFunction<typeof loadDispatchBoardUnassignedJobs>;
  loadDispatchBoardOnHoldJobs: DuckFunction<typeof loadDispatchBoardOnHoldJobs>;
  loadDisposalFacilities: DuckFunction<typeof loadDisposalFacilities>;
  loadOperationalFacilities: DuckFunction<typeof loadOperationalFacilities>;
  loadRouteBuilderSettings: DuckFunction<typeof loadRouteBuilderSettings>;
  loadServiceTypes: DuckFunction<typeof loadServiceTypes>;
  loadServiceZones: DuckFunction<typeof loadServiceZones>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  onSubmitRouteBuilder: (values: RouteSettings) => void;
  vehicleTypeId: number;
  vendorId: number;

  /** TODO Intermediate Facility for Route Builder */
  // loadDisposalFacilities: DuckFunction<typeof loadDisposalFacilities>;
}

class DispatchBoardRouteBuilderModalResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      loadDispatchBoardUnassignedJobs,
      loadDispatchBoardOnHoldJobs,
      loadDisposalFacilities,
      loadOperationalFacilities,
      loadRouteBuilderSettings,
      loadServiceTypes,
      loadServiceZones,
      loadVehicles,
      loadWasteTypes,
      vehicleTypeId,
      vendorId,
      /** TODO Intermediate Facility for Route Builder */
      // loadDisposalFacilities,
    } = this.props;

    const allRecords = false;
    const showActiveTrucks = true;
    const vehicleTypeIdUndefined = undefined;

    const promises: any[] = [
      loadDispatchBoardUnassignedJobs(vendorId, vehicleTypeId),
      loadDispatchBoardOnHoldJobs(vendorId, TODAY_FORMATTED),
      loadDisposalFacilities(vendorId),
      loadOperationalFacilities(vendorId),
      loadRouteBuilderSettings(vendorId),
      loadServiceTypes(vehicleTypeId),
      loadServiceZones(vendorId, allRecords),
      loadVehicles(vendorId, showActiveTrucks, vehicleTypeIdUndefined, TODAY_FORMATTED),
      loadWasteTypes(),
      /** TODO Intermediate Facility for Route Builder */
      // loadDisposalFacilities(vendorId),
    ];

    return Promise.all(promises);
  };

  render() {
    const { closeModal, vehicleTypeId, vendorId, onSubmitRouteBuilder, finishBuilding } = this.props;

    return (
      <Resolver
        successComponent={DispatchBoardRouteBuilderModal}
        successProps={{
          closeModal,
          finishBuilding,
          onSubmitRouteBuilder,
          vehicleTypeId,
          vendorId,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = {
  loadDispatchBoardUnassignedJobs,
  loadDispatchBoardOnHoldJobs,
  loadDisposalFacilities,
  loadOperationalFacilities,
  loadRouteBuilderSettings,
  loadServiceTypes,
  loadServiceZones,
  loadVehicles,
  loadWasteTypes,
  /** TODO Intermediate Facility for Route Builder */
  // loadDisposalFacilities,
};

export default connect(undefined, mapDispatchToProps)(DispatchBoardRouteBuilderModalResolver);
