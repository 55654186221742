import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { downloadDocument } from '../../ducks';
import { DuckFunction } from '../../../contracts/ducks';
import { OpportunityToggle } from '../../../opportunity/components/styled';
import { TableRow, TableCell, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

type Props = {
  downloadDocument: DuckFunction<typeof downloadDocument>;
  fileId: number;
  fileName: string;
};

class DocumentListTableRow extends PureComponent<Props> {
  onClickDownload = async (event: React.MouseEvent, fileId: number, fileName: string) => {
    const { downloadDocument } = this.props;
    event.preventDefault();

    downloadDocument(fileId, fileName)
      .then(() => createSuccessNotification(translate('common.alertMessages.downloadCompleted')))
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.downloadFailed'));
      });
  };

  render() {
    const { fileId, fileName } = this.props;

    return (
      <TableRow>
        <TableCell width={'100%'} align="left">
          <OpportunityToggle color="primary" onClick={e => this.onClickDownload(e, fileId, fileName)}>
            <Text weight="normal" size="small">
              {fileName}
            </Text>
          </OpportunityToggle>
        </TableCell>
      </TableRow>
    );
  }
}

const mapDispatchToProps = {
  downloadDocument,
};

export default connect(undefined, mapDispatchToProps)(DocumentListTableRow);
