import { DragEvent, MouseEvent } from 'react';

import { DispatchBoardRouteJob } from '../../../../interfaces/DispatchBoardRouteJob';
import { JobTypes } from '../../../../constants';
import { ModalTypes } from './common/ModalContainer';
import { theme } from 'src/core/styles';
import DispatchBoardJob from './DispatchBoardJob';

interface DispatchBoardUnassignedJobProps {
  index: number;
  style: object;
  data: {
    handleDeleteJob: (jobId: number) => void;
    openedJobId?: number;
    selectedJobs: number[];
    handleJobSelection: (event: MouseEvent, index: number) => void;
    handleDragStart: (event: DragEvent<HTMLDivElement>, jobId: number, serviceContractId: number) => void;
    onJobDrop: (
      sourceRouteId: number,
      targetRouteId: number,
      jobsSelected: DispatchBoardRouteJob[],
      position?: number,
      type?: string,
    ) => void;
    toggleJob: (event: MouseEvent, jobId: number) => void;
    toggleModal: (modal: ModalTypes, open?: boolean, isHeader?: boolean) => void;
    jobs: DispatchBoardRouteJob[];
  };
}

const DispatchBoardUnassignedJob = ({ index, style, data }: DispatchBoardUnassignedJobProps) => {
  const {
    handleDeleteJob,
    handleDragStart,
    handleJobSelection,
    jobs,
    onJobDrop,
    openedJobId,
    selectedJobs,
    toggleJob,
    toggleModal,
  } = data;

  const job = jobs[index];

  const isExpanded = job.id === openedJobId;
  const isSelected = selectedJobs.indexOf(index) > -1;

  return (
    <div style={{ ...style, background: isExpanded ? theme.grayLight : theme.grayLighter }}>
      <DispatchBoardJob
        deleteJob={handleDeleteJob}
        draggable
        isExpanded={isExpanded}
        isSelected={isSelected}
        job={job}
        jobIndex={index}
        key={job.id}
        onClick={(event: MouseEvent) => handleJobSelection(event, index)}
        onDragStart={(event: DragEvent<HTMLDivElement>) => handleDragStart(event, job.id, job.serviceContractId)}
        onJobDrop={onJobDrop}
        toggleJob={toggleJob}
        toggleModal={toggleModal}
        type={JobTypes.unassigned}
      />
    </div>
  );
};

export default DispatchBoardUnassignedJob;
