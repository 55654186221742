import React, { Fragment, PureComponent } from 'react';

import { camelCase } from 'lodash-es';

import { date, time } from '../../../utils/services/formatter';
import { Label, TableCell, TableRow, Text } from '../../../core/components/styled';
import { PICKUP_STATUS_LABEL_COLORS } from '../../../routes/constants/pickupStatusLabelColors';
import { PICKUP_STATUSES } from '../../../common/constants';
import { RouteLocationImagesModalResolver } from './';
import { RouteTrackerIssue } from '../../../routes/interfaces/RouteTrackerIssue';
import { ThumbnailSet } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props extends RouteTrackerIssue {
  vehicleTypeTechnicalName?: string;
  pickupExceptionDescription?: string;
}

interface State {
  isRouteIssueImagesModalOpen: boolean;
}

class RouteTrackerIssuesModalTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isRouteIssueImagesModalOpen: false };
  }

  openRouteIssueImagesModal = () => {
    this.setState({ isRouteIssueImagesModalOpen: true });
  };

  closeRouteIssueImagesModal = () => {
    this.setState({ isRouteIssueImagesModalOpen: false });
  };

  render() {
    const {
      customerLocationAddress,
      customerName,
      images,
      pickupExceptionDescription,
      pickupExceptionType,
      routeId,
      routeLocationId,
      routeLocationStatusId,
      stopNumber,
      timeStamp,
    } = this.props;
    const { isRouteIssueImagesModalOpen } = this.state;

    const imageUrls: string[] = [];
    const imageIds: number[] = [];

    if (images.length) {
      images.forEach(image => {
        imageUrls.push(image.url);
        imageIds.push(image.imageId);
      });
    }
    return (
      <Fragment>
        <TableRow>
          <TableCell width="11%">{stopNumber}</TableCell>
          <TableCell width="23%">
            <Text block weight="medium">
              {customerName}
              <Text weight="normal" block size="small" margin="xxSmall no no no">
                {customerLocationAddress}
              </Text>
            </Text>
          </TableCell>

          <TableCell width="14%">
            <Text block>
              {date(timeStamp)}
              <Text block size="small" margin="xxSmall no no no">
                {time(timeStamp)}
              </Text>
            </Text>
          </TableCell>

          <TableCell width="15%">
            <Text block size="modal">
              {pickupExceptionType &&
                translate(`routes.pickupIssueTypes.${camelCase(pickupExceptionType.technicalName)}`)}
            </Text>
          </TableCell>
          <TableCell width="15%">
            <Text block>{pickupExceptionDescription || '-'}</Text>
          </TableCell>

          <TableCell width="13%">
            {!!routeLocationStatusId && (
              <Label color={PICKUP_STATUS_LABEL_COLORS[routeLocationStatusId.toString()]}>
                {PICKUP_STATUSES[routeLocationStatusId].name}
              </Label>
            )}
          </TableCell>

          <TableCell width="9%">
            {!!imageUrls.length && <ThumbnailSet images={imageUrls} onClick={this.openRouteIssueImagesModal} />}
          </TableCell>
        </TableRow>

        {isRouteIssueImagesModalOpen && (
          <RouteLocationImagesModalResolver
            imageIds={imageIds}
            routeId={routeId}
            routeLocationId={routeLocationId}
            closeModal={this.closeRouteIssueImagesModal}
          />
        )}
      </Fragment>
    );
  }
}

export default RouteTrackerIssuesModalTableRow;
