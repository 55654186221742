import React, { useMemo, useRef, useState } from 'react';

import humps from 'humps';
import { clone, cloneDeep, size } from 'lodash-es';
import { observable, transaction } from 'mobx';

import translate from '../../../core/services/translate';
import { Container, FollowCarContainer, MapContainer, VehicleInfo } from '../styled/MapWithTimeline';
import {
  ContainerInsights,
  RouteMapProps,
  TimelineTracking,
  TripTimeDetails,
  VehiclePosition,
  VehicleTracking,
} from './Interfaces';
import { MapTimeline } from './MapTimeline';
import RouteMapTimelineGL from 'src/routes/components/pages/routes/routePageSections/routeMap/RouteMapTimelineGL';

const enrichVehicleTracking = (vehicleTracking: VehicleTracking[]) => {
  const vtClone = cloneDeep(vehicleTracking);
  if (size(vtClone)) {
    vtClone.forEach(vt => {
      vt.coordinateGroups.forEach(g => {
        g.timestampMs = new Date(g.endTimestamp).getTime();
        g.hideOnMap = observable.box(true);
      });
      vt.applicationModeChanges.forEach(amc => {
        amc.timestampMs = new Date(amc.timestamp).getTime();
        amc.hideOnMap = observable.box(true);
      });
    });
  }
  return vtClone;
};

const enrichContainerInsights = (containerInsights: ContainerInsights[]) => {
  const cisClone = cloneDeep(containerInsights);
  cisClone.forEach(ci => {
    ci.displayAsScheduled = observable.box(true);
    ci.reportTimestampMs = new Date(ci.reportDateTime).getTime();
  });
  return cisClone;
};

const transformVehicleInsights = (vehicleInsights: any[]) => {
  const viClone = cloneDeep(vehicleInsights);
  viClone.forEach(vi => {
    vi.hideOnMap = observable.box(true);
    vi.timestampMs = new Date(vi.reportDateTime).getTime();
  });
  return viClone;
};

interface MapWithTimelineProps {
  mapProps: RouteMapProps;
  vehicleInsights: any[];
  tripTimeDetails: TripTimeDetails;
}

interface EventProgressMap {
  [key: string]: { total: number; completed: number };
}

export const MapWithTimeline: React.FC<MapWithTimelineProps> = props => {
  const { mapProps, tripTimeDetails } = props;
  const [vehiclePositions, setVehiclePosition] = useState<VehiclePosition[]>(mapProps.vehiclePositions);
  const vehiclePosition = {
    ...vehiclePositions[0],
    vehicleId: vehiclePositions[0]?.vehicle?.id,
  };

  const [averageSpeed, setAverageSpeed] = useState(0);

  const vehiclePositionRef = useRef(vehiclePosition);

  const [completedStops, setCompletedStops] = useState(0);

  const vehicleTrackings = useMemo(() => enrichVehicleTracking(mapProps.vehicleTrackings), [mapProps.vehicleTrackings]);
  const containerInsights = useMemo(
    () => enrichContainerInsights(mapProps.containerInsights),
    [mapProps.containerInsights],
  );

  const [eventsProgress, setEventsProgress] = useState<EventProgressMap>({});

  const vehicleInsights = useMemo(() => transformVehicleInsights(props.vehicleInsights), [props.vehicleInsights]);

  const moveVehicle = (tracking: TimelineTracking) => {
    if (!tracking) {
      return;
    }

    let eventsProgress: EventProgressMap = {};
    let completedStops = 0;

    // set marker visibility
    transaction(() => {
      // todo (sc): think of a better way to update marker visibility. this reiterates every 100ms which is not very effective.

      containerInsights.forEach(ci => {
        const isScheduled = tracking.timestamp < ci.reportTimestampMs || ci.reportTimestampMs === 0;
        if (!isScheduled) {
          completedStops++;
        }
        ci.displayAsScheduled.set(isScheduled);
      });

      if (vehicleTrackings[0]) {
        vehicleTrackings.forEach(vt => {
          vt.coordinateGroups.forEach(cg => cg.hideOnMap.set(tracking.timestamp < cg.timestampMs));
          vt.applicationModeChanges.forEach(amc => amc.hideOnMap.set(tracking.timestamp < amc.timestampMs));
        });
      }

      vehicleInsights.forEach(e => {
        const isVisibleOnMap = tracking.timestamp >= e.timestampMs;
        e.hideOnMap.set(!isVisibleOnMap);
        const eventType: string = e.insightSubTypes[0].name;
        if (!eventsProgress[eventType]) {
          eventsProgress[eventType] = { total: 0, completed: 0 };
        }
        eventsProgress[eventType].total++;
        if (isVisibleOnMap) {
          eventsProgress[eventType].completed++;
        }
      });
    });

    setEventsProgress(eventsProgress);
    setCompletedStops(completedStops);

    // set vehicle position
    const vp = clone(vehiclePosition);
    if (vp) {
      vp.coordinates = tracking.coordinates;
    }
    vehiclePositionRef.current = vp;
    setVehiclePosition([vp]);
    setAverageSpeed(tracking.speed);
  };

  const coordinateGroups = vehicleTrackings[0]?.coordinateGroups.filter(
    (coordinate: any) => !coordinate.hideOnMap.get(),
  );
  const applicationModeChanges: any = vehicleTrackings[0]?.applicationModeChanges
    .filter((applicationMode: any) => !applicationMode.hideOnMap.get())
    .map((applicationMode: any) => {
      return {
        ...applicationMode,
        latitude: applicationMode.coordinates.latitude,
        longitude: applicationMode.coordinates.longitude,
      };
    });
  const vehicleTrackingsFormated = !!vehicleTrackings.length
    ? [
        {
          coordinateGroups,
          applicationModeChanges,
          vehicle: vehicleTrackings[0]?.vehicle,
        },
      ]
    : [];

  const vehicleInsightsFormated = vehicleInsights.filter((vehicleInsight: any) => !vehicleInsight.hideOnMap.get());

  return (
    <Container>
      <FollowCarContainer>
        <VehicleInfo>
          {(!tripTimeDetails.tripTimeDetails || !tripTimeDetails.tripTimeDetails.length) && (
            <div>
              <b>
                <sup>*</sup>
                {translate('routeTimeline.routeTimelineNoInfoMessage')}
              </b>
            </div>
          )}
          {tripTimeDetails && (
            <>
              <div>
                {translate('routeTimeline.driver')}: {tripTimeDetails.driver.name}
              </div>
              <div>
                {translate('routeTimeline.vehicle')}: {tripTimeDetails.vehicle.name}
              </div>
            </>
          )}
          <div>
            {translate('routeTimeline.speed')} ({mapProps.unitOfMeasure}): {averageSpeed}
          </div>
          <div>
            {translate('routeTimeline.completedStops')}: {completedStops} / {containerInsights.length}
          </div>
          {Object.keys(eventsProgress).map(k => (
            <div key={k}>
              {translate(`routeTimeline.${humps.camelize(k)}`)}: {eventsProgress[k].completed} /{' '}
              {eventsProgress[k].total}
            </div>
          ))}
        </VehicleInfo>
      </FollowCarContainer>
      <MapContainer>
        <RouteMapTimelineGL
          vehicleTrackings={vehicleTrackingsFormated}
          containerInsights={containerInsights}
          vehiclePosition={vehiclePosition}
          vehicleInsights={vehicleInsightsFormated}
          alertsAreReadOnly
        />
      </MapContainer>
      {vehicleTrackings[0] && (
        <MapTimeline
          tripTimeDetails={props.tripTimeDetails}
          runTimeline={moveVehicle}
          vehicleTracking={vehicleTrackings[0]}
        />
      )}
    </Container>
  );
};
