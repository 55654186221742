import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadCityAlertSettings } from '../../ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import CityAlertSettingsModal from './CityAlertSettingsModal';
import { loadRequirementTypes, loadTimeMeasurementTypes, loadUnitOfMeasureTypes } from 'src/common/ducks';

const CityAlertSettingsModalResolver: React.FC<ModalProps> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([
      loadCityAlertSettings(vendorId)(dispatch),
      loadTimeMeasurementTypes()(dispatch),
      loadUnitOfMeasureTypes()(dispatch),
      loadRequirementTypes()(dispatch),
    ]);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      successComponent={CityAlertSettingsModal}
      successProps={{ closeModal, vendorId }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      onError={closeModal}
    />
  );
};

export default CityAlertSettingsModalResolver;
