import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const TON = 1;
export const POUND = 2;
export const GALLON = 6;
export const LOAD = 8;
export const FILL_UP = 9;
export const CUBIC_YARDS = 11;

export const DEBRIS_UNIT_OF_MEASUREMENT = mapKeys(
  [
    { id: TON, name: translate('common.loadsDumped.unitOfMeasureValues.ton') },
    { id: POUND, name: translate('common.loadsDumped.unitOfMeasureValues.pound') },
    { id: LOAD, name: translate('common.loadsDumped.unitOfMeasureValues.load') },
    { id: CUBIC_YARDS, name: translate('common.loadsDumped.unitOfMeasureValues.cubicYards') },
  ],
  'id',
);

export const WATER_UNIT_OF_MEASUREMENT = mapKeys(
  [
    { id: GALLON, name: translate('common.waterFill.unitOfMeasureValues.gallon') },
    { id: FILL_UP, name: translate('common.waterFill.unitOfMeasureValues.fillUp') },
  ],
  'id',
);
