import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const REQUIRED = 1;
export const NOT_REQUIRED = 2;
export const OPTIONAL = 3;

export const PICTURE_OPTIONS = [
  { label: translate('vendors.pictureConfigurationTypes.required'), value: 1 },
  { label: translate('vendors.pictureConfigurationTypes.optional'), value: 3 },
  { label: translate('vendors.pictureConfigurationTypes.none'), value: 4 },
];

export const PICTURE_REQUIREMENT_OPTIONS = [
  { value: REQUIRED, label: translate('vendors.pictureConfigurationTypes.required') },
  { value: OPTIONAL, label: translate('vendors.pictureConfigurationTypes.optional') },
  { value: NOT_REQUIRED, label: translate('vendors.pictureConfigurationTypes.none') },
];

export const PICTURE_REQUIREMENT_TYPES = mapKeys(
  [
    { id: REQUIRED, name: translate('vendors.pictureConfigurationTypes.required'), technicalName: 'Required' },
    { id: OPTIONAL, name: translate('vendors.pictureConfigurationTypes.optional'), technicalName: 'Optional' },
    {
      id: NOT_REQUIRED,
      name: translate('vendors.pictureConfigurationTypes.notRequired'),
      technicalName: 'NotRequired',
    },
  ],
  'id',
);
