import React, { PureComponent } from 'react';
import { Collapsible as CollapsibleContainer, CollapsibleContent } from './styled';

interface Props {
  children: any;
  isOpen: boolean;
  width?: string;
}

interface State {
  height: string | number;
}

class Collapsible extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      height: props.isOpen ? 'auto' : 0,
    };
  }

  private timeoutId?: number;
  private contentRef?: HTMLDivElement;

  componentDidUpdate() {
    const { isOpen } = this.props;
    if (!!this.state.height !== isOpen) {
      this.timeoutId = setTimeout(() => {
        if (!this.contentRef) return;

        if (!isOpen) {
          this.setState({ height: this.contentRef.offsetHeight });
          setTimeout(() => {
            this.setState({ height: 0 });
          });

          return;
        }

        this.setState({
          height: this.contentRef.offsetHeight,
        });
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  onTransitionEnd = () => {
    this.setState({
      height: this.props.isOpen ? 'auto' : 0,
    });
  };

  setContentRef = (element: HTMLDivElement) => {
    this.contentRef = element;
  };

  render() {
    const { children, isOpen, width } = this.props;
    const { height } = this.state;

    return (
      <CollapsibleContainer
        isOpen={isOpen}
        style={{ height: height, width: width }}
        onTransitionEnd={this.onTransitionEnd}
      >
        <CollapsibleContent ref={this.setContentRef}>{children}</CollapsibleContent>
      </CollapsibleContainer>
    );
  }
}

export default Collapsible;
