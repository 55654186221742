import { map } from 'lodash-es';

import { RouteTemplate } from 'src/routes/interfaces/RouteTemplates';

const transformLoadRouteTemplatesByScheduledDays = (routeTemplates: RouteTemplate[]) =>
  map(routeTemplates, routeTemplate => ({
    label: routeTemplate.routeTemplateName,
    value: routeTemplate.id,
    vehicleTypeId: routeTemplate.vehicleTypeId,
    scheduledDay: routeTemplate.scheduledDay,
    hasStopsInPendingOptimization: routeTemplate.hasStopsInPendingOptimization,
  }));

export default transformLoadRouteTemplatesByScheduledDays;
