import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification } from 'src/core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { featuresForLoggedInUser, loadFeatures, saveFeatures } from 'src/vendors/ducks/features';
import { FeaturesFormValues } from 'src/vendors/interfaces/Features';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalSection } from '../../../core/components/styled';
import accountSettingsFeaturesFormInitialValues from 'src/vendors/services/accountSettingsFeaturesFormInitialValuesSelector';
import AppExperienceForm from '../forms/AppExperienceForm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  closeModal: (pristine?: boolean) => void;
}

export default function AppExperienceModal({ closeModal, featureCode }: Props) {
  const dispatch = useDispatch();

  const features = useSelector(featuresForLoggedInUser);
  const { isSavingChild, isLoadingChild } = useSelector((state: AppState) => state.vendors.features);
  const featuresValues = useSelector(accountSettingsFeaturesFormInitialValues);
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const saveFeaturesConfiguration = (values: FeaturesFormValues) => {
    if (!vendorId) {
      return;
    }

    const updatedFeatures = features.map(feature => ({
      ...feature,
      enabled: !!values[feature.code],
    }));

    const isOnlyChildLoading = true;

    saveFeatures(
      vendorId,
      updatedFeatures,
      isOnlyChildLoading,
    )(dispatch)
      .then(() => {
        loadFeatures(vendorId, isOnlyChildLoading)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.alertMessages.saveFeaturesError'));
      });
  };

  return (
    <Modal
      size="smallMedium"
      title={translate(createTranslationKey('generalFeatures', 'vendors.featureCodes'))}
      onClose={closeModal}
      isLoading={isSavingChild || isLoadingChild}
    >
      <ModalSection>
        <AppExperienceForm initialValues={featuresValues} onSubmit={saveFeaturesConfiguration} />
      </ModalSection>
    </Modal>
  );
}
