import styled, { css } from 'styled-components';
interface WrapperProps {
  withBorder?: boolean;
}

export const PaymentDetailsWrapper = styled.div<WrapperProps>`
  display: flex;
  flex: 1;
  padding-bottom: 15px;
  ${props =>
    props.withBorder &&
    css`
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `};
`;
