import { identity, find, get } from 'lodash-es';
import { createSelector } from 'reselect';

const getWasteAuditFormInitialValues = (wasteAuditConfigurationState: any) => {
  const wasteAuditConfiguration = get(wasteAuditConfigurationState, 'wasteAuditConfiguration', []);
  const wasteAuditConfigurationType = find(wasteAuditConfiguration, { isEnabled: true });

  return {
    wasteAuditConfiguration,
    wasteAuditConfigurationType: wasteAuditConfigurationType ? wasteAuditConfigurationType.name : undefined,
  };
};

const wasteAuditFormInitialValuesSelector = createSelector(getWasteAuditFormInitialValues, identity);

export default wasteAuditFormInitialValuesSelector;
