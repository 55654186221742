import update from 'immutability-helper';
import { LngLat } from 'mapbox-gl';
import { AnyAction } from 'redux';

import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { setPersistentSOSAlertVehicleId, clearPersistentSOSAlertVehicleId } from 'src/common/utils/sosAlerts';

/**
 * Actions
 */
const SET_BOX = 'dashboard/mapControls/SET_BOX';
const SET_VIEWPORT = 'dashboard/mapControls/SET_VIEWPORT';
const SET_MODAL_MAP_VIEWPORT = 'dashboard/mapControls/SET_MODAL_MAP_VIEWPORT';
const SET_SATELLITE = 'dashboard/mapControls/SET_SATELLITE';
const SET_MODAL_MAP_SATELLITE = 'dashboard/mapControls/SET_MODAL_MAP_SATELLITE';
const SET_SELECTED_FEATURE = 'dashboard/mapControls/SET_SELECTED_FEATURE';
const SET_MODAL_MAP_SELECTED_FEATURE = 'dashboard/mapControls/SET_MODAL_MAP_SELECTED_FEATURE';
const CLEAR_SELECTED_FEATURE = 'dashboard/mapControls/CLEAR_SELECTED_FEATURE';
const CLEAR_MODAL_MAP_SELECTED_FEATURE = 'dashboard/mapControls/CLEAR_MODAL_MAP_SELECTED_FEATURE';
const SET_SOS_ALERT_VEHICLE = 'dashboard/mapControls/SET_SOS_ALERT_VEHICLE';
const CLEAR_SOS_ALERT_VEHICLE = 'dashboard/mapControls/CLEAR_SOS_ALERT_VEHICLE';
const RESET = 'dashboard/mapControls/RESET';
const SET_MAP_MODAL_DATA = 'dashboard/mapControls/SET_MAP_MODAL_DATA';

type Section = 'navigation' | 'legend' | 'audits' | 'feedback' | 'messages' | 'fleetInsights' | 'modalMapNavigation';

export type ControlBox = {
  width: number;
  height: number;
  isVisible: boolean;
};

type FeatureNamespace =
  | 'applicationModeChanges'
  | 'cityInsights'
  | 'cityAlerts'
  | 'containerInsights'
  | 'serviceExceptions'
  | 'streetSweeperTrackings'
  | 'snowRoadConditions'
  | 'vehicleInsights'
  | 'vehiclePositions'
  | 'vehicleTrackings'
  | 'vendorLocations'
  | 'route'
  | 'routeStop'
  | 'routeSegments';

type SelectedFeature = {
  namespace: FeatureNamespace;
  id: number;

  /**
   * For some of the features rendered on the map we will show
   * the popup right in the point the user clicked. This should be
   * useful in this case.
   *
   * Coordinates should be shaped as [longitude, latitude].
   */
  coordinates?: {
    lat: number;
    lng: number;
  };
  vehicleTrackingGroupIndex?: number;
  vehicleTrackingApplicationStatusIndex?: number;
  vehicleTrackingsSource?: 'route' | 'vehicle';
  vehicleInsightsSource?: 'geoFenceIncidents' | 'safetyIncidents';
  zoomToIt?: boolean;
  doNotShowPopup?: boolean;
  sourceId?: string;
};

/**
 * Initial state
 */
interface MapControlsState {
  /**
   * Space between controls in pixels.
   */
  spacing: number;
  navigation: ControlBox;
  modalMapNavigation: ControlBox;
  legend: ControlBox;
  audits: ControlBox;
  feedback: ControlBox;
  isSatelliteEnabled: boolean;
  isModalMapSatelliteEnabled: boolean;
  messages: ControlBox;
  fleetInsights: ControlBox;
  viewport: MapGLViewport;
  modalMapViewport?: MapGLViewport;
  sosAlertVehicleId?: number;
  selectedFeature?: SelectedFeature;
  selectedModalMapFeature?: SelectedFeature;
  mapModalData: {
    date: string | null;
    isModalMapOpen: boolean;
    vehicleId: number | null;
    isSourceRoute: boolean;
  };
}

const initialState: MapControlsState = {
  spacing: 20,
  navigation: {
    width: 0,
    height: 0,
    isVisible: true,
  },
  modalMapNavigation: {
    width: 0,
    height: 0,
    isVisible: true,
  },
  legend: {
    width: 0,
    height: 0,
    isVisible: true,
  },
  audits: {
    width: 0,
    height: 0,
    isVisible: true,
  },
  feedback: {
    width: 0,
    height: 0,
    isVisible: true,
  },
  isSatelliteEnabled: false,
  isModalMapSatelliteEnabled: false,
  messages: {
    width: 0,
    height: 0,
    isVisible: true,
  },
  fleetInsights: {
    width: 0,
    height: 0,
    isVisible: true,
  },
  viewport: {},
  modalMapViewport: {},
  mapModalData: {
    date: null,
    isModalMapOpen: false,
    vehicleId: null,
    isSourceRoute: false,
  },
};

/**
 * Reducer
 * @param state
 * @param action
 */
export const mapControlsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_BOX:
      return update(state, {
        [action.section as Section]: {
          $merge: action.box as ControlBox,
        },
      });

    case SET_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: {
            namespace: action.namespace,
            id: action.id,
            ...action.additionalFields,
          },
        },
      });

    case CLEAR_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: undefined,
        },
      });

    case SET_MODAL_MAP_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedModalMapFeature: {
            namespace: action.namespace,
            id: action.id,
            ...action.additionalFields,
          },
        },
      });

    case CLEAR_MODAL_MAP_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedModalMapFeature: undefined,
        },
      });

    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    case SET_MODAL_MAP_VIEWPORT:
      return update(state, {
        $merge: {
          modalMapViewport: action.viewport,
        },
      });

    case SET_SOS_ALERT_VEHICLE:
      return update(state, {
        $merge: {
          sosAlertVehicleId: action.vehicleId,
        },
      });

    case CLEAR_SOS_ALERT_VEHICLE:
      return update(state, {
        $merge: {
          sosAlertVehicleId: undefined,
        },
      });

    case SET_SATELLITE:
      //
      return update(state, {
        $merge: {
          isSatelliteEnabled: action.isSatelliteEnabled,
          viewport: {
            ...state.viewport,
            latitude: action.center ? action.center.lat : state.viewport.latitude,
            longitude: action.center ? action.center.lng : state.viewport.longitude,
            zoom: action.mapZoom || state.viewport.zoom,
          },
        },
      });

    case SET_MODAL_MAP_SATELLITE:
      return update(state, {
        $merge: {
          isModalMapSatelliteEnabled: action.isSatelliteEnabled,
        },
      });

    case SET_MAP_MODAL_DATA:
      return update(state, {
        $merge: {
          mapModalData: {
            date: action.date,
            isModalMapOpen: action.isModalMapOpen,
            vehicleId: action.vehicleId,
            isSourceRoute: action.isSourceRoute,
          },
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

/**
 * Action creators
 */
const setBox = (section: Section, box: Partial<ControlBox>) => ({
  type: SET_BOX,
  section,
  box,
});

export const setNavigationMapControl = (box: Partial<ControlBox>) => setBox('navigation', box);

export const setModalMapNavigationMapControl = (box: Partial<ControlBox>) => setBox('modalMapNavigation', box);

export const setLegendMapControl = (box: Partial<ControlBox>) => setBox('legend', box);

export const setAuditsMapControl = (box: Partial<ControlBox>) => setBox('audits', box);

export const setFeedbackMapControl = (box: Partial<ControlBox>) => setBox('feedback', box);

export const setMessagesMapControl = (box: Partial<ControlBox>) => setBox('messages', box);

export const setFleetInsightsMapControl = (box: Partial<ControlBox>) => setBox('fleetInsights', box);

export const setMapModalData = ({
  date,
  isModalMapOpen,
  vehicleId,
  isSourceRoute,
}: {
  date: string | null;
  isModalMapOpen: boolean;
  vehicleId: number | null;
  isSourceRoute: boolean;
}) => ({
  type: SET_MAP_MODAL_DATA,
  date,
  isModalMapOpen,
  vehicleId,
  isSourceRoute,
});

export const setDashboardViewport = (viewport: MapGLViewport) => ({
  type: SET_VIEWPORT,
  viewport,
});

export const setDashboardModalMapViewport = (viewport: MapGLViewport) => ({
  type: SET_MODAL_MAP_VIEWPORT,
  viewport,
});

export const setSatelliteMapControl = (isSatelliteEnabled: boolean, mapCenter?: LngLat, mapZoom?: number) => ({
  type: SET_SATELLITE,
  isSatelliteEnabled,
  mapCenter,
  mapZoom,
});

export const setModalMapSatelliteMapControl = (isSatelliteEnabled: boolean) => ({
  type: SET_MODAL_MAP_SATELLITE,
  isSatelliteEnabled,
});

export const setDashboardSelectedFeature = (
  namespace: FeatureNamespace,
  id: number,
  additionalFields: Partial<Omit<SelectedFeature, 'namespace' | 'id'>> = {},
) => ({
  type: SET_SELECTED_FEATURE,
  namespace,
  id,
  additionalFields,
});

export const clearDashboardSelectedFeature = () => ({
  type: CLEAR_SELECTED_FEATURE,
});

export const setDashboardModalMapSelectedFeature = (
  namespace: FeatureNamespace,
  id: number,
  additionalFields: Partial<Omit<SelectedFeature, 'namespace' | 'id'>> = {},
) => ({
  type: SET_MODAL_MAP_SELECTED_FEATURE,
  namespace,
  id,
  additionalFields,
});

export const clearDashboardModalMapSelectedFeature = () => ({
  type: CLEAR_MODAL_MAP_SELECTED_FEATURE,
});

export const setSOSAlertVehicle = (vehicleId: number) => {
  /**
   * TODO
   * After we will migrate to the new dashboard page,
   * this will not be required anymore. Compatibility
   * is required.
   */
  setPersistentSOSAlertVehicleId(vehicleId);

  return {
    type: SET_SOS_ALERT_VEHICLE,
    vehicleId,
  };
};

export const clearSOSAlertVehicle = () => {
  /**
   * TODO
   * After we will migrate to the new dashboard page,
   * this will not be required anymore. Compatibility
   * is required.
   */
  clearPersistentSOSAlertVehicleId();

  return {
    type: CLEAR_SOS_ALERT_VEHICLE,
  };
};

export const resetMapControls = () => ({
  type: RESET,
});
