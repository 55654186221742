import { contactByIdSelector } from '../../ducks';
import { Modal } from '../../../core/components';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector } from 'src/core/hooks/useSelector';
import ContactsEditorForm from '../forms/ContactsEditorForm';
import translate from '../../../core/services/translate';

export const contactsEditorModalId = 'contacts-editor-modal-id';

interface RouteParams {
  customerId?: string;
  locationId?: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  customerId: number;
  locationId?: number;
  contactId?: number;
  customerName: string;
  onSaveContact(...args: any[]): void;
  onCancel(pristine: boolean): void;
}

const ContactsEditorModal = ({
  onSaveContact,
  onCancel,
  customerName,
  customerId: propsCustomerId,
  locationId: propsLocationId,
  match: {
    params: { customerId: routeCustomerId, locationId: routeLocationId },
  },
  contactId,
}: Props) => {
  const isSaving = useSelector(state => state.customers.contacts.isSaving);
  const initialValues = useSelector(state => (contactByIdSelector as any)(state.customers.contacts, contactId));
  const customerId = propsCustomerId ? propsCustomerId : Number(routeCustomerId);
  const locationId = propsLocationId ? propsLocationId : Number(routeLocationId);

  return (
    <Modal
      padding="medium no no no"
      size="medium"
      isLoading={isSaving}
      title={translate(`common.contact.${contactId ? 'edit' : 'create'}Contact`)}
      subTitle={customerName}
      id={contactsEditorModalId}
      overflow={isSaving ? 'hidden' : 'auto'}
    >
      <ContactsEditorForm
        onSubmit={onSaveContact}
        onCancel={onCancel}
        customerId={customerId}
        locationId={locationId}
        contactId={contactId}
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default withRouter(ContactsEditorModal);
