import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { SortableElement } from 'react-sortable-hoc';

import { DELIVERY_UTILITY_ID, ROLL_OFF_ID } from 'src/fleet/constants';
import { SERVICE_TYPES_LIST } from 'src/common/constants/serviceTypes';
import translate from 'src/core/services/translate';
import { ACCOUNT_STATUSES, ROUTE_PICKUP_TYPE_IDS } from '../../../constants';
import { DragHandle } from '../../../../core/components';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import RouteSequenceStopDropZone from './RouteSequenceStopDropZone';
import { getMapBounds } from 'src/common/components/map/util';
import { setRouteMapViewport, setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';

interface Props {
  onRouteLocationDrop: () => void;
  openOrderNumberPopover: (newOrderNumber: number, event: MouseEvent, id: number) => void;
  newOrderNumber: number;
  vehicleTypeId: number;
  orderNo: number;
  customerName: string;
  locationName: string;
  street?: string;
  streetNumber?: string;
  id: number;
  pickupTypeId: number;
  accountTypeId: number;
  serviceTypeId: number;
  binLatitude: number;
  binLongitude: number;
}

const NewRouteSequenceEditorPageTableRow = ({
  newOrderNumber,
  onRouteLocationDrop,
  openOrderNumberPopover,
  orderNo,
  vehicleTypeId,
  customerName,
  id,
  locationName,
  street,
  streetNumber,
  pickupTypeId,
  accountTypeId,
  serviceTypeId,
  binLatitude,
  binLongitude,
}: Props) => {
  const dispatch = useDispatch();
  const tableCellWidths =
    vehicleTypeId === ROLL_OFF_ID || vehicleTypeId === DELIVERY_UTILITY_ID
      ? ['5%', '11%', '9%', '25%', '15%', '15%', '10%']
      : ['5%', '11%', '9%', '40%', undefined, '15%', '10%'];

  const handleClick = (event: any) => {
    const isTableRowClicked = event ? event.target.parentNode.tagName === 'DIV' : false;

    if (isTableRowClicked) {
      const point = { latitude: binLatitude, longitude: binLongitude };
      const bounds = getMapBounds([point]);

      dispatch(setRouteMapViewport(bounds));
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, id));
    }
  };

  return (
    <TableRow height={70} wrap={'wrap'} position={'relative'} onClick={handleClick}>
      <RouteSequenceStopDropZone
        position="top"
        routeLocationIndex={newOrderNumber - 1}
        onRouteLocationDrop={onRouteLocationDrop}
      />
      <TableCell width={tableCellWidths[0]}>
        <DragHandle />
      </TableCell>

      <TableCell width={tableCellWidths[1]}>
        <TableActionButton onClick={event => openOrderNumberPopover(newOrderNumber, event, id)}>
          {newOrderNumber}
        </TableActionButton>
      </TableCell>

      <TableCell width={tableCellWidths[2]}>
        <TableActionButton>{orderNo}</TableActionButton>
      </TableCell>

      <TableCell vertical width={tableCellWidths[3]}>
        <Text block weight="medium" margin="no no xxSmall" singleLine>
          {customerName}
        </Text>

        <Text weight="light" size="small" margin="no no xxSmall" singleLine>
          {locationName}, {streetNumber} {street}
        </Text>
      </TableCell>

      {(vehicleTypeId === ROLL_OFF_ID || vehicleTypeId === DELIVERY_UTILITY_ID) && (
        <TableCell width={tableCellWidths[4]}>
          {pickupTypeId && ROUTE_PICKUP_TYPE_IDS[pickupTypeId] && ROUTE_PICKUP_TYPE_IDS[pickupTypeId].name}
        </TableCell>
      )}
      <TableCell width={tableCellWidths[5]}>
        {(!!accountTypeId && ACCOUNT_STATUSES[accountTypeId].name) || '-'}
      </TableCell>
      <TableCell width={tableCellWidths[6]}>
        {SERVICE_TYPES_LIST.find(st => st.id === serviceTypeId)?.name || translate('common.serviceTypes.unknown')}
      </TableCell>
      <RouteSequenceStopDropZone
        position="bottom"
        routeLocationIndex={newOrderNumber}
        onRouteLocationDrop={onRouteLocationDrop}
      />
    </TableRow>
  );
};

export default SortableElement(NewRouteSequenceEditorPageTableRow);
