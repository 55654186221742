import styled, { css } from 'styled-components';
import { rgba } from 'polished';

interface RouteSequenceRowWrapperProps {
  expanded?: boolean;
}

export const RouteSequenceRowWrapper = styled.div<RouteSequenceRowWrapperProps>`
  border: 3px solid transparent;

  ${props =>
    props.expanded &&
    css`
      border-color: ${props.theme.brandPrimary};
      background-color: ${rgba(props.theme.brandPrimary, 0.06)};
    `}
`;

export const RouteSequenceArrow = styled.span`
  color: ${props => props.theme.brandPrimary};
`;

export const RouteSequenceFormWrapper = styled.div`
  width: 100%;
`;
