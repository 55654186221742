import styled from 'styled-components';

import Devices from 'src/core/styles/devices';

export const MaintenancePageMessageContainer = styled.div`
  padding-top: 100px;

  @media screen and (max-width: 1640px) {
    margin: 30px;
  }
  @media ${Devices.laptop} {
    margin: 30px;
  }
`;
