import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { TODAY } from '../../../core/constants';
import { DayOfServiceMultiSelect } from '../../../routes/components';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { DateRangePicker } from '../../../core/components';
import { ROUTE } from '../../../fleet/constants';
import { VehicleTypeForVendorDropdown } from '../../../fleet/components';
import { FLEET_INSIGHTS } from '../../constants';
import translate from '../../../core/services/translate';
import reportingDataTrendsInitialValuesSelector from '../../services/reportingDataTrendsInitialValuesSelector';
import { AppState } from '../../../store';
import { getExcludeVehicleFilters } from 'src/common/utils/filters';

interface ComponentProps extends RouteComponentProps {}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  reportCategory: string;
  reportType: string;
  excludeVehicleTypes?: string[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class ReportingDataTrendsForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, reportCategory, excludeVehicleTypes } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="dateRange"
                props={{ hasMarginLeft: 'smaller' }}
                component={DateRangePicker}
                disabledDays={[{ after: TODAY }]}
                margin="no"
              />
            </GridColumn>

            {reportCategory === FLEET_INSIGHTS && (
              <GridColumn size="3/12">
                <Field
                  name="vehicleTypeId"
                  component={VehicleTypeForVendorDropdown}
                  vehicleRoleTypeId={ROUTE}
                  excludeVehicleTypes={excludeVehicleTypes}
                  placeholder={translate('vehicles.vehicleType')}
                  dropdownProps={{ isClearable: true, margin: 'no' }}
                />
              </GridColumn>
            )}

            <GridColumn size="3/12">
              <Field name="daysOfOperation" component={DayOfServiceMultiSelect} multiSelectProps={{ margin: 'no' }} />
            </GridColumn>

            <GridColumn size="3/12" align="right">
              <Button color="primary">{translate('common.search')}</Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const {
    location: { search },
  } = ownProps;

  const initialValues = reportingDataTrendsInitialValuesSelector(search);
  const { reportCategory, reportType } = state.insights.reportingDataTrends.reportingDataTrends as any;
  const filtersPreferences = state.common.filters.filters;
  const excludeVehicleTypes = getExcludeVehicleFilters(filtersPreferences);
  return {
    initialValues,
    reportCategory,
    reportType,
    excludeVehicleTypes,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'reportingDataTrends',
      enableReinitialize: true,
    })(ReportingDataTrendsForm),
  ),
);
