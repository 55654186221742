import { identity, map, find, size, get, has } from 'lodash-es';
import { createSelector } from 'reselect';
import { EVERY_X_DAYS, EVERY_X_WEEKS, MONTHLY, X_TIMES_PER_WEEK, EVERY_1_MONTH } from '../../common/constants';
import { WEEKDAYS_BY_ID, TODAY } from '../../core/constants';
import { technicalNameByPickupFrequencyTypeIdSelector } from '../../common/ducks';
import { DaysOfService } from '../interfaces/Services';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Service } from '../interfaces/Services';

const weekdayOptions = map(WEEKDAYS_BY_ID);

const getDayOfServiceByFrequencyType = (service: Service, pickupFrequencyTypes: TechnicalType[]) => {
  const pickupFrequencyTypeName = (technicalNameByPickupFrequencyTypeIdSelector as any)(
    pickupFrequencyTypes,
    get(service, 'pickupFrequencyTypeId'),
  );

  switch (pickupFrequencyTypeName) {
    case EVERY_X_WEEKS:
      return !!service.dayOfServices && !!size(service.dayOfServices) ? service.dayOfServices[0].day : undefined;
    case MONTHLY:
    case X_TIMES_PER_WEEK:
    case EVERY_X_DAYS:
      return !!service.dayOfServices && !!size(service.dayOfServices) && size(service.dayOfServices) < 2
        ? service.dayOfServices[0].day
        : undefined;
    default:
      return undefined;
  }
};

const getDayOfServices = (dayOfServices?: DaysOfService[]) =>
  map(weekdayOptions, dayOfService => {
    const serviceDay = find(dayOfServices, { schedulerId: dayOfService.id });
    return {
      ...dayOfService,
      ...serviceDay,
      schedulerId: dayOfService.id,
      checked: !!serviceDay,
    };
  });

const getService = (service: Service, pickupFrequencyTypes: TechnicalType[], isBillingFeatureActive?: boolean) => ({
  ...service,
  dayOfService: getDayOfServiceByFrequencyType(service, pickupFrequencyTypes),
  dayOfServices: getDayOfServices(get(service, 'dayOfServices')),
  effectiveDate: has(service, 'effectiveDate') ? get(service, 'effectiveDate') : TODAY,
  monthlyServicesYearlyFrequencyId: has(service, 'monthlyServicesYearlyFrequencyId')
    ? get(service, 'monthlyServicesYearlyFrequencyId') || EVERY_1_MONTH
    : EVERY_1_MONTH,
  numberOfContainers: has(service, 'numberOfContainers') ? get(service, 'numberOfContainers') : 1,
  serviceContractAccountStatusTypeId: has(service, 'id') ? get(service, 'serviceContractAccountStatusTypeId') : 1,
  shouldRecreateRoutes: false,
  monthlyServicesMonthlyFrequencyIds: isBillingFeatureActive
    ? !!(service?.monthlyServicesMonthlyFrequencyIds as number[])?.length
      ? (service?.monthlyServicesMonthlyFrequencyIds as number[])[0]
      : undefined
    : service?.monthlyServicesMonthlyFrequencyIds,
});

const serviceDetailsEditorFormInitialValuesSelector = createSelector(getService, identity);

export default serviceDetailsEditorFormInitialValuesSelector;
