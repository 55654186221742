import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from 'src/core/components/styled/Box';
import { CITY_ALERT_TYPES } from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { getIconTypeForLocationAndCityAlert } from 'src/fleet/components/pages/locationAndCityAlertsSections/utils';
import { LocationAndCityAlert } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { MapGLPopup, MapGLPopupContent, MapGLPopupTitle } from 'src/common/components/map/MapGLPopup';
import { MapInfoImage, MapInfoImageWrapper } from 'src/core/components/styled';
import { ModalImage } from 'src/routes/interfaces/RouteImages';
import { resetCityAlertImages } from 'src/vendors/ducks';
import { RouteImagesModal } from 'src/routes/components/modals';
import { useSelector } from 'src/core/hooks/useSelector';
import CityAlertsGLPopupContent from 'src/routes/components/pages/routes/routePageSections/routeMap/cityAlerts/CityAlertsGLPopupContent';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  cityAlert: CityAlert | LocationAndCityAlert;
}

const DashboardCityAlertsGLPopup: FC<Props> = ({ cityAlert }) => {
  const dispatch = useDispatch();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const images = useSelector(state => state.vendors.cityAlerts.images);

  const cityAlertName = cityAlert.cityAlertTypeId && CITY_ALERT_TYPES[cityAlert.cityAlertTypeId].name;

  const modalImages: ModalImage[] = images.map(image => ({
    imageUrl: image.url,
    rawImageUrl: image.rawUrl,
    timeStamp: image.timeStamp || '',
    imageBlobId: image.blobId,
    routeName: cityAlertName,
    locationName: cityAlert.address,
  }));

  // clean up
  useEffect(
    () => () => {
      dispatch(resetCityAlertImages());
    },
    [dispatch],
  );

  return (
    <>
      <MapGLPopup width={250}>
        <MapGLPopupTitle
          beforeTitleContent={
            <Box mr={8} width={25} height={25}>
              {cityAlert && (
                <img
                  src={getIconTypeForLocationAndCityAlert(cityAlert as LocationAndCityAlert)?.iconUrl}
                  width="25"
                  height="25"
                  alt=""
                />
              )}
            </Box>
          }
          title={cityAlertName}
        />

        <MapGLPopupContent>
          <CityAlertsGLPopupContent alert={cityAlert} isCityAlert={true} />

          {!!images.length && (
            <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
              <Box mt={5}>
                <MapInfoImageWrapper
                  width="40%"
                  overlay={images.length > 1 ? images.length : undefined}
                  onClick={() => setIsImageModalOpen(true)}
                  noStyling
                >
                  <MapInfoImage src={images[0].url} />
                </MapInfoImageWrapper>
              </Box>
            </LabeledDataView>
          )}
        </MapGLPopupContent>
      </MapGLPopup>

      {!!isImageModalOpen && <RouteImagesModal images={modalImages} closeModal={() => setIsImageModalOpen(false)} />}
    </>
  );
};

export default DashboardCityAlertsGLPopup;
