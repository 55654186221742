import { get } from 'lodash-es';
import { useDispatch } from 'react-redux';
import React, { useMemo } from 'react';

import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { loadRouteLocationImages, loadRouteLocationIssues } from 'src/routes/ducks';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { RouteStopItem } from 'src/dashboard/interfaces/routesData';
import { SCHEDULED } from 'src/routes/constants';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import DashboardRouteStopGLPopup from './DashboardRouteStopGLPopup';

const Loading: React.FC<{ latitude: number; longitude: number }> = ({ latitude, longitude }) => {
  const dispatch = useDispatch();

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={latitude}
      longitude={longitude}
      onClose={() => dispatch(clearDashboardSelectedFeature())}
    >
      <MapGLPopupLoading />
    </MapGLPopupWrapper>
  );
};

const DashboardRouteStopGLPopupResolver: React.FC = () => {
  const dispatch = useDispatch();

  const routeStops = useSelector(
    state => ((state.dashboard.routesData?.mapRouteStops as any) || []) as RouteStopItem[],
  );
  const routeData = useSelector(state => state.dashboard.routesData);

  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);

  const selectedStop = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'routeStop') {
      return undefined;
    }
    return routeStops.find(stop => stop.id === selectedFeature.id);
  }, [selectedFeature, routeStops]);

  if (!selectedStop) {
    return null;
  }

  const loadDependencies = () => {
    if (!routeData?.routeInformation?.id) {
      return Promise.reject();
    }

    const dependencies = [
      loadRouteLocationImages(routeData.routeInformation.id, selectedStop.id)(dispatch),
      loadRouteLocationIssues(routeData.routeInformation.id, selectedStop.id)(dispatch),
    ];

    return Promise.all<any>(dependencies);
  };

  const shouldLoadClosestStrobeImage =
    routeData?.routeInformation?.statusType.id !== SCHEDULED && !!get(routeData?.routeDriversVehicles, 0);

  return (
    <TypedResolver
      key={selectedStop.id}
      successComponent={DashboardRouteStopGLPopup}
      successProps={{ routeStop: selectedStop, shouldLoadClosestStrobeImage }}
      loadingComponent={Loading}
      loadingProps={{ latitude: selectedStop.displayLatitude, longitude: selectedStop.displayLongitude }}
      resolve={loadDependencies}
    />
  );
};

export default DashboardRouteStopGLPopupResolver;
