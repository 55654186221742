import { http } from '../../core/services/http';
import { transformLoadVehicleTypesForVendor } from '../../fleet/services/transformLoadVehicleTypes';

export const loadVehicleTypesForPickupExceptions = (vendorId: number) =>
  http
    .get(`/vendors/${vendorId}/vehicleTypesForPickupExceptions`)
    .then(response => transformLoadVehicleTypesForVendor(response.data));

export const loadVehicleTypesForExceptionManagement = (vendorId: number) =>
  http
    .get(`/vendors/${vendorId}/vehicleTypesForExceptionManagement`)
    .then(response => transformLoadVehicleTypesForVendor(response.data));
