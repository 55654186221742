import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  MessagingHeaderContainer,
  MessagingHeader,
  MessagingContentContainer,
  MessagingActions,
  MessageListContainer,
} from '../styles/MessagingContainer';
import { Button } from 'src/core/components/styled';
import { closeThread, loadThreadMessages, readMessage, sendMessageToExistingThread } from '../ducks/messaging';
import { MessageInput } from './MessageInput';
import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { MessageItem } from './MessageItem';
import translate from 'src/core/services/translate';

export const MessagingActiveThread = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);
  const activeThread = useSelector(s => s.messaging.activeThread!);
  const isLoadingMessages = useSelector(s => s.messaging.isLoadingMessages);
  const messages = useSelector(s => s.messaging.activeThreadMessages);

  const [failedMessageIds, setFailedMessageIds] = useState<{ [key: number]: boolean }>({});

  const sendTextMessage = (messageText: string, messageId?: number) => {
    if (messageId) {
      failedMessageIds[messageId] = false;
      setFailedMessageIds({ ...failedMessageIds });
    }
    const { temporaryMessageId, promise } = sendMessageToExistingThread(
      {
        vendorId,
        messageTypeId: activeThread.messageTypeId,
        messageText,
        threadId: activeThread.threadId,
        toDriverId: activeThread.driverId,
        routeId: activeThread.routeId,
        vehicleTypeId: activeThread.vehicleTypeId,
      },
      messageId,
    )(dispatch);
    promise.catch(() => {
      setFailedMessageIds({ ...failedMessageIds, [temporaryMessageId]: true });
    });
  };

  const goToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    loadThreadMessages(vendorId, activeThread.threadId)(dispatch);
  }, [dispatch, vendorId, activeThread]);

  const messageListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageListRef.current) {
      goToBottom();
    }
  }, [messages, failedMessageIds]);

  useEffect(() => {
    const lastUnreadMessage = messages.filter(message => !message.isMessageRead && !message.fromUserId).slice(-1)[0];

    if (lastUnreadMessage) readMessage(lastUnreadMessage)(dispatch);
  }, [messages, userId, dispatch]);

  return (
    <>
      <MessagingHeaderContainer>
        <MessagingHeader>{activeThread.threadName}</MessagingHeader>

        <Button margin="xSmall no xSmall auto" onClick={() => closeThread()(dispatch)} text color="warning">
          {translate('messaging.close')}
        </Button>
      </MessagingHeaderContainer>

      <MessagingContentContainer ref={messageListRef} isLoading={isLoadingMessages}>
        <MessageListContainer>
          {messages.map(m => (
            <MessageItem
              onRetry={
                failedMessageIds[m.messageId]
                  ? message => sendTextMessage(message.messageText!, message.messageId!)
                  : undefined
              }
              key={m.messageId}
              message={m}
            />
          ))}
        </MessageListContainer>
      </MessagingContentContainer>

      <MessagingActions>
        {activeThread.isReadOnly ? (
          <div>{translate('messaging.cannotReply')}</div>
        ) : (
          <MessageInput disabledSendButton={isLoadingMessages} onSendMessage={sendTextMessage} />
        )}
      </MessagingActions>
    </>
  );
};
