import React from 'react';

import { HaulerProfileGrid, HaulerProfileGridColumn } from './styled';
import translate from '../../core/services/translate';

const HaulerProfileInvalidGusIdMessage = () => (
  <HaulerProfileGrid>
    <HaulerProfileGridColumn lastColumn padding="sMedium" width="100%">
      {translate('common.alertMessages.invalidGusId')}
    </HaulerProfileGridColumn>
  </HaulerProfileGrid>
);

export default HaulerProfileInvalidGusIdMessage;
