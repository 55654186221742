import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../../../store';
import { currentVendorIdSelector } from '../../../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../../../contracts/ducks';
import { loadDisposalSiteLocations, loadWasteTypes } from '../../../../../common/ducks';
import { loadVehicleTypesForVendor } from '../../../../../fleet/ducks';
import { loadWeightTickets } from '../../../../ducks';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { PanelSectionLoading } from '../../../../../core/components/styled';
import { TypedResolver } from '../../../../../core/components';
import { WeightTicketsModal } from '../../../../../customers/components/modals';
import WeightTickets from './WeightTickets';

interface WeightTicketsResolverProps {
  closeModal?: () => void;
  isDisposalSectionHidden?: boolean;
  isModal?: boolean;
  loadDisposalSiteLocations: DuckFunction<typeof loadDisposalSiteLocations>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  loadWeightTickets: DuckFunction<typeof loadWeightTickets>;
  modalSubTitle?: string;
  modalTitle?: string;
  onLoadTab?: () => void;
  routeId: number;
  routeLocationId?: number;
  siteAddress?: string;
  vendorId: number;
}

class WeightTicketsResolver extends PureComponent<WeightTicketsResolverProps> {
  loadDependencies = () => {
    const {
      loadDisposalSiteLocations,
      loadVehicleTypesForVendor,
      loadWasteTypes,
      loadWeightTickets,
      routeId,
      routeLocationId,
      vendorId,
    } = this.props;
    return Promise.all([
      loadDisposalSiteLocations(vendorId),
      loadVehicleTypesForVendor(vendorId),
      loadWasteTypes(),
      loadWeightTickets(routeId, routeLocationId),
    ]);
  };

  render() {
    const {
      closeModal = () => {},
      isDisposalSectionHidden,
      isModal,
      modalSubTitle,
      modalTitle,
      onLoadTab,
      siteAddress,
    } = this.props;

    return (
      <TypedResolver
        loadingComponent={isModal ? PageLoadingOverlay : PanelSectionLoading}
        resolve={this.loadDependencies}
        successComponent={isModal ? WeightTicketsModal : WeightTickets}
        successProps={{
          modalTitle,
          modalSubTitle,
          siteAddress,
          closeModal,
          onLoadTab,
          isDisposalSectionHidden,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadWeightTickets, loadDisposalSiteLocations, loadVehicleTypesForVendor, loadWasteTypes };

export default connect(mapStateToProps, mapDispatchToProps)(WeightTicketsResolver);
