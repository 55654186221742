import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import promisePrototypeFinallyShim from 'promise.prototype.finally';
import 'mdn-polyfills/String.prototype.startsWith';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';

import { locale } from './translate';

const initialize = () => {
  momentDurationFormatSetup(moment);
  promisePrototypeFinallyShim.shim();
  moment.locale(locale);
};

export default initialize;
