import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Facility } from 'src/common/interfaces/Facility';
import { FACILITIES_LAYER, FACILITY_SOURCE } from 'src/fleet/constants/facilityStatus';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import FacilitiesPopupResolver from '../containersPageSections/containersMap/FacilitiesPopupResolver';
import FacilityGLLayer from './FacilityGLLayer';

type Props = {
  map: mapboxgl.Map;
  facilities: Facility[];
};

export default function FacilitiesGL({ map, facilities }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === FACILITY_SOURCE && feature.properties?.layer === FACILITIES_LAYER);

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.facility, feature.properties?.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <FacilityGLLayer />
      <FacilitiesPopupResolver facilities={facilities} />
    </>
  );
}
