import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import { omit } from 'lodash-es';

import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { loadRateAccountingCodes, loadRateCodes, saveRateAccountingCode, saveRateCode } from 'src/finance/ducks';
import { Modal } from 'src/core/components';
import { NEW_RATE_ACCOUNTING_CODE_ID } from 'src/finance/constants';
import { RateConfigurationAddRateForm } from '../forms';
import { RateConfigurationAddRateFormValues } from '../forms/RateConfigurationAddRateForm';
import {
  RateConfigurationSearchFormValues,
  RATE_CONFIURATION_SEARCH_FORM_NAME,
} from '../forms/RateConfigurationSearchForm';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

const addRateCodeModalId = 'add-rate-code=modal-id';

interface Props {
  onCancel(): void;
}

const RateConfigurationAddRateModal = ({ onCancel }: Props) => {
  const dispatch = useDispatch();

  const { isSaving } = useSelector(state => state.finance.rateManager);
  const formValues: RateConfigurationSearchFormValues = useSelector(state =>
    getFormValues(RATE_CONFIURATION_SEARCH_FORM_NAME)(state),
  );

  const initialValues = {
    enabledForServiceTypeIds: formValues?.serviceTypeId ? [formValues?.serviceTypeId] : [],
  };

  const onSubmit = (formData: RateConfigurationAddRateFormValues) => {
    const saveRate = (data: RateConfigurationAddRateFormValues) => {
      saveRateCode(omit(data, ['rateAccountingCode', 'rateAccountingCodeDescription']))(dispatch)
        .then(() => {
          createSuccessNotification(translate('finance.rateManager.alertMessages.rateCodeSaved'));
          onCancel();

          loadRateCodes()(dispatch);
          loadRateAccountingCodes()(dispatch);
        })
        .catch(error => {
          const { code } = error.response.data;

          switch (code) {
            case 'RateCodeNotUnique':
              createErrorNotification(translate('finance.rateManager.alertMessages.rateCodeAddNotUnique'));
              break;

            default:
              createErrorNotification(translate('finance.rateManager.alertMessages.rateCodeSaveError'));
              break;
          }
        });
    };

    if (formData.rateAccountingCodeId === NEW_RATE_ACCOUNTING_CODE_ID) {
      saveRateAccountingCode({
        description: formData.rateAccountingCodeDescription,
        code: formData.rateAccountingCode,
      })(dispatch)
        .then(id => {
          const newFormData = { ...formData, rateAccountingCodeId: id };
          saveRate(newFormData);
        })
        .catch(error => {
          const { code } = error.response.data;

          switch (code) {
            case 'RateAccountingCodeAlreadyExists':
              createErrorNotification(translate('finance.rateManager.alertMessages.rateAccountingCodeAlreadyExists'));
              break;

            default:
              createErrorNotification(translate('finance.rateManager.alertMessages.rateCodeSaveError'));
              break;
          }
        });
    } else {
      saveRate(formData);
    }
  };

  return (
    <Modal
      padding="medium no no no"
      size="medium"
      isLoading={isSaving}
      title={translate('finance.rateManager.addRateCode')}
      onClose={onCancel}
      id={addRateCodeModalId}
      overflow={isSaving ? 'hidden' : 'visible'}
    >
      <RateConfigurationAddRateForm onCancel={onCancel} initialValues={initialValues} onSubmit={onSubmit} />
    </Modal>
  );
};

export default RateConfigurationAddRateModal;
