import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { BulkDeclineAwardsObject, BulkAcceptAwardsObject } from '../interfaces/Opportunities';
import { EventServiceNotes, OpportunityRow } from '../interfaces/Opportunities';
import {
  loadOpportunityByUserId as doLoadOpportunityByUserId,
  updateBiddingOpportunity as doUpdateBiddingOpportunity,
  updateBulkAwards as doUpdateBulkAwards,
  getSourcingEventNotes as doGetSourcingNotes,
  getMarketSubTypes as doLoadMarketSubTypes,
} from '../services/opportunities';

//  Actions
const START_LOAD = 'opportunities/START_LOAD';
export const COMPLETE_LOAD = 'opportunities/COMPLATE_LOAD';
const FAIL_LOAD = 'opportunities/FAIL_LOAD';
const START_LOAD_NOTES = 'opportunities/START_LOAD_NOTES';
export const COMPLETE_LOAD_NOTES = 'opportunities/COMPLATE_LOAD_NOTES';
const FAIL_LOAD_NOTES = 'opportunities/FAIL_LOAD_NOTES';
const START_LOAD_MARKET_SUBTYPES = 'opportunities/START_LOAD_MARKET_SUBTYPES';
export const COMPLETE_LOAD_MARKET_SUBTYPES = 'opportunities/COMPLATE_LOAD_MARKET_SUBTYPES';
const FAIL_LOAD_MARKET_SUBTYPES = 'opportunities/FAIL_LOAD_MARKET_SUBTYPES';
const FAIL_SAVE = 'opportunities/SAVE_FAIL';
const START_SAVE = 'opportunities/START_SAVE';
const COMPLETE_SAVE = 'opportunities/COMPLETE_SAVE';

//  Initial State
interface OpportunitiesState {
  isAutoRefresh: boolean;
  isLoading: boolean;
  isLoadingMarketSubTypes: boolean;
  isLoadingNotes: boolean;
  isSaving: boolean;
  opportunities: OpportunityRow[];
  divisions: any[];
  equipmentTypes: any[];
  materialTypes: any[];
  notes: EventServiceNotes[];
  paymentInfo: any;
  marketSubTypes: any[];
}

const initialState: OpportunitiesState = {
  isAutoRefresh: false,
  isLoading: false,
  isLoadingMarketSubTypes: false,
  isLoadingNotes: false,
  isSaving: false,
  opportunities: [],
  divisions: [],
  equipmentTypes: [],
  materialTypes: [],
  marketSubTypes: [],
  notes: [],
  paymentInfo: {},
};

//  Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isAutoRefresh: action.opportunities.isAutoRefresh,
          isLoading: false,
          opportunities: action.opportunities.opportunities,
          paymentInfo: action.opportunities.paymentInfo,
          divisions: action.opportunities.divisions,
          equipmentTypes: action.opportunities.equipmentSubTypes,
          materialTypes: action.opportunities.materialTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isAutoRefresh: false,
          isLoading: false,
          opportunities: [],
          divisions: [],
          equipmentTypes: [],
          materialTypes: [],
          paymentInfo: {},
        },
      });

    case START_LOAD_NOTES:
      return update(state, {
        $merge: {
          isLoadingNotes: true,
        },
      });

    case COMPLETE_LOAD_NOTES:
      return update(state, {
        $merge: {
          isLoadingNotes: false,
          notes: action.notes,
        },
      });

    case FAIL_LOAD_NOTES:
      return update(state, {
        $merge: {
          isLoadingNotes: false,
          notes: [],
        },
      });

    case START_LOAD_MARKET_SUBTYPES:
      return update(state, {
        $merge: {
          isLoadingMarketSubTypes: true,
        },
      });

    case COMPLETE_LOAD_MARKET_SUBTYPES:
      return update(state, {
        $merge: {
          isLoadingMarketSubTypes: false,
          marketSubTypes: action.marketSubTypes,
        },
      });

    case FAIL_LOAD_MARKET_SUBTYPES:
      return update(state, {
        $merge: {
          isLoadingMarketSubTypes: false,
          marketSubTypes: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (opportunities: any) => ({
  type: COMPLETE_LOAD,
  opportunities,
});

// unused
// const failLoad = () => ({
//   type: FAIL_LOAD,
// });

const startLoadNotes = () => ({
  type: START_LOAD_NOTES,
});

const completeLoadNotes = (notes: EventServiceNotes[]) => ({
  type: COMPLETE_LOAD_NOTES,
  notes,
});

const failLoadNotes = () => ({
  type: FAIL_LOAD_NOTES,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (bidOpportunity: any) => ({
  type: COMPLETE_SAVE,
  bidOpportunity,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadMarketSubTypes = () => ({
  type: START_LOAD_MARKET_SUBTYPES,
});

const completeLoadMarketSubTypes = (marketSubTypes: any) => ({
  type: COMPLETE_LOAD_MARKET_SUBTYPES,
  marketSubTypes,
});

const failLoadMarketSubTypes = () => ({
  type: FAIL_LOAD_MARKET_SUBTYPES,
});

export const loadOpportunities =
  (
    userId: string | number | undefined,
    vendorId: number,
    isActionRequired: boolean,
    startDate: string,
    endDate: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadOpportunityByUserId(userId, vendorId, isActionRequired, startDate, endDate)
      .then(opportunity => dispatch(completeLoad(opportunity)))
      .catch(() => dispatch(push('/dashboard')));
  };

export const updateSourcingBid = (biddingOpportunity: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  return doUpdateBiddingOpportunity(biddingOpportunity)
    .then(biddingOpportunity => dispatch(completeSave(biddingOpportunity)))
    .catch(() => dispatch(failSave()));
};

export const updateBulkAwards =
  (
    bulkAwardsObj: BulkDeclineAwardsObject | BulkAcceptAwardsObject,
    userId: string | undefined,
    vendorId: number,
    isActionRequired?: boolean,
    startDate?: string,
    endDate?: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    return doUpdateBulkAwards(bulkAwardsObj, userId, vendorId, isActionRequired, startDate, endDate)
      .then(bulkAwardsObj => dispatch(completeSave(bulkAwardsObj)))
      .catch(() => dispatch(failSave()));
  };

export const getSourcingNotes = (itemId: number, eventServiceId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadNotes());
  return doGetSourcingNotes(itemId, eventServiceId)
    .then(notes => dispatch(completeLoadNotes(notes)))
    .catch(() => dispatch(failLoadNotes()));
};

export const loadMarketSubTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadMarketSubTypes());
  return doLoadMarketSubTypes()
    .then(marketSubTypes => dispatch(completeLoadMarketSubTypes(marketSubTypes)))
    .catch(() => dispatch(failLoadMarketSubTypes()));
};
