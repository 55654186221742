import { camelCase } from 'lodash-es';
import { change, getFormValues, submit } from 'redux-form';
import { debounce, isEqual } from 'lodash-es';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Grid, GridColumn, Message, PanelSection, Popover, Text } from 'src/core/components/styled';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getInitialPickupTypeId, getRouteStopsTableCells } from './utils';
import {
  loadRouteStops,
  loadRouteSummary,
  loadTravelPathStatusDetails,
  resetIsLoaded,
  setJobPriorityTypeIds,
  setPickupStatusIds,
  setSearchTerm,
  updateRouteStops,
} from 'src/routes/ducks';
import { multiWordAndSearch } from 'src/core/services/search';
import { NavigationPrompt, PopoverWrapper, Table } from 'src/core/components';
import { OPTIMIZED_JOB_POSITION_ID } from 'src/core/constants/jobPositionOptions';
import { PinnedStop, RouteStop } from 'src/routes/interfaces/RouteStop';
import { RouteMapFiltersFormValues, ROUTE_MAP_FILTERS_FORM_NAME } from '../routeMap/RouteMapFiltersForm';
import { clearRouteMapSelectedFeature, setIsDrawingMode } from 'src/routes/ducks/mapControls';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { UNKNOWN_ID } from 'src/common/constants/wasteTypes';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import RouteStopsForm, { RouteStopsFormValues, ROUTE_STOPS_FORM_NAME } from './RouteStopsForm';
import RouteStopsSearchForm, {
  ROUTE_STOPS_PICKUP_STATUS_FIELD,
  ROUTE_STOPS_SEARCH,
} from 'src/routes/components/forms/RouteStopsSearchForm';
import RouteStopsTableRow from './RouteStopsTableRow';
import translate from 'src/core/services/translate';
import { SCHEDULED } from 'src/routes/constants';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';

export const filterStopsPredicate =
  (
    showStopsWithLocationAlerts: boolean,
    showTemporaryStops: boolean,
    pickupStatusIds?: number[],
    jobPriorityIds?: number[],
    searchTerm?: string,
  ) =>
  (routeStop: RouteStop) =>
    ((showStopsWithLocationAlerts
      ? !!routeStop.vendorLocationAlertIds?.length === showStopsWithLocationAlerts
      : true) ||
      routeStop.isNew) &&
    ((showTemporaryStops ? routeStop.isTemporaryContainer === showTemporaryStops : true) || routeStop.isNew) &&
    (!pickupStatusIds || !pickupStatusIds.length || pickupStatusIds.includes(routeStop.pickupStatusTypeId)) &&
    (!jobPriorityIds || !jobPriorityIds.length || jobPriorityIds.includes(routeStop.jobPriorityTypeId || 0)) &&
    (!searchTerm ||
      multiWordAndSearch(routeStop.customerName, searchTerm) ||
      multiWordAndSearch(routeStop.locationName, searchTerm) ||
      multiWordAndSearch(`${routeStop.locationName}, ${routeStop.streetNumber} ${routeStop.street}`, searchTerm) ||
      multiWordAndSearch(routeStop.accountNumber, searchTerm) ||
      multiWordAndSearch(routeStop.vendorAccountNo, searchTerm));

type Props = {
  isEditMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
};

export default function RouteStops({ isEditMode, setEditMode }: Props) {
  const normalizeFilterArray = (array: boolean[]) =>
    array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const routeStopsFormValues = useSelector(getFormValues(ROUTE_STOPS_FORM_NAME)) as RouteStopsFormValues | undefined;
  const mapFiltersFormValues = useSelector(getFormValues(ROUTE_MAP_FILTERS_FORM_NAME)) as RouteMapFiltersFormValues;
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { routeStops, isLoading: isRouteStopsLoading, isSaving } = useSelector(state => state.routes.routeStops);
  const { pickupStatusIds, jobPriorityTypeIds, searchTerm, showStopsWithLocationAlerts, showTemporaryStops } =
    useSelector(state => state.routes.filters);
  const { travelPathStatusDetails } = useSelector(state => state.routes.travelPath);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  const [deletedStops, setDeletedStops] = useState<number[]>([]);
  const [transferredStops, setTransferredStops] = useState<boolean>(false);
  const [optimizationCanceled, setOptimizationCanceled] = useState<boolean>(false);

  const isRouteStopsFormDirty =
    routeStopsFormValues?.routeStops.some(routeStop => routeStop.isChanged || routeStop.isNew || routeStop.isPinned) ||
    !!deletedStops.length ||
    transferredStops ||
    optimizationCanceled;

  const filteredStops = useMemo(() => {
    dispatch(clearRouteMapSelectedFeature());
    return routeStops.filter(
      filterStopsPredicate(
        showStopsWithLocationAlerts,
        showTemporaryStops,
        pickupStatusIds,
        jobPriorityTypeIds,
        searchTerm,
      ),
    );
  }, [
    dispatch,
    routeStops,
    showStopsWithLocationAlerts,
    showTemporaryStops,
    pickupStatusIds,
    jobPriorityTypeIds,
    searchTerm,
  ]);

  const routeStopsWithZeroStopNumber = filteredStops.filter(stop => stop.orderNo === 0);
  const optimizedRouteStops = filteredStops.filter(stop => stop.orderNo === -1);
  const routeStopsWithStopNumber = filteredStops.filter(stop => stop.orderNo > 0);

  if (!routeSummary) return null;

  const onSearchTermChange = debounce(searchTerm => {
    dispatch(setSearchTerm(searchTerm));
  }, 200);

  const onPickupStatusChange = (pickupStatusIds: number[]) => {
    dispatch(setPickupStatusIds(pickupStatusIds));
  };

  const onJobPriorityChange = (priorityTypeIds: number[]) => {
    dispatch(setJobPriorityTypeIds(priorityTypeIds));
  };

  const handleSaveStops = (formData: any) => {
    let stopOrderNo = 0;
    const routeStops = formData.routeStops.map((stop: RouteStop) => {
      const isOptimized = stop.positionTypeId === OPTIMIZED_JOB_POSITION_ID;
      if (!isOptimized) stopOrderNo++;
      return {
        ...stop,
        orderNo: isOptimized ? -1 : stopOrderNo,
      };
    });

    const newStops = routeStops
      .filter((routeStop: any) => routeStop.isNew)
      .map((routeStop: RouteStop) => ({
        accountTypeId: routeStop.accountTypeId,
        jobPriorityTypeId: routeStop.jobPriorityTypeId,
        locationId: routeStop.locationId,
        orderNo: routeStop.orderNo,
        pickupStatusTypeId: routeStop.pickupStatusTypeId,
        pickupTypeId: routeStop.pickupTypeId,
        wasteMaterialTypeId: routeStop.wasteMaterialTypeId,
        reasonCodeTypeId: routeStop.reasonCodeTypeId || null,
        serviceContractId: routeStop.serviceContractId,
        positionTypeId: routeStop.positionTypeId,

        latitude: routeStop.isUpdatedLatLng ? routeStop.binLatitude : undefined,
        longitude: routeStop.isUpdatedLatLng ? routeStop.binLongitude : undefined,
      }));

    const pinnedStops = routeStops
      .filter((routeStop: any) => routeStop.isPinned && !isNaN(routeStop.id))
      .map((pinnedStop: PinnedStop) => ({
        binLatitude: pinnedStop.binLatitude,
        binLongitude: pinnedStop.binLongitude,
        city: pinnedStop.city,
        country: pinnedStop.country,
        countryId: pinnedStop.countryId,
        jobPriorityTypeId: pinnedStop.jobPriorityTypeId,
        latitude: pinnedStop.latitude,
        line1: pinnedStop.line1,
        line2: pinnedStop.line2,
        longitude: pinnedStop.longitude,
        pickupStatusTypeId: pinnedStop.pickupStatusTypeId,
        pickupTypeId: pinnedStop.pickupTypeId,
        reasonCodeTypeId: pinnedStop.reasonCodeTypeId || null,
        routeLocationAccountTypeId: pinnedStop.accountTypeId,
        state: pinnedStop.state,
        stateId: pinnedStop.stateId,
        street: pinnedStop.street,
        streetNumber: pinnedStop.streetNumber,
        wasteMaterialTypeId: pinnedStop.wasteMaterialTypeId,
        zip: pinnedStop.zip,
        positionTypeId: pinnedStop.positionTypeId,
        orderNo: pinnedStop.orderNo,
      }));

    const changedStops = routeStops
      .filter((routeStop: any) => routeStop.id && !routeStop.isPinned && !isNaN(routeStop.id))
      .map((routeStop: any) => ({
        accountTypeId: routeStop.accountTypeId,
        orderNo: routeStop.orderNo,
        pickupTypeId: routeStop.pickupTypeId,
        jobPriorityTypeId: routeStop.jobPriorityTypeId,
        reasonCodeTypeId: routeStop.reasonCodeTypeId || null,
        routeLocationId: routeStop.id,
        positionTypeId: routeStop.positionTypeId,
        latitude: routeStop.isUpdatedLatLng ? routeStop.binLatitude : undefined,
        longitude: routeStop.isUpdatedLatLng ? routeStop.binLongitude : undefined,
      }));

    const updatedRouteStops = {
      changedStops,
      newStops,
      pinnedStops: pinnedStops,
      deletedStops,
    };

    updateRouteStops(
      vendorId,
      routeSummary.routeId,
      updatedRouteStops,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.stopsSaved'));
        setEditMode(false);
        dispatch(setIsDrawingMode(false));
        setDeletedStops([]);
        setTransferredStops(false);
        setOptimizationCanceled(false);
        loadRouteStops(
          vendorId,
          routeSummary.routeId,
          normalizeFilterArray(mapFiltersFormValues.pickupStatusFilters),
        )(dispatch);
        loadRouteSummary(vendorId, routeSummary.routeId)(dispatch);
        if (routeSummary.totalStopsCount >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled)) {
          loadTravelPathStatusDetails(routeSummary.routeId)(dispatch);
        }
      })
      .catch(error => {
        switch (error?.response?.data?.code) {
          case 'StopOptimizationNotAllowedOnARouteInProgress':
            createErrorNotification(`${translate(`routes.alertMessages.${camelCase(error.response.data.code)}`)}`);
            break;

          default:
            createErrorNotification(`${translate('routes.alertMessages.stopsSaveError')}`);
            break;
        }
      });
  };

  const handleExitEditMode = async () => {
    if (!filteredStops.length) {
      dispatch(resetIsLoaded());
    }
    if (isRouteStopsFormDirty)
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    setEditMode(false);
    setDeletedStops([]);
    setTransferredStops(false);
    dispatch(setIsDrawingMode(false));

    const pickupStatusFilters = normalizeFilterArray(mapFiltersFormValues.pickupStatusFilters);
    dispatch(change(ROUTE_STOPS_SEARCH, ROUTE_STOPS_PICKUP_STATUS_FIELD, pickupStatusFilters));
    dispatch(setPickupStatusIds(pickupStatusFilters));

    if ((!isEqual(pickupStatusFilters, pickupStatusIds) || transferredStops) && !!filteredStops.length)
      loadRouteStops(vendorId, routeSummary.routeId, pickupStatusFilters)(dispatch);
  };

  const deleteStops = (stopIds: number[]) => {
    setDeletedStops(prevDeletedStops => [...prevDeletedStops, ...stopIds.filter(id => !isNaN(id))]);
  };
  const transferStops = () => setTransferredStops(true);

  const cancelOptimization = () => {
    setOptimizationCanceled(true);
  };

  const renderStopsTable = () => {
    if (isEditMode)
      return (
        <RouteStopsForm
          onSubmit={handleSaveStops}
          initialValues={{
            routeStops,
            pickupTypeId: getInitialPickupTypeId(routeSummary.vehicleTypeId),
            wasteMaterialTypeId: UNKNOWN_ID,
            jobPriorityTypeId: undefined,
          }}
          deleteStops={deleteStops}
          transferStops={transferStops}
          isRouteStopsFormDirty={isRouteStopsFormDirty}
          setOptimizationCanceled={cancelOptimization}
        />
      );

    return filteredStops.length ? (
      <>
        {!!optimizedRouteStops.length && (
          <PanelSection>
            <Table
              cells={getRouteStopsTableCells(routeSummary.vehicleTypeId)}
              rowComponent={RouteStopsTableRow}
              rows={optimizedRouteStops}
              tableHeading={translate('routes.pendingOptimization')}
            />
          </PanelSection>
        )}
        {!!routeStopsWithZeroStopNumber.length && (
          <PanelSection>
            <Table
              cells={optimizedRouteStops.length ? [] : getRouteStopsTableCells(routeSummary.vehicleTypeId)}
              rowComponent={RouteStopsTableRow}
              rows={routeStopsWithZeroStopNumber}
              tableHeading={translate('routes.unableToSequenceRoute')}
            />
          </PanelSection>
        )}
        <Table
          cells={
            routeStopsWithZeroStopNumber.length || optimizedRouteStops.length
              ? []
              : getRouteStopsTableCells(routeSummary.vehicleTypeId)
          }
          rowComponent={RouteStopsTableRow}
          rows={routeStopsWithStopNumber}
          virtualized
          virtualizedProps={{
            height: Math.min(routeStopsWithStopNumber.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
            itemSize: TABLE_ROW_HEIGHT_SMALL,
          }}
          noOverflow
          withClickableRows
        />
      </>
    ) : (
      <Message padding="sMedium">{translate('routes.noRouteStops')}</Message>
    );
  };

  const isEditButtonDisabled = !!travelPathStatusDetails && travelPathStatusDetails.inProgress;

  return (
    <PanelSection isLoading={isRouteStopsLoading || isSaving}>
      <NavigationPrompt when={isRouteStopsFormDirty} />
      <Grid multiLine>
        <GridColumn size="12/12" padding="no">
          <PanelSection withBorder>
            <Grid>
              <GridColumn size="8/12">
                <RouteStopsSearchForm
                  initialValues={{
                    routeStopsSearchTerm: searchTerm,
                    pickupStatusIds: pickupStatusIds,
                    jobPriorityIds: jobPriorityTypeIds,
                  }}
                  onSearchTermChange={onSearchTermChange}
                  onPickupStatusChange={onPickupStatusChange}
                  onJobPriorityChange={onJobPriorityChange}
                  isScheduledRoute={routeSummary.routeStatusTypeId === SCHEDULED}
                />
              </GridColumn>
              <GridColumn align="right" size="4/12">
                {isEditMode ? (
                  <>
                    <Button color="secondary" onClick={handleExitEditMode} margin="xSmall">
                      {translate('common.cancel')}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => dispatch(submit(ROUTE_STOPS_FORM_NAME))}
                      margin="xSmall no"
                      disabled={!isRouteStopsFormDirty}
                    >
                      {translate('common.save')}
                    </Button>
                  </>
                ) : (
                  !isSupport &&
                  !isViewOnly && (
                    <PopoverWrapper
                      triggerButton={
                        <Button
                          color="primary"
                          onClick={() => {
                            dispatch(change(ROUTE_STOPS_SEARCH, ROUTE_STOPS_PICKUP_STATUS_FIELD, pickupStatusIds));
                            loadRouteStops(vendorId, routeSummary.routeId)(dispatch).then(() => setEditMode(true));
                          }}
                          margin="xSmall no"
                          disabled={isEditButtonDisabled}
                        >
                          {translate('routes.editStops')}
                        </Button>
                      }
                      popoverContent={
                        isEditButtonDisabled && (
                          <Popover>
                            <Text block weight="medium" margin="xxSmall no xxSmall">
                              {translate('routes.travelPath.alertMessages.cantMakeChangesRoute')}
                            </Text>
                          </Popover>
                        )
                      }
                    />
                  )
                )}
              </GridColumn>
            </Grid>
          </PanelSection>
        </GridColumn>

        <GridColumn size="12/12" padding="no">
          {renderStopsTable()}
        </GridColumn>
      </Grid>
    </PanelSection>
  );
}
