import { change } from 'redux-form';
import { Component } from 'react';
import { connect } from 'react-redux';
import { map, size } from 'lodash-es';
import { push } from 'connected-react-router';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';

import { AppState } from '../../../store';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { loadUnassignedWeightTickets, deleteWeightTicket, uploadDocument } from '../../ducks/index';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { TODAY_FORMATTED, LIMIT_PER_PAGE, PAGE } from '../../../core/constants';
import { UnassignedWeightTicketsForm } from '../forms';
import { WeightTicketCard } from './weightTicketsPageSections/index';
import { WeightTicketsCards } from '../styled/UnassignedWeightTickets';
import { WeightTicketsPreviewImageModal, WeightTicketsUploadModal, ManagePhoneNumbersModalResolver } from '../modal';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  change(...args: any[]): any;
  vendorId: number;
  isLoading: boolean;
  location: any;
  match: any;
  push: any;
  unassignedWeightTickets: any[];
  loadUnassignedWeightTickets: (
    token: string,
    vendorId: number,
    startDate: string,
    endDate: string,
    searchTerm: string,
    page: number,
  ) => Promise<any>;
  deleteWeightTicket: (token: string, fileId: number, vendorId: number) => Promise<any>;
  uploadDocument: (token: string, file: any, vendorId: number) => Promise<any>;
}

interface State {
  isImagePreviewModalOpen: boolean;
  isUploadModalOpen: boolean;
  weightTicketDetails: any;
  unassignedWeightTicketList: any;
  from?: string;
  isManagePhoneNumbersModalOpen: boolean;
}

class UnassignedWeightTicketsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isImagePreviewModalOpen: false,
      isUploadModalOpen: false,
      weightTicketDetails: {},
      unassignedWeightTicketList:
        size(props.unassignedWeightTickets) && props.unassignedWeightTickets.slice(0, LIMIT_PER_PAGE),
      from: props.location.state && props.location.state.from,
      isManagePhoneNumbersModalOpen: false,
    };
  }

  componentDidMount = () => {
    this.loadBatchedUnassignedWeightTickets();
  };

  componentDidUpdate(prevProps: Props) {
    const {
      location,
      match: {
        params: { token },
      },
      unassignedWeightTickets,
      loadUnassignedWeightTickets,
      vendorId,
    } = this.props;
    if (prevProps.unassignedWeightTickets !== unassignedWeightTickets) {
      const unassignedWeightTicketList =
        size(unassignedWeightTickets) && unassignedWeightTickets.slice(0, LIMIT_PER_PAGE);
      this.setState({ unassignedWeightTicketList });
      this.loadBatchedUnassignedWeightTickets();
    }
    if (prevProps.location.search !== location.search) {
      const { searchTerm, startDate, endDate, page } = getQueryParams(location.search);
      loadUnassignedWeightTickets(
        token,
        vendorId,
        startDate || TODAY_FORMATTED,
        endDate || TODAY_FORMATTED,
        searchTerm,
        page,
      ).then(data => {
        if (prevProps.unassignedWeightTickets !== data.unassignedWeightTickets) {
          this.loadBatchedUnassignedWeightTickets();
        }
      });
    }
  }

  deleteUnassignedWeightTicket = async (id: number) => {
    const {
      deleteWeightTicket,
      vendorId,
      match: {
        params: { token },
      },
    } = this.props;
    if (!(await confirm(translate('autoDispatch.alertMessages.confirmDeleteWeightTicket')))) {
      return;
    }
    await deleteWeightTicket(token, id, vendorId);
  };

  openImagePreviewModal = (fileId: number) => {
    const { unassignedWeightTickets } = this.props;
    this.setState({
      isImagePreviewModalOpen: true,
      weightTicketDetails: unassignedWeightTickets.filter(weightTicket => weightTicket.id === fileId),
    });
  };

  openUploadModal = () => {
    this.setState({
      isUploadModalOpen: true,
    });
  };

  closeImagePreviewModal = () => {
    this.setState({
      isImagePreviewModalOpen: false,
    });
  };

  closeUploadModal = () => {
    this.setState({
      isUploadModalOpen: false,
    });
  };

  onSubmit = (data: any) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    const { from, to } = data.date;
    push(
      createUrl(pathname, search, {
        startDate: from,
        endDate: to,
        searchTerm: data.searchTerm,
      }),
    );
  };

  uploadFile = (files: any) => {
    const {
      change,
      loadUnassignedWeightTickets,
      match: {
        params: { token },
      },
      uploadDocument,
      vendorId,
    } = this.props;
    const file = files[0];
    const from = moment().subtract(3, 'days').toDate();
    uploadDocument(token, file, vendorId)
      .then(() => {
        change('unassignedWeightTickets', 'searchTerm', null);
        this.closeUploadModal();
        createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
        loadUnassignedWeightTickets(token, vendorId, moment(from).format('MM/DD/YYYY'), TODAY_FORMATTED, '', PAGE);
      })
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.uploadFailedDuplicateFileName'));
      });
  };

  getSelectedImage = (fileId: number, fileName: string) => {
    return;
  };

  loadBatchedUnassignedWeightTickets = () => {
    setTimeout(() => {
      const { unassignedWeightTickets } = this.props;
      const { unassignedWeightTicketList } = this.state;
      const hasMore = unassignedWeightTicketList.length + 1 < unassignedWeightTickets.length;
      this.setState((prev, props) => ({
        unassignedWeightTicketList: props.unassignedWeightTickets.slice(
          0,
          prev.unassignedWeightTicketList.length + LIMIT_PER_PAGE,
        ),
      }));
      if (hasMore) this.loadBatchedUnassignedWeightTickets();
    }, 0);
  };

  handleBackAction = () => {
    const {
      history: { length, goBack, push },
      match: {
        params: { token },
      },
    } = this.props;

    const { from } = this.state;
    const previousPageUrl = !!token
      ? `/fleet/autodispatch/serviceconfirmation/${token}`
      : '/fleet/autodispatch/serviceconfirmation/';

    if (from) {
      return push(from);
    }
    return length > 1 ? goBack() : push(previousPageUrl);
  };

  openManagePhoneNumbersModal = () => {
    this.setState({ isManagePhoneNumbersModalOpen: true });
  };

  closeManagePhoneNumbersModal = () => {
    this.setState({ isManagePhoneNumbersModalOpen: false });
  };

  render() {
    const { isLoading, vendorId } = this.props;
    const {
      isImagePreviewModalOpen,
      isUploadModalOpen,
      weightTicketDetails,
      unassignedWeightTicketList,
      isManagePhoneNumbersModalOpen,
    } = this.state;
    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction onClick={this.handleBackAction} id="back-button">
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate('autoDispatch.unassignedWeightTickets')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button
              id="unassigned-weight-ticket-upload"
              color="primary"
              size="small"
              margin="no xSmall no no"
              line
              onClick={() => this.openUploadModal()}
            >
              {translate('autoDispatch.uploadWeightTicket')}
            </Button>
            <Button
              color="primary"
              id="manage-phone-numbers-modal-button"
              line
              noCapitalize
              onClick={() => this.openManagePhoneNumbersModal()}
              size="small"
            >
              {translate('autoDispatch.managePhoneNumbers')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <UnassignedWeightTicketsForm onSubmit={this.onSubmit} />
            <PanelSection vertical dark padding="sMedium">
              {size(unassignedWeightTicketList) > 0 ? (
                <WeightTicketsCards>
                  {map(unassignedWeightTicketList, (weightTicket, index) => (
                    <WeightTicketCard
                      key={index}
                      isModal={false}
                      weightTicket={weightTicket}
                      getSelectedImage={this.getSelectedImage}
                      openImagePreviewModal={this.openImagePreviewModal}
                      deleteUnassignedWeightTicket={this.deleteUnassignedWeightTicket}
                    />
                  ))}
                </WeightTicketsCards>
              ) : (
                <Message padding="sMedium">{translate('autoDispatch.noUnassignedWeightTickets')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
        {isImagePreviewModalOpen && (
          <WeightTicketsPreviewImageModal
            weightTicketDetails={weightTicketDetails[0]}
            closeModal={this.closeImagePreviewModal}
            isModalView={false}
          />
        )}
        {isUploadModalOpen && (
          <WeightTicketsUploadModal
            vendorId={vendorId}
            uploadFile={this.uploadFile}
            closeModal={this.closeUploadModal}
          />
        )}
        {isManagePhoneNumbersModalOpen && (
          <ManagePhoneNumbersModalResolver closeModal={this.closeManagePhoneNumbersModal} />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.fleet.unassignedWeightTickets.isLoading,
  unassignedWeightTickets: state.fleet.unassignedWeightTickets.unassignedWeightTickets,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  change,
  loadUnassignedWeightTickets,
  deleteWeightTicket,
  uploadDocument,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnassignedWeightTicketsPage));
