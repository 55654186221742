import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import BinSensorModal from './BinSensorModal';
import { loadBinSensorSettings } from 'src/vendors/ducks/binSensorSettings';

interface Props {
  closeModal: () => void;
}

export default function BinSensorModalResolver({ closeModal }: Props) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    return loadBinSensorSettings(vendorId)(dispatch);
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={BinSensorModal}
      successProps={{ closeModal }}
    />
  );
}
