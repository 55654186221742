import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ActionButtonTooltip } from '../../../core/components';
import { AppState } from '../../../store';
import {
  Button,
  Grid,
  GridColumn,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from '../../../core/components/styled';
import { BusinessProfileForm } from '../forms';
import { currentUserIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { createSuccessNotification } from '../../../core/services/createNotification';
import CertificationsPage from './CertificationsPage';
import { saveBusinessProfile, saveFile, loadCertifications, loadBusinessProfile } from '../../ducks';
import translate from '../../../core/services/translate';
import { DuckFunction } from '../../../contracts/ducks';

interface Props {
  businessInfo: any;
  gusId: string;
  isloading: boolean;
  userName: string;
  saveBusinessProfile(...args: any[]): any;
  loadBusinessProfile: DuckFunction<typeof loadBusinessProfile>;
}

interface State {
  isEditable: boolean;
}

class BusinessProfilePage extends PureComponent<Props, State> {
  readonly state: State = {
    isEditable: false,
  };

  onSubmit = (data: any) => {
    const { gusId, userName, saveBusinessProfile, loadBusinessProfile } = this.props;

    saveBusinessProfile(gusId, { ...data, currentUser: userName }).then(() => {
      createSuccessNotification(`${translate('haulerProfile.alertMessages.companyInfoSaved')}`);
      loadBusinessProfile(gusId);
      this.setState({ isEditable: false });
    });
  };

  onCancel = () => {
    this.setState({ isEditable: false });
  };

  editIconClick = () => {
    this.setState({ isEditable: true });
  };

  render() {
    const { isEditable } = this.state;

    return (
      <Panel noBoxShadow>
        <PanelSection>
          <Grid padding="no">
            <GridColumn padding="no" size="11/12">
              <PanelSectionTitle>{translate('haulerProfile.companyHeadquarters')}</PanelSectionTitle>
            </GridColumn>
            <GridColumn size="1/12">
              {!isEditable && (
                <Button type="button" text color="primary" onClick={this.editIconClick}>
                  <ActionButtonTooltip icon="edit" tooltip="edit" />
                </Button>
              )}
            </GridColumn>
          </Grid>
        </PanelSection>
        <PanelSection>
          <BusinessProfileForm onSubmit={this.onSubmit} closeModal={this.onCancel} isEditable={isEditable} />
        </PanelSection>
        <PanelSectionGroup>
          <PanelSectionTitle margin="medium no">{translate('haulerProfile.certifications')}</PanelSectionTitle>
          <CertificationsPage />
        </PanelSectionGroup>
      </Panel>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  businessInfo: state.haulerProfile.businessProfile.businessInfo,
  certifications: state.haulerProfile.certifications.certifications,
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor) as any,
  isloading: state.haulerProfile.businessProfile.isLoading,
  userName: state.account.login.user.email,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadBusinessProfile,
  loadCertifications,
  saveBusinessProfile,
  saveFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProfilePage);
