import { useCallback, useEffect, useState } from 'react';
import { getFeatureCollection } from 'src/common/components/map/util';
import GeoFencesGLSource from './GeoFencesGLSource';

type Props = {
  geoFencesGeoJSON: GeoJSON.Feature<GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties>[];
  map: mapboxgl.Map;
  isSatellite: boolean;
};

export default function GeoFencesGL({ map, geoFencesGeoJSON, isSatellite }: Props) {
  const geoJSON = getFeatureCollection(geoFencesGeoJSON);
  const [mapZoom, setMapZoom] = useState(map.getZoom());

  const handleZoom = useCallback(() => {
    setMapZoom(map.getZoom());
  }, [map]);

  useEffect(() => {
    map?.once('load', () => {
      map.on('zoom', handleZoom);
    });

    return () => {
      map?.off('zoom', handleZoom);
    };
  }, [handleZoom, map]);

  return <GeoFencesGLSource geoJSON={geoJSON} isSatellite={isSatellite} mapZoom={mapZoom} />;
}
