import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import doExportReports from '../services/exportReports';

// Actions
const START_EXPORT = 'insights/reports/START_EXPORT';
const COMPLETE_EXPORT = 'insights/reports/COMPLETE_EXPORT';
const FAIL_EXPORT = 'insights/reports/FAIL_EXPORT';

// Initial state
const initialState = {
  isExporting: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    default:
      return state;
  }
};

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const exportReports =
  (
    vendorId: number,
    reportType: string,
    vehicleType: string | null | any[],
    vehicleName: string | null | any[],
    routeName: string | null,
    driverName: string | null | any[],
    startDate: string,
    endDate: string,
    wasteMaterialType: string[],
    serviceType: string[],
    vehicleTypeIdsCSV: number[],
    serviceZoneIdsCSV: number[],
    supervisorIdsCSV: number[],
    customerId?: number,
    customerLocationId?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportReportsPromise = doExportReports(
      vendorId,
      reportType,
      vehicleType,
      vehicleName,
      routeName,
      driverName,
      startDate,
      endDate,
      wasteMaterialType,
      serviceType,
      vehicleTypeIdsCSV,
      serviceZoneIdsCSV,
      supervisorIdsCSV,
      customerId,
      customerLocationId,
    );
    exportReportsPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportReportsPromise;
  };
