import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { date as formatDate } from '../../../utils/services/formatter';

interface Props {
  index: number;
  date?: Date;
  disposalSite?: string;
  disposalTime?: string;
  routeName?: string;
  routeType?: string;
  ticketNumber?: string;
  unitofMeasure?: string;
  vehicleName?: string;
  vehicleTypeName?: string;
  weight?: number;
}

const DisposalSummaryPageTableRow: React.FC<Props> = ({
  date,
  disposalSite,
  disposalTime,
  routeName,
  routeType,
  ticketNumber,
  unitofMeasure,
  vehicleName,
  vehicleTypeName,
  weight,
  index,
}) => (
  <TableRow>
    <TableCell width="9%" id={`disposal-summary-${index}-ticket-number`}>
      {ticketNumber}
    </TableCell>

    <TableCell width="10%" id={`disposal-summary-${index}-date`}>
      {formatDate(date)}
    </TableCell>

    <TableCell width="10%" id={`disposal-summary-${index}-route-name`}>
      {routeName}
    </TableCell>

    <TableCell width="10%" id={`disposal-summary-${index}-route-type`}>
      {routeType}
    </TableCell>

    <TableCell width="10%" id={`disposal-summary-${index}-vehicle-name`}>
      {vehicleName}
    </TableCell>

    <TableCell width="11%" id={`disposal-summary-${index}-vehicle-type-name`}>
      {vehicleTypeName}
    </TableCell>

    <TableCell width="10%" id={`disposal-summary-${index}-disposal-site`}>
      {disposalSite}
    </TableCell>

    <TableCell width="8%" id={`disposal-summary-${index}-weight`}>
      {weight}
    </TableCell>

    <TableCell width="12%" id={`disposal-summary-${index}-uom`}>
      {unitofMeasure}
    </TableCell>

    <TableCell width="10%" id={`disposal-summary-${index}-disposal-time`}>
      {disposalTime}
    </TableCell>
  </TableRow>
);

export default DisposalSummaryPageTableRow;
