import { debounce } from 'lodash-es';
import { FC, useMemo, useCallback } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import Cookie from 'js-cookie';

import { createUrl } from '../../../utils/services/queryParams';
import { Dropdown } from 'src/core/components';
import { Grid, GridColumn } from 'src/core/components/styled';
import { PanelSearch, TypedField } from 'src/core/components';
import { RATE_CONFIGURATION_SERVCE_TYPE_ID_SELECTED_COOKIE, RATE_STATUSES } from 'src/finance/constants/rateManager';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export interface RateConfigurationSearchFormValues {
  searchText?: string;
  serviceTypeId?: number;
  statusTypeId?: number;
}

type PropsWithoutReduxForm = RouteComponentProps & {
  isInEditMode: boolean;
  setRateCodesFilter: (
    statusTypeId?: number,
    serviceTypeId?: number,
    searchText?: string,
    shouldChangeRateCodes?: boolean,
  ) => void;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<RateConfigurationSearchFormValues, PropsWithoutReduxForm>;

export const RATE_CONFIURATION_SEARCH_FORM_NAME = 'rateConfigurationSearchForm';

const RateConfigurationSearchForm: FC<Props> = ({
  handleSubmit,
  isInEditMode,
  location: { pathname, search },
  setRateCodesFilter,
}) => {
  const dispatch = useDispatch();

  const serviceTypes = useSelector(state => state.common.serviceTypes.serviceTypes);

  const serviceTypeOptions = serviceTypes.map(serviceType => ({
    label: serviceType.name,
    value: serviceType.id,
  }));

  const statusOptions = RATE_STATUSES.map(status => ({
    label: status.name,
    value: status.id,
  }));

  const shouldChangeRateCodes = true;

  const setServiceTypeId = (serviceTypeId?: number) => {
    dispatch(push(createUrl(pathname, search, { serviceTypeId })));
    setRateCodesFilter(undefined, serviceTypeId, undefined, shouldChangeRateCodes);
    serviceTypeId && Cookie.set(RATE_CONFIGURATION_SERVCE_TYPE_ID_SELECTED_COOKIE, serviceTypeId.toString());
  };

  const setStatusTypeId = (statusTypeId?: number) => {
    dispatch(push(createUrl(pathname, search, { statusTypeId })));
    setRateCodesFilter(statusTypeId, undefined, undefined, shouldChangeRateCodes);
  };

  const debouncedCallback = useMemo(
    () =>
      debounce((searchText?: string) => {
        dispatch(push(createUrl(pathname, search, { searchText })));
        setRateCodesFilter(undefined, undefined, searchText, shouldChangeRateCodes);
      }, 470),
    [dispatch, pathname, search, setRateCodesFilter, shouldChangeRateCodes],
  );

  const setSearchTextDebounced = useCallback(debouncedCallback, [debouncedCallback]);

  return (
    <GridColumn size="8/12">
      <form onSubmit={handleSubmit}>
        <Grid padding="no" multiLine>
          <GridColumn size="4/12" padding="no xSmall no no">
            <TypedField
              name="serviceTypeId"
              component={Dropdown}
              onChange={(_, newValue: RateConfigurationSearchFormValues['serviceTypeId']) => setServiceTypeId(newValue)}
              props={{
                label: translate('common.serviceType'),
                margin: 'no',
                options: serviceTypeOptions,
                width: '100%',
                disabled: isInEditMode,
              }}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <TypedField
              name="statusTypeId"
              component={Dropdown}
              onChange={(_, newValue: RateConfigurationSearchFormValues['statusTypeId']) => setStatusTypeId(newValue)}
              props={{
                label: translate('common.status'),
                margin: 'no',
                options: statusOptions,
                width: '100%',
                disabled: isInEditMode,
              }}
            />
          </GridColumn>

          <GridColumn size="5/12" verticalAlign="center">
            <TypedField
              name="searchText"
              component={PanelSearch}
              onChange={(_, newValue: RateConfigurationSearchFormValues['searchText']) =>
                setSearchTextDebounced(newValue)
              }
              props={{
                margin: 'no',
                isClearable: true,
              }}
            />
          </GridColumn>
        </Grid>
      </form>
    </GridColumn>
  );
};

export default withRouter(
  reduxForm<RateConfigurationSearchFormValues, PropsWithoutReduxForm>({
    form: RATE_CONFIURATION_SEARCH_FORM_NAME,
    enableReinitialize: true,
  })(RateConfigurationSearchForm),
);
