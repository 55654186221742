import { http } from '../../core/services/http';
import { DriverSafetyConfigurations } from '../interfaces/DriverSafety';

export const loadDriverSafetyConfigurations = (vendorId: number, vehicleTypeId?: number) =>
  http.get(`vendors/${vendorId}/driverSafetySettings?vehicleTypeId=${vehicleTypeId}`).then(response => response.data);

export const loadDriverSafetyConfigurationsForVehicle = (vendorId: number, vehicleId?: number) =>
  http.get(`vendors/${vendorId}/vehicles/${vehicleId}/driverSafetySettings`).then(response => response.data);

export const saveDriverSafetyConfigurations = (
  vendorId: number,
  vehicleTypeId: number,
  driverSafetyConfigurations: DriverSafetyConfigurations[],
) =>
  http
    .put(`vendors/${vendorId}/driverSafetySettings`, { settings: driverSafetyConfigurations, vendorId, vehicleTypeId })
    .then(response => response.data);

export const saveDriverSafetyConfigurationsForVehicle = (
  vendorId: number,
  vehicleId: number,
  driverSafetyConfigurations: DriverSafetyConfigurations[],
) =>
  http
    .put(`vendors/${vendorId}/vehicles/${vehicleId}/driverSafetySettings`, {
      settings: driverSafetyConfigurations,
      vendorId,
      vehicleId,
    })
    .then(response => response.data);
