import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { createBills as doCreateBills } from 'src/finance/services/createBills';

// Actions
const START_CREATE_BILLS = 'finance/billing/createBills/START_CREATE_BILLS';
const COMPLETE_CREATE_BILLS = 'finance/billing/createBills/COMPLETE_CREATE_BILLS';
const FAIL_CREATE_BILLS = 'finance/billing/createBills/FAIL_CREATE_BILLS';
const RESET = 'finance/billing/createBills/RESET';

// Initial state
const initialState: {
  isCreatingBills: boolean;
} = {
  isCreatingBills: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_CREATE_BILLS:
      return update(state, {
        $merge: {
          isCreatingBills: true,
        },
      });

    case COMPLETE_CREATE_BILLS:
      return update(state, {
        $merge: {
          isCreatingBills: false,
        },
      });

    case FAIL_CREATE_BILLS:
      return update(state, {
        $merge: {
          isCreatingBills: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startCreateBills = () => ({
  type: START_CREATE_BILLS,
});

const completeCreateBills = () => ({
  type: COMPLETE_CREATE_BILLS,
});

const failCreateBills = () => ({
  type: FAIL_CREATE_BILLS,
});

export const createBills =
  (
    vendorId: number,
    startDate: StringOrDate,
    endDate: StringOrDate,
    customerId?: number,
    locationId?: number,
    customerStatusIds?: number[],
    customerTypeIds?: number[],
    billMethodIds?: number[],
    paymentTermsIds?: number[],
    unbilledSearchTerm?: string,
    workOrderTypeId?: number,
    containerTypeId?: number,
    isOverdue?: boolean
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startCreateBills());
    const createBillsPromise = doCreateBills(
      vendorId,
      startDate,
      endDate,
      customerId,
      locationId,
      customerStatusIds,
      customerTypeIds,
      billMethodIds,
      paymentTermsIds,
      unbilledSearchTerm,
      workOrderTypeId,
      containerTypeId,
      isOverdue
    );
    createBillsPromise.then(() => dispatch(completeCreateBills())).catch(() => dispatch(failCreateBills()));
    return createBillsPromise;
  };

export const resetCreateBills = () => ({
  type: RESET,
});
