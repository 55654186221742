import Cookie from 'js-cookie';

import { http } from '../../core/services/http';
import { ReleaseNote } from '../interfaces/ReleaseNote';

export const loadPublicReleaseNotes = () => {
  return http.get('release-notes/public').then(response => response.data);
};

export const loadReleaseNotes = (fromDate: string, toDate: string) => {
  return http.get('release-notes', { params: { fromDate, toDate } }).then(response => response.data);
};

export const saveReleaseNote = (releaseNote: ReleaseNote) => {
  return http.post(`release-notes/`, releaseNote).then(response => response.data);
};

export const editReleaseNote = (releaseNote: ReleaseNote) =>
  http.put(`release-notes/${releaseNote.id}`, releaseNote).then(response => {
    return response.data;
  });

export const deleteReleaseNote = (id: number) => http.delete(`release-notes/${id}`);

export const deleteReleaseNoteItem = (id: number, featureId: number) =>
  http.delete(`release-notes/${id}/items/${featureId}`);

const cookieKey = 'LastSeenReleaseDate';

export const getLatestReleaseNote = () => http.get(`release-notes/latest`).then(response => response.data);

export const getLastSeenReleaseNote = () => Cookie.get(cookieKey);

export const setLastSeenReleaseNote = (date: Date | string) => {
  Cookie.set(cookieKey, date);
};
