import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { CityInsight } from 'src/dashboard/interfaces/cityInsights';
import { getIconTypeForCityInsight } from 'src/common/constants/insightIcons';
import { DASHBOARD_CITY_INSIGHTS_LAYER } from 'src/dashboard/constants/dashboardMapGL';

export const getInsightId = (insight: CityInsight) => +insight.mapSelectorId.replace('S:', '');

export type CityInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getCityInsightsGeoJSON = (cityInsights: CityInsight[]) =>
  getFeatureCollection<GeoJSON.Point, CityInsightFeatureProperties>(
    cityInsights.map(insight =>
      getPointFeature(getInsightId(insight), [insight.longitude, insight.latitude], {
        id: getInsightId(insight),
        clickable: true,
        layer: DASHBOARD_CITY_INSIGHTS_LAYER,
        icon: getIconTypeForCityInsight(insight)?.id,
      }),
    ),
  );
