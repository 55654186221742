import styled, { css } from 'styled-components';

interface Props {
  id?: string | number;
  right?: boolean;
  selectedItem?: boolean;
  grayed?: boolean;
  disabled?: boolean;
}

export const DefaultFiltersContainer = styled.div<Props>`
  height: 180px;
  width: 100%;
  margin-top: 5px;
  overflow: auto;
  border: 2px solid ${props => props.theme.grayDarker};
  ${props =>
    props.right &&
    css`
      margin-left: auto;
    `};
`;

export const FilterItem = styled.h6<Props>`
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 12px;
  text-align: left;
  padding: 10px;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
  ${props =>
    props.selectedItem &&
    !props.disabled &&
    css`
      background: ${props => props.theme.brandPrimary};
      color: ${props => props.theme.brandSecondary};
    `};
  ${props =>
    props.grayed &&
    !props.selectedItem &&
    css`
      background: ${props => props.theme.grayLight};
    `};

  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.grayDark};
      &:hover {
        cursor: not-allowed;
      }
    `}
`;
