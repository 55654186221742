import { isDirty, submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSet } from 'src/core/components/styled';
import { Panel } from 'src/core/components/styled';
import { SERVICE_DETAILS_EDITOR_FORM } from '../../forms/ServiceDetailsEditorForm';
import { STREET_SERVICE_DETAILS_EDITOR_FORM } from '../../forms/StreetServiceDetailsEditorForm';
import translate from 'src/core/services/translate';

interface Props {
  isSaving: boolean;
  isSnowPlowOrStreetSweeperRoute: boolean;
  onCancel: () => void;
}

const ServiceDetailsSubmitSection = ({ isSaving, isSnowPlowOrStreetSweeperRoute, onCancel }: Props) => {
  const dispatch = useDispatch();
  const isServiceFormDirty = useSelector(isDirty(SERVICE_DETAILS_EDITOR_FORM));
  const isStreetServiceFormDirty = useSelector(isDirty(STREET_SERVICE_DETAILS_EDITOR_FORM));

  const onSubmit = async (_: any) => {
    isSnowPlowOrStreetSweeperRoute
      ? dispatch(submit(STREET_SERVICE_DETAILS_EDITOR_FORM))
      : dispatch(submit(SERVICE_DETAILS_EDITOR_FORM));
  };

  return (
    <Panel>
      <ButtonSet padding="large no" margin="no" align="center">
        <Button type="button" color="secondary" id="edit-service-cancel-button" onClick={onCancel}>
          {translate('common.cancel')}
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={onSubmit}
          id="edit-service-save-button"
          margin="no no no small"
          disabled={isSaving || (isSnowPlowOrStreetSweeperRoute ? !isStreetServiceFormDirty : !isServiceFormDirty)}
        >
          {translate('common.save')}
        </Button>
      </ButtonSet>
    </Panel>
  );
};

export default ServiceDetailsSubmitSection;
