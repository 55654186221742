import { filter, find } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import { useSelector } from 'src/core/hooks/useSelector';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  SERVICE_CONTRACT_WORKFLOW_FORM,
  ServiceContractWorkflowFormValues,
} from '../components/forms/ServiceContractWorkflowForm';
import { loadContainerList, loadCustomerLocationsForWorkflow, loadService, loadServices, resetService } from '../ducks';
import { Service } from '../interfaces/Services';
import { CustomerLocation } from '../interfaces/Customers';
import { CLOSED } from 'src/common/constants/accountStatuses';

const useServiceContractWorkflow = (
  isContainerManagementFeatureEnabled: boolean,
  formValues: ServiceContractWorkflowFormValues,
) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const services = useSelector(state => state.customers.services.servicesForServiceContractWorkflow);

  // Flow's actions
  const changeSize = formValues?.actions?.changeSize;
  const updateCount = formValues?.actions?.updateCount;
  const replaceContainer = formValues?.actions?.replaceContainer;
  const manageContainers = formValues?.actions?.manageContainers;

  const selectedService = useMemo(
    () => find(services, { id: formValues?.serviceId }),
    [formValues?.serviceId, services],
  );

  //load locations for the selected customer
  useEffect(() => {
    if (formValues?.customerId)
      loadCustomerLocationsForWorkflow(formValues.customerId)(dispatch).then(locations => {
        if (locations.length === 1) {
          const location = locations[0] as CustomerLocation;
          if (location.accountStatusId !== CLOSED)
            dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'locationId', locations[0].id));
        } else if (formValues?.locationId) {
          const location = locations.find(location => location.id === formValues?.locationId);
          if (!location) {
            dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'locationId', null));
          }
        }
      });
    // reset the locations if no customer is selected
    else {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'locationId', null));
    }
  }, [dispatch, formValues.customerId, formValues?.locationId]);

  // load services for the selected location
  useEffect(() => {
    if (formValues?.locationId) loadServices(formValues.locationId, true)(dispatch);

    dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'serviceId', null));
  }, [dispatch, formValues?.locationId]);

  // when selected service changes reset the bellow fields
  useEffect(() => {
    if (!selectedService?.id) {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'sizeId', null));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'numberOfContainers', null));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerNo', null));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'changeEffectiveDate', null));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'createRemovalJob', null));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'createDeliveryJob', null));
    } else {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'sizeId', selectedService.equipmentSizeId));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'currentSizeId', selectedService.equipmentSizeId));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'numberOfContainers', selectedService.numberOfContainers));
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerNo', selectedService.binNumber));
    }
  }, [
    dispatch,
    selectedService?.id,
    selectedService?.equipmentSizeId,
    selectedService?.numberOfContainers,
    selectedService?.binNumber,
  ]);

  const countIncreased = useMemo(() => {
    if (isContainerManagementFeatureEnabled) {
      const vendorContainerIds = formValues.currentService?.vendorContainers?.map(container => container.id);
      const newContainerIds = filter(formValues?.containerManagementContainers, c => c.locationId === 0)?.map(
        container => container.id,
      );
      return newContainerIds?.some(containerId => !vendorContainerIds?.includes(containerId));
    } else {
      if (formValues?.numberOfContainers)
        return formValues?.numberOfContainers > (selectedService?.numberOfContainers || 0);

      return false;
    }
  }, [
    formValues?.containerManagementContainers,
    formValues.currentService?.vendorContainers,
    formValues?.numberOfContainers,
    isContainerManagementFeatureEnabled,
    selectedService?.numberOfContainers,
  ]);

  const countDecreased = useMemo(() => {
    if (isContainerManagementFeatureEnabled) {
      const vendorContainerIds = formValues.currentService?.vendorContainers?.map(container => container.id);
      const newContainerIds = filter(formValues?.containerManagementContainers, c => c.locationId === 0)?.map(
        container => container.id,
      );
      return vendorContainerIds?.some(containerId => !newContainerIds?.includes(containerId));
    } else {
      if (formValues?.numberOfContainers)
        return formValues?.numberOfContainers < (selectedService?.numberOfContainers || 0);

      return false;
    }
  }, [
    formValues?.containerManagementContainers,
    formValues.currentService?.vendorContainers,
    formValues?.numberOfContainers,
    isContainerManagementFeatureEnabled,
    selectedService?.numberOfContainers,
  ]);

  useEffect(() => {
    if (!formValues.deliveryJobDate && formValues.createDeliveryJob) {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'deliveryJobDate', formValues.changeEffectiveDate));
    } else if (!formValues.createDeliveryJob) {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'deliveryJobDate', null));
    }

    if (!formValues.removalJobDate && formValues.createRemovalJob) {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'removalJobDate', formValues.changeEffectiveDate));
    } else if (!formValues.createRemovalJob) {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'removalJobDate', null));
    }
  }, [
    dispatch,
    formValues.changeEffectiveDate,
    formValues.createDeliveryJob,
    formValues.createRemovalJob,
    formValues.deliveryJobDate,
    formValues.removalJobDate,
  ]);

  // if no action selected reset the service
  useEffect(() => {
    if (
      ((!isContainerManagementFeatureEnabled && !updateCount && !replaceContainer) ||
        (isContainerManagementFeatureEnabled && !manageContainers)) &&
      !changeSize &&
      formValues?.serviceId
    ) {
      dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'serviceId', null));
    }
  }, [
    changeSize,
    dispatch,
    formValues?.serviceId,
    isContainerManagementFeatureEnabled,
    manageContainers,
    replaceContainer,
    updateCount,
  ]);

  //when there is a service and manage containers is selected, load container list and the service
  useEffect(() => {
    if (selectedService?.id) {
      loadService(selectedService?.id)(dispatch).then((service: Service) => {
        if (manageContainers) {
          const vendorContainerIds = service.vendorContainers.map(container => container.id);
          loadContainerList(vendorId)(dispatch).then(containersList => {
            const containerManagementContainers = containersList
              .filter(container => vendorContainerIds.includes(container.id))
              .map(container => ({
                ...container,
                locationId: 0,
              }));

            dispatch(
              change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerManagementContainers', containerManagementContainers),
            );
          });
        }
        dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'currentService', service));
      });
    } else {
      dispatch(resetService());
    }
  }, [dispatch, manageContainers, selectedService?.id, vendorId]);

  return {
    countDecreased,
    countIncreased,
    replaceContainer,
    updateCount,
    changeSize,
    manageContainers,
    selectedService,
  };
};

export default useServiceContractWorkflow;
