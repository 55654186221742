import { connect } from 'react-redux';
import { Push, push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { useState, useRef } from 'react';

import { Grid, Panel, PanelSection, Text } from 'src/core/components/styled';
import { MapDragHandle } from 'src/core/components';
import { PageContent, PageHeader, PageDetails, PageTitleContainer, PageTitle } from 'src/common/components/styled';
import { STREET_SEGMENTS_MAP_HEIGHT } from 'src/routes/constants/streetSegmentsMap';
import { StreetNetworkMapWrapper } from 'src/customers/components/styled';
import CreateEditSnowSweeperRouteModalResolver from 'src/vendors/components/modals/CreateEditSnowSweeperRouteModalResolver';
import SnowSweeperRouteSummaryDetails from './SnowSweeperRouteSummaryDetails';
import StreetNetworkMapGL from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import translate from 'src/core/services/translate';

type Props = {
  push: Push;
};

const CreateSnowOrSweeperRoutePage: React.FC<Props> = ({ push }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isCreateSnowOrSweeperRouteModalOpen, setIsCreateSnowOrSweeperRouteModalOpen] = useState(true);
  const [mapHeight, setMapHeight] = useState(STREET_SEGMENTS_MAP_HEIGHT);

  const handleResizeMap = (_: any, __: any, map: HTMLElement) => {
    setMapHeight(map.offsetHeight);
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('routes.newRoute')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      <Panel>
        <PanelSection>
          <SnowSweeperRouteSummaryDetails />
        </PanelSection>
      </Panel>
      <Panel margin="xxSmall no">
        <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }} onResize={handleResizeMap}>
          <StreetNetworkMapWrapper ref={wrapperRef} height={mapHeight}>
            <StreetNetworkMapGL />
          </StreetNetworkMapWrapper>
        </Resizable>
      </Panel>
      <Panel padding="medium">
        <Grid margin="medium no" centered>
          <Text>{translate('routes.noRouteStreetSegmentsAdded')}</Text>
        </Grid>
      </Panel>

      {isCreateSnowOrSweeperRouteModalOpen && (
        <CreateEditSnowSweeperRouteModalResolver
          closeModal={() => {
            push('/customers/service-network');
            setIsCreateSnowOrSweeperRouteModalOpen(false);
          }}
        />
      )}
    </PageContent>
  );
};

const mapDispatchToProps = { push };

export default connect(undefined, mapDispatchToProps)(CreateSnowOrSweeperRoutePage);
