import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getIconTypeForGeoFenceInsight } from 'src/common/constants/insightIcons';
import { DASHBOARD_GEO_FENCE_INCIDENTS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { GeoFenceIncidentsItem } from 'src/dashboard/interfaces/routesData';
import { DASHBOARD_VEHICLE_INSIGHTS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { getIconTypeForDashboardMapboxVehicleInsight } from 'src/common/constants/insightIcons';

import { VehicleInsightDetails } from 'src/dashboard/interfaces/vehiclePositions';

export type VehicleInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getGeoFenceIncidentsGeoJSON = (geoFenceIncidents: GeoFenceIncidentsItem[]) =>
  getFeatureCollection<GeoJSON.Point, VehicleInsightFeatureProperties>(
    geoFenceIncidents.map(insight => {
      return getPointFeature(insight.id, [insight.longitude, insight.latitude], {
        id: insight.id,
        clickable: true,
        layer: DASHBOARD_GEO_FENCE_INCIDENTS_LAYER,
        icon: getIconTypeForGeoFenceInsight(insight)?.id,
      });
    }),
  );

export const getDashboardMapboxVehicleInsightsGeoJSON = (vehicleInsights: VehicleInsightDetails[]) =>
  getFeatureCollection<GeoJSON.Point, VehicleInsightFeatureProperties>(
    vehicleInsights.map((insight, index) =>
      getPointFeature(index + 1, [insight.longitude, insight.latitude], {
        id: index + 1,
        clickable: true,
        layer: DASHBOARD_VEHICLE_INSIGHTS_LAYER,
        icon: getIconTypeForDashboardMapboxVehicleInsight(insight)?.id,
      }),
    ),
  );
