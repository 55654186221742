import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  loadEquipmentSizes,
  loadEquipmentTypes,
  loadJobPriorityTypes,
  loadReasonCodeTypes,
  loadWasteAuditTypes,
  loadWasteTypes,
} from 'src/common/ducks';
import { loadPickupTypes } from 'src/routes/ducks/pickupTypes';
import { loadUserById } from 'src/vendors/ducks';
import { PanelSectionLoading } from 'src/core/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteStops from './RouteStops';

type Props = {
  isEditMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
};

export default function RouteStopsResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { user } = useSelector(state => state.account.login);

  const loadDependencies = () => {
    if (!routeSummary) return Promise.reject();
    const dependencies = [
      loadWasteAuditTypes()(dispatch),
      loadWasteTypes()(dispatch),
      loadEquipmentSizes()(dispatch),
      loadPickupTypes(routeSummary.vehicleTypeId)(dispatch),
      loadReasonCodeTypes()(dispatch),
      loadEquipmentTypes()(dispatch),
      loadUserById(vendorId, user.userId)(dispatch),
      loadJobPriorityTypes()(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PanelSectionLoading}
      resolve={loadDependencies}
      successComponent={RouteStops}
      successProps={props}
    />
  );
}
