import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE } from 'src/dashboard/constants/dashboardMapGL';
import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DashboardSnowSweeperStreetSegmentsGLPopupResolver from './DashboardSnowSweeperStreetSegmentsGLPopupResolver';
import DashboardSnowSweeperStreetSegmentsGLSource from './DashboardSnowSweeperStreetSegmentsGLSource';
import { getDashboardSnowSweeperStreetSegmentsGeoJSON } from './utils';
import { getFormValues } from 'redux-form';
import {
  SNOW_SWEEPER_FILTERS_FORM_NAME,
  SnowSweeperFiltersFormValues,
} from 'src/dashboard/components/forms/SnowSweeperFiltersForm';

interface Props {
  map: mapboxgl.Map;
  vehicleTypeId: number;
}

const DashboardSnowSweeperStreetSegmentsGL: FC<Props> = ({ map, vehicleTypeId }) => {
  const { selectedFeature } = useSelector(state => state.dashboard.mapControls);
  const segments = useSelector(state => state.dashboard.alternativeFleetOps.filteredSegments);
  const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const geoJson = useMemo(
    () => getDashboardSnowSweeperStreetSegmentsGeoJSON(segments, vehicleTypeId, formValues.isLastActivity),
    [formValues.isLastActivity, segments, vehicleTypeId],
  );

  useEffect(() => {
    if (!map || !map.isStyleLoaded()) return;

    map.removeFeatureState({ source: DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE });

    if (selectedFeature?.id) {
      map.setFeatureState(
        {
          id: selectedFeature.id,
          source: DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE,
        },
        {
          selected: true,
        },
      );
    }
  }, [map, selectedFeature]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === DASHBOARD_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE);

        if (!feature?.id) return;

        dispatch(setDashboardSelectedFeature('routeSegments', Number(feature.id)));
      });
    });
  }, [map, dispatch, vendorId]);

  return (
    <>
      <DashboardSnowSweeperStreetSegmentsGLSource geoJSON={geoJson} />
      <DashboardSnowSweeperStreetSegmentsGLPopupResolver
        segments={segments}
        isSnowPlow={vehicleTypeId === SNOW_PLOW_ID}
        key={selectedFeature?.id}
      />
    </>
  );
};

export default DashboardSnowSweeperStreetSegmentsGL;
