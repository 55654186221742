import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { doLoadGeoFenceFilterOptions } from '../services/geoFences';

export type GeoFenceMapFilterOption = {
  id: number;
  name: string;
  zoneTypeId: number;
};

export type GeoFencesFilterOptions = {
  geoFenceMapFilterOptions: GeoFenceMapFilterOption[];
  total: number;
  totalBulky: number;
  totalStreetSweepers: number;
  totalGenerals: number;
  totalRouteTemplates: number;
  totalDailyRoutes: number;
};

const START_LOAD_GEO_FENCE_FILTER_OPTIONS = 'routes/geoFencesFilterOptions/START_LOAD_GEO_FENCE_FILTER_OPTIONS';
const COMPLETE_LOAD_GEO_FENCE_FILTER_OPTIONS = 'routes/geoFencesFilterOptions/COMPLETE_LOAD_GEO_FENCE_FILTER_OPTIONS';
const FAIL_LOAD_GEO_FENCE_FILTER_OPTIONS = 'routes/geoFencesFilterOptions/FAIL_LOAD_GEO_FENCE_FILTER_OPTIONS';
const RESET_GEO_FENCE_FILTER_OPTIONS = 'routes/geoFencesFilterOptions/RESET_GEO_FENCE_FILTER_OPTIONS';

const initialState = {
  geoFencesFilterOptions: {
    geoFenceMapFilterOptions: [] as GeoFenceMapFilterOption[],
    total: 0,
    totalBulky: 0,
    totalStreetSweepers: 0,
    totalGenerals: 0,
    totalRouteTemplates: 0,
    totalDailyRoutes: 0,
  },
  isLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_GEO_FENCE_FILTER_OPTIONS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_GEO_FENCE_FILTER_OPTIONS:
      return update(state, {
        $merge: {
          isLoading: false,
          geoFencesFilterOptions: action.geoFencesFilterOptions,
        },
      });

    case FAIL_LOAD_GEO_FENCE_FILTER_OPTIONS:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET_GEO_FENCE_FILTER_OPTIONS:
      return initialState;
    default:
      return state;
  }
};

// Action creators
const startLoadGeoFenceFilterOptions = () => ({
  type: START_LOAD_GEO_FENCE_FILTER_OPTIONS,
});

const completeLoadGeoFenceFilterOptions = (geoFencesFilterOptions: GeoFencesFilterOptions) => ({
  type: COMPLETE_LOAD_GEO_FENCE_FILTER_OPTIONS,
  geoFencesFilterOptions,
});

const failLoadGeoFenceFilterOptions = () => ({
  type: FAIL_LOAD_GEO_FENCE_FILTER_OPTIONS,
});

export const resetGeoFenceFilterOptions = () => ({ type: RESET_GEO_FENCE_FILTER_OPTIONS });

export const loadGeoFenceFilterOptions =
  ({
    vendorId,
    routeId,
    routeTemplateId,
    routeName,
    routeDate,
    vehicleTypeId,
  }: {
    vendorId: number;
    routeId?: number;
    routeTemplateId?: number;
    routeName?: string;
    routeDate?: string | Date;
    vehicleTypeId?: number;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadGeoFenceFilterOptions());
    const loadGeoFenceFilterOptionsPromise = doLoadGeoFenceFilterOptions({
      vendorId,
      routeId,
      routeTemplateId,
      routeName,
      routeDate,
      vehicleTypeId,
    });
    loadGeoFenceFilterOptionsPromise
      .then(geoFencesFilterOptions => dispatch(completeLoadGeoFenceFilterOptions(geoFencesFilterOptions)))
      .catch(() => dispatch(failLoadGeoFenceFilterOptions()));
    return loadGeoFenceFilterOptionsPromise;
  };
