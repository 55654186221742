import FileSaver from 'file-saver';

import { EXCEL_MIME_TYPES } from '../../core/constants';
import { http } from '../../core/services/http';
import { transformLoadVehicles } from './transformVehicles';
import { transformRawVehicles } from './vehiclesTransformers';
import { Vehicle, VehicleSummary } from '../interfaces/Vehicles';
import { VehicleType } from '../interfaces/VehicleTypes';
import translate from '../../core/services/translate';

export const loadVehicles = (
  vendorId: number,
  showActiveTrucks = false,
  vehicleTypeId?: number,
  date?: Date | string,
) =>
  http
    .get(`vendors/${vendorId}/vehicles`, { params: { showActiveTrucks, vehicleTypeId, date } })
    .then(response => transformLoadVehicles(response.data.vehicles));

export interface LoadVehicleListFilters {
  searchTerm?: string;
  vehicleTypeIds?: number[];
  showActiveVehicles?: boolean;
  showAvailableVehicles?: boolean;
  operationalFacility?: number;
}

export const loadVehicleList = (
  vendorId: number,
  vehicleTypes: VehicleType[],
  {
    searchTerm,
    vehicleTypeIds = [],
    showActiveVehicles,
    showAvailableVehicles,
    operationalFacility,
  }: LoadVehicleListFilters = {},
) =>
  http
    .get<Vehicle[]>(`vendors/${vendorId}/vehicleList`, {
      params: {
        searchTerm,
        vehicleTypeIdsCSV: !vehicleTypeIds.length ? undefined : vehicleTypeIds.join(','),
        showActiveVehicles,
        showAvailableVehicles,
        operationalFacilityId: operationalFacility,
      },
    })
    .then(response => transformRawVehicles(response.data, vehicleTypes));

export const loadVehicleSummary = (
  vendorId: number,
  {
    searchTerm,
    vehicleTypeIds = [],
    showActiveVehicles,
    showAvailableVehicles,
    operationalFacility,
  }: LoadVehicleListFilters = {},
) =>
  http
    .get<VehicleSummary>(`vendors/${vendorId}/vehicleSummary`, {
      params: {
        searchTerm,
        vehicleTypeIdsCSV: !vehicleTypeIds.length ? undefined : vehicleTypeIds.join(','),
        showActiveVehicles,
        showAvailableVehicles,
        operationalFacilityId: operationalFacility,
      },
    })
    .then(response => response.data);

export const loadVehicleCameraConfigurations = () =>
  http.get('/common/systemTypes/vehicleCameraConfigurations').then(response => response.data);

export const loadDeviceManufacturerTypeList = () =>
  http.get('/common/systemTypes/deviceManufacturerTypeValues').then(response => response.data);

export const loadActiveVehicles = (vendorId: number) =>
  http.get(`vendors/${vendorId}/activeVehicles`).then(response => response.data);

export const exportVehicleList = (
  vendorId: number,
  {
    searchTerm,
    vehicleTypeIds = [],
    showActiveVehicles,
    showAvailableVehicles,
    operationalFacility,
  }: LoadVehicleListFilters = {},
) =>
  http
    .get(`vendors/${vendorId}/exportVehiclesList`, {
      params: {
        searchTerm,
        vehicleTypeIdsCSV: !vehicleTypeIds.length ? undefined : vehicleTypeIds.join(','),
        showActiveVehicles,
        showAvailableVehicles,
        operationalFacilityId: operationalFacility,
      },
      responseType: 'arraybuffer',
    })
    .then(response => {
      const blob = new Blob([response.data], {
        type: EXCEL_MIME_TYPES,
      });

      FileSaver.saveAs(blob, `${translate('vehicles.vehicleListExport')}.xlsx`);
    });

export const checkVendorHasMileageInformation = (vendorId: number, vehicleTypeIds: number[] = []) =>
  http
    .get<boolean>(`vendors/${vendorId}/hasMileageInformation`, {
      params: {
        vehicleTypeIdsCSV: !vehicleTypeIds.length ? undefined : vehicleTypeIds.join(','),
      },
    })
    .then(response => response.data);
