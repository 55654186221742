import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadRateAccountingCodes as doLoadRateAccountingCodes } from '../services/rateAccountingCodes';

const START_LOAD = 'common/rateAccountingCodes/START_LOAD';
const COMPLETE_LOAD = 'common/rateAccountingCodes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/rateAccountingCodes/FAIL_LOAD';
const RESET = 'common/rateAccountingCodes/RESET';

type State = {
  rateAccountingCodes: any[];
  isLoading: boolean;
};

const initialState: State = {
  rateAccountingCodes: [],
  isLoading: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rateAccountingCodes: action.rateAccountingCodes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rateAccountingCodes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rateAccountingCodes: any[]) => ({
  type: COMPLETE_LOAD,
  rateAccountingCodes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRateAccountingCodes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRateAccountingCodes = doLoadRateAccountingCodes();
  loadRateAccountingCodes
    .then(rateAccountingCodes => dispatch(completeLoad(rateAccountingCodes)))
    .catch(() => dispatch(failLoad()));
  return loadRateAccountingCodes;
};

export const resetRateCodes = () => ({
  type: RESET,
});
