import React from 'react';

import { Field } from 'redux-form';
import { filter, find, get, map } from 'lodash-es';

import { ACCOUNT_STATUSES, NEW_ACCOUNT } from '../../../common/constants';
import { Container, TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import { CUSTOMERS_SUSPENDED_LOCATIONS_EDIT } from '../../../account/constants';
import { Dropdown, ActionButtonTooltip } from '../../../core/components';
import { PermissionGuard } from '../../../account/components';

const accountStatusOptions = map(
  filter(ACCOUNT_STATUSES, ({ id }) => id !== NEW_ACCOUNT),
  ({ id, name }) => ({
    label: name,
    value: id,
  }),
);

interface Props {
  address?: string;
  customer: any;
  hasEditStatusPermission: boolean;
  onStatusChange: (key: string, customerId?: number, locationId?: number, serviceId?: number, value?: number) => void;
  push: (url: string) => void;
  location: any;
  service: any;
  status: number;
}

const SuspendedLocationsFormTableRow: React.FC<Props> = ({
  address,
  customer,
  hasEditStatusPermission,
  location,
  onStatusChange,
  push,
  service,
  status,
}) => {
  return (
    <TableRow id={`customers-suspended-location-${location.id}-service-${service.id}-form-table-row`}>
      <TableCell
        id={`customers-suspended-location-${location.id}-service-${service.id}-form-customer-name-cell`}
        width="23%"
        vertical
      >
        <Text block weight="medium" margin="no no xxSmall" singleLine>
          {customer.name}
        </Text>
      </TableCell>
      <TableCell
        id={`customers-suspended-location-${location.id}-service-${service.id}-form-location-name-cell`}
        width="28%"
        vertical
      >
        <Text weight="light" margin="no no xxSmall" singleLine>
          {location.name}
        </Text>
        {location.id && (
          <Text block weight="medium" margin="no no xxSmall" singleLine>
            {address}
          </Text>
        )}
      </TableCell>
      <TableCell
        id={`customers-suspended-location-${location.id}-service-${service.id}-form-service-name-cell`}
        width="27%"
        vertical
      >
        <Text weight="light" margin="no no xxSmall" singleLine>
          {service.name}
        </Text>
      </TableCell>
      <TableCell
        id={`customers-suspended-location-${location.id}-service-${service.id}-form-status-cell`}
        width="14%"
        align="center"
      >
        <Container>
          {hasEditStatusPermission ? (
            <Field
              name={`suspendedLocations.suspendedLocations${customer.id + location.id + service.id}.status`}
              component={Dropdown as any}
              margin="no"
              options={accountStatusOptions}
              onChange={(event: any) =>
                onStatusChange(
                  `suspendedLocations.suspendedLocations${customer.id + location.id + service.id}.isChanged`,
                  customer.id,
                  location.id,
                  service.id,
                  event,
                )
              }
              id={`customers-suspended-location-${location.id}-service-${service.id}-form-status-field`}
            />
          ) : (
            <Text weight="light" margin="no no xxSmall">
              {get(
                find(accountStatusOptions, accountStatus => accountStatus.value === status),
                'label',
              )}
            </Text>
          )}
        </Container>
      </TableCell>
      <TableCell
        id={`customers-suspended-location-${location.id}-service-${service.id}-form-edit-button-cell`}
        width="9%"
        align="right"
      >
        <PermissionGuard permission={CUSTOMERS_SUSPENDED_LOCATIONS_EDIT}>
          <TableActionButton
            id={`customers-suspended-location-${location.id}-service-${service.id}-form-edit-button`}
            onClick={() => push(`/customers/customers/${customer.id}/edit`)}
          >
            <ActionButtonTooltip icon="edit" tooltip="edit" />
          </TableActionButton>
        </PermissionGuard>
      </TableCell>
    </TableRow>
  );
};

export default SuspendedLocationsFormTableRow;
