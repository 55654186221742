import { PureComponent, Fragment } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';

import {
  Grid,
  GridColumn,
  PanelSection,
  Button,
  ButtonSet,
  ModalClose,
  ModalCloseIcon,
} from '../../../core/components/styled';
import { Switch } from '../../../core/components';
import { TypedField } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class ContainerManagementForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine } = this.props;

    return (
      <Fragment>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <PanelSection padding="small xSmall">
          <Grid centered>
            <GridColumn size="6/12" align="center">
              <form onSubmit={handleSubmit}>
                <TypedField
                  name="lockContainerSelection"
                  component={Switch}
                  props={{
                    label: translate('vendors.lockContainerSelection'),
                  }}
                />
                <ButtonSet margin="large no no">
                  <Button type="submit" color="primary">
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </form>
            </GridColumn>
          </Grid>
        </PanelSection>
      </Fragment>
    );
  }
}

export default reduxForm<any, ComponentProps>({
  form: 'containerManagement',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(ContainerManagementForm);
