import React, { PureComponent } from 'react';
import { reduce, get } from 'lodash-es';
import { ModalTitle } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { NotificationPhoneNumbersForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props {
  saveTextPhoneNumbers: (numbers: string) => void;
  onCancel: (isPristine: boolean) => void;
}

class NotificationPhoneNumbersModal extends PureComponent<Props> {
  onSaveNotificationphoneNumbers = (formData: any) => {
    const { onCancel, saveTextPhoneNumbers } = this.props;
    const textNotificationPhones = reduce(
      get(formData, 'textNotificationPhones'),
      (phoneNumbers: any[], key) => {
        phoneNumbers.push(get(key, 'phoneNumber'));
        return phoneNumbers;
      },
      [],
    ).join(',');
    saveTextPhoneNumbers(textNotificationPhones);
    onCancel(true);
  };

  render() {
    const { onCancel } = this.props;
    return (
      <Modal padding="medium" size="small">
        <ModalTitle>{translate('customers.editNotificationPhoneNumbers')}</ModalTitle>
        <NotificationPhoneNumbersForm onSubmit={this.onSaveNotificationphoneNumbers} closePopup={onCancel} />
      </Modal>
    );
  }
}

export default NotificationPhoneNumbersModal;
