import { BULK, PORTABLE_TOILET, PORTABLE_RESTROOM_TRAILER } from '../../common/constants';
import { camelize } from 'humps';
import { map, upperFirst } from 'lodash-es';

import { Route } from '../interfaces/Route';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const getEquipmentSize = (equipmentSize: string, equipmentName: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

export const getEquipmentSizeTranslationKey = (
  equipmentSize: string,
  serviceTypeName: string,
  equipmentName: string,
) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

export const transformLoadDispatchBoardJobs = (stops: any[], isOnHoldJob?: boolean) => {
  return map(
    stops,
    (
      {
        containerType,
        containerSize,
        containerSizeTechnicalName,
        containerTypeTechnicalName,
        wasteMaterialType,
        wasteMaterialTypeTechnicalName,
        jobType,
        jobStatus,
        serviceType,
        ...job
      },
      index,
    ) => {
      const size = getEquipmentSize(containerSizeTechnicalName, containerType);

      return {
        index,
        ...job,
        containerType,
        containerTypeName: translate(
          createTranslationKey(containerTypeTechnicalName || containerType, 'common.equipmentTypes'),
        ),
        containerSize,
        containerSizeName: translate(
          getEquipmentSizeTranslationKey(containerSizeTechnicalName, serviceType.name, containerType),
          { size },
        ),
        wasteMaterialType,
        wasteMaterialTypeTechnicalName,
        wasteMaterialTypeName: translate(createTranslationKey(wasteMaterialTypeTechnicalName, 'common.wasteTypes')),
        jobType,
        jobTypeName: translate(createTranslationKey(jobType, 'routes.pickupTypes')),
        jobStatus: jobStatus.pickupStatusType.name,
        jobStatusId: jobStatus.pickupStatusType.id,
        jobStatusName: translate(createTranslationKey(jobStatus.pickupStatusType.name, 'common.pickupStatuses')),
        serviceDate: jobStatus.jobConfirmationTimestamp,
        isOnHoldJob,
      };
    },
  );
};

export const transformLoadRouteJobs = (route: Route) => {
  const jobs = transformLoadDispatchBoardJobs(route.routeJobs);

  return { jobs, routeDetails: { ...route.routeCountInfo } };
};
