import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../store';
import translate from '../../../../core/services/translate';
import { Table } from '../../../../core/components';
import { VehicleTableWrapper } from '../../styled/VehiclesPage';
import { pmLogsCells } from './vehiclePageCells';
import VehiclePreventativeMaintenanceLogsTableRow from './VehiclePreventativeMaintenanceLogsTableRow';
import VehicleMoreIndicator from './VehicleMoreIndicator';
import VehiclePageSection from './VehiclePageSection';
import { useContainerScrollAtRockBottom } from '../../utils/vehiclePageHooks';

const PreventativeMaintenanceLogSection: React.FC = () => {
  const pmLogs = useSelector((state: AppState) => state.fleet.preventativeMaintenance.vehiclePMLogs);

  const { containerHasMaximumScroll, setRef, onScroll } = useContainerScrollAtRockBottom(pmLogs.length);

  return (
    <VehiclePageSection
      title={translate('vehicles.preventativeMaintenanceLog')}
      sectionDisabled={!pmLogs.length}
      subtitle={
        !pmLogs.length
          ? translate('vehicles.pmLogs.noPMLogs')
          : translate('vehicles.pmLogs.noOfPMLogs', { count: pmLogs.length })
      }
      content={
        <>
          {!!pmLogs.length && (
            <VehicleTableWrapper ref={setRef} onScroll={onScroll}>
              <Table cells={pmLogsCells} rows={pmLogs} rowComponent={VehiclePreventativeMaintenanceLogsTableRow} />
            </VehicleTableWrapper>
          )}

          <VehicleMoreIndicator disabled={containerHasMaximumScroll} />
        </>
      }
    />
  );
};

export default PreventativeMaintenanceLogSection;
