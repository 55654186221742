import axios from 'axios';
import FileSaver from 'file-saver';
import update from 'immutability-helper';
import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';

import { EXCEL_MIME_TYPES } from 'src/core/constants';
import { CustomerImportFile } from '../interfaces/CustomerImport';
import {
  loadCustomerImportUploadedFilesStatus as doLoadCustomerImportUploadedFilesStatus,
  uploadCustomersFile as doUploadCustomersFile,
} from '../services/customerImport';

//action types
const START_LOAD = 'customerImport/START_LOAD';
const COMPLETE_LOAD = 'customerImport/COMPLETE_LOAD';
const FAIL_LOAD = 'customerImport/FAIL_LOAD';
const START_UPLOAD = 'customerImport/START_UPLOAD';
const COMPLETE_UPLOAD = 'customerImport/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'customerImport/FAIL_UPLOAD';
const UPDATE_UPLOAD_PERCENT = 'customerImport/UPDATE_UPLOAD_PERCENT';
const RESET = 'customerImport/RESET';

interface State {
  isLoading: boolean;
  isUploading: boolean;
  uploadingPercent: number;
  uploadedFiles: CustomerImportFile[];
}

const initialState: State = {
  isLoading: false,
  isUploading: false,
  uploadingPercent: 0,
  uploadedFiles: [],
};

//reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        isLoading: { $set: true },
      });
    case COMPLETE_LOAD:
      return update(state, {
        isLoading: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });
    case FAIL_LOAD:
      return update(state, {
        isLoading: { $set: false },
      });
    case START_UPLOAD:
      return update(state, {
        isUploading: { $set: true },
      });
    case COMPLETE_UPLOAD:
      return update(state, {
        isUploading: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });
    case FAIL_UPLOAD:
      return update(state, {
        isUploading: { $set: false },
      });
    case RESET:
      return initialState;
    default:
      return state;
  }
};

//action creators
const startLoad = () => ({ type: START_LOAD });
const completeLoad = (uploadedFiles: CustomerImportFile[]) => ({ type: COMPLETE_LOAD, uploadedFiles });
const failLoad = () => ({ type: FAIL_LOAD });
const startUpload = () => ({ type: START_UPLOAD });
const completeUpload = (uploadedFiles: CustomerImportFile[]) => ({ type: COMPLETE_UPLOAD, uploadedFiles });
const failUpload = () => ({ type: FAIL_UPLOAD });

// thunks
export const resetCustomerImport = () => ({ type: RESET });

export const loadCustomersImportUploadedFilesStatus = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadCustomersImportUploadedFilesStatusPromise = doLoadCustomerImportUploadedFilesStatus(vendorId);

  loadCustomersImportUploadedFilesStatusPromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeLoad(uploadedFiles));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadCustomersImportUploadedFilesStatusPromise;
};

export const uploadCustomersImportFile = (fileData: any, vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadCustomersFilePromise = doUploadCustomersFile(fileData, vendorId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadCustomersFilePromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeUpload(uploadedFiles));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadCustomersFilePromise;
};

export const downloadCustomersImportFile =
  (fileDownloadUrl: string, fileName: string, date?: string) => async (dispatch: Dispatch) =>
    axios.get(fileDownloadUrl, { responseType: 'arraybuffer' }).then(response => {
      const fileExtension = fileDownloadUrl.substring(fileDownloadUrl.lastIndexOf('.'));
      const blob =
        fileExtension === 'csv'
          ? new Blob([response.data], { type: 'text/csv;charset=utf-8' })
          : new Blob([response.data], {
              type: EXCEL_MIME_TYPES,
            });
      FileSaver.saveAs(blob, date ? `${moment(date).format('MM-DD-YYYY_HH_mm_ss')}-${fileName}` : `${fileName}`);
    });
