import React from 'react';
import moment from 'moment';

import {
  BlogPostContainer,
  BlogPostDate,
  BlogPostImageContainer,
  BlogPostImageStub,
  BlogPostInfoContainer,
  BlogPostTitle,
  ReadMoreUrl,
  BlogPostExcerpt,
} from './styled/BlogPost';
import { BlogPostType } from '../interfaces/news';
import NoBlogPhotoAvatar from '../assets/NoBlogPhotoAvatar.png';
import translate from '../../core/services/translate';

type Props = {
  blogPost: BlogPostType;
  flip: boolean;
  category?: string;
};

export default function BlogPost({ blogPost, flip, category }: Props) {
  const dateFormat = category ? 'MMMM DD, YYYY' : 'MMMM, YYYY';
  const categoryName = category ? `${category} •` : '';

  return (
    <BlogPostContainer hasRightMargin={flip}>
      {blogPost.image ? <BlogPostImageContainer src={blogPost.image} /> : <BlogPostImageStub src={NoBlogPhotoAvatar} />}
      <BlogPostInfoContainer>
        <BlogPostDate>
          {categoryName} {moment(blogPost.publishDate).format(dateFormat)}
        </BlogPostDate>
        <BlogPostTitle>{blogPost.title}</BlogPostTitle>
        <BlogPostExcerpt>
          {blogPost.summary ? `${blogPost.summary.substr(0, 60)} ... ` : ''}
          <ReadMoreUrl target="_blank" href={blogPost.url}>
            {translate('account.readMore')}
          </ReadMoreUrl>
        </BlogPostExcerpt>
      </BlogPostInfoContainer>
    </BlogPostContainer>
  );
}
