import { FC } from 'react';
import { formValueSelector } from 'redux-form';

import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import { Dropdown, Input, Switch } from 'src/core/components';
import { Grid, GridColumn, Separator, Text, PanelSection } from '../../../core/components/styled';
import {
  isRequired,
  maxValueNumeric9999_99,
  minValueNumeric1,
  optionalMinValueNumeric1,
} from 'src/utils/services/validator';
import { PERIOD_TYPES } from 'src/vendors/constants/bulkyItemScheduler';
import { TypedField } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector(BULKY_ITEM_SCHEDULER_FORM);

type Props = {};

const BulkyItemSchedulerServiceLimitsByLocationSection: FC<Props> = () => {
  const isEnabledMaxItemsPerRequest = useSelector(state => formSelector(state, 'isEnabledMaxItemsPerRequest'));
  const isEnabledMaxItemsPerLocation = useSelector(state => formSelector(state, 'isEnabledMaxItemsPerLocation'));

  const periodTypes = PERIOD_TYPES.map(type => ({
    label: type.name,
    value: type.id,
  }));

  return (
    <GridColumn size="12/12" padding="no xSmall small xSmall">
      <Text block size="large" weight="medium" margin="no no xSmall no">
        {translate('vendors.bulkyItemScheduler.serviceLimitsByLocation')}
      </Text>
      <Grid multiLine>
        <GridColumn size="3/12" padding="xSmall xSmall small no" alignVerticalCenter>
          <TypedField
            name="isEnabledMaxItemsPerRequest"
            component={Switch}
            props={{
              label: translate('vendors.bulkyItemScheduler.maxItemsPerRequest'),
              margin: 'no',
            }}
          />
        </GridColumn>

        <GridColumn size="9/12" padding="xSmall no small no" alignVerticalCenter>
          <PanelSection>
            <TypedField
              name="maxItemsPerRequest"
              component={Input}
              props={{
                margin: 'no no no lMedium',
                placeholder: '0',
                type: 'number',
                width: '60px',
              }}
              validate={
                isEnabledMaxItemsPerRequest
                  ? [isRequired, minValueNumeric1, maxValueNumeric9999_99]
                  : [optionalMinValueNumeric1, maxValueNumeric9999_99]
              }
            />
            <Text block padding="no small xxSmall" flex flexAlignCenter>
              {translate('vendors.bulkyItemScheduler.items')}
            </Text>
          </PanelSection>
        </GridColumn>

        <GridColumn size="3/12" padding="xSmall xSmall small no" alignVerticalCenter>
          <TypedField
            name="isEnabledMaxItemsPerLocation"
            component={Switch}
            props={{
              label: translate('vendors.bulkyItemScheduler.maxLocationPickups'),
              margin: 'no',
            }}
          />
        </GridColumn>

        <GridColumn size="2/12" padding="xSmall no small no" alignVerticalCenter>
          <PanelSection>
            <TypedField
              name="maxPickupPerLocation"
              component={Input}
              props={{
                margin: 'no no no lMedium',
                placeholder: '0',
                type: 'number',
                width: '60px',
              }}
              validate={
                isEnabledMaxItemsPerLocation
                  ? [isRequired, minValueNumeric1, maxValueNumeric9999_99]
                  : [optionalMinValueNumeric1, maxValueNumeric9999_99]
              }
            />
            <Text block padding="no no xxSmall small" flex flexAlignCenter>
              {translate('vendors.bulkyItemScheduler.itemsPer')}
            </Text>
          </PanelSection>
        </GridColumn>

        <GridColumn size="2/12" padding="xSmall no small xxSmall" alignVerticalCenter>
          <TypedField
            name="maxPickupPerLocationPeriodTypeId"
            component={Dropdown}
            props={{
              options: periodTypes,
              margin: 'no ',
              placeholder: translate('vendors.bulkyItemScheduler.timeRange'),
            }}
            validate={isEnabledMaxItemsPerLocation ? [isRequired] : []}
          />
        </GridColumn>

        <GridColumn size="12/12" padding="xSmall xSmall small no" alignVerticalCenter>
          <TypedField
            name="allowResetPickupsPerLocation"
            component={Switch}
            props={{
              label: translate('vendors.bulkyItemScheduler.allowResetPickupsPerLocation'),
              margin: 'no',
            }}
          />
        </GridColumn>
      </Grid>

      <Separator color="grayLight" size={2} margin="sMedium no xSmall no" />
    </GridColumn>
  );
};

export default BulkyItemSchedulerServiceLimitsByLocationSection;
