import { Dispatch, AnyAction } from 'redux';
import { findIndex } from 'lodash-es';
import update from 'immutability-helper';

import { WaterFillsState, WaterFills } from '../interfaces/WaterFills';
import {
  createWaterFill,
  updateWaterFill,
  deleteWaterFill as doDeleteWaterFill,
  loadWaterFill as doLoadWaterFill,
  loadWaterFills as doLoadWaterFills,
  loadStreetSweeperMaterialTypeValuesWaterFill as doLoadStreetSweeperMaterialTypeValuesWaterFill,
  loadStreetSweeperUnitOfMeasureValuesWaterFill as doLoadStreetSweeperUnitOfMeasureValuesWaterFill,
  uploadWaterFillsImage as doUploadWaterFillsImage,
} from '../services/waterFills';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'routes/waterFills/START_LOAD';
const COMPLETE_LOAD = 'routes/waterFills/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/waterFills/FAIL_LOAD';
const START_SAVE = 'routes/waterFills/START_SAVE';
const COMPLETE_SAVE = 'routes/waterFills/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/waterFills/FAIL_SAVE';
const START_LOAD_LOAD_DUMPED = 'routes/waterFills/START_LOAD_LOAD_DUMPED';
const COMPLETE_LOAD_LOAD_DUMPED = 'routes/waterFills/COMPLETE_LOAD_LOAD_DUMPED';
const FAIL_LOAD_LOAD_DUMPED = 'routes/waterFills/FAIL_LOAD_LOAD_DUMPED';
const START_DELETE = 'routes/waterFills/START_DELETE';
const COMPLETE_DELETE = 'routes/waterFills/COMPLETE_DELETE';
const FAIL_DELETE = 'routes/waterFills/FAIL_DELETE';
const RESET = 'routes/waterFills/RESET';
const START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE = 'routes/waterFills/START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE';
const COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE = 'routes/waterFills/COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE';
const FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE = 'routes/waterFills/FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE';
const START_LOAD_STREET_SWEEPER_MATERIAL_TYPE = 'routes/waterFills/START_LOAD_STREET_SWEEPER_MATERIAL_TYPE';
const COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE = 'routes/waterFills/COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE';
const FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE = 'routes/waterFills/FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE';
const START_UPLOAD_WATER_FILLS_IMAGE = 'routes/weightTicket/START_UPLOAD_WATER_FILLS_IMAGE';
const COMPLETE_UPLOAD_WATER_FILLS_IMAGE = 'routes/weightTicket/COMPLETE_UPLOAD_WATER_FILLS_IMAGE';
const FAIL_UPLOAD_WATER_FILLSD_IMAGE = 'routes/weightTicket/FAIL_UPLOAD_WATER_FILLSD_IMAGE';

// Initial state
const initialState: WaterFillsState = {
  isDeleting: false,
  isLoading: false,
  isLoadingMaterialType: false,
  isLoadingUnitOfMeasure: false,
  isLoadingWaterFill: false,
  isSaving: false,
  isUploadingWaterFillsImage: false,
  materialTypeValues: [],
  unitOfMeasureValues: [],
  waterFill: {} as WaterFills,
  waterFills: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          waterFills: action.waterFills,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          waterFills: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          waterFill: action.waterFill,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          waterFill: undefined,
        },
      });

    case START_LOAD_LOAD_DUMPED:
      return update(state, {
        $merge: {
          isLoadingWaterFill: true,
        },
      });

    case COMPLETE_LOAD_LOAD_DUMPED:
      return update(state, {
        $merge: {
          isLoadingWaterFill: false,
          waterFill: action.waterFill,
        },
      });

    case FAIL_LOAD_LOAD_DUMPED:
      return update(state, {
        $merge: {
          isLoadingWaterFill: false,
          waterFill: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const waterFillIndex = findIndex(state.waterFills, { id: action.waterFillId });
      return update(state, {
        waterFills: { $splice: [[waterFillIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: true,
        },
      });

    case COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: false,
          unitOfMeasureValues: action.unitOfMeasureValues,
        },
      });

    case FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitOfMeasure: false,
          unitOfMeasureValues: undefined,
        },
      });

    case START_LOAD_STREET_SWEEPER_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: true,
        },
      });

    case COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: false,
          materialTypeValues: action.materialTypeValues,
        },
      });

    case FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE:
      return update(state, {
        $merge: {
          isLoadingMaterialType: false,
          materialTypeValues: undefined,
        },
      });

    case START_UPLOAD_WATER_FILLS_IMAGE:
      return update(state, {
        $merge: {
          isUploadingWaterFillsImage: true,
        },
      });

    case COMPLETE_UPLOAD_WATER_FILLS_IMAGE:
      return update(state, {
        $merge: {
          isUploadingWaterFillsImage: false,
        },
      });

    case FAIL_UPLOAD_WATER_FILLSD_IMAGE:
      return update(state, {
        $merge: {
          isUploadingWaterFillsImage: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (waterFills: WaterFills[]) => ({
  type: COMPLETE_LOAD,
  waterFills,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (waterFill: WaterFills) => ({
  type: COMPLETE_SAVE,
  waterFill,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadWaterFill = () => ({
  type: START_LOAD_LOAD_DUMPED,
});

const completeLoadWaterFill = (waterFill: WaterFills) => ({
  type: COMPLETE_LOAD_LOAD_DUMPED,
  waterFill,
});

const failLoadWaterFill = () => ({
  type: FAIL_LOAD_LOAD_DUMPED,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (waterFillId: number) => ({
  type: COMPLETE_DELETE,
  waterFillId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startLoadStreetSweeperUnitOfMeasureValues = () => ({
  type: START_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE,
});

const completeLoadStreetSweeperUnitOfMeasureValues = (unitOfMeasureValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE,
  unitOfMeasureValues,
});

const failLoadStreetSweeperUnitOfMeasureValues = () => ({
  type: FAIL_LOAD_STREET_SWEEPER_UNIT_OF_MEASURE,
});

const startLoadStreetSweeperMaterialTypeValues = () => ({
  type: START_LOAD_STREET_SWEEPER_MATERIAL_TYPE,
});

const completeLoadStreetSweeperMaterialType = (materialTypeValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_STREET_SWEEPER_MATERIAL_TYPE,
  materialTypeValues,
});

const failLoadStreetSweeperMaterialType = () => ({
  type: FAIL_LOAD_STREET_SWEEPER_MATERIAL_TYPE,
});

const startUploadWaterFillsImage = () => ({
  type: START_UPLOAD_WATER_FILLS_IMAGE,
});

const completeUploadWaterFillsImage = () => ({
  type: COMPLETE_UPLOAD_WATER_FILLS_IMAGE,
});

const failUploadWaterFillsImage = () => ({
  type: FAIL_UPLOAD_WATER_FILLSD_IMAGE,
});

export const saveWaterFill = (routeId: number, waterFill: WaterFills) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveWaterFillPromise = waterFill.id ? updateWaterFill(waterFill) : createWaterFill(routeId, waterFill);

  saveWaterFillPromise
    .then((waterFill: WaterFills) => dispatch(completeSave(waterFill)))
    .catch(() => dispatch(failSave()));
  return saveWaterFillPromise;
};

export const loadWaterFills = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadWaterFillsPromise = doLoadWaterFills(routeId);
  loadWaterFillsPromise.then(waterFills => dispatch(completeLoad(waterFills))).catch(() => dispatch(failLoad()));
  return loadWaterFillsPromise;
};

export const loadWaterFill = (waterFillId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadWaterFill());
  const loadWaterFillPromise = doLoadWaterFill(waterFillId);
  loadWaterFillPromise
    .then((waterFill: WaterFills) => dispatch(completeLoadWaterFill(waterFill)))
    .catch(() => dispatch(failLoadWaterFill()));
  return loadWaterFillPromise;
};

export const deleteWaterFill = (waterFillId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteWaterFillPromise = doDeleteWaterFill(waterFillId);
  deleteWaterFillPromise.then(() => dispatch(completeDelete(waterFillId))).catch(() => dispatch(failDelete()));
  return deleteWaterFillPromise;
};

export const loadStreetSweeperUnitOfMeasureValuesWaterFill = () => (dispatch: Dispatch) => {
  dispatch(startLoadStreetSweeperUnitOfMeasureValues());
  const loadStreetSweeperUnitOfMeasureValuesPromise = doLoadStreetSweeperUnitOfMeasureValuesWaterFill();
  loadStreetSweeperUnitOfMeasureValuesPromise
    .then((unitOfMeasureValues: TechnicalType[]) =>
      dispatch(completeLoadStreetSweeperUnitOfMeasureValues(unitOfMeasureValues)),
    )
    .catch(() => dispatch(failLoadStreetSweeperUnitOfMeasureValues()));
  return loadStreetSweeperUnitOfMeasureValuesPromise;
};

export const loadStreetSweeperMaterialTypeValuesWaterFill = () => (dispatch: Dispatch) => {
  dispatch(startLoadStreetSweeperMaterialTypeValues());
  const loadStreetSweeperMaterialTypePromise = doLoadStreetSweeperMaterialTypeValuesWaterFill();
  loadStreetSweeperMaterialTypePromise
    .then((materialTypeValues: TechnicalType[]) => dispatch(completeLoadStreetSweeperMaterialType(materialTypeValues)))
    .catch(() => dispatch(failLoadStreetSweeperMaterialType()));
  return loadStreetSweeperMaterialTypePromise;
};

export const uploadWaterFillsImage = (file: File, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startUploadWaterFillsImage());

  const uploadWaterFillsImagePromise = doUploadWaterFillsImage(file, routeId);
  uploadWaterFillsImagePromise
    .then(waterFillsImage => {
      dispatch(completeUploadWaterFillsImage());
      return waterFillsImage;
    })
    .catch(() => dispatch(failUploadWaterFillsImage()));
  return uploadWaterFillsImagePromise;
};

export const resetWaterFills = () => ({
  type: RESET,
});
