import React, { useState, useEffect, useRef } from 'react';
import humps from 'humps';

import { Button } from 'src/core/components/styled';
import { ContainerButton } from 'src/fleet/components/styled/Containers';
import { downloadOpenBidsTemplate, getUploadedFilesStatus, uploadOpenBidsFile } from '../../services/opportunities';
import { Modal, Table } from 'src/core/components';
import { OpenBidsUploadModalTableRow } from '../pages/OpenBidsUploadModalTableRow';
import RecordsUploaderModal from 'src/common/components/RecordsUploaderModal';
import translate from 'src/core/services/translate';
import { EXCEL_MIME_TYPES } from 'src/core/constants';
import { OpportunitiesSearchFilter } from '../../interfaces/Opportunities';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { Loader } from 'src/customers/components/styled/CustomerLocations';

interface Props {
  filterParams: OpportunitiesSearchFilter;
  vendorId: number;
  onClose: (hasSuccessfulImports: boolean) => void;
}

const tableCells = [
  {
    name: 'fileName',
    label: translate('customers.customerUpload.fileName'),
    width: '28%',
  },
  {
    name: 'status',
    label: translate('common.status'),
    width: '14%',
  },
  {
    name: 'numberOfRecords',
    label: translate('customers.customerUpload.numberOfRecords'),
    width: '13%',
  },
  {
    name: 'numberOfSuccessfulRecords',
    label: translate('customers.customerUpload.numberOfSuccessfulRecords'),
    width: '11%',
  },
  {
    name: 'numberOfFailedRecords',
    label: translate('customers.customerUpload.numberOfFailedRecords'),
    width: '8%',
  },
  {
    name: 'errorFileUrl',
    label: translate('customers.customerUpload.errorFile'),
    width: '10%',
  },
  {
    name: 'date',
    label: `${translate('common.date')} & ${translate('customers.time')}`,
    width: '16%',
  },
];

const ImportOpenBidsModal: React.SFC<Props> = props => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const refreshTimeoutId = useRef<number>(0);

  const refreshData = useRef(() => {
    refreshTimeoutId.current = window.setTimeout(() => {
      setIsRefreshing(true);
      getUploadedFilesStatus(props.vendorId)
        .then((result: any) => {
          setUploadedFiles(result.data.uploadStats);
          setIsRefreshing(false);
          refreshData.current();
        })
        .catch(() => setIsRefreshing(false));
    }, 15000);
  });

  useEffect(() => {
    getUploadedFilesStatus(props.vendorId).then((result: any) => {
      setUploadedFiles(result.data.uploadStats || []);
      setIsDataLoaded(true);
      if (result.data.uploadStats && result.data.uploadStats.length) {
        refreshData.current();
      }
    });
    return () => window.clearTimeout(refreshTimeoutId.current);
  }, [props.vendorId, refreshData, uploadedFiles.length]);

  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const hasAnySuccessfulUploads = useRef(false);

  const uploadFile = (file: File) => {
    return uploadOpenBidsFile(file, props.vendorId)
      .then(() => {
        hasAnySuccessfulUploads.current = true;
        createSuccessNotification(translate('customers.customerUpload.successMessage'));
      })
      .catch((e: any) => {
        const errorMessageCode =
          e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);
        createErrorNotification(
          errorMessageCode
            ? translate(`customers.alertMessages.${errorMessageCode}`)
            : translate('customers.customerUpload.errorMessage'),
        );
        setIsUploading(false);
        throw e;
      });
  };

  const onFileInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target && e.target.files) {
      uploadFile(e.target.files[0]);
      e.target.value = '';
    }
  };

  const downloadSampleTemplate = () => {
    const { filterParams, vendorId } = props;
    return downloadOpenBidsTemplate(vendorId, filterParams);
  };

  const getUploaderModal = () =>
    !uploadedFiles.length ? (
      <RecordsUploaderModal
        {...props}
        downloadSample={downloadSampleTemplate}
        parentTranslationsPath="opportunity.bidUpload"
        uploadFile={uploadFile}
        willUnmountAfterUpload
        onClose={() => props.onClose(hasAnySuccessfulUploads.current)}
      />
    ) : (
      <Modal
        size="xLarge"
        title={translate('opportunity.opportunities.importOpenBids')}
        onClose={() => props.onClose(hasAnySuccessfulUploads.current)}
      >
        {isUploading && <PageLoadingOverlay />}

        <Table cells={tableCells} rows={uploadedFiles} rowComponent={OpenBidsUploadModalTableRow} />
        <ContainerButton>
          {isRefreshing && <Loader />}
          <Button
            color="primary"
            margin="small no no small"
            onClick={() => (fileUploaderRef.current as HTMLInputElement).click()}
            type="button"
          >
            {translate('common.chooseFile')}
          </Button>
          <input
            accept={EXCEL_MIME_TYPES}
            id="fileSelect"
            onChange={onFileInputChanged}
            ref={fileUploaderRef}
            style={{ display: 'none' }}
            type="file"
          />
          <Button color="primary" margin="no no no small" line onClick={() => downloadSampleTemplate()}>
            {translate('opportunity.opportunities.exportOpenBids')}
          </Button>
        </ContainerButton>
      </Modal>
    );

  return isDataLoaded ? getUploaderModal() : <PageLoadingOverlay />;
};

export default ImportOpenBidsModal;
