import { filter } from 'lodash-es';
import { httpInsightsReporting } from '../../core/services/http';

export const loadVendorLocations = (vendorId: number, vendorLocationTypes: any) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/dashboardHaulerLocations`, {
      params: { haulerLocationTypes: vendorLocationTypes.join(',') },
    })
    .then(response => response.data);

export const loadHaulerLocationsForMapbox = (vendorLocationTypes?: string, includeInactiveFacilities?: boolean) =>
  httpInsightsReporting
    .get(`dashboard/hauler-locations`, {
      params: { locationTypeIds: vendorLocationTypes, includeInactiveFacilities },
    })
    .then(response => filter(response.data, r => r.customerSubTypeId !== null));
