import React from 'react';

import { DeleteCustomerLocationOrServiceForm } from '../forms';
import { Modal } from '../../../core/components';
import { ModalTitle } from '../../../core/components/styled';

type Props = {
  checkMethod: (selectedId: number, vendorId: number) => Promise<any>;
  modalTitle: string;
  onCancel: () => void;
  onDelete: (shouldRecreateRoutes: boolean) => void;
  selectedId: number;
  vendorId: number;
};

const DeleteCustomerLocationOrServiceModal: React.FC<Props> = ({
  checkMethod,
  modalTitle,
  onCancel,
  onDelete,
  selectedId,
  vendorId,
}) => (
  <Modal padding="medium" size="small" overflow="hidden">
    <ModalTitle>{modalTitle}</ModalTitle>
    <DeleteCustomerLocationOrServiceForm
      checkMethod={checkMethod}
      onCancel={onCancel}
      onDelete={onDelete}
      selectedId={selectedId}
      vendorId={vendorId}
    />
  </Modal>
);

export default DeleteCustomerLocationOrServiceModal;
