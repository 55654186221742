import { identity, get } from 'lodash-es';
import { createSelector } from 'reselect';
import moment from 'moment';

import { ContainerState } from '../interfaces/containers';

const getContainerEditorNewFormInitialValues = (containerState: ContainerState, vendorId: number) => ({
  vendorId,
  equipmentTypeId: get(containerState, 'containerNew.equipmentType.id', null),
  equipmentSizeId: get(containerState, 'containerNew.equipmentSize.id', null),
  equipmentConditionId: get(containerState, 'containerNew.equipmentCondition.id', null),
  equipmentCondition: get(containerState, 'containerNew.equipmentCondition', null),
  wasteMaterialType: get(containerState, 'containerNew.wasteMaterialType', null),
  wasteMaterialTypeId: get(containerState, 'containerNew.wasteMaterialType.id', null),
  serviceType: get(containerState, 'containerNew.serviceType', null),
  warrantyExpirationDate: moment(get(containerState, 'containerNew.warrantyExpirationDate', null)).format('MM/DD/YYYY'),
  ...get(containerState, 'containerNew', {}),
});

const containerEditorNewFormInitialValuesSelector = createSelector(getContainerEditorNewFormInitialValues, identity);

export default containerEditorNewFormInitialValuesSelector;
