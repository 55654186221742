import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { BlogPostType, TwitterPostType } from '../interfaces/news';
import {
  loadBlogPosts as doLoadBlogPosts,
  loadEnvironmentSafetyBlogPosts as doLoadEnvironmentSafetyBlogPosts,
  loadPublicPolicyPosts as doLoadPublicPolicyPosts,
  loadTwitterPosts as doLoadTwitterPosts,
} from '../services/news';

// Actions
const START_LOAD_BLOG_POSTS = 'account/news/START_LOAD_BLOG_POSTS';
const COMPLETE_LOAD_BLOG_POSTS = 'account/news/COMPLETE_LOAD_BLOG_POSTS';
const FAIL_LOAD_BLOG_POSTS = 'account/news/FAIL_LOAD_BLOG_POSTS';
const START_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS = 'account/news/START_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS';
const COMPLETE_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS = 'account/news/COMPLETE_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS';
const FAIL_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS = 'account/news/FAIL_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS';
const START_LOAD_PUBLIC_POLICY_POSTS = 'account/news/START_LOAD_PUBLIC_POLICY_POSTS';
const COMPLETE_LOAD_PUBLIC_POLICY_POSTS = 'account/news/COMPLETE_LOAD_PUBLIC_POLICY_POSTS';
const FAIL_LOAD_PUBLIC_POLICY_POSTS = 'account/news/FAIL_LOAD_PUBLIC_POLICY_POSTS';
const START_LOAD_TWITTER_POSTS = 'account/news/START_LOAD_TWITTER_POSTS';
const COMPLETE_LOAD_TWITTER_POSTS = 'account/news/COMPLETE_LOAD_TWITTER_POSTS';
const FAIL_LOAD_TWITTER_POSTS = 'account/news/FAIL_LOAD_TWITTER_POSTS';
const RESET = 'account/news/RESET';

type NewsState = {
  blogPosts: BlogPostType[];
  environmentSafetyBlogPosts: BlogPostType[];
  isLoadingBlogPosts: boolean;
  isLoadingEnvironmentSafetyBlogPosts: boolean;
  isLoadingPublicPolicyPosts: boolean;
  isLoadingTwitterPosts: boolean;
  publicPolicyPosts: BlogPostType[];
  twitterPosts: TwitterPostType[];
};

// Initial state
const initialState: NewsState = {
  blogPosts: [],
  environmentSafetyBlogPosts: [],
  isLoadingBlogPosts: false,
  isLoadingEnvironmentSafetyBlogPosts: false,
  isLoadingPublicPolicyPosts: false,
  isLoadingTwitterPosts: false,
  publicPolicyPosts: [],
  twitterPosts: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_BLOG_POSTS:
      return update(state, {
        $merge: { isLoadingBlogPosts: true },
      });

    case COMPLETE_LOAD_BLOG_POSTS:
      return update(state, {
        $merge: { isLoadingBlogPosts: false, blogPosts: action.blogPosts },
      });

    case FAIL_LOAD_BLOG_POSTS:
      return update(state, {
        $merge: { isLoadingBlogPosts: false, blogPosts: [] },
      });

    case START_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS:
      return update(state, {
        $merge: { isLoadingEnvironmentSafetyBlogPosts: true },
      });

    case COMPLETE_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS:
      return update(state, {
        $merge: {
          isLoadingEnvironmentSafetyBlogPosts: false,
          environmentSafetyBlogPosts: action.environmentSafetyBlogPosts,
        },
      });

    case FAIL_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS:
      return update(state, {
        $merge: { isLoadingEnvironmentSafetyBlogPosts: false, environmentSafetyBlogPosts: [] },
      });

    case START_LOAD_PUBLIC_POLICY_POSTS:
      return update(state, {
        $merge: { isLoadingPublicPolicyPosts: true },
      });

    case COMPLETE_LOAD_PUBLIC_POLICY_POSTS:
      return update(state, {
        $merge: {
          isLoadingPublicPolicyPosts: false,
          publicPolicyPosts: action.publicPolicyPosts,
        },
      });

    case FAIL_LOAD_PUBLIC_POLICY_POSTS:
      return update(state, {
        $merge: { isLoadingPublicPolicyPosts: false, publicPolicyPosts: [] },
      });

    case START_LOAD_TWITTER_POSTS:
      return update(state, {
        $merge: { isLoadingTwitterPosts: true },
      });

    case COMPLETE_LOAD_TWITTER_POSTS:
      return update(state, {
        $merge: { isLoadingTwitterPosts: false, twitterPosts: action.twitterPosts },
      });

    case FAIL_LOAD_TWITTER_POSTS:
      return update(state, {
        $merge: { isLoadingTwitterPosts: false, twitterPosts: [] },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoadBlogPosts = () => ({
  type: START_LOAD_BLOG_POSTS,
});

const completeLoadBlogPosts = (blogPosts: BlogPostType[]) => ({
  type: COMPLETE_LOAD_BLOG_POSTS,
  blogPosts,
});

const failLoadBlogPosts = () => ({
  type: FAIL_LOAD_BLOG_POSTS,
});

const startLoadEnvironmentSafetyBlogPosts = () => ({
  type: START_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS,
});

const completeLoadEnvironmentSafetyBlogPosts = (environmentSafetyBlogPosts: BlogPostType[]) => ({
  type: COMPLETE_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS,
  environmentSafetyBlogPosts,
});

const failLoadEnvironmentSafetyBlogPosts = () => ({
  type: FAIL_LOAD_ENVIRONMENT_SAFETY_BLOG_POSTS,
});

const startLoadPublicPolicyPosts = () => ({
  type: START_LOAD_PUBLIC_POLICY_POSTS,
});

const completeLoadPublicPolicyPosts = (publicPolicyPosts: BlogPostType[]) => ({
  type: COMPLETE_LOAD_PUBLIC_POLICY_POSTS,
  publicPolicyPosts,
});

const failLoadPublicPolicyPosts = () => ({
  type: FAIL_LOAD_PUBLIC_POLICY_POSTS,
});

const startLoadTwitterPosts = () => ({
  type: START_LOAD_TWITTER_POSTS,
});

const completeLoadTwitterPosts = (twitterPosts: TwitterPostType[]) => ({
  type: COMPLETE_LOAD_TWITTER_POSTS,
  twitterPosts,
});

const failLoadTwitterPosts = () => ({
  type: FAIL_LOAD_TWITTER_POSTS,
});

export const loadBlogPosts = () => (dispatch: Dispatch) => {
  dispatch(startLoadBlogPosts());
  const loadBlogPostsPromise = doLoadBlogPosts();
  loadBlogPostsPromise
    .then(blogPosts => dispatch(completeLoadBlogPosts(blogPosts)))
    .catch(() => dispatch(failLoadBlogPosts()));
  return loadBlogPostsPromise;
};

export const loadEnvironmentSafetyBlogPosts = () => (dispatch: Dispatch) => {
  dispatch(startLoadEnvironmentSafetyBlogPosts());
  const loadEnvironmentSafetyBlogPostsPromise = doLoadEnvironmentSafetyBlogPosts();
  loadEnvironmentSafetyBlogPostsPromise
    .then(environmentSafetyBlogPosts => dispatch(completeLoadEnvironmentSafetyBlogPosts(environmentSafetyBlogPosts)))
    .catch(() => dispatch(failLoadEnvironmentSafetyBlogPosts()));
  return loadEnvironmentSafetyBlogPostsPromise;
};

export const loadPublicPolicyPosts = () => (dispatch: Dispatch) => {
  dispatch(startLoadPublicPolicyPosts());
  const loadPublicPolicyPostsPromise = doLoadPublicPolicyPosts();
  loadPublicPolicyPostsPromise
    .then(publicPolicyPosts => dispatch(completeLoadPublicPolicyPosts(publicPolicyPosts)))
    .catch(() => dispatch(failLoadPublicPolicyPosts()));
  return loadPublicPolicyPostsPromise;
};

export const loadTwitterPosts = () => (dispatch: Dispatch) => {
  dispatch(startLoadTwitterPosts());
  const loadTwitterPostsPromise = doLoadTwitterPosts();
  loadTwitterPostsPromise
    .then(twitterPosts => dispatch(completeLoadTwitterPosts(twitterPosts)))
    .catch(() => dispatch(failLoadTwitterPosts()));
  return loadTwitterPostsPromise;
};

export const resetNews = () => ({
  type: RESET,
});
