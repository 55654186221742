import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { InferenceAuditPage } from '.';
import { loadInferenceAudits, loadRunningVehicles } from '../../ducks';
import { loadPermissions, shouldLoadPermissionsSelector } from '../../../account/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { TODAY_FORMATTED } from '../../../core/constants';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadInferenceAudits: (vendorId: number, date: Date | string, vehicleId: number, status: string, page: number) => void;
  loadPermissions: () => void;
  loadRunningVehicles: (vendorId: number, date: Date | string) => void;
  shouldLoadPermissions: boolean;
  vendorId: number;
}

class InferenceAuditPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadInferenceAudits,
      loadPermissions,
      loadRunningVehicles,
      location: { search },
      shouldLoadPermissions,
      vendorId,
    } = this.props;

    const { date = TODAY_FORMATTED, vehicleId, status, page } = getQueryParams(search);

    const dependenciesWithoutPermissions = [
      loadRunningVehicles(vendorId, date),
      loadInferenceAudits(vendorId, date, vehicleId, status, page),
    ];

    return shouldLoadPermissions
      ? Promise.all([loadPermissions(), ...dependenciesWithoutPermissions])
      : Promise.all(dependenciesWithoutPermissions);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('inferenceAudit.inferenceAudit')}</DocumentTitle>
        <Resolver
          successComponent={InferenceAuditPage}
          successProps={{
            vendorId: this.props.vendorId,
          }}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: (shouldLoadPermissionsSelector as any)(state.account.login, state.account.permissions),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadInferenceAudits,
  loadPermissions,
  loadRunningVehicles,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InferenceAuditPageResolver));
