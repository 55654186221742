import moment from 'moment';

import { CalendarUnbilledCharge } from 'src/finance/interfaces/Billing';
import { currency } from 'src/utils/services/formatter';
import { Grid, GridColumn, Popover, Text } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import translate from 'src/core/services/translate';

interface Props {
  charge: CalendarUnbilledCharge;
}

const UnbilledChargesMonthlyEventItemPopoverContent: React.FC<Props> = ({ charge }) => (
  <Popover align="left" padding="small xxSmall">
    <Grid padding="no small no no" multiLine>
      <GridColumn size="12/12" margin="no no small">
        <Text block size="xLarge">
          {moment(charge.date).format('LL')}
        </Text>
      </GridColumn>
      <GridColumn size="4/12" margin="no no small" padding="no xxSmall xxSmall no" verticalAlign="center" borderBottom>
        <Text size="small" color="grayDarker" margin="no xxSmall no no">
          <Icon icon="onCall" width="15px" />
        </Text>
        {translate('finance.xOnCall', { number: charge.onCallWorkOrders })}
      </GridColumn>
      <GridColumn size="4/12" margin="no no small" padding="no xxSmall xxSmall no" verticalAlign="center" borderBottom>
        <Text size="small" color="grayDarker" margin="no xxSmall no no">
          <Icon icon="recurring" width="18px" />
        </Text>
        {translate('finance.xRecurring', { number: charge.recurringWorkOrders })}
      </GridColumn>
      <GridColumn size="4/12" margin="no no small" padding="no no xxSmall xxSmall" verticalAlign="center" borderBottom>
        <Text size="small" color="primary" margin="no xxSmall no no">
          <Icon icon="scheduled" width="18px" />
        </Text>
        {translate('finance.xScheduled', { number: charge.scheduledWorkOrders })}
      </GridColumn>

      <GridColumn size="6/12" margin="no no small" padding="no no no xxSmall">
        <Text block size="small" color="grayDark" margin="no">
          {translate('finance.totalUnbilled')}
        </Text>
        <Text weight="medium" block size="small" color="grayBase" margin="no">
          {currency(charge.totalAmount)}
        </Text>
      </GridColumn>
      <GridColumn size="6/12" margin="no no small" padding="no xxSmall no no">
        <Text block size="small" color="grayDark" margin="no">
          {translate('finance.totalServiceLocations')}
        </Text>
        <Text weight="medium" block size="small" color="grayBase" margin="no">
          {charge.serviceLocations}
        </Text>
      </GridColumn>

      <GridColumn size="6/12" margin="no no small" padding="no no no xxSmall">
        <Text block size="small" color="grayDark" margin="no">
          {translate('finance.workOrders')}
        </Text>
        <Text weight="medium" block size="small" color="grayBase" margin="no">
          {charge.totalWorkOrders}
        </Text>
      </GridColumn>
      <GridColumn size="6/12" margin="no no small" padding="no xxSmall no no">
        <Text block size="small" color="grayDark" margin="no">
          {translate('common.workOrderTypes.onCall')}
        </Text>
        <Text weight="medium" block size="small" color="grayBase" margin="no">
          {currency(charge.onCallAmount)}
        </Text>
      </GridColumn>

      <GridColumn size="6/12" margin="no no small" padding="no no no xxSmall">
        <Text block size="small" color="grayDark" margin="no">
          {translate('common.workOrderTypes.recurring')}
        </Text>
        <Text weight="medium" block size="small" color="grayBase" margin="no">
          {currency(charge.recurringAmount)}
        </Text>
      </GridColumn>
      <GridColumn size="6/12" margin="no no small" padding="no xxSmall no no">
        <Text block size="small" color="grayDark" margin="no">
          {translate('common.workOrderTypes.scheduled')}
        </Text>
        <Text weight="medium" block size="small" color="grayBase" margin="no">
          {currency(charge.scheduledAmount)}
        </Text>
      </GridColumn>
      <GridColumn size="6/12" margin="no no small" padding="no xxSmall no no">
        <Text block size="small" color="grayDark" margin="no">
          {translate('common.workOrderTypes.additionalCharges')}
        </Text>
        <Text weight="medium" block size="small" color="grayBase" margin="no">
          {currency(charge.additionalCharges)}
        </Text>
      </GridColumn>
    </Grid>
  </Popover>
);

export default UnbilledChargesMonthlyEventItemPopoverContent;
