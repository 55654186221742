import { map } from 'lodash-es';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadEquipmentConditions = (equipmentConditions: any[]) =>
  map(equipmentConditions, equipmentCondition => ({
    ...equipmentCondition,
    name: translate(createTranslationKey(equipmentCondition.technicalName, 'common.equipmentConditions')),
  }));

export default transformLoadEquipmentConditions;
