import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../../store';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { DuckFunction } from '../../../../contracts/ducks';
import { loadFeatures } from '../../../../vendors/ducks';
import { loadYRoute, loadRouteMapDetails } from '../../../ducks';
import { PageLoading } from '../../../../common/components/styled';
import { Resolver } from '../../../../core/components';
import { SCHEDULED } from '../../../constants';
import { shouldLoadPermissionsSelector, loadPermissions } from '../../../../account/ducks';
import translate from '../../../../core/services/translate';
import YRoutePage from './YRoutePage';

interface RouteParams {
  routeId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadRouteMapDetails: DuckFunction<typeof loadRouteMapDetails>;
  loadYRoute: DuckFunction<typeof loadYRoute>;
  shouldLoadPermissions: boolean;
  vendorId: number;
}

class YRoutePageResolver extends PureComponent<Props> {
  componentDidMount() {
    const { loadFeatures, vendorId } = this.props;
    loadFeatures(vendorId);
  }

  loadDependencies = async () => {
    const {
      match: {
        params: { routeId },
      },
      loadYRoute,
      loadRouteMapDetails,
      shouldLoadPermissions,
      loadPermissions,
      vendorId,
    } = this.props;

    let dependencies: Promise<any>[] = [];

    const { routeStatusTypeId } = await loadYRoute(routeId);
    const isYRoute = true;
    dependencies = routeStatusTypeId !== SCHEDULED ? [loadRouteMapDetails(vendorId, routeId, isYRoute)] : [];

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>
        <Resolver
          successComponent={YRoutePage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/routes/route-tracker"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadYRoute,
  loadRouteMapDetails,
  loadFeatures,
  loadPermissions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YRoutePageResolver));
