import { camelize } from 'humps';
import { map, upperFirst } from 'lodash-es';
import moment from 'moment';

import { BULK, PICKUP_STATUSES, PORTABLE_RESTROOM_TRAILER, PORTABLE_TOILET } from 'src/common/constants';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { dateTimeFormat } from 'src/utils/services/validator';

export const getEquipmentSize = (equipmentSize: string, equipmentName: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string, serviceTypeName: string, equipmentName: string) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

export const getServiceName = ({
  equipmentSizeTechnicalName,
  equipmentType,
  equipmentTypeTechnicalName,
  serviceType,
}: {
  equipmentSizeTechnicalName: string;
  equipmentType: string;
  equipmentTypeTechnicalName: string;
  serviceType: string;
}) => {
  if (equipmentSizeTechnicalName && equipmentType) {
    const size = getEquipmentSize(equipmentSizeTechnicalName, equipmentType);
    const equipmentSizeName = translate(
      getEquipmentSizeTranslationKey(equipmentSizeTechnicalName, serviceType, equipmentType),
      { size },
    );

    const equipmentTypeName = translate(createTranslationKey(equipmentTypeTechnicalName, 'common.equipmentTypes'));

    return `${equipmentSizeName} - ${equipmentTypeName}`;
  }
  return translate('common.notAvailable');
};

export const transformLoadServiceHistory = (serviceHistory: any) =>
  map(serviceHistory, ({ wasteMaterialTypeTechnicalName, serviceDateTime, pickupStatusTypeId, ...serviceEvent }) => ({
    ...serviceEvent,
    serviceName: getServiceName(serviceEvent),
    wasteMaterialTypeName: translate(createTranslationKey(wasteMaterialTypeTechnicalName, 'common.wasteTypes')),
    serviceDate: !!serviceDateTime ? moment(serviceDateTime).format(dateTimeFormat) : undefined,
    pickupStatus: PICKUP_STATUSES[pickupStatusTypeId].name,
    pickupStatusId: pickupStatusTypeId,
  }));

export const transformLoadAdhocServiceHistory = (response: any) => ({
  ...response,
  serviceHistory: transformLoadServiceHistory(response.serviceHistory),
});
