import React from 'react';

import { FooterLink, FooterMenuContainer } from './styled/LoginPage';
import translate from '../../core/services/translate';

const currentYear = new Date().getFullYear();
const footerMenuLinks = [
  {
    name: translate('landingPage.footer.additionalInfo.privacyPolicy'),
    link: 'https://www.rubicon.com/privacy-policy/',
  },
  {
    name: translate('landingPage.footer.additionalInfo.termsConditions'),
    link: 'https://www.rubicon.com/terms-conditions/',
  },
  { name: `© ${currentYear}, Rubicon.com`, link: 'https://www.rubicon.com/' },
];

export default function FooterMenu() {
  return (
    <FooterMenuContainer>
      {footerMenuLinks.map(page => (
        <FooterLink key={page.link} href={page.link} target="_blank">
          {page.name}
        </FooterLink>
      ))}
    </FooterMenuContainer>
  );
}
