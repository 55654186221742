import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { loadPermissions, shouldLoadPermissionsSelector } from 'src/account/ducks';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { loadServiceTypes } from 'src/common/ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { Resolver } from 'src/core/components';
import translate from 'src/core/services/translate';
import { loadCustomer, loadCustomerLocations } from 'src/customers/ducks';
import { AppState } from 'src/store';
import { checkIsBillingActive, loadVendor } from 'src/vendors/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import CustomerDetailsPage from './CustomerDetailsPage';

interface MatchParams {
  customerId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  loadCustomer: DuckFunction<typeof loadCustomer>;
  loadCustomerLocations: DuckFunction<typeof loadCustomerLocations>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadServiceTypes: DuckFunction<typeof loadServiceTypes>;
  loadVendor: DuckFunction<typeof loadVendor>;
  checkIsBillingActive: DuckFunction<typeof checkIsBillingActive>;
  shouldLoadPermissions: boolean;
  vendorId?: number;
  isBillingFeatureActive?: boolean
}

class CustomerDetailsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      match: {
        params,
      },
      loadCustomer,
      loadServiceTypes,
      loadCustomerLocations,
      shouldLoadPermissions,
      loadPermissions,
      vendorId,
      loadVendor,
      checkIsBillingActive,
    } = this.props;
    const customerId = Number(params.customerId);

    const dependencies = customerId
      ? [loadCustomer(customerId), loadServiceTypes(), loadCustomerLocations(customerId)]
      : [];

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    if (vendorId) {
      dependencies.push(loadVendor(vendorId));
      dependencies.push(checkIsBillingActive(vendorId));
    }

    return Promise.all(dependencies);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('customers.customerDetails')}</DocumentTitle>
        <Resolver
          successComponent={CustomerDetailsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/customers/customers"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorId(state),
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
});

const mapDispatchToProps = { loadCustomer, loadServiceTypes, loadCustomerLocations, loadPermissions, loadVendor, checkIsBillingActive };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPageResolver));
