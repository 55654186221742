import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppState } from './../../../store';
import { createErrorNotification } from '../../../core/services/createNotification';
import { loadLastKnownLocation } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { reverseGeocode } from '../../../customers/ducks/locations';
import { TypedResolver } from '../../../core/components';
import LastKnownLocationModal from './LastKnownLocationModal';
import translate from '../../../core/services/translate';

interface Props {
  closeModal(): void;
  deviceId?: string;
  vendorId: string;
  deviceName?: string;
}

const LastKnownLocationModalResolver: React.FC<Props> = ({ closeModal, deviceId, deviceName, vendorId }) => {
  const dispatch = useDispatch();

  const [lastKnowLocationAddress, setLastKnowLocationAddress] = useState<string>();

  const isReverseGeocodeLoading = useSelector((state: AppState) => state.customers.locations.isLoading);
  const lastKnownLocation = useSelector((state: AppState) => state.settings.lastKnownLocation.lastKnownLocation);

  const loadDependencies = React.useCallback(() => {
    let dependencies = [];

    if (deviceId)
      dependencies.push(
        loadLastKnownLocation(deviceId, vendorId)(dispatch)
          .then(response => {
            const { latitude, longitude } = response;

            if (latitude !== 0 && longitude !== 0)
              reverseGeocode(
                latitude,
                longitude,
              )(dispatch).then(response => {
                const { line1 } = response;
                setLastKnowLocationAddress(line1);
              });
          })
          .catch(() => {
            closeModal();
            createErrorNotification(translate('settings.alertMessages.loadLastKnownLocationError'));
          }),
      );

    return Promise.all(dependencies);
  }, [closeModal, deviceId, vendorId, dispatch]);

  return (
    <TypedResolver
      successComponent={LastKnownLocationModal}
      loadingComponent={PageLoadingOverlay}
      successProps={{
        closeModal,
        deviceId,
        deviceName,
        isReverseGeocodeLoading,
        lastKnowLocationAddress,
        lastKnownLocation,
      }}
      resolve={loadDependencies}
    />
  );
};

export default LastKnownLocationModalResolver;
