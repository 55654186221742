import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';
import { RubiconServiceRate } from '../interfaces/RubiconServices';
import { loadRubiconServiceRates as doLoadRubiconServicesRates } from '../services/rubiconServicesRates';

interface State {
  isLoading: boolean;
  rubiconServiceRates: RubiconServiceRate[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Actions
const START_LOAD = 'customers/rubiconServicesRates/START_LOAD';
const COMPLETE_LOAD = 'customers/rubiconServicesRates/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/rubiconServicesRates/FAIL_LOAD';
const RESET = 'customers/rubiconServicesRates/RESET';

// Initial state
const initialState = {
  isLoading: false,
  rubiconServiceRates: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rubiconServiceRates: action.rubiconServiceRates,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rubiconServiceRates: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rubiconServiceRates: RubiconServiceRate[]) => ({
  type: COMPLETE_LOAD,
  rubiconServiceRates,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRubiconServiceRates =
  (serviceId: number, serviceStatus: string, vendorId: string, userId: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRubiconServiceRatesPromise = doLoadRubiconServicesRates(serviceId, serviceStatus, vendorId, userId);

    loadRubiconServiceRatesPromise.then(services => dispatch(completeLoad(services))).catch(() => dispatch(failLoad()));

    return loadRubiconServiceRatesPromise;
  };
export const resetRubiconServiceRates = () => ({
  type: RESET,
});
