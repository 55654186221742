import { identity, mapKeys } from 'lodash-es';
import { createSelector } from 'reselect';

const getTimeManagementSettingsFormInitialValuesSelector = (timeManagementSettingsState: any) =>
  mapKeys(timeManagementSettingsState.timeManagementSettings, ({ vehicleTypeId }) => vehicleTypeId);

const timeManagementSettingsFormInitialValuesSelector = createSelector(
  getTimeManagementSettingsFormInitialValuesSelector,
  identity,
);

export default timeManagementSettingsFormInitialValuesSelector;
