import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import * as Services from '../services/roadConditions';
import { RoadSegmentCondition } from '../interfaces/roadConditions';

/**
 * Actions
 */
const SET_CONDITIONS_LOADING = 'dashboard/roadConditions/SET_CONDITIONS_LOADING';
const SET_CONDITIONS = 'dashboard/roadConditions/SET_CONDITIONS';
const RESET = 'dashboard/roadConditions/RESET';

/**
 * Initial state
 */
interface RoadConditionsState {
  isLoading: boolean;
  oldRoadConditions: GeoJSON.Feature<GeoJSON.LineString, RoadSegmentCondition>[];
  roadConditions: RoadSegmentCondition[];
}

const initialState: RoadConditionsState = {
  isLoading: false,
  roadConditions: [],
  oldRoadConditions: [],
};

/**
 * Reducer
 * @param state
 * @param action
 */
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_CONDITIONS_LOADING:
      return update(state, {
        $merge: {
          isLoading: action.loading,
        },
      });

    case SET_CONDITIONS:
      return update(state, {
        $merge: {
          roadConditions: action.conditions,
          oldRoadConditions: action.features,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

/**
 * Action creators
 */
const setConditionsLoading = (loading: boolean) => ({
  type: SET_CONDITIONS_LOADING,
  loading,
});

/**
 * TODO
 */
// const setConditions = (conditions: RoadSegmentCondition[]) => ({
const setConditions = (features: GeoJSON.Feature<GeoJSON.LineString, RoadSegmentCondition>[]) => ({
  type: SET_CONDITIONS,
  conditions: features.map(feature => ({
    ...feature.properties,
    lineSegment: feature.geometry.coordinates,
  })),
  features,
});

export const resetRoadConditions = () => ({
  type: RESET,
});

/**
 * Complex action creators
 */
export const loadRoadConditions = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(setConditionsLoading(true));

  const promise = Services.loadRoadConditions(vendorId);

  promise
    .then(conditions => {
      dispatch(setConditions(conditions));
    })
    .finally(() => {
      dispatch(setConditionsLoading(false));
    });

  return promise;
};
