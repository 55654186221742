import { http } from '../../core/services/http';
import transformDailyReportTypes from './transformDailyReportTypes';

const loadDailyReportTypes = (vendorId: number) =>
  http
    .get(`/reports/${vendorId}/dailyReportTypes`, { params: { vendorId } })
    .then(response =>
      transformDailyReportTypes(response.data).sort((a, b) => (a.technicalName > b.technicalName ? 1 : -1)),
    );
export default loadDailyReportTypes;
