import React from 'react';

import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import { Grid, GridColumn, PanelSection, Button, ButtonSet } from '../../../core/components/styled';
import { Input, Checkbox } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import { serviceZoneFormInitialValuesSelector } from '../../services/serviceZoneFormInitialValuesSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
  serviceZoneId?: number;
}

interface FormData {
  name: string;
  isActive: boolean;
}

type Props = ComponentProps & InjectedFormProps<FormData, ComponentProps>;

const ServiceZoneForm: React.FC<Props> = ({ handleSubmit, pristine, onCancel }) => (
  <PanelSection padding="small xSmall">
    <Grid multiLine centered>
      <GridColumn size="8/12">
        <form noValidate onSubmit={handleSubmit}>
          <Field
            name="name"
            component={Input}
            label={translate('routes.serviceZone')}
            margin="small no"
            validate={[isRequired]}
          />
          <Field name="isActive" component={Checkbox} label={translate('common.active')} />
          <ButtonSet margin="large auto no">
            <Button type="submit" id="service-zone-save-button" color="primary">
              {translate('common.save')}
            </Button>
            <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
              {translate('common.cancel')}
            </Button>
          </ButtonSet>
        </form>
      </GridColumn>
    </Grid>
  </PanelSection>
);

const mapStateToProps = (state: AppState, { serviceZoneId }: ComponentProps) => ({
  initialValues: (serviceZoneFormInitialValuesSelector as any)(state.routes.serviceZones as any, serviceZoneId),
});

export default connect(mapStateToProps)(
  reduxForm<FormData, ComponentProps>({
    form: 'serviceZoneEditor',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(ServiceZoneForm),
);
