import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface Props {
  isReadyToDrop?: boolean;
  position?: string;
}

export const RouteSequenceStopDropZone = styled.div<Props>`
  width: 100%;
  height: 25px;
  border: solid transparent;
  ${props =>
    props.position === 'top' &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      border-width: 4px 0 0;
    `};
  ${props =>
    props.position === 'bottom' &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      border-width: 0 0 4px;
    `};
  ${props =>
    props.position === 'single' &&
    css`
      height: 90px;
      border-width: 4px 0;
    `};
  ${props =>
    props.isReadyToDrop &&
    css`
      background-color: ${props => transparentize(0.5, props.theme.brandPrimary)};
    `};
`;
