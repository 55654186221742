import { useEffect } from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';

import ServiceContainersClustersGLSource, {
  SERVICE_CONTAINERS_CLUSTERS_LAYER,
  SERVICE_CONTAINERS_CLUSTERS_SOURCE,
} from './ServiceContainersClustersSource';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import mapPin from 'src/common/assets/img/triggers/stationary-trigger.png';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';

type Props<P extends object = {}> = {
  map: mapboxgl.Map;
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
  setViewport: (viewport: MapGLViewport) => void;
};

const mapImage = [{ id: 'mapPin', url: mapPin, sourceId: SERVICE_CONTAINERS_CLUSTERS_SOURCE }];

const mapImageOptions = { addLayers: false };

export default function ServiceContainersClustersGL({ geoJSON, map, setViewport }: Props) {
  useMapImages(mapImage, map, mapImageOptions);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', SERVICE_CONTAINERS_CLUSTERS_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [SERVICE_CONTAINERS_CLUSTERS_LAYER],
          })
          .filter(feature => feature.source === SERVICE_CONTAINERS_CLUSTERS_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(SERVICE_CONTAINERS_CLUSTERS_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          setViewport({
            latitude: (feature.geometry as any).coordinates[1],
            longitude: (feature.geometry as any).coordinates[0],
            zoom,
          });
        });
      });
    });
  }, [map, setViewport]);

  return <ServiceContainersClustersGLSource geoJSON={geoJSON} />;
}
