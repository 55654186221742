import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadBinSensorSettings as doLoadBinSensorSettings,
  saveBinSensorSettings as dosaveBinSensorSettings,
} from '../services/binSensorSettings';
import { BinSensorSettings } from '../interfaces/BinSensorSettings';
// Actions
const COMPLETE_LOAD = 'vendors/binSensorSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/binSensorSettings/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/binSensorSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/binSensorSettings/FAIL_SAVE';
const START_LOAD = 'vendors/binSensorSettings/START_LOAD';
const START_SAVE = 'vendors/binSensorSettings/START_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  binSensorSettings: {} as BinSensorSettings,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          binSensorSettings: action.binSensorSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (binSensorSettings: BinSensorSettings) => ({
  type: COMPLETE_LOAD,
  binSensorSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (binSensorSettings: BinSensorSettings) => ({
  type: COMPLETE_SAVE,
  binSensorSettings,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadBinSensorSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadBinSensorSettingsPromise = doLoadBinSensorSettings(vendorId);
  loadBinSensorSettingsPromise
    .then(binSensorSettings => {
      dispatch(completeLoad(binSensorSettings));
    })
    .catch(() => dispatch(failLoad()));
  return loadBinSensorSettingsPromise;
};

export const saveBinSensorSettings = (configurationId: number, payload: BinSensorSettings) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveBinSensorSettingsPromise = dosaveBinSensorSettings(configurationId, payload);
  saveBinSensorSettingsPromise
    .then(binSensorSettings => dispatch(completeSave(binSensorSettings)))
    .catch(() => dispatch(failSave()));

  return saveBinSensorSettingsPromise;
};

