import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { CustomerImportModalTableRow } from '../customerImportModal/CustomerImportModalTableRow';
import { EsriImportModalTableRow } from './EsriImportModalTableRow';
import { getUploadedEsriFilesStatus } from '../../../services/customerUploaderModal';
import { Message } from '../../../../core/components/styled';
import { Modal, Table } from '../../../../core/components';
import { esriImportFeatureSelector } from '../../../ducks/customers';
import translate from '../../../../core/services/translate';

interface Props {
  vendorId: number;
  onClose: (hasSuccessfulImports: boolean) => void;
}

const ESRI_SYNC = 'EsriSync';

const tableCells = [
  {
    name: 'fileName',
    label: translate('customers.customerUpload.fileName'),
    width: '24%',
  },
  {
    name: 'status',
    label: translate('common.status'),
    width: '10%',
  },
  {
    name: 'numberOfRecords',
    label: translate('customers.customerUpload.numberOfRecords'),
    width: '10%',
  },
  {
    name: 'numberOfImportedRecords',
    label: translate('customers.esriUpload.imported'),
    width: '10%',
  },
  {
    name: 'numberOfSuccessfulRecords',
    label: translate('customers.esriUpload.successful'),
    width: '10%',
  },
  {
    name: 'numberOfFailedRecords',
    label: translate('customers.esriUpload.failed'),
    width: '10%',
  },
  {
    name: 'errorFileUrl',
    label: translate('customers.customerUpload.errorFile'),
    width: '11%',
  },
  {
    name: 'date',
    label: `${translate('common.date')} & ${translate('customers.time')}`,
    width: '12%',
  },
];

const tableCellsForNewLayout = [
  {
    name: 'fileName',
    label: translate('customers.customerUpload.file'),
    width: '13%',
    sortable: true,
  },
  {
    name: 'status',
    label: translate('customers.customerUpload.fileStatus'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'totalRecordsCount',
    label: translate('customers.customerUpload.numberOfRecords'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'uploadedRecordsCount',
    label: translate('customers.customerUpload.numberOfImportedRecords'),
    width: '11%',
    sortable: true,
  },
  {
    name: 'failedRecordsCount',
    label: translate('customers.customerUpload.numberOfFailedRecords'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'validRecordsCount',
    label: translate('customers.customerUpload.numberOfValidRecords'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'invalidRecordsCount',
    label: translate('customers.customerUpload.numberOfInvalidRecords'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'errorFileDownloadUrl',
    label: translate('customers.customerUpload.outputFile'),
    width: '10%',
  },
  {
    name: 'date',
    label: translate('customers.customerUpload.dateTime'),
    width: '16%',
    sortable: true,
  },
];

const EsriUploadModal: React.SFC<Props> = props => {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const esriImportFeature = useSelector((state: AppState) => esriImportFeatureSelector(state));
  useEffect(() => {
    getUploadedEsriFilesStatus(props.vendorId, esriImportFeature.code).then(result => {
      setUploadedFiles(result.data || []);
    });
  }, [esriImportFeature.code, props.vendorId]);

  return (
    <Modal size="xLarge" title={translate('customers.esriImport')} onClose={() => props.onClose(false)}>
      {!!uploadedFiles.length ? (
        <Table
          cells={esriImportFeature.code === ESRI_SYNC ? tableCellsForNewLayout : tableCells}
          rows={uploadedFiles}
          rowComponent={esriImportFeature.code === ESRI_SYNC ? CustomerImportModalTableRow : EsriImportModalTableRow}
        />
      ) : (
        <Message padding="sMedium">{translate('customers.noEsriImport')}</Message>
      )}
    </Modal>
  );
};

export default EsriUploadModal;
