import React from 'react';
import moment from 'moment';

import { TableCell, TableRow, Button, Text } from '../../../../core/components/styled';
import { PreventativeMaintenanceStatus } from '../../../interfaces/PreventativeMaintenance';
import {
  PM_TYPE_MILEAGE,
  PM_TYPE_ENGINE_HOURS,
  PM_TYPE_DATE_CHECKS,
  PM_STATUS_UP_TO_DATE,
  PM_STATUS_PAST_DUE,
} from '../../../constants/preventativeMaintenance';
import translate from '../../../../core/services/translate';
import CompletePreventativeMaintenanceMilestoneModal from '../../modal/CompletePreventativeMaintenanceMilestoneModal';
import { hasPermission } from '../../../../account/utils/permissions';
import { ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS } from '../../../../account/constants';

type Props = PreventativeMaintenanceStatus & {
  vehicleId: number;
  currentMileage?: number;
  currentEngineHours?: number;
};

const VehicleMaintenanceStatusTableRow: React.FC<Props> = ({
  preventativeMaintenanceType,
  preventativeMaintenanceStatusType,
  mileage,
  engineHours,
  date,
  vehicleId,
}) => {
  const [completePMModalOpen, setCompletePMModalOpen] = React.useState<boolean>(false);

  const isViewOnlyOrSupport = !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS);

  let pmUpToDate = false;
  let pmPastDue = false;
  let unit: string | undefined = undefined;
  let count: string | number | null | undefined = undefined;

  if (preventativeMaintenanceType.id === PM_TYPE_MILEAGE) {
    unit = translate('vehicles.preventativeMaintenanceLabels.miles');
    count = mileage;
  } else if (preventativeMaintenanceType.id === PM_TYPE_ENGINE_HOURS) {
    unit = translate('vehicles.preventativeMaintenanceLabels.hours');
    count = engineHours;
  } else if (preventativeMaintenanceType.id === PM_TYPE_DATE_CHECKS) {
    count = moment(date).format('MM/DD/YYYY');
    unit = '';
  }

  if (preventativeMaintenanceStatusType.id === PM_STATUS_UP_TO_DATE) {
    pmUpToDate = true;
  } else if (preventativeMaintenanceStatusType.id === PM_STATUS_PAST_DUE) {
    pmPastDue = true;
  }

  return (
    <TableRow>
      <TableCell width="33.33%" padding="defaultCellVertical defaultCellHorizontal defaultCellVertical medium">
        <Text color={pmPastDue ? 'alert' : 'default'}>{translate(`vehicles.preventativeMaintenanceTypes.${preventativeMaintenanceStatusType.technicalName}`)}</Text>
      </TableCell>

      <TableCell width="33.33%">{!pmUpToDate && `${count} ${unit}`}</TableCell>

      <TableCell
        width="33.33%"
        align="right"
        padding="defaultCellVertical medium defaultCellVertical defaultCellHorizontal"
      >
        {!isViewOnlyOrSupport && !pmUpToDate && (
          <Button line size="xSmall" color="primary" onClick={() => setCompletePMModalOpen(true)}>
            {translate('vehicles.vehicleMaintenanceStatus.complete')}
          </Button>
        )}
      </TableCell>

      {!isViewOnlyOrSupport && !pmUpToDate && completePMModalOpen && (
        <CompletePreventativeMaintenanceMilestoneModal
          vehicleId={vehicleId}
          typeId={preventativeMaintenanceType.id}
          onClose={() => setCompletePMModalOpen(false)}
        />
      )}
    </TableRow>
  );
};

export default VehicleMaintenanceStatusTableRow;
