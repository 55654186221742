import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

import { useSelector } from 'src/core/hooks/useSelector';
import { isRoutePlannerNewLayoutActive } from 'src/routes/services/routePlannerPersistentBetaFlag';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { PermissionGuard } from '../../../../account/components';
import { ROUTES_PLANNER_SERVICE_ZONES_CREATE } from '../../../../account/constants';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { Table } from '../../../../core/components';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../../core/components/styled';
import confirm from '../../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import translate from '../../../../core/services/translate';
import { deleteServiceZone, saveServiceZone } from '../../../ducks';
import { ServiceZone } from '../../../interfaces/ServiceZones';
import { ServiceZoneEditorModal } from '../../modals';
import ServiceZonesPageTableRow from './ServiceZonesPageTableRow';

const ServiceZonesPage: FC = () => {
  const dispatch = useDispatch();
  const [isServiceZoneEditorModalOpen, setIsServiceZoneEditorModalOpen] = useState(false);
  const [selectedServiceZoneId, setSelectedServiceZoneId] = useState<number | undefined>();

  const vendorId = useSelector(currentVendorId);
  const { isLoading, isSaving, isDeleting, serviceZones } = useSelector(state => state.routes.serviceZones);

  const onServiceZoneFormSubmit = (serviceZone: ServiceZone) => {
    saveServiceZone(
      vendorId,
      serviceZone,
    )(dispatch)
      .then(() => {
        dispatch(reset('serviceZoneEditor'));
        closeServiceZoneEditorModal();
        createSuccessNotification(translate('routes.alertMessages.serviceZoneSaved'));
      })
      .catch(error => {
        const code = error.response.data && error.response.data.code;
        createErrorNotification(`${translate(createTranslationKey(code, 'routes.alertMessages'))}`);
      });
  };

  const onCancelSaveServiceZoneEditor = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    dispatch(reset('serviceZoneEditor'));
    closeServiceZoneEditorModal();
  };

  const openServiceZoneModal = (id?: number) => {
    setIsServiceZoneEditorModalOpen(true);
    setSelectedServiceZoneId(id);
  };

  const closeServiceZoneEditorModal = () => {
    setIsServiceZoneEditorModalOpen(false);
    setSelectedServiceZoneId(undefined);
  };

  const onDeleteServiceZone = async (id: number, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteServiceZone')))) {
      return;
    }
    deleteServiceZone(id)(dispatch)
      .then(() => {
        createSuccessNotification(`${translate('routes.alertMessages.serviceZoneDeleted')}`);
      })
      .catch(() => {
        createErrorNotification(`${translate('routes.alertMessages.serviceZoneDeleteFail')}`);
      });
  };

  const handleGoBack = () => {
    const isRoutePlannerNewLayout = isRoutePlannerNewLayoutActive();
    dispatch(
      push(isRoutePlannerNewLayout ? '/routes/route-planner' : '/routes/route-templates', {
        prevPath: '/routes/service-zones',
      }),
    );
  };

  const serviceZonesTableCells = [
    { name: 'name', label: translate('routes.serviceZone'), width: '50%' },
    { name: 'routeTemplateCount', label: translate('routes.numberOfRoutes'), width: '20%' },
    { name: 'active', label: translate('common.active'), width: '15%' },
    { name: 'options', label: translate('common.options'), width: '15%', align: 'right' },
  ];

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={handleGoBack} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate('routes.serviceZones')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <PermissionGuard permission={ROUTES_PLANNER_SERVICE_ZONES_CREATE}>
            <Button color="primary" id="create-service-zone-button" onClick={() => openServiceZoneModal()}>
              {translate('routes.createServiceZone')}
            </Button>
          </PermissionGuard>
        </PageActions>
      </PageHeader>
      <Panel>
        <PanelSectionGroup isLoading={isLoading || isSaving || isDeleting}>
          <PanelSection>
            {!!size(serviceZones) && (
              <Table
                cells={serviceZonesTableCells}
                rows={serviceZones}
                rowComponent={ServiceZonesPageTableRow}
                rowProps={{ deleteServiceZone: onDeleteServiceZone, editServiceZone: openServiceZoneModal }}
              />
            )}
          </PanelSection>
          {!size(serviceZones) && <Message padding="sMedium">{translate('routes.noServiceZones')}</Message>}
        </PanelSectionGroup>
      </Panel>
      {!!isServiceZoneEditorModalOpen && (
        <ServiceZoneEditorModal
          closeModal={onCancelSaveServiceZoneEditor}
          serviceZoneId={selectedServiceZoneId}
          onServiceZoneSubmit={onServiceZoneFormSubmit}
        />
      )}
    </PageContent>
  );
};

export default ServiceZonesPage;
