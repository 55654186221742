import { http } from '../../core/services/http';
import transformLoadRouteTemplatesByScheduledDays from './transformLoadRouteTemplatesByScheduledDays';

const SCHEDULED_DAYS = '1,2,3,4,5,6,7';

const loadRouteTemplatesByScheduledDays = (vendorId: number, serviceTypeId?: number, scheduledDays: string = SCHEDULED_DAYS) =>
  http
    .get('routetemplates/byScheduledDays', { params: { vendorId, serviceTypeId, scheduledDays } })
    .then(response => transformLoadRouteTemplatesByScheduledDays(response.data));

export default loadRouteTemplatesByScheduledDays;
