import styled, { css } from 'styled-components';

import { BareButtonStyle } from 'src/core/components/styled';
import { sizeMapper } from 'src/utils/styles';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const RouteDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 5px;
`;

export const RouteDetail = styled.div<{ margin?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'medium no small no')};
  padding: 0 3px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const RouteDetailLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #76768c;
  margin-bottom: 6px;
  text-align: center;
`;

export const RouteDetailValueWithIcon = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 46px;

  color: ${props => (props.isActive ? props.theme.brandPrimary : props.theme.bandDefault)};

  ${props =>
    props.isActive &&
    css`
      cursor: pointer;
    `}
`;

export const RouteDetailValue = styled.span<{ isSizeSmall?: boolean; isSizeSmaller?: boolean; noWrap?: boolean }>`
  text-align: center;
  font-size: 18px;
  margin-top: 4px;

  ${props =>
    props.isSizeSmall &&
    css`
      font-size: 15px;
    `}

  ${props =>
    props.isSizeSmaller &&
    css`
      font-size: 12px;
    `}

  ${props =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}
`;

export const EditRouteButton = styled.button`
  ${BareButtonStyle}
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const SeeMoreButton = styled.button`
  ${BareButtonStyle}
  font-weight: 500;
  color: ${props => props.theme.brandPrimary};

  margin-bottom: 8px;
`;
