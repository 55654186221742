import { camelize } from 'humps';
import { isNumber, map, orderBy, pick, reduce, size, upperFirst } from 'lodash-es';
import moment from 'moment';

import { BULK, PORTABLE_TOILET, PORTABLE_RESTROOM_TRAILER } from '../../common/constants';
import { BULK_ID, PORTABLE_TOILET_ID } from 'src/common/constants/serviceTypes';
import { ROUTE_STATUSES_BY_ID, SCHEDULED } from '../constants';
import { Route, ServiceContract } from '../interfaces/Route';
import { RouteLocation } from '../interfaces/RouteLocation';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const getEquipmentSize = (equipmentSize: string, equipmentName?: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string) =>
  `common.equipmentSizes.x${upperFirst(camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')))}`;

const getEquipmentSizeShortCodeTranslationKey = (
  equipmentSize: string,
  serviceTypeName: string,
  equipmentName: string,
) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

const getEquipmentSizeShortNameTranslationKey = (
  equipmentSize: string,
  serviceTypeId: number,
  equipmentName: string,
) => {
  let equipmentSizesTranslationName;

  if (serviceTypeId === BULK_ID || serviceTypeId === PORTABLE_TOILET_ID) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

export const getShortServiceName = (
  serviceTypeId: number,
  equipmentType?: TechnicalType,
  equipmentSize?: TechnicalType,
) => {
  if (!equipmentType || !equipmentSize) return;

  const equipmentTypeShortCode = translate(
    createTranslationKey(equipmentType.technicalName, 'common.equipmentTypeShortCodes'),
  );
  const equipmentSizeShortCode = translate(
    getEquipmentSizeShortNameTranslationKey(equipmentSize.technicalName, serviceTypeId, equipmentType.name),
    {
      size: getEquipmentSize(equipmentSize.name, equipmentSize.technicalName),
    },
  );

  return `${equipmentSizeShortCode} - ${equipmentTypeShortCode}`;
};

const getServiceName = ({
  equipmentTypeTechnicalName,
  equipmentType,
  equipmentSizeTechnicalName,
  equipmentSize,
  serviceTypeName,
}: ServiceContract) => {
  const equipmentTypeShortCode = translate(
    createTranslationKey(equipmentTypeTechnicalName, 'common.equipmentTypeShortCodes'),
  );
  const equipmentSizeShortCode = translate(
    getEquipmentSizeShortCodeTranslationKey(equipmentSizeTechnicalName, serviceTypeName, equipmentType),
    {
      size: getEquipmentSize(equipmentSize, equipmentSizeTechnicalName),
    },
  );

  return `${equipmentSizeShortCode} - ${equipmentTypeShortCode}`;
};

const getWasterRecorderEquipmentName = ({ equipmentSizeName }: { equipmentSizeName: string }) =>
  translate(getEquipmentSizeTranslationKey(equipmentSizeName), {
    size: getEquipmentSize(equipmentSizeName),
  });

// MAY BE USED IN THE FUTURE
// function mapWasteAuditType(
//   wasteAuditStatuses: number[],
//   wasteAuditLocationStatusTypeId: number,
//   routeWasteAuditTypeId?: number,
// ) {
//   if (!routeWasteAuditTypeId) {
//     return [];
//   }

//   switch (routeWasteAuditTypeId) {
//     case EXCEPTIONS: {
//       const wasteAuditStatusList: WasteAuditStatus[] = map(wasteAuditStatuses, wasteAuditStatusId => ({
//         ...WASTE_AUDIT_EXCEPTION_TYPES[wasteAuditStatusId],
//         type: EXCEPTIONS,
//       }));
//       if (wasteAuditLocationStatusTypeId && wasteAuditLocationStatusTypeId !== FLAGGED) {
//         wasteAuditStatusList.push({
//           ...WASTE_AUDIT_LOCATION_STATUS_TYPES[wasteAuditLocationStatusTypeId],
//           type: PARTICIPATION,
//         });
//       }
//       return wasteAuditStatusList;
//     }
//     case PARTICIPATION:
//       if (wasteAuditLocationStatusTypeId) {
//         return [{ ...WASTE_AUDIT_LOCATION_STATUS_TYPES[wasteAuditLocationStatusTypeId], type: PARTICIPATION }];
//       }
//       return [];
//     default:
//       return [];
//   }
// }

export const transformLoadRouteLocations = (routeLocations: any[], routeWasteAuditTypeId?: number): RouteLocation[] =>
  map(
    routeLocations,
    ({
      orderNo,
      customer,
      serviceContract,
      status,
      routeLocationAccountTypeId,
      wasteAuditLocationStatusTypeId,
      wasteAuditStatuses,
      wasterRecorders,
      pickupType,
      pickupTypeId,
      ...routeLocation
    }) => ({
      ...pick(
        routeLocation,
        'id',
        'pickupType',
        'pickupTypeId',
        'numberOfImages',
        'numberOfIssues',
        'numberOfNotes',
        'wasteAuditStatuses',
        'cartFillLevelEnabled',
        'wasteDetails',
        'reasonCodeTypeId',
        'assistingVehicleRegplate',
      ),
      orderNumber: orderNo,
      accountStatusId: routeLocationAccountTypeId,
      customer: pick(customer, 'id', 'name', 'accountNumber', 'locations'),
      location: pick(customer.locations[0], 'id', 'name', 'address', 'vendorAccountNo', 'streetSegment'),
      service: serviceContract && {
        id: serviceContract.id,
        name: getServiceName(serviceContract),
        equipmentSize: translate(
          getEquipmentSizeShortCodeTranslationKey(
            serviceContract.equipmentSizeTechnicalName,
            serviceContract.serviceTypeName,
            serviceContract.equipmentType,
          ),
          {
            size: getEquipmentSize(serviceContract.equipmentSize, serviceContract.equipmentSizeTechnicalName),
          },
        ),
        equipmentTypeName: translate(
          createTranslationKey(serviceContract.equipmentTypeTechnicalName, 'common.equipmentTypes'),
        ),
        serviceContractBinDetails: serviceContract.serviceContractBinDetails,
        wasteMaterialTypeName: translate(
          createTranslationKey(serviceContract.wasteMaterialTypeTechnicalName, 'common.wasteTypes'),
        ),
        wasteMaterialTypeId: serviceContract.wasteMaterialTypeId,
      },
      wasterRecorders:
        wasterRecorders &&
        map(wasterRecorders, wasterRecorder => ({
          ...wasterRecorder,
          equipmentTypeName: getWasterRecorderEquipmentName(wasterRecorder),
        })),
      serviceCount: size(wasterRecorders)
        ? reduce(wasterRecorders, (sum, wasterRecorder) => sum + wasterRecorder.numberOfBins, 0)
        : undefined,
      wasteAuditStatuses: wasteAuditStatuses,
      pickupStatusId: status.statusId,
      pickupStatusDate: status.statusDate,
      pickupStatusName: translate(createTranslationKey(status.statusName, 'common.pickupStatuses')),
      pickupType,
      pickupTypeId,
      pickupExceptions: status.pickupExceptions,
      totalPassesCount:
        (isNumber(status.forwardPassesCount) && status.forwardPassesCount) +
        (isNumber(status.reversePassesCount) && status.reversePassesCount),
      streetPasses: orderBy(status.streetPasses, ['passDateTime'], ['desc']),
    }),
  );

export const transformLoadRoute = (
  { routeDate, vehicleTypeName, routeLocations, ...route }: Route & { total: number },
  wasteAuditTypeId?: number,
) => ({
  ...route,
  routeDate: routeDate && moment(routeDate).format('MM/DD/YYYY'),
  vehicleTypeName: vehicleTypeName && translate(createTranslationKey(vehicleTypeName, 'vehicles.vehicleTypes')),
  routeLocations: transformLoadRouteLocations(routeLocations, wasteAuditTypeId),
});

export const transformCreateRoute = (route: Route) => ({
  ...route,
  routeStatusTypeId: SCHEDULED,
  routeLocations: map(
    route.routeLocations,
    ({ accountStatusId, service, location, orderNumber, ...routeLocation }) => ({
      ...routeLocation,
      locationId: location.id,
      routeLocationAccountTypeId: accountStatusId,
      serviceContract: service,
      orderNo: orderNumber,
    }),
  ),
});

export const transformEditRoute = (route: Route) => ({
  ...pick(
    route,
    'routeName',
    'date',
    'routePriorityTypeId',
    'supervisorId',
    'startingLocationId',
    'endingLocationId',
    'groups',
    'vendorServiceZoneId',
    'isTestRoute',
    'vehicleTypeId',
  ),
});

export const transformTransferRouteLocations = (
  routeLocations: RouteLocation[],
  routeDate: Date | string,
  positionTypeId: number,
) => ({
  routeLocations: map(routeLocations, ({ id }) => id),
  routeDate,
  positionTypeId,
});

export const transformCloneRoute = (route: Route) => ({
  ...route,
  routeStatusName: ROUTE_STATUSES_BY_ID[route.routeStatusTypeId].name,
  vehicleTypeName: translate(createTranslationKey(route.vehicleTypeName, 'vehicles.vehicleTypes')),
  materialType: translate(createTranslationKey(route.wasteMaterialType, 'common.wasteTypes')),
});
