import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  loadLoadDumped,
  loadStreetSweeperUnitOfMeasureValues,
  loadStreetSweeperMaterialTypeValues,
} from 'src/routes/ducks/loadsDumped';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import LoadDumpedEditorModal from './LoadDumpedEditorModal';

type Props = {
  loadDumpedId?: number;
  onClose: (formPristine?: boolean) => void;
  routeId: number;
};

export default function LoadDumpedEditorModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    const dependencies = [
      loadStreetSweeperUnitOfMeasureValues()(dispatch),
      loadStreetSweeperMaterialTypeValues()(dispatch),
    ];
    if (props.loadDumpedId) dependencies.push(loadLoadDumped(vendorId, props.loadDumpedId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={LoadDumpedEditorModal}
      successProps={{ ...props }}
    />
  );
}
