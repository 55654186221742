import React, { PureComponent } from 'react';
import { debounce, map } from 'lodash-es';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import translate from '../../../core/services/translate';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch, Dropdown } from '../../../core/components';
import { STATUSES_BY_ID } from '../../constants/status';

interface PropsWithoutReduxForm {
  onSearchTermChange(term: string): void;
  onSearchStatusChange(status: string): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class FleetSearchForm extends PureComponent<Props> {
  onSearchTermChange = debounce((event: any, searchTerm: string) => {
    this.props.onSearchTermChange(searchTerm);
  }, 300);

  onSearchStatusChange = (event: any, value: string) => {
    this.props.onSearchStatusChange(value);
  };

  render() {
    const searchStatusOptions = map(STATUSES_BY_ID, searchStatus => ({
      label: searchStatus.name,
      value: searchStatus.id,
    }));

    return (
      <form>
        <PanelSection padding="sMedium xSmall" withBorder>
          <Grid>
            <GridColumn size="10/12">
              <Field name="fleetSearchTerm" component={PanelSearch} onChange={this.onSearchTermChange} margin="no" />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="searchStatus"
                margin="no"
                component={Dropdown as any}
                options={searchStatusOptions}
                placeholder={translate('common.status')}
                onChange={this.onSearchStatusChange}
                isClearable
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'fleetSearch',
  enableReinitialize: true,
})(FleetSearchForm);
