import { includes, size } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import humps from 'humps';

import { Button, ModalFixedFooter, PanelSection, Text } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  downloadSampleCityAlertsTemplate,
  downloadSampleLocationAlertsTemplate,
} from 'src/fleet/services/locationAndCityAlertsImports';
import { EXCEL_MIME_TYPES, TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import {
  loadCityAlertsImportUploadedFilesStatus,
  loadLocationAlertsImportUploadedFilesStatus,
  uploadCityAlertsFile,
  uploadLocationAlertsFile,
} from 'src/fleet/ducks/locationAndCityAlertsImports';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { LocationAndCityAlertsImportModalTableRow } from './LocationAndCityAlertsImportModalTableRow';
import { Modal, Table } from 'src/core/components';
import { UploadArea } from 'src/customers/components/styled/CustomerImport';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: () => void;
  isCityAlertsModalDisplayed?: boolean;
}

type Drag = React.DragEvent<HTMLDivElement>;

const LocationAndCityAlertsImportModal = ({ closeModal, isCityAlertsModalDisplayed }: Props) => {
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const [isValidHover, setIsValidHover] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const { isUploading, uploadedFiles, isLoading, uploadingPercent } = useSelector(
    state => state.fleet.locationAndCityAlertsImports,
  );
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (getIsVendorNotChanged(vendorId))
        isCityAlertsModalDisplayed
          ? loadCityAlertsImportUploadedFilesStatus(vendorId)(dispatch)
          : loadLocationAlertsImportUploadedFilesStatus(vendorId)(dispatch);
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch, uploadedFiles, vendorId, isCityAlertsModalDisplayed]);

  const isValidFile = (items: DataTransferItemList) => {
    if (items.length !== 1) return false;
    return items[0].kind === 'file' && includes([EXCEL_MIME_TYPES], items[0].type);
  };

  const onDragEnter = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      setIsDragging(true);
      if (isValidFile(e.dataTransfer.items)) setIsValidHover(true);
      else setIsValidHover(false);
    }
  };

  const onDragOver = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (!isDragging) setIsDragging(true);
      if (!isValidHover && isValidFile(e.dataTransfer.items)) {
        setIsValidHover(true);
      }
    }
  };

  const onDragLeave = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    setIsValidHover(false);
    setIsDragging(false);
  };

  const onDrop = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (isValidFile(e.dataTransfer.items)) {
        uploadFile(e.dataTransfer.files[0]);
      }
    }
    setIsValidHover(false);
    setIsDragging(false);
  };

  const uploadFile = (file: File) => {
    const uploadAlertsFile = isCityAlertsModalDisplayed ? uploadCityAlertsFile : uploadLocationAlertsFile;

    uploadAlertsFile(
      file,
      vendorId,
    )(dispatch)
      .then(() => createSuccessNotification(translate('customers.customerUpload.successMessage')))
      .catch(e => {
        const errorMessageCode =
          e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

        createErrorNotification(
          errorMessageCode
            ? translate(`customers.alertMessages.${errorMessageCode}`)
            : translate('customers.customerUpload.errorMessage'),
        );
      });
  };

  const onFileInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target && e.target.files) {
      uploadFile(e.target.files[0]);
      e.target.value = '';
    }
  };

  const openFileUploader = () => {
    fileUploaderRef.current && fileUploaderRef.current.click();
  };

  const tableCellsWidths = ['15%', '12%', '11%', '12%', '11%', '11%', '11%', '17%'];

  const tableCells = [
    {
      name: 'fileName',
      label: translate('customers.customerUpload.file'),
      width: tableCellsWidths[0],
      sortable: true,
    },
    {
      name: 'status',
      label: translate('customers.customerUpload.fileStatus'),
      width: tableCellsWidths[1],
      sortable: true,
    },
    {
      name: 'totalRecordsCount',
      label: translate('customers.customerUpload.numberOfRecords'),
      width: tableCellsWidths[2],
      sortable: true,
    },
    {
      name: 'uploadedRecordsCount',
      label: translate('customers.customerUpload.numberOfImportedRecords'),
      width: tableCellsWidths[3],
      sortable: true,
    },
    {
      name: 'validRecordsCount',
      label: translate('customers.customerUpload.numberOfValidRecords'),
      width: tableCellsWidths[4],
      sortable: true,
    },
    {
      name: 'invalidRecordsCount',
      label: translate('customers.customerUpload.numberOfInvalidRecords'),
      width: tableCellsWidths[5],
      sortable: true,
    },
    {
      name: 'errorFileDownloadUrl',
      label: translate('customers.customerUpload.outputFile'),
      width: tableCellsWidths[6],
    },
    {
      name: 'date',
      label: translate('customers.customerUpload.dateTime'),
      width: tableCellsWidths[7],
      sortable: true,
    },
  ];

  const virtualizedProps = {
    itemSize: TABLE_ROW_HEIGHT_SMALL,
    height: Math.min(uploadedFiles.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 4) || 1,
  };

  const importAlertsModalTitle = translate(
    isCityAlertsModalDisplayed ? 'vendors.cityAlerts.importCityAlerts' : 'vendors.cityAlerts.importLocationAlerts',
  );

  return (
    <Modal size="xLarge" title={importAlertsModalTitle} onClose={() => closeModal()} padding="medium no no no">
      <UploadArea
        uploadPercentage={uploadingPercent}
        isValidHover={isValidHover}
        isDragging={isDragging}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {!isUploading && (
          <Text color={`${isDragging ? 'white' : 'primary'}`}>
            <Button
              color={`${isDragging ? 'white' : 'primary'}`}
              text
              underline
              margin="no xxSmall no"
              onClick={openFileUploader}
            >
              <Text weight="medium">{translate('common.browse')}</Text>
            </Button>
            {translate('common.or')} {translate('customers.customerUpload.dropYourExcelFile')}
          </Text>
        )}
        {isUploading && <Loader />}
      </UploadArea>

      {!!size(uploadedFiles) && (
        <PanelSection padding="small" vertical>
          <Table
            cells={tableCells}
            rows={uploadedFiles}
            rowComponent={LocationAndCityAlertsImportModalTableRow}
            rowProps={{ loading: isLoading, tableCellsWidths }}
            virtualized
            virtualizedProps={virtualizedProps}
          />
          <Text margin="small no medium" fontStyle="italic" color="info" block>
            {translate('customers.customerUpload.informativeMessage')}
          </Text>
        </PanelSection>
      )}

      <ModalFixedFooter isShadowed>
        <Button
          color="primary"
          size="medium"
          line
          onClick={isCityAlertsModalDisplayed ? downloadSampleCityAlertsTemplate : downloadSampleLocationAlertsTemplate}
          margin="no xSmall no no"
        >
          {translate('customers.customerUpload.downloadSample')}
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={openFileUploader}
          type="button"
          disabled={isUploading}
          margin="no no no xSmall"
        >
          {translate('haulerProfile.uploadFile')}
        </Button>
        <input
          accept={EXCEL_MIME_TYPES}
          id="fileSelect"
          onChange={onFileInputChanged}
          ref={fileUploaderRef}
          style={{ display: 'none' }}
          type="file"
        />
      </ModalFixedFooter>
    </Modal>
  );
};

export default LocationAndCityAlertsImportModal;
