const theme = {
  black: '#000',
  gray: '#d9d9e1',
  grayBase: '#242833',
  grayDark: '#b4b4c4',
  grayDarker: '#76768c',
  grayDarkest: '#f9f9f9',
  grayLight: '#f2f2f5',
  grayLighter: '#fafafc',
  orangeDark: '#FC6526',

  markerBackground: '#00a599',
  markerFontColor: '#fff',
  markerStroke: '#00a599',

  bodyBackgroundColor: '#fafafc',
  brandAlert: '#ff5252',
  brandDefault: '#242833',
  brandGreenDark: '#00A69A',
  brandInfo: '#0066a6',
  brandInfoLight: '#03a9f4',
  brandPartSuccess: '#3068aa',
  brandPrimary: '#00a599',
  brandSecondary: '#f2f2f5',
  brandSuccess: '#00a599',
  brandWarning: '#ffa452',
  brandWhite: '#ffffff',

  fontFamilyBase: 'Montserrat, Helvetica Neue, Helvetica, sans-serif',
  fontSizeBase: '14px',

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,

  mobileBreakpoint: '450px',
  tabletBreakpoint: '992px',
  desktopBreakpoint: '1240px',
  desktopLargeBreakpoint: '1360px',
};

export default theme;
