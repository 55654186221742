import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Text } from 'src/core/components/styled';
import { date, time } from '../../utils/services/formatter';
import {
  MessagingActions,
  MessagingContentContainer,
  ThreadItem,
  ThreadItemContent,
  ThreadItemDate,
  ThreadItemMessage,
  ThreadItemTitle,
  ThreadsList,
} from '../styles/MessagingContainer';
import { openThread, setSendNewMessage } from '../ducks/messaging';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { MessageThread } from '../interfaces/MessageThread';
import { MessageType } from '../interfaces/MessateType';

const getThreadTypePrefix = (thread: MessageThread) => {
  if (thread.messageTypeId === MessageType.All || thread.messageTypeId === MessageType.VehicleType) {
    return translate('messaging.threadTypeLabels.drivers');
  } else if (thread.messageTypeId === MessageType.Route) {
    return `${translate('messaging.messageTypes.route')} - ${date(thread.routeDate)}`;
  } else if (thread.messageTypeId === MessageType.Direct) {
    return translate('messaging.threadTypeLabels.direct');
  }
  return '';
};

export const MessageThreads: React.FC = () => {
  const dispatch = useDispatch();
  const isLoadingThreads = useSelector(s => s.messaging.isLoadingThreads);
  const threads = useSelector(s => s.messaging.messageThreads);

  return (
    <>
      <MessagingContentContainer isLoading={isLoadingThreads}>
        <ThreadsList>
          {threads.map(t => (
            <ThreadItem onClick={() => openThread(t)(dispatch)} key={t.threadId}>
              <ThreadItemContent hasUnreadMessages={!!t.numberOfUnreadMessages}>
                <ThreadItemTitle>
                  <Text color="grayDarker" size="xSmall">
                    {getThreadTypePrefix(t)}
                  </Text>
                  <div>{t.threadName}</div>
                </ThreadItemTitle>
                <ThreadItemMessage>
                  {t.lastMessageText.length > 80 ? t.lastMessageText.substr(0, 80 - 1) + '...' : t.lastMessageText}
                </ThreadItemMessage>
                <ThreadItemDate>
                  <div>{time(t.sentDate)}</div>
                  <div>{date(t.sentDate)}</div>
                </ThreadItemDate>
              </ThreadItemContent>
            </ThreadItem>
          ))}
        </ThreadsList>
      </MessagingContentContainer>

      <MessagingActions>
        <Button onClick={() => dispatch(setSendNewMessage(true))} color="primary">
          + {translate('messaging.newMessage')}
        </Button>
      </MessagingActions>
    </>
  );
};
