import React, { PureComponent } from 'react';

import { Modal, ImageSlider } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: () => void;
  weightTicket: any;
}

class WeightTicketImagesModal extends PureComponent<Props> {
  render() {
    const {
      weightTicket: { ticketNumber, disposalSiteAddress, image, customerName, materialType, locationAddress },
      closeModal,
    } = this.props;

    const modalSubtitle = ticketNumber
      ? `${translate('routes.ticketNumber')} : ${ticketNumber}`
      : materialType && locationAddress
      ? `${translate('common.material')} : ${materialType} | ${translate('common.address')} : ${locationAddress}`
      : materialType
      ? `${translate('common.material')} : ${materialType}`
      : '';

    return (
      <Modal title={disposalSiteAddress} subTitle={modalSubtitle} onClose={closeModal} padding="medium no no">
        <ImageSlider
          margin="small no no"
          images={[image.url]}
          imagesToDownload={[image.rawUrl || image.url]}
          imageTitle={customerName}
        />
      </Modal>
    );
  }
}

export default WeightTicketImagesModal;
