import update from 'immutability-helper';
import { identity, reduce } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { LIMIT_PER_PAGE } from '../constants';
import {
  loadRecurringServices as doLoadRecurringServices,
  loadRecurringServicesByVendorId as doLoadRecurringServicesByVendorId,
  loadRecurringServicesIssueType as doLoadRecurringServicesIssueType,
  updateAcceptSelectedPaymentServices as doUpdateAcceptSelectedPaymentServices,
  updateIssueReportRecurringServices as doUpdateIssueReportRecurringServices,
  updateIssueReportRecurringServicesByVendorId as doUpdateIssueReportRecurringServicesByVendorId,
  updateRecurringServices as doUpdateRecurringServices,
  updateRecurringServicesByVendorId as doUpdateRecurringServicesByVendorId,
  updateUndoAcceptRecurringServices as doUpdateUndoAcceptRecurringServices,
  updateUndoAcceptRecurringServicesByVendorId as doUpdateUndoAcceptRecurringServicesByVendorId,
  updateUndoReportRecurringServices as doUpdateUndoReportRecurringServices,
  updateUndoReportRecurringServicesByVendorId as doUpdateUndoReportRecurringServicesByVendorId,
} from '../services/loadRecurringServices';
// import { RECURRING_SERVICE, ONCALL_SERVICE, PROCESSED_SERVICE } from '../constants';

//  Actions
const START_LOAD = 'payments/recurringServices/START_LOAD';
export const COMPLETE_LOAD = 'payments/recurringServices/COMPLETE_LOAD';
const FAIL_LOAD = 'payments/recurringServices/FAIL_LOAD';
const FAIL_SAVE = 'payments/recurringServices/SAVE_FAIL';
const START_SAVE = 'payments/recurringServices/START_SAVE';
const COMPLETE_SAVE = 'payments/recurringServices/COMPLETE_SAVE';
const RESET = 'payments/recurringServices/RESET';

const START_TYPE_LOAD = 'payments/issueType/START_TYPE_LOAD';
const COMPLETE_TYPE_LOAD = 'payments/issueType/COMPLETE_TYPE_LOAD';
const FAIL_TYPE_LOAD = 'payments/issueType/FAIL_TYPE_LOAD';

//  Initial State
const initialState = {
  isLoading: false,
  isSaving: false,
  limit: undefined,
  page: undefined,
  total: undefined,
  workOrdersInfo: undefined,
  pricingInfo: undefined,
  paymentInfo: undefined,
  haulerName: undefined,
  // activitiBaseUrl: undefined,
  readonly: undefined,
  errorMessage: undefined,
  issueType: undefined,
};

//  Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          limit: action.recurringServices.limit,
          page: action.recurringServices.page,
          total: action.recurringServices.total,
          workOrdersInfo: action.recurringServices.workOrdersInfo,
          pricingInfo: action.recurringServices.pricingInfo,
          paymentInfo: action.recurringServices.paymentInfo,
          haulerName: action.recurringServices.haulerName,
          // activitiBaseUrl: action.recurringServices.activitiBaseUrl,
          readonly: action.recurringServices.readonly,
          errorMessage: action.recurringServices.errorMessage,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          limit: undefined,
          page: undefined,
          total: undefined,
          workOrdersInfo: undefined,
          pricingInfo: undefined,
          paymentInfo: undefined,
          haulerName: undefined,
          // activitiBaseUrl: undefined,
          readonly: undefined,
          errorMessage: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          limit: action.recurringServices.limit,
          page: action.recurringServices.page,
          total: action.recurringServices.total,
          workOrdersInfo: action.recurringServices.workOrdersInfo,
          pricingInfo: action.recurringServices.pricingInfo,
          paymentInfo: action.recurringServices.paymentInfo,
          haulerName: action.recurringServices.haulerName,
          // activitiBaseUrl: action.recurringServices.activitiBaseUrl,
          readonly: action.recurringServices.readonly,
          errorMessage: action.recurringServices.errorMessage,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    case START_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          issueType: action.issueType,
        },
      });

    case FAIL_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          issueType: action.issueType,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (recurringServices: any) => ({
  type: COMPLETE_LOAD,
  recurringServices,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (recurringServices: any) => ({
  type: COMPLETE_SAVE,
  recurringServices,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startTypeLoad = () => ({
  type: START_TYPE_LOAD,
});

const completeTypeLoad = (issueType: any) => ({
  type: COMPLETE_TYPE_LOAD,
  issueType,
});

const failTypeLoad = () => ({
  type: FAIL_TYPE_LOAD,
});

export const loadRecurringServicesIssueType = (vendorId: number, token: string) => (dispatch: Dispatch) => {
  dispatch(startTypeLoad());
  if (token) {
    return doLoadRecurringServicesIssueType(token)
      .then(issueType => dispatch(completeTypeLoad(issueType)))
      .catch(() => dispatch(failTypeLoad()));
  }
};

export const loadRecurringServices = (vendorId: number, token: string, date: Date | string, page: number, limit: number) => (
  dispatch: Dispatch,
) => {
  dispatch(startLoad());
  if (token) {
    return doLoadRecurringServices(token, date || '', page || 1, limit || LIMIT_PER_PAGE)
      .then(recurringServices => dispatch(completeLoad(recurringServices)))
      .catch(() => dispatch(failLoad()));
  } else if (vendorId) {
    return doLoadRecurringServicesByVendorId(vendorId)
      .then(recurringServices => dispatch(completeLoad(recurringServices)))
      .catch(() => dispatch(failLoad()));
  }
};

export const updateRecurringServices = (
  requestRecurringServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateRecurringServices(requestRecurringServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateRecurringServicesByVendorId(requestRecurringServicesObj, vendorId)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateIssueReportRecurringServices = (
  requestRecurringServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateIssueReportRecurringServices(requestRecurringServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateIssueReportRecurringServicesByVendorId(requestRecurringServicesObj, vendorId)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateUndoReportRecurringServices = (
  requestRecurringServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateUndoReportRecurringServices(requestRecurringServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateUndoReportRecurringServicesByVendorId(requestRecurringServicesObj, vendorId)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateUndoAcceptRecurringServices = (
  requestRecurringServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateUndoAcceptRecurringServices(requestRecurringServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  } else if (vendorId) {
    return doUpdateUndoAcceptRecurringServicesByVendorId(requestRecurringServicesObj, vendorId)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  }
};

export const updateAcceptSelectedRecurringPaymentServices = (
  requestRecurringServicesObj: any,
  vendorId: number,
  token: string,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startSave());
  if (token) {
    return doUpdateAcceptSelectedPaymentServices(requestRecurringServicesObj, token, page || 1, limit || LIMIT_PER_PAGE)
      .then(recurringServices => dispatch(completeSave(recurringServices)))
      .catch(() => dispatch(failSave()));
  }
};

// Selectors
const getVendorPaymentMethodTypes = (paymentMethodStates: any, paymentMethodId: any) => {
  const vendorPaymentMethodTypes = reduce(
    paymentMethodStates,
    (paymentInfo, paymentMethodState) => {
      if (paymentMethodState.paymentMethod === paymentMethodId) {
        paymentInfo.push(paymentMethodState);
      }
      return paymentInfo;
    },
    [] as any[],
  );
  return vendorPaymentMethodTypes;
};

export const vendorPaymentMethodTypes = createSelector(getVendorPaymentMethodTypes, identity);

export const resetRecurringService = () => ({
  type: RESET,
});
