import { Modal } from '../../../core/components';
import { Button, Grid, ModalSection, Text } from '../../../core/components/styled';
import { PopoverDetailsContainer } from '../styled/CustomerLocations';
import { CHANGE_REQUEST } from 'src/customers/constants/requestTypeOptions';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
  values: any;
}
export default function TicketConfirmationModal({ closeModal, values }: Props) {
  const fileName = values?.Files ? values?.Files[0].name : undefined;
  const showErrrorMessage =
    values?.ServiceRequestType === CHANGE_REQUEST
      ? !values?.dataCorrectionResponse?.serviceOrderId
      : !values?.addNewWorkOrderSettings?.serviceOrderId;

  const WorkOrder =
    values?.ServiceRequestType === CHANGE_REQUEST
      ? values?.dataCorrectionResponse?.workOrder
      : values?.addNewWorkOrderSettings?.workOrder;
  return (
    <Modal size="small" title="Submitted Work Order Summary" onClose={closeModal}>
      <ModalSection margin="medium no no" align="center">
        <PopoverDetailsContainer>
          {showErrrorMessage && (
            <Text block color="alert" weight="medium" size="medium" margin="xSmall">
              {translate('customers.rubiconService.alertMessages.confirmErrorMessage')}
            </Text>
          )}
          <Text block weight="medium" size="medium">
            {values?.ServiceRequestType === CHANGE_REQUEST
              ? translate('customers.rubiconService.requestOrder')
              : translate('autoDispatch.workOrder')}
            #: {WorkOrder}
          </Text>
          <Grid multiLine>
            <Grid margin="xSmall no no">
              <Text align="left">
                {translate('common.customer')}: {values?.Customer}
              </Text>
            </Grid>
            <Grid margin="xSmall no no">
              <Text align="left">
                {translate('common.location')}: {values?.Location}
              </Text>
            </Grid>
            <Grid margin="xSmall no no">
              {translate('customers.serviceId')}: {values?.ServiceId}
            </Grid>
            <Grid margin="xSmall no no">
              {translate('customers.rubiconService.serviceRequestType')}:{' '}
              {values?.ServiceRequestType !== CHANGE_REQUEST
                ? values?.serviceRequestDescription
                : values?.ServiceRequestType}
            </Grid>
            {values?.ServiceRequestType !== CHANGE_REQUEST && (
              <>
                <Grid margin="xSmall no no">
                  {translate('customers.rubiconService.dateSubmitted')}: {values?.DatePerformed}
                </Grid>
                <Grid margin="xSmall no no">
                  {translate('customers.rubiconService.amount')}: {values?.Amount}
                </Grid>
                <Grid margin="xSmall no no">
                  {translate('haulerProfile.documents')}: {fileName}
                </Grid>
              </>
            )}
          </Grid>
          <Grid multiLine></Grid>
        </PopoverDetailsContainer>
        <Button type="button" margin="no small" color="primary" onClick={() => closeModal()}>
          {translate('common.ok')}
        </Button>
      </ModalSection>
    </Modal>
  );
}
