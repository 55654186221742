import { camelCase, kebabCase } from 'lodash-es';
import { FC, useState } from 'react';

import { ActionButtonTooltip, ThumbnailSet } from '../../../../core/components';
import { date, time } from '../../../../utils/services/formatter';
import { MaterialTicketEnhanced } from 'src/routes/interfaces/MaterialTickets';
import { TableCell, TableRow, Text } from '../../../../core/components/styled';
import { WeightTicketImagesModal } from '../../modals';
import translate from 'src/core/services/translate';

interface Props extends MaterialTicketEnhanced {
  isSnowPlowRoute: boolean;
}

const MaterialTripsSummaryTableRow: FC<Props> = ({
  activityTimeLocal,
  image,
  isSnowPlowRoute,
  isTicketAddedByAssistingVehicle,
  locationAddress,
  locationName,
  materialAmount,
  materialType,
  routeDate,
  routeName,
  ticketNumber,
  unitOfMeasureType,
  vehicleName,
  vehicleType,
}) => {
  const [isRouteImagesModalOpen, setIsRouteImagesModalOpen] = useState(false);

  const openRouteImagesModal = () => {
    setIsRouteImagesModalOpen(true);
  };

  const closeRouteImagesModal = () => {
    setIsRouteImagesModalOpen(false);
  };

  const weightTicket = { ticketNumber, disposalSiteAddress: locationAddress, image, customerName: ticketNumber };

  return (
    <>
      <TableRow>
        <TableCell width={isSnowPlowRoute ? '10%' : '14%'}>
          <Text margin="no no xxSmall" singleLine>
            {date(routeDate)}
          </Text>
        </TableCell>
        {isSnowPlowRoute && <TableCell width="9%">{ticketNumber}</TableCell>}
        <TableCell width={isSnowPlowRoute ? '12%' : '16%'} vertical justifyContent="center">
          {vehicleName ? (
            <>
              <Text weight="medium" margin="no no xxSmall" hasIcon={isTicketAddedByAssistingVehicle && !!vehicleName}>
                {vehicleName}
                {isTicketAddedByAssistingVehicle && vehicleName && (
                  <ActionButtonTooltip
                    margin="no no no xSmall"
                    icon="infoFill"
                    tooltip={translate('routeAssist.assistingVehicle')}
                    tooltipAsString
                    iconSize="sMedium"
                    color="alert"
                    hasTextWithIcon={true}
                  />
                )}
              </Text>
              <Text weight="light" margin="no">
                {vehicleType?.name}
              </Text>
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell width={isSnowPlowRoute ? '12%' : '16%'}>
          <Text weight="light" margin="no no xxSmall" title={routeName}>
            {routeName}
          </Text>
        </TableCell>
        <TableCell width="9%">
          {materialType
            ? translate(`common.materialTickets.materialTypeValues.${camelCase(materialType.technicalName)}`)
            : '-'}
        </TableCell>
        <TableCell width="9%">{materialAmount || '-'}</TableCell>
        <TableCell width="9%">{unitOfMeasureType ? unitOfMeasureType?.name : '-'}</TableCell>
        <TableCell width="11%" vertical justifyContent="center">
          <Text weight="medium" margin="no no xxSmall" singleLine>
            {activityTimeLocal ? date(activityTimeLocal) : '-'}
          </Text>
          <Text weight="light" margin="no" singleLine>
            {time(activityTimeLocal)}
          </Text>
        </TableCell>
        <TableCell width={isSnowPlowRoute ? '11%' : '16%'}>{locationName}</TableCell>
        {isSnowPlowRoute && (
          <TableCell width="8%" align="center">
            {!!image && image.url && (
              <ThumbnailSet
                id={`open-route-${kebabCase(routeName)}-images-button`}
                images={[image.url]}
                onClick={openRouteImagesModal}
              />
            )}
          </TableCell>
        )}
      </TableRow>

      {!!isRouteImagesModalOpen && (
        <WeightTicketImagesModal weightTicket={weightTicket} closeModal={closeRouteImagesModal} />
      )}
    </>
  );
};

export default MaterialTripsSummaryTableRow;
