import React, { MouseEvent, PureComponent, Fragment } from 'react';

import { Clusterer } from '@react-google-maps/marker-clusterer';
import { findIndex, get, map, size } from 'lodash-es';
import { InfoWindow, Marker } from '@react-google-maps/api';
import update from 'immutability-helper';

import { ActionButtonTooltip } from '../../core/components';
import { CustomerProximitySearchModalResolver } from '../../customers/components/modals';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import {
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  TableActionButton,
} from '../../core/components/styled';
import { NEW } from 'src/common/constants/accountStatuses';
import { PICKUP_STATUSES, GOOGLE as google } from '../../common/constants';
import { ROUTE_PICKUP_TYPE_IDS } from '../../routes/constants';
import confirm from '../../core/services/confirm';
import locationPin from '../../common/assets/img/common/locationPin.svg';
import locationWithAlertPin from '../../common/assets/img/common/locationWithAlertPin.svg';
import mapPinCircle from '../../common/assets/img/common/mapPinCirclePrimary.svg';
import newLocationPin from '../../common/assets/img/common/newLocationPin.svg';
import newLocationWithAlertsPin from '../../common/assets/img/common/newLocationWithAlertsPin.png';
import translate from '../../core/services/translate';

interface Props {
  asMapboxMarker?: boolean;
  clusterer?: Clusterer;
  mapBounds?: any;
  currentMapZoom?: number;
  geoFenceEditing?: boolean;
  index?: number;
  isInfoWindowOpen?: boolean;
  isPinDraggable?: boolean;
  isRoutePlannerPage?: boolean;
  onAddressChange?: (index?: number, id?: number, address?: any) => void;
  routeLocation: any;
  toggleInfoWindow?: (index?: string) => any;
}

interface State {
  isCustomerProximitySearchModalOpen: boolean;
  markerPositions: any[];
  popupMarkerPositions?: any;
  selectedLocation?: number;
  startAddress?: any;
}

const markerIcon = {
  url: mapPinCircle,
  size: new google.maps.Size(12, 12),
  scaledSize: new google.maps.Size(12, 12),
  anchor: new google.maps.Point(6, 6),
};

class RouteLocationMarker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState = ({ routeLocation: { service, latitude, longitude } }: any) => ({
    isCustomerProximitySearchModalOpen: false,
    markerPositions: map(get(service, 'serviceContractBinDetails'), serviceContractBinDetail => ({
      id: serviceContractBinDetail.id,
      lat: serviceContractBinDetail.displayLatitude || latitude,
      lng: serviceContractBinDetail.displayLongitude || longitude,
    })),
    popupMarkerPositions: undefined,
    selectedLocation: undefined,
    startAddress: undefined,
  });

  componentDidUpdate(prevProps: Props) {
    const {
      routeLocation: { service, latitude, longitude },
    } = this.props;
    if (prevProps.routeLocation !== this.props.routeLocation) {
      const markerPositions = map(get(service, 'serviceContractBinDetails'), serviceContractBinDetail => ({
        id: serviceContractBinDetail.id,
        lat: serviceContractBinDetail.displayLatitude || latitude,
        lng: serviceContractBinDetail.displayLongitude || longitude,
      }));
      this.setState({ markerPositions });
    }
  }

  onDragEnd = async (address: any, id: number) => {
    const { onAddressChange, index } = this.props;
    const markerIndex = findIndex(this.state.markerPositions, position => position.id === id);
    this.setAddress(address, markerIndex);
    if (!(await confirm(translate('routes.alertMessages.confirmChangeLocation')))) {
      this.resetAddress(markerIndex);
      return;
    }
    onAddressChange && onAddressChange(index, id, address);
  };

  onDragStart = (startAddress: any) => this.setState({ startAddress });

  setAddress = (address: any, markerIndex: number) =>
    this.setState(prevState => ({
      markerPositions: update(prevState.markerPositions, {
        [markerIndex]: {
          lat: { $set: address.latLng.lat() },
          lng: { $set: address.latLng.lng() },
        },
      }),
    }));

  resetAddress = (markerIndex: number) =>
    this.setState(prevState => ({
      markerPositions: update(prevState.markerPositions, {
        [markerIndex]: {
          lat: { $set: prevState.startAddress.latLng?.lat() },
          lng: { $set: prevState.startAddress.latLng.lng() },
        },
      }),
    }));

  toggleInfoWindow = (popupMarkerPositions?: any) => {
    const { index, toggleInfoWindow } = this.props;

    this.setState({ popupMarkerPositions });
    toggleInfoWindow && toggleInfoWindow(`routeLocations.${index}`);
  };

  openProximitySearchModal = (event: MouseEvent<HTMLButtonElement>, locationId: number) => {
    event.stopPropagation();
    this.setState({
      isCustomerProximitySearchModalOpen: true,
      selectedLocation: locationId,
    });
  };

  closeCustomerProximitySearchModal = () => {
    this.setState({ isCustomerProximitySearchModalOpen: false, selectedLocation: undefined });
  };

  render() {
    const {
      asMapboxMarker,
      clusterer,
      geoFenceEditing,
      index,
      isInfoWindowOpen,
      isPinDraggable,
      isRoutePlannerPage,
      routeLocation: {
        accountStatusId,
        customer,
        id,
        location,
        newOrderNumber,
        orderNumber,
        pickupStatusId,
        pickupTypeId,
        serviceContractRouteTemplateId,
      },
      mapBounds,
      currentMapZoom,
    } = this.props;

    const { isCustomerProximitySearchModalOpen, markerPositions, popupMarkerPositions, selectedLocation } = this.state;

    const isDraggable = isPinDraggable && !!id;
    const pixelOffset = new google.maps.Size(0, -16);

    const jobIsPendingOptimization = orderNumber === JOB_PENDING_OPTIMIZATION_ID;

    const getInfoWindowContent = () => {
      return (
        <MapInfoWindow>
          <MapInfoWindowDetails>
            {newOrderNumber && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('routes.newStopNumber')}:</MapInfoWindowDetailLabel>
                {newOrderNumber}
              </MapInfoWindowDetail>
            )}
            <MapInfoWindowDetail>
              <MapInfoWindowDetailLabel>{translate('routes.stopNumber')}:</MapInfoWindowDetailLabel>
              {jobIsPendingOptimization ? translate('routes.pendingOptimization') : orderNumber}
            </MapInfoWindowDetail>

            <MapInfoWindowDetail>
              <MapInfoWindowDetailLabel>{translate('common.customer')}:</MapInfoWindowDetailLabel>
              {customer.name}

              <TableActionButton
                margin="no no no xSmall"
                color="primary"
                onClick={event => this.openProximitySearchModal(event, id)}
              >
                <ActionButtonTooltip
                  icon="searchVehicle"
                  size="sMedium"
                  sourceIsInfoWindow
                  tooltip="customerProximitySearch"
                />
              </TableActionButton>
            </MapInfoWindowDetail>

            <MapInfoWindowDetail>
              <MapInfoWindowDetailLabel>{translate('common.location')}:</MapInfoWindowDetailLabel>
              {location.name}
            </MapInfoWindowDetail>

            <MapInfoWindowDetail>
              <MapInfoWindowDetailLabel>{translate('common.address')}:</MapInfoWindowDetailLabel>
              {location.address.line1}
            </MapInfoWindowDetail>

            {ROUTE_PICKUP_TYPE_IDS[pickupTypeId] && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('routes.pickupType')}:</MapInfoWindowDetailLabel>
                {ROUTE_PICKUP_TYPE_IDS[pickupTypeId].name}
              </MapInfoWindowDetail>
            )}

            {!!pickupStatusId && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.status')}:</MapInfoWindowDetailLabel>
                {PICKUP_STATUSES[pickupStatusId].name}
              </MapInfoWindowDetail>
            )}
          </MapInfoWindowDetails>

          {!!isCustomerProximitySearchModalOpen && (
            <CustomerProximitySearchModalResolver
              onCancel={this.closeCustomerProximitySearchModal}
              locationId={selectedLocation}
            />
          )}
        </MapInfoWindow>
      );
    };

    const isNewStatusAccount = isRoutePlannerPage && accountStatusId === NEW;
    const isLocationWithAlert = isRoutePlannerPage && location?.hasVendorLocationAlert;
    const pinUrl =
      isNewStatusAccount && isLocationWithAlert
        ? newLocationWithAlertsPin
        : isNewStatusAccount
        ? newLocationPin
        : isLocationWithAlert
        ? locationWithAlertPin
        : isRoutePlannerPage
        ? locationPin
        : markerIcon.url;

    const markerLabel =
      currentMapZoom &&
      currentMapZoom >= 17 &&
      isRoutePlannerPage &&
      mapBounds &&
      !jobIsPendingOptimization &&
      mapBounds.contains(markerPositions[0])
        ? {
            text: `${orderNumber}`,
            className: 'markerLabel',
          }
        : undefined;

    return asMapboxMarker ? (
      getInfoWindowContent()
    ) : (
      <Fragment>
        {!!size(markerPositions) && (
          <Marker
            key={index}
            icon={{
              url: pinUrl,
              size: markerIcon.size,
              scaledSize: markerIcon.scaledSize,
              anchor: markerIcon.anchor,
              labelOrigin: new google.maps.Point(6.5, 20),
            }}
            position={markerPositions[0]}
            onClick={() => this.toggleInfoWindow(markerPositions[0])}
            draggable={geoFenceEditing ? false : isDraggable}
            onDragStart={address => this.onDragStart(address)}
            onDragEnd={address => this.onDragEnd(address, markerPositions[0].id)}
            clusterer={clusterer}
            title={!jobIsPendingOptimization ? `${translate('routes.stopNumber')}: ${orderNumber}` : undefined}
            label={markerLabel}
          />
        )}
        {isInfoWindowOpen && popupMarkerPositions && (
          <InfoWindow onCloseClick={this.toggleInfoWindow} position={popupMarkerPositions} options={{ pixelOffset }}>
            <MapInfoWindow>
              <MapInfoWindowDetails>
                {(newOrderNumber || newOrderNumber === 0) && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('routes.newStopNumber')}:</MapInfoWindowDetailLabel>
                    {newOrderNumber}
                  </MapInfoWindowDetail>
                )}
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('routes.stopNumber')}:</MapInfoWindowDetailLabel>
                  {jobIsPendingOptimization ? <em>{translate('routes.pendingOptimization')}</em> : orderNumber}
                </MapInfoWindowDetail>

                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('common.customer')}:</MapInfoWindowDetailLabel>
                  {customer.name}

                  <TableActionButton
                    margin="no no no xSmall"
                    color="primary"
                    onClick={event => this.openProximitySearchModal(event, serviceContractRouteTemplateId || id)}
                  >
                    <ActionButtonTooltip
                      icon="searchVehicle"
                      size="sMedium"
                      sourceIsInfoWindow
                      tooltip="customerProximitySearch"
                    />
                  </TableActionButton>
                </MapInfoWindowDetail>

                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('common.location')}:</MapInfoWindowDetailLabel>
                  {location.name}
                </MapInfoWindowDetail>

                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('common.address')}:</MapInfoWindowDetailLabel>
                  {location.address.line1}
                </MapInfoWindowDetail>

                {ROUTE_PICKUP_TYPE_IDS[pickupTypeId] && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('routes.pickupType')}:</MapInfoWindowDetailLabel>
                    {ROUTE_PICKUP_TYPE_IDS[pickupTypeId].name}
                  </MapInfoWindowDetail>
                )}

                {!!pickupStatusId && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.status')}:</MapInfoWindowDetailLabel>
                    {PICKUP_STATUSES[pickupStatusId].name}
                  </MapInfoWindowDetail>
                )}
              </MapInfoWindowDetails>

              {!!isCustomerProximitySearchModalOpen && (
                <CustomerProximitySearchModalResolver
                  onCancel={this.closeCustomerProximitySearchModal}
                  locationId={selectedLocation}
                />
              )}
            </MapInfoWindow>
          </InfoWindow>
        )}
      </Fragment>
    );
  }
}

export default RouteLocationMarker;
