import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import arrow from 'src/common/assets/img/common/arrowSmall.png';
import dot from 'src/common/assets/img/common/dot.png';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  getMapboxVehicleTrackingsForRouteGeoJSON,
  getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON,
  VehicleTrackingFeatureProperties,
} from 'src/dashboard/components/pages/dashboardPageSections/mapGL/dashboardVehicleTrackings/utils';
import { RouteDriversVehiclesItem, RouteVehiclesBreadCrumbsItem } from 'src/dashboard/interfaces/routesData';
import YRouteMapVehicleTrackingsGLPopup from 'src/routes/components/pages/yRoute/yRoutePageSections/yRouteMap/YRouteMapVehicleTrackingsGLPopup';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import {
  ROUTE_MAP_CLUSTERS_SOURCE,
  ROUTE_MAP_VEHICLE_TRACKINGS_POINTS_SOURCE,
  ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
} from '../constants';
import RouteMapVehicleTrackingsGLPopupResolver from './RouteMapVehicleTrackingsGLPopupResolver';
import RouteMapVehicleTrackingsGLSource from './RouteMapVehicleTrackingsGLSource';

const mapImages = [
  {
    id: 'arrow',
    url: arrow,
    sourceId: ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
  {
    id: 'dot',
    url: dot,
    sourceId: ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
];

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
  isYRoute?: boolean;
  vehicleTrackings: RouteVehiclesBreadCrumbsItem[];
  isPlaybackMode?: boolean;
};

export default function RouteMapVehicleTrackingsGL({ map, vehicleTrackings, isYRoute, isPlaybackMode }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  const vehicleFilters = useSelector(
    state => state.routes.routeMapVehicleData.filters,
  ) as unknown as RouteDriversVehiclesItem[];

  const geoJSONForRoute = useMemo(() => {
    return {
      lineFeature: getMapboxVehicleTrackingsForRouteGeoJSON(vehicleTrackings || [], vehicleFilters),
      pointFeature: getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON(vehicleTrackings || [], vehicleFilters),
    };
  }, [vehicleTrackings, vehicleFilters]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const feature = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_VEHICLE_TRACKINGS_POINTS_SOURCE ||
              feature.source === ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
          )[0] as any as GeoJSON.Feature<GeoJSON.LineString, VehicleTrackingFeatureProperties> | undefined;

        const [otherFeatures] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === ROUTE_MAP_CLUSTERS_SOURCE);

        if (!!feature && !otherFeatures) {
          /**
           * Yes, setting the id from the properties since Mapbox removes the floating numbers
           * from the main id of the feature. We need to set some other ids for these things,
           * but API changes will be necessary.
           */

          dispatch(clearRouteMapSelectedFeature());
          dispatch(
            setRouteMapSelectedFeature(RouteMapFeature.vehicleTrackings, feature.properties.vehicleId, {
              coordinates: event.lngLat,
              vehicleTrackingGroupIndex: feature.properties.groupIndex,
            }),
          );
        }
      });
    });
  }, [map, dispatch, vehicleTrackings]);

  return (
    <>
      <RouteMapVehicleTrackingsGLSource
        geoJSONForLine={geoJSONForRoute.lineFeature}
        geoJSONForPoints={geoJSONForRoute.pointFeature}
      />
      {isYRoute ? <YRouteMapVehicleTrackingsGLPopup /> : <RouteMapVehicleTrackingsGLPopupResolver />}
    </>
  );
}
