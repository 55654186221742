import { useDispatch } from 'react-redux';
import { FC, useMemo } from 'react';

import { AutomaticPaymentsModal } from './AutomaticPaymentsModal';
import { loadLocationPaymentMethods } from 'src/customers/ducks/paymentMethods';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';

export const AutomaticPaymentsModalResolver: FC<{
  closeModal: () => void;
  onSubmit: (paymentConnectPaymentMethodId?: string) => void;
  onUnenrollAutomaticPayments: () => void;
  selectedLocationId: number;
}> = ({ closeModal, onSubmit, onUnenrollAutomaticPayments, selectedLocationId }) => {
  const dispatch = useDispatch();

  const dependency = useMemo(
    () => () => loadLocationPaymentMethods(selectedLocationId)(dispatch).catch(() => {}),
    [selectedLocationId, dispatch],
  );

  return (
    <TypedResolver
      resolve={dependency}
      successProps={{ closeModal, onSubmit, onUnenrollAutomaticPayments }}
      loadingComponent={PageLoadingOverlay}
      successComponent={AutomaticPaymentsModal}
    />
  );
};
