import { camelCase } from 'lodash-es';
import { SortableElement } from 'react-sortable-hoc';
import humps from 'humps';
import React from 'react';

import { AppState } from 'src/store';
import { Container, FormError, TableCell, TableRow } from '../../../core/components/styled';
import { DragHandle, UnconnectedDropdown, UnconnectedInput, UnconnectedSwitch } from '../../../core/components';
import { PICTURE_OPTIONS } from '../../constants';
import { TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT_LARGEST } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../core/services/translate';

interface Props {
  billingModuleEnabled: boolean;
  canAddDetail: number;
  canTakePicture: number;
  changeChargeAmount(id: number, value: any): void;
  chargeAmount: string;
  createAdditionalCharge: boolean;
  defaultPickupExceptionSubTypeId: number;
  id: number;
  isEnabled: boolean;
  onChangeAccountingCode(id: number, value: any): void;
  onChangeCanAddDetailPermissions(id: number, value: any): void;
  onChangePickupExceptionSubType(id: number, value: any): void;
  onChangePicturePermissions(id: number, value: any): void;
  onChangeRateCode(id: number, value: any): void;
  pickupExceptionSubTypes: any[];
  rateAccountingCodeId: number;
  rateCodeId: number;
  tableCellWidths: { [key: string]: string };
  technicalName: string;
  toggleCreateAdditionalCharge(id: number, value: any): void;
  toggleExceptionFlag(id: number, value: any): void;
  useExceptionCharges?: boolean;
}

const ExceptionConfigurationModalTableRow: React.FC<Props> = ({
  billingModuleEnabled,
  canAddDetail,
  canTakePicture,
  changeChargeAmount,
  chargeAmount,
  createAdditionalCharge,
  defaultPickupExceptionSubTypeId,
  id,
  isEnabled,
  onChangeAccountingCode,
  onChangeCanAddDetailPermissions,
  onChangePickupExceptionSubType,
  onChangePicturePermissions,
  onChangeRateCode,
  pickupExceptionSubTypes,
  rateAccountingCodeId,
  rateCodeId,
  tableCellWidths,
  technicalName,
  toggleCreateAdditionalCharge,
  toggleExceptionFlag,
  useExceptionCharges,
}) => {
  const rateAccountingCodes = useSelector((state: AppState) => state.common.rateAccountingCodes.rateAccountingCodes);
  const rateCodeTypes = useSelector((state: AppState) => state.common.rateCodeTypes.rateCodes);

  const canAddDetailOptions = PICTURE_OPTIONS;

  const pickupExceptionSubTypesOptions = pickupExceptionSubTypes?.map(pickupExceptionSubType => {
    return {
      label: translate(
        `routes.pickupIssueTypes.exceptionSubTypes.${humps.camelize(pickupExceptionSubType.technicalName)}`,
      ),
      value: pickupExceptionSubType.id,
    };
  });

  return (
    <TableRow height={billingModuleEnabled ? TABLE_ROW_HEIGHT_LARGEST : TABLE_ROW_HEIGHT}>
      <TableCell padding="small" width={tableCellWidths.dragHandle}>
        <Container>
          <DragHandle />
        </Container>
      </TableCell>

      <TableCell width={tableCellWidths.featureName}>
        {translate(`routes.pickupIssueTypes.${camelCase(technicalName)}`)}
      </TableCell>

      <TableCell width={tableCellWidths.enabled}>
        <UnconnectedSwitch checked={isEnabled} onChange={(value: any) => toggleExceptionFlag(id, value)} />
      </TableCell>

      <TableCell width={tableCellWidths.versionNumber} align="center">
        <Container>
          <UnconnectedDropdown
            margin="no"
            options={PICTURE_OPTIONS}
            value={canTakePicture}
            disabled={!isEnabled}
            onChange={(value: any) => onChangePicturePermissions(id, value)}
          />
        </Container>
      </TableCell>
      <TableCell width={tableCellWidths.canAddDetail} align="center">
        <Container>
          <UnconnectedDropdown
            margin="no"
            options={canAddDetailOptions}
            value={canAddDetail}
            disabled={!isEnabled}
            onChange={(value: any) => onChangeCanAddDetailPermissions(id, value)}
          />
        </Container>
      </TableCell>
      <TableCell width={tableCellWidths.exceptionSubTypes} align="center">
        <Container>
          <UnconnectedDropdown
            margin="no"
            options={pickupExceptionSubTypesOptions}
            value={defaultPickupExceptionSubTypeId}
            disabled={!isEnabled}
            onChange={(value: any) => onChangePickupExceptionSubType(id, value)}
            isClearable={true}
          />
        </Container>
      </TableCell>
      {billingModuleEnabled && useExceptionCharges && (
        <>
          <TableCell width={tableCellWidths.createAdditionalCharge}>
            <UnconnectedSwitch
              checked={createAdditionalCharge}
              onChange={(value: any) => toggleCreateAdditionalCharge(id, value)}
              disabled={!isEnabled || !billingModuleEnabled}
            />
          </TableCell>
          <TableCell width={tableCellWidths.rateAmount} align="center">
            <Container>
              <UnconnectedDropdown
                margin="no"
                options={rateCodeTypes}
                value={rateCodeId}
                disabled={!isEnabled || !createAdditionalCharge || !billingModuleEnabled}
                onChange={(value: any) => onChangeRateCode(id, value)}
                isClearable={true}
                placeholder={translate('common.rateCode')}
                menuPosition="fixed"
              />
              <UnconnectedDropdown
                margin="no"
                options={rateAccountingCodes}
                value={rateAccountingCodeId}
                disabled={!isEnabled || !createAdditionalCharge || !billingModuleEnabled}
                onChange={(value: any) => onChangeAccountingCode(id, value)}
                isClearable={true}
                placeholder={translate('common.rateAccountingCode')}
                menuPosition="fixed"
              />

              {createAdditionalCharge && (!rateCodeId || !rateAccountingCodeId) && (
                <FormError errorOnSingleLine errorTopPosition="60px">
                  {translate('common.validationMessages.bothFieldsAreRequired')}
                </FormError>
              )}
            </Container>
          </TableCell>
          <TableCell width={tableCellWidths.chargeAmount}>
            <UnconnectedInput
              margin="no no no no"
              disabled={!isEnabled || !createAdditionalCharge || !billingModuleEnabled}
              preventNumberExponent
              align="left"
              type="number"
              prepend="$"
              isCurrency={true}
              value={chargeAmount}
              onChange={(value: any) => changeChargeAmount(id, value)}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

 export default SortableElement(ExceptionConfigurationModalTableRow);
