import axios from 'axios';
import update from 'immutability-helper';
import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';

import translate from 'src/core/services/translate';
import store from 'src/store';
import { dateTimeFormat } from 'src/utils/services/validator';
import {
  AlternativeFleetStreetSegment,
  AlternativeFleetStreetSegmentsPayload,
  NameIdAndRouteType,
} from '../interfaces/alterativeFleetOps';
import {
  loadAlternativeFleetRoutes as doLoadAlternativeFleetRoutes,
  loadAlternativeFleetSegments as doLoadAlternativeFleetSegments,
  loadAlternativeFleetSnowPlowServiceActivities as doLoadAlternativeFleetSnowPlowServiceActivities,
  loadAlternativeFleetStreetSweeperServiceActivities as doLoadAlternativeFleetStreetSweeperServiceActivities,
} from '../services/alternativeFleetOps';
import { filterDashboardSegments } from '../utils/alternativeFleet';

const CancelToken = axios.CancelToken;
let cancelAlternativeFleetStreetSegmentsRequest: any;

//actions
const START_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES =
  'dashboard/alternativeFleetOps/START_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES';
const COMPLETE_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES =
  'dashboard/alternativeFleetOps/COMPLETE_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES';
const FAIL_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES =
  'dashboard/alternativeFleetOps/FAIL_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES';
const START_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES =
  'dashboard/alternativeFleetOps/START_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES';
const COMPLETE_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES =
  'dashboard/alternativeFleetOps/COMPLETE_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES';
const FAIL_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES =
  'dashboard/alternativeFleetOps/FAIL_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES';
const START_LOAD_ALTERNATIVE_FLEET_ROUTES = 'dashboard/alternativeFleetOps/START_LOAD_ALTERNATIVE_FLEET_ROUTES';
const COMPLETE_LOAD_ALTERNATIVE_FLEET_ROUTES = 'dashboard/alternativeFleetOps/COMPLETE_LOAD_ALTERNATIVE_FLEET_ROUTES';
const FAIL_LOAD_ALTERNATIVE_FLEET_ROUTES = 'dashboard/alternativeFleetOps/FAIL_LOAD_ALTERNATIVE_FLEET_ROUTES';
const START_LOAD_ALTERNATIVE_FLEET_SEGMENTS = 'dashboard/alternativeFleetOps/START_LOAD_ALTERNATIVE_FLEET_SEGMENTS';
const COMPLETE_LOAD_ALTERNATIVE_FLEET_SEGMENTS =
  'dashboard/alternativeFleetOps/COMPLETE_LOAD_ALTERNATIVE_FLEET_SEGMENTS';
const FAIL_LOAD_ALTERNATIVE_FLEET_SEGMENTS = 'dashboard/alternativeFleetOps/FAIL_LOAD_ALTERNATIVE_FLEET_SEGMENTS';
const COMPLETE_FILTER_ALTERNATIVE_FLEET_SEGMENTS =
  'dashboard/alternativeFleetOps/COMPLETE_FILTER_ALTERNATIVE_FLEET_SEGMENTS';

const RESET = 'dashboard/alternativeFleetOps/RESET';

// initial state
const initialState = {
  isLoadingSnowPlowServiceActivities: false,
  snowPlowServiceActivities: [] as TechnicalType[],
  isLoadingStreetSweeperServiceActivities: false,
  streetSweeperServiceActivities: [] as TechnicalType[],
  isLoadingRoutes: false,
  routes: [] as NameIdAndRouteType[],
  isLoadingServiceTypes: false,
  isLoadingSegments: false,
  segments: [] as AlternativeFleetStreetSegment[],
  filteredSegments: [] as AlternativeFleetStreetSegment[],
  lastRefreshed: '',
};

// reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES:
      return update(state, {
        $merge: {
          isLoadingSnowPlowServiceActivities: true,
        },
      });
    case COMPLETE_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES:
      return update(state, {
        $merge: {
          isLoadingSnowPlowServiceActivities: false,
          snowPlowServiceActivities: action.payload,
        },
      });
    case FAIL_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES:
      return update(state, {
        $merge: {
          isLoadingSnowPlowServiceActivities: false,
        },
      });
    case START_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES:
      return update(state, {
        $merge: {
          isLoadingStreetSweeperServiceActivities: true,
        },
      });
    case COMPLETE_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES:
      return update(state, {
        $merge: {
          isLoadingStreetSweeperServiceActivities: false,
          streetSweeperServiceActivities: action.payload,
        },
      });
    case FAIL_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES:
      return update(state, {
        $merge: {
          isLoadingStreetSweeperServiceActivities: false,
        },
      });
    case START_LOAD_ALTERNATIVE_FLEET_ROUTES:
      return update(state, {
        $merge: {
          isLoadingRoutes: true,
        },
      });
    case COMPLETE_LOAD_ALTERNATIVE_FLEET_ROUTES:
      return update(state, {
        $merge: {
          isLoadingRoutes: false,
          routes: action.payload,
        },
      });
    case FAIL_LOAD_ALTERNATIVE_FLEET_ROUTES:
      return update(state, {
        $merge: {
          isLoadingRoutes: false,
        },
      });
    case START_LOAD_ALTERNATIVE_FLEET_SEGMENTS:
      return update(state, {
        $merge: {
          isLoadingSegments: true,
        },
      });
    case COMPLETE_LOAD_ALTERNATIVE_FLEET_SEGMENTS:
      return update(state, {
        $merge: {
          isLoadingSegments: false,
          segments: action.payload,
          filteredSegments: action.payload,
          lastRefreshed: moment().format(dateTimeFormat),
        },
      });
    case FAIL_LOAD_ALTERNATIVE_FLEET_SEGMENTS:
      return update(state, {
        $merge: {
          isLoadingSegments: false,
        },
      });

    case COMPLETE_FILTER_ALTERNATIVE_FLEET_SEGMENTS:
      return update(state, {
        $merge: {
          filteredSegments: action.payload,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// action creators
const startLoadAlternativeFleetSnowPlowServiceActivities = () => ({
  type: START_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES,
});
const completeLoadAlternativeFleetSnowPlowServiceActivities = (serviceActivities: TechnicalType[]) => ({
  type: COMPLETE_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES,
  payload: serviceActivities,
});
const failLoadAlternativeFleetSnowPlowServiceActivities = () => ({
  type: FAIL_LOAD_ALTERNATIVE_FLEET_SNOW_PLOW_SERVICE_ACTIVITIES,
});
const startLoadAlternativeFleetStreetSweeperServiceActivities = () => ({
  type: START_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES,
});
const completeLoadAlternativeFleetStreetSweeperServiceActivities = (serviceActivities: TechnicalType[]) => ({
  type: COMPLETE_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES,
  payload: serviceActivities,
});
const failLoadAlternativeFleetStreetSweeperServiceActivities = () => ({
  type: FAIL_LOAD_ALTERNATIVE_FLEET_STREET_SWEEPER_SERVICE_ACTIVITIES,
});
const startLoadAlternativeFleetRoutes = () => ({
  type: START_LOAD_ALTERNATIVE_FLEET_ROUTES,
});
const completeLoadAlternativeFleetRoutes = (routes: NameIdAndRouteType[]) => ({
  type: COMPLETE_LOAD_ALTERNATIVE_FLEET_ROUTES,
  payload: routes,
});
const failLoadAlternativeFleetRoutes = () => ({
  type: FAIL_LOAD_ALTERNATIVE_FLEET_ROUTES,
});
const startLoadAlternativeFleetSegments = () => ({
  type: START_LOAD_ALTERNATIVE_FLEET_SEGMENTS,
});
const completeLoadAlternativeFleetSegments = (segments: AlternativeFleetStreetSegment[]) => ({
  type: COMPLETE_LOAD_ALTERNATIVE_FLEET_SEGMENTS,
  payload: segments,
});
const failLoadAlternativeFleetSegments = () => ({
  type: FAIL_LOAD_ALTERNATIVE_FLEET_SEGMENTS,
});

const completeFilterAlternativeFleetSegments = (segments: AlternativeFleetStreetSegment[]) => ({
  type: COMPLETE_FILTER_ALTERNATIVE_FLEET_SEGMENTS,
  payload: segments,
});

export const resetAlternativeFleetOps = () => ({
  type: RESET,
});

// thunks
export const loadAlternativeFleetSnowPlowServiceActivities = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadAlternativeFleetSnowPlowServiceActivities());
  const loadAlternativeFleetSnowPlowServiceActivitiesPromise =
    doLoadAlternativeFleetSnowPlowServiceActivities(vendorId);
  loadAlternativeFleetSnowPlowServiceActivitiesPromise
    .then((serviceActivities: TechnicalType[]) => {
      dispatch(completeLoadAlternativeFleetSnowPlowServiceActivities(serviceActivities));
    })
    .catch(() => {
      dispatch(failLoadAlternativeFleetSnowPlowServiceActivities());
    });
  return loadAlternativeFleetSnowPlowServiceActivitiesPromise;
};

export const loadAlternativeFleetStreetSweeperServiceActivities = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadAlternativeFleetStreetSweeperServiceActivities());
  const loadAlternativeFleetStreetSweeperServiceActivitiesPromise =
    doLoadAlternativeFleetStreetSweeperServiceActivities(vendorId);
  loadAlternativeFleetStreetSweeperServiceActivitiesPromise
    .then((serviceActivities: TechnicalType[]) => {
      dispatch(completeLoadAlternativeFleetStreetSweeperServiceActivities(serviceActivities));
    })
    .catch(() => {
      dispatch(failLoadAlternativeFleetStreetSweeperServiceActivities());
    });
  return loadAlternativeFleetStreetSweeperServiceActivitiesPromise;
};

export const loadAlternativeFleetRoutes = (vendorId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadAlternativeFleetRoutes());
  const loadAlternativeFleetRoutesPromise = doLoadAlternativeFleetRoutes(vendorId, vehicleTypeId);
  loadAlternativeFleetRoutesPromise
    .then((routes: NameIdAndRouteType[]) => {
      dispatch(completeLoadAlternativeFleetRoutes(routes));
    })
    .catch(() => {
      dispatch(failLoadAlternativeFleetRoutes());
    });
  return loadAlternativeFleetRoutesPromise;
};

export const loadAlternativeFleetSegments =
  (
    params: AlternativeFleetStreetSegmentsPayload,
    filterMode: 'lastActivity' | 'pickupStatus' = 'lastActivity',
    noLoadingIndicator?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    if (cancelAlternativeFleetStreetSegmentsRequest)
      cancelAlternativeFleetStreetSegmentsRequest(translate('common.inFlightRequestCanceled'));

    !noLoadingIndicator && dispatch(startLoadAlternativeFleetSegments());

    const cancelToken = new CancelToken(c => {
      cancelAlternativeFleetStreetSegmentsRequest = c;
    });

    const loadAlternativeFleetSegmentsPromise = doLoadAlternativeFleetSegments(params, cancelToken);
    loadAlternativeFleetSegmentsPromise
      .then((segments: AlternativeFleetStreetSegment[]) => {
        const filteredSegments = filterDashboardSegments(
          params.vehicleTypeId,
          segments,
          params.lastActivity || [],
          params.pickupStatusTypeIds || [],
          filterMode,
        );
        dispatch(completeLoadAlternativeFleetSegments(segments));
        dispatch(completeFilterAlternativeFleetSegments(filteredSegments));
      })
      .catch(er => {
        if (!axios.isCancel(er)) {
          dispatch(failLoadAlternativeFleetSegments());
        }
      });
    return loadAlternativeFleetSegmentsPromise;
  };

export const filterAlternativeFleetSegments =
  (
    vehicleTypeId: number,
    lastActivity: number[],
    pickupStatusTypeIds: number[],
    filterMode: 'lastActivity' | 'pickupStatus' = 'lastActivity',
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const segments = store.getState().dashboard.alternativeFleetOps.segments;
      const isLoadingSegments = store.getState().dashboard.alternativeFleetOps.isLoadingSegments;

      if (!segments.length || isLoadingSegments) {
        dispatch(completeFilterAlternativeFleetSegments([]));
        return;
      }

      const filteredSegments = filterDashboardSegments(
        vehicleTypeId,
        segments,
        lastActivity,
        pickupStatusTypeIds,
        filterMode,
      );
      dispatch(completeFilterAlternativeFleetSegments(filteredSegments));
    } catch {
      dispatch(completeFilterAlternativeFleetSegments([]));
    }
  };
