import React, { Fragment } from 'react';

import StreetSweeperTrackingGroup from './StreetSweeperTrackingGroup';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';

type StreetSweeperTracking = any;

interface Props {
  mapBounds: any;
  mapZoom: number;
  openedInfoWindows: OpenedInfoWindows;
  streetSweeperTrackings: StreetSweeperTracking[];
  toggleInfoWindow: (key: string) => void;
  unitOfMeasure: string;
  vehicleName: string;
}

const StreetSweeperTrackings: React.SFC<Props> = ({
  streetSweeperTrackings,
  openedInfoWindows,
  toggleInfoWindow,
  mapZoom,
  mapBounds,
  unitOfMeasure,
  vehicleName,
}) => (
  <Fragment>
    {streetSweeperTrackings.map((streetSweeperTracking, index) => (
      <StreetSweeperTrackingGroup
        key={index}
        coordinateGroup={streetSweeperTracking}
        vehicleName={vehicleName}
        unitOfMeasure={unitOfMeasure}
        mapZoom={mapZoom}
        mapBounds={mapBounds}
        openedInfoWindows={openedInfoWindows}
        toggleInfoWindow={toggleInfoWindow}
        index={index}
      />
    ))}
  </Fragment>
);

export default StreetSweeperTrackings;
