import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Icon from '../Icon';
import IconMedium from '../IconMedium';
import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';
import { BareButtonStyle } from './Button';
import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';

interface CommonProps {
  align?: string;
  alignItems?: string;
  backgroundColor?: string;
  borderBottom?: string;
  borderColor?: string;
  borderWidth?: string;
  cellSize?: string;
  centered?: boolean;
  clickable?: boolean;
  content?: string;
  cursor?: string;
  flex?: string;
  flexWrap?: string;
  hasHeading?: boolean;
  hasLeftBorder?: boolean;
  hasRightBorder?: boolean;
  hidden?: boolean;
  horizontalAlign?: string;
  hoverColor?: string;
  isHighlighted?: boolean;
  isSortedBy?: boolean;
  justifyContent?: string;
  margin?: string;
  minHeight?: string;
  noBackground?: boolean;
  noBottomBorder?: boolean;
  noOverflow?: boolean;
  noPaddingRight?: boolean;
  overflow?: string;
  padding?: string;
  position?: string;
  right?: boolean;
  rotationAngle?: string;
  size?: string;
  sortable?: boolean;
  sortOrder?: string;
  sticky?: boolean;
  textAlign?: string;
  top?: string;
  vertical?: boolean;
  virtualized?: boolean;
  visibility?: string;
  visible?: boolean;
  whiteSpace?: boolean;
  width?: string;
  withClickableRows?: boolean;
  withTopBorder?: boolean;
  wordBreak?: string;
  wrap?: string;
}

interface TableProps extends CommonProps {
  height?: string;
}

export interface TableBodyProps extends CommonProps {
  height?: number;
}

export interface TableRowProps extends CommonProps {
  flexDirection?: string;
  height?: number;
  marginTop?: string;
  padding?: string;
}

interface TableRowDraggableProps {
  display?: string;
  width?: string;
  height?: string;
  top?: string;
  left?: string;
}

export const TableHeadCellSortIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  display: none;
  width: 12px;
  height: 12px;
  transform: none;
  transition: transform 0.3s ease-out;
`;

export interface TableCellProps {
  align?: string;
  alignItems?: string;
  backgroundColor?: string;
  cellSize?: string;
  flex?: string;
  flexWrap?: string;
  hasBackground?: boolean;
  hasError?: boolean;
  hasLeftBorder?: boolean;
  hasRightBorder?: boolean;
  hidden?: boolean;
  horizontalAlign?: string;
  justifyContent?: string;
  minHeight?: string;
  padding?: string;
  position?: string;
  singleLine?: boolean;
  textTransform?: string;
  vertical?: boolean;
  whiteSpace?: boolean;
  width?: string;
  wordBreak?: string;
  wrap?: string;
}

export const TableCell = styled.div<TableCellProps>`
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  min-height: ${props => props.minHeight || '60px'};
  padding: ${props => sizeMapper(props.padding, 'defaultCellVertical defaultCellHorizontal')};
  word-break: ${props => (props.wordBreak ? props.wordBreak : 'break-word')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'no-wrap')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  line-height: 18px;
  font-size: 14px;

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};

  ${props =>
    props.whiteSpace &&
    css`
      white-space: nowrap;
    `};

  ${props =>
    props.singleLine &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};

  ${props =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `};

  ${props =>
    props.align &&
    css`
      justify-content: ${mapper(
        props.align,
        {
          left: 'flex-start',
          center: 'center',
          right: 'flex-end',
        },
        'left',
      )};
    `};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      align-items: ${mapper(
        props.horizontalAlign,
        {
          left: 'flex-start',
          center: 'center',
          right: 'flex-end',
        },
        'left',
      )};
      justify-content: ${mapper(
        props.align,
        {
          top: 'flex-start',
          middle: 'center',
          bottom: 'flex-end',
        },
        'center',
      )};
    `};

  ${props =>
    props.cellSize &&
    css`
      min-height: ${mapper(props.cellSize, { small: '60px', medium: '80px', large: '100px' }, 'small')};
    `};

  ${props =>
    props.position &&
    css`
      position: ${props.position};
      z-index: 5;
    `};

  ${props =>
    props.hasRightBorder &&
    css`
      border-right: 1px solid ${props => props.theme.grayLight};
      padding-right: 25px;
    `};

  ${props =>
    props.hasLeftBorder &&
    css`
      border-left: 1px solid ${props => props.theme.grayLight};
      padding-left: 25px;
      margin-left: -1px;
    `};

  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}

  ${props =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `};

  ${props =>
    props.hasError &&
    css`
      color: ${props => props.theme.brandAlert};
    `};

  ${props =>
    props.hasBackground &&
    css`
      background: ${props.backgroundColor || props.theme.grayLighter};
    `};
`;

interface TableHeadCellProps extends CommonProps {
  rotate?: boolean;
  textTransform?: string;
}

export const TableHeadCell = styled(TableCell)<TableHeadCellProps>`
  position: relative;
  min-height: 45px;
  text-transform: ${props => props.textTransform || 'uppercase'};
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 11px;
  color: ${props => props.theme.grayDarker};
  transition: background-color 0.2s ease-out;
  text-align: ${props => props.align};
  padding: ${props => sizeMapper(props.padding, 'defaultCellVertical defaultCellHorizontal')};

  ${props =>
    !props.noPaddingRight &&
    css`
      padding-right: 26px;
    `};

  ${props =>
    props.rotate &&
    css`
      white-space: nowrap;
      transform: rotate(-90deg);
      height: 100px;
      top: 40px;
      left: 4px;
    `};

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    word-break: inherit;
  }

  &:not(:first-child)&:not(:last-child) {
    visibility: ${props => (props.visibility === 'none' ? 'hidden' : '')};
  }

  ${TableHeadCellSortIcon} {
    transform: ${props => mapper(props.sortOrder, { asc: 'none', desc: 'rotate(180deg)' })};
  }

  ${props =>
    props.sortable &&
    css`
      cursor: pointer;

      &:hover {
        ${TableHeadCellSortIcon} {
          display: inline-block;
        }
      }
    `};

  ${props =>
    props.isSortedBy &&
    css`
      background-color: ${props => props.theme.grayLight};

      ${TableHeadCellSortIcon} {
        display: inline-block;
      }
    `};
`;

export const TableActionIcon = styled(Icon)<CommonProps>`
  vertical-align: top;
  width: ${props => mapper(props.size, { small: '13px', medium: '25px', large: '50px' }, 'small')};
  height: ${props => mapper(props.size, { small: '13px', medium: '25px', large: '50px' }, 'small')};

  ${props =>
    props.rotationAngle &&
    css`
      transform: rotate(${props.rotationAngle}deg);
    `};
`;

export const TableActionIconMedium = styled(IconMedium)`
  vertical-align: top;
  width: 13px;
  height: 16px;
`;

export const TableActionImage = styled.img<CommonProps>`
  display: block;
  max-height: 18px;
  cursor: pointer;
  max-width: 18px;
  margin: ${props => sizeMapper(props.margin ? props.margin : 'no no no small')};

  ${props =>
    props.right &&
    css`
      margin-left: auto;
      margin-right: 20px;
    `};
`;

export const TableActionLink = styled(Link)`
  margin-right: 15px;
  line-height: 14px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface TableActionButtonProps {
  color?: string;
  hoverColor?: string;
  margin?: string;
  cursor?: string;
  zIndex?: number;
}

export const TableActionButton = styled(TableActionLink.withComponent('button')).attrs({
  type: 'button',
})<TableActionButtonProps>`
  ${BareButtonStyle};
  color: ${props => (props.color ? colorMapper(props) : props.theme.grayDark)};
  margin: ${props => sizeMapper(props.margin ? props.margin : 'no small no no')};

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    padding: 8px;
  }

  &:hover {
    color: ${props => colorMapperWithoutProps(props.hoverColor, 'brandPrimary')};
  }

  &:disabled {
    opacity: 0.9;
    cursor: ${props => props.cursor || 'default'};
  }

  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}
`;

export const TableRow = styled.div<TableRowProps>`
  display: flex;
  flex-direction: ${p => p.flexDirection || 'row'};
  border-bottom: 1px solid ${props => props.theme.grayLight};

  ${p =>
    p.align &&
    css`
      align-items: ${p.align};
    `};

  ${p =>
    p.textAlign &&
    css`
      text-align: ${p.textAlign};
      display: flow-root;
    `};

  body > & {
    background-color: ${props => props.theme.grayLight};
    z-index: 6002;
  }

  ${props =>
    props.hasHeading &&
    css`
      justify-content: center;
      width: 100%;
      padding: 7px 0;
      font-weight: 600;
      background-color: #f0c2c2;
      border: 1px solid #ec8282;
      border-bottom: 0;
    `};

  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `};

  ${props =>
    props.isHighlighted &&
    css`
      background-color: ${transparentize(0.95, props.theme.brandPrimary)};
    `};

  ${props =>
    props.borderWidth &&
    css`
      border: ${props.borderWidth} solid ${props.borderColor || props.theme.brandPrimary};
    `};

  ${props =>
    props.borderBottom &&
    css`
      border-bottom: ${props.borderBottom} solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `};

  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `};

  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
      opacity: 0;
      height: 0;
      overflow: hidden;
    `};

  ${props =>
    props.visible &&
    css`
      visibility: visible;
      opacity: 1;
      height: auto;
      margin-top: -2px;
      background: #fff;
      transition: all 0.5s ease-out;
    `};

  ${props =>
    props.clickable &&
    css`
      &:hover {
        background-color: ${props => props.theme.grayLighter};
        cursor: pointer;
      }
    `}

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};

      &:first-child {
        margin-top: 0;
      }
    `};
`;

export const TableRowDraggable = styled.div<TableRowDraggableProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;

export interface TableHeadProps extends CommonProps {}

export const TableHead = styled.div<TableHeadProps>`
  background-color: ${props => props.theme.grayLighter};

  ${props =>
    props.sticky &&
    css`
      @supports (position: sticky) {
        position: sticky;
        top: ${props.top || 0}px;
        z-index: 1100;
      }
    `};
`;

export const TableBody = styled.div<CommonProps>`
  ${props =>
    props.hasHeading &&
    css`
      border: 1px solid #ec8282;
      border-top: 0;
      max-height: 560px;
      overflow: auto;
    `};

  ${props =>
    props.withClickableRows &&
    css`
      ${TableRow}:hover {
        background-color: ${props => props.theme.grayLighter};
        cursor: pointer;
      }
    `};

  ${props =>
    props.virtualized &&
    css`
      ${TableCell} {
        word-break: keep-all;
      }
    `};

  display: ${props => props.visibility};

  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow} !important;
    `};
`;

export interface TableFooterProps extends CommonProps {}

export const TableFooter = styled.div<TableFooterProps>`
  background-color: ${props => props.theme.grayLighter};

  ${props =>
    props.sticky &&
    css`
      @supports (position: sticky) {
        position: sticky;
        top: ${props.top || 0}px;
        z-index: 1100;
      }
    `};
`;

export const Table = styled.div<TableProps>`
  width: 100%;
  background-color: ${props => (props.noBackground ? 'none' : '#fff')};
  font-weight: ${props => props.theme.fontWeightLight};

  td {
    padding: 8px 20px 8px 26px;
    word-wrap: break-word;
    text-align: left;
  }

  ${props =>
    props.height &&
    css`
      height: ${mapper(props.height, { small: '30vh', medium: '60vh', large: '90vh' })};
      overflow: auto;
      display: block;
    `};

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.noBottomBorder &&
    css`
      border-bottom: none;

      ${TableBody} ${TableRow}:last-child {
        border-bottom: none;
      }
    `};
`;

export const TableCellsOuterWrapper = styled.div<CommonProps>`
  > div {
    will-change: initial !important;

    ${props =>
      props.noOverflow &&
      css`
        overflow: visible auto !important;
      `};

    ${props =>
      props.overflow &&
      css`
        overflow: ${props.overflow} !important;
      `};
  }
`;

export const TableScrollMarker = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.9) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;

  &:before {
    content: '';
    width: 45px;
    height: 45px;
    text-align: center;
    box-shadow: 0 0 5px 0 rgba(36, 40, 51, 0.12);
    border-radius: 50%;
    border: 1px solid ${props => props.theme.brandPrimary};
    margin-left: -22px;
    position: absolute;
    top: 8px;
    left: 50%;
  }

  svg {
    position: absolute;
    top: 24px;
    left: 50%;
    color: ${props => props.theme.brandPrimary};
    margin-left: -7px;
  }
`;

export const TableActionButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
