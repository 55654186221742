import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { DispatchBoardRouteJob } from 'src/routes/interfaces/DispatchBoardRouteJob';
import {
  addOnHoldJobsToRoute,
  deleteOnHoldJobs as doDeleteOnHoldJobs,
  loadOnHoldJobs as doLoadOnHoldJobs,
  loadOnHoldJobsCount as doLoadOnHoldJobsCount,
} from 'src/routes/services/dispatchBoardOnHoldJobs';

// Actions
const START_LOAD = 'dispatchBoard/onHoldJobs/START_LOAD';
const END_LOAD = 'dispatchBoard/onHoldJobs/END_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/onHoldJobs/COMPLETE_LOAD';
const COMPLETE_LOAD_COUNT = 'dispatchBoard/onHoldJobs/COMPLETE_LOAD_COUNT';
const FAIL_LOAD = 'dispatchBoard/onHoldJobs/FAIL_LOAD';
const RESET = 'dispatchBoard/onHoldJobs/RESET';

interface State {
  isLoading: boolean;
  onHoldJobs: DispatchBoardRouteJob[];
  onHoldJobsCount: number;
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  onHoldJobs: [],
  onHoldJobsCount: 0,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case END_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          onHoldJobs: action.onHoldJobs,
        },
      });

    case COMPLETE_LOAD_COUNT:
      return update(state, {
        $merge: {
          onHoldJobsCount: action.onHoldJobsCount,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          onHoldJobs: [],
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const endLoad = () => ({
  type: END_LOAD,
});

const completeLoad = (onHoldJobs: DispatchBoardRouteJob[]) => ({
  type: COMPLETE_LOAD,
  onHoldJobs,
});

const completeLoadCount = (onHoldJobsCount: number) => ({
  type: COMPLETE_LOAD_COUNT,
  onHoldJobsCount,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDispatchBoardOnHoldJobsCount =
  (
    vendorId: number,
    date?: Date | string,
    routeStatuses?: any[],
    searchTerm?: string,
    serviceZones?: number[],
    supervisors?: number[],
    groupIds?: number[],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const promise = doLoadOnHoldJobsCount(
      vendorId,
      date,
      routeStatuses,
      searchTerm,
      serviceZones,
      supervisors,
      groupIds,
    );
    promise.then(routes => dispatch(completeLoadCount(routes))).catch(() => dispatch(failLoad()));
    return promise;
  };

export const deleteDispatchBoardOnHoldJobs = (jobIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const promise = doDeleteOnHoldJobs(jobIds);
  promise.then(() => {
    dispatch(endLoad());
  });
  return promise;
};

export const addDispatchBoardOnHoldJobsToRoute = (routeData: any) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const promise = addOnHoldJobsToRoute(routeData);
  promise.then(() => {
    dispatch(endLoad());
  });
  return promise;
};

export const loadDispatchBoardOnHoldJobs =
  (
    vendorId: number,
    date?: Date | string,
    routeStatuses?: any[],
    searchTerm?: string,
    serviceZones?: number[],
    supervisors?: number[],
    groupIds?: number[],
  ) =>
  (dispatch: Dispatch) => {
    const promise = doLoadOnHoldJobs(vendorId, date, routeStatuses, searchTerm, serviceZones, supervisors, groupIds);
    promise.then(stops => dispatch(completeLoad(stops)));
    return promise;
  };

export const resetDispatchBoardOnHoldJobs = () => ({
  type: RESET,
});
