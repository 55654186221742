import React from 'react';
import { connect } from 'react-redux';

import { hasRoleSelector } from '../ducks';
import { AppState } from '../../store';

interface ComponentProps {
  roles: string | string[];
}

interface Props extends ComponentProps {
  hasRole?: boolean;
}

const RoleGuard: React.FC<Props> = ({ hasRole, children }) => {
  if (hasRole && children) {
    return <>{children}</>;
  }

  return null;
};

const mapStateToProps = (state: AppState, { roles }: ComponentProps) => ({
  hasRole: hasRoleSelector(state.account.login, roles),
});

export default connect(mapStateToProps)(RoleGuard);
