import { useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, size } from 'lodash-es';

import { AppState } from 'src/store';
import { Button, ButtonSet, Message, PanelSection } from '../../../../core/components/styled';
import confirm from 'src/core/services/confirm';
import { createSuccessNotification, createErrorNotification } from '../../../../core/services/createNotification';
import OutboundCreationRuleEditorModal from '../ServiceRequestConfigurationTabs/OutboundCreationRuleEditorModal';
import CreationRulesTableRow from '../../CreationRulesTableRow';
import { deleteOutboundCreationRules } from '../../../ducks/open311Settings';
import { Table, UnconnectedCheckbox } from 'src/core/components';
import translate from '../../../../core/services/translate';

export default function OutBoundConfigurationSection() {
  const dispatch = useDispatch();

  const open311Configuration = useSelector((state: AppState) => state.vendors.open311Settings.open311Configuration);
  const creationRulesList = useSelector((state: AppState) => state.vendors.open311Settings.exceptions);
  const configurationId = open311Configuration?.id || 0;

  const [isCreationRuleEditorModalOpen, setIsCreationRuleEditorModalOpen] = useState(false);
  const [selectedCreationRuleId, setSelectedCreationId] = useState<number | undefined>();
  const [allRulesChecked, setAllRulesChecked] = useState<boolean>(false);
  const [selectedRules, setSelectedRules] = useState<number[]>([]);

  const openCreationRuleEditorModal = (creationRuleId: number | undefined) => {
    setIsCreationRuleEditorModalOpen(true);
    setSelectedCreationId(creationRuleId);
  };

  const closeCreationRuleEditorModal = async (formPristine?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    setIsCreationRuleEditorModalOpen(false);
    setSelectedCreationId(undefined);
  };

  const checkAllCreationRule = () => {
    const creationRuleIds: number[] = [];
    creationRulesList?.forEach(rule => {
      if (selectedRules.length === 0) {
        creationRuleIds.push(rule.id);
      }
    });
    setAllRulesChecked(!allRulesChecked);
    setSelectedRules(creationRuleIds);
  };

  const checkCreationRule = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const updatedSelectedRules = [...selectedRules];
    const indexDelete = updatedSelectedRules.indexOf(id);
    if (indexDelete > -1) {
      updatedSelectedRules.splice(indexDelete, 1);
    } else {
      updatedSelectedRules.push(id);
    }
    setSelectedRules(updatedSelectedRules);
  };

  const CreationRulesTableCells = [
    {
      name: 'selectAll',
      component: UnconnectedCheckbox,
      componentProps: {
        onChange: checkAllCreationRule,
        checked: selectedRules.length === creationRulesList?.length,
        partial: 0 < selectedRules.length && selectedRules.length < creationRulesList?.length,
      },
      width: '5%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
    },
    { name: 'serviceCode', label: translate('vendors.open311.serviceCode'), width: '20%' },
    { name: 'exceptionTypeId', label: translate('dashboard.exceptionType'), width: '20%' },
    { name: 'outcomeCode', label: translate('vendors.open311.outcomeCode'), width: '20%' },
    { name: 'responseCode', label: translate('vendors.open311.responseCode'), width: '20%' },
    { name: 'options', label: translate('common.options'), width: '10%' },
  ];

  const deleteOutboundRules = async () => {
    if (!(await confirm(translate('vendors.open311.alertMessages.deleteRuleMessage')))) {
      return;
    }
    deleteOutboundCreationRules(
      configurationId,
      selectedRules,
    )(dispatch)
      .then(() => {
        checkAllCreationRule();
        createSuccessNotification(translate('vendors.open311.alertMessages.deletedCreationRules'));
      })
      .catch(() => {
        createErrorNotification(translate('vednors.alertMessages.creationRuleDeleteError'));
      });
  };

  const creationRuleTableRows = map(creationRulesList, creationRule => {
    return {
      ...creationRule,
      isChecked: selectedRules.includes(creationRule.id),
    };
  });

  return (
    <>
      <PanelSection vertical id={`creation-rule-form`}>
        {!!size(creationRulesList) && (
          <Table
            cells={CreationRulesTableCells}
            rows={creationRuleTableRows}
            rowComponent={CreationRulesTableRow}
            rowProps={{
              isInbound: false,
              editCreationRule: openCreationRuleEditorModal,
              checkCreationRule: checkCreationRule,
            }}
          />
        )}

        {!size(creationRulesList) && (
          <Message padding="sMedium">{translate('vendors.open311.noCreationRules')}</Message>
        )}

        <ButtonSet margin="sMedium no">
          {!selectedRules.length && (
            <Button
              id={`open311-creation-rule-add-button`}
              type="button"
              color="primary"
              line
              onClick={() => openCreationRuleEditorModal(undefined)}
            >
              + {translate('vendors.open311.addCreationRule')}
            </Button>
          )}
          {!!selectedRules.length && (
            <Button type="button" color="alert" line onClick={deleteOutboundRules}>
              {translate('common.delete')}
            </Button>
          )}
        </ButtonSet>

        {isCreationRuleEditorModalOpen && (
          <OutboundCreationRuleEditorModal
            creationRules={creationRulesList}
            creationRuleId={selectedCreationRuleId}
            onClose={closeCreationRuleEditorModal}
          ></OutboundCreationRuleEditorModal>
        )}
      </PanelSection>
    </>
  );
}
