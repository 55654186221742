import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { RoutePlannerTablePayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { RoutePlannerTableData } from 'src/routes/interfaces/routePlanner/RoutePlannerTableData';
import { loadRoutePlannerTableData as doLoadRoutePlannerTableData } from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerTableData/START_LOAD';
const COMPLETE_LOAD = 'routePlannerTableData/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerTableData/FAIL_LOAD';
const RESET = 'routePlannerTableData/RESET';

interface State {
  isLoading: boolean;
  tableData: RoutePlannerTableData[];
}

const initialState: State = {
  isLoading: false,
  tableData: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          tableData: action.tableData,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          tableData: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (tableData: RoutePlannerTableData[]) => ({
  type: COMPLETE_LOAD,
  tableData,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetRoutePlannerTableData = () => ({
  type: RESET,
});

export const loadRoutePlannerTableData = (params: RoutePlannerTablePayload) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePlannerTableDataPromise = doLoadRoutePlannerTableData(params);

  loadRoutePlannerTableDataPromise
    .then((tableData: RoutePlannerTableData[]) => {
      dispatch(completeLoad(tableData));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return loadRoutePlannerTableDataPromise;
};
