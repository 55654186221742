import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { Vendor } from '../interfaces/Vendors';
import {
  checkIfVendorHasNetworkFile as doCheckIfVendorHasNetworkFile,
  createVendor,
  loadVendor as doLoadVendor,
  updateVendor,
} from '../services/vendor';

// Actions
const START_LOAD = 'vendor/vendor/START_LOAD';
const COMPLETE_LOAD = 'vendor/vendor/COMPLETE_LOAD';
const FAIL_LOAD = 'vendor/vendor/FAIL_LOAD';
const START_SAVE = 'vendor/vendor/START_SAVE';
export const COMPLETE_SAVE = 'vendor/vendor/COMPLETE_SAVE';
const FAIL_SAVE = 'vendor/vendor/FAIL_SAVE';
export const VIEW_ADVANCED_PROFILE = 'vendor/vendor/VIEW_ADVANCED_PROFILE';
const RESET = 'vendor/vendor/RESET';
const START_CHECK_IF_VENDOR_HAS_NETWORK_FILE = 'vendor/vendor/START_CHECK_IF_VENDOR_HAS_NETWORK_FILE';
const COMPLETE_CHECK_IF_VENDOR_HAS_NETWORK_FILE = 'vendor/vendor/COMPLETE_CHECK_IF_VENDOR_HAS_NETWORK_FILE';
const FAIL_CHECK_IF_VENDOR_HAS_NETWORK_FILE = 'vendor/vendor/FAIL_CHECK_IF_VENDOR_HAS_NETWORK_FILE';

// Initial state =
const initialState = {
  isLoading: false,
  isSaving: false,
  vendor: {} as Vendor,
  hasVendorNetworkFile: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vendor: action.vendor,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vendor: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          vendor: (state.vendor as Vendor).id === action.vendor.id ? action.vendor : state.vendor,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    case START_CHECK_IF_VENDOR_HAS_NETWORK_FILE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_CHECK_IF_VENDOR_HAS_NETWORK_FILE:
      return update(state, {
        $merge: {
          isLoading: false,
          hasVendorNetworkFile: action.hasVendorNetworkFile,
        },
      });

    case FAIL_CHECK_IF_VENDOR_HAS_NETWORK_FILE:
      return update(state, {
        $merge: {
          isLoading: false,
          hasVendorNetworkFile: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vendor: Vendor) => ({
  type: COMPLETE_LOAD,
  vendor,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (vendor: Vendor) => ({
  type: COMPLETE_SAVE,
  vendor,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startCheckIfVendorHasNetworkFile = () => ({
  type: START_CHECK_IF_VENDOR_HAS_NETWORK_FILE,
});

const completeCheckIfVendorHasNetworkFile = (hasVendorNetworkFile: boolean) => ({
  type: COMPLETE_CHECK_IF_VENDOR_HAS_NETWORK_FILE,
  hasVendorNetworkFile,
});

const failCheckIfVendorHasNetworkFile = () => ({
  type: FAIL_CHECK_IF_VENDOR_HAS_NETWORK_FILE,
});

export const loadVendor = (vendorId: number, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
  !noLoadingIndicator && dispatch(startLoad());

  const loadVendorPromise = doLoadVendor(vendorId);
  loadVendorPromise.then(vendor => dispatch(completeLoad(vendor))).catch(() => dispatch(failLoad()));

  return loadVendorPromise;
};

export const saveVendor = (vendor: Vendor) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveVendorPromise = vendor.id ? updateVendor(vendor.id, vendor) : createVendor(vendor);
  saveVendorPromise.then(vendor => dispatch(completeSave(vendor))).catch(() => dispatch(failSave()));
  return saveVendorPromise;
};

export const resetVendor = () => ({
  type: RESET,
});

export const viewAdvancedProfile = () => ({
  type: VIEW_ADVANCED_PROFILE,
});

export const checkIfVendorHasNetworkFile = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startCheckIfVendorHasNetworkFile());

  const checkIfVendorHasNetworkFilePromise = doCheckIfVendorHasNetworkFile(vendorId);
  checkIfVendorHasNetworkFilePromise
    .then(hasVendorNetworkFile => dispatch(completeCheckIfVendorHasNetworkFile(hasVendorNetworkFile)))
    .catch(() => dispatch(failCheckIfVendorHasNetworkFile()));

  return checkIfVendorHasNetworkFilePromise;
};
