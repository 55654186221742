import { find, groupBy, map } from 'lodash-es';
import { FC, useMemo, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { CUSTOMER_SUBTYPES, CUSTOMER_TYPES } from 'src/common/constants';
import { Checkbox, TypedField } from 'src/core/components';
import { Button } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  RouteMapFiltersClose,
  RouteMapFiltersCloseIcon,
  RouteMapFiltersTitle,
  RouteMapFiltersWrapper,
} from 'src/routes/components/styled/RouteMapFilters';
import ExpandableMapFilterSectionEnhanced from './ExpandableMapFilterSectionEnhanced';

type PropsWithoutReduxForm = {
  isFormOpen: boolean;
  onClose: () => void;
};

export type ServiceAreaMapFiltersFormValues = {
  haulerLocationFilters: any;
  customerLocationFilters: any;
  showCustomerLocations: boolean;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<ServiceAreaMapFiltersFormValues, PropsWithoutReduxForm>;

export const SERVICE_AREA_MAP_FILTERS_FORM_NAME = 'serviceAreaMapFiltersForm';

const ServiceAreaMapFiltersForm: FC<Props> = ({ isFormOpen, onClose, change, handleSubmit }) => {
  //   const customers = useSelector(state => state.customers.streetNetworkServiceAreas.customers);
  const vendorLocationsForMapbox = useSelector(state => state.customers.streetNetworkServiceAreas.haulerLocations);

  //   const customersFilters = useMemo(
  //     () =>
  //       customers.map(customer => ({
  //         label: customer.name,
  //         id: customer.id,
  //         value: `_${customer.id}`,
  //       })),
  //     [customers],
  //   );

  const [isHaulerLocationFiltersOpen, setIsHaulerLocationFiltersOpen] = useState(true);
  //   const [isCustomersFiltersOpen, setIsCustomersFiltersOpen] = useState(false);

  const haulerLocationsFilters = useMemo(() => {
    const locationGroupedByType = groupBy(vendorLocationsForMapbox, location => location.customerTypeId);
    return map(Object.keys(locationGroupedByType), locationTypeId => {
      const locations = locationGroupedByType[locationTypeId];

      const locationsGroupedBySubType = groupBy(locations, location => location.customerSubType);
      const locationTypeName = CUSTOMER_TYPES[locationTypeId];
      return {
        name: `locationTypeId_${locationTypeId}`,
        label: locationTypeName.name,
        justDisplayName: true,
        subFilters: map(
          Object.keys(locationsGroupedBySubType),

          locationSubType => {
            const subTypeId = locationsGroupedBySubType[locationSubType][0].customerSubTypeId;
            const locationSubTypeName = find(CUSTOMER_SUBTYPES, { id: subTypeId })?.name || '';
            return {
              name: `locationSubTypeId_${subTypeId}`,
              label: locationSubTypeName,
              subFilters: map(locationsGroupedBySubType[locationSubType], location => {
                return {
                  name: `locationId_${location.id}`,
                  label: location.locationName,
                };
              }),
            };
          },
        ) as any,
      };
    });
  }, [vendorLocationsForMapbox]);

  return isFormOpen ? (
    <form onSubmit={handleSubmit}>
      <RouteMapFiltersWrapper width="300">
        <RouteMapFiltersTitle>{translate('tooltips.mapFilters')}</RouteMapFiltersTitle>
        <RouteMapFiltersClose onClick={onClose}>
          <RouteMapFiltersCloseIcon />
        </RouteMapFiltersClose>

        <TypedField
          name="showCustomerLocations"
          component={Checkbox}
          props={{
            label: translate('customers.customerLocations'),
            margin: 'medium small small small',
            size: 'small',
          }}
        />

        <ExpandableMapFilterSectionEnhanced
          title={translate('dashboard.vendorLocations')}
          filters={haulerLocationsFilters}
          name="haulerLocationFilters"
          change={change}
          isOpen={isHaulerLocationFiltersOpen}
          formName={SERVICE_AREA_MAP_FILTERS_FORM_NAME}
          setIsOpen={setIsHaulerLocationFiltersOpen}
          hasCheckAllPerFilter
        />

        {/* <RouteMapFiltersSection>
          <RouteMapFiltersWrapper>
            <ExpandableMapFiltersSection
              title={translate('customers.customerLocations')}
              filters={customersFilters}
              name="customerLocationFilters"
              change={change}
              isOpen={isCustomersFiltersOpen}
              setIsOpen={setIsCustomersFiltersOpen}
            />
          </RouteMapFiltersWrapper>
        </RouteMapFiltersSection> */}
        <Button type="submit" color="primary" fullWidth margin="no">
          {translate('common.apply')}
        </Button>
      </RouteMapFiltersWrapper>
    </form>
  ) : null;
};

export default reduxForm<ServiceAreaMapFiltersFormValues, PropsWithoutReduxForm>({
  form: SERVICE_AREA_MAP_FILTERS_FORM_NAME,
  destroyOnUnmount: false,
})(ServiceAreaMapFiltersForm);
