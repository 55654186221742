import { Dictionary } from '../../common/interfaces/Dictionary';
import { VehicleTrackingRaw, VehicleTrackingResponse } from '../components/mapWithTimeline/Interfaces';

export const transformLoadRouteMapDebugTrackings = (vehicleTrackings: VehicleTrackingResponse[]) => {
  const trackingsMap: Dictionary<VehicleTrackingRaw[]> = {};
  const trapezoidsMap: Dictionary<VehicleTrackingRaw[]> = {};
  const vehicleNameMap: Dictionary<string> = {};
  vehicleTrackings.forEach(vt => {
    trackingsMap[vt.vehicleId] = vt.vehicleTrackings;
    trapezoidsMap[vt.vehicleId] = vt.vehicleTrackings.filter(vt => vt.vehicleSpeed < 10);
    vehicleNameMap[vt.vehicleId] = vt.vehicleName;
  });
  return {
    trapezoids: trapezoidsMap,
    vehicleTrackings: trackingsMap,
    vehicleNames: vehicleNameMap,
  };
};
