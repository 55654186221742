import React, { PureComponent } from 'react';

import { change } from 'redux-form';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { ActionButtonTooltip } from '../../../core/components';
import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentUserIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { date as formatDate } from '../../../utils/services/formatter';
import { DOCUMENT_TYPE_OPTIONS } from '../../constants';
import { exportFile } from '../../ducks';
import { TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface ComponentProps extends RouteComponentProps {
  createdDate: string;
  description: string;
  fileName: string;
  id: number;
  type: string;
  validFrom: string;
  validTo: string;
}

interface Props extends ComponentProps {
  change(...args: any[]): any;
  exportFile(...args: any[]): any;
  userId?: number;
  vendorGroupId?: number;
}

class DocumentListTableRow extends PureComponent<Props> {
  onClickDownload = async (event: any, fileId: number, fileName: string) => {
    event.preventDefault();

    const { exportFile, vendorGroupId } = this.props;

    await exportFile(fileId, fileName, vendorGroupId)
      .then(() => createSuccessNotification(translate('common.alertMessages.downloadCompleted')))
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.downloadFailed'));
      });
  };

  render() {
    const { createdDate, description, id, fileName, type, validFrom, validTo } = this.props;

    return (
      <TableRow>
        <TableCell vertical width={'30%'}>
          <Text block weight="medium" margin="no no xxSmall">
            {fileName}
          </Text>
          <Text weight="light" size="small" margin="no no xxSmall" singleLine>
            {translate('common.uploadDate')}: {formatDate(createdDate)}
          </Text>
        </TableCell>
        <TableCell width={'16%'}>{DOCUMENT_TYPE_OPTIONS[type].name}</TableCell>
        <TableCell width={'20%'}>{description}</TableCell>
        <TableCell width={'12%'}>{!!validFrom ? formatDate(validFrom) : '-'}</TableCell>
        <TableCell width={'12%'}>{!!validTo ? formatDate(validTo) : '-'}</TableCell>
        <TableCell width={'10%'} align="center">
          <TableActionButton
            onClick={(e: any) => this.onClickDownload(e, id, fileName)}
            id={`download-document-${id}-button`}
          >
            <ActionButtonTooltip icon="download" tooltip="download" />
          </TableActionButton>
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorGroupId: (currentVendorGusIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  exportFile,
  change,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentListTableRow));
