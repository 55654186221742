import { http } from '../../core/services/http';
import { getBaseUrl } from '../../core/services/environment';
import { getSelfCreateUser, setSelfCreateUser } from './selfCreateUser';

export const requestPasswordReset = (email: string, forceReset?: boolean) =>
  http.post('portalAccount/forgotPassword', { email, forceReset });

export const resetPassword = (code: string, email: string, password: string) =>
  http.post('portalAccount/resetForgotPassword', {
    code,
    email,
    password,
  });

export const checkSelfUser = (tokenId: string) =>
  http.get(`/users/selfcreate/check?tokenId=${tokenId}`).then(response => response.data);

export const createSelfUser = async (
  email: string,
  name: string,
  password: string,
  confirmPassword: string,
  vendorId: number,
  tokenId: string,
) => {
  const response = await http.post('/users/selfcreate', {
    email,
    name,
    password,
    confirmPassword,
    vendorId,
    tokenId,
    inviteUrl: getBaseUrl(),
  });

  setSelfCreateUser(response.data);

  return response.data;
};

export const getSelfUserSession = () => getSelfCreateUser('user');

export const validateResetPasswordToken = (code: string, email: string) =>
  http.post(`/portalAccount/verifyResetForgotPasswordToken`, { code, email }).then(response => response.data);
