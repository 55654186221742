import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { map } from 'lodash-es';

import ACE from '../../../account/assets/rubiconSelect/ACE.png';
import ARCCompactors from '../../../account/assets/rubiconSelect/ARCCompactors.png';
import BioBag from '../../../account/assets/rubiconSelect/BioBag.png';
import CCG from '../../../account/assets/rubiconSelect/CCG.png';
import Concorde from '../../../account/assets/rubiconSelect/Concorde.png';
import CRA from '../../../account/assets/rubiconSelect/CRA.png';
import eSquared from '../../../account/assets/rubiconSelect/eSquared.png';
import IOA from '../../../account/assets/rubiconSelect/IOA.png';
import Michelin from '../../../account/assets/rubiconSelect/Michelin.png';
import Miltek from '../../../account/assets/rubiconSelect/Miltek.png';
import OTTO from '../../../account/assets/rubiconSelect/otto.png';
import Plumcreek from '../../../account/assets/rubiconSelect/Plumcreek.png';
import RubiconSelect from '../../../account/assets/rubiconSelect/RubiconSelect.png';
import { Page } from '../../../common/components';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
} from '../../../common/components/styled';
import { Button, Panel, PanelSection, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import {
  RubiconSelectTiles,
  RubiconSelectTile,
  RubiconSelectTileContent,
  RubiconSelectTileFooter,
  RubiconSelectImageContainer,
} from '../styled/rubiconSelect';

const rubiconSelectPartnerList = [
  {
    partnerName: 'RUBICONSelect Mastercard Fuel Card',
    text: 'Are you aware that Rubicon offers a fuel card that provides the ability to capture detailed fuel purchase information as well as receive discounts at over 1,000 major and independent truck stop locations? The RUBICONSelect Mastercard offers Purchasing power of the EFS Card — combined with the Mastercard® Network for non-fuel purchases.',
    subText: 'RUBICONSelect members can apply for the RUBICONSelect fuel card and start saving now.',
    imageSrc: RubiconSelect,
    link: ' https://onlineservices.secure.force.com/creditapplication/OTRBOCA?pgm=rubiconselectmulticard',
    linkTitle: 'Apply Now',
  },
  {
    partnerName: 'Michelin',
    text: 'Michelin, the world’s top-selling tire brand, is now offering tires, retreads, and select services to RUBICONSelect members at a discounted rate.',
    subText:
      'RUBICONSelect members will receive up to 15% by signing up for Rubicon’s special Michelin National Accounts program using below link.',
    imageSrc: Michelin,
    link: 'https://myportal.michelingroup.com/s/advantage-form?language=en_US&AAC=RGUS',
    linkTitle: 'Sign Up Now',
  },
  {
    partnerName: 'Plum Creek',
    text: 'Plum Creek offers a variety of waste and recycling equipment, such as balers, compactors, conveyers, and shredders, as well as manufacturing waste and scrap containers, making them a true “turnkey” partner for haulers.',
    subText: 'RUBICONSelect members enjoy special pricing on Plum Creek containers, balers, compactors, and shredders.',
    imageSrc: Plumcreek,
    link: 'https://www.rubicon.com/lp/rubiconselect-plum-creek/',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'Commercial Credit Group(CCG)',
    text: 'CCG has been providing superior commercial equipment financing for the waste industry since 2004.',
    subText:
      'Through RUBICONSelect, members can benefit from customizable funding solutions with no interest or payments for 90 days, a minimum of $35,000 with no maximum, and financing for both new and used equipment.',
    imageSrc: CCG,
    link: 'https://www.commercialcreditgroup.com/rubicon-financing',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'Mil-Tek',
    text: 'Mil-Tek air-powered balers and compactors offer a range of benefits, including fewer pickups, faster compaction, and virtually no maintenance—all within a small footprint. The company is proud to have the smallest, most efficient balers in the world, which are proven to reduce waste and costs.',
    subText:
      'RUBICONSelect members receive equipment discounts of up to 7%, plus one month of free supplies worth up to $750 per total order.',
    imageSrc: Miltek,
    link: 'https://www.rubicon.com/lp/rubiconselect-mil-tek/',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'Concord Inc.',
    text: 'Concord offers special pricing to RUBICONSelect members on programs to help businesses protect themselves, and their employees, from costly Department of Transportation (DOT) violations, which can cost up to $80,000 per occurrence.',
    subText:
      'Programs offered include Driver Qualification File Management, CSA M Management, Motor Vehicle Record Monitoring, Drug and Alcohol Testing, and Clearinghouse and Background Screening Services.',
    imageSrc: Concorde,
    link: 'https://www.rubicon.com/lp/rubiconselect-concorde/',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'eSquared Communication Consulting',
    text: 'eSquared Communication Consulting is Rubicon’s preferred partner and one-stop-shop for discounted in-cab hardware such as tablets, vehicles mounts/cradles, and accessories. eSquared also offer carrier data such as AT&T, Verizon, or T-Mobile. Your product will come 100% ready for install when received from our warehouse.',
    subText: '',
    imageSrc: eSquared,
    linkTitle: 'Learn More',
    link: 'https://e2.e2cc.com/rubicon/?utm_source=socials&utm_medium=post&utm_campaign=wasteexpo23&utm_term=linkedin',
  },
  {
    partnerName: 'ACE Equipment Company',
    text: 'ACE Equipment Company is a leading manufacturer of state-of-the-art industrial balers, compactors, and related equipment. For 25 years, they have been creating innovative new products with cutting-edge technology, while upholding the highest standards.',
    subText: 'RUBICONSelect members can enjoy a 25% discount on all purchases from ACE Equipment Company.',
    imageSrc: ACE,
    link: 'https://www.rubicon.com/lp/rubiconselect-ace/',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'BioBag',
    text: 'BioBag is a world leader in providing bags and films for the collection of organic waste for composting. Unlike regular plastic, BioBags can be broken down by the micro-organisms in soil. BioBags are made from starches from renewable crops, meaning they can be composted along with the organic waste contained within them in municipal composting facilities.',
    subText:
      'RUBICONSelect members can enjoy 10-case pricing on orders of one or more cases, and pallet pricing on orders of 10 cases or more.',
    imageSrc: BioBag,
    link: 'https://www.rubicon.com/lp/rubiconselect-biobag/',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'Insurance Office of America (IOA)',
    text: 'IOA offers a variety of insurance and risk management products and services, including their proprietary Risk Management program which has been shown to reduce claims by up to 40%. The company is endorsed by the Solid Waste Association of North America (SWANA) as their preferred insurance and risk management partner.',
    subText: 'RUBICONSelect members will receive a free audit from Insurance Office of America.',
    imageSrc: IOA,
    link: 'https://www.rubicon.com/lp/rubiconselect-ioa/',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'ARC Compactors',
    text: 'ARC customers save money, reduce odor, and gain freedom from their current hauler’s equipment, allowing them to focus on the work that makes their business successful.',
    subText: 'RUBICONSelect members can enjoy a one-month free rental from ARC.',
    imageSrc: ARCCompactors,
    link: 'https://www.rubicon.com/lp/rubiconselect-arc/',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'Compactor Rentals of America',
    text: 'Compactor Rentals of America is the USA’s premier provider of commercial trash compactor and baler rental solutions. Their large inventory of equipment, strong manufacturer connections, and logistical advantages created by our seven strategically placed service facilities allow them to shorten lead times and provide equipment faster than their competition.',
    subText:
      'RUBICONSelect members receive a 5% discount on monthly rental rates, and three free months for every 60-month rental contract.',
    imageSrc: CRA,
    link: 'https://www.rubicon.com/lp/rubiconselect-cra',
    linkTitle: 'Learn More',
  },
  {
    partnerName: 'OTTO',
    text: 'Since 1983 OTTO has been building industry leading trash and recycling carts at our plants in Charlotte NC and Eloy AZ.  With sizes ranging from 95 Gallon down to 25 Gallon, we have a cart to meet your collection needs.',
    subText:
      'RUBICONSelect members can order refuse carts with free cart design services and no-charge Hot Stamp creation/ application.',
    imageSrc: OTTO,
    link: 'https://www.rubicon.com/lp/rubiconselect-otto',
    linkTitle: 'Learn More',
  },
];

const RubiconSelectPage: React.FC<RouteComponentProps> = ({ history }) => {
  const openLink = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Page>
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction
                onClick={() => {
                  history.goBack();
                }}
              >
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>
                {translate('common.rubiconSelect')}
                <sup>TM</sup>
              </PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>

        <Panel>
          <PanelSection vertical dark padding="xxSmall">
            <RubiconSelectTiles>
              {map(rubiconSelectPartnerList, (partner, index) => (
                <RubiconSelectTile key={index}>
                  <RubiconSelectImageContainer src={partner.imageSrc} />
                  <RubiconSelectTileFooter>
                    <RubiconSelectTileContent>
                      <Text weight="medium" margin="xSmall">
                        {partner.partnerName}
                      </Text>
                      <Text weight="light" margin="xSmall" size="small">
                        {partner.text}
                      </Text>
                      <Text weight="light" margin="xSmall" size="small">
                        {partner.subText}
                      </Text>
                    </RubiconSelectTileContent>
                    <Button color="primary" margin="medium small small" onClick={() => openLink(partner.link)}>
                      {partner.linkTitle}
                    </Button>
                  </RubiconSelectTileFooter>
                </RubiconSelectTile>
              ))}
            </RubiconSelectTiles>
          </PanelSection>
        </Panel>
      </PageContent>
    </Page>
  );
};

export default RubiconSelectPage;
