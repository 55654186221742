import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import { size } from 'lodash-es';

import { AppState } from 'src/store';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { isMaterialTypesFeatureEnabled } from 'src/vendors/ducks/features';
import {
  loadSearchedLocation,
  loadUpcomingServices,
  resetSearchedLocation,
  resetUpcomingServices,
} from 'src/customers/ducks';
import { Message, Panel, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { ServiceHistory } from 'src/customers/interfaces/ServiceHistory';
import { Table } from 'src/core/components';
import { TABLE_ROW_HEIGHT_LARGER } from 'src/core/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { UpcomingServicesForm } from '../forms';
import { UpcomingServicesTableRow } from '.';
import translate from 'src/core/services/translate';

interface MatchState {
  customerId: string;
}

interface LocationState {
  endDate: string;
  jobPriorityTypeIds: string;
  locationId: string;
  pickupStatusTypeIds: string;
  serviceContractIds: string;
  startDate: string;
}

interface Props extends RouteComponentProps<MatchState, {}, LocationState> {
  currentServiceHistoryPageUrl?: string;
  isDeleting: boolean;
  isLoading: boolean;
  isMaterialTypesEnabled: boolean;
  loadSearchedLocation: DuckFunction<typeof loadSearchedLocation>;
  loadUpcomingServices: DuckFunction<typeof loadUpcomingServices>;
  push: (url: string) => void;
  reasonCodeTypes: TechnicalType[];
  resetSearchedLocation: () => void;
  resetUpcomingServices: () => void;
  upcomingServices: ServiceHistory[];
  vehicleType?: string;
  wasteAuditLocationStatusTypeId?: number;
  wasteAuditType: TechnicalType;
}

class UpcomingServicesPage extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;

    if (prevProps.location.search !== location.search) {
      const { locationId } = getQueryParams(location.search);
      const { locationId: previousLocationId } = getQueryParams(prevProps.location.search);

      this.loadUpcomingServices(previousLocationId !== locationId);
    }
  }

  componentWillUnmount() {
    this.props.resetUpcomingServices();
  }

  loadUpcomingServices = (locationIsDifferent = false) => {
    const {
      loadSearchedLocation,
      loadUpcomingServices,
      location,
      match: { params },
      resetSearchedLocation,
    } = this.props;

    const customerId = Number(params.customerId);

    const { locationId, serviceContractIds, pickupStatusTypeIds, jobPriorityTypeIds, startDate, endDate } =
      getQueryParams(location.search);

    if (locationIsDifferent) {
      !locationId && resetSearchedLocation();

      Promise.all([
        locationId && loadSearchedLocation(locationId),
        loadUpcomingServices(
          customerId,
          locationId,
          serviceContractIds,
          pickupStatusTypeIds,
          jobPriorityTypeIds,
          startDate,
          endDate,
        ),
      ]);
    } else {
      loadUpcomingServices(
        customerId,
        locationId,
        serviceContractIds,
        pickupStatusTypeIds,
        jobPriorityTypeIds,
        startDate,
        endDate,
      );
    }
  };

  render() {
    const {
      isDeleting,
      isLoading,
      isMaterialTypesEnabled,
      match: { params },
      reasonCodeTypes,
      upcomingServices,
      vehicleType,
      wasteAuditLocationStatusTypeId,
      wasteAuditType,
    } = this.props;
    const customerId = Number(params.customerId);

    const upcomingServicesTableCells = [
      {
        name: 'locationName',
        label: `${translate('common.location')} / ${translate('common.address')}`,
        width: '24%',
        sortable: true,
      },
      { name: 'serviceName', label: translate('common.service'), width: '16%', sortable: true },
      { name: 'serviceDate', label: translate('common.date'), width: '11%', sortable: true },
      {
        name: 'routeName',
        label: translate('common.route'),
        width: '18%',
        sortable: true,
      },
      { name: 'pickupType', label: translate('routes.pickupType'), width: '17%', padding: 'defaultCellVertical no' },
      {
        name: 'options',
        label: translate('common.options'),
        width: '14%',
        align: 'right',
      },
    ];

    const virtualizedProps = {
      itemSize: TABLE_ROW_HEIGHT_LARGER,
      height: Math.min(upcomingServices.length * TABLE_ROW_HEIGHT_LARGER, TABLE_ROW_HEIGHT_LARGER * 9) || 1,
    };

    const goBackToPreviousPage = () => {
      const { push, currentServiceHistoryPageUrl } = this.props;

      return currentServiceHistoryPageUrl
        ? push(currentServiceHistoryPageUrl)
        : push(`/customers/customers/${customerId}/serviceHistory`);
    };

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction
                id={`customers-${customerId}-upcoming-services-back-button`}
                onClick={goBackToPreviousPage}
              >
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate('customers.upcomingServices')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isDeleting}>
            <UpcomingServicesForm />
            <PanelSection>
              {!!size(upcomingServices) && (
                <Table
                  cells={upcomingServicesTableCells}
                  rows={upcomingServices}
                  rowComponent={UpcomingServicesTableRow}
                  rowProps={{
                    isMaterialTypesEnabled,
                    reasonCodeTypes,
                    vehicleType,
                    wasteAuditLocationStatusTypeId,
                    wasteAuditType,
                  }}
                  virtualized
                  virtualizedProps={virtualizedProps}
                />
              )}
              {!size(upcomingServices) && (
                <Message padding="sMedium">{translate('customers.noUpcomingServices')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentServiceHistoryPageUrl: state.customers.serviceHistory.currentServiceHistoryPageUrl,
  isDeleting: state.customers.upcomingServices.isDeleting,
  isLoading: state.customers.upcomingServices.isLoading,
  isMaterialTypesEnabled: isMaterialTypesFeatureEnabled(state),
  reasonCodeTypes: state.common.reasonCodeTypes.reasonCodeTypes,
  upcomingServices: state.customers.upcomingServices.upcomingServices,
});

const mapDispatchToProps = {
  loadSearchedLocation,
  loadUpcomingServices,
  push,
  resetSearchedLocation,
  resetUpcomingServices,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpcomingServicesPage));
