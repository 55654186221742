import { map } from 'lodash-es';

import { IS_PICTURE_REQUIRED, PICTURE_REQUIRED, PICTURE_NOT_REQUIRED } from '../constants';
import { LocationFlags } from '../interfaces/LocationFlags';

export const transformLoadLocationFlags = (locationFlags: LocationFlags[]) =>
  map(locationFlags, ({ canTakePictureTechnicalName, orderNo, ...locationFlag }, index) => ({
    ...locationFlag,
    orderNo: orderNo || index + 1,
    isPictureRequired: IS_PICTURE_REQUIRED[canTakePictureTechnicalName || ''].value,
  }));

export const transformSaveLocationFlags = (locationFlags: LocationFlags[]) =>
  map(locationFlags, ({ isPictureRequired, ...locationFlag }) => ({
    ...locationFlag,
    canTakePictureTechnicalName: isPictureRequired ? PICTURE_REQUIRED : PICTURE_NOT_REQUIRED,
  }));
