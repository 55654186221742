import React, { Fragment, PureComponent } from 'react';
import { size } from 'lodash-es';
import styled from 'styled-components';

import { Grid, GridColumn, Text } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';

interface Props {
  hoursOfOperations: any;
}

const HourOfOperationGrid = styled(Grid)`
  border-bottom: 1px solid ${p => p.theme.brandSecondary};
`;

const getHourFormatted = (hourFormatted: string) => hourFormatted.replace('00:00', '12:00').replace('00:30', '12:30');

class HoursOfOperationViewMode extends PureComponent<Props> {
  render() {
    const { hoursOfOperations } = this.props;
    const operationsHoursList = hoursOfOperations.hoursOfOperation;
    return (
      <Fragment>
        <HourOfOperationGrid>
          <GridColumn size="4/12" padding="small" />
          <GridColumn size="4/12" padding="small">
            <Text weight="medium" size="small">
              {translate('common.open')}
            </Text>
          </GridColumn>
          <GridColumn size="4/12" padding="small">
            <Text weight="medium" size="small">
              {translate('common.close')}
            </Text>
          </GridColumn>
        </HourOfOperationGrid>
        {!!size(operationsHoursList) &&
          operationsHoursList.map((obj: any, index: number) => (
            <HourOfOperationGrid multiLine margin="no no small no" key={index}>
              {obj.isActive && (
                <Fragment key={index}>
                  <GridColumn size="4/12" padding="small">
                    <Text>{obj.titleKey}</Text>
                  </GridColumn>
                  <GridColumn size="4/12" padding="small">
                    <Text>{getHourFormatted(obj.fromHoursFormatted)}</Text>
                  </GridColumn>
                  <GridColumn size="4/12" padding="small">
                    <Text>{getHourFormatted(obj.toHoursFormatted)}</Text>
                  </GridColumn>
                </Fragment>
              )}

              {!obj.isActive && (
                <Fragment>
                  <GridColumn size="4/12" padding="small">
                    <Text>{obj.titleKey}</Text>
                  </GridColumn>
                  <GridColumn size="4/12" padding="small">
                    {translate('haulerProfile.closed')}
                  </GridColumn>
                  <GridColumn size="4/12" padding="small" />
                </Fragment>
              )}
            </HourOfOperationGrid>
          ))}
      </Fragment>
    );
  }
}

export default HoursOfOperationViewMode;
