import { http } from '../../core/services/http';
import translate from '../../core/services/translate';
import { downloadExcelFile } from '../../utils/services/downloadExcelFile';

export const exportContainers = (
  vendorId: number,
  searchTerm?: string,
  containerTypeId?: number,
  locationId?: number,
  equipmentTypeId?: number,
  equipmentSizeId?: number,
  equipmentConditionId?: number,
  sortedBy?: string,
  sortOrder?: string,
) =>
  downloadExcelFile('/inventories/export', translate('containers.containers'), {
    vendorId,
    searchTerm,
    containerTypeId,
    locationId,
    equipmentTypeId,
    equipmentSizeId,
    equipmentConditionId,
    sortedBy,
    sortOrder,
  });

export const deleteContainersBatched = (vendorId: number, containerIds: number[]) =>
  http.post(`/inventories/containers/delete`, { vendorId, containerIds }).then(response => response.data);

export const transferContainersBatched = (vendorId: number, locationId: number, containerIds: number[]) =>
  http.post(`/inventories/containers/transfer`, { vendorId, locationId, containerIds });

export const loadContainerFacilities = (vendorId: number) =>
  http.get('/inventories/facilities', { params: { vendorId } }).then(response => response.data);
