import React, { PureComponent, Fragment } from 'react';
import Cookie from 'js-cookie';
import { orderBy } from 'lodash-es';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { Districts } from '../../../vendors/interfaces/DivisionAccess';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { currentVendorGusIdSelector, currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { loadContacts } from '../../ducks';
import { HPORT } from '../../constants/contactTypes';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ContactsPage from './ContactsPage';
import { loadHaulerProfileDivisions } from '../../ducks/divisions';
import translate from '../../../core/services/translate';

interface Props {
  gusId?: string;
  vendorId: number;
  loadContacts(...args: any[]): any;
  loadHaulerProfileDivisions(...args: any[]): any;
}

class ContactsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { gusId, vendorId, loadContacts, loadHaulerProfileDivisions } = this.props;
    if (!gusId) return Promise.reject();
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    return divisionIdFromCookie
      ? Promise.all([loadContacts(gusId, HPORT, divisionIdFromCookie), loadHaulerProfileDivisions(vendorId)])
      : loadHaulerProfileDivisions(vendorId).then((response: Districts[]) => {
          const allDivisions = response;
          if (allDivisions.length > 0) {
            const assignedDivisionsOrderByName = orderBy(allDivisions, 'name', 'asc');
            const divisionId = assignedDivisionsOrderByName[0].value;
            Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, {
              expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
            });
            return Promise.all([loadContacts(gusId, HPORT, divisionId)]);
          }
        });
  };
  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('haulerProfile.contacts')}</DocumentTitle>
        <Resolver resolve={this.loadDependencies} successComponent={ContactsPage} loadingComponent={PageLoading} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadContacts,
  loadHaulerProfileDivisions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPageResolver);
