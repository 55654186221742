import styled, { css } from 'styled-components';
import { sizeMapper } from '../../../utils/styles';

interface HaulerProfileGridColumnProps {
  padding?: string;
  lastColumn?: boolean;
  margin?: string;
  tabletSize?: boolean;
  width?: string;
}

interface HaulerProfileGridProps {
  multiLine?: boolean;
}

export const HaulerProfileGridColumn = styled.div<HaulerProfileGridColumnProps>`
  width: 25%;
  margin: ${props => sizeMapper(props.margin, '2% 2% 2% 0')};
  background: #fff;
  padding: ${props => sizeMapper(props.padding, 'xSmall')};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.tabletSize &&
    css`
      @media (max-width: ${props => props.theme.tabletBreakpoint}) {
        width: 50%;
      }
    `};

  &:nth-child(3n) {
    margin-right: 0;
  }

  ${props =>
    props.lastColumn &&
    css`
      margin-right: auto;
    `};
`;

export const EquipmentsGrid = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const HaulerProfileGrid = styled.div<HaulerProfileGridProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${props =>
    props.multiLine &&
    css`
      flex-wrap: wrap;
    `};
`;
