import { FC, useCallback } from 'react';
import { DocumentTitle } from 'src/common/components';
import { TypedResolver } from 'src/core/components';
import translate from 'src/core/services/translate';
import GroupsPage from './GroupsPage';
import { PageLoading } from 'src/common/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadGroups } from 'src/routes/ducks';
import { useDispatch } from 'react-redux';

const GroupsPageResolver: FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = useCallback(() => {
    const dependencies = [loadGroups(vendorId)(dispatch)];
    return Promise.all(dependencies);
  }, [dispatch, vendorId]);

  return (
    <>
      <DocumentTitle>{translate('routes.groups.manageGroups')}</DocumentTitle>
      <TypedResolver
        successComponent={GroupsPage}
        loadingComponent={PageLoading}
        redirectOnError="/routes/route-templates"
        resolve={loadDependencies}
      />
    </>
  );
};

export default GroupsPageResolver;
