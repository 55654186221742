import moment from 'moment';

import { checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { duration } from 'src/utils/services/formatter';
import { getStationaryTimeInfo, timelineLargestWidth, timelineLargeWidth } from '../../mapWithTimeline/TimelineUtils';
import { Grid, GridColumn } from 'src/core/components/styled';
import {
  loadServiceHistoryData,
  loadTimeManagementData,
  resetServiceHistoryData,
  resetTimeManagementData,
} from 'src/routes/ducks';
import { loadLoadsDumped, resetLoadsDumped } from 'src/routes/ducks/loadsDumped';
import { loadWaterFills, resetWaterFills } from 'src/routes/ducks/waterFills';
import { renderMapLegend, renderStationaryTime } from '../../mapWithTimeline/MapTimeline';
import { renderRouteTimeline } from 'src/insights/services/reportingDetailsOptions';
import {
  RouteDetail,
  RouteDetailLabel,
  RouteDetailsWrapper,
  RouteDetailValue,
} from 'src/routes/components/styled/RouteDetails';
import { RouteTimeline, TimelineContainer } from '../../styled/MapWithTimeline';
import { TripTimeIncident } from '../../mapWithTimeline/Interfaces';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  VEHICLE_TYPE_IDS,
  ROLL_OFF_ID,
  DELIVERY_UTILITY_ID,
  STREET_SWEEPER_ID,
  SNOW_PLOW_ID,
} from 'src/fleet/constants';
import { SCHEDULED } from 'src/routes/constants';
import LoadsDumpedSection from '../routeModalSections/LoadsDumpedSection';
import RouteModalSection from '../routeModalSections/RouteModalSection';
import SnowSweeperServiceHistorySection from '../routeModalSections/SnowSweeperServiceHistorySection';
import TimeManagementSection from '../routeModalSections/TimeManagementSection';
import translate from 'src/core/services/translate';
import WaterFillsSection from '../routeModalSections/WaterFillsSection';

type Props = {
  isNoRoute?: boolean;
};

export default function RouteTab({ isNoRoute }: Props) {
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { routeDetails } = useSelector(state => state.routes.routeDetails);
  const { wasteTypes } = useSelector(state => state.common.wasteTypes);
  const { isLoading: isLoadingLoadsDumped, isDeleting: isDeletingLoadsDumped } = useSelector(
    state => state.routes.loadsDumped,
  );
  const { isLoading: isLoadingWaterFill, isDeleting: isDeletingWaterFill } = useSelector(
    state => state.routes.waterFills,
  );
  const { isLoading: isLoadingTimeManagement } = useSelector(state => state.routes.timeManagement);
  const { isLoading: isLoadingServiceHistory } = useSelector(state => state.routes.serviceHistory);

  const isStreetSweepingFeatureEnabled = useSelector(state => checkIfStreetSweepingIsEnabled(state));
  const streetSweepingSettings = useSelector(state => state.vendors.streetSweepingSettings.streetSweepingSettings);

  if (!routeSummary || !routeDetails) return null;

  const isSnowPlowRoute = routeSummary.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const wasteMaterialType = wasteTypes.find(wasteType => wasteType.id === routeSummary.wasteMaterialTypeId)?.name;
  const showWasteMaterialType =
    routeSummary.vehicleTypeId !== ROLL_OFF_ID &&
    routeSummary.vehicleTypeId !== DELIVERY_UTILITY_ID &&
    routeSummary.vehicleTypeId !== STREET_SWEEPER_ID &&
    routeSummary.vehicleTypeId !== SNOW_PLOW_ID;

  const hasNotEnoughDataRoute =
    routeDetails.totalInTransitTime === 0 &&
    routeDetails.totalServiceTime === 0 &&
    routeDetails.totalStationaryTime === 0 &&
    routeDetails.totalTimeAtDisposalFacility === 0 &&
    routeDetails.totalTimeAtOperationalFacility === 0 &&
    routeDetails.totalTripTime === 0;
  const hasNotEnoughDataSnowSweeperRoute =
    routeDetails.totalBreakTime === 0 &&
    routeDetails.totalFacilityTime === 0 &&
    routeDetails.totalRouteTime === 0 &&
    routeDetails.totalServiceTime === 0 &&
    routeDetails.totalStationaryTime === 0 &&
    routeDetails.totalNotServicingTime === 0 &&
    routeDetails.totalTransitTime === 0;
  const hasNotEnoughData = isSnowOrSweeperRoute ? hasNotEnoughDataSnowSweeperRoute : hasNotEnoughDataRoute;

  return (
    <>
      <RouteDetailsWrapper>
        {!isSnowOrSweeperRoute && (
          <>
            <RouteDetail>
              <RouteDetailLabel>{translate('vehicles.vehicleType')}</RouteDetailLabel>
              <RouteDetailValue>{VEHICLE_TYPE_IDS[routeSummary.vehicleTypeId].name}</RouteDetailValue>
            </RouteDetail>
            <RouteDetail>
              <RouteDetailLabel>{translate('common.date')}</RouteDetailLabel>
              <RouteDetailValue>{moment(routeSummary.date).format('MM/DD/YYYY')}</RouteDetailValue>
            </RouteDetail>
          </>
        )}

        {showWasteMaterialType && (
          <RouteDetail>
            <RouteDetailLabel>{translate('common.wasteType')}</RouteDetailLabel>
            <RouteDetailValue>{wasteMaterialType}</RouteDetailValue>
          </RouteDetail>
        )}

        <RouteDetail>
          <RouteDetailLabel>{translate('routes.totalTripTime')}</RouteDetailLabel>
          <RouteDetailValue>
            {duration(
              isSnowOrSweeperRoute ? routeDetails.totalRouteTime : routeDetails.routeTripTime,
              'seconds',
              'hh:mm:ss',
            )}
          </RouteDetailValue>
        </RouteDetail>

        {isSnowOrSweeperRoute && (
          <>
            <RouteDetail>
              <RouteDetailLabel>{translate('routes.totalTransitTime')}</RouteDetailLabel>
              <RouteDetailValue>{duration(routeDetails.totalTransitTime, 'seconds', 'hh:mm:ss')}</RouteDetailValue>
            </RouteDetail>
            <RouteDetail>
              <RouteDetailLabel>{translate('routes.totalServiceTime')}</RouteDetailLabel>
              <RouteDetailValue>{duration(routeDetails.totalServiceTime, 'seconds', 'hh:mm:ss')}</RouteDetailValue>
            </RouteDetail>
            <RouteDetail>
              <RouteDetailLabel>{translate('routes.routeStatusDetails.totalNotServicingTime')}</RouteDetailLabel>
              <RouteDetailValue>{duration(routeDetails.totalNotServicingTime, 'seconds', 'hh:mm:ss')}</RouteDetailValue>
            </RouteDetail>
            <RouteDetail>
              <RouteDetailLabel>{translate('routes.totalFacilityTime')}</RouteDetailLabel>
              <RouteDetailValue>{duration(routeDetails.totalFacilityTime, 'seconds', 'hh:mm:ss')}</RouteDetailValue>
            </RouteDetail>
            <RouteDetail>
              <RouteDetailLabel>{translate('routes.totalBreakTime')}</RouteDetailLabel>
              <RouteDetailValue>{duration(routeDetails.totalBreakTime, 'seconds', 'hh:mm:ss')}</RouteDetailValue>
            </RouteDetail>
          </>
        )}

        <RouteDetail>
          <RouteDetailLabel>{translate('routes.startingLocation')}</RouteDetailLabel>
          <RouteDetailValue>{routeDetails.startingLocationName || '-'}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.endingLocation')}</RouteDetailLabel>
          <RouteDetailValue>{routeDetails.endingLocationName || '-'}</RouteDetailValue>
        </RouteDetail>

        {isStreetSweeperRoute && isStreetSweepingFeatureEnabled && (
          <>
            {streetSweepingSettings?.loadsDumped && (
              <RouteDetail>
                <RouteDetailLabel>{translate('common.loadsDumped.loadsDumped')}</RouteDetailLabel>
                <RouteDetailValue>{routeDetails.numberOfStreetSweeperDisposalTickets || 0}</RouteDetailValue>
              </RouteDetail>
            )}
            {streetSweepingSettings?.waterFillUps && (
              <RouteDetail>
                <RouteDetailLabel>{translate('common.waterFill.waterFill')}</RouteDetailLabel>
                <RouteDetailValue>{routeDetails.numberOfStreetSweeperMaterialPickUpTickets || 0}</RouteDetailValue>
              </RouteDetail>
            )}
          </>
        )}
      </RouteDetailsWrapper>

      {routeSummary.routeStatusTypeId !== SCHEDULED && (
        <TimelineContainer width={isSnowOrSweeperRoute ? timelineLargestWidth : timelineLargeWidth}>
          <RouteTimeline>
            {renderRouteTimeline(
              isSnowOrSweeperRoute ? routeDetails.totalRouteTime : routeDetails.totalTripTime,
              routeDetails.tripTimeDetails,
              35,
              hasNotEnoughData,
              isSnowOrSweeperRoute,
            )}
            {renderStationaryTime(
              getStationaryTimeInfo(
                routeDetails.tripTimeIncidents as TripTimeIncident[],
                routeDetails.tripTimeDetails?.length ? routeDetails.tripTimeDetails[0].startTime : '',
              ).map,
              isSnowOrSweeperRoute ? routeDetails.totalRouteTime : routeDetails.totalTripTime,
            )}
          </RouteTimeline>
          {!hasNotEnoughData &&
            renderMapLegend(routeDetails.totalStationaryTime * 1000, isSnowPlowRoute, isStreetSweeperRoute)}
        </TimelineContainer>
      )}

      <Grid multiLine margin="medium no no no">
        {isStreetSweeperRoute && isStreetSweepingFeatureEnabled && (
          <>
            {streetSweepingSettings?.loadsDumped && (
              <GridColumn size="12/12">
                <RouteModalSection
                  isLoading={isLoadingLoadsDumped || isDeletingLoadsDumped}
                  title={translate('common.loadsDumped.loadsDumped')}
                  content={<LoadsDumpedSection />}
                  loadDataFunction={loadLoadsDumped}
                  resetDataAction={resetLoadsDumped}
                />
              </GridColumn>
            )}
            {streetSweepingSettings?.waterFillUps && (
              <GridColumn size="12/12">
                <RouteModalSection
                  isLoading={isLoadingWaterFill || isDeletingWaterFill}
                  title={translate('common.waterFill.waterFill')}
                  content={<WaterFillsSection />}
                  loadDataFunctionWithoutVendor={loadWaterFills}
                  resetDataAction={resetWaterFills}
                />
              </GridColumn>
            )}
          </>
        )}

        {isSnowOrSweeperRoute && (
          <GridColumn size="12/12">
            <RouteModalSection
              isLoading={isLoadingTimeManagement}
              title={translate('vendors.featureCodes.timeManagement')}
              content={<TimeManagementSection />}
              loadDataFunction={loadTimeManagementData}
              resetDataAction={resetTimeManagementData}
            />
          </GridColumn>
        )}

        {isSnowOrSweeperRoute && !isNoRoute && (
          <GridColumn size="12/12">
            <RouteModalSection
              isLoading={isLoadingServiceHistory}
              title={translate('customers.serviceHistory')}
              content={<SnowSweeperServiceHistorySection />}
              loadDataFunction={loadServiceHistoryData}
              resetDataAction={resetServiceHistoryData}
            />
          </GridColumn>
        )}
      </Grid>
    </>
  );
}
