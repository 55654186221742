import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { exportFile as doExportFile, loadFiles as doLoadFiles, saveFile as doSaveFile } from '../services/files';

// Actions
const START_LOAD = 'haulerProfile/files/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/files/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/files/FAIL_LOAD';
const START_SAVE = 'haulerProfile/files/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/files/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/files/FAIL_SAVE';
const START_EXPORT = 'haulerProfile/files/START_EXPORT';
const COMPLETE_EXPORT = 'haulerProfile/files/COMPLETE_EXPORT';
const FAIL_EXPORT = 'haulerProfile/files/FAIL_EXPORT';
const RESET = 'haulerProfile/files/RESET';

// Initial state =
const initialState = {
  isLoading: false,
  isSaving: false,
  isExporting: false,
  files: [],
  isValidGusId: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          files: action.files,
          isValidGusId: true,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return initialState;
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (files: any) => ({
  type: COMPLETE_LOAD,
  files,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const loadFiles = (params: any) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadFiles(params)
    .then(files => dispatch(completeLoad(files)))
    .catch(() => dispatch(failLoad()));
};

export const saveFile = (params: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveFilePromise = doSaveFile(params);
  saveFilePromise
    .then(() => {
      dispatch(completeSave());
    })
    .catch(() => {
      dispatch(failSave());
    });

  return saveFilePromise;
};

export const exportFile =
  (fileId: number, fileName: string, vendorGroupId: number, divisionId?: string) => (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportFilePromise = doExportFile(fileId, fileName, vendorGroupId, divisionId);
    exportFilePromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));

    return exportFilePromise;
  };

export const resetFiles = () => ({
  type: RESET,
});
