import { map, omit } from 'lodash-es';
import moment from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { DuckFunction } from 'src/contracts/ducks';
import { Modal } from 'src/core/components';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { loadVehicles } from 'src/fleet/ducks';
import { DispatchBoardRouteEditorForm } from 'src/routes/components/forms';
import { DISPATCH_BOARD_SOURCE_FORM_NAME } from 'src/routes/components/forms/DispatchBoardSourceForm';
import { DISPATCH_BOARD_TARGET_FORM_NAME } from 'src/routes/components/forms/DispatchBoardTargetForm';
import { saveDispatchBoardRoute } from 'src/routes/ducks/dispatchBoard';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { getRouteSaveErrorMessage } from 'src/routes/utils/routeDetails';

interface Props {
  closeModal: () => void;
  disableVehicleType?: boolean;
  jobIds?: any[];
  loadSupervisors: DuckFunction<typeof loadSupervisors>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  onSuccess?: () => void;
  routeId?: number;
  saveDispatchBoardRoute: DuckFunction<typeof saveDispatchBoardRoute>;
  onSave?: (routeData?: any) => Promise<any>;
  submit: (formName: string) => void;
  vendorId: number;
}

class DispatchBoardRouteEditorModal extends PureComponent<Props> {
  saveDispatchBoardRoute = (route: any) => {
    const { saveDispatchBoardRoute, submit, closeModal, onSuccess, onSave } = this.props;

    const saveFunction = onSave || saveDispatchBoardRoute;

    let routeData = {
      ...omit(route, ['groupIds']),
      groups: map(route.groupIds, groupId => ({ id: groupId })),
      startTime: route.startTime ? moment(route.startTime, 'hh:mm a').format('HH:mm') : undefined,
      endTime: route.endTime ? moment(route.endTime, 'hh:mm a').format('HH:mm') : undefined,
    };

    saveFunction(routeData)
      .then(() => {
        createSuccessNotification(`${translate('routes.alertMessages.routeSaved')}`);
        closeModal();
        submit(DISPATCH_BOARD_SOURCE_FORM_NAME);
        submit(DISPATCH_BOARD_TARGET_FORM_NAME);

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(error => {
        const { code } = error?.response?.data;
        getRouteSaveErrorMessage(code, route.date);
      });
  };

  handleDateChange = (date: any | Date | string) => {
    const { loadSupervisors, loadVehicles, vendorId } = this.props;
    const showActiveTrucks = true;
    const vehicleTypeId = undefined;

    loadSupervisors(vendorId, date);
    loadVehicles(vendorId, showActiveTrucks, vehicleTypeId, date);
  };

  render() {
    const { routeId, closeModal, jobIds, disableVehicleType } = this.props;

    return (
      <Modal
        title={translate(`routes.${routeId ? 'edit' : 'add'}Route`)}
        subTitle={
          jobIds && jobIds.length > 0
            ? translate('dispatchBoard.routeBuilder.jobsSelected', { selectedJobs: jobIds.length })
            : undefined
        }
        subTitleColor="primary"
        subTitleFontWeight="medium"
        subTitleSize="medium"
        onClose={closeModal}
        padding="medium"
        size="small"
      >
        <DispatchBoardRouteEditorForm
          disableVehicleType={disableVehicleType}
          routeId={routeId}
          onSubmit={this.saveDispatchBoardRoute}
          jobIds={jobIds}
          handleDateChange={this.handleDateChange}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  loadSupervisors,
  loadVehicles,
  saveDispatchBoardRoute,
  submit,
};

export default connect(undefined, mapDispatchToProps)(DispatchBoardRouteEditorModal);
