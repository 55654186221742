import { find } from 'lodash-es';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { TRAVEL_PATH_SERVICE_TYPES_IDS } from 'src/routes/constants';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';

const RouteMapTravelPathGLPopup: FC = () => {
  const dispatch = useDispatch();
  const { travelPathData, showTravelPath } = useSelector(state => state.routes.travelPath);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const selectedTravelPath = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.feature !== RouteMapFeature.travelPath ||
      !showTravelPath ||
      !travelPathData
    ) {
      return undefined;
    }

    return find(travelPathData.features, travelPath => travelPath.properties.pathId === selectedFeature.id);
  }, [selectedFeature, showTravelPath, travelPathData]);

  if (!selectedTravelPath || !selectedFeature?.coordinates) return null;

  const { sequence, streetId, side, servCode, priority } = selectedTravelPath.properties;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedFeature.coordinates.lat}
      longitude={selectedFeature.coordinates.lng}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle title={TRAVEL_PATH_SERVICE_TYPES_IDS[servCode]?.name} />
        <MapGLPopupContent>
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.sequenceNo')}
            value={sequence}
          />
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.priority')}
            value={priority}
          />
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.streetID')}
            value={streetId}
          />
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('customers.streetNetwork.travelDirection')}
            value={
              side === 'R'
                ? translate('routes.routeSettings.directions.right')
                : translate('routes.routeSettings.directions.left')
            }
          />
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default RouteMapTravelPathGLPopup;
