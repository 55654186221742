import { camelCase } from 'lodash-es';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, PopoverWrapper } from 'src/core/components';
import { BILLED, DRAFT } from 'src/finance/constants/invoiceStatuses';
import { Bills } from 'src/finance/interfaces/Billing';
import {
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  Grid,
  GridColumn,
  Popover,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
} from 'src/core/components/styled';
import { currency } from 'src/utils/services/formatter';
import { InfoIcon } from 'src/routes/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import BillHistoryModalResolver from 'src/finance/components/modals/BillHistoryModalResolver';
import SendBillModalResolver from 'src/finance/components/modals/SendBillModalResolver';
import translate from 'src/core/services/translate';

type Props = Bills & {
  statusName?: string;
  changeStatusToUnbilled: (billId: number) => void;
  onDraftBillSelected: (billId: number) => void;
  selectedDraftBills: number[];
  tableCellWidths: { [key: string]: string };
};

const BillsListTableRow: React.FC<Props> = ({
  changeStatusToUnbilled,
  onDraftBillSelected,
  selectedDraftBills,
  tableCellWidths,
  ...billData
}) => {
  const dispatch = useDispatch();

  const [showSendBillModal, setShowSendBillModal] = useState(false);
  const [showBillHistoryModal, setShowBillHistoryModal] = useState(false);

  const onEditBill = () => dispatch(push(`/finance/billing/bill-details/${billData.billId}`));
  const onSendBillClick = () => setShowSendBillModal(true);
  const closeSendBillModal = () => setShowSendBillModal(false);
  const viewBillHistory = () => setShowBillHistoryModal(true);
  const closeBillHistory = () => setShowBillHistoryModal(false);

  const isChecked = selectedDraftBills.includes(billData.billId);

  return (
    <TableRow id={`bill-${billData.billId}`} height={TABLE_ROW_HEIGHT_LARGE}>
      <TableCell width={tableCellWidths.draftBillCheckbox} padding="defaultCellVertical xSmall">
        {billData.status === DRAFT && (
          <CheckboxContainer block noLabel size="small" margin="no no xSmall no">
            <CheckboxInput
              type="checkbox"
              name={billData.billId.toString()}
              checked={isChecked}
              onChange={() => onDraftBillSelected(billData.billId)}
            />
            <CheckboxCheck />
          </CheckboxContainer>
        )}
      </TableCell>
      <TableCell
        id={`bill-${billData.billId}-id-cell`}
        width={tableCellWidths.billId}
        justifyContent="center"
        vertical
        padding="defaultCellVertical xSmall"
      >
        <Text block>{billData.billId || '-'}</Text>
      </TableCell>
      <TableCell
        id={`bill-${billData.billId}-status-cell`}
        width={tableCellWidths.billStatus}
        justifyContent="center"
        vertical
        padding="defaultCellVertical xSmall"
      >
        <Text flex>
          {billData.statusName ? translate(`finance.invoiceStatuses.${camelCase(billData.statusName)}`) : '-'}
          {billData.isOverdue && (
            <ActionButtonTooltip size="medium" icon="overdue" tooltip="pastDue" margin="no no no xxSmall" />
          )}
          {billData.showWarningIcon && (
            <ActionButtonTooltip
              size="mMedium"
              icon="issueStatus"
              tooltip={billData.showWarningIconTooltip || translate('finance.alertMessages.billReleaseError')}
              tooltipAsString
              margin="no no no xxSmall"
            />
          )}
        </Text>
        {billData.status !== DRAFT && (
          <Text block fontStyle="italic">
            {billData.paymentStatusName
              ? translate(`finance.applyChecks.invoicePaymentStatuses.${camelCase(billData.paymentStatusName)}`)
              : '-'}
          </Text>
        )}
      </TableCell>
      <TableCell
        id={`bill-${billData.billId}-startDate-cell`}
        width={tableCellWidths.billDate}
        padding="defaultCellVertical xSmall"
      >
        <Text>{billData.billDate ? moment(billData.billDate).format('MM/DD/YYYY') : '-'}</Text>
      </TableCell>
      <TableCell
        id={`bill-${billData.billId}-endDate-cell`}
        width={tableCellWidths.dueDate}
        padding="defaultCellVertical xSmall"
      >
        <Text>{billData.dueDate ? moment(billData.dueDate).format('MM/DD/YYYY') : '-'}</Text>
      </TableCell>
      <TableCell
        justifyContent="center"
        id={`bill-${billData.billId}-total-cell`}
        width={tableCellWidths.billTotal}
        vertical
        padding="defaultCellVertical xSmall"
      >
        <Grid>
          <GridColumn width="80px" padding="no">
            {translate('common.total')}:
          </GridColumn>
          <Text>{currency(billData.billTotal || 0)}</Text>
        </Grid>
        <Grid>
          <GridColumn width="80px" padding="no">
            {translate('finance.balance')}:
          </GridColumn>
          <Text>{currency(billData.billBalance || 0)}</Text>
        </Grid>
      </TableCell>
      <TableCell
        id={`bill-${billData.billId}-customer-cell`}
        justifyContent="center"
        vertical
        width={tableCellWidths.customerId}
        padding="defaultCellVertical xSmall"
      >
        <Text>
          {billData.customerName || '-'} • {billData.customerId || '-'}
        </Text>
        <Text block color="grayDarker">
          {billData.siteName || '-'} • {billData.siteId || '-'}
        </Text>
      </TableCell>

      <TableCell
        id={`bill-${billData.billId}-lastUpdateInfo-cell`}
        justifyContent="center"
        width={tableCellWidths.updatedDateTime}
        padding="defaultCellVertical xSmall"
      >
        <Grid flexDirection="row" margin="no">
          <Text color="grayDarker">
            {billData.updatedDateTime ? moment(billData.updatedDateTime).format('MM/DD/YYYY') : '-'}
          </Text>

          <PopoverWrapper
            margin="no"
            triggerButton={<InfoIcon />}
            popoverContent={
              <Popover>
                <Text block color="grayDarker">
                  {translate('common.user')}: {billData.updatedUser || '-'}
                </Text>
                <Text block color="grayDarker">
                  {billData.updatedDateTime ? moment(billData.updatedDateTime).format('h:mm a') : '-'}
                </Text>
              </Popover>
            }
            size="medium"
            width="20%"
            align="center"
          />
        </Grid>
      </TableCell>

      <TableCell
        id={`bill-${billData.billId}-options-cell`}
        width={tableCellWidths.options}
        justifyContent="center"
        padding="defaultCellVertical xSmall"
      >
        {billData.status === BILLED ? (
          <>
            <TableActionButton onClick={onEditBill} id={`bill-${billData.billId}-view-button`}>
              <ActionButtonTooltip icon="redeye" tooltip="viewBill" />
            </TableActionButton>
            <TableActionButton onClick={onSendBillClick} id={`bill-${billData.billId}-send-bill-button`}>
              <ActionButtonTooltip icon="email" tooltip="sendBill" />
            </TableActionButton>
          </>
        ) : (
          <>
            <TableActionButton onClick={onEditBill} id={`bill-${billData.billId}-edit-button`}>
              <ActionButtonTooltip icon="edit" tooltip="editBill" />
            </TableActionButton>
            <TableActionButton
              onClick={() => changeStatusToUnbilled(billData.billId)}
              id={`bill-${billData.billId}-delete-button`}
            >
              <ActionButtonTooltip icon="delete" tooltip="deleteBill" />
            </TableActionButton>
          </>
        )}
        <TableActionButton onClick={viewBillHistory} id={`bill-history-${billData.billId}-view-button`}>
          <ActionButtonTooltip icon="history" tooltip="viewBillHistory" customViewBox="0 0 25 25" />
        </TableActionButton>
      </TableCell>
      {showSendBillModal && (
        <SendBillModalResolver
          closeModal={closeSendBillModal}
          billInfo={{
            billId: billData.billId,
            customerName: billData.customerName,
            siteName: billData.siteName,
            siteAddress: billData.siteAddress,
            status: billData.statusName,
          }}
        />
      )}
      {showBillHistoryModal && (
        <BillHistoryModalResolver
          closeModal={closeBillHistory}
          billInfo={{
            billId: billData.billId,
            customerName: billData.customerName,
            siteName: billData.siteName,
            siteAddress: billData.siteAddress,
            status: billData.statusName,
          }}
        />
      )}
    </TableRow>
  );
};

export default BillsListTableRow;
