import React from 'react';
import moment from 'moment';

import { TableCell, TableRow, Text } from '../../../../core/components/styled';
import { VehicleFaultCode } from '../../../interfaces/VehicleFaultCodes';

type Props = VehicleFaultCode;

const VehicleInspectionsTableRow: React.FC<Props> = ({
  suspectParameterNumberCode,
  suspectParametersNumberDescription,
  failureModeIdentifier,
  failureModeIdentifierDescription,
  occurenceCount,
  eventTime,
}) => (
  <TableRow>
    <TableCell width="15%" padding="defaultCellVertical defaultCellHorizontal defaultCellVertical medium">
      {suspectParameterNumberCode}
    </TableCell>

    <TableCell width="17%">{suspectParametersNumberDescription}</TableCell>

    <TableCell width="15%" align="center">
      {failureModeIdentifier}
    </TableCell>

    <TableCell width="23%">{failureModeIdentifierDescription}</TableCell>

    <TableCell width="15%" align="center">
      {occurenceCount}
    </TableCell>

    <TableCell vertical width="15%" horizontalAlign="right" padding="defaultCellVertical medium defaultCellVertical defaultCellHorizontal">
      <Text size="sMedium">{moment(eventTime).format('MM/DD/YYYY')}</Text>

      <Text size="sMedium" color="grayDarker">
        {moment(eventTime).format('LT')}
      </Text>
    </TableCell>
  </TableRow>
);

export default VehicleInspectionsTableRow;
