import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadMaterialTypes } from 'src/vendors/ducks';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import MaterialTypesModal from './MaterialTypesModal';

interface Props extends ModalProps {}

const MaterialTypesModalResolver: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadMaterialTypes(vendorId)(dispatch), loadVehicleTypesForVendor(vendorId)(dispatch)]);
  }, [dispatch, vendorId]);

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={MaterialTypesModal}
      successProps={{ closeModal }}
    />
  );
};

export default MaterialTypesModalResolver;
