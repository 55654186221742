import * as turf from '@turf/turf';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Units } from '@turf/helpers';

import { PinLocation } from 'src/customers/interfaces/Customers';
import { theme } from 'src/core/styles';

interface Props {
  pinLocation?: PinLocation;
  radius: number;
}

const CustomerProximityMapGLSource: FC<Props> = ({ pinLocation, radius }) => {
  const center = [pinLocation?.longitude || 0, pinLocation?.latitude || 0];
  const options = { steps: 50, units: 'yards' as Units };
  const circle = turf.circle(center, radius, options);

  return (
    <Source id="pinLocationSource" type="geojson" data={circle}>
      <Layer
        id="pinLocationLayer"
        type="fill"
        paint={{
          'fill-color': theme.brandPrimary,
          'fill-opacity': 0.3,
        }}
      />
    </Source>
  );
};

export default CustomerProximityMapGLSource;
