import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { RouteBuilderSettingsState } from '../interfaces/RouteBuilderSettings';
import { RouteSettings } from '../interfaces/RouteSettings';
import {
  loadRouteBuilderSettings as doLoadRouteBuilderSettings,
  saveRouteBuilderSettings as doSaveRouteBuilderSettings,
} from '../services/routeBuilderSettings';

// Actions
const COMPLETE_LOAD = 'vendors/routeBuilderSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/routeBuilderSettings/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/routeBuilderSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/routeBuilderSettings/FAIL_SAVE';
const START_LOAD = 'vendors/routeBuilderSettings/START_LOAD';
const START_SAVE = 'vendors/routeBuilderSettings/START_SAVE';

// Initial state
const initialState: RouteBuilderSettingsState = {
  isLoading: false,
  isSaving: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeBuilderSettings: action.routeBuilderSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeBuilderSettings: RouteSettings) => ({
  type: COMPLETE_LOAD,
  routeBuilderSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadRouteBuilderSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadRouteBuilderSettingsPromise = doLoadRouteBuilderSettings(vendorId);

  loadRouteBuilderSettingsPromise
    .then(routeBuilderSettings => dispatch(completeLoad(routeBuilderSettings)))
    .catch(() => dispatch(failLoad()));

  return loadRouteBuilderSettingsPromise;
};

export const saveRouteBuilderSettings = (vendorId: number, routeBuilderSettings: RouteSettings) => (
  dispatch: Dispatch,
) => {
  dispatch(startSave());

  const saveRouteBuilderSettingsPromise = doSaveRouteBuilderSettings(vendorId, routeBuilderSettings);

  saveRouteBuilderSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveRouteBuilderSettingsPromise;
};
