import React, { ChangeEvent, PureComponent } from 'react';

import { map } from 'lodash-es';

import { CONFIRMATION_TYPES } from '../constants';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {}

class ConfirmationTypeDropDown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;

    const confirmationTypeOptions = map(CONFIRMATION_TYPES, confirmationType => ({
      label: confirmationType.name,
      value: confirmationType.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('routes.confirmationType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('routes.confirmationType') : undefined),
          options: confirmationTypeOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default ConfirmationTypeDropDown;
