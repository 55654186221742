import { PureComponent } from 'react';

import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { NavigationSettings } from 'src/vendors/interfaces/NavigationSettings';
import { Table } from '../../../core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  ModalClose,
  ModalCloseIcon,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import navigationSettingsFormInitialValuesSelector from '../../../vendors/services/navigationSettingsFormInitialValuesSelector';
import NavigationTableRow from '../NavigationTableRow';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
  pristine?: boolean;
  navigationSettings?: NavigationSettings[];
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class NavigationForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine, navigationSettings } = this.props;

    const navigationFormTableCells = [
      { name: 'vehicleType', label: translate('vehicles.vehicleType'), noPaddingRight: true, width: '20%' },
      { name: 'isActive', label: translate('common.active'), noPaddingRight: true, width: '10%' },
      {
        name: 'avoidFreeways',
        label: translate('vendors.navigation.avoidFreeways'),
        noPaddingRight: true,
        width: '12%',
      },
      {
        name: 'avoidTollRoads',
        label: translate('vendors.navigation.avoidTollRoads'),
        noPaddingRight: true,
        width: '13%',
      },
      { name: 'avoidUTurns', label: translate('vendors.navigation.avoidUTurns'), noPaddingRight: true, width: '11%' },
      {
        name: 'useRealTimeTrafficData',
        label: translate('vendors.navigation.useRealTimeTrafficData'),
        noPaddingRight: true,
        width: '13%',
      },
      {
        name: 'isSequentialNavigationEnabled',
        label: translate('vendors.navigation.sequentialRouteNavigation'),
        noPaddingRight: true,
        width: '15%',
      },
      { name: 'alleyPriorityTypeId', label: translate('vendors.navigation.alleyPriority'), width: '18%' },
    ];

    return (
      <>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit}>
          {size(navigationSettings) ? (
            <>
              <Table cells={navigationFormTableCells} rowComponent={NavigationTableRow} rows={navigationSettings} />
              <Grid centered>
                <GridColumn size="5/12">
                  <ButtonSet margin="medium no">
                    <Button type="submit" color="primary">
                      {translate('common.save')}
                    </Button>
                  </ButtonSet>
                </GridColumn>
              </Grid>
            </>
          ) : (
            <Message padding="sMedium sMedium medium sMedium">{translate('vehicles.noVehiclesConfigured')}</Message>
          )}
        </form>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: (navigationSettingsFormInitialValuesSelector as any)(state.vendors.navigationSettings),
  navigationSettings: state.vendors.navigationSettings.navigationSettings,
});

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    enableReinitialize: true,
    form: 'navigationForm',
    onSubmitFail: focusFirstInvalidField,
  })(NavigationForm),
);
