import { map } from 'lodash-es';

import { EventTypes } from '../interfaces/EventTypes';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadEventTypes = (eventTypes: EventTypes[]) =>
  map(eventTypes, ({ id, name }: any) => ({
    id,
    name: translate(createTranslationKey(name, 'routes.eventTypes')),
  }));

export default transformLoadEventTypes;
