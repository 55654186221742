import { change } from 'redux-form';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { TimePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import moment from 'moment';

import { FormLabel } from './styled';
import { Input, TypedField } from 'src/core/components';
import { TimePickerFieldWrapper } from './styled/TimePicker';
import translate from 'src/core/services/translate';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';

interface Props {
  allowClear?: boolean;
  change: any;
  defaultValue?: Date | string | null;
  disabled?: boolean;
  formName: string;
  fullWidth?: boolean;
  labelName?: string;
  minuteStep?: number;
  name: string;
  onTimePickerChange?: (timeString: string) => void;
  timeFormat?: string;
  validate?: any[];
}

class TimePickerField extends PureComponent<Props> {
  render() {
    const {
      allowClear = true,
      change,
      defaultValue,
      disabled = false,
      formName,
      fullWidth,
      labelName,
      minuteStep,
      name,
      onTimePickerChange,
      timeFormat,
      validate,
    } = this.props;

    const onChange = (time: any, timeString: string) => {
      change(
        formName,
        name,
        time
          ? moment(timeString, timeFormat || timeFormatWithoutSeconds).format(timeFormat || timeFormatWithoutSeconds)
          : null,
      );

      onTimePickerChange && onTimePickerChange(timeString);
    };

    return (
      <>
        <TimePickerFieldWrapper fullWidth={fullWidth}>
          <FormLabel noTransform size="medium">
            {labelName}
          </FormLabel>
          <TimePicker
            onChange={onChange}
            format={timeFormat || timeFormatWithoutSeconds}
            popupStyle={{
              zIndex: 9999,
            }}
            defaultValue={defaultValue ? moment(defaultValue, timeFormat || 'HH:mm') : undefined}
            placeholder={'hh:mm'}
            locale={{
              ...locale,
              lang: {
                ...locale.lang,
                now: translate('common.now'),
                ok: translate('common.ok'),
              },
            }}
            minuteStep={minuteStep}
            allowClear={allowClear}
            disabled={disabled}
          />
          <TypedField
            name={name}
            component={Input}
            props={{ type: 'hidden', margin: 'no', shouldShowError: true }}
            validate={validate}
          />
        </TimePickerFieldWrapper>
      </>
    );
  }
}

const mapDispatchToProps = {
  change,
};

export default connect(undefined, mapDispatchToProps)(TimePickerField);
