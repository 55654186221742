import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadHaulerProfileDivisions as doLoadHaulerProfileDivisions } from '../services/compliance';

// Actions
const START_LOAD = 'haulerProfile/divisions/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/divisions/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/divisions/FAIL_LOAD';

// Initial state
const initialState = {
  isLoading: false,
  divisions: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          divisions: action.divisions,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (divisions: any) => ({
  type: COMPLETE_LOAD,
  divisions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadHaulerProfileDivisions = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDivisionssPromise = doLoadHaulerProfileDivisions(vendorId);
  loadDivisionssPromise.then(divisions => dispatch(completeLoad(divisions))).catch(() => dispatch(failLoad()));
  return loadDivisionssPromise;
};
