import { reduce, identity, set } from 'lodash-es';
import { createSelector } from 'reselect';

const getPricingInfoIntialValues = (pricingInfoState: any) => ({
  pricingInfo: reduce(
    [pricingInfoState],
    (initialValues, { rubiconInvoiceNo, reportDate, acceptedWOTotalAmount }) =>
      set(initialValues, `settings${rubiconInvoiceNo}`, {
        rubiconInvoiceNo,
        reportDate,
        acceptedWOTotalAmount,
        paymentMethods: undefined,
      }),
    {},
  ),
});

const pricingInfoIntialValueSelector = createSelector(getPricingInfoIntialValues, identity);

export default pricingInfoIntialValueSelector;
