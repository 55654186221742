import update from 'immutability-helper';
import { checkSelfUser as doCheckUser, createSelfUser as doCreateSelfUser } from '../services/account';
import { login } from '../ducks';
import { getSelfCreateUser } from '../services/selfCreateUser';
import { RUBICON_PRO_PORTAL } from '../../vendors/constants';
import { AnyAction } from 'redux';
import { User } from './login';
import { ThunkDispatch } from 'redux-thunk';
// Actions
const START_CHECK_USER = 'account/createUserAccount/START_CHECK_USER';
const COMPLETE_CHECK_USER = 'account/createUserAccount/COMPLETE_CHECK_USER';
const FAIL_CHECK_USER = 'account/createUserAccount/FAIL_CHECK_USER';
const START_CREATE_USER = 'account/createUserAccount/START_CREATE_USER';
const COMPLETE_CREATE_USER = 'account/createUserAccount/COMPLETE_CREATE_USER';
const FAIL_CREATE_USER = 'account/createUserAccount/FAIL_CREATE_USER';
const SELF_USER_LOGOUT = 'account/createUSerAccount/SELF_USER_LOGOUT';
const RESET = 'account/createUserAccount/RESET';

// Initial state
const selfUser = getSelfCreateUser('user');

export interface CreateUserAccountState {
  isCheckingUser: boolean;
  isCheckingUserFailed: boolean;
  isCreatingUser: boolean;
  isFailedCreatingUser: boolean;
  isNonTechHauler: boolean;
  vendorId?: number;
  selfUser: User;
}

const initialState: CreateUserAccountState = {
  isCheckingUser: false,
  isCheckingUserFailed: false,
  isCreatingUser: false,
  isFailedCreatingUser: false,
  isNonTechHauler: true,
  vendorId: undefined,
  selfUser,
};

type Dispatch = ThunkDispatch<CreateUserAccountState, any, AnyAction>;

// Reducer
export const reducer = (state: CreateUserAccountState = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_CHECK_USER:
      return update(state, {
        $merge: {
          isCheckingUser: true,
          isCheckingUserFailed: false,
        },
      });

    case COMPLETE_CHECK_USER:
      return update(state, {
        $merge: {
          isCheckingUser: false,
          isCheckingUserFailed: false,
          vendorId: action.user,
        },
      });

    case FAIL_CHECK_USER:
      return update(state, {
        $merge: {
          isCheckingUser: false,
          isCheckingUserFailed: true,
        },
      });

    case START_CREATE_USER:
      return update(state, {
        $merge: {
          isFailedCreatingUser: false,
          isCreatingUser: true,
          isNonTechHauler: false,
        },
      });
    case COMPLETE_CREATE_USER:
      return update(state, {
        $merge: {
          isFailedCreatingUser: false,
          isCreatingUser: false,
          selfUser: action.user,
          isNonTechHauler: !!action.user && action.user.vendor.vendorTypeId === RUBICON_PRO_PORTAL,
        },
      });
    case FAIL_CREATE_USER:
      return update(state, {
        $merge: {
          isFailedCreatingUser: true,
          isCreatingUser: false,
        },
      });
    case SELF_USER_LOGOUT:
      return update(state, {
        $merge: {
          isFailedCreatingUser: false,
          isCreatingUser: false,
          selfUser: undefined,
          isNonTechHauler: false,
        },
      });
    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startCheckUser = () => ({
  type: START_CHECK_USER,
});

const completeCheckUser = (user: User) => ({
  type: COMPLETE_CHECK_USER,
  user,
});

const failcheckUser = (error: any) => ({
  type: FAIL_CHECK_USER,
  error,
});

const startCreateUser = () => ({
  type: START_CREATE_USER,
});

const completeCreateUser = (user: User) => ({
  type: COMPLETE_CREATE_USER,
  user,
});

const selfUserLogout = () => ({
  type: SELF_USER_LOGOUT,
});

const failCreateUser = (error: any) => ({
  type: FAIL_CREATE_USER,
  error,
});

export const checkSelfUser = (token: string) => (dispatch: Dispatch) => {
  dispatch(startCheckUser());
  return doCheckUser(token)
    .then(response => dispatch(completeCheckUser(response.vendorId)))
    .catch(error => dispatch(failcheckUser(error)));
};

export const createSelfUser =
  (
    email: string,
    name: string,
    password: string,
    confirmPassword: string,
    vendorId: number,
    token: string,
    redirectTo: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startCreateUser());
    const createUserPromise = doCreateSelfUser(email, name, password, confirmPassword, vendorId, token);
    createUserPromise
      .then(response => {
        dispatch(completeCreateUser(response));
        dispatch(login(email, password, redirectTo));
      })
      .catch(error => dispatch(failCreateUser(error)));
    return createUserPromise;
  };

export const selfUserDestroy = () => (dispatch: Dispatch) => {
  dispatch(selfUserLogout());
};

export const getVendorTypeId = () => getSelfCreateUser('user.vendor.vendorTypeId');

export const resetResetPassword = () => ({
  type: RESET,
});
