import { FC, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import arrow from 'src/common/assets/img/common/arrowSmall.png';
import dot from 'src/common/assets/img/common/dot.png';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useSelector } from 'src/core/hooks/useSelector';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { getTravelPathVehicleBreadCrumbsLineGeoJSON, getTravelPathVehicleBreadCrumbsPointsGeoJSON } from '../utils';
import TravelPathVehicleBreadcrumbGLSource, {
  TRAVEL_PATH_VEHICLE_BREADCRUMBS_LINE_LAYER,
  TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_SOURCE,
  TRAVEL_PATH_VEHICLE_BREADCRUMBS_SOURCE,
} from './TravelPathVehicleBreadcrumbGLSource';
import TravelPathVehicleBreadcrumbsPopupResolver from './TravelPathVehicleBreadcrumbsPopupResolver';

const mapImages = [
  {
    id: 'arrow',
    url: arrow,
    sourceId: TRAVEL_PATH_VEHICLE_BREADCRUMBS_SOURCE,
    sdf: true,
  },
  {
    id: 'dot',
    url: dot,
    sourceId: TRAVEL_PATH_VEHICLE_BREADCRUMBS_SOURCE,
    sdf: true,
  },
];

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
  mapBearing: number;
  minSelected: number;
  maxSelected: number;
}

const TravelPathVehicleBreadcrumbsGL: FC<Props> = ({ map, mapBearing, minSelected, maxSelected }) => {
  useMapImages(mapImages, map, mapImagesOptions);
  const dispatch = useDispatch();

  const { routeVehicleBreadCrumbs } = useSelector(state => state.routes.travelPathBuildAndEdit);

  const lineGeoJSON = useMemo(() => {
    if (!routeVehicleBreadCrumbs) return null;
    return getTravelPathVehicleBreadCrumbsLineGeoJSON(routeVehicleBreadCrumbs, minSelected, maxSelected);
  }, [maxSelected, minSelected, routeVehicleBreadCrumbs]);

  const pointsGeoJSON = useMemo(() => {
    if (!routeVehicleBreadCrumbs) return null;
    return getTravelPathVehicleBreadCrumbsPointsGeoJSON(routeVehicleBreadCrumbs, mapBearing, minSelected, maxSelected);
  }, [mapBearing, maxSelected, minSelected, routeVehicleBreadCrumbs]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === TRAVEL_PATH_VEHICLE_BREADCRUMBS_POINTS_SOURCE ||
              feature.source === TRAVEL_PATH_VEHICLE_BREADCRUMBS_LINE_LAYER,
          );

        if (!!feature && feature.properties) {
          dispatch(
            setRouteMapSelectedFeature(
              RouteMapFeature.vehicleTrackings,
              feature.properties?.id as number,
              {
                coordinates: event.lngLat,
                vehicleTrackingGroupIndex: feature.properties.groupIndex,
              },
              true,
            ),
          );
        }
      });
    });
  }, [map, dispatch]);

  if (!lineGeoJSON || !pointsGeoJSON) return null;

  return (
    <>
      <TravelPathVehicleBreadcrumbGLSource geoJSONForLine={lineGeoJSON} geoJsonForPoints={pointsGeoJSON} />
      <TravelPathVehicleBreadcrumbsPopupResolver />
    </>
  );
};

export default TravelPathVehicleBreadcrumbsGL;
