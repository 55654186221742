import React from 'react';
import { Switch, Route } from 'react-router';

import { Page } from '../../common/components';
import { RubiconDispatchesPageResolver } from './pages';
import { AuthRoute } from '../../account/components';

const Workorders = () => (
  <Page>
    <Switch>
      <Route exact path="/workorders/:token" component={RubiconDispatchesPageResolver} />
      <AuthRoute exact path="/workorders" component={RubiconDispatchesPageResolver as any} />
    </Switch>
  </Page>
);

export default Workorders;
