import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { size } from 'lodash-es';

import { Box } from 'src/core/components/styled/Box';
import { ComplexMapControl, MapLegend, MapLegendInfo } from 'src/routes/components/styled/RouteMap';
import { getAgingIntervalOptionLabel } from 'src/routes/utils/routeDetails';
import { Icon, UnconnectedSwitch } from 'src/core/components';
import { PassesStatusComplex, PassesStatusSimple, StatusContainer } from 'src/routes/components/styled';
import { ROUTE_SEGMENT_LANE_COLORS, transformFromCamelCase } from './utils';
import { Separator, Text } from 'src/core/components/styled';
import { setSegmentColoringType, setIsLegendCollapsed } from 'src/customers/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  driversColorsMap?: { [key: string]: string };
  isDailyRoute?: boolean;
  isDriverExperienceComplex?: boolean;
  isRouteTemplate?: boolean;
  isSatelliteView: boolean;
  isSnowPlowRoute?: boolean;
  routeId?: number;
}

const StreetNetworkMapLegend: FC<Props> = ({
  driversColorsMap,
  isDailyRoute,
  isDriverExperienceComplex,
  isRouteTemplate,
  isSatelliteView,
  isSnowPlowRoute,
  routeId,
}) => {
  const dispatch = useDispatch();
  const { displayConfiguration } = useSelector(state => state.vendors.snowPlowSettings.snowPlowSettings);
  const { segmentColorSettings } = useSelector(state => state.vendors.streetSweepingSettings.streetSweepingSettings);
  const { isEditSegmentsModeOn } = useSelector(state => state.customers.streetNetwork) as any;
  const settingsToUse = isSnowPlowRoute ? displayConfiguration?.segmentConfiguration : segmentColorSettings;
  const { isTravelPathLegendOpen, travelPathData, showTravelPath } = useSelector(state => state.routes.travelPath);
  const { segmentColorType, isLegendCollapsed } = useSelector(state => state.customers.streetNetwork);

  const onChangeColorType = useCallback(
    (colorType: 'segmentStatus' | 'agingInterval' | 'servicingDriver') => {
      dispatch(setSegmentColoringType(colorType));
    },
    [dispatch],
  );

  const switches = useMemo(() => {
    return (
      <>
        <Separator margin="xSmall no" />
        <UnconnectedSwitch
          checked={segmentColorType === 'segmentStatus'}
          size="small"
          label={translate('common.segmentStatus')}
          onChange={() => onChangeColorType('segmentStatus')}
          margin="no no xxSmall"
        />
        <UnconnectedSwitch
          checked={segmentColorType === 'agingInterval'}
          size="small"
          label={translate('dashboard.lastActivity')}
          onChange={() => onChangeColorType('agingInterval')}
          margin="no no xxSmall"
        />

        <UnconnectedSwitch
          checked={segmentColorType === 'servicingDriver'}
          label={translate('common.driver')}
          size="small"
          onChange={() => onChangeColorType('servicingDriver')}
          margin="no no xxSmall"
        />
      </>
    );
  }, [onChangeColorType, segmentColorType]);

  const yOffset = isTravelPathLegendOpen ? 235 : size(travelPathData?.features) && showTravelPath ? 40 : 0;

  return (
    <ComplexMapControl vertical position="bottom-right" zIndex={1} yOffset={yOffset}>
      {(isRouteTemplate || isDailyRoute) && (
        <MapLegend collapsed={isLegendCollapsed} onClick={() => dispatch(setIsLegendCollapsed(!isLegendCollapsed))}>
          <Text size="small">
            <b>{translate('routes.snowPlow.legend')}</b>
          </Text>
          <Icon icon="chevronDown" />
          {isLegendCollapsed && routeId && !isEditSegmentsModeOn && (
            <MapLegendInfo minWidth="170px">
              {segmentColorType === 'agingInterval' ? (
                <>
                  {settingsToUse
                    ?.filter(item => !!item.enabled)
                    .map((item, index) => {
                      const { blue, green, red, streetSegmentAgingInterval } = item;
                      const { name, timeMeasurementType } = streetSegmentAgingInterval;
                      const { id } = timeMeasurementType;

                      return (
                        <StatusContainer key={index} vertical margin={index === 0 ? 'no' : 'small no no no'}>
                          <PassesStatusComplex forwardColor={`rgba(${red}, ${green}, ${blue})`}></PassesStatusComplex>
                          <Text size="xSmall" margin="no no no small">
                            {getAgingIntervalOptionLabel(id, name)}
                          </Text>
                        </StatusContainer>
                      );
                    })}
                </>
              ) : segmentColorType === 'servicingDriver' ? (
                <Box>
                  {driversColorsMap
                    ? Object.keys(driversColorsMap).map((key, index) => {
                        const color = driversColorsMap[key];
                        return (
                          <StatusContainer key={index} vertical margin={index === 0 ? 'no' : 'small no no no'}>
                            <PassesStatusComplex forwardColor={color}></PassesStatusComplex>
                            <Text size="xSmall" margin="no no no small">
                              {transformFromCamelCase(key)}
                            </Text>
                          </StatusContainer>
                        );
                      })
                    : null}

                  <StatusContainer
                    vertical
                    margin={driversColorsMap && !!Object.keys(driversColorsMap).length ? 'medium no no no' : 'no'}
                  >
                    <PassesStatusComplex forwardColor={'#000'}></PassesStatusComplex>
                    <Text size="xSmall" margin="no no no small">
                      {translate('common.pickupStatuses.notServiced')}
                    </Text>
                  </StatusContainer>
                  <StatusContainer vertical margin={'small no no no'}>
                    <PassesStatusComplex forwardColor={'blue'}></PassesStatusComplex>
                    <Text size="xSmall" margin="no no no small">
                      {translate('dashboard.multiple')} {translate('drivers.drivers')}
                    </Text>
                  </StatusContainer>
                </Box>
              ) : (
                <>
                  <StatusContainer vertical>
                    <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.blue}></PassesStatusComplex>
                    <Text size="xSmall" margin="no no no small">
                      {translate('common.pickupStatuses.notServiced')}
                    </Text>
                  </StatusContainer>
                  {isDriverExperienceComplex && (
                    <StatusContainer vertical margin="small no no no">
                      <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.yellow}></PassesStatusComplex>
                      <Text size="xSmall" margin="no no no small">
                        {translate('common.pickupStatuses.partiallyServiced')}
                      </Text>
                    </StatusContainer>
                  )}
                  <StatusContainer vertical margin="small no no no">
                    <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.green}></PassesStatusComplex>
                    <Text size="xSmall" margin="no no no small">
                      {translate('common.pickupStatuses.serviced')}
                    </Text>
                  </StatusContainer>
                </>
              )}
              {switches}
            </MapLegendInfo>
          )}
          {isLegendCollapsed && routeId && isEditSegmentsModeOn && (
            <MapLegendInfo>
              <StatusContainer vertical>
                <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.blue}></PassesStatusComplex>
                <Text size="xSmall" margin="no no no small">
                  {translate('customers.streetNetwork.status.assignedToThisRoute')}
                </Text>
              </StatusContainer>
              <StatusContainer vertical margin="small no no no">
                {isSatelliteView ? (
                  <PassesStatusSimple customColor={ROUTE_SEGMENT_LANE_COLORS.white}></PassesStatusSimple>
                ) : (
                  <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.black}></PassesStatusComplex>
                )}
                <Text size="xSmall" margin="no no no small">
                  {translate('customers.streetNetwork.status.notAssignedToThisRoute')}
                </Text>
              </StatusContainer>
            </MapLegendInfo>
          )}
          {isLegendCollapsed && isRouteTemplate && (
            <MapLegendInfo>
              <StatusContainer vertical>
                <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.blue}></PassesStatusComplex>
                <Text size="xSmall" margin="no no no small">
                  {translate('customers.streetNetwork.status.assignedToThisRouteTemplate')}
                </Text>
              </StatusContainer>
              <StatusContainer vertical margin="small no no no">
                {isSatelliteView ? (
                  <PassesStatusSimple customColor={ROUTE_SEGMENT_LANE_COLORS.white}></PassesStatusSimple>
                ) : (
                  <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.black}></PassesStatusComplex>
                )}
                <Text size="xSmall" margin="no no no small">
                  {translate('customers.streetNetwork.status.assignedToAnotherRouteTemplate')}
                </Text>
              </StatusContainer>
              <StatusContainer vertical margin="small no no no">
                <PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.orange}></PassesStatusComplex>
                <Text size="xSmall" margin="no no no small">
                  {translate('customers.streetNetwork.status.notAssignedToAnyRouteTemplate')}
                </Text>
              </StatusContainer>
            </MapLegendInfo>
          )}
        </MapLegend>
      )}
    </ComplexMapControl>
  );
};

export default StreetNetworkMapLegend;
