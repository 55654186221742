import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';
import { TODAY_FORMATTED } from '../../core/constants';

const getDisposalSummaryFormInitialValues = (queryString: string) => {
  const { vehicleTypeId, ticketNumber, searchString, disposalSiteLocationId, startDate, endDate } = getQueryParams(
    queryString,
  );

  return {
    vehicleTypeId: Number(vehicleTypeId),
    searchString,
    ticketNumber,
    disposalSiteLocationId: Number(disposalSiteLocationId),
    dateRange: {
      from: startDate || TODAY_FORMATTED,
      to: endDate || TODAY_FORMATTED,
    },
  };
};

const disposalSummaryFormInitialValuesSelector = createSelector(
  getDisposalSummaryFormInitialValues,
  identity,
);

export default disposalSummaryFormInitialValuesSelector;
