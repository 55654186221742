import { http } from '../../core/services/http';
import { MAX_LIMIT, PAGE } from '../../core/constants/pagination';

export const loadUnassignedWeightTickets = (
  vendorId: number,
  startDate: string,
  endDate: string,
  searchTerm: string,
  page: number = PAGE,
  limit: number = MAX_LIMIT,
  isAssigned: boolean = false,
) =>
  http
    .get(`vendor/${vendorId}/weight-tickets/files`, {
      params: { startDate, endDate, searchTerm, page, limit, isAssigned },
    })
    .then(response => response.data);

export const loadUnassignedWeightTicketsByToken = (
  token: string,
  startDate: string,
  endDate: string,
  searchTerm: string,
  page: number = PAGE,
  limit: number = MAX_LIMIT,
  isAssigned: boolean = false,
) =>
  http
    .get(`gus/${token}/weight-tickets/files`, {
      params: { startDate, endDate, searchTerm, page, limit, isAssigned },
    })
    .then(response => response.data);

export const deleteWeightTicket = (fileId: number, vendorId: number) =>
  http.delete(`vendor/${vendorId}/weight-tickets/files/${fileId}`).then(response => response.data);

export const deleteWeightTicketByToken = (fileId: number, token: string) =>
  http.delete(`gus/${token}/weight-tickets/files/${fileId}`).then(response => response.data);

export const uploadDocument = (file: any, vendorId: number) => {
  const formData = new FormData();
  formData.append('file', file);

  return http
    .post(`vendor/${vendorId}/weight-tickets/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};

export const uploadDocumentByToken = (file: any, token: string) => {
  const formData = new FormData();
  formData.append('file', file);

  return http
    .post(`gus/${token}/weight-tickets/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};
