import { http } from 'src/core/services/http';
import {
  deleteCreationRule,
  inboundFieldMapping,
  open311DismissError,
  inboundCreationRule,
  open311Configuration,
  customField,
} from '../interfaces/Open311';

export const loadOpen311Lookup = (vendorId: number) =>
  http.get(`vendors/${vendorId}/open311/configuration/types`).then(response => {
    return response.data;
  });

export const loadOpen311Configuration = (vendorId: number) =>
  http.get(`vendors/${vendorId}/open311/configuration`).then(response => {
    return response.data;
  });

export const saveGlobalNoteField = (configurationId: number, globalNoteField: customField) =>
  http
    .post(`vendors/open311/configurations/${configurationId}/notefields`, globalNoteField)
    .then(response => response.data);

export const updateGlobalNoteField = (configurationId: number, notefieldId: number, globalNoteField: customField) =>
  http
    .put(`vendors/open311/configurations/${configurationId}/notefields/${notefieldId}`, globalNoteField)
    .then(response => response.data);

export const deleteGlobalNoteField = (configurationId: number, ids: deleteCreationRule) =>
  http
    .delete(`vendors/open311/configurations/${configurationId}/notefields`, { data: ids })
    .then(response => response.data);

export const deleteServiceTypeNoteField = (configurationId: number, serviceTypeId: number, ids: deleteCreationRule) =>
  http
    .delete(`vendors/open311/configurations/${configurationId}/servicetypes/${serviceTypeId}/settings/notefields`, {
      data: ids,
    })
    .then(response => response.data);

export const updateServiceTypelNoteField = (
  configurationId: number,
  serviceTypeId: number,
  notefieldId: number,
  globalNoteField: customField,
) =>
  http
    .put(
      `vendors/open311/configurations/${configurationId}/servicetypes/${serviceTypeId}/notefields/${notefieldId}`,
      globalNoteField,
    )
    .then(response => response.data);

export const saveServiceTypeNoteField = (
  configurationId: number,
  serviceTypeId: number,
  serviceRequestNoteField: customField,
) =>
  http
    .post(
      `vendors/open311/configurations/${configurationId}/servicetypes/${serviceTypeId}/notefields`,
      serviceRequestNoteField,
    )
    .then(response => response.data);

export const updateServiceTypeNoteField = (
  configurationId: number,
  serviceTypeId: number,
  noteFieldId: number,
  globalNoteField: customField,
) =>
  http
    .post(`vendors/open311/configurations/${configurationId}/servicetypes/${serviceTypeId}/notefields`, globalNoteField)
    .then(response => response.data);

export const updateServiceConfiguration = (
  serviceConfiguration: open311Configuration,
  Open311ConfigurationId?: number,
) =>
  http
    .put(`vendors/open311/configurations/${Open311ConfigurationId}`, serviceConfiguration)
    .then(response => response.data);

export const updateFieldMappings = (inboundFieldMapping: inboundFieldMapping, Open311ConfigurationId: number) =>
  http
    .put(`open311-configurations/${Open311ConfigurationId}/inbound-configuration`, inboundFieldMapping)
    .then(response => response.data);

export const loadServiceRequestErrors = (vendorId: number) =>
  http.get(`vendors/${vendorId}/open311-configuration/errors`).then(response => {
    return response.data;
  });

export const dismissServiceRequestError = (vendorId: number, ticketIds: open311DismissError) =>
  http.delete(`vendors/${vendorId}/open311-configuration/errors`, { data: ticketIds });

export const deleteCreationRules = (configurationId: number, ids: deleteCreationRule) =>
  http
    .delete(`vendors/open311/configurations/${configurationId}/servicetypes/settings`, {
      data: ids,
    })
    .then(response => response.data);

export const creationNewRule = (configurationId: number, creationRulesObj: inboundCreationRule) =>
  http
    .post(`vendors/open311/configurations/${configurationId}/servicetypes/settings`, creationRulesObj)
    .then(response => response.data);

export const updateCreationRule = (
  configurationId: number,
  serviceTypeId: number,
  creationRulesObj: inboundCreationRule,
) =>
  http
    .put(`vendors/open311/configurations/${configurationId}/servicetypes/${serviceTypeId}/settings`, creationRulesObj)
    .then(response => response.data);

export const creationNewOutboundRule = (configurationId: number, creationRulesObj: inboundCreationRule) =>
  http
    .post(`vendors/open311/configurations/${configurationId}/exceptions/settings`, creationRulesObj)
    .then(response => response.data);

export const updateOutboundCreationRule = (
  configurationId: number,
  exceptionId: number,
  creationRulesObj: inboundCreationRule,
) =>
  http
    .put(`vendors/open311/configurations/${configurationId}/exceptions/${exceptionId}/settings`, creationRulesObj)
    .then(response => response.data);

export const deleteOutboundCreationRules = (configurationId: number, ids: deleteCreationRule) =>
  http
    .delete(`vendors/open311/configurations/${configurationId}/exceptions/settings`, {
      data: ids,
    })
    .then(response => response.data);
