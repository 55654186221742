import { PureComponent } from 'react';

import { camelCase, filter, find, get, map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import Cookie from 'js-cookie';
import moment from 'moment';

import { AppState } from 'src/store';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { DateRangePicker, TypedField, MultiSelect } from '../../../core/components';
import { INSIGHTS_COOKIE_DATE_KEY, INSIGHTS_COOKIE_START_DATE_KEY } from '../../constants';
import { QUEUED_ID, IN_PROGRESS_ID, FAILED_ID, COMPLETED_ID } from 'src/insights/constants/videoRequests';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TODAY, DATE_RANGE_PICKER_TODAY_3_7_PAST } from '../../../core/constants/weekdays';
import translate from 'src/core/services/translate';

interface PropsWithoutReduxForm {
  videoRequestEndDate?: Date | string;
  videoRequestStartDate?: Date | string;
  videoStatuses?: TechnicalType[];
}

export interface VideoRequestsSearchFormValues {
  dateRange: { from?: Date | string; to?: Date | string };
  videoStatus: number[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<VideoRequestsSearchFormValues, PropsWithoutReduxForm>;

class VideoRequestsSearchForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, videoStatuses } = this.props;

    const dateRangeDisabledDays = {
      after: TODAY,
    };

    const formatText = (selectedOptions: number[], allOptionsSelected: boolean) => {
      if (allOptionsSelected && size(selectedOptions) !== 1) {
        return translate('routes.videoRequest.allVideoStatuses');
      }

      return size(selectedOptions) === 1
        ? get(find(videoStatuses, { id: Number(selectedOptions[0]) }), 'name')
        : translate('routes.videoRequest.xVideoStatusesSelected', { selected: size(selectedOptions) });
    };

    const getVideoStatusOptions = () =>
      map(filter(videoStatuses), videoStatus => ({
        label: translate(`insights.dailyReportTypes.status.${camelCase(videoStatus.technicalName)}`),
        value: videoStatus.id,
      }));

    const onDateChanged = (event: any, { from, to }: DateRangeOptionValue) => {
      const eventIsValid = moment(event, 'MM/DD/YYYY', true).isValid();
      const fromIsValid = moment(from, 'MM/DD/YYYY', true).isValid();
      const toIsValid = moment(to, 'MM/DD/YYYY', true).isValid();

      if (eventIsValid || fromIsValid) Cookie.set(INSIGHTS_COOKIE_START_DATE_KEY, from && fromIsValid ? from : event);
      if (eventIsValid || toIsValid) Cookie.set(INSIGHTS_COOKIE_DATE_KEY, to && toIsValid ? to : event);
    };

    return (
      <form noValidate onSubmit={handleSubmit}>
        <PanelSection padding="small no">
          <Grid>
            <GridColumn size="3/12" padding="no">
              <TypedField
                name="dateRange"
                component={DateRangePicker}
                props={{
                  options: DATE_RANGE_PICKER_TODAY_3_7_PAST,
                  disabledDays: [dateRangeDisabledDays],
                  isResetRangeVisible: true,
                  margin: 'no',
                  hasMarginLeft: 'smaller',
                }}
                onChange={onDateChanged}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <TypedField
                name="videoStatus"
                props={{
                  options: getVideoStatusOptions(),
                  placeholder: translate('routes.videoRequest.videoStatus'),
                  formatText: formatText,
                  normalizeValues: Number,
                }}
                component={MultiSelect}
              />
            </GridColumn>

            <GridColumn size="2/12" align="center">
              <Button color="primary" type="submit" size="small">
                {translate('common.search')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: PropsWithoutReduxForm) => ({
  initialValues: {
    dateRange: {
      from: ownProps.videoRequestStartDate,
      to: ownProps.videoRequestEndDate,
    },
    videoStatus: [QUEUED_ID, IN_PROGRESS_ID, FAILED_ID, COMPLETED_ID],
  },
  videoStatuses: state.routes.videoRequest.videoStatuses,
});

export default connect(mapStateToProps)(
  reduxForm<VideoRequestsSearchFormValues, PropsWithoutReduxForm>({
    form: 'videoRequestsSearchForm',
    enableReinitialize: true,
  })(VideoRequestsSearchForm),
);
