import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableCell,
  TableRow,
  Text,
  Label,
  TableActionButton,
  Message,
  DetailsListBodySection,
} from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { PreviewBills } from 'src/finance/interfaces/BillCreateSummary';
import { CUSTOMER_ACCOUNT_STATUSES, CUSTOMER_ACCOUNT_STATUS_LABEL_COLORS } from 'src/finance/constants';
import { currency } from 'src/utils/services/formatter';
import { ActionButtonTooltip } from 'src/core/components';
import UnbilledChargesTable from './UnbilledChargesTable';
import translate from 'src/core/services/translate';
import { size } from 'lodash-es';
import { useSelector } from 'src/core/hooks/useSelector';
import { ExpandableDetailsContainer } from '../../paymentManagement/styled';
import { loadUnbilledChargesByLocation } from 'src/finance/ducks/unbilledChargesByLocation';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { useLocation } from 'react-router';
import { BillCreateQueryParams } from '../BillCreatePage';
import { getQueryParams } from 'src/utils/services/queryParams';
import { ExpandCharges } from 'src/finance/components/styled/CreateBill';

type Props = PreviewBills & {
  cellWidths: {
    customerId: string;
    customerStatusId: string;
    recurringWOsCount: string;
    scheduledWOsCount: string;
    onCallWOsCount: string;
    totalWOsCount: string;
    options: string;
  };
};

const BillPreviewTableRow = ({ cellWidths, ...bill }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const unbilledCharges = useSelector(state => state.finance.unbilled.unbilledCharges);
  const isLoading = useSelector(state => state.finance.unbilled.isLoading);

  const vendorId = useSelector(currentVendorId);

  const { startDate, endDate, billMethodIds, paymentTermsIds } = useMemo(() => {
    const { startDate, endDate } = getQueryParams<BillCreateQueryParams>(search);

    const { billMethodIds = [], paymentTermsIds = [] } = getQueryParams<BillCreateQueryParams>(search, {
      mapTypeIdsToArray: true,
    });

    return {
      startDate,
      endDate,
      billMethodIds,
      paymentTermsIds,
    };
  }, [search]);

  useEffect(() => {
    if (isExpanded) {
      loadUnbilledChargesByLocation(
        vendorId,
        startDate,
        endDate,
        bill.locationId,
        billMethodIds,
        paymentTermsIds,
      )(dispatch);
    }
  }, [isExpanded, bill.locationId, dispatch, vendorId, startDate, endDate, billMethodIds, paymentTermsIds]);

  return (
    <>
      <TableRow id={`bill-${bill.customerId}`} height={TABLE_ROW_HEIGHT_LARGE}>
        <TableCell
          id={`bill-${bill.customerId}-customer-cell`}
          justifyContent="center"
          vertical
          width={cellWidths.customerId}
          padding="defaultCellVertical xSmall"
        >
          <Text block>
            {bill.customerName || '–'} {bill.customerId ? `• ${bill.customerId}` : ''}
          </Text>
          <Text block color="grayDarker">
            {bill.locationName || '–'} • {bill.locationId || '–'} {bill.locationCode ? `• ${bill.locationCode}` : ''}
            {bill.isOverDue && (
              <ActionButtonTooltip size="medium" icon="overdue" tooltip="pastDue" margin="no no no xxSmall" />
            )}
          </Text>
        </TableCell>
        <TableCell
          id={`bill-${bill.customerId}-customerStatus-cell`}
          width={cellWidths.customerStatusId}
          padding="defaultCellVertical xSmall"
        >
          <Label color={CUSTOMER_ACCOUNT_STATUS_LABEL_COLORS[bill.customerStatusId]}>
            {CUSTOMER_ACCOUNT_STATUSES[bill.customerStatusId].name || '–'}
          </Label>
        </TableCell>
        <TableCell
          id={`bill-${bill.customerId}-recurringWOs-cell`}
          justifyContent="center"
          vertical
          width={cellWidths.recurringWOsCount}
          padding="defaultCellVertical xSmall"
        >
          <Text block>{bill.recurringWOs.count || '–'}</Text>
          <Text block color="grayDarker">
            {currency(bill.recurringWOs.amount || 0)}
          </Text>
        </TableCell>
        <TableCell
          id={`bill-${bill.customerId}-scheduledWOs-cell`}
          justifyContent="center"
          vertical
          width={cellWidths.scheduledWOsCount}
          padding="defaultCellVertical xSmall"
        >
          <Text block>{bill.scheduledWOs.count || '–'}</Text>
          <Text block color="grayDarker">
            {currency(bill.scheduledWOs.amount || 0)}
          </Text>
        </TableCell>
        <TableCell
          id={`bill-${bill.customerId}-onCallWOs-cell`}
          justifyContent="center"
          vertical
          width={cellWidths.onCallWOsCount}
          padding="defaultCellVertical xSmall"
        >
          <Text block>{bill.onCallWOs.count || '–'}</Text>
          <Text block color="grayDarker">
            {currency(bill.onCallWOs.amount || 0)}
          </Text>
        </TableCell>
        <TableCell
          id={`bill-${bill.customerId}-totalWOs-cell`}
          justifyContent="center"
          vertical
          width={cellWidths.totalWOsCount}
          padding="defaultCellVertical xSmall"
        >
          <Text block>{bill.totalWOs.count || '–'}</Text>
          <Text block color="grayDarker">
            {currency(bill.totalWOs.amount || 0)}
          </Text>
        </TableCell>
        <TableCell
          id={`bill-${bill.customerId}-options-cell`}
          justifyContent="center"
          vertical
          width={cellWidths.options}
          padding="defaultCellVertical xSmall"
        >
          <ExpandCharges>
            <TableActionButton
              id={isExpanded ? `hide-bill-${bill.customerId}-button` : `show-bill-${bill.customerId}-button`}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <ActionButtonTooltip
                icon={isExpanded ? 'arrowUpFill' : 'arrowDownFill'}
                tooltip={isExpanded ? 'hideUnassignedCharges' : 'showUnassignedCharges'}
              />
            </TableActionButton>
          </ExpandCharges>
        </TableCell>
      </TableRow>

      {isExpanded && (
        <ExpandableDetailsContainer isLoading={isLoading}>
          <DetailsListBodySection width="100%">
            {!!size(unbilledCharges) ? (
                <UnbilledChargesTable unbilledCharges={unbilledCharges} />
            ) : (
              <Message padding="no no small no">{translate('finance.noUnbilledCharges')}</Message>
            )}
          </DetailsListBodySection>
        </ExpandableDetailsContainer>
      )}
    </>
  );
};

export default BillPreviewTableRow;
