import { map } from 'lodash-es';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

const transformInvoiceStatuses = (invoiceStatuses: TechnicalType[]) =>
  map(invoiceStatuses, ({ id, technicalName }) => ({
    id: id,
    name: translate(createTranslationKey(technicalName, 'finance.invoiceStatuses')),
    technicalName: technicalName,
  }));

export default transformInvoiceStatuses;
