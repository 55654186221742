import React, { ChangeEvent } from 'react';
// @ts-ignore
import TimeField from 'react-simple-timefield';
import {
  FormGroup,
  FormLabel,
  FormError,
  Input as FormInput,
  FormGroupClearContainer,
  FormGroupClear,
  TimeFieldWrapper,
} from './styled';
import { inputAutofillAnimationStart, inputAutofillAnimationCancel } from './styled/Input';
import { WrappedFieldProps } from 'redux-form';
import FormMeta from './styled/FormMeta';

interface Props extends WrappedFieldProps {
  autoComplete?: string;
  disabled?: boolean;
  disabledDarker?: boolean;
  errorOnSingleLine?: boolean;
  errorTopPosition?: string;
  fieldNotTouched?: boolean;
  isClearable?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  isTimeField?: boolean;
  label?: string;
  margin?: string;
  maxLength?: number;
  metaInfo?: string;
  name?: string;
  onTimeChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  opacity?: boolean;
  padding?: string;
  placeholder?: string;
  raisedLabel?: boolean;
  readOnly?: boolean;
  shouldShowError?: boolean;
  showErrorBeforeSubmit?: boolean;
  showSeconds?: boolean;
  time?: string;
  type?: string;
  width?: string;
  withEllipsesText?: boolean;
}

const Input: React.FunctionComponent<Props> = ({
  autoComplete,
  disabled,
  disabledDarker,
  errorOnSingleLine,
  errorTopPosition,
  fieldNotTouched,
  input,
  isClearable,
  isHidden,
  isLoading,
  isTimeField,
  label,
  margin,
  maxLength,
  meta: { asyncValidating, submitFailed, error },
  metaInfo,
  name,
  onTimeChange,
  opacity,
  padding,
  placeholder,
  raisedLabel,
  readOnly,
  shouldShowError,
  showErrorBeforeSubmit,
  showSeconds = true,
  time,
  type,
  width,
  ...props
}) => {
  const [isAutoFilled, setIsAutoFilled] = React.useState(false);
  const clearInput = () => {
    input.onChange(null);
  };
  return (
    <FormGroup
      hasValue={input.value || input.value === 0 || isTimeField || isAutoFilled}
      isLoading={isLoading || asyncValidating}
      margin={margin}
      padding={padding}
      width={width}
      raisedLabel={raisedLabel}
      disabled={disabled}
      isHidden={isHidden}
    >
      {!!label && <FormLabel>{label}</FormLabel>}

      {isTimeField ? (
        <TimeFieldWrapper readOnly={readOnly}>
          <TimeField
            value={time || input.value}
            onChange={onTimeChange || input.onChange}
            colon=":"
            showSeconds={showSeconds}  
            //@ts-ignore  
            name={name || 'elapsedTime'}
            readOnly={readOnly}
          />
        </TimeFieldWrapper>
      ) : (
        <>
          {isClearable && (Array.isArray(input.value) ? !!input.value.length : !!input.value) && (
            <FormGroupClearContainer>
              <FormGroupClear disabled={disabled} onClick={clearInput} />
            </FormGroupClearContainer>
          )}
          <FormInput
            {...input}
            {...props}
            readOnly={readOnly}
            disabled={disabled || disabledDarker}
            disabledDarker={disabledDarker}
            opacity={opacity}
            placeholder={placeholder}
            maxLength={maxLength}
            type={type}
            onAnimationStart={e => {
              switch (e.animationName) {
                default:
                case (inputAutofillAnimationStart as any).name:
                  setIsAutoFilled(true);
                  break;

                case (inputAutofillAnimationCancel as any).name:
                  setIsAutoFilled(false);
                  break;
              }
            }}
            onFocus={() => setIsAutoFilled(false)}
            shouldShowError={shouldShowError}
          />
        </>
      )}

      {!!metaInfo && <FormMeta singleLine={errorOnSingleLine}>{metaInfo}</FormMeta>}

      {(submitFailed || showErrorBeforeSubmit) && error && !fieldNotTouched && (
        <FormError errorOnSingleLine={errorOnSingleLine} errorTopPosition={errorTopPosition}>
          {error}
        </FormError>
      )}
    </FormGroup>
  );
};

export default Input;
