import { ChangeEvent, FC } from 'react';

import { filter, map, size } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { AppState } from 'src/store';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { MultiSelect, TypedField } from '../../core/components';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import { NONE_ID } from '../constants/dispatchBoard';
import { Supervisor } from '../interfaces/Supervisors';
import translate from '../../core/services/translate';
import { getAllWithOptionNone } from '../services/dispatchBoardGetAllWithOptionNone';
import { useSelector } from 'src/core/hooks/useSelector';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('routes.allSupervisors')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('routes.noSupervisorsPlaceholder')
    : translate('routes.xSupervisorsSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludeSupervisorsIds?: number[];
  withOptionNone?: boolean;
}

const SupervisorsMultiSelect: FC<Props> = ({
  input,
  label,
  multiSelectProps,
  placeholder,
  withLabel,
  withPlaceholder,
  excludeSupervisorsIds,
  withOptionNone,
}) => {
  const supervisors: Supervisor[] = useSelector((state: AppState) => state.routes.supervisors.supervisors);

  const onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    input.onChange(value);
  };

  const supervisorsToShow = withOptionNone ? getAllWithOptionNone(supervisors || []) : supervisors;

  const supervisorsOptions =
    excludeSupervisorsIds && excludeSupervisorsIds.length
      ? filter(supervisorsToShow, supervisor => !excludeSupervisorsIds.includes(supervisor.id)).map(supervisor => ({
          label: `${supervisor.firstName} ${supervisor.lastName}`,
          value: supervisor.id,
        }))
      : map(supervisorsToShow, supervisor => ({
          label: `${supervisor.firstName} ${supervisor.lastName}`,
          value: supervisor.id,
        }));

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={onChange}
      props={{
        label: label || (withLabel ? translate('routes.supervisor') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('routes.supervisor') : undefined),
        options: supervisorsOptions,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
}

export default SupervisorsMultiSelect;
