import React, { Fragment } from 'react';

import { TableCell, TableRow, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { coordinate, date, time } from '../../../utils/services/formatter';

interface Props {
  deviceESN?: string;
  durationSinceLastCommunication?: string;
  lastRecordedOn?: string;
  latitude?: number;
  longitude?: number;
  regplate?: string;
  vehicleType?: string;
  vendor?: string;
}

const na = translate('common.notAvailable');

const DeviceStatusesPageTableRow: React.FC<Props> = ({
  vendor,
  regplate,
  vehicleType,
  deviceESN,
  lastRecordedOn,
  durationSinceLastCommunication,
  latitude,
  longitude,
}) => (
  <TableRow>
    <TableCell width="16%">{vendor}</TableCell>
    <TableCell width="10%">{regplate}</TableCell>
    <TableCell width="12%">{vehicleType}</TableCell>
    <TableCell width="10%">{deviceESN}</TableCell>
    <TableCell width="12%" vertical>
      {lastRecordedOn ? (
        <Fragment>
          <Text block weight="light" margin="no no xxSmall" singleLine>
            {date(lastRecordedOn)}
          </Text>
          <Text weight="light" margin="no no xxSmall" singleLine>
            {time(lastRecordedOn)}
          </Text>
        </Fragment>
      ) : (
        na
      )}
    </TableCell>
    <TableCell width="18%">{durationSinceLastCommunication}</TableCell>
    <TableCell width="12%">{latitude ? coordinate(latitude) : na}</TableCell>
    <TableCell width="10%">{longitude ? coordinate(longitude) : na}</TableCell>
  </TableRow>
);

export default DeviceStatusesPageTableRow;
