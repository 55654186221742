import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { RouteTemplateBuilderService } from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderService';

const useSelectedDraftStops = (): RouteTemplateBuilderService[] | undefined => {
  const selectedDraft = useSelector((state: AppState) => state.routes.routeTemplateBuilder.selectedDraft);

  return React.useMemo(
    () =>
      selectedDraft?.serviceContracts.map(service => ({
        ...service,
        materialTypeId: selectedDraft.wasteMaterialTypeId,
        daysOfService: [
          {
            id: selectedDraft.dayOfService,
            draftRouteTemplateId: selectedDraft.id,
            routeTemplateId: selectedDraft.routeTemplateId,
            routeName: selectedDraft.name,
          },
        ],
        serviceTypeId: selectedDraft.serviceTypeId,
        // this here is irrevelant because we disable filters when
        // displaying using selected draft stops
        equipmentTypeId: -1,
        equipmentSizeId: -1,
      })),
    [selectedDraft],
  );
};

export default useSelectedDraftStops;
