import { map } from 'lodash-es';

import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { TechnicalType } from '../interfaces/TechnicalType';

const transformLoadJobPriorityTypes = (jobPriorityTypes: TechnicalType[]) =>
  map(jobPriorityTypes, jobPriorityType => ({
    ...jobPriorityType,
    name: translate(createTranslationKey(jobPriorityType.technicalName, 'common.jobPriorityTypes')),
  }));

export default transformLoadJobPriorityTypes;
