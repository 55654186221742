import React, { PureComponent, Fragment } from 'react';
import { size, map, filter, every, omit, pickBy, keys, reduce, indexOf, isEqual } from 'lodash-es';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';
import {
  FormGroup,
  FormGroupClearContainer,
  FormGroupClear,
  FormLabel,
  FormError,
  Input as FormInput,
  MultiSelectOverlay,
  MultiSelectOptions,
  Button,
  ButtonSet,
  Checkbox as CheckboxContainer,
  CheckboxCheck,
  CheckboxInput,
  CheckboxText,
} from './styled';
import { UnconnectedInput } from '.';
import translate from '../services/translate';
import { DropdownOption } from './Dropdown';
import { Loader } from '../../customers/components/styled/CustomerLocations';
import { AsyncOptions } from './styled/MultiSelect';

export interface MultiSelectOption extends DropdownOption {
  isDisabled?: boolean;
  isOrderedMultiselect?: boolean;
  unselectable?: boolean;
  canOnlyUnselect?: boolean;
  isUncheckedByDefault?: boolean;
  renderCustomOption?: (loadAsyncOptions: () => void) => JSX.Element;
}

export enum AsyncOptionsPositions {
  Top = 'top',
  Bottom = 'bottom',
}

export enum OptionDropdownPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export interface MultiSelectProps {
  asyncOptionsPosition?: AsyncOptionsPositions;
  cachedAsyncOptions?: DropdownOption[];
  canCheckAll?: boolean;
  checkBoxClickHandler?: (e: any) => void;
  checkedOptionsFirst?: boolean;
  defaultToAll?: boolean;
  disabled?: boolean;
  fitContentWidth?: boolean;
  formatText?: (value: any[], checkAll: boolean, options: MultiSelectOption[]) => string;
  id?: string;
  input: WrappedFieldInputProps;
  inputWidth?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  keepOverlayOpen?: any;
  label?: string;
  labelAll?: string;
  loadAsyncOptions?: () => Promise<MultiSelectOption[]>;
  margin?: string;
  maxWidth?: string;
  menuPosition?: string;
  meta: WrappedFieldMetaProps;
  minWidth?: string;
  noOptionsMessage?: string;
  normalizeValues?: (values: any) => any;
  noWrap?: boolean;
  onChange?: (value: any) => void;
  optionDropdownPosition?: OptionDropdownPosition;
  options: MultiSelectOption[];
  placeholder?: string;
  reinitializeKey?: any;
}

interface State {
  checkAll: boolean;
  selectedValues: { [key: string]: boolean };
  selectedValuesCount: number;
  text: string;
  searchTerm: string;
  filteredOptions: MultiSelectOption[];
  isOverlayOpen: boolean;
  optionsFromPromise?: MultiSelectOption[];
  isLoadingOptions?: boolean;
  orderedOptions: MultiSelectOption[];
}

class MultiSelect extends PureComponent<MultiSelectProps, State> {
  constructor(props: MultiSelectProps) {
    super(props);
    this.state = this.getInitialState(props, this.state);
  }

  static defaultProps = {
    canCheckAll: true,
    defaultToAll: true,
    noOptionsMessage: translate('common.noResults'),
  };

  hasLoadedOptions = false;
  isLoadingOptions = false;

  getAllOptions = (): MultiSelectOption[] => {
    let additionalOptions: MultiSelectOption[] = [];
    if (this.state && this.state.optionsFromPromise) {
      additionalOptions = this.state.optionsFromPromise;
    }
    if (this.props.cachedAsyncOptions) {
      additionalOptions = this.props.cachedAsyncOptions;
    }
    return [...this.props.options, ...additionalOptions];
  };

  getInitialState = (props: MultiSelectProps, state: State) => {
    const {
      defaultToAll,
      input: { value },
      options,
      keepOverlayOpen,
    } = props;

    const selectedValues = reduce(
      this.getAllOptions().filter(option => !option.isDisabled && !option.unselectable),
      (selectedValues, option) => {
        const newVal: any = { ...selectedValues };
        const isOptionSelected =
          indexOf(value, option.value && option.value.toString()) > -1 ||
          indexOf(value, parseInt(option.value, 10)) > -1;
        newVal[option.value] = isOptionSelected || (defaultToAll && !option.isUncheckedByDefault && !size(value));
        return newVal;
      },
      {},
    ) as { [key: string]: boolean };

    const orderedOptions = [...options].sort((a, b) => {
      if (selectedValues[a.value] && selectedValues[b.value]) return 0;
      if (selectedValues[a.value] && !selectedValues[b.value]) return -1;
      if (!selectedValues[a.value] && selectedValues[b.value]) return 1;
      return 1;
    });

    const selectedValuesCount = size(pickBy(selectedValues));
    const checkAll = every(selectedValues);

    return {
      checkAll,
      selectedValues,
      selectedValuesCount,
      text: this.formatText(value, checkAll),
      searchTerm: state ? state.searchTerm : '',
      filteredOptions:
        state && state.searchTerm
          ? filter(
              this.getAllOptions().filter(o => !o.unselectable),
              option =>
                (typeof option.label === 'string' || React.isValidElement(option.label)) &&
                ((typeof option.label === 'string' &&
                  option.label.toLowerCase().indexOf(state.searchTerm.toLowerCase()) > -1) ||
                  React.isValidElement(option.label)),
            )
          : options,
      orderedOptions,
      isOverlayOpen: keepOverlayOpen !== null && keepOverlayOpen ? keepOverlayOpen : false,
    };
  };

  componentDidUpdate(prevProps: MultiSelectProps) {
    if (!this.state.isOverlayOpen && this.state.searchTerm.length) this.setState({ searchTerm: '' });

    if (
      (prevProps.input.value && !this.props.input.value) ||
      !isEqual(prevProps.input.value, this.props.input.value) ||
      !isEqual(prevProps.options, this.props.options) ||
      prevProps.reinitializeKey !== this.props.reinitializeKey
    ) {
      this.setState({ ...this.getInitialState(this.props, this.state), isOverlayOpen: this.state.isOverlayOpen });
    }
  }

  onApply = () => {
    const {
      input: { onChange },
      keepOverlayOpen,
      normalizeValues,
      options,
    } = this.props;
    const selectedValues = keys(pickBy(this.state.selectedValues));
    const normalizedValues = normalizeValues ? map(selectedValues, normalizeValues) : selectedValues;
    const value = normalizedValues.length ? normalizedValues : null;

    this.setState(prevState => ({
      text: this.formatText(value, prevState.checkAll),
      searchTerm: '',
      filteredOptions: options,
      isOverlayOpen: keepOverlayOpen || false,
    }));

    return onChange(value);
  };

  onCheckAllChange = () => {
    this.setState(prevState => {
      const { checkAll } = prevState;
      const { options } = this.props;

      const selectedValues = reduce(
        options.filter(filter => !filter.isDisabled),
        (selectedValues, option) => {
          const newValue: any = { ...selectedValues };
          newValue[option.value] = option.canOnlyUnselect ? false : !checkAll;
          return newValue;
        },
        {},
      );

      const selectedValuesCount = !checkAll ? options.length : 0;
      return { selectedValues, selectedValuesCount, checkAll: !checkAll };
    });
  };

  onSearchTermChange = (value: string) => {
    this.setState({
      searchTerm: value || this.state.searchTerm,
      filteredOptions: filter(
        this.getAllOptions().filter(o => !o.unselectable),
        option =>
          (typeof option.label === 'string' || React.isValidElement(option.label)) &&
          ((typeof option.label === 'string' && option.label.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
            React.isValidElement(option.label)),
      ),
    });
  };

  onOptionChange = (value: string) => {
    // search the option in the options array
    const option = this.getAllOptions().find(o => o.value === value);

    this.setState(prevState => {
      let selectedValues = { [value]: option?.canOnlyUnselect ? false : !prevState.selectedValues[value] };
      selectedValues = { ...prevState.selectedValues, ...selectedValues };
      const selectedValuesCount = size(pickBy(selectedValues));
      const checkAll = every(omit(prevState.selectedValues, value)) && !prevState.selectedValues[value];

      return {
        selectedValues,
        selectedValuesCount,
        checkAll,
      };
    });
  };

  onOverlayMouseDown = (event: React.SyntheticEvent) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  clearInput = () => {
    this.props.input.onChange(null);
  };

  formatText = (value: any[] | null, checkAll: boolean) => {
    const length = size(value);
    if (!length) return '';

    const { formatText, labelAll } = this.props;

    if (formatText)
      return formatText(
        value as any[],
        checkAll,
        this.getAllOptions().filter(o => !o.unselectable),
      );

    return checkAll
      ? labelAll
        ? labelAll
        : translate('common.all')
      : translate('common.xSelected', { selected: length });
  };

  loadAsyncOptions = () => {
    const { loadAsyncOptions } = this.props;
    if (loadAsyncOptions) {
      // we need this because open overlay is called both on focus and on click, which will result in a double request when clicked
      this.isLoadingOptions = true;
      this.setState({ isLoadingOptions: true });
      loadAsyncOptions()
        .then(optionsFromPromise => {
          this.hasLoadedOptions = true;
          this.setState({ optionsFromPromise, isLoadingOptions: false });
        })
        .catch(() => this.setState({ isLoadingOptions: false }));
    }
  };

  openOverlay = () => {
    const { loadAsyncOptions, cachedAsyncOptions } = this.props;
    const shouldLoadOptions =
      !!loadAsyncOptions && !cachedAsyncOptions && !this.hasLoadedOptions && !this.isLoadingOptions;
    if (shouldLoadOptions) {
      this.loadAsyncOptions();
    }
    this.setState({ ...this.getInitialState(this.props, this.state), isOverlayOpen: true });
    setTimeout(() => {
      document.addEventListener('mousedown', this.closeOverlay);
    });
  };

  closeOverlay = () => {
    this.setState(this.getInitialState(this.props, this.state));

    setTimeout(() => {
      document.removeEventListener('mousedown', this.closeOverlay);
    });
  };

  getOption = (option: MultiSelectOption, index: number) => {
    return (
      <CheckboxContainer
        block
        key={index}
        isDisabled={option.isDisabled}
        noWrap={this.props.noWrap}
        size="small"
        margin="no no xSmall no"
        isOrderedMultiselect={option.isOrderedMultiselect}
      >
        {!option.unselectable && (
          <>
            <CheckboxInput
              onClick={this.props.checkBoxClickHandler}
              type="checkbox"
              name={option.value}
              disabled={option.isDisabled}
              checked={option.isDisabled ? !option.isDisabled : !!this.state.selectedValues[option.value]}
              onChange={() => this.onOptionChange(option.value)}
            />
            <CheckboxCheck />
          </>
        )}

        <CheckboxText>
          {option.renderCustomOption ? option.renderCustomOption(this.loadAsyncOptions) : option.label}
        </CheckboxText>
      </CheckboxContainer>
    );
  };

  getAsyncOptions = () => {
    const { loadAsyncOptions, cachedAsyncOptions, asyncOptionsPosition } = this.props;
    const { isLoadingOptions, optionsFromPromise } = this.state;
    return (
      (loadAsyncOptions || cachedAsyncOptions) && (
        <AsyncOptions position={asyncOptionsPosition || 'top'}>
          {isLoadingOptions ? <Loader /> : (optionsFromPromise || cachedAsyncOptions || []).map(this.getOption)}
        </AsyncOptions>
      )
    );
  };

  render() {
    const {
      asyncOptionsPosition,
      canCheckAll,
      disabled,
      input,
      isClearable,
      isLoading,
      isSearchable,
      label,
      margin,
      meta: { submitFailed, error },
      fitContentWidth,
      minWidth,
      maxWidth,
      noOptionsMessage,
      optionDropdownPosition,
      options,
      placeholder,
      checkedOptionsFirst,
      menuPosition,
      inputWidth,
      ...props
    } = this.props;

    const { selectedValuesCount, filteredOptions, text, checkAll, isOverlayOpen, searchTerm, orderedOptions } =
      this.state;

    return (
      <FormGroup
        hasValue={!!size(input.value)}
        isLoading={isLoading}
        margin={margin}
        width={inputWidth}
        disabled={disabled}
      >
        {!!label && <FormLabel>{label}</FormLabel>}

        {isClearable && (Array.isArray(input.value) ? !!input.value.length : !!input.value) && (
          <FormGroupClearContainer>
            <FormGroupClear disabled={disabled} onClick={this.clearInput} />
          </FormGroupClearContainer>
        )}

        <FormInput
          disabled={disabled}
          name={input.name}
          onClick={this.openOverlay}
          onFocus={this.openOverlay}
          placeholder={placeholder}
          readOnly
          value={text}
          withSelectStyle
          {...props}
        />

        {isOverlayOpen && (
          <MultiSelectOverlay
            fitContentWidth={fitContentWidth}
            position={optionDropdownPosition}
            menuPosition={menuPosition}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onMouseDown={this.onOverlayMouseDown}
          >
            {isSearchable && !!size(options) && (
              <UnconnectedInput onChange={this.onSearchTermChange} placeholder={translate('common.search')} />
            )}

            {!!size(filteredOptions) && (
              <Fragment>
                <MultiSelectOptions>
                  {canCheckAll && (
                    <CheckboxContainer block size="small" margin="no no xSmall no">
                      <CheckboxInput type="checkbox" name="all" checked={checkAll} onChange={this.onCheckAllChange} />
                      <CheckboxCheck />
                      <CheckboxText>{translate('common.all')}</CheckboxText>
                    </CheckboxContainer>
                  )}
                  {!searchTerm &&
                    asyncOptionsPosition &&
                    asyncOptionsPosition === AsyncOptionsPositions.Top &&
                    this.getAsyncOptions()}
                  {map(searchTerm ? filteredOptions : checkedOptionsFirst ? orderedOptions : options, this.getOption)}
                  {!searchTerm &&
                    asyncOptionsPosition &&
                    asyncOptionsPosition === AsyncOptionsPositions.Bottom &&
                    this.getAsyncOptions()}
                </MultiSelectOptions>

                <ButtonSet margin="small no no">
                  <Button
                    fluid
                    color="primary"
                    size="small"
                    type="button"
                    disabled={!selectedValuesCount}
                    onClick={this.onApply}
                  >
                    {translate('common.apply')}
                  </Button>
                </ButtonSet>
              </Fragment>
            )}

            {!size(this.getAllOptions()) && noOptionsMessage}
          </MultiSelectOverlay>
        )}

        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

export default MultiSelect;
