import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadAlertTypes as doLoadAlertTypes } from '../services/alertTypes';
import { TechnicalType } from '../interfaces/TechnicalType';

// Actions
const START_LOAD = 'common/alertTypes/START_LOAD';
const COMPLETE_LOAD = 'common/alertTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/alertTypes/FAIL_LOAD';
const RESET = 'common/alertTypes/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  alertTypes: TechnicalType[];
} = {
  isLoading: false,
  alertTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          alertTypes: action.alertTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          alertTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (alertTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  alertTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadAlertTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadAlertTypesPromise = doLoadAlertTypes();
  loadAlertTypesPromise
    .then((alertTypes: TechnicalType[]) => dispatch(completeLoad(alertTypes)))
    .catch(() => dispatch(failLoad()));
  return loadAlertTypesPromise;
};

export const resetAlertTypes = () => ({
  type: RESET,
});
