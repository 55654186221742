import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
// import { size } from 'lodash-es';

// import { Table } from '../../../core/components';
import { PanelSection, Text, Grid, GridColumn } from '../../../core/components/styled';
// import { updateAcceptSelectedPaymentServices } from '../../ducks';
// import RecurringServicesFormInitialValueSelector from '../../services/recurringServicesInitialValueSelector';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
// import { RecurringServicesFormTableRow } from './';

interface RouteParams {
  token: string;
}

interface PropsWithoutReduxForm extends RouteComponentProps<RouteParams> {
  workOrdersInfo: any;
  pricingInfo: any;
  paymentInfo: any;
  reset: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class PricingInfoProcessedFormFooter extends PureComponent<Props> {
  render() {
    const { pricingInfo, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection centered withBorder padding="small">
          <Grid padding="no no no no" multiLine>
            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.acceptedWoTotalAmount')}: ${pricingInfo.acceptedWOTotalAmount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.acceptedWOTotalAmount}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.acceptedWoCount')}: {pricingInfo.acceptedWOCount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.acceptedWOCount}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.allIssueWoCount')}: {pricingInfo.atIssueWOCount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.atIssueWOCount}
                </Text> */}
              </PanelSection>
            </GridColumn>

            <GridColumn size="2/12" align="left">
              <PanelSection>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('payment.pendingApprovalWoCount')}: {pricingInfo.pendingApprovelWOCount}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.pendingApprovelWOCount}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="4/12" align="right">
              <PanelSection>
                <Text size="small" block weight="medium" margin="no no xxSmall">
                  {translate('payment.rubiconInvoiceNo')}:{pricingInfo.rubiconInvoiceNo}
                </Text>
                {/* <Text block weight="medium" margin="no xSmall xxSmall">
                  {pricingInfo.rubiconInvoiceNo}
                </Text> */}
              </PanelSection>
            </GridColumn>
            <GridColumn size="12/12" align="right" />
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  workOrdersInfo: state.payments.processedServices.workOrdersInfo,
  pricingInfo: state.payments.processedServices.pricingInfo,
  paymentInfo: state.payments.processedServices.paymentInfo,
});

const mapDispatchToProps = {
  reset,
  //   updateAcceptSelectedPaymentServices,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'pricingInfoFormFooter',
      enableReinitialize: true,
    })(PricingInfoProcessedFormFooter),
  ),
);
