import React, { Component } from 'react';

import { Radio as RadioContainer, RadioInput, RadioCheck, RadioText } from './styled';

interface Props {
  block?: boolean;
  disabled?: boolean;
  input: any;
  label: string;
  margin?: string;
  size?: string;
  value?: number | string;
}

class Radio extends Component<Props> {
  render() {
    const { input, label, size, block, margin, disabled } = this.props;

    return (
      <RadioContainer isDisabled={disabled} block={block} size={size} margin={margin}>
        <RadioInput {...input} type="radio" disabled={disabled} />
        <RadioCheck />
        <RadioText>{label}</RadioText>
      </RadioContainer>
    );
  }
}

export default Radio;
