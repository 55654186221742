import { PureComponent, Fragment } from 'react';
import moment from 'moment';

import { dateFormat, timeFormat, timeFormatWithoutMeridiem } from 'src/utils/services/validator';
import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { duration } from 'src/utils/services/formatter';

interface Props {
  driverName: string;
  endTime: Date | string;
  facilityName: string;
  materialPickupsTableCellWidths: string[];
  startTime: Date | string;
  vehicleName: string;
}

class MaterialPickupsTableRow extends PureComponent<Props> {
  render() {
    const { driverName, endTime, facilityName, materialPickupsTableCellWidths, startTime, vehicleName } = this.props;

    const startMoment = moment(startTime);
    const endMoment = moment(endTime);

    return (
      <Fragment>
        <TableRow>
          <TableCell width={materialPickupsTableCellWidths[0]}>
            <Text>{facilityName}</Text>
          </TableCell>
          <TableCell width={materialPickupsTableCellWidths[1]}>
            <Text>
              {vehicleName && driverName ? (
                <Text>
                  <Text block>{vehicleName}</Text>
                  <Text>{driverName}</Text>
                </Text>
              ) : (
                '-'
              )}
            </Text>
          </TableCell>
          <TableCell width={materialPickupsTableCellWidths[2]}>
            <Text>
              <Text block>{startMoment.format(dateFormat)}</Text>
              <Text size="small">{startMoment.format(timeFormat)}</Text>
            </Text>
          </TableCell>
          <TableCell width={materialPickupsTableCellWidths[3]}>
            <Text>
              <Text block>{endMoment.format(dateFormat)}</Text>
              <Text size="small">{endMoment.format(timeFormat)}</Text>
            </Text>
          </TableCell>
          <TableCell width={materialPickupsTableCellWidths[4]}>
            <Text>{duration(endMoment.diff(startMoment, 's'), 'seconds', timeFormatWithoutMeridiem)}</Text>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

export default MaterialPickupsTableRow;
