import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const ALL = 'ALL';
export const SCHEDULED = 'SCHEDULED';
export const NON_SCHEDULED = 'ONCALL';

export const WORKORDER_TYPE = mapKeys(
  [
    { technicalName: ALL, name: translate('common.all') },
    { technicalName: SCHEDULED, name: translate('dashboard.scheduled') },
    { technicalName: NON_SCHEDULED, name: translate('autoDispatch.nonScheduled') },
  ],
  'technicalName',
);
