import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { mapper } from '../../../utils/styles';

type Props = Partial<{
  color?: string;
  left: number;
  percentage: string;
  top: number;
  type: string;
}>;

export const CustomLegend = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 4px;
  justify-content: center;
`;

export const CustomTooltip = styled.div<Props>`
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  position: fixed;
  display: none;
  background-color: ${props => props.theme.brandPrimary};
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  text-align: left;
  font-size: 13px;
  z-index: 9;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${props => props.theme.brandPrimary};
    position: absolute;
    left: -10px;
    top: 8px;
  }
`;

interface ChartWrapperProps {
  percentage?: string;
  width?: string;
  height?: string;
  inline?: boolean;
}

export const ChartWrapper = styled.div<ChartWrapperProps>`
  width: ${props => props.width || '180px'};
  height: ${props => props.height || '180px'};
  margin: 0 auto;

  ${props =>
    props.inline &&
    css`
      display: inline-block;
    `}

  &:hover {
    ${CustomTooltip} {
      display: block;

      ${props =>
        props.percentage === '0.00' &&
        css`
          display: none;
        `};
    }
  }
`;

export const CustomLegendItem = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 5px;

  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 2px;
    border-radius: 50%;

    ${props =>
      props.type &&
      css`
        background-color: ${mapper(
          props.type,
          {
            FirstGPSMovement: transparentize(0.5, '#fff'),
            Scheduled: '#9fc5f9',
            InProgress: '#fcfe01',
            Completed: '#1e9f0e',
            Cancelled: '#fb9901',
            IssueReported: '#d02b27',
            OnHold: '#bd10e0',
            LastGPSMovement: transparentize(0.5, '#fff'),
          },
          'small',
        )};
      `};

    ${props =>
      props.color &&
      css`
        background-color: ${props.color};
      `}
  }
`;
