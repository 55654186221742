import React from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadXDeviceStatuses, updateXDeviceData } from 'src/settings/ducks/deviceStatuses';
import { Modal } from '../../../core/components';
import { useDispatch, useSelector } from 'react-redux';
import EditDeviceDetailsForm from '../forms/EditDeviceDetailsForm';
import translate from '../../../core/services/translate';

interface Props {
  closeModal(): void;
  deviceId?: string;
  deviceOwnerId?: number;
  isLoading: boolean;
  phoneName: string;
  serialNumber?: string;
  vendorId?: number;
}

export const EditDeviceDetailsModal: React.FC<Props> = ({
  closeModal,
  deviceId,
  deviceOwnerId,
  isLoading,
  phoneName,
  serialNumber,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const updateDevice = async (formValues: any) => {
    updateXDeviceData(
      formValues.deviceOwnerTypeId,
      formValues.phoneName,
      formValues.serialNumber,
      deviceId,
    )(dispatch)
      .then(() => {
        loadXDeviceStatuses(vendorId)(dispatch);
        createSuccessNotification(translate('settings.alertMessages.deviceDetailsUpdated'));
        closeModal();
      })
      .catch(error => {
        const { code } = error.response.data;

        switch (code) {
          case 'DevicePhoneNameAlreadyExists':
            createErrorNotification(translate('settings.alertMessages.devicePhoneNameAlreadyExists'));
            break;

          default:
            createErrorNotification(translate('settings.alertMessages.deviceDetailsUpdatedError'));
            break;
        }
      });
  };

  return (
    <Modal
      isLoading={isLoading}
      onClose={closeModal}
      padding="medium"
      title={translate('settings.editDevice')}
      verticalSize="medium"
      size="small"
    >
      <EditDeviceDetailsForm
        deviceId={deviceId}
        serialNumber={serialNumber}
        deviceOwnerId={deviceOwnerId}
        phoneName={phoneName}
        initialValues={{ deviceOwnerTypeId: deviceOwnerId, phoneName: phoneName, serialNumber: serialNumber }}
        onSubmit={updateDevice}
        closeModal={closeModal}
      ></EditDeviceDetailsForm>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoading: state.settings.deviceStatuses.isLoading,
});

export default connect(mapStateToProps)(EditDeviceDetailsModal);
