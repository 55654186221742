import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import { Container, ButtonSet, Button } from '../../../core/components/styled';
import { Dropdown, Input } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import { DECLINE_REASONS } from '../../constants';
import serviceChangeRequestFormInitialValueSelector from '../../services/serviceChangeRequestFormInitialValuesSelector';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector('declineOpportunity');

interface ComponentProps {
  caseId: number;
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  respondedNo?: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  response?: any;
  respondedNo: {
    reason?: string;
  };
}

class DeclineServiceChangeRequestForm extends PureComponent<Props> {
  readonly state: State = {
    response: undefined,
    respondedNo: { reason: undefined },
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: any): State {
    if (nextProps.respondedNo && nextProps.respondedNo.reason) {
      return {
        response: 'No',
        respondedNo: { reason: nextProps.respondedNo.reason },
      };
    }

    return prevState;
  }

  onChangeReason = (event: any, value: string) => {
    this.setState({ respondedNo: { reason: value } });
  };

  render() {
    const { caseId, handleSubmit, onCancel, pristine } = this.props;

    const reasonsSelectOption = map(DECLINE_REASONS, reasonOption => ({
      label: reasonOption.name,
      value: reasonOption.id,
    }));

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <Field
            name={`changeRequests.settings${caseId}.respondedNo.reason`}
            component={Dropdown as any}
            options={reasonsSelectOption}
            label={translate('opportunity.selectReasonType')}
            validate={[isRequired]}
            margin="small"
            onChange={this.onChangeReason}
          />
        </Container>
        <Container>
          {this.state.respondedNo.reason === 'Other' && (
            <Field
              name={`changeRequests.settings${caseId}.respondedNo.notes`}
              component={Input}
              type="text"
              label={translate('opportunity.opportunities.reason')}
              size="small"
              validate={[isRequired]}
              margin="small small large"
            />
          )}
        </Container>
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: serviceChangeRequestFormInitialValueSelector(state.opportunity.serviceChangeRequest.serviceChangeRequests),
  respondedNo: formSelector(state, `changeRequests.settings${ownProps.caseId}.respondedNo`),
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'declineServiceChangeRequest',
  })(DeclineServiceChangeRequestForm),
);
