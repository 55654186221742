import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from 'src/contracts/ducks';
import { loadRouteLocationIssues } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import RouteLocationIssuesModal from './RouteLocationIssuesModal';

interface Props {
  routeId: number;
  modalTitle?: string;
  modalSubTitle?: string;
  routeLocationId?: number;
  vehicleTypeId?: number;
  closeModal: () => void;
  loadRouteLocationIssues: DuckFunction<typeof loadRouteLocationIssues>;
  wasteAuditType?: TechnicalType;
}

class RouteLocationIssuesModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { routeId, routeLocationId, loadRouteLocationIssues } = this.props;

    return Promise.all([loadRouteLocationIssues(routeId, routeLocationId)]);
  };

  render() {
    const { modalTitle, modalSubTitle, routeLocationId, closeModal, wasteAuditType, vehicleTypeId } = this.props;

    return (
      <Resolver
        successComponent={RouteLocationIssuesModal}
        successProps={{
          modalTitle,
          modalSubTitle,
          routeLocationId,
          closeModal,
          wasteAuditType,
          vehicleTypeId,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
        onError={closeModal}
      />
    );
  }
}

const mapDispatchToProps = { loadRouteLocationIssues };

export default connect(undefined, mapDispatchToProps)(RouteLocationIssuesModalResolver);
