import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadWasteTypes } from 'src/common/ducks';

import { loadGroups, loadMaterialTypeValues, loadServiceZones } from 'src/routes/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { PageLoadingOverlay } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import { loadOperationalFacilities } from '../../../../fleet/ducks';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import DailyAndReoccurringFiltersSection from '../../pages/routePlanner/RoutePlannerDailyAndReoccurringPageSections/DailyAndReoccurringFiltersSection';

const DailyAndReoccurringFiltersSectionResolver: React.FC = () => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    const dependencies = [loadMaterialTypeValues()(dispatch), loadWasteTypes()(dispatch)];

    if (vendorId) {
      dependencies.push(
        loadSupervisors(vendorId)(dispatch),
        loadServiceZones(vendorId)(dispatch),
        loadOperationalFacilities(vendorId)(dispatch),
        loadGroups(vendorId)(dispatch),
      );
    }

    return Promise.all(dependencies);
  }, [dispatch, vendorId]);

  return (
    <TypedResolver
      successComponent={DailyAndReoccurringFiltersSection}
      loadingComponent={PageLoadingOverlay}
      successProps={{}}
      resolve={loadDependencies}
    />
  );
};

export default DailyAndReoccurringFiltersSectionResolver;
