import { Field, reduxForm, InjectedFormProps, submit } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Dropdown } from '../../../core/components';
import { Grid, GridColumn, PanelSection, Button, ButtonSet } from '../../../core/components/styled';
import { isRequired } from '../../../utils/services/validator';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../core/services/translate';

export type TransferSnowPlowTemplateSegmentsFormValues = {
  targetTemplateId: string;
};

const form = 'transferRouteLocations';

export default reduxForm<TransferSnowPlowTemplateSegmentsFormValues>({
  form,
})(function TransferSnowOrSweeperTemplateSegmentsForm({
  handleSubmit,
}: InjectedFormProps<TransferSnowPlowTemplateSegmentsFormValues>) {
  const dispatch = useDispatch();
  const { isLoading, routeTemplatesForReassignment = [] } = useSelector(
    state => state.routes.routeTemplatesForReassignment,
  );

  const routesForReassignmentOptions = routeTemplatesForReassignment.map(
    ({ name, id }: { name: string; id: number }) => ({
      label: name,
      value: id,
    }),
  );

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="medium no no" vertical isLoading={isLoading}>
        <Grid centered>
          <GridColumn size="6/12">
            <Field
              name="targetTemplateId"
              component={Dropdown}
              options={routesForReassignmentOptions}
              disabled={!routesForReassignmentOptions.length}
              label={translate('routes.targetRoute')}
              validate={isRequired}
            />
          </GridColumn>
        </Grid>

        <ButtonSet margin="lMedium no no">
          <Button type="button" onClick={() => dispatch(submit(form))} color="primary">
            {translate('routes.transferSegments')}
          </Button>
        </ButtonSet>
      </PanelSection>
    </form>
  );
});
