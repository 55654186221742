import FileSaver from 'file-saver';
import { EXCEL_MIME_TYPES } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { http } from '../../core/services/http';
import { DriverInspectionLog } from '../interfaces/DriverInspectionLog';

export const doLoadDriverInspectionLogs = (vendorId: number, driverId: number, startDate: string, endDate: string) =>
  http
    .get<DriverInspectionLog[]>(`vendors/${vendorId}/driverSafetyInspectionLog`, {
      params: { driverId, startDate, endDate },
    })
    .then(response => response.data);

export const doLoadDriverInspectionDetails = (vendorId: number, driverSafetyInspectionId: number) =>
  http
    .get(`vendors/${vendorId}/driverSafetyInspection`, {
      params: { driverSafetyInspectionId },
    })
    .then(response => response.data);

export const exportDriverInspectionLog = (vendorId: number, driverSafetyInspectionId: number) => {
  return http
    .post(
      `/reports/vendors/${vendorId}/driverSafetyInspectionReport`,
      { vendorId, driverSafetyInspectionId },
      {
        responseType: 'arraybuffer',
      },
    )
    .then(response => {
      const blob = new Blob([response.data], {
        type: EXCEL_MIME_TYPES,
      });
      FileSaver.saveAs(
        blob,
        `${translate('vendors.driverSafetyReport.driverSafetyInspectionReport')}-${driverSafetyInspectionId}.xlsx`,
      );
    });
};
