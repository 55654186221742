import { identity, get, find } from 'lodash-es';
import { createSelector } from 'reselect';

import { customerLocationByIdSelector, State } from '../ducks/locations';
import { COUNTRIES, UNITED_STATES } from '../../common/constants';
import { TemporaryAddress } from '../interfaces/Customers';

const getCountry = (country?: string) => {
  if (!country) {
    return UNITED_STATES;
  }

  return get(find(COUNTRIES, { id: country }), 'id') || UNITED_STATES;
};

const getInitialCustomerAddress = (customerLocationsState: State, customerLocationId: number, currentFormValues: Partial<TemporaryAddress>) => {
  const address = get((customerLocationByIdSelector as any)(customerLocationsState, customerLocationId), 'address');
  return {
    ...address,
    ...currentFormValues,
    country: getCountry(get(address, 'country')),
  };
};

const temporaryAddressEditorFormInitialValues = createSelector(getInitialCustomerAddress, identity);
export default temporaryAddressEditorFormInitialValues;
