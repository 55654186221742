import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { find, findIndex, map, size, filter } from 'lodash-es';

import { AppState } from '../../../store';
import { ALL } from '../../constants/holidayPlanner';
import {
  Button,
  Message,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  ModalFixedHeader,
  ModalSection,
  ModalTitle,
  Text,
} from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { Modal, Table, UnconnectedCheckbox, Icon } from '../../../core/components';
import { multiWordAndSearch } from '../../../core/services/search';
import { RouteTemplatesFormForHolidayPlanner } from '../forms';
import { saveHolidayPlannerRouteTemplates } from '../../ducks/holidayPlanner';
import RouteTemplateHolidayPlannerTableRow from '../../../routes/components/pages/routeTemplates/RouteTemplateHolidayPlannerTableRow';
import translate from '../../../core/services/translate';
import update from 'immutability-helper';

interface Props {
  closeModal: () => void;
  holidayId: number;
  isDefaultHoliday: boolean;
  isLoading: boolean;
  isSaving: boolean;
  routeTemplatesForHolidayPlanner?: any[];
  setRouteTemplatesCountDisplayed: (selectedTemplatesLength: number | string) => void;
  saveHolidayPlannerRouteTemplates: (
    vendorId: number,
    holidayId: number,
    isDefaultHoliday: boolean,
    routeTemplates: any[],
  ) => Promise<any>;
  vendorId: number;
}

interface State {
  allRouteTemplatesChecked: boolean;
  routeTemplatesFiltered: any[];
  routeTemplatesForHolidayPlanner: any[];
  selectedRouteTemplateName: string;
  selectedVehicleTypeIds: any[];
}

class HolidayPlannerSelectRoutesModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      allRouteTemplatesChecked: props.routeTemplatesForHolidayPlanner
        ? props.routeTemplatesForHolidayPlanner.filter(routeTemplate => routeTemplate.isChecked).length ===
          props.routeTemplatesForHolidayPlanner.length
        : false,
      routeTemplatesFiltered: props.routeTemplatesForHolidayPlanner || [],
      routeTemplatesForHolidayPlanner: props.routeTemplatesForHolidayPlanner || [],
      selectedRouteTemplateName: '',
      selectedVehicleTypeIds: [],
    };
  }

  CheckAllRouteTemplatesCheckbox = ({ checkAll, checked }: any) => (
    <UnconnectedCheckbox
      block
      noLabel
      size="small"
      margin="no no xSmall no"
      checked={checked}
      onChange={() => checkAll()}
    />
  );

  checkAllRouteTemplates = () => {
    this.setState(prevState => {
      const routeTemplatesFiltered = map(prevState.routeTemplatesFiltered, routeTemplate => ({
        ...routeTemplate,
        isChecked: !prevState.allRouteTemplatesChecked,
      }));

      const filteredRouteTemplatesIds = map(routeTemplatesFiltered, ({ id }) => id);

      const routeTemplatesForHolidayPlanner = map(prevState.routeTemplatesForHolidayPlanner, routeTemplate =>
        filteredRouteTemplatesIds.includes(routeTemplate.id)
          ? { ...routeTemplate, isChecked: !prevState.allRouteTemplatesChecked }
          : routeTemplate,
      );

      return {
        allRouteTemplatesChecked: !prevState.allRouteTemplatesChecked,
        routeTemplatesFiltered,
        routeTemplatesForHolidayPlanner,
      };
    });
  };

  onRouteTemplateSelected = (event: any, id: number) => {
    event.stopPropagation();

    const { routeTemplatesFiltered, routeTemplatesForHolidayPlanner } = this.state;

    const routeTemplateIndex = findIndex(routeTemplatesFiltered, { id });
    const routeTemplateForHolidayPlannerIndex = findIndex(routeTemplatesForHolidayPlanner, { id });

    const routeTemplatesUpdated = update(routeTemplatesFiltered, {
      [routeTemplateIndex]: {
        isChecked: { $set: !routeTemplatesFiltered[routeTemplateIndex].isChecked },
      },
    });
    const routeTemplatesForHolidayPlannerUpdated = update(routeTemplatesForHolidayPlanner, {
      [routeTemplateForHolidayPlannerIndex]: {
        isChecked: { $set: !routeTemplatesForHolidayPlanner[routeTemplateForHolidayPlannerIndex].isChecked },
      },
    });

    const allRouteTemplatesChecked = size(find(routeTemplatesUpdated, { isChecked: false })) === 0;

    this.setState({
      allRouteTemplatesChecked,
      routeTemplatesFiltered: routeTemplatesUpdated,
      routeTemplatesForHolidayPlanner: routeTemplatesForHolidayPlannerUpdated,
    });
  };

  saveSelectedRoutes = () => {
    const { routeTemplatesForHolidayPlanner } = this.state;
    const {
      closeModal,
      holidayId,
      isDefaultHoliday,
      saveHolidayPlannerRouteTemplates,
      setRouteTemplatesCountDisplayed,
      vendorId,
    } = this.props;

    const routeTemplates = routeTemplatesForHolidayPlanner.map(routeTemplate => {
      return {
        routeTemplateId: routeTemplate.id,
        isChecked: routeTemplate.isChecked,
      };
    });

    const routeTemplatesForHolidayPlannerChecked = routeTemplatesForHolidayPlanner.filter(
      routeTemplate => routeTemplate.isChecked,
    );
    const selectedTemplatesLength =
      routeTemplatesForHolidayPlannerChecked.length === routeTemplatesForHolidayPlanner.length
        ? ALL
        : routeTemplatesForHolidayPlannerChecked.length;

    if (routeTemplatesForHolidayPlannerChecked.length === 0) {
      createErrorNotification(`${translate('routes.holidayPlanner.alertMessages.selectAtLeastOneRoute')}`);
    } else {
      saveHolidayPlannerRouteTemplates(vendorId, holidayId, isDefaultHoliday, routeTemplates)
        .then(() => {
          createSuccessNotification(`${translate('routes.holidayPlanner.alertMessages.saveRoutesSucces')}`);
          setRouteTemplatesCountDisplayed(selectedTemplatesLength);
          closeModal();
        })
        .catch(() => {
          createErrorNotification(`${translate('routes.holidayPlanner.alertMessages.saveRoutesError')}`);
        });
    }
  };

  onRouteTemplateNameChange = (routeTemplateName: string) => {
    this.setState({ selectedRouteTemplateName: routeTemplateName }, () => {
      this.loadRouteTemplatesForHolidayPlanner();
    });
  };

  onVehicleTypeChange = (vehicleTypeIds: any[]) => {
    this.setState({ selectedVehicleTypeIds: vehicleTypeIds }, () => {
      this.loadRouteTemplatesForHolidayPlanner();
    });
  };

  loadRouteTemplatesForHolidayPlanner = () => {
    const { selectedVehicleTypeIds, selectedRouteTemplateName, routeTemplatesForHolidayPlanner } = this.state;

    const routeTemplatesFilteredByName = filter(
      routeTemplatesForHolidayPlanner,
      routeTemplate =>
        !selectedRouteTemplateName || multiWordAndSearch(routeTemplate.routeTemplateName, selectedRouteTemplateName),
    );
    let routeTemplatesFiltered = routeTemplatesFilteredByName;

    if (size(selectedVehicleTypeIds)) {
      routeTemplatesFiltered = filter(routeTemplatesFilteredByName, routeTemplate =>
        selectedVehicleTypeIds.includes(routeTemplate.vehicleTypeId.toString()),
      );
    }

    const allRouteTemplatesChecked = size(find(routeTemplatesFiltered, { isChecked: false })) === 0;

    this.setState({
      allRouteTemplatesChecked,
      routeTemplatesFiltered,
    });
  };

  render() {
    const { closeModal, isLoading, isSaving } = this.props;
    const { routeTemplatesFiltered, allRouteTemplatesChecked } = this.state;

    const routesTableCells = [
      {
        name: 'selectAll',
        component: this.CheckAllRouteTemplatesCheckbox,
        componentProps: {
          checkAll: this.checkAllRouteTemplates,
          checked: allRouteTemplatesChecked,
        },
        width: '5%',
      },
      { name: 'routeTemplateName', label: translate('routes.routeName'), width: '30%', sortable: true },
      {
        name: 'wasteMaterialTypeName',
        label: translate('routes.materialType'),
        width: '15%',
        sortable: true,
      },
      { name: 'routeDate', label: translate('routes.dayOfService'), width: '15%', sortable: true },
      { name: 'noOfStops', label: translate('routes.numberOfStops'), width: '15%', sortable: true },
      { name: 'vehicleTypeName', label: translate('vehicles.vehicleType'), width: '20%', sortable: true },
    ];

    return (
      <Modal padding="no" size="large" flex isLoading={isLoading || isSaving} verticalSize="smallMedium">
        <ModalSection padding="no" fluid>
          <ModalFixedHeader padding="sMedium no no no">
            <ModalClose onClick={closeModal}>
              <ModalCloseIcon />
            </ModalClose>
            <ModalTitle>
              {translate('routes.holidayPlanner.selectRoutes')}
              <br />
              <Text flex singleLine size="small" margin="no">
                <Icon icon="info" width="14px" height="10px" />{' '}
                {translate('routes.holidayPlanner.alertMessages.displayingActiveRouteTemplatesOnly')}
              </Text>
            </ModalTitle>
          </ModalFixedHeader>
          <RouteTemplatesFormForHolidayPlanner
            onRouteTemplateNameChange={this.onRouteTemplateNameChange}
            onVehicleTypeChange={this.onVehicleTypeChange}
          />
          {size(routeTemplatesFiltered) ? (
            <Table
              cells={routesTableCells}
              rows={routeTemplatesFiltered}
              rowComponent={RouteTemplateHolidayPlannerTableRow}
              rowProps={{
                selectRouteTemplate: this.onRouteTemplateSelected,
              }}
            />
          ) : (
            <Message padding="sMedium">{translate('routes.holidayPlanner.noRouteTemplates')}</Message>
          )}
          <ModalFixedFooter padding="sMedium small" justifyContent="center">
            <Button type="button" color="primary" onClick={this.saveSelectedRoutes}>
              {translate('common.save')}
            </Button>
            <Button type="button" margin="no no no small" color="secondary" onClick={closeModal}>
              {translate('common.cancel')}
            </Button>
          </ModalFixedFooter>
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  routeTemplatesForHolidayPlanner: state.routes.routeTemplates.routeTemplatesForHolidayPlanner,
  isSaving: state.routes.holidayPlanner.holidayPlannerRouteTemplatesSaving,
  isLoading: state.routes.routeTemplates.isLoading,
});

const mapDispatchToProps = { saveHolidayPlannerRouteTemplates };

export default connect(mapStateToProps, mapDispatchToProps)(HolidayPlannerSelectRoutesModal);
