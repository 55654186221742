import moment from 'moment';

import { DisposalTrip } from 'src/routes/interfaces/DisposalTrip';
import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { disposalsTableCellWidths } from './DisposalTripsSection';
import { duration } from 'src/utils/services/formatter';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';

export default function DisposalTripTableRow({
  disposalSite,
  driverName,
  endTime,
  facilityName,
  startTime,
  vehicleName,
}: DisposalTrip) {
  const startMoment = moment(startTime);
  const endMoment = moment(endTime);

  return (
    <TableRow>
      <TableCell width={disposalsTableCellWidths[0]}>
        <Text>{disposalSite || facilityName}</Text>
      </TableCell>
      <TableCell width={disposalsTableCellWidths[1]}>
        {vehicleName && driverName ? (
          <Text>
            <Text block weight="medium">
              {vehicleName}
            </Text>
            <Text>{driverName}</Text>
          </Text>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell width={disposalsTableCellWidths[2]}>
        <Text>
          <Text block weight="medium">
            {startMoment.format('MM/DD/YYYY')}
          </Text>
          <Text>{startMoment.format(timeFormatWithoutSeconds)}</Text>
        </Text>
      </TableCell>
      <TableCell width={disposalsTableCellWidths[3]}>
        <Text>
          <Text block weight="medium">
            {endMoment.format('MM/DD/YYYY')}
          </Text>
          <Text>{endMoment.format(timeFormatWithoutSeconds)}</Text>
        </Text>
      </TableCell>
      <TableCell width={disposalsTableCellWidths[4]}>
        <Text>{duration(endMoment.diff(startMoment, 's'), 'seconds', 'hh:mm')}</Text>
      </TableCell>
    </TableRow>
  );
}
