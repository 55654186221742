import EsriCredentialsSection from '../modals/EsriCredentialsSection';
import {
  ESRI_CREDENTIALS_LAYER,
  ESRI_CUSTOMER_LAYER,
  ESRI_GEO_FENCE_LAYER,
  ESRI_ROUTE_LAYER,
  ESRI_SERVICE_LAYER,
} from '../../constants/esriConfig';
import { Grid, GridColumn, ModalSection } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import Open311Section from '../modals/ServiceRequestConfigurationSections/Open311Section';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function EsriIntegrationConfigurationModal({ closeModal }: Props) {
  return (
    <Modal size="medium" title={translate('vendors.esriSync.esriIntegration')} onClose={closeModal}>
      <ModalSection margin="medium no no" align="center">
        <Grid multiLine>
          <GridColumn size="12/12">
            <Open311Section
              title={translate('vendors.esriSync.esriCredentials')}
              content={<EsriCredentialsSection configLayer={ESRI_CREDENTIALS_LAYER} />}
            />
          </GridColumn>
          <GridColumn size="12/12">
            <Open311Section
              title={translate('vendors.esriSync.customerConfig')}
              content={<EsriCredentialsSection configLayer={ESRI_CUSTOMER_LAYER} />}
            />
          </GridColumn>
          <GridColumn size="12/12">
            <Open311Section
              title={translate('vendors.esriSync.serviceConfig')}
              content={<EsriCredentialsSection configLayer={ESRI_SERVICE_LAYER} />}
            />
          </GridColumn>
          <GridColumn size="12/12">
            <Open311Section
              title={translate('vendors.esriSync.routeConfig')}
              content={<EsriCredentialsSection configLayer={ESRI_ROUTE_LAYER} />}
            />
          </GridColumn>
          <GridColumn size="12/12">
            <Open311Section
              title={translate('vendors.esriSync.geoFenceConfig')}
              content={<EsriCredentialsSection configLayer={ESRI_GEO_FENCE_LAYER} />}
            />
          </GridColumn>
        </Grid>
      </ModalSection>
    </Modal>
  );
}
