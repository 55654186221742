import styled, { css, keyframes } from 'styled-components';

import { mapper } from 'src/utils/styles';
import FormMeta from './FormMeta';

interface Props {
  align?: string;
  disabled?: boolean;
  disabledDarker?: boolean;
  fixedInputWidth?: boolean;
  focus?: boolean;
  hasError?: boolean;
  hasMarginLeft?: string;
  inputWidth?: string;
  isInlineLabelFirst?: boolean;
  leftMargin?: string | number;
  leftPadding?: number;
  noMarginLeft?: boolean;
  noWrap?: boolean;
  opacity?: boolean;
  readOnly?: boolean;
  rightPadding?: string;
  shouldShowError?: boolean;
  width?: string | number;
  withEllipsesText?: boolean;
  withSelectStyle?: boolean;
}

export const InputStyle = (props: any) => `
  width: 100%;
  padding: 2px 2px 8px 0;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid ${props.theme.grayLight};
  border-radius: 0;
  line-height: 22px;
  font-weight: ${props.theme.fontWeightLight};
  font-size: 14px;
  text-overflow: ellipsis;

  &:focus {
    border-bottom-color: ${props.focus && (props.readOnly ? props.theme.grayLight : props.theme.brandPrimary)};
  }

  &::placeholder {
    text-transform: capitalize;
    color: ${props.theme.grayDark};
    font-size: 14px;
  }
  `;

export const SelectStyle = (props: any) => `
    appearance: none;
    padding-right: 36px;
    background: ${
      props.focus
        ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+')"
        : " url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2Utb3BhY2l0eT0iLjcwNCIvPjwvc3ZnPg==')"
    } right 2px center no-repeat;

    &:focus {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
    }

    &:disabled {
      cursor: not-allowed;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiM5Njk2OTYiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
    }
  `;

export const FormInputWrapper = styled.div<Props & { flex?: boolean }>`
  position: relative;
  ${props =>
    props.flex &&
    css`
      display: flex;
    `};
`;

export const FormInlineLabel = styled.span<Props>`
  position: absolute;
  line-height: 22px;
  top: 2px;
  pointer-events: none;

  ${props =>
    props.leftMargin &&
    css`
      left: ${props.leftMargin}%;
    `};

  ${props =>
    props.isInlineLabelFirst &&
    css`
      position: relative;
      top: 0;
    `};
`;

export const TimeFieldWrapper = styled.div<Props>`
  input {
    ${InputStyle};
    width: 100% !important;

    ${props =>
      props.readOnly &&
      css`
        opacity: 0.4;
        cursor: not-allowed;
        color: ${props => props.theme.grayDark};
      `};
  }
`;

/**
 * The only purpose for these animations is to trigger
 * the onAnimationStart event on react components.
 * If there is no keyframe declaration, the event
 * won't be triggered.
 */
export const inputAutofillAnimationStart = keyframes`
  from { opacity: .95 }
  to { opacity: 1 }
`;

/**
 * Keyframes should differ, even if just a bit, or else
 * they will be treated as being the same, having the
 * same name and object instance.
 */
export const inputAutofillAnimationCancel = keyframes`
  from { opacity: .97 }
  to { opacity: 1 }
`;

export const MultipleInputWrapper = styled.div<Props>`
  border-bottom: none;
  display: flex;
  flex-direction: row;
  width: ${props => props.width}px;
`;

export const Input = styled.input<Props>`
  ${InputStyle};

  &:-webkit-autofill {
    animation-name: ${inputAutofillAnimationStart};
    /**
     * Veeeeery slow change of background color.
     * The background color cannot be overwritten when
     * autofill is in action, but we can change the
     * transition so it would change only after a great
     * amount of time.
     */
    transition: background-color 9999999s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    animation-name: ${inputAutofillAnimationCancel};
  }

  ${props =>
    props.hasMarginLeft &&
    css`
      margin-left: ${props.hasMarginLeft === 'normal' && '-150px'};
    `}
  ${props =>
    props.hasMarginLeft &&
    css`
      margin-left: ${props.hasMarginLeft === 'small' && '-102px'};
    `}
    ${props =>
    props.hasMarginLeft &&
    css`
      margin-left: ${props.hasMarginLeft === 'smaller' && '-90px'};
    `}

      ${props =>
    props.hasMarginLeft &&
    css`
      margin-left: ${props.hasMarginLeft === 'normalLineInput' && '-85px'};
    `}
      ${props =>
    props.hasMarginLeft &&
    css`
      margin-left: ${props.hasMarginLeft === 'smallLineInput' && '-35px'};
    `}
    ${props =>
    props.hasMarginLeft &&
    css`
      margin-left: ${props.hasMarginLeft === 'smallerLineInput'
        ? '-20px'
        : props.hasMarginLeft === 'hasSize' && '-15px'};
    `}


  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  ${props =>
    props.disabledDarker &&
    css`
      opacity: 1;
    `}

  ${props =>
    props.withSelectStyle &&
    css`
      ${SelectStyle};
    `};

  ${props =>
    props.withEllipsesText &&
    css`
      text-overflow: ellipsis;
    `};

  ${props =>
    props.opacity &&
    css`
      opacity: 1;
      color: ${props.theme.grayBase} !important;
    `};

  ${props =>
    props.rightPadding &&
    css`
      padding-right: ${props.rightPadding};
    `};

  ${props =>
    props.leftPadding &&
    css`
      padding-left: ${props.leftPadding}%;
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `};

  ${props =>
    props.hasError &&
    css`
      border-bottom: 1px solid ${props => props.theme.brandAlert};
    `};

  &[type='hidden'] + span {
    display: none;

    ${props =>
      props.shouldShowError &&
      css`
        display: block;
      `};
  }

  ${props =>
    props.focus &&
    css`
      border-bottom: 1px solid ${props => props.theme.brandPrimary};
    `};

  &:focus + ${FormMeta} {
    color: ${props => props.theme.brandPrimary};
  }

  ${props =>
    props.inputWidth &&
    css`
      width: ${props.inputWidth};
    `};

  ${props =>
    props.fixedInputWidth &&
    css`
      width: 20px;
      margin-left: 0;
      text-align: center;
    `};

  ${props =>
    props.noMarginLeft &&
    css`
      margin-left: 0;
    `};

  ${props =>
    props.isInlineLabelFirst &&
    css`
      margin-left: 5px;
      width: auto;
    `};
`;

interface FakeInputProps extends Props {
  verticalMiddle?: boolean;
  noFw?: boolean;
  noOverflow?: boolean;
  size?: 'normal' | 'small';
}

export const FakeInput = styled.div<FakeInputProps>`
  ${InputStyle};
  font-size: ${props => mapper(props.size, { small: '12px', normal: '14px' }, 'normal')};
  line-height: ${props => mapper(props.size, { small: '18px', normal: '22px' }, 'normal')};
  ${props =>
    props.verticalMiddle &&
    css`
      display: flex;
      align-items: center;
    `}
  ${props =>
    props.noFw &&
    css`
      width: auto;
    `}
  ${props =>
    !props.noOverflow
      ? css`
          text-overflow: ellipsis;
          overflow: hidden;
        `
      : css`
          text-overflow: initial;
        `}
  ${props =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}
`;
