import React, { Fragment, useEffect, useRef, useState } from 'react';

import { throttle } from 'lodash-es';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Input, PopoverWrapper, UnconnectedCheckbox } from '../../../core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  PanelSection,
  Popover,
  Text,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import {
  PdfDocument,
  PdfFilename,
  PdfHeader,
  PdfLoader,
  PdfPage,
  PdfPageIndicator,
  PdfWrapper,
  ProgressBar,
  ProgressContainer,
} from '../styled';
import { isRequired } from '../../../utils/services/validator';

interface PropsWithoutReduxForm {
  selfCertificationDocumentUrl: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const SignDocumentForm = (props: Props) => {
  const pdfWrapperRef = useRef<HTMLDivElement>(null);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [scrolled, setScrolled] = useState<number>(0);
  const [isAcceptDisabled, setIsAcceptDisabled] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { selfCertificationDocumentUrl, handleSubmit } = props;

  useEffect(() => {
    const wrapper = pdfWrapperRef.current;

    const scrollProgress = throttle(() => {
      if (wrapper) {
        const scrollPx = wrapper.scrollTop;
        const winHeightPx = wrapper.scrollHeight - wrapper.clientHeight;
        const progress = Math.ceil((scrollPx / winHeightPx) * 100);
        const currentPage = Math.ceil((1 * progress) / 100);

        setScrolled(progress);
        setPageNumber(currentPage);
        if (currentPage === numPages) setIsAcceptDisabled(false);
      }
    }, 500);

    if (numPages > 0 && wrapper) {
      wrapper.addEventListener('scroll', scrollProgress);
    }

    return () => (wrapper ? wrapper.removeEventListener('scroll', scrollProgress) : undefined);
  }, [numPages]);

  const onDocumentLoadSuccess = (pdf: any) => {
    setPageNumber(1);
    setNumPages(pdf.numPages);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection padding="small xSmall">
        {selfCertificationDocumentUrl ? (
          <Grid multiLine>
            <GridColumn size="6/12" padding="no xSmall no">
              <Field
                name="vendor"
                component={Input}
                label={translate('common.vendor')}
                disabled
                margin="no no medium no"
              />
            </GridColumn>
            <GridColumn size="6/12" padding="no xSmall no">
              <Field
                name="email"
                component={Input}
                label={translate('common.email')}
                disabled
                margin="no no medium no"
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                name="name"
                component={Input}
                label={translate('common.name')}
                margin="no no medium no"
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                name="title"
                component={Input}
                label={translate('common.title')}
                margin="no no medium no"
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="12/12">
              <ProgressContainer>
                <ProgressBar width={`${scrolled}%`} />
              </ProgressContainer>
              <PdfHeader>
                <PdfFilename>{selfCertificationDocumentUrl.replace(/^.*[\\/]/, '')}</PdfFilename>
                <PdfPageIndicator>{`${translate('common.page')}: ${pageNumber}/${numPages}`}</PdfPageIndicator>
              </PdfHeader>
              <PdfWrapper ref={pdfWrapperRef}>
                <PdfDocument
                  file={selfCertificationDocumentUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={<PdfLoader />}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <PdfPage key={`page_${index + 1}`} loading=" " pageNumber={index + 1} />
                  ))}
                </PdfDocument>
              </PdfWrapper>
            </GridColumn>
            <GridColumn size="12/12" padding="medium no no">
              <Fragment>
                <PopoverWrapper
                  triggerButton={
                    <UnconnectedCheckbox
                      disabled={isAcceptDisabled}
                      onChange={() => setIsDisabled(!isDisabled)}
                      label={translate('haulerProfile.acceptCertificateOfCompliance')}
                    />
                  }
                  popoverContent={
                    isDisabled && (
                      <Popover>
                        <Text block margin="xxSmall no xxSmall">
                          {translate('haulerProfile.readDocument')}
                        </Text>
                      </Popover>
                    )
                  }
                  size="medium"
                />
              </Fragment>
            </GridColumn>
            <GridColumn size="12/12">
              <ButtonSet align="center">
                <PopoverWrapper
                  triggerButton={
                    <Button type="submit" color="primary" disabled={isDisabled}>
                      {translate('common.submit')}
                    </Button>
                  }
                  popoverContent={
                    isDisabled && (
                      <Popover>
                        <Text block margin="xxSmall no xxSmall">
                          {translate('haulerProfile.submitButtonDisableMessage')}
                        </Text>
                      </Popover>
                    )
                  }
                  size="large"
                />
              </ButtonSet>
            </GridColumn>
          </Grid>
        ) : (
          <Message padding="sMedium">{translate('haulerProfile.noDocumentFound')}</Message>
        )}
      </PanelSection>
    </form>
  );
};

export default reduxForm({
  form: 'signDocument',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(SignDocumentForm);
