import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadDriverSafetyConfigurationsForVehicle } from 'src/vendors/ducks';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import DriverSafetyModal from './DriverSafetyModal';

interface Props extends ModalProps {
  vehicleId?: number;
  vehicleTypeIdSpecificForVehicle?: number;
}

const DriverSafetyModalResolver: React.FC<Props> = ({ closeModal, vehicleId, vehicleTypeIdSpecificForVehicle }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return !!vehicleId
      ? Promise.all([loadDriverSafetyConfigurationsForVehicle(vendorId, vehicleId)(dispatch)])
      : Promise.all([loadVehicleTypesForVendor(vendorId)(dispatch)]);
  }, [dispatch, vendorId, vehicleId]);

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={DriverSafetyModal}
      successProps={{ closeModal, vehicleId, vehicleTypeIdSpecificForVehicle }}
    />
  );
};

export default DriverSafetyModalResolver;
