import React, { Fragment } from 'react';

import { map, size } from 'lodash-es';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { TOTER, WASTE_AUDIT } from 'src/fleet/constants';
import {
  EXCEPTIONS,
  PARTICIPATION,
  WASTE_AUDIT_EXCEPTION_TYPES,
  WASTE_AUDIT_LOCATION_STATUS_TYPES,
} from 'src/routes/constants';
import { WasterRecorder } from 'src/routes/interfaces/WasterRecorder';

interface Props {
  vehicleType: string;
  wasteAuditLocationStatusTypeId?: number;
  wasteAuditStatuses?: number[];
  wasteAuditType?: TechnicalType;
  wasterRecorders?: WasterRecorder[];
}

const StatusDetailsPopover = ({
  vehicleType,
  wasteAuditLocationStatusTypeId,
  wasteAuditStatuses,
  wasteAuditType,
  wasterRecorders,
}: Props) => (
  <Fragment>
    {!size(wasterRecorders) && vehicleType === TOTER && (
      <Text weight="medium" size="small">
        {translate('routes.noCarts')}
      </Text>
    )}

    {!!size(wasterRecorders) &&
      map(wasterRecorders, ({ equipmentTypeName, numberOfBins }) => (
        <Text block size="small" margin="no no xSmall" key={equipmentTypeName} lastChildNoMargin>
          <Text weight="medium" size="small">
            {equipmentTypeName}
          </Text>
          : {numberOfBins}
        </Text>
      ))}

    {vehicleType === WASTE_AUDIT &&
      wasteAuditType &&
      ((!size(wasteAuditStatuses) && wasteAuditType.id === EXCEPTIONS) ||
        (!wasteAuditLocationStatusTypeId && wasteAuditType.id === PARTICIPATION)) && (
        <Text weight="medium" size="small">
          {translate(`routes.noWasteAudit${wasteAuditType.technicalName}`)}
        </Text>
      )}

    {!!size(wasteAuditStatuses) &&
      map(wasteAuditStatuses, wasteAuditStatus => (
        <Text block weight="medium" size="small" margin="no no xSmall" key={wasteAuditStatus} lastChildNoMargin>
          {WASTE_AUDIT_EXCEPTION_TYPES[wasteAuditStatus].name}
        </Text>
      ))}

    {wasteAuditLocationStatusTypeId && wasteAuditType?.id === PARTICIPATION && (
      <Text block weight="medium" size="small" margin="no">
        {WASTE_AUDIT_LOCATION_STATUS_TYPES[wasteAuditLocationStatusTypeId].name}
      </Text>
    )}
  </Fragment>
);

export default StatusDetailsPopover;
