import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { dateAndTime } from 'src/utils/services/formatter';
import { getSegmentCenter, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { Grid, GridColumn, Label, Separator, Text } from 'src/core/components/styled';
import { MapGLPopup, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { MapGLPopupContent } from '../../styled';
import { RouteMapFiltersTitle } from 'src/routes/components/styled/RouteMapFilters';
import { STREET_SWEEPER_BROOM_SIDE_TYPE, STREET_SWEEPER_SEGMENT_TYPE } from 'src/customers/constants/streetNetwork';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export const getTypesAsString = (types: number[] = [], isBroomSideType?: boolean) => {
  return types.length
    ? types
        .map(
          (serviceType: number) =>
            (isBroomSideType ? STREET_SWEEPER_BROOM_SIDE_TYPE : STREET_SWEEPER_SEGMENT_TYPE)[serviceType]?.name,
        )
        .join(', ')
    : '-';
};

export default function StreetNetworkMapRouteSegmentsGLPopup({
  isDailyRoute,
  isRouteTemplate,
  isSnowPlowRoute,
  isStreetSweeperRoute,
}: {
  isDailyRoute?: boolean;
  isRouteTemplate?: boolean;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
}) {
  const dispatch = useDispatch();

  const {
    streetNetworkSegmentDetails,
    streetNetworkSegmentDetailsForDailyRoute,
    isLoadingSegmentDetails,
    isLoadingSegmentDetailsForDailyRoute,
  } = useSelector(state => state.customers.streetNetwork);

  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const routeSegment = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeSegments) {
      return undefined;
    }

    const selectedSegment: any = isDailyRoute ? streetNetworkSegmentDetailsForDailyRoute : streetNetworkSegmentDetails;

    if (!selectedSegment) {
      return undefined;
    }

    const segmentCenter = getSegmentCenter(parseSegmentJSON(selectedSegment.lineSegment));

    return {
      ...selectedSegment,
      segmentCenter,
    };
  }, [selectedFeature, isDailyRoute, streetNetworkSegmentDetailsForDailyRoute, streetNetworkSegmentDetails]);

  if (!routeSegment || isLoadingSegmentDetails || isLoadingSegmentDetailsForDailyRoute) {
    return null;
  }

  const forwardPasses = routeSegment.streetSegmentActivitySettings[0].forwardPasses;
  const reversePasses = routeSegment.streetSegmentActivitySettings[0].reversePasses;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={routeSegment.segmentCenter[1]}
      longitude={routeSegment.segmentCenter[0]}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupContent>
          <Grid multiLine>
            {/* Segment details section */}
            <GridColumn size="12/12" padding="no" margin="no">
              <RouteMapFiltersTitle>{translate('customers.streetNetwork.segmentDetails')}</RouteMapFiltersTitle>
            </GridColumn>
            <GridColumn size="9/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="sMedium">
                {translate('customers.streetNetwork.streetName')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.name || '-'} singleLine>
                {routeSegment.name || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="3/12" padding="no" margin="small no no no">
              <Label color={routeSegment.isActive ? 'success' : 'info'}>
                {routeSegment.isActive
                  ? translate('customers.streetNetwork.active')
                  : translate('customers.streetNetwork.inactive')}
              </Label>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="sMedium">
                {translate('customers.streetNetwork.segmentID')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.streetId || '-'} singleLine>
                {routeSegment.streetId || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="sMedium">
                {translate('customers.streetNetwork.drivingSpeed')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.speedLimit || '-'} singleLine>
                {routeSegment.speedLimit || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="sMedium">
                {translate('customers.streetNetwork.classification')}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={routeSegment.type || '-'} singleLine>
                {routeSegment.type || '-'}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="sMedium">
                {translate('customers.streetNetwork.travelDirection')}
              </Text>
              <Text
                block
                margin="xxSmall no no no"
                size="medium"
                title={translate(`customers.streetNetwork.${routeSegment.isOneWay ? 'oneWay' : 'twoWay'}`)}
                singleLine
              >
                {translate(`customers.streetNetwork.${routeSegment.isOneWay ? 'oneWay' : 'twoWay'}`)}
              </Text>
            </GridColumn>

            {/* Service details section */}
            <GridColumn size="12/12" padding="no" margin="sMedium no no no">
              <Separator color="gray" margin="no no small no" />
              <RouteMapFiltersTitle>{translate('customers.streetNetwork.serviceDetails')}</RouteMapFiltersTitle>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="sMedium">
                {translate(
                  isSnowPlowRoute
                    ? 'customers.streetNetwork.forwardPassesSnow'
                    : 'customers.streetNetwork.forwardPassesSweeper',
                )}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={forwardPasses || 0} singleLine>
                {forwardPasses || 0}
              </Text>
            </GridColumn>
            <GridColumn size="6/12" padding="no" margin="small no no no">
              <Text block weight="medium" size="sMedium">
                {translate(
                  isSnowPlowRoute
                    ? 'customers.streetNetwork.reversePassesSnow'
                    : 'customers.streetNetwork.reversePassesSweeper',
                )}
              </Text>
              <Text block margin="xxSmall no no no" size="medium" title={reversePasses || 0} singleLine>
                {reversePasses || 0}
              </Text>
            </GridColumn>

            {routeSegment.pickupStatusTypeName && !isRouteTemplate && (isSnowPlowRoute || isStreetSweeperRoute) && (
              <>
                <GridColumn size="12/12" padding="no" margin="sMedium no no no">
                  <Separator color="gray" margin="no no small no" />
                  <RouteMapFiltersTitle>{translate('customers.streetNetwork.serviceHistory')}</RouteMapFiltersTitle>
                </GridColumn>
                <GridColumn size="6/12" padding="no" margin="small no no no">
                  <Text block weight="medium" size="sMedium">
                    {translate('customers.streetNetwork.segmentStatus')}
                  </Text>
                  <Text
                    block
                    margin="xxSmall no no no"
                    size="medium"
                    title={routeSegment.pickupStatusTypeName || '-'}
                    singleLine
                  >
                    {routeSegment.pickupStatusTypeName || '-'}
                  </Text>
                </GridColumn>
              </>
            )}

            {isDailyRoute && (
              <>
                <GridColumn size="6/12" padding="no" margin="small no no no">
                  <Text block weight="medium" size="sMedium">
                    {translate('customers.streetNetwork.lastPassDate')}
                  </Text>
                  <Text
                    block
                    margin="xxSmall no no no"
                    size="medium"
                    title={routeSegment.lastPassDateTime ? dateAndTime(routeSegment.lastPassDateTime) : '-'}
                    singleLine
                  >
                    {routeSegment.lastPassDateTime ? dateAndTime(routeSegment.lastPassDateTime) : '-'}
                  </Text>
                </GridColumn>

                <GridColumn size="6/12" padding="no" margin="small no no no">
                  <Text block weight="medium" size="sMedium">
                    {translate('customers.streetNetwork.lastPassVehicle')}
                  </Text>
                  <Text
                    block
                    margin="xxSmall no no no"
                    size="medium"
                    title={routeSegment.vehicleName || '-'}
                    singleLine
                  >
                    {routeSegment.vehicleName || '-'}
                  </Text>
                </GridColumn>

                <GridColumn size="6/12" padding="no" margin="small no no no">
                  <Text block weight="medium" size="sMedium">
                    {translate('customers.streetNetwork.lastPassDriver')}
                  </Text>
                  <Text block margin="xxSmall no no no" size="medium" title={routeSegment.driverName || '-'} singleLine>
                    {routeSegment.driverName || '-'}
                  </Text>
                </GridColumn>
              </>
            )}
          </Grid>
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
