import { mapKeys } from 'lodash-es';

import accident from 'src/common/assets/img/cityAlerts/accident.png';
import icyBridge from 'src/common/assets/img/cityAlerts/icyBridge.png';
import metalPlates from 'src/common/assets/img/cityAlerts/metalPlates.png';
import slickSpot from 'src/common/assets/img/cityAlerts/slickSpot.png';
import snowDrift from 'src/common/assets/img/cityAlerts/snowDrift.png';
import speedBump from 'src/common/assets/img/cityAlerts/speedBump.png';
import translate from '../../core/services/translate';

export const CITY_ALERT_TYPE_ID = 1;
export const LOCATION_ALERT_TYPE_ID = 2;

export const NEW_LOCATION_ALERT_ID = -1;

export const ALERT_STATUS_ACTIVE = 1;
export const ALERT_STATUS_INACTVE = 2;
export const ALERT_STATUS_EXPIRED = 3;

export const ALERT_STATUS_TYPES = mapKeys(
  [
    { id: ALERT_STATUS_ACTIVE, name: translate('common.active') },
    { id: ALERT_STATUS_INACTVE, name: translate('common.inactive') },
    { id: ALERT_STATUS_EXPIRED, name: translate('common.expired') },
  ],
  'id',
);

export const CITY_ALERT_ACCIDENT_ID = 1;
export const CITY_ALERT_ICY_BRIDGE_ID = 2;
export const CITY_ALERT_SNOW_DRIFT_ID = 3;
export const CITY_ALERT_SLICK_SPOT_ID = 4;
export const CITY_ALERT_METAL_PLATES_ID = 5;
export const CITY_ALERT_SPEED_BUMP_ID = 6;

export const CITY_ALERT_TYPES = mapKeys(
  [
    { id: CITY_ALERT_ACCIDENT_ID, name: translate('vendors.cityAlerts.accident'), iconUrl: accident },
    { id: CITY_ALERT_ICY_BRIDGE_ID, name: translate('vendors.cityAlerts.icyBridge'), iconUrl: icyBridge },
    { id: CITY_ALERT_SNOW_DRIFT_ID, name: translate('vendors.cityAlerts.snowDrift'), iconUrl: snowDrift },
    { id: CITY_ALERT_SLICK_SPOT_ID, name: translate('vendors.cityAlerts.slickSpot'), iconUrl: slickSpot },
    { id: CITY_ALERT_METAL_PLATES_ID, name: translate('vendors.cityAlerts.metalPlates'), iconUrl: metalPlates },
    { id: CITY_ALERT_SPEED_BUMP_ID, name: translate('vendors.cityAlerts.speedBump'), iconUrl: speedBump },
  ],
  'id',
);

export const LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE = 'locationAndCityAlertsClustersSource';
export const LOCATION_AND_CITY_ALERTS_CLUSTERS_COUNTERS_LAYER = 'locationAndCityAlertsClustersCountersLayer';
export const LOCATION_AND_CITY_ALERTS_CLUSTERS_LAYER = 'locationAndCityAlertsClustersLayer';
export const LOCATION_AND_CITY_ALERTS_LAYER = 'locationAndCityAlertsLayer';
export const LOCATION_AND_CITY_ALERTS_HEATMAP_SOURCE = 'locationAndCityAlertsHeatmapSource';
export const LOCATION_AND_CITY_ALERTS_HEATMAP = 'locationAndCityAlertsHeatmap';

export const ROUTE_CITY_ALERTS = 1;
export const ALL_CITY_ALERTS = 2;

export const ALERT_TYPES = mapKeys(
  [
    { id: ROUTE_CITY_ALERTS, name: translate('vendors.cityAlerts.routeCityAlerts') },
    { id: ALL_CITY_ALERTS, name: translate('vendors.cityAlerts.allCityAlerts') },
  ],
  'id',
);
