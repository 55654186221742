import { GeoJSONSource } from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { getLocationAndCityAlertGeoJSON } from './utils';
import { getFeatureCollection } from 'src/common/components/map/util';
import {
  LOCATION_AND_CITY_ALERTS_CLUSTERS_LAYER,
  LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE,
} from 'src/fleet/constants/locationAndCityAlerts';
import { LocationAndCityAlertsForMap } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { setLocationAndCityAlertsMapViewport } from 'src/fleet/ducks';
import LocationAndCityAlertsClustersGLSource from './LocationAndCityAlertsClustersGLSource';

type Props = {
  map: mapboxgl.Map;
  locationAndCityAlertsForMap: LocationAndCityAlertsForMap[];
};

export default function LocationAndCityAlertsClustersGL({ map, locationAndCityAlertsForMap }: Props) {
  const dispatch = useDispatch();

  const geoJson = useMemo(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (locationAndCityAlertsForMap.length) {
      const locationAndCityAlertsForMapCollection = getLocationAndCityAlertGeoJSON(locationAndCityAlertsForMap);
      collection.features = collection.features.concat(locationAndCityAlertsForMapCollection.features);
    }
    return collection;
  }, [locationAndCityAlertsForMap]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', LOCATION_AND_CITY_ALERTS_CLUSTERS_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [LOCATION_AND_CITY_ALERTS_CLUSTERS_LAYER],
          })
          .filter(feature => feature.source === LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(LOCATION_AND_CITY_ALERTS_CLUSTERS_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setLocationAndCityAlertsMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <LocationAndCityAlertsClustersGLSource geoJSON={geoJson} />;
}
