import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { ChangePasswordForm } from '../forms';
import { Modal } from '../../../core/components';
import { ModalTitle } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  isSaving: boolean;
  closeModal(pristine: boolean): void;
  onSubmit(formData: any): void;
}

const ChangePasswordModal: React.FC<Props> = ({ isSaving, closeModal, onSubmit }) => {
  return (
    <Modal padding="medium" size="small">
      <ModalTitle>{translate('account.changePassword')}</ModalTitle>
      <ChangePasswordForm onSubmit={onSubmit} onCancel={closeModal} />
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isSaving: state.customers.customer.isSaving,
});

export default withRouter(connect(mapStateToProps)(ChangePasswordModal));
