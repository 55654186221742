import React from 'react';
import { Field, reduxForm, FieldArray, WrappedFieldArrayProps, InjectedFormProps } from 'redux-form';

import { Button, Grid, GridColumn, PanelSectionSubTitle } from '../../../core/components/styled';
import { DatePicker, Dropdown, TextArea, Input, Checkbox, ActionButtonTooltip } from '../../../core/components';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { RELEASE_TYPES } from '../../constants/releaseTypes';
import {
  ReleaseItemsContainer,
  ReleaseItemContainer,
  RemoveReleaseItemContainer,
  ReleaseItemFieldsContainer,
  AddReleaseItemContainer,
} from '../styled/ReleaseNotes';
import confirm from '../../../core/services/confirm';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

const ReleaseNoteItemsFieldArray: React.FC<WrappedFieldArrayProps> = ({ fields }) => {
  return (
    <ReleaseItemsContainer name="items">
      <PanelSectionSubTitle>{translate('versioning.releaseItems')}</PanelSectionSubTitle>
      {fields.map((field, index) => (
        <ReleaseItemContainer key={index}>
          <RemoveReleaseItemContainer>
            <Button
              disabled={fields.length === 1}
              padding="no"
              text
              type="button"
              color="grayDark"
              onClick={async () => {
                if (!(await confirm(translate('versioning.alerts.removeItemConfirmation')))) {
                  return;
                }
                fields.remove(index);
              }}
            >
              <ActionButtonTooltip
                icon="delete"
                tooltip={
                  fields.length === 1
                    ? translate('versioning.cantDeleteAllReleaseItems')
                    : translate('versioning.removeReleaseItem')
                }
                tooltipAsString
              />
            </Button>
          </RemoveReleaseItemContainer>
          <ReleaseItemFieldsContainer>
            <Field
              name={`${field}.title`}
              validate={[isRequired]}
              component={Input}
              label={translate('versioning.title')}
            />
            <Field
              name={`${field}.description`}
              component={TextArea}
              maxLength={2000}
              rows="5"
              label={translate('common.description')}
            />
          </ReleaseItemFieldsContainer>
        </ReleaseItemContainer>
      ))}
      <AddReleaseItemContainer>
        <Button
          onClick={() => {
            fields.push({});
          }}
          type="button"
          color="primary"
        >
          + {translate('versioning.addReleaseItem')}
        </Button>
      </AddReleaseItemContainer>
    </ReleaseItemsContainer>
  );
};

const ReleaseNotesEditorForm: React.FC<InjectedFormProps<any, {}>> = ({ handleSubmit, pristine }) => (
  <form onSubmit={handleSubmit}>
    <Grid multiLine>
      <GridColumn size="6/12">
        <Field
          name="releaseDate"
          component={DatePicker}
          label={translate('versioning.releaseDate')}
          validate={[isRequired, isDateValidValidator]}
        />
      </GridColumn>
      <GridColumn size="6/12">
        <Field
          name="publishDate"
          component={DatePicker}
          label={translate('versioning.publishDate')}
          isClearable
          validate={[isDateValidValidator]}
        />
      </GridColumn>
      <GridColumn size="6/12">
        <Field name="iosAppVersion" component={Input} label={translate('versioning.iosAppVersion')} isClearable />
      </GridColumn>
      <GridColumn size="6/12">
        <Field
          name="androidAppVersion"
          component={Input}
          label={translate('versioning.androidAppVersion')}
          isClearable
        />
      </GridColumn>
      <GridColumn size="6/12">
        <Field
          name="releaseType"
          label={translate('versioning.releaseType')}
          component={Dropdown}
          options={RELEASE_TYPES}
          validate={[isRequired]}
        />
      </GridColumn>
      <GridColumn verticalAlign="center" size="6/12">
        <Field name="notifyUsers" component={Checkbox} label={translate('versioning.notifyUsers')} />
      </GridColumn>
      <GridColumn size="12/12">
        <FieldArray validate={[isRequired]} name="items" component={ReleaseNoteItemsFieldArray} props={{}} />
      </GridColumn>
      <GridColumn align="center" size="12/12">
        <Button disabled={pristine} type="submit" color="primary" margin="medium no no no" id="save-release-notes">
          {translate('common.save')}
        </Button>
      </GridColumn>
    </Grid>
  </form>
);

export const releaseNotesEditorFormKey = 'releaseNotesEditorForm';

export default reduxForm<any, {}>({
  onSubmitFail: focusFirstInvalidField,
  form: releaseNotesEditorFormKey,
})(ReleaseNotesEditorForm);
