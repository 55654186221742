import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const SILVER_ID = 1;
export const GOLD_ID = 2;
export const PLATINUM_ID = 3;

export const SUBSCRIPTION_PACKAGE_TYPES = mapKeys(
  [
    { id: SILVER_ID, name: translate('vendors.subscriptionPackageTypes.silver') },
    { id: GOLD_ID, name: translate('vendors.subscriptionPackageTypes.gold') },
    { id: PLATINUM_ID, name: translate('vendors.subscriptionPackageTypes.platinum') },
  ],
  'id',
);
