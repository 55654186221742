import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { AnyAction, Dispatch } from 'redux';

import doLoadStreetSweeper from '../services/loadStreetSweeper';

// Actions
const START_LOAD = 'dashboard/streetSweeper/START_LOAD';
export const COMPLETE_LOAD = 'dashboard/streetSweeper/COMPLETE_LOAD';
const FAIL_LOAD = 'dashboard/streetSweeper/FAIL_LOAD';
const RESET = 'dashboard/streetSweeper/RESET';

// Initial state
const initialState = {
  isLoading: false,
  streetSweeper: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSweeper: action.streetSweeper,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSweeper: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSweeper: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (streetSweeper: any) => ({
  type: COMPLETE_LOAD,
  streetSweeper,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadStreetSweeper =
  (vendorId: number, date: Date | string, streetSweeperVehicleIds: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadStreetSweeper(vendorId, date, streetSweeperVehicleIds)
      .then(vehicleTrackings => dispatch(completeLoad(vehicleTrackings)))
      .catch(() => dispatch(failLoad()));
  };

export const resetStreetSweeper = () => ({
  type: RESET,
});

// Selectors
const getStreetSweeperTrackings = (streetSweeperState: any) => get(streetSweeperState, 'coordinateGroups') || [];
export const streetSweeperTrackingsSelector = createSelector(getStreetSweeperTrackings, identity);

const getServiceExceptions = (streetSweeperState: any) => get(streetSweeperState, 'serviceExceptions') || [];
export const serviceExceptionsSelector = createSelector(getServiceExceptions, identity);

const getStreetSweeper = (streetSweeperState: any) => {
  const vehicleName = get(streetSweeperState, 'vehicle.name', undefined);
  const unitOfMeasure = get(streetSweeperState, 'unitOfMeasure', '');
  return {
    vehicleName,
    unitOfMeasure,
  };
};
export const streetSweeperSelector = createSelector(getStreetSweeper, identity);
