import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  exportReportingFautlCodeDetails as doExportReportingFaultCodeDetails,
  loadReportingFaultCodeDetails as doLoadReportingFaultCodeDetails,
} from '../services/reportingFaultCodeDetails';

// Actions
const START_LOAD = 'insights/reportingFaultCodeDetails/START_LOAD';
const COMPLETE_LOAD = 'insights/reportingFaultCodeDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/reportingFaultCodeDetails/FAIL_LOAD';
const START_EXPORT = 'settings/reportingFaultCodeDetails/START_EXPORT';
const COMPLETE_EXPORT = 'settings/reportingFaultCodeDetails/COMPLETE_EXPORT';
const FAIL_EXPORT = 'settings/reportingFaultCodeDetails/FAIL_EXPORT';
const RESET = 'insights/reportingFaultCodeDetails/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isExporting: false,
  reportingFaultCodeDetails: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          reportingFaultCodeDetails: action.reportingFaultCodeDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (reportingFaultCodeDetails: any) => ({
  type: COMPLETE_LOAD,
  reportingFaultCodeDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const loadReportingFaultCodeDetails = (
  vendorId: number,
  date: Date | string,
  vehicleId: number,
  vehicleName: string,
) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadReportingFaultCodeDetailsPromise = doLoadReportingFaultCodeDetails(vendorId, date, vehicleId, vehicleName);
  loadReportingFaultCodeDetailsPromise
    .then(reportingFaultCodeDetails => dispatch(completeLoad(reportingFaultCodeDetails)))
    .catch(() => dispatch(failLoad()));
  return loadReportingFaultCodeDetailsPromise;
};

export const exportReportingFaultCodeDetails = (
  vendorId: number,
  date: Date | string,
  vehicleId: number,
  vehicleName: string,
) => (dispatch: Dispatch) => {
  dispatch(startExport());
  const exportReportingFaultCodeDetailsPromise = doExportReportingFaultCodeDetails(
    vendorId,
    date,
    vehicleId,
    vehicleName,
  );
  exportReportingFaultCodeDetailsPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
  return exportReportingFaultCodeDetailsPromise;
};

export const resetReportingFaultCodeDetails = () => ({
  type: RESET,
});
