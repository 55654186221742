import { map, pick } from 'lodash-es';

import { FORWARD_VIEW_ID, HOPPER_VIEW_ID, VIDEO_DEVICE_SURFSIGHT_TYPE_ID } from 'src/routes/constants';
import { Modal } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import VideoRequestForm, { VideoRequestFormValues } from './VideoRequestForm';
import { CameraConfigurations } from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestForm';
import { createErrorNotification } from 'src/core/services/createNotification';
interface Props {
  closeModal: () => void;
  initialValues: VideoRequestFormValues;
  onSubmit: (formValues: VideoRequestFormValues) => void;
}

const VideoRequestModal = ({ onSubmit, closeModal, initialValues }: Props) => {
  const { isSaving } = useSelector(state => state.routes.videoRequest);
  const cameraConfigOptions = useSelector(state => state.routes.videoRequest.cameraConfigurations);

  const rawCameraConfigOptions = cameraConfigOptions?.filter(
    (option: CameraConfigurations) =>
      option.vehicleCameraConfigurationTypeId === FORWARD_VIEW_ID ||
      option.vehicleCameraConfigurationTypeId === HOPPER_VIEW_ID,
  );

  const cameraConfigurations = map(rawCameraConfigOptions, (option: CameraConfigurations) =>
    pick(option, ['id', 'isActive', 'displayName', 'vehicleCameraConfigurationTypeId']),
  );

  initialValues.cameras = cameraConfigurations;

  const onVideoRequestFormSubmit = (formValues: VideoRequestFormValues) => {
    const selectedCamears = formValues.cameras?.filter(camera => camera.isActive);

    if (initialValues?.videoDeviceTypeId !== VIDEO_DEVICE_SURFSIGHT_TYPE_ID && selectedCamears?.length === 0 ){
      createErrorNotification(translate('routes.videoRequest.isRequiredCameraConfiguration'));
      return;
    }

    onSubmit(formValues);
  };

  return (
    <Modal
      title={translate('routes.videoRequest.videoRequest')}
      onClose={closeModal}
      padding="medium medium no medium"
      size="medium"
      isLoading={isSaving}
    >
      <VideoRequestForm onSubmit={onVideoRequestFormSubmit} closeModal={closeModal} initialValues={initialValues} />
    </Modal>
  );
};

export default VideoRequestModal;
