import {
  DashboardFilterDetailScreenBellowContent,
  DashboardFilterDetailScreenDataContainer,
  DashboardFilterDetailScreenLabel,
  DashboardFilterDetailScreenValue,
} from '../../styled/DashboardFilterMapbox';

interface DashboardFilterDetailScreenLabelValueProps {
  label: string;
  value: string | number | null;
  bellowContent?: React.ReactNode;
  labelFixedWidth?: string;
  fullWidth?: boolean;
  leftMargin?: boolean;
  rightContent?: React.ReactNode;
}

const DashboardFilterDetailScreenLabelValue = ({
  labelFixedWidth,
  fullWidth = false,
  label,
  leftMargin,
  rightContent,
  bellowContent,
  value,
}: DashboardFilterDetailScreenLabelValueProps) => {
  const valueToDisplay = value ?? 'N/A';

  return (
    <>
      <DashboardFilterDetailScreenDataContainer
        hasBellowContent={!!bellowContent}
        fullWidth={fullWidth}
        leftMargin={leftMargin}
        labelFixedWidth={labelFixedWidth}
      >
        <DashboardFilterDetailScreenLabel title={label}>{label}</DashboardFilterDetailScreenLabel>
        <DashboardFilterDetailScreenValue color={value || value === 0 ? 'grayDarkest' : 'grayDark'}>
          {valueToDisplay}
        </DashboardFilterDetailScreenValue>
        {rightContent}
      </DashboardFilterDetailScreenDataContainer>
      {bellowContent && (
        <DashboardFilterDetailScreenBellowContent leftMargin={leftMargin}>
          {bellowContent}
        </DashboardFilterDetailScreenBellowContent>
      )}
    </>
  );
};

export default DashboardFilterDetailScreenLabelValue;
