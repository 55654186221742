import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { getFeatureCollection } from 'src/common/components/map/util';
import { SERVICE_AREAS_LAYER_ID, SERVICE_AREAS_SOURCE_ID } from 'src/routes/constants';
import { setServiceAreasSelectedFeature } from '../ducks/serviceAreas';
import ServiceAreasGLPopup from './ServiceAreasGLPopup';
import ServiceAreasGLSource from './ServiceAreasGLSource';

type Props = {
  equipmentSizes?: string[];
  equipmentTypes: string;
  handleSelectFeature: () => void;
  isSatellite: boolean;
  isServiceAreaEditorModalOpen?: boolean;
  isSourceServiceAreasPage?: boolean;
  map: mapboxgl.Map;
  serviceAreasGeoJSON: GeoJSON.Feature<GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties>[];
};

export default function ServiceAreasGL({
  equipmentSizes,
  equipmentTypes,
  handleSelectFeature,
  isSatellite,
  isServiceAreaEditorModalOpen,
  isSourceServiceAreasPage,
  map,
  serviceAreasGeoJSON,
}: Props) {
  const dispatch = useDispatch();
  const geoJSON = getFeatureCollection(serviceAreasGeoJSON);

  const [mapZoom, setMapZoom] = useState(map.getZoom());

  useEffect(() => {
    map?.once('load', () => {
      map.on('zoom', () => {
        setMapZoom(map.getZoom());
      });
    });
  }, [map, dispatch]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === SERVICE_AREAS_SOURCE_ID && feature.layer.id === SERVICE_AREAS_LAYER_ID);

        if (feature) {
          dispatch(
            setServiceAreasSelectedFeature('serviceAreas', feature.properties?.zip as number, {
              coordinates: { lng: event.lngLat.lng, lat: event.lngLat.lat },
            }),
          );

          handleSelectFeature();
        }
      });
    });
  }, [dispatch, map, serviceAreasGeoJSON, handleSelectFeature, geoJSON]);

  return (
    <>
      <ServiceAreasGLSource geoJSON={geoJSON} isSatellite={isSatellite} mapZoom={mapZoom} />
      {((isSourceServiceAreasPage && !isServiceAreaEditorModalOpen) ||
        (!isSourceServiceAreasPage && isServiceAreaEditorModalOpen)) && (
        <ServiceAreasGLPopup equipmentTypes={equipmentTypes} equipmentSizes={equipmentSizes} />
      )}
    </>
  );
}
