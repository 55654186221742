import styled from 'styled-components';

import {
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { Grid, GridColumn, Panel, PanelSection, PanelSectionTitle } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

const ExternalLink = styled.a`
  color: ${p => p.theme.brandPrimary};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const StripeOnboardingPage = () => {
  const { stripeAccountStatus, stripeAccountLink } = useSelector(state => state.vendors.stripeAccount);
  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer fluid>
            <PageBackButton id="back-button" to="/finance/billing">
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle margin="small">{translate('finance.stripeIntegration.stripeOnboarding')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      <Panel margin="no no xLarge" padding="medium" isLoading={true}>
        <PanelSectionTitle>{translate('finance.stripeIntegration.needAdditionalInfo')}</PanelSectionTitle>
        <PanelSection padding="no" vertical>
          <Grid padding="no" multiLine>
            {!!stripeAccountLink ? (
              <>
                <GridColumn size="12/12" padding="no no xSmall">
                  {translate('finance.stripeIntegration.enterDetails')}
                </GridColumn>
                <GridColumn size="12/12" padding="xSmall no no">
                  <ExternalLink href={stripeAccountLink.link}>
                    {translate(
                      `finance.stripeIntegration.${
                        !stripeAccountStatus?.isActive ? 'additionalInformation' : 'startOnboarding'
                      }`,
                    )}
                  </ExternalLink>
                </GridColumn>
              </>
            ) : (
              <GridColumn size="12/12" padding="no no xSmall">
                {translate('finance.stripeIntegration.accountActivatedSetUp')}
              </GridColumn>
            )}
          </Grid>
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

export default StripeOnboardingPage;
