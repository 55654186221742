import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { loadWasteTypes } from 'src/common/ducks';

import { AppState } from '../../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { DuckFunction } from '../../../../contracts/ducks';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadDisposalsSummary } from '../../../ducks';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { PageLoading } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import RouteDisposalsSummaryPage from './RouteDisposalsSummaryPage';
import translate from '../../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadDisposalsSummary: DuckFunction<typeof loadDisposalsSummary>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  vendorId: number;
  userId: string;
}

class RouteDisposalsSummaryPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      loadDisposalsSummary,
      loadVehicleTypesForVendor,
      loadWasteTypes,
      loadFiltersSettings,
      location: { search },
      vendorId,
      userId,
    } = this.props;

    const { searchTerm, vehicleTypeIds, routeStatusTypeIds, startDate, endDate } = getQueryParams(search);
    const promises = [
      loadDisposalsSummary(
        vendorId,
        searchTerm,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        !vehicleTypeIds,
      ),
      loadFiltersSettings(vendorId, userId),
      loadVehicleTypesForVendor(vendorId),
      loadWasteTypes(),
    ];
    return Promise.all(promises);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.tracker')}</DocumentTitle>
        <TypedResolver
          successComponent={RouteDisposalsSummaryPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/dashboard"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login) as any,
});

const mapDispatchToProps = {
  loadDisposalsSummary,
  loadVehicleTypesForVendor,
  loadWasteTypes,
  loadFiltersSettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteDisposalsSummaryPageResolver));
