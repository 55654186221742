import { identity, get, find } from 'lodash-es';
import { createSelector } from 'reselect';

const getUserById = (usersState: any, userId: number) => {
  const user = find(get(usersState, 'users', {}), { userId });

  return {
    isAccountActive: true,
    ...user,
    languageId: (user && user.languageId) || 1,
    userTypeId: (user && user.userTypeId) || -1,
  };
};

const userEditorFormInitialValuesSelector = createSelector(getUserById, identity);

export default userEditorFormInitialValuesSelector;
