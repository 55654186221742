import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadPickupFrequencyTypes, loadServiceTypes, loadWasteTypes } from '../../../common/ducks';
import { loadRouteImportDetails } from '../../ducks';
import { loadVehicleTypes } from '../../../fleet/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import RouteImportDetailsPage from './RouteImportDetailsPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadVehicleTypes: DuckFunction<typeof loadVehicleTypes>;
  loadPickupFrequencyTypes: DuckFunction<typeof loadPickupFrequencyTypes>;
  loadServiceTypes: DuckFunction<typeof loadServiceTypes>;
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  loadRouteImportDetails: DuckFunction<typeof loadRouteImportDetails>;
}

class RouteImportDetailsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      location: { search },
      loadPickupFrequencyTypes,
      loadServiceTypes,
      loadWasteTypes,
      loadVehicleTypes,
      loadRouteImportDetails,
    } = this.props;
    const { importDetailId } = getQueryParams(search);

    return Promise.all([
      loadPickupFrequencyTypes(),
      loadServiceTypes(),
      loadWasteTypes(),
      loadVehicleTypes(),
      loadRouteImportDetails(importDetailId),
    ]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routeImport.routeImportDetails')}</DocumentTitle>
        <Resolver
          successComponent={RouteImportDetailsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/route-import"
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  loadVehicleTypes,
  loadPickupFrequencyTypes,
  loadServiceTypes,
  loadWasteTypes,
  loadRouteImportDetails,
};

export default connect(
  undefined,
  mapDispatchToProps,
)(RouteImportDetailsPageResolver);
