import { AnyAction } from 'redux';
import { findIndex } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadUpcomingServices as doLoadUpcomingServices,
  deleteUpcomingService as doDeleteUpcomingService,
} from '../services/upcomingServices';
import { ServiceHistory } from '../interfaces/ServiceHistory';

// Actions
const START_LOAD = 'customers/upcomingServices/START_LOAD';
const COMPLETE_LOAD = 'customers/upcomingServices/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/upcomingServices/FAIL_LOAD';
const RESET = 'customers/upcomingServices/RESET';
const START_DELETE = 'customers/upcomingServices/START_DELETE';
const COMPLETE_DELETE = 'customers/upcomingServices/COMPLETE_DELETE';
const FAIL_DELETE = 'customers/upcomingServices/FAIL_DELETE';

interface State {
  isDeleting: boolean;
  isLoading: boolean;
  upcomingServices: ServiceHistory[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isDeleting: false,
  isLoading: false,
  upcomingServices: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          upcomingServices: action.upcomingServices,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          upcomingServices: [],
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const index = findIndex(state.upcomingServices, { routeLocationId: action.routeLocationId });
      return update(state, {
        upcomingServices: { $splice: [[index, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (upcomingServices: ServiceHistory[]) => ({
  type: COMPLETE_LOAD,
  upcomingServices,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (routeLocationId: number) => ({
  type: COMPLETE_DELETE,
  routeLocationId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadUpcomingServices =
  (
    customerId: number,
    locationId: number,
    serviceContractIds: string | undefined,
    pickupStatusTypeIds: string | undefined,
    jobPriorityTypeIds: string | undefined,
    startDate: Date | string,
    endDate: Date | string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadUpcomingServicesPromise = doLoadUpcomingServices(
      customerId,
      locationId,
      serviceContractIds,
      pickupStatusTypeIds,
      jobPriorityTypeIds,
      startDate,
      endDate,
    );
    loadUpcomingServicesPromise
      .then(upcomingServices => dispatch(completeLoad(upcomingServices)))
      .catch(() => dispatch(failLoad()));
    return loadUpcomingServicesPromise;
  };

export const deleteUpcomingService = (routeLocationId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteUpcomingServicePromise = doDeleteUpcomingService(routeLocationId);
  deleteUpcomingServicePromise
    .then(() => dispatch(completeDelete(routeLocationId)))
    .catch(() => dispatch(failDelete()));
  return deleteUpcomingServicePromise;
};

export const resetUpcomingServices = () => ({
  type: RESET,
});
