import { RouteTemplateBuilderFilters } from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderFilters';
import {
  RouteTemplateBuilderServicesResponse,
  RouteTemplateBuilderServicesResponseDTO,
  RouteTemplateBuilderDraft,
  RouteTemplateBuilderServiceContractDetails,
  ServiceContractExtraData,
  ServicesTotalsCounts,
} from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { RouteTemplateBuilderWorkSession } from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderWorkSession';
import {
  transformRouteTemplateBuilderServices,
  transformRouteTemplateBuilderDaysOfService,
} from './transformRouteTemplateBuilder';
import { http } from '../../core/services/http';
import { WorkSessionChangeLog } from '../interfaces/routeTemplateBuilder/WorkSessionChangeLog';
import { RouteTemplateBuilderOption } from '../interfaces/routeTemplateBuilder/RouteTemplateOptions';
import moment from 'moment';
import { Dictionary } from 'src/common/interfaces/Dictionary';

export const loadRouteTemplateBuilderWorkSessions = (vendorId: number) =>
  http
    .get('routeTemplatePlanner/workSessions', {
      params: { vendorId },
    })
    .then(response => response.data);

export const initializeRouteTemplateBuilderWorkSession = (vendorId: number, workSessionName: string) =>
  http
    .post<number>('routeTemplatePlanner/workSession', { vendorId, name: workSessionName })
    .then(response => response.data);

export const loadRouteTemplateBuilderWorkSession = (workSessionId: number) =>
  http
    .get<RouteTemplateBuilderWorkSession>(`routeTemplatePlanner/workSession/${workSessionId}`)
    .then(response => response.data);

export const updateRouteTemplateBuilderWorkSession = (workSession: RouteTemplateBuilderWorkSession) =>
  http.put(`routeTemplatePlanner/workSession`, workSession).then(response => response.data);

export const getNumberOfContainersByDayOfService = (
  vendorId: number,
  workSessionId: number,
  vehicleTypeId: number,
  serviceZoneId: number | null,
  wasteMaterialTypeId?: number,
  groupIds?: number[],
) =>
  http
    .get<RouteTemplateBuilderFilters>(`routeTemplatePlanner/numberOfContainers`, {
      params: {
        vendorId,
        workSessionId,
        vehicleTypeId,
        wasteMaterialTypeId: wasteMaterialTypeId || undefined,
        groupIds: groupIds?.join(',') || undefined,
        serviceZoneId,
      },
    })
    .then(response => response.data);

export const loadRouteTemplateBuilderFilters = (
  vendorId: number,
  workSessionId: number,
  vehicleTypeId: number,
  serviceZoneId: number | null,
  wasteMaterialTypeId?: number,
  groupIds?: number[],
) =>
  http
    .get<RouteTemplateBuilderFilters>('routeTemplatePlanner/filters', {
      params: {
        vendorId,
        workSessionId,
        vehicleTypeId,
        wasteMaterialTypeId: wasteMaterialTypeId || undefined,
        groupIds: groupIds?.join(',') || undefined,
        serviceZoneId,
      },
    })
    .then(response => response.data);

export const loadRouteTemplateBuilderServices = (
  vendorId: number,
  workSessionId: number,
  vehicleTypeId: number,
  wasteMaterialTypeId: number,
  serviceZoneId: number | null,
  groupIds?: number[],
): Promise<RouteTemplateBuilderServicesResponse> =>
  http
    .get<RouteTemplateBuilderServicesResponseDTO>('routeTemplatePlanner/serviceContracts/map', {
      params: {
        vendorId,
        workSessionId,
        vehicleTypeId,
        wasteMaterialTypeId: wasteMaterialTypeId || undefined,
        groupIds: groupIds?.join(',') || undefined,
        serviceZoneId,
      },
    })
    .then(({ data: { daysOfService, serviceContracts } }) => {
      const routeTemplatesDictionary: Dictionary<string> = {};
      const transformedDaysOfService = transformRouteTemplateBuilderDaysOfService(daysOfService);
      transformedDaysOfService.forEach(d =>
        d.routeTemplates.forEach(rt => (routeTemplatesDictionary[rt.id] = rt.name)),
      );
      return {
        routeTemplatesDictionary,
        daysOfService: transformRouteTemplateBuilderDaysOfService(daysOfService),
        serviceContracts: transformRouteTemplateBuilderServices(serviceContracts, routeTemplatesDictionary),
      };
    });

export const loadRouteTemplateBuilderWorkSessionChangeLog = (workSessionId: number) =>
  http
    .get<WorkSessionChangeLog[]>(`routeTemplatePlanner/workSession/${workSessionId}/changeLogs`)
    .then(response => response.data);

export const loadRouteTemplateDraftServices = (draftId: number, changeLogId?: number) =>
  http
    .get<RouteTemplateBuilderDraft>(`routeTemplatePlanner/workSession/routeTemplate/${draftId}`, {
      params: { changeLogId },
    })
    .then(response => ({
      ...response.data,
      startDate: response.data.startDate ? moment(response.data.startDate).format('L') : response.data.startDate,
    }));

export const loadRouteTemplateOptionsForWorkSession = (
  workSessionId: number,
  vehicleTypeId: number,
  dayOfServiceId: number,
) =>
  http
    .get<RouteTemplateBuilderOption[]>(`routeTemplatePlanner/workSession/${workSessionId}/routeTemplateOptions`, {
      params: {
        vehicleTypeId,
        dayOfService: dayOfServiceId,
      },
    })
    .then(response => response.data);

export const upsertDraftRouteTemplateForWorkSession = (
  workSessionId: number,
  draftId: number | null,
  payload: {
    vehicleTypeId: number;
    wasteMaterialTypeId: number;
    dayOfService: number;
    serviceContracts: { id: number; isDeleted?: boolean }[];
    name: string;
    routeTemplateId?: number;
    vendorServiceZoneId?: unknown;
    routeConfirmationTypeId?: unknown;
    startingLocationId?: number;
    endingLocationId?: number;
    startDate?: string;
  },
  update?: boolean,
) =>
  update
    ? http
        .put(`routeTemplatePlanner/workSession/${workSessionId}/routeTemplate`, payload, {
          params: { draftId },
        })
        .then(response => response.status === 200)
    : http
        .post(`routeTemplatePlanner/workSession/${workSessionId}/routeTemplate`, payload, {
          params: { draftId },
        })
        .then(response => response.status === 200);

export const revertRouteTemplateDraftVersion = (draftRouteTemplateId: number, changeLogId: number) =>
  http.put(`/routeTemplatePlanner/workSession/routeTemplate/${draftRouteTemplateId}/revert/${changeLogId}`);

export const deleteRouteTemplateBuilderWorkSession = (workSessionId: number) =>
  http.delete(`routeTemplatePlanner/workSession/${workSessionId}`).then(response => response.status === 200);

export const deleteWorkSessionRouteTemplateDraft = (draftRouteTemplateId: number) =>
  http.delete(`/routeTemplatePlanner/workSession/routeTemplate/${draftRouteTemplateId}`);

export const publishWorkSessionRouteTemplateDraft = (draftRouteTemplateId: number) =>
  http.put(`/routeTemplatePlanner/workSession/routeTemplate/${draftRouteTemplateId}/publish`);

export const publishWorkSession = (workSessionId: number, updateRouteTrackerTemplateIds?: number[]) =>
  http.put(`routeTemplatePlanner/workSession/${workSessionId}/publish`, updateRouteTrackerTemplateIds);

export const getRouteTemplatesWithTrackerRoutes = (workSessionId: number) =>
  http.get(`routeTemplatePlanner/workSession/${workSessionId}/trackerRoutes`).then(response => response.data);

export const loadServiceContract = (workSessionId: number, serviceContractId: number) =>
  http
    .get<RouteTemplateBuilderServiceContractDetails>(
      `routeTemplatePlanner/workSession/${workSessionId}/serviceContract/${serviceContractId}`,
    )
    .then(response => response.data);

export const loadRouteTemplateBuilderServicesExtraData = (serviceContractIds: number[]) =>
  http
    .post<ServiceContractExtraData[]>(`routeTemplatePlanner/workSession/serviceContracts`, serviceContractIds.join(','))
    .then(response => response.data);

export const loadRouteTemplateBuilderServicesTotalsCounts = (serviceContractIds: number[], cancelToken: any) =>
  http
    .post<ServicesTotalsCounts>(`/routeTemplatePlanner/servicesInfo`, serviceContractIds.join(','), { cancelToken })
    .then(response => response.data);
