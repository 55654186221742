import { find } from 'lodash-es';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';

import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  TRAVEL_PATH_BUILDER_FILTERS_FORM,
  TravelPathBuilderFiltersFormValues,
} from 'src/routes/components/forms/travelPath/TravelPathBuilderFiltersForm';
import { clearRouteMapSelectedFeature, loadRouteVehicleInformation } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import TravelPathVehiclePositionPopup from './TravelPathVehiclePositionPopup';

const TravelPathVehiclePositionPopupResolver: FC = () => {
  const dispatch = useDispatch();

  const filters = useSelector(getFormValues(TRAVEL_PATH_BUILDER_FILTERS_FORM)) as TravelPathBuilderFiltersFormValues;
  const { routeVehiclePositions } = useSelector(state => state.routes.travelPathBuildAndEdit);
  const { selectedFeatureForTravelPath } = useSelector(state => state.routes.mapControls);

  const vehiclePosition = useMemo(() => {
    if (!selectedFeatureForTravelPath || selectedFeatureForTravelPath.feature !== RouteMapFeature.vehiclePositions)
      return undefined;

    return find(routeVehiclePositions, s => s.vehicleId === selectedFeatureForTravelPath.id);
  }, [routeVehiclePositions, selectedFeatureForTravelPath]);

  const loadDependencies = () => {
    if (!selectedFeatureForTravelPath || !vehiclePosition?.vehicleId || !filters?.date) return Promise.reject();

    const promise = loadRouteVehicleInformation(vehiclePosition.vehicleId, filters?.date)(dispatch);

    return Promise.all([promise]);
  };

  if (!vehiclePosition) return null;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={vehiclePosition.latitude}
      longitude={vehiclePosition.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature(true))}
    >
      <TypedResolver
        key={vehiclePosition.vehicleId}
        successComponent={TravelPathVehiclePositionPopup}
        successProps={{ vehiclePosition }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ latitude: vehiclePosition.latitude, longitude: vehiclePosition.longitude }}
        resolve={loadDependencies}
      />
    </MapGLPopupWrapper>
  );
};

export default TravelPathVehiclePositionPopupResolver;
