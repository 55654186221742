import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import * as Services from '../services/reasonCodeTypes';
import { ReasonCodeTypesState } from '../interfaces/ReasonCodeTypes';
import { TechnicalType } from '../interfaces/TechnicalType';

// Actions
const START_LOAD = 'common/reasonCodeTypes/START_LOAD';
const COMPLETE_LOAD = 'common/reasonCodeTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/reasonCodeTypes/FAIL_LOAD';

// Initial state
const initialState: ReasonCodeTypesState = {
  isLoading: false,
  reasonCodeTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          reasonCodeTypes: action.reasonCodeTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (reasonCodeTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  reasonCodeTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadReasonCodeTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const promise = Services.loadReasonCodeTypes();

  promise
    .then(reasonCodeTypes => {
      dispatch(completeLoad(reasonCodeTypes));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};
