import { http } from '../../core/services/http';
import { transformRubiconDispatches } from './transformRubiconDispatches';

export const loadRubiconDispatches = (
  isActionRequired: boolean,
  userId: string,
  vendorId: number,
  startDate?: string,
  endDate?: string,
) =>
  http
    .get(`gus/workorderautodispatch/dispatches/vendor/${vendorId}/user/${userId}`, {
      params: { isActionRequired, startDate, endDate },
    })
    .then(response => transformRubiconDispatches(response.data));

export const loadRubiconDispatchesByToken = (
  isActionRequired: boolean,
  token: string,
  startDate?: string,
  endDate?: string,
) =>
  http
    .get(`gus/workorderautodispatch/dispatches/${token}`, {
      params: { isActionRequired, startDate, endDate },
    })
    .then(response => transformRubiconDispatches(response.data));

export const loadDocuments = (serviceDate: string, userId: string, vendorId: number, workOrder: string) =>
  http
    .get(`gus/vendor/${vendorId}/user/${userId}/documents`, {
      params: { serviceDate, workOrder },
    })
    .then(response => response.data);

export const loadDocumentsByToken = (serviceDate: string, token: string, workOrder: string) =>
  http
    .get(`gus/vendor/${token}/documents`, {
      params: { serviceDate, workOrder },
    })
    .then(response => response.data);

export const loadMPUReasonCodes = () => http.get('gus/missed-pickups/reason-codes').then(response => response.data);
