import React, { PureComponent } from 'react';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';

import { TableCell, TableRow, Text } from '../../core/components/styled';
import {
  InspectionImageContainer,
  InspectionPassedIcon,
  InspectionFailedIcon,
  InspectionAsterisk,
} from '../../fleet/components/styled/VehicleInspections';

interface Props {
  imageUrl: string;
  item: string;
  note: string;
  passed: boolean;
  isRequired: boolean;
  openImagePreviewModal: (imageUrl: string) => void;
}

class InspectionDetailsTableRow extends PureComponent<Props> {
  render() {
    const { item, note, passed, imageUrl, isRequired, openImagePreviewModal } = this.props;
    return (
      <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
        <TableCell width={'35%'}>
          <Text block title={item} weight="light" margin="no no xxSmall" singleLine>
            {item}
          </Text>

          {isRequired && <InspectionAsterisk />}
        </TableCell>

        <TableCell width={'10%'}>{passed ? <InspectionPassedIcon /> : <InspectionFailedIcon />}</TableCell>

        <TableCell width={'15%'}>
          {imageUrl && <InspectionImageContainer src={imageUrl} onClick={() => openImagePreviewModal(imageUrl)} />}
        </TableCell>

        <TableCell width={'40%'}>
          <Text block weight="light" margin="no no xxSmall">
            {note}
          </Text>
        </TableCell>
      </TableRow>
    );
  }
}

export default InspectionDetailsTableRow;
