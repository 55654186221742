import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { loadingOverlay } from 'src/core/styles'

interface Props {
  isLoading?: boolean;
}

export const ExpandableDetailsContainer = styled.div<Props>`
  display: flex;
  overflow-y: auto;
  border-bottom: 1px solid ${p => p.theme.brandSecondary};
  padding: 10px;
  background-color: ${p => transparentize(0.7)(p.theme.brandSecondary)};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px', '#fff', 0.05, 2300)};
    `};
`;
