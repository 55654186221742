import { isPristine } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import { saveSupervisorExperienceSettings, saveDriverSafetyInspectionSettings } from '../../ducks';
import { SupervisorExperienceSettings } from '../../interfaces/SupervisorExperienceSettings';
import driverSafetyInspectionSettingsFormInitialValuesSelector from 'src/vendors/services/driverSafetyInspectionSettingsFormInitialValuesSelector';
import SupervisorExperienceSettingsForm from '../forms/SupervisorExperienceSettingsForm';
import translate from '../../../core/services/translate';

type Props = {
  closeModal: (pristine?: boolean) => void;
  vendorId: number;
};

export default function SupervisorExperienceSettingsModal({ closeModal, vendorId }: Props) {
  const dispatch = useDispatch();

  const {
    isLoading,
    isSaving,
    supervisorExperienceSettings,
    driverSafetyInspectionSettings = [],
  } = useSelector((state: AppState) => state.vendors.supervisorExperienceSettings);
  const formPristine = useSelector((state: AppState) => isPristine('supervisorExperienceSettingsForm')(state));
  const driverSafetyInspectionSettingsFormInitialValues =
    driverSafetyInspectionSettingsFormInitialValuesSelector(driverSafetyInspectionSettings);
  const initialValues = Object.assign(supervisorExperienceSettings, driverSafetyInspectionSettingsFormInitialValues);

  const handleSubmit = (supervisorExperienceSettings: SupervisorExperienceSettings | any) => {
    const supervisorExperienceSettingsFormData = {
      vendorId: supervisorExperienceSettings.vendorId,
      requireVehicleAtLogin: supervisorExperienceSettings.requireVehicleAtLogin,
    };

    saveSupervisorExperienceSettings(
      vendorId,
      supervisorExperienceSettingsFormData,
    )(dispatch)
      .then(() => {
        delete supervisorExperienceSettings.vendorId;
        delete supervisorExperienceSettings.requireVehicleAtLogin;
        saveDriverSafetyInspectionSettings(
          vendorId,
          supervisorExperienceSettings,
        )(dispatch)
          .then(() => {
            createSuccessNotification(translate('vendors.alertMessages.supervisorExperienceSettingsSaved'));
            closeModal();
          })
          .catch(() => createErrorNotification(translate('vendors.alertMessages.supervisorExperienceSettingsError')));
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.supervisorExperienceSettingsError')));
  };

  const closeCurrentModal = (pristine: boolean) => {
    closeModal(pristine);
  };

  const tryCloseModal = () => {
    closeModal(formPristine);
  };

  return (
    <Modal
      title={translate('vendors.featureCodes.supervisorExperience')}
      padding="medium no no"
      size="medium"
      onClose={tryCloseModal}
      isLoading={isLoading || isSaving}
    >
      <ModalSection>
        <SupervisorExperienceSettingsForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={closeCurrentModal}
        />
      </ModalSection>
    </Modal>
  );
}
