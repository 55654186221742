import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const DRY_RUN = 'DR';
export const CONTAMINATIONS = 'CL';
export const EXTRA_YARDAGE = 'XY';
export const CHANGE_REQUEST = 'Change Request';

export const REQUEST_TYPE_OPTIONS = mapKeys(
  [
    { id: DRY_RUN, name: translate('customers.rubiconService.dryRun'), technicalName: '' },
    { id: CONTAMINATIONS, name: translate('customers.rubiconService.contamination'), value: 'VT' },
    { id: EXTRA_YARDAGE, name: translate('customers.rubiconService.extraYardage'), value: 'VE' },
  ],
  'id',
);
