import React from 'react';
import { ProgressBarContainer, ProgressBarDiv } from './styled/ProgressPopup';

interface Props {
  progress?: number;
  withBorder?: boolean;
  margin?: string;
}

export const ProgressBar: React.SFC<Props> = ({ progress, withBorder, margin }) => (
  <ProgressBarContainer withBorder={withBorder} margin={margin}>
    <ProgressBarDiv progress={progress || 0} />
  </ProgressBarContainer>
);

export default ProgressBar;
