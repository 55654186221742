import React, { ChangeEvent, PureComponent } from 'react';

import { map, size } from 'lodash-es';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { isSuperAdminSelector } from '../../account/ducks';
import { Vendor } from '../interfaces/Vendors';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  isSuperAdmin: boolean;
  userAssignedVendors: Vendor[];
  vendors: Vendor[];
  vendorList?: Vendor[];
}

class VendorDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      dropdownProps,
      input: { name },
      isSuperAdmin,
      label,
      placeholder,
      userAssignedVendors,
      vendors,
      vendorList,
      withLabel,
      withPlaceholder,
    } = this.props;

    const vendorsList = !!size(vendorList) ? vendorList : isSuperAdmin ? vendors : userAssignedVendors;
    const vendorOptions = map(vendorsList, vendor => ({
      label: vendor.name,
      value: vendor.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.vendor') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.vendor') : undefined),
          options: vendorOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isSuperAdmin: isSuperAdminSelector(state.account.login),
  userAssignedVendors: state.vendors.vendorAccess.assignedUserVendors,
  vendors: state.vendors.vendors.vendors,
});

export default connect(mapStateToProps)(VendorDropdown);
