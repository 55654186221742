import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, GridColumn, PanelSection, ModalSection } from '../../../core/components/styled';
import { InsightConfiguration } from 'src/vendors/interfaces/InsightConfiguration';
import { Table } from '../../../core/components';
import InsightConfigurationTableRow from '../modals/InsightConfigurationTableRow';
import translate from 'src/core/services/translate';

export const INSIGHT_CONFIGURATION_FORM = 'InsightConfigurationForm';

interface ComponentProps {
  currentInsightConfiguration: InsightConfiguration[];
  toggleInsightConfiguration?: (id: number, isChecked: any | boolean) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const InsightConfigurationForm: React.FC<Props> = ({
  handleSubmit,
  currentInsightConfiguration,
  toggleInsightConfiguration,
}) => {
  const insightConfigurationTableCells = [
    {
      name: 'insightName',
      label: translate('insights.insightName'),
      width: '70%',
      id: 'insight-settings-header-report-type',
    },
    {
      name: 'switch',
      label: translate('insights.displayInsight'),
      width: '30%',
      align: 'right',
      id: 'insight-settings-header-display',
    },
  ];

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ModalSection padding="xxSmall" align="center">
        <PanelSection padding="small" centered>
          <GridColumn size="12/12">
            <Grid centered multiLine>
              <Table
                cells={insightConfigurationTableCells}
                rows={currentInsightConfiguration}
                rowComponent={InsightConfigurationTableRow}
                rowProps={{
                  toggleInsightConfiguration,
                }}
              />
            </Grid>
          </GridColumn>
        </PanelSection>
      </ModalSection>
    </form>
  );
};

export default reduxForm<any, ComponentProps>({ form: INSIGHT_CONFIGURATION_FORM })(InsightConfigurationForm);
