import { httpInsightsReporting } from '../../core/services/http';

export const loadStrobeImages = (
  vendorId: number,
  vehicleId: number,
  timeStamp?: Date | string,
  cameraSerialNumber?: string,
) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/vehicles/${vehicleId}/strobeImages?`, { params: { timeStamp, cameraSerialNumber } })
    .then(response => response.data);

export const loadNextStrobeImages = (
  vendorId: number,
  vehicleId: number,
  strobeImageId: number,
  cameraSerialNumber: string,
  numberOfImages: number,
) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/vehicles/${vehicleId}/strobeImages/${strobeImageId}/nextImages`, {
      params: { numberOfImages, cameraSerialNumber },
    })
    .then(response => response.data);

export const loadPrevStrobeImages = (
  vendorId: number,
  vehicleId: number,
  strobeImageId: number,
  cameraSerialNumber: string,
  numberOfImages: number,
) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/vehicles/${vehicleId}/strobeImages/${strobeImageId}/previousImages`, {
      params: { numberOfImages, cameraSerialNumber },
    })
    .then(response => response.data);

export const loadClosestStrobeImage = (vendorId: number, vehicleId: number, timeStamp?: Date | string) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/vehicles/${vehicleId}/closestStrobeImage`, { params: { timeStamp } })
    .then(response => response.data);
