import update from 'immutability-helper';
import doLoadHolidays from '../services/loadHolidays';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Actions
const START_LOAD = 'common/holidays/START_LOAD';
const COMPLETE_LOAD = 'common/holidays/COMPLETE_LOAD';
const FAIL_LOAD = 'common/holidays/FAIL_LOAD';
const RESET = 'common/holidays/RESET';

// Initial state
const initialState: HolidaysState = {
  isLoading: false,
  holidays: [],
};

interface HolidaysState {
  isLoading: boolean;
  holidays: Date[];
}

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          holidays: action.holidays,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          holidays: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (holidays: Date[]) => ({
  type: COMPLETE_LOAD,
  holidays,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadHolidays = () => (dispatch: ThunkDispatch<HolidaysState, any, AnyAction>) => {
  dispatch(startLoad());
  return doLoadHolidays()
    .then(holidays => dispatch(completeLoad(holidays)))
    .catch(() => dispatch(failLoad()));
};

export const resetHolidays = () => ({
  type: RESET,
});
