import styled, { css } from 'styled-components';
import { BareButtonStyle } from './Button';
import { sizeMapper, mapper } from '../../../utils/styles';

export interface DayOfWeekProps {
  size?: 'xSmall' | 'small' | 'medium' | 'large';
  margin?: string;
  isSelected?: boolean;
}

export const DayOfWeek = styled.button.attrs({ type: 'button' })<DayOfWeekProps>`
  ${BareButtonStyle};
  width: ${props => mapper(props.size, { xSmall: '18px', small: '22px', medium: '30px', large: '40px' }, 'small')};
  height: ${props => mapper(props.size, { xSmall: '18px', small: '22px', medium: '30px', large: '40px' }, 'small')};
  margin: ${props => sizeMapper(props.margin, 'no small no no')};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.gray};
  text-transform: capitalize;
  text-align: center;
  line-height: ${props =>
    mapper(props.size, { xSmall: '18px', small: '22px', medium: '30px', large: '40px' }, 'small')};
  font-size: ${props => mapper(props.size, { xSmall: '10px', small: '12px', medium: '14px', large: '16px' }, 'small')};
  color: ${props => props.theme.gray};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${props =>
    props.isSelected &&
    css`
      background: ${props => props.theme.brandPrimary};
      border-color: ${props => props.theme.brandPrimary};
      color: #fff;
    `};
`;

export interface DaysOfWeekProps {
  margin?: string;
  isReadOnly?: boolean;
}

export const DaysOfWeek = styled.div<DaysOfWeekProps>`
  display: flex;
  margin: ${props => sizeMapper(props.margin, 'no no sMedium')};

  ${props =>
    props.isReadOnly &&
    css`
      pointer-events: none;
    `};
`;
