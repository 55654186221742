import { identity, mapKeys } from 'lodash-es';
import { createSelector } from 'reselect';

const getNavigationSettingsFormInitialValuesSelector = (navigationSettingsState: any) =>
  mapKeys(navigationSettingsState.navigationSettings, ({ vehicleTypeId }) => vehicleTypeId);

const navigationSettingsFormInitialValuesSelector = createSelector(
  getNavigationSettingsFormInitialValuesSelector,
  identity,
);

export default navigationSettingsFormInitialValuesSelector;
