import React from 'react';

import DoughnutChart from 'src/core/components/DoughnutChart';
import { Grid, GridColumn, Panel, PanelSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { theme } from 'src/core/styles';
import { ContainerStatisticsElement, ContainerStatisticsWrapper } from 'src/finance/components/styled/Statistics';
import { getChartData } from 'src/finance/components/utils/paymentStatistics';

const chartColors = ['#9FC5FA', theme.brandGreenDark, '#BD10E0', '#FFCA00'];

const PaymentsStatisticsSection = () => {
  const { isLoading: isLoadingPaymentsData, payments: paymentsData } = useSelector(state => state.finance.payments);
  const { isLoading: isLoadingOpenBillsData, openBills: openBillsData } = useSelector(state => state.finance.openBills);
  const paymentsAgingBucketSummary = paymentsData?.paymentsAgingBucketSummary;
  const billsAgingBucketSummary = openBillsData?.billsAgingBucketSummary;

  const chartsMemo = React.useMemo(() => {
    if (!paymentsAgingBucketSummary || !billsAgingBucketSummary) {
      return null;
    }

    const billsData = getChartData(billsAgingBucketSummary);
    const paymentsData = getChartData(paymentsAgingBucketSummary);

    return (
      <>
        {billsData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center" width="50%">
            <DoughnutChart
              showLegend
              showSummaryLegend
              title={`${translate('finance.applyChecks.bills')}\n${billsData.total}`}
              doughnutSize={140}
              dataset={{
                data: billsData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={billsData.rawDataValues}
              labels={billsData.labels}
            />
          </ContainerStatisticsElement>
        )}
        {paymentsData && (
          <ContainerStatisticsElement verticalAlign="middle" alignItems="center" width="50%">
            <DoughnutChart
              showLegend
              showSummaryLegend
              title={`${translate('finance.applyChecks.payments')}\n${paymentsData.total}`}
              doughnutSize={140}
              dataset={{
                data: paymentsData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={paymentsData.rawDataValues}
              labels={paymentsData.labels}
            />
          </ContainerStatisticsElement>
        )}
      </>
    );
  }, [billsAgingBucketSummary, paymentsAgingBucketSummary]);

  return (
    <PanelSection withBorder isLoading={isLoadingPaymentsData || isLoadingOpenBillsData}>
      <Panel fluid padding="small">
        <Grid>
          <GridColumn size="12/12">
            <ContainerStatisticsWrapper>{chartsMemo}</ContainerStatisticsWrapper>
          </GridColumn>
        </Grid>
      </Panel>
    </PanelSection>
  );
};

export default PaymentsStatisticsSection;
