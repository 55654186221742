const computeRemaining = (total: number, batchSize: number) => {
  const remaining = total - batchSize;
  return remaining > 0 ? remaining : 0;
};

const computePercentage = (a: number, b: number) => {
  const value = Math.round((100 * a) / b);
  return value > 100 ? 100 : value;
};

const applyBatching = async (
  elements: any[],
  batchSize: number,
  callback: (elements: any[], percentage: number) => void,
) => {
  let remainingElementsCount = elements.length;
  let startPosition = 0;

  while (remainingElementsCount > 0) {
    const lastElementPosition = startPosition + batchSize;
    const batchElements = elements.slice(startPosition, lastElementPosition);
    const remainingAfterBatch = computeRemaining(remainingElementsCount, batchSize);

    // eslint-disable-next-line no-await-in-loop
    await callback(batchElements, computePercentage(elements.length - remainingAfterBatch, elements.length));

    startPosition += batchSize;
    remainingElementsCount = remainingAfterBatch;
  }
};

export default applyBatching;
