import { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { isEqual, map } from 'lodash-es';

import {
  Button,
  IconButtonIcon,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
  Popover,
  Text,
  ModalFixedFooter,
} from 'src/core/components/styled';
import { PopoverWrapper, Table } from 'src/core/components';
import { REQUIRED_ID } from 'src/common/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { useSelector } from 'src/core/hooks/useSelector';
import CityAlertSettingsTableRow from './CityAlertSettingsTableRow';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
}

export interface CityAlertSettingsFormValues {
  cityAlertTypes: {
    [key: string]: {
      cityAlertType: TechnicalType;
      isActive: boolean;
      audioAlert: boolean;
      canTakePicture: boolean;
      expirationUnit: number;
      expirationUnitTimeMeasurementType: TechnicalType;
      proximityUnitMeasurementType: TechnicalType;
      proximityValue: number;
      hasNoEndDate?: boolean;
    };
  };
  cityAlertProximityUnitMeasurementType: number;
  cityAlertProximityValue: number;
}
export const CITY_ALERT_SETTINGS_FORM_NAME = 'cityAlertSettingsForm';

type Props = ComponentProps & InjectedFormProps<CityAlertSettingsFormValues, ComponentProps>;

const CityAlertSettingsForm: FC<Props> = ({ handleSubmit, onCancel, pristine }) => {
  const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings.cityAlertSettings);
  const formattedCityAlertSettings = map(cityAlertSettings?.cityAlertTypes, alertType => {
    return { ...alertType, canTakePicture: isEqual(REQUIRED_ID, alertType.canTakePicture.id) };
  });

  const tableCells = [
    { name: 'title', label: translate('vendors.cityAlerts.alertType'), align: 'left', width: '15%' },
    { name: 'isActive', label: translate('common.active'), align: 'left', width: '10%' },
    { name: 'audioAlert', label: translate('vendors.cityAlerts.audioAlert'), align: 'left', width: '10%' },
    {
      alignItems: 'center',
      name: 'expiration',
      label: translate('vendors.cityAlerts.alertExpiresAfter'),
      align: 'left',
      width: '22%',
      component: () => (
        <PopoverWrapper
          triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xSmall" />}
          popoverContent={
            <Popover>
              <Text margin="no" block>
                {translate('vendors.cityAlerts.alertExpirationNote')}
              </Text>
            </Popover>
          }
          size="medium"
        />
      ),
    },
    { name: 'noExpiration', label: 'no expiration', align: 'left', width: '12%' },
    {
      name: 'proximity',
      label: translate('vendors.cityAlerts.alertProximity'),
      align: 'left',
      width: '18%',
      component: () => (
        <PopoverWrapper
          triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xSmall" />}
          popoverContent={
            <Popover>
              <Text margin="no" block>
                {translate('vendors.cityAlerts.alertProximityNote')}
              </Text>
            </Popover>
          }
          size="medium"
        />
      ),
    },
    {
      name: 'canTakePicture',
      label: translate('vendors.cityAlerts.picture'),
      align: 'left',
      width: '13%',
      component: () => (
        <PopoverWrapper
          triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xSmall" />}
          popoverContent={
            <Popover>
              <Text margin="no" block>
                {translate('vendors.cityAlerts.alertPictureNote')}
              </Text>
            </Popover>
          }
          size="medium"
        />
      ),
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>

        <PanelSection padding="small" vertical>
          <Table cells={tableCells} rows={formattedCityAlertSettings} rowComponent={CityAlertSettingsTableRow} />
        </PanelSection>
        <ModalFixedFooter isShadowed>
          <Button type="button" margin="no small" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
        </ModalFixedFooter>
      </form>
    </>
  );
};

export default reduxForm<CityAlertSettingsFormValues, ComponentProps>({
  form: CITY_ALERT_SETTINGS_FORM_NAME,
  onSubmitFail: focusFirstInvalidField,
})(CityAlertSettingsForm);
