import { useDispatch } from 'react-redux';
import { useState } from 'react';
import humps from 'humps';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { CustomerProximitySearchModalResolver } from 'src/customers/components/modals';
import { TableActionButton, GridColumn, Grid } from 'src/core/components/styled';
import { getRouteStopIconType } from './utils';
import {
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { PICKUP_STATUSES, ROUTE_PICKUP_TYPE_IDS, SCHEDULED } from 'src/common/constants';

import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';

type Props = {
  routeStop: RouteLocation;
};

export default function RouteMapRouteStopsGLPopup({ routeStop }: Props) {
  const dispatch = useDispatch();

  const [isCustomerProximitySearchModalOpen, setCustomerProximitySearchModalOpen] = useState(false);

  const iconType = getRouteStopIconType(routeStop);
  const pickupStatusName = PICKUP_STATUSES[SCHEDULED].technicalName;
  const insightType = humps.camelize(pickupStatusName);

  const lat =
    routeStop.service.serviceContractBinDetails[0]?.displayLatitude ||
    routeStop.service.serviceContractBinDetails[0]?.binLatitude ||
    routeStop.location.address.latitude;
  const long =
    routeStop.service.serviceContractBinDetails[0]?.displayLongitude ||
    routeStop.service.serviceContractBinDetails[0]?.binLongitude ||
    routeStop.location.address.longitude;

  const getAddress = () => {
    if (routeStop && routeStop.location && routeStop.location.address) return routeStop.location.address.line1;
    return '-';
  };

  const jobIsPendingOptimization = routeStop.orderNumber === JOB_PENDING_OPTIMIZATION_ID;

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={lat}
        longitude={long}
        onClose={() => dispatch(clearRouteMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={iconType?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${insightType}`)}
          />

          <MapGLPopupContent>
            <Grid>
              <GridColumn size="12/12">
                {ROUTE_PICKUP_TYPE_IDS[routeStop.pickupTypeId] && (
                  <LabeledDataView
                    noWrap
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('routes.pickupType')}
                    value={ROUTE_PICKUP_TYPE_IDS[routeStop.pickupTypeId]?.name || '-'}
                  />
                )}

                {routeStop.newOrderNumber && (
                  <LabeledDataView
                    noWrap
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('routes.newStopNumber')}
                    value={routeStop.newOrderNumber}
                  />
                )}

                <LabeledDataView
                  noWrap
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('dashboard.stopNumber')}
                  value={jobIsPendingOptimization ? null : routeStop.orderNumber}
                  children={jobIsPendingOptimization && <em>{translate('routes.pendingOptimization')}</em>}
                />

                <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.customer')}>
                  {routeStop.customer.name}

                  {routeStop.serviceContractRouteTemplateId && (
                    <TableActionButton
                      margin="no no no xSmall"
                      color="primary"
                      onClick={() => setCustomerProximitySearchModalOpen(true)}
                    >
                      <ActionButtonTooltip
                        icon="searchVehicle"
                        size="sMedium"
                        sourceIsInfoWindow
                        tooltip="customerProximitySearch"
                      />
                    </TableActionButton>
                  )}
                </LabeledDataView>
                <LabeledDataView
                  noWrap
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('common.location')}
                  value={routeStop.location.name}
                />
                <LabeledDataView
                  noWrap
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('common.address')}
                  value={getAddress()}
                />
              </GridColumn>
            </Grid>
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isCustomerProximitySearchModalOpen && routeStop.serviceContractRouteTemplateId && (
        <CustomerProximitySearchModalResolver
          locationId={routeStop.serviceContractRouteTemplateId}
          onCancel={() => setCustomerProximitySearchModalOpen(false)}
        />
      )}
    </>
  );
}
