import { AnyAction } from 'redux';
import update from 'immutability-helper';

import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';

const SET_VIEWPORT = 'customerLocations/mapControls/SET_VIEWPORT';
const RESET = 'customerLocations/mapControls/RESET';

interface State {
  viewport: MapGLViewport;
}

const initialState: State = {
  viewport: {},
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export const setCustomerLocationsMapViewport = (viewport: MapGLViewport) => {
  return {
    type: SET_VIEWPORT,
    viewport,
  };
};

export const resetCustomerLocationsMapControls = () => ({
  type: RESET,
});
