import { change, getFormValues } from 'redux-form';
import { map } from 'lodash-es';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

import { NONE_ZERO_ID } from 'src/routes/constants/routePriorityTypes';
import { SNOW_SWEEPER_FILTERS_FORM_NAME, SnowSweeperFiltersFormValues } from '../SnowSweeperFiltersForm';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import SnowSweeperFilterItem from './SnowSweeperFilterItem';
import { getNumberOfActiveFilters } from './utils';

interface Props {
  isSnowPlow: boolean;
  isStreetSweeper: boolean;
}

const ServiceActivityFilterSection: FC<Props> = ({ isSnowPlow, isStreetSweeper }) => {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;

  const { snowPlowServiceActivities, streetSweeperServiceActivities } = useSelector(
    state => state.dashboard.alternativeFleetOps,
  );

  const serviceActivities = isSnowPlow ? snowPlowServiceActivities : streetSweeperServiceActivities;

  const ALL_SERVICE_ACTIVITY_TYPES_OPTIONS = serviceActivities.map(serviceActivity => ({
    value: serviceActivity.id,
    label: translate(createTranslationKey(serviceActivity.technicalName, 'vendors.serviceConfiguration')),
  }));

  ALL_SERVICE_ACTIVITY_TYPES_OPTIONS.push({ value: NONE_ZERO_ID, label: translate('dashboard.none') });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      const serviceActivityIds: any = {
        all: checked,
      };
      map(ALL_SERVICE_ACTIVITY_TYPES_OPTIONS, activity => {
        serviceActivityIds[`_${activity.value}`] = checked;
      });
      dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'serviceActivityIds', serviceActivityIds));
    },
    [ALL_SERVICE_ACTIVITY_TYPES_OPTIONS, dispatch],
  );

  const handleCheckAny = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValue, prevValue, name) => {
      // uncheck the all checkbox if any of the other checkboxes are unchecked
      if (!newValue) dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'serviceActivityIds.all', false));
      else if (
        getNumberOfActiveFilters(formValues?.serviceActivityIds) ===
        ALL_SERVICE_ACTIVITY_TYPES_OPTIONS.length - 1
      )
        dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'serviceActivityIds.all', true));
    },
    [ALL_SERVICE_ACTIVITY_TYPES_OPTIONS.length, dispatch, formValues?.serviceActivityIds],
  );

  return (
    <>
      <SnowSweeperFilterItem name="serviceActivityIds.all" label={translate('common.all')} onChange={handleCheckAll} />

      {map(ALL_SERVICE_ACTIVITY_TYPES_OPTIONS, activity => (
        <SnowSweeperFilterItem
          key={activity.value}
          name={`serviceActivityIds._${activity.value}`}
          label={activity.label}
          onChange={handleCheckAny}
        />
      ))}
    </>
  );
};

export default ServiceActivityFilterSection;
