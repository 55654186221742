import React, { Fragment } from 'react';

import { Popover } from '../../../../core/components/styled';
import { PopoverWrapper } from '../../../../core/components';
import { InfoIcon } from '../../styled';

interface ComponentProps {
  label: string;
  tooltipText: string;
}

const RouteBuilderTableCell: React.FC<ComponentProps> = props => {
  const { label, tooltipText } = props;

  return (
    <Fragment>
      {label}
      {tooltipText && (
        <PopoverWrapper
          margin="no no no xSmall"
          triggerButton={<InfoIcon />}
          popoverContent={<Popover>{tooltipText}</Popover>}
        />
      )}
    </Fragment>
  );
};

export default React.memo(RouteBuilderTableCell);
