import React from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import { Text } from '../../core/components/styled';
import { WEIGHT_MEASUREMENT_UNITS } from '../constants';

interface Props {
  input: WrappedFieldInputProps;
}

const WeightMeasurementUnitText: React.SFC<Props> = ({ input: { value } }) => (
  <Text>{WEIGHT_MEASUREMENT_UNITS[value] ? WEIGHT_MEASUREMENT_UNITS[value].name : undefined}</Text>
);

export default WeightMeasurementUnitText;
