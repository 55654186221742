import React, { ChangeEvent, PureComponent } from 'react';

import { map, camelCase } from 'lodash-es';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  reasons: any[];
}

class ReasonsDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      reasons,
    } = this.props;

    const reasonsOptions = map(reasons, reason => ({
      label: translate(`routes.reasons.${camelCase(reason.technicalName)}`),
      value: reason.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.pickupStatus') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.pickupStatus') : undefined),
          options: reasonsOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default ReasonsDropdown;
