import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const CONTAINER_OUT = 1;
export const CONTAINER_NOT_OUT = 2;
export const CLEAN_RECYCLING = 3;
export const FLAGGED = 4;
export const NOT_SET = 5;

export const WASTE_AUDIT_LOCATION_STATUS_TYPES = mapKeys(
  [
    {
      id: CONTAINER_OUT,
      name: translate('routes.wasteAuditLocationStatusTypes.containerOut'),
      technicalName: 'containerOut',
    },
    {
      id: CONTAINER_NOT_OUT,
      name: translate('routes.wasteAuditLocationStatusTypes.containerNotOut'),
      technicalName: 'containerNotOut',
    },
    {
      id: CLEAN_RECYCLING,
      name: translate('routes.wasteAuditLocationStatusTypes.cleanRecycling'),
      technicalName: 'cleanRecycling',
    },
    {
      id: FLAGGED,
      name: translate('routes.wasteAuditLocationStatusTypes.flagged'),
      technicalName: 'flagged',
    },
    {
      id: NOT_SET,
      name: translate('routes.wasteAuditLocationStatusTypes.notSet'),
      technicalName: 'notSet',
    },
  ],
  'id',
);
