import React, { useState } from 'react';

import { InfoWindow, Marker } from '@react-google-maps/api';

import {
  MapInfoImage,
  MapInfoImageWrapper,
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowDetailsContainer,
  MapInfoWindowTitle,
} from '../../core/components/styled';
import translate from '../../core/services/translate';
import { ApplicationModeChange } from '../../routes/components/mapWithTimeline/Interfaces';
import StrobeImagesModalResolver from '../../routes/components/modals/StrobeImagesModalResolver';
import { time } from '../../utils/services/formatter';
import { APPLICATION_STATUS_ICONS, APPLICATION_STATUSES, GOOGLE as google } from '../constants';
import { getSpeedInformation } from '../services/getSpeedInformation';

interface Props {
  applicationModeChange: ApplicationModeChange;
  applicationModeChangeId?: string;
  isInfoWindowOpen?: boolean;
  mapZoom: number;
  strobeImageUrl?: string;
  toggleInfoWindow: (key: string, vehicleIndex: number, timestamp: string) => string;
  unitOfMeasure: string;
  vehicleId: number;
  vehicleIndex: number;
  vehicleName: string;
}

const ApplicationModeChangeMarker = (props: Props) => {
  const {
    applicationModeChange: { hideOnMap, coordinates, timestamp, speed, applicationModeStatusId },
    applicationModeChangeId,
    isInfoWindowOpen,
    mapZoom,
    strobeImageUrl,
    toggleInfoWindow,
    unitOfMeasure,
    vehicleId,
    vehicleIndex,
    vehicleName,
  } = props;

  const [isStrobeImageModalOpen, setIsStrobeImageModalOpen] = useState(false);

  const markerPosition = {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };

  const openStrobeImageModal = () => setIsStrobeImageModalOpen(true);

  const closeStrobeImageModal = () => setIsStrobeImageModalOpen(false);

  const toggleWindow = () =>
    toggleInfoWindow(`applicationModeChanges.${applicationModeChangeId}`, vehicleIndex, timestamp);

  const markerIcon = {
    url: APPLICATION_STATUS_ICONS[applicationModeStatusId].icon,
    size: mapZoom >= 18 ? new google.maps.Size(24, 24) : new google.maps.Size(20, 20),
    scaledSize: mapZoom >= 18 ? new google.maps.Size(24, 24) : new google.maps.Size(20, 20),
  };

  const pixelOffset = new google.maps.Size(0, -30);

  return hideOnMap && hideOnMap.get() ? null : (
    <Marker zIndex={270} icon={markerIcon} position={markerPosition} onClick={toggleWindow} noClustererRedraw>
      {isInfoWindowOpen && markerPosition && (
        <InfoWindow onCloseClick={toggleWindow} position={markerPosition} options={{ pixelOffset }}>
          <MapInfoWindow>
            <MapInfoWindowDetailsContainer>
              <MapInfoWindowDetails margin>
                <MapInfoWindowTitle>{vehicleName}</MapInfoWindowTitle>
                {!!timestamp && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.timestamp')}:</MapInfoWindowDetailLabel>
                    {time(timestamp)}
                  </MapInfoWindowDetail>
                )}
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('common.appStatus')}:</MapInfoWindowDetailLabel>
                  {APPLICATION_STATUSES[applicationModeStatusId].name}
                </MapInfoWindowDetail>
                <MapInfoWindowDetail>
                  <MapInfoWindowDetailLabel>{translate('common.speed')}:</MapInfoWindowDetailLabel>
                  {getSpeedInformation(speed, unitOfMeasure)}
                </MapInfoWindowDetail>
              </MapInfoWindowDetails>
              {!!strobeImageUrl && (
                <MapInfoImageWrapper onClick={openStrobeImageModal}>
                  <MapInfoImage src={strobeImageUrl} onClick={openStrobeImageModal} />
                </MapInfoImageWrapper>
              )}
            </MapInfoWindowDetailsContainer>
            {!!isStrobeImageModalOpen && (
              <StrobeImagesModalResolver
                vehicleId={vehicleId}
                vehicleName={vehicleName}
                timeStamp={timestamp}
                closeModal={closeStrobeImageModal}
              />
            )}
          </MapInfoWindow>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default ApplicationModeChangeMarker;
