import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from '../../../store';
import { DatePicker, UnconnectedCheckbox, PopoverWrapper } from '../../../core/components';
import { DELIVERY_UTILITY_ID } from '../../../fleet/constants';
import { DispatchBoardFlexWrapper } from '../styled';
import { Dropdown, Input, TypedField } from '../../../core/components';
import { EquipmentTypeDropdown, WasteTypeDropdown } from '../../../common/components';
import { Grid, GridColumn, Popover } from '../../../core/components/styled';
import { PickupTypeDropdown } from '..';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import loadPickupTypes from '../../services/loadPickupTypes';
import translate from '../../../core/services/translate';
import { isDateValidValidator } from 'src/utils/services/validator';

interface PropsWithoutReduxForm {
  allSelected: boolean;
  handleCheckAll: () => void;
  handleDateFilterChange: (e: any, date: Date | string) => void;
  handleEquipmentFilterChange: (e: any, equipment: TechnicalType) => void;
  handleMaterialTypeFilterChange: (e: any, materialType: TechnicalType) => void;
  handlePickupTypeFilterChange: (event: React.ChangeEvent<HTMLInputElement>, pickupTypeId: number) => void;
  handleReasonCodeTypeFilterChange: (e: any, reasonCode: TechnicalType) => void;
  handleSearchTermFilterChange: (e: any, searchTerm: string) => void;
  numberOfJobs: number;
  partial?: boolean;
}

export interface FormData {
  date?: Date | string;
  equipment?: TechnicalType;
  materialType?: TechnicalType;
  pickupType?: number;
  reasonCodeType?: number;
  searchTerm?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const DispatchBoardRouteBuilderFilterForm = (props: Props) => {
  const {
    allSelected,
    handleCheckAll,
    handleDateFilterChange,
    handleEquipmentFilterChange,
    handleMaterialTypeFilterChange,
    handlePickupTypeFilterChange,
    handleReasonCodeTypeFilterChange,
    handleSearchTermFilterChange,
    numberOfJobs,
    partial = false,
  } = props;

  const [pickupTypes, setPickupTypes] = useState<TechnicalType[]>([]);

  useEffect(() => {
    loadPickupTypes(DELIVERY_UTILITY_ID).then(pickupTypes => {
      const deliveryUtilityPickupTypes = pickupTypes[0];
      setPickupTypes(deliveryUtilityPickupTypes.pickupTypes);
    });
  }, []);

  const reasonCodeTypeOptions = useSelector((state: AppState) =>
    state.common.reasonCodeTypes.reasonCodeTypes.map((reasonCodeType: TechnicalType) => ({
      label: reasonCodeType.name,
      value: reasonCodeType.id,
    })),
  );

  return (
    <form onSubmit={e => e.preventDefault()} id="dispatchBoardRouteBuilderFilterForm">
      <DispatchBoardFlexWrapper>
        <PopoverWrapper
          triggerButton={
            <UnconnectedCheckbox
              margin="no"
              checkboxMargin="no"
              onChange={handleCheckAll}
              checked={allSelected}
              partial={partial}
            />
          }
          popoverContent={<Popover>{translate('dispatchBoard.routeBuilder.selectAll', { numberOfJobs })}</Popover>}
          margin="ssMedium small no no"
        />
        <Grid multiLine>
          <GridColumn size="9/12">
            <Field
              name="searchTerm"
              component={Input}
              label={translate('common.search')}
              margin="no"
              onChange={handleSearchTermFilterChange}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <Field
              name="equipment"
              component={EquipmentTypeDropdown}
              label={translate('common.equipmentType')}
              byName
              dropdownProps={{ isClearable: true, margin: 'no' }}
              onChange={handleEquipmentFilterChange}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <Field
              name="date"
              component={DatePicker}
              label={translate('common.date')}
              onChange={handleDateFilterChange}
              margin="no"
              isClearable
              validate={[isDateValidValidator]}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <Field
              name="materialType"
              component={WasteTypeDropdown}
              label={translate('common.wasteType')}
              optionValueType="name"
              dropdownProps={{ isClearable: true, margin: 'no' }}
              onChange={handleMaterialTypeFilterChange}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="pickupType"
              component={PickupTypeDropdown}
              onChange={handlePickupTypeFilterChange}
              props={{
                dropdownProps: {
                  isClearable: true,
                  margin: 'no',
                },
                pickupTypes,
                withLabel: true,
              }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="reasonCodeType"
              component={Dropdown}
              onChange={handleReasonCodeTypeFilterChange}
              props={{
                isClearable: true,
                label: translate('common.reasonCode'),
                margin: 'no',
                options: reasonCodeTypeOptions,
              }}
            />
          </GridColumn>
        </Grid>
      </DispatchBoardFlexWrapper>
    </form>
  );
};

export default reduxForm<FormData, PropsWithoutReduxForm>({
  form: 'dispatchBoardRouteBuilderFilterForm',
})(DispatchBoardRouteBuilderFilterForm);
