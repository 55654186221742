import { camelCase } from 'lodash-es';
import { connect } from 'react-redux';
import { Fragment, PureComponent } from 'react';
import { isPristine } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { CustomerEmailModalResolver } from '../modals/CustomerEmailModalResolver';
import { date } from 'src/utils/services/formatter';
import { deleteUpcomingService, loadUpcomingServices } from 'src/customers/ducks';
import { DispatchBoardJobEditorModalResolver, RouteLocationNotesModalResolver } from 'src/routes/components/modals';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { isAdminSelector } from 'src/account/ducks';
import { OTHER_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { PICKUP_TYPE_PICKUP, ROUTE_PICKUP_TYPES } from 'src/routes/constants';
import { ServiceHistory } from 'src/customers/interfaces/ServiceHistory';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import confirm from 'src/core/services/confirm';
import JobPriorityTypeIndicator from 'src/routes/components/pages/common/JobPriorityTypeIndicator';
import translate from 'src/core/services/translate';

interface MatchState {
  customerId: string;
}

interface LocationState {
  locationId: string;
  serviceContractIds: string;
  pickupStatusTypeIds: string;
  jobPriorityTypeIds: string;
  startDate: string;
  endDate: string;
}

interface ComponentProps extends RouteComponentProps<MatchState, {}, LocationState> {
  deleteUpcomingService: DuckFunction<typeof deleteUpcomingService>;
  isAdmin?: boolean;
  isMaterialTypesEnabled: boolean;
  isRouteNotesFormPristine?: boolean;
  loadUpcomingServices: DuckFunction<typeof loadUpcomingServices>;
}

interface State {
  isJobEditorModalOpen: boolean;
  isRouteLocationNotesModalOpen: boolean;
  isSendEmailModalOpen: boolean;
}

type Props = ComponentProps & ServiceHistory;

class UpcomingServicesTableRow extends PureComponent<Props, State> {
  state = {
    isJobEditorModalOpen: false,
    isRouteLocationNotesModalOpen: false,
    isSendEmailModalOpen: false,
  };

  openJobEditorModal = (value: boolean) => {
    this.setState({ isJobEditorModalOpen: value });
  };

  openSendEmailModal = (value: boolean) => {
    this.setState({ isSendEmailModalOpen: value });
  };

  openRouteLocationNotesModal = (value: boolean) => {
    const { isRouteNotesFormPristine } = this.props;

    !value && !isRouteNotesFormPristine && this.loadUpcomingServices();
    this.setState({ isRouteLocationNotesModalOpen: value });
  };

  onDeleteUpcomingService = async (routeLocationId: number) => {
    const { deleteUpcomingService } = this.props;

    if (!(await confirm(translate('containers.alertMessages.confirmDeleteService')))) return;

    deleteUpcomingService(routeLocationId)
      .then(() => {
        createSuccessNotification(translate('containers.alertMessages.serviceDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('containers.alertMessages.serviceDeletedFailed'));
      });
  };

  loadUpcomingServices = () => {
    const {
      loadUpcomingServices,
      location,
      match: { params },
    } = this.props;

    const customerId = Number(params?.customerId);

    const { locationId, serviceContractIds, startDate, pickupStatusTypeIds, jobPriorityTypeIds, endDate } =
      getQueryParams(location.search);
    loadUpcomingServices(
      customerId,
      locationId,
      serviceContractIds,
      pickupStatusTypeIds,
      jobPriorityTypeIds,
      startDate,
      endDate,
    );
  };

  render() {
    const {
      isAdmin,
      isMaterialTypesEnabled,
      routeId,
      match: { params },
      ...routeLocation
    } = this.props;
    const { isJobEditorModalOpen, isRouteLocationNotesModalOpen, isSendEmailModalOpen } = this.state;
    const customerId = Number(params?.customerId);

    const {
      address,
      customerName,
      jobPriorityType,
      locationName,
      materialType,
      noteCount,
      pickupType,
      routeDate,
      routeLocationId,
      routeName,
      serviceName,
      vehicleType,
      wasteMaterialTypeName,
      workOrderNumber,
    } = routeLocation;

    const vehicleTypeId = typeof vehicleType !== 'string' ? vehicleType.id : undefined;

    const materialTypeName =
      materialType?.categoryId === OTHER_ID
        ? materialType?.technicalName
        : translate(`vendors.bulkyItemScheduler.itemTypes.${camelCase(materialType?.technicalName)}`);

    return (
      <Fragment>
        <TableRow>
          <TableCell vertical width="24%" justifyContent="center">
            <Text block weight="medium" margin="no no xxSmall" singleLine title={locationName}>
              {locationName}
            </Text>
            <Text weight="light" margin="no no xxSmall" title={address} doubleLine>
              {address}
            </Text>
          </TableCell>

          <TableCell vertical width="16%" align="center">
            <Text block weight="medium" margin="no no xxSmall" singleLine title={serviceName}>
              {serviceName}
            </Text>
            <Text weight="light" margin="no no xxSmall" singleLine title={wasteMaterialTypeName}>
              {wasteMaterialTypeName}
            </Text>
          </TableCell>
          <TableCell width="11%">{date(routeDate)}</TableCell>

          <TableCell vertical align="center" width="18%">
            <Text weight="medium" margin="no" position="relative">
              {routeName}
            </Text>
          </TableCell>

          <TableCell vertical align="center" width="17%" padding="defaultCellVertical no">
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
              <Box width="84%">
                <Text block weight="medium" margin="no" singleLine>
                  {pickupType
                    ? pickupType.name === PICKUP_TYPE_PICKUP
                      ? translate('routes.pickupTypes.pickup')
                      : ROUTE_PICKUP_TYPES[pickupType.technicalName]?.name
                    : '-'}
                </Text>

                {isMaterialTypesEnabled && materialType?.technicalName && (
                  <Text block weight="light" size="small" margin="no" singleLine title={materialTypeName}>
                    {materialTypeName}
                  </Text>
                )}

                {!!workOrderNumber && (
                  <Text block weight="light" size="small" margin="no" singleLine title={workOrderNumber.toString()}>
                    {translate('routes.woNumber')}: {workOrderNumber}
                  </Text>
                )}
              </Box>

              {jobPriorityType && (
                <Box ml={5}>
                  <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={jobPriorityType.id} />
                </Box>
              )}
            </Box>
          </TableCell>

          <TableCell align="right" width="14%">
            <TableActionButton
              id={`send-email-${routeId}-service-button`}
              onClick={() => {
                this.openSendEmailModal(true);
              }}
            >
              <ActionButtonTooltip icon="email" tooltip="sendEmail" />
            </TableActionButton>

            <TableActionButton onClick={() => this.openRouteLocationNotesModal(true)}>
              <ActionButtonTooltip icon="note" tooltip="notes" content={noteCount > 0 ? noteCount : undefined} />
            </TableActionButton>

            {!!isAdmin && (
              <>
                <TableActionButton id={`edit-${routeId}-service-button`} onClick={() => this.openJobEditorModal(true)}>
                  <ActionButtonTooltip icon="edit" tooltip="editJob" />
                </TableActionButton>

                <TableActionButton
                  id={`delete-${routeId}-service-button`}
                  onClick={() => this.onDeleteUpcomingService(routeLocationId)}
                >
                  <ActionButtonTooltip icon="delete" tooltip="delete" />
                </TableActionButton>
              </>
            )}
          </TableCell>
        </TableRow>

        {!!isJobEditorModalOpen && (
          <DispatchBoardJobEditorModalResolver
            jobId={routeLocationId}
            routeId={routeId}
            vehicleTypeId={vehicleTypeId}
            closeModal={() => this.openJobEditorModal(false)}
            refreshUnassignedJobs={() => this.loadUpcomingServices()}
            date={routeDate}
          />
        )}

        {!!isSendEmailModalOpen && (
          <CustomerEmailModalResolver
            customerId={customerId}
            email={{}}
            isInfoEmail
            onClose={() => this.openSendEmailModal(false)}
          />
        )}

        {!!isRouteLocationNotesModalOpen && (
          <RouteLocationNotesModalResolver
            modalTitle={customerName}
            modalSubTitle={locationName}
            routeId={routeId}
            routeLocationId={routeLocationId}
            siteAddress={address}
            closeModal={() => this.openRouteLocationNotesModal(false)}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isAdmin: isAdminSelector(state.account.login),
  isRouteNotesFormPristine: isPristine('routeNotes')(state),
});

const mapDispatchToProps = {
  deleteUpcomingService,
  loadUpcomingServices,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpcomingServicesTableRow));
