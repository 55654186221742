import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { AppState } from '../../../store';
import { ALL } from '../../constants/holidayPlanner';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { FRESH_FORM_KEY } from '../../constants/holidayPlanner';
import { Holiday } from '../../interfaces/HolidayPlanner';
import { SubmissionError } from 'redux-form';
import * as Ducks from '../../ducks/holidayPlanner';
import * as Utils from '../../utils/holidayPlanner';
import HolidayPlannerModalTableRowEdit from './HolidayPlannerTableRowEdit';
import HolidayPlannerModalTableRowView from './HolidayPlannerTableRowView';
import translate from '../../../core/services/translate';

interface Props {
  index: number;
  holiday: Holiday;
  holidays: Holiday[];
  settingsSaving: boolean;
}

const HolidayPlannerModalTableRow: React.FC<Props> = ({ holiday, holidays, index, settingsSaving }) => {
  const dispatch = useDispatch();

  const [isInEditMode, setIsInEditMode] = useState<boolean>(holiday.isFresh || false);
  const [routeTemplatesCountToDisplay, setRouteTemplatesCountToDisplay] = useState<number | string>(0);
  const [currentError, setCurrentError] = useState(undefined);
  const [currentNumberOfDaysIsNull, setCurrentNumberOfDays] = useState(false);

  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  if (settingsSaving && isInEditMode) {
    setIsInEditMode(false);
  }

  const otherHolidayDates = React.useMemo(
    () =>
      holidays
        .filter(item => item.id !== holiday.id)
        .map(item => moment(item.calculatedHolidayDate, 'MM/DD/YYYY').format('MM/DD'))
        .filter(Utils.notUndefined),
    [holidays, holiday.id],
  );

  const otherRecurrences = React.useMemo(
    () => holidays.filter(item => item.id !== holiday.id && !item.exactDayOfMonth).map(Utils.buildRecurrenceId),
    [holidays, holiday.id],
  );

  const toggleEditMode = React.useCallback(
    (routeTemplatesCountToDisplay?: number | string) => {
      if (
        routeTemplatesCountToDisplay &&
        (typeof routeTemplatesCountToDisplay === 'number' || routeTemplatesCountToDisplay === ALL)
      ) {
        setRouteTemplatesCountToDisplay(routeTemplatesCountToDisplay);
      }

      setIsInEditMode(!isInEditMode);

      if (holiday.isFresh) {
        dispatch(Ducks.removeFreshHoliday(index));
      }
    },
    [isInEditMode, holiday.isFresh, dispatch, index],
  );

  const saveHoliday = React.useCallback(
    (updatedHoliday: Holiday) => {
      if (!vendorId) {
        return;
      }

      setCurrentError(undefined);

      return Ducks.saveHoliday(
        vendorId,
        updatedHoliday,
      )(dispatch)
        .then(response => {
          const { routeTemplatesCount } = response;

          if (routeTemplatesCount && routeTemplatesCount >= 0) {
            setRouteTemplatesCountToDisplay(routeTemplatesCount);
          } else if (routeTemplatesCount === null) {
            setRouteTemplatesCountToDisplay(ALL);
          }

          setIsInEditMode(false);
          createSuccessNotification(translate('routes.holidayPlanner.holidaySaved'));
        })
        .catch(error => {
          let code: string | undefined = undefined;

          if (error.isAxiosError) {
            code = error.response.data && error.response.data.code;
          }

          switch (code) {
            case 'InvalidHolidayPlannerRecurrenceDate':
              throw new SubmissionError({
                asyncValidateField: translate('routes.holidayPlanner.holidayRecurrenceNotValid'),
              });

            case 'InvalidHolidayPlannerDuplicateDate':
              throw new SubmissionError({
                asyncValidateField: translate('routes.holidayPlanner.holidayDuplicateDate'),
              });

            case 'InvalidHolidayPlannerPostponeDateMoreThanThreeDaysInThePast':
              throw new SubmissionError({
                postponeToDate: translate('routes.holidayPlanner.holidayPostponeDateTooFarInPast'),
              });

            case 'InvalidHolidayPlannerPostponeDateInThePast':
              throw new SubmissionError({
                asyncValidateFieldPostpone: translate('routes.holidayPlanner.holidayPostponeDateInPast'),
              });

            case 'InvalidHolidayPlannerRoutesAlreadyScheduled':
              throw new SubmissionError({
                asyncValidateFieldPostpone: translate('routes.holidayPlanner.holidayPlannerRoutesAlreadyScheduled'),
              });

            case 'InvalidHolidayPlannerRouteDatesImpacted':
              throw new SubmissionError({
                asyncValidateFieldPostpone: translate(
                  'routes.holidayPlanner.alertMessages.invalidHolidayPlannerRouteDatesImpacted',
                ),
              });

            default:
              createErrorNotification(translate('routes.holidayPlanner.holidayNotSaved'));
              break;
          }
        });
    },
    [vendorId, dispatch],
  );

  const holidayInitialValues = {
    ...holiday,
    routeShiftingDays: holiday.routeShiftingDays === null ? 0 : holiday.routeShiftingDays,
  };

  const setCurrentErrorCode = (error?: string) => {
    switch (error) {
      case 'InvalidHolidayPlannerRecurrenceDate':
        return setCurrentError(translate('routes.holidayPlanner.holidayRecurrenceNotValid'));

      case 'InvalidHolidayPlannerDuplicateDate':
        return setCurrentError(translate('routes.holidayPlanner.holidayDuplicateDate'));

      case 'InvalidHolidayPlannerPostponeDateMoreThanThreeDaysInThePast':
        return setCurrentError(translate('routes.holidayPlanner.holidayPostponeDateTooFarInPast'));

      case 'InvalidHolidayPlannerPostponeDateInThePast':
        return setCurrentError(translate('routes.holidayPlanner.holidayPostponeDateInPast'));

      case 'InvalidHolidayPlannerRoutesAlreadyScheduled':
        return setCurrentError(translate('routes.holidayPlanner.holidayPlannerRoutesAlreadyScheduled'));

      case 'InvalidHolidayPlannerRouteDatesImpacted':
        return setCurrentError(translate('routes.holidayPlanner.invalidHolidayPlannerRouteDatesImpacted'));
    }
  };

  const setCurrentNumberOfDaysIsNull = (isNull: boolean) => {
    setCurrentNumberOfDays(isNull);
  };

  return isInEditMode ? (
    <HolidayPlannerModalTableRowEdit
      form={`holidayPlannerFor${holiday.id || FRESH_FORM_KEY}`}
      initialValues={holidayInitialValues}
      onSubmit={saveHoliday}
      otherHolidayDates={otherHolidayDates}
      otherRecurrences={otherRecurrences}
      requiresSchedule={holiday.activateRequiresSchedule}
      routeTemplatesCountToDisplay={routeTemplatesCountToDisplay}
      saveInProgress={holiday.saveInProgress}
      toggleEditMode={toggleEditMode}
      currentError={currentError}
      currentNumberOfDaysIsNull={currentNumberOfDaysIsNull}
    />
  ) : (
    <HolidayPlannerModalTableRowView
      holiday={holiday}
      toggleEditMode={toggleEditMode}
      setCurrentErrorCode={setCurrentErrorCode}
      setCurrentNumberOfDaysIsNull={setCurrentNumberOfDaysIsNull}
    />
  );
};

export default HolidayPlannerModalTableRow;
