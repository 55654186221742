import { OpportunityRow } from '../../../interfaces/Opportunities';
import { SmartFilters } from '../../../../common/interfaces/SmartFilters';
import translate from '../../../../core/services/translate';
import {
  AWARD_ACCEPTED,
  AWARD_DECLINED,
  NOT_AWARDED,
  COMPLETED,
  HAULER_SELECTED,
  OPEN_BIDS,
  SUBMITTED_BIDS,
  UNDER_REVIEW,
  YOUR_AWARDS,
  AWARD_COMPLETED,
  BID_REVIEW,
  CLOSED,
  CANCELLED,
  DECLINED,
  OPEN_AWARD,
  OPEN_FOR_BID,
  PENDING_HAULER_APPROVAL,
  PENDING_RUBICON_APPROVAL,
} from '../../../constants/opportunitiesStatus';

const dynamicSubtitle = (count = 0) => {
  return count === 1
    ? translate('opportunity.opportunities.opportunityCount', { count })
    : translate('opportunity.opportunities.opportunitiesCount', { count });
};

const dynamicCaution = (count = 0) => {
  return !!count && translate('autoDispatch.overdueCount', { count });
};

export const opportunitiesSmartFilters: SmartFilters<OpportunityRow, number> = [
  {
    id: OPEN_BIDS,
    title: translate('opportunity.opportunitiesSmartFilters.open'),
    dynamicSubtitle,
    dynamicCaution,
    isActive: true,
    filter(opportunity) {
      return opportunity.opportunityStatus.id === OPEN_FOR_BID && opportunity.offer.hasNoBid;
    },
    cautionFilter(opportunity) {
      return (
        opportunity.action.isRequired && opportunity.opportunityStatus.id === OPEN_FOR_BID && opportunity.offer.hasNoBid
      );
    },
  },
  {
    id: SUBMITTED_BIDS,
    title: translate('opportunity.opportunitiesSmartFilters.submittedBids'),
    dynamicSubtitle,
    dynamicCaution,
    filter(opportunity) {
      return opportunity.opportunityStatus.id === OPEN_FOR_BID && !opportunity.offer.hasNoBid;
    },
    cautionFilter(opportunity) {
      return (
        opportunity.action.isRequired &&
        opportunity.opportunityStatus.id === OPEN_FOR_BID &&
        !opportunity.offer.hasNoBid
      );
    },
  },
  {
    id: UNDER_REVIEW,
    title: translate('opportunity.opportunitiesSmartFilters.underReview'),
    dynamicSubtitle,
    dynamicCaution,
    filter(opportunity) {
      return (
        opportunity.opportunityStatus.id === BID_REVIEW || opportunity.opportunityStatus.id === PENDING_RUBICON_APPROVAL
      );
    },
    cautionFilter(opportunity) {
      return (
        opportunity.action.isRequired &&
        (opportunity.opportunityStatus.id === PENDING_RUBICON_APPROVAL ||
          opportunity.opportunityStatus.id === BID_REVIEW)
      );
    },
  },
  {
    id: YOUR_AWARDS,
    title: translate('opportunity.opportunitiesSmartFilters.yourAwards'),
    dynamicSubtitle,
    dynamicCaution,
    filter(opportunity) {
      return (
        opportunity.opportunityStatus.id === OPEN_AWARD || opportunity.opportunityStatus.id === PENDING_HAULER_APPROVAL
      );
    },
    cautionFilter(opportunity) {
      return (
        opportunity.action.isRequired &&
        (opportunity.opportunityStatus.id === OPEN_AWARD ||
          opportunity.opportunityStatus.id === PENDING_HAULER_APPROVAL)
      );
    },
  },
  {
    id: CLOSED,
    title: translate('opportunity.opportunitiesSmartFilters.closed'),
    dynamicSubtitle,
    dynamicCaution,
    filter(opportunity) {
      return (
        opportunity.opportunityStatus.id === CLOSED ||
        opportunity.opportunityStatus.id === DECLINED ||
        opportunity.opportunityStatus.id === CANCELLED ||
        opportunity.opportunityStatus.id === COMPLETED ||
        opportunity.opportunityStatus.id === AWARD_ACCEPTED ||
        opportunity.opportunityStatus.id === AWARD_DECLINED ||
        opportunity.opportunityStatus.id === AWARD_COMPLETED ||
        opportunity.opportunityStatus.id === NOT_AWARDED ||
        opportunity.opportunityStatus.id === HAULER_SELECTED
      );
    },
    cautionFilter(opportunity) {
      return (
        opportunity.action.isRequired &&
        (opportunity.opportunityStatus.id === CLOSED ||
          opportunity.opportunityStatus.id === DECLINED ||
          opportunity.opportunityStatus.id === CANCELLED ||
          opportunity.opportunityStatus.id === COMPLETED ||
          opportunity.opportunityStatus.id === AWARD_ACCEPTED ||
          opportunity.opportunityStatus.id === AWARD_DECLINED ||
          opportunity.opportunityStatus.id === AWARD_COMPLETED ||
          opportunity.opportunityStatus.id === NOT_AWARDED ||
          opportunity.opportunityStatus.id === HAULER_SELECTED)
      );
    },
  },
];

export const getSmartFilterPopoverContext = (status: number) => {
  switch (status) {
    case OPEN_BIDS:
      return translate('tooltips.open');

    case SUBMITTED_BIDS:
      return translate('tooltips.submittedBids');

    case UNDER_REVIEW:
      return translate('tooltips.underReview');

    case YOUR_AWARDS:
      return translate('tooltips.yourAwards');

    case CLOSED:
      return translate('tooltips.closed');

    default:
      break;
  }
};
