import { ChangeEvent, PureComponent } from 'react';
import { map } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } from 'src/core/constants/weekdays';
import { MultiSelect, TypedField } from '../../core/components';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import { WEEKDAYS_BY_ID } from '../../core/constants';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  restrictPickupDays?: number[];
}

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) => {
  let optionsSelected: string[] = [];

  selectedOptions.forEach(option => {
    optionsSelected.push(WEEKDAYS_BY_ID[Number(option)].middleCodeName);
  });

  return allOptionsSelected ? translate('common.allDaysOfService') : optionsSelected.join(', ');
};

class DayOfServiceMultiSelect extends PureComponent<Props> {
  onChange = (_: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
    this.props?.multiSelectProps?.onChange && this.props?.multiSelectProps?.onChange(value);
  };

  render() {
    const {
      input: { name },
      label,
      multiSelectProps,
      placeholder,
      restrictPickupDays,
      withLabel,
      withPlaceholder,
    } = this.props;

    const dayOfWeekOptions = map(WEEKDAYS_BY_ID, dayOfWeek => ({
      label: dayOfWeek.name,
      value: dayOfWeek.id,
      isDisabled:
        (dayOfWeek.id === MONDAY && restrictPickupDays?.includes(MONDAY)) ||
        (dayOfWeek.id === TUESDAY && restrictPickupDays?.includes(TUESDAY)) ||
        (dayOfWeek.id === WEDNESDAY && restrictPickupDays?.includes(WEDNESDAY)) ||
        (dayOfWeek.id === THURSDAY && restrictPickupDays?.includes(THURSDAY)) ||
        (dayOfWeek.id === FRIDAY && restrictPickupDays?.includes(FRIDAY)) ||
        (dayOfWeek.id === SATURDAY && restrictPickupDays?.includes(SATURDAY)) ||
        (dayOfWeek.id === SUNDAY && restrictPickupDays?.includes(SUNDAY)),
    }));

    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('routes.dayOfService') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('routes.dayOfService') : undefined),
          options: dayOfWeekOptions,
          formatText,
          ...multiSelectProps,
        }}
      />
    );
  }
}

export default DayOfServiceMultiSelect;
