import { AnyAction } from 'redux';

import update from 'immutability-helper';
import { ThunkDispatch } from 'redux-thunk';

import { PICKUP_STATUSES } from 'src/common/constants';
import applyBatching from 'src/common/services/applyBatching';
import { TODAY_FORMATTED } from 'src/core/constants';
import { ON_HOLD_ROUTE_ID, UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import { loadOnHoldJobs } from 'src/routes/services/dispatchBoardOnHoldJobs';
import {
  addToRouteOrRouteTemplate,
  checkDuplicateJobs,
  loadRoutesJobs,
  transferRouteJobs,
} from 'src/routes/services/dispatchBoardRouteJobs';
import { loadUnassignedJobs } from 'src/routes/services/dispatchBoardUnassignedJobs';
import { getRandomHexColor } from 'src/utils/randomColor';

// Actions
const TOGGLE_ROUTE = 'dispatchBoard/map/TOGGLE_ROUTE';
const TOGGLE_ROUTES = 'dispatchBoard/map/TOGGLE_ROUTES';
const CLEAR_ROUTES = 'dispatchBoard/map/CLEAR_ROUTES';
const OPEN_MAP = 'dispatchBoard/map/OPEN_MAP';
const CLOSE_MAP = 'dispatchBoard/map/CLOSE_MAP';
const START_LOAD = 'dispatchBoard/map/START_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/map/COMPLETE_LOAD';
const FAIL_LOAD = 'dispatchBoard/map/FAIL_LOAD';
const SEARCH_JOBS = 'dispatchBoard/map/SEARCH_JOBS';
const SELECT_JOBS = 'dispatchBoard/map/SELECT_JOBS';
const SET_DUPLICATE_JOBS = 'dispatchBoard/map/SET_DUPLICATE_JOBS';

const START_BATCH_TRANSFER = 'dispatchBoard/map/START_BATCH_TRANSFER';
const BATCH_UPDATE = 'dispatchBoard/map/BATCH_UPDATE';
const COMPLETE_BATCH_TRANSFER = 'dispatchBoard/map/COMPLETE_BATCH_TRANSFER';

const mapJobRoutes = (jobRoutes: any[], selectedRoutes: any[], routeColors: any[]) =>
  jobRoutes.map(j => ({
    ...j,
    routeName: selectedRoutes?.find(sr => sr.id === j.routeId)?.name,
    routeColor: routeColors[j.routeId],
  }));

const getRouteColors = (routes: any) =>
  routes.reduce((result: any, route: any) => ({ ...result, [route.id]: getRandomHexColor() }), {});

interface State {
  allJobRoutes: any[];
  batchTransfererInProgress: boolean;
  batchTransfererProgress: number;
  duplicateRouteId?: number;
  hasDuplicateJobs: boolean;
  isLoading: boolean;
  isMapActive: boolean;
  jobRoutes: any[];
  routeColors: any[];
  selectedJobs: any[];
  selectedRoutes: any[];
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState: State = {
  allJobRoutes: [],
  batchTransfererInProgress: false,
  batchTransfererProgress: 0,
  duplicateRouteId: undefined,
  hasDuplicateJobs: false,
  routeColors: [],
  isLoading: false,
  isMapActive: false,
  jobRoutes: [],
  selectedJobs: [],
  selectedRoutes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_ROUTE: {
      const existingRoute = state.selectedRoutes.find(r => r.id === action.route.id);

      const selectedRoutes =
        existingRoute != null
          ? state.selectedRoutes.filter(r => r !== existingRoute)
          : [...state.selectedRoutes, action.route];

      return update(state, {
        $merge: {
          selectedRoutes,
        },
      });
    }
    case TOGGLE_ROUTES: {
      return update(state, {
        $merge: {
          selectedRoutes: action.routes,
        },
      });
    }

    case CLEAR_ROUTES:
      return update(state, {
        $merge: {
          selectedRoutes: [],
        },
      });

    case OPEN_MAP: {
      const selectedRoutes = action.route ? [action.route] : state.selectedRoutes;

      return update(state, {
        $merge: {
          isMapActive: true,
          routeColors: getRouteColors(selectedRoutes),
          selectedRoutes,
          jobRoutes: [],
        },
      });
    }

    case CLOSE_MAP:
      return update(state, {
        $merge: {
          isMapActive: false,
        },
      });

    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD: {
      const jobs = mapJobRoutes(action.jobRoutes, state.selectedRoutes, state.routeColors);
      return update(state, {
        $merge: {
          isLoading: false,
          jobRoutes: jobs,
          allJobRoutes: jobs,
        },
      });
    }
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case SEARCH_JOBS: {
      if (!action.value) {
        return update(state, {
          $merge: {
            jobRoutes: state.allJobRoutes,
          },
        });
      }
      const searchTerm = action.value.toLowerCase();

      const jobRoutes = state.allJobRoutes.filter(
        job =>
          (job.jobType && job.jobType.toLowerCase().indexOf(searchTerm) !== -1) ||
          (job.jobStatusId && PICKUP_STATUSES[job.jobStatusId].name.toLowerCase().indexOf(searchTerm) !== -1) ||
          (job.customerName && job.customerName.toLowerCase().indexOf(searchTerm) !== -1) ||
          (job.locationAddress.formattedAddress &&
            job.locationAddress.formattedAddress.toLowerCase().indexOf(searchTerm) !== -1) ||
          (!!job.workOrderNumber && job.workOrderNumber.toLowerCase().indexOf(searchTerm) !== -1),
      );

      return update(state, {
        $merge: {
          jobRoutes,
        },
      });
    }

    case SELECT_JOBS:
      return update(state, {
        $merge: {
          selectedJobs: action.jobs,
        },
      });

    case START_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: true,
        },
      });
    case COMPLETE_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: false,
        },
      });
    case BATCH_UPDATE:
      return update(state, {
        $merge: {
          batchTransfererProgress: action.progress,
        },
      });

    case SET_DUPLICATE_JOBS:
      return update(state, {
        $merge: {
          hasDuplicateJobs: action.hasDuplicateJobs,
          duplicateRouteId: action.routeId,
        },
      });

    default:
      return state;
  }
};

// Action creators
export const toggleRoute = (route: any) => ({
  type: TOGGLE_ROUTE,
  route,
});

export const toggleRoutes = (routes: any[]) => ({
  type: TOGGLE_ROUTES,
  routes,
});

export const openMap = () => ({
  type: OPEN_MAP,
});

export const openMapWithRoute = (route: any) => ({
  type: OPEN_MAP,
  route,
});

export const closeMap = () => ({
  type: CLOSE_MAP,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (jobRoutes: any[]) => ({
  type: COMPLETE_LOAD,
  jobRoutes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startBatchTransfer = () => ({
  type: START_BATCH_TRANSFER,
});

const completeBatchTransfer = () => ({
  type: COMPLETE_BATCH_TRANSFER,
});

const batchUpdate = (progress: number) => ({
  type: BATCH_UPDATE,
  progress,
});

export const setDuplicateJobs = (hasDuplicateJobs: boolean, routeId?: number) => ({
  type: SET_DUPLICATE_JOBS,
  hasDuplicateJobs,
  routeId,
});

export const loadDispatchBoardJobs =
  (routeIds: any[], vendorId: number, vehicleTypeId: number, searchTerm?: string, date?: string | Date) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());

    const promises = [loadRoutesJobs(routeIds.join(','), searchTerm)];

    const shouldLoadUnassignedJobs = routeIds.find(id => id === UNASSIGNED_ROUTE_ID) === UNASSIGNED_ROUTE_ID;
    if (shouldLoadUnassignedJobs) {
      promises.push(loadUnassignedJobs(vendorId, vehicleTypeId, searchTerm));
    }

    const shouldLoadOnHoldJobs = routeIds.find(id => id === ON_HOLD_ROUTE_ID) === ON_HOLD_ROUTE_ID;
    if (shouldLoadOnHoldJobs) {
      promises.push(loadOnHoldJobs(vendorId, date || TODAY_FORMATTED, [], searchTerm));
    }

    const promise = Promise.all(promises);

    promise
      .then(jobRoutes => {
        const jobs = jobRoutes
          .flat()
          .map(j =>
            j.routeId == null
              ? { ...j, routeId: j.isUnassignedJob ? UNASSIGNED_ROUTE_ID : j.isOnHoldJob ? ON_HOLD_ROUTE_ID : null }
              : j,
          );
        return dispatch(completeLoad(jobs));
      })
      .catch(() => dispatch(failLoad()));

    return promise;
  };

export const searchJobs = (value: string) => ({
  type: SEARCH_JOBS,
  value,
});

export const selectJobs = (jobs: any[]) => ({
  type: SELECT_JOBS,
  jobs,
});

export const clearSelectedJobs = () => ({
  type: SELECT_JOBS,
  jobs: [],
});

export const assignJobsToRoute = (routeId: number, jobIds: any[], position: number) => () =>
  transferRouteJobs(jobIds, routeId, position);

export const assignJobsToRouteOrRouteTemplate = (request: any) => async (dispatch: Dispatch) => {
  dispatch(startBatchTransfer());

  const batchSize = 50;
  let position = request.position;

  if (request.shouldCheckForDuplicates) {
    const checkResult = await checkDuplicateJobs({
      jobs: request.jobs.map((j: any) => ({
        id: j.id,
        serviceContractId: j.serviceContractId,
        isAssigned: !j.isUnassigned,
      })),
      routeId: request.isTemplate ? null : request.routeId,
      routeTemplateId: request.isTemplate ? request.routeTemplateId : null,
    });
    const hasDuplicateJobs = !!checkResult.length;
    dispatch(setDuplicateJobs(hasDuplicateJobs, request.routeId));

    if (hasDuplicateJobs) {
      dispatch(completeBatchTransfer());
      return false;
    }
  }
  const promise = applyBatching(request.jobs, batchSize, async (batchJobs, progress) => {
    await addToRouteOrRouteTemplate({
      ...request,
      jobs: batchJobs,
      position,
    });
    position += batchSize;
    dispatch(batchUpdate(progress));
  });

  promise.then(() => dispatch(completeBatchTransfer())).catch(() => dispatch(completeBatchTransfer()));
  return promise;
};

export const clearSelectedRoutes = () => ({
  type: CLEAR_ROUTES,
});
