import { find, forEach } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  clearDashboardModalMapSelectedFeature,
  setDashboardModalMapViewport,
  setModalMapNavigationMapControl,
  setModalMapSatelliteMapControl,
} from 'src/dashboard/ducks/mapControls';
import { getMapBounds } from 'src/common/components/map/util';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLWrapper } from 'src/customers/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import MapModalDataInformation from './MapModalDataInformation';
import MapModalRoutesLegend from './MapModalRoutesLegend';
import VehiclePositionsGL from './VehiclePositions/VehiclePositionsGL';
import VehicleTrackingsGL from './VehicleTrackings/VehicleTrackingsGL';

const ModalMapGL = () => {
  const dispatch = useDispatch();

  const [map, setMap] = useState<mapboxgl.Map>();
  const vehiclePositions = useSelector(state => state.dashboard.vehiclesData.vehiclesList);
  const vehicleTrackings = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbsByRoutes);
  const vehicleTrackingsForRouteVehicle = useSelector(state => state.dashboard.routesData.vehicleFullTrackings);
  const routeVehiclePositions = useSelector(state => state.dashboard.routesData.routeVehiclePositions);

  const { vehicleId, isSourceRoute: isRouteVehicle } = useSelector(state => state.dashboard.mapControls.mapModalData);

  const viewport = useSelector(state => state.dashboard.mapControls.modalMapViewport);

  const setNavigationRef = (element: HTMLDivElement | null) => {
    if (element) {
      dispatch(
        setModalMapNavigationMapControl({
          width: element.offsetWidth,
          height: element.offsetHeight,
          isVisible: true,
        }),
      );
    } else {
      dispatch(setModalMapNavigationMapControl({ isVisible: false }));
    }
  };

  const setIsSatelliteView = (isSatelliteView: boolean) => {
    dispatch(setModalMapSatelliteMapControl(isSatelliteView));
  };

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If any cluster is clicked, then any popup
             * should be closed.
             */
            !!feature.properties?.cluster_id,
        );

        if (features.length) {
          dispatch(clearDashboardModalMapSelectedFeature());
        }
      });

      map.on('mousemove', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true || !!feature.properties?.cluster_id,
        );

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch]);

  const selectedVehicle = useMemo(() => {
    if (!vehicleId) {
      return null;
    }
    if (isRouteVehicle) {
      return find(routeVehiclePositions, { vehicleId });
    }
    return find(vehiclePositions, { id: vehicleId });
  }, [isRouteVehicle, routeVehiclePositions, vehicleId, vehiclePositions]);

  // fit map bounds
  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    if (selectedVehicle) {
      points.push({ latitude: selectedVehicle.latitude, longitude: selectedVehicle.longitude });
    }

    if (vehicleTrackings && !isRouteVehicle) {
      forEach(vehicleTrackings.routes, vehicleTracking => {
        forEach(vehicleTracking.coords, point => {
          forEach(point, coord => {
            points.push({ latitude: coord.lat, longitude: coord.lng });
          });
        });
      });
    }

    if (vehicleTrackingsForRouteVehicle && isRouteVehicle) {
      forEach(vehicleTrackingsForRouteVehicle.coords, coords => {
        forEach(coords, coord => {
          points.push({ latitude: coord.lat, longitude: coord.lng });
        });
      });
    }

    if (points.length) {
      const bounds = getMapBounds(points, {
        padding: 10,
        capZoom: 17,
      });

      dispatch(setDashboardModalMapViewport(bounds));
    }
  }, [dispatch, isRouteVehicle, selectedVehicle, vehicleTrackings, vehicleTrackingsForRouteVehicle]);

  return (
    <MapGLWrapper>
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={setIsSatelliteView}
        setNavigationRef={setNavigationRef}
      >
        <MapModalDataInformation />
        {!isRouteVehicle && <MapModalRoutesLegend />}
        {map && (
          <>
            <VehiclePositionsGL map={map} />
            <VehicleTrackingsGL map={map} />
          </>
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default ModalMapGL;
