import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const NOT_SWEEPING = 1;
export const ON_CURB = 2;
export const OFF_CURB = 3;
export const UNDER_REVIEW = 4;
export const OFF_CURB_OBSTACLE = 99;
export const ON_CURB_ICON = 'completed';
export const OFF_CURB_ICON = 'cancelled';
export const NOT_SWEEPING_ICON = 'notSweeping';
export const OFF_CURB_OBSTACLE_ICON = 'exceptionsObstacles';

export const SERVICE_MODES = mapKeys(
  [
    { id: NOT_SWEEPING, name: translate('dashboard.serviceModes.notSweeping') },
    { id: ON_CURB, name: translate('dashboard.serviceModes.onCurb') },
    { id: OFF_CURB, name: translate('dashboard.serviceModes.offCurb') },
    { id: UNDER_REVIEW, name: translate('dashboard.serviceModes.underReview') },
    { id: OFF_CURB_OBSTACLE, name: translate('dashboard.serviceModes.offCurbObstacle') },
  ],
  'id',
);
