import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RouteTrackerIssue } from '../interfaces/RouteTrackerIssue';
import { loadRouteTrackerIssues as doLoadRouteTrackerIssues } from '../services/loadRouteTrackerIssues';

// Actions
const START_LOAD = 'routes/routeTrackerIssues/START_LOAD';
const COMPLETE_LOAD = 'routes/routeTrackerIssues/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeTrackerIssues/FAIL_LOAD';
const RESET = 'routes/routeTrackerIssues/RESET';

interface State {
  isLoading: boolean;
  routeTrackerIssues: RouteTrackerIssue[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  routeTrackerIssues: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTrackerIssues: action.routeTrackerIssues,
        },
      });

    case FAIL_LOAD:
    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeTrackerIssues: RouteTrackerIssue[]) => ({
  type: COMPLETE_LOAD,
  routeTrackerIssues,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteTrackerIssues = (routeId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const routeTrackerIssuesPromise = doLoadRouteTrackerIssues(routeId);
  routeTrackerIssuesPromise
    .then(routeTrackerIssues => dispatch(completeLoad(routeTrackerIssues)))
    .catch(() => dispatch(failLoad()));
  return routeTrackerIssuesPromise;
};

export const resetRouteTrackerIssues = () => ({
  type: RESET,
});
