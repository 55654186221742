import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { get, map } from 'lodash-es';

import { AGREEMENT, CERTIFICATIONS, COMPLIANCE, DOCUMENT_TYPE_OPTIONS } from '../../constants';
import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn } from '../../../core/components/styled';
import { Dropdown, Input, FileInput, DatePicker } from '../../../core/components';
import { isFile, isRequired, isDateValidValidator } from '../../../utils/services/validator';
import { COI, W9 } from '../../constants/documentTypesOption';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import moment from 'moment';

const allowedFileTypes = '.doc,.csv,.jpg,.png,.pdf,.xls,.xlsx';

interface ComponentProps {
  uploadFileSource: string;
  documentType?: string;
}

interface PropsWithoutReduxForm extends ComponentProps {
  formValues: any;
}
interface State {
  changedInput?: string;
  prevValues?: any;
  currentValues?: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;
class UploadFileForm extends PureComponent<Props, State> {
  readonly state: State = {
    changedInput: '',
    prevValues: {
      from: '',
      to: '',
    },
    currentValues: {
      from: '',
      to: '',
    },
  };

  componentDidUpdate() {
    const { change, formValues } = this.props;
    const { prevValues, currentValues, changedInput } = this.state;
    const fromDate = formValues?.validFrom;
    const toDate = formValues?.validTo;
    const existsDateValues = fromDate && toDate;
    const existsPrevDateValues = prevValues.from && prevValues.to;
    if (fromDate && !currentValues.from) {
      this.setState({
        currentValues: { from: fromDate },
      });
    }
    if (
      changedInput === 'toDate' &&
      (fromDate || currentValues.from) &&
      toDate &&
      moment(toDate).diff(moment(fromDate), 'days') < 0 &&
      moment(prevValues.to).format('MM/DD/YYYY') === moment(toDate).format('MM/DD/YYYY')
    ) {
      this.setState({
        prevValues: {
          from: currentValues.from,
          to: null,
        },
        currentValues: { from: currentValues.from, to: null },
      });
      change('validTo', null);
    }

    if ((!currentValues.from || currentValues.from === 'Invalid date') && currentValues.to) {
      this.setState({
        prevValues: {
          from: currentValues.from,
          to: null,
        },
        currentValues: { from: currentValues.from, to: null },
      });
      change('validTo', null);
    }

    if (
      (prevValues.effectiveDate !== currentValues.effectiveDate ||
        prevValues.expirationDate !== currentValues.expirationDate) &&
      existsDateValues &&
      existsPrevDateValues
    ) {
      if (
        changedInput === 'fromDate' &&
        prevValues.from &&
        moment(currentValues.from).diff(moment(currentValues.to), 'days') > 0
      ) {
        this.setState({
          prevValues: {
            from: currentValues.from,
            to: null,
          },
          currentValues: { from: currentValues.from, to: null },
        });
        change('validTo', null);
      } else if (changedInput === 'toDate' && prevValues.to && moment(toDate).diff(moment(fromDate), 'days') < 0) {
        this.setState({
          prevValues: {
            from: prevValues.from,
            to: prevValues.to,
          },
          currentValues: { from: currentValues.from, to: prevValues.to },
        });
        change('validTo', moment(prevValues.to));
      }
    }
  }

  render() {
    const { handleSubmit, formValues, uploadFileSource, documentType } = this.props;
    const { currentValues } = this.state;
    const documentTypes = map(DOCUMENT_TYPE_OPTIONS, option => ({ label: option.name, value: option.id }));
    const descriptionGridSize = uploadFileSource === COMPLIANCE ? '12/12' : '6/12';

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Grid>
          <GridColumn size="12/12">
            <Field
              name="file"
              component={FileInput}
              type="text"
              accept={allowedFileTypes}
              validate={[isFile, isRequired]}
              margin="sMedium no sMedium no"
            />
          </GridColumn>
        </Grid>

        <Grid>
          {uploadFileSource === AGREEMENT && (
            <GridColumn size="6/12">
              <Field
                name="documentType"
                component={Dropdown}
                label={translate('haulerProfile.filesUpload.documentLabel')}
                type="text"
                options={documentTypes}
                validate={[isRequired]}
              />
            </GridColumn>
          )}
          {uploadFileSource === CERTIFICATIONS && (
            <GridColumn size="6/12">
              <Field
                name="certificationNumber"
                component={Input}
                label={translate('haulerProfile.certificationNo')}
                type="text"
                maxLength="30"
                validate={[isRequired]}
              />
            </GridColumn>
          )}
          <GridColumn size={descriptionGridSize}>
            <Field
              name="documentDescription"
              component={Input}
              label={translate('haulerProfile.filesUpload.documentDescription')}
              type="text"
              maxLength="60"
              validate={[isRequired]}
            />
          </GridColumn>
        </Grid>
        {documentType !== W9 && documentType !== COI && (
          <Grid>
            <GridColumn size="6/12">
              <Field
                onChange={(e: any) => {
                  this.setState(prev => ({
                    changedInput: 'fromDate',
                    prevValues: {
                      from: prev.currentValues.from ? prev.currentValues.from : moment(e).format('MM/DD/YYYY'),
                      to: currentValues.to,
                    },
                    currentValues: { from: moment(e).format('MM/DD/YYYY'), to: currentValues.to },
                  }));
                }}
                name="validFrom"
                component={DatePicker}
                label={translate('common.startDate')}
                disabledDays={get(formValues, 'validTo') && [{ after: new Date(get(formValues, 'validTo')) }]}
                validate={[isRequired, isDateValidValidator]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                onChange={(e: any) =>
                  this.setState(prev => ({
                    changedInput: 'toDate',
                    prevValues: {
                      from: currentValues.from,
                      to: prev.currentValues.to ? prev.currentValues.to : moment(e).format('MM/DD/YYYY'),
                    },
                    currentValues: { from: currentValues.from, to: moment(e).format('MM/DD/YYYY') },
                  }))
                }
                name="validTo"
                component={DatePicker}
                label={translate('common.endDate')}
                disabledDays={get(formValues, 'validFrom') && [{ before: new Date(get(formValues, 'validFrom')) }]}
                validate={[isRequired, isDateValidValidator]}
              />
            </GridColumn>
          </Grid>
        )}
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  formValues: getFormValues('uploadFile')(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'uploadFile',
    onSubmitFail: focusFirstInvalidField,
    initialValues: {},
  })(UploadFileForm),
);
