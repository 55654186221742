import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, getFormValues } from 'redux-form';

import { Grid, GridColumn, ButtonSet, Button, Popover } from '../../../core/components/styled';
import { TableActionButton } from '../../../core/components/styled/Table';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';

import { Checkbox, ActionButtonTooltip, PopoverWrapper } from '../../../core/components';
import vehicleTypesFormInitialValuesSelector from '../../services/vehicleTypesFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { VehicleTypeWrapperColumn } from '../styled/VehiclesPage';
interface PropsWithoutReduxForm {
  vehicleTypes?: TechnicalType[];
  vehicleTypesByVendors: (TechnicalType & { count?: number })[];
  formValues: any;
  onConfigureToggle(vehicleTypeId?: number): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const VehicleTypesForm: React.FC<Props> = ({
  handleSubmit,
  vehicleTypes = [],
  vehicleTypesByVendors = [],
  onConfigureToggle,
  formValues,
  initialValues,
}) => (
  <form onSubmit={handleSubmit} noValidate>
    <Grid multiLine>
      {vehicleTypes.map(({ id, name }) => {
        const typeInitiallyDisabled =
          !initialValues[`vehicleTypeId:${id}`] || (!!formValues && !formValues[`vehicleTypeId:${id}`]);
        const typeEnabledButNotSaved = typeInitiallyDisabled && !!formValues && !!formValues[`vehicleTypeId:${id}`];
        let tooltipKey: string = 'configurePM';
        let vehicleCount: number | undefined = !typeInitiallyDisabled
          ? vehicleTypesByVendors.find(x => x.id === id)?.count
          : 0;
        let disableFlag: boolean = !!vehicleCount;
        if (typeEnabledButNotSaved) {
          tooltipKey = 'configurePMAfterSave';
        } else if (typeInitiallyDisabled) {
          tooltipKey = 'configurePMAfterEnable';
        }
        return (
          <VehicleTypeWrapperColumn key={id} size="4/12" padding="xSmall">
            <Grid>
              <GridColumn size="10/12" verticalAlign="center">
                <PopoverWrapper
                  triggerButton={
                    <Field
                      name={`vehicleTypeId:${id}`}
                      component={Checkbox}
                      props={{
                        disabled: disableFlag,
                        block: true,
                        margin: 'no',
                        size: 'medium',
                        label: name,
                      }}
                    />
                  }
                  popoverContent={
                    !!vehicleCount ? <Popover>{translate('vehicles.disableConfigVehicleType')}</Popover> : null
                  }
                />
              </GridColumn>

              <GridColumn size="2/12" align="right">
                <TableActionButton
                  cursor="not-allowed"
                  margin="no"
                  disabled={typeInitiallyDisabled}
                  color={typeInitiallyDisabled ? 'gray' : 'grayDarker'}
                  hoverColor={typeInitiallyDisabled ? 'gray' : 'brandPrimary'}
                  onClick={() => {
                    onConfigureToggle(id);
                  }}
                >
                  <ActionButtonTooltip iconSize="mMedium" icon="settingsWithoutCircle" tooltip={tooltipKey} />
                </TableActionButton>
              </GridColumn>
            </Grid>
          </VehicleTypeWrapperColumn>
        );
      })}
    </Grid>

    <ButtonSet margin="lMedium no no">
      <Button type="submit" color="primary" id="save-config-vehicle-types-button">
        {translate('common.save')}
      </Button>
    </ButtonSet>
  </form>
);

const selector = getFormValues('vehicleTypesForm');

const mapStateToProps = (state: AppState) => ({
  vehicleTypes: state.fleet.vehicleTypes.vehicleTypes,
  vehicleTypesByVendors: state.fleet.vehicleTypesForVendor.vehicleTypesForVendor,
  initialValues: vehicleTypesFormInitialValuesSelector(state.fleet.vehicleTypesForVendor.vehicleTypesForVendor),
  formValues: selector(state),
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'vehicleTypesForm',
  })(VehicleTypesForm),
);
