import React, { ChangeEvent, PureComponent } from 'react';

import { map, size } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { MultiSelect, TypedField } from '../../core/components';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

interface Props {
  input: WrappedFieldInputProps;
  dropDownOptions: any[];
  label?: string;
  multiSelectProps?: Partial<MultiSelectProps>;
  placeholder?: string;
  withLabel?: boolean;
  withPlaceholder?: boolean;
}

class InsightsMultiSelectDropdown extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    this.props.input.onChange(value);
  };

  formatText = (selectedOptions: any[], allOptionsSelected: boolean) => {
    const { dropDownOptions } = this.props;

    if (allOptionsSelected) {
      return translate('insights.allInsights');
    }

    const selectedInsight = dropDownOptions.find(option => option.id === Number(selectedOptions[0])).technicalName;

    return size(selectedOptions) === 1
      ? translate(createTranslationKey(selectedInsight, 'vendors.locationFlags'))
      : translate('insights.xInsightsSelected', { selected: size(selectedOptions) });
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      multiSelectProps,
      dropDownOptions,
    } = this.props;

    const insightsOptions = map(dropDownOptions, insight => ({
      label: translate(createTranslationKey(insight.technicalName, 'vendors.locationFlags')),
      value: insight.id,
    }));

    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          formatText: this.formatText,
          label: label || (withLabel ? translate('insights.allInsights') : undefined),
          options: insightsOptions,
          placeholder: placeholder || (withPlaceholder ? translate('insights.allInsights') : undefined),
          ...multiSelectProps,
        }}
      />
    );
  }
}

export default InsightsMultiSelectDropdown;
