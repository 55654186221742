import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Resolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { loadStrobeImages } from '../../ducks';
import StrobeImagesModal from './StrobeImagesModal';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { loadVehicle } from '../../../fleet/ducks';
import { AppState } from '../../../store';

interface Props {
  closeModal: () => void;
  loadStrobeImages: (vendorId: number, vehicleId: number, timeStamp?: string) => Promise<any>;
  loadVehicle: (vehicleId: number) => Promise<any>;
  shouldDisplayDownloadVideo?: boolean;
  timeStamp?: string;
  vehicleId: number;
  vehicleName?: string;
  vendorId: number;
  videoDeviceTypeId?: number;
  deviceId?: string;
}

class StrobeImagesModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { vendorId, vehicleId, timeStamp, loadVehicle, loadStrobeImages } = this.props;
    return Promise.all([loadVehicle(vehicleId), loadStrobeImages(vendorId, vehicleId, timeStamp)]);
  };

  render() {
    const { closeModal, shouldDisplayDownloadVideo, timeStamp, vehicleId, vehicleName, videoDeviceTypeId, deviceId } =
      this.props;

    return (
      <Resolver
        successComponent={StrobeImagesModal}
        successProps={{
          closeModal,
          shouldDisplayDownloadVideo,
          timeStamp,
          vehicleId,
          vehicleName,
          videoDeviceTypeId,
          deviceId,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

export default connect(mapStateToProps, { loadStrobeImages, loadVehicle })(StrobeImagesModalResolver);
