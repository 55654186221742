import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { MaterialTicketEnhanced, MaterialTicketsParams } from '../interfaces/MaterialTickets';
import {
  loadWaterFillUpTickets as doLoadWaterFillUpTickets,
  loadDisposalTickets as doLoadDisposalTickets,
  loadMaterialPickupTickets as doLoadMaterialPickupTickets,
} from '../services/materialTrips';

const START_LOAD = 'routes/materialTrips/START_LOAD';
const COMPLETE_LOAD = 'routes/materialTrips/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/materialTrips/FAIL_LOAD';
const RESET = 'routes/materialTrips/RESET';

interface State {
  isLoading: boolean;
  materialTrips: MaterialTicketEnhanced[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

const initialState = {
  isLoading: false,
  materialTrips: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          materialTrips: action.materialTrips,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          materialTrips: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          materialTrips: [],
        },
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (materialTrips: MaterialTicketEnhanced[]) => ({
  type: COMPLETE_LOAD,
  materialTrips,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetMaterialTrips = () => ({
  type: RESET,
});

type TicketType = 'waterFillUpTickets' | 'disposalTickets' | 'materialPickupTickets';
// Thunks

export const loadMaterialTrips = (type: TicketType, params: MaterialTicketsParams) => async (dispatch: Dispatch) => {
  dispatch(startLoad());

  let loadTicketsPromise;
  switch (type) {
    case 'waterFillUpTickets':
      loadTicketsPromise = doLoadWaterFillUpTickets(params);
      break;
    case 'disposalTickets':
      loadTicketsPromise = doLoadDisposalTickets(params);
      break;
    case 'materialPickupTickets':
      loadTicketsPromise = doLoadMaterialPickupTickets(params);
      break;
    default:
      loadTicketsPromise = doLoadWaterFillUpTickets(params);
      break;
  }

  loadTicketsPromise
    .then((materialTrips: MaterialTicketEnhanced[]) => {
      dispatch(completeLoad(materialTrips));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return loadTicketsPromise;
};
