import { FC } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { Button, ButtonSet } from '../../../../../core/components/styled';
import translate from '../../../../../core/services/translate';

interface Props {}

const PlanARoute: FC<Props> = () => {
  const dispatch = useDispatch();

  return (
    <ButtonSet vertical margin="no">
      <>
        <Button
          onClick={() => dispatch(push('/routes/route-tracker/create'))}
          color="primary"
          id="plan-daily-route-button"
        >
          {translate('routes.dailyRoute')}
        </Button>
        <Button
          onClick={() => dispatch(push('/routes/route-templates/create'))}
          color="primary"
          margin="small no no"
          id="plan-template-route-button"
        >
          {translate('routes.routeTemplate')}
        </Button>
      </>
    </ButtonSet>
  );
};

export default PlanARoute;
