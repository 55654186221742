import { Component } from 'react';

import { WrappedFieldProps } from 'redux-form';
import { find, get, indexOf } from 'lodash-es';

import {
  FormError,
  FormGroup,
  FormGroupClear,
  FormGroupClearContainer,
  FormLabel,
  Grid,
  GridColumn,
  Input as FormInput,
} from './styled';
import { GOOGLE as google } from '../../common/constants';
import translate from '../services/translate';

interface Props extends WrappedFieldProps {
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  instantValidation?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  label?: string;
  margin?: string;
  placeholder?: string;
  setEmptyValue?: () => void;
  withPlaceholder?: boolean;
  fullWidth?: boolean;
}

class LocationPicker extends Component<Props, { value: string }> {
  static defaultProps = {
    setEmptyValue: () => {},
    instantValidation: false,
    withPlaceholder: false,
    readOnly: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = { value: this.getFormattedAddress() };
  }

  autocomplete: any = null;

  disableAutoComplete = () => {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
      document.getElementsByClassName('pac-container')[0].remove();
    }
  };

  enableAutoComplete = () => {
    const {
      input: { onChange },
    } = this.props;

    this.autocomplete = new google.maps.places.Autocomplete(this.locationPickerRef, { types: ['address'] });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      const location = this.getLocation(place);
      this.setState({ value: location.formattedAddress || '' });
      if (location.formattedAddress) {
        onChange({ ...this.props.input.value, ...location });
      }
    });
  };

  componentDidMount() {
    if (!this.props.readOnly) this.enableAutoComplete();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.input.value !== this.props.input.value) {
      if (this.props.input.value && this.props.input.value.formattedAddress) {
        this.setState({ value: this.props.input.value.formattedAddress });
      } else {
        this.setState({ value: this.props.input.value });
      }
    }
    if (prevProps.readOnly !== this.props.readOnly) {
      if (this.props.readOnly) this.disableAutoComplete();
      else this.enableAutoComplete();
    }
  }

  onChange = (event: any) => {
    if (event.target.value === '' && this.props.setEmptyValue) this.props.setEmptyValue();
    this.setState({ value: event.target.value });
  };

  onBlur = () => {
    this.setState({ value: this.getFormattedAddress() || this.state.value });
  };

  getFormattedAddress = () => this.props.input.value.formattedAddress || '';

  getLocation = (place: any) => ({
    city: this.getCity(place),
    country: this.getCountry(place),
    formattedAddress: place.formatted_address,
    latitude: place.geometry ? place.geometry.location.lat() : undefined,
    longitude: place.geometry ? place.geometry.location.lng() : undefined,
    state: this.getState(place),
    street: this.getStreet(place),
    streetNumber: this.getStreetNumber(place),
    zip: this.getZip(place),
  });

  getStreetNumber = (place: any) => this.getAddressComponent(place, 'street_number');
  getStreet = (place: any) => this.getAddressComponent(place, 'route');
  getCity = (place: any) =>
    this.getAddressComponent(place, 'locality') ||
    this.getAddressComponent(place, 'sublocality') ||
    this.getAddressComponent(place, 'neighborhood') ||
    this.getAddressComponent(place, 'administrative_area_level_3');

  getState = (place: any) => this.getAddressComponent(place, 'administrative_area_level_1');
  getCountry = (place: any) => this.getAddressComponent(place, 'country');
  getZip = (place: any) => this.getAddressComponent(place, 'postal_code');

  getAddressComponent = (place: any, type: any) => {
    const addressComponent = find(
      place.address_components,
      addressComponent => indexOf(addressComponent.types, type) > -1,
    );

    return get(addressComponent, 'short_name', '');
  };

  locationPickerRef: any = null;

  setLocationPickerRef = (element: any) => {
    this.locationPickerRef = element;
  };

  clearInput = () => {
    const {
      input: { onChange },
    } = this.props;
    this.setState({ value: '' });
    onChange(null);
  };

  render() {
    const {
      disabled,
      input: { name },
      instantValidation,
      isClearable,
      isLoading,
      label,
      margin,
      meta: { dirty, asyncValidating, submitFailed, error },
      placeholder,
      withPlaceholder,
      readOnly,
      fullWidth,
      ...props
    } = this.props;

    const { value } = this.state;
    return (
      <FormGroup
        hasValue={value}
        isLoading={isLoading || asyncValidating}
        margin={margin}
        width={fullWidth ? '100%' : undefined}
      >
        {!!label && !placeholder && <FormLabel>{label}</FormLabel>}

        <Grid padding="no">
          <GridColumn padding="no" size="12/12" color="#fff">
            <FormGroupClearContainer>
              <FormInput
                disabled={disabled}
                name={name}
                onBlur={this.onBlur}
                onChange={this.onChange}
                placeholder={placeholder || (withPlaceholder ? translate('common.address') : '')}
                readOnly={readOnly}
                ref={this.setLocationPickerRef}
                type="text"
                value={value}
                rightPadding={isClearable ? '32px' : undefined}
                {...props}
              />

              {isClearable && !!value && <FormGroupClear smallMargin disabled={disabled} onClick={this.clearInput} />}
            </FormGroupClearContainer>
          </GridColumn>
        </Grid>

        {(submitFailed || (dirty && instantValidation)) && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

export default LocationPicker;
