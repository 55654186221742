import { change, Field, reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { map, size } from 'lodash-es';
import { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DELIVERY_UTILITY_ID, FRONT_LOAD_ID, RESIDENTIAL_ID } from 'src/fleet/constants/vehicleTypes';
import { Dropdown, DatePicker, Input, TypedField } from '../../../core/components';
import { DuckFunction } from 'src/contracts/ducks';
import { getComboRouteOptions } from './utils/CustomOptionsUtils';
import { Grid, GridColumn, PanelSection, Button, ButtonSet } from '../../../core/components/styled';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { JOB_IS_NOT_PENDING_ID, JOB_IS_PENDING_ID } from 'src/routes/constants/jobStatuses';
import { loadRoutesForReassignment } from '../../ducks';
import { ROLL_OFF_ID } from 'src/fleet/constants';
import { SCHEDULED } from 'src/routes/constants/routeStatuses';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import translate from '../../../core/services/translate';

interface ComponentProps extends RouteComponentProps<{ routeId: string }> {
  change: any;
  isLoading: boolean;
  loadRoutesForReassignment: DuckFunction<typeof loadRoutesForReassignment>;
  routesForReassignment: any[];
  vehicleTypeId: number;
  vendorId: number;
  wasteMaterialTypeId: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

interface State {
  isOptimizedOptionHidden: boolean;
}

class TransferRouteLocationsForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOptimizedOptionHidden: false,
    };
  }

  loadAvailableRoutesForReassignment = (event: any, value: string) => {
    const {
      vendorId,
      vehicleTypeId,
      wasteMaterialTypeId,
      match: {
        params: { routeId },
      },
      loadRoutesForReassignment,
    } = this.props;
    loadRoutesForReassignment(vendorId, routeId, vehicleTypeId, wasteMaterialTypeId, value);
  };

  changeTargetRouteId = (event: any, value: any) => {
    const { change, routesForReassignment } = this.props;
    let routeStatusTypeId = value.routeStatusTypeId;
    let hasStopsInPendingOptimization = value.hasStopsInPendingOptimization;

    if (typeof value === 'number') {
      const currentTargetRoute = (routesForReassignment as any).filter((route: any) => route.id === value)[0];
      routeStatusTypeId = currentTargetRoute.routeStatusTypeId;
      hasStopsInPendingOptimization = currentTargetRoute.hasStopsInPendingOptimization;
    }

    this.setState({
      isOptimizedOptionHidden: routeStatusTypeId !== SCHEDULED,
    });

    change('hasStopsInPendingOptimization', hasStopsInPendingOptimization ? JOB_IS_PENDING_ID : JOB_IS_NOT_PENDING_ID);
  };

  render() {
    const { isLoading, routesForReassignment, handleSubmit, vehicleTypeId } = this.props;
    const { isOptimizedOptionHidden } = this.state;

    const routesForReassignmentOptions = map(
      routesForReassignment,
      ({ name, id, vehicleTypeId, routeStatusTypeId }) => ({
        label: name,
        value: id,
        vehicleTypeId: vehicleTypeId,
        routeStatusTypeId: routeStatusTypeId,
      }),
    );

    const groupedCustomOptions = [
      ...(vehicleTypeId === FRONT_LOAD_ID || vehicleTypeId === RESIDENTIAL_ID || vehicleTypeId === DELIVERY_UTILITY_ID
        ? getComboRouteOptions(vehicleTypeId, routesForReassignment, false, true)
        : routesForReassignmentOptions),
    ];

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="medium no no" vertical isLoading={isLoading}>
          <Grid centered>
            <GridColumn size="6/12">
              <Field
                name="routeDate"
                component={DatePicker}
                label={translate('common.date')}
                validate={[isRequired, isDateValidValidator]}
                onChange={this.loadAvailableRoutesForReassignment}
                inputProps={{ id: 'transfer-stops-date' }}
              />

              <Field
                name="targetRouteId"
                component={Dropdown}
                options={groupedCustomOptions}
                label={translate('routes.targetRoute')}
                disabled={!size(groupedCustomOptions)}
                onChange={this.changeTargetRouteId}
                validate={[isRequired]}
                inputProps={{ id: 'transfer-stops-target' }}
              />
              <Field margin="no" name="hasStopsInPendingOptimization" component={Input} type="hidden" />

              <TypedField
                name="positionTypeId"
                component={JobPositionTypeDropdown}
                props={{
                  isOptimizedOptionHidden: vehicleTypeId === ROLL_OFF_ID || isOptimizedOptionHidden,
                  withLabel: true,
                  dropdownProps: {
                    margin: 'no no small',
                  },
                }}
              />
            </GridColumn>
          </Grid>

          <ButtonSet margin="lMedium no no">
            <Button color="primary" id="transfer-stops-button">
              {translate('routes.transferStops')}
            </Button>
          </ButtonSet>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { vehicleTypeId, wasteMaterialTypeId } = getFormValues('routeEditor')(state) as any;

  return {
    vehicleTypeId,
    wasteMaterialTypeId,
    isLoading: state.routes.routesForReassignment.isLoading,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    routesForReassignment: state.routes.routesForReassignment.routesForReassignment as any,
  };
};

const mapDispatchToProps = {
  change,
  loadRoutesForReassignment,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, ComponentProps>({
      form: 'transferRouteLocations',
      enableReinitialize: true,
    })(TransferRouteLocationsForm),
  ),
);
