import { memo } from 'react';
import { Source, Layer } from 'react-map-gl';

import { ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW } from 'src/customers/constants/streetNetwork';
import { RouteSegmentFeatureProperties } from './utils';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString, RouteSegmentFeatureProperties>;
};

export default memo(function StreetNetworkMapRouteSegmentsGLSource({ geoJSON }: Props) {
  return (
    <Source type="geojson" id={ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW} data={geoJSON} tolerance={0.0001}>
      <Layer
        id="routeSegments"
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW}
        paint={{
          'line-color': ['case', ['coalesce', ['feature-state', 'selected'], false], '#ff5252', ['get', 'color']],
          'line-width': 6,
          'line-opacity': ['case', ['coalesce', ['feature-state', 'selected'], false], 1, ['get', 'opacity']],
          'line-offset': ['get', 'lineOffset'],
        }}
        filter={['all', ['!=', ['get', 'hasForwardPass'], false]]}
      />
      <Layer
        id="routeSegmentsTwoWays"
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={ROUTE_MAP_ROUTE_SEGMENTS_SOURCE_SNOW_PLOW}
        filter={['all', ['!=', ['get', 'hasReversePass'], false]]}
        paint={{
          'line-color': [
            'case',
            ['coalesce', ['feature-state', 'selected'], false],
            '#ff5252',
            ['get', 'reverseSegmentColor'],
          ],
          'line-width': 6,
          'line-opacity': ['case', ['coalesce', ['feature-state', 'selected'], false], 1, ['get', 'opacity']],
          'line-offset': ['get', 'lineOffsetNegative'],
        }}
      />
    </Source>
  );
});
