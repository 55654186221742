import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';
import doLoadContainerList from '../services/containerList';
import { ContainerListItem } from '../interfaces/ContainerListItem';
// Constants
const START_LOAD = 'customers/containerList/START_LOAD';
const COMPLETE_LOAD = 'customers/containerList/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/containerList/FAIL_LOAD';
const RESET = 'customers/containerList/RESET';

// Initial State

type State = {
  containerList: ContainerListItem[];
  isLoading: boolean;
};

const initialState: State = {
  containerList: [],
  isLoading: false,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });
    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerList: action.containerList,
        },
      });
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerList: [],
        },
      });
    case RESET:
      return update(state, {
        $merge: initialState,
      });
    default:
      return state;
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerList: ContainerListItem[]) => ({
  type: COMPLETE_LOAD,
  containerList,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const reset = () => {
  return {
    type: RESET,
  };
};

// Async Actions
export const loadContainerList = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerListPromise = doLoadContainerList(vendorId);
  loadContainerListPromise
    .then(containerList => dispatch(completeLoad(containerList)))
    .catch(() => dispatch(failLoad()));
  return loadContainerListPromise;
};
