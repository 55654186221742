import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { loadRoutesTrackerIssuesSummary as doLoadRouteTrackerIssuesSummary } from '../services/loadRouteTrackerIssues';

// Actions
const START_LOAD = 'routes/routeTrackerIssuesSummary/START_LOAD';
const COMPLETE_LOAD = 'routes/routeTrackerIssuesSummary/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeTrackerIssuesSummary/FAIL_LOAD';
const RESET = 'routes/routeTrackerIssuesSummary/RESET';

interface State {
  isLoading: boolean;
  routeTrackerIssuesSummary: any;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  routeTrackerIssuesSummary: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTrackerIssuesSummary: action.routeTrackerIssuesSummary,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeTrackerIssuesSummary: any) => ({
  type: COMPLETE_LOAD,
  routeTrackerIssuesSummary,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteTrackerIssuesSummary =
  (
    vendorId: number,
    searchTerm: string,
    vehicleTypeIds: number[],
    routeStatusTypeIds: number[],
    startDate: Date | string,
    endDate: Date | string,
    useUserGlobalFilters?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const routeTrackerIssuesSummaryPromise = doLoadRouteTrackerIssuesSummary(
      vendorId,
      searchTerm,
      vehicleTypeIds,
      routeStatusTypeIds,
      startDate,
      endDate,
      useUserGlobalFilters,
    );
    routeTrackerIssuesSummaryPromise
      .then(routeTrackerIssuesSummary => dispatch(completeLoad(routeTrackerIssuesSummary)))
      .catch(() => dispatch(failLoad()));
    return routeTrackerIssuesSummaryPromise;
  };

export const resetRouteTrackerIssuesSummary = () => ({
  type: RESET,
});
