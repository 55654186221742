import { debounce, every, filter, find, map, omit } from 'lodash-es';
import moment from 'moment';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, change, getFormValues, reduxForm } from 'redux-form';

import { EquipmentSizeDropdown } from 'src/common/components';
import { CLOSED, SEARCH_BY_ACCOUNT_NUMBER, SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants';
import { ACTIVE, NEW, ON_HOLD, SUSPENDED } from 'src/common/constants/accountStatuses';
import { IN_USE } from 'src/common/constants/containerStatusTypes';
import {
  ActionButtonTooltip,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Switch,
  TypeAhead,
  TypedField,
} from 'src/core/components';
import { Button, ButtonSet, Grid, GridColumn, ModalFixedFooter, ModalSection, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { BUSINESS_TYPE_ID } from 'src/customers/constants/customerTypes';
import { loadContainerList, loadCustomersSimplified } from 'src/customers/ducks';
import useServiceContractWorkflow from 'src/customers/hooks/useServiceContractWorkflow';
import { ContainerListItem } from 'src/customers/interfaces/ContainerListItem';
import { Customer } from 'src/customers/interfaces/Customers';
import { Service } from 'src/customers/interfaces/Services';
import CreateEditContainerModalResolver from 'src/fleet/components/modal/CreateEditContainerModalResolver';
import SelectContainersModalResolver from 'src/fleet/components/modal/SelectContainersModalResolver';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { saveContainer } from 'src/fleet/ducks';
import { ContainerToSave } from 'src/fleet/interfaces/containers';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import {
  isDateValidValidator,
  isInteger,
  isNumberOrZero,
  isRequired,
  maxLength120,
  maxValueNumeric999,
  minValueNumeric0,
  minValueNumeric1,
} from 'src/utils/services/validator';
import { checkIfContainerManagementIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import TransferContainerModalResolver from '../modals/TransferContainerModalResolver';
import { CreateContainerToggle } from '../styled/AccountStatus';

export interface ServiceContractWorkflowFormValues {
  customerId?: number;
  locationId?: number;
  actions: {
    searchByAccountNumber: boolean;
    changeSize: boolean;
    updateCount: boolean;
    replaceContainer: boolean;
    manageContainers: boolean;
  };
  serviceId?: number;
  sizeId?: number;
  currentService?: Service;
  numberOfContainers?: number;
  containerNo?: string;
  changeEffectiveDate: Date | string;
  createRemovalJob: boolean;
  removalJobDate?: Date | string;
  createDeliveryJob: boolean;
  deliveryJobDate?: Date | string;
  containerManagementContainers?: any[];
}

interface PropsWithoutReduxForm {
  closeModal: (pristine: boolean) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<ServiceContractWorkflowFormValues, PropsWithoutReduxForm>;
export const SERVICE_CONTRACT_WORKFLOW_FORM = 'serviceContractWorkflowForm';

const selectSizeValidate = (value: number, allValues: ServiceContractWorkflowFormValues) => {
  if (allValues.actions.changeSize) {
    if (!value) return translate('common.validationMessages.isRequired');
    if (value === allValues.currentService?.equipmentSizeId)
      return translate('customers.alertMessages.selectDifferentEquipmentSize');
    return undefined;
  }
  return undefined;
};

const manageContainersValidate = (value: number, allValues: ServiceContractWorkflowFormValues) => {
  if (allValues.actions.manageContainers) {
    const initialContainers = allValues.currentService?.vendorContainers;
    const currentContainers = allValues.containerManagementContainers;
    // if the ids are the same, it means that the user didn't change anything in the container list so we return error

    if (
      initialContainers?.length === currentContainers?.length &&
      every(initialContainers, container => {
        const currentContainer = currentContainers?.find(currentContainer => currentContainer.id === container.id);
        return currentContainer?.locationId === container.locationId;
      })
    )
      return translate('customers.alertMessages.updateContainers');
    return undefined;
  }
  return undefined;
};

const updateContainersCountValidate = (value: number, allValues: ServiceContractWorkflowFormValues) => {
  if (allValues.actions.updateCount) {
    if (
      Number(value) === Number(allValues.currentService?.numberOfContainers) // if the number of containers is the same
    )
      return translate('customers.alertMessages.updateContainersCount');
    return undefined;
  }
  return undefined;
};

const replaceContainerValidate = (value: number, allValues: ServiceContractWorkflowFormValues) => {
  if (allValues.actions.replaceContainer) {
    if (value.toString() === allValues.currentService?.binNumber.toString())
      return translate('customers.alertMessages.updateContainerNumber');
    return undefined;
  }
  return undefined;
};

const ServiceContractWorkflowForm: FC<Props> = ({ closeModal, handleSubmit, pristine, initialValues }) => {
  const dispatch = useDispatch();
  const [isSelectContainersModalOpen, setIsSelectContainersModalOpen] = useState(false);
  const [isAddNewContainerModalOpen, setIsAddNewContainerModalOpen] = useState(false);
  const [isTransferContainerModalOpen, setIsTransferContainerModalOpen] = useState(false);
  const [selectedContainerForTransfer, setSelectedContainerForTransfer] = useState<ContainerListItem | null>(null);

  const vendorId = useSelector(currentVendorId);

  const isLoadingContainersList = useSelector(state => state.customers.containerList.isLoading);
  const containers = useSelector(state => state.customers.containerList.containerList);
  const vehicleTypesForVendor = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const customerLocations = useSelector(state => state.customers.locations.locationsForWorkflow);
  const isLoadingCustomerLocations = useSelector(state => state.customers.locations.isLoadingForWorkflow);
  const services = useSelector(state => state.customers.services.servicesForServiceContractWorkflow);
  const isLoadingServices = useSelector(state => state.customers.services.isLoading);
  const isContainerManagementFeatureEnabled = useSelector(checkIfContainerManagementIsEnabled);
  const equipmentConditions = useSelector(state => state.common.equipmentConditions.equipmentConditions);
  const isLoadingService = useSelector(state => state.customers.service.isLoading);
  const customer = useSelector(state => state.customers.customer.customer);

  const formValues = useSelector(getFormValues(SERVICE_CONTRACT_WORKFLOW_FORM)) as ServiceContractWorkflowFormValues;

  const hasDeliveryUtilityVehicle = useMemo(() => {
    return vehicleTypesForVendor.some(vehicleType => vehicleType.id === DELIVERY_UTILITY_ID);
  }, [vehicleTypesForVendor]);

  const {
    countDecreased,
    countIncreased,
    replaceContainer,
    updateCount,
    changeSize,
    manageContainers,
    selectedService,
  } = useServiceContractWorkflow(isContainerManagementFeatureEnabled, formValues);

  const customerLocationOptions = useMemo(
    () =>
      map(
        filter(customerLocations, l => l.accountStatusTypeId !== CLOSED),
        location => ({
          label: `${location.name} ${location.accountNumber ? `(${location.accountNumber})` : ''}`,
          value: location.id,
        }),
      ),
    [customerLocations],
  );

  const serviceOptions = useMemo(
    () =>
      map(
        filter(services, s => s.accountStatusTypeId !== CLOSED),
        service => {
          const isDisabled =
            !!service.scheduledChildServiceContractId ||
            (service.effectiveDate && new Date(service.effectiveDate) > new Date());

          return {
            label: `${service.serviceTypeName} / ${service.equipmentTypeName} / ${service.equipmentSizeName} / ${
              service.wasteMaterialType
            }  / ${service.numberOfContainers} ${translate('customers.containers')} ${
              service.haulerAccountNo ? `(${service.haulerAccountNo})` : ''
            }  ${isDisabled ? `(${translate('customers.alertMessages.workflowPending')})` : ''}
        `,
            value: service.id,
            disabled: isDisabled,
          };
        },
      ),
    [services],
  );

  const containersListFormatted = useMemo(
    () =>
      map(containers, container => {
        const attachedContainer = formValues?.containerManagementContainers?.find(
          ctr => ctr.id === container.id && ctr.locationId === 0,
        );

        return {
          ...container,
          locationId: attachedContainer?.locationId,
          isChecked: attachedContainer ? attachedContainer.locationId === 0 : false,
        };
      }),
    [containers, formValues?.containerManagementContainers],
  );

  // load the customers from the server based on the search term
  const loadCustomersOptions = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    const page = 1;
    const limit = 50;
    const sortedBy = 'name';
    const sortOrder = 'asc';
    const customerTypeIds = [BUSINESS_TYPE_ID].join(',');
    const customerStatusTypeIds = [ACTIVE, NEW, SUSPENDED, ON_HOLD].join(',');
    const searchType = formValues?.actions?.searchByAccountNumber
      ? SEARCH_BY_ACCOUNT_NUMBER
      : SEARCH_BY_NAME_OR_ADDRESS;

    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page,
      limit,
      sortedBy,
      sortOrder,
      searchType,
    })(dispatch).then(({ customers }: { customers: Customer[] }) => {
      onOptionsLoaded(
        map(customers, customer => ({
          label: `${customer.name} ${customer.accountNumber ? `(${customer.accountNumber})` : ''}`,
          value: customer.id,
        })),
      );
    });
  }, 500);

  const handleContainerCheckboxChange = useCallback(
    async (isSelected: boolean, id: number) => {
      const container = containersListFormatted.find(container => container.id === id);
      const containerIsInUse = container?.statusTypeId === IN_USE;
      const containerMatchesSelectedService =
        container?.equipmentSizeId === selectedService?.equipmentSizeId &&
        container?.equipmentTypeId === selectedService?.equipmentTypeId;

      if (isSelected) {
        let shouldAddContainer = true;

        if (!containerMatchesSelectedService)
          if (containerIsInUse)
            shouldAddContainer =
              (await confirm(
                translate('customers.alertMessages.selectedContainerDoesntMatcheServiceContractDetails'),
              )) && (await confirm(translate('customers.alertMessages.selectedContainerIsAlreadyAssigned')));
          else
            shouldAddContainer = await confirm(
              translate('customers.alertMessages.selectedContainerDoesntMatcheServiceContractDetails'),
            );
        else if (containerIsInUse)
          shouldAddContainer = await confirm(translate('customers.alertMessages.selectedContainerIsAlreadyAssigned'));
        else shouldAddContainer = true;

        if (!shouldAddContainer) return;

        //if container is already in the list, we just update the locationId
        const containerThatExists = formValues?.containerManagementContainers?.find(container => container.id === id);

        //update the container list in the redux form
        const updatedContainers = containerThatExists
          ? formValues?.containerManagementContainers?.map(container => {
              if (container.id === id) {
                return {
                  ...container,
                  locationId: 0,
                };
              }
              return container;
            })
          : [...(formValues?.containerManagementContainers || []), { ...container, locationId: 0 }];

        const selected = updatedContainers?.filter(container => container.locationId === 0);

        const updatedContainerNo = selected?.map(container => container.containerNumber).join(', ');

        //add container to redux form
        dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerManagementContainers', updatedContainers));
        dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerNo', updatedContainerNo));
        dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'numberOfContainers', selected?.length));
      } else {
        setSelectedContainerForTransfer({ ...omit(container, 'isChecked') });
        if (formValues?.currentService?.vendorContainers.find(container => container.id === id))
          setIsTransferContainerModalOpen(true);
        else {
          // simply remove the container from the list
          const updatedContainers = formValues?.containerManagementContainers?.filter(container => container.id !== id);
          const selected = updatedContainers?.filter(container => container.locationId === 0);
          const updatedContainerNo = selected?.map(container => container.containerNumber).join(', ');

          dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerManagementContainers', updatedContainers));
          dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerNo', updatedContainerNo));
          dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'numberOfContainers', selected?.length));
        }
      }
    },
    [containersListFormatted, dispatch, formValues?.containerManagementContainers], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleContainerTransfer = useCallback(
    (values: any) => {
      if (selectedContainerForTransfer) {
        // up[date the local container list of transferred containers
        //check if the containers is already in the list
        const containerThatExists = formValues?.containerManagementContainers?.find(
          container => container.id === selectedContainerForTransfer.id,
        );

        //update the container list in the redux form
        const updatedContainers = containerThatExists
          ? formValues?.containerManagementContainers?.map(container => {
              if (container.id === selectedContainerForTransfer.id) {
                return {
                  ...container,
                  locationId: values.facilityTypeId,
                };
              }
              return container;
            })
          : [
              ...(formValues?.containerManagementContainers || []),
              { ...selectedContainerForTransfer, locationId: values.facilityTypeId },
            ];

        const containersUpdated = updatedContainers?.filter(container => container.locationId === 0);

        const updatedContainerNo = containersUpdated?.map(container => container.containerNumber).join(', ');

        dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerManagementContainers', updatedContainers));
        dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerNo', updatedContainerNo));
        dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'numberOfContainers', containersUpdated?.length));

        setSelectedContainerForTransfer(null);
        setIsTransferContainerModalOpen(false);
      }
    },
    [dispatch, formValues?.containerManagementContainers, selectedContainerForTransfer],
  );

  const handleCreateContainer = useCallback(
    (values: ContainerToSave) => {
      saveContainer({
        ...values,
        ...(!values.id
          ? { equipmentCondition: find(equipmentConditions, { id: values.equipmentConditionId }).name }
          : {}),
        ...(typeof values.locationId === 'object'
          ? { locationId: values.locationId.locationId, serviceContractId: values.locationId.serviceContractId }
          : {}),
      })(dispatch)
        .then(() => {
          createSuccessNotification(translate('containers.alertMessages.containerSaved'));
          loadContainerList(vendorId)(dispatch).then(response => {
            const newContainerToCheck = response?.find(
              (container: any) => container.containerNumber === values.containerNumber,
            );

            const locationIdToCheck =
              typeof values.locationId === 'object' ? values.locationId.locationId : values.locationId;

            if (locationIdToCheck === selectedService?.locationId) {
              const selectedContainers = [
                ...(formValues?.containerManagementContainers || []),
                { ...newContainerToCheck, locationId: 0 },
              ];

              const selected = selectedContainers?.filter(container => container.locationId === 0);

              const updatedContainerNo = selected?.map(container => container.containerNumber).join(', ');
              dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerManagementContainers', selectedContainers));
              dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'containerNo', updatedContainerNo));
              dispatch(change(SERVICE_CONTRACT_WORKFLOW_FORM, 'numberOfContainers', selected?.length));
            }
            setIsAddNewContainerModalOpen(false);
          });
        })
        .catch(() => {
          createErrorNotification(translate('containers.alertMessages.containerAlreadyExists'));
        });
    },
    [dispatch, equipmentConditions, formValues?.containerManagementContainers, selectedService?.locationId, vendorId],
  );

  const numberOfContainersValidation = isContainerManagementFeatureEnabled
    ? [isNumberOrZero, minValueNumeric0, maxValueNumeric999, manageContainersValidate]
    : [isRequired, isInteger, minValueNumeric1, maxValueNumeric999, updateContainersCountValidate];

  const containerNoValidation = isContainerManagementFeatureEnabled
    ? [replaceContainerValidate]
    : [maxLength120, replaceContainerValidate];

  const didNotChooseAction =
    !formValues?.actions?.changeSize &&
    !formValues?.actions?.manageContainers &&
    !formValues?.actions?.replaceContainer &&
    !formValues?.actions?.updateCount;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ModalSection padding="small medium no medium" overflow="visible">
          <Grid padding="no">
            <GridColumn size="8/12" padding="no">
              <TypedField
                name="customerId"
                component={TypeAhead}
                props={{
                  getOptions: loadCustomersOptions,
                  isClearable: true,
                  defaultValue: initialValues?.customerId
                    ? {
                        label: `${customer?.name} ${customer?.accountNumber ? `(${customer?.accountNumber})` : ''}`,
                        value: initialValues.customerId,
                      }
                    : undefined,
                  margin: 'no',
                  label: translate('common.customer'),
                }}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="4/12" verticalAlign="flex-end" padding="no no no small">
              <TypedField
                component={Switch}
                name="actions.searchByAccountNumber"
                props={{
                  label: translate('customers.searchByAccountNumber'),
                  margin: 'no',
                  size: 'medium',
                }}
              />
            </GridColumn>
          </Grid>
        </ModalSection>
        <ModalSection padding="no medium no medium" isLoading={isLoadingCustomerLocations} position="relative">
          <TypedField
            name="locationId"
            component={Dropdown}
            props={{
              disabled: !customerLocationOptions.length,
              options: customerLocationOptions,
              width: '100%',
              menuPosition: 'fixed',
              margin: 'xSmall no',
              label: translate('common.location'),
            }}
            validate={[isRequired]}
          />
        </ModalSection>

        {!!formValues?.customerId && !!formValues?.locationId && (
          <ModalSection padding="xSmall medium" isLoading={isLoadingServices} overflow="visible" position="relative">
            {!!services.length ? (
              <>
                <Text block>{translate('customers.needTo')}...</Text>

                <TypedField
                  component={Checkbox}
                  name="actions.changeSize"
                  props={{
                    label: translate('customers.changeSize'),
                    margin: 'xSmall no xxSmall no',
                    block: true,
                    size: 'small',
                  }}
                />
                {!isContainerManagementFeatureEnabled ? (
                  <>
                    <TypedField
                      component={Checkbox}
                      name="actions.updateCount"
                      props={{
                        label: translate('customers.updateCount'),
                        margin: 'xxSmall no',
                        block: true,
                        size: 'small',
                      }}
                    />
                    <TypedField
                      component={Checkbox}
                      name="actions.replaceContainer"
                      props={{
                        label: translate('customers.replaceContainer'),
                        margin: 'xxSmall no small no',
                        block: true,
                        size: 'small',
                      }}
                    />
                  </>
                ) : (
                  <TypedField
                    component={Checkbox}
                    name="actions.manageContainers"
                    props={{
                      label: translate('customers.manageContainers'),
                      margin: 'xxSmall no small no',
                      block: true,
                      size: 'small',
                    }}
                  />
                )}

                {/* THE ACTUAL FLOWS BASED ON THE ABOVE COMBINATIONS */}
                {(changeSize || updateCount || replaceContainer || manageContainers) && (
                  <>
                    <TypedField
                      name="serviceId"
                      component={Dropdown}
                      props={{
                        isOptionDisabled: (option: any) => !!option.disabled,
                        disabled: !formValues?.locationId,
                        options: serviceOptions,
                        width: '100%',
                        isClearable: true,
                        margin: 'xSmall no',
                        label: translate('customers.selectService'),
                      }}
                      validate={[isRequired]}
                    />

                    {formValues?.serviceId && (
                      <>
                        {changeSize && (
                          <>
                            <TypedField
                              component={EquipmentSizeDropdown}
                              name="sizeId"
                              props={{
                                equipmentTypeId: selectedService?.equipmentTypeId,
                                label: translate('customers.selectSize'),
                                menuPosition: 'fixed',
                              }}
                              validate={[selectSizeValidate]}
                            />
                          </>
                        )}

                        <ModalSection
                          padding="no no"
                          isLoading={isLoadingContainersList || isLoadingService}
                          position="relative"
                        >
                          {(updateCount || manageContainers) && (
                            <Grid multiLine>
                              {isContainerManagementFeatureEnabled && (
                                <>
                                  <GridColumn size="1/12" margin="xSmall xSmall">
                                    <CreateContainerToggle onClick={() => setIsSelectContainersModalOpen(true)}>
                                      <ActionButtonTooltip size="mMedium" icon="select" tooltip="selectContainers" />
                                    </CreateContainerToggle>
                                  </GridColumn>
                                  <GridColumn size="1/12" margin="xSmall xSmall">
                                    {/* TODO: there was a bug related to this on api side, we hide the create button for now */}
                                    {/* <CreateContainerToggle onClick={() => setIsAddNewContainerModalOpen(true)}>
                                      <ActionButtonTooltip size="mLarge" icon="plus" tooltip="addNewContainer" />
                                    </CreateContainerToggle> */}
                                  </GridColumn>
                                  <GridColumn size="10/12" margin="xSmall small"></GridColumn>
                                </>
                              )}
                              <GridColumn size="4/12" verticalAlign="flex-start" padding="no no">
                                <TypedField
                                  component={Input}
                                  name="numberOfContainers"
                                  props={{
                                    label: translate('customers.selectCount'),
                                    margin: 'xSmall no',
                                    type: 'number',
                                    disabled: !!isContainerManagementFeatureEnabled,
                                  }}
                                  validate={numberOfContainersValidation}
                                />
                              </GridColumn>
                              <GridColumn size="8/12" verticalAlign="flex-start">
                                <TypedField
                                  component={Input}
                                  validate={containerNoValidation}
                                  name="containerNo"
                                  props={{
                                    label: translate('common.binNumber'),
                                    margin: 'xSmall no xSmall small',
                                    width: '100%',
                                    type: 'text',
                                    disabled: !!isContainerManagementFeatureEnabled,
                                  }}
                                />
                              </GridColumn>
                            </Grid>
                          )}
                        </ModalSection>

                        {replaceContainer && !updateCount && (
                          <TypedField
                            component={Input}
                            validate={[maxLength120]}
                            name="containerNo"
                            props={{
                              width: '50%',
                              label: translate('common.binNumber'),
                              margin: 'xSmall no',
                              type: 'text',
                              disabled: !!isContainerManagementFeatureEnabled,
                            }}
                          />
                        )}

                        <Grid multiLine margin="small no">
                          <GridColumn size="3/12" verticalAlign="flex-start" padding="no no" margin="xSmall no">
                            <Text margin="xSmall no no no">{translate('customers.changeEffectiveDate')}</Text>
                          </GridColumn>
                          <GridColumn size="9/12" verticalAlign="flex-start" margin="xSmall no">
                            <TypedField
                              name="changeEffectiveDate"
                              component={DatePicker}
                              validate={[isRequired, isDateValidValidator]}
                              props={{
                                width: '35%',
                                margin: 'no',
                                disabledDays: [{ before: moment().add(1, 'days').toDate() }],
                              }}
                            />
                          </GridColumn>

                          {(countDecreased || changeSize || replaceContainer) && hasDeliveryUtilityVehicle && (
                            <>
                              <GridColumn size="3/12" verticalAlign="flex-start" padding="no no" margin="xSmall no">
                                <TypedField
                                  component={Checkbox}
                                  name="createRemovalJob"
                                  props={{
                                    label: translate('customers.createRemovalJob'),
                                    margin: 'xSmall no',
                                    block: true,
                                  }}
                                />
                              </GridColumn>
                              <GridColumn size="9/12" verticalAlign="flex-start" margin="xSmall no">
                                {formValues?.createRemovalJob && (
                                  <TypedField
                                    name="removalJobDate"
                                    component={DatePicker}
                                    validate={[isRequired, isDateValidValidator]}
                                    props={{
                                      width: '35%',
                                      margin: 'no',
                                      disabledDays: [{ before: moment().add(1, 'days').toDate() }],
                                    }}
                                  />
                                )}
                              </GridColumn>
                            </>
                          )}

                          {(countIncreased || changeSize || replaceContainer) && hasDeliveryUtilityVehicle && (
                            <>
                              <GridColumn size="3/12" verticalAlign="flex-start" padding="no no" margin="no">
                                <TypedField
                                  component={Checkbox}
                                  name="createDeliveryJob"
                                  props={{
                                    label: translate('customers.createDeliveryJob'),
                                    margin: 'xSmall no',
                                    block: true,
                                  }}
                                />
                              </GridColumn>
                              <GridColumn size="9/12" verticalAlign="flex-start" margin="no">
                                {formValues?.createDeliveryJob && (
                                  <TypedField
                                    name="deliveryJobDate"
                                    component={DatePicker}
                                    validate={[isRequired, isDateValidValidator]}
                                    props={{
                                      width: '35%',
                                      margin: 'no',
                                      disabledDays: [{ before: moment().add(1, 'days').toDate() }],
                                    }}
                                  />
                                )}
                              </GridColumn>
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Text block align="center">
                  {translate('customers.noServicesForLocation')}
                </Text>
              </>
            )}
          </ModalSection>
        )}

        <ModalFixedFooter
          flex-direction="column"
          align="center"
          padding="xLarge small small small"
          justifyContent="space-around"
        >
          <ButtonSet align="center" margin="no small">
            <Button type="button" margin="no small" color="secondary" onClick={() => closeModal(pristine)}>
              {translate('common.cancel')}
            </Button>
            <Button type="submit" color="primary" disabled={didNotChooseAction}>
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </ModalFixedFooter>
      </form>

      {isAddNewContainerModalOpen && (
        <CreateEditContainerModalResolver
          closeModal={() => setIsAddNewContainerModalOpen(false)}
          onSubmit={handleCreateContainer}
        />
      )}

      {isSelectContainersModalOpen && (
        <SelectContainersModalResolver
          closeModal={() => setIsSelectContainersModalOpen(false)}
          onCheckboxChange={handleContainerCheckboxChange}
          containerList={containersListFormatted}
        />
      )}

      {isTransferContainerModalOpen && (
        <TransferContainerModalResolver
          handleContainerTransfer={handleContainerTransfer}
          title={translate('customers.alertMessages.containerPlacement')}
          closeModal={() => {
            setIsTransferContainerModalOpen(false);
            setSelectedContainerForTransfer(null);
          }}
        />
      )}
    </>
  );
};

export default reduxForm<ServiceContractWorkflowFormValues, PropsWithoutReduxForm>({
  form: SERVICE_CONTRACT_WORKFLOW_FORM,
  enableReinitialize: true,
  shouldValidate: () => true,
  onSubmitFail: focusFirstInvalidField,
})(ServiceContractWorkflowForm);
