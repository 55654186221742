import { includes, map } from 'lodash-es';

import { TableCell, TableRow } from '../../../core/components/styled';
import { TOGGLE_ID, TEXT_FIELD_ID, NUMERIC_TEXT_FIELD_ID, SLIDER_ID } from 'src/fleet/constants';
import { UnconnectedInput, UnconnectedSwitch, UnconnectedDropdown } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

type Props = {
  booleanValue: boolean;
  defaultBooleanValue: boolean;
  defaultId: number;
  defaultNumericValue: number | string;
  defaultTextValue: string;
  fieldId: number;
  fieldName: string;
  fieldsWithError: number[];
  fieldTypeId: number;
  hasVehicleTypeId: boolean;
  maximumValue: number | string;
  minimumValue: number | string;
  numericValue: string;
  pickerValues: string;
  setFieldValue: (defaultId: number, value: boolean | string | number, fieldName: string) => void;
  textValue: string;
};

const VisionConfigurationModalTableRow: React.FC<Props> = ({
  booleanValue,
  defaultBooleanValue,
  defaultId,
  defaultNumericValue,
  defaultTextValue,
  fieldId,
  fieldName,
  fieldsWithError,
  fieldTypeId,
  hasVehicleTypeId,
  maximumValue,
  minimumValue,
  numericValue,
  pickerValues,
  setFieldValue,
  textValue,
}) => {
  const pickerValuesAsArray = pickerValues ? pickerValues.split(',') : [];
  const dropdownOptions = map(pickerValuesAsArray, pickerValue => ({
    label: pickerValue,
    value: pickerValue,
  }));

  const hasError = includes(fieldsWithError, defaultId);

  return (
    <TableRow>
      <TableCell hasError={hasError} textTransform="capitalize" width={hasVehicleTypeId ? '75%' : '50%'}>
        {translate(createTranslationKey(fieldName, 'vendors.visionConfiguration'))}
      </TableCell>
      <TableCell width="25%" align="center">
        {fieldTypeId === TOGGLE_ID ? (
          <UnconnectedSwitch
            checked={booleanValue}
            onChange={(value: boolean) => setFieldValue(defaultId, value, 'booleanValue')}
          />
        ) : fieldTypeId === TEXT_FIELD_ID ? (
          <UnconnectedInput
            align="center"
            onChange={(value: boolean) => setFieldValue(defaultId, value, 'textValue')}
            value={textValue || undefined}
            hasError={hasError}
          />
        ) : fieldTypeId === NUMERIC_TEXT_FIELD_ID ? (
          <UnconnectedInput
            width="100%"
            type="number"
            align="center"
            onChange={(value: boolean) => setFieldValue(defaultId, value, 'numericValue')}
            value={numericValue}
            hasError={hasError}
            preventNumberExponent
          />
        ) : fieldTypeId === SLIDER_ID ? (
          <UnconnectedInput
            width="100%"
            type="number"
            min={minimumValue}
            max={maximumValue}
            step={maximumValue && maximumValue >= 10 ? '1' : '0.1'}
            align="center"
            onChange={(value: boolean) => setFieldValue(defaultId, value, 'numericValue')}
            value={numericValue}
            hasError={hasError}
            preventNumberExponent
          />
        ) : (
          <UnconnectedDropdown
            width="100%"
            value={textValue}
            onChange={(value: boolean) => setFieldValue(defaultId, value, 'textValue')}
            options={dropdownOptions}
          />
        )}
      </TableCell>
      {!hasVehicleTypeId && (
        <TableCell width="25%" align="center" hasBackground>
          {fieldTypeId === TOGGLE_ID ? (
            <UnconnectedSwitch disabled checked={defaultBooleanValue} />
          ) : fieldTypeId === TEXT_FIELD_ID ? (
            <UnconnectedInput disabled align="center" value={defaultTextValue || undefined} />
          ) : fieldTypeId === NUMERIC_TEXT_FIELD_ID || fieldTypeId === SLIDER_ID ? (
            <UnconnectedInput disabled width="100%" align="center" value={defaultNumericValue || undefined} />
          ) : (
            <UnconnectedInput disabled width="100%" align="center" value={defaultTextValue} />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default VisionConfigurationModalTableRow;
