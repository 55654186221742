import { getFeatureCollection, getLineStringFeature } from 'src/common/components/map/util';
import { ROUTE_SEGMENT_LANE_COLORS } from 'src/customers/components/pages/streetNetwork/utils';
import {
  calculateIsDriverExperienceModerate,
  calculateIsDriverExperienceSimple,
  getForwardStatusId,
  getReverseStatusId,
} from 'src/dashboard/components/pages/dashboardPageSections/mapGL/dashboardSnowSweeperStreetSegments/utils';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';

export const getSegmentCenter = (segment: number[][]) => segment[Math.floor(segment.length / 2)];

export const parseSegmentJSON = (lineSegment: string): number[][] => JSON.parse(lineSegment);

const getSegmentColorBasedOnStatus = (segment: RouteSegment, selectedSegmentId?: number, status?: number) => {
  if (selectedSegmentId && selectedSegmentId === segment.streetSegmentId) return ROUTE_SEGMENT_LANE_COLORS.red;

  switch (status) {
    case COMPLETED:
      return ROUTE_SEGMENT_LANE_COLORS.green;
    case SCHEDULED:
      return ROUTE_SEGMENT_LANE_COLORS.blue;
    case IN_PROGRESS:
      return ROUTE_SEGMENT_LANE_COLORS.yellow;
    default:
      return ROUTE_SEGMENT_LANE_COLORS.blue;
  }
};
const getSegmentOpacity = (segment: RouteSegment, selectedSegmentId?: number) => {
  // it is selected
  if (selectedSegmentId === segment.streetSegmentId) {
    return 1;
  }

  return 0.65;
};

export const getDashboardSNowSweeperStreetSegmentsGeoJSON = (
  segments: RouteSegment[],
  vehicleTypeId: number,
  selectedSegmentId?: number,
) => {
  const isDriverExperienceSimple = calculateIsDriverExperienceSimple(vehicleTypeId);
  const isDriverExperienceModerate = calculateIsDriverExperienceModerate(vehicleTypeId);

  return getFeatureCollection<GeoJSON.LineString, any>(
    segments.map(segment => {
      const parsedSegment = parseSegmentJSON(segment.lineSegment);
      const twoWayOffset = 5;

      const hasTwoLanes = (!segment.isOneWay || !segment?.reverseStatusId) && !isDriverExperienceSimple;

      const forwardStatusId = getForwardStatusId(
        segment.forwardStatusId,
        segment.reverseStatusId,
        isDriverExperienceSimple,
        isDriverExperienceModerate,
      );

      const reverseStatusId = getReverseStatusId(
        segment.forwardStatusId,
        segment.reverseStatusId,
        isDriverExperienceSimple,
        isDriverExperienceModerate,
      );

      const lineStringFeature = getLineStringFeature(segment.streetSegmentId, parsedSegment, {
        id: segment.streetSegmentId,
        clickable: true,
        segmentCenter: getSegmentCenter(parsedSegment),
        hasForwardPass: true,
        hasReversePass: hasTwoLanes,
        color: getSegmentColorBasedOnStatus(segment, selectedSegmentId, forwardStatusId),
        reverseSegmentColor: getSegmentColorBasedOnStatus(segment, selectedSegmentId, reverseStatusId),
        opacity: getSegmentOpacity(segment, selectedSegmentId),
        lineOffset: !hasTwoLanes ? 0 : twoWayOffset,
        lineOffsetNegative: !hasTwoLanes ? 0 : -twoWayOffset,
      });

      return lineStringFeature;
    }),
  );
};
