import { camelCase, find, keys, pickBy, size, map } from 'lodash-es';

import translate from '../../core/services/translate';

const getSelectedContactTypesName = (functionTypes: any[], allContactFunctionTypes: any[]) => {
  const selectedContactTypes = map(functionTypes, selectedContactType =>
    find(allContactFunctionTypes, contactType => contactType.id === selectedContactType),
  );
  const selectedContactTypesName = map(selectedContactTypes, 'title');
  const selectedContactTypesNameTranslated = map(selectedContactTypesName, selectedContactTypeName =>
    translate(`haulerProfile.roles.${camelCase(selectedContactTypeName)}`),
  ).join(', ');

  return selectedContactTypesNameTranslated;
};

export const transformLoadContacts = (contactsObj: any) => ({
  ...contactsObj,
  contacts:
    !!size(contactsObj.contacts) &&
    map(contactsObj.contacts, contact => ({
      ...contact,
      selectedContactTypes:
        !!size(contact.functionTypes) && map(contact.functionTypes, contactTypeId => ({ id: contactTypeId })),
      selectedContactTypesDisplayName:
        !!size(contact.functionTypes) &&
        getSelectedContactTypesName(contact.functionTypes, contactsObj.allContactFunctionTypes),
    })),
});

export const transformSaveContacts = (formData: any) => ({
  ...formData,
  functionTypes: formData.functionTypes,
  firstName: formData.firstName,
  lastName: formData.lastName,
  phoneNumber: formData.phoneNumber,
  emailAddress: formData.emailAddress,
});

export const getSelectedContactTypeIds = (formData: any) => {
  const selectedContactTypeIds = map(keys(pickBy(formData)), key => key.split(':')[1]);
  const filteredContactTypeIds = selectedContactTypeIds.filter(
    selectedContactType => selectedContactType !== undefined,
  );
  return filteredContactTypeIds;
};
