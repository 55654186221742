import translate from '../../core/services/translate';
import createTransformer from '../../utils/services/createTransformer';
import createTranslationKey from '../../utils/services/createTranslationKey';

export const getTranslatedDurationSinceLastCommunication = (durationSinceLastCommunication: string) => {
  const time = durationSinceLastCommunication.match(new RegExp(/\d+/g));

  if (!time) {
    return '-';
  }

  return `${time[0]} ${translate('common.days')} ${time[1]} ${translate('common.hours')} ${time[2]} ${translate(
    'common.minutes',
  )}`;
};

const transformLoadDeviceStatuses = (values: any) => {
  const deviceStatuses = createTransformer(values);

  return {
    ...deviceStatuses.omit('deviceStatus').value,
    deviceStatuses: deviceStatuses.get('deviceStatus').map((deviceStatus: any) => ({
      ...deviceStatus,
      vehicleType: translate(createTranslationKey(deviceStatus.vehicleType, 'vehicles.vehicleTypes')),
      durationSinceLastCommunication: getTranslatedDurationSinceLastCommunication(
        deviceStatus.durationSinceLastCommunication,
      ),
    })).value,
  };
};

export default transformLoadDeviceStatuses;
