import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { DuckFunction } from '../../../../contracts/ducks';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadRouteTrackerIssuesSummary } from '../../../ducks';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { PageLoading } from '../../../../common/components/styled';
import { Resolver } from '../../../../core/components';
import RouteIssuesReportedSummaryPage from './RouteIssuesReportedSummaryPage';
import translate from '../../../../core/services/translate';

interface Props extends RouteComponentProps {
  vendorId: number;
  userId: string;
  loadRouteTrackerIssuesSummary: DuckFunction<typeof loadRouteTrackerIssuesSummary>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
}

class RouteIssuesReportedSummaryPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      vendorId,
      userId,
      location: { search },
      loadRouteTrackerIssuesSummary,
      loadVehicleTypesForVendor,
      loadFiltersSettings,
    } = this.props;

    const { searchTerm, vehicleTypeIds, routeStatusTypeIds, startDate, endDate } = getQueryParams(search);

    const promises = [
      loadVehicleTypesForVendor(vendorId),
      loadRouteTrackerIssuesSummary(
        vendorId,
        searchTerm,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        !vehicleTypeIds,
      ),
      loadFiltersSettings(vendorId, userId),
    ];
    return Promise.all(promises);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.tracker')}</DocumentTitle>
        <Resolver
          successComponent={RouteIssuesReportedSummaryPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/dashboard"
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login) as any,
});

const mapDispatchToProps = {
  loadRouteTrackerIssuesSummary,
  loadVehicleTypesForVendor,
  loadFiltersSettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteIssuesReportedSummaryPageResolver));
