import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { map } from 'lodash-es';
import moment from 'moment';

import { AppState } from '../../../store';
import { Container, ButtonSet, Button, Text } from '../../../core/components/styled';
import { DatePicker, DaysOfWeekPicker, Dropdown, TypedField } from '../../../core/components';
import deliveryFormInitialValueSelector from '../../services/deliveryFormInitialValueSelector';
import isFalsy from '../../../utils/services/isFalsy';
import { isDateValidValidator, isRequired, mustLength } from '../../../utils/services/validator';
import { REMOVAL } from '../../../fleet/constants/openDispatachesServices';
import translate from '../../../core/services/translate';
import WEEK_OF_MONTHS from '../../constants/weekOfMonths';
import { REQUESTED } from '../../../fleet/constants/rubiconDisptachesStatus';

const formSelector = formValueSelector('serviceDelivered');

const ONE_X_PER_MONTH = '1x/Month';
interface ComponentProps {
  caseId: number;
  agreement?: any;
  daysInWeekSchedule?: any;
  frequency?: string;
  beforeDeliveryDate?: string;
  afterDeliveryDate?: string;
  liftAndShiftVendor?: boolean;
  pickupTypeId?: number;
  statusId: number;
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  response: any;
  requestedChanges: any;
  WasDelivered: any;
  CanNotDeliver: any;
  holidays: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  response?: any;
}

class ServiceDeliveredForm extends PureComponent<Props, State> {
  readonly state: State = {
    response: undefined,
  };

  mustValidator = mustLength(this.props.daysInWeekSchedule);

  render() {
    const {
      agreement,
      afterDeliveryDate,
      beforeDeliveryDate,
      caseId,
      daysInWeekSchedule,
      frequency,
      handleSubmit,
      holidays,
      onCancel,
      pristine,
      pickupTypeId,
      statusId,
    } = this.props;

    const deliveryDate = moment(agreement.deliveryDate).format('MM/DD/YYYY');
    const weekOptions = map(WEEK_OF_MONTHS, w => ({
      label: w.name,
      value: w.id,
    }));
    return (
      <form onSubmit={handleSubmit} noValidate>
        {statusId === REQUESTED ? (
          <Container>
            <Text weight="light" margin="no small small">
              {translate('opportunity.deliveryDate')}: {deliveryDate}
            </Text>
          </Container>
        ) : (
          <Container>
            <Field
              name={`deliveries.settings${caseId}.WasDelivered.deliveryDate`}
              component={DatePicker}
              placeholder={deliveryDate}
              disabledDays={[
                {
                  before: moment(beforeDeliveryDate, 'MM/DD/YYYY').toDate(),
                  after: moment(afterDeliveryDate, 'MM/DD/YYYY').toDate(),
                },
                { daysOfWeek: [0, 6] },
                ...holidays,
              ]}
              validate={[isDateValidValidator]}
              margin="large small small"
              disabled={statusId === REQUESTED}
            />
            {pickupTypeId !== REMOVAL &&
              !isFalsy(daysInWeekSchedule, { allowZero: true }) &&
              daysInWeekSchedule > 0 && (
                <Field
                  name={`deliveries.settings${caseId}.WasDelivered.Schedule`}
                  component={DaysOfWeekPicker}
                  label={translate('opportunity.deliveries.pickupWillBeOn')}
                  validate={[isRequired, this.mustValidator]}
                  multiple
                  daysOfWeekProps={{ margin: 'Small no Small' }}
                  dayOfWeekProps={{ margin: 'no small no no' }}
                  margin={frequency === ONE_X_PER_MONTH ? 'small' : 'medium small medium'}
                />
              )}

            {pickupTypeId !== REMOVAL && frequency === ONE_X_PER_MONTH && (
              <TypedField
                name={`deliveries.settings${caseId}.WasDelivered.week`}
                component={Dropdown}
                validate={[isRequired]}
                props={{
                  options: weekOptions,
                  label: translate('opportunity.serviceChangeRequest.week'),
                  margin: 'no small medium',
                }}
              />
            )}

            {!!frequency && (
              <Text weight="light" margin="no small small">
                {translate('customers.frequency')}: {frequency}
              </Text>
            )}
          </Container>
        )}

        <ButtonSet margin="xxGrande no no no">
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: deliveryFormInitialValueSelector(
    state.opportunity.opportunity.deliveries,
    state.fleet.rubiconDispatches.rubiconDispatches,
  ),
  response: formSelector(state, `deliveries.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `deliveries.settings${ownProps.caseId}.requestedChanges`),
  WasDelivered: formSelector(state, `deliveries.settings${ownProps.caseId}.WasDelivered`),
  CanNotDeliver: formSelector(state, `deliveries.settings${ownProps.caseId}.CanNotDeliver`),
  holidays: state.common.holidays.holidays,
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'serviceDelivered',
  })(ServiceDeliveredForm),
);
