import { reduxForm, InjectedFormProps } from 'redux-form';

import { Button, ButtonSet, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { Input, TypedField } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import translate from 'src/core/services/translate';

interface ComponentProps {
  onCloseModal: () => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const QuickBooksConnectForm: React.FC<Props> = ({ onCloseModal, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="no">
        <Grid multiLine>
          <GridColumn size="12/12" margin="small no">
            <TypedField
              name="clientId"
              component={Input}
              validate={[isRequired]}
              props={{
                label: translate('finance.quickBooksIntegration.clientId'),
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="12/12" margin="small no">
            <TypedField
              name="clientSecret"
              component={Input}
              validate={[isRequired]}
              props={{
                label: translate('finance.quickBooksIntegration.clientSecret'),
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>

      <Grid centered>
        <ButtonSet margin="medium" align="center">
          <Button type="button" color="primary" line onClick={() => onCloseModal()}>
            {translate('common.cancel')}
          </Button>
          <Button type="submit" color="primary" margin="no small">
            {translate('finance.quickBooksIntegration.activate')}
          </Button>
        </ButtonSet>
      </Grid>
    </form>
  );
}
export default reduxForm({
  form: 'quickBooksConnectForm',
  onSubmitFail: focusFirstInvalidField,
})(
  QuickBooksConnectForm,
);
