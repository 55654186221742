import { RawVehicle, Vehicle } from '../interfaces/Vehicles';
import { VehicleType } from '../interfaces/VehicleTypes';

export const transformRawVehicle = (vehicle: RawVehicle, vehicleTypes: VehicleType[]): Vehicle => {
  const vehicleType = vehicleTypes.find(type => type.id === vehicle.vehicleTypeId);
  const vehicleSubType = vehicleType
    ? vehicleType.subTypes.find(type => type.id === vehicle.vehicleSubTypeId)
    : undefined;

  return {
    ...vehicle,
    vehicleType,
    vehicleSubType,
    vehicleTypeName: vehicleType && vehicleType.name,
  };
};

export const transformRawVehicles = (vehicles: RawVehicle[], vehicleTypes: VehicleType[]): Vehicle[] =>
  vehicles.map(vehicle => transformRawVehicle(vehicle, vehicleTypes));

export const transformVehicle = (vehicle: Vehicle): RawVehicle => {
  const newVehicle = { ...vehicle };

  delete newVehicle.vehicleType;
  delete newVehicle.vehicleSubType;
  delete newVehicle.vehicleTypeName;

  return newVehicle;
};

export const transformVehicles = (vehicles: Vehicle[]): RawVehicle[] =>
  vehicles.map(vehicle => transformVehicle(vehicle));
