import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import {
  loadBulkyItemScheduler,
  loadBulkyCategoryTypes,
  loadBulkyItemTypes,
  loadPeriodTypes,
  loadDataRetention,
} from 'src/vendors/ducks';
import { loadBulkyCancellationTimeFrameTypes } from 'src/common/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import BulkyItemSchedulerModal from './BulkyItemSchedulerModal';
import { loadGeoFenceBasicList } from 'src/routes/ducks';
import { BULKY_PICKUPS_GEO_FENCE_ID } from 'src/routes/constants';

const BulkyItemSchedulerModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadGeoFenceBasicList(vendorId, BULKY_PICKUPS_GEO_FENCE_ID.toString())(dispatch),
      loadBulkyCancellationTimeFrameTypes()(dispatch),
      loadBulkyItemScheduler(vendorId)(dispatch),
      loadBulkyCategoryTypes()(dispatch),
      loadBulkyItemTypes()(dispatch),
      loadPeriodTypes()(dispatch),
      loadDataRetention()(dispatch),
    ];

    return Promise.all(dependencies);
  }, [vendorId, dispatch]);

  return (
    <TypedResolver
      successComponent={BulkyItemSchedulerModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default BulkyItemSchedulerModalResolver;
