import { concat, filter } from 'lodash-es';
import translate from 'src/core/services/translate';
import {
  DELIVERY_UTILITY_ID,
  FRONT_LOAD_ID,
  RESIDENTIAL_ID,
  ROLL_OFF_ID,
  TOTER_ID,
} from 'src/fleet/constants/vehicleTypes';
import { augmentWithType } from 'src/routes/services/routeTemplateHelper';
import { DispatchBoardJobRouteTitle } from '../../styled/DispatchBoard/DispatchBoardJobs';

const getRouteOptions = (
  vehicleTypeId: number,
  routes: any[],
  hasIsTemplate?: boolean,
  hasRoutesForReassignmentOptions?: boolean,
) => {
  return hasIsTemplate
    ? [
        ...routes
          .filter((route: any) => route.vehicleTypeId === vehicleTypeId)
          .map((route: any) => ({
            label: route.name,
            value: augmentWithType(route.id, route.isTemplate),
            vehicleTypeId: route.vehicleTypeId,
            routeStatusTypeId: route.routeStatusTypeId,
            hasStopsInPendingOptimization: route.hasStopsInPendingOptimization,
          })),
      ]
    : hasRoutesForReassignmentOptions
    ? [...filter(routes, { vehicleTypeId: vehicleTypeId })]
    : [
        ...routes
          .filter(r => r.vehicleTypeId === vehicleTypeId)
          .map(r => ({ label: r.name, value: r, routeStatusTypeId: r.routeStatusTypeId })),
      ];
};

export const getRequiredOptions = (
  vehicleTypeId: number,
  routes: any[],
  hasIsTemplate?: boolean,
  hasRoutesForReassignmentOptions?: boolean,
) => {
  let title;
  if (vehicleTypeId === FRONT_LOAD_ID) {
    title = translate('common.serviceTypes.frontLoad');
  } else if (vehicleTypeId === RESIDENTIAL_ID) {
    title = translate('common.serviceTypes.residential');
  } else if (vehicleTypeId === DELIVERY_UTILITY_ID) {
    title = translate('dashboard.delivery');
  } else if (vehicleTypeId === ROLL_OFF_ID) {
    title = translate('common.serviceTypes.rollOff');
  } else if (vehicleTypeId === TOTER_ID) {
    title = translate('common.serviceTypes.cart');
  }
  return [
    {
      label: <DispatchBoardJobRouteTitle>{title}</DispatchBoardJobRouteTitle>,
      options: getRouteOptions(vehicleTypeId, routes, hasIsTemplate, hasRoutesForReassignmentOptions),
    },
  ];
};

export const getComboRouteOptions = (
  vehicleTypeId: number,
  routes: any[],
  hasIsTemplate?: boolean,
  hasRoutesForReassignmentOptions?: boolean,
) => {
  let options = [] as any[];
  if (routes) {
    const frontLoadRoutes = getRequiredOptions(FRONT_LOAD_ID, routes, hasIsTemplate);
    const residentialRoutes = getRequiredOptions(RESIDENTIAL_ID, routes, hasIsTemplate);
    const deliveryUtilityRoutes = getRequiredOptions(DELIVERY_UTILITY_ID, routes, hasIsTemplate);
    options =
      vehicleTypeId === FRONT_LOAD_ID
        ? concat(frontLoadRoutes, residentialRoutes, deliveryUtilityRoutes)
        : vehicleTypeId === RESIDENTIAL_ID
        ? concat(residentialRoutes, frontLoadRoutes, deliveryUtilityRoutes)
        : concat(deliveryUtilityRoutes, frontLoadRoutes, residentialRoutes);
  }
  return options;
};
