import React, { PureComponent, Fragment } from 'react';

import { camelCase } from 'lodash-es';

import {
  DashboardMapInsightsCategory,
  DashboardMapInsightsCategoryTitle,
  DashboardMapInsight,
  DashboardMapInsightIcon,
  DashboardMapInsightName,
  DashboardMapInsightValue,
} from '../../styled';
import translate from '../../../../core/services/translate';

interface Props {
  category: string;
  title: string;
  insights: any[];
  hasVehicleInsights?: boolean;
  id?: string;
}

class DashboardMapInsightsSection extends PureComponent<Props> {
  render() {
    const { category, title, insights, hasVehicleInsights, id } = this.props;
    let hasInsightValue = false;

    return (
      <DashboardMapInsightsCategory id={id}>
        {insights.map(insight => {
          const insightFormattedValue = insight.insightFormattedValue;

          if (insightFormattedValue !== '0' && !hasInsightValue) {
            hasInsightValue = true;
            return <DashboardMapInsightsCategoryTitle key={title}>{title}</DashboardMapInsightsCategoryTitle>;
          }

          return null;
        })}

        {insights.map((insight, insightIndex) => {
          const insightType = camelCase(insight.name);
          const insightFormattedValue = insight.insightFormattedValue;
          const insightID = id ? `${id}[${insightIndex}]` : undefined;
          if (insightFormattedValue !== '0') {
            return (
              <DashboardMapInsight key={insight.name} category={category} type={insightType} id={insightID}>
                <Fragment>
                  <DashboardMapInsightIcon />
                  <DashboardMapInsightName>
                    {hasVehicleInsights
                      ? translate(`vehicles.vehicleTypes.${insightType}`)
                      : insight.insightType === 'Issue'
                      ? translate(`routes.pickupIssueTypes.${insightType}`)
                      : translate(`dashboard.${insightType}`)}
                  </DashboardMapInsightName>
                  <DashboardMapInsightValue>{insightFormattedValue}</DashboardMapInsightValue>
                </Fragment>
              </DashboardMapInsight>
            );
          }

          return null;
        })}
      </DashboardMapInsightsCategory>
    );
  }
}

export default DashboardMapInsightsSection;
