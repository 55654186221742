import styled, { css } from 'styled-components';
import { BareButtonStyle } from '../../../core/components/styled';
import { transparentize } from 'polished';

export const ServiceDetailsCard = styled.div<{
  isHighlighted?: boolean;
  isParent?: boolean;
  isChildren?: boolean;
  childLevel?: number;
}>`
  width: 100%;
  display: flex;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  &:last-child {
    margin-bottom: 0;
  }

  ${p =>
    p.isParent &&
    css`
      border-left: 15px solid ${p => transparentize(0.8)(p.theme.brandInfoLight)};
    `}

  ${p =>
    p.isChildren &&
    css`
      background-color: ${p => transparentize(0.8)(p.theme.brandInfoLight)};
      margin-top: 0;
      border-radius: 0 5px 5px 0;
    `}

    ${p =>
    p.childLevel &&
    css`
      border-left: ${p.childLevel * 15}px solid ${p => transparentize(0.8)(p.theme.brandInfoLight)};
    `}
`;

export const ServiceDetailsCardImage = styled.img`
  max-width: 120px;
  border-radius: 10px;
`;

export const GetRecommendationButton = styled.button`
  ${BareButtonStyle}

  color: ${p => p.theme.brandPrimary};
`;

export const SetAsPrimaryButton = styled.button`
  ${BareButtonStyle}
  color: ${p => p.theme.brandPrimary};
`;

export const DeleteCollectionButton = styled.button`
  ${BareButtonStyle}
  color: ${p => p.theme.brandAlert};
`;
