import styled, { css } from 'styled-components';

import { BareButtonStyle } from '../../../core/components/styled';
import bannerImg from '../../assets/img/banner/banners.png';
import Icon from '../../../core/components/Icon';

type Props = Partial<{
  isActive: boolean;
  isVisible?: boolean;
  step: number;
}>;

export const BannerCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
`;

export const BannerClose = styled.button`
  ${BareButtonStyle};
  position: absolute;
  top: 3px;
  right: 7px;
  color: #fff;
`;

export const BannerNavigationItem = styled.button<Props>`
  ${BareButtonStyle};
  width: 6px;
  height: 6px;
  margin-right: 3px;
  border: 1px solid #fff;
  border-radius: 3px;

  ${props =>
    props.isActive &&
    css`
      background-color: #fff;
    `};
`;

export const BannerNavigation = styled.div`
  position: absolute;
  bottom: 2px;
  left: 50%;
  width: 54px;
  margin-left: -26px;
`;

export const BannerLink = styled.a<Props>`
  display: none;
  width: 100%;
  height: 100%;

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};
`;

export const Banner = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 50vw;
  z-index: 1000;
  width: 695px;
  height: 75px;
  margin-left: -347px;
  background: url(${bannerImg}) no-repeat;
  background-size: 695px 375px;
  background-position: 0 0;
  transition: background-position 0.3s ease-out;

  ${props =>
    props.step &&
    css`
      background-position: 0 -${props.step * 75}px;
    `};
`;
