import React from 'react';

import { CollectionPoint } from '../../../interfaces/CollectionPoint';
import {
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowTitleContainer,
} from '../../../../core/components/styled/MapInfoWindow';
import translate from '../../../../core/services/translate';
import { BareButtonStyle, Text } from '../../../../core/components/styled';
import styled from 'styled-components';
import { COLOR_ALERT } from 'src/core/styles';
import { Popup } from 'react-map-gl';

const AssignButton = styled.button`
  ${BareButtonStyle}
  color: ${p => p.theme.brandPrimary};
`;

export const CollectionPointMarkerInfoWindow: React.FC<{
  collectionPoint: CollectionPoint;
  anyActiveLocations: boolean;
  assignToCollectionPoint: (collectionPoint: CollectionPoint) => void;
  handleRemoveCollectionPoints: (collectionPointId: number | string) => void;
  activeCollectionPoint?: CollectionPoint;
  onClose: () => void;
}> = ({
  collectionPoint,
  anyActiveLocations,
  assignToCollectionPoint,
  handleRemoveCollectionPoints,
  activeCollectionPoint,
  onClose,
}) => {
  return (
    <Popup onClose={onClose} latitude={collectionPoint.latitude} longitude={collectionPoint.longitude}>
      <div>
        <MapInfoWindowTitleContainer>
          <MapInfoWindowDetailLabel>{translate(`pickupLocationEditor.collectionPoint`)}</MapInfoWindowDetailLabel>
          <br />
        </MapInfoWindowTitleContainer>
        <MapInfoWindowDetails>
          <MapInfoWindowDetail noCapitalize>
            <MapInfoWindowDetailLabel>
              {translate('pickupLocationEditor.numberOfAssociatedStops')}:
            </MapInfoWindowDetailLabel>
            {collectionPoint.serviceContractIds?.length || 0}
          </MapInfoWindowDetail>
          {anyActiveLocations && (
            <MapInfoWindowDetail>
              <AssignButton
                onClick={() => {
                  assignToCollectionPoint(collectionPoint);
                  onClose();
                }}
              >
                {translate('pickupLocationEditor.assignToThisCollectionPoint')}
              </AssignButton>
            </MapInfoWindowDetail>
          )}
          {activeCollectionPoint && (
            <MapInfoWindowDetail>
              <Text
                size="small"
                cursor="pointer"
                underlined
                color={COLOR_ALERT}
                onClick={() => {
                  activeCollectionPoint.id && handleRemoveCollectionPoints(activeCollectionPoint.id);
                }}
              >
                {translate('pickupLocationEditor.deleteCollectionPoint')}
              </Text>
            </MapInfoWindowDetail>
          )}
        </MapInfoWindowDetails>
      </div>
    </Popup>
  );
};
