import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { FleetRouteInstance } from '../interfaces/FleetRouteInstance';
import { loadFleetRouteInstances as doLoadFleetRouteInstances } from '../services/fleetRouteInstances';

// Actions
const START_LOAD = 'common/fleetRouteInstances/START_LOAD';
const COMPLETE_LOAD = 'common/fleetRouteInstances/COMPLETE_LOAD';
const FAIL_LOAD = 'common/fleetRouteInstances/FAIL_LOAD';

// Initial state =
const initialState = {
  isLoading: false,
  fleetRouteInstances: [] as FleetRouteInstance[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          fleetRouteInstances: action.fleetRouteInstances,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          fleetRouteInstances: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (fleetRouteInstances: FleetRouteInstance[]) => ({
  type: COMPLETE_LOAD,
  fleetRouteInstances,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadFleetRouteInstances = () => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadFleetRouteInstancesPromise = doLoadFleetRouteInstances();
  loadFleetRouteInstancesPromise
    .then(fleetRouteInstances => dispatch(completeLoad(fleetRouteInstances)))
    .catch(() => dispatch(failLoad()));

  return loadFleetRouteInstancesPromise;
};
