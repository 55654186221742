import { find } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';

import DoughnutChart from 'src/core/components/DoughnutChart';
import { theme } from 'src/core/styles';
import { ContainerStatisticsElement, ContainerStatisticsWrapper } from 'src/finance/components/styled/Statistics';
import { getChartData } from 'src/finance/components/utils/billCreateSummary';
import { AppState } from 'src/store';
import {
  BILL_CREATE_REPORTS,
  BILL_METHOD,
  CHARGE_TYPE,
  CUSTOMER,
  CUSTOMER_STATUS,
  PAYMENT_TERMS,
} from 'src/finance/constants';

const chartColors = [
  '#9FC5FA',
  theme.brandGreenDark,
  '#BD10E0',
  '#FFCA00',
  theme.brandPrimary,
  theme.brandAlert,
  theme.brandInfoLight,
  theme.brandWarning,
  theme.brandInfo,
  theme.brandDefault,
  theme.brandGreenDark,
  theme.brandPartSuccess,
  theme.markerBackground,
  theme.brandSuccess,
  '#7300ff',
  '#42b274',
  '#ff00a8',
  '#89b73f',
  '#ff0058',
  '#00a599',
  '#d6b000',
  '#ffa600',
];

const BillCreateStatistics = () => {
  const billReports = useSelector((state: AppState) => state.finance.billCreateSummary.billCreateSummary.amountReports);

  const chartsMemo = React.useMemo(() => {
    if (!billReports) {
      return null;
    }

    const customerSummary = find(billReports, bill => bill.reportCode === CUSTOMER);
    const customerSummaryData = getChartData(customerSummary);

    const paymentTermsSummary = find(billReports, bill => bill.reportCode === PAYMENT_TERMS);
    const paymentTermsSummaryData = getChartData(paymentTermsSummary);

    const chargeTypeSummary = find(billReports, bill => bill.reportCode === CHARGE_TYPE);
    const chargeTypeSummaryData = getChartData(chargeTypeSummary);

    const billMethodSummary = find(billReports, bill => bill.reportCode === BILL_METHOD);
    const billMethodSummaryData = getChartData(billMethodSummary);

    const customerStatusSummary = find(billReports, bill => bill.reportCode === CUSTOMER_STATUS);
    const customerStatusSummaryData = getChartData(customerStatusSummary);

    return (
      <>
        {customerSummaryData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              showSummaryOnHover
              title={BILL_CREATE_REPORTS[CUSTOMER].name}
              doughnutSize={130}
              dataset={{
                data: customerSummaryData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={customerSummaryData.rawDataValues}
              labels={customerSummaryData.labels}
            />
          </ContainerStatisticsElement>
        )}
        {paymentTermsSummaryData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              showSummaryOnHover
              title={BILL_CREATE_REPORTS[PAYMENT_TERMS].name}
              doughnutSize={130}
              dataset={{
                data: paymentTermsSummaryData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={paymentTermsSummaryData.rawDataValues}
              labels={paymentTermsSummaryData.labels}
            />
          </ContainerStatisticsElement>
        )}
        {chargeTypeSummaryData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              showSummaryOnHover
              title={BILL_CREATE_REPORTS[CHARGE_TYPE].name}
              doughnutSize={130}
              dataset={{
                data: chargeTypeSummaryData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={chargeTypeSummaryData.rawDataValues}
              labels={chargeTypeSummaryData.labels}
            />
          </ContainerStatisticsElement>
        )}
        {billMethodSummaryData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              showSummaryOnHover
              title={BILL_CREATE_REPORTS[BILL_METHOD].name}
              doughnutSize={130}
              dataset={{
                data: billMethodSummaryData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={billMethodSummaryData.rawDataValues}
              labels={billMethodSummaryData.labels}
            />
          </ContainerStatisticsElement>
        )}
        {customerStatusSummaryData && (
          <ContainerStatisticsElement verticalAlign="middle" alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              showSummaryOnHover
              title={BILL_CREATE_REPORTS[CUSTOMER_STATUS].name}
              doughnutSize={130}
              dataset={{
                data: customerStatusSummaryData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={customerStatusSummaryData.rawDataValues}
              labels={customerStatusSummaryData.labels}
            />
          </ContainerStatisticsElement>
        )}
      </>
    );
  }, [billReports]);

  return (
    <ContainerStatisticsWrapper>
      <div>{chartsMemo}</div>
    </ContainerStatisticsWrapper>
  );
};

export default BillCreateStatistics;
