import { camelCase, round } from 'lodash-es';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupSubtitle,
  MapGLPopupTitle,
} from 'src/common/components/map/MapGLPopup';
import { getSpeedInformation } from 'src/common/services/getSpeedInformation';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { time } from 'src/utils/services/formatter';
import { isPostTripFeatureEnabled, isPreTripFeatureEnabled } from 'src/vendors/ducks/features';

interface Props {
  vehicleDateTime?: Date | string;
  vehicleDriverName?: string;
  vehicleId: number;
  vehicleMilesDriven?: number;
  vehicleName: string;
  vehicleOdometer?: number;
  vehicleStatus: boolean;
  vehicleType: string;
}

const VehiclePositionsGLPopup: FC<Props> = ({
  vehicleDateTime,
  vehicleDriverName,
  vehicleId,
  vehicleMilesDriven,
  vehicleName,
  vehicleOdometer,
  vehicleStatus,
  vehicleType,
}) => {
  const isPreTripEnabled = useSelector(state => isPreTripFeatureEnabled(state));
  const isPostTripEnabled = useSelector(state => isPostTripFeatureEnabled(state));
  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);

  return (
    <MapGLPopup>
      <MapGLPopupTitle title={vehicleName} />

      <MapGLPopupSubtitle subtitle={translate(`vehicles.vehicleTypes.${camelCase(vehicleType)}`)} />

      <MapGLPopupContent>
        <LabeledDataView
          noWrap
          width="calc(100% - 10px)"
          size="small"
          label={translate('common.timestamp')}
          value={vehicleDateTime ? time(vehicleDateTime) : '-'}
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('drivers.driverName')}
          value={vehicleDriverName || '-'}
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vehicles.vehicleStatus')}
          value={translate(`common.${!!vehicleStatus ? 'active' : 'inactive'}`)}
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vehicles.lastReportedSpeed')}
          value={
            ((vehicleOdometer || vehicleOdometer === 0) &&
              getSpeedInformation(round(vehicleOdometer), getMeasurementType(systemOfMeasurementId))) ||
            '-'
          }
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vehicles.totalMilesDriven')}
          value={vehicleMilesDriven || '-'}
        />

        {(isPostTripEnabled || isPreTripEnabled) && (
          <LabeledDataView noWrap width="calc(100% - 10px)" size="small">
            <Box textDecoration="underline">
              <Link to={`/fleet/vehicles/${vehicleId}/inspection-data?&source=dashboard`}>
                {translate('vehicles.vehicleInspections.viewInspections')}
              </Link>
            </Box>
          </LabeledDataView>
        )}
      </MapGLPopupContent>
    </MapGLPopup>
  );
};

export default VehiclePositionsGLPopup;
