import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  initializeTravelPathBuildOrEdit,
  loadTravelPathBuilderFilters,
  loadTravelPathStatusDetails,
} from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import TravelPathBuilderModal from './TravelPathBuilderModal';
import { loadVendor } from 'src/vendors/ducks';

interface Props {
  closeModal: (shouldRefreshDisplayedTP: boolean) => void;
  date?: string;
  isAlternativeFleet?: boolean;
  isSnowPlow?: boolean;
  name: string;
  routeTemplateId?: number;
}

const TravelPathBuilderModalResolver: FC<Props> = ({
  closeModal,
  routeTemplateId,
  isAlternativeFleet,
  isSnowPlow,
  name,
  date,
}) => {
  const vendorId = useSelector(currentVendorId);

  const dispatch = useDispatch();

  const loadDependencies = useCallback(async () => {
    if (!vendorId || !routeTemplateId) return Promise.reject();

    const configsRes = await initializeTravelPathBuildOrEdit(vendorId, true, undefined, routeTemplateId)(dispatch);

    if (configsRes?.error) return Promise.reject();

    return Promise.all([
      loadTravelPathBuilderFilters(vendorId, routeTemplateId)(dispatch),
      loadTravelPathStatusDetails(undefined, routeTemplateId)(dispatch),
      loadVendor(vendorId)(dispatch),
    ]);
  }, [dispatch, routeTemplateId, vendorId]);

  return (
    <TypedResolver
      successComponent={TravelPathBuilderModal}
      successProps={{ closeModal, routeTemplateId, isAlternativeFleet, name, date, isSnowPlow }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      onError={error => {
        console.error(error);
        closeModal(false);
      }}
    />
  );
};

export default TravelPathBuilderModalResolver;
