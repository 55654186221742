import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { BillHistory } from 'src/finance/interfaces/BillHistory';
import { date } from 'src/utils/services/formatter';

type Props = BillHistory & {};

const BillHistoryTableRow = ({ ...info }: Props) => (
  <TableRow id={`payment-info-${info.id}`} height={TABLE_ROW_HEIGHT_LARGE}>
    <TableCell width="15%" padding="defaultCellVertical xSmallbil">
      <Text block>{info.eventDate ? date(info.eventDate) : '-'}</Text>
    </TableCell>
    <TableCell
      id={`payment-info-${info.id}-date`}
      justifyContent="center"
      vertical
      width="15%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>{info.action || '–'}</Text>
    </TableCell>
    <TableCell
      id={`payment-info-${info.id}-method`}
      justifyContent="center"
      vertical
      width="70%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>{info.details || '–'}</Text>
    </TableCell>
  </TableRow>
);

export default BillHistoryTableRow;
