import { map, sortBy, replace, indexOf, trim, camelCase } from 'lodash-es';

import translate from '../../core/services/translate';

const hoursOfOperationDisplayOrder = {
  SUN: 1,
  MON: 2,
  TUE: 3,
  WED: 4,
  THU: 5,
  FRI: 6,
  SAT: 7,
  EMERG: 8,
};

/* eslint no-bitwise: [2, { allow: ["~"] }] */
export const transformLoadHoursOfOperation = (hoursOfOper: any) => ({
  ...hoursOfOper,
  hoursOfOperation: sortBy(
    map(hoursOfOper.hoursOfOperation, category => ({
      ...category,
      titleKey: translate(`haulerProfile.hoursOfOperationsType.${camelCase(category.title)}`),
      fromHoursFormatted: category.isActive
        ? replace(
            `0${category.from.hours <= 12 ? category.from.hours : ~~(category.from.hours % 12)}:0${
              category.from.minutes
            } ${category.from.hours < 12 ? 'AM' : 'PM'}`,
            /\d(\d\d)/g,
            '$1',
          )
        : '',
      toHoursFormatted: category.isActive
        ? replace(
            `0${category.to.hours <= 12 ? category.to.hours : ~~(category.to.hours % 12)}:0${category.to.minutes} ${
              category.to.hours < 12 ? 'AM' : 'PM'
            }`,
            /\d(\d\d)/g,
            '$1',
          )
        : '',
      orderNumber: (hoursOfOperationDisplayOrder as any)[category.operationType],
    })),
    category => category.orderNumber,
  ).filter(oh => !!oh.orderNumber),
});

export const transformSaveHoursOfOperation = (hoursOfOper: any) => ({
  ...hoursOfOper,
  hoursOfOperation: map(hoursOfOper.hoursOfOperation, category => ({
    ...category,
    from: {
      hours:
        trim(category.fromHoursFormatted.substr(indexOf(category.fromHoursFormatted, ' ') + 1)) === 'AM' ||
        Number(category.fromHoursFormatted.substr(0, 2)) === 12
          ? Number(category.fromHoursFormatted.substr(0, 2))
          : Number(category.fromHoursFormatted.substr(0, 2)) + 12,
      minutes: Number(category.fromHoursFormatted.substr(indexOf(category.fromHoursFormatted, ':') + 1, 2)),
    },
    to: {
      hours:
        trim(category.toHoursFormatted.substr(indexOf(category.toHoursFormatted, ' ') + 1)) === 'AM' ||
        Number(category.toHoursFormatted.substr(0, 2)) === 12
          ? Number(category.toHoursFormatted.substr(0, 2))
          : Number(category.toHoursFormatted.substr(0, 2)) + 12,
      minutes: Number(category.toHoursFormatted.substr(indexOf(category.toHoursFormatted, ':') + 1, 2)),
    },
  })),
});
