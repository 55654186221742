import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const FILE_IMPORT_PROCESS_IN_PROGRESS = 1;
export const FILE_IMPORT_PROCESS_ERROR = 2;
export const FILE_IMPORT_PROCESS_COMPLETED = 3;

export const FILE_IMPORT_PROCESSING_STATUSES = mapKeys(
  [
    { id: FILE_IMPORT_PROCESS_IN_PROGRESS, name: translate('routeImport.fileImportProcessingStatuses.inProgress') },
    { id: FILE_IMPORT_PROCESS_ERROR, name: translate('routeImport.fileImportProcessingStatuses.error') },
    { id: FILE_IMPORT_PROCESS_COMPLETED, name: translate('routeImport.fileImportProcessingStatuses.completed') },
  ],
  'id',
);
