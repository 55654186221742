import { camelize } from 'humps';
import { filter, find, map, upperFirst } from 'lodash-es';

import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { formatNumber } from 'src/utils/services/formatter';

interface Statistic {
  [key: string]: number;
}

interface ChartData {
  labels: string[];
  data: number[];
  rawDataValues?: string[] | number[];
  total: string;
  ignoreTranslations: boolean;
}

export const getChartData = (
  statistic?: Statistic,
  translationPrefix: string = '',
  ignoreTranslations: boolean = false,
  isEquipmentSize: boolean = false,
): ChartData | null => {
  if (!statistic) {
    return null;
  }

  const statisticArray = map(Object.keys(statistic), key => ({
    key: key,
    value: statistic[key],
  }));

  const filteredStatisticArray = filter(statisticArray, el => el.key !== 'total');
  const totalValue = find(statisticArray, el => el.key === 'total')?.value || 0;
  const total = `$${formatNumber(totalValue)}` || '-';

  const data: { label: string; value: number; rawValue: string }[] = [];
  if (ignoreTranslations) {
    map(filteredStatisticArray, (el: any) =>
      data.push({
        label: `${el.value} ${translate('common.serviceContract')}`,
        value: el.key,
        rawValue: formatNumber(el.key),
      }),
    );
  } else
    map(filteredStatisticArray, (el: any) =>
      data.push({
        label: isEquipmentSize
          ? translate(
              `common.equipmentSizes.x${upperFirst(
                camelize(el.key?.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
              )}`,
              { size: el.key ? parseFloat(el.key) : 0 },
            )
          : `${translate(createTranslationKey(el.key, translationPrefix))}`,
        value: el.value,
        rawValue: formatNumber(el.value),
      }),
    );

  return {
    labels: data.map(item => item.label),
    data: data.map(item => item.value),
    rawDataValues: data.map(item => parseFloat(item.rawValue.replace(/[$,]/g, ''))), // Convert string to number
    total: total,
    ignoreTranslations,
  };
};
