import { identity, get, pick } from 'lodash-es';
import { createSelector } from 'reselect';

import { Group } from '../interfaces/Groups';

const getRouteEditorFormInitialValues = (routeState: any, geoFenceState: any) => ({
  ...pick(
    get(routeState, 'route', {}),
    'isTestRoute',
    'routeConfirmationTypeId',
    'routeDate',
    'routeName',
    'startingLocationId',
    'vehicleId',
    'vehicleTypeId',
    'vendorServiceZoneId',
    'wasteMaterialTypeId',
    'intermediateLocationId',
    'supervisorId',
  ),
  geoFence:
    !!routeState.route && geoFenceState.geoFence && routeState.route.id
      ? geoFenceState.geoFence
      : {
          geoFenceCoordinates: [],
        },
  groups: get(routeState, 'route.groups', []).map((group: Group) => group.id),
});

const routeEditorFormInitialValuesSelector = createSelector(getRouteEditorFormInitialValues, identity);

export default routeEditorFormInitialValuesSelector;
