import styled, { css } from 'styled-components';

import { sizeMapper } from '../../../utils/styles';

interface Props {
  alignItems?: string;
  margin?: string;
  orientation?: string;
}

export const DownArrow = styled.span<Props>`
  outline: none;
  margin: ${props => sizeMapper(props.margin, 'no')};
  cursor: pointer;
  transform: ${props => (props.orientation === 'top' ? '' : 'rotate(180deg)')};
  color: ${props => props.theme.grayDarker};
  font-size: 11px;

  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
      display: flex;
      position: relative;
      top: -4px;
    `}
`;
