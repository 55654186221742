import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';

import { APPLICATION_STATUSES } from 'src/common/constants';
import { getSpeedInformation } from 'src/common/services/getSpeedInformation';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { StrobeImagesModalResolver } from 'src/routes/components/modals';
import StrobeImagesResolver from 'src/routes/components/pages/routes/routePageSections/routeMap/StrobeImagesResolver';
import { FORWARD_VIEW_ID, VIDEO_DEVICE_SURFSIGHT_TYPE_ID } from 'src/routes/constants';
import { saveVideoRequest } from 'src/routes/ducks';
import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { dateFormat, timeFormat } from 'src/utils/services/validator';
import { MapGLPopup, MapGLPopupContent, MapGLPopupTitle } from 'src/common/components/map/MapGLPopup';
import { time, timeStamp } from 'src/utils/services/formatter';
import { VideoRequestFormValues } from '../vehicleTrackings/VideoRequestForm';
import VideoRequestModalResolver from '../vehicleTrackings/VideoRequestModalResolver';

interface Props {
  applicationModeStatusId: number;
  shouldDisplayDownloadVideo?: boolean;
  videoDeviceTypeId?: number;
  deviceId?: string;
  showTimestamp?: boolean;
  speed: number;
  timestamp?: string;
  unitOfMeasure: string;
  vehicleId: number;
  vehicleName: string;
}

const RouteTimelineMapVehicleTrackingsGLPopup: React.FC<Props> = ({
  applicationModeStatusId,
  shouldDisplayDownloadVideo,
  videoDeviceTypeId = VIDEO_DEVICE_SURFSIGHT_TYPE_ID,
  deviceId,
  showTimestamp,
  speed,
  timestamp,
  unitOfMeasure,
  vehicleId,
  vehicleName,
}) => {
  const dispatch = useDispatch();

  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const [isStrobeImageModalOpen, setIsStrobeImageModalOpen] = useState(false);
  const [isVideoRequestModalOpen, setIsVideoRequestModalOpen] = useState(false);

  const onVideoRequestModalSubmit = (formValues: VideoRequestFormValues) => {
    saveVideoRequest({
      ...formValues,
      userId,
      vendorId,
      routeId: routeSummary?.routeId,
      driverId: routeSummary?.driverId,
    })(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.videoRequestSaved'));
        setIsVideoRequestModalOpen(false);
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.videoRequestSaveError'));
      });
  };

  return (
    <>
      <MapGLPopup>
        <MapGLPopupTitle title={vehicleName} />

        <MapGLPopupContent>
          {!!timestamp && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.timestamp')}
              value={showTimestamp ? timeStamp(timestamp) : time(timestamp)}
            />
          )}

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.appStatus')}
            value={APPLICATION_STATUSES[applicationModeStatusId].name}
          />

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.speed')}
            value={getSpeedInformation(speed, unitOfMeasure)}
          />

          <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
            <StrobeImagesResolver
              vehicleId={vehicleId}
              timestamp={timestamp}
              setIsStrobeImageModalOpen={setIsStrobeImageModalOpen}
            />
          </LabeledDataView>

          {shouldDisplayDownloadVideo && (
            <LabeledDataView noWrap size="small">
              <Box cursor="pointer" onClick={() => setIsVideoRequestModalOpen(true)} textDecoration="underline">
                {translate('routes.videoRequest.requestVideoFootage')}
              </Box>
            </LabeledDataView>
          )}
        </MapGLPopupContent>
      </MapGLPopup>

      {isStrobeImageModalOpen && (
        <StrobeImagesModalResolver
          closeModal={() => setIsStrobeImageModalOpen(false)}
          shouldDisplayDownloadVideo={shouldDisplayDownloadVideo}
          videoDeviceTypeId={videoDeviceTypeId}
          deviceId={deviceId}
          timeStamp={timestamp}
          vehicleId={vehicleId}
          vehicleName={vehicleName}
        />
      )}

      {isVideoRequestModalOpen && (
        <VideoRequestModalResolver
          closeModal={() => setIsVideoRequestModalOpen(false)}
          onSubmit={onVideoRequestModalSubmit}
          initialValues={{
            date: moment(timestamp).format(dateFormat),
            time: moment(timestamp).format(timeFormat),
            vehicleId,
            vehicleName,
            vehicleCameraConfigurationTypeIds: [FORWARD_VIEW_ID],
            videoDeviceTypeId,
            deviceId,
          }}
        />
      )}
    </>
  );
};

export default RouteTimelineMapVehicleTrackingsGLPopup;
