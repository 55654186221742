import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, ThumbnailSet } from 'src/core/components';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { getWaterFillsTableCellWidths } from './WaterFillsSection';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import { useSelector } from 'src/core/hooks/useSelector';
import { WATER_FILL_MATERIALS, WATER_FILL_UOM } from 'src/routes/constants/waterFill';
import { WaterFills } from 'src/routes/interfaces/WaterFills';
import { WeightTicketImagesModal } from '..';

type Props = {
  deleteWaterFill?: (waterFillId: number) => void;
  editWaterFill?: (waterFillId: number) => void;
  isViewOnly?: boolean;
  waterFillsCellWidths?: string[];
};

export default function WaterFillsTableRow({
  deleteWaterFill,
  editWaterFill,
  id: waterFillId,
  image,
  isViewOnly,
  locationAddress,
  locationName,
  materialAmount,
  materialTypeId,
  pickUpDateTimeLocal,
  unitOfMeasureTypeId,
  waterFillsCellWidths,
}: Props & WaterFills) {
  const isSupportPermission = checkIfSupport();
  const isViewOnlyPermission = checkIfViewOnly();

  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const [isWaterFillsImageModalOpen, setWaterFillsImageModalOpen] = useState(false);

  if (!routeSummary && !isViewOnly) return null;

  const timestampMoment = moment(pickUpDateTimeLocal);
  const waterFillsTableCellWidths =
    isViewOnly && waterFillsCellWidths ? waterFillsCellWidths : getWaterFillsTableCellWidths();

  return (
    <>
      <TableRow>
        <TableCell width={waterFillsTableCellWidths[0]} vertical justifyContent="center">
          <Text>{locationName}</Text>
          <Text size="small">{locationAddress}</Text>
        </TableCell>
        <TableCell width={waterFillsTableCellWidths[1]}>
          <Text>{materialTypeId && WATER_FILL_MATERIALS[materialTypeId].name}</Text>
        </TableCell>
        <TableCell width={waterFillsTableCellWidths[2]}>
          <Text>{materialAmount}</Text>
        </TableCell>
        <TableCell width={waterFillsTableCellWidths[3]}>
          <Text>{unitOfMeasureTypeId && WATER_FILL_UOM[unitOfMeasureTypeId].name}</Text>
        </TableCell>
        <TableCell width={waterFillsTableCellWidths[4]} vertical justifyContent="center">
          <Text weight="medium">{timestampMoment.format('MM/DD/YYYY')}</Text>
          <Text>{timestampMoment.format(timeFormatWithoutSeconds)}</Text>
        </TableCell>
        <TableCell width={waterFillsTableCellWidths[5]}>
          <Text>
            {image?.url && <ThumbnailSet images={[image?.url]} onClick={() => setWaterFillsImageModalOpen(true)} />}
          </Text>
        </TableCell>

        {!isViewOnlyPermission && !isSupportPermission && !isViewOnly && (
          <>
            <TableCell width={waterFillsTableCellWidths[6]} align="right">
              <TableActionButton
                onClick={() => (editWaterFill && waterFillId ? editWaterFill(waterFillId) : undefined)}
              >
                <ActionButtonTooltip icon="edit" tooltip="edit" />
              </TableActionButton>
              <TableActionButton
                onClick={() => (deleteWaterFill && waterFillId ? deleteWaterFill(waterFillId) : undefined)}
              >
                <ActionButtonTooltip icon="delete" tooltip="delete" />
              </TableActionButton>
            </TableCell>
          </>
        )}

        {isWaterFillsImageModalOpen && (
          <WeightTicketImagesModal
            weightTicket={{
              disposalSiteAddress: locationName,
              image: { url: image?.url, rawUrl: image?.url },
              locationAddress,
              materialType: materialTypeId && WATER_FILL_MATERIALS[materialTypeId].name,
            }}
            closeModal={() => setWaterFillsImageModalOpen(false)}
          />
        )}
      </TableRow>
    </>
  );
}
