import { PureComponent } from 'react';

import { Line, Pie, Bar } from 'react-chartjs-2';
import { orderBy, cloneDeep, sum } from 'lodash-es';

import { ASC, DESC, LINE, PIE, BAR, TABLE_ROW_LIMIT } from '../../../../core/constants';
import { createUrl } from '../../../../utils/services/queryParams';
import {
  EXCEPTIONS_BY_HOUSEHOLD,
  EXCEPTIONS,
  GEO_FENCES_COVERAGE_STREET_SWEEPING,
  GEO_FENCES_STREET_SWEEPING,
  MATERIAL_CONTAMINATION,
  OBSTACLES_STREET_SWEEPING,
} from '../../../../insights/constants';
import {
  DISPOSAL_TONS,
  DRIVER_SAFETY,
  EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD,
  FAULT_CODES,
  HARD_ACCELERATION,
  HARD_BREAKING,
  HARD_TURNING,
  OFF_ROUTE,
  PICKUPS_PER_HOUR,
  RECYCLE_PARTICIPATION_RATE,
  SPEEDING,
  STATIONARY_OFF_ROUTE,
  STATIONARY,
  STREET_SWEEPING_LOADS_DUMPED,
  STREET_SWEEPING_WATER_FILLUP,
  TRIP_TIME_STREET_SWEEPER,
  TRIP_TIME,
} from '../../../constants/reportTypes';
import { Hide, Popover } from '../../../../core/components/styled';
import { INSIGHTS_SEE_DETAILS } from '../../../../account/constants';
import {
  InsightCard as InsightCardContainer,
  InsightCardChartContainer,
  InsightCardContent,
  InsightCardDetails,
  InsightCardHeader,
  InsightCardInfoIcon,
  InsightCardMoreDataAvailable,
  InsightCardSeeDetails,
  InsightCardTable,
  InsightCardTableBody,
  InsightCardTableCell,
  InsightCardTableContainer,
  InsightCardTableHead,
  InsightCardTableRow,
  InsightCardTableSort,
  InsightCardTableSortIcon,
  InsightCardTableToggle,
  InsightCardTableToggleContainer,
  InsightCardTitle,
  InsightCardValue,
  InsightCardValueExplain,
  InsightCardValues,
  InsightCardValueUnit,
  InsightPieChartPlaceholder,
  InsightPieChartPlaceholderTooltip,
} from '../../styled';
import { PermissionGuard } from '../../../../account/components';
import { PopoverWrapper } from '../../../../core/components';
import createTranslationKey from '../../../../utils/services/createTranslationKey';
import translate from '../../../../core/services/translate';
import { formatInsightValue } from 'src/insights/services/getInsightCardDetails';

export const ChartComponents = {
  [LINE]: Line,
  [PIE]: Pie,
  [BAR]: Bar,
};

const maxAmountOfDimensionsTillOverflow = 5;

interface Props {
  cardDetails: any;
  chartData: any;
  chartOptions: any;
  chartPlaceholderText?: string;
  chartType: string;
  date?: Date | string;
  daysOfOperation?: any[];
  displayChartPlaceholder?: boolean;
  goToDataTrendsPage: (
    date?: Date | string,
    vehicleTypeId?: number,
    reportType?: string,
    daysOfOperation?: any[],
  ) => any;
  index?: number;
  vehicleTypeId?: number;
}

interface State {
  isExpanded: boolean;
  sortOrder: string;
  tableRows: any[];
}

class InsightCard extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      cardDetails: { tableRows, sortOrder },
    } = props;

    const orderedTableRows = orderBy(tableRows, [this.getValueNameToSortBy(), 'name'], [sortOrder, sortOrder]);

    this.state = {
      isExpanded: false,
      tableRows: orderedTableRows,
      sortOrder,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const {
      cardDetails: { tableRows },
    } = this.props;

    if (tableRows !== prevProps.cardDetails.tableRows) {
      this.setState(({ sortOrder }) => ({
        tableRows: orderBy(tableRows, [this.getValueNameToSortBy(), 'name'], [sortOrder as any, sortOrder as any]),
      }));
    }
  }

  getValueNameToSortBy = () => {
    const {
      cardDetails: { reportType },
    } = this.props;

    const valueToSortBy =
      reportType === STREET_SWEEPING_LOADS_DUMPED || reportType === STREET_SWEEPING_WATER_FILLUP
        ? 'formattedValue'
        : 'value';

    return valueToSortBy;
  };

  onChartContainerClick = () => {
    const {
      date,
      vehicleTypeId,
      cardDetails: { reportType },
      goToDataTrendsPage,
      daysOfOperation,
    } = this.props;

    if (
      reportType !== GEO_FENCES_COVERAGE_STREET_SWEEPING &&
      reportType !== GEO_FENCES_STREET_SWEEPING &&
      reportType !== MATERIAL_CONTAMINATION &&
      reportType !== OBSTACLES_STREET_SWEEPING
    ) {
      goToDataTrendsPage(date, vehicleTypeId, reportType, daysOfOperation);
    }
  };

  getChartData = () => {
    const { chartData } = this.props;
    let result = chartData;
    if (this.showOverflow()) {
      const dataClone = cloneDeep(chartData);
      dataClone.datasets[0].data = dataClone.datasets[0].data.splice(0, maxAmountOfDimensionsTillOverflow);
      dataClone.labels = dataClone.labels.splice(0, maxAmountOfDimensionsTillOverflow);
      result = dataClone;
    }
    return result;
  };

  toggle = () => {
    this.setState(({ isExpanded }) => ({
      isExpanded: !isExpanded,
    }));
  };

  toggleSortOrder = () => {
    this.setState(({ sortOrder }) => {
      const nextSortOrder = sortOrder === ASC ? DESC : ASC;
      const tableRows = orderBy(
        this.props.cardDetails.tableRows,
        [this.getValueNameToSortBy(), 'name'],
        [nextSortOrder, nextSortOrder],
      );

      return {
        tableRows,
        sortOrder: nextSortOrder,
      };
    });
  };

  showOverflow = () => {
    const { chartData, chartType } = this.props;
    return (
      chartType === BAR &&
      chartData &&
      chartData.datasets[0].data &&
      chartData.datasets[0].data.length > maxAmountOfDimensionsTillOverflow
    );
  };

  render() {
    const {
      cardDetails: { explain, reportDescription, reportName, reportType, seeDetailsPath, tableColumns, unit },
      chartOptions,
      chartPlaceholderText,
      chartType,
      date,
      displayChartPlaceholder,
      vehicleTypeId,
    } = this.props;

    const { isExpanded, tableRows, sortOrder } = this.state;
    const tableRowsWithValue = tableRows.filter(row => row.value !== 0);
    const averageFormattedValue = formatInsightValue(
      tableRowsWithValue.length
        ? [
            STREET_SWEEPING_LOADS_DUMPED,
            STREET_SWEEPING_WATER_FILLUP,
            SPEEDING,
            DISPOSAL_TONS,
            FAULT_CODES,
            HARD_ACCELERATION,
            HARD_BREAKING,
            HARD_TURNING,
            DRIVER_SAFETY,
          ].includes(reportType)
          ? sum(tableRowsWithValue.map(el => el.value))
          : (sum(tableRowsWithValue.map(el => el.value)) / tableRowsWithValue.length).toFixed(2)
        : 0,
      this.props.cardDetails,
    );

    const ChartComponent = (ChartComponents as any)[chartType];
    const seeDetailsUrl = createUrl(seeDetailsPath, undefined, {
      startDate: date,
      endDate: date,
      vehicleTypeId,
      reportType,
      sortOrder,
    });

    const getName = (tableRowName: string, tableRowTechnicalName: string, reportType: string) => {
      switch (reportType) {
        case OBSTACLES_STREET_SWEEPING:
          return translate(createTranslationKey(tableRowName, 'insights.obstacles'));

        case EXCEPTIONS:
        case EXCEPTIONS_BY_HOUSEHOLD:
        case EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD:
          return translate(createTranslationKey(tableRowTechnicalName, 'routes.wasteAuditStatuses'));

        case MATERIAL_CONTAMINATION:
          return translate(createTranslationKey(tableRowName, 'insights.contaminationSubType'));

        default:
          return tableRowName;
      }
    };

    const isCursorPointer =
      !displayChartPlaceholder &&
      reportType !== GEO_FENCES_COVERAGE_STREET_SWEEPING &&
      reportType !== GEO_FENCES_STREET_SWEEPING &&
      reportType !== MATERIAL_CONTAMINATION &&
      reportType !== OBSTACLES_STREET_SWEEPING;

    const isAverageValue =
      reportType === TRIP_TIME ||
      reportType === STATIONARY ||
      reportType === PICKUPS_PER_HOUR ||
      reportType === OFF_ROUTE ||
      reportType === STATIONARY_OFF_ROUTE ||
      reportType === RECYCLE_PARTICIPATION_RATE ||
      reportType === TRIP_TIME_STREET_SWEEPER;

    return (
      <InsightCardContainer>
        <InsightCardHeader>
          <InsightCardTitle title={reportName}>{reportName}</InsightCardTitle>
          <PopoverWrapper
            triggerButton={<InsightCardInfoIcon id={`insights-${reportType}-popover-button`} />}
            popoverContent={<Popover id={`insights-${reportType}-popover`}>{reportDescription}</Popover>}
            width="14px"
          />
        </InsightCardHeader>

        <InsightCardContent>
          <Hide hide={isExpanded}>
            <InsightCardDetails>
              <InsightCardValues>
                <InsightCardValue id={`insights-${reportType}-card-value`}>
                  {averageFormattedValue}
                  {isAverageValue && (
                    <PopoverWrapper
                      margin="no no no xSmall"
                      triggerButton={<InsightCardInfoIcon id={`insights-${reportType}-popover-button`} />}
                      popoverContent={
                        <Popover id={`insights-${reportType}-popover`}>
                          {translate('insights.averageValueExcluding0')}
                        </Popover>
                      }
                      width="14px"
                    />
                  )}
                </InsightCardValue>
                <InsightCardValueUnit>{unit}</InsightCardValueUnit>
                <InsightCardValueExplain>{explain}</InsightCardValueExplain>
              </InsightCardValues>
              <PermissionGuard permission={INSIGHTS_SEE_DETAILS}>
                <InsightCardSeeDetails to={seeDetailsUrl} id={`insights-${reportType}-see-details`}>
                  {translate('common.seeDetails')}
                </InsightCardSeeDetails>
              </PermissionGuard>
            </InsightCardDetails>
          </Hide>

          <Hide hide={isExpanded}>
            <InsightCardChartContainer onClick={this.onChartContainerClick} isCursorPointer={isCursorPointer}>
              {!displayChartPlaceholder && <ChartComponent data={this.getChartData()} options={chartOptions} />}

              {!!displayChartPlaceholder && (
                <InsightPieChartPlaceholder size="small">
                  <InsightPieChartPlaceholderTooltip>{chartPlaceholderText}</InsightPieChartPlaceholderTooltip>
                </InsightPieChartPlaceholder>
              )}

              {this.showOverflow() && (
                <InsightCardMoreDataAvailable>{translate('insights.more')}</InsightCardMoreDataAvailable>
              )}
            </InsightCardChartContainer>
          </Hide>

          <InsightCardTableContainer isExpanded={isExpanded}>
            <InsightCardTable>
              <InsightCardTableHead>
                <InsightCardTableRow>
                  <InsightCardTableCell>{tableColumns && tableColumns[0]}</InsightCardTableCell>

                  <InsightCardTableCell>
                    <InsightCardTableSort
                      sortOrder={sortOrder}
                      onClick={this.toggleSortOrder}
                      id={`insights-${reportType}-sort-button`}
                    >
                      {tableColumns && tableColumns[1]}
                      <InsightCardTableSortIcon />
                    </InsightCardTableSort>
                  </InsightCardTableCell>
                </InsightCardTableRow>
              </InsightCardTableHead>

              <InsightCardTableBody>
                {tableRows.map((tableRow, tableIndex) => (
                  <InsightCardTableRow key={`${tableRow.name}-${tableIndex}`}>
                    <InsightCardTableCell id={`insights-${reportType}-vehicle-${tableIndex}-name`}>
                      {getName(tableRow.name, tableRow.technicalName, reportType)}
                    </InsightCardTableCell>

                    <InsightCardTableCell id={`insights-${reportType}-vehicle-${tableIndex}-value`}>
                      {tableRow.formattedValue}
                    </InsightCardTableCell>
                  </InsightCardTableRow>
                ))}
              </InsightCardTableBody>
            </InsightCardTable>

            <InsightCardTableToggleContainer>
              {tableRows.length > TABLE_ROW_LIMIT && (
                <InsightCardTableToggle onClick={this.toggle} id={`insights-${reportType}-show-more`}>
                  {isExpanded ? translate('common.showLess') : translate('common.showMore')}
                </InsightCardTableToggle>
              )}
            </InsightCardTableToggleContainer>
          </InsightCardTableContainer>
        </InsightCardContent>
      </InsightCardContainer>
    );
  }
}

export default InsightCard;
