import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import useRefreshDataInterval from 'src/common/hooks/useRefreshDataInterval';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { ActionButtonTooltip, Checkbox, TypedField } from 'src/core/components';
import { FieldProps } from 'src/core/components/FieldProps';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { Button, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { TRAVEL_PATH_LOCK_TYPE_BUILD_ID, TRAVEL_PATH_LOCK_TYPE_EDIT_ID } from 'src/routes/constants';
import {
  downloadTravelPathGeoJsonFile,
  finishTravelPathBuildOrEdit,
  loadTravelPathStatusDetails,
  triggerTravelPath,
} from 'src/routes/ducks';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import TravelPathEditorModalResolver from '../../modals/TravelPathEditor/TravelPathEditorModalResolver';
import TravelPathBuilderModalResolver from '../../modals/travelPathBuilder/TravelPathBuilderModalResolver';

interface TravelPathFieldProps extends WrappedFieldProps, FieldProps {
  date?: string;
  displayTravelPath: boolean;
  isActionButtonAvailable: boolean;
  isAlternativeFleet?: boolean;
  isSnowPlow?: boolean;
  routeName: string;
  routeId?: number;
  routeTemplateId?: number;
}

const TravelPathField: FC<TravelPathFieldProps> = ({
  input: { name, onChange, ...inputRest },
  displayTravelPath = true,
  isActionButtonAvailable = true,
  routeId,
  routeTemplateId,
  isAlternativeFleet,
  isSnowPlow,
  routeName,
  date,
}) => {
  const [showTravelPathEditorModal, setShowTravelPathEditorModal] = useState(false);
  const [showTravelPathBuilderModal, setShowTravelPathBuilderModal] = useState(false);

  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);

  const {
    isLoadingTravelPathStatus,
    isTriggeringTravelPath,
    travelPathStatusDetails,
    travelPathError,
    travelPathData,
  } = useSelector(state => state.routes.travelPath);

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  const hasUnlockBuildOrEditCapability =
    !isViewOnly && !isSupport && !travelPathStatusDetails?.lockDetails?.isLockedBySystem;

  const isGeneralLocked =
    travelPathStatusDetails?.lockDetails?.isLockedBySystem ||
    (travelPathStatusDetails?.lockDetails?.isLockedByUser && !travelPathStatusDetails?.lockDetails?.isLockedByMe);

  const isLockedEdit =
    travelPathStatusDetails?.lockDetails?.isLockedByMe &&
    travelPathStatusDetails?.lockDetails?.lockType?.id === TRAVEL_PATH_LOCK_TYPE_BUILD_ID;
  const isLockedBuilder =
    travelPathStatusDetails?.lockDetails?.isLockedByMe &&
    travelPathStatusDetails?.lockDetails?.lockType?.id === TRAVEL_PATH_LOCK_TYPE_EDIT_ID;

  const isTriggerTravelPathButtonUnavailable =
    !isActionButtonAvailable ||
    isLoadingTravelPathStatus ||
    isTriggeringTravelPath ||
    travelPathStatusDetails?.inProgress ||
    isLockedEdit ||
    isLockedBuilder ||
    isGeneralLocked ||
    isSupport ||
    isViewOnly;

  const isViewTravelPathCheckBoxDisabled =
    isLoadingTravelPathStatus ||
    isTriggeringTravelPath ||
    travelPathStatusDetails?.inProgress ||
    !travelPathStatusDetails?.isCreated ||
    isLockedEdit ||
    isLockedBuilder ||
    isGeneralLocked;

  const isEditOrBuildTravePathOptionUnavailable =
    !isActionButtonAvailable ||
    isLoadingTravelPathStatus ||
    isTriggeringTravelPath ||
    travelPathStatusDetails?.inProgress ||
    !travelPathStatusDetails?.isCreated ||
    travelPathStatusDetails?.isDirty ||
    travelPathStatusDetails?.isFailed ||
    !!travelPathError ||
    !isNaviV3FeatureEnabled ||
    isSupport ||
    isViewOnly;

  const isRefreshRequired = travelPathStatusDetails?.isRefreshRequired;

  // if the travel path is inProgress, make the checkbox unchecked
  useEffect(() => {
    if (travelPathStatusDetails?.inProgress) {
      onChange(false);
    }
  }, [inputRest.value, onChange, travelPathStatusDetails?.inProgress]);

  const handleChange: TypedFieldOnChangeFunction = (event, value) => {
    onChange(value);
  };

  const handleOpenTravelPathBuildOrEdit = useCallback(
    async (openBuilder?: boolean) => {
      if (!travelPathStatusDetails) return;
      if (isRefreshRequired) {
        if (
          await confirm(
            translate('routes.travelPath.alertMessages.travelPathNeedsRefresh'),
            translate('routes.travelPath.alertMessages.refreshToProceed'),
            translate('common.cancel'),
            translate('tooltips.refresh'),
          )
        ) {
          triggerTravelPath(
            routeId,
            routeTemplateId,
          )(dispatch).catch(() => {
            createErrorNotification(translate('routes.travelPath.alertMessages.triggerError'));
          });
          loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
        }
      } else {
        // if the user has otherLocks, do not allow to open the travel path editor
        //and ask if he wants to end the other edit session lock
        if (travelPathStatusDetails.lockDetails?.otherLockByMe) {
          if (
            await confirm(
              translate('routes.travelPath.alertMessages.anotherSessionFound'),
              translate('routes.travelPath.alertMessages.anotherSessionOpen', {
                entityName: travelPathStatusDetails.lockDetails?.otherLockByMe?.name || '',
              }),
              translate('common.cancel'),
              translate('common.yes'),
            )
          ) {
            const isBuilder =
              travelPathStatusDetails.lockDetails?.otherLockByMe?.lockType?.id === TRAVEL_PATH_LOCK_TYPE_BUILD_ID;
            finishTravelPathBuildOrEdit(
              vendorId,
              isBuilder,
              travelPathStatusDetails.lockDetails?.otherLockByMe.routeId,
              travelPathStatusDetails.lockDetails?.otherLockByMe.routeTemplateId,
            )(dispatch)
              .then(res => {
                if (!res.error?.code) {
                  if (openBuilder) {
                    setShowTravelPathBuilderModal(true);
                  } else setShowTravelPathEditorModal(true);
                }
              })
              .catch(() => {
                createErrorNotification(translate('routes.travelPath.alertMessages.unlockError'));
              });
          }
        } else if (openBuilder) {
          setShowTravelPathBuilderModal(true);
        } else setShowTravelPathEditorModal(true);
      }
    },
    [dispatch, isRefreshRequired, routeId, routeTemplateId, travelPathStatusDetails, vendorId],
  );

  const triggerTravelPathRegenerateAction = useCallback(async () => {
    if (
      await confirm(
        !travelPathStatusDetails?.isCreated
          ? translate('routes.travelPath.alertMessages.createTravelPathConfirmation')
          : translate('routes.travelPath.alertMessages.regenerateConfirmation'),
      )
    )
      triggerTravelPath(
        routeId,
        routeTemplateId,
      )(dispatch).catch(() => {
        createErrorNotification(translate('routes.travelPath.alertMessages.triggerError'));
      });
  }, [dispatch, routeId, routeTemplateId, travelPathStatusDetails?.isCreated]);

  const unlockTravelPathActions = useCallback(async () => {
    if (
      await confirm(
        translate('routes.travelPath.beingEditedCurrently'),
        translate('routes.travelPath.beingEditedMessage', {
          entityName: routeId ? translate('common.route') : translate('routes.routeTemplate'),
          userName: travelPathStatusDetails?.lockDetails?.lockedByUsername || '',
        }),
        translate('common.cancel'),
        translate('routes.travelPath.unlock'),
      )
    ) {
      const isBuilder = travelPathStatusDetails?.lockDetails?.lockType?.id === TRAVEL_PATH_LOCK_TYPE_BUILD_ID;
      finishTravelPathBuildOrEdit(
        vendorId,
        isBuilder,
        routeId,
        routeTemplateId,
      )(dispatch)
        .then(() => {
          loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
        })
        .catch(() => {
          createErrorNotification(translate('routes.travelPath.alertMessages.unlockError'));
        });
    }
  }, [
    dispatch,
    routeId,
    routeTemplateId,
    travelPathStatusDetails?.lockDetails?.lockType?.id,
    travelPathStatusDetails?.lockDetails?.lockedByUsername,
    vendorId,
  ]);

  const unlockTravelPathActionsForUser = useCallback(async () => {
    if (
      await confirm(
        translate('routes.travelPath.beingEditedCurrently'),
        translate('routes.travelPath.areYouSureYouWantToUnlock'),
        translate('common.cancel'),
        translate('routes.travelPath.unlock'),
      )
    ) {
      const isBuilder = travelPathStatusDetails?.lockDetails?.lockType?.id === TRAVEL_PATH_LOCK_TYPE_BUILD_ID;
      finishTravelPathBuildOrEdit(
        vendorId,
        isBuilder,
        routeId,
        routeTemplateId,
      )(dispatch)
        .then(() => {
          loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
        })
        .catch(() => {
          createErrorNotification(translate('routes.travelPath.alertMessages.unlockError'));
        });
    }
  }, [dispatch, routeId, routeTemplateId, travelPathStatusDetails?.lockDetails?.lockType?.id, vendorId]);

  const REFRESH_TRAVEL_PATH_INTERVAL = 30000;

  const refreshData = useCallback(() => {
    if (
      displayTravelPath &&
      getIsVendorNotChanged(vendorId) &&
      (routeId || routeTemplateId) &&
      (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled)
    ) {
      loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
    }
  }, [
    dispatch,
    displayTravelPath,
    isTravelPathFeatureEnabled,
    isNaviV3FeatureEnabled,
    routeId,
    routeTemplateId,
    vendorId,
  ]);

  useRefreshDataInterval({
    interval: REFRESH_TRAVEL_PATH_INTERVAL,
    callback: refreshData,
    refreshOnWindowFocus: true,
  });

  const closeTravelPathEditorModal = useCallback(
    (shouldRefreshDisplayedTP: boolean) => {
      setShowTravelPathEditorModal(false);
      loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
      if (travelPathData && shouldRefreshDisplayedTP) {
        downloadTravelPathGeoJsonFile(routeId, routeTemplateId)(dispatch);
      }
    },
    [dispatch, routeId, routeTemplateId, travelPathData],
  );

  const closeTravelPathBuilderModal = useCallback(
    (shouldRefreshDisplayedTP: boolean) => {
      setShowTravelPathBuilderModal(false);
      loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch);
      if (travelPathData && shouldRefreshDisplayedTP) {
        downloadTravelPathGeoJsonFile(routeId, routeTemplateId)(dispatch);
      }
    },
    [dispatch, routeId, routeTemplateId, travelPathData],
  );

  const travelPathStatus = useMemo(() => {
    if (travelPathStatusDetails) {
      const { inProgress, isCreated, isDirty, isFailed, isRefreshRequired } = travelPathStatusDetails;
      if (isLoadingTravelPathStatus || isTriggeringTravelPath)
        return (
          <>
            <Loader />
          </>
        );
      if (isFailed) return translate('routes.travelPath.failed');
      if (inProgress)
        return (
          <Box display="flex">
            {translate('routes.routeStatuses.inProgress')}
            <Box margin="no no no xSmall">
              <Loader />
            </Box>
          </Box>
        );
      if ((isCreated && isDirty) || isRefreshRequired) return translate('routes.travelPath.refreshRequired');
      if (!isDirty && !isCreated && !isRefreshRequired) return translate('routes.travelPath.createPath');
      if (isCreated && !isDirty && !isRefreshRequired) return translate('routes.travelPath.upToDate');
    }
    return null;
  }, [isLoadingTravelPathStatus, isTriggeringTravelPath, travelPathStatusDetails]);

  // if neither travel path nor navi v3 feature is enabled, do not display the travel path functionality
  if (!isTravelPathFeatureEnabled && !isNaviV3FeatureEnabled) return null;

  return (
    <>
      <Box display="flex" alignItems="flex-start" margin="small no no no">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <TypedField
              {...inputRest}
              name={name}
              component={Checkbox}
              onChange={handleChange}
              props={{
                label: translate('routes.travelPath.travelPath'),
                block: true,
                margin: 'no',
                size: 'small',
                disabled: isViewTravelPathCheckBoxDisabled,
              }}
            />

            {isViewTravelPathCheckBoxDisabled && isEditOrBuildTravePathOptionUnavailable && (
              <>
                {isGeneralLocked && (
                  <ActionButtonTooltip
                    icon="lock"
                    tooltipAsString
                    margin="no no no xSmall"
                    tooltip={`${translate('routes.travelPath.beingEditedByUser')}`}
                  />
                )}

                {isLockedEdit && (
                  <ActionButtonTooltip
                    icon="lock"
                    tooltipAsString
                    margin="no no no xSmall"
                    tooltip={`${translate('routes.travelPath.youAlreadyStartedBuildSession')}`}
                  />
                )}

                {isLockedBuilder && (
                  <ActionButtonTooltip
                    icon="lock"
                    tooltipAsString
                    margin="no no no xSmall"
                    tooltip={`${translate('routes.travelPath.youAlreadyStartedEditSession')}`}
                  />
                )}
              </>
            )}
          </Box>

          {isActionButtonAvailable && !travelPathError && (
            <Text block size="xSmall" margin="xxSmall no xxSmall medium">
              <em>{travelPathStatus}</em>
            </Text>
          )}
        </Box>
        <Box margin="no no no small" display="flex" flexDirection="column" justifyContent="space-between">
          <Box>
            {!isEditOrBuildTravePathOptionUnavailable && (
              <>
                {isGeneralLocked ? (
                  <Button
                    id="edit-trigger-tp-button"
                    text
                    padding="no"
                    onClick={unlockTravelPathActions}
                    type="button"
                    disabled={!hasUnlockBuildOrEditCapability}
                  >
                    <ActionButtonTooltip
                      icon="lock"
                      tooltipAsString
                      tooltip={`${translate('routes.travelPath.beingEditedByUser')}${
                        hasUnlockBuildOrEditCapability ? `. ${translate('routes.travelPath.unlockEditing')}` : ''
                      }`}
                    />
                  </Button>
                ) : (
                  <>
                    {!isLockedEdit ? (
                      <Button text padding="no" onClick={() => handleOpenTravelPathBuildOrEdit()} type="button">
                        <ActionButtonTooltip
                          tooltipAsString
                          margin="no no no xxSmall"
                          tooltip={translate('routes.travelPath.editPath')}
                          icon="edit"
                        />
                      </Button>
                    ) : (
                      <Button text padding="no" onClick={unlockTravelPathActionsForUser} type="button">
                        <ActionButtonTooltip
                          icon="lock"
                          tooltipAsString
                          tooltip={`${translate('routes.travelPath.youAlreadyStartedBuildSession')}`}
                        />
                      </Button>
                    )}
                    {routeTemplateId ? (
                      !isLockedBuilder ? (
                        <Button
                          text
                          padding="no"
                          onClick={() => handleOpenTravelPathBuildOrEdit(true)}
                          margin="no no no xSmall"
                          type="button"
                        >
                          <ActionButtonTooltip
                            tooltipAsString
                            tooltip={translate('routes.travelPath.travelPathBuilder')}
                            icon="hammer"
                          />
                        </Button>
                      ) : (
                        <Button
                          text
                          padding="no"
                          margin="no no no xSmall"
                          onClick={unlockTravelPathActionsForUser}
                          type="button"
                        >
                          <ActionButtonTooltip
                            icon="lock"
                            tooltipAsString
                            tooltip={`${translate('routes.travelPath.youAlreadyStartedEditSession')}`}
                          />
                        </Button>
                      )
                    ) : null}
                  </>
                )}
              </>
            )}
          </Box>

          <Box>
            {!isTriggerTravelPathButtonUnavailable && (
              <Button text padding="no" onClick={triggerTravelPathRegenerateAction} margin="no" type="button">
                <ActionButtonTooltip
                  icon={!travelPathStatusDetails?.isCreated ? 'plus-simple' : 'refresh'}
                  tooltipAsString
                  size="sMedium"
                  tooltip={
                    !travelPathStatusDetails?.isCreated
                      ? translate('routes.travelPath.createPath')
                      : translate('routes.travelPath.regenerate')
                  }
                />
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      {travelPathError && !travelPathStatusDetails?.inProgress && (
        <Text block size="xSmall" margin="no no no medium" color="warning">
          <em>{travelPathError}</em>
        </Text>
      )}

      {showTravelPathEditorModal && (
        <TravelPathEditorModalResolver
          routeId={routeId}
          routeTemplateId={routeTemplateId}
          isAlternativeFleet={isAlternativeFleet}
          isSnowPlow={isSnowPlow}
          closeModal={closeTravelPathEditorModal}
          name={routeName}
          date={date}
        />
      )}

      {showTravelPathBuilderModal && (
        <TravelPathBuilderModalResolver
          routeTemplateId={routeTemplateId}
          isAlternativeFleet={isAlternativeFleet}
          isSnowPlow={isSnowPlow}
          closeModal={closeTravelPathBuilderModal}
          name={routeName}
          date={date}
        />
      )}
    </>
  );
};

export default TravelPathField;
