import { closeOpenPolygon, openClosedPolygon } from '../../common/services/polygon';

export const transformSaveFacility = {
  facilityDaysOfOperation: {
    isOpenMonday: false,
    isOpenTuesday: false,
    isOpenWednesday: false,
    isOpenThursday: false,
    isOpenFriday: false,
    isOpenSaturday: false,
    isOpenSunday: false,
    weekHoursBegin: null,
    weekHoursEnd: null,
    weekendHoursBegin: null,
    weekendHoursEnd: null,
  },
  isActive: false,
  isWeightTicketEnabled: false,
};

export const transformGeoJsonSaveFacility = (geoFenceJson: any) =>
  JSON.stringify({
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [geoFenceJson],
    },
  });

export const transformGeoJsonSave = (geoFenceJson: any) => {
  const rawCoordinates = geoFenceJson.slice();
  const coordinates = closeOpenPolygon(rawCoordinates);

  return JSON.stringify({
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [coordinates],
    },
  });
};

export const transformLoadGeoJsonFacility = (geoFenceJson: any) => {
  const geoFenceObj = JSON.parse(geoFenceJson);
  const geoFenceCoord = geoFenceObj.geometry.coordinates[0];
  return geoFenceCoord;
};

export const transformLoadGeoJson = (geoFenceJson: any) => {
  const geoFenceObj = JSON.parse(geoFenceJson);
  const rawCoordinates = geoFenceObj.geometry.coordinates[0];
  const coordinates = openClosedPolygon(rawCoordinates);

  return coordinates;
};
