import { connect } from 'react-redux';
import { isAdminSelector } from '../ducks';
import { AppState } from '../../store';

const AdminGuard = ({ isAdmin, children }: { isAdmin: boolean; children: any }) => (isAdmin ? children : null);

const mapStateToProps = (state: AppState) => ({
  isAdmin: isAdminSelector(state.account.login),
});

export default connect(mapStateToProps)(AdminGuard);
