import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import EsriSyncModal from './EsriSyncModal';
import { loadEsriSyncSettings } from '../../ducks';

interface Props {
  closeModal: () => void;
  vendorId?: number;
}

export default function EsriSyncModalResolver(props: Props) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    return loadEsriSyncSettings(vendorId)(dispatch);
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={EsriSyncModal}
      successProps={{ ...props, vendorId }}
    />
  );
}
