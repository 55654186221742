import { useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { Button, ButtonSet, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { Checkbox } from '../../../core/components';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { ESRI_ROUTE_LAYER } from 'src/vendors/constants/esriConfig';
import EsriConfigValidationModalResolver from '../modals/EsriConfigValidationModalResolver';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { Input, TypedField } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import translate from 'src/core/services/translate';

function EsriRouteConfigForm({ form, handleSubmit }: InjectedFormProps) {
  const vendorId = useSelector(currentVendorId);
  const [isEsriValidationModalOpen, toggleIsEsriValidationModalOpen] = useState(false);
  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="no">
        <Grid multiLine>
          <GridColumn size="12/12" margin="small no">
            <TypedField
              name="featureServiceName"
              component={Input}
              validate={[isRequired]}
              props={{
                label: translate('vendors.esriSync.featureLayerName'),
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="12/12" margin="small no">
            <TypedField
              name="featureServiceUrl"
              component={Input}
              validate={[isRequired]}
              props={{
                label: translate('vendors.esriSync.featureLayerUrl'),
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="4/12" padding="medium xSmall">
            <TypedField
              name="canCreate"
              component={Checkbox}
              props={{
                label: translate('vendors.esriSync.canCreate'),
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="4/12" padding="medium xSmall">
            <TypedField
              name="canUpdate"
              component={Checkbox}
              props={{
                label: translate('vendors.esriSync.canUpdate'),
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="4/12" padding="medium xSmall">
            <TypedField
              name="isEnabled"
              component={Checkbox}
              props={{
                label: translate('vendors.esriSync.isEnabled'),
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>

      <Grid centered>
        <ButtonSet margin="medium">
          <Button type="button" color="primary" line onClick={() => toggleIsEsriValidationModalOpen(true)}>
            {translate('vendors.esriSync.validate')}
          </Button>
          <Button type="submit" color="primary" margin="no small">
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </Grid>
      {isEsriValidationModalOpen && (
        <EsriConfigValidationModalResolver
          vendorId={vendorId}
          configLayer={ESRI_ROUTE_LAYER}
          closeModal={() => toggleIsEsriValidationModalOpen(false)}
        ></EsriConfigValidationModalResolver>
      )}
    </form>
  );
}
export default reduxForm({ form: 'esriRouteConfigForm', onSubmitFail: focusFirstInvalidField })(EsriRouteConfigForm);
