import { http } from '../../core/services/http';
import { DirtyCollectionPoint } from '../interfaces/DirtyCollectionPoint';
import { DirtyPickupLocation } from '../interfaces/DirtyPickupLocation';

export const updatePickupLocations = (
  vendorId: number,
  modifiedServiceContracts: DirtyPickupLocation[],
  modifiedCollectionWaypoints: DirtyCollectionPoint[],
) => {
  return http.post(`vendors/${vendorId}/services/updatePickupLocations`, {
    modifiedServiceContracts,
    modifiedCollectionWaypoints,
  });
};
