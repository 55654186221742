import { http } from 'src/core/services/http';
import { IServiceConfiguration, IServiceRequestErrors, IServiceCityConfiguration } from '../interfaces/CityWorksConfiguration';

export const loadCityWorksErrors = (vendorId: number) =>
    http
        .get<IServiceRequestErrors>(`/vendors/${vendorId}/cityworks/servicerequest/errors`)
        .then(response => response.data);

export const loadCityWorksConfiguration = (vendorId: number) =>
    http
        .get<IServiceCityConfiguration>(`/vendors/${vendorId}/cityworks/serviceconfigurations`)
        .then(response => response.data);

export const loadCityWorksServiceConfiguration = (vendorId: number) =>
    http
        .get<IServiceConfiguration>(`/vendors/${vendorId}/cityworks/configuration`)
        .then(response => response.data);


export const loadCityWorksproblemtypes = (vendorId: number) =>
    http
        .get<any>(`/vendors/${vendorId}/cityworks/problemtypes`)
        .then(response => response.data);


export const deleteServiceRequestErrors = (vendorId: number, data: number[]) => {
    return http
        .delete<any>(`/vendors/${vendorId}/cityworks/servicerequest/errors`, { data: { EventIds: data } })
        .then(response => response.data);
}

