import React, { MouseEvent, PureComponent } from 'react';

import { camelCase, map, filter, size, reduce, get, join, uniq } from 'lodash-es';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { observer } from 'mobx-react';
import humps from 'humps';

import { ActionButtonTooltip } from '../../core/components';
import { CustomerProximitySearchModalResolver } from '../../customers/components/modals';
import { getItem } from '../../core/services/persistentStorage';
import { INSIGHT_ICONS, WASTE_AUDIT, PICKUP_STATUSES, SCHEDULED, GOOGLE as google } from '../../common/constants';
import { PICKUP_ISSUE_TYPES, ROUTE_PICKUP_TYPES } from '../../routes/constants';
import { time } from '../../utils/services/formatter';
import {
  DetailsListActionButton,
  DetailsListActionIcon,
  Link,
  MapInfoImage,
  MapInfoImageWrapper,
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowDetailsContainer,
  MapInfoWindowIcon,
  MapInfoWindowTitle,
  MapInfoWindowTitleContainer,
  TableActionButton,
} from '../../core/components/styled';
import IssuesReportedModal from '../../dashboard/components/modals/IssuesReportedModal';
import StrobeImagesModalResolver from '../../routes/components/modals/StrobeImagesModalResolver';
import translate from '../../core/services/translate';
import { AppState } from '../../store';
import { ContainerInsights } from 'src/routes/components/mapWithTimeline/Interfaces';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';
import { RouteLocationImagesModalResolver } from 'src/routes/components/modals';

const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);

interface Props {
  asMapBoxMarker?: boolean;
  clusterer?: any;
  containerInsight: ContainerInsights;
  isInfoWindowOpen?: boolean;
  isYRoute?: boolean;
  selectedSearchedVehicleId?: any;
  strobeImageUrl?: string;
  toggleInfoWindow?: (insightId: string) => void;
  noRedraw?: boolean;
}

interface State {
  isCustomerProximitySearchModalOpen: boolean;
  isIssueImageModalOpen: boolean;
  isRouteLocationIssuesModalOpen: boolean;
  isStrobeImageModalOpen: boolean;
  selectedLocation: number;
}

class ContainerInsightMarker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      containerInsight: { insightId, routeLocationId, isYRoute },
      isInfoWindowOpen,
      selectedSearchedVehicleId,
      toggleInfoWindow,
    } = this.props;

    if (
      selectedSearchedVehicleId != null &&
      (isYRoute
        ? selectedSearchedVehicleId.routeLocationId === routeLocationId
        : selectedSearchedVehicleId.binInsightId === insightId) &&
      !isInfoWindowOpen
    ) {
      toggleInfoWindow && toggleInfoWindow(`containerInsights.${insightId}`);
    }

    this.state = {
      isCustomerProximitySearchModalOpen: false,
      isIssueImageModalOpen: false,
      isRouteLocationIssuesModalOpen: false,
      isStrobeImageModalOpen: false,
      selectedLocation: 0,
    };
  }

  toggleInfoWindow = () => {
    const {
      toggleInfoWindow,
      containerInsight: { insightId },
    } = this.props;

    toggleInfoWindow && toggleInfoWindow(`containerInsights.${insightId}`);
  };

  openIssueImageModal = () => {
    this.setState({ isIssueImageModalOpen: true });
  };

  openStrobeImageModal = () => {
    this.setState({ isStrobeImageModalOpen: true });
  };

  closeIssueImageModal = () => {
    this.setState({ isIssueImageModalOpen: false });
  };

  closeStrobeImageModal = () => {
    this.setState({ isStrobeImageModalOpen: false });
  };

  openRouteLocationIssuesModal = () => {
    this.setState({ isRouteLocationIssuesModalOpen: true });
  };

  closeRouteLocationIssuesModal = () => {
    this.setState({ isRouteLocationIssuesModalOpen: false });
  };

  openProximitySearchModal = (event: any, locationId: number) => {
    event.stopPropagation();
    this.setState({
      isCustomerProximitySearchModalOpen: true,
      selectedLocation: locationId,
    });
  };

  closeCustomerProximitySearchModal = () => {
    this.setState({ isCustomerProximitySearchModalOpen: false, selectedLocation: 0 });
  };

  getInfoWindowContent = (markerIconType: any, markerIcon: any) => {
    const {
      isCustomerProximitySearchModalOpen,
      isIssueImageModalOpen,
      isRouteLocationIssuesModalOpen,
      isStrobeImageModalOpen,
      selectedLocation,
    } = this.state;
    const { containerInsight, strobeImageUrl } = this.props;

    const images = map(
      filter(containerInsight.insightSubTypes, insightSubType => !!insightSubType.imageUrl),
      insightSubType => ({
        customerName: containerInsight.customerName,
        imageUrl: insightSubType.imageUrl,
        imageBlobId: insightSubType.imageBlobId,
        issueName: translate(`dashboard.${camelCase(insightSubType.name)}`),
        locationName: containerInsight.address,
        timeStamp:
          containerInsight.vehicleType === WASTE_AUDIT
            ? containerInsight.reportDateTime
            : get(insightSubType, 'pickupExceptionDetails.timeStamp'),
      }),
    );

    const FILTER_KEY = 'dashboardFilters';
    const storageKey = getItem(FILTER_KEY);

    const selectedDate = storageKey ? JSON.parse(storageKey).date : undefined;

    const translationKeys = {
      insightTypeName: markerIconType,
      insightMessageLabel: humps.camelize(containerInsight.insightMessageLabel),
    };

    return (
      <MapInfoWindow vertical>
        <MapInfoWindowTitleContainer>
          <MapInfoWindowIcon size="small" src={INSIGHT_ICONS[markerIconType]} />
          <MapInfoWindowTitle>{translate(`dashboard.${markerIconType}`)}</MapInfoWindowTitle>

          {size(containerInsight.insightSubTypes) > 1 && !(containerInsight.vehicleType === WASTE_AUDIT) && (
            <DetailsListActionButton onClick={this.openRouteLocationIssuesModal} margin="no no xxSmall xSmall">
              <DetailsListActionIcon icon="issue" /> {size(containerInsight.insightSubTypes) - 1}
            </DetailsListActionButton>
          )}
          {!!size(images) && containerInsight.vehicleType === WASTE_AUDIT && (
            <DetailsListActionButton onClick={this.openIssueImageModal} margin="no xSmall xxSmall xSmall">
              <DetailsListActionIcon icon="image" /> {size(images)}
            </DetailsListActionButton>
          )}
        </MapInfoWindowTitleContainer>

        {containerInsight.vehicleType === WASTE_AUDIT &&
          map(
            containerInsight.insightSubTypes,
            (insightSubType, index: number) =>
              index > 0 && (
                <MapInfoWindowTitleContainer key={index}>
                  <MapInfoWindowIcon size="small" src={INSIGHT_ICONS[humps.camelize(insightSubType.name)]} />
                  <MapInfoWindowTitle>{translate(`dashboard.${camelCase(insightSubType.name)}`)}</MapInfoWindowTitle>
                </MapInfoWindowTitleContainer>
              ),
          )}
        <MapInfoWindowDetailsContainer>
          <MapInfoWindowDetails>
            {!!containerInsight.vehicle.name && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.truck')}:</MapInfoWindowDetailLabel>
                {containerInsight.vehicle.name}
              </MapInfoWindowDetail>
            )}

            {!!containerInsight.route.name && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('routes.route')}:</MapInfoWindowDetailLabel>
                {containerInsight.route.id || containerInsight.isYRoute ? (
                  <Link
                    to={
                      containerInsight.isYRoute
                        ? `/routes/y-daily-routes/${containerInsight.routeDetailId}`
                        : `/routes/route-tracker/${containerInsight.route.id}`
                    }
                  >
                    {containerInsight.route.name}
                  </Link>
                ) : (
                  containerInsight.route.name
                )}
              </MapInfoWindowDetail>
            )}

            {!!containerInsight.reportDateTime && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.timestamp')}:</MapInfoWindowDetailLabel>
                {time(containerInsight.reportDateTime)}
              </MapInfoWindowDetail>
            )}

            {containerInsight.vehicleType !== WASTE_AUDIT && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('routes.pickupType')}:</MapInfoWindowDetailLabel>
                {ROUTE_PICKUP_TYPES[containerInsight.pickupType]
                  ? ROUTE_PICKUP_TYPES[containerInsight.pickupType].name
                  : ''}
              </MapInfoWindowDetail>
            )}

            {!!containerInsight.insightMessageValue && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>
                  {translate(`dashboard.${translationKeys.insightMessageLabel}`)}:
                </MapInfoWindowDetailLabel>
                {containerInsight.insightMessageValue}
              </MapInfoWindowDetail>
            )}
            {!!containerInsight.customerName && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.customer')}:</MapInfoWindowDetailLabel>
                {containerInsight.customerName}

                <TableActionButton
                  margin="no no no xSmall"
                  color="primary"
                  onClick={(event: MouseEvent) =>
                    this.openProximitySearchModal(
                      event,
                      containerInsight.isYRoute ? containerInsight.insightId : containerInsight.routeLocationId,
                    )
                  }
                >
                  <ActionButtonTooltip
                    icon="searchVehicle"
                    size="sMedium"
                    sourceIsInfoWindow
                    tooltip="customerProximitySearch"
                  />
                </TableActionButton>
              </MapInfoWindowDetail>
            )}

            {!!containerInsight.address && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('common.address')}:</MapInfoWindowDetailLabel>
                {containerInsight.address}
              </MapInfoWindowDetail>
            )}
            {!(containerInsight.vehicleType === WASTE_AUDIT) && size(containerInsight.insightSubTypes) > 1 && (
              <MapInfoWindowDetail>
                <MapInfoWindowDetailLabel>{translate('dashboard.exceptionType')}:</MapInfoWindowDetailLabel>
                {reduce(
                  containerInsight.insightSubTypes,
                  (exceptions: any[], insightSubType, key: number) => {
                    if (key > 0) {
                      const insightSubTypeName = PICKUP_ISSUE_TYPES[insightSubType.id].name;
                      if (insightSubTypeName) {
                        exceptions.push(insightSubTypeName);
                      }
                    }
                    return exceptions;
                  },
                  [],
                ).join(', ')}
              </MapInfoWindowDetail>
            )}
            {containerInsight.vehicleType === WASTE_AUDIT &&
              size(containerInsight.insightSubTypes) > 1 &&
              map(containerInsight.insightSubTypes, (insightSubType, index) => {
                const messageLabel =
                  insightSubType.insightSubMessageLabel && humps.camelize(insightSubType.insightSubMessageLabel);
                const insightSubMessageValues = insightSubType.insightSubMessageValues
                  ? uniq(insightSubType.insightSubMessageValues)
                  : [];
                const messageValue = join(insightSubMessageValues, ', ');
                return (
                  !!messageLabel && (
                    <MapInfoWindowDetail key={index}>
                      <MapInfoWindowDetailLabel>{translate(`dashboard.${messageLabel}`)}:</MapInfoWindowDetailLabel>
                      {messageValue}
                    </MapInfoWindowDetail>
                  )
                );
              })}
          </MapInfoWindowDetails>
          {!!size(images) && containerInsight.vehicleType === WASTE_AUDIT && (
            <MapInfoImageWrapper onClick={this.openIssueImageModal}>
              <MapInfoImage src={images[0].imageUrl} onClick={this.openIssueImageModal} />
            </MapInfoImageWrapper>
          )}
          {!!strobeImageUrl &&
            containerInsight.vehicleType !== WASTE_AUDIT &&
            containerInsight.insightSubTypes[0].name !== PICKUP_STATUSES[SCHEDULED].name && (
              <MapInfoImageWrapper onClick={this.openStrobeImageModal}>
                <MapInfoImage src={strobeImageUrl} onClick={this.openStrobeImageModal} />
              </MapInfoImageWrapper>
            )}
        </MapInfoWindowDetailsContainer>
        {!!isIssueImageModalOpen && (
          <RouteLocationImagesModalResolver
            routeId={containerInsight.route.id}
            routeLocationId={containerInsight.routeLocationId}
            closeModal={this.closeIssueImageModal}
          />
        )}
        {!!isStrobeImageModalOpen && (
          <StrobeImagesModalResolver
            vehicleId={containerInsight.vehicle.id}
            vehicleName={containerInsight.vehicle.name}
            timeStamp={containerInsight.reportDateTime}
            closeModal={this.closeStrobeImageModal}
          />
        )}
        {!!isRouteLocationIssuesModalOpen && (
          <IssuesReportedModal issues={containerInsight} closeModal={this.closeRouteLocationIssuesModal} />
        )}

        {!!isCustomerProximitySearchModalOpen && (
          <CustomerProximitySearchModalResolver
            onCancel={this.closeCustomerProximitySearchModal}
            locationId={selectedLocation}
            defaultDate={selectedDate}
          />
        )}
      </MapInfoWindow>
    );
  };

  render() {
    const { containerInsight, isInfoWindowOpen, clusterer, asMapBoxMarker } = this.props;

    const markerIconType = humps.camelize(containerInsight.insightSubTypes[0].name);

    const markerPosition = {
      lat: containerInsight.latitude,
      lng: containerInsight.longitude,
    };

    const markerIcon = {
      url: INSIGHT_ICONS[
        containerInsight.displayAsScheduled && containerInsight.displayAsScheduled.get() ? 'scheduled' : markerIconType
      ],
      size: new google.maps.Size(15, 15),
      scaledSize: new google.maps.Size(15, 15),
    };

    const pixelOffset = new google.maps.Size(0, -20);

    return asMapBoxMarker ? (
      this.getInfoWindowContent(markerIconType, markerIcon)
    ) : (
      <Marker
        key={containerInsight.insightId}
        icon={markerIcon}
        position={markerPosition}
        onClick={this.toggleInfoWindow}
        clusterer={clusterer}
        title={`${translate('routes.stopNumber')}: ${
          containerInsight.insightMessageValue ||
          (containerInsight.isYRoute ? containerInsight.insightId : containerInsight.routeLocationId)
        }`}
      >
        {isInfoWindowOpen && markerPosition && (
          <InfoWindow onCloseClick={this.toggleInfoWindow} position={markerPosition} options={{ pixelOffset }}>
            {this.getInfoWindowContent(markerIconType, markerIcon)}
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  // TODO refactor this, we need to decouple this from redux-form selector
  selectedSearchedVehicleId: formSelector(state, 'searchedVehicleId'),
});

export default connect(mapStateToProps)(observer(ContainerInsightMarker));
