import React, { useState, useEffect, useRef } from 'react';
import humps from 'humps';

import { ContainerImportModalTableRow } from './ContainerImportModalTableRow';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { Modal, Table } from 'src/core/components';
import { Button } from 'src/core/components/styled';
import { EXCEL_MIME_TYPES } from 'src/core/constants';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import {
  downloadSampleContainerTemplate,
  getContainerUploadedFilesStatus,
  uploadContainerFile,
} from 'src/fleet/services/containerUploaderModal';
import { ContainerButton } from '../styled/Containers';
import RecordsUploaderModal from 'src/common/components/RecordsUploaderModal';
import translate from 'src/core/services/translate';

interface Props {
  vendorId: number;
  onClose: (hasSuccessfulImports: boolean) => void;
}

const tableCells = [
  {
    name: 'fileName',
    label: translate('containers.containerImport.fileName'),
    width: '30%',
  },
  {
    name: 'status',
    label: translate('common.status'),
    width: '13%',
  },
  {
    name: 'numberOfRecords',
    label: translate('containers.containerImport.numberOfRecords'),
    width: '10%',
  },
  {
    name: 'numberOfSuccessfulRecords',
    label: translate('containers.containerImport.numberOfSuccessfulRecords'),
    width: '11%',
  },
  {
    name: 'numberOfFailedRecords',
    label: translate('containers.containerImport.numberOfFailedRecords'),
    width: '8%',
  },
  {
    name: 'errorFileUrl',
    label: translate('containers.containerImport.errorFile'),
    width: '12%',
  },
  {
    name: 'date',
    label: `${translate('common.date')} & ${translate('customers.time')}`,
    width: '16%',
  },
];

const ContainerImportModal: React.SFC<Props> = props => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const refreshTimeoutId = useRef<number>(0);

  const refreshData = useRef(() => {
    refreshTimeoutId.current = window.setTimeout(() => {
      setIsRefreshing(true);
      getContainerUploadedFilesStatus(props.vendorId)
        .then(result => {
          setUploadedFiles(result.data);
          setIsRefreshing(false);
          refreshData.current();
        })
        .catch(() => setIsRefreshing(false));
    }, 15000);
  });

  useEffect(() => {
    getContainerUploadedFilesStatus(props.vendorId).then(result => {
      setUploadedFiles(result.data || []);
      setIsDataLoaded(true);
      if (result.data && result.data.length) {
        refreshData.current();
      }
    });

    return () => window.clearTimeout(refreshTimeoutId.current);
  }, [props.vendorId, refreshData, uploadedFiles.length]);

  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const hasAnySuccessfulUploads = useRef(false);

  const uploadFile = (file: File) => {
    setIsUploading(true);
    return uploadContainerFile(file, props.vendorId)
      .then(result => {
        const { data: uploadResult } = result;
        hasAnySuccessfulUploads.current = true;
        setUploadedFiles(uploadResult);
        setIsUploading(false);
        createSuccessNotification(translate('containers.containerImport.successMessage'));
        return result;
      })
      .catch(e => {
        const errorMessageCode =
          e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

        createErrorNotification(
          errorMessageCode
            ? translate(`customers.alertMessages.${errorMessageCode}`)
            : translate('containers.containerImport.errorMessage'),
        );
        setIsUploading(false);
        throw e;
      });
  };

  const onFileInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target && e.target.files) {
      uploadFile(e.target.files[0]);
      e.target.value = '';
    }
  };

  const getUploaderModal = () =>
    !uploadedFiles.length ? (
      <RecordsUploaderModal
        {...props}
        downloadSample={downloadSampleContainerTemplate}
        parentTranslationsPath="containers.containerImport"
        uploadFile={uploadFile}
        willUnmountAfterUpload
        onClose={() => props.onClose(hasAnySuccessfulUploads.current)}
      />
    ) : (
      <Modal
        size="xLarge"
        title={translate('containers.containerImport.buttonTitle')}
        onClose={() => props.onClose(hasAnySuccessfulUploads.current)}
      >
        {isUploading && <PageLoadingOverlay />}

        <Table cells={tableCells} rows={uploadedFiles} rowComponent={ContainerImportModalTableRow} />
        <ContainerButton>
          {isRefreshing && <Loader />}
          <Button
            color="primary"
            margin="small no no no"
            onClick={() => (fileUploaderRef.current as HTMLInputElement).click()}
            type="button"
          >
            {translate('common.chooseFile')}
          </Button>
          <input
            accept={EXCEL_MIME_TYPES}
            id="fileSelect"
            onChange={onFileInputChanged}
            ref={fileUploaderRef}
            style={{ display: 'none' }}
            type="file"
          />
          <Button color="primary" line onClick={downloadSampleContainerTemplate}>
            {translate('containers.containerImport.downloadSample')}
          </Button>
        </ContainerButton>
      </Modal>
    );

  return isDataLoaded ? getUploaderModal() : <PageLoadingOverlay />;
};

export default ContainerImportModal;
