import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';

import { Panel, PanelSection, Label } from '../../../core/components/styled';
import { getQueryParams } from '../../../utils/services/queryParams';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageTitleContainer,
  PageTitle,
  PageSubtitle,
} from '../../../common/components/styled';
import { RouteImportDetailsForm } from '../forms';
import { vendorByIdSelector } from '../../../vendors/ducks';
import {
  saveRouteImportDetails,
  resetRouteImportDetails,
  vendorIdSelector,
  routeImportIdSelector,
  routeImportStatusTypeIdSelector,
} from '../../ducks';
import { IMPORT_STATUSES } from '../../constants';
import { createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';

interface Props extends RouteComponentProps {
  isLoading: boolean;
  isSaving: boolean;
  vendor: any;
  routeImportId?: number;
  routeImportStatusTypeId?: number;
  saveRouteImportDetails: DuckFunction<typeof saveRouteImportDetails>;
  resetRouteImportDetails: DuckAction<typeof resetRouteImportDetails>;
  push: any;
}

class RouteImportDetailsPage extends Component<Props> {
  componentWillUnmount() {
    this.props.resetRouteImportDetails();
  }

  onSubmit = async (formData: any) => {
    const {
      location: { search },
      routeImportId,
      saveRouteImportDetails,
      push,
    } = this.props;
    const { importDetailId } = getQueryParams(search);

    await saveRouteImportDetails(importDetailId, formData);
    createSuccessNotification(`${translate('routeImport.alertMessages.routeImportDetailsSaved')}`);
    push(`/route-import/statuses?routeImportId=${routeImportId}`);
  };

  render() {
    const { routeImportId, routeImportStatusTypeId, vendor, isSaving, isLoading } = this.props;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <Label>{routeImportStatusTypeId ? IMPORT_STATUSES[routeImportStatusTypeId].name : null}</Label>
            <PageTitleContainer>
              <PageBackButton to={`/route-import/statuses?routeImportId=${routeImportId}`}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle>{translate('routeImport.routeImportDetails')}</PageTitle>
            </PageTitleContainer>

            <PageSubtitle>{vendor.name}</PageSubtitle>
          </PageDetails>
          <PageActions />
        </PageHeader>

        <Panel>
          <PanelSection padding="large" isLoading={isSaving || isLoading}>
            <RouteImportDetailsForm onSubmit={this.onSubmit} />
          </PanelSection>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.routeImport.routeImportDetails.isLoading,
  isSaving: state.routeImport.routeImportDetails.isSaving,
  routeImportId: routeImportIdSelector(state.routeImport.routeImportDetails),
  routeImportStatusTypeId: routeImportStatusTypeIdSelector(state.routeImport.routeImportDetails),
  vendor: vendorByIdSelector(state.vendors.vendors, (vendorIdSelector as any)(state.routeImport.routeImportDetails)),
});

const mapDispatchToProps = { saveRouteImportDetails, resetRouteImportDetails, push };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteImportDetailsPage));
