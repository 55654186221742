import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadFleetMaintenance as doLoadFleetMaintenance,
  saveFleetMaintenance as doSaveFleetMaintenance,
} from '../services/fleetMaintenance';
import { FleetMaintenance } from '../interfaces/FleetMaintenance';

// Actions
const START_LOAD = 'vendors/fleetMaintenance/START_LOAD';
const COMPLETE_LOAD = 'vendors/fleetMaintenance/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/fleetMaintenance/FAIL_LOAD';
const START_SAVE = 'vendors/fleetMaintenance/START_SAVE';
const COMPLETE_SAVE = 'vendors/fleetMaintenance/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/fleetMaintenance/FAIL_SAVE';
const RESET = 'vendors/fleetMaintenance/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  fleetMaintenance: {} as FleetMaintenance,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          fleetMaintenance: action.fleetMaintenance,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (fleetMaintenance: FleetMaintenance) => ({
  type: COMPLETE_LOAD,
  fleetMaintenance,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadFleetMaintenance = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadFleetMaintenancePromise = doLoadFleetMaintenance(vendorId);
  loadFleetMaintenancePromise
    .then((fleetMaintenance: FleetMaintenance) => dispatch(completeLoad(fleetMaintenance)))
    .catch(() => dispatch(failLoad()));

  return loadFleetMaintenancePromise;
};

export const saveFleetMaintenance = (vendorId: number, fleetMaintenance: FleetMaintenance) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveFleetMaintenancePromise = doSaveFleetMaintenance(vendorId, fleetMaintenance);
  saveFleetMaintenancePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveFleetMaintenancePromise;
};

export const resetFleetMaintenance = () => ({
  type: RESET,
});
