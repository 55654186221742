import { identity, reduce } from 'lodash-es';
import { createSelector } from 'reselect';
import { AppState } from '../../store';
import { FeaturesFormValues, FeaturesState } from '../interfaces/Features';

const getAccountSettingsFeaturesFormInitialValues = ({ features }: FeaturesState) => {
  return reduce(features, (initialValues, { code, enabled }) => ({ ...initialValues, [code]: enabled }), {});
};

const accountSettingsFeaturesFormInitialValuesSelector = createSelector<
  FeaturesState,
  FeaturesFormValues,
  FeaturesFormValues
>(getAccountSettingsFeaturesFormInitialValues, identity);

const accountSettingsFeaturesFormInitialValues = (state: AppState) =>
  accountSettingsFeaturesFormInitialValuesSelector(state.vendors.features);

export default accountSettingsFeaturesFormInitialValues;
