import styled from 'styled-components';

import { Devices } from '../../../core/styles';

export const SocialMediaLinksWrapper = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  @media screen and (max-width: 1640px) {
    padding: 0 30px;
  }
  @media ${Devices.laptop} {
    padding: 0 30px;
  }
  @media ${Devices.tablet} {
    width: 100%;
  }
`;

export const SocialMediaLink = styled.a`
  display: inline-block;
  margin: 20px 20px 0 0;
  @media ${Devices.tablet} {
    margin: 10px 20px 0 0;
  }
`;

export const SocialMediaIcon = styled.img`
  color: inherit;
`;
