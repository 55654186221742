import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { ESRI_CREDENTIALS_LAYER, ESRI_CUSTOMER_LAYER, ESRI_ROUTE_LAYER } from 'src/vendors/constants/esriConfig';
import { Grid, GridColumn, ModalSection, Text } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
  configLayer: string;
}

export default function EsriConfigValidationModal({ closeModal, configLayer }: Props) {
  const { esriConfigValidation } = useSelector((state: AppState) => state.vendors.esriIntegrationConfig);
  const esriSyncStatus = esriConfigValidation?.status;
  const color = esriSyncStatus === 'Success' ? 'success' : esriSyncStatus === 'Error' ? 'alert' : 'grayDarker';
  const modalTitle =
    configLayer === ESRI_CREDENTIALS_LAYER
      ? translate('vendors.esriSync.esriCredentialsValidation')
      : configLayer === ESRI_CUSTOMER_LAYER
      ? translate('vendors.esriSync.esriCustomerValidation')
      : configLayer === ESRI_ROUTE_LAYER
      ? translate('vendors.esriSync.esriRouteValidation')
      : translate('vendors.esriSync.esriServiceValidation');

  return (
    <Modal size="small" title={modalTitle} onClose={closeModal}>
      <ModalSection margin="medium no no">
        <Text align="center" color={color} block weight="medium" size="Large">
          {translate('vendors.esriSync.esriSyncStatus')} {esriConfigValidation && esriConfigValidation.status}
        </Text>
        <Grid multiLine>
          <GridColumn>
            <Text color="success" block weight="medium" size="small">
              {translate('vendors.esriSync.successMessages')}
            </Text>
            {esriConfigValidation.successMessages.map((message: any) => (
              <Text padding="no no no medium" color="success" block weight="light" size="small">
                {message}
              </Text>
            ))}
            <Text color="grayDarker" block weight="medium" size="small">
              {translate('vendors.esriSync.warningMessages')}
            </Text>
            {esriConfigValidation.warningMessages.map((message: any) => (
              <Text padding="no no no medium" color="grayDarker" block weight="light" size="small">
                {message}
              </Text>
            ))}
            <Text color="alert" block weight="medium" size="small">
              {translate('vendors.esriSync.errorMessages')}
            </Text>
            {esriConfigValidation.errorMessages.map((message: any) => (
              <Text padding="no no no medium" color="alert" block weight="light" size="small">
                {message}
              </Text>
            ))}
          </GridColumn>
        </Grid>
      </ModalSection>
    </Modal>
  );
}
