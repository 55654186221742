import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
import { map } from 'lodash-es';

import { Button, Grid, GridColumn, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { DatePicker, Input, Dropdown } from '../../../core/components';
import { ROUTE } from '../../../fleet/constants';
import { VehicleDropdown, VehicleTypeForVendorDropdown, DriverDropdown } from '../../../fleet/components';
import { INSIGHT_HISTORY_CATEGORY_OPTIONS } from '../../constants';
import translate from '../../../core/services/translate';
import insightHistoryFormInitialValuesSelector from '../../services/insightHistoryFormInitialValuesSelector';
import { AppState } from '../../../store';
import { isDateValidValidator } from 'src/utils/services/validator';

const insightHistoryOptions = map(INSIGHT_HISTORY_CATEGORY_OPTIONS, ({ id, name }) => ({ label: name, value: id }));

interface ComponentProps extends RouteComponentProps {}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  vehicleTechnicalName: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class InsightHistoryForm extends PureComponent<Props> {
  render() {
    const { vehicleTechnicalName, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <PanelSectionGroup>
          <PanelSection padding="small xSmall">
            <Grid multiLine>
              <GridColumn size="3/12">
                <Field
                  name="date"
                  component={DatePicker}
                  placeholder={translate('common.date')}
                  isClearable
                  margin="no"
                  validate={[isDateValidValidator]}
                />
              </GridColumn>

              <GridColumn size="3/12">
                <Field
                  name="vehicleType"
                  component={VehicleTypeForVendorDropdown}
                  vehicleRoleTypeId={ROUTE}
                  dropdownProps={{ isClearable: true }}
                  withPlaceholder
                  withTechnicalName
                />
              </GridColumn>

              <GridColumn size="3/12">
                <Field
                  name="vehicleName"
                  vehicleTechnicalName={vehicleTechnicalName}
                  component={VehicleDropdown}
                  dropdownProps={{ isClearable: true }}
                  withPlaceholder
                  withVehicleName
                />
              </GridColumn>

              <GridColumn size="3/12">
                <Field name="routeName" component={Input} placeholder={translate('routes.routeName')} />
              </GridColumn>
            </Grid>
          </PanelSection>

          <PanelSection padding="small xSmall">
            <Grid>
              <GridColumn size="3/12">
                <Field
                  name="driverName"
                  component={DriverDropdown}
                  dropdownProps={{ isClearable: true }}
                  withPlaceholder
                  withDriverName
                />
              </GridColumn>
              <GridColumn size="3/12">
                <Field
                  name="insightCategory"
                  component={Dropdown}
                  placeholder={translate('insights.insightCategory')}
                  options={insightHistoryOptions}
                  isClearable
                  margin="no"
                />
              </GridColumn>

              <GridColumn size="6/12" align="right">
                <Button color="primary">{translate('common.search')}</Button>
              </GridColumn>
            </Grid>
          </PanelSection>
        </PanelSectionGroup>
      </form>
    );
  }
}

const formSelector = formValueSelector('insightHistory');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const vehicleTechnicalName = formSelector(state, 'vehicleType');

  return {
    vehicleTechnicalName,
    initialValues: insightHistoryFormInitialValuesSelector(ownProps.location.search),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'insightHistory',
      enableReinitialize: true,
    })(InsightHistoryForm),
  ),
);
