import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { vehicleSubTypesSelector } from '../ducks';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  vehicleSubTypes: TechnicalType[];
  vehicleTypeId: number;
}

class VehicleSubTypeDropdown extends PureComponent<Props> {
  onChange = (_: unknown, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      vehicleSubTypes,
      dropdownProps,
    } = this.props;

    const vehicleSubTypeOptions = vehicleSubTypes.map(vehicleSubType => ({
      label: vehicleSubType.name,
      value: vehicleSubType.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicleSubType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicleSubType') : undefined),
          options: vehicleSubTypeOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  vehicleSubTypes: vehicleSubTypesSelector(state.fleet.vehicleTypes, ownProps.vehicleTypeId) || [],
});

export default connect(mapStateToProps)(VehicleSubTypeDropdown);
