import { getMapBounds } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import store from 'src/store';
import { setDashboardViewport } from '../ducks/mapControls';
import { CityInsight } from '../interfaces/cityInsights';
import { RouteVehiclePosition, RouteStopItem } from '../interfaces/routesData';
import { VehiclePosition } from '../interfaces/vehiclePositions';
import { AlternativeFleetStreetSegment } from '../interfaces/alterativeFleetOps';
import { parseSegmentJSON } from './snowRoadConditions';

export const fitMapboxBounds = (selectedVehicleId?: number, width?: number, height?: number) => {
  const state = store.getState();
  const points: { latitude: number; longitude: number }[] = [];

  const vehiclePositions = (state.dashboard.vehiclesData.vehiclesList || []) as VehiclePosition[];
  const mapRouteStops = (state.dashboard.routesData.mapRouteStops || []) as RouteStopItem[];
  const routeVehiclePositions = (state.dashboard.routesData.routeVehiclePositions || []) as RouteVehiclePosition[];
  const snowSweeperSegments = (state.dashboard.alternativeFleetOps.filteredSegments ||
    []) as AlternativeFleetStreetSegment[];

  const selectedFeature = state.dashboard.mapControls.selectedFeature;

  if (selectedFeature && selectedFeature.namespace === 'vehiclePositions') {
    const vehiclePosition = vehiclePositions.find(({ id }) => id === selectedFeature.id);
    if (vehiclePosition) {
      const { latitude, longitude } = vehiclePosition;
      points.push({ latitude, longitude });
    }
  } else {
    if (selectedVehicleId) {
      const vehiclePosition = vehiclePositions.find(({ id }) => id === selectedVehicleId);
      if (vehiclePosition) {
        const { latitude, longitude } = vehiclePosition;
        points.push({ latitude, longitude });
      }
    } else {
      vehiclePositions.forEach(({ latitude, longitude }) => {
        points.push({ latitude, longitude });
      });
    }

    routeVehiclePositions.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    mapRouteStops.forEach(({ displayLatitude, displayLongitude }) => {
      points.push({ latitude: displayLatitude, longitude: displayLongitude });
    });

    snowSweeperSegments.forEach(segment => {
      const parsedSegment = parseSegmentJSON(segment.lineSegment);
      parsedSegment.forEach(el => {
        const latitude = el[1];
        const longitude = el[0];
        points.push({ latitude, longitude });
      });
    });
  }

  if (!!points.length) {
    const bounds = getMapBounds(points, {
      width,
      height,
      padding: 50,
      capZoom: 15,
    });

    store.dispatch(setDashboardViewport(bounds));
  }
};

export const useCheckIfCityInsightsAreVisibleMapbox = () => {
  const dataIsLoaded = useSelector(
    state =>
      !!(((state.dashboard.mapInsightsMapbox?.mapInsights as any)?.cityInsights?.insights || []) as CityInsight[])
        .length,
  );

  return dataIsLoaded;
};
