import { TableCell } from '../../../../core/components/Table';
import translate from '../../../../core/services/translate';

export const vehicleMaintenanceCells: TableCell[] = [
  {
    name: 'vehicleStatus',
    label: translate('vehicles.vehicleMaintenanceStatus.vehicleStatus'),
    width: '33.33%',
    padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical medium',
    noPaddingRight: true,
  },
  {
    name: 'upcomingPMMilestone',
    label: translate('vehicles.vehicleMaintenanceStatus.upcomingPMMilestone'),
    width: '33.33%',
    noPaddingRight: true,
  },
  {
    name: 'action',
    align: 'right',
    label: translate('vehicles.vehicleMaintenanceStatus.action'),
    width: '33.33%',
    padding: 'defaultCellVertical medium defaultCellVertical defaultCellHorizontal',
    noPaddingRight: true,
  },
];

export const pmLogsCells: TableCell[] = [
  {
    name: 'completedDate',
    label: translate('vehicles.pmLogs.completedDate'),
    width: '15%',
    padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical medium',
    noPaddingRight: true,
  },
  {
    name: 'completedMileageHours',
    label: translate('vehicles.pmLogs.completedMileageHours'),
    width: '20%',
    noPaddingRight: true,
  },
  {
    name: 'milestoneDate',
    label: translate('vehicles.pmLogs.milestoneDate'),
    width: '15%',
    noPaddingRight: true,
  },
  {
    name: 'milestoneMileageHours',
    label: translate('vehicles.pmLogs.milestoneMileageHours'),
    width: '20%',
    noPaddingRight: true,
  },
  {
    name: 'user',
    align: 'right',
    label: translate('vehicles.pmLogs.user'),
    width: '30%',
    padding: 'defaultCellVertical medium defaultCellVertical defaultCellHorizontal',
    noPaddingRight: true,
  },
];

export const issuesCells: TableCell[] = [
  {
    name: 'issueName',
    label: translate('vehicles.vehicleIssues.issueName'),
    width: '12%',
    padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical medium',
    noPaddingRight: true,
  },
  {
    name: 'openedDate',
    label: translate('vehicles.vehicleIssues.openedDate'),
    width: '11%',
    noPaddingRight: true,
  },
  {
    name: 'resolvedDate',
    label: translate('vehicles.vehicleIssues.resolvedDate'),
    width: '12%',
    noPaddingRight: true,
  },
  {
    name: 'route',
    label: translate('vehicles.vehicleIssues.route'),
    width: '13%',
    noPaddingRight: true,
  },
  {
    name: 'driver',
    label: translate('vehicles.vehicleIssues.driver'),
    width: '12%',
    noPaddingRight: true,
  },
  {
    name: 'images',
    align: 'center',
    label: translate('vehicles.vehicleIssues.images'),
    width: '8%',
    noPaddingRight: true,
  },
  {
    name: 'status',
    label: translate('vehicles.vehicleIssues.status'),
    width: '11%',
    noPaddingRight: true,
  },
  {
    name: 'lastUpdatedBy',
    label: translate('vehicles.vehicleIssues.lastUpdatedBy'),
    width: '11%',
    noPaddingRight: true,
  },
  {
    name: 'options',
    align: 'right',
    label: translate('common.options'),
    width: '11%',
    padding: 'defaultCellVertical medium defaultCellVertical defaultCellHorizontal',
    noPaddingRight: true,
  },
];

export const inspectionsCells: TableCell[] = [
  {
    name: 'routeDate',
    label: translate('routes.routeDate'),
    width: '13%',
    padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical medium',
    noPaddingRight: true,
  },
  {
    name: 'driver',
    label: translate('common.driver'),
    width: '20%',
    noPaddingRight: true,
  },
  {
    name: 'inspection',
    label: translate('vehicles.vehicleInspections.inspection'),
    width: '15%',
    noPaddingRight: true,
  },
  {
    name: 'startTime',
    align: 'center',
    label: translate('common.startTime'),
    width: '10%',
    noPaddingRight: true,
  },
  {
    name: 'endTime',
    align: 'center',
    label: translate('common.endTime'),
    width: '10%',
    noPaddingRight: true,
  },
  {
    name: 'noIssues',
    align: 'center',
    label: translate('vehicles.vehicleInspections.numberOfIssues'),
    width: '12%',
    noPaddingRight: true,
  },
  {
    name: 'options',
    align: 'right',
    label: translate('common.options'),
    width: '20%',
    padding: 'defaultCellVertical medium defaultCellVertical defaultCellHorizontal',
    noPaddingRight: true,
  },
];

export const faultCodesCells: TableCell[] = [
  {
    name: 'faultCode',
    label: translate('vehicles.vehicleFaultCodes.faultCode'),
    width: '15%',
    padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical medium',
    noPaddingRight: true,
  },
  {
    name: 'faultCodeDescription',
    label: translate('vehicles.vehicleFaultCodes.faultCodeDescription'),
    width: '17%',
    noPaddingRight: true,
  },
  {
    name: 'fmiNo',
    align: 'center',
    label: translate('vehicles.vehicleFaultCodes.fmiNo'),
    width: '15%',
    noPaddingRight: true,
  },
  {
    name: 'fmiDescription',
    label: translate('vehicles.vehicleFaultCodes.fmiDescription'),
    width: '23%',
    noPaddingRight: true,
  },
  {
    name: 'totalOccurences',
    align: 'center',
    label: translate('vehicles.vehicleFaultCodes.totalOccurences'),
    width: '15%',
    noPaddingRight: true,
  },
  {
    name: 'timestamp',
    align: 'right',
    label: translate('vehicles.vehicleFaultCodes.timestamp'),
    width: '15%',
    padding: 'defaultCellVertical medium defaultCellVertical defaultCellHorizontal',
    noPaddingRight: true,
  },
];

export const fuelEfficiencyLogCells: (
  isImperialMeasurementSystem: boolean,
) => TableCell[] = isImperialMeasurementSystem => [
  {
    name: 'fillUpTimeStamp',
    label: `${translate('common.date')} / ${translate('common.time')}`,
    width: '12%',
    noPaddingRight: true,
  },
  {
    name: 'fillUpLocation',
    label: translate('common.location'),
    width: '40%',
    noPaddingRight: true,
  },
  {
    name: 'milesPerGallon',
    label: translate(`vehicles.${isImperialMeasurementSystem ? 'mpg' : 'kpl'}`),
    width: '8%',
    noPaddingRight: true,
  },
  {
    name: 'gallonsUsed',
    label: translate(`vehicles.${isImperialMeasurementSystem ? 'gallonsUsed' : 'litersUsed'}`),
    width: '10%',
    noPaddingRight: true,
  },
  {
    name: 'distanceTravelled',
    label: translate(
      `vehicles.${isImperialMeasurementSystem ? 'distanceTravelledMiles' : 'distanceTravelledKilometers'}`,
    ),
    width: '11%',
    noPaddingRight: true,
  },
  {
    name: 'odometer',
    label: translate(`vehicles.${isImperialMeasurementSystem ? 'odometerMiles' : 'odometerKilometers'}`),
    width: '11%',
    noPaddingRight: true,
  },
  {
    name: 'options',
    label: '',
    width: '8%',
    noPaddingRight: true,
  },
];
