import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { colorMapper, sizeMapper } from '../../../utils/styles';
import { LIGHT, loading } from '../../../core/styles';

// Set the workerSrc globally
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfWrapper = styled.div`
  padding: ${sizeMapper('no xSmall')};
  background-color: ${colorMapper};
  height: 500px;
  overflow: auto;
`;

export const PdfLoader = styled.div`
  margin-top: 179px;
  ${loading('50px', LIGHT)};
`;

export const ProgressContainer = styled.div`
  width: 100%;
  background: #fff;
  height: 5px;
  width: 100%;
`;

export const ProgressBar = styled.div<{ width: string }>`
  height: 5px;
  background: ${props => props.theme.brandAlert};
  width: ${props => props.width};
`;

export const PdfHeader = styled.div`
  width: 100%;
  background-color: ${colorMapper};
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${sizeMapper('small medium small sMedium')};
`;

export const PdfFilename = styled.span``;
export const PdfPageIndicator = styled.span``;

export const PdfDocument = styled(Document)`
  text-align: center;
`;

export const PdfPage = styled(Page)`
  text-align: center;
  padding: ${sizeMapper('no xSmall')};
  margin: ${sizeMapper('xSmall no')};
`;
