import React, { Fragment, useState } from 'react';

import { debounce, orderBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, submit } from 'redux-form';

import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { APP_EXPERIENCE_FEATURE_ID, SEQUENTIAL_NAVIGATION_FEATURE_ID } from 'src/vendors/constants/features';
import { featuresForLoggedInUser } from 'src/vendors/ducks/features';
import { ActionButtonTooltip, Checkbox } from '../../../core/components';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { AccountSettingsModals } from '../pages/AccountSettingsPage';

interface PropsWithoutReduxForm {}

type AppExperienceFormProps = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const AppExperienceForm: React.FC<AppExperienceFormProps> = ({ form }) => {
  const dispatch = useDispatch();

  const features = useSelector(featuresForLoggedInUser);

  const appExperienceFeatures = orderBy(
    features.filter(
      feature => feature.parentId === APP_EXPERIENCE_FEATURE_ID && feature.id !== SEQUENTIAL_NAVIGATION_FEATURE_ID,
    ),
    ['name'],
    ['asc'],
  );

  const [selectedFeatureCode, setSelectedFeatureCode] = useState<string | undefined>();

  const closeFeatureModal = async (pristine = true) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
      return;
    }

    setSelectedFeatureCode(undefined);
  };

  const onChange = debounce(() => {
    dispatch(submit(form));
  }, 100);

  return (
    <form onChange={onChange} noValidate>
      <PanelSection padding="small" centered>
        <GridColumn size="6/12">
          <Grid centered multiLine>
            {appExperienceFeatures.map(feature => (
              <Fragment key={feature.code}>
                <GridColumn size="11/12" verticalAlign="center" padding="no" minHeight={42}>
                  <Field
                    component={Checkbox}
                    name={feature.code}
                    props={{
                      block: true,
                      margin: 'no',
                      size: 'medium',
                      label: feature.name,
                      disabled: feature.isReadonly,
                    }}
                  />
                </GridColumn>

                <GridColumn size="1/12" verticalAlign="center" padding="no" align="right" minHeight={42}>
                  {feature.canEditDetails && (
                    <Button
                      text
                      color="grayDark"
                      margin="no"
                      padding="no"
                      type="button"
                      disabled={!feature.enabled}
                      onClick={() => setSelectedFeatureCode(feature.code)}
                    >
                      <ActionButtonTooltip icon="edit" tooltip="editFeature" iconSize="sMedium" />
                    </Button>
                  )}
                </GridColumn>
              </Fragment>
            ))}
            <AccountSettingsModals featureCode={selectedFeatureCode} closeFeatureModal={closeFeatureModal} />
          </Grid>
        </GridColumn>
      </PanelSection>
    </form>
  );
};

export default reduxForm({ form: 'appExperienceForm', onSubmitFail: focusFirstInvalidField })(AppExperienceForm);
