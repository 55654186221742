import { useState } from 'react';
import { UnconnectedCheckbox } from 'src/core/components';

import { Button, ButtonSet, Grid, GridColumn, IconButtonIcon, Text } from '../../../core/components/styled';
import { DefaultFiltersContainer, FilterItem } from '../styled/DefaultFilters';
import translate from '../../../core/services/translate';

type Props = {
  title: string;
  filters: any[];
  locked: boolean;
  bottomLine?: boolean;
  isAdmin: boolean;
  setFilters: (filters: any[]) => void;
  setLocked: (locked: boolean) => void;
};

const DefaultFiltersModalSection = ({ title, filters, locked, bottomLine, isAdmin, setFilters, setLocked }: Props) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleSelectedItem = (value: number) => () => {
    if (!selectedItems.includes(value)) {
      setSelectedItems([...selectedItems, value]);
    } else {
      const spliced = selectedItems.filter(item => item !== value);
      setSelectedItems(spliced);
    }
  };

  const handleMove = (value: boolean) => () => {
    if (value === undefined) return;
    const newFilters: any = filters.map(filter => {
      if (selectedItems.includes(filter.value)) {
        if (filter.isGlobal && !isAdmin) return { ...filter, isPreferenceUnselected: !filter.isPreferenceUnselected };
        return {
          ...filter,
          isEnabled: value,
        };
      }
      return filter;
    });
    setFilters(newFilters);
    setSelectedItems([]);
  };

  const availableList: any[] = [];
  const assignedList: any[] = [];
  if (filters && filters.length) {
    filters.forEach(filter => {
      if (isAdmin) {
        if (filter.isEnabled) {
          assignedList.push(filter);
        } else {
          availableList.push(filter);
        }
      } else {
        if (filter.isEnabled && !filter.isPreferenceUnselected) {
          assignedList.push(filter);
        } else {
          availableList.push(filter);
        }
      }
    });
  }

  if (assignedList.length === 0 && locked && isAdmin) {
    setLocked(false);
  }

  return (
    <Grid centered multiLine>
      <GridColumn size="12/12" margin="no no small no">
        <Text size="large">{title}</Text>
      </GridColumn>
      {isAdmin && (
        <GridColumn size="12/12" margin="no no small no">
          <UnconnectedCheckbox
            onChange={ev => setLocked(ev.target.checked)}
            checked={locked}
            disabled={!assignedList.length}
            label={translate(`common.lockSelections`)}
          />
        </GridColumn>
      )}
      <GridColumn size="5/12">
        <Text size="large">{translate('common.notSelected')}</Text>
        <DefaultFiltersContainer right>
          {availableList.map((item, i) => (
            <FilterItem
              selectedItem={selectedItems.includes(item.value)}
              id={item.value}
              key={item.value}
              onClick={handleSelectedItem(item.value)}
              grayed={i % 2 !== 0}
            >
              {item.name}
            </FilterItem>
          ))}
        </DefaultFiltersContainer>
      </GridColumn>
      <GridColumn size="2/12">
        <ButtonSet vertical margin="xLarge no" style={{ height: '80px' }}>
          <Button text color="primary" size="xxLarge" onClick={handleMove(true)}>
            <IconButtonIcon icon="fastForward" margin="no" color="primary" size="large" />
          </Button>
          <Button text color="primary" size="xxLarge" onClick={handleMove(false)}>
            <IconButtonIcon icon="fastForward" rotate={180} margin="no" color="primary" size="large" />
          </Button>
        </ButtonSet>
      </GridColumn>
      <GridColumn size="5/12">
        <Text size="large">{translate('common.selected')}</Text>
        <DefaultFiltersContainer>
          {assignedList.map((item, i) => (
            <FilterItem
              selectedItem={selectedItems.includes(item.value)}
              id={item.value}
              key={item.value}
              onClick={handleSelectedItem(item.value)}
              grayed={i % 2 !== 0}
              // disabled={item.isGlobal && !isAdmin}
            >
              {item.name}
            </FilterItem>
          ))}
        </DefaultFiltersContainer>
      </GridColumn>
      <GridColumn size="12/12" padding="sMedium no">
        {bottomLine && <hr />}
      </GridColumn>
    </Grid>
  );
};

export default DefaultFiltersModalSection;
