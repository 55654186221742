import { WrappedFieldArrayProps } from 'redux-form';

import { ActionButtonTooltip, Dropdown, Input, MultiSelect, TypedField } from 'src/core/components';
import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import {
  BulkyItemScheduler,
  BulkySchedulerLimitByDaySettings,
  BulkySchedulerLimitByTimeRangeSettings,
} from 'src/vendors/interfaces/BulkyItemScheduler';
import { DAY_ID, MONTH_ID, PERIOD_TYPES, QUARTER_ID, WEEK_ID, YEAR_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { DayOfServiceMultiSelect } from 'src/routes/components';
import { GridColumn, TableCell, TableRow } from 'src/core/components/styled';
import { isRequired, minValueNumeric0 } from 'src/utils/services/validator';
import { Rate } from 'src/customers/interfaces/Services';
import { TableActionButton } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import BulkyItemSchedulerServiceLimitsByDayValidationField, {
  bulkyItemSchedulerServiceLimitsByDayValidate,
} from './BulkyItemSchedulerServiceLimitsByDayValidationField';
import BulkyItemSchedulerServiceLimitsByTimeRangeValidationField, {
  bulkyItemSchedulerServiceLimitsByTimeRangeValidate,
} from './BulkyItemSchedulerServiceLimitsByTimeRangeValidationField';
import TimePickerField from 'src/core/components/TimePickerField';
import translate from 'src/core/services/translate';

const isMaxValueNumeric = (value: string, formData: BulkyItemScheduler, _: any, name: string) => {
  if (formData && formData.bulkySchedulerLimitByTimeRangeSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;
    const currentTimeRangeSettings = formData.bulkySchedulerLimitByTimeRangeSettings[index as number] || 0;

    if (currentTimeRangeSettings) {
      const getServiceLocationMaxPickups = (periodTypeId: number) =>
        formData.bulkySchedulerLimitByTimeRangeSettings?.find(
          (serviceLocation: any) => serviceLocation?.period?.id === periodTypeId,
        )?.maxPickUps;

      const weekMaxLocationPickups = getServiceLocationMaxPickups(WEEK_ID);
      const monthLocationPickups = getServiceLocationMaxPickups(MONTH_ID);
      const quarterMaxLocationPickups = getServiceLocationMaxPickups(QUARTER_ID);
      const yearMaxLocationPickups = getServiceLocationMaxPickups(YEAR_ID);

      let pickupsValidator = undefined;
      switch (currentTimeRangeSettings?.period?.id) {
        case DAY_ID:
          pickupsValidator =
            weekMaxLocationPickups || monthLocationPickups || quarterMaxLocationPickups || yearMaxLocationPickups;
          break;
        case WEEK_ID:
          pickupsValidator = monthLocationPickups || quarterMaxLocationPickups || yearMaxLocationPickups;
          break;
        case MONTH_ID:
          pickupsValidator = quarterMaxLocationPickups || yearMaxLocationPickups;
          break;
        case QUARTER_ID:
          pickupsValidator = yearMaxLocationPickups;
          break;
      }

      return pickupsValidator && Number(value) > pickupsValidator
        ? translate('common.validationMessages.xMaxValue', { max: pickupsValidator })
        : undefined;
    }
  }
};

type Props = WrappedFieldArrayProps<Rate> & {
  cellWidths: { [key: string]: string };
  initialValueBulkySchedulerLimitByDaySettings?: BulkySchedulerLimitByDaySettings[];
  initialValueBulkySchedulerLimitByTimeRangeSettings?: BulkySchedulerLimitByTimeRangeSettings[];
  isLimitByDays?: boolean;
  restrictPickupDays: number[];
};

const BulkyItemSchedulerServiceLimitsByZoneSectionTableRow = ({
  initialValueBulkySchedulerLimitByDaySettings,
  initialValueBulkySchedulerLimitByTimeRangeSettings,
  cellWidths,
  fields,
  isLimitByDays,
  restrictPickupDays,
}: Props) => {
  const { geoFenceBasicList } = useSelector(state => state.routes.geoFences);

  const geoFenceOptions = geoFenceBasicList.map((geoFence: any) => {
    return { value: geoFence.id.toString(), label: geoFence.geoFenceName };
  });

  const periodTypes = PERIOD_TYPES.map(type => ({
    label: type.name,
    value: type.id,
  }));

  return (
    <>
      {fields.map((field, index) => (
        <TableRow key={`rateCodeCell-${field}`} className="tableRow">
          <TableCell width={cellWidths.daysOfWeek}>
            {isLimitByDays ? (
              <TypedField
                name={`${field}.days`}
                component={DayOfServiceMultiSelect}
                props={{
                  multiSelectProps: {
                    margin: 'no',
                    disabled: false,
                    inputWidth: '100%',
                    label: translate('billing.dayOfWeek'),
                    defaultToAll: false,
                  },
                  restrictPickupDays: restrictPickupDays,
                }}
                validate={[isRequired]}
              />
            ) : (
              <TypedField
                name={`${field}.period.id`}
                component={Dropdown}
                props={{
                  options: periodTypes,
                  margin: 'no',
                  disabled: false,
                  width: '100%',
                  label: translate('vendors.bulkyItemScheduler.timeRange'),
                }}
                validate={[isRequired]}
              />
            )}
          </TableCell>

          <TableCell width={cellWidths.dailyMax}>
            <TypedField
              name={`${field}.maxPickUps`}
              component={Input}
              props={{
                type: 'number',
                margin: 'no',
                disabled: false,
                label: isLimitByDays
                  ? translate('vendors.bulkyItemScheduler.dailyMax')
                  : translate('vendors.bulkyItemScheduler.maxPickups'),
              }}
              validate={
                isLimitByDays ? [isRequired, minValueNumeric0] : [isRequired, minValueNumeric0, isMaxValueNumeric]
              }
            />
          </TableCell>

          <TableCell width={cellWidths.puWindowStart}>
            <TimePickerField
              name={`${field}.pickUpWindowBegin`}
              formName={BULKY_ITEM_SCHEDULER_FORM}
              minuteStep={15}
              fullWidth={true}
              labelName={translate('vendors.bulkyItemScheduler.puWindowStart')}
              defaultValue={
                isLimitByDays
                  ? initialValueBulkySchedulerLimitByDaySettings?.length
                    ? initialValueBulkySchedulerLimitByDaySettings[index]?.pickUpWindowBegin
                    : undefined
                  : initialValueBulkySchedulerLimitByTimeRangeSettings?.length
                  ? initialValueBulkySchedulerLimitByTimeRangeSettings[index]?.pickUpWindowBegin
                  : undefined
              }
            />
          </TableCell>

          <TableCell width={cellWidths.puWindowEnd}>
            <TimePickerField
              name={`${field}.pickUpWindowEnd`}
              formName={BULKY_ITEM_SCHEDULER_FORM}
              minuteStep={15}
              fullWidth={true}
              labelName={translate('vendors.bulkyItemScheduler.puWindowEnd')}
              defaultValue={
                isLimitByDays
                  ? initialValueBulkySchedulerLimitByDaySettings?.length
                    ? initialValueBulkySchedulerLimitByDaySettings[index]?.pickUpWindowEnd
                    : undefined
                  : initialValueBulkySchedulerLimitByTimeRangeSettings?.length
                  ? initialValueBulkySchedulerLimitByTimeRangeSettings[index]?.pickUpWindowEnd
                  : undefined
              }
            />
          </TableCell>

          <TableCell width={cellWidths.geoFenceIds}>
            <TypedField
              name={`${field}.geoFences`}
              component={MultiSelect}
              props={{
                defaultToAll: false,
                options: geoFenceOptions,
                margin: 'no',
                disabled: false,
                inputWidth: '100%',
                label: translate('routes.geoFences.geoFencesTitle'),
                isClearable: true,
              }}
            />
          </TableCell>

          <TableCell width={cellWidths.action} justifyContent="center" alignItems="center">
            <TableActionButton onClick={() => fields.remove(index)} id={`bill-${field}-delete-button`}>
              <ActionButtonTooltip icon="delete" tooltip="deleteRate" />
            </TableActionButton>
          </TableCell>
        </TableRow>
      ))}

      {isLimitByDays ? (
        <GridColumn padding="small" id="serviceLimitsByDayValidation">
          <TypedField
            name="serviceLimitsByDayValidation"
            component={BulkyItemSchedulerServiceLimitsByDayValidationField}
            validate={[bulkyItemSchedulerServiceLimitsByDayValidate]}
          />
        </GridColumn>
      ) : (
        <GridColumn padding="small" id="serviceLimitsByTimeRangeValidation">
          <TypedField
            name="serviceLimitsByTimeRangeValidation"
            component={BulkyItemSchedulerServiceLimitsByTimeRangeValidationField}
            validate={[bulkyItemSchedulerServiceLimitsByTimeRangeValidate]}
          />
        </GridColumn>
      )}
    </>
  );
};

export default BulkyItemSchedulerServiceLimitsByZoneSectionTableRow;
