import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadCustomerNotificationsVehicleTypes as doLoadCustomerNotificationsVehicleTypes } from '../services/customerNotificationsVehicleTypes';

// Actions
const START_LOAD = 'vendors/customerNotificationsVehicleTypes/START_LOAD';
const COMPLETE_LOAD = 'vendors/customerNotificationsVehicleTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/customerNotificationsVehicleTypes/FAIL_LOAD';
const RESET = 'vendors/customerNotificationsVehicleTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  customerNotificationsVehicleTypes: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          customerNotificationsVehicleTypes: action.customerNotificationsVehicleTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (customerNotificationsVehicleTypes: any) => ({
  type: COMPLETE_LOAD,
  customerNotificationsVehicleTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadCustomerNotificationsVehicleTypes = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const changePasswordPromise = doLoadCustomerNotificationsVehicleTypes(vendorId);
  changePasswordPromise
    .then(customerNotificationsVehicleTypes => dispatch(completeLoad(customerNotificationsVehicleTypes)))
    .catch(() => dispatch(failLoad()));
  return changePasswordPromise;
};

export const resetCustomerNotificationsVehichleTypes = () => ({
  type: RESET,
});
