import { identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { getQueryParams } from '../../utils/services/queryParams';

const getRouteImportStatusesFormInitialValues = (queryString: string) => {
  const { haulerRouteImportStatusTypeId } = getQueryParams(queryString);

  return {
    haulerRouteImportStatusTypeId: haulerRouteImportStatusTypeId && Number(haulerRouteImportStatusTypeId),
  };
};

const routeImportStatusesFormInitialValuesSelector = createSelector(getRouteImportStatusesFormInitialValues, identity);

export default routeImportStatusesFormInitialValuesSelector;
