import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import { Modal } from '../../../core/components';
import { ModalFixedHeader, ModalTitle, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveSurfsightConfiguration } from '../../ducks/surfsightConfiguration';
import SurfsightConfigurationForm from '../forms/SurfsightConfigurationForm';
import translate from '../../../core/services/translate';

//const CONFIG_TYPE = 'organization';
interface Props extends ModalProps {
  closeModal: (pristine?: boolean) => void;
}

export default function SurfsightDataModal({ closeModal }: Props) {
  const dispatch = useDispatch();
  const { surfsightConfiguration, isLoading, isSaving } = useSelector(
    (state: AppState) => state.vendors.surfsightConfiguration,
  );

  const handleSubmit = (formData: any) => {
    const payload = {
      id: surfsightConfiguration.id,
      organizationId: formData.organizationId,
      vendorId: surfsightConfiguration.vendorId,
    };

    saveSurfsightConfiguration(payload)(dispatch)
      .then(() => {
        closeModal();
        createSuccessNotification(translate('vendors.alertMessages.surfsightDataSaved'));
      })
      .catch(({ response }) => {
        createErrorNotification(response?.data?.message || translate('vendors.alertMessages.surfsightDataError'));
      });
  };

  return (
    <Modal isLoading={isLoading || isSaving} padding="no" size="smallMedium">
      <ModalFixedHeader padding="medium no no no">
        <ModalClose onClick={() => closeModal()}>
          <ModalCloseIcon />
        </ModalClose>
        <ModalTitle>{translate(createTranslationKey('surfsightConfiguration', 'vendors.featureCodes'))}</ModalTitle>
      </ModalFixedHeader>
      <SurfsightConfigurationForm onSubmit={handleSubmit} initialValues={surfsightConfiguration} />
    </Modal>
  );
}
