export const ROUTE_BUILDER_HEADER_AND_FOOTER_HEIGHT = 94 + 79;
export const ROUTE_BUILDER_FILTER_HEIGHT = 53;
export const ROUTE_BUILDER_JOBS_LIST_HEIGHT = 500;
export const ROUTE_BUILDER_ITEMS = 10;
export const ROUTE_BUILDER_ITEM_HEIGHT = 90;
export const ROUTE_BUILDER_ITEM_HEIGHT_EXPANDED = 180;
export const ROUTE_BUILDER_MAX_ROUTES = 25;
export const ROUTE_BUILDER_MAX_SELECTED_JOBS = 500;
export const ROUTE_BUILDER_TIME_PER_ROUTE = 5;
export const ROUTE_BUILDER_BASE_TIME = 40;

export const ROUTE_BUILDER_PENDING = 1;
export const ROUTE_BUILDER_COMPLETE = 2;
export const ROUTE_BUILDER_FAILED = 3;
export const ROUTE_BUILDER_FINISHED = 4;
