import { debounce, find, groupBy, map, size } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';

import { MapGL } from 'src/common/components/map/MapGL';
import { getMapBounds } from 'src/common/components/map/util';
import useGeoFenceControllerMapbox, { NEW_GEO_FENCE_ID } from 'src/common/hooks/geoFenceControllerMapbox';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon, Text } from 'src/core/components/styled';
import { GEO_FENCE_OPTIONS, GEO_FENCE_OPTIONS_SAT, GEO_FENCE_OPTIONS_SELECTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { MapGLWrapper } from 'src/customers/components/styled';
import { setSelectedHaulerLocationsForDisplay } from 'src/customers/ducks';
import {
  loadStreetNetworkCustomerLocations,
  setShowCustomerLocations,
  setStreetNetworkServiceAreasViewport,
} from 'src/customers/ducks/streetNetworkServiceAreas';
import GeoFencesGL from 'src/routes/components/pages/geoFences/components/mapGL/GeoFencesGL';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { ServiceAreaMapFiltersForm } from '../../forms';
import { ServiceAreaMapFiltersFormValues } from '../../forms/ServiceAreaMapFiltersForm';
import { ServiceAreaModalWarning } from '../../styled/StreetNetwork';
import ServiceAreaMapClusterGL from './cluster/ServiceAreaMapClusterGL';
import HaulerLocationsGL from './haulerLocations/HaulerLocationsGL';
import { getSelectedIds } from './utils';
import CustomerLocationsGL from './customerLocations/CustomerLocationsGL';
import { ServiceAreaMapDrawingInstructions } from './ServiceAreaMapDrawingInstructions';

interface Props {
  handleSaveServiceArea: (editedPolygon: any) => void;
  vendorId: number;
}

const ServiceAreaModalMap: FC<Props> = ({ handleSaveServiceArea, vendorId }) => {
  const dispatch = useDispatch();
  const [drawMode, setDrawMode] = useState<EditingMode | DrawPolygonMode>();
  const editorRef = useRef<Editor>(null);
  const [mapRef, setMap] = useState<mapboxgl.Map>();
  const [isSatelliteView, setIsSatelliteView] = useState(false);

  const [isMapFiltersOpen, setIsMapFiltersOpen] = useState(false);

  const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false);
  const [editedGeoFences, setEditedGeoFences] = useState<any>([]);

  const viewport = useSelector(state => state.customers.streetNetworkServiceAreas.viewport);
  const serviceArea = useSelector(state => state.customers.streetNetworkServiceAreas.serviceAreas);
  const haulerLocations = useSelector(state => state.customers.streetNetworkServiceAreas.haulerLocations);
  const customerLocationSize = useSelector(state => state.customers.streetNetworkServiceAreas.customerLocations.length);

  const currentServiceArea = useMemo(() => {
    // take the first one
    return serviceArea?.length ? serviceArea[0] : null;
  }, [serviceArea]);

  const {
    mapGeoFences,
    getAllCoords,
    allGeoFencesGeoJson,
    selectedGeoFenceGeo,
    hasChanges,
    // bulkAddGeoFences,
    // emptyGeoFences,
    addGeoFence,
    getAllGeoJsonForEditing,
    selectGeoFencePolygon,
    unselectGeoFencePolygon,
    updatePolygonInGeoFence,
    undoPolygonInGeoFence,
    removeUnsavedGeoFenceOrPolygons,
    // removePolygonFromGeoFence,
    addPolygonToGeoFence,
  } = useGeoFenceControllerMapbox();

  //add the polygon to the controller
  useEffect(() => {
    if (currentServiceArea?.polygons?.length) {
      const polygon = currentServiceArea.polygons[0];
      const jsonObject = JSON.parse(polygon.json);

      addGeoFence({
        id: polygon.id!,
        geoFenceCoordinates: [jsonObject.geometry.coordinates],
        isActive: true,
        vendorId,
        geoFenceSettings: [],
      });
    }
  }, [currentServiceArea?.polygons, currentServiceArea?.id, vendorId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    mapRef?.once('load', () => {
      mapRef.on('click', event => {
        const features = mapRef.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If any cluster is clicked, then any popup
             * should be closed.
             */
            !!feature.properties?.cluster_id,
        );

        if (features.length) {
        }
      });

      mapRef.on('mousemove', event => {
        const features = mapRef.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true || !!feature.properties?.cluster_id,
        );

        mapRef.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      mapRef.on('mouseleave', () => {
        mapRef.getCanvas().style.cursor = '';
      });
    });
  }, [mapRef, dispatch]);

  // fit map bounds
  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    points.push(...getAllCoords);

    if (points.length) {
      const bounds = getMapBounds(points, {
        padding: 10,
        capZoom: 17,
      });
      dispatch(setStreetNetworkServiceAreasViewport(bounds));
    }
  }, [mapGeoFences.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateGeoFenceHistoryDebounced = useMemo(
    () =>
      debounce((newPolygons: any) => {
        const updatedPolygon = find(newPolygons, (polygon: any) => {
          return (
            polygon.properties?.polygonId === selectedGeoFenceGeo?.polygonId &&
            polygon.properties?.geoFenceId === selectedGeoFenceGeo?.id
          );
        });

        if (updatedPolygon && selectedGeoFenceGeo) {
          updatePolygonInGeoFence(
            selectedGeoFenceGeo?.id,
            selectedGeoFenceGeo?.polygonId,
            updatedPolygon.geometry.coordinates[0],
          );
        }
      }, 200),
    [selectedGeoFenceGeo, updatePolygonInGeoFence],
  );

  const onEditGeoFence = (event: any) => {
    const newPolygons = event.data;
    const last = newPolygons.length - 1;

    if (event.editType === 'addFeature' && isAddingPolygon) {
      setIsAddingPolygon(false);
      setDrawMode(new EditingMode());

      const polygonId = addPolygonToGeoFence(newPolygons[last].geometry.coordinates[0], currentServiceArea?.id);
      setEditedGeoFences([
        ...editedGeoFences,
        {
          ...newPolygons[last],
          properties: {
            polygonId,
            geoFenceId: currentServiceArea?.id || NEW_GEO_FENCE_ID,
          },
        },
      ]);
    } else {
      updateGeoFenceHistoryDebounced(newPolygons);
      setEditedGeoFences(newPolygons);
    }
  };

  useEffect(() => {
    setEditedGeoFences(getAllGeoJsonForEditing());
  }, [getAllGeoJsonForEditing, mapGeoFences]);

  const handleUndoGeoFence = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedGeoFenceGeo) {
      undoPolygonInGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
    }
  };

  // TODO: for now we do not alow deleting, only editing
  // const handleAddPolygonToGeoFence = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setDrawMode(new DrawPolygonMode());
  //   setIsAddingPolygon(true);
  //   unselectGeoFencePolygon();
  // };

  // const handleDeletePolygonFromGeoFence = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (selectedGeoFenceGeo) {
  //     removePolygonFromGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
  //     setEditedGeoFences([
  //       ...filter(editedGeoFences, (p: any) => p.properties?.polygonId !== selectedGeoFenceGeo.polygonId),
  //     ]);
  //     unselectGeoFencePolygon();
  //   }
  // };

  const handleSelectInGeoFenceEditor = useCallback(
    (selected: any) => {
      if (selected?.selectedFeatureIndex === null) {
        unselectGeoFencePolygon();
        return;
      }

      if (selected?.selectedFeature?.properties?.polygonId && selected?.selectedFeature?.properties?.geoFenceId) {
        selectGeoFencePolygon(
          selected?.selectedFeature?.properties?.geoFenceId,
          selected?.selectedFeature?.properties?.polygonId,
        );
      } else {
        const geoFenceId = currentServiceArea?.polygons[0]?.id || NEW_GEO_FENCE_ID;
        selectGeoFencePolygon(geoFenceId, selected?.selectedFeatureIndex);
      }
    },
    [currentServiceArea, selectGeoFencePolygon, unselectGeoFencePolygon],
  );

  const handleEnterExitEditMode = () => {
    if (drawMode) {
      setDrawMode(undefined);
      setIsAddingPolygon(false);
      unselectGeoFencePolygon();
      removeUnsavedGeoFenceOrPolygons();
      if (editedGeoFences) {
        setEditedGeoFences(undefined);
      }
    } else {
      setDrawMode(new EditingMode());
      const features = getAllGeoJsonForEditing();
      setEditedGeoFences(features);
      if (!size(editedGeoFences)) {
        setIsAddingPolygon(true);
        setDrawMode(new DrawPolygonMode());
      }
    }
  };

  const getFeatureStyle = ({
    feature,
    index,
    state,
  }: {
    feature: any;
    index: number;
    state: 'SELECTED' | 'HOVERED' | 'INACTIVE' | 'UNCOMMITTED' | 'CLOSING';
  }) => {
    const cursor =
      drawMode instanceof EditingMode ? 'pointer' : drawMode instanceof DrawPolygonMode ? 'crosshair' : 'default';

    if (state === 'SELECTED' || state === 'HOVERED') {
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };
    }
    return {
      stroke: isSatelliteView ? GEO_FENCE_OPTIONS_SAT.strokeColor : GEO_FENCE_OPTIONS.strokeColor,
      fill: isSatelliteView ? GEO_FENCE_OPTIONS_SAT.fillColor : GEO_FENCE_OPTIONS.fillColor,
      strokeWidth: 2,
      fillOpacity: 0.1,
      cursor,
    };
  };

  const handleSubmitMapFiltersForm = useCallback(
    (values: ServiceAreaMapFiltersFormValues) => {
      const locationTypes = Object.keys(values.haulerLocationFilters || {});

      const selectedIds = locationTypes.reduce((acc, locationType) => {
        const subtypeValues = getSelectedIds(values.haulerLocationFilters[locationType] || {}, {
          prefix: 'locationSubTypeId_',
        });
        return [...acc, ...subtypeValues];
      }, [] as any);

      const selectedLocations = haulerLocations.filter(location => selectedIds.includes(location.customerSubTypeId));
      dispatch(setSelectedHaulerLocationsForDisplay(selectedLocations));
      // todo: add customers here as well
      if (values.showCustomerLocations) {
        if (!customerLocationSize) {
          loadStreetNetworkCustomerLocations(vendorId)(dispatch);
        }
        dispatch(setShowCustomerLocations(true));
      } else {
        dispatch(setShowCustomerLocations(false));
      }

      setIsMapFiltersOpen(false);
    },
    [customerLocationSize, dispatch, haulerLocations, vendorId],
  );

  const onSave = useCallback(() => {
    handleSaveServiceArea(editedGeoFences);
  }, [editedGeoFences, handleSaveServiceArea]);

  const serviceAreaFiltersFormInitialValues = useMemo(() => {
    const locationGroupedByType = groupBy(haulerLocations, location => location.customerTypeId);
    const locationFilters = Object.keys(locationGroupedByType).reduce((acc, locationTypeId) => {
      const locations = locationGroupedByType[locationTypeId];
      const locationsGroupedBySubType = groupBy(locations, location => location.customerSubType);
      const subTypeObject = map(Object.keys(locationsGroupedBySubType), locationSubType => {
        const subTypeId = locationsGroupedBySubType[locationSubType][0].customerSubTypeId;
        return {
          [`locationSubTypeId_${subTypeId}`]: true,
        };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {});
      subTypeObject[`all`] = true;
      return {
        ...acc,
        [`locationTypeId_${locationTypeId}`]: subTypeObject,
      };
    }, {});

    return {
      haulerLocationFilters: locationFilters,
      customerLocationFilters: undefined,
      showCustomerLocations: false,
    };
  }, [haulerLocations]);

  return (
    <MapGLWrapper drawingEnabled={!!drawMode} isDrawing={!!drawMode && drawMode instanceof DrawPolygonMode}>
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={mapRef => setMap(mapRef)}
        setIsSatelliteViewEnabled={setIsSatelliteView}
      >
        {currentServiceArea?.isLocked && (
          <ServiceAreaModalWarning>
            <Text color="alert">{translate('customers.streetNetwork.generatingInProgress')}</Text>
          </ServiceAreaModalWarning>
        )}

        {mapRef && (
          <>
            <ServiceAreaMapClusterGL map={mapRef} />

            <HaulerLocationsGL map={mapRef} />
            <CustomerLocationsGL map={mapRef} />

            {!drawMode && (
              <GeoFencesGL geoFencesGeoJSON={allGeoFencesGeoJson} isSatellite={isSatelliteView} map={mapRef} />
            )}
          </>
        )}

        <ComplexMapControl vertical position="top-left">
          <TooltipIconButton
            tooltip="filters"
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color="secondary"
            margin="no"
            onClick={() => setIsMapFiltersOpen(true)}
          >
            <IconButtonIcon icon="filter" color="primary" />
          </TooltipIconButton>
        </ComplexMapControl>

        <ComplexMapControl vertical position="top-right">
          <TooltipIconButton
            color="secondary"
            tooltip={drawMode ? 'cancel' : !!size(editedGeoFences) ? 'editServiceAre' : 'createServiceArea'}
            tooltipPosition="left"
            margin="small no no"
            disabled={currentServiceArea?.isLocked}
            onClick={handleEnterExitEditMode}
            type="button"
          >
            <IconButtonIcon icon={drawMode ? 'close' : 'edit'} size="large" />
          </TooltipIconButton>

          {drawMode && (
            <>
              {selectedGeoFenceGeo && (
                <TooltipIconButton
                  disabled={size(selectedGeoFenceGeo.history) <= 1}
                  color="secondary"
                  tooltip="undo"
                  tooltipPosition="left"
                  margin="small no no"
                  onClick={handleUndoGeoFence}
                  type="button"
                >
                  <IconButtonIcon icon="betterUndo" size="large" />
                </TooltipIconButton>
              )}
              {/* TODO: for now we do not alow deleting, only editing */}
              {/* <TooltipIconButton
                disabled={isAddingPolygon || !selectedGeoFenceGeo}
                color="secondary"
                tooltip="deletePolygon"
                tooltipPosition="left"
                margin="small no no"
                onClick={handleDeletePolygonFromGeoFence}
                type="button"
              >
                <IconButtonIcon icon="deleteGeoFence" size="large" />
              </TooltipIconButton> */}
              {/* TODO: for now only a polygon will be allowed (one street network) */}
              {/* <TooltipIconButton
                disabled={isAddingPolygon}
                color="secondary"
                tooltip="addPolygon"
                tooltipPosition="left"
                margin="small no no"
                onClick={handleAddPolygonToGeoFence}
                type="button"
              >
                <IconButtonIcon icon="createGeoFence" size="large" />
              </TooltipIconButton> */}

              <TooltipIconButton
                color="secondary"
                tooltip="save"
                tooltipPosition="left"
                margin="small no no"
                disabled={!hasChanges}
                onClick={onSave}
                type="button"
              >
                <IconButtonIcon icon="save" size="large" />
              </TooltipIconButton>
            </>
          )}
        </ComplexMapControl>

        {drawMode && <ServiceAreaMapDrawingInstructions />}

        {drawMode && (
          <Editor
            ref={editorRef}
            clickRadius={12}
            mode={drawMode}
            features={editedGeoFences ? editedGeoFences : []}
            onUpdate={onEditGeoFence}
            onSelect={handleSelectInGeoFenceEditor}
            featureStyle={getFeatureStyle}
          />
        )}
      </MapGL>
      {isMapFiltersOpen && (
        <ServiceAreaMapFiltersForm
          onSubmit={handleSubmitMapFiltersForm}
          isFormOpen={isMapFiltersOpen}
          onClose={() => setIsMapFiltersOpen(false)}
          initialValues={serviceAreaFiltersFormInitialValues}
        />
      )}
    </MapGLWrapper>
  );
};

export default ServiceAreaModalMap;
