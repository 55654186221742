import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { find, findIndex, get, identity } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { createService, deleteService as doDeleteService, updateService } from '../services/service';
import { ServiceFeatureNamespace, Service, ServiceFeature } from 'src/customers/interfaces/Services';
import doLoadServices from '../services/loadServices';
import doLoadYServices from '../services/loadYServices';
import transformLoadServices from '../services/transformLoadServices';

// Actions
const START_LOAD = 'customers/services/START_LOAD';
const COMPLETE_LOAD = 'customers/services/COMPLETE_LOAD';
const COMPLETE_LOAD_FOR_SERVICE_CONTRACT_WORKFLOW = 'customers/services/COMPLETE_LOAD_FOR_SERVICE_CONTRACT_WORKFLOW';
const FAIL_LOAD = 'customers/services/FAIL_LOAD';
const START_Y_LOAD = 'customers/services/START_Y_LOAD';
const COMPLETE_Y_LOAD = 'customers/services/COMPLETE_Y_LOAD';
const FAIL_Y_LOAD = 'customers/services/FAIL_Y_LOAD';
const START_SAVE = 'customers/services/START_SAVE';
export const COMPLETE_SAVE = 'customers/services/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/services/FAIL_SAVE';
const START_DELETE = 'customers/services/START_DELETE';
export const COMPLETE_DELETE = 'customers/services/COMPLETE_DELETE';
const FAIL_DELETE = 'customers/services/FAIL_DELETE';
const RESET = 'customers/services/RESET';
const RESET_SERVICES_FOR_SERVICE_CONTRACT_WORKFLOW = 'customers/services/RESET_SERVICES_FOR_SERVICE_CONTRACT_WORKFLOW';
const SET_SELECTED_FEATURE = 'customers/services/SET_SELECTED_FEATURE';
const CLEAR_SELECTED_FEATURE = 'customers/services/CLEAR_SELECTED_FEATURE';

interface State {
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  selectedFeature?: ServiceFeature;
  services: Service[];
  servicesForServiceContractWorkflow: Service[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isDeleting: false,
  isLoading: false,
  isSaving: false,
  selectedFeature: undefined,
  services: [],
  servicesForServiceContractWorkflow: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          services: action.services,
        },
      });

    case COMPLETE_LOAD_FOR_SERVICE_CONTRACT_WORKFLOW:
      return update(state, {
        $merge: {
          isLoading: false,
          servicesForServiceContractWorkflow: action.services,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          services: [],
        },
      });

    case START_Y_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_Y_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          services: action.services,
        },
      });

    case FAIL_Y_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          services: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          services: action.services,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const index = findIndex(state.services, { id: action.serviceId });
      return update(state, {
        services: { $splice: [[index, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case SET_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: {
            namespace: action.namespace,
            ...action.additionalFields,
          },
        },
      });

    case CLEAR_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    case RESET_SERVICES_FOR_SERVICE_CONTRACT_WORKFLOW:
      return update(state, {
        $merge: {
          servicesForServiceContractWorkflow: initialState.servicesForServiceContractWorkflow,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (services: Service[]) => ({
  type: COMPLETE_LOAD,
  services,
});

const completeLoadForServiceContractWorkflow = (services: Service[]) => ({
  type: COMPLETE_LOAD_FOR_SERVICE_CONTRACT_WORKFLOW,
  services,
});

const failLoad = () => ({
  type: FAIL_LOAD,
  services: [],
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (services: Service[], saveType: 'Update' | 'Create') => ({
  type: COMPLETE_SAVE,
  services,
  saveType,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (serviceId: number) => ({
  type: COMPLETE_DELETE,
  serviceId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startYLoad = () => ({
  type: START_Y_LOAD,
});

const completeYLoad = (services: Service[]) => ({
  type: COMPLETE_Y_LOAD,
  services,
});

const failYLoad = () => ({
  type: FAIL_Y_LOAD,
});

export const saveService = (service: Service) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveServicePromise = service.id ? updateService(service) : createService(service);
  const saveType = service.id ? 'Update' : 'Create';
  saveServicePromise
    .then(res => dispatch(completeSave(transformLoadServices(res.data), saveType)))
    .catch(() => dispatch(failSave()));
  return saveServicePromise;
};

export const deleteService =
  (serviceId: number, shouldRecreateRoutes: boolean, moveContainersToFacilityId?: number) => (dispatch: Dispatch) => {
    dispatch(startDelete());
    const deleteServicePromise = doDeleteService(serviceId, shouldRecreateRoutes, moveContainersToFacilityId);
    deleteServicePromise.then(() => dispatch(completeDelete(serviceId))).catch(() => dispatch(failDelete()));
    return deleteServicePromise;
  };

export const loadServices = (locationId: number, isForServiceContractWorkflow?: boolean) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadServicesPromise = doLoadServices(locationId)
    .then(services =>
      isForServiceContractWorkflow
        ? dispatch(completeLoadForServiceContractWorkflow(services))
        : dispatch(completeLoad(services)),
    )
    .catch(() => dispatch(failLoad()));
  return loadServicesPromise;
};

export const loadYServices = (binLocationId: number) => (dispatch: Dispatch) => {
  dispatch(startYLoad());
  const loadYServicesPromise = doLoadYServices(binLocationId)
    .then((services: Service[]) => dispatch(completeYLoad(services)))
    .catch(() => dispatch(failYLoad()));
  return loadYServicesPromise;
};

export const setServiceSelectedFeature = (
  namespace: ServiceFeatureNamespace,
  additionalFields: Partial<Omit<ServiceFeature, 'namespace'>> = {},
) => ({
  type: SET_SELECTED_FEATURE,
  namespace,
  additionalFields,
});

export const clearServiceSelectedFeature = () => ({
  type: CLEAR_SELECTED_FEATURE,
});

export const resetServices = () => ({
  type: RESET,
});

export const resetServicesForServiceContractWorkflow = () => ({
  type: RESET_SERVICES_FOR_SERVICE_CONTRACT_WORKFLOW,
});

const getServiceTypeById = (servicesState: State, serviceId: number) => {
  const service = find(servicesState.services, { id: serviceId });
  return get(service, 'serviceTypeId', undefined);
};
export const serviceTypeIdSelector = createSelector(getServiceTypeById, identity);
