import { connect } from 'react-redux';
import { map, size } from 'lodash-es';

import { AdvRoute } from 'src/routes/components/pages/routes/RouteTrackerPage';
import { AppState } from 'src/store';
import { Modal } from 'src/core/components';
import RouteReschedulerForm, { RouteReschedulerFormValues } from 'src/routes/components/forms/RouteRescheduleForm';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: () => void;
  isLoadingRoutesResourceAvailability?: boolean;
  isRescheduling: boolean;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  onSubmit: (formValues: RouteReschedulerFormValues) => void;
  routes: AdvRoute[];
}

const RouteRescheduleModal = ({
  closeModal,
  isLoadingRoutesResourceAvailability,
  isRescheduling,
  isSnowPlowRoute,
  isStreetSweeperRoute,
  onSubmit,
  routes,
}: Props) => {
  const routeOptions = {
    allTargetDate: undefined,
    routes: map(routes, route => {
      return {
        ...route,
        newRouteDate: undefined,
        isChecked: false,
      };
    }),
  };

  return (
    <Modal
      title={translate('routes.rescheduleRoutes')}
      onClose={closeModal}
      padding="medium medium no medium"
      size="large"
      isLoading={isLoadingRoutesResourceAvailability || isRescheduling}
    >
      {!!size(routes) && (
        <RouteReschedulerForm
          closeModal={closeModal}
          initialValues={routeOptions}
          isSnowPlowRoute={isSnowPlowRoute}
          isStreetSweeperRoute={isStreetSweeperRoute}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoadingRoutesResourceAvailability: state.routes.routeTracker.isLoadingRoutesResourceAvailability,
  isRescheduling: state.routes.routeTracker.isRescheduling,
});

export default connect(mapStateToProps)(RouteRescheduleModal);
