import { includes } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PermissionGuard } from 'src/account/components';
import { DASHBOARD_FILTERS, DASHBOARD_REAL_TIME_INSIGHTS_FEED } from 'src/account/constants';
import { isRubiconNonTechHauler, isSmartCitySelector, vendorGusIdSelector } from 'src/account/ducks';
import { Banner } from 'src/common/components';
import { PageContent } from 'src/common/components/styled';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { resetAlternativeFleetOps, resetFleetInsights } from 'src/dashboard/ducks';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import useFitBoundsDashboardMapGL from 'src/dashboard/hooks/useFitBoundsDashboardMapGL';
import useLoadDataForSnowSweeperDashboard from 'src/dashboard/hooks/useLoadDataForSnowSweeperDashboard';
import getSnowSweeperFiltersFormInitialValues from 'src/dashboard/services/snowSweeperFiltersFormInitialValues';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { RUBICON_Z } from 'src/vendors/constants';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { breakDownDeviceRoleTypeId } from 'src/vendors/services/vendorEditorFormInitialValuesSelector';
import { SnowSweeperFiltersForm } from '../forms';
import { DashboardMapWrapper } from '../styled';
import { DashboardFilterSectionCollapseButton } from '../styled/DashboardFilterMapbox';
import { DashboardFleetInsightsMapbox, DashboardMapLoadingOverlay } from './dashboardPageSections';
import DashboardOperationsTabs from './dashboardPageSections/DashboardOperationsTabs';
import DashboardMapGL from './dashboardPageSections/mapGL/DashboardMapGL';
import { resetCityAlerts } from 'src/vendors/ducks';
import { resetGeoFences } from 'src/routes/ducks';

interface Props {
  isSnowPlow: boolean;
  isStreetSweeper: boolean;
}

const SnowSweeperDashboardPage: FC<Props> = ({ isSnowPlow, isStreetSweeper }) => {
  const dispatch = useDispatch();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const isNonTechHauler = useSelector(state =>
    isRubiconNonTechHauler(state.account.login, state.vendors.defaultVendor, state.account.createUserAccount),
  );
  const isSmartCity = useSelector(state => isSmartCitySelector(state.account.login, state.vendors.defaultVendor));
  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));
  const vendor = useSelector(state => state.vendors.vendor.vendor) || ({} as Vendor);
  const isRubiconZ = includes(breakDownDeviceRoleTypeId(vendor?.deviceRoleTypeId), RUBICON_Z);
  const snowPlowSettings = useSelector(state => state.vendors.snowPlowSettings.snowPlowSettings);
  const streetSweeperSettings = useSelector(state => state.vendors.streetSweepingSettings.streetSweepingSettings);
  const isLoadingVehiclePositions = useSelector(state => state.dashboard.vehiclesData.isLoading);
  const isLoadingGeoFences = useSelector(state => state.routes.geoFences.isLoading);
  const isLoadingCityAlerts = useSelector(state => state.vendors.cityAlerts.isLoading);
  const isLoadingFleetInsights = useSelector(state => state.dashboard.fleetInsights.isLoading);
  const isLoadingMapInsights = useSelector(state => state.dashboard.mapInsightsMapbox.isLoading);

  const { isLoadingSegments } = useSelector(state => state.dashboard.alternativeFleetOps);
  const { serviceZones } = useSelector(state => state.routes.serviceZones);
  const { groups } = useSelector(state => state.routes.groups);

  const isSubMenuHidden = !isVendorWithGusId && !isNonTechHauler;

  const initialValues = useMemo(
    () =>
      getSnowSweeperFiltersFormInitialValues(
        snowPlowSettings,
        streetSweeperSettings,
        {
          serviceZones,
          groups,
        },
        isSnowPlow,
      ),
    [snowPlowSettings, streetSweeperSettings, serviceZones, groups, isSnowPlow],
  );

  // cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(resetAlternativeFleetOps());
      dispatch(clearDashboardSelectedFeature());
      dispatch(resetCityAlerts());
      dispatch(resetFleetInsights());
      dispatch(resetGeoFences());
    };
  }, [dispatch]);

  // ================== LOAD DATA ==================
  useLoadDataForSnowSweeperDashboard(isSnowPlow ? SNOW_PLOW_ID : STREET_SWEEPER_ID);
  // ================== LOAD DATA ==================

  // ==========================
  // FIT MAP BOUNDS (VIEWPORT)
  // ==========================
  useFitBoundsDashboardMapGL(isLoadingVehiclePositions || isLoadingSegments, false, false);

  const isLoading =
    isLoadingVehiclePositions ||
    isLoadingSegments ||
    isLoadingGeoFences ||
    isLoadingCityAlerts ||
    isLoadingFleetInsights ||
    isLoadingMapInsights;

  return (
    <PageContent fluid fitToVerticalViewport isSubMenuHidden={isSubMenuHidden} margin="no" tabletMargin="no">
      {!isSmartCity && <Banner />}

      <PermissionGuard permission={DASHBOARD_FILTERS}>
        <DashboardFilterSectionCollapseButton
          isSnowSweeperDashboard
          isCollapsed={isCollapsed}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <TooltipIconButton
            tooltip={isCollapsed ? 'openPanel' : 'closePanel'}
            noButtonWrapper
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color="secondary"
            margin="no no"
          >
            <IconButtonIcon icon="arrowLeft" />
          </TooltipIconButton>
        </DashboardFilterSectionCollapseButton>

        <SnowSweeperFiltersForm
          isCollapsed={isCollapsed}
          isSnowPlow={isSnowPlow}
          isStreetSweeper={isStreetSweeper}
          initialValues={initialValues}
        />
      </PermissionGuard>
      <PermissionGuard permission={DASHBOARD_REAL_TIME_INSIGHTS_FEED}>
        <DashboardFleetInsightsMapbox />
      </PermissionGuard>

      <DashboardMapWrapper isBetaPage isSnowSweeperDashboard isCollapsed={isCollapsed}>
        <DashboardMapGL
          isRubiconZ={isRubiconZ}
          isSnowSweeperDashboard
          vehicleTypeId={isStreetSweeper ? STREET_SWEEPER_ID : isSnowPlow ? SNOW_PLOW_ID : undefined}
        />
        <DashboardMapLoadingOverlay isLoading={isLoading} />
      </DashboardMapWrapper>

      <DashboardOperationsTabs isCollapsed={isCollapsed} />
    </PageContent>
  );
};

export default SnowSweeperDashboardPage;
