import { upperFirst } from 'lodash-es';
import { camelize } from 'humps';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const getEquipmentSize = (equipmentSize: string) => parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string) =>
  `common.equipmentSizeShortCodes.x${upperFirst(
    camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
  )}`;

export const getServiceName = (equipmentSize: string, equipmentType: string, wasteMaterial: string) => {
  const equipmentTypeName = translate(createTranslationKey(equipmentType, 'common.equipmentTypeShortCodes'));
  const equipmentSizeName = translate(getEquipmentSizeTranslationKey(equipmentSize), {
    size: getEquipmentSize(equipmentSize),
  });
  const wasteMaterialName = translate(createTranslationKey(wasteMaterial, 'common.wasteTypes'));
  return `${equipmentSizeName} - ${equipmentTypeName} - ${wasteMaterialName}`;
};
