import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { INSIGHT_ICONS } from '../../../common/constants';
import { mapper } from '../../../utils/styles';
import { STATIONARY_TIME } from '../../../insights/constants';
import { timelineWidth } from '../../../routes/components/mapWithTimeline/TimelineUtils';

export const TimelineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

interface TimelineWrapperProps {
  height?: number;
  hasBorder?: boolean;
}

export const TimelineWrapper = styled.div<TimelineWrapperProps>`
  display: flex;
  width: 100%;
  height: ${p => p.height || 26}px;
  align-items: center;
  justify-content: center;

  ${props =>
    props.hasBorder &&
    css`
      border: 1px solid ${props => props.theme.gray};
    `};
`;

interface TimelineElementProps {
  type?: string;
  width?: number;
}

export const TimelineElement = styled.div<TimelineElementProps>`
  height: 100%;
  position: relative;

  ${props =>
    props.type &&
    css`
      background-color: ${mapper(
        props.type,
        {
          FirstGPSMovement: transparentize(0.5, '#fff'),
          InTransit: transparentize(0.5, '#bd10e0'),
          Brining: transparentize(0.5, '#bd10e0'),
          Sweeping: transparentize(0.5, '#bd10e0'),
          InService: transparentize(0.5, '#009688'),
          Plowing: transparentize(0.5, '#009688'),
          OperationalFacility: transparentize(0.3, '#001eff'),
          Salting: transparentize(0.1, '#e8104d'),
          DisposalFacility: transparentize(0.5, '#003147'),
          LastGPSMovement: transparentize(0.5, '#fff'),
          Breaktime: transparentize(0.5, '#f0b213'),
          OffRoute: transparentize(0.5, '#c8234e'),
          DrivingToFromFacility: transparentize(0.1, '#1abc57'),
          DrivingToDumpDebris: transparentize(0.5, '#604a12'),
          NotServicing: transparentize(0.8, '#A9A9A9'),
        },
        'small',
      )};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width}%;
    `};

  &:before {
    content: '';
    height: 33px;
  }
`;

interface TimelineBoxProps {
  hasMargin?: boolean;
  justifyContent?: string;
  margin?: string;
  marginRight?: number;
  marginTop?: number;
  type?: string;
  width?: number;
}

export const TimelineBox = styled.div<TimelineBoxProps>`
  position: relative;
  width: ${props => props.width || timelineWidth}px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin: auto;

  ${props =>
    props.hasMargin &&
    css`
      margin: 15px auto;

      ${TimelineLegend} {
        margin-top: 15px;
      }

      ${TimelineLegendItem} {
        &:before {
          margin-top: 2px;
        }
      }
    `};
`;

export const TripDetailsWrapper = styled.div<TimelineBoxProps>`
  margin: auto;
`;

export const TimelineLegend = styled.div<TimelineBoxProps>`
  width: ${props => props.width || timelineWidth}px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

export const TimelineLegendItem = styled.div<TimelineBoxProps>`
  display: flex;
  justify-content: left;
  margin-right: ${props => props.marginRight ?? 30}px;
  position: relative;
  padding-left: 14px !important;

  &:last-child {
    margin-right: 0;
  }

  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid ${props => props.theme.grayBase};
    margin-right: 7px;
    margin-top: 3px;
    position: absolute;
    top: 0;
    left: 0;

    ${props =>
      props.type === STATIONARY_TIME &&
      css`
        background-image: url(${INSIGHT_ICONS.stationary});
        background-size: contain;
        background-repeat: no-repeat;
        border: none;
      `}

    ${props =>
      props.type &&
      css`
        background-color: ${mapper(
          props.type,
          {
            FirstGPSMovement: transparentize(0.5, '#fff'),
            InTransit: transparentize(0.5, '#bd10e0'),
            Brining: transparentize(0.5, '#bd10e0'),
            Sweeping: transparentize(0.5, '#bd10e0'),
            InService: transparentize(0.5, '#009688'),
            Plowing: transparentize(0.5, '#009688'),
            OperationalFacility: transparentize(0.3, '#001eff'),
            Salting: transparentize(0.1, '#e8104d'),
            DisposalFacility: transparentize(0.5, '#003147'),
            LastGPSMovement: transparentize(0.5, '#fff'),
            StationaryTime: 'initial',
            Breaktime: transparentize(0.5, '#f0b213'),
            OffRoute: transparentize(0.5, '#c8234e'),
            DrivingToFromFacility: transparentize(0.1, '#1abc57'),
            DrivingToDumpDebris: transparentize(0.5, '#604a12'),
            NotServicing: transparentize(0.8, '#A9A9A9'),
          },
          'small',
        )};
      `};
  }
`;
