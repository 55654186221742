import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { reset } from 'redux-form';
import humps from 'humps';

import { ACCOUNT_STATUSES, CUSTOMER_TYPES, STREET } from '../../../../common/constants';
import { ActionButtonTooltip } from '../../../../core/components';
import { AppState } from '../../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import { CustomerDetailsEditorModal } from '../../modals';
import { CUSTOMERS_DETAILS_EDIT } from '../../../../account/constants';
import {
  DetailsList,
  DetailsListActionButton,
  DetailsListActions,
  DetailsListBody,
  DetailsListBodySection,
  DetailsListHeader,
  DetailsListHeaderTitle,
  DetailsListItemDescription,
  DetailsListItemTitle,
  Panel,
} from '../../../../core/components/styled';
import { DuckFunction } from '../../../../contracts/ducks';
import { getFutureAccountStatus } from 'src/customers/services/futureAccountStatusValidator';
import { loadCustomer } from 'src/customers/ducks';
import { PermissionGuard } from '../../../../account/components';
import { saveCustomer } from '../../../ducks';
import confirm from '../../../../core/services/confirm';
import createTranslationKey from '../../../../utils/services/createTranslationKey';
import translate from '../../../../core/services/translate';
import ContactsPageSection from '../ContactsPageSection';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { customerDetailsEditorModalId } from 'src/customers/components/modals/CustomerDetailsEditorModal';

interface Props {
  customer: any;
  loadCustomer: DuckFunction<typeof loadCustomer>;
  reset: (formKey: string) => void;
  saveCustomer: DuckFunction<typeof saveCustomer>;
  isBillingFeatureActive?: boolean;
}

class CustomerDetails extends PureComponent<Props> {
  readonly state = {
    isCustomerDetailsModalOpen: false,
    isCustomerContactsModalOpen: false,
    isCustomerContactsEditorModalOpen: false,
  };

  onCustomerEditorFormSubmit = async (data: any) => {
    const { saveCustomer, loadCustomer } = this.props;

    const futureAccountStatus = get(data, 'futureAccountStatus', {});
    const redirectToPage = true;

    const customer = {
      ...data,
      futureAccountStatus: getFutureAccountStatus(futureAccountStatus),
    };

    scrollToTopOfModal(customerDetailsEditorModalId);

    await saveCustomer(customer, redirectToPage)
      .then(() => {
        this.props.reset('customerLocationEditor');
        this.closeCustomerDetailsEditorModal();
        createSuccessNotification(translate('customers.alertMessages.customerSaved'));

        loadCustomer(customer.id);
      })
      .catch(e => {
        const errorMessageCode =
          e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

        createErrorNotification(
          errorMessageCode
            ? translate(createTranslationKey(errorMessageCode, 'customers.alertMessages'))
            : translate('customers.alertMessages.customerSaveError'),
        );
      });
  };

  onCancelSaveCustomerDetails = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('customerLocationEditor');
    this.closeCustomerDetailsEditorModal();
  };

  openCustomerDetailsModal = () => {
    this.setState({ isCustomerDetailsModalOpen: true });
  };

  closeCustomerDetailsEditorModal = () => {
    this.setState({ isCustomerDetailsModalOpen: false });
  };

  openCustomerContactsModal = () => {
    this.setState({ isCustomerContactsModalOpen: true });
  };

  closeCustomerContactsModal = () => {
    this.setState({ isCustomerContactsModalOpen: false });
  };

  openCustomerContactsEditorModal = () => {
    this.setState({ isCustomerContactsEditorModalOpen: true });
  };

  closeCustomerContactsEditorModal = () => {
    this.setState({ isCustomerContactsEditorModalOpen: false });
  };

  render() {
    const { isCustomerDetailsModalOpen } = this.state;
    const { customer, isBillingFeatureActive } = this.props;

    const customerDetails = [
      {
        label: 'customers.customerName',
        value: customer.name,
      },
      {
        label: 'customers.customerType',
        value: CUSTOMER_TYPES[customer.customerTypeId].name,
      },
      {
        label: 'common.phone',
        value: customer.phone || '-',
      },
      {
        label: 'customers.accountNumber',
        value: customer.accountNumber || '-',
      },
      {
        label: 'common.accountStatus',
        value:
          (!!customer.customerAccountStatusTypeId && ACCOUNT_STATUSES[customer.customerAccountStatusTypeId].name) ||
          '-',
      },
    ];

    const streetCustomerDetails = [
      {
        label: 'customers.customerName',
        value: customer.name,
      },
      {
        label: 'customers.customerType',
        value: CUSTOMER_TYPES[customer.customerTypeId].name,
      },
    ];

    return (
      <Panel>
        <DetailsList>
          <DetailsListHeader padding="small small" withBorder>
            <DetailsListHeaderTitle>{translate('customers.customerDetails')}</DetailsListHeaderTitle>
            <DetailsListActions>
              <PermissionGuard permission={CUSTOMERS_DETAILS_EDIT}>
                <DetailsListActionButton
                  id="edit-customer-details-button"
                  onClick={() => this.openCustomerDetailsModal()}
                >
                  <ActionButtonTooltip icon="edit" tooltip="editCustomerDetails" />
                </DetailsListActionButton>
              </PermissionGuard>
            </DetailsListActions>
          </DetailsListHeader>
          <DetailsListBody padding="small small" margin="small no no no">
            {(customer.customerTypeId === STREET ? streetCustomerDetails : customerDetails).map(customerDetail => (
              <DetailsListBodySection key={customerDetail.label}>
                <DetailsListItemTitle margin="no no small">{translate(customerDetail.label)}</DetailsListItemTitle>
                <DetailsListItemDescription>{customerDetail.value}</DetailsListItemDescription>
              </DetailsListBodySection>
            ))}
          </DetailsListBody>
        </DetailsList>
        {isBillingFeatureActive && (
          <ContactsPageSection
            customerId={customer.id}
            customerName={customer.name}
            title={translate('common.contact.customerContacts')}
          />
        )}
        {!!isCustomerDetailsModalOpen && (
          <CustomerDetailsEditorModal
            onCancel={this.onCancelSaveCustomerDetails}
            onSaveCustomer={this.onCustomerEditorFormSubmit}
          />
        )}
      </Panel>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  customer: state.customers.customer.customer,
  isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
});

const mapDispatchToProps = {
  loadCustomer,
  reset,
  saveCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
