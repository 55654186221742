import { identity, get, has, map, size } from 'lodash-es';
import { createSelector } from 'reselect';

import { breakDownDeviceRoleTypeId } from 'src/vendors/services/vendorEditorFormInitialValuesSelector';
import { TODAY } from 'src/core/constants';
import { TRAPEZOID } from '../constants';
import { AVAILABLE, UNAVAILABLE } from '../constants/status';

const getVehicleEditorFormInitialValues = (vehicleState: any, isCreate = false) => {
  const vehicle = isCreate ? {} : get(vehicleState, 'vehicle', {});
  const initialValues = {
    ...vehicle,
    isActive: has(vehicle, 'isActive') ? vehicle.isActive : true,
    isAvailable: vehicle.isAvailable || isCreate ? AVAILABLE : UNAVAILABLE,
    confirmationModeId: vehicle.confirmationModeId || TRAPEZOID,
    binColors: size(vehicle.binColors)
      ? map(vehicle.binColors, binColor => binColor.id)
      : map(vehicleState.binColors, binColor => binColor.id),
    deviceRoles: vehicle.deviceRoleTypeId ? breakDownDeviceRoleTypeId(vehicle.deviceRoleTypeId) : undefined,
    vehicleUnavailableEndDate: vehicle.vehicleUnavailable?.endDate,
    vehicleUnavailableInitialValues: vehicle.vehicleUnavailable,
    vehicleUnavailableStartDate: vehicle.vehicleUnavailable?.startDate || TODAY,
  };

  return initialValues;
};

const vehicleEditorFormInitialValuesSelector = createSelector(getVehicleEditorFormInitialValues, identity);

export default vehicleEditorFormInitialValuesSelector;
