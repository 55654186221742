import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const EXTRA_PICKUP = 9;
export const EMPTY_AND_RETURN = 4;
export const DELIVERY = 3;
export const MISSED_PICKUP = 16;
export const REMOVAL = 7;
export const EMPTY_AND_FINAL = 17;

export const OPEN_DISPATCHES_SERVICES_LIST = [
  { id: DELIVERY, name: translate('opportunity.opportunities.delivery') },
  { id: EXTRA_PICKUP, name: translate('opportunity.opportunities.extraPickup') },
  { id: EMPTY_AND_FINAL, name: translate('opportunity.opportunities.emptyAndFinal') },
  { id: EMPTY_AND_RETURN, name: translate('routes.pickupTypes.emptyAndReturn') },
  { id: MISSED_PICKUP, name: translate('opportunity.opportunities.missedPickup') },
  { id: REMOVAL, name: translate('opportunity.opportunities.removal') },
];

export const OPEN_DISPATCHES_SERVICES = mapKeys(OPEN_DISPATCHES_SERVICES_LIST, 'id');
