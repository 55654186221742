import { useDispatch } from 'react-redux';

import {
  loadWaterFill,
  loadStreetSweeperUnitOfMeasureValuesWaterFill,
  loadStreetSweeperMaterialTypeValuesWaterFill,
} from 'src/routes/ducks/waterFills';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import WaterFillEditorModal from './WaterFillEditorModal';

type Props = {
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  waterFillId?: number;
};

export default function WaterFillEditorModalResolver(props: Props) {
  const dispatch = useDispatch();

  const loadDependencies = () => {
    const dependencies = [
      loadStreetSweeperUnitOfMeasureValuesWaterFill()(dispatch),
      loadStreetSweeperMaterialTypeValuesWaterFill()(dispatch),
    ];
    if (props.waterFillId) dependencies.push(loadWaterFill(props.waterFillId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={WaterFillEditorModal}
      successProps={{ ...props }}
    />
  );
}
