import { http } from '../../core/services/http';
import { DataRetention } from '../interfaces/DataRetention';

export const loadDataRetention = () =>
  http.get('/subscriptionPackage/dataRetentionConfiguration').then(response => response.data);

export const loadVendorDataRetention = (vendorId?: number) =>
  http.get('/subscriptionPackage/dataRetentionConfiguration', { params: { vendorId } }).then(response => response.data);

export const saveDataRetention = (dataRetention?: DataRetention) =>
  http.post('/subscriptionPackage/dataRetentionConfiguration', dataRetention).then(response => response.data);
