import React from 'react';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
} from 'src/common/components/map/MapGLPopup';
import { Box } from 'src/core/components/styled/Box';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  selectedFeature: any;
  onClose(): void;
}

const RateBuilderMapGLPopup: React.FC<Props> = ({ selectedFeature, onClose }) => {
  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedFeature.displayLatitude}
      longitude={selectedFeature.displayLongitude}
      onClose={onClose}
    >
      <MapGLPopup maxHeight={400}>
        <MapGLPopupTitle
          title={translate('customers.serviceContract')}
          afterTitleContent={
            <Box ml={8} display="flex" alignItems="center">
              <span>{selectedFeature.serviceContractId}</span>
            </Box>
          }
        />

        <MapGLPopupContent>
          {!!selectedFeature.customerName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.customer')}
              value={selectedFeature.customerName}
            />
          )}

          {!!selectedFeature.locationName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.locationName')}
              value={selectedFeature.locationName}
            />
          )}

          {!!selectedFeature.locationAddress && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.address')}
              value={selectedFeature.locationAddress}
            />
          )}

          {!!selectedFeature.equipmentType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.equipmentType')}
              value={selectedFeature.equipmentType.name}
            />
          )}

          {!!selectedFeature.equipmentSize && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.equipmentSize')}
              value={selectedFeature.equipmentSize.name}
            />
          )}

          {!!selectedFeature.numberOfContainers && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.numberOfContainers')}
              value={selectedFeature.numberOfContainers.toString()}
            />
          )}

          {!!selectedFeature.pickupFrequencyType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.pickupFrequency')}
              value={selectedFeature.pickupFrequencyType.name}
            />
          )}

          {!!selectedFeature.serviceType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.serviceType')}
              value={selectedFeature.serviceType.name}
            />
          )}

          {!!selectedFeature.wasteMaterialType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.wasteType')}
              value={selectedFeature.wasteMaterialType.name}
            />
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default RateBuilderMapGLPopup;
