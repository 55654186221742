import { Layer } from 'react-map-gl';
import { memo } from 'react';

import { CUSTOMER_LOCATIONS_CLUSTERS_SOURCE } from './CustomerLocationsClustersSource';

export const CUSTOMER_LOCATIONS_LAYER = 'customerLocationsLayer';
export const CUSTOMER_LOCATIONS_NUMBERS_LAYER = 'customerLocationsNumbersLayer';

const CustomerLocationsGL = memo(() => {
  return (
    <>
      <Layer
        id={CUSTOMER_LOCATIONS_LAYER}
        type="circle"
        source={CUSTOMER_LOCATIONS_CLUSTERS_SOURCE}
        filter={['!', ['has', 'point_count']]}
        paint={{
          'circle-color': '#009688',
          'circle-radius': 12,
        }}
      />

      <Layer
        id={CUSTOMER_LOCATIONS_NUMBERS_LAYER}
        source={CUSTOMER_LOCATIONS_CLUSTERS_SOURCE}
        type="symbol"
        paint={{
          'text-color': '#fff',
          'text-halo-width': 0.3,
          'text-halo-color': '#fff',
        }}
        layout={{
          'text-field': ['get', 'orderNo'],
          'text-size': 12,
        }}
      />
    </>
  );
});

export default CustomerLocationsGL;
