import React, { PureComponent } from 'react';

import { debounce } from 'lodash-es';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch } from '../../../core/components';
import { ROUTE, SNOW_PLOW } from '../../../fleet/constants';
import { VehicleTypeForVendorMultiSelect } from '../../../fleet/components';
import translate from '../../../core/services/translate';

interface ComponentProps {}

interface Props extends ComponentProps {
  onRouteTemplateNameChange: (routeTemplateName: string) => void;
  onVehicleTypeChange: (vehicleTypeIds: any[]) => void;
}

class RouteTemplatesFormForHolidayPlanner extends PureComponent<Props & InjectedFormProps<any, Props>> {
  onRouteTemplateNameChange = debounce((event, routeTemplateName) => {
    const { onRouteTemplateNameChange } = this.props;
    onRouteTemplateNameChange(routeTemplateName);
  }, 800);

  onVehicleTypeChange = (event: any, vehicleTypeIds: any[]) => {
    const { onVehicleTypeChange } = this.props;
    onVehicleTypeChange(vehicleTypeIds);
  };

  render() {
    return (
      <form>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="8/12">
              <Field name="routeTemplateName" component={PanelSearch} onChange={this.onRouteTemplateNameChange} />
            </GridColumn>
            <GridColumn size="4/12">
              <Field
                component={VehicleTypeForVendorMultiSelect as any}
                excludeVehicleTypes={[SNOW_PLOW]}
                multiSelectProps={{ margin: 'no' }}
                name="vehicleTypeIds"
                onChange={this.onVehicleTypeChange}
                placeholder={translate('vehicles.allVehicleTypes')}
                vehicleRoleTypeId={ROUTE}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

export default reduxForm<{}, Props>({
  enableReinitialize: true,
  form: 'routeTemplatesForHolidayPlanner',
})(RouteTemplatesFormForHolidayPlanner);
