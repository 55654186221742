import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { VehicleType } from '../interfaces/VehicleTypes';
import { vehicleTypesForRoleTypeSelector } from '../ducks';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  excludeVehicleTypes: string[];
  vehicleRoleTypeId: string;
  vehicleTypes: VehicleType[];
}

class VehicleTypeDropdown extends PureComponent<Props> {
  onChange = (_: unknown, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      vehicleTypes,
      dropdownProps,
    } = this.props;

    const vehicleTypesOptions = vehicleTypes.map(vehicleType => ({
      label: vehicleType.name,
      value: vehicleType.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicleType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicleType') : undefined),
          options: vehicleTypesOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  vehicleTypes:
    vehicleTypesForRoleTypeSelector(
      state.fleet.vehicleTypes,
      ownProps.vehicleRoleTypeId,
      ownProps.excludeVehicleTypes,
    ) || [],
});

export default connect(mapStateToProps)(VehicleTypeDropdown);
