import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { find, findIndex, identity } from 'lodash-es';
import { push } from 'connected-react-router';
import { ThunkDispatch } from 'redux-thunk';
import * as H from 'history';
import update from 'immutability-helper';

import { BulkyPickupByLocation, CustomerLocation } from 'src/customers/interfaces/Customers';
import {
  createCustomerLocation,
  deleteCustomerLocation as doDeleteCustomerLocation,
  loadCustomerLocations as doLoadCustomerLocations,
  saveGeocode as doSaveGeocode,
  resetBulkyPickUp as doResetBulkyPickUp,
  loadBulkyPickUpByLocation as doLoadBulkyPickUpByLocation,
  updateCustomerLocation,
} from 'src/customers/services/locations';
import { transformLoadLocations } from 'src/customers/services/transformLocations';
import { getQueryParams } from 'src/utils/services/queryParams';

// Actions
const START_LOAD = 'customers/customerLocations/START_LOAD';
const COMPLETE_LOAD = 'customers/customerLocations/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/customerLocations/FAIL_LOAD';
const START_LOAD_FOR_WORKFLOW = 'customers/customerLocations/START_LOAD_FOR_WORKFLOW';
const COMPLETE_LOAD_FOR_WORKFLOW = 'customers/customerLocations/COMPLETE_LOAD_FOR_WORKFLOW';
const FAIL_LOAD_FOR_WORKFLOW = 'customers/customerLocations/FAIL_LOAD_FOR_WORKFLOW';
const START_SAVE = 'customers/customerLocations/START_SAVE';
export const COMPLETE_SAVE = 'customers/customerLocations/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/customerLocations/FAIL_SAVE';
const START_DELETE = 'customers/customerLocations/START_DELETE';
export const COMPLETE_DELETE = 'customers/customerLocations/COMPLETE_DELETE';
const FAIL_DELETE = 'customers/customerLocations/FAIL_DELETE';
export const SEARCH_LOCATIONS = 'customers/customerLocations/SEARCH_LOCATIONS';
const RESET = 'customers/customerLocations/RESET';
const START_GEOCODE = 'customers/locations/START_GEOCODE';
const COMPLETE_GEOCODE = 'customers/locations/COMPLETE_GEOCODE';
const FAIL_GEOCODE = 'customers/locations/FAIL_GEOCODE';
const START_RESET_BULKY_PICKUP = 'customers/locations/START_RESET_BULKY_PICKUP';
const COMPLETE_RESET_BULKY_PICKUP = 'customers/locations/COMPLETE_RESET_BULKY_PICKUP';
const FAIL_RESET_BULKY_PICKUP = 'customers/locations/FAIL_RESET_BULKY_PICKUP';
const START_LOAD_BULKY_PICKUP_BY_LOCATION = 'customers/locations/START_LOAD_BULKY_PICKUP_BY_LOCATION';
const COMPLETE_LOAD_BULKY_PICKUP_BY_LOCATION = 'customers/locations/COMPLETE_LOAD_BULKY_PICKUP_BY_LOCATION';
const FAIL_LOAD_BULKY_PICKUP_BY_LOCATION = 'customers/locations/FAIL_LOAD_BULKY_PICKUP_BY_LOCATION';

export interface State {
  bulkyPickupByLocation: BulkyPickupByLocation;
  isDeleting: boolean;
  isLoading: boolean;
  isLoadingBulkyPickupByLocation: boolean;
  isLoadingForWorkflow: boolean;
  isResetingBulkyPickUp: boolean;
  isSaving: boolean;
  locations?: CustomerLocation[];
  locationsForWorkflow?: CustomerLocation[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  bulkyPickupByLocation: {} as BulkyPickupByLocation,
  isDeleting: false,
  isLoading: false,
  isLoadingBulkyPickupByLocation: false,
  isLoadingForWorkflow: false,
  isResetingBulkyPickUp: false,
  isSaving: false,
  locations: [],
  locationsForWorkflow: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_GEOCODE:
      return {
        ...state,
        isLoading: true,
      };
    case COMPLETE_GEOCODE:
      return {
        ...state,
        isLoading: false,
        data: action.location,
      };
    case FAIL_GEOCODE:
      return {
        ...state,
        isLoading: false,
      };
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locations: action.locations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locations: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          locations: action.locations,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const index = findIndex(state.locations, { id: action.locationId });
      return update(state, {
        locations: { $splice: [[index, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_LOAD_FOR_WORKFLOW:
      return update(state, {
        $merge: {
          isLoadingForWorkflow: true,
        },
      });

    case COMPLETE_LOAD_FOR_WORKFLOW:
      return update(state, {
        $merge: {
          isLoadingForWorkflow: false,
          locationsForWorkflow: action.locations,
        },
      });

    case FAIL_LOAD_FOR_WORKFLOW:
      return update(state, {
        $merge: {
          isLoadingForWorkflow: false,
          locationsForWorkflow: [],
        },
      });

    case START_RESET_BULKY_PICKUP:
      return {
        ...state,
        isResetingBulkyPickUp: true,
      };
    case COMPLETE_RESET_BULKY_PICKUP:
      return {
        ...state,
        isResetingBulkyPickUp: false,
      };
    case FAIL_RESET_BULKY_PICKUP:
      return {
        ...state,
        isResetingBulkyPickUp: false,
      };

    case START_LOAD_BULKY_PICKUP_BY_LOCATION:
      return update(state, {
        $merge: {
          isLoadingBulkyPickupByLocation: true,
        },
      });
    case COMPLETE_LOAD_BULKY_PICKUP_BY_LOCATION:
      return update(state, {
        $merge: {
          isLoadingBulkyPickupByLocation: false,
          bulkyPickupByLocation: action.bulkyPickupByLocation,
        },
      });
    case FAIL_LOAD_BULKY_PICKUP_BY_LOCATION:
      return update(state, {
        $merge: {
          isLoadingBulkyPickupByLocation: false,
          bulkyPickupByLocation: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startGeocode = () => ({
  type: START_GEOCODE,
});

const completeGeocode = (location: CustomerLocation) => ({
  type: COMPLETE_GEOCODE,
  location,
});

const failGeocode = () => ({
  type: FAIL_GEOCODE,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (locations: CustomerLocation[]) => ({
  type: COMPLETE_LOAD,
  locations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (locations: CustomerLocation[], saveType: 'Update' | 'Create') => ({
  type: COMPLETE_SAVE,
  locations,
  saveType,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (locationId: number) => ({
  type: COMPLETE_DELETE,
  locationId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startLoadForWorkflow = () => ({
  type: START_LOAD_FOR_WORKFLOW,
});

const completeLoadForWorkflow = (locations: CustomerLocation[]) => ({
  type: COMPLETE_LOAD_FOR_WORKFLOW,
  locations,
});

const failLoadForWorkflow = () => ({
  type: FAIL_LOAD_FOR_WORKFLOW,
});

const startResetBulkyPickUp = () => ({
  type: START_RESET_BULKY_PICKUP,
});

const completeResetBulkyPickUp = () => ({
  type: COMPLETE_RESET_BULKY_PICKUP,
});

const failResetBulkyPickUp = () => ({
  type: FAIL_RESET_BULKY_PICKUP,
});

const startLoadBulkyPickUpByLocation = () => ({
  type: START_LOAD_BULKY_PICKUP_BY_LOCATION,
});

const completeLoadBulkyPickUpByLocation = (bulkyPickupByLocation: BulkyPickupByLocation) => ({
  type: COMPLETE_LOAD_BULKY_PICKUP_BY_LOCATION,
  bulkyPickupByLocation,
});

const failLoadBulkyPickUpByLocation = () => ({
  type: FAIL_LOAD_BULKY_PICKUP_BY_LOCATION,
});

export const loadCustomerLocations = (customerId: number, preventStartLoad?: boolean) => (dispatch: Dispatch) => {
  if (!preventStartLoad) {
    dispatch(startLoad());
  }
  const loadCustomerLocationsPromise = doLoadCustomerLocations(customerId);
  loadCustomerLocationsPromise.then(locations => dispatch(completeLoad(locations))).catch(() => dispatch(failLoad()));
  return loadCustomerLocationsPromise;
};

export const loadCustomerLocationsForWorkflow = (customerId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadForWorkflow());
  const loadCustomerLocationsPromise = doLoadCustomerLocations(customerId);
  loadCustomerLocationsPromise
    .then(locations => dispatch(completeLoadForWorkflow(locations)))
    .catch(() => dispatch(failLoadForWorkflow()));
  return loadCustomerLocationsPromise;
};

export const saveCustomerLocation =
  (location: CustomerLocation, searchLocation: H.Location<any>, redirectToPage?: boolean) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveLocationPromise = location.id ? updateCustomerLocation(location) : createCustomerLocation(location);
    const saveType = location.id ? 'Update' : 'Create';

    saveLocationPromise
      .then(res => {
        dispatch(completeSave(transformLoadLocations(res.data), saveType));

        if (!location.id) {
          const maxLocationId = res.data.reduce(
            (max: number, location: CustomerLocation) => (location.id > max ? location.id : max),
            0,
          );
          const { search } = searchLocation;
          const currentPage = getQueryParams(search);
          const currentPageWithStatus = { ...currentPage, locationId: maxLocationId };
          const params = Object.entries(currentPageWithStatus)
            .map(([key, val]) => `${key}=${val}`)
            .join('&');
          const searchParams = params.toString();

          if (redirectToPage) dispatch(push(`/customers/customers/${location.customerId}/services?${searchParams}`));
        }
      })
      .catch(() => dispatch(failSave()));

    return saveLocationPromise;
  };

export const deleteCustomerLocation =
  (locationId: number, shouldRecreateRoutes?: boolean, moveContainersToFacilityId?: number) => (dispatch: Dispatch) => {
    dispatch(startDelete());
    const deleteCustomerLocationPromise = doDeleteCustomerLocation(
      locationId,
      shouldRecreateRoutes,
      moveContainersToFacilityId,
    );
    deleteCustomerLocationPromise.then(() => dispatch(completeDelete(locationId))).catch(() => dispatch(failDelete()));
    return deleteCustomerLocationPromise;
  };

export const reverseGeocode = (latitude: number, longitude: number) => (dispatch: Dispatch) => {
  dispatch(startGeocode());
  const reverseGeocodePromise = doSaveGeocode(latitude, longitude);
  reverseGeocodePromise.then(res => dispatch(completeGeocode(res))).catch(() => dispatch(failGeocode()));
  return reverseGeocodePromise;
};

export const resetBulkyPickUp = (locationId: number) => (dispatch: Dispatch) => {
  dispatch(startResetBulkyPickUp());
  const resetBulkyPickUpPromise = doResetBulkyPickUp(locationId);
  resetBulkyPickUpPromise
    .then(() => dispatch(completeResetBulkyPickUp()))
    .catch(() => dispatch(failResetBulkyPickUp()));
  return resetBulkyPickUpPromise;
};

export const loadBulkyPickUpByLocation = (locationId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadBulkyPickUpByLocation());
  const loadBulkyPickUpByLocationPromise = doLoadBulkyPickUpByLocation(locationId);
  loadBulkyPickUpByLocationPromise
    .then(data => dispatch(completeLoadBulkyPickUpByLocation(data)))
    .catch(() => dispatch(failLoadBulkyPickUpByLocation()));
  return loadBulkyPickUpByLocationPromise;
};

export const resetCustomerLocations = () => ({
  type: RESET,
});

export const searchLocations = () => ({
  type: SEARCH_LOCATIONS,
});

// Selectors
const getCustomerLocationById = (customerLocationsState: State, customerLocationId: number) =>
  find(customerLocationsState.locations, { id: customerLocationId });
export const customerLocationByIdSelector = createSelector(getCustomerLocationById, identity);
