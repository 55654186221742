import { filter, map, size } from 'lodash-es';
import { ChangeEvent, FC, useMemo } from 'react';
import { WrappedFieldInputProps } from 'redux-form';

import { TypedField } from 'src/core/components';
import MultiSelect, { MultiSelectProps } from 'src/core/components/MultiSelect';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';
import { DropDownFieldProps } from './DropDownFieldProps';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('common.allJobPriorities')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('common.noJobPriority')
    : translate('common.xJobPrioritiesSelected', { count: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludeJobPriorityIds?: number[];
  includeNoneOption?: boolean;
}

const JobPriorityTypesMultiSelect: FC<Props> = ({
  input,
  multiSelectProps,
  excludeJobPriorityIds,
  label,
  withLabel,
  placeholder,
  withPlaceholder,
  includeNoneOption,
  ...rest
}) => {
  const priorityTypes = useSelector(state => state.common.jobPriorityTypes.jobPriorityTypes);

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    input.onChange(value);
    multiSelectProps?.onChange && multiSelectProps.onChange(value);
  };

  const options = useMemo(() => {
    const filtered = map(
      filter(priorityTypes, jobPriority => !!jobPriority.id && !excludeJobPriorityIds?.includes(jobPriority.id)),
      job => ({ label: job.name, value: job.id }),
    );
    if (includeNoneOption) {
      filtered.unshift({
        label: translate('common.noJobPriority'),
        value: NONE_ID,
      });
    }
    return filtered;
  }, [excludeJobPriorityIds, priorityTypes, includeNoneOption]);

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={handleChange}
      {...rest}
      props={{
        label: label || (withLabel ? translate('common.jobPriority') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('common.jobPriority') : undefined),
        options: options,
        canCheckAll: multiSelectProps?.canCheckAll || false,
        isClearable: true,
        normalizeValues: Number,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
};

export default JobPriorityTypesMultiSelect;
