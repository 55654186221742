import { http } from '../../core/services/http';

import { transformLoadMaterials } from './transformMaterials';
import { Material } from '../interfaces/Materials';

export const loadMaterials = (haulerId: string, divisionId?: string) =>
  http.get(`/haulerprofile/${haulerId}/materials`).then(response => transformLoadMaterials(response.data));

export const saveMaterial = (haulerId: string, material: Material) =>
  http.post(`/haulerprofile/${haulerId}/material`, material);

export const updateMaterial = (haulerId: string, material: Material) =>
  http.put(`/haulerprofile/${haulerId}/material/${material.id}`, material);
