import React, { ChangeEvent, PureComponent } from 'react';

import { map } from 'lodash-es';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { ROUTE_TEMPLATE_STATUSES } from '../constants';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {}

class RouteTemplateStatusDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;

    const routeTemplateStatusOptions = map(ROUTE_TEMPLATE_STATUSES, routeStatus => ({
      label: routeStatus.name,
      value: routeStatus.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('routes.routeStatus') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('routes.routeStatus') : undefined),
          options: routeTemplateStatusOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default RouteTemplateStatusDropdown;
