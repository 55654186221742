import { http } from 'src/core/services/http';
import { TemporaryAddress } from '../interfaces/Customers';

export const saveTemporaryAddress = (address: TemporaryAddress) =>
  http
    .post('/locations/geolocateAddress', address)
    .then(res => res.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export default saveTemporaryAddress;
