import React, { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Table } from '../../../core/components';
import { ButtonLink, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { loadRouteImports, resetRouteImports } from '../../ducks';
import { RouteImportsForm } from '../forms';
import { RouteImportsPageTableRow } from '.';
import { AppState } from '../../../store';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';

interface Props extends RouteComponentProps {
  isLoading: boolean;
  routeImports: any;
  loadRouteImports: DuckFunction<typeof loadRouteImports>;
  resetRouteImports: DuckAction<typeof resetRouteImports>;
  push: any;
}

interface FormValues {
  vendorId: number;
  fileProcessingStatusTypeId: number;
  startDate: string;
  endDate: string;
}

class RouteImportsPage extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { loadRouteImports, location } = this.props;
    if (prevProps.location.search !== location.search) {
      const { vendorId, fileProcessingStatusTypeId, startDate, endDate } = getQueryParams(location.search);
      loadRouteImports(vendorId, fileProcessingStatusTypeId, startDate, endDate);
    }
  }

  componentWillUnmount() {
    this.props.resetRouteImports();
  }

  onSubmit = ({ vendorId, fileProcessingStatusTypeId, startDate, endDate }: FormValues) => {
    const { push, location } = this.props;
    push(createUrl(location.pathname, location.search, { vendorId, fileProcessingStatusTypeId, startDate, endDate }));
  };

  routeImportTableCells = [
    { name: 'vendorName', label: translate('common.vendorName'), width: '25%', sortable: true },
    { name: 'description', label: translate('common.description'), width: '20%', sortable: true },
    { name: 'createdOn', label: translate('common.date'), width: '15%', sortable: true },
    {
      name: 'completedAndFailedCount',
      label: `${translate('common.completed')} / ${translate('common.failed')}`,
      width: '15%',
    },
    { name: 'totalCount', label: translate('common.total'), width: '10%', sortable: true },
    { name: 'fileProcessingStatusName', label: translate('common.status'), width: '15%', sortable: true },
  ];

  render() {
    const { routeImports, isLoading, push } = this.props;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('routeImport.routeImport')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>

          <PageActions>
            <ButtonLink to="/route-import/create" color="primary">
              {translate('routeImport.createRouteImport')}
            </ButtonLink>
          </PageActions>
        </PageHeader>

        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <RouteImportsForm onSubmit={this.onSubmit} />
            <PanelSection>
              {!!size(routeImports) && (
                <Table
                  cells={this.routeImportTableCells}
                  rows={routeImports}
                  rowComponent={RouteImportsPageTableRow}
                  rowProps={{ push }}
                  withClickableRows
                />
              )}

              {!size(routeImports) && <Message padding="sMedium">{translate('routeImport.noRouteImports')}</Message>}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.routeImport.routeImports.isLoading,
  routeImports: state.routeImport.routeImports.routeImports,
});

const mapDispatchToProps = {
  loadRouteImports,
  resetRouteImports,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteImportsPage));
