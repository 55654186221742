import React from 'react';
import moment from 'moment';
import { Field } from 'redux-form';

import { Checkbox, DatePicker } from 'src/core/components';
import { date, humanizeDate } from 'src/utils/services/formatter';
import { FormError, Label, TableCell, TableRow, Text } from 'src/core/components/styled';
import { isDateValidValidator } from 'src/utils/services/validator';
import { LABEL_COLORS } from 'src/routes/constants';
import { OverrideDate } from '..';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { VerticalAlign } from 'src/core/components/styled/DatePicker';
import translate from 'src/core/services/translate';

export interface RouteReschedulerRow {
  configuration: string;
  datePickerVAlign?: VerticalAlign;
  driverName: string;
  hasDuplicateError: boolean;
  isChecked: boolean;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
  materialType: string;
  newRouteDate?: string;
  routeDate: Date | string; // BaseRoute
  routeId: number;
  routeName?: string;
  routeRunDate?: string;
  routeStatusName: string;
  routeStatusType?: TechnicalType;
  routeStatusTypeId: number;
  segmentsCount?: number;
  totalStops: number;
  tripEndTime?: Date | string;
  tripStartTime?: Date | string;
  tripTime: number;
  vehicleName?: string;
  vehicleTypeName: string;
}

const RouteReschedulerTableRow: React.FC<RouteReschedulerRow> = ({
  configuration,
  datePickerVAlign,
  driverName,
  hasDuplicateError,
  isSnowPlowRoute,
  isStreetSweeperRoute,
  materialType,
  routeDate,
  routeId,
  routeName,
  routeRunDate,
  routeStatusName,
  routeStatusType,
  routeStatusTypeId,
  segmentsCount,
  totalStops,
  tripEndTime,
  tripStartTime,
  tripTime,
  vehicleName,
  vehicleTypeName,
}) => (
  <TableRow
    key={`routeScheduler-${routeId}`}
    id={`routeScheduler-${routeId}-button`}
    height={TABLE_ROW_HEIGHT_LARGE}
    align="flex-end"
  >
    <TableCell id={`routeScheduler-${routeId}-route-name-cell`} width="17%" vertical align="center">
      <Text title={routeName} weight="strong" margin="no no xxSmall" singleLine>
        <strong>{routeName}</strong>
      </Text>
      {materialType && (
        <Text title={materialType} weight="light" margin="no no xxSmall" singleLine>
          {materialType}
        </Text>
      )}
      {driverName && (
        <Text title={driverName} weight="light" margin="no no xxSmall" singleLine>
          {driverName}
        </Text>
      )}
    </TableCell>
    <TableCell id={`routeScheduler-${routeId}-vehicle-name-cell`} width="15%" vertical align="center">
      {vehicleName && (
        <Text title={vehicleName} weight="strong" margin="no no xxSmall" singleLine>
          {vehicleName}
        </Text>
      )}
      <Text title={vehicleTypeName} weight="light" margin="no no xxSmall" singleLine>
        {isSnowPlowRoute
          ? VEHICLE_TYPE_IDS[SNOW_PLOW_ID].name
          : isStreetSweeperRoute
          ? VEHICLE_TYPE_IDS[STREET_SWEEPER_ID].name
          : vehicleTypeName}
      </Text>
    </TableCell>
    <TableCell id={`routeScheduler-${routeId}-total-stops-cell`} width="10%" whiteSpace>
      {(isSnowPlowRoute || isStreetSweeperRoute ? segmentsCount : totalStops) || 0}{' '}
    </TableCell>
    <TableCell id={`routeScheduler-${routeId}-status-cell`} width="15%">
      <Label color={LABEL_COLORS[routeStatusTypeId]}>{routeStatusName}</Label>
    </TableCell>
    <TableCell id={`routeScheduler-${routeId}-current-date-cell`} width="15%" whiteSpace>
      {humanizeDate(routeDate)}
      {routeRunDate && <OverrideDate routeRunDate={date(routeRunDate)} />}
    </TableCell>
    <TableCell width="5%" onClick={e => e.stopPropagation()}>
      <Field name={`${configuration}.isChecked`} component={Checkbox} props={{ id: `route-${routeId}-checkbox` }} />
    </TableCell>
    <TableCell vertical id={`routeScheduler-${routeId}-target-date-cell`} width="23%" whiteSpace>
      <Field
        name={`${configuration}.newRouteDate`}
        component={DatePicker}
        horizontalAlign="left"
        verticalAlign={datePickerVAlign}
        disabledDays={[
          {
            before: moment.max(moment(routeDate).subtract(30, 'days'), moment()).toDate(),
            after: moment(routeDate).add(30, 'days').toDate(),
          },
          moment(routeDate).toDate(),
        ]}
        margin="no"
        label={translate('common.targetDate')}
        isClearable
        validate={[isDateValidValidator]}
        shouldNotLoadDataRetention={true}
      />
      {hasDuplicateError && (
        <div>
          <FormError>{translate('routes.rescheduleErrors.duplicateRoute')}</FormError>
        </div>
      )}
    </TableCell>
  </TableRow>
);

export default RouteReschedulerTableRow;
