import Cookie from 'js-cookie';
import { get } from 'lodash-es';
import { User } from '../ducks/login';

export const SESSION_COOKIE_KEY = 'session';

export const createSession = (authToken: string, user: any) => {
  Cookie.set(SESSION_COOKIE_KEY, JSON.stringify({ authToken, user }));
};

export const getSessionValue = (key: string, defaultValue?: any) =>
  get(JSON.parse(Cookie.get(SESSION_COOKIE_KEY) || '{}'), key, defaultValue);

export const updateSessionUser = (user: User) => {
  const prevSession = JSON.parse(Cookie.get(SESSION_COOKIE_KEY) || '{}');
  Cookie.set(
    SESSION_COOKIE_KEY,
    JSON.stringify({ ...prevSession, user: { ...prevSession.user, email: user.email, name: user.name } }),
  );
};

export const destroySession = () => {
  Cookie.remove(SESSION_COOKIE_KEY);
};
