import React, { ChangeEvent } from 'react';

import { connect } from 'react-redux';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { Dropdown, TypedField } from '../../core/components';
import translate from '../../core/services/translate';
import { AppState } from '../../store';
import { useSelector } from 'src/core/hooks/useSelector';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';

interface MonthlyServicesWeeklyFrequencyDropdownProps extends DropDownFieldProps {
  monthlyServicesWeeklyFrequency: TechnicalType[];
}

const MonthlyServicesWeeklyFrequencyDropdown = (props: MonthlyServicesWeeklyFrequencyDropdownProps) => {
  const {
    input: { name, onChange: onInputChange },
    placeholder,
    withPlaceholder,
    label,
    withLabel,
    monthlyServicesWeeklyFrequency,
    dropdownProps,
  } = props;

  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));

  const onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    onInputChange(value);
  };

  const monthlyServicesWeeklyFrequencyOptions = monthlyServicesWeeklyFrequency.map(option => ({
    label: option.name,
    value: option.id,
  }));

  //eliminate first 3 options if Billing Module is Active
  const dropdownOptions = isBillingFeatureActive
    ? monthlyServicesWeeklyFrequencyOptions.slice(3)
    : monthlyServicesWeeklyFrequencyOptions;

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChange}
      props={{
        label: label || (withLabel ? translate('customers.dayOfMonth') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('customers.dayOfMonth') : undefined),
        options: dropdownOptions,
        ...dropdownProps,
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  monthlyServicesWeeklyFrequency: state.common.pickupFrequencyTypes.monthlyServicesWeeklyFrequency || [],
});

export default connect(mapStateToProps)(MonthlyServicesWeeklyFrequencyDropdown);
