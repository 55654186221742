import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const CONTAINER_STATUS_AVAILABLE = 1;
export const CONTAINER_STATUS_IN_USE = 2;
export const CONTAINER_STATUS_UNAVAILABLE = 3;

export const CONTAINER_STATUSES = mapKeys(
  [
    { id: CONTAINER_STATUS_AVAILABLE, name: translate('containers.available'), technicalName: 'Available' },
    { id: CONTAINER_STATUS_IN_USE, name: translate('containers.inUse'), technicalName: 'InUse' },
    { id: CONTAINER_STATUS_UNAVAILABLE, name: translate('containers.unavailable'), technicalName: 'Unavailable' },
  ],
  'id',
);
