import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';
import { DropDownFieldProps } from './DropDownFieldProps';
import { AppState } from '../../store';

interface PickupFrequencyType {
  name: string;
  id: number;
}

interface Props extends DropDownFieldProps {
  pickupFrequencyTypes: PickupFrequencyType[];
}

class PickupFrequencyTypeDropdown extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      pickupFrequencyTypes,
      dropdownProps,
    } = this.props;

    const pickupFrequencyTypeOptions = pickupFrequencyTypes.map(pickupFrequencyType => ({
      label: pickupFrequencyType.name,
      value: pickupFrequencyType.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.pickupFrequencyType') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.pickupFrequencyType') : undefined)}
        component={Dropdown as any}
        options={pickupFrequencyTypeOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  pickupFrequencyTypes: state.common.pickupFrequencyTypes.pickupFrequencyTypes as any as PickupFrequencyType[],
});

export default connect(mapStateToProps)(PickupFrequencyTypeDropdown);
