import React, { memo } from 'react';
import DoughnutChart from 'src/core/components/DoughnutChart';
import { theme } from 'src/core/styles';
import { Grid, Text } from 'src/core/components/styled';
import { getChartData } from './getChartData';
import { ContainerStatisticsElement } from 'src/finance/components/styled/Statistics';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
const chartColors = [
  '#9FC5FA',
  theme.brandGreenDark,
  '#BD10E0',
  '#FFCA00',
  theme.brandPrimary,
  theme.brandAlert,
  theme.brandInfoLight,
  theme.brandWarning,
  theme.brandInfo,
  theme.brandDefault,
  theme.brandGreenDark,
  theme.brandPartSuccess,
  theme.markerBackground,
  theme.brandSuccess,
  '#7300ff',
  '#42b274',
  '#ff00a8',
  '#89b73f',
  '#ff0058',
  '#00a599',
  '#d6b000',
  '#ffa600',
];
const RateBuilderCharts: React.FC = () => {
  const serviceContractsData = useSelector(state => state.customers.rateBuilder.serviceContracts);

  const equipmentTypeChartData = getChartData(
    serviceContractsData.equipmentTypeChart?.reduce<Record<string, number>>((acc, item) => {
      acc[item.label.name] = item.value;
      return acc;
    }, {}),
    'common.equipmentTypes', // Translation prefix for Equipment Size
    false,
  );

  const isEquipmentSize = true;
  const equipmentSizeChartData = getChartData(
    serviceContractsData.equipmentSizeChart?.reduce<Record<string, number>>((acc, item) => {
      acc[item.label.name] = item.value;
      return acc;
    }, {}),
    'common.equipmentTypes', // Translation prefix for Equipment Size
    false,
    isEquipmentSize,
  );

  const wasteMaterialTypeChartData = getChartData(
    serviceContractsData.wasteMaterialTypeChart?.reduce<Record<string, number>>((acc, item) => {
      acc[item.label.name] = item.value;
      return acc;
    }, {}),
    'common.wasteTypes', // Translation prefix for Equipment Size
    false,
  );

  const equipmentCountChartData = getChartData(
    serviceContractsData.equipmentCountChart?.reduce<Record<string, number>>((acc, item) => {
      acc[item.label.name] = item.value;
      return acc;
    }, {}),
    undefined, // No translation prefix
    true, // isEquipmentCount flag set to true
  );

  if (!serviceContractsData || Object.keys(serviceContractsData).length === 0) {
    return (
      <Grid padding="medium">
        <Text weight="light" size="large" align="center" margin="no no no no">
          {translate('routes.planner.noData')}
        </Text>
      </Grid>
    );
  }

  return (
    <>
      <Grid padding="no" verticalAlign="center">
        <Text weight="medium" size="large" align="left" margin="sMedium xSmall small medium">
          {serviceContractsData.customersCount}
        </Text>
        <Text weight="medium" size="small" align="left" margin="sMedium large small no">
          {translate('customers.customers')}
        </Text>
        <Text weight="medium" size="large" align="left" margin="sMedium xSmall small no">
          {serviceContractsData.locationsCount}
        </Text>
        <Text weight="medium" size="small" align="left" margin="sMedium large small no">
          {translate('customers.rateBuilder.locations')}
        </Text>
        <Text weight="medium" size="large" align="left" margin="sMedium xSmall small no">
          {serviceContractsData.serviceContractsCount}
        </Text>
        <Text weight="medium" size="small" align="left" margin="sMedium no small no">
          {translate('customers.rateBuilder.services')}
        </Text>
      </Grid>

      <Grid padding="no">
        {equipmentTypeChartData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center" minWidth="10%">
            <DoughnutChart
              dataset={{
                data: equipmentTypeChartData.data,
                backgroundColor: chartColors,
              }}
              labels={equipmentTypeChartData.labels}
              title={translate('common.equipmentType')}
              showLegend={true}
              showLegendScrolled={equipmentTypeChartData.data.length > 5}
              showSummaryOnHover={true}
              showWholeLegend={true}
              doughnutSize={88}
              showSummaryLegend
              rawDataValues={equipmentTypeChartData.rawDataValues as number[]}
            />
          </ContainerStatisticsElement>
        )}

        {equipmentSizeChartData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center" minWidth="10%">
            <DoughnutChart
              dataset={{
                data: equipmentSizeChartData.data,
                backgroundColor: chartColors,
              }}
              labels={equipmentSizeChartData.labels}
              title={translate('common.equipmentSize')}
              showLegend={true}
              showLegendScrolled={equipmentSizeChartData.data.length > 5}
              showSummaryOnHover={true}
              showWholeLegend={true}
              doughnutSize={88}
              showSummaryLegend
              rawDataValues={equipmentSizeChartData.rawDataValues as number[]}
            />
          </ContainerStatisticsElement>
        )}

        {equipmentCountChartData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center" minWidth="10%">
            <DoughnutChart
              dataset={{
                data: equipmentCountChartData.data,
                backgroundColor: chartColors,
              }}
              doughnutSize={88}
              labels={equipmentCountChartData.labels}
              title={translate('common.equipmentCount')}
              showLegend={true}
              showLegendScrolled={equipmentCountChartData.data.length > 5}
              showWholeLegend={true}
              showSummaryOnHover={true}
              showSummaryLegend
              rawDataValues={equipmentCountChartData.rawDataValues as number[]}
            />
          </ContainerStatisticsElement>
        )}

        {wasteMaterialTypeChartData && (
          <ContainerStatisticsElement verticalAlign="middle" alignItems="center" minWidth="10%">
            <DoughnutChart
              dataset={{
                data: wasteMaterialTypeChartData.data,
                backgroundColor: chartColors,
              }}
              doughnutSize={88}
              showSummaryLegend
              labels={wasteMaterialTypeChartData.labels}
              title={translate('routes.editJobStatus.materialType')}
              showLegend={true}
              showLegendScrolled={wasteMaterialTypeChartData.data.length > 5}
              showWholeLegend={true}
              showSummaryOnHover={true}
              rawDataValues={wasteMaterialTypeChartData.rawDataValues as number[]}
            />
          </ContainerStatisticsElement>
        )}
      </Grid>
    </>
  );
};

export default memo(RateBuilderCharts);
