import { filter, find, map } from 'lodash-es';
import { useMemo } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { DELIVERY_UTILITY, RESIDENTIAL, FRONT_LOAD, ROLL_OFF, WASTE_AUDIT } from 'src/common/constants';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getExcludeVehicleFilters } from 'src/common/utils/filters';
import { MultiSelect, TypedField } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { TOTER, SNOW_PLOW, STREET_SWEEPER } from 'src/fleet/constants';

export const VehiclesStatusFilterByVehicleTypeFormName = 'VehiclesStatusFIlterByVehicleTypeForm';

interface PropsWithoutReduxForm {}

interface FormData {
  vehicleTypeIds?: number[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const VehiclesStatusFilterByVehicleTypeForm: React.FC<Props> = ({ handleSubmit }) => {
  const vehicleTypesForVendor = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const excludeVehicleFilters = useSelector(state => getExcludeVehicleFilters(state.common.filters.filters));

  const options = useMemo(
    () =>
      map(
        filter(
          vehicleTypesForVendor,
          v =>
            !find(excludeVehicleFilters, f => v.technicalName === f) &&
            [
              TOTER,
              DELIVERY_UTILITY,
              RESIDENTIAL,
              FRONT_LOAD,
              ROLL_OFF,
              SNOW_PLOW,
              STREET_SWEEPER,
              WASTE_AUDIT,
            ].includes(v.technicalName),
        ),
        (vehicleType: TechnicalType) => ({
          label: vehicleType.name,
          value: vehicleType.id,
        }),
      ),
    [excludeVehicleFilters, vehicleTypesForVendor],
  );

  return (
    <Box display="flex" justifyContent="center" width={170} margin="no auto">
      <form onSubmit={handleSubmit}>
        <TypedField
          name="vehicleTypeIds"
          component={MultiSelect}
          props={{
            label: translate('vehicles.vehicleType'),
            options,
          }}
        />
      </form>
    </Box>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: VehiclesStatusFilterByVehicleTypeFormName,
  enableReinitialize: true,
})(VehiclesStatusFilterByVehicleTypeForm);
