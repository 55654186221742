import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import * as turf from '@turf/turf';

import { loadStreetNetworkDataLayers as doLoadStreetNetworkDataLayers } from 'src/customers/services/streetNetwork';
import { StreetNetworkPropertiesTravelPath } from 'src/routes/interfaces/TravelPath';

// Actions
const START_LOAD_STREET_NETWORK_DATA_LAYER = 'customers/streetNetwork/START_LOAD_STREET_NETWORK_DATA_LAYER';
const COMPLETE_LOAD_STREET_NETWORK_DATA_LAYER = 'customers/streetNetwork/COMPLETE_LOAD_STREET_NETWORK_DATA_LAYER';
const FAIL_LOAD_STREET_NETWORK_DATA_LAYER = 'customers/streetNetwork/FAIL_LOAD_STREET_NETWORK_DATA_LAYER';
const CLEAR_STREET_NETWORK_DATA_LAYER = 'customers/streetNetwork/CLEAR_STREET_NETWORK_DATA_LAYER';

const CancelToken = axios.CancelToken;
let cancelStreetNetworkDataLayerRequest: any;

export interface StreetNetworkDataLayers {
  data: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
  zoomClassNumber: number;
  lastUpdate: string;
}

interface State {
  isLoading: boolean;
  streetNetwork: StreetNetworkDataLayers[];
  streetNetworkWithBbox?: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
  errorMessage?: string;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  streetNetwork: [] as StreetNetworkDataLayers[],
  streetNetworkWithBbox: undefined,
  errorMessage: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_STREET_NETWORK_DATA_LAYER:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_STREET_NETWORK_DATA_LAYER:
      return update(state, {
        $merge:
          typeof action.newDataLayer.zoomClassNumber === 'number'
            ? {
                isLoading: false,
                streetNetwork: [...state.streetNetwork, action.newDataLayer],
                streetNetworkWithBbox: undefined,
              }
            : {
                isLoading: false,
                streetNetworkWithBbox: action.newDataLayer.data,
              },
      });

    case FAIL_LOAD_STREET_NETWORK_DATA_LAYER:
      return update(state, {
        $merge: {
          isLoading: false,
          streetNetwork: [],
          errorMessage: action.error,
        },
      });
    case CLEAR_STREET_NETWORK_DATA_LAYER:
      return update(state, {
        $merge: {
          isLoading: true,
          streetNetwork: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoadStreetNetworkDataLayer = () => ({
  type: START_LOAD_STREET_NETWORK_DATA_LAYER,
});

const completeLoadStreetNetworkDataLayer = (
  parsedStreetNetwork: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>,
  lastUpdate: string,
  layerFilter: number | turf.BBox,
) => ({
  type: COMPLETE_LOAD_STREET_NETWORK_DATA_LAYER,
  newDataLayer: { data: parsedStreetNetwork, zoomClassNumber: layerFilter, lastUpdate },
});

const failLoadStreetNetworkDataLayer = (error?: string) => ({
  type: FAIL_LOAD_STREET_NETWORK_DATA_LAYER,
  error,
});

// Thunks

export const loadStreetNetworkDataLayers =
  (vendorId: number, layerFilter: number | turf.BBox, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    if (cancelStreetNetworkDataLayerRequest) cancelStreetNetworkDataLayerRequest();

    const cancelTokenStreets = new CancelToken(c => {
      cancelStreetNetworkDataLayerRequest = c;
    });

    !noLoadingIndicator && dispatch(startLoadStreetNetworkDataLayer());

    const formattedVendorId = vendorId.toString().padStart(5, '0');
    const dataLayerType = `centerline_${formattedVendorId}`;

    const cqlFilter = typeof layerFilter === 'number' ? `Class <= ${layerFilter}` : undefined;
    const bbox = typeof layerFilter !== 'number' ? layerFilter : undefined;

    const loadStreetNetworkPromise = doLoadStreetNetworkDataLayers(
      vendorId,
      dataLayerType,
      cqlFilter,
      bbox,
      cancelTokenStreets,
    );

    loadStreetNetworkPromise
      .then(streetNetwork => {
        if (streetNetwork.error) {
          dispatch(failLoadStreetNetworkDataLayer(streetNetwork.error.code));
        } else {
          try {
            const parsedStreetNetwork = JSON.parse(streetNetwork.result.dataLayer);
            const lastUpdate = streetNetwork.result.lastUpdate;

            dispatch(completeLoadStreetNetworkDataLayer(parsedStreetNetwork, lastUpdate, layerFilter));
          } catch (e) {
            dispatch(failLoadStreetNetworkDataLayer());
          }
        }
      })
      .catch(() => {
        dispatch(failLoadStreetNetworkDataLayer());
      });
  };

export const clearStreetNetworkDataLayers = () => ({
  type: CLEAR_STREET_NETWORK_DATA_LAYER,
});
