import { FC, useState } from 'react';
import { size } from 'lodash-es';

import { Button, Message, ModalFixedFooter, PanelSection, Popover, Text } from 'src/core/components/styled';
import { Modal, PopoverWrapper, Table } from 'src/core/components';
import { NewPaymentModalResolver } from './NewPaymentModalResolver';
import { PaymentManagementTableRow } from './PaymentManagementTableRow';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

const tableCells = [
  {
    name: 'paymentMethodId',
    label: translate('finance.paymentManagement.paymentMethodId'),
    width: '35%',
  },
  {
    name: 'type',
    label: translate('common.type'),
    width: '25%',
  },
  {
    name: 'details',
    label: translate('finance.paymentManagement.details'),
    width: '25%',
  },
  {
    name: 'options',
    label: translate('common.options'),
    width: '15%',
    align: 'right',
  },
];

export const PaymentManagementModal: FC<{
  customerId: number;
  isAddNewPaymentMethodUnavailable: boolean;
  onClose: () => void;
}> = ({ customerId, isAddNewPaymentMethodUnavailable, onClose }) => {
  const { isLoading, paymentMethods } = useSelector(s => s.customers.paymentMethods);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isButtonDisabled = isAddNewPaymentMethodUnavailable;

  return (
    <>
      <Modal
        isLoading={isLoading}
        onClose={onClose}
        title={translate('finance.paymentManagement.managePaymentMethods')}
        padding="medium no no no"
      >
        <PanelSection padding="small" minHeight={200}>
          {!!size(paymentMethods) ? (
            <Table
              cells={tableCells}
              rows={paymentMethods}
              rowComponent={PaymentManagementTableRow}
              rowProps={{ customerId }}
            />
          ) : (
            <Message padding="sMedium no">
              <em>{translate('customers.noPaymentMethods')}</em>
            </Message>
          )}
        </PanelSection>

        <ModalFixedFooter isShadowed>
          <Button type="button" color="secondary" margin="no xSmall" onClick={() => onClose()}>
            {translate('common.cancel')}
          </Button>

          <PopoverWrapper
            triggerButton={
              <Button
                onClick={() => setIsModalOpen(true)}
                color="primary"
                margin="no xSmall"
                disabled={isButtonDisabled}
              >
                {translate('customers.serviceEditor.addNew')}
              </Button>
            }
            popoverContent={
              isButtonDisabled && (
                <Popover>
                  <Text block weight="medium" margin="xxSmall no xxSmall">
                    {translate('customers.alertMessages.addNewPaymentMethodUnvailable')}
                  </Text>
                </Popover>
              )
            }
            size="large"
          />
        </ModalFixedFooter>
      </Modal>

      {isModalOpen && <NewPaymentModalResolver customerId={customerId} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};
