import { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';
import { Field, formValueSelector, getFormError, InjectedFormProps, reduxForm, reset } from 'redux-form';
import { filter, get, isEqual, map } from 'lodash-es';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';

import { AppState } from '../../../store';
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  NavigationPrompt,
  PopoverWrapper,
  TypedField,
} from '../../../core/components';
import { COMPLETED, IN_PROGRESS } from '../../constants';
import {
  ConfirmationTypeDropdown,
  GroupsMultiSelect,
  ServiceZoneDropdown,
  SupervisorDropdown,
  VehicleTypeForVendorDropdown,
} from '..';
import { customerLocationsSelector } from '../../../customers/ducks';
import {
  DELIVERY_UTILITY,
  RESIDENTIAL,
  ROLL_OFF,
  ROUTE,
  SNOW_PLOW,
  STREET_SWEEPER,
  VEHICLE_TYPE_IDS,
  WASTE_AUDIT,
} from '../../../fleet/constants';
import { Facility } from '../../../common/interfaces/Facility';
import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { getExcludedFiltersIds, getExcludeVehicleFilters } from 'src/common/utils/filters';
import { Grid, GridColumn, PanelSection, PanelSectionGroup, Popover } from '../../../core/components/styled';
import { isRequired, isDateValidValidator, maxLength50, isValidEndTime } from '../../../utils/services/validator';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { loadVehicles } from 'src/fleet/ducks';
import { PermissionGuard } from '../../../account/components';
import { Route } from '../../interfaces/Route';
import { RouteLocation } from '../../interfaces/RouteLocation';
import { ROUTES_TRACKER_VIEW_EDIT } from '../../../account/constants';
import { Supervisor } from '../../interfaces/Supervisors';
import { supervisorExperienceFeatureIsEnabled } from '../../../vendors/ducks/features';
import { technicalNameByVehicleTypeIdSelector } from '../../../fleet/ducks';
import { TODAY } from '../../../core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehicleDropdown } from '../../../fleet/components';
import { WasteTypeDropdown } from '../../../common/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import routeEditorFormInitialValuesSelector from '../../services/routeEditorFormInitialValuesSelector';
import TimePickerField from 'src/core/components/TimePickerField';
import translate from '../../../core/services/translate';
import { Group } from 'src/routes/interfaces/Groups';

const ROUTE_EDITOR_FORM = 'routeEditor';

export interface FormValues {
  endTime: string;
  isTestRoute: boolean;
  routeConfirmationTypeId: number;
  routeDate: Date | string;
  routeName: string;
  startingLocationId: number;
  startTime: string;
  supervisorId: number;
  vehicleId: number;
  vehicleTypeId: number;
  vendorServiceZoneId: number;
  wasteMaterialTypeId: number;
  groups: number[];
  /** TODO Intermediate Facility */
  // intermediateLocationId: number;
}

interface PropsWithoutReduxForm extends RouteComponentProps {
  truckYardFacilities?: Facility[];
  disposalFacilities?: Facility[];
  disabledFields: { [key: string]: boolean };
  endTime?: string;
  filtersPreferences?: FilterSetting[];
  isCreateMode?: boolean;
  route?: Route;
  routeDate?: Date | string;
  routeLocations?: RouteLocation[];
  serviceZones?: number[];
  startTime?: string;
  supervisorExperienceEnabled: boolean;
  supervisors: Supervisor[];
  transferredStops: boolean;
  vehicleTypeId?: number;
  vehicleTypeTechnicalName?: string;
  vendorId: number;
  groups?: Group[];

  /** TODO Intermediate Facility */
  // disposalFacilities: Facility[]; // ??
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const RouteEditorForm = (props: Props) => {
  const {
    change,
    truckYardFacilities,
    disposalFacilities,
    disabledFields,
    endTime,
    filtersPreferences,
    handleSubmit,
    initialValues,
    isCreateMode,
    pristine,
    route,
    routeDate,
    routeLocations,
    serviceZones,
    startTime,
    submitSucceeded,
    supervisorExperienceEnabled,
    supervisors,
    transferredStops,
    vehicleTypeId,
    vehicleTypeTechnicalName,
    vendorId,
    groups,
    /** TODO Intermediate Facility */
    // disposalFacilities,
  } = props;

  const dispatch = useDispatch();

  const { supervisorsLoading: isLoadingSupervisors } = useSelector(state => state.routes.supervisors);
  const { isLoading: isLoadingVehicles } = useSelector(state => state.fleet.vehicles);

  useEffect(() => {
    if (vehicleTypeTechnicalName === ROLL_OFF || vehicleTypeTechnicalName === DELIVERY_UTILITY) {
      change('wasteMaterialTypeId', null);
    }
  }, [change, vehicleTypeTechnicalName]);

  const handleDateChange = (date: any | Date | string) => {
    const showActiveTrucks = true;
    const vehicleTypeId = undefined;

    loadSupervisors(vendorId, date)(dispatch);
    loadVehicles(vendorId, showActiveTrucks, vehicleTypeId, date)(dispatch);
  };

  const resetRouteTemplateLocation = (date: any | Date | string) => {
    dispatch(reset('addRouteLocation'));

    handleDateChange(date);
  };

  const onStartingLocationChange = (event: any, value: number) => {
    change('endingLocationId', value);
  };

  const isRouteInThePast =
    moment(routeDate, 'MM/DD/YYYY', true).isValid() &&
    moment(routeDate, 'MM/DD/YYYY').isBefore(moment().startOf('day'));
  const isRouteInProgressCompleted =
    route && (route.routeStatusTypeId === COMPLETED || route.routeStatusTypeId === IN_PROGRESS);
  const routeLocationIds = map(routeLocations, routeLocation => routeLocation.id);
  const locationIds = map(get(route, 'routeLocations'), routeLocation => routeLocation.id);
  const differentRouteLocations = !isEqual(routeLocationIds, locationIds) && !transferredStops;
  const isVisibleSupervisor = vehicleTypeTechnicalName !== STREET_SWEEPER && vehicleTypeTechnicalName !== WASTE_AUDIT;

  const excludeVehicleFilters = isCreateMode
    ? getExcludeVehicleFilters(filtersPreferences)
    : filter(
        getExcludeVehicleFilters(filtersPreferences),
        vehicleType =>
          initialValues &&
          initialValues.vehicleTypeId &&
          VEHICLE_TYPE_IDS[initialValues.vehicleTypeId].technicalName !== vehicleType,
      );
  const excludedServiceZonesFiltersIds = isCreateMode
    ? getExcludedFiltersIds(serviceZones, filtersPreferences, FILTER_SERVICE_ZONE_ID)
    : filter(
        getExcludedFiltersIds(serviceZones, filtersPreferences, FILTER_SERVICE_ZONE_ID),
        id => initialValues && initialValues.vendorServiceZoneId !== id,
      );
  const excludedSupervisorsFiltersIds = isCreateMode
    ? getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID)
    : filter(
        getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID),
        id => initialValues && initialValues.supervisorId !== id,
      );

  // groupIds to exclude those inactive groups  except if initial value is inactive
  const groupIdsToExclude = map(
    filter(groups, group => !initialValues?.groups?.includes(group.id) && !group.isActive),
    'id',
  );

  let customerLocations = [] as any[];
  if (!!truckYardFacilities && !!disposalFacilities)
    customerLocations = [...truckYardFacilities, ...disposalFacilities];

  return (
    <PanelSectionGroup isLoading={isLoadingSupervisors || isLoadingVehicles}>
      <NavigationPrompt when={(!pristine || differentRouteLocations) && !submitSucceeded} />
      <form onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall no">
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="routeName"
                component={Input}
                label={translate('routes.routeName')}
                validate={[isRequired, maxLength50]}
                disabled={disabledFields.routeName}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="routeDate"
                component={DatePicker}
                label={translate('common.date')}
                validate={[isRequired, isDateValidValidator]}
                disabled={disabledFields.routeDate || isRouteInThePast}
                disabledDays={[{ before: TODAY }]}
                props={{ name: 'routeDate' }}
                onChange={resetRouteTemplateLocation}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <TimePickerField
                labelName={translate('routes.scheduledStartTime')}
                name="startTime"
                validate={!!endTime ? [isRequired] : undefined}
                formName={ROUTE_EDITOR_FORM}
                minuteStep={15}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <TimePickerField
                labelName={translate('routes.scheduledEndTime')}
                name="endTime"
                validate={startTime ? [isRequired, isValidEndTime] : [isValidEndTime]}
                formName={ROUTE_EDITOR_FORM}
                minuteStep={15}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <TypedField
                name="description"
                component={Input}
                props={{ label: translate('routes.description'), autoComplete: 'off' }}
                validate={[maxLength50]}
              />
            </GridColumn>
          </Grid>
        </PanelSection>

        <PanelSection padding="small xSmall no">
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="vehicleTypeId"
                component={VehicleTypeForVendorDropdown}
                withLabel
                vehicleRoleTypeId={ROUTE}
                excludeVehicleTypes={[SNOW_PLOW, STREET_SWEEPER, ...excludeVehicleFilters]}
                validate={[isRequired]}
                dropdownProps={{
                  disabled: disabledFields.vehicleTypeId || isRouteInThePast || isRouteInProgressCompleted,
                  id: 'route-vehicle-type',
                }}
                onChange={resetRouteTemplateLocation}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="vehicleId"
                vehicleTypeId={vehicleTypeId}
                component={VehicleDropdown}
                withLabel
                dropdownProps={{
                  disabled: disabledFields.vehicleId || isRouteInThePast,
                  isClearable: true,
                  id: 'route-vehicle',
                }}
                shouldGroupByAvailability={true}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="startingLocationId"
                component={Dropdown}
                options={truckYardFacilities}
                label={translate('routes.startingLocation')}
                disabled={disabledFields.startingLocationId || isRouteInThePast}
                isClearable
                id="route-starting-location"
                onChange={onStartingLocationChange}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="endingLocationId"
                component={Dropdown}
                options={customerLocations}
                label={translate('routes.endingLocation')}
                disabled={disabledFields.startingLocationId || isRouteInThePast}
                isClearable
                id="route-ending-location"
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="vendorServiceZoneId"
                component={ServiceZoneDropdown}
                label={translate('routes.serviceZone')}
                dropdownProps={{ id: 'route-service-zone' }}
                props={{
                  excludeServiceZonesIds: excludedServiceZonesFiltersIds,
                }}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="groups"
                component={GroupsMultiSelect}
                props={{
                  withLabel: true,
                  excludeGroupsIds: groupIdsToExclude,
                  cantSelectInactive: true,
                }}
                multiSelectProps={{
                  defaultToAll: false,
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>

        <PanelSection padding="small xSmall no">
          {/* NOTE: Temporarily we leave waste type field always enabled. See RVP-2001 */}
          <Grid>
            {vehicleTypeTechnicalName !== ROLL_OFF && vehicleTypeTechnicalName !== DELIVERY_UTILITY && (
              <GridColumn size="3/12">
                <Field
                  name="wasteMaterialTypeId"
                  component={WasteTypeDropdown}
                  withLabel
                  validate={isRouteInThePast ? undefined : [isRequired]}
                  dropdownProps={{
                    disabled: isRouteInThePast,
                    id: 'route-waste-type',
                  }}
                />
              </GridColumn>
            )}

            {/** TODO Intermediate Facility */}
            {/*
              <GridColumn size="3/12">
                <Field
                  name="intermediateLocationId"
                  component={Dropdown}
                  props={{
                    isClearable: true,
                    label: translate('routes.intermediateFacility'),
                    width: '100%',
                    options: disposalFacilities.map(facility => ({
                      label: facility.name,
                      value: facility.locationId,
                    })),
                  }}
                />
              </GridColumn>
              */}

            {vehicleTypeTechnicalName === RESIDENTIAL && (
              <GridColumn size="3/12">
                <Field
                  name="routeConfirmationTypeId"
                  component={ConfirmationTypeDropdown}
                  label={translate('routes.confirmationType')}
                  validate={[isRequired]}
                  dropdownProps={{ disabled: disabledFields.routeConfirmationTypeId || isRouteInThePast }}
                />
              </GridColumn>
            )}

            {supervisorExperienceEnabled && isVisibleSupervisor && (
              <GridColumn size="3/12">
                <TypedField
                  name="supervisorId"
                  component={SupervisorDropdown}
                  props={{
                    withLabel: true,
                    excludeSupervisorsIds: excludedSupervisorsFiltersIds,
                    shouldGroupByAvailability: true,
                  }}
                />
              </GridColumn>
            )}

            <PermissionGuard permission={ROUTES_TRACKER_VIEW_EDIT}>
              <GridColumn size="3/12" verticalAlign="center">
                <PopoverWrapper
                  triggerButton={
                    <Field component={Checkbox} name="isTestRoute" label={translate('routes.testRoute')} />
                  }
                  popoverContent={<Popover>{translate('routes.testRoutePopover')}</Popover>}
                />
              </GridColumn>
            </PermissionGuard>
          </Grid>
        </PanelSection>
      </form>
    </PanelSectionGroup>
  );
};

RouteEditorForm.displayName = 'RouteEditorForm';

const formSelector = formValueSelector(ROUTE_EDITOR_FORM);

const mapStateToProps = (state: AppState) => {
  const vehicleTypeId = Number(formSelector(state, 'vehicleTypeId'));
  const routeDate = formSelector(state, 'routeDate');
  const startTime = formSelector(state, 'startTime');
  const endTime = formSelector(state, 'endTime');

  return {
    truckYardFacilities: customerLocationsSelector(state.fleet.facilities.operationalFacilities),
    disposalFacilities: customerLocationsSelector(state.fleet.facilities.disposalFacilities),
    endTime,
    filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
    formError: getFormError(ROUTE_EDITOR_FORM)(state),
    initialValues: routeEditorFormInitialValuesSelector(state.routes.route, state.routes.geoFence),
    route: state.routes.route.route,
    routeDate,
    serviceZones: state.routes.serviceZones.serviceZones,
    startTime,
    supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
    supervisors: state.routes.supervisors.supervisors,
    vehicleTypeId,
    vehicleTypeTechnicalName: technicalNameByVehicleTypeIdSelector(state.fleet.vehicleTypesForVendor, vehicleTypeId),
    groups: state.routes.groups.groups,
    /** TODO Intermediate Facility */
    // disposalFacilities: state.fleet.facilities.disposalFacilities,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<FormValues, PropsWithoutReduxForm>({
      form: ROUTE_EDITOR_FORM,
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
    })(RouteEditorForm),
  ),
);
