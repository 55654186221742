import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { Container, ContainerNew, ContainerState, ContainerToSave } from '../interfaces/containers';
import { createContainer, loadContainerNew as doLoadContainerNew, updateContainer } from '../services/container';

// Actions
const START_LOAD = 'fleet/container/START_LOAD';
export const COMPLETE_LOAD = 'fleet/container/COMPLETE_LOAD';
const COMPLETE_LOAD_CONTAINER_NEW = 'fleet/container/COMPLETE_LOAD_CONTAINER_NEW';
const FAIL_LOAD = 'fleet/container/FAIL_LOAD';
const START_SAVE = 'fleet/container/START_SAVE';
export const COMPLETE_SAVE = 'fleet/container/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/container/FAIL_SAVE';
const RESET = 'fleet/container/RESET';

// Initial state
const initialState: ContainerState = {
  container: undefined,
  containerNew: undefined,
  isLoading: false,
  isSaving: false,
};

// Reducer
export const reducer = (state: ContainerState = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          container: action.container,
        },
      });

    case COMPLETE_LOAD_CONTAINER_NEW:
      return update(state, {
        $merge: {
          isLoading: false,
          containerNew: action.container,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          container: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoadContainerNew = (container: ContainerNew) => ({
  type: COMPLETE_LOAD_CONTAINER_NEW,
  container,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadContainerNew = (containerId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerPromise = doLoadContainerNew(containerId);
  loadContainerPromise
    .then(container => dispatch(completeLoadContainerNew(container)))
    .catch(() => dispatch(failLoad()));
  return loadContainerPromise;
};

export const saveContainer = (container: Container | ContainerToSave) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveContainerPromise = container.id
    ? updateContainer(container as Container)
    : createContainer(container as ContainerToSave);
  saveContainerPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveContainerPromise;
};

export const resetContainer = () => ({
  type: RESET,
});
