import React, { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import {
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Pagination, Table } from '../../../core/components';
import { Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { LIMIT_PER_PAGE } from '../../../core/constants';
import translate from '../../../core/services/translate';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { loadRouteImportStatuses, resetRouteImportStatuses } from '../../ducks';
import { RouteImportStatusesForm } from '../forms';
import { RouteImportStatusesPageTableRow } from '.';
import { AppState } from '../../../store';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';

interface Props extends RouteComponentProps {
  isLoading: boolean;
  routeImportStatuses?: any[];
  total: number;
  loadRouteImportStatuses: DuckFunction<typeof loadRouteImportStatuses>;
  resetRouteImportStatuses: DuckAction<typeof resetRouteImportStatuses>;
  push: any;
}

class RouteImportStatusesPage extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { loadRouteImportStatuses, location } = this.props;

    if (prevProps.location.search !== location.search) {
      const { routeImportId, haulerRouteImportStatusTypeId, page = 1 } = getQueryParams(location.search);
      loadRouteImportStatuses(routeImportId, haulerRouteImportStatusTypeId, page, LIMIT_PER_PAGE);
    }
  }

  componentWillUnmount() {
    this.props.resetRouteImportStatuses();
  }

  onSubmit = ({ haulerRouteImportStatusTypeId }: { haulerRouteImportStatusTypeId: number }) => {
    const { push, location } = this.props;
    push(createUrl(location.pathname, location.search, { haulerRouteImportStatusTypeId, page: undefined }));
  };

  routeImportStatusTableCells = [
    { name: 'customerName', label: translate('common.customerName'), width: '35%' },
    { name: 'locationName', label: translate('common.locationName'), width: '35%' },
    { name: 'importStatusName', label: translate('common.status'), width: '30%' },
  ];

  render() {
    const { routeImportStatuses, total, isLoading, push } = this.props;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to="/route-import">
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle>{translate('routeImport.routeImportStatuses')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>

        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <RouteImportStatusesForm onSubmit={this.onSubmit} />
            <PanelSection>
              {!!size(routeImportStatuses) && (
                <Table
                  cells={this.routeImportStatusTableCells}
                  rows={routeImportStatuses}
                  rowComponent={RouteImportStatusesPageTableRow}
                  rowProps={{ push }}
                  withClickableRows
                />
              )}

              {!size(routeImportStatuses) && (
                <Message padding="sMedium">{translate('routeImport.noRouteImportStatuses')}</Message>
              )}
            </PanelSection>
            <Pagination totalResults={total!} />
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.routeImport.routeImportStatuses.isLoading,
  routeImportStatuses: state.routeImport.routeImportStatuses.routeImportStatuses,
  total: state.routeImport.routeImportStatuses.total as any as number,
});

const mapDispatchToProps = {
  loadRouteImportStatuses,
  resetRouteImportStatuses,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteImportStatusesPage));
