import { map } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'src/core/hooks/useSelector';

import {
  TravelPathSliderRange,
  TravelPathSliderTextLeft,
  TravelPathSliderTextRight,
  TravelPathSliderThumbLeft,
  TravelPathSliderThumbRight,
  TravelPathSliderTrack,
  TravelPathSliderWrapper,
} from 'src/routes/components/styled';
import { time } from 'src/utils/services/formatter';
import { TravelPathSliderFlagLeft, TravelPathSliderFlagRight } from '../../styled/TravelPath';

interface Props {
  disabled?: boolean;
  onMinChange: (value: number) => void;
  onMaxChange: (value: number) => void;
  minSelected: number;
  maxSelected: number;
}

const TravelPathBuilderRangeSelection: FC<Props> = ({
  disabled,
  onMinChange,
  onMaxChange,
  minSelected,
  maxSelected,
}) => {
  const { routeVehicleBreadCrumbs } = useSelector(state => state.routes.travelPathBuildAndEdit);

  const breadCrumbs = useMemo(() => {
    if (!routeVehicleBreadCrumbs || routeVehicleBreadCrumbs.vehicles.length === 0) return [];

    const firstVehicle = routeVehicleBreadCrumbs.vehicles[0];

    return map(firstVehicle.coords, tracking => {
      return map(tracking, coord => {
        return {
          reportedDate: time(coord.ts),
        };
      });
    }).reduce((acc, curr) => [...acc, ...curr], []);
  }, [routeVehicleBreadCrumbs]);

  const min = 0;
  const max = breadCrumbs.length - 1;

  const [minVal, setMinVal] = useState(minSelected);
  const [maxVal, setMaxVal] = useState(maxSelected);
  const range = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMaxVal(maxSelected);
    setMinVal(minSelected);
  }, [maxSelected, minSelected]);

  // Convert to percentage
  const getPercent = useCallback(value => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, maxVal, getPercent]);

  const handleMinChange = useCallback(
    event => {
      const value = Math.min(Number(event.target.value), maxVal - 1);
      setMinVal(value);
      onMinChange(value);
    },
    [maxVal, onMinChange],
  );

  const handleMaxChange = useCallback(
    event => {
      const value = Math.max(Number(event.target.value), minVal + 1);
      setMaxVal(value);
      onMaxChange(value);
    },
    [minVal, onMaxChange],
  );

  const aboveMinText = useMemo(() => {
    return breadCrumbs[minVal]?.reportedDate || '';
  }, [breadCrumbs, minVal]);

  const aboveMaxText = useMemo(() => {
    return breadCrumbs[maxVal]?.reportedDate || '';
  }, [breadCrumbs, maxVal]);

  return (
    <>
      <TravelPathSliderThumbLeft
        type="range"
        min={min}
        max={max}
        value={minVal}
        disabled={disabled}
        onChange={handleMinChange}
        style={{ zIndex: minVal > max - 100 ? 5 : 3 }}
      />
      <TravelPathSliderThumbRight
        type="range"
        min={min}
        max={max}
        value={maxVal}
        disabled={disabled}
        onChange={handleMaxChange}
      />
      <TravelPathSliderWrapper>
        <TravelPathSliderTrack>
          <TravelPathSliderRange ref={range}>
            <TravelPathSliderTextLeft>{aboveMinText}</TravelPathSliderTextLeft>
            <TravelPathSliderTextRight>{aboveMaxText}</TravelPathSliderTextRight>
            <TravelPathSliderFlagLeft />
            <TravelPathSliderFlagRight />
          </TravelPathSliderRange>
        </TravelPathSliderTrack>
      </TravelPathSliderWrapper>
    </>
  );
};

export default TravelPathBuilderRangeSelection;
