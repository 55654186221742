import React, { useState } from 'react';
import moment from 'moment';
import { uniq, orderBy } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { TableRow, TableCell, TableActionButton, Text } from '../../../../../core/components/styled';
import { RouteTemplateBuilderWorkSession } from '../../../../interfaces/routeTemplateBuilder/RouteTemplateBuilderWorkSession';
import { ActionButtonTooltip, MoreButton } from '../../../../../core/components';
import translate from '../../../../../core/services/translate';
import renderListSummary from '../../../../../common/services/listSummary';
import { WEEKDAYS_BY_ID } from '../../../../../core/constants';
import {
  RouteTemplateBuilderTableRowCluster,
  RouteTemplateBuilderTableRowCollapseIcon,
} from '../../../styled/RouteTemplateBuilderListing';
import { AppState } from '../../../../../store';
import {
  setExpandedWorkSession,
  deleteRouteTemplateBuilderWorkSession,
  deleteWorkSessionRouteTemplateDraft,
  loadRouteTemplateBuilderWorkSessions,
  setSelectedDraftId,
  publishWorkSession,
} from '../../../../ducks/routeTemplateBuilder';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import confirm from 'src/core/services/confirm';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import Spacer from 'src/common/components/styled/Spacer';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { RouteTemplateBuilderUpdateTrackersModal } from '../routeTemplateBuilderModals/RouteTemplateBuilderUpdateTrackersModal';
import { getRouteTemplatesWithTrackerRoutes } from 'src/routes/services/routeTemplateBuilder';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';

const handleErrorCode = (code: string) =>
  createErrorNotification(translate(createTranslationKey(code, 'routeTemplateBuilder.publishErrors')));

type Props = RouteTemplateBuilderWorkSession;

const RouteTemplateBuilderWorkSessionsTableRow: React.FC<Props> = props => {
  const { id, name, isPublished, lastUpdateDate, routeTemplates: rawRouteTemplates } = props;
  const dispatch = useDispatch();

  const [routeTemplatesWithTrackerRoutes, setRouteTemplatesWithTrackerRoutes] = useState<
    { id: number; name: string }[]
  >([]);

  const expandedWorkSessionId = useSelector(
    (state: AppState) => state.routes.routeTemplateBuilder.expandedWorkSessionId,
  );
  const availableVehicleTypes = useSelector(
    (state: AppState) => (state.fleet.vehicleTypesForVendor.vehicleTypesForVendor || []) as any[],
  );
  const availableMaterialTypes = useSelector((state: AppState) => (state.common.wasteTypes.wasteTypes || []) as any[]);

  const vendorId = useSelector(currentVendorId);

  const routeTemplates = orderBy(rawRouteTemplates, 'lastUpdateDate', 'desc');
  const isExpanded = expandedWorkSessionId === id;
  const routeNames = orderBy(uniq(routeTemplates.map(template => template.name)));
  const daysOfService = orderBy(uniq(routeTemplates.map(template => template.dayOfService))).map(day =>
    WEEKDAYS_BY_ID[day].name.slice(0, 3),
  );
  const vehicleTypes = orderBy(
    uniq(
      routeTemplates.map(template => {
        const type = availableVehicleTypes.find(vehicleType => vehicleType.id === template.vehicleTypeId);

        return type.name;
      }),
    ),
  );
  const materialTypes = orderBy(
    uniq(
      routeTemplates
        .map(template => {
          const type = availableMaterialTypes.find(materialType => materialType.id === template.wasteMaterialTypeId);

          return type?.name;
        })
        .filter(name => !!name),
    ),
  );

  const setExpanded = () => {
    if (!routeTemplates.length) {
      return;
    }

    dispatch(setExpandedWorkSession(isExpanded ? undefined : id));
  };

  const onDelete = async () => {
    if (
      await confirm(
        translate('routeTemplateBuilder.workSessionDeleteConfirmation', { name }),
        translate('routeTemplateBuilder.workSessionDeleteConfirmationInfo'),
      )
    ) {
      deleteRouteTemplateBuilderWorkSession(id)(dispatch)
        .then(() => {
          createSuccessNotification(translate('routeTemplateBuilder.workSessionDeleted'));
        })
        .catch(error => {
          switch (error?.response?.data?.code) {
            default:
              handleErrorCode('RouteTemplatePlannerWorkSessionDeleteError');
              break;

            case 'RouteTemplatePlannerInvalidWorkSession':
            case 'RouteTemplatePlannerDeleteWorkSessionWithPublishedRouteTemplate':
              handleErrorCode(error.response.data.code);
              break;
          }
        });
    }
  };

  const isActuallyExpanded = routeTemplates.length ? isExpanded : false;

  const publish = async (updateRouteTrackerTemplateIds?: number[]) => {
    if (
      await confirm(
        translate('routeTemplateBuilder.publishWorkSessionAlert'),
        translate('routeTemplateBuilder.publishWorkSessionAlertMessage'),
      )
    ) {
      publishWorkSession(
        id,
        updateRouteTrackerTemplateIds || [],
      )(dispatch).then(() => {
        createSuccessNotification(translate('routeTemplateBuilder.publishSuccessful'));
        loadRouteTemplateBuilderWorkSessions(vendorId)(dispatch);
      });
    }
  };

  return (
    <>
      <RouteTemplateBuilderTableRowCluster isExpanded={isActuallyExpanded}>
        <TableRow clickable onClick={setExpanded}>
          <TableCell width="17%" padding="defaultCellVertical small">
            {name}
          </TableCell>

          <TableCell
            vertical
            width="11%"
            padding="defaultCellVertical xxSmall defaultCellVertical xxSmall"
            horizontalAlign="left"
            align="middle"
          >
            {!!lastUpdateDate && (
              <>
                <Text block>{moment(lastUpdateDate).format('MM/DD/YYYY')}</Text>

                <Text block color="grayDarker" size="small">
                  {moment(lastUpdateDate).format(timeFormatWithoutSeconds)}
                </Text>
              </>
            )}
          </TableCell>

          <TableCell width="22%" padding="defaultCellVertical small defaultCellVertical xSmall">
            {renderListSummary(routeNames, 1)}
          </TableCell>

          <TableCell width="13%" padding="defaultCellVertical small defaultCellVertical no">
            {renderListSummary(daysOfService, 2)}
          </TableCell>

          <TableCell width="12%" padding="defaultCellVertical small defaultCellVertical no">
            {renderListSummary(vehicleTypes, 1)}
          </TableCell>

          <TableCell width="14%" padding="defaultCellVertical small defaultCellVertical no">
            {renderListSummary(materialTypes, 1)}
          </TableCell>

          <TableCell width="7%" padding="defaultCellVertical small defaultCellVertical no" />

          <TableCell width="9%" padding="defaultCellVertical small defaultCellVertical no" />

          <TableCell width="15%" padding="defaultCellVertical small defaultCellVertical no">
            <MoreButton
              stopPropagation
              ellipsis
              items={[
                {
                  to: `/routes/route-template-builder/${id}`,
                  isLink: true,
                  text: translate('tooltips.editWorkSession'),
                },
                {
                  handler: onDelete,
                  text: translate('tooltips.deleteWorkSession'),
                },
                {
                  handler: async () => {
                    const routeTemplatesWithTrackerRoutes = await getRouteTemplatesWithTrackerRoutes(id);
                    if (routeTemplatesWithTrackerRoutes && routeTemplatesWithTrackerRoutes.length) {
                      setRouteTemplatesWithTrackerRoutes(routeTemplatesWithTrackerRoutes);
                    } else {
                      publish();
                    }
                  },
                  disabled: isPublished,
                  text: translate('routeTemplateBuilder.publish'),
                },
              ]}
            />

            <Spacer />

            {!!routeTemplates.length && <RouteTemplateBuilderTableRowCollapseIcon icon="chevronDown" />}
          </TableCell>
        </TableRow>

        {isExpanded &&
          routeTemplates.map(template => {
            const vehicleType = availableVehicleTypes.find(vehicleType => vehicleType.id === template.vehicleTypeId);
            const materialType = availableMaterialTypes.find(
              materialType => materialType.id === template.wasteMaterialTypeId,
            );

            return (
              <TableRow key={template.id}>
                <TableCell width="17%" padding="defaultCellVertical small" />

                <TableCell
                  vertical
                  width="11%"
                  padding="defaultCellVertical xxSmall defaultCellVertical xxSmall"
                  horizontalAlign="left"
                  align="middle"
                >
                  <Text block>{moment(template.lastUpdateDate).format('MM/DD/YYYY')}</Text>

                  <Text block color="grayDarker" size="small">
                    {moment(template.lastUpdateDate).format(timeFormatWithoutSeconds)}
                  </Text>
                </TableCell>

                <TableCell width="22%" padding="defaultCellVertical small defaultCellVertical xSmall">
                  {template.name}
                </TableCell>

                <TableCell width="13%" padding="defaultCellVertical small defaultCellVertical no">
                  {WEEKDAYS_BY_ID[template.dayOfService].name}
                </TableCell>

                <TableCell width="12%" padding="defaultCellVertical small defaultCellVertical no">
                  {vehicleType.name}
                </TableCell>

                <TableCell width="14%" padding="defaultCellVertical small defaultCellVertical no">
                  {materialType?.name}
                </TableCell>

                <TableCell width="7%" padding="defaultCellVertical small defaultCellVertical no">
                  {template.allStopsCount || 0}
                </TableCell>

                <TableCell width="9%" padding="defaultCellVertical small defaultCellVertical no">
                  {template.isPublished
                    ? translate('routeTemplateBuilder.statuses.published')
                    : template.routeTemplateId
                    ? translate('routeTemplateBuilder.statuses.draft')
                    : translate('routeTemplateBuilder.statuses.new')}
                </TableCell>

                <TableCell width="15%" padding="defaultCellVertical small defaultCellVertical no">
                  <TableActionButton
                    color="grayDarker"
                    onClick={event => {
                      event.stopPropagation();

                      if (template.isPublished && !!template.routeTemplateId) {
                        dispatch(push(`/routes/route-templates/${template.routeTemplateId}/edit`));
                      } else {
                        dispatch(setSelectedDraftId(template.id));
                        dispatch(push(`/routes/route-template-builder/${id}`));
                      }
                    }}
                  >
                    <ActionButtonTooltip
                      icon="edit"
                      iconSize="sMedium"
                      tooltip={template.isPublished ? 'editRouteTemplate' : 'editDraft'}
                    />
                  </TableActionButton>

                  {!template.isPublished && (
                    <TableActionButton
                      color="grayDarker"
                      onClick={async () => {
                        if (await confirm(translate('routeTemplateBuilder.deleteRouteTemplateDraftAlert'))) {
                          deleteWorkSessionRouteTemplateDraft(template.id)(dispatch)
                            .then(() => loadRouteTemplateBuilderWorkSessions(vendorId)(dispatch))
                            .catch(error => {
                              switch (error?.response?.data?.code) {
                                case 'RouteTemplatePlannerDeleteDestinationRouteTemplate':
                                case 'RouteTemplatePlannerInvalidWorkSessionRouteTemplate':
                                  handleErrorCode(error.response.data.code);
                                  break;

                                default:
                                  handleErrorCode('RouteTemplatePlannerRouteTemplateDeleteError');
                                  break;
                              }
                            });
                        }
                      }}
                    >
                      <ActionButtonTooltip icon="delete" iconSize="sMedium" tooltip="deleteDraft" />
                    </TableActionButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </RouteTemplateBuilderTableRowCluster>
      {routeTemplatesWithTrackerRoutes && !!routeTemplatesWithTrackerRoutes.length && (
        <RouteTemplateBuilderUpdateTrackersModal
          onPublish={publish}
          routeTemplates={routeTemplatesWithTrackerRoutes}
          onClose={() => setRouteTemplatesWithTrackerRoutes([])}
        />
      )}
    </>
  );
};

export default RouteTemplateBuilderWorkSessionsTableRow;
