import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadRouteDetails as doLoadRouteDetails,
  loadSnowSweeperRouteDetails as doLoadSnowSweeperRouteDetails,
  updateRouteDetails as doUpdateRouteDetails,
} from '../services/routeDetails';
import { RouteDetails, RouteDetailsDataToSubmit } from '../interfaces/RouteDetails';

const START_LOAD = 'routes/routeDetails/START_LOAD';
const COMPLETE_LOAD = 'routes/routeDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeDetails/FAIL_LOAD';
const START_SAVE = 'routes/routeDetails/START_SAVE';
const COMPLETE_SAVE = 'routes/routeDetails/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/routeDetails/FAIL_SAVE';
const RESET = 'routes/routeDetails/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  routeDetails?: RouteDetails;
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  routeDetails: undefined,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: !action.noLoading,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeDetails: action.routeDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeDetails: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = (noLoading?: boolean) => ({
  type: START_LOAD,
  noLoading,
});

const completeLoad = (routeDetails: RouteDetails) => ({
  type: COMPLETE_LOAD,
  routeDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadRouteDetails = (vendorId: number, routeId: number, noLoading?: boolean) => (dispatch: Dispatch) => {
  dispatch(startLoad(noLoading));
  const loadRouteDetailsPromise = doLoadRouteDetails(vendorId, routeId);
  loadRouteDetailsPromise.then(routeDetails => dispatch(completeLoad(routeDetails))).catch(() => dispatch(failLoad()));
  return loadRouteDetailsPromise;
};

export const loadSnowSweeperRouteDetails =
  (vendorId: number, routeId: number, startDate?: Date | string, endDate?: Date | string, noLoading?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad(noLoading));
    const loadRouteDetailsPromise = doLoadSnowSweeperRouteDetails(vendorId, routeId, startDate, endDate);
    loadRouteDetailsPromise
      .then(routeDetails => dispatch(completeLoad(routeDetails)))
      .catch(() => dispatch(failLoad()));
    return loadRouteDetailsPromise;
  };

export const updateRouteDetails =
  (vendorId: number, routeId: number, routeDetails: RouteDetailsDataToSubmit) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const updateRouteDetailsPromise = doUpdateRouteDetails(vendorId, routeId, routeDetails);
    updateRouteDetailsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return updateRouteDetailsPromise;
  };

export const resetRouteDetails = () => ({
  type: RESET,
});
