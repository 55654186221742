import { http } from 'src/core/services/http';
import { RoutePlannerMetric } from '../interfaces/routePlanner/RoutePlannerMetric';

export const loadRoutePlannerRouteMetricsSettings = (vendorId: number) =>
  http.get(`${vendorId}/routePlanner/routes/metricSettings`).then(response => response.data);

export const saveRoutePlannerRouteMetricsSettings = (vendorId: number, data: RoutePlannerMetric[]) =>
  http.put(`${vendorId}/routePlanner/routes/metricSettings`, data).then(response => response.data);

export const loadRoutePlannerRouteTemplateMetricsSettings = (vendorId: number) =>
  http.get(`${vendorId}/routePlanner/routeTemplates/metricSettings`).then(response => response.data);

export const saveRoutePlannerRouteTemplateMetricsSettings = (vendorId: number, data: RoutePlannerMetric[]) =>
  http.put(`${vendorId}/routePlanner/routeTemplates/metricSettings`, data).then(response => response.data);
