import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { reset } from 'redux-form';

import { TableActionIcon, TableCell, TableRow, Text } from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { PaymentInfoFillIcon, PaymentInfoFillIconPopUpOpen } from '../../../opportunity/components/styled';
import isFalsy from '../../../utils/services/isFalsy';
import { getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { LIMIT_PER_PAGE } from '../../constants';
import {
  updateIssueReportProcessedServices,
  updateProcessedServices,
  updateUndoAcceptProcessedServices,
  updateUndoReportProcessedServices,
} from '../../ducks';
import { DetailsWorkOrderRateModal } from '../modal';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface ComponentProps extends RouteComponentProps<RouteParams> {
  account?: string;
  action?: number;
  confirmed?: string;
  customerName?: string;
  iconStatus?: string;
  invoiceUpdatedDate?: string;
  locationName?: string;
  locationState?: string;
  locationZip?: string;
  loctionAddress?: string;
  loctionCity?: string;
  material?: string;
  quantity?: string;
  rateCodes: any[];
  scheduledDate?: string;
  serviceDescr?: string;
  serviceFreq?: string;
  serviceType?: string;
  status?: string;
  vendName?: string;
  vendorAddress?: string;
  vendorCity?: string;
  vendorState?: string;
  vendorZip?: string;
  workOrder: number;
  workOrderIdentifier?: string;
  woTotal?: number;
}

interface Props extends ComponentProps {
  pricingInfo: any;
  issueType: any;
  vendorId?: number;
  updateProcessedServices(...args: any[]): any;
  updateUndoReportProcessedServices(...args: any[]): any;
  updateUndoAcceptProcessedServices(...args: any[]): any;
  updateIssueReportProcessedServices(...args: any[]): any;
  reset(...args: any[]): any;
}

interface State {
  isAcceptProcessedServicesModalOpen: boolean;
  isReportIssueProcessedServicesModalOpen: boolean;
  isDetailsOnCallWorkOrderRateModalOpen: boolean;
}

const TableActionIconMock = TableActionIcon as any;
const PaymentInfoFillIconMock = PaymentInfoFillIcon as any;

class ProcessedServicesFormTableRow extends PureComponent<Props, State> {
  readonly state = {
    isAcceptProcessedServicesModalOpen: false,
    isReportIssueProcessedServicesModalOpen: false,
    isDetailsOnCallWorkOrderRateModalOpen: false,
  };

  // Undo
  onUndoSubmit = async () => {
    const {
      vendorId,
      workOrder,
      scheduledDate,
      action,
      rateCodes,
      location,
      pricingInfo,
      match: {
        params: { token },
      },
    } = this.props;
    const { page } = getQueryParams(location.search);
    const requestRecurringServicesObj: any = {};

    requestRecurringServicesObj.workOrder = workOrder;
    requestRecurringServicesObj.date = moment(pricingInfo.reportDate).format('YYYY-MM-DD');

    if (action === 1) {
      requestRecurringServicesObj.rubiconInvoiceNo = pricingInfo.rubiconInvoiceNo;
      requestRecurringServicesObj.rateCodes = rateCodes;

      await this.props
        .updateUndoAcceptProcessedServices(requestRecurringServicesObj, vendorId, token, page, LIMIT_PER_PAGE)
        .then(() => {
          createSuccessNotification(translate('opportunity.opportunitySave'));
          //   onCancel(true);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
    } else if (action === 2) {
      requestRecurringServicesObj.scheduleDate = moment(scheduledDate).format('YYYY-MM-DD');

      await this.props
        .updateUndoReportProcessedServices(requestRecurringServicesObj, vendorId, token, page, LIMIT_PER_PAGE)
        .then(() => {
          createSuccessNotification(translate('opportunity.opportunitySave'));
          //   onCancel(true);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
    }
  };

  // Details
  openDetailsOnCallWorkOrderRateModalOpen = (event: any) => {
    this.setState({ isDetailsOnCallWorkOrderRateModalOpen: true });
    event.stopPropagation();

    return false;
  };

  closeDetailsOnCallWorkOrderRateModalOpen = () => {
    this.props.reset('acceptRecurringServices');
    this.setState({ isDetailsOnCallWorkOrderRateModalOpen: false });
  };

  // Accept
  openAcceptProcessedServicesModal = (event: any) => {
    this.setState({ isAcceptProcessedServicesModalOpen: true });
    event.stopPropagation();

    return false;
  };

  closeAcceptProcessedServicesModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('acceptRecurringServices');
    this.setState({ isAcceptProcessedServicesModalOpen: false });
  };

  // Report Issue
  openReportIssueProcessedServicesModal = () => {
    this.setState({ isReportIssueProcessedServicesModalOpen: true });
  };

  closeReportIssueProcessedServicesModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('reportIssueRecurringServices');
    this.setState({ isReportIssueProcessedServicesModalOpen: false });
  };

  render() {
    const {
      workOrderIdentifier,
      workOrder,
      account,
      vendName,
      customerName,
      invoiceUpdatedDate,
      vendorAddress,
      vendorCity,
      vendorState,
      vendorZip,
      locationName,
      loctionAddress,
      loctionCity,
      locationState,
      locationZip,
      confirmed,
      rateCodes,
      serviceDescr,
      material,
      serviceType,
      scheduledDate,
      serviceFreq,
      quantity,
      status,
      woTotal,
      iconStatus,
    } = this.props;

    return (
      <TableRow key={workOrderIdentifier}>
        <TableCell vertical width="8%" padding="no no xSmall xSmall">
          {vendName && (
            <Fragment>
              <Text block weight="normal" margin="no no no" size="small">
                {status}
              </Text>
              <Text block weight="normal" margin="no no no" size="small">
                <TableActionIconMock icon={iconStatus} size="medium" />
              </Text>
            </Fragment>
          )}
        </TableCell>
        <TableCell vertical width="16%" padding="no xSmall xSmall xSmall">
          {vendName && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.name')}:{' '}
              </Text> */}
              <Text block weight="medium" margin="no no xxSmall" size="small">
                {vendName}
              </Text>
            </Fragment>
          )}
          {vendorAddress && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.address')}:{' '}
              </Text> */}
              <Text block margin="no no xxSmall" weight="light" size="small">
                {` ${vendorAddress}, ${vendorCity}, ${vendorState}, ${vendorZip}`}
              </Text>
            </Fragment>
          )}
        </TableCell>
        <TableCell width="16%" vertical>
          {locationName && (
            <Fragment>
              <Text weight="normal" margin="no no xxSmall" size="small">
                {customerName} ({locationName})
              </Text>
            </Fragment>
          )}
          {loctionAddress && (
            <Text block margin="no no xxSmall" weight="light" size="small">
              {` ${loctionAddress}, ${loctionCity}, ${locationState}, ${locationZip}`}
            </Text>
          )}
        </TableCell>
        <TableCell width="12%" vertical>
          {workOrder && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('payment.rubiconWO')}:{' '}
              </Text>

              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {workOrder}
              </Text>
            </Fragment>
          )}
          {account && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.account')}:{' '}
              </Text>
              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {account}
              </Text>
            </Fragment>
          )}
          {!isFalsy(woTotal, { allowZero: true }) && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('payment.woTotal')}:{' '}
              </Text>
              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                ${woTotal}
              </Text>
              <Text margin="no no no" weight="normal" size="small" singleLine>
                <PaymentInfoFillIconPopUpOpen onClick={this.openDetailsOnCallWorkOrderRateModalOpen}>
                  <Text margin="no no no" size="small" singleLine color="gray">
                    {translate('payment.details')}
                  </Text>

                  <PaymentInfoFillIconMock color="primary" margin="no no no" />
                </PaymentInfoFillIconPopUpOpen>
                {this.state.isDetailsOnCallWorkOrderRateModalOpen && (
                  <DetailsWorkOrderRateModal
                    workOrderIdentifier={workOrderIdentifier}
                    rateCodes={rateCodes}
                    onCancel={this.closeDetailsOnCallWorkOrderRateModalOpen}
                  />
                )}
              </Text>
            </Fragment>
          )}
        </TableCell>
        <TableCell vertical width="16%" padding="xSmall xSmall xSmall xSmall">
          {serviceDescr && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('payment.serviceDescription')}:{' '}
              </Text>

              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {serviceType}
              </Text>
              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {serviceDescr}
              </Text>
            </Fragment>
          )}
          {material && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.material')}:{' '}
              </Text> */}
              <Text weight="normal" size="small" singleLine>
                {material}
              </Text>
            </Fragment>
          )}
          {serviceFreq && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('opportunity.frequency')}:{' '}
              </Text> */}
              <Text weight="normal" size="small" singleLine>
                {serviceFreq}
              </Text>
            </Fragment>
          )}
          {quantity && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('opportunity.quantity')}:{' '}
              </Text> */}
              <Text weight="normal" size="small" singleLine>
                {quantity}
              </Text>
            </Fragment>
          )}
          {scheduledDate && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('opportunity.scheduledDate')}:{' '}
              </Text>
              <Text weight="normal" size="small" singleLine>
                {scheduledDate}
              </Text>
            </Fragment>
          )}
        </TableCell>

        <TableCell vertical width="12%">
          {confirmed && (
            <Fragment>
              <Text margin="no no xxSmall" weight="normal" size="small" align="center" singleLine>
                {confirmed}
              </Text>
            </Fragment>
          )}
        </TableCell>
        <TableCell vertical width="20%">
          {workOrder && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('payment.rubiconInvoiceNo')}#:{' '}
              </Text> */}

              <Text margin="no no no" weight="normal" size="small">
                {rateCodes[0].rubiconInvoiceNo}
              </Text>
              <Text margin="no no no" size="small" color="gray" singleLine>
                {translate('payment.releasedOn')}:
              </Text>
              <Text margin="no no no" size="small" color="gray" singleLine>
                {moment(invoiceUpdatedDate).format('YYYY-MM-DD HH:mm A')}
              </Text>
            </Fragment>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

// const formSelector = formValueSelector('recurringServicesForm');

const mapStateToProps = (state: AppState) => ({
  // rateCodes: formSelector(state, `workOrdersInfo.settings${ownProps.workOrderIdentifier}.rateCodes`),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  pricingInfo: state.payments.processedServices.pricingInfo,
  issueType: state.payments.processedServices.issueType,
});

const mapDispatchToProps = {
  updateProcessedServices,
  updateUndoReportProcessedServices,
  updateUndoAcceptProcessedServices,
  updateIssueReportProcessedServices,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcessedServicesFormTableRow));
