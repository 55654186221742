import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const IMPERIAL = 1;
export const METRIC = 2;

export const MEASUREMENT_TYPES = mapKeys(
  [
    { id: IMPERIAL, name: translate('vendors.measurementTypes.imperial') },
    { id: METRIC, name: translate('vendors.measurementTypes.metric') },
  ],
  'id',
);
