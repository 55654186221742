import { http } from '../../core/services/http';
import { CustomerPortalRoles } from '../ducks/customerPortal';

export const loadCustomerPortalRoles = () =>
  http
    .get(`/common/systemTypes/customerPortalRoles`)
    .then(response => response.data.map((roles: CustomerPortalRoles) => ({ label: roles.name, value: roles.id })));

export const resendRegistrationEmail = (contactId?: number) =>
  http.post(`/contacts/${contactId}/resendRegEmail`).then(response => response.data);
