import styled from 'styled-components';
import { transparentize } from 'polished';

interface Props {
  small?: boolean;
}

export const CommunityInsightsIcon = styled.img<Props>`
  width: 18px;
  height: 18px;
  margin: ${props => (props.small ? '-2px 8px 0 0' : '0 8px 0 0')};
`;

export const MapLegend = styled.div`
  position: absolute;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  border: 1px solid #f2f2f5;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};
  padding: 10px;
`;
