import { debounce } from 'lodash-es';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { PanelSearch, TypedField } from 'src/core/components';

interface PropsWithoutReduxForm {
  onSearchTermChange: (searchTerm?: string) => void;
  formName?: string;
  placeholder?: string;
}

interface FormData {
  searchTerm?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const RoutePlannerFilterableSectionSearchForm = (props: Props) => {
  const { placeholder, handleSubmit, onSearchTermChange } = props;

  const onSearchTermChangeHandler = debounce((event: any, searchTerm: string) => {
    onSearchTermChange(searchTerm);
  }, 300);

  return (
    <form onSubmit={handleSubmit}>
      <TypedField
        name="searchTerm"
        component={PanelSearch}
        onChange={onSearchTermChangeHandler}
        props={{
          margin: 'no',
          isClearable: true,
          placeholder,
        }}
      />
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  enableReinitialize: true,
})(RoutePlannerFilterableSectionSearchForm);
