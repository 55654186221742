import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Icon } from '../../../../core/components';
import { BareButtonStyle } from '../../../../core/components/styled';
import { REACT_WINDOW_CLASS_NAME } from '../../../../core/constants/reactWindow';
import { fadeAndSlideInDown, loadingOverlay } from '../../../../core/styles';
import { sizeMapper } from '../../../../utils/styles';

export const DispatchBoardFilters = styled.div<{ isHidden?: boolean }>`
  padding: 13px;
  border-bottom: 1px solid ${props => props.theme.grayLight};

  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

export const DispatchBoardFlexOverflowWrapper = styled.div`
  flex: 1;
  overflow: auto;
  text-align: left;

  .${REACT_WINDOW_CLASS_NAME} {
    height: 100% !important;
  }
`;

export const DispatchBoardSubPanel = styled.div<{ isFilterFocused?: boolean; isLoading?: boolean }>`
  background: #fff;
  height: 100%;
  overflow: ${props => (props.isFilterFocused ? 'visible' : 'hidden')};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const InfoIcon = styled(Icon).attrs({ icon: 'infoFill' })`
  width: 12px;
  height: 12px;
  color: ${props => props.theme.grayDark};
  transition: color 0.3s ease-out;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const DispatchBoardMapIcon = styled(Icon)<{ disabled?: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: 10px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const DispatchBoardMapText = styled.span<{ disabled?: boolean }>`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const RouteBuilderSuccessMessage = styled.div`
  padding: 20px;
`;

export const RouteBuilderFailedStops = styled.div`
  margin-top: 15px;
  a {
    color: ${p => p.theme.brandPrimary};
  }
  div {
    margin-bottom: 5px;
  }
`;

export const DispatchBoardPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const DispatchBoardPanelTitle = styled.h2`
  margin: 0 auto 0 0;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 24px;
`;

export const DispatchBoardJobMapCounter = styled.div<{ isActive?: boolean }>`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.brandPrimary};
      cursor: pointer;
    `};

  ${props =>
    !props.isActive &&
    css`
      color: ${props => props.theme.grayDark};
    `};
`;

export const DispatchBoardPanelTitleGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const DispatchBoardPanelHeader = styled.div<{ withBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0;

  ${props =>
    props.withBorder &&
    css`
      padding-bottom: 15px;
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `};
`;

export const DispatchBoardPanelFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 70px;
  padding: 15px;
`;

export const DispatchBoardInfiniteWrapper = styled.div<{ maxHeight?: number; isDropTarget?: boolean }>`
  height: 100%;
  max-height: ${p => p.maxHeight}px;

  ${p =>
    p.isDropTarget &&
    css`
      background-color: ${transparentize(0.5, p.theme.brandPrimary)};
    `}
`;

export const DispatchBoardScrollMarker = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.9) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(36, 40, 51, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;

  &:before {
    content: '\u203A';
    background-color: rgba(255, 255, 255, 0.7);
    width: 45px;
    height: 45px;
    color: ${props => props.theme.gray};
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    box-shadow: 0 0 5px 0 rgba(36, 40, 51, 0.12);
    border-radius: 50%;
    margin-left: -22px;
    transform: rotate(90deg);
    position: absolute;
    top: -22px;
    left: 50%;
    box-sizing: border-box;
  }
`;

export const DispatchBoardJobsSelectedWrapper = styled.div<{
  padding?: string;
  isOpen?: boolean;
}>`
  width: 100%;
  justify-content: space-between;
  padding: ${props => sizeMapper(props.padding, 'xSmall no no')};
  align-items: center;

  ${props =>
    props.isOpen
      ? css`
          display: flex;
          animation: ${fadeAndSlideInDown} 0.3s ease-out;
        `
      : css`
          display: none;
        `}
`;

export const DispatchBoardJobsSelectedTextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const DispatchBoardJobsSelectedNoOfJobs = styled.span<{
  moreThanMaxSelected?: boolean;
}>`
  color: ${props => (props.moreThanMaxSelected ? props.theme.brandAlert : props.theme.brandSuccess)};
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeightMedium};
  line-height: 23px;
`;

export const DispatchBoardJobsSelectedInstructions = styled.span`
  color: ${props => props.theme.brandSuccess};
  font-size: 10px;
`;

export const DispatchBoardRouteBuilderSelectedJobsCounter = styled.span<{
  moreThanMaxSelected?: boolean;
  padding?: string;
}>`
  color: ${props => (props.moreThanMaxSelected ? props.theme.brandAlert : props.theme.brandSuccess)};
  padding: ${props => sizeMapper(props.padding, 'no')};
`;

export const DispatchBoardPanelContent = styled.div<{ isHidden?: boolean }>`
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `};
`;

export const DispatchBoardPanel = styled.div<{ isLoading?: boolean }>`
  position: relative;
  min-height: 100%;
  height: 100%;

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const DispatchBoardContainerError = styled.div<{ margin?: string }>`
  max-width: 600px;
  text-align: center;
  margin: ${props => sizeMapper(props.margin, 'auto')};
  color: ${props => props.theme.brandAlert};
`;

export const DispatchBoardMapDivContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const DispatchBoardPaneBackButtonIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: ${props => props.theme.brandPrimary};
`;

export const DispatchBoardPaneBackButton = styled.button<{
  padding?: string;
}>`
  ${BareButtonStyle}
  line-height: 23px;
  top: 2px;
  padding: ${props => sizeMapper(props.padding, 'auto')};
`;

export const SelectOnMap = styled.button`
  ${BareButtonStyle}
  color: ${props => props.theme.brandPrimary};
  line-height: 23px;
`;

export const PinOnMapWrapper = styled.div<{ sourceIsProximity?: boolean }>`
  ${props =>
    props.sourceIsProximity &&
    css`
      margin-bottom: 70px;
    `};
`;
