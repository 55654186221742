import { identity, map } from 'lodash-es';
import { createSelector } from 'reselect';
import { getQueryParams } from '../../utils/services/queryParams';

const getRouteTemplatesFormInitialValues = (queryString: string, vehicleFiltersPreferencesIds: number[]) => {
  const { daysOfServiceIds, vehicleTypeIds, routeStatus, routeTemplateName, groupIds } = getQueryParams(queryString);
  return {
    routeTemplateName,
    daysOfServiceIds: daysOfServiceIds && map(daysOfServiceIds.toString().split(','), Number),
    routeStatus: routeStatus && Number(routeStatus),
    vehicleTypeIds: vehicleTypeIds ? map(vehicleTypeIds.toString().split(','), Number) : vehicleFiltersPreferencesIds,
    groupIds: groupIds ? map(groupIds.toString().split(','), Number) : [],
  };
};

const routeTemplatesFormInitialValuesSelector = createSelector(getRouteTemplatesFormInitialValues, identity);
export default routeTemplatesFormInitialValuesSelector;
