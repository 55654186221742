import { memo, FC } from 'react';
import { Layer, Source } from 'react-map-gl';

import { StreetNetworkPropertiesTravelPath } from 'src/routes/interfaces/TravelPath';

export const STREET_NETWORK_OPTIONS_TO_CHOOSE_SOURCE = 'streetNetworkOptionsToChooseSource';
export const STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER = 'streetNetworkOptionsToChooseLayer';
export const STREET_NETWORK_OPTIONS_TO_CHOOSE_ARROWS_SOURCE = 'streetNetworkOptionsToChooseArrowsSource';
export const STREET_NETWORK_OPTIONS_TO_CHOOSE_ARROWS_LAYER = 'streetNetworkOptionsToChooseArrowsLayer';
export const STREET_NETWORK_OPTIONS_TO_CHOOSE_ARROWS_ON_LINE_LAYER = 'streetNetworkOptionsToChooseArrowsOnLineLayer';
interface Props {
  streetNetworkOptions: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
  streetNetworkOptionsArrowsPoints: GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties>;
}

const StreetNetworkOptionsToChooseGLSource: FC<Props> = ({
  streetNetworkOptions,
  streetNetworkOptionsArrowsPoints,
}) => {
  return (
    <>
      <Source type="geojson" data={streetNetworkOptions} id={STREET_NETWORK_OPTIONS_TO_CHOOSE_SOURCE}>
        {/* solid lines */}
        <Layer
          id={STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER}
          type="line"
          source={STREET_NETWORK_OPTIONS_TO_CHOOSE_SOURCE}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          paint={{
            'line-color': '#87CEFA',
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 5, 22, 15],
          }}
        />
        <Layer
          id={STREET_NETWORK_OPTIONS_TO_CHOOSE_ARROWS_ON_LINE_LAYER}
          type="symbol"
          source={STREET_NETWORK_OPTIONS_TO_CHOOSE_SOURCE}
          minzoom={15}
          layout={{
            'symbol-placement': 'line',
            'text-field': '▶',
            'text-anchor': 'center',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 20, 22, 50],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-rotate': ['get', 'rotate'],
          }}
          paint={{
            'text-color': '#87CEFA',
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3,
          }}
        />
      </Source>
      <Source
        type="geojson"
        data={streetNetworkOptionsArrowsPoints}
        id={STREET_NETWORK_OPTIONS_TO_CHOOSE_ARROWS_SOURCE}
      >
        <Layer
          id={STREET_NETWORK_OPTIONS_TO_CHOOSE_ARROWS_LAYER}
          type="symbol"
          source={STREET_NETWORK_OPTIONS_TO_CHOOSE_ARROWS_SOURCE}
          minzoom={15}
          layout={{
            'symbol-placement': 'point',
            'text-field': '▶',
            'text-anchor': 'center',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 20, 22, 50],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-rotate': ['get', 'rotate'],
          }}
          paint={{
            'text-color': '#87CEFA',
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3,
          }}
        />
      </Source>
    </>
  );
};

export default memo(StreetNetworkOptionsToChooseGLSource);
