import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const ACTIVE_RATE_STATUS = 'active';
export const INACTIVE_RATE_STATUS = 'inactive';

export const RATE_STATUSES_LIST = [
  { technicalName: ACTIVE_RATE_STATUS, name: translate('common.active') },
  { technicalName: INACTIVE_RATE_STATUS, name: translate('common.inactive') },
];

export const RATE_STATUSES = mapKeys(RATE_STATUSES_LIST, 'technicalName');
