import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Grid } from '../../../core/components/styled';
import { Checkbox } from '../../../core/components';
import { CloseInstructions } from '../styled/RouteMap';
import translate from '../../../core/services/translate';

const RouteSequenceInstructionsForm: React.FC<InjectedFormProps<any>> = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid alignRight centered>
        <Field name="doNotShowAgain" component={Checkbox} label={translate('routes.doNotShowThisAgain')} />
        <CloseInstructions type="submit">X</CloseInstructions>
      </Grid>
    </form>
  );
};

export default reduxForm<any>({ form: 'routeSequenceInstructionsForm' })(RouteSequenceInstructionsForm);
