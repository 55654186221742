import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadExcludeCustomerFromProcessingFee as doLoadExcludeCustomerFromProcessingFee } from '../services/customerFeeExclude';

// Actions
const START_LOAD = 'vendors/excludeCustomerFromProcessingFee/START_LOAD';
const COMPLETE_LOAD = 'vendors/excludeCustomerFromProcessingFee/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/excludeCustomerFromProcessingFee/FAIL_LOAD';
const RESET = 'vendors/excludeCustomerFromProcessingFee/RESET';

// Initial state
const initialState = {
  isLoading: false,
  excludeCustomerFromProcessingFee: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          excludeCustomerFromProcessingFee: action.excludeCustomerFromProcessingFee,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (excludeCustomerFromProcessingFee: boolean) => ({
  type: COMPLETE_LOAD,
  excludeCustomerFromProcessingFee,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const reset = () => ({
  type: RESET,
});

export const loadExcludeCustomerFromProcessingFee = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadExcludeCustomerFromProcessingFeePromise = doLoadExcludeCustomerFromProcessingFee();
  loadExcludeCustomerFromProcessingFeePromise
    .then((excludeCustomerFromProcessingFee: any) => {
      dispatch(completeLoad(excludeCustomerFromProcessingFee));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadExcludeCustomerFromProcessingFeePromise;
};

export const resetExcludeCustomerFromProcessingFee = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
