import { get } from 'lodash-es';
import moment from 'moment';
import { MouseEvent } from 'react';

import { PermissionGuard } from 'src/account/components';
import { ROUTES_PLANNER_CREATE, ROUTES_PLANNER_SELECTION, ROUTES_TRACKER_SEQUENCE_ROUTE } from 'src/account/constants';
import { checkIfSupport, checkIfViewOnly, hasPermission } from 'src/account/utils/permissions';
import { hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { ROUTE_SEQUENCE_STATUS_ICONS, ROUTE_SEQUENCE_TOOLTIP_COLORS } from 'src/routes/constants';
import { DISPATCH_BOARD_VEHICLE_TYPE_IDS } from 'src/routes/constants/dispatchBoard';
import { RoutePlannerTableData } from 'src/routes/interfaces/routePlanner/RoutePlannerTableData';
import { getTimeDifference, routePlannerRoutesTableCellsWidths } from 'src/routes/utils/routePlanner';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';

interface Props extends RoutePlannerTableData {
  isChecked: boolean;
  routeSequenceStatus: string;
  jobId: number;
  selectRoute: (routeId?: number, routeTemplateId?: number) => void;
  deleteRoute: (isDaily: boolean, id: number) => void;
  cloneRoute: (isDaily: boolean, id: number, scheduledDay: number, event: MouseEvent<HTMLButtonElement>) => void;
  handleScheduleClick: (templateId?: number) => void;
  onTableRowClick: (
    isDaily: boolean,
    id: number,
    routeSequenceStatus: string,
    jobId: number,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => void;
  openAddJobModal: (routeId?: number, vehicleTypeId?: number, date?: string) => void;
}

const RoutePlannerRouteTableRow = ({
  date,
  description,
  driverFirstName,
  driverLastName,
  endTime,
  isActive,
  isChecked,
  jobId,
  name,
  numberOfStops,
  routeId,
  routeSequenceStatus,
  routeTemplateId,
  scheduledDay,
  startTime,
  vehicleName,
  vehicleTypeId,
  wasteMaterialTypeId,
  selectRoute,
  deleteRoute,
  cloneRoute,
  handleScheduleClick,
  onTableRowClick,
  openAddJobModal,
}: Props) => {
  const filtersPreferences = useSelector(state => state.common.filters.filters);
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const wasteTypes = useSelector(state => state.common.wasteTypes.wasteTypes);
  const hasRouteSequencePermission = hasPermission(ROUTES_TRACKER_SEQUENCE_ROUTE);

  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;
  const routeType = vehicleTypeId ? VEHICLE_TYPE_IDS[vehicleTypeId]?.name : '';
  const materialType = wasteMaterialTypeId
    ? wasteTypes.find(wasteType => wasteType.id === wasteMaterialTypeId)?.name
    : '';
  const hasPermissionsBasedOnGlobalFilters = hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId });
  const vehicleTypeSupportsJobAdd = vehicleTypeId && DISPATCH_BOARD_VEHICLE_TYPE_IDS.indexOf(vehicleTypeId) !== -1;

  const isDailyRoute = !!routeId;
  const isRouteTemplate = !!routeTemplateId && !routeId;

  const isViewOnly = checkIfViewOnly();
  const isSupport = checkIfSupport();

  const isViewOnlyOrSupport = isViewOnly || isSupport;

  const canAddJob =
    isDailyRoute &&
    vehicleTypeSupportsJobAdd &&
    !isViewOnly &&
    !isSnowPlowRoute &&
    !isStreetSweeperRoute &&
    hasPermissionsBasedOnGlobalFilters;

  const id = isDailyRoute ? routeId : (routeTemplateId as number);

  return (
    <>
      <TableRow
        height={TABLE_ROW_HEIGHT_SMALL}
        onClick={() => onTableRowClick(isDailyRoute, id, routeSequenceStatus, jobId, false, vehicleTypeId)}
      >
        <TableCell width={routePlannerRoutesTableCellsWidths[0]} padding="xxSmall xxSmall">
          <PermissionGuard permission={ROUTES_PLANNER_SELECTION}>
            <UnconnectedCheckbox
              name={`selected_${routeId}_${routeTemplateId}`}
              checked={isChecked}
              onClick={event => event.stopPropagation()}
              onChange={(e: any) => {
                e.stopPropagation();
                selectRoute(routeId, routeTemplateId);
              }}
            />
          </PermissionGuard>
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[1]} padding="xxSmall xxSmall">
          <Text block singleLine title={routeType}>
            {routeType}
          </Text>
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[2]} padding="xxSmall xxSmall">
          <Text block singleLine title={name}>
            {name}
          </Text>
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[3]} padding="xxSmall xxSmall">
          {vehicleName && (
            <Text block singleLine title={vehicleName}>
              {vehicleName}
            </Text>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[4]} padding="xxSmall xxSmall">
          {(driverFirstName || driverLastName) && (
            <Text block singleLine title={`${driverFirstName} ${driverLastName}`}>
              {`${driverFirstName} ${driverLastName}`}
            </Text>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[5]} padding="xxSmall xxSmall">
          {materialType && !isSnowPlowRoute && !isStreetSweeperRoute && (
            <Text block singleLine title={materialType}>
              {materialType}
            </Text>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[6]} padding="xxSmall xxSmall">
          {description && (
            <Text block singleLine title={description}>
              {description}
            </Text>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[7]} padding="xxSmall xxSmall">
          {numberOfStops && (
            <Text block singleLine title={numberOfStops.toString()}>
              {numberOfStops}
            </Text>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[8]} padding="xxSmall xxSmall">
          {startTime && (
            <Text block singleLine title={moment(startTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}>
              {moment(startTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}
            </Text>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[9]} padding="xxSmall xxSmall">
          {endTime && (
            <Text block singleLine title={moment(endTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}>
              {moment(endTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}
            </Text>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[10]} padding="xxSmall xxSmall">
          {endTime && startTime && (
            <Text block singleLine title={getTimeDifference(endTime, startTime)}>
              {getTimeDifference(endTime, startTime)}
            </Text>
          )}
        </TableCell>
        <TableCell width="4%" padding="no">
          {routeSequencingEnabled && routeSequenceStatus && hasRouteSequencePermission && (
            <TableActionButton>
              <ActionButtonTooltip
                color={(ROUTE_SEQUENCE_TOOLTIP_COLORS as any)[routeSequenceStatus]}
                imageSrc={get(ROUTE_SEQUENCE_STATUS_ICONS[routeSequenceStatus], 'icon')}
                tooltip={`routeSequence${routeSequenceStatus}`}
              />
            </TableActionButton>
          )}
        </TableCell>
        <TableCell width={routePlannerRoutesTableCellsWidths[11]} padding="xxSmall xxSmall" align="right">
          {canAddJob && (
            <TableActionButton
              color="grayDarker"
              onClick={(e: any) => {
                e.stopPropagation();
                openAddJobModal(routeId, vehicleTypeId, moment(date).format('DD/MM/YYYY'));
              }}
            >
              <ActionButtonTooltip iconSize="sMedium" icon="plusSquared" tooltip="addJob" customViewBox="0 0 20 15" />
            </TableActionButton>
          )}
          {!isViewOnlyOrSupport && (
            <TableActionButton
              color="grayDarker"
              onClick={(e: any) => {
                e.stopPropagation();
                onTableRowClick(isDailyRoute, id, routeSequenceStatus, jobId, true, vehicleTypeId);
              }}
            >
              <ActionButtonTooltip iconSize="sMedium" icon="edit" tooltip="edit" />
            </TableActionButton>
          )}
          {!isSnowPlowRoute &&
            !isStreetSweeperRoute &&
            hasPermissionsBasedOnGlobalFilters &&
            (isRouteTemplate ? isActive && !!numberOfStops : true) && (
              <TableActionButton
                color="grayDarker"
                onClick={(e: any) => {
                  cloneRoute(isDailyRoute, id, scheduledDay || 1, e);
                }}
              >
                <PermissionGuard permission={ROUTES_PLANNER_CREATE}>
                  <ActionButtonTooltip
                    iconSize="sMedium"
                    icon="copyPaste"
                    tooltip={isDailyRoute ? 'copyTracker' : 'createRoute'}
                    customViewBox="0 0 20 20"
                  />
                </PermissionGuard>
              </TableActionButton>
            )}
          {isRouteTemplate && isActive && !!numberOfStops && !isViewOnlyOrSupport && (
            <TableActionButton
              color="grayDarker"
              onClick={(e: any) => {
                e.stopPropagation();
                handleScheduleClick(id);
              }}
            >
              <ActionButtonTooltip
                iconSize="sMedium"
                icon="calendarReload"
                tooltip="scheduleReoccurringRoutes"
                customViewBox="0 0 20 20"
              />
            </TableActionButton>
          )}
          {!isSnowPlowRoute && !isStreetSweeperRoute && hasPermissionsBasedOnGlobalFilters && !isViewOnlyOrSupport && (
            <TableActionButton
              color="grayDarker"
              onClick={(e: any) => {
                e.stopPropagation();
                deleteRoute(isDailyRoute, id);
              }}
            >
              <ActionButtonTooltip
                iconSize="sMedium"
                icon="delete"
                tooltip={isDailyRoute ? 'deleteDailyRoute' : 'deleteReoccurringRoute'}
              />
            </TableActionButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default RoutePlannerRouteTableRow;
