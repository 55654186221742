import { map, omit } from 'lodash-es';

import { IEventLog, RawEventLog } from '../interfaces/EventLog';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadEventLog = (eventLog: RawEventLog[]): IEventLog[] =>
  map(eventLog, event => ({
    ...event,
    sessionDetails: map(event.sessionDetails, sessionDetail => ({
      ...omit(sessionDetail, 'cPUUsage'),
      cpuUsage: sessionDetail.cPUUsage,
      actionName: translate(createTranslationKey(sessionDetail.actionName, 'routes.eventTypes')),
    })),
    routeSessionZDeviceDetails: map(event.routeSessionZDeviceDetails, sessionDetail => ({
      creationTimeStamp: sessionDetail.creationTimeStamp,
      appStatus: sessionDetail.appStatus,
      wiFiStatus: sessionDetail.wiFiStatus,
      speed: sessionDetail.speed,
      camera1Status: sessionDetail.camera1Status,
      camera2Status: sessionDetail.camera2Status,
      camera1FPS: sessionDetail.camera1FPS,
      camera2FPS: sessionDetail.camera2FPS,
      imageQueue: sessionDetail.imageQueue,
    })),
  }));

export default transformLoadEventLog;
