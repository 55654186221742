import React, { MouseEvent } from 'react';

import { ActionButtonTooltip } from '../../../../core/components';
import { PermissionGuard } from '../../../../account/components';
import { ROUTES_PLANNER_SERVICE_ZONES_DELETE, ROUTES_PLANNER_SERVICE_ZONES_EDIT } from '../../../../account/constants';
import { TableActionButton, TableCell, TableRow } from '../../../../core/components/styled';

interface Props {
  deleteServiceZone: (id: number, event: MouseEvent<HTMLButtonElement>) => void;
  editServiceZone: (id: number) => void;
  id: number;
  isServiceZoneActive: string;
  name: string;
  routeTemplateCount?: number;
}

const ServiceZonesPageTableRow: React.FC<Props> = ({
  deleteServiceZone,
  editServiceZone,
  id,
  isServiceZoneActive,
  name,
  routeTemplateCount,
}) => (
  <TableRow>
    <TableCell width="50%">{name}</TableCell>
    <TableCell width="20%">{routeTemplateCount}</TableCell>
    <TableCell width="15%">{isServiceZoneActive}</TableCell>
    <TableCell width="15%" align="right">
      <PermissionGuard permission={ROUTES_PLANNER_SERVICE_ZONES_EDIT}>
        <TableActionButton id={`edit-service-${id}-button`} onClick={() => editServiceZone(id)}>
          <ActionButtonTooltip icon="edit" tooltip="editServiceZone" />
        </TableActionButton>
      </PermissionGuard>
      <PermissionGuard permission={ROUTES_PLANNER_SERVICE_ZONES_DELETE}>
        <TableActionButton id={`delete-service-${id}-button`} onClick={event => deleteServiceZone(id, event)}>
          <ActionButtonTooltip icon="delete" tooltip="deleteServiceZone" />
        </TableActionButton>
      </PermissionGuard>
    </TableCell>
  </TableRow>
);

export default ServiceZonesPageTableRow;
