import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { loadRecurringServices } from '../../ducks/recurringServices';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { DatePicker } from '../../../core/components';
import translate from '../../../core/services/translate';
import pricingInfoIntialValueSelector from '../../services/pricingInfoIntialValueSelector';
import { AppState } from '../../../store';
import { isDateValidValidator } from 'src/utils/services/validator';

interface ComponentProps extends RouteComponentProps {
  pricingInfo: any;
  onDateChange(...args: any[]): any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  loadRecurringServices(...args: any): any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class PaymentReportDateForm extends PureComponent<Props> {
  render() {
    const { pricingInfo, onDateChange, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="xSmall no" withBorder>
          <Grid>
            <GridColumn size="10/12" />
            <GridColumn size="2/12">
              <Field
                name={`pricingInfo.settings${pricingInfo.rubiconInvoiceNo}.reportDate`}
                component={DatePicker}
                label={translate('payment.reportPeriod')}
                onDayChange={(event: any) => onDateChange(event)}
                margin="no no no"
                validate={[isDateValidValidator]}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const initialValues = pricingInfoIntialValueSelector(ownProps.pricingInfo);

  return {
    initialValues,
  };
};

const mapDispatchToProps = { loadRecurringServices };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'paymentReportDate',
      enableReinitialize: true,
    })(PaymentReportDateForm),
  ),
);
