import { identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { getQueryParams } from '../../utils/services/queryParams';

const getRouteImportsFormInitialValues = (queryString: string) => {
  const { vendorId, fileProcessingStatusTypeId, startDate, endDate } = getQueryParams(queryString);

  return {
    vendorId: vendorId && Number(vendorId),
    fileProcessingStatusTypeId: fileProcessingStatusTypeId && Number(fileProcessingStatusTypeId),
    startDate,
    endDate,
  };
};

const routeImportsFormInitialValuesSelector = createSelector(getRouteImportsFormInitialValues, identity);

export default routeImportsFormInitialValuesSelector;
