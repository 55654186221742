import styled from 'styled-components';

export const TypeAheadGroupContainer = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';
  margin: '-8px';
  padding: '8px 12px';
  background: ${props => props.theme.grayLight};
`;

export const TypeAheadGroupBadge = styled.span`
  display: 'inline-block';
  padding: '3px 6px';
  background-color: '#ebecf0';
  border-radius: '100%';
  text-align: 'center';
  line-height: '1';
  font-size: '10px';
  color: '#172b4d';
  font-weight: ${props => props.theme.fontWeightMedium};
`;
