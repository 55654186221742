import { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { mobileNavigationPageWidthThreshold, mobilePageWidthThreshold } from 'src/core/constants/mobilePages';

export function useInterval(callback: any, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useStateRef<S>(initialValue: S): [S, (state: S) => void, any] {
  const [value, setValue] = useState<S>(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
}

export function useIsMobileView(isNavigation?: boolean) {
  return useMediaQuery({
    query: `(max-width: ${isNavigation ? mobileNavigationPageWidthThreshold : mobilePageWidthThreshold}px)`,
  });
}

export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
