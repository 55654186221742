import { http } from '../../core/services/http';
import { StreetSweepingSettings } from '../interfaces/StreetSweepingSettings';

export const loadStreetSweepingSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/streetSweepingSettings`).then(response => response.data);

export const saveStreetSweepingSettings = (vendorId: number, streetSweepingSettings: StreetSweepingSettings) =>
  http
    .put(`/vendors/${vendorId}/streetSweepingSettings`, streetSweepingSettings)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadStreetSweepingDefaultSettings = (vendorId: number) => {
  return http.get(`/vendors/${vendorId}/streetSweepingDefaultSettings`).then(response => response.data);
};
