import { ACCOUNT_STATUSES } from '../../../constants/accountStatuses';
import { Customer, Location } from '../../../interfaces/Route';
import { ROUTE_PICKUP_TYPE_IDS } from '../../../constants';
import { Service } from '../../../interfaces/RouteLocation';
import { TableRow, TableCell, Text } from '../../../../core/components/styled';
import { UnconnectedDaysOfWeekPicker } from '../../../../core/components';
import { SelectedTemplateLocation } from '../common/routeMapGL/RouteMapGL';

interface Props {
  accountStatusId: number;
  customer: Customer;
  enablePickupType: boolean;
  location: Location;
  orderNumber: number;
  pickupTypeId: number;
  scheduledDays: string[];
  service: Service;
  latitude: number;
  longitude: number;
  serviceContractRouteTemplateId: number;
  selectTemplateLocation: (routeTemplateLocation: SelectedTemplateLocation, event: any) => void;
}

const RouteTemplateLocationsTableRow = ({
  orderNumber,
  customer: { name: customerName, accountNumber },
  location: {
    address: { street, streetNumber },
    name: locationName,
    vendorAccountNo,
  },
  pickupTypeId,
  accountStatusId,
  scheduledDays,
  service,
  enablePickupType,
  latitude,
  longitude,
  serviceContractRouteTemplateId,
  selectTemplateLocation,
}: Props) => {
  const selectedTemplateLocation: SelectedTemplateLocation = {
    latitude,
    longitude,
    serviceContractRouteTemplateId,
  };

  return (
    <TableRow
      id={`route-template-stop-${orderNumber}`}
      onClick={ev => selectTemplateLocation(selectedTemplateLocation, ev)}
    >
      <TableCell width="10%" id={`route-template-stop-${orderNumber}-number`}>
        <Text block weight="medium" margin="no no xxSmall">
          {orderNumber >= 0 && orderNumber}
        </Text>
      </TableCell>

      <TableCell width={enablePickupType ? '25%' : '35%'} vertical>
        <Text block weight="medium" margin="no no xxSmall" id={`route-template-stop-${orderNumber}-customer`}>
          {customerName} {accountNumber && `(${accountNumber})`}
        </Text>

        <Text
          singleLine
          weight="light"
          size="small"
          margin="no no xxSmall"
          id={`route-template-stop-${orderNumber}-location`}
        >
          {locationName}, {streetNumber} {street} {vendorAccountNo && `(${vendorAccountNo})`}
        </Text>
      </TableCell>

      <TableCell width={enablePickupType ? '10%' : '15%'} id={`route-template-stop-${orderNumber}-account-status`}>
        {(!!accountStatusId && ACCOUNT_STATUSES[accountStatusId].name) || '-'}
      </TableCell>

      <TableCell width="20%" id={`route-template-stop-${orderNumber}-service`}>
        {service && service.name}
      </TableCell>
      <TableCell width="20%" id={`route-template-stop-${orderNumber}-day-of-service`}>
        <UnconnectedDaysOfWeekPicker
          selectedWeekdays={scheduledDays}
          isReadOnly
          multiple
          dayOfWeekProps={{ margin: "no xSmall no no'" }}
          daysOfWeekProps={{ margin: 'no' }}
        />
      </TableCell>

      {enablePickupType && (
        <TableCell width="15%">
          {(ROUTE_PICKUP_TYPE_IDS[pickupTypeId] && ROUTE_PICKUP_TYPE_IDS[pickupTypeId].name) || '-'}
        </TableCell>
      )}
    </TableRow>
  );
};

export default RouteTemplateLocationsTableRow;
