export const EN = 'en';
export const FR = 'fr';
export const ES = 'es';
export const DE = 'de';
export const DEFAULT_LOCALE = EN;
export const LOCALES = [EN, FR, ES, DE];
export const LANGUAGES = [EN, ES, FR, DE];

export const LANGUAGE_OPTIONS: { id: number; name: string }[] = [
  { id: 1, name: EN },
  { id: 2, name: ES },
  { id: 3, name: FR },
  { id: 4, name: DE },
];
