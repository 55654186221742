import ReactDOM from 'react-dom';
import Confirmation from '../components/Confirmation';
import history from './history';

const controlledConfirm = (title: string, message?: string, noLabel?: string, yesLabel?: string) => {
  const wrapper = document.body.appendChild(document.createElement('div'));

  function dispose() {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(wrapper);
      unregisterHistoryChangeEventHandler();
      setTimeout(() => document.body.removeChild(wrapper));
    });
  }

  const unregisterHistoryChangeEventHandler = history.listen(dispose);

  const promise = new Promise((resolve, reject) => {
    try {
      ReactDOM.render(
        <Confirmation
          message={message}
          noLabel={noLabel}
          reject={reject}
          resolve={resolve}
          title={title}
          yesLabel={yesLabel}
        />,
        wrapper,
      );
    } catch (e) {
      throw e;
    }
  });

  return {
    promise: promise.then(
      () => {
        dispose();
        return true;
      },
      () => {
        dispose();
        return false;
      },
    ),
    close: dispose,
  };
};

export default controlledConfirm;
