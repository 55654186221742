import styled, { css } from 'styled-components';

import Icon from '../Icon';
import { sizeMapper, colorMapper } from '../../../utils/styles';

type Props = Partial<{
  color: string;
  margin: string;
  vertical: boolean;
  centered: boolean;
  maxWidth: string;
}>;

export const DescriptionListItemIcon = styled(Icon)<Props>`
  width: 16px;
  height: 16px;
  color: ${colorMapper};
  margin: ${props => sizeMapper(props.margin, 'no xxSmall no no')};
`;

export const DescriptionListThumbItem = styled.div<Props>`
  display: flex;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no')};
  line-height: 16px;
  font-size: 12px;
`;

export const DescriptionListItem = styled.div<Props>`
  display: flex;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no')};
  line-height: 16px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
`;

export const DescriptionList = styled.div<Props>`
  display: flex;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};

  ${props =>
    props.centered &&
    css`
      justify-content: ${props.vertical ? 'flex-start' : 'center'};
      align-items: ${props.vertical ? 'center' : 'flex-start'};
    `};
`;
