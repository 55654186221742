import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getPaymentTerms } from '../services/paymentTermOptions';

// Constants
const START_LOAD_PAYMENT_TERM_OPTIONS = 'customers/paymentTermOptions/START_LOAD_PAYMENT_TERM_OPTIONS';
const COMPLETE_LOAD_PAYMENT_TERM_OPTIONS = 'customers/paymentTermOptions/COMPLETE_LOAD_PAYMENT_TERM_OPTIONS';
const FAIL_LOAD_PAYMENT_TERM_OPTIONS = 'customers/paymentTermOptions/FAIL_LOAD_PAYMENT_TERM_OPTIONS';

interface State {
  isLoading: boolean;
  paymentTerms: TechnicalType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial State
const initialState: State = {
  isLoading: false,
  paymentTerms: [],
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD_PAYMENT_TERM_OPTIONS,
});

const completeLoad = (paymentTerms: TechnicalType[]) => ({
  type: COMPLETE_LOAD_PAYMENT_TERM_OPTIONS,
  paymentTerms,
});

const failLoad = () => ({
  type: FAIL_LOAD_PAYMENT_TERM_OPTIONS,
});

// Async Actions
export const loadPaymentTerms = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getPaymentTermsPromise = getPaymentTerms();
  getPaymentTermsPromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getPaymentTermsPromise;
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_PAYMENT_TERM_OPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_PAYMENT_TERM_OPTIONS:
      return {
        ...initialState,
        isLoading: false,
      };
    case COMPLETE_LOAD_PAYMENT_TERM_OPTIONS:
      return {
        ...state,
        paymentTerms: action.paymentTerms,
        isLoading: false,
      };
    default:
      return state;
  }
};
