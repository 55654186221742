import {
  COLOR_DEFAULT,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SUCCESS,
  COLOR_PART_SUCCESS,
  COLOR_INFO,
  COLOR_WARNING,
  COLOR_ALERT,
  COLOR_GRAY,
  COLOR_GRAY_DARK,
  COLOR_GRAY_DARKER,
  COLOR_BLACK,
  theme,
} from '../../core/styles';
import { COLOR_GRAY_LIGHT, COLOR_GRAY_LIGHTER, COLOR_WHITE, COLOR_ORANGE_DARK } from 'src/core/styles/colors';

const COLOR_MAP = {
  [COLOR_DEFAULT]: 'brandDefault',
  [COLOR_PRIMARY]: 'brandPrimary',
  [COLOR_SECONDARY]: 'brandSecondary',
  [COLOR_SUCCESS]: 'brandSuccess',
  [COLOR_PART_SUCCESS]: 'brandPartSuccess',
  [COLOR_INFO]: 'brandInfo',
  [COLOR_WARNING]: 'brandWarning',
  [COLOR_ALERT]: 'brandAlert',
  [COLOR_GRAY]: 'gray',
  [COLOR_GRAY_DARK]: 'grayDark',
  [COLOR_GRAY_DARKER]: 'grayDarker',
  [COLOR_GRAY_LIGHT]: 'grayLight',
  [COLOR_GRAY_LIGHTER]: 'grayLighter',
  [COLOR_BLACK]: 'black',
  [COLOR_WHITE]: 'brandWhite',
  [COLOR_ORANGE_DARK]: 'orangeDark',
};

const colorMapper = (props: any, defaultColor: string = COLOR_DEFAULT) =>
  props.theme[(COLOR_MAP as any)[props.color || defaultColor]];

export default colorMapper;

export const colorMapperWithoutProps = (color?: string, defaultColor: string = COLOR_DEFAULT) =>
  (theme as any)[(COLOR_MAP as any)[color || defaultColor]];
