import { darken } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from '../../../core/components/styled';
import { Icon } from '../../../core/components';
import { INSIGHT_ICONS, VEHICLE_ICONS } from '../../../common/constants';

export const DashboardMapInsightsDateLabel = styled.span`
  margin-right: 6px;
  font-weight: ${props => props.theme.fontWeightMedium};
  color: ${props => props.theme.grayBase};
`;

export const DashboardMapInsightsDate = styled.span`
  display: block;
  margin-bottom: 15px;
  text-transform: capitalize;
  text-align: center;
  font-size: 10px;
  color: ${props => props.theme.grayDark};
`;

export const DashboardMapInsightsToggleIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  width: 12px;
  height: 12px;
  transform: none;
  transition: transform 0.3s ease;
`;

interface DashboardMapInsightsToggleProps {
  isOpen?: boolean;
}

export const DashboardMapInsightsToggle = styled.button.attrs({ type: 'button' })<DashboardMapInsightsToggleProps>`
  ${BareButtonStyle};
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 10px;
  color: ${props => props.theme.grayDark};

  ${props =>
    props.isOpen &&
    css`
      ${DashboardMapInsightsToggleIcon} {
        transform: rotate(180deg);
      }
    `};
`;

export const DashboardMapInsightsLinkIcon = styled(Icon)`
  width: 13px;
  height: 13px;
  margin-right: 5px;
  vertical-align: top;
`;

export const DashboardMapInsightsLink = styled(Link)`
  display: inline-block;
  vertical-align: top;
  font-size: 10px;
  color: ${props => props.theme.brandPrimary};

  &:hover {
    color: ${props => darken(0.05, props.theme.brandPrimary)};
  }
`;

export const DashboardMapInsightsLinkContainer = styled.div`
  margin-bottom: 15px;
  text-align: center;
`;

export const DashboardMapInsightsCategoryTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: ${props => props.theme.grayLighter};
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 8px;
  color: ${props => props.theme.grayDark};
`;

export const DashboardMapInsightsCategoryTitleMapbox = styled.span`
  padding: 0 15px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 14px;
  color: ${props => props.theme.grayDarker};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DashboardMapInsightsCategory = styled.div``;

export const DashboardMapInsightsCategories = styled.div`
  display: block;
  height: auto;
  overflow: hidden;
`;

export const DashboardMapInsightIcon = styled.span`
  display: inline-block;
  width: 30px;
  height: 22px;
  margin: 3px 15px 0 0;
  background-size: 15px;
  background-repeat: no-repeat;
`;

export const DashboardMapInsightName = styled.span`
  flex: 1;
  line-height: 22px;
  font-size: 13px;
`;

export const DashboardMapInsightValue = styled.span`
  text-align: right;
  line-height: 22px;
  font-size: 13px;
`;

interface DashboardMapInsightProps {
  category?: string;
  type: string;
}

export const DashboardMapInsight = styled.div<DashboardMapInsightProps>`
  display: flex;
  padding: 0 15px;
  margin-bottom: 8px;

  ${props =>
    props.category &&
    props.category === 'vehicles' &&
    css`
      ${DashboardMapInsightIcon} {
        background-image: url(${VEHICLE_ICONS[props.type] || VEHICLE_ICONS.vehicle});
        background-size: 24px;
      }
    `};

  ${props =>
    props.category &&
    props.category !== 'vehicles' &&
    css`
      ${DashboardMapInsightIcon} {
        background-image: url(${INSIGHT_ICONS[props.type]});
      }
    `};
`;

interface DashboardMapInsightsProps {
  isSubMenuHidden?: boolean;
}

export const DashboardMapInsights = styled.div<DashboardMapInsightsProps>`
  position: fixed;
  top: ${props => (props.isSubMenuHidden ? '90px' : '160px')};
  right: 20px;
  z-index: 1000;
  width: 305px;
  max-height: 50vh;
  padding-top: 15px;
  overflow-y: auto;
  background-color: #fff;
  font-size: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;
