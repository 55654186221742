import React, { PureComponent } from 'react';
import { size } from 'lodash-es';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { Pagination, Table } from '../../../core/components';
import { PanelSection, TabLink, Tabs } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { getQueryParams } from '../../../utils/services/queryParams';
import { LIMIT_PER_PAGE } from '../../constants';
import { loadOnCallServices } from '../../ducks';
import recurringServicesInitialValueSelector from '../../services/recurringServicesInitialValueSelector';
import { OnCallServicesFormTableRow } from './';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface ComponentProps extends RouteComponentProps<RouteParams> {
  vendorId: number;
}

interface ReduxProps {
  initialValues: any;
  pricingInfo: any;
  workOrdersInfo: any;
  total?: number;
  loadOnCallServices(...args: any[]): any;
}

type PropsWithoutReduxForm = ReduxProps & ComponentProps;

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class OnCallServicesForm extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      location,
      vendorId,
      loadOnCallServices,
      pricingInfo,
      match: {
        params: { token },
      },
    } = this.props;

    if (prevProps.location.search !== location.search) {
      const { page } = getQueryParams(location.search);
      const date = moment(pricingInfo.reportDate).format('YYYY-MM-DD');

      return Promise.all([loadOnCallServices(vendorId, token, date, page, LIMIT_PER_PAGE)]);
    }
  }

  onCallServicesTableCells = [
    { name: 'Status', label: translate('common.status'), width: '8%', sortable: false },
    { name: 'Division Name', label: translate('payment.divisionName'), width: '16%', sortable: false },
    { name: 'Customer Location', label: translate('payment.customerLocation'), width: '16%', sortable: false },
    { name: 'Work Order', label: translate('payment.workOrder'), width: '12%', sortable: false },
    { name: 'Service Information', label: translate('payment.serviceInformation'), width: '16%', sortable: false },
    { name: 'Confirmed', label: translate('payment.confirmed'), width: '12%', sortable: false },
    { name: 'Action', label: translate('payment.action'), width: '20%', sortable: false },
  ];

  handleSubmit = (event: any) => {
    event.preventDefault();
  };

  render() {
    const { workOrdersInfo, match, total } = this.props;

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <PanelSection centered withBorder padding="sMedium">
          <Tabs>
            <TabLink to={`/payments/recurring-service/${match.params.token}`}>
              {translate('payment.recurringService')}
            </TabLink>
            <TabLink to={`/payments/oncall-service/${match.params.token}`}>
              {translate('payment.oncallService')}
            </TabLink>
            <TabLink to={`/payments/processed-service/${match.params.token}`}>
              {translate('payment.processedService')}
            </TabLink>
          </Tabs>
        </PanelSection>

        <PanelSection>
          {!!size(workOrdersInfo) && (
            <Table
              cells={this.onCallServicesTableCells}
              rows={workOrdersInfo}
              rowComponent={OnCallServicesFormTableRow}
              rowProps={
                {
                  // push,
                }
              }
            />
          )}
        </PanelSection>
        <Pagination totalResults={total!} />
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  workOrdersInfo: state.payments.onCallServices.workOrdersInfo,
  pricingInfo: state.payments.onCallServices.pricingInfo,
  initialValues: recurringServicesInitialValueSelector(state.payments.onCallServices.workOrdersInfo),
  total: state.payments.onCallServices.total,
});

const mapDispatchToProps = {
  loadOnCallServices,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'onCallServicesForm',
      enableReinitialize: true,
    })(OnCallServicesForm),
  ),
);
