import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { TechnicalType } from '../interfaces/TechnicalType';

const transformUnitOfMeasureTypes = (unitOfMeasureTypes: TechnicalType[]) =>
  map(unitOfMeasureTypes, unitOfMeasure => ({
    ...unitOfMeasure,
    name: translate(createTranslationKey(unitOfMeasure.technicalName, 'common.unitsOfMeasurement')),
  }));

export default transformUnitOfMeasureTypes;
