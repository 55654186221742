import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
import { clearServiceAreasSelectedFeature } from 'src/haulerProfile/ducks/serviceAreas';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { getEquipmentTypeId } from '../../utils/equipments';
import { HAULER_PROFILE_DIVISION_COOKIE } from '../../constants/HaulerProfileDivisionCookie';
import { Modal } from '../../../core/components';
import { saveServiceArea, loadServiceAreas } from '../../ducks';
import { ServiceAreaEditorForm } from '../forms';
import serviceAreasFilterFormInitialValuesSelector from '../../services/serviceAreasFilterFormInitialValuesSelector';
import translate from '../../../core/services/translate';

type Props = {
  equipmentId?: number;
  closeModal: (formPristine?: boolean) => void;
};

export default function ServiceAreaEditorModal({ equipmentId, closeModal }: Props) {
  const dispatch = useDispatch();
  const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
  const haulerId = useSelector((state: AppState) =>
    currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  );
  const userName = useSelector((state: AppState) => state.account.login.user.email);
  const serviceAreaEditorFormInitialValues = useSelector((state: AppState) =>
    serviceAreasFilterFormInitialValuesSelector(state.haulerProfile.serviceAreas.serviceAreaOptions),
  );
  const allEquipments =
    useSelector((state: AppState) => state.haulerProfile.serviceAreas.serviceAreas?.equipments) || [];
  const rawEquipment = allEquipments.find(equip => equip.equipment.id === equipmentId);
  let equipmentInitialValues = {};
  if (rawEquipment) {
    const { equipment, materials, zipCodes } = rawEquipment;

    equipmentInitialValues = {
      equipmentTypes: getEquipmentTypeId(equipment),
      equipmentSizes: [equipment.id],
      materials: materials.map((material: any) => material.vendGroupEquipmentMaterialId),
      zipCodes: zipCodes.map((zipCode: any) => zipCode.zipCode),
    };
  }

  const handleSubmitServiceArea = (formData: any) => {
    const { equipmentSizes, materials, zipCodes } = formData;

    const serviceArea = {
      editMode: !!equipmentId,
      materialIds: materials.join(),
      updatedBy: userName,
      vendGroup: haulerId,
      vendorGroupEquipmentIds: equipmentId || equipmentSizes.join(),
      zipCodes: zipCodes.join(),
      // vendId: divisionIdFromCookie,
    };

    saveServiceArea(
      haulerId,
      serviceArea,
    )(dispatch)
      .then(() => {
        loadServiceAreas(haulerId, divisionIdFromCookie)(dispatch);
        createSuccessNotification(translate('haulerProfile.alertMessages.serviceAreaSaved'));
        closeModal(true);
      })
      .catch(() => {
        createErrorNotification(translate('haulerProfile.alertMessages.serviceAreaSaveError'));
      });

    dispatch(clearServiceAreasSelectedFeature());
  };

  return (
    <Modal padding="no" flex size="xLarge">
      <ServiceAreaEditorForm
        initialValues={equipmentId ? equipmentInitialValues : serviceAreaEditorFormInitialValues}
        onSubmit={handleSubmitServiceArea}
        closeModal={closeModal}
        equipmentId={equipmentId}
      />
    </Modal>
  );
}
