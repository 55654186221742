import React, { PureComponent } from 'react';
import {
  FileOpenDialogToggle as FileOpenDialogToggleContainer,
  FileOpenDialogToggleLabel,
  FileOpenDialogToggleInput,
  FileOpenDialogToggleIcon,
  FileOpenDialogToggleText,
} from './styled';

interface Props {
  icon?: string;
  label?: string;
  multiple?: boolean;
  accept?: string;
  margin?: string;
  onFilesSelected: (files: FileList | null) => void;
}

class FileOpenDialogToggle extends PureComponent<Props> {
  onFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onFilesSelected(event.target.files);
  };

  render() {
    const { icon, label, multiple, accept, margin } = this.props;
    return (
      <FileOpenDialogToggleContainer margin={margin}>
        <FileOpenDialogToggleLabel>
          <FileOpenDialogToggleInput multiple={multiple} accept={accept} onChange={this.onFilesSelected} type="file" />
          {icon && <FileOpenDialogToggleIcon icon={icon} />}
          {label && <FileOpenDialogToggleText>{label}</FileOpenDialogToggleText>}
        </FileOpenDialogToggleLabel>
      </FileOpenDialogToggleContainer>
    );
  }
}

export default FileOpenDialogToggle;
