import { sizeMapper } from 'src/utils/styles';
import styled, { css } from 'styled-components';
import { PaymentDetailLabel } from './PaymentDetailLabel';

interface PaymentDetailProps {
  margin?: string;
  clickable?: boolean;
}

export const PaymentDetail = styled.div<PaymentDetailProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${props => sizeMapper(props.margin, 'no')};
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  ${props =>
    props.clickable &&
    css`
      &:hover {
        ${PaymentDetailLabel} {
          color: ${props.theme.brandPrimary} !important;
        }
      }
    `};
`;
