import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../../core/services/createNotification';
import { inboundCreationRule } from '../../../interfaces/Open311';
import { Modal } from '../../../../core/components';
import Open311CreationRuleEditorForm from '../../forms/Open311CreationRuleEditorForm';
import { saveCreationRule } from '../../../ducks/open311Settings';
import translate from '../../../../core/services/translate';

type Props = {
  creationRules: inboundCreationRule[] | undefined;
  creationRuleId?: number;
  onClose: (formPristine?: boolean) => void;
};

export default function CreationRuleEditorModal({ creationRules, creationRuleId, onClose }: Props) {
  const dispatch = useDispatch();
  const isEditMode = !!creationRuleId;

  const isSaving = useSelector((state: AppState) => state.vendors.open311Settings.isSaving);
  const open311Configuration = useSelector((state: AppState) => state.vendors.open311Settings.open311Configuration);
  const configurationId = open311Configuration?.id || 0;
  const rawCreationRule = creationRules?.find(cr => cr.id === creationRuleId);

  let creationRuleInitialValues: any = {};

  if (rawCreationRule) {
    const {
      serviceCode,
      serviceName,
      equipmentTypeId,
      equipmentSizeId,
      wasteMaterialTypeId,
      pickupTypeId,
      vehicleTypeId,
      reasonCodeId,
      adHocJob,
      serviceTypeId,
      customNotesField,
      createServiceContractIfNotFound,
      useScheduledDate,
      closeOutCode,
      outcomeCode,
      responseCode,
      scheduledDate,
      scheduledDateType,
    } = rawCreationRule;

    creationRuleInitialValues = {
      serviceCode,
      serviceName,
      equipmentTypeId,
      equipmentSizeId,
      wasteMaterialTypeId,
      pickupTypeId,
      vehicleTypeId,
      reasonCodeId,
      adHocJob,
      serviceTypeId,
      customNotesField,
      createServiceContractIfNotFound,
      useScheduledDate,
      closeOutCode,
      outcomeCode,
      responseCode,
      scheduledDate,
      scheduledDateType,
    };
  }

  const handleSubmitCreationRule = async (formData: any) => {
    const creationRuleObj = {
      id: creationRuleId || 0,
      configurationId,
      noteFields: [],
      ...formData,
    };

    saveCreationRule(
      configurationId,
      creationRuleId,
      creationRuleObj,
    )(dispatch)
      .then(async () => {
        createSuccessNotification(translate('vendors.open311.alertMessages.creationRuleSaved'));
        onClose(true);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.open311.alertMessages.creationRuleSaveError'));
      });
  };

  return (
    <Modal
      size="large"
      isLoading={isSaving}
      onClose={onClose}
      title={translate(`vendors.open311.${isEditMode ? 'edit' : 'add'}CreationRule`)}
    >
      <Open311CreationRuleEditorForm
        initialServiceCode={creationRuleInitialValues.serviceCode}
        isInbound={true}
        initialValues={creationRuleInitialValues}
        onSubmit={handleSubmitCreationRule}
        onClose={onClose}
      />
    </Modal>
  );
}
