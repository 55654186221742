import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { SortableTable } from 'src/core/components';
import { Button, Panel, PanelSection, PanelSectionGroup, PanelSectionTitle } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  reorderRouteMetricsSettings,
  reorderRouteTemplateMetricsSettings,
  saveRoutePlannerRouteMetricsSettings,
  saveRoutePlannerRouteTemplateMetricsSettings,
  toggleRouteMetricsSettings,
  toggleRouteTemplateMetricsSettings,
} from 'src/routes/ducks/routePlanner/routePlannerMetricsSettings';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RoutePlannerMetricsSettingsTableRow from './RoutePlannerSettingsPageSections/RoutePlannerMetricsSettingsTableRow';

const RoutePlannerSettingsPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { pathname } = useLocation();
  const isReoccurringRoutesPage = pathname.includes('reoccurring');
  const { routeMetricsSettings, routeTemplateMetricsSettings, hasChangesToSave, isLoading } = useSelector(
    state => state.routes.routePlanner.routePlannerMetricsSettings,
  );

  const routePlannerSettingsTableCells = (section: string) => [
    {
      name: 'reportTypeName',
      label: translate('routes.planner.metricType'),
      width: '85%',
      id: `insights-settings-${section}-header-report-type`,
    },
    {
      name: 'switch',
      label: translate('routes.planner.displayMetric'),
      width: '15%',
      align: 'right',
      id: `route-planner-settings-${section}-header-display`,
    },
  ];

  const handleGoBack = () => {
    const { length, goBack } = history;
    return length > 1 ? goBack() : push('/routes/route-tracker');
  };

  const onReorderItem = (fromIndex: number, toIndex: number) => {
    if (isReoccurringRoutesPage) dispatch(reorderRouteTemplateMetricsSettings(fromIndex, toIndex));
    else dispatch(reorderRouteMetricsSettings(fromIndex, toIndex));
  };

  const saveRoutePlannerSettings = () => {
    if (isReoccurringRoutesPage)
      saveRoutePlannerRouteTemplateMetricsSettings(vendorId, routeTemplateMetricsSettings)(dispatch);
    else saveRoutePlannerRouteMetricsSettings(vendorId, routeMetricsSettings)(dispatch);
  };

  const toggleItem = (id: number, value: boolean) => {
    if (isReoccurringRoutesPage) dispatch(toggleRouteTemplateMetricsSettings(id, value));
    else dispatch(toggleRouteMetricsSettings(id, value));
  };
  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={handleGoBack} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>
              {isReoccurringRoutesPage
                ? translate('routes.planner.routeTemplatesPlanner')
                : translate('routes.planner.dailyRoutesPlanner')}
            </PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <Button
            color="primary"
            id="route-planner-settings-save-button"
            onClick={saveRoutePlannerSettings}
            disabled={!hasChangesToSave}
          >
            {translate('common.save')}
          </Button>
        </PageActions>
      </PageHeader>

      <Panel>
        <PanelSectionGroup isLoading={isLoading}>
          <PanelSection vertical>
            <PanelSectionTitle margin="sMedium no sMedium sMedium">
              {translate('routes.planner.metricsSettings')}
            </PanelSectionTitle>
            <SortableTable
              sort={onReorderItem}
              cells={routePlannerSettingsTableCells(isReoccurringRoutesPage ? 'route-template' : 'daily-route')}
              rows={isReoccurringRoutesPage ? routeTemplateMetricsSettings : routeMetricsSettings}
              rowComponent={RoutePlannerMetricsSettingsTableRow}
              rowProps={{ toggleItem }}
            />
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default RoutePlannerSettingsPage;
