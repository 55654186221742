import { http } from '../../core/services/http';
import { EsriConfig, EsriCredentials, EsriSyncSettings } from '../interfaces/EsriSyncSettings';

export const loadEsriSyncSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/esri/config`).then(response => response.data);

export const saveEsriSyncSettings = (vendorId: number, EsriSyncSettings: EsriSyncSettings) =>
  http
    .put(`/vendors/${vendorId}/esri/config`, EsriSyncSettings)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const validateEsriSyncSettings = (vendorId: number, EsriSyncSettings: EsriSyncSettings) =>
  http.get(`/vendors/${vendorId}/esri/config/validate/customer?type=customer`).then(response => response.data);

//////////Esri Integration Configuration ///////

export const validateEsriCredentials = (vendorId: number, esriCredentials: EsriCredentials) =>
  http.get(`/vendors/${vendorId}/esri/config/validate/esriConfig`).then(response => response.data);

export const validateEsriCustomerConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http.get(`/vendors/${vendorId}/esri/config/validate/customerv2`).then(response => response.data);

export const validateEsriRouteConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http.get(`/vendors/${vendorId}/esri/config/validate/route`).then(response => response.data);

export const validateEsriServiceConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http.get(`/vendors/${vendorId}/esri/config/validate/service`).then(response => response.data);

export const validateEsriGeoFenceConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http.get(`/vendors/${vendorId}/esri/config/validate/geofence`).then(response => response.data);

export const loadEsriCredentials = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/esri/config/esriv2`).then(response => response.data);

export const saveEsriCredentials = (vendorId: number, EsriSyncSettings: EsriCredentials) =>
  http
    .put(`/vendors/${vendorId}/esri/config/esriv2`, EsriSyncSettings)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadEsriCustomerConfig = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/esri/config/customer`).then(response => response.data);

export const saveEsriCustomerConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http
    .put(`/vendors/${vendorId}/esri/config/customer`, esriConfiguration)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadEsriServiceConfig = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/esri/config/Service`).then(response => response.data);

export const saveEsriServiceConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http
    .put(`/vendors/${vendorId}/esri/config/Service`, esriConfiguration)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadEsriRouteConfig = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/esri/config/route`).then(response => response.data);

export const saveEsriRouteConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http
    .put(`/vendors/${vendorId}/esri/config/route`, esriConfiguration)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadEsriGeoFenceConfig = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/esri/config/geofence`).then(response => response.data);

export const saveEsriGeoFenceConfig = (vendorId: number, esriConfiguration: EsriConfig) =>
  http
    .put(`/vendors/${vendorId}/esri/config/geofence`, esriConfiguration)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
