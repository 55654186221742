import { http } from '../../core/services/http';
import {
  PreventativeMaintenanceConfiguration,
  PreventativeMaintenanceStatus,
  PreventativeMaintenanceLog,
  PreventativeMaintenanceMilestoneCompletePayload,
  PreventativeMaintenanceRawConfiguration,
} from '../interfaces/PreventativeMaintenance';
import { transformRawConfiguration } from './preventativeMaintenanceTransformers';

export const loadPMConfigurationForVehicleType = (vendorId: number, vehicleTypeId: number) =>
  http
    .get<PreventativeMaintenanceRawConfiguration>(`vendors/${vendorId}/preventativeMaintenance/config`, {
      params: { vehicleTypeId },
    })
    .then(response => transformRawConfiguration(response.data));

export const loadPMConfigurationForVehicle = (vehicleId: number) =>
  http
    .get<PreventativeMaintenanceRawConfiguration>(`vehicles/${vehicleId}/preventativeMaintenance/config`)
    .then(response => transformRawConfiguration(response.data));

export const savePMConfigurationForVehicleType = (
  vendorId: number,
  vehicleTypeId: number,
  configuration: PreventativeMaintenanceConfiguration,
) =>
  http.put(`vendors/${vendorId}/preventativeMaintenance/config`, configuration, {
    params: { vehicleTypeId },
  });

export const savePMConfigurationForVehicle = (vehicleId: number, configuration: PreventativeMaintenanceConfiguration) =>
  http.put(`vehicles/${vehicleId}/preventativeMaintenance/config`, configuration);

export const loadVehiclePMStatus = (vehicleId: number) =>
  http
    .get<PreventativeMaintenanceStatus>(`vehicles/${vehicleId}/preventativeMaintenance`)
    .then(response => response.data);

export const loadVehiclePMLogs = (vehicleId: number) =>
  http
    .get<PreventativeMaintenanceLog[]>(`vehicles/${vehicleId}/preventativeMaintenance/logs`)
    .then(response => response.data);

export const updateVehiclePM = (
  vehicleId: number,
  maintenanceDetails: PreventativeMaintenanceMilestoneCompletePayload,
) => http.put(`vehicles/${vehicleId}/preventativeMaintenance/complete`, maintenanceDetails);

export const checkForActivePM = (vendorId: number, vehicleTypeId?: number) =>
  http
    .get<boolean>('vehicles/hasVendorVehicleWithPreventativeMaintenance', { params: { vendorId, vehicleTypeId } })
    .then(response => response.data);
