import update from 'immutability-helper';
import { map, orderBy, size } from 'lodash-es';
import moment from 'moment';

import {
  CHGHAULNG,
  CHGVHAULNG,
  CHGVLOCK,
  CHGLOCK,
  CHGVCASTOR,
  CHGCASTOR,
  CHGCASTERS,
  CHGLOCKFEE,
} from '../constants/opportunitiesStatus';
import { EndDestination, HaulerRateDetail, Offer, RawEndDestination } from '../interfaces/ChangeRequests';
import { MONTH } from '../constants/status';
import { TODAY_FORMATTED } from '../../core/constants';
import translate from '../../core/services/translate';
import { YES, NO } from '../constants/status';

export const transformEndDestinations = (rawEndDestinations: RawEndDestination[]): EndDestination[] =>
  map(rawEndDestinations, endDestination => ({
    ...endDestination,
    label: `${endDestination.name}: ${endDestination.address}`,
    value: endDestination.id,
  }));

const getServiceRate = (haulerRateDetails: HaulerRateDetail[]) => {
  const objHaulerRateDetails = haulerRateDetails.find(
    haulerRateDetail => haulerRateDetail.bundled && haulerRateDetail.inherit,
  );
  const serviceBaseRate = !!(objHaulerRateDetails && objHaulerRateDetails.rate);
  return serviceBaseRate;
};

export const transformOffer = (offer: Offer) => ({
  ...offer,
  deliveryDate: moment(offer.deliveryDate).format('MM/DD/YYYY'),
  serviceUom: offer.serviceUom === 'Month' ? 'servicePerMonth' : 'servicePerEach',
  deliveryUom: offer.deliveryUom === 'Month' ? 'deliveryPerMonth' : 'deliveryPerEach',
  lockbarUom: offer.lockbarUom === 'Month' ? 'lockbarPerMonth' : 'lockbarPerEach',
  castersUom: offer.castersUom === 'Month' ? 'castersPerMonth' : 'castersPerEach',
  extraPickupUom: offer.extraPickupUom === 'Month' ? 'extraPickupPerMonth' : 'extraPickupPerEach',
  isServiceBaseRate: getServiceRate(offer.haulerRateDetails),
});

export const transformRequestedChangesDisplayFees = (df: any, r: any) => [
  ...df,
  df.unshift({
    name: 'Requested Date',
    value: r.requestedChanges.deliveryDate,
  }),
];

export const transformHaulerRateDetails = (haulerRateDetails: any[]) =>
  orderBy(
    map(haulerRateDetails, rateDetail => ({
      ...rateDetail,
      rate: rateDetail.mandatory && rateDetail.rate === 0 ? null : rateDetail.rate,
      isMonthlyFee:
        rateDetail.uom === MONTH &&
        (rateDetail.rateCd === CHGHAULNG ||
          rateDetail.rateCd === CHGVHAULNG ||
          rateDetail.rateCd === CHGVLOCK ||
          rateDetail.rateCd === CHGLOCK ||
          rateDetail.rateCd === CHGVCASTOR ||
          rateDetail.rateCd === CHGCASTOR ||
          rateDetail.rateCd === CHGCASTERS ||
          rateDetail.rateCd === CHGLOCKFEE),
    })),
    ['isMonthlyFee'],
    ['desc'],
  );

const transformFrequencyDays = (frequencyDays: string) => {
  if (frequencyDays === '(unknown)') {
    return '';
  }
  return frequencyDays.split('');
};

export const transformRequestedChanges = (r: any) => ({
  ...r.requestedChanges,
  deliveryDate: moment(r.requestedChanges.deliveryDate).format('MM/DD/YYYY'),
  haulerRateDetails: transformHaulerRateDetails(r.requestedChanges.haulerRateDetails),
  displayFees: r.requestedChanges.displayFees
    ? transformRequestedChangesDisplayFees(r.requestedChanges.displayFees, r)
    : undefined,
  swapRequired:
    (r.equipmentChanged && r.frequencyChanged) ||
    (r.equipmentChanged && r.materialChanged) ||
    (r.equipmentChanged && r.materialChanged && r.frequencyChanged) ||
    r.equipmentChanged
      ? YES
      : NO,
  serviceRecommendation: map(r.requestedChanges.serviceRecommendation, serviceRecommendation =>
    update(serviceRecommendation, {
      frequencyDays: { $set: transformFrequencyDays(serviceRecommendation.frequencyDays) },
    }),
  ),
});

export const getBeforeDeliveryDate = (deliveryDate: string, deliveryDaysThreshold: number) => {
  //const startDate = moment(deliveryDate).subtract(deliveryDaysThreshold, 'days').format('MM/DD/YYYY'); // disable to patch release
  const startDate = moment(deliveryDate).format('MM/DD/YYYY');
  const beforeDeliveryDate = moment(startDate).isSameOrAfter(TODAY_FORMATTED) ? startDate : TODAY_FORMATTED;
  return beforeDeliveryDate;
};

export const transformChangeRequests = (changeServices: any[]) =>
  map(changeServices, o => ({
    ...o,
    daysInWeekSchedule:
      o.requestedChanges && size(o.requestedChanges.serviceRecommendation)
        ? parseInt(o.requestedChanges.serviceRecommendation[0].frequencyId)
        : o.daysInWeekSchedule,
    workflowStatus: o.workflowStatus,
    notifyPossibleCustomerApproval: o.notifyPossibleCustomerApproval,
    endDestinations: transformEndDestinations(o.endDestinations),
    requestedChanges: o.requestedChanges
      ? transformRequestedChanges(o)
      : {
          overallserviceCharge: o.offer.overallserviceCharge || '',
          displayFees: o.offer.displayFees,
          haulerRateDetails: transformHaulerRateDetails(o.offer.haulerRateDetails),
          swapRequired:
            (o.equipmentChanged && o.frequencyChanged) ||
            (o.materialChanged && o.equipmentChanged) ||
            (o.materialChanged && o.equipmentChanged && o.frequencyChanged) ||
            o.equipmentChanged
              ? YES
              : NO,
        },
    offer: transformOffer(o.offer),
    deliveryDate: moment(o.offer.deliveryDate).format('MM/DD/YYYY'),
    afterDeliveryDate: moment(o.offer.deliveryDate)
      .add(o.deliveryDaysThreshold - 1, 'days')
      .format('MM/DD/YYYY'),
    beforeDeliveryDate: getBeforeDeliveryDate(o.offer.deliveryDate, o.deliveryDaysThreshold),
    requestedServiceLevelSummary: {
      equipmentSubType: o.equipmentSubType,
      equipmentSize: o.equipmentSize,
      equipmentName: o.equipmentName,
      equipmentUnits: o.equipmentUnits,
      frequency: o.frequency,
      material: o.material,
      quantity: o.quantity,
      serviceDescription: o.serviceDescription,
      frequencyDays: transformFrequencyDays(o.frequencyDays),
    },
    currentServiceLevelSummary: update(o.currentServiceLevelSummary, {
      currentServiceLevel: {
        frequencyDays: { $set: transformFrequencyDays(o.currentServiceLevelSummary.currentServiceLevel.frequencyDays) },
      },
    }),
    portalStatus: o.portalStatus,
  }));

export const transformPaymentMethods = (p: any) =>
  map(p.paymentMethods, p => ({
    label: p,
    value: p,
  }));

export const transformVendorPayment = (vp: any) =>
  map(vp.vendorPayments, v => ({
    ...v,
    label: `${v.remitName !== undefined ? v.remitName : ''} ${v.remitName !== undefined ? ': ' : ''} ${
      v.remitAddr1 !== undefined ? v.remitAddr1 : ''
    } ${v.remitCity !== undefined ? v.remitCity : ''} ${v.remitState !== undefined ? v.remitState : ''} ${
      v.remitZip !== undefined ? v.remitZip : ''
    }`,
    value: v.paymentId,
  }));

export const transformPaymentInfo = (changeRequest: any) => ({
  ...changeRequest.paymentInfo,
  paymentMethods: changeRequest.paymentInfo ? transformPaymentMethods(changeRequest.paymentInfo) : [],
  vendorPayments: changeRequest.paymentInfo ? transformVendorPayment(changeRequest.paymentInfo) : [],
});

export const transformEquipments = (equipmentSubTypes: any[]) =>
  map(equipmentSubTypes, equipmentSubType => ({
    id: equipmentSubType,
    name: translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentSubType}`),
  }));

export const transformMaterials = (materialTypes: any[]) =>
  map(materialTypes, ({ id }) => ({
    id: id,
    name: translate(`haulerProfile.materialTypes.${id}`),
  }));

export const transformChangeRequest = (changeRequest: any) => ({
  ...changeRequest,
  changeRequests: transformChangeRequests(changeRequest.services.changeServices),
  equipments: transformEquipments(changeRequest.equipmentSubTypes),
  materials: changeRequest.materialTypes,
  paymentInfo: transformPaymentInfo(changeRequest.services),
});
