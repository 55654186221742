import moment from 'moment';
import { createSelector } from 'reselect';
import { identity, map } from 'lodash-es';
import { technicalNameByVehicleTypeIdSelector } from '../../fleet/ducks';
import { VEHICLE_TYPES } from '../../fleet/constants';
import { DispatchBoardSimpleRoute } from '../interfaces/DispatchBoardRoute';
import { EXCEPTION } from '../constants';

const getDispatchBoardRouteEditorFormInitialValues = ({
  vendorId,
  route,
  jobIds,
  vehicleTypesForVendorState,
  vehicleTypeId,
  date,
}: {
  vendorId: number;
  route?: DispatchBoardSimpleRoute;
  jobIds?: number[];
  vehicleTypesForVendorState: any;
  vehicleTypeId: number;
  date: Date;
}) => {
  if (route) {
    const vehicleType = technicalNameByVehicleTypeIdSelector(vehicleTypesForVendorState, vehicleTypeId);

    return {
      ...route,
      vendorId,
      groupIds: map(route.groups, (group: any) => group.id),
      vehicleType: vehicleType && VEHICLE_TYPES[vehicleType].name,
      date: moment(route.date).format('MM/DD/YYYY'),
    };
  }

  return {
    vendorId,
    vehicleTypeId,
    date,
    routeConfirmationTypeId: EXCEPTION,
    jobIds,
  };
};

const dispatchBoardRouteEditorFormInitialValuesSelector = createSelector(
  getDispatchBoardRouteEditorFormInitialValues,
  identity,
);

export default dispatchBoardRouteEditorFormInitialValuesSelector;
