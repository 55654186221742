import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { Dropdown, Input, TextArea, UnconnectedCheckbox } from 'src/core/components';
import { Button } from 'src/core/components/styled/Button';
import ButtonSet from 'src/core/components/styled/ButtonSet';
import { Grid, GridColumn } from 'src/core/components/styled/Grid';
import Text from 'src/core/components/styled/Text';
import { ThumbnailWrapper } from 'src/core/components/styled/ThumbnailWrapper';
import { ThumbnailSetImageSlider } from 'src/core/components/ThumbnailSetImageSlider';
import translate from 'src/core/services/translate';
import { CustomerLocationEmail } from 'src/customers/interfaces/CustomerLocationEmail';
import { Email } from 'src/customers/interfaces/Email';
import { AppState } from 'src/store';
import { isEmail, isRequired } from 'src/utils/services/validator';

const CustomEmailContainer = styled(GridColumn)`
  margin-bottom: 20px;
`;

const getToEmail = (customerLocationsEmails: CustomerLocationEmail[], toEmail?: string) => {
  return customerLocationsEmails.length === 1 && customerLocationsEmails[0].emailAddress
    ? `${customerLocationsEmails[0].locationId},${customerLocationsEmails[0].emailAddress}`
    : toEmail;
};

interface FormValues {
  fromEmail: string;
  toEmail: string;
  emailSubject: string;
  emailMessage: string;
}

interface PropsWithoutReduxForm {
  allowCustomRecipient?: boolean;
  customerLocationsEmails: CustomerLocationEmail[];
  email: Partial<Email>;
  readOnly?: boolean;
  selectedImageBlobIds: string[];
  toggleImageBlobId: (blobId: string) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const CustomerEmailForm: React.FC<Props> = ({
  allowCustomRecipient,
  change,
  customerLocationsEmails,
  email: { images = [], toEmail },
  handleSubmit,
  readOnly,
  selectedImageBlobIds,
  toggleImageBlobId,
}) => {
  const [customEmail, setCustomEmail] = useState(false);
  return (
    <form onSubmit={handleSubmit}>
      <Grid multiLine>
        {!readOnly && allowCustomRecipient && (
          <>
            <GridColumn size="6/12" />
            <CustomEmailContainer size="6/12">
              <UnconnectedCheckbox
                label={translate('customers.emails.customRecipient')}
                onChange={() => {
                  if (!customEmail) {
                    change('toEmail', '');
                  } else {
                    change('toEmail', getToEmail(customerLocationsEmails, toEmail));
                  }
                  setCustomEmail(!customEmail);
                }}
                checked={customEmail}
              />
            </CustomEmailContainer>
          </>
        )}
        <GridColumn size="6/12">
          <Field
            readOnly
            component={Input}
            name="fromEmail"
            placeholder={translate('common.from')}
            label={translate('common.from')}
            validate={[isRequired, isEmail]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            readOnly={readOnly}
            validate={customEmail ? [isRequired, isEmail] : [isRequired]}
            component={readOnly || customEmail ? Input : Dropdown}
            options={
              readOnly
                ? undefined
                : customerLocationsEmails.map(le => ({
                    label: `${le.locationName} (${le.emailAddress || translate('customers.emails.noEmail')})`,
                    value: `${le.locationId},${le.emailAddress}`,
                    isDisabled: !le.emailAddress,
                  }))
            }
            name="toEmail"
            label={translate('common.to')}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Field
            readOnly={readOnly}
            component={Input}
            name="emailSubject"
            label={translate('common.subject')}
            validate={[isRequired]}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Field
            readOnly={readOnly}
            validate={[isRequired]}
            component={TextArea}
            name="emailMessage"
            placeholder={translate('common.message')}
            label={translate('common.message')}
            rows="5"
          />
        </GridColumn>
        {!!images.length && (
          <>
            <GridColumn size="12/12">
              <Text>{translate('customers.emails.attachments')}:</Text>
            </GridColumn>
            {images.map(image => (
              <GridColumn size="2/12" key={image.imageBlobId}>
                <ThumbnailWrapper
                  active={selectedImageBlobIds.includes(image.imageBlobId)}
                  onClick={() => toggleImageBlobId(image.imageBlobId)}
                >
                  <UnconnectedCheckbox
                    checked={selectedImageBlobIds.includes(image.imageBlobId)}
                    onChange={() => toggleImageBlobId(image.imageBlobId)}
                    onClick={e => e.stopPropagation()}
                    margin="no small no no"
                  />
                  <ThumbnailSetImageSlider
                    size="70px"
                    images={[image.imageUrl]}
                    imagesToDownload={[image.rawImageUrl || image.imageUrl]}
                  />
                </ThumbnailWrapper>
              </GridColumn>
            ))}
          </>
        )}
      </Grid>
      {!readOnly && (
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('common.send')}
          </Button>
        </ButtonSet>
      )}
    </form>
  );
};

const mapStateToProps = (
  state: AppState,
  { customerLocationsEmails, email: { toEmail, emailSubject, emailMessage, fromEmail } }: PropsWithoutReduxForm,
) => {
  return {
    initialValues: {
      fromEmail: fromEmail || state.account.login.user.email,
      toEmail: getToEmail(customerLocationsEmails, toEmail),
      emailSubject,
      emailMessage: emailMessage || translate('customers.emails.defaultMessage'),
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'customerEmail',
  })(CustomerEmailForm),
);
