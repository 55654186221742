import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { TextArea, TextDisplay } from '../../../core/components';
import { Button, Container, TableCell, TableRow } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { isRequired } from '../../../utils/services/validator';
import RubiconDispatchesNotesDateTimeDisplay from './RubiconDispatchesNotesDateTimeDisplay';
import RubiconDispatchesNotesReasonDisplay from './RubiconDispatchesNotesReasonDisplay';

interface PropsWithoutReduxForm {
  index: number;
  isInEditMode: boolean;
  onCancel(): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const RubiconDispatchesNotesTableRowForm: React.FC<Props> = ({ index, isInEditMode, onCancel, handleSubmit }) => {
  const cellWidths = React.useMemo(
    () => ({
      notes: 35,
      sourceName: 25,
      reason: isInEditMode ? 0 : 25,
      date: isInEditMode ? 40 : 15,
    }),
    [isInEditMode],
  );

  return (
    <form onSubmit={handleSubmit}>
      <TableRow isHighlighted={isInEditMode}>
        <TableCell width={`${cellWidths.notes}%`}>
          <Container>
            <Field
              name="userNote"
              component={isInEditMode ? TextArea : TextDisplay}
              validate={[isRequired]}
              props={
                isInEditMode
                  ? {
                      placeholder: translate('common.note'),
                      margin: 'no',
                      id: `notes-${index}-content`,
                    }
                  : undefined
              }
            />
          </Container>
        </TableCell>

        <TableCell width={`${cellWidths.sourceName}%`}>
          <Field
            name="createdByUserID"
            component={({ input: { value } }: { input: { value: string } }) => {
              if (isInEditMode) {
                return '';
              }

              return value.replace(/\s+/g, '').toLowerCase() === 'vendorportal'
                ? translate('common.vendor')
                : translate('autoDispatch.rubicon');
            }}
            props={{
              block: true,
              margin: 'no no xxSmall',
            }}
          />
        </TableCell>

        {!isInEditMode && (
          <TableCell width={`${cellWidths.reason}%`}>
            <Container>
              <Field name="reasonCode" component={RubiconDispatchesNotesReasonDisplay} />
            </Container>
          </TableCell>
        )}

        <TableCell width={`${cellWidths.date}%`} vertical={!isInEditMode} align={isInEditMode ? 'right' : undefined}>
          {!isInEditMode && <Field name="noteDate" component={RubiconDispatchesNotesDateTimeDisplay} />}

          {isInEditMode && (
            <>
              <Button
                size="small"
                color="secondary"
                margin="no xxSmall no no"
                onClick={onCancel}
                id={`notes-${index}-cancel`}
              >
                {translate('common.cancel')}
              </Button>

              <Button type="submit" size="small" color="primary" id={`notes-${index}-save`}>
                {translate('common.save')}
              </Button>
            </>
          )}
        </TableCell>
      </TableRow>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  enableReinitialize: true,
})(RubiconDispatchesNotesTableRowForm);
