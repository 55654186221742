import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const LOCATION_CUSTOMER_TYPE_ID = 1;
export const LOCATION_CONTAINER_YARD_ID = 2;
export const LOCATION_TRUCK_YARD_ID = 3;

export const CONTAINER_LOCATION_TYPES = mapKeys(
  [
    { id: LOCATION_CUSTOMER_TYPE_ID, name: translate('common.containerLocationTypes.customer') },
    { id: LOCATION_CONTAINER_YARD_ID, name: translate('common.containerLocationTypes.containerYard') },
    { id: LOCATION_TRUCK_YARD_ID, name: translate('common.containerLocationTypes.truckYard') },
  ],
  'id',
);
