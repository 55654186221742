import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MapGL } from 'src/common/components/map/MapGL';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import { getMapBounds } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import { RouteSegmentExtended } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import StreetNetworkMapRouteSegmentsGL from 'src/customers/components/pages/streetNetwork/StreetNetworkMapRouteSegmentsGL';
import { DASHBOARD_VEHICLE_POSITIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import {
  clearDashboardSelectedFeature,
  setDashboardViewport,
  setNavigationMapControl,
  setSatelliteMapControl,
} from 'src/dashboard/ducks/mapControls';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import DashboardMapAuditLinks from '../DashboardMapAuditLinks';
import DashboardMapDataInformation from '../DashboardMapDataInformation';
import DashboardMapLegend from '../DashboardMapLegend';
import DashboardWidgets from '../dashboardWidgets/DashboardWidgets';
import DashboardApplicationStatusGL from './dashboardApplicationStatus/DashboardApplicationStatusGL';
import DashboardCityAlertsGL from './dashboardCityAlerts/DashboardCityAlertsGL';
import DashboardCityInsightsGL from './dashboardCityInsights/DashboardCityInsightsGL';
import DashboardClustersGL from './dashboardCluster/DashboardClustersGL';
import DashboardGeoFencesGL from './dashboardGeoFences/DashboardGeoFencesGL';
import DashboardHaulerLocationsGL from './dashboardHaulerLocations/DashboardHaulerLocationsGL';
import DashboardRouteStopsGL from './dashboardRouteStops/DashboardRouteStopsGL';
import DashboardSnowSweeperStreetSegmentsGL from './dashboardSnowSweeperStreetSegments/DashboardSnowSweeperStreetSegmentsGL';
import DashboardGeoFenceIncidentsGL from './dashboardVehicleInsights/DashboardGeoFenceIncidentsGL';
import DashboardVehiclePositionsGL from './dashboardVehiclePositions/DashboardVehiclePositionsGL';
import DashboardVehicleTrackingsGL from './dashboardVehicleTrackings/DashboardVehicleTrackingsGL';
interface Props {
  showVehicleTypesPanel?: boolean;
  filteredSegments?: RouteSegmentExtended[];
  isRubiconZ: boolean;
  selectedVehicleId?: number;
  streetNetwork?: RouteSegmentExtended[];
  isSnowSweeperDashboard?: boolean;
  vehicleTypeId?: number;
  routeId?: number;
}

const DashboardMapGL: React.FC<Props> = ({
  showVehicleTypesPanel = false,
  filteredSegments = [],
  isRubiconZ,
  selectedVehicleId,
  streetNetwork = [],
  isSnowSweeperDashboard,
  vehicleTypeId,
  routeId,
}) => {
  //  TODO uncomment this when creating alert is enabled on map
  // const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
  // const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
  // const hasCityAlerts = getActiveCityAlertTypes(cityAlertSettings).hasCityAlerts;

  // const cityAlertsFilters = useMemo(() => {
  //   const cityAlertsFilters = [];
  //   if (hasCityAlerts) {
  //     cityAlertsFilters.push({
  //       name: 'cityAlertTypes',
  //       subFilters: activeCityAlertTypes?.map((option: CityAlertSetting) => {
  //         const alertType = option.cityAlertType;
  //         return {
  //           name: `alertType_${alertType.id}`,
  //         };
  //       }),
  //     });
  //   }
  //   return cityAlertsFilters;
  // }, [hasCityAlerts, activeCityAlertTypes]);

  const dispatch = useDispatch();

  const [map, setMap] = useState<mapboxgl.Map>();

  const viewport = useSelector(state => state.dashboard.mapControls.viewport);
  const isSatelliteView = useSelector(state => state.dashboard.mapControls.isSatelliteEnabled);
  const isOnDemandVideoDownloadEnabled = useSelector(isOnDemandVideoDownloadFeatureEnabled);

  const setNavigationRef = (element: HTMLDivElement | null) => {
    if (element) {
      dispatch(setNavigationMapControl({ width: element.offsetWidth, height: element.offsetHeight, isVisible: true }));
    } else {
      dispatch(setNavigationMapControl({ isVisible: false }));
    }
  };

  const setIsSatelliteView = (isSatelliteView: boolean) => {
    if (map) {
      const bounds = getMapBounds([{ latitude: map?.getCenter().lat, longitude: map?.getCenter().lng }]);
      dispatch(setDashboardViewport(bounds));
      dispatch(setSatelliteMapControl(isSatelliteView, map?.getCenter(), map?.getZoom()));
    }
  };

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If any cluster is clicked, then any popup
             * should be closed.
             */
            !!feature.properties?.cluster_id,
        );

        if (features.length) {
          dispatch(clearDashboardSelectedFeature());
        }
      });

      map.on('mousemove', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true || !!feature.properties?.cluster_id,
        );

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });

      // move vehicles on top of all layers
      map.on('idle', () => {
        if (map.getLayer(DASHBOARD_VEHICLE_POSITIONS_LAYER)) {
          map.moveLayer(DASHBOARD_VEHICLE_POSITIONS_LAYER);
        }
      });
    });
  }, [map, dispatch]);

  //  TODO uncomment this when creating alert is enabled on map
  // const handleLoadCityAlertsWhenCreatedNew = useCallback(() => {
  //   dispatch(change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'isCityAlertsLayerOn', true));
  //   const newCityAlertsFormValues = cityAlertsFilters
  //     ?.map(cityAlert => {
  //       const subFilters = cityAlert.subFilters?.map((subFilter: any) => ({
  //         [subFilter.name]: true,
  //       }));

  //       return {
  //         [cityAlert.name]: {
  //           groupFilters: Object.assign({}, ...subFilters),
  //           isGroupSelected: true,
  //         },
  //       };
  //     })
  //     .reduce((acc, val) => Object.assign(acc, val), {});

  //   dispatch(change(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, 'cityAlerts.groups', newCityAlertsFormValues));
  // }, [dispatch, cityAlertsFilters]);

  return (
    <MapGLWrapper>
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        navigationControlXOffset={-10}
        onMapRefLoaded={map => setMap(map)}
        setIsSatelliteViewEnabled={setIsSatelliteView}
        setNavigationRef={setNavigationRef}
      >
        <DashboardMapLegend isRubiconZ={isRubiconZ} />

        <DashboardMapAuditLinks auditLinksControlXOffset={70} />

        <DashboardMapDataInformation isSnowSweeperDashboard={isSnowSweeperDashboard} />

        {/* TODO uncomment this when creating alert is enabled on map */}
        {/* <CityAlertCreateTrigger
          xOffset={0}
          yOffset={180}
          position="bottom-right"
          onSavedSuccess={handleLoadCityAlertsWhenCreatedNew}
          zIndex={6001}
          routeId={routeId}
        /> */}

        {!!map && (
          <>
            {isSnowSweeperDashboard && (
              <DashboardSnowSweeperStreetSegmentsGL vehicleTypeId={vehicleTypeId || SNOW_PLOW_ID} map={map} />
            )}

            <DashboardClustersGL map={map} selectedVehicleId={selectedVehicleId} />

            <DashboardGeoFenceIncidentsGL map={map} />

            <DashboardCityAlertsGL map={map} />

            <DashboardCityInsightsGL map={map} />

            <DashboardHaulerLocationsGL map={map} />

            <DashboardGeoFencesGL map={map} />

            <DashboardRouteStopsGL map={map} />

            <DashboardVehicleTrackingsGL
              isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled}
              map={map}
              selectedVehicleId={selectedVehicleId}
            />

            <DashboardApplicationStatusGL isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled} map={map} />

            <DashboardVehiclePositionsGL map={map} isSnowSweeperDashboard={isSnowSweeperDashboard} />

            {!isSnowSweeperDashboard && (
              <StreetNetworkMapRouteSegmentsGL
                map={map}
                streetNetwork={streetNetwork}
                routeSegments={filteredSegments}
                isSatelliteView={isSatelliteView}
                isDailyRoute={true}
                isDashboardPage
                routeId={routeId}
                isSnowPlowRoute={vehicleTypeId === SNOW_PLOW_ID}
              />
            )}
          </>
        )}
      </MapGL>

      <DashboardWidgets
        displayActiveVehicleTypes={showVehicleTypesPanel}
        isForSnowSweeper={
          isSnowSweeperDashboard || vehicleTypeId === SNOW_PLOW_ID || vehicleTypeId === STREET_SWEEPER_ID
        }
      />
    </MapGLWrapper>
  );
};

export default DashboardMapGL;
