import { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from 'src/contracts/ducks';
import { loadGeoFenceFilterOptions } from 'src/routes/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import CustomerProximitySearchModal from './CustomerProximitySearchModal';

interface Props {
  defaultDate?: string;
  defaultDateIsSelected?: boolean;
  loadGeoFenceFilterOptions: DuckFunction<typeof loadGeoFenceFilterOptions>;
  locationId?: number;
  onCancel: () => void;
  vendorId: number;
}

class CustomerProximitySearchEditorModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadGeoFenceFilterOptions, vendorId, defaultDate } = this.props;

    const dependencies: Promise<unknown>[] = [loadGeoFenceFilterOptions({ vendorId, routeDate: defaultDate })];

    return Promise.all(dependencies);
  };

  render() {
    const { defaultDate, defaultDateIsSelected, locationId, onCancel } = this.props;

    return (
      <Fragment>
        <Resolver
          successComponent={CustomerProximitySearchModal}
          loadingComponent={PageLoadingOverlay}
          successProps={{
            defaultDate,
            defaultDateIsSelected,
            locationId,
            onCancel,
          }}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadGeoFenceFilterOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProximitySearchEditorModalResolver);
