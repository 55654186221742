import { useDispatch } from 'react-redux';
import React from 'react';

import { loadRateAccountingCodes } from 'src/finance/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import RateConfigurationAddRateModal from './RateConfigurationAddRateModal';

interface Props {
  onCancel(): void;
}
const RateConfigurationAddRateModalResolver: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useDispatch();

  const loadDependencies = async () => {
    const dependencies: Promise<unknown>[] = [loadRateAccountingCodes()(dispatch)];

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoadingOverlay}
      successComponent={RateConfigurationAddRateModal}
      successProps={{
        onCancel,
      }}
    />
  );
};

export default RateConfigurationAddRateModalResolver;
