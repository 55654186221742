import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const SIMPLE = 1;
export const MODERATE = 2;
export const COMPLEX = 3;

export const DRIVING_COMPLEXITY_TYPES = mapKeys(
  [
    { id: SIMPLE, name: translate('vendors.drivingComplexity.simple') },
    { id: MODERATE, name: translate('vendors.drivingComplexity.moderate.title') },
    { id: COMPLEX, name: translate('vendors.drivingComplexity.complex.title') },
  ],
  'id',
);
