import styled from 'styled-components';

import theme from '../../../core/styles/theme';

export const LocationNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 50%;
  padding: 5px;
  color: ${theme.markerFontColor};
  background-color: ${theme.markerBackground};
  border: 1px solid ${theme.markerStroke};
`;

export const DaysOfOperationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`;

export const DaysOfOperationLabel = styled.span`
  pointer-events: none;
  text-transform: capitalize;
  font-size: 14px;
  color: ${props => props.theme.grayDark};
`;

export const HoursOfOperationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
