import React, { useState } from 'react';
import { ActionButtonTooltip, Modal } from '../../core/components';
import { TableActionButton, TableCell, TableRow, Text } from '../../core/components/styled';
import translate from '../../core/services/translate';
import moment from 'moment';
import { ReleaseNote } from '../interfaces/ReleaseNote';
import { ReleaseNoteEditorModal } from './ReleaseNoteEditorModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReleaseNote, loadReleaseNotes } from '../ducks/releaseNotes';
import { createErrorNotification, createSuccessNotification } from '../../core/services/createNotification';
import confirm from '../../core/services/confirm';
import { RELEASE_TYPES_MAP } from '../constants/releaseTypes';
import { ReleaseNoteContent } from './ReleaseNotesPageContent';
import { releaseNotesFilterSelector } from './forms/ReleaseNotesManagementFilterForm';

export default function ReleaseNotesTableRow(releaseNote: ReleaseNote) {
  const { releaseDate, releaseType, notifyUsers, publishDate, id } = releaseNote;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { to, from } = useSelector(s => releaseNotesFilterSelector(s, 'dateRange'));

  const deleteNote = async () => {
    if (!(await confirm(translate('versioning.alerts.deleteRelease')))) {
      return;
    }
    deleteReleaseNote(id)(dispatch)
      .then(() => {
        createSuccessNotification(translate('versioning.alerts.deletedRelease'));
        loadReleaseNotes(from, to)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('versioning.alerts.deleteError'));
      });
  };

  return (
    <>
      <TableRow>
        <TableCell width="25%">
          <Text>{moment(releaseDate).format('MM/DD/YYYY')}</Text>
        </TableCell>
        <TableCell width="25%">
          <Text>{RELEASE_TYPES_MAP[releaseType]?.label}</Text>
        </TableCell>
        <TableCell width="20%">
          <Text>{notifyUsers ? translate('common.yes') : translate('common.no')}</Text>
        </TableCell>
        <TableCell width="20%">
          <Text>{publishDate && moment(publishDate).format('MM/DD/YYYY')}</Text>
        </TableCell>
        <TableCell justifyContent="flex-end" width="10%">
          <TableActionButton onClick={() => setIsPreviewModalOpen(true)}>
            <ActionButtonTooltip icon="check" tooltip={translate('versioning.previewReleaseNote')} tooltipAsString />
          </TableActionButton>
          <TableActionButton onClick={() => setIsEditModalOpen(true)}>
            <ActionButtonTooltip icon="edit" tooltip={translate('versioning.editReleaseNote')} tooltipAsString />
          </TableActionButton>
          <TableActionButton onClick={deleteNote}>
            <ActionButtonTooltip icon="delete" tooltip={translate('versioning.deleteReleaseNote')} tooltipAsString />
          </TableActionButton>
        </TableCell>
      </TableRow>
      {isEditModalOpen && (
        <ReleaseNoteEditorModal releaseNote={releaseNote} onCloseModal={() => setIsEditModalOpen(false)} />
      )}
      {isPreviewModalOpen && (
        <Modal
          title={translate('versioning.previewReleaseNote')}
          onClose={() => setIsPreviewModalOpen(false)}
          size="large"
        >
          <ReleaseNoteContent releaseNote={releaseNote} />
        </Modal>
      )}
    </>
  );
}
