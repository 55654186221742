import { http } from '../../core/services/http';
import { FeatureConfigurations } from '../interfaces/FeatureConfiguration';

export const loadFeatureConfigurations = (vendorId: number, vehicleTypeId: number, featureCode: any) =>
  http
    .get(`/vendors/${vendorId}/featureConfig`, { params: { featureCode, vehicleTypeId } })
    .then(response => response.data);

export const saveFeatureConfigurations = (vendorId: number, featureConfigurations?: FeatureConfigurations) =>
  http.post(`/vendors/${vendorId}/featureConfig`, featureConfigurations).then(response => response.data);
