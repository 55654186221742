import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { debounce, map } from 'lodash-es';
import { Field, formValueSelector, reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';

import { AppState } from '../../../store';
import { augmentWithType } from '../../services/routeTemplateHelper';
import { BULK } from 'src/common/constants';
import { Button, ButtonSet, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { checkIfSupport } from '../../../account/utils/permissions';
import { COMPLETED, ISSUE_REPORTED, SCHEDULED, PICKUP_STATUSES } from 'src/common/constants';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DatePicker, Dropdown, Input, TypeAhead, TextArea } from '../../../core/components';
import {
  DELIVERY_UTILITY_ID,
  DELIVERY_UTILITY,
  FRONT_LOAD,
  RESIDENTIAL,
  ROLL_OFF,
  TOTER,
} from '../../../fleet/constants';
import { DISPATCH_BOARD_FORM_NAME } from 'src/routes/constants/dispatchBoard';
import { DispatchBoardContainerError } from '../styled';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { FRONT_LOAD_ID, RESIDENTIAL_ID } from '../../../fleet/constants/vehicleTypes';
import { getComboRouteOptions, getRequiredOptions } from './utils/CustomOptionsUtils';
import { getExcludeVehicleFilters, getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { isDateValidValidator, isRequired, maxLength15 } from '../../../utils/services/validator';
import { JOB_IS_NOT_PENDING_ID, JOB_IS_PENDING_ID } from 'src/routes/constants/jobStatuses';
import { JobPriorityTypesDropdown, WasteTypeDropdown } from 'src/common/components';
import { loadDispatchBoardMaterialTypes } from 'src/vendors/ducks';
import { loadPickupTypes } from '../../ducks/pickupTypes';
import { loadRoutesAndTemplates as doLoadRoutes } from '../../services/dispatchBoardRoutes';
import { loadVendor } from '../../../vendors/ducks';
import { MaterialTypesDropdown, PickupTypeDropdown, PinOnMapMapbox } from '..';
import { MaterialTypes } from 'src/vendors/interfaces/MaterialTypes';
import { ON_HOLD } from '../../../common/constants/accountStatuses';
import { PICKUP_TYPE_PICKUP_ID, PICKUP_TYPE_SERVICE_ID } from '../../constants';
import { renderCustomerLocationOptionLabel } from './utils/CustomerLocationUtils';
import { resetDispatchBoardRouteJob, resetDispatchBoardUnassignedJob } from '../../ducks/dispatchBoard';
import { ROLL_OFF_ID } from 'src/common/constants/serviceTypes';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import { TODAY_FORMATTED, TODAY } from '../../../core/constants';
import { VehicleTypeForVendorDropdown } from '../../../fleet/components';
import { vehicleTypesForVendorWithRoleTypeSelector } from 'src/fleet/ducks';
import CustomerLocationWithPin from '../CustomerLocationWithPin';
import dispatchBoardJobEditorFormInitialValuesSelector from '../../services/dispatchBoardJobEditorFormInitialValuesSelector';
import doLoadServiceAccountStatus from '../../services/loadServiceAccountStatus';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import searchCustomerLocations from '../../services/searchCustomerLocations';
import translate from '../../../core/services/translate';
import { CustomerLocationServiceContract } from 'src/customers/interfaces/Customers';
import { getServiceName } from 'src/routes/services/transformSearchCustomerLocations';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';

interface ReduxFormValues {
  vehicleType?: string;
  vehicleTypeId?: number;
  serviceContractId?: number;
  locationName?: string;
  pickupTypeId: number;
}

interface ComponentProps {
  allowRoutesFromPast?: boolean;
  customerId?: number;
  date?: Date | string;
  duplicateRouteId?: number | null;
  handleRoutes?: (routes: any[]) => void;
  initialRouteId?: string;
  isDuplicate: boolean;
  isMaterialTypesEnabled?: boolean;
  jobId?: number;
  routeId?: number;
  vehicleTypeId?: number;
  selectedServiceContract?: CustomerLocationServiceContract;
  isBillingFeatureActive?: boolean;
}

interface PropsWithoutReduxForm extends ComponentProps {
  date: Date | string;
  excludeVehicleFilters: string[];
  filtersPreferencesIds: FiltersPreferencesIds;
  forceMaterialTypes: MaterialTypes[];
  isDateReadonly: boolean;
  isSaving: boolean;
  isSupport: boolean;
  job: any;
  loadDispatchBoardMaterialTypes: DuckFunction<typeof loadDispatchBoardMaterialTypes>;
  loadPickupTypes: DuckFunction<typeof loadPickupTypes>;
  loadVendor: DuckFunction<typeof loadVendor>;
  materialTypes: MaterialTypes[];
  reasonCodeTypes: TechnicalType[];
  resetDispatchBoardRouteJob: DuckAction<typeof resetDispatchBoardRouteJob>;
  resetDispatchBoardUnassignedJob: DuckAction<typeof resetDispatchBoardUnassignedJob>;
  routeIdInitialValue?: number;
  routeJob?: any;
  serviceContractId?: number;
  vehicleTypesForVendor?: TechnicalType[];
  vendorId: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  allRouteVehicleTypes: any[];
  customRouteOptions: any[];
  date: Date | string;
  defaultPickupTypeId: number;
  isLoading: boolean;
  isMapOpened: boolean;
  isOptimizedOptionHidden: boolean;
  isPinOnMapSelected: boolean;
  locationReference?: TypeAhead;
  mapCenterByVendor?: {
    lat: string;
    lng: string;
  };
  pickupTypes: TechnicalType[];
  routeId?: number;
  routeOptions: any[];
  routeVehicleTypeIds: any[];
  totalRoutes: any[];
  vehicleTypeId?: number;
}

const unassignedOption = { label: translate('routes.unassigned'), value: '-1', vehicleTypeId: -1 };

class DispatchBoardJobEditorForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      allRouteVehicleTypes: [],
      customRouteOptions: [],
      date: props.date,
      defaultPickupTypeId: props.vehicleTypeId === DELIVERY_UTILITY_ID ? PICKUP_TYPE_SERVICE_ID : PICKUP_TYPE_PICKUP_ID,
      isLoading: false,
      isMapOpened: false,
      isOptimizedOptionHidden: false,
      isPinOnMapSelected: !!props.selectedServiceContract,
      mapCenterByVendor: undefined,
      pickupTypes: [],
      routeOptions: [],
      routeVehicleTypeIds: [],
      totalRoutes: [],
      vehicleTypeId: props.vehicleTypeId,
    };
  }

  componentDidMount() {
    const { vehicleTypeId, date } = this.state;

    if (!vehicleTypeId) {
      return;
    }

    this.loadPickupTypes(vehicleTypeId);
    this.loadRoutes(vehicleTypeId, date);
  }

  componentWillUnmount() {
    const { resetDispatchBoardRouteJob, resetDispatchBoardUnassignedJob, routeId } = this.props;

    if (!routeId || (routeId && routeId.toString() === '-1')) {
      resetDispatchBoardUnassignedJob();
    } else {
      resetDispatchBoardRouteJob();
    }
  }

  onDateChange = async (e: any, newDate: any) => {
    const { vehicleTypeId } = this.state;

    this.setState({ date: newDate });

    if (!vehicleTypeId) {
      return;
    }

    await this.loadRoutes(vehicleTypeId, newDate);

    this.props.change('routeId', null);
    this.props.change('date', newDate);

    if (!this.props.serviceContractId) {
      return;
    }

    const accountStatus = await doLoadServiceAccountStatus(
      this.props.serviceContractId,
      moment(newDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
    );

    if (this.state.locationReference) {
      const { option } = this.state.locationReference.state;

      /**
       * The account status might vary from day to day, so
       * changing the date requires a new account status check
       * since the customer location dropdown might already
       * have some state regarding the current location.
       *
       * This piece of code (even if it's a bit ugly), changes
       * the selected option on the TypeAhead state.
       *
       * Nasty, I know.
       */

      if (typeof option === 'object' && !!option) {
        this.state.locationReference.setState({
          option: {
            ...(option || {}),
            isOnHold: accountStatus === ON_HOLD,
          },
        });
      }
    }
  };

  onVehicleTypeChange = async (e: any, newVehicleTypeId: number) => {
    const { change, isMaterialTypesEnabled, loadDispatchBoardMaterialTypes, vendorId, selectedServiceContract } =
      this.props;
    const { date } = this.state;

    this.setState({
      vehicleTypeId: newVehicleTypeId,
      isPinOnMapSelected: false,
    });

    await this.loadPickupTypes(newVehicleTypeId);
    await this.loadRoutes(newVehicleTypeId, date);

    if (isMaterialTypesEnabled) {
      await loadDispatchBoardMaterialTypes(vendorId, newVehicleTypeId);
    }

    if (!selectedServiceContract) change('serviceContractId', null);
    change('materialTypeId', null);
    change('vehicleTypeId', newVehicleTypeId);
    change('pickupTypeId', this.state.defaultPickupTypeId);
    change('routeId', unassignedOption.value);
  };

  onRouteChange = async (e: any, routeId?: string) => {
    const { routeVehicleTypeIds, vehicleTypeId, totalRoutes, routeOptions } = this.state;
    const { change } = this.props;

    const isSelectedVehicleTypeCorrect = routeVehicleTypeIds?.filter(
      vehicleType => vehicleType.id === vehicleTypeId,
    ).length;

    const setIsOptimizedOptionHidden = (routeOptions: any) => {
      const selectedRoute = routeOptions.filter((r: any) => r.value === routeId);
      if (selectedRoute.length > 0) {
        this.setState({ isOptimizedOptionHidden: selectedRoute[0].routeStatusTypeId !== SCHEDULED });
        change(
          'hasStopsInPendingOptimization',
          selectedRoute[0].hasStopsInPendingOptimization ? JOB_IS_PENDING_ID : JOB_IS_NOT_PENDING_ID,
        );
      }
    };

    if (isSelectedVehicleTypeCorrect) {
      totalRoutes.forEach((route, index) => {
        if (index > 0) {
          setIsOptimizedOptionHidden(route.options);
        }
      });
    } else {
      setIsOptimizedOptionHidden(routeOptions);
    }

    this.setState({
      routeId: Number(routeId),
    });
  };

  setOpenSelectOnMap = () => {
    this.setState({ isLoading: true });

    const { vendorId, loadVendor } = this.props;

    loadVendor(vendorId).then((response: any) => {
      const mapCenterByVendor = { lat: response.homeAddress.latitude, lng: response.homeAddress.longitude };

      this.setState({
        isMapOpened: true,
        isLoading: false,
        mapCenterByVendor,
      });
    });
  };

  handlePinSelection = (address: any) => {
    const { change } = this.props;

    const newAddress = {
      ...address,
      formattedAddress: address.formattedAddress || address.line1,
    };

    change('pinAddress', newAddress);

    this.setState({
      isPinOnMapSelected: true,
      isMapOpened: false,
    });
  };

  clearPinnedAddress = () => {
    const { change } = this.props;

    change('pinAddress', null);
    this.setState({ isPinOnMapSelected: false });
  };

  handleBackClick = () => {
    this.setState({ isMapOpened: false });
  };

  holdReferenceForLocationSelect = (ref: TypeAhead) => {
    this.setState({ locationReference: ref });
  };

  loadPickupTypes = async (vehicleTypeId: number) => {
    const { loadPickupTypes } = this.props;

    this.setState({ isLoading: true });

    const pickupTypesPromise = await loadPickupTypes(vehicleTypeId);

    this.setState({
      defaultPickupTypeId: pickupTypesPromise[0].defaultPickupTypeId,
      isLoading: false,
      pickupTypes: pickupTypesPromise[0].pickupTypes,
    });
  };

  loadRoutes = async (vehicleTypeId: number, date: Date | string) => {
    const { filtersPreferencesIds, handleRoutes, routeId, vehicleTypesForVendor, vendorId } = this.props;

    if (!!vehicleTypeId && !!date) {
      this.setState({ isLoading: true });

      const routes = await doLoadRoutes(vendorId, vehicleTypeId, date, filtersPreferencesIds);

      let routeOptions;

      const unassignedOption = {
        label: translate('routes.unassigned'),
        value: '-1',
        vehicleTypeId: -1,
        routeStatusTypeId: 0,
        hasStopsInPendingOptimization: false,
      };

      const groupedCustomOptions = [
        unassignedOption,
        ...(vehicleTypeId === FRONT_LOAD_ID || vehicleTypeId === RESIDENTIAL_ID || DELIVERY_UTILITY_ID
          ? getComboRouteOptions(vehicleTypeId, routes, true)
          : getRequiredOptions(vehicleTypeId, routes, true)),
      ];

      this.setState({
        totalRoutes: groupedCustomOptions,
      });

      let routeVehicleTypeIds = vehicleTypesForVendor?.filter(vehicleType => {
        return (
          vehicleType.technicalName === FRONT_LOAD ||
          vehicleType.technicalName === RESIDENTIAL ||
          vehicleType.technicalName === BULK ||
          vehicleType.technicalName === DELIVERY_UTILITY
        );
      });
      let allRouteVehicleTypes = [] as any[];
      vehicleTypesForVendor?.forEach(async vehicleType => {
        await doLoadRoutes(vendorId, vehicleType.id, date, filtersPreferencesIds).then(res =>
          allRouteVehicleTypes.push(...res),
        );
      });

      routeOptions = map(routes, route => ({
        label: route.name,
        value: augmentWithType(route.id, route.isTemplate),
        vehicleTypeId: route.vehicleTypeId,
        routeStatusTypeId: route.routeStatusTypeId,
        hasStopsInPendingOptimization: route.hasStopsInPendingOptimization,
      }));

      routeOptions.unshift(unassignedOption);

      if (handleRoutes) {
        handleRoutes(routes);
      }
      if (routeOptions && routeVehicleTypeIds) {
        this.setState({
          allRouteVehicleTypes,
          isLoading: false,
          routeOptions,
          routeVehicleTypeIds,
        });
      }
    }

    this.onRouteChange(undefined, routeId?.toString());
  };

  loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    const { vendorId, customerId } = this.props;
    const { vehicleTypeId, date } = this.state;

    searchCustomerLocations(vendorId, searchTerm, vehicleTypeId, undefined, date, customerId).then((response: any) => {
      const customerLocationsOptions = map(response, customerGroup => ({
        label: customerGroup.label,
        options: map(customerGroup.options, customer => ({
          label: customer.label,
          value:
            customer.value.service.serviceContractBinDetails.length &&
            customer.value.service.serviceContractBinDetails[0].serviceContractId,
          isOnHold: customer.value.service.serviceContractAccountStatusTypeIdAtDate === ON_HOLD,
        })),
      }));
      return onOptionsLoaded(customerLocationsOptions);
    });
  }, 500);

  renderForm = () => {
    const {
      duplicateRouteId,
      excludeVehicleFilters,
      forceMaterialTypes,
      handleSubmit,
      initialRouteId,
      isBillingFeatureActive,
      isDateReadonly,
      isDuplicate,
      isMaterialTypesEnabled,
      isSaving,
      jobId,
      materialTypes,
      reasonCodeTypes,
      routeIdInitialValue,
      routeJob,
      selectedServiceContract,
    } = this.props;

    const {
      date,
      isLoading,
      isOptimizedOptionHidden,
      pickupTypes,
      routeId,
      routeOptions,
      routeVehicleTypeIds,
      totalRoutes,
      vehicleTypeId,
    } = this.state;

    const isEditing = !!jobId;
    const isRouteReadonly =
      !vehicleTypeId ||
      !date ||
      (routeJob != null &&
        (routeJob.jobStatusId === PICKUP_STATUSES[COMPLETED].id ||
          routeJob.jobStatusId === PICKUP_STATUSES[ISSUE_REPORTED].id));

    const isSelectedVehicleTypeCorrect = routeVehicleTypeIds?.filter(vehicleType => vehicleType.id === vehicleTypeId);

    const isCompleted = routeJob?.jobStatusId === COMPLETED;

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall no" isLoading={isLoading || isSaving}>
          <Grid multiLine>
            {isEditing ? (
              <GridColumn size="6/12">
                <Field name="vehicleType" component={Input} label={translate('vehicles.vehicleType')} disabled />
              </GridColumn>
            ) : (
              <GridColumn size="6/12">
                <Field
                  name="vehicleTypeId"
                  component={VehicleTypeForVendorDropdown as any}
                  label={translate('vehicles.vehicleType')}
                  acceptedVehicleTypes={[FRONT_LOAD, ROLL_OFF, TOTER, DELIVERY_UTILITY, RESIDENTIAL]}
                  validate={[isRequired]}
                  onChange={this.onVehicleTypeChange}
                  excludeVehicleTypes={excludeVehicleFilters}
                />
              </GridColumn>
            )}
            <GridColumn size="6/12">
              <Field
                name="date"
                component={DatePicker as any}
                label={translate('common.date')}
                disabledDays={[{ before: TODAY }]}
                validate={[isRequired, isDateValidValidator]}
                onChange={this.onDateChange}
                disabled={isDateReadonly}
                props={{ disableFormSubmitOnDateChange: true }}
              />
            </GridColumn>

            {isEditing || selectedServiceContract ? (
              <GridColumn size="12/12">
                <Field name="locationName" component={Input} label={translate('routes.customerLocation')} disabled />
              </GridColumn>
            ) : (
              this.renderCustomerLocationLine()
            )}

            <GridColumn size="6/12">
              <>
                <Field
                  name="routeId"
                  component={Dropdown}
                  label={translate('routes.route')}
                  options={isSelectedVehicleTypeCorrect.length ? totalRoutes : [...routeOptions]}
                  disabled={isRouteReadonly}
                  validate={[isRequired]}
                  onChange={this.onRouteChange}
                />
                <Field margin="no" name="hasStopsInPendingOptimization" component={Input} type="hidden" />
              </>
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                name="pickupTypeId"
                component={PickupTypeDropdown}
                withLabel
                validate={[isRequired]}
                pickupTypes={pickupTypes}
                props={{ dropdownProps: { disabled: isEditing && isBillingFeatureActive } }}
              />
            </GridColumn>

            {isMaterialTypesEnabled && (
              <GridColumn size="6/12">
                <Field
                  name="materialTypeId"
                  component={MaterialTypesDropdown}
                  withLabel
                  forceMaterialTypes={!!forceMaterialTypes?.length ? forceMaterialTypes : materialTypes}
                  props={{ dropdownProps: { disabled: isCompleted && isEditing && isBillingFeatureActive } }}
                />
              </GridColumn>
            )}

            <GridColumn size="6/12">
              <Field
                name="workOrderNumber"
                component={Input}
                validate={[maxLength15]}
                label={translate('routes.workOrderNumber')}
                disabled={isEditing && isBillingFeatureActive && isCompleted}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                name="reasonCodeTypeId"
                component={Dropdown}
                props={{
                  isClearable: true,
                  label: translate('common.reasonCode'),
                  options: reasonCodeTypes.map(type => ({
                    label: type.name,
                    value: type.id,
                  })),
                }}
                disabled={isEditing && isBillingFeatureActive && isCompleted}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                name="jobPriorityTypeId"
                component={JobPriorityTypesDropdown}
                props={{
                  isClearable: true,
                  withLabel: true,
                  dropdownProps: { disabled: isCompleted && isEditing && isBillingFeatureActive },
                }}
              />
            </GridColumn>

            {(((routeId?.toString() || '') !== '-1' && routeId && routeIdInitialValue !== Number(routeId)) ||
              (initialRouteId !== '-1' && !isEditing)) && (
              <GridColumn size="6/12">
                <Field
                  isOptimizedOptionHidden={vehicleTypeId === ROLL_OFF_ID || isOptimizedOptionHidden}
                  withLabel
                  name="positionTypeId"
                  component={JobPositionTypeDropdown as any}
                />
              </GridColumn>
            )}

            {!isEditing && (
              <GridColumn size="12/12">
                <Field name="routeNote" component={TextArea} rows={4} label={translate('routes.routeDetails')} />
              </GridColumn>
            )}

            {isDuplicate && duplicateRouteId && (
              <DispatchBoardContainerError>
                <p>
                  {translate('dispatchBoard.duplicateJobEditWarning', {
                    routeName:
                      routeOptions.find(r => r.value === duplicateRouteId.toString()) &&
                      routeOptions.find(r => r.value === duplicateRouteId.toString()).label,
                  })}
                </p>
                <p>{translate('dispatchBoard.duplicateJobsSelectedConfirmation')}</p>
              </DispatchBoardContainerError>
            )}

            <GridColumn size="12/12">
              <ButtonSet margin="large auto no">
                <Button type="submit" color="primary" onClick={handleSubmit} id="save-job-button">
                  {isDuplicate ? translate('dispatchBoard.continueSave') : translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  };

  renderCustomerLocationLine = () => {
    const isDeliveryUtility = this.state.vehicleTypeId === DELIVERY_UTILITY_ID;
    const { isPinOnMapSelected } = this.state;

    // for delivery/utility the user has the option to pin on map the customer/location
    if (isDeliveryUtility) {
      return (
        <>
          <CustomerLocationWithPin
            colSize={6}
            fieldName="serviceContractId"
            getOptions={this.loadCustomerLocations}
            handleClearAddress={this.clearPinnedAddress}
            handlePinClick={this.setOpenSelectOnMap}
            isPinOnMapSelected={isPinOnMapSelected}
            renderCustomerLocationOptionLabel={renderCustomerLocationOptionLabel}
            holdSelectReference={this.holdReferenceForLocationSelect}
            formShouldRefresh={false}
          />

          {isPinOnMapSelected && (
            <GridColumn size="6/12">
              <Field name="wasteMaterialTypeId" label={translate('common.wasteType')} component={WasteTypeDropdown} />
            </GridColumn>
          )}
        </>
      );
    }

    return (
      <GridColumn size="12/12">
        <Field
          name="serviceContractId"
          component={TypeAhead}
          placeholder={`${translate('common.customer')} / ${translate('common.location')}`}
          getOptions={this.loadCustomerLocations}
          isClearable
          validate={[isRequired]}
          props={{
            getOptionLabel: renderCustomerLocationOptionLabel,
            ref: this.holdReferenceForLocationSelect,
            formShouldRefresh: true,
          }}
        />
      </GridColumn>
    );
  };

  render() {
    const { isMapOpened, mapCenterByVendor } = this.state;
    const PinOnMapHack = PinOnMapMapbox as any;

    if (isMapOpened) {
      return (
        <PinOnMapHack
          handleBackClick={this.handleBackClick}
          handlePinSelection={this.handlePinSelection}
          mapCenterByVendor={mapCenterByVendor}
          modalHasTitle
        />
      );
    }

    return this.renderForm();
  }
}

const dispatchBoardFormSelector = formValueSelector(DISPATCH_BOARD_FORM_NAME);
const dispatchBoardJobEditorFormSelector = formValueSelector('dispatchBoardJobEditorForm');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const {
    account,
    fleet,
    routes,
    routes: { dispatchBoard },
    vendors,
  } = state;
  const { allowRoutesFromPast, date: dateFromProps, jobId, routeId, vehicleTypeId, selectedServiceContract } = ownProps;
  let dateInitialValue: Date | string = TODAY_FORMATTED;
  let routeIdInitialValue: number | undefined = undefined;

  if (!!dateFromProps && moment(dateFromProps, 'MM/DD/YYYY').isSameOrAfter(moment(), 'day')) {
    dateInitialValue = dateFromProps;
    routeIdInitialValue = routeId;
  } else if (allowRoutesFromPast) {
    routeIdInitialValue = routeId;
  }

  const isSupport = checkIfSupport();
  const isSaving = routeIdInitialValue ? dispatchBoard.routeJob.isSaving : dispatchBoard.unassignedJob.isSaving;
  const vendorId = (currentVendorIdSelector as any)(account.login, vendors.defaultVendor);
  const job: any = routeIdInitialValue ? dispatchBoard.routeJob.routeJob : dispatchBoard.unassignedJob.unassignedJob;

  if (!!job) {
    dateInitialValue = moment(job.date).format('MM/DD/YYYY');
  }

  if (isSupport) {
    routeIdInitialValue = -1;
  }

  const routeJob =
    jobId == null
      ? null
      : (dispatchBoard.sourceRouteJobs as any).routeJobs.find((j: any) => j.id === jobId) ||
        dispatchBoard.targetRouteJobs.routeJobs.find((j: any) => j.id === jobId);

  const vehicleTypesForVendorState = fleet.vehicleTypesForVendor;
  const dispatchBoardFormVehicleTypeId = dispatchBoardFormSelector(state, 'vehicleTypeId') || vehicleTypeId;

  const pickupTypeId =
    routes && routes.pickupTypes && routes.pickupTypes.pickupTypes
      ? (routes.pickupTypes.pickupTypes as any)[0].defaultPickupTypeId
      : PICKUP_TYPE_PICKUP_ID;

  const serviceContractId = dispatchBoardJobEditorFormSelector(state, 'serviceContractId');
  const vehicleTypesForVendor =
    (vehicleTypesForVendorWithRoleTypeSelector as any)(state.fleet.vehicleTypesForVendor) || [];

  const initialRouteId = dispatchBoardJobEditorFormSelector(state, 'routeId');
  const { filters } = state.common.filters;

  let locationName: string | undefined = job?.locationName;

  if (selectedServiceContract) {
    locationName = getServiceName({
      equipmentSizeName: selectedServiceContract.equipmentSize.name,
      equipmentSizeTechnicalName: selectedServiceContract.equipmentSize.technicalName,
      equipmentTypeTechnicalName: selectedServiceContract.equipmentType.technicalName,
      equipmentTypeName: selectedServiceContract.equipmentType.name,
      wasteMaterialTypeTechnicalName: selectedServiceContract.wasteMaterialType.technicalName,
      serviceContractBinDetails: selectedServiceContract.binNumber,
      serviceTypeName: selectedServiceContract.serviceType.name,
    });
  }

  return {
    date: dateInitialValue,
    excludeVehicleFilters: getExcludeVehicleFilters(filters) as any,
    filtersPreferencesIds: {
      vehicleTypeIdsCSV: getVehicleFiltersPreferencesIds(filters).join(','),
      supervisorIdsCSV: '',
      serviceZoneIdsCSV: '',
    },
    forceMaterialTypes: state.vendors.materialTypes.forceMaterialTypes,
    isDateReadonly: routeJob != null && routeJob.jobStatusId !== SCHEDULED,
    initialRouteId,
    initialValues: (dispatchBoardJobEditorFormInitialValuesSelector as any)(
      vendorId,
      pickupTypeId,
      job,
      vehicleTypesForVendorState,
      dispatchBoardFormVehicleTypeId,
      dateInitialValue,
      routeIdInitialValue,
      locationName,
      selectedServiceContract?.id || job?.serviceContractId,
    ),
    isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
    isSaving,
    isSupport,
    job,
    materialTypes: state.vendors.materialTypes.materialTypes,
    reasonCodeTypes: state.common.reasonCodeTypes.reasonCodeTypes,
    routeIdInitialValue,
    routeJob,
    serviceContractId,
    vehicleTypesForVendor,
    vendorId,
  };
};

const mapDispatchToProps = {
  loadDispatchBoardMaterialTypes,
  loadPickupTypes,
  loadVendor,
  resetDispatchBoardRouteJob,
  resetDispatchBoardUnassignedJob,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<ReduxFormValues, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: 'dispatchBoardJobEditorForm',
    onSubmitFail: focusFirstInvalidField,
  })(DispatchBoardJobEditorForm),
);
