import React from 'react';
import { Table } from 'src/core/components';
import { ModalTitle } from 'src/core/components/styled/Modal';
import { TableCell, TableRow } from 'src/core/components/styled/Table';
import translate from 'src/core/services/translate';

export interface RouteRescheduleError {
  routeId: number;
  routeName: string;
  errorCode: string;
  errorMessage: string;
  newRouteDate?: string;
}

const RouteReschedulesErrorsTableRow: React.FC<RouteRescheduleError> = ({
  newRouteDate,
  routeId,
  routeName,
  errorCode,
}) => (
  <TableRow key={routeId}>
    <TableCell width="50%">{routeName}</TableCell>
    <TableCell width="50%">
      {translate(`routes.rescheduleErrors.${errorCode}`)} {newRouteDate && `: ${newRouteDate}`}
    </TableCell>
  </TableRow>
);

const cells = [
  { name: 'route', label: translate('common.route'), width: '50%' },
  { name: 'reason', label: translate('common.reason'), width: '50%' },
];

export const RouteReschedulesErrorsModalContent: React.FC<{ errors: RouteRescheduleError[] }> = ({ errors }) => (
  <>
    <ModalTitle noTransform>{translate('routes.rescheduleErrors.modalTitle')}</ModalTitle>
    <Table cells={cells} rows={errors} rowComponent={RouteReschedulesErrorsTableRow} />
  </>
);
