import { Grid, GridColumn } from 'src/core/components/styled';
import {
  loadDrivingIncidents,
  loadRouteAssistData,
  loadSnowSweeperRouteAssistData,
  resetDrivingIncidents,
  resetRouteAssistData,
} from 'src/routes/ducks';
import {
  RouteDetail,
  RouteDetailLabel,
  RouteDetailsWrapper,
  RouteDetailValue,
} from 'src/routes/components/styled/RouteDetails';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, WASTE_AUDIT_ID } from 'src/fleet/constants';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';
import DrivingIncidentsSection from '../routeModalSections/DrivingIncidentsSection';
import RouteAssistSection from '../routeModalSections/RouteAssistSection';
import RouteModalSection from '../routeModalSections/RouteModalSection';
import translate from 'src/core/services/translate';

export default function FleetTab() {
  const isSupervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { fleetDetails } = useSelector(state => state.routes.fleetDetails);
  const { isLoading: isLoadingDrivingIncidents } = useSelector(state => state.routes.drivingIncidents);
  const { isLoading: isLoadingRouteAssist } = useSelector(state => state.routes.routeAssist);

  if (!fleetDetails || !routeSummary) return null;

  const isSupervisorHidden =
    !isSupervisorExperienceEnabled ||
    [SNOW_PLOW_ID, WASTE_AUDIT_ID, STREET_SWEEPER_ID].includes(routeSummary.vehicleTypeId);

  const isSnowPlowRoute = routeSummary.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  return (
    <>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.vehicleName')}</RouteDetailLabel>
          <RouteDetailValue>{fleetDetails.vehicleName || '-'}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('common.driver')}</RouteDetailLabel>
          <RouteDetailValue>{fleetDetails.driverName || '-'}</RouteDetailValue>
        </RouteDetail>
        {!isSupervisorHidden && (
          <RouteDetail>
            <RouteDetailLabel>{translate('routes.supervisor')}</RouteDetailLabel>
            <RouteDetailValue>{fleetDetails.supervisorName || '-'}</RouteDetailValue>
          </RouteDetail>
        )}
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.assistingVehicles')}</RouteDetailLabel>
          <RouteDetailValue>{fleetDetails.activeAssistingVehicles}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.drivingIncidents')}</RouteDetailLabel>
          <RouteDetailValue>{fleetDetails.drivingIncidentsCount}</RouteDetailValue>
        </RouteDetail>
      </RouteDetailsWrapper>
      <Grid multiLine>
        <GridColumn size="12/12">
          <RouteModalSection
            isLoading={isLoadingDrivingIncidents}
            title={translate('routes.drivingIncidents')}
            content={<DrivingIncidentsSection />}
            loadDataFunction={loadDrivingIncidents}
            resetDataAction={resetDrivingIncidents}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <RouteModalSection
            isLoading={isLoadingRouteAssist}
            title={translate('routeAssist.routeAssist')}
            content={<RouteAssistSection />}
            loadDataFunction={isSnowOrSweeperRoute ? loadSnowSweeperRouteAssistData : loadRouteAssistData}
            resetDataAction={resetRouteAssistData}
          />
        </GridColumn>
      </Grid>
    </>
  );
}
