import ReactDOM from 'react-dom';
import InformationPopup from '../components/InformationPopup';
import history from './history';

const informationPrompt = (title: string, message?: string, cantReject?: boolean) => {
  const wrapper = document.body.appendChild(document.createElement('div'));

  const unregisterHistoryChangeEventHandler = history.listen(dispose);

  function dispose() {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(wrapper);
      unregisterHistoryChangeEventHandler();
      setTimeout(() => document.body.removeChild(wrapper));
    });
  }

  const promise = new Promise((resolve, reject) => {
    try {
      ReactDOM.render(
        <InformationPopup title={title} message={message} resolve={resolve} reject={reject} cantReject={cantReject} />,
        wrapper,
      );
    } catch (e) {
      throw e;
    }
  });

  return promise.then(
    () => {
      dispose();
      return true;
    },
    () => {
      dispose();
      return false;
    },
  );
};

export default informationPrompt;
