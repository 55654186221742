import React from 'react';
import { Field } from 'redux-form';

import { DistanceMeasurementUnitDropdown } from '../../../common/components';
import { Input } from '../../../core/components';
import { Container, TableCell, TableRow } from '../../../core/components/styled';
import { isRequired } from '../../../utils/services/validator';

interface Props {
  id: number;
  vehicleTypeName: string;
  customerTypeName: string;
  locationTypeName: string;
  vendorId?: number;
}

const ProximitySettingsFormTableRow: React.FC<Props> = ({
  id,
  vehicleTypeName,
  customerTypeName,
  locationTypeName,
}) => (
  <TableRow>
    <TableCell width="20%">{vehicleTypeName}</TableCell>
    <TableCell width="20%">{customerTypeName}</TableCell>
    <TableCell width="20%">{locationTypeName}</TableCell>
    <TableCell width="20%">
      <Field
        name={`proximitySettings.settings${id}.distance`}
        component={Input}
        type="number"
        validate={[isRequired]}
        margin="no"
        disabled
      />
    </TableCell>
    <TableCell width="20%">
      <Container>
        <Field
          name={`proximitySettings.settings${id}.measurementType`}
          component={DistanceMeasurementUnitDropdown}
          dropdownProps={{
            margin: 'no',
            disabled: true,
          }}
          validate={[isRequired]}
        />
      </Container>
    </TableCell>
  </TableRow>
);

export default ProximitySettingsFormTableRow;
