import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { find, upperFirst } from 'lodash-es';
import { camelize } from 'humps';

import { UnconnectedDropdown } from 'src/core/components';
import {
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { LOCATION_CUSTOMER_TYPE_ID } from 'src/common/constants';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { ContainerLocationForMap, ContainerNewSimplified } from 'src/fleet/interfaces/containers';
import { resetContainersForMapLocation } from 'src/fleet/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Grid, GridColumn } from 'src/core/components/styled';
import { DropdownOption } from 'src/core/components/Dropdown';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

type Props = {
  containerLocation: ContainerLocationForMap;
};

export default function ContainerPopup({ containerLocation }: Props) {
  const dispatch = useDispatch();
  const equipmentSizes = useSelector(state => state.common.equipmentSizes.equipmentSizes);
  const containerStatuses = useSelector(state => state.common.containerStatuses.containerStatuses);
  const equipmentConditions = useSelector(state => state.common.equipmentConditions.equipmentConditions);
  const equipmentTypes = useSelector(state => state.common.equipmentTypes.equipmentTypes);
  const locationTypes = useSelector(state => state.common.containerLocationTypes.containerLocationTypes);
  const containersForMapLocation = useSelector(state => state.fleet.containersForMapLocation.containersForMapLocation);

  const [selectedContainer, setSelectedContainer] = useState<ContainerNewSimplified | undefined>();

  const containerOptionsDropdown: DropdownOption[] = [];

  useEffect(
    () => () => {
      dispatch(resetContainersForMapLocation());
    },
    [dispatch],
  );

  useEffect(() => {
    setSelectedContainer(find(containersForMapLocation, { id: containerLocation.con[0]?.id }));
  }, [containersForMapLocation, containerLocation]);

  if (containerLocation.con.length === 0 || !containersForMapLocation || !containersForMapLocation.length) {
    return null;
  }

  if (containerLocation.con.length > 1) {
    containerLocation.con.forEach(container => {
      const currentContainer = find(containersForMapLocation, { id: container.id });
      currentContainer &&
        containerOptionsDropdown.push({
          label: currentContainer.nr,
          value: currentContainer.id,
        });
    });
  }

  const containerStatus = find(containerStatuses, { id: selectedContainer?.st });
  const containerSize = find(equipmentSizes, { id: selectedContainer?.s }) as TechnicalType;
  const size = getEquipmentSize(containerSize?.technicalName, containerSize?.name);
  const containerCondition = find(equipmentConditions, { id: selectedContainer?.c });

  const containerTypeTranslated = translate(
    `common.equipmentTypes.${camelize(find(equipmentTypes, { id: selectedContainer?.t })?.technicalName || '')}`,
  );

  const sizeTranslated = translate(
    `common.equipmentSizes.x${upperFirst(
      camelize(containerSize?.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
    )}`,
    {
      size: size ? getEquipmentSize(containerSize?.technicalName, containerSize?.name) : 0,
    },
  );

  const handleContainerOnLocationChange = (containerId: number) => {
    const container = find(containersForMapLocation, { id: containerId });
    setSelectedContainer(container);
  };

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={containerLocation.la}
      longitude={containerLocation.lo}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle
          title={
            containerOptionsDropdown.length < 1 ? `${translate('containers.container')} ${selectedContainer?.nr} ` : ' '
          }
          beforeTitleContent={
            containerOptionsDropdown.length > 1 && (
              <Grid margin="no no small">
                <GridColumn size="4/12" verticalAlign="center">
                  {translate('containers.container')}
                </GridColumn>
                <GridColumn size="8/12">
                  <UnconnectedDropdown
                    options={containerOptionsDropdown}
                    value={selectedContainer?.id}
                    onChange={handleContainerOnLocationChange}
                    margin="no"
                  />
                </GridColumn>
              </Grid>
            )
          }
        />
        <MapGLPopupContent>
          <Grid multiLine padding="small no">
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.equipmentType')}
                value={containerTypeTranslated}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.equipmentSize')}
                value={sizeTranslated}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.equipmentCondition')}
                value={containerCondition?.name}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.status')}
                value={containerStatus?.name}
              />
            </GridColumn>
            <GridColumn size="6/12" margin="xxSmall no">
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.locationType')}
                value={find(locationTypes, { id: selectedContainer?.lt })?.name}
              />
            </GridColumn>
            {selectedContainer?.cn && (
              <GridColumn size="6/12" margin="xxSmall no">
                <LabeledDataView
                  noWrap
                  width="calc(100% - 10px)"
                  size="small"
                  label={
                    selectedContainer?.lt === LOCATION_CUSTOMER_TYPE_ID
                      ? translate('common.customer')
                      : translate('facilities.facilityName')
                  }
                  value={selectedContainer?.cn}
                />
              </GridColumn>
            )}
            <GridColumn size="12/12" margin="xxSmall no">
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('common.location')}
                value={selectedContainer?.a}
              />
            </GridColumn>
          </Grid>
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
