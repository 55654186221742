import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { map } from 'lodash-es';
import { Dropdown } from '../../core/components';
import { DISTANCE_MEASUREMENT_UNITS } from '../constants';
import translate from '../../core/services/translate';
import { DropDownFieldProps } from './DropDownFieldProps';

class DistanceMeasurementUnitDropdown extends PureComponent<DropDownFieldProps> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;

    const distanceMeasurementUnitOptions = map(DISTANCE_MEASUREMENT_UNITS, ({ id, name }) => ({
      label: name,
      value: id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.unit') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.unit') : undefined)}
        component={Dropdown as any}
        options={distanceMeasurementUnitOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

export default DistanceMeasurementUnitDropdown;
