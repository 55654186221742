import update from 'immutability-helper';
import { filter, findIndex, get, identity, size } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  deleteFleetInsight as doDeleteFleetInsight,
  loadFleetInsights as doLoadFleetInsights,
} from '../services/fleetInsights';

// Actions
const START_LOAD = 'dashboard/fleetInsights/START_LOAD';
export const COMPLETE_LOAD = 'dashboard/fleetInsights/COMPLETE_LOAD';
const FAIL_LOAD = 'dashboard/fleetInsights/FAIL_LOAD';
const START_DELETE = 'dashboard/fleetInsights/START_DELETE';
export const COMPLETE_DELETE = 'dashboard/fleetInsights/COMPLETE_DELETE';
export const TOGGLE_FLEET_INSIGHTS = 'dashboard/fleetInsights/TOGGLE';
const FAIL_DELETE = 'dashboard/fleetInsights/FAIL_DELETE';
const RESET = 'dashboard/fleetInsights/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isDeleting: false,
  fleetInsights: undefined,
  total: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(
        state,
        action.append
          ? ({
              $merge: { isLoading: false },
              fleetInsights: { $push: action.fleetInsights },
            } as any)
          : { $merge: { isLoading: false, fleetInsights: action.fleetInsights, total: action.total } },
      );

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          fleetInsights: undefined,
          total: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const index = findIndex(state.fleetInsights, { insightId: action.insightId });
      return update(state, {
        $merge: { isDeleting: false },
        fleetInsights: { [index]: { isDismissed: { $set: true } } },
      } as any);
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case TOGGLE_FLEET_INSIGHTS:
      return update(state, {
        $merge: {
          isExpanded: action.isExpanded,
        },
      } as any);

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          isDeleting: false,
          fleetInsights: undefined,
          isExpanded: true,
          total: undefined,
        },
      } as any);

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (fleetInsights: any, total: any, append: any) => ({
  type: COMPLETE_LOAD,
  fleetInsights,
  total,
  append,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startRemove = () => ({
  type: START_DELETE,
});

const completeRemove = (insightId: number) => ({
  type: COMPLETE_DELETE,
  insightId,
});

const failRemove = () => ({
  type: FAIL_DELETE,
});

export const toggleFleetInsightsContainer = (isExpanded: boolean) => ({
  type: TOGGLE_FLEET_INSIGHTS,
  isExpanded,
});

export const loadFleetInsights =
  (
    vendorId: number,
    date: Date | string,
    limit: number,
    fromInsightId?: number,
    vehicleTypeIdsCSV?: number[],
    serviceZoneIdsCSV?: number[],
    supervisorIdsCSV?: number[],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadFleetInsights(
      vendorId,
      date,
      limit,
      fromInsightId,
      vehicleTypeIdsCSV,
      serviceZoneIdsCSV,
      supervisorIdsCSV,
    )
      .then(({ fleetInsights, total }) => dispatch(completeLoad(fleetInsights, total, !!fromInsightId)))
      .catch(() => dispatch(failLoad()));
  };

export const removeFleetInsight = (insightId: number) => (dispatch: Dispatch) => {
  dispatch(startRemove());
  return doDeleteFleetInsight(insightId)
    .then(() => dispatch(completeRemove(insightId)))
    .catch(() => dispatch(failRemove()));
};

export const resetFleetInsights = () => ({
  type: RESET,
});

// Selectors
const getFleetInsights = (fleetInsightsState: any) => get(fleetInsightsState, 'fleetInsights') || [];
export const fleetInsightsSelector = createSelector(getFleetInsights, identity);

const getFleetInsightCount = (fleetInsightState: any) => {
  const fleetInsightCount = fleetInsightState.total || 0;
  const dismissedFleetInsightCount = size(filter(fleetInsightState.fleetInsights, { isDismissed: true }));
  return fleetInsightCount - dismissedFleetInsightCount;
};

export const fleetInsightCountSelector = createSelector(getFleetInsightCount, identity);
