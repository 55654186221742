import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { loadWasteRecorder as doLoadWasteRecorder } from '../services/routeTracker';

// Actions
const START_LOAD = 'routes/wasteRecorder/START_LOAD';
const COMPLETE_LOAD = 'routes/wasteRecorder/COMPLETE_SAVE';
const FAIL_LOAD = 'routes/wasteRecorder/FAIL_SAVE';
const RESET = 'routes/wasteRecorder/RESET';

interface State {
  isLoading: boolean;
  wasteRecorder: any;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  wasteRecorder: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteRecorder: action.wasteRecorder,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteRecorder: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (wasteRecorder: any) => ({
  type: COMPLETE_LOAD,
  wasteRecorder,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadWasteRecorder = (routeId: number, routeLocationId: number) => async (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadWasteRecorderPromise = doLoadWasteRecorder(routeId, routeLocationId);
  await loadWasteRecorderPromise.then(route => dispatch(completeLoad(route))).catch(() => dispatch(failLoad()));
  return loadWasteRecorderPromise;
};

export const resetWasteRecorder = () => ({
  type: RESET,
});
