import React, { ComponentType, ComponentProps, ChangeEvent } from 'react';
import { BaseFieldProps, WrappedFieldProps, Field } from 'redux-form';

import { RequiredPropertiesOf } from '../../common/interfaces/RequiredPropertiesOf';
import { IfEquals } from '../../common/interfaces/IfEquals';

export type TypedFieldOnChangeFunction = (
  event: ChangeEvent<any>,
  newValue: any,
  previousValue: any,
  fieldName: string,
) => void;

interface PropsWithOptionalProps<
  S extends ComponentType<P>,
  P = any,
  O = Omit<ComponentProps<S>, keyof WrappedFieldProps>
> extends Omit<BaseFieldProps<P>, 'component' | 'props' | 'onChange'> {
  component: S;
  props?: O;
  onChange?: TypedFieldOnChangeFunction;
  onSwitch?: () => void;
}

interface PropsWithRequiredProps<
  S extends ComponentType<P>,
  P = any,
  O = Omit<ComponentProps<S>, keyof WrappedFieldProps>
> extends Omit<BaseFieldProps<P>, 'component' | 'props' | 'onChange'> {
  component: S;
  props: O;
  onChange?: TypedFieldOnChangeFunction;
  onSwitch?: () => void;
}

const TypedField = <
  S extends ComponentType<P>,
  P = any,
  O = Omit<ComponentProps<S>, keyof WrappedFieldProps>,
  R = RequiredPropertiesOf<O>
>({
  onChange,
  ...props
}: IfEquals<R, never, PropsWithOptionalProps<S, P>, PropsWithRequiredProps<S, P>>) => (
  <Field {...props} onChange={onChange as any} />
);

export default TypedField;
