import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import humps from 'humps';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import { INSIGHT_ICONS, GOOGLE as google } from '../constants';
import { time } from '../../utils/services/formatter';
import {
  MapInfoWindow,
  MapInfoWindowIcon,
  MapInfoWindowDetails,
  MapInfoWindowTitle,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
} from '../../core/components/styled';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

interface Props {
  vehicleInsight: any;
  isInfoWindowOpen?: boolean;
  toggleInfoWindow: (key: string) => void;
  clusterer: Clusterer;
}

class VehicleInsightMarker extends PureComponent<Props> {
  toggleInfoWindow = () => {
    const {
      toggleInfoWindow,
      vehicleInsight: { insightId },
    } = this.props;

    toggleInfoWindow(`vehicleInsights.${insightId}`);
  };

  render() {
    const { vehicleInsight, isInfoWindowOpen, clusterer } = this.props;

    const markerPosition = {
      lat: parseFloat(vehicleInsight.latitude),
      lng: parseFloat(vehicleInsight.longitude),
    };
    const markerIconType = humps.camelize(vehicleInsight.markerIconType || vehicleInsight.insightSubTypes[0].name);
    const markerIcon = {
      url: INSIGHT_ICONS[markerIconType],
      size: new google.maps.Size(15, 15),
      scaledSize: new google.maps.Size(15, 15),
    };
    const pixelOffset = new google.maps.Size(0, -20);

    const translationKeys = {
      insightTypeName: createTranslationKey(vehicleInsight.insightSubTypes[0].name, 'dashboard'),
      vehicleTypeName: createTranslationKey(vehicleInsight.vehicleTypeName, 'vehicles.vehicleTypes'),
      reportDateTimeLabel: createTranslationKey(vehicleInsight.reportDateTimeLabel, 'dashboard'),
      insightMessageLabel: createTranslationKey(vehicleInsight.insightMessageLabel, 'dashboard'),
      insightFormattedLabel: createTranslationKey(vehicleInsight.insightSubTypes[0].insightFormattedLabel, 'dashboard'),
      insightFormattedTotalLabel: createTranslationKey(
        vehicleInsight.insightSubTypes[0].insightFormattedTotalLabel,
        'dashboard',
      ),
    };

    return vehicleInsight.hideOnMap && vehicleInsight.hideOnMap.get() ? null : (
      <Marker
        key={vehicleInsight.insightId}
        icon={markerIcon}
        position={markerPosition}
        onClick={this.toggleInfoWindow}
        clusterer={clusterer}
      >
        {isInfoWindowOpen && markerPosition && (
          <InfoWindow onCloseClick={this.toggleInfoWindow} position={markerPosition} options={{ pixelOffset }}>
            <MapInfoWindow>
              <MapInfoWindowIcon size="small" src={INSIGHT_ICONS[markerIconType]} />
              <MapInfoWindowDetails>
                <MapInfoWindowTitle>{translate(`${translationKeys.insightTypeName}`)}</MapInfoWindowTitle>

                {!!vehicleInsight.vehicle.name && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>{translate('common.truck')}:</MapInfoWindowDetailLabel>
                    {vehicleInsight.vehicle.name} - {translate(`${translationKeys.vehicleTypeName}`)}
                  </MapInfoWindowDetail>
                )}

                {!!vehicleInsight.reportDateTime && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>
                      {translate(`${translationKeys.reportDateTimeLabel}`)}:
                    </MapInfoWindowDetailLabel>
                    {time(vehicleInsight.reportDateTime)}
                  </MapInfoWindowDetail>
                )}

                {!!vehicleInsight.insightMessageValue && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>
                      {translate(`${translationKeys.insightMessageLabel}`)}:
                    </MapInfoWindowDetailLabel>
                    {vehicleInsight.messageFormatUi
                      ? translate(`dashboard.${vehicleInsight.messageFormatUi}`, {
                          insightValue: vehicleInsight.value1Formatted,
                          insightSecondaryValue: vehicleInsight.value2Formatted,
                        })
                      : vehicleInsight.insightMessageValue}
                  </MapInfoWindowDetail>
                )}

                {!!vehicleInsight.insightSubTypes[0].insightFormattedValue && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>
                      {translate(`${translationKeys.insightFormattedLabel}`)}:
                    </MapInfoWindowDetailLabel>
                    {vehicleInsight.insightSubTypes[0].insightFormattedValue}
                  </MapInfoWindowDetail>
                )}

                {!!vehicleInsight.insightSubTypes[0].insightFormattedTotalValue && (
                  <MapInfoWindowDetail>
                    <MapInfoWindowDetailLabel>
                      {translate(`${translationKeys.insightFormattedTotalLabel}`)}:
                    </MapInfoWindowDetailLabel>
                    {vehicleInsight.insightSubTypes[0].insightFormattedTotalValue}
                  </MapInfoWindowDetail>
                )}
              </MapInfoWindowDetails>
            </MapInfoWindow>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default observer(VehicleInsightMarker);
