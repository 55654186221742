import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import UnconnectedDropdown from './UnconnectedDropdown';
import { FormLabel } from './styled';
import { AppState } from '../../store';
import translate from '../services/translate';

const ViewCameraContainer = styled.div`
  margin-right: 20px;

  & > label {
    margin-right: 10px;
  }

  & > div {
    width: 200px;
    margin: 0;
    display: inline-block;
  }
`;

interface Props {
  vehicleCameraConfigurations: any[];
  handleChange: (value: any) => void;
}

const VehicleCameraViewDropdownComponent: React.SFC<Props> = props => {
  const { vehicleCameraConfigurations, handleChange } = props;
  const [value, setValue] = useState(1);
  const options = useMemo(
    () => [
      { value: 1, label: translate('common.all') },
      ...vehicleCameraConfigurations.map(vcc => ({ value: vcc.cameraSerialNumber, label: vcc.displayName })),
    ],
    [vehicleCameraConfigurations],
  );

  useEffect(() => {
    if (value) {
      const cameraValue = value === 1 ? null : value;
      handleChange(cameraValue);
    }
  }, [value, handleChange]);

  return (
    <ViewCameraContainer>
      <FormLabel noTransform>{translate('vehicles.cameraConfigurations.view')}:</FormLabel>
      <UnconnectedDropdown onChange={setValue} value={value} options={options} />
    </ViewCameraContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  vehicleCameraConfigurations: state.fleet.vehicle.vehicle.vehicleCameraConfigurations,
});

export default connect(mapStateToProps)(VehicleCameraViewDropdownComponent);
