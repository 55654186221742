import { useState } from 'react';
import { IconButtonIcon, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import styled from 'styled-components';

interface DrawingInstructionsContainerProps {
  isOpen?: boolean;
}

const DrawingInstructionsContainer = styled.div<DrawingInstructionsContainerProps>`
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 10px;
  background-color: rgb(255, 255, 255, 0.5);
  font-size: 12px;
  border-radius: 7px;
  cursor: ${props => (props.isOpen ? 'default' : 'pointer')} !important;

  & > div:first-child {
    cursor: pointer !important;
  }
`;

export const DrawingInstructions = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <DrawingInstructionsContainer
      isOpen={isOpen}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        setIsOpen(!isOpen);
      }}
    >
      {isOpen ? (
        <>
          <div onClick={() => setIsOpen(!isOpen)}>
            <IconButtonIcon icon="xMark" margin="no no xSmall" />
          </div>

          <Text block margin="xxSmall no no">
            {translate('maps.instructions.editPolygon')}
          </Text>
          <Text block margin="xxSmall no no">
            {translate('maps.instructions.clickOnPoint')}
          </Text>
          <Text block margin="xxSmall no no">
            {translate('maps.instructions.clickOnPolygon')}
          </Text>
          <Text block margin="xxSmall no no">
            {translate('maps.instructions.clickSave')}
          </Text>
        </>
      ) : (
        <IconButtonIcon icon="info" margin="no" />
      )}
    </DrawingInstructionsContainer>
  );
};

export default DrawingInstructions;
