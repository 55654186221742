import { FormErrors } from 'redux-form';
import { Dispatch } from 'redux';

const findFirstInvalidFieldName = (errors: any) => {
  if (!errors || typeof errors !== 'object') {
    return null;
  }

  const names = Object.keys(errors);
  const returnPath: string[] = [];

  baseLoop: for (let n = 0; n < names.length; n++) {
    const name = names[n];
    const value = errors[name];

    if (typeof value === 'string') {
      returnPath.push(name);
      break;
    }

    if (!(value instanceof Array)) {
      continue;
    }

    for (let arrayIndex = 0; arrayIndex < value.length; arrayIndex++) {
      const arrayName = `${name}[${arrayIndex}]`;
      const arrayValue = value[arrayIndex];

      if (arrayValue === undefined) {
        continue;
      }

      if (typeof arrayValue === 'string') {
        returnPath.push(arrayName);
        break baseLoop;
      }

      if (typeof arrayValue === 'object') {
        const deepCheck = findFirstInvalidFieldName(arrayValue);

        if (deepCheck) {
          returnPath.push(arrayName, deepCheck);
          break baseLoop;
        }
      }
    }
  }

  return returnPath.join('.');
};

const focusFirstInvalidField = (
  errors: FormErrors<FormData, string> | undefined,
  dispatch: Dispatch<any>,
  submitError: any,
  props: any,
) => {
  const fieldName = findFirstInvalidFieldName(errors);

  if (!fieldName) {
    return;
  }

  const field =
    fieldName &&
    (document.querySelector<HTMLInputElement>(`[name="${fieldName}"]`) ||
      document.querySelector<HTMLInputElement>(`[id="${fieldName}"]`));

  if (field) {
    field.focus();
  }
};

export default focusFirstInvalidField;
