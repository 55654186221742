import { http } from '../../core/services/http';

export const loadContacts = (customerId: number, locationId?: number, searchText?: string) =>
  http.get(`customers/${customerId}/contacts`, { params: { locationId, searchText } }).then(response => response.data);

export const loadContactsWithRouteLocation = (routeLocationId: number) =>
  http.get(`routelocations/${routeLocationId}/contacts`).then(response => response.data);

export const deleteContact = (contactId: number) =>
  http.delete(`contacts/${contactId}`).then(response => response.data);

export const createContact = (data: any, customerId: number) => {
  return http.post(`customers/${customerId}/contacts`, data).then(response => response.data);
};

export const updateContact = (data: any, customerId: number) =>
  http.put(`customers/${customerId}/contacts/${data.id}`, data).then(response => response.data);
