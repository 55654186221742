import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { getVehicleFiltersPreferencesIds } from 'src/common/utils/filters';
import { getQueryParams } from '../../../utils/services/queryParams';
import { getReportingDetailsFormFields } from '../../services/reportingDetailsOptions';
import { loadDrivers, loadVehicles, loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadReportingDetails, loadVehiclesByReportType, loadWasteAuditConfiguration } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import ReportingDetailsPage from './ReportingDetailsPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  filtersPreferencesIds: FiltersPreferencesIds;
  loadDrivers: DuckFunction<typeof loadDrivers>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadReportingDetails: DuckFunction<typeof loadReportingDetails>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehiclesByReportType: DuckFunction<typeof loadVehiclesByReportType>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadWasteAuditConfiguration: DuckFunction<typeof loadWasteAuditConfiguration>;
  userId: string;
  vendorId: number;
}

class ReportingDetailsPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      filtersPreferencesIds,
      loadDrivers,
      loadFiltersSettings,
      loadReportingDetails,
      loadVehicles,
      loadVehiclesByReportType,
      loadVehicleTypesForVendor,
      loadWasteAuditConfiguration,
      location: { search },
      userId,
      vendorId,
    } = this.props;

    const {
      startDate,
      endDate,
      reportType,
      vehicleTypeId,
      vehicleId,
      searchString,
      sortedBy,
      sortOrder,
      wasteAuditType,
      obstacleType,
      materialContaminationType,
      routeId,
    } = getQueryParams(search);

    await loadFiltersSettings(vendorId, userId);

    const dependencies: Promise<any>[] = [
      loadReportingDetails(
        vendorId,
        startDate,
        endDate,
        vehicleTypeId,
        vehicleId,
        searchString,
        wasteAuditType,
        reportType,
        sortedBy,
        sortOrder,
        obstacleType,
        materialContaminationType,
        routeId,
        filtersPreferencesIds,
      ),
    ];

    const fields = getReportingDetailsFormFields(reportType);

    if (fields && fields.vehicleTypeId) {
      dependencies.push(loadVehicleTypesForVendor(vendorId));
    }

    if (fields && fields.driverName) {
      dependencies.push(loadDrivers(vendorId));
    }

    if (fields && fields.vehicleId) {
      dependencies.push(loadVehicles(vendorId));
      dependencies.push(loadVehiclesByReportType(vendorId, startDate, endDate, reportType, filtersPreferencesIds));
    }

    if (fields && fields.exceptions) {
      dependencies.push(loadWasteAuditConfiguration(vendorId));
    }

    return Promise.all(dependencies);
  };

  render() {
    const { reportType } = getQueryParams(this.props.location.search);

    return (
      <Fragment>
        <DocumentTitle>{translate(createTranslationKey(reportType, 'insights.reportTypes'))}</DocumentTitle>
        <Resolver
          successComponent={ReportingDetailsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filtersPreferencesIds: {
    serviceZoneIdsCSV: '',
    supervisorIdsCSV: '',
    vehicleTypeIdsCSV: getVehicleFiltersPreferencesIds(state.common.filters.filters).join(','),
  },
  userId: currentUserIdSelector(state.account.login) as any,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadDrivers,
  loadFiltersSettings,
  loadReportingDetails,
  loadVehicles,
  loadVehiclesByReportType,
  loadVehicleTypesForVendor,
  loadWasteAuditConfiguration,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingDetailsPageResolver));
