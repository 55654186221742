import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { doLoadLightConditionTypes, doLoadWeatherConditionTypes } from '../services/conditionTypes';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

const START_WEATHER_CONDITION_TYPE_LOAD = 'fleet/weatherConditionTypes/START_LOAD';
const COMPLETE_WEATHER_CONDITION_TYPE_LOAD = 'fleet/weatherConditionTypes/COMPLETE_LOAD';
const FAIL_WEATHER_CONDITION_TYPE_LOAD = 'fleet/weatherConditionTypes/FAIL_LOAD';

const START_LIGHT_CONDITION_TYPE_LOAD = 'fleet/weatherConditionTypes/START_LOAD';
const COMPLETE_LIGHT_CONDITION_TYPE_LOAD = 'fleet/lighConditionTypes/COMPLETE_LOAD';
const FAIL_LIGHT_CONDITION_TYPE_LOAD = 'fleet/lightConditionTypes/FAIL_LOAD';

type State = {
  isLoading: boolean;
  weatherConditionTypes: TechnicalType[];
  lightConditionTypes: TechnicalType[];
};

const initialState: State = {
  isLoading: false,
  weatherConditionTypes: [],
  lightConditionTypes: [],
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_WEATHER_CONDITION_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_WEATHER_CONDITION_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          weatherConditionTypes: action.weatherConditionTypes,
        },
      });

    case FAIL_WEATHER_CONDITION_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          lightConditionTypes: [],
        },
      });

    case START_LIGHT_CONDITION_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LIGHT_CONDITION_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          lightConditionTypes: action.lightConditionTypes,
        },
      });

    case FAIL_LIGHT_CONDITION_TYPE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          lightConditionTypes: [],
        },
      });

    default:
      return state;
  }
};
const startWeatherConditionTypesLoad = () => ({
  type: START_WEATHER_CONDITION_TYPE_LOAD,
});

const completeWeatherConditionTypesLoad = (weatherConditionTypes: TechnicalType[]) => ({
  type: COMPLETE_WEATHER_CONDITION_TYPE_LOAD,
  weatherConditionTypes,
});

const failWeatherConditionTypesLoad = () => ({
  type: FAIL_WEATHER_CONDITION_TYPE_LOAD,
});

const startLightConditionTypesLoad = () => ({
  type: START_LIGHT_CONDITION_TYPE_LOAD,
});

const completeLightConditionTypesLoad = (lightConditionTypes: TechnicalType[]) => ({
  type: COMPLETE_LIGHT_CONDITION_TYPE_LOAD,
  lightConditionTypes,
});

const failLightConditionTypesLoad = () => ({
  type: FAIL_LIGHT_CONDITION_TYPE_LOAD,
});

export const loadWeatherConditionTypes = () => (dispatch: Dispatch) => {
  dispatch(startWeatherConditionTypesLoad());
  const loadWeatherConditionTypesPromise = doLoadWeatherConditionTypes();
  loadWeatherConditionTypesPromise
    .then(weatherConditionTypes => dispatch(completeWeatherConditionTypesLoad(weatherConditionTypes)))
    .catch(() => dispatch(failWeatherConditionTypesLoad()));
  return loadWeatherConditionTypesPromise;
};
export const loadLightConditionTypes = () => (dispatch: Dispatch) => {
  dispatch(startLightConditionTypesLoad());
  const loadLightConditionTypes = doLoadLightConditionTypes();
  loadLightConditionTypes
    .then(lightConditionTypes => dispatch(completeLightConditionTypesLoad(lightConditionTypes)))
    .catch(() => dispatch(failLightConditionTypesLoad()));
  return loadLightConditionTypes;
};
