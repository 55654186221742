import update from 'immutability-helper';
import { loadStreetSegmentAgingIntervals as doLoadStreetSegmentAgingIntervals } from '../services/loadStreetSegmentAgingIntervals';
import { AnyAction, Dispatch } from 'redux';
import { StreetSegmentAgingInterval } from 'src/vendors/interfaces/SnowPlowSettings';

// Actions
const START_LOAD = 'common/streetSegmentAgingIntervals/START_LOAD';
const COMPLETE_LOAD = 'common/streetSegmentAgingIntervals/COMPLETE_LOAD';
const FAIL_LOAD = 'common/streetSegmentAgingIntervals/FAIL_LOAD';
const RESET = 'common/streetSegmentAgingIntervals/RESET';

// Initial state
const initialState = {
  isLoading: false,
  streetSegmentAgingIntervals: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSegmentAgingIntervals: action.streetSegmentAgingIntervals,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          streetSegmentAgingIntervals: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (streetSegmentAgingIntervals: StreetSegmentAgingInterval[]) => ({
  type: COMPLETE_LOAD,
  streetSegmentAgingIntervals,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadStreetSegmentAgingIntervals = (timeMeasurementTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStreetSegmentAgingIntervalsPromise = doLoadStreetSegmentAgingIntervals(timeMeasurementTypeId);
  loadStreetSegmentAgingIntervalsPromise
    .then((streetSegmentAgingIntervals: StreetSegmentAgingInterval[]) =>
      dispatch(completeLoad(streetSegmentAgingIntervals)),
    )
    .catch(() => dispatch(failLoad()));
  return loadStreetSegmentAgingIntervalsPromise;
};

export const resetStreetSegmentAgingInterval = () => ({
  type: RESET,
});
