import { combineReducers } from 'redux';

import { reducer as routePlannerCalendarData } from './routePlannerCalendarData';
import { reducer as routePlannerRoutes } from './routePlannerRoutes';
import { reducer as routePlannerRouteDrivers } from './routePlannerRouteDrivers';
import { reducer as routePlannerRouteVehicles } from './routePlannerRouteVehicles';
import { reducer as routePlannerRouteTemplates } from './routePlannerRouteTemplates';
import { reducer as routePlannerRouteTemplateDrivers } from './routePlannerRouteTemplateDrivers';
import { reducer as routePlannerRouteTemplateVehicles } from './routePlannerRouteTemplateVehicles';
import { reducer as routePlannerTableData } from './routePlannerTableData';
import { reducer as routePlannerVehicleStatus } from './routePlannerVehicleStatus';
import { reducer as routePlannerDriverStatus } from './routePlannerDriverStatus';
import { reducer as routePlannerMetricsSettings } from './routePlannerMetricsSettings';

export { loadRoutePlannerCalendarData, resetRoutePlannerCalendarData } from './routePlannerCalendarData';
export { loadRoutePlannerTableData, resetRoutePlannerTableData } from './routePlannerTableData';

export {
  loadRoutePlannerRoutes,
  resetRoutePlannerRoutes,
  assignDriverToRoute,
  removeDriverFromRoute,
  assignVehicleToRoute,
  removeVehicleFromRoute,
  bulkDeleteRoutes,
} from './routePlannerRoutes';
export {
  loadRoutePlannerRouteDrivers,
  loadRoutePlannerRouteDriversForAvailability,
  resetRoutePlannerRouteDrivers,
} from './routePlannerRouteDrivers';
export {
  loadRoutePlannerRouteVehicles,
  loadRoutePlannerRouteVehiclesForAvailability,
  resetRoutePlannerRouteVehicles,
} from './routePlannerRouteVehicles';
export {
  loadRoutePlannerVehicleStatus,
  resetRoutePlannerVehicleStatus,
  updateRoutePlannerVehiclesStatus,
} from './routePlannerVehicleStatus';
export {
  loadRoutePlannerDriverStatus,
  resetRoutePlannerDriverStatus,
  updateRoutePlannerDriversStatus,
} from './routePlannerDriverStatus';

export {
  loadRoutePlannerRouteTemplates,
  resetRoutePlannerRouteTemplates,
  assignDriverToRouteTemplate,
  removeDriverFromRouteTemplate,
  assignVehicleToRouteTemplate,
  removeVehicleFromRouteTemplate,
  bulkDeleteRouteTemplates,
} from './routePlannerRouteTemplates';
export {
  loadRoutePlannerRouteTemplateVehicles,
  resetRoutePlannerRouteTemplateVehicles,
} from './routePlannerRouteTemplateVehicles';
export {
  loadRoutePlannerRouteTemplateDrivers,
  resetRoutePlannerRouteTemplateDrivers,
} from './routePlannerRouteTemplateDrivers';

export {
  loadRoutePlannerRouteMetricsSettings,
  loadRoutePlannerRouteTemplateMetricsSettings,
  saveRoutePlannerRouteMetricsSettings,
  saveRoutePlannerRouteTemplateMetricsSettings,
  resetRoutePlannerRouteMetricsSettings,
  resetRoutePlannerRouteTemplateMetricsSettings,
  reorderRouteMetricsSettings,
  reorderRouteTemplateMetricsSettings,
} from './routePlannerMetricsSettings';

export const reducer = combineReducers({
  routePlannerCalendarData,
  routePlannerTableData,
  routePlannerRoutes,
  routePlannerRouteDrivers,
  routePlannerRouteVehicles,
  routePlannerRouteTemplates,
  routePlannerRouteTemplateDrivers,
  routePlannerRouteTemplateVehicles,
  routePlannerVehicleStatus,
  routePlannerDriverStatus,
  routePlannerMetricsSettings,
});
