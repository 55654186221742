import { loading } from 'src/core/styles';
import styled from 'styled-components';

export const DirtyPickupLocationsDictionaryWrapper = styled.div`
  height: 70vh;
  position: relative;

  *:focus {
    outline: none;
  }
`;

export const ActiveLocationIndexesInfo = styled.div`
  background-color: ${p => p.theme.brandPrimary};
  color: white;
  left: calc(50% - 150px);
  padding: 10px;
  position: absolute;
  top: 10px;
  width: 300px;
  z-index: 1;
  display: flex;
  justify-content: center;

  div {
    margin-left: auto;
  }

  svg {
    width: 15px;
    cursor: pointer;
  }
`;

export const CollectionPointsLoadingContainer = styled.div`
  background-color: ${p => p.theme.brandPrimary};
  color: white;
  right: 20px;
  padding: 10px;
  position: absolute;
  top: 20px;
  z-index: 1;
  display: flex;
  justify-content: center;

  ${loading('24px')};
`;
