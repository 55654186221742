import { IObservableValue } from 'mobx';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import { ServiceContractBinDetail } from '../../interfaces/RouteLocation';

export interface Coordinates {
  lat: number;
  long: number;
  latitude: number;
  longitude: number;
}

export interface VehiclePosition {
  completedStops: number;
  coordinates: Coordinates;
  driverId: number;
  driverName: string;
  eventDateTime: string;
  isActive: boolean;
  isStationary: boolean;
  latitude: number;
  longitude: number;
  stationaryTime: number;
  totalStops: number;
  vehicle: any;
  vehicleId?: number;
  vehicleStatus: number;
  vehicleSubType: string;
  vehicleSubTypeName: string;
  vehicleType: any;
}

export interface GroupedVehicleTrackings {
  applicationModeStatusId: number;
  coordinates: Coordinates[];
  endTimestamp: string;
  hideOnMap: IObservableValue<boolean>;
  speed: number;
  startTimestamp: string;
  deviceId?: number; //TODO: RVP-8353 this might change
  timestampMs: number;
}

export interface ApplicationModeChange {
  applicationModeStatusId: number;
  coordinates: Coordinates;
  hideOnMap: IObservableValue<boolean>;
  speed: number;
  timestamp: string;
  timestampMs: number;
}

export interface TimelineTracking {
  isCloned?: boolean;
  timestamp: number;
  coordinates: Coordinates;
  speed: number;
}

export interface ContainerInsightSubType {
  displayName: string;
  id: number;
  imageBlobId: string;
  imageUrl: string;
  rawImageUrl?: string;
  images?: Array<{
    imageBlobId: string;
    imageUrl: string;
    rawImageUrl?: string;
  }>;
  insightDetailsExceptionSubTypes: any;
  insightFormattedLabel: string;
  insightFormattedTotalLabel: string;
  insightFormattedTotalValue: string;
  insightFormattedValue: string;
  insightSubMessageLabel?: string;
  insightSubMessageValues?: string[];
  insightTotalValue: number;
  insightType: string;
  insightValue: number;
  name: string;
  pickupExceptionDetails: any;
}

export interface ContainerInsights {
  displayAsScheduled: IObservableValue<boolean>;
  reportTimestampMs: number;
  address: string;
  customerEmail: string;
  customerName: string;
  deviceType: string;
  driver: TechnicalType;
  imageUrl: string;
  insightId: number;
  insightMessageLabel: string;
  insightMessageType: string;
  insightMessageValue: string;
  insightSubTypes: ContainerInsightSubType[];
  isYRoute: boolean;
  latitude: number;
  locationName: string;
  longitude: number;
  messageFormatUi: string;
  pickupType: string;
  reportDateTime: string;
  reportDateTimeLabel: string;
  route: TechnicalType;
  routeDetailId: number;
  routeLocationId: number;
  sessionId: number;
  value1Formatted: string;
  value2Formatted: string;
  vehicle: TechnicalType;
  vehicleType: string;
  vehicleTypeId: string;
  vehicleTypeName: string;
}

export interface EnhancedContainerInsights extends ContainerInsights {
  serviceContractBinDetails: ServiceContractBinDetail;
  orderNumber: number;
}

export interface VehicleTracking {
  vehicle: any;
  unitOfMeasure: string;
  coordinateGroups: GroupedVehicleTrackings[];
  applicationModeChanges: ApplicationModeChange[];
}

export interface VehicleTrackingRaw {
  routeId: number;
  vehicleId: number;
  reportedDate: string;
  latitude: number;
  longitude: number;
  vehicleSpeed: number;
  bearing: number;
  distanceFromLast: number;
  applicationModeStatusId: number;
}

export interface VehicleTrackingResponse {
  vehicleId: number;
  vehicleName: string;
  vehicleTrackings: VehicleTrackingRaw[];
  trapezoids: VehicleTrackingRaw[];
}

export interface StreetServicingSideConfiguration {
  id: number;
  name: string;
  technicalName: string;
  startAt: number;
  endAt: number;
  innerLength: number;
  binLocation: number;
  binLocationReduced: number;
  measurementType: string;
}

export interface VehicleSettings {
  id: number;
  name: string;
  vehicleSubType: TechnicalType;
  vehicleType: TechnicalType;
  isFullGpsEnabled: boolean;
  isRawGpsEnabled: boolean;
  isOtherNavigationEnabled: boolean;
  isEnhancedServiceConfirmationEnabled: boolean;
  isAverageHeadingEnabled: boolean;
  isSpeedCalculationEnabled: boolean;
  speedOffsetMultiplier: number;
  confirmationModeId: number;
  streetServicingSideId: number;
  confirmationModes: [
    {
      id: number;
      name: string;
      technicalName: string;
      streetServicingSides: StreetServicingSideConfiguration[];
    },
  ];
}

export interface RouteMapProps {
  children?: any;
  vehicleTrackings: VehicleTracking[];
  vehiclePositions: VehiclePosition[];
  containerInsights: ContainerInsights[];
  unitOfMeasure: string;
}

export interface StationaryTimeMap {
  [startPosition: number]: {
    numberOfStationaryTrackings: number;
    startTimestamp: number;
    endTimestamp: number;
  };
}

export class StationaryTimeInfo {
  total: number = 0;
  map: StationaryTimeMap = {};
}

export interface TripTimeIncident {
  reportType: string;
  reportDateTime: string;
  duration: number;
  durationUnitOfMeasure: string;
  latitude: number;
  longitude: number;
}

export interface TripTimeDetails {
  driver: TechnicalType;
  routeDate: string;
  totalInTransitTime: number;
  totalServiceTime: number;
  totalStationaryTime: number;
  totalTimeAtDisposalFacility: number;
  totalTimeAtOperationalFacility: number;
  totalTransitTime: number;
  totalTripTime: number;
  tripTimeDetails: any[];
  tripTimeIncidents: TripTimeIncident[];
  vehicle: TechnicalType;
  vehicleType: string;
}
