import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { map, sum } from 'lodash-es';
import { Pie } from 'react-chartjs-2';

import { AgingIntervals, RouteProgress, SegmentsProgress } from '../../../../interfaces/Route';
import { AppState } from '../../../../../store';
import { ChartWrapper, CustomLegend, CustomLegendItem, CustomTooltip } from '../../../../../common/components/styled';
import { kFormatter } from '../../../../../utils/services/formatter';
import { LATEST_ACTIVITY, ROUTES, SEGMENTS } from '../../../../services/routesChart';
import { PIE } from '../../../../../core/constants';
import { Text } from '../../../../../core/components/styled';
import translate from '../../../../../core/services/translate';

const ChartComponents = {
  [PIE]: Pie,
};

interface Props {
  chartType: string;
  search: string;
  routesSummary: RouteProgress[] | SegmentsProgress[] | AgingIntervals[];
}

interface State {
  legend: any[];
  top?: number;
  left?: number;
  label?: string;
  value?: number;
  type?: string;
  percentage?: string;
}

class PieChartCustomLegend extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      legend: [],
    };
  }
  private chartRef: any;

  componentDidMount() {
    const legend = this.chartRef && this.chartRef.chartInstance.generateLegend();
    this.setState({ legend });
  }

  componentDidUpdate(prevProps: Props) {
    const { routesSummary } = this.props;

    if (prevProps.search !== this.props.search || prevProps.routesSummary !== routesSummary) {
      const legend = this.chartRef && this.chartRef.chartInstance.generateLegend();
      this.setState({ legend });
    }
  }

  setChartRef = (element: any) => {
    this.chartRef = element;
  };

  setPositionAndData = (top: number, left: number, label: string, value: number, type: string, percentage: string) => {
    this.setState({ top, left, label, value, type, percentage });
  };

  getPieChartOptions = () => ({
    legend: {
      display: false,
    },
    maintainAspectRatio: true,
    legendCallback: this.legendMarkup,
    tooltips: {
      displayColors: false,
      custom: (tooltipModel: any) => {
        const chart = this.chartRef;
        if (!chart) {
          return;
        }
        const chartData = chart.props.data;
        const dataSets = chartData.datasets[0];

        const total = sum(map(dataSets.piechartData, 'value'));
        const value = Number(tooltipModel.body[0].lines[0].split(': ')[1]);
        const label = tooltipModel.body[0].lines[0].split(' (')[0];
        const percentage = ((value * 100) / total).toFixed(2);
        const type = this.props.chartType;

        const position = chart.chartInstance.canvas.getBoundingClientRect();
        const left = position.left + tooltipModel.caretX + 20;
        const top = position.top + tooltipModel.caretY - 30;

        this.setPositionAndData(top, left, label, value, type, percentage);
      },
    },
  });

  legendMarkup = () => {
    const chartData = this.chartRef.props.data;
    const legendSet = chartData.datasets[0];

    const legend = legendSet.data.map((set: any, index: number) => {
      let dataPoint = chartData.labels[index];
      const { color, technicalName } = legendSet.piechartData[index];

      if (dataPoint) {
        return (
          <CustomLegendItem key={index} type={technicalName} color={!technicalName ? color : undefined}>
            <Text size="small">{dataPoint}</Text>
          </CustomLegendItem>
        );
      }
      return null;
    });

    return legend;
  };

  getPieChartData = (chartData: any[]) => {
    const data = map(chartData, 'value');
    const colors = map(chartData, 'color');

    const labelsWithValue = map(chartData, ({ label, value }) => ({
      label: `${label} (${kFormatter(value, { decimals: 0, kThreshold: 10000 })})`,
      type: label.replace(),
    }));
    const labels = map(labelsWithValue, 'label');

    return {
      labels,
      datasets: [
        {
          data,
          piechartData: chartData,
          backgroundColor: colors,
        },
      ],
    };
  };

  render() {
    const { routesSummary } = this.props;
    const { legend, left, top, label, value, percentage, type } = this.state;
    const ChartComponent = ChartComponents[PIE];

    return (
      <Fragment>
        <ChartWrapper percentage={percentage}>
          <ChartComponent
            ref={this.setChartRef}
            data={this.getPieChartData(routesSummary)}
            options={this.getPieChartOptions()}
            width={180}
            height={180}
          />
          <CustomTooltip top={top} left={left}>
            <div>{label}</div>
            <div>
              {translate(
                type === ROUTES
                  ? 'insights.numberOfRoutes'
                  : type === LATEST_ACTIVITY || type === SEGMENTS
                  ? 'insights.numberOfSegments'
                  : 'insights.numberOfStops',
              )}
              <em>{value}</em>
            </div>
            <div>
              {translate('insights.percentageOfTotal')}: <em>{percentage}%</em>
            </div>
          </CustomTooltip>
        </ChartWrapper>
        <CustomLegend>{legend}</CustomLegend>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

export default connect(mapStateToProps)(PieChartCustomLegend);
