import { DragEvent } from 'react';

import { createErrorNotification } from '../../core/services/createNotification';
import translate from '../../core/services/translate';
import { UNASSIGNED_ROUTE_ID } from '../constants/dispatchBoard';

export const dispatchBoardJobDropAction = (
  event: DragEvent,
  targetRouteId: number,
  insertJobAtIndex: number,
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: any[],
    insertJobAtIndex?: number,
    type?: string,
  ) => void,
) => {
  const { dataTransfer } = event.nativeEvent;
  const sourceRouteId = dataTransfer?.getData('routeId')
    ? Number(dataTransfer.getData('routeId'))
    : UNASSIGNED_ROUTE_ID;
  const jobsSelected = dataTransfer ? JSON.parse(dataTransfer.getData('jobsSelected')) : [];
  const type = dataTransfer?.getData('type');

  if (!jobsSelected || !jobsSelected.length) return;

  if (sourceRouteId === targetRouteId) {
    createErrorNotification(translate('dispatchBoard.cannotTransferWithinSameRoute'));
    return;
  }

  onJobDrop(sourceRouteId, targetRouteId, jobsSelected, insertJobAtIndex, type);
};
