import { ChangeEvent } from 'react';
import { InjectedFormProps } from 'redux-form';

import { Checkbox, Collapsible, SimpleInput } from 'src/core/components';
import {
  ExpandableMapFilterSection,
  ExpandableMapFilterSectionContent,
  ExpandableMapFilterSectionHeader,
  ExpandableMapFilterSectionIcon,
  ExpandableMapFilterSubSectionSearch,
  ExpandableMapFilterSubSectionSearchIcon,
} from '../styled/StreetNetwork';
import translate from 'src/core/services/translate';
import TypedField, { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { Text } from 'src/core/components/styled';

type FiltersSectionProps = {
  change: InjectedFormProps['change'];
  filters?: {
    id: number | string;
    label: string;
    rightLabel?: string;
    subFilters?: { id: string | number; label: string | number; rightLabel?: string }[];
  }[];
  hasCheckAll?: boolean;
  isOpen: boolean;
  name: string;
  onSetDriversSearchTerm?: (searchTerm: string) => void;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  noDataMessage?: string;
  onFilterChange?: (_: any, isChecked: boolean, id: string | number) => void;
};

export const ExpandableMapFiltersSection = ({
  change,
  filters,
  hasCheckAll,
  isOpen,
  name,
  onSetDriversSearchTerm,
  setIsOpen,
  title,
  noDataMessage,
  onFilterChange,
}: FiltersSectionProps) => {
  if (!filters?.length && !onSetDriversSearchTerm) return null;

  const handleCheckAll: TypedFieldOnChangeFunction = (_: any, isChecked: boolean) => {
    filters &&
      filters.forEach((filter, index) => {
        change(`${name}[${filter.id}]`, isChecked);
      });
  };

  const setDriversSearchTerm = (event: ChangeEvent<any>) => {
    onSetDriversSearchTerm && onSetDriversSearchTerm(event.target.value);
  };

  return (
    <>
      <ExpandableMapFilterSection isOpen={isOpen}>
        <ExpandableMapFilterSectionHeader onClick={() => setIsOpen(!isOpen)}>
          <ExpandableMapFilterSectionIcon /> {title}
        </ExpandableMapFilterSectionHeader>
        <ExpandableMapFilterSectionContent isVisible={isOpen}>
          <Collapsible isOpen={isOpen}>
            {hasCheckAll && (
              <TypedField
                name={`${name}_checkAll`}
                component={Checkbox}
                onChange={handleCheckAll}
                props={{
                  label: translate('common.all'),
                  block: true,
                  margin: 'xSmall no',
                  size: 'small',
                  withBottomBorder: true,
                }}
              />
            )}

            {!!filters && onSetDriversSearchTerm && (
              <ExpandableMapFilterSubSectionSearch>
                <TypedField
                  name="driversSearchTerm"
                  component={SimpleInput}
                  props={{
                    placeholder: translate('common.search'),
                    size: 'small',
                    margin: 'no',
                  }}
                  onChange={event => setDriversSearchTerm(event)}
                />
                <ExpandableMapFilterSubSectionSearchIcon />
              </ExpandableMapFilterSubSectionSearch>
            )}

            {!!filters &&
              filters.map(filter => (
                <TypedField
                  key={filter.id}
                  name={`${name}[${filter.id}]`}
                  component={filter.label ? Checkbox : () => null}
                  props={{
                    label: filter.label,
                    block: true,
                    margin: 'xSmall no',
                    size: 'small',
                    labelRight: filter.rightLabel,
                    withBottomBorder: true,
                  }}
                  onChange={(event, value) => onFilterChange && onFilterChange(event, value, filter.id)}
                />
              ))}

            {!filters?.length && (
              <Text color="grayDarker" margin="xSmall no" size="small" block>
                <em>{!!noDataMessage ? noDataMessage : translate('containers.noDataAvailable')}</em>
              </Text>
            )}
          </Collapsible>
        </ExpandableMapFilterSectionContent>
      </ExpandableMapFilterSection>
    </>
  );
};
