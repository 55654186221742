import React, { PureComponent } from 'react';

import { find, get, size } from 'lodash-es';
import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Dropdown, MultiSelectSeparator, TypedField } from 'src/core/components';
import { FieldProps } from 'src/core/components/FieldProps';
import { MultiSelectProps } from 'src/core/components/MultiSelect';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH } from '../constants';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { DropDownProps } from 'src/core/components/Dropdown';

interface OwnProps {
  multiSelectProps?: Partial<MultiSelectProps>;
  dropdownProps?: Partial<DropDownProps>;
  monthlyServicesFrequencySingle?: TechnicalType[];
  monthlyServicesFrequencyMultiple?: TechnicalType[];
  isBillingFeatureActive?: boolean;
}

type Props = OwnProps & FieldProps & WrappedFieldProps;

class MonthlyServicesMonthlyFrequencyDropdownOrMultiSelect extends PureComponent<Props> {
  onChange = (_: unknown, value: number[]) => {
    this.props.input.onChange(this.props.isBillingFeatureActive ? [value] : value);
  };

  formatText = (selectedOptions: Array<number | string>): string => {
    const { monthlyServicesFrequencySingle = [], monthlyServicesFrequencyMultiple = [] } = this.props;

    const message1 =
      size(selectedOptions) === 1
        ? get(
            find(monthlyServicesFrequencySingle, option => option.id === selectedOptions[0]),
            'technicalName',
          )
        : null;

    const message2 =
      size(selectedOptions) === 1
        ? get(
            find(monthlyServicesFrequencyMultiple, option => option.id === selectedOptions[0]),
            'technicalName',
          )
        : null;

    return size(selectedOptions) === 1
      ? translate(createTranslationKey(message1 ? message1 : message2 ? message2 : '', 'customers.dayOfMonthList'))
      : translate('customers.xDaysSelected', { selected: size(selectedOptions) });
  };

  render() {
    const {
      input: { name },
      placeholder,
      label,
      multiSelectProps,
      dropdownProps,
      // componentProps,
      monthlyServicesFrequencySingle = [],
      monthlyServicesFrequencyMultiple = [],
      isBillingFeatureActive,
    } = this.props;

    const monthlyServicesFrequencySingleOptions = monthlyServicesFrequencySingle.map(option => ({
      label: option.name,
      value: option.id,
    }));

    const monthlyServicesFrequencyMultipleOptions = monthlyServicesFrequencyMultiple.map(option => ({
      label: option.name,
      value: option.id,
    }));

    return (
      <>
        {isBillingFeatureActive ? (
          <TypedField
            name={name}
            component={Dropdown}
            onChange={this.onChange}
            props={{
              ...dropdownProps,
              label,
              options: monthlyServicesFrequencyMultipleOptions,
            }}
          />
        ) : (
          <TypedField
            name={name}
            component={MultiSelectSeparator}
            onChange={this.onChange}
            props={{
              ...multiSelectProps,
              formatText: this.formatText,
              label,
              options1: monthlyServicesFrequencySingleOptions,
              options2: monthlyServicesFrequencyMultipleOptions,
              placeholder,
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const monthlyServicesMonthlyFrequency: any[] =
    state.common.pickupFrequencyTypes.monthlyServicesMonthlyFrequency || [];

  return {
    isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
    monthlyServicesFrequencySingle: monthlyServicesMonthlyFrequency
      ? monthlyServicesMonthlyFrequency.filter(
          monthlyServiceFrequency => monthlyServiceFrequency.id === MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH,
        )
      : [],
    monthlyServicesFrequencyMultiple: monthlyServicesMonthlyFrequency
      ? monthlyServicesMonthlyFrequency.filter(
          monthlyServiceFrequency => monthlyServiceFrequency.id !== MONTHLY_SERVICES_FREQUENCY_DAY_OF_MONTH,
        )
      : [],
  };
};

export default connect(mapStateToProps)(MonthlyServicesMonthlyFrequencyDropdownOrMultiSelect);
