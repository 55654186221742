import update from 'immutability-helper';
import { find, get, identity, map } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { EXCEPTIONS } from '../constants';
import doLoadWasteAuditConfiguration from '../services/loadWasteAuditConfiguration';

// Actions
const START_LOAD = 'insights/wasteAuditConfiguration/START_LOAD';
const COMPLETE_LOAD = 'insights/wasteAuditConfiguration/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/wasteAuditConfiguration/FAIL_LOAD';
const RESET = 'insights/wasteAuditConfiguration/RESET';

// Initial state
const initialState = {
  isLoading: false,
  wasteAuditConfiguration: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteAuditConfiguration: action.wasteAuditConfiguration,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (wasteAuditConfiguration: any) => ({
  type: COMPLETE_LOAD,
  wasteAuditConfiguration,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadWasteAuditConfiguration = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadWasteAuditConfigurationPromise = doLoadWasteAuditConfiguration(vendorId);
  loadWasteAuditConfigurationPromise
    .then(wasteAuditConfiguration => dispatch(completeLoad(wasteAuditConfiguration)))
    .catch(() => dispatch(failLoad()));
  return loadWasteAuditConfigurationPromise;
};

export const resetWasteAuditConfiguration = () => ({
  type: RESET,
});

// Selectors
const getWasteAuditExceptions = ({ wasteAuditConfiguration }: any) =>
  map(
    get(find(wasteAuditConfiguration, { name: EXCEPTIONS }), 'vendorWasteAuditConfigurationTypes'),
    configurationType => ({
      label: translate(createTranslationKey(configurationType.technicalName, 'routes.wasteAuditStatuses')),
      value: configurationType.technicalName,
      isEnabled: configurationType.isEnabled,
    }),
  );

export const wasteAuditExceptionsSelector = createSelector(getWasteAuditExceptions, identity);
