import moment from 'moment';
import { omit } from 'lodash-es';

import { ASC, DESC, LIMIT_PER_PAGE, PAGE, TODAY_FORMATTED } from '../../core/constants';
import { dateFormat } from 'src/utils/services/validator';
import { DispatchBoardRouteJobLocationAddress } from '../interfaces/DispatchBoardRouteJob';
import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { http, httpInsightsReporting } from '../../core/services/http';
import {
  Route,
  RouteTrackerPayload,
  RoutesResourceAvailability,
  SnowSweeperRouteTrackerPayload,
} from '../interfaces/Route';
import { RouteLocation } from '../interfaces/RouteLocation';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import {
  transformCloneRoute,
  transformCreateRoute,
  transformLoadRoute,
  transformTransferRouteLocations,
  transformEditRoute,
} from './transformRoute';
import transformLoadRouteTracker, {
  transformLoadSnowOrSweeperRoutesTracker,
  transformSnowOrSweeperRoutesHeader,
} from './transformLoadRouteTracker';
import translate from '../../core/services/translate';

const SORTED_BY = 'routeDate';

export const loadRoutesTrackerForMessages = (
  vendorId: number,
  searchTerm?: string,
  vehicleTypeIds?: string,
  routeStatusTypeIds?: string,
  startDate: Date | string = TODAY_FORMATTED,
  endDate: Date | string = TODAY_FORMATTED,
  serviceZones?: string,
  supervisors?: string,
  useUserGlobalFilters?: boolean,
) =>
  http
    .get('messages/routes', {
      params: {
        vendorId,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        searchTerm,
        serviceZoneIds: serviceZones,
        supervisorIds: supervisors,
        useUserGlobalFilters,
      },
    })
    .then(response => response.data);

export const loadRoutesTracker = (queryParams: RouteTrackerPayload) =>
  http
    .get('routes/tracker', {
      params: {
        ...queryParams,
        startDate: queryParams.startDate || TODAY_FORMATTED,
        endDate: queryParams.endDate || TODAY_FORMATTED,
        serviceZoneIds: queryParams.serviceZones,
        supervisorIds: queryParams.supervisors,
      },
    })
    .then(response => transformLoadRouteTracker(response.data));

export const loadSnowOrSweeperRoutesTracker = (queryParams: SnowSweeperRouteTrackerPayload) =>
  http
    .get(`routes/alternativeFleet/tracker/list`, {
      params: {
        ...omit(queryParams, ['cancelToken', 'serviceZones', 'supervisors']),
        startDate: queryParams.startDate || TODAY_FORMATTED,
        endDate: queryParams.endDate || TODAY_FORMATTED,
        serviceZoneIds: queryParams.serviceZones,
        supervisorIds: queryParams.supervisors,
      },
      cancelToken: queryParams.cancelToken,
    })
    .then(response => transformLoadSnowOrSweeperRoutesTracker(response.data));

export const loadSnowOrSweeperRoutesHeader = (queryParams: SnowSweeperRouteTrackerPayload) =>
  http
    .get(`routes/alternativeFleet/tracker/header`, {
      params: {
        ...omit(queryParams, ['cancelToken', 'serviceZones', 'supervisors']),
        startDate: queryParams.startDate || TODAY_FORMATTED,
        endDate: queryParams.endDate || TODAY_FORMATTED,
        serviceZoneIds: queryParams.serviceZones,
        supervisorIds: queryParams.supervisors,
      },
      cancelToken: queryParams.cancelToken,
    })
    .then(response => transformSnowOrSweeperRoutesHeader(response.data));

export const exportRoutesTracker = (
  vendorId: number,
  searchTerm: string,
  vehicleTypeIds: string,
  routeStatusTypeIds: string,
  startDate: Date | string = TODAY_FORMATTED,
  endDate: Date | string = TODAY_FORMATTED,
  page: number = PAGE,
  sortOrder: typeof ASC | typeof DESC = DESC,
  sortedBy: string = SORTED_BY,
  limit: number = LIMIT_PER_PAGE,
  serviceZones: string,
  supervisors: string,
) =>
  downloadExcelFile('routes/exportRouteTrackerList', translate('routes.routes'), {
    vendorId,
    searchTerm,
    vehicleTypeIds,
    routeStatusTypeIds,
    startDate,
    endDate,
    page,
    limit,
    sortDirection: sortOrder,
    sortOrder: sortedBy,
    serviceZoneIds: serviceZones,
    supervisorIds: supervisors,
  });

export const exportSnowOrSweeperRoutesTracker = (
  vendorId: number,
  vehicleTypeId: number,
  startDate: Date | string = TODAY_FORMATTED,
  endDate: Date | string = TODAY_FORMATTED,
) =>
  downloadExcelFile(
    'routes/alternativeFleet/tracker/export',
    translate(vehicleTypeId === SNOW_PLOW_ID ? 'routes.snowPlowRoutes' : 'routes.streetSweeperRoutes'),
    {
      vendorId,
      vehicleTypeId,
      startDate,
      endDate,
    },
  );

export const createRoute = (route: Route) =>
  http.post('routes', transformCreateRoute(route)).then(response => response.data);

export const createSnowOrSweeperRoute = (snowOrSweeperRoute: Route) =>
  http.post('routes', transformCreateRoute(snowOrSweeperRoute)).then(response => response.data);

export const editSnowOrSweeperRoute = (snowOrSweeperRoute: Route, vendorId: number, routeId?: number) =>
  http
    .put(`${vendorId}/routes/${routeId}/routeDetail/details`, transformEditRoute(snowOrSweeperRoute))
    .then(response => response.data);

export const loadRoute = (routeId: number, includeUnscheduledStops?: boolean) =>
  http
    .get(`routes/${routeId}`, { params: { includeUnscheduledStops } })
    .then(response => transformLoadRoute(response.data));

export const rescheduleRoutes = (routes: Array<{ routeId: number; newRouteDate?: Date | string }>) =>
  http.post('routes/bulkReSchedule', routes).then(response => response.data);

export const deleteRoute = (routeId: number) => http.delete(`routes/${routeId}`);

export const deleteRoutes = (routeIds: number[]) => http.delete('routes/bulkDelete', { data: routeIds });

export const cloneRoute = (
  routeId: number,
  { routeName, routeCloneDate }: { routeName: string; routeCloneDate: Date | string },
) =>
  http
    .post(`routes/${routeId}/clone`, { routeName, date: routeCloneDate })
    .then(response => transformCloneRoute(response.data))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const transferRouteLocations = ({
  routeLocationsToTransfer,
  targetRouteId,
  routeDate,
  positionTypeId,
}: {
  routeLocationsToTransfer: RouteLocation[];
  targetRouteId: any;
  routeDate: Date | string;
  positionTypeId: number;
}) =>
  http
    .post(
      `routeLocations/reassignToRoute/${targetRouteId?.id ? targetRouteId.id : targetRouteId}`,
      transformTransferRouteLocations(routeLocationsToTransfer, routeDate, positionTypeId),
    )
    .then(response => response.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const exportRoute = (routeId: number, pickupStatusTypeId?: number, customerOrAddressContains?: string) =>
  downloadExcelFile(`routes/exportRouteDetails/${routeId}`, translate('routes.routeDetails'), {
    pickupStatusTypeId,
    customerOrAddressContains,
  });

export const routeLocationAddressChange = (routeLocationAddress: Partial<DispatchBoardRouteJobLocationAddress>) =>
  http.post(`routeLocations/${routeLocationAddress.routeLocationId}/updateBinGeoCoordinates`, routeLocationAddress);

export const loadWasteRecorder = (routeId: number, routeLocationId: number) =>
  http.get(`routes/${routeId}/wasteRecorder`, { params: { routeLocationId } }).then(response => response.data);

export const loadTripTimeDetails = (routeId: number, isYRoute?: boolean) =>
  httpInsightsReporting
    .get(`${isYRoute ? 'yroutes' : 'routes'}/${routeId}/triptimedetails`)
    .then(response => response.data);

export const loadYRoute = (routeId: number | string, loadRouteLocations?: boolean) =>
  http
    .get(`yroutes/${routeId}`, { params: { loadRouteLocations } })
    .then(response => transformLoadRoute(response.data));

export const exportYRoute = (routeDetailId: number) =>
  downloadExcelFile(`yRoutes/exportRouteDetails/${routeDetailId}`, translate('routes.yRouteDetails'));

export const loadRoutesResourceAvailability = (routesResourceAvailability: RoutesResourceAvailability[]) =>
  http.post('routes/routesResourceAvailability', routesResourceAvailability).then(response => response.data);

export const loadNextRunNumber = (routeId: number) =>
  http.get('routes/alternativeFleet/nextRunNumber', { params: { routeId } }).then(response => response.data);

export const resetRouteTracker = (routeId: number, { routeName, date }: { routeName: string; date: Date | string }) =>
  http
    .post(`routes/${routeId}/reset`, { routeName, date: moment(date).format(dateFormat) })
    .then(response => transformCloneRoute(response.data))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
