import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadFeatures } from 'src/vendors/ducks';
import {
  loadDisposalFacilities,
  loadOperationalFacilities,
  loadVehicles,
  loadVehicleTypesForVendor,
} from 'src/fleet/ducks';
import { loadGroups, loadServiceZones } from 'src/routes/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { loadFiltersSettings, loadWasteTypes } from 'src/common/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import EditRouteDetailsModal from './EditRouteDetailsModal';

type Props = {
  closeModal: () => void;
};

export default function EditRouteDetailsModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(state => state.account.login.user.userId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const allRecords = false;
  const showActiveTrucks = true;
  const vehicleTypeId = undefined;
  const date = routeSummary?.date;

  const isActive = false;
  const includeInactiveFacilities = true;

  const loadDependencies = () =>
    Promise.all([
      loadFeatures(vendorId)(dispatch),
      loadDisposalFacilities(vendorId, isActive, includeInactiveFacilities)(dispatch),
      loadOperationalFacilities(vendorId, isActive, includeInactiveFacilities)(dispatch),
      loadServiceZones(vendorId, allRecords)(dispatch),
      loadSupervisors(vendorId, date)(dispatch),
      loadGroups(vendorId)(dispatch),
      loadVehicles(vendorId, showActiveTrucks, vehicleTypeId, date)(dispatch),
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadFiltersSettings(vendorId, userId)(dispatch),
    ]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={EditRouteDetailsModal}
      successProps={props}
    />
  );
}
