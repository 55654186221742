import { FC } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { DashboardTabsButton, DashboardTabsButtons } from '../../styled';
import {
  hasSnowPlowVehiclesEnabledSelector,
  hasStreetSweeperVehiclesEnabledSelector,
} from 'src/fleet/ducks/vehicleTypesForVendor';

type DashboardOperationsTabsProps = {
  isCollapsed?: boolean;
};

const DashboardOperationsTabs: FC<DashboardOperationsTabsProps> = ({ isCollapsed }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const isStreetSweepingFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);
  const hasSnowVehicles = useSelector(hasSnowPlowVehiclesEnabledSelector);
  const hasSweeperVehicles = useSelector(hasStreetSweeperVehiclesEnabledSelector);

  const showSnowOps = isSnowPlowFeatureEnabled && hasSnowVehicles;
  const showSweeperOps = isStreetSweepingFeatureEnabled && hasSweeperVehicles;

  const isSnowPlow = pathname.includes('snow-ops');
  const isStreetSweeper = pathname.includes('sweeper-ops');

  if (!showSnowOps && !showSweeperOps) return null;

  return (
    <DashboardTabsButtons isCollapsed={isCollapsed}>
      <DashboardTabsButton
        active={!isSnowPlow && !isStreetSweeper}
        onClick={() => dispatch(push('/dashboard/all-ops'))}
      >
        {translate('dashboard.allOps')}
      </DashboardTabsButton>
      {showSnowOps && (
        <DashboardTabsButton active={isSnowPlow} onClick={() => dispatch(push('/dashboard/snow-ops'))}>
          {translate('dashboard.snowOps')}
        </DashboardTabsButton>
      )}
      {showSweeperOps && (
        <DashboardTabsButton active={isStreetSweeper} onClick={() => dispatch(push('/dashboard/sweeper-ops'))}>
          {translate('dashboard.sweeperOps')}
        </DashboardTabsButton>
      )}
    </DashboardTabsButtons>
  );
};

export default DashboardOperationsTabs;
