import { filter, map } from 'lodash-es';
import { Resizable } from 're-resizable';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Field, InjectedFormProps, change, getFormValues, reduxForm } from 'redux-form';

import { useSelector } from 'src/core/hooks/useSelector';
import { createSuccessNotification } from 'src/core/services/createNotification';
import { GeoFenceJsonList } from 'src/routes/ducks/geoFences';
import { Checkbox, Dropdown, Input, MapDragHandle, NavigationPrompt, PopoverWrapper } from '../../../core/components';
import {
  Grid,
  GridColumn,
  MapContainer,
  PanelSection,
  PanelSectionGroup,
  Popover,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { STREET_SWEEPER_ID } from '../../../fleet/constants';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../../../fleet/ducks';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isRequired, maxLength50 } from '../../../utils/services/validator';
import GeoFencesMapGL from '../pages/geoFences/components/mapGL/GeoFencesMapGL';
import { BULKY_PICKUPS_GEO_FENCE_ID, GEO_FENCE_TYPE, STREET_SWEEPER_GEO_FENCE_ID } from './../../constants';
import { isBulkyItemSchedulerFeatureEnabled } from 'src/vendors/ducks/features';

interface PropsWithoutReduxForm {
  hasCreateSaveEditGeoFencePermission?: boolean;
  isCreate: boolean;
  isEditingGeoFence: boolean;
  setIsEditingGeoFence: (isEditing: boolean) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const GeoFenceEditorForm = ({
  pristine,
  submitSucceeded,
  hasCreateSaveEditGeoFencePermission,
  handleSubmit,
  isEditingGeoFence,
  setIsEditingGeoFence,
}: Props) => {
  const dispatch = useDispatch();
  const vehicleTypesForVendor = useSelector(state =>
    vehicleTypesForVendorWithRoleTypeSelector(state.fleet.vehicleTypesForVendor),
  ) as unknown as any[];

  const thisFormValues = useSelector(getFormValues('geoFenceEditorForm')) as any;

  const isBulkySchedulerEnabled = useSelector(isBulkyItemSchedulerFeatureEnabled);

  const geoFenceGeoJson = useMemo(() => {
    if (thisFormValues && thisFormValues.geoFenceJson) {
      const json = JSON.parse(thisFormValues.geoFenceJson);
      return [
        {
          ...thisFormValues,
          name: thisFormValues.geoFenceName,
          geoFenceJson: json,
          geoFenceCoordinates:
            json.geometry.type === 'Polygon' ? [json.geometry.coordinates] : json.geometry.coordinates,
        },
      ];
    }
    return [];
  }, [thisFormValues?.geoFenceJson]); // eslint-disable-line react-hooks/exhaustive-deps

  const isStreetSweeperVehicleEnabled = filter(
    vehicleTypesForVendor,
    vehicleTypeForVendor => vehicleTypeForVendor.id === STREET_SWEEPER_ID,
  ).length;

  const zoneTypes = map(GEO_FENCE_TYPE, ({ id, name }) => ({
    value: id,
    label: name,
  }));

  const zoneTypesFiltered = isStreetSweeperVehicleEnabled
    ? zoneTypes
    : zoneTypes.filter(zoneType => zoneType.value !== STREET_SWEEPER_GEO_FENCE_ID);

  const zoneTypesFilteredForBulky = isBulkySchedulerEnabled
    ? zoneTypesFiltered
    : zoneTypesFiltered.filter(zoneType => zoneType.value !== BULKY_PICKUPS_GEO_FENCE_ID);

  const updateGeoFenceJson = (geoFencesData: GeoFenceJsonList, vendorId: number) => {
    createSuccessNotification(translate('routes.geoFences.alertMessages.geoFenceSavedLocally'));
    const geFenceJson = geoFencesData[0]?.geoFenceJson || {};
    dispatch(change('geoFenceEditorForm', 'geoFenceJson', geFenceJson));
  };

  return (
    <PanelSectionGroup height="100%" width="100%">
      <NavigationPrompt when={!pristine && !submitSucceeded} />
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall no">
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="geoFenceName"
                component={Input}
                label={translate('routes.geoFences.geoFenceName')}
                validate={[isRequired, maxLength50]}
                disabled={!hasCreateSaveEditGeoFencePermission}
              />
            </GridColumn>
            <GridColumn size="4/12"></GridColumn>
            <GridColumn size="3/12" verticalAlign="center">
              <Field
                component={Dropdown as any}
                name="geoFenceZoneType.id"
                options={zoneTypesFilteredForBulky}
                label={translate('routes.geoFences.type')}
                width="100%"
                validate={[isRequired]}
                disabled={!hasCreateSaveEditGeoFencePermission}
              />
            </GridColumn>
            <GridColumn size="2/12" verticalAlign="center">
              <PopoverWrapper
                triggerButton={
                  <Field
                    component={Checkbox}
                    name="isTest"
                    label={translate('routes.geoFences.testGeoFence')}
                    disabled={!hasCreateSaveEditGeoFencePermission}
                  />
                }
                popoverContent={<Popover>{translate('routes.geoFences.testGeoFencePopover')}</Popover>}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
      <PanelSection>
        <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
          <MapContainer size="large">
            <GeoFencesMapGL
              geoFenceJsonList={geoFenceGeoJson}
              updateGeoFence={updateGeoFenceJson}
              isEditingGeoFences={isEditingGeoFence}
              setIsEditingGeoFences={setIsEditingGeoFence}
              isSingleGeoFence
            />
          </MapContainer>
        </Resizable>
      </PanelSection>
    </PanelSectionGroup>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'geoFenceEditorForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(GeoFenceEditorForm);
