import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorId } from '../../services/currentVendorSelector';
import { loadExceptionManagementConfigurations } from '../../ducks/exceptionManagementConfiguration';
import { loadReasonCodeTypes } from '../../../common/ducks';
import { loadVehicleTypesForExceptionManagement } from '../../ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import ExceptionManagementConfigurationModal from './ExceptionManagementConfigurationModal';

const ExceptionManagementConfigurationModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const vehicleTypeId = useSelector((state: AppState) => state.vendors.exceptionManagementConfiguration.vehicleTypeId);
  const reasonCodesCount = useSelector((state: AppState) => state.common.reasonCodeTypes.reasonCodeTypes.length);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies = [loadVehicleTypesForExceptionManagement(vendorId)(dispatch), loadReasonCodeTypes()(dispatch)];

    if (vehicleTypeId) {
      dependencies.push(loadExceptionManagementConfigurations(vendorId, vehicleTypeId, reasonCodesCount)(dispatch));
    }

    return Promise.all(dependencies);
  }, [vendorId, vehicleTypeId, dispatch, reasonCodesCount]);

  return (
    <TypedResolver
      successComponent={ExceptionManagementConfigurationModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default ExceptionManagementConfigurationModalResolver;
