import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const DETECTION_BASKETBALL_HOOP = 'Basketball Hoop';
export const DETECTION_BIKE_LANE_SWEEPING = 'Bike Lane Sweeping';
export const DETECTION_BLACK = 'Black';
export const DETECTION_BLUE = 'Blue';
export const DETECTION_CONSTRUCTION = 'Construction';
export const DETECTION_CRACKING = 'Cracking';
export const DETECTION_DISCOLORATION_IN_ROAD = 'Discoloration in road';
export const DETECTION_DRIVEWAY = 'Driveway';
export const DETECTION_GRAY = 'Gray';
export const DETECTION_GREEN = 'Green';
export const DETECTION_INCORRECT_INFERENCE = 'Incorrect Inference';
export const DETECTION_INTERSECTION_SWEEPING = 'Intersection Sweeping';
export const DETECTION_MAILBOX = 'Mailbox';
export const DETECTION_MANHOLE = 'Manhole';
export const DETECTION_NO_CURB_PRESENT = 'No Curb Present';
export const DETECTION_NON_CURB_SWEEPING = 'Non Curb Sweeping';
export const DETECTION_NOT_A_TRASH_CONTAINER = 'Not a Trash Container';
export const DETECTION_NOT_OVERFLOWING = 'Not Overflowing';
export const DETECTION_ON_CURB_SHADOWS = 'On Curb w/ Shadows';
export const DETECTION_ON_LEFT_CURB_OR_MEDIAN = 'On Left Curb / Median';
export const DETECTION_ON_RIGHT_CURB = 'On Right Curb';
export const DETECTION_OPEN_LID = 'Open Lid';
export const DETECTION_OTHER = 'Other';
export const DETECTION_OUTSIDE_THE_STREET_SEGMENT = 'Outside the Street Segment';
export const DETECTION_PATCHED_POTHOLE = 'Patched pothole';
export const DETECTION_PEDESTRIAN = 'Person';
export const DETECTION_PINK = 'Pink';
export const DETECTION_PUDDLE = 'Puddle';
export const DETECTION_SIGN = 'Sign';
export const DETECTION_STREET_MARKINGS = 'Street Markings (turn arrows, etc.)';
export const DETECTION_TELEPHONE_POLES = 'Telephone Poles';
export const DETECTION_TREE_BUSH = 'Tree';
export const DETECTION_TURN_LANE_SWEEPING = 'Turn Lane Sweeping';
export const DETECTION_UNKNOWN = 'Unknown';
export const DETECTION_VEHICLE = 'Vehicle';
export const DETECTION_VEHICLES = 'Vehicles';
export const DETECTION_WASTE_CONTAINER = 'Waste container';
export const DETECTION_PLASTIC_BAG = 'Plastic Bag';
export const DETECTION_PLASTIC_BOTTLE = 'Plastic Bottle';
export const DETECTION_PLASTIC_FILM = 'Plastic Film';
export const DETECTION_CAN = 'Can';
export const DETECTION_CARDBOARD = 'Cardboard';
export const DETECTION_PAPER_BAG = 'Paper Bag';
export const DETECTION_NO_CONTAMINATION_PRESENT = 'No Contamination Present';
export const DETECTION_NOT_A_POTHOLE = 'Not a pothole';
export const DETECTION_SHADOW = 'Shadow';
export const DETECTION_PAPER_PLATES = 'Paper Plates';
export const DETECTION_PAPER_NEWSPAPER = 'Paper / Newspaper';
export const DETECTION_SHREDDED_PAPER = 'Shredded Paper';
export const DETECTION_FAST_FOOD_BAGS = 'Fast Food Bags';
export const DETECTION_OVEREXPOSED_IMAGE = 'Overexposed image';

const keys = [
  {
    technicalName: DETECTION_BASKETBALL_HOOP,
    name: translate('inferenceAudit.inferenceAuditDetections.basketballHoop'),
    safeName: 'basketballHoop',
  },
  {
    technicalName: DETECTION_BIKE_LANE_SWEEPING,
    name: translate('inferenceAudit.inferenceAuditDetections.bikeLaneSweeping'),
    safeName: 'bikeLaneSweeping',
  },
  {
    technicalName: DETECTION_BLACK,
    name: translate('inferenceAudit.inferenceAuditDetections.black'),
    safeName: 'black',
  },
  {
    technicalName: DETECTION_BLUE,
    name: translate('inferenceAudit.inferenceAuditDetections.blue'),
    safeName: 'blue',
  },
  {
    technicalName: DETECTION_CONSTRUCTION,
    name: translate('inferenceAudit.inferenceAuditDetections.construction'),
    safeName: 'construction',
  },
  {
    technicalName: DETECTION_DRIVEWAY,
    name: translate('inferenceAudit.inferenceAuditDetections.driveway'),
    safeName: 'driveway',
  },
  {
    technicalName: DETECTION_GRAY,
    name: translate('inferenceAudit.inferenceAuditDetections.gray'),
    safeName: 'gray',
  },
  {
    technicalName: DETECTION_GREEN,
    name: translate('inferenceAudit.inferenceAuditDetections.green'),
    safeName: 'green',
  },
  {
    technicalName: DETECTION_INCORRECT_INFERENCE,
    name: translate('inferenceAudit.inferenceAuditDetections.incorrectInference'),
    safeName: 'incorrectInference',
  },
  {
    technicalName: DETECTION_INTERSECTION_SWEEPING,
    name: translate('inferenceAudit.inferenceAuditDetections.intersectionSweeping'),
    safeName: 'intersectionSweeping',
  },
  {
    technicalName: DETECTION_MAILBOX,
    name: translate('inferenceAudit.inferenceAuditDetections.mailbox'),
    safeName: 'mailbox',
  },
  {
    technicalName: DETECTION_NO_CURB_PRESENT,
    name: translate('inferenceAudit.inferenceAuditDetections.noCurbPresent'),
    safeName: 'noCurbPresent',
  },
  {
    technicalName: DETECTION_NON_CURB_SWEEPING,
    name: translate('inferenceAudit.inferenceAuditDetections.nonCurbSweeping'),
    safeName: 'nonCurbSweeping',
  },
  {
    technicalName: DETECTION_NOT_OVERFLOWING,
    name: translate('inferenceAudit.inferenceAuditDetections.notOverflowing'),
    safeName: 'notOverflowing',
  },
  {
    technicalName: DETECTION_ON_CURB_SHADOWS,
    name: translate('inferenceAudit.inferenceAuditDetections.onCurbShadows'),
    safeName: 'onCurbShadows',
  },
  {
    technicalName: DETECTION_ON_LEFT_CURB_OR_MEDIAN,
    name: translate('inferenceAudit.inferenceAuditDetections.onLeftCurbOrMedian'),
    safeName: 'onLeftCurbOrMedian',
  },
  {
    technicalName: DETECTION_ON_RIGHT_CURB,
    name: translate('inferenceAudit.inferenceAuditDetections.onRightCurb'),
    safeName: 'onRightCurb',
  },
  {
    technicalName: DETECTION_OPEN_LID,
    name: translate('inferenceAudit.inferenceAuditDetections.openLid'),
    safeName: 'openLid',
  },
  {
    technicalName: DETECTION_OTHER,
    name: translate('inferenceAudit.inferenceAuditDetections.other'),
    safeName: 'other',
  },
  {
    technicalName: DETECTION_PEDESTRIAN,
    name: translate('inferenceAudit.inferenceAuditDetections.pedestrian'),
    safeName: 'pedestrian',
  },
  {
    technicalName: DETECTION_PINK,
    name: translate('inferenceAudit.inferenceAuditDetections.pink'),
    safeName: 'pink',
  },
  {
    technicalName: DETECTION_SIGN,
    name: translate('inferenceAudit.inferenceAuditDetections.sign'),
    safeName: 'sign',
  },
  {
    technicalName: DETECTION_TREE_BUSH,
    name: translate('inferenceAudit.inferenceAuditDetections.treeBush'),
    safeName: 'treeBush',
  },
  {
    technicalName: DETECTION_TURN_LANE_SWEEPING,
    name: translate('inferenceAudit.inferenceAuditDetections.turnLaneSweeping'),
    safeName: 'turnLaneSweeping',
  },
  {
    technicalName: DETECTION_UNKNOWN,
    name: translate('inferenceAudit.inferenceAuditDetections.unknown'),
    safeName: 'unknown',
  },
  {
    technicalName: DETECTION_VEHICLE,
    name: translate('inferenceAudit.inferenceAuditDetections.vehicle'),
    safeName: 'vehicle',
  },
  {
    technicalName: DETECTION_WASTE_CONTAINER,
    name: translate('inferenceAudit.inferenceAuditDetections.wasteContainer'),
    safeName: 'wasteContainer',
  },
  {
    technicalName: DETECTION_NOT_A_TRASH_CONTAINER,
    name: translate('inferenceAudit.inferenceAuditDetections.notTrashContainer'),
    safeName: 'notTrashContainer',
  },
  {
    technicalName: DETECTION_TELEPHONE_POLES,
    name: translate('inferenceAudit.inferenceAuditDetections.telephonePoles'),
    safeName: 'telephonePoles',
  },
  {
    technicalName: DETECTION_VEHICLES,
    name: translate('inferenceAudit.inferenceAuditDetections.vehicles'),
    safeName: 'vehicles',
  },
  {
    technicalName: DETECTION_STREET_MARKINGS,
    name: translate('inferenceAudit.inferenceAuditDetections.streetMarkings'),
    safeName: 'streetMarkings',
  },
  {
    technicalName: DETECTION_PUDDLE,
    name: translate('inferenceAudit.inferenceAuditDetections.puddle'),
    safeName: 'puddle',
  },
  {
    technicalName: DETECTION_CRACKING,
    name: translate('inferenceAudit.inferenceAuditDetections.cracking'),
    safeName: 'cracking',
  },
  {
    technicalName: DETECTION_MANHOLE,
    name: translate('inferenceAudit.inferenceAuditDetections.manhole'),
    safeName: 'manhole',
  },
  {
    technicalName: DETECTION_DISCOLORATION_IN_ROAD,
    name: translate('inferenceAudit.inferenceAuditDetections.discolorationInRoad'),
    safeName: 'discolorationInRoad',
  },
  {
    technicalName: DETECTION_PATCHED_POTHOLE,
    name: translate('inferenceAudit.inferenceAuditDetections.patchedPothole'),
    safeName: 'patchedPothole',
  },
  {
    technicalName: DETECTION_OUTSIDE_THE_STREET_SEGMENT,
    name: translate('inferenceAudit.inferenceAuditDetections.outsideTheStreetSegment'),
    safeName: 'outsideTheStreetSegment',
  },
  {
    technicalName: DETECTION_PLASTIC_BAG,
    name: translate('inferenceAudit.inferenceAuditDetections.plasticBag'),
    safeName: 'plasticBag',
  },
  {
    technicalName: DETECTION_PLASTIC_BOTTLE,
    name: translate('inferenceAudit.inferenceAuditDetections.plasticBottle'),
    safeName: 'plasticBottle',
  },
  {
    technicalName: DETECTION_PLASTIC_FILM,
    name: translate('inferenceAudit.inferenceAuditDetections.plasticFilm'),
    safeName: 'plasticFilm',
  },
  {
    technicalName: DETECTION_CAN,
    name: translate('inferenceAudit.inferenceAuditDetections.can'),
    safeName: 'can',
  },
  {
    technicalName: DETECTION_CARDBOARD,
    name: translate('inferenceAudit.inferenceAuditDetections.cardboard'),
    safeName: 'cardboard',
  },
  {
    technicalName: DETECTION_PAPER_BAG,
    name: translate('inferenceAudit.inferenceAuditDetections.paperBag'),
    safeName: 'paperBag',
  },
  {
    technicalName: DETECTION_NO_CONTAMINATION_PRESENT,
    name: translate('inferenceAudit.inferenceAuditDetections.noContaminationPresent'),
    safeName: 'noContaminationPresent',
  },
  {
    technicalName: DETECTION_NOT_A_POTHOLE,
    name: translate('inferenceAudit.inferenceAuditDetections.notAPothole'),
    safeName: 'noContaminationPresent',
  },
  {
    technicalName: DETECTION_SHADOW,
    name: translate('inferenceAudit.inferenceAuditDetections.shadow'),
    safeName: 'noContaminationPresent',
  },
  {
    technicalName: DETECTION_PAPER_PLATES,
    name: translate('inferenceAudit.inferenceAuditDetections.paperPlates'),
    safeName: 'noContaminationPresent',
  },
  {
    technicalName: DETECTION_PAPER_NEWSPAPER,
    name: translate('inferenceAudit.inferenceAuditDetections.paperNewspaper'),
    safeName: 'noContaminationPresent',
  },
  {
    technicalName: DETECTION_SHREDDED_PAPER,
    name: translate('inferenceAudit.inferenceAuditDetections.shreddedPaper'),
    safeName: 'noContaminationPresent',
  },
  {
    technicalName: DETECTION_FAST_FOOD_BAGS,
    name: translate('inferenceAudit.inferenceAuditDetections.fastFoodBags'),
    safeName: 'noContaminationPresent',
  },
  {
    technicalName: DETECTION_OVEREXPOSED_IMAGE,
    name: translate('inferenceAudit.inferenceAuditDetections.overexposedImage'),
    safeName: 'noContaminationPresent',
  },
];

export const INFERENCE_AUDIT_DETECTIONS = mapKeys(keys, 'technicalName');
export const INFERENCE_AUDIT_DETECTIONS_BY_SAFE_NAME = mapKeys(keys, 'safeName');
