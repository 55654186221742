import styled, { css } from 'styled-components';

import { loadingOverlay } from 'src/core/styles';

export const LogoImageThumbnail = styled.img`
  border: 0.5px solid ${props => props.theme.brandPrimary};
  padding: 5px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 3px;
`;

export const LogoImageContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')}
    `}
`;
