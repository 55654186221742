import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import doLoadRoutesForReassignment from '../services/loadRoutesForReassignment';

// Actions
const START_LOAD = 'routes/routesForReassignment/START_LOAD';
const COMPLETE_LOAD = 'routes/routesForReassignment/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routesForReassignment/FAIL_LOAD';
const RESET = 'routes/routesForReassignment/RESET';

interface State {
  isLoading: boolean;
  routesForReassignment?: any[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  routesForReassignment: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routesForReassignment: action.routesForReassignment,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routesForReassignment: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routesForReassignment: any[]) => ({
  type: COMPLETE_LOAD,
  routesForReassignment,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRoutesForReassignment =
  (
    vendorId: number,
    sourceRouteId: number | string,
    vehicleTypeId: number,
    wasteMaterialTypeId: number | undefined,
    date: Date | string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRoutesForReassignmentPromise = doLoadRoutesForReassignment(
      vendorId,
      sourceRouteId,
      vehicleTypeId,
      wasteMaterialTypeId,
      date,
    );
    loadRoutesForReassignmentPromise
      .then(routesForReassignment => dispatch(completeLoad(routesForReassignment)))
      .catch(() => dispatch(failLoad()));
    return loadRoutesForReassignmentPromise;
  };

export const resetRoutesForReassignment = () => ({
  type: RESET,
});
