import { camelCase, debounce, filter, map, uniqBy } from 'lodash-es';
import { ChangeEvent, useState } from 'react';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { useMemo } from 'react';
import moment, { Duration } from 'moment';

import { Button, Grid, GridColumn, ModalSection, Text } from 'src/core/components/styled';
import { CityAlertSetting } from 'src/vendors/interfaces/CityAlertSettings';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DatePicker, Dropdown, Input, LocationPicker, Switch, TypeAhead, TypedField } from 'src/core/components';
import { isBeforeStartDate } from 'src/utils/services/validator';
import { isRequired } from 'src/utils/services/validator';
import { LocationData } from 'src/customers/interfaces/LocationServiceTypes';
import { NEW_LOCATION_ALERT_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import searchCustomerOnlyWithLocations from 'src/routes/services/searchCustomerOnlyWithLocations';
import TimePickerField from 'src/core/components/TimePickerField';
import translate from 'src/core/services/translate';

interface PropsWithoutReduxForm {
  isCityAlert?: boolean;
  isLocationAlert?: boolean;
  isFetchingAddress: boolean;
  isSourceLocationAndCityAlerts?: boolean;
  onLocationPickerChanged: (
    pinnedAddress?: ChangeEvent | LocationData,
    locationId?: number,
    isPinOnMapVisible?: boolean,
  ) => void;
}

export interface CityAlertCreateFormValues {
  alertTypeId?: number;
  cityAlertTypeId?: number;
  description?: string;
  endDate: Date | string;
  expirationDate?: Date | string;
  expirationTime?: string;
  isActive: boolean;
  locationAlertTypeId?: number;
  pinnedAddress?: LocationData;
  startDate: Date | string;
  title?: string;
}

export const CITY_ALERT_CREATE_FORM = 'CityAlertCreateForm';
const formSelector = formValueSelector(CITY_ALERT_CREATE_FORM);

type Props = PropsWithoutReduxForm & InjectedFormProps<CityAlertCreateFormValues, PropsWithoutReduxForm>;

const CityAlertCreateForm: React.FC<Props> = ({
  change,
  handleSubmit,
  isCityAlert,
  isLocationAlert,
  isFetchingAddress,
  isSourceLocationAndCityAlerts,
  onLocationPickerChanged,
}) => {
  const vendorId = useSelector(currentVendorId);
  const startDate = useSelector(state => formSelector(state, 'startDate'));
  const endDate = useSelector(state => formSelector(state, 'endDate'));
  const alertTypeId = useSelector(state => formSelector(state, 'alertTypeId'));
  const alertTypes = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings?.cityAlertTypes);
  const locationAlerts = useSelector(state => state.vendors.locationAlerts.locationAlerts);

  const isNewLocationAlert = isLocationAlert && alertTypeId === NEW_LOCATION_ALERT_ID;
  const isPinOnMapVisible = true;

  const getDefaultExpirationLabel = (alertTypeId: number, cityAlertTypeSelected?: CityAlertSetting) => {
    return alertTypeId ? (
      cityAlertTypeSelected?.hasNoEndDate ? (
        translate('vendors.cityAlerts.noExpiration')
      ) : (
        <>
          {cityAlertTypeSelected?.expirationUnit}{' '}
          {translate(
            `common.timeMeasurementTypes.${camelCase(
              cityAlertTypeSelected?.expirationUnitTimeMeasurementType.technicalName,
            )}`,
          )}
        </>
      )
    ) : (
      '-'
    );
  };

  const [alertExpirationTimeLabel, setAlertExpirationTimeLabel] = useState<string>('-');

  const cityAlertTypeOptions = useMemo(
    () =>
      map(
        filter(alertTypes, alert => alert.isActive),
        ({ cityAlertType }) => ({
          label: cityAlertType.name,
          value: cityAlertType.id,
        }),
      ),
    [alertTypes],
  );

  const locationAlertTypeOptions = useMemo(
    () =>
      map(
        filter(locationAlerts, alert => alert.isActive),
        ({ title, id }) => ({
          label: title,
          value: id,
        }),
      ),
    [locationAlerts],
  );

  locationAlertTypeOptions.unshift({
    label: (
      <Button color="primary" size="xSmall">
        {translate('vendors.cityAlerts.newLocationAlert')}
      </Button>
    ) as any,
    value: NEW_LOCATION_ALERT_ID,
  });

  const onAlertTypeChanged = (value: ChangeEvent | number) => {
    if (isLocationAlert) {
      const currentLocation = locationAlerts.find(location => location.id === Number(value));
      change('description', currentLocation?.description);
    }

    const cityAlertTypeSelected = alertTypes?.find(alertType => alertType.cityAlertType.id === value);
    const defaultExpirationLabel = getDefaultExpirationLabel(value as number, cityAlertTypeSelected);

    if (!endDate && !!startDate) setAlertExpirationTimeLabel(translate('vendors.cityAlerts.noExpiration'));
    else if (!endDate) setAlertExpirationTimeLabel(defaultExpirationLabel);
  };

  const loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    searchCustomerOnlyWithLocations(vendorId, searchTerm).then(onOptionsLoaded);
  }, 500);

  const getDurationLabel = (duration: Duration) =>
    duration.asHours() >= 24
      ? `${parseInt(duration.asDays().toString())} ${translate(
          `common.timeMeasurementTypes.${parseInt(duration.asDays().toString()) === 1 ? 'day' : 'days'}`,
        )}`
      : duration.asHours() <= 1
      ? `${parseInt(duration.asMinutes().toString())} ${translate(
          `common.timeMeasurementTypes.${parseInt(duration.asMinutes().toString()) === 1 ? 'minute' : 'minutes'}`,
        )}`
      : `${parseInt(duration.asHours().toString())} ${translate(
          `common.timeMeasurementTypes.${parseInt(duration.asHours().toString()) === 1 ? 'hour' : 'hours'}`,
        )}`;

  const onCustomerLocationChanged = (event: any) => {
    if (event && event.location) {
      const locationAddress = event.location.address;
      const locationId = event.location.id;
      onLocationPickerChanged(locationAddress, locationId, isPinOnMapVisible);
    }

    if (!event) onLocationPickerChanged(undefined, undefined, isPinOnMapVisible);
  };

  const onStartDateChanged = (startDate: ChangeEvent | string) => {
    const currentTime = moment(new Date());
    const newStartDate = moment(startDate as string).startOf('day');
    const newEndDate =
      endDate &&
      moment(endDate as string)
        .endOf('day')
        .add(1);

    const cityAlertTypeSelected = alertTypes?.find(alertType => alertType.cityAlertType.id === alertTypeId);
    const defaultExpirationLabel = getDefaultExpirationLabel(alertTypeId, cityAlertTypeSelected);

    const shouldCalculateFromToday = moment().isSame(startDate as string, 'day') || !startDate;
    const duration =
      !!endDate &&
      (shouldCalculateFromToday
        ? moment.duration(newEndDate.diff(currentTime))
        : moment.duration(newEndDate.diff(newStartDate)));
    const newAlertExpirationTimeLabel =
      !endDate && !!startDate
        ? translate('vendors.cityAlerts.noExpiration')
        : !duration || !duration?.isValid()
        ? defaultExpirationLabel
        : getDurationLabel(duration);

    setAlertExpirationTimeLabel(newAlertExpirationTimeLabel);
  };

  const onEndDateChanged = (endDate: ChangeEvent | string) => {
    const currentTime = moment(new Date());
    const newStartDate = moment(startDate as string).startOf('day');
    const newEndDate = moment(endDate as string)
      .endOf('day')
      .add(1);

    const shouldCalculateFromToday = moment().isSame(startDate, 'day') || !startDate;
    const duration =
      !!endDate &&
      (shouldCalculateFromToday
        ? moment.duration(newEndDate.diff(currentTime))
        : moment.duration(newEndDate.diff(newStartDate)));

    const cityAlertTypeSelected = alertTypes?.find(alertType => alertType.cityAlertType.id === alertTypeId);
    const defaultExpirationLabel = getDefaultExpirationLabel(alertTypeId, cityAlertTypeSelected);

    const newAlertExpirationTimeLabel =
      !endDate && !!startDate
        ? translate('vendors.cityAlerts.noExpiration')
        : !duration || !duration?.isValid()
        ? defaultExpirationLabel
        : getDurationLabel(duration);

    setAlertExpirationTimeLabel(newAlertExpirationTimeLabel);
  };

  return (
    <form onSubmit={handleSubmit}>
      <ModalSection padding="small xSmall no" overflow="visible">
        <Grid multiLine margin="negLarge no no no">
          <GridColumn size="2/12" margin="no" verticalAlign="center">
            <TypedField
              name="isActive"
              component={Switch}
              props={{
                label: translate('common.active'),
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid multiLine margin="sMedium no no no">
          <GridColumn size="3/12">
            <TypedField
              name="alertTypeId"
              component={Dropdown}
              props={{
                options: isSourceLocationAndCityAlerts
                  ? isCityAlert
                    ? cityAlertTypeOptions
                    : uniqBy(locationAlertTypeOptions, 'value')
                  : cityAlertTypeOptions,
                label: translate('vendors.cityAlerts.alertType'),
                margin: 'no',
              }}
              validate={[isRequired]}
              onChange={onAlertTypeChanged}
            />
          </GridColumn>
          {isPinOnMapVisible && isCityAlert ? (
            <GridColumn size="5/12">
              <TypedField
                name="pinnedAddress"
                props={{
                  isLoading: isFetchingAddress,
                  label: translate('tooltips.searchAddressPinLocationOnMap'),
                  margin: 'no',
                }}
                component={LocationPicker}
                validate={[isRequired]}
                onChange={(pinnedAddress: ChangeEvent | LocationData) => onLocationPickerChanged(pinnedAddress)}
              />
            </GridColumn>
          ) : (
            <GridColumn size="5/12">
              <TypedField
                name="customerLocation"
                component={TypeAhead}
                props={{
                  isClearable: true,
                  label: `${translate('common.customer')} / ${translate('common.location')}`,
                  getOptions: loadCustomerLocations,
                  margin: 'no',
                }}
                validate={[isRequired]}
                onChange={onCustomerLocationChanged}
              />
            </GridColumn>
          )}

          <GridColumn size="2/12">
            <TypedField
              name="latitude"
              component={Input}
              props={{
                label: translate('common.latitude'),
                disabled: true,
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="2/12">
            <TypedField
              name="longitude"
              component={Input}
              props={{
                label: translate('common.longitude'),
                disabled: true,
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid multiLine margin="sMedium no no no">
          {isLocationAlert && (
            <>
              {isNewLocationAlert && (
                <GridColumn size="3/12">
                  <TypedField
                    name="title"
                    component={Input}
                    props={{
                      label: translate('vendors.cityAlerts.locationTitle'),
                    }}
                    validate={[isRequired]}
                  />
                </GridColumn>
              )}

              <GridColumn size="5/12">
                <TypedField
                  name="description"
                  component={Input}
                  props={{
                    label: translate('vendors.cityAlerts.locationDescription'),
                    disabled: !isNewLocationAlert,
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>
            </>
          )}

          {isSourceLocationAndCityAlerts && isCityAlert && (
            <Grid multiLine>
              <GridColumn size="2/12" borderRight>
                <Text whiteSpace="nowrap" block size="small" margin="xSmall no small no">
                  <em>{translate('vendors.cityAlerts.alertExpiresAfter')}:</em>
                </Text>
              </GridColumn>
              <GridColumn size="10/12" borderRight>
                <Text whiteSpace="nowrap" block size="small" margin="xSmall no small no">
                  <em>{translate('vendors.cityAlerts.changeTheDefaulSettingsMessage')}:</em>
                </Text>
              </GridColumn>
            </Grid>
          )}

          {isSourceLocationAndCityAlerts ? (
            <>
              {isCityAlert && (
                <>
                  <GridColumn size="2/12" borderRight verticalAlign="flex-start" align="left">
                    <Text>{alertExpirationTimeLabel}</Text>
                  </GridColumn>

                  <GridColumn size="2/12">
                    <TypedField
                      name="startDate"
                      component={DatePicker}
                      props={{
                        placeholder: !isLocationAlert && translate('common.startDate'),
                        label: isLocationAlert && translate('common.startDate'),
                        isClearable: true,
                        disabledDays: [{ before: TODAY }],
                        margin: 'no',
                      }}
                      onChange={onStartDateChanged}
                    />
                  </GridColumn>
                  <GridColumn size="2/12">
                    <TypedField
                      name="endDate"
                      component={DatePicker}
                      props={{
                        placeholder: !isLocationAlert && translate('common.endDate'),
                        label: isLocationAlert && translate('common.endDate'),
                        isClearable: true,
                        disabledDays: [{ before: TODAY }],
                        margin: 'no',
                      }}
                      validate={startDate && endDate ? [isRequired, isBeforeStartDate] : []}
                      onChange={onEndDateChanged}
                    />
                  </GridColumn>

                  <GridColumn size="4/12" verticalAlign="center" align="left" margin="negMedium no no no">
                    <Text size="small">{translate('vendors.cityAlerts.infoMessageRegardingDates')}</Text>
                  </GridColumn>
                </>
              )}

              <GridColumn
                size={isCityAlert ? '2/12' : isNewLocationAlert ? '4/12' : '7/12'}
                margin={isCityAlert ? 'negSmall no no no' : 'small no no no'}
                align="right"
              >
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
              </GridColumn>
            </>
          ) : (
            <>
              <GridColumn size="3/12">
                <TypedField
                  name="expirationDate"
                  component={DatePicker}
                  props={{
                    label: translate('vendors.cityAlerts.alertExpiresAfter'),
                    disabledDays: [{ before: TODAY }],
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>
              <GridColumn size="2/12" verticalAlign="center">
                <TimePickerField
                  defaultValue="23:45"
                  name="expirationTime"
                  formName={CITY_ALERT_CREATE_FORM}
                  minuteStep={15}
                  allowClear={false}
                  validate={[isRequired]}
                />
              </GridColumn>
            </>
          )}
        </Grid>
      </ModalSection>
    </form>
  );
};

export default reduxForm<CityAlertCreateFormValues, PropsWithoutReduxForm>({
  form: CITY_ALERT_CREATE_FORM,
  onSubmitFail: focusFirstInvalidField,
})(CityAlertCreateForm);
