import { MonthlyBody, MonthlyCalendar } from '@zach.codes/react-calendar';
import '@zach.codes/react-calendar/dist/calendar-tailwind-no-reset.css';
import humps from 'humps';
import moment from 'moment';

import { ISSUE_REPORTED, PICKUP_STATUSES_BY_TECHNICAL_NAME, UNSCHEDULED } from 'src/common/constants/pickupStatuses';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { PopoverWrapper } from 'src/core/components';
import MonthlyDay from 'src/core/components/MonthlyDay';
import MonthlyNav from 'src/core/components/MonthlyNav';
import {
  Grid,
  GridColumn,
  LabeledSwitch,
  LabeledSwitchBar,
  LabeledSwitchHandle,
  LabeledSwitchText,
  PanelSection,
} from 'src/core/components/styled';
import { CalendarWrapper, MonthlyEventItem } from 'src/core/components/styled/Calendar';
import { TODAY } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { ServiceWorkOrder } from 'src/customers/interfaces/Services';
import WorkOrdersMonthlyEventItemPopoverButton from './WorkOrdersMonthlyEventItemPopoverButton';
import WorkOrdersMonthlyEventItemPopoverContent from './WorkOrdersMonthlyEventItemPopoverContent';

const getPUStatusColor = (date: StringOrDate, pickupStatus: string) => {
  const inFuture = moment(date).isAfter(TODAY);
  const psId = PICKUP_STATUSES_BY_TECHNICAL_NAME[humps.camelize(pickupStatus)]?.id;
  const isError = psId === ISSUE_REPORTED || psId === UNSCHEDULED;
  return inFuture ? 'grayDarker' : isError ? 'alert' : 'primary';
};

interface Props {
  currentMonth: {
    startDate: Date;
    endDate: Date;
  };
  billingModuleEnabled: boolean;
  handleDateChange: (date: Date) => void;
  showOperationalWorkOrders: boolean;
  effectiveStartDate: StringOrDate;
  workOrders: ServiceWorkOrder[];
  toggleOperationalWorkOrders: () => void;
}

const WorkOrdersCalendar = ({
  currentMonth,
  effectiveStartDate,
  handleDateChange,
  showOperationalWorkOrders,
  toggleOperationalWorkOrders,
  workOrders,
  billingModuleEnabled,
}: Props) => {
  return (
    <CalendarWrapper>
      <MonthlyCalendar currentMonth={currentMonth.startDate} onCurrentMonthChange={handleDateChange}>
        <PanelSection padding="small no">
          <Grid flexDirection="row-reverse">
            <GridColumn size="8/12" marginRight="small" padding="no no no xSmall">
              <MonthlyNav
                lowerDateLimit={moment(effectiveStartDate).startOf('month').subtract(1, 'month').toDate()}
                upperDateLimit={moment().add(90, 'days').endOf('month').toDate()}
              />
            </GridColumn>
            <GridColumn size="4/12" marginRight="auto" padding="no">
              {billingModuleEnabled && (
                <LabeledSwitch isActive={showOperationalWorkOrders}>
                  <LabeledSwitchBar onClick={toggleOperationalWorkOrders}>
                    <LabeledSwitchText isHighlighted={!showOperationalWorkOrders}>
                      {translate('customers.serviceEditor.billingWorkOrders')}
                    </LabeledSwitchText>
                    <LabeledSwitchText isHighlighted={showOperationalWorkOrders}>
                      {translate('customers.serviceEditor.operationalWorkOrders')}
                    </LabeledSwitchText>
                    <LabeledSwitchHandle />
                  </LabeledSwitchBar>
                </LabeledSwitch>
              )}
            </GridColumn>
          </Grid>
        </PanelSection>
        <MonthlyBody
          events={workOrders.map(wo => ({
            ...wo,
            date: moment(moment(wo.scheduledDate).format('MM/DD/YYYY')).toDate(),
          }))}
        >
          <MonthlyDay<ServiceWorkOrder>
            height="10rem"
            noOverflow
            renderDay={data =>
              !!data.length && (
                <MonthlyEventItem>
                  <PopoverWrapper
                    width="100%"
                    size="large"
                    triggerButton={
                      <WorkOrdersMonthlyEventItemPopoverButton
                        isOperationalView={showOperationalWorkOrders}
                        workOrders={data}
                      />
                    }
                    popoverContent={
                      <WorkOrdersMonthlyEventItemPopoverContent
                        getPUStatusColor={getPUStatusColor}
                        isOperationalView={showOperationalWorkOrders}
                        workOrders={data}
                      />
                    }
                  />
                </MonthlyEventItem>
              )
            }
          />
        </MonthlyBody>
      </MonthlyCalendar>
    </CalendarWrapper>
  );
};

export default WorkOrdersCalendar;
