import React from 'react';
import { connect } from 'react-redux';

import { isSmartCitySelector } from '../ducks';
import { AppState } from '../../store';

interface Props {
  isSmartCityVendor?: boolean;
}

const SmartCityVendorGuard: React.FC<Props> = ({ isSmartCityVendor, children }) => {
  if (!isSmartCityVendor && children) {
    return <>{children}</>;
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  isSmartCityVendor: isSmartCitySelector(state.account.login, state.vendors.defaultVendor),
});

export default connect(mapStateToProps)(SmartCityVendorGuard);
