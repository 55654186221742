import { push } from 'connected-react-router';
import { map, size } from 'lodash-es';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionButtonTooltip } from 'src/core/components';
import { Button, ButtonSet } from 'src/core/components/styled';

import translate from 'src/core/services/translate';
import { CalendarEventFooter, CalendarEventWrapper, CalendarEventBody } from 'src/routes/components/styled';
import { createUrl } from 'src/utils/services/queryParams';
import CalendarEventExpandableSection from './CalendarEventExpandableSection';
import { checkIfSupport, checkIfViewOnly, hasPermission } from 'src/account/utils/permissions';
import { ROUTES_PLANNER_SCHEDULE } from 'src/account/constants';

interface CalendarEventProps {
  selectedDate?: Date;
  openScheduler: (templates: { id: number; scheduledDay: number }[], defaultDate?: Date) => void;
  openAddJobModal: (routeId?: number, vehicleTypeId?: number, date?: string) => void;
}

const CalendarEventInner = ({ event, openScheduler, openAddJobModal, selectedDate }: any) => {
  const { routeSection, vehicleSection, driverSection, routeTemplateIds } = event;
  const dateToPass = moment(event.start).format('DD/MM/YYYY');

  const dispatch = useDispatch();

  const isViewOnly = checkIfViewOnly();
  const isSupport = checkIfSupport();

  const isViewOnlyOrSupport = isViewOnly || isSupport;

  const [openedSections, setOpenSections] = useState({
    routeSection: false,
    vehicleSection: false,
    driverSection: false,
  });

  const toggleSection = useCallback((section: 'routeSection' | 'vehicleSection' | 'driverSection') => {
    setOpenSections(prev => {
      return {
        routeSection: false,
        vehicleSection: false,
        driverSection: false,
        [section]: !prev[section],
      };
    });
  }, []);

  const openSchedulerHandler = useCallback(() => {
    openScheduler(
      map(routeTemplateIds, (id: number) => ({ id, scheduledDay: moment(event.start).day() })),
      event.start,
    );
  }, [event, openScheduler, routeTemplateIds]);

  return (
    <CalendarEventWrapper isSelected={!!selectedDate && moment(selectedDate).isSame(event.start, 'day')}>
      <CalendarEventBody>
        {routeSection && (
          <CalendarEventExpandableSection
            title={translate('routes.routes')}
            sections={routeSection}
            hasTotal
            isOpen={openedSections.routeSection}
            toggleSection={() => toggleSection('routeSection')}
          />
        )}
        {vehicleSection && (
          <CalendarEventExpandableSection
            title={translate('vehicles.vehicles')}
            sections={vehicleSection}
            isOpen={openedSections.vehicleSection}
            toggleSection={() => toggleSection('vehicleSection')}
          />
        )}
        {driverSection && (
          <CalendarEventExpandableSection
            title={translate('drivers.drivers')}
            sections={driverSection}
            isOpen={openedSections.driverSection}
            toggleSection={() => toggleSection('driverSection')}
          />
        )}
      </CalendarEventBody>
      <CalendarEventFooter>
        <ButtonSet margin="no" padding="small no no">
          <Button
            size="xSmall"
            margin="no"
            padding="no"
            onClick={() =>
              dispatch(push(createUrl('/routes/route-planner/daily-routes', dateToPass, { date: dateToPass })))
            }
            squaredOnBottom
            squaredOnTop
            text
          >
            <ActionButtonTooltip
              withHoverColor
              size="mLarge"
              icon="calendarSeventeen"
              color="black"
              tooltip="planDailyRoutes"
            />
          </Button>
          {!!size(routeTemplateIds) && !isViewOnlyOrSupport && hasPermission(ROUTES_PLANNER_SCHEDULE) && (
            <Button
              size="xSmall"
              margin="no"
              padding="no"
              onClick={openSchedulerHandler}
              squaredOnBottom
              squaredOnTop
              text
            >
              <ActionButtonTooltip
                withHoverColor
                size="mLarge"
                icon="calendarPlus"
                color="black"
                tooltip="scheduleReoccurringRoutes"
              />
            </Button>
          )}
          {!isViewOnly && (
            <Button
              size="xSmall"
              margin="no"
              padding="no"
              onClick={() => {
                openAddJobModal(undefined, undefined, dateToPass);
              }}
              squaredOnBottom
              squaredOnTop
              text
            >
              <ActionButtonTooltip
                withHoverColor
                size="mLarge"
                icon="plusSquared"
                tooltip="addJob"
                color="black"
                customViewBox="0 0 32 27"
              />
            </Button>
          )}
          <Button
            size="xSmall"
            margin="no"
            padding="no"
            onClick={() =>
              dispatch(
                push(
                  createUrl('/routes/route-planner/reoccurring-routes', undefined, {
                    dayOfServiceId: moment(event.start).day() === 0 ? 7 : moment(event.start).day(),
                    routeStatusesIds: 1, // default to active routes
                  }),
                ),
              )
            }
            squaredOnBottom
            squaredOnTop
            text
          >
            <ActionButtonTooltip
              withHoverColor
              size="mLarge"
              icon="calendarReload"
              color="black"
              tooltip="planReoccurringRoutes"
            />
          </Button>
        </ButtonSet>
      </CalendarEventFooter>
    </CalendarEventWrapper>
  );
};

const CalendarEvent = ({ selectedDate, openAddJobModal, openScheduler }: CalendarEventProps) => {
  return (props: any) => (
    <CalendarEventInner
      {...props}
      openScheduler={openScheduler}
      openAddJobModal={openAddJobModal}
      selectedDate={selectedDate}
    />
  );
};
export default CalendarEvent;
