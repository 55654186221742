import { http } from 'src/core/services/http';
import { FuelTicket } from '../interfaces/FuelTicket';

export const loadFuelTicket = (fuelTicketId: number) =>
  http.get(`/fuelTicket/${fuelTicketId}`).then(response => response.data);

export const createFuelTicket = (routeId: number, fuelTicket: FuelTicket) =>
  http.post('/fuelTicket/?routeId=' + routeId, fuelTicket).then(response => response.data);

export const updateFuelTicket = (fuelTicket: FuelTicket) =>
  http.put(`/fuelTicket/${fuelTicket.id}`, fuelTicket).then(response => response.data);

export const uploadFuelTicketImage = async (file: File, routeId: number) => {
  const formData = new FormData();
  const dataObject = JSON.stringify([{ RouteId: routeId, imageSourceTypeId: 1 }]);
  formData.append('Image', file);
  formData.append('data', dataObject);
  const response = await http.post('images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const linkImageToFuelTicket = async (imageId: number | null, fuelTicketId: number) => {
  const response = http.post(`/fuelTicket/${fuelTicketId}/addImage?imageId=${imageId}`);
  return response;
};

export const deleteFuelTicket = (fuelTicketId: number) => http.delete(`/fuelTicket/${fuelTicketId}`);

export const loadFuelTicketUnitOfMeasureValues = () =>
  http.get('/common/systemTypes/fuelingTicketUnitOfMeasureTypeValues').then(response => response.data);

export const loadFuelTickets = (routeId: number, routeLocationId?: number) =>
  http.get(`routes/${routeId}/fuelTickets`, { params: { routeLocationId } }).then(response => response.data);

export const loadFuelingStations = (vendorId: number) =>
  http.get(`vendors/${vendorId}/fuelingStations`).then(response => response.data);
