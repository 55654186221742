import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { size } from 'lodash-es';

import { AppState } from '../../../store';
import { Panel, PanelSection, TableRow, TableCell } from '../../../core/components/styled';
import { Table } from '../../../core/components';
import { getLastUrlSelector } from '../../../core/ducks';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
  PageBackButton,
  PageBackButtonIcon,
} from '../../../common/components/styled';
import { decimal } from '../../../utils/services/formatter';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { WASTE_AUDIT_INSIGHTS, EXCEPTIONS } from '../../constants';
import {
  getChartType,
  getChartData,
  getChartOptions,
  shouldDisplayChartPlaceholder,
  getChartPlaceholderText,
} from '../../services/insightChart';
import { ChartComponents } from './insightPageSections/InsightCard';
import { dashboardActiveCategorySelector } from '../../services/dashboardActiveCategorySelector';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { InsightPieChartPlaceholder, InsightPieChartPlaceholderTooltip } from '../styled';
import { loadReportingDataTrends, resetReportingDataTrends } from '../../ducks';
import { ReportingDataTrendsForm } from '../forms';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface ReportingDataTrendsRowProps {
  name: string;
  instanceCount: number;
  percentage: number;
}

const ReportingDataTrendsRow: React.FC<ReportingDataTrendsRowProps> = ({ name, instanceCount, percentage }) => (
  <TableRow>
    <TableCell width="50%">{name}</TableCell>
    <TableCell width="25%">{instanceCount} </TableCell>
    <TableCell width="25%">{`${percentage} %`}</TableCell>
  </TableRow>
);

interface ReportingDataTrendsPageProps extends RouteComponentProps {
  loadReportingDataTrends: DuckFunction<typeof loadReportingDataTrends>;
  previousPageUrl: string;
  push: any;
  reportingDataTrends: any;
  resetReportingDataTrends: DuckAction<typeof resetReportingDataTrends>;
  vendorId: number;
}

interface OnSubmitProps {
  dateRange: { from: string; to: string };
  vehicleTypeId: number;
  daysOfOperation: any[];
}

class ReportingDataTrendsPage extends PureComponent<ReportingDataTrendsPageProps> {
  componentDidUpdate(prevProps: ReportingDataTrendsPageProps) {
    const { loadReportingDataTrends, location, vendorId } = this.props;

    if (prevProps.location.search !== location.search) {
      const { startDate, endDate, reportType, vehicleTypeId, daysOfOperation } = getQueryParams(location.search);

      loadReportingDataTrends(vendorId, reportType, startDate, endDate, vehicleTypeId, daysOfOperation);
    }
  }

  componentWillUnmount() {
    this.props.resetReportingDataTrends();
  }

  onSubmit = ({ dateRange: { from, to }, vehicleTypeId, daysOfOperation }: OnSubmitProps) => {
    const { push, location } = this.props;
    push(
      createUrl(location.pathname, location.search, { startDate: from, endDate: to, vehicleTypeId, daysOfOperation }),
    );
  };

  render() {
    const { reportingDataTrends, previousPageUrl } = this.props;
    const { reportType, reportCategory } = reportingDataTrends;

    const ChartComponent = ChartComponents[getChartType(reportType)];

    const reportingDataTrendsTableCells = [
      { name: 'name', label: reportingDataTrends.unit, width: '50%', sortable: true },
      { name: 'instanceCount', label: translate('insights.instances'), width: '25%', sortable: true },
      { name: 'percentage', label: translate('insights.percentageOfTotal'), width: '25%', sortable: true },
    ];

    const displayChartPlaceholder = shouldDisplayChartPlaceholder(reportingDataTrends);
    const chartPlaceholderText = getChartPlaceholderText(reportingDataTrends.reportType);

    const reportingDataTrendsTableRows =
      reportCategory === WASTE_AUDIT_INSIGHTS && reportType === EXCEPTIONS
        ? reportingDataTrends.insightsData.map(({ name, technicalName, instanceCount, value }: any) => ({
            name:
              reportType === EXCEPTIONS
                ? translate(createTranslationKey(technicalName, 'routes.wasteAuditStatuses'))
                : name,
            instanceCount,
            percentage: reportType === EXCEPTIONS ? value : Number(decimal(value)) * 100,
          }))
        : [];

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to={previousPageUrl}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle>{translate(createTranslationKey(reportType, 'insights.reportTypes'))}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>

        <Panel>
          <ReportingDataTrendsForm onSubmit={this.onSubmit} />

          <PanelSection padding="sMedium" centered withBorder>
            {!displayChartPlaceholder && (
              <ChartComponent data={getChartData(reportingDataTrends)} options={getChartOptions(reportingDataTrends)} />
            )}

            {!!displayChartPlaceholder && (
              <InsightPieChartPlaceholder size="large">
                <InsightPieChartPlaceholderTooltip>{chartPlaceholderText}</InsightPieChartPlaceholderTooltip>
              </InsightPieChartPlaceholder>
            )}
          </PanelSection>

          {reportCategory === WASTE_AUDIT_INSIGHTS && reportType === EXCEPTIONS && (
            <PanelSection withBorder>
              {!!size(reportingDataTrendsTableRows) && (
                <Table
                  cells={reportingDataTrendsTableCells}
                  rows={reportingDataTrendsTableRows}
                  rowComponent={ReportingDataTrendsRow}
                />
              )}
            </PanelSection>
          )}
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const activeCategories = (dashboardActiveCategorySelector as any)(state.insights.insightDashboardPreferences);

  return {
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    reportingDataTrends: state.insights.reportingDataTrends.reportingDataTrends,
    previousPageUrl: activeCategories[WASTE_AUDIT_INSIGHTS]
      ? (getLastUrlSelector as any)(state.core, '/insights/wasteaudit')
      : (getLastUrlSelector as any)(state.core, '/insights/insightsfleet'),
  };
};

const mapDispatchToProps = { loadReportingDataTrends, resetReportingDataTrends, push };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingDataTrendsPage));
