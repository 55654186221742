import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { loadUnitOfMeasureTypes } from 'src/common/ducks';
import { ModalProps } from 'src/core/components/Modal';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { Rate } from 'src/customers/interfaces/Services';
import { RateCodeFormValues } from './RateCodeModal';
import { RateCodeModal } from '.';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TableCell } from 'src/core/components/Table';
import { TypedResolver } from 'src/core/components';
import * as Ducks from 'src/customers/ducks/service';

interface Props extends ModalProps {
  closeModal: () => void;
  formProps: {
    customerName?: string | undefined;
    location?: string | undefined;
    tableCells?: TableCell[];
    workOrderId?: number | string;
    workOrderDate?: StringOrDate;
  };
  getNewRate: () => void;
  initialValues: { rates: Rate[] };
  onSubmit: (data: RateCodeFormValues) => void;
  serviceTypeId?: number;
}

const RateCodeModalResolver: FC<Props> = ({
  closeModal,
  formProps,
  getNewRate,
  initialValues,
  onSubmit,
  serviceTypeId,
}) => {
  const dispatch = useDispatch();

  const loadDependencies = async () => {
    const dependencies: Promise<unknown>[] = [];
    dependencies.push(Ducks.loadRateTypesByServiceType(serviceTypeId)(dispatch), loadUnitOfMeasureTypes()(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={RateCodeModal}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      redirectOnError="/finance/billing"
      successProps={{ closeModal, formProps, getNewRate, initialValues, onSubmit }}
    />
  );
};

export default RateCodeModalResolver;
