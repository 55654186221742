export const mapId = 'mapGLWrapper';
export const noScrollTrigger = 'noScrollTrigger';

export const scrollToTopOfModal = (modalId: string) => {
  const modal = document.getElementById(modalId);
  modal?.scrollTo(0, 0);
};

export const getWindowShouldScroll = (sourceTrigger: EventTarget) =>
  (sourceTrigger as any).closest(`.${noScrollTrigger}`);

export const scrollToTopOfMap = () => {
  const element = document.getElementById(mapId);
  const topOffset =
    element && element.getBoundingClientRect ? element.getBoundingClientRect().top + window.pageYOffset : 0;

  window.scrollTo({
    top: topOffset,
    behavior: 'smooth',
  });
};
