import { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';

import { FormGroup, FormLabel, FormError, TextArea as FormTextArea } from './styled';

interface Props extends WrappedFieldProps {
  isLoading: boolean;
  placeholder: string;
  label: string;
  disabled: boolean;
  margin: string;
  scrollYVisible?: boolean;
  maxLength?: number;
  rows?: number;
  noTransform?: boolean;
}

const TextArea: FC<Props> = ({
  input,
  meta: { asyncValidating, submitFailed, error },
  placeholder,
  label,
  disabled,
  isLoading,
  margin,
  noTransform,
  ...props
}) => (
  <FormGroup hasValue={input.value} isLoading={isLoading || asyncValidating} margin={margin}>
    {!!label && <FormLabel noTransform={noTransform}>{label}</FormLabel>}
    <FormTextArea {...input} placeholder={placeholder} disabled={disabled} {...props} />

    {submitFailed && error && <FormError>{error}</FormError>}
  </FormGroup>
);

export default TextArea;
