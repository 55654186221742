import { map } from 'lodash-es';

import { ContactType } from 'src/common/interfaces/ContactType';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

const transformLoadContactTypes = (contactTypes: ContactType[]) =>
  map(contactTypes, contactType => ({
    ...contactType,
    name: translate(createTranslationKey(contactType.technicalName, 'common.contactTypes')),
  }));

export default transformLoadContactTypes;
