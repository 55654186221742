import { map, filter } from 'lodash-es';

const transformLoadWasteAuditConfiguration = (wasteAuditConfigurations: any) =>
  map(
    wasteAuditConfigurations,
    ({ vendorWasteAuditConfigurationTypes, vendorWasteAuditConfigurationStatusTypes, ...wasteAuditConfiguration }) => ({
      ...wasteAuditConfiguration,
      vendorWasteAuditConfigurationTypes: filter(
        vendorWasteAuditConfigurationTypes,
        ({ technicalName }) => !!technicalName,
      ),
      vendorWasteAuditConfigurationStatusTypes: filter(
        vendorWasteAuditConfigurationStatusTypes,
        ({ technicalName }) => !!technicalName,
      ),
    }),
  );

export default transformLoadWasteAuditConfiguration;
