import React from 'react';

import { uploadRoutesFile, downloadSampleRouteUploadTemplate } from '../../services/dispatchBoardRouteUploaderModal';
import RecordsUploaderModal, { RecordsUploaderModalProps } from '../../../common/components/RecordsUploaderModal';

type Props = Omit<
  RecordsUploaderModalProps,
  'parentTranslationsPath' | 'downloadSample' | 'uploadFile' | 'showUploadingMessageOnlyOnLoader'
>;

const RouteUploaderModal: React.FC<Props> = props => (
  <RecordsUploaderModal
    showUploadingMessageOnlyOnLoader
    parentTranslationsPath="dispatchBoard.routeUploader"
    downloadSample={downloadSampleRouteUploadTemplate}
    uploadFile={uploadRoutesFile}
    {...props}
  />
);

export default RouteUploaderModal;
