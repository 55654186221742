import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import GeneralSettingsForm from '../../forms/GeneralSettingsForm';
import { loadOpen311Configuration, updateServiceConfiguration } from 'src/vendors/ducks/open311Settings';
import translate from 'src/core/services/translate';

export default function GeneralSettingsSection() {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { open311Configuration } = useSelector((state: AppState) => state.vendors.open311Settings);

  const open311ConfigurationId = open311Configuration?.id;
  const initialValues = open311Configuration;

  const handleSubmit = (formData: any) => {
    updateServiceConfiguration(
      formData,
      open311ConfigurationId,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.open311.alertMessages.serviceConfigurationSaved'));
        loadOpen311Configuration(vendorId)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.open311.alertMessages.serviceConfigurationSaved'));
      });
  };

  return <GeneralSettingsForm onSubmit={handleSubmit} initialValues={initialValues}></GeneralSettingsForm>;
}
