import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { RoutePlannerRoutesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { RoutePlannerRoute } from 'src/routes/interfaces/routePlanner/RoutePlannerRoute';
import {
  loadRoutePlannerRoutes as doLoadRoutePlannerRoutes,
  assignDriverToRoute as doAssignDriverToRoute,
  removeDriverFromRoute as doRemoveDriverFromRoute,
  assignVehicleToRoute as doAssignVehicleToRoute,
  removeVehicleFromRoute as doRemoveVehicleFromRoute,
  bulkDeleteRoutes as doBulkDeleteRoutes,
} from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerRoutes/START_LOAD';
const COMPLETE_LOAD = 'routePlannerRoutes/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerRoutes/FAIL_LOAD';
const START_SAVE = 'routePlannerRoutes/START_SAVE';
const COMPLETE_SAVE = 'routePlannerRoutes/COMPLETE_SAVE';
const FAIL_SAVE = 'routePlannerRoutes/FAIL_SAVE';
const START_BULK_DELETE = 'routePlannerRoutes/START_BULK_DELETE';
const COMPLETE_BULK_DELETE = 'routePlannerRoutes/COMPLETE_BULK_DELETE';
const FAIL_BULK_DELETE = 'routePlannerRoutes/FAIL_BULK_DELETE';
const RESET = 'routePlannerRoutes/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  routes: RoutePlannerRoute[];
}

const initialState: State = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  routes: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routes: action.routes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routes: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_BULK_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routes: RoutePlannerRoute[]) => ({
  type: COMPLETE_LOAD,
  routes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startBulkDelete = () => ({
  type: START_BULK_DELETE,
});

const completeBulkDelete = () => ({
  type: COMPLETE_BULK_DELETE,
});

const failBulkDelete = () => ({
  type: FAIL_BULK_DELETE,
});

export const resetRoutePlannerRoutes = () => ({
  type: RESET,
});

export const loadRoutePlannerRoutes = (params: RoutePlannerRoutesPayload) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadRoutePlannerRoutesPromise = doLoadRoutePlannerRoutes(params);
  loadRoutePlannerRoutesPromise
    .then((routes: RoutePlannerRoute[]) => dispatch(completeLoad(routes)))
    .catch(() => dispatch(failLoad()));
  return loadRoutePlannerRoutesPromise;
};

export const assignDriverToRoute = (vendorId: number, routeId: number, driverId: number) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const assignDriverToRoutePromise = doAssignDriverToRoute(vendorId, routeId, driverId);
  assignDriverToRoutePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return assignDriverToRoutePromise;
};

export const removeDriverFromRoute = (vendorId: number, routeId: number, driverId: number) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const removeDriverFromRoutePromise = doRemoveDriverFromRoute(vendorId, routeId, driverId);
  removeDriverFromRoutePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return removeDriverFromRoutePromise;
};

export const assignVehicleToRoute = (vendorId: number, routeId: number, vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const assignVehicleToRoutePromise = doAssignVehicleToRoute(vendorId, routeId, vehicleId);
  assignVehicleToRoutePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return assignVehicleToRoutePromise;
};

export const removeVehicleFromRoute =
  (vendorId: number, routeId: number, vehicleId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());

    const removeVehicleFromRoutePromise = doRemoveVehicleFromRoute(vendorId, routeId, vehicleId);
    removeVehicleFromRoutePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return removeVehicleFromRoutePromise;
  };

export const bulkDeleteRoutes = (vendorId: number, routeIds: number[]) => (dispatch: Dispatch) => {
  dispatch(startBulkDelete());

  const bulkDeleteRoutePlannerRoutesPromise = doBulkDeleteRoutes(vendorId, routeIds);
  bulkDeleteRoutePlannerRoutesPromise
    .then(() => dispatch(completeBulkDelete()))
    .catch(() => dispatch(failBulkDelete()));
  return bulkDeleteRoutePlannerRoutesPromise;
};
