import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { push } from 'connected-react-router';
import { findIndex, map, omit, size } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { ActionButtonTooltip, Table } from 'src/core/components';
import { assignChargesToBill } from 'src/finance/services/unbilledCharges';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  Panel,
  PanelFooter,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
  Text,
} from 'src/core/components/styled';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DRAFT, BILLED, PAYMENT_STATUS_PAID } from 'src/finance/constants/invoiceStatuses';
import { OverdueContainer } from '../../styled/BillDetails';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageFooter,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageActions,
  PageTitleContainer,
} from 'src/common/components/styled';
import { Rate } from 'src/finance/interfaces/ServiceRates';
import { rateCodeEditorModalId, RateCodeFormValues } from 'src/finance/components/modals/RateCodeModal/RateCodeModal';
import { RateCodeModalResolver } from 'src/finance/components/modals/RateCodeModal';
import { resetBillDetails, resetUnbilledCharges } from 'src/finance/ducks';
import { resetRateCodes } from 'src/common/ducks/rateCodes';
import { resetRateTypesByWorkOrder, resetRateTypesByServiceContractId } from 'src/customers/ducks/service';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { SelectedBillsToAssign, SelectedBillsToUnassign } from 'src/finance/interfaces/BillDetails';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { TableCell } from 'src/core/components/Table';
import { UnbilledCharge } from 'src/finance/interfaces/UnbilledCharges';
import { useSelector } from 'src/core/hooks/useSelector';
import * as BilledChargesDucks from 'src/finance/ducks/billDetails';
import * as Ducks from 'src/finance/ducks/serviceRates';
import * as UnbilledChargesDucks from 'src/finance/ducks/unbilledCharges';
import BillDetailsTableRow from './billingPageSections/BillDetailsTableRow';
import BillHistoryModalResolver from '../../modals/BillHistoryModalResolver';
import confirm from 'src/core/services/confirm';
import LabeledDataView from 'src/core/components/LabeledDataView';
import PaymentInfoTableRow from './billingPageSections/PaymentInfoTableRow';
import SendBillModalResolver from '../../modals/SendBillModalResolver';
import translate from 'src/core/services/translate';
import UnbilledChargesTable from './billingPageSections/UnbilledChargesTable';

const paymentInfoCells: TableCell[] = [
  {
    name: 'paymentNumber',
    label: 'Payment Number',
    width: '12%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'paymentDate',
    label: 'Payment Date',
    width: '12%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'Payment Method',
    width: '12%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'paymentAmount',
    label: 'Payment Amount',
    width: '12%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
];

const billTableCells: TableCell[] = [
  {
    name: 'workOrderId',
    label: translate('finance.workOrder'),
    width: '12%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'serviceContractId',
    label: translate('finance.serviceDetails'),
    width: '41%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'workOrderDescription',
    label: translate('finance.workOrderDescription'),
    width: '19%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'serviceDate',
    label: translate('finance.serviceDate'),
    width: '10%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'totalAmount',
    label: translate('finance.amount'),
    width: '10%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'options',
    label: translate('common.options'),
    width: '8%',
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
];

const BillDetailsPage = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector<number>(currentVendorId);
  const [isAssigningBills, setIsAssigningBills] = useState(false);
  const billDetails = useSelector(state => state.finance.billDetails.billDetails);
  const paymentInfo = useSelector(state => state.finance.billDetails.paymentInfo);
  const unbilledCharges = useSelector(state => state.finance.unbilledCharges.unbilledCharges);
  const rates: Rate[] = useSelector(state => state.finance.serviceRates.rates);
  const isReleasing = useSelector(state => state.finance.billDetails.isReleasingBill);
  const isLoadingBillDetails = useSelector(
    state =>
      state.finance.billDetails.billDetailsLoading ||
      state.finance.billDetails.isUnassigningBilledCharges ||
      state.finance.billDetails.isExportingBilledCharges ||
      state.finance.unbilledCharges.isAssigningToBill,
  );
  const isLoadingUnbilledCharges = useSelector(
    state =>
      state.finance.unbilledCharges.unbilledChargesLoading ||
      state.finance.billDetails.isUnassigningBilledCharges ||
      state.finance.unbilledCharges.isAssigningToBill,
  );

  const isLoadingBillPreviewPDF = useSelector(state => state.finance.billDetails.isLoadingBillPreviewPDF);

  const [selectedBillsToUnassign, setSelectedBillsToUnassign] = useState([] as SelectedBillsToUnassign[]);
  const [selectedBillsToAssign, setSelectedBillsToAssign] = useState([] as SelectedBillsToAssign[]);
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isSendBillModalOpen, setIsSendBillModalOpen] = useState(false);
  const [isBillHistoryModalOpen, setIsBillHistoryModalOpen] = useState(false);

  const isBillReleased = billDetails.billStatusId === BILLED;
  const billIsInPreview = billDetails.billStatusId === DRAFT;
  const billStatusIsPaid = billDetails.paymentStatusId === PAYMENT_STATUS_PAID;

  const reloadBills = () => {
    BilledChargesDucks.loadBillDetails(vendorId, billDetails.id)(dispatch);
    UnbilledChargesDucks.loadUnbilledChargesList(vendorId, billDetails.id, true)(dispatch);
  };

  const closeSendBillModal = () => setIsSendBillModalOpen(false);
  const closeBillHistoryModal = () => setIsBillHistoryModalOpen(false);

  const checkBill = (
    workOrderId: number,
    invoiceId: number,
    vendorLocationId: number,
    serviceDate: StringOrDate,
    headerId: number,
    invoiceSubTotal: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();

    const updatedSelectedBillsToUnassign = [...selectedBillsToUnassign];

    const indexUnbill = findIndex(
      updatedSelectedBillsToUnassign,
      updatedSelectedBillToUnassign => updatedSelectedBillToUnassign.headerId === headerId,
    );

    if (indexUnbill > -1) {
      updatedSelectedBillsToUnassign.splice(indexUnbill, 1);
    } else {
      updatedSelectedBillsToUnassign.push({
        workOrderId,
        invoiceId,
        vendorLocationId,
        serviceDate,
        headerId,
        invoiceSubTotal,
      });
    }
    setSelectedBillsToUnassign(updatedSelectedBillsToUnassign);
  };

  const selectBillsToAssign = (
    id: number,
    workOrderId: number,
    vendorLocationId: number,
    billDate: StringOrDate,
    invoiceSubTotal: number,
    headerId: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();
    const updatedSelectedBillsToAssign = [...selectedBillsToAssign];
    const indexBill = findIndex(
      updatedSelectedBillsToAssign,
      updatedSelectedBillToassign => updatedSelectedBillToassign.id === id,
    );

    if (indexBill > -1) {
      updatedSelectedBillsToAssign.splice(indexBill, 1);
    } else {
      updatedSelectedBillsToAssign.push({
        workOrderId,
        vendorLocationId,
        billDate,
        invoiceSubTotal,
        id,
        chargeHeaderId: headerId,
      });
    }
    setSelectedBillsToAssign(updatedSelectedBillsToAssign);
  };

  const unassignBilledCharges = async () => {
    if (!(await confirm(translate('finance.alertMessages.confirmRemoveBill')))) return;
    BilledChargesDucks.unassignBilledCharges(
      vendorId,
      billDetails.id,
      selectedBillsToUnassign,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('finance.alertMessages.billRemoved'));
        discardSelectedCharges();
        reloadBills();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.billRemoveError'),
          'finance.alertMessages',
        );
      });
  };
  const assignBilledCharges = async () => {
    if (!(await confirm(translate('finance.alertMessages.confirmAssignBills')))) return;
    // needs header id to be unique. when comparing which boxes are checked.
    setIsAssigningBills(true);
    assignChargesToBill(selectedBillsToAssign, billDetails.id)
      .then(() => {
        setIsAssigningBills(false);
        createSuccessNotification(translate('finance.alertMessages.billsAdded'));
        discardSelectedAssignedCharges();
        reloadBills();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.billAddError'),
          'finance.alertMessages',
        );
        setIsAssigningBills(false);
      });
    // might be possible to make this code DRY as well and use one api instead of two.

    discardSelectedAssignedCharges();
  };

  // MAKE THIS CODE MORE DRY. repeated above.
  const unassignBilledCharge = async (
    workOrderId: number,
    invoiceId: number,
    vendorLocationId: number,
    serviceDate: StringOrDate,
    headerId: number,
    invoiceSubTotal: number,
    e: any,
  ) => {
    if (!(await confirm(translate('finance.alertMessages.confirmRemoveBill')))) return;
    const bill = [
      {
        workOrderId,
        invoiceId,
        vendorLocationId,
        serviceDate,
        headerId,
        invoiceSubTotal,
      },
    ];
    BilledChargesDucks.unassignBilledCharges(
      vendorId,
      billDetails.id,
      bill,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('finance.alertMessages.billRemoved'));
        discardSelectedCharges();
        reloadBills();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.billRemoveError'),
          'finance.alertMessages',
        );
      });
  };

  const discardSelectedCharges = () => {
    setIsUnassignModalOpen(false);
    setSelectedBillsToUnassign([]);
  };

  const discardSelectedAssignedCharges = () => {
    setIsAssignModalOpen(false);
    setSelectedBillsToAssign([]);
  };

  const confirmReleaseBill = async (error: any) => {
    if (!(await confirm(error.response?.data?.message))) {
      return false;
    }
    return true;
  };

  const releaseBill = () => {
    BilledChargesDucks.releaseBill(billDetails.id)(dispatch)
      .then(() => {
        createSuccessNotification(translate('finance.alertMessages.billReleased'));
        reloadBills();
      })
      .catch(async e => {
        if (e.response?.data?.code === 'ConfirmationRequired') {
          const confirmation = await confirmReleaseBill(e);
          if (confirmation) {
            BilledChargesDucks.releaseBill(
              billDetails.id,
              confirmation,
            )(dispatch)
              .then(() => {
                createSuccessNotification(translate('finance.alertMessages.billReleased'));
                reloadBills();
              })
              .catch(error => {
                createErrorNotificationIncludingTechnicalMessage(
                  e,
                  translate('finance.alertMessages.billReleaseError'),
                  'finance.alertMessages',
                );
              });
          }
        } else {
          createErrorNotificationIncludingTechnicalMessage(
            e,
            translate('finance.alertMessages.billReleaseError'),
            'finance.alertMessages',
          );
        }
      });
  };

  const billedChargesOptions = map(billDetails.billedCharges, bill => {
    return {
      ...bill,
      isChecked: selectedBillsToUnassign.some(selectedBill => selectedBill.headerId === bill.headerId),
    };
  });

  const unbilledChargesOptions = unbilledCharges.map((bill: UnbilledCharge, index: number) => {
    return {
      ...bill,
      id: index,
      isChecked: selectedBillsToAssign.some(selectedBill => selectedBill.id === index),
    };
  });

  const virtualizedPropsBilledCharges = {
    height: Math.min(size(billDetails.billedCharges) * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  useEffect(
    () => () => {
      dispatch(resetBillDetails());
      dispatch(resetUnbilledCharges());
    },
    [dispatch],
  );

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const handleRowClick = (bill: any) => setSelectedRow(bill);

  const handleModalClose = () => {
    setSelectedRow(null);
    dispatch(resetRateTypesByWorkOrder());
    dispatch(resetRateTypesByServiceContractId());
    dispatch(resetRateCodes());
  };

  // modal doesnt open because headerid is null
  const modalOpen = useMemo(() => !!selectedRow && !!selectedRow.headerId, [selectedRow]);

  const onSubmitRateCodeForm = (data: RateCodeFormValues) => {
    scrollToTopOfModal(rateCodeEditorModalId);

    Ducks.saveServiceRates(
      selectedRow.headerId,
      data.rates.map(rate => ({ ...omit(rate, 'id') })), //strip ID property, only used on UI
    )(dispatch)
      .then(() => {
        reloadBills();
        handleModalClose();
        createSuccessNotification(translate('common.alertMessages.successMessage'));
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('common.alertMessages.errorMessage'),
          'finance.alertMessages',
        );
      });
  };

  const handleCollectPayment = (invoiceId: number) => {
    // try to add a new parameter and call it return to billDetails?
    dispatch(push(`/finance/pay-now/${invoiceId}?returnToBillingDetails=true`));
  };

  return (
    <PageContent>
      <PageHeader withSubTitle>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={() => dispatch(push('/finance/billing'))}>
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle id="bill-details-title">{translate('finance.billDetails')}</PageTitle>
          </PageTitleContainer>
          <PageSubtitle id="bill-details-subtitle">
            {billDetails.id ? `#${billDetails.id}` : '–'}
            <OverdueContainer>
              {!!billDetails.isOverdue && (
                <ActionButtonTooltip
                  size="medium"
                  icon="overdue"
                  tooltip="pastDue"
                  margin="no"
                  customViewBox="0 0 25 25"
                />
              )}
            </OverdueContainer>
          </PageSubtitle>
        </PageDetails>
        <PageActions>
          {billDetails && (
            <ButtonSet align="right" margin="no no small no">
              {billDetails.billStatusId !== DRAFT && (
                <Button
                  type="button"
                  color="primary"
                  margin="no small no no"
                  line
                  id="bill-details-preview-button"
                  onClick={() => setIsSendBillModalOpen(true)}
                >
                  {translate('finance.sendBill')}
                </Button>
              )}
              <Button
                type="button"
                color="primary"
                margin="no small no no"
                line
                id="bill-details-preview-button"
                disabled={!size(billDetails.billedCharges) || isLoadingBillPreviewPDF}
                onClick={() => BilledChargesDucks.previewBilledCharges(billDetails.id)(dispatch)}
              >
                {billDetails.billStatusId === BILLED ? translate('finance.viewPDF') : translate('finance.previewPDF')}
              </Button>
              <Button
                type="button"
                color="primary"
                margin="no small no no"
                line
                id="bill-details-preview-button"
                onClick={() => setIsBillHistoryModalOpen(true)}
              >
                {translate('finance.billHistory')}
              </Button>
            </ButtonSet>
          )}
        </PageActions>
      </PageHeader>
      <Panel padding="no">
        {billDetails && (
          <PanelSection withBorder padding="medium" isLoading={isLoadingBillDetails || isReleasing} vertical>
            <Grid>
              <GridColumn size="3/12" padding="no small no no">
                <LabeledDataView label={translate('finance.customerName')} value={billDetails.customerName || '–'} />
              </GridColumn>
              <GridColumn size="3/12" padding="no small no no">
                <LabeledDataView label={translate('finance.location')} value={billDetails.location || '–'} />
              </GridColumn>
              <GridColumn size="4/12" padding="no small no no">
                <LabeledDataView
                  label={translate('finance.locationAddress')}
                  value={billDetails.locationAddress || '–'}
                />
              </GridColumn>
              <GridColumn size="2/12" padding="no small no no">
                <LabeledDataView label={translate('common.status')} value={billDetails.billStatusName} />
              </GridColumn>
            </Grid>
            <Grid margin="small no no no">
              <GridColumn size="3/12" padding="no small no no">
                <LabeledDataView
                  label={translate('finance.applyChecks.totalCharges')}
                  value={billDetails.invoiceTotal ? `$${billDetails.invoiceTotal}` : '$0'}
                />
              </GridColumn>
              {isBillReleased && (
                <GridColumn size="3/12" padding="no small no no">
                  <LabeledDataView
                    label={translate('finance.applyChecks.billBalance')}
                    value={billDetails.balance ? `$${billDetails.invoiceTotal}` : '$0'}
                  />
                </GridColumn>
              )}
              <GridColumn size="9/12" padding="no" align="right" verticalAlign="center">
                {!isBillReleased && (
                  <Button type="button" color="primary" size="xLarge" onClick={releaseBill}>
                    {translate('finance.release')}
                  </Button>
                )}
              </GridColumn>
            </Grid>
          </PanelSection>
        )}
        {billStatusIsPaid && (
          <PanelSectionGroup padding="no no medium" width="100%" isLoading={isReleasing}>
            <PanelSection vertical padding="no medium">
              <PanelSectionTitle margin="medium no">{translate('finance.paymentInfo')}</PanelSectionTitle>
              {!!size(paymentInfo) ? (
                <Table
                  cells={paymentInfoCells}
                  rowComponent={PaymentInfoTableRow}
                  rows={paymentInfo}
                  scrollMarker
                  virtualized
                  virtualizedProps={virtualizedPropsBilledCharges}
                />
              ) : (
                <Message padding="sMedium">{translate('finance.noPaymentInfo')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        )}
        <PanelSectionGroup padding="no" width="100%">
          <PanelSection noBoxShadow padding="medium no no medium">
            <GridColumn size="4/12" padding="no no small no">
              <PanelSectionTitle margin="no">{translate('finance.includedCharges')}</PanelSectionTitle>
            </GridColumn>
            {billDetails.billStatusId === BILLED && (
              <GridColumn size="8/12">
                <ButtonSet align="right" margin="no no small no">
                  <Button
                    type="button"
                    color="primary"
                    margin="no small no no"
                    id="bill-details-collect-payment-button"
                    onClick={() => handleCollectPayment(billDetails.id)}
                    disabled={billStatusIsPaid || billDetails.balance <= 0}
                  >
                    {translate('finance.collectPayment')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            )}
          </PanelSection>
          <PanelSection padding="no medium large" withBorder isLoading={isLoadingBillDetails || isReleasing} vertical>
            {!!size(billDetails.billedCharges) ? (
              <Table
                cells={billTableCells}
                rowComponent={BillDetailsTableRow}
                rowProps={{
                  checkBill: checkBill,
                  isBillReleased,
                  onClick: handleRowClick,
                  unassignBilledCharge: unassignBilledCharge,
                }}
                rows={billedChargesOptions}
                scrollMarker
                virtualized
                virtualizedProps={virtualizedPropsBilledCharges}
              />
            ) : (
              <Message padding="no no small no">{translate('finance.noBilledCharges')}</Message>
            )}
            {!!selectedBillsToUnassign.length && (
              <GridColumn margin="auto">
                <Button
                  type="button"
                  color="primary"
                  margin="small"
                  id="bill-details-unassign-button"
                  onClick={() => setIsUnassignModalOpen(true)}
                >
                  {`${translate('finance.unassign')} (${
                    selectedBillsToUnassign.length
                  } - $${selectedBillsToUnassign.reduce((sum, bill) => sum + bill.invoiceSubTotal, 0)})`}
                </Button>
              </GridColumn>
            )}
          </PanelSection>
          {!!selectedBillsToUnassign.length && isUnassignModalOpen && (
            <PanelSection centered padding="no">
              <PageFooter>
                <PanelFooter>
                  <Text block size="xxLarge" weight="medium">
                    {selectedBillsToUnassign.length}{' '}
                    {selectedBillsToUnassign.length > 1
                      ? translate('finance.chargesSelected')
                      : translate('finance.chargeSelected')}
                    <Text size="small" block weight="light" color="primary">
                      {translate('finance.removeChargesApproval')}
                    </Text>
                  </Text>
                  <ButtonSet margin="no">
                    <Button
                      id="discard-selected-button"
                      color="primary"
                      onClick={discardSelectedCharges}
                      margin="no small no no"
                      line
                    >
                      {translate('finance.discard')}
                    </Button>
                    <Button id="remove-charges-button" color="primary" onClick={unassignBilledCharges} margin="no">
                      {translate('finance.removeCharges')}
                    </Button>
                  </ButtonSet>
                </PanelFooter>
              </PageFooter>
            </PanelSection>
          )}
        </PanelSectionGroup>
        {billIsInPreview && (
          <PanelSectionGroup
            padding="no no medium"
            width="100%"
            isLoading={isLoadingUnbilledCharges || isReleasing || isAssigningBills}
          >
            <PanelSection vertical padding="no medium">
              <PanelSectionTitle margin="medium no small no">
                {translate('finance.unassignedCharges')}
              </PanelSectionTitle>
              {!!size(unbilledCharges) && billDetails ? (
                <UnbilledChargesTable
                  unbilledCharges={unbilledChargesOptions}
                  reloadBills={reloadBills}
                  hasAssignOption={!isBillReleased}
                  billId={billDetails.id}
                  selectBillsToAssign={selectBillsToAssign}
                  onEditClick={handleRowClick}
                />
              ) : (
                <Message padding="small no">{translate('finance.noUnAssignedCharges')}</Message>
              )}
              {!!selectedBillsToAssign.length && (
                <GridColumn margin="auto">
                  <Button
                    type="button"
                    color="primary"
                    margin="small"
                    id="bill-details-unassign-button"
                    onClick={() => setIsAssignModalOpen(true)}
                  >
                    {`Assign (${selectedBillsToAssign.length} - $${selectedBillsToAssign.reduce(
                      (sum, bill) => sum + bill.invoiceSubTotal,
                      0,
                    )})`}
                  </Button>
                </GridColumn>
              )}
            </PanelSection>
            {!!selectedBillsToAssign.length && isAssignModalOpen && (
              <PanelSection centered padding="no">
                <PageFooter>
                  <PanelFooter>
                    <Text block size="xxLarge" weight="medium">
                      {selectedBillsToAssign.length}{' '}
                      {selectedBillsToAssign.length > 1
                        ? translate('finance.chargesSelected')
                        : translate('finance.chargeSelected')}
                      <Text size="small" block weight="light" color="primary">
                        {translate('finance.addChargesApproval')}
                      </Text>
                    </Text>
                    <ButtonSet margin="no">
                      <Button
                        id="discard-selected-button"
                        color="primary"
                        onClick={discardSelectedAssignedCharges}
                        margin="no small no no"
                        line
                      >
                        {translate('finance.discard')}
                      </Button>
                      <Button id="remove-charges-button" color="primary" onClick={assignBilledCharges} margin="no">
                        {translate('finance.addCharges')}
                      </Button>
                    </ButtonSet>
                  </PanelFooter>
                </PageFooter>
              </PanelSection>
            )}
          </PanelSectionGroup>
        )}
      </Panel>
      {modalOpen && (
        <RateCodeModalResolver
          closeModal={handleModalClose}
          formProps={{
            customerName: billDetails.customerName,
            location: billDetails.location,
            workOrderId: selectedRow.workOrderId,
            workOrderDate: selectedRow.serviceDate,
          }}
          isBillReleased={isBillReleased}
          onSubmit={onSubmitRateCodeForm}
          headerId={selectedRow.headerId}
          initialValues={{
            rates: rates || [],
          }}
          workOrderId={selectedRow.workOrderId}
          serviceContractId={selectedRow.serviceContractId}
        />
      )}
      {isSendBillModalOpen && (
        <SendBillModalResolver
          closeModal={closeSendBillModal}
          billInfo={{
            billId: billDetails.id,
            customerName: billDetails.customerName,
            siteName: billDetails.location,
            siteAddress: billDetails.locationAddress,
            status: billDetails.billStatusName,
          }}
        />
      )}
      {isBillHistoryModalOpen && (
        <BillHistoryModalResolver
          closeModal={closeBillHistoryModal}
          billInfo={{
            billId: billDetails.id,
            customerName: billDetails.customerName,
            siteName: billDetails.location,
            siteAddress: billDetails.locationAddress,
            status: billDetails.billStatusName,
          }}
        />
      )}
    </PageContent>
  );
};

export default BillDetailsPage;
