import React from 'react';
import { useDispatch } from 'react-redux';
import Cookie from 'js-cookie';

import { GridColumn } from '../../../core/components/styled/Grid';
import { HAULER_PROFILE_DIVISION_COOKIE } from '../../constants/HaulerProfileDivisionCookie';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';
import { loadEquipments } from '../../ducks';
import { EquipmentCategory, EquipmentSize } from '../../interfaces/Equipments';
import { deleteEquipmentSize, updateEquipmentSize } from '../../services/equipments';
import { CustomEquipmentSizeField } from './CustomEquipmentSizeField';
import { StandardEquipmentSizeField } from './StandardEquipmentSizeField';

interface Props {
  equipmentCategory: EquipmentCategory;
  equipmentSize: EquipmentSize;
  haulerId: string;
  userName: string;
}

export const EquipmentSizeField: React.FC<Props> = ({ equipmentCategory, equipmentSize, haulerId, userName }) => {
  const dispatch = useDispatch();

  const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);

  const updateHaulerEquipmentSize = (isChecked: boolean, title?: string) => {
    const eqSize = { ...equipmentSize };
    eqSize.isActive = isChecked;
    if (title) {
      eqSize.other = title;
    }
    updateEquipmentSize(
      haulerId,
      eqSize,
      userName,
      equipmentCategory.binSubType,
      equipmentCategory.binSubTypeCategory,
      divisionIdFromCookie,
    ).then(() => dispatch(loadEquipments(haulerId, divisionIdFromCookie)));
  };

  const deleteEquipment = async () => {
    if (
      await confirm(
        translate(
          equipmentSize.isUsedInServiceArea
            ? 'haulerProfile.alertMessages.deleteUsedEquipmentSize'
            : 'haulerProfile.alertMessages.deleteEquipmentSize',
        ),
      )
    ) {
      if (equipmentSize.id) {
        deleteEquipmentSize(haulerId, equipmentSize.id, divisionIdFromCookie).then(() =>
          dispatch(loadEquipments(haulerId, divisionIdFromCookie)),
        );
      }
    }
  };

  const validate = (equipmentTypeName: string) => {
    return !equipmentCategory.binClasses.find(
      e => e.other && e.other.toLowerCase() === equipmentTypeName.toLowerCase(),
    );
  };

  const handleChange = async (checked: boolean, title?: string) => {
    if (!checked && equipmentSize.isActive && equipmentSize.isUsedInServiceArea) {
      if (await confirm(translate('haulerProfile.alertMessages.removeEquipmentSizeWarning'))) {
        updateHaulerEquipmentSize(checked, title);
      }
    } else {
      updateHaulerEquipmentSize(checked, title);
    }
  };

  return (
    <GridColumn size="12/12" padding="small no">
      {!equipmentSize.isOther ? (
        <StandardEquipmentSizeField onChange={handleChange} equipmentSize={equipmentSize} />
      ) : (
        <CustomEquipmentSizeField
          validate={validate}
          onDelete={deleteEquipment}
          onChange={handleChange}
          equipmentSize={equipmentSize}
        />
      )}
    </GridColumn>
  );
};
