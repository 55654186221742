import { sizeMapper } from 'src/utils/styles';
import styled from 'styled-components';

export const RouteSettingsFormRow = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  padding: 0 18px;
  justify-content: space-between;
  min-height: 50px;
  margin: ${props => sizeMapper(props.margin, 'no')};
`;

export const RouteSettingsFormRowHeader = styled(RouteSettingsFormRow)`
  margin-bottom: 0;
  min-height: 30px;

  &:not(:first-child) {
    margin-top: 24px;
  }

  & > span {
    font-weight: bold;
    text-transform: capitalize;
  }
`;

export const RouteSettingsFormRowInputHolder = styled.div`
  flex: 0 0 180px;
`;

export const RouteSettingsFormRadioGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const RouteSettingsFormRadioInstance = styled.div`
  flex: 0 0 20%;
`;

export const RouteSettingsFormSpacer = styled.div`
  height: 60px;
`;
