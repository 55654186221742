import { combineReducers } from 'redux';

import { reducer as businessProfileReducer } from './businessProfile';
import { reducer as businessTypeReducer } from './businessType';
import { reducer as certificationsReducer } from './certifications';
import { reducer as companyInfoReducer } from './companyInfo';
import { reducer as complianceReducer } from './compliance';
import { reducer as contactsReducer } from './contacts';
import { reducer as divisionsReducer } from './divisions';
import { reducer as equipmentsReducer } from './equipments';
import { reducer as filesReducer } from './files';
import { reducer as hoursOfOperationReducer } from './hoursOfOperation';
import { reducer as materialsReducer } from './materials';
import { reducer as serviceAreasReducer } from './serviceAreas';
import { reducer as servicesReducer } from './services';
import { reducer as zipCodeBoundariesReducer } from './zipCodeBoundaries';

export { deleteContact, loadContacts, saveContacts, updateContacts } from './contacts';
export { loadBusinessProfile, saveBusinessProfile } from './businessProfile';
export { loadBusinessType, saveBusinessType, resetBusinessType } from './businessType';
export { loadCertifications, deleteUploadedDocument } from './certifications';
export { loadCompanyInfo, contactTypesSelector, saveCompanyInfo, resetCompanyInfo } from './companyInfo';
export { loadCompliance, uploadSignedDocument, uploadSelfCertification } from './compliance';
export { loadEquipments } from './equipments';
export { loadFiles, saveFile, exportFile, resetFiles } from './files';
export { loadHoursOfOperation, saveHoursOfOperation } from './hoursOfOperation';
export { loadMaterials, saveMaterial } from './materials';
export { loadServiceAreas, loadServiceAreaOptions, saveServiceArea, deleteServiceArea } from './serviceAreas';
export { loadServices, saveServices, resetServices } from './services';
export { loadZipCodeBoundaries } from './zipCodeBoundaries';

export const reducer = combineReducers({
  businessProfile: businessProfileReducer,
  businessType: businessTypeReducer,
  certifications: certificationsReducer,
  companyInfo: companyInfoReducer,
  compliance: complianceReducer,
  contacts: contactsReducer,
  divisions: divisionsReducer,
  equipments: equipmentsReducer,
  files: filesReducer,
  hoursOfOperation: hoursOfOperationReducer,
  materials: materialsReducer,
  serviceAreas: serviceAreasReducer,
  services: servicesReducer,
  zipCodeBoundaries: zipCodeBoundariesReducer,
});
