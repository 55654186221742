import { get, map } from 'lodash-es';

import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

const transformDisposalSummary = (data: any) => ({
  ...data,
  dailyDisposalSummary: map(get(data, 'dailyDisposalSummary', []), disposalSummary => ({
    ...disposalSummary,
    vehicleTypeName: translate(createTranslationKey(disposalSummary.vehicleType, 'vehicles.vehicleTypes')),
    routeType: translate(createTranslationKey(disposalSummary.routeType, 'common.wasteTypes')),
  })),
});

export default transformDisposalSummary;
