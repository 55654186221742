import { httpInsightsReporting } from '../../core/services/http';
import transformLoadRunningVehicleCounts from './transformLoadRunningVehicleCounts';

const loadRunningVehicleCounts = (vendorId: number, date: Date | string, insightGroupIds: any[]) =>
  httpInsightsReporting
    .get(`/vendors/${vendorId}/vendorRunningVehiclesCount`, {
      params: { date, insightGroupIds: insightGroupIds.join(',') },
    })
    .then(response => transformLoadRunningVehicleCounts(response.data));

export default loadRunningVehicleCounts;
