import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';

import {
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  isNavi3FeatureEnabled,
  isTravelPathNavigationFeatureEnabled,
} from 'src/vendors/ducks/features';
import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { loadDisposalFacilities, loadOperationalFacilities } from 'src/fleet/ducks';
import { loadFeatures, loadSnowPlowSettings, loadStreetSweepingSettings } from 'src/vendors/ducks';
import { loadFiltersSettings } from 'src/common/ducks';
import {
  loadGeoFenceFilterOptions,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  loadServiceZones,
  loadTravelPathStatusDetails,
} from 'src/routes/ducks';
import { loadRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { loadStreetNetwork, setIsEditSegmentsModeOn } from 'src/customers/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { PageLoading } from 'src/common/components/styled';
import { removePersistentMapFilters } from 'src/routes/services/persistentMapFilterSettingStorage';
import { Resolver } from 'src/core/components';
import { STREET_SEGMENT_ASSIGNED_TYPES_STATUSES } from 'src/customers/constants/streetNetwork';
import SnowOrSweeperRouteTemplatePage from './SnowOrSweeperRouteTemplatePage';
import store from 'src/store';
import translate from 'src/core/services/translate';

interface TemplateParams {
  routeTemplateId?: string;
}

const batchedLocationsRequesterId = 'snowOrSweeperBatchId';

const SnowOrSweeperRouteTemplatePageResolver: FC<RouteComponentProps<TemplateParams>> = ({
  match: {
    params: { routeTemplateId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);

  const streetSegmentAssignedTypesStatus = STREET_SEGMENT_ASSIGNED_TYPES_STATUSES[0].id;
  const routeId = undefined;
  const routeTemplateIdFormatted = routeTemplateId ? Number(routeTemplateId) : undefined;
  const streetSegmentAssignedStatus = undefined;
  const includeRouteTemplateIds = true;

  const { pathname } = history.location;
  const isSnowPlowRoute = pathname.includes('snow-plow');
  const isStreetSweeperRoute = pathname.includes('street-sweeper');

  useEffect(() => {
    removePersistentMapFilters();
  }, []);

  const loadDependencies = async () => {
    if (!vendorId) Promise.reject();

    dispatch(setIsEditSegmentsModeOn(false));

    const dependencies: Promise<any>[] = [
      loadStreetNetwork({
        vendorId,
        routeId,
        routeTemplateId: routeTemplateIdFormatted,
        includeRouteTemplateIds,
        streetSegmentAssignedStatus,
        streetSegmentAssignedTypesStatus,
      })(dispatch),
      loadServiceZones(vendorId, false)(dispatch),
      loadFeatures(vendorId)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      loadOperationalFacilities(vendorId)(dispatch),
      loadDisposalFacilities(vendorId)(dispatch),
      loadGeoFenceFilterOptions({ vendorId })(dispatch),
    ];

    if (routeTemplateId) {
      const isTemplate = true;
      dependencies.push(loadRouteGeoFence(routeTemplateId, isTemplate)(dispatch));

      const { id } = await loadRouteTemplateOptimized(routeTemplateId)(dispatch);
      if (id) {
        const { total } = await loadRouteTemplateLocationsBatched(batchedLocationsRequesterId, id)(dispatch);
        if (total >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled))
          dependencies.push(loadTravelPathStatusDetails(undefined, id)(dispatch));
      }
    }

    if (userId) {
      dependencies.push(loadFiltersSettings(vendorId, userId)(dispatch));
    }

    const isSnowPlowFeatureEnabled = checkIfSnowPlowIsEnabled(store.getState());
    if (isSnowPlowFeatureEnabled && isSnowPlowRoute) dependencies.push(loadSnowPlowSettings(vendorId)(dispatch));

    const isStreetSweeperFeatureEnabled = checkIfStreetSweepingIsEnabled(store.getState());
    if (isStreetSweeperFeatureEnabled && isStreetSweeperRoute)
      dependencies.push(loadStreetSweepingSettings(vendorId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>
      <Resolver
        successComponent={SnowOrSweeperRouteTemplatePage}
        successProps={{ routeTemplateId }}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/routes/route-templates"
      />
    </>
  );
};

export default SnowOrSweeperRouteTemplatePageResolver;
