import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { MultiSelect, TypedField } from 'src/core/components';
import { Button, Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DELIVERY_UTILITY_ID, ROLL_OFF_ID, SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import {
  DAILY_REOCCURRING_FILTERS_FORM,
  ROUTE_PLANNER_VEHICLE_TYPES_FORM_NAME,
} from 'src/routes/constants/routePlanner';
import { setRoutePlannerPersistentFilters } from 'src/routes/services/routePlannerFilterStorage';
import { createUrl } from 'src/utils/services/queryParams';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { FormValues } from '../SnowOrSweeperRouteTemplateEditorForm';

interface PropsWithoutReduxForm {
  closeModal: () => void;
  filtersOptions: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const DailyAndReoccurringFiltersSectionForm: React.FC<Props> = ({ closeModal, filtersOptions }) => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  const {
    materialTypesOptions,
    supervisorsOptions,
    serviceZonesOptions,
    facilityOptions,
    routeStatusesOptions,
    groupIdsOptions,
  } = filtersOptions;

  const formValues = useSelector(getFormValues(DAILY_REOCCURRING_FILTERS_FORM)) as any;
  const vehicleTypeFormValues = useSelector(getFormValues(ROUTE_PLANNER_VEHICLE_TYPES_FORM_NAME)) as any;
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let routePlannerFilters = {};

    if (formValues) {
      const { materialTypeIds, supervisorsIds, serviceZonesIds, facilityTypeIds, routeStatusesIds, groupIds } =
        formValues;
      routePlannerFilters = {
        materialTypeIds: materialTypeIds && materialTypeIds.toString(),
        supervisorsIds: supervisorsIds && supervisorsIds.toString(),
        serviceZonesIds: serviceZonesIds && serviceZonesIds.toString(),
        facilityTypeIds: facilityTypeIds && facilityTypeIds.toString(),
        routeStatusesIds: routeStatusesIds && routeStatusesIds.toString(),
        groupIds: groupIds && groupIds.toString(),
      };
    }

    setRoutePlannerPersistentFilters(routePlannerFilters);
    dispatch(push(createUrl(pathname, search, routePlannerFilters)));
    closeModal();
  };

  const showMaterialTypes =
    vehicleTypeFormValues.vehicleTypeId &&
    vehicleTypeFormValues.vehicleTypeId !== ROLL_OFF_ID &&
    vehicleTypeFormValues.vehicleTypeId !== DELIVERY_UTILITY_ID &&
    vehicleTypeFormValues.vehicleTypeId !== SNOW_PLOW_ID &&
    vehicleTypeFormValues.vehicleTypeId !== STREET_SWEEPER_ID;

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection centered padding="sMedium xSmall">
        <GridColumn size="7/12">
          <Grid position="relative" centered multiLine>
            {showMaterialTypes && (
              <GridColumn margin="xxSmall no" size="12/12">
                <TypedField
                  name="materialTypeIds"
                  component={MultiSelect}
                  props={{
                    options: materialTypesOptions,
                    label: translate('routeTemplateBuilder.materialTypes'),
                    menuPosition: 'fixed',
                    minWidth: '263px',
                    isSearchable: true,
                    isClearable: true,
                  }}
                />
              </GridColumn>
            )}
            {supervisorEnabled && (
              <GridColumn margin="xxSmall no" size="12/12">
                <TypedField
                  name="supervisorsIds"
                  component={MultiSelect}
                  props={{
                    options: supervisorsOptions,
                    label: translate('common.supervisors'),
                    menuPosition: 'fixed',
                    minWidth: '263px',
                    isSearchable: true,
                    isClearable: true,
                  }}
                />
              </GridColumn>
            )}
            <GridColumn margin="xxSmall no" size="12/12">
              <TypedField
                name="serviceZonesIds"
                component={MultiSelect}
                props={{
                  options: serviceZonesOptions,
                  label: translate('routes.serviceZones'),
                  menuPosition: 'fixed',
                  minWidth: '263px',
                  isSearchable: true,
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn margin="xxSmall no" size="12/12">
              <TypedField
                name="facilityTypeIds"
                component={MultiSelect}
                props={{
                  options: facilityOptions,
                  label: translate('facilities.facilities'),
                  menuPosition: 'fixed',
                  minWidth: '263px',
                  maxWidth: '263px',
                  isSearchable: true,
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn margin="xxSmall no" size="12/12">
              <TypedField
                name="groupIds"
                component={MultiSelect}
                props={{
                  options: groupIdsOptions,
                  label: translate('routes.groups.groups'),
                  menuPosition: 'fixed',
                  minWidth: '263px',
                  maxWidth: '263px',
                  isSearchable: true,
                  isClearable: true,
                }}
              />
            </GridColumn>
            {!!size(routeStatusesOptions) && (
              <GridColumn margin="xxSmall no" size="12/12">
                <TypedField
                  name="routeStatusesIds"
                  component={MultiSelect}
                  props={{
                    options: routeStatusesOptions,
                    label: translate('routes.routeStatus'),
                    menuPosition: 'fixed',
                    minWidth: '263px',
                    isSearchable: true,
                    isClearable: true,
                  }}
                />
              </GridColumn>
            )}
            <GridColumn margin="small no no no">
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            </GridColumn>
          </Grid>
        </GridColumn>
      </PanelSection>
    </form>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: DAILY_REOCCURRING_FILTERS_FORM,
  enableReinitialize: true,
})(DailyAndReoccurringFiltersSectionForm);
