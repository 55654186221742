const calculatePaginationBoundaries = (
  totalResults: number,
  limitPerPage: number,
  maxPaginationItems: number,
  currentPage: number,
) => {
  const totalPages = Math.max(1, Math.ceil(totalResults / limitPerPage));
  let firstPage = Math.ceil(Math.max(1, currentPage - maxPaginationItems / 2));
  const lastPage = Math.min(totalPages, firstPage + maxPaginationItems - 1);
  firstPage = Math.max(1, Math.min(firstPage, lastPage - maxPaginationItems + 1));

  return { firstPage, lastPage, totalPages };
};

export default calculatePaginationBoundaries;
