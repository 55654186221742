import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { DragHandle, UnconnectedSwitch } from 'src/core/components';
import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { ROUTE_PLANNER_METRICS } from 'src/routes/constants';
import { RoutePlannerMetric } from 'src/routes/interfaces/routePlanner/RoutePlannerMetric';

interface Props extends RoutePlannerMetric {
  reportType: string;
  toggleItem: (itemId: number, value: boolean) => void;
}

const RoutePlannerMetricsSettingsTableRow: React.FC<Props> = ({
  id,
  metricTypeId,
  metricTypeName,
  orderNo,
  isActive,
  reportType,
  toggleItem,
}) => {
  const metricName = ROUTE_PLANNER_METRICS[metricTypeId]?.name;
  return (
    <TableRow>
      <TableCell width="85%">
        <DragHandle
          disabled={!isActive}
          margin="no small no no"
          id={`route-planner-settings-${reportType}-drag-handle`}
        />
        <Text size="medium" id={`route-planner-settings-${reportType}-report-name`}>
          {metricName}
        </Text>
      </TableCell>
      <TableCell width="15%" align="right">
        <UnconnectedSwitch
          checked={isActive}
          id={`route-planner-settings-${reportType}-switch`}
          onChange={(value: any) => toggleItem(id, value)}
        />
      </TableCell>
    </TableRow>
  );
};

export default SortableElement(RoutePlannerMetricsSettingsTableRow);
