import { AnyAction } from 'redux';
import update from 'immutability-helper';
import { ThunkDispatch } from 'redux-thunk';

import { loadVehicleTripInspectionDetails as doLoadVehicleTripInspectionDetails } from '../services/vehicleInspections';

// Actions
const START_LOAD = 'vendors/vehicle/tripInspectionDetails/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicle/tripInspectionDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicle/tripInspectionDetails/FAIL_LOAD';
const RESET = 'vendors/vehicle/tripInspectionDetails/RESET';

interface VehicleTripInspectionDetailsState {
  isLoading: boolean;
  vehicleTripInspectionDetails: any[];
}

const initialState: VehicleTripInspectionDetailsState = {
  isLoading: false,
  vehicleTripInspectionDetails: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTripInspectionDetails: action.vehicleTripInspectionDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTripInspectionDetails: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTripInspectionDetails: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleTripInspectionDetails: any[]) => {
  return {
    type: COMPLETE_LOAD,
    vehicleTripInspectionDetails,
  };
};

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadVehicleTripInspectionDetails = (vendorId: number, vehicleId: number) => (
  dispatch: ThunkDispatch<VehicleTripInspectionDetailsState, any, AnyAction>,
) => {
  dispatch(startLoad());
  const loadVehicleTripInspectionDetailsPromise = doLoadVehicleTripInspectionDetails(vendorId, vehicleId);
  loadVehicleTripInspectionDetailsPromise
    .then(vehicleTripInspectionDetails => dispatch(completeLoad(vehicleTripInspectionDetails)))
    .catch(() => dispatch(failLoad()));

  return loadVehicleTripInspectionDetailsPromise;
};

export const resetVehicleTripInspectionsDetails = () => ({
  type: RESET,
});
