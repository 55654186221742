import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { HaulerProfileInvalidGusIdMessage } from '..';
import { OverviewGrid, OverviewGridColumn } from '../styled/HaulerProfileOverview';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
  PageSubtitle,
} from '../../../common/components/styled';
import { Panel, PanelSection, PanelSectionTitle } from '../../../core/components/styled';
import BusinessProfilePage from './BusinessProfilePage';
import HoursOfOperationPage from './HoursOfOperationPage';
import translate from '../../../core/services/translate';
import OverviewMapGL from '../OverviewMapGL';
import { MapGLWrapper } from 'src/customers/components/styled';
import { MAP_CITY_ZOOM_IN_BIGGER } from 'src/core/constants/mapOptions';

interface Props extends RouteComponentProps {
  address: any;
  businessProfile: any;
  gusId?: string;
  hoursOfOperation: any;
  isValidGusId: boolean;
}

class OverviewPage extends PureComponent<Props> {
  render() {
    const { address, hoursOfOperation, isValidGusId } = this.props;
    const pinAddress = {
      latitude: address && address.latitude,
      longitude: address && address.longitude,
    };

    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('haulerProfile.overview')}</PageTitle>
            </PageTitleContainer>
            <PageSubtitle size="medium">{translate('haulerProfile.haulerProfile')}</PageSubtitle>
          </PageDetails>
        </PageHeader>
        <Panel>
          <PanelSection>
            {isValidGusId ? (
              <OverviewGrid>
                <OverviewGridColumn size={hoursOfOperation ? '4/12' : '5/12'}>
                  <BusinessProfilePage />
                </OverviewGridColumn>

                {hoursOfOperation && (
                  <OverviewGridColumn size="4/12">
                    <HoursOfOperationPage hoursOfOperation={hoursOfOperation} />
                  </OverviewGridColumn>
                )}

                <OverviewGridColumn size={hoursOfOperation ? '4/12' : '7/12'}>
                  <PanelSectionTitle>{translate('common.map')}</PanelSectionTitle>
                  <MapGLWrapper height="calc(100% - 60px)">
                    {!!pinAddress.latitude && !!pinAddress.longitude && (
                      <OverviewMapGL
                        isMarker
                        address={[[[pinAddress.longitude, pinAddress.latitude]]]}
                        mapZoom={MAP_CITY_ZOOM_IN_BIGGER}
                      />
                    )}
                  </MapGLWrapper>
                </OverviewGridColumn>
              </OverviewGrid>
            ) : (
              <HaulerProfileInvalidGusIdMessage />
            )}
          </PanelSection>
        </Panel>
      </PageContent>
    );
  }
}

const formSelector = formValueSelector('businessProfile');

const mapStateToProps = (state: AppState) => ({
  address: formSelector(state, 'address'),
  businessProfile: state.haulerProfile.businessProfile.businessInfo,
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  hoursOfOperation: state.haulerProfile.hoursOfOperation.hoursOfOperation,
  isValidGusId: state.haulerProfile.businessProfile.isValidGusId,
});

export default connect(mapStateToProps)(OverviewPage);
