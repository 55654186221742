import { find, map as _map } from 'lodash-es';
import { GeoJSONSource } from 'mapbox-gl';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { setRouteMapViewport } from 'src/routes/ducks';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { ContainerInsightFeatureProperties } from '../../routes/routePageSections/routeMap/routeStops/utils';
import {
  ROUTE_SEQUENCE_MAP_CLUSTER_LAYER,
  ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE,
  ROUTE_SEQUENCE_MAP_STOPS_LAYER,
} from './constants';
import RouteSequenceMapClusterLayer from './RouteSequenceMapClusterLayer';

type Props = {
  map: mapboxgl.Map;
  routeStops?: RouteStop[];
  routeLocations?: RouteLocation[];
};

const RouteSequenceMapClustersGL = ({ map, routeStops, routeLocations }: Props) => {
  const dispatch = useDispatch();

  const geoJson = useMemo(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);
    if (routeStops && routeStops.length) {
      const routeStopsCollection = getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
        _map(routeStops, routeStop => {
          return getPointFeature(routeStop.id, [routeStop.displayLongitude, routeStop.displayLatitude], {
            id: routeStop.id,
            clickable: true,
            layer: ROUTE_SEQUENCE_MAP_STOPS_LAYER,
            icon: find(NEW_INSIGHT_ICON_TYPES, ({ types }) => types.indexOf('icScheduled') !== -1)?.id,
            orderNo: routeStop.orderNo,
            newOrderNumber: routeStop.newOrderNumber || undefined,
          });
        }),
      );
      collection.features = collection.features.concat(routeStopsCollection.features);
    }
    if (routeLocations && routeLocations.length) {
      const icon = find(NEW_INSIGHT_ICON_TYPES, ({ types }) => types.indexOf('icScheduled') !== -1)?.id;
      const routeLocationsCollection = getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
        _map(routeLocations, routeLocation => {
          const {
            latitude,
            longitude,
            service: { serviceContractBinDetails },
          } = routeLocation as any;
          const serviceContractBinDetailsData = serviceContractBinDetails?.[0];

          const displayLatitude = serviceContractBinDetailsData.displayLatitude || latitude;
          const displayLongitude = serviceContractBinDetailsData.displayLongitude || longitude;

          return getPointFeature(routeLocation.id, [displayLongitude || longitude, displayLatitude], {
            id: routeLocation.location.id,
            clickable: true,
            layer: ROUTE_SEQUENCE_MAP_STOPS_LAYER,
            icon,
            orderNo: routeLocation.orderNumber,
            newOrderNumber: routeLocation.newOrderNumber || undefined,
          });
        }),
      );
      collection.features = collection.features.concat(routeLocationsCollection.features);
    }
    return collection;
  }, [routeLocations, routeStops]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', ROUTE_SEQUENCE_MAP_CLUSTER_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [ROUTE_SEQUENCE_MAP_CLUSTER_LAYER],
          })
          .filter(feature => feature.source === ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(ROUTE_SEQUENCE_MAP_CLUSTER_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setRouteMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <RouteSequenceMapClusterLayer geoJSON={geoJson} />;
};

export default RouteSequenceMapClustersGL;
