import { camelCase, map } from 'lodash-es';
import { ChangeEvent, useEffect, useState } from 'react';
import { Field, formValueSelector, InjectedFormProps, isDirty, reduxForm } from 'redux-form';
import moment from 'moment';

import { ACCOUNT_STATUSES, ON_HOLD, STREET, SUSPENDED } from '../../../common/constants';
import { AccountStatusToggle, AccountStatusToggleIcon } from '../styled';
import {
  accountStatusUpdateFromDateValidator,
  accountStatusUpdateFutureStatusValidator,
} from 'src/customers/services/futureAccountStatusValidator';
import {
  Button,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  ModalSubtitle,
  PanelSection,
  Text,
} from '../../../core/components/styled';
import { checkCustomerHasScheduledDailyRoutes } from '../../../routes/services/routeTemplate';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { Customer, CustomerBillSetting } from 'src/customers/interfaces/Customers';
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  LocationPicker,
  Switch,
  TypedField,
  UpdateTrackerRouteSwitch,
} from '../../../core/components';
import { FutureAccountStatus } from 'src/customers/interfaces/Services';
import { FutureAccountStatusDropdown } from '..';
import { getFutureAccountStatusCanceledMessagge } from 'src/customers/constants/customers';
import {
  isOptionalPhone,
  isRequired,
  maxLength50,
  maxLength100,
  isDateValidValidator,
  hasCoordinates,
  hasCountry,
  hasCity,
  hasZip,
  hasStreet,
  hasStreetNumber,
  isRequiredNumber,
} from '../../../utils/services/validator';
import { Address } from 'src/common/interfaces/Facility';
import { automaticAccountNumberGenerationSelector, billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { billingMethods } from 'src/customers/constants/billingMethods';
import { humanizeDate } from '../../../utils/services/formatter';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TODAY } from '../../../core/constants/weekdays';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { useSelector } from 'src/core/hooks/useSelector';
import AutomaticBillingFormSections from './formSections/AutomaticBillingFormSections';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

export const CUSTOMER_DETAILS_EDITOR_FORM = 'customerDetailsEditorForm';

const formSelector = formValueSelector(CUSTOMER_DETAILS_EDITOR_FORM);

const accountStatusOptions = map(ACCOUNT_STATUSES, ({ name, id }) => ({
  label: name,
  value: id,
}));

export interface CustomerDetailsEditorFormValues extends Customer {
  address: Address;
  billAddressSame: boolean;
  customerBillSetting: CustomerBillSetting;
  customerBillSettingBillingAddress: Address;
  customerBillSettingSendInvoiceByEmail: boolean;
  customerIsPOBoxAddress?: boolean;
  customerPOBoxAddress?: string;
  endDate?: Date | string;
  futureAccountStatus: FutureAccountStatus | null;
  startDate?: Date | string;
}

interface ComponentProps {
  allowedCustomerTypeId?: number;
  billingCycleOptions?: TechnicalType[];
  billingMethodOptions?: TechnicalType[];
  currencyOptions?: TechnicalType[];
  customerId?: number;
  customerTypeOptions?: TechnicalType[];
  initialValues: CustomerDetailsEditorFormValues;
  onCancel(pristine: boolean): void;
  paymentTermOptions?: TechnicalType[];
  serviceContractCustomerName?: string;
  serviceContractCustomerTypeId?: number;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<CustomerDetailsEditorFormValues, PropsWithoutReduxForm>;

const CustomerDetailsEditorForm = (props: Props) => {
  const {
    billingCycleOptions,
    change,
    customerId,
    customerTypeOptions,
    handleSubmit,
    initialValues,
    onCancel,
    pristine,
    paymentTermOptions,
  } = props;

  const { customerTypeId, isEsriRecord } = initialValues;

  const [isOpen, setIsOpen] = useState(false);
  const [accountStatusChanged, setAccountStatusChanged] = useState(false);
  const [hasScheduledDailyRoutes, setHasScheduledDailyRoutes] = useState(false);

  //  temporary hide this field
  // const businessTypesData = useSelector(state => state.common.businessTypes.businessTypes);

  const vendorId = useSelector(currentVendorId);
  const address = useSelector(state => formSelector(state, 'address'));
  const endDate = useSelector(state => formSelector(state, 'endDate'));
  const startDate = useSelector(state => formSelector(state, 'startDate'));
  const customerAccountStatusTypeId = useSelector(state => formSelector(state, 'customerAccountStatusTypeId'));
  const futureAccountStatusFromDate = useSelector(state => formSelector(state, 'futureAccountStatus.fromDate'));
  const futureAccountStatusId = useSelector(state => formSelector(state, 'futureAccountStatus.accountStatusId'));
  const futureAccountStatusToDate = useSelector(state => formSelector(state, 'futureAccountStatus.toDate'));
  const excludeLateFees = useSelector(state => formSelector(state, 'customerBillSetting.excludeLateFees'));
  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));
  const isAutomaticAccountNumberGenerationFeatureActive = useSelector(state =>
    automaticAccountNumberGenerationSelector(state.vendors.features.features),
  );
  const automaticBillsEnabled = useSelector(state => formSelector(state, 'customerBillSetting.isAutoBillingActive'));
  const billCycleType = useSelector(state => formSelector(state, 'customerBillSetting.customerBillCycle.id'));
  const customerIsPOBoxAddress = useSelector(state => formSelector(state, 'customerIsPOBoxAddress'));
  const isSameAsCustomerAddress = useSelector(state => formSelector(state, 'customerBillSetting.billAddressSame'));

  const { useLateFees, passProcessingFee } = useSelector(state => state.vendors.billingModule.billingModule);

  const isFormDirty = useSelector(isDirty(CUSTOMER_DETAILS_EDITOR_FORM));

  useEffect(() => {
    if (customerId) {
      checkCustomerHasScheduledDailyRoutes(customerId, vendorId).then(response => {
        setHasScheduledDailyRoutes(response);
      });
    }
  }, [customerId, vendorId]);

  const customerTypes = map(customerTypeOptions, ({ technicalName, id }) => ({
    label: translate(`customers.customerTypes.${camelCase(technicalName)}`),
    value: id,
  }));

  // temporary hide this field
  // const businessTypes = map(businessTypesData, ({ technicalName, id }) => ({
  //   label: translate(createTranslationKey(technicalName, `common.businessTypes`)),
  //   value: id,
  // }));

  const billingCycles = map(billingCycleOptions, ({ technicalName, id }) => ({
    label: translate(createTranslationKey(technicalName, `billing.billCycles`)),
    value: id,
  }));

  const paymentTerms = map(paymentTermOptions, ({ technicalName, id }) => ({
    label: translate(createTranslationKey(technicalName, `common.paymentTermTypes`)),
    value: id,
  }));

  const accountStatusMessageFuture =
    futureAccountStatusId && futureAccountStatusFromDate && moment(futureAccountStatusFromDate).toDate() > TODAY
      ? 'customers.futureAccountStatus'
      : undefined;

  const accountStatusMessageCurrent =
    futureAccountStatusFromDate &&
    moment(futureAccountStatusFromDate).toDate() <= TODAY &&
    futureAccountStatusToDate &&
    moment(futureAccountStatusToDate).toDate() >= TODAY
      ? 'customers.currentAccountStatus'
      : undefined;

  const handleAddressChange = (_: ChangeEvent<HTMLInputElement>, newAddress: Address) => {
    if (isSameAsCustomerAddress) {
      change('customerBillSettingBillingAddress', newAddress);
    }
  };

  const handleIsSameAsCustomerAddress = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    if (isChecked && address?.formattedAddress !== '') {
      change('customerBillSettingBillingAddress', address);
    }
  };

  const handleAccountStatusChange = () => {
    setAccountStatusChanged((!!futureAccountStatusId && !!futureAccountStatusFromDate) || false);
    change('futureAccountStatus.accountStatusId', null);
    change('futureAccountStatus.fromDate', null);
  };

  const handleChangeCustomerIsPOBoxAddress: TypedFieldOnChangeFunction = (_, isChecked) => {
    if (isChecked) {
      change('customerBillSetting.billAddressSame', false);
    }
    change('customerBillSettingBillingAddress', null);
  };

  const renderFormFields = () =>
    customerTypeId !== STREET ? (
      <Grid multiLine>
        <GridColumn size="12/12" margin="no no sMedium no">
          <ModalSubtitle fontWeight="medium" textAlign="left" size="medium" color="black">
            {translate('customers.customerInfo')}
          </ModalSubtitle>
        </GridColumn>
        <GridColumn size="12/12">
          <Field
            name="name"
            component={Input}
            label={translate('customers.customerName')}
            validate={[isRequired, maxLength100]}
            disabled={isEsriRecord}
          />
        </GridColumn>

        {isBillingFeatureActive && (
          <>
            <GridColumn size="8/12">
              <TypedField
                name="address"
                component={LocationPicker}
                validate={[isRequired, hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
                props={{
                  id: 'address-field',
                  fullWidth: true,
                  isClearable: true,
                  label: translate('customers.customerAddress'),
                }}
                onChange={handleAddressChange}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                id="contract-start-date-field"
                name="startDate"
                component={DatePicker}
                disabledDays={[
                  {
                    after: endDate ? moment(endDate).subtract(1, 'days').toDate() : undefined,
                  },
                ]}
                validate={[isRequired, isDateValidValidator]}
                label={translate('customers.contractStartDate')}
                isClearable
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                id="contract-end-date-field"
                name="endDate"
                component={DatePicker}
                disabledDays={[
                  {
                    before: startDate ? moment(startDate).add(1, 'days').toDate() : moment().toDate(),
                    after: undefined,
                  },
                ]}
                label={translate('customers.contractEndDate')}
                isClearable
                validate={[isDateValidValidator]}
              />
            </GridColumn>
          </>
        )}

        <GridColumn size="12/12">
          <Field
            id="customer-type-field"
            name="customerTypeId"
            component={Dropdown}
            options={customerTypes}
            label={translate('customers.customerType')}
            validate={[isRequired]}
            disabled={!!customerId}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Field
            name="phone"
            component={Input}
            label={translate('common.phone')}
            validate={[isOptionalPhone]}
            disabled={isEsriRecord}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Field
            name="accountNumber"
            component={Input}
            label={translate('customers.accountNumber')}
            validate={[maxLength50]}
            disabled={isEsriRecord || isAutomaticAccountNumberGenerationFeatureActive}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="customerAccountStatusTypeId"
            component={Dropdown}
            options={accountStatusOptions}
            label={translate('common.accountStatus')}
            onChange={handleAccountStatusChange}
          />
        </GridColumn>

        <GridColumn size="5/12">
          {!isOpen && accountStatusChanged && getFutureAccountStatusCanceledMessagge()}
          {!isOpen &&
            (accountStatusMessageFuture || accountStatusMessageCurrent) &&
            !!futureAccountStatusId &&
            !accountStatusChanged && (
              <Text block margin="small no no no">
                {translate(accountStatusMessageFuture || accountStatusMessageCurrent, {
                  label: ACCOUNT_STATUSES[futureAccountStatusId].name,
                  date: humanizeDate(
                    accountStatusMessageFuture ? futureAccountStatusFromDate : futureAccountStatusToDate,
                  ),
                })}
              </Text>
            )}
        </GridColumn>

        {!!customerId && (
          <GridColumn size="1/12">
            <AccountStatusToggle onClick={() => setIsOpen(!isOpen)}>
              <AccountStatusToggleIcon isOpen={isOpen} />
            </AccountStatusToggle>
          </GridColumn>
        )}

        {isOpen && (
          <GridColumn size="12/12">
            <Grid multiLine>
              <GridColumn size="4/12" padding="no small no no">
                <Field
                  name="futureAccountStatus.accountStatusId"
                  component={FutureAccountStatusDropdown}
                  margin="no"
                  accountStatusId={customerAccountStatusTypeId}
                  label={translate('common.updateStatusTo')}
                  validate={[isRequired]}
                  futureAccountStatusValidator={accountStatusUpdateFutureStatusValidator}
                />
              </GridColumn>
              <GridColumn size="4/12">
                <Field
                  name="futureAccountStatus.fromDate"
                  component={DatePicker}
                  disabledDays={[
                    {
                      before: moment().add(1, 'days').toDate(),
                      after: futureAccountStatusToDate
                        ? moment(futureAccountStatusToDate).subtract(1, 'days').toDate()
                        : undefined,
                    },
                  ]}
                  validate={[accountStatusUpdateFromDateValidator, isRequired, isDateValidValidator]}
                  margin="no"
                  label={translate('common.from')}
                  isClearable
                />
              </GridColumn>
              {(futureAccountStatusId === SUSPENDED || futureAccountStatusId === ON_HOLD) && (
                <GridColumn size="4/12">
                  <Field
                    name="futureAccountStatus.toDate"
                    component={DatePicker}
                    disabledDays={[
                      {
                        before: futureAccountStatusFromDate
                          ? moment(futureAccountStatusFromDate).add(1, 'days').toDate()
                          : moment().add(2, 'days').toDate(),
                        after: undefined,
                      },
                    ]}
                    margin="no"
                    label={translate('common.to')}
                    isClearable
                    validate={[isDateValidValidator]}
                  />
                </GridColumn>
              )}
            </Grid>
          </GridColumn>
        )}

        {!!customerId && (
          <GridColumn size="12/12" verticalAlign="flex-end" margin="no no small no">
            <UpdateTrackerRouteSwitch
              topMargin="no"
              isFormDirty={isFormDirty}
              hasScheduledDailyRoutes={hasScheduledDailyRoutes}
            />
          </GridColumn>
        )}

        {isBillingFeatureActive && (
          <>
            <GridColumn size="12/12" margin={!!customerId ? 'medium no sMedium no' : 'no no sMedium no'}>
              <PanelSection withBorder />
            </GridColumn>

            <GridColumn size="12/12" margin="no">
              <ModalSubtitle fontWeight="medium" textAlign="left" size="medium" color="black" margin="sMedium no no no">
                {translate('customers.billingInfo')}
              </ModalSubtitle>
            </GridColumn>

            <GridColumn size="4/12" verticalAlign="center">
              <TypedField
                name="customerIsPOBoxAddress"
                component={Switch}
                props={{
                  label: translate('customers.billingAddressIsPOBox'),
                }}
                onChange={handleChangeCustomerIsPOBoxAddress}
              />
            </GridColumn>

            <GridColumn size="8/12" verticalAlign="center">
              {!!customerIsPOBoxAddress ? (
                <Field
                  name="customerPOBoxAddress"
                  component={Input}
                  label={translate('customers.billingAddressPOBox')}
                  validate={[isRequired]}
                />
              ) : (
                <TypedField
                  name="customerBillSettingBillingAddress"
                  component={LocationPicker}
                  validate={[isRequired, hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
                  props={{
                    id: 'billing-address-field',
                    disabled: isSameAsCustomerAddress,
                    fullWidth: true,
                    isClearable: true,
                    label: translate('customers.billingAddress'),
                  }}
                />
              )}
            </GridColumn>
            <GridColumn size="12/12">
              <TypedField
                name="customerBillSetting.billAddressSame"
                component={Checkbox}
                onChange={handleIsSameAsCustomerAddress}
                props={{
                  label: translate('customers.isSameAsCustomerAddress'),
                  block: true,
                  margin: 'no no sMedium',
                  disabled: !!customerIsPOBoxAddress,
                }}
              />
            </GridColumn>

            {/* temporary hide this field */}
            {/* <GridColumn size="6/12">
              <Field
                id="business-type-field"
                name="customerBillSetting.customerBusinessType.id"
                component={Dropdown}
                options={businessTypes}
                label={translate('customers.businessType')}
              />
            </GridColumn> */}

            <GridColumn size="6/12">
              <Field
                id="billing-cycle-field"
                name="customerBillSetting.customerBillCycle.id"
                component={Dropdown}
                options={billingCycles}
                label={translate('customers.billingCycle')}
                validate={[isRequired]}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                id="payment-terms-field"
                name="customerBillSetting.paymentTerm.id"
                component={Dropdown}
                options={paymentTerms}
                label={translate('common.paymentTerms')}
                validate={[isRequired]}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                id="billing-method-field"
                name="customerBillSettingSendInvoiceByEmail"
                component={Dropdown}
                options={billingMethods}
                label={translate('customers.billingMethod')}
                validate={[isRequiredNumber]}
                props={{ raisedLabel: true }}
              />
            </GridColumn>

            <GridColumn size="12/12">
              <Field
                name="customerBillSetting.isAutoBillingActive"
                component={Checkbox}
                label={translate('billing.automaticallyGenerateBills')}
                margin="no no sMedium"
              />
            </GridColumn>
            <AutomaticBillingFormSections automaticBillsEnabled={automaticBillsEnabled} billCycleType={billCycleType} />

            {useLateFees && (
              <GridColumn size="12/12">
                <Field
                  name="customerBillSetting.excludeLateFees"
                  component={Checkbox}
                  label={translate('billing.excludeLateFees')}
                  checked={excludeLateFees}
                  margin="no no sMedium"
                />
              </GridColumn>
            )}
            {passProcessingFee && (
              <GridColumn size="12/12">
                <Field
                  name="customerBillSetting.excludeProcessingFees"
                  component={Checkbox}
                  label={translate('billing.excludeCustomerFromProcessingFee')}
                  checked={excludeLateFees}
                  margin="no no sMedium"
                />
              </GridColumn>
            )}
          </>
        )}
      </Grid>
    ) : (
      <Grid multiLine>
        <GridColumn size="12/12">
          <Field
            name="name"
            component={Input}
            label={translate('customers.customerName')}
            validate={[isRequired, maxLength100]}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Field
            name="customerTypeId"
            component={Dropdown}
            options={customerTypes}
            label={translate('customers.customerType')}
            validate={[isRequired]}
            disabled={!!customerId}
          />
        </GridColumn>
      </Grid>
    );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>

      <PanelSection padding="small">{renderFormFields()}</PanelSection>

      <ModalFixedFooter isShadowed>
        <Button type="submit" margin="no xSmall" color="primary">
          {translate('common.save')}
        </Button>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => onCancel(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<CustomerDetailsEditorFormValues, PropsWithoutReduxForm>({
  form: CUSTOMER_DETAILS_EDITOR_FORM,
  onSubmitFail: focusFirstInvalidField,
})(CustomerDetailsEditorForm);
