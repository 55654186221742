import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import doLoadDailyReportTypes from '../services/loadDailyReportTypes';

// Actions
const START_LOAD = 'insights/dailyReportTypes/START_LOAD';
const COMPLETE_LOAD = 'insights/dailyReportTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/dailyReportTypes/FAIL_LOAD';
const RESET = 'insights/dailyReportTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  dailyReportTypes: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          dailyReportTypes: action.dailyReportTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (dailyReportTypes: any) => ({
  type: COMPLETE_LOAD,
  dailyReportTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDailyReportTypes = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDailyReportTypesPromise = doLoadDailyReportTypes(vendorId);
  loadDailyReportTypesPromise
    .then(dailyReportTypes => dispatch(completeLoad(dailyReportTypes)))
    .catch(() => dispatch(failLoad()));
  return loadDailyReportTypesPromise;
};

export const resetDailyReportTypes = () => ({
  type: RESET,
});
