import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';

import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelLogo, AccountPanelTitle, AccountPanelError } from '../styled';
import { isRequired, minLength8, passwordComplexity, isEmail } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

export interface ChangePasswordFormValues {
  email: string;
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

interface PropsWithoutReduxForm {
  changePasswordFailed: boolean;
  changePasswordFailedInvalidCredentials: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<ChangePasswordFormValues, PropsWithoutReduxForm>;

const differentPassword = (value: string, allValues: ChangePasswordFormValues) =>
  value !== allValues.currentPassword ? undefined : translate('account.mustBeDifferentPassword');

const matchPassword = (value: string, allValues: ChangePasswordFormValues) =>
  value === allValues.newPassword ? undefined : translate('account.mustMatchNewPassword');

const ChangePasswordForm: React.FC<Props> = ({
  changePasswordFailed,
  changePasswordFailedInvalidCredentials,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelLogo />

    <AccountPanelTitle>{translate('account.changePassword')}</AccountPanelTitle>

    <Field
      name="email"
      component={Input}
      label={translate('common.email')}
      type="text"
      validate={[isRequired, isEmail]}
    />

    <Field
      name="currentPassword"
      type="password"
      label={translate('common.oldPassword')}
      component={Input}
      validate={[isRequired]}
    />

    <Field
      name="newPassword"
      type="password"
      label={translate('common.newPassword')}
      component={Input}
      validate={[isRequired, minLength8, passwordComplexity, differentPassword]}
    />

    <Field
      name="newPasswordConfirmation"
      type="password"
      label={translate('common.newPasswordConfirmation')}
      component={Input}
      validate={[isRequired, matchPassword]}
    />

    {changePasswordFailed && <AccountPanelError>{translate('account.changePasswordFailed')}</AccountPanelError>}

    {changePasswordFailedInvalidCredentials && (
      <AccountPanelError>{translate('account.changePasswordFailedInvalidCredentials')}</AccountPanelError>
    )}

    <ButtonSet>
      <Button type="submit" color="primary">
        {translate('common.submit')}
      </Button>
    </ButtonSet>
  </form>
);

const mapStateToProps = (state: AppState) => ({
  changePasswordFailed: state.account.login.changePasswordFailed,
  changePasswordFailedInvalidCredentials: state.account.login.changePasswordFailedInvalidCredentials,
});

export default connect(mapStateToProps)(
  reduxForm<ChangePasswordFormValues, PropsWithoutReduxForm>({
    form: 'changeAccountPassword',
    onSubmitFail: focusFirstInvalidField,
  })(ChangePasswordForm),
);
