import React, { PureComponent, Fragment } from 'react';

import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { Button, Grid, GridColumn, PanelSection, Text } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  GEO_FENCE_GENERAL_ALERTS,
  INSIDE_GEO_FENCE_GENERAL_ID,
  OUTSIDE_GEO_FENCE_GENERAL_ID,
} from '../../../vendors/constants';
import { GeoFenceSetting, TimeField } from '../../../vendors/interfaces/GeoFenceSettings';
import { GeoFencePopoverClose, GeoFencePopoverTitle, PopoverCloseIcon } from './../styled/GeoFences';
import { Input, Switch } from '../../../core/components';
import { INSIDE_GEO_FENCE_ID, OUTSIDE_GEO_FENCE_ID } from './../../constants';
import { isRequired } from '../../../utils/services/validator';
import { loadGeoFenceAlert, saveGeoFenceAlert } from '../../ducks/geoFences';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface ComponentProps {
  closeGeoFenceAlert: () => void;
  formValues: any;
  geoFenceId: number;
  isLoadingAlert: boolean;
  isSavingAlert: boolean;
  loadGeoFenceAlert: (geoFenceId: number, vendorId: number) => Promise<any>;
  saveGeoFenceAlert: (geoFenceAlert: any, geoFenceId: number) => Promise<any>;
  vendorId: number;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  isOutsideGeoFenceAlertEnabled: boolean;
  isInsideGeoFenceAlertEnabled: boolean;
  isOutsideToggleAlertEnabled: boolean;
  isInsideToggleAlertEnabled: boolean;
}

class GeoFenceAlertForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOutsideGeoFenceAlertEnabled: false,
      isInsideGeoFenceAlertEnabled: false,
      isOutsideToggleAlertEnabled: false,
      isInsideToggleAlertEnabled: false,
    };
  }

  componentDidMount() {
    const { loadGeoFenceAlert, vendorId, geoFenceId } = this.props;
    loadGeoFenceAlert(geoFenceId, vendorId);
  }

  componentDidUpdate(prevProps: Props) {
    const { initialValues } = this.props;

    if (initialValues && initialValues !== prevProps.initialValues) {
      const initialValuesOutside = initialValues.find(
        (alert: GeoFenceSetting) => alert.geoFenceType.id === OUTSIDE_GEO_FENCE_ID,
      );
      const isOutsideGeoFenceAlertEnabled = initialValuesOutside.isVendorAlertActive;
      const isOutsideGeoFenceAlertActive = initialValuesOutside.isActive;

      const initialValuesInside = initialValues.find(
        (alert: GeoFenceSetting) => alert.geoFenceType.id === INSIDE_GEO_FENCE_ID,
      );
      const isInsideGeoFenceAlertEnabled = initialValuesInside.isVendorAlertActive;
      const isInsideGeoFenceAlertActive = initialValuesInside.isActive;

      this.setState({
        isOutsideGeoFenceAlertEnabled: !isOutsideGeoFenceAlertActive
          ? isOutsideGeoFenceAlertActive
          : isOutsideGeoFenceAlertEnabled,
        isOutsideToggleAlertEnabled: isOutsideGeoFenceAlertEnabled,
        isInsideGeoFenceAlertEnabled: !isInsideGeoFenceAlertActive
          ? isInsideGeoFenceAlertActive
          : isInsideGeoFenceAlertEnabled,
        isInsideToggleAlertEnabled: isInsideGeoFenceAlertEnabled,
      });
    }
  }

  updateFormValue = (name: string, value: any) => {
    const { change } = this.props;
    change(name, value);
  };

  onTimeChange = (event: any, fieldName: string) => {
    const time = event.target.value;
    this.updateFormValue(fieldName, time);
  };

  onToggleChange = (geoFenceAlertId: number) => {
    if (geoFenceAlertId === OUTSIDE_GEO_FENCE_GENERAL_ID) {
      this.setState({
        isOutsideGeoFenceAlertEnabled: !this.state.isOutsideGeoFenceAlertEnabled,
      });
    }

    if (geoFenceAlertId === INSIDE_GEO_FENCE_GENERAL_ID) {
      this.setState({
        isInsideGeoFenceAlertEnabled: !this.state.isInsideGeoFenceAlertEnabled,
      });
    }
  };

  saveGeoFenceAlert = (event: any) => {
    event.preventDefault();
    const { formValues, geoFenceId, saveGeoFenceAlert, closeGeoFenceAlert } = this.props;

    saveGeoFenceAlert(formValues, geoFenceId)
      .then(() => {
        closeGeoFenceAlert();
        createSuccessNotification(translate('routes.geoFences.alertMessages.alertSaveSuccess'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.geoFences.alertMessages.alertSaveFail'));
      });
  };

  render() {
    const { closeGeoFenceAlert, handleSubmit, isLoadingAlert, isSavingAlert } = this.props;
    const {
      isOutsideGeoFenceAlertEnabled,
      isInsideGeoFenceAlertEnabled,
      isOutsideToggleAlertEnabled,
      isInsideToggleAlertEnabled,
    } = this.state;

    return (
      <Fragment>
        <GeoFencePopoverTitle>
          <Text size="large" weight="medium">
            {translate('routes.geoFences.geoFenceAlerts')}
          </Text>
          <GeoFencePopoverClose onClick={() => closeGeoFenceAlert()}>
            <PopoverCloseIcon />
          </GeoFencePopoverClose>
        </GeoFencePopoverTitle>
        <form onSubmit={handleSubmit} noValidate>
          <PanelSection padding="small xSmall no" isLoading={isLoadingAlert || isSavingAlert}>
            <Grid multiLine>
              {GEO_FENCE_GENERAL_ALERTS.map(geoFenceAlert => (
                <Fragment key={geoFenceAlert.id}>
                  <GridColumn size="12/12">
                    <Field
                      name={`[${geoFenceAlert.id}].isActive`}
                      component={Switch}
                      props={{
                        disabled:
                          (geoFenceAlert.id === OUTSIDE_GEO_FENCE_GENERAL_ID && !isOutsideToggleAlertEnabled) ||
                          (geoFenceAlert.id === INSIDE_GEO_FENCE_GENERAL_ID && !isInsideToggleAlertEnabled),
                        label: translate(`routes.geoFences.${geoFenceAlert.translationKey}`),
                        labelOnLeft: true,
                        margin: 'no no sMedium no',
                        width: '100%',
                      }}
                      onSwitch={() => this.onToggleChange(geoFenceAlert.id)}
                    />
                  </GridColumn>

                  {geoFenceAlert.timeFields.map((timeField: TimeField) => {
                    const fieldName = `[${geoFenceAlert.id}].${timeField.name}`;

                    return (
                      <GridColumn size="12/12" key={timeField.name}>
                        <Field
                          component={Input}
                          isTimeField
                          label={translate(`vendors.${timeField.translationKey}`)}
                          name={fieldName}
                          onTimeChange={(event: any) => this.onTimeChange(event, fieldName)}
                          validate={[isRequired]}
                          props={{
                            name: fieldName,
                            readOnly:
                              (geoFenceAlert.id === OUTSIDE_GEO_FENCE_GENERAL_ID && !isOutsideGeoFenceAlertEnabled) ||
                              (geoFenceAlert.id === INSIDE_GEO_FENCE_GENERAL_ID && !isInsideGeoFenceAlertEnabled) ||
                              (geoFenceAlert.id === OUTSIDE_GEO_FENCE_GENERAL_ID &&
                                timeField.name === 'countTimeAfter'),
                          }}
                        />
                      </GridColumn>
                    );
                  })}
                </Fragment>
              ))}

              {(isOutsideToggleAlertEnabled || isInsideToggleAlertEnabled) && (
                <GridColumn size="12/12" align="center">
                  <Button
                    type="submit"
                    id="geo-fence-save-alert"
                    color="primary"
                    onClick={(event: any) => this.saveGeoFenceAlert(event)}
                  >
                    {translate('common.save')}
                  </Button>
                </GridColumn>
              )}
            </Grid>
          </PanelSection>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  formValues: (getFormValues as any)('geoFenceAlertEditorForm')(state),
  geoFenceId: state.routes.geoFences.geoFence.id || ownProps.geoFenceId,
  initialValues: state.routes.geoFences.geoFenceAlert,
  isLoadingAlert: state.routes.geoFences.isLoadingAlert,
  isSavingAlert: state.routes.geoFences.isSavingAlert,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadGeoFenceAlert,
  saveGeoFenceAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'geoFenceAlertEditorForm',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(GeoFenceAlertForm),
);
