import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import { useCallback, useEffect } from 'react';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { getMapBounds } from '../util';

export const useZoomableClusters = (
  sourceId: string,
  layers: string[],
  setViewport: (viewPort: MapGLViewport) => void,
  map?: mapboxgl.Map,
) => {
  const handleClusterClick = useCallback(
    (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (map) {
        const [feature] = map.queryRenderedFeatures(event.point, {
          layers,
        });

        if (feature) {
          const clusterId = feature.properties?.cluster_id;
          const source = map.getSource(sourceId) as GeoJSONSource;
          source.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            const coordinates: number[] = (feature.geometry as any).coordinates;

            const bounds = getMapBounds([{ longitude: coordinates[0], latitude: coordinates[1] }], { padding: 20 });

            setViewport({ ...bounds, zoom });
          });
        }
      }
    },
    [layers, map, setViewport, sourceId],
  );

  useEffect(() => {
    if (map) {
      map.on('click', handleClusterClick);
      return () => {
        map.off('click', handleClusterClick);
      };
    }
  }, [map, handleClusterClick]);
};

