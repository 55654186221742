import { useEffect, useMemo, useState } from 'react';
import { getFeatureCollection } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import { map as _map } from 'lodash-es';

import DashboardGeoFencesGLSource from './DashboardGeoFencesGLSource';

type Props = {
  map: mapboxgl.Map;
};

export default function DashboardGeoFencesGL({ map }: Props) {
  const geoFences = useSelector(state => state.routes.geoFences.geoFences.geoFences);
  const isSatellite = useSelector(state => state.dashboard.mapControls.isSatelliteEnabled);
  const geoFencesGeoJSON = useMemo(() => {
    const allGeoFencesGeoJSON = _map(geoFences, (geoFence: any) => {
      const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
      return {
        ...parsedGeoFenceJson,
        id: geoFence.id,
        properties: {
          ...parsedGeoFenceJson?.properties,
          name: geoFence.geoFenceName,
        },
      };
    });

    return allGeoFencesGeoJSON;
  }, [geoFences]);

  const geoJSON = getFeatureCollection(geoFencesGeoJSON);

  const [mapZoom, setMapZoom] = useState(map.getZoom());

  useEffect(() => {
    map?.once('load', () => {
      map.on('zoom', () => {
        setMapZoom(map.getZoom());
      });
    });
  }, [map]);

  return <DashboardGeoFencesGLSource geoJSON={geoJSON} isSatellite={isSatellite} mapZoom={mapZoom} />;
}
