import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { VEHICLE_TYPES } from '../../fleet/constants';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../../fleet/ducks';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  excludeVehicleTypes: any[];
  vehicleRoleTypeId: number;
  vehicleTypesForVendor: any[];
  withTechnicalName?: boolean;
}

class VehicleTypeForVendorDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      vehicleTypesForVendor,
      dropdownProps,
      withTechnicalName,
    } = this.props;

    const vehicleTypesOptions = vehicleTypesForVendor.map(vehicleType => ({
      label: VEHICLE_TYPES[vehicleType.technicalName].name,
      value: withTechnicalName ? vehicleType.technicalName : vehicleType.id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicleType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicleType') : undefined),
          options: vehicleTypesOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const vehicleTypesForVendor = (vehicleTypesForVendorWithRoleTypeSelector as any)(
    state.fleet.vehicleTypesForVendor,
    ownProps.vehicleRoleTypeId,
    ownProps.excludeVehicleTypes,
  );

  let vehicleTypeId;
  if (state.routes.route.route && state.routes.route.route.id) {
    vehicleTypeId = state.routes.route.route.vehicleTypeId;
  } else if (state.routes.routeTemplate.routeTemplate) {
    vehicleTypeId = state.routes.routeTemplate.routeTemplate.vehicleTypeId;
  }

  return {
    vehicleTypesForVendor,
    vehicleTypeId,
  };
};

export default connect(mapStateToProps)(VehicleTypeForVendorDropdown);
