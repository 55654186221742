import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

export const CUSTOMER_ADMIN = 1;
export const CUSTOMER_MANAGER = 2;
export const CUSTOMER_READONLY = 3;
export const CUSTOMER_SERVICE_ONLY = 5;
export const CUSTOMER_USER = 7;

export const CUSTOMER_ROLES_LIST = mapKeys(
  [
    {
      id: CUSTOMER_ADMIN,
      name: translate(createTranslationKey('CustomerAdmin', 'customers.customerRoles')),
      technicalName: 'CustomerAdmin',
    },
    {
      id: CUSTOMER_MANAGER,
      name: translate(createTranslationKey('CustomerManager', 'customers.customerRoles')),
      technicalName: 'CustomerManager',
    },
    {
      id: CUSTOMER_READONLY,
      name: translate(createTranslationKey('CustomerReadOnly', 'customers.customerRoles')),
      technicalName: 'CustomerReadOnly',
    },
    {
      id: CUSTOMER_SERVICE_ONLY,
      name: translate(createTranslationKey('CustomerServiceOnly', 'customers.customerRoles')),
      technicalName: 'CustomerServiceOnly',
    },
    {
      id: CUSTOMER_USER,
      name: translate(createTranslationKey('CustomerUser', 'customers.customerRoles')),
      technicalName: 'CustomerUser',
    },
  ],
  'id',
);
