import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { orderBy } from 'lodash-es';

import { ALL_EQUIPMENTS_TYPES } from '../../constants';
import { AppState } from '../../../store';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { Dropdown, MultiSelect } from '../../../core/components';
import {
  getAllEquipmentSizes,
  getEquipmentTypeId,
  getEquipmentTypeOptions,
  getEquipmentSizeOptions,
  getMaterialOptions,
} from '../../utils/equipments';
import { SavedEquipment, SavedMaterial } from '../../interfaces/ServiceArea';
import serviceAreasFilterFormInitialValuesSelector from '../../services/serviceAreasFilterFormInitialValuesSelector';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  savedEquipments: SavedEquipment[];
  savedMaterials: SavedMaterial[];
}
interface FormValues {
  equipmentTypes?: string;
  equipmentSizes?: string[];
  materials?: string[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

function ServiceAreasFilterForm({ change, handleSubmit, savedEquipments, savedMaterials }: Props) {
  const equipmentTypeOptions = [
    { label: translate('common.all'), value: ALL_EQUIPMENTS_TYPES },
    ...getEquipmentTypeOptions(savedEquipments),
  ];
  const materialOptions = orderBy(getMaterialOptions(savedMaterials), 'label', 'asc');

  const [equipmentSizeOptions, setEquipmentSizeOptions] = useState(getEquipmentSizeOptions(savedEquipments, []));
  const [isEquipmentSizesMultiSelectDisabled, setIsEquipmentSizesMultiSelectDisabled] = useState(true);

  const handleChangeEquipmentTypes = (equipmentTypes: any) => {
    setIsEquipmentSizesMultiSelectDisabled(equipmentTypes === ALL_EQUIPMENTS_TYPES);

    const equipmentSizes =
      equipmentTypes === ALL_EQUIPMENTS_TYPES
        ? getAllEquipmentSizes(savedEquipments)
        : savedEquipments.find(equipment => getEquipmentTypeId(equipment) === equipmentTypes)?.binTypes || [];

    const equipmentSizeOptions = equipmentSizes.map(equipmentSize => ({
      label: equipmentSize.other
        ? equipmentSize.other
        : translate(`haulerProfile.equipments.equipmentTypes.${equipmentSize.binClass}`),
      value: equipmentSize.vendorGroupEquipmentId,
    }));

    setEquipmentSizeOptions(equipmentSizeOptions);
    change(
      'equipmentSizes',
      equipmentSizes.map(equipmentSize => equipmentSize.vendorGroupEquipmentId),
    );
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection padding="xSmall small">
        <Grid>
          <GridColumn size="3/12">
            <Field
              name="equipmentTypes"
              component={Dropdown}
              onChange={handleChangeEquipmentTypes}
              margin="no"
              options={equipmentTypeOptions}
              label={translate('common.equipmentType')}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <Field
              name="equipmentSizes"
              component={MultiSelect}
              margin="no"
              normalizeValues={Number}
              options={orderBy(equipmentSizeOptions, ['label'], ['asc'])}
              label={translate('common.equipmentSize')}
              disabled={isEquipmentSizesMultiSelectDisabled}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <Field
              name="materials"
              component={MultiSelect}
              margin="no"
              normalizeValues={Number}
              options={materialOptions}
              label={translate('common.material')}
            />
          </GridColumn>

          <GridColumn size="3/12" align="right">
            <Button margin="small no no no" color="primary">
              {translate('common.applyFilter')}
            </Button>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
}

const mapStateToProps = (state: AppState) => ({
  initialValues: serviceAreasFilterFormInitialValuesSelector(state.haulerProfile.serviceAreas.serviceAreaOptions),
  savedEquipments: state.haulerProfile.serviceAreas.serviceAreaOptions?.savedEquipments || [],
  savedMaterials: state.haulerProfile.serviceAreas.serviceAreaOptions?.savedMaterials || [],
});

export default connect(mapStateToProps)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'serviceAreasFilterForm',
    enableReinitialize: true,
  })(ServiceAreasFilterForm),
);
