import React, { PureComponent, Children, cloneElement } from 'react';
import { mapKeys, mapValues } from 'lodash-es';
import {
  Accordion as AccordionContainer,
  AccordionSection as AccordionSectionContainer,
  AccordionHead,
  AccordionTitle,
  AccordionToggleIcon,
} from './styled/ConfigurationAccordion';
import Collapsible from './Collapsible';

interface AccordionSectionProps {
  name?: string;
  data: any;
  title?: string;
  children: any;
  isOpen?: boolean;
  toggle?: (key: string) => void;
  component: any;
  rowProps: any;
}

export const AccordionSection: React.SFC<AccordionSectionProps> = ({
  name,
  title,
  children,
  isOpen,
  toggle,
  component: Component,
  rowProps,
  data,
}) => (
  <AccordionSectionContainer isOpen={isOpen}>
    <AccordionHead onClick={toggle && name ? () => toggle(name) : undefined}>
      <AccordionToggleIcon />
      <AccordionTitle>{title}</AccordionTitle>
      <Component rowProps={rowProps} {...data} />
    </AccordionHead>
    <Collapsible isOpen={!!isOpen}>{children}</Collapsible>
  </AccordionSectionContainer>
);

interface Props {
  openedSections?: string[];
  canOpenMultiple?: boolean;
  margin?: string;
  children: any;
}

export class Accordion extends PureComponent<Props, { openedSections: { [key: string]: boolean } }> {
  constructor(props: Props) {
    super(props);

    const openedSections = mapValues(mapKeys(props.openedSections), () => true);
    this.state = { openedSections };
  }

  toggleSection = (section: string) => {
    this.setState(({ openedSections }) =>
      openedSections[section] || this.props.canOpenMultiple
        ? { openedSections: { ...openedSections, [section]: !openedSections[section] } }
        : { openedSections: { [section]: true } },
    );
  };

  render() {
    const { margin, children } = this.props;
    return (
      <AccordionContainer margin={margin}>
        {Children.map(children, child =>
          cloneElement(child, {
            isOpen: !!this.state.openedSections[child.props.name],
            toggle: this.toggleSection,
          }),
        )}
      </AccordionContainer>
    );
  }
}
