import { connect } from 'react-redux';
import { Fragment, PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { DocumentTitle } from 'src/common/components';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadCustomer, loadLocation, loadServices } from 'src/customers/ducks';
import { loadLocationPaymentMethods } from 'src/customers/ducks/paymentMethods';
import { loadPermissions, shouldLoadPermissionsSelector } from 'src/account/ducks';
import { PageLoading } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import ServicesPage from './ServicesPage';
import translate from 'src/core/services/translate';

interface MatchState {
  customerId: string;
}

interface LocationState {
  locationId: string;
}

interface Props extends RouteComponentProps<MatchState, {}, LocationState> {
  isBillingFeatureActive?: boolean;
  loadCustomer: DuckFunction<typeof loadCustomer>;
  loadLocation: DuckFunction<typeof loadLocation>;
  loadLocationPaymentMethods: DuckFunction<typeof loadLocationPaymentMethods>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  loadServices: DuckFunction<typeof loadServices>;
  shouldLoadPermissions: boolean;
}

class ServicesPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      isBillingFeatureActive,
      loadCustomer,
      loadLocation,
      loadLocationPaymentMethods,
      loadPermissions,
      loadServices,
      location: { search },
      match: {
        params: { customerId },
      },
      shouldLoadPermissions,
    } = this.props;
    const { locationId } = getQueryParams(search);

    const dependencies = [loadLocation(locationId), loadServices(locationId), loadCustomer(Number(customerId))];

    if (shouldLoadPermissions) {
      dependencies.push(loadPermissions());
    }

    if (isBillingFeatureActive) {
      dependencies.push(loadLocationPaymentMethods(Number(locationId)).catch(() => {}));
    }

    return Promise.all(dependencies);
  };

  render() {
    const {
      match: {
        params: { customerId },
      },
    } = this.props;

    return (
      <Fragment>
        <DocumentTitle>{translate('customers.services')}</DocumentTitle>
        <Resolver
          successComponent={ServicesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError={`/customers/customers/${customerId}`}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
});

const mapDispatchToProps = {
  loadCustomer,
  loadLocation,
  loadLocationPaymentMethods,
  loadPermissions,
  loadServices,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesPageResolver));
