import React, { Fragment } from 'react';

import { Table, PopoverWrapper } from '../../../core/components';
import { Button, ButtonSet, Message, Popover, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { RouteNotesFormTableRow } from '.';
import { DuckFunction } from 'src/contracts/ducks';
import { deleteRouteNote, saveRouteNote } from 'src/routes/ducks/routeLocationNotes';
import { WrappedFieldArrayProps } from 'redux-form';

const routeNotesTableCells = [
  { name: 'notes', label: translate('common.note'), width: '35%' },
  { name: 'sourceName', label: translate('common.source'), width: '20%' },
  { name: 'date', label: translate('common.timestamp'), width: '15%' },
  { name: 'options', label: translate('common.options'), width: '30%', align: 'right' },
];

interface ComponentProps extends WrappedFieldArrayProps {
  change: any;
  deleteRouteNote: DuckFunction<typeof deleteRouteNote>;
  isNewStop?: boolean;
  isReadOnly: boolean;
  routeId: number;
  routeLocationId: number;
  saveRouteNote: DuckFunction<typeof saveRouteNote>;
}

const RouteNotesFormFieldArray: React.FC<ComponentProps> = ({
  change,
  deleteRouteNote,
  fields,
  isNewStop,
  isReadOnly,
  routeId,
  routeLocationId,
  saveRouteNote,
}) => {
  const routeNotesTableRows = fields.map((fieldArrayKey, index, fields) => ({
    fieldArrayKey,
    index,
    fields,
  }));

  return (
    <Fragment>
      {!!routeNotesTableRows.length && (
        <Table
          withTopBorder
          cells={routeNotesTableCells}
          rows={routeNotesTableRows}
          rowComponent={RouteNotesFormTableRow}
          rowProps={{ routeId, routeLocationId, saveRouteNote, deleteRouteNote, change, isReadOnly }}
        />
      )}

      {!routeNotesTableRows.length && (
        <Message padding="no no sMedium sMedium">{translate('routes.noRouteNotes')}</Message>
      )}

      {!isReadOnly && (
        <ButtonSet margin="sMedium no sMedium">
          <PopoverWrapper
            triggerButton={
              <Button
                type="button"
                color="primary"
                disabled={!routeLocationId || isNewStop}
                line
                onClick={() => fields.push({})}
                id="add-route-note"
              >
                + {translate('routes.addRouteNote')}
              </Button>
            }
            popoverContent={
              (!routeLocationId || isNewStop) && (
                <Popover>
                  <Text block margin="xxSmall no xxSmall">
                    {translate('routes.alertMessages.addRouteNoteDisabled')}
                  </Text>
                </Popover>
              )
            }
            size="large"
          />
        </ButtonSet>
      )}
    </Fragment>
  );
};

export default RouteNotesFormFieldArray;
