import { change, isDirty, submit } from 'redux-form';
import { forEach, get, size } from 'lodash-es';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import {
  Button,
  GridColumn,
  MapContainer,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
  Text,
} from 'src/core/components/styled';
import { createErrorNotification, createWarningNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  DELIVERY_UTILITY_ID,
  FRONT_LOAD_ID,
  RESIDENTIAL_ID,
  ROLL_OFF_ID,
  TOTER_ID,
  WASTE_AUDIT_ID,
} from 'src/fleet/constants';
import {
  clearRouteMapSelectedFeature,
  exportRoute,
  loadRouteStops,
  resetRouteSegments,
  resetRouteStops,
  resetRouteStopsFilters,
  routeSequence,
} from 'src/routes/ducks';
import { getTextWithAccentSections } from 'src/landing/components/services';
import { MapDragHandle, ActionButtonTooltip, Table } from 'src/core/components';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { Grid } from 'src/core/components/styled';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { PermissionGuard } from 'src/account/components';
import { PICKUP_STATUSES } from 'src/common/constants';
import { removeLastLocation } from 'src/core/ducks';
import { ROUTE_MAP_FILTERS_FORM_NAME } from './routePageSections/routeMap/RouteMapFiltersForm';
import { ROUTE_STOPS_FORM_NAME } from './routePageSections/routeStops/RouteStopsForm';
import {
  RouteLocationImagesModalResolver,
  RouteLocationIssuesModalResolver,
  ServiceSideModalResolver,
} from '../../modals';
import { ROUTES_TRACKER_SEQUENCE_ROUTE, ROUTES_TRACKER_VIEW_EXPORT } from 'src/account/constants';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';
import { SCHEDULED, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE, IN_PROGRESS, COMPLETED } from 'src/routes/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import getRouteSequenceAdditionalConfigurations from 'src/routes/services/routeSequenceAdditionalConfigurations';
import routeAssistance from 'src/common/assets/img/insights/routeAssistance.png';
import RouteMapResolver from './routePageSections/routeMap/RouteMapResolver';
import RoutePageUnscheduledStopsTableRow from './RoutePageUnscheduledStopsTableRow';
import RoutePageWasteAuditNotesTableRow from './RoutePageWasteAuditNotesTableRow';
import RouteStopsResolver from './routePageSections/routeStops/RouteStopsResolver';
import RouteSummaryDetails from './routePageSections/RouteSummaryDetails';
import translate from 'src/core/services/translate';

type Props = {
  isUnscheduledStopsVisible?: boolean;
  routeLocationId?: number;
  shouldOpenImagesModal?: boolean;
};

export default function RoutePage({ isUnscheduledStopsVisible, routeLocationId, shouldOpenImagesModal }: Props) {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const [isEditMode, setEditMode] = useState(false);
  const [isServiceSideModalOpen, setServiceSideModalOpen] = useState(false);
  const [isRouteLocationIssuesModalOpen, setIsRouteLocationIssuesModalOpen] = useState(!!routeLocationId);
  const [isRouteImagesModalOpen, setIsRouteImagesModalOpen] = useState(!!shouldOpenImagesModal);
  const [isMapSmall, setIsMapSmall] = useState(false);

  const location = useLocation<{ routeLocationId?: number }>();

  const defaultRouteLocationId = location.state?.routeLocationId;

  const vendorId = useSelector(currentVendorId);
  const userName = useSelector(state => state.account.login.user.email);
  const { isExporting } = useSelector(state => state.routes.route);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { routeSequenceHistory } = useSelector(state => state.routes.routeSequence) as any;
  const {
    routeStops,
    routeStopsForSequence,
    isLoading: isRouteStopsLoading,
    isLoaded: isRouteStopsLoaded,
  } = useSelector(state => state.routes.routeStops);
  const {
    isLoading: isRouteWithVehicleTrackingLoading,
    filters: vehicleFilters,
    isVehicleTrackingLoaded,
  } = useSelector(state => state.routes.routeMapVehicleData);

  const { routeVehiclesBreadCrumbs: vehicleTrackingsForRoute, isLoadingRouteVehiclesBreadCrumbs } = useSelector(
    state => state.dashboard.routesData,
  );

  const { equipmentSizes } = useSelector(state => state.common.equipmentSizes);
  const { pickupStatusIds: filterPickupStatusIds, searchTerm: filterSearchTerm } = useSelector(
    state => state.routes.filters,
  );
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const routeSequencingSettings = useSelector(state => state.vendors.routeSequenceSettings.routeSequenceSettings);
  // const { geoFenceLoading } = useSelector(state => state.routes.geoFence);
  const { isLoading: isCityInsightsLoading } = useSelector(state => state.routes.routeMapCityInsights);
  const { isLoading: isHaulerLocationsLoading } = useSelector(state => state.dashboard.vendorLocations);
  const { lastLocations } = useSelector(state => state.core);
  const { routeLocationDetails, unscheduledStops, wasteAuditNotes } = useSelector(state => state.routes.routeStops);

  const { isLoading: isLoadingGeoFences } = useSelector(state => state.routes.geoFences);
  const { isDownloadingGeoJsonFile } = useSelector(state => state.routes.travelPath);
  const { geoFenceLoading } = useSelector(state => state.routes.geoFence);
  const { isLoading: isLoadingCityAlerts } = useSelector(state => state.vendors.cityAlerts);

  const isScheduledRoute = routeSummary?.routeStatusTypeId === SCHEDULED;
  const isInProgressRoute = routeSummary?.routeStatusTypeId === IN_PROGRESS;
  const isCompletedRoute = routeSummary?.routeStatusTypeId === COMPLETED;
  const isWasteAuditRoute = routeSummary?.vehicleTypeId === WASTE_AUDIT_ID;

  const handleLoadRouteStops = useCallback(() => {
    return new Promise((resolve, reject) => {
      dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, 'showAllStops', true));
      forEach(PICKUP_STATUSES, ({ id }) => {
        dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, `pickupStatusFilters[${id}]`, true));
      });
      setTimeout(() => {
        dispatch(submit(ROUTE_MAP_FILTERS_FORM_NAME));
        resolve(true);
      }, 0);
    });
  }, [dispatch]);

  const handleAutoLoadRouteStops = useCallback(() => {
    if (defaultRouteLocationId) {
      dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, 'defaultRouteLocationId', defaultRouteLocationId));
      handleLoadRouteStops().then(() => {
        change(ROUTE_MAP_FILTERS_FORM_NAME, 'defaultRouteLocationId', undefined);
      });
    }
  }, [defaultRouteLocationId, dispatch, handleLoadRouteStops]);

  useEffect(
    () => () => {
      dispatch(resetRouteStops());
      dispatch(resetRouteStopsFilters());
      dispatch(resetRouteSegments());
      dispatch(clearRouteMapSelectedFeature());
    },
    [dispatch],
  );

  if (!routeSummary) return null;

  const goBackToPreviousPage = () => {
    const createRoute = get(lastLocations, '/routes/route-tracker/create');
    if (createRoute) {
      removeLastLocation(createRoute);
    }

    const sequenceRoute = get(lastLocations, `/routes/route-tracker/${routeSummary.routeId}/route-sequence`);
    if (sequenceRoute) {
      removeLastLocation(sequenceRoute);
    }

    const oldRoute = get(lastLocations, `/routes/route-tracker-old/${routeSummary.routeId}`);
    if (oldRoute) {
      removeLastLocation(oldRoute);
    }

    const hasLastLocations = Object.keys(lastLocations).length > 1;

    return hasLastLocations && !createRoute && !sequenceRoute && !oldRoute
      ? goBack()
      : dispatch(push('/routes/route-tracker'));
  };

  const handleSequenceRoute = async () => {
    const routeHasMoreThan250Stops = routeStops.length > 250;
    const routeWasSequencedLessThan30DaysAgo =
      !!routeSequenceHistory && moment().subtract(30, 'days').isBefore(routeSequenceHistory.lastSequenceDate);
    const routeWasSequencedRecently = routeHasMoreThan250Stops && routeWasSequencedLessThan30DaysAgo;

    const optimizedStopsLength = routeStops.filter(
      routeStop => routeStop.orderNo === JOB_PENDING_OPTIMIZATION_ID,
    ).length;

    if (routeWasSequencedRecently) {
      if (
        !(await confirm(
          translate(
            optimizedStopsLength
              ? 'routes.alertMessages.confirmRecentlySequencedRouteSequencingWithOptimizedStops'
              : 'routes.alertMessages.confirmRecentlySequencedRouteSequencing',
          ),
        ))
      ) {
        return;
      }
    } else if (
      !(await confirm(
        translate(
          optimizedStopsLength
            ? 'routes.alertMessages.confirmRouteSequencingWithOptimizedStops'
            : 'routes.alertMessages.confirmRouteSequencing',
        ),
      ))
    ) {
      return;
    }

    setServiceSideModalOpen(true);
  };

  const startRouteSequence = async (formData: any) => {
    if (isDirty(ROUTE_STOPS_FORM_NAME)(dispatch)) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    const routeSequenceLocations = routeStopsForSequence.map(routeStopForSequence => {
      let size = 0;

      if (
        routeStopForSequence.equipmentSizeId &&
        (routeSummary.vehicleTypeId === ROLL_OFF_ID || routeSummary.vehicleTypeId === DELIVERY_UTILITY_ID)
      ) {
        const equipmentSize = equipmentSizes.find(
          equipmentSize => equipmentSize.id === routeStopForSequence.equipmentSizeId,
        );

        const numbersMatch = equipmentSize?.name.match(/\d/g);
        if (numbersMatch?.length) size = Number(numbersMatch[0]);
      }

      return {
        address: routeStopForSequence.street,
        id: routeStopForSequence.id,
        latitude: routeStopForSequence.binLatitude || routeStopForSequence.displayLatitude,
        longitude: routeStopForSequence.binLongitude || routeStopForSequence.displayLongitude,
        name: routeStopForSequence.locationName,
        orderNo: routeStopForSequence.orderNo,
        pickupTypeId: routeStopForSequence.pickupTypeId,
        size,
        streetNumber: routeStopForSequence.streetNumber,
        serviceContractId: routeStopForSequence.serviceContractId,
      };
    });

    const additionalConfigurations = getRouteSequenceAdditionalConfigurations(formData, routeSummary.vehicleTypeId);

    routeSequence({
      requestedBy: userName,
      routeId: routeSummary.routeId,
      routeSequenceLocations,
      sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
      vehicleType: routeSummary.vehicleTypeName,
      vendorId,
      ...additionalConfigurations,
    })(dispatch)
      .then(() => {
        createWarningNotification(`${translate('routes.alertMessages.routeSequenceRequestProcessing')}`);
        goBackToPreviousPage();
      })
      .catch(() => {
        createErrorNotification(`${translate('routes.alertMessages.routeSequenceRequestError')}`);
      });
  };

  const handleExportRoute = () => {
    // TODO:it should receive an array of Ids, now works with only an id
    exportRoute(
      routeSummary.routeId,
      filterPickupStatusIds && filterPickupStatusIds.length ? filterPickupStatusIds[0] : undefined,
      filterSearchTerm,
    )(dispatch);
  };

  const handleLoadBreadcrumbs = () => {
    const hasNoVehicles = !vehicleFilters.length;

    if (hasNoVehicles) {
      createErrorNotification(translate('routes.alertMessages.vehicleTrackingDataNotYetAvailable'));
    } else {
      dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, 'showVehicleTrackings', true));
      forEach(vehicleFilters, ({ vehicleId }) => {
        dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, `vehicleFilters[_${vehicleId}]`, true));
      });
      setTimeout(() => dispatch(submit(ROUTE_MAP_FILTERS_FORM_NAME)), 0);
    }
  };

  const handleResizeMap = (_: any, __: any, map: HTMLElement) => {
    if (map.offsetHeight < 200) !isMapSmall && setIsMapSmall(true);
    else isMapSmall && setIsMapSmall(false);
  };

  let routeSequencingEnabledForSpecificVehicleType = false;
  if (routeSequencingEnabled) {
    const routeSequencingSettingsFiltered = routeSequencingSettings.filter(
      routeSequenceSetting => routeSequenceSetting.vehicleTypeId === routeSummary.vehicleTypeId,
    );
    routeSequencingEnabledForSpecificVehicleType = routeSequencingSettingsFiltered[0]?.isActive;
  }

  const isRouteSequenceButtonVisible =
    [DELIVERY_UTILITY_ID, FRONT_LOAD_ID, RESIDENTIAL_ID, ROLL_OFF_ID, TOTER_ID].includes(routeSummary.vehicleTypeId) &&
    routeSummary.totalStopsCount > 1 &&
    routeSequencingEnabled &&
    routeSequencingEnabledForSpecificVehicleType &&
    isScheduledRoute;

  let routeAssistTooltip = '';
  if (routeSummary.assistingVehiclesActiveCount || routeSummary.assistingVehiclesCount) {
    routeAssistTooltip =
      translate('routeAssist.routeAssisted', { numberOfVehicles: routeSummary.assistingVehiclesCount }) +
      (routeSummary.assistingVehiclesActiveCount
        ? ` (${translate('routeAssist.activeVehicles', {
            numberOfVehicles: routeSummary.assistingVehiclesActiveCount,
          })})`
        : '');
  }

  const unscheduledStopsTableCells = [
    { name: 'stopNumber', label: translate('routes.stopNumber'), width: '10%' },
    { name: 'address', label: translate('common.address'), width: '45%' },
    { name: 'pickupStatus', label: translate('common.status'), width: '20%' },
    { name: 'reportDateTime', label: translate('routes.pickupTime'), width: '25%' },
  ];

  const wasteAuditNotesTableCells = [
    { name: 'note', label: translate('common.locationName'), width: '25%' },
    { name: 'stopStatus', label: translate('routes.stopStatus'), width: '25%' },
    { name: 'timestamp', label: translate('routes.timestamp'), width: '25%' },
    { name: 'wasteAuditLocationStatus', label: translate('routes.reportedAs'), width: '25%' },
  ];

  return (
    <PageContent isLoading={isExporting}>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={goBackToPreviousPage} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <Grid>
              <PageTitle>{routeSummary.name}</PageTitle>
              {(routeSummary.routeAssistanceEnabled ||
                !!(routeSummary.assistingVehiclesActiveCount || routeSummary.assistingVehiclesCount)) && (
                <ActionButtonTooltip
                  imageSrc={routeAssistance}
                  tooltip={routeAssistTooltip}
                  tooltipAsString
                  margin="small xSmall"
                />
              )}
            </Grid>
            {routeSummary.description && (
              <Grid>
                <PageSubtitle margin="no">{routeSummary.description}</PageSubtitle>
              </Grid>
            )}
          </PageTitleContainer>
        </PageDetails>
        <PageActions flex>
          {isRouteSequenceButtonVisible && (
            <PermissionGuard permission={ROUTES_TRACKER_SEQUENCE_ROUTE}>
              <Button color="primary" disabled={isEditMode} id="sequence-route-button" onClick={handleSequenceRoute}>
                {translate('routes.sequenceRoute')}
              </Button>
            </PermissionGuard>
          )}
          <PermissionGuard permission={ROUTES_TRACKER_VIEW_EXPORT}>
            <Button
              color="primary"
              onClick={handleExportRoute}
              margin="no no no xSmall"
              id="export-route-button"
              disabled={isExporting || isRouteStopsLoading || isRouteWithVehicleTrackingLoading || isEditMode}
            >
              {translate('common.export')}
            </Button>
          </PermissionGuard>
        </PageActions>
      </PageHeader>
      <Panel>
        <PanelSection>
          <RouteSummaryDetails />
        </PanelSection>
      </Panel>
      <Panel margin="xxSmall no">
        <PanelSection
          isLoading={
            isRouteStopsLoading ||
            isRouteWithVehicleTrackingLoading ||
            isCityInsightsLoading ||
            isHaulerLocationsLoading ||
            isLoadingRouteVehiclesBreadCrumbs ||
            geoFenceLoading ||
            isLoadingGeoFences ||
            isDownloadingGeoJsonFile ||
            isLoadingCityAlerts
          }
          centered
        >
          <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }} onResize={handleResizeMap}>
            <MapContainer size="large">
              <RouteMapResolver
                isEditMode={isEditMode}
                isMapSmall={isMapSmall}
                isUnscheduledStopsVisible={isUnscheduledStopsVisible}
                onFiltersReady={handleAutoLoadRouteStops}
              />
            </MapContainer>
          </Resizable>
        </PanelSection>
      </Panel>
      <Panel>
        {isRouteStopsLoaded ? (
          <RouteStopsResolver isEditMode={isEditMode} setEditMode={setEditMode} />
        ) : !!routeSummary?.totalStopsCount ? (
          <PanelSection
            padding="large no"
            isLoading={isRouteStopsLoading || isRouteWithVehicleTrackingLoading}
            centered
          >
            {!isVehicleTrackingLoaded &&
              !isScheduledRoute &&
              !vehicleTrackingsForRoute?.vehicles.length &&
              !isLoadingRouteVehiclesBreadCrumbs && (
                <Text margin="no xxSmall no no" onClick={handleLoadBreadcrumbs} size="large" cursor="pointer">
                  {getTextWithAccentSections(translate(`routes.loadBreadcrumbs`))}
                </Text>
              )}
            <Text onClick={handleLoadRouteStops} size="large" cursor="pointer">
              {getTextWithAccentSections(translate(`routes.loadRouteStops`))}
            </Text>
          </PanelSection>
        ) : (
          <PanelSection padding="no no small no" isLoading={isRouteStopsLoading || isRouteWithVehicleTrackingLoading}>
            <Grid multiLine>
              <GridColumn align="right" size="12/12">
                <Button
                  color="primary"
                  onClick={() => {
                    loadRouteStops(vendorId, routeSummary.routeId)(dispatch).then(() => setEditMode(true));
                  }}
                  margin="xSmall no"
                >
                  {translate('routes.editStops')}
                </Button>
              </GridColumn>
              <GridColumn align="center" size="12/12">
                <Message padding="sMedium">{translate('routes.noRouteStops')}</Message>
              </GridColumn>
            </Grid>
          </PanelSection>
        )}
      </Panel>

      {!!size(wasteAuditNotes) && isWasteAuditRoute && (
        <Panel margin="xLarge no">
          <PanelSectionGroup>
            <PanelSection vertical>
              <PanelSectionTitle margin="sMedium no sMedium sMedium">
                {translate('routes.wasteAuditNotes')}
              </PanelSectionTitle>
              <Table
                cells={wasteAuditNotesTableCells}
                rowComponent={RoutePageWasteAuditNotesTableRow}
                rows={wasteAuditNotes}
              />
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      )}

      {!!size(unscheduledStops) && isUnscheduledStopsVisible && (isInProgressRoute || isCompletedRoute) && (
        <Panel margin="no no xLarge">
          <PanelSectionGroup>
            <PanelSection vertical>
              <PanelSectionTitle margin="sMedium no sMedium sMedium">
                {translate('routes.unscheduledStops')}
              </PanelSectionTitle>
              <Table
                cells={unscheduledStopsTableCells}
                rowComponent={RoutePageUnscheduledStopsTableRow}
                rows={unscheduledStops}
              />
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      )}

      {isServiceSideModalOpen && (
        <ServiceSideModalResolver
          closeModal={() => setServiceSideModalOpen(false)}
          intermediateLocationId={routeSummary.intermediateLocationId}
          onSubmit={startRouteSequence}
          routeId={routeSummary.routeId}
          vehicleTypeId={routeSummary.vehicleTypeId}
        />
      )}

      {!!isRouteLocationIssuesModalOpen && routeLocationDetails && (
        <RouteLocationIssuesModalResolver
          modalTitle={routeLocationDetails.customerName}
          modalSubTitle={routeLocationDetails.locationName}
          routeId={routeSummary.routeId}
          routeLocationId={routeLocationId}
          closeModal={() => setIsRouteLocationIssuesModalOpen(false)}
          wasteAuditType={{ id: routeSummary.wasteMaterialTypeId, name: '', technicalName: '' }}
          vehicleTypeId={routeSummary.vehicleTypeId}
        />
      )}

      {!!isRouteImagesModalOpen && !!shouldOpenImagesModal && (
        <RouteLocationImagesModalResolver
          routeId={routeSummary.routeId}
          closeModal={() => setIsRouteImagesModalOpen(false)}
          routeLocationId={routeLocationId}
        />
      )}
    </PageContent>
  );
}
