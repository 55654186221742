import { useSelector, useDispatch } from 'react-redux';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';

import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadRubiconServiceRates } from 'src/customers/ducks/rubiconServicesRates';
import EditWorkOrderModal from './EditWorkOrderModal';
import { loadPickupFrequencyTypes } from 'src/common/ducks';
import { RubiconServicesHistory } from 'src/customers/interfaces/RubiconServices';

interface Props {
  closeModal: (pristine?: boolean, isReload?: boolean) => void;
  setTicketConfirmationValues: (values: any) => void;
  toggleTicketConfirmation: () => void;
  serviceId: number;
  serviceStatus: string;
  serviceHistory: RubiconServicesHistory;
  isRubiconServicesPage: boolean;
}

export default function EditWorkOrderModalResolver(props: Props) {
  const {
    closeModal,
    setTicketConfirmationValues,
    toggleTicketConfirmation,
    serviceId,
    serviceStatus,
    serviceHistory,
    isRubiconServicesPage,
  } = props;

  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    const dependencies: Promise<any>[] = [];
    dependencies.push(
      loadPickupFrequencyTypes()(dispatch),
      loadRubiconServiceRates(serviceId, serviceStatus, vendorId, userId)(dispatch),
    );
    return Promise.all(dependencies);
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={EditWorkOrderModal}
      successProps={{
        closeModal,
        setTicketConfirmationValues,
        toggleTicketConfirmation,
        serviceHistory,
        isRubiconServicesPage,
      }}
    />
  );
}
