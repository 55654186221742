import React, { ChangeEvent, PureComponent } from 'react';

import { map, size } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { MultiSelect, TypedField } from '../../core/components';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import { ROUTE_STATUSES_BY_ID } from '../constants/routeStatuses';
import translate from '../../core/services/translate';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('routes.allRouteStatuses')
    : translate('routes.xRouteStatusesSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
}

class RouteStatusesMultiSelect extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      multiSelectProps,
    } = this.props;

    const routeStatusesOptions = map(ROUTE_STATUSES_BY_ID, routeStatus => ({
      label: routeStatus.name,
      value: routeStatus.id,
    }));

    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('routes.routeStatus') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('routes.routeStatus') : undefined),
          options: routeStatusesOptions,
          formatText,
          ...multiSelectProps,
        }}
      />
    );
  }
}

export default RouteStatusesMultiSelect;
