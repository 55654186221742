import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import doLoadVersionStatuses from '../services/loadVersionStatuses';

// Actions
const START_LOAD = 'settings/versionStatuses/START_LOAD';
const COMPLETE_LOAD = 'settings/versionStatuses/COMPLETE_LOAD';
const FAIL_LOAD = 'settings/versionStatuses/FAIL_LOAD';
const RESET = 'settings/versionStatuses/RESET';

// Initial state
const initialState = {
  isLoading: false,
  versionStatuses: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          versionStatuses: action.versionStatuses,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          versionStatuses: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          versionStatuses: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (versionStatuses: any) => ({
  type: COMPLETE_LOAD,
  versionStatuses,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetVersionStatuses = () => ({
  type: RESET,
});

export const loadVersionStatuses = (vendorId: number, vehicleTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVersionStatusesPromise = doLoadVersionStatuses(vendorId, vehicleTypeId);
  loadVersionStatusesPromise
    .then(versionStatuses => dispatch(completeLoad(versionStatuses)))
    .catch(() => dispatch(failLoad()));
  return loadVersionStatusesPromise;
};

// Selectors
const getAppVersions = (versionStatusesState: any) => get(versionStatusesState, 'versionStatuses.appVersions');
export const appVersionsSelector = createSelector(getAppVersions, identity);

const getCurrentApplicationBuildNumber = (versionStatusesState: any) =>
  get(versionStatusesState, 'versionStatuses.currentApplicationBuildNumber');

export const currentApplicationBuildNumberSelector = createSelector(getCurrentApplicationBuildNumber, identity);
