import { find, has, map, reduce } from 'lodash-es';
import { OptionsType } from 'react-select';
import { PureComponent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import AsyncSelect from 'react-select/async';

import { DropdownOption, dropdownStyles } from './Dropdown';
import { FormError, FormGroup, FormLabel } from './styled';
import { theme } from '../styles';
import translate from '../services/translate';

const typeAheadStyles = {
  ...dropdownStyles,
  option: (baseStyle: any, state: any) => ({
    ...baseStyle,
    ':active': '#f2f2f3',
    paddingRight: '30px',
    backgroundColor: (state.isFocused && '#f3f4f3') || 'transparent',
    color: '#000',
    fontSize: '12px',
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    fontSize: '14px',
    color: theme.grayDark,
    margin: 0,
  }),
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '-8px',
  padding: '8px 12px',
  background: theme.grayLight,
};

const groupBadgeStyles = {
  display: 'inline-block',
  padding: '3px 6px',
  backgroundColor: '#ebecf0',
  borderRadius: '100%',
  textAlign: 'center',
  lineHeight: '1',
  fontWeight: theme.fontWeightMedium,
  fontSize: '10px',
  color: '#172b4d',
};

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles as any}>{data.options.length}</span>
  </div>
);

interface Props extends WrappedFieldProps {
  isMulti?: boolean;
  defaultOptions?: any[];
  defaultValue?: object;
  disabled?: boolean;
  formShouldRefresh?: boolean;
  getOptionLabel?: (option: any) => string;
  getOptions: (searchTerm: string, onOptionsLoaded: (options: OptionsType<any>) => void) => void;
  id?: string;
  inputValue?: string;
  isClearable?: boolean;
  label?: string;
  margin?: string;
  placeholder?: string;
  inputDefaultValue?: any;
}

interface State {
  multipleValue: DropdownOption[];
  inputText: string;
  option: any;
}

class TypeAhead extends PureComponent<Props, State> {
  static defaultProps = {
    defaultOptions: [],
    formShouldRefresh: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = { inputText: '', option: undefined, multipleValue: [] };
  }

  componentDidMount() {
    const { isMulti } = this.props;
    if (isMulti) {
      this.props.input.onChange([]);
    }
  }

  componentDidUpdate() {
    const {
      isMulti,
      input: { value },
    } = this.props;
    if (isMulti && value && !value.length && this.state.multipleValue && !!this.state.multipleValue.length) {
      this.setState({ multipleValue: [] });
    }
  }
  onInputChange = (inputText: string) => {
    const { isMulti } = this.props;
    if (!isMulti) this.setState({ inputText });
  };

  onChange = (option: any) => {
    const {
      isClearable,
      isMulti,
      input: { onChange },
    } = this.props;
    if (option && option.length === undefined) {
      const value = has(option, 'value') ? option.value : null;
      this.setState({ option });
      onChange(value);
    } else if (isClearable && !isMulti) {
      this.setState({ inputText: '', option: undefined });
      onChange(null);
    } else {
      this.setState({ multipleValue: option });
      onChange(map(option, (el: any) => el.value));
    }
  };

  findSelectedOption = (value: string) => {
    if (value === '') return '';

    if (this.props.inputValue === null) {
      return '';
    }

    reduce(
      (this.state.option ? [this.state.option] : []).concat(this.props.defaultOptions || []),
      (selectedOption, option) => {
        if (selectedOption) return selectedOption;
        if (option.value && option.value === value) return option;
        return find(option.options, { value }) || '';
      },
      '',
    );
  };

  noOptionsMessage = ({ inputValue }: any) =>
    inputValue.length < 3 ? translate('common.searchMinCharacters', { count: 3 }) : translate('common.noResults');

  render() {
    const {
      input: { value },
      meta: { submitFailed, error },
      disabled,
      placeholder,
      label,
      getOptions,
      margin,
      inputValue,
      defaultValue,
      getOptionLabel,
      formShouldRefresh,
      defaultOptions,
      isMulti,
      inputDefaultValue,
      ...props
    } = this.props;

    const { inputText, option } = this.state;

    const typeAheadValue = inputDefaultValue
      ? inputDefaultValue
      : isMulti
      ? this.state.multipleValue
      : formShouldRefresh
      ? this.findSelectedOption(value)
      : option || this.findSelectedOption(value);

    return (
      <FormGroup
        margin={margin}
        hasValue={
          (typeof value === 'string' && value) ||
          (typeof value === 'number' && value) ||
          (typeof value === 'object' && !!Object.keys(value).length) ||
          inputText
        }
        disabled={disabled}
      >
        {!!label && <FormLabel>{label}</FormLabel>}

        <AsyncSelect
          {...props}
          isMulti={isMulti}
          defaultOptions={!formShouldRefresh && option ? [option] : defaultOptions}
          defaultValue={defaultValue == null ? '' : defaultValue}
          isAsync
          isDisabled={disabled}
          styles={typeAheadStyles}
          formatGroupLabel={formatGroupLabel}
          getOptionLabel={getOptionLabel}
          loadOptions={getOptions}
          noOptionsMessage={this.noOptionsMessage}
          placeholder={placeholder || ''}
          value={typeAheadValue}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
        />
        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

export default TypeAhead;
