import { filter, find } from 'lodash-es';
import { useDispatch } from 'react-redux';
import React, { useMemo } from 'react';

import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { DASHBOARD_VEHICLE_TRACKINGS_SOURCE } from 'src/dashboard/constants/vehicleTrackings';
import { findClosestPointOnLine } from 'src/utils/mappingUtils';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { getVehicleTrackingGroupCenterMapBox } from 'src/dashboard/utils/vehicleTrackings';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { RouteVehicleCoord } from 'src/dashboard/interfaces/routesData';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehicleBreadcrumbs } from 'src/dashboard/interfaces/vehiclePositions';
import DashboardVehicleTrackingsGLPopup from './DashboardVehicleTrackingsGLPopup';

interface Props {
  isOnDemandVideoDownloadEnabled: boolean;
}

const DashboardVehicleTrackingsGLPopupResolver: React.FC<Props> = ({ isOnDemandVideoDownloadEnabled }) => {
  const dispatch = useDispatch();

  const vehicleTrackings = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs as VehicleBreadcrumbs);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);
  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.namespace !== 'vehicleTrackings' ||
      selectedFeature.vehicleTrackingGroupIndex === undefined ||
      selectedFeature.coordinates === undefined
    ) {
      return undefined;
    }

    const { vehicleTrackingGroupIndex: groupIndex, vehicleTrackingsSource, id, sourceId } = selectedFeature;

    let group: RouteVehicleCoord[] = [];

    if (vehicleTrackingsSource === 'route') {
      const filtered = filter(vehicleTrackingsForRoute?.vehicles, v => v.id === id && v.coords.length > 0);
      if (filtered.length > 0) {
        group = filtered[0].coords[groupIndex];
      }
    } else group = vehicleTrackings?.coords[groupIndex];

    if ((!vehicleTrackings && !vehicleTrackingsForRoute) || !group) {
      return undefined;
    }

    let closestCoordinate: RouteVehicleCoord;

    if (sourceId === DASHBOARD_VEHICLE_TRACKINGS_SOURCE) {
      closestCoordinate = findClosestPointOnLine<RouteVehicleCoord>(
        selectedFeature.coordinates.lat,
        selectedFeature.coordinates.lng,
        group,
      );
    } else {
      closestCoordinate = group.reduce((prev, curr) => {
        if (!selectedFeature.coordinates) return prev;
        const prevDistance = Math.sqrt(
          Math.pow(prev.lat - selectedFeature.coordinates.lat, 2) +
            Math.pow(prev.lng - selectedFeature.coordinates.lng, 2),
        );
        const currDistance = Math.sqrt(
          Math.pow(curr.lat - selectedFeature.coordinates.lat, 2) +
            Math.pow(curr.lng - selectedFeature.coordinates.lng, 2),
        );

        return prevDistance < currDistance ? prev : curr;
      }, [] as any);
    }

    const vehicleForRoute = find(vehicleTrackingsForRoute?.vehicles, { id });

    return {
      vehicle: vehicleTrackingsSource === 'route' ? vehicleForRoute?.name || '' : vehicleTrackings?.name,
      id: selectedFeature.id,
      selectedData: closestCoordinate,
      shouldDisplayDownloadVideo: vehicleTrackingsSource === 'route' ? vehicleForRoute?.hdv : vehicleTrackings?.hdv,
      videoDeviceTypeId: vehicleTrackingsSource === 'route' ? vehicleForRoute?.vdtId : vehicleTrackings.vdtId,
      anchor: selectedFeature.coordinates
        ? {
            latitude: selectedFeature.coordinates.lat,
            longitude: selectedFeature.coordinates.lng,
          }
        : getVehicleTrackingGroupCenterMapBox(vehicleTrackings),
    };
  }, [selectedFeature, vehicleTrackings, vehicleTrackingsForRoute]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.anchor.latitude}
      longitude={selectedTracking.anchor.longitude}
      onClose={() => dispatch(clearDashboardSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingGroupIndex}`}
        successComponent={DashboardVehicleTrackingsGLPopup}
        successProps={{
          showTimestamp: true,
          speed: selectedTracking.selectedData.sp,
          unitOfMeasure: getMeasurementType(systemOfMeasurementId),
          vehicleId: selectedTracking.id,
          vehicleName: selectedTracking.vehicle,
          timestamp: selectedTracking.selectedData.ts,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled,
          videoDeviceTypeId: selectedTracking.videoDeviceTypeId,
          deviceId: selectedTracking.selectedData.deid,
          applicationModeStatusId: selectedTracking.selectedData.am,
        }}
        loadingComponent={MapGLPopupLoading}
      />
    </MapGLPopupWrapper>
  );
};

export default DashboardVehicleTrackingsGLPopupResolver;
