import { PAYMENTS, AUTO_DISPATCH, WORK_ORDERS, OPPORTUNITIES } from 'src/opportunity/constants/status';

import matchRoute from 'src/utils/services/matchRoute';

export const getTokenFromUrl = (pathname: string, routeName: string) => {
  if (routeName === OPPORTUNITIES.toLowerCase() || routeName === PAYMENTS) {
    return matchRoute(pathname, '/opportunities') && pathname.split('/').length === 4
      ? pathname.substring(pathname.lastIndexOf('/') + 1)
      : null;
  } else if (routeName === AUTO_DISPATCH) {
    return matchRoute(pathname, '/fleet/autodispatch') && pathname.split('/').length === 5
      ? pathname.substring(pathname.lastIndexOf('/') + 1)
      : null;
  } else if (routeName === PAYMENTS) {
    return pathname.split('/').length === 4 ? pathname.substring(pathname.lastIndexOf('/') + 1) : null;
  } else if (routeName === WORK_ORDERS.toLowerCase()) {
    return pathname.split('/').length === 3 ? pathname.substring(pathname.lastIndexOf('/') + 1) : null;
  }
};
