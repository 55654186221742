import { reduxForm, InjectedFormProps } from 'redux-form';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { ButtonSet, Button, Grid, GridColumn } from 'src/core/components/styled';
import { Input, TypedField } from 'src/core/components';
import { isRequired } from 'src/utils/services/validator';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

interface ComponentProps extends RouteComponentProps {
  currentQuantity: number;
  id: number;
  isAddInventory: boolean;
  isAddNewInventory: boolean;
  partName: string;
  onCancel: (pristine: boolean) => void;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class EditInventoryForm extends Component<Props> {
  render() {
    const { handleSubmit, onCancel, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Grid>
          <GridColumn size={'9/12'} padding="medium no">
            <TypedField
              name={'partName'}
              component={Input}
              validate={[isRequired]}
              props={{
                label: translate('containers.part'),
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>

        <ButtonSet margin="large no no no">
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  initialValues: { partName: ownProps.partName },
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, any>({
      form: 'editInventory',
      enableReinitialize: true,
      onSubmitFail: focusFirstInvalidField,
    })(EditInventoryForm),
  ),
);
