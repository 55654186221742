import { Fragment, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { find, includes, sumBy, camelCase, map } from 'lodash-es';
import { formValueSelector, Field, reduxForm, InjectedFormProps } from 'redux-form';
import React, { useState } from 'react';
import styled from 'styled-components';

import { AppState } from '../../../store';
import { AMOUNT, PERCENT } from '../../constants';
import { CHANGE_OF_BILLING } from '../../constants/requestType';
import { Container, ButtonSet, Button, Grid, GridColumn, Text } from '../../../core/components/styled';
import {
  BUCKET,
  CADDY,
  CAGE,
  COMPACTER,
  COOKING_OIL,
  DISP,
  DRUM,
  EXCFEE,
  FUEL,
  GREASE,
  HAND_PICKUP,
  HAUL,
  JETTNG,
  MONTH,
  OVERSIZED_TIRES,
  PASSENGER_TIRES,
  RENTAL_RECEIVER_BOX,
  RENTAL_SELF_CONTAINED,
  RENTAL_VERTICAL_COMPACTOR,
  RENTVO,
  ROLL_OFF,
  TANK,
  TIRES,
  TRAP,
  TRAILER,
  UNSPECIFIED,
} from '../../constants/status';
import { Equipment } from '../../interfaces/Opportunities';
import { DaysOfWeekPicker, Dropdown, Input, TypedField } from '../../../core/components';
import { getFrequencyTranslated } from 'src/customers/components/forms/EditWorkOrderForm';
import { isRequired } from '../../../utils/services/validator';
import { useIsMobileView } from 'src/utils/hooks';
import enterBidFormInitialValueSelector from '../../services/enterBidFormInitialValueSelector';
import {
  ONE_X_PER_WEEK,
  TWO_X_PER_WEEK,
  THREE_X_PER_WEEK,
  FOUR_X_PER_WEEK,
  FIVE_X_PER_WEEK,
  SIX_X_PER_WEEK,
  SEVEN_X_PER_WEEK,
} from '../../constants/serviceFrequencies';
import translate from '../../../core/services/translate';

const CurrencyContainer = styled.div<{ isMobile: boolean }>`
  position: absolute;
  left: ${p => (p.isMobile ? '-10px' : '0')};
  bottom: ${p => (p.isMobile ? '22px' : '27px')};
`;

const CTR_NAME = 'service';

const formSelector = formValueSelector('enterBid');

interface ComponentProps {
  caseId: number;
  eventType: string;
  equipment: Equipment;
  equipmentType: string;
  isEquipmentCustomerOwned: boolean;
  itemId: number;
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  requestedChanges: any;
  requestedChangesState: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const EnterBidForm: React.FC<Props> = ({
  caseId,
  eventType,
  equipment,
  equipmentType,
  isEquipmentCustomerOwned,
  requestedChanges,
  requestedChangesState,
  change,
  handleSubmit,
  onCancel,
  pristine,
}) => {
  const { type, subType } = equipment;
  const isMobile = useIsMobileView();
  const marketSubTypes = useSelector((state: AppState) => state.opportunity.opportunities.marketSubTypes);
  const lookupOptions = useSelector((state: AppState) => state.customers.rubiconTypes.rubiconServicesOptions);

  const [monthlyTotal, setMonthlyTotal] = useState(requestedChangesState.overallserviceCharge || 0);
  const [selectedFrequency, setSelectedFrequency] = useState(requestedChangesState.frequencyId);

  const equipmentOptions = lookupOptions.equipments ? lookupOptions.equipments : [];
  const equipmentSizeOptions = lookupOptions.equipmentSize ? lookupOptions.equipmentSize : [];
  const frequencyOptions = lookupOptions.frequency ? lookupOptions.frequency : [];
  const materialOptions = lookupOptions.materials ? lookupOptions.materials : [];

  const { wasteMaterialId } = equipment;

  const getEquipmentSizes = (subType: string) => {
    const filterEquipmentsByEquipmentType = equipmentSizeOptions?.filter(
      (equipmentSize: any) => equipmentSize.type === subType && equipmentSize.code === type,
    );
    return map(filterEquipmentsByEquipmentType, ({ code, description }) => ({
      label: description,
      value: code,
    }));
  };

  const [equipmentSizeByEquipmentType, setEquipmentSizeByType] = useState(getEquipmentSizes(subType));

  const frequencyOptionsFormatted = frequencyOptions.map(frequency => {
    return {
      label: getFrequencyTranslated(frequency.label),
      value: frequency.value,
    };
  });

  const marketSubTypesOptions = marketSubTypes.map(marketSubType => {
    return {
      label: marketSubType.description,
      value: marketSubType.subtype,
    };
  });

  const onEquipmentTypeChange = (event: any, value: string) => {
    const filterEquipmentsByEquipmentType = equipmentSizeOptions.filter(
      (equipmentSize: any) => equipmentSize.type === value,
    );

    const equipmentSizeList = map(filterEquipmentsByEquipmentType, ({ code, description }) => ({
      label: description,
      value: code,
    }));

    setEquipmentSizeByType(equipmentSizeList);
  };

  const calculateTotalMonthlyServiceFee = (ctrName: string, taxValue: number, rateCode: string, id?: number) => {
    let total = 0;
    requestedChanges.haulerRateDetails.forEach((fees: any, index: number) => {
      if (fees.isAdditionalCharge && fees.rateType === PERCENT && ctrName !== CTR_NAME) {
        if (id === index) {
          total += taxValue;
        } else {
          total += parseInputValue(fees.rateInDollar);
        }
      } else if (!fees.isAdditionalCharge && fees.uom === MONTH) {
        if (ctrName === CTR_NAME && rateCode === fees.rateCd) {
          total += taxValue;
        } else {
          total += parseInputValue(fees.rateInDollar);
        }
      } else if (ctrName === CTR_NAME && fees.isAdditionalCharge && fees.rateType === PERCENT) {
        if (rateCode.includes(HAUL)) {
          total += onServiceChangeCalculateTaxbasedPercentage(
            taxValue,
            `${`settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}`,
            requestedChanges.haulerRateDetails[index].rate,
          );
        } else {
          total += parseInputValue(fees.rateInDollar);
        }
      }
    });
    const totalMonthlyFee = Math.round((total + Number.EPSILON) * 100) / 100;
    return totalMonthlyFee;
  };

  const onServiceChangeCalculateTaxbasedPercentage = (serviceValue: number, ctrNamePath: string, value: number) => {
    const taxValue = serviceValue ? (serviceValue * (value || 0)) / 100 : 0;
    change(ctrNamePath, Math.round((taxValue + Number.EPSILON) * 100) / 100);
    return taxValue;
  };

  const onTaxInDollarChangeCalculatePercentage = (name: any, value: number, rateCode: string, id: number) => {
    const service = calculateServiceRate(rateCode);
    const taxPercentage = service ? (100 * (value || 0)) / service : 0;
    const ctrNamePath = name.currentTarget.name.substr(0, name.currentTarget.name.length - 8);
    const ctrName = ctrNamePath.split('.');
    change(ctrNamePath, Math.round((taxPercentage + Number.EPSILON) * 100) / 100);

    const totalMonthlyServiceFees =
      equipmentType === ROLL_OFF
        ? calculateHaulDispoalFee(ctrName[ctrName.length - 1], value, rateCode, id)
        : calculateTotalMonthlyServiceFee(ctrName[ctrName.length - 1], value || 0, rateCode, id);
    setMonthlyTotal(totalMonthlyServiceFees);
  };

  const onPercentageChangeCalculateTaxInDollar = (name: any, value: number, rateCode: string, id: number) => {
    const service = calculateServiceRate(rateCode);
    const taxValue = service ? (service * (value || 0)) / 100 : 0;
    const ctrName = name.currentTarget.name.split('.');
    const ctrNamePath = `${name.currentTarget.name}InDollar`;
    change(ctrNamePath, Math.round((taxValue + Number.EPSILON) * 100) / 100);

    const totalMonthlyServiceFees =
      equipmentType === ROLL_OFF
        ? calculateHaulDispoalFee(ctrName[ctrName.length - 1], taxValue, rateCode, id)
        : calculateTotalMonthlyServiceFee(ctrName[ctrName.length - 1], taxValue, rateCode, id);
    setMonthlyTotal(totalMonthlyServiceFees);
  };

  const calculateServiceRate = (rateCode: string) => {
    let service = 0;
    if (equipmentType === ROLL_OFF) {
      service = getHaulAndDisposalRate(rateCode);
    } else if (equipmentType === TRAP && wasteMaterialId === GREASE) {
      service = rateCode.includes(FUEL)
        ? getServiceRate()
        : sumBy(
            requestedChanges.haulerRateDetails.filter(
              (haulerRateDetail: any) =>
                includes(haulerRateDetail.rateCd, HAUL) || includes(haulerRateDetail.rateCd, JETTNG),
            ),
            (o: any) => Number(o.rateInDollar),
          );
    } else if (
      wasteMaterialId === COOKING_OIL &&
      (equipmentType === BUCKET || equipmentType === CADDY || equipmentType === DRUM || equipmentType === TANK)
    ) {
      service = sumBy(
        requestedChanges.haulerRateDetails.filter(
          (haulerRateDetail: any) => includes(haulerRateDetail.rateCd, HAUL) || includes(haulerRateDetail.rateCd, DISP),
        ),
        (o: any) => Number(o.rateInDollar),
      );
    } else if (
      (wasteMaterialId === PASSENGER_TIRES || wasteMaterialId === TIRES || wasteMaterialId === OVERSIZED_TIRES) &&
      (equipmentType === CAGE ||
        equipmentType === HAND_PICKUP ||
        equipmentType === TRAILER ||
        equipmentType === UNSPECIFIED)
    ) {
      service = rateCode.includes(FUEL)
        ? getServiceRate()
        : sumBy(
            requestedChanges.haulerRateDetails.filter(
              (haulerRateDetail: any) =>
                includes(haulerRateDetail.rateCd, HAUL) || includes(haulerRateDetail.rateCd, EXCFEE),
            ),
            (o: any) => Number(o.rateInDollar),
          );
    } else if (
      equipmentType === RENTAL_RECEIVER_BOX ||
      equipmentType === RENTAL_SELF_CONTAINED ||
      equipmentType === RENTAL_VERTICAL_COMPACTOR ||
      equipmentType === COMPACTER
    ) {
      service = sumBy(
        requestedChanges.haulerRateDetails.filter((haulerRateDetail: any) => includes(haulerRateDetail.rateCd, RENTVO)),
        (o: any) => Number(o.rateInDollar),
      );
    } else {
      service = getServiceRate();
    }
    return service;
  };
  const getServiceRate = () => {
    const objHaulerRateDetails = requestedChanges.haulerRateDetails.find(
      (haulerRateDetail: any) => !haulerRateDetail.isAdditionalCharge && haulerRateDetail.rateCd.includes(HAUL),
    );
    const service = objHaulerRateDetails && objHaulerRateDetails.rateInDollar ? objHaulerRateDetails.rateInDollar : 0;
    return service;
  };

  const getHaulAndDisposalRate = (rateCode: string) => {
    const haulingAndDisposalFee = rateCode.includes(FUEL)
      ? sumBy(
          requestedChanges.haulerRateDetails.filter((haulerRateDetail: any) => includes(haulerRateDetail.rateCd, HAUL)),
          (o: any) => Number(o.rateInDollar),
        )
      : sumBy(
          requestedChanges.haulerRateDetails.filter(
            (haulerRateDetail: any) =>
              includes(haulerRateDetail.rateCd, HAUL) || includes(haulerRateDetail.rateCd, DISP),
          ),
          (o: any) => Number(o.rateInDollar),
        );
    const service = parseInputValue(haulingAndDisposalFee) || 0;
    return service;
  };

  const onServiceChangeCalculateTotalMonthlyServiceFee = (name: any, value: number, rateCode: string, uom: string) => {
    if (equipmentType !== ROLL_OFF && uom === MONTH) {
      const totalMonthlyServiceFees = calculateTotalMonthlyServiceFee(CTR_NAME, parseInputValue(value), rateCode);
      setMonthlyTotal(totalMonthlyServiceFees);
    } else if (equipmentType === ROLL_OFF && (rateCode.includes(HAUL) || rateCode.includes(DISP))) {
      calculateHaulDispoalFee(CTR_NAME, parseInputValue(value), rateCode);
    }
  };

  const calculateHaulDispoalFee = (ctrName: string, taxValue: number, rateCode: string, id?: number) => {
    let HauleAndDisposalFee = 0;
    requestedChanges.haulerRateDetails.forEach((fees: any, index: number) => {
      if (!fees.isAdditionalCharge && (includes(fees.rateCd, HAUL) || includes(fees.rateCd, DISP))) {
        if (ctrName === CTR_NAME && rateCode === fees.rateCd) {
          HauleAndDisposalFee += taxValue;
        } else {
          HauleAndDisposalFee += parseInputValue(fees.rateInDollar);
        }
      } else if (ctrName === CTR_NAME && fees.isAdditionalCharge && fees.rateType === PERCENT) {
        onServiceChangeCalculateTaxbasedPercentage(
          HauleAndDisposalFee,
          `${`settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}`,
          requestedChanges.haulerRateDetails[index].rate,
        );
      }
    });
  };

  const parseInputValue = (value: any) => {
    if (!value) return 0;
    return Number(value);
  };

  const onFrequencyChange = (event: any, value: string) => {
    if (value !== selectedFrequency) {
      setSelectedFrequency(value);
    }
  };

  const isValidNumberOfDays = useCallback(
    (value: any, formValues: any) => {
      const selectedDays = value.length;
      if (selectedDays !== parseInt(selectedFrequency)) {
        return `Select ${selectedFrequency} days.`;
      }
      return undefined;
    },
    [selectedFrequency],
  );

  const isFrequencyDaysVisible =
    selectedFrequency === ONE_X_PER_WEEK ||
    selectedFrequency === TWO_X_PER_WEEK ||
    selectedFrequency === THREE_X_PER_WEEK ||
    selectedFrequency === FOUR_X_PER_WEEK ||
    selectedFrequency === FIVE_X_PER_WEEK ||
    selectedFrequency === SIX_X_PER_WEEK ||
    selectedFrequency === SEVEN_X_PER_WEEK;

  return (
    <form onSubmit={handleSubmit} noValidate>
      {eventType === CHANGE_OF_BILLING && (
        <Grid multiLine>
          <Grid>
            <GridColumn size="6/12">
              <TypedField
                name={`settings${caseId}.requestedChanges.equipmentSubType`}
                component={Dropdown}
                onChange={onEquipmentTypeChange}
                props={{
                  options: equipmentOptions,
                  label: translate('common.equipmentType'),
                  isClearable: true,
                  disabled: true,
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name={`settings${caseId}.requestedChanges.equipmentName`}
                component={Dropdown}
                props={{
                  options: equipmentSizeByEquipmentType,
                  label: translate('common.equipmentSize'),
                  isClearable: true,
                }}
              />
            </GridColumn>
          </Grid>

          <Grid>
            <GridColumn size="6/12">
              <TypedField
                name={`settings${caseId}.requestedChanges.frequencyId`}
                component={Dropdown}
                onChange={onFrequencyChange}
                props={{
                  options: frequencyOptionsFormatted,
                  label: translate('customers.frequency'),
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name={`settings${caseId}.requestedChanges.marketSubType`}
                component={Dropdown}
                props={{
                  options: marketSubTypesOptions,
                  label: translate('opportunity.opportunities.marketCondition'),
                  isClearable: true,
                }}
              />
            </GridColumn>
          </Grid>
          <Grid>
            <GridColumn size="6/12">
              <TypedField
                name={`settings${caseId}.requestedChanges.materialType`}
                component={Dropdown}
                props={{
                  options: materialOptions,
                  label: translate('common.material'),
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name={`settings${caseId}.requestedChanges.quantity`}
                component={Input}
                props={{
                  label: translate('containers.quantity'),
                }}
              />
            </GridColumn>
          </Grid>
          {isFrequencyDaysVisible && (
            <Grid>
              <GridColumn size={'12/12'}>
                <TypedField
                  name={`settings${caseId}.requestedChanges.frequencyDays`}
                  component={DaysOfWeekPicker}
                  validate={[isRequired, isValidNumberOfDays]}
                  props={{
                    label: translate('common.dayOfWeek'),
                    daysOfWeekProps: { margin: 'xSmall' },
                    dayOfWeekProps: { margin: 'xSmall' },
                    multiple: true,
                  }}
                />
              </GridColumn>
            </Grid>
          )}
        </Grid>
      )}
      {requestedChangesState.haulerRateDetails.map((fees: any, index: number) => (
        <Container key={index}>
          {includes(fees.rateCd, HAUL) && fees.uom === MONTH && (
            <Grid margin="no no small">
              <GridColumn padding={isMobile ? 'no' : undefined} size="12/12">
                {eventType !== CHANGE_OF_BILLING && (
                  <Text block weight="medium" size="large" color="primary">
                    {translate('opportunity.monthlyTotal', {
                      total: monthlyTotal,
                    })}
                  </Text>
                )}
              </GridColumn>
            </Grid>
          )}

          {!fees.isAdditionalCharge && (
            <Grid>
              <GridColumn padding="no" size="12/12">
                <CurrencyContainer isMobile={isMobile}>
                  <span style={{ color: 'red' }}>*</span>
                  <span>$</span>
                </CurrencyContainer>

                <Field
                  name={
                    !!fees.rateType
                      ? `settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`
                      : `settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`
                  }
                  component={Input}
                  type="number"
                  label={`${translate(`opportunity.haulingRates.${camelCase(fees.rateDesc)}`)} (${translate(
                    `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                  )})`}
                  validate={fees.isAccessoryRequired || fees.mandatory ? isRequired : undefined}
                  margin={isMobile ? 'xSmall no' : 'no sMedium small'}
                  onChange={(name: any, value: number) =>
                    onServiceChangeCalculateTotalMonthlyServiceFee(name, parseInputValue(value), fees.rateCd, fees.uom)
                  }
                />
              </GridColumn>
            </Grid>
          )}
          {fees.isAdditionalCharge && fees.rateType === PERCENT && (
            <Grid margin={!isMobile ? 'no small' : 'no'}>
              <GridColumn padding="no" size="7/12">
                <CurrencyContainer isMobile={isMobile}>$</CurrencyContainer>
                <Field
                  name={`settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}
                  component={Input}
                  type="number"
                  label={translate(`opportunity.haulingRates.${camelCase(fees.rateDesc)}`)}
                  margin={isMobile ? 'xSmall no' : 'no small small'}
                  onChange={(name: any, value: number) =>
                    onTaxInDollarChangeCalculatePercentage(name, parseInputValue(value), fees.rateCd, index)
                  }
                />
              </GridColumn>
              <GridColumn size="5/12">
                <Field
                  name={`settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                  component={Input}
                  type="number"
                  label="%"
                  margin={isMobile ? 'xSmall no' : 'no small small'}
                  onChange={(name: any, value: number) =>
                    onPercentageChangeCalculateTaxInDollar(name, parseInputValue(value), fees.rateCd, index)
                  }
                />
              </GridColumn>
            </Grid>
          )}
          {fees.isAdditionalCharge && fees.rateType === AMOUNT && (
            <Grid>
              <GridColumn padding="no" size="12/12">
                <CurrencyContainer isMobile={isMobile}>
                  <span style={{ color: 'red' }}>*</span>
                  <span>$</span>
                </CurrencyContainer>
                <Field
                  name={`settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}
                  component={Input}
                  type="number"
                  label={`${fees.rateDesc} (${translate(`opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`)})`}
                  validate={fees.isAccessoryRequired || fees.mandatory ? isRequired : undefined}
                  margin={isMobile ? 'xSmall no' : 'no sMedium small'}
                  onChange={(name: any, value: number) =>
                    onServiceChangeCalculateTotalMonthlyServiceFee(name, parseInputValue(value), fees.rateCd, fees.uom)
                  }
                />
              </GridColumn>
            </Grid>
          )}
        </Container>
      ))}
      {eventType !== CHANGE_OF_BILLING && (
        <Container>
          <TypedField
            name={`settings${caseId}.requestedChanges.notes`}
            component={Input}
            props={{
              margin: 'medium small xLarge',
              label: translate('common.note'),
            }}
          />
        </Container>
      )}
      <Container>
        {isEquipmentCustomerOwned && (
          <Fragment>
            <Text margin="xLarge no xSmall" size="small" color="alert">
              {translate('common.note')}:
            </Text>
            <Text weight="normal" size="small" padding="no xxSmall">
              {translate('opportunity.opportunities.messages.deliveryNotRequired')}
            </Text>
          </Fragment>
        )}
      </Container>

      <ButtonSet margin="large auto no">
        <Button type="submit" color="primary">
          {translate('opportunity.submit')}
        </Button>
        <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ButtonSet>
    </form>
  );
};

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { caseId } = ownProps;
  const opportunity = find(state.opportunity.opportunities.opportunities, { caseId }) as any;
  return {
    initialValues: enterBidFormInitialValueSelector(state.opportunity.opportunities.opportunities),
    requestedChanges: formSelector(state, `settings${caseId}.requestedChanges`),
    requestedChangesState: opportunity.requestedChanges,
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'enterBid',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EnterBidForm),
);
