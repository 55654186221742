import { map } from 'lodash-es';
import translate from '../../core/services/translate';

const transformLoadDrivers = (drivers: any[]) =>
  map(drivers, driver => ({
    ...driver,
    name: `${driver.firstName} ${driver.lastName}`,
    isActive: driver.isActive ? translate('common.yes') : translate('common.no'),

    /**
     * I know, funky, but the guy who did the conversion on row 8 (isActive: 'yes' | 'no')
     * forced me to do this...
     * Luckily, we have Git Blame.
     */
    isActiveBoolean: driver.isActive,
  }));

export default transformLoadDrivers;
