import { FC } from 'react';
import { FieldArray, arrayPush, change, formValueSelector } from 'redux-form';
import { useDispatch } from 'react-redux';

import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import {
  BulkySchedulerLimitByDaySettings,
  BulkySchedulerLimitByTimeRangeSettings,
} from 'src/vendors/interfaces/BulkyItemScheduler';
import { Button, Grid, GridColumn, Separator, Text, ButtonSet } from '../../../core/components/styled';
import { Checkbox, Table, TypedField } from 'src/core/components';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { TableCell } from 'src/core/components/Table';
import { useSelector } from 'src/core/hooks/useSelector';
import BulkyItemSchedulerServiceLimitsByZoneSectionTableRow from './BulkyItemSchedulerServiceLimitsByZoneSectionTableRow';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector(BULKY_ITEM_SCHEDULER_FORM);

type Props = {
  initialValueBulkySchedulerLimitByDaySettings?: BulkySchedulerLimitByDaySettings[];
  initialValueBulkySchedulerLimitByTimeRangeSettings?: BulkySchedulerLimitByTimeRangeSettings[];
  restrictPickupDays: number[];
};

const getTableHeight = (ratesLength: number): number =>
  Math.min(ratesLength * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1;

const BulkyItemSchedulerServiceLimitsByZoneSection: FC<Props> = ({
  initialValueBulkySchedulerLimitByDaySettings,
  initialValueBulkySchedulerLimitByTimeRangeSettings,
  restrictPickupDays,
}) => {
  const dispatch = useDispatch();

  const isLimitByDayOfWeek = useSelector(state => formSelector(state, 'isZoneLimitByDayOfWeek')) || false;
  const isLimitByTimeRange = useSelector(state => formSelector(state, 'isZoneLimitByTimeRange')) || false;

  const bulkySchedulerLimitByDaySettings =
    useSelector(state => formSelector(state, 'bulkySchedulerLimitByDaySettings')) || [];
  const bulkySchedulerLimitByTimeRangeSettings =
    useSelector(state => formSelector(state, 'bulkySchedulerLimitByTimeRangeSettings')) || [];

  const serviceLimitsByZoneTableCellWidths = {
    daysOfWeek: '25%',
    dailyMax: '13%',
    puWindowStart: '15%',
    puWindowEnd: '15%',
    geoFenceIds: '30%',
    action: '2%',
  };

  const serviceLimitsByZoneTableCells: TableCell[] = [
    {
      name: isLimitByDayOfWeek ? 'days' : 'period',
      label: isLimitByDayOfWeek ? translate('billing.dayOfWeek') : translate('vendors.bulkyItemScheduler.timeRange'),
      width: serviceLimitsByZoneTableCellWidths.daysOfWeek,
      sortable: false,
    },
    {
      name: 'maxPickUps',
      label: isLimitByDayOfWeek
        ? translate('vendors.bulkyItemScheduler.dailyMax')
        : translate('vendors.bulkyItemScheduler.maxPickups'),
      width: serviceLimitsByZoneTableCellWidths.dailyMax,
      sortable: false,
    },
    {
      name: 'pickUpWindowBegin',
      label: translate('vendors.bulkyItemScheduler.puWindowStart'),
      width: serviceLimitsByZoneTableCellWidths.puWindowStart,
      sortable: false,
    },
    {
      name: 'pickUpWindowEnd',
      label: translate('vendors.bulkyItemScheduler.puWindowEnd'),
      width: serviceLimitsByZoneTableCellWidths.puWindowEnd,
      sortable: false,
    },
    {
      name: 'geoFences',
      label: translate('routes.geoFences.geoFencesTitle'),
      width: serviceLimitsByZoneTableCellWidths.geoFenceIds,
      sortable: false,
    },
    {
      name: 'action',
      label: '',
      width: serviceLimitsByZoneTableCellWidths.action,
      align: 'center',
      sortable: false,
      noPaddingRight: true,
    },
  ];

  const getNewServiceLimitByZone = (): any =>
    isLimitByDayOfWeek
      ? {
          geoFences: [],
          days: undefined,
          maxPickUps: undefined,
          pickUpWindowBegin: undefined,
          pickUpWindowEnd: undefined,
        }
      : {
          geoFences: [],
          period: undefined,
          maxPickUps: undefined,
          pickUpWindowBegin: undefined,
          pickUpWindowEnd: undefined,
        };

  const handleAddNewServiceLimitByZone = () => {
    if (isLimitByDayOfWeek) {
      dispatch(arrayPush(BULKY_ITEM_SCHEDULER_FORM, 'bulkySchedulerLimitByDaySettings', getNewServiceLimitByZone()));
    } else {
      dispatch(
        arrayPush(BULKY_ITEM_SCHEDULER_FORM, 'bulkySchedulerLimitByTimeRangeSettings', getNewServiceLimitByZone()),
      );
    }
  };

  const handleLimitTypeByDayOfWeekChange = (event: any, value: boolean) => {
    if (!value) {
      event.preventDefault();
    } else {
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'isZoneLimitByTimeRange', false));
    }
  };

  const handleLimitTypeByTimeRangeChange = (event: any, value: boolean) => {
    if (!value) {
      event.preventDefault();
    } else {
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'isZoneLimitByDayOfWeek', false));
    }
  };

  return (
    <GridColumn size="12/12" padding="no xSmall small xSmall">
      <Text block size="large" weight="medium" margin="no no xSmall no">
        {translate('vendors.bulkyItemScheduler.serviceLimits')}
      </Text>
      <Grid centered multiLine>
        <GridColumn size="3/12" padding="small xSmall small no" alignVerticalCenter>
          <TypedField
            name="isZoneLimitByDayOfWeek"
            component={Checkbox}
            props={{ label: translate('vendors.bulkyItemScheduler.limitsByDayOfWeek'), isStyleAsCircle: true }}
            onChange={(event, value) => handleLimitTypeByDayOfWeekChange(event, value)}
          />
        </GridColumn>
        <GridColumn size="3/12" padding="small xSmall small no" alignVerticalCenter>
          <TypedField
            name="isZoneLimitByTimeRange"
            component={Checkbox}
            props={{ label: translate('vendors.bulkyItemScheduler.limitsByTimeRange'), isStyleAsCircle: true }}
            onChange={(event, value) => handleLimitTypeByTimeRangeChange(event, value)}
          />
        </GridColumn>
        <GridColumn size="12/12" padding="no">
          {((isLimitByDayOfWeek && !!bulkySchedulerLimitByDaySettings?.length) ||
            (!isLimitByDayOfWeek && !!bulkySchedulerLimitByTimeRangeSettings?.length)) && (
            <Table
              cells={serviceLimitsByZoneTableCells}
              tableBodyProps={{ height: getTableHeight(6), noOverflow: true }}
            >
              <FieldArray
                name={
                  isLimitByDayOfWeek ? 'bulkySchedulerLimitByDaySettings' : 'bulkySchedulerLimitByTimeRangeSettings'
                }
                component={BulkyItemSchedulerServiceLimitsByZoneSectionTableRow as any}
                isLimitByDays={isLimitByDayOfWeek}
                cellWidths={serviceLimitsByZoneTableCellWidths}
                initialValueBulkySchedulerLimitByDaySettings={initialValueBulkySchedulerLimitByDaySettings}
                initialValueBulkySchedulerLimitByTimeRangeSettings={initialValueBulkySchedulerLimitByTimeRangeSettings}
                rerenderOnEveryChange
                restrictPickupDays={restrictPickupDays}
              />
            </Table>
          )}
        </GridColumn>
        <GridColumn size="12/12">
          <ButtonSet margin="medium no sMedium no" align="center">
            <Button
              type="button"
              color="primary"
              line
              onClick={handleAddNewServiceLimitByZone}
              margin="no"
              disabled={!isLimitByDayOfWeek && !isLimitByTimeRange}
            >
              {`+ ${translate('common.addNew')}`}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>

      <Separator color="grayLight" size={2} margin="sMedium no xSmall no" />
    </GridColumn>
  );
};

export default BulkyItemSchedulerServiceLimitsByZoneSection;
