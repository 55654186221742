import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

import { getMapBounds } from 'src/common/components/map/util';
import { Modal } from 'src/core/components';
import { ModalProps } from 'src/core/components/Modal';
import { ModalSection, ModalTitle } from 'src/core/components/styled';
import { MAP_CITY_ZOOM_IN } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  resetStreetNetworkServiceAreas,
  setStreetNetworkServiceAreasViewport,
  updateStreetNetworkServiceAreas,
} from 'src/customers/ducks/streetNetworkServiceAreas';
import ServiceAreaModalMap from './ServiceAreaModalMap';

import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import {
  clearStreetNetworkDataLayers,
  loadStreetNetworkServiceAreas,
  saveStreetNetworkServiceAreas,
} from 'src/customers/ducks';
import { ServiceAreas } from 'src/customers/interfaces/StreetNetwork';
import { SERVICE_AREA_MAP_FILTERS_FORM_NAME } from '../../forms/ServiceAreaMapFiltersForm';
import confirm from 'src/core/services/confirm';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';

interface Props extends ModalProps {
  vendorId: number;
}

const ServiceAreaModal: FC<Props> = ({ onClose, vendorId }) => {
  const dispatch = useDispatch();

  const serviceArea = useSelector(state => state.customers.streetNetworkServiceAreas.serviceAreas);
  const isLoading = useSelector(state => state.customers.streetNetworkServiceAreas.isLoading);
  const isSaving = useSelector(state => state.customers.streetNetworkServiceAreas.isSaving);
  const isUpdating = useSelector(state => state.customers.streetNetworkServiceAreas.isUpdating);
  const facilities = useSelector(state => state.customers.streetNetworkServiceAreas.haulerLocations);
  const currentVendor = useSelector(state => state.vendors.vendor.vendor);

  useEffect(
    () => () => {
      dispatch(resetStreetNetworkServiceAreas());
      dispatch(reset(SERVICE_AREA_MAP_FILTERS_FORM_NAME));
    },
    [dispatch],
  );

  const mapCenterByVendor = useMemo(() => {
    if (currentVendor?.id) {
      return getMapBounds(
        [{ latitude: currentVendor.homeAddress.latitude, longitude: currentVendor.homeAddress.longitude }],
        {
          capZoom: MAP_CITY_ZOOM_IN,
        },
      );
    }
  }, [currentVendor.id, currentVendor.homeAddress]);

  const currentServiceArea = useMemo(() => {
    // take the first one
    return serviceArea?.length ? serviceArea[0] : null;
  }, [serviceArea]);

  useEffect(() => {
    if (currentServiceArea && currentServiceArea?.polygons?.length > 0) return;

    mapCenterByVendor && dispatch(setStreetNetworkServiceAreasViewport(mapCenterByVendor));
  }, [dispatch, mapCenterByVendor, currentServiceArea]);

  const handleSaveServiceArea = useCallback(
    async (editedPolygon: any) => {
      if (!editedPolygon || editedPolygon.length === 0) return;

      if (await confirm(translate('customers.alertMessages.serviceAreaCreateConfirmation'))) {
        const facilitiesInPolygon = facilities
          .filter(facility => booleanPointInPolygon([facility.longitude, facility.latitude], editedPolygon[0]))
          ?.map(facility => {
            const existingFacility = currentServiceArea?.facilities?.find(f => f.locationId === facility.id);

            return {
              ...existingFacility,
              locationId: facility.id,
              name: facility.locationName,
            };
          });

        const polygonAlready = currentServiceArea?.polygons?.length ? currentServiceArea?.polygons[0] : null;

        const payload: ServiceAreas = {
          id: currentServiceArea?.id,
          name: currentServiceArea?.name || `${currentVendor.name || ''}_ServiceAreas`,
          facilities: facilitiesInPolygon,
          polygons: [
            {
              ...(polygonAlready || {}),
              name: polygonAlready?.name || `${currentVendor.name || ''}_MainServiceArea`,
              json: JSON.stringify(editedPolygon[0]),
            },
          ],
        };

        if (!!facilitiesInPolygon.length) {
          dispatch(clearStreetNetworkDataLayers());
          if (!!payload.id) {
            updateStreetNetworkServiceAreas(
              vendorId,
              payload,
            )(dispatch)
              .then(() => {
                createSuccessNotification(translate('customers.alertMessages.serviceAreaUpdatedSuccessfully'));
              })
              .catch(() => {
                createErrorNotification(translate('customers.alertMessages.serviceAreaUpdatedError'));
              })
              .finally(() => {
                getIsVendorNotChanged(vendorId) && loadStreetNetworkServiceAreas(vendorId)(dispatch);
                onClose && onClose(true);
              });
          } else {
            saveStreetNetworkServiceAreas(
              vendorId,
              payload,
            )(dispatch)
              .then(() => {
                createSuccessNotification(translate('customers.alertMessages.serviceAreaSubmittedSuccessfully'));
              })
              .catch(() => {
                createErrorNotification(translate('customers.alertMessages.serviceAreaSubmittedError'));
              })
              .finally(() => {
                getIsVendorNotChanged(vendorId) && loadStreetNetworkServiceAreas(vendorId)(dispatch);
                onClose && onClose(true);
              });
          }
        } else {
          createErrorNotification(translate('customers.alertMessages.serviceAreaNoFacility'));
        }
      }
    },
    [
      facilities,
      currentServiceArea?.polygons,
      currentServiceArea?.id,
      currentServiceArea?.name,
      currentServiceArea?.facilities,
      currentVendor.name,
      vendorId,
      dispatch,
      onClose,
    ],
  );

  return (
    <Modal padding="medium no no no" size="large" onClose={onClose} isLoading={isLoading || isSaving || isUpdating}>
      <ModalTitle>{translate('haulerProfile.serviceArea')}</ModalTitle>
      <ModalSection height="700px" padding="small no">
        <ServiceAreaModalMap handleSaveServiceArea={handleSaveServiceArea} vendorId={vendorId} />
      </ModalSection>
    </Modal>
  );
};
export default ServiceAreaModal;
