import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadServiceDetails as doLoadServiceDetails,
  loadSnowSweeperServiceDetails as doLoadSnowSweeperServiceDetails,
} from '../services/serviceDetails';
import { ServiceDetails } from '../interfaces/ServiceDetails';

const START_LOAD = 'routes/serviceDetails/START_LOAD';
const COMPLETE_LOAD = 'routes/serviceDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/serviceDetails/FAIL_LOAD';
const RESET = 'routes/serviceDetails/RESET';

interface State {
  isLoading: boolean;
  serviceDetails?: ServiceDetails;
}

const initialState: State = {
  isLoading: false,
  serviceDetails: undefined,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceDetails: action.serviceDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceDetails: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceDetails: ServiceDetails) => ({
  type: COMPLETE_LOAD,
  serviceDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadServiceDetails = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadServiceDetailsPromise = doLoadServiceDetails(vendorId, routeId);
  loadServiceDetailsPromise
    .then(serviceDetails => dispatch(completeLoad(serviceDetails)))
    .catch(() => dispatch(failLoad()));
  return loadServiceDetailsPromise;
};

export const loadSnowSweeperServiceDetails =
  (vendorId: number, routeId: number, startDate?: Date | string, endDate?: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadServiceDetailsPromise = doLoadSnowSweeperServiceDetails(vendorId, routeId, startDate, endDate);
    loadServiceDetailsPromise
      .then(serviceDetails => dispatch(completeLoad(serviceDetails)))
      .catch(() => dispatch(failLoad()));
    return loadServiceDetailsPromise;
  };

export const resetServiceDetails = () => ({
  type: RESET,
});
