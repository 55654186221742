import React, { useEffect, useMemo, useState } from 'react';

import { includes, remove, uniq, uniqBy } from 'lodash-es';
import { Resizable } from 're-resizable';
import { connect } from 'react-redux';
import { change, getFormValues, reset } from 'redux-form';
import DocumentTitle from '../../../../common/components/DocumentTitle';
import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageLoadingOverlay,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { ACCOUNT_STATUSES } from '../../../../common/constants/accountStatuses';
import { DuckFunction } from '../../../../contracts/ducks';
import {
  MapDragHandle,
  Table,
  UnconnectedCheckbox,
  UnconnectedDropdown,
  UpdateTrackerRouteSwitchWrapper,
} from '../../../../core/components';
import { DropdownOption } from '../../../../core/components/Dropdown';
import { Button, MapContainer, Panel, PanelSection } from '../../../../core/components/styled';
import { WEEKDAYS_BY_ID } from '../../../../core/constants';
import {
  ROUTE_RECOMMENDATION_POSITION_OPTIONS,
  ROUTE_RECOMMENDATION_POSITION_ALL_OPTIONS,
} from '../../../../core/constants/routeRecommendation';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import { multiWordOrSearch } from '../../../../core/services/search';
import translate from '../../../../core/services/translate';
import { checkRouteRecommendationsHasScheduledDailyRoutes } from '../../../../routes/services/routeTemplate';
import { AppState } from '../../../../store';
import createTranslationKey from '../../../../utils/services/createTranslationKey';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { loadRouteRecommendations, ServiceContractsWithRecommendation } from '../../../ducks/routeRecommendations';
import { saveRouteRecommendationAssignments } from '../../../services/routeRecommendations';
import RouteRecommendationsForm from '../../forms/RouteRecommendationsForm';
import { InsertAllWrapper, NoResultsContainer } from '../../styled/RouteRecommendations';
import RouteRecommendationsTableRow, { recommendationsTableRowHeight } from './RouteRecommendationsTableRow';
import { isOptimizedStopInsertionFeatureEnabled } from 'src/vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';
import { ServicesMapGL } from '../servicesPageSections';

interface CheckAllServicesProps {
  checkAll: (checked: boolean) => void;
  getChecked: () => boolean;
}

const CheckAllServicesCheckbox: React.SFC<CheckAllServicesProps> = ({ checkAll, getChecked }) => (
  <UnconnectedCheckbox
    block
    checked={getChecked()}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => checkAll(event.target.checked)}
    size="small"
  />
);

const getTableCells = (
  checkAll: (checked: boolean) => void,
  allChecked: boolean,
  insertAllServices: (position: string) => void,
  anyChecked: boolean,
  isOptimizedStopInsertionEnabled: boolean,
) => [
  {
    name: 'selectAll',
    component: CheckAllServicesCheckbox,
    componentProps: {
      checkAll: checkAll,
      getChecked: () => allChecked,
    },
    width: '5%',
  },
  {
    name: 'customer',
    label: translate('common.customer'),
    width: '20%',
    sortable: false,
  },
  {
    name: 'serviceType',
    label: translate('common.serviceType'),
    width: '15%',
    sortable: false,
  },
  {
    name: 'service',
    label: translate('common.service'),
    width: '13%',
    sortable: false,
  },
  {
    name: 'dayOfService',
    label: translate('common.dayOfService'),
    width: '17%',
    sortable: false,
  },
  {
    name: 'routeAssignment',
    label: translate('customers.routeRecommendations.routeAssignment'),
    width: '15%',
    sortable: false,
  },
  {
    name: 'position',
    component: () => (
      <InsertAllWrapper>
        <UnconnectedDropdown
          disabled={!anyChecked}
          margin="no"
          onChange={insertAllServices}
          placeholder={translate('customers.routeRecommendations.insertAs')}
          value={''}
          width="100%"
          options={
            isOptimizedStopInsertionEnabled
              ? ROUTE_RECOMMENDATION_POSITION_ALL_OPTIONS
              : ROUTE_RECOMMENDATION_POSITION_OPTIONS
          }
        />
      </InsertAllWrapper>
    ),
    width: '15%',
    sortable: false,
  },
];

export interface ServiceContractRecommendationAssignment {
  cachedRouteTemplates?: DropdownOption[];
  serviceContractId: number;
  recommendedRouteTemplateIds?: number[];
  serviceContractPosition?: string;
  serviceContractRouteTemplateRecommendationId?: number;
}

interface Props {
  services: ServiceContractsWithRecommendation[];
  vendorId: number;
  loadRouteRecommendations: DuckFunction<typeof loadRouteRecommendations>;
  reset: (form: string) => void;
  shouldRecreateRoutes: boolean;
  change: any;
}

const addLatLng = (service: ServiceContractsWithRecommendation) => ({
  ...service,
  id: service.serviceContractId,
  lat: service.binLatitude,
  lng: service.binLongitude,
});

const getUniqueServiceKey = (service: ServiceContractsWithRecommendation) =>
  `${service.serviceContractId}_${service.schedulerType.id}`;

const RouteRecommendationsPage: React.SFC<Props> = ({
  change,
  loadRouteRecommendations,
  reset,
  services,
  vendorId,
  shouldRecreateRoutes,
}) => {
  const [assignmentsMap, setAssignmentsMap] = useState<{ [key: string]: ServiceContractRecommendationAssignment }>({});
  const [checkedServices, setCheckedServices] = useState<ServiceContractsWithRecommendation[]>([]);
  const [refinerValues, setRefinerValues] = useState<{ [key: string]: string | number }>({});
  const [filteredServices, setFilteredServices] = useState(services);
  const [isSaving, setIsSaving] = useState(false);
  const [hasScheduledDailyRoutes, setHasScheduledDailyRoutes] = useState(false);

  const isOptimizedStopInsertionEnabled = useSelector(state => isOptimizedStopInsertionFeatureEnabled(state));

  useEffect(() => {
    const refinerKeys = Object.keys(refinerValues).filter(k => !!refinerValues[k]);
    const filteredServices = services.filter(s =>
      refinerKeys.every(k => {
        if (k === 'searchTerm') {
          return (
            multiWordOrSearch(s.customerName, refinerValues[k]) ||
            multiWordOrSearch(s.address, refinerValues[k]) ||
            multiWordOrSearch(s.locationName, refinerValues[k])
          );
        }
        return (s as any)[k].id === refinerValues[k];
      }),
    );
    setFilteredServices(filteredServices);
  }, [services, refinerValues]);

  useEffect(() => {
    const assignments: { [key: string]: ServiceContractRecommendationAssignment } = {};
    services.forEach(s => {
      const uniqueKey = getUniqueServiceKey(s);
      assignments[uniqueKey] = {
        serviceContractId: s.serviceContractId,
        recommendedRouteTemplateIds: s.recommendedRouteTemplate ? [s.recommendedRouteTemplate.id] : undefined,
        serviceContractRouteTemplateRecommendationId: s.recommendedRouteTemplate ? s.recommendedRouteTemplate.id : 0,
        cachedRouteTemplates: assignmentsMap[uniqueKey] && assignmentsMap[uniqueKey].cachedRouteTemplates,
      };
    });
    setAssignmentsMap({ ...assignments });
  }, [services]); // eslint-disable-line react-hooks/exhaustive-deps

  const serviceTypes = useMemo(
    () =>
      uniqBy(
        filteredServices.map(s => ({
          value: s.serviceType.id,
          label: translate(createTranslationKey(s.serviceType.technicalName, 'common.serviceTypes')),
        })),
        'value',
      ).sort((a, b) => (a.label < b.label ? -1 : 1)),
    [filteredServices],
  );

  const wasteTypes = useMemo(
    () =>
      uniqBy(
        filteredServices.map(s => ({
          value: s.wasteMaterialType.id,
          label: translate(createTranslationKey(s.wasteMaterialType.technicalName, 'common.wasteTypes')),
        })),
        'value',
      ).sort((a, b) => (a.label < b.label ? -1 : 1)),
    [filteredServices],
  );

  const daysOfService = useMemo(
    () =>
      uniqBy(
        filteredServices.map(s => ({ value: s.schedulerType.id, label: WEEKDAYS_BY_ID[s.schedulerType.id].name })),
        'value',
      ).sort((a, b) => a.value - b.value),
    [filteredServices],
  );

  const accountStatuses = useMemo(
    () =>
      uniqBy(
        filteredServices.map(s => ({
          value: s.serviceContractAccountStatusType.id,
          label: ACCOUNT_STATUSES[s.serviceContractAccountStatusType.id].name,
        })),
        'value',
      ).sort((a, b) => (a.label < b.label ? -1 : 1)),
    [filteredServices],
  );

  const insertAllServices = (position: string) => {
    if (checkedServices.length) {
      checkedServices.forEach(s => (assignmentsMap[getUniqueServiceKey(s)].serviceContractPosition = position));
    }
    setAssignmentsMap({ ...assignmentsMap });
  };

  const checkService = (service: ServiceContractsWithRecommendation) => {
    if (includes(checkedServices, service)) {
      remove(checkedServices, service);
    } else {
      checkedServices.push(service);
    }
    setCheckedServices([...checkedServices]);
  };

  const applyRefiners = (values: { [refiner: string]: string | number }) => {
    setCheckedServices([]);
    setRefinerValues(values);
  };

  const getServices = () =>
    filteredServices.map(s => ({
      checked: includes(checkedServices, s),
      assignment: assignmentsMap[getUniqueServiceKey(s)],
      service: s,
    }));

  const checkAllServices = (checked: boolean) => {
    if (checked) {
      setCheckedServices([...filteredServices]);
    } else {
      setCheckedServices([]);
    }
  };

  const getMapPins = () => {
    return checkedServices.length ? checkedServices.map(addLatLng) : filteredServices.map(addLatLng);
  };

  const cacheRouteTemplates = (service: ServiceContractsWithRecommendation, cachedRouteTemplates: DropdownOption[]) => {
    const uniqueKey = getUniqueServiceKey(service);
    assignmentsMap[uniqueKey] = { ...assignmentsMap[uniqueKey], cachedRouteTemplates };
    setAssignmentsMap({ ...assignmentsMap });
  };

  const clearTrackerRouteSwitch = () => change('updateTrackerRouteSwitchWrapper', 'shouldRecreateRoutes', false);

  const changeAssignment = async (
    service: ServiceContractsWithRecommendation,
    routeTemplateIds: number[],
    cachedRouteTemplates?: DropdownOption[],
    serviceContractPosition?: string,
  ) => {
    const uniqueId = getUniqueServiceKey(service);
    const assignment: ServiceContractRecommendationAssignment = {
      serviceContractPosition,
      cachedRouteTemplates,
      serviceContractId: service.serviceContractId,
      recommendedRouteTemplateIds: routeTemplateIds,
      serviceContractRouteTemplateRecommendationId:
        service.recommendedRouteTemplate && service.recommendedRouteTemplate.id,
    };
    assignmentsMap[uniqueId] = assignment;
    setAssignmentsMap({ ...assignmentsMap });

    const validAssignments: any[] = [];
    Object.keys(assignmentsMap).forEach(k => {
      const assignment = assignmentsMap[k];
      if (
        assignment.recommendedRouteTemplateIds &&
        assignment.recommendedRouteTemplateIds.length &&
        assignment.serviceContractPosition
      ) {
        const uniqueIds = k.split('_').map(Number);
        const service = services.find(
          s => s.serviceContractId === uniqueIds[0] && s.schedulerType.id === uniqueIds[1],
        ) as ServiceContractsWithRecommendation;
        validAssignments.push({
          routeTemplates: assignment.recommendedRouteTemplateIds.map(rtid => ({
            routeTemplateId: rtid,
            serviceContractRouteTemplateRecommendationId:
              service.recommendedRouteTemplate && service.recommendedRouteTemplate.id === rtid
                ? service.recommendedRouteTemplate.serviceContractRouteTemplateRecommendationId
                : undefined,
          })),
        });
      }
    });

    const selectedRouteTemplateIds: any[] = [];
    validAssignments.forEach(validAssignment => {
      validAssignment.routeTemplates.forEach((routeTemplate: any) => {
        selectedRouteTemplateIds.push(routeTemplate.routeTemplateId);
      });
    });

    if (selectedRouteTemplateIds.length > 0) {
      const hasScheduledDailyRoutes = await checkRouteRecommendationsHasScheduledDailyRoutes(
        uniq(selectedRouteTemplateIds),
        vendorId,
      );
      setHasScheduledDailyRoutes(hasScheduledDailyRoutes);
      if (!hasScheduledDailyRoutes) clearTrackerRouteSwitch();
    } else {
      setHasScheduledDailyRoutes(false);
      clearTrackerRouteSwitch();
    }
  };

  const saveChanges = () => {
    const validAssignments: any[] = [];

    Object.keys(assignmentsMap).forEach(k => {
      const assignment = assignmentsMap[k];
      if (
        assignment.recommendedRouteTemplateIds &&
        assignment.recommendedRouteTemplateIds.length &&
        assignment.serviceContractPosition
      ) {
        const uniqueIds = k.split('_').map(Number);
        const service = services.find(
          s => s.serviceContractId === uniqueIds[0] && s.schedulerType.id === uniqueIds[1],
        ) as ServiceContractsWithRecommendation;
        validAssignments.push({
          binLatitude: service.binLatitude,
          binLongitude: service.binLongitude,
          vendorId: vendorId,
          serviceContractId: service.serviceContractId,
          serviceContractPosition: assignment.serviceContractPosition,
          routeTemplates: assignment.recommendedRouteTemplateIds.map(rtid => ({
            routeTemplateId: rtid,
            serviceContractRouteTemplateRecommendationId:
              service.recommendedRouteTemplate && service.recommendedRouteTemplate.id === rtid
                ? service.recommendedRouteTemplate.serviceContractRouteTemplateRecommendationId
                : undefined,
          })),
          schedulerId: service.schedulerType.id,
        });
      }
    });

    setIsSaving(true);

    saveRouteRecommendationAssignments(validAssignments, shouldRecreateRoutes)
      .then(data => {
        createSuccessNotification(translate('customers.routeRecommendations.successMessage'));
        reset('routeRecommendations');
        loadRouteRecommendations(vendorId).finally(() => setIsSaving(false));
        setHasScheduledDailyRoutes(false);
        clearTrackerRouteSwitch();
      })
      .catch(error => {
        setIsSaving(false);
        createErrorNotification(translate('customers.routeRecommendations.errorMessage'));
      });
  };

  const validAssignmentsCount = Object.keys(assignmentsMap).filter(
    k =>
      assignmentsMap[k].recommendedRouteTemplateIds &&
      (assignmentsMap[k].recommendedRouteTemplateIds as any).length &&
      assignmentsMap[k].serviceContractPosition,
  ).length;

  const rows = getServices();

  return (
    <PageContent>
      {isSaving && <PageLoadingOverlay />}
      <DocumentTitle>{translate('customers.routeRecommendations.title')}</DocumentTitle>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButton to={`/customers/customers`}>
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle>{translate('customers.routeRecommendations.title')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions align="right">
          <Button color="primary" disabled={!validAssignmentsCount} id="route-save-button" onClick={saveChanges}>
            {translate('common.save')} {!!validAssignmentsCount && `(${validAssignmentsCount})`}
          </Button>
          <UpdateTrackerRouteSwitchWrapper hasScheduledDailyRoutes={hasScheduledDailyRoutes} />
        </PageActions>
      </PageHeader>
      <Panel>
        <PanelSection vertical withBorder>
          <RouteRecommendationsForm
            serviceTypes={serviceTypes}
            wasteTypes={wasteTypes}
            daysOfService={daysOfService}
            accountStatuses={accountStatuses}
            onSubmit={applyRefiners}
          />
          <Resizable
            defaultSize={{ width: '100%', height: 500 }}
            minWidth="100%"
            handleComponent={{ bottom: <MapDragHandle /> }}
          >
            <MapContainer>
              <ServicesMapGL serviceContainers={getMapPins()} />
            </MapContainer>
          </Resizable>
        </PanelSection>
        <PanelSection vertical padding="no no xSmall">
          {!rows.length ? (
            <NoResultsContainer>{translate('common.noResults')}</NoResultsContainer>
          ) : (
            <Table
              cells={getTableCells(
                checkAllServices,
                checkedServices.length === filteredServices.length,
                insertAllServices,
                !!checkedServices.length,
                isOptimizedStopInsertionEnabled,
              )}
              rows={rows}
              rowComponent={RouteRecommendationsTableRow}
              rowProps={{
                vendorId,
                recommendationsLength: rows.length,
                onAssignmentChange: changeAssignment,
                onCheckChange: checkService,
                cacheRouteTemplates,
              }}
              virtualized={filteredServices.length > 9}
              virtualizedProps={
                filteredServices.length > 9
                  ? {
                      itemSize: recommendationsTableRowHeight,
                      height: recommendationsTableRowHeight * 9,
                    }
                  : undefined
              }
            />
          )}
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('updateTrackerRouteSwitchWrapper')(state) as any;

  return {
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    services: state.customers.routeRecommendations.recommendations,
    shouldRecreateRoutes: formValues ? formValues.shouldRecreateRoutes : false,
  };
};

export default connect(mapStateToProps, { change, loadRouteRecommendations, reset })(RouteRecommendationsPage);
