import createTransformer from '../../utils/services/createTransformer';

const transformLoadFleetInsights = (values: any) => {
  const fleetInsights = createTransformer(values);

  return {
    total: fleetInsights.get('total').value,
    fleetInsights: fleetInsights.get('fleetInsights').map((values: any) => {
      const fleetInsight = createTransformer(values);
      return {
        ...fleetInsight.omit('realTimeInsightsId').value,
        insightId: fleetInsight.get('realTimeInsightsId').value,
      };
    }).value,
  };
};

export default transformLoadFleetInsights;
