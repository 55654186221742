import humps from 'humps';
import { mapKeys, flatten, mapValues } from 'lodash-es';

// RVP-3593: DISABLE THESE FOR NOW AND USE Issue.png instead
// import containerReplacement from '../assets/img/insights/containerReplacement.png';
// import overflowing from '../assets/img/insights/overflowingWhenready.png';
// import extra from '../assets/img/insights/extra.png';
// import frozen from '../assets/img/insights/frozen.png';
// import gate from '../assets/img/insights/gate.png';
// import laborTime from '../assets/img/insights/laborTime.png';
// import moveContainer from '../assets/img/insights/moveContainer.png';
// import overweight from '../assets/img/insights/overweight.png';
// import prep from '../assets/img/insights/prep.png';
// import returnTrip from '../assets/img/insights/returnTrip.png';
// import waitTime from '../assets/img/insights/waitTime.png';
// import after from '../assets/img/insights/after.png';
// import before from '../assets/img/insights/before.png';
// import additionalLoads from '../assets/img/insights/additionalLoads.png';
// import lockedContainer from '../assets/img/insights/lockedContainer.png';

import baggedRecyclables from '../assets/img/insights/baggedRecyclables.png';
import brokenSidewalkCurb from '../assets/img/insights/brokenSidewalkCurb.png';
import brokenTrafficSignal from '../assets/img/insights/brokenTrafficSignal.png';
import cancelled from '../assets/img/insights/cancelled.png';
import cardboardInContainer from '../assets/img/insights/cardboardInContainer.png';
import cardboardNotFlattened from '../assets/img/insights/cardboardNotFlattened.png';
import cleanRecycling from '../assets/img/insights/cleanRecycling.png';
import cloggedInlet from '../assets/img/insights/cloggedInlet.png';
import cog from '../assets/img/insights/settings.png';
import completed from '../assets/img/insights/completed.png';
import containerNotOut from '../assets/img/insights/containerNotOut.png';
import containerOut from '../assets/img/insights/containerOut.png';
import contaminated from '../assets/img/insights/contaminated.png';
import curbObstruction from '../assets/img/insights/curbObstruction.png';
import damagedContainer from '../assets/img/insights/damagedContainer.png';
import damagedStreetSign from '../assets/img/insights/damagedStreetSign.png';
import exceptionsObstacles from '../assets/img/serviceExceptions/exceptionsObstacles.png';
import foodOrLiquid from '../assets/img/insights/foodOrLiquid.png';
import graffiti from '../assets/img/insights/graffiti.png';
import handicap from '../assets/img/insights/handicap.png';
import hangingWires from '../assets/img/insights/hangingWires.png';
import hardDriving from '../assets/img/insights/hardDriving.png';
import highGrassWeeds from '../assets/img/insights/grass.png';
import idleTime from '../assets/img/insights/idleTime.png';
import improperStorageArea from '../assets/img/insights/improperStorageArea.png';
import inProgress from '../assets/img/insights/inProgress.png';
import insideGeoFence from '../assets/img/insights/insideGeoFence.png';
import issue from '../assets/img/insights/issue.png';
import leftAsTrash from '../assets/img/insights/leftAsTrash.png';
import litterJunk from '../assets/img/insights/litter.png';
import lowBranches from '../assets/img/insights/lowBranches.png';
import materialContamination from '../assets/img/insights/materialContamination.png';
import metalWoodTanks from '../assets/img/insights/metalWoodTanks.png';
import missingNewAddress from '../assets/img/insights/missingNewAddress.png';
import multiple from '../assets/img/insights/multipleContamination.png';
import noElectronics from '../assets/img/insights/electronics.png';
import notSweeping from '../assets/img/insights/notSweeping.png';
import number1 from '../assets/img/insights/number1.png';
import number2 from '../assets/img/insights/number2.png';
import number3 from '../assets/img/insights/number3.png';
import offRoute from '../assets/img/insights/offRoute.png';
import offRouteStationary from '../assets/img/insights/offRouteStationary.png';
import oops from '../assets/img/insights/ooops.png';
import outsideGeoFence from '../assets/img/insights/outsideGeoFence.png';
import overflowingBins from '../assets/img/insights/overflowingBins.png';
import placedOnHold from '../assets/img/insights/placedOnHold.png';
import plasticBags from '../assets/img/insights/plasticBags.png';
import plasticInContainer from '../assets/img/insights/plasticInContainer.png';
import potholes from '../assets/img/insights/potHoles.png';
import recRolltainerOutside from '../assets/img/insights/recRolltainerOutside.png';
import scheduled from '../assets/img/insights/scheduled.png';
import serviced from '../assets/img/insights/serviced.png';
import speeding from '../assets/img/insights/speeding.png';
import stationary from '../assets/img/insights/stationary.png';
import streetLightBroken from '../assets/img/insights/streetLightBroken.png';
import streetObstruction from '../assets/img/insights/streetObstruction.png';
import styrofoam from '../assets/img/insights/styrofoam.png';
import tangledCords from '../assets/img/insights/tangledCords.png';
import textiles from '../assets/img/insights/textiles.png';
import thanks from '../assets/img/insights/thanks.png';
import trash from '../assets/img/insights/trash3048Gallons.png';
import unlockedContainer from '../assets/img/insights/unlockedContainer.png';
import unScheduled from 'src/common/assets/img/insights/unScheduled.png';
import vacant from '../assets/img/insights/vacant.png';
import vacantCar from '../assets/img/insights/vacantCar.png';

import icAssistedCancelled from '../assets/img/newInsights/Assisted_Cancelled.png';
import icAssistedCompleted from '../assets/img/newInsights/Assisted_Completed.png';
import icAssistedInProgress from '../assets/img/newInsights/Assisted_InProgress.png';
import icAssistedIssueReportedNotServiced from '../assets/img/newInsights/Assisted_IssueReported_NotServiced.png';
import icAssistedIssueReportedServiced from '../assets/img/newInsights/Assisted_IssueReported_Serviced.png';
import icAssistedIssueReported from '../assets/img/newInsights/Assisted_IssueReported.png';
import icAssistedOnHold from '../assets/img/newInsights/Assisted_OnHold.png';
import icAssistedReassigned from '../assets/img/newInsights/Assisted_Reassigned.png';
import icAssistedScheduled from '../assets/img/newInsights/Assisted_Scheduled.png';
import icCancelled from '../assets/img/newInsights/Cancelled.png';
import icCompleted from '../assets/img/newInsights/Completed.png';
import icFlaggedAssistedCancelled from '../assets/img/newInsights/Flagged_Assisted_Cancelled.png';
import icFlaggedAssistedCompleted from '../assets/img/newInsights/Flagged_Assisted_Completed.png';
import icFlaggedAssistedIssueReportedNotServiced from '../assets/img/newInsights/Flagged_Assisted_IssueReported_NotServiced.png';
import icFlaggedAssistedIssueReportedServiced from '../assets/img/newInsights/Flagged_Assisted_IssueReported_Serviced.png';
import icFlaggedAssistedIssueReported from '../assets/img/newInsights/Flagged_Assisted_IssueReported.png';
import icFlaggedAssistedReassigned from '../assets/img/newInsights/Flagged_Assisted_Reassigned.png';
import icFlaggedAssistedScheduled from '../assets/img/newInsights/Flagged_Assisted_Scheduled.png';
import icFlaggedAssistedOnHold from '../assets/img/newInsights/Flagged_Assisted_OnHold.png';
import icFlaggedCancelled from '../assets/img/newInsights/Flagged_Cancelled.png';
import icFlaggedCompleted from '../assets/img/newInsights/Flagged_Completed.png';
import icFlaggedInProgress from '../assets/img/newInsights/Flagged_InProgress.png';
import icFlaggedIssueReportedNotServiced from '../assets/img/newInsights/Flagged_IssueReported_NotServiced.png';
import icFlaggedIssueReportedServiced from '../assets/img/newInsights/Flagged_IssueReported_Serviced.png';
import icFlaggedIssueReported from '../assets/img/newInsights/Flagged_IssueReported.png';
import icFlaggedOnHold from '../assets/img/newInsights/Flagged_OnHold.png';
import icFlaggedReassigned from '../assets/img/newInsights/Flagged_Reassigned.png';
import icFlaggedScheduled from '../assets/img/newInsights/Flagged_Scheduled.png';
import icInProgress from '../assets/img/newInsights/InProgress.png';
import icIssueReportedNotServiced from '../assets/img/newInsights/IssueReported_NotServiced.png';
import icIssueReportedServiced from '../assets/img/newInsights/IssueReported_Serviced.png';
import icIssueReported from '../assets/img/newInsights/IssueReported.png';
import icNewAssistedCancelled from '../assets/img/newInsights/New_Assisted_Cancelled.png';
import icNewAssistedCompleted from '../assets/img/newInsights/New_Assisted_Completed.png';
import icNewAssistedInProgress from '../assets/img/newInsights/New_Assisted_InProgress.png';
import icNewAssistedIssueReportedNotServiced from '../assets/img/newInsights/New_Assisted_IssueReported_NotServiced.png';
import icNewAssistedIssueReportedServiced from '../assets/img/newInsights/New_Assisted_IssueReported_Serviced.png';
import icNewAssistedIssueReported from '../assets/img/newInsights/New_Assisted_IssueReported.png';
import icNewAssistedOnHold from '../assets/img/newInsights/New_Assisted_OnHold.png';
import icNewAssistedReassigned from '../assets/img/newInsights/New_Assisted_Reassigned.png';
import icNewAssistedScheduled from '../assets/img/newInsights/New_Assisted_Scheduled.png';
import icNewCancelled from '../assets/img/newInsights/New_Cancelled.png';
import icNewCompleted from '../assets/img/newInsights/New_Completed.png';
import icNewFlaggedAssistedCancelled from '../assets/img/newInsights/New_Flagged_Assisted_Cancelled.png';
import icNewFlaggedAssistedCompleted from '../assets/img/newInsights/New_Flagged_Assisted_Completed.png';
import icNewFlaggedAssistedInProgress from '../assets/img/newInsights/New_Flagged_Assisted_InProgress.png';
import icNewFlaggedAssistedIssueReportedNotServiced from '../assets/img/newInsights/New_Flagged_Assisted_IssueReported_NotServiced.png';
import icNewFlaggedAssistedIssueReportedServiced from '../assets/img/newInsights/New_Flagged_Assisted_IssueReported_Serviced.png';
import icNewFlaggedAssistedIssueReported from '../assets/img/newInsights/New_Flagged_Assisted_IssueReported.png';
import icNewFlaggedAssistedOnHold from '../assets/img/newInsights/New_Flagged_Assisted_OnHold.png';
import icNewFlaggedAssistedReassigned from '../assets/img/newInsights/New_Flagged_Assisted_Reassigned.png';
import icNewFlaggedAssistedScheduled from '../assets/img/newInsights/New_Flagged_Assisted_Scheduled.png';
import icNewFlaggedCancelled from '../assets/img/newInsights/New_Flagged_Cancelled.png';
import icNewFlaggedCompleted from '../assets/img/newInsights/New_Flagged_Completed.png';
import icNewFlaggedInProgress from '../assets/img/newInsights/New_Flagged_InProgress.png';
import icNewFlaggedIssueReportedNotServiced from '../assets/img/newInsights/New_Flagged_IssueReported_NotServiced.png';
import icNewFlaggedIssueReportedServiced from '../assets/img/newInsights/New_Flagged_IssueReported_Serviced.png';
import icNewFlaggedIssueReported from '../assets/img/newInsights/New_Flagged_IssueReported.png';
import icNewFlaggedOnHold from '../assets/img/newInsights/New_Flagged_OnHold.png';
import icNewFlaggedReassigned from '../assets/img/newInsights/New_Flagged_Reassigned.png';
import icNewFlaggedScheduled from '../assets/img/newInsights/New_Flagged_Scheduled.png';
import icNewInProgress from '../assets/img/newInsights/New_InProgress.png';
import icNewIssueReportedNotServiced from '../assets/img/newInsights/New_IssueReported_NotServiced.png';
import icNewIssueReportedServiced from '../assets/img/newInsights/New_IssueReported_Serviced.png';
import icNewIssueReported from '../assets/img/newInsights/New_IssueReported.png';
import icNewOnHold from '../assets/img/newInsights/New_OnHold.png';
import icNewReassigned from '../assets/img/newInsights/New_Reassigned.png';
import icNewScheduled from '../assets/img/newInsights/New_Scheduled.png';
import icOnHoldAssistedCompleted from '../assets/img/newInsights/OnHold_Assisted_Completed.png';
import icOnHoldAssistedCancelled from '../assets/img/newInsights/OnHold_Assisted_Cancelled.png';
import icOnHoldAssistedInProgress from '../assets/img/newInsights/OnHold_Assisted_InProgress.png';
import icOnHoldAssistedIssueReportedNotServiced from '../assets/img/newInsights/OnHold_Assisted_IssueReported_NotServiced.png';
import icOnHoldAssistedIssueReportedServiced from '../assets/img/newInsights/OnHold_Assisted_IssueReported_Serviced.png';
import icOnHoldAssistedIssueReported from '../assets/img/newInsights/OnHold_Assisted_IssueReported.png';
import icOnHoldAssistedOnHold from '../assets/img/newInsights/OnHold_Assisted_OnHold.png';
import icOnHoldAssistedReassigned from '../assets/img/newInsights/OnHold_Assisted_Reassigned.png';
import icOnHoldAssistedScheduled from '../assets/img/newInsights/OnHold_Assisted_Scheduled.png';
import icOnHoldCancelled from '../assets/img/newInsights/OnHold_Cancelled.png';
import icOnHoldCompleted from '../assets/img/newInsights/OnHold_Completed.png';
import icOnHoldFlaggedAssistedCancelled from '../assets/img/newInsights/OnHold_Flagged_Assisted_Cancelled.png';
import icOnHoldFlaggedAssistedCompleted from '../assets/img/newInsights/OnHold_Flagged_Assisted_Completed.png';
import icOnHoldFlaggedAssistedInProgress from '../assets/img/newInsights/OnHold_Flagged_Assisted_InProgress.png';
import icOnHoldFlaggedAssistedIssueReportedNotServiced from '../assets/img/newInsights/OnHold_Flagged_Assisted_IssueReported_NotServiced.png';
import icOnHoldFlaggedAssistedIssueReportedServiced from '../assets/img/newInsights/OnHold_Flagged_Assisted_IssueReported_Serviced.png';
import icOnHoldFlaggedAssistedIssueReported from '../assets/img/newInsights/OnHold_Flagged_Assisted_IssueReported.png';
import icOnHoldFlaggedAssistedOnHold from '../assets/img/newInsights/OnHold_Flagged_Assisted_OnHold.png';
import icOnHoldFlaggedAssistedReassigned from '../assets/img/newInsights/OnHold_Flagged_Assisted_Reassigned.png';
import icOnHoldFlaggedAssistedScheduled from '../assets/img/newInsights/OnHold_Flagged_Assisted_Scheduled.png';
import icOnHoldFlaggedCancelled from '../assets/img/newInsights/OnHold_Flagged_Cancelled.png';
import icOnHoldFlaggedCompleted from '../assets/img/newInsights/OnHold_Flagged_Completed.png';
import icOnHoldFlaggedInProgress from '../assets/img/newInsights/OnHold_Flagged_InProgress.png';
import icOnHoldFlaggedIssueReportedServiced from '../assets/img/newInsights/OnHold_Flagged_IssueReported_Serviced.png';
import icOnHoldFlaggedIssueReported from '../assets/img/newInsights/OnHold_Flagged_IssueReported.png';
import icOnHoldFlaggedOnHold from '../assets/img/newInsights/OnHold_Flagged_OnHold.png';
import icOnHoldFlaggedReassigned from '../assets/img/newInsights/OnHold_Flagged_Reassigned.png';
import icOnHoldFlaggedScheduled from '../assets/img/newInsights/OnHold_Flagged_Scheduled.png';
import icOnHoldInProgress from '../assets/img/newInsights/OnHold_InProgress.png';
import icOnHoldIssueReportedNotServiced from '../assets/img/newInsights/OnHold_IssueReported_NotServiced.png';
import icOnHoldIssueReportedServiced from '../assets/img/newInsights/OnHold_IssueReported_Serviced.png';
import icOnHoldIssueReported from '../assets/img/newInsights/OnHold_IssueReported.png';
import icOnHoldOnHold from '../assets/img/newInsights/OnHold_OnHold.png';
import icOnHoldReassigned from '../assets/img/newInsights/OnHold_Reassigned.png';
import icOnHoldScheduled from '../assets/img/newInsights/OnHold_Scheduled.png';
import icOnHold from '../assets/img/newInsights/OnHold.png';
import icReassigned from '../assets/img/newInsights/Reassigned.png';
import icScheduled from '../assets/img/newInsights/Scheduled.png';
import icSuspendedAssistedCancelled from '../assets/img/newInsights/Suspended_Assisted_Cancelled.png';
import icSuspendedAssistedCompleted from '../assets/img/newInsights/Suspended_Assisted_Completed.png';
import icSuspendedAssistedInProgress from '../assets/img/newInsights/Suspended_Assisted_InProgress.png';
import icSuspendedAssistedIssueReportedNotServiced from '../assets/img/newInsights/Suspended_Assisted_IssueReported_NotServiced.png';
import icSuspendedAssistedIssueReportedServiced from '../assets/img/newInsights/Suspended_Assisted_IssueReported_Serviced.png';
import icSuspendedAssistedIssueReported from '../assets/img/newInsights/Suspended_Assisted_IssueReported.png';
import icSuspendedAssistedOnHold from '../assets/img/newInsights/Suspended_Assisted_OnHold.png';
import icSuspendedAssistedReassigned from '../assets/img/newInsights/Suspended_Assisted_Reassigned.png';
import icSuspendedAssistedScheduled from '../assets/img/newInsights/Suspended_Assisted_Scheduled.png';
import icSuspendedCancelled from '../assets/img/newInsights/Suspended_Cancelled.png';
import icSuspendedCompleted from '../assets/img/newInsights/Suspended_Completed.png';
import icSuspendedFlaggedAssistedCancelled from '../assets/img/newInsights/Suspended_Flagged_Assisted_Cancelled.png';
import icSuspendedFlaggedAssistedCompleted from '../assets/img/newInsights/Suspended_Flagged_Assisted_Completed.png';
import icSuspendedFlaggedAssistedInProgress from '../assets/img/newInsights/Suspended_Flagged_Assisted_InProgress.png';
import icSuspendedFlaggedAssistedIssueReportedNotServiced from '../assets/img/newInsights/Suspended_Flagged_Assisted_IssueReported_NotServiced.png';
import icSuspendedFlaggedAssistedIssueReportedServiced from '../assets/img/newInsights/Suspended_Flagged_Assisted_IssueReported_Serviced.png';
import icSuspendedFlaggedAssistedIssueReported from '../assets/img/newInsights/Suspended_Flagged_Assisted_IssueReported.png';
import icSuspendedFlaggedAssistedOnHold from '../assets/img/newInsights/Suspended_Flagged_Assisted_OnHold.png';
import icSuspendedFlaggedAssistedReassigned from '../assets/img/newInsights/Suspended_Flagged_Assisted_Reassigned.png';
import icSuspendedFlaggedAssistedScheduled from '../assets/img/newInsights/Suspended_Flagged_Assisted_Scheduled.png';
import icSuspendedFlaggedCancelled from '../assets/img/newInsights/Suspended_Flagged_Cancelled.png';
import icSuspendedFlaggedCompleted from '../assets/img/newInsights/Suspended_Flagged_Completed.png';
import icSuspendedFlaggedInProgress from '../assets/img/newInsights/Suspended_Flagged_InProgress.png';
import icSuspendedFlaggedIssueReportedNotServiced from '../assets/img/newInsights/Suspended_Flagged_IssueReported_NotServiced.png';
import icSuspendedFlaggedIssueReportedServiced from '../assets/img/newInsights/Suspended_Flagged_IssueReported_Serviced.png';
import icSuspendedFlaggedIssueReported from '../assets/img/newInsights/Suspended_Flagged_IssueReported.png';
import icSuspendedFlaggedOnHold from '../assets/img/newInsights/Suspended_Flagged_OnHold.png';
import icSuspendedFlaggedReassigned from '../assets/img/newInsights/Suspended_Flagged_Reassigned.png';
import icSuspendedFlaggedScheduled from '../assets/img/newInsights/Suspended_Flagged_Scheduled.png';
import icSuspendedInProgress from '../assets/img/newInsights/Suspended_InProgress.png';
import icSuspendedIssueReportedNotServiced from '../assets/img/newInsights/Suspended_IssueReported_NotServiced.png';
import icSuspendedIssueReportedServiced from '../assets/img/newInsights/Suspended_IssueReported_Serviced.png';
import icSuspendedIssueReported from '../assets/img/newInsights/Suspended_IssueReported.png';
import icSuspendedOnHold from '../assets/img/newInsights/Suspended_OnHold.png';
import icSuspendedReassigned from '../assets/img/newInsights/Suspended_Reassigned.png';
import icSuspendedScheduled from '../assets/img/newInsights/Suspended_Scheduled.png';

import { CityInsight } from 'src/dashboard/interfaces/cityInsights';
import { ContainerInsights } from 'src/routes/components/mapWithTimeline/Interfaces';
import { VehicleInsight } from 'src/dashboard/interfaces/vehicleInsights';
import { VehicleInsightDetails } from 'src/dashboard/interfaces/vehiclePositions';
import IconWithTypes from '../interfaces/IconWithTypes';
import { GeoFenceIncidentsItem } from 'src/dashboard/interfaces/routesData';
import { CommunityInsightItem } from 'src/insights/interfaces/communityInsights';

export const INSIGHT_ICON_TYPES: IconWithTypes[] = [
  new IconWithTypes('issue', issue, [
    'a',
    'abatement',
    'additionalLoads',
    'after',
    'animalNotFound',
    'animalWaste',
    'appliance',
    'applianceViolation',
    'assistedStop',
    'audit',
    'b',
    'baggedGarbage',
    'batteries',
    'before',
    'binNotOutPickedUp',
    'blocked',
    'blockedAlley',
    'blockedConstruction',
    'blockedSnowOrIce',
    'blockedStreet',
    'bobcat',
    'bulkItem',
    'c',
    'calledTooEarly',
    'car',
    'cardboard',
    'citizenError',
    'cleaningFee',
    'codeEnforcementNeeded',
    'containerBlocked',
    'containerNotOut',
    'containerNotReady',
    'containerNotReadyPerCustomer',
    'containerReplacement',
    'contaminatedUnacceptable',
    'contractorGenerated',
    'couldntEnterProperty',
    'couldntEnterReachAddress',
    'couldntLocateContainer',
    'couldntReachAddress',
    'craneNeeded',
    'crewError',
    'curbObstruction',
    'damagedContainer',
    'damagedWarranty',
    'damageToPrivateProperty',
    'deadAnimal',
    'deferToNextCycle',
    'diapers',
    'differentTruck',
    'dirtRocks',
    'duplicate',
    'electronics',
    'electronics',
    'emptyContainer',
    'eviction',
    'excessContainers',
    'extra',
    'flagged',
    'flammablesFuelTanksBatteries',
    'foam',
    'freonAppliance',
    'frozenContainer',
    'gate',
    'glass',
    'handledByDifferentAgency',
    'hazardousMaterials',
    'hazardousWaste',
    'hhw',
    'holidayTree',
    'hotList',
    'illegalDumping',
    'illegalDumpingCollected',
    'illegalDumpingNotFound',
    'illegalDumpingReferral',
    'illegalDumpingSelfCleaned',
    'illegalDumpingUnderReview',
    'illegalSetout',
    'improperSetOut',
    'incorrectCollectionDay',
    'incorrectFa',
    'invalidAddress',
    'issueReported',
    'laborTime',
    'lateSetOut',
    'lockedContainer',
    'lostCart',
    'lumber',
    'manualCrewNeeded',
    'medicalWaste',
    'metalPlates',
    'missed',
    'moveContainerForAccess',
    'moveOut',
    'needles',
    'noActionTaken',
    'nonCityIssuedContainer',
    'notCollected',
    'notServicedByCity',
    'notSet',
    'obstruction',
    'onPrivateProperty',
    'openLid',
    'other',
    'outOfJurisdiction',
    'outOfReach',
    'overflowing',
    'overgrownTreeBush',
    'overturned',
    'overweightContainer',
    'pedestrian',
    'percentageFullFrom1To25',
    'percentageFullFrom26To50',
    'percentageFullFrom51To75',
    'percentageFullFrom76To100',
    'petPreventingAccess',
    'pickedUp',
    'pickedUpDifferentSize',
    'pickupRequestCancelled',
    'pizzaBoxes',
    'prep',
    'privateCollection',
    'prohibitedItem',
    'pullOut',
    'referredToAgency',
    'referTo311',
    'referToDot',
    'referToOtherAgency',
    'referToParks',
    'referToWatershed',
    'refilledByCustomer',
    'rejectionTag',
    'returnTrip',
    'returnTripSnowPlow',
    'routeDelayed',
    'routeInterrupted',
    'scrapMetal',
    'scrapMetalPotsPans',
    'scrapMetalWoodFurniture',
    'shreddedPaper',
    'stopRouteToReloadSalt',
    'streetConstruction',
    'streetSign',
    'sweepIncomplete',
    'tanglers',
    'tires',
    'toGoContainers',
    'trashBag',
    'trashBags',
    'trashCan',
    'unbaggedTrash',
    'uncurbed',
    'waitTime',
    'warningTag',
    'wasteContainer',
    'wrongCartType',
    'wrongLocation',
    'yardWaste',
    'yardWasteDelivery',
  ]),
  new IconWithTypes('baggedRecyclables', baggedRecyclables, ['baggedRecyclables', 'doNotBagRecyclables']),
  new IconWithTypes('containerNotOut', containerNotOut, ['binNotOut']),
  new IconWithTypes('containerOut', containerOut, ['containerOut']),
  new IconWithTypes('damagedContainer', damagedContainer, ['brokenBin', 'brokenContainer', 'containerBroken']),
  new IconWithTypes('brokenSidewalkCurb', brokenSidewalkCurb, ['brokenSidewalkCurb']),
  new IconWithTypes('brokenTrafficSignal', brokenTrafficSignal, ['brokenTrafficSignal']),
  new IconWithTypes('cancelled', cancelled, ['cancelled']),
  new IconWithTypes('cardboardInContainer', cardboardInContainer, ['cardboardInContainer']),
  new IconWithTypes('cardboardNotFlattened', cardboardNotFlattened, ['cardboardNotFlattened']),
  new IconWithTypes('cleanRecycling', cleanRecycling, [
    'cleanRecycling',
    'recycle3048Gallons',
    'recycleLessThan30Gallons',
    'recycleMoreThan48Gallons',
  ]),
  new IconWithTypes('cloggedInlet', cloggedInlet, ['cloggedInlet']),
  new IconWithTypes('cog', cog, ['cog']),
  new IconWithTypes('completed', completed, ['completed']),
  new IconWithTypes('contaminated', contaminated, ['contaminated']),
  new IconWithTypes('curbObstruction', curbObstruction, ['curbObstruction']),
  new IconWithTypes('damagedStreetSign', damagedStreetSign, ['damagedStreetSign']),
  new IconWithTypes('exceptionsObstacles', exceptionsObstacles, ['exceptionsObstacles']),
  new IconWithTypes('graffiti', graffiti, ['graffiti']),
  new IconWithTypes('handicap', handicap, ['handicap']),
  new IconWithTypes('hangingWires', hangingWires, ['hangingWires']),
  new IconWithTypes('hardDriving', hardDriving, [
    'hardAcceleration',
    'hardBraking',
    'hardLeftTurn',
    'hardRightTurn',
    'hardTurning',
  ]),
  new IconWithTypes('highGrassWeeds', highGrassWeeds, ['highGrassWeeds']),
  new IconWithTypes('idleTime', idleTime, ['idleTime']),
  new IconWithTypes('inProgress', inProgress, ['inProgress']),
  new IconWithTypes('insideGeoFence', insideGeoFence, ['insideGeoFence']),
  new IconWithTypes('improperStorageArea', improperStorageArea, ['improperStorageArea']),
  new IconWithTypes('leftAsTrash', leftAsTrash, ['leftAsTrash']),
  new IconWithTypes('litterJunk', litterJunk, ['litterJunk']),
  new IconWithTypes('lowBranches', lowBranches, ['lowBranches']),
  new IconWithTypes('materialContamination', materialContamination, ['materialContamination']),
  new IconWithTypes('multiple', multiple, ['multiple']),
  new IconWithTypes('noElectronics', noElectronics, ['noElectronics']),
  new IconWithTypes('foodOrLiquid', foodOrLiquid, ['noFoodOrLiquid']),
  new IconWithTypes('plasticBags', plasticBags, ['noPlasticBags']),
  new IconWithTypes('tangledCords', tangledCords, ['noTanglersCordsHosesOrChains']),
  new IconWithTypes('metalWoodTanks', metalWoodTanks, ['noTanksWoodPlasticFurnitureOrMetal']),
  new IconWithTypes('missingNewAddress', missingNewAddress, ['missingNewAddress']),
  new IconWithTypes('notSweeping', notSweeping, ['notSweeping']),
  new IconWithTypes('number1', number1, ['number1']),
  new IconWithTypes('number2', number2, ['number2']),
  new IconWithTypes('number3', number3, ['number3']),
  new IconWithTypes('offRoute', offRoute, ['offRoute']),
  new IconWithTypes('offRouteStationary', offRouteStationary, ['offRouteStationary']),
  new IconWithTypes('oops', oops, ['oops']),
  new IconWithTypes('outsideGeoFence', outsideGeoFence, ['outsideGeoFence']),
  new IconWithTypes('overflowingBins', overflowingBins, ['overflowingBins']),
  new IconWithTypes('placedOnHold', placedOnHold, ['placedOnHold']),
  new IconWithTypes('plasticInContainer', plasticInContainer, ['plasticInContainer']),
  new IconWithTypes('potholes', potholes, ['potholes']),
  new IconWithTypes('recRolltainerOutside', recRolltainerOutside, ['recRolltainerOutside']),
  new IconWithTypes('scheduled', scheduled, ['scheduled']),
  new IconWithTypes('serviced', serviced, ['serviced']),
  new IconWithTypes('speeding', speeding, ['speeding']),
  new IconWithTypes('stationary', stationary, ['stationary']),
  new IconWithTypes('streetLightBroken', streetLightBroken, ['streetLightBroken']),
  new IconWithTypes('streetObstruction', streetObstruction, ['streetObstruction']),
  new IconWithTypes('styrofoam', styrofoam, ['styrofoam']),
  new IconWithTypes('textiles', textiles, ['textiles']),
  new IconWithTypes('thanks', thanks, ['thanks']),
  new IconWithTypes('trash', trash, ['trash3048Gallons', 'trashLessThan30Gallons', 'trashMoreThan48Gallons']),
  new IconWithTypes('unlockedContainer', unlockedContainer, ['unlockedContainer']),
  new IconWithTypes('unScheduled', unScheduled, ['unScheduled']),
  new IconWithTypes('vacant', vacant, ['vacant']),
  new IconWithTypes('vacantCar', vacantCar, ['vacantCar']),
];

const mapIconTypesToIconMap = (types: IconWithTypes[]): { [k: string]: string } =>
  mapValues(
    mapKeys(
      flatten(
        types.map(iconType => iconType.types.map(insightType => ({ type: insightType, icon: iconType.iconUrl }))),
      ),
      value => value.type,
    ),
    value => value.icon,
  );

const INSIGHT_ICONS = mapIconTypesToIconMap(INSIGHT_ICON_TYPES);

export default INSIGHT_ICONS;

export const getIconTypeForCityInsight = (insight: CityInsight | CommunityInsightItem) => {
  const insightType = humps.camelize(insight.locationFlaggingType.technicalName);
  const iconType = INSIGHT_ICON_TYPES.find(type => type.types.indexOf(insightType) !== -1);

  return iconType;
};

export const CONTAINER_INSIGHT_ICON_TYPES = INSIGHT_ICON_TYPES.filter(
  type =>
    ['scheduled', 'cancelled', 'inProgress', 'placedOnHold', 'completed', 'issue', 'serviced'].indexOf(type.id) !== -1,
);

export const NEW_INSIGHT_ICON_TYPES = [
  new IconWithTypes('icAssistedCancelled', icAssistedCancelled, ['icAssistedCancelled']),
  new IconWithTypes('icAssistedCompleted', icAssistedCompleted, ['icAssistedCompleted']),
  new IconWithTypes('icAssistedInProgress', icAssistedInProgress, ['icAssistedInProgress']),
  new IconWithTypes('icAssistedIssueReportedNotServiced', icAssistedIssueReportedNotServiced, [
    'icAssistedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icAssistedIssueReportedServiced', icAssistedIssueReportedServiced, [
    'icAssistedIssueReportedServiced',
  ]),
  new IconWithTypes('icAssistedIssueReported', icAssistedIssueReported, ['icAssistedIssueReported']),
  new IconWithTypes('icAssistedOnHold', icAssistedOnHold, ['icAssistedOnHold']),
  new IconWithTypes('icAssistedReassigned', icAssistedReassigned, ['icAssistedReassigned']),
  new IconWithTypes('icAssistedScheduled', icAssistedScheduled, ['icAssistedScheduled']),
  new IconWithTypes('icCancelled', icCancelled, ['icCancelled']),
  new IconWithTypes('icCompleted', icCompleted, ['icCompleted']),
  new IconWithTypes('icFlaggedAssistedCancelled', icFlaggedAssistedCancelled, ['icFlaggedAssistedCancelled']),
  new IconWithTypes('icFlaggedAssistedCompleted', icFlaggedAssistedCompleted, ['icFlaggedAssistedCompleted']),
  new IconWithTypes('icFlaggedAssistedIssueReportedNotServiced', icFlaggedAssistedIssueReportedNotServiced, [
    'icFlaggedAssistedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icFlaggedAssistedIssueReportedServiced', icFlaggedAssistedIssueReportedServiced, [
    'icFlaggedAssistedIssueReportedServiced',
  ]),
  new IconWithTypes('icFlaggedAssistedIssueReported', icFlaggedAssistedIssueReported, [
    'icFlaggedAssistedIssueReported',
  ]),
  new IconWithTypes('icFlaggedAssistedReassigned', icFlaggedAssistedReassigned, ['icFlaggedAssistedReassigned']),
  new IconWithTypes('icFlaggedAssistedScheduled', icFlaggedAssistedScheduled, ['icFlaggedAssistedScheduled']),
  new IconWithTypes('icFlaggedAssistedOnHold', icFlaggedAssistedOnHold, ['icFlaggedAssistedOnHold']),
  new IconWithTypes('icFlaggedCancelled', icFlaggedCancelled, ['icFlaggedCancelled']),
  new IconWithTypes('icFlaggedCompleted', icFlaggedCompleted, ['icFlaggedCompleted']),
  new IconWithTypes('icFlaggedInProgress', icFlaggedInProgress, ['icFlaggedInProgress']),
  new IconWithTypes('icFlaggedIssueReportedNotServiced', icFlaggedIssueReportedNotServiced, [
    'icFlaggedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icFlaggedIssueReportedServiced', icFlaggedIssueReportedServiced, [
    'icFlaggedIssueReportedServiced',
  ]),
  new IconWithTypes('icFlaggedIssueReported', icFlaggedIssueReported, ['icFlaggedIssueReported']),
  new IconWithTypes('icFlaggedOnHold', icFlaggedOnHold, ['icFlaggedOnHold']),
  new IconWithTypes('icFlaggedReassigned', icFlaggedReassigned, ['icFlaggedReassigned']),
  new IconWithTypes('icFlaggedScheduled', icFlaggedScheduled, ['icFlaggedScheduled']),
  new IconWithTypes('icInProgress', icInProgress, ['icInProgress']),
  new IconWithTypes('icIssueReportedNotServiced', icIssueReportedNotServiced, ['icIssueReportedNotServiced']),
  new IconWithTypes('icIssueReportedServiced', icIssueReportedServiced, ['icIssueReportedServiced']),
  new IconWithTypes('icIssueReported', icIssueReported, ['icIssueReported']),
  new IconWithTypes('icNewAssistedCancelled', icNewAssistedCancelled, ['icNewAssistedCancelled']),
  new IconWithTypes('icNewAssistedCompleted', icNewAssistedCompleted, ['icNewAssistedCompleted']),
  new IconWithTypes('icNewAssistedInProgress', icNewAssistedInProgress, ['icNewAssistedInProgress']),
  new IconWithTypes('icNewAssistedIssueReportedNotServiced', icNewAssistedIssueReportedNotServiced, [
    'icNewAssistedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icNewAssistedIssueReportedServiced', icNewAssistedIssueReportedServiced, [
    'icNewAssistedIssueReportedServiced',
  ]),
  new IconWithTypes('icNewAssistedIssueReported', icNewAssistedIssueReported, ['icNewAssistedIssueReported']),
  new IconWithTypes('icNewAssistedOnHold', icNewAssistedOnHold, ['icNewAssistedOnHold']),
  new IconWithTypes('icNewAssistedReassigned', icNewAssistedReassigned, ['icNewAssistedReassigned']),
  new IconWithTypes('icNewAssistedScheduled', icNewAssistedScheduled, ['icNewAssistedScheduled']),
  new IconWithTypes('icNewCancelled', icNewCancelled, ['icNewCancelled']),
  new IconWithTypes('icNewCompleted', icNewCompleted, ['icNewCompleted']),
  new IconWithTypes('icNewFlaggedAssistedCancelled', icNewFlaggedAssistedCancelled, ['icNewFlaggedAssistedCancelled']),
  new IconWithTypes('icNewFlaggedAssistedCompleted', icNewFlaggedAssistedCompleted, ['icNewFlaggedAssistedCompleted']),
  new IconWithTypes('icNewFlaggedAssistedInProgress', icNewFlaggedAssistedInProgress, [
    'icNewFlaggedAssistedInProgress',
  ]),
  new IconWithTypes('icNewFlaggedAssistedIssueReportedNotServiced', icNewFlaggedAssistedIssueReportedNotServiced, [
    'icNewFlaggedAssistedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icNewFlaggedAssistedIssueReportedServiced', icNewFlaggedAssistedIssueReportedServiced, [
    'icNewFlaggedAssistedIssueReportedServiced',
  ]),
  new IconWithTypes('icNewFlaggedAssistedIssueReported', icNewFlaggedAssistedIssueReported, [
    'icNewFlaggedAssistedIssueReported',
  ]),
  new IconWithTypes('icNewFlaggedAssistedOnHold', icNewFlaggedAssistedOnHold, ['icNewFlaggedAssistedOnHold']),
  new IconWithTypes('icNewFlaggedAssistedReassigned', icNewFlaggedAssistedReassigned, [
    'icNewFlaggedAssistedReassigned',
  ]),
  new IconWithTypes('icNewFlaggedAssistedScheduled', icNewFlaggedAssistedScheduled, ['icNewFlaggedAssistedScheduled']),
  new IconWithTypes('icNewFlaggedCancelled', icNewFlaggedCancelled, ['icNewFlaggedCancelled']),
  new IconWithTypes('icNewFlaggedCompleted', icNewFlaggedCompleted, ['icNewFlaggedCompleted']),
  new IconWithTypes('icNewFlaggedInProgress', icNewFlaggedInProgress, ['icNewFlaggedInProgress']),
  new IconWithTypes('icNewFlaggedIssueReportedNotServiced', icNewFlaggedIssueReportedNotServiced, [
    'icNewFlaggedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icNewFlaggedIssueReportedServiced', icNewFlaggedIssueReportedServiced, [
    'icNewFlaggedIssueReportedServiced',
  ]),
  new IconWithTypes('icNewFlaggedIssueReported', icNewFlaggedIssueReported, ['icNewFlaggedIssueReported']),
  new IconWithTypes('icNewFlaggedOnHold', icNewFlaggedOnHold, ['icNewFlaggedOnHold']),
  new IconWithTypes('icNewFlaggedReassigned', icNewFlaggedReassigned, ['icNewFlaggedReassigned']),
  new IconWithTypes('icNewFlaggedScheduled', icNewFlaggedScheduled, ['icNewFlaggedScheduled']),
  new IconWithTypes('icNewInProgress', icNewInProgress, ['icNewInProgress']),
  new IconWithTypes('icNewIssueReportedNotServiced', icNewIssueReportedNotServiced, ['icNewIssueReportedNotServiced']),
  new IconWithTypes('icNewIssueReportedServiced', icNewIssueReportedServiced, ['icNewIssueReportedServiced']),
  new IconWithTypes('icNewIssueReported', icNewIssueReported, ['icNewIssueReported']),
  new IconWithTypes('icNewOnHold', icNewOnHold, ['icNewOnHold']),
  new IconWithTypes('icNewReassigned', icNewReassigned, ['icNewReassigned']),
  new IconWithTypes('icNewScheduled', icNewScheduled, ['icNewScheduled']),
  new IconWithTypes('icOnHoldAssistedCompleted', icOnHoldAssistedCompleted, ['icOnHoldAssistedCompleted']),
  new IconWithTypes('icOnHoldAssistedCancelled', icOnHoldAssistedCancelled, ['icOnHoldAssistedCancelled']),
  new IconWithTypes('icOnHoldAssistedInProgress', icOnHoldAssistedInProgress, ['icOnHoldAssistedInProgress']),
  new IconWithTypes('icOnHoldAssistedIssueReportedNotServiced', icOnHoldAssistedIssueReportedNotServiced, [
    'icOnHoldAssistedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icOnHoldAssistedIssueReportedServiced', icOnHoldAssistedIssueReportedServiced, [
    'icOnHoldAssistedIssueReportedServiced',
  ]),
  new IconWithTypes('icOnHoldAssistedIssueReported', icOnHoldAssistedIssueReported, ['icOnHoldAssistedIssueReported']),
  new IconWithTypes('icOnHoldAssistedOnHold', icOnHoldAssistedOnHold, ['icOnHoldAssistedOnHold']),
  new IconWithTypes('icOnHoldAssistedReassigned', icOnHoldAssistedReassigned, ['icOnHoldAssistedReassigned']),
  new IconWithTypes('icOnHoldAssistedScheduled', icOnHoldAssistedScheduled, ['icOnHoldAssistedScheduled']),
  new IconWithTypes('icOnHoldCancelled', icOnHoldCancelled, ['icOnHoldCancelled']),
  new IconWithTypes('icOnHoldCompleted', icOnHoldCompleted, ['icOnHoldCompleted']),
  new IconWithTypes('icOnHoldFlaggedAssistedCancelled', icOnHoldFlaggedAssistedCancelled, [
    'icOnHoldFlaggedAssistedCancelled',
  ]),
  new IconWithTypes('icOnHoldFlaggedAssistedCompleted', icOnHoldFlaggedAssistedCompleted, [
    'icOnHoldFlaggedAssistedCompleted',
  ]),
  new IconWithTypes('icOnHoldFlaggedAssistedInProgress', icOnHoldFlaggedAssistedInProgress, [
    'icOnHoldFlaggedAssistedInProgress',
  ]),
  new IconWithTypes(
    'icOnHoldFlaggedAssistedIssueReportedNotServiced',
    icOnHoldFlaggedAssistedIssueReportedNotServiced,
    ['icOnHoldFlaggedAssistedIssueReportedNotServiced'],
  ),
  new IconWithTypes('icOnHoldFlaggedAssistedIssueReportedServiced', icOnHoldFlaggedAssistedIssueReportedServiced, [
    'icOnHoldFlaggedAssistedIssueReportedServiced',
  ]),
  new IconWithTypes('icOnHoldFlaggedAssistedIssueReported', icOnHoldFlaggedAssistedIssueReported, [
    'icOnHoldFlaggedAssistedIssueReported',
  ]),
  new IconWithTypes('icOnHoldFlaggedAssistedOnHold', icOnHoldFlaggedAssistedOnHold, ['icOnHoldFlaggedAssistedOnHold']),
  new IconWithTypes('icOnHoldFlaggedAssistedReassigned', icOnHoldFlaggedAssistedReassigned, [
    'icOnHoldFlaggedAssistedReassigned',
  ]),
  new IconWithTypes('icOnHoldFlaggedAssistedScheduled', icOnHoldFlaggedAssistedScheduled, [
    'icOnHoldFlaggedAssistedScheduled',
  ]),
  new IconWithTypes('icOnHoldFlaggedCancelled', icOnHoldFlaggedCancelled, ['icOnHoldFlaggedCancelled']),
  new IconWithTypes('icOnHoldFlaggedCompleted', icOnHoldFlaggedCompleted, ['icOnHoldFlaggedCompleted']),
  new IconWithTypes('icOnHoldFlaggedInProgress', icOnHoldFlaggedInProgress, ['icOnHoldFlaggedInProgress']),
  new IconWithTypes(icOnHoldFlaggedIssueReportedServiced, icOnHoldFlaggedIssueReportedServiced, [
    'icOnHoldFlaggedIssueReportedServiced',
  ]),
  new IconWithTypes('icOnHoldFlaggedIssueReported', icOnHoldFlaggedIssueReported, ['icOnHoldFlaggedIssueReported']),
  new IconWithTypes('icOnHoldFlaggedOnHold', icOnHoldFlaggedOnHold, ['icOnHoldFlaggedOnHold']),
  new IconWithTypes('icOnHoldFlaggedReassigned', icOnHoldFlaggedReassigned, ['icOnHoldFlaggedReassigned']),
  new IconWithTypes('icOnHoldFlaggedScheduled', icOnHoldFlaggedScheduled, ['icOnHoldFlaggedScheduled']),
  new IconWithTypes('icOnHoldInProgress', icOnHoldInProgress, ['icOnHoldInProgress']),
  new IconWithTypes('icOnHoldIssueReportedNotServiced', icOnHoldIssueReportedNotServiced, [
    'icOnHoldIssueReportedNotServiced',
  ]),
  new IconWithTypes('icOnHoldIssueReportedServiced', icOnHoldIssueReportedServiced, ['icOnHoldIssueReportedServiced']),
  new IconWithTypes('icOnHoldIssueReported', icOnHoldIssueReported, ['icOnHoldIssueReported']),
  new IconWithTypes('icOnHoldOnHold', icOnHoldOnHold, ['icOnHoldOnHold']),
  new IconWithTypes('icOnHoldReassigned', icOnHoldReassigned, ['icOnHoldReassigned']),
  new IconWithTypes('icOnHoldScheduled', icOnHoldScheduled, ['icOnHoldScheduled']),
  new IconWithTypes('icOnHold', icOnHold, ['icOnHold']),
  new IconWithTypes('icReassigned', icReassigned, ['icReassigned']),
  new IconWithTypes('icScheduled', icScheduled, ['icScheduled']),
  new IconWithTypes('icSuspendedAssistedCancelled', icSuspendedAssistedCancelled, ['icSuspendedAssistedCancelled']),
  new IconWithTypes('icSuspendedAssistedCompleted', icSuspendedAssistedCompleted, ['icSuspendedAssistedCompleted']),
  new IconWithTypes('icSuspendedAssistedInProgress', icSuspendedAssistedInProgress, ['icSuspendedAssistedInProgress']),
  new IconWithTypes('icSuspendedAssistedIssueReportedNotServiced', icSuspendedAssistedIssueReportedNotServiced, [
    'icSuspendedAssistedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icSuspendedAssistedIssueReportedServiced', icSuspendedAssistedIssueReportedServiced, [
    'icSuspendedAssistedIssueReportedServiced',
  ]),
  new IconWithTypes('icSuspendedAssistedIssueReported', icSuspendedAssistedIssueReported, [
    'icSuspendedAssistedIssueReported',
  ]),
  new IconWithTypes('icSuspendedAssistedOnHold', icSuspendedAssistedOnHold, ['icSuspendedAssistedOnHold']),
  new IconWithTypes('icSuspendedAssistedReassigned', icSuspendedAssistedReassigned, ['icSuspendedAssistedReassigned']),
  new IconWithTypes('icSuspendedAssistedScheduled', icSuspendedAssistedScheduled, ['icSuspendedAssistedScheduled']),
  new IconWithTypes('icSuspendedCancelled', icSuspendedCancelled, ['icSuspendedCancelled']),
  new IconWithTypes('icSuspendedCompleted', icSuspendedCompleted, ['icSuspendedCompleted']),
  new IconWithTypes('icSuspendedFlaggedAssistedCancelled', icSuspendedFlaggedAssistedCancelled, [
    'icSuspendedFlaggedAssistedCancelled',
  ]),
  new IconWithTypes('icSuspendedFlaggedAssistedCompleted', icSuspendedFlaggedAssistedCompleted, [
    'icSuspendedFlaggedAssistedCompleted',
  ]),
  new IconWithTypes('icSuspendedFlaggedAssistedInProgress', icSuspendedFlaggedAssistedInProgress, [
    'icSuspendedFlaggedAssistedInProgress',
  ]),
  new IconWithTypes(
    'icSuspendedFlaggedAssistedIssueReportedNotServiced',
    icSuspendedFlaggedAssistedIssueReportedNotServiced,
    ['icSuspendedFlaggedAssistedIssueReportedNotServiced'],
  ),
  new IconWithTypes(
    'icSuspendedFlaggedAssistedIssueReportedServiced',
    icSuspendedFlaggedAssistedIssueReportedServiced,
    ['icSuspendedFlaggedAssistedIssueReportedServiced'],
  ),
  new IconWithTypes('icSuspendedFlaggedAssistedIssueReported', icSuspendedFlaggedAssistedIssueReported, [
    'icSuspendedFlaggedAssistedIssueReported',
  ]),
  new IconWithTypes('icSuspendedFlaggedAssistedOnHold', icSuspendedFlaggedAssistedOnHold, [
    'icSuspendedFlaggedAssistedOnHold',
  ]),
  new IconWithTypes('icSuspendedFlaggedAssistedReassigned', icSuspendedFlaggedAssistedReassigned, [
    'icSuspendedFlaggedAssistedReassigned',
  ]),
  new IconWithTypes('icSuspendedFlaggedAssistedScheduled', icSuspendedFlaggedAssistedScheduled, [
    'icSuspendedFlaggedAssistedScheduled',
  ]),
  new IconWithTypes('icSuspendedFlaggedCancelled', icSuspendedFlaggedCancelled, ['icSuspendedFlaggedCancelled']),
  new IconWithTypes('icSuspendedFlaggedCompleted', icSuspendedFlaggedCompleted, ['icSuspendedFlaggedCompleted']),
  new IconWithTypes('icSuspendedFlaggedInProgress', icSuspendedFlaggedInProgress, ['icSuspendedFlaggedInProgress']),
  new IconWithTypes('icSuspendedFlaggedIssueReportedNotServiced', icSuspendedFlaggedIssueReportedNotServiced, [
    'icSuspendedFlaggedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icSuspendedFlaggedIssueReportedServiced', icSuspendedFlaggedIssueReportedServiced, [
    'icSuspendedFlaggedIssueReportedServiced',
  ]),
  new IconWithTypes('icSuspendedFlaggedIssueReported', icSuspendedFlaggedIssueReported, [
    'icSuspendedFlaggedIssueReported',
  ]),
  new IconWithTypes('icSuspendedFlaggedOnHold', icSuspendedFlaggedOnHold, ['icSuspendedFlaggedOnHold']),
  new IconWithTypes('icSuspendedFlaggedReassigned', icSuspendedFlaggedReassigned, ['icSuspendedFlaggedReassigned']),
  new IconWithTypes('icSuspendedFlaggedScheduled', icSuspendedFlaggedScheduled, ['icSuspendedFlaggedScheduled']),
  new IconWithTypes('icSuspendedInProgress', icSuspendedInProgress, ['icSuspendedInProgress']),
  new IconWithTypes('icSuspendedIssueReportedNotServiced', icSuspendedIssueReportedNotServiced, [
    'icSuspendedIssueReportedNotServiced',
  ]),
  new IconWithTypes('icSuspendedIssueReportedServiced', icSuspendedIssueReportedServiced, [
    'icSuspendedIssueReportedServiced',
  ]),
  new IconWithTypes('icSuspendedIssueReported', icSuspendedIssueReported, ['icSuspendedIssueReported']),
  new IconWithTypes('icSuspendedOnHold', icSuspendedOnHold, ['icSuspendedOnHold']),
  new IconWithTypes('icSuspendedReassigned', icSuspendedReassigned, ['icSuspendedReassigned']),
  new IconWithTypes('icSuspendedScheduled', icSuspendedScheduled, ['icSuspendedScheduled']),
  new IconWithTypes('serviced', serviced, ['serviced']),
];

export const getIconTypeForContainerInsight = (insight: ContainerInsights) => {
  const insightType = humps.camelize(insight.insightSubTypes[0].name);
  const iconType = CONTAINER_INSIGHT_ICON_TYPES.find(type => type.types.indexOf(insightType) !== -1);

  return iconType;
};

export const VEHICLE_INSIGHT_ICON_TYPES = INSIGHT_ICON_TYPES.filter(
  type =>
    [
      'stationary',
      'speeding',
      'offRoute',
      'offRouteStationary',
      'outsideGeoFence',
      'insideGeoFence',
      'hardDriving',
    ].indexOf(type.id) !== -1,
);

export const getIconTypeForVehicleInsight = (insight: VehicleInsight) => {
  const insightType = humps.camelize(insight.insightSubTypes[0].name);
  const iconType = VEHICLE_INSIGHT_ICON_TYPES.find(type => type.types.indexOf(insightType) !== -1);

  return iconType;
};

export const getIconTypeForGeoFenceInsight = (insight: GeoFenceIncidentsItem) => {
  const insightType = humps.camelize(insight.reportType);
  const iconType = INSIGHT_ICON_TYPES.find(type => type.types.indexOf(insightType) !== -1);
  return iconType;
};

export const getIconTypeForDashboardMapboxVehicleInsight = (vehicleInsight: VehicleInsightDetails) => {
  const insightType = humps.camelize(vehicleInsight.insightTypeName);
  const iconType = VEHICLE_INSIGHT_ICON_TYPES.find(type => type.types.indexOf(insightType) !== -1);

  return iconType;
};
