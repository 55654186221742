import { AnyAction } from 'redux';

import update from 'immutability-helper';
import { ThunkDispatch } from 'redux-thunk';

import { Trigger } from '../interfaces/Trigger';
import doLoadTriggers from '../services/loadTriggers';

// Actions
const START_LOAD = 'routes/triggers/START_LOAD';
const COMPLETE_LOAD = 'routes/triggers/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/triggers/FAIL_LOAD';
const RESET = 'routes/triggers/RESET';

interface State {
  isLoading: boolean;
  triggers: Trigger[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  triggers: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          triggers: action.triggers,
        },
      });

    case FAIL_LOAD:
    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (triggers: Trigger[]) => ({
  type: COMPLETE_LOAD,
  triggers,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadTriggers = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadTriggersPromise = doLoadTriggers(routeId);
  loadTriggersPromise.then(triggers => dispatch(completeLoad(triggers))).catch(() => dispatch(failLoad()));
  return loadTriggersPromise;
};

export const resetTriggers = () => ({
  type: RESET,
});
