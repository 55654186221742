import React, { PureComponent } from 'react';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from '../../../store';
import { Container, ButtonSet, Button } from '../../../core/components/styled';
import { Dropdown, Input, TypedField } from '../../../core/components';
import { isRequired } from '../../../utils/services/validator';
import { NO, OTHER, REASONS } from '../../constants';
import opportunityFormInitialValueSelector from '../../services/opportunityFormInitialValueSelector';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector('declineOpportunity');

interface ComponentProps {
  caseId: number;
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  respondedNo?: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  response?: any;
  respondedNo: {
    reason?: string;
  };
}

class DeclineOpportunityForm extends PureComponent<Props> {
  readonly state: State = {
    response: undefined,
    respondedNo: { reason: undefined },
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: any): State {
    if (nextProps.respondedNo && nextProps.respondedNo.reason) {
      return {
        response: NO,
        respondedNo: { reason: nextProps.respondedNo.reason },
      };
    }

    return prevState;
  }

  onChangeReason = (event: any, value: string) => {
    this.setState({ respondedNo: { reason: value } });
  };

  render() {
    const { caseId, handleSubmit, onCancel, pristine } = this.props;
    const { respondedNo } = this.state;
    const reasonsSelectOption = map(REASONS, reasonOption => ({
      label: reasonOption.name,
      value: reasonOption.id,
    }));
    const isNoteFieldrequired = respondedNo.reason === OTHER ? [isRequired] : undefined;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <TypedField
            name={`opportunities.settings${caseId}.respondedNo.reason`}
            component={Dropdown}
            props={{
              options: reasonsSelectOption,
              label: translate('opportunity.selectReasonType'),
              margin: 'medium small small',
            }}
            validate={[isRequired]}
            onChange={this.onChangeReason}
          />
        </Container>
        <Container>
          <TypedField
            name={`opportunities.settings${caseId}.respondedNo.notes`}
            component={Input}
            validate={isNoteFieldrequired}
            props={{
              margin: 'medium small xLarge',
              label: translate('opportunity.opportunities.reason'),
            }}
          />
        </Container>
        <ButtonSet margin="medium no no">
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: opportunityFormInitialValueSelector(state.opportunity.opportunity.opportunities),
  respondedNo: formSelector(state, `opportunities.settings${ownProps.caseId}.respondedNo`),
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'declineOpportunity',
  })(DeclineOpportunityForm),
);
