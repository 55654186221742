import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { TimeManagementData } from '../interfaces/TimeManagement';
import { loadTimeManagementData as doLoadTimeManagementData } from '../services/timeManagement';

const START_LOAD = 'routes/timeManagement/START_LOAD';
const COMPLETE_LOAD = 'routes/timeManagement/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/timeManagement/FAIL_LOAD';
const RESET = 'routes/timeManagement/RESET';

interface State {
  timeManagementData?: TimeManagementData;
  isLoading: boolean;
}

const initialState: State = {
  timeManagementData: undefined,
  isLoading: false,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          timeManagementData: action.timeManagementData,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          timeManagementData: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (timeManagementData: TimeManagementData) => ({
  type: COMPLETE_LOAD,
  timeManagementData,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadTimeManagementData = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadTimeManagementDataPromise = doLoadTimeManagementData(vendorId, routeId);
  loadTimeManagementDataPromise
    .then((timeManagementData: TimeManagementData) => dispatch(completeLoad(timeManagementData)))
    .catch(() => dispatch(failLoad()));
  return loadTimeManagementDataPromise;
};

export const resetTimeManagementData = () => ({
  type: RESET,
});
