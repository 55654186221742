import { useDispatch } from 'react-redux';
import humps from 'humps';

import { Button, TableCell, TableRow, Text } from 'src/core/components/styled';
import { CustomerImportFile } from 'src/customers/interfaces/CustomerImport';
import { dateAndTime } from 'src/utils/services/formatter';
import { downloadAlertsImportFile } from 'src/fleet/ducks/locationAndCityAlertsImports';
import { getStatusColor } from 'src/customers/components/modals/customerImportModal/CustomerImportModalTableRow';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import translate from 'src/core/services/translate';

interface Props extends CustomerImportFile {
  loading: boolean;
  status: string;
  tableCellsWidths: string[];
}

export const LocationAndCityAlertsImportModalTableRow = ({
  date,
  errorFileDownloadUrl,
  errorFileName,
  fileDownloadUrl,
  fileName,
  id,
  invalidRecordsCount,
  loading,
  status,
  tableCellsWidths,
  totalRecordsCount,
  uploadedRecordsCount,
  validRecordsCount,
}: Props) => {
  const dispatch = useDispatch();

  const statusColor = getStatusColor(status);

  const showLoading = status !== 'ValidationFailed' && status !== 'Uploaded' && loading;

  const handleDownloadFile = (fileUrl: string, fileName: string, date?: string) => {
    downloadAlertsImportFile(fileUrl, fileName, date)(dispatch);
  };

  return (
    <TableRow key={id} height={TABLE_ROW_HEIGHT_SMALL}>
      <TableCell width={tableCellsWidths[0]}>
        {fileDownloadUrl && (
          <Button
            fullWidth
            text
            noCapitalize
            color="primary"
            onClick={() => handleDownloadFile(fileDownloadUrl, fileName)}
          >
            <Text align="left" block singleLine title={fileName}>
              {fileName}
            </Text>
          </Button>
        )}
      </TableCell>
      <TableCell width={tableCellsWidths[1]}>
        {showLoading ? (
          <Loader />
        ) : status ? (
          <Text color={statusColor}>{translate(`customers.customerUpload.statuses.${humps.camelize(status)}`)}</Text>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell width={tableCellsWidths[2]}>
        <Text>{totalRecordsCount}</Text>
      </TableCell>
      <TableCell width={tableCellsWidths[3]}>
        <Text>{uploadedRecordsCount}</Text>
      </TableCell>
      <TableCell width={tableCellsWidths[4]}>
        <Text>{validRecordsCount}</Text>
      </TableCell>
      <TableCell width={tableCellsWidths[5]}>
        <Text>{invalidRecordsCount}</Text>
      </TableCell>
      <TableCell width={tableCellsWidths[6]}>
        {errorFileDownloadUrl ? (
          <Button text color="primary" onClick={() => handleDownloadFile(errorFileDownloadUrl, errorFileName, date)}>
            {translate('common.download')}
          </Button>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell width={tableCellsWidths[7]}>
        <Text>{dateAndTime(date)}</Text>
      </TableCell>
    </TableRow>
  );
};
