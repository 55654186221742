import { FC, useCallback } from 'react';
import { TypedResolver } from 'src/core/components';
import AddToGroupsModal from './AddToGroupsModal';
import { loadGroups } from 'src/routes/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { useDispatch } from 'react-redux';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { AddToGroupsFormValues } from '../forms/AddToGroupsForm';

interface AddToGroupsModalResolverProps {
  closeModal: () => void;
  onAddToGroups: (v: AddToGroupsFormValues) => void;
}

const AddToGroupsModalResolver: FC<AddToGroupsModalResolverProps> = ({ closeModal, onAddToGroups }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const groups = useSelector(state => state.routes.groups.groups);

  const loadDependencies = useCallback(() => {
    const dependencies = [];

    if (!groups.length) {
      dependencies.push(loadGroups(vendorId)(dispatch));

      return Promise.all(dependencies);
    } else return Promise.resolve();
  }, [dispatch, groups.length, vendorId]);

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoadingOverlay}
      successComponent={AddToGroupsModal}
      successProps={{
        closeModal,
        onAddToGroups,
      }}
      onError={closeModal}
    />
  );
};

export default AddToGroupsModalResolver;
