import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormGroup, FormLabel, FormError, Input as FormInput, FormInputWrapper, FormInlineLabel } from './styled';

interface Props extends WrappedFieldProps {
  isLoading?: boolean;
  placeholder?: string;
  label?: string;
  disabled: boolean;
  margin?: string;
  inlineLabel: string;
  isInlineLabelFirst?: boolean;
  leftPadding?: number;
  leftMargin?: number;
  raisedLabel?: boolean;
  shouldInlineLabelBeAlwaysVisible?: boolean;
  type?: string;
}

const InputFieldLabel: React.FC<Props> = ({
  input,
  meta: { asyncValidating, submitFailed, error },
  placeholder,
  label,
  disabled,
  isLoading,
  margin,
  inlineLabel,
  isInlineLabelFirst,
  leftMargin,
  leftPadding,
  raisedLabel,
  shouldInlineLabelBeAlwaysVisible,
  type,
  ...props
}) => {
  const showInlineLabel = shouldInlineLabelBeAlwaysVisible || input.value;
  return (
    <FormGroup
      hasValue={input.value}
      isLoading={isLoading || asyncValidating}
      margin={margin}
      raisedLabel={raisedLabel}
    >
      {!!label && <FormLabel>{label}</FormLabel>}

      <FormInputWrapper>
        {isInlineLabelFirst ? (
          <>
            {showInlineLabel && (
              <FormInlineLabel isInlineLabelFirst={isInlineLabelFirst}>{inlineLabel}</FormInlineLabel>
            )}
            <FormInput
              {...input}
              placeholder={placeholder}
              disabled={disabled}
              leftPadding={leftPadding}
              {...props}
              type={type}
              isInlineLabelFirst={isInlineLabelFirst}
            />
          </>
        ) : (
          <>
            <FormInput {...input} placeholder={placeholder} disabled={disabled} {...props} type={type} />
            {showInlineLabel && <FormInlineLabel leftMargin={leftMargin}>{inlineLabel}</FormInlineLabel>}
          </>
        )}
      </FormInputWrapper>

      {submitFailed && error && <FormError>{error}</FormError>}
    </FormGroup>
  );
};

export default InputFieldLabel;
