import moment from 'moment';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { size } from 'lodash-es';

import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageTitle,
  PageActions,
} from 'src/common/components/styled';
import { Collapsible, Table } from 'src/core/components';
import {
  Button,
  Text,
  Panel,
  PanelSection,
  Tabs,
  Tab as TabUntyped,
  PanelSectionGroup,
  PanelSectionHeader,
  PanelSectionTitle,
  ToggleIcon,
} from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { exportDriverInspectionLog } from 'src/fleet/services/driverInspection';
import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import InspectionPreviewImageModal from '../modal/InspectionPreviewImageModal';
import {
  InspectionFailedIcon,
  InspectionPassedIcon,
  InspectionsListContainer,
  ToggleIconContainer,
} from '../styled/VehicleInspections';
import InspectionDetailsTableRow from '../../../common/components/InspectionDetailsTableRow';
import { LIGHT_CONDITION_TYPES, WEATHER_CONDITION_TYPES } from 'src/common/constants/conditionTypes';
import { DriverInspectionLog } from 'src/fleet/interfaces/DriverInspectionLog';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';

const Tab = TabUntyped as any;

const TABS = {
  summary: 1,
  details: 2,
};
const DriverInspectionPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isImagePreviewModalOpen, setIsImagePreviewModalOpen] = useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const vendorId = useSelector(currentVendorId);

  const { driverInspectionDetails, driverInspectionLogs, isLoading } = useSelector(
    (state: AppState) => state.fleet.driverInspection,
  );

  if (!driverInspectionDetails) return null;
  const {
    date,
    driverName,
    driverSafetyInspectionItems,
    duration,
    endTime,
    id,
    lightConditionType,
    signatureImage,
    startTime,
    vehicleRegPlate,
    vehicleTypeId,
    weatherConditionType,
  } = driverInspectionDetails;

  const supervisorName = driverInspectionLogs
    .filter((el: DriverInspectionLog) => el.id === id)
    .map((el: DriverInspectionLog) => el.resourceName)[0];

  const openImagePreviewModal = (imageUrl: string) => {
    setIsImagePreviewModalOpen(true);
    setSelectedImageUrl(imageUrl);
  };
  const closeImagePreviewModal = () => {
    setIsImagePreviewModalOpen(false);
    setSelectedImageUrl('');
  };

  const exportReport = () => {
    exportDriverInspectionLog(vendorId, id);
  };

  const formatedStartTime = startTime ? moment(startTime, 'hh:mm:ss').format('LT') : '-';
  const formatedEndTime = endTime ? moment(endTime, 'hh:mm:ss').format('LT') : '-';
  const formatedDate = moment(date).format('MM/DD/YYYY');
  const inspectionDuration = duration ? duration.replace('.', 'd ').replace(':', 'h ').replace(':', 'm ') + 's' : '-';

  const driverInspectionDetailsTableCells = [
    { name: 'item', label: translate('vehicles.vehicleInspections.item'), width: '35%' },
    {
      name: 'passed',
      label: (
        <span>
          <InspectionPassedIcon />
          <Text size="large">{'/'}</Text>
          <InspectionFailedIcon />
        </span>
      ),
      width: '10%',
    },
    { name: 'comments', label: translate('common.comments'), width: '55%' },
  ];
  let tripInspectionCategories: any = {};
  let driverInspectionCategories: any = [];
  driverSafetyInspectionItems.forEach((inspection: any) => {
    const technicalName = inspection.driverSafetyInspectionType?.technicalName;

    const inspectionLine = {
      item: inspection.driverSafetyInspectionType.name,
      passed: inspection.passedInspection,
      note: inspection.note,
    };
    driverInspectionCategories.push({ ...inspectionLine });
    if (tripInspectionCategories[technicalName]) {
      tripInspectionCategories[technicalName].items.push({ ...inspectionLine });
    } else {
      tripInspectionCategories[technicalName] = {
        items: [inspectionLine],
        name: inspection.driverSafetyInspectionType.name,
      };
    }
  });

  const virtualizedProps = {
    height: Math.min(driverInspectionCategories.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction
              onClick={() => {
                history.goBack();
              }}
            >
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate('tooltips.driverInspection') + ' ' + formatedDate}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <Button onClick={exportReport} color="primary" margin="no">
            {translate('common.export')}
          </Button>
        </PageActions>
      </PageHeader>
      <Panel>
        <PanelSection centered withBorder padding="sMedium">
          <Tabs>
            <Tab isSelected={activeTab === TABS.summary} onClick={() => setActiveTab(TABS.summary)}>
              {translate('vehicles.vehicleInspections.summary')}
            </Tab>
            <Tab isSelected={activeTab === TABS.details} onClick={() => setActiveTab(TABS.details)}>
              {translate('vehicles.vehicleInspections.details')}
            </Tab>
          </Tabs>
        </PanelSection>
        <PanelSectionGroup isLoading={isLoading}>
          {activeTab === TABS.summary && (
            <PanelSection padding="medium">
              <InspectionsListContainer>
                <Text block weight="medium" align="left">
                  {`${translate('common.vehicle')}:`}
                </Text>
                <Text>{vehicleRegPlate}</Text>

                <Text block weight="medium" align="left">
                  {`${translate('common.vehicleType')}:`}
                </Text>
                <Text>{vehicleTypeId && VEHICLE_TYPE_IDS[vehicleTypeId].name}</Text>

                <Text block weight="medium" align="left">
                  {`${translate('routes.routeDate')}:`}
                </Text>
                <Text>{formatedDate}</Text>

                <Text block weight="medium" align="left">
                  {`${translate('common.startTime')}:`}
                </Text>
                <Text>{formatedStartTime}</Text>

                <Text block weight="medium" align="left">
                  {`${translate('common.endTime')}:`}
                </Text>
                <Text>{formatedEndTime}</Text>

                <Text block weight="medium" align="left">
                  {`${translate('common.duration')}:`}
                </Text>
                <Text>{inspectionDuration}</Text>

                <Text block weight="medium" align="left">
                  {`${translate('drivers.driverName')}:`}
                </Text>
                <Text>{driverName}</Text>
                <Text block weight="medium" align="left">
                  {`${translate('drivers.supervisorName')}:`}
                </Text>
                <Text>{supervisorName}</Text>

                <Text block weight="medium" align="left">
                  {`${translate('vehicles.vehicleInspections.signature')}:`}
                </Text>

                {!!signatureImage && <img src={signatureImage.url} alt={`${signatureImage.imageId}`} width="200px" />}
              </InspectionsListContainer>
            </PanelSection>
          )}
          {activeTab === TABS.details && (
            <Fragment>
              <Fragment key={0}>
                <PanelSectionHeader
                  margin="small"
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <PanelSectionTitle margin="small no xxSmall">
                    {translate('tooltips.driverInspection')}
                  </PanelSectionTitle>{' '}
                  <PanelSectionGroup positionLeft="45%" position="absolute">
                    <PanelSectionGroup padding="no no xxSmall no">
                      <Text margin="no small small no" weight="medium" align="left">
                        {`${translate('common.weatherConditions')}:`}
                      </Text>
                      {weatherConditionType && <Text>{WEATHER_CONDITION_TYPES[weatherConditionType.id]?.name}</Text>}
                    </PanelSectionGroup>
                    <PanelSectionGroup>
                      <Text margin="no small small no" weight="medium" align="left">
                        {`${translate('common.lightConditions')}:`}
                      </Text>
                      {lightConditionType && <Text>{LIGHT_CONDITION_TYPES[lightConditionType.id]?.name}</Text>}
                    </PanelSectionGroup>
                  </PanelSectionGroup>
                  {!!size(driverInspectionCategories) && (
                    <ToggleIconContainer>
                      <ToggleIcon size={16} isOpen={!isCollapsed} icon="arrowDownFill" />
                    </ToggleIconContainer>
                  )}
                </PanelSectionHeader>

                <Collapsible isOpen={!isCollapsed}>
                  <PanelSection>
                    {!!size(driverInspectionCategories) ? (
                      <Table
                        cells={driverInspectionDetailsTableCells}
                        rows={driverInspectionCategories}
                        rowComponent={InspectionDetailsTableRow}
                        withTopBorder
                        scrollMarker
                        rowProps={{
                          openImagePreviewModal: openImagePreviewModal,
                          totalRows: size(driverInspectionCategories),
                        }}
                        virtualized
                        virtualizedProps={virtualizedProps}
                      />
                    ) : (
                      <Text padding="sMedium xSmall">{translate('tooltips.driverInspections.noInspections')}</Text>
                    )}
                  </PanelSection>
                </Collapsible>
              </Fragment>
            </Fragment>
          )}
        </PanelSectionGroup>
      </Panel>
      {isImagePreviewModalOpen && (
        <InspectionPreviewImageModal imageUrl={selectedImageUrl} closeModal={closeImagePreviewModal} />
      )}
    </PageContent>
  );
};

export default withRouter(DriverInspectionPage);
