import { Collapsible } from 'src/core/components';
import {
  DashboardFilterCategoryBody,
  DashboardFilterCategory as DashboardFilterCategoryContainer,
  DashboardFilterCategoryHeader,
  DashboardFilterCategoryHeaderIcon,
  DashboardFilterNumberOfSelectedFilters,
} from '../../styled/DashboardFilterMapbox';

interface Props {
  title: string;
  selectedLabel?: string;
  isOpen: boolean;
  category: string;
  children: React.ReactNode;
  id?: string;
  toggleCategory(category: string): void;
}

const DashboardFilterCategoryMapbox = ({
  title,
  isOpen,
  children,
  id,
  category,
  toggleCategory,
  selectedLabel,
}: Props) => {
  const toggleCategoryHandler = () => {
    toggleCategory(category);
  };

  return (
    <DashboardFilterCategoryContainer isOpen={isOpen} id={id}>
      <DashboardFilterCategoryHeader onClick={toggleCategoryHandler}>
        <span>{title}</span>
        {selectedLabel && (
          <DashboardFilterNumberOfSelectedFilters>{selectedLabel}</DashboardFilterNumberOfSelectedFilters>
        )}
        <DashboardFilterCategoryHeaderIcon />
      </DashboardFilterCategoryHeader>
      <DashboardFilterCategoryBody>
        <Collapsible isOpen={isOpen}>{children}</Collapsible>
      </DashboardFilterCategoryBody>
    </DashboardFilterCategoryContainer>
  );
};

export default DashboardFilterCategoryMapbox;
