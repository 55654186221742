import React from 'react';

import { dateAndTime } from 'src/utils/services/formatter';
import { TableRow, TableCell, Text } from 'src/core/components/styled';

interface Props {
  userNote: string;
  createdOn: string;
  createdBy: string;
}

export const SourcingNotesTableRow: React.FC<Props> = ({ userNote, createdOn, createdBy }) => {
  return (
    <TableRow>
      <TableCell width={'50%'}>
        <Text>{userNote}</Text>
      </TableCell>
      <TableCell width={'25%'}>
        <Text>{createdBy}</Text>
      </TableCell>
      <TableCell width={'25%'}>
        <Text>{dateAndTime(createdOn)}</Text>
      </TableCell>
    </TableRow>
  );
};
