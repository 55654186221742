import { push } from 'connected-react-router';
import Cookie from 'js-cookie';
import { filter, find, map, orderBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { Button, PanelSection, PanelSectionGroup, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE } from 'src/routes/constants';
import { deleteRouteTemplate, loadRouteSequenceStatus } from 'src/routes/ducks';
import {
  assignDriverToRouteTemplate,
  assignVehicleToRouteTemplate,
  loadRoutePlannerRouteTemplateDrivers,
  loadRoutePlannerRouteTemplates,
  loadRoutePlannerRouteTemplateVehicles,
  removeDriverFromRouteTemplate,
  removeVehicleFromRouteTemplate,
} from 'src/routes/ducks/routePlanner';
import { RoutePlannerTemplatesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { RoutePlannerRouteTemplate } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplate';
import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';
import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import { getRoutePlannerFilters } from 'src/routes/services/routePlannerFilters';
import { getRoutePlannerOptions } from 'src/routes/services/routePlannerVehicleTypesFormInitialValuesSelector';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DailyAndReoccurringFiltersSectionResolver from '../../modals/routePlanner/DailyAndReoccurringFilterByModalResolver';
import { NoDataMessageContainer } from '../../styled';
import AssigningDriversAndVehiclesSection from './RoutePlannerDailyAndReoccurringPageSections/AssigningDriversAndVehiclesSection';
import DailyAndReoccurringStatisticsSection from './RoutePlannerDailyAndReoccurringPageSections/DailyAndReoccurringStatisticsSection';
import RoutePlannerRouteTemplatesListingSection from './RoutePlannerDailyAndReoccurringPageSections/RoutePlannerRouteTemplatesListingSection';

const RoutePlannerReoccurringPage: React.FC = () => {
  const { search, pathname } = useLocation();
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const [changedDrivers, setChangedDrivers] = useState<RoutePlannerRouteTemplateDriver[]>([]);
  const [changedVehicles, setChangedVehicles] = useState<RoutePlannerRouteTemplateVehicle[]>([]);
  const [changedTemplates, setChangedTemplates] = useState<RoutePlannerRouteTemplate[]>([]);
  const [deletedTemplates, setDeletedTemplates] = useState<number[]>([]);
  const [routeTemplateIdsUpdating, setRouteTemplateIdUpdating] = useState<number[]>([]);

  // used to keep in sync filtered routes with the ones in the table
  const [searchTerm, setSearchTerm] = useState('');
  const [noDriver, setNoDriver] = useState(false);
  const [noVehicle, setNoVehicle] = useState(false);

  const { drivers, isLoading: isLoadingDrivers } = useSelector(
    state => state.routes.routePlanner.routePlannerRouteTemplateDrivers,
  );
  const { vehicles, isLoading: isLoadingVehicles } = useSelector(
    state => state.routes.routePlanner.routePlannerRouteTemplateVehicles,
  );
  const { routeTemplates, isLoading: isLoadingTemplates } = useSelector(
    state => state.routes.routePlanner.routePlannerRouteTemplates,
  );

  const isViewOnlyOrSupport = checkIfSupport() || checkIfViewOnly();

  const vehicleTypes = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const filterPreferences = useSelector(state => state.common.filters.filters);
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const vehicleTypeAndDayOfServiceFilters = useMemo(() => {
    return getRoutePlannerOptions(vehicleTypes, filterPreferences, search);
  }, [filterPreferences, search, vehicleTypes]);

  const { vehicleTypeId, dayOfServiceId } = vehicleTypeAndDayOfServiceFilters;

  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeper = vehicleTypeId === STREET_SWEEPER_ID;

  useEffect(() => {
    if (vehicleTypeId) {
      const routePlannerFilters = getRoutePlannerFilters(search);

      const { supervisorsIds, serviceZonesIds, facilityTypeIds, routeStatusesIds, materialTypeIds, groupIds } =
        routePlannerFilters;
      const filtersToPass = {
        vendorId,
        materialTypeIds: materialTypeIds?.length ? materialTypeIds?.toString() : '',
        scheduledDayIds: dayOfServiceId && !isStreetSweeper && !isSnowPlowRoute ? dayOfServiceId?.toString() : '',
        vehicleTypeIds: vehicleTypeId ? vehicleTypeId?.toString() : '',
        supervisorIds: supervisorsIds?.length && supervisorEnabled ? supervisorsIds?.toString() : '',
        serviceZoneIds: serviceZonesIds?.length ? serviceZonesIds?.toString() : '',
        facilityIds: facilityTypeIds?.length ? facilityTypeIds?.toString() : '',
        isActive: routeStatusesIds?.length
          ? routeStatusesIds.length === 1
            ? !!routeStatusesIds[0]
            : undefined
          : undefined,
        groupIds: groupIds?.length ? groupIds?.toString() : '',
      } as RoutePlannerTemplatesPayload;

      loadRoutePlannerRouteTemplateDrivers(filtersToPass)(dispatch);
      loadRoutePlannerRouteTemplateVehicles(filtersToPass)(dispatch);

      loadRoutePlannerRouteTemplates(filtersToPass)(dispatch).then((templates: RoutePlannerRouteTemplate[]) => {
        if (templates?.length) {
          const templatesIds = templates.map(template => template.routeTemplateId);
          if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
            if (templatesIds.length)
              loadRouteSequenceStatus(templatesIds, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE)(dispatch);
          }
        }
      });
    }
  }, [
    dayOfServiceId,
    dispatch,
    filterPreferences,
    isSnowPlowRoute,
    isStreetSweeper,
    search,
    supervisorEnabled,
    vehicleTypeAndDayOfServiceFilters,
    vehicleTypeId,
    vehicleTypes,
    vendorId,
  ]);

  // refresh the route sequence statuses
  useEffect(() => {
    const interval = setInterval(() => {
      if (routeTemplates.length) {
        const templatesIds = routeTemplates.map(template => template.routeTemplateId);
        if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
          if (templatesIds.length) loadRouteSequenceStatus(templatesIds, SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE)(dispatch);
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, routeTemplates, vendorId]);

  const addTemplateUpdating = (id: number) => {
    if (!routeTemplateIdsUpdating.includes(id)) {
      setRouteTemplateIdUpdating([...routeTemplateIdsUpdating, id]);
    }
  };
  const removeTemplateUpdating = (id: number) => {
    setRouteTemplateIdUpdating(filter(routeTemplateIdsUpdating, templateId => templateId !== id));
  };

  // updating the drivers, vehicles and routes that have been changed
  const combinedDrivers: RoutePlannerRouteTemplateDriver[] = useMemo(() => {
    return orderBy(
      map(drivers, driver => {
        const changedDriver = find(changedDrivers, changedDriver => changedDriver.driverId === driver.driverId);
        return changedDriver || driver;
      }),
      ['driverName'],
      ['asc'],
    );
  }, [drivers, changedDrivers]);

  const combinedVehicles: RoutePlannerRouteTemplateVehicle[] = useMemo(() => {
    return orderBy(
      map(vehicles, vehicle => {
        const changedVehicle = find(changedVehicles, changedVehicle => changedVehicle.vehicleId === vehicle.vehicleId);
        return changedVehicle || vehicle;
      }),
      ['vehicleName'],
      ['asc'],
    );
  }, [vehicles, changedVehicles]);

  const combinedTemplates: RoutePlannerRouteTemplate[] = useMemo(() => {
    return orderBy(
      map(
        filter(routeTemplates, r => !deletedTemplates.includes(r.routeTemplateId)),
        template => {
          const changedTemplate = find(
            changedTemplates,
            changedTemplate => changedTemplate.routeTemplateId === template.routeTemplateId,
          );
          return changedTemplate
            ? { ...changedTemplate, isUpdating: routeTemplateIdsUpdating.includes(template.routeTemplateId) }
            : template;
        },
      ),
      ['routeTemplateName'],
      ['asc'],
    );
  }, [changedTemplates, deletedTemplates, routeTemplateIdsUpdating, routeTemplates]);

  const filteredTemplatesForTableSection: RoutePlannerRouteTemplate[] = useMemo(() => {
    return filter(combinedTemplates, template => {
      return (
        template.routeTemplateName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (noDriver ? template.driverId === null : true) &&
        (noVehicle ? template.vehicleId === null : true)
      );
    });
  }, [combinedTemplates, searchTerm, noDriver, noVehicle]);

  const assignDriver = (driverId: number, templateId: number, isUnassignedFrom?: number) => {
    const changedDriver = find(combinedDrivers, driver => driver.driverId === driverId);
    const changedRoute = find(combinedTemplates, template => template.routeTemplateId === templateId);
    const unassignedFromRoute = find(combinedTemplates, template => template.routeTemplateId === isUnassignedFrom);

    if (changedDriver && changedRoute) {
      addTemplateUpdating(templateId);
      assignDriverToRouteTemplate(
        vendorId,
        changedRoute.routeTemplateId,
        changedDriver.driverId,
      )(dispatch)
        .then(() => {
          // updating the changes locally
          const newDriver = {
            ...changedDriver,
            routesTemplates: [
              ...filter(
                changedDriver.routesTemplates,
                template =>
                  template.routeTemplateId !== templateId &&
                  (isUnassignedFrom ? template.routeTemplateId !== isUnassignedFrom : true),
              ),
              { routeTemplateId: templateId, routeTemplateName: changedRoute.routeTemplateName },
            ],
          };
          setChangedDrivers(prevDrivers => [...filter(prevDrivers, driver => driver.driverId !== driverId), newDriver]);
          const updatedTemplate = {
            ...changedRoute,
            driverId,
            driverName: changedDriver.driverName,
          };
          if (isUnassignedFrom && unassignedFromRoute) {
            removeDriverFromRouteTemplate(
              vendorId,
              unassignedFromRoute.routeTemplateId,
              driverId,
            )(dispatch)
              .then(() => {
                const updatedUnassignedRoute = {
                  ...unassignedFromRoute,
                  driverId: undefined,
                  driverName: undefined,
                };
                setChangedTemplates(prevTemplates => [
                  ...filter(
                    prevTemplates,
                    template =>
                      template.routeTemplateId !== templateId && template.routeTemplateId !== isUnassignedFrom,
                  ),
                  updatedTemplate,
                  updatedUnassignedRoute,
                ]);
              })
              .catch(error => {
                error.exceptionMessage && createErrorNotification(error.exceptionMessage);
              });
          } else
            setChangedTemplates(prevTemplates => [
              ...filter(prevTemplates, template => template.routeTemplateId !== templateId),
              updatedTemplate,
            ]);
          removeTemplateUpdating(templateId);
          createSuccessNotification(translate('routes.planner.driverAssignedSuccessfully'));
        })
        .catch(error => {
          error.exceptionMessage && createErrorNotification(error.exceptionMessage);
          removeTemplateUpdating(templateId);
        });
    }
  };

  const assignVehicle = (vehicleId: number, templateId: number, isUnassignedFrom?: number) => {
    const changedVehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === vehicleId);
    const changedRoute = find(combinedTemplates, template => template.routeTemplateId === templateId);
    const unassignedFromRoute = find(combinedTemplates, template => template.routeTemplateId === isUnassignedFrom);

    if (changedVehicle && changedRoute) {
      addTemplateUpdating(templateId);
      assignVehicleToRouteTemplate(
        vendorId,
        changedRoute.routeTemplateId,
        changedVehicle.vehicleId,
      )(dispatch)
        .then(() => {
          // updating the changes locally
          const newVehicle = {
            ...changedVehicle,
            routesTemplates: [
              ...filter(changedVehicle.routesTemplates, v =>
                isUnassignedFrom ? v.routeTemplateId !== isUnassignedFrom : true,
              ),
              { routeTemplateId: changedRoute.routeTemplateId, routeTemplateName: changedRoute.routeTemplateName },
            ],
          };
          setChangedVehicles(prevVehicles => [
            ...filter(prevVehicles, vehicle => vehicle.vehicleId !== vehicleId),
            newVehicle,
          ]);
          const updatedTemplate = {
            ...changedRoute,
            vehicleId,
            vehicleName: changedVehicle.vehicleName,
          };

          if (isUnassignedFrom && unassignedFromRoute) {
            // when dragged from one route to another make sure to unassign from the previous route
            removeVehicleFromRouteTemplate(
              vendorId,
              unassignedFromRoute.routeTemplateId,
              vehicleId,
            )(dispatch)
              .then(() => {
                const updatedUnassignedRoute = {
                  ...unassignedFromRoute,
                  vehicleId: undefined,
                  vehicleName: undefined,
                };
                setChangedTemplates(prevTemplates => [
                  ...filter(
                    prevTemplates,
                    template =>
                      template.routeTemplateId !== templateId && template.routeTemplateId !== isUnassignedFrom,
                  ),
                  updatedTemplate,
                  updatedUnassignedRoute,
                ]);
              })
              .catch(error => {
                error.exceptionMessage && createErrorNotification(error.exceptionMessage);
              });
          } else
            setChangedTemplates(prevTemplates => [
              ...filter(prevTemplates, template => template.routeTemplateId !== templateId),
              updatedTemplate,
            ]);
          removeTemplateUpdating(templateId);
          createSuccessNotification(translate('routes.planner.vehicleAssignedSuccessfully'));
        })
        .catch(error => {
          error.exceptionMessage && createErrorNotification(error.exceptionMessage);
          removeTemplateUpdating(templateId);
        });
    }
  };

  const unassignDriver = (driverId: number, templateId: number, isSoftRemove?: boolean) => {
    const changedDriver = find(combinedDrivers, driver => driver.driverId === driverId);
    const changedRoute = find(combinedTemplates, template => template.routeTemplateId === templateId);

    if (changedDriver && changedRoute) {
      if (isSoftRemove) {
        // when a driver is replaced by another driver
        const newDriver = {
          ...changedDriver,
          routesTemplates: filter(changedDriver.routesTemplates, template => template.routeTemplateId !== templateId),
        };
        setChangedDrivers(prevDrivers => [...filter(prevDrivers, driver => driver.driverId !== driverId), newDriver]);
        const updatedTemplate = {
          ...changedRoute,
          driverId: undefined,
          driverName: undefined,
        };
        setChangedTemplates(prevTemplates => [
          ...filter(prevTemplates, template => template.routeTemplateId !== templateId),
          updatedTemplate,
        ]);
      } else {
        addTemplateUpdating(templateId);
        removeDriverFromRouteTemplate(
          vendorId,
          changedRoute.routeTemplateId,
          changedDriver.driverId,
        )(dispatch)
          .then(() => {
            // updating the changes locally
            const newDriver = {
              ...changedDriver,
              routesTemplates: filter(
                changedDriver.routesTemplates,
                template => template.routeTemplateId !== templateId,
              ),
            };
            setChangedDrivers(prevDrivers => [
              ...filter(prevDrivers, driver => driver.driverId !== driverId),
              newDriver,
            ]);
            const updatedTemplate = {
              ...changedRoute,
              driverId: undefined,
              driverName: undefined,
            };
            setChangedTemplates(prevTemplates => [
              ...filter(prevTemplates, template => template.routeTemplateId !== templateId),
              updatedTemplate,
            ]);
            removeTemplateUpdating(templateId);
            createSuccessNotification(translate('routes.planner.driverRemovedSuccessfully'));
          })
          .catch(error => {
            error.exceptionMessage && createErrorNotification(error.exceptionMessage);
            removeTemplateUpdating(templateId);
          });
      }
    }
  };

  const unassignVehicle = (vehicleId: number, templateId: number, isSoftRemove?: boolean) => {
    const changedVehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === vehicleId);
    const changedRoute = find(combinedTemplates, template => template.routeTemplateId === templateId);

    if (changedVehicle && changedRoute) {
      if (isSoftRemove) {
        // when a vehicle is replaced by another vehicle
        const newVehicle = {
          ...changedVehicle,
          routesTemplates: filter(changedVehicle.routesTemplates, template => template.routeTemplateId !== templateId),
        };
        setChangedVehicles(prevVehicles => [
          ...filter(prevVehicles, vehicle => vehicle.vehicleId !== vehicleId),
          newVehicle,
        ]);
        const updatedTemplate = {
          ...changedRoute,
          vehicleId: undefined,
          vehicleName: undefined,
        };
        setChangedTemplates(prevTemplates => [
          ...filter(prevTemplates, template => template.routeTemplateId !== templateId),
          updatedTemplate,
        ]);
      } else {
        addTemplateUpdating(templateId);
        removeVehicleFromRouteTemplate(
          vendorId,
          changedRoute.routeTemplateId,
          changedVehicle.vehicleId,
        )(dispatch)
          .then(() => {
            // updating the changes locally
            const newVehicle = {
              ...changedVehicle,
              routesTemplates: filter(
                changedVehicle.routesTemplates,
                template => template.routeTemplateId !== templateId,
              ),
            };
            setChangedVehicles(prevVehicles => [
              ...filter(prevVehicles, vehicle => vehicle.vehicleId !== vehicleId),
              newVehicle,
            ]);
            const updatedTemplate = {
              ...changedRoute,
              vehicleId: undefined,
              vehicleName: undefined,
            };
            setChangedTemplates(prevTemplates => [
              ...filter(prevTemplates, template => template.routeTemplateId !== templateId),
              updatedTemplate,
            ]);
            removeTemplateUpdating(templateId);
            createSuccessNotification(translate('routes.planner.vehicleRemovedSuccessfully'));
          })
          .catch(error => {
            error.exceptionMessage && createErrorNotification(error.exceptionMessage);
            removeTemplateUpdating(templateId);
          });
      }
    }
  };

  const deleteTemplate = async (templateId: number) => {
    if (!(await confirm(translate('routes.planner.deleteReoccurringConfirmationSingle')))) {
      return;
    }
    const changedRoute = find(combinedTemplates, template => template.routeTemplateId === templateId);
    if (changedRoute) {
      if (changedRoute.driverId) {
        const changedDriver = find(combinedDrivers, driver => driver.driverId === changedRoute.driverId);
        if (changedDriver) {
          const newDriver = {
            ...changedDriver,
            routesTemplates: filter(changedDriver.routesTemplates, template => template.routeTemplateId !== templateId),
          };
          setChangedDrivers(prevDrivers => [
            ...filter(prevDrivers, driver => driver.driverId !== changedRoute.driverId),
            newDriver,
          ]);
        }
      }
      if (changedRoute.vehicleId) {
        const changedVehicle = find(combinedVehicles, vehicle => vehicle.vehicleId === changedRoute.vehicleId);
        if (changedVehicle) {
          const newVehicle = {
            ...changedVehicle,
            routesTemplates: filter(
              changedVehicle.routesTemplates,
              template => template.routeTemplateId !== templateId,
            ),
          };
          setChangedVehicles(prevVehicles => [
            ...filter(prevVehicles, vehicle => vehicle.vehicleId !== changedRoute.vehicleId),
            newVehicle,
          ]);
        }
      }
      deleteRouteTemplate(templateId)(dispatch)
        .then(() => {
          createSuccessNotification(translate('routes.planner.deleteReoccurringSuccessSingle'));
          setDeletedTemplates(prevTemplates => [...prevTemplates, templateId]);
        })
        .catch(() => {
          createErrorNotification(translate('routes.planner.deleteReoccurringError'));
        });
    }
  };

  const goBackToPreviousPage = () => {
    dispatch(push('/routes/route-planner', { prevPath: pathname }));
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={goBackToPreviousPage} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle>{translate('routes.planner.reoccurringRoutePlanner')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        {!isViewOnlyOrSupport && (
          <PageActions align="center">
            <Button
              margin="xSmall sMedium no no"
              line
              color="primary"
              id="route-planner-add-template-button"
              onClick={() =>
                dispatch(
                  push(isSnowPlowRoute ? '/routes/route-templates/snow-plow/create' : '/routes/route-templates/create'),
                )
              }
            >
              + {translate('routes.addRoute')}
            </Button>
          </PageActions>
        )}
      </PageHeader>
      <PanelSectionGroup>
        <DailyAndReoccurringFiltersSectionResolver />
      </PanelSectionGroup>

      {vehicleTypeId ? (
        <>
          <PanelSection display="block" isLoading={isLoadingTemplates || isLoadingDrivers || isLoadingVehicles}>
            <DailyAndReoccurringStatisticsSection
              drivers={combinedDrivers}
              vehicles={combinedVehicles}
              routes={combinedTemplates}
            />
          </PanelSection>

          <AssigningDriversAndVehiclesSection
            drivers={combinedDrivers}
            isDaily={false}
            isLoadingDrivers={isLoadingDrivers}
            isLoadingTemplates={isLoadingTemplates}
            isLoadingVehicles={isLoadingVehicles}
            onAssignDriver={assignDriver}
            onAssignVehicle={assignVehicle}
            onUnassignDriver={unassignDriver}
            onUnassignVehicle={unassignVehicle}
            routes={[]}
            routeTemplates={combinedTemplates}
            setNoDriver={setNoDriver}
            setNoVehicle={setNoVehicle}
            setSearchTerm={setSearchTerm}
            vehicles={combinedVehicles}
          />
          <RoutePlannerRouteTemplatesListingSection
            rows={filteredTemplatesForTableSection}
            isLoading={isLoadingTemplates}
            deleteTemplate={deleteTemplate}
          />
        </>
      ) : (
        <NoDataMessageContainer>
          <Text>{translate('routes.planner.selectVehicleType')}</Text>
        </NoDataMessageContainer>
      )}
    </PageContent>
  );
};

export default RoutePlannerReoccurringPage;
