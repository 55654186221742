import React from 'react';

import { connect } from 'react-redux';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import { deleteWeightTicket, loadRoute, saveWeightTicket } from '../../ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { PanelSection } from '../../../core/components/styled';
import { technicalNameByVehicleTypeIdSelector } from '../../../fleet/ducks';
import { WeightTicketsFormFieldArray } from '.';
import weightTicketsFormInitialValuesSelector from '../../services/weightTicketsFormInitialValuesSelector';

interface ComponentProps {
  change: any;
  deleteWeightTicket: DuckFunction<typeof deleteWeightTicket>;
  isDeleting: boolean;
  isLinkingWeightTicketImage: boolean;
  isSaving: boolean;
  isUploadingWeightTicketImage: boolean;
  loadRoute: DuckFunction<typeof loadRoute>;
  routeId: number;
  routeLocations: any[];
  routeStatusTypeId: number;
  saveWeightTicket: DuckFunction<typeof saveWeightTicket>;
  vehicleTypeTechnicalName: string;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const WeightTicketsForm: React.FC<Props> = ({
  change,
  deleteWeightTicket,
  isDeleting,
  isLinkingWeightTicketImage,
  isSaving,
  isUploadingWeightTicketImage,
  loadRoute,
  routeId,
  routeLocations,
  routeStatusTypeId,
  saveWeightTicket,
  vehicleTypeTechnicalName,
}) => (
  <form>
    <PanelSection
      vertical
      isLoading={isSaving || isDeleting || isUploadingWeightTicketImage || isLinkingWeightTicketImage}
      id={`route-${routeId}-weight-tickets-form`}
    >
      <FieldArray
        name="weightTickets"
        component={WeightTicketsFormFieldArray as any}
        routeId={routeId}
        routeStatusTypeId={routeStatusTypeId}
        routeLocations={routeLocations}
        vehicleTypeTechnicalName={vehicleTypeTechnicalName}
        loadRoute={loadRoute}
        saveWeightTicket={saveWeightTicket}
        deleteWeightTicket={deleteWeightTicket}
        change={change}
      />
    </PanelSection>
  </form>
);

const mapStateToProps = (state: AppState) => ({
  routeId: state.routes.route.route!.id,
  routeStatusTypeId: state.routes.route.route!.routeStatusTypeId,
  vehicleTypeTechnicalName: (technicalNameByVehicleTypeIdSelector as any)(
    state.fleet.vehicleTypesForVendor,
    state.routes.route.route!.vehicleTypeId,
  ),
  vehicleTypeId: state.routes.route.route!.vehicleTypeId,
  routeLocations: state.routes.route.route!.routeLocations || [],
  weightTicketsCount: state.routes.route.route!.weightTicketsCount,
  weightTicketsWeightTotal: state.routes.route.route!.weightTicketsWeightTotal,
  isSaving: state.routes.weightTicket.isSaving,
  isDeleting: state.routes.weightTickets.isDeleting,
  isUploadingWeightTicketImage: state.routes.weightTicket.isUploadingWeightTicketImage,
  isLinkingWeightTicketImage: state.routes.weightTicket.isLinkingWeightTicketImage,
  initialValues: weightTicketsFormInitialValuesSelector(state.routes.weightTickets),
});

const mapDispatchToProps = {
  saveWeightTicket,
  deleteWeightTicket,
  loadRoute,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    form: 'weightTickets',
  })(WeightTicketsForm),
);
