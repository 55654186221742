import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { VEHICLE_ICON_TYPES } from 'src/common/constants/vehicleIcons';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehiclePosition } from 'src/routes/components/mapWithTimeline/Interfaces';
import YRouteMapVehiclePositionsGLPopup from 'src/routes/components/pages/yRoute/yRoutePageSections/yRouteMap/YRouteMapVehiclePositionsGLPopup';
import { ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';
import RouteMapVehiclePositionsGLLayers from './RouteMapVehiclePositionsGLLayers';
import RouteMapVehiclePositionsGLPopup from './RouteMapVehiclePositionsGLPopup';

const mapImages = VEHICLE_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: ROUTE_MAP_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

type Props = {
  map: mapboxgl.Map;
  isYRoute?: boolean;
  currentVehiclePositions?: VehiclePosition[];
};

export default function RouteMapVehiclePositionsGL({ map, currentVehiclePositions, isYRoute }: Props) {
  useMapImages(mapImages, map, mapImagesOptions);

  const { sosAlertVehicleId } = useSelector(state => state.routes.mapControls);

  return (
    <>
      <RouteMapVehiclePositionsGLLayers sosAlertVehicleId={sosAlertVehicleId} />
      {isYRoute ? (
        <YRouteMapVehiclePositionsGLPopup />
      ) : (
        <RouteMapVehiclePositionsGLPopup currentVehiclePositions={currentVehiclePositions} />
      )}
    </>
  );
}
