import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { GeoFenceAlertsSettingsForm } from '../forms';
import { GeoFenceSetting } from '../../interfaces/GeoFenceSettings';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import { saveGeoFenceSettings } from '../../ducks/';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
  vendorId: number;
}

export default function GeoFenceAlertsSettingsModal({ closeModal, vendorId }: Props) {
  const dispatch = useDispatch();
  const { geoFenceSettings, isSaving } = useSelector((state: AppState) => state.vendors.geoFenceSettings);

  const handleSubmit = (formData: any) => {
    const newGeoFenceSettings = formData.map((geoFenceSetting: GeoFenceSetting) => ({ ...geoFenceSetting, vendorId }));

    saveGeoFenceSettings(
      vendorId,
      newGeoFenceSettings,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.geoFenceAlertsSettingsSaved'));
        closeModal();
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.geoFenceAlertsSettingsError')));
  };

  return (
    <Modal size="medium" title={translate('vendors.globalGeoFenceAlerts')} onClose={closeModal}>
      <ModalSection padding="no" isLoading={isSaving}>
        <GeoFenceAlertsSettingsForm onSubmit={handleSubmit} initialValues={geoFenceSettings} />
      </ModalSection>
    </Modal>
  );
}
