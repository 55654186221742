import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { AppState } from 'src/store';
import { loadRouteRecommendations as doLoadRouteRecommendations } from '../services/routeRecommendations';

// Actions
const COMPLETE_LOAD_RECOMMENDATIONS = 'customers/routeRecommendations/COMPLETE_LOAD_RECOMMENDATIONS';

interface RecommendedRouteTemplate {
  serviceContractRouteTemplateRecommendationId: number;
  id: number;
  name: string;
}

export interface ServiceContractsWithRecommendation {
  address: string;
  binLatitude: number;
  binLongitude: number;
  customerAccountNumber?: number;
  customerName: string;
  enabled?: boolean;
  equipmentSize: TechnicalType;
  equipmentType: TechnicalType;
  locationAccountNumber: string;
  locationName: string;
  recommendedRouteTemplate?: RecommendedRouteTemplate;
  schedulerType: TechnicalType;
  serviceContractAccountStatusType: TechnicalType;
  serviceContractId: number;
  serviceType: TechnicalType;
  wasteMaterialType: TechnicalType;
}

export interface RouteRecommendationState {
  recommendations: ServiceContractsWithRecommendation[];
}

type Dispatch = ThunkDispatch<RouteRecommendationState, any, AnyAction>;

// Initial state
const initialState: RouteRecommendationState = { recommendations: [] };

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case COMPLETE_LOAD_RECOMMENDATIONS:
      return {
        recommendations: action.recommendations,
      };
    default:
      return state;
  }
};

// Action creators
const completeLoad = (recommendations: ServiceContractsWithRecommendation[]) => ({
  type: COMPLETE_LOAD_RECOMMENDATIONS,
  recommendations,
});

export const loadRouteRecommendations = (vendorId: number) => (dispatch: Dispatch) => {
  const promise = doLoadRouteRecommendations(vendorId);
  promise.then(recommendations => dispatch(completeLoad(recommendations)));
  return promise;
};

export const routeRecommendationsFeatureSelector = createSelector(
  (state: AppState) => state.vendors.features.features,
  featuresState => (featuresState as any as any[]).find(f => f.code === 'RouteRecommendation'),
);
