import { map } from 'lodash-es';

import { http } from '../../core/services/http';
import { MaterialTicketEnhanced, MaterialTicketsParams } from '../interfaces/MaterialTickets';

const transformLoadMaterialTrips = (data: MaterialTicketEnhanced[]) =>
  map(data, material => ({
    ...material,
    materialTypeTechnicalName: material?.materialType?.technicalName,
    unitOfMeasureTypeTechnicalName: material?.unitOfMeasureType?.technicalName,
  }));

export const loadWaterFillUpTickets = (params: MaterialTicketsParams) =>
  http
    .get('/routes/alternativeFleet/waterFillUpTickets', {
      params,
    })
    .then(response => transformLoadMaterialTrips(response.data));

export const loadDisposalTickets = (params: MaterialTicketsParams) =>
  http
    .get('/routes/alternativeFleet/disposalTickets', {
      params,
    })
    .then(response => transformLoadMaterialTrips(response.data));

export const loadMaterialPickupTickets = (params: MaterialTicketsParams) =>
  http
    .get('/routes/alternativeFleet/materialPickupTickets', {
      params,
    })
    .then(response => transformLoadMaterialTrips(response.data));
