import update from 'immutability-helper';
import { camelCase } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { validateResetPasswordToken as doValidateResetPasswordToken } from '../services/account';

// Actions
const START_VALIADTE_RESET_PASSWORD_TOKEN = 'account/validateResetPasswordToken/START_VALIADTE_RESET_PASSWORD_TOKEN';
const COMPLETE_VALIDATE_RESET_PASSWORD_TOKEN =
  'account/validateResetPasswordToken/COMPLETE_VALIDATE_RESET_PASSWORD_TOKEN';
const FAIL_VALIDATE_RESET_PASSWORD_TOKEN = 'account/validateResetPasswordToken/FAIL_VALIDATE_RESET_PASSWORD_TOKEN';
const RESET = 'account/validateResetPasswordToken/RESET';

interface State {
  isValidatingResetPasswordToken: boolean;
  isValidResetPasswordTokenFailed: boolean;
  isValidResetPasswordTokenFailedMessage: string | null;
  isValidToken: boolean;
}

// Initial state
const initialState: State = {
  isValidatingResetPasswordToken: false,
  isValidResetPasswordTokenFailed: false,
  isValidResetPasswordTokenFailedMessage: null,
  isValidToken: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_VALIADTE_RESET_PASSWORD_TOKEN:
      return update(state, {
        $merge: {
          isValidatingResetPasswordToken: true,
          isValidResetPasswordTokenFailed: false,
          isValidResetPasswordTokenFailedMessage: null,
        },
      });

    case COMPLETE_VALIDATE_RESET_PASSWORD_TOKEN:
      return update(state, {
        $merge: {
          isValidatingResetPasswordToken: false,
          isValidToken: action.isValidToken,
        },
      });

    case FAIL_VALIDATE_RESET_PASSWORD_TOKEN:
      return update(state, {
        $merge: {
          isValidatingResetPasswordToken: false,
          isValidResetPasswordTokenFailed: true,
          isValidResetPasswordTokenFailedMessage: action.error.request.response
            ? camelCase(action.error.request.response.Code)
            : null,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startValidateResetPasswordToken = () => ({
  type: START_VALIADTE_RESET_PASSWORD_TOKEN,
});

const completeValidateResetPasswordToken = (isValidToken: boolean) => ({
  type: COMPLETE_VALIDATE_RESET_PASSWORD_TOKEN,
  isValidToken,
});

const failValidateResetPasswordToken = (error: any) => ({
  type: FAIL_VALIDATE_RESET_PASSWORD_TOKEN,
  error,
});

export const resetResetPassword = () => ({
  type: RESET,
});

export const validateResetPasswordToken = (code: string, email: string) => (dispatch: Dispatch) => {
  dispatch(startValidateResetPasswordToken());
  return doValidateResetPasswordToken(code, email)
    .then(response => dispatch(completeValidateResetPasswordToken(response)))
    .catch(error => dispatch(failValidateResetPasswordToken(error)));
};
