import React, { PureComponent } from 'react';
import moment from 'moment';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { Dropdown, DatePicker } from '../../../core/components';
import { VendorDropdown } from '../../../vendors/components';
import { FILE_IMPORT_PROCESSING_STATUSES } from '../../constants';
import routeImportsFormInitialValuesSelector from '../../services/routeImportsFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { isDateValidValidator } from 'src/utils/services/validator';

interface PropsWithoutReduxForm extends RouteComponentProps {
  initialValues: any;
  startDate?: string;
  endDate?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class RouteImportsForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, startDate, endDate } = this.props;
    const startDateAsDate = startDate && moment(startDate, 'MM/DD/YYYY').toDate();
    const endDateAsDate = endDate && moment(endDate, 'MM/DD/YYYY').toDate();

    const processingStatusSelectOptions = map(FILE_IMPORT_PROCESSING_STATUSES, fileImportProcessingStatus => ({
      label: fileImportProcessingStatus.name,
      value: fileImportProcessingStatus.id,
    }));

    return (
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall">
          <Grid>
            <GridColumn size="2/12">
              <Field
                name="vendorId"
                component={VendorDropdown}
                placeholder={translate('common.allVendors')}
                dropdownProps={{
                  margin: 'no',
                  isClearable: true,
                }}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="fileProcessingStatusTypeId"
                component={Dropdown}
                placeholder={translate('common.allStatuses')}
                options={processingStatusSelectOptions}
                margin="no"
                isClearable
              />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="startDate"
                component={DatePicker}
                placeholder={translate('common.from')}
                disabledDays={[{ after: endDateAsDate }]}
                margin="no"
                isClearable
                validate={[isDateValidValidator]}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="endDate"
                component={DatePicker}
                placeholder={translate('common.to')}
                disabledDays={[{ before: startDateAsDate }]}
                margin="no"
                isClearable
                validate={[isDateValidValidator]}
              />
            </GridColumn>

            <GridColumn size="4/12" align="right">
              <Button color="primary">{translate('common.search')}</Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps) => {
  const initialValues = routeImportsFormInitialValuesSelector(ownProps.location.search);
  const { startDate, endDate } = (getFormValues as any)('routeImports')(state) || initialValues;

  return {
    initialValues,
    startDate,
    endDate,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'routeImports',
      enableReinitialize: true,
    })(RouteImportsForm),
  ),
);
