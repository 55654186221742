import React from 'react';
import { RecommendedSpan } from './styled/RouteRecommendations';
import translate from '../../core/services/translate';
import { useInterval } from '../../utils/hooks';
import {
  loadServiceContractRecommendation,
  generateServiceContractRecommendation,
} from '../services/routeRecommendations';
import { GetRecommendationButton } from './styled/ServiceDetails';
import { Loader } from './styled/CustomerLocations';

export interface RouteRecommendationResponse {
  recommendedRouteTemplateName?: string;
  recommendedRouteTemplateId?: number;
  routeRecommendationStatusType: RouteRecommendationStatus;
}

enum RouteRecommendationStatus {
  Pending = 1,
  Complete = 2,
  NotAvailable = 3,
}

export const RouteRecommendationOption: React.SFC<{ label: string }> = ({ label }) => (
  <span>
    {label}
    <RecommendedSpan> - {translate('customers.routeRecommendations.recommended')}</RecommendedSpan>
  </span>
);

export const RouteRecommendationMultiSelectOption: React.SFC<{
  vendorId: number;
  service: any;
  day: number;
  onLoadComplete: (routeRecommendation: any) => void;
  recommendation?: RouteRecommendationResponse;
}> = ({ onLoadComplete, vendorId, service, day, recommendation }) => {
  useInterval(
    () => {
      loadServiceContractRecommendation(service.id, day, vendorId).then((response: any) => {
        onLoadComplete(response.data);
      });
    },
    recommendation && recommendation.routeRecommendationStatusType === RouteRecommendationStatus.Pending ? 5000 : null,
  );

  const generateRecommendation = () => {
    onLoadComplete({ routeRecommendationStatusType: RouteRecommendationStatus.Pending });
    generateServiceContractRecommendation({
      vendorId: vendorId,
      serviceContractId: service.id,
      wasteMaterialTypeId: service.wasteMaterialTypeId,
      serviceTypeId: service.serviceTypeId,
      binLatitude: service.containers[0].lat,
      binLongitude: service.containers[0].lng,
      schedulerId: day,
    }).then(async () => {
      loadServiceContractRecommendation(service.id, day, vendorId).then((response: any) => onLoadComplete(response.data));
    });
  };

  if (recommendation === null) {
    return (
      <GetRecommendationButton onClick={generateRecommendation} type="button">
        {translate('customers.routeRecommendations.getRouteRecommendation')}
      </GetRecommendationButton>
    );
  }
  if (!recommendation) {
    return <Loader />;
  }
  if (recommendation && recommendation.routeRecommendationStatusType === RouteRecommendationStatus.Pending) {
    return (
      <>
        <Loader /> {translate('customers.routeRecommendations.recommendingRoute')}
      </>
    );
  }
  if (recommendation && recommendation.routeRecommendationStatusType === RouteRecommendationStatus.NotAvailable) {
    return (
      <>
        <span>{translate('customers.routeRecommendations.recommendationUnavailable')} </span>
        <GetRecommendationButton onClick={generateRecommendation} type="button">
          {translate('customers.routeRecommendations.tryAgain')}
        </GetRecommendationButton>
      </>
    );
  }
  return <RouteRecommendationOption label={recommendation.recommendedRouteTemplateName || ''} />;
};
