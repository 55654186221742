import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { loadUnbilledChargesByLocation as doLoadUnbilledChargesByLocation } from '../services/unbilledCharges';
import { UnbilledCharge } from '../interfaces/UnbilledCharges';

// Actions
const START_LOAD = 'finance/billing/unbilledCharges/START_LOAD';
const COMPLETE_LOAD = 'finance/billing/unbilledCharges/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/billing/unbilledCharges/FAIL_LOAD';
const RESET = 'finance/billing/unbilledCharges/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  unbilledCharges: UnbilledCharge[];
} = {
  isLoading: false,
  unbilledCharges: [] as UnbilledCharge[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unbilledCharges: action.unbilledCharges,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unbilledCharges: [] as UnbilledCharge[],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (unbilledCharges: UnbilledCharge) => ({
  type: COMPLETE_LOAD,
  unbilledCharges,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadUnbilledChargesByLocation =
  (
    vendorId: number,
    startDate: StringOrDate,
    endDate: StringOrDate,
    locationId?: number,
    billMethodIds?: number[],
    paymentTermsIds?: number[],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadUnbilledChargesByLocationPromise = doLoadUnbilledChargesByLocation(
      vendorId,
      startDate,
      endDate,
      locationId,
      billMethodIds,
      paymentTermsIds,
    );
    loadUnbilledChargesByLocationPromise
      .then(unbilledCharges => dispatch(completeLoad(unbilledCharges)))
      .catch(() => dispatch(failLoad()));
    return loadUnbilledChargesByLocationPromise;
  };

export const resetLoadUnbilledChargesByLocation = () => ({
  type: RESET,
});
