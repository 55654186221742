import { http } from '../../core/services/http';
import { transformLoadRouteMapDebugTrackings } from './transformRouteMapDebug';

export const loadMobileVehicleTrackings = (routeId: number, vehicleId?: number) =>
  http
    .get(`routes/${routeId}/routeTracking`, { params: { vehicleId } })
    .then(response => transformLoadRouteMapDebugTrackings(response.data));

export const loadRawVehicleTrackings = (routeId: number) =>
  http.get(`routes/${routeId}/routeTrackingRaw`).then(response => transformLoadRouteMapDebugTrackings(response.data));

export const loadVehicleSettings = (vehicleId: string) => http.get(`/vehicles/${vehicleId}/settings`).then(r => r.data);
