import React, { ChangeEvent, PureComponent } from 'react';

import { map } from 'lodash-es';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

interface Props extends DropDownFieldProps {
  wasteAuditParticipationStatuses: any[];
}

class WasteAuditParticipationDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      wasteAuditParticipationStatuses,
    } = this.props;

    const participationStatuses = map(wasteAuditParticipationStatuses, status => ({
      label: translate(createTranslationKey(status.technicalName, 'routes.wasteAuditStatuses')),
      value: status.wasteAuditLocationStatusTypeId,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.pickupStatus') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.pickupStatus') : undefined),
          options: participationStatuses,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default WasteAuditParticipationDropdown;
