import React from 'react';
import styled from 'styled-components';
import {
  ACTIVE,
  NEW,
  SUSPENDED,
  ON_HOLD,
  CLOSED,
  ACCOUNT_STATUSES,
} from '../../../../common/constants/accountStatuses';

const colorMap: { [key: number]: string } = {
  [ACTIVE]: '#00a69a',
  [NEW]: '#3f95fd',
  [SUSPENDED]: '#ff9500',
  [ON_HOLD]: '#ffcc00',
  [CLOSED]: '#ff3b30',
};

export const CustomerServiceIconImage = styled.img`
  width: 50px;
  border-radius: 3px 3px 0 0;
`;

const Wrapper = styled.div`
  height: 63px;
  margin-right: 3px;
`;

const CustomerServiceIconWrapper = styled.div<{ color: string }>`
  position: relative;
  width: 50px;
  height: 50px;

  & > div {
    padding: 1px;
    background: ${p => p.color};
    left: 0;
    right: 0;
    font-size: 8px;
    color: white;
    text-align: center;
    position: absolute;
    bottom: -13px;
    border-radius: 2px;
    left: 0;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0 0 3px 3px;

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const CustomerServiceIcon: React.SFC<{ src: string; accountStatusId?: number }> = ({ src, accountStatusId }) => {
  if (!accountStatusId) return null;
  return (
    <Wrapper>
      <CustomerServiceIconWrapper color={colorMap[accountStatusId]}>
        <div>
          <div>{ACCOUNT_STATUSES[accountStatusId] ? ACCOUNT_STATUSES[accountStatusId].name : 'zZzZzZ'}</div>
        </div>
        <CustomerServiceIconImage src={src} />
      </CustomerServiceIconWrapper>
    </Wrapper>
  );
}
