import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

const transformLoadUserTypes = (userTypes: any[]) =>
  map(userTypes, userType => ({
    ...userType,
    name: translate(createTranslationKey(userType.technicalName, 'common.userTypes')),
  }));

export default transformLoadUserTypes;
