import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { loadCustomerPaymentMethods } from 'src/customers/ducks/paymentMethods';
import { Modal, TypedResolver } from 'src/core/components';
import { PageLoading } from 'src/common/components/styled';
import { setupPaymentIntentWithCustomer } from 'src/finance/ducks/paymentManagement';
import NewPaymentForm from '../../pages/newPaymentMethod/NewPaymentMethodForm';

export const NewPaymentModalResolver: FC<{ customerId: number; onClose: () => void }> = ({ customerId, onClose }) => {
  const dispatch = useDispatch();

  const loadDependencies = async () => {
    return setupPaymentIntentWithCustomer(customerId)(dispatch).catch(() => {});
  };

  const reloadPaymentMethods = () => {
    loadCustomerPaymentMethods(customerId)(dispatch);
  };

  return (
    <Modal minWidth="90vw">
      <TypedResolver
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={NewPaymentForm}
        successProps={{
          onCancel: () => {
            onClose();
          },
          returnToPreviousPage: () => {
            reloadPaymentMethods();
            onClose();
          },
        }}
      />
    </Modal>
  );
};
