import { AxiosProgressEvent } from "axios";
import { http } from "src/core/services/http";

export const uploadInvoices = (fileData: any, vendorId: number,dispatch: any, updateProgress: any) => {
    const formData = new FormData();
    if (fileData) {
        Array.from(fileData).forEach((f: any) => formData.append('file', f));
      }

    formData.append('data', JSON.stringify({ vendorId }));
    
    return http.post('/gus/vendor/invoice/webupload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if(progressEvent && progressEvent.total){
          dispatch(updateProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))} 
        }
    });
  };
  