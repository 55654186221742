import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import {
  ROUTE_MAP_TRAVEL_PATH_DASHED_LINES_LAYER,
  ROUTE_MAP_TRAVEL_PATH_ARROWS_SOURCE,
  ROUTE_MAP_TRAVEL_PATH_ARROWS_LAYER,
  ROUTE_MAP_TRAVEL_PATH_LINES_LAYER,
  ROUTE_MAP_TRAVEL_PATH_SEQUENCE_LAYER,
  ROUTE_MAP_TRAVEL_PATH_SOURCE,
} from '../constants';

interface Props {
  linesGeoJson: any;
  arrowsGeoJson: any;
}

const RouteMapTravelPathGLSource = memo(({ linesGeoJson, arrowsGeoJson }: Props) => {
  return (
    <>
      <Source type="geojson" data={linesGeoJson} id={ROUTE_MAP_TRAVEL_PATH_SOURCE}>
        {/* solid lines */}
        <Layer
          id={ROUTE_MAP_TRAVEL_PATH_LINES_LAYER}
          type="line"
          source={ROUTE_MAP_TRAVEL_PATH_SOURCE}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          filter={['!=', 'isDashed', true]}
          paint={{
            'line-color': ['get', 'color'],
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 1, 22, 8],
            'line-translate': [0, 0],
          }}
        />
        {/* dashed lines */}

        <Layer
          id={ROUTE_MAP_TRAVEL_PATH_DASHED_LINES_LAYER}
          type="line"
          source={ROUTE_MAP_TRAVEL_PATH_SOURCE}
          layout={{
            'line-cap': 'round',
            'line-join': 'round',
          }}
          filter={['==', 'isDashed', true]}
          paint={{
            'line-color': ['get', 'color'],
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 1, 22, 5],
            'line-dasharray': [3, 2],
          }}
        />
        {/* sequence number */}
        <Layer
          id={ROUTE_MAP_TRAVEL_PATH_SEQUENCE_LAYER}
          type="symbol"
          source={ROUTE_MAP_TRAVEL_PATH_SOURCE}
          minzoom={17}
          layout={{
            'symbol-placement': 'line-center',
            'text-field': ['get', 'sequence'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 12, 22, 16],
            'text-allow-overlap': true,
            'text-ignore-placement': true,
          }}
          paint={{
            'text-color': ['get', 'color'],
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3,
            'text-translate': [0, -25],
          }}
        />
      </Source>
      <Source type="geojson" data={arrowsGeoJson} id={ROUTE_MAP_TRAVEL_PATH_ARROWS_SOURCE}>
        <Layer
          id={ROUTE_MAP_TRAVEL_PATH_ARROWS_LAYER}
          type="symbol"
          source={ROUTE_MAP_TRAVEL_PATH_ARROWS_SOURCE}
          minzoom={15}
          layout={{
            'symbol-placement': 'point',
            'text-field': '▶',
            'text-anchor': 'center',
            'text-size': ['interpolate', ['linear'], ['zoom'], 15, 18, 22, 50],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-rotate': ['get', 'rotate'],
          }}
          paint={{
            'text-color': ['get', 'color'],
            'text-halo-color': 'hsl(55, 11%, 96%)',
            'text-halo-width': 3,
          }}
        />
      </Source>
    </>
  );
});

export default RouteMapTravelPathGLSource;
