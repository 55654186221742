import { camelCase, map } from 'lodash-es';
import { change } from 'redux-form';
import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  ActionButtonTooltip,
  DatePicker,
  Dropdown,
  MultiSelect,
  Switch,
  UnconnectedCheckbox,
} from 'src/core/components';
import {
  BlackoutSettings,
  SeasonalAndBlackoutSettings,
  SeasonalPickupSettings,
} from 'src/vendors/interfaces/BulkyItemScheduler';
import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import { Button, Grid, GridColumn, Separator, Text, TableActionButton } from '../../../core/components/styled';
import { isRequired, isRequiredOption } from 'src/utils/services/validator';
import {
  isValidEndDayBlackOut,
  isValidEndDaySeasonal,
  isValidEndMonthBlackOut,
  isValidEndMonthSeasonal,
  isBeforeStartDateSeasonalSettings,
  isBeforeStartDateBlackoutSttings,
} from 'src/vendors/services/bulkyItemSchedulerValidator';
import { RECURRENCE_TYPES, WEEKDAYS } from '../../../core/constants/weekdays';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TypedField } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import MONTHS from '../../../common/constants/months';
import translate from '../../../core/services/translate';

type Props = { isForBlackOutDates?: boolean; bulkySettings: SeasonalAndBlackoutSettings[]; dateTypes: DateType[] };

export enum DateType {
  single = 'SINGLE DATE',
  specific = 'SPECIFIC DATE RANGE',
  descriptive = 'DESCRIPTIVE DATE RANGE',
}

const BulkyItemSeasonalPickupScheduleAndBlackoutDatesSection: FC<Props> = ({
  isForBlackOutDates,
  bulkySettings,
  dateTypes,
}) => {
  const dispatch = useDispatch();

  const { bulkyCategoryTypes } = useSelector(state => state.vendors.bulkyItemScheduler);

  const categoryTypesOptions = map(bulkyCategoryTypes, (type: TechnicalType) => ({
    label: translate(`vendors.bulkyItemScheduler.categoryTypes.${camelCase(type.name)}`),
    value: type.id,
  }));

  const recurrenceDays = WEEKDAYS.map(day => ({
    label: day.name,
    value: day.id,
  }));

  const recurrenceMonths = Object.keys(MONTHS).map((key, index) => ({
    label: translate(MONTHS[key].translationKey),
    value: index + 1,
  }));

  const recurrenceTypes = RECURRENCE_TYPES.map(type => ({
    label: type.label,
    value: type.value,
  }));

  const deleteItem = (index: number) => {
    const newSettings = bulkySettings.filter(
      (_: SeasonalAndBlackoutSettings, itemIndex: number) => itemIndex !== index,
    );
    const newDateTypes = [...dateTypes.filter((_: any, itemIndex: number) => itemIndex !== index)];

    if (isForBlackOutDates) {
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'bulkySchedulerBlackoutSettings', newSettings));
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'blackoutDateTypes', newDateTypes));
    } else {
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'bulkySchedulerSeasonalPickUpSettings', newSettings));
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'seasonalDateTypes', newDateTypes));
    }
  };

  const addNewItem = () => {
    const newSettingsBlackoutSettings = [
      ...bulkySettings,
      {
        categoryIds: [] as number[],
        blackoutSettings: { isEnabled: true } as BlackoutSettings,
      },
    ];

    const newSettingsSeasonalPickUpSettings = [
      ...bulkySettings,
      {
        categoryIds: [] as number[],
        seasonalPickUpSettings: { isEnabled: true } as SeasonalPickupSettings,
      },
    ];

    const newDateTypes = [...dateTypes, DateType.single];

    if (isForBlackOutDates) {
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'bulkySchedulerBlackoutSettings', newSettingsBlackoutSettings));
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'blackoutDateTypes', newDateTypes));
    } else {
      dispatch(
        change(BULKY_ITEM_SCHEDULER_FORM, 'bulkySchedulerSeasonalPickUpSettings', newSettingsSeasonalPickUpSettings),
      );
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'seasonalDateTypes', newDateTypes));
    }
  };

  const handleChangeDateType = (dateTypeIndex: number, newDateType: DateType) => {
    const newDateTypes = dateTypes.map((dateType, index) => {
      if (index === dateTypeIndex) {
        return newDateType;
      } else return dateType;
    });

    if (isForBlackOutDates) {
      dispatch(
        change(BULKY_ITEM_SCHEDULER_FORM, `bulkySchedulerBlackoutSettings[${dateTypeIndex}]`, {
          categoryIds: bulkySettings[dateTypeIndex]?.categoryIds,
          blackoutSettings: {
            isEnabled: bulkySettings[dateTypeIndex]?.blackoutSettings?.isEnabled,
          } as SeasonalPickupSettings,
        }),
      );
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'blackoutDateTypes', newDateTypes));
    } else {
      dispatch(
        change(BULKY_ITEM_SCHEDULER_FORM, `bulkySchedulerSeasonalPickUpSettings[${dateTypeIndex}]`, {
          categoryIds: bulkySettings[dateTypeIndex]?.categoryIds,
          seasonalPickUpSettings: {
            isEnabled: bulkySettings[dateTypeIndex]?.seasonalPickUpSettings?.isEnabled,
          } as SeasonalPickupSettings,
        }),
      );
      dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'seasonalDateTypes', newDateTypes));
    }
  };

  const bulkyItemSeasonalPickupScheduleOrBlackoutSection = (index: number) => (
    <Fragment key={index}>
      <GridColumn size="3/12" padding="xSmall xSmall small no" verticalAlign="flex-start">
        <TypedField
          name={
            isForBlackOutDates
              ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.isEnabled`
              : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.isEnabled`
          }
          component={Switch}
          props={{
            margin: 'no small no no',
            size: 'medium',
          }}
        />
        <TypedField
          name={
            isForBlackOutDates
              ? `bulkySchedulerBlackoutSettings[${index}].categoryIds`
              : `bulkySchedulerSeasonalPickUpSettings[${index}].categoryIds`
          }
          component={MultiSelect}
          props={{
            defaultToAll: false,
            options: categoryTypesOptions,
            margin: 'no',
            label: translate('vendors.bulkyItemScheduler.customPickup.category'),
          }}
          validate={[isRequiredOption]}
        />
      </GridColumn>

      <GridColumn size="9/12" padding="xSmall xSmall small xSmall">
        <Grid multiLine>
          <GridColumn size="11/12" margin="small no xSmall no">
            <UnconnectedCheckbox
              label={translate('vendors.bulkyItemScheduler.singleDate')}
              checked={dateTypes[index] === DateType.single}
              onChange={() => handleChangeDateType(index, DateType.single)}
              margin="no lMedium no no"
            />
            <UnconnectedCheckbox
              label={translate('routes.seasonality.specificDateRange')}
              checked={dateTypes[index] === DateType.specific}
              onChange={() => handleChangeDateType(index, DateType.specific)}
              margin="no lMedium no no"
            />
            <UnconnectedCheckbox
              label={translate('routes.seasonality.descriptiveDateRange')}
              checked={dateTypes[index] === DateType.descriptive}
              onChange={() => handleChangeDateType(index, DateType.descriptive)}
            />
          </GridColumn>
          <GridColumn size="1/12" alignVerticalCenter>
            <TableActionButton onClick={() => deleteItem(index)} margin="no no no medium">
              <ActionButtonTooltip icon="delete" tooltip="delete" />
            </TableActionButton>
          </GridColumn>

          {dateTypes[index] === DateType.single ? (
            <>
              <GridColumn size="12/12" alignVerticalCenter margin="small no no no">
                <Text block weight="medium" size="medium" margin="no no small no">
                  {translate('vendors.bulkyItemScheduler.singleDate')}
                </Text>
              </GridColumn>
              <GridColumn size="6/12">
                <TypedField
                  name={
                    isForBlackOutDates
                      ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.singleDate`
                      : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.singleDate`
                  }
                  component={DatePicker}
                  props={{
                    label: translate('common.date'),
                    margin: 'no no sMedium no',
                    width: '50%',
                    disabledDays: [
                      {
                        before: moment().toDate(),
                      },
                    ],
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>
            </>
          ) : dateTypes[index] === DateType.specific ? (
            <>
              <Grid multiLine>
                <GridColumn size="4/12" alignVerticalCenter margin="small no no no">
                  <Text block weight="medium" size="medium" margin="no no small no">
                    {translate('routes.seasonality.rangeStart')}
                  </Text>
                </GridColumn>

                <GridColumn size="4/12" alignVerticalCenter margin="small no no no">
                  <Text block weight="medium" size="medium" margin="no no small no">
                    {translate('routes.seasonality.rangeEnd')}
                  </Text>
                </GridColumn>
              </Grid>

              <Grid multiLine>
                <GridColumn size="4/12">
                  <TypedField
                    name={
                      isForBlackOutDates
                        ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.specificBeginDate`
                        : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.specificBeginDate`
                    }
                    component={DatePicker}
                    props={{
                      label: translate('vendors.bulkyItemScheduler.startDate'),
                      width: '50%',
                      disabledDays: [
                        {
                          before: moment().toDate(),
                        },
                      ],
                    }}
                    validate={[isRequired]}
                  />
                </GridColumn>

                <GridColumn size="4/12">
                  <TypedField
                    name={
                      isForBlackOutDates
                        ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.specificEndDate`
                        : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.specificEndDate`
                    }
                    component={DatePicker}
                    props={{
                      label: translate('vendors.bulkyItemScheduler.endDate'),
                      width: '50%',
                      disabledDays: [
                        {
                          before: moment(
                            isForBlackOutDates
                              ? bulkySettings[index]?.blackoutSettings?.specificBeginDate
                              : bulkySettings[index]?.seasonalPickUpSettings?.specificBeginDate,
                          )
                            .add(1, 'days')
                            .toDate(),
                        },
                      ],
                      isClearable: true,
                    }}
                    validate={[
                      isForBlackOutDates ? isBeforeStartDateBlackoutSttings : isBeforeStartDateSeasonalSettings,
                    ]}
                  />
                </GridColumn>
              </Grid>
            </>
          ) : (
            <>
              <GridColumn size="6/12" alignVerticalCenter margin="small no no no">
                <Text block weight="medium" size="medium" margin="no no small no">
                  {translate('routes.seasonality.rangeStart')}
                </Text>
              </GridColumn>

              <GridColumn size="6/12" alignVerticalCenter margin="small no no no">
                <Text block weight="medium" size="medium" margin="no no small no">
                  {translate('routes.seasonality.rangeEnd')}
                </Text>
              </GridColumn>

              <GridColumn size="2/12">
                <TypedField
                  name={
                    isForBlackOutDates
                      ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.descriptiveBeginDayTypeId`
                      : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.descriptiveBeginDayTypeId`
                  }
                  component={Dropdown}
                  props={{
                    width: '100%',
                    label: translate('routes.seasonality.order'),
                    options: recurrenceTypes,
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>

              <GridColumn size="2/12">
                <TypedField
                  name={
                    isForBlackOutDates
                      ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.descriptiveBeginDayOfWeek`
                      : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.descriptiveBeginDayOfWeek`
                  }
                  component={Dropdown}
                  props={{
                    width: '100%',
                    label: translate('routes.seasonality.dayOfWeek'),
                    options: recurrenceDays,
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>

              <GridColumn size="2/12">
                <TypedField
                  name={
                    isForBlackOutDates
                      ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.descriptiveBeginMonth`
                      : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.descriptiveBeginMonth`
                  }
                  component={Dropdown}
                  props={{
                    width: '100%',
                    label: translate('routes.seasonality.month'),
                    options: recurrenceMonths,
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>

              <GridColumn size="2/12">
                <TypedField
                  name={
                    isForBlackOutDates
                      ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.descriptiveEndDayTypeId`
                      : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.descriptiveEndDayTypeId`
                  }
                  component={Dropdown}
                  props={{
                    width: '100%',
                    label: translate('routes.seasonality.order'),
                    options: recurrenceTypes,
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>

              <GridColumn size="2/12">
                <TypedField
                  name={
                    isForBlackOutDates
                      ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.descriptiveEndDayOfWeek`
                      : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.descriptiveEndDayOfWeek`
                  }
                  component={Dropdown}
                  props={{
                    width: '100%',
                    label: translate('routes.seasonality.dayOfWeek'),
                    options: recurrenceDays,
                  }}
                  validate={[isRequired, isForBlackOutDates ? isValidEndDayBlackOut : isValidEndDaySeasonal]}
                />
              </GridColumn>

              <GridColumn size="2/12">
                <TypedField
                  name={
                    isForBlackOutDates
                      ? `bulkySchedulerBlackoutSettings[${index}].blackoutSettings.descriptiveEndMonth`
                      : `bulkySchedulerSeasonalPickUpSettings[${index}].seasonalPickUpSettings.descriptiveEndMonth`
                  }
                  component={Dropdown}
                  props={{
                    width: '100%',
                    label: translate('routes.seasonality.month'),
                    options: recurrenceMonths,
                  }}
                  validate={[isRequired, isForBlackOutDates ? isValidEndMonthBlackOut : isValidEndMonthSeasonal]}
                />
              </GridColumn>
            </>
          )}
        </Grid>
      </GridColumn>

      {index + 1 < bulkySettings.length && <Separator color="grayLight" size={1} margin="no no xSmall no" />}
    </Fragment>
  );

  return (
    <GridColumn size="12/12" padding="no xSmall small xSmall">
      <Text block size="large" weight="medium" margin="no no xSmall no">
        {isForBlackOutDates
          ? translate('vendors.bulkyItemScheduler.blackoutDates')
          : translate('vendors.bulkyItemScheduler.seasonalPickupSchedule')}
      </Text>
      <Grid multiLine>
        {bulkySettings?.map((_: SeasonalAndBlackoutSettings, index: number) =>
          bulkyItemSeasonalPickupScheduleOrBlackoutSection(index),
        )}
      </Grid>

      <Button color="primary" margin="small no no no" type="button" size="small" onClick={addNewItem}>
        {translate('common.addNew')}
      </Button>
      <Separator color="grayLight" size={2} margin="sMedium no xSmall no" />
    </GridColumn>
  );
};

export default BulkyItemSeasonalPickupScheduleAndBlackoutDatesSection;
