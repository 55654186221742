import { PureComponent } from 'react';

import { FormError, FormGroup, FormLabel } from './styled';
import { FormGroupProps } from './styled/FormGroup';
import { WrappedFieldProps } from 'redux-form';
import translate from '../services/translate';
import UnconnectedCheckbox from './UnconnectedCheckbox';

export interface CheckboxGroupProps extends WrappedFieldProps {
  disabled?: boolean;
  fieldNotTouched?: boolean;
  formGroupProps?: Partial<FormGroupProps>;
  items: {
    value: string | number;
    label: string;
    name?: string;
  }[];
  label?: string;
  margin?: string;
  padding?: string;
  size?: string;
  type?: string;
  withLabel?: boolean;
}

class CheckboxGroup extends PureComponent<CheckboxGroupProps> {
  static defaultProps = {
    formGroupProps: {},
  };

  onChange = (value: string | number) => () => {
    const { type } = this.props;
    const { value: currentValue } = this.props.input;
    let selectedValues: any = [...currentValue];

    if (type === 'radio') {
      selectedValues = [value];
    } else {
      if (currentValue.indexOf(value) > -1) {
        selectedValues.splice(currentValue.indexOf(value), 1);
      } else {
        selectedValues = new Set([...currentValue, value]);
      }
    }

    this.props.input.onChange([...selectedValues]);
  };

  render() {
    const {
      disabled,
      fieldNotTouched,
      formGroupProps,
      input,
      items,
      label,
      margin,
      meta: { submitFailed, error },
      padding,
      size,
      withLabel,
    } = this.props;

    return (
      <>
        <FormGroup margin={margin} padding={padding} {...formGroupProps}>
          {(!!label || withLabel) && (
            <FormLabel margin="no no xxSmall no" noTransform>
              {label || (withLabel ? translate('vendors.deviceType') : undefined)}
            </FormLabel>
          )}
          {items.map(item => (
            <UnconnectedCheckbox
              size={size}
              key={item.value}
              disabled={!!disabled}
              label={item.label}
              checked={input.value.indexOf(item.value) > -1}
              onChange={this.onChange(item.value)}
            />
          ))}
        </FormGroup>

        {submitFailed && error && !fieldNotTouched && <FormError>{error}</FormError>}
      </>
    );
  }
}

export default CheckboxGroup;
