import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { loadServiceTypes } from 'src/common/ducks';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadCustomer } from 'src/customers/ducks';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import ServiceContractWorkflowModal from './ServiceContractWorkflowModal';

interface Props {
  closeModal: () => void;
  onSubmitSuccess?: () => void;
  selectedCustomerId?: number;
  selectedLocationId?: number;
}

export const ServiceContractWorkflowModalResolver: FC<Props> = ({
  closeModal,
  selectedCustomerId,
  selectedLocationId,
  onSubmitSuccess,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const customer = useSelector(state => state.customers.customer.customer);

  const loadDependencies = useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }
    const dependencies: any[] = [loadServiceTypes()(dispatch), loadVehicleTypesForVendor(vendorId)(dispatch)];

    if (selectedCustomerId && customer?.id !== selectedCustomerId) {
      dependencies.push(loadCustomer(selectedCustomerId)(dispatch));
    }

    return Promise.all(dependencies);
  }, [customer?.id, dispatch, selectedCustomerId, vendorId]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={ServiceContractWorkflowModal}
      successProps={{ closeModal, vendorId, selectedCustomerId, selectedLocationId, onSubmitSuccess }}
    />
  );
};
