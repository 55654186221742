import { useDispatch } from 'react-redux';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadFacilityTypes, loadFacility, loadFacilitySubType } from '../../ducks';
import { loadWasteTypes } from '../../../common/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import { TypedResolver } from 'src/core/components';
import FacilityEditorModal from './FacilityEditorModal';
import { PageLoadingOverlay } from 'src/common/components/styled';

interface Props {
  facilityId?: number;
  closeFacilityEditorModal: () => void;
  onCancel: (formPristine: boolean) => Promise<void>;
}

const FacilityEditorModalResolver = ({ facilityId, closeFacilityEditorModal, onCancel }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }
    const dependencies: Promise<any>[] = [loadFacilityTypes()(dispatch), loadWasteTypes()(dispatch)];
    if (facilityId) {
      const loadFacilityPromise = await loadFacility(facilityId)(dispatch);
      dependencies.push(loadFacilitySubType(loadFacilityPromise.facilityType.id)(dispatch));
    }
    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={FacilityEditorModal}
      successProps={{ onCancel, facilityId, closeFacilityEditorModal }}
      loadingComponent={PageLoadingOverlay}
      onError={closeFacilityEditorModal}
      resolve={loadDependencies}
    />
  );
};

export default FacilityEditorModalResolver;
