import React from 'react';

import { Field } from 'redux-form';
import { SortableElement } from 'react-sortable-hoc';

import { DragHandle, Switch } from '../../../core/components';
import { TableCell, TableRow } from '../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  driverSafetyInspectionType: TechnicalType;
}

const DriverSafetyInspectionSettingsFormTableRow: React.FC<Props> = ({ driverSafetyInspectionType }) => {
  return (
    <TableRow>
      <TableCell width="5%" align="center" padding="small">
        <DragHandle />
      </TableCell>
      <TableCell width="55%">
        {translate(createTranslationKey(driverSafetyInspectionType.technicalName, 'vendors.driverSafetyReport'))}
      </TableCell>
      <TableCell width="20%" align="center">
        <Field name={`${driverSafetyInspectionType.technicalName}.isActive`} component={Switch} />
      </TableCell>
      <TableCell width="20%" align="center">
        <Field name={`${driverSafetyInspectionType.technicalName}.isRequired`} component={Switch} />
      </TableCell>
    </TableRow>
  );
};

export default SortableElement(DriverSafetyInspectionSettingsFormTableRow);
