import { FC, useMemo } from 'react';

import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { NoResultsContainer } from 'src/customers/components/styled/RouteRecommendations';
import { SectionTableWrapper } from 'src/common/components/styled';
import { Table, UnconnectedCheckbox } from 'src/core/components';
import ServiceContractsTableRow from './ServiceContractsTableRow';

interface CheckAllServicesProps {
  checkAll: (checked: boolean) => void;
  getChecked: () => boolean;
}

const CheckAllServicesCheckbox: FC<CheckAllServicesProps> = ({ checkAll, getChecked }) => (
  <UnconnectedCheckbox
    block
    checked={getChecked()}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => checkAll(event.target.checked)}
    size="small"
  />
);

const tableWidths = ['5%', '12%', '28%', '15%', '13%', '30%'];

const contractsTableCells = (checkAll: (checked: boolean) => void, allChecked: boolean) => [
  {
    name: 'selectAll',
    component: CheckAllServicesCheckbox,
    componentProps: {
      checkAll: checkAll,
      getChecked: () => allChecked,
    },
    width: tableWidths[0],
  },
  {
    name: 'customerName',
    label: translate('common.customer'),
    width: tableWidths[1],
    sortable: true,
  },
  {
    name: 'location',
    label: translate('common.location'),
    width: tableWidths[2],
  },
  {
    name: 'equipment',
    label: translate('common.equipment'),
    width: tableWidths[3],
  },
  {
    name: 'material',
    label: translate('common.material'),
    width: tableWidths[4],
  },
  {
    name: 'rates',
    label: translate('common.rates'),
    width: tableWidths[5],
  },
];

interface Props {
  setCheckedServiceContractIds: (ids: number[]) => void;
  checkedServiceContractIds: number[];
}

const RateBuilderListView: FC<Props> = ({ setCheckedServiceContractIds, checkedServiceContractIds }) => {
  const serviceContractsData = useSelector(state => state.customers.rateBuilder.serviceContracts);
  const serviceContracts = useMemo(() => serviceContractsData?.serviceContracts || [], [serviceContractsData]);

  const allChecked = serviceContracts.length === checkedServiceContractIds.length;

  const checkAll = () => {
    if (allChecked) {
      setCheckedServiceContractIds([]);
    } else {
      const sIds = serviceContracts.map(item => item.serviceContractId);
      setCheckedServiceContractIds(sIds);
    }
  };

  const onCheckChange = (e: any, sId: number) => {
    if (checkedServiceContractIds.includes(sId)) {
      const newSelection = checkedServiceContractIds.filter(i => i !== sId);
      setCheckedServiceContractIds(newSelection);
    } else {
      setCheckedServiceContractIds([...checkedServiceContractIds, sId]);
    }
  };

  if (!serviceContracts.length) {
    return <NoResultsContainer>{translate('common.noResults')}</NoResultsContainer>;
  }

  return (
    <SectionTableWrapper padding="no medium medium medium">
      <Table
        cells={contractsTableCells(checkAll, allChecked)}
        rows={serviceContracts}
        rowComponent={ServiceContractsTableRow}
        rowProps={{
          onCheckChange,
          tableWidths,
          checkedServiceContractIds,
        }}
      />
    </SectionTableWrapper>
  );
};

export default RateBuilderListView;
