import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { AppState } from '../../../store';

import { ActionRequiredContainer } from '../../../fleet/components/styled/RubiconDispatches';
import { Grid, GridColumn, PanelSection, Text } from '../../../core/components/styled';
import rubiconDispatchesFormInitialValuesSelector from '../../../fleet/services/rubiconDispatchesFormInitialValuesSelector';
import { Switch } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  notificationsCount: number;
  onActionRequiredToggleChange: (event: any, isActionRequired: boolean) => void;
}

type ComponentProps = Props & InjectedFormProps<any, Props>;

class OpportunitiesFilterForm extends PureComponent<ComponentProps> {
  render() {
    const { notificationsCount, onActionRequiredToggleChange } = this.props;
    return (
      <form>
        <Fragment>
          <PanelSection padding="xSmall" withBorder>
            <Grid>
              <GridColumn size="10/12"></GridColumn>
              <GridColumn size="2/12">
                <ActionRequiredContainer border>
                  <Text block size="small" weight="medium" margin="no no xxSmall">
                    {translate('autoDispatch.actionRequired')}
                  </Text>
                  <Field
                    name="isActionRequired"
                    component={Switch}
                    onChange={onActionRequiredToggleChange}
                    props={{
                      label: translate('opportunity.opportunities.opportunitiesCount', { count: notificationsCount }),
                      margin: 'no',
                      color: 'alert',
                    }}
                  />
                </ActionRequiredContainer>
              </GridColumn>
            </Grid>
          </PanelSection>
        </Fragment>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, { location: { search } }: Props) => ({
  initialValues: rubiconDispatchesFormInitialValuesSelector(search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, Props>({
      form: 'OpportunitiesFilter',
      enableReinitialize: true,
    })(OpportunitiesFilterForm),
  ),
);
