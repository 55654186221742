import { AnyAction } from 'redux';
import update from 'immutability-helper';

// Actions
const SET_DRAG_PAN = 'routes/routeMapSettings/SET_DRAG_PAN';
const SET_MAP_GEOJSON = 'routes/routeMapSettings/SET_MAP_GEOJSON';
const SET_TRIGGER_MAP_GEOJSON = 'routes/routeMapSettings/SET_TRIGGER_MAP_GEOJSON';
const SET_MAP_GEOJSON_FOR_MAP = 'routes/routeMapSettings/SET_MAP_GEOJSON_FOR_MAP';
const RESET = 'routes/routeMapSettings/RESET';

type GeoJson = GeoJSON.FeatureCollection<GeoJSON.Point, any>;

interface State {
  dragPan: boolean;
  mapGeoJson: GeoJson;
  mapGeoJsonForMap: GeoJson;
  triggerMapGeoJson: GeoJson;
}

// Initial state
const initialState: State = {
  dragPan: true,
  mapGeoJson: { type: 'FeatureCollection', features: [] },
  mapGeoJsonForMap: { type: 'FeatureCollection', features: [] },
  triggerMapGeoJson: { type: 'FeatureCollection', features: [] },
};

// Reducer
export const routeMapSettingsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_DRAG_PAN:
      return update(state, {
        $merge: {
          dragPan: action.dragPan,
        },
      });

    case SET_MAP_GEOJSON:
      return update(state, {
        $merge: {
          mapGeoJson: action.mapGeoJson,
        },
      });

    case SET_TRIGGER_MAP_GEOJSON:
      return update(state, {
        $merge: {
          triggerMapGeoJson: action.triggerMapGeoJson,
        },
      });

    case SET_MAP_GEOJSON_FOR_MAP:
      return update(state, {
        $merge: {
          mapGeoJsonForMap: action.mapGeoJsonForMap,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
export const setMapDragPan = (dragPan: boolean) => ({
  type: SET_DRAG_PAN,
  dragPan,
});

export const setMapGeoJson = (mapGeoJson: GeoJson) => ({
  type: SET_MAP_GEOJSON,
  mapGeoJson,
});

export const setTriggerMapGeoJson = (triggerMapGeoJson: GeoJson) => ({
  type: SET_TRIGGER_MAP_GEOJSON,
  triggerMapGeoJson,
});

export const setMapGeoJsonForMap = (mapGeoJsonForMap: GeoJson) => ({
  type: SET_MAP_GEOJSON_FOR_MAP,
  mapGeoJsonForMap,
});

export const resetMapGeoJson = () => ({
  type: RESET,
});
