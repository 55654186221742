import translate from 'src/core/services/translate';

export const billThruAnnualOptions = [
  {
    label: translate('customers.billing.lastYear'),
    value: -1,
  },
  {
    label: translate('customers.billing.currentYear'),
    value: 0,
  },
  {
    label: translate('customers.billing.nextYear'),
    value: 1,
  },
];
