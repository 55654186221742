import translate from 'src/core/services/translate';

export const billThruWeekOptions = [
  {
    label: translate('customers.billing.previousWeek'),
    value: -1,
  },
  {
    label: translate('customers.billing.currentWeek'),
    value: 0,
  },
  {
    label: translate('customers.billing.nextWeek'),
    value: 1,
  },
];
