import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { Accordion, AccordionSection, Modal, Table } from '../../../core/components';
import { AppState } from 'src/store';
import { DuckAction } from 'src/contracts/ducks';
import { Message, ModalSubtitle } from '../../../core/components/styled';
import { resetRouteLocationNotes } from '../../ducks';
import { RouteLocationNotesModalTableRow } from './';
import { RouteNotesForm } from '../forms';
import translate from '../../../core/services/translate';

const routeLocationNoteTableCells = [
  { name: 'date', label: translate('common.timestamp'), width: '20%' },
  { name: 'notes', label: translate('common.note'), width: '80%' },
];

interface Props {
  closeModal: () => void;
  isNewStop?: boolean;
  isReadOnly: boolean;
  modalSubTitle?: string;
  modalTitle?: string;
  resetRouteLocationNotes: DuckAction<typeof resetRouteLocationNotes>;
  routeId?: number;
  routeLocationId?: number;
  routeLocationNotes?: any;
  siteAddress?: string;
}

class RouteLocationNotesModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetRouteLocationNotes();
  }

  render() {
    const {
      closeModal,
      isNewStop,
      isReadOnly,
      modalTitle,
      routeId,
      routeLocationId,
      routeLocationNotes: { locationNotes, serviceContractNotes },
      siteAddress,
    } = this.props;

    const openSections = routeLocationId ? ['routeNotes'] : [];

    return (
      <Modal title={modalTitle} onClose={closeModal} padding="medium no no">
        {siteAddress && <ModalSubtitle>{siteAddress}</ModalSubtitle>}

        <Accordion margin="medium no no" openedSections={openSections}>
          <AccordionSection name="routeNotes" title={translate('routes.routeNotes')} isOpen>
            <RouteNotesForm
              isNewStop={isNewStop}
              isReadOnly={isReadOnly}
              routeId={routeId}
              routeLocationId={routeLocationId}
            />
          </AccordionSection>

          <AccordionSection name="locationNotes" title={translate('routes.locationNotes')}>
            {locationNotes.length ? (
              <Table
                withTopBorder
                noBottomBorder
                cells={routeLocationNoteTableCells}
                rows={locationNotes}
                rowComponent={RouteLocationNotesModalTableRow}
              />
            ) : (
              <Message padding="no no sMedium sMedium">{translate('routes.noNotes')}</Message>
            )}
          </AccordionSection>

          <AccordionSection name="serviceNotes" title={translate('routes.serviceNotes')}>
            {serviceContractNotes.length ? (
              <Table
                withTopBorder
                noBottomBorder
                cells={routeLocationNoteTableCells}
                rows={serviceContractNotes}
                rowComponent={RouteLocationNotesModalTableRow}
              />
            ) : (
              <Message padding="no no sMedium sMedium">{translate('routes.noNotes')}</Message>
            )}
          </AccordionSection>
        </Accordion>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  routeLocationNotes: state.routes.routeLocationNotes.routeLocationNotes,
});

const mapDispatchToProps = { resetRouteLocationNotes };

export default connect(mapStateToProps, mapDispatchToProps)(RouteLocationNotesModal);
