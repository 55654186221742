import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadStopMapDetails as doLoadStopMapDetails } from '../services/stopMapDetails';
import { StopMapDetails } from '../interfaces/StopMapDetails';

const START_LOAD = 'routes/stopMapDetails/START_LOAD';
const COMPLETE_LOAD = 'routes/stopMapDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/stopMapDetails/FAIL_LOAD';
const RESET = 'routes/stopMapDetails/RESET';

interface State {
  isLoading: boolean;
  stopMapDetails?: StopMapDetails;
}

const initialState: State = {
  isLoading: false,
  stopMapDetails: undefined,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          stopMapDetails: action.stopMapDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          stopMapDetails: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (stopMapDetails: StopMapDetails) => ({
  type: COMPLETE_LOAD,
  stopMapDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadStopMapDetails =
  (vendorId: number, routeId: number, routeLocationId: number) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadStopMapDetailsPromise = doLoadStopMapDetails(vendorId, routeId, routeLocationId);
    loadStopMapDetailsPromise
      .then(stopMapDetails => dispatch(completeLoad(stopMapDetails)))
      .catch(() => dispatch(failLoad()));
    return loadStopMapDetailsPromise;
  };

export const resetStopMapDetails = () => ({
  type: RESET,
});
