import React from 'react';

import { size } from 'lodash-es';

import { UnconnectedDaysOfWeekPicker } from '../../core/components';
import {
  DetailsListBodySection,
  DetailsListItemDescription,
  DetailsListItemTitle,
  Popover,
} from '../../core/components/styled';
import translate from '../../core/services/translate';
import { PopoverDetailsContainer } from '../../fleet/components/styled/RubiconDispatches';
import { ServiceSummary } from './pages/ServiceChangeRequestsTableRow';

const renderDetail = (translation: string, value: string | React.ReactElement) => (
  <DetailsListBodySection>
    <DetailsListItemTitle size="small">{translate(translation)}</DetailsListItemTitle>
    <DetailsListItemDescription size="small" weight="normal">
      {value}
    </DetailsListItemDescription>
  </DetailsListBodySection>
);

const ServiceDetailsPopover: React.SFC<{ serviceSummary: ServiceSummary; title: string; }> = ({
  serviceSummary,
  title,
}) => (
  <Popover>
    <DetailsListItemDescription weight="medium" align="left" size="medium">
      {title}
    </DetailsListItemDescription>
    <div>
      <PopoverDetailsContainer>
        {renderDetail(
          'common.equipmentType',
          serviceSummary.equipmentSubType
            ? translate(`haulerProfile.equipments.equipmentSubTypes.${serviceSummary.equipmentSubType}`)
            : serviceSummary.equipmentName,
        )}
        {renderDetail('opportunity.frequency', serviceSummary.frequency || '-')}
      </PopoverDetailsContainer>
      {!!size(serviceSummary.frequencyDays) && (
        <PopoverDetailsContainer>
          {renderDetail(
            'opportunity.dayOfService',
            (
              <UnconnectedDaysOfWeekPicker
                selectedWeekdays={serviceSummary.frequencyDays}
                isReadOnly
                multiple
                dayOfWeekProps={{ margin: "no xSmall no no'" }}
                daysOfWeekProps={{ margin: 'no' }}
              />
            ) || '-',
          )}
        </PopoverDetailsContainer>
      )}
      <PopoverDetailsContainer>
        {renderDetail(
          'customers.equipmentSize',
          serviceSummary.equipmentSize ? `${serviceSummary.equipmentSize} ${serviceSummary.equipmentUnits}` : '-',
        )}
        {renderDetail('customers.wasteMaterial', serviceSummary.material || '-')}
      </PopoverDetailsContainer>
      <PopoverDetailsContainer>
        {renderDetail('opportunity.quantity', `${serviceSummary.quantity || 0} Container(s)`)}
        {renderDetail('payment.serviceDescription', serviceSummary.serviceDescription)}
      </PopoverDetailsContainer>
    </div>
  </Popover>
);

export default ServiceDetailsPopover;
