import React from 'react';

import { size } from 'lodash-es';
import { UnconnectedDaysOfWeekPicker } from '../../core/components';
import { TableCell, TableRow, Text } from '../../core/components/styled';
import translate from '../../core/services/translate';
import { SERVICE_FREQUENCIES } from '../constants';

interface Props {
  equipmentSizes: any[];
  requestedChangeService: any;
}

const RequestChangeInformation: React.FC<Props> = ({ equipmentSizes, requestedChangeService }) => {
  const equipmentService = equipmentSizes.filter(c => c.svcCode === requestedChangeService.equipmentId);
  const serviceDescription = size(equipmentService) ? equipmentService[0].svcDescription : '-';
  return (
    <TableRow borderBottom="0px">
      <TableCell vertical padding="no no no xSmall" width="230px">
        <Text block margin="no no no" weight="light" size="small" color="grayDarker">
          {translate('routes.editJobStatus.equipmentTypeSize')}
        </Text>
        <Text margin="no no no" weight="normal" size="small">
          {serviceDescription}
        </Text>
      </TableCell>
      <TableCell vertical padding="no no no xSmall" width="170px">
        <Text block margin="no no no" weight="light" size="small" color="grayDarker">
          {translate('opportunity.frequency')}
        </Text>
        <Text margin="no no no" weight="normal" size="small">
          {SERVICE_FREQUENCIES[requestedChangeService?.frequencyId]?.name}
        </Text>
      </TableCell>
      <TableCell vertical padding="no no no xSmall" width="170px">
        <Text block margin="no no no" weight="light" size="small" color="grayDarker">
          {translate('opportunity.dayOfService')}
        </Text>
        <UnconnectedDaysOfWeekPicker
          selectedWeekdays={requestedChangeService.frequencyDays}
          isReadOnly
          multiple
          dayOfWeekProps={{ margin: "no xSmall no no'" }}
          daysOfWeekProps={{ margin: 'no' }}
        />
      </TableCell>

      <TableCell vertical padding="no no no xSmall" width="170px">
        <Text block margin="no xSmall no" weight="light" size="small" color="grayDarker">
          {translate('opportunity.quantity')}
        </Text>
        <Text margin="no small no" weight="normal" size="small">
          {requestedChangeService.quantity}
        </Text>
      </TableCell>
    </TableRow>
  );
};

export default RequestChangeInformation;
