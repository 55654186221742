import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { orderBy } from 'lodash-es';

import { AppState } from '../../../store';
import { currentVendorGusIdSelector, currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Districts } from '../../../vendors/interfaces/DivisionAccess';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import EquipmentsPage from './EquipmentsPage';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { loadEquipments, loadMaterials } from '../../ducks';
import { loadHaulerProfileDivisions } from '../../ducks/divisions';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  gusId?: string;
  vendorId: number;
  loadEquipments: DuckFunction<typeof loadEquipments>;
  loadHaulerProfileDivisions: DuckFunction<typeof loadHaulerProfileDivisions>;
  loadMaterials: DuckFunction<typeof loadMaterials>;
}

class EquipmentsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { gusId, vendorId, loadEquipments, loadHaulerProfileDivisions, loadMaterials } = this.props;
    if (!gusId) return Promise.reject();
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    return divisionIdFromCookie
      ? Promise.all([
          loadEquipments(gusId, divisionIdFromCookie),
          loadMaterials(gusId, divisionIdFromCookie),
          loadHaulerProfileDivisions(vendorId),
        ])
      : loadHaulerProfileDivisions(vendorId).then((response: Districts[]) => {
          const allDivisions = response;
          if (allDivisions.length > 0) {
            const assignedDivisionsOrderByName = orderBy(allDivisions, 'name', 'asc');
            const divisionId = assignedDivisionsOrderByName[0].value;
            Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, {
              expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
            });
            return Promise.all([loadEquipments(gusId, divisionId), loadMaterials(gusId, divisionId)]);
          }
        });
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('haulerProfile.equipments.title')}</DocumentTitle>
        <Resolver resolve={this.loadDependencies} successComponent={EquipmentsPage} loadingComponent={PageLoading} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadEquipments,
  loadHaulerProfileDivisions,
  loadMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentsPageResolver);
