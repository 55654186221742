import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPristine } from 'redux-form';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { saveRouteBuilderSettings } from '../../ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';
import { routeBuilderSettingsFormInitialValues } from '../../services/routeBuilderSettingsFormInitialValuesSelector';
import { ROUTE_BUILDER_SETTINGS_FORM_NAME } from '../../constants/routeSettingsForm';
import RouteBuilderForm from '../forms/RouteBuilderForm';
import { RouteSettings } from '../../interfaces/RouteSettings';

interface Props {
  closeModal(pristine: boolean): void;
}

const RouteBuilderModal: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector((state: AppState) => currentVendorId(state));
  const initialValues = useSelector((state: AppState) => routeBuilderSettingsFormInitialValues(state));
  const formPristine = useSelector((state: AppState) => isPristine(ROUTE_BUILDER_SETTINGS_FORM_NAME)(state));

  const onSubmit = React.useCallback(
    (settings: RouteSettings) => {
      if (!vendorId) {
        return;
      }

      saveRouteBuilderSettings(
        vendorId,
        settings,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('vendors.alertMessages.routeBuilderSettingsSaved'));
          closeModal(true);
        })
        .catch(({ code }) => {
          createErrorNotification(
            code
              ? translate(createTranslationKey(code, 'vendors.alertMessages'))
              : translate('vendors.alertMessages.routeBuilderSettingsError'),
          );
        });
    },
    [vendorId, closeModal, dispatch],
  );

  const tryCloseModal = React.useCallback(() => {
    closeModal(formPristine);
  }, [closeModal, formPristine]);

  return (
    <Modal padding="no" size="smallMedium" onClose={tryCloseModal}>
      <ModalSection padding="medium no no">
        <ModalTitle>{translate('vendors.featureCodes.routeBuilder')}</ModalTitle>
      </ModalSection>

      <ModalSection>
        <RouteBuilderForm initialValues={initialValues} onSubmit={onSubmit} />
      </ModalSection>
    </Modal>
  );
};

export default RouteBuilderModal;
