import { RouteComponentProps } from 'react-router';
import moment from 'moment';

import { TableCell, TableRow } from 'src/core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

interface Props extends RouteComponentProps {
  addedBy: string;
  addedOn: Date | string;
  inventory: number;
  operationType: TechnicalType;
  quantity: number;
}

function LowInventoryPartHistoryTableRow({ addedOn, addedBy, operationType, quantity, inventory }: Props) {
  return (
    <TableRow>
      <TableCell width="15%">{moment(addedOn).format('MM/DD/YYYY')}</TableCell>
      <TableCell width="25%">{addedBy}</TableCell>
      <TableCell width="20%">
        {translate(createTranslationKey(operationType.technicalName, 'containers.operationType'))}
      </TableCell>
      <TableCell width="20%">{quantity}</TableCell>
      <TableCell width="20%">{inventory}</TableCell>
    </TableRow>
  );
}

export default LowInventoryPartHistoryTableRow;
