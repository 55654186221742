import humps from 'humps';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER, ROUTE_MAP_VEHICLE_POSITIONS_LAYER } from '../constants';
import { RouteMapVehiclePosition } from 'src/routes/interfaces/RouteMapVehicleData';
import { VehiclePosition } from 'src/routes/components/mapWithTimeline/Interfaces';
import VEHICLE_ICONS, { VEHICLE_ICON_TYPES } from 'src/common/constants/vehicleIcons';

export type VehiclePositionFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getVehicleIconTypeId = (
  vehiclePosition: RouteMapVehiclePosition | VehiclePosition,
  vehicleTypeName?: string,
) => {
  const camelizedVehicleTypeName = humps.camelize(vehicleTypeName || '');
  const normalizedVehicleTypeName = !!VEHICLE_ICONS[camelizedVehicleTypeName] ? camelizedVehicleTypeName : 'vehicle';
  const iconId = `${normalizedVehicleTypeName}${
    (vehiclePosition.isStationary && 'Stationary') || (!vehiclePosition.isActive && 'Inactive') || ''
  }`;
  const iconTypeId = VEHICLE_ICON_TYPES.find(type => type.id === iconId)?.id;
  return iconTypeId;
};

export const getVehiclePositionsGeoJSON = (
  vehiclePositions: any,
  vehicleTypeName?: string,
  isPlaybackMode?: boolean,
) => {
  return getFeatureCollection<GeoJSON.Point, VehiclePositionFeatureProperties>(
    vehiclePositions.map((vehiclePosition: any) =>
      getPointFeature(vehiclePosition.vehicleId, [vehiclePosition.longitude, vehiclePosition.latitude], {
        id: vehiclePosition.vehicleId,
        clickable: true,
        layer: isPlaybackMode ? ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER : ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
        icon: getVehicleIconTypeId(vehiclePosition, vehicleTypeName),
      }),
    ),
  );
};

export const getYVehicleIconTypeId = (vehiclePosition: VehiclePosition, vehicleTypeName?: string) => {
  const camelizedVehicleTypeName = humps.camelize(vehicleTypeName || '');
  const normalizedVehicleTypeName = !!VEHICLE_ICONS[camelizedVehicleTypeName] ? camelizedVehicleTypeName : 'vehicle';
  const iconId = `${normalizedVehicleTypeName}${
    (vehiclePosition.isStationary && 'Stationary') || (!vehiclePosition.isActive && 'Inactive') || ''
  }`;
  const iconTypeId = VEHICLE_ICON_TYPES.find(type => type.id === iconId)?.id;
  return iconTypeId;
};

export const getYVehiclePositionsGeoJSON = (
  vehiclePositions: VehiclePosition[],
  vehicleTypeName?: string,
  isPlaybackMode?: boolean,
) => {
  return getFeatureCollection<GeoJSON.Point, VehiclePositionFeatureProperties>(
    vehiclePositions.map(vehiclePosition =>
      getPointFeature(
        vehiclePosition.vehicle.id,
        [vehiclePosition.coordinates.longitude, vehiclePosition.coordinates.latitude],
        {
          id: vehiclePosition.vehicle.id,
          clickable: true,
          layer: isPlaybackMode ? ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER : ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
          icon: getYVehicleIconTypeId(vehiclePosition, vehicleTypeName),
        },
      ),
    ),
  );
};
