import moment from 'moment';

import { APRIL_ID, FEBRUARY_ID, JUNE_ID, NOVEMBER_ID, SEPTEMBER_ID } from 'src/common/constants/months';
import { BulkyItemScheduler } from '../interfaces/BulkyItemScheduler';
import { dateFormat } from 'src/utils/services/validator';
import translate from 'src/core/services/translate';

export const isValidEndMonthBlackOut = (value: number, formData: BulkyItemScheduler, _: any, name: string) => {
  if (name && formData.bulkySchedulerBlackoutSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;
    const startMonth =
      formData.bulkySchedulerBlackoutSettings[index as number].blackoutSettings.descriptiveBeginMonth || 0;

    return value >= startMonth ? undefined : translate('common.validationMessages.isBeforeStartMonth');
  }
};

export const isValidEndMonthSeasonal = (value: number, formData: BulkyItemScheduler, _: any, name: string) => {
  if (name && formData.bulkySchedulerSeasonalPickUpSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;
    const startMonth =
      formData.bulkySchedulerSeasonalPickUpSettings[index as number].seasonalPickUpSettings.descriptiveBeginMonth || 0;

    return value >= startMonth ? undefined : translate('common.validationMessages.isBeforeStartMonth');
  }
};

export const isValidEndDayBlackOut = (value: number, formData: BulkyItemScheduler, _: any, name: string) => {
  if (name && formData.bulkySchedulerBlackoutSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;
    const startMonth =
      formData.bulkySchedulerBlackoutSettings[index as number].blackoutSettings.descriptiveBeginMonth || 0;
    const endMonth = formData.bulkySchedulerBlackoutSettings[index as number].blackoutSettings.descriptiveEndMonth || 0;
    const startDayOfWeek =
      formData.bulkySchedulerBlackoutSettings[index as number].blackoutSettings.descriptiveBeginDayOfWeek || 0;

    return startMonth >= endMonth
      ? value >= startDayOfWeek
        ? undefined
        : translate('common.validationMessages.isBeforeStartDay')
      : undefined;
  }
};

export const isValidEndDaySeasonal = (value: number, formData: BulkyItemScheduler, _: any, name: string) => {
  if (name && formData.bulkySchedulerSeasonalPickUpSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;
    const startMonth =
      formData.bulkySchedulerSeasonalPickUpSettings[index as number].seasonalPickUpSettings.descriptiveBeginMonth || 0;
    const endMonth =
      formData.bulkySchedulerSeasonalPickUpSettings[index as number].seasonalPickUpSettings.descriptiveEndMonth || 0;
    const startDayOfWeek =
      formData.bulkySchedulerSeasonalPickUpSettings[index as number].seasonalPickUpSettings.descriptiveBeginDayOfWeek ||
      0;

    return startMonth >= endMonth
      ? value >= startDayOfWeek
        ? undefined
        : translate('common.validationMessages.isBeforeStartDay')
      : undefined;
  }
};

export const isValidDayOfMonth = (value: number, month: number) => {
  const monthsWith30Days = [APRIL_ID, JUNE_ID, SEPTEMBER_ID, NOVEMBER_ID];
  const monthsWith28Days = [FEBRUARY_ID];
  const lastDayOfMonth = 31;
  const lastDayOfMonthFebruary = 28;

  if (
    value &&
    ((value === lastDayOfMonth && monthsWith30Days.indexOf(month) !== -1) ||
      (value > lastDayOfMonthFebruary && monthsWith28Days.indexOf(month) !== -1))
  ) {
    return translate('common.validationMessages.dayDoesntExistInMonth');
  }
};

export const isBeforeStartDateSeasonalSettings = (
  value: number,
  formData: BulkyItemScheduler,
  _: any,
  name: string,
) => {
  if (name && formData.bulkySchedulerSeasonalPickUpSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;

    const specificBeginDate =
      formData.bulkySchedulerSeasonalPickUpSettings[index as number]?.seasonalPickUpSettings?.specificBeginDate;
    const specificEndDate = value;

    if (
      moment(specificEndDate, dateFormat).isBefore(moment(specificBeginDate), 'day') ||
      moment(specificEndDate).isSame(specificBeginDate, 'day')
    )
      return translate('common.validationMessages.isBeforeOrSameStartDay');
  }
};

export const isBeforeStartDateBlackoutSttings = (value: number, formData: BulkyItemScheduler, _: any, name: string) => {
  if (name && formData.bulkySchedulerBlackoutSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;

    const specificBeginDate =
      formData.bulkySchedulerBlackoutSettings[index as number]?.blackoutSettings?.specificBeginDate;
    const specificEndDate = value;

    if (
      moment(specificEndDate, dateFormat).isBefore(moment(specificBeginDate), 'day') ||
      moment(specificEndDate).isSame(specificBeginDate, 'day')
    )
      return translate('common.validationMessages.isBeforeOrSameStartDay');
  }
};

export const isBeforeStartDateMaterialSettings = (
  value: number,
  formData: BulkyItemScheduler,
  _: any,
  name: string,
) => {
  if (name && formData.materialPickupSettings) {
    const fieldName = name.match(/\[(.*?)\]/);
    const index = fieldName ? fieldName[1] : 0;

    const subsetFieldName = fieldName?.input?.split('.')[1].match(/\[(.*?)\]/);
    const subsetIndex = subsetFieldName ? subsetFieldName[1] : 0;

    const startFrom = formData.materialPickupSettings[index as number]?.subsetItems[subsetIndex as number]?.startFrom;
    const endDate = value;

    if (moment(endDate, dateFormat).isBefore(moment(startFrom), 'day'))
      return translate('common.validationMessages.isBeforeStartDay');
  }
};
