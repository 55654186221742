import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Field as FieldUntyped, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';

import { TechnicalType } from '../../../../common/interfaces/TechnicalType';
import { DatePicker, Dropdown, UnconnectedCheckbox } from '../../../../core/components';
import { Button, Grid, GridColumn as GridColumnUntyped } from '../../../../core/components/styled';
import { MAX_NUMBER_ASSIGNABLE_JOBS, TODAY_FORMATTED } from '../../../../core/constants';
import translate from '../../../../core/services/translate';
import { DELIVERY_UTILITY_ID, FRONT_LOAD_ID, RESIDENTIAL_ID, ROLL_OFF_ID } from '../../../../fleet/constants';
import { AppState } from '../../../../store';
import focusFirstInvalidField from '../../../../utils/services/focusFirstInvalidField';
import { isDateValidValidator, isRequired } from '../../../../utils/services/validator';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { clearRoutes, loadRouteNames } from '../../../ducks/dispatchBoard';
import { ExceptionManagerJobRouteInfo } from '../../../interfaces/ExceptionManagerJob';
import { AllSelectorWrapper, AssignToInfo, FormWrapper } from '../../styled/ExceptionManager';
import { wasteTypeOptionMapper } from './WasteTypesMultiSelect';
import { DISPATCH_BOARD_FORM_NAME } from 'src/routes/constants/dispatchBoard';
import { getComboRouteOptions, getRequiredOptions } from '../utils/CustomOptionsUtils';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';

const Field = FieldUntyped as any;
interface RouteChoice {
  label: string;
  value: ExceptionManagerJobRouteInfo;
}

interface CommonProps {
  isLoadingRoutes: boolean;
  wasteTypes: any[];
}

interface ActualFormProps extends CommonProps {
  handleDateFilterChange: (date: Date | string) => void;
  routes?: RouteChoice[];
  vehicleTypeId?: number;
  destination?: any;
}

const GridColumn = GridColumnUntyped as any;

const ActualFormComponent: React.FC<ActualFormProps & InjectedFormProps<{}, ActualFormProps>> = ({
  handleSubmit,
  routes,
  isLoadingRoutes,
  handleDateFilterChange,
  wasteTypes,
  vehicleTypeId,
  destination,
}) => {
  const isInsertPositionEnabled =
    destination && destination.name !== translate('dispatchBoard.exceptionsDispatcher.setAsUnassigned');
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Grid>
          <GridColumn size="3/12">
            <Field
              name="date"
              component={DatePicker}
              label={translate('common.date')}
              onChange={(date: any) => handleDateFilterChange(date)}
              margin="no"
              disabledDays={[{ before: new Date() }]}
              validate={[isRequired, isDateValidValidator]}
            />
          </GridColumn>
          <GridColumn size="4/12">
            <Field
              disabled={isLoadingRoutes}
              name="route"
              component={Dropdown}
              defaultValue={unassignedRoute}
              label={translate('dispatchBoard.exceptionsDispatcher.destination')}
              dropdownProps={{
                isClearable: true,
                margin: 'no',
              }}
              options={routes || []}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="3/12">
            {vehicleTypeId === DELIVERY_UTILITY_ID && (
              <Field
                name="wasteMaterialType"
                component={Dropdown}
                label={translate('common.wasteType')}
                dropdownProps={{ isClearable: true, margin: 'no' }}
                validate={[isRequired]}
                options={wasteTypes}
              />
            )}
          </GridColumn>

          <GridColumn size="2/12">
            {isInsertPositionEnabled && (
              <Field
                isOptimizedOptionHidden={vehicleTypeId === ROLL_OFF_ID}
                withLabel
                name="positionTypeId"
                component={JobPositionTypeDropdown as any}
                validate={[isRequired]}
                dropdownProps={{ margin: 'no' }}
              />
            )}
          </GridColumn>

          <Button type="submit" line color="primary">
            {translate('dispatchBoard.exceptionsDispatcher.assign')}
          </Button>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const ActualForm = reduxForm<{}, ActualFormProps>({
  form: 'dispatchBoardExceptionDispatcherAsignerForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(ActualFormComponent);

const unassignedRoute = {
  label: translate('dispatchBoard.exceptionsDispatcher.setAsUnassigned'),
  value: { name: translate('dispatchBoard.exceptionsDispatcher.setAsUnassigned') },
};

interface DispatchBoardExceptionManagerAssignerFormProps extends CommonProps {
  allSelected: boolean;
  assignedNumberOfJobs: number;
  checkedNumber: number;
  clearRoutes: () => void;
  handleCheckAll: (allChecked: boolean) => void;
  isLoadingRoutes: boolean;
  loadRouteNames: (vendorId: number, vehicleTypeId: number, date: Date | string) => void;
  newAssignedNumberOfJobs: number;
  onAssign: (
    date: Date | string,
    route: ExceptionManagerJobRouteInfo,
    wasteMaterialType: TechnicalType,
    positionTypeId: number,
  ) => void;
  partial: boolean;
  routes?: ExceptionManagerJobRouteInfo[];
  vehicleTypeId: number;
  vendorId: number;
  wasteTypes: any[];
  destination?: any;
}

const DispatchBoardExceptionManagerAssignerForm: React.FC<DispatchBoardExceptionManagerAssignerFormProps> = ({
  allSelected,
  assignedNumberOfJobs,
  checkedNumber,
  clearRoutes,
  handleCheckAll,
  isLoadingRoutes,
  loadRouteNames,
  newAssignedNumberOfJobs,
  onAssign,
  partial,
  routes,
  vehicleTypeId,
  vendorId,
  wasteTypes,
  destination,
}) => {
  useEffect(() => {
    loadRouteNames(vendorId, vehicleTypeId, TODAY_FORMATTED);
    // eslint-disable-next-line
  }, [partial]);

  const handleDateFilterChange = (date: any) => {
    loadRouteNames(vendorId, vehicleTypeId, date);
  };

  const getOptions = () => {
    let options = [] as any[];
    if (routes) {
      options =
        vehicleTypeId === FRONT_LOAD_ID || vehicleTypeId === RESIDENTIAL_ID || vehicleTypeId === DELIVERY_UTILITY_ID
          ? getComboRouteOptions(vehicleTypeId, routes)
          : getRequiredOptions(vehicleTypeId, routes);
      options.unshift(unassignedRoute);
    }
    return options;
  };

  const assign = ({ date, route, wasteMaterialType, positionTypeId }: any) => {
    onAssign(date, route, wasteMaterialType, positionTypeId);
    handleCheckAll(false);
    clearRoutes();
  };

  const totalNumberOfAssignments = assignedNumberOfJobs + newAssignedNumberOfJobs;

  return (
    <FormWrapper>
      <AllSelectorWrapper>
        <UnconnectedCheckbox
          margin="sMedium no no"
          onChange={(ev: any) => handleCheckAll(ev.target.checked)}
          checked={allSelected}
          partial={partial}
        />
      </AllSelectorWrapper>

      {(allSelected || partial) &&
        (totalNumberOfAssignments > MAX_NUMBER_ASSIGNABLE_JOBS ? (
          translate('dispatchBoard.exceptionsDispatcher.maxNumberExceeded', {
            maxNumberOfJobs: MAX_NUMBER_ASSIGNABLE_JOBS,
            extraNumberOfJobs: totalNumberOfAssignments - MAX_NUMBER_ASSIGNABLE_JOBS,
          })
        ) : (
          <React.Fragment>
            <AssignToInfo>
              {translate('dispatchBoard.exceptionsDispatcher.assignXJobsTo', { amountOfStops: checkedNumber })}
            </AssignToInfo>
            <ActualForm
              handleDateFilterChange={handleDateFilterChange}
              initialValues={{ date: TODAY_FORMATTED, route: unassignedRoute.value }}
              isLoadingRoutes={isLoadingRoutes}
              onSubmit={assign}
              routes={getOptions()}
              vehicleTypeId={vehicleTypeId}
              wasteTypes={wasteTypes}
              destination={destination}
            />
          </React.Fragment>
        ))}
    </FormWrapper>
  );
};

const dBoardFormSelector = formValueSelector(DISPATCH_BOARD_FORM_NAME);

const asignFormSelector = formValueSelector('dispatchBoardExceptionDispatcherAsignerForm');

const wasteTypesForExceptionDispatchSelector = createSelector(
  (state: AppState) => (state.common.wasteTypes.wasteTypes || []) as TechnicalType[],
  wasteTypes => wasteTypeOptionMapper(wasteTypes, true),
);

const mapStateToProps = (state: AppState) => ({
  routes: state.routes.dispatchBoard.exceptions.routes,
  isLoadingRoutes: state.routes.dispatchBoard.exceptions.isLoadingRoutes,
  vehicleTypeId: dBoardFormSelector(state, 'vehicleTypeId'),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  wasteTypes: wasteTypesForExceptionDispatchSelector(state),
  destination: asignFormSelector(state, 'route'),
});

const mapDispatchToProps = {
  loadRouteNames,
  clearRoutes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchBoardExceptionManagerAssignerForm);
