import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { defaultsDeep } from 'lodash-es';

import {
  HorizontalBarChartWrapper,
  HorizontalBarChartDrawingWrapper,
  HorizontalBarChartTitle,
} from './styled/HorizontalBarChartStyles';

const horizontalBarChartOptions = {
  cutoutPercentage: 75,
  responsive: false,
  maintainAspectRatio: false,
  aspectRatio: 1,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
        },
        ticks: {
          fontStyle: 'normal',
          fontSize: 10,
          min: 0,
          precision: 0,
        },
      },
    ],
    yAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontStyle: 'normal',
          fontSize: 12,
          mirror: true,
          padding: -5,
        },
      },
    ],
  },
};

interface DataSet {
  data: number[];
  backgroundColor?: string[];
  borderColor?: string[];
  borderWidth?: number;
  borderSkipped?: false | 'bottom' | 'left' | 'top' | 'right';
  hoverBackgroundColor?: string[];
  hoverBorderColor?: string[];
  hoverBorderWidth?: number;
  label?: string;
  order?: number;
  xAxisId?: string;
  yAxisId?: string;
}

interface Props {
  dataset?: DataSet;
  datasets?: DataSet[];
  labels?: string[];
  chartWidth?: number;
  chartHeight?: number;
  title?: string;
  titleSize?: number;
  options?: {
    cutoutPercentage?: number;
    responsive?: boolean;
    maintainAspectRatio?: boolean;
    aspectRatio?: number;
    tooltips?: {
      enabled?: boolean;
      backgroundColor?: string;
      usePointStyle?: boolean;
      boxWidth?: number;
      borderWidth?: number;
    };
  };
}

const HorizontalBarChart: React.FC<Props> = ({
  dataset,
  datasets: rawDatasets,
  labels,
  options,
  title,
  titleSize,
  chartWidth = 200,
  chartHeight = 90,
}) => {
  const datasets = React.useMemo(
    () => (!rawDatasets && !!dataset ? [dataset] : rawDatasets || []),
    [dataset, rawDatasets],
  );

  return (
    <HorizontalBarChartWrapper>
      {!!title && <HorizontalBarChartTitle size={titleSize}>{title}</HorizontalBarChartTitle>}

      <HorizontalBarChartDrawingWrapper width={chartWidth} height={chartHeight}>
        <HorizontalBar
          width={chartWidth}
          height={chartHeight}
          data={{
            datasets,
            labels,
          }}
          options={defaultsDeep(options, horizontalBarChartOptions)}
        />
      </HorizontalBarChartDrawingWrapper>
    </HorizontalBarChartWrapper>
  );
};

export default HorizontalBarChart;
