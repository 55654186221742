import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { loadContacts } from '../../ducks';
import { ManagePhoneNumbersModal } from './';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  closeModal: () => void;
  gusId: string;
  loadContacts: DuckFunction<typeof loadContacts>;
}

function ManagePhoneNumbersModalResolver({
  closeModal,
  gusId,
  loadContacts,
  match: {
    params: { token },
  },
}: Props) {
  return (
    <Fragment>
      <DocumentTitle>{translate('autoDispatch.managePhoneNumbers')}</DocumentTitle>
      <Resolver
        resolve={() => loadContacts(token, gusId)}
        successComponent={ManagePhoneNumbersModal}
        loadingComponent={PageLoadingOverlay}
        successProps={{
          closeModal,
        }}
      />
    </Fragment>
  );
}

const mapStateToProps = (state: AppState) => ({
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadContacts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagePhoneNumbersModalResolver));
