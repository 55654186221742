import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadFuelTicketUnitOfMeasure, loadFuelTickets, loadFuelingStation } from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import FuelTicketsModal from './FuelTicketsModal';

interface FuelTicketsModalResolverProps {
  closeModal: () => void;
  modalTitle?: string;
  routeId: number;
  routeLocationId?: number;
}

const FuelTicketsModalResolver = ({ routeId, closeModal, modalTitle }: FuelTicketsModalResolverProps) => {
  const vendorId = useSelector(currentVendorId);

  const dispatch = useDispatch();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    return Promise.all([
      loadFuelTickets(routeId)(dispatch),
      loadFuelingStation(vendorId)(dispatch),
      loadFuelTicketUnitOfMeasure()(dispatch),
    ]);
  };

  return (
    <TypedResolver
      successComponent={FuelTicketsModal}
      successProps={{
        closeModal,
        modalTitle,
      }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default FuelTicketsModalResolver;
