import update from 'immutability-helper';
import { find, get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  loadVehicleTypesForPickupExceptions as doLoadVehicleTypesForPickupExceptions,
  loadVehicleTypesForExceptionManagement as doLoadVehicleTypesForExceptionManagement,
} from '../services/loadVehicleTypesForExceptionConfiguration';

// Actions
const START_LOAD = 'vendors/vehicleTypesForExceptionConfiguration/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicleTypesForExceptionConfiguration/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicleTypesForExceptionConfiguration/FAIL_LOAD';
const RESET = 'vendors/vehicleTypesForExceptionConfiguration/RESET';

// Initial state =
const initialState = {
  isLoading: false,
  vehicles: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: action.vehicles,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicles: any) => ({
  type: COMPLETE_LOAD,
  vehicles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadVehicleTypesForPickupExceptions =
  (vendorId: number, showActiveTrucks: boolean = false) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadVehiclesPromise = (doLoadVehicleTypesForPickupExceptions as any)(vendorId, showActiveTrucks);
    loadVehiclesPromise.then((vehicles: any) => dispatch(completeLoad(vehicles))).catch(() => dispatch(failLoad()));
    return loadVehiclesPromise;
  };

export const loadVehicleTypesForExceptionManagement =
  (vendorId: number, showActiveTrucks: boolean = false) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadVehiclesPromise = (doLoadVehicleTypesForExceptionManagement as any)(vendorId, showActiveTrucks);
    loadVehiclesPromise.then((vehicles: any) => dispatch(completeLoad(vehicles))).catch(() => dispatch(failLoad()));
    return loadVehiclesPromise;
  };

export const resetVehicleTypesForExceptionConfiguration = () => ({
  type: RESET,
});

const getTechnicalNameByVehicleTypeId = (vehicleTypesForExceptionConfigurationState: any, vehicleTypeId: number) => {
  const vehicleType = find(vehicleTypesForExceptionConfigurationState.vehicles, { id: vehicleTypeId });
  return get(vehicleType, 'technicalName');
};

export const technicalNameByVehicleTypeIdSelector = createSelector(getTechnicalNameByVehicleTypeId, identity);
