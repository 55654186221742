import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from 'src/utils/services/queryParams';

const getUnbilledChargesFilterFormInitialValuesSelector = (queryString: string) => {
  const { unbilledSearchTerm, workOrderTypeId, containerTypeId } = getQueryParams(queryString);
  return {
    unbilledSearchTerm,
    workOrderTypeId,
    containerTypeId,
  };
};

const unbilledChargesFilterFormInitialValuesSelector = createSelector(
  getUnbilledChargesFilterFormInitialValuesSelector,
  identity,
);

export default unbilledChargesFilterFormInitialValuesSelector;
