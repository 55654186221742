import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { SnowRoadSegmentCondition, SnowRoadSegmentConditionUpdates } from '../interfaces/snowRoadConditions';
import { updateSnowRoadConditions } from '../services/snowRoadConditionsTransformers';
import {
  loadStreetSweeperConditions as doLoadStreetSweeperConditions,
  loadStreetSweeperConditionUpdates as doLoadStreetSweeperConditionUpdates,
} from '../services/streetSweeperConditions';
/**
 * Actions
 */
const SET_CONDITIONS_LOADING = 'dashboard/streetSweeperConditions/SET_CONDITIONS_LOADING';
const SET_CONDITIONS = 'dashboard/streetSweeperConditions/SET_CONDITIONS';
const RESET = 'dashboard/streetSweeperConditions/RESET';

/**
 * Initial state
 */
interface StreetSweeperConditionsState {
  isLoading: boolean;
  streetSweeperConditions: SnowRoadSegmentCondition[];
}

const initialState: StreetSweeperConditionsState = {
  isLoading: false,
  streetSweeperConditions: [],
};

/**
 * Reducer
 * @param state
 * @param action
 */
export const streetSweeperConditionsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_CONDITIONS_LOADING:
      return update(state, {
        $merge: {
          isLoading: action.loading,
        },
      });

    case SET_CONDITIONS:
      return update(state, {
        $merge: {
          streetSweeperConditions: !action.updatesOnly
            ? action.conditions
            : updateSnowRoadConditions(state.streetSweeperConditions, action.conditions),
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

/**
 * Action creators
 */
const setConditionsLoading = (loading: boolean) => ({
  type: SET_CONDITIONS_LOADING,
  loading,
});

const setConditions = (conditions: SnowRoadSegmentCondition[]) => ({
  type: SET_CONDITIONS,
  conditions,
});

const setConditionUpdates = (conditions: SnowRoadSegmentConditionUpdates[]) => ({
  type: SET_CONDITIONS,
  conditions,
  updatesOnly: true,
});

export const resetStreetSweeperConditions = () => ({
  type: RESET,
});

/**
 * Complex action creators
 */
export const loadStreetSweeperConditions = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(setConditionsLoading(true));

  const promise = doLoadStreetSweeperConditions(vendorId);

  promise
    .then(conditions => {
      dispatch(setConditions(conditions));
    })
    .finally(() => {
      dispatch(setConditionsLoading(false));
    });

  return promise;
};

export const loadStreetSweeperConditionUpdates = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(setConditionsLoading(true));

  const promise = doLoadStreetSweeperConditionUpdates(vendorId);

  promise
    .then(conditions => {
      dispatch(setConditionUpdates(conditions));
    })
    .finally(() => {
      dispatch(setConditionsLoading(false));
    });

  return promise;
};
