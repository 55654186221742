import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const BIN_NOT_OUT = 1;
export const BLOCKED = 2;
export const CONTAMINATED_UNACCEPTABLE = 3;
export const BULK_ITEM = 4;
export const BROKEN_BIN = 5;
export const OTHER = 6;
export const PICKED_UP = 7;
export const CONTAINER_BLOCKED = 8;
export const COULD_NOT_ENTER_PROPERTY = 9;
export const COULD_NOT_LOCATE_CONTAINER = 10;
export const CONTAINER_NOT_READY = 11;
export const DAMAGED_CONTAINER = 12;
export const COULD_NOT_REACH_ADDRESS = 13;
export const OVERFLOWING = 14;
export const COULD_NOT_ENTER_REACH_ADDRESS = 15;
export const CAR = 16;
export const OVERGROWN_TREE_BUSH = 17;
export const WASTE_CONTAINER = 18;
export const STREET_SIGN = 19;
export const PEDESTRIAN = 20;
export const EXTRA = 21;
export const MOVE_CONTAINER_FOR_ACCESS = 22;
export const OVERWEIGHT_CONTAINER = 23;
export const PREP = 24;
export const GATE = 25;
export const WAIT_TIME = 26;
export const LABOR_TIME = 27;
export const RETURN_TRIP = 28;
export const CONTAINER_REPLACEMENT = 29;
export const FROZEN_CONTAINER = 30;
export const BIN_NOT_OUT_PICKED_UP = -1;
export const LOCKED_CONTAINER = 31;
export const BEFORE = 32;
export const AFTER = 33;
export const ADDITIONAL_LOADS = 34;
export const PULL_OUT = 35;
export const CLEANING_FEE = 36;
export const OVERTURNED = 37;
export const OUT_OF_REACH = 38;
export const PROHIBITED_ITEM = 39;
export const OBSTRUCTION = 40;
export const APPLIANCE = 41;
export const DIFFERENT_TRUCK = 42;
export const ILLEGAL_DUMPING = 43;
export const ILLEGAL_DUMPING_SELF_CLEANED = 44;
export const ILLEGAL_DUMPING_REFERRAL = 45;
export const ILLEGAL_DUMPING_COLLECTED = 46;
export const ILLEGAL_DUMPING_NOT_FOUND = 47;
export const ILLEGAL_DUMPING_UNDER_REVIEW = 48;
export const APPLIANCE_VIOLATION = 49;
export const YARD_WASTE = 50;
export const BLOCKED_CONSTRUCTION = 51;
export const TIRES = 52;
export const FREON_APPLIANCE = 53;
export const YARD_WASTE_DELIVERY = 54;
export const DEAD_ANIMAL = 55;
export const BOBCAT = 56;
export const ABATEMENT = 57;
export const HHW = 58;
export const TRASH_BAGS = 59;
export const IMPROPER_STORAGE_AREA = 60;
export const DAMAGE_TO_PRIVATE_PROPERTY = 61;
export const STOP_ROUTE_TO_RELOAD_SALT = 62;
export const RETURN_TRIP_SNOW_PLOW = 63;
export const EXCESS_CONTAINERS = 64;
export const NOT_SERVICED_BY_CITY = 65;
export const EVICTION = 66;
export const CONTRACTOR_GENERATED = 67;
export const CITIZEN_ERROR = 68;
export const CREW_ERROR = 69;
export const INCORRECT_COLLECTION_DAY = 70;
export const INVALID_ADDRESS = 71;
export const LATE_SET_OUT = 72;
export const PET_PREVENTING_ACCESS = 73;
export const REFERRED_TO_AGENCY = 74;
export const ROUTE_INTERRUPTED = 75;
export const BLOCKED_SNOW_OR_ICE = 76;
export const WRONG_LOCATION = 77;
export const DUPLICATED = 78;
export const PLASTIC_BAGS = 79;
export const BLOCKED_STREET = 80;
export const BLOCKED_ALLEY = 81;
export const CALLED_TOO_EARLY = 82;
export const PICKUP_REQUEST_CANCELLED = 83;
export const PRIVATE_COLLECTION = 84;
export const CODE_ENFORCEMENT_NEEDED = 85;
export const OPEN_LID = 86;
export const HOLIDAY_TREE = 87;
export const METAL_PLATES = 88;
export const ILLEGAL_SETOUT = 89;
export const TRASH_BAG = 90;
export const ELECTRONICS = 91;
export const CARDBOARD = 92;
export const CRANE_NEEDED = 93;
export const NOT_COLLECTED = 94;
export const REFER_TO_311 = 95;
export const ROUTE_DELAYED = 96;
export const OUT_OF_JURISTICTION = 97;
export const HOT_LIST = 98;
export const ASSISTED_STOP = 99;
export const MISSED = 100;
export const MOVE_OUT = 101;
export const IMPROPER_SETOUT = 102;
export const UNBAGGED_TRASH = 103;
export const NON_CITY_ISSUED_CONTAINER = 104;
export const ANIMAL_NOT_FOUND = 105;
export const REFER_TO_PARKS = 106;
export const REFER_TO_WATERSHED = 107;
export const NO_ACTION_TAKEN = 108;
export const PICKED_UP_DIFFERENT_SIZE = 109;
export const INCORRECT_FA = 110;
export const DAMAGED_WARRANTY = 111;
export const WRONG_CART_TYPE = 112;
export const DEFER_TO_NEXT_CYCLE = 113;
export const REFER_TO_OTHER_AGENCY = 114;
export const REFER_TO_DOT = 115;
export const SWEEP_INCOMPLETE = 116;
export const STREET_CONSTRUCTION = 117;
export const CURB_OBSTRUCTION = 118;
export const UNCURBED = 119;
export const HAZARDOUS_MATERIALS = 120;
export const ON_PRIVATE_PROPERTY = 121;
export const HANDLED_BY_DIFFERENT_AGENCY = 122;
export const MANUAL_CREW_NEEDED = 123;
export const AUDIT = 124;
export const LOST_CART = 125;
export const REFILLED_BY_CUSTOMER = 126;

const issueTypes = [
  { id: BIN_NOT_OUT, name: translate('routes.pickupIssueTypes.binNotOut') },
  { id: BLOCKED, name: translate('routes.pickupIssueTypes.containerBlocked') },
  { id: CONTAMINATED_UNACCEPTABLE, name: translate('routes.pickupIssueTypes.contaminatedUnacceptable') },
  { id: BULK_ITEM, name: translate('routes.pickupIssueTypes.bulkItem') },
  { id: BROKEN_BIN, name: translate('routes.pickupIssueTypes.brokenBin') },
  { id: OTHER, name: translate('routes.pickupIssueTypes.other') },
  { id: PICKED_UP, name: translate('routes.pickupIssueTypes.pickedUp') },
  { id: CONTAINER_BLOCKED, name: translate('routes.pickupIssueTypes.containerBlocked') },
  { id: COULD_NOT_ENTER_PROPERTY, name: translate('routes.pickupIssueTypes.couldNotEnterProperty') },
  { id: COULD_NOT_LOCATE_CONTAINER, name: translate('routes.pickupIssueTypes.couldntLocateContainer') },
  { id: CONTAINER_NOT_READY, name: translate('routes.pickupIssueTypes.containerNotReadyPerCustomer') },
  { id: DAMAGED_CONTAINER, name: translate('routes.pickupIssueTypes.damagedContainer') },
  { id: COULD_NOT_REACH_ADDRESS, name: translate('routes.pickupIssueTypes.couldNotReachAddress') },
  { id: OVERFLOWING, name: translate('routes.pickupIssueTypes.overflowing') },
  { id: COULD_NOT_ENTER_REACH_ADDRESS, name: translate('routes.pickupIssueTypes.couldNotEnterReachAddress') },
  { id: CAR, name: translate('routes.pickupIssueTypes.car') },
  { id: OVERGROWN_TREE_BUSH, name: translate('routes.pickupIssueTypes.overgrownTreeBush') },
  { id: WASTE_CONTAINER, name: translate('routes.pickupIssueTypes.wasteContainer') },
  { id: STREET_SIGN, name: translate('routes.pickupIssueTypes.streetSign') },
  { id: PEDESTRIAN, name: translate('routes.pickupIssueTypes.pedestrian') },
  { id: EXTRA, name: translate('routes.pickupIssueTypes.extra') },
  { id: MOVE_CONTAINER_FOR_ACCESS, name: translate('routes.pickupIssueTypes.moveContainerForAccess') },
  { id: OVERWEIGHT_CONTAINER, name: translate('routes.pickupIssueTypes.overweightContainer') },
  { id: PREP, name: translate('routes.pickupIssueTypes.prep') },
  { id: GATE, name: translate('routes.pickupIssueTypes.gate') },
  { id: WAIT_TIME, name: translate('routes.pickupIssueTypes.waitTime') },
  { id: LABOR_TIME, name: translate('routes.pickupIssueTypes.laborTime') },
  { id: RETURN_TRIP, name: translate('routes.pickupIssueTypes.returnTrip') },
  { id: CONTAINER_REPLACEMENT, name: translate('routes.pickupIssueTypes.containerReplacement') },
  { id: FROZEN_CONTAINER, name: translate('routes.pickupIssueTypes.frozenContainer') },
  { id: BIN_NOT_OUT_PICKED_UP, name: translate('routes.pickupIssueTypes.binNotOutPickedUp') },
  { id: LOCKED_CONTAINER, name: translate('routes.pickupIssueTypes.lockedContainer') },
  { id: BEFORE, name: translate('routes.pickupIssueTypes.before') },
  { id: AFTER, name: translate('routes.pickupIssueTypes.after') },
  { id: ADDITIONAL_LOADS, name: translate('routes.pickupIssueTypes.additionalLoads') },
  { id: PULL_OUT, name: translate('routes.pickupIssueTypes.pullOut') },
  { id: CLEANING_FEE, name: translate('routes.pickupIssueTypes.cleaningFee') },
  { id: OVERTURNED, name: translate('routes.pickupIssueTypes.overturned') },
  { id: OUT_OF_REACH, name: translate('routes.pickupIssueTypes.outOfReach') },
  { id: PROHIBITED_ITEM, name: translate('routes.pickupIssueTypes.prohibitedItem') },
  { id: OBSTRUCTION, name: translate('routes.pickupIssueTypes.obstruction') },
  { id: APPLIANCE, name: translate('routes.pickupIssueTypes.appliance') },
  { id: DIFFERENT_TRUCK, name: translate('routes.pickupIssueTypes.differentTruck') },
  { id: ILLEGAL_DUMPING, name: translate('routes.pickupIssueTypes.illegalDumping') },
  { id: ILLEGAL_DUMPING_SELF_CLEANED, name: translate('routes.pickupIssueTypes.illegalDumpingSelfCleaned') },
  { id: ILLEGAL_DUMPING_REFERRAL, name: translate('routes.pickupIssueTypes.illegalDumpingReferral') },
  { id: ILLEGAL_DUMPING_COLLECTED, name: translate('routes.pickupIssueTypes.illegalDumpingCollected') },
  { id: ILLEGAL_DUMPING_NOT_FOUND, name: translate('routes.pickupIssueTypes.illegalDumpingNotFound') },
  { id: ILLEGAL_DUMPING_UNDER_REVIEW, name: translate('routes.pickupIssueTypes.illegalDumpingUnderReview') },
  { id: APPLIANCE_VIOLATION, name: translate('routes.pickupIssueTypes.applianceViolation') },
  { id: YARD_WASTE, name: translate('routes.pickupIssueTypes.yardWaste') },
  { id: BLOCKED_CONSTRUCTION, name: translate('routes.pickupIssueTypes.blockedConstruction') },
  { id: TIRES, name: translate('routes.pickupIssueTypes.tires') },
  { id: FREON_APPLIANCE, name: translate('routes.pickupIssueTypes.freonAppliance') },
  { id: YARD_WASTE_DELIVERY, name: translate('routes.pickupIssueTypes.yardWasteDelivery') },
  { id: DEAD_ANIMAL, name: translate('routes.pickupIssueTypes.deadAnimal') },
  { id: BOBCAT, name: translate('routes.pickupIssueTypes.bobcat') },
  { id: ABATEMENT, name: translate('routes.pickupIssueTypes.abatement') },
  { id: HHW, name: translate('routes.pickupIssueTypes.hhw') },
  { id: TRASH_BAGS, name: translate('routes.pickupIssueTypes.trashBags') },
  { id: IMPROPER_STORAGE_AREA, name: translate('routes.pickupIssueTypes.improperStorageArea') },
  { id: DAMAGE_TO_PRIVATE_PROPERTY, name: translate('routes.pickupIssueTypes.damageToPrivateProperty') },
  { id: STOP_ROUTE_TO_RELOAD_SALT, name: translate('routes.pickupIssueTypes.stopRouteToReloadSalt') },
  { id: RETURN_TRIP_SNOW_PLOW, name: translate('routes.pickupIssueTypes.returnTrip') },
  { id: EXCESS_CONTAINERS, name: translate('routes.pickupIssueTypes.excessContainers') },
  { id: NOT_SERVICED_BY_CITY, name: translate('routes.pickupIssueTypes.notServicedByCity') },
  { id: EVICTION, name: translate('routes.pickupIssueTypes.eviction') },
  { id: CONTRACTOR_GENERATED, name: translate('routes.pickupIssueTypes.contractorGenerated') },
  { id: CITIZEN_ERROR, name: translate('routes.pickupIssueTypes.citizenError') },
  { id: CREW_ERROR, name: translate('routes.pickupIssueTypes.crewError') },
  { id: INCORRECT_COLLECTION_DAY, name: translate('routes.pickupIssueTypes.incorrectCollectionDay') },
  { id: INVALID_ADDRESS, name: translate('routes.pickupIssueTypes.invalidAddress') },
  { id: LATE_SET_OUT, name: translate('routes.pickupIssueTypes.lateSetOut') },
  { id: PET_PREVENTING_ACCESS, name: translate('routes.pickupIssueTypes.petPreventingAccess') },
  { id: REFERRED_TO_AGENCY, name: translate('routes.pickupIssueTypes.referredToAgency') },
  { id: ROUTE_INTERRUPTED, name: translate('routes.pickupIssueTypes.routeInterrupted') },
  { id: BLOCKED_SNOW_OR_ICE, name: translate('routes.pickupIssueTypes.blockedSnowOrIce') },
  { id: WRONG_LOCATION, name: translate('routes.pickupIssueTypes.wrongLocation') },
  { id: DUPLICATED, name: translate('routes.pickupIssueTypes.duplicate') },
  { id: PLASTIC_BAGS, name: translate('routes.pickupIssueTypes.plasticBags') },
  { id: BLOCKED_STREET, name: translate('routes.pickupIssueTypes.blockedStreet') },
  { id: BLOCKED_ALLEY, name: translate('routes.pickupIssueTypes.blockedAlley') },
  { id: CALLED_TOO_EARLY, name: translate('routes.pickupIssueTypes.calledTooEarly') },
  { id: PICKUP_REQUEST_CANCELLED, name: translate('routes.pickupIssueTypes.pickupRequestCancelled') },
  { id: PRIVATE_COLLECTION, name: translate('routes.pickupIssueTypes.privateCollection') },
  { id: CODE_ENFORCEMENT_NEEDED, name: translate('routes.pickupIssueTypes.codeEnforcementNeeded') },
  { id: OPEN_LID, name: translate('routes.pickupIssueTypes.openLid') },
  { id: HOLIDAY_TREE, name: translate('routes.pickupIssueTypes.holidayTree') },
  { id: METAL_PLATES, name: translate('routes.pickupIssueTypes.metalPlates') },
  { id: ILLEGAL_SETOUT, name: translate('routes.pickupIssueTypes.illegalSetout') },
  { id: TRASH_BAG, name: translate('routes.pickupIssueTypes.trashBag') },
  { id: ELECTRONICS, name: translate('routes.pickupIssueTypes.electronics') },
  { id: CARDBOARD, name: translate('routes.pickupIssueTypes.cardboard') },
  { id: CRANE_NEEDED, name: translate('routes.pickupIssueTypes.craneNeeded') },
  { id: NOT_COLLECTED, name: translate('routes.pickupIssueTypes.notCollected') },
  { id: REFER_TO_311, name: translate('routes.pickupIssueTypes.referTo311') },
  { id: ROUTE_DELAYED, name: translate('routes.pickupIssueTypes.routeDelayed') },
  { id: OUT_OF_JURISTICTION, name: translate('routes.pickupIssueTypes.outOfJurisdiction') },
  { id: HOT_LIST, name: translate('routes.pickupIssueTypes.hotList') },
  { id: ASSISTED_STOP, name: translate('routes.pickupIssueTypes.assistedStop') },
  { id: MISSED, name: translate('routes.pickupIssueTypes.missed') },
  { id: MOVE_OUT, name: translate('routes.pickupIssueTypes.moveOut') },
  { id: IMPROPER_SETOUT, name: translate('routes.pickupIssueTypes.improperSetOut') },
  { id: UNBAGGED_TRASH, name: translate('routes.pickupIssueTypes.unbaggedTrash') },
  { id: NON_CITY_ISSUED_CONTAINER, name: translate('routes.pickupIssueTypes.nonCityIssuedContainer') },
  { id: ANIMAL_NOT_FOUND, name: translate('routes.pickupIssueTypes.animalNotFound') },
  { id: REFER_TO_PARKS, name: translate('routes.pickupIssueTypes.referToParks') },
  { id: REFER_TO_WATERSHED, name: translate('routes.pickupIssueTypes.referToWatershed') },
  { id: NO_ACTION_TAKEN, name: translate('routes.pickupIssueTypes.noActionTaken') },
  { id: PICKED_UP_DIFFERENT_SIZE, name: translate('routes.pickupIssueTypes.pickedUpDifferentSize') },
  { id: INCORRECT_FA, name: translate('routes.pickupIssueTypes.incorrectFa') },
  { id: DAMAGED_WARRANTY, name: translate('routes.pickupIssueTypes.damagedWarranty') },
  { id: WRONG_CART_TYPE, name: translate('routes.pickupIssueTypes.wrongCartType') },
  { id: DEFER_TO_NEXT_CYCLE, name: translate('routes.pickupIssueTypes.deferToNextCycle') },
  { id: REFER_TO_OTHER_AGENCY, name: translate('routes.pickupIssueTypes.referToOtherAgency') },
  { id: REFER_TO_DOT, name: translate('routes.pickupIssueTypes.referToDot') },
  { id: SWEEP_INCOMPLETE, name: translate('routes.pickupIssueTypes.sweepIncomplete') },
  { id: STREET_CONSTRUCTION, name: translate('routes.pickupIssueTypes.streetConstruction') },
  { id: CURB_OBSTRUCTION, name: translate('routes.pickupIssueTypes.curbObstruction') },
  { id: UNCURBED, name: translate('routes.pickupIssueTypes.uncurbed') },
  { id: AUDIT, name: translate('routes.pickupIssueTypes.audit') },
  { id: HAZARDOUS_MATERIALS, name: translate('routes.pickupIssueTypes.hazardousMaterials') },
  { id: ON_PRIVATE_PROPERTY, name: translate('routes.pickupIssueTypes.onPrivateProperty') },
  { id: HANDLED_BY_DIFFERENT_AGENCY, name: translate('routes.pickupIssueTypes.handledByDifferentAgency') },
  { id: MANUAL_CREW_NEEDED, name: translate('routes.pickupIssueTypes.manualCrewNeeded') },
  { id: LOST_CART, name: translate('routes.pickupIssueTypes.lostCart') },
  { id: REFILLED_BY_CUSTOMER, name: translate('routes.pickupIssueTypes.refilledByCustomer') },
];

export const PICKUP_ISSUE_TYPES = mapKeys(issueTypes, 'id');

export const PICKUP_ISSUE_TYPES_BY_NAME = mapKeys(issueTypes, 'name');
