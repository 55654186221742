import React, { useRef, useState, useEffect } from 'react';

import { ActionButton, ActionContent, ActionIcon, ActionTooltip } from './styled';
import { TableActionImage } from '../../core/components/styled';
import translate from '../services/translate';

interface Props {
  actionTooltipRef?: any;
  color?: string;
  content?: string | number;
  customViewBox?: string;
  hasTextWithIcon?: boolean;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  imageSrc?: string;
  margin?: string;
  marginTop?: string;
  rotationAngle?: string;
  size?: string;
  sourceIsInfoWindow?: boolean;
  tooltip?: string;
  tooltipAsString?: boolean;
  tooltipLeftPosition?: number;
  tooltipProps?: any;
  tooltipTopPosition?: number;
  withHoverColor?: boolean;
}

const ActionButtonTooltip: React.FC<Props> = ({
  color,
  content,
  customViewBox,
  hasTextWithIcon,
  icon,
  iconColor,
  iconSize,
  imageSrc,
  margin,
  marginTop,
  rotationAngle,
  size,
  sourceIsInfoWindow,
  tooltip,
  tooltipAsString,
  tooltipProps,
  withHoverColor,
}) => {
  const [tooltipLeftPosition, setTooltipLeftPosition] = useState<number>();
  const [tooltipTopPosition, setTooltipTopPosition] = useState<number>();
  const [hoverColor, setHoverColor] = useState<string | undefined>('black');
  const actionTooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      removeTooltip();
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = () => {
    removeTooltip();
  };

  const onTooltipMouseLeave = (event: any) => {
    withHoverColor && setHoverColor(color);
    removeTooltip();
  };

  const removeTooltip = () => {
    const actionTooltip: HTMLElement | null = document.getElementById('actionTooltip');
    if (actionTooltip) {
      if (actionTooltip.remove) {
        actionTooltip.remove();
      } else if (actionTooltip.parentNode) {
        // don't we just <3 IE 11?
        actionTooltip.parentNode.removeChild(actionTooltip);
      }
    }
  };

  const onTooltipMouseEnter = (event: any) => {
    removeTooltip();
    withHoverColor && setHoverColor('primary');
    const tooltipLeftPosition = event && event.target.getBoundingClientRect().left;
    const tooltipTopPosition = event && event.target.getBoundingClientRect().top;
    const tooltipWidth = event && event.target.getBoundingClientRect().width;
    const actionTooltipWidth = actionTooltipRef.current && actionTooltipRef.current.getBoundingClientRect().width;
    let finalLeftPosition = tooltipLeftPosition;
    let finalTopPosition = tooltipTopPosition;

    if (actionTooltipWidth) {
      finalLeftPosition =
        tooltipLeftPosition - actionTooltipWidth / 2 - (sourceIsInfoWindow ? 10 : 0) + tooltipWidth / 2;
      finalTopPosition = tooltipTopPosition - 40;
    }

    setTooltipLeftPosition(finalLeftPosition);
    setTooltipTopPosition(finalTopPosition);

    setTimeout(() => {
      if (finalLeftPosition && tooltipTopPosition) {
        const actionTooltip = actionTooltipRef.current && actionTooltipRef.current.cloneNode(true);
        if (actionTooltip && actionTooltip instanceof HTMLElement) {
          actionTooltip.style.visibility = 'visible';
          actionTooltip.id = 'actionTooltip';
          document.body.appendChild(actionTooltip);
        }
      }
    });
  };

  return (
    <ActionButton
      margin={margin}
      onMouseEnter={event => onTooltipMouseEnter(event)}
      onMouseLeave={event => onTooltipMouseLeave(event)}
      hasTextWithIcon={hasTextWithIcon}
      id={`actionButton_${Math.random()}`}
    >
      {tooltip && (
        <ActionTooltip
          color={withHoverColor ? hoverColor : color}
          ref={actionTooltipRef}
          size={size}
          tooltipLeftPosition={tooltipLeftPosition}
          tooltipTopPosition={tooltipTopPosition}
        >
          {tooltipAsString ? tooltip : translate(`tooltips.${tooltip}`, tooltipProps)}
        </ActionTooltip>
      )}
      {icon && (
        <ActionIcon
          color={withHoverColor ? hoverColor : iconColor || color}
          customViewBox={customViewBox}
          icon={icon}
          rotationAngle={rotationAngle}
          size={iconSize || size}
        />
      )}
      {imageSrc && <TableActionImage src={imageSrc} margin="no" />}
      {content && <ActionContent marginTop={marginTop}>{content}</ActionContent>}
    </ActionButton>
  );
};

export default ActionButtonTooltip;
