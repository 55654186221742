import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { PaymentStatistic } from '../interfaces/ApplyChecks';

import { loadPaymentStatistics as doLoadPaymentStatistics } from '../services/paymentStatistics';

// Actions
const START_LOAD = 'finance/applyChecks/paymentStatistics/START_LOAD';
const COMPLETE_LOAD = 'finance/applyPayemnts/paymentStatistics/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/paymentStatistics/FAIL_LOAD';
const RESET = 'finance/applyChecks/paymentFStatistics/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  paymentStatistics: PaymentStatistic | null;
} = {
  isLoading: false,
  paymentStatistics: null,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentStatistics: action.paymentStatistics,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentStatistics: null,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (paymentStatistics: any) => ({
  type: COMPLETE_LOAD,
  paymentStatistics,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadPaymentStatistics =
  ({
    status,
    vendorId,
    startDate,
    endDate,
    paymentNumber,
    customerId,
    locationId,
  }: {
    status: string;
    vendorId: number;
    startDate?: string;
    endDate?: string;
    paymentNumber?: number;
    customerId?: number;
    locationId?: number;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadPaymentStatisticsPromise = doLoadPaymentStatistics(
      status,
      vendorId,
      startDate,
      endDate,
      paymentNumber,
      customerId,
      locationId,
    );
    loadPaymentStatisticsPromise
      .then((paymentStatistics: any) => dispatch(completeLoad(paymentStatistics)))
      .catch(() => dispatch(failLoad()));
    return loadPaymentStatisticsPromise;
  };

export const resetPaymentStatistics = () => ({
  type: RESET,
});
