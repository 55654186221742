import { ChangeEvent, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { equipmentTypesSelector } from 'src/common/ducks';
import { EquipmentType } from 'src/common/interfaces/ServiceType';
import { Dropdown, TypedField } from 'src/core/components';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';
import { usePrevious } from 'src/utils/hooks';
import { DropDownFieldProps } from './DropDownFieldProps';

interface EquipmentTypeDropdownProps extends DropDownFieldProps {
  byName?: boolean;
  serviceTypeId?: number;
}

const EquipmentTypeDropdown = (props: EquipmentTypeDropdownProps) => {
  const {
    byName,
    dropdownProps,
    input: { name, onChange: inputOnChange },
    label,
    placeholder,
    serviceTypeId,
    withLabel,
    withPlaceholder,
  } = props;

  const equipmentTypes: EquipmentType[] = useSelector(
    (state: AppState) => (equipmentTypesSelector as any)(state.common.serviceTypes.serviceTypes, serviceTypeId) || [],
  );

  const previousServiceTypeId = usePrevious(serviceTypeId);

  useEffect(() => {
    if ((previousServiceTypeId || previousServiceTypeId === null) && previousServiceTypeId !== serviceTypeId) {
      inputOnChange(null);
    }
  }, [inputOnChange, serviceTypeId, previousServiceTypeId]);

  const onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    inputOnChange(value);
  };

  const equipmentTypeOptions = equipmentTypes
    ? equipmentTypes.map(equipmentType => ({
        label: equipmentType.name,
        value: byName ? equipmentType.name : equipmentType.id,
      }))
    : [];

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChange}
      props={{
        label: label || (withLabel ? translate('common.equipmentType') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('common.equipmentType') : undefined),
        options: equipmentTypeOptions,
        ...dropdownProps,
      }}
    />
  );
};

export default EquipmentTypeDropdown;
