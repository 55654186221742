import { useState } from 'react';
import { connect } from 'react-redux';
import { camelCase } from 'lodash';

import {
  DetailsListActionButton,
  DetailsListBodySection,
  DetailsListItemDescription,
  DetailsListItemTitle,
  Link,
  Popover,
} from '../../../../core/components/styled';
import {
  CUSTOMERS_LOCATION_DETAILS_DELETE_SERVICE,
  CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE,
} from '../../../../account/constants';
import {
  DaysOfServiceInfo,
  ScheduleContainer,
  ServiceDetailsActions,
  ServiceDetailsContainer,
  FrequencyContainer,
  Ellipsis,
} from '../../styled/CustomerLocations';

import { ACCOUNT_STATUSES } from '../../../../common/constants';
import { ActionButtonTooltip, UnconnectedDaysOfWeekPicker, Icon, PopoverWrapper } from '../../../../core/components';
import { checkServiceHasScheduledDailyRoutes } from '../../../../routes/services/routeTemplate';
import { createSuccessNotification, createErrorNotification } from '../../../../core/services/createNotification';
import { CustomerServiceIcon } from './CustomerServiceIcon';
import { deleteService } from '../../../ducks';
import { PermissionGuard } from '../../../../account/components';
import { SERVICE_TYPE_ICONS } from '../../../constants';
import { DeleteCustomerLocationOrServiceModal } from '../../modals';
import { STREET_ID, STREET_SWEEPING_ID } from 'src/common/constants/serviceTypes';
import translate from '../../../../core/services/translate';
import TransferContainerModalResolver from '../../modals/TransferContainerModalResolver';
import { checkServiceHasVendorContainers } from 'src/customers/services/service';
import { dateFormat } from 'src/utils/services/validator';
import moment from 'moment';
import { useSelector } from 'src/core/hooks/useSelector';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';

interface Props {
  service: any;
  locationId: number;
  deleteService: (
    serviceId: number,
    shouldRecreateRoutes: boolean,
    moveContainersToFacilityId?: number,
  ) => Promise<any>;
  refreshServices: () => void;
  vendorId: number;
}

const Icons = SERVICE_TYPE_ICONS as any;

const ServiceDetail = ({ service, locationId, deleteService, refreshServices, vendorId }: Props) => {
  const [isDeleteServiceModalOpen, setIsDeleteServiceModalOpen] = useState(false);
  const [isTransferContainerModalOpen, setIsTransferContainerModalOpen] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(0);
  const [moveContainersToFacilityId, setMoveContainersToFacilityId] = useState(0);

  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));

  const customer = useSelector(state => state.customers.customer.customer);
  const customerId = customer?.id || 0;

  const daysOfService = service.daysOfService
    .filter((dos: any) => dos.day.length === 1 && isNaN(parseFloat(dos.day)))
    .map((dos: any) => dos.day);

  const openDeleteServiceModal = async () => {
    const hasContainersAssigned = await checkServiceHasVendorContainers(service.id);
    if (hasContainersAssigned) setIsTransferContainerModalOpen(true);
    else setIsDeleteServiceModalOpen(true);
    setSelectedServiceId(service.id);
  };

  const onTransferContainer = (values: { facilityTypeId: number }) => {
    setMoveContainersToFacilityId(values.facilityTypeId);
    setIsTransferContainerModalOpen(false);
    setIsDeleteServiceModalOpen(true);
  };

  const onDeleteService = (shouldRecreateRoutes: boolean) => {
    setIsDeleteServiceModalOpen(false);
    setSelectedServiceId(0);

    deleteService(service.id, shouldRecreateRoutes, moveContainersToFacilityId)
      .then(() => {
        createSuccessNotification(`${translate('customers.alertMessages.serviceDeleted')}`);
        refreshServices();
      })
      .catch(error => {
        const errorCode = error.response && error.response.data?.message;
        switch (errorCode) {
          case 'ServiceContractUsedInRouteTemplate':
            createErrorNotification(translate(`customers.alertMessages.${camelCase(errorCode)}`));
            break;

          case 'ServiceContractUsedInRoute':
            createErrorNotification(translate(`customers.alertMessages.${camelCase(errorCode)}`));
            break;

          default:
            createErrorNotification(translate('customers.alertMessages.serviceDeleteError'));
            break;
        }
      });
  };

  const onCancelDeleteService = () => {
    setIsDeleteServiceModalOpen(false);
    setSelectedServiceId(0);
  };

  const onCancelTransferContainer = () => {
    setIsTransferContainerModalOpen(false);
    setMoveContainersToFacilityId(0);
  };

  const serviceDetails = [
    {
      id: 'serviceName',
      title: translate('common.serviceType'),
      description: service.serviceName,
    },
    {
      id: 'equipmentTypeName',
      title: translate('common.equipmentType'),
      description: service.equipmentTypeName,
    },
    {
      id: 'equipmentSizeName',
      title: translate('customers.equipmentSize'),
      description: service.equipmentSizeName,
    },
    {
      id: 'numberOfContainers',
      title: translate('containers.containerCountNumber'),
      description: (
        <Ellipsis>
          {service.numberOfContainers}
          {service.binNumber && ` - ${service.binNumber}`}
        </Ellipsis>
      ),
    },
    {
      id: 'wasteMaterialType',
      title: translate('customers.wasteMaterial'),
      description: service.wasteMaterialType,
    },
  ];

  const isSnowPlowService = service.serviceTypeId === STREET_ID;
  const isStreetSweeperService = service.serviceTypeId === STREET_SWEEPING_ID;
  const isSnowPlowOrStreetSweeperService = isSnowPlowService || isStreetSweeperService;

  const streetServiceDetails = [
    {
      id: 'forwardPasses',
      title: isSnowPlowService
        ? translate('customers.streetNetwork.forwardPassesSnow')
        : translate('customers.streetNetwork.forwardPassesSweeper'),
      description: service.forwardPasses || 0,
    },
    {
      id: 'reversePasses',
      title: isSnowPlowService
        ? translate('customers.streetNetwork.reversePassesSnow')
        : translate('customers.streetNetwork.reversePassesSweeper'),
      description: service.reversePasses || 0,
    },
  ];

  return (
    <>
      <ServiceDetailsContainer
        isParent={service.isParent}
        isChildren={service.isChild && service.effectiveDate}
        childLevel={service.generation}
      >
        {service.isChild && service.effectiveDate && (
          <DetailsListBodySection>
            <DetailsListItemTitle size="small" color="secondary" margin="no no small">
              {translate('customers.effectiveFrom')}
            </DetailsListItemTitle>
            <DetailsListItemDescription width="100%">
              {moment(service.effectiveDate).format(dateFormat)}
            </DetailsListItemDescription>
          </DetailsListBodySection>
        )}

        <CustomerServiceIcon accountStatusId={service.accountStatusTypeId} src={Icons[service.serviceTypeIcon]} />

        {(isSnowPlowOrStreetSweeperService ? streetServiceDetails : serviceDetails).map(serviceDetail => (
          <DetailsListBodySection key={serviceDetail.id} padding="no no no small">
            <DetailsListItemTitle size="small" color="secondary" margin="no no small">
              {serviceDetail.title}
            </DetailsListItemTitle>
            <DetailsListItemDescription width="100%">{serviceDetail.description}</DetailsListItemDescription>
          </DetailsListBodySection>
        ))}

        {!isSnowPlowOrStreetSweeperService && (
          <DetailsListBodySection>
            <FrequencyContainer>
              <DetailsListItemTitle size="small" color="secondary" margin="no no small">
                {translate('customers.frequency')}
              </DetailsListItemTitle>
              {!!daysOfService.length && (
                <DaysOfServiceInfo>
                  <PopoverWrapper
                    triggerButton={<Icon icon="info" />}
                    popoverContent={
                      <Popover>
                        <ScheduleContainer>
                          <UnconnectedDaysOfWeekPicker
                            isReadOnly
                            multiple
                            selectedWeekdays={daysOfService}
                            dayOfWeekProps={{ margin: "no xSmall no no'" }}
                            daysOfWeekProps={{ margin: 'no' }}
                          />
                        </ScheduleContainer>
                      </Popover>
                    }
                    width="15px"
                    size="small"
                    height="15px"
                  />
                </DaysOfServiceInfo>
              )}
            </FrequencyContainer>
            <DetailsListItemDescription width="100%">{service.pickupFrequencyType}</DetailsListItemDescription>
          </DetailsListBodySection>
        )}

        <DetailsListBodySection>
          <DetailsListItemDescription width="100%">
            {!!ACCOUNT_STATUSES[service.accountStatusTypeId] && ACCOUNT_STATUSES[service.accountStatusTypeId].name}
          </DetailsListItemDescription>
          {!isSnowPlowOrStreetSweeperService && (
            <DetailsListItemDescription width="100%">
              <Ellipsis>{service.haulerAccountNo || '-'}</Ellipsis>
            </DetailsListItemDescription>
          )}
        </DetailsListBodySection>
        <ServiceDetailsActions>
          <PermissionGuard permission={CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE}>
            <DetailsListActionButton id={`edit-service-${service.id}-button`} margin="no xSmall">
              <Link
                id="edit-service-link"
                color="grayDark"
                to={`/customers/customers/${customerId}/location/${locationId}/service/${service.id}`}
              >
                <ActionButtonTooltip icon="edit" tooltip="editService" />
              </Link>
            </DetailsListActionButton>
          </PermissionGuard>
          {!isSnowPlowOrStreetSweeperService && !isBillingFeatureActive && (
            <PermissionGuard permission={CUSTOMERS_LOCATION_DETAILS_DELETE_SERVICE}>
              <DetailsListActionButton
                id={`delete-service-${service.id}-button`}
                onClick={openDeleteServiceModal}
                margin="no"
              >
                <ActionButtonTooltip icon="delete" tooltip="deleteService" />
              </DetailsListActionButton>
            </PermissionGuard>
          )}
        </ServiceDetailsActions>
      </ServiceDetailsContainer>

      {!!isDeleteServiceModalOpen && (
        <DeleteCustomerLocationOrServiceModal
          checkMethod={checkServiceHasScheduledDailyRoutes}
          modalTitle={translate('customers.alertMessages.confirmDeleteService')}
          onCancel={onCancelDeleteService}
          onDelete={onDeleteService}
          selectedId={selectedServiceId}
          vendorId={vendorId}
        />
      )}

      {!!isTransferContainerModalOpen && (
        <TransferContainerModalResolver
          closeModal={onCancelTransferContainer}
          title={translate('containers.whereTransferContainers', {
            containerPlace: translate('common.service'),
          })}
          handleContainerTransfer={onTransferContainer}
        />
      )}
    </>
  );
};

export default connect(null, { deleteService })(ServiceDetail);
