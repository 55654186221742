import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'src/core/hooks/useSelector';
import { getHaulerLocationsGeoJSON } from 'src/routes/components/pages/routes/routePageSections/routeMap/haulerLocations/utils';
import TravelPathHaulerLocationsGLSource, {
  TRAVEL_PATH_HAULER_LOCATIONS_SOURCE,
} from './TravelPathHaulerLocationsGLSource';
import mapboxgl from 'mapbox-gl';
import TravelPathHaulerLocationGLPopup from './TravelPathHaulerLocationGLPopup';
import { VENDOR_LOCATION_ICON_TYPES_NEW } from 'src/common/constants/vendorLocationIcons';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useDispatch } from 'react-redux';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';

interface Props {
  map: mapboxgl.Map;
}

const mapImages = VENDOR_LOCATION_ICON_TYPES_NEW.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: TRAVEL_PATH_HAULER_LOCATIONS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

const TravelPathHaulerLocationsGL: FC<Props> = ({ map }) => {
  const dispatch = useDispatch();
  useMapImages(mapImages, map, mapImagesOptions);
  const haulerLocations = useSelector(state => state.routes.travelPathBuildAndEdit.haulerLocations);

  const geoJSON = useMemo(() => getHaulerLocationsGeoJSON(haulerLocations), [haulerLocations]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === TRAVEL_PATH_HAULER_LOCATIONS_SOURCE);

        if (feature) {
          const isForTravelPath = true;
          dispatch(
            setRouteMapSelectedFeature(
              RouteMapFeature.vendorLocations,
              feature.id as number,
              undefined,
              isForTravelPath,
            ),
          );
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <TravelPathHaulerLocationsGLSource geoJSON={geoJSON} />
      <TravelPathHaulerLocationGLPopup />
    </>
  );
};

export default TravelPathHaulerLocationsGL;
