import update from 'immutability-helper';
import doLoadRunningVehicles from '../services/runningVehicles';
import { AnyAction } from 'redux';
import { RunningVehicle } from '../interfaces/interfaces';
import { ThunkDispatch } from 'redux-thunk';

// Actions
const START_LOAD = 'inferenceAudit/runningVehicles/START_LOAD';
const COMPLETE_LOAD = 'inferenceAudit/runningVehicles/COMPLETE_LOAD';
const FAIL_LOAD = 'inferenceAudit/runningVehicles/FAIL_LOAD';
const RESET = 'inferenceAudit/runningVehicles/RESET';

interface State {
  isLoading: boolean;
  runningVehicles?: RunningVehicle[];
}

// Initial state
const initialState: State = {
  isLoading: false,
  runningVehicles: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction): State => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          runningVehicles: action.runningVehicles,
        },
      });

    case FAIL_LOAD: {
      return update(state, {
        $merge: {
          isLoading: false,
          runningVehicles: undefined,
        },
      });
    }
    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (runningVehicles: RunningVehicle[]) => ({
  type: COMPLETE_LOAD,
  runningVehicles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRunningVehicles = (vendorId: number, date: Date | string) => (dispatch: ThunkDispatch<State, any, AnyAction>) => {
  dispatch(startLoad());
  const loadRunningVehiclesPromise = doLoadRunningVehicles(vendorId, date);
  loadRunningVehiclesPromise
    .then(runningVehicles => dispatch(completeLoad(runningVehicles)))
    .catch(() => dispatch(failLoad()));
  return loadRunningVehiclesPromise;
};

export const resetRunningVehicles = () => ({
  type: RESET,
});
