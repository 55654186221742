import { combineReducers } from 'redux';

import { reducer as inferenceAuditsReducer } from './inferenceAudits';
import { reducer as runningVehiclesReducer } from './runningVehicles';

export { loadInferenceAudits, resetInferenceAudits, saveInferenceAudits } from './inferenceAudits';

export { loadRunningVehicles, resetRunningVehicles } from './runningVehicles';

export const reducer = combineReducers({
  inferenceAudits: inferenceAuditsReducer,
  runningVehicles: runningVehiclesReducer,
});
