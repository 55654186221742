import moment from 'moment';

import { date as dateFormatter } from 'src/utils/services/formatter';
import { Label } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { LABEL_COLORS, ROUTE_STATUSES_BY_ID } from 'src/routes/constants';
import EffectiveDate from '../../EffectiveDate';
import OverrideDate from '../../OverrideDate';
import {
  RouteDetail,
  RouteDetailLabel,
  RouteDetailsWrapper,
  RouteDetailValue,
  Wrapper,
} from '../../styled/RouteDetails';

const RouteSequenceSummaryPanel = () => {
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  if (!routeSummary) return null;

  const { date, routeRunDate, routeStatusTypeId, templateEffectiveDate, totalStopsCount, vehicleTypeId } = routeSummary;

  return (
    <Wrapper>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('common.status')}</RouteDetailLabel>
          <RouteDetailValue>
            <Label fontSize="xLarge" color={LABEL_COLORS[routeStatusTypeId]}>
              {ROUTE_STATUSES_BY_ID[routeStatusTypeId].name}
            </Label>
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.vehicleType')}</RouteDetailLabel>
          <RouteDetailValue>{VEHICLE_TYPE_IDS[vehicleTypeId].name}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>
            {translate(`routes.${vehicleTypeId === SNOW_PLOW_ID ? 'snowPlow.streetSegments' : 'stops'}`)}
          </RouteDetailLabel>
          <RouteDetailValue>{totalStopsCount}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('common.date')}</RouteDetailLabel>
          <RouteDetailValue>
            {moment(date).format('MM/DD/YYYY')}{' '}
            {routeRunDate && <OverrideDate routeRunDate={dateFormatter(routeRunDate)} />}
            {templateEffectiveDate && <EffectiveDate templateEffectiveDate={dateFormatter(templateEffectiveDate)} />}
          </RouteDetailValue>
        </RouteDetail>
      </RouteDetailsWrapper>
    </Wrapper>
  );
};

export default RouteSequenceSummaryPanel;
