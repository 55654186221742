import { http } from '../../core/services/http';
import { transformLoadLocationFlags, transformSaveLocationFlags } from './transformLocationFlags';
import { LocationFlags } from '../interfaces/LocationFlags';

export const loadLocationFlags = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/locationFlagTypes`).then(response => transformLoadLocationFlags(response.data));

export const saveLocationFlags = (vendorId: number, locationFlags: LocationFlags[]) =>
  http
    .post(`/vendors/${vendorId}/locationFlagTypes`, transformSaveLocationFlags(locationFlags))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
