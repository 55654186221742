import React from 'react';

import { Field } from 'redux-form';
import { SortableElement } from 'react-sortable-hoc';

import { DragHandle, Input, Switch } from '../../../core/components';
import { isEmails } from '../../../utils/services/validator';
import { TableCell, TableRow } from '../../../core/components/styled';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  isEnabled: boolean;
  technicalName: string;
}

const FlagLocationFormTableRow: React.FC<Props> = ({ technicalName, isEnabled }) => {
  return (
    <TableRow>
      <TableCell width="5%" align="center" padding="small">
        <DragHandle />
      </TableCell>
      <TableCell width="35%">{translate(createTranslationKey(technicalName, 'vendors.locationFlags'))}</TableCell>
      <TableCell width="15%">
        <Field name={`${technicalName}.isEnabled`} component={Switch} />
      </TableCell>
      <TableCell width="20%" align="right">
        <Field name={`${technicalName}.isPictureRequired`} component={Switch} />
      </TableCell>
      <TableCell width="25%">
        <Field
          name={`${technicalName}.emailSummary`}
          component={Input}
          type="text"
          validate={[isEmails]}
          margin="no"
          disabled={!isEnabled}
          withEllipsesText
        />
      </TableCell>
    </TableRow>
  );
};

export default SortableElement(FlagLocationFormTableRow);
