import { uniqueId } from 'lodash-es';

import {
  ContainerStatisticsTable,
  ContainerStatisticsTableBody,
  ContainerStatisticsTableBodyContainer,
  ContainerStatisticsTableCell,
  ContainerStatisticsTableFeaturedValue,
  ContainerStatisticsTableRow,
} from 'src/finance/components/styled/Statistics';

interface Props {
  rows: any[];
}

const StatisticsHighlightedTable = ({ rows }: Props) => {
  return (
    <ContainerStatisticsTable>
      <ContainerStatisticsTableBodyContainer>
        <ContainerStatisticsTableBody>
          {rows &&
            rows.map(row => (
              <ContainerStatisticsTableRow key={uniqueId('statistics_highlighted_table_')}>
                <ContainerStatisticsTableCell lineHeight="normal" color="black">
                  {row.label}
                </ContainerStatisticsTableCell>
                <ContainerStatisticsTableCell lineHeight="normal">
                  <ContainerStatisticsTableFeaturedValue>${row.value || '-'}</ContainerStatisticsTableFeaturedValue>
                </ContainerStatisticsTableCell>
              </ContainerStatisticsTableRow>
            ))}
        </ContainerStatisticsTableBody>
      </ContainerStatisticsTableBodyContainer>
    </ContainerStatisticsTable>
  );
};

export default StatisticsHighlightedTable;
