import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { BillCreateSummary } from 'src/finance/interfaces/BillCreateSummary';
import { loadBillCreateSummary as doLoadBillCreateSummary } from 'src/finance/services/billCreateSummary';

// Actions
const START_LOAD = 'finance/billing/billCreateSummary/START_LOAD';
const COMPLETE_LOAD = 'finance/billing/billCreateSummary/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/billing/billCreateSummary/FAIL_LOAD';
const RESET = 'finance/billing/billCreateSummary/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  billCreateSummary: BillCreateSummary;
} = {
  isLoading: false,
  billCreateSummary: {} as BillCreateSummary,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          billCreateSummary: action.billCreateSummary,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          billCreateSummary: {} as BillCreateSummary,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (billCreateSummary: BillCreateSummary) => ({
  type: COMPLETE_LOAD,
  billCreateSummary,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadBillCreateSummary =
  (
    vendorId: number,
    startDate: StringOrDate,
    endDate: StringOrDate,
    customerId?: number,
    locationId?: number,
    customerStatusIds?: number[],
    customerTypeIds?: number[],
    billMethodIds?: number[],
    paymentTermsIds?: number[],
    unbilledSearchTerm?: string,
    workOrderTypeId?: number,
    containerTypeId?: number,
    isOverdue?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadBillCreateSummaryPromise = doLoadBillCreateSummary(
      vendorId,
      startDate,
      endDate,
      customerId,
      locationId,
      customerStatusIds,
      customerTypeIds,
      billMethodIds,
      paymentTermsIds,
      unbilledSearchTerm,
      workOrderTypeId,
      containerTypeId,
      isOverdue
    );
    loadBillCreateSummaryPromise
      .then(billCreateSummary => dispatch(completeLoad(billCreateSummary)))
      .catch(() => dispatch(failLoad()));
    return loadBillCreateSummaryPromise;
  };

export const resetBillCreateSummary = () => ({
  type: RESET,
});
