import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  saveVehicleNote as doSaveVehicleNote,
  deleteVehicleNote as doDeleteVehicleNote,
  loadVehicleNotes as doLoadVehicleNotes,
} from '../services/vehicleNotes';
import { VehicleNotes } from '../interfaces/VehicleNotes';

// Actions
const START_LOAD = 'fleet/vehicleNotes/START_LOAD';
const COMPLETE_LOAD = 'fleet/vehicleNotes/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/vehicleNotes/FAIL_LOAD';
const START_SAVE_VEHICLE_NOTE = 'fleet/vehicleNotes/START_SAVE_VEHICLE_NOTE';
const COMPLETE_SAVE_VEHICLE_NOTE = 'fleet/vehicleNotes/COMPLETE_SAVE_VEHICLE_NOTE';
const FAIL_SAVE_VEHICLE_NOTE = 'fleet/vehicleNotes/FAIL_SAVE_VEHICLE_NOTE';
const START_DELETE_VEHICLE_NOTE = 'fleet/vehicleNotes/START_DELETE_VEHICLE_NOTE';
const COMPLETE_DELETE_VEHICLE_NOTE = 'fleet/vehicleNotes/COMPLETE_DELETE_VEHICLE_NOTE';
const FAIL_DELETE_VEHICLE_NOTE = 'fleet/vehicleNotes/FAIL_DELETE_VEHICLE_NOTE';
const RESET = 'fleet/vehicleNotes/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  vehicleNotes?: VehicleNotes[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  vehicleNotes: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleNotes: action.vehicleNotes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE_VEHICLE_NOTE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE_VEHICLE_NOTE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE_VEHICLE_NOTE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE_VEHICLE_NOTE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE_VEHICLE_NOTE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE_VEHICLE_NOTE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleNotes: VehicleNotes[]) => ({
  type: COMPLETE_LOAD,
  vehicleNotes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSaveVehicleNote = () => ({
  type: START_SAVE_VEHICLE_NOTE,
});

const completeSaveVehicleNote = (vehicleNote: VehicleNotes) => ({
  type: COMPLETE_SAVE_VEHICLE_NOTE,
  vehicleNote,
});

const failSaveVehicleNote = () => ({
  type: FAIL_SAVE_VEHICLE_NOTE,
});

const startDeleteVehicleNote = () => ({
  type: START_DELETE_VEHICLE_NOTE,
});

const completeDeleteVehicleNote = () => ({
  type: COMPLETE_DELETE_VEHICLE_NOTE,
});

const failDeleteVehicleNote = () => ({
  type: FAIL_DELETE_VEHICLE_NOTE,
});

export const loadVehicleNotes = (vehicleId: number, tripInspectionDetailId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVehicleNotesPromise = doLoadVehicleNotes(vehicleId, tripInspectionDetailId);
  loadVehicleNotesPromise
    .then((vehicleNotes: VehicleNotes[]) => dispatch(completeLoad(vehicleNotes)))
    .catch(() => dispatch(failLoad()));
  return loadVehicleNotesPromise;
};

export const saveVehicleNote =
  (
    vehicleId: number,
    tripInspectionDetailId: number,
    vehicleNote: string,
    newIssueStatus: number,
    vehicleNoteId?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startSaveVehicleNote());
    const saveVehicleNotePromise = doSaveVehicleNote(
      vehicleId,
      tripInspectionDetailId,
      vehicleNote,
      newIssueStatus,
      vehicleNoteId,
    );
    saveVehicleNotePromise
      .then((vehicleNote: VehicleNotes) => dispatch(completeSaveVehicleNote(vehicleNote)))
      .catch(() => dispatch(failSaveVehicleNote()));
    return saveVehicleNotePromise;
  };

export const deleteVehicleNote = (vehicleId: number, vehicleNoteId: number) => (dispatch: Dispatch) => {
  dispatch(startDeleteVehicleNote());
  const deleteVehicleNotePromise = doDeleteVehicleNote(vehicleId, vehicleNoteId);
  deleteVehicleNotePromise
    .then(() => dispatch(completeDeleteVehicleNote()))
    .catch(() => dispatch(failDeleteVehicleNote()));
  return deleteVehicleNotePromise;
};

export const resetVehicleNotes = () => ({
  type: RESET,
});
