import React, { ChangeEvent } from 'react';

import { ActionButtonTooltip, UnconnectedCheckbox } from '../../../../core/components';
import { TableCell, TableRow, TableActionButton } from '../../../../core/components/styled';
import { SCHEDULED_DATE_TYPE } from '../../../../core/constants/scheduledDateType';

interface Props {
  id: number;
  configurationId: number;
  fieldName: string;
  fieldDisplayName: string;
  fieldType: string;
  isChecked: boolean;
  isInbound: boolean;
  checkDefaultNote: (id: number, e: ChangeEvent<HTMLInputElement>) => void;
  editDefaultNoteField: (id: number) => void;
}

const DefaultNotesTableRow: React.FC<Props> = ({
  id,
  isChecked,
  fieldName,
  fieldDisplayName,
  fieldType,
  checkDefaultNote,
  editDefaultNoteField,
}) => (
  <TableRow>
    <TableCell width="5%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
      <UnconnectedCheckbox
        block
        checked={isChecked}
        onChange={e => checkDefaultNote(id, e)}
        onClick={e => e.stopPropagation()}
        id={`default-note-${id}-checkbox`}
      />
    </TableCell>
    <TableCell width="30%">{fieldName}</TableCell>
    <TableCell width="30%">{fieldDisplayName}</TableCell>
    <TableCell width="20%">{SCHEDULED_DATE_TYPE[fieldType]?.name}</TableCell>
    <TableCell width="15%">
      <TableActionButton id={`creationRule-${id}-edit-button`} onClick={() => editDefaultNoteField(id)}>
        <ActionButtonTooltip icon="edit" tooltip="edit" />
      </TableActionButton>
    </TableCell>
  </TableRow>
);

export default DefaultNotesTableRow;
