import styled, { css } from 'styled-components';
import { loadingOverlay } from '../../../../core/styles';
import { Icon } from '../../../../core/components';

interface LoadingContainerProps {
  isLoading?: boolean;
}

interface FullscreenMapProps {
  fullscreen?: boolean;
}

export const FullscreenMap = styled.div<FullscreenMapProps>`
  ${props =>
    props.fullscreen &&
    css`
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: fixed;
      height: 100%;
      background: #fff;
      z-index: 6001;
      display: flex;
      flex-direction: column;
    `};
`;

export const LoadingContainer = styled.div<LoadingContainerProps>`
  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const DispatchBoardMapFilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 12px;
`;

export const DispatchBoardMapFilterCloseIcon = styled(Icon)`
  width: 14px;
  height: 14px;
`;

export const DispatchBoardMapFilterCloseButton = styled.div`
  cursor: pointer;
  color: ${props => props.theme.brandPrimary};
  margin-right: 15px;
`;

export const DispatchBoardMapSearch = styled.div`
  color: ${props => props.theme.brandPrimary};
  flex: 1;
  display: flex;
  align-items: center;

  div {
    width: 100%;
  }
`;

export const SearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  color: ${props => props.theme.gray};
  margin-right: -35px;
  margin-left: 10px;
`;

export const Container = styled.div`
  position: absolute;
  right: 30px;
  bottom: 20px;
  z-index: 1000;
  border-radius: 1.5px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  padding: 12px 12px 0 12px;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
`;

export const Route = styled.div`
  padding-bottom: 12px;
  display: flex;
  align-items: center;
`;

export const MapRouteName = styled.div`
  margin-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
`;

export const Circle = styled.div`
  font-size: 10px;
  width: 24px;
  height: 24px;
  background: red;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;
