import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../../store';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { DuckFunction } from '../../../../contracts/ducks';
import { loadServiceZones } from '../../../ducks';
import { PageLoading } from '../../../../common/components/styled';
import { Resolver } from '../../../../core/components';
import { shouldLoadPermissionsSelector, loadPermissions } from '../../../../account/ducks';
import ServiceZonesPage from './ServiceZonesPage';
import translate from '../../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadServiceZones: DuckFunction<typeof loadServiceZones>;
  vendorId: number;
  shouldLoadPermissions: boolean;
  loadPermissions: DuckFunction<typeof loadPermissions>;
}

class ServiceZonesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadServiceZones, vendorId, loadPermissions, shouldLoadPermissions } = this.props;

    return shouldLoadPermissions
      ? Promise.all([loadServiceZones(vendorId), loadPermissions()])
      : loadServiceZones(vendorId);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routes.serviceZones')}</DocumentTitle>
        <Resolver
          successComponent={ServiceZonesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/routes/route-templates"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  shouldLoadPermissions: shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
});

const mapDispatchToProps = {
  loadServiceZones,
  loadPermissions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceZonesPageResolver));
