import { Address } from 'src/common/interfaces/Facility';
import { LoadDumpedFormValues } from '../components/forms/LoadDumpedEditorForm';
import { WaterFillFormValues } from '../components/forms/WaterFillEditorForm';

export const setLocationValue = (
  change?: any,
  address?: Address,
  formValues?: LoadDumpedFormValues | WaterFillFormValues,
) => {
  change(
    'locationName',
    address ? address.formattedAddress || address.line1 : formValues ? formValues.locationName : null,
  );
  change(
    'locationNameAddress',
    address
      ? address.formattedAddress || address.line1
      : formValues
      ? { formattedAddress: formValues.locationName }
      : null,
  );
  change('latitude', address ? address.latitude : formValues ? formValues.latitude : null);
  change('longitude', address ? address.longitude : formValues ? formValues.longitude : null);
};
