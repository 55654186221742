import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { maxLength512 } from 'src/utils/services/validator';
import { Input, TypedField } from '../../../core/components';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

interface PropsWithoutReduxForm {
  canCreate: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

function BinSensorForm({ handleSubmit }: Props) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid margin="no no no no">
        <GridColumn size="12/12">
          <TypedField
            name={'apiKey'}
            validate={[maxLength512]}
            component={Input}
            props={{
              label: translate('vendors.apiKey'),
              margin: 'no no medium no',
            }}
          />
        </GridColumn>
      </Grid>

      <PanelSection vertical padding="small">
        <Grid centered>
          <GridColumn size="12/12">
            <Button type="submit" color="primary">
              {translate('common.save')}
            </Button>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
}

export default connect()(reduxForm({ form: 'binSensorForm', onSubmitFail: focusFirstInvalidField })(BinSensorForm));
