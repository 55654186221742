import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const RUBICON_PRO = 1;
export const SMART_CITY = 2;
export const RUBICON_PRO_PORTAL = 3;

export const VENDOR_TYPES_ARRAY = [
  { id: RUBICON_PRO, technicalName: 'RUBICON_PRO', name: translate('common.vendorTypes.rubiconPro') },
  { id: SMART_CITY, technicalName: 'SMART_CITY', name: translate('common.vendorTypes.smartCity') },
  {
    id: RUBICON_PRO_PORTAL,
    technicalName: 'RUBICON_PRO_PORTAL',
    name: translate('common.vendorTypes.rubiconProPortal'),
  },
];

export const VENDOR_TYPES = mapKeys(VENDOR_TYPES_ARRAY, 'id');
