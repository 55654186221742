import React, { useState, ReactNode, useEffect } from 'react';

import { Box } from './styled/Box';
import { Text } from './styled';
import { FloatingCollapsibleHoverBox, FloatingCollapsibleToggleIcon } from './styled/FloatingCollapsibleStyles';

interface Props {
  title: string;
  children?: ReactNode;
  isOpenByDefault?: boolean;
  minWidthWhenClosed?: number;
  minWidthWhenOpen?: number;
  widthWhenClosed?: number;
  widthWhenOpen?: number;
  renderAfterTitle?: () => ReactNode;
  renderBeforeTitle?: () => ReactNode;
  onInstanceChange?: (ref: HTMLDivElement | null) => void;
}

export default function FloatingCollapsible({
  title,
  children,
  isOpenByDefault = false,
  minWidthWhenClosed,
  minWidthWhenOpen,
  widthWhenClosed,
  widthWhenOpen,
  renderAfterTitle,
  renderBeforeTitle,
  onInstanceChange,
}: Props) {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const [collapseRef, setCollapseRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (typeof onInstanceChange === 'function') {
      onInstanceChange(collapseRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Box
      ref={instance => {
        setCollapseRef(instance);

        if (typeof onInstanceChange === 'function') {
          onInstanceChange(instance);
        }
      }}
      width={isOpen ? widthWhenOpen : widthWhenClosed}
      minWidth={isOpen ? minWidthWhenOpen : minWidthWhenClosed}
      borderRadius={5}
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      boxShadow="0 3px 6px rgba(0,0,0, .1)"
      overflow="hidden"
    >
      <FloatingCollapsibleHoverBox
        p={12}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box display="flex" alignItems="center">
          {typeof renderBeforeTitle === 'function' && renderBeforeTitle()}

          <Text size="medium" weight="medium">
            {title}
          </Text>

          {typeof renderAfterTitle === 'function' && renderAfterTitle()}
        </Box>

        <FloatingCollapsibleToggleIcon isOpen={isOpen} />
      </FloatingCollapsibleHoverBox>

      {isOpen && children}
    </Box>
  );
}
