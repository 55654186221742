import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { difference, findIndex, get, size } from 'lodash-es';
import update from 'immutability-helper';

import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn, Message, TextArea } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { DeviceServices, Vehicles } from '../../interfaces/ZDeviceConfiguration';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import {
  loadZDeviceConfigurations,
  resetZDeviceConfigurations,
  saveZDeviceConfigurations,
  selectCommands,
  selectZDeviceServices,
} from '../../ducks';
import { Modal, Table } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { VehicleTypeForm } from '../forms';
import { ZDeviceConfigurationModalTableRow } from './';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  commands?: string;
  deviceServices: DeviceServices[];
  featureCode: string;
  isLoading: boolean;
  loadZDeviceConfigurations: DuckFunction<typeof loadZDeviceConfigurations>;
  resetZDeviceConfigurations: DuckAction<typeof resetZDeviceConfigurations>;
  saveZDeviceConfigurations: DuckFunction<typeof saveZDeviceConfigurations>;
  vehicles?: Vehicles[];
}

interface State {
  additionalParameters?: string;
  deviceServices?: DeviceServices[];
  initialValuesLoaded: boolean;
}

class ZDeviceConfigurationModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deviceServices: undefined,
      initialValuesLoaded: false,
      additionalParameters: undefined,
    };
  }

  componentDidUpdate() {
    if (!!size(this.props.deviceServices) && !this.state.initialValuesLoaded) {
      this.setState({
        initialValuesLoaded: true,
        deviceServices: this.props.deviceServices,
        additionalParameters: this.props.commands,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetZDeviceConfigurations();
  }

  onChangeVehicleTypeId = () => {
    this.setState({ initialValuesLoaded: false });
  };

  onTextAreaChange = ({ target: { value } }: any) => {
    this.setState({ additionalParameters: value });
  };

  onZDeviceConfigurationVersionChange = (id: number) => (versionNumber: any) => {
    if (versionNumber.length >= 100) return;
    const { deviceServices } = this.state;
    const configIndex = findIndex(deviceServices, device => (device as DeviceServices).deviceService.id === id);
    this.setState(prevState => ({
      deviceServices: update(prevState.deviceServices, {
        [configIndex]: {
          versionNumber: { $set: versionNumber },
        },
      }),
    }));
  };

  toggleZDeviceConfiguration = (id: number) => (value: boolean) => {
    const { deviceServices } = this.state;

    const newDeviceServices = deviceServices?.map((device: DeviceServices) => {
      if (device.deviceService.id === id) {
        const newDeviceService = { ...device, isEnabled: value };
        return newDeviceService;
      }
      return device;
    });
    this.setState({ deviceServices: newDeviceServices });
  };

  saveZDeviceConfigurations = () => {
    const { saveZDeviceConfigurations, closeModal } = this.props;
    const { deviceServices, additionalParameters } = this.state;
    saveZDeviceConfigurations(deviceServices, additionalParameters)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.zDeviceConfigurationSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.saveAiModelConfigurationError')));
  };

  closeModal = () => {
    const { deviceServices, closeModal } = this.props;
    closeModal(
      !size(difference(get(deviceServices, 'deviceService'), get(this.state.deviceServices, 'deviceService'))),
    );
  };

  render() {
    const { featureCode, isLoading, loadZDeviceConfigurations, vehicles } = this.props;
    const { deviceServices, additionalParameters } = this.state;

    const zDeviceConfigurationTableCells = [
      { name: 'featureName', label: translate('vendors.modelFeatures'), width: '60%' },
      { name: 'enabled', label: translate('common.active'), width: '20%', align: 'right' },
      { name: 'versionNumber', label: translate('common.version'), width: '20%', align: 'right' },
    ];
    return (
      <Modal
        title={translate(createTranslationKey(featureCode, 'vendors.featureCodes'))}
        onClose={this.closeModal}
        verticalSize="small"
        isLoading={isLoading}
      >
        <VehicleTypeForm
          loadModelConfigurations={loadZDeviceConfigurations}
          onChangeVehicleTypeId={this.onChangeVehicleTypeId}
          vehicles={vehicles}
        />
        {!!size(deviceServices) && (
          <Table
            cells={zDeviceConfigurationTableCells}
            rows={deviceServices}
            rowComponent={ZDeviceConfigurationModalTableRow}
            rowProps={{
              toggleZDeviceConfiguration: this.toggleZDeviceConfiguration,
              onZDeviceConfigurationVersionChange: this.onZDeviceConfigurationVersionChange,
            }}
          />
        )}
        {!size(deviceServices) && <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>}
        <Grid style={{ marginTop: '15px' }} centered>
          <GridColumn size="10/12">
            {deviceServices && !!size(deviceServices) && (
              <TextArea
                style={{ resize: 'none' }}
                cols={3}
                placeholder={translate('common.additionalParameters')}
                onChange={this.onTextAreaChange}
                value={additionalParameters}
              />
            )}
          </GridColumn>
        </Grid>
        {deviceServices && (
          <Grid centered>
            <GridColumn size="6/12">
              <ButtonSet margin="medium no">
                <Button color="primary" onClick={this.saveZDeviceConfigurations}>
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  commands: selectCommands(state),
  deviceServices: selectZDeviceServices(state),
  isLoading: state.vendors.zDeviceConfigurations.isLoading,
  vehicles: state.fleet.vehicleTypesForVendor.vehicleTypesForVendor,
});

const mapDispatchToProps = {
  saveZDeviceConfigurations,
  resetZDeviceConfigurations,
  loadZDeviceConfigurations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZDeviceConfigurationModal);
