import React from 'react';

import { WrappedFieldProps } from 'redux-form';

import {
  FormGroupClear,
  PanelSearch as PanelSearchContainer,
  PanelSearchIcon,
  PanelSearchInput,
  FormGroupClearContainer,
} from './styled';
import translate from '../services/translate';

interface Props extends WrappedFieldProps {
  borderBottom?: boolean;
  placeholder?: string;
  margin?: string;
  padding?: string;
  isClearable?: boolean;
  minWidth?: string;
}

const PanelSearch: React.FC<Props> = ({ input, placeholder, isClearable = false, ...props }) => (
  <PanelSearchContainer borderBottom={props.borderBottom} padding={props.padding} margin={props.margin}>
    <PanelSearchIcon />

    <PanelSearchInput
      onKeyPress={(e: any) => {
        e.key === 'Enter' && e.preventDefault();
      }}
      {...input}
      placeholder={placeholder || translate('common.search')}
      {...props}
      autoComplete="off"
    />

    {isClearable && !!input.value && (
      <FormGroupClearContainer>
        <FormGroupClear onClick={() => input.onChange('')} />
      </FormGroupClearContainer>
    )}
  </PanelSearchContainer>
);

export default PanelSearch;
