import { find } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

import { clearDashboardModalMapSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import VehicleTrackingsGLPopup from './VehicleTrackingsGLPopup';

const VehicleTrackingsGLPopupResolver = () => {
  const dispatch = useDispatch();

  const vehicleTrackingsByRoutes = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbsByRoutes);
  const vehicleTrackingsForRouteVehicle = useSelector(state => state.dashboard.routesData.vehicleFullTrackings);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedModalMapFeature);
  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);
  const { vehicleId } = useSelector(state => state.dashboard.mapControls.mapModalData);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.namespace !== 'vehicleTrackings' ||
      selectedFeature.vehicleTrackingGroupIndex === undefined ||
      selectedFeature.coordinates === undefined
    ) {
      return undefined;
    }

    const { vehicleTrackingGroupIndex: groupIndex, vehicleTrackingsSource, id } = selectedFeature;

    const group =
      vehicleTrackingsSource === 'vehicle'
        ? find(vehicleTrackingsByRoutes?.routes, { id })?.coords[groupIndex]
        : vehicleTrackingsForRouteVehicle?.coords[groupIndex];

    if (!vehicleTrackingsByRoutes || !group) {
      return undefined;
    }

    // get the closest coordinate to the selected point
    const closestCoordinate = group.reduce((prev, curr) => {
      if (!selectedFeature.coordinates) return prev;

      const prevDistance = Math.sqrt(
        Math.pow(prev.lat - selectedFeature.coordinates.lat, 2) +
          Math.pow(prev.lng - selectedFeature.coordinates.lng, 2),
      );
      const currDistance = Math.sqrt(
        Math.pow(curr.lat - selectedFeature.coordinates.lat, 2) +
          Math.pow(curr.lng - selectedFeature.coordinates.lng, 2),
      );

      return prevDistance < currDistance ? prev : curr;
    }, [] as any);

    return {
      vehicle:
        (vehicleTrackingsSource === 'vehicle'
          ? vehicleTrackingsByRoutes?.name
          : vehicleTrackingsForRouteVehicle?.name) || '',
      id: selectedFeature.id,
      selectedData: closestCoordinate,
      videoDeviceTypeId:
        (vehicleTrackingsSource === 'vehicle'
          ? vehicleTrackingsByRoutes?.vdtId
          : vehicleTrackingsForRouteVehicle?.vdtId) || 0,
      shouldDisplayDownloadVideo:
        vehicleTrackingsSource === 'vehicle' ? vehicleTrackingsByRoutes?.hdv : vehicleTrackingsForRouteVehicle?.hdv,
      anchor: {
        latitude: selectedFeature.coordinates.lat,
        longitude: selectedFeature.coordinates.lng,
      },
    };
  }, [
    selectedFeature,
    vehicleTrackingsByRoutes,
    vehicleTrackingsForRouteVehicle?.coords,
    vehicleTrackingsForRouteVehicle?.hdv,
    vehicleTrackingsForRouteVehicle?.name,
    vehicleTrackingsForRouteVehicle?.vdtId,
  ]);

  if (!selectedTracking || !vehicleId) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.anchor.latitude}
      longitude={selectedTracking.anchor.longitude}
      onClose={() => dispatch(clearDashboardModalMapSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingGroupIndex}`}
        successComponent={VehicleTrackingsGLPopup}
        successProps={{
          speed: selectedTracking.selectedData.sp,
          unitOfMeasure: getMeasurementType(systemOfMeasurementId),
          vehicleId,
          vehicleName: selectedTracking.vehicle,
          timestamp: selectedTracking.selectedData.ts,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo,
          applicationModeStatusId: selectedTracking.selectedData.am,
          videoDeviceTypeId: selectedTracking.videoDeviceTypeId,
          deviceId: selectedTracking.selectedData.deid,
        }}
        loadingComponent={MapGLPopupLoading}
      />
    </MapGLPopupWrapper>
  );
};

export default VehicleTrackingsGLPopupResolver;
