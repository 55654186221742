import moment from 'moment';
import translate from 'src/core/services/translate';
import { currency } from 'src/utils/services/formatter';
import { Grid, GridColumn, Text } from 'src/core/components/styled';
import { InvoiceDetails } from 'src/finance/interfaces/PaymentManagement';

interface InvoiceDetailProps {
  invoiceDetails: InvoiceDetails;
}

const InvoiceDetailSection = ({
  invoiceDetails: { id, invoiceDate, invoiceTotal, balance, dueDate },
}: InvoiceDetailProps) => {
  return (
    <Grid padding="no">
      <GridColumn size="4/12">
        <Text block size="large" padding="xxSmall" weight="medium">
          <Text color="grayDarker">{`${translate('finance.paymentManagement.invoiceId')}: `}</Text>
          {id}
        </Text>
        <Text block size="large" padding="xxSmall" weight="medium">
          <Text color="grayDarker">{`${translate('finance.paymentManagement.issuedOn')}: `}</Text>
          {`${moment(invoiceDate).format('MM/DD/YYYY')}`}
        </Text>
        <Text block size="large" padding="xxSmall" weight="medium">
          <Text color="grayDarker">{`${translate('finance.paymentManagement.invoiceTotal')}: `}</Text>
          <Text color="primary" weight="medium" size="large">
            {currency(invoiceTotal)}
          </Text>
        </Text>
      </GridColumn>
      <GridColumn size="4/12" marginLeft="auto">
        <Text block size="large" padding="xxSmall" weight="medium">
          <Text color="grayDarker">{`${translate('finance.paymentManagement.balance')}: `}</Text>
          <Text color="primary" size="large" weight="medium">
            {currency(balance)}
          </Text>
        </Text>
        <Text block size="large" padding="xxSmall" weight="medium" color="grayDarker">
          <Text color="grayDarker">{`${translate('finance.paymentManagement.dueOn')}: `}</Text>
          {`${moment(dueDate).format('MM/DD/YYYY')}`}
        </Text>
      </GridColumn>
    </Grid>
  );
};

export default InvoiceDetailSection;
