import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../contracts/ducks';
import { loadVehicleNotes } from 'src/fleet/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import VehicleNotesModal from './VehicleNotesModal';

interface Props {
  closeModal: () => void;
  fleetMaintenanceConfigIsOptional: boolean;
  isPendingOrClosingProcess: boolean;
  loadVehicleNotes: DuckFunction<typeof loadVehicleNotes>;
  modalTitle?: string;
  newIssueStatus: number;
  tripInspectionDetailId: number;
  updateVehicleIssue: (status: number, vehicleNote?: string) => void;
  vehicleId: number;
}

class VehicleNotesModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { isPendingOrClosingProcess, loadVehicleNotes, tripInspectionDetailId, vehicleId } = this.props;
    const promises = isPendingOrClosingProcess ? [] : [loadVehicleNotes(vehicleId, tripInspectionDetailId)];
    return Promise.all(promises);
  };

  render() {
    const {
      closeModal,
      fleetMaintenanceConfigIsOptional,
      isPendingOrClosingProcess,
      modalTitle,
      newIssueStatus,
      tripInspectionDetailId,
      updateVehicleIssue,
      vehicleId,
    } = this.props;

    return (
      <Resolver
        successComponent={VehicleNotesModal}
        successProps={{
          closeModal,
          fleetMaintenanceConfigIsOptional,
          isPendingOrClosingProcess,
          modalTitle,
          newIssueStatus,
          tripInspectionDetailId,
          updateVehicleIssue,
          vehicleId,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = { loadVehicleNotes };

export default connect(undefined, mapDispatchToProps)(VehicleNotesModalResolver);
