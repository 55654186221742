import styled, { css } from 'styled-components';

interface ContainersMapControlContainerProps {
  isOpen?: boolean;
  disabled?: boolean;
  color?: string;
}

export const ContainersMapControlContainer = styled.div<ContainersMapControlContainerProps>`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  ${p =>
    p.isOpen &&
    css`
      width: auto;
      height: auto;
    `}

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const ContainersMapInfoOverlay = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  opacity: 0.7;

  h4 {
    text-align: center;
    margin-top: 200px;
  }
`;

export const ContainersLegendItem = styled.div<{ hasTopBorder?: boolean }>`
  display: flex;
  align-items: center;
  padding: 3px 0;
  font-size: 12px;

  &:not(:first-child) {
    margin: 5px 0 0;
  }

  ${p =>
    p.hasTopBorder &&
    css`
      border-top: 1px solid #e0e0e0;
      padding-top: 5px;
      margin-top: 10px !important;
    `}
`;

export const ContainersLegendItems = styled.div`
  flex: 1;
  overflow: auto;
  padding: 7px 15px 15px;
`;

export const ContainersLegendItemColor = styled.div<{ color: string; hasBorder?: boolean }>`
  height: 12px;
  width: 12px;
  background: ${p => p.color};
  border-radius: 15px;

  ${props =>
    props.hasBorder &&
    css`
      border: 1px solid red;
    `}
`;

export const ContainersMapControlToggle = styled.div`
  cursor: pointer;
  display: flex;
  padding: 15px;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

export const ContainerItemColor = styled.div<ContainersMapControlContainerProps>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: ${props => props.color};
  margin-right: 8px;
`;
