import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import humps from 'humps';
import { get } from 'lodash';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { CustomerProximitySearchModalResolver } from 'src/customers/components/modals';
import { TableActionButton, Text } from 'src/core/components/styled';
import {
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { INSIGHT_ICONS, PICKUP_STATUSES, ROUTE_PICKUP_TYPE_IDS } from 'src/common/constants';
import { resetStopMapDetails } from 'src/routes/ducks/stopMapDetails';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { useSelector } from 'src/core/hooks/useSelector';
import { WASTE_AUDIT_ID } from 'src/fleet/constants';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';
import { WASTE_AUDIT_EXCEPTION_TYPES, WASTE_AUDIT_LOCATION_STATUS_TYPES } from 'src/routes/constants';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { getRouteStopIconType } from '../../routes/routePageSections/routeMap/routeStops/utils';

type Props = {
  routeStop: RouteStop;
};

export default function RouteSequenceStopPopup({ routeStop }: Props) {
  const dispatch = useDispatch();
  const [isCustomerProximitySearchModalOpen, setCustomerProximitySearchModalOpen] = useState(false);

  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { stopMapDetails } = useSelector(state => state.routes.stopMapDetails);

  useEffect(
    () => () => {
      dispatch(resetStopMapDetails());
    },
    [dispatch],
  );

  if (!routeSummary) return null;
  const iconType = getRouteStopIconType(routeStop, routeSummary);
  const pickupStatusName = PICKUP_STATUSES[routeStop.pickupStatusTypeId].technicalName;
  const insightType = humps.camelize(pickupStatusName);

  const getAddress = () => {
    if (stopMapDetails && stopMapDetails.address) return stopMapDetails.address;
    if (routeStop && routeStop.addressForPopup) return routeStop.addressForPopup;
    return '-';
  };

  const isYRoute = false;

  const getWasteAuditStatus = () => {
    let icon = null;
    let title = '';
    if (routeSummary.vehicleTypeId !== WASTE_AUDIT_ID) return null;
    if (routeStop.wasteAuditStatuses && routeStop.wasteAuditStatuses.length === 0) {
      icon =
        routeStop.wasteAuditLocationStatusTypeId !== undefined
          ? get(WASTE_AUDIT_LOCATION_STATUS_TYPES[routeStop.wasteAuditLocationStatusTypeId], 'technicalName')
          : '';
      title =
        routeStop.wasteAuditLocationStatusTypeId !== undefined
          ? get(WASTE_AUDIT_LOCATION_STATUS_TYPES[routeStop.wasteAuditLocationStatusTypeId], 'name')
          : '';
    }
    if (routeStop.wasteAuditStatuses && routeStop.wasteAuditStatuses.length === 1) {
      icon =
        typeof routeStop.wasteAuditStatuses[0] === 'number'
          ? get(WASTE_AUDIT_EXCEPTION_TYPES[routeStop.wasteAuditStatuses[0]], 'technicalName')
          : '';
      title =
        typeof routeStop.wasteAuditStatuses[0] === 'number'
          ? get(WASTE_AUDIT_EXCEPTION_TYPES[routeStop.wasteAuditStatuses[0]], 'name')
          : '';
    }
    if (routeStop.wasteAuditStatuses && routeStop.wasteAuditStatuses.length > 1) {
      icon = 'multiple';
      title = translate(`dashboard.multiple`);
    }
    if (!icon || !title) return null;
    return (
      <Box mt={5} display="flex" alignItems="center">
        {icon && (
          <Box mr={8} width={15} height={15}>
            <img src={INSIGHT_ICONS[humps.camelize(icon)]} width="15" height="15" alt="" />
          </Box>
        )}
        {title && (
          <Text weight="medium" size="medium">
            {title}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={routeStop.displayLatitude}
        longitude={routeStop.displayLongitude}
        onClose={() => dispatch(clearRouteMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={iconType?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${insightType}`)}
            secondTitle={routeSummary.vehicleTypeId === WASTE_AUDIT_ID && getWasteAuditStatus()}
          />

          <MapGLPopupContent>
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('routes.newStopNumber')}
              value={routeStop.newOrderNumber}
            />
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('dashboard.stopNumber')}
              value={routeStop.orderNo}
            />
            {routeSummary.vehicleTypeId !== WASTE_AUDIT_ID && (
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('routes.pickupType')}
                value={ROUTE_PICKUP_TYPE_IDS[routeStop.pickupTypeId]?.name || '-'}
              />
            )}
            <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('routes.route')}>
              {routeSummary.routeId || isYRoute ? (
                <Box textDecoration="underline">
                  <Link
                    to={
                      isYRoute
                        ? `/routes/y-daily-routes/${routeSummary.routeDetailId}`
                        : `/routes/route-tracker/${routeSummary.routeId}`
                    }
                  >
                    {routeSummary.name}
                  </Link>
                </Box>
              ) : (
                routeSummary.name
              )}
            </LabeledDataView>
            <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.customer')}>
              {routeStop.customerName}
              <TableActionButton
                margin="no no no xSmall"
                color="primary"
                onClick={() => setCustomerProximitySearchModalOpen(true)}
              >
                <ActionButtonTooltip
                  icon="searchVehicle"
                  size="sMedium"
                  sourceIsInfoWindow
                  tooltip="customerProximitySearch"
                />
              </TableActionButton>
            </LabeledDataView>
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.address')}
              value={getAddress()}
            />
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isCustomerProximitySearchModalOpen && (
        <CustomerProximitySearchModalResolver
          defaultDate={routeStop.statusDate}
          locationId={routeStop.id}
          onCancel={() => setCustomerProximitySearchModalOpen(false)}
        />
      )}
    </>
  );
}
