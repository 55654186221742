import { assign } from 'lodash-es';
import { ComponentType, MouseEvent } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReactDOM from 'react-dom';

import { BOTTOM, LEFT, RIGHT, TOP } from '../constants';
import { InnerPopover, ModalClose, ModalCloseIcon, Popover } from '../components/styled';
import { PopoverPosition, PopoverProps } from '../components/styled/Popover';
import { theme } from '../styles';
import history from './history';
import store from '../../store';

const calculateElementOffset = (element: HTMLElement) => {
  const bounds = element.getBoundingClientRect();
  return {
    top: bounds.top + window.pageYOffset,
    right: bounds.right + window.pageXOffset,
    bottom: bounds.bottom + window.pageYOffset,
    left: bounds.left + window.pageXOffset,
    width: bounds.width,
    height: bounds.height,
  };
};

const calculatePopoverPosition = (element: HTMLElement, position: PopoverPosition) => {
  const { top, bottom, left, width } = calculateElementOffset(element);
  const isTablet = window.innerWidth < 1240;

  switch (position) {
    case TOP:
      return { bottom: `${window.innerHeight - top}px`, left: `${left + width / 2}px` };

    case BOTTOM:
      return isTablet ? { top: `${bottom}px`, right: 0 } : { top: `${bottom}px`, left: `${left + width / 2}px` };

    case RIGHT:
      return { top: `${(top + bottom) / 2}px`, left: `${left + width}px` };

    case LEFT:
      return { top: `${(top + bottom) / 2}px`, right: `${left + width}px` };

    default:
      break;
  }
};

const createPopover = (
  targetElement: HTMLElement,
  Component: ComponentType<any>,
  componentProps: object = {},
  popoverProps: PopoverProps = { position: TOP },
) => {
  const { position = TOP, maxHeight, hasCloseButton, size, ...restOfPopoverProps } = popoverProps;
  const parentNode = document.body.appendChild(document.createElement('div'));
  let isClosed = false;

  const popoverPosition = calculatePopoverPosition(targetElement, position);
  assign(parentNode.style, {
    position: 'absolute',
    ...popoverPosition,
  });

  const closePopover = () => {
    if (isClosed) return;
    isClosed = true;
    unregisterHistoryChangeEventHandler();
    ReactDOM.unmountComponentAtNode(parentNode);
    setTimeout(() => document.body.removeChild(parentNode));
    document.removeEventListener('mousedown touchstart', closePopover);
  };

  const onPopoverMouseDown = (event: MouseEvent) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  const unregisterHistoryChangeEventHandler = history.listen(closePopover);

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Popover
          position={position}
          size={size}
          {...restOfPopoverProps}
          onMouseDown={onPopoverMouseDown}
          isOpened={!isClosed}
        >
          <InnerPopover maxHeight={maxHeight}>
            {hasCloseButton && (
              <ModalClose onClick={closePopover} zIndex={6003}>
                <ModalCloseIcon />
              </ModalClose>
            )}
            <Component {...componentProps} close={closePopover} />
          </InnerPopover>
        </Popover>
      </Provider>
    </ThemeProvider>,
    parentNode,
  );

  document.addEventListener('mousedown', closePopover);

  return closePopover;
};

export default createPopover;
