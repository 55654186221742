import React from 'react';

import moment from 'moment';
import { useDispatch } from 'react-redux';

import { ActionButtonTooltip } from '../../../../core/components';
import { AppState } from 'src/store';
import { createSuccessNotification, createErrorNotification } from '../../../../core/services/createNotification';
import { hasPermission } from '../../../../account/utils/permissions';
import { ISSUE_STATUS_PENDING, ISSUE_STATUS_CLOSED } from '../../../constants/vehicleIssues';
import { OPTIONAL, REQUIRED } from 'src/common/constants/fleetMaintenance';
import { RouteImagesModal } from '../../../../routes/components/modals';
import { ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS } from '../../../../account/constants';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehicleIssue } from '../../../interfaces/VehicleIssues';
import * as Ducks from '../../../ducks/vehicleIssues';
import translate from '../../../../core/services/translate';
import VehicleNotesModalResolver from '../../modal/VehicleNotesModalResolver';

type Props = VehicleIssue & {
  isFleetMaintenanceFeatureEnabled: boolean;
  reloadIssues(): void;
  vehicleId: number;
};

const VehicleIssuesTableRow: React.FC<Props> = ({
  driverName,
  image,
  isFleetMaintenanceFeatureEnabled,
  lastModifiedBy,
  lastModifiedOn,
  note,
  occurrenceDate,
  reloadIssues,
  resolvedOnDate,
  routeName,
  tripInspectionDetailId,
  tripInspectionIssueStatusType,
  tripInspectionSubCategory,
  vehicleId,
}) => {
  const dispatch = useDispatch();

  const [imageVisible, setImageVisible] = React.useState<boolean>(false);
  const [vehicleNotesVisible, setVehicleNotesVisible] = React.useState<boolean>(false);
  const [newIssueStatus, setNewIssueStatus] = React.useState<number>(0);
  const [isPendingOrClosingProcess, setIsPendingOrClosingProcess] = React.useState<boolean>(false);

  const issueStatus =
    tripInspectionIssueStatusType &&
    translate(`vehicles.vehicleIssuesStatuses.${tripInspectionIssueStatusType.technicalName}`);
  const issueStatusIsPending =
    tripInspectionIssueStatusType && tripInspectionIssueStatusType.id === ISSUE_STATUS_PENDING;
  const issueStatusIsClosed = tripInspectionIssueStatusType && tripInspectionIssueStatusType.id === ISSUE_STATUS_CLOSED;

  const isViewOnlyOrSupport = !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS);

  const fleetMaintenanceConfiig = useSelector(
    (state: AppState) => state.vendors.fleetMaintenance.fleetMaintenance.tripIssuesNoteRequirementType,
  );
  const fleetMaintenanceConfigIsRequired = fleetMaintenanceConfiig?.id === REQUIRED;
  const fleetMaintenanceConfigIsOptional = fleetMaintenanceConfiig?.id === OPTIONAL;

  const showImage = () => {
    setImageVisible(true);
  };

  const hideImage = () => {
    setImageVisible(false);
  };

  const updateVehicleIssue = (status: number, vehicleNote?: string) => {
    if (fleetMaintenanceConfigIsRequired || fleetMaintenanceConfigIsOptional || !isPendingOrClosingProcess) {
      setVehicleNotesVisible(false);
    }

    Ducks.updateVehicleIssue(
      vehicleId,
      tripInspectionDetailId,
      status,
      vehicleNote,
    )(dispatch)
      .then(() => {
        createSuccessNotification(
          translate(
            status === ISSUE_STATUS_PENDING
              ? 'vehicles.issueMarkedPendingSuccess'
              : 'vehicles.issueMarkedClosedSuccess',
          ),
        );
        reloadIssues();
      })
      .catch(() => {
        createErrorNotification(
          translate(
            status === ISSUE_STATUS_PENDING ? 'vehicles.issueMarkedPendingFail' : 'vehicles.issueMarkedClosedFail',
          ),
        );
      });
  };

  const markIssueAsPendingOrClosed = (status: number) => {
    if (isFleetMaintenanceFeatureEnabled && (fleetMaintenanceConfigIsRequired || fleetMaintenanceConfigIsOptional)) {
      setNewIssueStatus(status);
      setVehicleNotesVisible(true);
      setIsPendingOrClosingProcess(true);
    } else {
      updateVehicleIssue(status);
    }
  };

  const openVehiclesNotesModal = () => {
    tripInspectionIssueStatusType && setNewIssueStatus(tripInspectionIssueStatusType.id);
    setVehicleNotesVisible(true);
    setIsPendingOrClosingProcess(false);
  };

  const closeVehicleNotesModal = () => {
    setVehicleNotesVisible(false);
  };

  return (
    <TableRow>
      <TableCell width="12%" padding="defaultCellVertical defaultCellHorizontal defaultCellVertical medium">
        {tripInspectionSubCategory.name}
      </TableCell>

      <TableCell vertical width="11%" align="center">
        <Text size="sMedium">{moment(occurrenceDate).format('MM/DD/YYYY')}</Text>

        <Text size="sMedium" color="grayDarker">
          {moment(occurrenceDate).format('LT')}
        </Text>
      </TableCell>

      <TableCell vertical width="12%" align="center">
        {!!resolvedOnDate && (
          <>
            <Text size="sMedium" align="right">
              {moment(resolvedOnDate).format('MM/DD/YYYY')}
            </Text>

            <Text size="sMedium" color="grayDarker" align="right">
              {moment(resolvedOnDate).format('LT')}
            </Text>
          </>
        )}
      </TableCell>

      <TableCell width="13%">{routeName}</TableCell>

      <TableCell width="12%">{driverName}</TableCell>

      <TableCell width="8%" align="center">
        {!!image && image.imageId && (
          <TableActionButton color="grayDarker" margin="no small no no" onClick={showImage}>
            <ActionButtonTooltip iconSize="mMedium" icon="redeye" tooltip="viewImage" />
          </TableActionButton>
        )}
      </TableCell>

      <TableCell width="11%">
        {issueStatus}

        {!!note && (
          <TableActionButton
            disabled
            color="grayDarker"
            hoverColor="grayDarker"
            margin="no no no xSmall"
            onClick={() => markIssueAsPendingOrClosed(ISSUE_STATUS_PENDING)}
          >
            <ActionButtonTooltip
              tooltipAsString
              color="grayDarker"
              iconSize="sMedium"
              icon="info"
              tooltip={translate('common.noteWithContent', { content: note })}
            />
          </TableActionButton>
        )}
      </TableCell>

      <TableCell vertical width="11%">
        {(issueStatusIsClosed || issueStatusIsPending) && (
          <>
            <Text margin="no no xxSmall no" size="sMedium">
              {lastModifiedBy}
            </Text>
            {moment(lastModifiedOn).format('MM/DD/YYYY LT')}
          </>
        )}
      </TableCell>

      <TableCell
        width="11%"
        align="right"
        padding="defaultCellVertical medium defaultCellVertical defaultCellHorizontal"
      >
        {!isViewOnlyOrSupport && !issueStatusIsClosed && (
          <TableActionButton
            color="grayDarker"
            margin="no small no no"
            onClick={() => markIssueAsPendingOrClosed(ISSUE_STATUS_CLOSED)}
          >
            <ActionButtonTooltip iconSize="mMedium" icon="checkCircleV2" tooltip="markClosed" />
          </TableActionButton>
        )}

        {!isViewOnlyOrSupport && !issueStatusIsPending && (
          <TableActionButton color="grayDarker" onClick={() => markIssueAsPendingOrClosed(ISSUE_STATUS_PENDING)}>
            <ActionButtonTooltip iconSize="mMedium" icon="pendingIssue" tooltip="markPending" />
          </TableActionButton>
        )}

        {!isViewOnlyOrSupport &&
          isFleetMaintenanceFeatureEnabled &&
          (issueStatusIsClosed || issueStatusIsPending) &&
          (fleetMaintenanceConfigIsRequired || fleetMaintenanceConfigIsOptional) && (
            <TableActionButton color="grayDarker" onClick={openVehiclesNotesModal}>
              <ActionButtonTooltip iconSize="mMedium" icon="note" tooltip="notes" />
            </TableActionButton>
          )}
      </TableCell>

      {!!image && imageVisible && (
        <RouteImagesModal
          images={[
            {
              imageUrl: image.url,
              rawImageUrl: image?.rawUrl || image.url,
              imageBlobId: image.blobId,
              routeName,
              timeStamp: occurrenceDate,
              issueName: tripInspectionSubCategory.name,
            },
          ]}
          closeModal={hideImage}
        />
      )}

      {vehicleNotesVisible && (
        <VehicleNotesModalResolver
          closeModal={closeVehicleNotesModal}
          fleetMaintenanceConfigIsOptional={fleetMaintenanceConfigIsOptional}
          isPendingOrClosingProcess={isPendingOrClosingProcess}
          modalTitle={tripInspectionSubCategory.name}
          newIssueStatus={newIssueStatus}
          tripInspectionDetailId={tripInspectionDetailId}
          updateVehicleIssue={updateVehicleIssue}
          vehicleId={vehicleId}
        />
      )}
    </TableRow>
  );
};

export default VehicleIssuesTableRow;
