import { AnyLayout } from 'mapbox-gl';
import { FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import { CITY_ALERTS_ICON_TYPES } from 'src/common/constants';
import { getAlertPaint, getAlertLayout } from 'src/fleet/components/pages/locationAndCityAlertsSections/utils';
import { getCityAlertsGeoJSON } from '../utils';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useSelector } from 'src/core/hooks/useSelector';

interface Props {
  map: mapboxgl.Map;
}

export const CITY_ALERTS_SOURCE_ID = 'city-alerts-source';
export const CITY_ALERTS_LAYER_ID = 'city-alerts-layer';

const mapImages = CITY_ALERTS_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: CITY_ALERTS_SOURCE_ID,
}));

const mapImagesOptions = { addLayers: false };

const CityAlertsGL: FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const cityAlerts = useSelector(state => state.vendors.cityAlerts?.cityAlerts);

  const cityAlertsGeoJson = useMemo(() => getCityAlertsGeoJSON(cityAlerts), [cityAlerts]);

  return (
    <Source id={CITY_ALERTS_SOURCE_ID} type="geojson" data={cityAlertsGeoJson}>
      <Layer
        id={CITY_ALERTS_LAYER_ID}
        type="symbol"
        source={CITY_ALERTS_SOURCE_ID}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], CITY_ALERTS_LAYER_ID]]}
        paint={getAlertPaint()}
        layout={getAlertLayout(['get', 'icon'], ['get', 'count']) as AnyLayout}
      />
    </Source>
  );
};

export default CityAlertsGL;
