import { http } from 'src/core/services/http';
import { QuickBooksAuthParams } from '../interfaces/QuickBooksTypes';

export const checkIsQuickBooksActive = () =>
  http.get<boolean>('/qbo/isQboActive').then(response => response.data);

export const quickBooksAuthorization = (clientId: string, clientSecret: string) =>
  http.post<any>('/qbo/authorization', {
    clientId,
    clientSecret,
  })
  .then(response => response.data);

export const completeQuickBooksSetup = (params: QuickBooksAuthParams) =>
  http.get<any>('/qbo/authresponse', {
    params: {
      code: params.code,
      state: params.state,
      realmId: params.realmId,
    }
  }).then(response => response.data);
