import { DragEvent, PureComponent } from 'react';

import { dispatchBoardJobDropAction } from '../../../../services/dispatchBoardJobDropAction';
import { DispatchBoardJobDropzone as DispatchBoardJobDropzoneContainer } from '../../../styled';
import { DispatchBoardRouteJob } from '../../../../interfaces/DispatchBoardRouteJob';
import { UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';

interface Props {
  targetRouteId: number;
  position: any;
  jobIndex: number;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
}

class DispatchBoardJobDropzone extends PureComponent<Props> {
  state = {
    isReadToDrop: false,
  };

  static defaultProps = {
    targetRouteId: UNASSIGNED_ROUTE_ID,
  };

  onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ isReadToDrop: true });
  };

  onDragLeave = () => {
    this.setState({ isReadToDrop: false });
  };

  onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ isReadToDrop: false });
    const { targetRouteId, jobIndex, onJobDrop } = this.props;
    dispatchBoardJobDropAction(event, targetRouteId, jobIndex + 1, onJobDrop);
  };

  render() {
    const { position } = this.props;
    const { isReadToDrop } = this.state;

    return (
      <DispatchBoardJobDropzoneContainer
        isReadyToDrop={isReadToDrop}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        position={position}
      />
    );
  }
}

export default DispatchBoardJobDropzone;
