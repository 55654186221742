import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

const getDefaultVendorFormInitialValues = (defaultVendorState: any) => ({
  vendorId: defaultVendorState.defaultVendor && defaultVendorState.defaultVendor.id,
});

const defaultVendorFormInitialValuesSelector = createSelector(getDefaultVendorFormInitialValues, identity);

export default defaultVendorFormInitialValuesSelector;
