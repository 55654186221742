import { camelCase } from 'lodash-es';
import { FC, useMemo, useState } from 'react';

import { date, time } from '../../../utils/services/formatter';
import { Image, ModalImage } from 'src/routes/interfaces/RouteImages';
import { RouteImagesModal } from './';
import { TableCell, TableRow, Text } from '../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { ThumbnailSet } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  cityAlertType: TechnicalType;
  address: string;
  createdOn: string;
  driverName: string;
  vehicleName: string;
  images: Image[];
}

const RouteTrackerCityAlertsModalTableRow: FC<Props> = (props: Props) => {
  const { cityAlertType, address, createdOn, driverName, vehicleName, images } = props;
  const [isRouteCityAlertImageModalOpen, setIsRouteCityAlertImageModalOpen] = useState(false);
  const imageUrls: string[] = [];

  if (images?.length) {
    images.forEach(image => {
      imageUrls.push(image.url);
    });
  }

  const openRouteCityAlertImageModal = () => {
    setIsRouteCityAlertImageModalOpen(true);
  };

  const closeRouteCityAlertImageModal = () => {
    setIsRouteCityAlertImageModalOpen(false);
  };

  const imagesToShow = useMemo(() => {
    return images.map(image => {
      return {
        customerName: driverName,
        timeStamp: createdOn,
        locationName: address,
        issueName: cityAlertType.technicalName,
        imageUrl: image.url,
        rawImageUrl: image.rawUrl,
        imageBlobId: image.blobId,
        imageTimingType: image.imageTimingType ? image.imageTimingType.technicalName : undefined,
      };
    });
  }, [address, cityAlertType.technicalName, createdOn, driverName, images]);

  return (
    <>
      <TableRow>
        <TableCell width="20%">{translate(`vendors.cityAlerts.${camelCase(cityAlertType.technicalName)}`)}</TableCell>
        <TableCell width="25%">
          <Text block weight="medium">
            {address}
          </Text>
        </TableCell>
        <TableCell width="20%">
          <Text block>
            {date(createdOn)}
            <Text block margin="xxSmall no no no">
              {time(createdOn)}
            </Text>
          </Text>
        </TableCell>
        <TableCell width="20%" vertical>
          <Text>{driverName}</Text>
          <Text margin="xxSmall no no no">{vehicleName}</Text>
        </TableCell>
        <TableCell width="15%" vertical>
          {!!imageUrls.length && <ThumbnailSet images={imageUrls} onClick={openRouteCityAlertImageModal} />}
        </TableCell>
      </TableRow>
      {isRouteCityAlertImageModalOpen && (
        <RouteImagesModal images={imagesToShow as unknown as ModalImage[]} closeModal={closeRouteCityAlertImageModal} />
      )}
    </>
  );
};

export default RouteTrackerCityAlertsModalTableRow;
