import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { saveStationaryAlert, resetStationaryAlerts } from '../../ducks';
import { StationaryAlertsForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
  isLoading: boolean;
  isSaving: boolean;
  resetStationaryAlerts: DuckAction<typeof resetStationaryAlerts>;
  saveStationaryAlert: DuckFunction<typeof saveStationaryAlert>;
  vendorId: number;
}

class StationaryAlertsModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetStationaryAlerts();
  }

  onSubmit = (formData: any) => {
    const { vendorId, saveStationaryAlert, closeModal } = this.props;
    const stationaryAlert = {
      ...formData,
      isActive: !!formData.emailList,
    };

    saveStationaryAlert(vendorId, stationaryAlert)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.stationaryAlertSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.saveStationaryAlertError')));
  };
  render() {
    const { isLoading, isSaving, closeModal } = this.props;

    return (
      <Modal verticalSize="small" padding="no" isLoading={isLoading || isSaving}>
        <ModalSection padding="medium medium small">
          <ModalTitle>{translate('vendors.featureCodes.stationaryAlerts')}</ModalTitle>
        </ModalSection>
        <StationaryAlertsForm onSubmit={this.onSubmit} onCancel={closeModal} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
  isLoading: state.vendors.stationaryAlerts.isLoading,
  isSaving: state.vendors.stationaryAlerts.isLoading,
});

const mapDispatchToProps = { saveStationaryAlert, resetStationaryAlerts };

export default connect(mapStateToProps, mapDispatchToProps)(StationaryAlertsModal);
