import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { RoutePlannerTemplatesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';

import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import { loadRoutePlannerRouteTemplateVehicles as doLoadRoutePlannerRouteTemplateVehicles } from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerRouteTemplateVehicles/START_LOAD';
const COMPLETE_LOAD = 'routePlannerRouteTemplateVehicles/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerRouteTemplateVehicles/FAIL_LOAD';
const RESET = 'routePlannerRouteTemplateVehicles/RESET';

interface State {
  isLoading: boolean;
  vehicles: RoutePlannerRouteTemplateVehicle[];
}

const initialState: State = {
  isLoading: false,
  vehicles: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: action.vehicles,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicles: RoutePlannerRouteTemplateVehicle[]) => ({
  type: COMPLETE_LOAD,
  vehicles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetRoutePlannerRouteTemplateVehicles = () => ({
  type: RESET,
});

export const loadRoutePlannerRouteTemplateVehicles =
  (params: RoutePlannerTemplatesPayload) => async (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRoutePlannerVehiclesPromise = doLoadRoutePlannerRouteTemplateVehicles(params);

    loadRoutePlannerVehiclesPromise
      .then((vehicles: RoutePlannerRouteTemplateVehicle[]) => dispatch(completeLoad(vehicles)))
      .catch(() => dispatch(failLoad()));

    return loadRoutePlannerVehiclesPromise;
  };
