import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const BLACK = 1;
export const BLUE = 2;
export const GREEN = 3;
export const GRAY = 4;
export const PINK = 5;
export const OTHER = 99;

export const BIN_COLORS_LIST = [
  { id: BLACK, name: translate('common.binColors.black') },
  { id: BLUE, name: translate('common.binColors.blue') },
  { id: GREEN, name: translate('common.binColors.green') },
  { id: GRAY, name: translate('common.binColors.gray') },
  { id: PINK, name: translate('common.binColors.pink') },
  { id: OTHER, name: translate('common.binColors.other') },
];

export const BIN_COLORS = mapKeys(BIN_COLORS_LIST, 'id');
