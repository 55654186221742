import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  initializeTravelPathBuildOrEdit,
  loadStreetNetworkForTravelPathModal,
  loadTravelPathForBuildOrEdit,
  loadTravelPathStatusDetails,
} from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import TravelPathEditorModal from './TravelPathEditorModal';
import { getBboxBasedOnFeatures } from './utils';

interface Props {
  closeModal: (shouldRefreshDisplayedTP: boolean) => void;
  date?: string;
  isAlternativeFleet?: boolean;
  isSnowPlow?: boolean;
  name: string;
  routeId?: number;
  routeTemplateId?: number;
}

const TravelPathEditorModalResolver: FC<Props> = ({
  closeModal,
  routeId,
  routeTemplateId,
  isAlternativeFleet,
  isSnowPlow,
  name,
  date,
}) => {
  const vendorId = useSelector(currentVendorId);

  const dispatch = useDispatch();

  const loadDependencies = useCallback(async () => {
    const dependencies = [];

    const configs = await initializeTravelPathBuildOrEdit(vendorId, false, routeId, routeTemplateId)(dispatch);

    if (configs?.result?.streets) {
      const travelPathData = await loadTravelPathForBuildOrEdit(vendorId, configs.result)(dispatch);
      let travelPathDataParsed = {
        features: [],
      };
      if (travelPathData?.result && !travelPathData?.error) {
        try {
          travelPathDataParsed = JSON.parse(travelPathData.result);
        } catch (error) {
          return Promise.reject();
        }
      }

      const bbox = getBboxBasedOnFeatures(travelPathDataParsed);

      dependencies.push(
        loadStreetNetworkForTravelPathModal(vendorId, bbox, configs.result)(dispatch),
        loadTravelPathStatusDetails(routeId, routeTemplateId)(dispatch),
      );
    } else return Promise.reject();

    return Promise.all(dependencies);
  }, [dispatch, routeId, routeTemplateId, vendorId]);

  return (
    <TypedResolver
      successComponent={TravelPathEditorModal}
      successProps={{ closeModal, routeId, routeTemplateId, isAlternativeFleet, name, date, isSnowPlow }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      onError={() => {
        closeModal(false);
      }}
    />
  );
};

export default TravelPathEditorModalResolver;
