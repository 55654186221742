import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const VALID_TRUE = true;
export const VALID_TRUE_ID = 1;
export const VALID_FALSE = false;
export const VALID_FALSE_ID = 0;

const keys = [
  { id: VALID_TRUE_ID, technicalName: 'correct', name: translate('inferenceAudit.inferenceAuditStatusAction.correct') },
  {
    id: VALID_FALSE_ID,
    technicalName: 'incorrect',
    name: translate('inferenceAudit.inferenceAuditStatusAction.incorrect'),
  },
];

export const INFERENCE_AUDIT_CORRECTNESS = mapKeys(keys, 'id');
