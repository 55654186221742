import React, { ChangeEvent } from 'react';

import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { Button, ButtonSet, PanelSection, Grid, GridColumn, Text } from '../../../core/components/styled';
import { Input, TypedField, Switch } from '../../../core/components';
import { isRequired, isTimeValid } from '../../../utils/services/validator';
import { normalizeAndReturnNumber } from '../../../core/services/normalizeNumber';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

function SOSAlertSettingForm({ change, form, handleSubmit }: InjectedFormProps) {
  const formValues: any = useSelector((state: AppState) => getFormValues(form)(state));
  const snoozeAlert = formValues.snoozeAlert;

  const changeTime = (field: string) => (_: ChangeEvent, value: string) => {
    change(field, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection vertical padding="small">
        <Grid margin="no no small no">
          <GridColumn size="3/12" verticalAlign="center" padding="no no small">
            <Text size="large" weight="medium">
              {translate(`vendors.sosAlert.snoozeAlert`)}
            </Text>
          </GridColumn>
          <GridColumn size="9/12" verticalAlign="center" padding="no no small">
            <TypedField name={'snoozeAlert'} component={Switch} />
          </GridColumn>
        </Grid>

        <Grid margin="no no small no">
          <GridColumn size="6/12">
            <TypedField
              name={'snoozeTime'}
              component={Input}
              validate={[isTimeValid]}
              props={{
                isTimeField: true,
                label: translate('vendors.sosAlert.snoozeTime'),
                margin: 'no no medium no',
                onTimeChange: changeTime('snoozeTime'),
                readOnly: !snoozeAlert,
              }}
            />
          </GridColumn>
        </Grid>

        <Grid margin="no no small no">
          <GridColumn size="3/12" verticalAlign="center" padding="no no small">
            <Text size="large" weight="medium">
              {translate(`vendors.sosAlert.silenceAlert`)}
            </Text>
          </GridColumn>
          <GridColumn size="9/12" verticalAlign="center" padding="no no small">
            <TypedField name={'silenceAlert'} component={Switch} />
          </GridColumn>
        </Grid>

        <Grid margin="no no small no">
          <GridColumn size="6/12" verticalAlign="center" padding="no no small">
            <TypedField
              name="dialNumber"
              component={Input}
              validate={[isRequired]}
              normalize={normalizeAndReturnNumber}
              props={{
                label: translate('vendors.sosAlert.emergencyPhoneNumber'),
                margin: 'no',
                maxLength: 15,
              }}
            />
          </GridColumn>
        </Grid>

        <Grid centered>
          <GridColumn size="12/12">
            <ButtonSet>
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
}

export default reduxForm({ form: 'sosAlertSettingForm', onSubmitFail: focusFirstInvalidField })(SOSAlertSettingForm);
