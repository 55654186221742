import { camelCase, indexOf, size } from 'lodash-es';
import { MouseEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import {
  Circle,
  Grid,
  GridColumn,
  Label,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
} from 'src/core/components/styled';
import {
  CUSTOMER_CIRCLE_COLOR,
  PICKUP_STATUS_LABEL_COLORS,
  ROUTE_PICKUP_TYPE_IDS,
  SCHEDULED,
} from 'src/routes/constants';
import { dateAndTime } from 'src/utils/services/formatter';
import { formatStopAddress, getRouteStopsTableCellWidths } from './utils';
import { getMapBounds } from 'src/common/components/map/util';
import { getShortServiceName } from 'src/routes/services/transformRoute';
import { isMaterialTypesFeatureEnabled } from 'src/vendors/ducks/features';
import { OTHER_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { PermissionGuard } from 'src/account/components';
import { PICKUP_STATUSES, WASTE_AUDIT } from 'src/common/constants';
import {
  RouteLocationImagesModalResolver,
  RouteLocationIssuesModalResolver,
  RouteLocationNotesModalResolver,
  StrobeImagesModalResolver,
} from '../../../../modals';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { ROUTES_TRACKER_VIEW_ADD_NOTE } from 'src/account/constants';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { setRouteMapSelectedFeature, setRouteMapViewport } from 'src/routes/ducks';
import { StatusContainer } from '../../../../styled';
import { TOP } from 'src/core/constants';
import { TOTER } from 'src/fleet/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { vehicleStrobeImagesStatusSelector } from 'src/vendors/ducks';
import createPopover from 'src/core/services/createPopover';
import JobPriorityTypeIndicator from '../../../common/JobPriorityTypeIndicator';
import RoutePageCartFillLevelPopover from '../../RoutePageCartFillLevelPopover';
import RoutePageStatusDetailsPopover from '../../RoutePageStatusDetailsPopover';
import translate from 'src/core/services/translate';

export default function RouteStopsTableRow({
  accountNumber,
  accountTypeId,
  assistingVehicleRegplate,
  binLatitude,
  binLongitude,
  cartFillLevelEnabled,
  customerName,
  equipmentSizeId,
  equipmentTypeId,
  id,
  isTemporaryContainer,
  jobPriorityTypeId,
  locationName,
  materialType,
  numberOfImages,
  numberOfIssues,
  numberOfNotes,
  orderNo,
  pickupStatusTypeId,
  pickupTypeId,
  reasonCodeTypeId,
  serviceTypeId,
  statusDate,
  street,
  streetNumber,
  vehicleId,
  vendorAccountNo,
  wasteAuditStatuses,
  wasteMaterialTypeId,
  wasteRecorders: wasterRecorders,
}: RouteStop) {
  const dispatch = useDispatch();
  const closeCartFillLevelPopover = useRef<() => void>();
  const [isRouteLocationIssuesModalOpen, setRouteLocationIssuesModalOpen] = useState(false);
  const [isRouteLocationNotesModalOpen, setRouteLocationNotesModalOpen] = useState(false);
  const [isRouteLocationImagesModalOpen, setRouteLocationImagesModalOpen] = useState(false);
  const [isRouteLocationStrobeImagesModalOpen, setRouteLocationStrobeImagesModalOpen] = useState(false);

  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { reasonCodeTypes } = useSelector(state => state.common.reasonCodeTypes);
  const { wasteTypes } = useSelector(state => state.common.wasteTypes);
  const { equipmentTypes } = useSelector(state => state.common.equipmentTypes);
  const { equipmentSizes } = useSelector(state => state.common.equipmentSizes);
  const { wasteAuditTypes } = useSelector(state => state.common.wasteAuditTypes);
  const isMaterialTypesEnabled = useSelector(isMaterialTypesFeatureEnabled);
  const vehicleStrobeImagesEnabled = useSelector(state =>
    vehicleStrobeImagesStatusSelector(state.vendors.features.features),
  );

  if (!routeSummary) return null;

  const tableCellWidths = getRouteStopsTableCellWidths(routeSummary.vehicleTypeId);

  const equipmentType = equipmentTypes.find(equipmentType => equipmentType.id === equipmentTypeId);
  const equipmentSize = equipmentSizes.find(equipmentSize => equipmentSize.id === equipmentSizeId);
  const reasonCodeName = reasonCodeTypes.find(code => code.id === reasonCodeTypeId)?.name;
  const wasteTypeName = wasteTypes.find(wasteType => wasteType.id === wasteMaterialTypeId)?.name;
  const wasteAuditType = wasteAuditTypes.find(wasteAuditType => wasteAuditType.id === routeSummary.wasteAuditTypeId);
  const serviceCount = wasterRecorders?.length
    ? wasterRecorders.reduce((sum, wasterRecorder) => sum + wasterRecorder.numberOfBins, 0)
    : undefined;

  const showRoutePageStatusDetailsPopover = (event: MouseEvent<HTMLSpanElement>) => {
    if (indexOf([WASTE_AUDIT, TOTER], routeSummary.vehicleTypeName) === -1 || size(wasterRecorders)) return;

    event.stopPropagation();
    createPopover(
      event.currentTarget,
      RoutePageStatusDetailsPopover,
      { wasterRecorders, wasteAuditStatuses, vehicleTypeTechnicalName: routeSummary.vehicleTypeName, wasteAuditType },
      { position: TOP, size: 'small' },
    );
  };

  const showRoutePageCartFillLevelPopover = (event: MouseEvent<HTMLSpanElement>) => {
    if (!wasterRecorders?.length || routeSummary.vehicleTypeName !== TOTER || !cartFillLevelEnabled) return;
    event.stopPropagation();
    closeCartFillLevelPopover.current = createPopover(
      event.currentTarget,
      RoutePageCartFillLevelPopover,
      { wasterRecorders },
      { position: TOP, size: 'small' },
    );
  };

  const closeRoutePageCartFillLevelPopover = () => {
    if (closeCartFillLevelPopover.current) closeCartFillLevelPopover.current();
  };

  const handleClick = (event: any) => {
    const isTableRowClicked = event ? event.target.parentNode.tagName === 'DIV' : false;

    if (isTableRowClicked) {
      const point = { latitude: binLatitude, longitude: binLongitude };
      const bounds = getMapBounds([point]);

      dispatch(setRouteMapViewport(bounds));
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, id));
    }
  };

  const address = formatStopAddress(locationName, street, streetNumber, vendorAccountNo);
  const customer = `${customerName} ${accountNumber ? `(${accountNumber})` : ''}`.trim();

  return (
    <>
      <TableRow onClick={handleClick} height={65}>
        <TableCell width={tableCellWidths[0]}>{orderNo < 0 ? '' : orderNo}</TableCell>
        <TableCell width={tableCellWidths[1]}>
          <Grid>
            <GridColumn width="3%" padding="no">
              <Circle color={CUSTOMER_CIRCLE_COLOR[accountTypeId]} />
            </GridColumn>
            <GridColumn width="95%">
              <Text title={customer} block singleLine weight="medium" margin="no no xxSmall">
                {customer}
              </Text>
              <Text title={address} block singleLine size="small">
                {address}
              </Text>
              {isTemporaryContainer && (
                <Text title={translate('routes.temporary')} block singleLine size="small" color="primary">
                  {translate('routes.temporary')}
                </Text>
              )}
            </GridColumn>
          </Grid>
        </TableCell>

        <TableCell width={tableCellWidths[2]}>
          <Grid>
            <GridColumn width="100%" padding="no">
              <Text title={getShortServiceName(serviceTypeId, equipmentType, equipmentSize)} block singleLine>
                {getShortServiceName(serviceTypeId, equipmentType, equipmentSize)}
              </Text>
              <Text title={wasteTypeName} block singleLine>
                {wasteTypeName}
              </Text>
            </GridColumn>
          </Grid>
        </TableCell>

        <TableCell vertical padding="defaultCellVertical no" align="center" width={tableCellWidths[3]}>
          <Text block weight="medium" margin="no">
            {ROUTE_PICKUP_TYPE_IDS[pickupTypeId]?.name}
          </Text>

          {isMaterialTypesEnabled && materialType?.technicalName && (
            <Text block size="small" margin="no">
              {materialType?.categoryId === OTHER_ID
                ? materialType?.technicalName
                : translate(`vendors.bulkyItemScheduler.itemTypes.${camelCase(materialType?.technicalName)}`)}
            </Text>
          )}

          {reasonCodeName && (
            <Text block size="small" margin="no">
              {reasonCodeName}
            </Text>
          )}
        </TableCell>

        {routeSummary.vehicleTypeName === TOTER && (
          <TableCell width={tableCellWidths[4]}>
            <Text
              block
              size="medium"
              margin="xxSmall no no"
              onMouseOver={showRoutePageCartFillLevelPopover}
              onMouseOut={closeRoutePageCartFillLevelPopover}
            >
              {serviceCount !== null && serviceCount !== undefined && `${serviceCount} ${translate('routes.carts')}`}
            </Text>
          </TableCell>
        )}

        <TableCell width={tableCellWidths[5]} vertical align="center">
          <StatusContainer>
            <Box display="flex" alignItems="center">
              <Label color={PICKUP_STATUS_LABEL_COLORS[pickupStatusTypeId]} onClick={showRoutePageStatusDetailsPopover}>
                {PICKUP_STATUSES[pickupStatusTypeId].name}
              </Label>
              <Box ml={5}>
                <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={jobPriorityTypeId} />
              </Box>
            </Box>

            {assistingVehicleRegplate && (
              <TableActionButton margin="no small">
                <ActionButtonTooltip
                  icon="infoFill"
                  tooltipProps={{ vehicleName: assistingVehicleRegplate }}
                  tooltip="assistedBy"
                />
              </TableActionButton>
            )}
          </StatusContainer>

          {statusDate && (
            <Text block size="small" margin="xxSmall no no">
              {dateAndTime(statusDate)}
            </Text>
          )}
        </TableCell>

        <TableCell width={tableCellWidths[6]} align="right">
          {!!numberOfIssues && routeSummary.vehicleTypeName !== WASTE_AUDIT && (
            <TableActionButton onClick={() => setRouteLocationIssuesModalOpen(true)}>
              <ActionButtonTooltip icon="issue" tooltip="issues" content={numberOfIssues} />
            </TableActionButton>
          )}

          {wasteAuditStatuses &&
            !!wasteAuditStatuses.length &&
            routeSummary.vehicleTypeName === WASTE_AUDIT &&
            !!numberOfIssues && (
              <TableActionButton onClick={() => setRouteLocationIssuesModalOpen(true)}>
                <ActionButtonTooltip icon="issue" tooltip="issues" content={numberOfIssues} />
              </TableActionButton>
            )}

          <PermissionGuard permission={ROUTES_TRACKER_VIEW_ADD_NOTE}>
            <TableActionButton onClick={() => setRouteLocationNotesModalOpen(true)}>
              <ActionButtonTooltip icon="note" tooltip="notes" content={numberOfNotes || undefined} />
            </TableActionButton>
          </PermissionGuard>

          {!!numberOfImages && (
            <TableActionButton onClick={() => setRouteLocationImagesModalOpen(true)}>
              <ActionButtonTooltip icon="image" tooltip="images" content={numberOfImages} />
            </TableActionButton>
          )}

          {vehicleStrobeImagesEnabled &&
            routeSummary.hasStrobeData &&
            routeSummary.vehicleTypeName !== WASTE_AUDIT &&
            pickupStatusTypeId !== SCHEDULED &&
            !!statusDate &&
            routeSummary.strobeVehiclesIds?.includes(vehicleId) && (
              <TableActionButton onClick={() => setRouteLocationStrobeImagesModalOpen(true)}>
                <ActionButtonTooltip icon="film" tooltip="strobeImages" />
              </TableActionButton>
            )}
        </TableCell>
      </TableRow>

      {isRouteLocationIssuesModalOpen && (
        <RouteLocationIssuesModalResolver
          modalTitle={customerName}
          modalSubTitle={locationName}
          routeId={routeSummary.routeId}
          routeLocationId={id}
          closeModal={() => setRouteLocationIssuesModalOpen(false)}
          wasteAuditType={wasteAuditType}
          vehicleTypeId={routeSummary.vehicleTypeId}
        />
      )}

      {isRouteLocationNotesModalOpen && (
        <RouteLocationNotesModalResolver
          modalTitle={customerName}
          modalSubTitle={locationName}
          routeId={routeSummary.routeId}
          routeLocationId={id}
          closeModal={() => setRouteLocationNotesModalOpen(false)}
        />
      )}

      {isRouteLocationImagesModalOpen && (
        <RouteLocationImagesModalResolver
          routeId={routeSummary.routeId}
          routeLocationId={id}
          closeModal={() => setRouteLocationImagesModalOpen(false)}
        />
      )}

      {isRouteLocationStrobeImagesModalOpen && (
        <StrobeImagesModalResolver
          vehicleId={vehicleId}
          vehicleName={assistingVehicleRegplate || routeSummary.vehicleName}
          timeStamp={statusDate}
          closeModal={() => setRouteLocationStrobeImagesModalOpen(false)}
        />
      )}
    </>
  );
}
