import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import {
  ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER,
  ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
  ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE,
} from '../constants';
import { VehicleTrackingFeatureProperties } from './utils';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>;
  isPlaybackMode?: boolean;
};

export default memo(function RouteTimelineMapVehicleTrackingsGLSource({ geoJSON, isPlaybackMode }: Props) {
  return (
    <Source type="geojson" id={ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        beforeId={isPlaybackMode ? ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER : ROUTE_MAP_VEHICLE_POSITIONS_LAYER}
        id="allVehicleTrackingsArrows"
        type="symbol"
        source={ROUTE_MAP_VEHICLE_TRACKINGS_SOURCE}
        layout={{
          'symbol-placement': 'line',
          'symbol-spacing': ['interpolate', ['exponential', 1], ['zoom'], 1, 1, 16, 50],
          'icon-image': 'arrow',
          'icon-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 1, 30, 4],
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-ignore-placement': true,
        }}
        paint={{
          'icon-color': ['get', 'color'],
        }}
      />
    </Source>
  );
});
