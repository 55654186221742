import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  DriverSafetyInspectionSettings,
  SupervisorExperienceSettings,
  SupervisorExperienceSettingsState,
} from '../interfaces/SupervisorExperienceSettings';
import {
  loadSupervisorExperienceSettings as doLoadSupervisorExperienceSettings,
  saveSupervisorExperienceSettings as doSaveSupervisorExperienceSettings,
  loadDriverSafetyInspectionSettings as doLoadDriverSafetyInspectionSettings,
  saveDriverSafetyInspectionSettings as doSaveDriverSafetyInspectionSettings,
} from '../services/supervisorExperienceSettings';

// Actions
const START_LOAD = 'vendors/supervisorExperienceSettings/START_LOAD';
const COMPLETE_LOAD = 'vendors/supervisorExperienceSettings/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/supervisorExperienceSettings/FAIL_LOAD';
const START_SAVE = 'vendors/supervisorExperienceSettings/START_SAVE';
const COMPLETE_SAVE = 'vendors/supervisorExperienceSettings/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/supervisorExperienceSettings/FAIL_SAVE';
const START_LOAD_SAFETY_SETTINGS = 'vendors/supervisorExperienceSettings/START_LOAD_SAFETY_SETTINGS';
const COMPLETE_LOAD_SAFETY_SETTINGS = 'vendors/supervisorExperienceSettings/COMPLETE_LOAD_SAFETY_SETTINGS';
const FAIL_LOAD_SAFETY_SETTINGS = 'vendors/supervisorExperienceSettings/FAIL_LOAD_SAFETY_SETTINGS';
const START_SAVE_SAFETY_SETTINGS = 'vendors/supervisorExperienceSettings/START_SAVE';
const COMPLETE_SAVE_SAFETY_SETTINGS = 'vendors/supervisorExperienceSettings/COMPLETE_SAVE';
const FAIL_SAVE_SAFETY_SETTINGS = 'vendors/supervisorExperienceSettings/FAIL_SAVE';

// Initial state
const initialState: SupervisorExperienceSettingsState = {
  driverSafetyInspectionSettings: undefined,
  isLoading: false,
  isSaving: false,
  supervisorExperienceSettings: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          supervisorExperienceSettings: action.supervisorExperienceSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_SAFETY_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_SAFETY_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: false,
          driverSafetyInspectionSettings: action.driverSafetyInspectionSettings,
        },
      });

    case FAIL_LOAD_SAFETY_SETTINGS:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE_SAFETY_SETTINGS:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE_SAFETY_SETTINGS:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE_SAFETY_SETTINGS:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (supervisorExperienceSettings: SupervisorExperienceSettings) => ({
  type: COMPLETE_LOAD,
  supervisorExperienceSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadDriverSafetyInspectionSettings = () => ({
  type: START_LOAD_SAFETY_SETTINGS,
});

const completeLoadDriverSafetyInspectionSettings = (
  driverSafetyInspectionSettings: DriverSafetyInspectionSettings[],
) => ({
  type: COMPLETE_LOAD_SAFETY_SETTINGS,
  driverSafetyInspectionSettings,
});

const failLoadDriverSafetyInspectionSettings = () => ({
  type: FAIL_LOAD_SAFETY_SETTINGS,
});

const startSaveDriverSafetyInspectionSettings = () => ({
  type: START_SAVE_SAFETY_SETTINGS,
});

const completeSaveDriverSafetyInspectionSettings = () => ({
  type: COMPLETE_SAVE_SAFETY_SETTINGS,
});

const failSaveDriverSafetyInspectionSettings = () => ({
  type: FAIL_SAVE_SAFETY_SETTINGS,
});

export const loadSupervisorExperienceSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadSupervisorExperienceSettingsPromise = doLoadSupervisorExperienceSettings(vendorId);

  loadSupervisorExperienceSettingsPromise
    .then(supervisorExperienceSettings => dispatch(completeLoad(supervisorExperienceSettings)))
    .catch(() => dispatch(failLoad()));

  return loadSupervisorExperienceSettingsPromise;
};

export const saveSupervisorExperienceSettings =
  (vendorId: number, supervisorExperienceSettings: SupervisorExperienceSettings) => (dispatch: Dispatch) => {
    dispatch(startSave());

    const saveSupervisorExperienceSettingsPromise = doSaveSupervisorExperienceSettings(
      vendorId,
      supervisorExperienceSettings,
    );

    saveSupervisorExperienceSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveSupervisorExperienceSettingsPromise;
  };

export const loadDriverSafetyInspectionSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadDriverSafetyInspectionSettings());

  const loadDriverSafetyInspectionSettingsPromise = doLoadDriverSafetyInspectionSettings(vendorId);

  loadDriverSafetyInspectionSettingsPromise
    .then((driverSafetyInspectionSettings: DriverSafetyInspectionSettings[]) =>
      dispatch(completeLoadDriverSafetyInspectionSettings(driverSafetyInspectionSettings)),
    )
    .catch(() => dispatch(failLoadDriverSafetyInspectionSettings()));

  return loadDriverSafetyInspectionSettingsPromise;
};

export const saveDriverSafetyInspectionSettings =
  (vendorId: number, driverSafetyInspectionSettings: DriverSafetyInspectionSettings[]) => (dispatch: Dispatch) => {
    dispatch(startSaveDriverSafetyInspectionSettings());

    const saveDriverSafetyInspectionSettingsPromise = doSaveDriverSafetyInspectionSettings(
      vendorId,
      driverSafetyInspectionSettings,
    );

    saveDriverSafetyInspectionSettingsPromise
      .then(() => dispatch(completeSaveDriverSafetyInspectionSettings()))
      .catch(() => dispatch(failSaveDriverSafetyInspectionSettings()));

    return saveDriverSafetyInspectionSettingsPromise;
  };
