import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Resolver } from '../../../core/components';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { reportDateSelector } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { PageLoading } from '../../../common/components/styled';
import { loadOnCallServices, loadOnCallServicesIssueType } from '../../ducks/onCallServices';
import OnCallServicesPage from './OnCallServicesPage';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  vendorId?: number;
  date?: string;
  loadOnCallServices(...args: any[]): any;
  loadOnCallServicesIssueType(...args: any[]): any;
}

class OnCallServicesPageResolver extends PureComponent<Props> {
  loadDependancies = () => {
    const {
      vendorId,
      date,
      loadOnCallServices,
      loadOnCallServicesIssueType,
      match: {
        params: { token },
      },
    } = this.props;

    return Promise.all([loadOnCallServices(vendorId, token, date), loadOnCallServicesIssueType(vendorId, token)]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('payment.payments')}</DocumentTitle>
        <Resolver
          successComponent={OnCallServicesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependancies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  date: reportDateSelector(state.payments),
});

const mapDispatchToProps = {
  loadOnCallServices,
  loadOnCallServicesIssueType,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnCallServicesPageResolver);
