import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadRouteAssist as doLoadRouteAssist,
  loadSnowSweeperRouteAssist as doLoadSnowSweeperRouteAssist,
} from '../services/routeAssist';
import { RouteAssistData } from '../interfaces/RouteAssist';

const START_LOAD = 'routes/routeAssist/START_LOAD';
const COMPLETE_LOAD = 'routes/routeAssist/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeAssist/FAIL_LOAD';
const RESET = 'routes/routeAssist/RESET';

interface State {
  isLoading: boolean;
  routeAssist?: RouteAssistData;
}

const initialState: State = {
  isLoading: false,
  routeAssist: undefined,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeAssist: action.routeAssist,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeAssist: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeAssist: RouteAssistData) => ({
  type: COMPLETE_LOAD,
  routeAssist,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteAssistData = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRouteAssistPromise = doLoadRouteAssist(vendorId, routeId);
  loadRouteAssistPromise.then(routeAssist => dispatch(completeLoad(routeAssist))).catch(() => dispatch(failLoad()));
  return loadRouteAssistPromise;
};

export const loadSnowSweeperRouteAssistData =
  (vendorId: number, routeId: number, startDate?: Date | string, endDate?: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRouteAssistPromise = doLoadSnowSweeperRouteAssist(vendorId, routeId, startDate, endDate);
    loadRouteAssistPromise.then(routeAssist => dispatch(completeLoad(routeAssist))).catch(() => dispatch(failLoad()));
    return loadRouteAssistPromise;
  };

export const resetRouteAssistData = () => ({
  type: RESET,
});
