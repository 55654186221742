
import { useSelector } from "src/core/hooks/useSelector";
import { DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, DashboardExtraMapLayersFormValues } from "../../dashboardPageSections/dashboardWidgets/ExtraMapLayersWidgetContent";
import { getFormValues } from "redux-form";
import { getFeatureCollection } from 'src/common/components/map/util';
import { CityInsight } from 'src/dashboard/interfaces/cityInsights';
import { GeoFenceIncidentsItem, RouteVehiclePosition } from 'src/dashboard/interfaces/routesData';
import { VehicleInsightDetails, VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import { useCheckIfCityInsightsAreVisibleMapbox } from 'src/dashboard/utils/dashboardMapbox';
import { useMemo } from "react";
import { getVehiclesListPositionsGeoJSON } from "../../dashboardPageSections/mapGL/dashboardVehiclePositions/utils";
import { getDashboardMapboxVehicleInsightsGeoJSON, getGeoFenceIncidentsGeoJSON } from "../../dashboardPageSections/mapGL/dashboardVehicleInsights/utils";
import { getApplicationStatusForRouteGeoJSON, getApplicationStatusForVehicleGeoJSON } from "../../dashboardPageSections/mapGL/dashboardApplicationStatus/utils";
import { size } from "lodash-es";
import { getRouteStopsGeoJSON } from "../../dashboardPageSections/mapGL/dashboardRouteStops/utils";
import { getCityInsightsGeoJSON } from "../../dashboardPageSections/mapGL/dashboardCityInsights/utils";
import { getCityAlertsGeoJSON } from "../../dashboardPageSections/mapGL/dashboardCityAlerts/utils";
import { getMapboxHaulerLocationsGeoJSON } from "../../dashboardPageSections/mapGL/dashboardHaulerLocations/utils";
import { EsriRouteStops } from "./EsriRouteStops";

interface Props {
    selectedVehicleId?: number;
  }
  

const EsriRoutesData = ({selectedVehicleId }: Props) => {
    const extraMapLayersFormValues = useSelector(
      getFormValues(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME),
    ) as DashboardExtraMapLayersFormValues;
  
    const showAppStatusForVehicle = useSelector(state => state.dashboard.vehiclesData.showAppStatus);
    const showAppStatusForRoute = useSelector(state => state.dashboard.routesData.showAppStatus);
    const vehicleTrackingsForVehicle = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs);
    const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);
    const routeDriversVehicles = useSelector(state => state.dashboard.routesData.routeDriversVehicles);
    const cityAlerts = useSelector(state => state.vendors.cityAlerts.cityAlerts);
  
    const vehiclePositions = useSelector(state => (state.dashboard.vehiclesData.vehiclesList || []) as VehiclePosition[]);
  
    const routeVehiclePositions = useSelector(
      state => (state.dashboard.routesData.routeVehiclePositions || []) as RouteVehiclePosition[],
    );
  
    const geoFenceIncidents = useSelector(
      state => state.dashboard.routesData.geoFenceIncidents || [],
    ) as GeoFenceIncidentsItem[];
  
    const vehicleInsights = useSelector(
      state => (state.dashboard.vehiclesData.vehicleInsightDetails || []) as VehicleInsightDetails[],
    );
  
    const cityInsights = useSelector(
      state => ((state.dashboard.mapInsightsMapbox?.mapInsights as any)?.cityInsights.insights || []) as CityInsight[],
    );
    const haulerLocations = useSelector(state => state.dashboard.vendorLocations.vendorLocationsToDisplay);
  
    const routeStops = useSelector(state => state.dashboard.routesData.mapRouteStops);
  
    const cityInsightsAreVisible = useCheckIfCityInsightsAreVisibleMapbox();
  
    const geoJSON = useMemo(() => {
      let collection = getFeatureCollection<GeoJSON.Point, any>([]);
  
      if (routeVehiclePositions.length) {
        const vehiclePositionsCollection = getVehiclesListPositionsGeoJSON(routeVehiclePositions);
        collection.features = collection.features.concat(vehiclePositionsCollection.features);
      }
  
      if (geoFenceIncidents.length) {
        const geoFenceIncidentsCollection = getGeoFenceIncidentsGeoJSON(geoFenceIncidents);
  
        collection.features = collection.features.concat(geoFenceIncidentsCollection.features);
      }
  
      if (showAppStatusForVehicle && !!size(vehicleTrackingsForVehicle.coords)) {
        const applicationStatusCollection = getApplicationStatusForVehicleGeoJSON(vehicleTrackingsForVehicle);
        collection.features = collection.features.concat(applicationStatusCollection.features);
      }
  
      if (showAppStatusForRoute && vehicleTrackingsForRoute && !!size(vehicleTrackingsForRoute?.vehicles)) {
        const applicationStatusCollection = getApplicationStatusForRouteGeoJSON(
          vehicleTrackingsForRoute,
          showAppStatusForRoute,
          routeDriversVehicles,
        );
        collection.features = collection.features.concat(applicationStatusCollection.features);
      }
  
      if (vehicleInsights.length) {
        const vehicleInsightsCollection = getDashboardMapboxVehicleInsightsGeoJSON(vehicleInsights);
  
        collection.features = collection.features.concat(vehicleInsightsCollection.features);
      }
  
      if (routeStops && routeStops.length) {
        const routeStopsCollection = getRouteStopsGeoJSON(routeStops);
  
        collection.features = collection.features.concat(routeStopsCollection.features);
      }
  
      if (cityInsightsAreVisible && cityInsights.length) {
        const cityInsightsCollection = getCityInsightsGeoJSON(cityInsights);
  
        collection.features = collection.features.concat(cityInsightsCollection.features);
      }
  
      if (cityAlerts.length) {
        const cityAlertsCollection = getCityAlertsGeoJSON(cityAlerts);
  
        collection.features = collection.features.concat(cityAlertsCollection.features);
      }
  
      if (haulerLocations.length && extraMapLayersFormValues?.isHaulerLocationsLayerOn) {
        const haulerLocationsCollection = getMapboxHaulerLocationsGeoJSON(haulerLocations);
  
        collection.features = collection.features.concat(haulerLocationsCollection.features);
      }
  
      if (vehiclePositions.length) {
        if (selectedVehicleId) {
          const selectedVehiclePosition = vehiclePositions.find(
            vehiclePosition => vehiclePosition.id === selectedVehicleId,
          );
          if (selectedVehiclePosition) {
            const vehiclePositionsCollection = getVehiclesListPositionsGeoJSON([selectedVehiclePosition]);
            collection.features = collection.features.concat(vehiclePositionsCollection.features);
          }
        } else {
          const vehiclePositionsCollection = getVehiclesListPositionsGeoJSON(vehiclePositions);
          collection.features = collection.features.concat(vehiclePositionsCollection.features);
        }
      }
  
      return collection;
    }, [
      routeVehiclePositions,
      geoFenceIncidents,
      showAppStatusForVehicle,
      vehicleTrackingsForVehicle,
      showAppStatusForRoute,
      vehicleTrackingsForRoute,
      vehicleInsights,
      routeStops,
      cityInsightsAreVisible,
      cityInsights,
      cityAlerts,
      haulerLocations,
      extraMapLayersFormValues?.isHaulerLocationsLayerOn,
      vehiclePositions,
      routeDriversVehicles,
      selectedVehicleId,
    ]);

    return (
        <EsriRouteStops selectedVehicleId={selectedVehicleId} geoJSON={geoJSON} />
    )
  
}

export default EsriRoutesData