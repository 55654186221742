import { useEffect, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { EquipmentTypeDropdown } from 'src/common/components';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { Dropdown, SimpleInput, TypedField } from 'src/core/components';
import { MultiSelectValues } from 'src/core/components/MultiSelectEnhanced';
import { GridColumn } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { FilterFunctions } from 'src/routes/components/pages/dispatchBoard/dispatchBoardPageSections/common/useDispatchBoardFilters';
import { FilterInlineWrapper } from 'src/routes/components/styled';
import { loadPickupTypes } from 'src/routes/ducks/pickupTypes';
import { AppState } from 'src/store';
import {
  isBulkyItemSchedulerFeatureEnabled,
  isMaterialTypesFeatureEnabled,
  supervisorExperienceFeatureIsEnabled,
} from 'src/vendors/ducks/features';
import { MaterialTypesMultiSelect, PickupTypeDropdown, ServiceZonesMultiSelect, SupervisorsMultiSelect } from '..';

interface PropsWithoutReduxForm {
  filterFunctions: FilterFunctions;
  isBulkyItemSchedulerEnabled?: boolean;
  isMaterialTypesEnabled?: boolean;
  supervisorExperienceEnabled?: boolean;
  vehicleTypeId: number;
}

interface FormData {
  equipmentType?: string;
  pickupTypeId?: number;
  reasonCodeTypeId?: number;
  searchTerm?: string;
  serviceZones: number[];
  supervisors: number[];
  materialTypes: MultiSelectValues;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const DispatchBoardJobsForm = (props: Props) => {
  const {
    filterFunctions,
    isBulkyItemSchedulerEnabled,
    isMaterialTypesEnabled,
    handleSubmit,
    vehicleTypeId,
    supervisorExperienceEnabled,
  } = props;

  const dispatch = useDispatch();

  const [pickupTypes, setPickupTypes] = useState<TechnicalType[]>([]);

  useEffect(() => {
    async function asyncLoadPickupTypes() {
      const pickupTypesPromise = await loadPickupTypes(vehicleTypeId)(dispatch);
      setPickupTypes(pickupTypesPromise[0].pickupTypes);
    }
    asyncLoadPickupTypes();
  }, [dispatch, vehicleTypeId]);

  const reasonCodeTypeOptions = useSelector((state: AppState) =>
    state.common.reasonCodeTypes.reasonCodeTypes.map(reasonCodeType => ({
      label: reasonCodeType.name,
      value: reasonCodeType.id,
    })),
  );

  return (
    <form onSubmit={handleSubmit}>
      <TypedField
        name="searchTerm"
        component={SimpleInput}
        onChange={filterFunctions.onSearchTermChange}
        props={{
          placeholder: translate('common.search'),
          margin: 'no',
          padding: 'xSmall small',
          withBorder: true,
          withBoxShadow: true,
        }}
      />
      <FilterInlineWrapper flexWrap>
        <GridColumn size="4/12" padding="xSmall xSmall sMedium no">
          <TypedField
            name="equipmentType"
            component={EquipmentTypeDropdown}
            onChange={filterFunctions.onEquipmentTypeFilterChange}
            props={{
              byName: true,
              dropdownProps: {
                isClearable: true,
                margin: 'no small no no',
                onMenuClose: filterFunctions.onFilterBlur,
                onMenuOpen: filterFunctions.onFilterFocus,
                width: '100%',
              },
              withPlaceholder: true,
            }}
          />
        </GridColumn>
        <GridColumn size="4/12" padding="xSmall xSmall sMedium no">
          <TypedField
            name="pickupTypeId"
            component={PickupTypeDropdown}
            onChange={filterFunctions.onPickupTypeFilterChange}
            props={{
              dropdownProps: {
                isClearable: true,
                margin: 'no small no no',
                onMenuClose: filterFunctions.onFilterBlur,
                onMenuOpen: filterFunctions.onFilterFocus,
                width: '100%',
              },
              pickupTypes,
              withPlaceholder: true,
            }}
          />
        </GridColumn>
        <GridColumn size="4/12" padding="xSmall xSmall sMedium no">
          <TypedField
            name="reasonCodeTypeId"
            component={Dropdown}
            onChange={filterFunctions.onReasonCodeTypeIdFilterChange}
            props={{
              isClearable: true,
              onMenuClose: filterFunctions.onFilterBlur,
              onMenuOpen: filterFunctions.onFilterFocus,
              options: reasonCodeTypeOptions,
              margin: 'no',
              placeholder: translate('common.reasonCode'),
              width: '100%',
            }}
          />
        </GridColumn>
        <GridColumn size="6/12" padding="xSmall xSmall no no">
          <TypedField
            onChange={filterFunctions.onServiceZonesFilterChange}
            name="serviceZones"
            component={ServiceZonesMultiSelect}
            props={{
              placeholder: translate('routes.allServiceZones'),
              multiSelectProps: {
                normalizeValues: Number,
                fitContentWidth: true,
              },
            }}
          />
        </GridColumn>

        {supervisorExperienceEnabled && (
          <GridColumn size="6/12" padding="xSmall no no xSmall">
            <TypedField
              onChange={filterFunctions.onSupervisorsFilterChange}
              name="supervisors"
              component={SupervisorsMultiSelect}
              props={{
                placeholder: translate('routes.allSupervisors'),
                multiSelectProps: {
                  normalizeValues: Number,
                },
              }}
            />
          </GridColumn>
        )}
        {(isMaterialTypesEnabled || (isBulkyItemSchedulerEnabled && vehicleTypeId === DELIVERY_UTILITY_ID)) && (
          <GridColumn size="6/12" padding="xSmall xSmall no no">
            <TypedField
              onChange={filterFunctions.onMaterialTypesFilterChange}
              name="materialTypes"
              component={MaterialTypesMultiSelect}
              props={{
                includeNoneOption: true,
                placeholder: translate('vendors.materialTypes.allMaterialTypes'),
                multiSelectProps: {
                  normalizeValues: Number,
                  fitContentWidth: true,
                },
              }}
            />
          </GridColumn>
        )}
      </FilterInlineWrapper>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
  isMaterialTypesEnabled: isMaterialTypesFeatureEnabled(state),
  isBulkyItemSchedulerEnabled: isBulkyItemSchedulerFeatureEnabled(state),
});

export default connect(mapStateToProps)(reduxForm<FormData, PropsWithoutReduxForm>({})(DispatchBoardJobsForm));
