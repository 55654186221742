import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { get, find } from 'lodash-es';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { CustomerNotificationForm } from '../forms';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { EmailTemplate, NotificationTypes } from '../../interfaces/CustomerNotifications';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveNotificationEmailTemplate, resetNotificationEmailTemplates } from '../../ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  vendorId: number;
  isLoading: boolean;
  isSaving: boolean;
  saveNotificationEmailTemplate: DuckFunction<typeof saveNotificationEmailTemplate>;
  resetNotificationEmailTemplates: DuckAction<typeof resetNotificationEmailTemplates>;
  notificationTypes: NotificationTypes[];
}

class CustomerNotificationModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetNotificationEmailTemplates();
  }
  /**
   * name: ServiceCompletedNotification
   * VendorEmailTemplateNotificationTypeId: id for notification type
   * pickupExceptionTypeId: reasonTypeId
   */
  onSubmit = (emailTemplate: EmailTemplate) => {
    const { vendorId, saveNotificationEmailTemplate, closeModal, notificationTypes } = this.props;

    const data = {
      ...emailTemplate,
      name: get(
        find(notificationTypes, {
          id: emailTemplate.name,
        }),
        'technicalName',
        '',
      ),
      vendorEmailTemplateNotificationTypeId: emailTemplate.name,
      toAdditionalCCAddress: emailTemplate.toAdditionalCCAddress
        ? emailTemplate.toAdditionalCCAddress.replace(/ /g, '')
        : undefined,
    };

    saveNotificationEmailTemplate(vendorId, data)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.notificationEmailTemplateSaved'));
        closeModal(true);
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
      });
  };

  render() {
    const { isLoading, isSaving, closeModal } = this.props;

    return (
      <Modal
        verticalSize="small"
        title={translate('vendors.featureCodes.customerNotification')}
        onClose={closeModal}
        isLoading={isLoading || isSaving}
      >
        <CustomerNotificationForm onSubmit={this.onSubmit} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.vendors.notificationEmailTemplates.isLoading,
  isSaving: state.vendors.notificationEmailTemplates.isSaving,
  notificationTypes: state.vendors.notificationTypes.notificationTypes || [],
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { saveNotificationEmailTemplate, resetNotificationEmailTemplates };

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNotificationModal);
