import { InjectedFormProps, reduxForm } from 'redux-form';
import { useMemo } from 'react';

import { JobPriorityTypesMultiSelect, PickupStatusDropdown } from 'src/common/components';
import {
  CANCELED,
  COMPLETED,
  IN_PROGRESS,
  ISSUE_REPORTED,
  PLACED_ON_HOLD,
  REASSIGNED,
  SCHEDULED,
  SERVICED,
} from 'src/common/constants';
import { PanelSearch, TypedField } from 'src/core/components';
import { Grid, GridColumn } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

export const ROUTE_STOPS_SEARCH = 'routeStopsSearch';
export const ROUTE_STOPS_PICKUP_STATUS_FIELD = 'pickupStatusIds';
export const ROUTE_STOPS_JOB_PRIORITY_FIELD = 'jobPriorityIds';

type RouteStopsSearchFormValues = {
  routeStopsSearchTerm: string;
  pickupStatusIds?: number[];
  jobPriorityIds?: number[];
};

type PropsWithoutReduxForm = {
  isDriverExperienceSimple?: boolean;
  isScheduledRoute?: boolean;
  onPickupStatusChange?: (pickupStatus: number[]) => void;
  onJobPriorityChange?: (jobPriority: number[]) => void;
  onSearchTermChange: (searchTerm: string) => void;
  isSnowPlowRoute?: boolean;
  isStreetSweeperRoute?: boolean;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteStopsSearchFormValues, PropsWithoutReduxForm>;

export default reduxForm<RouteStopsSearchFormValues, PropsWithoutReduxForm>({
  form: ROUTE_STOPS_SEARCH,
  enableReinitialize: true,
})(function RouteStopsSearchForm({
  isDriverExperienceSimple,
  onJobPriorityChange,
  onPickupStatusChange,
  onSearchTermChange,
  isSnowPlowRoute,
  isStreetSweeperRoute,
  isScheduledRoute,
}: Props) {
  const handleSearchTermChange = (_: any, searchTerm: string) => {
    onSearchTermChange(searchTerm);
  };

  const acceptedPickupStatuses = useMemo(() => {
    const status =
      isSnowPlowRoute || isStreetSweeperRoute
        ? isDriverExperienceSimple
          ? [COMPLETED, SCHEDULED]
          : [COMPLETED, SCHEDULED, IN_PROGRESS]
        : [SCHEDULED, IN_PROGRESS, COMPLETED, CANCELED, REASSIGNED, ISSUE_REPORTED, SERVICED, PLACED_ON_HOLD];

    if (isScheduledRoute) {
      return [SCHEDULED];
    }
    return status;
  }, [isDriverExperienceSimple, isScheduledRoute, isSnowPlowRoute, isStreetSweeperRoute]);

  return (
    <form>
      <Grid>
        <GridColumn size="6/12">
          <TypedField
            name="routeStopsSearchTerm"
            component={PanelSearch}
            onChange={handleSearchTermChange}
            props={{ padding: 'small no' }}
          />
        </GridColumn>
        {onJobPriorityChange && (
          <GridColumn size="3/12">
            <TypedField
              name={ROUTE_STOPS_JOB_PRIORITY_FIELD}
              component={JobPriorityTypesMultiSelect}
              onChange={(_, jobPriority) => {
                onJobPriorityChange(jobPriority);
              }}
              props={{
                withPlaceholder: true,
                includeNoneOption: true,
                multiSelectProps: {
                  canCheckAll: true,
                  margin: 'xSmall no no no',
                  isClearable: true,
                },
              }}
            />
          </GridColumn>
        )}
        {onPickupStatusChange && (
          <GridColumn size="3/12">
            <TypedField
              name={ROUTE_STOPS_PICKUP_STATUS_FIELD}
              component={PickupStatusDropdown}
              onChange={(_, pickupStatus) => {
                onPickupStatusChange(pickupStatus);
              }}
              props={{
                withPlaceholder: !isSnowPlowRoute && !isStreetSweeperRoute,
                acceptedPickupStatuses,
                dropdownProps: { margin: 'xSmall no no no', isClearable: true },
                placeholder: (isSnowPlowRoute || isStreetSweeperRoute) && translate('common.segmentStatus'),
                isSnowOrSweeperRoute: isSnowPlowRoute || isStreetSweeperRoute,
              }}
            />
          </GridColumn>
        )}
      </Grid>
    </form>
  );
});
