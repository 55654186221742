import moment from 'moment';
import { useMemo, useState } from 'react';
import { Modal } from 'src/core/components';
import { ModalSection, Tab, Tabs } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { ModalProps } from 'src/vendors/interfaces/ModalProps';
import DriversStatusEditZone from './DriversStatusEditZone';
import VehiclesStatusEditZone from './VehiclesStatusEditZone';

export enum STATUS_EDITOR_TABS {
  DRIVERS_STATUS = 'DRIVERS_STATUS',
  VEHICLES_STATUS = 'VEHICLES_STATUS',
}

interface Props extends ModalProps {
  closeModal: () => void;
  date: string;
  activeTab: STATUS_EDITOR_TABS;
  onStatusChanged: (drivers: RoutePlannerRouteDriver[], vehicles: RoutePlannerRouteVehicle[]) => void;
}

const DriversAndVehiclesStatusEditModal: React.FC<Props> = ({ date, activeTab, closeModal, onStatusChanged }) => {
  const [visibleTab, setVisibleTab] = useState(activeTab || STATUS_EDITOR_TABS.DRIVERS_STATUS);
  const drivers = useSelector(state => state.routes.routePlanner.routePlannerRouteDrivers.driversForAvailability);
  const driversStatus = useSelector(state => state.routes.routePlanner.routePlannerDriverStatus.updatedDriverStatuses);
  const vehicles = useSelector(state => state.routes.routePlanner.routePlannerRouteVehicles.vehiclesForAvailability);
  const vehiclesStatus = useSelector(
    state => state.routes.routePlanner.routePlannerVehicleStatus.updatedVehicleStatuses,
  );

  const mergedDrivers = useMemo(() => {
    return drivers.map(driver => {
      const driverStatus = driversStatus.find(status => status.driverId === driver.driverId);
      return {
        ...driver,
        isAvailable: driverStatus ? driverStatus.isAvailable : driver.isAvailable,
      };
    });
  }, [drivers, driversStatus]);

  const mergedVehicles = useMemo(() => {
    return vehicles.map(vehicle => {
      const vehicleStatus = vehiclesStatus.find(status => status.vehicleId === vehicle.vehicleId);
      return {
        ...vehicle,
        isAvailable: vehicleStatus ? vehicleStatus.isAvailable : vehicle.isAvailable,
      };
    });
  }, [vehicles, vehiclesStatus]);

  const renderTab = () => {
    switch (visibleTab) {
      case STATUS_EDITOR_TABS.DRIVERS_STATUS:
        return <DriversStatusEditZone drivers={mergedDrivers} onStatusChanged={onStatusChanged} />;
      case STATUS_EDITOR_TABS.VEHICLES_STATUS:
        return <VehiclesStatusEditZone vehicles={mergedVehicles} onStatusChanged={onStatusChanged} />;
    }
  };

  return (
    <Modal
      size="smallMedium"
      height="625px"
      onClose={closeModal}
      title={`${moment(date).format('dddd').substring(0, 3)}-${moment(date).format('MM/DD')}`}
    >
      <ModalSection margin="medium no no" align="center">
        <Tabs>
          <Tab
            flexDisplay
            isSelected={visibleTab === STATUS_EDITOR_TABS.DRIVERS_STATUS}
            onClick={() => setVisibleTab(STATUS_EDITOR_TABS.DRIVERS_STATUS)}
          >
            {translate('drivers.drivers')}
          </Tab>
          <Tab
            flexDisplay
            isSelected={visibleTab === STATUS_EDITOR_TABS.VEHICLES_STATUS}
            onClick={() => setVisibleTab(STATUS_EDITOR_TABS.VEHICLES_STATUS)}
          >
            {translate('vehicles.vehicles')}
          </Tab>
        </Tabs>
      </ModalSection>
      <ModalSection overflow="hidden" margin="small no small">
        {renderTab()}
      </ModalSection>
    </Modal>
  );
};
export default DriversAndVehiclesStatusEditModal;
