import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ActionButtonTooltip, UnconnectedInput } from '../../../core/components';
import { Button } from '../../../core/components/styled/Button';
import FormError from '../../../core/components/styled/FormError';
import UnconnectedCheckbox from '../../../core/components/UnconnectedCheckbox';
import translate from '../../../core/services/translate';
import { EquipmentSize } from '../../interfaces/Equipments';
import { ActionButtonContainer } from '../styled';

interface Props {
  equipmentSize: EquipmentSize;
  onChange: (value: boolean, title?: string) => void;
  onDelete: () => void;
  validate: (equipmentName: string) => boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;

  svg,
  input {
    margin-left: 12px;
  }
`;

export const CustomEquipmentSizeField: React.FC<Props> = ({ equipmentSize, onChange, onDelete, validate }) => {
  const [equipmentName, setEquipmentName] = useState(equipmentSize.other);
  const [canEdit] = useState(equipmentSize.canEdit);
  const [isActive, setIsActive] = useState(equipmentSize.isActive);
  const [isValid, setIsValid] = useState(true);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setIsActive(equipmentSize.isActive);
  }, [equipmentSize.isActive]);

  const handleChange = (isChecked: boolean) => {
    if (equipmentName) {
      onChange(isChecked);
    } else {
      setIsActive(isChecked);
      setIsEditable(canEdit && true);
    }
  };

  const saveChanges = () => {
    if (validate(equipmentName!)) {
      onChange(isActive, equipmentName);
      setIsEditable(false);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <>
      <Container>
        <UnconnectedCheckbox
          disabled={isEditable}
          margin="no"
          onChange={event => handleChange(event.target.checked)}
          checked={isActive}
          noLabel={isEditable}
          label={isEditable ? undefined : equipmentName ? equipmentName : translate('haulerProfile.other')}
        />
        {equipmentName && canEdit && !isEditable && (
          <>
            <ActionButtonContainer>
              <Button text color="primary" padding="small" onClick={() => setIsEditable(canEdit && true)}>
                <ActionButtonTooltip size="small" icon="edit" tooltip="edit" />
              </Button>
            </ActionButtonContainer>
            <ActionButtonContainer>
              <Button text color="primary" padding="small" onClick={onDelete}>
                <ActionButtonTooltip customViewBox="0 0 24 24" size="small" icon="trash" tooltip="delete" />
              </Button>
            </ActionButtonContainer>
          </>
        )}
        {isEditable && (
          <>
            <UnconnectedInput
              autoFocus
              margin="no"
              placeholder={translate('haulerProfile.other')}
              value={equipmentName}
              onChange={setEquipmentName}
            />
            <Button
              text
              color={equipmentName ? 'primary' : 'secondary'}
              padding="small"
              onClick={equipmentName ? saveChanges : undefined}
            >
              <ActionButtonTooltip size="small" icon="check" tooltip="save" />
            </Button>
            <Button
              text
              color="primary"
              padding="small"
              onClick={() => {
                setEquipmentName(equipmentSize.other);
                setIsEditable(false);
                setIsValid(true);
                if (!equipmentSize.other) {
                  setIsActive(false);
                }
              }}
            >
              <ActionButtonTooltip size="small" icon="undo" tooltip="cancel" />
            </Button>
          </>
        )}
      </Container>
      {!isValid && (
        <FormError errorOnSingleLine>{translate('haulerProfile.alertMessages.customEquipmentError')}</FormError>
      )}
    </>
  );
};
