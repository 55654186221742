import React, { Fragment } from 'react';
import { FixedSizeList as List } from 'react-window';

import { Accordion, AccordionSection } from './AccordionConfiguration';
import { REACT_WINDOW_CLASS_NAME } from '../constants/reactWindow';

interface Props {
  headerComponent: any;
  rowComponent: any;
  rowProps?: any;
  rows: any[];
  virtualized?: boolean;
  virtualizedProps?: any;
}

const Row = ({ data, style, index }: any) => {
  const { rowProps, RowComponent, rows } = data;
  return (
    <div style={style}>
      <RowComponent key={index} index={data[index].id} orderNumber={index} rowProps={rowProps} {...rows[index]} />
    </div>
  );
};

const AccordionTableCells: React.SFC<Props> = ({
  headerComponent: HeaderComponent,
  rowComponent: RowComponent,
  rowProps,
  rows,
  virtualized,
  virtualizedProps,
}) => {
  const props = virtualized
    ? {
        ...virtualizedProps,
        itemCount: rows.length,
        itemData: { rows, RowComponent, rowProps },
        width: '100%',
        className: REACT_WINDOW_CLASS_NAME,
      }
    : {};

  const getRows = () =>
    rows.map((headerRow, index) => (
      <Accordion key={index}>
        <AccordionSection
          component={HeaderComponent}
          rowProps={rowProps}
          data={headerRow}
          name={headerRow.model.technicalName}
        >
          {headerRow.modelFeatures &&
            headerRow.modelFeatures.map((tableRow: any, index2: number) => (
              <RowComponent key={index2} index={tableRow.id} orderNumber={index2} rowProps={rowProps} {...tableRow} />
            ))}
        </AccordionSection>
      </Accordion>
    ));
  const TableCellsWrapper = virtualized ? List : Fragment;
  return <TableCellsWrapper {...props}>{virtualized ? Row : getRows()}</TableCellsWrapper>;
};

AccordionTableCells.propTypes = {};

AccordionTableCells.defaultProps = {
  rowComponent: undefined,
  rowProps: undefined,
  rows: undefined,
  virtualized: undefined,
  virtualizedProps: undefined,
};

export default AccordionTableCells;
