import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  loadRouteHistorySegments,
  loadRouteHistoryVehicleBreadCrumb,
  loadRouteHistoryVehiclePosition,
} from 'src/dashboard/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RouteHistoryModal from './RouteHistoryModal';

interface Props {
  closeModal: () => void;
}

const RouteHistoryModalResolver = ({ closeModal }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { routeHistoryItem, routeHistoryDriverVehicle } = useSelector(state => state.dashboard.routeHistoryData);

  const loadDependencies = async () => {
    if (!vendorId || !routeHistoryItem) {
      return Promise.reject();
    }

    const routeId = routeHistoryItem.routeId;
    const routeDate = routeHistoryItem.routeDate;
    const vehicleIds = routeHistoryDriverVehicle?.vehicleId ? [routeHistoryDriverVehicle.vehicleId] : [];

    const dependencies: Promise<any>[] = [
      loadRouteHistoryVehiclePosition(routeId, routeDate, vehicleIds.join(','))(dispatch),
      loadRouteHistoryVehicleBreadCrumb(routeId, routeDate, vehicleIds.join(','))(dispatch),
      loadRouteHistorySegments(vendorId, routeId)(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={RouteHistoryModal}
      successProps={{ closeModal }}
      loadingComponent={PageLoadingOverlay}
      onError={closeModal}
      resolve={loadDependencies}
    />
  );
};

export default RouteHistoryModalResolver;
