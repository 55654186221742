import React from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { Modal } from '../../../core/components';
import { UPLOAD_TITLE } from '../../constants/modalTitles';
import { UploadFileForm } from '../forms';
import getModalTitle from '../../services/getModalTitle';

interface ComponentProps {
  closeModal(...args: any[]): any;
  submitUploadFile(...args: any[]): any;
  uploadFileSource: string;
  documentType?: string;
}

interface Props extends ComponentProps {
  isSavingFile: boolean;
}

const UploadFileModal: React.FC<Props> = ({
  closeModal,
  documentType,
  isSavingFile,
  submitUploadFile,
  uploadFileSource,
}) => (
  <Modal
    title={getModalTitle(UPLOAD_TITLE, documentType)}
    onClose={closeModal}
    verticalSize="mediumLarge"
    padding="medium"
    isLoading={isSavingFile}
  >
    <UploadFileForm onSubmit={submitUploadFile} uploadFileSource={uploadFileSource} documentType={documentType} />
  </Modal>
);

const mapStateToProps = (state: AppState) => ({
  isSavingFile: state.haulerProfile.files.isSaving,
});

export default connect(mapStateToProps)(UploadFileModal);
