import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { getQueryParams } from 'src/utils/services/queryParams';
import { INVOICE_PAYMENT_DEFAULT_STATUSES } from 'src/finance/constants/invoicePaymentStatuses';
import {
  loadBillStatuses,
  loadOpenBills,
  loadPayments,
  loadPaymentStatuses,
  loadPaymentMethods,
} from 'src/finance/ducks';
import { loadPermissions, shouldLoadPermissions } from 'src/account/ducks/permissions';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import ApplyChecksPage, { getPaymentsParams } from './ApplyChecksPage';
import translate from 'src/core/services/translate';

export interface PaymentsSearchQueryParams {
  startDate: string;
  endDate: string;
  paymentStatusIds: number[] | string | string[];
  paymentMethodIds: number[] | string | string[];
  paymentNumberId: string;
  paymentNumberName: string;
  customerId: number;
  customerName: string;
  locationId: number;
  locationName: string;
  searchText: string;
}

const ApplyChecksPageResolver = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const vendorId = useSelector(currentVendorId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);

  const loadDependencies = () => {
    const dependencies: Promise<any>[] = [
      loadPaymentStatuses()(dispatch),
      loadPaymentMethods()(dispatch),
      loadBillStatuses()(dispatch),
    ];

    const searchParams = getQueryParams(search);
    const paymentsParams = getPaymentsParams(vendorId, searchParams);

    const noLoadingIndicator = true;

    dependencies.push(
      loadPayments(paymentsParams)(dispatch),
      loadOpenBills({
        vendorId,
        billStatusIds: INVOICE_PAYMENT_DEFAULT_STATUSES,
        startDate: paymentsParams.startDate,
        endDate: paymentsParams.endDate,
        noLoadingIndicator,
      })(dispatch),
    );

    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('finance.applyChecks.applyChecks')}</DocumentTitle>
      <TypedResolver
        redirectOnError="/finance"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={ApplyChecksPage}
      />
    </>
  );
};

export default ApplyChecksPageResolver;
