import humps from 'humps';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { clearRouteMapSelectedFeature, resetStopMapDetails } from 'src/routes/ducks';
import { CustomerProximitySearchModalResolver } from 'src/customers/components/modals';
import { getRouteStopIconType } from 'src/routes/components/pages/routes/routePageSections/routeMap/routeStops/utils';
import { JOB_PENDING_OPTIMIZATION_ID, SCHEDULED } from 'src/routes/constants';
import { PICKUP_STATUSES, ROUTE_PICKUP_TYPE_IDS } from 'src/common/constants';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { TableActionButton } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import JobPriorityTypeIndicator from 'src/routes/components/pages/common/JobPriorityTypeIndicator';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  routeStop: RouteStop;
}

const TravelPathRouteStopGLPopup: FC<Props> = ({ routeStop }) => {
  const dispatch = useDispatch();
  const [isCustomerProximitySearchModalOpen, setCustomerProximitySearchModalOpen] = useState(false);
  const { stopMapDetails } = useSelector(state => state.routes.stopMapDetails);

  const iconType = getRouteStopIconType(routeStop);
  const pickupStatusName = PICKUP_STATUSES[routeStop.pickupStatusTypeId || SCHEDULED].technicalName;
  const insightType = humps.camelize(pickupStatusName);

  const getAddress = () => {
    if (stopMapDetails && stopMapDetails.address) return stopMapDetails.address;
    if (routeStop && routeStop.address) return routeStop.address;
    if (routeStop && routeStop.addressForPopup) return routeStop.addressForPopup;
    return '-';
  };

  useEffect(
    () => () => {
      dispatch(resetStopMapDetails());
    },
    [dispatch],
  );

  const jobIsPendingOptimization = routeStop.orderNo === JOB_PENDING_OPTIMIZATION_ID || routeStop.isOptimal;

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={routeStop.displayLatitude}
        longitude={routeStop.displayLongitude}
        onClose={() => dispatch(clearRouteMapSelectedFeature(true))}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={iconType?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${insightType}`)}
            afterTitleContent={
              <>
                {routeStop.jobPriorityTypeId && (
                  <Box ml={5}>
                    <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={routeStop.jobPriorityTypeId} />
                  </Box>
                )}
              </>
            }
          />
          <MapGLPopupContent>
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('routes.pickupType')}
              value={ROUTE_PICKUP_TYPE_IDS[routeStop.pickupTypeId]?.name || '-'}
            />
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('dashboard.stopNumber')}
              value={jobIsPendingOptimization ? null : routeStop.orderNo}
              children={jobIsPendingOptimization && <em>{translate('routes.pendingOptimization')}</em>}
            />
            <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.customer')}>
              {routeStop.customerName}

              <TableActionButton
                margin="no no no xSmall"
                color="primary"
                onClick={() => setCustomerProximitySearchModalOpen(true)}
              >
                <ActionButtonTooltip
                  icon="searchVehicle"
                  size="sMedium"
                  sourceIsInfoWindow
                  tooltip="customerProximitySearch"
                />
              </TableActionButton>
            </LabeledDataView>
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.location')}
              value={routeStop.locationName || '-'}
            />
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.address')}
              value={getAddress()}
            />
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isCustomerProximitySearchModalOpen && (
        <CustomerProximitySearchModalResolver
          defaultDate={routeStop.statusDate}
          locationId={routeStop.id}
          onCancel={() => setCustomerProximitySearchModalOpen(false)}
        />
      )}
    </>
  );
};

export default TravelPathRouteStopGLPopup;
