import React, { PureComponent, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Confirmation from './Confirmation';
import translate from '../services/translate';

interface State {
  nextLocation: any;
  openModal: boolean;
}

interface Props extends RouteComponentProps {
  when: boolean;
}

class NavigationPrompt extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { nextLocation: null, openModal: false };
  }

  private unblock: any;

  componentDidMount() {
    this.unblock = (this.props.history as any).block((nextLocation: any) => {
      if (this.props.when) {
        this.setState({
          openModal: true,
          nextLocation,
        });
      }
      return !this.props.when;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  onCancel = () => this.setState({ nextLocation: null, openModal: false });
  onConfirm = () => this.navigateToNextLocation();

  navigateToNextLocation = () => {
    this.unblock();
    this.props.history.push(`${this.state.nextLocation.pathname}${this.state.nextLocation.search}`);
  };

  render() {
    return (
      <Fragment>
        {this.state.openModal && (
          <Confirmation
            title={translate('common.alertMessages.leavePageWithoutSaving')}
            resolve={this.onConfirm}
            reject={this.onCancel}
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(NavigationPrompt);
