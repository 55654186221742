import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const INVOICE_PAYMENT_STATUS_UNPAID_ID = 1;
export const INVOICE_PAYMENT_STATUS_PROCESSING_ID = 2;
export const INVOICE_PAYMENT_STATUS_PARTIALLY_PAID_ID = 3;
export const INVOICE_PAYMENT_STATUS_PAID_ID = 4;

export const INVOICE_PAYMENT_DEFAULT_STATUSES = [
  INVOICE_PAYMENT_STATUS_UNPAID_ID,
  INVOICE_PAYMENT_STATUS_PROCESSING_ID,
  INVOICE_PAYMENT_STATUS_PARTIALLY_PAID_ID,
].toString();

export const INVOICE_PAYMENT_STATUSES_LIST = [
  { id: INVOICE_PAYMENT_STATUS_UNPAID_ID, name: translate('finance.applyChecks.invoicePaymentStatuses.unpaid') },
  {
    id: INVOICE_PAYMENT_STATUS_PROCESSING_ID,
    name: translate('finance.applyChecks.invoicePaymentStatuses.processing'),
  },
  {
    id: INVOICE_PAYMENT_STATUS_PARTIALLY_PAID_ID,
    name: translate('finance.applyChecks.invoicePaymentStatuses.partiallyPaid'),
  },
  { id: INVOICE_PAYMENT_STATUS_PAID_ID, name: translate('finance.applyChecks.invoicePaymentStatuses.paid') },
];

export const INVOICE_PAYMENT_STATUSES = mapKeys(INVOICE_PAYMENT_STATUSES_LIST, 'id');
