import React from 'react';

import {
  MoreButtonWrapper,
  MoreButtonPopup,
  MoreButtonPopupButton,
  MoreButtonPopupButtonSpacer,
  MoreButtonLink,
  MoreButtonComponent,
} from './styled/MoreButton';
import translate from '../services/translate';
import { useClickAway } from '../hooks';
import { Button } from './styled';
import ActionButtonTooltip from './ActionButtonTooltip';

type HandlerFunction = (event: React.MouseEvent) => void;

export interface MoreButtonItem {
  text: string;
  id?: string;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  handler?: HandlerFunction;
  isLink?: boolean;
  to?: string;
}

export interface MoreButtonProps {
  ellipsis?: boolean;
  stopPropagation?: boolean;
  buttonText?: string;
  margin?: string;
  items?: MoreButtonItem[];
  isIconButton?: boolean;
  icon?: string;
  tooltip?: string;
  menuTop?: boolean;
  color?: string;
}

const MoreButton: React.FC<MoreButtonProps> = ({
  buttonText,
  margin,
  items = [],
  ellipsis,
  stopPropagation,
  isIconButton,
  icon,
  tooltip,
  menuTop,
  color,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const toggleVisibility = React.useCallback(
    (event: React.MouseEvent) => {
      if (stopPropagation) {
        event.stopPropagation();
      }
      setIsOpen(!isOpen);
    },
    [setIsOpen, isOpen, stopPropagation],
  );

  const handleClick = React.useCallback(
    (handler?: HandlerFunction) => (event: React.MouseEvent) => {
      if (stopPropagation) event.stopPropagation();
      setIsOpen(false);

      if (typeof handler === 'function') {
        handler(event);
      }
    },
    [setIsOpen, stopPropagation],
  );

  const handleClickAway = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useClickAway({ ref: wrapperRef, handler: handleClickAway });

  return (
    <MoreButtonWrapper ref={wrapperRef} margin={margin}>
      {!isIconButton && (
        <MoreButtonComponent
          size={ellipsis ? 'xxLarge' : undefined}
          padding={ellipsis ? 'no' : undefined}
          id="more-button"
          text={ellipsis}
          line={!ellipsis}
          color={color || 'primary'}
          squaredOnBottom={isOpen && !menuTop}
          squaredOnTop={isOpen && menuTop}
          onClick={toggleVisibility}
        >
          {ellipsis ? '•••' : buttonText || translate('common.more')}
        </MoreButtonComponent>
      )}
      {isIconButton && (
        <Button
          size="xSmall"
          margin="no"
          padding="xSmall"
          color="white"
          borderRight
          squaredOnBottom
          squaredOnTop
          onClick={toggleVisibility}
        >
          <ActionButtonTooltip
            withHoverColor
            size="mLarge"
            icon={icon}
            color="black"
            tooltip={tooltip}
            customViewBox="0 -3 30 30"
          />
        </Button>
      )}
      {isOpen && (
        <MoreButtonPopup menuTop={menuTop}>
          {items.map((item, index) => {
            if (!item) {
              return null;
            }

            const { text, handler, id, startAdornment, endAdornment, disabled, isLink, to } = item;

            const button = (
              <MoreButtonPopupButton
                key={index}
                onClick={!disabled ? handleClick(handler) : undefined}
                disabled={disabled}
                id={id}
              >
                {!!startAdornment && <span>{startAdornment}</span>}

                {text}

                {!!endAdornment && (
                  <>
                    <MoreButtonPopupButtonSpacer />
                    {endAdornment}
                  </>
                )}
              </MoreButtonPopupButton>
            );

            return isLink && !!to ? (
              <MoreButtonLink to={to} key={index}>
                {button}
              </MoreButtonLink>
            ) : (
              button
            );
          })}
        </MoreButtonPopup>
      )}
    </MoreButtonWrapper>
  );
};

export default MoreButton;
