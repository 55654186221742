import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, GridColumn } from '../../../core/components/styled';
import { DriverSafetyConfigurationsFormValues } from '../../interfaces/DriverSafety';
import { TypedField } from '../../../core/components';
import { DRIVER_SAFETY_VEHICLE_FORM } from '../../constants/driverSafety';
import { VehicleTypeForVendorDropdown } from 'src/fleet/components';

interface PropsWithoutReduxForm {
  onVehicleTypeIdChange: () => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<DriverSafetyConfigurationsFormValues, {}>;

const DriverSafetyVehicleForm: React.FC<Props> = ({ onVehicleTypeIdChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Grid centered margin="small no medium no">
        <GridColumn size="3/12">
          <TypedField
            component={VehicleTypeForVendorDropdown}
            name="vehicleTypeId"
            onChange={onVehicleTypeIdChange}
            props={{
              dropdownProps: {
                margin: 'no',
              },
              withLabel: true,
            }}
          />
        </GridColumn>
      </Grid>
    </form>
  );
};

export default reduxForm<any, any>({
  form: DRIVER_SAFETY_VEHICLE_FORM,
  enableReinitialize: true,
})(DriverSafetyVehicleForm);
