import { change, reduxForm, InjectedFormProps } from 'redux-form';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import { ButtonSet, Button, Grid, GridColumn } from 'src/core/components/styled';
import { DatePicker, Dropdown, Input, Switch, TypedField } from 'src/core/components';
import { isNaturalNumber, isNaturalNumberWithZero, isRequired, maxValueNumeric } from 'src/utils/services/validator';
import { LowInventoryParts } from 'src/fleet/interfaces/ContainerStatistics';
import { TODAY } from 'src/core/constants';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

interface ComponentProps extends RouteComponentProps {
  currentQuantity: number;
  id: number;
  isAddInventory: boolean;
  isAddNewInventory: boolean;
  onCancel: (pristine: boolean) => void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  change: any;
  lowInventoryParts: LowInventoryParts[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

type State = {
  isNewInventory: boolean;
};

class AddRemoveInventoryForm extends Component<Props, State> {
  quantityValidator: any[];

  constructor(props: Props) {
    super(props);

    this.state = {
      isNewInventory: props.isAddNewInventory || false,
    };

    const isAddPart = props.isAddInventory || props.isAddNewInventory;
    this.quantityValidator = isAddPart
      ? [isNaturalNumberWithZero, isRequired, maxValueNumeric(99999)]
      : [isNaturalNumber, isRequired, maxValueNumeric(props.currentQuantity)];
  }

  setIsNewInventory = (value: any) => {
    const { change } = this.props;

    this.setState({ isNewInventory: value });
    change('partName', undefined);
  };

  render() {
    const { handleSubmit, id, isAddInventory, lowInventoryParts, onCancel, pristine } = this.props;
    const { isNewInventory } = this.state;

    const lowInventoryPartsOptions = lowInventoryParts.map(part => ({
      label: part.name,
      value: part.id,
    }));

    const isAddNewInventory = !id;

    return (
      <form onSubmit={handleSubmit}>
        <TypedField
          name={'date'}
          component={DatePicker}
          props={{
            label: translate('common.date'),
            margin: 'medium no',
          }}
          validate={[isRequired]}
        />

        <Grid>
          {isNewInventory ? (
            <GridColumn size={'9/12'} padding="no">
              <TypedField
                name={'partName'}
                component={Input}
                validate={[isRequired]}
                props={{
                  label: translate('containers.part'),
                  margin: 'no',
                }}
              />
            </GridColumn>
          ) : (
            <GridColumn size={isAddNewInventory ? '9/12' : '12/12'} padding="no">
              <TypedField
                name={'partName'}
                component={Dropdown}
                validate={[isRequired]}
                props={{
                  label: translate('containers.part'),
                  margin: 'no',
                  options: lowInventoryPartsOptions,
                  disabled: !isAddNewInventory,
                }}
              />
            </GridColumn>
          )}

          {isAddNewInventory && (
            <GridColumn size="3/12" padding="medium no no small">
              <TypedField
                component={Switch}
                name={'isNew'}
                props={{
                  label: translate('containers.new'),
                  size: 'medium',
                }}
                onChange={event => this.setIsNewInventory(event)}
              />
            </GridColumn>
          )}
        </Grid>

        <TypedField
          name={'quantity'}
          component={Input}
          props={{
            label: translate('containers.quantity'),
            margin: 'medium no',
            type: 'number',
          }}
          validate={this.quantityValidator}
        />

        <ButtonSet margin="large no no no">
          <Button type="submit" color="primary">
            {translate(isAddInventory || isAddNewInventory ? 'common.add' : 'common.remove')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  initialValues: { date: TODAY, partName: ownProps.id, isNew: true },
  lowInventoryParts: state.fleet.lowInventoryParts.lowInventoryParts || [],
});

const mapDispatchToProps = {
  change,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, any>({
      form: 'addRemoveInventory',
      enableReinitialize: true,
      onSubmitFail: focusFirstInvalidField,
    })(AddRemoveInventoryForm),
  ),
);
