import translate from '../../core/services/translate';

export const POSTPONEMENT_TYPES = {
  BEFORE: 1,
  AFTER: 2,
};

export const ALL = translate('routes.holidayPlanner.all');

export const FRESH_FORM_KEY = 'Fresh';

export const DATE_TYPES = [
  { value: false, name: translate('routes.holidayPlanner.dateTypes.floating') },
  { value: true, name: translate('routes.holidayPlanner.dateTypes.exactDate') },
];

export const DAYS_COUNT = [
  { value: 0, name: translate('routes.holidayPlanner.noOtherRoutesImpacted') },
  { value: 1, name: translate('routes.holidayPlanner.dayCount') },
  { value: 2, name: translate('routes.holidayPlanner.daysCount', { count: 2 }) },
  { value: 3, name: translate('routes.holidayPlanner.daysCount', { count: 3 }) },
  { value: 4, name: translate('routes.holidayPlanner.daysCount', { count: 4 }) },
  { value: 5, name: translate('routes.holidayPlanner.daysCount', { count: 5 }) },
];
