import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { ActionButtonTooltip } from 'src/core/components';
import { getEquipmentSize, getEquipmentSizeTranslationKey } from 'src/customers/services/transformLoadServices';
import { resetAdvancedSearchRoutes } from 'src/dashboard/ducks/routesData';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { TABLE_ROW_HEIGHT_xxLARGE } from 'src/core/constants';
import { TableCell, TableRow, Text, TableActionButton } from 'src/core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

interface Props {
  address: string;
  customerAccountNumber: string;
  customerId: number;
  customerName: string;
  driverName: string;
  equipmentSize: TechnicalType;
  equipmentType: TechnicalType;
  locationAccountNumber: string;
  locationName: string;
  numberOfContainers: number;
  onSelectRoute: (
    routeId: number,
    routeLocationId: number,
    isSnowPlowOrStreetSweeperRoute: boolean,
    vehicleTypeId?: number,
  ) => void;
  routeId: number;
  routeLocationId: number;
  routeName: string;
  serviceType: TechnicalType;
  streetSegmentId: number;
  vehicleId: number;
  vehicleName: string;
  vehicleType: TechnicalType;
  vehicleTypeId: number;
  wasteMaterialType: TechnicalType;
}

const AdvancedSearchModalTableRow: React.FC<Props> = ({
  address,
  customerAccountNumber,
  customerId,
  customerName,
  driverName,
  equipmentSize,
  equipmentType,
  locationAccountNumber,
  locationName,
  numberOfContainers,
  onSelectRoute,
  routeId,
  routeLocationId,
  routeName,
  serviceType,
  streetSegmentId,
  vehicleId,
  vehicleName,
  vehicleType,
  wasteMaterialType,
}) => {
  const dispatch = useDispatch();

  const isSnowPlowRoute = vehicleType.id === SNOW_PLOW_ID;
  const isStreetSweeperRoute = vehicleType.id === STREET_SWEEPER_ID;
  const isSnowPlowOrStreetSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const goToServiceHistoryPage = () => {
    dispatch(push(`/customers/customers/${customerId}/serviceHistory`));
    dispatch(resetAdvancedSearchRoutes());
  };

  const goToRoutePage = () => {
    const routeUrl = isSnowPlowRoute ? 'snow-tracker/' : isStreetSweeperRoute ? 'sweeper-tracker/' : 'route-tracker/';
    dispatch(push(`/routes/${routeUrl}${routeId}`, { routeLocationId, streetSegmentId }));
    dispatch(resetAdvancedSearchRoutes());
  };

  const goToVehiclePage = () => {
    dispatch(push(`/fleet/vehicles/${vehicleId}`));
    dispatch(resetAdvancedSearchRoutes());
  };

  return (
    <TableRow height={TABLE_ROW_HEIGHT_xxLARGE}>
      <TableCell width="35%" vertical align="center">
        <Text block margin="no no xxSmall">
          {customerName} {customerAccountNumber && `(${customerAccountNumber})`}
        </Text>
        <Text block margin="no no xxSmall">
          {locationName} {locationAccountNumber && `(${locationAccountNumber})`}
        </Text>
        <Text block margin="no no xxSmall">
          {address || '-'}
        </Text>
      </TableCell>
      <TableCell width="30%" vertical align="center">
        <Text block margin="no no xxSmall">
          {equipmentType ? translate(createTranslationKey(equipmentType.technicalName, 'common.equipmentTypes')) : '-'}{' '}
          {(numberOfContainers || numberOfContainers === 0) && `(${numberOfContainers})`}
        </Text>
        <Text block margin="no no xxSmall">
          {equipmentSize && serviceType && equipmentType
            ? translate(
                getEquipmentSizeTranslationKey(equipmentSize.technicalName, serviceType.name, equipmentType.name),
                {
                  size: getEquipmentSize(equipmentSize.name),
                },
              )
            : '-'}
        </Text>
        <Text block margin="no no xxSmall">
          {wasteMaterialType
            ? translate(createTranslationKey(wasteMaterialType.technicalName, 'common.wasteTypes'))
            : '-'}
        </Text>
      </TableCell>
      <TableCell width="20%" vertical align="center">
        <Text block margin="no no xxSmall">
          {routeName || '-'}
        </Text>
        <Text block margin="no no xxSmall">
          {vehicleName || '-'}
        </Text>
        <Text block margin="no no xxSmall">
          {driverName || '-'}
        </Text>
      </TableCell>
      <TableCell width="15%">
        <TableActionButton
          onClick={() =>
            onSelectRoute(
              routeId,
              isSnowPlowOrStreetSweeperRoute ? streetSegmentId : routeLocationId,
              isSnowPlowOrStreetSweeperRoute,
              vehicleType.id,
            )
          }
        >
          <ActionButtonTooltip size="sMedium" icon="mapPin" tooltip="viewOnDashboard" />
        </TableActionButton>
        {customerId && !isSnowPlowOrStreetSweeperRoute && (
          <TableActionButton onClick={() => goToServiceHistoryPage()}>
            <ActionButtonTooltip size="sMedium" icon="customer" tooltip="viewCustomerServiceHistory" />
          </TableActionButton>
        )}
        {routeId && (
          <TableActionButton onClick={() => goToRoutePage()}>
            <ActionButtonTooltip size="sMedium" icon="routes" tooltip="viewRouteDetailsPage" />
          </TableActionButton>
        )}
        {vehicleId && (
          <TableActionButton onClick={() => goToVehiclePage()}>
            <ActionButtonTooltip
              size="sMedium"
              icon="vehicleInspection"
              customViewBox="0 0 24 24"
              tooltip="viewVehicleDetailsPage"
            />
          </TableActionButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AdvancedSearchModalTableRow;
