import { size } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { Modal } from '../../../core/components';
import { ModalFixedHeader, ModalTitle, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveDataRetention } from 'src/vendors/ducks';
import { DataRetention } from 'src/vendors/interfaces/DataRetention';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import DataRetentionForm from '../forms/DataRetentionForm';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  closeModal: (pristine?: boolean) => void;
}

export default function DataRetentionModal({ closeModal }: Props) {
  const dispatch = useDispatch();

  const { dataRetention, isLoading, isSaving } = useSelector((state: AppState) => state.vendors.dataRetention);

  const dataRetentionInitialValues = dataRetention || ([] as DataRetention[]);
  const retentionConfiguration = dataRetentionInitialValues.find(
    (config: DataRetention) => config.isActive,
  )?.dataRetentionValue;
  const initialValues = { retentionConfiguration };

  const handleSubmit = (formData: DataRetention) => {
    const retentionConfiguration = dataRetention.find(
      config => config.dataRetentionValue === formData.retentionConfiguration,
    );
    const formDataRetention = { ...retentionConfiguration, isActive: true };

    saveDataRetention(formDataRetention)(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.dataRetentionSaved'));
        closeModal();
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.dataRetentionSaveError')));
  };

  return (
    <Modal isLoading={isLoading || isSaving} padding="no" size="smallMedium">
      <ModalFixedHeader padding="medium no no no">
        <ModalClose onClick={() => closeModal()}>
          <ModalCloseIcon />
        </ModalClose>
        <ModalTitle>{translate(createTranslationKey('dataRetention', 'vendors.featureCodes'))}</ModalTitle>
      </ModalFixedHeader>

      {size(dataRetention) && (
        <DataRetentionForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          dataRetention={dataRetention}
          onCancel={closeModal}
        />
      )}
    </Modal>
  );
}
