import React, { PureComponent, Fragment } from 'react';
import { find } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

import { vendorPaymentMethodTypes } from '../../ducks';
import { isRequired } from '../../../utils/services/validator';
import { Dropdown, Input } from '../../../core/components';
import { Container, ButtonSet, Button, Grid, GridColumn, Text } from '../../../core/components/styled';
import { PERCENT } from '../../constants';
import translate from '../../../core/services/translate';
import opportunityFormInitialValueSelector from '../../services/opportunityFormInitialValueSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { InputMask } from '../styled';
import { AppState } from '../../../store';

const formSelector = formValueSelector('acceptOpportunity');

interface ComponentProps {
  caseId: number;
  divisionId: string;
  divisionName: string;
  isBiddingSourcing?: boolean;
  paymentInfo?: any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  endDestinations?: any[];
  initialValues: any;
  isEquipmentCustomerOwned: boolean;
  notifyPossibleCustomerApproval?: boolean;
  onCancel(pristine: boolean): void;
  paymentMethods: any;
  paymentInfo?: any;
  response: any;
  requestedChanges: any;
  requestedChangesState: any;
  status?: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class AcceptOpportunityForm extends PureComponent<Props> {
  render() {
    const {
      caseId,
      divisionId,
      divisionName,
      endDestinations,
      handleSubmit,
      isEquipmentCustomerOwned,
      notifyPossibleCustomerApproval,
      onCancel,
      pristine,
      paymentMethods,
      paymentInfo,
      requestedChangesState,
      status,
    } = this.props;
    const division = `${divisionName}  (${divisionId})`;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container margin="no no medium">
          <Fragment>
            <Text padding="no small no no" margin="medium no" weight="normal" size="small" color="grayDark">
              {translate('haulerProfile.division')}:
            </Text>
            <Text margin="medium no" weight="normal" size="small">
              {division}
            </Text>
          </Fragment>
        </Container>
        <Container>
          <Field
            name={`opportunities.settings${caseId}.requestedChanges.paymentMethod`}
            component={Dropdown}
            options={paymentMethods}
            label={translate('opportunity.opportunities.paymentMethod')}
            margin=" no xSmall"
            disabled={status === 'AwaitingHaulerApproval'}
          />
        </Container>
        <Container>
          <Field
            name={`opportunities.settings${caseId}.requestedChanges.paymentId`}
            component={Dropdown}
            options={paymentInfo}
            label={translate('opportunity.opportunities.paymentInfo')}
            margin="medium no xSmall"
            disabled={status === 'AwaitingHaulerApproval'}
          />
        </Container>
        <Container>
          <Field
            name={`opportunities.settings${caseId}.requestedChanges.facilityId`}
            component={Dropdown}
            options={endDestinations}
            label={translate('opportunity.opportunities.endDestination')}
            margin="medium no xSmall"
            disabled={status === 'AwaitingHaulerApproval'}
          />
        </Container>

        <Container>
          <Fragment>
            {status === 'AwaitingHaulerApproval' &&
              (requestedChangesState.overallserviceCharge || requestedChangesState.overallserviceCharge !== 0) && (
                <Grid>
                  <GridColumn size="12/12" padding="no no">
                    <Fragment>
                      <InputMask>$</InputMask>
                      <Field
                        name={`opportunities.settings${caseId}.requestedChanges.overallserviceCharge`}
                        component={Input}
                        type="number"
                        label={translate('opportunity.overallMonthlyTotal')}
                        margin="xSmall"
                        disabled
                      />
                    </Fragment>
                  </GridColumn>
                </Grid>
              )}
          </Fragment>

          {requestedChangesState.haulerRateDetails.map((fees: any, index: number) => (
            <Fragment key={index}>
              {fees.mandatory && !fees.inherit && !fees.fee && (
                <Fragment>
                  <InputMask>$</InputMask>
                  <Field
                    name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                    component={Input}
                    type="number"
                    label={`${fees.rateDesc} (${translate(`opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`)})`}
                    size="small"
                    validate={isRequired}
                    margin="xSmall"
                    disabled={status === 'AwaitingHaulerApproval'}
                  />
                </Fragment>
              )}
              {status === 'AwaitingHaulerApproval' &&
                fees.bundled &&
                fees.rateType === PERCENT &&
                !fees.inherit &&
                fees.fee && (
                  <Grid>
                    <GridColumn size="1/12">
                      <InputMask margin="no medium no" top="medium">
                        $
                      </InputMask>
                    </GridColumn>
                    <GridColumn padding="no" size="7/12">
                      <Field
                        name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rateInDollar`}
                        component={Input}
                        type="number"
                        label={fees.rateDesc}
                        margin="no medium small"
                        disabled
                      />
                    </GridColumn>
                    <GridColumn size="5/12">
                      <Field
                        name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                        component={Input}
                        type="number"
                        label="%"
                        margin="no small small"
                        disabled
                      />
                    </GridColumn>
                  </Grid>
                )}
              {status === 'AwaitingHaulerApproval' && fees.bundled && fees.inherit && (
                <Fragment>
                  <InputMask margin="no large xSmall">$</InputMask>
                  <Field
                    name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                    component={Input}
                    type="number"
                    label={`${fees.rateDesc} (${translate(`opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`)})`}
                    validate={isRequired}
                    margin="no xLarge small"
                    disabled
                  />
                </Fragment>
              )}
              {status === 'AwaitingHaulerApproval' && !fees.bundled && !fees.inherit && (
                <Fragment>
                  <InputMask>$</InputMask>
                  <Field
                    name={`opportunities.settings${caseId}.requestedChanges.haulerRateDetails[${index}].rate`}
                    component={Input}
                    type="number"
                    label={`${fees.rateDesc} (${translate(`opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`)})`}
                    disabled
                    margin="xSmall"
                  />
                </Fragment>
              )}
            </Fragment>
          ))}
        </Container>
        <Container>
          {notifyPossibleCustomerApproval && (
            <Fragment>
              <Text margin="xLarge no xSmall" size="small" color="alert">
                {translate('common.note')}:{' *'}
              </Text>
              <Text weight="normal" size="small">
                {translate('opportunity.opportunities.messages.acceptDisclaimer')}
              </Text>
            </Fragment>
          )}
        </Container>
        <Container>
          {isEquipmentCustomerOwned && (
            <Fragment>
              <Text weight="normal" size="small">
                {translate('opportunity.opportunities.messages.deliveryNotRequired')}
              </Text>
            </Fragment>
          )}
        </Container>
        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { isBiddingSourcing, caseId, paymentInfo } = ownProps;
  const opportunity = isBiddingSourcing
    ? (find as any)(state.opportunity.opportunities.opportunities, { caseId })
    : (find as any)(state.opportunity.opportunity.opportunities, { caseId });
  const opportunities = isBiddingSourcing
    ? state.opportunity.opportunities.opportunities
    : state.opportunity.opportunity.opportunities;
  return {
    initialValues: opportunityFormInitialValueSelector(opportunities),
    response: formSelector(state, `opportunities.settings${caseId}.response`),
    requestedChanges: formSelector(state, `opportunities.settings${caseId}.requestedChanges`),
    requestedChangesState: opportunity.requestedChanges,
    paymentMethods: paymentInfo.paymentMethods,
    paymentInfo: vendorPaymentMethodTypes(
      paymentInfo.vendorPayments,
      formSelector(state, `opportunities.settings${caseId}.requestedChanges.paymentMethod`),
    ),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'acceptOpportunity',
    onSubmitFail: focusFirstInvalidField,
  })(AcceptOpportunityForm),
);
