import { ChangeEvent } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, PopoverWrapper, UnconnectedCheckbox } from 'src/core/components';
import { IconButtonIcon, TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { UnbilledCharge } from 'src/finance/interfaces/UnbilledCharges';
import UnbilledChargesAmountPopover from './UnbilledChargesAmountPopover';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { currency } from 'src/utils/services/formatter';

type Props = UnbilledCharge & {
  assignToBill: (workOrderId: number, billDate: StringOrDate, vendorLocationId: number, headerId: number) => void;
  hasAssignOption: boolean;
  isChecked: boolean;
  isViewOnly?: boolean;
  onEditClick?: (bill: any) => void;
  selectBillsToAssign?: (
    id: number,
    workOrderId: number,
    vendorLocationId: number,
    billDate: StringOrDate,
    invoiceSubTotal: number,
    headerId: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
};

const UnbilledChargesTableRow = ({
  assignToBill,
  hasAssignOption,
  isViewOnly,
  onEditClick,
  selectBillsToAssign,
  ...charge
}: Props) => (
  <TableRow id={`charge-${charge.workOrderId}-button`} height={TABLE_ROW_HEIGHT_LARGE}>
    {!isViewOnly && (
      <TableCell width="3%" padding="defaultCellVertical no defaultCellVertical xSmall">
        {hasAssignOption && selectBillsToAssign && (
          <UnconnectedCheckbox
            block
            checked={charge.isChecked}
            onChange={e =>
              selectBillsToAssign(
                charge.id,
                charge.workOrderId,
                charge.vendorLocationId,
                charge.serviceDate,
                charge.invoiceSubTotal,
                charge.headerId,
                e,
              )
            }
            onClick={e => e.stopPropagation()}
            id={`bill-${charge.workOrderId}-checkbox`}
            margin="no xSmall no no"
          />
        )}
      </TableCell>
    )}

    <TableCell
      id={`charge-${charge.workOrderId}-workOrder-cell`}
      justifyContent="center"
      vertical
      width={isViewOnly ? '13%' : '10%'}
      padding="defaultCellVertical xSmall"
    >
      <Text block>{charge.workOrderId || '-'}</Text>
    </TableCell>
    <TableCell
      id={`charge-${charge.workOrderId}-service-cell`}
      justifyContent="center"
      vertical
      width={isViewOnly ? '28%' : '20%'}
      padding="defaultCellVertical xSmall"
    >
      <Text block>
        {charge.equipmentTypeName || '-'} • {charge.equipmentSizeName || '-'}
      </Text>
      <Text block>
        {charge.materialTypeName || '-'} • {charge.serviceFrequencyDescription || '-'}
      </Text>
    </TableCell>
    <TableCell
      id={`charge-${charge.workOrderId}-customer`}
      justifyContent="center"
      vertical
      width="10%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>{charge.customerId || '-'}</Text>
      <Text block>{charge.customerName || '-'}</Text>
    </TableCell>
    <TableCell
      id={`charge-${charge.workOrderId}-location`}
      justifyContent="center"
      vertical
      width="10%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>{charge.locationId || '-'}</Text>
      <Text block>{charge.locationName || '-'}</Text>
    </TableCell>
    <TableCell
      id={`charge-${charge.workOrderId}-work-descr-cell`}
      justifyContent="center"
      vertical
      width="19%"
      padding="defaultCellVertical xSmall"
    >
      <Text block>{charge.workFlowDescription || '-'}</Text>
    </TableCell>
    <TableCell id={`charge-${charge.workOrderId}-serviceDate-cell`} width="10%" padding="defaultCellVertical xSmall">
      <Text>{charge.serviceDate ? moment(charge.serviceDate).format('MM/DD/YYYY') : '-'}</Text>
    </TableCell>
    <TableCell id={`charge-${charge.workOrderId}-amount-cell`} width="10%" padding="defaultCellVertical xSmall">
      <Text>{currency(charge.invoiceSubTotal || 0)}</Text>
      {!!charge.rateCodes?.length && (
        <PopoverWrapper
          triggerButton={<IconButtonIcon icon="info" size="medium" color="primary" margin="no no no xxSmall" />}
          popoverContent={<UnbilledChargesAmountPopover rateCodes={charge.rateCodes} />}
          width="20px"
          size="medium"
        />
      )}
    </TableCell>

    {!isViewOnly && (
      <TableCell id={`charge-${charge.workOrderId}-options-cell`} width="8%" padding="defaultCellVertical xSmall">
        {hasAssignOption && (
          <>
            {charge.headerId && (
              <TableActionButton
                onClick={() => (onEditClick ? onEditClick(charge) : null)}
                id={`bill-${charge.workOrderId}-edit-button`}
              >
                <ActionButtonTooltip icon="money" tooltip="editRate" customViewBox="0 -960 960 960" size="mMedium" />
              </TableActionButton>
            )}
            <TableActionButton
              id={`assign-to-${charge.workOrderId}-bill-button`}
              onClick={() =>
                assignToBill(charge.workOrderId, charge.serviceDate, charge.vendorLocationId, charge.headerId)
              }
            >
              <ActionButtonTooltip icon="reply" tooltip="assignToBill" />
            </TableActionButton>
          </>
        )}
      </TableCell>
    )}
  </TableRow>
);

export default UnbilledChargesTableRow;
