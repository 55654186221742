import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { AppState } from '../../../../../store';
import {
  AssistingVehicleContainer,
  AssistingVehicleInfo,
  RouteAssistSummaryContainer,
  RouteSummaryAssistedContainer,
  RouteSummaryAssistedTitle,
  RouteSummaryAssistedToggle,
} from '../../../styled/RouteSummary';
import { duration } from '../../../../../utils/services/formatter';
import { PageSubtitle } from '../../../../../common/components/styled';
import {
  RouteSummary as RouteSummaryContainer,
  RouteSummaryDetails,
  RouteSummaryIcon,
  RouteSummaryIconContainer,
  RouteSummaryItem,
  RouteSummaryLabel,
  RouteSummaryValue,
  RouteSummaryValueButton,
} from '../../../styled';
import { SCHEDULED } from '../../../../../common/constants';
import { supervisorExperienceFeatureIsEnabled } from '../../../../../vendors/ducks/features';
import Icon from '../../../../../core/components/Icon';
import translate from '../../../../../core/services/translate';

interface Props {
  completedStopCount?: number;
  driverId?: number;
  driverName?: string;
  isWeightTicketsEnabled?: boolean;
  isYRoute?: boolean;
  openRouteImagesModal?: () => void;
  routeAssistanceDetails?: [
    {
      assistedStops: number;
      assistedTime: number;
      driverName: string;
      vehicleRegplate: string;
    },
  ];
  routeId?: number;
  routeImagesCount?: number;
  routeStatusTypeId: number;
  supervisorId?: number;
  startingLocationName?: string;
  totalAssistedStops?: number;
  totalAssistedTime?: number;
  totalPickupExceptions?: number;
  totalRouteAssistanceTime?: number;
  totalStopCount?: number;
  vehicleName?: string;
  vehicleTypeName: string;
  vendorServiceZoneName?: string;
  weightTicketsCount?: number;
  weightTicketsWeightTotal?: string;
}

const RouteSummary: React.FC<Props> = ({
  completedStopCount,
  driverId,
  driverName,
  isWeightTicketsEnabled,
  isYRoute,
  openRouteImagesModal,
  routeAssistanceDetails,
  routeImagesCount,
  routeStatusTypeId,
  supervisorId,
  startingLocationName,
  totalAssistedStops,
  totalAssistedTime,
  totalStopCount,
  vehicleName,
  vehicleTypeName,
  vendorServiceZoneName,
  weightTicketsCount,
  weightTicketsWeightTotal,
}) => {
  const weightTicketsText = `${weightTicketsCount} / ${weightTicketsWeightTotal} ${translate('common.tons')}`;

  const [isRouteAssistOpen, setIsRouteAssistOpen] = useState(totalAssistedStops !== null);

  const supervisors = useSelector((state: AppState) => state.routes.supervisors.supervisors);
  const selectedSupervisor = supervisorId ? supervisors.find(supervisor => supervisor.id === supervisorId) : undefined;
  const supervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  return (
    <>
      <RouteSummaryContainer>
        <RouteSummaryItem>
          <RouteSummaryIconContainer>
            <RouteSummaryIcon icon="vehicle" />
          </RouteSummaryIconContainer>
          <RouteSummaryDetails>
            <RouteSummaryLabel>{translate('vehicles.vehicle')}</RouteSummaryLabel>
            <RouteSummaryValue id="route-vehicle">
              {!!vehicleName && `${vehicleName},`} {vehicleTypeName}
            </RouteSummaryValue>
          </RouteSummaryDetails>
        </RouteSummaryItem>

        {!!driverId && !isYRoute && (
          <RouteSummaryItem>
            <RouteSummaryIconContainer>
              <RouteSummaryIcon icon="user" />
            </RouteSummaryIconContainer>
            <RouteSummaryDetails>
              <RouteSummaryLabel>{translate('drivers.driver')}</RouteSummaryLabel>
              <RouteSummaryValue>{driverName}</RouteSummaryValue>
            </RouteSummaryDetails>
          </RouteSummaryItem>
        )}

        {totalStopCount !== undefined && (
          <RouteSummaryItem>
            <RouteSummaryIconContainer>
              <RouteSummaryIcon icon="container" />
            </RouteSummaryIconContainer>
            <RouteSummaryDetails>
              <RouteSummaryLabel>{translate('routes.numberOfStops')}</RouteSummaryLabel>
              <RouteSummaryValue id="route-stops">
                {routeStatusTypeId === SCHEDULED || isYRoute
                  ? totalStopCount
                  : `${completedStopCount} / ${totalStopCount}`}
              </RouteSummaryValue>
            </RouteSummaryDetails>
          </RouteSummaryItem>
        )}

        {isWeightTicketsEnabled && routeStatusTypeId !== SCHEDULED && !isYRoute && (
          <RouteSummaryItem>
            <RouteSummaryIconContainer>
              <RouteSummaryIcon icon="scale" />
            </RouteSummaryIconContainer>
            <RouteSummaryDetails>
              <RouteSummaryLabel>{translate('routes.weightTickets')}</RouteSummaryLabel>
              <RouteSummaryValue>{weightTicketsText}</RouteSummaryValue>
            </RouteSummaryDetails>
          </RouteSummaryItem>
        )}

        {routeStatusTypeId !== SCHEDULED && !isYRoute && (
          <RouteSummaryItem>
            <RouteSummaryIconContainer>
              <RouteSummaryIcon icon="image" />
            </RouteSummaryIconContainer>
            <RouteSummaryDetails>
              <RouteSummaryLabel>{translate('common.images')}</RouteSummaryLabel>
              {!!routeImagesCount && (
                <RouteSummaryValueButton onClick={openRouteImagesModal}>{routeImagesCount}</RouteSummaryValueButton>
              )}

              {!routeImagesCount && <RouteSummaryValue>{routeImagesCount}</RouteSummaryValue>}
            </RouteSummaryDetails>
          </RouteSummaryItem>
        )}

        {supervisorExperienceEnabled && selectedSupervisor && (
          <RouteSummaryItem>
            <RouteSummaryIconContainer>
              <RouteSummaryIcon icon="supervisor" />
            </RouteSummaryIconContainer>
            <RouteSummaryDetails>
              <RouteSummaryLabel>{translate('routes.supervisor')}</RouteSummaryLabel>
              <RouteSummaryValue>
                {selectedSupervisor.firstName} {selectedSupervisor.lastName}
              </RouteSummaryValue>
            </RouteSummaryDetails>
          </RouteSummaryItem>
        )}

        {startingLocationName && (
          <RouteSummaryItem>
            <RouteSummaryIconContainer>
              <RouteSummaryIcon icon="pinOnMap" />
            </RouteSummaryIconContainer>
            <RouteSummaryDetails>
              <RouteSummaryLabel>{translate('routes.startingLocation')}</RouteSummaryLabel>
              <RouteSummaryValue>{startingLocationName}</RouteSummaryValue>
            </RouteSummaryDetails>
          </RouteSummaryItem>
        )}

        {vendorServiceZoneName && (
          <RouteSummaryItem>
            <RouteSummaryIconContainer>
              <RouteSummaryIcon icon="map" />
            </RouteSummaryIconContainer>
            <RouteSummaryDetails>
              <RouteSummaryLabel>{translate('dispatchBoard.routeBuilder.serviceZoneLabel')}</RouteSummaryLabel>
              <RouteSummaryValue>{vendorServiceZoneName}</RouteSummaryValue>
            </RouteSummaryDetails>
          </RouteSummaryItem>
        )}
      </RouteSummaryContainer>

      {typeof totalAssistedStops === 'number' && (
        <RouteSummaryAssistedContainer>
          <RouteSummaryAssistedToggle onClick={() => setIsRouteAssistOpen(!isRouteAssistOpen)}>
            <PageSubtitle>
              <Icon width="12px" icon={isRouteAssistOpen ? 'arrowDownFill' : 'arrowUpFill'} />
              <RouteSummaryAssistedTitle>{translate('routeAssist.routeAssist')}</RouteSummaryAssistedTitle>
            </PageSubtitle>
          </RouteSummaryAssistedToggle>

          {isRouteAssistOpen && (
            <RouteAssistSummaryContainer>
              <RouteSummaryItem>
                <RouteSummaryIconContainer>
                  <RouteSummaryIcon icon="clock" />
                </RouteSummaryIconContainer>
                <RouteSummaryDetails>
                  <RouteSummaryLabel>{translate('routeAssist.totalTime')}</RouteSummaryLabel>
                  <RouteSummaryValue>{duration(totalAssistedTime || 0, 'minutes', 'hh:mm')}</RouteSummaryValue>
                </RouteSummaryDetails>
              </RouteSummaryItem>

              <RouteSummaryItem>
                <RouteSummaryIconContainer>
                  <RouteSummaryIcon icon="handshake" />
                </RouteSummaryIconContainer>
                <RouteSummaryDetails>
                  <RouteSummaryLabel>{translate('routeAssist.totalAssistedStops')}</RouteSummaryLabel>
                  <RouteSummaryValue>
                    {totalAssistedStops}
                    {!!totalStopCount && ` / ${totalStopCount}`}
                  </RouteSummaryValue>
                </RouteSummaryDetails>
              </RouteSummaryItem>
              {routeAssistanceDetails && (
                <RouteSummaryItem>
                  <RouteSummaryIconContainer>
                    <RouteSummaryIcon icon="vehicle" />
                  </RouteSummaryIconContainer>
                  <AssistingVehicleContainer first>
                    <RouteSummaryLabel>{translate('common.vehicle')}</RouteSummaryLabel>
                    {routeAssistanceDetails.map(d => (
                      <AssistingVehicleInfo key={d.vehicleRegplate}>
                        <RouteSummaryValue>{d.vehicleRegplate}</RouteSummaryValue>
                      </AssistingVehicleInfo>
                    ))}
                  </AssistingVehicleContainer>
                  <AssistingVehicleContainer>
                    <RouteSummaryLabel>{translate('routeAssist.assistedStops')}</RouteSummaryLabel>
                    {routeAssistanceDetails.map(d => (
                      <AssistingVehicleInfo key={d.vehicleRegplate}>
                        <RouteSummaryValue>
                          {d.assistedStops}
                          {!!totalStopCount && ` / ${totalStopCount}`}
                        </RouteSummaryValue>
                      </AssistingVehicleInfo>
                    ))}
                  </AssistingVehicleContainer>
                  <AssistingVehicleContainer>
                    <RouteSummaryLabel>{translate('routeAssist.totalAssistTime')}</RouteSummaryLabel>
                    {routeAssistanceDetails.map(d => (
                      <AssistingVehicleInfo key={d.vehicleRegplate}>
                        <RouteSummaryValue>{duration(d.assistedTime, 'minutes', 'hh:mm')}</RouteSummaryValue>
                      </AssistingVehicleInfo>
                    ))}
                  </AssistingVehicleContainer>
                </RouteSummaryItem>
              )}
            </RouteAssistSummaryContainer>
          )}
        </RouteSummaryAssistedContainer>
      )}
    </>
  );
};

export default RouteSummary;
