import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { AcceptServiceForm } from '../forms';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { Modal } from '../../../core/components';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  customerName?: string;
  customerAddress?: string;
  hasGusServiceContract?: boolean;
  itemId?: number;
  isLoading: boolean;
  pickupTypeId: number;
  requestedServiceDate: string;
  reasonCodes: ReasonCodeGroups;
  serviceDescription?: string;
  onAcceptService(...args: any): any;
  onCancel(pristine: boolean): void;
  mobileVersion?: boolean;
  daysInWeekSchedule?: number;
}

const AcceptServiceModal: React.FC<Props> = ({
  customerName,
  customerAddress,
  hasGusServiceContract,
  isLoading,
  itemId,
  mobileVersion,
  pickupTypeId,
  reasonCodes,
  requestedServiceDate,
  serviceDescription,
  daysInWeekSchedule,
  onAcceptService,
  onCancel,
}) => {
  const modalTitle =
    pickupTypeId === MISSED_PICKUP
      ? translate('autoDispatch.missedPickup.acceptTitle')
      : translate('opportunity.acceptService');
  return (
    <Modal
      padding="medium"
      isLoading={isLoading}
      maxHeight="80vh"
      size={mobileVersion ? undefined : 'small'}
      title={modalTitle}
    >
      <AcceptServiceForm
        onSubmit={onAcceptService}
        onCancel={onCancel}
        daysInWeekSchedule={daysInWeekSchedule}
        itemId={itemId}
        pickupTypeId={pickupTypeId}
        reasonCodes={reasonCodes}
        requestedServiceDate={requestedServiceDate}
        hasGusServiceContract={hasGusServiceContract}
        customerName={customerName}
        customerAddress={customerAddress}
        serviceDescription={serviceDescription}
      />
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoading: state.fleet.openDispatches.isLoading || state.fleet.openDispatches.isSaving,
});

export default withRouter(connect(mapStateToProps)(AcceptServiceModal));
