import { map } from 'lodash';

import { Group } from 'src/routes/interfaces/Groups';
import { Popover, Text } from 'src/core/components/styled';
import { PopoverWrapper } from 'src/core/components';
import { RouteDetail, RouteDetailLabel, RouteDetailValue } from 'src/routes/components/styled/RouteDetails';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

type Props = {
  isSnowPlowRoute?: boolean;
  routeSummary?: RouteSummary;
};

export const getGroupsAssigned = (groups?: Group[]) => {
  const routeSummaryGroups = groups || [];
  const groupsAssigned = map(routeSummaryGroups.slice(1), group => group.groupName).join(', ');

  return (
    <>
      {routeSummaryGroups.length > 0 ? routeSummaryGroups[0].groupName : '-'}

      {routeSummaryGroups.length > 1 && (
        <PopoverWrapper
          triggerButton={
            <Text margin="no no no xxSmall" block color="primary">
              <b>+{routeSummaryGroups.length - 1}</b>
            </Text>
          }
          popoverContent={<Popover>{groupsAssigned}</Popover>}
          size="medium"
        />
      )}
    </>
  );
};

export default function SnowSweeperRouteHeadingDetails({ isSnowPlowRoute, routeSummary }: Props) {
  const supervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const supervisorName = routeSummary?.supervisorId
    ? `${routeSummary?.supervisorFirstName} ${routeSummary?.supervisorLastName}`
    : '-';

  return (
    <>
      <RouteDetail margin="no">
        <RouteDetailLabel>{translate('common.vehicleType')}: </RouteDetailLabel>
        <RouteDetailValue>
          {VEHICLE_TYPE_IDS[isSnowPlowRoute ? SNOW_PLOW_ID : STREET_SWEEPER_ID]?.name}
        </RouteDetailValue>
      </RouteDetail>
      <RouteDetail margin="no">
        <RouteDetailLabel>{translate('common.vehicleDriver')}: </RouteDetailLabel>
        <RouteDetailValue>
          {routeSummary?.vehicleName || routeSummary?.driverFirstName || routeSummary?.driverLastName ? (
            <>
              {routeSummary?.vehicleName && (routeSummary?.driverFirstName || routeSummary?.driverLastName)
                ? `${routeSummary?.vehicleName} / `
                : routeSummary?.vehicleName && !routeSummary?.driverFirstName && !routeSummary?.driverLastName
                ? routeSummary?.vehicleName
                : undefined}
              {routeSummary?.driverFirstName} {routeSummary?.driverLastName}
            </>
          ) : (
            '-'
          )}
        </RouteDetailValue>
      </RouteDetail>
      <RouteDetail margin="no">
        <RouteDetailLabel>{translate('routes.groups.groups')}: </RouteDetailLabel>
        <RouteDetailValue>{getGroupsAssigned(routeSummary?.groups)}</RouteDetailValue>
      </RouteDetail>

      <RouteDetail margin="no">
        <RouteDetailLabel>{translate('routes.serviceZone')}: </RouteDetailLabel>
        <RouteDetailValue>{routeSummary?.vendorServiceZoneName || '-'}</RouteDetailValue>
      </RouteDetail>

      {supervisorExperienceEnabled && (
        <RouteDetail margin="no">
          <RouteDetailLabel>{translate('routes.supervisor')}: </RouteDetailLabel>
          <RouteDetailValue>{supervisorName}</RouteDetailValue>
        </RouteDetail>
      )}
    </>
  );
}
