import { loadingOverlay } from 'src/core/styles';
import styled from 'styled-components';

export const DashboardMapWrapper = styled.div<{
  isBetaPage?: boolean;
  isSnowSweeperDashboard?: boolean;
  isCollapsed?: boolean;
}>`
  position: relative;
  transition: all 0.3s ease-in-out;
  width: ${props =>
    props.isCollapsed
      ? '100%'
      : props.isBetaPage
      ? props.isSnowSweeperDashboard
        ? 'calc(100% - 350px)'
        : 'calc(100% - 410px)'
      : '100%'};
  height: 100%;

  ${props =>
    props.isCollapsed
      ? 'margin-left: 0px;'
      : props.isBetaPage
      ? props.isSnowSweeperDashboard
        ? 'margin-left: 350px;'
        : 'margin-left: 410px;'
      : ''}

  *:focus {
    outline: none;
  }

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
`;

export const DashboardCustomLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => loadingOverlay('24px', props.theme.brandPrimary, 0.9, 6000)};
`;
