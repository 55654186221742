import { useSelector, useDispatch } from 'react-redux';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';

import { AppState } from 'src/store';
import EsriSyncValidationModal from './EsriSyncValidationModal';
import { validateEsriSyncSettings } from 'src/vendors/ducks/esriSyncSettings';

interface Props {
  closeModal: () => void;
  vendorId: number;
}

export default function EsriSyncValidationModalResolver(props: Props) {
  const { closeModal, vendorId } = props;
  const { esriSyncSettings } = useSelector((state: AppState) => state.vendors.esriSyncSettings);
  const dispatch = useDispatch();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    return validateEsriSyncSettings(vendorId, esriSyncSettings)(dispatch);
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={EsriSyncValidationModal}
      successProps={{ closeModal }}
    />
  );
}
