import { PureComponent } from 'react';

import { formValueSelector, getFormValues, isPristine, isValid, reset as resetForm, submit } from 'redux-form';
import { cloneDeep, orderBy, size, uniqueId } from 'lodash-es';
import { connect } from 'react-redux';
import { FormAction } from 'redux-form';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import update from 'immutability-helper';

import { AppState } from 'src/store';
import { AddRouteTemplateLocationForm, RouteTemplateEditorForm } from '../../forms';
import { Button, PanelSection, PanelSectionGroup, PanelSectionLoading } from '../../../../core/components/styled';
import { createSuccessNotification } from '../../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DELIVERY_UTILITY_ID } from '../../../../fleet/constants';
import { filterRouteEntityLocations } from '../common/BaseRouteEntityEditorPage';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { PageBackButtonAction, PageBackButtonIcon } from '../../../../common/components/styled';
import { PICKUP_TYPE_SERVICE_ID } from '../../../constants/routePickupTypes';
import {
  resetRoutesForReassignment,
  resetRouteTemplate,
  resetRouteTemplateUnassignedLocations,
  routeTemplateLocationAddressChange,
  saveRouteTemplate,
  transferRouteTemplateLocations,
} from '../../../ducks';
import { resetDisposalSiteLocations } from '../../../../common/ducks';
import { resetGeoFence } from '../../../ducks/routeGeoFence';
import { resetVehicleTypesForVendor } from '../../../../fleet/ducks';
import { routeFormKeys } from '../../../constants';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { SortableTable, Table } from '../../../../core/components';
import { TABLE_ROW_HEIGHT } from '../../../../core/constants';
import { TransferRouteTemplateLocationsModal, UnassignedLocationsModalResolver } from './routeTemplatePageSections';
import { DuckAction, DuckFunction } from '../../../../contracts/ducks';
import { RouteTemplate, RouteTemplateLocation } from 'src/routes/interfaces/RouteTemplates';
import BaseRouteEntityEditorPage from '../common/BaseRouteEntityEditorPage';
import confirm from '../../../../core/services/confirm';
import RouteLocationsTableRow, { UnsortableRouteLocationsTableRow } from './RouteLocationsTableRow';
import RouteLocationsWithZeroStopTableRow from './RouteLocationsWithZeroStopTableRow';
import translate from '../../../../core/services/translate';
import {
  FIRST_JOB_POSITION_ID,
  LAST_JOB_POSITION_ID,
  OPTIMIZED_JOB_POSITION_ID,
} from 'src/core/constants/jobPositionOptions';

interface RouteParams {
  routeTemplateId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  batchTransfererInProgress: boolean;
  batchTransfererProgress: number;
  isLoading: boolean;
  isRouteEntityFormPristine: boolean;
  isRouteEntityFormValid: boolean;
  isSaving: boolean;
  isSnowPlowRoute: boolean;
  locationsInfo: any;
  push: (url: string) => void;
  resetDisposalSiteLocations: DuckAction<typeof resetDisposalSiteLocations>;
  resetForm: DuckAction<typeof resetForm>;
  resetGeoFence: DuckAction<typeof resetGeoFence>;
  resetRoutesForReassignment: DuckAction<typeof resetRoutesForReassignment>;
  resetRouteTemplate: DuckAction<typeof resetRouteTemplate>;
  resetRouteTemplateUnassignedLocations: DuckAction<typeof resetRouteTemplateUnassignedLocations>;
  resetVehicleTypesForVendor: DuckAction<typeof resetVehicleTypesForVendor>;
  routeEntity?: any;
  routeEntityLocationAddressChange: DuckFunction<typeof routeTemplateLocationAddressChange>;
  saveRouteEntity: DuckFunction<typeof saveRouteTemplate>;
  scheduledDay?: string;
  shouldRecreateRoutes: boolean;
  submit: (form: string) => FormAction;
  transferRouteTemplateLocations: DuckFunction<typeof transferRouteTemplateLocations>;
  vehicleTypeId: number;
  vendorId: number;
  wasteMaterialTypeId?: number;
}

interface State {
  isUnassignedLocationsModalOpen: boolean;
}

class RouteTemplateEditorPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isUnassignedLocationsModalOpen: false,
    };
  }

  private baseEditorRef?: any;

  componentDidUpdate(prevProps: Props) {
    const { push, vehicleTypeId, isSnowPlowRoute } = this.props;
    const { vehicleTypeId: prevVehicleTypeId } = prevProps;

    if (vehicleTypeId !== prevVehicleTypeId && isSnowPlowRoute) {
      const routeTemplateId = Number(this.props.match.params.routeTemplateId);
      push(`/routes/route-templates/${routeTemplateId}`);
    }
  }

  componentWillUnmount() {
    const { resetRouteTemplate, resetRouteTemplateUnassignedLocations, resetGeoFence } = this.props;
    resetRouteTemplate();
    resetRouteTemplateUnassignedLocations();
    resetGeoFence();
  }

  setBaseEditorRef = (ref: any) => {
    this.baseEditorRef = ref;
  };

  getStopId = (routeTemplateLocation: RouteTemplateLocation) => routeTemplateLocation.service.id;

  addUnassignedLocationsToRoute = (locations: RouteTemplate[], insertPosition: number) => {
    const { state } = this.baseEditorRef;
    const { resetForm, vehicleTypeId } = this.props;
    let newLocations;

    newLocations = cloneDeep(locations);
    newLocations.forEach((location: any, index: number) => {
      vehicleTypeId === DELIVERY_UTILITY_ID && (location.pickupTypeId = PICKUP_TYPE_SERVICE_ID);
      if (insertPosition === OPTIMIZED_JOB_POSITION_ID) location.orderNumber = JOB_PENDING_OPTIMIZATION_ID;
      else if (insertPosition === LAST_JOB_POSITION_ID)
        location.orderNumber =
          state.routeEntityLocations.filter(
            (routeLocation: any) => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
          ).length +
          index +
          1;
      else {
        location.orderNumber = index + 1;
      }

      location.stopId = uniqueId();
    });

    const routeLocationsWithStopNumber = state.routeEntityLocations.filter(
      (routeLocation: RouteTemplate) => routeLocation.orderNumber !== 0,
    );
    const routeLocationsWithZeroStopNumber = state.routeEntityLocations.filter(
      (routeLocation: RouteTemplate) => routeLocation.orderNumber === 0,
    );

    const routeTemplateLocationsAfterAdd =
      insertPosition === FIRST_JOB_POSITION_ID || insertPosition === OPTIMIZED_JOB_POSITION_ID
        ? update(routeLocationsWithStopNumber, {
            $unshift: newLocations,
          })
        : update(routeLocationsWithStopNumber, {
            $push: newLocations,
          });

    const routeEntityLocationsReseted = this.baseEditorRef.resetOrderNumbers(routeTemplateLocationsAfterAdd);

    const filteredRouteEntityLocationsWithStopNumber = filterRouteEntityLocations(
      routeEntityLocationsReseted,
      state.searchTerm,
    );
    const filteredRouteEntityLocationsWithZeroStopNumber = filterRouteEntityLocations(
      routeLocationsWithZeroStopNumber,
      state.searchTerm,
    );

    this.baseEditorRef.setState({
      routeEntityLocations: [...routeLocationsWithZeroStopNumber, ...routeEntityLocationsReseted],
      filteredRouteEntityLocations: [
        ...filteredRouteEntityLocationsWithZeroStopNumber,
        ...filteredRouteEntityLocationsWithStopNumber,
      ],
      allRouteEntityLocationsChecked: false,
      saveStops: true,
      mapShouldFitBounds: true,
      polygonPathShouldReset: false,
    });

    resetForm('routeTemplateEditorLocationSearch');
  };

  openUnassignedLocationsModal = () => {
    this.setState({ isUnassignedLocationsModalOpen: true });
  };

  closeUnassignedLocationsModal = () => {
    this.setState({ isUnassignedLocationsModalOpen: false });
  };

  transferLocations = (
    baseTransferLocations: any,
    targetRouteTemplateId: any,
    shouldRecreateRoutes: boolean,
    positionTypeId: number,
  ) => {
    const {
      match: {
        params: { routeTemplateId },
      },
      transferRouteTemplateLocations,
    } = this.props;

    const id = typeof targetRouteTemplateId === 'number' ? targetRouteTemplateId : targetRouteTemplateId.id;
    baseTransferLocations(transferRouteTemplateLocations, this.getStopId, {
      targetRouteTemplateId: id,
      routeTemplateId,
      shouldRecreateRoutes,
      positionTypeId,
    });
  };

  handleBackAction = () => {
    const {
      history: { length, goBack },
      push,
    } = this.props;

    return length > 1 ? goBack() : push('/routes/route-templates');
  };

  render() {
    const {
      routeEntity,
      vehicleTypeId,
      scheduledDay,
      vendorId,
      wasteMaterialTypeId,
      match: {
        params: { routeTemplateId },
      },
    } = this.props;
    const pickupTypeEnabled = vehicleTypeId === DELIVERY_UTILITY_ID;

    const routeLocationsTableCells = [
      {
        name: 'customer',
        label: translate('common.customer'),
        width: pickupTypeEnabled ? '17%' : '20%',
        sortable: false,
      },
      {
        name: 'accountStatus',
        label: translate('routes.accountStatus'),
        width: pickupTypeEnabled ? '9%' : '13%',
      },
      { name: 'service', label: translate('routes.service'), width: pickupTypeEnabled ? '17%' : '20%' },
      { name: 'dayOfService', label: translate('routes.dayOfService'), width: pickupTypeEnabled ? '17%' : '20%' },
      { name: 'options', label: translate('common.options'), width: pickupTypeEnabled ? '9%' : '10%' },
    ];

    if (pickupTypeEnabled) {
      routeLocationsTableCells.splice(4, 0, {
        name: 'pickupType',
        label: translate('routes.pickupType'),
        width: '13%',
        sortable: true,
      });
    }

    return (
      <BaseRouteEntityEditorPage
        ref={this.setBaseEditorRef}
        locationsAccessorKey="locations"
        additionalRouteEntityLocationsTableCells={routeLocationsTableCells}
        displayRouteEntityUrl="/routes/route-templates/"
        formKey={routeFormKeys.routeTemplate}
        routeEntityId={routeTemplateId}
        routeEntityLocations={routeEntity && (routeEntity.locations || routeEntity.routeLocations)}
        routeEntityLocationIdAccessor={routeTemplateId ? 'serviceContractRouteTemplateId' : 'stopId'}
        routeEntitySavedTranslationKey="routes.alertMessages.routeSaved"
        formsToResetOnLocationAdd={['addRouteTemplateLocation', 'routeTemplateEditorLocationSearch']}
        mapEditButtonId="route-template-map-edit-button"
        {...this.props}
        renderBackButton={() => (
          <PageBackButtonAction
            disabled={this.props.locationsInfo && this.props.locationsInfo.isLoading}
            onClick={this.handleBackAction}
            id="back-button"
          >
            <PageBackButtonIcon />
          </PageBackButtonAction>
        )}
        renderEditorForm={(locations, transferredStops, onSubmit) => (
          <RouteTemplateEditorForm
            disabledFields={{
              scheduledDay: (size(locations) > 0 && scheduledDay) || routeTemplateId,
              vehicleTypeId: (size(locations) > 0 && vehicleTypeId) || routeTemplateId,
            }}
            isCreateMode={!routeTemplateId}
            routeTemplateLocations={locations}
            transferredStops={transferredStops}
            onSubmit={onSubmit}
          />
        )}
        renderAddRouteEntityLocationForm={(onSubmit, ref) => {
          const newOptimizedRouteLocationsLength =
            ref.state.routeEntityLocations.filter(
              (routeLocation: RouteTemplate) =>
                routeLocation.serviceContractRouteTemplateId &&
                routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
            ).length || 0;

          return !!vehicleTypeId && !!scheduledDay ? (
            <AddRouteTemplateLocationForm
              onSubmit={(values: any) => {
                const { routeLocation, pickupTypeId, positionTypeId } = values;
                onSubmit({ ...routeLocation, pickupTypeId, positionTypeId } as any);
              }}
              openUnassignedLocationsModal={() => this.openUnassignedLocationsModal()}
              routeTemplateId={routeTemplateId}
              newOptimizedRouteLocationsLength={newOptimizedRouteLocationsLength}
            />
          ) : null;
        }}
        renderLocationsTable={ref => {
          if (
            this.props.locationsInfo &&
            this.props.locationsInfo.isLoading &&
            !ref.state.filteredRouteEntityLocations.length
          ) {
            return <PanelSectionLoading />;
          }

          const routeLocationsWithStopNumber = orderBy(
            ref.state.filteredRouteEntityLocations.filter(
              (routeLocation: RouteTemplate) => routeLocation.orderNumber > 0,
            ),
            ['orderNumber'],
            ['asc'],
          );

          const routeLocationsWithZeroStopNumber = ref.state.filteredRouteEntityLocations.filter(
            (routeLocation: RouteTemplate) => routeLocation.orderNumber === 0,
          );

          const optimizedRouteLocations = ref.state.filteredRouteEntityLocations.filter(
            (routeLocation: RouteTemplate) => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
          );
          const newOptimizedRouteLocationsLength = ref.state.routeEntityLocations.filter(
            (routeLocation: RouteTemplate) =>
              routeLocation.serviceContractRouteTemplateId && routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
          ).length;
          const currentOptimizedRouteLocationsLength = ref.state.filteredRouteEntityLocations.filter(
            (routeLocation: RouteTemplate) => !routeLocation.serviceContractRouteTemplateId,
          ).length;

          let optimizedCanceledRouteLocations: RouteTemplate[] = [];
          const cancelOptimization = async () => {
            if (!(await confirm(translate('routes.alertMessages.confirmCancelOptimization')))) {
              return;
            }

            optimizedRouteLocations.forEach((routeLocation: RouteTemplate, index: number) => {
              optimizedCanceledRouteLocations.push({
                ...routeLocation,
                orderNumber:
                  ref.state.routeEntityLocations.filter(
                    (routeLocation: any) => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
                  ).length +
                  index +
                  1,
              });
            });

            this.baseEditorRef.setState(
              {
                filteredRouteEntityLocations: [
                  ...ref.state.filteredRouteEntityLocations.filter(
                    (routeLocation: RouteTemplate) => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
                  ),
                  ...optimizedCanceledRouteLocations,
                ],
                routeEntityLocations: [
                  ...ref.state.routeEntityLocations.filter(
                    (routeLocation: RouteTemplate) => routeLocation.orderNumber !== JOB_PENDING_OPTIMIZATION_ID,
                  ),
                  ...optimizedCanceledRouteLocations,
                ],
                saveStops: true,
              },
              () => {
                createSuccessNotification(translate('routes.alertMessages.confirmCancelOptimizationSucess'));
              },
            );
          };

          return (
            <PanelSectionGroup width="100%">
              {!!size(optimizedRouteLocations) && (
                <PanelSection>
                  <Table
                    cells={ref.getRouteLocationsTableCells(newOptimizedRouteLocationsLength)}
                    rowComponent={UnsortableRouteLocationsTableRow}
                    rows={optimizedRouteLocations}
                    tableHeading={translate(
                      currentOptimizedRouteLocationsLength
                        ? 'routes.pendingOptimizationWillStartAfterSave'
                        : 'routes.pendingOptimization',
                    )}
                    tableHeadingAction={
                      newOptimizedRouteLocationsLength > 0 && (
                        <Button
                          type="button"
                          color="primary"
                          size="xSmall"
                          margin="no no no xSmall"
                          onClick={() => cancelOptimization()}
                        >
                          {translate('routes.cancelOptimization')}
                        </Button>
                      )
                    }
                    rowProps={{
                      deleteRouteTemplateLocation: ref.deleteRouteEntityLocation,
                      editService: ref.openServiceDetailsModal,
                      enablePickupType: pickupTypeEnabled,
                      isSearchInitialized: ref.state.isSearchInitialized,
                      onRouteLocationDrop: size(routeLocationsWithZeroStopNumber) ? ref.onRouteLocationDrop : undefined,
                      onRoutePickupTypeChange: (value: string, locationId: number, stopId: string) =>
                        ref.updateRouteProperty(Number(value), locationId, 'pickupTypeId', stopId),
                      openOrderNumberPopover: ref.openOrderNumberPopover,
                      selectLocation: ref.selectLocation,
                      selectRouteTemplateLocation: ref.onRouteEntityLocationSelected,
                      newOptimizedRouteLocationsLength: newOptimizedRouteLocationsLength,
                    }}
                  />
                </PanelSection>
              )}
              <PanelSection>
                {!!size(routeLocationsWithZeroStopNumber) && (
                  <Table
                    cells={
                      size(optimizedRouteLocations)
                        ? []
                        : ref.getRouteLocationsTableCells(newOptimizedRouteLocationsLength)
                    }
                    rowComponent={RouteLocationsWithZeroStopTableRow}
                    rows={routeLocationsWithZeroStopNumber}
                    rowProps={{
                      deleteRouteTemplateLocation: ref.deleteRouteEntityLocation,
                      editService: ref.openServiceDetailsModal,
                      enablePickupType: pickupTypeEnabled,
                      isSearchInitialized: ref.state.isSearchInitialized,
                      onRoutePickupTypeChange: (value: string, locationId: number) =>
                        ref.updateRouteProperty(Number(value), locationId, 'pickupTypeId'),
                      openOrderNumberPopover: ref.openOrderNumberPopover,
                      selectLocation: ref.selectLocation,
                      selectRouteTemplateLocation: ref.onRouteEntityLocationSelected,
                      newOptimizedRouteLocationsLength: newOptimizedRouteLocationsLength,
                    }}
                    tableHeading={translate('routes.unableToSequenceRoute')}
                  />
                )}
              </PanelSection>
              <PanelSection>
                {!!size(routeLocationsWithStopNumber) && (
                  <SortableTable
                    cells={
                      size(routeLocationsWithZeroStopNumber) || size(optimizedRouteLocations)
                        ? []
                        : ref.getRouteLocationsTableCells(newOptimizedRouteLocationsLength)
                    }
                    rows={routeLocationsWithStopNumber}
                    rowComponent={RouteLocationsTableRow}
                    rowProps={{
                      deleteRouteTemplateLocation: ref.deleteRouteEntityLocation,
                      editService: ref.openServiceDetailsModal,
                      enablePickupType: pickupTypeEnabled,
                      isSearchInitialized: ref.state.isSearchInitialized,
                      onRouteLocationDrop: size(routeLocationsWithZeroStopNumber) ? ref.onRouteLocationDrop : undefined,
                      onRoutePickupTypeChange: (value: string, locationId: number, stopId: string) =>
                        ref.updateRouteProperty(Number(value), locationId, 'pickupTypeId', stopId),
                      openOrderNumberPopover: ref.openOrderNumberPopover,
                      selectLocation: ref.selectLocation,
                      selectRouteTemplateLocation: ref.onRouteEntityLocationSelected,
                      newOptimizedRouteLocationsLength: newOptimizedRouteLocationsLength,
                    }}
                    virtualized
                    virtualizedProps={{
                      itemSize: TABLE_ROW_HEIGHT,
                      height:
                        Math.min(routeLocationsWithStopNumber.length * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 8) || 1,
                    }}
                    sort={ref.onSortOrderChange}
                    withClickableRows
                    noOverflow
                  />
                )}
              </PanelSection>
            </PanelSectionGroup>
          );
        }}
        renderTransferRouteEntityLocationsModal={(onClose, baseTransferLocations) => (
          <TransferRouteTemplateLocationsModal
            vendorId={vendorId}
            sourceRouteTemplateId={routeEntity && routeEntity.id}
            vehicleTypeId={vehicleTypeId}
            wasteMaterialTypeId={wasteMaterialTypeId}
            onTransferRouteTemplateLocationsSubmit={({
              targetRouteTemplateId,
              shouldRecreateRoutes,
              positionTypeId,
            }: any) =>
              this.transferLocations(baseTransferLocations, targetRouteTemplateId, shouldRecreateRoutes, positionTypeId)
            }
            closeModal={onClose}
          />
        )}
      >
        {this.state.isUnassignedLocationsModalOpen && (
          <UnassignedLocationsModalResolver
            addUnassignedLocationsToRoute={this.addUnassignedLocationsToRoute as any}
            closeModal={this.closeUnassignedLocationsModal}
            vehicleTypeId={vehicleTypeId as number}
            scheduledDay={scheduledDay as Date | string}
            routeTemplateId={routeTemplateId}
          />
        )}
      </BaseRouteEntityEditorPage>
    );
  }
}

const routeTemplateFormSelector = formValueSelector('routeTemplateEditor');

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('updateTrackerRouteSwitchWrapper')(state) as any;
  const vehicleTypeId = Number(routeTemplateFormSelector(state, 'vehicleTypeId'));

  return {
    batchTransfererInProgress: state.routes.routeTemplate.batchTransfererInProgress,
    batchTransfererProgress: state.routes.routeTemplate.batchTransfererProgress,
    isLoading: state.routes.routeTemplate.isLoading,
    isRouteEntityFormPristine: isPristine('routeTemplateEditor')(state),
    isRouteEntityFormValid: isValid('routeTemplateEditor')(state),
    isSaving: state.routes.routeTemplate.isSaving,
    locationsInfo: {
      isLoading: state.routes.routeTemplate.isLoadingLocations,
      total: state.routes.routeTemplate.locationsTotal,
      limit: state.routes.routeTemplate.locationsLimit,
      loadedPages: state.routes.routeTemplate.loadedPages,
    },
    routeEntity: state.routes.routeTemplate.routeTemplate,
    scheduledDay: routeTemplateFormSelector(state, 'scheduledDay'),
    vehicleTypeId,
    isSnowPlowRoute: vehicleTypeId === SNOW_PLOW_ID,
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    wasteMaterialTypeId:
      typeof routeTemplateFormSelector(state, 'wasteMaterialTypeId') !== 'undefined' &&
      routeTemplateFormSelector(state, 'wasteMaterialTypeId') !== '' &&
      routeTemplateFormSelector(state, 'wasteMaterialTypeId') !== null
        ? Number(routeTemplateFormSelector(state, 'wasteMaterialTypeId'))
        : undefined,
    shouldRecreateRoutes: formValues ? formValues.shouldRecreateRoutes : false,
  };
};

const mapDispatchToProps = {
  saveRouteEntity: saveRouteTemplate,
  transferRouteTemplateLocations,
  routeEntityLocationAddressChange: routeTemplateLocationAddressChange,
  resetRouteTemplate,
  push,
  submit,
  resetVehicleTypesForVendor,
  resetRouteTemplateUnassignedLocations,
  resetRoutesForReassignment,
  resetDisposalSiteLocations,
  resetForm,
  resetGeoFence,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTemplateEditorPage));
