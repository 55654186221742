import { date } from '../../../utils/services/formatter';
import { TableCell } from '../../../core/components/Table';
import translate from '../../../core/services/translate';

interface RubiconDispatchesTableColumnsReturn {
  columns: TableCell[];
  flexes: (string | undefined)[];
}

export const useRubiconDispatchesTableColumns = (): RubiconDispatchesTableColumnsReturn => {
  const flexes = ['0 1 15%', '0 1 20%', '0 1 24%', '0 1 20%', '0 1 20%'];

  const columns: TableCell[] = [
    {
      name: 'workorderNumber',
      label: translate('autoDispatch.rubiconDispatchesColumns.workorderNumber'),
      flex: flexes[0],
      sortable: true,
    },
    {
      name: 'equipmentType',
      label: translate('autoDispatch.rubiconDispatchesColumns.equipmentType'),
      flex: flexes[1],
      sortable: true,
      padding: 'defaultCellVertical no',
    },
    {
      name: 'customer',
      label: translate('autoDispatch.rubiconDispatchesColumns.customer'),
      flex: flexes[2],
      sortable: true,
    },
    {
      name: 'status',
      label: translate('autoDispatch.rubiconDispatchesColumns.status'),
      flex: flexes[3],
      sortable: true,
    },
    {
      name: 'actions',
      label: translate('autoDispatch.rubiconDispatchesColumns.actions'),
      flex: flexes[5],
      sortable: true,
      align: 'right',
    },
  ];

  return { flexes, columns };
};

export enum DispatchStatus {
  CLOSED = 'Closed',
  COMPLETE = 'Work Order Complete',
  CONFIRMED = 'Service Confirmed',
  DELIVERY_SUSPENDED = 'Delivery Suspended',
  DECLINE = 'Decline',
  PENDING = 'Pending',
  REQUESTED = 'Requested',
  SCHEDULED = 'Scheduled',
  UNABLE_TO_COMPLETE = 'Unable To Complete',
}

export const getNoteIcon = (statusName: string) => {
  const { COMPLETE, CONFIRMED, PENDING, REQUESTED, SCHEDULED } = DispatchStatus;

  switch (statusName) {
    case REQUESTED:
      return 'requestedNote';

    case PENDING:
      return 'pendingNote';

    case SCHEDULED:
      return 'scheduledNote';

    case COMPLETE:
      return 'completedNote';

    case CONFIRMED:
      return 'page-check';

    default:
      return 'page-content';
  }
};

export const getDateByStatus = (statusName: string, dates: any) => {
  const { CLOSED, COMPLETE, CONFIRMED, DELIVERY_SUSPENDED, PENDING, REQUESTED, SCHEDULED, UNABLE_TO_COMPLETE } = DispatchStatus;

  switch (statusName) {
    case REQUESTED:
      return dates.requestedDate ? date(dates.requestedDate) : '';

    case PENDING:
      return dates.requestedDate ? date(dates.requestedDate) : '';

    case SCHEDULED:
      return dates.scheduledDate ? date(dates.scheduledDate) : '';

    case COMPLETE:
      return dates.completedDate ? date(dates.completedDate) : '';

    case CONFIRMED:
      return dates.completedDate ? date(dates.completedDate) : '';

    case DELIVERY_SUSPENDED:
      return dates.scheduledDate ? date(dates.scheduledDate) : '';

    case CLOSED:
      return dates.latestDate ? date(dates.latestDate) : '';

    case UNABLE_TO_COMPLETE:
      return dates.requestedDate ? date(dates.requestedDate) : date(dates.scheduledDate);

    default:
      return dates.requestedDate ? date(dates.requestedDate) : '';
  }
};

