import React, { PureComponent } from 'react';
import Select, { MenuPosition } from 'react-select';
import { has, reduce, find } from 'lodash-es';
import { FormGroup, FormLabel } from './styled';
import { dropdownStyles } from './Dropdown';

interface Props {
  disabled?: boolean;
  label?: string;
  margin?: string;
  onChange?: (value: any) => void;
  options: any[];
  placeholder?: string;
  value?: number | string;
  width?: string;
  isClearable?: boolean;
  menuPosition?: MenuPosition;
  customComponents?: any;
}

interface State {
  inputText: string;
  value?: number | string;
}

class UnconnectedDropdown extends PureComponent<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { value } = nextProps;
    if (prevState.value !== value) {
      return { value };
    }
    return prevState;
  }

  constructor(props: Props) {
    super(props);
    this.state = { inputText: '', value: props.value };
  }

  onInputChange = (inputText: string) => {
    this.setState({ inputText });
  };

  onChange = (option: any) => {
    const value = has(option, 'value') ? option.value : null;
    this.setState({ value });

    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  findSelectedOption = (value: any) =>
    reduce(
      this.props.options,
      (selectedOption, option) => {
        if (selectedOption) return selectedOption;
        if (option.value && option.value === value) return option;
        return find(option.options, { value }) || '';
      },
      '',
    );

  render() {
    const { placeholder, label, disabled, margin, width, ...props } = this.props;
    const { value, inputText } = this.state;

    return (
      <FormGroup width={width} margin={margin} hasValue={value || inputText}>
        {!!label && <FormLabel>{label}</FormLabel>}

        <Select
          {...props}
          classNamePrefix="vpDropdown"
          isDisabled={disabled}
          styles={dropdownStyles}
          placeholder={placeholder || ''}
          value={this.findSelectedOption(value)}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          components={this.props.customComponents}
        />
      </FormGroup>
    );
  }
}

export default UnconnectedDropdown;
