import React from 'react';
import { Modal } from '../../../core/components';
import { OpenBillsPdfViewer } from 'src/finance/components/pages/applyChecks/applyChecksPageSections';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  selectedOpenBillId: number;
  selectedOpenBillDocumentUrl: string | null;
  onDownload(...args: any[]): void;
  onCancel(): void;
}

const OpenBillPdfViewerModal = ({ onDownload, onCancel, selectedOpenBillId, selectedOpenBillDocumentUrl }: Props) => {
  const { isExportingBill } = useSelector(state => state.finance.openBills);

  return (
    <Modal
      overflow="visible"
      padding="medium"
      size="medium"
      isLoading={isExportingBill}
      title={translate('finance.applyChecks.openBill')}
      subTitle={`${selectedOpenBillId}`}
      onClose={onCancel}
    >
      <OpenBillsPdfViewer onDownload={onDownload} openBillDocumentUrl={selectedOpenBillDocumentUrl} />
    </Modal>
  );
};

export default OpenBillPdfViewerModal;
