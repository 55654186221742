import update from 'immutability-helper';
import { filter, find, get, identity, mapKeys } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { AppState } from '../../store';
import { VehicleType, VehicleTypesState } from '../interfaces/VehicleTypes';
import { loadVehicleTypes as doLoadVehicleTypes } from '../services/vehicleTypes';

// Actions
const START_LOAD = 'common/vehicleTypes/START_LOAD';
const COMPLETE_LOAD = 'common/vehicleTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/vehicleTypes/FAIL_LOAD';
const RESET = 'common/vehicleTypes/RESET';

// Initial state
const initialState: VehicleTypesState = {
  isLoading: false,
  vehicleTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTypes: action.vehicleTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleTypes: VehicleType[]) => ({
  type: COMPLETE_LOAD,
  vehicleTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadVehicleTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVehicleTypesPromise = doLoadVehicleTypes();
  loadVehicleTypesPromise.then(vehicleTypes => dispatch(completeLoad(vehicleTypes))).catch(() => dispatch(failLoad()));
  return loadVehicleTypesPromise;
};

export const resetVehicleTypes = () => ({
  type: RESET,
});

// Selectors
const getVehicleTypesById = (vehicleTypesState: any) => mapKeys(vehicleTypesState.vehicleTypes, 'id');

export const vehicleTypesByIdSelector = createSelector(getVehicleTypesById, identity);

export const vehicleTypesById = (state: AppState) => vehicleTypesByIdSelector(state.fleet.vehicleTypes);

const getVehicleTypesForRoleType = (vehicleTypesState: any, vehicleRoleType: any, excludeVehicleTypes: any) =>
  filter(
    vehicleTypesState.vehicleTypes,
    vehicleType =>
      (!vehicleRoleType || vehicleType.vehicleRoleType === vehicleRoleType) &&
      (!excludeVehicleTypes || excludeVehicleTypes.indexOf(vehicleType.technicalName) === -1),
  );

export const vehicleTypesForRoleTypeSelector = createSelector(getVehicleTypesForRoleType, identity);

const getVehicleSubTypes = (vehicleTypesState: any, vehicleTypeId: number) => {
  const vehicleType = find(vehicleTypesState.vehicleTypes, { id: vehicleTypeId });
  return get(vehicleType, 'subTypes', []);
};

export const vehicleSubTypesSelector = createSelector(getVehicleSubTypes, identity);
