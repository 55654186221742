import { camelize } from 'humps';
import moment from 'moment';

import { currency } from 'src/utils/services/formatter';
import { Grid, GridColumn, Label, Popover, Text } from 'src/core/components/styled';
import { ServiceWorkOrder } from 'src/customers/interfaces/Services';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import INSIGHT_ICONS from 'src/common/constants/insightIcons';
import translate from 'src/core/services/translate';

interface Props {
  getPUStatusColor: (date: StringOrDate, pickupStatus: string) => string;
  isOperationalView: boolean;
  workOrders: ServiceWorkOrder[];
}

const WorkOrdersMonthlyEventItemPopoverContent: React.FC<Props> = ({
  getPUStatusColor,
  isOperationalView,
  workOrders,
}) => (
  <Popover align="left" padding="small xxSmall">
    {workOrders.map(workOrder => {
      const ps = workOrder.pickupStatus ? camelize(workOrder.pickupStatus) : 'unScheduled';

      return (
        <Grid key={workOrder.workOrderNumber} padding="no small no no" multiLine>
          <GridColumn size="4/12" margin="no no xSmall" padding="no xSmall xSmall xSmall" borderBottom>
            <Text block size="large">
              {moment(workOrder.scheduledDate).format('MM/DD/YYYY')}
            </Text>
          </GridColumn>
          <GridColumn size="8/12" margin="no no xSmall" borderBottom alignVerticalCenter>
            <Text block size="small">
              {translate('customers.serviceEditor.xWorkOrderNo', { woNumber: workOrder.workOrderNumber || '-' })}
            </Text>
            {isOperationalView && (
              <Text flex flexAlignCenter size="small">
                <img
                  src={INSIGHT_ICONS[ps]}
                  width="15"
                  height="15"
                  alt={translate(createTranslationKey(ps, 'common.pickupStatuses'))}
                />
                {!!workOrder.workFlow && (
                  <>
                    {' | '}
                    <strong>
                      {translate(createTranslationKey(workOrder.workFlow, 'customers.serviceEditor.workFlows'))}
                    </strong>
                  </>
                )}
              </Text>
            )}
          </GridColumn>

          {isOperationalView ? (
            <>
              <GridColumn size="4/12" margin="no no xSmall">
                <Text block size="small">
                  {translate('common.route')}
                </Text>
                <Text block weight="medium" size="medium" wordWrap="break-word">
                  {workOrder.routeName}
                </Text>
              </GridColumn>

              <GridColumn size="4/12" margin="no no xSmall">
                <Text block size="small">
                  {translate('common.serviceStatus')}
                </Text>
                <Label color={getPUStatusColor(workOrder.scheduledDate, ps)}>
                  {translate(`common.pickupStatuses.${ps}`)}
                </Label>
              </GridColumn>
            </>
          ) : (
            <>
              <GridColumn size="4/12" margin="no no xSmall">
                <Text block size="small">
                  {translate('customers.serviceEditor.invoiced')}
                </Text>
                <Text weight="medium" size="medium">
                  {workOrder.invoiced || '-'}
                </Text>
              </GridColumn>

              <GridColumn size="4/12" margin="no no xSmall">
                <Text block size="small">
                  {translate('customers.serviceEditor.amount')}
                </Text>
                <Text block weight="medium" size="medium">
                  {currency(workOrder.amount)}
                </Text>
              </GridColumn>
            </>
          )}
          <GridColumn size="4/12" margin="no no xSmall">
            <Text block size="small">
              {translate('common.vehicle')}
            </Text>
            <Text block size="small" weight="medium">
              {workOrder.vehicleId || '-'}
            </Text>
          </GridColumn>
        </Grid>
      );
    })}
  </Popover>
);

export default WorkOrdersMonthlyEventItemPopoverContent;
