import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';

import { getQueryParams } from '../../../utils/services/queryParams';
import { LIMIT_PER_PAGE } from '../../constants';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { AcceptOnCallServicesForm } from '../forms';
import { AppState } from '../../../store';

interface ComponentProps {
  workOrderIdentifier: string;
  workOrder: number;
  vendorId: number;
  action: number;
  updateOnCallServices(...args: any[]): any;
  onCancel(...args: any[]): any;
  token: string;
  location: any;
}

interface ReduxProps {
  rateCodes?: any[];
  pricingInfo?: any;
  isSaving?: boolean;
}

type Props = ReduxProps & ComponentProps;

class AcceptOnCallServicesModal extends PureComponent<Props> {
  onSubmit = async () => {
    const { vendorId, workOrder, action, rateCodes, pricingInfo, location, onCancel, token } = this.props;
    const { page } = getQueryParams(location.search);
    const requestRecurringServicesObj: any = {};

    requestRecurringServicesObj.workOrder = workOrder;
    requestRecurringServicesObj.date = moment(pricingInfo.reportDate).format('YYYY-MM-DD');
    requestRecurringServicesObj.rubiconInvoiceNo = pricingInfo.rubiconInvoiceNo;

    if (action === 0 || action === 4) {
      requestRecurringServicesObj.rateCodes = rateCodes;
    }

    await this.props
      .updateOnCallServices(requestRecurringServicesObj, vendorId, token, page, LIMIT_PER_PAGE)
      .then(() => {
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  };

  stopPropagation = (event: any) => {
    event.preventDefault();
  };

  render() {
    const { isSaving, onCancel } = this.props;

    return (
      <Modal size="small" isLoading={isSaving}>
        <ModalTitle>{translate('opportunity.acceptService')}</ModalTitle>
        <ModalSubtitle />
        <Grid centered>
          <GridColumn size="8/12">
            <AcceptOnCallServicesForm {...this.props} onSubmit={this.onSubmit} onCancel={onCancel} />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('acceptOnCallServices');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  rateCodes: formSelector(state, `workOrdersInfo.settings${ownProps.workOrderIdentifier}.rateCodes`),
  pricingInfo: state.payments.onCallServices.pricingInfo,
  isSaving: state.payments.onCallServices.isSaving,
});

export default connect(mapStateToProps)(AcceptOnCallServicesModal);
