import { isNumber, isString } from 'lodash-es';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getFormValues, submit } from 'redux-form';

import { AppState } from 'src/store';
import { checkDuplicateJobs, checkDuplicateWorkOrderNoForRouteJob } from 'src/routes/services/dispatchBoardRouteJobs';
import { checkDuplicateWorkOrderNoForUnassignedJob } from 'src/routes/services/dispatchBoardUnassignedJobs';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { CustomerLocationServiceContract } from 'src/customers/interfaces/Customers';
import { DISPATCH_BOARD_SOURCE_FORM_NAME } from 'src/routes/components/forms/DispatchBoardSourceForm';
import { DISPATCH_BOARD_TARGET_FORM_NAME } from 'src/routes/components/forms/DispatchBoardTargetForm';
import { DispatchBoardJobEditorForm } from 'src/routes/components/forms';
import { DuckAction, DuckFunction } from 'src/contracts/ducks';
import { extractRouteId, isRouteTemplate } from 'src/routes/services/routeTemplateHelper';
import {
  loadRouteHasMaterialTypeConfigured,
  loadRouteHasStopsInPendingOptimization,
} from 'src/routes/ducks/dispatchBoard/dispatchBoardRouteJob';
import { Modal } from 'src/core/components';
import { resetForceMaterialTypes } from 'src/vendors/ducks';
import { saveDispatchBoardRouteJob, saveDispatchBoardUnassignedJob } from 'src/routes/ducks/dispatchBoard';
import { UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';

export interface DispatchBoardJobEditorModalCommonProps {
  allowRoutesFromPast?: boolean;
  closeModal(): void;
  customerId?: number;
  date?: Date | string;
  forceMaterialTypeId?: string;
  isMaterialTypesEnabled?: boolean;
  jobId?: number;
  newMaterialTypeId?: string;
  newRouteId?: string;
  refreshOnHoldJobs?(): void;
  refreshUnassignedJobs?(): void;
  routeId?: number;
  selectedServiceContract?: CustomerLocationServiceContract;
  vehicleTypeId?: number;
}

export interface DispatchBoardJobEditorModalProps extends DispatchBoardJobEditorModalCommonProps {
  loadRouteHasMaterialTypeConfigured: DuckFunction<typeof loadRouteHasMaterialTypeConfigured>;
  loadRouteHasStopsInPendingOptimization: DuckFunction<typeof loadRouteHasStopsInPendingOptimization>;
  resetForceMaterialTypes: DuckAction<typeof resetForceMaterialTypes>;
  saveDispatchBoardRouteJob: DuckFunction<typeof saveDispatchBoardRouteJob>;
  saveDispatchBoardUnassignedJob: DuckFunction<typeof saveDispatchBoardUnassignedJob>;
  submit: any;
}

interface State {
  isDuplicate: boolean;
  duplicateRouteId?: number | null;
}

class DispatchBoardJobEditorModal extends PureComponent<DispatchBoardJobEditorModalProps, State> {
  readonly state: State = {
    isDuplicate: false,
  };

  handleCloseModal = () => {
    const { closeModal, resetForceMaterialTypes } = this.props;
    closeModal();
    resetForceMaterialTypes();
  };

  saveJob = async (job: any) => {
    const {
      jobId,
      loadRouteHasMaterialTypeConfigured,
      loadRouteHasStopsInPendingOptimization,
      newMaterialTypeId,
      newRouteId,
      refreshOnHoldJobs,
      refreshUnassignedJobs,
      routeId: originalRouteId,
      saveDispatchBoardRouteJob,
      saveDispatchBoardUnassignedJob,
      submit,
    } = this.props;

    const { isDuplicate } = this.state;

    const currentRouteId = (isNumber(job.routeId) || isString(job.routeId) ? job.routeId : job.routeId.id).toString();
    const jobRouteId = extractRouteId(currentRouteId);
    const isTemplate = isRouteTemplate(currentRouteId);

    const routeTemplateId = isTemplate ? jobRouteId : null;
    let routeId = !isTemplate ? jobRouteId : null;
    if (job.id && originalRouteId && jobRouteId === UNASSIGNED_ROUTE_ID) {
      routeId = null; // for moving a route job to unassigned, that endpoint doesn't work with -1
    }

    if (!!job.id && !!newMaterialTypeId && !!newRouteId && newRouteId !== UNASSIGNED_ROUTE_ID.toString()) {
      const hasRouteMaterialTypeConfigured = await loadRouteHasMaterialTypeConfigured(
        parseInt(newRouteId?.replace('_template', '')),
        newMaterialTypeId,
        newRouteId?.includes('template'),
      );
      if (!hasRouteMaterialTypeConfigured)
        if (
          !(await confirm(
            translate('routes.alertMessages.materialTypeNotApplicable'),
            '',
            translate('common.cancel'),
            translate('common.ok'),
          ))
        )
          return;
    }

    const hasStopsInPendingOptimization = await loadRouteHasStopsInPendingOptimization(routeId);
    if (hasStopsInPendingOptimization)
      if (
        !(await confirm(
          translate('routes.alertMessages.routeHasStopsInOptimization'),
          '',
          translate('common.cancel'),
          translate('common.continue'),
        ))
      )
        return;

    const isUnassigned =
      (!job.id && !originalRouteId && jobRouteId === UNASSIGNED_ROUTE_ID) ||
      (!job.id && originalRouteId && jobRouteId === UNASSIGNED_ROUTE_ID) ||
      (job.id && !originalRouteId && jobRouteId === UNASSIGNED_ROUTE_ID) ||
      (job.id && !originalRouteId && jobRouteId !== UNASSIGNED_ROUTE_ID);

    const jobData: any = {
      bulkyItemId: job.materialTypeId,
      date: job.date,
      jobPriorityTypeId: job.jobPriorityTypeId,
      pickupTypeId: job.pickupTypeId,
      positionTypeId: job.positionTypeId,
      reasonCodeTypeId: job.reasonCodeTypeId,
      routeId,
      routeNote: job.routeNote,
      routeTemplateId,
      serviceContractId: job.serviceContractId,
      vehicleTypeId: job.vehicleTypeId,
      vendorId: job.vendorId,
      wasteMaterialTypeId: job.wasteMaterialTypeId,
      workOrderNumber: job.workOrderNumber,
    };

    // add pinned address if the location was pinned on map
    if (job.pinAddress) {
      jobData.serviceContractId = 0;
      jobData.pinnedAddress = {
        binLatitude: job.pinAddress.binLatitude || job.pinAddress.latitude,
        binLongitude: job.pinAddress.binLongitude || job.pinAddress.longitude,
        city: job.pinAddress.city,
        country: job.pinAddress.country,
        countryId: job.pinAddress.countryId,
        latitude: job.pinAddress.latitude,
        line1: job.pinAddress.line1 || job.pinAddress.formattedAddress,
        line2: job.pinAddress.line2,
        longitude: job.pinAddress.longitude,
        state: job.pinAddress.state,
        stateId: job.pinAddress.stateId,
        street: job.pinAddress.street,
        streetNumber: job.pinAddress.streetNumber,
        zip: job.pinAddress.zip,
      };
    }

    if ((!isDuplicate && jobRouteId !== originalRouteId) || (!jobId && !isDuplicate)) {
      const duplicateJobs = await checkDuplicateJobs({
        jobs: [{ id: job.id, serviceContractId: job.serviceContractId, isAssigned: !isUnassigned }],
        routeId: routeId === UNASSIGNED_ROUTE_ID ? null : routeId,
        routeTemplateId,
      });

      if (duplicateJobs.length) {
        this.setState({
          duplicateRouteId: routeId === UNASSIGNED_ROUTE_ID || routeId === null ? routeTemplateId : routeId,
          isDuplicate: true,
        });
        return;
      }
    }

    if (jobData.workOrderNumber) {
      const isWorkOrderExist = isUnassigned
        ? await checkDuplicateWorkOrderNoForUnassignedJob(jobData.vendorId, jobData.workOrderNumber, job.id)
        : await checkDuplicateWorkOrderNoForRouteJob(jobData.vendorId, jobData.workOrderNumber, job.id);

      if (isWorkOrderExist) {
        createErrorNotification(`${translate('routes.alertMessages.workOrderAlreadyExist')}`);
        return;
      }
    }

    const savePromise = isUnassigned
      ? saveDispatchBoardUnassignedJob(job.id, jobData)
      : saveDispatchBoardRouteJob(job.id, jobData);

    savePromise
      .then(() => {
        isUnassigned
          ? createSuccessNotification(`${translate('routes.alertMessages.unassignedJobSaved')}`)
          : createSuccessNotification(`${translate('routes.alertMessages.routeJobSaved')}`);

        if (refreshUnassignedJobs) {
          refreshUnassignedJobs();
        }

        if (refreshOnHoldJobs) {
          refreshOnHoldJobs();
        }

        submit(DISPATCH_BOARD_SOURCE_FORM_NAME);
        submit(DISPATCH_BOARD_TARGET_FORM_NAME);
        this.handleCloseModal();
      })
      .catch(() => {
        isUnassigned
          ? createErrorNotification(`${translate('routes.alertMessages.routeJobSaveFailed')}`)
          : createErrorNotification(`${translate('routes.alertMessages.unassignedJobSaveFailed')}`);
      });
  };

  render() {
    const {
      allowRoutesFromPast,
      customerId,
      date,
      isMaterialTypesEnabled,
      jobId,
      routeId,
      vehicleTypeId,
      selectedServiceContract,
    } = this.props;
    const { duplicateRouteId, isDuplicate } = this.state;

    return (
      <Modal
        onClose={this.handleCloseModal}
        padding="medium"
        title={translate(`routes.${jobId ? 'edit' : 'add'}Job`)}
        verticalSize="medium"
      >
        <DispatchBoardJobEditorForm
          allowRoutesFromPast={allowRoutesFromPast}
          customerId={customerId}
          date={date}
          duplicateRouteId={duplicateRouteId}
          isDuplicate={isDuplicate}
          isMaterialTypesEnabled={isMaterialTypesEnabled}
          jobId={jobId}
          onSubmit={this.saveJob}
          routeId={routeId}
          vehicleTypeId={vehicleTypeId}
          selectedServiceContract={selectedServiceContract}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = (getFormValues as any)('dispatchBoardJobEditorForm')(state);

  return {
    newRouteId: formValues?.routeId,
    newMaterialTypeId: formValues?.materialTypeId,
  };
};

const mapDispatchToProps = {
  loadRouteHasMaterialTypeConfigured,
  loadRouteHasStopsInPendingOptimization,
  resetForceMaterialTypes,
  saveDispatchBoardRouteJob,
  saveDispatchBoardUnassignedJob,
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchBoardJobEditorModal);
