import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import { RestrictedRoute } from '../../account/components';
import {
  BetaReportsPageResolver,
  CommunityInsightsPageResolver,
  DisposalSummaryPageResolver,
  FaultCodeDetailsPageResolver,
  InsightDashboardPreferencesPageResolver,
  InsightsFleetPageResolver,
  InsightsWasteAuditPageResolver,
  ReportingDataTrendsPageResolver,
  ReportingDetailsPageResolver,
  ReportsPageResolver,
} from './pages';
import { INSIGHTS_SEE_DETAILS, INSIGHTS_PREFERENCES } from '../../account/constants';
import { isReportsNewLayoutActive, setReportsNewLayoutActive } from '../services/reportsPageLayout';

const Insights: React.FC = () => {
  // TODO - to be removed when old reports page is obsolete
  const currentUrl = window.location.pathname;
  const reportsNewLayoutActive = isReportsNewLayoutActive();

  if (currentUrl.includes('/insights/reports') && reportsNewLayoutActive) {
    return <Redirect to="/insights/beta-reports" />;
  }

  if (currentUrl.includes('/insights/beta-reports') && !reportsNewLayoutActive) setReportsNewLayoutActive(true);

  return (
    <Switch>
      <Route exact path="/insights/insightsfleet" component={InsightsFleetPageResolver} />
      <Route exact path="/insights/wasteaudit" component={InsightsWasteAuditPageResolver} />

      <RestrictedRoute
        permission={INSIGHTS_PREFERENCES}
        exact
        path="/insights/settings"
        component={InsightDashboardPreferencesPageResolver}
      />
      <RestrictedRoute
        permission={INSIGHTS_SEE_DETAILS}
        exact
        path="/insights/reporting-details"
        component={ReportingDetailsPageResolver}
      />
      <RestrictedRoute
        permission={INSIGHTS_SEE_DETAILS}
        exact
        path="/insights/fault-code-details"
        component={FaultCodeDetailsPageResolver}
      />
      <RestrictedRoute
        permission={INSIGHTS_SEE_DETAILS}
        exact
        path="/insights/disposal-summary"
        component={DisposalSummaryPageResolver}
      />

      <Route exact path="/insights/reporting-data-trends" component={ReportingDataTrendsPageResolver} />
    
      <Route exact path="/insights/community-insights" component={CommunityInsightsPageResolver} />      

      <Route
        exact
        path={reportsNewLayoutActive ? '/insights/beta-reports' : '/insights/reports'}
        component={reportsNewLayoutActive ? BetaReportsPageResolver : ReportsPageResolver}
      />
      <Redirect from="/insights/route-summary" to="/routes/route-tracker" />
      <Redirect to="/insights/insightsfleet" />
    </Switch>
  );
};

export default Insights;
