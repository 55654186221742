import styled, { css } from 'styled-components';
import { sizeMapper } from '../../../utils/styles';
import { transparentize } from 'polished';

interface Props {
  isActive?: boolean;
  isDisabled?: boolean;
  margin?: string;
  isHighlighted?: boolean;
}

export const SwitchBar = styled.div<Props>`
  position: relative;
  height: 36px;
  background-color: #fff;
  border-radius: 16px;
  cursor: pointer;
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.brandPrimary};
  box-sizing: border-box;
`;

export const SwitchHandle = styled.div<Props>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 120px;
  height: 30px;
  background: ${props => props.theme.brandPrimary};
  border-radius: 14px;
  box-shadow: 0 2px 4px ${props => transparentize(0.9, props.theme.grayBase)};
  transform: none;
  transition: transform 0.1s;
`;

export const SwitchText = styled.span<Props>`
  line-height: 18px;
  font-size: 14px;
  text-transform: capitalize;
  user-select: none;
  position: relative;
  width: 49%;
  text-align: center;
  z-index: 2;

  ${props =>
    props.isHighlighted &&
    css`
      color: #fff;
    `}
`;

export const Switch = styled.div<Props>`
  display: flex;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.isActive &&
    css`
      ${SwitchHandle} {
        transform: translateX(114px);
      }
    `};

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      ${SwitchBar} {
        pointer-events: none;
      }
    `};
`;
