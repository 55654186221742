import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { Button, ButtonSet, Text } from 'src/core/components/styled';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { deleteLoadDumped, loadLoadsDumped, loadRouteDetails, loadSnowSweeperRouteDetails } from 'src/routes/ducks';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { Table } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import LoadDumpedEditorModalResolver from '../LoadDumpedEditorModalResolver';
import LoadsDumpedTableRow from './LoadsDumpedTableRow';
import translate from 'src/core/services/translate';

export const getLoadsDumpedTableCellWidths = () => ['28%', '16%', '11%', '14%', '11%', '8%', '12%'];

const getLoadsDumpedTableCells = () => {
  const loadsDumpedTableCellWidths = getLoadsDumpedTableCellWidths();

  const loadsDumpedTableCells = [
    {
      name: 'location',
      label: translate('common.materialTickets.location'),
      width: loadsDumpedTableCellWidths[0],
    },
    {
      name: 'material',
      label: translate('common.material'),
      width: loadsDumpedTableCellWidths[1],
    },
    {
      name: 'quantity',
      label: translate('common.materialTickets.quantity'),
      width: loadsDumpedTableCellWidths[2],
    },
    {
      name: 'uom',
      label: translate('common.materialTickets.uom'),
      width: loadsDumpedTableCellWidths[3],
    },
    {
      name: 'timestamp',
      label: translate('common.materialTickets.materialTicketDate'),
      width: loadsDumpedTableCellWidths[4],
    },
    {
      name: 'images',
      label: translate('common.image'),
      width: loadsDumpedTableCellWidths[5],
    },
    {
      name: 'options',
      label: translate('common.options'),
      width: loadsDumpedTableCellWidths[6],
      align: 'right',
    },
  ];

  return loadsDumpedTableCells;
};

export default function LoadsDumpedSection() {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { loadsDumped } = useSelector(state => state.routes.loadsDumped) || [];
  const vendorId = useSelector(currentVendorId);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const isSnowPlowRoute = routeSummary?.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary?.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const [isLoadDumpedEditorModalOpen, setIsLoadDumpedEditorModalOpen] = useState(false);
  const [selectedLoadDumped, setSelectedLoadDumped] = useState<number>();

  if (!routeSummary) return null;

  const openLoadDumpedEditorModalOpen = (loadDumpedId?: number) => {
    if (loadDumpedId) setSelectedLoadDumped(loadDumpedId);
    setIsLoadDumpedEditorModalOpen(true);
  };

  const closeLoadDumpedEditorModal = () => {
    setIsLoadDumpedEditorModalOpen(false);
    setSelectedLoadDumped(undefined);
  };

  const handleDeleteLoadDumped = async (loadDumpedId: number) => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteLoadDumped')))) {
      return;
    }

    deleteLoadDumped(loadDumpedId)(dispatch)
      .then(() => {
        loadLoadsDumped(vendorId, routeSummary.routeId)(dispatch);

        const noLoading = true;
        isSnowOrSweeperRoute
          ? loadSnowSweeperRouteDetails(
              vendorId,
              routeSummary.routeId,
              segmentsFilterStartDate,
              segmentsFilterEndDate,
              noLoading,
            )(dispatch)
          : loadRouteDetails(vendorId, routeSummary.routeId, noLoading)(dispatch);

        createSuccessNotification(translate('routes.alertMessages.loadDumpedDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.loadDumpedDeleteError'));
      });
  };

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  return (
    <>
      {loadsDumped && loadsDumped.length ? (
        <Table
          cells={getLoadsDumpedTableCells()}
          rows={loadsDumped}
          rowComponent={LoadsDumpedTableRow}
          rowProps={{
            editLoadDumped: openLoadDumpedEditorModalOpen,
            deleteLoadDumped: handleDeleteLoadDumped,
          }}
        />
      ) : (
        <Text block align="center" margin="medium no">
          {translate('common.loadsDumped.noLoadsDumped')}
        </Text>
      )}
      {!isSupport && !isViewOnly && (
        <ButtonSet margin="sMedium no">
          <Button type="button" color="primary" line onClick={() => openLoadDumpedEditorModalOpen(undefined)}>
            + {translate('common.loadsDumped.addLoadsDumped')}
          </Button>
        </ButtonSet>
      )}

      {isLoadDumpedEditorModalOpen && (
        <LoadDumpedEditorModalResolver
          routeId={routeSummary.routeId}
          loadDumpedId={selectedLoadDumped}
          onClose={closeLoadDumpedEditorModal}
        />
      )}
    </>
  );
}
