import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { difference, includes, size } from 'lodash-es';

import { AppState } from '../../../store';
import { AccordionTable, Modal } from '../../../core/components';
import { AiModelModalTableRow, AiModelModalTableRowHeaderRow } from './';
import { Button, ButtonSet, Grid, GridColumn, Message, TextArea } from '../../../core/components/styled';
import {
  commandsConfigurationSelector,
  loadAiModelConfigurations,
  modelConfigurationSelector,
  resetAiModelConfigurations,
  saveAiModelConfigurations,
} from '../../ducks';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModelFeature, ModelFeatures } from '../../interfaces/ModelFeature';
import { VehicleTypeForm } from '../forms';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  commands?: string;
  isLoading: boolean;
  loadAiModelConfigurations: DuckFunction<typeof loadAiModelConfigurations>;
  modelConfigurations?: ModelFeatures[];
  resetAiModelConfigurations: DuckAction<typeof resetAiModelConfigurations>;
  saveAiModelConfigurations: DuckFunction<typeof saveAiModelConfigurations>;
  vehicles: any;
}

interface State {
  modelConfigurations?: ModelFeatures[];
  initialValuesLoaded: boolean;
  additionalParameters?: string;
  disabledModelFeatures: string[];
}

class AiModelConfigurationModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modelConfigurations: undefined,
      initialValuesLoaded: false,
      additionalParameters: '',
      disabledModelFeatures: [],
    };
  }

  componentDidUpdate() {
    const { modelConfigurations } = this.props;
    if (!!size(modelConfigurations) && !this.state.initialValuesLoaded) {
      this.disableChildrenRows(modelConfigurations);
      this.setState({
        initialValuesLoaded: true,
        modelConfigurations,
        additionalParameters: this.props.commands || '',
      });
    }
  }

  componentWillUnmount() {
    this.props.resetAiModelConfigurations();
  }

  onChangeVehicleTypeId = () => {
    this.setState({ initialValuesLoaded: false, modelConfigurations: undefined });
  };

  onConfigurationVersionChange = (id: number) => (versionNumber: string) => {
    if (versionNumber.length >= 100) return;
    const { modelConfigurations } = this.state;
    const newModelConfigs = [...(modelConfigurations as ModelFeatures[])];
    const newConfig = newModelConfigs.map(model => {
      const featureArray: any[] = [];
      model.modelFeatures.map((feature: ModelFeature) => {
        if (feature.modelFeature && feature.modelFeature.id === id) {
          const newFeature = { ...feature, versionNumber };
          return featureArray.push(newFeature);
        }
        return featureArray.push(feature);
      });
      const newModel = { ...model, modelFeatures: featureArray };
      return newModel;
    });
    this.setState({ modelConfigurations: newConfig });
  };

  onTextAreaChange = ({ target: { value } }: any) => {
    this.setState({ additionalParameters: value });
  };

  disableChildrenRows = (modelConfigurations?: ModelFeatures[]) => {
    if (!modelConfigurations) return;
    const disabledArray: string[] = [];
    modelConfigurations.forEach(modelFeature => {
      if (modelFeature.isEnabled === false) {
        modelFeature.modelFeatures.forEach((feature: ModelFeature) => {
          disabledArray.push(feature.modelFeature.technicalName);
        });
      }
    });
    this.setState({ disabledModelFeatures: disabledArray });
  };

  disableRowCheck = (rowName: string) => {
    return includes(this.state.disabledModelFeatures, rowName);
  };

  toggleAIModelHeaderSwitch = (id: number) => (value: boolean) => {
    const { modelConfigurations } = this.state;
    const newModelConfigs = [...(modelConfigurations as any[])];
    const newConfig = newModelConfigs.map(val => {
      if (val.model.id === id) {
        const newVal = { ...val, isEnabled: value };
        return newVal;
      }
      return val;
    });
    this.setState({ modelConfigurations: newConfig }, () => {
      this.disableChildrenRows(newConfig);
    });
  };

  toggleAIModelTableRowSwitch = (id: number) => (value: boolean) => {
    const { modelConfigurations } = this.state;
    const newModelConfigs = [...(modelConfigurations as any[])];
    const newConfig = newModelConfigs.map(model => {
      const featureArray: any[] = [];
      model.modelFeatures.map((feature: ModelFeature) => {
        if (feature.modelFeature && feature.modelFeature.id === id) {
          const newFeature = { ...feature, isEnabled: value };
          return featureArray.push(newFeature);
        }
        return featureArray.push(feature);
      });
      const newModel = { ...model, modelFeatures: featureArray };
      return newModel;
    });
    this.setState({ modelConfigurations: newConfig });
  };

  saveAiModelConfigurations = () => {
    const { saveAiModelConfigurations, closeModal } = this.props;
    const { modelConfigurations, additionalParameters } = this.state;
    saveAiModelConfigurations(modelConfigurations, additionalParameters)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.aiModelConfigurationSaved'));
        closeModal(true);
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.saveAiModelConfigurationError')));
  };

  closeModal = () => {
    const { modelConfigurations, closeModal } = this.props;
    closeModal(!size(difference(modelConfigurations, this.state.modelConfigurations || [])));
  };

  render() {
    const { featureCode, isLoading, loadAiModelConfigurations, vehicles } = this.props;
    const { modelConfigurations, additionalParameters } = this.state;

    const aiModelConfigurationTableCells = [
      { name: 'featureName', label: translate('vendors.modelFeatures'), width: '60%' },
      { name: 'enabled', label: translate('common.active'), width: '20%', align: 'right' },
      { name: 'versionNumber', label: translate('common.version'), width: '20%', align: 'right' },
    ];

    return (
      <Modal
        title={translate(createTranslationKey(featureCode || '', 'vendors.featureCodes'))}
        onClose={this.closeModal}
        verticalSize="small"
        isLoading={isLoading}
      >
        <VehicleTypeForm
          loadModelConfigurations={loadAiModelConfigurations}
          onChangeVehicleTypeId={this.onChangeVehicleTypeId}
          vehicles={vehicles}
        />
        {modelConfigurations && !!size(modelConfigurations) && (
          <AccordionTable
            cells={aiModelConfigurationTableCells}
            rows={modelConfigurations}
            rowComponent={AiModelModalTableRow}
            headerComponent={AiModelModalTableRowHeaderRow}
            withTopBorder={false}
            rowProps={{
              toggleAIModelTableRowSwitch: this.toggleAIModelTableRowSwitch,
              onConfigurationVersionChange: this.onConfigurationVersionChange,
              toggleAIModelHeaderSwitch: this.toggleAIModelHeaderSwitch,
              disableRowCheck: this.disableRowCheck,
            }}
          />
        )}
        {modelConfigurations && !size(modelConfigurations) && (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}
        <Grid style={{ marginTop: '15px' }} centered>
          <GridColumn size="10/12">
            {modelConfigurations && !!size(modelConfigurations) && (
              <TextArea
                style={{ resize: 'none' }}
                cols={3}
                placeholder={translate('common.additionalParameters')}
                onChange={this.onTextAreaChange}
                value={additionalParameters}
              />
            )}
          </GridColumn>
        </Grid>
        {modelConfigurations && (
          <Grid centered>
            <GridColumn size="6/12">
              <ButtonSet margin="medium no">
                <Button color="primary" onClick={this.saveAiModelConfigurations}>
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  commands: commandsConfigurationSelector(state),
  isLoading: state.vendors.aiModelConfigurations.isLoading,
  modelConfigurations: modelConfigurationSelector(state),
  vehicles: state.fleet.vehicleTypesForVendor.vehicleTypesForVendor,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadAiModelConfigurations,
  resetAiModelConfigurations,
  saveAiModelConfigurations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AiModelConfigurationModal);
