import { filter, find, map, toLower } from 'lodash-es';
import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { formatNumber } from 'src/utils/services/formatter';

export const getChartData = (statistic?: any) => {
  if (!statistic) {
    return null;
  }
  const statisticArray = map(Object.keys(statistic), function (key) {
    return { key: key, value: statistic[key] };
  });
  const filteredStatisticArray = filter(statisticArray, el => el.key !== 'total');
  const total = `$${formatNumber(find(statisticArray, el => el.key === 'total')?.value)}` || '-';

  const data: any[] = [];

  map(filteredStatisticArray, (el: any) =>
    data.push({
      label: translate(createTranslationKey(toLower(el.key), 'finance.applyChecks.aging')),
      value: el.value,
      rawValue: `$${formatNumber(el.value)}`,
    }),
  );
  return {
    labels: data.map(item => item.label),
    data: data.map(item => item.value),
    rawDataValues: data.map(item => item.rawValue),
    total: total,
  };
};
