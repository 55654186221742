import React, { ChangeEvent, useEffect, useState, MouseEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { find, size } from 'lodash-es';
import { Resizable } from 're-resizable';

import { PermissionGuard } from 'src/account/components';
import { FLEET_CONTAINERS_EXPORT } from 'src/account/constants';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageFooter,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { MapDragHandle, MoreButton, Table, UnconnectedCheckbox } from 'src/core/components';
import {
  Button,
  Grid,
  GridColumn,
  IconButton,
  IconButtonIcon,
  MapContainer,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Text,
} from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import {
  deleteContainersBatched,
  exportContainers,
  loadContainerFacilities,
  loadContainerLocationsForMap,
  loadContainersStatistics,
  resetContainerFacilities,
  resetContainerLocationsForMap,
  resetContainersStatistics,
  saveContainer,
  transferContainersBatched,
} from 'src/fleet/ducks';
import { loadContainersList, resetContainersList } from 'src/fleet/ducks';
import { AppState } from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { TopToggleButtonsContainer, TopToggleIconButton } from '../styled/Containers';
import { MoreButtonItem } from 'src/core/components/MoreButton';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { ContainerToSave, Container } from 'src/fleet/interfaces/containers';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { ContainerNewSimplified } from 'src/fleet/interfaces/containers';
import { ContainerActivityModalResolver, TransferContainersModalResolver } from '../modal';
import { TransferContainersFormValues } from '../forms/TransferContainersForm';
import ContainersMapGL from './containersPageSections/containersMap/ContainersMapGL';
import ContainerImportModal from '../modal/ContainerImportModal';
import CreateEditContainerModalResolver from '../modal/CreateEditContainerModalResolver';
import ContainerStatisticsSection from './containersPageSections/ContainersStatisticsSection';
import NewContainersPageTableRow from './containersPageSections/NewContainerPageTableRow';
import ContainersFormNew from '../forms/ContainersFormNew';
import translate from 'src/core/services/translate';
import confirm from 'src/core/services/confirm';
import { setIsDrawingMode } from 'src/routes/ducks/mapControls';

interface QueryParams {
  searchTerm?: string;
  containerTypeId?: number;
  locationId?: number;
  equipmentTypeId?: number;
  equipmentSizeId?: number;
  equipmentConditionId?: number;
}
const TABS = {
  list: 1,
  map: 2,
};

const NewContainersPage: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const containersList = useSelector((state: AppState) => state.fleet.containersList.containersList || []);
  const containerLocationsForMap = useSelector(
    (state: AppState) => state.fleet.containerLocationsForMap.containerLocationsForMap || [],
  );
  const containersForMapLoading = useSelector((state: AppState) => state.fleet.containerLocationsForMap.isLoading);
  const containersListLoading = useSelector((state: AppState) => state.fleet.containersList.isLoading);
  const containerFacilities = useSelector(
    (state: AppState) => state.fleet.containerFacilities.containerFacilities || [],
  );
  const equipmentConditions = useSelector(
    (state: AppState) => state.common.equipmentConditions.equipmentConditions || [],
  );
  const vendorId = useSelector(currentVendorId);
  const isLoadingData = containersListLoading || containersForMapLoading;

  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [selectedContainers, setSelectedContainers] = useState<number[]>([]);
  const [containerId, setContainerId] = useState<number | undefined>(undefined);
  const [isCreateEditContainerModalOpen, setIsCreateEditContainerModalOpen] = useState<boolean>(false);
  const [isTransferContainersModalOpen, setIsTransferContainersModalOpen] = useState<boolean>(false);
  const [isImportContainerModalOpen, setIsImportContainerModalOpen] = useState<boolean>(false);
  const [isViewContainerActivityModalOpen, setIsViewContainerActivityModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { searchTerm, containerTypeId, equipmentTypeId, equipmentSizeId, equipmentConditionId, locationId } =
    getQueryParams<QueryParams>(search);

  const containerListItems = containersList.map((container: ContainerNewSimplified) => ({
    ...container,
    isSelected: selectedContainers.includes(container.id),
  }));

  const memoizedFacilities = useMemo(() => containerFacilities, [containerFacilities]);

  // cleanup on unmount
  useEffect(
    () => () => {
      dispatch(resetContainersStatistics());
      dispatch(resetContainersList());
      dispatch(resetContainerLocationsForMap());
      dispatch(resetContainerFacilities());
      dispatch(clearRouteMapSelectedFeature());
    },
    [dispatch],
  );

  // refetch statistics data
  useEffect(() => {
    if (!vendorId || !containerTypeId || activeTab === TABS.map) {
      return;
    }
    loadContainersStatistics(
      vendorId,
      containerTypeId,
      equipmentTypeId,
      equipmentTypeId ? equipmentSizeId : undefined,
      equipmentConditionId,
      searchTerm,
      locationId,
    )(dispatch);
  }, [
    dispatch,
    activeTab,
    vendorId,
    containerTypeId,
    locationId,
    equipmentTypeId,
    equipmentSizeId,
    searchTerm,
    equipmentConditionId,
  ]);

  // load containers in the list and map
  useEffect(() => {
    if (!vendorId || !containerTypeId) {
      dispatch(resetContainersList());
      dispatch(resetContainerLocationsForMap());
      return;
    }
    setSelectedContainers([]);
    if (activeTab === TABS.list) {
      loadContainersList(
        vendorId,
        containerTypeId,
        equipmentTypeId,
        equipmentTypeId ? equipmentSizeId : undefined,
        equipmentConditionId,
        searchTerm,
        locationId,
      )(dispatch);
    }
    if (activeTab === TABS.map) {
      loadContainerLocationsForMap(
        vendorId,
        containerTypeId,
        equipmentTypeId,
        equipmentTypeId ? equipmentSizeId : undefined,
        equipmentConditionId,
        searchTerm,
        locationId,
      )(dispatch);
      loadContainerFacilities(vendorId)(dispatch);
    }
  }, [
    dispatch,
    vendorId,
    containerTypeId,
    locationId,
    equipmentTypeId,
    equipmentSizeId,
    searchTerm,
    equipmentConditionId,
    activeTab,
  ]);

  const refreshAllData = () => {
    if (!vendorId || !containerTypeId) {
      return;
    }
    loadContainersStatistics(
      vendorId,
      containerTypeId,
      equipmentTypeId,
      equipmentTypeId ? equipmentSizeId : undefined,
      equipmentConditionId,
      searchTerm,
      locationId,
    )(dispatch);
    if (activeTab === TABS.list) {
      loadContainersList(
        vendorId,
        containerTypeId,
        equipmentTypeId,
        equipmentTypeId ? equipmentSizeId : undefined,
        equipmentConditionId,
        searchTerm,
        locationId,
      )(dispatch);
    }
    if (activeTab === TABS.map) {
      loadContainerLocationsForMap(
        vendorId,
        containerTypeId,
        equipmentTypeId,
        equipmentTypeId ? equipmentSizeId : undefined,
        equipmentConditionId,
        searchTerm,
        locationId,
      )(dispatch);
      loadContainerFacilities(vendorId)(dispatch);
    }
  };

  const handleTabChange = (tab: number) => {
    setActiveTab(tab);
    setSelectedContainers([]);
    dispatch(clearRouteMapSelectedFeature());
  };

  const virtualizedProps = {
    height: Math.min(containersList?.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const showContainerImportModal = (visible: boolean = true) => {
    setIsImportContainerModalOpen(visible);
  };

  const showCreateContainerModal = (visible: boolean = true) => {
    setIsCreateEditContainerModalOpen(visible);
  };

  const handleExportContainers = (exportAll?: boolean) => {
    exportAll
      ? exportContainers(vendorId)(dispatch)
      : exportContainers(
          vendorId,
          searchTerm,
          containerTypeId,
          locationId,
          equipmentTypeId,
          equipmentSizeId,
          equipmentConditionId,
        )(dispatch);
  };

  const handleEditContainer = (containerId: number) => {
    setContainerId(containerId);
    setIsCreateEditContainerModalOpen(true);
  };

  const handleViewContainerActivity = (containerId: number) => {
    setContainerId(containerId);
    setIsViewContainerActivityModalOpen(true);
  };

  const handleDeleteContainer = async (containerId: number) => {
    if (!(await confirm(translate('containers.alertMessages.confirmDeleteContainer')))) return;
    deleteContainersBatched(vendorId, [containerId])(dispatch)
      .then(() => {
        createSuccessNotification(translate('containers.alertMessages.containerDeleted'));
        setSelectedContainers([]);
        refreshAllData();
      })
      .catch(() => {
        createErrorNotification(translate('containers.alertMessages.containerDeletedFailed'));
      });
  };

  const handleSubmitCreateEditContainer = (values: Container | ContainerToSave) => {
    saveContainer({
      ...values,
      ...(!values.id
        ? { equipmentCondition: find(equipmentConditions, { id: values.equipmentConditionId }).name }
        : {}),
      ...(typeof values.locationId === 'object'
        ? { locationId: values.locationId.locationId, serviceContractId: values.locationId.serviceContractId }
        : {}),
    })(dispatch)
      .then(() => {
        createSuccessNotification(translate('containers.alertMessages.containerSaved'));
        setIsCreateEditContainerModalOpen(false);
        setSelectedContainers([]);
        refreshAllData();
      })
      .catch(() => {
        createErrorNotification(translate('containers.alertMessages.containerAlreadyExists'));
      });
  };

  const handleSelectContainer = (containerId: number) => {
    if (selectedContainers.includes(containerId)) {
      setSelectedContainers(selectedContainers.filter(id => id !== containerId));
    } else {
      setSelectedContainers([...selectedContainers, containerId]);
    }
  };

  const handleCheckAllContainers = () => {
    if (selectedContainers.length === containersList.length) {
      setSelectedContainers([]);
    } else {
      setSelectedContainers(containersList.map(container => container.id));
    }
  };

  const handleDeleteContainers = async () => {
    if (!(await confirm(translate('containers.alertMessages.confirmDeleteContainers')))) return;
    deleteContainersBatched(
      vendorId,
      selectedContainers,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('containers.alertMessages.containersDeletedSuccessfully'));
        setSelectedContainers([]);
        refreshAllData();
      })
      .catch(() => {
        createErrorNotification(translate('containers.alertMessages.containersDeletedFailed'));
      });
  };

  const handleOpenTransferContainersModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsTransferContainersModalOpen(true);
  };

  const transferContainers = async (formValues: TransferContainersFormValues) => {
    transferContainersBatched(
      vendorId,
      formValues.locationId,
      selectedContainers,
    )(dispatch)
      .then(() => {
        createSuccessNotification(
          translate('containers.alertMessages.containersTransferredSuccessfully', {
            total: selectedContainers.length,
            count: selectedContainers.length,
          }),
        );
        setIsTransferContainersModalOpen(false);
        setSelectedContainers([]);
        dispatch(setIsDrawingMode(false));
        refreshAllData();
      })
      .catch(() => {
        createErrorNotification(translate('containers.alertMessages.containersTransferredFailed'));
        setSelectedContainers([]);
      });
  };

  const checkCell =
    !checkIfViewOnly() && !checkIfSupport()
      ? {
          name: 'selectAll',
          component: UnconnectedCheckbox,
          componentProps: {
            onChange: handleCheckAllContainers,
            checked: selectedContainers.length === containersList.length,
            partial: 0 < selectedContainers.length && selectedContainers.length < containersList.length,
          },
          width: '3%',
          padding: 'defaultCellVertical xSmall',
          noPaddingRight: true,
          onClick: (e: ChangeEvent<HTMLInputElement>) => e.stopPropagation(),
        }
      : {
          name: 'selectAll',
          width: '3%',
          padding: 'defaultCellVertical xSmall',
          noPaddingRight: true,
        };

  const containersTableCells = [
    checkCell,
    {
      name: 'nr',
      label: translate('containers.container'),
      width: '8%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'lt',
      label: translate('common.locationType'),
      width: '12%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'a',
      label: translate('common.location'),
      width: '24.5%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 't',
      label: translate('common.type'),
      width: '10%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 's',
      label: translate('common.size'),
      width: '11.6%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'c',
      label: translate('containers.condition'),
      width: '8%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'st',
      label: translate('common.status'),
      width: '11.8%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
      align: 'left',
    },
    {
      name: 'actions',
      label: translate('common.options'),
      width: '10%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: false,
      align: 'right',
    },
  ];

  const moreButtonItems: MoreButtonItem[] = [
    {
      id: 'create-container-button',
      text: translate('containers.addSingleContainer'),
      handler: () => {
        setContainerId(undefined);
        showCreateContainerModal();
      },
    },
    {
      text: translate('containers.importMultipleContainers'),
      handler: () => showContainerImportModal(),
    },
  ];

  const moreExportButtonItems: MoreButtonItem[] = [
    {
      id: 'export-page-containers-button',
      text: translate('common.exportPage'),
      handler: () => handleExportContainers(),
    },
    {
      id: 'export-all-containers-button',
      text: translate('common.exportAllContainers'),
      handler: () => handleExportContainers(true),
    },
  ];

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer fluid>
            <Grid align="center">
              <GridColumn>
                <PageTitle margin="small">{translate('containers.containers')}</PageTitle>
              </GridColumn>
              <GridColumn>
                <TopToggleButtonsContainer>
                  <TopToggleIconButton hasBorderRight isActive={activeTab === TABS.map}>
                    <IconButton
                      size="small"
                      margin="no"
                      color="secondary"
                      borderRadius="0"
                      onClick={() => handleTabChange(TABS.map)}
                    >
                      <IconButtonIcon icon="map" size="xLarge" margin="no xSmall no " />
                      <Text margin="no no no xSmall" weight="medium">
                        {translate('common.map')}
                      </Text>
                    </IconButton>
                  </TopToggleIconButton>
                  <TopToggleIconButton isActive={activeTab === TABS.list}>
                    <IconButton
                      size="small"
                      margin="no"
                      color="secondary"
                      borderRadius="0"
                      onClick={() => handleTabChange(TABS.list)}
                    >
                      <IconButtonIcon icon="listView" size="xLarge" margin="no xSmall no " />
                      <Text margin="no no no xSmall" weight="medium">
                        {translate('common.list')}
                      </Text>
                    </IconButton>
                  </TopToggleIconButton>
                </TopToggleButtonsContainer>
              </GridColumn>
            </Grid>
          </PageTitleContainer>
        </PageDetails>
        <PageActions flex>
          <PermissionGuard permission={FLEET_CONTAINERS_EXPORT}>
            <MoreButton
              margin="no small no no"
              buttonText={translate('containers.export')}
              items={moreExportButtonItems}
            />
          </PermissionGuard>
          <PermissionGuard permission={FLEET_CONTAINERS_EXPORT}>
            {!isViewOnly && !isSupport && (
              <MoreButton
                margin="no small no no"
                buttonText={translate('containers.addContainers')}
                items={moreButtonItems}
              />
            )}
          </PermissionGuard>
        </PageActions>
      </PageHeader>
      <ContainersFormNew />
      <Panel margin="no no xLarge">
        <PanelSectionGroup>
          {activeTab !== TABS.map && <ContainerStatisticsSection />}
          <PanelSection isLoading={isLoadingData} minHeight={isLoadingData ? 100 : 0}>
            {!!size(containerListItems) && activeTab === TABS.list && (
              <Table
                cells={containersTableCells}
                noOverflow
                rowComponent={NewContainersPageTableRow}
                rowProps={{
                  totalRows: size(containerListItems),
                  rowHeight: TABLE_ROW_HEIGHT_LARGE,
                  editContainer: handleEditContainer,
                  selectContainer: handleSelectContainer,
                  deleteContainer: handleDeleteContainer,
                  viewContainerActivity: handleViewContainerActivity,
                }}
                rows={containerListItems}
                scrollMarker
                virtualized
                virtualizedProps={virtualizedProps}
                withClickableRows
              />
            )}
            {activeTab === TABS.map && (
              <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                <MapContainer size="large">
                  <ContainersMapGL
                    containerLocations={containerLocationsForMap}
                    facilities={memoizedFacilities}
                    setSelectedContainers={setSelectedContainers}
                    selectedContainers={selectedContainers}
                  />
                </MapContainer>
              </Resizable>
            )}
          </PanelSection>
          {!isViewOnly && !isSupport && !!selectedContainers.length && (
            <PanelSection centered padding="no">
              <PageFooter>
                {!!selectedContainers.length && (
                  <Button
                    id="delete-route-button"
                    color="alert"
                    line
                    onClick={handleDeleteContainers}
                    margin="no small no no"
                  >
                    {translate('containers.deleteXContainers', { count: selectedContainers.length })}
                  </Button>
                )}
                {!!selectedContainers.length && (
                  <Button
                    id="reschedule-route-button"
                    color="primary"
                    onClick={handleOpenTransferContainersModal}
                    margin="no small no no"
                  >
                    {translate('containers.transferXContainers', { count: selectedContainers.length })}
                  </Button>
                )}
              </PageFooter>
            </PanelSection>
          )}
        </PanelSectionGroup>
      </Panel>
      {isImportContainerModalOpen && (
        <ContainerImportModal vendorId={vendorId} onClose={() => setIsImportContainerModalOpen(false)} />
      )}
      {isCreateEditContainerModalOpen && (
        <CreateEditContainerModalResolver
          containerId={containerId}
          closeModal={() => setIsCreateEditContainerModalOpen(false)}
          onSubmit={handleSubmitCreateEditContainer}
        />
      )}
      {isTransferContainersModalOpen && (
        <TransferContainersModalResolver
          closeModal={() => setIsTransferContainersModalOpen(false)}
          onSubmit={transferContainers}
        />
      )}
      {isViewContainerActivityModalOpen && (
        <ContainerActivityModalResolver
          containerId={containerId}
          containerTypeId={Number(containerTypeId)}
          closeModal={() => setIsViewContainerActivityModalOpen(false)}
        />
      )}
    </PageContent>
  );
};

export default withRouter(NewContainersPage);
