import { http } from '../../core/services/http';
import { transformSuspendSearchCustomerLocations } from './transformSuspendSearchCustomerLocations';

const suspendSearchCustomerLocations = (vendorId: number, nameContains: string) =>
  http
    .get('/customers/locations/toSuspend', {
      params: { vendorId, nameContains },
    })
    .then(response => transformSuspendSearchCustomerLocations(response.data));

export default suspendSearchCustomerLocations;
