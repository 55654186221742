import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { rotate } from './animations';
import { DARK, LIGHT } from './themes';

const LOADING_BACKGROUND_IMAGES: { [k: string]: string } = {
  [DARK]:
    'PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMjQyODMzIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCIvPjwvZz48L3N2Zz4=',
  [LIGHT]:
    'PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCIvPjwvZz48L3N2Zz4=',
};

export const loading = (size: string, color = DARK) => css`
  display: inline-block;
  width: ${size};
  height: ${size};
  background: url('data:image/svg+xml;base64,${LOADING_BACKGROUND_IMAGES[color]}') no-repeat center;
  background-size: ${size};
  animation: ${rotate} 0.4s linear infinite;
`;

export const loadingOverlay = (
  size: string,
  backgroundColor = '#fff',
  transparency = 0.05,
  zIndex = 2001,
  position?: string,
) => css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndex};
    background: ${transparentize(transparency, backgroundColor)};
  }

  &:after {
    content: '';
    position: absolute;
    top: ${position === 'top' ? '250px' : '50%'};
    left: 50%;
    z-index: ${zIndex};
    margin: -13px 0 0 -13px;
    ${loading(size)};
  }
`;

interface TextLoaderProps {
  size?: number;
  zIndex?: number;
  backgroundColor?: string;
  transparency?: number;
  children?: React.ReactText;
}

export const TextLoader = styled.div<TextLoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.zIndex || 1000};
  background: ${props => transparentize(props.transparency || 0.05, props.backgroundColor || '#fff')};
  user-select: none;

  &:before {
    content: '';
    z-index: ${props => props.zIndex || 1000};
    margin: 16px 0;
    display: inline-block;
    width: ${props => props.size || 24}px;
    height: ${props => props.size || 24}px;
    background: url('data:image/svg+xml;base64,${LOADING_BACKGROUND_IMAGES[DARK]}') no-repeat center;
    background-size: ${props => props.size || 24}px;
    animation: ${rotate} 0.4s linear infinite;
  }
`;
