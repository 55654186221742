import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadDisposalFacilities, loadOperationalFacilities } from 'src/fleet/ducks';
import { loadFeatures } from 'src/vendors/ducks';
import { loadGroups, loadServiceZones } from 'src/routes/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';
import { TypedResolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import CreateEditSnowSweeperRouteModal from './CreateEditSnowSweeperRouteModal';

type Props = {
  closeModal: () => void;
  onDateChange?: (startDate: any | Date | string, endDate: Date | string, isEditMode?: boolean) => void;
  routeSummary?: RouteSummary;
};

export default function CreateEditSnowSweeperRouteModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = props;

  const isActive = false;
  const includeInactiveFacilities = true;

  const loadDependencies = () =>
    Promise.all([
      loadFeatures(vendorId)(dispatch),
      !!routeSummary
        ? loadOperationalFacilities(vendorId, isActive, includeInactiveFacilities)(dispatch)
        : loadOperationalFacilities(vendorId)(dispatch),
      !!routeSummary
        ? loadDisposalFacilities(vendorId, isActive, includeInactiveFacilities)(dispatch)
        : loadDisposalFacilities(vendorId)(dispatch),
      loadServiceZones(vendorId, false)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      loadGroups(vendorId)(dispatch),
    ]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={CreateEditSnowSweeperRouteModal}
      successProps={props}
    />
  );
}
