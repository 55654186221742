import React from 'react';

import { connect } from 'react-redux';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { DuckFunction } from '../../../contracts/ducks';
import { Modal, Resolver } from '../../../core/components';
import { loadMapInsights, vehicleMapInsightsSelector } from '../../../dashboard/ducks';
import { AppState } from '../../../store';
import {
  containerInsightsSelector,
  getUnitOfMeasureSelector,
  loadRoute,
  loadRouteMapCityInsights,
  loadRouteMapDetails,
  loadRouteMapVehicleInsights,
  loadRouteSummary,
  loadTripTimeDetails,
  loadYRoute,
  vehiclePositionsSelector,
  vehicleTrackingsSelector,
} from '../../ducks';
import { ContainerInsights, TripTimeDetails, VehicleTracking } from '../mapWithTimeline/Interfaces';
import { loadRouteStopsForMap } from 'src/routes/ducks';
import { MapWithTimeline } from '../mapWithTimeline/MapWithTimeline';
import { Route } from '../../interfaces/Route';
import { RouteMapProps } from '../RouteMap';
import { RouteMapVehiclePosition } from 'src/routes/interfaces/RouteMapVehicleData';
import * as VEHICLES_INSIGHT_TYPES from '../../constants/vehicleInsightTypes';
import { createErrorNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';

interface MapTimelineModalResolverProps {
  closeModal: () => void;
  containerInsights: ContainerInsights[];
  isYRoute: boolean;
  loadMapInsights: DuckFunction<typeof loadMapInsights>;
  loadRoute: DuckFunction<typeof loadRoute>;
  loadRouteMapCityInsights: DuckFunction<typeof loadRouteMapCityInsights>;
  loadRouteMapDetails: DuckFunction<typeof loadRouteMapDetails>;
  loadRouteMapVehicleInsights: DuckFunction<typeof loadRouteMapVehicleInsights>;
  loadRouteStopsForMap: DuckFunction<typeof loadRouteStopsForMap>;
  loadRouteSummary: DuckFunction<typeof loadRouteSummary>;
  loadTripTimeDetails: DuckFunction<typeof loadTripTimeDetails>;
  loadYRoute: DuckFunction<typeof loadYRoute>;
  mapProps?: RouteMapProps;
  modalTitle: string;
  route: Route;
  routeId: number;
  tripTimeDetails: TripTimeDetails;
  unitOfMeasure: string;
  vehicleInsights: any;
  vehiclePositions: RouteMapVehiclePosition[];
  vehicleTrackings: VehicleTracking[];
}

const ActualModal = ({
  closeModal,
  containerInsights,
  mapProps,
  modalTitle,
  route,
  tripTimeDetails,
  unitOfMeasure,
  vehicleInsights,
  vehiclePositions,
  vehicleTrackings,
}: MapTimelineModalResolverProps) => {
  const propsForMap: RouteMapProps =
    mapProps ||
    ({
      containerInsights,
      routeId: route.id,
      routeLocations: [],
      routeName: route.routeName,
      triggers: [],
      unitOfMeasure,
      vehiclePositions,
      vehicleTrackings,
    } as any);

  return (
    <Modal title={modalTitle || route.routeName} minWidth="90%" size="large" verticalSize="large" onClose={closeModal}>
      {tripTimeDetails && (
        <MapWithTimeline vehicleInsights={vehicleInsights} tripTimeDetails={tripTimeDetails} mapProps={propsForMap} />
      )}
    </Modal>
  );
};

const MapTimelineModalResolver = ({
  closeModal,
  containerInsights,
  isYRoute,
  loadMapInsights,
  loadRoute,
  loadRouteMapCityInsights,
  loadRouteMapDetails,
  loadRouteMapVehicleInsights,
  loadRouteStopsForMap,
  loadRouteSummary,
  loadTripTimeDetails,
  loadYRoute,
  mapProps,
  modalTitle,
  route: propsRoute,
  routeId,
  tripTimeDetails,
  unitOfMeasure,
  vehicleInsights,
  vehiclePositions,
  vehicleTrackings,
}: MapTimelineModalResolverProps) => {
  return (
    <Resolver
      successComponent={ActualModal}
      successProps={{
        route: propsRoute,
        closeModal,
        modalTitle,
        vehiclePositions,
        tripTimeDetails,
        mapProps,
        vehicleInsights,
        vehicleTrackings,
        routeId,
        unitOfMeasure,
        containerInsights,
      }}
      loadingComponent={PageLoadingOverlay}
      resolve={async () => {
        let route = propsRoute;
        if (!route || route.id !== routeId) {
          route = await (isYRoute ? loadYRoute(routeId) : loadRoute(routeId));
        }

        const noLoading = true;
        const cityInsights = undefined;

        const dependencies = [
          loadTripTimeDetails(routeId, isYRoute),
          loadMapInsights({
            vendorId: route.vendorId,
            date: route.routeDate,
            vehicleIds: route.vehicleId ? [route.vehicleId] : [],
            containerIssueInsightTypes: [],
            containerServiceInsightTypes: [],
            vehicleInsightTypes: [
              VEHICLES_INSIGHT_TYPES.STATIONARY,
              VEHICLES_INSIGHT_TYPES.SPEEDING,
              VEHICLES_INSIGHT_TYPES.HARD_ACCELERATION,
              VEHICLES_INSIGHT_TYPES.HARD_BRAKING,
              VEHICLES_INSIGHT_TYPES.OFF_ROUTE,
              VEHICLES_INSIGHT_TYPES.OFF_ROUTE_STATIONARY,
              VEHICLES_INSIGHT_TYPES.OUTSIDE_GEO_FENCE,
              VEHICLES_INSIGHT_TYPES.INSIDE_GEO_FENCE,
            ],
            cityInsightTypes: [],
          }),
          loadRouteStopsForMap(route.vendorId, routeId),
          loadRouteSummary(route.vendorId, routeId),
          loadRouteMapDetails(route.vendorId, routeId, isYRoute),
          loadRouteMapCityInsights(route.vendorId, routeId, cityInsights, noLoading),
          loadRouteMapVehicleInsights(route.vendorId, routeId),
        ];

        return Promise.all(dependencies);
      }}
      onError={() => {
        createErrorNotification(translate('routes.alertMessages.loadingDataForPlayBackError'));
        closeModal();
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  const props = {
    containerInsights: containerInsightsSelector(state.routes.routeMapDetails) as any,
    unitOfMeasure: getUnitOfMeasureSelector(state.routes.routeMapDetails.routeMapDetails) as any,
    vehiclePositions: vehiclePositionsSelector(state.routes.routeMapDetails) as any,
    route: state.routes.route.route as Route,
    tripTimeDetails: state.routes.route.tripTimeDetails as any,
    vehicleInsights: vehicleMapInsightsSelector(state.dashboard.mapInsights) as any,
    vehicleTrackings: vehicleTrackingsSelector(state.routes.routeMapDetails) as any,
  };

  return props;
};

export default connect(mapStateToProps, {
  loadMapInsights,
  loadRoute,
  loadRouteMapCityInsights,
  loadRouteMapDetails,
  loadRouteMapVehicleInsights,
  loadRouteStopsForMap,
  loadRouteSummary,
  loadTripTimeDetails,
  loadYRoute,
})(MapTimelineModalResolver);
