import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../../store';
import translate from '../../../../core/services/translate';
import { Table } from '../../../../core/components';
import { VehicleTableWrapper } from '../../styled/VehiclesPage';
import VehicleMoreIndicator from './VehicleMoreIndicator';
import { Vehicle } from '../../../interfaces/Vehicle';
import { inspectionsCells } from './vehiclePageCells';
import { loadVehicleInspections } from '../../../ducks/vehicleInspections';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { getQueryParams } from '../../../../utils/services/queryParams';
import VehiclePageSection from './VehiclePageSection';
import {
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM,
  VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO,
} from '../../../constants/vehicle';
import { useContainerScrollAtRockBottom } from '../../utils/vehiclePageHooks';
import InspectionLogsTableRow from '../../../../common/components/InspectionLogsTableRow';

const VehicleInspectionLogSection: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const inspections = useSelector((state: AppState) => state.fleet.vehicleInspections.inspections);
  const inspectionsLoading = useSelector((state: AppState) => state.fleet.vehicleInspections.inspectionsLoading);
  const vehicle: Vehicle | undefined = useSelector((state: AppState) => state.fleet.vehicle.vehicle);

  const { containerHasMaximumScroll, setRef, onScroll } = useContainerScrollAtRockBottom(inspections.length);

  const { startDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_FROM, endDate = VEHICLE_DETAILS_DEFAULT_DATE_RANGE_TO } =
    getQueryParams(search);

  const vehicleHasYDevice = !!vehicle && !!vehicle.deviceId;
  const vehicleId = !!vehicle ? vehicle.id : null;

  React.useEffect(() => {
    if (!vendorId || !vehicleId) {
      return;
    }

    loadVehicleInspections(vendorId, vehicleId, startDate, endDate)(dispatch);
  }, [dispatch, vendorId, vehicleId, startDate, endDate]);

  return (
    <VehiclePageSection
      title={translate('vehicles.vehicleInspectionLog')}
      sectionLoading={inspectionsLoading}
      sectionHasBorder={vehicleHasYDevice}
      sectionDisabled={!inspections.length}
      subtitle={
        !inspections.length
          ? translate('vehicles.vehicleInspections.noInspections')
          : translate('vehicles.vehicleInspections.noOfInspections', { count: inspections.length })
      }
      content={
        <>
          {!!inspections.length && (
            <VehicleTableWrapper ref={setRef} onScroll={onScroll}>
              <Table cells={inspectionsCells} rows={inspections} rowComponent={InspectionLogsTableRow} />
            </VehicleTableWrapper>
          )}

          <VehicleMoreIndicator disabled={containerHasMaximumScroll} />
        </>
      }
    />
  );
};

export default withRouter(VehicleInspectionLogSection);
