import React, { Fragment, PureComponent } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { reset } from 'redux-form';

import { Button, ButtonSet, TableActionIcon, TableCell, TableRow, Text } from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { PaymentInfoFillIcon, PaymentInfoFillIconPopUpOpen } from '../../../opportunity/components/styled';
import isFalsy from '../../../utils/services/isFalsy';
import { getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { LIMIT_PER_PAGE } from '../../constants';
import {
  updateAcceptSelectedOnCallPaymentServices,
  updateIssueReportOnCallServices,
  updateOnCallServices,
  updateUndoAcceptOnCallServices,
  updateUndoReportOnCallServices,
} from '../../ducks';
import { AcceptOnCallServicesModal, DetailsWorkOrderRateModal, ReportIssueOnCallServicesModal } from '../modal';
import { AppState } from '../../../store';

interface RouteParams {
  token: string;
}

interface ComponentProps extends RouteComponentProps<RouteParams> {
  workOrderIdentifier: string;
  workOrder: number;
  account?: string;
  action: number;
  confirmed?: string;
  customerName?: string;
  iconStatus: string;
  locationName?: string;
  locationState?: string;
  locationZip?: string;
  loctionAddress?: string;
  loctionCity?: string;
  material?: string;
  quantity?: string;
  scheduledDate?: string;
  serviceDescr?: string;
  serviceFreq?: string;
  serviceType?: string;
  status?: string;
  svcOrd?: number;
  vendName?: string;
  vendorAddress?: string;
  vendorCity?: string;
  vendorState?: string;
  vendorZip?: string;
  woTotal?: number;
}

interface ReduxProps {
  vendorId: number;
  pricingInfo: any;
  issueType: any;
  rateCodes: any[];
  updateOnCallServices(...args: any[]): any;
  updateUndoReportOnCallServices(...args: any[]): any;
  updateUndoAcceptOnCallServices(...args: any[]): any;
  updateIssueReportOnCallServices(...args: any[]): any;
  updateAcceptSelectedOnCallPaymentServices(...args: any[]): any;
  reset(...args: any[]): any;
}

type Props = ReduxProps & ComponentProps;

interface State {
  isAcceptOnCallServicesModalOpen: boolean;
  isReportIssueOnCallServicesModalOpen: boolean;
  isDetailsOnCallWorkOrderRateModalOpen: boolean;
}

const PaymentInfoFillIconMock = PaymentInfoFillIcon as any;

class OnCallServicesFormTableRow extends PureComponent<Props, State> {
  readonly state = {
    isAcceptOnCallServicesModalOpen: false,
    isReportIssueOnCallServicesModalOpen: false,
    isDetailsOnCallWorkOrderRateModalOpen: false,
  };

  // Undo
  onUndoSubmit = async () => {
    const { match, vendorId, workOrder, scheduledDate, action, rateCodes, svcOrd, location, pricingInfo } = this.props;
    const { page } = getQueryParams(location.search);
    const token = match.params.token;
    const requestRecurringServicesObj: any = {};

    requestRecurringServicesObj.workOrder = workOrder;
    requestRecurringServicesObj.svcOrd = svcOrd;
    requestRecurringServicesObj.date = moment(pricingInfo.reportDate).format('YYYY-MM-DD');

    if (action === 1) {
      requestRecurringServicesObj.rubiconInvoiceNo = pricingInfo.rubiconInvoiceNo;
      requestRecurringServicesObj.rateCodes = rateCodes;

      await this.props
        .updateUndoAcceptOnCallServices(requestRecurringServicesObj, vendorId, token, page, LIMIT_PER_PAGE)
        .then(() => {
          createSuccessNotification(translate('opportunity.opportunitySave'));
          //   onCancel(true);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
    } else if (action === 2) {
      requestRecurringServicesObj.scheduleDate = moment(scheduledDate).format('YYYY-MM-DD');

      await this.props
        .updateUndoReportOnCallServices(requestRecurringServicesObj, vendorId, token, page, LIMIT_PER_PAGE)
        .then(() => {
          createSuccessNotification(translate('opportunity.opportunitySave'));
          //   onCancel(true);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
    }
  };

  // Details
  openDetailsOnCallWorkOrderRateModalOpen = (event: any) => {
    this.setState({ isDetailsOnCallWorkOrderRateModalOpen: true });
    event.stopPropagation();

    return false;
  };

  closeDetailsOnCallWorkOrderRateModalOpen = () => {
    this.props.reset('acceptRecurringServices');
    this.setState({ isDetailsOnCallWorkOrderRateModalOpen: false });
  };

  // Accept
  openAcceptOnCallServicesModal = (event: any) => {
    this.setState({ isAcceptOnCallServicesModalOpen: true });
    event.stopPropagation();

    return false;
  };

  closeAcceptOnCallServicesModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('acceptOnCallServices');
    this.setState({ isAcceptOnCallServicesModalOpen: false });
  };

  // Report Issue
  openReportIssueOnCallServicesModal = () => {
    this.setState({ isReportIssueOnCallServicesModalOpen: true });
  };

  closeReportIssueOnCallServicesModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('reportIssueRecurringServices');
    this.setState({ isReportIssueOnCallServicesModalOpen: false });
  };

  render() {
    const {
      workOrderIdentifier,
      workOrder,
      account,
      customerName,
      vendName,
      vendorAddress,
      vendorCity,
      vendorState,
      vendorZip,
      locationName,
      loctionAddress,
      loctionCity,
      locationState,
      locationZip,
      confirmed,
      action,
      // businessType,
      serviceDescr,
      material,
      serviceType,
      scheduledDate,
      serviceFreq,
      quantity,
      status,
      woTotal,
      iconStatus,
      rateCodes,
      vendorId,
      location,
      match,
      updateOnCallServices,
      updateIssueReportOnCallServices,
    } = this.props;

    const token = match.params.token;

    return (
      <TableRow key={workOrderIdentifier}>
        <TableCell vertical width="8%" padding="no no xSmall xSmall">
          {vendName && (
            <Fragment>
              <Text block weight="normal" margin="no no no" size="small">
                {status}
              </Text>
              <Text block weight="normal" margin="no no no" size="small">
                <TableActionIcon icon={iconStatus} size="medium" />
              </Text>
            </Fragment>
          )}
        </TableCell>

        <TableCell vertical width="16%" padding="no xSmall xSmall xSmall">
          {vendName && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.name')}:{' '}
              </Text> */}
              <Text block weight="medium" margin="no no xxSmall" size="small">
                {vendName}
              </Text>
            </Fragment>
          )}

          {vendorAddress && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.address')}:{' '}
              </Text> */}
              <Text block margin="no no xxSmall" weight="light" size="small">
                {` ${vendorAddress}, ${vendorCity}, ${vendorState}, ${vendorZip}`}
              </Text>
            </Fragment>
          )}
        </TableCell>

        <TableCell width="16%" vertical>
          {locationName && (
            <Fragment>
              <Text weight="normal" margin="no no xxSmall" size="small">
                {customerName} ({locationName})
              </Text>
            </Fragment>
          )}

          {loctionAddress && (
            <Text block margin="no no xxSmall" weight="light" size="small">
              {` ${loctionAddress}, ${loctionCity}, ${locationState}, ${locationZip}`}
            </Text>
          )}
        </TableCell>

        <TableCell width="12%" vertical>
          {workOrder && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('payment.rubiconWO')}:{' '}
              </Text>

              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {workOrder}
              </Text>
            </Fragment>
          )}

          {account && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.account')}:{' '}
              </Text>
              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {account}
              </Text>
            </Fragment>
          )}

          {!isFalsy(woTotal, { allowZero: true }) && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('payment.woTotal')}:{' '}
              </Text>

              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                ${woTotal}
              </Text>

              <Text margin="no no no" weight="normal" size="small" singleLine>
                <PaymentInfoFillIconPopUpOpen onClick={this.openDetailsOnCallWorkOrderRateModalOpen}>
                  <Text margin="no no no" size="small" singleLine color="gray">
                    {translate('payment.details')}
                  </Text>

                  <PaymentInfoFillIconMock color="primary" margin="no no no" />
                </PaymentInfoFillIconPopUpOpen>

                {this.state.isDetailsOnCallWorkOrderRateModalOpen && (
                  <DetailsWorkOrderRateModal
                    onCancel={this.closeDetailsOnCallWorkOrderRateModalOpen}
                    workOrderIdentifier={workOrderIdentifier}
                    rateCodes={rateCodes}
                  />
                )}
              </Text>
            </Fragment>
          )}
        </TableCell>

        <TableCell vertical width="16%" padding="xSmall xSmall xSmall xSmall">
          {serviceDescr && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('payment.serviceDescription')}:{' '}
              </Text>
              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {serviceType}
              </Text>
              <Text margin="no no xxSmall" weight="normal" size="small" singleLine>
                {serviceDescr}
              </Text>
            </Fragment>
          )}

          {material && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('common.material')}:{' '}
              </Text> */}
              <Text weight="normal" size="small" singleLine>
                {material}
              </Text>
            </Fragment>
          )}

          {serviceFreq && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('opportunity.frequency')}:{' '}
              </Text> */}
              <Text weight="normal" size="small" singleLine>
                {serviceFreq}
              </Text>
            </Fragment>
          )}

          {quantity && (
            <Fragment>
              {/* <Text margin="no no no" size="small" singleLine color="gray">
                {translate('opportunity.quantity')}:{' '}
              </Text> */}
              <Text weight="normal" size="small" singleLine>
                {quantity}
              </Text>
            </Fragment>
          )}

          {scheduledDate && (
            <Fragment>
              <Text margin="no no no" size="small" singleLine color="gray">
                {translate('opportunity.scheduledDate')}:{' '}
              </Text>
              <Text weight="normal" size="small" singleLine>
                {scheduledDate}
              </Text>
            </Fragment>
          )}
        </TableCell>

        <TableCell vertical width="12%">
          {confirmed && (
            <Fragment>
              <Text margin="no no xxSmall" weight="normal" size="small" align="center" singleLine>
                {confirmed}
              </Text>
            </Fragment>
          )}
        </TableCell>

        <TableCell vertical width="20%" padding="no no no no">
          <ButtonSet vertical flex-direction="column" align="center">
            {(action === 0 || action === 1 || action === 4) && (
              <Button
                background-color="transparent"
                color="primary"
                size="small"
                onClick={this.openAcceptOnCallServicesModal}
                disabled={action === 1}
                margin="no no xSmall"
              >
                {translate('accept')}
              </Button>
            )}

            {this.state.isAcceptOnCallServicesModalOpen && (
              <AcceptOnCallServicesModal
                workOrderIdentifier={workOrderIdentifier}
                workOrder={workOrder}
                vendorId={vendorId}
                action={action}
                updateOnCallServices={updateOnCallServices}
                onCancel={this.closeAcceptOnCallServicesModal}
                token={token}
                location={location}
              />
            )}

            {action === 1 && (
              <Button
                color="primary"
                background-color="transparent"
                size="small"
                onClick={this.onUndoSubmit}
                line
                margin="no no xSmall"
              >
                {translate('payment.undoAccept')}
              </Button>
            )}

            {action === 2 && (
              <Button
                color="primary"
                background-color="transparent"
                size="small"
                onClick={this.onUndoSubmit}
                line
                margin="no no xSmall"
              >
                {translate('payment.undo')}
              </Button>
            )}

            {(action === 0 || action === 2 || action === 4) && (
              <Button
                color="alert"
                background-color="transparent"
                size="small"
                onClick={this.openReportIssueOnCallServicesModal}
                line
                disabled={action === 0 || action === 2 || action === 4}
                margin="no no xSmall"
              >
                {translate('payment.reportIssue')}
              </Button>
            )}

            {this.state.isReportIssueOnCallServicesModalOpen && (
              <ReportIssueOnCallServicesModal
                workOrderIdentifier={workOrderIdentifier}
                workOrder={workOrder}
                vendorId={vendorId}
                action={action}
                scheduledDate={scheduledDate}
                updateIssueReportOnCallServices={updateIssueReportOnCallServices}
                onCancel={this.closeReportIssueOnCallServicesModal}
                location={location}
                token={token}
              />
            )}
          </ButtonSet>

          {action === 4 && (
            <Text margin="no no no" size="small" color="gray">
              {translate('payment.reportIssueMessage')}
            </Text>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

// const formSelector = formValueSelector('recurringServicesForm');

const mapStateToProps = (state: AppState) => ({
  // rateCodes: formSelector(state, `workOrdersInfo.settings${ownProps.workOrderIdentifier}.rateCodes`),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  pricingInfo: state.payments.onCallServices.pricingInfo,
  issueType: state.payments.onCallServices.issueType,
});

const mapDispatchToProps = {
  updateOnCallServices,
  updateUndoReportOnCallServices,
  updateUndoAcceptOnCallServices,
  updateIssueReportOnCallServices,
  updateAcceptSelectedOnCallPaymentServices,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnCallServicesFormTableRow));
