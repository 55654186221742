import humps from 'humps';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { PICKUP_STATUSES } from 'src/common/constants';
import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { DASHBOARD_ROUTE_STOPS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { RouteStopItem } from 'src/dashboard/interfaces/routesData';
import {
  ACCOUNT_STATUSES,
  ACTIVE,
  COMPLETED,
  EXTRA_PICKUP,
  ISSUE_REPORTED,
  SCHEDULED,
  SERVICED,
} from 'src/routes/constants';
import { ContainerInsightFeatureProperties } from '../dashboardContainerInsights/utils';

export const getRouteStopIconType = (routeStop: RouteStopItem) => {
  const accountStatus =
    routeStop.accountTypeId && routeStop.accountTypeId !== ACTIVE && routeStop.accountTypeId !== EXTRA_PICKUP
      ? ACCOUNT_STATUSES[routeStop.accountTypeId]?.technicalName
      : '';
  const flagged = routeStop.hasLocationAlert ? 'Flagged' : '';
  const assisted = routeStop.assistingVehicleRegplate ? 'Assisted' : '';
  let pickupStatus = PICKUP_STATUSES[routeStop.pickupStatusTypeId]?.technicalName;

  if (routeStop.pickupStatusTypeId === SERVICED)
    return NEW_INSIGHT_ICON_TYPES.find(
      ({ types }) => types.indexOf(humps.camelize(PICKUP_STATUSES[SERVICED].technicalName)) !== -1,
    );

  if (routeStop.hasIssueReported) {
    pickupStatus = PICKUP_STATUSES[ISSUE_REPORTED].technicalName;
    if (routeStop.pickupStatusTypeId === SCHEDULED) pickupStatus += 'NotServiced';
    if (routeStop.pickupStatusTypeId === COMPLETED) pickupStatus += 'Serviced';
    if (routeStop.pickupStatusTypeId === ISSUE_REPORTED) pickupStatus += 'NotServiced';
  }

  const insightType = `ic${accountStatus}${flagged}${assisted}${pickupStatus}`;

  return NEW_INSIGHT_ICON_TYPES.find(({ types }) => types.indexOf(insightType) !== -1);
};

export const getRouteStopsGeoJSON = (routeStops: RouteStopItem[]) =>
  getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
    routeStops.map(routeStop => {
      return getPointFeature(routeStop.id, [routeStop.displayLongitude, routeStop.displayLatitude], {
        id: routeStop.id,
        clickable: true,
        layer: DASHBOARD_ROUTE_STOPS_LAYER,
        icon: getRouteStopIconType(routeStop)?.id,
        orderNo: routeStop.orderNo,
        newOrderNumber: undefined,
      });
    }),
  );
  