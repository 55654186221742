import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { LocationAlert } from '../interfaces/Customers';
import doLoadLocationAlerts from '../services/loadLocationAlerts';

// Actions
const START_LOAD = 'customers/locationAlerts/START_LOAD';
const COMPLETE_LOAD = 'customers/locationAlerts/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/locationAlerts/FAIL_LOAD';
const RESET = 'customers/locationAlerts/RESET';

interface State {
  isLoading: boolean;
  locationAlerts: LocationAlert[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  locationAlerts: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationAlerts: action.locationAlerts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationAlerts: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (locationAlerts: LocationAlert) => ({
  type: COMPLETE_LOAD,
  locationAlerts,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadLocationAlerts = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadLocationAlerts = doLoadLocationAlerts(vendorId);
  loadLocationAlerts
    .then(service => {
      dispatch(completeLoad(service));
    })
    .catch(() => dispatch(failLoad()));
  return loadLocationAlerts;
};

export const resetLocationAlerts = () => ({
  type: RESET,
});
