import React, { Component } from 'react';

import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { reset } from 'redux-form';

import { AppState } from '../../../../store';
import { createUrl, getQueryParams } from '../../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DateRangeOptionValue } from '../../../../core/components/DateRangePicker';
import { DisposalSummary } from '../../../interfaces/DisposalSummary';
import { DuckFunction } from '../../../../contracts/ducks';
import { loadDisposalsSummary } from '../../../ducks';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { Message, Panel, PanelSection, PanelSectionGroup } from '../../../../core/components/styled';
import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { RouteTrackerForm } from '../../forms';
import { Table } from '../../../../core/components';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import RouteDisposalsSummaryTableRow from './RouteDisposalsSummaryTableRow';
import translate from '../../../../core/services/translate';

interface RouteDisposalsSummaryPageProps extends RouteComponentProps {
  disposalsSummary: DisposalSummary[];
  isLoading: boolean;
  loadDisposalsSummary: DuckFunction<typeof loadDisposalsSummary>;
  push: (url: string) => void;
  reset: (formKey: string) => void;
  vendorId: number;
  wasteTypes: TechnicalType[];
}

class RouteDisposalsSummaryPage extends Component<RouteDisposalsSummaryPageProps> {
  componentDidUpdate(prevProps: RouteDisposalsSummaryPageProps) {
    const { vendorId, location, loadDisposalsSummary, reset } = this.props;

    if (location.search !== prevProps.location.search) {
      const { searchTerm, startDate, endDate, vehicleTypeIds, routeStatusTypeIds } = getQueryParams(location.search);
      loadDisposalsSummary(vendorId, searchTerm, vehicleTypeIds, routeStatusTypeIds, startDate, endDate);
    }
    reset('routeTracker');
  }

  handleSubmit = ({
    date,
    vehicleTypeIds,
    searchTerm,
    routeStatusTypeIds,
  }: {
    date: DateRangeOptionValue;
    vehicleTypeIds: number[];
    searchTerm: string;
    routeStatusTypeIds: number[];
  }) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    const { from, to } = date;

    push(
      createUrl(pathname, search, {
        startDate: from,
        endDate: to,
        routeStatusTypeIds: routeStatusTypeIds && routeStatusTypeIds.toString(),
        vehicleTypeIds: vehicleTypeIds && vehicleTypeIds.toString(),
        searchTerm,
      }),
    );
  };

  handleBackAction = () => {
    const {
      push,
      location: { search },
    } = this.props;
    const { startDate, endDate, vehicleTypeIds, routeStatusTypeIds, serviceZones, supervisors, groupIds } =
      getQueryParams(search);
    const searchParams = {
      startDate,
      endDate,
      vehicleTypeIds,
      routeStatusTypeIds,
      searchTerm: '',
      serviceZones,
      supervisors,
      groupIds,
    };
    return push(createUrl('/routes/route-tracker', undefined, searchParams));
  };

  render() {
    const { disposalsSummary, isLoading, wasteTypes } = this.props;

    const disposalsSummaryTableCells = [
      { name: 'routeDate', label: translate('routes.routeDate'), width: '11%', sortable: true },
      { name: 'ticketNumber', label: translate('common.ticketNumber'), width: '9%', sortable: false },
      { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '14%', sortable: true },
      { name: 'routeName', label: translate('routes.route'), width: '15%', sortable: true },
      { name: 'wasteType', label: translate('common.wasteType'), width: '10%' },
      { name: 'weightInTons', label: translate('common.tons'), width: '8%', sortable: true },
      { name: 'disposalTime', label: translate('common.weightTickets.disposalDate'), width: '11%' },
      {
        name: 'disposalSiteName',
        label: translate('common.customerTypes.disposalFacility'),
        width: '14%',
        sortable: true,
      },
      { name: 'image', label: translate('common.image'), width: '8%', align: 'center' },
    ];

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction id="back-button" onClick={this.handleBackAction}>
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate('common.disposalsDetails')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <RouteTrackerForm onSubmit={this.handleSubmit} />
            <PanelSection>
              {disposalsSummary.length ? (
                <Table
                  cells={disposalsSummaryTableCells}
                  rows={disposalsSummary}
                  rowComponent={RouteDisposalsSummaryTableRow}
                  rowProps={{
                    wasteTypes,
                  }}
                />
              ) : (
                <Message padding="sMedium">{translate('insights.noReportsFound')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  disposalsSummary: state.routes.disposalsSummary.disposalsSummary,
  isLoading: state.routes.disposalsSummary.isLoading,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  wasteTypes: state.common.wasteTypes.wasteTypes,
});

const mapDispatchToProps = {
  loadDisposalsSummary,
  loadVehicleTypesForVendor,
  push,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteDisposalsSummaryPage));
