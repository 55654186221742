import React from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { every } from 'lodash-es';
import validator from 'validator';

import { Dropdown, ChipInput, TypedField } from '../../../core/components';
import { DropdownOption } from '../../../core/components/Dropdown';
import { Grid, GridColumn } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { VEHICLE_NOTIFICATIONS_FORM } from '../../constants/vehicleNotifications';
import { VehicleNotificationsFormValues } from '../../interfaces/VehicleNotifications';
import { isEmail } from '../../../utils/services/validator';

const selector = formValueSelector(VEHICLE_NOTIFICATIONS_FORM);

const isArrayOfEmails = (values: string[]) => {
  if (every(values, value => validator.isEmail(value))) {
    return;
  }

  return translate('vendors.vehicleNotificationsEmailsInvalid');
};

interface PropsWithoutReduxForm {
  types: DropdownOption[];
  onTypingEmailChange(typing: boolean): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<VehicleNotificationsFormValues, PropsWithoutReduxForm>;

const VehicleNotificationsForm: React.FC<Props> = ({
  types,
  onTypingEmailChange,
  initialValues: { configurations },
  handleSubmit,
}) => {
  const currentNotificationTypeId = useSelector((state: AppState) => selector(state, 'currentNotificationTypeId'));

  const currentTypeIndex = React.useMemo(() => {
    let index = 0;

    if (!!configurations) {
      configurations.forEach((configuration, configurationIndex) => {
        if (configuration.vehicleNotificationType.id === currentNotificationTypeId) {
          index = configurationIndex;
        }
      });
    }

    return index;
  }, [currentNotificationTypeId, configurations]);

  return (
    <form onSubmit={handleSubmit} id={VEHICLE_NOTIFICATIONS_FORM}>
      <Grid centered multiLine>
        <GridColumn size="6/12">
          <TypedField
            name="currentNotificationTypeId"
            component={Dropdown}
            props={{
              margin: 'small no medium',
              width: '100%',
              options: types,
            }}
          />
        </GridColumn>

        {currentNotificationTypeId > -1 && (
          <GridColumn size="10/12">
            <TypedField
              name={`configurations[${currentTypeIndex}].emailAddressesCSV`}
              component={ChipInput}
              validate={[isArrayOfEmails]}
              props={{
                margin: 'no',
                placeholder: translate('vendors.sendTo'),
                validateContent: isEmail,
                metaTextOnFocus: translate('vendors.pressEnterToAddEmailToList'),
                onTyping: onTypingEmailChange,
              }}
            />
          </GridColumn>
        )}
      </Grid>
    </form>
  );
};

export default reduxForm<VehicleNotificationsFormValues, PropsWithoutReduxForm>({
  form: VEHICLE_NOTIFICATIONS_FORM,
  enableReinitialize: true,
})(VehicleNotificationsForm);
