import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { BillHistoryResponseData } from '../interfaces/BillHistory';

import {
  loadBillHistory as doLoadBillHistory,
} from '../services/billHistory';

// Actions
const START_LOAD_BILL_HISTORY = 'finance/billHistory/START_LOAD_BILL_HISTORY';
const COMPLETE_LOAD_BILL_HISTORY = 'finance/billHistory/COMPLETE_LOAD_BILL_HISTORY';
const FAIL_LOAD_BILL_HISTORY = 'finance/billHistory/FAIL_LOAD_BILL_HISTORY';
const RESET = 'finance/billHistory/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  billHistory?: BillHistoryResponseData;
} = {
  isLoading: false,
  billHistory: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_BILL_HISTORY:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_BILL_HISTORY:
      return update(state, {
        $merge: {
          isLoading: false,
          billHistory: action.billHistory,
        },
      });

    case FAIL_LOAD_BILL_HISTORY:
      return update(state, {
        $merge: {
          isLoading: false,
          billHistory: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoadBillHistory = () => ({
  type: START_LOAD_BILL_HISTORY,
});

const completeLoadBillHistory = (billHistory: any) => ({
  type: COMPLETE_LOAD_BILL_HISTORY,
  billHistory,
});

const failLoadBillHistory = () => ({
  type: FAIL_LOAD_BILL_HISTORY,
});

export const loadBillHistory =
  (invoiceId: number) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadBillHistory());
    const loadBillHistoryPromise = doLoadBillHistory(invoiceId);
    loadBillHistoryPromise.then((billHistory: any) => dispatch(completeLoadBillHistory(billHistory))).catch(() => dispatch(failLoadBillHistory()));
    return loadBillHistoryPromise;
  };

export const resetOpenBills = () => ({
  type: RESET,
});
