import styled from 'styled-components';

export const HorizontalBarChartWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
`;

interface HorizontalBarChartSizedWrapperProps {
  width: number;
  height: number;
}

export const HorizontalBarChartDrawingWrapper = styled.div<HorizontalBarChartSizedWrapperProps>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

interface HorizontalBarChartTitleProps {
  size?: number;
}

export const HorizontalBarChartTitle = styled.div<HorizontalBarChartTitleProps>`
  font-size: ${props => props.size || 15}px;
  font-weight: 500;
`;
