import styled from 'styled-components';
import { Grid } from '../../../core/components/styled/Grid';
import { TableCell } from '../../../core/components/styled';

export const GridWrapper = styled(Grid)`
  align-items: flex-end;
  padding: 0 15px 7px;
`;

export const MapContainer = styled.div`
  height: 300px;

  *:focus {
    outline: none;
  }
`;

export const AssignedCheckmarkContainer = styled.div`
  width: 16px;
  margin-left: 9px;
  margin-bottom: 9px;
  color: ${p => p.theme.brandPrimary};
`;

export const RecommendedSpan = styled.span`
  color: ${p => p.theme.brandPrimary};
  font-size: 10px;
  font-weight: 600;
`;

export const InsertAllWrapper = styled.div`
  text-transform: capitalize;
  width: 100%;
`;

export const DayOfWeekPicker = styled(TableCell)`
  word-break: initial;
`;

export const NoResultsContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
