import { useEffect, useRef, useCallback } from 'react';
import { GeoJSONSource } from 'mapbox-gl';
import { cloneDeep } from 'lodash-es';
import translate from '../../../../core/services/translate';
import confirm from '../../../../core/services/confirm';
import { Coordinates } from '../Coordinates';

export const useDraggablePoints = (
  map: mapboxgl.Map,
  source: GeoJSON.FeatureCollection<any>,
  sourceId: string,
  layerId: string,
  setDragPan: (dragPan: boolean) => void,
  updateAddress: (markerId: number, updatedAddress: Coordinates) => void,
  setData: (data: any) => void,
  showConfirmation: boolean = true,
) => {
  const originalSourceRef = useRef<GeoJSON.FeatureCollection<any>>(cloneDeep(source));
  const editableSourceRef = useRef<GeoJSON.FeatureCollection<any>>(cloneDeep(source));
  const currentFeatureRef = useRef<GeoJSON.Feature<GeoJSON.Point, any>>();
  const currentAddressRef = useRef<Coordinates>();
  const isMovedRef = useRef<boolean>(false);

  const setDragPanRef = useRef(setDragPan);
  const updateAddressRef = useRef(updateAddress);
  const setDataRef = useRef(setData);

  useEffect(() => {
    setDragPanRef.current = setDragPan;
    updateAddressRef.current = updateAddress;
    setDataRef.current = setData;
  }, [setDragPan, updateAddress, setData]);

  useEffect(() => {
    originalSourceRef.current = cloneDeep(source);

    if (originalSourceRef.current.features.length > editableSourceRef.current.features.length) {
      editableSourceRef.current = cloneDeep(source);
    }
  }, [source]);

  const updateSource = useCallback(
    (source: GeoJSON.FeatureCollection<any>) => {
      (map.getSource(sourceId) as GeoJSONSource).setData(source);
    },
    [map, sourceId],
  );

  const mouseMove = useCallback(
    (e: any) => {
      if (editableSourceRef.current) {
        const canvas = map.getCanvasContainer();
        const coords = e.lngLat;
        canvas.style.cursor = 'grabbing';
        if (currentFeatureRef.current) {
          currentFeatureRef.current.geometry.coordinates = [coords.lng, coords.lat];
          currentAddressRef.current = { lat: coords.lat, lng: coords.lng };
          isMovedRef.current = true;
        }
        updateSource(editableSourceRef.current);
      }
    },
    [map, updateSource],
  );

  const changeCursor = useCallback(
    e => {
      const canvas = map.getCanvasContainer();
      canvas.style.cursor = 'move';
    },
    [map],
  );

  const resetCursor = useCallback(() => {
    const canvas = map.getCanvasContainer();
    canvas.style.cursor = '';
  }, [map]);

  const movePoint = useCallback(
    function (e) {
      e.preventDefault();

      const canvas = map.getCanvasContainer();

      if (setDragPanRef.current) {
        setDragPanRef.current(false);
      }

      if (e.features && e.features.length) {
        currentFeatureRef.current = editableSourceRef.current.features.find(
          f => f.id === (e.features![0].id as number),
        );
      }

      canvas.style.cursor = 'grab';

      map.on('mousemove', mouseMove);
      map.once('mouseup', async () => {
        if (isMovedRef.current) {
          if (showConfirmation && !(await confirm(translate('routes.alertMessages.confirmChangeLocation')))) {
            updateSource(originalSourceRef.current);
            editableSourceRef.current = cloneDeep(originalSourceRef.current);
          } else {
            if (currentFeatureRef.current && currentAddressRef.current) {
              updateAddressRef.current(currentFeatureRef.current.id as number, currentAddressRef.current);
              setDataRef.current(editableSourceRef.current);
            }
          }
        }
        if (setDragPanRef.current) {
          setDragPanRef.current(true);
        }
        const canvas = map.getCanvasContainer();
        canvas.style.cursor = '';
        map.off('mousemove', mouseMove);
        isMovedRef.current = false;
      });
    },
    [map, mouseMove, showConfirmation, updateSource],
  );

  useEffect(() => {
    map.on('mouseenter', layerId, changeCursor);
    map.on('mouseleave', layerId, resetCursor);
    map.on('mousedown', layerId, movePoint);

    return () => {
      map.off('mouseenter', layerId, changeCursor);
      map.off('mouseleave', layerId, resetCursor);
      map.off('mousedown', layerId, movePoint);
    };
  }, [changeCursor, layerId, map, mouseMove, movePoint, resetCursor, updateSource]);
};
