import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { WrappedFieldInputProps } from 'redux-form';

import { AppState } from '../../store';
import { Text } from '../../core/components/styled';
import { routeLocationsByIdSelector } from '../ducks';

interface Props {
  input: WrappedFieldInputProps;
  routeLocationsById: any;
}

class DisplayRouteCustomerText extends PureComponent<Props> {
  render() {
    const {
      routeLocationsById,
      input: { value },
    } = this.props;

    return (
      <Fragment>
        {!!routeLocationsById[value] && (
          <Fragment>
            <Text block singleLine weight="medium" margin="no no xxSmall">
              {routeLocationsById[value].customer.name}
            </Text>
            <Text block singleLine size="small">
              {routeLocationsById[value].location.name}
            </Text>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  routeLocationsById: (routeLocationsByIdSelector as any)(state.routes.route.route),
});

export default connect(mapStateToProps)(DisplayRouteCustomerText);
