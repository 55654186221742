import { push } from 'connected-react-router';
import update from 'immutability-helper';
import { camelCase } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { createNotification } from '../../core/ducks';
import translate from '../../core/services/translate';
import { resetPassword as doResetPassword } from '../services/account';

// Actions
const START_RESET_PASSWORD = 'account/resetPassword/START_RESET_PASSWORD';
const COMPLETE_RESET_PASSWORD = 'account/resetPassword/COMPLETE_RESET_PASSWORD';
const FAIL_RESET_PASSWORD = 'account/resetPassword/FAIL_RESET_PASSWORD';
const RESET = 'account/resetPassword/RESET';

interface State {
  isResettingPassword: boolean;
  isResetPasswordFailed: boolean;
  isResetPasswordFailedMessage: string | null;
}

// Initial state
const initialState: State = {
  isResettingPassword: false,
  isResetPasswordFailed: false,
  isResetPasswordFailedMessage: null,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_RESET_PASSWORD:
      return update(state, {
        $merge: {
          isResettingPassword: true,
          isResetPasswordFailed: false,
          isResetPasswordFailedMessage: null,
        },
      });

    case COMPLETE_RESET_PASSWORD:
      return update(state, {
        $merge: {
          isResettingPassword: false,
        },
      });

    case FAIL_RESET_PASSWORD:
      return update(state, {
        $merge: {
          isResettingPassword: false,
          isResetPasswordFailed: true,
          isResetPasswordFailedMessage: action.error.request.response
            ? camelCase(action.error.request.response.Code)
            : null,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startResetPassword = () => ({
  type: START_RESET_PASSWORD,
});

const completeResetPassword = () => ({
  type: COMPLETE_RESET_PASSWORD,
});

const failResetPassword = (error: any) => ({
  type: FAIL_RESET_PASSWORD,
  error,
});

export const resetResetPassword = () => ({
  type: RESET,
});

export const resetPassword = (token: string, email: string, password: string) => (dispatch: Dispatch) => {
  dispatch(startResetPassword());

  return doResetPassword(token, email, password)
    .then(() => {
      createNotification(translate('account.passwordChangedSuccessfully'), 'success', 6000)(dispatch);
      dispatch(resetResetPassword());
      dispatch(push('/account/login'));
    })
    .catch(error => dispatch(failResetPassword(error)))
    .finally(() => dispatch(completeResetPassword()));
};
