import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { DateRangePicker, PanelSearch } from '../../../core/components';
import { Form, Grid, GridColumn, PanelSection, Button } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

export interface InvoiceFormValues {
  invoiceNumber?: string;
  accountNumber?: string;
  dateRange: { from: string; to: string };
  vendorId: string;
}
interface PropsWithoutReduxForm {
  initialValues: any;
  invoices?: any[];
  invoice?: any;
  invoiceSearchName?: any;
  dateRange?: { from?: string; to?: string };
}

type Props = PropsWithoutReduxForm & InjectedFormProps<InvoiceFormValues, PropsWithoutReduxForm>;

class InvoiceForm extends PureComponent<Props> {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="invoiceNumber"
                placeholder={translate('invoice.invoiceNumber')}
                component={PanelSearch}
                margin="no"
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="accountNumber"
                placeholder={translate('common.account')}
                component={PanelSearch}
                margin="no"
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                name="dateRange"
                component={DateRangePicker}
                maxInterval={{ amount: 90, unit: 'days' }}
                margin="no"
              />
            </GridColumn>
          </Grid>
          <Button type="submit" color="primary">
            {translate('common.search')}
          </Button>
        </PanelSection>
      </Form>
    );
  }
}

export default connect()(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'invoiceForm',
    enableReinitialize: true,
  })(InvoiceForm),
);
