import React, { useMemo } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { PanelSearch, TypedField, MultiSelect, DateRangePicker, Dropdown } from '../../../core/components';
import { PanelSection, PanelSectionGroup, Grid, GridColumn } from '../../../core/components/styled';
import { WEEKDAYS } from '../../../core/constants/weekdays';
import translate from '../../../core/services/translate';
import { multiSelectTextFormat } from '../../../common/services/multiSelectTextFormat';
import {
  ROUTE_TEMPLATE_BUILDER_PAGE_FORM,
  RTB_TEMPLATE_STATUSES,
  DRAFT,
  RTB_TEMPLATE_STATUSES_MAP,
  NEW,
} from 'src/routes/constants/routeTemplateBuilder';
import { RouteTemplateStatus } from '../styled/RouteTemplateBuilderMap';
import { useSelector } from 'src/core/hooks/useSelector';
import { uniqBy } from 'lodash-es';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants/vehicleTypes';

const daysOfService = WEEKDAYS.map(day => ({
  label: day.name,
  value: day.id,
}));

const statuses = RTB_TEMPLATE_STATUSES.map(status => ({
  label: status.name,
  value: status.id,
}));

const daysOfServiceFormatText = multiSelectTextFormat(
  'routeTemplateBuilder.allDaysOfService',
  'routeTemplateBuilder.xDaysOfServiceSelected',
);
const routesFormatText = multiSelectTextFormat(
  'routeTemplateBuilder.allRoutes',
  'routeTemplateBuilder.xRoutesSelected',
);
const vehicleTypeFormatText = multiSelectTextFormat('vehicles.allVehicleTypes', 'vehicles.xVehicleTypesSelected');

interface FormValues {
  searchTerm: string;
  dayOfServiceIds: number[];
  dateRange: {
    from: string;
    to: string;
  };
  routeTemplateIds: number[];
  vehicleTypeIds: number[];
  status: number;
}

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const RouteTemplateBuilderPageForm: React.FC<Props> = () => {
  const rawRouteTemplates = useSelector(state => state.routes.routeTemplateBuilder.workSessionsRouteTemplates);

  const routeTemplates = useMemo(
    () => uniqBy(rawRouteTemplates, template => (template.isPublished ? template.routeTemplateId : template.id)),
    [rawRouteTemplates],
  );

  const availableVehicleTypes = React.useMemo(
    () =>
      uniqBy(
        rawRouteTemplates.map(rt => ({ label: VEHICLE_TYPE_IDS[rt.vehicleTypeId].name, value: rt.vehicleTypeId })),
        'value',
      ),
    [rawRouteTemplates],
  );

  return (
    <PanelSection withBorder padding="no">
      <Grid>
        <GridColumn width="24%" padding="no small">
          <PanelSectionGroup withBorder width="100%" height="100%">
            <Grid>
              <GridColumn padding="small no">
                <TypedField name="searchTerm" component={PanelSearch} props={{ isClearable: true }} />
              </GridColumn>
            </Grid>
          </PanelSectionGroup>
        </GridColumn>

        <GridColumn width="16%" padding="small small small no">
          <TypedField
            name="dayOfServiceIds"
            component={MultiSelect}
            props={{
              margin: 'no',
              options: daysOfService,
              formatText: daysOfServiceFormatText,
              normalizeValues(value: string | number) {
                return +value;
              },
            }}
          />
        </GridColumn>

        <GridColumn width="20%" padding="small small small no">
          <TypedField
            name="dateRange"
            component={DateRangePicker}
            props={{
              hasMarginLeft: 'small',
              isClearable: true,
              placeholder: translate('common.dateRange'),
              margin: 'no',
            }}
          />
        </GridColumn>

        <GridColumn width="15%" padding="small small small no">
          <TypedField
            name="routeTemplateIds"
            component={MultiSelect}
            props={{
              noWrap: true,
              margin: 'no',
              options: routeTemplates.map(template => ({
                label: template.name,
                value: template.isPublished ? template.routeTemplateId : template.id,
                renderCustomOption: () => (
                  <>
                    {template.name}

                    {!template.isPublished && (
                      <RouteTemplateStatus
                        statusId={!template.routeTemplateId ? NEW : DRAFT}
                        margin="xxSmall no xxSmall xSmall"
                      >
                        {RTB_TEMPLATE_STATUSES_MAP[!template.routeTemplateId ? NEW : DRAFT].name}
                      </RouteTemplateStatus>
                    )}
                  </>
                ),
              })),
              placeholder: translate('routeTemplateBuilder.routes'),
              noOptionsMessage: translate('routeTemplateBuilder.noRoutes'),
              formatText: routesFormatText,
              normalizeValues(value: string | number) {
                return +value;
              },
            }}
          />
        </GridColumn>

        <GridColumn width="15%" padding="small small small no">
          <TypedField
            name="vehicleTypeIds"
            component={MultiSelect}
            props={{
              margin: 'no',
              options: availableVehicleTypes,
              formatText: vehicleTypeFormatText,
              normalizeValues(value: string | number) {
                return +value;
              },
            }}
          />
        </GridColumn>

        <GridColumn width="10%" padding="small small small no">
          <TypedField
            name="status"
            component={Dropdown}
            props={{
              isClearable: true,
              placeholder: translate('routeTemplateBuilder.status'),
              margin: 'no',
              options: statuses,
            }}
          />
        </GridColumn>
      </Grid>
    </PanelSection>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: ROUTE_TEMPLATE_BUILDER_PAGE_FORM,
})(RouteTemplateBuilderPageForm);
