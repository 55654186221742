import { map, size } from 'lodash-es';
import { MouseEvent, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';

import { PermissionGuard, SuperAdminGuard } from 'src/account/components';
import { GEO_FENCES_DAILY_ROUTE, GEO_FENCES_ROUTE_TEMPLATE } from 'src/account/constants';
import { isAdminSelector, isSuperAdminSelector } from 'src/account/ducks';
import { UnconnectedSwitch } from 'src/core/components';
import { IconButtonIcon } from 'src/core/components/styled';
import { BOTTOM } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import createPopover from 'src/core/services/createPopover';
import translate from 'src/core/services/translate';
import { FRONT_LOAD_ID, RESIDENTIAL_ID, SNOW_PLOW_ID } from 'src/fleet/constants';
import RouteGeoFenceAlertsForm from 'src/routes/components/forms/RouteGeoFenceAlertsForm';
import EventLogModalResolver from 'src/routes/components/modals/EventLogModalResolver';
import MapTimelineModalResolver from 'src/routes/components/modals/MapTimelineModalResolver';
import TriggerMapModalResolver from 'src/routes/components/modals/TriggerMapModalResolver';
import ZEventLogModalResolver from 'src/routes/components/modals/ZEventLogModalResolver';
import { COMPLETED, SCHEDULED } from 'src/routes/constants';
import { setDebugMode, toggleSpeedColor } from 'src/routes/ducks';
import { GeoFence, deleteGeoFences } from 'src/routes/ducks/geoFences';
import { loadRouteGeoFence, saveRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { checkIfGeoFenceIsEnabled, isRoutePlaybackFeatureEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  RouteMapAction,
  RouteMapActionText,
  RouteMapActionsClose,
  RouteMapActionsCloseIcon,
  RouteMapActionsContainer,
  RouteMapActionsSection,
  RouteMapActionsTitle,
  RouteMapActionsWrapper,
} from '../../../../styled/RouteMapActions';
import { getFormValues } from 'redux-form';
import { ROUTE_MAP_FILTERS_FORM_NAME, RouteMapFiltersFormValues } from './RouteMapFiltersForm';

type Props = {
  closeRouteMapActions: () => void;
  drawMode?: DrawPolygonMode | EditingMode;
  enterDrawMode: () => void;
  exitDrawMode: () => void;
  editGeoFence: () => void;
  handleAddPolygon: () => void;
  handleDeletePolygon: () => void;
  undoDraw: () => void;
  showRouteGeoFence: (id?: number) => void;
  removeRouteGeoFence: (id: number) => void;
  isEditingStops: boolean;
  isAddingPolygon: boolean;
  isRouteMapActionsOpen: boolean;
  polygonsInEdit: GeoJSON.Feature<GeoJSON.Polygon>[];
  selectedGeoFence?: any;
};

export default function RouteMapActions({
  closeRouteMapActions,
  drawMode,
  enterDrawMode,
  editGeoFence,
  exitDrawMode,
  handleAddPolygon,
  undoDraw,
  showRouteGeoFence,
  handleDeletePolygon,
  removeRouteGeoFence,
  isRouteMapActionsOpen,
  polygonsInEdit,
  isEditingStops,
  isAddingPolygon,
  selectedGeoFence,
}: Props) {
  const dispatch = useDispatch();
  const closeGeoFenceAlertPopover = useRef<() => void>();
  const [isEventLogModalOpen, setEventLogModalOpen] = useState(false);
  const [isZEventLogModalOpen, setZEventLogModalOpen] = useState(false);
  const [isTriggerMapModalOpen, setTriggerMapModalOpen] = useState(false);
  const [isRoutePlaybackModalOpen, setRoutePlaybackModalOpen] = useState(false);
  const [isGeoFenceAlertVisible, setGeoFenceAlertVisible] = useState(false);

  const vendorId = useSelector(currentVendorId);
  const geoFenceIsActive = useSelector(checkIfGeoFenceIsEnabled);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { isDebugMode } = useSelector(state => state.routes.mapControls);
  const { geoFence } = useSelector(state => state.routes.geoFence);
  const showSpeedColors = useSelector(state => state.routes.mapControls.showSpeedColors);

  const handleSpeedToggle = () => {
    dispatch(toggleSpeedColor());
  };
  const isRubiconAdmin = useSelector(state => {
    const loginState = state.account.login;
    return isAdminSelector(loginState) || isSuperAdminSelector(loginState);
  });

  const isRoutePlaybackEnabled = useSelector(isRoutePlaybackFeatureEnabled);
  const { showVehicleTrackings } = useSelector(getFormValues(ROUTE_MAP_FILTERS_FORM_NAME)) as RouteMapFiltersFormValues;
  if (!routeSummary) return null;

  const showGeoFenceAlert = (event: MouseEvent<HTMLDivElement>, readOnly = false) => {
    event.stopPropagation();
    setGeoFenceAlertVisible(true);
    if (!isGeoFenceAlertVisible) {
      closeGeoFenceAlertPopover.current = createPopover(
        event.target as HTMLElement,
        RouteGeoFenceAlertsForm,
        {
          alertsAreReadOnly: readOnly,
          geoFenceId: geoFence?.id,
          closeGeoFenceAlert: closeGeoFenceAlert,
        },
        {
          position: BOTTOM,
          tabletRightAlign: true,
        },
      );
    } else {
      closeGeoFenceAlert();
    }
  };

  const closeGeoFenceAlert = () => {
    if (isGeoFenceAlertVisible) {
      setGeoFenceAlertVisible(false);
    }
    if (closeGeoFenceAlertPopover.current) closeGeoFenceAlertPopover.current();
  };

  const handleSaveGeoFence = async () => {
    if (polygonsInEdit.length === 0 && !!geoFence?.id) {
      exitDrawMode();
      handleDeleteGeoFence();
    } else {
      const newGeoFence = {
        geoFenceCoordinates: map(polygonsInEdit, polygon => polygon.geometry.coordinates as number[][][]),
        vendorId,
        id: geoFence?.id || 0,
        geoFenceSettings: geoFence?.geoFenceSettings || [],
        isActive: true,
        routeName: routeSummary.name,
        routeId: routeSummary.routeId,
        startingLocationId: routeSummary.startingLocationId,
      };

      saveRouteGeoFence(
        routeSummary.routeId,
        newGeoFence,
      )(dispatch)
        .then((data: GeoFence) => {
          const successMessage = geoFence?.id ? 'editSuccess' : 'saveSuccess';
          loadRouteGeoFence(routeSummary.routeId)(dispatch);
          showRouteGeoFence(data?.id);
          exitDrawMode();
          createSuccessNotification(translate(`routes.geoFences.alertMessages.${successMessage}`));
        })
        .catch(() => {
          createErrorNotification(translate('routes.geoFences.alertMessages.saveError'));
        });
    }
  };

  const handleDeleteGeoFence = async () => {
    if (!geoFence) return;

    if (!(await confirm(translate('routes.geoFences.alertMessages.confirmDeleteGeoFence')))) {
      return;
    }

    deleteGeoFences(
      vendorId,
      String(geoFence.id),
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.geoFences.alertMessages.geoFenceDeleted'));
        removeRouteGeoFence(geoFence.id);
        exitDrawMode();
        loadRouteGeoFence(routeSummary.routeId)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('routes.geoFences.alertMessages.geoFenceDeleteError'));
      });
  };

  const isUndoDisabled = !selectedGeoFence || size(selectedGeoFence.history) <= 1;

  return isRouteMapActionsOpen ? (
    <>
      <RouteMapActionsWrapper>
        <RouteMapActionsTitle>{translate('tooltips.mapActions')}</RouteMapActionsTitle>
        <RouteMapActionsClose onClick={closeRouteMapActions}>
          <RouteMapActionsCloseIcon />
        </RouteMapActionsClose>
        <RouteMapActionsContainer>
          <RouteMapActionsSection>
            {routeSummary.vehicleTypeId !== SNOW_PLOW_ID &&
              routeSummary.routeStatusTypeId === SCHEDULED &&
              geoFenceIsActive &&
              !isEditingStops && (
                <PermissionGuard permission={GEO_FENCES_DAILY_ROUTE || GEO_FENCES_ROUTE_TEMPLATE}>
                  {!geoFence?.id && drawMode instanceof EditingMode && (
                    <>
                      <RouteMapAction onClick={handleSaveGeoFence}>
                        <IconButtonIcon size="large" icon="createGeoFence" />
                        <RouteMapActionText>{translate('routes.geoFences.saveGeoFence')}</RouteMapActionText>
                      </RouteMapAction>
                      {/* Delete GeoFence (if not saved) */}
                      <RouteMapAction onClick={() => exitDrawMode()}>
                        <IconButtonIcon size="large" icon="deleteGeoFence" />
                        <RouteMapActionText>{translate('routes.geoFences.deleteGeoFence')}</RouteMapActionText>
                      </RouteMapAction>
                    </>
                  )}
                  {/* GeoFence edit actions */}
                  {drawMode && (
                    <>
                      <RouteMapAction onClick={() => exitDrawMode()}>
                        <IconButtonIcon size="xLarge" icon="cancelClear" />
                        <RouteMapActionText noLeftMargin>{translate('common.cancel')}</RouteMapActionText>
                      </RouteMapAction>
                      <RouteMapAction onClick={undoDraw} disabled={isUndoDisabled}>
                        <IconButtonIcon icon="betterUndo" size="large" />
                        <RouteMapActionText>{translate('payment.undo')}</RouteMapActionText>
                      </RouteMapAction>
                      <RouteMapAction
                        onClick={isAddingPolygon || !selectedGeoFence ? undefined : handleDeletePolygon}
                        disabled={isAddingPolygon || !selectedGeoFence}
                      >
                        <IconButtonIcon size="large" icon="deleteGeoFence" />
                        <RouteMapActionText>{translate('routes.geoFences.deletePolygon')}</RouteMapActionText>
                      </RouteMapAction>
                      <RouteMapAction
                        onClick={isAddingPolygon ? undefined : handleAddPolygon}
                        disabled={isAddingPolygon}
                      >
                        <IconButtonIcon size="large" icon="createGeoFence" />
                        <RouteMapActionText>{translate('routes.geoFences.addPolygon')}</RouteMapActionText>
                      </RouteMapAction>

                      {!!geoFence?.id && (
                        <RouteMapAction onClick={handleSaveGeoFence}>
                          <IconButtonIcon size="large" icon="createGeoFence" />
                          <RouteMapActionText>{translate('routes.geoFences.saveGeoFence')}</RouteMapActionText>
                        </RouteMapAction>
                      )}
                    </>
                  )}
                  {!drawMode && (
                    <RouteMapAction onClick={geoFence?.id ? editGeoFence : enterDrawMode}>
                      <IconButtonIcon size="large" icon="createGeoFence" />
                      <RouteMapActionText>
                        {translate(geoFence?.id ? 'tooltips.editGeoFence' : 'routes.geoFences.doCreateGeoFence')}
                      </RouteMapActionText>
                    </RouteMapAction>
                  )}
                  {!!(geoFence && geoFence.id) && !drawMode && (
                    <>
                      <RouteMapAction onClick={handleDeleteGeoFence}>
                        <IconButtonIcon size="large" icon="deleteGeoFence" />
                        <RouteMapActionText>{translate('routes.geoFences.deleteGeoFence')}</RouteMapActionText>
                      </RouteMapAction>
                      <RouteMapAction onClick={showGeoFenceAlert}>
                        <IconButtonIcon size="large" icon="geoFenceAlert" />
                        <RouteMapActionText>{translate('routes.geoFences.setGeoFenceAlerts')}</RouteMapActionText>
                      </RouteMapAction>
                    </>
                  )}
                </PermissionGuard>
              )}

            {routeSummary.routeStatusTypeId !== SCHEDULED && (
              <>
                {isRoutePlaybackEnabled && (
                  <RouteMapAction onClick={() => setRoutePlaybackModalOpen(true)}>
                    <IconButtonIcon size="large" icon="playRoutePlayback" />
                    <RouteMapActionText>{translate('routes.playRoutePlayback')}</RouteMapActionText>
                  </RouteMapAction>
                )}
                {!!(geoFence && geoFence.id) && (
                  <RouteMapAction onClick={e => showGeoFenceAlert(e, true)}>
                    <IconButtonIcon size="large" icon="geoFenceAlert" />
                    <RouteMapActionText>{translate('routes.geoFences.geoFenceAlerts')}</RouteMapActionText>
                  </RouteMapAction>
                )}
              </>
            )}
          </RouteMapActionsSection>

          <RouteMapActionsSection>
            {isRubiconAdmin && (
              <>
                {showVehicleTrackings && (
                  <RouteMapAction onClick={() => setZEventLogModalOpen(true)}>
                    <UnconnectedSwitch
                      onChange={() => handleSpeedToggle()}
                      label={translate('routes.showSpeedVisualization')}
                      size="medium"
                      checked={showSpeedColors}
                      id="speedToggle"
                    />
                  </RouteMapAction>
                )}
                <RouteMapAction onClick={() => setZEventLogModalOpen(true)}>
                  <IconButtonIcon size="large" icon="eventLog" />
                  <RouteMapActionText>{translate('routes.zEventLog')}</RouteMapActionText>
                </RouteMapAction>
                <RouteMapAction onClick={() => setEventLogModalOpen(true)}>
                  <IconButtonIcon size="large" icon="eventLog" />
                  <RouteMapActionText>{translate('routes.eventLog')}</RouteMapActionText>
                </RouteMapAction>
                {routeSummary.vehicleTypeId === FRONT_LOAD_ID && (
                  <RouteMapAction onClick={() => setTriggerMapModalOpen(true)}>
                    <IconButtonIcon size="large" icon="map" />
                    <RouteMapActionText>{translate('routes.triggerMap')}</RouteMapActionText>
                  </RouteMapAction>
                )}
              </>
            )}

            {routeSummary.routeStatusTypeId === COMPLETED && routeSummary.vehicleTypeId === RESIDENTIAL_ID && (
              <SuperAdminGuard>
                <RouteMapAction>
                  <UnconnectedSwitch
                    size="medium"
                    checked={isDebugMode}
                    onChange={(value: boolean) => dispatch(setDebugMode(value))}
                  />
                  <RouteMapActionText>{translate('maps.debug.debugMode')}</RouteMapActionText>
                </RouteMapAction>
              </SuperAdminGuard>
            )}
          </RouteMapActionsSection>
        </RouteMapActionsContainer>
      </RouteMapActionsWrapper>
      {isEventLogModalOpen && <EventLogModalResolver closeModal={() => setEventLogModalOpen(false)} />}
      {isZEventLogModalOpen && <ZEventLogModalResolver closeModal={() => setZEventLogModalOpen(false)} />}
      {isTriggerMapModalOpen && <TriggerMapModalResolver closeModal={() => setTriggerMapModalOpen(false)} />}
      {isRoutePlaybackModalOpen && (
        <MapTimelineModalResolver
          isYRoute={false}
          routeId={routeSummary.routeId}
          modalTitle={routeSummary.name}
          closeModal={() => setRoutePlaybackModalOpen(false)}
        />
      )}
    </>
  ) : null;
}
