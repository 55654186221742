import React from 'react';
import {
  DetailsListBodySection,
  DetailsListItemDescription,
  DetailsListItemTitle,
  Popover,
} from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { Service } from 'src/customers/interfaces/Services';
import { PopoverDetailsContainerWrapper, PopoverDetailsContainer } from '../../styled/CustomerLocations';

const renderDetail = (translation: string, value: string | number) => (
  <DetailsListBodySection>
    <DetailsListItemTitle size="small">{translate(translation)}</DetailsListItemTitle>
    <DetailsListItemDescription align="left">{value || '-'}</DetailsListItemDescription>
  </DetailsListBodySection>
);

export const ServicePopover: React.SFC<{ service: Service }> = ({ service }) => (
  <Popover>
    <DetailsListItemDescription>{service.serviceName}</DetailsListItemDescription>

    <PopoverDetailsContainerWrapper>
      <PopoverDetailsContainer>
        {renderDetail('common.equipmentType', service.equipmentType)}
        {renderDetail('customers.frequency', service.pickupFrequencyType)}
        {renderDetail('customers.containerCount', service.containerCount)}
      </PopoverDetailsContainer>

      <PopoverDetailsContainer>
        {renderDetail('customers.equipmentSize', service.equipmentSize)}
        {renderDetail('customers.wasteMaterial', service.wasteMaterialType)}
        {renderDetail('common.binNumber', service.binNumber)}
      </PopoverDetailsContainer>

      {!!service.assignedToRouteTemplates.length && (
        <PopoverDetailsContainer>
          <DetailsListBodySection>
            <DetailsListItemTitle size="small">{translate('customers.assignedRouteTemplates')}</DetailsListItemTitle>

            <DetailsListItemDescription align="left">
              {service.assignedToRouteTemplates.map(template => template.name).join(', ')}
            </DetailsListItemDescription>
          </DetailsListBodySection>
        </PopoverDetailsContainer>
      )}
    </PopoverDetailsContainerWrapper>
  </Popover>
);
