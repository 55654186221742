import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DuckFunction } from 'src/contracts/ducks';
import { Modal } from 'src/core/components';
import { ModalTitle } from 'src/core/components/styled';
import { createSuccessNotification, createWarningNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { saveTemporaryAddress } from 'src/customers/ducks';
import { TemporaryAddress } from 'src/customers/interfaces/Customers';
import { AppState } from 'src/store';
import createFormattedAddress from 'src/utils/services/createFormattedAddress';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import TemporaryAddressEditorForm from '../forms/TemporaryAddressEditorForm';

interface Props {
  isSaving: boolean;
  closePopup: (pristine: boolean) => void;
  saveTemporaryAddress: DuckFunction<typeof saveTemporaryAddress>;
  changeLocationAddress: (one: any, address: any) => void;
  locationId: number;
  vendor?: Vendor;
}

class TemporaryAddressModal extends PureComponent<Props> {
  saveTemporaryAddress = async (formData: TemporaryAddress) => {
    const { saveTemporaryAddress, closePopup, changeLocationAddress, vendor } = this.props;

    const address = await saveTemporaryAddress(formData);
    if (!address.latitude || !address.longitude) {
      createWarningNotification(translate('customers.alertMessages.temporaryAddressSavedNoCoords'), 10000);
    } else {
      createSuccessNotification(translate('customers.alertMessages.temporaryAddressSaved'));
    }
    const usFormat = true;
    closePopup(true);
    changeLocationAddress(undefined, {
      ...address,
      latitude: address.latitude || vendor?.homeAddress.latitude,
      longitude: address.longitude || vendor?.homeAddress.longitude,
      formattedAddress: createFormattedAddress(address, usFormat),
      text: createFormattedAddress(address, usFormat),
      line1: createFormattedAddress(address, usFormat),
    });
  };

  render() {
    const { isSaving, closePopup, locationId } = this.props;
    return (
      <Modal padding="medium" isLoading={isSaving} size="medium">
        <ModalTitle>{translate('customers.temporaryAddress')}</ModalTitle>
        <TemporaryAddressEditorForm
          onSubmit={this.saveTemporaryAddress}
          closePopup={closePopup}
          locationId={locationId}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendor: state.vendors.vendor.vendor,
  isSaving: state.customers.temporaryAddress.isSaving,
});

const mapDispatchToProps = {
  saveTemporaryAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryAddressModal);
