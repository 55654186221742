import { connect } from 'react-redux';
import { map } from 'lodash-es';
import { PureComponent } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { ALERT_STATUS_ACTIVE, ALERT_STATUS_TYPES } from 'src/fleet/constants/locationAndCityAlerts';
import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { CityAlertSetting, CityAlertSettings } from 'src/vendors/interfaces/CityAlertSettings';
import { DATE_RANGE_PICKER_3_7_30, TODAY_FORMATTED, THREE_DAYS_BEFORE_TODAY } from '../../../core/constants/weekdays';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { DateRangePicker, MultiSelect, PanelSearch, TypedField } from '../../../core/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { isCityAlertFeatureEnabled, isLocationAlertFeatureEnabled } from 'src/vendors/ducks/features';
import { LocationAlert } from 'src/vendors/interfaces/LocationAlert';
import store, { AppState } from '../../../store';
import translate from '../../../core/services/translate';

export const getActiveCityAlertTypes = (cityAlertSettings?: CityAlertSettings) => {
  const isCityAlertEnabled = isCityAlertFeatureEnabled(store.getState());

  const activeCityAlertSettings = cityAlertSettings
    ? cityAlertSettings.cityAlertTypes.filter((option: CityAlertSetting) => option.isActive)
    : [];

  const hasCityAlerts = activeCityAlertSettings.length > 0 && isCityAlertEnabled;
  return { activeCityAlertSettings, hasCityAlerts };
};

interface ComponentProps extends RouteComponentProps {
  cityAlertTypes?: CityAlertSetting[];
  isCityAlertEnabled?: boolean;
  isLocationAlertEnabled?: boolean;
  locationAlerts?: LocationAlert[];
}

interface ReduxProps extends ComponentProps {}

type LocationAndCityAlertsProps = {
  cityAlertTypeIds: number[];
  date: DateRangeOptionValue;
  locationAlertTypeIds: number[];
  searchTerm: string;
  statusTypeIds: number[];
};

type Props = ReduxProps & InjectedFormProps<LocationAndCityAlertsProps, RouteComponentProps>;

class LocationAndCityAlertsSearchForm extends PureComponent<Props> {
  render() {
    const { cityAlertTypes, handleSubmit, isCityAlertEnabled, isLocationAlertEnabled, locationAlerts } = this.props;

    const alertStatusTypeOptions = map(ALERT_STATUS_TYPES, alertStatus => ({
      label: alertStatus.name,
      value: alertStatus.id,
    }));

    const cityAlertOptions =
      cityAlertTypes?.map(({ cityAlertType }) => ({
        value: cityAlertType.id,
        label: cityAlertType.name,
      })) || [];

    const locationAlertOptions =
      locationAlerts?.map(({ id, title }) => ({
        value: id,
        label: title,
      })) || [];

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall no xSmall">
          <Grid>
            <GridColumn size="3/12">
              <TypedField name="searchTerm" component={PanelSearch} props={{ isClearable: true }} />
            </GridColumn>

            <GridColumn size="3/12">
              <TypedField
                name="date"
                component={DateRangePicker}
                props={{
                  hasMarginLeft: 'smaller',
                  options: DATE_RANGE_PICKER_3_7_30,
                  placeholder: translate('insights.select'),
                  maxInterval: { amount: 120, unit: 'days' },
                }}
              />
            </GridColumn>

            {isCityAlertEnabled && (
              <GridColumn size="3/12">
                <TypedField
                  name="cityAlertTypeIds"
                  component={MultiSelect}
                  props={{
                    placeholder: translate('vendors.cityAlerts.cityAlerts'),
                    options: cityAlertOptions,
                    isClearable: true,
                    labelAll: translate('vendors.cityAlerts.allCityAlerts'),
                  }}
                />
              </GridColumn>
            )}

            {isLocationAlertEnabled && (
              <GridColumn size="4/12">
                <TypedField
                  name="locationAlertTypeIds"
                  component={MultiSelect}
                  props={{
                    placeholder: translate('customers.locationAlerts'),
                    options: locationAlertOptions,
                    isSearchable: true,
                    isClearable: true,
                    labelAll: translate('vendors.cityAlerts.allLocationAlerts'),
                  }}
                />
              </GridColumn>
            )}

            <GridColumn size="2/12">
              <TypedField
                name="statusTypeIds"
                component={MultiSelect}
                props={{
                  placeholder: translate('common.status'),
                  options: alertStatusTypeOptions,
                }}
              />
            </GridColumn>

            <GridColumn align="right" marginLeft="auto">
              <Button id="location-city-alerts-route-button" color="primary">
                {translate('common.search')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const {
    startDate,
    endDate,
    searchTerm,
    statusTypeIds,
    cityAlertTypeIds,
    noCityAlertTypeSelected,
    noLocationAlertTypeSelected,
  } = getQueryParams(ownProps.location.search);

  const cityAlertSettings = state.vendors.cityAlertSettings?.cityAlertSettings;
  const cityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
  const cityAlertOptions = cityAlertTypes?.map((cityAlert: CityAlertSetting) => cityAlert.cityAlertType.id) || [];

  const locationAlerts = state.vendors.locationAlerts.locationAlerts;
  const selectedLocationAlertsIds = state.fleet.locationAndCityAlerts.selectedLocationAlertsIds;
  const locationAlertOptions = locationAlerts?.map((locationAlert: LocationAlert) => locationAlert.id) || [];

  return {
    initialValues: {
      date: {
        from: startDate || THREE_DAYS_BEFORE_TODAY,
        to: endDate || TODAY_FORMATTED,
      },
      searchTerm,
      statusTypeIds: statusTypeIds?.toString().split(',') || [ALERT_STATUS_ACTIVE],
      cityAlertTypeIds: cityAlertTypeIds?.length
        ? cityAlertTypeIds?.toString().split(',')
        : noCityAlertTypeSelected
        ? []
        : cityAlertOptions,
      locationAlertTypeIds: selectedLocationAlertsIds?.length
        ? selectedLocationAlertsIds
        : noLocationAlertTypeSelected
        ? []
        : locationAlertOptions,
    },
    cityAlertTypes,
    locationAlerts,
    isCityAlertEnabled: isCityAlertFeatureEnabled(state),
    isLocationAlertEnabled: isLocationAlertFeatureEnabled(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<LocationAndCityAlertsProps, ReduxProps>({
      form: 'locationAndCityAlertsForm',
      enableReinitialize: true,
    })(LocationAndCityAlertsSearchForm),
  ),
);
