import translate from '../../core/services/translate';

export const DRIVER = 1;
export const SUPERVISOR = 2;
export const DRIVER_SUPERVISOR = 3;

export const ROLE_TYPES = [
  { id: DRIVER, name: translate('common.driver') },
  { id: SUPERVISOR, name: translate('dashboard.supervisor') },
  { id: DRIVER_SUPERVISOR, name: translate('resources.driverSupervisor') },
];
