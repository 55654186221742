import { http } from '../../core/services/http';
import { LoadsDumped } from '../interfaces/LoadsDumped';

export const createLoadDumped = (routeId: number, loadDumped: LoadsDumped) =>
  http.post(`streetSweeperDisposalTicket`, { ...loadDumped, routeId }).then(response => response.data);

export const updateLoadDumped = (loadDumped: LoadsDumped, vendorId?: number) =>
  http
    .put(`streetSweeperDisposalTicket/${loadDumped.id}`, {
      ...loadDumped,
      streetSweeperDisposalTicketId: loadDumped.id,
      vendorId,
    })
    .then(response => response.data);

export const loadLoadDumped = (vendorId: number, loadDumpedId: number) =>
  http
    .get(`streetSweeperDisposalTicket/${loadDumpedId}`, {
      params: { vendorId, streetSweeperDisposalTicketId: loadDumpedId },
    })
    .then(response => response.data);

export const loadLoadsDumped = (vendorId: number, routeId: number) =>
  http.get(`streetSweeperDisposalTickets`, { params: { vendorId, routeId } }).then(response => response.data);

export const deleteLoadDumped = (loadDumpedId: number) => http.delete(`streetSweeperDisposalTicket/${loadDumpedId}`);

export const loadStreetSweeperUnitOfMeasureValues = () =>
  http.get('common/systemTypes/streetSweeperDisposalTicketUnitOfMeasureTypeValues').then(response => response.data);

export const loadStreetSweeperMaterialTypeValues = () =>
  http.get('common/systemTypes/streetSweeperDisposalTicketMaterialTypeValues').then(response => response.data);

export const uploadLoadsDumpedImage = async (file: File, routeId: number) => {
  const formData = new FormData();
  const dataObject = JSON.stringify([{ routeId: routeId, imageSourceTypeId: 1 }]);

  formData.append('Image', file);
  formData.append('data', dataObject);

  const response = await http.post('images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};
