import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { LastKnowLocation } from '../interfaces/LastKnowLocation';
import { loadLastKnownLocation as doLoadLastKnownLocation } from '../services/lastKnownLocation';

// Actions
const START_LOAD = 'settings/lastKnownLocation/START_LOAD';
const COMPLETE_LOAD = 'settings/lastKnownLocation/COMPLETE_LOAD';
const FAIL_LOAD = 'settings/lastKnownLocation/FAIL_LOAD';

interface State {
  isLoading: boolean;
  lastKnownLocation?: LastKnowLocation;
}

// Initial state
const initialState: State = {
  isLoading: false,
  lastKnownLocation: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          lastKnownLocation: action.lastKnownLocation,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          lastKnownLocation: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (lastKnownLocation: LastKnowLocation) => ({
  type: COMPLETE_LOAD,
  lastKnownLocation,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadLastKnownLocation = (deviceId: string, vendorId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadLastKnownLocationPromise = doLoadLastKnownLocation(deviceId, vendorId);

  loadLastKnownLocationPromise
    .then((lastKnownLocation: LastKnowLocation) => dispatch(completeLoad(lastKnownLocation)))
    .catch(() => dispatch(failLoad()));

  return loadLastKnownLocationPromise;
};
