import { useEffect } from 'react';

import { push, Push } from 'connected-react-router';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getServiceZonesFiltersPreferencesIds, getSupervisorsFiltersPreferencesIds } from 'src/common/utils/filters';
import { DuckAction, DuckFunction } from 'src/contracts/ducks';
import { PopoverWrapper } from 'src/core/components';
import { Popover, Text } from 'src/core/components/styled';
import { TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { DispatchBoardSourceForm } from 'src/routes/components/forms';
import { DispatchBoardSourceFormFilters } from 'src/routes/components/forms/DispatchBoardSourceForm';
import {
  DispatchBoardFilters,
  DispatchBoardJobMapCounter,
  DispatchBoardMapIcon,
  DispatchBoardMapText,
  DispatchBoardPanel,
  DispatchBoardPanelContent,
} from 'src/routes/components/styled';
import { ON_HOLD_ROUTE_ID, UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';
import JobTypes from 'src/routes/constants/jobTypes';
import { ROUTE_STATUSES } from 'src/routes/constants/routeStatuses';
import {
  clearSelectedRoutes,
  deleteDispatchBoardSourceRoute,
  deleteDispatchBoardSourceRouteJob,
  loadDispatchBoardSourceRouteJobs,
  loadDispatchBoardSourceRoutes,
  loadDispatchBoardTargetRouteJobs,
  loadDispatchBoardUnassignedJobs,
  loadDispatchBoardUnassignedJobsCount,
  moveDispatchBoardSourceRouteJobToPosition,
  openMap,
  openMapWithRoute,
  resetDispatchBoardSourceRouteJobs,
  setSourceDate,
} from 'src/routes/ducks/dispatchBoard';
import {
  loadDispatchBoardOnHoldJobs,
  loadDispatchBoardOnHoldJobsCount,
} from 'src/routes/ducks/dispatchBoard/dispatchBoardOnHoldJobs';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';
import dispatchBoardFilteredSourceRouteJobsSelector from 'src/routes/services/dispatchBoardFilteredSourceRouteJobsSelector';
import { getAllWithOptionNone } from 'src/routes/services/dispatchBoardGetAllWithOptionNone';
import { AppState } from 'src/store';
import { usePrevious } from 'src/utils/hooks';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import {
  loadBulkyCategoryTypes,
  loadBulkyItemScheduler,
  loadBulkyItemTypes,
  loadDispatchBoardMaterialTypes,
} from 'src/vendors/ducks';
import {
  isBulkyItemSchedulerFeatureEnabled,
  isMaterialTypesFeatureEnabled,
  isOnHoldBucketEnabled,
} from 'src/vendors/ducks/features';
import { DispatchBoardQueryParams } from '../DispatchBoardPage';
import DispatchBoardOnHoldJobsDetails from './DispatchBoardOnHoldJobsDetails';
import DispatchBoardRoute from './DispatchBoardRoute';
import DispatchBoardRouteDetails from './DispatchBoardRouteDetails';
import DispatchBoardRoutes from './DispatchBoardRoutes';
import DispatchBoardUnassignedJobs from './DispatchBoardUnassignedJobs';

const MAX_SELECTED_JOBS = 50000;

interface Props {
  isDeletingRoute: boolean;
  isLoading: boolean;
  isLoadingRouteJobs: boolean;
  isBulkyItemSchedulerEnabled: boolean;
  isMaterialTypesEnabled: boolean;
  jobsTransferring: boolean;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: any[],
    position?: number,
    type?: string,
  ) => void;
  onHoldBucketEnabled: boolean;
  onHoldJobsCount: number;
  openMap: DuckAction<typeof openMap>;
  push: Push;
  routeDetails: any;
  routeJobs: any[];
  selectedRoutes: any[];
  selectedRoutesJobsCount: number;
  serviceZonesFiltersPreferencesIds: number[];
  sourceRoutes: DispatchBoardSimpleRoute[];
  supervisorsFiltersPreferencesIds: number[];
  targetDate: Date | string;
  targetRouteId?: number;
  toggleJobTransferring: (value: any) => void;
  toggleRoute: (route: any) => void;
  toggleRouteEditorModal: (isOpen: boolean, isCreate?: boolean, isUnassignedEditor?: boolean) => void;
  unassignedJobsCount: any;
  unassignedProps: any;
  vehicleTypeId: number;
  vendorId: number;

  clearSelectedRoutes: DuckAction<typeof clearSelectedRoutes>;
  deleteDispatchBoardSourceRoute: DuckFunction<typeof deleteDispatchBoardSourceRoute>;
  deleteDispatchBoardSourceRouteJob: DuckFunction<typeof deleteDispatchBoardSourceRouteJob>;
  loadBulkyCategoryTypes: DuckFunction<typeof loadBulkyCategoryTypes>;
  loadBulkyItemScheduler: DuckFunction<typeof loadBulkyItemScheduler>;
  loadBulkyItemTypes: DuckFunction<typeof loadBulkyItemTypes>;
  loadDispatchBoardOnHoldJobs: DuckFunction<typeof loadDispatchBoardOnHoldJobs>;
  loadDispatchBoardOnHoldJobsCount: DuckFunction<typeof loadDispatchBoardOnHoldJobsCount>;
  loadDispatchBoardSourceRouteJobs: DuckFunction<typeof loadDispatchBoardSourceRouteJobs>;
  loadDispatchBoardSourceRoutes: DuckFunction<typeof loadDispatchBoardSourceRoutes>;
  loadDispatchBoardTargetRouteJobs: DuckFunction<typeof loadDispatchBoardTargetRouteJobs>;
  loadDispatchBoardUnassignedJobs: DuckFunction<typeof loadDispatchBoardUnassignedJobs>;
  loadDispatchBoardUnassignedJobsCount: DuckFunction<typeof loadDispatchBoardUnassignedJobsCount>;
  loadDispatchBoardMaterialTypes: DuckFunction<typeof loadDispatchBoardMaterialTypes>;
  moveDispatchBoardSourceRouteJobToPosition: DuckFunction<typeof moveDispatchBoardSourceRouteJobToPosition>;
  openMapWithRoute: DuckAction<typeof openMapWithRoute>;
  resetDispatchBoardSourceRouteJobs: DuckAction<typeof resetDispatchBoardSourceRouteJobs>;
  setSourceDate: DuckAction<typeof setSourceDate>;
}

const DispatchBoardSources = (props: Props) => {
  const {
    isBulkyItemSchedulerEnabled,
    isDeletingRoute,
    isLoading,
    isLoadingRouteJobs,
    isMaterialTypesEnabled,
    jobsTransferring,
    onHoldBucketEnabled,
    onHoldJobsCount,
    routeDetails,
    routeJobs,
    selectedRoutes,
    selectedRoutesJobsCount,
    serviceZonesFiltersPreferencesIds,
    sourceRoutes,
    supervisorsFiltersPreferencesIds,
    targetDate,
    targetRouteId,
    unassignedJobsCount,
    unassignedProps,
    unassignedProps: { refreshUnassignedJobs, refreshOnHoldJobs },
    vehicleTypeId,
    vendorId,

    clearSelectedRoutes,
    deleteDispatchBoardSourceRoute,
    deleteDispatchBoardSourceRouteJob,
    loadBulkyCategoryTypes,
    loadBulkyItemScheduler,
    loadBulkyItemTypes,
    loadDispatchBoardOnHoldJobs,
    loadDispatchBoardOnHoldJobsCount,
    loadDispatchBoardSourceRouteJobs,
    loadDispatchBoardSourceRoutes,
    loadDispatchBoardTargetRouteJobs,
    loadDispatchBoardUnassignedJobs,
    loadDispatchBoardUnassignedJobsCount,
    loadDispatchBoardMaterialTypes,
    moveDispatchBoardSourceRouteJobToPosition,
    onJobDrop,
    openMap,
    openMapWithRoute,
    push,
    resetDispatchBoardSourceRouteJobs,
    setSourceDate,
    toggleJobTransferring,
    toggleRoute,
    toggleRouteEditorModal,
  } = props;

  const { pathname, search } = useLocation<DispatchBoardQueryParams>();

  const allServiceZones = useSelector(state => getAllWithOptionNone(state.routes.serviceZones.serviceZones || []));
  const allSupervisors = useSelector(state => getAllWithOptionNone(state.routes.supervisors.supervisors || []));

  const {
    date = TODAY_FORMATTED,
    searchTerm = '',
    sourceRouteId: _sourceRouteId,
  } = getQueryParams<DispatchBoardQueryParams>(search);
  const {
    routeStatuses = map(ROUTE_STATUSES, 'id'),
    serviceZones = serviceZonesFiltersPreferencesIds,
    supervisors = supervisorsFiltersPreferencesIds,
    groupIds,
  } = getQueryParams<DispatchBoardQueryParams>(search, { mapTypeIdsToArray: true });

  const sourceRouteId = _sourceRouteId ? Number(_sourceRouteId) : undefined;

  const filters = {
    date,
    routeStatuses,
    searchTerm,
    serviceZones,
    supervisors,
    groupIds,
  };

  const updateQueryParams = ({
    vehicleTypeId,
    routeStatuses,
    serviceZones,
    supervisors,
    ...paramsToUpdate
  }: Partial<DispatchBoardQueryParams>) => {
    push(
      createUrl(pathname, search, {
        ...paramsToUpdate,
        routeStatuses:
          routeStatuses && ROUTE_STATUSES.length !== routeStatuses.length ? routeStatuses.toString() : undefined,
        serviceZones:
          serviceZones && allServiceZones?.length !== serviceZones.length ? serviceZones.toString() : undefined,
        supervisors: supervisors && allSupervisors?.length !== supervisors.length ? supervisors.toString() : undefined,
      }),
    );
  };

  const handleLoadRoutesAndUnassignedJobs = (f: DispatchBoardSourceFormFilters = filters) => {
    loadDispatchBoardSourceRoutes({
      vendorId,
      vehicleTypeId,
      date: f.date,
      routeStatuses: ROUTE_STATUSES.length === f.routeStatuses?.length ? [] : f.routeStatuses,
      searchTerm: f.searchTerm,
      serviceZones: allServiceZones?.length === f.serviceZones?.length ? [] : f.serviceZones,
      supervisors: allSupervisors?.length === f.supervisors?.length ? [] : f.supervisors,
      groupIds: f.groupIds,
    });
    loadDispatchBoardUnassignedJobsCount(vendorId, vehicleTypeId, f.searchTerm);

    if (vehicleTypeId === DELIVERY_UTILITY_ID) {
      loadDispatchBoardOnHoldJobsCount(vendorId, date, routeStatuses, f.searchTerm, f.serviceZones, f.supervisors);
    }

    if (sourceRouteId !== undefined) {
      if (sourceRouteId === UNASSIGNED_ROUTE_ID) {
        loadDispatchBoardUnassignedJobs(vendorId, vehicleTypeId, f.searchTerm);
      } else if (sourceRouteId === ON_HOLD_ROUTE_ID) {
        loadDispatchBoardOnHoldJobs(vendorId, date, [], f.searchTerm);
      } else {
        loadDispatchBoardSourceRouteJobs(sourceRouteId, f.searchTerm);
      }
    }
  };

  const openRouteDetails = (route: DispatchBoardSimpleRoute) => {
    updateQueryParams({ sourceRouteId: route.id });
  };

  const closeRouteDetails = () => {
    updateQueryParams({ sourceRouteId: undefined });
  };

  const openUnassignedJobs = () => {
    updateQueryParams({ sourceRouteId: UNASSIGNED_ROUTE_ID });
  };

  const onOnHoldClick = () => {
    updateQueryParams({ sourceRouteId: ON_HOLD_ROUTE_ID });
  };
  const handleClearSelectedRoutes = () => {
    if (selectedRoutes.length) clearSelectedRoutes();
  };

  useEffect(() => {
    if (vendorId != null && vehicleTypeId != null) handleLoadRoutesAndUnassignedJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId, vehicleTypeId]);

  const prevVehicleTypeId: number = usePrevious<number>(vehicleTypeId);
  const prevSourceRoutesLength: number = usePrevious<number>(sourceRoutes.length);

  useEffect(() => {
    if (!!prevVehicleTypeId && vehicleTypeId !== prevVehicleTypeId) {
      handleClearSelectedRoutes();
      closeRouteDetails();
      handleLoadRoutesAndUnassignedJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypeId]);

  useEffect(() => {
    if (
      sourceRouteId &&
      sourceRouteId > 0 &&
      !!prevSourceRoutesLength &&
      !sourceRoutes.find(sR => sR.id === sourceRouteId)
    ) {
      handleClearSelectedRoutes();
      closeRouteDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceRouteId, sourceRoutes]);

  useEffect(() => {
    if (vehicleTypeId) {
      if (isMaterialTypesEnabled) {
        loadDispatchBoardMaterialTypes(vendorId, vehicleTypeId);
      } else if (isBulkyItemSchedulerEnabled && vehicleTypeId === DELIVERY_UTILITY_ID) {
        loadBulkyCategoryTypes();
        loadBulkyItemTypes();
        loadBulkyItemScheduler(vendorId);
      }
    }
  }, [
    isBulkyItemSchedulerEnabled,
    isMaterialTypesEnabled,
    loadBulkyCategoryTypes,
    loadBulkyItemScheduler,
    loadBulkyItemTypes,
    loadDispatchBoardMaterialTypes,
    vehicleTypeId,
    vendorId,
  ]);

  const onSourceFormSubmit = (f: DispatchBoardSourceFormFilters) => {
    handleLoadRoutesAndUnassignedJobs(f);
    setSourceDate(f.date);
    updateQueryParams(f);
  };

  const canOpenMap = selectedRoutesJobsCount > 0;

  const selectedSourceRoute = sourceRoutes.find(sR => sR.id === sourceRouteId);

  return (
    <DispatchBoardPanel isLoading={isLoading}>
      <DispatchBoardPanelContent
        isHidden={!!sourceRouteId || sourceRouteId === UNASSIGNED_ROUTE_ID || sourceRouteId === ON_HOLD_ROUTE_ID}
      >
        <DispatchBoardFilters>
          <DispatchBoardSourceForm initialValues={filters} onSubmit={onSourceFormSubmit}>
            <PopoverWrapper
              triggerButton={
                <DispatchBoardJobMapCounter
                  id="view-jobs-button"
                  isActive={canOpenMap}
                  onClick={(canOpenMap && selectedRoutesJobsCount <= MAX_SELECTED_JOBS && openMap) || undefined}
                >
                  {canOpenMap && (
                    <DispatchBoardMapText disabled={selectedRoutesJobsCount > MAX_SELECTED_JOBS}>
                      {translate('routes.numberOfJobsOnMap', { jobCount: selectedRoutesJobsCount })}
                    </DispatchBoardMapText>
                  )}
                  <DispatchBoardMapIcon icon="routes" disabled={selectedRoutesJobsCount > MAX_SELECTED_JOBS} />
                </DispatchBoardJobMapCounter>
              }
              popoverContent={
                selectedRoutesJobsCount > MAX_SELECTED_JOBS && (
                  <Popover color="primary">
                    <Text block weight="medium" margin="xxSmall no xxSmall">
                      {translate('routes.alertMessages.jobsMoreThan', { MAX_SELECTED_JOBS })}
                    </Text>
                  </Popover>
                )
              }
              size="large"
            />
          </DispatchBoardSourceForm>
        </DispatchBoardFilters>
        {unassignedJobsCount.totalCount > 0 && (
          <DispatchBoardRoute
            isSelected={!!selectedRoutes.find(sr => sr.id === UNASSIGNED_ROUTE_ID)}
            onJobDrop={onJobDrop}
            onRouteClick={openUnassignedJobs}
            onRouteToggle={toggleRoute}
            unassignedJobsCount={unassignedJobsCount}
            type={JobTypes.unassigned}
          />
        )}

        {onHoldBucketEnabled && vehicleTypeId === DELIVERY_UTILITY_ID && onHoldJobsCount > 0 && (
          <DispatchBoardRoute
            isSelected={!!selectedRoutes.find(sr => sr.id === ON_HOLD_ROUTE_ID)}
            onJobDrop={onJobDrop}
            onRouteClick={openUnassignedJobs}
            onOnHoldClick={onOnHoldClick}
            onRouteToggle={toggleRoute}
            unassignedJobsCount={{ totalCount: onHoldJobsCount }}
            type={JobTypes.onHold}
          />
        )}

        {!!sourceRoutes.length && (
          <DispatchBoardRoutes
            hasUnassignedJobs={unassignedJobsCount.totalCount > 0}
            onJobDrop={onJobDrop}
            onRouteClick={openRouteDetails}
            onRouteToggle={toggleRoute}
            routes={sourceRoutes}
            selectedRoutes={selectedRoutes}
            type={JobTypes.source}
          />
        )}
      </DispatchBoardPanelContent>

      {sourceRouteId ? (
        sourceRouteId !== UNASSIGNED_ROUTE_ID && selectedSourceRoute ? (
          <DispatchBoardRouteDetails
            deleteJob={deleteDispatchBoardSourceRouteJob}
            deleteRoute={deleteDispatchBoardSourceRoute}
            filteredJobsSelector={dispatchBoardFilteredSourceRouteJobsSelector as any}
            isDeletingRoute={isDeletingRoute}
            isLoadingRouteJobs={isLoadingRouteJobs}
            jobsTransferring={jobsTransferring}
            loadOppositeRouteJobs={loadDispatchBoardTargetRouteJobs}
            loadRouteJobs={loadDispatchBoardSourceRouteJobs}
            loadRoutesAndUnassignedJobs={handleLoadRoutesAndUnassignedJobs}
            moveJobToPosition={moveDispatchBoardSourceRouteJobToPosition}
            onClose={closeRouteDetails}
            onJobDrop={onJobDrop}
            openMapWithRoute={openMapWithRoute}
            refreshUnassignedJobs={refreshUnassignedJobs}
            refreshOnHoldJobs={refreshOnHoldJobs}
            resetRouteJobs={resetDispatchBoardSourceRouteJobs}
            route={selectedSourceRoute}
            routeDetails={routeDetails}
            routeJobs={routeJobs}
            routeSearchTerm={searchTerm}
            targetDate={targetDate}
            targetRouteId={targetRouteId}
            toggleJobTransferring={toggleJobTransferring}
            type={JobTypes.source}
            vehicleTypeId={vehicleTypeId}
          />
        ) : sourceRouteId === UNASSIGNED_ROUTE_ID ? (
          <DispatchBoardUnassignedJobs
            jobsTransferring={jobsTransferring}
            onClose={closeRouteDetails}
            onJobDrop={onJobDrop}
            toggleJobTransferring={toggleJobTransferring}
            toggleRouteEditorModal={toggleRouteEditorModal}
            unassignedJobsCount={unassignedJobsCount.totalCount}
            vehicleTypeId={vehicleTypeId}
            vendorId={vendorId}
            {...unassignedProps}
          />
        ) : sourceRouteId === ON_HOLD_ROUTE_ID ? (
          <DispatchBoardOnHoldJobsDetails
            jobsTransferring={jobsTransferring}
            onClose={closeRouteDetails}
            onJobDrop={onJobDrop}
            toggleJobTransferring={toggleJobTransferring}
            vendorId={vendorId}
            filters={filters}
          />
        ) : null
      ) : null}
    </DispatchBoardPanel>
  );
};

const selectedRoutesJobsCount = (
  selectedRoutes: DispatchBoardSimpleRoute[],
  sourceRoutes: DispatchBoardSimpleRoute[],
  unassignedJobsTotalCount: number,
  onHoldJobsCount: number,
) => {
  let assignedJobCount = selectedRoutes
    .filter(r => !!sourceRoutes.find(s => s.id === r.id))
    .reduce((total, route) => total + route.totalNumberOfJobs, 0);

  if (selectedRoutes.find(s => s.id === UNASSIGNED_ROUTE_ID)) {
    assignedJobCount = assignedJobCount + unassignedJobsTotalCount;
  }

  if (selectedRoutes.find(s => s.id === ON_HOLD_ROUTE_ID)) {
    assignedJobCount = assignedJobCount + onHoldJobsCount;
  }

  return assignedJobCount;
};

const mapStateToProps = (state: AppState) => {
  const {
    routes: { dispatchBoard },
  } = state;
  const { filters } = state.common.filters;

  return {
    isDeletingRoute: dispatchBoard.sourceRoutes.isDeletingRoute,
    isLoadingRouteJobs: (dispatchBoard.sourceRouteJobs as any).isLoading,
    isBulkyItemSchedulerEnabled: isBulkyItemSchedulerFeatureEnabled(state),
    isMaterialTypesEnabled: isMaterialTypesFeatureEnabled(state),
    routeDetails: (dispatchBoard.sourceRouteJobs as any).routeDetails,
    routeJobs: (dispatchBoard.sourceRouteJobs as any).routeJobs,
    selectedRoutesJobsCount: selectedRoutesJobsCount(
      dispatchBoard.map.selectedRoutes,
      dispatchBoard.sourceRoutes.sourceRoutes,
      dispatchBoard.unassignedJobs.unassignedJobsCount.totalCount,
      dispatchBoard.onHoldJobs.onHoldJobsCount,
    ),
    onHoldBucketEnabled: isOnHoldBucketEnabled(state),
    onHoldJobsCount: dispatchBoard.onHoldJobs.onHoldJobsCount,
    selectedRoutes: dispatchBoard.map.selectedRoutes,
    serviceZonesFiltersPreferencesIds: getServiceZonesFiltersPreferencesIds(filters),
    sourceRoutes: dispatchBoard.sourceRoutes.sourceRoutes,
    supervisorsFiltersPreferencesIds: getSupervisorsFiltersPreferencesIds(filters),
    targetDate: dispatchBoard.sourceRoutes.selectedDate,
    targetRouteId: dispatchBoard.targetRouteJobs.routeId,
    unassignedJobsCount: dispatchBoard.unassignedJobs.unassignedJobsCount,
  };
};

const mapDispatchToProps = {
  clearSelectedRoutes,
  deleteDispatchBoardSourceRoute,
  deleteDispatchBoardSourceRouteJob,
  loadBulkyCategoryTypes,
  loadBulkyItemScheduler,
  loadBulkyItemTypes,
  loadDispatchBoardOnHoldJobs,
  loadDispatchBoardOnHoldJobsCount,
  loadDispatchBoardSourceRouteJobs,
  loadDispatchBoardSourceRoutes,
  loadDispatchBoardTargetRouteJobs,
  loadDispatchBoardUnassignedJobs,
  loadDispatchBoardUnassignedJobsCount,
  loadDispatchBoardMaterialTypes,
  moveDispatchBoardSourceRouteJobToPosition,
  openMap,
  openMapWithRoute,
  push,
  resetDispatchBoardSourceRouteJobs,
  setSourceDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchBoardSources);
