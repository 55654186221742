import moment from 'moment';
import { useState } from 'react';
import Icon from 'src/core/components/Icon';
import { Box } from 'src/core/components/styled/Box';
import { Button } from 'src/core/components/styled/Button';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  RouteSequenceSummaryContainer,
  RouteSequenceSummaryInfoContainer,
  RouteSequenceSummaryLabels,
  RouteSequenceSummaryTitleContainer,
} from '../../styled/RouteMap';

export const RouteSequenceSummary: React.FC<{ routeTemplate?: boolean }> = ({ routeTemplate }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const routeMaxRouteTime = useSelector(s => s.routes.routeSequence.maxRouteTime);
  const routeEstimatedRouteTime = useSelector(s => s.routes.routeSequence.totalRouteTime);
  const routeSequence = useSelector(s => s.routes.routeSequence.routeSequence);

  const routeTemplateMaxRouteTime = useSelector(s => s.routes.routeSequence.maxRouteTime);
  const routeTemplateTotalRouteTime = useSelector(s => s.routes.routeSequence.totalRouteTime);
  const routeTemplateSequence = useSelector(s => s.routes.routeSequence.routeSequence);

  const maxRouteTime = routeTemplate ? routeTemplateMaxRouteTime : routeMaxRouteTime;
  const estimatedRouteTime = routeTemplate ? routeTemplateTotalRouteTime : routeEstimatedRouteTime;
  const sequence = routeTemplate ? routeTemplateSequence : routeSequence;

  const numberOfStops = sequence?.length || 0;

  return (
    <RouteSequenceSummaryContainer>
      <Button onClick={() => setIsExpanded(!isExpanded)} text fullWidth>
        <Box display="flex" justifyContent="space-between">
          <RouteSequenceSummaryTitleContainer>
            {translate('routes.routeSequenceSummary')}
          </RouteSequenceSummaryTitleContainer>
          <Icon width="12px" icon={!isExpanded ? 'arrowDownFill' : 'arrowUpFill'} />
        </Box>
      </Button>
      {isExpanded && (
        <RouteSequenceSummaryInfoContainer>
          <RouteSequenceSummaryLabels>
            <div>{translate('routes.routeSequenceMaxRouteTime')}</div>
            <div>{translate('routes.routeSequenceRequestNumberOfStops')}</div>
            <div style={{ marginTop: '24px' }}>{translate('routes.routeSequenceEstimatedRouteTime')}</div>
            <div>{translate('routes.routeSequenceSequencedNumberOfStops')}</div>
          </RouteSequenceSummaryLabels>
          <RouteSequenceSummaryLabels>
            <div>{moment.utc(moment.duration(maxRouteTime, 'h').asMilliseconds()).format('HH:mm')}</div>
            <div>{numberOfStops}</div>
            <div style={{ marginTop: '24px' }}>
              {moment.utc(moment.duration(estimatedRouteTime, 'h').asMilliseconds()).format('HH:mm')}
            </div>
            <div>{numberOfStops}</div>
          </RouteSequenceSummaryLabels>
        </RouteSequenceSummaryInfoContainer>
      )}
    </RouteSequenceSummaryContainer>
  );
};
