import { map } from 'lodash-es';
import { CustomerLocation } from '../interfaces/Customers';

const transformSearchCustomerLocations = (customerLocations: CustomerLocation[]) =>
  map(customerLocations, ({ id, name }) => ({
    label: name,
    value: id,
  }));

export default transformSearchCustomerLocations;
