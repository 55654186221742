import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';

import TicketConfirmationModal from './TicketConfirmationModal';

interface Props {
  closeModal: () => void;
  values: any;
}

export default function TicketConfirmationModalResolver(props: Props) {
  const { closeModal, values } = props;

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      successComponent={TicketConfirmationModal}
      successProps={{ closeModal, values }}
    />
  );
}
