import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import { http } from 'src/core/services/http';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID } from 'src/fleet/constants';

export const loadStreetsImportUploadedFilesStatus = () =>
  http.get('/streetSegmentImport/list', { params: {} }).then(response => response.data);

export const downloadSampleStreetsTemplate = (vehicleTypeId: number) =>
  downloadExcelFile(
    `/streetSegmentImport/downloadTemplate?vehicleTypeId=${vehicleTypeId}`,
    translate(
      vehicleTypeId === SNOW_PLOW_ID
        ? 'customers.streets.streetsSnowDataSampleFile'
        : 'customers.streets.streetsStreetSweeperDataSampleFile',
    ),
  );

export const uploadStreetsImportFile = (
  fileData: File,
  vehicleTypeId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vehicleTypeId }));

  return http
    .post('/streetSegmentImport/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};
