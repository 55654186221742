import { isEmpty, includes } from 'lodash-es';

import { DEFAULT_TIME } from '../../core/constants';
import { http } from '../../core/services/http';
import { ISSUE_REPORTED, COMPLETED, FLAGGED } from '../../routes/constants';
import { JobStatus } from '../interfaces/DispatchBoardRouteJob';

export const loadExceptions = (vendorId: number, vehicleTypeId?: number, includeSubtypes = true, showActive = true) =>
  http
    .get(`/vendors/${vendorId}/pickupExceptionTypes`, { params: { vehicleTypeId, includeSubtypes, showActive } })
    .then(response => response.data);

export const saveJobStatus = (data: JobStatus, routeId: number, routeLocationId: number) => {
  const formData = new FormData();
  const formStatusId = {
    statusId: data.jobStatus,
  };
  const formStatusExceptions =
    data.jobStatus === ISSUE_REPORTED
      ? {
          pickupExceptions: [
            {
              pickupExceptionType: { id: data.exception },
              pickupExceptionSubType: { id: data.reason },
              description: data.notes,
              numericValue: data.numericValue,
              elapsedTime: data.elapsedTime !== DEFAULT_TIME ? data.elapsedTime : null,
            },
          ],
        }
      : undefined;
  if (formStatusExceptions) {
    const formStatusExceptionsSubType = formStatusExceptions.pickupExceptions[0].pickupExceptionSubType as any;
    Object.keys(formStatusExceptionsSubType).forEach(
      key => formStatusExceptionsSubType[key] == null && delete formStatusExceptionsSubType[key],
    );

    const formStatusExceptionsObject = formStatusExceptions.pickupExceptions[0] as any;
    Object.keys(formStatusExceptionsObject).forEach(
      key =>
        (formStatusExceptionsObject[key] == null || isEmpty(formStatusExceptionsObject[key])) &&
        delete formStatusExceptionsObject[key],
    );
  }
  const dataObject = JSON.stringify({ ...formStatusId, ...formStatusExceptions });

  if (data.file) {
    Array.from(data.file).forEach(f => formData.append('files', f));
  }
  formData.append('jsonObject', dataObject);

  return http.post(`routes/${routeId}/routeLocations/${routeLocationId}/status`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const saveWasteAuditJobStatus = (data: JobStatus, routeId: number, routeLocationId: number) => {
  const formData = new FormData();
  const formStatusId = {
    routeLocationPickupStatusTypeId: data.jobStatus,
  };

  const formStatusExceptions = (
    data.jobStatus === COMPLETED
      ? {
          wasteAuditExceptionTypeIds:
            !includes(data.wasteAuditExceptions[0], 'status') && !data.wasteAuditParticipation
              ? data.wasteAuditExceptions
              : null,
          wasteAuditLocationStatusTypeId:
            includes(data.wasteAuditExceptions[0], 'status') || data.wasteAuditParticipation
              ? data.wasteAuditParticipation || parseFloat(data.wasteAuditExceptions[0].substr(-1))
              : FLAGGED,
          description: data.notes,
        }
      : undefined
  ) as any;
  if (formStatusExceptions) {
    Object.keys(formStatusExceptions).forEach(
      key => formStatusExceptions[key] == null && delete formStatusExceptions[key],
    );
  }
  const dataObject = JSON.stringify({ ...formStatusId, ...formStatusExceptions });

  if (data.file) {
    formData.append('file', data.file[0]);
  }
  formData.append('jsonObject', dataObject);

  return http.post(`routes/${routeId}/routeLocations/${routeLocationId}/wasteAuditStatus`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
