import React from 'react';
import { LoginPageWrapper, SiteWidth, RubiconHorizontalLogo } from 'src/account/components/styled/LoginPage';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { getTextWithAccentSections } from '../services';
import { MaintenancePageMessageContainer } from '../styled/MaintenancePage';

export const MaintenancePage: React.FC = () => {
  return (
    <LoginPageWrapper>
      <SiteWidth>
        <RubiconHorizontalLogo />
        <MaintenancePageMessageContainer>
          <Text weight="medium" margin="small no" block size="xxLarge">
            {getTextWithAccentSections(translate('maintenancePage.title'))}
          </Text>
          <Text block size="xLarge">
            {getTextWithAccentSections(translate('maintenancePage.description'))}
          </Text>
        </MaintenancePageMessageContainer>
      </SiteWidth>
    </LoginPageWrapper>
  );
};
