import { createSelector } from 'reselect';
import { identity } from 'lodash-es';
import moment from 'moment';

import { multiWordAndSearch } from '../../core/services/search';
import { FilteredJobs } from '../../routes/interfaces/DispatchBoardRoute';

function filterEquipment(jobs: FilteredJobs[], equipment?: string) {
  if (equipment == null) return jobs;
  return jobs.filter(({ containerTypeName }: any) => containerTypeName && containerTypeName === equipment);
}

function filterMaterialType(jobs: FilteredJobs[], materialType?: string) {
  if (materialType == null) return jobs;
  return jobs.filter(
    ({ wasteMaterialTypeName }: any) => wasteMaterialTypeName && wasteMaterialTypeName === materialType,
  );
}

function filterOnDate(jobs: FilteredJobs[], filterDate?: Date | string) {
  if (filterDate == null) return jobs;
  const formattedFilterDate = moment(filterDate, 'MM/DD/YYYY');
  return jobs.filter(({ date: jobDate }: any) => {
    const formattedJobDate = moment(jobDate);
    return formattedJobDate.isSameOrBefore(formattedFilterDate);
  });
}

function filterPickupType(jobs: FilteredJobs[], pickupType?: string) {
  if (pickupType == null) return jobs;
  return jobs.filter(({ pickupTypeId }: any) => pickupTypeId && pickupTypeId === pickupType);
}

function filterReasonCodeType(jobs: FilteredJobs[], reasonCodeType?: string) {
  if (reasonCodeType == null) return jobs;
  return jobs.filter(({ reasonCodeTypeId }: any) => reasonCodeTypeId && reasonCodeTypeId === reasonCodeType);
}

function filterSearchTerm(jobs: FilteredJobs[], searchTerm?: string) {
  if (searchTerm == null) return jobs;
  return jobs.filter(
    ({
      customerName,
      locationName,
      customerAccountNumber,
      locationAccountNumber,
      serviceContractAccountNumber,
      workOrderNumber,
      locationAddress,
    }: any) =>
      !searchTerm ||
      multiWordAndSearch(customerName, searchTerm) ||
      multiWordAndSearch(locationName, searchTerm) ||
      multiWordAndSearch(customerAccountNumber, searchTerm) ||
      multiWordAndSearch(locationAccountNumber, searchTerm) ||
      multiWordAndSearch(serviceContractAccountNumber, searchTerm) ||
      multiWordAndSearch(workOrderNumber, searchTerm) ||
      multiWordAndSearch(locationAddress.formattedAddress, searchTerm),
  );
}

const getDispatchBoardRouteBuilderFilteredUnassignedJobs = ({
  unassignedJobs,
  date,
  equipment,
  materialType,
  pickupType,
  reasonCodeType,
  searchTerm,
}: FilteredJobs) => {
  const dateFilterResult = filterOnDate(unassignedJobs as any, date);
  const equipmentFilterResults = filterEquipment(dateFilterResult, equipment);
  const materialTypeFilterResults = filterMaterialType(equipmentFilterResults, materialType);
  const pickupTypeFilterResults = filterPickupType(materialTypeFilterResults, pickupType);
  const reasonCodeTypeFilterResults = filterReasonCodeType(pickupTypeFilterResults, reasonCodeType);
  const searchTermFilterResults = filterSearchTerm(reasonCodeTypeFilterResults, searchTerm);
  return searchTermFilterResults;
};

const dispatchBoardRouteBuilderFilteredUnassignedJobsSelector = createSelector(
  getDispatchBoardRouteBuilderFilteredUnassignedJobs,
  identity,
);

export default dispatchBoardRouteBuilderFilteredUnassignedJobsSelector;
