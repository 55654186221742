import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { AdhocServiceHistoryTableRow } from './AdhocServiceHistoryTableRow';
import AdhocServiceHistoryForm from '../../forms/AdhocServiceHistoryForm';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { loadAdhocServiceHistory } from '../../../ducks/adhocServiceHistory';
import { Message, Panel, PanelSection, PanelSectionGroup } from '../../../../core/components/styled';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
  PageBackButton,
  PageBackButtonIcon,
} from '../../../../common/components/styled';
import { Pagination, Table } from '../../../../core/components';
import translate from '../../../../core/services/translate';

const adhocServiceHistoryTableCells = [
  {
    name: 'locationName',
    label: `${translate('common.address')}`,
    width: '20%',
    sortable: true,
  },
  { name: 'serviceName', label: translate('common.service'), width: '15%', sortable: true },
  { name: 'serviceDate', label: translate('common.date'), width: '11%', sortable: true },
  { name: 'pickupStatusType', label: translate('common.pickupStatus'), width: '12%', sortable: true },
  {
    name: 'routeName',
    label: translate('common.route'),
    width: '14%',
    sortable: true,
  },
  { name: 'pickupType', label: translate('routes.pickupType'), width: '10%', padding: 'defaultCellVertical no' },
  {
    name: 'workOrderNumber',
    label: translate('autoDispatch.workOrder'),
    width: '10%',
    padding: 'defaultCellVertical no',
  },
  {
    name: 'events',
    label: translate('common.events'),
    width: '8%',
    align: 'right',
  },
];

interface Props extends RouteComponentProps {}
interface AdhocServiceHistoryFormValues {
  locationId: string;
  dateRange: { from: string; to: string };
  pickupStatusTypeIds: string[];
  workOrderNumber: string;
}

const AdhocServiceHistoryPage: React.FC<Props> = ({ location: { pathname, search } }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const adhocServiceHistory = useSelector((state: AppState) => state.customers.adhocServiceHistory.adhocServiceHistory);
  const total = useSelector((state: AppState) => state.customers.adhocServiceHistory.total);
  const {
    startDate: initialStartDate = moment().subtract(7, 'days').format('MM/DD/YYYY'),
    endDate: initialEndDate = moment().format('MM/DD/YYYY'),
  } = getQueryParams(search);


  const adhocHistoryFormInitialValues: Partial<AdhocServiceHistoryFormValues> = {
    dateRange: {
      from: initialStartDate,
      to: initialEndDate,
    },
  };

  const onAdhocHistoryCriteriaChange = ({
    dateRange: { from: fromDate, to: toDate },
    locationId,
    pickupStatusTypeIds,
    workOrderNumber,
  }: AdhocServiceHistoryFormValues) => {
    dispatch(
      push(
        createUrl(pathname, search, {
          startDate: fromDate,
          endDate: toDate,
          locationId,
          pickupStatusTypeIds: pickupStatusTypeIds && pickupStatusTypeIds.toString(),
          workOrderNumber,
        }),
      ),
    );
 
    loadAdhocServiceHistory(
      vendorId,
      locationId,
      pickupStatusTypeIds?.toString(),
      fromDate,
      toDate,
      workOrderNumber,
    )(dispatch);
  };
  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButton to={`/customers/customers`}>
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle>{translate('customers.adhocServiceHistory')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      <Panel>
        <PanelSectionGroup isLoading={false}>
          <AdhocServiceHistoryForm
            onChange={onAdhocHistoryCriteriaChange}
            initialValues={adhocHistoryFormInitialValues}
          />
          <PanelSection>
            {adhocServiceHistory.length ? (
              <Table
                cells={adhocServiceHistoryTableCells}
                rows={adhocServiceHistory}
                rowComponent={AdhocServiceHistoryTableRow}
              />
            ) : (
              <Message padding="sMedium">{translate('customers.noAdhocHistory')}</Message>
            )}
          </PanelSection>
          {!!total && <Pagination totalResults={total} />}
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default withRouter(AdhocServiceHistoryPage);
