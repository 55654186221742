import { camelize } from 'humps';
import { map, pick, upperFirst, size } from 'lodash-es';

import { BULK, PORTABLE_TOILET, PORTABLE_RESTROOM_TRAILER } from '../../common/constants';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const getEquipmentSize = (equipmentSize: string, equipmentName: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string, serviceTypeName: string, equipmentName: string) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${upperFirst(
        camelize(equipmentSize?.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${equipmentSize?.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

export const getServiceName = ({
  equipmentSizeName,
  equipmentSizeTechnicalName,
  equipmentTypeTechnicalName,
  equipmentTypeName,
  wasteMaterialTypeTechnicalName,
  serviceContractBinDetails,
  serviceTypeName,
}: any) => {
  const equipmentTypeShortCode = translate(
    createTranslationKey(equipmentTypeTechnicalName, 'common.equipmentTypeShortCodes'),
  );
  const equipmentSizeShortCode = translate(
    getEquipmentSizeTranslationKey(equipmentSizeTechnicalName, serviceTypeName, equipmentTypeName),
    {
      size: getEquipmentSize(equipmentSizeName, equipmentSizeTechnicalName),
    },
  );
  const wasteMaterialTypeName = translate(createTranslationKey(wasteMaterialTypeTechnicalName, 'common.wasteTypes'));
  const containerNumber =
    !!size(serviceContractBinDetails) && serviceContractBinDetails[0].binNumber
      ? ` (${serviceContractBinDetails[0].binNumber})`
      : '';

  return `${equipmentSizeShortCode} - ${equipmentTypeShortCode} - ${wasteMaterialTypeName}${containerNumber}`;
};

export const transformSearchCustomerOnlyWithLocations = (customers: any) => {
  const result = [] as any[];
  map(customers, customer => {
    const label = `${customer.name}`;
    const options = map(customer.locations, location => ({
      id: customer.id,
      label: location.address.line1,
      value: {
        customer: pick(customer, 'id', 'name'),
        location: pick(location, 'id', 'name', 'address'),
      },
    }));
    result.push({ label, options });
  });
  return result;
};

export const transformLoadCustomerLocations = (customers: any) => {
  const result = [] as any[];
  map(customers, customer => {
    map(customer.locations, location => {
      const label = `${customer.name} > ${location.name} > ${location.address.streetNumber} ${location.address.street}`;
      const options = map(location.serviceContracts, serviceContract => ({
        id: customer.id,
        label: getServiceName(serviceContract),
        value: {
          accountStatusId: serviceContract.routeLocationAccountTypeId,
          customer: pick(customer, 'id', 'name'),
          location: pick(location, 'id', 'name', 'address'),
          scheduledDays: serviceContract.scheduledDays,
          service: {
            id: serviceContract.id,
            name: getServiceName(serviceContract),
            equipmentTypeTechnicalName: serviceContract.equipmentTypeTechnicalName,
            equipmentSizeTechnicalName: serviceContract.equipmentSizeTechnicalName,
            numberOfContainers: serviceContract.numberOfContainers,
            wasteMaterialTypeTechnicalName: serviceContract.wasteMaterialTypeTechnicalName,
            serviceContractBinDetails: serviceContract.serviceContractBinDetails,
            serviceContractAccountStatusTypeIdAtDate: serviceContract.serviceContractAccountStatusTypeIdAtDate,
          },
        },
      }));
      result.push({ label, options });
    });
  });
  return result;
};

export const transformLoadCustomers = (customers: any) => {
  const result = [];
  const label = translate('customers.customers');
  const options = map(customers, customer => ({
    id: customer.id,
    label: `${customer.name}`,
    value: {
      id: customer.id,
      name: customer.name,
      status: customer.status,
      locations: map(customer.locations, location => ({
        id: location.id,
        name: location.name,
        address: location.address,
        serviceContracts: map(location.serviceContracts, serviceContract => ({
          id: serviceContract.id,
          name: getServiceName(serviceContract),
          wasteMaterialTypeName: serviceContract.wasteMaterialTypeName,
          wasteMaterialTypeTechnicalName: serviceContract.wasteMaterialTypeTechnicalName,
          equipmentTypeName: serviceContract.equipmentTypeName,
          equipmentTypeTechnicalName: serviceContract.equipmentTypeTechnicalName,
          equipmentSizeName: serviceContract.equipmentSizeName,
          equipmentSizeTechnicalName: serviceContract.equipmentSizeTechnicalName,
          serviceTypeName: serviceContract.serviceTypeName,
          serviceTypeTechnicalName: serviceContract.serviceTypeTechnicalName,
        })),
      })),
    },
  }));
  result.push({ label, options });
  return result;
};
