import { connect } from 'react-redux';

import { hasPermissionSelector } from '../ducks';
import { AppState } from '../../store';

const selector = hasPermissionSelector as any;

interface PermissionGuardProps {
  children: any;
  hasPermission?: boolean;
  permission: string;
}

const PermissionGuard: React.SFC<PermissionGuardProps> = ({ hasPermission, children }) =>
  hasPermission ? children : null;

const mapStateToProps = (state: AppState, { permission }: PermissionGuardProps) => ({
  hasPermission: selector(state.account.permissions, permission) as boolean,
});

export default connect(mapStateToProps)(PermissionGuard);
