import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { RouteAssistForm } from '../forms';
import { saveRouteAssistSettings } from '../../ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: (pristine: boolean) => void;
  saveRouteAssistSettings: (vendorId: number, routeAssistSettings: any) => Promise<any>;
  vendorId?: number;
}

class RouteAssistModal extends PureComponent<Props> {
  onSubmit = (routeAssistSettings: any) => {
    const { vendorId, saveRouteAssistSettings, closeModal } = this.props;

    saveRouteAssistSettings(vendorId as number, routeAssistSettings as any)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.routeAssistSettingsSaved'));
        closeModal(true);
      })
      .catch(({ code }) =>
        createErrorNotification(
          code
            ? translate(createTranslationKey(code, 'vendors.alertMessages'))
            : translate('vendors.alertMessages.routeAssistSettingsError'),
        ),
      );
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Modal padding="no" size="small">
        <ModalSection padding="medium no no">
          <ModalTitle>{translate('vendors.featureCodes.routeAssistanceInApp')}</ModalTitle>
        </ModalSection>
        <ModalSection>
          <RouteAssistForm onCancel={closeModal} onSubmit={this.onSubmit} />
        </ModalSection>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { saveRouteAssistSettings };

export default connect(mapStateToProps, mapDispatchToProps)(RouteAssistModal);
