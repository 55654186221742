import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getArrowsForEditGeoJSON, getTravelPathForEditGeoJSON } from '../../utils';
import { setSelectedSegment } from 'src/routes/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import TravelPathGLSource, { TRAVEL_PATH_SOURCE } from './TravelPathGLSource';
import TravelPathLegend from 'src/routes/components/pages/routes/routePageSections/routeMap/travelPath/TravelPathLegend';
import { size } from 'lodash-es';

interface Props {
  map: mapboxgl.Map;
  mapBearing: number;
  isDisplayOnly?: boolean;
}

const TravelPathGL: FC<Props> = ({ map, mapBearing, isDisplayOnly }) => {
  const dispatch = useDispatch();
  const { startSegment, endSegment, travelPathEditData } = useSelector(state => state.routes.travelPathBuildAndEdit);

  useEffect(() => {
    if (isDisplayOnly) return;

    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === TRAVEL_PATH_SOURCE);

        if (feature && feature.properties && feature.properties.clickable) {
          dispatch(setSelectedSegment(feature));
        }
      });
    });
  }, [map, dispatch, startSegment, endSegment, isDisplayOnly]);

  const linesGeoJson = useMemo(() => {
    if (!travelPathEditData) return null;
    return getTravelPathForEditGeoJSON(travelPathEditData, startSegment, endSegment);
  }, [endSegment, startSegment, travelPathEditData]);

  const arrowsGeoJson = useMemo(() => {
    if (!travelPathEditData) return null;
    return getArrowsForEditGeoJSON(travelPathEditData, mapBearing, startSegment, endSegment);
  }, [endSegment, mapBearing, startSegment, travelPathEditData]);

  if (!linesGeoJson || !arrowsGeoJson) return null;

  return (
    <>
      <TravelPathGLSource linesGeoJson={linesGeoJson} arrowsGeoJson={arrowsGeoJson} />
      {!!size(travelPathEditData?.features) && isDisplayOnly && <TravelPathLegend isEditModal position={'top-right'} />}
    </>
  );
};

export default TravelPathGL;
