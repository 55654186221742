import { map, upperFirst } from 'lodash-es';
import { camelize } from 'humps';
import moment from 'moment';

import { BULK, PORTABLE_TOILET, PORTABLE_RESTROOM_TRAILER } from '../../common/constants';
import { NONE_ID } from '../constants/dispatchBoard';
import { Route } from '../interfaces/Route';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const getEquipmentSize = (equipmentSize: string, equipmentName: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string, serviceTypeName: string, equipmentName: string) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

export const transformLoadUnassignedJobs = (jobs: Route) => {
  const now = moment().startOf('day');
  return map(
    jobs,
    ({
      containerSize,
      containerSizeTechnicalName,
      containerType,
      containerTypeTechnicalName,
      jobStatus,
      jobType,
      routeId,
      routeName,
      serviceType,
      serviceZoneId,
      supervisorId,
      wasteMaterialType,
      wasteMaterialTypeTechnicalName,
      ...job
    }) => {
      const size = getEquipmentSize(containerSizeTechnicalName, containerType);

      const date = moment(job.date);
      const isOverdue = date.isBefore(now);
      const isInFuture = date.isAfter(now);

      return {
        ...job,
        routeId: null,
        routeName: null,
        prevRouteId: routeId,
        prevRouteName: routeName,
        containerSize,
        containerSizeName: translate(
          getEquipmentSizeTranslationKey(containerSizeTechnicalName, serviceType.name, containerType),
          { size },
        ),
        containerType,
        containerTypeName: translate(
          createTranslationKey(containerTypeTechnicalName || containerType, 'common.equipmentTypes'),
        ),
        formattedDate: date.format('MM/DD/YYYY'),
        isInFuture,
        isOverdue,
        jobStatus,
        jobStatusName: translate(createTranslationKey(jobStatus, 'common.pickupStatuses')),
        jobType,
        jobTypeName: translate(createTranslationKey(jobType, 'routes.pickupTypes')),
        serviceType,
        serviceZoneId: serviceZoneId || NONE_ID,
        supervisorId: supervisorId || NONE_ID,
        wasteMaterialType,
        wasteMaterialTypeName: translate(createTranslationKey(wasteMaterialTypeTechnicalName, 'common.wasteTypes')),
        isUnassignedJob: true,
      };
    },
  );
};
