import { sizeMapper } from '../../utils/styles';
import styled from 'styled-components';

interface Props {
  align?: string;
  hasScheduledDailyRoutes?: boolean;
  wrapperMargin?: string;
}

export const UpdateTrackerRouteSwitchWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.align};
  margin: ${props => sizeMapper(props.wrapperMargin, 'no')};
}`;
