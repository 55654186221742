import {
  TRIP_CONFIGURATION_EXTERIOR_ID,
  TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID,
  TRIP_CONFIGURATION_INTERIOR_ID,
} from '../constants/tripInspection';
import { TripConfiguration } from '../interfaces/TripConfiguration';

const transformLoadTripConfiguration = (tripConfiguration: TripConfiguration) => {
  return {
    ...tripConfiguration,
    tripConfigurationExterior: tripConfiguration.tripInspectionCategories[TRIP_CONFIGURATION_EXTERIOR_ID],
    tripConfigurationVehicleSpecific:
      tripConfiguration.tripInspectionCategories[TRIP_CONFIGURATION_VEHICLE_SPECIFIC_ID],
    tripConfigurationInterior: tripConfiguration.tripInspectionCategories[TRIP_CONFIGURATION_INTERIOR_ID],
  };
};

export default transformLoadTripConfiguration;
