import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { MaterialPickupsTableRow } from '.';
import { MaterialTicket } from 'src/routes/interfaces/MaterialTickets';
import { MaterialTicketsTableRow } from 'src/routes/components/modals';
import { Modal, Table } from 'src/core/components';
import { ModalSection, ModalTitle, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

const materialPickupsTableCellWidths = ['26%', '20%', '20%', '20%', '14%'];
const materialPickupsTableCells = [
  { name: 'facilityName', label: translate('facilities.facilityName'), width: materialPickupsTableCellWidths[0] },
  { name: 'vehicleName', label: translate('common.vehicleDriver'), width: materialPickupsTableCellWidths[1] },
  { name: 'startTime', label: translate('common.startTime'), width: materialPickupsTableCellWidths[2] },
  { name: 'endTime', label: translate('common.endTime'), width: materialPickupsTableCellWidths[3] },
  { name: 'duration', label: translate('common.duration'), width: materialPickupsTableCellWidths[4] },
];

const materialTicketsCellWidths = ['12%', '31%', '13%', '11%', '10%', '14%', '9%'];
const materialTicketsTableCells = [
  {
    name: 'ticketNumber',
    label: translate('common.ticketNumber'),
    width: materialTicketsCellWidths[0],
  },
  {
    name: 'puLocation',
    label: translate('common.materialTickets.puLocation'),
    width: materialTicketsCellWidths[1],
  },
  {
    name: 'material',
    label: translate('common.material'),
    width: materialTicketsCellWidths[2],
  },
  {
    name: 'quantity',
    label: translate('common.materialTickets.quantity'),
    width: materialTicketsCellWidths[3],
  },
  {
    name: 'uom',
    label: translate('common.materialTickets.uom'),
    width: materialTicketsCellWidths[4],
  },
  {
    name: 'timestamp',
    label: translate('common.materialTickets.materialTicketDate'),
    width: materialTicketsCellWidths[5],
  },
  {
    name: 'image',
    label: translate('common.image'),
    width: materialTicketsCellWidths[6],
  },
];

interface Props {
  closeModal: () => void;
  materialPickups: MaterialTicket[];
  materialTickets: MaterialTicket[];
  modalSubTitle?: string;
  modalTitle?: string;
}

const MaterialTicketsModal: React.FC<Props> = props => {
  const { closeModal, materialPickups, materialTickets, modalSubTitle, modalTitle } = props;

  return (
    <Modal size="large" subTitle={modalSubTitle} title={modalTitle} onClose={closeModal} padding="medium">
      <ModalSection margin="small no no">
        {materialTickets.length ? (
          <Table
            cells={materialTicketsTableCells}
            rows={materialTickets}
            rowComponent={MaterialTicketsTableRow}
            rowProps={{
              isViewOnly: true,
              materialTicketsCellWidths,
            }}
          />
        ) : (
          <Text block align="center" padding="no no small no">
            {translate('common.materialTickets.noMaterialTickets')}
          </Text>
        )}
      </ModalSection>

      <ModalTitle margin="medium no no no">{translate('routes.materialPickupTrips')}</ModalTitle>
      <ModalSection margin="small no no no">
        {materialPickups.length ? (
          <Table
            cells={materialPickupsTableCells}
            rows={materialPickups}
            rowComponent={MaterialPickupsTableRow}
            rowProps={{ materialPickupsTableCellWidths }}
          />
        ) : (
          <Text block align="center" padding="no no small no">
            {translate('routes.noMaterialPickupTrips')}
          </Text>
        )}
      </ModalSection>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  materialPickups: state.routes.materialTickets.materialPickups || [],
  materialTickets: state.routes.materialTickets.materialTickets || [],
});

export default connect(mapStateToProps)(MaterialTicketsModal);
