import { orderBy } from 'lodash-es';

import { http } from '../../core/services/http';
import { RouteSummary } from '../interfaces/RouteSummary';

export const loadRouteSummary = (vendorId: number, routeId: number) =>
  http
    .get<RouteSummary>(`${vendorId}/routes/${routeId}/routeDetail/summary`)
    .then(response => ({ ...response.data, routeId }));

export const loadSnowSweeperRouteSummary = (
  vendorId: number,
  routeId: number,
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get<RouteSummary>(`${vendorId}/routes/${routeId}/routeDetail/alternativeFleet/summary`, {
      params: { startDate, endDate },
    })
    .then(response => ({
      ...response.data,
      routeId,
      nowServicing: orderBy(response.data.nowServicing, 'driverId', 'asc'),
    }));
