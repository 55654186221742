import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import translate from 'src/core/services/translate';
import { http } from 'src/core/services/http';
import { ILoadOpenBillsParams } from '../interfaces/openBills';


export const loadOpenBills = (
  params: ILoadOpenBillsParams
) =>
  http
    .get('reconciliations/bills', {
      params,
    })
    .then(response => response.data);

export const assignOpenBill = (vendorId: number, billId: number, paymentId: number, customerId: number) =>
  http.post(`reconciliations/payments/${paymentId}/bills/${billId}/assign`, { vendorId, customerId });

export const unassignOpenBill = (paymentId: number, billIds: number[]) =>
  http.put(`reconciliations/payments/${paymentId}/bills/unaassign`, billIds);

export const exportOpenBills = () =>
  downloadExcelFile('export/openBills', `${translate('finance.applyChecks.openBills')}-${translate('finance.report')}`);

export const exportOpenBill = (billId: number) =>
  downloadExcelFile(
    'export/openBills',
    `${translate('finance.applyChecks.openBill')}-${translate('finance.report')}`,
    billId,
  );
