import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const BUSINESS = 1;
export const STREET = 2;
export const DISPOSAL_FACILITY = 3;
export const OPERATIONAL_FACILITY = 4;

export const CUSTOMER_TYPES = mapKeys(
  [
    { id: BUSINESS, name: translate('common.customerTypes.business') },
    { id: STREET, name: translate('common.customerTypes.street') },
    { id: DISPOSAL_FACILITY, name: translate('common.customerTypes.disposalFacility') },
    { id: OPERATIONAL_FACILITY, name: translate('common.customerTypes.operationalFacility') },
  ],
  'id',
);
