import { debounce } from 'lodash-es';
import { Field, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { useDispatch } from 'react-redux';

import { DATE_RANGE_PICKER_TODAY_7_30_90_PAST } from '../../../core//constants';
import { DateRangePicker, MultiSelect, TypedField, PanelSearch } from 'src/core/components';
import { Grid, GridColumn, Panel, PanelSection } from '../../../core/components/styled';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { useSelector } from '../../../core//hooks/useSelector';
import translate from '../../../core//services/translate';

const APPLY_CHECKS_FILTER_FORM = 'applyChecksFilterForm';

export interface ApplyChecksFilterFormValues {
  searchText: string;
  date?: {
    from: StringOrDate;
    to: StringOrDate;
  };
  paymentMethodIds?: number[] | string | string[];
  paymentStatusIds?: number[] | string | string[];
}

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<ApplyChecksFilterFormValues, PropsWithoutReduxForm>;

const ApplyChecksFilterForm = ({ handleSubmit }: Props) => {
  const dispatch = useDispatch();

  const paymentStatusOptions = useSelector(state => state.finance.paymentStatuses.paymentStatuses);
  const paymentMethodOptions = useSelector(state => state.finance.paymentMethods.paymentMethods);

  const handleSearchTerm = () => {
    dispatch(submit(APPLY_CHECKS_FILTER_FORM));
  };

  const onSearchTermChange = debounce(() => handleSearchTerm(), 470);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Panel>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12" borderRight>
              <TypedField
                name="searchText"
                component={PanelSearch}
                props={{
                  placeholder: translate('common.search'),
                  isClearable: true,
                  margin: 'no',
                  padding: 'ssMedium no no no',
                }}
                onChange={() => onSearchTermChange()}
              />
            </GridColumn>
            <GridColumn size="4/12">
              <TypedField
                component={MultiSelect}
                name="paymentMethodIds"
                props={{
                  options: paymentMethodOptions as any,
                  label: translate('finance.paymentManagement.paymentMethod'),
                  isClearable: true,
                  isSearchable: true,
                  margin: 'no',
                  normalizeValues: Number,
                }}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <TypedField
                component={MultiSelect}
                name="paymentStatusIds"
                props={{
                  options: paymentStatusOptions as any,
                  label: translate('finance.applyChecks.status'),
                  isClearable: true,
                  isSearchable: true,
                  margin: 'no',
                  normalizeValues: Number,
                }}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="date"
                component={DateRangePicker}
                hasMarginLeft="normal"
                options={DATE_RANGE_PICKER_TODAY_7_30_90_PAST}
                margin="no"
                label={translate('finance.applyChecks.paymentReceivedDate')}
                alignRight={true}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </Panel>
    </form>
  );
};

export default reduxForm<ApplyChecksFilterFormValues, PropsWithoutReduxForm>({
  form: APPLY_CHECKS_FILTER_FORM,
  onChange: (values, dispatch, props, previousValues) => {
    if (values.searchText === previousValues.searchText) {
      props.submit && props.submit();
    }
  },
})(ApplyChecksFilterForm);
