import { http } from '../../core/services/http';
import { EquipmentCategory, EquipmentSize } from '../interfaces/Equipments';

const equipmentsSortOrder: any = {
  FL: 0,
  RL: 1,
  CP: 2,
  VC: 3,
  OT: 4,
  RB: 5,
  CT: 6,
  COMCT: 7,
  RESCT: 8,
  RESBG: 9,
};

export const loadEquipments = (haulerId: string, divisionId?: string) =>
  http
    .get(`/haulerProfile/${haulerId}/equipments`)
    .then(response =>
      (response.data.equipments as EquipmentCategory[]).sort(
        (a, b) => equipmentsSortOrder[a.binSubType] - equipmentsSortOrder[(b.binSubTypeCategory || '') + b.binSubType],
      ),
    );

export const isEquipmentSizeUsed = (haulerId: string, equipmentSize: EquipmentSize) =>
  new Promise(resolve => setTimeout(() => resolve(true), 200));

export const updateEquipmentSize = (
  haulerId: string,
  equipmentSize: EquipmentSize,
  userName: string,
  equipmentType: string,
  equipmentTypeCategory?: string,
  divisionId?: string,
) => {
  const dto = {
    id: equipmentSize.id,
    vendGroup: haulerId,
    binSubType: equipmentType,
    binSubTypeCategory: equipmentTypeCategory,
    binClass: equipmentSize.binClass,
    other: equipmentSize.other,
    isActive: equipmentSize.isActive,
    updatedBy: userName,
    vendId: divisionId,
  };

  if (dto.id) {
    return http.put(`/haulerProfile/${haulerId}/equipment/${dto.id}`, dto);
  }
  return http.post(`/haulerProfile/${haulerId}/equipment`, dto);
};

export const deleteEquipmentSize = (haulerId: string, equipmentSizeId: number, divisionId?: string) => {
  return http.delete(`/haulerProfile/${haulerId}/equipment/${equipmentSizeId}`);
};
