import React from 'react';
import { Switch, Redirect, Route } from 'react-router';

import { Page } from '../../common/components';
import { OpportunityPageResolver, ServiceChangeRequestsPageResolver } from './pages';
import { OpportunitiesPageResolver } from './pages/OpportunitiesPageResolver';
import { AuthRoute } from '../../account/components';

const Opportunity = () => (
  <Page>
    <Switch>
      <AuthRoute exact path="/opportunities/" component={OpportunitiesPageResolver} />
      <Route exact path="/opportunities/hauler-opportunities/:token" component={OpportunityPageResolver} />
      <AuthRoute exact path="/opportunities/hauler-opportunities/" component={OpportunityPageResolver as any} />
      <AuthRoute
        exact
        path="/opportunities/service-change-requests/"
        component={ServiceChangeRequestsPageResolver as any}
      />
      <Redirect to="/opportunities/hauler-opportunities/" />
    </Switch>
  </Page>
);

export default Opportunity;
