import { map, sum } from 'lodash-es';
import { theme } from '../../core/styles';
import { decimal, kFormatter } from '../../utils/services/formatter';
import translate from '../../core/services/translate';

export const ROUTES = 'Routes';
export const JOBS = 'Jobs';
export const LATEST_ACTIVITY = 'LatestActivity';
export const SEGMENTS = 'Segments';

export const getPieChartOptions = (type: typeof ROUTES | typeof JOBS) => ({
  maintainAspectRatio: true,
  legend: {
    display: true,
    position: 'bottom',
    align: 'center',
    fullWidth: true,
    labels: {
      boxWidth: 10,
    },
  },
  tooltips: {
    backgroundColor: theme.brandPrimary,
    bodyFontSize: 14,
    displayColors: false,
    callbacks: {
      title: () => {},
      label: ({ index }: any, { datasets }: any) => {
        const { label, value } = datasets[0].piechartData[index];
        const total = sum(map(datasets[0].piechartData, 'value'));
        const percentage = decimal((value * 100) / total, 0);
        switch (type) {
          case ROUTES:
            return [
              label,
              translate('insights.numberOfRoutes', { routes: value }),
              translate('insights.percentageOfTotalWithValue', { percentage }),
            ];
          case JOBS:
            return [
              label,
              translate('insights.numberOfStops', { stops: value }),
              translate('insights.percentageOfTotalWithValue', { percentage }),
            ];
          default:
            return [
              label,
              translate('insights.numberOfStops', { stops: value }),
              translate('insights.percentageOfTotalWithValue', { percentage }),
            ];
        }
      },
    },
  },
});

export const getPieChartData = (chartData: any[]) => {
  const data = map(chartData, 'value');
  const colors = map(chartData, 'color');

  const labelsWithValue = map(chartData, ({ label, value }) => ({
    label: `${label} (${kFormatter(value, { decimals: 0, kThreshold: 10000 })})`,
  }));
  const labels = map(labelsWithValue, 'label');
  return {
    labels,
    datasets: [
      {
        data,
        piechartData: chartData,
        backgroundColor: colors,
      },
    ],
  };
};
