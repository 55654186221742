import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { StripeAccountLink, StripeAccountStatus } from 'src/vendors/interfaces/StripeAccount';
import {
  checkIsBillingActive as doCheckIsBillingActive,
  createStripeAccount as doCreateStripeAccount,
  refreshStripeAccountLink as doRefreshStripeAccountLink,
  completeStripeAccountSetup as doCompleteStripeAccountSetup,
} from 'src/vendors/services/stripeAccount';

// Actions
const START_LOAD = 'vendors/stripeAccount/START_LOAD';
const COMPLETE_LOAD_BILLING_ACTIVE = 'vendors/stripeAccount/COMPLETE_LOAD_BILLING_ACTIVE';
const FAIL_LOAD_BILLING_ACTIVE = 'vendors/stripeAccount/FAIL_LOAD_BILLING_ACTIVE';
const COMPLETE_LOAD_ACCOUNT_STATUS = 'vendors/stripeAccount/COMPLETE_LOAD_ACCOUNT_STATUS';
const FAIL_LOAD_ACCOUNT_STATUS = 'vendors/stripeAccount/FAIL_LOAD_ACCOUNT_STATUS';
const COMPLETE_LOAD_ACCOUNT_LINK = 'vendors/stripeAccount/COMPLETE_LOAD_ACCOUNT_LINK';
const FAIL_LOAD_ACCOUNT_LINK = 'vendors/stripeAccount/FAIL_LOAD_ACCOUNT_LINK';
const RESET = 'vendors/stripeAccount/RESET';

interface StripeState {
  isBillingActive: boolean;
  stripeAccountStatus?: StripeAccountStatus;
  stripeAccountLink?: StripeAccountLink;
  isLoading: boolean;
  isSaving: boolean;
}

// Initial state
const initialState: StripeState = {
  isBillingActive: false,
  stripeAccountStatus: undefined,
  stripeAccountLink: undefined,
  isLoading: false,
  isSaving: false,
};

// Reducer
export const reducer = (state: StripeState = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_BILLING_ACTIVE:
      return update(state, {
        $merge: {
          isBillingActive: action.isBillingActive,
          isLoading: false,
        },
      });

    case FAIL_LOAD_BILLING_ACTIVE:
      return update(state, {
        $merge: {
          isBillingActive: false,
          isLoading: false,
        },
      });

    case COMPLETE_LOAD_ACCOUNT_STATUS:
      return update(state, {
        $merge: {
          stripeAccountStatus: action.stripeAccountStatus,
          isLoading: false,
        },
      });

    case FAIL_LOAD_ACCOUNT_STATUS:
      return update(state, {
        $merge: {
          stripeAccountStatus: undefined,
          isLoading: false,
        },
      });

    case COMPLETE_LOAD_ACCOUNT_LINK:
      return update(state, {
        $merge: {
          stripeAccountLink: action.stripeAccountLink,
          isLoading: false,
        },
      });

    case FAIL_LOAD_ACCOUNT_LINK:
      return update(state, {
        $merge: {
          stripeAccountLink: undefined,
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoadIsBillingActive = (isBillingActive: boolean) => ({
  type: COMPLETE_LOAD_BILLING_ACTIVE,
  isBillingActive,
});

const failLoadIsBillingActive = () => ({
  type: FAIL_LOAD_BILLING_ACTIVE,
});

const completeLoadStripeAccountStatus = (stripeAccountStatus: StripeAccountStatus) => ({
  type: COMPLETE_LOAD_ACCOUNT_STATUS,
  stripeAccountStatus,
});

const failLoadStripeAccountStatus = () => ({
  type: FAIL_LOAD_ACCOUNT_STATUS,
});

const completeLoadStripeAccountLink = (stripeAccountLink: StripeAccountLink) => ({
  type: COMPLETE_LOAD_ACCOUNT_LINK,
  stripeAccountLink,
});

const failLoadStripeAccountLink = () => ({
  type: FAIL_LOAD_ACCOUNT_LINK,
});

export const resetStripeAccount = () => ({
  type: RESET,
});

export const checkIsBillingActive = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStripeAccountLinkPromise = doCheckIsBillingActive(vendorId);
  loadStripeAccountLinkPromise
    .then(isActive => dispatch(completeLoadIsBillingActive(isActive)))
    .catch(() => dispatch(failLoadIsBillingActive()));
  return loadStripeAccountLinkPromise;
};

export const createStripeAccount = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStripeAccountLinkPromise = doCreateStripeAccount(vendorId);
  loadStripeAccountLinkPromise
    .then(stripeAccountLink => dispatch(completeLoadStripeAccountLink(stripeAccountLink)))
    .catch(() => dispatch(failLoadStripeAccountLink()));
  return loadStripeAccountLinkPromise;
};

export const refreshStripeAccountLink = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const refreshStripeAccountLinkPromise = doRefreshStripeAccountLink(vendorId);
  refreshStripeAccountLinkPromise
    .then(stripeAccountLink => dispatch(completeLoadStripeAccountLink(stripeAccountLink)))
    .catch(() => dispatch(failLoadStripeAccountLink()));
  return refreshStripeAccountLinkPromise;
};

export const completeStripeAccountSetup = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStripeAccountStatusPromise = doCompleteStripeAccountSetup(vendorId);
  loadStripeAccountStatusPromise
    .then(stripeAccountStatus => dispatch(completeLoadStripeAccountStatus(stripeAccountStatus)))
    .catch(() => dispatch(failLoadStripeAccountStatus()));
  return loadStripeAccountStatusPromise;
};
