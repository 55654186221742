import { ChangeEvent } from 'react';

import { DropDownFieldProps } from 'src/common/components/DropDownFieldProps';
import { Dropdown, TypedField } from 'src/core/components';
import translate from 'src/core/services/translate';
import { RATE_STATUSES_LIST } from 'src/customers/constants/rateStatuses';

interface RateTypeDropdownProps extends DropDownFieldProps {
  margin?: string;
}

const RateTypeDropdown = (props: RateTypeDropdownProps) => {
  const {
    dropdownProps,
    input: { name, onChange: inputOnChange },
    label,
    margin,
    placeholder,
    withLabel,
    withPlaceholder,
  } = props;

  const onChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: string | undefined,
    _previousValue: string | undefined,
    fieldName: string,
  ) => {
    inputOnChange({ fieldName, value });
  };

  const options = RATE_STATUSES_LIST.map(({ name, technicalName }) => ({
    label: name,
    value: technicalName,
  }));

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChange}
      props={{
        width: '100%',
        label: label || (withLabel ? translate('finance.modal.rateStatus') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('finance.modal.rateStatus') : undefined),
        options,
        menuPosition: 'fixed',
        margin: margin,
        ...dropdownProps,
      }}
    />
  );
};

export default RateTypeDropdown;
