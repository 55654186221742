import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps } from 'redux-form';

import { ChevronDownArrow, Switch } from '../../../core/components';
import { Button, TableCell, TableRow } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { RESIDENTIAL_ID, ROLL_OFF_ID, VEHICLE_TYPE_IDS } from '../../../fleet/constants';
import { AppState } from '../../../store';
import { RouteSettings } from '../../interfaces/RouteSettings';
import { loadRouteSequenceDefaultSettings } from '../../services/routeSequenceSettings';
import { DownArrow } from '../styled';
import { RouteSequenceArrow, RouteSequenceFormWrapper, RouteSequenceRowWrapper } from '../styled/RouteSequence';
import RouteSettingsForm from './RouteSettingsForm';

interface Props {
  index: number;
  routeStartTime: Date;
  vehicleTypeId: number;
  hasError: boolean[];
  setHasError: (hasError: boolean[]) => void;
  formSelector: ReturnType<typeof formValueSelector>;
  change: InjectedFormProps['change'];
}

const RouteSequenceFormTableRow: React.FC<Props> = ({
  change,
  index,
  routeStartTime,
  vehicleTypeId,
  formSelector,
  hasError,
  setHasError,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const globalTurnTimeSettingsEnabled = useSelector((state: AppState) =>
    formSelector(state, `settings[${index}].globalTurnTimeSettingsEnabled`),
  );

  const handleToggleClick = React.useCallback(() => {
    if (vehicleTypeId !== ROLL_OFF_ID) {
      setExpanded(!expanded);
    }
  }, [expanded, vehicleTypeId]);

  const restoreDefaults = React.useCallback(async () => {
    const defaults = await loadRouteSequenceDefaultSettings(vehicleTypeId);
    if (vehicleTypeId === RESIDENTIAL_ID) {
      if (defaults.bothSidesServiceTimePerStop && typeof defaults.bothSidesServiceTimePerStop === 'object')
        defaults.bothSidesServiceTimePerStop =
          defaults.bothSidesServiceTimePerStop.getHours() * 3600 +
          defaults.bothSidesServiceTimePerStop.getMinutes() * 60 +
          defaults.bothSidesServiceTimePerStop.getSeconds();
      if (defaults.singleSideServiceTimePerStop && typeof defaults.singleSideServiceTimePerStop === 'object')
        defaults.singleSideServiceTimePerStop =
          defaults.singleSideServiceTimePerStop.getHours() * 3600 +
          defaults.singleSideServiceTimePerStop.getMinutes() * 60 +
          defaults.singleSideServiceTimePerStop.getSeconds();
    }
    Object.keys(defaults).forEach(key => {
      if (['id', 'isActive'].indexOf(key) !== -1) {
        return;
      }

      change(`settings[${index}].${key}`, defaults[key as keyof RouteSettings]);
    });
  }, [change, index, vehicleTypeId]);

  return (
    <RouteSequenceRowWrapper expanded={expanded}>
      <TableRow borderBottom={expanded ? '0' : undefined}>
        <TableCell width="70%">{VEHICLE_TYPE_IDS[vehicleTypeId].name}</TableCell>

        <TableCell width="20%">
          <Field component={Switch} name={`settings[${index}].isActive`} />
        </TableCell>

        <TableCell width="10%">
          {vehicleTypeId !== ROLL_OFF_ID && (
            <DownArrow orientation={expanded ? 'bottom' : 'top'} onClick={handleToggleClick}>
              <RouteSequenceArrow>
                <ChevronDownArrow height={16} />
              </RouteSequenceArrow>
            </DownArrow>
          )}
        </TableCell>
      </TableRow>

      {vehicleTypeId !== ROLL_OFF_ID && (
        <TableRow hidden={!expanded} wrap="wrap">
          <TableCell width="100%" padding="no">
            <RouteSequenceFormWrapper>
              <RouteSettingsForm
                change={change}
                index={index}
                vehicleTypeId={vehicleTypeId}
                defaultRouteStartTime={routeStartTime}
                globalTurnTimeSettingsEnabled={globalTurnTimeSettingsEnabled}
                hasError={hasError}
                setHasError={setHasError}
              />
            </RouteSequenceFormWrapper>
          </TableCell>

          <TableCell width="100%">
            <Button type="button" color="secondary" margin="no no ssMedium no" onClick={restoreDefaults}>
              {translate('routes.restoreToDefaults')}
            </Button>
          </TableCell>
        </TableRow>
      )}
    </RouteSequenceRowWrapper>
  );
};

export default RouteSequenceFormTableRow;
