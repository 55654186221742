import { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Input, Switch, TypedField } from 'src/core/components';
import { Button, ButtonSet, Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { isRequired } from 'src/utils/services/validator';

interface ComponentProps {
  onCancel: (pristine: boolean) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const GroupEditorForm: FC<Props> = ({ handleSubmit, onCancel, pristine }) => {
  return (
    <PanelSection padding="small xSmall">
      <Grid multiLine centered>
        <GridColumn size="8/12">
          <form noValidate onSubmit={handleSubmit}>
            <TypedField
              name="groupName"
              component={Input}
              validate={[isRequired]}
              props={{
                label: translate('routes.groups.groupName'),
                margin: 'small no',
              }}
            />
            <TypedField
              name="isActive"
              component={Switch}
              props={{
                label: translate('common.active'),
              }}
            />
            <ButtonSet margin="large auto no">
              <Button type="submit" id="service-zone-save-button" color="primary">
                {translate('common.save')}
              </Button>
              <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          </form>
        </GridColumn>
      </Grid>
    </PanelSection>
  );
};

export default reduxForm<any, ComponentProps>({
  form: 'groupEditorForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(GroupEditorForm);
