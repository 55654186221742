import update from 'immutability-helper';
import { findIndex, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { MANAGER } from '../constants';
import {
  createUser,
  deleteUser as doDeleteUser,
  getUserEditDistricts,
  getVendorDistricts,
  saveDivisions,
  updateUser,
} from '../services/user';
import { loadUserById as doLoadUserById, loadUsers as doLoadUsers } from '../services/users';
import { Districts } from '../interfaces/DivisionAccess';
import { Users } from '../interfaces/UserEditor';

// Actions
const START_LOAD = 'vendors/users/START_LOAD';
export const COMPLETE_LOAD = 'vendors/users/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/users/FAIL_LOAD';
const START_DELETE = 'vendor/users/START_DELETE';
export const COMPLETE_DELETE = 'vendor/users/COMPLETE_DELETE';
const FAIL_DELETE = 'vendor/users/FAIL_DELETE';
const START_SAVE = 'vendor/users/START_SAVE';
export const COMPLETE_SAVE = 'vendor/users/COMPLETE_SAVE';
const FAIL_SAVE = 'vendor/users/FAIL_SAVE';
const RESET = 'vendors/users/RESET';
const START_USER_LOAD = 'vendors/user/START_LOAD';
const COMPLETE_USER_LOAD = 'vendors/user/COMPLETE_LOAD';
const FAIL_USER_LOAD = 'vendors/user/FAIL_LOAD';
const START_LOAD_DISTRICTS = 'vendors/users/START_LOAD_DISTRICTS';
const COMPLETE_LOAD_DISTRICTS = 'vendors/users/COMPLETE_LOAD_DISTRICTS';
const FAIL_LOAD_DISTRICTS = 'vendors/users/FAIL_LOAD_DISTRICTS';
const START_SAVE_DIVISIONS = 'vendors/users/START_SAVE_DIVISIONS';
const FAIL_SAVE_DIVISIONS = 'vendors/users/FAIL_SAVE_DIVISIONS';
const COMPLETE_SAVE_DIVISIONS = 'vendors/users/COMPLETE_SAVE_DIVISIONS';
const COMPLETE_UPDATE_DISTRICTS = 'vendors/users/COMPLETE_UPDATE_DISTRICTS';

// Initial state
const initialState = {
  districts: null,
  districtsUserId: null,
  isLoading: false,
  isLoadingDistricts: false,
  isSaving: false,
  user: undefined,
  users: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_SAVE_DIVISIONS:
    case START_LOAD_DISTRICTS:
      return { ...state, isLoadingDistricts: true };
    case COMPLETE_SAVE_DIVISIONS:
      return { ...state, isLoadingDistricts: false };
    case COMPLETE_LOAD_DISTRICTS:
      return { ...state, isLoadingDistricts: false, districts: action.districts, districtsUserId: action.userId };
    case COMPLETE_UPDATE_DISTRICTS:
      return { ...state, isLoadingDistricts: false, districts: action.districts };
    case FAIL_SAVE_DIVISIONS:
    case FAIL_LOAD_DISTRICTS:
      return { ...state, isLoadingDistricts: false };
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          users: action.users,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          users: undefined,
        },
      });

    case START_USER_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_USER_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          user: action.user,
        },
      });

    case FAIL_USER_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          user: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      } as any);

    case COMPLETE_DELETE: {
      const userIndex = findIndex(state.users, { userId: action.userId });
      return update(state, {
        users: { $splice: [[userIndex, 1]] },
        $merge: { isDeleting: false },
      } as any);
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
          vendor: undefined,
        },
      } as any);

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      } as any);

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      } as any);

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      } as any);

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          users: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators

const startDistrictLoad = () => ({
  type: START_LOAD_DISTRICTS,
});

const startDivisionSave = () => ({
  type: START_SAVE_DIVISIONS,
});

const failDivisionSave = () => ({
  type: FAIL_SAVE_DIVISIONS,
});

export const completeDivisionSave = () => ({
  type: COMPLETE_SAVE_DIVISIONS,
});

export const updateDistricts = (districts: Districts) => ({
  type: COMPLETE_UPDATE_DISTRICTS,
  districts,
});

export const completeLoadDistrict = (districts: Districts, userId?: string) => ({
  type: COMPLETE_LOAD_DISTRICTS,
  districts,
  userId,
});

const failLoadDistricts = () => ({
  type: FAIL_LOAD_DISTRICTS,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (users: any) => ({
  type: COMPLETE_LOAD,
  users,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startUserLoad = () => ({
  type: START_USER_LOAD,
});

const completeUserLoad = (user: any) => ({
  type: COMPLETE_USER_LOAD,
  user,
});

const failUserLoad = () => ({
  type: FAIL_USER_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (userId: string) => ({
  type: COMPLETE_DELETE,
  userId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (users: any) => ({
  type: COMPLETE_SAVE,
  users,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

// Async Actions

export const saveDivision = (vendorId: number, userId: string, data: any) => (dispatch: Dispatch) => {
  dispatch(startDivisionSave());
  return saveDivisions(vendorId, userId, data)
    .then(() => dispatch(completeDivisionSave()))
    .catch(() => dispatch(failDivisionSave()));
};
export const loadDistricts = (vendorId: number, userId?: string) => (dispatch: Dispatch) => {
  dispatch(startDistrictLoad());
  const loadDistrictsPromise = userId ? getUserEditDistricts(vendorId, userId) : getVendorDistricts(vendorId);
  loadDistrictsPromise
    .then(data => dispatch(completeLoadDistrict(data, userId)))
    .catch(() => dispatch(failLoadDistricts()));
  return loadDistrictsPromise;
};

export const loadUsers = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadUsersPromise = doLoadUsers(vendorId);
  loadUsersPromise.then(users => dispatch(completeLoad(users))).catch(() => dispatch(failLoad()));

  return loadUsersPromise;
};

export const loadUserById = (vendorId: number, userId?: number | string) => (dispatch: Dispatch) => {
  dispatch(startUserLoad());
  const loadUsersPromise = doLoadUserById(vendorId, userId);
  loadUsersPromise.then(users => dispatch(completeUserLoad(users))).catch(() => dispatch(failUserLoad()));

  return loadUsersPromise;
};

export const deleteUser = (vendorId: number, userId: string) => (dispatch: Dispatch) => {
  dispatch(startDelete());

  const deleteUserPromise = doDeleteUser(vendorId, userId);
  deleteUserPromise.then(() => dispatch(completeDelete(userId))).catch(() => dispatch(failDelete()));

  return deleteUserPromise;
};

export const saveUser = (vendorId: number, user?: Users) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const saveUserPromise = user?.userId ? updateUser(vendorId, user) : createUser(vendorId, user);
  saveUserPromise.then(response => dispatch(completeSave(response.data))).catch(() => dispatch(failSave()));

  return saveUserPromise;
};

export const resetUsers = () => ({
  type: RESET,
});

// Selectors
const isUserManager = (userState: any) => userState.user.roleId === MANAGER;
export const isUserManagerSelector = createSelector(isUserManager, identity);

const isGusIdUser = (state: any) =>
  (state.account.login.user && state.account.login.user.vendor && state.account.login.user.vendor.gusId) ||
  (state.vendors.defaultVendor.defaultVendor && state.vendors.defaultVendor.defaultVendor.gusId);

export const gusIdSelector = createSelector(isGusIdUser, identity);

const isUserId = (state: any) => state.account.login.user.userId;

export const userIdSelector = createSelector(isUserId, identity);

const districtsUserId = (state: any) => state.vendors.users.districtsUserId;

export const districtsUserIdSelector = createSelector(districtsUserId, identity);
