import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import DateRangeForm, { DateRangeFormValues } from 'src/common/components/DateRangeForm';
import InspectionLogsTableRow from 'src/common/components/InspectionLogsTableRow';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageTitle,
  PageActions,
  PageSubtitle,
} from 'src/common/components/styled';
import { Table } from 'src/core/components';
import { Panel } from 'src/core/components/styled';
import { TableCell } from 'src/core/components/Table';
import { TABLE_ROW_HEIGHT } from 'src/core/constants';
import { MAX_INTERVAL_THIRTY_DAYS } from 'src/core/constants/weekdays';
import {
  DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_FROM,
  DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_TO,
} from 'src/fleet/constants/driverInspectionLog';
import { loadDriverInspectionLogs } from 'src/fleet/ducks';
import { Driver } from 'src/fleet/interfaces/Driver';
import { AppState } from 'src/store';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import translate from 'src/core/services/translate';

interface RouteParams {
  driverId: string;
}

const DriverInspectionLogPage: React.FC<RouteComponentProps<RouteParams>> = ({
  match: {
    params: { driverId },
  },
  history,
  location: { pathname, search },
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { firstName } = useSelector((state: AppState) => state.fleet.driver.driver) as unknown as Driver;
  const { driverInspectionLogs } = useSelector((state: AppState) => state.fleet.driverInspection);

  const {
    startDate = DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_FROM,
    endDate = DRIVER_INSPECTION_LOG_DEFAULT_DATE_RANGE_TO,
  } = getQueryParams(search);
  const driverInspectionLogDateRangeFormInitialValues = {
    dateRange: {
      from: startDate,
      to: endDate,
    },
  };
  const onDriverInspectionLogDateRangeChange = ({ dateRange }: Partial<DateRangeFormValues>) => {
    if (dateRange && (dateRange.from !== startDate || dateRange.to !== endDate)) {
      loadDriverInspectionLogs(vendorId, parseInt(driverId), dateRange.from, dateRange.to)(dispatch);
    }
  };
  const inspectionsCells: TableCell[] = [
    {
      name: 'inspectionDate',
      label: translate('vehicles.vehicleInspections.inspectionDate'),
      width: '15%',
      padding: 'defaultCellVertical defaultCellHorizontal defaultCellVertical medium',
      noPaddingRight: true,
    },
    {
      name: 'driver',
      label: translate('common.driver'),
      width: '20%',
      noPaddingRight: true,
    },
    {
      name: 'startTime',
      align: 'center',
      label: translate('common.startTime'),
      width: '15%',
      noPaddingRight: true,
    },
    {
      name: 'endTime',
      align: 'center',
      label: translate('common.endTime'),
      width: '15%',
      noPaddingRight: true,
    },
    {
      name: 'noIssues',
      align: 'center',
      label: translate('vehicles.vehicleInspections.numberOfIssues'),
      width: '15%',
      noPaddingRight: true,
    },
    {
      name: 'options',
      align: 'right',
      label: translate('common.options'),
      width: '20%',
      padding: 'defaultCellVertical medium defaultCellVertical defaultCellHorizontal',
      noPaddingRight: true,
    },
  ];

  const virtualizedProps = {
    height: Math.min(driverInspectionLogs?.length * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT,
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction
              id="back-button"
              onClick={() => (history.length === 1 ? dispatch(push('/fleet/resources')) : history.goBack())}
            >
              <PageBackButtonIcon />
            </PageBackButtonAction>

            <PageTitle>{translate('tooltips.driverInspectionLog')}</PageTitle>
            <PageSubtitle>
              {driverInspectionLogs.length
                ? translate('tooltips.driverInspections.noOfInspections', { count: driverInspectionLogs.length })
                : translate('tooltips.driverInspections.noInspections')}
            </PageSubtitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions flex align="right">
          <DateRangeForm
            intervalAmount={MAX_INTERVAL_THIRTY_DAYS}
            initialValues={driverInspectionLogDateRangeFormInitialValues}
            onChange={onDriverInspectionLogDateRangeChange}
          />
        </PageActions>
      </PageHeader>
      <Panel>
        <Table
          rowProps={{ driverId: parseInt(driverId), driver: firstName }}
          withTopBorder
          scrollMarker
          virtualized
          virtualizedProps={virtualizedProps}
          cells={inspectionsCells}
          rows={driverInspectionLogs}
          rowComponent={InspectionLogsTableRow}
        />
      </Panel>
    </PageContent>
  );
};

export default withRouter(DriverInspectionLogPage);
