import { http } from '../../core/services/http';
import { transformSearchCustomerOnlyWithLocations } from './transformSearchCustomerLocations';

const searchCustomerOnlyWithLocations = (vendorId: number, customerName: string) =>
  http
    .get('/customers/withLocations', {
      params: { vendorId, customerName },
    })
    .then(response => transformSearchCustomerOnlyWithLocations(response.data.customers));

export default searchCustomerOnlyWithLocations;
