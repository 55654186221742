import React, { Component } from 'react';

import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { push } from 'connected-react-router';
import { withRouter, RouteComponentProps } from 'react-router';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
import { PermissionGuard } from '../../../account/components';
import { INSIGHTS_PREFERENCES } from '../../../account/constants';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { ButtonLink, Panel, PanelSection, PanelSectionGroup, PanelSectionTitle } from '../../../core/components/styled';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { dashboardActiveCategorySelector } from '../../services/dashboardActiveCategorySelector';
import { dashboardInsightTypeIdsSelector } from '../../services/dashboardInsightTypeIdsSelector';
import {
  FLEET_INSIGHTS,
  INSIGHTS_COOKIE_DATE_KEY,
  INSIGHTS_COOKIE_START_DATE_KEY,
  SNOW_PLOW_INSIGHTS,
  STREET_SWEEPING_INSIGHTS,
  WASTE_AUDIT_INSIGHTS,
} from '../../constants';
import { getVehicleFiltersPreferencesIds, hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import {
  getChartData,
  getChartOptions,
  getChartPlaceholderText,
  getChartType,
  shouldDisplayChartPlaceholder,
} from '../../services/insightChart';
import { InsightCard } from './insightPageSections';
import { InsightCards } from '../styled';
import { InsightsForm } from '../forms';
import {
  loadFleetInsightReports,
  loadInsightDashboardPreferences,
  loadRunningVehicleCounts,
  loadSnowPlowInsightReports,
  loadStreetSweepingInsightReports,
  loadWasteAuditInsightReports,
  resetInsightReports,
  resetRunningVehicleCounts,
  vehicleTechnicalNameByIdSelector,
} from '../../ducks';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { FilterSetting, FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { TODAY_FORMATTED } from '../../../core/constants/weekdays';
import getInsightCardDetails from '../../services/getInsightCardDetails';
import moment from 'moment';
import translate from '../../../core/services/translate';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, WASTE_AUDIT_ID } from 'src/fleet/constants';

interface Props extends RouteComponentProps {
  activeCategories: {
    [FLEET_INSIGHTS]: boolean;
    [SNOW_PLOW_INSIGHTS]: boolean;
    [STREET_SWEEPING_INSIGHTS]: boolean;
    [WASTE_AUDIT_INSIGHTS]: boolean;
  };
  dashboardInsightTypeIds?: string;
  date?: string;
  daysOfOperation: number[];
  filtersPreferencesIds: FiltersPreferencesIds;
  filtersPreferences: FilterSetting[];
  fleetInsightReports: any[];
  insightDashboardPreferences: any[];
  insightGroupIds: any[];
  isLoadingFleetReports: boolean;
  isLoadingStreetSweeperReports: boolean;
  isLoadingVehicleCounts: boolean;
  isLoadingWasteAuditReports: boolean;
  loadFleetInsightReports: DuckFunction<typeof loadFleetInsightReports>;
  loadInsightDashboardPreferences: DuckFunction<typeof loadInsightDashboardPreferences>;
  loadRunningVehicleCounts: DuckFunction<typeof loadRunningVehicleCounts>;
  loadSnowPlowInsightReports: DuckFunction<typeof loadSnowPlowInsightReports>;
  loadStreetSweepingInsightReports: DuckFunction<typeof loadStreetSweepingInsightReports>;
  loadWasteAuditInsightReports: DuckFunction<typeof loadWasteAuditInsightReports>;
  push: any;
  resetInsightReports: DuckAction<typeof resetInsightReports>;
  resetRunningVehicleCounts: DuckAction<typeof resetRunningVehicleCounts>;
  snowPlowInsightReports: any[];
  streetSweeperInsightReports: any[];
  vehicleTypeId?: number;
  vendorId: number;
  wasteAuditInsightReports: any[];
}

class InsightsPage extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.loadInsightReports();
  }

  async componentDidUpdate(prevProps: Props) {
    const { loadRunningVehicleCounts, location, vendorId, insightGroupIds } = this.props;
    const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
    const { date = moment(insightsDate).format('MM/DD/YYYY') || TODAY_FORMATTED } = getQueryParams(location.search);

    if (location.search !== prevProps.location.search) {
      loadRunningVehicleCounts(vendorId, date, insightGroupIds);
      this.loadInsightReports();
    }
  }

  componentWillUnmount() {
    const { resetInsightReports, resetRunningVehicleCounts } = this.props;

    resetInsightReports();
    resetRunningVehicleCounts();
  }

  onDateChange = (event: any, date: Date | string) => {
    const dateFormatted = moment(date).format('MM/DD/YYYY');
    const dateIsValid = moment(dateFormatted).isValid();

    if (dateIsValid) {
      Cookie.set(INSIGHTS_COOKIE_DATE_KEY, dateFormatted);
      Cookie.set(INSIGHTS_COOKIE_START_DATE_KEY, dateFormatted);

      this.loadReports({ date: dateFormatted });
    }
  };

  onVehicleTypeChange = (event: any, vehicleTypeId: number) => {
    this.loadReports({ vehicleTypeId });
  };

  loadReports = (updatedProps: any) => {
    const { location, push } = this.props;
    const { date, vehicleTypeId } = getQueryParams(location.search);

    push(createUrl(location.pathname, location.search, { date, vehicleTypeId, ...updatedProps }));
  };

  loadInsightReports = () => {
    const {
      activeCategories,
      dashboardInsightTypeIds = '',
      daysOfOperation,
      filtersPreferencesIds,
      insightDashboardPreferences,
      loadFleetInsightReports,
      loadSnowPlowInsightReports,
      loadStreetSweepingInsightReports,
      loadWasteAuditInsightReports,
      location,
      vendorId,
    } = this.props;

    const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
    const { date = moment(insightsDate).format('MM/DD/YYYY') || TODAY_FORMATTED, vehicleTypeId } = getQueryParams(
      location.search,
    );

    resetInsightReports();

    if (activeCategories[FLEET_INSIGHTS]) {
      loadFleetInsightReports(
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        insightDashboardPreferences,
        daysOfOperation,
        filtersPreferencesIds,
      );
    }
    if (activeCategories[STREET_SWEEPING_INSIGHTS]) {
      loadStreetSweepingInsightReports(
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        insightDashboardPreferences,
        daysOfOperation,
        filtersPreferencesIds,
      );
    }
    if (activeCategories[SNOW_PLOW_INSIGHTS]) {
      loadSnowPlowInsightReports(
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        insightDashboardPreferences,
        daysOfOperation,
        filtersPreferencesIds,
      );
    }
    if (activeCategories[WASTE_AUDIT_INSIGHTS]) {
      loadWasteAuditInsightReports(
        vendorId,
        date,
        dashboardInsightTypeIds,
        vehicleTypeId,
        insightDashboardPreferences,
        daysOfOperation,
        filtersPreferencesIds,
      );
    }
  };

  goToDataTrendsPage = (
    endDate?: Date | string,
    vehicleTypeId?: number,
    reportType?: string,
    daysOfOperation?: any[],
  ) => {
    const dataTrendsUrl = createUrl('/insights/reporting-data-trends', undefined, {
      daysOfOperation,
      endDate,
      reportType,
      vehicleTypeId,
    });

    this.props.push(dataTrendsUrl);
  };

  render() {
    const {
      activeCategories,
      date,
      daysOfOperation,
      fleetInsightReports,
      filtersPreferences,
      isLoadingFleetReports,
      isLoadingStreetSweeperReports,
      isLoadingVehicleCounts,
      isLoadingWasteAuditReports,
      location,
      snowPlowInsightReports,
      streetSweeperInsightReports,
      vehicleTypeId,
      wasteAuditInsightReports,
    } = this.props;

    const preferenceSource = location.pathname === '/insights/insightsfleet' ? 'insightsfleet' : 'wasteaudit';

    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('insights.insights')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <PermissionGuard permission={INSIGHTS_PREFERENCES}>
              <ButtonLink
                to={`/insights/settings?date=${date}&source=${preferenceSource}`}
                line
                color="primary"
                margin="no small no no"
                id="insights-preferences-button"
              >
                {translate('common.preferences')}
              </ButtonLink>
            </PermissionGuard>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoadingVehicleCounts}>
            <InsightsForm onDateChange={this.onDateChange} onVehicleTypeChange={this.onVehicleTypeChange} />

            {activeCategories[FLEET_INSIGHTS] && (
              <PanelSection vertical dark padding="sMedium">
                <PanelSectionTitle>{translate('insights.fleetInsights')}</PanelSectionTitle>
                <InsightCards isLoading={isLoadingFleetReports}>
                  {fleetInsightReports.map((fleetInsightReport, index) => (
                    <InsightCard
                      cardDetails={getInsightCardDetails(fleetInsightReport)}
                      chartData={getChartData(fleetInsightReport)}
                      chartOptions={getChartOptions(fleetInsightReport)}
                      chartPlaceholderText={getChartPlaceholderText(fleetInsightReport.reportType)}
                      chartType={getChartType(fleetInsightReport.reportType)}
                      date={date}
                      daysOfOperation={daysOfOperation}
                      displayChartPlaceholder={shouldDisplayChartPlaceholder(fleetInsightReport)}
                      goToDataTrendsPage={this.goToDataTrendsPage}
                      index={index}
                      key={fleetInsightReport.reportType}
                      vehicleTypeId={vehicleTypeId}
                    />
                  ))}
                </InsightCards>
              </PanelSection>
            )}

            {activeCategories[STREET_SWEEPING_INSIGHTS] &&
              hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: STREET_SWEEPER_ID }) && (
                <PanelSection vertical dark padding="sMedium">
                  <PanelSectionTitle>{translate('insights.streetSweepingInsights')}</PanelSectionTitle>
                  <InsightCards isLoading={isLoadingStreetSweeperReports}>
                    {streetSweeperInsightReports.map((streetSweeperInsightReport, index) => (
                      <InsightCard
                        cardDetails={getInsightCardDetails(streetSweeperInsightReport)}
                        chartData={getChartData(streetSweeperInsightReport)}
                        chartOptions={getChartOptions(streetSweeperInsightReport)}
                        chartPlaceholderText={getChartPlaceholderText(streetSweeperInsightReport.reportType)}
                        chartType={getChartType(streetSweeperInsightReport.reportType)}
                        date={date}
                        daysOfOperation={daysOfOperation}
                        displayChartPlaceholder={shouldDisplayChartPlaceholder(streetSweeperInsightReport)}
                        goToDataTrendsPage={this.goToDataTrendsPage}
                        index={index}
                        key={streetSweeperInsightReport.reportType}
                      />
                    ))}
                  </InsightCards>
                </PanelSection>
              )}

            {activeCategories[SNOW_PLOW_INSIGHTS] &&
              hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: SNOW_PLOW_ID }) && (
                <PanelSection vertical dark padding="sMedium">
                  <PanelSectionTitle>{translate('insights.snowPlowInsights')}</PanelSectionTitle>
                  <InsightCards isLoading={isLoadingStreetSweeperReports}>
                    {snowPlowInsightReports.map((snowPlowInsightReport, index) => (
                      <InsightCard
                        cardDetails={getInsightCardDetails(snowPlowInsightReport)}
                        chartData={getChartData(snowPlowInsightReport)}
                        chartOptions={getChartOptions(snowPlowInsightReport)}
                        chartPlaceholderText={getChartPlaceholderText(snowPlowInsightReport.reportType)}
                        chartType={getChartType(snowPlowInsightReport.reportType)}
                        date={date}
                        daysOfOperation={daysOfOperation}
                        displayChartPlaceholder={shouldDisplayChartPlaceholder(snowPlowInsightReport)}
                        goToDataTrendsPage={this.goToDataTrendsPage}
                        index={index}
                        key={snowPlowInsightReport.reportType}
                      />
                    ))}
                  </InsightCards>
                </PanelSection>
              )}

            {activeCategories[WASTE_AUDIT_INSIGHTS] &&
              hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: WASTE_AUDIT_ID }) && (
                <PanelSection vertical dark padding="sMedium">
                  <PanelSectionTitle>{translate('insights.wasteAuditInsights')}</PanelSectionTitle>
                  <InsightCards isLoading={isLoadingWasteAuditReports}>
                    {wasteAuditInsightReports.map((wasteAuditInsightReport, index) => (
                      <InsightCard
                        cardDetails={getInsightCardDetails(wasteAuditInsightReport)}
                        chartData={getChartData(wasteAuditInsightReport)}
                        chartOptions={getChartOptions(wasteAuditInsightReport)}
                        chartPlaceholderText={getChartPlaceholderText(wasteAuditInsightReport.reportType)}
                        chartType={getChartType(wasteAuditInsightReport.reportType)}
                        date={date}
                        daysOfOperation={daysOfOperation}
                        displayChartPlaceholder={shouldDisplayChartPlaceholder(wasteAuditInsightReport)}
                        goToDataTrendsPage={this.goToDataTrendsPage}
                        index={index}
                        key={wasteAuditInsightReport.reportType}
                      />
                    ))}
                  </InsightCards>
                </PanelSection>
              )}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const formSelector = formValueSelector('insights');

const mapStateToProps = (state: AppState) => {
  const vehicleTypeId = Number(formSelector(state, 'vehicleTypeId'));
  const { insightDashboardPreferences, insightReports, runningVehicleCounts } = state.insights;
  const filtersPreferencesIds = {
    vehicleTypeIdsCSV: getVehicleFiltersPreferencesIds(state.common.filters.filters).join(','),
    supervisorIdsCSV: '',
    serviceZoneIdsCSV: '',
  };

  return {
    activeCategories: (dashboardActiveCategorySelector as any)(insightDashboardPreferences),
    dashboardInsightTypeIds: dashboardInsightTypeIdsSelector(insightDashboardPreferences),
    date: moment(formSelector(state, 'date')).format('MM/DD/YYYY'),
    daysOfOperation: insightDashboardPreferences.insightDashboardDaysOfOperation,
    filtersPreferencesIds,
    filtersPreferences: state.common.filters.filters,
    fleetInsightReports: insightReports.fleetInsightReports,
    insightDashboardPreferences: insightDashboardPreferences.insightDashboardPreferences,
    isLoadingFleetReports: insightReports.isLoadingFleet,
    isLoadingStreetSweeperReports: insightReports.isLoadingStreetSweeper,
    isLoadingVehicleCounts: runningVehicleCounts.isLoading,
    isLoadingWasteAuditReports: insightReports.isLoadingWasteAudit,
    snowPlowInsightReports: insightReports.snowPlowInsightReports,
    streetSweeperInsightReports: insightReports.streetSweeperInsightReports,
    vehicleTechnicalName: vehicleTechnicalNameByIdSelector(runningVehicleCounts, vehicleTypeId),
    vehicleTypeId,
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    wasteAuditInsightReports: insightReports.wasteAuditInsightReports,
  };
};

const mapDispatchToProps = {
  loadFleetInsightReports,
  loadInsightDashboardPreferences,
  loadRunningVehicleCounts,
  loadSnowPlowInsightReports,
  loadStreetSweepingInsightReports,
  loadWasteAuditInsightReports,
  push,
  resetInsightReports,
  resetRunningVehicleCounts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsightsPage));
