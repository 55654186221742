import { PureComponent } from 'react';

import { ActionButtonTooltip } from '.';
import {
  ImageSlider as ImageSliderContainer,
  ImageSliderContent,
  ImageSliderImage,
  ImageSliderLoading,
  ImageSliderNavigation,
  ImageSliderRotate,
  ImageSliderDownload,
  ImageSliderDownloadIcon,
  ImageSliderPosition,
  ImageSliderNavigationIcon,
} from './styled';
import { downloadFile } from '../services/downloadFile';
import translate from '../services/translate';

interface Props {
  images: any[];
  imagesToDownload: any[];
  imageTitle: string;
  margin?: string;
  onIndexChange?: (index: number) => void;
}

interface State {
  index: number;
  rotationAngle: number;
  isLoading: boolean;
}

class ImageSlider extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { index: 0, rotationAngle: 0, isLoading: true };
  }

  onImageLoad = () => this.setState({ isLoading: false });

  goToPreviousImage = () => {
    const { onIndexChange } = this.props;

    this.setState(({ index }) => {
      const nextIndex = index - 1;

      if (onIndexChange) onIndexChange(nextIndex);
      return { index: nextIndex, rotationAngle: 0, isLoading: true };
    });
  };

  goToNextImage = () => {
    const { onIndexChange } = this.props;

    this.setState(({ index }) => {
      const nextIndex = index + 1;

      if (onIndexChange) onIndexChange(nextIndex);
      return { index: nextIndex, rotationAngle: 0, isLoading: true };
    });
  };

  rotateClockwise = () =>
    this.setState(({ rotationAngle }) => ({
      rotationAngle: rotationAngle + 90,
    }));

  render() {
    const { images, imagesToDownload, margin, imageTitle } = this.props;
    const { index, rotationAngle, isLoading } = this.state;

    return (
      <ImageSliderContainer margin={margin}>
        <ImageSliderContent>
          <ImageSliderImage onLoad={this.onImageLoad} src={images[index]} rotationAngle={rotationAngle} />
        </ImageSliderContent>

        {isLoading && <ImageSliderLoading />}

        {index > 0 && (
          <ImageSliderNavigation previous onClick={this.goToPreviousImage} disabled={isLoading}>
            <ImageSliderNavigationIcon icon="arrowLeft" />
          </ImageSliderNavigation>
        )}

        {index < images.length - 1 && (
          <ImageSliderNavigation next onClick={this.goToNextImage} disabled={isLoading}>
            <ImageSliderNavigationIcon icon="arrowRight" />
          </ImageSliderNavigation>
        )}

        <ImageSliderRotate onClick={this.rotateClockwise}>
          <ActionButtonTooltip icon="rotateClockwise" tooltip="rotateClockwise" />
        </ImageSliderRotate>

        <ImageSliderPosition>
          {index + 1} / {images.length}
        </ImageSliderPosition>

        <ImageSliderDownload onClick={() => downloadFile(imagesToDownload[index], imageTitle)}>
          <ImageSliderDownloadIcon /> {translate('common.download')}
        </ImageSliderDownload>
      </ImageSliderContainer>
    );
  }
}

export default ImageSlider;
