import { connect } from 'react-redux';
import { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from 'src/store';
import { ActionButtonTooltip } from 'src/core/components';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import {
  deleteLowInventoryPart,
  editLowInventoryPart,
  loadContainersStatistics,
  loadLowInventoryParts,
} from 'src/fleet/ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { TableActionButton, TableCell, TableRow } from 'src/core/components/styled';
import AddRemoveInventoryModalResolver from './AddRemoveInventoryModalResolver';
import confirm from 'src/core/services/confirm';
import EditInventoryModal from './EditInventoryModal';
import LowInventoryPartHistoryModalResolver from './LowInventoryPartHistoryModalResolver';
import translate from 'src/core/services/translate';

interface Props extends RouteComponentProps {
  containerTypeId: number;
  currentQuantity: number;
  deleteLowInventoryPart: DuckFunction<typeof deleteLowInventoryPart>;
  editLowInventoryPart: DuckFunction<typeof editLowInventoryPart>;
  id: number;
  isSupport?: boolean;
  isViewOnly?: boolean;
  loadContainersStatistics: DuckFunction<typeof loadContainersStatistics>;
  loadLowInventoryParts: DuckFunction<typeof loadLowInventoryParts>;
  name: string;
  vendorId: number;
  weeklyUsage: number;
  weeksToNoInventory: number;
}

function LowInventoryPartsTableRow({
  containerTypeId,
  currentQuantity,
  deleteLowInventoryPart,
  editLowInventoryPart,
  id,
  isSupport,
  isViewOnly,
  loadContainersStatistics,
  loadLowInventoryParts,
  location: { search },
  name,
  vendorId,
  weeklyUsage,
  weeksToNoInventory,
}: Props) {
  const [isLowInventoryPartHistoryModalVisible, setIsLowInventoryPartHistoryModalVisible] = useState(false);
  const [isAddRemoveInventoryModalVisible, setIsAddRemoveInventoryModalVisible] = useState(false);
  const [isAddInventory, setIsAddInventory] = useState(false);
  const [isEditInventoryModalVisible, setIsEditInventoryModalVisible] = useState(false);

  const viewAddRemoveInventoryModal = (isAddInventory?: boolean) => {
    setIsAddRemoveInventoryModalVisible(true);
    setIsAddInventory(isAddInventory || false);
  };

  const deletePart = async (id: number) => {
    if (!(await confirm(translate('containers.alertMessages.confirmDeleteInventory')))) return;

    deleteLowInventoryPart(vendorId, id)
      .then(() => {
        refreshData();
        createSuccessNotification(translate('containers.alertMessages.inventoryDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('containers.alertMessages.inventoryDeletedFailed'));
      });
  };

  const saveEditInventory = (formData: any) => {
    editLowInventoryPart(vendorId, id, formData.partName)
      .then(() => {
        refreshData();
        createSuccessNotification(translate('containers.alertMessages.inventorySaved'));
        setIsEditInventoryModalVisible(false);
      })
      .catch(() => {
        createErrorNotification(translate('containers.alertMessages.inventoryEditFailed'));
      });
  };

  const refreshData = () => {
    loadLowInventoryParts(vendorId, containerTypeId);

    const { searchTerm, locationId, equipmentTypeId, equipmentSizeId, equipmentConditionId } = getQueryParams(search);
    loadContainersStatistics(
      vendorId,
      containerTypeId,
      equipmentTypeId,
      equipmentTypeId ? equipmentSizeId : undefined,
      equipmentConditionId,
      searchTerm,
      locationId,
    );
  };

  return (
    <>
      <TableRow>
        <TableCell width="20%">{name}</TableCell>
        <TableCell width="20%">{currentQuantity}</TableCell>
        <TableCell width="20%">{weeklyUsage}</TableCell>
        <TableCell width="20%">{weeksToNoInventory === 999 ? '-' : weeksToNoInventory}</TableCell>
        <TableCell width="20%" align="right">
          <>
            {!isViewOnly && !isSupport && (
              <>
                <TableActionButton onClick={() => viewAddRemoveInventoryModal()} margin="xSmall small no no">
                  <ActionButtonTooltip size="small" icon="minus-simple" tooltip="remove" />
                </TableActionButton>
                <TableActionButton onClick={() => viewAddRemoveInventoryModal(true)}>
                  <ActionButtonTooltip size="small" icon="plus-simple" tooltip="add" />
                </TableActionButton>
                <TableActionButton onClick={() => setIsEditInventoryModalVisible(true)}>
                  <ActionButtonTooltip size="small" icon="edit" tooltip="edit" />
                </TableActionButton>
                <TableActionButton onClick={() => deletePart(id)}>
                  <ActionButtonTooltip size="small" icon="delete" tooltip="delete" />
                </TableActionButton>
              </>
            )}

            <TableActionButton onClick={() => setIsLowInventoryPartHistoryModalVisible(true)}>
              <ActionButtonTooltip size="small" icon="plowHistory" tooltip="viewHistory" />
            </TableActionButton>
          </>
        </TableCell>
      </TableRow>

      {isLowInventoryPartHistoryModalVisible && (
        <LowInventoryPartHistoryModalResolver
          closeModal={() => setIsLowInventoryPartHistoryModalVisible(false)}
          id={id}
          partName={name}
        />
      )}

      {isAddRemoveInventoryModalVisible && (
        <AddRemoveInventoryModalResolver
          closeModal={() => setIsAddRemoveInventoryModalVisible(false)}
          containerTypeId={containerTypeId}
          currentQuantity={currentQuantity}
          id={id}
          isAddInventory={isAddInventory}
        />
      )}

      {isEditInventoryModalVisible && (
        <EditInventoryModal
          closeModal={() => setIsEditInventoryModalVisible(false)}
          id={id}
          partName={name}
          saveEditInventory={saveEditInventory}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  deleteLowInventoryPart,
  editLowInventoryPart,
  loadContainersStatistics,
  loadLowInventoryParts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LowInventoryPartsTableRow));
