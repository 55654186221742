import React from 'react';
import { useSelector } from 'react-redux';
import { transparentize } from 'polished';
import { PanelSection, PanelSectionGroup, Text } from '../../../../core/components/styled';
import { AppState } from '../../../../store';
import { theme } from '../../../../core/styles';
import DoughnutChart from '../../../../core/components/DoughnutChart';
import HorizontalBarChart from '../../../../core/components/HorizontalBarChart';
import { VehiclesPageChartsWrapper, VehiclesPageFleetInsightWrapper } from '../../styled/VehiclesPage';
import translate from '../../../../core/services/translate';
import { camelCase } from 'lodash-es';
import { kFormatter } from '../../../../utils/services/formatter';

const barChartColors = [theme.brandPrimary, theme.brandWarning, theme.brandAlert, theme.brandInfo, theme.brandDefault];

const VehiclesPageCharts: React.FC = () => {
  const summary = useSelector((state: AppState) => state.fleet.vehicles.vehicleSummary);
  const summaryLoading = useSelector((state: AppState) => state.fleet.vehicles.vehicleSummaryLoading);
  const vendorHasActivePM = useSelector((state: AppState) => state.fleet.preventativeMaintenance.vendorHasActivePM);

  const chartsMemo = React.useMemo(() => {
    if (!summary) {
      return null;
    }

    /** Only first five will be displayed. */
    const topTripInspectionIssues = summary.topTripInspectionIssues ? summary.topTripInspectionIssues.slice(0, 5) : [];

    return (
      <>
        <VehiclesPageChartsWrapper>
          <DoughnutChart
            showLegend
            title={translate('vehicles.charts.fleetSummary')}
            doughnutSize={150}
            dataset={{
              data: summary.fleetSummary
                ? [summary.fleetSummary.flaggedPercentage, summary.fleetSummary.notFlaggedPercentage]
                : [],
              backgroundColor: [theme.brandAlert, theme.brandPrimary],
            }}
            labels={[translate('vehicles.charts.flaggedForMaintenance'), translate('vehicles.charts.notFlagged')]}
          />

          {vendorHasActivePM && (
            <DoughnutChart
              showLegend
              title={translate('vehicles.charts.pmSummary')}
              doughnutSize={150}
              dataset={{
                data: summary.preventativeMaintenanceSummary
                  ? [
                      summary.preventativeMaintenanceSummary.almostDuePercentage,
                      summary.preventativeMaintenanceSummary.duePercentage,
                      summary.preventativeMaintenanceSummary.pastDuePercentage,
                    ]
                  : [],
                backgroundColor: [theme.brandPrimary, theme.brandWarning, theme.brandAlert],
              }}
              labels={[
                translate('vehicles.charts.almostDue'),
                translate('vehicles.charts.due'),
                translate('vehicles.charts.pastDue'),
              ]}
            />
          )}

          <VehiclesPageFleetInsightWrapper isHidden={!topTripInspectionIssues.length}>
            <HorizontalBarChart
              chartWidth={400}
              chartHeight={170}
              title={translate('vehicles.charts.top5FleetIssues')}
              dataset={{
                data: topTripInspectionIssues.map(issue => issue.count),
                backgroundColor: topTripInspectionIssues.map((issue, index) =>
                  transparentize(0.9, barChartColors[index]),
                ),
                borderColor: barChartColors,
                borderWidth: 1,
              }}
              labels={topTripInspectionIssues.map(
                issue =>
                  `${translate(
                    `vendors.tripInspection.subCategories.${camelCase(issue.tripInspectionIssue.technicalName)}`,
                  )} (${kFormatter(issue.count)})`,
              )}
            />
          </VehiclesPageFleetInsightWrapper>
        </VehiclesPageChartsWrapper>
        <Text weight="light" fontStyle="italic">
          {translate('vehicles.charts.defaultInfoMessageForDisplayedData')}
        </Text>
      </>
    );
  }, [summary, vendorHasActivePM]);

  return (
    <PanelSection withBorder isLoading={summaryLoading} padding={summaryLoading && !summary ? 'small no' : 'no'}>
      <PanelSectionGroup padding="xSmall" width="100%">
        {chartsMemo}
      </PanelSectionGroup>
    </PanelSection>
  );
};

export default VehiclesPageCharts;
