import { FC } from 'react';

import { DetailsListHeader, Popover, Text } from 'src/core/components/styled';
import {
  PopoverDetailsContainer,
  PopoverDetailsContainerWrapper,
} from 'src/customers/components/styled/CustomerLocations';
import translate from 'src/core/services/translate';

export const CustomerLocationPopover: FC<{ binNumbers: string[] }> = ({ binNumbers }) => (
  <Popover align="left">
    <DetailsListHeader>
      <Text size="medium" padding="no no xSmall xSmall" weight="medium">
        {translate('common.binNumber')}
      </Text>
    </DetailsListHeader>

    <PopoverDetailsContainerWrapper>
      <PopoverDetailsContainer>
        {binNumbers.map(binNumber => {
          return (
            <Text size="sMedium" color="grayDarker" block>
              {binNumber}
            </Text>
          );
        })}
      </PopoverDetailsContainer>
    </PopoverDetailsContainerWrapper>
  </Popover>
);
