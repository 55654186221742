import React, { ChangeEvent, Fragment, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getFormValues, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import { ActionRequiredContainer } from '../../../fleet/components/styled/RubiconDispatches';
import { CHANGE_REQUESTS_TYPE } from '../../constants';
import { DATE_RANGE_PICKER_TODAY_3 } from '../../../core/constants';
import { DateRangePicker, MultiSelect, PanelSearch, TypedField, Switch } from '../../../core/components';
import { DateRangeOptionValue } from '../../../core/components/DateRangePicker';
import { Grid, GridColumn, PanelSection, Text, ToggleIcon } from '../../../core/components/styled';
import rubiconDispatchesFormInitialValuesSelector from '../../../fleet/services/rubiconDispatchesFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { ToggleContainer } from '../../../routes/components/styled';
import { useIsMobileView } from 'src/utils/hooks';

export interface ServiceChangeRequestsFilterFormProps {
  divisionOptions: any[];
  equipmentOptions: any[];
  materialTypes: any[];
  onActionRequiredToggleChange: (event: any, isActionRequired: boolean) => void;
  onDateChange: (event: ChangeEvent<HTMLInputElement>, date: DateRangeOptionValue) => void;
  onDivisionChange: (event: any, divisionIds: string[]) => void;
  onEquipmentChange: (event: any, equipmentIds: string[]) => void;
  onMaterialTypeChange: (event: any, materialTypeIds: string[]) => void;
  onRequestTypeChange: (event: any, requestTypeIds: number[]) => void;
  onSearchChange?: (event: any, searchTerm: string) => void;
  totalActionRequiredCount: number;
}
interface Props extends ServiceChangeRequestsFilterFormProps, RouteComponentProps {}

interface PropsWithoutReduxForm extends Props {
  formValues: any;
}

type ComponentProps = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;
const ServiceChangeRequestsFilterForm: React.FC<ComponentProps> = ({
  divisionOptions,
  equipmentOptions,
  formValues,
  materialTypes,
  onActionRequiredToggleChange,
  onDateChange,
  onDivisionChange,
  onEquipmentChange,
  onMaterialTypeChange,
  onRequestTypeChange,
  onSearchChange,
  totalActionRequiredCount,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDateDisabled = !!formValues && formValues.isActionRequired;

  const isMobile = useIsMobileView();
  const onToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <form>
      <Fragment>
        <PanelSection padding="small xSmall" withBorder>
          <Grid multiLine column={isMobile}>
            {!isMobile && (
              <GridColumn size="3/12">
                <Field name="searchTerm" component={PanelSearch as any} onChange={onSearchChange} />
              </GridColumn>
            )}
            <GridColumn margin={isMobile ? 'small no' : 'no'} size={isMobile ? '12/12' : '3/12'}>
              <TypedField
                name="date"
                component={DateRangePicker as any}
                onChange={onDateChange}
                props={{
                  hasMarginLeft: 'small',
                  options: DATE_RANGE_PICKER_TODAY_3,
                  disabledDays: [
                    {
                      before: moment().subtract(60, 'days').toDate(),
                    },
                  ],
                  tabletAlignLeft: isMobile,
                  numberOfMonths: isMobile ? 1 : undefined,
                  maxInterval: { amount: 31, unit: 'days' },
                  margin: 'no',
                  disabled: isDateDisabled,
                }}
              />
            </GridColumn>
            <GridColumn margin={isMobile ? 'small no' : 'no'} size={isMobile ? '12/12' : '2/12'}>
              <TypedField
                name="requestTypeIds"
                component={MultiSelect}
                onChange={onRequestTypeChange}
                props={{
                  margin: isMobile ? 'small' : 'no',
                  placeholder: translate('opportunity.allRequests'),
                  options: map(CHANGE_REQUESTS_TYPE, (requestType: any) => ({
                    label: requestType.name,
                    value: requestType.id,
                  })),
                  formatText(selectedOptions: any[], allSelected: boolean) {
                    return allSelected
                      ? translate('opportunity.allRequests')
                      : translate('common.xRequestsSelected', { selected: selectedOptions.length });
                  },
                }}
              />
            </GridColumn>

            {!isMobile && (
              <GridColumn size={isMobile ? '12/12' : '1/12'}>
                <ToggleContainer id="toggle-filter-button" onClick={onToggle}>
                  <ToggleIcon size={10} isOpen={isExpanded} icon={isExpanded ? 'remove' : 'add'} />
                  {` ${translate('common.filter')}`}
                </ToggleContainer>
              </GridColumn>
            )}
            <GridColumn margin={isMobile ? 'small no' : 'no'} size={isMobile ? '12/12' : '2/12'}>
              <ActionRequiredContainer>
                <Text block size="small" weight="medium" margin="no no xxSmall">
                  {translate('autoDispatch.actionRequired')}
                </Text>
                <Field
                  name="isActionRequired"
                  component={Switch}
                  onChange={onActionRequiredToggleChange}
                  props={{
                    label: translate('autoDispatch.workOrdersCount', { count: totalActionRequiredCount }),
                    margin: 'no',
                    color: 'alert',
                  }}
                />
              </ActionRequiredContainer>
            </GridColumn>
            {isExpanded && (
              <>
                <GridColumn margin={isMobile ? 'small no' : 'no'} size={isMobile ? '12/12' : '3/12'}>
                  <TypedField
                    name="equipmentIds"
                    component={MultiSelect}
                    onChange={onEquipmentChange}
                    props={{
                      margin: isMobile ? 'small' : 'no',
                      placeholder: translate('common.allEquipments'),
                      options: map(equipmentOptions, (equipment: any) => ({
                        label: equipment.name,
                        value: equipment.id,
                      })),
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('opportunity.allEquipments')
                          : translate('common.xEquipmentsSelected', { selected: selectedOptions.length });
                      },
                      isSearchable: true,
                    }}
                  />
                </GridColumn>
                <GridColumn margin={isMobile ? 'small no' : 'no'} size={isMobile ? '12/12' : '3/12'}>
                  <TypedField
                    name="materialTypeIds"
                    component={MultiSelect}
                    onChange={onMaterialTypeChange}
                    props={{
                      margin: isMobile ? 'small' : 'no',
                      placeholder: translate('common.allMaterialTypes'),
                      options: map(materialTypes, (material: any) => ({
                        label: material.name,
                        value: material.id,
                      })),
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('opportunity.allMaterialTypes')
                          : translate('common.xMaterialTypesSelected', { selected: selectedOptions.length });
                      },
                      isSearchable: true,
                    }}
                  />
                </GridColumn>
                <GridColumn margin={isMobile ? 'small no' : 'no'} size={isMobile ? '12/12' : '3/12'}>
                  <TypedField
                    name="divisionIds"
                    component={MultiSelect}
                    onChange={onDivisionChange}
                    props={{
                      margin: isMobile ? 'small' : 'no',
                      placeholder: translate('autoDispatch.divisions'),
                      options: divisionOptions.map((division: any) => ({
                        label: `${division.id} (${division.name})`,
                        value: division.id,
                      })),
                      formatText(selectedOptions: any[], allSelected: boolean) {
                        return allSelected
                          ? translate('autoDispatch.allDivisions')
                          : translate('common.xDivisionsSelected', { selected: selectedOptions.length });
                      },
                      isSearchable: true,
                    }}
                  />
                </GridColumn>
              </>
            )}
          </Grid>
        </PanelSection>
      </Fragment>
    </form>
  );
};

const mapStateToProps = (state: AppState, { location: { search } }: Props) => ({
  initialValues: rubiconDispatchesFormInitialValuesSelector(search),
  formValues: getFormValues('serviceChangeRequestsFilter')(state),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'serviceChangeRequestsFilter',
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(ServiceChangeRequestsFilterForm),
  ),
);
