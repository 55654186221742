import { useRef, useState } from 'react';

import VideoMessageModalResolver from 'src/common/components/VideoMessageModalResolver';
import VideoMessageTriggerModal from 'src/common/components/VideoMessageTriggerModal';
import FloatingCollapsible from 'src/core/components/FloatingCollapsible';
import { VEHICLE_TYPES } from 'src/fleet/constants';
import { Link } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';
import { time } from '../../../../utils/services/formatter';
import {
  fleetInsightCountSelector,
  fleetInsightsSelector,
  loadFleetInsights,
  removeFleetInsight,
} from '../../../ducks';
import {
  DashboardFleetInsightsContainer,
  DashboardFleetInsightsCount,
  DashboardFleetInsightsDetailsTable,
  DashboardFleetInsightsDetailsTableCell,
  DashboardFleetInsightsDetailsTableContainer,
  DashboardFleetInsightsHeaderTable,
  DashboardFleetInsightsHeaderTableCell,
  DashboardFleetInsightsNoResults,
  DashboardFleetInsightsRemove,
  DashboardFleetInsightsRemoveIcon,
  DashboardFleetInsightsTableBody,
  DashboardFleetInsightsTableContainer,
  DashboardFleetInsightsTableRow,
} from '../../styled';

import { debounce, last } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useSelector } from 'src/core/hooks/useSelector';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import {
  LOADS_DUMPED_FLEET_INSIGHT,
  STREET_SWEEPER_FLEET_INSIGHT,
  VIDEO_DOWNLOAD,
  WATER_FILL_UPS_FLEET_INSIGHT,
} from 'src/dashboard/constants/dashboardFleetInsights';
import { getSelectedVehicleTypes } from 'src/dashboard/services/getDashboardFilterFormData';
import { getPersistentFilters } from 'src/dashboard/services/persistentFilterStorage';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { MapboxFiltersFormValues } from '../../forms/DashboardMapboxFiltersForm';
import { TODAY_FORMATTED } from 'src/core/constants';

const DashboardFleetInsightsMapbox = () => {
  const [isOpen] = useState(false);
  const [isVideoFootageModalOpen, setIsVideoFootageModalOpen] = useState(false);
  const [videoDownloadJobId, setVideoDownloadJobId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const detailsTableContainerRef = useRef<HTMLDivElement | null>(null);

  const controlSpacing = useSelector(state => state.dashboard.mapControls.spacing);
  const messagesControl = useSelector(state => state.dashboard.mapControls.messages);
  const feedbackControl = useSelector(state => state.dashboard.mapControls.feedback);
  const vendorId = useSelector(currentVendorId);
  const isLoading = useSelector(state => state.dashboard.fleetInsights.isLoading);
  const fleetInsightCount = useSelector(state => fleetInsightCountSelector(state.dashboard.fleetInsights)) || 0;
  const fleetInsights = useSelector(state => fleetInsightsSelector(state.dashboard.fleetInsights)) || [];
  const formValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;
  const date = formValues?.date || TODAY_FORMATTED;

  let xOffset = controlSpacing;
  let yOffset = controlSpacing;

  if (feedbackControl.isVisible) {
    xOffset += controlSpacing + feedbackControl.width;
  }

  if (messagesControl.isVisible) {
    yOffset += controlSpacing + messagesControl.height;
  }

  const onDetailsTableContainerScroll = debounce(() => {
    if (detailsTableContainerRef.current && date && vendorId) {
      const canLoadMoreFleetInsights = fleetInsights && fleetInsights.length < fleetInsightCount;
      const isScrolledToBottom =
        detailsTableContainerRef.current.scrollTop + detailsTableContainerRef?.current.clientHeight ===
        detailsTableContainerRef.current.scrollHeight;

      if (isScrolledToBottom && !isLoading && canLoadMoreFleetInsights && date) {
        const persistentFilters = getPersistentFilters(vendorId as any);
        const vehicleTypesIds = getSelectedVehicleTypes(persistentFilters).map(
          vehicleType => VEHICLE_TYPES[vehicleType].id,
        );
        loadFleetInsights(vendorId, date, 10, last<any>(fleetInsights).insightId, vehicleTypesIds)(dispatch);
      }
    }
  }, 200);

  const toggleVideoFootageModal = (isOpen: boolean, videoDownloadJobId?: number) => {
    setIsVideoFootageModalOpen(isOpen);
    setVideoDownloadJobId(videoDownloadJobId);
  };

  return (
    <DashboardFleetInsightsContainer xOffset={xOffset} yOffset={yOffset}>
      <FloatingCollapsible
        isOpenByDefault={isOpen}
        title={translate('dashboard.fleetInsights')}
        minWidthWhenClosed={150}
        widthWhenOpen={545}
        renderAfterTitle={() =>
          fleetInsightCount > 0 && <DashboardFleetInsightsCount>{fleetInsightCount}</DashboardFleetInsightsCount>
        }
      >
        <DashboardFleetInsightsTableContainer>
          <DashboardFleetInsightsHeaderTable>
            <DashboardFleetInsightsTableBody>
              <DashboardFleetInsightsTableRow>
                <DashboardFleetInsightsHeaderTableCell width="20%">{date}</DashboardFleetInsightsHeaderTableCell>
                <DashboardFleetInsightsHeaderTableCell width="25%">
                  {translate('routes.route')}
                </DashboardFleetInsightsHeaderTableCell>
                <DashboardFleetInsightsHeaderTableCell width="55%">
                  {translate('dashboard.insight')}
                </DashboardFleetInsightsHeaderTableCell>
              </DashboardFleetInsightsTableRow>
            </DashboardFleetInsightsTableBody>
          </DashboardFleetInsightsHeaderTable>

          <DashboardFleetInsightsDetailsTableContainer
            onScroll={onDetailsTableContainerScroll}
            ref={detailsTableContainerRef}
          >
            <DashboardFleetInsightsDetailsTable>
              <DashboardFleetInsightsTableBody>
                {!!fleetInsights &&
                  fleetInsights.map(
                    (insight: any, insightIndex: number) =>
                      !insight.isDismissed && (
                        <DashboardFleetInsightsTableRow
                          key={insight.insightId}
                          id={`dashboard-fleet-insights-${insightIndex}`}
                        >
                          <DashboardFleetInsightsDetailsTableCell
                            width="20%"
                            id={`dashboard-fleet-insights-${insightIndex}-date`}
                          >
                            {time(insight.timestamp)}
                          </DashboardFleetInsightsDetailsTableCell>

                          <DashboardFleetInsightsDetailsTableCell
                            width="25%"
                            id={`dashboard-fleet-insights-${insightIndex}-route`}
                          >
                            {insight.vehicleType === STREET_SWEEPER_FLEET_INSIGHT &&
                            (insight.reportType === LOADS_DUMPED_FLEET_INSIGHT ||
                              insight.reportType === WATER_FILL_UPS_FLEET_INSIGHT) ? (
                              '-'
                            ) : insight.route?.id ? (
                              <Link to={`/routes/route-tracker/${insight.route.id}`}>{insight.route.name}</Link>
                            ) : (
                              insight.route?.name || '-'
                            )}
                          </DashboardFleetInsightsDetailsTableCell>

                          <DashboardFleetInsightsDetailsTableCell
                            width="55%"
                            id={`dashboard-fleet-insights-${insightIndex}-insight`}
                          >
                            {insight.reportType === VIDEO_DOWNLOAD ? (
                              <>
                                {insight.vehicle.name}: {translate('routes.videoRequest.videoAvailable')}.{' '}
                                <VideoMessageTriggerModal
                                  onOpenVideoFootageModal={() =>
                                    dispatch(toggleVideoFootageModal(true, Number(insight.value1Formatted)))
                                  }
                                />
                              </>
                            ) : !insight.isVideoFootage ? (
                              translate(`dashboard.${insight.messageFormatUi}`, {
                                message: insight.message,
                                insightValue: insight.value1Formatted,
                                insightSecondaryValue: insight.value2Formatted,
                                vehicleName: insight.vehicle.name,
                                geoFenceName: insight.geoFence?.name,
                              })
                            ) : (
                              insight.message
                            )}

                            <DashboardFleetInsightsRemove
                              onClick={() => {
                                removeFleetInsight(insight.insightId)(dispatch);
                              }}
                              id={`dashboard-fleet-insights-${insightIndex}-remove-button`}
                            >
                              <DashboardFleetInsightsRemoveIcon />
                            </DashboardFleetInsightsRemove>
                          </DashboardFleetInsightsDetailsTableCell>
                        </DashboardFleetInsightsTableRow>
                      ),
                  )}
              </DashboardFleetInsightsTableBody>
            </DashboardFleetInsightsDetailsTable>
          </DashboardFleetInsightsDetailsTableContainer>

          {fleetInsightCount === 0 && (
            <DashboardFleetInsightsNoResults>
              {translate('dashboard.noFleetInsights', { date })}.
            </DashboardFleetInsightsNoResults>
          )}

          {isVideoFootageModalOpen && (
            <VideoMessageModalResolver
              videoDownloadJobId={videoDownloadJobId}
              closeModal={() => toggleVideoFootageModal(false)}
            />
          )}
        </DashboardFleetInsightsTableContainer>
      </FloatingCollapsible>
    </DashboardFleetInsightsContainer>
  );
};

export default DashboardFleetInsightsMapbox;
