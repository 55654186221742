import { filter, find, groupBy, map } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { TypedField } from 'src/core/components';

import { Button, Separator } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { ExpandableMapFiltersSection } from 'src/customers/components/forms/ExpandableMapFiltersSection';
import { ExpandableMapFiltersSubsection } from 'src/customers/components/forms/ExpandableMapFiltersSubsection';
import { getActiveCityAlertTypes } from 'src/fleet/components/forms/LocationAndCityAlertsSearchForm';
import { TravelPathField } from 'src/routes/components/forms';
import {
  RouteMapFiltersClose,
  RouteMapFiltersCloseIcon,
  RouteMapFiltersSection as RouteMapFiltersSectionWrapper,
  RouteMapFiltersTitle,
  RouteMapFiltersWrapper,
} from 'src/routes/components/styled/RouteMapFilters';
import { GENERAL_GEO_FENCE_ID, GEO_FENCES_ALL_TYPES } from 'src/routes/constants';
import { DAILY_ROUTE_GEO_FENCE_ID } from 'src/routes/constants/geoFenceType';
import { GeoFenceMapFilterOption } from 'src/routes/ducks/geoFenceFilterOptions';
import { checkIfGeoFenceIsEnabled } from 'src/vendors/ducks/features';

export type OnMapFiltersFormValues = {
  geoFencesTypesFilters: boolean[];
  geoFenceSubFilters: boolean[];
  geoFenceSearchTerm: string;
  showTravelPath?: boolean;
  cityAlertsFilters: boolean[];
};

type PropsWithoutReduxForm = {
  closeRouteMapFilters: () => void;
  excludeOptionsIds?: number[];
  hideDailyRouteGeoFence?: boolean;
  isCityAlertsVisible?: boolean;
  isOnMapFiltersFormValuesOpen: boolean;
  isOnRightSide?: boolean;
  routeName?: string;
  routeTemplateId?: number;
  showTravelPath?: boolean;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<OnMapFiltersFormValues, PropsWithoutReduxForm>;

export const ON_MAP_FILTERS_FORM_NAME = 'onMapFiltersForm';

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

export default reduxForm<OnMapFiltersFormValues, PropsWithoutReduxForm>({ form: ON_MAP_FILTERS_FORM_NAME })(
  function OnMapFiltersForm({
    change,
    closeRouteMapFilters,
    excludeOptionsIds = [],
    handleSubmit,
    hideDailyRouteGeoFence,
    isCityAlertsVisible,
    isOnMapFiltersFormValuesOpen,
    isOnRightSide,
    routeName,
    routeTemplateId,
    showTravelPath,
  }: Props) {
    const formValues = useSelector(getFormValues(ON_MAP_FILTERS_FORM_NAME)) as any;
    const [isGeoFenceFiltersOpen, setIsGeoFenceFiltersOpen] = useState(true);
    const [isCityAlertsFiltersOpen, setIsCityAlertsFiltersOpen] = useState(false);
    const { geoFenceMapFilterOptions } = useSelector(
      state => state.routes.geoFencesFilterOptions.geoFencesFilterOptions,
    );
    const isGeoFenceEnabled = useSelector(checkIfGeoFenceIsEnabled);

    const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
    const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
    const hasCityAlerts = getActiveCityAlertTypes(cityAlertSettings).hasCityAlerts;

    const cityAlertsFilters = useMemo(
      () =>
        map(activeCityAlertTypes, cityAlertType => ({
          id: cityAlertType.cityAlertType.id,
          label: cityAlertType.cityAlertType.name,
        })),
      [activeCityAlertTypes],
    );

    const onSubmit = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event);
    };

    const geoFencesGroupedByType = useMemo(
      () =>
        groupBy(
          filter(
            geoFenceMapFilterOptions,
            (geoFence: GeoFenceMapFilterOption) => !excludeOptionsIds.includes(geoFence.id),
          ),
          (geoFence: GeoFenceMapFilterOption) => geoFence.zoneTypeId,
        ),
      [excludeOptionsIds, geoFenceMapFilterOptions],
    ) as any;

    const geoFencesFilters = useMemo(() => {
      /// General GeoFence has to be first in the list
      let geoFenceTypeIds = Object.keys(geoFencesGroupedByType);
      if (geoFenceTypeIds.includes(GENERAL_GEO_FENCE_ID.toString())) {
        geoFenceTypeIds.splice(geoFenceTypeIds.indexOf(GENERAL_GEO_FENCE_ID.toString()), 1);
        geoFenceTypeIds.unshift(GENERAL_GEO_FENCE_ID.toString());
      }

      if (hideDailyRouteGeoFence)
        geoFenceTypeIds = filter(geoFenceTypeIds, id => id !== DAILY_ROUTE_GEO_FENCE_ID.toString());

      return map(geoFenceTypeIds, geoFenceZoneTypeId => {
        const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId];
        const geoFenceZoneTypeName = GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId];

        return {
          id: geoFenceZoneTypeId,
          label: geoFenceZoneTypeName?.name,
          rightLabel: `${geoFences?.length || ''}`,
          subFilters: map(geoFences, (option: GeoFenceMapFilterOption) => ({
            id: option.id,
            label: option.name,
          })) as any,
        };
      });
    }, [geoFencesGroupedByType, hideDailyRouteGeoFence]);

    const subGeoFenceFilters = useMemo(() => {
      if (!formValues) return [];
      return map(
        filter(Object.keys(geoFencesGroupedByType), (id: number) => formValues.geoFencesTypesFilters[id]) as any,
        geoFenceZoneTypeId => {
          const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId];
          const filteredGeoFences = filter(
            geoFences,
            gf => gf.name.toLowerCase().indexOf(formValues?.geoFenceSearchTerm.toLowerCase()) > -1,
          );
          return {
            label: GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId]?.name,
            id: geoFenceZoneTypeId,
            rightLabel: `${geoFences.length}`,
            subFilters: map(filteredGeoFences, (option: GeoFenceMapFilterOption) => ({
              id: option.id,
              label: option.name,
            })) as any,
          };
        },
      );
    }, [formValues, geoFencesGroupedByType]);

    // uncheck all subFilters if category is unchecked
    useEffect(() => {
      if (!formValues) return;
      const normalizedGeoFencesIds = normalizeFilterArray(formValues.geoFenceSubFilters);
      const normalizedGeoFencesTypesIds = normalizeFilterArray(formValues.geoFencesTypesFilters);
      const filteredGeoFencesIdsToDeselect = normalizedGeoFencesIds.filter((id: number) => {
        const geoFenceOption = find(geoFenceMapFilterOptions, { id });
        return (
          geoFenceOption &&
          geoFenceOption.zoneTypeId &&
          !normalizedGeoFencesTypesIds.includes(geoFenceOption.zoneTypeId)
        );
      });
      filteredGeoFencesIdsToDeselect.forEach(subFilter => {
        change(`geoFenceSubFilters.${subFilter}`, false);
      });
    }, [change, formValues, geoFenceMapFilterOptions, geoFencesFilters, subGeoFenceFilters]);

    return isOnMapFiltersFormValuesOpen ? (
      <form onSubmit={onSubmit}>
        <RouteMapFiltersWrapper isOnRightSide={isOnRightSide}>
          <RouteMapFiltersTitle>{translate('tooltips.mapFilters')}</RouteMapFiltersTitle>
          <RouteMapFiltersClose onClick={closeRouteMapFilters}>
            <RouteMapFiltersCloseIcon />
          </RouteMapFiltersClose>

          {/* General & Route & StreetSweeper GeoFences grouped */}
          {isGeoFenceEnabled && !!geoFenceMapFilterOptions?.length && (
            <RouteMapFiltersSectionWrapper>
              <ExpandableMapFiltersSection
                title={translate('routes.geoFences.geoFencesTitle')}
                filters={geoFencesFilters}
                name="geoFencesTypesFilters"
                change={change}
                isOpen={isGeoFenceFiltersOpen}
                setIsOpen={setIsGeoFenceFiltersOpen}
              />
            </RouteMapFiltersSectionWrapper>
          )}

          {hasCityAlerts && isCityAlertsVisible && (
            <RouteMapFiltersSectionWrapper>
              <ExpandableMapFiltersSection
                title={translate('vendors.cityAlerts.cityAlerts')}
                filters={cityAlertsFilters}
                name="cityAlertsFilters"
                change={change}
                isOpen={isCityAlertsFiltersOpen}
                setIsOpen={setIsCityAlertsFiltersOpen}
                hasCheckAll
              />
            </RouteMapFiltersSectionWrapper>
          )}

          {showTravelPath && (
            <RouteMapFiltersSectionWrapper>
              <Separator margin="small no no" />
              <TypedField
                name="showTravelPath"
                component={TravelPathField}
                props={{
                  displayTravelPath: true,
                  isActionButtonAvailable: true,
                  routeTemplateId: routeTemplateId,
                  routeName: routeName || '',
                }}
              />
            </RouteMapFiltersSectionWrapper>
          )}

          <Button type="submit" color="primary" fullWidth margin="medium no no no">
            {translate('common.apply')}
          </Button>
        </RouteMapFiltersWrapper>
        <ExpandableMapFiltersSubsection
          isOpen={isGeoFenceFiltersOpen}
          setIsOpen={setIsGeoFenceFiltersOpen}
          change={change}
          name="geoFenceSubFilters"
          filters={subGeoFenceFilters}
          rightPosition={isOnRightSide ? '250px' : undefined}
          leftPosition={isOnRightSide ? undefined : '230px'}
        />
      </form>
    ) : null;
  },
);
