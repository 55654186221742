import { identity, mapKeys } from 'lodash-es';
import { createSelector } from 'reselect';

const getRouteAssistSettingsFormInitialValuesSelector = (routeAssistSettingsState: any) =>
  mapKeys(routeAssistSettingsState.routeAssistSettings, ({ vehicleTypeId }) => vehicleTypeId);

const routeAssistSettingsFormInitialValuesSelector = createSelector(
  getRouteAssistSettingsFormInitialValuesSelector,
  identity,
);

export default routeAssistSettingsFormInitialValuesSelector;
