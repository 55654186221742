import { colorMapperWithoutProps } from 'src/utils/styles/colorMapper';
import styled, { css } from 'styled-components';

export const RouteSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  border-bottom: 2px solid ${props => props.theme.grayLight};
`;

interface VehicleSectionCollapseButtonProps {
  isOpen?: boolean;
}

export const RouteSectionCollapseButton = styled.button<VehicleSectionCollapseButtonProps>`
  background: none;
  outline: none;
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => colorMapperWithoutProps(props.isOpen ? 'primary' : 'grayDarker')};

  & > svg {
    width: 20px;
    height: 20px;
    transition: transform 0.2s;
  }

  &:not(:disabled) {
    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }

    ${props =>
      props.isOpen &&
      css`
        & > svg {
          transform: rotate(180deg);
        }
      `}
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.03);
  }
`;
