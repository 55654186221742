import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { PaymentMethods } from '../interfaces/ApplyChecks';

import { loadPaymentMethods as doLoadPaymentMethods } from '../services/paymentMethods';

// Actions
const START_LOAD = 'finance/applyChecks/paymentMethods/START_LOAD';
const COMPLETE_LOAD = 'finance/applyPayemnts/paymentMethods/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/paymentMethods/FAIL_LOAD';
const RESET = 'finance/applyChecks/paymentMethods/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  paymentMethods: PaymentMethods[];
} = {
  isLoading: false,
  paymentMethods: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethods: action.paymentMethods,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethods: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (paymentMethods: any) => ({
  type: COMPLETE_LOAD,
  paymentMethods,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadPaymentMethods = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadPaymentMethodsPromise = doLoadPaymentMethods();
  loadPaymentMethodsPromise
    .then((paymentMethods: any) => dispatch(completeLoad(paymentMethods)))
    .catch(() => dispatch(failLoad()));
  return loadPaymentMethodsPromise;
};

export const resetPaymentMethods = () => ({
  type: RESET,
});
