import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { PermissionGuard } from 'src/account/components';
import { GEO_FENCES_CREATE_EDIT_DELETE } from 'src/account/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon } from 'src/core/components/styled';

import { MapGLWrapper } from 'src/customers/components/styled';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { GeoFenceJsonList } from 'src/routes/ducks/geoFences';
import GeoFencesGL from './GeoFencesGL';
import useGeoFenceControllerMapbox, { NEW_GEO_FENCE_ID } from 'src/common/hooks/geoFenceControllerMapbox';
import { debounce, filter, find, map, size } from 'lodash-es';
import DrawingInstructions from './DrawInstructions';
import { getMapBounds } from 'src/common/components/map/util';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { useSelector } from 'src/core/hooks/useSelector';
import { GEO_FENCE_OPTIONS, GEO_FENCE_OPTIONS_SAT, GEO_FENCE_OPTIONS_SELECTED } from 'src/core/constants';
import { Vendor } from 'src/vendors/interfaces/Vendors';

interface Props {
  geoFenceJsonList: GeoFenceJsonList;
  isEditingGeoFences: boolean;
  setIsEditingGeoFences: (isEditingGeoFences: boolean) => void;
  updateGeoFence: (json: GeoFenceJsonList, vendorId: number) => void;
  isSingleGeoFence?: boolean;
}

const GeoFencesMapGL = ({
  geoFenceJsonList,
  isEditingGeoFences,
  isSingleGeoFence,
  setIsEditingGeoFences,
  updateGeoFence,
}: Props) => {
  const vendor = useSelector(state => state.vendors.vendor.vendor) as any as Vendor;

  const mapCenterByVendor = useMemo(() => {
    if (vendor?.id) {
      return getMapBounds([{ latitude: vendor.homeAddress.latitude, longitude: vendor.homeAddress.longitude }], {
        capZoom: 16,
      });
    }
  }, [vendor.id, vendor.homeAddress]);
  const [shouldCenterByVendor, setShouldCenterByVendor] = useState<boolean>(!geoFenceJsonList?.length);

  const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();

  const [editedGeoFences, setEditedGeoFences] = useState<any>();
  const [drawMode, setDrawMode] = useState<EditingMode | DrawPolygonMode>();
  const editorRef = useRef<Editor>(null);
  const [viewport, setViewport] = useState<MapGLViewport>({});
  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });
  const vendorId = useSelector(currentVendorId);
  const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false);

  const {
    mapGeoFences,
    getAllCoords,
    allGeoFencesGeoJson,
    selectedGeoFenceGeo,
    hasChanges,
    bulkAddGeoFences,
    emptyGeoFences,
    getAllGeoJsonForEditing,
    selectGeoFencePolygon,
    unselectGeoFencePolygon,
    updatePolygonInGeoFence,
    undoPolygonInGeoFence,
    removeUnsavedGeoFenceOrPolygons,
    removePolygonFromGeoFence,
    addPolygonToGeoFence,
  } = useGeoFenceControllerMapbox();

  /************************************************
   * GEO-FENCES Management
   ************************************************/

  // adding geoFences to controller
  useEffect(() => {
    if (geoFenceJsonList.length && !drawMode) {
      bulkAddGeoFences(geoFenceJsonList as any);
      setDrawMode(undefined);
    } else if (!drawMode && !isSingleGeoFence) {
      emptyGeoFences();
    }
  }, [bulkAddGeoFences, emptyGeoFences, geoFenceJsonList, drawMode, mapGeoFences.length, isSingleGeoFence]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: mapInstance?.getCenter(),
      mapZoom: mapInstance?.getZoom(),
    });
  };

  const handleSelectInGeoFenceEditor = useCallback(
    (selected: any) => {
      if (selected?.selectedFeatureIndex === null) {
        unselectGeoFencePolygon();
        return;
      }
      if (
        (selected?.selectedFeature?.properties?.polygonId || selected?.selectedFeature?.properties?.polygonId === 0) &&
        selected?.selectedFeature?.properties?.geoFenceId
      ) {
        selectGeoFencePolygon(
          selected?.selectedFeature?.properties?.geoFenceId,
          selected?.selectedFeature?.properties?.polygonId,
        );
      } else {
        const geoFenceId = geoFenceJsonList[geoFenceJsonList.length - 1]?.id || NEW_GEO_FENCE_ID;
        selectGeoFencePolygon(geoFenceId, selected?.selectedFeatureIndex);
      }
    },
    [geoFenceJsonList, selectGeoFencePolygon, unselectGeoFencePolygon],
  );

  const updateGeoFenceHistoryDebounced = useMemo(
    () =>
      debounce((newPolygons: any) => {
        const updatedPolygon = find(newPolygons, (polygon: any) => {
          return (
            polygon.properties?.polygonId === selectedGeoFenceGeo?.polygonId &&
            polygon.properties?.geoFenceId === selectedGeoFenceGeo?.id
          );
        });

        if (updatedPolygon && selectedGeoFenceGeo) {
          updatePolygonInGeoFence(
            selectedGeoFenceGeo?.id,
            selectedGeoFenceGeo?.polygonId,
            updatedPolygon.geometry.coordinates[0],
          );
        }
      }, 200),
    [selectedGeoFenceGeo, updatePolygonInGeoFence],
  );

  const onEditGeoFence = (event: any) => {
    const newPolygons = event.data;
    const last = newPolygons.length - 1;

    if (event.editType === 'addFeature' && isAddingPolygon) {
      setIsAddingPolygon(false);
      setDrawMode(new EditingMode());
      const geoFenceId = geoFenceJsonList.length ? geoFenceJsonList[geoFenceJsonList.length - 1].id : undefined;
      const polygonId = addPolygonToGeoFence(newPolygons[last].geometry.coordinates[0], geoFenceId);
      setEditedGeoFences([
        ...editedGeoFences,
        {
          ...newPolygons[last],
          properties: {
            polygonId,
            geoFenceId: geoFenceId || NEW_GEO_FENCE_ID,
          },
        },
      ]);
    } else {
      updateGeoFenceHistoryDebounced(newPolygons);
      setEditedGeoFences(newPolygons);
    }
  };

  useEffect(() => {
    setEditedGeoFences(getAllGeoJsonForEditing());
  }, [getAllGeoJsonForEditing, mapGeoFences]);

  const handleEnterExitEditMode = () => {
    if (isEditingGeoFences) {
      setDrawMode(undefined);
      setIsAddingPolygon(false);
      unselectGeoFencePolygon();
      removeUnsavedGeoFenceOrPolygons();
      if (editedGeoFences) {
        setEditedGeoFences(undefined);
      }
      setIsEditingGeoFences(false);
    } else {
      setDrawMode(new EditingMode());
      setIsEditingGeoFences(true);
      const features = getAllGeoJsonForEditing();
      setEditedGeoFences(features);
      if (isSingleGeoFence && editedGeoFences?.length === 0) {
        setIsAddingPolygon(true);
        setDrawMode(new DrawPolygonMode());
      }
    }
  };

  // center map on vendor
  useEffect(() => {
    if (shouldCenterByVendor) {
      mapCenterByVendor && !mapViewStyle.mapCenter && setViewport(mapCenterByVendor);
      setShouldCenterByVendor(false);
    } else if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      setViewport(bounds);
    }
  }, [mapCenterByVendor, mapViewStyle.mapCenter, mapViewStyle.mapZoom, shouldCenterByVendor]);

  // when selecting geoFences from list to be displayed on map
  useEffect(() => {
    const points = getAllCoords;

    if (!!points.length && !isEditingGeoFences) {
      const bounds = getMapBounds(points, {
        capZoom: 16,
      });
      setViewport(bounds);
    }
  }, [getAllCoords, isEditingGeoFences, isSingleGeoFence]);

  // focus on geoFence when entering edit mode
  useEffect(() => {
    if (isEditingGeoFences) {
      const points = getAllCoords;
      if (!!points.length) {
        const bounds = getMapBounds(points, {
          capZoom: 16,
        });
        setViewport(bounds);
      }
    }
  }, [isEditingGeoFences]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUndoGeoFence = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedGeoFenceGeo) {
      undoPolygonInGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
    }
  };

  const handleDeletePolygonFromGeoFence = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedGeoFenceGeo) {
      removePolygonFromGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
      setEditedGeoFences([
        ...filter(editedGeoFences, (p: any) => p.properties?.polygonId !== selectedGeoFenceGeo.polygonId),
      ]);
      unselectGeoFencePolygon();
    }
  };

  const handleAddPolygonToGeoFence = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDrawMode(new DrawPolygonMode());
    setIsAddingPolygon(true);
    unselectGeoFencePolygon();
  };

  const onSaveChanges = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const geoJsonToSave = map(mapGeoFences, geoFence => {
      const hasOnlyOnePolygon = geoFence.polygons.length === 1;
      const geoFenceJson = {
        type: 'Feature',
        properties: {
          id: geoFence.id,
          name: geoFence.name,
        },
        geometry: {
          type: hasOnlyOnePolygon ? 'Polygon' : 'MultiPolygon',
          coordinates: hasOnlyOnePolygon
            ? [geoFence.polygons[0].coordinates]
            : geoFence.polygons.map(polygon => [polygon.coordinates]),
        },
      };
      return {
        id: geoFence.id,
        name: geoFence.name,
        geoFenceJson: JSON.stringify(geoFenceJson),
      };
    }) as GeoFenceJsonList;

    updateGeoFence(geoJsonToSave, vendorId);
    handleEnterExitEditMode();
  };

  /************************************************
   * End of GEO-FENCES Management
   * *********************************************/

  const getFeatureStyle = ({
    feature,
    index,
    state,
  }: {
    feature: any;
    index: number;
    state: 'SELECTED' | 'HOVERED' | 'INACTIVE' | 'UNCOMMITTED' | 'CLOSING';
  }) => {
    const isSelectedOrHovered = state === 'SELECTED' || state === 'HOVERED';
    const isSameGeoFence = feature.properties.geoFenceId === selectedGeoFenceGeo?.id;
    const isSamePolygon = feature.properties.polygonId === selectedGeoFenceGeo?.polygonId;
    const cursor =
      drawMode instanceof EditingMode ? 'pointer' : drawMode instanceof DrawPolygonMode ? 'crosshair' : 'default';

    if ((isSelectedOrHovered || isSameGeoFence) && !isSingleGeoFence)
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };

    if (isSelectedOrHovered && isSamePolygon && isSingleGeoFence)
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };

    return {
      stroke: mapViewStyle.isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.strokeColor : GEO_FENCE_OPTIONS.strokeColor,
      fill: mapViewStyle.isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.fillColor : GEO_FENCE_OPTIONS.fillColor,
      strokeWidth: 2,
      fillOpacity: 0.1,
      cursor,
    };
  };

  return (
    <MapGLWrapper
      drawingEnabled={!!drawMode && isEditingGeoFences}
      isDrawing={drawMode && drawMode instanceof DrawPolygonMode}
    >
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={setMapInstance}
        setIsSatelliteViewEnabled={handleMapViewStyleChange}
      >
        {drawMode && isEditingGeoFences && <DrawingInstructions />}
        <PermissionGuard permission={GEO_FENCES_CREATE_EDIT_DELETE}>
          <ComplexMapControl vertical position="top-right">
            <TooltipIconButton
              color="secondary"
              tooltip={
                isEditingGeoFences
                  ? 'cancel'
                  : isSingleGeoFence
                  ? !!size(editedGeoFences)
                    ? 'editGeoFence'
                    : 'createGeoFence'
                  : 'editGeoFences'
              }
              tooltipPosition="left"
              margin="small no no"
              onClick={handleEnterExitEditMode}
              type="button"
            >
              <IconButtonIcon icon={isEditingGeoFences ? 'close' : 'edit'} size="large" />
            </TooltipIconButton>
            {isEditingGeoFences && (
              <>
                {selectedGeoFenceGeo && (
                  <TooltipIconButton
                    disabled={size(selectedGeoFenceGeo.history) <= 1}
                    color="secondary"
                    tooltip="undo"
                    tooltipPosition="left"
                    margin="small no no"
                    onClick={handleUndoGeoFence}
                    type="button"
                  >
                    <IconButtonIcon icon="betterUndo" size="large" />
                  </TooltipIconButton>
                )}
                {isSingleGeoFence && (
                  <>
                    <TooltipIconButton
                      disabled={isAddingPolygon || !selectedGeoFenceGeo}
                      color="secondary"
                      tooltip="deletePolygon"
                      tooltipPosition="left"
                      margin="small no no"
                      onClick={handleDeletePolygonFromGeoFence}
                      type="button"
                    >
                      <IconButtonIcon icon="deleteGeoFence" size="large" />
                    </TooltipIconButton>

                    <TooltipIconButton
                      disabled={isAddingPolygon}
                      color="secondary"
                      tooltip="addPolygon"
                      tooltipPosition="left"
                      margin="small no no"
                      onClick={handleAddPolygonToGeoFence}
                      type="button"
                    >
                      <IconButtonIcon icon="createGeoFence" size="large" />
                    </TooltipIconButton>
                  </>
                )}
                <TooltipIconButton
                  color="secondary"
                  tooltip="save"
                  tooltipPosition="left"
                  margin="small no no"
                  disabled={!hasChanges}
                  onClick={onSaveChanges}
                  type="button"
                >
                  <IconButtonIcon icon="check" size="large" />
                </TooltipIconButton>
              </>
            )}
          </ComplexMapControl>
        </PermissionGuard>

        {drawMode && isEditingGeoFences && (
          <Editor
            ref={editorRef}
            clickRadius={12}
            mode={drawMode}
            features={editedGeoFences ? editedGeoFences : []}
            onUpdate={onEditGeoFence}
            onSelect={handleSelectInGeoFenceEditor}
            featureStyle={getFeatureStyle}
          />
        )}

        {mapInstance && !drawMode && (
          <GeoFencesGL
            geoFencesGeoJSON={allGeoFencesGeoJson}
            isSatellite={mapViewStyle.isSatelliteEnabled}
            map={mapInstance}
          />
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default GeoFencesMapGL;
