import { map, reduce, some, sum } from 'lodash-es';
import { transparentize } from 'polished';
import moment from 'moment';

import {
  AUDIT_RATE,
  AUDIT_TIME,
  DISPOSAL_TONS,
  DRIVER_SAFETY,
  EXCEPTION_RATE,
  EXCEPTIONS_BY_HOUSEHOLD,
  EXCEPTIONS,
  FAULT_CODES,
  GEO_FENCES_COVERAGE_STREET_SWEEPING,
  GEO_FENCES_STREET_SWEEPING,
  HARD_ACCELERATION,
  HARD_BREAKING,
  HARD_TURNING,
  MATERIAL_CONTAMINATION,
  OBSTACLES_STREET_SWEEPING,
  OFF_CURB_OBSTACLES,
  OFF_ROUTE,
  ON_CURB_STREET_SWEEPER,
  PARTICIPATION_RATE,
  PICKUPS_PER_HOUR,
  RECYCLE_PARTICIPATION_RATE,
  SET_OUT_RATE,
  SNOW_PLOW_CHART,
  SPEEDING,
  STATIONARY_OFF_ROUTE,
  STATIONARY,
  TRIP_TIME_STREET_SWEEPER,
  TRIP_TIME,
} from '../constants';
import { BAR, CHART_COLORS, LINE, PIE } from '../../core/constants';
import { date, decimal, duration } from '../../utils/services/formatter';
import {
  EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD,
  STREET_SWEEPING_COMPLETION,
  STREET_SWEEPING_LOADS_DUMPED,
  STREET_SWEEPING_WATER_FILLUP,
} from '../constants/reportTypes';
import { getFormattedTime } from './reportingDetailsOptions';
import { theme } from '../../core/styles';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

interface TooltipLabel {
  datasets: any[];
  index: number;
}

interface ReportData {
  history: any;
  insightsData: any[];
  reportType: string;
  unit: string;
}

export const getChartType = (reportType: string) => {
  if (
    reportType === EXCEPTIONS ||
    reportType === GEO_FENCES_COVERAGE_STREET_SWEEPING ||
    reportType === GEO_FENCES_STREET_SWEEPING ||
    reportType === MATERIAL_CONTAMINATION ||
    reportType === OBSTACLES_STREET_SWEEPING ||
    reportType === OFF_CURB_OBSTACLES
  ) {
    return PIE;
  } else if (reportType === EXCEPTIONS_BY_HOUSEHOLD || reportType === EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD) {
    return BAR;
  }
  return LINE;
};

const calculateSlope = ({ history }: ReportData) => {
  const eventsWithActiveVehicles = reduce(
    history,
    (eventsWithActiveVehicles: any[], event, index) =>
      event.activeVehicles ? [...eventsWithActiveVehicles, { index, ...event }] : eventsWithActiveVehicles,
    [],
  );

  if (!eventsWithActiveVehicles.length) return 0;

  const defaultSlopeData = { sumX: 0, sumY: 0, sumXY: 0, sumXX: 0 };
  const slopeData = reduce(
    eventsWithActiveVehicles,
    ({ sumX, sumY, sumXX, sumXY }, { index, value }) => ({
      sumX: sumX + index,
      sumY: sumY + value,
      sumXY: sumXY + index * value,
      sumXX: sumXX + index * index,
    }),
    defaultSlopeData,
  );

  const { sumX, sumY, sumXY, sumXX } = slopeData;
  const a = eventsWithActiveVehicles.length * sumXY;
  const b = sumX * sumY;
  const c = eventsWithActiveVehicles.length * sumXX;
  const d = sumX * sumX;

  return c - d === 0 ? 0 : (a - b) / (c - d);
};

const isTrendingDown = (reportData: ReportData) => calculateSlope(reportData) < 0;

const getLineChartColor = (reportData: ReportData) => {
  switch (reportData.reportType) {
    case AUDIT_RATE:
    case PARTICIPATION_RATE:
    case PICKUPS_PER_HOUR:
    case RECYCLE_PARTICIPATION_RATE:
    case SET_OUT_RATE:
      return isTrendingDown(reportData) ? theme.brandAlert : theme.brandSuccess;

    default:
      return isTrendingDown(reportData) ? theme.brandSuccess : theme.brandAlert;
  }
};

const getLineChartValueFormatter = (reportType: string) => {
  switch (reportType) {
    case AUDIT_TIME:
    case OFF_ROUTE:
    case STATIONARY:
    case STATIONARY_OFF_ROUTE:
    case TRIP_TIME:
    case TRIP_TIME_STREET_SWEEPER:
      return (value: number) => duration(value, 'minutes', 'hh:mm');

    case PARTICIPATION_RATE:
    case SET_OUT_RATE:
    case EXCEPTION_RATE:
    case ON_CURB_STREET_SWEEPER:
      return (value: number) => `${decimal(value * 100, 0)}%`;

    case RECYCLE_PARTICIPATION_RATE:
    case STREET_SWEEPING_COMPLETION:
    case SNOW_PLOW_CHART:
      return (value: number) => `${decimal(value, 0)}%`;

    default:
      return (value: number) => value;
  }
};

const getLineChartSuggestedMax = ({ reportType }: ReportData) => {
  switch (reportType) {
    case SPEEDING:
    case FAULT_CODES:
    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
      return 5;

    case STATIONARY:
      return 120;

    case AUDIT_TIME:
    case TRIP_TIME:
    case TRIP_TIME_STREET_SWEEPER:
      return 600;

    case DISPOSAL_TONS:
    case AUDIT_RATE:
    case PICKUPS_PER_HOUR:
    case EXCEPTIONS_BY_HOUSEHOLD:
    case EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD:
    case RECYCLE_PARTICIPATION_RATE:
    case STREET_SWEEPING_COMPLETION:
    case SNOW_PLOW_CHART:
    case DRIVER_SAFETY:
      return 100;

    case STREET_SWEEPING_LOADS_DUMPED:
    case STREET_SWEEPING_WATER_FILLUP:
      return 20;

    case EXCEPTION_RATE:
    case ON_CURB_STREET_SWEEPER:
    case PARTICIPATION_RATE:
    case SET_OUT_RATE:
      return 1;

    default:
      return undefined;
  }
};

const getLineChartSuggestedMin = ({ reportType }: ReportData) => {
  switch (reportType) {
    case EXCEPTION_RATE:
    case ON_CURB_STREET_SWEEPER:
    case PARTICIPATION_RATE:
    case RECYCLE_PARTICIPATION_RATE:
    case SET_OUT_RATE:
    case STREET_SWEEPING_COMPLETION:
    case SNOW_PLOW_CHART:
      return 1;

    default:
      return 0;
  }
};

const getLineChartData = (reportData: ReportData) => {
  const { history } = reportData;
  const labels = map(history, event => moment(event.date).format('MM/DD/YYYY'));
  const data = map(history, 'value');
  const color = getLineChartColor(reportData);

  return {
    labels,
    datasets: [
      {
        data,
        history,
        lineTension: 0,
        pointBackgroundColor: color,
        pointBorderColor: color,
        backgroundColor: transparentize(0.8, color),
        borderColor: color,
        borderWidth: 2,
      },
    ],
  };
};

const getPieChartData = ({ insightsData }: ReportData) => {
  const data = map(insightsData, 'value');

  return {
    datasets: [
      {
        data,
        insightsData,
        backgroundColor: CHART_COLORS,
      },
    ],
  };
};

const getBarChartData = ({ insightsData }: ReportData) => {
  const names = (insightsData || []).map(i =>
    translate(createTranslationKey(i.technicalName, 'routes.wasteAuditStatuses')),
  );
  const data = map(insightsData, 'value');

  return {
    labels: names,
    datasets: [
      {
        data,
        insightsData,
        backgroundColor: CHART_COLORS,
      },
    ],
  };
};

export const getChartData = (reportData: ReportData) => {
  const chartType = getChartType(reportData.reportType);
  if (chartType === LINE) {
    return getLineChartData(reportData);
  } else if (chartType === BAR) {
    return getBarChartData(reportData);
  }
  return getPieChartData(reportData);
};

const getBarChartOptions = (reportData: ReportData) => ({
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
          suggestedMin: getLineChartSuggestedMin(reportData),
          suggestedMax: getLineChartSuggestedMax(reportData),
        },
        scaleLabel: {
          display: true,
          labelString: translate('insights.reportTypeUnits.percentOfTotal'),
        },
      },
    ],
  },
  tooltips: {
    backgroundColor: theme.brandPrimary,
    bodyFontSize: 14,
    displayColors: false,
    callbacks: {
      title: () => {},
      label: ({ index }: TooltipLabel, { datasets }: TooltipLabel) => {
        const exception = datasets[0].insightsData[index];

        const numberOfInstances = translate('insights.numberOfInstancesWithTotal', {
          instanceCount: exception.instanceCount,
          percentage: exception.value,
        });

        const numberOfActiveRoutes = translate('insights.numberOfActiveRoutes', {
          activeRoutes: exception.additionalDetails,
        });

        return [
          translate(createTranslationKey(exception.technicalName, 'routes.wasteAuditStatuses')),
          numberOfActiveRoutes,
          numberOfInstances,
        ];
      },
    },
  },
});

const getLineChartOptions = (reportData: ReportData) => {
  const { reportType, unit } = reportData;

  return {
    legend: false,
    scales: {
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
            callback: (value: number, index: number, labels: string) =>
              index === 0 || index === labels.length - 1 ? value : '',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: getLineChartSuggestedMin(reportData),
            suggestedMax: getLineChartSuggestedMax(reportData),
            callback: getLineChartValueFormatter(reportData.reportType),
          },
          scaleLabel: {
            display: true,
            labelString: unit,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.brandPrimary,
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: () => {},
        label: ({ index }: TooltipLabel, { datasets }: TooltipLabel) => {
          const event = datasets[0].history[index];
          const valueFormatter = getLineChartValueFormatter(reportType);
          const tooltipLabel =
            reportType === PARTICIPATION_RATE || reportType === SET_OUT_RATE
              ? 'vehicles.activeRoutes'
              : 'vehicles.activeVehicles';
          return [
            date(event.date),
            `${valueFormatter(event.value)} ${unit}`,
            `${translate(tooltipLabel)}: ${event.activeVehicles}`,
          ];
        },
      },
    },
  };
};

const getPieChartOptions = (reportType: string) => ({
  legend: false,
  tooltips: {
    backgroundColor: theme.brandPrimary,
    bodyFontSize: 14,
    displayColors: false,
    callbacks: {
      title: () => {},
      label: ({ index }: TooltipLabel, { datasets }: TooltipLabel) => {
        const { name, technicalName, value, instanceCount, additionalDetails } = datasets[0].insightsData[index];
        const total = sum(map(datasets[0].insightsData, 'value'));
        const percentage = decimal((value * 100) / total, 0);

        switch (reportType) {
          case OBSTACLES_STREET_SWEEPING:
            return [
              translate(createTranslationKey(name, 'insights.obstacles')),
              translate('insights.numberOfInstances', { instanceCount }),
              translate('insights.percentageOfTotalWithValue', { percentage }),
            ];
          case GEO_FENCES_COVERAGE_STREET_SWEEPING:
            return [
              name,
              translate('insights.timeSpentFormatted', { timeSpent: getFormattedTime(additionalDetails) }),
              translate('insights.percentageOfTotalWithValue', { percentage: value }),
            ];
          case GEO_FENCES_STREET_SWEEPING:
            return [
              name,
              translate('insights.numberOfInstances', { instanceCount }),
              translate('insights.percentageOfTotalWithValue', { percentage: value }),
            ];
          case EXCEPTIONS:
            return [
              translate(createTranslationKey(technicalName, 'routes.wasteAuditStatuses')),
              translate('insights.numberOfInstancesWithTotal', { instanceCount, percentage }),
              translate('insights.numberOfRoutes', { routes: additionalDetails }),
            ];
          case MATERIAL_CONTAMINATION:
            return [
              translate(createTranslationKey(name, 'insights.contaminationSubType')),
              translate('insights.numberOfInstances', { instanceCount }),
              translate('insights.percentageOfTotalWithValue', { percentage }),
            ];
          default:
            return [
              name,
              translate('insights.numberOfInstancesWithTotal', { instanceCount, percentage }),
              translate('insights.numberOfRoutes', { routes: additionalDetails }),
            ];
        }
      },
    },
  },
});

export const getChartOptions = (reportData: ReportData) => {
  const chartType = getChartType(reportData.reportType);
  if (chartType === LINE) {
    return getLineChartOptions(reportData);
  } else if (chartType === BAR) {
    return getBarChartOptions(reportData);
  }
  return getPieChartOptions(reportData.reportType);
};

export const shouldDisplayChartPlaceholder = ({ reportType, insightsData }: ReportData) => {
  switch (reportType) {
    case EXCEPTIONS:
    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
    case GEO_FENCES_STREET_SWEEPING:
    case MATERIAL_CONTAMINATION:
    case OBSTACLES_STREET_SWEEPING:
      return !some(map(insightsData, 'value'));

    default:
      return false;
  }
};

export const getChartPlaceholderText = (reportType: string) => {
  switch (reportType) {
    case OBSTACLES_STREET_SWEEPING:
      return translate('insights.noObstaclesReported');

    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
      return translate('insights.noGeoFencesCoverageReported');

    case GEO_FENCES_STREET_SWEEPING:
      return translate('insights.noGeoFencesReported');

    case MATERIAL_CONTAMINATION:
      return translate('insights.noMaterialsReported');

    case EXCEPTIONS:
      return translate('insights.noExceptionsReported');

    default:
      return undefined;
  }
};
