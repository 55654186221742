import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { MapGLPopupWrapper, MapGLPopupLoading } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { ContainerFacility } from 'src/fleet/interfaces/containers';
import FacilityPopup from './FacilityPopup';
import { Facility } from 'src/common/interfaces/Facility';

const Loading: React.FC<{ latitude: number; longitude: number }> = ({ latitude, longitude }) => {
  const dispatch = useDispatch();

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={latitude}
      longitude={longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopupLoading />
    </MapGLPopupWrapper>
  );
};

type Props = {
  facilities: (ContainerFacility | Facility)[];
};

export default function FacilitiesPopupResolver({ facilities }: Props) {
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const facility: ContainerFacility | Facility | undefined = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.facility) {
      return undefined;
    }
    return facilities.find(facility => facility.id === selectedFeature.id);
  }, [selectedFeature, facilities]);

  if (!facility) {
    return null;
  }

  const loadDependencies = () => {
    if (!selectedFeature || !facility) return Promise.reject();

    return Promise.resolve();
  };

  return (
    <TypedResolver
      key={facility.id}
      successComponent={FacilityPopup}
      successProps={{ facility }}
      loadingComponent={Loading}
      loadingProps={{ latitude: facility.address.latitude, longitude: facility.address.longitude }}
      resolve={loadDependencies}
    />
  );
}
