import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/store';
import {
  cancelBatchedLocationsLoading,
  loadGeoFenceFilterOptions,
  loadServiceZones,
  loadTravelPathStatusDetails,
} from '../../../ducks';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../../common/components';
import { DuckFunction } from 'src/contracts/ducks';
import { loadDisposalFacilities } from '../../../../fleet/ducks/facilities';
import { loadFeatures, loadUserById } from '../../../../vendors/ducks';
import { loadOperationalFacilities, loadVehicles, loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { loadFiltersSettings, loadReasonCodeTypes, loadWasteTypes } from '../../../../common/ducks';
import { loadSupervisors } from '../../../ducks/supervisors';
import { PageLoading } from '../../../../common/components/styled';
import { Resolver } from '../../../../core/components';
import { User } from 'src/account/ducks/login';
import RouteEditorPage from './RouteEditorPage';
import translate from '../../../../core/services/translate';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';

interface RouteParams {
  routeId?: string;
}

const batchedLocationsRequesterId = 'routeEditorPageResolver';

interface Props extends RouteComponentProps<RouteParams> {
  loadDisposalFacilities: DuckFunction<typeof loadDisposalFacilities>;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadOperationalFacilities: DuckFunction<typeof loadOperationalFacilities>;
  loadReasonCodeTypes: DuckFunction<typeof loadReasonCodeTypes>;
  loadServiceZones: DuckFunction<typeof loadServiceZones>;
  loadSupervisors: DuckFunction<typeof loadSupervisors>;
  loadUserById: DuckFunction<typeof loadUserById>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadGeoFenceFilterOptions: DuckFunction<typeof loadGeoFenceFilterOptions>;
  loadTravelPathStatusDetails: DuckFunction<typeof loadTravelPathStatusDetails>;
  user: User;
  vendorId: number;
  isTravelPathFeatureEnabled: boolean;
  isNaviV3FeatureEnabled: boolean;
}

class RouteEditorPageResolver extends PureComponent<Props> {
  componentWillUnmount() {
    cancelBatchedLocationsLoading(batchedLocationsRequesterId);
  }

  loadDependencies = async () => {
    const {
      loadDisposalFacilities,
      loadFeatures,
      loadOperationalFacilities,
      loadReasonCodeTypes,
      loadServiceZones,
      loadSupervisors,
      loadUserById,
      loadVehicles,
      loadVehicleTypesForVendor,
      loadWasteTypes,
      loadFiltersSettings,
      loadGeoFenceFilterOptions,
      loadTravelPathStatusDetails,
      user,
      vendorId,
      isTravelPathFeatureEnabled,
      isNaviV3FeatureEnabled,
      match: {
        params: { routeId },
      },
    } = this.props;

    const promises = [
      loadDisposalFacilities(vendorId),
      loadFeatures(vendorId),
      loadOperationalFacilities(vendorId),
      loadReasonCodeTypes(),
      loadServiceZones(vendorId, false),
      loadSupervisors(vendorId),
      loadUserById(vendorId, user.userId),
      loadVehicles(vendorId, true),
      loadVehicleTypesForVendor(vendorId),
      loadWasteTypes(),
      loadFiltersSettings(vendorId, user.userId),
      loadGeoFenceFilterOptions({ vendorId }),
    ];

    if (routeId && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled))
      promises.push(loadTravelPathStatusDetails(Number(routeId)));

    return Promise.all(promises);
  };

  render() {
    const {
      match: {
        params: { routeId },
      },
    } = this.props;

    return (
      <Fragment>
        <DocumentTitle>
          {routeId ? translate('routes.editDailyRoute') : translate('routes.createDailyRoute')}
        </DocumentTitle>
        <Resolver
          successComponent={RouteEditorPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/routes/route-tracker"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.account.login.user,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  isTravelPathFeatureEnabled: isTravelPathNavigationFeatureEnabled(state),
  isNaviV3FeatureEnabled: isNavi3FeatureEnabled(state),
});

const mapDispatchToProps = {
  loadDisposalFacilities,
  loadFeatures,
  loadOperationalFacilities,
  loadReasonCodeTypes,
  loadServiceZones,
  loadSupervisors,
  loadUserById,
  loadVehicles,
  loadVehicleTypesForVendor,
  loadWasteTypes,
  loadFiltersSettings,
  loadGeoFenceFilterOptions,
  loadTravelPathStatusDetails,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteEditorPageResolver));
