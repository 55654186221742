import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GeoJSONSource } from 'mapbox-gl';

import { setRouteMapViewport } from 'src/routes/ducks';
import { setMapGeoJson } from 'src/routes/ducks/routeMapSettings';
import { getFeatureCollection } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import { Facility } from 'src/common/interfaces/Facility';
import { getFacilitiesGeoJSON } from '../../utils/facilities';
import FacilitiesClusterGLSource from './FacilitiesClusterGLSource';
import { FACILITY_CLUSTER_LAYER, FACILITY_SOURCE } from 'src/fleet/constants/facilityStatus';

type Props = {
  map: mapboxgl.Map;
  facilities: Facility[];
};

export default function FacilitiesClustersGL({ map, facilities }: Props) {
  const dispatch = useDispatch();
  const geoJson = useSelector(s => s.routes.routeMapSettings.mapGeoJson);

  useEffect(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (facilities.length) {
      const facilitiesCollection = getFacilitiesGeoJSON(facilities);
      collection.features = collection.features.concat(facilitiesCollection.features);
    }

    dispatch(setMapGeoJson(collection));
  }, [facilities, dispatch]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', FACILITY_CLUSTER_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [FACILITY_CLUSTER_LAYER],
          })
          .filter(feature => feature.source === FACILITY_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(FACILITY_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setRouteMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
    });
  }, [map, dispatch]);

  return <FacilitiesClusterGLSource geoJSON={geoJson} />;
}
