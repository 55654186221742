import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import doLoadRouteImportStatuses from '../services/loadRouteImportStatuses';

// Actions
const START_LOAD = 'routeImport/routeImportStatuses/START_LOAD';
const COMPLETE_LOAD = 'routeImport/routeImportStatuses/COMPLETE_LOAD';
const FAIL_LOAD = 'routeImport/routeImportStatuses/FAIL_LOAD';
const RESET = 'routeImport/routeImportStatuses/RESET';

// Initial state
const initialState = {
  isLoading: false,
  routeImportStatuses: undefined,
  total: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeImportStatuses: action.routeImportStatuses,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeImportStatuses: any, total: number) => ({
  type: COMPLETE_LOAD,
  routeImportStatuses,
  total,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteImportStatuses = (
  routeImportId: number,
  haulerRouteImportStatusTypeId: number,
  page: number,
  limit: number,
) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadRouteImportStatusesPromise = doLoadRouteImportStatuses(
    routeImportId,
    haulerRouteImportStatusTypeId,
    page,
    limit,
  );

  loadRouteImportStatusesPromise
    .then(({ routeImportStatuses, total }) => dispatch(completeLoad(routeImportStatuses, total)))
    .catch(() => dispatch(failLoad()));

  return loadRouteImportStatusesPromise;
};

export const resetRouteImportStatuses = () => ({
  type: RESET,
});
