import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';
import { Feature, RawFeature } from '../interfaces/Features';

export const transformRawFeatures = (features: RawFeature[]): Feature[] =>
  features.map(({ editDetails, ...feature }) => ({
    ...feature,
    canEditDetails: editDetails,
    name: translate(createTranslationKey(feature.code, 'vendors.featureCodes')),
  }));

export const transformFeatures = (features: Feature[]): RawFeature[] =>
  features.map(({ canEditDetails, ...feature }) => ({
    ...feature,
    editDetails: canEditDetails,
  }));
