import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_SOURCE, ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER } from '../constants';
import { VehiclePositionFeatureProperties } from '../vehiclePositions/utils';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, VehiclePositionFeatureProperties>;
  sosAlertVehicleId?: number;
};

export default React.memo(function RouteMapTimeLineVehiclePositionSource({ geoJSON, sosAlertVehicleId }: Props) {
  return (
    <Source type="geojson" id={ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id={ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER}
        type="symbol"
        source={ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_SOURCE}
        paint={{}}
        layout={{
          'icon-image': ['get', 'icon'],
          'icon-size': 0.75,
          'icon-allow-overlap': true,
        }}
      />

      {sosAlertVehicleId && (
        <Layer
          id="sosAlertVehicles"
          type="symbol"
          source={ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_SOURCE}
          filter={['all', ['==', ['get', 'id'], sosAlertVehicleId]]}
          paint={{}}
          layout={{
            'icon-image': 'exclamation',
            'icon-size': 1,
            'icon-offset': [0, -50],
            'icon-allow-overlap': true,
          }}
        />
      )}
    </Source>
  );
});
