import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, RouteComponentProps, RouteProps } from 'react-router';
import { isAdminSelector } from '../ducks';
import { AppState } from '../../store';

interface AdminRouteProps extends RouteComponentProps {
  isAdmin: boolean;
  component: React.ComponentClass | React.FC | React.SFC;
}

const AdminRoute = ({ isAdmin, component: Component, ...rest }: AdminRouteProps & RouteProps) => (
  <Route
    {...rest}
    render={props =>
      isAdmin ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
  />
);

const mapStateToProps = (state: AppState) => ({
  isAdmin: isAdminSelector(state.account.login),
});

export default withRouter(connect(mapStateToProps)(AdminRoute));
