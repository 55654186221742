import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { ALL_EQUIPMENTS_TYPES } from '../constants';
import { ServiceAreaOptions } from '../interfaces/ServiceArea';
import { getAllEquipmentSizes } from '../utils/equipments';

const getServiceAreasFilterFormInitialValues = (serviceAreaOptions?: ServiceAreaOptions) => ({
  equipmentTypes: ALL_EQUIPMENTS_TYPES,
  equipmentSizes: getAllEquipmentSizes(serviceAreaOptions?.savedEquipments || []).map(
    equipmentSize => equipmentSize.vendorGroupEquipmentId,
  ),
  materials: (serviceAreaOptions?.savedMaterials || []).map(material => material.materialId),
});

const serviceAreasFilterFormInitialValuesSelector = createSelector(getServiceAreasFilterFormInitialValues, identity);

export default serviceAreasFilterFormInitialValuesSelector;
