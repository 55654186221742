import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { ActionTooltip } from '../../../../core/components/styled/ActionButtonTooltip';
import { BareButtonStyle } from '../../../../core/components/styled';
import { COLOR_SECONDARY, loadingOverlay } from '../../../../core/styles';
import { colorMapper, mapper, sizeMapper } from '../../../../utils/styles';
import { Icon } from '../../../../core/components';

export const DispatchBoardJobDropzone = styled.div<{
  position: 'top' | 'bottom' | 'single';
  isReadyToDrop?: boolean;
}>`
  width: 100%;
  height: 25px;
  border: solid transparent;

  ${props =>
    props.position === 'top' &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      border-width: 4px 0 0;
    `};

  ${props =>
    props.position === 'bottom' &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      border-width: 0 0 4px;
    `};

  ${props =>
    props.position === 'single' &&
    css`
      height: 90px;
      border-width: 4px 0;
    `};

  ${props =>
    props.isReadyToDrop &&
    css`
      background-color: ${props => transparentize(0.5, props.theme.brandPrimary)};
    `};
`;

export const DispatchBoardJobExpandIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;

export const DispatchBoardJobExpand = styled.button`
  ${BareButtonStyle};
  color: ${props => props.theme.gray};
  transition: color 0.3s, transform 0.3s;

  &:hover {
    color: ${props => props.theme.grayDarker};
  }
`;

export const DispatchBoardJobActionIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const DispatchBoardJobAction = styled.button`
  ${BareButtonStyle};
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  font-size: 13px;
  color: ${props => props.theme.gray};
  transition: color 0.3s ease-out;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${props => props.theme.grayDarker};
  }
`;

export const DispatchBoardJobActions = styled.div<{
  align?: string;
  isDragStart?: boolean;
  noStyle?: boolean;
}>`
  ${props =>
    !props.noStyle &&
    css`
      display: flex;
      justify-content: ${mapper(props.align, { left: 'flex-start', right: 'flex-end' }, 'left')};
      margin-bottom: 10px;
    `};

  ${props =>
    props.isDragStart &&
    css`
      ${ActionTooltip} {
        display: none;
      }
    `};
`;

export const DispatchBoardIconWrapper = styled.span`
  position: relative;
  top: 2px;
`;

export const DispatchBoardJobDetail = styled.span<{
  color?: string;
  variant?: string;
  ellipsis?: boolean;
}>`
  display: block;
  margin-bottom: 6px;
  font-size: 13px;

  &:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.color &&
    css`
      color: ${props.variant === COLOR_SECONDARY ? props.theme.brandDefault : colorMapper(props)};
    `}

  ${props =>
    props.ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    `}
`;

export const DispatchBoardJobDragHandle = styled(Icon).attrs({ icon: 'drag' })`
  width: 18px;
  height: 18px;
  color: ${props => props.theme.gray};
`;

export const DispatchBoardJobIndex = styled.span`
  pointer-events: none;
`;

export const DispatchBoardJobLocationName = styled.span`
  display: block;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
`;

export const DispatchBoardJobLocationAddress = styled.span`
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  color: ${props => props.theme.grayDark};
`;

export const DispatchBoardJobCustomerName = styled.span`
  display: block;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${props => props.theme.fontWeightMedium};
`;

export const DispatchBoardJobCell = styled.div<{
  align?: string;
  width?: string;
  verticalAlign?: boolean;
}>`
  text-align: ${props => mapper(props.align, { left: 'left', right: 'right' }, 'left')};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.verticalAlign &&
    css`
      min-height: 98px;
      display: flex;
      align-items: end;
      justify-content: center;
      flex-direction: column;
    `}

  ${props =>
    props.align === 'right' &&
    css`
      align-items: flex-end;
    `}
`;

export const DispatchBoardJobRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  &:first-child {
    margin-bottom: 0;
  }
`;

export const DispatchBoardJobRouteTitle = styled.span`
  display: block;
  padding: 4px 12px;
  background-color: ${props => props.theme.grayLighter};
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 11px;
  color: ${props => props.theme.grayBase};
`;

export const DispatchBoardFlexWrapper = styled.div`
  display: flex;
`;

interface DispatchBoardJobContainerProps {
  height: number;
  isExpanded?: boolean;
  isSelected?: boolean;
  jobIsPendingOptimization?: boolean;
  padding?: string;
  transparent?: boolean;
  width?: string;
}

export const DispatchBoardJobGhostContainer = styled.div`
  height: 90px;
  width: 400px;
  background-color: ${props => props.theme.grayLight};
  position: relative;
  box-shadow: 0 4px 10px rgba(36, 40, 51, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DispatchBoardJobGhostSecondary = styled(DispatchBoardJobGhostContainer)`
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 2;
`;

export const DispatchBoardJobContainer = styled.div<DispatchBoardJobContainerProps>`
  position: relative;
  padding: ${props => sizeMapper(props.padding, 'no xSmall')};
  background-color: ${props => props.theme.grayLighter};
  border-bottom: 1px solid ${props => props.theme.grayLight};
  height: 90px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: transform, height 0.3s;
  flex: 1;

  ${props =>
    props.transparent &&
    css`
      background-color: transparent;
    `};

  ${props =>
    props.isSelected &&
    css`
      background-color: ${props => props.theme.grayLight};
    `};

  ${props =>
    props.jobIsPendingOptimization &&
    css`
      background: #f3e9e9 !important;
      border: 1px solid #ec8282;
    `};

  ${props =>
    props.isExpanded &&
    css`
      background-color: ${props => props.theme.grayLight};
      height: ${props => props.height}px;

      ${DispatchBoardJobRow} {
        &:first-child {
          margin-bottom: 20px;
        }
      }

      ${DispatchBoardJobExpand} {
        transform: rotate(180deg);
      }
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const DispatchBoardJobs = styled.div``;

export const AssignJobsDivContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 34px 0;
`;

export const AssignJobsModalContainerForm = styled.form<{
  isLoading?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const AssignJobsFormFieldContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 230px;

  div {
    width: 100%;
  }
`;

export const AssignJobsButtonContainer = styled.div<{
  hasDuplicateJobs?: boolean;
}>`
  margin-top: ${props => (props.hasDuplicateJobs ? '40px' : '100px')};
  display: flex;
  flex-direction: column;
`;
