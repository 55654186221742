import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn } from '../../../core/components/styled';
import { Dropdown, Switch, TypedField } from '../../../core/components';
import { isAdminSelector } from 'src/account/ducks';
import { TechnicalTypeValue } from 'src/common/interfaces/TechnicalType';
import { VehicleTrackingSettings } from '../../interfaces/VehicleTracking';
import translate from '../../../core/services/translate';

type PropsWithoutReduxForm = {
  futureVehicleTracking?: boolean;
  isAdmin?: boolean;
  navigationLineTypes: TechnicalTypeValue[];
  onCancel: (pristine: boolean) => void;
  // vehicleTrackingSettings?: VehicleTrackingSettings;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<VehicleTrackingSettings, PropsWithoutReduxForm>;

class VehicleTrackingForm extends PureComponent<Props> {
  render() {
    const {
      futureVehicleTracking,
      handleSubmit,
      isAdmin,
      navigationLineTypes,
      onCancel,
      pristine,
      // vehicleTrackingSettings,
    } = this.props;
    const isFutureVehicleTrackingEnabled = futureVehicleTracking;

    const futureVehicleTrackingOptions = map(navigationLineTypes, type => ({
      label: type.name,
      value: type.value,
    }));

    //  TODO: Uncomment this when we have subscriptionBasedFrequencyConfigurations on the response

    // const vehicleTrackingFrequencyOptions = map(
    //   vehicleTrackingSettings?.subscriptionBasedFrequencyConfigurations,
    //   config => ({
    //     label: `${config.vehicleTrackingFrequencyValue} ${translate(
    //       config.vehicleTrackingFrequencyValue === 1 ? 'common.second' : 'common.seconds',
    //     )}`,
    //     value: config.vehicleTrackingFrequencyValue,
    //   }),
    // );

    return (
      <form onSubmit={handleSubmit}>
        <Grid centered multiLine>
          <GridColumn size="5/12" padding="medium no" verticalAlign="center">
            <span>{translate('vendors.showPastVehicleTracking')}</span>
          </GridColumn>
          <GridColumn size="3/12" padding="medium no" verticalAlign="center">
            <TypedField
              name="showPastVehicleTracking"
              component={Switch}
              props={{
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid centered multiLine>
          <GridColumn size="5/12" padding="medium no" verticalAlign="center">
            <span>{translate('vendors.showFutureVehicleTracking')}</span>
          </GridColumn>
          <GridColumn size="3/12" padding="medium no" verticalAlign="center">
            <TypedField
              name="futureVehicleTracking"
              component={Switch}
              props={{
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid centered multiLine>
          <GridColumn size="5/12" padding={`no no ${isAdmin ? 'small' : 'medium'} no`} verticalAlign="center">
            <span>{translate('vendors.futureVehicleTraking')}</span>
          </GridColumn>
          <GridColumn size="3/12" padding={`no no ${isAdmin ? 'small' : 'medium'} no`} verticalAlign="center">
            <TypedField
              name="navigationLine"
              component={Dropdown}
              props={{
                label: translate('vendors.trackingType'),
                options: futureVehicleTrackingOptions,
                width: '100%',
                disabled: !isFutureVehicleTrackingEnabled,
              }}
            />
          </GridColumn>
        </Grid>

        {/* TODO: Uncomment this when we have subscriptionBasedFrequencyConfigurations on the response */}

        {/* {isAdmin && (
          <Grid centered multiLine>
            <GridColumn size="5/12" padding="no no medium no" verticalAlign="center">
              <span>{translate('vendors.frequencyConfiguration')}</span>
            </GridColumn>
            <GridColumn size="3/12" padding="no no medium no" verticalAlign="center">
              <TypedField
                name="vehicleTrackingFrequency"
                component={Dropdown}
                props={{
                  label: translate('vendors.timeMeasurementType'),
                  options: vehicleTrackingFrequencyOptions,
                  width: '100%',
                }}
              />
            </GridColumn>
          </Grid>
        )} */}

        <Grid centered>
          <GridColumn size="12/12" padding="no medium medium medium">
            <ButtonSet margin="medium no" align="center">
              <Button type="submit" color="primary" margin="no small">
                {translate('common.save')}
              </Button>
              <Button type="button" color="secondary" margin="no small" onClick={() => onCancel(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('vehicleTrackingForm')(state);

  return {
    futureVehicleTracking: (formValues as VehicleTrackingSettings)?.futureVehicleTracking,
    isAdmin: isAdminSelector(state.account.login),
    navigationLineTypes: state.vendors.vehicleTrackingSettings.navigationLineTypes || [],
    vehicleTrackingSettings: state.vendors.vehicleTrackingSettings.vehicleTrackingSettings,
  };
};

export default connect(mapStateToProps)(
  reduxForm<VehicleTrackingSettings, PropsWithoutReduxForm>({
    form: 'vehicleTrackingForm',
  })(VehicleTrackingForm),
);
