import { Redirect, Route, Switch, useLocation } from 'react-router';

import { AuthRoute, RestrictedRoute } from 'src/account/components';
import {
  ROUTES_PLANNER_DETAILS_EDIT,
  ROUTES_PLANNER_SERVICE_ZONES,
  ROUTES_TRACKER_SEQUENCE_ROUTE,
} from 'src/account/constants';
import { ROUTES_PLANNER_HOLIDAY_PLANNER } from 'src/account/constants/permissions';
import { useHasRouteTemplateBuilderAccess } from 'src/account/utils/permissions';
import { PageLoading } from 'src/common/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  isRoutePlannerNewLayoutActive,
  setRoutePlannerNewLayoutActive,
} from 'src/routes/services/routePlannerPersistentBetaFlag';
import {
  DispatchBoardPageResolver,
  RouteDisposalsSummaryResolver,
  RouteEditorPageResolver,
  RouteIssuesReportedSummaryResolver,
  RouteTemplateEditorPageResolver,
  RouteTemplatePageResolver,
  RouteTemplateSequenceEditorPageResolverNew,
  RouteTemplatesPageResolver,
  RouteTrackerPageResolver,
  ServiceZonesPageResolver,
  SnowOrSweeperTrackerPageResolver,
  YRoutePageResolver,
} from './pages';
import GroupsPageResolver from './pages/groups/GroupsPageResolver';
import HolidayPlannerPageResolver from './pages/holidayPlanner/HolidayPlannerPageResolver';
import PickupLocationsEditorPageResolver from './pages/pickupLocations/PickupLocationsEditorPageResolver';
import RoutePlannerDailyRoutesPageResolver from './pages/routePlanner/RoutePlannerDailyRoutesPageResolver';
import RoutePlannerMainPageResolver from './pages/routePlanner/RoutePlannerMainPageResolver';
import RoutePlannerReoccurringPageResolver from './pages/routePlanner/RoutePlannerReoccurringPageResolver';
import RoutePlannerSettingsPageResolver from './pages/routePlanner/RoutePlannerSettingsPageResolver';
import NewRouteSequenceEditorPageResolver from './pages/routeSequence/NewRouteSequenceEditorPageResolver';
import { RouteTemplateBuilderWorkSessionPageResolver } from './pages/routeTemplateBuilder/RouteTemplateBuilderWorkSessionPageResolver';
import RouteTemplateBuilderWorkSessionsPageResolver from './pages/routeTemplateBuilder/RouteTemplateBuilderWorkSessionsPageResolver';
import SnowOrSweeperRouteTemplateEditorPageResolver from './pages/routeTemplates/SnowOrSweeperRouteTemplateEditorPageResolver';
import SnowOrSweeperRouteTemplatePageResolver from './pages/routeTemplates/SnowOrSweeperRouteTemplatePageResolver';
import CreateSnowOrSweeperRoutePageResolver from './pages/routes/CreateSnowOrSweeperRoutePageResolver';
import MaterialTripsSummaryPageResolver from './pages/routes/MaterialTripsSummaryPageResolver';
import NewRoutePageResolver from './pages/routes/NewRoutePageResolver';
import SnowOrSweeperRoutePageResolver from './pages/routes/SnowOrSweeperRoutePageResolver';

const Routes = () => {
  const hasRouteTemplateBuilderAccess = useHasRouteTemplateBuilderAccess();
  const features = useSelector(state => state.vendors.features.features);
  const { pathname } = useLocation();

  if (!features.length) {
    /**
     * If features are not loaded, then the user is redirected away when accessing directly
     * the Route Template Builder page and since the checks done to verify access
     * require the features to be loaded, the only thing to do is wait.
     */
    return <PageLoading />;
  }

  // TODO: Remove this when the old route planner (templates listing) becomes obsolete
  const isRoutePlannerBetaPageActive = isRoutePlannerNewLayoutActive();

  if (pathname === '/routes/route-templates' && isRoutePlannerBetaPageActive) {
    return <Redirect to="/routes/route-planner" />;
  }
  if (pathname.includes('/routes/route-planner') && !isRoutePlannerBetaPageActive) setRoutePlannerNewLayoutActive(true);

  return (
    <Switch>
      <Route exact path="/routes/route-tracker/create" component={RouteEditorPageResolver} />
      <Route
        exact
        path={['/routes/snow-tracker/create', '/routes/sweeper-tracker/create']}
        component={CreateSnowOrSweeperRoutePageResolver}
      />
      <Route
        exact
        path={['/routes/snow-tracker/:routeId', '/routes/sweeper-tracker/:routeId']}
        component={SnowOrSweeperRoutePageResolver}
      />
      <Redirect exact from="/routes/daily-routes/create" to="/routes/route-tracker/create" />
      <Redirect exact from="/routes/route-tracker-beta/:routeId" to="/routes/route-tracker/:routeId" />
      <Redirect exact from="/routes/route-tracker-old/:routeId" to="/routes/route-tracker/:routeId" />
      <Route exact path={'/routes/route-tracker/:routeId'} component={NewRoutePageResolver} />
      <Redirect exact from="/routes/daily-routes/:routeId" to="/routes/route-tracker/:routeId" />
      <Route exact path="/routes/route-tracker" component={RouteTrackerPageResolver} />
      <Route
        exact
        path={['/routes/snow-tracker', '/routes/sweeper-tracker']}
        component={SnowOrSweeperTrackerPageResolver}
      />

      <Route exact path="/routes/y-daily-routes/:routeId" component={YRoutePageResolver} />

      <Redirect exact from="/routes/daily-routes/:routeId/edit" to="/routes/route-tracker/:routeId" />

      <Redirect exact from="/routes/route-tracker/snow-plow/:routeId" to="/routes/snow-tracker/:routeId" />
      <Redirect exact from="/routes/route-tracker/street-sweeper/:routeId" to="/routes/sweeper-tracker/:routeId" />

      <RestrictedRoute
        exact
        path="/routes/route-tracker/:routeId/route-sequence"
        component={NewRouteSequenceEditorPageResolver}
        permission={ROUTES_TRACKER_SEQUENCE_ROUTE}
      />
      <Redirect
        exact
        from="/routes/route-tracker-old/:routeId/route-sequence"
        to="/routes/route-tracker/:routeId/route-sequence"
      />
      <Redirect
        exact
        from="/routes/route-tracker-beta/:routeId/route-sequence"
        to="/routes/route-tracker/:routeId/route-sequence"
      />
      <Redirect
        exact
        from="/routes/daily-routes/:routeId/route-sequence"
        to="/routes/route-tracker/:routeId/route-sequence"
      />
      <RestrictedRoute
        exact
        path="/routes/route-templates/:routeTemplateId/route-sequence"
        component={RouteTemplateSequenceEditorPageResolverNew}
        permission={ROUTES_TRACKER_SEQUENCE_ROUTE}
      />
      <Route
        exact
        path={['/routes/route-templates/snow-plow/create', '/routes/route-templates/street-sweeper/create']}
        component={SnowOrSweeperRouteTemplateEditorPageResolver}
      />
      <Route
        exact
        path={[
          '/routes/route-templates/snow-plow/:routeTemplateId',
          '/routes/route-templates/street-sweeper/:routeTemplateId',
        ]}
        component={SnowOrSweeperRouteTemplatePageResolver}
      />
      <Route exact path="/routes/route-templates" component={RouteTemplatesPageResolver} />
      <Route exact path="/routes/route-templates/create" component={RouteTemplateEditorPageResolver} />
      <Route exact path="/routes/route-templates/:routeTemplateId" component={RouteTemplatePageResolver} />
      <RestrictedRoute
        exact
        path="/routes/route-templates/:routeTemplateId/edit"
        component={RouteTemplateEditorPageResolver}
        permission={ROUTES_PLANNER_DETAILS_EDIT}
      />
      <RestrictedRoute
        exact
        path={[
          '/routes/route-templates/snow-plow/:routeTemplateId/edit',
          '/routes/route-templates/street-sweeper/:routeTemplateId/edit',
        ]}
        component={SnowOrSweeperRouteTemplateEditorPageResolver}
        permission={ROUTES_PLANNER_DETAILS_EDIT}
      />
      <RestrictedRoute
        exact
        path="/routes/route-templates/:routeTemplateId/editPickupLocations"
        component={PickupLocationsEditorPageResolver}
        permission={ROUTES_PLANNER_DETAILS_EDIT}
      />

      <Route exact path="/routes/dispatch-board" component={DispatchBoardPageResolver} />
      <Route exact path="/routes/disposals-details" component={RouteDisposalsSummaryResolver} />
      <Route exact path="/routes/issues-reported" component={RouteIssuesReportedSummaryResolver} />

      <Route
        exact
        path={['/routes/disposals-summary', '/routes/material-pickups-summary', '/routes/water-fills-summary']}
        component={MaterialTripsSummaryPageResolver}
      />

      <RestrictedRoute
        exact
        path="/routes/service-zones"
        component={ServiceZonesPageResolver}
        permission={ROUTES_PLANNER_SERVICE_ZONES}
      />
      <RestrictedRoute
        exact
        path="/routes/holiday-planner"
        component={HolidayPlannerPageResolver}
        permission={ROUTES_PLANNER_HOLIDAY_PLANNER}
      />

      <Route exact path="/routes/route-planner" component={RoutePlannerMainPageResolver} />
      <Route exact path="/routes/route-planner/daily-routes" component={RoutePlannerDailyRoutesPageResolver} />
      <Route exact path="/routes/route-planner/reoccurring-routes" component={RoutePlannerReoccurringPageResolver} />
      <Route exact path="/routes/route-planner/daily-routes/settings" component={RoutePlannerSettingsPageResolver} />
      <Route
        exact
        path="/routes/route-planner/reoccurring-routes/settings"
        component={RoutePlannerSettingsPageResolver}
      />
      <Route exact path="/routes/groups" component={GroupsPageResolver} />
      {hasRouteTemplateBuilderAccess && (
        <>
          <AuthRoute
            exact
            path="/routes/route-template-builder"
            component={RouteTemplateBuilderWorkSessionsPageResolver}
          />

          <AuthRoute
            exact
            path="/routes/route-template-builder/:workSessionId"
            component={RouteTemplateBuilderWorkSessionPageResolver}
          />
        </>
      )}

      <Redirect to="/routes/route-tracker" />
    </Switch>
  );
};

export default Routes;
