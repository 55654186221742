import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import * as Services from '../services/vehicleIssues';
import { VehicleIssuesState, VehicleIssue } from '../interfaces/VehicleIssues';
import { TechnicalType } from '../../common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'vendors/vehicle/vehicleIssues/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicle/vehicleIssues/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicle/vehicleIssues/FAIL_LOAD';
const START_LOAD_ISSUE_STATUSES = 'vendors/vehicle/vehicleIssues/START_LOAD_ISSUE_STATUSES';
const COMPLETE_LOAD_ISSUE_STATUSES = 'vendors/vehicle/vehicleIssues/COMPLETE_LOAD_ISSUE_STATUSES';
const FAIL_LOAD_ISSUE_STATUSES = 'vendors/vehicle/vehicleIssues/FAIL_LOAD_ISSUE_STATUSES';
const START_LOAD_DRIVERS = 'vendors/vehicle/vehicleIssues/START_LOAD_DRIVERS';
const COMPLETE_LOAD_DRIVERS = 'vendors/vehicle/vehicleIssues/COMPLETE_LOAD_DRIVERS';
const FAIL_LOAD_DRIVERS = 'vendors/vehicle/vehicleIssues/FAIL_LOAD_DRIVERS';
const START_LOAD_ISSUE_SUBCATEGORIES = 'vendors/vehicle/vehicleIssues/START_LOAD_ISSUE_SUBCATEGORIES';
const COMPLETE_LOAD_ISSUE_SUBCATEGORIES = 'vendors/vehicle/vehicleIssues/COMPLETE_LOAD_ISSUE_SUBCATEGORIES';
const FAIL_LOAD_ISSUE_SUBCATEGORIES = 'vendors/vehicle/vehicleIssues/FAIL_LOAD_ISSUE_SUBCATEGORIES';
const START_UPDATE_ISSUE = 'vendors/vehicle/vehicleIssues/START_UPDATE_ISSUE';
const COMPLETE_UPDATE_ISSUE = 'vendors/vehicle/vehicleIssues/COMPLETE_UPDATE_ISSUE';
const FAIL_UPDATE_ISSUE = 'vendors/vehicle/vehicleIssues/FAIL_UPDATE_ISSUE';
const RESET = 'vendors/vehicle/vehicleIssues/RESET';

const initialState: VehicleIssuesState = {
  issues: [],
  issuesLoading: false,

  issueStatuses: [],
  issueStatusesLoading: false,

  drivers: [],
  driversLoading: false,

  issueSubcategories: [],
  issueSubcategoriesLoading: false,

  issueIsUpdating: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          issuesLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          issues: action.issues,
          issuesLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          issues: [],
          issuesLoading: false,
        },
      });

    case START_LOAD_ISSUE_STATUSES:
      return update(state, {
        $merge: {
          issueStatusesLoading: true,
        },
      });

    case COMPLETE_LOAD_ISSUE_STATUSES:
      return update(state, {
        $merge: {
          issueStatuses: action.issueStatuses,
          issueStatusesLoading: false,
        },
      });

    case FAIL_LOAD_ISSUE_STATUSES:
      return update(state, {
        $merge: {
          issueStatuses: [],
          issueStatusesLoading: false,
        },
      });

    case START_LOAD_DRIVERS:
      return update(state, {
        $merge: {
          driversLoading: true,
        },
      });

    case COMPLETE_LOAD_DRIVERS:
      return update(state, {
        $merge: {
          drivers: action.drivers,
          driversLoading: false,
        },
      });

    case FAIL_LOAD_DRIVERS:
      return update(state, {
        $merge: {
          drivers: [],
          driversLoading: false,
        },
      });

    case START_LOAD_ISSUE_SUBCATEGORIES:
      return update(state, {
        $merge: {
          issueSubcategoriesLoading: true,
        },
      });

    case COMPLETE_LOAD_ISSUE_SUBCATEGORIES:
      return update(state, {
        $merge: {
          issueSubcategories: action.issueSubcategories,
          issueSubcategoriesLoading: false,
        },
      });

    case FAIL_LOAD_ISSUE_SUBCATEGORIES:
      return update(state, {
        $merge: {
          issueSubcategories: [],
          issueSubcategoriesLoading: false,
        },
      });

    case START_UPDATE_ISSUE:
      return update(state, {
        $merge: {
          issueIsUpdating: true,
        },
      });

    case COMPLETE_UPDATE_ISSUE:
      return update(state, {
        $merge: {
          issueIsUpdating: false,
        },
      });

    case FAIL_UPDATE_ISSUE:
      return update(state, {
        $merge: {
          issueIsUpdating: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (issues: VehicleIssue[]) => ({
  type: COMPLETE_LOAD,
  issues,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadStatuses = () => ({
  type: START_LOAD_ISSUE_STATUSES,
});

const completeLoadStatuses = (issueStatuses: TechnicalType[]) => ({
  type: COMPLETE_LOAD_ISSUE_STATUSES,
  issueStatuses,
});

const failLoadStatuses = () => ({
  type: FAIL_LOAD_ISSUE_STATUSES,
});

const startLoadDrivers = () => ({
  type: START_LOAD_DRIVERS,
});

const completeLoadDrivers = (drivers: string[]) => ({
  type: COMPLETE_LOAD_DRIVERS,
  drivers,
});

const failLoadDrivers = () => ({
  type: FAIL_LOAD_DRIVERS,
});

const startLoadIssueSubcategories = () => ({
  type: START_LOAD_ISSUE_SUBCATEGORIES,
});

const completeLoadIssueSubcategories = (issueSubcategories: TechnicalType[]) => ({
  type: COMPLETE_LOAD_ISSUE_SUBCATEGORIES,
  issueSubcategories,
});

const failLoadIssueSubcategories = () => ({
  type: FAIL_LOAD_ISSUE_SUBCATEGORIES,
});

const startUpdateIssue = () => ({
  type: START_UPDATE_ISSUE,
});

const completeUpdateIssue = () => ({
  type: COMPLETE_UPDATE_ISSUE,
});

const failUpdateIssue = () => ({
  type: FAIL_UPDATE_ISSUE,
});

export const resetVehicleIssues = () => ({
  type: RESET,
});

export const loadVehicleIssues =
  (vehicleId: number, filters?: Services.LoadVehicleIssuesFilters) => (dispatch: Dispatch) => {
    dispatch(startLoad());

    const promise = Services.loadVehicleIssues(vehicleId, filters);

    promise
      .then(issues => {
        dispatch(completeLoad(issues));
      })
      .catch(() => {
        dispatch(failLoad());
      });

    return promise;
  };

export const loadVehicleIssueStatuses = () => (dispatch: Dispatch) => {
  dispatch(startLoadStatuses());

  const promise = Services.loadVehicleIssueStatuses();

  promise
    .then(issueStatuses => {
      dispatch(completeLoadStatuses(issueStatuses));
    })
    .catch(() => {
      dispatch(failLoadStatuses());
    });

  return promise;
};

export const loadVehicleDrivers = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadDrivers());

  const promise = Services.loadVehicleDrivers(vehicleId);

  promise
    .then(drivers => {
      dispatch(completeLoadDrivers(drivers));
    })
    .catch(() => {
      dispatch(failLoadDrivers());
    });

  return promise;
};

export const loadVehicleIssueSubcategories = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadIssueSubcategories());

  const promise = Services.loadVehicleIssueSubcategories(vehicleId);

  promise
    .then(issueSubcategories => {
      dispatch(completeLoadIssueSubcategories(issueSubcategories));
    })
    .catch(() => {
      dispatch(failLoadIssueSubcategories());
    });

  return promise;
};

export const updateVehicleIssue =
  (vehicleId: number, tripInspectionDetailId: number, statusId: number, vehicleNote?: string) =>
  (dispatch: Dispatch) => {
    dispatch(startUpdateIssue());

    const promise = Services.updateVehicleIssue(vehicleId, tripInspectionDetailId, statusId, vehicleNote);

    promise
      .then(() => {
        dispatch(completeUpdateIssue());
      })
      .catch(() => {
        dispatch(failUpdateIssue());
      });

    return promise;
  };
