import { http } from '../../core/services/http';
import { LocationAlert } from '../interfaces/LocationAlert';

export const loadLocationAlerts = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/locationAlertTypes`).then(response => response.data);

export const createLocationAlert = (vendorId: number, locationAlert: LocationAlert) =>
  http.post(`/vendors/${vendorId}/locationAlertTypes`, locationAlert).then(response => response.data);

export const updateLocationAlert = (vendorId: number, locationAlert: LocationAlert) =>
  http.put(`/vendors/${vendorId}/locationAlertTypes`, locationAlert).then(response => response.data);

export const deleteLocationAlert = (vendorId: number, locationAlertTypeId: number) =>
  http.delete(`/vendors/${vendorId}/locationAlertTypes/${locationAlertTypeId}`).then(response => response.data);
