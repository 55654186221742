import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import {
  loadVehicleNotificationsConfigurations,
  loadVehicleNotificationsTypes,
} from '../../ducks/vehicleNotifications';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import VehicleNotificationsModal from './VehicleNotificationsModal';

const VehicleNotificationsModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadVehicleNotificationsTypes()(dispatch),
      loadVehicleNotificationsConfigurations(vendorId)(dispatch),
    ];

    return Promise.all(dependencies);
  }, [vendorId, dispatch]);

  return (
    <TypedResolver
      successComponent={VehicleNotificationsModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default VehicleNotificationsModalResolver;
