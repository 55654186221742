import { http } from '../../core/services/http';
import { VideoRequestFormValues } from '../components/pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestForm';
import { transformCameraConfigurations } from './transformCameraConfigurations';

export const saveVideoRequest = ({ ...videoRequest }: VideoRequestFormValues) => {
  delete videoRequest.date;
  delete videoRequest.time;
  delete videoRequest.vehicleName;

  return http
    .post(`/videoDownload/${videoRequest.vendorId}/requestVideoDownload`, { ...videoRequest })
    .then(response => response.data);
};

export const loadVideoDownloadDetails = (vendorId: number, videoDownloadJobId?: number) =>
  http
    .get(`/videoDownload/${vendorId}/videoDownload`, { params: { videoDownloadJobId } })
    .then(response => response.data);

export const loadVideoRequests = (
  vendorId: number,
  userId: string,
  startDate?: Date | string,
  endDate?: Date | string,
  videoStatus?: number[],
) =>
  http
    .get(`/videoDownload/${vendorId}/videoDownloads`, {
      params: {
        vendorId,
        userId,
        startDate,
        endDate,
        videoDownloadStatusesCSV: videoStatus?.toString(),
      },
    })
    .then(response => response.data);

export const loadVideoStatuses = () => http.get(`/common/videoDownloadJobStatusValues`).then(response => response.data);

export const loadCameraConfigurationsByVehicle = (vehicleId: number) =>
  http.get(`/vehicles/${vehicleId}/cameraconfiguration`).then(response => transformCameraConfigurations(response.data));
