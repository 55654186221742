import { change } from 'redux-form';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { CITY_ALERT_CREATE_FORM, CityAlertCreateFormValues } from '../../forms/CityAlertCreateForm';
import {
  CITY_ALERT_TYPE_ID,
  LOCATION_ALERT_TYPE_ID,
  NEW_LOCATION_ALERT_ID,
} from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlertCreateForm } from '../../forms';
import { Coordinates } from 'src/common/components/map/Coordinates';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { getLatLng } from 'src/fleet/components/pages/locationAndCityAlertsSections/utils';
import { LocationData } from 'src/customers/interfaces/LocationServiceTypes';
import { LocationMapPicker } from 'src/routes/components';
import { ModalSection } from 'src/core/components/styled';
import { saveCityAlert } from 'src/vendors/ducks';
import { saveGeocode } from 'src/customers/services/locations';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import Modal, { ModalProps } from 'src/core/components/Modal';
import translate from 'src/core/services/translate';

const modalId = 'city-alert-create-modal';

export interface Props extends ModalProps {
  isCityAlert?: boolean;
  isLocationAlert?: boolean;
  isSourceLocationAndCityAlerts?: boolean;
  modalTitle: string;
  onSavedSuccess?: (id?: number) => void;
  routeId?: number;
}

const CityAlertCreateModal: FC<Props> = ({
  isCityAlert,
  isLocationAlert,
  isSourceLocationAndCityAlerts,
  modalTitle,
  onClose,
  onSavedSuccess,
  routeId,
}) => {
  const dispatch = useDispatch();

  const { isSaving } = useSelector(state => state.vendors.cityAlerts);

  const [isFetchingAddress, setIsFetchingAddress] = useState(false);
  const [isLocationPickerChanged, setIsLocationPickerChanged] = useState(false);
  const [currentPinnedAddress, setCurrentPinnedAddress] = useState<LocationData | undefined>();
  const [currentLocationId, setCurrentLocationId] = useState<number | undefined>();
  const [isPinOnMapVisible, setIsPinOnMapVisible] = useState<boolean>(true);

  const updateLatitudeAndLongitude = useCallback(
    (latitude?: number, longitude?: number) => {
      dispatch(change(CITY_ALERT_CREATE_FORM, 'latitude', latitude ? getLatLng(latitude) : undefined));
      dispatch(change(CITY_ALERT_CREATE_FORM, 'longitude', longitude ? getLatLng(longitude) : undefined));
    },
    [dispatch],
  );

  const updatePinAddress = useCallback(
    ({ lat, lng }: Coordinates) => {
      setIsFetchingAddress(true);
      setIsLocationPickerChanged(false);
      saveGeocode(lat, lng)
        .then(loc => {
          const location = {
            ...loc,
            latitude: lat,
            longitude: lng,
          };

          const pinnedAddress = {
            ...location,
            formattedAddress: location.line1,
            latitude: location.latitude,
            longitude: location.longitude,
          };

          dispatch(change(CITY_ALERT_CREATE_FORM, 'pinnedAddress', pinnedAddress));

          setCurrentPinnedAddress(pinnedAddress);
          setCurrentLocationId(undefined);

          updateLatitudeAndLongitude(location.latitude, location.longitude);
          setIsFetchingAddress(false);
        })
        .catch(err => {
          setIsFetchingAddress(false);
        });
    },
    [dispatch, updateLatitudeAndLongitude],
  );

  const handleCreateCityAlert = useCallback(
    (values: CityAlertCreateFormValues) => {
      const isSourceLocationAndCityAlerts = !values.expirationTime && !values.expirationDate;

      const payload = {
        createdOn: moment().utc().toDate(),
        endDate: isSourceLocationAndCityAlerts ? values.endDate : values.expirationDate,
        isActive: values.isActive,
        latitude: currentPinnedAddress?.latitude,
        longitude: currentPinnedAddress?.longitude,
        startDate: values.startDate,
        routeId,
      };

      const cityAlertPayload = {
        ...payload,
        alertTypeId: CITY_ALERT_TYPE_ID,
        cityAlertTypeId: values.alertTypeId,
        endTime: isSourceLocationAndCityAlerts ? undefined : values.expirationTime,
      };

      const locatinAlertPayload = {
        ...payload,
        alertTypeId: LOCATION_ALERT_TYPE_ID,
        locationAlertTypeId: values.alertTypeId !== NEW_LOCATION_ALERT_ID ? values.alertTypeId : undefined,
        description: values.description,
        title: values.title,
        locationId: currentLocationId,
      };

      scrollToTopOfModal(modalId);

      saveCityAlert(isCityAlert ? cityAlertPayload : locatinAlertPayload)(dispatch)
        .then(response => {
          createSuccessNotification(
            translate(`vendors.cityAlerts.alertMessages.${isCityAlert ? 'cityAlertCreated' : 'locationAlertCreated'}`),
          );
          onClose && onClose(true);
          onSavedSuccess && onSavedSuccess(response.id);
        })
        .catch(() => {
          createErrorNotification(
            translate(`vendors.cityAlerts.alertMessages.${isCityAlert ? 'cityAlertError' : 'locationAlertError'}`),
          );
        });
    },
    [dispatch, onClose, onSavedSuccess, routeId, currentPinnedAddress, currentLocationId, isCityAlert],
  );

  const initialValues = {
    expirationDate: isSourceLocationAndCityAlerts ? undefined : TODAY,
    expirationTime: isSourceLocationAndCityAlerts ? undefined : '11:45 PM', // giving initial value to time picker (its a string)
    isActive: true,
  };

  const onLocationPickerChanged = (
    pinnedAddress?: any | LocationData,
    locationId?: number,
    isPinOnMapVisible?: boolean,
  ) => {
    setCurrentPinnedAddress(pinnedAddress);
    setCurrentLocationId(locationId);
    setIsLocationPickerChanged(true);
    setIsPinOnMapVisible(isPinOnMapVisible !== undefined ? isPinOnMapVisible : true);

    updateLatitudeAndLongitude(getLatLng(pinnedAddress?.latitude), getLatLng(pinnedAddress?.longitude));
  };

  return (
    <Modal
      title={translate(modalTitle)}
      size="mediumLarge"
      padding="medium no no"
      onClose={onClose}
      isLoading={isSaving}
      overflow={isSaving ? 'hidden' : 'auto'}
      id={modalId}
    >
      <CityAlertCreateForm
        initialValues={initialValues}
        isCityAlert={isCityAlert}
        isFetchingAddress={isFetchingAddress}
        isLocationAlert={isLocationAlert}
        isSourceLocationAndCityAlerts={isSourceLocationAndCityAlerts}
        onLocationPickerChanged={onLocationPickerChanged}
        onSubmit={handleCreateCityAlert}
      />
      <ModalSection height="500px" width="100%" padding="small" position="relative">
        <LocationMapPicker
          handleLocationChange={updatePinAddress}
          isCityAlert={isCityAlert}
          isLocationAlert={isLocationAlert}
          isLocationPickerChanged={isLocationPickerChanged}
          isPinOnMapVisible={isPinOnMapVisible}
          location={currentPinnedAddress}
        />
      </ModalSection>
    </Modal>
  );
};

export default CityAlertCreateModal;
