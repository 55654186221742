import { httpInsightsReporting } from '../../core/services/http';
import { createUrl } from '../../utils/services/queryParams';
import transformLoadYServices from './transformLoadYServices';

const loadYServices = (binInsightId: number) =>
  httpInsightsReporting
    .get(createUrl('services', undefined, { binInsightId }))
    .then(response => transformLoadYServices(response.data));

export default loadYServices;
