import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { Button, ButtonSet, Text } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { deleteMaterialTicket } from 'src/routes/ducks';
import { loadMaterialTickets } from 'src/routes/ducks/materialTickets';
import { Table } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import MaterialTicketEditorModalResolver from '../MaterialTicketEditorModalResolver';
import MaterialTicketsTableRow from './MaterialTicketsTableRow';
import translate from 'src/core/services/translate';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';

export const getMaterialTicketsTableCellWidths = () => ['9%', '30%', '12%', '10%', '8%', '13%', '8%', '10%'];

const getMaterialTicketsTableCells = () => {
  const materialTicketsTableCellWidths = getMaterialTicketsTableCellWidths();

  const materialTicketsTableCells = [
    {
      name: 'ticketNumber',
      label: translate('common.ticketNumber'),
      width: materialTicketsTableCellWidths[0],
    },
    {
      name: 'puLocation',
      label: translate('common.materialTickets.puLocation'),
      width: materialTicketsTableCellWidths[1],
    },
    {
      name: 'material',
      label: translate('common.material'),
      width: materialTicketsTableCellWidths[2],
    },
    {
      name: 'quantity',
      label: translate('common.materialTickets.quantity'),
      width: materialTicketsTableCellWidths[3],
    },
    {
      name: 'uom',
      label: translate('common.materialTickets.uom'),
      width: materialTicketsTableCellWidths[4],
    },
    {
      name: 'timestamp',
      label: translate('common.materialTickets.materialTicketDate'),
      width: materialTicketsTableCellWidths[5],
    },
    {
      name: 'image',
      label: translate('common.image'),
      width: materialTicketsTableCellWidths[6],
    },
    {
      name: 'options',
      label: translate('common.options'),
      width: materialTicketsTableCellWidths[7],
      align: 'right',
    },
  ];

  return materialTicketsTableCells;
};

export default function MaterialTicketsSection() {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { materialTickets } = useSelector(state => state.routes.materialTickets) || [];

  const [isMaterialTicketEditorModalOpen, setIsMaterialTicketEditorModalOpen] = useState(false);
  const [selectedMaterialTicket, setSelectedMaterialTicket] = useState<number>();

  if (!routeSummary) return null;

  const openMaterialTicketEditorModalOpen = (materialTicketId?: number) => {
    if (materialTicketId) setSelectedMaterialTicket(materialTicketId);
    setIsMaterialTicketEditorModalOpen(true);
  };

  const closeMaterialTicketEditorModal = () => {
    setIsMaterialTicketEditorModalOpen(false);
    setSelectedMaterialTicket(undefined);
  };

  const handleDeleteMaterialTicket = async (materialTicketId: number) => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteMaterialTicket')))) {
      return;
    }

    deleteMaterialTicket(materialTicketId)(dispatch)
      .then(() => {
        loadMaterialTickets(routeSummary.routeId)(dispatch);
        createSuccessNotification(translate('routes.alertMessages.materialTicketDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.materialTicketDeleteError'));
      });
  };
  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();
  return (
    <>
      {materialTickets && materialTickets.length ? (
        <Table
          cells={getMaterialTicketsTableCells()}
          rows={materialTickets}
          rowComponent={MaterialTicketsTableRow}
          rowProps={{
            editMaterialTicket: openMaterialTicketEditorModalOpen,
            deleteMaterialTicket: handleDeleteMaterialTicket,
          }}
        />
      ) : (
        <Text block align="center" margin="medium no">
          {translate('common.materialTickets.noMaterialTickets')}
        </Text>
      )}
      {!isSupport && !isViewOnly && (
        <ButtonSet margin="sMedium no">
          <Button type="button" color="primary" line onClick={() => openMaterialTicketEditorModalOpen(undefined)}>
            + {translate('common.materialTickets.addMaterialTicket')}
          </Button>
        </ButtonSet>
      )}

      {isMaterialTicketEditorModalOpen && (
        <MaterialTicketEditorModalResolver
          routeId={routeSummary.routeId}
          materialTicketId={selectedMaterialTicket}
          onClose={closeMaterialTicketEditorModal}
        />
      )}
    </>
  );
}
