import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';

import { addNotes } from '../../ducks';
import { AppState } from 'src/store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from 'src/contracts/ducks';
import { getNotes } from '../../ducks';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { NoteItem } from 'src/fleet/interfaces/RubiconDispatchesNotes';
import { NotesForm } from '../forms';
import { RouteComponentProps } from 'react-router';
import translate from '../../../core/services/translate';

type RouteParams = {
  token: string;
};

interface Props extends RouteComponentProps<RouteParams> {
  addNotes: DuckFunction<typeof addNotes>;
  getNotes: DuckFunction<typeof getNotes>;
  isSaving: boolean;
  note: string;
  notes: NoteItem[];
  onCancel: (pristine?: boolean, isReload?: boolean) => void;
  reasonCode: string;
  rubiconPONbr: string;
  serviceDate: string;
  userId: string;
  vendorId: number;
}

class NotesModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      addNotes,
      match: {
        params: { token },
      },
      note,
      onCancel,
      reasonCode,
      rubiconPONbr,
      serviceDate,
      userId,
      vendorId,
    } = this.props;

    const body = { workOrder: rubiconPONbr, userNote: note, reasonCode, serviceDate };

    const promise = addNotes({
      body,
      token,
      userId,
      vendorId,
    });

    promise &&
      promise
        .then(() => {
          const isReload = true;
          createSuccessNotification(translate('opportunity.opportunitySave'));
          reset('notesForm');
          onCancel(true, isReload);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
  };

  render() {
    const { notes, isSaving } = this.props;

    return (
      <Modal size="small" isLoading={isSaving}>
        <ModalTitle>{notes.length > 0 ? translate('common.note') : ''}</ModalTitle>
        <ModalSubtitle />
        <Grid centered>
          <GridColumn size="12/12">
            <NotesForm {...this.props} onSubmit={this.onSubmit} />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('notesForm');

const mapStateToProps = (state: AppState) => ({
  note: formSelector(state, 'note'),
  reasonCode: formSelector(state, 'reasonCode'),
  isSaving: state.fleet.notes.isSaving,
  notes: state.fleet.notes.notes || [],
});

const mapDispatchToProps = {
  addNotes,
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);
