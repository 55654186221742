import { AnyAction, Dispatch } from 'redux';
import { orderBy } from 'lodash-es';
import update from 'immutability-helper';

import {
  loadStreetsImportUploadedFilesStatus as doLoadStreetsImportUploadedFilesStatus,
  uploadStreetsImportFile as doUploadStreetsImportFile,
} from '../services/streets';
import { StreetsImportFile, StreetsReducerState } from '../interfaces/Streets';

// Actions
const START_LOAD = 'finance/customers/streets/START_LOAD';
const COMPLETE_LOAD = 'finance/customers/streets/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/customers/streets/FAIL_LOAD';
const UPDATE_UPLOAD_PERCENT = 'finance/customers/streets/UPDATE_UPLOAD_PERCENT';
const START_UPLOAD = 'finance/customers/streets/START_UPLOAD';
const COMPLETE_UPLOAD = 'finance/customers/streets/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'finance/customers/streets/FAIL_UPLOAD';

// Initial state
const initialState: StreetsReducerState = {
  isLoading: false,
  isUploading: false,
  uploadedFiles: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        isLoading: { $set: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        isLoading: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });

    case FAIL_LOAD:
      return update(state, {
        isLoading: { $set: false },
      });

    case START_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: true,
        },
      });

    case COMPLETE_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
          uploadedFiles: action.uploadedFiles,
        },
      });

    case FAIL_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
          uploadedFiles: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (uploadedFiles: StreetsImportFile[]) => ({
  type: COMPLETE_LOAD,
  uploadedFiles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startUpload = () => ({
  type: START_UPLOAD,
});

const completeUpload = (uploadedFiles: StreetsImportFile[]) => ({
  type: COMPLETE_UPLOAD,
  uploadedFiles,
});

const failUpload = () => ({
  type: FAIL_UPLOAD,
});

export const loadStreetsImportUploadedFilesStatus = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStreetsImportUploadedFilesStatusPromise = doLoadStreetsImportUploadedFilesStatus();

  loadStreetsImportUploadedFilesStatusPromise
    .then((uploadedFiles: StreetsImportFile[]) => {
      dispatch(completeLoad(orderBy(uploadedFiles, 'date', 'desc')));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadStreetsImportUploadedFilesStatusPromise;
};

export const uploadStreetsImportFile = (fileData: File, vehicleTypeId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadStreetsImportFilePromise = doUploadStreetsImportFile(fileData, vehicleTypeId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadStreetsImportFilePromise
    .then((uploadedFiles: StreetsImportFile[]) => {
      dispatch(completeUpload(orderBy(uploadedFiles, 'date', 'desc')));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadStreetsImportFilePromise;
};
