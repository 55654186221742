import React, { PureComponent } from 'react';
import Cookie from 'js-cookie';
import { assign, pickBy, size } from 'lodash-es';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { getSelectedContactTypeIds } from '../../services/transformContacts';
import { HAULER_PROFILE_DIVISION_COOKIE } from '../../constants/HaulerProfileDivisionCookie';
import { HPORT } from '../../constants/contactTypes';
import { loadContacts, saveContacts, updateContacts } from '../../ducks';
import { Modal } from '../../../core/components';
import { ModalTitle } from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import ContactEditorForm from '../forms/ContactEditorForm';
import translate from './../../../core/services/translate';

interface Props extends RouteComponentProps {
  closeContactEditorModal: () => void;
  contactId?: number;
  contactList: any[];
  functionTypes: any;
  gusId?: string;
  isLoading: boolean;
  isSaving: boolean;
  isSavingContact: boolean;
  loadContacts(...args: any[]): any;
  onCancel: () => void;
  saveContacts: (gusId: any, data: any) => Promise<any>;
  updateContacts: (gusId: any, data: any) => Promise<any>;
  userName: string;
}

class ContactEditorModal extends PureComponent<Props> {
  onSubmitContact = async (formData: any) => {
    const { closeContactEditorModal, contactList, gusId, loadContacts, saveContacts, updateContacts, userName } =
      this.props;

    const checkedContactTypes = pickBy(formData, obj => obj === true);
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    const selectedContactTypeIds = getSelectedContactTypeIds(checkedContactTypes);

    const data = {
      contactType: HPORT,
      emailAddress: formData.emailAddress,
      firstName: formData.firstName.trim(),
      functionTypes: selectedContactTypeIds,
      isPrimary: formData.isPrimary,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
      updatedBy: userName,
      vendId: divisionIdFromCookie,
    };

    const primaryContactSelected = contactList
      ? contactList.filter(
          contact => contact.contactType === formData.contactType && contact.id !== formData.id && contact.isPrimary,
        )
      : [];

    if (size(primaryContactSelected) && formData.isPrimary) {
      if (!(await confirm(translate('haulerProfile.alertMessages.primaryContactSelected')))) {
        return;
      }
    }

    if (formData.id) {
      const updateParams = assign(data, { id: formData.id });
      updateContacts(gusId, updateParams)
        .then(() => {
          closeContactEditorModal();
          loadContacts(gusId, HPORT, divisionIdFromCookie);
          createSuccessNotification(`${translate('haulerProfile.alertMessages.contactUpdated')}`);
        })
        .catch(() => {
          createErrorNotification(`${translate('haulerProfile.alertMessages.errorContactSave')}`);
        });
    } else {
      saveContacts(gusId, data)
        .then(() => {
          closeContactEditorModal();
          loadContacts(gusId, HPORT, divisionIdFromCookie);
          createSuccessNotification(`${translate('haulerProfile.alertMessages.contactSaved')}`);
        })
        .catch(() => {
          createErrorNotification(`${translate('haulerProfile.alertMessages.errorContactSave')}`);
        });
    }
  };

  render() {
    const { contactId, functionTypes, isSavingContact, onCancel } = this.props;

    return (
      <Modal padding="medium" size="small" isLoading={isSavingContact}>
        <ModalTitle>{translate(`haulerProfile.${contactId ? 'edit' : 'addNew'}Contact`)}</ModalTitle>
        <ContactEditorForm
          onSubmit={this.onSubmitContact}
          onCancel={onCancel}
          contactId={contactId}
          functionTypes={functionTypes}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  functionTypes: state.haulerProfile.contacts.functionTypes,
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  isSavingContact: state.haulerProfile.contacts.isSaving,
  userName: state.account.login.user.email,
});

const mapDispatchToProps = {
  loadContacts,
  saveContacts,
  updateContacts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactEditorModal));
