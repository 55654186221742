import { http } from '../../core/services/http';
import { transformInvoices } from './transformInvoices';
import translate from '../../core/services/translate';
import { downloadExcelFilebyPost } from '../../utils/services/downloadExcelFile';

export const loadInvoices = (findInvoicesObj: any) =>
  http.post(`gus/vendor/invoice/search`, findInvoicesObj).then(response => {
    return transformInvoices(response.data);
  });

export const exportInvoices = (findInvoicesObj: any) => {
  return downloadExcelFilebyPost(
    `gus/vendor/invoice/search/export`,
    `${translate('invoice.invoices')}`,
    findInvoicesObj,
  );
};
