import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  saveVideoRequest as doSaveVideoRequest,
  loadCameraConfigurationsByVehicle as doLoadCameraConfigurationsByVehicle,
  loadVideoDownloadDetails as doLoadVideoDownloadDetails,
  loadVideoRequests as doLoadVideoRequests,
  loadVideoStatuses as doLoadVideoStatuses,
} from '../services/videoRequest';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { VideoDownloadDetails } from 'src/common/interfaces/VideoDownloadDetails';
import {
  VideoRequestFormValues,
  CameraConfigurations,
} from '../components/pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestForm';

// Actions
const START_SAVE = 'routes/videoRequest/START_SAVE';
const COMPLETE_SAVE = 'routes/videoRequest/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/videoRequest/FAIL_SAVE';
const START_LOAD = 'routes/videoRequest/START_LOAD';
const COMPLETE_LOAD = 'routes/videoRequest/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/videoRequest/FAIL_LOAD';
const START_LOAD_VIDEO_REQUESTS = 'routes/videoRequest/START_LOAD_VIDEO_REQUESTS';
const COMPLETE_LOAD_VIDEO_REQUESTS = 'routes/videoRequest/COMPLETE_LOAD_VIDEO_REQUESTS';
const FAIL_LOAD_VIDEO_REQUESTS = 'routes/videoRequest/FAIL_LOAD_VIDEO_REQUESTS';
const START_LOAD_VIDEO_STATUSES = 'routes/videoRequest/START_LOAD_VIDEO_STATUSES';
const COMPLETE_LOAD_VIDEO_STATUSES = 'routes/videoRequest/COMPLETE_LOAD_VIDEO_STATUSES';
const FAIL_LOAD_VIDEO_STATUSES = 'routes/videoRequest/FAIL_LOAD_VIDEO_STATUSES';
const START_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE = 'routes/videoRequest/START_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE';
const COMPLETE_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE =
  'routes/videoRequest/COMPLETE_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE';
const FAIL_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE = 'routes/videoRequest/FAIL_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE';

interface State {
  isLoading: boolean;
  isLoadingCameraConfigurationsByVehicle: boolean;
  isLoadingVideoRequests: boolean;
  isLoadingVideoStatuses: boolean;
  isSaving: boolean;
  videoDownloadDetails: VideoDownloadDetails;
  videoRequests: VideoDownloadDetails[];
  videoStatuses: TechnicalType[];
  cameraConfigurations: CameraConfigurations[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  isLoadingCameraConfigurationsByVehicle: false,
  isLoadingVideoRequests: false,
  isLoadingVideoStatuses: false,
  isSaving: false,
  videoDownloadDetails: {} as VideoDownloadDetails,
  videoRequests: [] as VideoDownloadDetails[],
  videoStatuses: [] as TechnicalType[],
  cameraConfigurations: [] as CameraConfigurations[],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          videoDownloadDetails: action.videoDownloadDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_VIDEO_REQUESTS:
      return update(state, {
        $merge: {
          isLoadingVideoRequests: !action.noLoading,
        },
      });

    case COMPLETE_LOAD_VIDEO_REQUESTS:
      return update(state, {
        $merge: {
          isLoadingVideoRequests: false,
          videoRequests: action.videoRequests,
        },
      });

    case FAIL_LOAD_VIDEO_REQUESTS:
      return update(state, {
        $merge: {
          isLoadingVideoRequests: false,
        },
      });

    case START_LOAD_VIDEO_STATUSES:
      return update(state, {
        $merge: {
          isLoadingVideoStatuses: true,
        },
      });

    case COMPLETE_LOAD_VIDEO_STATUSES:
      return update(state, {
        $merge: {
          isLoadingVideoStatuses: false,
          videoStatuses: action.videoStatuses,
        },
      });

    case FAIL_LOAD_VIDEO_STATUSES:
      return update(state, {
        $merge: {
          isLoadingVideoStatuses: false,
        },
      });

    case START_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE:
      return update(state, {
        $merge: {
          isLoadingCameraConfigurationsByVehicle: true,
        },
      });

    case COMPLETE_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE:
      return update(state, {
        $merge: {
          isLoadingCameraConfigurationsByVehicle: false,
          cameraConfigurations: action.cameraConfigurations,
        },
      });

    case FAIL_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE:
      return update(state, {
        $merge: {
          isLoadingCameraConfigurationsByVehicle: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (videoDownloadDetails: VideoDownloadDetails) => ({
  type: COMPLETE_LOAD,
  videoDownloadDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadVideoRequests = (noLoading?: boolean) => ({
  type: START_LOAD_VIDEO_REQUESTS,
  noLoading,
});

const completeLoadVideoRequests = (videoRequests: VideoDownloadDetails[]) => ({
  type: COMPLETE_LOAD_VIDEO_REQUESTS,
  videoRequests,
});

const failLoadVideoRequests = () => ({
  type: FAIL_LOAD_VIDEO_REQUESTS,
});

const startLoadVideoStatuses = () => ({
  type: START_LOAD_VIDEO_STATUSES,
});

const completeLoadVideoStatuses = (videoStatuses: TechnicalType[]) => ({
  type: COMPLETE_LOAD_VIDEO_STATUSES,
  videoStatuses,
});

const failLoadVideoStatuses = () => ({
  type: FAIL_LOAD_VIDEO_STATUSES,
});

const startLoadCameraConfigurationsByVehicle = () => ({
  type: START_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE,
});

const completeLoadCameraConfigurationsByVehicle = (cameraConfigurations: CameraConfigurations[]) => ({
  type: COMPLETE_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE,
  cameraConfigurations,
});

const failLoadCameraConfigurationsByVehicle = () => ({
  type: FAIL_LOAD_CAMERA_CONFIGURATIONS_BY_VEHICLE,
});

export const saveVideoRequest =
  ({ ...videoRequest }: VideoRequestFormValues) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());

    const selectedCameraConfigurations = videoRequest?.cameras?.filter(cameraConfig => !!cameraConfig.isActive);

    if (videoRequest) {
      videoRequest.cameras = selectedCameraConfigurations;
    }

    const saveVideoRequestPromise = doSaveVideoRequest(videoRequest);
    saveVideoRequestPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return saveVideoRequestPromise;
  };

export const loadVideoDownloadDetails = (vendorId: number, videoDownloadJobId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadVideoDownloadDetailsPromise = doLoadVideoDownloadDetails(vendorId, videoDownloadJobId);
  loadVideoDownloadDetailsPromise
    .then((videoDownloadDetails: VideoDownloadDetails) => dispatch(completeLoad(videoDownloadDetails)))
    .catch(() => dispatch(failLoad()));
  return loadVideoDownloadDetailsPromise;
};

export const loadVideoRequests =
  (
    vendorId: number,
    userId: string,
    startDate?: Date | string,
    endDate?: Date | string,
    videoStatus?: number[],
    noLoading?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadVideoRequests(noLoading));
    const loadVideoRequestsPromise = doLoadVideoRequests(vendorId, userId, startDate, endDate, videoStatus);
    loadVideoRequestsPromise
      .then((videoRequests: VideoDownloadDetails[]) => dispatch(completeLoadVideoRequests(videoRequests)))
      .catch(() => dispatch(failLoadVideoRequests()));
    return loadVideoRequestsPromise;
  };

export const loadVideoStatuses = () => (dispatch: Dispatch) => {
  dispatch(startLoadVideoStatuses());
  const loadVideoStatusesPromise = doLoadVideoStatuses();
  loadVideoStatusesPromise
    .then((videoStatuses: TechnicalType[]) => dispatch(completeLoadVideoStatuses(videoStatuses)))
    .catch(() => dispatch(failLoadVideoStatuses()));
  return loadVideoStatusesPromise;
};

export const loadCameraConfigurationsByVehicle = (vehicleId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadCameraConfigurationsByVehicle());
  const loadCameraConfigurationsByVehiclePromise = doLoadCameraConfigurationsByVehicle(vehicleId);
  loadCameraConfigurationsByVehiclePromise
    .then((cameraConfigurations: CameraConfigurations[]) =>
      dispatch(completeLoadCameraConfigurationsByVehicle(cameraConfigurations)),
    )
    .catch(() => dispatch(failLoadCameraConfigurationsByVehicle()));
  return loadCameraConfigurationsByVehiclePromise;
};
