import React, { useEffect } from "react";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import { useEsriMap } from "./EsriMapContext";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import LabelClass from "@arcgis/core/layers/support/LabelClass";
import { categorySymbolMapping, icCategorySymbolMapping } from "./iconsMapping";

interface GeoJSONFeatureProperties {
    orderNo?: number;
    sOrderNo?: string;
    name?: string;
    description?: string;
    category?: string;
}

interface GeoJSONFeature {
    type: string;
    properties: GeoJSONFeatureProperties;
    geometry: {
        type: string;
        coordinates: number[];
    };
}

interface GeoJSON {
    type: string;
    features: GeoJSONFeature[];
}

// Function to create a SimpleMarkerSymbol with a given color
const createSimpleMarkerSymbol = (color: string) =>
    new SimpleMarkerSymbol({
        color: color,
        size: "14px", // Adjust the size as needed
        outline: {
            color: "white",
            width: 1,
        },
    });

// Function to create a PictureMarkerSymbol with a given URL
const createPictureMarkerSymbol = (url: string) =>
    new PictureMarkerSymbol({
        url: url,
        width: "40px",
        height: "40px",
    });

    // Function to create a PictureMarkerSymbol with a given URL
const createPictureMarkerSymbolRouteStops = (url: string) =>
    new PictureMarkerSymbol({
        url: url,
        // width: "40px",
        // height: "40px",
    });


// Generate unique value infos dynamically
const generateUniqueValueInfos = (modifiedGeoJSON: any) => {
    const uniqueValueInfos: any[] = [];



        Object.keys(icCategorySymbolMapping).forEach((icon) => {
            uniqueValueInfos.push({
                value: icon,
                symbol: createPictureMarkerSymbolRouteStops(icCategorySymbolMapping[icon]),
            });
        });



        Object.keys(categorySymbolMapping).forEach((icon) => {
            uniqueValueInfos.push({
                value: icon,
                symbol: createPictureMarkerSymbol(categorySymbolMapping[icon]),
            });
        });
    return uniqueValueInfos;
};

const renderer = (modifiedGeoJSON: any) => new UniqueValueRenderer({
    field: "icon",
    defaultSymbol: createSimpleMarkerSymbol("gray"), // Default color if the icon type is not matched
    uniqueValueInfos: generateUniqueValueInfos(modifiedGeoJSON),
});

const Popup = {
    title: "",
    content: [
        {
            type: "fields",
            fieldInfos: [
                {
                    fieldName: "name",
                    label: "Name",
                },
                {
                    fieldName: "description",
                    label: "Description",
                },
                {
                    fieldName: "icon",
                    label: "icon",
                },
                {
                    fieldName: "category",
                    label: "category",
                },
                {
                    fieldName: "layer",
                    label: "layer",
                },
            ],
        },
    ],
};

const createLabelClass = (labelField: string) =>
    new LabelClass({
        labelExpressionInfo: {
            // Convert the numerical orderNo to a string
            expression: `$feature.${labelField}`,
        },
        symbol: {
            type: "text", // autocasts as new TextSymbol()
            color: "black",
            haloColor: "white",
            haloSize: "1px",
            font: {
                // autocasts as new Font()
                size: 12,
                family: "Arial",
                weight: "bold",
            },
        },
        labelPlacement: "above-right", // Adjust the label placement as needed
    });

const convertOrderNoToString = (geoJSON: GeoJSON): GeoJSON => {

    // Clone the geoJSON to avoid mutating the original object
    const geoJSONClone: GeoJSON = JSON.parse(JSON.stringify(geoJSON));

    // Iterate through each feature in the GeoJSON and convert the orderNo to a string
    geoJSONClone.features.forEach((feature) => {
        if (feature.properties && feature.properties.orderNo !== undefined) {
            feature.properties.sOrderNo = String(feature.properties.orderNo);
        } else {
            feature.properties.sOrderNo = "";
        }
    });

    return geoJSONClone;
};

interface EsriRouteStopsProps {
    geoJSON: GeoJSON;
    selectedVehicleId?: number
}

export const EsriRouteStops: React.FC<EsriRouteStopsProps> = ({ geoJSON, selectedVehicleId }) => {

    const { map } = useEsriMap();
   
    const modifiedGeoJSON = convertOrderNoToString(geoJSON);

    const blob = new Blob([JSON.stringify(modifiedGeoJSON)], {
        type: "application/json",
    });


    const url = URL.createObjectURL(blob);
   
    useEffect(() => {
        if (!map) return;

        const layer = new GeoJSONLayer({
            url,
            renderer: renderer(modifiedGeoJSON),
            copyright: "Rubicon",
            popupEnabled: true,
            popupTemplate: Popup,
            title: "Trucks",
            listMode: "hide",
            labelingInfo: [createLabelClass("sOrderNo")],
            
            featureReduction: {
                type: "cluster",
                maxScale: 40000,
                clusterRadius: "100px",
                clusterMinSize: "45px",
                clusterMaxSize: "50px",
                symbol: {
                    type: "simple-marker",
                    style: "circle",
                    color: "#69dcff",
                    outline: {
                        color: "rgba(0, 139, 174, 0.5)",
                        width: 6,
                    },
                },
                labelingInfo: [
                    {
                        deconflictionStrategy: "none",
                        labelExpressionInfo: {
                            expression: "Text($feature.cluster_count, '#,###')",
                        },
                        symbol: {
                            type: "text",
                            color: "#004a5d",
                            font: {
                                weight: "bold",
                                family: "Noto Sans",
                                size: "12px",
                            },
                        },
                        labelPlacement: "center-center",
                    },
                ],
            },
        });

        map.add(layer);
   
        return () => {
            map.remove(layer);
        };
    }, [map, geoJSON, selectedVehicleId,url, modifiedGeoJSON]);

    return null;
};
