import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CITY_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { loadCityAlertImages } from 'src/vendors/ducks';
import { loadLocationAndCityAlert } from 'src/fleet/ducks';
import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import DashboardCityAlertsGLPopup from 'src/dashboard/components/pages/dashboardPageSections/mapGL/dashboardCityAlerts/DashboardCityAlertsGLPopup';

interface Props {
  onClose: () => void;
  selectedFeature?: { id: number; type: string };
}

const CityAlertPopupResolver: FC<Props> = ({ onClose, selectedFeature }) => {
  const dispatch = useDispatch();

  const cityAlerts = useSelector(state => state.vendors.cityAlerts.cityAlerts);
  const { locationAndCityAlert: alert } = useSelector(state => state.fleet.locationAndCityAlerts);

  const cityAlert = useMemo(() => {
    if (!selectedFeature || selectedFeature.type !== 'cityAlert') {
      return undefined;
    }

    return selectedFeature && cityAlerts.find((alert: CityAlert) => alert.id === selectedFeature.id);
  }, [cityAlerts, selectedFeature]);

  const loadDependencies = useCallback(() => {
    const dependencies = [];

    if (cityAlert?.id) {
      dependencies.push(
        loadCityAlertImages(cityAlert.id)(dispatch),
        loadLocationAndCityAlert(cityAlert.id, cityAlert.alertTypeId || CITY_ALERT_TYPE_ID)(dispatch),
      );
    } else return Promise.reject();

    return Promise.all(dependencies);
  }, [cityAlert?.id, dispatch, cityAlert?.alertTypeId]);

  if (!cityAlert || !selectedFeature) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={cityAlert?.latitude}
      longitude={cityAlert?.longitude}
      onClose={onClose}
      tipSize={15}
      anchor="bottom-right"
    >
      <TypedResolver
        resolve={loadDependencies}
        successComponent={DashboardCityAlertsGLPopup}
        successProps={{ cityAlert: alert }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 320, width: 250 }}
        onError={onClose}
      />
    </MapGLPopupWrapper>
  );
};

export default CityAlertPopupResolver;
