import { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';

import { RESIDENTIAL_ID, SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { DocumentTitle } from '../../../../common/components';
import { PageLoading } from '../../../../common/components/styled';
import { loadFiltersSettings, loadWasteTypes } from '../../../../common/ducks';
import { DuckFunction } from '../../../../contracts/ducks';
import { Resolver } from '../../../../core/components';
import translate from '../../../../core/services/translate';
import { loadOperationalFacilities, loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { loadDisposalFacilities } from '../../../../fleet/ducks/facilities';
import { AppState } from '../../../../store';
import { loadFeatures } from '../../../../vendors/ducks';
import { currentUserIdSelector, currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import {
  loadGeoFenceFilterOptions,
  loadGroups,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  loadServiceZones,
  loadTravelPathStatusDetails,
} from '../../../ducks';
import { loadPickupTypes } from '../../../ducks/pickupTypes';
import { loadRouteGeoFence } from '../../../ducks/routeGeoFence';
import { cancelBatchedLocationsLoading } from '../../../ducks/routeTemplate';
import { loadSupervisors } from '../../../ducks/supervisors';
import RouteTemplateEditorPage from './RouteTemplateEditorPage';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';

interface RouteTemplateParams {
  routeTemplateId: string;
}

interface RouteTemplatePageResolverProps extends RouteComponentProps<RouteTemplateParams> {
  loadDisposalFacilities: DuckFunction<typeof loadDisposalFacilities>;
  loadFeatures: DuckFunction<typeof loadFeatures>;
  loadOperationalFacilities: DuckFunction<typeof loadOperationalFacilities>;
  loadPickupTypes: DuckFunction<typeof loadPickupTypes>;
  loadRouteGeoFence: DuckFunction<typeof loadRouteGeoFence>;
  loadRouteTemplateLocationsBatched: DuckFunction<typeof loadRouteTemplateLocationsBatched>;
  loadRouteTemplateOptimized: DuckFunction<typeof loadRouteTemplateOptimized>;
  loadServiceZones: DuckFunction<typeof loadServiceZones>;
  loadSupervisors: DuckFunction<typeof loadSupervisors>;
  loadGroups: DuckFunction<typeof loadGroups>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadWasteTypes: DuckFunction<typeof loadWasteTypes>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadGeoFenceFilterOptions: DuckFunction<typeof loadGeoFenceFilterOptions>;
  loadTravelPathStatusDetails: DuckFunction<typeof loadTravelPathStatusDetails>;
  push: (url: string) => void;
  vendorId: number;
  userId: string;
  isTravelPathFeatureEnabled: boolean;
  isNaviV3FeatureEnabled: boolean;
}

class RouteTemplateEditorPageResolver extends PureComponent<RouteTemplatePageResolverProps> {
  componentWillUnmount() {
    cancelBatchedLocationsLoading(this.batchedLocationsRequesterId);
  }

  batchedLocationsRequesterId = 'routeTemplateEditor';

  loadDependencies = async () => {
    const {
      loadOperationalFacilities,
      loadDisposalFacilities,
      loadRouteTemplateLocationsBatched,
      loadRouteTemplateOptimized,
      loadServiceZones,
      loadVehicleTypesForVendor,
      loadWasteTypes,
      loadPickupTypes,
      loadRouteGeoFence,
      loadFeatures,
      loadSupervisors,
      loadFiltersSettings,
      loadGeoFenceFilterOptions,
      loadTravelPathStatusDetails,
      loadGroups,
      push,
      match: {
        params: { routeTemplateId },
      },
      vendorId,
      userId,
      isTravelPathFeatureEnabled,
      isNaviV3FeatureEnabled,
    } = this.props;

    const isActive = false;
    const includeInactiveFacilities = true;

    const promises: any[] = [
      loadWasteTypes(),
      !!routeTemplateId
        ? loadOperationalFacilities(vendorId, isActive, includeInactiveFacilities)
        : loadOperationalFacilities(vendorId),
      !!routeTemplateId
        ? loadDisposalFacilities(vendorId, isActive, includeInactiveFacilities)
        : loadDisposalFacilities(vendorId),
      loadVehicleTypesForVendor(vendorId),
      loadServiceZones(vendorId, false),
      loadFeatures(vendorId),
      loadSupervisors(vendorId),
      loadFiltersSettings(vendorId, userId),
      loadGroups(vendorId),
      loadGeoFenceFilterOptions({ vendorId, routeTemplateId: Number(routeTemplateId) }),
    ];

    if (routeTemplateId) {
      const { id, vehicleTypeId } = await loadRouteTemplateOptimized(routeTemplateId);

      const { total } = await loadRouteTemplateLocationsBatched(this.batchedLocationsRequesterId, id);

      if (vehicleTypeId === RESIDENTIAL_ID && total >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled)) {
        promises.push(loadTravelPathStatusDetails(undefined, id));
      }

      if (vehicleTypeId === STREET_SWEEPER_ID) {
        // redirect to new page if is street sweeper or snow plow
        push(`/routes/route-templates/street-sweeper/${routeTemplateId}/edit`);
        return;
      } else if (vehicleTypeId === SNOW_PLOW_ID) {
        push(`/routes/route-templates/snow-plow/${routeTemplateId}/edit`);
        return;
      }

      promises.push(loadPickupTypes(vehicleTypeId));

      const isTemplate = true;
      promises.push(loadRouteGeoFence(routeTemplateId, isTemplate));
    }

    return Promise.all(promises);
  };

  render() {
    const {
      match: {
        params: { routeTemplateId },
      },
    } = this.props;

    return (
      <Fragment>
        <DocumentTitle>
          {routeTemplateId ? translate('routes.editPlannedRoute') : translate('routes.createPlannedRoute')}
        </DocumentTitle>
        <Resolver
          successComponent={RouteTemplateEditorPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/routes/route-templates"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login) as any,
  isTravelPathFeatureEnabled: isTravelPathNavigationFeatureEnabled(state),
  isNaviV3FeatureEnabled: isNavi3FeatureEnabled(state),
});

const mapDispatchToProps = {
  loadOperationalFacilities,
  loadDisposalFacilities,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  loadServiceZones,
  loadVehicleTypesForVendor,
  loadWasteTypes,
  loadPickupTypes,
  loadRouteGeoFence,
  loadFeatures,
  loadSupervisors,
  loadFiltersSettings,
  loadGeoFenceFilterOptions,
  loadTravelPathStatusDetails,
  loadGroups,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTemplateEditorPageResolver));
