import { getFormValues } from 'redux-form';

import { Popover, TableCell, TableRow } from 'src/core/components/styled';
import { PopoverWrapper, UnconnectedCheckbox } from 'src/core/components';
import { RateCodes, RateCodeServiceType } from 'src/finance/interfaces/RateManager';
import {
  RateConfigurationSearchFormValues,
  RATE_CONFIURATION_SEARCH_FORM_NAME,
} from '../../forms/RateConfigurationSearchForm';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props extends RateCodes {}

const RateConfigurationTableRow = ({ typeCode, description, rateCodeServiceType }: Props) => {
  const { billingUnitOfMeasureTypes } = useSelector(state => state.finance.rateManager);
  const formValues: RateConfigurationSearchFormValues = useSelector(state =>
    getFormValues(RATE_CONFIURATION_SEARCH_FORM_NAME)(state),
  );

  const currentRateCodeService = rateCodeServiceType.find(
    rateCode => Number(rateCode.serviceTypeId) === Number(formValues?.serviceTypeId),
  );

  const curentUoms = currentRateCodeService?.unitOfMeasureTypeIds?.map(uom =>
    billingUnitOfMeasureTypes?.find(allUom => allUom.id === uom),
  );

  const { rateAccountingCode } = currentRateCodeService || ({} as RateCodeServiceType);

  const getUomTableCells = () => {
    const uomTableCells: any[] = [];

    billingUnitOfMeasureTypes?.forEach(uom => {
      const uomTableCell = (
        <TableCell key={uom.id} width={`${40 / billingUnitOfMeasureTypes?.length}%`}>
          <UnconnectedCheckbox checked={!!curentUoms?.find(curentUom => curentUom?.id === uom.id)} disabled />
        </TableCell>
      );

      uomTableCells.push(uomTableCell);
    });

    return uomTableCells;
  };

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
      <TableCell width="10%">
        <UnconnectedCheckbox checked={!!currentRateCodeService} disabled />
      </TableCell>
      <TableCell width="15%">{typeCode}</TableCell>
      <TableCell width="20%">{description || '-'}</TableCell>
      <TableCell width="15%">
        <PopoverWrapper
          triggerButton={rateAccountingCode?.code || '-'}
          popoverContent={
            rateAccountingCode?.description && (
              <Popover>
                <b>{translate('common.description')}:</b> {rateAccountingCode?.description}
              </Popover>
            )
          }
          size="large"
        />
      </TableCell>
      {getUomTableCells()}
    </TableRow>
  );
};

export default RateConfigurationTableRow;
