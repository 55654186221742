import { camelCase } from 'lodash-es';
import { Fragment, PureComponent } from 'react';

import { ActionButtonTooltip, ThumbnailSet } from '../../../../core/components';
import { date, time } from '../../../../utils/services/formatter';
import { EmailRouteImagesModal, RouteLocationImagesModalResolver } from '../../modals';
import { Image } from 'src/routes/interfaces/RouteImages';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { TechnicalType } from '../../../../common/interfaces/TechnicalType';
import translate from '../../../../core/services/translate';

interface Props {
  customerLocationAddress: string;
  customerName: string;
  driverName: string;
  image?: Image;
  images?: Image[];
  pickupExceptionType: TechnicalType;
  pickupExceptionId?: number;
  routeId: number;
  routeLocationId?: number;
  routeName: string;
  timeStamp: string;
  vehicleTypeId: number;
  isServiceCompleted: boolean;
}

interface State {
  isEmailRouteImagesModalOpen: boolean;
  isRouteLocationImagesModalOpen: boolean;
}

class RouteIssuesReportedSummaryTableRow extends PureComponent<Props, State> {
  state = {
    isEmailRouteImagesModalOpen: false,
    isRouteLocationImagesModalOpen: false,
  };

  openRouteImagesModal = () => {
    this.setState({ isRouteLocationImagesModalOpen: true });
  };

  closeRouteImagesModal = () => {
    this.setState({ isRouteLocationImagesModalOpen: false });
  };

  openEmailRouteImagesModal = () => {
    this.setState({ isEmailRouteImagesModalOpen: true });
  };

  closeEmailRouteImagesModal = () => {
    this.setState({ isEmailRouteImagesModalOpen: false });
  };

  render() {
    const {
      customerName,
      customerLocationAddress,
      driverName,
      image,
      images: issueImages = [],
      pickupExceptionType,
      routeId,
      routeLocationId,
      routeName,
      timeStamp,
      vehicleTypeId,
      pickupExceptionId,
      isServiceCompleted,
    } = this.props;
    const { isRouteLocationImagesModalOpen, isEmailRouteImagesModalOpen } = this.state;

    let images: Image[] = [];
    const imageIds: number[] = [];
    const imageUrls: string[] = [];
    const imageBlobIds: string[] = [];

    if (!!issueImages.length) {
      images = issueImages;
      issueImages.forEach(image => {
        imageIds.push(image.imageId);
        imageUrls.push(image.url);
        imageBlobIds.push(image.blobId);
      });
    } else if (!!image) {
      images.push(image);
      imageUrls.push(image.url);
      imageIds.push(image.imageId);
      imageBlobIds.push(image.blobId);
    }

    const routeImage = {
      images,
      routeId,
      vehicleTypeId,
      routeLocationId,
      routeLocationDetailPickupExceptionId: pickupExceptionId,
    };

    return (
      <Fragment>
        <TableRow>
          <TableCell width="25%" vertical>
            <Text weight="medium" margin="no no xxSmall" singleLine>
              {customerName}
            </Text>
            <Text weight="light" margin="no no xxSmall" singleLine>
              {customerLocationAddress}
            </Text>
          </TableCell>
          <TableCell width="20%" vertical>
            <Text weight="medium" margin="no no xxSmall" singleLine>
              {routeName}
            </Text>
            <Text weight="light" margin="no no xxSmall" singleLine>
              {driverName}
            </Text>
          </TableCell>
          <TableCell width="10%" vertical>
            <Text weight="light" margin="no no xxSmall" singleLine>
              {date(timeStamp)}
            </Text>
            <Text weight="light" margin="no no xxSmall" singleLine>
              {time(timeStamp)}
            </Text>
          </TableCell>
          <TableCell width="17%">
            {translate(`routes.pickupIssueTypes.${camelCase(pickupExceptionType.technicalName)}`)}
          </TableCell>

          <TableCell width="8%" align="center">
            {!!imageUrls.length && (
              <ThumbnailSet
                id={`open-route-${routeId}-images-button`}
                images={imageUrls}
                onClick={this.openRouteImagesModal}
              />
            )}
          </TableCell>

          <TableCell width="12%">{isServiceCompleted ? translate('common.yes') : translate('common.no')}</TableCell>

          {routeImage && routeImage.images && (
            <TableCell width="8%" align="center">
              <TableActionButton
                id={`open-email-route-${routeId}-images-button`}
                onClick={this.openEmailRouteImagesModal}
              >
                <ActionButtonTooltip icon="email" tooltip="emailImages" />
              </TableActionButton>
            </TableCell>
          )}
        </TableRow>

        {!!isRouteLocationImagesModalOpen && (
          <RouteLocationImagesModalResolver
            imageIds={imageIds}
            routeId={routeId}
            routeLocationId={routeLocationId}
            closeModal={this.closeRouteImagesModal}
          />
        )}

        {!!isEmailRouteImagesModalOpen && (
          <EmailRouteImagesModal routeImage={routeImage} closeModal={this.closeEmailRouteImagesModal} />
        )}
      </Fragment>
    );
  }
}

export default RouteIssuesReportedSummaryTableRow;
