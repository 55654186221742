import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { PageLoading } from '../../../common/components/styled';
import { shouldLoadPermissions, loadPermissions } from '../../../account/ducks/permissions';
import { TypedResolver } from '../../../core/components';
import {
  loadContainerLocationTypes,
  loadContainerStatuses,
  loadContainerTypes,
  loadEquipmentConditions,
  loadEquipmentSizes,
  loadEquipmentTypes,
  loadServiceTypes,
} from 'src/common/ducks';
import { loadContainersStatistics } from 'src/fleet/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { DocumentTitle } from 'src/common/components';
import translate from 'src/core/services/translate';
import NewContainersPage from './NewContainersPage';

const NewContainersPageResolver: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);

  const loadDependencies = () => {
    const dependencies: Promise<any>[] = [
      loadEquipmentConditions()(dispatch),
      loadEquipmentSizes()(dispatch),
      loadEquipmentTypes()(dispatch),
      loadServiceTypes()(dispatch),
      loadContainerLocationTypes()(dispatch),
      loadContainerTypes()(dispatch),
      loadContainerStatuses()(dispatch),
    ];

    const { searchTerm, containerTypeId, locationId, equipmentTypeId, equipmentSizeId, equipmentConditionId } =
      getQueryParams(search);

    if (vendorId) {
      dependencies.push(
        loadContainersStatistics(
          vendorId,
          containerTypeId,
          equipmentTypeId,
          equipmentTypeId ? equipmentSizeId : undefined,
          equipmentConditionId,
          searchTerm,
          locationId,
        )(dispatch),
      );
    }

    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('containers.containers')}</DocumentTitle>
      <TypedResolver
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={NewContainersPage}
      />
    </>
  );
};

export default NewContainersPageResolver;
