import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { RATE_BUILDER_SOURCE } from 'src/routes/constants/rateBuilder';
interface Props<P extends object = {}> {
  map: mapboxgl.Map;
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
  selectedJobIds: number[];
}

const RateBuilderMapGLSource = React.memo<Props>(({ map, geoJSON, selectedJobIds }) => {
  return (
    <Source type="geojson" id={RATE_BUILDER_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id="allJobsPoints"
        source={RATE_BUILDER_SOURCE}
        type="circle"
        filter={['!', ['in', ['get', 'id'], ['literal', selectedJobIds]]]}
        paint={{
          'circle-radius': 8,
        }}
      />

      <Layer
        id="selectedJobs"
        source={RATE_BUILDER_SOURCE}
        type="circle"
        filter={['in', ['get', 'id'], ['literal', selectedJobIds]]}
        paint={{
          'circle-color': '#009688',
          'circle-radius': 12,
        }}
      />
    </Source>
  );
});

export default RateBuilderMapGLSource;
