import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { size } from 'lodash-es';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { loadAvailableVendors, loadAssignedVendors, vendorAccessUserIdSelector } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { UserVendors } from '../../interfaces/UserEditor';
import VendorAccessModal from './VendorAccessModal';

interface Props {
  assignedVendors: UserVendors[];
  availableVendors: UserVendors[];
  closeModal: () => void;
  currentVendorAccessUserId?: number;
  isAllAssignedVendorsRemoved: boolean;
  loadAssignedVendors: (vendorId: number, userId?: string) => Promise<any>;
  loadAvailableVendors: (vendorId: number, userId?: string) => Promise<any>;
  userId?: string;
  vendorId: number;
}

class VendorAccessModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadAvailableVendors,
      loadAssignedVendors,
      assignedVendors,
      currentVendorAccessUserId,
      userId,
      vendorId,
      isAllAssignedVendorsRemoved,
    } = this.props;

    const isSameVendorAccessUser = currentVendorAccessUserId === userId;

    if (!!size(assignedVendors) && isSameVendorAccessUser) {
      return Promise.resolve();
    } else if (!size(assignedVendors) && isAllAssignedVendorsRemoved) {
      return Promise.resolve();
    }

    const dependencies = [loadAvailableVendors(vendorId, userId), loadAssignedVendors(vendorId, userId)];
    return Promise.all(dependencies);
  };

  render() {
    const { availableVendors, assignedVendors, closeModal, userId } = this.props;
    return (
      <Resolver
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
        successComponent={VendorAccessModal}
        successProps={{ assignedVendors, availableVendors, userId, closeModal }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentVendorAccessUserId: vendorAccessUserIdSelector(state),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadAvailableVendors, loadAssignedVendors };

export default connect(mapStateToProps, mapDispatchToProps)(VendorAccessModalResolver);
