import { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { clearStreetNetworkServiceAreasSelectedFeature } from 'src/customers/ducks';

const CustomerLocationsPopup: FC = () => {
  const dispatch = useDispatch();
  const selectedFeature = useSelector(state => state.customers.streetNetworkServiceAreas.selectedFeature);

  if (!selectedFeature || selectedFeature.namespace !== 'customerLocations') return null;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedFeature.extraData.latitude}
      longitude={selectedFeature.extraData.longitude}
      onClose={() => dispatch(clearStreetNetworkServiceAreasSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle title={selectedFeature.extraData.name} />

        <MapGLPopupContent>
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.latitude')}
            value={selectedFeature.extraData.latitude}
          />
          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.longitude')}
            value={selectedFeature.extraData.longitude}
          />
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default CustomerLocationsPopup;
