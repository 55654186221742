import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const PLOWING = 'Plowing';
export const SALTING = 'Salting';
export const BRINING = 'Brining';
export const SWEEPING = 'Sweeping';
export const BREAKTIME = 'Breaktime';
export const DRIVING_TO_FROM_FACILITY = 'DrivingToFromFacility';
export const DRIVING_TO_DUMP_DEBRIS = 'DrivingToDumpDebris';
export const OFF_ROUTE = 'OffRoute';

export const PLOWING_ID = 1;
export const SALTING_ID = 2;
export const BRINING_ID = 3;
export const SWEEPING_ID = 4;

export const SERVICE_CONFIGURATION_TYPES = mapKeys(
  [
    { technicalName: PLOWING, name: translate('vendors.serviceConfiguration.plowing') },
    { technicalName: SALTING, name: translate('vendors.serviceConfiguration.salting') },
    { technicalName: BRINING, name: translate('vendors.serviceConfiguration.brining') },
    { technicalName: SWEEPING, name: translate('vendors.serviceConfiguration.sweeping') },
  ],
  'technicalName',
);
export const NON_SERVICE_CONFIGURATION_TYPES = mapKeys(
  [
    { technicalName: BREAKTIME, name: translate('vendors.serviceConfiguration.breaktime') },
    { technicalName: DRIVING_TO_FROM_FACILITY, name: translate('vendors.serviceConfiguration.drivingToFromFacility') },
    { technicalName: DRIVING_TO_DUMP_DEBRIS, name: translate('vendors.serviceConfiguration.drivingToDumpDebris') },
    { technicalName: OFF_ROUTE, name: translate('vendors.serviceConfiguration.offRoute') },
  ],
  'technicalName',
);
