import React from 'react';

import { SmartFilters, SmartFiltersExtended, SmartFilter, SmartFilterExtended } from '../interfaces/SmartFilters';

export const useSmartFilters = <T extends object, I = string | number>(
  smartFilters: SmartFilters<T, I>,
  rawEntries: T[],
) => {
  const initialActiveFilter = smartFilters.find(filter => filter.isActive);
  const initialActiveFilterId = initialActiveFilter ? initialActiveFilter.id : null;

  const [activeFilterId, setActiveFilterId] = React.useState<SmartFilter<T, I>['id'] | null>(initialActiveFilterId);

  React.useEffect(() => {
    smartFilters.forEach(smartFilter => {
      if (smartFilter.isActive) {
        setActiveFilterId(smartFilter.id);
      }
    });
  }, [smartFilters]);

  return React.useMemo((): SmartFiltersExtended<T, I> => {
    let activeFilterEntries: T[] = rawEntries;

    const filters: SmartFilterExtended<T, I>[] = smartFilters.map(({ filter, cautionFilter, ...smartFilter }) => {
      const entries = !!filter ? rawEntries.filter(filter) : [];
      const cautionEntries = !!cautionFilter ? rawEntries.filter(cautionFilter) : [];
      const isActive = activeFilterId === smartFilter.id;

      if (isActive) {
        activeFilterEntries = entries;
      }

      return {
        ...smartFilter,
        entries,
        entriesCount: entries.length,
        cautionEntries,
        cautionEntriesCount: cautionEntries.length,
        isActive,
        setActive() {
          if (activeFilterId === smartFilter.id) {
            setActiveFilterId(null);
          } else {
            setActiveFilterId(smartFilter.id);
          }
        },
      };
    });

    return {
      filters,
      activeFilter: filters.find(filter => filter.isActive),
      activeFilterEntries,
    };
  }, [smartFilters, rawEntries, activeFilterId]);
};
