import humps, { camelize } from 'humps';
import { get, map, upperFirst } from 'lodash-es';

import { BULK, PORTABLE_RESTROOM_TRAILER, PORTABLE_TOILET } from '../../common/constants';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { date } from '../../utils/services/formatter';

export const getEquipmentSize = (equipmentSize: string) => parseFloat(equipmentSize);

export const getEquipmentSizeTranslationKey = (
  equipmentSize: string,
  serviceTypeName: string,
  equipmentName: string,
) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizes.x${upperFirst(
      camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
    )}`;
  }

  return equipmentSizesTranslationName;
};

const transformLoadServices = (services: any[]) => {
  const transformed = map(services, ({ containers, ...service }) => {
    const fromDate = get(service.futureAccountStatus, 'fromDate');
    const toDate = get(service.futureAccountStatus, 'toDate');

    return {
      ...service,
      serviceTypeIcon: humps.camelize(service.equipmentTypeName.split('.').join('')),
      serviceName: translate(createTranslationKey(service.serviceTypeName, 'common.serviceTypes')),
      wasteMaterialType: translate(createTranslationKey(service.wasteMaterialType.technicalName, 'common.wasteTypes')),
      equipmentSizeName: translate(
        getEquipmentSizeTranslationKey(
          service.equipmentSizeTechnicalName,
          service.serviceTypeName,
          service.equipmentTypeName,
        ),
        {
          size: getEquipmentSize(service.equipmentSizeName),
        },
      ),
      pickupFrequencyType: translate(
        createTranslationKey(service.pickupFrequencyTypeName, 'common.pickupFrequencyTypes'),
      ),
      containers: map(containers, ({ binLatitude, binLongitude, displayLatitude, displayLongitude, id }) => ({
        lat: binLatitude,
        lng: binLongitude,
        displayLatitude,
        displayLongitude,
        id,
      })),
      futureAccountStatus: {
        ...service.futureAccountStatus,
        accountStatusId: get(service.futureAccountStatus, 'accountStatusId') || undefined,
        fromDate: fromDate ? date(fromDate, 'MM/DD/YYYY') : undefined,
        toDate: toDate ? date(toDate, 'MM/DD/YYYY') : undefined,
      },
      equipmentTypeName: translate(createTranslationKey(service.equipmentTypeTechnicalName, 'common.equipmentTypes')),
      forwardPasses: containers[0].streetSegmentActivitySettings
        ? containers[0].streetSegmentActivitySettings[0].forwardPasses
        : 0,
      reversePasses: containers[0].streetSegmentActivitySettings
        ? containers[0].streetSegmentActivitySettings[0].reversePasses
        : 0,
    };
  });

  const processedIDs: number[] = [];
  const orderedArray: any = [];

  function processService(service: any, generation: number) {
    const { id, scheduledChildServiceContractId: childId } = service;

    if (processedIDs.includes(id)) {
      return;
    }

    orderedArray.push({ ...service, isChild: !!generation, isParent: !!childId, generation });
    processedIDs.push(id);

    if (childId) {
      const childService = transformed.find(s => s.id === childId);

      if (childService) {
        processService(childService, generation + 1);
      }
    }
  }

  transformed.forEach(service => {
    processService(service, 0);
  });

  return orderedArray;
};

export default transformLoadServices;
