import React from 'react';

import { WrappedFieldArrayProps } from 'redux-form';

import { ContainerColorConfiguration } from 'src/vendors/interfaces/ContainerColorConfiguration';
import { Dropdown, Table, TypedField } from '../../../core/components';
import { Option } from 'src/common/interfaces/Option';
import { TableCell, TableRow } from '../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

type Props = WrappedFieldArrayProps & {
  binColors: TechnicalType[];
  containerColorConfiguration: ContainerColorConfiguration[];
  tableCellsWidths: string[];
};

const ContainerColorConfigurationFormFieldArray: React.FC<Props> = ({
  binColors,
  containerColorConfiguration,
  fields,
  tableCellsWidths,
}) => {
  const containerColorConfigurationTableCells = [
    { name: 'routeMaterialType', label: translate('common.routeMaterialType'), width: tableCellsWidths[0] },
    { name: 'containerColor', label: translate('common.containerColor'), width: tableCellsWidths[1], align: 'right' },
  ];

  const binColorsOptions = binColors.map((binColor: TechnicalType) => ({
    label: binColor.name,
    value: binColor.id,
  })) as Option[];

  return (
    <Table cells={containerColorConfigurationTableCells}>
      {fields.map((configuration: string, index: number) => {
        return (
          <TableRow height={70} key={index}>
            <TableCell width={`${tableCellsWidths[0]}`}>
              {translate(
                createTranslationKey(
                  containerColorConfiguration[index].wasteMaterialType.technicalName,
                  'common.wasteTypes',
                ),
              )}
            </TableCell>

            <TableCell width={`${tableCellsWidths[1]}`}>
              <TypedField
                name={`${configuration}.binColor.id`}
                component={Dropdown}
                props={{
                  isClearable: true,
                  margin: 'no',
                  options: binColorsOptions,
                  placeholder: translate('common.containerColor'),
                  width: '100%',
                }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default ContainerColorConfigurationFormFieldArray;
