import React, { ChangeEvent, PureComponent } from 'react';

import { isArray, map } from 'lodash-es';
import moment from 'moment';
import { DayModifiers } from 'react-day-picker';
import IDatePickerInput from 'react-day-picker/DayPickerInput';

import { CALENDAR_WEEKDAYS, MONTHS_SHORT } from 'src/common/constants';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { MAX_SEARCHABLE_MONTHS, TODAY, TOMORROW } from 'src/core/constants';
import { CALENDAR_INPUT_LENGTH } from 'src/core/constants/calendar';
import { onCalendarInputKeyDown } from 'src/core/services/calendar';
import translate from 'src/core/services/translate';
import {
  DatePicker as DatePickerContainer,
  DatePickerInput,
  FormError,
  FormGroup,
  FormGroupClear,
  FormLabel,
} from './styled';
import { HorizontalAlign, VerticalAlign } from './styled/DatePicker';

interface Props {
  disabled?: boolean;
  disabledDays?: any[];
  disableFormSubmitOnDateChange?: boolean;
  error?: string;
  hasError?: boolean;
  horizontalAlign?: HorizontalAlign;
  id?: string;
  value?: StringOrDate;
  isClearable?: boolean;
  isTodayWithNoStyle?: boolean;
  label?: string;
  margin?: string;
  mask?: string;
  name?: string;
  onDateChange?: (date: StringOrDate) => void;
  placeholder?: string;
  raisedLabel?: boolean;
  tabletAlignHalfLeft?: boolean;
  tabletAlignLeft?: boolean;
  verticalAlign?: VerticalAlign;
  width?: string;
}

interface State {
  inputValue: StringOrDate;
  currentMonth?: StringOrDate;
  isLabelRaised?: boolean;
  isDateValid: boolean;
  hasChangedValue: boolean;
}

const format = 'MM/DD/YYYY';

class DatePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { value = '', placeholder } = props;
    this.state = {
      isDateValid: true,
      inputValue: moment(value).format(format),
      currentMonth: moment(value, format).toDate(),
      isLabelRaised: !!value || !!placeholder,
      hasChangedValue: false,
    };
  }

  onDayChange = (date: StringOrDate, _: DayModifiers, input?: IDatePickerInput) => {
    const { onDateChange, placeholder } = this.props;
    input?.getInput().value.length === 0 && this.setState({ isLabelRaised: !!this.props.placeholder });

    if (
      date &&
      moment(date).format(format).length === CALENDAR_INPUT_LENGTH &&
      input?.getInput().value.length === CALENDAR_INPUT_LENGTH
    ) {
      const newValue = moment(date).format(format);
      !!onDateChange && onDateChange(newValue);
      this.setState({ inputValue: newValue, isDateValid: true, hasChangedValue: true });
    } else if (input?.getInput().value.length === 0) {
      !!onDateChange && onDateChange('');
      this.setState({
        inputValue: '',
        isLabelRaised: !!placeholder,
        isDateValid: false,
      });
    }
  };

  onBlur = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as any).value;
    const { disableFormSubmitOnDateChange, disabledDays, onDateChange, value: defaultValue = '' } = this.props;
    let isDateValid = moment(value || this.state.inputValue, 'MM/DD/YYYY', true).isValid();
    if (value.length === 0 && this.state.inputValue.toString().length === 0) isDateValid = true;
    this.setState({ isDateValid });

    const isAfter =
      disabledDays &&
      disabledDays[0].after &&
      moment(moment(disabledDays[0].after).format(format)).diff(moment(value), 'days') < 0;
    const isBefore =
      (disabledDays && disabledDays[0].before && moment(value).diff(moment(disabledDays[0].before), 'days') < 0) ||
      moment(value).diff(moment().subtract(MAX_SEARCHABLE_MONTHS, 'months').toDate()) < 0;

    if (isDateValid && (isAfter || isBefore) && this.state.hasChangedValue) {
      const firstEnabledDay = disabledDays && moment(disabledDays[0].before).format(format);

      if (firstEnabledDay) {
        this.setState({ inputValue: firstEnabledDay, hasChangedValue: true });
        !!onDateChange && onDateChange(firstEnabledDay);
      }
    }

    if (!isDateValid && !disableFormSubmitOnDateChange) {
      this.setState({ inputValue: value, hasChangedValue: true });
      !!onDateChange && onDateChange(value);
    }

    if (value && !moment(defaultValue).isValid() && !moment(this.state.inputValue).isValid()) {
      this.setState({
        inputValue: moment().format(format),
        isDateValid: true,
        hasChangedValue: true,
      });
      !!onDateChange && onDateChange(moment().format(format));
    }
  };

  clearInput = () => {
    const currentMonth = new Date(moment().year(), moment().month());
    this.setState({
      inputValue: '',
      currentMonth,
      isLabelRaised: !!this.props.placeholder,
      isDateValid: true,
      hasChangedValue: true,
    });
    !!this.props.onDateChange && this.props.onDateChange('');
  };

  parseDate = (str: string, format: string) => {
    const date = moment(str, format, true);
    return date.isValid() ? date.toDate() : undefined;
  };

  formatDate = (date: StringOrDate, format: string) => moment(date).format(format);

  onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const str = ev.target.value;
    const { disabledDays, onDateChange, placeholder, value } = this.props;
    str.length > 0
      ? this.setState({ isLabelRaised: true })
      : str.length === 0 && this.setState({ isLabelRaised: !!placeholder });
    if (str.length !== CALENDAR_INPUT_LENGTH) return;

    const currentDate = TODAY;
    const nextDay = TOMORROW;

    const isAfter =
      disabledDays && disabledDays[0].after
        ? moment(disabledDays[0].after).diff(moment(str), 'days') < 0
        : Math.abs(moment(currentDate).diff(moment(str), 'months')) > MAX_SEARCHABLE_MONTHS;
    const isBefore =
      disabledDays && disabledDays[0].before
        ? moment(str).diff(moment(disabledDays[0].before), 'days') < 0
        : moment(str).diff(moment().subtract(MAX_SEARCHABLE_MONTHS, 'months').toDate()) < 0;

    const resetToDay =
      disabledDays &&
      disabledDays[0].before !== currentDate &&
      moment(disabledDays[0].after).diff(moment(str), 'days') > -2
        ? nextDay
        : currentDate;
    const newValue = (isAfter || isBefore) && typeof value === 'string' ? resetToDay : str;
    const date = moment(newValue);
    !!onDateChange && onDateChange(date.format(format));
    this.setState({ inputValue: date.format(format), hasChangedValue: true });

    if (!date.isValid()) {
      this.setState({ inputValue: '', isLabelRaised: !!placeholder, hasChangedValue: true });
      !!onDateChange && onDateChange('');
    }
  };

  render() {
    const {
      disabled,
      disabledDays = [],
      horizontalAlign,
      id,
      value: defaultValue,
      isClearable,
      isTodayWithNoStyle,
      label,
      margin,
      mask,
      hasError,
      error,
      name,
      placeholder,
      tabletAlignHalfLeft,
      tabletAlignLeft,
      verticalAlign,
      width,
    } = this.props;

    const { inputValue, isLabelRaised, isDateValid } = this.state;

    const months = map(MONTHS_SHORT, month => translate(month.translationKey));
    const weekdaysShort = map(CALENDAR_WEEKDAYS, day => translate(day.translationKey));
    const currentMonth = new Date(moment().year(), moment().month());
    const specificDisabledDays =
      disabledDays && disabledDays.length > 1 && disabledDays.slice(1).map(el => moment(el).format(format));

    const hasTodayButton = () => {
      if (!disabledDays) return true;
      let ok = true;
      if (disabledDays.length) {
        if (disabledDays[0].before)
          if (moment(disabledDays[0]?.before).endOf('day').isAfter(moment().endOf('day'))) ok = false;
        if (disabledDays[0].after)
          if (moment(disabledDays[0]?.after).endOf('day').isBefore(moment().endOf('day'))) ok = false;
      }
      if (isArray(specificDisabledDays)) {
        specificDisabledDays.forEach(el => {
          if (moment(el).endOf('day').isSame(moment().endOf('day'))) ok = false;
        });
      }
      return ok;
    };

    const value = defaultValue && !inputValue ? defaultValue : defaultValue && inputValue ? inputValue : '';

    return (
      <FormGroup hasValue={!!value} margin={margin} raisedLabel={isLabelRaised} width={width} disabled={disabled}>
        {!!label && <FormLabel>{label}</FormLabel>}

        <DatePickerContainer
          focus={true}
          horizontalAlign={horizontalAlign}
          isTodayWithNoStyle={isTodayWithNoStyle}
          mask={!!value && !!mask ? this.formatDate(value, mask) : undefined}
          position="relative"
          tabletAlignHalfLeft={tabletAlignHalfLeft}
          tabletAlignLeft={tabletAlignLeft}
          verticalAlign={verticalAlign}
          hasTodayButton={hasTodayButton()}
        >
          <DatePickerInput
            format="MM/DD/YYYY"
            onDayChange={this.onDayChange}
            parseDate={this.parseDate}
            formatDate={this.formatDate}
            value={moment(value).isValid() ? value : isClearable ? '' : ''}
            placeholder={placeholder || ''}
            inputProps={{
              disabled,
              name,
              id,
              readOnly: false,
              autoComplete: 'off',
              onChange: this.onInputChange,
              onKeyDown: onCalendarInputKeyDown,
              onBlur: this.onBlur,
            }}
            dayPickerProps={{
              month: moment(this.state.currentMonth).isValid()
                ? (this.state.currentMonth as Date | undefined)
                : (currentMonth as Date | undefined),
              disabledDays: [
                ...(disabledDays || []),
                disabledDays && disabledDays.length > 0 && disabledDays[0].before
                  ? { before: disabledDays[0].before }
                  : {
                      before: moment().subtract(MAX_SEARCHABLE_MONTHS, 'months').toDate(),
                    },
              ],
              months,
              weekdaysShort,
              todayButton: hasTodayButton() && translate('common.today'),
            }}
          />

          {isClearable && !!value && <FormGroupClear disabled={disabled} onClick={this.clearInput} />}
        </DatePickerContainer>

        {((hasError && error) || !isDateValid) && (
          <FormError>{error || translate('common.validationMessages.invalidDate')}</FormError>
        )}
      </FormGroup>
    );
  }
}

export default DatePicker;
