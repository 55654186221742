import { Redirect, Switch } from 'react-router';

import { AuthRoute } from '../../account/components';
import { InferenceAuditPageResolver } from './pages';

const InferenceAudit = () => (
  <Switch>
    <AuthRoute exact path="/inference-audit" component={InferenceAuditPageResolver} />
    <Redirect to="/inference-audit" />
  </Switch>
);

export default InferenceAudit;
