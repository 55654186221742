import { map } from 'lodash-es';

import { ALERT_STATUS_ACTIVE, CITY_ALERT_TYPES } from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlertSave } from '../interfaces/CityAlert';
import { http } from 'src/core/services/http';
import { transformLoadCityAlerts } from './transformLoadCityAlerts';

export const loadCityAlerts = (cityAlertTypeIds?: number[], routeId?: number, shouldLoadImages?: boolean) =>
  http
    .post(`/alerts/map`, {
      cityAlertTypeIds,
      routeId,
      shouldLoadImages,
      statusTypeIds: [ALERT_STATUS_ACTIVE],
    })
    .then(response => transformLoadCityAlerts(response.data));

export const saveCityAlert = (cityAlert: CityAlertSave) =>
  http.post(`/alerts`, cityAlert).then(response => response.data);

export const loadCityAlertImages = (cityAlertId: number) =>
  http
    .get(`/city/alerts/${cityAlertId}/images`, {
      params: {
        cityAlertId,
      },
    })
    .then(response => response.data);

export const loadCityAlertsForRoute = (routeId?: number) =>
  http
    .get(`city/alerts/list`, {
      params: {
        cityAlertTypeIds: map(CITY_ALERT_TYPES, 'id').join(','),
        routeId,
        statusTypeIds: [ALERT_STATUS_ACTIVE].toString(),
        shouldLoadImages: true,
      },
    })
    .then(response => transformLoadCityAlerts(response.data));
