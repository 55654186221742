import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { Certification } from '../interfaces/Certification';
import { deleteDocument as doDeleteDocument } from '../services/files';
import { loadCertifications as doLoadCertifications } from '../services/overview';

// Actions
const START_LOAD = 'haulerProfile/certifications/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/certifications/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/certifications/FAIL_LOAD';
const START_DELETE = 'haulerProfile/certifications/START_DELETE';
const COMPLETE_DELETE = 'haulerProfile/certifications/COMPLETE_DELETE';

interface State {
  isLoading: boolean;
  isDeleting: boolean;
  certifications: Certification[];
}

// Initial state
const initialState: State = {
  isLoading: false,
  isDeleting: false,
  certifications: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction): State => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          certifications: action.certifications,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (certifications: any) => ({
  type: COMPLETE_LOAD,
  certifications,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDeleteDocument = () => ({
  type: START_DELETE,
});

const completeDeleteDocument = (documentId: number) => ({
  type: COMPLETE_DELETE,
  documentId,
});

export const loadCertifications = (haulerId: string, divisionId?: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadCertificationsPromise = doLoadCertifications(haulerId, divisionId);
  loadCertificationsPromise
    .then(certifications => dispatch(completeLoad(certifications)))
    .catch(() => dispatch(failLoad()));
  return loadCertificationsPromise;
};

export const deleteUploadedDocument =
  (haulerId: string, documentId: number, userId: string, divisionId?: string) => async (dispatch: Dispatch) => {
    dispatch(startDeleteDocument());
    await doDeleteDocument(haulerId, documentId, userId, divisionId);
    dispatch(completeDeleteDocument(documentId));
  };
