import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';
import { findIndex } from 'lodash-es';

import { loadRollOffJobs as doLoadRollOffJobs } from '../services/rollOffJobs';

// Actions
const START_LOAD = 'routes/rollOffJobs/START_LOAD';
export const COMPLETE_LOAD = 'routes/rollOffJobs/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/rollOffJobs/FAIL_LOAD';
const START_DELETE = 'routes/rollOffJobs/START_DELETE';
export const COMPLETE_DELETE = 'routes/rollOffJobs/COMPLETE_DELETE';
const FAIL_DELETE = 'routes/rollOffJobs/FAIL_DELETE';
export const START_SAVE = 'routes/rollOffJobs/START_SAVE';
export const COMPLETE_SAVE = 'routes/rollOffJobs/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/rollOffJobs/FAIL_SAVE';
const RESET = 'routes/RollOffJobs/RESET';
export const ADD_NOTE = 'routes/RollOffJobs/ADD_NOTE';
export const ADD_JOB = 'routes/RollOffJobs/ADD_JOB';

interface State {
  isLoading: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  rollOffJobs?: any[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isDeleting: false,
  isSaving: false,
  rollOffJobs: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rollOffJobs: action.rollOffJobs,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rollOffJobs: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const rollOffJobIndex = findIndex(state.rollOffJobs, { id: action.rollOffJobId });
      return update(state, {
        rollOffJobs: { $splice: [[rollOffJobIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rollOffJobs: any[]) => ({
  type: COMPLETE_LOAD,
  rollOffJobs,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const addNoteButton = () => ({
  type: ADD_NOTE,
});

const addJobButton = () => ({
  type: ADD_JOB,
});

export const loadRollOffJobs =
  (
    vendorId: number,
    startDate: Date | string,
    endDate: Date | string,
    routeName: string,
    pickupStatusTypeIds: number[],
    customerLocationName: string,
    pickupTypeId: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    return doLoadRollOffJobs(
      vendorId,
      startDate,
      endDate,
      routeName,
      pickupStatusTypeIds,
      customerLocationName,
      pickupTypeId,
    )
      .then(rollOffJobs => dispatch(completeLoad(rollOffJobs)))
      .catch(() => dispatch(failLoad()));
  };

export const addNote = () => (dispatch: Dispatch) => {
  dispatch(addNoteButton());
};

export const addJob = () => (dispatch: Dispatch) => {
  dispatch(addJobButton());
};

export const resetRollOffJobs = () => ({
  type: RESET,
});
