import update from 'immutability-helper';
import { omit } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { CreateEditPaymentData, PaymentResponseData } from 'src/finance/interfaces/ApplyChecks';
import {
  LoadPaymentsParams,
  exportPayments as doExportPayments,
  loadPayments as doLoadPayments,
  savePayment as doSavePayment,
  updatePayment as doUpdatePayment,
  deletePayment as doDeletePayment,
} from 'src/finance/services/payments';

// Actions
const START_LOAD = 'finance/applyChecks/payments/START_LOAD';
const COMPLETE_LOAD = 'finance/applyChecks/payments/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/payments/FAIL_LOAD';
const START_EXPORT = 'finance/applyChecks/payments/START_EXPORT';
const COMPLETE_EXPORT = 'finance/applyChecks/payments/COMPLETE_EXPORT';
const FAIL_EXPORT = 'finance/applyChecks/payments/FAIL_EXPORT';
const START_SAVE = 'finance/applyChecks/payments/START_SAVE';
const COMPLETE_SAVE = 'finance/applyChecks/payments/COMPLETE_SAVE';
const FAIL_SAVE = 'finance/applyChecks/payments/FAIL_SAVE';
const START_UPDATE = 'finance/applyChecks/payments/START_UPDATE';
const COMPLETE_UPDATE = 'finance/applyChecks/payments/COMPLETE_UPDATE';
const FAIL_UPDATE = 'finance/applyChecks/payments/FAIL_UPDATE';
const START_DELETE_PAYMENT = 'finance/applyChecks/payments/START_DELETE_PAYMENT';
const COMPLETE_DELETE_PAYMENT = 'finance/applyChecks/payments/COMPLETE_DELETE_PAYMENT';
const FAIL_DELETE_PAYMENT = 'finance/applyChecks/payments/FAIL_DELETE_PAYMENT';
const RESET = 'finance/applyChecks/payments/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  isSaving: boolean;
  isExporting: boolean;
  isUpdating: boolean;
  payments?: PaymentResponseData;
} = {
  isLoading: false,
  isSaving: false,
  isExporting: false,
  isUpdating: false,
  payments: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          payments: action.payments,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          payments: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    case START_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: true,
        },
      });

    case COMPLETE_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case FAIL_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case START_DELETE_PAYMENT:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_DELETE_PAYMENT:
    case FAIL_DELETE_PAYMENT:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (payments: any) => ({
  type: COMPLETE_LOAD,
  payments,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startUpdate = () => ({
  type: START_UPDATE,
});

const completeUpdate = () => ({
  type: COMPLETE_UPDATE,
});

const failUpdate = () => ({
  type: FAIL_UPDATE,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const startDeletePayment = () => ({
  type: START_DELETE_PAYMENT,
});

const completeDeletePayment = () => ({
  type: COMPLETE_DELETE_PAYMENT,
});

const failDeletePayment = () => ({
  type: FAIL_DELETE_PAYMENT,
});

export const loadPayments = (params: LoadPaymentsParams) => (dispatch: Dispatch) => {
  !params.noLoadingIndicator && dispatch(startLoad());
  const loadPaymentsPromise = doLoadPayments(params);
  loadPaymentsPromise.then((payments: any) => dispatch(completeLoad(payments))).catch(() => dispatch(failLoad()));
  return loadPaymentsPromise;
};

export const exportPayments = (params: LoadPaymentsParams) => (dispatch: Dispatch) => {
  dispatch(startExport());
  const exportPaymentsPromise = doExportPayments(params);
  exportPaymentsPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
  return exportPaymentsPromise;
};

export const savePayment = (data: CreateEditPaymentData) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const formData = new FormData();

  formData.append(
    'data',
    JSON.stringify({
      ...omit(data, 'files'),
      applyPayment: false,
      createdManually: 1,
    }),
  );

  if (data.files) {
    Array.from(data.files).forEach(f => formData.append('file', f));
  }

  const savePaymentPromise = doSavePayment(formData);
  savePaymentPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return savePaymentPromise;
};

export const applyCheck = (data: CreateEditPaymentData) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const formData = new FormData();

  formData.append(
    'data',
    JSON.stringify({
      ...omit(data, 'files'),
      applyPayment: true,
    }),
  );

  if (data.files) {
    Array.from(data.files).forEach(f => formData.append('file', f));
  }

  const applyCheckPromise = doSavePayment(formData);
  applyCheckPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return applyCheckPromise;
};

export const updatePayment = (paymentNumber: number, data: CreateEditPaymentData) => (dispatch: Dispatch) => {
  dispatch(startUpdate());
  const updatePaymentPromise = doUpdatePayment(paymentNumber, data);
  updatePaymentPromise.then(() => dispatch(completeUpdate())).catch(() => dispatch(failUpdate()));
  return updatePaymentPromise;
};

export const resetPayments = () => ({
  type: RESET,
});

export const deletePayment = (paymentId: number) => (dispatch: Dispatch) => {
  dispatch(startDeletePayment());
  return doDeletePayment(paymentId)
    .then(data => {
      dispatch(completeDeletePayment());
      return data;
    })
    .catch(() => failDeletePayment());
};
