import update from 'immutability-helper';
import { get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  loadYDeviceStatuses as doLoadYDeviceStatuses,
  exportDeviceStatuses as doExportDeviceStatuses,
  exportXDeviceStatuses as doExportXDeviceStatuses,
  loadXDeviceStatuses as doLoadXDeviceStatuses,
  deleteXDevice as doDeleteXDevice,
  updateXDeviceData as doUpdateXDevice,
} from '../services/deviceStatuses';
import { XDevicesStatuses } from '../interfaces/XDevicesStatus';

// Actions
const START_LOAD = 'settings/deviceStatuses/START_LOAD';
const COMPLETE_LOAD_Y = 'settings/deviceStatuses/COMPLETE_LOAD_Y';
const COMPLETE_LOAD_X = 'settings/deviceStatuses/COMPLETE_LOAD_X';
const COMPLETE_DELETE_X_DEVICE = 'settings/deviceStatuses/COMPLETE_DELETE_X_DEVICE';
const COMPLETE_UPDATE_X_DEVICE = 'settings/deviceStatuses/COMPLETE_UPDATE_X_DEVICE';
const FAIL_LOAD = 'settings/deviceStatuses/FAIL_LOAD';
const START_EXPORT = 'settings/deviceStatuses/START_EXPORT';
const COMPLETE_EXPORT = 'settings/deviceStatuses/COMPLETE_EXPORT';
const FAIL_EXPORT = 'settings/deviceStatuses/FAIL_EXPORT';
const RESET = 'settings/deviceStatuses/RESET';

interface State {
  isLoading: boolean;
  isExporting: boolean;
  yDeviceStatuses?: any[];
  xDeviceStatuses?: XDevicesStatuses;
}

// Initial state
const initialState: State = {
  isLoading: false,
  isExporting: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_Y:
      return update(state, {
        $merge: {
          isLoading: false,
          yDeviceStatuses: action.yDeviceStatuses,
        },
      });

    case COMPLETE_LOAD_X:
      return update(state, {
        $merge: {
          isLoading: false,
          xDeviceStatuses: action.xDeviceStatuses,
        },
      });

    case COMPLETE_DELETE_X_DEVICE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case COMPLETE_UPDATE_X_DEVICE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          yDeviceStatuses: undefined,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          isExporting: false,
          yDeviceStatuses: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoadY = (yDeviceStatuses: any) => ({
  type: COMPLETE_LOAD_Y,
  yDeviceStatuses,
});

const completeLoadX = (xDeviceStatuses: any) => ({
  type: COMPLETE_LOAD_X,
  xDeviceStatuses,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const completeDeleteXDevice = () => ({
  type: COMPLETE_DELETE_X_DEVICE,
});

const completeUpdateXDevice = () => ({
  type: COMPLETE_UPDATE_X_DEVICE,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

export const loadYDeviceStatuses =
  (vendorId: number, vehicleTypeId: number, sortedBy: string, sortOrder: string, page: number, limit: number) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());

    const loadDeviceStatusesPromise = doLoadYDeviceStatuses(vendorId, vehicleTypeId, sortedBy, sortOrder, page, limit);

    loadDeviceStatusesPromise
      .then(deviceStatus => dispatch(completeLoadY(deviceStatus)))
      .catch(() => dispatch(failLoad()));

    return loadDeviceStatusesPromise;
  };

export const loadXDeviceStatuses = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadDeviceStatusesPromise = doLoadXDeviceStatuses(vendorId);

  loadDeviceStatusesPromise
    .then(deviceStatus => dispatch(completeLoadX(deviceStatus)))
    .catch(() => dispatch(failLoad()));

  return loadDeviceStatusesPromise;
};

export const deleteXDevice = (deviceId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadDeviceStatusesPromise = doDeleteXDevice(deviceId);

  loadDeviceStatusesPromise.then(() => dispatch(completeUpdateXDevice())).catch(() => dispatch(failLoad()));

  return loadDeviceStatusesPromise;
};

export const updateXDeviceData =
  (deviceOwnerTypeId: number, phoneName: string, serialNumber: string, uniqueDeviceIdentifier: any) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadDeviceStatusesPromise = doUpdateXDevice(
      deviceOwnerTypeId,
      phoneName,
      serialNumber,
      uniqueDeviceIdentifier,
    );

    loadDeviceStatusesPromise.then(() => dispatch(completeDeleteXDevice())).catch(() => dispatch(failLoad()));

    return loadDeviceStatusesPromise;
  };

export const exportDeviceStatuses =
  (vendorId: number, vehicleTypeId: number, sortedBy: string, sortOrder: string) => (dispatch: Dispatch) => {
    dispatch(startExport());

    const exportDeviceStatusesPromise = doExportDeviceStatuses(vendorId, vehicleTypeId, sortedBy, sortOrder);

    exportDeviceStatusesPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));

    return exportDeviceStatusesPromise;
  };

export const exportXDevicesSatuses = (vendorId: number, searchTerm: string) => (dispatch: Dispatch) => {
  dispatch(startExport());

  const exportXDevicesStatusesPromise = doExportXDeviceStatuses(vendorId, searchTerm);

  exportXDevicesStatusesPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));

  return exportXDevicesStatusesPromise;
};

export const resetDeviceStatuses = () => ({
  type: RESET,
});

// Selectors
const getDeviceStatuses = (deviceStatusState: any) => get(deviceStatusState, 'yDeviceStatuses.deviceStatuses', []);
export const deviceStatusesSelector = createSelector(getDeviceStatuses, identity);

const getDeviceStatusCount = (deviceStatusState: any) => get(deviceStatusState, 'yDeviceStatuses.total', 0);
export const deviceStatusCountSelector = createSelector(getDeviceStatusCount, identity);
