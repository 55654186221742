import React, { PureComponent, Fragment } from 'react';

import { BaseFieldProps, change, Field as GenericField, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';

import { AppState } from '../../../store';
import { CheckboxGroup, RadioButton } from '../../../core/components';
import { FormGroup, IconButton, IconButtonIcon, Text } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { dateTimeFormat, isRequired } from '../../../utils/services/validator';
import { INFERENCE_AUDIT_CORRECTNESS, INFERENCE_AUDIT_DETECTIONS } from '../../constants';
import {
  DETECTION_BASKETBALL_HOOP,
  DETECTION_BIKE_LANE_SWEEPING,
  DETECTION_BLACK,
  DETECTION_BLUE,
  DETECTION_CONSTRUCTION,
  DETECTION_CRACKING,
  DETECTION_DISCOLORATION_IN_ROAD,
  DETECTION_DRIVEWAY,
  DETECTION_GRAY,
  DETECTION_GREEN,
  DETECTION_INCORRECT_INFERENCE,
  DETECTION_INTERSECTION_SWEEPING,
  DETECTION_MAILBOX,
  DETECTION_MANHOLE,
  DETECTION_NO_CURB_PRESENT,
  DETECTION_NON_CURB_SWEEPING,
  DETECTION_NOT_A_TRASH_CONTAINER,
  DETECTION_NOT_OVERFLOWING,
  DETECTION_ON_CURB_SHADOWS,
  DETECTION_ON_LEFT_CURB_OR_MEDIAN,
  DETECTION_ON_RIGHT_CURB,
  DETECTION_OPEN_LID,
  DETECTION_OTHER,
  DETECTION_OUTSIDE_THE_STREET_SEGMENT,
  DETECTION_PATCHED_POTHOLE,
  DETECTION_PEDESTRIAN,
  DETECTION_PINK,
  DETECTION_PUDDLE,
  DETECTION_SIGN,
  DETECTION_STREET_MARKINGS,
  DETECTION_TELEPHONE_POLES,
  DETECTION_TREE_BUSH,
  DETECTION_TURN_LANE_SWEEPING,
  DETECTION_UNKNOWN,
  DETECTION_VEHICLE,
  DETECTION_VEHICLES,
  DETECTION_WASTE_CONTAINER,
  DETECTION_PLASTIC_BAG,
  DETECTION_PLASTIC_BOTTLE,
  DETECTION_PLASTIC_FILM,
  DETECTION_CAN,
  DETECTION_CARDBOARD,
  DETECTION_PAPER_BAG,
  DETECTION_NO_CONTAMINATION_PRESENT,
  DETECTION_NOT_A_POTHOLE,
  DETECTION_SHADOW,
  DETECTION_PAPER_PLATES,
  DETECTION_PAPER_NEWSPAPER,
  DETECTION_SHREDDED_PAPER,
  DETECTION_FAST_FOOD_BAGS,
  DETECTION_OVEREXPOSED_IMAGE,
} from '../../constants/inferenceAuditDetections';
import { VALID_FALSE, VALID_FALSE_ID, VALID_TRUE, VALID_TRUE_ID } from '../../constants/inferenceAuditIsValid';
import { COMPLETE } from '../../constants/inferenceAuditStatuses';
import {
  OFF_CURB,
  BIN_OVERFLOW,
  BIN_DETECTION,
  BIN_DETECTION_OVERFLOW,
  POTHOLE_DETECTION,
  MATERIAL_CONTAMINATION,
} from '../../constants/modelClassTypes';
import {
  FlexForm,
  InferenceAuditFormBlock,
  InferenceAuditModalBlockLabel,
  InferenceAuditModalBlockWrapper,
  InferenceListContainer,
  StatusWrapper,
} from '../styled/InferenceAuditPage';
import { InferenceAudit } from '../../interfaces/interfaces';

interface ComponentProps extends RouteComponentProps {
  auditIndex: number;
  isLoading?: boolean;
  isModal?: boolean;
}

interface PropsWithoutReduxForm extends ComponentProps {
  change: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<InferenceAudit, PropsWithoutReduxForm>;

interface State {
  isValid?: boolean;
  auditedDetections?: string[];
  correctAuditedDetections?: string[];
  incorrectAuditedDetections?: string[];
}

const Field = GenericField as new () => GenericField<
  BaseFieldProps & {
    block?: boolean;
    color?: string;
    disabled?: boolean;
    formGroupProps?: any;
    items?: any[];
    label?: string;
    margin?: string;
    placeholder?: string;
    size?: string;
    type?: string;
    value?: boolean;
  }
>;

class InferenceAuditForm extends PureComponent<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: Props) {
    if (nextProps.initialValues.status === COMPLETE) {
      return {
        isValid: nextProps.initialValues.isValid,
      };
    }
    return prevState;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      isValid: props.initialValues.isValid,
      auditedDetections: props.initialValues.auditedDetections,
      correctAuditedDetections: props.initialValues.auditedDetections,
      incorrectAuditedDetections: props.initialValues.auditedDetections,
    };
  }

  handleIsValidChange = (e: any, value: boolean, prevState: State) => {
    const { change } = this.props;
    if (value) {
      this.setState({ ...this.state, correctAuditedDetections: prevState.auditedDetections });
    } else {
      this.setState({ ...this.state, incorrectAuditedDetections: prevState.auditedDetections });
    }
    change('auditedDetections', value ? this.state.correctAuditedDetections : this.state.incorrectAuditedDetections);

    this.setState({ ...this.state, isValid: value });
  };

  handleAuditedDetectionsChange = (e: any, value: string[]) => {
    if (this.state.isValid) {
      this.setState({ correctAuditedDetections: value });
    } else {
      this.setState({ incorrectAuditedDetections: value });
    }
    this.setState({ auditedDetections: value });
  };

  render() {
    const { handleSubmit, initialValues, isModal, isLoading } = this.props;
    const { isValid } = this.state;

    const { detectionNames, modelClass, modelClassName, status, statusName, timeStamp, vehicleName } = initialValues;

    const offCurbCorrectOptions = [
      {
        value: DETECTION_VEHICLE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_VEHICLE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_VEHICLE].safeName,
      },
      {
        value: DETECTION_WASTE_CONTAINER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_WASTE_CONTAINER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_WASTE_CONTAINER].safeName,
      },
      {
        value: DETECTION_TREE_BUSH,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_TREE_BUSH].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_TREE_BUSH].safeName,
      },
      {
        value: DETECTION_PEDESTRIAN,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PEDESTRIAN].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PEDESTRIAN].safeName,
      },
      {
        value: DETECTION_SIGN,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_SIGN].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_SIGN].safeName,
      },
      {
        value: DETECTION_CONSTRUCTION,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_CONSTRUCTION].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_CONSTRUCTION].safeName,
      },
      {
        value: DETECTION_BASKETBALL_HOOP,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_BASKETBALL_HOOP].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_BASKETBALL_HOOP].safeName,
      },
      {
        value: DETECTION_OTHER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].safeName,
      },
      {
        value: DETECTION_UNKNOWN,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_UNKNOWN].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_UNKNOWN].safeName,
      },
    ];
    const offCurbIncorrectOptions = [
      {
        value: DETECTION_ON_RIGHT_CURB,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_ON_RIGHT_CURB].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_ON_RIGHT_CURB].safeName,
      },
      {
        value: DETECTION_ON_LEFT_CURB_OR_MEDIAN,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_ON_LEFT_CURB_OR_MEDIAN].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_ON_LEFT_CURB_OR_MEDIAN].safeName,
      },
      {
        value: DETECTION_ON_CURB_SHADOWS,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_ON_CURB_SHADOWS].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_ON_CURB_SHADOWS].safeName,
      },
      {
        value: DETECTION_NO_CURB_PRESENT,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_NO_CURB_PRESENT].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_NO_CURB_PRESENT].safeName,
      },
      {
        value: DETECTION_NON_CURB_SWEEPING,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_NON_CURB_SWEEPING].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_NON_CURB_SWEEPING].safeName,
      },
      {
        value: DETECTION_BIKE_LANE_SWEEPING,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_BIKE_LANE_SWEEPING].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_BIKE_LANE_SWEEPING].safeName,
      },
      {
        value: DETECTION_TURN_LANE_SWEEPING,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_TURN_LANE_SWEEPING].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_TURN_LANE_SWEEPING].safeName,
      },
      {
        value: DETECTION_INCORRECT_INFERENCE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_INCORRECT_INFERENCE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_INCORRECT_INFERENCE].safeName,
      },
      {
        value: DETECTION_INTERSECTION_SWEEPING,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_INTERSECTION_SWEEPING].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_INTERSECTION_SWEEPING].safeName,
      },
      {
        value: DETECTION_DRIVEWAY,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_DRIVEWAY].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_DRIVEWAY].safeName,
      },
    ];
    const overflowCorrectOptions = [
      {
        value: DETECTION_BLACK,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_BLACK].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_BLACK].safeName,
      },
      {
        value: DETECTION_BLUE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_BLUE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_BLUE].safeName,
      },
      {
        value: DETECTION_GRAY,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_GRAY].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_GRAY].safeName,
      },
      {
        value: DETECTION_GREEN,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_GREEN].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_GREEN].safeName,
      },
      {
        value: DETECTION_PINK,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PINK].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PINK].safeName,
      },
      {
        value: DETECTION_OTHER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].safeName,
      },
    ];
    const overflowIncorrectOptions = [
      {
        value: DETECTION_PEDESTRIAN,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PEDESTRIAN].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PEDESTRIAN].safeName,
      },
      {
        value: DETECTION_MAILBOX,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_MAILBOX].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_MAILBOX].safeName,
      },
      {
        value: DETECTION_NOT_OVERFLOWING,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_NOT_OVERFLOWING].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_NOT_OVERFLOWING].safeName,
      },
      {
        value: DETECTION_OPEN_LID,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OPEN_LID].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OPEN_LID].safeName,
      },
      {
        value: DETECTION_NOT_A_TRASH_CONTAINER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_NOT_A_TRASH_CONTAINER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_NOT_A_TRASH_CONTAINER].safeName,
      },
      {
        value: DETECTION_TELEPHONE_POLES,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_TELEPHONE_POLES].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_TELEPHONE_POLES].safeName,
      },
      {
        value: DETECTION_VEHICLES,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_VEHICLES].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_VEHICLES].safeName,
      },
      {
        value: DETECTION_OTHER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].safeName,
      },
    ];
    const potholeDetectionIncorrectOptions = [
      {
        value: DETECTION_STREET_MARKINGS,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_STREET_MARKINGS].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_STREET_MARKINGS].safeName,
      },
      {
        value: DETECTION_PUDDLE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PUDDLE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PUDDLE].safeName,
      },
      {
        value: DETECTION_CRACKING,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_CRACKING].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_CRACKING].safeName,
      },
      {
        value: DETECTION_MANHOLE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_MANHOLE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_MANHOLE].safeName,
      },
      {
        value: DETECTION_DISCOLORATION_IN_ROAD,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_DISCOLORATION_IN_ROAD].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_DISCOLORATION_IN_ROAD].safeName,
      },
      {
        value: DETECTION_PATCHED_POTHOLE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PATCHED_POTHOLE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PATCHED_POTHOLE].safeName,
      },
      {
        value: DETECTION_OUTSIDE_THE_STREET_SEGMENT,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OUTSIDE_THE_STREET_SEGMENT].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OUTSIDE_THE_STREET_SEGMENT].safeName,
      },
      {
        value: DETECTION_NOT_A_POTHOLE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_NOT_A_POTHOLE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_NOT_A_POTHOLE].safeName,
      },
      {
        value: DETECTION_SHADOW,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_SHADOW].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_SHADOW].safeName,
      },
    ];

    const materialContaminationCorrectOptions = [
      {
        value: DETECTION_PLASTIC_BAG,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PLASTIC_BAG].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PLASTIC_BAG].safeName,
      },
      {
        value: DETECTION_PLASTIC_BOTTLE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PLASTIC_BOTTLE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PLASTIC_BOTTLE].safeName,
      },
      {
        value: DETECTION_PLASTIC_FILM,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PLASTIC_FILM].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PLASTIC_FILM].safeName,
      },
      {
        value: DETECTION_CAN,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_CAN].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_CAN].safeName,
      },
      {
        value: DETECTION_OTHER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].safeName,
      },
    ];
    const materialContaminationIncorrectOptions = [
      {
        value: DETECTION_CARDBOARD,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_CARDBOARD].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_CARDBOARD].safeName,
      },
      {
        value: DETECTION_PAPER_BAG,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PAPER_BAG].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PAPER_BAG].safeName,
      },
      {
        value: DETECTION_NO_CONTAMINATION_PRESENT,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_NO_CONTAMINATION_PRESENT].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_NO_CONTAMINATION_PRESENT].safeName,
      },
      {
        value: DETECTION_PAPER_PLATES,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PAPER_PLATES].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PAPER_PLATES].safeName,
      },
      {
        value: DETECTION_PAPER_NEWSPAPER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_PAPER_NEWSPAPER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_PAPER_NEWSPAPER].safeName,
      },
      {
        value: DETECTION_SHREDDED_PAPER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_SHREDDED_PAPER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_SHREDDED_PAPER].safeName,
      },
      {
        value: DETECTION_FAST_FOOD_BAGS,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_FAST_FOOD_BAGS].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_FAST_FOOD_BAGS].safeName,
      },
      {
        value: DETECTION_OVEREXPOSED_IMAGE,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OVEREXPOSED_IMAGE].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OVEREXPOSED_IMAGE].safeName,
      },
      {
        value: DETECTION_OTHER,
        label: translate(INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].name),
        name: INFERENCE_AUDIT_DETECTIONS[DETECTION_OTHER].safeName,
      },
    ];

    let detectionsOptions: any[] = [];
    let detectionsLabel = '';

    if (modelClass === OFF_CURB && isValid === VALID_TRUE) {
      detectionsOptions = offCurbCorrectOptions;
      detectionsLabel = translate('inferenceAudit.obstacle');
    } else if (modelClass === OFF_CURB && isValid === VALID_FALSE) {
      detectionsOptions = offCurbIncorrectOptions;
      detectionsLabel = translate('inferenceAudit.becauseTheVehicle');
    } else if (
      (modelClass === BIN_OVERFLOW || modelClass === BIN_DETECTION || modelClass === BIN_DETECTION_OVERFLOW) &&
      isValid === VALID_TRUE
    ) {
      detectionsOptions = overflowCorrectOptions;
      detectionsLabel = translate('inferenceAudit.color');
    } else if (
      (modelClass === BIN_OVERFLOW || modelClass === BIN_DETECTION || modelClass === BIN_DETECTION_OVERFLOW) &&
      isValid === VALID_FALSE
    ) {
      detectionsOptions = overflowIncorrectOptions;
      detectionsLabel = translate('inferenceAudit.otherObjectIdentifiedAsOverflowing');
    } else if (modelClass === POTHOLE_DETECTION && isValid === VALID_FALSE) {
      detectionsOptions = potholeDetectionIncorrectOptions;
      detectionsLabel = translate('inferenceAudit.otherObjectIdentifiedAsPothole');
    } else if (modelClass === MATERIAL_CONTAMINATION && isValid === VALID_TRUE) {
      detectionsOptions = materialContaminationCorrectOptions;
      detectionsLabel = translate('inferenceAudit.otherObjectIdentifiedAsMaterialContamination');
    } else if (modelClass === MATERIAL_CONTAMINATION && isValid === VALID_FALSE) {
      detectionsOptions = materialContaminationIncorrectOptions;
      detectionsLabel = translate('inferenceAudit.otherObjectIdentifiedAsMaterialContamination');
    }

    const inferenceListContainer = (
      <InferenceAuditFormBlock borderRight width="50%" padding="no small no" vertical align="top">
        <InferenceListContainer>
          <Text block weight="medium" align="right">
            {`${translate('inferenceAudit.inferenceAuditModel.timeStamp')}:`}
          </Text>
          <Text block>{moment(timeStamp).format(dateTimeFormat)}</Text>

          <Text block weight="medium" align="right">
            {`${translate('inferenceAudit.inferenceAuditModel.inference')}:`}
          </Text>
          <Text block>{modelClassName}</Text>

          {modelClass !== POTHOLE_DETECTION && (
            <Fragment>
              <Text block weight="medium" align="right">
                {`${translate(
                  `inferenceAudit.inferenceAuditModel.${
                    modelClass === OFF_CURB ? 'obstacle' : modelClass === MATERIAL_CONTAMINATION ? 'object' : 'binColor'
                  }`,
                )}:`}
              </Text>
              <Text block>{detectionNames}</Text>
            </Fragment>
          )}
        </InferenceListContainer>
      </InferenceAuditFormBlock>
    );

    const isValidFormGroup = (
      <InferenceAuditFormBlock width={isModal ? '27%' : '30%'} padding="no small no" vertical align="top">
        <FormGroup margin="no" width="100%">
          {isModal && (
            <Text block size="small" weight="medium" margin="no no small">
              {translate('inferenceAudit.thisInferenceIs')}
            </Text>
          )}
          <Field
            name="isValid"
            component={RadioButton as any}
            type="radio"
            label={INFERENCE_AUDIT_CORRECTNESS[VALID_TRUE_ID].name}
            value={VALID_TRUE}
            parse={value => value === VALID_TRUE.toString()}
            disabled={status === COMPLETE}
            block
            color="primary"
            size="small"
            margin="no no xSmall"
            onChange={this.handleIsValidChange}
          />
          <Field
            name="isValid"
            component={RadioButton as any}
            type="radio"
            label={INFERENCE_AUDIT_CORRECTNESS[VALID_FALSE_ID].name}
            value={VALID_FALSE}
            parse={value => value === VALID_TRUE.toString()}
            disabled={status === COMPLETE}
            block
            color="alert"
            size="small"
            margin="no"
            onChange={this.handleIsValidChange}
          />
        </FormGroup>
      </InferenceAuditFormBlock>
    );

    const detectionsFormBlock = (
      <InferenceAuditFormBlock width={isModal ? '100%' : '70%'} padding="no small no" vertical align="top">
        <Field
          name="auditedDetections"
          items={detectionsOptions}
          component={CheckboxGroup as any}
          validate={[isRequired]}
          disabled={status === COMPLETE}
          margin="no"
          formGroupProps={{
            grid: true,
            gridColumns: isModal && 'repeat(2, 1fr)',
            gridColumnGap: '20px',
            gridRowGap: '5px',
          }}
          props={{
            type: 'radio',
          }}
          onChange={this.handleAuditedDetectionsChange}
        />
      </InferenceAuditFormBlock>
    );

    const statusFormBlock = (
      <InferenceAuditFormBlock
        borderLeft={isModal}
        width={isModal ? '23%' : '30%'}
        padding="no small no"
        align="center"
      >
        <StatusWrapper>
          <Text block weight="medium">
            {statusName}
          </Text>
          {status !== COMPLETE && (
            <IconButton type="submit" disabled={status === COMPLETE} color="primary">
              <IconButtonIcon icon="check" margin="no" />
            </IconButton>
          )}
        </StatusWrapper>
      </InferenceAuditFormBlock>
    );

    const inferenceListContainerForViewList = (
      <InferenceAuditFormBlock borderRight={isModal} width={'70%'} padding="no small no" align="left">
        <InferenceListContainer>
          <Text block weight="medium" align="right">
            {`${translate('inferenceAudit.inferenceAuditModel.timeStamp')}:`}
          </Text>
          <Text block>{moment(timeStamp).format(dateTimeFormat)}</Text>

          <Text block weight="medium" align="right">
            {`${translate('inferenceAudit.inferenceAuditModel.vehicle')}:`}
          </Text>
          <Text block>{vehicleName}</Text>

          <Text block weight="medium" align="right">
            {`${translate('inferenceAudit.inferenceAuditModel.inference')}:`}
          </Text>
          <Text block>{modelClassName}</Text>

          {modelClass !== POTHOLE_DETECTION && (
            <Fragment>
              <Text block weight="medium" align="right">
                {`${translate(
                  `inferenceAudit.inferenceAuditModel.${
                    modelClass === OFF_CURB ? 'obstacle' : modelClass === MATERIAL_CONTAMINATION ? 'object' : 'binColor'
                  }`,
                )}:`}
              </Text>
              <Text block>{detectionNames}</Text>
            </Fragment>
          )}
        </InferenceListContainer>
      </InferenceAuditFormBlock>
    );

    return (
      <FlexForm isLoading={isLoading} noValidate wrap={'wrap'} onSubmit={handleSubmit}>
        {isModal ? (
          <Fragment>
            <InferenceAuditModalBlockWrapper borderBottom padding="sMedium small sMedium">
              {inferenceListContainer}
              {isValidFormGroup}
              {statusFormBlock}
            </InferenceAuditModalBlockWrapper>
            <InferenceAuditModalBlockWrapper padding="sMedium small sMedium">
              {detectionsFormBlock}
            </InferenceAuditModalBlockWrapper>
          </Fragment>
        ) : (
          <Fragment>
            <InferenceAuditModalBlockLabel width={'70%'} margin="no no small">
              {translate('inferenceAudit.inferenceAuditTableHeaders.modelInference')}
            </InferenceAuditModalBlockLabel>
            <InferenceAuditModalBlockLabel width={'30%'} margin="no no small" align="center">
              {translate('inferenceAudit.thisInferenceIs')}
            </InferenceAuditModalBlockLabel>
            {inferenceListContainerForViewList}
            {isValidFormGroup}

            <InferenceAuditModalBlockLabel borderTop width={'70%'} padding="small no no no" margin="small no">
              {detectionsLabel}
            </InferenceAuditModalBlockLabel>
            <InferenceAuditModalBlockLabel
              borderTop
              width={'30%'}
              padding="small no no no"
              margin="small no"
              align="center"
            >
              {translate('inferenceAudit.auditStatus')}
            </InferenceAuditModalBlockLabel>
            {detectionsFormBlock}
            {statusFormBlock}
          </Fragment>
        )}
      </FlexForm>
    );
  }
}

const mapStateToProps = (state: AppState, { auditIndex }: ComponentProps) => ({
  initialValues: state.inferenceAudit.inferenceAudits.inferenceAudits[auditIndex],
});

const mapDispatchToProps = {
  change,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<InferenceAudit, PropsWithoutReduxForm>({
      form: 'inferenceAuditForm',
      enableReinitialize: true,
    })(InferenceAuditForm),
  ),
);
