import React, { useMemo } from 'react';
import { Popup } from 'react-map-gl';

import { BinLocationMappingTypeId, COLLECTION_POINT } from '../../../../common/constants/binMappingTypes';
import { binMappingLocationTypeTranslationKeys } from '../../../../customers/components/PickupLocationEditorMapbox';
import { DirtyPickupLocation } from '../../../interfaces/DirtyPickupLocation';
import {
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
} from '../../../../core/components/styled/MapInfoWindow';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import translate from '../../../../core/services/translate';

export const PickupLocationMarkerInfoWindow: React.FC<{
  routeLocation: RouteLocation;
  dirtyPickupLocation?: DirtyPickupLocation;
  onClose: () => void;
}> = ({ routeLocation, dirtyPickupLocation, onClose }) => {
  const container = routeLocation.service.serviceContractBinDetails[0];
  const address = routeLocation.location.address;
  const { customer, location, orderNumber } = routeLocation;

  const markerPosition = useMemo(() => {
    return {
      lat: dirtyPickupLocation
        ? dirtyPickupLocation.binMappingTypeId === COLLECTION_POINT
          ? address.latitude
          : dirtyPickupLocation.latitude
        : container.binLocationMappingTypeId === COLLECTION_POINT
        ? address.latitude
        : container.binLatitude,
      lng: dirtyPickupLocation
        ? dirtyPickupLocation.binMappingTypeId === COLLECTION_POINT
          ? address.longitude
          : dirtyPickupLocation.longitude
        : container.binLocationMappingTypeId === COLLECTION_POINT
        ? address.longitude
        : container.binLongitude,
    };
  }, [dirtyPickupLocation, container, address]);

  return (
    <Popup onClose={onClose} latitude={markerPosition.lat} longitude={markerPosition.lng}>
      <MapInfoWindow>
        <MapInfoWindowDetails>
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('routes.stopNumber')}:</MapInfoWindowDetailLabel>
            {orderNumber}
          </MapInfoWindowDetail>

          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('common.customer')}:</MapInfoWindowDetailLabel>
            {customer.name}
          </MapInfoWindowDetail>

          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('common.location')}:</MapInfoWindowDetailLabel>
            {location.name}
          </MapInfoWindowDetail>

          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('common.address')}:</MapInfoWindowDetailLabel>
            {location.address.line1}
          </MapInfoWindowDetail>

          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('common.type')}:</MapInfoWindowDetailLabel>
            {translate(
              `customers.pickupLocations.${
                binMappingLocationTypeTranslationKeys[
                  (dirtyPickupLocation
                    ? dirtyPickupLocation.binMappingTypeId
                    : container.binLocationMappingTypeId) as BinLocationMappingTypeId
                ]
              }`,
            )}
          </MapInfoWindowDetail>
        </MapInfoWindowDetails>
      </MapInfoWindow>
    </Popup>
  );
};
