import React, { ChangeEvent } from 'react';

import { map } from 'lodash-es';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { X_DEVICE_TYPES } from '../constants/xDeviceTypes';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {}

export const DeviceTypesDropdown: React.FC<Props> = ({
  input: { name, onChange },
  placeholder,
  withPlaceholder,
  label,
  withLabel,
  dropdownProps,
}) => {
  const onChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    onChange(value);
  };

  const vendorStatusOptions = map(X_DEVICE_TYPES, xDevType => ({
    label: xDevType.name,
    value: xDevType.id,
  }));

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChangeValue}
      props={{
        label: label || (withLabel ? translate('vendors.xDeviceType') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('vendors.xDeviceType') : undefined),
        options: vendorStatusOptions,
        ...dropdownProps,
      }}
    />
  );
};
