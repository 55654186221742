import React, { ChangeEvent, PureComponent } from 'react';

import { debounce } from 'lodash-es';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch, TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  onSearchTermChange: (searchTerm: string) => void;
};

interface FormValues {
  routeEntityLocationSearchTerm: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

class RouteEntityLocationSearchForm extends PureComponent<Props> {
  onSearchTermChange = debounce((event: ChangeEvent<HTMLInputElement>, searchTerm: string) => {
    this.props.onSearchTermChange(searchTerm);
  }, 500);

  render() {
    return (
      <form>
        <PanelSection padding="xSmall" withBorder>
          <Grid>
            <GridColumn size="10/12">
              <TypedField
                name="routeEntityLocationSearchTerm"
                component={PanelSearch}
                onChange={this.onSearchTermChange}
                props={{
                  placeholder: translate('routes.searchStop'),
                  margin: "no",
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'routeEntityLocationSearch',
  enableReinitialize: true,
})(RouteEntityLocationSearchForm);
