import { push } from 'connected-react-router';
import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { requestPasswordReset as doRequestPasswordReset } from '../services/account';

// Actions
const START_REQUEST_PASSWORD_RESET = 'account/requestPasswordReset/START_REQUEST_PASSWORD_RESET';
const COMPLETE_REQUEST_PASSWORD_RESET = 'account/requestPasswordReset/COMPLETE_REQUEST_PASSWORD_RESET';
const RESET = 'account/requestPasswordReset/RESET';

// Initial state
const initialState = {
  isRequestingPasswordReset: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_REQUEST_PASSWORD_RESET:
      return update(state, { $merge: { isRequestingPasswordReset: true } });

    case COMPLETE_REQUEST_PASSWORD_RESET:
      return update(state, { $merge: { isRequestingPasswordReset: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startRequestPasswordReset = () => ({
  type: START_REQUEST_PASSWORD_RESET,
});

const completeRequestPasswordReset = () => ({
  type: COMPLETE_REQUEST_PASSWORD_RESET,
});

export const requestPasswordReset = (email: string) => async (dispatch: Dispatch) => {
  dispatch(startRequestPasswordReset());
  await doRequestPasswordReset(email);
  dispatch(completeRequestPasswordReset());
  dispatch(push('/account/password-reset-email-sent'));
};

export const resetRequestPasswordReset = () => ({
  type: RESET,
});
