import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadPermissions, shouldLoadPermissions } from 'src/account/ducks/permissions';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { loadBillStatuses, loadOpenBills, loadPaymentDetails, loadPaymentStatuses } from 'src/finance/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';

import translate from 'src/core/services/translate';

import { INVOICE_PAYMENT_DEFAULT_STATUSES } from 'src/finance/constants/invoicePaymentStatuses';
import PaymentDetailsPage from './PaymentDetailsPage';

interface PaymentDetailsMatchParams {
  paymentCustomerId: string;
  paymentId: string;
}

const PaymentDetailsPageResolver = () => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);
  const { paymentId, paymentCustomerId } = useParams<PaymentDetailsMatchParams>();

  const loadDependencies = () => {
    const dependencies: Promise<any>[] = [loadPaymentStatuses()(dispatch), loadBillStatuses()(dispatch),];

    if (paymentId) {
      dependencies.push(loadPaymentDetails({ vendorId, paymentId: Number(paymentId) })(dispatch));
    }

    if (paymentCustomerId) {
      const noLoadingIndicator = true;
      dependencies.push(
        loadOpenBills({
          vendorId,
          billStatusIds: INVOICE_PAYMENT_DEFAULT_STATUSES,
          customerId: Number(paymentCustomerId),
          excludedPaymentId: Number(paymentId),
          noLoadingIndicator,
        })(dispatch),
      );
    }

    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('finance.applyChecks.applyChecks')}</DocumentTitle>
      <TypedResolver
        redirectOnError="/finance"
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={PaymentDetailsPage}
      />
    </>
  );
};

export default PaymentDetailsPageResolver;
