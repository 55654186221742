import { Modal } from 'src/core/components';
import Table from 'src/core/components/Table';
import translate from 'src/core/services/translate';
import { PaymentAttachmentsTableRow } from './PaymentAttachmentsTableRow';
import { PaymentAttachment } from 'src/routes/interfaces/PaymentAttachment';

export const PaymentAttachmentsModal: React.FC<{ files: PaymentAttachment[]; onClose: () => void }> = ({
  files,
  onClose,
}) => {
  const cells = [
    {
      name: 'name',
      label: translate('common.name'),
      width: '100%',
      sortable: false,
    },
  ];
  return (
    <Modal title={translate('common.files')} onClose={onClose}>
      <Table cells={cells} rows={files} rowComponent={PaymentAttachmentsTableRow} />
    </Modal>
  );
};
