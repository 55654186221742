import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  checkIsQuickBooksActive as doCheckIsQuickBooksActive,
  quickBooksAuthorization as doQuickBooksAuthorization,
  completeQuickBooksSetup as doCompleteQuickBooksSetup,
} from 'src/vendors/services/quickBooks';
import { QuickBooksAuthParams } from '../interfaces/QuickBooksTypes';
import { createErrorNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';

// Actions
const START_LOAD = 'vendors/quickBooks/START_LOAD';
const COMPLETE_LOAD_QUICKBOOKS_ACTIVE = 'vendors/quickBooks/COMPLETE_LOAD_QUICKBOOKS_ACTIVE';
const FAIL_LOAD_QUICKBOOKS_ACTIVE = 'vendors/quickBooks/FAIL_LOAD_QUICKBOOKS_ACTIVE';
const COMPLETE_LOAD_AUTH_RESPONSE = 'vendors/quickBooks/COMPLETE_LOAD_AUTH_RESPONSE';
const FAIL_LOAD_AUTH_RESPONSE = 'vendors/quickBooks/FAIL_LOAD_AUTH_RESPONSE';
const COMPLETE_LOAD_AUTHORIZATION = 'vendors/quickBooks/COMPLETE_LOAD_AUTHORIZATION';
const FAIL_LOAD_AUTHORIZATION = 'vendors/quickBooks/FAIL_LOAD_AUTHORIZATION';
const RESET = 'vendors/quickBooks/RESET';

interface StripeState {
  isQuickBooksActive: boolean;
  quickBooksAuthResponse?: any;
  quickBooksAuthorization?: any;
  isLoading: boolean;
  isSaving: boolean;
}

// Initial state
const initialState: StripeState = {
  isQuickBooksActive: false,
  quickBooksAuthResponse: undefined,
  quickBooksAuthorization: undefined,
  isLoading: false,
  isSaving: false,
};

// Reducer
export const reducer = (state: StripeState = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_QUICKBOOKS_ACTIVE:
      return update(state, {
        $merge: {
          isQuickBooksActive: action.isQuickBooksActive,
          isLoading: false,
        },
      });

    case FAIL_LOAD_QUICKBOOKS_ACTIVE:
      return update(state, {
        $merge: {
          isQuickBooksActive: false,
          isLoading: false,
        },
      });

    case COMPLETE_LOAD_AUTH_RESPONSE:
      return update(state, {
        $merge: {
          quickBooksAuthResponse: action.quickBooksAuthResponse,
          isLoading: false,
        },
      });

    case FAIL_LOAD_AUTH_RESPONSE:
      return update(state, {
        $merge: {
          quickBooksAuthResponse: undefined,
          isLoading: false,
        },
      });

    case COMPLETE_LOAD_AUTHORIZATION:
      return update(state, {
        $merge: {
          quickBooksAuthorization: action.quickBooksAuthorization,
          isLoading: true, // do not stop loading on success, it will redirect the user to quickbooks so we need to keep the loading indicator on.
        },
      });

    case FAIL_LOAD_AUTHORIZATION:
      return update(state, {
        $merge: {
          quickBooksAuthorization: undefined,
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoadIsQuickBooksActive = (isQuickBooksActive: boolean) => ({
  type: COMPLETE_LOAD_QUICKBOOKS_ACTIVE,
  isQuickBooksActive,
});

const failLoadIsQuickBooksActive = () => ({
  type: FAIL_LOAD_QUICKBOOKS_ACTIVE,
});

const completeLoadQuickBooksAuthResponse = (quickBooksAuthResponse: any) => ({
  type: COMPLETE_LOAD_AUTH_RESPONSE,
  quickBooksAuthResponse,
});

const failLoadQuickBooksAuthResponse = () => ({
  type: FAIL_LOAD_AUTH_RESPONSE,
});

const completeLoadQuickBooksAuthorization = (quickBooksAuthorization: any) => ({
  type: COMPLETE_LOAD_AUTHORIZATION,
  quickBooksAuthorization,
});

const failLoadQuickBooksAuthorization = () => ({
  type: FAIL_LOAD_AUTHORIZATION,
});

export const resetStripeAccount = () => ({
  type: RESET,
});

export const checkIsQuickBooksActive = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadQuickBooksActivePromise = doCheckIsQuickBooksActive();
  loadQuickBooksActivePromise
    .then(isActive => dispatch(completeLoadIsQuickBooksActive(isActive)))
    .catch((error) => {
      if (error?.response?.data?.message) {
        createErrorNotification(error.response.data.message);
      } else {
        createErrorNotification(translate('finance.quickBooksIntegration.quickBooksActiveError'));
      }
      dispatch(failLoadIsQuickBooksActive())
    }); 
  return loadQuickBooksActivePromise;
};

export const quickBooksAuthorization = (clientId: string, clientSecret: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadQuickBooksAuthorizationPromise = doQuickBooksAuthorization(clientId, clientSecret);
  loadQuickBooksAuthorizationPromise
    .then(quickBooksAuthorization => {
      dispatch(completeLoadQuickBooksAuthorization(quickBooksAuthorization));
      if (quickBooksAuthorization.redirectUrl) {
        window.location.href = quickBooksAuthorization.redirectUrl;
      }
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        createErrorNotification(error.response.data.message);
      } else {
        createErrorNotification(translate('finance.quickBooksIntegration.completeAuthorizationError'));
      }
      dispatch(failLoadQuickBooksAuthorization());
    });
  return loadQuickBooksAuthorizationPromise;
};

export const completeQuickBooksSetup = (params: QuickBooksAuthParams) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadQuickBooksAuthResponsePromise = doCompleteQuickBooksSetup(params);
  loadQuickBooksAuthResponsePromise
    .then(quickBooksAuthResponse => {
      dispatch(completeLoadQuickBooksAuthResponse(quickBooksAuthResponse));
      checkIsQuickBooksActive()(dispatch); // on success check if quickbooks is activ
      window.location.href = '/account-settings'; // on usccess remove query params
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        createErrorNotification(error.response.data.message);
      } else {
        createErrorNotification(translate('finance.quickBooksIntegration.completeAuthorizationError'));
      }
      dispatch(failLoadQuickBooksAuthResponse());
    });
  return loadQuickBooksAuthResponsePromise;
};
