import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadContainerTypes as doLoadContainerTypes } from '../services/containerTypes';
import { ContainerType } from '../interfaces/ContainerTypes';

// Actions
const START_LOAD = 'common/containerTypes/START_LOAD';
const COMPLETE_LOAD = 'common/containerTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/containerTypes/FAIL_LOAD';
const RESET = 'common/containerTypes/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containerTypes: ContainerType[];
} = {
  isLoading: false,
  containerTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerTypes: action.containerTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerTypes: ContainerType[]) => ({
  type: COMPLETE_LOAD,
  containerTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainerTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerTypesPromise = doLoadContainerTypes();
  loadContainerTypesPromise
    .then((containerTypes: ContainerType[]) => dispatch(completeLoad(containerTypes)))
    .catch(() => dispatch(failLoad()));
  return loadContainerTypesPromise;
};

export const resetContainerTypes = () => ({
  type: RESET,
});
