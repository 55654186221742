import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRoutesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';
import { loadRoutePlannerRouteDrivers as doLoadRoutePlannerRouteDrivers } from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerRouteDrivers/START_LOAD';
const COMPLETE_LOAD = 'routePlannerRouteDrivers/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerRouteDrivers/FAIL_LOAD';
const START_LOAD_DRIVERS_FOR_AVAILABILITY = 'routePlannerRouteDrivers/START_LOAD_DRIVERS_FOR_AVAILABILITY';
const COMPLETE_LOAD_DRIVERS_FOR_AVAILABILITY = 'routePlannerRouteDrivers/COMPLETE_LOAD_DRIVERS_FOR_AVAILABILITY';
const FAIL_LOAD_DRIVERS_FOR_AVAILABILITY = 'routePlannerRouteDrivers/FAIL_LOAD_DRIVERS_FOR_AVAILABILITY';
const RESET = 'routePlannerRouteDrivers/RESET';

interface State {
  isLoading: boolean;
  isLoadingDriversForAvailability: boolean;
  drivers: RoutePlannerRouteDriver[];
  driversForAvailability: RoutePlannerRouteDriver[];
}

const initialState: State = {
  isLoading: false,
  isLoadingDriversForAvailability: false,
  drivers: [],
  driversForAvailability: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivers: action.drivers,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          drivers: [],
        },
      });

    case START_LOAD_DRIVERS_FOR_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingDriversForAvailability: true,
        },
      });

    case COMPLETE_LOAD_DRIVERS_FOR_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingDriversForAvailability: false,
          driversForAvailability: action.drivers,
        },
      });

    case FAIL_LOAD_DRIVERS_FOR_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingDriversForAvailability: false,
          driversForAvailability: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });
    default:
      return state;
  }
};

// action creators
const startLoad = () => ({
  type: START_LOAD,
});
const completeLoad = (drivers: RoutePlannerRouteDriver[]) => ({
  type: COMPLETE_LOAD,
  drivers,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadDriversForAvailability = () => ({
  type: START_LOAD_DRIVERS_FOR_AVAILABILITY,
});

const completeLoadDriversForAvailability = (drivers: RoutePlannerRouteDriver[]) => ({
  type: COMPLETE_LOAD_DRIVERS_FOR_AVAILABILITY,
  drivers,
});

const failLoadDriversForAvailability = () => ({
  type: FAIL_LOAD_DRIVERS_FOR_AVAILABILITY,
});

export const resetRoutePlannerRouteDrivers = () => ({
  type: RESET,
});

// async action creators
export const loadRoutePlannerRouteDrivers = (params: RoutePlannerRoutesPayload) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePlannerDriversPromise = doLoadRoutePlannerRouteDrivers(params);

  loadRoutePlannerDriversPromise
    .then((drivers: RoutePlannerRouteDriver[]) => {
      dispatch(completeLoad(drivers));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return loadRoutePlannerDriversPromise;
};

export const loadRoutePlannerRouteDriversForAvailability =
  (params: RoutePlannerRoutesPayload) => (dispatch: Dispatch) => {
    dispatch(startLoadDriversForAvailability());
    const loadRoutePlannerDriversPromise = doLoadRoutePlannerRouteDrivers(params);

    loadRoutePlannerDriversPromise
      .then((drivers: RoutePlannerRouteDriver[]) => {
        dispatch(completeLoadDriversForAvailability(drivers));
      })
      .catch(() => {
        dispatch(failLoadDriversForAvailability());
      });

    return loadRoutePlannerDriversPromise;
  };
