import { map, uniqBy } from 'lodash-es';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';

import { Dropdown, TypedField } from 'src/core/components';
import { TypedFieldOnChangeFunction } from 'src/core/components/TypedField';
import { Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { dateFormat } from 'src/utils/services/validator';

export const TRAVEL_PATH_BUILDER_FILTERS_FORM = 'TRAVEL_PATH_BUILDER_FILTERS_FORM';

interface PropsWithoutReduxForm {
  routeTemplateId?: number;
}

export interface TravelPathBuilderFiltersFormValues {
  date?: string;
  route?: number;
  vehicle?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<TravelPathBuilderFiltersFormValues, PropsWithoutReduxForm>;

const TravelPathBuilderFiltersForm: FC<Props> = ({ routeTemplateId, change }) => {
  const { travelPathBuilderFilters, didDoAnyTravelPathBuilderTracing, isDoingTravelPathBuilderTracing } = useSelector(
    state => state.routes.travelPathBuildAndEdit,
  );
  const values = useSelector(getFormValues(TRAVEL_PATH_BUILDER_FILTERS_FORM)) as TravelPathBuilderFiltersFormValues;

  const dateOptions = useMemo(
    () =>
      map(travelPathBuilderFilters, f => ({
        label: moment(f.date).format(dateFormat),
        value: f.date,
      })),
    [travelPathBuilderFilters],
  );

  const routeOptions = useMemo(() => {
    const selectedDate = values?.date;
    const selectedDateFilters = travelPathBuilderFilters?.find(f => f.date === selectedDate);

    return uniqBy(
      map(selectedDateFilters?.routes, r => ({
        label: r.routeName,
        value: r.routeId,
      })),
      'value',
    );
  }, [travelPathBuilderFilters, values]);

  const vehicleOptions = useMemo(() => {
    const selectedDate = values?.date;
    const selectedDateFilters = travelPathBuilderFilters?.find(f => f.date === selectedDate);

    const selectedRoute = values?.route;
    const selectedRouteFilters = selectedDateFilters?.routes?.find(r => r.routeId === selectedRoute);

    return uniqBy(
      map(selectedRouteFilters?.vehicles, v => ({
        label: v.vehicleName,
        value: v.vehicleId,
      })),
      'value',
    );
  }, [travelPathBuilderFilters, values]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleDateChange: TypedFieldOnChangeFunction = useCallback(
    (date: any) => {
      // if for this date is only one route, select it
      const selectedDateFilters = travelPathBuilderFilters?.find(f => f.date === date);
      if (selectedDateFilters?.routes?.length === 1) {
        change('route', selectedDateFilters.routes[0].routeId);
        if (selectedDateFilters.routes[0].vehicles.length === 1)
          change('vehicle', selectedDateFilters.routes[0].vehicles[0].vehicleId);
        else change('vehicle', undefined);
      } else {
        change('route', undefined);
        change('vehicle', undefined);
      }
    },
    [change, travelPathBuilderFilters],
  );

  const handleRouteChange: TypedFieldOnChangeFunction = useCallback(
    (route: any) => {
      // if for this route is only one vehicle, select it
      const selectedDate = values?.date;
      const selectedDateFilters = travelPathBuilderFilters?.find(f => f.date === selectedDate);
      const selectedRouteFilters = selectedDateFilters?.routes?.find(r => r.routeId === route);
      if (selectedRouteFilters?.vehicles?.length === 1) change('vehicle', selectedRouteFilters.vehicles[0].vehicleId);
      else change('vehicle', undefined);
    },
    [change, travelPathBuilderFilters, values?.date],
  );

  useEffect(() => {
    // if there is only one date, select it
    if (travelPathBuilderFilters?.length === 1) {
      change('date', travelPathBuilderFilters[0]?.date);
      const selectedDateFilters = travelPathBuilderFilters[0];
      if (selectedDateFilters?.routes?.length === 1) {
        change('route', selectedDateFilters.routes[0].routeId);
        if (selectedDateFilters.routes[0].vehicles.length === 1)
          change('vehicle', selectedDateFilters.routes[0].vehicles[0].vehicleId);
        else change('vehicle', undefined);
      } else {
        change('route', undefined);
        change('vehicle', undefined);
      }
    }
  }, [change, handleDateChange, travelPathBuilderFilters]);

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="xxSmall xSmall no xSmall" withTopInnerBoxShadow>
        <Grid>
          <GridColumn margin="no" size="2/12">
            <TypedField
              name="date"
              component={Dropdown}
              props={{
                label: translate('common.date'),
                options: dateOptions,
                menuPosition: 'fixed',
                disabled: didDoAnyTravelPathBuilderTracing || isDoingTravelPathBuilderTracing,
              }}
              onChange={handleDateChange}
            />
          </GridColumn>
          <GridColumn margin="no" size="3/12">
            <TypedField
              name="route"
              component={Dropdown}
              props={{
                label: translate('routes.routeName'),
                disabled: !values?.date || didDoAnyTravelPathBuilderTracing || isDoingTravelPathBuilderTracing,
                options: routeOptions,
                menuPosition: 'fixed',
              }}
              onChange={handleRouteChange}
            />
          </GridColumn>
          <GridColumn margin="no" size="3/12">
            <TypedField
              name="vehicle"
              component={Dropdown}
              props={{
                label: translate('common.vehicle'),
                options: vehicleOptions,
                disabled: !values?.route || didDoAnyTravelPathBuilderTracing || isDoingTravelPathBuilderTracing,
                menuPosition: 'fixed',
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default reduxForm<TravelPathBuilderFiltersFormValues, PropsWithoutReduxForm>({
  form: TRAVEL_PATH_BUILDER_FILTERS_FORM,
  enableReinitialize: true,
})(TravelPathBuilderFiltersForm);
