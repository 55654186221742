import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import moment from 'moment';

import { AppState } from 'src/store';
import { CompletedServiceConfirmationForm } from '../forms';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from 'src/common/ducks';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { Modal } from '../../../core/components';
import { RouteComponentProps } from 'react-router';
import { responseMissedPickup } from 'src/fleet/ducks/openDispatches';
import { updateServiceConfirmation } from 'src/fleet/ducks';
import translate from '../../../core/services/translate';

type RouteParams = {
  token: string;
};

interface Props extends RouteComponentProps<RouteParams> {
  itemId: number;
  completedDate?: Date | string;
  isRollOff?: boolean;
  isSaving?: boolean;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  pickupTypeId: number;
  reasonCode?: string;
  rubiconPONbr: string;
  serviceDate: Date | string;
  status: string;
  responseMissedPickup: DuckFunction<typeof responseMissedPickup>;
  updateServiceConfirmation: DuckFunction<typeof updateServiceConfirmation>;
  userId: string;
  vendID?: string;
  vendorId: number;
  weightTicket?: number;
  mobileVersion?: boolean;
}

class CompletedServiceConfirmationModal extends PureComponent<Props> {
  onSubmit = async () => {
    const {
      itemId,
      completedDate,
      loadDispatchOpportunitiesCount,
      location: { search },
      match: {
        params: { token },
      },
      onCancel,
      pickupTypeId,
      rubiconPONbr,
      serviceDate,
      status,
      userId,
      vendID,
      vendorId,
      weightTicket,
      responseMissedPickup,
      updateServiceConfirmation,
    } = this.props;

    const { dispatchStatus, startDate, endDate, workOrder, workOrderType, page, limit } = getQueryParams(search);
    const searchFilter = { dispatchStatus, startDate, endDate, workOrder, workOrderType, page, limit };
    const action = 'Completed';
    const responseType = 'SCHEDULED';
    const uom = 1;
    const weitghtTicketUom = undefined;

    const promise =
      pickupTypeId === MISSED_PICKUP && !!itemId
        ? responseMissedPickup(
            itemId,
            responseType,
            completedDate ? moment(completedDate).format('YYYY-MM-DD') : '',
            null,
            userId,
            token,
            vendorId,
            undefined,
          )
        : updateServiceConfirmation(
            rubiconPONbr,
            status,
            null,
            null,
            moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
            action,
            weightTicket,
            uom,
            userId,
            token,
            vendorId,
            vendID,
            weitghtTicketUom,
            searchFilter,
            moment(completedDate).format('YYYY-MM-DD HH:mm:ss'),
          );

    promise &&
      promise
        .then(() => {
          const isReload = true;
          loadDispatchOpportunitiesCount(userId, vendorId, token);
          createSuccessNotification(translate('opportunity.opportunitySave'));
          onCancel(true, isReload);
        })
        .catch(() => {
          createErrorNotification(translate('opportunity.opportunitySaveError'));
        });
  };

  render() {
    const { isSaving, mobileVersion } = this.props;

    return (
      <Modal
        overflow={mobileVersion ? 'visible' : undefined}
        title={translate('common.completed')}
        size={mobileVersion ? undefined : 'small'}
        minHeight={mobileVersion ? undefined : '415px'}
        isLoading={isSaving}
      >
        <Grid centered>
          <GridColumn size={mobileVersion ? '8/19' : '12/12'}>
            <CompletedServiceConfirmationForm {...this.props} onSubmit={this.onSubmit} />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const formSelector = formValueSelector('completedServiceConfirmation');

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  weightTicket: formSelector(
    state,
    `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.weightTicket`,
  ),
  date: formSelector(
    state,
    `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.requestedServiceDate`,
  ),
  completedDate: formSelector(
    state,
    `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.completedDate`,
  ),
  serviceDate: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.serviceDate`),
  isSaving: state.fleet.openDispatches.isSaving,
});

export default connect(mapStateToProps)(CompletedServiceConfirmationModal);
