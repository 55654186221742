import moment from 'moment';
import { HeaderProps } from 'react-big-calendar';
import { Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { CalendarWeekHeaderWrapper } from 'src/routes/components/styled';

const CalendarWeekHeader =
  ({ selectedDate }: { selectedDate?: Date }) =>
  ({ date }: HeaderProps) => {
    return (
      <CalendarWeekHeaderWrapper isSelected={!!selectedDate && moment(date).isSame(selectedDate, 'day')}>
        <Text size="large" weight="medium">
          {moment(date).format('dddd')}
        </Text>
        <Box>
          <Text color="grayDarker">{`${moment(date).format('M')} / `}</Text>
          <Text color="primary">{`${moment(date).format('D')}`}</Text>
        </Box>
      </CalendarWeekHeaderWrapper>
    );
  };

export default CalendarWeekHeader;
