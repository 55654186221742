import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ACCOUNT_STATUSES, ROUTE_PICKUP_TYPE_IDS } from '../../../constants';
import { TABLE_ROW_HEIGHT } from '../../../../core/constants';
import {
  Container,
  TableActionButton,
  TableCell,
  TableRow,
  TableRowDraggable,
  Text,
} from '../../../../core/components/styled';
import {
  ActionButtonTooltip,
  DragHandle,
  UnconnectedCheckbox,
  UnconnectedDaysOfWeekPicker,
  UnconnectedSelect,
} from '../../../../core/components';
import { AppState } from '../../../../store';
import { DropdownOption } from '../../../../core/components/Dropdown';

interface Props {
  accountStatusId: number;
  customer: any;
  deleteRouteTemplateLocation: (event: any, orderNumber: number, serviceContractRouteTemplateId: number) => void;
  editService: (event: any, serviceId: number, locationId: number, customerId: number) => void;
  enablePickupType: boolean;
  isChecked?: boolean;
  location: any;
  newOptimizedRouteLocationsLength: number;
  onRoutePickupTypeChange: (value: any, serviceContractRouteTemplateId: number) => void;
  openOrderNumberPopover: (orderNumber: number, event: any, serviceContractRouteTemplateId: number) => void;
  orderNumber: number;
  pickupTypeId: number;
  pickupTypes: DropdownOption[];
  scheduledDays: string[];
  selectLocation: (routeTemplateLocation: any, event: any) => void;
  selectRouteTemplateLocation: (locationId: number) => void;
  service: any;
  serviceContractRouteTemplateId: number;
}

const RouteLocationsWithZeroStopTableRow: React.SFC<Props> = ({
  accountStatusId,
  customer,
  deleteRouteTemplateLocation,
  editService,
  enablePickupType,
  isChecked,
  location: {
    address: { street, streetNumber, latitude, longitude },
    id: locationId,
    name: locationName,
  },
  newOptimizedRouteLocationsLength,
  onRoutePickupTypeChange,
  openOrderNumberPopover,
  orderNumber,
  pickupTypeId,
  pickupTypes,
  scheduledDays,
  selectLocation,
  selectRouteTemplateLocation,
  service,
  serviceContractRouteTemplateId,
}) => {
  const routeTemplateLocation = { latitude, longitude, orderNumber };

  const handleDragStart = (serviceContractRouteTemplateId: number) => (event: any) => {
    const dt = event.nativeEvent.dataTransfer;
    dt.setData('routeLocationId', serviceContractRouteTemplateId);
  };

  return (
    <TableRow
      position="relative"
      height={TABLE_ROW_HEIGHT}
      onClick={event => selectLocation(routeTemplateLocation, event)}
      id={`stop-${locationId}`}
    >
      <TableRowDraggable draggable onDragStart={handleDragStart(serviceContractRouteTemplateId)} />
      <TableCell width="5%" position="relative">
        {!newOptimizedRouteLocationsLength && (
          <UnconnectedCheckbox
            block
            checked={isChecked}
            onChange={() => selectRouteTemplateLocation(serviceContractRouteTemplateId)}
            id={`stop-${locationId}-checkbox`}
            size="small"
          />
        )}
      </TableCell>

      <TableCell width="5%">
        {!newOptimizedRouteLocationsLength && <DragHandle id={`stop-${locationId}-drag-handle`} />}
      </TableCell>

      <TableCell width="7%">
        {!newOptimizedRouteLocationsLength && (
          <TableActionButton
            onClick={event => openOrderNumberPopover(orderNumber, event, serviceContractRouteTemplateId)}
            id={`stop-${locationId}-reorder-button`}
          >
            {orderNumber}
          </TableActionButton>
        )}
      </TableCell>

      <TableCell width={enablePickupType ? '17%' : '20%'} vertical>
        <Text block weight="medium" margin="no no xxSmall" singleLine id={`stop-${locationId}-customer`}>
          {customer.name}
        </Text>

        <Text weight="light" size="small" margin="no no xxSmall" singleLine id={`stop-${locationId}-location`}>
          {locationName}, {streetNumber} {street}
        </Text>
      </TableCell>

      <TableCell width={enablePickupType ? '9%' : '13%'} id={`stop-${locationId}-account-status`}>
        {(!!accountStatusId && ACCOUNT_STATUSES[accountStatusId].name) || '-'}
      </TableCell>

      <TableCell width={enablePickupType ? '17%' : '20%'}>
        <Text block singleLine title={service && service.name} id={`stop-${locationId}-service`}>
          {service && service.name}
        </Text>
      </TableCell>

      <TableCell width={enablePickupType ? '17%' : '20%'} id={`stop-${locationId}-day-of-service`}>
        {
          <UnconnectedDaysOfWeekPicker
            dayOfWeekProps={{ margin: "no xSmall no no'" }}
            daysOfWeekProps={{ margin: 'no' }}
            isReadOnly
            multiple
            selectedWeekdays={scheduledDays}
          />
        }
      </TableCell>

      {enablePickupType && (
        <TableCell width={'13%'}>
          <Container>
            <UnconnectedSelect
              value={pickupTypeId || ''}
              options={pickupTypes}
              margin="no"
              onClick={(event: Event) => event.stopPropagation()}
              onChange={(value: any) => onRoutePickupTypeChange(value, serviceContractRouteTemplateId)}
            />
          </Container>
        </TableCell>
      )}

      <TableCell width="10%">
        {!newOptimizedRouteLocationsLength && (
          <TableActionButton
            onClick={event => deleteRouteTemplateLocation(event, orderNumber, serviceContractRouteTemplateId)}
            id={`stop-${locationId}-delete-button`}
          >
            <ActionButtonTooltip icon="delete" tooltip="deleteLocation" />
          </TableActionButton>
        )}

        {!newOptimizedRouteLocationsLength && (
          <TableActionButton id={`stop-${locationId}-edit-service-button`}>
            <Link
              id="edit-service-link"
              color="grayDark"
              to={`/customers/customers/${customer.id}/location/${locationId}/service/${service.id}`}
            >
              <ActionButtonTooltip icon="edit" tooltip="editService" />
            </Link>
          </TableActionButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state: AppState) => {
  const statePickupTypes = state.routes.pickupTypes.pickupTypes as any;
  let pickupTypes = [];
  if (statePickupTypes)
    pickupTypes = statePickupTypes[0].pickupTypes.map((pickupType: any) => ({
      label: ROUTE_PICKUP_TYPE_IDS[pickupType.id].name,
      value: pickupType.id,
    }));
  return {
    pickupTypes: pickupTypes,
  };
};

export default connect(mapStateToProps)(RouteLocationsWithZeroStopTableRow);
