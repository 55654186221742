import styled, { css } from 'styled-components';

export const CollapsibleContent = styled.div``;

export const Collapsible = styled.div<{ isOpen?: boolean }>`
  transition: height 0.3s ease-out, overflow 0.3s ease-out;

  ${props =>
    !props.isOpen &&
    css`
      overflow: hidden;
    `};
`;
