import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelLogo, AccountPanelTitle, AccountPanelDescription } from '../styled';
import { isRequired, isEmail } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

const RequestPasswordResetForm: React.FC<InjectedFormProps> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelLogo />
    <AccountPanelTitle>{translate('account.forgotYourPassword')}</AccountPanelTitle>
    <AccountPanelDescription>{translate('account.enterEmailToResetPassword')}</AccountPanelDescription>

    <Field
      name="email"
      component={Input}
      label={translate('common.email')}
      type="text"
      validate={[isRequired, isEmail]}
    />

    <ButtonSet>
      <Button type="submit" color="primary">
        {translate('common.send')}
      </Button>
    </ButtonSet>
  </form>
);

export default reduxForm({
  form: 'requestPasswordReset',
  onSubmitFail: focusFirstInvalidField,
})(RequestPasswordResetForm);
