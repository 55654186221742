import React, { useEffect, useState } from 'react';

import { CompactPicker } from 'react-color';
import { Range, createSliderWithTooltip } from 'rc-slider';
import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import styled, { css } from 'styled-components';

import {
  changeDebugVehicleColor,
  changeMobileTrapezoidFilters,
  changeRawTrapezoidFilters,
  filterMobileTrackingsByVehicleId,
  filterRawTrackingsByVehicleId,
  isVehicleConfirmationPolygonSupported,
  loadMobileVehicleTrackings,
  loadRawVehicleTrackings,
  loadVehicleSettings,
  resetRouteMapDebug,
  showMobileVehicleTrackings,
  showRawVehicleTrackings,
  showVehiclePolygon,
} from 'src/routes/ducks/routeMapDebug';
import { UnconnectedCheckbox, UnconnectedSwitch } from 'src/core/components';
import translate from 'src/core/services/translate';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { VehicleSettings, VehicleTrackingRaw } from 'src/routes/components/mapWithTimeline/Interfaces';
import { timeStamp } from 'src/utils/services/formatter';
import { AppState } from 'src/store';
import { DragHandleIcon } from 'src/core/components/styled';

interface Props {
  geoFenceIsActive: boolean;
  routeId: number;
}

const UseDebugMapContainer = styled.div<{ geoFenceIsActive?: boolean }>`
  position: absolute;
  z-index: 2001;
  right: 20px;
  top: 20px;
  padding: 5px;
  background: white;

  ${p =>
    p.geoFenceIsActive &&
    css`
      right: 70px;
    `};

  *:focus {
    outline: none;
  }
`;

const Color = styled.div<{ color: string }>`
  width: 10px;
  background: ${p => p.color};
  cursor: pointer;
  border: 1px solid black;
`;

const FiltersContainer = styled(UseDebugMapContainer)`
  padding: 0;
  border: 2px dashed ${p => p.theme.grayDark};
  box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.73);
`;

const DragHandle = styled.div`
  background: ${p => p.theme.brandPrimary};
  cursor: move;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VehiclesContainer = styled.div`
  margin-left: 10px;
  margin-top: 10px;
`;

const VehicleContainer = styled.div`
  position: relative;
  display: flex;
  padding: 10px 0;
  & > * {
    margin-right: 10px;
    margin-top: 10px;
  }
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ColorPickerContainer = styled.div`
  z-index: 2002;
  position: absolute;
  left: 13px;
`;

const DebugSectionFilterContainer = styled.div`
  border: 1px solid ${p => p.theme.brandPrimary};
  margin: 10px;
  padding: 10px;
`;

const VehicleFiltersContainer = styled.div`
  flex: 1;
  & > * {
    margin-top: 5px;
  }

  .rc-slider-tooltip {
    white-space: nowrap;
  }
`;

const TrapezoidRange = createSliderWithTooltip(Range);

interface DebugVehicleTrackingFilterSectionProps {
  changeVehicleColor: (vehicleId: string, color: string) => void;
  colors: Dictionary<string>;
  enableVehicleTracking: () => void;
  filteredVehicleIds: Dictionary<boolean>;
  isEnabled: boolean;
  label: string;
  onChangeTrapezoidFilters: (vehicleId: string, filterValues: number[]) => void;
  onLoadVehicleSettings: (vehicleId: string) => void;
  onVehicleFilterChange: (vehicleId: string) => void;
  showVehicleSettings: Dictionary<boolean>;
  vehicleIds?: string[];
  vehicleNames: Dictionary<string>;
  vehicleSettings: Dictionary<VehicleSettings>;
  vehicleTrackingTrapezoidFilters: Dictionary<number[]>;
  vehicleTrackingTrapezoids: Dictionary<VehicleTrackingRaw[]>;
  hidePolygons?: boolean;
}

const DebugVehicleTrackingFilterSection: React.FC<DebugVehicleTrackingFilterSectionProps> = ({
  changeVehicleColor,
  colors,
  enableVehicleTracking,
  filteredVehicleIds,
  hidePolygons,
  isEnabled,
  label,
  onChangeTrapezoidFilters,
  onLoadVehicleSettings,
  onVehicleFilterChange,
  showVehicleSettings,
  vehicleIds,
  vehicleNames,
  vehicleSettings,
  vehicleTrackingTrapezoidFilters,
  vehicleTrackingTrapezoids,
}) => {
  const [colorPickerVehicleId, setShowColorPickerVehicleId] = useState<string>();
  return (
    <>
      <DebugSectionFilterContainer>
        <UnconnectedSwitch onChange={enableVehicleTracking} checked={isEnabled} label={label} />
        <VehiclesContainer>
          {vehicleIds && (
            <>
              {!vehicleIds.length && translate('maps.debug.noResultsFound')}
              {vehicleIds!.map(id => (
                <VehicleContainer key={id}>
                  <Color onClick={() => setShowColorPickerVehicleId(id)} color={colors[id]}></Color>
                  {colorPickerVehicleId === id && (
                    <ColorPickerContainer>
                      <CompactPicker
                        onChange={color => {
                          changeVehicleColor(id, color.hex);
                          setShowColorPickerVehicleId(undefined);
                        }}
                      />
                    </ColorPickerContainer>
                  )}
                  <VehicleFiltersContainer>
                    <UnconnectedCheckbox
                      checked={filteredVehicleIds[id]}
                      key={id}
                      label={`${vehicleNames[id]} (${id.toString()})`}
                      onChange={() => onVehicleFilterChange(id)}
                    />
                    {!hidePolygons && (
                      <>
                        {!vehicleSettings[id] || isVehicleConfirmationPolygonSupported(vehicleSettings[id]) ? (
                          <>
                            <UnconnectedSwitch
                              onChange={() => onLoadVehicleSettings(id)}
                              checked={showVehicleSettings[id]}
                              label={translate('maps.debug.showPolygonConfirmation')}
                              disabled={!filteredVehicleIds[id]}
                            />
                            {vehicleTrackingTrapezoids[id] && !!vehicleTrackingTrapezoids[id].length && (
                              <TrapezoidRange
                                disabled={!showVehicleSettings[id] || !filteredVehicleIds[id]}
                                min={0}
                                max={vehicleTrackingTrapezoids[id].length - 1}
                                value={vehicleTrackingTrapezoidFilters[id]}
                                defaultValue={vehicleTrackingTrapezoidFilters[id]}
                                onChange={values => onChangeTrapezoidFilters(id, values)}
                                tipFormatter={(value: number) =>
                                  vehicleTrackingTrapezoids[id][value] &&
                                  `${timeStamp(vehicleTrackingTrapezoids[id][value].reportedDate)}`
                                }
                              />
                            )}
                          </>
                        ) : (
                          <div>{translate('maps.debug.polygonNotSupported')}</div>
                        )}
                      </>
                    )}
                  </VehicleFiltersContainer>
                </VehicleContainer>
              ))}
            </>
          )}
        </VehiclesContainer>
      </DebugSectionFilterContainer>
    </>
  );
};

export default function RouteMapDebug({ geoFenceIsActive, routeId }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => resetRouteMapDebug()(dispatch);
  }, [dispatch]);

  const mobileVehicleTrackings = useSelector((state: AppState) => state.routes.routeMapDebug.mobileVehicleTrackings);
  const rawVehicleTrackings = useSelector((state: AppState) => state.routes.routeMapDebug.rawVehicleTrackings);
  const rawVehicleTrackingsVisible = useSelector((state: AppState) => state.routes.routeMapDebug.showRawTrackings);
  const vehicleSettings = useSelector((state: AppState) => state.routes.routeMapDebug.vehicleSettings);
  const showVehicleSettings = useSelector((state: AppState) => state.routes.routeMapDebug.showVehicleSettings);
  const vehicleNames = useSelector((state: AppState) => state.routes.routeMapDebug.vehicleNames);
  const colors = useSelector((state: AppState) => state.routes.routeMapDebug.vehicleTrackingColors);

  const rawVehicleTrackingTrapezoids = useSelector(
    (state: AppState) => state.routes.routeMapDebug.rawVehicleTrackingTrapezoids,
  );
  const mobileVehicleTrackingTrapezoids = useSelector(
    (state: AppState) => state.routes.routeMapDebug.mobileVehicleTrackingTrapezoids,
  );

  const rawVehicleTrackingTrapezoidFilters = useSelector(
    (state: AppState) => state.routes.routeMapDebug.rawVehicleTrackingTrapezoidFilters,
  );
  const mobileVehicleTrackingTrapezoidFilters = useSelector(
    (state: AppState) => state.routes.routeMapDebug.mobileVehicleTrackingTrapezoidFilters,
  );

  const mobileVehicleTrackingsVisible = useSelector(
    (state: AppState) => state.routes.routeMapDebug.showMobileTrackings,
  );

  const filteredRawTrackingsVehicleIds = useSelector(
    (state: AppState) => state.routes.routeMapDebug.rawTrackingsVehicleIds,
  );
  const filteredMobileTrackingsVehicleIds = useSelector(
    (state: AppState) => state.routes.routeMapDebug.mobileTrackingsVehicleIds,
  );

  return (
    <>
      <UseDebugMapContainer geoFenceIsActive={geoFenceIsActive}>
        <Draggable bounds={{ top: -480 }} handle="#debugDragHandle">
          <FiltersContainer>
            <DragHandle id="debugDragHandle">
              <DragHandleIcon cursor="drag" />
            </DragHandle>
            <Resizable minWidth="500px" minHeight="300px">
              <DebugVehicleTrackingFilterSection
                hidePolygons
                key="raw"
                label={translate('maps.debug.rawVehicleTrackings')}
                isEnabled={rawVehicleTrackingsVisible}
                enableVehicleTracking={() => {
                  if (!rawVehicleTrackings) loadRawVehicleTrackings(routeId)(dispatch);
                  else showRawVehicleTrackings()(dispatch);
                }}
                vehicleIds={rawVehicleTrackings ? Object.keys(rawVehicleTrackings) : undefined}
                vehicleSettings={vehicleSettings}
                colors={colors}
                filteredVehicleIds={filteredRawTrackingsVehicleIds}
                vehicleNames={vehicleNames}
                onVehicleFilterChange={vehicleId => filterRawTrackingsByVehicleId(vehicleId)(dispatch)}
                onChangeTrapezoidFilters={(vehicleId, values) => {
                  changeRawTrapezoidFilters(vehicleId, values)(dispatch);
                }}
                onLoadVehicleSettings={vehicleId => {
                  if (!vehicleSettings[vehicleId]) loadVehicleSettings(vehicleId)(dispatch);
                  else showVehiclePolygon(vehicleId)(dispatch);
                }}
                vehicleTrackingTrapezoidFilters={rawVehicleTrackingTrapezoidFilters}
                vehicleTrackingTrapezoids={rawVehicleTrackingTrapezoids}
                showVehicleSettings={showVehicleSettings}
                changeVehicleColor={(vehicleId, color) => changeDebugVehicleColor(vehicleId, color)(dispatch)}
              />
              <DebugVehicleTrackingFilterSection
                key="mobile"
                vehicleSettings={vehicleSettings}
                label={translate('maps.debug.mobileVehicleTrackings')}
                isEnabled={mobileVehicleTrackingsVisible}
                enableVehicleTracking={() => {
                  if (!mobileVehicleTrackings) loadMobileVehicleTrackings(routeId)(dispatch);
                  else showMobileVehicleTrackings()(dispatch);
                }}
                vehicleIds={mobileVehicleTrackings ? Object.keys(mobileVehicleTrackings) : undefined}
                colors={colors}
                filteredVehicleIds={filteredMobileTrackingsVehicleIds}
                vehicleNames={vehicleNames}
                onVehicleFilterChange={vehicleId => filterMobileTrackingsByVehicleId(vehicleId)(dispatch)}
                onChangeTrapezoidFilters={(vehicleId, values) => {
                  changeMobileTrapezoidFilters(vehicleId, values)(dispatch);
                }}
                onLoadVehicleSettings={vehicleId => {
                  if (!vehicleSettings[vehicleId]) loadVehicleSettings(vehicleId)(dispatch);
                  else showVehiclePolygon(vehicleId)(dispatch);
                }}
                vehicleTrackingTrapezoidFilters={mobileVehicleTrackingTrapezoidFilters}
                vehicleTrackingTrapezoids={mobileVehicleTrackingTrapezoids}
                showVehicleSettings={showVehicleSettings}
                changeVehicleColor={(vehicleId, color) => changeDebugVehicleColor(vehicleId, color)(dispatch)}
              />
            </Resizable>
          </FiltersContainer>
        </Draggable>
      </UseDebugMapContainer>
    </>
  );
}
