import React, { PureComponent } from 'react';

import { DocumentUploadForm } from '../forms';
import { Grid, GridColumn } from '../../../core/components/styled';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

type Props = {
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  itemId: number;
  pickupTypeId: number;
};

class DocumentUploadModal extends PureComponent<Props> {
  onSubmit = async () => {};
  render() {
    const { itemId, pickupTypeId } = this.props;
    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <Modal
            title={
              pickupTypeId === MISSED_PICKUP && itemId
                ? translate('autoDispatch.confirmService')
                : translate('autoDispatch.confirmServiceUploadDocument')
            }
            size={isMobile ? undefined : 'small'}
          >
            <Grid centered>
              <GridColumn size={isMobile ? '12/12' : '10/12'}>
                <DocumentUploadForm {...(this.props as any)} onSubmit={this.onSubmit} />
              </GridColumn>
            </Grid>
          </Modal>
        )}
      />
    );
  }
}

export default DocumentUploadModal;
