import { DragEvent, Fragment, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { createSelector } from 'reselect';
import { identity, sum, take } from 'lodash-es';
import { SortableContainer } from 'react-sortable-hoc';
import { VariableSizeList as List } from 'react-window';

import { COMPLETED, ISSUE_REPORTED, SCHEDULED, PICKUP_STATUSES } from 'src/common/constants';
import { DispatchBoardRouteJob } from '../../../../interfaces/DispatchBoardRouteJob';
import { DispatchBoardScrollMarker } from '../../../styled';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { JobTypes, SELECTED_TRANSFERRED_STOPS } from '../../../../constants';
import { JobStatusModalResolver } from '../../../../../routes/components/modals';
import { ModalTypes } from './common/ModalContainer';
import { OrderNumberForm } from '../../../../../routes/components/forms';
import { REACT_WINDOW_CLASS_NAME } from '../../../../../core/constants/reactWindow';
import { sendJobSelectionNotifications, getMultiSelectedJobs } from './common/utils';
import { SortableDispatchBoardJob } from './DispatchBoardJob';
import { TABLE_ROW_HEIGHT_EXPANDED_LARGE } from 'src/core/constants/table';
import {
  TOP,
  TABLE_ROW_HEIGHT_LARGER,
  TABLE_ROW_HEIGHT_EXPANDED,
  TABLE_ROW_MAX_ITEMS,
} from '../../../../../core/constants';
import createPopover from '../../../../../core/services/createPopover';
import DispatchBoardDroppableList from './DispatchBoardDroppableList';
import { UNASSIGNED_ROUTE_ID } from 'src/routes/constants/dispatchBoard';

const SortableList = SortableContainer(
  ({ containerHeight, getElementSize, jobs, listRef, filteredJobs, itemData }: any) => (
    <List
      className={REACT_WINDOW_CLASS_NAME}
      height={containerHeight || 1}
      itemCount={filteredJobs.length}
      itemData={itemData}
      itemSize={getElementSize}
      ref={listRef}
      style={jobs.length > TABLE_ROW_MAX_ITEMS - 1 ? { paddingBottom: '60px' } : undefined}
      width="100%"
    >
      {DispatchBoardJob}
    </List>
  ),
);

const DispatchBoardJob = ({ style, index, data }: any) => {
  const {
    deleteJob,
    handleDragStart,
    handleJobSelection,
    jobs,
    onJobDrop,
    openedJobId,
    openJobStatusModal,
    openOrderNumberPopover,
    optimizedJobsLength,
    route,
    routeIsFiltered,
    selectedJobs,
    toggleJob,
    toggleModal,
    type,
  } = data;
  const job = jobs[index];

  return (
    <div style={style}>
      <SortableDispatchBoardJob
        deleteJob={deleteJob}
        draggable={job.isDraggable}
        index={index}
        isExpanded={job.id === openedJobId}
        isSelected={selectedJobs.indexOf(index) > -1}
        job={job}
        jobIndex={type !== JobTypes.unassigned ? job.orderNo - 1 : index}
        key={job.id}
        onClick={handleJobSelection(index, job.isDraggable)}
        onDragStart={handleDragStart(job.id, job.serviceContractId, type)}
        onJobDrop={onJobDrop}
        openJobStatusModal={openJobStatusModal}
        openOrderNumberPopover={openOrderNumberPopover}
        optimizedJobsLength={optimizedJobsLength}
        route={route}
        routeIsFiltered={routeIsFiltered}
        toggleJob={toggleJob}
        toggleModal={toggleModal}
        type={type}
      />
    </div>
  );
};

const containerHeightSelector = createSelector(
  elementHeights => sum(take(elementHeights as any, TABLE_ROW_MAX_ITEMS)),
  identity,
);

interface Props {
  deleteJob: (routeId: number, jobId: number) => void;
  jobs: any[];
  jobsTransferring: boolean;
  loadOppositeRouteJobs: (routeId: number, searchTerm?: string) => void;
  loadRouteJobs: (routeId: number, searchTerm?: string) => void;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
  onSortEnd: (value: any) => void;
  openedJobId?: number;
  optimizedJobsLength: number;
  route?: any;
  routeIsFiltered: boolean;
  searchTerm?: string;
  sortChanged: (index: any) => void;
  targetRouteId?: number;
  toggleJob: (e: MouseEvent, jobId: number) => void;
  toggleJobTransferring: (value: boolean) => void;
  toggleModal: (modal: ModalTypes, open: boolean, isHeader: boolean) => void;
  type: string;
  vehicleTypeId: number;
}

const DispatchBoardJobs = (props: Props) => {
  const {
    deleteJob,
    jobs,
    jobsTransferring,
    loadOppositeRouteJobs,
    loadRouteJobs,
    onJobDrop,
    onSortEnd,
    openedJobId,
    optimizedJobsLength,
    route,
    routeIsFiltered,
    searchTerm,
    sortChanged,
    targetRouteId,
    toggleJob,
    toggleJobTransferring,
    toggleModal,
    type,
    vehicleTypeId,
  } = props;

  const [selectedJob, setSelectedJob] = useState(undefined);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [isJobStatusModalOpen, setIsJobStatusModalOpen] = useState(false);

  let closePopover: any;

  const listRef = useRef();

  useEffect(() => {
    if (listRef.current) {
      (listRef.current as any).resetAfterIndex(0, true);
    }
  }, [openedJobId, jobs.length]);

  const getElementSize = (index: number) =>
    jobs[index].id === openedJobId
      ? !!jobs[index].containerNumber || jobs[index].isTemporaryContainer
        ? TABLE_ROW_HEIGHT_EXPANDED_LARGE
        : TABLE_ROW_HEIGHT_EXPANDED
      : TABLE_ROW_HEIGHT_LARGER;

  useEffect(() => {
    if (jobsTransferring === false) {
      // clear selected jobs when transfer is complete
      setSelectedJobs([]);
    }
  }, [jobsTransferring]);

  useEffect(() => {
    // clear selected jobs when search term changes
    setSelectedJobs([]);
  }, [searchTerm]);

  const closeJobStatusModal = ({ saveInProgress }: any) => {
    setIsJobStatusModalOpen(false);
    setSelectedJob(undefined);

    if (saveInProgress) {
      loadRouteJobs(route.id, searchTerm);
      if (targetRouteId === route.id) {
        loadOppositeRouteJobs(route.id, searchTerm);
      }
    }
  };

  const onOrderNumberFormSubmit = (currentOrderNumber: number, newOrderNumber: number) => {
    sortChanged({ oldIndex: currentOrderNumber, newIndex: newOrderNumber - 1 });
    closePopover();
  };

  const openJobStatusModal = (jobId: number) => {
    let selectedJobFound;

    if (route.statusId !== SCHEDULED) {
      selectedJobFound = jobs.find(job => job.id === jobId);
      setIsJobStatusModalOpen(true);
      setSelectedJob(selectedJobFound);
    }
  };

  const optimalRouteStopLength = jobs?.filter(jobs => jobs.orderNo === JOB_PENDING_OPTIMIZATION_ID).length || 0;

  const openOrderNumberPopover = (currentOrderNumber: number, event: KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    closePopover = createPopover(
      event.currentTarget,
      OrderNumberForm,
      {
        onSubmit: ({ orderNumber }: any) => onOrderNumberFormSubmit(currentOrderNumber, Number(orderNumber)),
        maxOrderNumber: jobs.filter(job => job.orderNo > 0).length,
        initialValues: { orderNumber: currentOrderNumber + 1 - optimalRouteStopLength },
      },
      { position: TOP },
    );
  };

  const handleJobSelection = (index: number, canSelect: boolean) => (e: any) => {
    const multiselectedJobs = getMultiSelectedJobs(index, selectedJobs, SELECTED_TRANSFERRED_STOPS, e, canSelect);

    setSelectedJobs(multiselectedJobs as any);
    sendJobSelectionNotifications(multiselectedJobs.length, SELECTED_TRANSFERRED_STOPS);
  };

  const handleDragStart = (jobId: number, serviceContractId: number, type?: string) => (event: DragEvent) => {
    const dt = event.nativeEvent.dataTransfer;
    const jobsSelected = [];

    if (selectedJobs.length) {
      selectedJobs.forEach(jobIndex => {
        jobsSelected.push({ jobId: jobs[jobIndex].id, serviceContractId: jobs[jobIndex].serviceContractId });
      });
    } else {
      jobsSelected.push({ jobId, serviceContractId });
    }

    dt?.setData('routeId', route.id);
    dt?.setData('jobsSelected', JSON.stringify(jobsSelected));
    dt?.setData('type', type || '');

    toggleJobTransferring(true);
  };

  const containerHeight = containerHeightSelector(
    jobs.map(j =>
      j.id === openedJobId
        ? !!j.containerNumber || j.isTemporaryContainer
          ? TABLE_ROW_HEIGHT_EXPANDED_LARGE
          : TABLE_ROW_HEIGHT_EXPANDED
        : TABLE_ROW_HEIGHT_LARGER,
    ),
  );

  const maxHeight = TABLE_ROW_MAX_ITEMS * TABLE_ROW_HEIGHT_LARGER;

  const filteredJobs = jobs.map(job => {
    const isDraggable =
      job.jobStatusId !== PICKUP_STATUSES[COMPLETED].id && job.jobStatusId !== PICKUP_STATUSES[ISSUE_REPORTED].id;
    return { ...job, isDraggable };
  });

  return (
    <Fragment>
      <DispatchBoardDroppableList
        maxHeight={containerHeight && containerHeight >= maxHeight ? containerHeight + 29 : maxHeight}
        routeId={Number((route && route.id) || UNASSIGNED_ROUTE_ID)}
        onJobDrop={onJobDrop}
        insertAtIndex={jobs.length + 1}
      >
        <SortableList
          containerHeight={containerHeight && containerHeight + 29}
          filteredJobs={filteredJobs}
          getElementSize={getElementSize}
          itemData={{
            deleteJob,
            handleDragStart,
            handleJobSelection,
            jobs: filteredJobs,
            onJobDrop,
            openedJobId,
            openJobStatusModal,
            openOrderNumberPopover,
            optimizedJobsLength,
            route,
            routeIsFiltered,
            selectedJobs,
            toggleJob,
            toggleModal,
            type,
          }}
          jobs={jobs}
          listRef={listRef}
          lockAxis="y"
          onSortEnd={onSortEnd}
          useDragHandle
        />
        {jobs.length > TABLE_ROW_MAX_ITEMS - 1 && <DispatchBoardScrollMarker />}
      </DispatchBoardDroppableList>

      {isJobStatusModalOpen && (
        <JobStatusModalResolver
          closeModal={closeJobStatusModal}
          routeId={route.id}
          routeLocation={selectedJob}
          routeLocationInfo={{
            vehicleTypeId,
            jobStatusId: (selectedJob as any).jobStatusId,
            equipmentTypeNameSize: `${(selectedJob as any).containerTypeName} / ${
              (selectedJob as any).containerSizeCode
            }`,
            wasteMaterialTypeName: (selectedJob as any).wasteMaterialTypeName,
          }}
        />
      )}
    </Fragment>
  );
};

export default DispatchBoardJobs;
