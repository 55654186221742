import React from 'react';

import { COMPLEX, MODERATE, SIMPLE } from 'src/common/constants/drivingComplexity';
import { InfoIcon } from 'src/routes/components/styled';
import { Popover, TableHead, TableHeadCell } from '../../../core/components/styled';
import { PopoverWrapper, RadioGroup, TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  isStreetSweeperRoute?: boolean;
}

const DriverExperience: React.FC<Props> = ({ isStreetSweeperRoute }) => (
  <>
    <TableHead>
      <TableHeadCell>{translate('vendors.driverExperience')}</TableHeadCell>
    </TableHead>

    <TypedField
      component={RadioGroup}
      name="drivingComplexityType"
      props={{
        color: 'primary',
        margin: 'sMedium xxSmall',
        items: [
          {
            value: SIMPLE.toString(),
            label: translate('vendors.drivingComplexity.simple'),
            margin: 'no no small',
          },
          {
            value: MODERATE.toString(),
            label: translate('vendors.drivingComplexity.moderate.title'),
            margin: 'no no small',
            infoIcon: (
              <PopoverWrapper
                margin="no xxSmall no"
                triggerButton={<InfoIcon />}
                popoverContent={
                  <Popover>
                    {translate(
                      `vendors.drivingComplexity.moderate.${
                        isStreetSweeperRoute ? 'streetSweeperDescription' : 'description'
                      }`,
                    )}
                  </Popover>
                }
                size="large"
              />
            ),
          },
          {
            value: COMPLEX.toString(),
            label: translate('vendors.drivingComplexity.complex.title'),
            margin: 'no no small',
            infoIcon: (
              <PopoverWrapper
                margin="no xxSmall no"
                triggerButton={<InfoIcon />}
                popoverContent={
                  <Popover>
                    {translate(
                      `vendors.drivingComplexity.complex.${
                        isStreetSweeperRoute ? 'streetSweeperDescription' : 'description'
                      }`,
                    )}
                  </Popover>
                }
                size="large"
              />
            ),
          },
        ],
      }}
    />
  </>
);

export default DriverExperience;
