import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import {
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
  PageActions,
} from '../../../../common/components/styled';
import translate from '../../../../core/services/translate';
import { Button, Panel, PanelSection, PanelSectionGroup, Text } from '../../../../core/components/styled';
import RouteTemplateBuilderPageForm from '../../forms/RouteTemplateBuilderPageForm';
import { WEEKDAYS } from '../../../../core/constants/weekdays';
import { AppState } from '../../../../store';
import Table, { TableCell } from '../../../../core/components/Table';
import RouteTemplateBuilderWorkSessionsTableRow from './routeTemplateBuilderSections/RouteTemplateBuilderWorkSessionsTableRow';
import RouteTemplateBuilderCreateWorkSessionModal from './routeTemplateBuilderModals/RouteTemplateBuilderCreateWorkSessionModal';
import { ROUTE_TEMPLATE_BUILDER_PAGE_FORM, DRAFT, PUBLISHED, NEW } from 'src/routes/constants/routeTemplateBuilder';
import { multiWordAndSearch } from 'src/core/services/search';
import { RouteTemplateBuilderWorkSession } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderWorkSession';

const cells: TableCell[] = [
  {
    name: 'name',
    label: translate('routeTemplateBuilder.workSessionName'),
    padding: 'defaultCellVertical small',
    width: '17%',
    sortable: true,
  },
  {
    name: 'lastUpdateDate',
    label: translate('routeTemplateBuilder.lastUpdated'),
    padding: 'defaultCellVertical xxSmall defaultCellVertical xxSmall',
    width: '11%',
    sortable: true,
  },
  {
    name: 'route',
    label: translate('routeTemplateBuilder.route'),
    padding: 'defaultCellVertical small defaultCellVertical xSmall',
    width: '22%',
  },
  {
    name: 'daysOfService',
    label: translate('routeTemplateBuilder.daysOfService'),
    padding: 'defaultCellVertical small defaultCellVertical no',
    width: '13%',
  },
  {
    name: 'vehicleType',
    label: translate('routeTemplateBuilder.vehicleType'),
    padding: 'defaultCellVertical small defaultCellVertical no',
    width: '12%',
  },
  {
    name: 'wasteType',
    label: translate('routeTemplateBuilder.wasteType'),
    padding: 'defaultCellVertical small defaultCellVertical no',
    width: '14%',
  },
  {
    name: 'stopNumber',
    label: translate('routeTemplateBuilder.stopNumber'),
    padding: 'defaultCellVertical small defaultCellVertical no',
    width: '7%',
  },
  {
    name: 'status',
    label: translate('routeTemplateBuilder.status'),
    padding: 'defaultCellVertical small defaultCellVertical no',
    width: '9%',
  },
  {
    name: 'options',
    label: translate('routeTemplateBuilder.options'),
    padding: 'defaultCellVertical small defaultCellVertical no',
    width: '15%',
  },
];

const allDayOfServiceIds = WEEKDAYS.map(day => day.id);
const selector = formValueSelector(ROUTE_TEMPLATE_BUILDER_PAGE_FORM);

const RouteTemplateBuilderWorkSessionsPage: React.FC = () => {
  const dispatch = useDispatch();
  const [createWorkSessionModalIsVisible, setCreateWorkSessionModalIsVisible] = React.useState<boolean>(false);

  const vehicleTypes = useSelector(
    (state: AppState) => (state.fleet.vehicleTypesForVendor.vehicleTypesForVendor || []) as any[],
  );
  const rawWorkSessions = useSelector((state: AppState) => state.routes.routeTemplateBuilder.workSessions);
  const isLoading = useSelector((state: AppState) => state.routes.routeTemplateBuilder.isLoading);
  const searchTerm: string | undefined = useSelector((state: AppState) => selector(state, 'searchTerm'));
  const dayOfServiceIds: number[] = useSelector((state: AppState) => selector(state, 'dayOfServiceIds') || []);
  const routeTemplateIds: number[] = useSelector((state: AppState) => selector(state, 'routeTemplateIds') || []);
  const dateRange: { from: string; to: string } | undefined = useSelector((state: AppState) =>
    selector(state, 'dateRange'),
  );
  const vehicleTypeIds: number[] = useSelector((state: AppState) => selector(state, 'vehicleTypeIds') || []);
  const status: number | undefined | null = useSelector((state: AppState) => selector(state, 'status'));
  const routeTemplates = useSelector((state: AppState) => state.routes.routeTemplateBuilder.workSessionsRouteTemplates);

  const allVehicleTypeIds = React.useMemo(() => vehicleTypes.map(type => type.id), [vehicleTypes]);
  const allRouteTemplateIds = React.useMemo(
    () => routeTemplates.map(template => (template.isPublished ? template.routeTemplateId : template.id)) as number[],
    [routeTemplates],
  );

  const workSessions: RouteTemplateBuilderWorkSession[] = React.useMemo(
    () =>
      rawWorkSessions
        .map(workSession => {
          const newRouteTemplates = workSession.routeTemplates.filter(template => {
            const id = template.isPublished ? template.routeTemplateId : template.id;
            const templateIsInFilters = id ? routeTemplateIds.indexOf(id) !== -1 : false;

            return (
              (!searchTerm || multiWordAndSearch(template.name, searchTerm)) &&
              dayOfServiceIds.indexOf(template.dayOfService) !== -1 &&
              templateIsInFilters &&
              (!dateRange ||
                (moment(template.lastUpdateDate).isSameOrAfter(moment(dateRange.from).startOf('day')) &&
                  moment(template.lastUpdateDate).isSameOrBefore(moment(dateRange.to).endOf('day')))) &&
              vehicleTypeIds.indexOf(template.vehicleTypeId) !== -1 &&
              (typeof status !== 'number' ||
                (status === PUBLISHED && template.isPublished) ||
                (status === DRAFT && !template.isPublished && template.routeTemplateId) ||
                (status === NEW && !template.isPublished && !template.routeTemplateId))
            );
          });

          return {
            ...workSession,
            routeTemplates: newRouteTemplates,
            isFilteredOut: workSession.routeTemplates.length > newRouteTemplates.length && !newRouteTemplates.length,
          };
        })
        .filter(
          workSession =>
            !!workSession.routeTemplates.length ||
            (!workSession.isFilteredOut &&
              (!searchTerm || multiWordAndSearch(workSession.name, searchTerm)) &&
              (!dateRange ||
                (moment(workSession.lastUpdateDate).isSameOrAfter(moment(dateRange.from).startOf('day')) &&
                  moment(workSession.lastUpdateDate).isSameOrBefore(moment(dateRange.to).endOf('day'))))),
        ),
    [rawWorkSessions, searchTerm, dayOfServiceIds, routeTemplateIds, dateRange, vehicleTypeIds, status],
  );

  React.useEffect(() => {
    dispatch(change(ROUTE_TEMPLATE_BUILDER_PAGE_FORM, 'routeTemplateIds', allRouteTemplateIds));
  }, [dispatch, allRouteTemplateIds]);

  return (
    <PageContent isLoading={isLoading}>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('routeTemplateBuilder.routeTemplateBuilder')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions flex>
          <Button color="primary" onClick={() => setCreateWorkSessionModalIsVisible(true)}>
            {translate('routeTemplateBuilder.createWorkSession')}
          </Button>
        </PageActions>
      </PageHeader>

      <Panel>
        <RouteTemplateBuilderPageForm
          initialValues={{
            dayOfServiceIds: allDayOfServiceIds,
            dateRange: {
              from: moment().subtract(1, 'month').format('MM/DD/YYYY'),
              to: moment().format('MM/DD/YYYY'),
            },
            vehicleTypeIds: allVehicleTypeIds,
            routeTemplateIds: allRouteTemplateIds,
          }}
        />

        {!!workSessions.length && (
          <PanelSection>
            <Table cells={cells} rows={workSessions} rowComponent={RouteTemplateBuilderWorkSessionsTableRow} />
          </PanelSection>
        )}

        {!workSessions.length && !!rawWorkSessions.length && (
          <PanelSection centered display="flex">
            <PanelSectionGroup centered vertical height="400px">
              <Text color="grayDarker" size="large" lineHeight="1.5" weight="light">
                {translate('routeTemplateBuilder.noWorkSessionsDueToFilters')}
              </Text>
            </PanelSectionGroup>
          </PanelSection>
        )}

        {!workSessions.length && !rawWorkSessions.length && (
          <PanelSection centered display="flex">
            <PanelSectionGroup centered vertical height="400px">
              <Text color="grayDarker" size="large" lineHeight="1.5" weight="light">
                {translate('routeTemplateBuilder.noDraftsHintPart1')}
                <br />
                {translate('routeTemplateBuilder.noDraftsHintPart2')}
              </Text>

              <Button color="primary" margin="sMedium no no" onClick={() => setCreateWorkSessionModalIsVisible(true)}>
                {translate('routeTemplateBuilder.createWorkSession')}
              </Button>
            </PanelSectionGroup>
          </PanelSection>
        )}
      </Panel>

      {createWorkSessionModalIsVisible && (
        <RouteTemplateBuilderCreateWorkSessionModal onClose={() => setCreateWorkSessionModalIsVisible(false)} />
      )}
    </PageContent>
  );
};

export default RouteTemplateBuilderWorkSessionsPage;
