import React, { Fragment } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import RubiconServicesDocumentListModal from './RubiconServicesDocumentListModal';
import { loadDocuments } from 'src/customers/ducks/rubiconServicesDocuments';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { isAdminSelector } from 'src/account/ducks';

interface Props extends RouteComponentProps {
  closeModal: () => void;
  serviceNumber: number;
}
const RubiconServicesDocumentListModalResolver: React.FC<Props> = ({ closeModal, serviceNumber }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);
  const isAdmin = useSelector((state: AppState) => isAdminSelector(state.account.login));

  const loadDependencies = async () => {
    return loadDocuments(vendorId, userId, isAdmin, serviceNumber)(dispatch);
  };
  return (
    <Fragment>
      <Resolver
        successComponent={RubiconServicesDocumentListModal}
        loadingComponent={PageLoadingOverlay}
        successProps={{ closeModal }}
        resolve={loadDependencies}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadDocuments,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RubiconServicesDocumentListModalResolver));
