import React, { useRef, useState } from 'react';
import { InfoWindow, Polyline } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { AppState } from 'src/store';
import { APPLICATION_STATUSES, GOOGLE as google, POLYLINE_COLORS } from '../constants';
import { Box } from 'src/core/components/styled/Box';
import { Coordinates, GroupedVehicleTrackings } from '../../routes/components/mapWithTimeline/Interfaces';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { dateFormat, timeFormat } from 'src/utils/services/validator';
import { FORWARD_VIEW_ID, VIDEO_DEVICE_SURFSIGHT_TYPE_ID } from 'src/routes/constants';
import { getSpeedInformation } from '../services/getSpeedInformation';
import {
  MapInfoImage,
  MapInfoImageWrapper,
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowDetailsContainer,
  MapInfoWindowTitle,
} from '../../core/components/styled';
import { saveVideoRequest } from 'src/routes/ducks';
import { time, timeStamp } from '../../utils/services/formatter';
import { useSelector } from 'src/core/hooks/useSelector';
import { VideoRequestFormValues } from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestForm';
import LabeledDataView from 'src/core/components/LabeledDataView';
import StrobeImagesModalResolver from '../../routes/components/modals/StrobeImagesModalResolver';
import translate from '../../core/services/translate';
import VideoRequestModal from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestModal';

const createPolylineCoordinates = (positions: Coordinates[]) =>
  positions.map(position => new google.maps.LatLng(position.latitude, position.longitude));

export interface VehicleContentInfoProps {
  coordinateGroup: GroupedVehicleTrackings;
  shouldDisplayDownloadVideo?: boolean;
  videoDeviceTypeId?: number;
  deviceId?: string;
  showTimestamp?: boolean;
  strobeImageUrl?: string;
  unitOfMeasure: string;
  vehicleId: number;
  vehicleName: string;
}

interface Props extends VehicleContentInfoProps {
  coordinateGroup: GroupedVehicleTrackings;
  coordinateGroupId?: string;
  isInfoWindowOpen?: boolean;
  mapZoom: number;
  toggleInfoWindow: (key: string, vehicleIndex: number, endTimestamp: string) => string;
  vehicleIndex: number;
}

export const VehicleTrackingInfoContent: React.FC<VehicleContentInfoProps> = ({
  coordinateGroup: { endTimestamp, speed, applicationModeStatusId },
  shouldDisplayDownloadVideo,
  videoDeviceTypeId = VIDEO_DEVICE_SURFSIGHT_TYPE_ID,
  deviceId,
  showTimestamp,
  strobeImageUrl,
  unitOfMeasure,
  vehicleId,
  vehicleName,
}) => {
  const dispatch = useDispatch();

  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);

  const [isStrobeImageModalOpen, setIsStrobeImageModalOpen] = useState(false);
  const [isVideoRequestModalOpen, setIsVideoRequestModalOpen] = useState(false);

  const onVideoRequestModalSubmit = (formValues: VideoRequestFormValues) => {
    saveVideoRequest({ ...formValues, userId, vendorId })(dispatch)
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.videoRequestSaved'));
        setIsVideoRequestModalOpen(false);
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.videoRequestSaveError'));
      });
  };

  return (
    <MapInfoWindow>
      <MapInfoWindowDetailsContainer>
        <MapInfoWindowDetails margin>
          <MapInfoWindowTitle>{vehicleName}</MapInfoWindowTitle>

          {!!endTimestamp && (
            <MapInfoWindowDetail>
              <MapInfoWindowDetailLabel>{translate('common.timestamp')}:</MapInfoWindowDetailLabel>
              {showTimestamp ? timeStamp(endTimestamp) : time(endTimestamp)}
            </MapInfoWindowDetail>
          )}
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('common.appStatus')}:</MapInfoWindowDetailLabel>
            {APPLICATION_STATUSES[applicationModeStatusId].name}
          </MapInfoWindowDetail>
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('common.speed')}:</MapInfoWindowDetailLabel>
            {getSpeedInformation(speed, unitOfMeasure)}
          </MapInfoWindowDetail>

          {shouldDisplayDownloadVideo && (
            <LabeledDataView noWrap size="small">
              <Box cursor="pointer" onClick={() => setIsVideoRequestModalOpen(true)} textDecoration="underline">
                {translate('routes.videoRequest.requestVideoFootage')}
              </Box>
            </LabeledDataView>
          )}
        </MapInfoWindowDetails>
        {!!strobeImageUrl && (
          <MapInfoImageWrapper onClick={() => setIsStrobeImageModalOpen(true)}>
            <MapInfoImage src={strobeImageUrl} onClick={() => setIsStrobeImageModalOpen(true)} />
          </MapInfoImageWrapper>
        )}
      </MapInfoWindowDetailsContainer>

      {!!isStrobeImageModalOpen && (
        <StrobeImagesModalResolver
          closeModal={() => setIsStrobeImageModalOpen(false)}
          shouldDisplayDownloadVideo={shouldDisplayDownloadVideo}
          timeStamp={endTimestamp}
          vehicleId={vehicleId}
          vehicleName={vehicleName}
          videoDeviceTypeId={videoDeviceTypeId}
          deviceId={deviceId}
        />
      )}

      {isVideoRequestModalOpen && (
        <VideoRequestModal
          closeModal={() => setIsVideoRequestModalOpen(false)}
          onSubmit={onVideoRequestModalSubmit}
          initialValues={{
            date: moment(endTimestamp).format(dateFormat),
            time: moment(endTimestamp).format(timeFormat),
            vehicleId,
            vehicleName,
            videoDeviceTypeId,
            deviceId,
            vehicleCameraConfigurationTypeIds: [FORWARD_VIEW_ID],
          }}
        />
      )}
    </MapInfoWindow>
  );
};

const VehicleTrackingMarker = (props: Props) => {
  const {
    coordinateGroupId,
    coordinateGroup: { coordinates, endTimestamp },
    isInfoWindowOpen,
    mapZoom,
    toggleInfoWindow,
    vehicleIndex,
  } = props;

  const [infoWindowPosition, setInfoWindowPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const setPolylineRef = useRef<any>(null);

  const toggleWindow = (event: google.maps.MapMouseEvent) => {
    if (event?.latLng) {
      setInfoWindowPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
    toggleInfoWindow(`vehicleTrackings.${coordinateGroupId}`, vehicleIndex, endTimestamp);
  };

  const pixelOffset = new google.maps.Size(0, -5);

  return (
    <>
      <Polyline
        path={createPolylineCoordinates(coordinates)}
        ref={setPolylineRef}
        options={{
          geodesic: true,
          clickable: true,
          strokeColor: POLYLINE_COLORS[vehicleIndex],
          strokeOpacity: 0.001,
          strokeWeight: 50,
          zIndex: 250,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: POLYLINE_COLORS[vehicleIndex],
                fillOpacity: 0.4,
                strokeColor: POLYLINE_COLORS[vehicleIndex],
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: mapZoom >= 15 ? 3 : 2,
                offset: '100%',
              } as any,
              repeat: '75px',
            },
          ],
        }}
        onClick={event => toggleWindow(event)}
      />
      {isInfoWindowOpen && infoWindowPosition.lat !== 0 && infoWindowPosition.lng !== 0 && (
        <InfoWindow position={infoWindowPosition} onCloseClick={toggleWindow as any} options={{ pixelOffset }}>
          <VehicleTrackingInfoContent {...props} />
        </InfoWindow>
      )}
    </>
  );
};

export default VehicleTrackingMarker;
