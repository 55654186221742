import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';

const getVersionStatusesFormInitialValues = (queryString: string) => {
  const { vendorId, vehicleTypeId } = getQueryParams(queryString);

  return {
    vendorId: vendorId && Number(vendorId),
    vehicleTypeId: vehicleTypeId && Number(vehicleTypeId),
  };
};

const versionStatusesFormInitialValuesSelector = createSelector(getVersionStatusesFormInitialValues, identity);

export default versionStatusesFormInitialValuesSelector;
