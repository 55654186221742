import { http } from '../../core/services/http';
import { TODAY } from '../../core/constants';

const loadRunningVehicles = (vendorId: number, date: Date | string = TODAY) =>
  http
    .get('inferenceAudit/runningVehicles', {
      params: {
        vendorId,
        date,
      },
    })
    .then(response => response.data);

export default loadRunningVehicles;
