import axios from 'axios';
import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import translate from 'src/core/services/translate';

import { ContainerNewSimplified } from '../interfaces/containers';
import { loadContainersList as doLoadContainersList } from '../services/containersList';

// Actions
const START_LOAD = 'fleet/containers/containersList/START_LOAD';
const COMPLETE_LOAD = 'fleet/containers/containersList/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/containersList/FAIL_LOAD';
const RESET = 'fleet/containers/containersList/RESET';

const CancelToken = axios.CancelToken;
let cancelLoadContainerList: any;

// Initial state
const initialState: {
  isLoading: boolean;
  containersList: ContainerNewSimplified[];
} = {
  isLoading: false,
  containersList: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersList: action.containersList,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersList: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containersList: any) => ({
  type: COMPLETE_LOAD,
  containersList,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainersList =
  (
    vendorId: number,
    containerTypeId?: number,
    equipmentTypeId?: number,
    equipmentSizeId?: number,
    equipmentConditionId?: number,
    searchTerm?: string,
    locationId?: number,
  ) =>
  (dispatch: Dispatch) => {
    // cancel previous call if another one was made
    if (cancelLoadContainerList) cancelLoadContainerList(translate('common.inFlightRequestCanceled'));

    dispatch(startLoad());

    const cancelToken = new CancelToken(c => {
      cancelLoadContainerList = c;
    });

    const loadContainersListPromise = doLoadContainersList(
      vendorId,
      containerTypeId,
      equipmentTypeId,
      equipmentSizeId,
      equipmentConditionId,
      searchTerm,
      locationId,
      cancelToken,
    );
    loadContainersListPromise
      .then((containersList: ContainerNewSimplified[]) => dispatch(completeLoad(containersList)))
      .catch(err => {
        if (!axios.isCancel(err)) dispatch(failLoad());
      });
    return loadContainersListPromise;
  };

export const resetContainersList = () => ({
  type: RESET,
});
