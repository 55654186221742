import { http } from '../../core/services/http';
import { VehicleStrobeImagesSettingsItem } from '../interfaces/VehicleStrobeImagesSettings';

export const loadVehicleStrobeImagesSettings = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/strobeImagesSettings`).then(response => response.data);

export const saveVehicleStrobeImagesSettings = (
  vendorId: number,
  strobeImagesSettings: VehicleStrobeImagesSettingsItem[],
) =>
  http
    .put(`/vendors/${vendorId}/strobeImagesSettings`, strobeImagesSettings)
    .then(response => response.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadVehicleStrobeImageFrequency = () =>
  http.get(`/common/vehicleStrobeImageFrequency`).then(response => response.data);
