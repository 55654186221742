import { useEffect, useRef, useCallback } from 'react';
import { Coordinates } from '../Coordinates';

interface UseDraggableMarkersOptions {
  map: mapboxgl.Map;
  source: GeoJSON.FeatureCollection<any>;
  sourceId: string;
  layerId: string;
  setDragPan: (dragPan: boolean) => void;
  updateAddress: (marker: GeoJSON.Feature<GeoJSON.Point, any>, updatedAddress: Coordinates) => Promise<boolean>;
  draggingEnabled?: boolean;
  getFeatureId?: (feature: GeoJSON.Feature<any, GeoJSON.GeoJsonProperties>) => number | string;
}

export const useDraggableMarkers = ({
  map,
  source,
  sourceId,
  layerId,
  setDragPan,
  updateAddress,
  draggingEnabled = true,
  getFeatureId = (f: GeoJSON.Feature<any, GeoJSON.GeoJsonProperties>) => f.properties?.id,
}: UseDraggableMarkersOptions) => {
  const currentFeatureRef = useRef<GeoJSON.Feature<GeoJSON.Point, any>>();
  const isMovedRef = useRef<boolean>(false);

  const updateSource = useCallback(() => (map.getSource(sourceId) as any).setData(source), [map, source, sourceId]);

  const mouseMove = useCallback(
    (e: any) => {
      const canvas = map.getCanvasContainer();
      const coords = e.lngLat;
      canvas.style.cursor = 'grabbing';
      if (currentFeatureRef.current) {
        currentFeatureRef.current.geometry.coordinates = [coords.lng, coords.lat];
        isMovedRef.current = true;
        updateSource();
      }
    },
    [map, updateSource],
  );

  const changeCursor = useCallback(
    e => {
      const canvas = map.getCanvasContainer();
      canvas.style.cursor = 'move';
    },
    [map],
  );

  const resetCursor = useCallback(() => {
    const canvas = map.getCanvasContainer();
    canvas.style.cursor = '';
  }, [map]);

  const startMarkerMove = useCallback(
    function (e) {
      if (e.features && e.features.length) {
        e.preventDefault();

        const canvas = map.getCanvasContainer();
        setDragPan(false);

        const marker = source.features.find(f => getFeatureId(f) === getFeatureId(e.features![0])) as GeoJSON.Feature<
          GeoJSON.Point,
          any
        >;

        if (marker) {
          const originalPosition = marker.geometry.coordinates;
          currentFeatureRef.current = marker;

          canvas.style.cursor = 'grab';

          map.on('mousemove', mouseMove);
          map.once('mouseup', async () => {
            if (isMovedRef.current && currentFeatureRef.current) {
              const [lng, lat] = currentFeatureRef.current.geometry.coordinates;
              const shouldUpdate = await updateAddress(currentFeatureRef.current, { lng, lat });
              if (!shouldUpdate) {
                currentFeatureRef.current.geometry.coordinates = originalPosition;
                updateSource();
              }
            }
            setDragPan(true);
            const canvas = map.getCanvasContainer();
            canvas.style.cursor = '';
            map.off('mousemove', mouseMove);
            isMovedRef.current = false;
          });
        }
      }
    },
    [getFeatureId, map, mouseMove, setDragPan, source.features, updateAddress, updateSource],
  );

  const disableDragging = useCallback(() => {
    map.off('mouseenter', layerId, changeCursor);
    map.off('mouseleave', layerId, resetCursor);
    map.off('mousedown', layerId, startMarkerMove);
  }, [changeCursor, layerId, map, startMarkerMove, resetCursor]);

  const enableDragging = useCallback(() => {
    map.on('mouseenter', layerId, changeCursor);
    map.on('mouseleave', layerId, resetCursor);
    map.on('mousedown', layerId, startMarkerMove);
  }, [changeCursor, layerId, map, startMarkerMove, resetCursor]);

  useEffect(() => {
    if (draggingEnabled) {
      enableDragging();
    } else {
      disableDragging();
    }

    return disableDragging;
  }, [
    changeCursor,
    disableDragging,
    draggingEnabled,
    enableDragging,
    layerId,
    map,
    mouseMove,
    startMarkerMove,
    resetCursor,
  ]);
};
