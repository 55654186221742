import { createSelector } from 'reselect';
import { identity, map } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';
import { ACTIVE, NEW } from '../../common/constants/accountStatuses';

const getCustomersFormInitialValues = (queryString: string) => {
  const {
    customerStatusTypeIds = `${ACTIVE},${NEW}`,
    customerTypeId,
    searchTerm,
    accountNumber,
  } = getQueryParams(queryString);
  return {
    customerStatusTypeIds: customerStatusTypeIds && map(customerStatusTypeIds.toString().split(','), Number),
    customerTypeId: Number(customerTypeId),
    searchTerm,
    accountNumber,
  };
};

const customersFormInitialValuesSelector = createSelector(getCustomersFormInitialValues, identity);

export default customersFormInitialValuesSelector;
