import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ActionButtonTooltip, DatePicker, FileOpenDialogToggle, Input, TypedField } from '../../../core/components';
import { Button, ButtonSet, Grid, GridColumn, ModalFixedFooter, Text } from '../../../core/components/styled';
import {
  DisposalSiteLocationDropdown,
  WasteTypeDropdown,
  WeightMeasurementUnitDropdown,
} from '../../../common/components';
import { isAfterCurrentTime, isDateValidValidator, isRequired, maxLength20 } from '../../../utils/services/validator';
import { RouteCustomerDropdown } from '../';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { TODAY } from '../../../core/constants';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import TimePickerInput from '../../../core/components/TimePicker';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  isRollOff: boolean;
  onClearfileName: () => void;
  onClose: (formPristine?: boolean) => void;
  onImageUpload: (files: any) => void;
  routeId?: string[];
  routeLocations: RouteLocation[];
  selectedFileName?: string;
  weightTicketId?: string;
}

interface FormValues {
  ticketNumber: string;
  disposalSize: string;
  weight: number;
  unit: number;
  disposalDate?: string;
  disposalTime?: string;
  customer: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

function WeightTicketEditorForm({
  change,
  handleSubmit,
  onClose,
  onImageUpload,
  onClearfileName,
  selectedFileName,
  isRollOff,
  pristine,
  routeLocations,
}: Props) {
  const onTimeChange = (value: Date | string) => {
    change('disposalTime', value);
  };

  const handleCustomerChange = (_: unknown, value: number) => {
    if (isRollOff) {
      const wasteMaterialTypeId = routeLocations.find(routeLocation => routeLocation.id === value)?.service
        ?.wasteMaterialTypeId;
      change('wasteMaterialTypeId', wasteMaterialTypeId);
    }
  };

  const fileName = selectedFileName && selectedFileName?.split('.')[1] + '.' + selectedFileName?.split('.')[2];

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid multiLine>
        <GridColumn size="6/12">
          <Field
            name="ticketNumber"
            component={Input}
            label={translate('common.ticketNumber')}
            validate={[isRequired, maxLength20]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="locationId"
            component={DisposalSiteLocationDropdown}
            label={translate('common.locationTypes.disposalSite')}
            validate={[isRequired]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field name="weight" component={Input} label={translate('common.weight')} validate={[isRequired]} />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="unitOfMeasureTypeId"
            component={WeightMeasurementUnitDropdown}
            label={translate('common.unit')}
            validate={[isRequired]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="disposalDate"
            component={DatePicker}
            label={translate('common.weightTickets.disposalDate')}
            validate={[isRequired, isDateValidValidator]}
            disabledDays={[{ after: TODAY }]}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <Field
            name="disposalTime"
            component={TimePickerInput}
            label={translate('common.weightTickets.disposalTime')}
            onTimeChange={(value: Date | string) => onTimeChange(value)}
            validate={[isRequired, isAfterCurrentTime]}
            props={{
              raisedLabel: true,
            }}
          />
        </GridColumn>
        {isRollOff && (
          <GridColumn size="6/12">
            <TypedField
              name="routeLocationId"
              component={RouteCustomerDropdown}
              onChange={handleCustomerChange}
              validate={isRequired}
              props={{
                label: translate('common.customer'),
              }}
            />
          </GridColumn>
        )}
        <GridColumn size="6/12">
          <TypedField
            name="wasteMaterialTypeId"
            component={WasteTypeDropdown}
            validate={[isRequired]}
            props={{
              label: translate('common.wasteType'),
            }}
          />
        </GridColumn>
        {!!fileName ? (
          <GridColumn size="6/12">
            <Text weight="medium" size="small" margin="no">
              {fileName}
            </Text>

            <Button id="clear-image-button" text color="primary" padding="no" onClick={() => onClearfileName()}>
              <ActionButtonTooltip icon="cancelClear" tooltip="clear" />{' '}
            </Button>
          </GridColumn>
        ) : (
          <GridColumn size="6/12">
            <Text block size="small">
              {translate('common.images')}
            </Text>

            <FileOpenDialogToggle
              margin="no xSmall no no"
              icon="upload"
              accept=".jpg"
              onFilesSelected={onImageUpload}
            />
          </GridColumn>
        )}
      </Grid>
      <ModalFixedFooter padding="sMedium small" justifyContent="space-around">
        <ButtonSet align="center" margin="no small">
          <Button type="submit" color="primary">
            {translate('common.save')}
          </Button>
          <Button type="button" margin="no small" color="secondary" onClick={() => onClose(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </ModalFixedFooter>
    </form>
  );
}

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'weightTicketEditorForm',
  onSubmitFail: focusFirstInvalidField,
})(WeightTicketEditorForm);
