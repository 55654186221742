import { InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash-es';

import { Button, ButtonSet, Grid, GridColumn } from '../../../core/components/styled';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isRequired } from '../../../utils/services/validator';
import { Input, Dropdown, TypedField } from '../../../core/components';
import { SCHEDULED_DATE_TYPE } from '../../../core/constants/scheduledDateType';
import translate from '../../../core/services/translate';

function DefaultNoteFieldsEditorForm({ form, handleSubmit }: InjectedFormProps) {
  const fieldTypeOptions = map(SCHEDULED_DATE_TYPE, date => ({
    label: date.name,
    value: date.id,
  }));
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid multiLine>
        <GridColumn size="6/12">
          <TypedField
            name="fieldName"
            validate={[isRequired]}
            component={Input}
            props={{
              label: `* ${translate('vendors.fieldName')}`,
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="fieldDisplayName"
            validate={[isRequired]}
            component={Input}
            props={{
              label: translate('vendors.fieldDisplayName'),
            }}
          />
        </GridColumn>
        <GridColumn size="6/12">
          <TypedField
            name="fieldType"
            validate={[isRequired]}
            component={Dropdown}
            props={{
              label: `* ${translate('vendors.fieldType')}`,
              options: fieldTypeOptions,
            }}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <ButtonSet align="center" margin="large small">
            <Button type="submit" color="primary">
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </form>
  );
}

export default reduxForm({ form: 'defaultNoteFieldsEditorForm', onSubmitFail: focusFirstInvalidField })(
  DefaultNoteFieldsEditorForm,
);
