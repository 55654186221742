import { http } from '../../core/services/http';
import { transformLoadFiles } from './transformLoadFiles';
import { downloadExcelFile } from '../../utils/services/downloadExcelFile';

interface LoadFilesProps {
  divisionId: string;
  vendorGroupId: number;
  includeSubFolders: boolean;
}

export const loadFiles = (params: LoadFilesProps) => {
  const { vendorGroupId, includeSubFolders } = params;
  return http
    .get(`/haulerProfile/${vendorGroupId}/documents`, {
      params: {
        includeSubFolders,
      },
    })
    .then(response => transformLoadFiles(response.data));
};

interface SaveFileProps {
  data: any;
  vendorGroupId: number;
  userId: number;
  isFileUpload: boolean;
  documentType: string;
  divisionId?: string;
}

export const saveFile = (params: SaveFileProps) => {
  const { data, vendorGroupId, userId, isFileUpload, documentType, divisionId } = params;
  const formData = new FormData();

  formData.append('ShowToCustomer', String(!!data.showToCustomer));
  formData.append('ShowToVendor', String(true));
  formData.append('DocumentType', documentType || data.documentType);

  if (isFileUpload) {
    formData.append('FileName', `${new Date().getTime()}_${data.file[0].name}`);
    formData.append('File', data.file[0]);
  } else {
    formData.append('Name', data.name);
    formData.append('Url', data.url);
  }

  if (data.certificationNumber) formData.append('CertificateNumber', data.certificationNumber);
  if (data.documentDescription) formData.append('DocumentDescription', data.documentDescription);
  if (data.validFrom) formData.append('ValidFrom', data.validFrom);
  if (data.validTo) formData.append('ValidTo', data.validTo);

  formData.append('VendorGroupId', String(vendorGroupId));
  formData.append('UserId', String(userId));

  const postUrl = !!divisionId
    ? `haulerprofile/${vendorGroupId}/user/${userId}/documents?divisionId=${divisionId}`
    : `haulerprofile/${vendorGroupId}/user/${userId}/documents`;

  return http
    .post(postUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch(error => Promise.reject(error.response.data.message));
};

export const exportFile = (fileId: number, fileName: string, venndorGroupId: number, divisionId?: string) => {
  const url = divisionId
    ? `haulerprofile/${venndorGroupId}/documents/${fileId}?divisionId=${divisionId}`
    : `haulerprofile/${venndorGroupId}/documents/${fileId}`;
  return downloadExcelFile(url, fileName, undefined, true);
};

export const deleteDocument = (haulerId: string, documentId: number, userId: string, divisionId?: string) => {
  const deleteUrl = divisionId
    ? `/haulerProfile/${haulerId}/user/${userId}/documents/${documentId}?divisionId=${divisionId}`
    : `/haulerProfile/${haulerId}/user/${userId}/documents/${documentId}`;
  return http.delete(deleteUrl);
};
