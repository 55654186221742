import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  hideRouteSequenceInstructions as doHideRouteSequenceInstructions,
  shouldHideRouteSequenceInstructions,
} from '../services/routeSequenceInstructions';

// Actions
const OPEN_ROUTE_SEQUENCE_INSTRUCTIONS = 'dashboard/ROUTE_SEQUENCE_INSTRUCTIONS/OPEN_ROUTE_SEQUENCE_INSTRUCTIONS';
const CLOSE_ROUTE_SEQUENCE_INSTRUCTIONS = 'dashboard/ROUTE_SEQUENCE_INSTRUCTIONS/CLOSE_ROUTE_SEQUENCE_INSTRUCTIONS';
const RESET_ROUTE_SEQUENCE_INSTRUCTIONS = 'dashboard/ROUTE_SEQUENCE_INSTRUCTIONS/RESET_ROUTE_SEQUENCE_INSTRUCTIONS';

interface State {
  isRouteSequenceOpen: boolean;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isRouteSequenceOpen: !shouldHideRouteSequenceInstructions(),
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OPEN_ROUTE_SEQUENCE_INSTRUCTIONS:
      return update(state, {
        $merge: {
          isRouteSequenceOpen: true,
        },
      });

    case CLOSE_ROUTE_SEQUENCE_INSTRUCTIONS:
      return update(state, {
        $merge: {
          isRouteSequenceOpen: false,
        },
      });

    case RESET_ROUTE_SEQUENCE_INSTRUCTIONS:
      return update(state, {
        $merge: {
          isRouteSequenceOpen: !shouldHideRouteSequenceInstructions(),
        },
      });

    default:
      return state;
  }
};

// Action creators
const openRouteSequenceInstructions = () => ({
  type: OPEN_ROUTE_SEQUENCE_INSTRUCTIONS,
});

const closeRouteSequenceInstructions = () => ({
  type: CLOSE_ROUTE_SEQUENCE_INSTRUCTIONS,
});

export const startRouteSequenceInstructions = () => (dispatch: Dispatch) => dispatch(openRouteSequenceInstructions());

export const doNotShowRouteSequenceInstructions = () => (dispatch: Dispatch) => {
  doHideRouteSequenceInstructions();
  dispatch(closeRouteSequenceInstructions());
};

export const hideRouteSequenceInstructions = () => (dispatch: Dispatch) => dispatch(closeRouteSequenceInstructions());

export const resetRouteSequenceInstructions = () => ({
  type: RESET_ROUTE_SEQUENCE_INSTRUCTIONS,
});
