import { httpInsightsReporting } from '../../core/services/http';
import transformLoadMapInsights from './transformLoadMapInsights';

const loadMapInsights = ({
  vendorId,
  date,
  vehicleIds = [],
  containerIssueInsightTypes = [],
  containerServiceInsightTypes = [],
  vehicleInsightTypes = [],
  cityInsightTypes = [],
  vehicleTypeIdsCSV = [],
  serviceZoneIdsCSV = [],
  supervisorIdsCSV = [],
}: {
  vendorId: number;
  date: Date | string;
  vehicleIds?: number[];
  containerIssueInsightTypes?: string[];
  containerServiceInsightTypes?: string[];
  vehicleInsightTypes?: string[];
  cityInsightTypes?: string[];
  vehicleTypeIdsCSV?: number[];
  serviceZoneIdsCSV?: number[];
  supervisorIdsCSV?: number[];
}) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/dashboardMapInsights`, {
      params: {
        date,
        vehicles: vehicleIds.join(','),
        issueBinInsights: containerIssueInsightTypes.join(','),
        serviceBinInsights: containerServiceInsightTypes.join(','),
        vehicleInsights: vehicleInsightTypes.join(','),
        cityInsights: cityInsightTypes.join(','),
        vehicleTypeIdsCSV: vehicleTypeIdsCSV.join(','),
        serviceZoneIdsCSV: serviceZoneIdsCSV.join(','),
        supervisorIdsCSV: supervisorIdsCSV.join(','),
      },
    })
    .then(response => transformLoadMapInsights(response.data));

export default loadMapInsights;
