import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import { size } from 'lodash-es';

import { AppState } from 'src/store';
import { Button, ButtonLink, Message, Panel, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import { checkIfViewOnly } from 'src/account/utils/permissions';
import { CUSTOMER_TYPE_IDS_FOR_JOB_ADDITION } from 'src/customers/constants/customerTypes';
import { DispatchBoardJobEditorModalResolver } from 'src/routes/components/modals';
import { DuckFunction } from 'src/contracts/ducks';
import {
  exportServiceHistory,
  loadSearchedLocation,
  loadServiceHistory,
  resetServiceHistory,
  resetSearchedLocation,
  setCurrentServiceHistoryPageUrl,
} from 'src/customers/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { isMaterialTypesFeatureEnabled } from 'src/vendors/ducks/features';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { ServiceHistory } from 'src/customers/interfaces/ServiceHistory';
import { ServiceHistoryForm } from '../forms';
import { ServiceHistoryTableRow } from '.';
import { Table } from 'src/core/components';
import { TABLE_ROW_HEIGHT_LARGER } from 'src/core/constants';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import translate from 'src/core/services/translate';

interface MatchState {
  customerId: string;
}

interface LocationState {
  locationId: string;
  serviceContractIds: string;
  pickupStatusTypeIds: string;
  jobPriorityTypeIds: string;
  startDate: string;
  endDate: string;
}

interface Props extends RouteComponentProps<MatchState, {}, LocationState> {
  customerName?: string;
  customerTypeId?: number;
  exportServiceHistory: DuckFunction<typeof exportServiceHistory>;
  isExporting: boolean;
  isLoading: boolean;
  isMaterialTypesEnabled: boolean;
  loadSearchedLocation: DuckFunction<typeof loadSearchedLocation>;
  loadServiceHistory: DuckFunction<typeof loadServiceHistory>;
  push: (url: string) => void;
  reasonCodeTypes: TechnicalType[];
  resetSearchedLocation: () => void;
  resetServiceHistory: () => void;
  serviceHistory: ServiceHistory[];
  setCurrentServiceHistoryPageUrl: (currentPacurrentServiceHistoryPageUrlgeUrl: string) => void;
  vehicleType?: string;
  wasteAuditLocationStatusTypeId?: number;
  wasteAuditType: TechnicalType;
}

interface State {
  jobEditorModalOpen: boolean;
}

class ServiceHistoryPage extends PureComponent<Props, State> {
  state = {
    jobEditorModalOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;

    if (prevProps.location.search !== location.search) {
      const { locationId } = getQueryParams(location.search);
      const { locationId: previousLocationId } = getQueryParams(prevProps.location.search);

      this.loadServiceHistory(previousLocationId !== locationId);
    }
  }

  componentWillUnmount() {
    this.props.resetServiceHistory();
  }

  loadServiceHistory = (locationIsDifferent = false) => {
    const {
      loadSearchedLocation,
      loadServiceHistory,
      location,
      match: { params },
      resetSearchedLocation,
    } = this.props;

    const customerId = Number(params.customerId);

    const { locationId, serviceContractIds, pickupStatusTypeIds, startDate, endDate, jobPriorityTypeIds } =
      getQueryParams(location.search);

    if (locationIsDifferent) {
      !locationId && resetSearchedLocation();

      Promise.all([
        locationId && loadSearchedLocation(locationId),
        loadServiceHistory(
          customerId,
          locationId,
          undefined,
          pickupStatusTypeIds,
          jobPriorityTypeIds,
          startDate,
          endDate,
        ),
      ]);
    } else {
      loadServiceHistory(
        customerId,
        locationId,
        serviceContractIds,
        pickupStatusTypeIds,
        jobPriorityTypeIds,
        startDate,
        endDate,
      );
    }
  };

  exportServiceHistory = () => {
    const {
      match: { params },
      location,
      exportServiceHistory,
      customerName,
    } = this.props;

    const customerId = Number(params.customerId);
    const { locationId, serviceContractIds, pickupStatusTypeIds, startDate, endDate, jobPriorityTypeIds } =
      getQueryParams(location.search);

    exportServiceHistory(
      customerId,
      customerName,
      locationId,
      serviceContractIds,
      pickupStatusTypeIds,
      jobPriorityTypeIds,
      startDate,
      endDate,
    );
  };

  openJobEditorModal = () => this.setState({ jobEditorModalOpen: true });

  closeJobEditorModal = () => this.setState({ jobEditorModalOpen: false });

  render() {
    const {
      customerTypeId,
      isExporting,
      isLoading,
      isMaterialTypesEnabled,
      location,
      match: { params },
      reasonCodeTypes,
      serviceHistory,
      setCurrentServiceHistoryPageUrl,
      vehicleType,
      wasteAuditLocationStatusTypeId,
      wasteAuditType,
    } = this.props;

    const customerId = Number(params.customerId);
    const { jobEditorModalOpen } = this.state;

    const serviceHistoryTableCells = [
      {
        name: 'locationName',
        label: `${translate('common.location')} / ${translate('common.address')}`,
        width: '17%',
        sortable: true,
      },
      { name: 'serviceName', label: translate('common.service'), width: '14%', sortable: true },
      { name: 'serviceDate', label: translate('common.date'), width: '12%', sortable: true },
      { name: 'pickupStatusType', label: translate('common.pickupStatus'), width: '11%', sortable: true },
      {
        name: 'routeName',
        label: translate('common.route'),
        width: '17%',
        sortable: true,
      },
      { name: 'pickupType', label: translate('routes.pickupType'), width: '13%', padding: 'defaultCellVertical no' },
      {
        name: 'events',
        label: translate('common.events'),
        width: '16%',
        align: 'right',
      },
    ];

    const virtualizedProps = {
      itemSize: TABLE_ROW_HEIGHT_LARGER,
      height: Math.min(serviceHistory.length * TABLE_ROW_HEIGHT_LARGER, TABLE_ROW_HEIGHT_LARGER * 9) || 1,
    };

    const customerTypeSupportsJobAdd = customerTypeId
      ? CUSTOMER_TYPE_IDS_FOR_JOB_ADDITION.indexOf(customerTypeId) !== -1
      : undefined;

    const goBackToPreviousPage = () => {
      const { push } = this.props;
      return push(`/customers/customers/${customerId}/edit`);
    };

    const { locationId, serviceContractIds } = getQueryParams(location.search);
    let upcomingServicesUrl = `/customers/customers/${customerId}/serviceHistory/upcomingServices?`;
    upcomingServicesUrl += locationId ? `locationId=${locationId}&` : '';
    upcomingServicesUrl += serviceContractIds ? `serviceContractIds=${serviceContractIds}&` : '';

    const currentServiceHistoryPageUrl = window.location.pathname + window.location.search;
    setCurrentServiceHistoryPageUrl(currentServiceHistoryPageUrl);

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction
                id={`customers-${customerId}-service-history-back-button`}
                onClick={goBackToPreviousPage}
              >
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate('customers.serviceHistory')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            {customerTypeSupportsJobAdd && !checkIfViewOnly() && (
              <Button
                line
                color="primary"
                id="add-job-button"
                margin="no xSmall no no"
                onClick={this.openJobEditorModal}
              >
                + {translate('routes.addJob')}
              </Button>
            )}
            <ButtonLink
              to={`/customers/customers/${customerId}/serviceHistory/communication`}
              color="primary"
              margin="no xSmall no no"
              id={`customers-${customerId}-service-history-communication-button`}
            >
              {translate('customers.emails.communication')}
            </ButtonLink>
            <ButtonLink
              to={upcomingServicesUrl}
              color="primary"
              margin="no xSmall no no"
              id={`customers-${customerId}-service-history-upcoming-services-button`}
            >
              {translate('customers.upcomingServices')}
            </ButtonLink>
            <Button
              id={`customers-${customerId}-service-history-export-button`}
              onClick={this.exportServiceHistory}
              color="primary"
              margin="no"
            >
              {translate('common.export')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isExporting}>
            <ServiceHistoryForm />
            <PanelSection>
              {!!size(serviceHistory) && (
                <Table
                  cells={serviceHistoryTableCells}
                  rows={serviceHistory}
                  rowComponent={ServiceHistoryTableRow}
                  rowProps={{
                    isMaterialTypesEnabled,
                    reasonCodeTypes,
                    vehicleType,
                    wasteAuditLocationStatusTypeId,
                    wasteAuditType,
                  }}
                  virtualized
                  virtualizedProps={virtualizedProps}
                />
              )}
              {!size(serviceHistory) && <Message padding="sMedium">{translate('customers.noServiceHistory')}</Message>}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>

        {jobEditorModalOpen && !!customerId && (
          <DispatchBoardJobEditorModalResolver
            customerId={customerId}
            closeModal={this.closeJobEditorModal}
            refreshUnassignedJobs={() => this.loadServiceHistory()}
          />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  customerName: state.customers.customer.customer?.name,
  customerTypeId: state.customers.customer.customer?.customerTypeId,
  isExporting: state.customers.serviceHistory.isExporting,
  isLoading: state.customers.serviceHistory.isLoading,
  isMaterialTypesEnabled: isMaterialTypesFeatureEnabled(state),
  reasonCodeTypes: state.common.reasonCodeTypes.reasonCodeTypes,
  serviceHistory: state.customers.serviceHistory.serviceHistory,
});

const mapDispatchToProps = {
  exportServiceHistory,
  loadSearchedLocation,
  loadServiceHistory,
  push,
  resetSearchedLocation,
  resetServiceHistory,
  setCurrentServiceHistoryPageUrl,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceHistoryPage));
