import {
  DELIVERY_UTILITY,
  FRONT_LOAD,
  RESIDENTIAL,
  ROLL_OFF,
  TOTER,
  ROLL_OFF_ID,
  FRONT_LOAD_ID,
  TOTER_ID,
  DELIVERY_UTILITY_ID,
  RESIDENTIAL_ID,
} from '../../fleet/constants';

export const DISPATCH_BOARD_VEHICLE_TYPES = [ROLL_OFF, FRONT_LOAD, TOTER, DELIVERY_UTILITY, RESIDENTIAL];

export const DISPATCH_BOARD_VEHICLE_TYPE_IDS = [
  ROLL_OFF_ID,
  FRONT_LOAD_ID,
  TOTER_ID,
  DELIVERY_UTILITY_ID,
  RESIDENTIAL_ID,
];

export const DISPATCH_BOARD_ROUTE_ITEM_HEIGHT = 90;

export const DISPATCH_BOARD_FORM_NAME = 'dispatchBoardForm';

export const DISPATCH_BOARD_SOURCE = 'dispatchBoardSource';

export const NONE_ID = 0;

export const UNASSIGNED_ROUTE_ID = -1;
export const ON_HOLD_ROUTE_ID = -2;
