import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { InvoiceSearchParams } from '../interfaces/InvoiceSearchParams';
import { exportInvoices as doExportInvoices, loadInvoices as doLoadInvoices } from '../services/loadInvoices';

//  Actions
const START_LOAD = 'opportunity/invoice/START_LOAD';
const START_EXPORT = 'opportunity/invoice/START_EXPORT';
const COMPLETE_LOAD = 'opportunity/invoice/COMPLETE_LOAD';
const FAIL_LOAD = 'opportunity/invoice/FAIL_LOAD';
const COMPLETE_EXPORT = 'opportunity/invoice/COMPLETE_EXPORT';
const FAIL_EXPORT = 'opportunity/invoice/FAIL_EXPORT';

//  Initial State
const intialState = {
  isLoading: false,
  isExporting: false,
  invoices: undefined,
  errorMessage: undefined,
};

//  Reducer
export const reducer = (state = intialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          invoices: action.invoices,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          invoices: undefined,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (invoices: any) => ({
  type: COMPLETE_LOAD,
  invoices,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const completeExport = (invoices: any) => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_LOAD,
});

export const loadInvoices = (findInvoicesObj: InvoiceSearchParams) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadInvoices(findInvoicesObj)
    .then((invoices: any) => dispatch(completeLoad(invoices)))
    .catch(() => dispatch(failLoad()));
};

export const exportInvoices = (findInvoiceObj: InvoiceSearchParams) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  doExportInvoices(findInvoiceObj)
    .then((invoiceData: any) => dispatch(completeExport(invoiceData)))
    .catch(() => dispatch(failExport()));
};
