import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';
import { orderBy } from 'lodash-es';

import { loadVehicleFuelEfficiencyLog as doLoadVehicleFuelEfficiencyLog } from '../services/vehicleFuelEfficiencyLog';
import { VehicleFuelEfficiencyLogState, VehicleFuelEfficiencyLog } from '../interfaces/VehicleFuelEfficiencyLog';

// Actions
const START_LOAD = 'vendors/vehicle/vehicleFuelEfficiencyLog/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicle/vehicleFuelEfficiencyLog/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicle/vehicleFuelEfficiencyLog/FAIL_LOAD';
const RESET = 'vendors/vehicle/vehicleFuelEfficiencyLog/RESET';

const initialState: VehicleFuelEfficiencyLogState = {
  fuelEfficiencyLog: undefined,
  fuelEfficiencyLogLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          fuelEfficiencyLogLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          fuelEfficiencyLog: {
            ...action.fuelEfficiencyLog,
            fuelEfficiencyLogDetails: orderBy(
              action.fuelEfficiencyLog.fuelEfficiencyLogDetails,
              fuelEfficiencyLogDetail => new Date(fuelEfficiencyLogDetail.fillUpTimeStamp).getTime(),
              'desc',
            ),
          },
          fuelEfficiencyLogLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          fuelEfficiencyLog: undefined,
          fuelEfficiencyLogLoading: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (fuelEfficiencyLog: VehicleFuelEfficiencyLog[]) => ({
  type: COMPLETE_LOAD,
  fuelEfficiencyLog,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetVehicleFuelEfficiencyLog = () => ({
  type: RESET,
});

export const loadVehicleFuelEfficiencyLog =
  (vehicleId: number, startDate: string, endDate: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());

    const promise = doLoadVehicleFuelEfficiencyLog(vehicleId, startDate, endDate);

    promise
      .then(fuelEfficiencyLog => {
        dispatch(completeLoad(fuelEfficiencyLog));
      })
      .catch(() => {
        dispatch(failLoad());
      });

    return promise;
  };
