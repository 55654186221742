import { size, sortBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useState, Fragment } from 'react';
import Cookie from 'js-cookie';

import { ALL_EQUIPMENTS_TYPES } from '../../constants/';
import { AppState } from '../../../store';
import { Button, MapContainer, Panel, PanelSection, PanelSectionGroup, Message } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { clearServiceAreasSelectedFeature, deleteServiceArea, loadServiceAreas } from '../../ducks/serviceAreas';
import { getAllEquipmentSizes, getEquipmentTypeId } from '../../utils/equipments';
import { HAULER_PROFILE_DIVISION_COOKIE } from '../../constants/HaulerProfileDivisionCookie';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { ServiceAreaEditorModal } from '../modals';
import { ServiceAreaOptions, ServiceAreas, ZipCode } from '../../interfaces/ServiceArea';
import { ServiceAreasFilterForm } from '../forms';
import { Table } from '../../../core/components';
import { ZipCodeBoundaries } from 'src/haulerProfile/interfaces/ZipCodeBoundaries';
import confirm from '../../../core/services/confirm';
import defaultDivisionFormInitialValuesSelector from '../../services/defaultDivisionFormInitialValuesSelector';
import ServiceAreasMapGL from '../ServiceAreasMapGL';
import ServiceAreasTableRow from './ServiceAreasTableRow';
import translate from '../../../core/services/translate';

const serviceAreaTableCells = [
  { name: 'equipmentType', label: translate('common.equipmentType'), width: '20%', sortable: true },
  { name: 'equipmentSize', label: translate('common.equipmentSize'), width: '15%', sortable: true },
  { name: 'materials', label: translate('common.materials'), width: '30%', sortable: true },
  { name: 'zipCodes', label: translate('haulerProfile.serviceArea'), width: '25%', sortable: false },
  { name: 'options', label: translate('common.options'), width: '10%', sortable: false },
];

const getServiceAreasRows = (serviceAreas: ServiceAreas) =>
  sortBy(
    (serviceAreas?.equipments || []).map(({ equipment, materials, zipCodes }) => ({
      equipmentType: translate(`haulerProfile.equipments.equipmentSubTypes.${getEquipmentTypeId(equipment)}`),
      equipmentSize: equipment.binClass
        ? translate(`haulerProfile.equipments.equipmentTypes.${equipment.binClass}`)
        : equipment.other,
      materials: materials.map(material => translate(`haulerProfile.materialTypes.${material.matSubType}`)).join(', '),
      zipCodes: zipCodes.map(zipCode => zipCode.zipCode).join(', '),
      equipmentId: equipment.id,
      equipmentCode: getEquipmentTypeId(equipment),
    })),
    ['equipmentType', 'equipmentSize'],
  );

const getFiltersInitialState = (serviceAreaOptions?: ServiceAreaOptions) => ({
  equipmentTypes: ALL_EQUIPMENTS_TYPES,
  equipmentSizes: getAllEquipmentSizes(serviceAreaOptions?.savedEquipments || []).map(
    equipmentSize => equipmentSize.vendorGroupEquipmentId,
  ),
  materials: (serviceAreaOptions?.savedMaterials || []).map(material => material.materialId),
});

export default function ServiceAreasPage() {
  const dispatch = useDispatch();
  const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);

  const haulerId = useSelector((state: AppState) =>
    currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  );
  const formInitialValues = useSelector((state: AppState) =>
    defaultDivisionFormInitialValuesSelector(state.haulerProfile.divisions),
  ) as any;
  //const divisions = useSelector((state: AppState) => state.haulerProfile.divisions.divisions);
  const serviceAreaOptions = useSelector((state: AppState) => state.haulerProfile.serviceAreas.serviceAreaOptions);
  const {
    isDeleting,
    isLoading,
    isSaving,
    serviceAreas: allServiceAreas,
  } = useSelector((state: AppState) => state.haulerProfile.serviceAreas);
  const { zipCodeBoundaries } = useSelector((state: AppState) => state.haulerProfile.zipCodeBoundaries);

  const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues?.divisionId;

  const [filters, setFilters] = useState(getFiltersInitialState(serviceAreaOptions));
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [isServiceAreaEditorModalOpen, setIsServiceAreaEditorModalOpen] = useState(false);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<number | undefined>();

  const handleSubmitFilterForm = (formValues: any) => {
    setFilters({ ...formValues });
    setIsFiltersApplied(true);
  };

  const filterServiceAreas = () => {
    const {
      equipmentTypes: selectedEquipments,
      equipmentSizes: selectedEquipmentSizes,
      materials: selectedMaterials,
    } = filters;

    const filteredEquipments = (allServiceAreas?.equipments || []).filter(
      ({ equipment, materials }) =>
        (selectedEquipments === ALL_EQUIPMENTS_TYPES || selectedEquipments === getEquipmentTypeId(equipment)) &&
        selectedEquipmentSizes.includes(equipment.id) &&
        materials.some(material => selectedMaterials.includes(material.vendGroupEquipmentMaterialId)),
    );

    const filteredZipCodes = (allServiceAreas?.zipCodes || []).filter(
      ({ equipments, materials }) =>
        equipments.some(
          equipment =>
            selectedEquipments === ALL_EQUIPMENTS_TYPES || selectedEquipments === getEquipmentTypeId(equipment),
        ) &&
        equipments.some(equipment => selectedEquipmentSizes.includes(equipment.id)) &&
        materials.some(material => selectedMaterials.includes(material.vendGroupEquipmentMaterialId)),
    );
    return { equipments: filteredEquipments, zipCodes: filteredZipCodes };
  };

  const openServiceAreaEditorModal = (equipmentId: number | undefined) => {
    setIsServiceAreaEditorModalOpen(true);
    setSelectedEquipmentId(equipmentId);
  };

  const closeServiceAreaEditorModal = async (formPristine?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    setIsServiceAreaEditorModalOpen(false);
    setSelectedEquipmentId(undefined);
    dispatch(clearServiceAreasSelectedFeature());
  };

  const removeServiceArea = async (equipmentId: number) => {
    if (!(await confirm(translate('haulerProfile.alertMessages.confirmDeleteServiceArea')))) {
      return;
    }

    deleteServiceArea(
      haulerId,
      equipmentId,
      divisionIdFromCookie,
    )(dispatch)
      .then(() => {
        loadServiceAreas(haulerId, divisionIdFromCookie)(dispatch);
        createSuccessNotification(translate('haulerProfile.alertMessages.serviceAreaDeleted'));
      })
      .catch(() => {
        createErrorNotification(translate('haulerProfile.alertMessages.serviceAreaDeleteError'));
      });
  };

  // const onDivisionChange = (divisionId: string) => {
  //   Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, { expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION });
  //   window.history.pushState(null, '', `?divisionId=${divisionId}`);
  //   loadServiceAreas(haulerId, divisionId)(dispatch);
  // };
  const filteredServiceAreas = filterServiceAreas();

  const filteredZipFeatures = (zipCodes: string[], zipCodeBoundaries?: ZipCodeBoundaries) =>
    (zipCodeBoundaries?.features || []).filter(feature => zipCodes?.includes(feature.properties.zip)) || [];
  const zipCodes = size(filteredServiceAreas.equipments)
    ? filteredServiceAreas.zipCodes.map((zipCode: { zipCode: ZipCode }) => zipCode.zipCode.zipCode)
    : [];
  const filteredFeatures = filteredZipFeatures(zipCodes, zipCodeBoundaries);

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('haulerProfile.services')}</PageTitle>
            {/* Hide DivisionForm for current Sprint-119 */}
            {/* <DivisionForm initialValues={formInitialValues} divisions={divisions} onDivisionChange={onDivisionChange} /> */}
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <Button
            color="primary"
            id="add-service-area-button"
            margin="no xSmall no no"
            onClick={() => openServiceAreaEditorModal(undefined)}
          >
            {translate('haulerProfile.addServiceArea')}
          </Button>
        </PageActions>
      </PageHeader>

      <Panel>
        <PanelSectionGroup isLoading={isLoading || isSaving || isDeleting}>
          {divisionId ? (
            <Fragment>
              <ServiceAreasFilterForm onSubmit={handleSubmitFilterForm} />
              <PanelSection>
                <MapContainer size="large">
                  <ServiceAreasMapGL
                    equipmentTypes={filters.equipmentTypes}
                    filteredFeatures={filteredFeatures}
                    isFiltersApplied={isFiltersApplied}
                    isServiceAreaEditorModalOpen={isServiceAreaEditorModalOpen}
                    isSourceServiceAreasPage={true}
                    setIsFiltersApplied={setIsFiltersApplied}
                  />
                </MapContainer>
              </PanelSection>

              <PanelSection>
                {size(filteredServiceAreas.equipments) ? (
                  <Table
                    cells={serviceAreaTableCells}
                    rows={getServiceAreasRows(filteredServiceAreas)}
                    rowComponent={ServiceAreasTableRow}
                    rowProps={{
                      editServiceArea: openServiceAreaEditorModal,
                      removeServiceArea,
                    }}
                  />
                ) : (
                  <Message padding="sMedium">{translate('haulerProfile.noServiceAreas')}</Message>
                )}
              </PanelSection>
            </Fragment>
          ) : (
            <Message padding="sMedium">{translate('common.alertMessages.invalidDivisionId')}</Message>
          )}
          {isServiceAreaEditorModalOpen && (
            <ServiceAreaEditorModal equipmentId={selectedEquipmentId} closeModal={closeServiceAreaEditorModal} />
          )}
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
}
