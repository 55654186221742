import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const SATISFACTORY = 'Satisfactory';
export const CONDITIONAL = 'Conditional';
export const UNSATISFACTORY = 'Unsatisfactory';

export const FMCSA_SCORE_OPTIONS = mapKeys(
  [
    { id: SATISFACTORY, name: translate('haulerProfile.fmcsaScoreOptions.satisfactory') },
    { id: CONDITIONAL, name: translate('haulerProfile.fmcsaScoreOptions.conditional') },
    { id: UNSATISFACTORY, name: translate('haulerProfile.fmcsaScoreOptions.unsatisfactory') },
  ],
  'id',
);
