import React from 'react';
import { useDispatch } from 'react-redux';

import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { loadFiltersSettings, loadWasteTypes } from 'src/common/ducks';
import { loadGroups } from 'src/routes/ducks';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from '../../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RoutePlannerMainPage from './RoutePlannerMainPage';
import translate from 'src/core/services/translate';

const RoutePlannerMainPageResolver: React.FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);

  const loadDependencies = React.useCallback(() => {
    const dependencies: any[] = [
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadGroups(vendorId)(dispatch),
    ];

    if (userId) {
      dependencies.push(loadFiltersSettings(vendorId, userId)(dispatch));
    }

    return Promise.all(dependencies);
  }, [dispatch, userId, vendorId]);

  return (
    <>
      <DocumentTitle>{translate('routes.planner.routePlanner')}</DocumentTitle>
      <TypedResolver
        successComponent={RoutePlannerMainPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
      />
    </>
  );
};

export default RoutePlannerMainPageResolver;
