import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { formatNumber } from 'src/utils/services/formatter';
import { AgingReports } from '../../interfaces/Billing';

export const getChartData = (report?: AgingReports) => {
  if (!report?.items) {
    return null;
  }
  const total = `$${formatNumber(report.reportTotal)}` || '-';
  const data: any[] = [];
  report.items.map(item =>
    data.push({
      label: translate(createTranslationKey(item.code, 'finance.billAging')),
      value: item.value,
      rawValue: `$${formatNumber(Number(item.value))}`,
    }),
  );
  return {
    labels: data.map(item => item.label),
    data: data.map(item => item.value),
    rawDataValues: data.map(item => item.rawValue),
    total: total,
  };
};
