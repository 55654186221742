import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ACTIVE = 1;
export const NEW = 2;
export const SUSPENDED = 3;
export const EXTRA_PICKUP = 4;
export const ON_HOLD = 5;

export const ACCOUNT_STATUSES = mapKeys(
  [
    { id: ACTIVE, name: translate('routes.accountStatuses.active'), technicalName: 'Active' },
    { id: NEW, name: translate('routes.accountStatuses.new'), technicalName: 'New' },
    { id: SUSPENDED, name: translate('routes.accountStatuses.suspended'), technicalName: 'Suspended' },
    { id: EXTRA_PICKUP, name: translate('routes.accountStatuses.extraPickup'), technicalName: 'ExtraPickup' },
    { id: ON_HOLD, name: translate('routes.accountStatuses.onHold'), technicalName: 'OnHold' },
  ],
  'id',
);
