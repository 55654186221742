import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { AppState } from '../../store';
import { RouteBuilderSettingsState } from '../interfaces/RouteBuilderSettings';
import { RouteSettings } from '../interfaces/RouteSettings';

const getRouteBuilderSettingsFormInitialValuesSelector = (routeBuilderSettingsState: RouteBuilderSettingsState) =>
  routeBuilderSettingsState.routeBuilderSettings;

export const routeBuilderSettingsFormInitialValuesSelector = createSelector<
  RouteBuilderSettingsState,
  RouteSettings | undefined,
  RouteSettings | undefined
>(getRouteBuilderSettingsFormInitialValuesSelector, identity);

export const routeBuilderSettingsFormInitialValues = (state: AppState) =>
  routeBuilderSettingsFormInitialValuesSelector(state.vendors.routeBuilderSettings);
