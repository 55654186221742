import styled from 'styled-components';
import { Icon } from '../../../core/components';
import { BareButtonStyle } from '../../../core/components/styled';

export const ContractCodeIcon = styled(Icon).attrs({ icon: 'infoFill' })`
  width: 12px;
  height: 18px;
  position: absolute;
  font-size: 10px;
  color: ${props => props.theme.brandPrimary};
`; 

export const ContractCodeIconPopup = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 10px;
`;
