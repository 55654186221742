import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ALL_EQUIPMENTS_TYPES, EQUIPMENT_TYPES } from '../constants';
import { clearServiceAreasSelectedFeature } from '../ducks/serviceAreas';
import { getEquipmentTypeId } from '../utils/equipments';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupWrapper,
  MapGLPopupTitle,
} from 'src/common/components/map/MapGLPopup';
import { useSelector } from 'src/core/hooks/useSelector';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  equipmentTypes: string;
  equipmentSizes?: string[];
}

const ServiceAreasGLPopup: FC<Props> = ({ equipmentTypes, equipmentSizes }) => {
  const dispatch = useDispatch();

  const { selectedFeature } = useSelector(state => state.haulerProfile.serviceAreas);
  const zipCodes = useSelector(state => state.haulerProfile.serviceAreas.serviceAreas?.zipCodes) || [];

  if (!selectedFeature) return null;

  const zipCode = zipCodes.find(zipCode => zipCode.zipCode.zipCode === selectedFeature.id.toString());

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      onClose={() => dispatch(clearServiceAreasSelectedFeature())}
      latitude={selectedFeature.coordinates.lat}
      longitude={selectedFeature.coordinates.lng}
    >
      <MapGLPopup minHeight={0}>
        <MapGLPopupTitle title={selectedFeature.id.toString()} />

        <MapGLPopupContent>
          {EQUIPMENT_TYPES.filter(et => equipmentTypes === ALL_EQUIPMENTS_TYPES || equipmentTypes === et).map(
            (equipmentType, index) => {
              const equipmentSizesByZip = (zipCode?.equipments || [])
                .filter(equipment => equipmentType === getEquipmentTypeId(equipment))
                .map(
                  equipment =>
                    equipment.other || translate(`haulerProfile.equipments.equipmentTypes.${equipment.binClass}`),
                );

              const allEquipmentSize = equipmentSizes?.length ? equipmentSizes : equipmentSizesByZip;

              return allEquipmentSize?.length ? (
                <LabeledDataView
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentType}`)}
                  value={allEquipmentSize?.join(', ')}
                  key={index}
                />
              ) : null;
            },
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
};

export default ServiceAreasGLPopup;
