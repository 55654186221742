import { ChangeEvent, FC, useCallback, useMemo, useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { RouteComponentProps, useLocation } from 'react-router';
import { size } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { BOTTOM, MAP_CITY_ZOOM, TABLE_ROW_HEIGHT_LARGEST } from 'src/core/constants';
import { Button, MapContainer, Message, Panel, PanelSection, Text } from 'src/core/components/styled';
import { CITY_ALERT_TYPE_ID, LOCATION_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { CityAlertSetting } from 'src/vendors/interfaces/CityAlertSettings';
import { clearRouteMapSelectedFeature, setRouteMapSelectedFeature } from 'src/routes/ducks';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { getActiveCityAlertTypes } from '../forms/LocationAndCityAlertsSearchForm';
import { getMapBounds } from 'src/common/components/map/util';
import { getTextWithAccentSections } from 'src/landing/components/services';
import { isLocationAlertFeatureEnabled, isCityAlertFeatureEnabled } from 'src/vendors/ducks/features';
import { loadLocationAlerts } from 'src/vendors/ducks';
import {
  loadLocationAndCityAlertsForMap,
  loadLocationAndCityAlertsForList,
  setLocationAndCityAlertsMapViewport,
} from 'src/fleet/ducks';
import { LocationAlert } from 'src/vendors/interfaces/LocationAlert';
import { LocationAndCityAlertsForList, LocationAndCityAlertsForMap } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { LocationAndCityAlertsImportModalResolver } from '../modal/LocationAndCityAlertsImportModalResolver';
import { LocationAndCityAlertsSearchForm } from '../forms';
import { LocationAndCityAlertsTableRow } from './';
import { MapDragHandle, Table } from 'src/core/components';
import {
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
  PageActions,
} from 'src/common/components/styled';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { setSelectedLocationAlerts } from 'src/fleet/ducks/locationAndCityAlerts';
import { TODAY_FORMATTED, THREE_DAYS_BEFORE_TODAY } from 'src/core/constants';
import CityAlertCreateModalResolver from 'src/dashboard/components/modals/CityAlertCreateModal/CityAlertCreateModalResolver';
import createPopover from 'src/core/services/createPopover';
import LocationAndCityAlertsCreateAlert from './locationAndCityAlertsSections/LocationAndCityAlertsCreateAlert';
import LocationAndCityAlertsImportAlerts from './locationAndCityAlertsSections/LocationAndCityAlertsImportAlerts';
import LocationAndCityAlertsMapGL from './locationAndCityAlertsSections/LocationAndCityAlertsMapGL';
import translate from 'src/core/services/translate';

const locationAndCityAlertsTableCellsWidth = ['15%', '12%', '15%', '14%', '13%', '12%', '11%', '8%'];

const locationAndCityAlertsTableCells = [
  {
    name: 'createdOn',
    label: translate('vendors.cityAlerts.dateLastFlagged'),
    width: locationAndCityAlertsTableCellsWidth[0],
    sortable: true,
  },
  {
    name: 'cityAlertTypeName',
    label: translate('vendors.cityAlerts.alert'),
    width: locationAndCityAlertsTableCellsWidth[1],
    sortable: true,
  },
  {
    name: 'address',
    label: translate('vendors.cityAlerts.approximateLocation'),
    width: locationAndCityAlertsTableCellsWidth[2],
    sortable: true,
  },
  {
    name: 'routeName',
    label: translate('routes.route'),
    width: locationAndCityAlertsTableCellsWidth[3],
    sortable: true,
  },
  {
    name: 'expirationDate',
    label: translate('vendors.cityAlerts.expiration'),
    width: locationAndCityAlertsTableCellsWidth[4],
    sortable: true,
  },
  {
    name: 'vehicleName',
    label: translate('common.vehicleDriver'),
    width: locationAndCityAlertsTableCellsWidth[5],
    sortable: true,
  },
  {
    name: 'isActive',
    label: translate('insights.status'),
    width: locationAndCityAlertsTableCellsWidth[6],
    sortable: true,
  },
  {
    name: 'options',
    label: translate('common.options'),
    width: locationAndCityAlertsTableCellsWidth[7],
    align: 'right',
    noPaddingRight: true,
  },
];

interface Props extends RouteComponentProps {
  alertsPageTitle: string;
  showCityAlertsUploadStatus?: boolean;
  showLocationAlertsUploadStatus?: boolean;
  vendorId: number;
}

const LocationAndCityAlertsPage: FC<Props> = ({
  alertsPageTitle,
  showCityAlertsUploadStatus,
  showLocationAlertsUploadStatus,
  vendorId,
}) => {
  const dispatch = useDispatch();

  const { pathname, search } = useLocation();
  const params = getQueryParams(search);

  const { locationAndCityAlertsForMap, locationAndCityAlertsForList, isLoadingForMap, isLoadingForList } = useSelector(
    (state: AppState) => state.fleet.locationAndCityAlerts,
  );
  const { isLoadingImages } = useSelector((state: AppState) => state.vendors.cityAlerts);
  const { selectedFeature } = useSelector((state: AppState) => state.routes.mapControls);
  const { selectedLocationAlertsIds } = useSelector((state: AppState) => state.fleet.locationAndCityAlerts);
  const isLocationAlertEnabled = useSelector(isLocationAlertFeatureEnabled);

  const cityAlertSettings = useSelector((state: AppState) => state.vendors.cityAlertSettings?.cityAlertSettings);
  const cityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
  const cityAlertOptions = cityAlertTypes?.map((option: CityAlertSetting) => option.cityAlertType.id).toString();
  const isCityAlertEnabled = useSelector(isCityAlertFeatureEnabled);

  const [isListAlertsLoaded, setIsListAlertsLoaded] = useState(false);
  const [rowScrollTopPosition, setRowScrollTopPosition] = useState(0);
  const [isAlertsImportModalVisible, setIsAlertsImportModalVisible] = useState(
    showCityAlertsUploadStatus || showLocationAlertsUploadStatus || false,
  );
  const [isCreateCityAlertModalVisible, setIsCreateCityAlertModalVisible] = useState(false);
  const [isCreateLocationAlertModalVisible, setIsCreateLocationAlertModalVisible] = useState(false);
  const [visibleModalType, setVisibleModalType] = useState<number>(
    showCityAlertsUploadStatus ? CITY_ALERT_TYPE_ID : showLocationAlertsUploadStatus ? LOCATION_ALERT_TYPE_ID : 0,
  );

  const virtualizedProps = useMemo(
    () => ({
      height:
        Math.min(locationAndCityAlertsForList.length * TABLE_ROW_HEIGHT_LARGEST, TABLE_ROW_HEIGHT_LARGEST * 8) || 1,
      itemSize: TABLE_ROW_HEIGHT_LARGEST,
    }),
    [locationAndCityAlertsForList],
  );

  useEffect(() => {
    if (!selectedFeature?.noTableScroll) {
      const alertIndex = locationAndCityAlertsForList.findIndex(
        (alert: LocationAndCityAlertsForList) => alert.id === selectedFeature?.id,
      );
      let rowScrollTopPosition = alertIndex * TABLE_ROW_HEIGHT_LARGEST + 1;
      if (locationAndCityAlertsForList.length > 8) {
        if (alertIndex > locationAndCityAlertsForList.length - 7) {
          rowScrollTopPosition = (locationAndCityAlertsForList.length - 7) * TABLE_ROW_HEIGHT_LARGEST + 1;
        }
        setRowScrollTopPosition(rowScrollTopPosition);
      }
    }
  }, [locationAndCityAlertsForList, selectedFeature]);

  const handleSubmit = useCallback(
    ({
      date,
      searchTerm,
      statusTypeIds,
      cityAlertTypeIds,
      locationAlertTypeIds,
    }: {
      date: DateRangeOptionValue;
      searchTerm: string;
      statusTypeIds: number[];
      cityAlertTypeIds: number[];
      locationAlertTypeIds: number[];
    }) => {
      const { from, to } = date;

      const searchParams = {
        startDate: from,
        endDate: to,
        searchTerm,
        statusTypeIds: statusTypeIds?.toString(),
        cityAlertTypeIds: cityAlertTypeIds?.length ? cityAlertTypeIds?.toString() : undefined,
        noCityAlertTypeSelected: !cityAlertTypeIds?.length,
        noLocationAlertTypeSelected: !locationAlertTypeIds?.length,
      };

      dispatch(setSelectedLocationAlerts(locationAlertTypeIds || []));
      dispatch(push(createUrl(pathname, undefined, { ...searchParams })));

      loadLocationAndCityAlertsForMap({ ...searchParams, locationAlertTypeIds: locationAlertTypeIds?.toString() })(
        dispatch,
      );
      isListAlertsLoaded &&
        loadLocationAndCityAlertsForList({ ...searchParams, locationAlertTypeIds: locationAlertTypeIds?.toString() })(
          dispatch,
        );
    },
    [dispatch, pathname, isListAlertsLoaded],
  );

  const handleLoadAlerts = async (
    shouldLoadAlertsForList?: boolean,
    shouldLoadAlertsForMap?: boolean,
    shouldChangeUrlParmas?: boolean,
    id?: number,
  ) => {
    const { cityAlertTypeIds } = params;

    const searchParams = {
      ...params,
      cityAlertTypeIds: shouldChangeUrlParmas
        ? cityAlertOptions
        : params.noCityAlertTypeSelected
        ? undefined
        : cityAlertTypeIds?.length
        ? cityAlertTypeIds
        : cityAlertOptions,
      noCityAlertTypeSelected: shouldChangeUrlParmas ? false : params.noCityAlertTypeSelected,
      noLocationAlertTypeSelected: shouldChangeUrlParmas ? false : params.noLocationAlertTypeSelected,
      startDate: params.startDate || THREE_DAYS_BEFORE_TODAY,
      endDate: params.endDate || TODAY_FORMATTED,
    };

    let locationAlertNewOptions;
    if (isLocationAlertEnabled) {
      const locationAlerts = await loadLocationAlerts(vendorId)(dispatch);
      locationAlertNewOptions = locationAlerts?.map((locationAlert: LocationAlert) => locationAlert.id);
    }

    const newSearchParams = {
      ...searchParams,
      locationAlertTypeIds: shouldChangeUrlParmas
        ? locationAlertNewOptions?.toString()
        : params.noLocationAlertTypeSelected
        ? undefined
        : selectedLocationAlertsIds?.length
        ? selectedLocationAlertsIds?.toString()
        : locationAlertNewOptions?.toString(),
    };

    if (shouldChangeUrlParmas) {
      dispatch(setSelectedLocationAlerts(locationAlertNewOptions));
      dispatch(push(createUrl(pathname, undefined, { ...searchParams })));
    }

    shouldLoadAlertsForList &&
      loadLocationAndCityAlertsForList(newSearchParams)(dispatch).then(() => {
        setIsListAlertsLoaded(true);
      });

    shouldLoadAlertsForMap &&
      loadLocationAndCityAlertsForMap(newSearchParams)(dispatch).then((response: any) => {
        if (!!id) {
          dispatch(clearRouteMapSelectedFeature());
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.cityAlert, Number(id)));

          const newAlert = response.locationAndCityAlertsForMap.find(
            (alert: LocationAndCityAlertsForMap) => alert.id === id,
          );

          if (newAlert) {
            const bounds = getMapBounds([{ longitude: newAlert.longitude, latitude: newAlert.latitude }], {
              padding: 25,
              capZoom: MAP_CITY_ZOOM,
            });

            dispatch(setLocationAndCityAlertsMapViewport(bounds));
          }
        }
      });
  };

  const onSaveLocationAndCityAlert = () => {
    const shouldLoadAlertsForList = true;
    const shouldLoadAlertsForMap = true;
    handleLoadAlerts(shouldLoadAlertsForList, shouldLoadAlertsForMap);
  };

  const handleLoadCityAlertsWhenCreatedNew = (id?: number) => {
    const shouldLoadAlertsForMap = true;
    const shouldChangeUrlParmas = true;
    const shouldLoadAlertsForList = isListAlertsLoaded;

    handleLoadAlerts(shouldLoadAlertsForList, shouldLoadAlertsForMap, shouldChangeUrlParmas, id);
  };

  const displayAlertsImportModalVisibility = (type: number) => {
    setIsAlertsImportModalVisible(true);
    setVisibleModalType(type);
  };

  const importAlertsPopover = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    createPopover(
      event.target,
      LocationAndCityAlertsImportAlerts,
      {
        isCityAlertEnabled,
        isLocationAlertEnabled,
        displayAlertsImportModalVisibility,
      },
      {
        position: BOTTOM,
        tabletRightAlign: true,
        zIndex: 100,
      },
    );
  };

  const createAnAlertPopover = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    createPopover(
      event.target,
      LocationAndCityAlertsCreateAlert,
      {
        isCityAlertEnabled,
        isLocationAlertEnabled,
        setIsCreateCityAlertModalVisible,
        setIsCreateLocationAlertModalVisible,
      },
      {
        position: BOTTOM,
        tabletRightAlign: true,
        zIndex: 100,
      },
    );
  };

  const handleCloseCreateAlertModal = useCallback(() => {
    setIsCreateCityAlertModalVisible(false);
    setIsCreateLocationAlertModalVisible(false);
  }, []);

  return (
    <PageContent isLoading={isLoadingImages}>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{alertsPageTitle}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions align="right">
          {(isCityAlertEnabled || isLocationAlertEnabled) && (
            <>
              <Button
                color="primary"
                onClick={(event: ChangeEvent<HTMLInputElement>) => importAlertsPopover(event)}
                id="import-alerts-button"
              >
                {translate('vendors.cityAlerts.importAlerts')}
              </Button>
              <Button
                color="primary"
                onClick={(event: ChangeEvent<HTMLInputElement>) => createAnAlertPopover(event)}
                id="create-alert-button"
                margin="no no no small"
              >
                {translate('vendors.cityAlerts.createAlert')}
              </Button>
            </>
          )}
        </PageActions>
      </PageHeader>

      <Panel margin="no">
        <PanelSection display="block">
          <LocationAndCityAlertsSearchForm onSubmit={handleSubmit} />

          <PanelSection isLoading={isLoadingForMap}>
            <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
              <MapContainer size="large">
                <LocationAndCityAlertsMapGL locationAndCityAlertsForMap={locationAndCityAlertsForMap} />
              </MapContainer>
            </Resizable>
          </PanelSection>

          {isListAlertsLoaded ? (
            <>
              {locationAndCityAlertsForList && (
                <PanelSection isLoading={isLoadingForList}>
                  <Table
                    cells={locationAndCityAlertsTableCells}
                    rows={locationAndCityAlertsForList}
                    rowComponent={LocationAndCityAlertsTableRow}
                    rowProps={{
                      locationAndCityAlertsTableCellsWidth,
                      onSaveLocationAndCityAlert,
                    }}
                    withClickableRows
                    virtualized
                    noOverflow
                    virtualizedProps={virtualizedProps}
                    rowScrollTopPosition={rowScrollTopPosition}
                  />
                </PanelSection>
              )}

              {!size(locationAndCityAlertsForList) && (
                <Message padding="sMedium">{translate('vendors.cityAlerts.noLocationOrCityAlertsFound')}</Message>
              )}
            </>
          ) : (
            <PanelSection padding="large no" isLoading={isLoadingForList} centered>
              <Text onClick={() => handleLoadAlerts(true)} size="large" cursor="pointer">
                {getTextWithAccentSections(translate(`vendors.cityAlerts.loadAlerts`))}
              </Text>
            </PanelSection>
          )}
        </PanelSection>

        {isAlertsImportModalVisible && (
          <LocationAndCityAlertsImportModalResolver
            closeModal={() => setIsAlertsImportModalVisible(false)}
            visibleModalType={visibleModalType}
          />
        )}

        {isCreateCityAlertModalVisible && (
          <CityAlertCreateModalResolver
            isCityAlert={true}
            isSourceLocationAndCityAlerts={true}
            modalTitle={translate(`vendors.cityAlerts.createCityAlert`)}
            onSavedSuccess={handleLoadCityAlertsWhenCreatedNew}
            onClose={handleCloseCreateAlertModal}
          />
        )}

        {isCreateLocationAlertModalVisible && (
          <CityAlertCreateModalResolver
            isLocationAlert={true}
            isSourceLocationAndCityAlerts={true}
            modalTitle={translate(`vendors.cityAlerts.createLocationAlert`)}
            onSavedSuccess={handleLoadCityAlertsWhenCreatedNew}
            onClose={handleCloseCreateAlertModal}
          />
        )}
      </Panel>
    </PageContent>
  );
};

export default LocationAndCityAlertsPage;
