import Cookie from 'js-cookie';
import { filter, map, size } from 'lodash-es';
import moment from 'moment';

import { hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import { VehicleType } from 'src/fleet/interfaces/VehicleTypes';
import { getQueryParams } from 'src/utils/services/queryParams';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { ROUTE_PLANNER_VEHICLE_TYPES_COOKIE } from '../constants';
import {
  ROUTE_PLANNER_DAYS_OF_SERVICE_COOKIE,
  ROUTE_PLANNER_SELECTED_DATE,
  ROUTE_PLANNER_VEHICLE_TYPE_COOKIE,
} from '../constants/routePlanner';
import { getRoutePlannerPersistentFilters } from './routePlannerFilterStorage';

export const getRoutePlannerOptions = (
  vehicleTypesForVendor: VehicleType[],
  filtersPreferences: FilterSetting[],
  search?: string,
) => {
  const valuesFromUrl = search && getQueryParams(search);

  const routePlannerFiltersFromStorage = getRoutePlannerPersistentFilters();

  const vehicleTypeIdFromUrl = valuesFromUrl?.vehicleTypeId;
  const vehicleTypeIdsFromUrl = map(valuesFromUrl?.vehicleTypeIds?.toString().split(','), Number);
  const vehicleTypesIds = vehicleTypesForVendor.map((vehicleType: any) => vehicleType.id) as number[];
  const dayOfServiceIdFromUrl = valuesFromUrl?.dayOfServiceId ? Number(valuesFromUrl?.dayOfServiceId) : undefined;
  const selectedDateFromUrl = valuesFromUrl?.selectedDay ? moment(valuesFromUrl?.selectedDay).toDate() : undefined;
  const groupIdsFromUrl = valuesFromUrl?.groupIds
    ? map(valuesFromUrl.groupIds.toString().split(','), Number)
    : undefined;

  const acceptedVehicleTypesIds = filter(
    vehicleTypeIdsFromUrl,
    el => vehicleTypesIds.includes(el) && hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: el }),
  );

  const acceptedVehicleTypeId =
    vehicleTypeIdFromUrl &&
    vehicleTypesIds.includes(vehicleTypeIdFromUrl) &&
    hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: vehicleTypeIdFromUrl })
      ? (vehicleTypeIdFromUrl as number)
      : undefined;

  const routePlannerVehicleTypeIdsFromUrl = size(acceptedVehicleTypesIds) > 0 ? acceptedVehicleTypesIds : [];

  const routePlannerVehicleTypeIdsFromCookie = Cookie.get(ROUTE_PLANNER_VEHICLE_TYPES_COOKIE)
    ? filter(map(Cookie.get(ROUTE_PLANNER_VEHICLE_TYPES_COOKIE)?.split(','), Number), (el: number) => {
        return (
          vehicleTypesIds.includes(el) && hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: el })
        );
      })
    : [];

  const routePlannerVehicleTypeIdFromUrl = acceptedVehicleTypeId ? acceptedVehicleTypeId : null;

  const routePlannerVehicleTypeIdFromCookie = Cookie.get(ROUTE_PLANNER_VEHICLE_TYPE_COOKIE)
    ? vehicleTypesIds.includes(Number(Cookie.get(ROUTE_PLANNER_VEHICLE_TYPE_COOKIE))) &&
      hasPermissionBasedOnProperties(filtersPreferences, {
        vehicleTypeId: Number(Cookie.get(ROUTE_PLANNER_VEHICLE_TYPE_COOKIE)),
      })
      ? Number(Cookie.get(ROUTE_PLANNER_VEHICLE_TYPE_COOKIE))
      : undefined
    : undefined;

  const dayOfServiceIdFromCookie = Cookie.get(ROUTE_PLANNER_DAYS_OF_SERVICE_COOKIE)
    ? Number(Cookie.get(ROUTE_PLANNER_DAYS_OF_SERVICE_COOKIE))
    : undefined;

  const selectedDateFromCookie = Cookie.get(ROUTE_PLANNER_SELECTED_DATE)
    ? Cookie.get(ROUTE_PLANNER_SELECTED_DATE)
    : undefined;

  const groupIdsFromStorage = routePlannerFiltersFromStorage?.groupIds
    ? routePlannerFiltersFromStorage?.groupIds.toString().split(',').map(Number)
    : undefined;

  return {
    vehicleTypeId: routePlannerVehicleTypeIdFromUrl || routePlannerVehicleTypeIdFromCookie,
    vehicleTypeIds:
      routePlannerVehicleTypeIdsFromUrl || size(routePlannerVehicleTypeIdsFromCookie)
        ? routePlannerVehicleTypeIdsFromCookie
        : [],
    dayOfServiceId: dayOfServiceIdFromUrl || dayOfServiceIdFromCookie || 1,
    selectedDate: selectedDateFromUrl || selectedDateFromCookie || undefined,
    groupIds: groupIdsFromUrl || groupIdsFromStorage || undefined,
  };
};
