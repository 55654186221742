import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import {
  Button,
  Grid,
  Message,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  ModalFixedHeader,
  ModalTitle,
} from 'src/core/components/styled';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { LowInventoryParts } from 'src/fleet/interfaces/ContainerStatistics';
import { LowInventoryPartsTableRow } from './';
import { Modal, Table } from 'src/core/components';
import AddRemoveInventoryModalResolver from './AddRemoveInventoryModalResolver';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: () => void;
  containerTypeId: number;
  isLoading: boolean;
  lowInventoryParts: LowInventoryParts[];
}

interface State {
  isAddRemoveInventoryModalVisible: boolean;
}

class LowInventoryPartsModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAddRemoveInventoryModalVisible: false,
    };
  }

  setIsAddRemoveInventoryModalVisible = (isVisible: boolean) => {
    this.setState({ isAddRemoveInventoryModalVisible: isVisible });
  };

  render() {
    const { closeModal, containerTypeId, isLoading, lowInventoryParts } = this.props;
    const { isAddRemoveInventoryModalVisible } = this.state;

    const isViewOnly = checkIfViewOnly();
    const isSupport = checkIfSupport();

    const lowInventoryPartsTableCells = [
      { name: 'name', label: translate('common.name'), width: '20%', sortable: true },
      { name: 'currentQuantity', label: translate('containers.inventory'), width: '20%', sortable: true },
      { name: 'weeklyUsage', label: translate('containers.weeklyUsage'), width: '20%', sortable: true },
      { name: 'weeksToNoInventory', label: translate('containers.weeksToNoInventory'), width: '20%', sortable: true },
      { name: 'options', label: translate('common.options'), width: '20%', align: 'right' },
    ];

    return (
      <Modal padding="no small" isLoading={isLoading}>
        <ModalFixedHeader padding="medium no no no">
          <ModalClose onClick={closeModal}>
            <ModalCloseIcon />
          </ModalClose>
          <ModalTitle>{translate('containers.manageInventory')}</ModalTitle>
        </ModalFixedHeader>
        <Grid margin="medium no no no">
          {lowInventoryParts.length ? (
            <Table
              cells={lowInventoryPartsTableCells}
              rows={lowInventoryParts}
              rowComponent={LowInventoryPartsTableRow}
              rowProps={{ containerTypeId, isViewOnly, isSupport }}
            />
          ) : (
            <Message padding="sMedium">{translate('containers.noLowInventoryParts')}</Message>
          )}
        </Grid>

        {!isViewOnly && !isSupport && (
          <ModalFixedFooter>
            <Grid margin="small no" centered>
              <Button type="submit" color="primary" onClick={() => this.setIsAddRemoveInventoryModalVisible(true)}>
                {translate('common.addNew')}
              </Button>

              {isAddRemoveInventoryModalVisible && (
                <AddRemoveInventoryModalResolver
                  closeModal={() => this.setIsAddRemoveInventoryModalVisible(false)}
                  containerTypeId={containerTypeId}
                  isAddNewInventory={isAddRemoveInventoryModalVisible}
                />
              )}
            </Grid>
          </ModalFixedFooter>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.fleet.lowInventoryParts.isLoading,
  lowInventoryParts: state.fleet.lowInventoryParts.lowInventoryParts || [],
});

export default connect(mapStateToProps)(LowInventoryPartsModal);
