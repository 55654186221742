import { FC, useState } from 'react';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import { BulkyItemScheduler } from 'src/vendors/interfaces/BulkyItemScheduler';
import { bulkyItemSchedulerModalId } from 'src/vendors/components/modals/BulkyItemSchedulerModal';
import { Button, Grid, ModalFixedFooter, ModalSection, PanelSection } from '../../../core/components/styled';
import { MaterialPickupSettings } from 'src/vendors/interfaces/BulkyItemScheduler';
import { useSelector } from 'src/core/hooks/useSelector';
import BulkyItemPickupMaterialsAndPricingSection from './BulkyItemPickupMaterialsAndPricingSection';
import BulkyItemSchedulerDispatchAndSchedulingSection from './BulkyItemSchedulerDispatchAndSchedulingSection';
import BulkyItemSchedulerGeneralSection from './BulkyItemSchedulerGeneralSection';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

const formSelector = formValueSelector(BULKY_ITEM_SCHEDULER_FORM);

interface ComponentProps {
  closeModal: () => void;
  initialValues: BulkyItemScheduler;
}

type Props = ComponentProps & InjectedFormProps<BulkyItemScheduler, ComponentProps>;

const BulkyItemSchedulerForm: FC<Props> = ({ closeModal, handleSubmit, initialValues }) => {
  const materialPickupSettings = useSelector(state => formSelector(state, 'materialPickupSettings'));
  const syncErrors = useSelector(state => (state.form.bulkyItemSchedulerForm as any).syncErrors);

  const [categoriesOpen, setCategoriesOpen] = useState(
    materialPickupSettings?.map((_: MaterialPickupSettings) => false),
  );

  const setIsCatgoryOpen = (index: number) => {
    const newCategoriesOpen = categoriesOpen.map((categoryIsOpen: boolean, categoryIndex: number) =>
      index === categoryIndex ? !categoryIsOpen : categoryIsOpen,
    );

    setCategoriesOpen(newCategoriesOpen);
  };

  const scrollToSectionWithError = (modal: HTMLElement | null, field: HTMLElement | null, top?: number) => {
    if (modal && field)
      setTimeout(() => {
        const topPosition = field.getBoundingClientRect().top + modal.scrollTop - (top || 100);
        modal.scrollTo(0, topPosition);
      }, 200);
  };

  const onSaveClick = () => {
    const materialPickupSettingsEnabled = materialPickupSettings?.filter(
      (materialPickupSetting: MaterialPickupSettings) => materialPickupSetting?.categorySettings?.isEnabled,
    )?.length;

    // auto scroll to Pick-up Materials And Pricing section when error occurs
    if (
      materialPickupSettingsEnabled === 0 &&
      syncErrors &&
      Object.keys(syncErrors)[0] === 'atLeastOnePickupMaterialAndPricing'
    ) {
      const modal = document.getElementById(bulkyItemSchedulerModalId);
      const field = document.querySelector<HTMLInputElement>(`[id="atLeastOnePickupMaterialAndPricing"]`);

      scrollToSectionWithError(modal, field);
    }

    // auto scroll to Service Limits by day section when error occurs
    if (syncErrors && Object.keys(syncErrors)[0] === 'serviceLimitsByDayValidation') {
      const modal = document.getElementById(bulkyItemSchedulerModalId);
      const field = document.querySelector<HTMLInputElement>(`[id="serviceLimitsByDayValidation"]`);

      scrollToSectionWithError(modal, field, 200);
    }

    // auto scroll to Service Limits by time range section when error occurs
    if (syncErrors && Object.keys(syncErrors)[0] === 'serviceLimitsByTimeRangeValidation') {
      const modal = document.getElementById(bulkyItemSchedulerModalId);
      const field = document.querySelector<HTMLInputElement>(`[id="serviceLimitsByTimeRangeValidation"]`);

      scrollToSectionWithError(modal, field, 200);
    }

    // auto scroll and open category from Pick-up Materials And Pricing section when error occurs
    if (materialPickupSettingsEnabled > 0 && syncErrors && Object.keys(syncErrors)[0] === 'materialPickupSettings') {
      let errorPosition: number | undefined = undefined;

      syncErrors?.materialPickupSettings?.forEach((materialPickupSetting: MaterialPickupSettings, index: number) => {
        if (!!materialPickupSetting && errorPosition === undefined) {
          errorPosition = index;
        }
      });

      if ((errorPosition || errorPosition === 0) && !categoriesOpen[errorPosition]) {
        setIsCatgoryOpen(errorPosition);
      }

      const modal = document.getElementById(bulkyItemSchedulerModalId);
      const field = document.querySelector<HTMLInputElement>(`[id="category-${errorPosition}"]`);

      scrollToSectionWithError(modal, field);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ModalSection padding="xxSmall" align="center">
        <PanelSection padding="small" centered>
          <Grid centered multiLine>
            <BulkyItemSchedulerGeneralSection />

            <BulkyItemSchedulerDispatchAndSchedulingSection
              initialValueBulkySchedulerLimitByDaySettings={initialValues.bulkySchedulerLimitByDaySettings}
              initialValueBulkySchedulerLimitByTimeRangeSettings={initialValues.bulkySchedulerLimitByTimeRangeSettings}
            />

            <BulkyItemPickupMaterialsAndPricingSection
              categoriesOpen={categoriesOpen}
              setIsCatgoryOpen={setIsCatgoryOpen}
            />
          </Grid>
        </PanelSection>
      </ModalSection>

      <ModalFixedFooter isShadowed>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => closeModal()}>
          {translate('common.cancel')}
        </Button>
        <Button type="submit" margin="no xSmall" color="primary" onClick={() => onSaveClick()}>
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<BulkyItemScheduler, ComponentProps>({
  form: BULKY_ITEM_SCHEDULER_FORM,
  onSubmitFail: focusFirstInvalidField,
})(BulkyItemSchedulerForm);
