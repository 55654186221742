import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadLocationFlagTypes as doLoadLocationFlagTypes } from '../services/locationFlagTypes';
import { TechnicalType } from '../interfaces/TechnicalType';

const START_LOAD = 'common/locationFlagTypes/START_LOAD';
const COMPLETE_LOAD = 'common/locationFlagTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/locationFlagTypes/FAIL_LOAD';
const RESET = 'common/locationFlagTypes/RESET';

type State = {
  locationFlagTypes: TechnicalType[];
  isLoading: boolean;
};

const initialState: State = {
  locationFlagTypes: [],
  isLoading: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationFlagTypes: action.locationFlagTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationFlagTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (locationFlagTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  locationFlagTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadLocationFlagTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadLocationFlagTypesPromise = doLoadLocationFlagTypes();
  loadLocationFlagTypesPromise
    .then(locationFlagTypes => dispatch(completeLoad(locationFlagTypes)))
    .catch(() => dispatch(failLoad()));
  return loadLocationFlagTypesPromise;
};

export const resetLocationFlagTypes = () => ({
  type: RESET,
});
