import ReactDOM from 'react-dom';
import Confirmation from '../components/Confirmation';
import history from './history';

const confirm = (
  title: string,
  message?: string,
  noLabel?: string,
  yesLabel?: string,
  noLabelHidden?: boolean,
  align?: string,
) => {
  const wrapper = document.body.appendChild(document.createElement('div'));

  function dispose() {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(wrapper);
      unregisterHistoryChangeEventHandler();
      setTimeout(() => document.body.removeChild(wrapper));
    });
  }

  const unregisterHistoryChangeEventHandler = history.listen(dispose);

  const promise = new Promise((resolve, reject) => {
    try {
      ReactDOM.render(
        <Confirmation
          align={align}
          message={message}
          noLabel={noLabel}
          noLabelHidden={noLabelHidden}
          reject={reject}
          resolve={resolve}
          title={title}
          yesLabel={yesLabel}
        />,
        wrapper,
      );
    } catch (e) {
      throw e;
    }
  });

  return promise.then(
    () => {
      dispose();
      return true;
    },
    () => {
      dispose();
      return false;
    },
  );
};

export default confirm;
