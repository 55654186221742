import React, { ChangeEvent } from 'react';

import { camelCase } from 'lodash-es';
import { SortableElement } from 'react-sortable-hoc';

import { Container, TableCell, TableRow } from '../../../core/components/styled';
import { DragHandle, Dropdown, UnconnectedSwitch } from '../../../core/components';
import { PICTURE_OPTIONS } from '../../constants';
import { TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  isActive: boolean;
  isMileage: boolean;
  isRequired: boolean;
  sendEmailNotification: boolean;
  toggleTripConfigurationIsActive: (
    technicalName: string,
    value: boolean,
    isMileage: boolean,
    tripConfigurationId: number,
  ) => void;
  toggleTripConfigurationIsRequired: (technicalName: string, value: boolean, tripConfigurationId: number) => void;
  toggleTripConfigurationPicture: (technicalName: string, value: boolean, tripConfigurationId: number) => void;
  toggleTripConfigurationPictureNotFlagged: (
    technicalName: string,
    value: boolean,
    tripConfigurationId: number,
  ) => void;
  toggleTripConfigurationSendEmailNotification: (
    technicalName: string,
    value: boolean,
    tripConfigurationId: number,
  ) => void;
  tripConfigurationId: number;
  tripInspectionSubCategory: any;
}

const TripConfigurationModalTableRow: React.FC<Props> = ({
  isActive,
  isMileage,
  isRequired,
  sendEmailNotification,
  toggleTripConfigurationIsActive,
  toggleTripConfigurationIsRequired,
  toggleTripConfigurationPicture,
  toggleTripConfigurationPictureNotFlagged,
  toggleTripConfigurationSendEmailNotification,
  tripConfigurationId,
  tripInspectionSubCategory,
}) => {
  const handleOnChange =
    (technicalName: string, tripConfigurationId: number, issueNotFlagged: boolean) =>
    (_: ChangeEvent, value: boolean) => {
      issueNotFlagged
        ? toggleTripConfigurationPictureNotFlagged(technicalName, value, tripConfigurationId)
        : toggleTripConfigurationPicture(technicalName, value, tripConfigurationId);
    };

  return (
    <TableRow>
      <TableCell padding="small no small small" width="4%" align="center">
        <Container>{!isMileage && <DragHandle />}</Container>
      </TableCell>

      <TableCell width="28%">
        {translate(`vendors.tripInspection.subCategories.${camelCase(tripInspectionSubCategory.technicalName)}`)}
      </TableCell>

      <TableCell width="5%" padding="defaultCellVertical no">
        <UnconnectedSwitch
          checked={isActive}
          onChange={value =>
            toggleTripConfigurationIsActive(
              tripInspectionSubCategory.technicalName,
              value,
              isMileage,
              tripConfigurationId,
            )
          }
        />
      </TableCell>

      {!isMileage && (
        <>
          <TableCell width="15%">
            <TypedField
              name={`${tripConfigurationId}-${tripInspectionSubCategory.technicalName}`}
              component={Dropdown}
              onChange={handleOnChange(tripInspectionSubCategory.technicalName, tripConfigurationId, false)}
              props={{
                margin: 'no',
                options: PICTURE_OPTIONS,
                width: '100%',
                menuPosition: 'fixed',
              }}
            />
          </TableCell>

          <TableCell width="15%">
            <TypedField
              name={`${tripConfigurationId}-${tripInspectionSubCategory.technicalName}-notFlagged`}
              component={Dropdown}
              onChange={handleOnChange(tripInspectionSubCategory.technicalName, tripConfigurationId, true)}
              props={{
                margin: 'no',
                options: PICTURE_OPTIONS,
                width: '100%',
                menuPosition: 'fixed',
              }}
            />
          </TableCell>

          <TableCell width="14%" padding="defaultCellVertical xSmall">
            <UnconnectedSwitch
              checked={isRequired}
              onChange={value =>
                toggleTripConfigurationIsRequired(tripInspectionSubCategory.technicalName, value, tripConfigurationId)
              }
            />
          </TableCell>

          <TableCell width="12%" padding="defaultCellVertical xSmall">
            <UnconnectedSwitch
              checked={sendEmailNotification}
              onChange={value =>
                toggleTripConfigurationSendEmailNotification(
                  tripInspectionSubCategory.technicalName,
                  value,
                  tripConfigurationId,
                )
              }
            />
          </TableCell>
        </>
      )}

      <TableCell
        width={isMileage ? '60%' : '5%'}
        align="right"
        padding="defaultCellVertical defaultCellHorizontal defaultCellVertical no"
      />
    </TableRow>
  );
};

export default SortableElement(TripConfigurationModalTableRow);
