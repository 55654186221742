import React from 'react';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import ContainerInsightMarker from './ContainerInsightMarker';
import { RubiconMarkerClusterer } from './RubiconMarkerClusterer';
import { ContainerInsights as ContInsights } from '../../routes/components/mapWithTimeline/Interfaces';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';

interface Props {
  containerInsights: ContInsights[];
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string) => void;
  hideInsightsPopup?: boolean;
}

const ContainerInsights: React.SFC<Props> = ({ containerInsights, openedInfoWindows, toggleInfoWindow }) => (
  <RubiconMarkerClusterer>
    {(clusterer: Clusterer) => (
      <>
        {containerInsights.map(containerInsight => (
          <ContainerInsightMarker
            key={containerInsight.insightId}
            containerInsight={containerInsight}
            isInfoWindowOpen={openedInfoWindows.containerInsights[containerInsight.insightId]}
            toggleInfoWindow={toggleInfoWindow}
            strobeImageUrl={openedInfoWindows.imageUrl}
            clusterer={clusterer}
          />
        ))}
      </>
    )}
  </RubiconMarkerClusterer>
);

export default ContainerInsights;
