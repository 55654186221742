import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import translate from '../../../core/services/translate';
import VehicleTripInspectionDetailsPage from './VehicleTripInspectionDetailsPage';
import { loadVehicleTripInspectionDetails, loadVehicle } from '../../ducks/index';
import { loadTripConfigurationForVendor } from '../../../vendors/ducks/loadTripConfiguration';

interface Props {
  match: any;
  vendorId: number;
  loadVehicleTripInspectionDetails: (vendorId: number, vehicleId: number) => Promise<any>;
  loadVehicle: (vehicleId: number) => Promise<any>;
  loadTripConfigurationForVendor: (
    vendorId: number,
    tripInspectionTypeId: number,
    vehicleTypeId: number,
  ) => Promise<any>;
}

class VehicleTripInspectionDetailsResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      vendorId,
      loadVehicleTripInspectionDetails,
      loadTripConfigurationForVendor,
      loadVehicle,
      match: {
        params: { vehicleId, tripInspectionId },
      },
    } = this.props;

    const dependencies = [];

    let vehicle;
    let tripInspection;

    if (vehicleId) {
      vehicle = await loadVehicle(vehicleId);
    }

    if (vendorId && tripInspectionId) {
      tripInspection = await loadVehicleTripInspectionDetails(vendorId, tripInspectionId);
    }

    if (vehicle && tripInspection) {
      dependencies.push(
        loadTripConfigurationForVendor(vendorId, tripInspection.tripInspectionTypeId, vehicle.vehicleTypeId),
      );
    }

    return Promise.all(dependencies);
  };

  render() {
    const {
      match: {
        params: { vehicleId },
      },
    } = this.props;

    return (
      <Fragment>
        <DocumentTitle>{translate('vehicles.vehicleInspections.inspectionLog')}</DocumentTitle>

        <Resolver
          successComponent={VehicleTripInspectionDetailsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/fleet/vehicles"
          successProps={{
            vehicleId,
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadVehicleTripInspectionDetails,
  loadVehicle,
  loadTripConfigurationForVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTripInspectionDetailsResolver);
