import styled from 'styled-components';

export const PaymentDetailValue = styled.span`
  text-align: start;
  font-size: 14px;
  margin-top: 4px;
`;

export const PaymentDetailValueWithIcon = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 46px;
  color: ${props => props.theme.brandPrimary};
`;
