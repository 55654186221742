import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from '../interfaces/TechnicalType';
import { loadBulkyCancellationTimeFrameType as doLoadBulkyCancellationTimeFrameType } from '../services/bulkyCancellationTimeFrameTypes';

// Actions
const START_LOAD = 'common/bulkyCancellationTimeFrameTypes/START_LOAD';
const COMPLETE_LOAD = 'common/bulkyCancellationTimeFrameTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/bulkyCancellationTimeFrameTypes/FAIL_LOAD';

// Initial state
const initialState = {
  isLoading: false,
  bulkyCancellationTimeFrameTypes: [] as TechnicalType[],
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          bulkyCancellationTimeFrameTypes: action.bulkyCancellationTimeFrameTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          bulkyCancellationTimeFrameTypes: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (bulkyCancellationTimeFrameTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  bulkyCancellationTimeFrameTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

// Thunks
export const loadBulkyCancellationTimeFrameTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadBulkyCancellationTimeFrameTypesPromise = doLoadBulkyCancellationTimeFrameType();
  loadBulkyCancellationTimeFrameTypesPromise
    .then(bulkyCancellationTimeFrameTypes => dispatch(completeLoad(bulkyCancellationTimeFrameTypes)))
    .catch(() => dispatch(failLoad()));
  return loadBulkyCancellationTimeFrameTypesPromise;
};
