import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../../../store';
import { currentVendorIdSelector } from '../../../../../vendors/services/currentVendorSelector';
import { loadRouteTemplateUnassignedLocations } from '../../../../ducks';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { Resolver } from '../../../../../core/components';
import UnassignedLocationsModal from './UnassignedLocationsModal';

interface Props {
  addUnassignedLocationsToRoute: () => void;
  closeModal: () => void;
  loadRouteTemplateUnassignedLocations: (
    vendorId: number,
    vehicleTypeId: number,
    scheduledDay: Date | string,
  ) => Promise<any>;
  scheduledDay: Date | string;
  vehicleTypeId: number;
  vendorId: number;
  routeTemplateId?: string;
}

class UnassignedLocationsModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { vendorId, vehicleTypeId, scheduledDay, loadRouteTemplateUnassignedLocations } = this.props;

    return loadRouteTemplateUnassignedLocations(vendorId, vehicleTypeId, scheduledDay);
  };

  render() {
    const { addUnassignedLocationsToRoute, closeModal, vehicleTypeId, routeTemplateId } = this.props;

    return (
      <Resolver
        successComponent={UnassignedLocationsModal}
        successProps={{ addUnassignedLocationsToRoute, closeModal, vehicleTypeId, routeTemplateId }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadRouteTemplateUnassignedLocations };

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedLocationsModalResolver);
