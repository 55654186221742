import React, { Fragment, useState } from 'react';
import { get, size } from 'lodash-es';

import { ActionButtonTooltip, ThumbnailSet } from '../../../../../core/components';
import { AppState } from '../../../../../store';
import { date, time } from '../../../../../utils/services/formatter';
import { routeLocationNameByIdSelector } from '../../../../ducks/weightTickets';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../../core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { WEIGHT_MEASUREMENT_UNITS } from '../../../../../common/constants';
import { WeightTicketImagesModal } from '../../../modals';

type Props = {
  activityTimeLocal: string;
  customerName: string;
  deleteWeightTicket: (weightTicketId: number) => void;
  disposalSiteAddress: string;
  disposalSiteName: string;
  editWeightTicket: (weightTicketId: number) => void;
  id: number;
  image: any;
  isRollOff: boolean;
  routeId: number;
  routeLocationId: number;
  ticketNumber: number;
  unitOfMeasureTypeId: number;
  wasteMaterialTypeId: number;
  weight: number;
};

export default function WeightTicketsTableRow({
  activityTimeLocal,
  customerName,
  deleteWeightTicket,
  disposalSiteAddress,
  disposalSiteName,
  editWeightTicket,
  id,
  image,
  isRollOff,
  routeId,
  routeLocationId,
  ticketNumber,
  unitOfMeasureTypeId,
  wasteMaterialTypeId,
  weight,
}: Props) {
  const imageUrl = image ? [get(image, 'url')] : [];
  const [isWeightTicketImagesModalOpen, setIsWeightTicketImagesModalOpen] = useState(false);
  const [weightTicketData, setWeightTicketData] = useState({
    ticketNumber: 0,
    disposalSiteAddress: '',
    image: null,
    customerName: '',
  });

  const locationName = useSelector((state: AppState) =>
    routeLocationNameByIdSelector(state.routes.route.route as any, routeLocationId),
  );

  const closeWeightTicketImagesModal = () => {
    setIsWeightTicketImagesModalOpen(false);
  };

  const openWeightTicketImagesModal = (disposalSiteAddress: string, image: any, customerName: string) => {
    setIsWeightTicketImagesModalOpen(true);
    setWeightTicketData({ ticketNumber, disposalSiteAddress, image, customerName });
  };

  const wasteMaterialType = useSelector(
    state => state.common.wasteTypes.wasteTypes.find(wasteType => wasteType.id === wasteMaterialTypeId)?.name,
  );
  return (
    <Fragment>
      <TableRow>
        <TableCell width="9%">
          <Text>{ticketNumber}</Text>
        </TableCell>

        <TableCell width={isRollOff ? '18%' : '32%'}>
          <Text>{disposalSiteName}</Text>
        </TableCell>

        <TableCell width="12%">
          <Text>{wasteMaterialType}</Text>
        </TableCell>

        <TableCell width="8%">
          <Text>{weight}</Text>
        </TableCell>

        <TableCell width="8%">
          <Text>{WEIGHT_MEASUREMENT_UNITS[unitOfMeasureTypeId].name}</Text>
        </TableCell>

        <TableCell width="13%" vertical>
          <Fragment>
            <Text block singleLine weight="medium" margin="no no xxSmall">
              {date(activityTimeLocal)}
            </Text>
            <Text block singleLine size="small">
              {time(activityTimeLocal)}
            </Text>
          </Fragment>
        </TableCell>

        {isRollOff && (
          <TableCell width="14%" vertical>
            <Fragment>
              <Text block singleLine weight="medium" margin="no no xxSmall">
                {customerName}
              </Text>
              <Text block singleLine size="small">
                {locationName}
              </Text>
            </Fragment>
          </TableCell>
        )}

        <TableCell width="8%">
          {!!size(imageUrl) && (
            <ThumbnailSet
              images={imageUrl}
              onClick={() => openWeightTicketImagesModal(disposalSiteAddress, image, customerName)}
            />
          )}
        </TableCell>

        <TableCell width="10%" align="right">
          <Fragment>
            <TableActionButton
              id={`route-${routeId}-weight-ticket-${id}-edit-button`}
              onClick={() => editWeightTicket(id)}
            >
              <ActionButtonTooltip icon="edit" tooltip="edit" />
            </TableActionButton>

            <TableActionButton
              id={`route-${routeId}-weight-ticket-${id}-delete-button`}
              onClick={() => deleteWeightTicket(id)}
            >
              <ActionButtonTooltip icon="delete" tooltip="delete" />
            </TableActionButton>
          </Fragment>
        </TableCell>
      </TableRow>
      {!!isWeightTicketImagesModalOpen && (
        <WeightTicketImagesModal weightTicket={weightTicketData} closeModal={closeWeightTicketImagesModal} />
      )}
    </Fragment>
  );
}
