import styled, { css } from 'styled-components';
import { mapper, sizeMapper, colorMapper } from '../../../utils/styles';

interface Props {
  align?: string;
  block?: boolean;
  cursor?: string;
  disabled?: boolean;
  doubleLine?: boolean;
  flex?: boolean;
  flexAlignCenter?: boolean;
  fontStyle?: string;
  hasIcon?: boolean;
  justifyContent?: string;
  lastChildNoMargin?: boolean;
  lineHeight?: string;
  margin?: string;
  maxWidth?: string;
  minWidth?: string;
  padding?: string;
  position?: string;
  singleLine?: boolean;
  size?: string;
  underlined?: boolean;
  uppercase?: boolean;
  weight?: string;
  whiteSpace?: string;
  wordWrap?: 'normal' | 'break-word' | 'anywhere';
  zIndex?: string;
}

const Text = styled.span<Props>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  font-size: ${props =>
    mapper(props.size, {
      xxSmall: '5px',
      xSmall: '11px',
      small: '12px',
      sMedium: '13px',
      medium: '14px',
      mLarge: '15px',
      large: '16px',
      xLarge: '18px',
      xxLarge: '24px',
    })};

  b {
    font-weight: 500;
  }

  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `};

  ${props =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.flex &&
    css`
      display: flex;
      align-items: ${props.flexAlignCenter ? 'center' : 'baseline'};
    `};

  ${props =>
    props.lastChildNoMargin &&
    css`
      &:last-child {
        margin-bottom: 0;
      }
    `};

  ${props =>
    props.weight &&
    css`
      font-weight: ${() => mapper(props.weight, { light: 300, normal: 400, medium: 500 })};
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${() => mapper(props.align, { left: 'left', center: 'center', right: 'right' }, 'left')};
    `};

  ${props =>
    props.singleLine &&
    css`
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};

  ${props =>
    props.wordWrap &&
    css`
      overflow-wrap: ${props.wordWrap};
    `};

  ${props =>
    props.doubleLine &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};

  ${props =>
    props.underlined &&
    css`
      text-decoration: underline;
    `};

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${props =>
    props.fontStyle &&
    css`
      font-style: ${props.fontStyle};
    `};

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `};

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `};

  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};

  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `};

  ${props =>
    props.hasIcon &&
    css`
      position: relative;
      padding-right: 22px;
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};

  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `};

  ${props =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `};
`;

export default Text;
