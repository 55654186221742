import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { GEO_FENCE_OPTIONS_SAT } from 'src/core/constants';

import { theme } from 'src/core/styles';
import { FACILITY_GEO_FENCE_SOURCE } from 'src/fleet/constants/facilityStatus';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
  isSatellite: boolean;
};

export default memo(function FacilitiesGeoFenceGLSource({ geoJSON, isSatellite }: Props) {
  return (
    <Source type="geojson" id={FACILITY_GEO_FENCE_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id="geoFence"
        type="fill"
        source={FACILITY_GEO_FENCE_SOURCE}
        paint={{
          'fill-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'fill-opacity': 0.1,
        }}
        layout={{}}
      />
      <Layer
        id="outline"
        type="line"
        source={FACILITY_GEO_FENCE_SOURCE}
        paint={{
          'line-color': isSatellite ? GEO_FENCE_OPTIONS_SAT.fillColor : theme.brandPrimary,
          'line-width': 1,
        }}
        layout={{}}
      />
    </Source>
  );
});
