import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_LAYER,
  DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_TWO_WAY_LAYER,
  DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE,
} from 'src/dashboard/constants/dashboardMapGL';
import { AlternativeFleetStreetSegmentFeature } from 'src/dashboard/interfaces/alterativeFleetOps';

type Props = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString, AlternativeFleetStreetSegmentFeature>;
};

export default memo(function SnowSweeperStreetSegmentsGLSource({ geoJSON }: Props) {
  return (
    <Source
      type="geojson"
      id={DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE}
      data={geoJSON}
      tolerance={0.0001}
    >
      <Layer
        id={DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_LAYER}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE}
        paint={{
          'line-color': ['get', 'color'],
          'line-width': 6,
          'line-opacity': ['get', 'opacity'],
          'line-offset': ['get', 'lineOffset'],
        }}
        filter={['all', ['!=', ['get', 'hasForwardPass'], false]]}
      />
      <Layer
        id={DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_TWO_WAY_LAYER}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        source={DASHBOARD_MAP_MODAL_SNOW_SWEEPER_STREET_SEGMENTS_SOURCE}
        filter={['all', ['!=', ['get', 'hasReversePass'], false]]}
        paint={{
          'line-color': ['get', 'reverseSegmentColor'],
          'line-width': 6,
          'line-opacity': ['get', 'opacity'],
          'line-offset': ['get', 'lineOffsetNegative'],
        }}
      />
    </Source>
  );
});
