import { filter, has, size } from 'lodash-es';
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import validator from 'validator';

import { APRIL_ID, JUNE_ID, SEPTEMBER_ID, NOVEMBER_ID, FEBRUARY_ID } from 'src/common/constants/months';
import { DEFAULT_TIME } from '../../core/constants';
import checkDuplicateRouteName from '../../routes/services/checkDuplicateRouteName';
import isFalsy from './isFalsy';
import translate from '../../core/services/translate';

export const integerRegex = /^[0-9]*$/;
const phoneRegex = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
const intlPhoneRegex = /^(?:\d{2})[- ]?\d{4}[- ]?\d{3}[- ]?\d{3}$/;
const numberRegex = /^\d+$/;
const decimalRegex = /^\d+\.?\d{1,4}$/;
const decimalRegexUpTo1 = /^\d+\.?\d{1,1}$/;
const decimalRegexUpTo2 = /^\d+\.?\d{1,2}$/;
const optionalDecimalRegexUpTo1 = /^\d+(\.?\d{1,1})?$/;
const requiredUrlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
const unsafeCharactersForURLRegex = /^[a-zA-Z0-9_-]*$/;

export const dateFormat = 'MM/DD/YYYY';
export const timeFormat = 'hh:mm:ss A';
export const timeFormatWithoutSeconds = 'hh:mm A';
export const timeFormatWithoutMeridiem = 'hh:mm:ss';
export const dateTimeFormat = 'MM/DD/YYYY hh:mm A';
export const dateTimeSecondsFormat = 'MM/DD/YYYY hh:mm:ss A';
const defaultTimeFormat = '00:00';

const validateEmail = (value: string, translation: string) => (validator.isEmail(value) ? undefined : translation);

const validatePhone = (value: string, translation: string) =>
  phoneRegex.test(value) || intlPhoneRegex.test(value) ? undefined : translation;

export const isRequired = (value: any) =>
  value || value === 0 ? undefined : translate('common.validationMessages.isRequired');

export const isPriceRequired = (value: any) =>
  value ? undefined : translate('common.validationMessages.isPriceRequired');

export const isRequiredOption = (value: any) =>
  !value || !value.length ? translate('common.validationMessages.isRequiredOption') : undefined;

export const isRequiredNumber = (value?: number | null) => {
  if (value === undefined || value === null) {
    return translate('common.validationMessages.isRequired');
  }
};

export const isAtLeastOneValidator = (value?: [] | null) => {
  if (!value || value?.length === 0) {
    return translate('common.validationMessages.isRequired');
  }
};

export const isRequiredTrimmedValue = (value: any) =>
  value && value.trim() ? undefined : translate('common.validationMessages.isRequired');

export const isRequiredOrZero = (value: any) =>
  value || value === 0 ? undefined : translate('common.validationMessages.isRequired');

export const isMultiSelectRequired = (value: any) =>
  size(value) ? undefined : translate('common.validationMessages.isRequired');

export const isMultiSelectOrDropdownRequired = (value: any) =>
  size(value) || !!value ? undefined : translate('common.validationMessages.isRequired');

export const isTimeValid = (value: string) =>
  value !== DEFAULT_TIME && value !== defaultTimeFormat ? undefined : translate('common.validationMessages.isRequired');

export const isIntlPhone = (value: string) =>
  isValidPhoneNumber(value) ? undefined : translate('common.validationMessages.isPhone');

export const isEmail = (value: string) =>
  value && validator.isEmail(value) ? undefined : translate('common.validationMessages.isEmail');

export const isPhone = (value: string) => validatePhone(value, translate('common.validationMessages.isPhone'));

export const isFax = (value: string) => validatePhone(value, translate('common.validationMessages.isFax'));

export const isOPtionalEmail = (value?: string) => {
  if (value) return validateEmail(value, translate('common.validationMessages.isEmail'));
};

export const isOptionalPhone = (value?: string) => {
  if (value) return validatePhone(value, translate('common.validationMessages.isPhone'));
};

export const isOptionalFax = (value?: string) => {
  if (value) return validatePhone(value, translate('common.validationMessages.isFax'));
};

export const isFile = (value: FileList) =>
  value && value instanceof FileList && value.length ? undefined : translate('common.validationMessages.isFile');

export const isGuid = (value: string) => validator.isUUID(value);

export const isEmails = (value: string) => {
  if (value) {
    const emailIdsArr = value.trim().split(/,|;/g);
    const invalidEmails = filter(emailIdsArr, email => !validator.isEmail(email.trim())).join(',');
    return invalidEmails ? translate('common.validationMessages.invalidEmails', { invalidEmails }) : undefined;
  }

  return undefined;
};

export const isInvalidEmail = (value: string) => {
  if (value) {
    const emailIdsArr = value.trim().split(/,|;/g);
    const invalidEmail = filter(emailIdsArr, email => !validator.isEmail(email.trim())).join(',');
    return invalidEmail && translate('common.validationMessages.invalidEmail');
  }

  return undefined;
};

export const hasCoordinates = (value: any) => {
  if (value && value.text) {
    return has(value, 'latitude') && has(value, 'longitude')
      ? undefined
      : translate('common.validationMessages.hasCoordinates');
  }

  return undefined;
};

export const hasCountry = (value: any) => {
  if (value && value.text) {
    return has(value, 'country') ? undefined : translate('common.validationMessages.hasCountry');
  }

  return undefined;
};

export const hasStreetAndNumber = (value: any) =>
  has(value, 'streetAndNumber') ? undefined : translate('common.validationMessages.hasStreetAndNumber');

export const hasCity = (value: any) => {
  if (value && value.text) {
    return has(value, 'city') ? undefined : translate('common.validationMessages.hasCity');
  }

  return undefined;
};

export const hasStreet = (value: any) => {
  if (value && value.text) {
    return has(value, 'street') ? undefined : translate('common.validationMessages.hasStreet');
  }

  return undefined;
};

export const hasStreetNumber = (value: any) => {
  if (value) {
    return has(value, 'streetNumber') ? undefined : translate('common.validationMessages.hasStreetNumber');
  }

  return undefined;
};

export const hasZip = (value: any) => {
  if (value && value.text) {
    return has(value, 'zip') ? undefined : translate('common.validationMessages.hasZip');
  }

  return undefined;
};

export const isUnchangedAddress = (value: any) => {
  if (value && value.text) {
    return value.formattedAddress === value.text
      ? undefined
      : translate('common.validationMessages.isUnchangedAddress');
  }

  return undefined;
};

export const maxValueNumeric = (max: number) => (value: number) =>
  Number(value) > max ? translate('common.validationMessages.xMaxValue', { max }) : undefined;

const minValueNumeric = (min: number) => (value: number) =>
  Number(value) < min ? translate('common.validationMessages.xMinValue', { min }) : undefined;

export const minValueNumeric0 = minValueNumeric(0);
export const minValueNumeric1 = minValueNumeric(1);

const optionalMinValueNumeric = (min: number) => (value?: number | null) => {
  if (value === undefined || value === null || value.toString().trim() === '') {
    return;
  }

  return Number(value) < min ? translate('common.validationMessages.xMinValue', { min }) : undefined;
};

export const optionalMinValueNumeric0 = optionalMinValueNumeric(0);
export const optionalMinValueNumeric1 = optionalMinValueNumeric(1);
export const optionalMinValueNumeric0_1 = optionalMinValueNumeric(0.1);

const optionalMaxValueNumeric = (max: number) => (value?: number | null) => {
  if (value === undefined || value === null || value.toString().trim() === '') {
    return;
  }

  return Number(value) > max ? translate('common.validationMessages.xMaxValue', { max }) : undefined;
};

export const optionalMaxValueNumeric99M = optionalMaxValueNumeric(99000000);
export const optionalMaxValueNumeric9999_99 = optionalMaxValueNumeric(9999.99);

export const isNumber = (value: string) =>
  value && numberRegex.test(value) ? undefined : translate('common.validationMessages.isNumber');

export const isNumberWithNoDecimals = (value: string) =>
  value && numberRegex.test(value) ? undefined : translate('common.validationMessages.isNumberNoDecimals');

export const isNumberOrZero = (value: string) =>
  !isFalsy(value, { allowZero: true }) && numberRegex.test(value)
    ? undefined
    : translate('common.validationMessages.isNumber');

export const isOptionalNumberOrZero = (value: string) => {
  if (!value) {
    return;
  }

  return isNumberOrZero(value);
};

const isGreaterThan = (min: number) => (value: number) =>
  Number(value) <= min ? translate('common.validationMessages.xGreaterThan', { min }) : undefined;

export const isGreaterThan0 = isGreaterThan(0);

export const isInteger = (value: string) =>
  value && integerRegex.test(value) ? undefined : translate('common.validationMessages.isInteger');

export const isNaturalNumber = (value: string) =>
  value && Number(value) > 0 ? undefined : translate('common.validationMessages.isNaturalNumber');

export const isNaturalNumberWithZero = (value: string) =>
  value && Number(value) >= 0 ? undefined : translate('common.validationMessages.isNaturalNumber');

export const isDecimal = (value: any) =>
  !isFalsy(value, { allowZero: true }) && (decimalRegex.test(value) || numberRegex.test(value))
    ? undefined
    : translate('common.validationMessages.isDecimal');

export const isDecimalUpTo1OrNull = (value: any) =>
  (!isFalsy(value, { allowZero: true }) && (decimalRegexUpTo1.test(value) || numberRegex.test(value))) ||
  value === null ||
  value === ''
    ? undefined
    : translate('common.validationMessages.isDecimalUpTo1');

export const isOptionalDecimalUpTo1OrNull = (value: any) => {
  if (!value || optionalDecimalRegexUpTo1.test(value) || numberRegex.test(value)) {
    return;
  }

  return translate('common.validationMessages.isDecimalUpTo1');
};

export const isDecimalUpTo2 = (value: any) =>
  !isFalsy(value, { allowZero: true }) && (decimalRegexUpTo2.test(value) || numberRegex.test(value))
    ? undefined
    : translate('common.validationMessages.isDecimalUpTo2');

export const isDecimalWithZero = (value: any) => {
  if (value) {
    return !isFalsy(value, { allowZero: true }) && (decimalRegex.test(value) || numberRegex.test(value))
      ? undefined
      : translate('common.validationMessages.isDecimal');
  }

  return undefined;
};

export const isDecimalWithoutZero = (value: any) => {
  if (value) {
    return decimalRegex.test(value) || numberRegex.test(value)
      ? undefined
      : translate('common.validationMessages.isDecimalWithoutZero');
  }

  return undefined;
};

export const maxValueNumeric24 = maxValueNumeric(24);
export const maxValueNumeric31 = maxValueNumeric(31);
export const maxValueNumeric59 = maxValueNumeric(59);
export const maxValueNumeric72 = maxValueNumeric(72);
export const maxValueNumeric90 = maxValueNumeric(90);
export const maxValueNumeric99 = maxValueNumeric(99);
export const maxValueNumeric100 = maxValueNumeric(100);
export const maxValueNumeric120 = maxValueNumeric(120);
export const maxValueNumeric999 = maxValueNumeric(999);
export const maxValueNumeric1000 = maxValueNumeric(1000);
export const maxValueNumeric9999 = maxValueNumeric(9999);
export const maxValueNumeric9999_99 = maxValueNumeric(9999.99);
export const maxValueNumeric99M = maxValueNumeric(99000000);
export const maxValueNumeric86399 = maxValueNumeric(86399);
export const maxValueNumeric365 = maxValueNumeric(365);

const maxLength = (max: number) => (value: string | number) => {
  if (typeof value === 'number') {
    value = String(value);
  }

  return value && value.length > max ? translate('common.validationMessages.xMaxLength', { max }) : undefined;
};

const minLength = (min: number) => (value: string | number) => {
  if (typeof value === 'number') {
    value = String(value);
  }

  return value && value.length < min ? translate('common.validationMessages.xMinLength', { min }) : undefined;
};

export const maxLength512 = maxLength(512);
export const maxLength500 = maxLength(500);
export const maxLength160 = maxLength(160);
export const maxLength250 = maxLength(250);
export const maxLength200 = maxLength(200);
export const maxLength120 = maxLength(120);
export const maxLength100 = maxLength(100);
export const maxLength50 = maxLength(50);
export const maxLength20 = maxLength(20);
export const maxLength10 = maxLength(10);
export const maxLength15 = maxLength(15);
export const maxLength30 = maxLength(30);
export const maxLength6 = maxLength(6);
export const maxLength5 = maxLength(5);
export const maxLength4 = maxLength(4);
export const maxLength3 = maxLength(3);
export const minLength8 = minLength(8);
export const minLength6 = minLength(6);
export const minLength3 = minLength(3);

export const mustLength = (must: number) => (value: any[]) =>
  value && value.length !== must ? translate('common.validationMessages.xMustLength', { must }) : undefined;

export const isPasswordMatch = (_: any, otherValues: any) =>
  otherValues.password !== otherValues.confirmPassword ? 'Passwords do not match' : undefined;

export const createIsUrlValidator = (message: string) => (value: string) =>
  requiredUrlRegex.test(value) ? undefined : message;

export const createUnsafeCharactersUrlValidator = (message: string) => (value: string) =>
  unsafeCharactersForURLRegex.test(value) ? undefined : message;

export const isValidUrl = createIsUrlValidator('Please provide a valid URL.');

export const hasSafeCharactersUrl = createUnsafeCharactersUrlValidator(
  translate('common.validationMessages.invalidSafeCharacters'),
);

export const createIsDateValidValidator = (message: string) => (value: any) => {
  if (value) {
    const isDateValid = moment(value, dateFormat, true).isValid() || moment(value, dateTimeFormat, true).isValid();

    return isDateValid ? undefined : message;
  }
  return undefined;
};

export const isDateValidValidator = (value: string) => {
  if (value) {
    const isDateValid = moment(value, dateFormat, true).isValid() || moment(value, dateTimeFormat, true).isValid();

    return isDateValid ? undefined : translate('common.validationMessages.invalidDate');
  }
  return undefined;
};

export const isDateValid = createIsDateValidValidator('Please provide a valid date.');

const countInArray = (array: any[], what: any) => array.filter(item => item === what).length;

export const isUniqueRouteName = async (name: string, nameArray: any[], date?: Date | string, vendorId?: number) => {
  const errorMessage = translate('common.validationMessages.routeAlreadyExists', { date });
  try {
    const beValidation = await checkDuplicateRouteName(name, date, vendorId);
    if (countInArray(nameArray, name) > 1 || beValidation.routeExists) {
      return errorMessage;
    }
  } catch (e) {
    // swallow error
    console.error(e);
  }
};

const passwordThatContainsLettersAndNumbers = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,})/;

export const passwordComplexity = (value: any) =>
  passwordThatContainsLettersAndNumbers.test(value) ? undefined : translate('account.passwordMustBeComplex');

const latRegex = /^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/;
export const isLatitude = (value: string) => {
  return latRegex.test(value) ? undefined : translate('common.validationMessages.invalidLatitude');
};

const longRegex = /^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/;
export const isLongitude = (value: string) => {
  return longRegex.test(value) ? undefined : translate('common.validationMessages.invalidLongitude');
};

export const isAfterCurrentTime = (value: any, formData: any) => {
  if (value !== defaultTimeFormat) {
    const currentDateTime = moment().format();
    const disposalDateTime = moment(formData.disposalDate + ' ' + formData.disposalTime).format();
    return moment(disposalDateTime).isBefore(currentDateTime)
      ? undefined
      : translate('common.validationMessages.isAfterCurrentTime');
  }
};

export const isAfterCurrentTimeMaterialTicket = (value: any, formData: any) => {
  if (value !== defaultTimeFormat) {
    const currentDateTime = moment().format();
    const materialTicketDateTime = moment(formData.materialTicketDate + ' ' + formData.materialTicketTime).format();
    return moment(materialTicketDateTime).isBefore(currentDateTime)
      ? undefined
      : translate('common.validationMessages.isAfterCurrentTime');
  }
};

export const isAfterCurrentTimeLoadsDumped = (value: any, formData: any) => {
  if (value !== defaultTimeFormat) {
    const currentDateTime = moment().format();
    const loadsDumpedDateTime = moment(formData.loadDumpedDate + ' ' + formData.loadDumpedTime).format();
    return moment(loadsDumpedDateTime).isBefore(currentDateTime)
      ? undefined
      : translate('common.validationMessages.isAfterCurrentTime');
  }
};

export const isAfterCurrentTimeWaterFills = (value: any, formData: any) => {
  if (value !== defaultTimeFormat) {
    const currentDateTime = moment().format();
    const waterFillsDateTime = moment(formData.waterFillDate + ' ' + formData.waterFillTime).format();
    return moment(waterFillsDateTime).isBefore(currentDateTime)
      ? undefined
      : translate('common.validationMessages.isAfterCurrentTime');
  }
};

export const isValidEndTime = (value: string, formData: any) => {
  if (value !== defaultTimeFormat && !!formData.startTime) {
    const currentDateTime = moment().format(dateFormat);
    const currentStartTime = moment(currentDateTime + ' ' + formData.startTime).format();
    const currentEndTime = moment(currentDateTime + ' ' + formData.endTime).format();

    return moment(currentStartTime).isSameOrBefore(currentEndTime)
      ? undefined
      : translate('common.validationMessages.isBeforeStartTime');
  }
};

export const isValidPickupEndTime = (value: string, formData: any) => {
  if (value !== defaultTimeFormat && !!formData.pickupWindowBegin) {
    const currentDateTime = moment().format(dateFormat);
    const currentStartTime = moment(currentDateTime + ' ' + formData.pickupWindowBegin).format();
    const currentEndTime = moment(currentDateTime + ' ' + formData.pickupWindowEnd).format();

    return moment(currentStartTime).isSameOrBefore(currentEndTime)
      ? undefined
      : translate('common.validationMessages.isBeforeStartTime');
  }
};

export const isValidEndMonth = (value: string, formData: any) =>
  value >= formData.startMonth ? undefined : translate('common.validationMessages.isBeforeStartMonth');

export const isValidEndDay = (value: string, formData: any) =>
  formData.startMonth >= formData.endMonth
    ? value >= formData.startDayOfWeek
      ? undefined
      : translate('common.validationMessages.isBeforeStartDay')
    : undefined;

export const isValidDayOfMonth = (value: number, month: any) => {
  const monthsWith30Days = [APRIL_ID, JUNE_ID, SEPTEMBER_ID, NOVEMBER_ID];
  const monthsWith28Days = [FEBRUARY_ID];
  const lastDayOfMonth = 31;
  const lastDayOfMonthFebruary = 28;

  if (
    value &&
    ((value === lastDayOfMonth && monthsWith30Days.indexOf(month) !== -1) ||
      (value > lastDayOfMonthFebruary && monthsWith28Days.indexOf(month) !== -1))
  ) {
    return translate('common.validationMessages.dayDoesntExistInMonth');
  }
};

export const isValidStartDayOfMonth = (value: number, formData: any) => {
  return isValidDayOfMonth(value, formData.startMonth);
};

export const isValidEndDayOfMonth = (value: number, formData: any) => {
  if (formData.endMonth === formData.startMonth && value < formData.startDay) {
    return translate('common.validationMessages.isBeforeStartDay');
  }

  return isValidDayOfMonth(value, formData.endMonth);
};

export const isBeforeStartDate = (value: Date | string, formData: any) => {
  return moment(value).isBefore(formData.startDate)
    ? translate('common.validationMessages.isBeforeStartDate')
    : undefined;
};
