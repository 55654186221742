import { PureComponent, Fragment } from 'react';

import { camelCase } from 'lodash-es';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import humps from 'humps';
import moment from 'moment';

import { AppState } from '../../../store';
import { ActionButtonTooltip, PopoverWrapper } from '../../../core/components';
import {
  AcceptOpenDispatchesModal,
  AcceptServiceModal,
  CompletedServiceConfirmationModal,
  DeclineOpenDispatchesModal,
  DocumentListModalResolver,
  DocumentUploadModal,
  RequestDateChangeForOpenDispatchesModal,
  UnableToCompleteServiceConfirmationModal,
} from '../modal';
import confirm from '../../../core/services/confirm';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import {
  createUnassignedJob,
  getServiceContractByCode,
  loadRubiconDispatches,
  saveServiceContract,
  setNewServiceContractId,
  showNotesModal,
  updateOpenDispatches,
  updateServiceConfirmation,
} from '../../ducks';
import { responseMissedPickup } from 'src/fleet/ducks/openDispatches';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  DetailsListBodySection,
  IconButton,
  IconButtonIcon,
  Popover,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
} from '../../../core/components/styled';
import { DetailsContainer, ServiceIconImage, WorkOrderLabel } from '../styled/RubiconDispatches';
import { DELIVERY, REMOVAL } from '../../constants';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { getDateByStatus } from '../utils/rubiconDispatchesPageHooks';
import { isRubiconNonTechHauler } from '../../../account/ducks';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { Equipment, ReasonCodeGroups, RubiconDispatchDates } from 'src/fleet/interfaces/RubiconDispatches';
import { RubiconEquipmentPopover } from './RubiconEquipmentPopover';
import { RubiconDispatchesServiceDatesPopover } from './RubiconDispatchesServiceDatesPopover';
import { ServiceDeliveredModal, ServiceUnableToDeliverModal } from '../../../opportunity/components/modal';
import { SERVICE_TYPE_ICONS } from '../../../customers/constants';
import translate from '../../../core/services/translate';
import { updateHaulerOpportunity } from '../../../opportunity/ducks';
import { YES } from '../../constants/status';
import { RubiconDispatchesMobileListRowDetailsView } from './RubiconDispatchesMobileListRowDetailsView';
import { REQUEST_CHANGE } from '../../constants/rubiconDisptachesStatus';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';
import { TEMP, PERM } from '../../../opportunity/constants/serviceFrequencies';
import { UNITS } from '../../../common/constants/unitOfMeasure';

export enum DispatchStatus {
  CLOSED = 'Closed',
  COMPLETE = 'Work Order Complete',
  CONFIRMED = 'Service Confirmed',
  DELIVERY_SUSPENDED = 'Delivery Suspended',
  DECLINE = 'Decline',
  PENDING = 'Pending',
  REQUESTED = 'Requested',
  SCHEDULED = 'Scheduled',
  UNABLE_TO_COMPLETE = 'Unable To Complete',
}
interface RouteParams {
  token: string;
}

export interface WorkOrderRow {
  actionRequired: boolean;
  afterDeliveryDate: string;
  agreement: any;
  beforeDeliveryDate: string;
  caseId: number;
  customerAddress: string;
  customerName: string;
  dates: RubiconDispatchDates;
  daysInWeekSchedule: number;
  daysOverDue: string;
  deliveryDaysThreshold: number;
  equipment: Equipment;
  equipmentType: string;
  fileDetails: any[];
  hasVendorNotes: boolean;
  hasWorkOrdersNotes: boolean;
  isActionRequired: boolean;
  isRollOff: boolean;
  isTemp: boolean;
  itemId: number;
  match: any;
  numberOfHoursLeft: string;
  options: any;
  pickupType: string;
  pickupTypeId: number;
  pickupTypeTechnicalName: string;
  requestedServiceDate: string;
  rubiconPONbr: string;
  serviceDate: string;
  serviceNotes: any[];
  siteCode: string;
  siteName: string;
  status: string;
  statusId: number;
  statusName: string;
  svcId: string;
  vendID: string;
  weightTicket: any;
  workorderNumber: string;
  workOrderType: string;
}

type ComponentProps = WorkOrderRow & RouteComponentProps<RouteParams>;

interface ReduxProps {
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
  createUnassignedJob(...args: any[]): any;
  getServiceContractByCode(serviceContractCode: string): any;
  isNonTechHauler: boolean;
  loadDispatchOpportunitiesCount(...args: any[]): any;
  loadRubiconDispatches(...args: any[]): any;

  newServiceContractId?: number;
  reasonCodes: ReasonCodeGroups;
  reset(...args: any[]): any;
  saveServiceContract(...args: any[]): any;
  serviceContract: any;
  setNewServiceContractId: DuckFunction<typeof setNewServiceContractId>;
  showNotesModal: DuckAction<typeof showNotesModal>;
  updateHaulerOpportunity(...args: any[]): any;
  updateOpenDispatches(...args: any[]): any;
  responseMissedPickup(...args: any[]): any;
  updateServiceConfirmation(...args: any[]): any;
  userId: string;
  vendorId: number;
}
interface State {
  action: string;
  hasGusServiceContract: boolean;
  isAcceptServiceForNonTechHaulerModalOpen: boolean;
  isAcceptServiceModalOpen: boolean;
  isCompletedExtraPickupNonROModalOpen: boolean;
  isCopied: boolean;
  isDeclineOpenDispatchesModalOpen: boolean;
  isDeliveryNoteModalOpen: boolean;
  isDocumentListModalOpen: boolean;
  isDocumentUploadModalOpen: boolean;
  isRequestDateChangeModalOpen: boolean;
  isServiceDeliveredModalOpen: boolean;
  isServiceUnableToDeliverModalOpen: boolean;
  isUnableToCompleteModalOpen: boolean;
  response: any;
}

type Props = ReduxProps & ComponentProps & { showMobileDetails?: boolean };

class RubiconDispatchesTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      action: '',
      hasGusServiceContract: false,
      isAcceptServiceForNonTechHaulerModalOpen: false,
      isAcceptServiceModalOpen: false,
      isCompletedExtraPickupNonROModalOpen: false,
      isCopied: false,
      isDeclineOpenDispatchesModalOpen: false,
      isDeliveryNoteModalOpen: false,
      isDocumentListModalOpen: false,
      isDocumentUploadModalOpen: false,
      isRequestDateChangeModalOpen: false,
      isServiceDeliveredModalOpen: false,
      isServiceUnableToDeliverModalOpen: false,
      isUnableToCompleteModalOpen: false,
      response: undefined,
    };
  }

  componentDidUpdate() {
    setTimeout(() => this.setState({ isCopied: false }), 2000);
  }

  closeMobileOverlay = () => {
    if (this.props.showMobileDetails) {
      this.props.closeMobilePageDetailOverlay();
    }
  };

  openAcceptServiceModal = async (pickupTypeId: number) => {
    const { getServiceContractByCode, setNewServiceContractId, isNonTechHauler, svcId } = this.props;
    const serviceContractCode = svcId;
    if (pickupTypeId === DELIVERY) this.setState({ isServiceDeliveredModalOpen: true });
    else if (isNonTechHauler) {
      this.setState({ isAcceptServiceForNonTechHaulerModalOpen: true });
    } else {
      this.setState({ isAcceptServiceModalOpen: true });

      await getServiceContractByCode(serviceContractCode)
        .then(() => {
          this.setState({ hasGusServiceContract: true });
        })
        .catch(() => {
          this.setState({ hasGusServiceContract: false });
        });

      setNewServiceContractId(undefined);
    }
  };

  onCancelAcceptService = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({ isAcceptServiceModalOpen: false });
    this.props.reset('acceptServiceForm');
    this.closeMobileOverlay();
  };

  onAcceptService = async (formData: any) => {
    const {
      createUnassignedJob,
      dates,
      itemId,
      loadDispatchOpportunitiesCount,
      match: {
        params: { token },
      },
      newServiceContractId,
      pickupTypeId,
      reset,
      rubiconPONbr,
      saveServiceContract,
      serviceContract,
      status,
      svcId,
      updateOpenDispatches,
      responseMissedPickup,
      userId,
      vendID,
      vendorId,
    } = this.props;
    const serviceContractId = serviceContract ? serviceContract.serviceContractId : null;

    const searchFilter = {};
    const serviceContractCode = svcId;
    const workOrderNumber = rubiconPONbr;
    const serviceScheduledDate = moment(dates.scheduledDate).format('YYYY-MM-DD HH:mm:ss');

    if (newServiceContractId) {
      await saveServiceContract(newServiceContractId, serviceContractCode);
    }

    await createUnassignedJob(
      vendorId,
      newServiceContractId || serviceContractId,
      formData.notes,
      serviceScheduledDate,
      workOrderNumber,
    );

    const action = 'Accept';
    const weightTicketUom = undefined;
    let reasonType;
    let responseType;
    let completedDate;
    let uploadedFile;
    if (pickupTypeId === MISSED_PICKUP) {
      reasonType = formData.reasonCode;
      responseType = 'REQUESTED';
      completedDate =
        !!itemId && formData.newServiceDate
          ? moment(formData.newServiceDate).format('YYYY-MM-DD')
          : formData.newServiceDate
          ? moment(formData.newServiceDate).format('YYYY-MM-DD HH:mm:ss')
          : null;
      uploadedFile = formData.file ? formData.file[0] : null;
    } else {
      reasonType = formData.notes ? 'OTH' : null;
      completedDate = undefined;
    }

    const promise =
      pickupTypeId === MISSED_PICKUP && !!itemId
        ? responseMissedPickup(
            itemId,
            responseType,
            completedDate,
            reasonType,
            userId,
            token,
            vendorId,
            formData.dayOfWeek,
            undefined,
            undefined,
            undefined,
            formData.notes,
            uploadedFile,
          )
        : updateOpenDispatches(
            workOrderNumber,
            status,
            formData.notes,
            reasonType,
            serviceScheduledDate,
            action,
            null,
            null,
            userId,
            token,
            vendorId,
            vendID,
            weightTicketUom,
            searchFilter,
            completedDate,
          );

    if (!promise) {
      return;
    }

    promise
      .then((response: any) => {
        if (response?.errorMessage) {
          createErrorNotification(response.errorMessage, 15000);
        } else {
          createSuccessNotification(translate('opportunity.opportunitySave'));
          loadDispatchOpportunitiesCount(userId, vendorId, token);
          this.setState({ isAcceptServiceModalOpen: false });
          this.reloadDispatchesList();
          reset('acceptServiceForm');
        }
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  };

  closeAcceptOpenDispatchesModal = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset('acceptOpenDispatches');
    this.setState({ isAcceptServiceForNonTechHaulerModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  openDeclineOpenDispatchesModal = async (pickupTypeId: number) => {
    if (pickupTypeId === DELIVERY)
      this.setState({ isServiceUnableToDeliverModalOpen: true, action: DispatchStatus.DECLINE });
    else if (pickupTypeId === MISSED_PICKUP) {
      this.setState({ isDeclineOpenDispatchesModalOpen: true });
    } else if (!(await confirm(translate('autoDispatch.alertMessages.confirmDecline')))) {
      this.setState({ isDeclineOpenDispatchesModalOpen: true });
    } else {
      this.setState({ isRequestDateChangeModalOpen: true });
    }
  };

  closeDeclineOpenDispatchesModal = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset('declineOpenDispatches');
    this.setState({ isDeclineOpenDispatchesModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  openRequestDateChangeModal = (pickupTypeId: number) => {
    if (pickupTypeId === DELIVERY) this.setState({ isServiceUnableToDeliverModalOpen: true, action: REQUEST_CHANGE });
    else this.setState({ isRequestDateChangeModalOpen: true });
  };

  closeRequestDateChangeModal = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset('requestDateChangeForOpenDispatches');
    this.setState({ isRequestDateChangeModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  openNotesModal = (pickupTypeId: number, isWorkOrderNote: boolean) => {
    const { customerName, customerAddress, rubiconPONbr: workOrder, serviceDate, showNotesModal } = this.props;
    showNotesModal({
      workOrder,
      serviceDate,
      title: customerName,
      subtitle: customerAddress,
      isWorkOrderNote,
    });
  };

  openDocumentListModal = () => {
    this.setState({ isDocumentListModalOpen: true });
  };

  closeDocumentListModal = () => {
    this.setState({ isDocumentListModalOpen: false });
  };

  openDocumentUploadModal = () => {
    this.setState({ isDocumentUploadModalOpen: true });
  };

  closeDocumentUploadModal = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset('completedServiceConfirmation');
    this.setState({ isDocumentUploadModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  openCompleteModal = (pickupTypeId: number, isRollOff: boolean) => {
    // delivery
    if (pickupTypeId === DELIVERY || pickupTypeId === REMOVAL) {
      this.setState({ isServiceDeliveredModalOpen: true });
    } else if (isRollOff) {
      this.setState({ isDocumentUploadModalOpen: true });
    } else {
      this.setState({ isCompletedExtraPickupNonROModalOpen: true });
    }
  };

  closeServiceDeliveredModal = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('serviceDelivered');
    this.setState({ isServiceDeliveredModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  openServiceUnableToDeliverModal = (value: any, pickupTypeId: number) => {
    if (pickupTypeId === DELIVERY || pickupTypeId === REMOVAL) {
      this.setState({ isServiceUnableToDeliverModalOpen: true });
      this.setState({ response: value });
    } else {
      this.setState({ isUnableToCompleteModalOpen: true });
    }
  };

  closeServiceUnableToDeliverModal = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset('serviceDelivered');
    this.setState({ isServiceUnableToDeliverModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  closeUnableToCompleteModalOpen = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset('unableToCompleteServiceConfirmation');
    this.setState({ isUnableToCompleteModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  closeExtraPickupNonROModal = async (formPristine?: boolean, isReload?: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.props.reset('completedServiceConfirmation');
    this.setState({ isCompletedExtraPickupNonROModalOpen: false });
    if (isReload) {
      this.reloadDispatchesList();
    }
  };

  closeDeliveryNotesModal = () => {
    this.setState({ isDeliveryNoteModalOpen: false });
    this.closeMobileOverlay();
  };

  reloadDispatchesList = () => {
    const {
      location: { search },
      loadRubiconDispatches,
      match: {
        params: { token },
      },
      userId,
      vendorId,
    } = this.props;
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    loadRubiconDispatches(userId, token, vendorId, startDate, endDate, isActionRequired);
    this.closeMobileOverlay();
  };

  copyToClipboard = () => {
    const { workorderNumber } = this.props;
    navigator.clipboard.writeText(workorderNumber);
    this.setState({ isCopied: true });
  };

  render() {
    const {
      actionRequired,
      afterDeliveryDate,
      agreement,
      beforeDeliveryDate,
      caseId,
      customerAddress,
      customerName,
      dates,
      daysInWeekSchedule,
      daysOverDue,
      deliveryDaysThreshold,
      equipment,
      equipmentType,
      hasVendorNotes,
      hasWorkOrdersNotes,
      isActionRequired,
      isNonTechHauler,
      isRollOff,
      isTemp,
      itemId,
      loadDispatchOpportunitiesCount,
      match,
      numberOfHoursLeft,
      options,
      pickupTypeId,
      pickupTypeTechnicalName,
      reasonCodes,
      requestedServiceDate,
      rubiconPONbr,
      serviceDate,
      showMobileDetails,
      siteCode,
      siteName,
      status,
      statusId,
      statusName,
      updateHaulerOpportunity,
      userId,
      vendID,
      vendorId,
      weightTicket,
      workorderNumber,
      workOrderType,
    } = this.props;
    const {
      action,
      hasGusServiceContract,
      isAcceptServiceModalOpen,
      isAcceptServiceForNonTechHaulerModalOpen,
      isCompletedExtraPickupNonROModalOpen,
      isCopied,
      isDocumentUploadModalOpen,
      isDocumentListModalOpen,
      isDeclineOpenDispatchesModalOpen,
      isRequestDateChangeModalOpen,
      isServiceDeliveredModalOpen,
      isServiceUnableToDeliverModalOpen,
      isUnableToCompleteModalOpen,
    } = this.state;

    const { type, subType, frequency, size, units, quantity, wasteMaterial, iconType } = equipment;
    const { startDate, endDate } = dates;
    const equipmentUnit = UNITS[units]?.name || units;
    const frequencyType = isTemp ? TEMP : PERM;
    const serviceDescription = `${equipmentType} ${equipment.wasteMaterial} ${equipment.frequency}`;
    const {
      canAccept,
      canDecline,
      canRequestDateChange,
      canComplete,
      canChooseUnable,
      canViewUploadedDocuments,
      canUploadDocuments,
    } = options;
    const deliveriesModalProps = {
      action,
      agreement,
      afterDeliveryDate,
      beforeDeliveryDate,
      caseId,
      daysInWeekSchedule,
      deliveryDaysThreshold,
      frequency: equipment.frequency,
      itemId,
      liftAndShiftVendor: true,
      pickupTypeId,
      statusId,
      userId,
      vendorId,
      match,
      updateHaulerOpportunity,
      loadDispatchOpportunitiesCount,
    };
    return (
      <Fragment>
        {showMobileDetails ? (
          <RubiconDispatchesMobileListRowDetailsView
            {...this.props}
            onOpenAcceptServiceModal={() => this.openAcceptServiceModal(pickupTypeId)}
            onOpenCompleteModal={() => this.openCompleteModal(pickupTypeId, isRollOff)}
            onOpenDeclineOpenDispatchesModal={() => this.openDeclineOpenDispatchesModal(pickupTypeId)}
            onOpenRequestDateChangeModal={() => this.openRequestDateChangeModal(pickupTypeId)}
            onOpenServiceUnableToDeliverModal={() =>
              this.openServiceUnableToDeliverModal('CouldNotDelivered', pickupTypeId)
            }
            onOpenNotesModal={() => this.openNotesModal(pickupTypeId, false)}
            onViewUploadedDocuments={this.openDocumentListModal}
          />
        ) : (
          <TableRow>
            <TableCell vertical width={'15%'}>
              <DetailsContainer noShadow padding="no">
                <DetailsListBodySection>
                  <div>
                    {pickupTypeId !== MISSED_PICKUP && (
                      <PopoverWrapper
                        autoHide
                        triggerButton={
                          <WorkOrderLabel onClick={() => this.copyToClipboard()}>{workorderNumber}</WorkOrderLabel>
                        }
                        popoverContent={
                          <Popover>
                            {isCopied ? (
                              <Text weight="normal" margin="no no xxSmall" singleLine size="small" color="alert">
                                {translate('autoDispatch.copySuccess')}
                              </Text>
                            ) : (
                              <Text weight="medium" margin="no no xxSmall" singleLine size="small">
                                {translate('autoDispatch.copyWorkOrder')}
                              </Text>
                            )}
                          </Popover>
                        }
                        size="xSmall"
                        margin="no"
                      />
                    )}
                    {hasWorkOrdersNotes && (
                      <IconButton
                        type="submit"
                        size="xSmall"
                        color="secondary"
                        margin="no"
                        onClick={() => this.openNotesModal(pickupTypeId, true)}
                      >
                        <IconButtonIcon
                          icon="page-content"
                          color="secondary"
                          size="xLarge"
                          margin="xxSmall no no xxSmall"
                        />
                      </IconButton>
                    )}
                    {workOrderType === DispatchStatus.SCHEDULED && (
                      <PopoverWrapper
                        triggerButton={
                          <IconButtonIcon icon="calendarTime" color="info" size="large" margin="no xxSmall" />
                        }
                        popoverContent={<Popover>{translate('autoDispatch.autoScheduled')}</Popover>}
                        width="15px"
                        size="xSmall"
                        margin="no"
                      />
                    )}
                  </div>
                  <Text block size="medium" margin="no" weight="light">
                    {translate(`opportunity.opportunities.${camelCase(pickupTypeTechnicalName)}`)}
                  </Text>
                </DetailsListBodySection>
              </DetailsContainer>
            </TableCell>

            <TableCell width="23%" padding="defaultCellVertical no">
              <DetailsContainer noShadow padding="no">
                <PopoverWrapper
                  triggerButton={
                    <ServiceIconImage src={SERVICE_TYPE_ICONS[humps.camelize(iconType.split('.').join(''))]} />
                  }
                  popoverContent={
                    <RubiconEquipmentPopover service={equipment} startDate={startDate} endDate={endDate} />
                  }
                  width="53px"
                  size="large"
                  margin="no"
                />
                <DetailsListBodySection flex overflow="hidden" padding="no no no small">
                  <Text block weight="medium" size="small" singleLine>
                    {subType ? translate(`haulerProfile.equipments.equipmentSubTypes.${subType}`) : type} ({quantity})
                  </Text>

                  <Text block weight="light" size="small" singleLine>
                    {parseInt(size)} {equipmentUnit} | {wasteMaterial}
                  </Text>
                  <Text block weight="light" size="small" singleLine>
                    {translate(`opportunity.serviceFrequency.${camelCase(frequency)}`)} ({frequencyType})
                  </Text>
                </DetailsListBodySection>
              </DetailsContainer>
            </TableCell>

            <TableCell width={'24%'} vertical>
              <DetailsListBodySection flex justifyStart overflow="hidden" padding="no">
                <Text block weight="medium" singleLine>
                  {customerName}
                </Text>

                {(siteName || siteCode) && (
                  <Fragment>
                    <Text weight="light" size="small" doubleLine>
                      {siteName}
                      {siteCode && `, ${siteCode}`}
                    </Text>
                  </Fragment>
                )}
                <PopoverWrapper
                  triggerButton={
                    <Text block weight="light" size="small" singleLine>
                      {customerAddress}
                    </Text>
                  }
                  popoverContent={
                    <Popover>
                      <div>{customerAddress}</div>
                    </Popover>
                  }
                  width="200px"
                  size="large"
                />
              </DetailsListBodySection>
            </TableCell>

            <TableCell vertical width={'20%'}>
              <DetailsContainer noShadow padding="no">
                <DetailsListBodySection>
                  <Text block weight="medium" margin="no">
                    {statusName}
                  </Text>
                  <PopoverWrapper
                    triggerButton={
                      <Text block weight="normal" size="small" color="grayDarker">
                        {getDateByStatus(statusName, dates)}
                        {isActionRequired && (
                          <IconButtonIcon icon="bell" color="alert" size="medium" margin="no no no xxSmall" />
                        )}
                      </Text>
                    }
                    popoverContent={<RubiconDispatchesServiceDatesPopover serviceDates={dates} status={statusName} />}
                    width="90px"
                    size="large"
                  />
                  {actionRequired && !!daysOverDue && (
                    <Text weight="normal" margin="no no xxSmall" singleLine size="small" color="alert">
                      {translate('common.xDaysOverdue', { days: daysOverDue })}
                    </Text>
                  )}
                  {actionRequired && !!numberOfHoursLeft && (
                    <Text block weight="normal" margin="no no xxSmall" singleLine size="small" color="alert">
                      {translate('common.xHoursLeft', { hours: numberOfHoursLeft })}
                    </Text>
                  )}
                  {!!weightTicket && (
                    <Text block weight="normal" singleLine size="small" color="grayDarker">
                      {weightTicket} Tons
                    </Text>
                  )}
                </DetailsListBodySection>
              </DetailsContainer>
            </TableCell>

            <TableCell width={'18%'}>
              {canAccept && (
                <TableActionButton margin="small small no no" onClick={() => this.openAcceptServiceModal(pickupTypeId)}>
                  <ActionButtonTooltip
                    customViewBox="0 0 25 25"
                    size="mLarge"
                    icon={pickupTypeId === MISSED_PICKUP && !!caseId ? 'response' : 'checkCircle'}
                    tooltip={pickupTypeId === MISSED_PICKUP && !!caseId ? 'response' : 'accept'}
                  />
                </TableActionButton>
              )}

              {canDecline && (
                <TableActionButton
                  margin="xSmall small no no"
                  onClick={() => this.openDeclineOpenDispatchesModal(pickupTypeId)}
                >
                  <ActionButtonTooltip customViewBox="0 0 25 25" size="mLarge" icon="closedIssue" tooltip="decline" />
                </TableActionButton>
              )}

              {canRequestDateChange && (
                <TableActionButton
                  margin="sMedium small no no"
                  onClick={() => this.openRequestDateChangeModal(pickupTypeId)}
                >
                  <ActionButtonTooltip
                    customViewBox="0 0 22 22"
                    size="mLarge"
                    icon="scheduler"
                    color="primary"
                    tooltip="requestChange"
                  />
                </TableActionButton>
              )}

              {canComplete && (
                <TableActionButton
                  margin="small small no no"
                  onClick={() => this.openCompleteModal(pickupTypeId, isRollOff)}
                >
                  <ActionButtonTooltip
                    customViewBox="0 0 25 25"
                    size="mLarge"
                    icon="checkCircle"
                    tooltip="completeRequest"
                  />
                </TableActionButton>
              )}

              {canChooseUnable && (
                <TableActionButton
                  margin="xSmall small no no"
                  onClick={() => this.openServiceUnableToDeliverModal('CouldNotDelivered', pickupTypeId)}
                >
                  <ActionButtonTooltip
                    customViewBox="0 0 25 25"
                    size="mLarge"
                    icon="closedIssue"
                    color="primary"
                    tooltip="unableToCompleteRequest"
                  />
                </TableActionButton>
              )}

              {canUploadDocuments && (
                <TableActionButton margin="ssMedium small no no" onClick={this.openDocumentUploadModal}>
                  <ActionButtonTooltip
                    customViewBox="0 0 24 24"
                    size="mLarge"
                    icon="cloud-upload"
                    color="primary"
                    tooltip="uploadDocument"
                  />
                </TableActionButton>
              )}

              {canViewUploadedDocuments && (
                <TableActionButton margin="xSmall small no no" onClick={this.openDocumentListModal}>
                  <ActionButtonTooltip
                    customViewBox="0 0 32 20"
                    size="mLarge"
                    icon="redeye"
                    color="primary"
                    tooltip="viewDocument"
                  />
                </TableActionButton>
              )}

              <TableActionButton margin="sMedium small no no" onClick={() => this.openNotesModal(pickupTypeId, false)}>
                <ActionButtonTooltip
                  customViewBox="0 0 26 26"
                  size="mLarge"
                  icon={hasVendorNotes ? 'requestedNote' : 'requestedNoteV2'}
                  color="primary"
                  tooltip="addViewNotes"
                />
              </TableActionButton>
            </TableCell>
          </TableRow>
        )}

        {isDocumentListModalOpen && (
          <DocumentListModalResolver
            scheduledDate={serviceDate}
            workOrderNo={workorderNumber}
            closeModal={this.closeDocumentListModal}
          />
        )}

        {isAcceptServiceForNonTechHaulerModalOpen && isNonTechHauler && (
          <AcceptOpenDispatchesModal
            daysInWeekSchedule={daysInWeekSchedule}
            itemId={itemId}
            onCancel={this.closeAcceptOpenDispatchesModal}
            pickupTypeId={pickupTypeId}
            reasonCodes={reasonCodes}
            requestedServiceDate={requestedServiceDate}
            rubiconPONbr={rubiconPONbr}
            serviceDate={serviceDate}
            status={status}
            vendID={vendID}
          />
        )}

        {!!isDeclineOpenDispatchesModalOpen && (
          <DeclineOpenDispatchesModal
            mobileVersion={!!showMobileDetails}
            {...(this.props as any)}
            onCancel={this.closeDeclineOpenDispatchesModal}
          />
        )}

        {isRequestDateChangeModalOpen && (
          <RequestDateChangeForOpenDispatchesModal
            {...(this.props as any)}
            onCancel={this.closeRequestDateChangeModal}
          />
        )}

        {isServiceDeliveredModalOpen && (
          <ServiceDeliveredModal {...deliveriesModalProps} onCancel={this.closeServiceDeliveredModal} />
        )}

        {isServiceUnableToDeliverModalOpen && (
          <ServiceUnableToDeliverModal {...deliveriesModalProps} onCancel={this.closeServiceUnableToDeliverModal} />
        )}

        {isUnableToCompleteModalOpen && (
          <UnableToCompleteServiceConfirmationModal
            {...this.props}
            onCancel={this.closeUnableToCompleteModalOpen}
            mobileVersion={!!showMobileDetails}
          />
        )}

        {isCompletedExtraPickupNonROModalOpen && (
          <CompletedServiceConfirmationModal
            {...this.props}
            mobileVersion={!!showMobileDetails}
            onCancel={this.closeExtraPickupNonROModal}
          />
        )}

        {isDocumentUploadModalOpen && <DocumentUploadModal {...this.props} onCancel={this.closeDocumentUploadModal} />}

        {isAcceptServiceModalOpen && !isNonTechHauler && (
          <AcceptServiceModal
            onCancel={this.onCancelAcceptService}
            onAcceptService={this.onAcceptService}
            daysInWeekSchedule={daysInWeekSchedule}
            itemId={itemId}
            pickupTypeId={pickupTypeId}
            reasonCodes={reasonCodes}
            requestedServiceDate={requestedServiceDate}
            hasGusServiceContract={hasGusServiceContract}
            customerName={customerName}
            customerAddress={customerAddress}
            serviceDescription={serviceDescription}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,

  reasonCodes: state.fleet.rubiconDispatches.reasonCodes,
  isNonTechHauler: isRubiconNonTechHauler(
    state.account.login,
    state.vendors.defaultVendor,
    state.account.createUserAccount,
  ),
  newServiceContractId: state.fleet.openDispatches.newServiceContractId,
  serviceContract: state.fleet.openDispatches.serviceContract,
});

const mapDispatchToProps = {
  createUnassignedJob,
  getServiceContractByCode,
  loadDispatchOpportunitiesCount,
  loadRubiconDispatches,
  reset,
  responseMissedPickup,
  saveServiceContract,
  setNewServiceContractId,
  showNotesModal,
  updateHaulerOpportunity,
  updateOpenDispatches,
  updateServiceConfirmation,
  closeMobilePageDetailOverlay,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RubiconDispatchesTableRow));
