import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { sizeMapper } from '../../../utils/styles';
import { BareButtonStyle, Button } from './Button';
import { Link } from 'react-router-dom';

interface MoreButtonWrapperProps {
  margin?: string;
}

export const MoreButtonWrapper = styled.div<MoreButtonWrapperProps>`
  display: inline-flex;
  position: relative;
  user-select: none;
  margin: ${props => sizeMapper(props.margin, 'no')};
`;

export const MoreButtonPopup = styled.div<{ menuTop?: boolean }>`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 3000;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};
  padding: 10px 0;
  border-radius: 5px 0 5px 5px;

  ${props =>
    props.menuTop &&
    css`
      top: auto;
      bottom: 100%;
      left: 0;
      right: auto;
      border-radius: 5px 5px 0 5px;
    `}
`;

interface MoreButtonPopupButtonProps {
  disabled?: boolean;
  justifyContent?: string;
}

export const MoreButtonPopupButton = styled.button<MoreButtonPopupButtonProps>`
  ${BareButtonStyle}

  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  white-space: nowrap;

  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${props => props.theme.grayLight};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      color: ${props.theme.grayDark};
      background-color: ${transparentize(0.5, props.theme.grayLight)};
    `}
`;

export const MoreButtonPopupButtonSpacer = styled.div`
  flex: 1;
`;

export const MoreButtonLink = styled(Link)`
  display: inline-block;
`;

export const MoreButtonComponent = styled(Button)`
  width: 100%;
`;
