import { createErrorNotification } from 'src/core/services/createNotification';
import { ResponseError } from '../interfaces/DispatchBoardRouteJob';
import translate from 'src/core/services/translate';

export enum RESPONSE_TYPES {
  'delete' = 'delete',
  'transfer' = 'transfer',
}

export const displayMessageOnResponseError = (error: ResponseError, type: string) => {
  const code = error && error.response && error.response.data && error.response.data.code;
  switch (code) {
    case 'DeliveryWOCannotBeUndispatched':
      createErrorNotification(translate('routes.alertMessages.routeJobDeleteErrorDeliveryWOCannotBeUndispatched'));
      break;
    default:
      createErrorNotification(
        translate(
          type === RESPONSE_TYPES.delete
            ? 'routes.alertMessages.routeJobDeleteError'
            : 'routes.alertMessages.routeStopsTransferError',
        ),
      );
      break;
  }
};
