import React, { Fragment, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import moment from 'moment';

import { AppState } from '../../../store';
import { Container, ButtonSet, Button, Text } from '../../../core/components/styled';
import {
  CONTAINER_BLOCKED,
  CONTAINER_CONTAMINATED,
  CONTAINER_OVERFLOWING,
  DRIVER_MISSED,
  HOLIDAY,
  INTERNAL_TRUCK_PERSONNEL_ISSUE,
  INCORRECT_SERVICE_DAYS,
  NOT_SERVICE_PROVIDER,
  ROUTE_DELAY,
  SITE_CONTAINER_INACCESSIBLE,
  SERVICE_ON_SUSPENSION,
  SITE_UNSAFE,
  SERVICE_COMPLETED,
  WEATHER_EVENT,
} from 'src/fleet/constants/missedPickupReasonCodes';
import { DatePicker, DaysOfWeekPicker, Dropdown, FileInput, TextArea, TypedField } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import openDispatchesFormInitialValueSelector from '../../services/openDispatchesFormInitialValueSelector';
import { ReasonCodeGroups } from 'src/fleet/interfaces/RubiconDispatches';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  itemId: number;
  pickupTypeId: number;
  reasonCodes: ReasonCodeGroups;
  requestedServiceDate?: string;
  rubiconPONbr: string;
  serviceDate: Date | string;
  missedPickupReasonCodes: any[];
  onCancel(pristine: boolean): void;
  daysInWeekSchedule?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const AcceptOpenDispatchesForm: React.FC<Props> = ({
  itemId,
  missedPickupReasonCodes,
  pickupTypeId,
  pristine,
  requestedServiceDate,
  reasonCodes,
  rubiconPONbr,
  serviceDate,
  change,
  handleSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [reasonCode, setReasonCode] = useState(null);

  const missedPickupReasonOptions = missedPickupReasonCodes.map(reasonCode => ({
    label: reasonCode.description,
    value: reasonCode.code,
  }));

  const onReasonCodeChange = (reason: any) => {
    setReasonCode(reason);
    if (reason === INCORRECT_SERVICE_DAYS || reason === NOT_SERVICE_PROVIDER)
      dispatch(change(`opportunities.settings${rubiconPONbr}${serviceDate}.completedDate`, null));
  };

  const reasonCodesOptions =
    pickupTypeId === MISSED_PICKUP && itemId ? missedPickupReasonOptions : reasonCodes.acceptRecovery;

  const isNewServiceHidden = reasonCode === INCORRECT_SERVICE_DAYS || reasonCode === NOT_SERVICE_PROVIDER;
  const isDaysOfWeekVisible = reasonCode === INCORRECT_SERVICE_DAYS;

  const dateValidation =
    reasonCode === SERVICE_ON_SUSPENSION ? [isDateValidValidator] : [isRequired, isDateValidValidator];

  let disableDays: any;

  if (
    reasonCode === CONTAINER_BLOCKED ||
    reasonCode === SITE_CONTAINER_INACCESSIBLE ||
    reasonCode === SITE_UNSAFE ||
    reasonCode === SERVICE_COMPLETED ||
    reasonCode === CONTAINER_OVERFLOWING ||
    reasonCode === CONTAINER_CONTAMINATED
  ) {
    disableDays = {
      before: moment().toDate(),
      after: moment().add(7, 'days').toDate(),
    };
  } else if (
    reasonCode === INTERNAL_TRUCK_PERSONNEL_ISSUE ||
    reasonCode === DRIVER_MISSED ||
    reasonCode === ROUTE_DELAY
  ) {
    disableDays = {
      before: moment().toDate(),
      after: moment().add(48, 'hours').toDate(),
    };
  } else if (
    reasonCode === WEATHER_EVENT ||
    reasonCode === NOT_SERVICE_PROVIDER ||
    reasonCode === HOLIDAY ||
    reasonCode === SERVICE_ON_SUSPENSION
  ) {
    disableDays = {
      before: moment().toDate(),
      after: moment().add(5, 'days').toDate(),
    };
  } else {
    disableDays = {
      before: moment().toDate(),
      after: moment().add(2, 'days').toDate(),
    };
  }
  //const mustValidator = mustLength(daysInWeekSchedule || 0);
  return (
    <form onSubmit={handleSubmit} noValidate>
      {pickupTypeId === MISSED_PICKUP && (
        <Fragment>
          <Container>
            <Text weight="light" margin="small small small">
              {translate('autoDispatch.originalServiceDate')}: {requestedServiceDate}
            </Text>
          </Container>

          <Container>
            <TypedField
              name={`opportunities.settings${rubiconPONbr}${serviceDate}.reasonCode`}
              component={Dropdown}
              validate={[isRequired]}
              onChange={e => onReasonCodeChange(e)}
              props={{
                label: translate('opportunity.selectReasonType'),
                margin: 'medium small small',
                options: reasonCodesOptions,
              }}
            />
          </Container>
          {!isNewServiceHidden && (
            <Container>
              <TypedField
                name={`opportunities.settings${rubiconPONbr}${serviceDate}.completedDate`}
                component={DatePicker}
                validate={dateValidation}
                props={{
                  raisedLabel: true,
                  label: translate('routes.holidayPlanner.newServicDate'),
                  margin: 'large small small',
                  disabledDays: [disableDays],
                  isTodayWithNoStyle: true,
                }}
              />
            </Container>
          )}
          {isDaysOfWeekVisible && (
            <Container>
              <TypedField
                name={`opportunities.settings${rubiconPONbr}${serviceDate}.dayOfWeek`}
                component={DaysOfWeekPicker}
                validate={[isRequired]}
                props={{
                  label: translate('common.dayOfWeek'),
                  daysOfWeekProps: { margin: 'xSmall' },
                  dayOfWeekProps: { margin: 'xSmall' },
                  margin: 'large small no',
                  multiple: true,
                }}
              />
            </Container>
          )}
          <Container>
            <Field
              name={`opportunities.settings${rubiconPONbr}${serviceDate}.file`}
              component={FileInput}
              type="text"
              accept={'.doc,.csv,.jpg,.png,.pdf,.xls,.xlsx'}
              margin="sMedium no sMedium no"
            />
          </Container>
        </Fragment>
      )}
      <Container>
        <Field
          name={`opportunities.settings${rubiconPONbr}${serviceDate}.note`}
          component={TextArea}
          rows={2}
          label={translate('routes.addRouteNote')}
          size="small"
          margin="medium small large"
        />
      </Container>

      <ButtonSet margin="large small large">
        <Button type="submit" color="primary">
          {translate('common.accept')}
        </Button>

        <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ButtonSet>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  initialValues: openDispatchesFormInitialValueSelector(
    state.fleet.openDispatches.opportunities,
    state.fleet.rubiconDispatches.rubiconDispatches,
  ),
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'acceptOpenDispatches',
    onSubmitFail: focusFirstInvalidField,
  })(AcceptOpenDispatchesForm),
);
