import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { isMaterialTypesFeatureEnabled } from 'src/vendors/ducks/features';
import { loadDispatchBoardRouteJob, loadDispatchBoardUnassignedJob } from '../../ducks/dispatchBoard';
import { loadGeoFenceFilterOptions } from 'src/routes/ducks';
import { loadJobPriorityTypes, loadReasonCodeTypes, loadWasteTypes } from '../../../common/ducks';
import { loadDispatchBoardMaterialTypes } from 'src/vendors/ducks';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import DispatchBoardJobEditorModal, { DispatchBoardJobEditorModalCommonProps } from './DispatchBoardJobEditorModal';

const DispatchBoardJobEditorModalResolver: React.FC<DispatchBoardJobEditorModalCommonProps> = ({
  jobId,
  routeId,
  date,
  forceMaterialTypeId,
  ...props
}) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const isMaterialTypesEnabled = useSelector(isMaterialTypesFeatureEnabled);

  const loadDependencies = React.useCallback(() => {
    const dependencies = [loadReasonCodeTypes()(dispatch), loadWasteTypes()(dispatch)];

    if (vendorId) {
      dependencies.push(
        loadVehicleTypesForVendor(vendorId)(dispatch),
        loadGeoFenceFilterOptions({ vendorId, routeDate: date })(dispatch),
        loadJobPriorityTypes()(dispatch),
      );

      if (isMaterialTypesEnabled) {
        dependencies.push(loadDispatchBoardMaterialTypes(vendorId, props.vehicleTypeId, forceMaterialTypeId)(dispatch));
      }
    }

    if (jobId) {
      if (routeId) {
        dependencies.push(loadDispatchBoardRouteJob(jobId)(dispatch));
      } else {
        dependencies.push(loadDispatchBoardUnassignedJob(jobId)(dispatch));
      }
    }

    return Promise.all(dependencies);
  }, [date, dispatch, jobId, routeId, vendorId, props.vehicleTypeId, isMaterialTypesEnabled, forceMaterialTypeId]);

  return (
    <TypedResolver
      successComponent={DispatchBoardJobEditorModal}
      loadingComponent={PageLoadingOverlay}
      successProps={{ jobId, routeId, date, isMaterialTypesEnabled, ...props }}
      resolve={loadDependencies}
    />
  );
};

export default DispatchBoardJobEditorModalResolver;
