import { useDispatch } from 'react-redux';

import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { resetRouteSummary } from 'src/routes/ducks/routeSummary';
import { TypedResolver } from 'src/core/components';
import CreateSnowOrSweeperRoutePage from './CreateSnowOrSweeperRoutePage';
import translate from 'src/core/services/translate';

export default function CreateSnowOrSweeperRoutePageResolver() {
  const dispatch = useDispatch();

  const loadDependencies = async () => {
    const dependencies = [];
    dependencies.push(dispatch(resetRouteSummary()));

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>

      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/customers/service-network"
        successComponent={CreateSnowOrSweeperRoutePage}
        resolve={loadDependencies}
      />
    </>
  );
}
