import React, { Component } from 'react';
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form';
import { size, find } from 'lodash-es';
import { Checkbox, Input } from '../../../../core/components';
import {
  Button,
  ButtonIcon,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  Panel,
  PanelSectionTitle,
} from '../../../../core/components/styled';
import confirm from '../../../../core/services/confirm';
import normalizePhone from '../../../../core/services/normalizePhone';
import translate from '../../../../core/services/translate';
import { isEmail, isOptionalFax, isOPtionalEmail, isPhone, isRequired } from '../../../../utils/services/validator';
import { ContactTypeDropdown } from '../..';

const FieldArrayMock = FieldArray as any;
const FieldMock = Field as any;
const CONTACT_TYPE = 'TXTMS';

interface ContactState {
  selectedContactTypes: any[];
}

class ContactsFieldArray extends Component<WrappedFieldArrayProps, ContactState> {
  state = { selectedContactTypes: [] };

  componentDidMount() {
    const { fields } = this.props;
    const { selectedContactTypes } = this.state;
    if (size(selectedContactTypes) === 0) {
      this.setState({
        selectedContactTypes: fields
          .map((name, index, fields) => ({
            selectedIndex: index,
            selectedValue: fields.get(index).type,
          }))
          .filter(item => item.selectedValue === CONTACT_TYPE),
      });
    }
  }

  deleteContact = async (event: React.MouseEvent, index: number) => {
    event.stopPropagation();

    if (!(await confirm(translate('haulerProfile.alertMessages.confirmDeleteContact')))) {
      return;
    }

    this.props.fields.remove(index);
  };

  onContactTypeChange = (event: any, index: number) => {
    this.setState({
      selectedContactTypes: [
        {
          selectedIndex: index,
          selectedValue: event,
        },
        ...this.state.selectedContactTypes,
      ],
    });
  };

  render() {
    const { fields } = this.props;
    const { selectedContactTypes } = this.state;
    return (
      <Panel padding="large medium">
        <PanelSectionTitle>{translate('haulerProfile.contacts')}</PanelSectionTitle>

        <Grid multiLine>
          {fields.map((field, index) => {
            const selectedContactType = find(selectedContactTypes, { selectedIndex: index }) as any;
            let selectedValue;
            let selectedIndex;
            if (selectedContactType) {
              selectedValue = selectedContactType.selectedValue;
              selectedIndex = selectedContactType.selectedIndex;
            }

            const isDisabled = selectedValue === CONTACT_TYPE && selectedIndex === index;
            const emailValidation = isDisabled ? [isOPtionalEmail] : [isRequired, isEmail];
            return (
              <GridColumn size="4/12" tabletSize="6/12" padding="small medium medium no" key={index}>
                <FieldMock
                  name={`${field}.type`}
                  component={ContactTypeDropdown}
                  label={translate('haulerProfile.contactType')}
                  validate={[isRequired]}
                  onChange={(event: any) => this.onContactTypeChange(event, index)}
                />

                <Field
                  name={`${field}.name`}
                  component={Input}
                  label={translate('common.name')}
                  validate={[isRequired]}
                />

                <Field
                  name={`${field}.emailAddress`}
                  component={Input}
                  label={translate('common.email')}
                  validate={emailValidation}
                  disabled={isDisabled}
                />

                <Field
                  name={`${field}.phoneNumber`}
                  component={Input}
                  type="text"
                  label={translate('common.phone')}
                  format={normalizePhone}
                  validate={[isRequired, isPhone]}
                />

                <Field
                  name={`${field}.faxNumber`}
                  component={Input}
                  label={translate('common.fax')}
                  validate={[isOptionalFax]}
                  disabled={isDisabled}
                />
                <Field
                  name={`${field}.isPrimary`}
                  component={Checkbox}
                  label={translate('haulerProfile.primary')}
                  disabled={isDisabled}
                />
                <ButtonSet margin="no" align="right">
                  <Button
                    text
                    color="primary"
                    type="button"
                    padding="no"
                    margin="no"
                    onClick={(event: React.MouseEvent) => this.deleteContact(event, index)}
                  >
                    <ButtonIcon icon="delete" size="medium" />
                  </Button>
                </ButtonSet>
              </GridColumn>
            );
          })}
        </Grid>

        {!fields.length && <Message padding="no">{translate('haulerProfile.noContacts')}</Message>}

        <ButtonSet margin="sMedium no no">
          <Button type="button" color="primary" line onClick={() => fields.push({})}>
            + {translate('haulerProfile.addContact')}
          </Button>
        </ButtonSet>
      </Panel>
    );
  }
}

const Contacts = () => <FieldArrayMock name="contacts" component={ContactsFieldArray} />;

export default Contacts;
