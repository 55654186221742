import React from 'react';

import { useDispatch } from 'react-redux';
import { find, get } from 'lodash-es';

import { useSelector } from 'src/core/hooks/useSelector';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { loadServiceZones } from 'src/routes/ducks';
import { loadServiceTypes } from 'src/common/ducks';
import DefaultFiltersModal from './DefaultFiltersModal';

interface Props extends ModalProps {
  userId: string;
}

const DefaultFiltersModalResolver: React.FC<Props> = ({ closeModal, userId }) => {
  const dispatch = useDispatch();
  const selectedUser = useSelector(state => find(get(state.vendors.users as any, 'users', {}), { userId }));
  const vendorId = useSelector(currentVendorId);
  // TODO: load here the default user filters
  const loadDependencies = React.useCallback(() => {
    if (!userId || !vendorId) {
      return Promise.reject();
    }

    const isGlobal = true;
    return Promise.all([
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      loadServiceZones(vendorId)(dispatch),
      loadServiceTypes()(dispatch),
      loadFiltersSettings(vendorId, userId, isGlobal)(dispatch),
    ]);
  }, [userId, vendorId, dispatch]);

  return (
    <Resolver
      successComponent={DefaultFiltersModal}
      successProps={{ closeModal, user: selectedUser, isAdmin: true }}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      onError={closeModal}
    />
  );
};

export default DefaultFiltersModalResolver;
