import { mapKeys } from 'lodash-es';
import completed from '../assets/img/completed.svg';
import failed from '../assets/img/failed.svg';
import processing from '../assets/img/processing.svg';

export const PENDING = 'Pending';
export const IN_PROGRESS = 'InProgress';
export const COMPLETE = 'Complete';
export const FAILED = 'Failed';
export const FAILED_CONFIRMED = 'FailedConfirmed';
export const FINALIZED = 'Finalized';
export const CANCELED = 'Canceled';

export const ROUTE_SEQUENCE_STATUS_ICONS = mapKeys(
  [
    { technicalName: PENDING, icon: processing },
    { technicalName: IN_PROGRESS, icon: processing },
    { technicalName: COMPLETE, icon: completed },
    { technicalName: FAILED, icon: failed },
    { technicalName: FAILED_CONFIRMED, icon: '' },
    { technicalName: FINALIZED, icon: '' },
    { technicalName: CANCELED, icon: '' },
  ],
  'technicalName',
);
