import { arrayPush, arraySplice, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import { BillingTableForm } from 'src/finance/components/modals/RateCodeModal';
import { Button, ButtonSet, ModalFixedFooter, PanelSection } from 'src/core/components/styled';
import { getEmptyRate } from 'src/finance/ducks/serviceRates';
import { Modal } from 'src/core/components';
import { Rate } from 'src/finance/interfaces/ServiceRates';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TableCell } from 'src/core/components/Table';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export const rateCodeEditorModalId = 'rate-code-editor-modal-id';

interface RateCodeModalProps {
  closeModal: () => void;
  formProps: {
    customerName?: string | undefined;
    location?: string | undefined;
    tableCells?: TableCell[];
    workOrderId?: number | string;
    workOrderDate?: StringOrDate;
  };
  isBillReleased: boolean;
}

export interface RateCodeFormValues {
  rates: Rate[];
}

export const RATE_CODE_EDITOR_FORM_NAME = 'rateCode';

type Props = RateCodeModalProps & InjectedFormProps<RateCodeFormValues, RateCodeModalProps>;

const RateCodeModal: React.FC<Props> = ({ closeModal, handleSubmit, formProps, isBillReleased }) => {
  const dispatch = useDispatch();

  const isSaving = useSelector(state => state.finance.serviceRates.isSaving);

  const handleAddNewRate = () => dispatch(arrayPush(RATE_CODE_EDITOR_FORM_NAME, 'rates', getEmptyRate()));
  const handleRemoveRate = (index: number) =>
    dispatch(arraySplice(RATE_CODE_EDITOR_FORM_NAME, 'rates', index, 1, null));

  return (
    <Modal
      onClose={closeModal}
      size="xLarge"
      title={translate('finance.modal.rateCode')}
      padding="medium no no no"
      isLoading={isSaving}
      id={rateCodeEditorModalId}
      overflow={isSaving ? 'hidden' : 'auto'}
    >
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="small xSmall no" vertical>
          <BillingTableForm
            handleRemoveRate={handleRemoveRate}
            customerName={formProps?.customerName}
            location={formProps?.location}
            workOrderDate={formProps?.workOrderDate}
            workOrderId={formProps?.workOrderId}
            isBillReleased={isBillReleased}
          />

          {!isBillReleased && (
            <ButtonSet margin="medium no xGrande no" align="center">
              <Button type="button" color="primary" line onClick={handleAddNewRate} margin={'no xSmall no no'}>
                {`+ ${translate('finance.modal.newRate')}`}
              </Button>
            </ButtonSet>
          )}
        </PanelSection>

        <ModalFixedFooter isShadowed>
          <Button type="button" color="secondary" margin="no xSmall no no" onClick={closeModal}>
            {translate('common.cancel')}
          </Button>
          <Button type="submit" color="primary" margin="no no no xSmall" disabled={isBillReleased}>
            {translate('finance.modal.update')}
          </Button>
        </ModalFixedFooter>
      </form>
    </Modal>
  );
};

export default reduxForm<RateCodeFormValues, RateCodeModalProps>({
  form: RATE_CODE_EDITOR_FORM_NAME,
  enableReinitialize: true,
})(RateCodeModal);
