import React from 'react';

import TooltipButton from '../../core/components/TooltipButton';
import { Box } from 'src/core/components/styled/Box';

const renderListSummary = (list: string[], rawLimit = 3) => {
  const limit = rawLimit < 1 ? 1 : rawLimit;

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" ml={-5} mr={-5}>
      <Box ml={5} mr={5}>
        {list.slice(0, limit).join(', ')}
      </Box>

      {list.length > limit && (
        <Box ml={5} mr={5}>
          <TooltipButton
            text
            noWrap
            size="xSmall"
            color="primary"
            margin="no"
            tooltipAsString
            tooltip={list.slice(limit).join(', ')}
          >
            +{list.length - limit} more
          </TooltipButton>
        </Box>
      )}
    </Box>
  );
};

export default renderListSummary;
