import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { debounce } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';

import { MapGL } from 'src/common/components/map/MapGL';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import { getMapBounds } from 'src/common/components/map/util';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DrawingInstructions } from 'src/routes/components/pages/routeTemplateBuilder/routeTemplateBuilderMap/DrawingInstructions';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { getRateBuilderMapGeoJSON } from './map/util';
import RateBuilderMapGLSource from './map/RateBuilderMapGLSource';
import RateBuilderMapGLPopup from './map/RateBuilderMapGLPopup';

interface Props {
  setCheckedServiceContractIds: (ids: number[]) => void;
  checkedServiceContractIds: number[];
}

const RateBuilderMapGL = ({ setCheckedServiceContractIds, checkedServiceContractIds }: Props) => {
  const editorRef = useRef<Editor>(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  const [editPolygon, setEditPolygon] = useState(true);
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [mapDrawingEnabled, setMapDrawingEnabled] = useState(false);
  const [polygon, setPolygon] = useState<any>();
  const [bounds, setMapBounds] = useState<any>();
  const [selectedFeature, setSelectedFeature] = useState<any>(undefined); // For clicked points
  const serviceContractsData = useSelector(state => state.customers.rateBuilder.serviceContracts);
  const serviceContracts = useMemo(() => serviceContractsData?.serviceContracts || [], [serviceContractsData]);

  const geoJSON = useMemo(() => getRateBuilderMapGeoJSON(serviceContracts), [serviceContracts]);

  useEffect(() => {
    const points = serviceContracts.map((j: any) => ({
      latitude: j.displayLatitude,
      longitude: j.displayLongitude,
    }));
    const bounds = getMapBounds(points);
    setMapBounds(bounds);
  }, [geoJSON, serviceContracts]);

  const selectPointsInPolygon = useCallback(
    (points: any[], newPolygon: any) => {
      const includedPoints = points.filter(p =>
        booleanPointInPolygon([p.displayLongitude, p.displayLatitude], newPolygon),
      );
      const sIds = includedPoints.map(item => item.serviceContractId);
      setCheckedServiceContractIds(sIds);
      setPolygon(newPolygon);
    },
    [setCheckedServiceContractIds],
  );

  const selectPointsInPolygonDebounced = useMemo(() => debounce(selectPointsInPolygon, 200), [selectPointsInPolygon]);

  const checkLocationsInPolygon = (event: any) => {
    const newPolygon = event.data[0];

    setPolygon(newPolygon);
    if (event.editType === 'addFeature') {
      selectPointsInPolygon(serviceContracts, newPolygon);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(serviceContracts, newPolygon);
    }
  };

  useEffect(() => {
    if (mapDrawingEnabled) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
      }
    } else {
      if (polygon && editorRef.current) {
        editorRef.current.deleteFeatures(0);
        setCheckedServiceContractIds([]);
        setPolygon(undefined);
      }

      if (drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [mapDrawingEnabled, drawMode, polygon, setCheckedServiceContractIds]);

  // Memoized function to find a service contract by feature ID
  const getServiceContractById = useCallback(
    (id: string | number) => serviceContracts.find(contract => contract.serviceContractId === id),
    [serviceContracts],
  );

  useEffect(() => {
    if (map) {
      const handleMapClick = (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const [feature] = map.queryRenderedFeatures(event.point);

        if (feature && feature.id) {
          // Use the feature ID to find the full data from serviceContracts
          const matchedContract = getServiceContractById(feature.id);
          if (matchedContract) {
            // Pass full matched contract data to selectedFeature
            setSelectedFeature(matchedContract);
          } else {
            setSelectedFeature(undefined);
          }
        } else {
          setSelectedFeature(undefined);
        }
      };

      map.on('click', handleMapClick);

      const handleMouseMove = (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const features = map.queryRenderedFeatures(event.point);
        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      };

      map.on('mousemove', handleMouseMove);

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });

      return () => {
        map.off('click', handleMapClick);
        map.off('mousemove', handleMouseMove);
      };
    }
  }, [map, getServiceContractById]);

  return (
    <MapGLWrapper drawingEnabled={!!drawMode && editPolygon} isDrawing={!!drawMode && !polygon}>
      <MapGL
        bounds={bounds}
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        onMapRefLoaded={setMap}
      >
        {drawMode && (
          <Editor
            ref={editorRef}
            clickRadius={12}
            mode={drawMode}
            selectable
            onUpdate={checkLocationsInPolygon}
            features={polygon ? [polygon] : []}
          />
        )}

        {map && (
          <RateBuilderMapGLSource
            geoJSON={geoJSON}
            map={map}
            selectedJobIds={checkedServiceContractIds.map(id => id)}
          />
        )}

        {selectedFeature && (
          <RateBuilderMapGLPopup selectedFeature={selectedFeature} onClose={() => setSelectedFeature(undefined)} />
        )}
      </MapGL>

      <ComplexMapControl vertical position="top-left">
        <TooltipIconButton
          tooltipAsString
          tooltip={translate(drawMode ? 'routeTemplateBuilder.deletePolygon' : 'routeTemplateBuilder.drawPolygon')}
          tooltipPosition="right"
          tooltipColor="grayDarker"
          margin="small no no"
          color={drawMode ? 'warning' : 'secondary'}
          onClick={() => {
            setEditPolygon(true);
            setMapDrawingEnabled(!mapDrawingEnabled);
          }}
        >
          <IconButtonIcon margin="no" icon={drawMode ? 'delete' : 'highlight'} />
        </TooltipIconButton>

        {polygon && (
          <TooltipIconButton
            tooltipAsString
            tooltip={translate(
              editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
            )}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            margin="small no no"
            color={editPolygon ? 'primary' : 'secondary'}
            onClick={() => setEditPolygon(!editPolygon)}
          >
            <IconButtonIcon margin="no" icon="edit" />
          </TooltipIconButton>
        )}
      </ComplexMapControl>

      {drawMode && <DrawingInstructions />}
    </MapGLWrapper>
  );
};

export default RateBuilderMapGL;
