import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

const DRY = 1;
const WET = 2;
const OTHER = 3;

const DAWN = 1;
const DAYLIGHT = 2;
const DUSK = 3;
const DARK = 4;

export const WEATHER_CONDITION_TYPES = mapKeys(
  [
    { id: DRY, name: translate('common.weatherConditionTypes.dry') },
    { id: WET, name: translate('common.weatherConditionTypes.wet') },
    { id: OTHER, name: translate('common.weatherConditionTypes.other') },
  ],
  'id',
);

export const LIGHT_CONDITION_TYPES = mapKeys(
  [
    { id: DAWN, name: translate('common.lightConditionTypes.dawn') },
    { id: DAYLIGHT, name: translate('common.lightConditionTypes.daylight') },
    { id: DUSK, name: translate('common.lightConditionTypes.dusk') },
    { id: DARK, name: translate('common.lightConditionTypes.dark') },
  ],
  'id',
);
