import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPristine } from 'redux-form';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalTitle, ModalSection } from '../../../core/components/styled';
import { resetRouteSequenceSettings, saveRouteSequenceSettings } from '../../ducks';
import { ROUTE_SEQUENCE_SETTINGS_FORM_NAME } from '../../constants/routeSettingsForm';
import { RouteSequenceFormValues } from '../../interfaces/RouteSequenceSettings';
import { routeSequenceSettingsFormInitialValues } from '../../services/routeSequenceSettingsFormInitialValuesSelector';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import RouteSequenceForm from '../forms/RouteSequenceForm';
import translate from '../../../core/services/translate';

interface Props {
  closeModal(pristine: boolean): void;
}

const RouteSequenceModal: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector((state: AppState) => currentVendorId(state));
  const initialValues = useSelector((state: AppState) => routeSequenceSettingsFormInitialValues(state));
  const formPristine = useSelector((state: AppState) => isPristine(ROUTE_SEQUENCE_SETTINGS_FORM_NAME)(state));

  const onSubmit = React.useCallback(
    ({ settings }: RouteSequenceFormValues) => {
      if (!vendorId) {
        return;
      }

      saveRouteSequenceSettings(
        vendorId,
        settings,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('vendors.alertMessages.routeSequenceSettingsSaved'));
          closeModal(true);
        })
        .catch(({ code }: { code: any }) => {
          createErrorNotification(
            code
              ? translate(createTranslationKey(code, 'vendors.alertMessages'))
              : translate('vendors.alertMessages.routeSequenceSettingsError'),
          );
        });
    },
    [vendorId, closeModal, dispatch],
  );

  const tryCloseModal = React.useCallback(() => {
    closeModal(formPristine);
  }, [closeModal, formPristine]);

  React.useEffect(
    () => () => {
      dispatch(resetRouteSequenceSettings());
    },
    [dispatch],
  );

  return (
    <Modal padding="no" size="smallMedium" onClose={tryCloseModal}>
      <ModalSection padding="medium no no">
        <ModalTitle>{translate('vendors.featureCodes.routeSequencing')}</ModalTitle>
      </ModalSection>

      <ModalSection>
        <RouteSequenceForm initialValues={initialValues} onSubmit={onSubmit} />
      </ModalSection>
    </Modal>
  );
};

export default RouteSequenceModal;
