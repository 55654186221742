import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction } from 'redux';

import {
  createLocationAlert,
  deleteLocationAlert as doDeleteLocationAlert,
  loadLocationAlerts as doLoadLocationAlerts,
  updateLocationAlert,
} from '../services/locationAlerts';
import { LocationAlert } from '../interfaces/LocationAlert';
import { ThunkDispatch } from 'redux-thunk';

// Actions
const START_LOAD = 'vendors/locationAlerts/START_LOAD';
const COMPLETE_LOAD = 'vendors/locationAlerts/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/locationAlerts/FAIL_LOAD';
const START_SAVE = 'vendors/locationAlerts/START_SAVE';
const COMPLETE_SAVE = 'vendors/locationAlerts/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/locationAlerts/FAIL_SAVE';
const START_DELETE = 'vendors/locationAlerts/START_DELETE';
const COMPLETE_DELETE = 'vendors/locationAlerts/COMPLETE_DELETE';
const FAIL_DELETE = 'vendors/locationAlerts/FAIL_DELETE';
const RESET = 'vendors/locationAlerts/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  locationAlerts: LocationAlert[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  locationAlerts: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          locationAlerts: action.locationAlerts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE: {
      const { locationAlert } = action;
      const locationAlertIndex = findIndex(state.locationAlerts, { id: locationAlert.id });
      if (locationAlertIndex === -1) {
        return update(state, {
          locationAlerts: { $push: [locationAlert] },
          $merge: { isSaving: false },
        } as any);
      }
      return update(state, {
        locationAlerts: { [locationAlertIndex]: { $set: locationAlert } },
        $merge: { isSaving: false },
      } as any);
    }

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const locationAlertIndex = findIndex(state.locationAlerts, { id: action.id });
      return update(state, {
        locationAlerts: { $splice: [[locationAlertIndex, 1]] },
        $merge: { isDeleting: false },
      } as any);
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (locationAlerts: LocationAlert[]) => ({
  type: COMPLETE_LOAD,
  locationAlerts,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (locationAlert: LocationAlert) => ({
  type: COMPLETE_SAVE,
  locationAlert,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (id: number) => ({
  type: COMPLETE_DELETE,
  id,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadLocationAlerts = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadLocationAlertsPromise = doLoadLocationAlerts(vendorId);
  loadLocationAlertsPromise
    .then(locationAlerts => dispatch(completeLoad(locationAlerts)))
    .catch(() => dispatch(failLoad()));

  return loadLocationAlertsPromise;
};

export const saveLocationAlert = (vendorId: number, locationAlert: LocationAlert) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveLocationAlertPromise = locationAlert.id
    ? updateLocationAlert(vendorId, locationAlert)
    : createLocationAlert(vendorId, locationAlert);
  saveLocationAlertPromise
    .then(savedLocationAlert => dispatch(completeSave(savedLocationAlert)))
    .catch(() => dispatch(failSave()));

  return saveLocationAlertPromise;
};

export const deleteLocationAlert = (vendorId: number, locationAlertTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteLocationAlertPromise = doDeleteLocationAlert(vendorId, locationAlertTypeId);
  deleteLocationAlertPromise.then(() => dispatch((completeDelete as any)())).catch(() => dispatch(failDelete()));
  return deleteLocationAlertPromise;
};

export const resetLocationAlerts = () => ({
  type: RESET,
});
