import React from 'react';
import { reduxForm, submit, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { PanelSectionTitle } from '../../../core/components/styled';
import { HaulerProfileGrid, HaulerProfileGridColumn } from '../styled';
import { BusinessCategory, BusinessType, Classifications } from './businessTypeFormSections';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import businessTypeFormInitialValuesSelector from '../../services/businessTypeFormInitialValuesSelector';
import { AppState } from '../../../store';

interface PropsWithoutReduxForm extends RouteComponentProps {
  initialValues: any;
  submit: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const BusinessTypeForm: React.FC<Props> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <HaulerProfileGrid>
      <HaulerProfileGridColumn padding="large medium">
        <PanelSectionTitle>{translate('haulerProfile.businessCategory')}</PanelSectionTitle>
        <BusinessCategory />
      </HaulerProfileGridColumn>
      <HaulerProfileGridColumn padding="large medium">
        <PanelSectionTitle>{translate('haulerProfile.businessType')}</PanelSectionTitle>
        <BusinessType />
      </HaulerProfileGridColumn>
      <HaulerProfileGridColumn padding="large medium">
        <PanelSectionTitle>{translate('haulerProfile.certifications/Classifications')}</PanelSectionTitle>
        <Classifications />
      </HaulerProfileGridColumn>
    </HaulerProfileGrid>
  </form>
);

const mapStateToProps = (state: AppState) => ({
  initialValues: businessTypeFormInitialValuesSelector(state.haulerProfile.businessType.businessType),
});

const mapDispatchToProps = {
  submit,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'businessType',
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(BusinessTypeForm),
  ),
);
