import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useRef } from 'react';

export const useClickableFeatures = (
  layers: string[],
  handleFeatureClick: (feature: mapboxgl.MapboxGeoJSONFeature) => void,
  map?: mapboxgl.Map,
) => {
  const handleFeatureClickRef = useRef(handleFeatureClick);

  useEffect(() => {
    handleFeatureClickRef.current = handleFeatureClick;
  }, [handleFeatureClick]);

  useEffect(() => {
    handleFeatureClickRef.current = handleFeatureClick;
  }, [handleFeatureClick]);

  const handleClick = useCallback(
    (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (map) {
        const features = map.queryRenderedFeatures(event.point, { layers });

        if (features.length) {
          handleFeatureClickRef.current(features[0]);
        }
      }
    },
    [layers, map],
  );

  const handleMouseMove = useCallback(
    (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (map) {
        const features = map.queryRenderedFeatures(event.point, { layers });

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      }
    },
    [layers, map],
  );

  const handleMouseLeave = useCallback(
    (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (map) {
        map.getCanvas().style.cursor = '';
      }
    },
    [map],
  );

  useEffect(() => {
    if (map) {
      map.on('click', handleClick);
      map.on('mousemove', handleMouseMove);
      map.on('mouseleave', handleMouseLeave);
      return () => {
        map.off('click', handleClick);
        map.off('mousemove', handleMouseMove);
        map.off('mouseleave', handleMouseLeave);
      };
    }
  }, [map, handleClick, handleMouseMove, handleMouseLeave]);
};
