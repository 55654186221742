import { Modal } from 'src/core/components';
import TransferSnowOrSweeperTemplateSegmentsForm from 'src/routes/components/forms/TransferSnowOrSweeperTemplateSegmentsForm';
import translate from 'src/core/services/translate';

type Props = {
  closeModal: () => void;
  onSubmit: (formData: any) => void;
};

export default function TransferSnowOrSweeperSegmentsModal({ closeModal, onSubmit }: Props) {
  return (
    <Modal title={translate('routes.transferSegments')} overflow="visible" onClose={closeModal} padding="medium">
      <TransferSnowOrSweeperTemplateSegmentsForm onSubmit={onSubmit} />
    </Modal>
  );
}
