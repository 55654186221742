import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const FAULT_CODE = 'FaultCode';
export const FAULT_CODE_INSIGHT = 'FaultCodeInsight';
export const HARD_ACCELERATION = 'HardAcceleration';
export const HARD_ACCELERATION_INSIGHT = 'HardAccelerationInsight';
export const HARD_BRAKING = 'HardBraking';
export const HARD_BRAKING_INSIGHT = 'HardBrakingInsight';
export const HARD_TURNING = 'HardTurning';
export const HARD_TURNING_INSIGHT = 'HardTurningInsight';
export const LOGIN = 'Login';
export const SPEEDING = 'Speeding';
export const SPEEDING_INSIGHT = 'SpeedingInsight';
export const STATIONARY = 'Stationary';
export const SERVICE_CONFIRMATION = 'ServiceConfirmation';
export const BATTERY = 'Battery';
export const ROUTE_ASSISTANCE_ASSIST = 'RouteAssistanceAssist';
export const ROUTE_ASSISTANCE_CANCEL = 'RouteAssistanceCancel';
export const ROUTE_ASSISTANCE_REQUEST = 'RouteAssistanceRequest';
export const ROUTE_ASSISTANCE_CANCEL_ASSIST = 'RouteAssistanceCancelAssist';

export const INSIGHT_HISTORY_CATEGORIES = mapKeys(
  [
    { id: FAULT_CODE, name: translate('insights.insightHistoryCategory.faultCode') },
    {
      id: HARD_ACCELERATION,
      name: translate('insights.insightHistoryCategory.hardAcceleration'),
    },
    { id: HARD_BRAKING, name: translate('insights.insightHistoryCategory.hardBraking') },
    { id: HARD_TURNING, name: translate('insights.insightHistoryCategory.hardTurning') },
    { id: LOGIN, name: translate('insights.insightHistoryCategory.login') },
    { id: SPEEDING, name: translate('insights.insightHistoryCategory.speeding') },
    { id: STATIONARY, name: translate('insights.insightHistoryCategory.stationary'), value: 'SpeedingInsight' },
    { id: SERVICE_CONFIRMATION, name: translate('insights.insightHistoryCategory.serviceConfirmation') },
    { id: BATTERY, name: translate('insights.insightHistoryCategory.battery') },
    { id: ROUTE_ASSISTANCE_ASSIST, name: translate('insights.insightHistoryCategory.routeAssistanceAssist') },
    { id: ROUTE_ASSISTANCE_CANCEL, name: translate('insights.insightHistoryCategory.routeAssistanceCancel') },
    { id: ROUTE_ASSISTANCE_REQUEST, name: translate('insights.insightHistoryCategory.routeAssistanceRequest') },
    {
      id: ROUTE_ASSISTANCE_CANCEL_ASSIST,
      name: translate('insights.insightHistoryCategory.routeAssistanceCancelAssist'),
    },
  ],
  'id',
);

export const INSIGHT_HISTORY_CATEGORY_OPTIONS = mapKeys(
  [
    { id: FAULT_CODE_INSIGHT, name: translate('insights.insightHistoryCategory.faultCode') },
    {
      id: HARD_ACCELERATION_INSIGHT,
      name: translate('insights.insightHistoryCategory.hardAcceleration'),
    },
    { id: HARD_BRAKING_INSIGHT, name: translate('insights.insightHistoryCategory.hardBraking') },
    { id: HARD_TURNING_INSIGHT, name: translate('insights.insightHistoryCategory.hardTurning') },
    { id: LOGIN, name: translate('insights.insightHistoryCategory.login') },
    { id: SPEEDING_INSIGHT, name: translate('insights.insightHistoryCategory.speeding') },
    { id: STATIONARY, name: translate('insights.insightHistoryCategory.stationary'), value: 'SpeedingInsight' },
    { id: SERVICE_CONFIRMATION, name: translate('insights.insightHistoryCategory.serviceConfirmation') },
    { id: BATTERY, name: translate('insights.insightHistoryCategory.battery') },
    { id: ROUTE_ASSISTANCE_ASSIST, name: translate('insights.insightHistoryCategory.routeAssistanceAssist') },
    { id: ROUTE_ASSISTANCE_CANCEL, name: translate('insights.insightHistoryCategory.routeAssistanceCancel') },
    { id: ROUTE_ASSISTANCE_REQUEST, name: translate('insights.insightHistoryCategory.routeAssistanceRequest') },
  ],
  'id',
);
