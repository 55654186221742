import { filter } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

import { ComplexMapControl, ComplexMapControlPosition, MapLegend } from 'src/routes/components/styled/RouteMap';
import {
  DEADHEAD_ID1,
  DEADHEAD_ID2,
  DEADHEAD_ID3,
  DOUBLE_SIDE_SERVICE,
  FROM_TIP_ID,
  SINGLE_SIDE_SERVICE,
  TO_TIP_ID,
  TRAVEL_PATH_SERVICE_TYPES,
  TRAVEL_PATH_SERVICE_TYPES_IDS,
} from 'src/routes/constants';
import { setIsTravelPathLegendOpen } from 'src/routes/ducks';

const LegendColorBox = ({
  color,
  label,
  width = '100%',
  dashed,
}: {
  color: string;
  label: string;
  width?: string;
  dashed?: boolean;
}) => (
  <Box flex={`0 0 ${width}`} padding="xxSmall no" display="flex" alignItems="center">
    <Box style={{ transform: 'rotate(90deg)' }} margin="no xxSmall no no">
      <Icon icon={dashed ? 'boldArrowDashed' : 'boldArrow'} color={color} width="20px" height="20px" />
    </Box>

    <Text size="small">{label}</Text>
  </Box>
);

const TravelPathLegend: FC<{ isEditModal?: boolean; yOffset?: number; position?: ComplexMapControlPosition }> = ({
  isEditModal,
  yOffset,
  position = 'bottom-right',
}) => {
  const dispatch = useDispatch();
  const { isTravelPathLegendOpen } = useSelector(state => state.routes.travelPath);
  const [isEditTravelPathLegendOpen, setIsEditTravelPathLegendOpen] = useState(false);

  const nonServiceTPs = filter(
    TRAVEL_PATH_SERVICE_TYPES,
    service =>
      ![SINGLE_SIDE_SERVICE, DOUBLE_SIDE_SERVICE, DEADHEAD_ID2, DEADHEAD_ID3, FROM_TIP_ID, TO_TIP_ID].includes(
        service.id,
      ),
  );

  const toggleLegend = () => {
    if (isEditModal) setIsEditTravelPathLegendOpen(!isEditTravelPathLegendOpen);
    else dispatch(setIsTravelPathLegendOpen(!isTravelPathLegendOpen));
  };

  useEffect(
    () => () => {
      !isEditModal && dispatch(setIsTravelPathLegendOpen(false));
    },
    [dispatch, isEditModal],
  );

  const isLegendOpen = isEditModal ? isEditTravelPathLegendOpen : isTravelPathLegendOpen;

  return (
    <ComplexMapControl position={position} yOffset={yOffset}>
      <MapLegend collapsed={isLegendOpen} onClick={toggleLegend}>
        <Text size="small">
          <b>{translate('routes.travelPath.travelPathLegend')}</b>
        </Text>
        <Icon icon="chevronDown" />
        {isLegendOpen && (
          <>
            <Box display="flex" flexWrap="wrap" margin="xxSmall" width={170}>
              <Box width="100%">
                <Text size="xSmall" weight="medium" color="grayDarker">
                  {translate('common.service')}
                </Text>
              </Box>

              <Box width="100%" display="flex" flexWrap="wrap" mt={3}>
                <LegendColorBox
                  color={TRAVEL_PATH_SERVICE_TYPES_IDS[SINGLE_SIDE_SERVICE].color}
                  label={TRAVEL_PATH_SERVICE_TYPES_IDS[SINGLE_SIDE_SERVICE].name}
                />
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" margin="xxSmall" width={200}>
              <Box width="100%">
                <Text size="xSmall" weight="medium" color="grayDarker">
                  {translate('routes.travelPath.travel')}
                </Text>
              </Box>

              <Box width="100%" display="flex" flexWrap="wrap" mt={3}>
                {nonServiceTPs.map(service => (
                  <LegendColorBox
                    dashed={service.id === DEADHEAD_ID1}
                    key={service.id}
                    color={service.color}
                    label={service.name}
                    width="100%"
                  />
                ))}
              </Box>
            </Box>
          </>
        )}
      </MapLegend>
    </ComplexMapControl>
  );
};

export default TravelPathLegend;
