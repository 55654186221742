import { ChangeEvent } from 'react';
import { currency, date } from 'src/utils/services/formatter';
import { isDecimalWithoutZero } from 'src/utils/services/validator';
import {
  INVOICE_PAYMENT_STATUSES,
  INVOICE_PAYMENT_STATUS_PROCESSING_ID,
} from 'src/finance/constants/invoicePaymentStatuses';
import { Input, TypedField, UnconnectedCheckbox } from 'src/core/components';
import { OpenBill } from 'src/finance/interfaces/ApplyChecks';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';

interface Props extends OpenBill {
  isChecked: boolean;
  checkBill: (billId: number, e: ChangeEvent<HTMLInputElement>) => void;
  onAmountToApplyChange: (billId: number, amountToApply: number) => void;
  index: number;
  tableCellWidths: { [key: string]: string };
}

const NewPaymentBillsTableRow = ({
  billStatusId,
  checkBill,
  customerId,
  customerLocationId,
  customerName,
  dueDate,
  index,
  invoiceDate,
  invoiceId,
  invoiceTotal,
  isChecked,
  locationName,
  onAmountToApplyChange,
  tableCellWidths,
  unappliedBillBalance,
}: Props) => {
  const paymentStatus = INVOICE_PAYMENT_STATUSES[billStatusId].name;
  const billIsProcessing = billStatusId === INVOICE_PAYMENT_STATUS_PROCESSING_ID;

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
      <TableCell
        width={tableCellWidths.selectAll}
        onClick={e => e.stopPropagation()}
        padding="defaultCellVertical xSmall"
      >
        <UnconnectedCheckbox
          id={`openBill-${invoiceId}-billId-cell`}
          block
          checked={isChecked}
          onChange={e => checkBill(invoiceId, e)}
          onClick={e => e.stopPropagation()}
          disabled={!!billIsProcessing}
        />
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-billId-cell`}
        justifyContent="center"
        vertical
        width={tableCellWidths.billId}
        padding="defaultCellVertical xSmall"
      >
        <TableActionButton
          margin="no no no xSmall"
          color="primary"
          onClick={() => window.open(`/finance/billing/bill-details/${invoiceId}`, '_blank')}
        >
          <Text>{invoiceId || '-'}</Text>
        </TableActionButton>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-billDate-cell`}
        width={tableCellWidths.billDate}
        padding="defaultCellVertical xSmall"
      >
        <Text>{invoiceDate ? date(invoiceDate) : '-'}</Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-dueDate-cell`}
        justifyContent="center"
        vertical
        width={tableCellWidths.dueDate}
        padding="defaultCellVertical xSmall"
      >
        <Text>{dueDate ? date(dueDate) : '-'}</Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-customerInfo-cell`}
        justifyContent="center"
        vertical
        width={tableCellWidths.customerInfo}
        padding="defaultCellVertical xSmall"
      >
        <Text block>
          {customerName || '-'} • {customerId || '-'}
        </Text>
        <Text block color="grayDarker">
          {locationName || '-'} • {customerLocationId || '-'}
        </Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-billAmount-cell`}
        width={tableCellWidths.billAmount}
        padding="defaultCellVertical xSmall"
      >
        <Text>{currency(invoiceTotal || 0)}</Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-billBalance-cell`}
        width={tableCellWidths.remainingBillBalance}
        padding="defaultCellVertical xSmall"
      >
        <Text>{currency(unappliedBillBalance || 0)}</Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-paymentStatus-cell`}
        width={tableCellWidths.paymentStatus}
        padding="defaultCellVertical xSmall"
      >
        <Text>{paymentStatus || '-'}</Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-amountToApply-cell`}
        width={tableCellWidths.amountToApply}
        padding="defaultCellVertical xSmall"
      >
        <TypedField
          name={`amountToApply[${index}]`}
          onChange={(e: any) => onAmountToApplyChange(invoiceId, e.target.value)}
          component={Input}
          validate={isChecked ? [isDecimalWithoutZero] : []}
          format={value => value ? Math.abs(parseFloat(value)) : 0}
          props={{ disabled: !isChecked, margin: '0px', type: "number" }}
        />
      </TableCell>
    </TableRow>
  );
};

export default NewPaymentBillsTableRow;
