import { camelCase, find, map, size } from 'lodash-es';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { InjectedFormProps, getFormValues, reduxForm, reset } from 'redux-form';

import { vendorGusIdSelector } from 'src/account/ducks';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { ActionButtonTooltip, Checkbox, TypedField } from 'src/core/components';
import { Button, Separator, TableActionButton, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { ENGINE_HOURS_DATA_ID, FAULT_DATA_ID, ODOMETER_DATA_ID } from 'src/dashboard/constants/yData';
import {
  loadDevices,
  loadRouteData,
  loadVehicleBreadcrumbs,
  loadVehicleInsightDetails,
  loadVehicleInspection,
  loadVehicleSafety,
  loadVehicleStatus,
  resetVehicleDetails,
  setVehicleInsightDetails,
} from 'src/dashboard/ducks';
import { setMapModalData } from 'src/dashboard/ducks/mapControls';
import { TripInspectionItem, VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import { RouteImagesModal } from 'src/routes/components/modals';
import { timeFormat, timeFormatWithoutSeconds } from 'src/utils/services/validator';
import { isPostTripFeatureEnabled, isPreTripFeatureEnabled } from 'src/vendors/ducks/features';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  DashboardFilterClose,
  DashboardFilterCloseIcon,
  DashboardFilterContainer,
  DashboardFilterDetailScreenSection,
  DashboardFilterPanel,
  DashboardFilterPanelContent,
  DashboardFilterPanelContentDetails,
  DashboardFilterSection,
  DashboardFilterTitleWrapper,
  VehicleInspectionRow,
} from '../styled/DashboardFilterMapbox';
import { MapboxFiltersFormValues } from './DashboardMapboxFiltersForm';
import { DashboardFilterCategoryMapbox, DashboardFilterDetailScreenLabelValue } from './dashboardFilterFormSections';

export const DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM = 'DashboardMapboxVehicleDetailsForm';

interface PropsWithoutReduxForm {
  close: () => void;
  isCollapsed?: boolean;
  isOpen: boolean;
  isSOSVehicle?: boolean;
  selectedVehicleId?: number;
}

export interface DashboardMapboxVehicleDetailsFormValues {
  displayBreadCrumbs: boolean;
  showAppStatus: boolean;
  vehicleSafety: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<DashboardMapboxVehicleDetailsFormValues, PropsWithoutReduxForm>;

enum FILTER_CATEGORIES {
  vehicleData = 'vehicleData',
  safetyData = 'safetyData',
  vehicleInspection = 'vehicleInspection',
  routeData = 'routeData',
  deviceData = 'deviceData',
}

const DashboardMapboxVehicleDetailsForm = ({
  close,
  handleSubmit,
  isOpen,
  isSOSVehicle,
  selectedVehicleId,
  isCollapsed,
}: Props) => {
  const dispatch = useDispatch();
  const [openedCategory, setOpenedCategory] = useState({
    vehicleData: true,
    safetyData: false,
    vehicleInspection: false,
    routeData: false,
    deviceData: false,
  });

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [issue, setIssue] = useState<any | null>(null);

  const mainFormValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;
  const thisFormValues = useSelector(
    getFormValues(DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM),
  ) as DashboardMapboxVehicleDetailsFormValues;

  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));
  const vehiclesList = useSelector(state => state.dashboard.vehiclesData.vehiclesList);
  const {
    devices,
    isLoadingDevices,
    isLoadingRouteData,
    isLoadingVehicleInspection,
    isLoadingVehicleSafety,
    isLoadingVehicleStatus,
    routeData,
    vehicleInsightDetails,
    vehicleInspection,
    vehicleSafety,
    vehicleStatus,
  } = useSelector(state => state.dashboard.vehiclesData);

  const isPreTripEnabled = useSelector(state => isPreTripFeatureEnabled(state));
  const isPostTripEnabled = useSelector(state => isPostTripFeatureEnabled(state));
  const vendorId = useSelector(currentVendorId);

  const vehicleBreadCrumbs = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbs);

  const { yData } = useSelector(state => state.vendors.yData);
  const isOdometerReadingDataVisible = yData.find(data => data.yDataType.id === ODOMETER_DATA_ID)?.isActive;
  const isEngineHoursDataVisible = yData.find(data => data.yDataType.id === ENGINE_HOURS_DATA_ID)?.isActive;
  const isFaultDataVisible = yData.find(data => data.yDataType.id === FAULT_DATA_ID)?.isActive;
  // const isFuelDataVisible = yData.find(data => data.yDataType.id === FUEL_DATA_ID)?.isActive;

  const isContainerVisible = isOpen && !isCollapsed;

  const vehicleHeaderInfo = useMemo(() => {
    if (!selectedVehicleId) {
      return null;
    }

    const vehicle = find(vehiclesList, { id: selectedVehicleId });

    if (!vehicle) {
      return null;
    }

    return vehicle;
  }, [selectedVehicleId, vehiclesList]) as VehiclePosition;

  useEffect(() => {
    if (!isOpen) {
      dispatch(reset(DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM));
      dispatch(resetVehicleDetails());
      setOpenedCategory({
        vehicleData: true,
        safetyData: false,
        vehicleInspection: false,
        routeData: false,
        deviceData: false,
      });
    }
  }, [dispatch, isOpen]);

  // on first render, call endpoint
  useEffect(() => {
    if (selectedVehicleId && mainFormValues?.date && isOpen) {
      dispatch(loadVehicleStatus(selectedVehicleId, mainFormValues.date));
      dispatch(loadVehicleBreadcrumbs(selectedVehicleId, mainFormValues.date));
    }
  }, [dispatch, mainFormValues?.date, selectedVehicleId, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  // refresh vehicleVehicleBreadcrumb once a minute
  useEffect(() => {
    if (selectedVehicleId && mainFormValues?.date && isOpen && getIsVendorNotChanged(vendorId)) {
      const interval = setInterval(() => {
        dispatch(loadVehicleBreadcrumbs(selectedVehicleId, mainFormValues.date));
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [dispatch, mainFormValues?.date, selectedVehicleId, isOpen, vendorId]);

  const toggleCategoryHandler = (category: FILTER_CATEGORIES) => {
    if (!openedCategory[category] && selectedVehicleId && mainFormValues?.date) {
      switch (category) {
        case FILTER_CATEGORIES.vehicleData:
          dispatch(loadVehicleStatus(selectedVehicleId, mainFormValues.date));
          break;
        case FILTER_CATEGORIES.safetyData:
          dispatch(loadVehicleSafety(selectedVehicleId, mainFormValues.date));
          break;
        case FILTER_CATEGORIES.vehicleInspection:
          dispatch(loadVehicleInspection(selectedVehicleId, mainFormValues.date));
          break;
        case FILTER_CATEGORIES.routeData:
          dispatch(loadRouteData(selectedVehicleId, mainFormValues.date));
          break;
        case FILTER_CATEGORIES.deviceData:
          dispatch(loadDevices(selectedVehicleId));
          break;
        default:
          break;
      }
    }
    setOpenedCategory({ ...openedCategory, [category]: !openedCategory[category] });
  };

  // the vehicle becomes inactive so data is not available
  useEffect(() => {
    if (!vehicleHeaderInfo && isOpen && !isSOSVehicle) close();
  }, [close, isOpen, isSOSVehicle, vehicleHeaderInfo]);

  const handleOpenImageModal = (issue: TripInspectionItem, routeName: string) => {
    setIssue({ ...issue, routeName });
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
    setIssue(null);
  };

  const onSafetyDataChange = (event: any, isChecked: boolean, insightTypeId: number) => {
    isChecked
      ? dispatch(loadVehicleInsightDetails(selectedVehicleId, insightTypeId, mainFormValues?.date))
      : dispatch(
          setVehicleInsightDetails(
            vehicleInsightDetails.filter(vehicleInsightDetail => vehicleInsightDetail.insightTypeId !== insightTypeId),
          ),
        );
  };

  const startTime = useMemo(() => {
    if (vehicleStatus.startTime) return vehicleStatus.startTime;
    else if (vehicleBreadCrumbs && size(vehicleBreadCrumbs.coords)) {
      const firstGroup = vehicleBreadCrumbs.coords[0];
      if (size(firstGroup)) {
        const firstCoord = firstGroup[0];
        return moment(firstCoord.ts).format(timeFormat);
      }
      return null;
    }
    return null;
  }, [vehicleStatus.startTime, vehicleBreadCrumbs]);

  const endTime = useMemo(() => {
    if (vehicleStatus.endTime) return vehicleStatus.endTime;
    else if (vehicleBreadCrumbs && size(vehicleBreadCrumbs.coords)) {
      const lastGroup = vehicleBreadCrumbs.coords[vehicleBreadCrumbs.coords.length - 1];
      if (size(lastGroup)) {
        const lastCoord = lastGroup[lastGroup.length - 1];
        return moment(lastCoord.ts).format(timeFormat);
      }
      return null;
    }
    return null;
  }, [vehicleStatus.endTime, vehicleBreadCrumbs]);

  const totalTime = useMemo(() => {
    if (vehicleStatus.totalTime) return vehicleStatus.totalTime;
    else if (vehicleBreadCrumbs && size(vehicleBreadCrumbs.coords)) {
      const firstGroup = vehicleBreadCrumbs.coords[0];
      const lastGroup = vehicleBreadCrumbs.coords[vehicleBreadCrumbs.coords.length - 1];
      if (size(firstGroup) && size(lastGroup)) {
        const firstCoord = firstGroup[0];
        const lastCoord = lastGroup[lastGroup.length - 1];
        return moment.utc(moment(lastCoord.ts).diff(moment(firstCoord.ts))).format('HH:mm:ss');
      }
      return null;
    }
    return null;
  }, [vehicleStatus.totalTime, vehicleBreadCrumbs]);

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <DashboardFilterContainer isOpen={isContainerVisible} isLarger={!isVendorWithGusId}>
          <DashboardFilterPanel>
            <DashboardFilterPanelContent>
              <DashboardFilterPanelContentDetails isCollapsed={isCollapsed}>
                <DashboardFilterClose isDetailsScreen onClick={close} id="dashboard-filter-close-button">
                  <DashboardFilterCloseIcon />
                </DashboardFilterClose>
                {vehicleHeaderInfo && (
                  <DashboardFilterSection>
                    <Text block size="xLarge" weight="medium" margin="negSmall no xSmall large">
                      {vehicleHeaderInfo.name}
                    </Text>

                    <TypedField
                      name="displayBreadCrumbs"
                      component={Checkbox}
                      props={{
                        size: 'small',
                        margin: 'no no no large',
                        label: translate('dashboard.filterKeys.displayBreadCrumbs'),
                      }}
                    />
                    {thisFormValues?.displayBreadCrumbs && (
                      <TypedField
                        name="showAppStatus"
                        component={Checkbox}
                        props={{
                          size: 'small',
                          margin: 'no no no small',
                          label: translate('dashboard.showAppStatus'),
                        }}
                      />
                    )}

                    <DashboardFilterTitleWrapper>
                      <Text margin="medium no no medium">
                        {translate(`vehicles.vehicleTypes.${camelCase(vehicleHeaderInfo.type)}`)}
                      </Text>
                      <Text margin="medium medium no no">
                        {translate(`common.${!!vehicleHeaderInfo.isActive ? 'active' : 'inactive'}`)}
                      </Text>
                    </DashboardFilterTitleWrapper>
                    <Separator margin="small no" size={2} color="grayDark" />
                  </DashboardFilterSection>
                )}
                <DashboardFilterSection>
                  <DashboardFilterCategoryMapbox
                    title={translate('dashboard.vehicleData')}
                    isOpen={openedCategory.vehicleData}
                    category={FILTER_CATEGORIES.vehicleData}
                    toggleCategory={toggleCategoryHandler}
                    id="dashboard-filter-vendor-vehicleData"
                  >
                    <DashboardFilterDetailScreenSection isLoading={isLoadingVehicleStatus}>
                      {/* Hidden util data is made available */}
                      {/* <DashboardFilterDetailScreenLabelValue
                        label={translate('dashboard.filterKeys.vin')}
                        value={vehicleStatus.vin}
                      /> */}

                      {isOdometerReadingDataVisible && (
                        <DashboardFilterDetailScreenLabelValue
                          label={translate('dashboard.filterKeys.odometerReading')}
                          value={vehicleStatus.odometer}
                        />
                      )}

                      {isEngineHoursDataVisible && (
                        <DashboardFilterDetailScreenLabelValue
                          label={translate('dashboard.filterKeys.engineHours')}
                          value={vehicleStatus.engineHours}
                        />
                      )}
                      {/* Hidden util data is made available */}
                      {/* {isFuelDataVisible && (
                        <DashboardFilterDetailScreenLabelValue
                          label={translate('dashboard.filterKeys.fuelLevel')}
                          value={vehicleStatus.fuelLevel}
                        />
                      )} */}

                      <DashboardFilterDetailScreenLabelValue
                        label={translate('dashboard.filterKeys.startTime')}
                        value={startTime}
                      />
                      <DashboardFilterDetailScreenLabelValue
                        label={translate('dashboard.filterKeys.endTime')}
                        value={endTime}
                      />
                      <DashboardFilterDetailScreenLabelValue
                        label={translate('dashboard.filterKeys.totalTime')}
                        value={totalTime}
                      />
                      {/* Hidden util data is made available */}
                      {/* <DashboardFilterDetailScreenLabelValue
                        label={translate('dashboard.filterKeys.idleTime')}
                        value={vehicleStatus.idleTime}
                      /> */}
                      {/* <DashboardFilterDetailScreenLabelValue
                        label={translate('dashboard.filterKeys.milesDriven')}
                        value={vehicleStatus.milesDriven ? `${round(vehicleStatus.milesDriven, 2)} miles` : null}
                        fullWidth
                      /> */}

                      {isFaultDataVisible && (
                        <DashboardFilterDetailScreenLabelValue
                          label={translate('dashboard.filterKeys.faultCodes')}
                          value={`${vehicleStatus.faultCodesCount || 0}`}
                          rightContent={
                            !!vehicleStatus.faultCodesCount && (
                              <Box color="#00a599" textDecoration="underline" margin="xxSmall no no" fontSize={12}>
                                <Link
                                  to={`/insights/fault-code-details?date=${mainFormValues.date}&vehicleId=${selectedVehicleId}`}
                                >
                                  {translate('vehicles.vehicleInspections.viewDetails')}
                                </Link>
                              </Box>
                            )
                          }
                        />
                      )}
                    </DashboardFilterDetailScreenSection>
                  </DashboardFilterCategoryMapbox>
                  <DashboardFilterCategoryMapbox
                    title={translate('dashboard.safetyData')}
                    isOpen={openedCategory.safetyData}
                    category={FILTER_CATEGORIES.safetyData}
                    toggleCategory={toggleCategoryHandler}
                    id="dashboard-filter-vendor-safetyData"
                  >
                    <DashboardFilterDetailScreenSection isLoading={isLoadingVehicleSafety}>
                      {!size(vehicleSafety?.vehicleInsights) && (
                        <Text color="grayDarker" margin="small" block>
                          <em>{translate('routes.planner.noData')}</em>
                        </Text>
                      )}
                      {map(vehicleSafety.vehicleInsights, (insight, key) => (
                        <DashboardFilterDetailScreenLabelValue
                          key={key}
                          label={translate(`dashboard.${camelCase(insight.insightType.technicalName)}`)}
                          value={insight.count}
                          rightContent={
                            <TypedField
                              name={`vehicleSafety.${insight.insightType.technicalName}`}
                              component={Checkbox}
                              props={{
                                size: 'small',
                                margin: 'no',
                              }}
                              onChange={(event, value) => onSafetyDataChange(event, value, insight.insightType.id)}
                            />
                          }
                        />
                      ))}
                    </DashboardFilterDetailScreenSection>
                  </DashboardFilterCategoryMapbox>

                  {(isPreTripEnabled || isPostTripEnabled) && (
                    <DashboardFilterCategoryMapbox
                      title={translate('dashboard.vehicleInspection')}
                      isOpen={openedCategory.vehicleInspection}
                      category={FILTER_CATEGORIES.vehicleInspection}
                      toggleCategory={toggleCategoryHandler}
                      id="dashboard-filter-vendor-vehicleInspection"
                    >
                      <DashboardFilterDetailScreenSection isLoading={isLoadingVehicleInspection}>
                        {!size(vehicleInspection) && (
                          <Text color="grayDarker" margin="small" block>
                            <em>{translate('routes.planner.noData')}</em>
                          </Text>
                        )}
                        {map(vehicleInspection, inspection => (
                          <DashboardFilterDetailScreenLabelValue
                            key={inspection.tripInspectionType.technicalName}
                            label={translate(
                              `dashboard.filterKeys.${camelCase(inspection.tripInspectionType.technicalName)}`,
                            )}
                            value={`${inspection.tripInspections.length} ${translate(
                              'dashboard.filterKeys.itemsFlagged',
                            )}`}
                            fullWidth
                            rightContent={
                              <>
                                {map(inspection.tripInspections, inner => (
                                  <VehicleInspectionRow key={inner.itemId}>
                                    <Text size="small" color="grayDarker" block>
                                      {translate(
                                        `vendors.tripInspection.subCategories.${camelCase(inner.itemTechnicalName)}`,
                                      )}
                                    </Text>
                                    {inner.imageUrl && (
                                      <TableActionButton
                                        color="grayDarker"
                                        margin="no small no no"
                                        onClick={() => handleOpenImageModal(inner, inspection.routeName)}
                                      >
                                        <ActionButtonTooltip iconSize="small" icon="image" tooltip="viewImage" />
                                      </TableActionButton>
                                    )}
                                  </VehicleInspectionRow>
                                ))}
                              </>
                            }
                          />
                        ))}
                      </DashboardFilterDetailScreenSection>
                    </DashboardFilterCategoryMapbox>
                  )}

                  <DashboardFilterCategoryMapbox
                    title={translate('dashboard.routeData')}
                    isOpen={openedCategory.routeData}
                    category={FILTER_CATEGORIES.routeData}
                    toggleCategory={toggleCategoryHandler}
                    id="dashboard-filter-vendor-routeData"
                  >
                    <DashboardFilterDetailScreenSection isLoading={isLoadingRouteData}>
                      {!size(routeData?.vehicleRoutes) ? (
                        <Text color="grayDarker" margin="small" block>
                          <em>{translate('routes.planner.noData')}</em>
                        </Text>
                      ) : (
                        <>
                          {map(routeData.vehicleRoutes, (route, index) => (
                            <DashboardFilterDetailScreenLabelValue
                              key={index}
                              label={route.routeName}
                              value={`${moment(route.startTime).format(timeFormatWithoutSeconds)} -  ${
                                route.endTime ? moment(route.endTime).format(timeFormatWithoutSeconds) : 'N/A'
                              }`}
                            />
                          ))}
                          <Box display="flex" justifyContent="flex-end">
                            <Button
                              margin="xSmall no xSmall no"
                              size="xSmall"
                              color="primary"
                              onClick={() =>
                                dispatch(
                                  setMapModalData({
                                    isModalMapOpen: true,
                                    vehicleId: vehicleHeaderInfo.id,
                                    date: mainFormValues.date.toString(),
                                    isSourceRoute: false,
                                  }),
                                )
                              }
                            >
                              {translate('tooltips.view')}
                            </Button>
                          </Box>
                        </>
                      )}
                    </DashboardFilterDetailScreenSection>
                  </DashboardFilterCategoryMapbox>
                  <DashboardFilterCategoryMapbox
                    title={translate('dashboard.devices')}
                    isOpen={openedCategory.deviceData}
                    category={FILTER_CATEGORIES.deviceData}
                    toggleCategory={toggleCategoryHandler}
                    id="dashboard-filter-vendor-deviceData"
                  >
                    <DashboardFilterDetailScreenSection isLoading={isLoadingDevices}>
                      {!size(devices?.devices) && (
                        <Text color="grayDarker" margin="small" block>
                          <em>{translate('routes.planner.noData')}</em>
                        </Text>
                      )}
                      {map(devices.devices, device => (
                        <DashboardFilterDetailScreenLabelValue
                          key={device.deviceId}
                          label={device.deviceName}
                          value={device.deviceId}
                        />
                      ))}
                    </DashboardFilterDetailScreenSection>
                  </DashboardFilterCategoryMapbox>
                </DashboardFilterSection>
              </DashboardFilterPanelContentDetails>
            </DashboardFilterPanelContent>
          </DashboardFilterPanel>
        </DashboardFilterContainer>

        {isImageModalOpen && issue && (
          <RouteImagesModal
            images={[
              {
                imageUrl: issue.imageUrl,
                rawImageUrl: issue?.rawImageUrl || issue.imageUrl,
                imageBlobId: '',
                routeName: issue.routeName,
                timeStamp: '',
                issueName: translate(`vendors.tripInspection.subCategories.${camelCase(issue.itemTechnicalName)}`),
              },
            ]}
            closeModal={handleCloseImageModal}
          />
        )}
      </form>
    </>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: DASHBOARD_MAPBOX_VEHICLE_DETAILS_FORM,
  enableReinitialize: true,
  onSubmit: () => {},
})(DashboardMapboxVehicleDetailsForm);
