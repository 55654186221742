import { filter, map, size } from 'lodash-es';
import React from 'react';
import { getFormValues, reduxForm } from 'redux-form';

import { Dropdown, TypedField } from 'src/core/components';

import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  PanelSection,
  PanelSectionGroup,
} from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  PAYMENT_STATUS_PARTIALLY_APPLIED_ID,
  PAYMENT_STATUS_UNAPPLIED_ID,
} from 'src/finance/constants/paymentStatuses';
import { isRequired } from 'src/utils/services/validator';

const OPEN_BILL_ASSIGN_FORM = 'openBillAsssignForm';

interface Props {
  selectedCustomerId: number;
  onSubmit(openPaymentId: number): void;
}

const OpenBillAsssignForm: React.FC<Props> = ({ onSubmit, selectedCustomerId }) => {
  const { payments: paymentsData } = useSelector(state => state.finance.payments);
  const payments = paymentsData?.payments;

  const openPayments = filter(
    payments,
    p =>
      (p.paymentStatusId === PAYMENT_STATUS_UNAPPLIED_ID ||
        p.paymentStatusId === PAYMENT_STATUS_PARTIALLY_APPLIED_ID) &&
      p.customerId === selectedCustomerId,
  );

  const openPaymentOptions = map(openPayments, p => ({
    label: `${p.id}, ${p.paymentNumber}, ${p.customerName}`,
    value: p.id,
  }));

  const currentFormValues = useSelector(getFormValues(OPEN_BILL_ASSIGN_FORM));
  return (
    <PanelSectionGroup>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (size(currentFormValues)) {
            const { openPaymentId } = currentFormValues as any;
            onSubmit(openPaymentId);
          }
        }}
        noValidate
      >
        <PanelSection padding="small xSmall no">
          <Grid>
            <GridColumn size="12/12">
              {!!size(openPaymentOptions) ? (
                <TypedField
                  component={Dropdown}
                  name="openPaymentId"
                  props={{
                    options: openPaymentOptions as any,
                    label: translate('finance.applyChecks.selectOpenPayments'),
                    isClearable: true,
                    margin: 'no',
                  }}
                  validate={[isRequired]}
                />
              ) : (
                <Message align="center" padding="xSmall">
                  {translate('finance.noCustomerPayments')}
                </Message>
              )}
            </GridColumn>
          </Grid>
        </PanelSection>

        <ButtonSet margin="large auto no" align="center">
          <Button type="submit" color="primary" disabled={!size(openPaymentOptions)}>
            {translate('finance.applyChecks.assign')}
          </Button>
        </ButtonSet>
      </form>
    </PanelSectionGroup>
  );
};

export default reduxForm<any, Props>({
  enableReinitialize: true,
  form: OPEN_BILL_ASSIGN_FORM,
})(OpenBillAsssignForm);
