import { Validator, WrappedFieldProps } from 'redux-form';

import { BulkySchedulerLimitByTimeRangeSettings } from 'src/vendors/interfaces/BulkyItemScheduler';
import { FormError } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

interface Props extends WrappedFieldProps {
  showErrorBeforeSubmit?: boolean;
}

const BulkyItemSchedulerServiceLimitsByTimeRangeValidationField: React.FC<Props> = ({
  showErrorBeforeSubmit = false,
  meta: { submitFailed, error },
}) => {
  if ((submitFailed || showErrorBeforeSubmit) && error) {
    return <FormError>{error}</FormError>;
  }

  return null;
};

export default BulkyItemSchedulerServiceLimitsByTimeRangeValidationField;

export const bulkyItemSchedulerServiceLimitsByTimeRangeValidate: Validator = (
  _,
  { bulkySchedulerLimitByTimeRangeSettings },
) => {
  let isValidationMessageVisible = false;
  let validationMessage = '';

  bulkySchedulerLimitByTimeRangeSettings?.forEach(
    (bulkySchedulerLimitByTimeRange1: BulkySchedulerLimitByTimeRangeSettings, index1: number) => {
      bulkySchedulerLimitByTimeRangeSettings?.forEach(
        (bulkySchedulerLimitByTimeRange2: BulkySchedulerLimitByTimeRangeSettings, index2: number) => {
          const hasCommonTimeRange =
            bulkySchedulerLimitByTimeRange1?.period?.id === bulkySchedulerLimitByTimeRange2?.period?.id &&
            index1 !== index2;

          const hasCommonGeoFences = bulkySchedulerLimitByTimeRange1?.geoFences?.filter((geoFence1: number | string) =>
            bulkySchedulerLimitByTimeRange2?.geoFences?.some(
              (geoFence2: number | string) => Number(geoFence1) === Number(geoFence2) && index1 !== index2,
            ),
          );

          const hasNoGeoFencesSelected =
            !bulkySchedulerLimitByTimeRange1?.geoFences?.length &&
            !bulkySchedulerLimitByTimeRange2?.geoFences?.length &&
            index1 !== index2;

          if (hasCommonTimeRange && hasNoGeoFencesSelected) validationMessage = 'sameTimeRangeSelectedLimitByTimeRange';

          if (hasCommonGeoFences?.length) validationMessage = 'sameGeofenceSelectedLimitByTimeRange';

          if (hasCommonGeoFences?.length || (hasCommonTimeRange && hasNoGeoFencesSelected))
            isValidationMessageVisible = true;
        },
      );
    },
  );

  if (!isValidationMessageVisible) {
    return;
  }

  return translate(`vendors.bulkyItemScheduler.${validationMessage}`);
};
