import { filter, map } from 'lodash-es';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import moment from 'moment';

import { Box } from 'src/core/components/styled/Box';
import { Button, Grid, GridColumn, ModalFixedFooter, PanelSection, Popover } from 'src/core/components/styled';
import { Checkbox, DatePicker, Dropdown, Input, PopoverWrapper, TypedField } from 'src/core/components';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import {
  ConfirmationTypeDropdown,
  GroupsMultiSelect,
  ServiceZoneDropdown,
  SupervisorDropdown,
  VehicleTypeForVendorDropdown,
} from '..';
import { customerLocationsSelector } from 'src/customers/ducks';
import {
  DELIVERY_UTILITY,
  RESIDENTIAL,
  ROLL_OFF,
  ROUTE,
  SNOW_PLOW,
  STREET_SWEEPER,
  WASTE_AUDIT,
} from 'src/fleet/constants';
import { Facility } from 'src/common/interfaces/Facility';
import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { getExcludedFiltersIds } from 'src/common/utils/filters';
import { InfoIcon } from '../styled';
import { isDateValidValidator, isRequired, isValidEndTime, maxLength50 } from 'src/utils/services/validator';
import { PermissionGuard } from 'src/account/components';
import { RouteDetailsFormData } from 'src/routes/interfaces/RouteDetails';
import { ROUTES_TRACKER_VIEW_EDIT } from 'src/account/constants';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { VehicleDropdown } from 'src/fleet/components';
import { WasteTypeDropdown } from 'src/common/components';
import TimePickerField from 'src/core/components/TimePickerField';
import translate from 'src/core/services/translate';

type PropsWithoutReduxForm = {
  closeModal: () => void;
  handleDateChange: (date: any | Date | string) => void;
};

type EditRouteDetailsFormProps = PropsWithoutReduxForm & InjectedFormProps<RouteDetailsFormData, PropsWithoutReduxForm>;

export const EDIT_ROUTE_DETAILS_FORM_NAME = 'EDIT_ROUTE_DETAILS_FORM';

export default reduxForm<RouteDetailsFormData, PropsWithoutReduxForm>({
  form: EDIT_ROUTE_DETAILS_FORM_NAME,
})(function EditRouteDetailsForm({
  change,
  closeModal,
  handleSubmit,
  handleDateChange,
  initialValues,
}: EditRouteDetailsFormProps) {
  const truckYardFacilities = useSelector(state => state.fleet.facilities.operationalFacilities);
  const disposalFacilities = useSelector(state => state.fleet.facilities.disposalFacilities);

  let filteredTruckYardFacilities = [] as Facility[];
  if (!!truckYardFacilities)
    filteredTruckYardFacilities = truckYardFacilities?.filter(
      (facility: Facility) =>
        facility.isActive || (!facility.isActive && facility.locationId === initialValues.startingLocationId),
    );

  let filteredCustomerLocations = [] as Facility[];
  if (!!truckYardFacilities && !!disposalFacilities)
    filteredCustomerLocations = [...truckYardFacilities, ...disposalFacilities].filter(
      (facility: Facility) =>
        facility.isActive || (!facility.isActive && facility.locationId === initialValues.endingLocationId),
    );

  const startingLocationOptions = customerLocationsSelector(filteredTruckYardFacilities);
  const endingLocationOptions = customerLocationsSelector(filteredCustomerLocations);

  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const vehicleTypeId = useSelector(state => formValueSelector(EDIT_ROUTE_DETAILS_FORM_NAME)(state, 'vehicleTypeId'));
  const startTime = useSelector(state => formValueSelector(EDIT_ROUTE_DETAILS_FORM_NAME)(state, 'startTime'));
  const endTime = useSelector(state => formValueSelector(EDIT_ROUTE_DETAILS_FORM_NAME)(state, 'endTime'));
  const supervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const serviceZones = useSelector(state => state.routes.serviceZones.serviceZones) as any;
  const supervisors = useSelector(state => state.routes.supervisors.supervisors) as Supervisor[];
  const filterPreferences = useSelector(state => state.common.filters.filters);
  const groups = useSelector(state => state.routes.groups.groups);

  // groupIds to exclude those inactive groups  except if initial value is inactive
  const groupIdsToExclude = map(
    filter(groups, group => !initialValues?.groups?.includes(group.id) && !group.isActive),
    'id',
  );

  const isRouteInThePast = moment(routeSummary?.date).isBefore(moment().startOf('day'));

  const selectedServiceZone = routeSummary?.vendorServiceZoneId;
  const selectedSupervisor = routeSummary?.supervisorId;
  const vehicleTypeName = routeSummary?.vehicleTypeName;
  const routeStatus = routeSummary?.routeStatusTypeId;
  const isSupervisorDropdownVisible = vehicleTypeName !== STREET_SWEEPER && vehicleTypeName !== WASTE_AUDIT;
  const isRouteConfirmationTypeDropdownVisible = vehicleTypeName === RESIDENTIAL;
  const isWasteTypeDropdownVisible = vehicleTypeName !== ROLL_OFF && vehicleTypeName !== DELIVERY_UTILITY;

  const isRouteInProgress = routeStatus === IN_PROGRESS;
  const isRouteScheduled = routeStatus === SCHEDULED;
  const isRouteCompleted = routeStatus === COMPLETED;
  const isRouteInProgressCompleted = isRouteCompleted || isRouteInProgress;

  const excludedServiceZones = getExcludedFiltersIds(serviceZones, filterPreferences, FILTER_SERVICE_ZONE_ID).filter(
    (el: number) => el !== selectedServiceZone,
  );
  const excludedSupervisors = getExcludedFiltersIds(supervisors, filterPreferences, FILTER_SUPERVISOR_ID).filter(
    (el: number) => el !== selectedSupervisor,
  );

  let disabledFields: { [key: string]: boolean } = {};
  if (routeSummary?.totalStopsCount) {
    disabledFields = {
      vehicleTypeId: true,
      wasteMaterialTypeId: true,
    };
  }
  if (routeStatus && !isRouteScheduled) {
    disabledFields = {
      ...disabledFields,
      routeConfirmationTypeId: isRouteCompleted,
      routeDate: true,
      startingLocationId: isRouteCompleted,
      vehicleId: isRouteCompleted,
      vehicleTypeId: isRouteCompleted,
      wasteMaterialTypeId: isRouteCompleted,
    };
  }

  const onStartingLocationChange = (_: any, value: number) => {
    change('endingLocationId', value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="small" vertical>
        <Grid padding="medium no no">
          <GridColumn size="12/12">
            <TypedField
              name="routeName"
              component={Input}
              props={{ label: translate('routes.routeName') }}
              validate={[isRequired, maxLength50]}
            />
          </GridColumn>
        </Grid>
        <Grid>
          <GridColumn size="6/12">
            <TypedField
              name="vehicleId"
              component={VehicleDropdown}
              props={{
                vehicleTypeId,
                withLabel: true,
                dropdownProps: { disabled: disabledFields.vehicleId || isRouteInThePast, isClearable: true },
                shouldGroupByAvailability: true,
              }}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <TypedField
              name="date"
              component={DatePicker}
              props={{
                label: translate('common.date'),
                disabled: disabledFields.routeDate || (isRouteInThePast && !isRouteScheduled),
                disabledDays: [{ before: TODAY }],
              }}
              validate={[isRequired, isDateValidValidator]}
              onChange={handleDateChange}
            />
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn size="6/12">
            <TypedField
              name="startingLocationId"
              component={Dropdown}
              props={{
                options: startingLocationOptions,
                label: translate('routes.startingLocation'),
                disabled: disabledFields.startingLocationId || isRouteInThePast,
                isClearable: true,
              }}
              onChange={onStartingLocationChange}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <TypedField
              name="endingLocationId"
              component={Dropdown}
              props={{
                options: endingLocationOptions,
                label: translate('routes.endingLocation'),
                disabled: disabledFields.startingLocationId || isRouteInThePast,
                isClearable: true,
              }}
            />
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn size="6/12" margin="no no sMedium no">
            <TimePickerField
              defaultValue={routeSummary?.startTime}
              labelName={translate('routes.scheduledStartTime')}
              name="startTime"
              validate={!!endTime ? [isRequired] : undefined}
              formName={EDIT_ROUTE_DETAILS_FORM_NAME}
              minuteStep={15}
            />
          </GridColumn>

          <GridColumn size="6/12">
            <TimePickerField
              defaultValue={routeSummary?.endTime}
              labelName={translate('routes.scheduledEndTime')}
              name="endTime"
              validate={startTime ? [isRequired, isValidEndTime] : [isValidEndTime]}
              formName={EDIT_ROUTE_DETAILS_FORM_NAME}
              minuteStep={15}
            />
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn size="12/12">
            <TypedField
              name="description"
              component={Input}
              props={{ label: translate('routes.description'), autoComplete: 'off' }}
              validate={[maxLength50]}
            />
          </GridColumn>
        </Grid>

        <Grid multiLine>
          <GridColumn size="6/12">
            <TypedField
              name="vehicleTypeId"
              component={VehicleTypeForVendorDropdown}
              props={{
                withLabel: true,
                vehicleRoleTypeId: ROUTE,
                excludeVehicleTypes: [SNOW_PLOW],
                dropdownProps: {
                  disabled: disabledFields.vehicleTypeId || isRouteInThePast || isRouteInProgressCompleted,
                },
                vehicleTypesForVendor: [],
              }}
              validate={isRequired}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <TypedField
              name="vendorServiceZoneId"
              component={ServiceZoneDropdown}
              props={{ label: translate('routes.serviceZone'), excludeServiceZonesIds: excludedServiceZones }}
            />
          </GridColumn>

          <GridColumn size="6/12">
            <Box display="flex" alignItems="center">
              <TypedField
                name="groups"
                component={GroupsMultiSelect}
                props={{
                  withLabel: true,
                  excludeGroupsIds: groupIdsToExclude,
                  cantSelectInactive: true,
                  multiSelectProps: {
                    defaultToAll: false,
                    inputWidth: '190px',
                    maxWidth: '200px',
                    minWidth: '200px',
                  },
                }}
              />
              <PopoverWrapper
                margin="no no no small"
                triggerButton={<InfoIcon />}
                popoverContent={<Popover>{translate('routes.groups.groupChangeMessage')}</Popover>}
                size="large"
              />
            </Box>
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn size="6/12">
            {supervisorExperienceEnabled && isSupervisorDropdownVisible && (
              <Box display="flex" alignItems="center">
                <TypedField
                  name="supervisorId"
                  component={SupervisorDropdown}
                  props={{
                    withLabel: true,
                    dropdownProps: {
                      width: '190px',
                    },
                    excludeSupervisorsIds: excludedSupervisors,
                    shouldGroupByAvailability: true,
                  }}
                />
                <PopoverWrapper
                  margin="no no no small"
                  triggerButton={<InfoIcon />}
                  popoverContent={<Popover>{translate('routes.supervisorsMessage')}</Popover>}
                  size="large"
                />
              </Box>
            )}
          </GridColumn>
          <GridColumn size="6/12">
            {isWasteTypeDropdownVisible && (
              <TypedField
                name="wasteMaterialTypeId"
                component={WasteTypeDropdown}
                props={{
                  withLabel: true,
                  dropdownProps: {
                    disabled: isRouteInThePast,
                  },
                }}
                validate={isRouteInThePast ? undefined : isRequired}
              />
            )}
          </GridColumn>
        </Grid>

        <Grid>
          <GridColumn size="6/12">
            {isRouteConfirmationTypeDropdownVisible && (
              <TypedField
                name="routeConfirmationTypeId"
                component={ConfirmationTypeDropdown}
                props={{
                  label: translate('routes.confirmationType'),
                  dropdownProps: { disabled: disabledFields.routeConfirmationTypeId || isRouteInThePast },
                }}
                validate={isRequired}
              />
            )}
          </GridColumn>
        </Grid>

        <PermissionGuard permission={ROUTES_TRACKER_VIEW_EDIT}>
          <Grid margin="no no small no">
            <GridColumn size="12/12">
              <PopoverWrapper
                triggerButton={
                  <TypedField
                    name="isTestRoute"
                    component={Checkbox}
                    props={{ label: translate('routes.testRoute') }}
                  />
                }
                popoverContent={<Popover>{translate('routes.testRoutePopover')}</Popover>}
              />
            </GridColumn>
          </Grid>
        </PermissionGuard>
      </PanelSection>

      <ModalFixedFooter isShadowed>
        <Button type="button" color="secondary" onClick={closeModal} margin="no xSmall">
          {translate('common.cancel')}
        </Button>
        <Button type="submit" color="primary" margin="no xSmall">
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
});
