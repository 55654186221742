import { useEffect } from 'react';

export const useInteractiveLayers = (
  interactiveLayerIds: string[],
  key: string,
  onAddInteractiveLayers: (interactiveLayerIds: string[], key: string) => void,
) => {
  useEffect(() => {
    onAddInteractiveLayers(interactiveLayerIds, key);
    return () => onAddInteractiveLayers([], key);
  }, [interactiveLayerIds, key, onAddInteractiveLayers]);
};
