import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
//import { currentVendorGusIdSelector } from 'src/vendors/services/currentVendorSelector';
import defaultDivisionFormInitialValuesSelector from '../../services/defaultDivisionFormInitialValuesSelector';
// import DivisionForm from '../forms/DivisionForm';
import { Districts } from 'src/vendors/interfaces/DivisionAccess';
import { EquipmentsForm, MaterialsForm } from '../forms';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  //HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { loadEquipments, loadMaterials } from '../../ducks';
import { PageContent, PageDetails, PageHeader, PageTitle, PageTitleContainer } from '../../../common/components/styled';
import {
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
  Grid,
  GridColumn,
  Message,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface Props {
  isLoadingEquipments: boolean;
  isLoadingMaterials: boolean;
  divisions: Districts[];
  formInitialValues: any;
}

const EquipmentsPage: React.FC<Props> = ({ divisions, formInitialValues, isLoadingEquipments, isLoadingMaterials }) => {
  //const dispatch = useDispatch();

  // const haulerId = useSelector((state: AppState) =>
  //   currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  // );

  // const onDivisionChange = (divisionId: string) => {
  //   Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, { expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION });
  //   window.history.pushState(null, '', `?divisionId=${divisionId}`);
  //   loadEquipments(haulerId, divisionId)(dispatch);
  //   loadMaterials(haulerId, divisionId)(dispatch);
  // };
  const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues.divisionId;
  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{`${translate('haulerProfile.equipments.title')} / ${translate(
              'haulerProfile.materials',
            )}`}</PageTitle>
            {/* Hide DivisionForm for current Sprint-119 */}
            {/* <DivisionForm initialValues={formInitialValues} divisions={divisions} onDivisionChange={onDivisionChange} /> */}
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>

      <Panel padding="small" margin="no">
        <PanelSectionGroup isLoading={isLoadingEquipments || isLoadingMaterials}>
          {divisionId ? (
            <Fragment>
              <PanelSection vertical>
                <Grid>
                  <GridColumn size="12/12">
                    <PanelSectionTitle margin="no">{translate('haulerProfile.equipments.title')}</PanelSectionTitle>
                  </GridColumn>
                </Grid>
                <Grid>
                  <GridColumn size="12/12">
                    <EquipmentsForm />
                  </GridColumn>
                </Grid>
              </PanelSection>

              <PanelSection vertical>
                <Grid>
                  <GridColumn size="12/12">
                    <PanelSectionTitle margin="small no">{translate('haulerProfile.materials')}</PanelSectionTitle>
                  </GridColumn>
                </Grid>
                <Grid>
                  <GridColumn size="12/12">
                    <MaterialsForm />
                  </GridColumn>
                </Grid>
              </PanelSection>
            </Fragment>
          ) : (
            <Message padding="sMedium">{translate('common.alertMessages.invalidDivisionId')}</Message>
          )}
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoadingEquipments: state.haulerProfile.equipments.isLoading,
  isLoadingMaterials: state.haulerProfile.materials.isLoading,
  divisions: state.haulerProfile.divisions.divisions,
  formInitialValues: defaultDivisionFormInitialValuesSelector(state.haulerProfile.divisions),
});

const mapDispatchToProps = {
  loadEquipments,
  loadMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentsPage);
