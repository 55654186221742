import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { debounce, sortBy } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import { Resizable } from 're-resizable';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { checkIfViewOnly } from 'src/account/utils/permissions';
import { MapGL } from 'src/common/components/map/MapGL';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import { getMapBounds } from 'src/common/components/map/util';
import FloatingCollapsible from 'src/core/components/FloatingCollapsible';
import MapDragHandle from 'src/core/components/MapDragHandle';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon, Label, Panel, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DrawingInstructions } from 'src/routes/components/pages/routeTemplateBuilder/routeTemplateBuilderMap/DrawingInstructions';
import RouteMapGeoFencesGL from 'src/routes/components/pages/routes/routePageSections/routeMap/geoFences/RouteMapGeoFencesGL';
import { FullscreenMap, LoadingContainer } from 'src/routes/components/styled';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { PICKUP_STATUS_LABEL_COLORS } from 'src/routes/constants';
import {
  DISPATCH_BOARD_FORM_NAME,
  DISPATCH_BOARD_SOURCE,
  UNASSIGNED_ROUTE_ID,
} from 'src/routes/constants/dispatchBoard';
import { loadGeoFences, resetGeoFences } from 'src/routes/ducks';
import {
  clearSelectedJobs,
  closeMap,
  loadDispatchBoardJobs,
  searchJobs,
  selectJobs,
} from 'src/routes/ducks/dispatchBoard';
import { DispatchBoardRouteJobRoute } from 'src/routes/interfaces/DispatchBoardRouteJob';
import { loadCityAlerts, resetCityAlerts } from 'src/vendors/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DispatchBoardMapGLPopup from './DispatchBoardMapGLPopup';
import DispatchBoardMapGLSource from './DispatchBoardMapGLSource';
import AssignJobsContainer from './assignJobs/AssignJobsContainer';
import CityAlertPopupResolver from './cityAlerts/CityAlertPopupResolver';
import CityAlertsGL, { CITY_ALERTS_SOURCE_ID } from './cityAlerts/CityAlertsGL';
import OnMapFiltersForm, { OnMapFiltersFormValues } from './map/OnMapFiltersForm';
import { getDispatchBoardJobsGeoJSON } from './utils';
import MapRoutesFilterList from './map/MapRoutesFilterList';

const mapDefaultHeight = window.innerHeight - 420;
const minMapSize = 400;
const formSelector = formValueSelector(DISPATCH_BOARD_FORM_NAME);

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

interface Props {
  searchTerm?: string;
  date?: string | Date;
}

const DispatchBoardMapGL: React.FC<Props> = ({ searchTerm, date }) => {
  const editorRef = useRef<Editor>(null);
  const [isMapFiltersOpen, setIsMapFiltersOpen] = useState(false);
  const [isMapRoutesFiltersOpen, setIsMapRoutesFiltersOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [selectedFeature, setSelectedFeature] = useState<
    | {
        id: number;
        type: 'job' | 'cityAlert';
      }
    | undefined
  >();
  const [editPolygon, setEditPolygon] = useState(true);
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [mapDrawingEnabled, setMapDrawingEnabled] = useState(false);
  const [polygon, setPolygon] = useState<any>();
  const [isSatellite, setIsSatellite] = useState(false);
  const [selectedRouteIdsFilter, setSelectedRouteIdsFilter] = useState<number[]>([]);

  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const selectedRoutes = useSelector(state => state.routes.dispatchBoard.map.selectedRoutes);
  const vehicleTypeId = useSelector(state => formSelector(state, 'vehicleTypeId'));
  const jobs = useSelector(state => state.routes.dispatchBoard.map.jobRoutes as DispatchBoardRouteJobRoute[]);
  const isLoadingCityAlerts = useSelector(state => state.vendors.cityAlerts.isLoading);
  const selectedJobs = useSelector(
    state => state.routes.dispatchBoard.map.selectedJobs as DispatchBoardRouteJobRoute[],
  );
  const selectedUnassignedJobIds = useSelector(s => s.routes.dispatchBoard.unassignedJobs.selectedJobIds);
  const jobColors = useSelector(state => state.routes.dispatchBoard.map.routeColors as any);
  const { geoFences } = useSelector(state => state.routes.geoFences.geoFences);

  const isViewOnly = checkIfViewOnly();

  const [bounds, setMapBounds] = useState<any>();

  // TODO uncomment this when creating alert is enabled on map
  // const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
  // const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;

  const selectedRouteIds = useMemo(() => (selectedRoutes as any[]).map(r => r.id), [selectedRoutes]);

  useEffect(() => {
    setSelectedRouteIdsFilter(selectedRouteIds);
  }, [selectedRouteIds]);

  const filteredJobs = useMemo(() => {
    return selectedRouteIds.length === 1 &&
      selectedRouteIds[0] === UNASSIGNED_ROUTE_ID &&
      selectedUnassignedJobIds.length
      ? jobs.filter(j => selectedUnassignedJobIds.includes(j.id))
      : [...jobs];
  }, [jobs, selectedRouteIds, selectedUnassignedJobIds]);

  const filteredRoutesJobs = useMemo(
    () => filteredJobs.filter(item => selectedRouteIdsFilter.includes(item.routeId)),
    [filteredJobs, selectedRouteIdsFilter],
  );

  const geoJSON = useMemo(() => getDispatchBoardJobsGeoJSON(filteredRoutesJobs), [filteredRoutesJobs]);

  const geoFencesGeoJSON = useMemo(() => {
    const allGeoFencesGeoJSON = (geoFences || []).map((geoFence: any) => {
      const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
      return {
        ...parsedGeoFenceJson,
        id: geoFence.id,
        properties: {
          ...parsedGeoFenceJson?.properties,
          name: geoFence.geoFenceName,
        },
      };
    });
    return allGeoFencesGeoJSON;
  }, [geoFences]);
  useEffect(() => {
    const points = filteredJobs.map(j => j.locationAddress);
    const bounds = getMapBounds(points);
    setMapBounds(bounds);
  }, [filteredJobs]);

  const routes = useMemo(
    () =>
      filteredJobs
        .reduce((routes: any[], job) => {
          if (!routes.find(r => r.id === job.routeId)) {
            const jobRoute = selectedRoutes.find(item => item.id === job.routeId);
            const completionProgress = !!jobRoute?.numberOfCompletedJobs
              ? Math.round((jobRoute.numberOfCompletedJobs / jobRoute.totalNumberOfJobs) * 100)
              : 0;

            const route = {
              id: job.routeId,
              name: job.routeName,
              color: jobColors[job.routeId],
              jobsCount: filteredJobs.filter(jr => jr.routeId === job.routeId).reduce(sum => sum + 1, 0),
              percentageColor: PICKUP_STATUS_LABEL_COLORS[jobRoute?.statusId ? jobRoute.statusId.toString() : '1'],
              completionProgress,
            };

            return sortBy(routes.concat(route), ['name']);
          }

          return routes;
        }, [] as any[])
        .sort(r => r.name),
    [filteredJobs, jobColors, selectedRoutes],
  );

  const filteredRoutes = useMemo(
    () => routes.filter(item => selectedRouteIdsFilter.includes(item.id)),
    [routes, selectedRouteIdsFilter],
  );

  const selectedJob = useMemo(
    () => selectedFeature && selectedFeature.type === 'job' && filteredJobs.find(job => job.id === selectedFeature.id),
    [filteredJobs, selectedFeature],
  );

  const setSelectedJobs = useCallback(
    (updatedSelectedJobs: DispatchBoardRouteJobRoute[]) => {
      dispatch(selectJobs(updatedSelectedJobs));
    },
    [dispatch],
  );

  const loadJobs = useCallback(() => {
    setIsLoading(true);

    const promise = loadDispatchBoardJobs(
      selectedRouteIds,
      vendorId,
      vehicleTypeId,
      searchTerm,
      date,
    )(dispatch).then(() => {
      setIsLoading(false);
    });

    return promise;
  }, [dispatch, searchTerm, selectedRouteIds, vehicleTypeId, vendorId, date]);

  const selectPointsInPolygon = useCallback(
    (points: DispatchBoardRouteJobRoute[], newPolygon: any) => {
      const includedPoints = points.filter(p =>
        booleanPointInPolygon([p.locationAddress.displayLongitude, p.locationAddress.displayLatitude], newPolygon),
      );

      setSelectedJobs(includedPoints);
      setPolygon(newPolygon);
    },
    [setSelectedJobs],
  );

  const selectPointsInPolygonDebounced = useMemo(() => debounce(selectPointsInPolygon, 200), [selectPointsInPolygon]);

  const checkLocationsInPolygon = (event: any) => {
    const newPolygon = event.data[0];

    setPolygon(newPolygon);

    if (event.editType === 'addFeature') {
      selectPointsInPolygon(filteredJobs, newPolygon);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(filteredJobs, newPolygon);
    }
  };

  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  useEffect(
    () => () => {
      dispatch(clearSelectedJobs());
      dispatch(resetGeoFences());
    },
    [dispatch],
  );

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        let hasSelectedFeature = false;
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === DISPATCH_BOARD_SOURCE);

        if (feature) {
          setSelectedFeature(undefined);
          setSelectedFeature({ id: feature.id as number, type: 'job' });
          hasSelectedFeature = true;
        }

        const [cityAlertFeature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === CITY_ALERTS_SOURCE_ID);

        if (cityAlertFeature) {
          setSelectedFeature(undefined);
          setSelectedFeature({ id: cityAlertFeature.id as number, type: 'cityAlert' });
          hasSelectedFeature = true;
        }

        if (!hasSelectedFeature) {
          setSelectedFeature(undefined);
        }
      });

      map.on('mousemove', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true,
        );

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch, geoFences]);

  useEffect(() => {
    if (mapDrawingEnabled) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
      }
    } else {
      if (polygon && editorRef.current) {
        editorRef.current.deleteFeatures(0);
        setSelectedJobs([]);
        setPolygon(undefined);
      }

      if (!!drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [mapDrawingEnabled, drawMode, polygon, setSelectedJobs]);

  const handleSubmitGeoFenceFilters = (formData: OnMapFiltersFormValues) => {
    const normalizedGeoFencesIds = normalizeFilterArray(formData.geoFenceSubFilters);
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);
    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      dispatch(
        loadGeoFences({
          vendorId,
          geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
          limit: 200,
          geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
          routeDate: date,
        }),
      );
    } else {
      dispatch(resetGeoFences());
    }

    const normalizeCityAlertsIds = normalizeFilterArray(formData.cityAlertsFilters);
    if (normalizeCityAlertsIds.length > 0) {
      loadCityAlerts(normalizeCityAlertsIds)(dispatch);
    } else {
      dispatch(resetCityAlerts());
    }

    setIsMapFiltersOpen(false);
  };

  const onSelectRoute = (routeId: number) => {
    if (selectedRouteIdsFilter.includes(routeId)) {
      const newSelection = selectedRouteIdsFilter.filter(id => id !== routeId);
      setSelectedRouteIdsFilter(newSelection);
    } else {
      setSelectedRouteIdsFilter([...selectedRouteIdsFilter, routeId]);
    }
  };

  // TODO uncomment this when creating alert is enabled on map
  // const handleLoadCityAlertsWhenCreatedNew = useCallback(() => {
  //   let newCityAlertsValues: boolean[] = [];
  //   activeCityAlertTypes.forEach(c => (newCityAlertsValues[c.cityAlertType.id] = true));
  //   dispatch(change(ON_MAP_FILTERS_FORM_NAME, 'cityAlertsFilters', newCityAlertsValues));
  //   dispatch(submit(ON_MAP_FILTERS_FORM_NAME));
  // }, [activeCityAlertTypes, dispatch]);

  return (
    <Panel>
      <LoadingContainer isLoading={isLoading || isLoadingCityAlerts}>
        <FullscreenMap fullscreen={isFullscreen}>
          <Resizable
            minHeight={isFullscreen ? '80vh' : '400px'}
            maxHeight={isFullscreen ? '80vh' : undefined}
            handleComponent={{ bottom: !isFullscreen ? <MapDragHandle /> : undefined }}
            defaultSize={{
              width: '100%',
              height: mapDefaultHeight > minMapSize ? mapDefaultHeight : minMapSize,
            }}
          >
            <MapGLWrapper drawingEnabled={!!drawMode && editPolygon} isDrawing={!!drawMode && !polygon}>
              <MapGL
                bounds={bounds}
                disableDefaultSatelliteView
                enableNewSatelliteView
                disableDefaultNavigationControl
                enableNewNavigationControl
                onMapRefLoaded={setMap}
                setIsSatelliteViewEnabled={setIsSatellite}
              >
                {!!drawMode && (
                  <Editor
                    ref={editorRef}
                    clickRadius={12}
                    mode={drawMode}
                    onUpdate={checkLocationsInPolygon}
                    features={polygon ? [polygon] : []}
                  />
                )}

                {!!map && (
                  <>
                    <RouteMapGeoFencesGL map={map} geoFencesGeoJSON={geoFencesGeoJSON} isSatellite={isSatellite} />

                    <DispatchBoardMapGLSource
                      geoJSON={geoJSON}
                      map={map}
                      selectedJobIds={selectedJobs.map(({ id }) => id)}
                    />

                    <CityAlertsGL map={map} />
                  </>
                )}

                {!!selectedJob && (
                  <DispatchBoardMapGLPopup selectedJob={selectedJob} onClose={() => setSelectedFeature(undefined)} />
                )}

                <CityAlertPopupResolver
                  onClose={() => setSelectedFeature(undefined)}
                  selectedFeature={selectedFeature}
                />
              </MapGL>

              <ComplexMapControl vertical position="top-left">
                <TooltipIconButton
                  tooltip="back"
                  tooltipPosition="right"
                  tooltipColor="grayDarker"
                  color="secondary"
                  margin="no"
                  onClick={() => dispatch(closeMap())}
                >
                  <IconButtonIcon icon="back" color="primary" />
                </TooltipIconButton>
                <TooltipIconButton
                  tooltip="routes"
                  tooltipPosition="right"
                  tooltipColor="grayDarker"
                  color="secondary"
                  margin="small no no"
                  onClick={() => setIsMapRoutesFiltersOpen(true)}
                >
                  <IconButtonIcon icon="routes" color="primary" />
                </TooltipIconButton>
                <TooltipIconButton
                  tooltip="mapFilters"
                  tooltipPosition="right"
                  tooltipColor="grayDarker"
                  color="secondary"
                  margin="small no no"
                  onClick={() => setIsMapFiltersOpen(true)}
                >
                  <IconButtonIcon icon="mapFilter" color="primary" />
                </TooltipIconButton>
                <TooltipIconButton
                  tooltip={translate('common.fullScreen')}
                  tooltipAsString
                  tooltipPosition="right"
                  tooltipColor="grayDarker"
                  margin="small no no"
                  color={isFullscreen ? 'primary' : 'secondary'}
                  onClick={() => setIsFullscreen(!isFullscreen)}
                >
                  <IconButtonIcon icon={isFullscreen ? 'fullscreenOff' : 'fullscreenOn'} />
                </TooltipIconButton>

                <TooltipIconButton
                  tooltipAsString
                  tooltip={translate(
                    drawMode ? 'routeTemplateBuilder.deletePolygon' : 'routeTemplateBuilder.drawPolygon',
                  )}
                  tooltipPosition="right"
                  tooltipColor="grayDarker"
                  margin="small no no"
                  color={drawMode ? 'warning' : 'secondary'}
                  onClick={() => {
                    setEditPolygon(true);
                    setMapDrawingEnabled(!mapDrawingEnabled);
                  }}
                >
                  <IconButtonIcon margin="no" icon={drawMode ? 'delete' : 'highlight'} />
                </TooltipIconButton>

                {polygon && (
                  <TooltipIconButton
                    tooltipAsString
                    tooltip={translate(
                      editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
                    )}
                    tooltipPosition="right"
                    tooltipColor="grayDarker"
                    margin="small no no"
                    color={editPolygon ? 'primary' : 'secondary'}
                    onClick={() => {
                      setEditPolygon(!editPolygon);
                    }}
                  >
                    <IconButtonIcon margin="no" icon="edit" />
                  </TooltipIconButton>
                )}
              </ComplexMapControl>

              {!!filteredRoutes.length && (
                <ComplexMapControl position="bottom-right">
                  <FloatingCollapsible
                    isOpenByDefault
                    title={translate('dashboard.legend')}
                    widthWhenOpen={250}
                    widthWhenClosed={100}
                  >
                    <Box
                      height={filteredRoutes.length > 10 ? 220 : filteredRoutes.length * 30 + 30}
                      overflow="auto"
                      margin="xSmall"
                    >
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={8}>
                        <Text>{translate('common.routes')}</Text>
                        <Text>{translate('dashboard.completed')}</Text>
                      </Box>
                      {filteredRoutes.map((route, index) => (
                        <Box key={route.id} display="flex" alignItems="center" mt={!!index ? 8 : 0}>
                          <Box
                            pl={3}
                            pr={3}
                            borderRadius={30}
                            backgroundColor={route.color}
                            fontSize={11}
                            minWidth={22}
                            height={22}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="white"
                          >
                            {route.jobsCount}
                          </Box>

                          <Box
                            title={route.name}
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            fontSize={12}
                            ml={8}
                          >
                            {route.name}
                          </Box>

                          <Label color={route.percentageColor} marginLeftAuto>
                            {route.completionProgress}%
                          </Label>
                        </Box>
                      ))}
                    </Box>
                  </FloatingCollapsible>
                </ComplexMapControl>
              )}

              {/* TODO uncomment this when creating alert is enabled on map */}
              {/* <CityAlertCreateTrigger
                xOffset={0}
                yOffset={10}
                position="top-right"
                onSavedSuccess={handleLoadCityAlertsWhenCreatedNew}
              /> */}

              {drawMode && <DrawingInstructions />}
              <OnMapFiltersForm
                isOnMapFiltersFormValuesOpen={isMapFiltersOpen}
                closeRouteMapFilters={() => setIsMapFiltersOpen(false)}
                initialValues={{
                  geoFencesTypesFilters: [],
                  geoFenceSubFilters: [],
                  geoFenceSearchTerm: '',
                  cityAlertsFilters: [],
                }}
                onSubmit={handleSubmitGeoFenceFilters}
                isCityAlertsVisible
              />
              <MapRoutesFilterList
                isRoutesSidebarOpen={isMapRoutesFiltersOpen}
                isLoading={isLoading}
                routesList={selectedRoutes}
                selectedRouteIdsFilter={selectedRouteIdsFilter}
                onSelectRoute={onSelectRoute}
                searchJobs={(value: string) => dispatch(searchJobs(value))}
                closeRouteMapFilters={() => setIsMapRoutesFiltersOpen(false)}
              />
            </MapGLWrapper>
          </Resizable>
          {!isViewOnly && (
            <AssignJobsContainer
              loadJobsForSelectedRoutes={loadJobs}
              vehicleTypeId={vehicleTypeId}
              onSuccessClose={() => setMapDrawingEnabled(false)}
            />
          )}
        </FullscreenMap>
      </LoadingContainer>
    </Panel>
  );
};

export default DispatchBoardMapGL;
