import React from 'react';

import { downloadSampleCustomerAccountStatus, uploadFile } from '../../services/customerAccountStatus';
import RecordsUploaderModal, { RecordsUploaderModalProps } from '../../../common/components/RecordsUploaderModal';

type Props = Omit<RecordsUploaderModalProps, 'parentTranslationsPath' | 'downloadSample' | 'uploadFile'>;

const CustomerAccountStatusUploaderModal: React.FC<Props> = props => (
  <RecordsUploaderModal
    downloadSample={downloadSampleCustomerAccountStatus}
    parentTranslationsPath="customers.accountStatusUpload"
    uploadFile={uploadFile}
    {...props}
  />
);

export default CustomerAccountStatusUploaderModal;
