import { PureComponent } from 'react';
import { change, Field, InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { filter, map, orderBy } from 'lodash-es';
import moment from 'moment';

import { AppState } from '../../../store';
import {
  isRequired,
  isOptionalPhone,
  hasCoordinates,
  hasCountry,
  hasCity,
  hasZip,
  hasStreet,
  hasStreetNumber,
  maxLength50,
} from '../../../utils/services/validator';
import { AVAILABLE, SERVICE_STATUSES } from 'src/fleet/constants/status';
import { ButtonSet, Button, Grid, GridColumn, Text } from '../../../core/components/styled';
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  LocationPicker,
  MultiSelect,
  NavigationPrompt,
  TypedField,
  UnconnectedCheckbox,
} from '../../../core/components';
import { DriverUnavailable } from 'src/fleet/interfaces/Driver';
import { DuckFunction } from '../../../contracts/ducks';
import { Facility } from 'src/common/interfaces/Facility';
import { loadLanguageOptions } from '../../../common/ducks';
import { ResourceEditorFormElement, SectionWrapper } from '../styled/Resources';
import ResourceEditorFormRoleValidationField, {
  resourceEditorFormRoleValidate,
} from './components/ResourceEditorFormRoleValidationField';
import { RouteTemplate } from '../../../routes/interfaces/RouteTemplates';
import { SUPERVISOR_ID } from '../../constants';
import { supervisorExperienceFeatureIsEnabled } from '../../../vendors/ducks/features';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { UserForDriver } from 'src/fleet/interfaces/UserForDriver';
import { VehicleDropdown } from '../../../fleet/components';
import driverEditorFormInitialValuesSelector from '../../services/driverEditorFormInitialValuesSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm extends RouteComponentProps {
  activeDrivers: any[];
  change: any;
  driverFacilities: Facility[];
  driverUnavailableEndDate?: Date | string;
  driverUnavailableInitialValues?: DriverUnavailable;
  driverUnavailableStartDate?: Date | string;
  isAvailable: boolean;
  isSupervisor?: boolean;
  isSupervisorVehicleTypeEnabled: boolean;
  languageOptions: any;
  linkedDriverIds: number[];
  linkedRouteTemplateIds: number[];
  loadLanguageOptions: DuckFunction<typeof loadLanguageOptions>;
  routeTemplates: RouteTemplate[];
  supervisorFeatureEnabled: boolean;
  usersForDriver?: UserForDriver[];
}

interface State {
  isAvailableStatusSelected: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class DriverEditorForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAvailableStatusSelected: false,
    };
  }

  componentDidMount() {
    const { loadLanguageOptions } = this.props;
    loadLanguageOptions();
  }

  changeServiceDate = (date: string, initialDate?: Date | string, isAvailableStatusSelected?: boolean) => {
    const { change } = this.props;

    change(date, isAvailableStatusSelected ? null : initialDate ? moment(initialDate).format('MM/DD/YYYY') : null);

    this.setState({
      isAvailableStatusSelected: isAvailableStatusSelected || false,
    });
  };

  onServiceStatusChange = (event: any) => {
    const { driverUnavailableInitialValues } = this.props;
    const isAvailableStatusSelected = event === AVAILABLE;

    this.changeServiceDate(
      'driverUnavailableStartDate',
      driverUnavailableInitialValues?.startDate,
      isAvailableStatusSelected,
    );
    this.changeServiceDate(
      'driverUnavailableEndDate',
      driverUnavailableInitialValues?.endDate,
      isAvailableStatusSelected,
    );
  };

  render() {
    const {
      activeDrivers,
      change,
      driverFacilities,
      driverUnavailableEndDate,
      driverUnavailableInitialValues,
      driverUnavailableStartDate,
      handleSubmit,
      initialValues,
      isAvailable,
      isSupervisor,
      isSupervisorVehicleTypeEnabled,
      languageOptions: languageOptionsArray,
      linkedDriverIds,
      linkedRouteTemplateIds,
      pristine,
      routeTemplates,
      submitSucceeded,
      submitting,
      supervisorFeatureEnabled,
      usersForDriver,
    } = this.props;

    const { isAvailableStatusSelected } = this.state;

    const languageOptions = map(languageOptionsArray, ({ name, id }) => ({
      label: name,
      value: id,
    }));

    const selectedDrivers = linkedDriverIds.map(driverId => activeDrivers.find(driver => driver.id === driverId));
    const selectedRouteTemplates = linkedRouteTemplateIds.map(routeTemplateId =>
      routeTemplates.find(template => template.id === routeTemplateId),
    );

    const usersForDriverOptions = filter(
      usersForDriver,
      diver => !diver.associatedDriverId || diver.associatedDriverId === initialValues.id,
    ).map(user => ({ value: user.userId, label: user.name }));

    const driverFacilitiesOptions = map(driverFacilities, ({ locationId, name, isActive }: Facility) => ({
      label: name,
      value: locationId,
      isActive: isActive,
    }));
    const driverFacilitiesOptionsOrdered = orderBy(driverFacilitiesOptions, 'isActive', 'desc');

    return (
      <>
        <NavigationPrompt when={!pristine && !submitSucceeded && !submitting} />

        <ResourceEditorFormElement onSubmit={handleSubmit} noValidate>
          <Grid centered multiLine>
            <GridColumn size="12/12" padding="xSmall no">
              <Grid multiLine>
                <GridColumn size="12/12">
                  <Text block size="xLarge" margin="no no xSmall">
                    {translate('resources.resourceDetails')}
                  </Text>
                </GridColumn>

                <GridColumn size="3/12">
                  <Field
                    name="firstName"
                    component={Input}
                    label={translate('common.firstName')}
                    validate={[isRequired, maxLength50]}
                  />
                </GridColumn>

                <GridColumn size="3/12">
                  <Field
                    name="lastName"
                    component={Input}
                    label={translate('common.lastName')}
                    validate={[isRequired, maxLength50]}
                  />
                </GridColumn>

                <GridColumn size="3/12">
                  <Field
                    name="phoneNumber"
                    component={Input}
                    label={translate('common.phone')}
                    validate={[isOptionalPhone]}
                  />
                </GridColumn>

                <GridColumn size="3/12">
                  <Field
                    name="driverLicenseNumber"
                    component={Input}
                    label={translate('drivers.licenseNumber')}
                    validate={[maxLength50]}
                  />
                </GridColumn>

                <GridColumn size="3/12">
                  <Field
                    name="languageId"
                    component={Dropdown}
                    options={languageOptions}
                    label={translate('account.languagePreferences')}
                  />
                </GridColumn>

                <GridColumn size="6/12">
                  <Field
                    name="homeAddress"
                    component={LocationPicker}
                    validate={[hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
                    props={{
                      fullWidth: true,
                      isClearable: true,
                      label: translate('common.address'),
                    }}
                  />
                </GridColumn>

                <GridColumn size="3/12" verticalAlign="center">
                  <Field name="isActive" component={Checkbox} label={translate('common.active')} />
                </GridColumn>

                <GridColumn size="3/12" verticalAlign="center">
                  <Field
                    name="isAvailable"
                    component={Dropdown}
                    label={translate('common.serviceStatus')}
                    options={SERVICE_STATUSES}
                    validate={[isRequired]}
                    onChange={this.onServiceStatusChange}
                    width="100%"
                  />
                </GridColumn>

                {(!isAvailable || !!driverUnavailableInitialValues) && !isAvailableStatusSelected && (
                  <>
                    <Field name="driverUnavailableInitialValues" component={Input} type="hidden" />
                    <GridColumn size="3/12" verticalAlign="center">
                      <Field
                        name="driverUnavailableStartDate"
                        component={DatePicker}
                        disabledDays={[
                          {
                            before: moment().toDate(),
                            after: driverUnavailableEndDate
                              ? moment(driverUnavailableEndDate).subtract(1, 'days').toDate()
                              : undefined,
                          },
                        ]}
                        label={translate('common.unavailableFrom')}
                        isClearable
                      />
                    </GridColumn>

                    <GridColumn size="3/12" verticalAlign="center">
                      <Field
                        name="driverUnavailableEndDate"
                        component={DatePicker}
                        disabledDays={[
                          {
                            before: driverUnavailableStartDate
                              ? moment(driverUnavailableStartDate).toDate()
                              : moment().toDate(),
                            after: undefined,
                          },
                        ]}
                        label={translate('common.unavailableTo')}
                        isClearable
                      />
                    </GridColumn>
                  </>
                )}

                <GridColumn size="3/12" verticalAlign="center">
                  <Field
                    name="locationId"
                    component={Dropdown}
                    label={translate('common.customerTypes.operationalFacility')}
                    options={driverFacilitiesOptionsOrdered}
                    isClearable
                    width="100%"
                  />
                </GridColumn>
              </Grid>
            </GridColumn>

            {supervisorFeatureEnabled && (
              <GridColumn size="12/12" padding="xSmall">
                <Text block size="xLarge">
                  {translate('common.roles')}
                </Text>

                <Text color="grayDark" size="small" margin="xxSmall no no">
                  {translate('resources.selectAllThatApply')}
                </Text>

                <Grid multiLine>
                  <GridColumn size="12/12" padding="small no no">
                    <TypedField
                      name="isDriver"
                      component={Checkbox}
                      props={{
                        label: translate('common.driver'),
                        size: 'medium',
                        margin: 'no',
                        disabled: isSupervisor,
                      }}
                    />
                  </GridColumn>

                  <GridColumn size={`${isSupervisor ? '3' : '12'}/12`} padding="small no no">
                    <TypedField
                      name="isSupervisor"
                      component={Checkbox}
                      onChange={(event, newIsSupervisor: boolean) => {
                        if (!newIsSupervisor) {
                          change('linkedDriverIds', []);
                          change('linkedRouteTemplateIds', []);
                        } else {
                          change('isDriver', true);
                          change('linkedDriverIds', initialValues.linkedDriverIds);
                          change('linkedRouteTemplateIds', initialValues.linkedRouteTemplateIds);
                        }
                      }}
                      props={{
                        label: translate('dashboard.supervisor'),
                        size: 'medium',
                        margin: 'no',
                      }}
                    />
                  </GridColumn>
                  {isSupervisor && (
                    <GridColumn size="4/12" padding="no">
                      <TypedField
                        name="userId"
                        component={Dropdown}
                        props={{
                          isClearable: true,
                          margin: 'no',
                          options: usersForDriverOptions,
                          label: translate('common.supervisorPortalUser'),
                        }}
                      />
                    </GridColumn>
                  )}
                </Grid>

                <TypedField
                  name="atLeastOneRoleSelected"
                  component={ResourceEditorFormRoleValidationField}
                  validate={[resourceEditorFormRoleValidate]}
                  props={{ showErrorBeforeSubmit: true }}
                />

                {isSupervisor && (
                  <Grid margin="medium no no">
                    <GridColumn size="4/12" padding="no xSmall no no">
                      <TypedField
                        name="linkedDriverIds"
                        component={MultiSelect}
                        props={{
                          isClearable: true,
                          isSearchable: true,
                          defaultToAll: false,
                          label: translate('resources.assignedDrivers'),
                          noOptionsMessage: translate('resources.noDrivers'),
                          options: activeDrivers.map(driver => ({
                            label: driver.name,
                            value: driver.id,
                            isDisabled: driver.id === initialValues.id,
                          })),
                          normalizeValues: value => +value,
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('resources.allDrivers')
                              : translate('resources.xDriversSelected', { selected: selectedOptions.length });
                          },
                        }}
                      />

                      <SectionWrapper>
                        {selectedDrivers.map(
                          driver =>
                            driver && (
                              <UnconnectedCheckbox
                                block
                                checked
                                overflow
                                margin="no no xSmall"
                                key={driver.id}
                                label={driver.name}
                                onChange={() => {
                                  const newLinkedDriverIds = linkedDriverIds.slice();
                                  const index = newLinkedDriverIds.indexOf(driver.id);

                                  newLinkedDriverIds.splice(index, 1);

                                  change('linkedDriverIds', newLinkedDriverIds);
                                }}
                              />
                            ),
                        )}
                      </SectionWrapper>
                    </GridColumn>

                    <GridColumn size="4/12" padding="no no no xSmall">
                      <TypedField
                        name="linkedRouteTemplateIds"
                        component={MultiSelect}
                        props={{
                          isClearable: true,
                          isSearchable: true,
                          defaultToAll: false,
                          label: translate('resources.assignedRoutes'),
                          noOptionsMessage: translate('resources.noRoutes'),
                          options: routeTemplates.map(template => ({
                            label: template.routeTemplateName,
                            value: template.id,
                          })),
                          normalizeValues: value => +value,
                          formatText(selectedOptions: any[], allSelected: boolean) {
                            return allSelected
                              ? translate('resources.allRoutes')
                              : translate('resources.xRoutesSelected', { selected: selectedOptions.length });
                          },
                        }}
                      />

                      <SectionWrapper>
                        {selectedRouteTemplates.map(
                          routeTemplate =>
                            routeTemplate && (
                              <UnconnectedCheckbox
                                block
                                checked
                                overflow
                                margin="no no xSmall"
                                key={routeTemplate.id}
                                label={routeTemplate.routeTemplateName}
                                onChange={() => {
                                  const newLinkedRouteTemplateIds = linkedRouteTemplateIds.slice();
                                  const index = newLinkedRouteTemplateIds.indexOf(routeTemplate.id);

                                  newLinkedRouteTemplateIds.splice(index, 1);

                                  change('linkedRouteTemplateIds', newLinkedRouteTemplateIds);
                                }}
                              />
                            ),
                        )}
                      </SectionWrapper>
                    </GridColumn>

                    {isSupervisorVehicleTypeEnabled && (
                      <GridColumn size="4/12" padding="no no no xSmall">
                        <TypedField
                          name="linkedVehicleId"
                          component={VehicleDropdown}
                          props={{
                            dropdownProps: {
                              isClearable: true,
                            },
                            withLabel: true,
                          }}
                        />
                      </GridColumn>
                    )}
                  </Grid>
                )}
              </GridColumn>
            )}

            <GridColumn size="12/12" padding="xSmall">
              <ButtonSet margin="large no no">
                <Button type="submit" color="primary" id="save-driver-button">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </ResourceEditorFormElement>
      </>
    );
  }
}

const formSelector = formValueSelector('driverEditorForm');

const mapStateToProps = (state: AppState) => ({
  activeDrivers: state.fleet.drivers.drivers.filter(driver => driver.isActiveBoolean === true),
  driverFacilities: state.fleet.driver.driverFacilities,
  driverUnavailableEndDate: formSelector(state, 'driverUnavailableEndDate'),
  driverUnavailableInitialValues: formSelector(state, 'driverUnavailable'),
  driverUnavailableStartDate: formSelector(state, 'driverUnavailableStartDate'),
  initialValues: driverEditorFormInitialValuesSelector(state),
  isAvailable: formSelector(state, 'isAvailable') === AVAILABLE,
  isSupervisor: formSelector(state, 'isSupervisor'),
  isSupervisorVehicleTypeEnabled: !!(state.fleet.vehicleTypesForVendor.vehicleTypesForVendor || []).find(
    (vehicleType: TechnicalType) => vehicleType.id === SUPERVISOR_ID,
  ),
  languageOptions: state.common.languageOptions.languageOptions,
  linkedDriverIds: formSelector(state, 'linkedDriverIds') || [],
  linkedRouteTemplateIds: formSelector(state, 'linkedRouteTemplateIds') || [],
  routeTemplates: (state.routes.routeTemplates.routeTemplates || []).filter(routeTemplate => routeTemplate.isEnabled),
  supervisorFeatureEnabled: supervisorExperienceFeatureIsEnabled(state),
  usersForDriver: state.fleet.usersForDriver.usersForDriver,
});

const mapDispatchToProps = {
  change,
  loadLanguageOptions,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'driverEditorForm',
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
    })(DriverEditorForm),
  ),
);
