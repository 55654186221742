const transformCoordinates = (coordinates: any) => {
  if (coordinates.length === 0) {
    return [];
  }
  //  check if is object lat long
  if (coordinates[0].lat) {
    const newCoords = [coordinates.map((coord: any) => [coord.lng, coord.lat])];
    // check if last coord is equal to first
    if (newCoords[0][0][0] !== newCoords[0][newCoords[0].length - 1][0]) {
      newCoords[0].push(newCoords[0][0]);
    }
    return newCoords;
  }

  if (coordinates[0][0][0] !== coordinates[0][coordinates[0].length - 1][0]) {
    coordinates[0].push(coordinates[0][0]);
  }
  return coordinates;
};

export const transformGeoFenceJson = (geoFenceJson: any) => {
  if (geoFenceJson && geoFenceJson.indexOf('MultiPolygon') === -1) {
    const parsedPolygon = JSON.parse(geoFenceJson);
    return JSON.stringify({
      ...parsedPolygon,
      geometry: {
        ...parsedPolygon.geometry,
        type: 'MultiPolygon',
        coordinates: [transformCoordinates(parsedPolygon.geometry.coordinates)],
      },
    });
  } else {
    const parsedPolygon = JSON.parse(geoFenceJson);
    return JSON.stringify({
      ...parsedPolygon,
      geometry: {
        ...parsedPolygon.geometry,
        coordinates: transformCoordinates(parsedPolygon.geometry.coordinates),
      },
    });
  }
};
