import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import { http } from 'src/core/services/http';
import { LIMIT_PER_PAGE } from 'src/core/constants';
import { SortOrder } from 'src/core/components/Table';
import { SuspendedLocation } from '../interfaces/Customers';
import { transformSuspendedLocations, transformSuspendLocations } from './transformSuspendedLocations';
import translate from 'src/core/services/translate';

export const loadSuspendedLocations = (
  vendorId: number,
  searchTerm?: string,
  accountStatusTypeId?: string,
  sortedBy?: string,
  sortOrder?: SortOrder,
  page: number = 1,
  limit: number = LIMIT_PER_PAGE,
) =>
  http
    .get('/customers/locations/suspended', {
      params: { vendorId, searchTerm, sortOrder: sortedBy, sortDirection: sortOrder, page, limit, accountStatusTypeId },
    })
    .then(response => transformSuspendedLocations(response.data));

export const saveSuspendedLocations = (suspendedLocations: SuspendedLocation[], shouldUpdateRoutes?: boolean) =>
  http.post('customers/locations/suspend', transformSuspendLocations(suspendedLocations), {
    params: { shouldUpdateRoutes },
  });

export const exportSuspendedLocations = (vendorId: number, searchTerm?: string, accountStatusTypeId?: string) =>
  downloadExcelFile('/customers/locations/suspended/export', translate('customers.suspendedLocations'), {
    vendorId,
    searchTerm,
    accountStatusTypeId,
  });
