import { filter, map, size } from 'lodash-es';
import { ChangeEvent, FC, useMemo } from 'react';
import { WrappedFieldInputProps } from 'redux-form';

import { TypedField } from 'src/core/components';
import MultiSelect, { MultiSelectProps } from 'src/core/components/MultiSelect';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';
import { DropDownFieldProps } from './DropDownFieldProps';
import { TechnicalType } from '../interfaces/TechnicalType';
import { AppState } from 'src/store';

const formatText = (selectedOptions: number[], allOptionsSelected: boolean) =>
  allOptionsSelected
    ? translate('routes.allPickupTypes')
    : size(selectedOptions) === 1 && selectedOptions[0] === NONE_ID
    ? translate('routes.noPickupTypes')
    : translate('routes.xPickupTypesSelected', { selected: size(selectedOptions) });

interface Props extends DropDownFieldProps {
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
  excludePickupTypeIds?: number[];
  includeNoneOption?: boolean;
  vehicleTypeId: number;
}

const PickupTypesMultiSelect: FC<Props> = ({
  input,
  multiSelectProps,
  excludePickupTypeIds,
  label,
  withLabel,
  placeholder,
  withPlaceholder,
  vehicleTypeId,
  ...rest
}) => {
  const allVehiclePickupTypes = useSelector((state: AppState) => state.routes.pickupTypes.pickupTypes);

  const selectedVehiclePickupTypes = useMemo(() => {
    return allVehiclePickupTypes?.find(item => item.vehicleTypeId === vehicleTypeId)?.pickupTypes || [] as TechnicalType[];
  }, [allVehiclePickupTypes, vehicleTypeId]);

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    input.onChange(value);
    multiSelectProps?.onChange && multiSelectProps.onChange(value);
  };

  const options = useMemo(() => {
    const filtered = map(
      filter(selectedVehiclePickupTypes, pickupType => !!pickupType.id && !excludePickupTypeIds?.includes(pickupType.id)),
      pickupType => ({ label: pickupType.name, value: pickupType.id }),
    );
    return filtered;
  }, [excludePickupTypeIds, selectedVehiclePickupTypes]);

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={handleChange}
      {...rest}
      props={{
        label: label || (withLabel ? translate('routes.pickupType') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('routes.pickupType') : undefined),
        options: options,
        canCheckAll: multiSelectProps?.canCheckAll || false,
        isClearable: true,
        normalizeValues: Number,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
};

export default PickupTypesMultiSelect;
