import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadDisposalSiteLocations } from 'src/common/ducks';
import { loadWeightTicket } from 'src/routes/ducks/weightTickets';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import WeightTicketEditorModal from './WeightTicketEditorModal';

type Props = {
  isRollOff: boolean;
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  routeLocationId?: number;
  weightTicketId?: number;
};

export default function WeightTicketEditorModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const loadDependencies = () => {
    const dependencies = [
      loadDisposalSiteLocations(vendorId)(dispatch),
    ];
    if (props.weightTicketId) dependencies.push(loadWeightTicket(props.weightTicketId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={WeightTicketEditorModal}
      successProps={{ ...props, isUsingDisposals: true }}
    />
  );
}
