import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { useSelector } from 'src/core/hooks/useSelector';

import { featureStatusSelector } from 'src/vendors/ducks';

interface Props extends RouteProps<any> {
  feature: string;
}

const FeatureRestrictedRoute = ({ feature, component: Component, ...rest }: Props) => {
  const location = useLocation();
  const isFeatureEnabled = useSelector(state => featureStatusSelector(state.vendors.features.features, feature));

  return (
    <Route
      {...rest}
      render={props =>
        isFeatureEnabled && !!Component ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )
      }
    />
  );
};

export default FeatureRestrictedRoute;
