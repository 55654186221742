import React, { Fragment } from 'react';

import { ActionButtonTooltip } from '../../../core/components';
import { date as formatDate } from '../../../utils/services/formatter';
import { TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import { W9 } from '../../constants/documentTypesOption';

interface Props {
  certificateNumber: string;
  createdUser: string;
  deleteDocument(id: number): void;
  description: string;
  documentType: string;
  downloadCertification(event: any, id: number, filename: string): void;
  fileName: string;
  id: number;
  isviewCertificateNo: boolean;
  userId: string;
  validFrom: string;
  validTo: string;
}

const ViewCertificationsTableRows: React.FC<Props> = ({
  certificateNumber,
  createdUser,
  deleteDocument,
  description,
  documentType,
  downloadCertification,
  fileName,
  id,
  isviewCertificateNo,
  userId,
  validFrom,
  validTo,
}) => (
  <TableRow>
    <TableCell width={isviewCertificateNo ? '30%' : documentType === W9 ? '80%' : '50%'}>{description}</TableCell>
    {isviewCertificateNo && <TableCell width="20%">#{certificateNumber}</TableCell>}
    {documentType !== W9 && (
      <Fragment>
        <TableCell width="15%">{!!validFrom ? formatDate(validFrom) : '-'}</TableCell>
        <TableCell width="15%">{!!validTo ? formatDate(validTo) : '-'}</TableCell>
      </Fragment>
    )}

    <TableCell width="20%" align="right">
      <TableActionButton onClick={e => downloadCertification(e, id, fileName)} id={`download-document-${id}-button`}>
        <ActionButtonTooltip icon="download" tooltip="downloadFile" />
      </TableActionButton>
      {createdUser === userId && (
        <TableActionButton id={`delete-document-${id}-button`} onClick={() => deleteDocument(id)}>
          <ActionButtonTooltip icon="delete" tooltip="deleteFile" />
        </TableActionButton>
      )}
    </TableCell>
  </TableRow>
);

export default ViewCertificationsTableRows;
