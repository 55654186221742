import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

// Actions
const CHANGE_TITLE = 'core/mobilePage/CHANGE_TITLE';
const CHANGE_SUBTITLE = 'core/mobilePage/CHANGE_SUBTITLE';
const CHANGE_SECONDARY_CONTENT = 'core/mobilePage/CHANGE_SECONDARY_CONTENT';
const CHANGE_DETAIL_OVERLAY_TITLE_CONTENT = 'core/mobilePage/CHANGE_DETAIL_OVERLAY_TITLE_CONTENT';
const OPEN_DETAIL_OVERLAY = 'core/mobilePage/OPEN_DETAIL_OVERLAY';
const CLOSE_DETAIL_OVERLAY = 'core/mobilePage/CLOSE_DETAIL_OVERLAY';

interface DocumentState {
  title: string;
  subTitle?: string | JSX.Element;
  secondaryContent?: JSX.Element;
  detailOverlayContent?: JSX.Element;
  detailOverlayTitleContent?: JSX.Element;
}

// Initial state
const initialState: DocumentState = {
  title: '',
};

// Reducer
export const mobilePageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CHANGE_TITLE:
      return update(state, {
        $merge: {
          title: action.title,
        },
      });

    case CHANGE_SUBTITLE:
      return update(state, {
        $merge: {
          subTitle: action.subTitle,
        },
      });

    case CHANGE_SECONDARY_CONTENT:
      return update(state, {
        $merge: {
          secondaryContent: action.secondaryContent,
        },
      });

    case CHANGE_DETAIL_OVERLAY_TITLE_CONTENT:
      return update(state, {
        $merge: {
          detailOverlayTitleContent: action.detailOverlayTitleContent,
        },
      });

    case OPEN_DETAIL_OVERLAY:
      return update(state, {
        $merge: {
          detailOverlayContent: action.detailOverlayContent,
        },
      });

    case CLOSE_DETAIL_OVERLAY:
      return update(state, {
        $merge: {
          detailOverlayContent: undefined,
        },
      });

    default:
      return state;
  }
};

const changeTitle = (title: string) => ({
  type: CHANGE_TITLE,
  title,
});

const changeSubtitle = (subTitle?: JSX.Element) => ({
  type: CHANGE_SUBTITLE,
  subTitle,
});

const changeSecondaryContent = (secondaryContent?: JSX.Element) => ({
  type: CHANGE_SECONDARY_CONTENT,
  secondaryContent,
});

const changeDetailOverlayTitleContent = (detailOverlayTitleContent?: JSX.Element) => ({
  type: CHANGE_DETAIL_OVERLAY_TITLE_CONTENT,
  detailOverlayTitleContent,
});

const openDetailOverlay = (detailOverlayContent?: JSX.Element) => ({
  type: OPEN_DETAIL_OVERLAY,
  detailOverlayContent,
});

const closeDetailOverlay = () => ({
  type: CLOSE_DETAIL_OVERLAY,
});

export const changeMobilePageTitle = (title: string) => (dispatch: Dispatch) => {
  dispatch(changeTitle(title));
};

export const changeMobilePageSubTitle = (subTitle?: JSX.Element) => (dispatch: Dispatch) => {
  dispatch(changeSubtitle(subTitle));
};

export const changeMobilePageSecondaryContent = (secondaryContent?: JSX.Element) => (dispatch: Dispatch) => {
  dispatch(changeSecondaryContent(secondaryContent));
};

export const changeMobilePageDetailOverlayTitleContent =
  (detailOverlayTitleContent?: JSX.Element) => (dispatch: Dispatch) => {
    dispatch(changeDetailOverlayTitleContent(detailOverlayTitleContent));
  };

export const openMobilePageDetailOverlay = (detailOverlayContent?: JSX.Element) => (dispatch: Dispatch) => {
  dispatch(openDetailOverlay(detailOverlayContent));
};

export const closeMobilePageDetailOverlay = () => (dispatch: Dispatch) => {
  dispatch(closeDetailOverlay());
};
