import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { loadContainerFacilities } from 'src/fleet/ducks';
import { ModalProps } from 'src/vendors/interfaces/ModalProps';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import TransferContainerModal from './TransferContainerModal';

interface Props extends ModalProps {
  title: string;
  handleContainerTransfer(values: any): void;
}
const TransferContainerModalResolver: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadContainerFacilities(vendorId)(dispatch)]);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      successComponent={TransferContainerModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default TransferContainerModalResolver;
