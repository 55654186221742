import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { filter } from 'lodash-es';
import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';
import { AppState } from '../../store';
import { DropDownFieldProps } from './DropDownFieldProps';

// todo: type this
type DisposalSiteLocation = any;

interface Props extends DropDownFieldProps {
  disposalSiteLocations: DisposalSiteLocation[];
}

class DisposalSiteLocationDropdown extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      disposalSiteLocations,
      dropdownProps,
    } = this.props;

    const disposalSiteOptionsValid = filter(
      disposalSiteLocations,
      disposalSiteLocation => !disposalSiteLocation.isDeleted,
    );

    const disposalSiteOptions = disposalSiteOptionsValid.map(disposalSite => ({
      label: `${disposalSite.customerName}${disposalSite.isActive ? '' : ' (Inactive)'}`,
      value: disposalSite.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.disposalSite') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.disposalSite') : undefined)}
        component={Dropdown as any}
        options={disposalSiteOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  disposalSiteLocations: state.common.disposalSiteLocations.disposalSiteLocations as any as DisposalSiteLocation[],
});

export default connect(mapStateToProps)(DisposalSiteLocationDropdown);
