import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isArray } from 'lodash-es';
import { reset } from 'redux-form';

import { AppState } from '../../../store';
import {
  AGING_REPORT,
  BILLING_REPORT,
  COLLECTIONS_REPORT,
  COMMUNITY_PARTICIPATION,
  DAILY,
  DRIVER_SAFETY_INSPECTION,
  EXCEPTIONS,
  FAULT_CODES,
  ODAKYU_DAILY_ROUTE_SUMMARY,
  ORPHAN_INVOICE_REPORT,
  PARTICIPATIONS,
  PAYMENT_REPORT,
  PRE_POST_TRIP_INSPECTION,
  RESIDENTIAL_STOP,
  ROLL_OF_SERVICE_HISTORY,
  SET_OUT,
  STREET_SWEEPING_ACTIVITY_SUMMARY,
  STREET_SWEEPING_OBSTACLES,
  UNSERVICED_RESIDENTIAL,
  Y_DEVICE_STATUS,
} from '../../constants/reportTypes';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { exportReports, resetDailyReportTypes } from '../../ducks';
import {
  loadDrivers,
  loadVehicles,
  loadVehicleTypesForVendor,
  resetDrivers,
  resetVehicles,
  resetVehicleTypesForVendor,
  vehicleTypesForVendorWithRoleTypeSelector,
} from '../../../fleet/ducks';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Panel } from '../../../core/components/styled';
import { ReportsForm } from '../forms';
import translate from '../../../core/services/translate';
import { BetaBaseWrapper, BetaButton } from 'src/routes/components/styled/RouteTracker';

import { setReportsNewLayoutActive } from 'src/insights/services/reportsPageLayout';

import {
  getVehicleFiltersPreferencesIds,
  getServiceZonesFiltersPreferencesIds,
  getSupervisorsFiltersPreferencesIds,
} from 'src/common/utils/filters';

interface ComponentProps {
  excludeVehicleTypes: string[];
  vehicleRoleTypeId: number[];
}

interface Props extends ComponentProps {
  drivers?: any[];
  exportReports: DuckFunction<typeof exportReports>;
  loadDrivers: DuckFunction<typeof loadDrivers>;
  loadVehicles: DuckFunction<typeof loadVehicles>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  reports?: any;
  reset: any;
  resetDailyReportTypes: DuckAction<typeof resetDailyReportTypes>;
  resetDrivers: DuckAction<typeof resetDrivers>;
  resetVehicles: DuckAction<typeof resetVehicles>;
  resetVehicleTypesForVendor: DuckAction<typeof resetVehicleTypesForVendor>;
  serviceZoneIdsCSV?: any;
  supervisorIdsCSV?: any;
  vehicles?: any[];
  vehicleTypeIdsCSV: number[];
  vehicleTypesForVendor?: any[];
  vendorId: number;
}

interface OnSubmitProps {
  customerId?: number;
  customerLocationId?: { locationId: number };
  dateRange: { from: string; to: string };
  driverName: string[];
  reportType: string;
  routeName: string;
  serviceType: string[];
  startDate: string;
  vehicleName: string[];
  vehicleType: string[];
  wasteMaterialType: string[];
}

class ReportPage extends PureComponent<Props> {
  componentWillUnmount() {
    const { resetDailyReportTypes, resetVehicleTypesForVendor, resetVehicles, resetDrivers } = this.props;

    resetDailyReportTypes();
    resetVehicleTypesForVendor();
    resetVehicles();
    resetDrivers();
  }

  onSubmit = ({
    reportType,
    vehicleType,
    vehicleName,
    routeName,
    driverName,
    dateRange: { from, to },
    startDate,
    wasteMaterialType,
    serviceType,
    customerId,
    customerLocationId,
  }: OnSubmitProps) => {
    const {
      drivers,
      exportReports,
      serviceZoneIdsCSV,
      supervisorIdsCSV,
      vehicles = [],
      vehicleTypeIdsCSV,
      vehicleTypesForVendor = [],
      vendorId,
    } = this.props;

    const prePostTripVehicleName: any[] = [];
    if (vehicleName) {
      vehicleName.forEach(item => {
        const vehicle = vehicles.find(vehicle => vehicle.regplate === item);
        prePostTripVehicleName.push(vehicle.id);
      });
    }

    const prePostTripVehicleTypes: any[] = [];
    if (vehicleType) {
      vehicleType.forEach(item => {
        const vehicle = vehicleTypesForVendor.find(vehicle => vehicle.technicalName === item);
        prePostTripVehicleTypes.push(vehicle.id);
      });
    }

    const driverIds: any[] = [];
    if (drivers) {
      drivers.forEach(item => {
        const driver: any[] = (driverName && driverName.filter(driver => driver === item.name)) || [];
        driver && driver.length && driverIds.push(item.id);
      });
    }

    const isVehicleNull = reportType === Y_DEVICE_STATUS || reportType === ROLL_OF_SERVICE_HISTORY;

    const isDriversNull =
      reportType === FAULT_CODES ||
      reportType === ODAKYU_DAILY_ROUTE_SUMMARY ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === RESIDENTIAL_STOP ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === Y_DEVICE_STATUS;

    const isRouteNull =
      reportType === FAULT_CODES ||
      reportType === ODAKYU_DAILY_ROUTE_SUMMARY ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === RESIDENTIAL_STOP ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === STREET_SWEEPING_ACTIVITY_SUMMARY ||
      reportType === STREET_SWEEPING_OBSTACLES ||
      reportType === Y_DEVICE_STATUS;

    const isSingleDateDisplayed =
      reportType === AGING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === DAILY ||
      reportType === DRIVER_SAFETY_INSPECTION ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PRE_POST_TRIP_INSPECTION ||
      reportType === ROLL_OF_SERVICE_HISTORY ||
      reportType === UNSERVICED_RESIDENTIAL ||
      reportType === Y_DEVICE_STATUS;

    const isDataForBillingReportsDisplayed =
      reportType === AGING_REPORT ||
      reportType === BILLING_REPORT ||
      reportType === COLLECTIONS_REPORT ||
      reportType === ORPHAN_INVOICE_REPORT ||
      reportType === PAYMENT_REPORT;

    const getVehicleName = () => {
      if (vehicleName && vehicleName.length === vehicles.length) {
        return null;
      }

      switch (reportType) {
        case PRE_POST_TRIP_INSPECTION:
        case DRIVER_SAFETY_INSPECTION:
          return prePostTripVehicleName.length ? prePostTripVehicleName : null;
        default:
          return vehicleName;
      }
    };

    const getVehicleType = () => {
      switch (reportType) {
        case STREET_SWEEPING_ACTIVITY_SUMMARY:
          return 'StreetSweeper';
        case STREET_SWEEPING_OBSTACLES:
          return 'StreetSweeper';
        case PARTICIPATIONS:
          return 'WasteAudit';
        case SET_OUT:
          return 'WasteAudit';
        case EXCEPTIONS:
          return 'WasteAudit';
        case COMMUNITY_PARTICIPATION:
          return 'WasteAudit';
        case UNSERVICED_RESIDENTIAL:
          return 'Residential';
        case RESIDENTIAL_STOP:
          return 'Residential';
        case ROLL_OF_SERVICE_HISTORY:
          return 'RollOff';
        case PRE_POST_TRIP_INSPECTION:
        case DRIVER_SAFETY_INSPECTION:
          return prePostTripVehicleTypes.length ? prePostTripVehicleTypes : null;
        default:
          return vehicleType;
      }
    };

    exportReports(
      vendorId,
      reportType,
      getVehicleType(),
      isVehicleNull ? null : getVehicleName(),
      isRouteNull ? null : isArray(routeName) ? routeName.toString() : routeName,
      isDriversNull ? null : reportType === DRIVER_SAFETY_INSPECTION ? driverIds : driverName,
      isSingleDateDisplayed ? startDate : from,
      isSingleDateDisplayed ? startDate : to,
      wasteMaterialType,
      serviceType,
      vehicleTypeIdsCSV,
      serviceZoneIdsCSV,
      supervisorIdsCSV,
      isDataForBillingReportsDisplayed ? customerId : undefined,
      isDataForBillingReportsDisplayed ? customerLocationId?.locationId : undefined,
    );
  };

  render() {
    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('insights.dataExport')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <BetaBaseWrapper inline>
              <BetaButton
                noUnderline
                align="right"
                padding="no"
                color="primary"
                margin="sMedium small no no"
                onClick={() => {
                  setReportsNewLayoutActive(true);
                  window.location.pathname = `/insights/beta-reports`;
                }}
              >
                {translate('routes.newLayout')}
              </BetaButton>
            </BetaBaseWrapper>
          </PageActions>
        </PageHeader>

        <Panel>
          <ReportsForm onSubmit={this.onSubmit} />
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { filters } = state.common.filters;
  return {
    vehicles: state.fleet.vehicles.vehicles,
    drivers: state.fleet.drivers.drivers,
    vehicleTypesForVendor: (vehicleTypesForVendorWithRoleTypeSelector as any)(
      state.fleet.vehicleTypesForVendor,
      ownProps.vehicleRoleTypeId,
      ownProps.excludeVehicleTypes,
    ),
    vehicleTypeIdsCSV: getVehicleFiltersPreferencesIds(filters),
    serviceZoneIdsCSV: getServiceZonesFiltersPreferencesIds(filters),
    supervisorIdsCSV: getSupervisorsFiltersPreferencesIds(filters),
    reports: state.insights.reportsList.reports,
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  };
};

const mapDispatchToProps = {
  exportReports,
  loadDrivers,
  loadVehicles,
  loadVehicleTypesForVendor,
  reset,
  resetDailyReportTypes,
  resetDrivers,
  resetVehicles,
  resetVehicleTypesForVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
