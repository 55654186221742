import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, WrappedFieldInputProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../store';
import { Dropdown } from '../../core/components';
import insightsFormInitialValuesSelector from '../services/insightsFormInitialValues';
import translate from '../../core/services/translate';

interface ComponentProps extends RouteComponentProps {
  input: WrappedFieldInputProps;
  placeholder?: string;
  label?: string;
  withPlaceholder?: boolean;
  withLabel?: boolean;
  materialContaminations?: any[];
  dropdownProps?: any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class MaterialContaminationsDropdown extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      label,
      withLabel,
      placeholder,
      withPlaceholder,
      dropdownProps,
      materialContaminations = [
        { label: translate('insights.contaminationSubType.can'), value: 'Can' },
        { label: translate('insights.contaminationSubType.other'), value: 'Other' },
        { label: translate('insights.contaminationSubType.plasticBag'), value: 'Plastic Bag' },
        { label: translate('insights.contaminationSubType.plasticBottle'), value: 'Plastic Bottle' },
        { label: translate('insights.contaminationSubType.plasticFilm'), value: 'Plastic Film' },
      ],
    } = this.props;

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('insights.materialContamination') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('insights.materialContamination') : undefined)}
        component={Dropdown}
        options={materialContaminations}
        onChange={this.onChange}
        dropdownProps={{ id: dropdownProps.id }}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const initialValues = (insightsFormInitialValuesSelector as any)(ownProps.location.search);

  return {
    initialValues,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'materialContaminationsDropdown',
      enableReinitialize: true,
    })(MaterialContaminationsDropdown),
  ),
);
