import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { UnconnectedSwitch } from '../../../core/components';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props {
  isActive: boolean;
  reportType: TechnicalType;
  toggleReportingConfiguration: (id: number, isChecked: any | boolean) => void;
}

const ReportingConfigurationTableRow: React.FC<Props> = ({ isActive, reportType, toggleReportingConfiguration }) => {
  return (
    <TableRow>
      <TableCell width="85%">
        {translate(createTranslationKey(reportType.technicalName, 'insights.dailyReportTypes'))}
      </TableCell>
      <TableCell width="15%" align="right">
        <UnconnectedSwitch
          checked={isActive}
          id={`reporting-settings-${reportType.technicalName}-switch`}
          onChange={(isChecked: boolean) => toggleReportingConfiguration(reportType.id, isChecked)}
        />
      </TableCell>
    </TableRow>
  );
};

export default ReportingConfigurationTableRow;
