import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  loadMaterialTicket,
  loadUnitOfMeasureValues,
  loadMaterialTypeValues,
  loadMaterialLocations,
} from 'src/routes/ducks/materialTickets';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import MaterialTicketEditorModal from './MaterialTicketEditorModal';

type Props = {
  materialTicketId?: number;
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  routeLocationId?: number;
};

export default function MaterialTicketEditorModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    const dependencies = [
      loadUnitOfMeasureValues()(dispatch),
      loadMaterialTypeValues()(dispatch),
      loadMaterialLocations(vendorId)(dispatch),
    ];
    if (props.materialTicketId) dependencies.push(loadMaterialTicket(props.materialTicketId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={MaterialTicketEditorModal}
      successProps={{ ...props }}
    />
  );
}
