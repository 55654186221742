import { http } from '../../core/services/http';
import { transformLoadCustomers as transformSearchCustomers } from '../../routes/services/transformSearchCustomerLocations';

const searchCustomers = (vendorId: number, searchTerm: string, cSVCustomerTypeIds = '1') =>
  http
    .get('/autoDispatch/customers', {
      params: { vendorId, searchTerm, cSVCustomerTypeIds },
    })
    .then(response => transformSearchCustomers(response.data.customers));

export default searchCustomers;
