import styled from 'styled-components';
import { transparentize } from 'polished';

export const Thumbnail = styled.img`
  width: 100%;
  height: auto;
`;

export const ThumbnailSetOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  text-align: center;
  background-color: ${props => transparentize(0.5, props.theme.grayBase)};
  font-size: 14px;
  color: #fff;
`;

export const ThumbnailSet = styled.div<{
  size?: string;
}>`
  position: relative;
  width: ${props => props.size || '40px'};
  height: 0; // make it square
  padding-bottom: ${props => props.size || '40px'};
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
`;
