import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadRouteMapVehicleInsights as doLoadRouteMapVehicleInsights } from '../services/routeMapVehicleInsights';
import {
  RouteMapVehicleInsightsData,
  RouteMapVehicleInsightsFilter,
  RouteMapVehicleInsight,
} from '../interfaces/RouteMapVehicleInsights';

const START_LOAD = 'routes/routeMapVehicleInsights/START_LOAD';
const COMPLETE_LOAD = 'routes/routeMapVehicleInsights/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeMapVehicleInsights/FAIL_LOAD';
const RESET = 'routes/routeMapVehicleInsights/RESET';

interface State {
  filters: RouteMapVehicleInsightsFilter[];
  isLoading: boolean;
  vehicleInsights: RouteMapVehicleInsight[];
}

const initialState: State = {
  filters: [],
  isLoading: false,
  vehicleInsights: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          filters: action.routeMapVehicleInsights.filters,
          vehicleInsights: action.routeMapVehicleInsights.records,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          filters: [],
          vehicleInsights: [],
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeMapVehicleInsights: RouteMapVehicleInsightsData) => ({
  type: COMPLETE_LOAD,
  routeMapVehicleInsights,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRouteMapVehicleInsights = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRouteMapVehicleInsights = doLoadRouteMapVehicleInsights(vendorId, routeId);
  loadRouteMapVehicleInsights
    .then(routeMapVehicleInsights => dispatch(completeLoad(routeMapVehicleInsights)))
    .catch(() => dispatch(failLoad()));
  return loadRouteMapVehicleInsights;
};

export const resetRouteMapVehicleInsights = () => ({
  type: RESET,
});
