import React from 'react';

import { FormGroup, FormLabel } from './styled';
import { FakeInput } from './styled/Input';

interface Props {
  flex?: boolean;
  label?: string;
  margin?: string;
  noWrap?: boolean;
  noWrapLabel?: boolean;
  size?: 'small' | 'normal';
  value?: string | number | null;
  width?: string;
}

const LabeledDataView: React.SFC<Props> = ({
  children,
  flex,
  label,
  margin,
  noWrap,
  noWrapLabel,
  size,
  value,
  width,
}) => (
  <FormGroup raisedLabel margin={margin || 'no'} width={width} size={size} flex={flex}>
    {!!label && (
      <FormLabel size={size} noWrapLabel={noWrapLabel}>
        {label}
      </FormLabel>
    )}

    <FakeInput noWrap={noWrap} size={size}>
      {value ?? children}
    </FakeInput>
  </FormGroup>
);

export default LabeledDataView;
