import React from 'react';

interface CommonProps {
  onClick: () => void;
  id?: string;
}

const Svg = ({ children, id, onClick }: CommonProps & { children: any }) => (
  <svg id={id} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
    {children}
  </svg>
);

export const Play: React.SFC<CommonProps & { isPlaying: boolean }> = ({ isPlaying, ...props }) => (
  <Svg {...props}>
    {!isPlaying ? (
      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 18v-12l10 6-10 6z" />
    ) : (
      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17h-3v-10h3v10zm5-10h-3v10h3v-10z" />
    )}
  </Svg>
);

export const FastForward: React.SFC<CommonProps> = props => (
  <Svg {...props}>
    <path d="M0 19v-14l12 7-12 7zm12 0v-14l12 7-12 7z" />
  </Svg>
);

export const FastReverse: React.SFC<CommonProps> = props => (
  <Svg {...props}>
    <path d="M12 12l12-7v14l-12-7zm-12 0l12-7v14l-12-7z" />
  </Svg>
);
