import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { ModalSearch as ModalSearchContainer, ModalSearchIcon, ModalSearchInput } from './styled';
import translate from '../services/translate';

const ModalSearch: React.FC<WrappedFieldProps> = ({ input, ...props }) => (
  <ModalSearchContainer>
    <ModalSearchIcon />
    <ModalSearchInput {...input} placeholder={translate('common.search')} {...props} />
  </ModalSearchContainer>
);

export default ModalSearch;
