import { useState } from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';

type DownloadProgress = {
  loaded: number;
  total: number;
  percent: number;
};

type DownloadFileWithProgress = {
  download: (url: string, fileName: string) => void;
  progress: DownloadProgress;
  isDownloading: boolean;
};

const useDownloadFileWithProgress = (): DownloadFileWithProgress => {
  const [progress, setProgress] = useState<DownloadProgress>({
    loaded: 0,
    total: 0,
    percent: 0,
  });

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const download = (url: string, fileName: string) => {
    setIsDownloading(true);
    setProgress({ loaded: 0, total: 0, percent: 0 });
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total || 0;
        const percent = Math.round((loaded / total) * 100);

        setProgress({ loaded, total, percent });
      },
    }).then(response => {
      FileSaver.saveAs(response.data, fileName);
      setIsDownloading(false);
    });
  };

  return { download, progress, isDownloading };
};

export default useDownloadFileWithProgress;
