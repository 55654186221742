import { identity, get, has } from 'lodash-es';
import { createSelector } from 'reselect';

import { AVAILABLE, UNAVAILABLE } from '../constants/status';
import { currentVendorIdSelector } from '../../vendors/services/currentVendorSelector';
import { DRIVER, DRIVER_SUPERVISOR, SUPERVISOR } from '../constants/resourceRoleTypes';
import { TODAY } from 'src/core/constants';

const getDriverEditorFormInitialValues = (state: any) => {
  const vendorId = currentVendorIdSelector(state.account.login, state.vendors.defaultVendor);
  const driver = get(state.fleet.driver, 'driver', {});

  return {
    resourceRoleType: {},
    ...driver,
    vendorId,
    languageId: driver.languageId || 1,
    isActive: has(driver, 'isActive') ? driver.isActive : true,
    isAvailable: driver.isAvailable || !driver.id ? AVAILABLE : UNAVAILABLE,
    isDriver:
      driver &&
      driver.resourceRoleType &&
      (driver.resourceRoleType.id === DRIVER || driver.resourceRoleType.id === DRIVER_SUPERVISOR),
    isSupervisor:
      driver &&
      driver.resourceRoleType &&
      (driver.resourceRoleType.id === SUPERVISOR || driver.resourceRoleType.id === DRIVER_SUPERVISOR),
    driverUnavailableEndDate: driver.driverUnavailable?.endDate,
    driverUnavailableInitialValues: driver.driverUnavailable,
    driverUnavailableStartDate: driver.driverUnavailable?.startDate || TODAY,
  };
};

const driverEditorFormInitialValuesSelector = createSelector(getDriverEditorFormInitialValues, identity);

export default driverEditorFormInitialValuesSelector;
