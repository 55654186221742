import React, { PureComponent } from 'react';

import { map } from 'lodash-es';
import { Field, WrappedFieldInputProps } from 'redux-form';

import { CONFIRMATION_MODES } from '../constants';
import { Dropdown } from '../../core/components';
import translate from '../../core/services/translate';

interface Props {
  dropdownProps?: any;
  input: WrappedFieldInputProps;
  label?: string;
  placeholder?: string;
  withLabel?: boolean;
  withPlaceholder?: boolean;
}

class ConfirmationModeDropdown extends PureComponent<Props> {
  onChange = (event: any, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
    } = this.props;

    const confirmationModeOptions = map(CONFIRMATION_MODES, confirmationMode => ({
      label: confirmationMode.name,
      value: confirmationMode.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('vendors.confirmationMode') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('vendors.confirmationMode') : undefined)}
        component={Dropdown}
        options={confirmationModeOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

export default ConfirmationModeDropdown;
