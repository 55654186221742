import axios from 'axios';
import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import translate from 'src/core/services/translate';
import { ContainerStatistics } from '../interfaces/ContainerStatistics';

import { loadContainerStatistics as doLoadContainersStatistics } from '../services/containersStatistics';

// Actions
const START_LOAD = 'fleet/containers/containersStatistics/START_LOAD';
const COMPLETE_LOAD = 'fleet/containers/containersStatistics/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containers/containersStatistics/FAIL_LOAD';
const RESET = 'fleet/containers/containersStatistics/RESET';

const CancelToken = axios.CancelToken;
let cancelLoadContainerStatistics: any;

// Initial state
const initialState: {
  isLoading: boolean;
  containersStatistics: ContainerStatistics | null;
} = {
  isLoading: false,
  containersStatistics: null,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersStatistics: action.containersStatistics,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containersStatistics: null,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containersStatistics: any) => ({
  type: COMPLETE_LOAD,
  containersStatistics,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainersStatistics =
  (
    vendorId: number,
    containerTypeId?: number,
    equipmentTypeId?: number,
    equipmentSizeId?: number,
    equipmentConditionId?: number,
    searchTerm?: string,
    locationId?: number,
  ) =>
  (dispatch: Dispatch) => {
    // cancel previous call if another one was made
    if (cancelLoadContainerStatistics) cancelLoadContainerStatistics(translate('common.inFlightRequestCanceled'));

    dispatch(startLoad());

    const cancelToken = new CancelToken(c => {
      cancelLoadContainerStatistics = c;
    });

    const loadContainersStatisticsPromise = doLoadContainersStatistics(
      vendorId,
      containerTypeId,
      equipmentTypeId,
      equipmentSizeId,
      equipmentConditionId,
      searchTerm,
      locationId,
      cancelToken,
    );
    loadContainersStatisticsPromise
      .then((containersStatistics: any) => dispatch(completeLoad(containersStatistics)))
      .catch(err => {
        if (!axios.isCancel(err)) dispatch(failLoad());
      });
    return loadContainersStatisticsPromise;
  };

export const resetContainersStatistics = () => ({
  type: RESET,
});
