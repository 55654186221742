import React, { PureComponent, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnregisterCallback } from 'history';

import Confirmation from '../../core/components/Confirmation';
import translate from '../../core/services/translate';

interface Props extends RouteComponentProps {
  when: boolean;
  onPageLeave(...args: any[]): any;
}

interface State {
  nextLocation: any;
  openModal: boolean;
}

class HaulerProfileNavigationPrompt extends PureComponent<Props, State> {
  readonly state: State = {
    nextLocation: null,
    openModal: false,
  };

  unblock: UnregisterCallback = () => {};

  componentDidMount() {
    this.unblock = this.props.history.block(nextLocation => {
      const { when } = this.props;

      if (when) {
        this.setState({
          openModal: true,
          nextLocation,
        });

        return !when;
      }
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  onCancel = () => this.setState({ nextLocation: null, openModal: false });
  onConfirm = () => this.navigateToNextLocationWithSaving();

  navigateToNextLocationWithoutSaving = () => {
    const { nextLocation } = this.state;

    if (nextLocation) {
      this.props.history.push(nextLocation.pathname);
      this.setState({ nextLocation: null, openModal: false });
    }
  };

  navigateToNextLocationWithSaving = () => {
    const { nextLocation } = this.state;

    if (nextLocation) {
      this.unblock();
      this.props.onPageLeave();
      this.props.history.push(nextLocation.pathname);
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.openModal && (
          <Confirmation
            title={translate('haulerProfile.alertMessages.confirmChanges')}
            resolve={this.onConfirm}
            reject={this.onCancel}
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(HaulerProfileNavigationPrompt);
