import React from 'react';
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form';

import { Checkbox } from '../../../../core/components';
import { Grid, GridColumn, Message, Panel, PanelSectionTitle } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';

const ServicesFieldArray: React.FC<WrappedFieldArrayProps> = ({ fields }) => (
  <Panel margin="medium no" padding="large medium">
    <PanelSectionTitle>{translate('haulerProfile.servicesOffered')}</PanelSectionTitle>

    <Grid multiLine>
      {fields.map((field, index) => (
        <GridColumn
          size="4/12"
          tabletSize="4/12"
          padding="no medium medium no"
          tabletPadding="no medium small no"
          key={index}
        >
          <Field name={`${field}.isSelected`} component={Checkbox} label={fields.get(index).displayTitle} />
        </GridColumn>
      ))}
    </Grid>

    {!fields.length && <Message padding="no">{translate('haulerProfile.noServices')}</Message>}
  </Panel>
);

const ServicesOffered = () => <FieldArray name="availableServices" component={ServicesFieldArray} props={{}} />;

export default ServicesOffered;
