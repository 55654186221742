import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { VEHICLE_ICON_TYPES } from 'src/common/constants/vehicleIcons';
import { useSelector } from 'src/core/hooks/useSelector';
import { DASHBOARD_CLUSTERS_SOURCE, DASHBOARD_VEHICLE_POSITIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { RouteVehiclePosition } from 'src/dashboard/interfaces/routesData';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import DashboardVehiclePositionsGLLayers from './DashboardVehiclePositionsGLLayers';
import DashboardVehiclePositionsGLPopupResolver from './DashboardVehiclePositionsGLPopupResolver';

const mapImages = VEHICLE_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: DASHBOARD_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
  isSnowSweeperDashboard?: boolean;
}

const DashboardVehiclePositionsGL: FC<Props> = ({ map, isSnowSweeperDashboard }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();
  const vehiclePositions = useSelector(state => (state.dashboard.vehiclesData.vehiclesList || []) as VehiclePosition[]);

  const routeVehiclePositions = useSelector(
    state => (state.dashboard.routesData.routeVehiclePositions || []) as RouteVehiclePosition[],
  );

  const sosAlertVehicleId = useSelector(state => state.dashboard.mapControls.sosAlertVehicleId);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_CLUSTERS_SOURCE &&
              feature.properties?.layer === DASHBOARD_VEHICLE_POSITIONS_LAYER,
          );

        if (!!feature) {
          dispatch(setDashboardSelectedFeature('vehiclePositions', feature.id as number));
        }
      });
    });
  }, [map, dispatch]);

  if (!vehiclePositions.length && !routeVehiclePositions.length) {
    return null;
  }

  return (
    <>
      <DashboardVehiclePositionsGLLayers sosAlertVehicleId={sosAlertVehicleId} />
      <DashboardVehiclePositionsGLPopupResolver isSnoSweeperDashboard={isSnowSweeperDashboard} />
    </>
  );
};

export default DashboardVehiclePositionsGL;
