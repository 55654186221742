import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeMobilePageTitle } from 'src/core/ducks/mobilePage';

interface Props {
  children: string;
}

const DocumentTitle: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `${children} - Rubicon X`;
    changeMobilePageTitle(children)(dispatch);
  }, [children, dispatch]);

  return null;
};

export default DocumentTitle;
