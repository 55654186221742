import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadHoursOfOperation as doLoadHoursOfOperation,
  saveHoursOfOperation as doSaveHoursOfOperation,
} from '../services/overview';

// Actions
const START_LOAD = 'haulerProfile/hoursOfoperation/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/hoursOfoperation/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/hoursOfoperation/FAIL_LOAD';
const START_SAVE = 'haulerProfile/hoursOfoperation/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/hoursOfoperation/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/hoursOfoperation/FAIL_SAVE';
const RESET = 'haulerProfile/hoursOfoperation/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  hoursOfOperation: undefined,
  isValidGusId: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          hoursOfOperation: action.hoursOfOperation,
          isValidGusId: true,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          hoursOfOperation: action.hoursOfOperation,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          ...initialState,
          isValidGusId: state.isValidGusId,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (hoursOfOperation: any) => ({
  type: COMPLETE_LOAD,
  hoursOfOperation,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (hoursOfOperation: any) => ({
  type: COMPLETE_SAVE,
  hoursOfOperation,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadHoursOfOperation = (haulerId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadHoursOfOperationPromise = doLoadHoursOfOperation(haulerId);
  loadHoursOfOperationPromise
    .then(hoursOfOperation => dispatch(completeLoad(hoursOfOperation)))
    .catch(() => dispatch(failLoad()));
  return loadHoursOfOperationPromise;
};

export const saveHoursOfOperation = (haulerId: string, data: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveHoursOfOperationPromise = doSaveHoursOfOperation(haulerId, data);
  saveHoursOfOperationPromise.then(() => dispatch(completeSave(data))).catch(() => dispatch(failSave()));
  return saveHoursOfOperationPromise;
};

export const resetHoursOfOperation = () => ({
  type: RESET,
});
