import update from 'immutability-helper';
import { filter, find, get, identity } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { DELIVERY_UTILITY_ID, RESIDENTIAL_ID } from '../../fleet/constants';
import { TechnicalType } from '../interfaces/TechnicalType';
import doLoadWasteTypes from '../services/loadWasteTypes';

// Actions
const START_LOAD = 'common/wasteTypes/START_LOAD';
const COMPLETE_LOAD = 'common/wasteTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/wasteTypes/FAIL_LOAD';
const RESET = 'common/wasteTypes/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  wasteTypes: TechnicalType[];
} = {
  isLoading: false,
  wasteTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteTypes: action.wasteTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (wasteTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  wasteTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadWasteTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadWasteTypesPromise = doLoadWasteTypes();
  loadWasteTypesPromise.then(wasteTypes => dispatch(completeLoad(wasteTypes))).catch(() => dispatch(failLoad()));
  return loadWasteTypesPromise;
};

export const resetWasteTypes = () => ({
  type: RESET,
});

const getTechnicalNameByWasteTypeId = (wasteTypes: { wasteTypes: TechnicalType[] }, wasteTypeId: number): string => {
  const wasteType = find(wasteTypes.wasteTypes, { id: wasteTypeId });
  return get(wasteType, 'technicalName', '');
};

export const technicalNameByWasteTypeIdSelector = createSelector(getTechnicalNameByWasteTypeId, identity);

export const filterWasteTypesByVehicleType = (wasteTypes: TechnicalType[], vehicleTypeId: number): TechnicalType[] => {
  if (vehicleTypeId === DELIVERY_UTILITY_ID) {
    return (
      filter(
        wasteTypes,
        wt => wt.technicalName === 'SpecialWaste' || wt.technicalName === 'TrashMSW' || wt.technicalName === 'Unknown',
      ) || []
    );
  } else if (vehicleTypeId === RESIDENTIAL_ID) {
    return (
      filter(
        wasteTypes,
        wt =>
          wt.technicalName === 'SingleStreamRecycling' ||
          wt.technicalName === 'TrashMSW' ||
          wt.technicalName === 'YardWaste',
      ) || []
    );
  }

  return wasteTypes || [];
};
