import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { DisposalSummary } from '../interfaces/DisposalSummary';
import { loadDisposalsSummary as doLoadDisposalsSummary } from '../services/disposalsSummary';

// Actions
const START_LOAD = 'routes/disposalsSummary/START_LOAD';
const COMPLETE_LOAD = 'routes/disposalsSummary/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/disposalsSummary/FAIL_LOAD';

interface State {
  isLoading: boolean;
  disposalsSummary: DisposalSummary[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  disposalsSummary: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          disposalsSummary: action.disposalsSummary,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          disposalsSummary: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (disposalsSummary: DisposalSummary) => ({
  type: COMPLETE_LOAD,
  disposalsSummary,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDisposalsSummary =
  (
    vendorId: number,
    searchTerm: string,
    vehicleTypeIds: number[],
    routeStatusTypeIds: number[],
    startDate: Date | string,
    endDate: Date | string,
    useUserGlobalFilters?: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const disposalsSummaryPromise = doLoadDisposalsSummary(
      vendorId,
      searchTerm,
      vehicleTypeIds,
      routeStatusTypeIds,
      startDate,
      endDate,
      useUserGlobalFilters,
    );
    disposalsSummaryPromise
      .then(disposalsSummary => dispatch(completeLoad(disposalsSummary)))
      .catch(() => dispatch(failLoad()));
    return disposalsSummaryPromise;
  };
