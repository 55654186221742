import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash-es';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { push } from 'connected-react-router';
import { withRouter, RouteComponentProps } from 'react-router';
import Cookie from 'js-cookie';
import moment from 'moment';

import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { DATE_RANGE_PICKER_3_7_30, TODAY, TODAY_FORMATTED } from '../../../core/constants/weekdays';
import { DateRangePicker, PanelSearch } from '../../../core/components';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { INSIGHTS_COOKIE_DATE_KEY, INSIGHTS_COOKIE_START_DATE_KEY } from '../../constants';
import { COMMUNITY_INSIGHTS_MAX_SEARCHABLE_MONTHS } from '../../constants/insightStatus';
import { InsightsMultiSelectDropdown, StatusMultiSelectDropdown } from '..';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { CityInsightType } from '../../../dashboard/interfaces/cityInsightTypes';

interface ComponentProps extends RouteComponentProps {
  onSearchTermChange(term: string): void;
  onInsightsChange(insightIds: null | number[]): void;
  onStatusesChange(statusIds: null | number[]): void;
}

interface ReduxProps extends ComponentProps {
  cityInsightActiveTypes: CityInsightType[];
  push: any;
}

type Props = ReduxProps & InjectedFormProps<any, ReduxProps>;

class CommunityInsightsForm extends PureComponent<Props> {
  onInsightSearchTermChanged = (event: any, searchTerm: string) => {
    const { onSearchTermChange } = this.props;

    onSearchTermChange(searchTerm);
  };

  onInsightsChanged = (event: any, insightIds: string[]) => {
    const { onInsightsChange, cityInsightActiveTypes } = this.props;

    if (insightIds.length === cityInsightActiveTypes.length) {
      return onInsightsChange(null);
    }

    onInsightsChange(insightIds.map(id => Number(id)));
  };

  onStatusChanged = (event: any, statusIds: string[]) => {
    const { onStatusesChange } = this.props;

    onStatusesChange(statusIds.map(id => Number(id)));
  };

  onDateChanged = (event: any, { from, to }: { from: string; to: string }) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;

    Cookie.set(INSIGHTS_COOKIE_START_DATE_KEY, from);
    Cookie.set(INSIGHTS_COOKIE_DATE_KEY, to);

    push(createUrl(pathname, search, { startDate: from, endDate: to }));
  };

  render() {
    const { cityInsightActiveTypes } = this.props;

    return (
      <form>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="4/12">
              <Field
                name="insightSearchTerm"
                component={PanelSearch as any}
                onChange={this.onInsightSearchTermChanged}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                component={InsightsMultiSelectDropdown as any}
                multiSelectProps={{ margin: 'no' }}
                name="insightIds"
                onChange={this.onInsightsChanged}
                dropDownOptions={cityInsightActiveTypes}
                placeholder={translate('insights.allInsights')}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                component={StatusMultiSelectDropdown as any}
                multiSelectProps={{ margin: 'no' }}
                name="statusIds"
                onChange={this.onStatusChanged}
                placeholder={translate('insights.allStatuses')}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="date"
                alignRight
                component={DateRangePicker as any}
                disabledDays={[
                  {
                    before: moment().subtract(COMMUNITY_INSIGHTS_MAX_SEARCHABLE_MONTHS, 'months').toDate(),
                    after: TODAY,
                  },
                ]}
                margin="no"
                onChange={this.onDateChanged}
                options={DATE_RANGE_PICKER_3_7_30}
                placeholder={translate('insights.select')}
                props={{ id: 'insights-community-date-range-picker', hasMarginLeft: 'smaller' }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);
  const insightsStartDate = Cookie.get(INSIGHTS_COOKIE_START_DATE_KEY);

  const { startDate, endDate, insightSearchTerm, statusIds, insightIds } = getQueryParams(ownProps.location.search);

  return {
    cityInsightActiveTypes: state.dashboard.filters.cityInsightActiveTypes,
    initialValues: {
      date: {
        from: startDate || insightsStartDate || insightsDate || TODAY_FORMATTED,
        to: endDate || insightsDate || TODAY_FORMATTED,
      },
      insightSearchTerm,
      statusIds: statusIds && map(statusIds.toString().split(','), Number),
      insightIds: insightIds && map(insightIds.toString().split(','), Number),
    },
  };
};

const mapDispatchToProps = { push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, ReduxProps>({
      form: 'communityInsights',
      enableReinitialize: true,
    })(CommunityInsightsForm),
  ),
);
