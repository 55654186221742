import { http } from 'src/core/services/http';

export const getVehiclesInProximity = (formData: {
  latitude: number;
  longitude: number;
  radius: number;
  startTime?: string;
  endTime?: string;
  date?: Date | string;
  vendorId: number;
}) =>
  http.post('/vehicles/inProximity', formData).then(response => response.data);
