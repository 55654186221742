import { map } from 'lodash-es';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import translate from 'src/core/services/translate';
import { ROUTE_ALL_PRIORITY_TYPES_OPTIONS } from 'src/routes/constants/routePriorityTypes';
import { SNOW_SWEEPER_FILTERS_FORM_NAME, SnowSweeperFiltersFormValues } from '../SnowSweeperFiltersForm';
import SnowSweeperFilterItem from './SnowSweeperFilterItem';
import { getNumberOfActiveFilters } from './utils';

const RoutePriorityFilterSection = () => {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      const priorityTypeIds: any = {
        all: checked,
      };
      map(ROUTE_ALL_PRIORITY_TYPES_OPTIONS, priority => {
        priorityTypeIds[`_${priority.value}`] = checked;
      });
      dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'priorityTypeIds', priorityTypeIds));
    },
    [dispatch],
  );

  const handleCheckAny = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValue, prevValue, name) => {
      // uncheck the all checkbox if any of the other checkboxes are unchecked
      if (!newValue) dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'priorityTypeIds.all', false));
      else if (getNumberOfActiveFilters(formValues?.priorityTypeIds) === ROUTE_ALL_PRIORITY_TYPES_OPTIONS.length - 1)
        dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'priorityTypeIds.all', true));
    },
    [dispatch, formValues?.priorityTypeIds],
  );

  return (
    <>
      <SnowSweeperFilterItem name="priorityTypeIds.all" label={translate('common.all')} onChange={handleCheckAll} />

      {map(ROUTE_ALL_PRIORITY_TYPES_OPTIONS, priority => (
        <SnowSweeperFilterItem
          key={priority.value}
          name={`priorityTypeIds._${priority.value}`}
          label={priority.label}
          onChange={handleCheckAny}
        />
      ))}
    </>
  );
};

export default RoutePriorityFilterSection;
