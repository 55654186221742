import styled from 'styled-components';

export const RouteLocationsLoaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 70%;
  margin: auto;
`;

export const TextContainer = styled.div`
  margin-bottom: 20px;
`;
