import { camelize } from 'humps';
import { find } from 'lodash-es';
import { useMemo } from 'react';
import { getFormValues } from 'redux-form';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { MapboxFiltersFormValues } from '../../forms/DashboardMapboxFiltersForm';
import { DashboardMapDataInformationWrapper } from '../../styled';

const MapModalDataInformation = () => {
  const mainFormValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as MapboxFiltersFormValues;
  const vehiclesPositions = useSelector(state => state.dashboard.vehiclesData.vehiclesList);

  const routeVehicles = useSelector(state => state.dashboard.routesData.routeVehiclePositions);

  const lastRefreshedVehicles = useSelector(state => state.dashboard.vehiclesData.lastRefreshed);
  const lastRefreshedRoutes = useSelector(state => state.dashboard.routesData.lastRefreshed);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedModalMapFeature);
  const isSatelliteView = useSelector(state => state.dashboard.mapControls.isModalMapSatelliteEnabled);

  const gpsSource = useMemo(() => {
    if (selectedFeature?.namespace === 'vehiclePositions') {
      const vehicle = find(vehiclesPositions, v => v.id === selectedFeature.id);
      const routeVehicle = find(routeVehicles, v => v.vehicleId === selectedFeature.id);
      return vehicle?.sourceDevice ?? routeVehicle?.sourceDevice ?? '';
    }
    return '';
  }, [selectedFeature, vehiclesPositions, routeVehicles]);

  const dataToShow = useMemo(
    () => ({
      lastRefreshed: mainFormValues.activeTab === 'routes' ? lastRefreshedRoutes : lastRefreshedVehicles,
      gpsSource,
    }),
    [mainFormValues.activeTab, gpsSource, lastRefreshedRoutes, lastRefreshedVehicles],
  );

  return (
    <ComplexMapControl position="top-left">
      <DashboardMapDataInformationWrapper color={isSatelliteView ? 'white' : 'black'}>
        <Text block>
          {translate('dashboard.filterKeys.lastRefreshed')}: <span>{dataToShow?.lastRefreshed}</span>
        </Text>
        {dataToShow?.gpsSource && (
          <Text block>
            {translate('dashboard.filterKeys.gpsSource')}:{' '}
            <span>{translate(`dashboard.filterKeys.gpsSources.${camelize(dataToShow.gpsSource)}`)}</span>
          </Text>
        )}
      </DashboardMapDataInformationWrapper>
    </ComplexMapControl>
  );
};

export default MapModalDataInformation;
