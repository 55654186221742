import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { CUSTOMER_SUBTYPES } from 'src/common/constants';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
} from 'src/common/components/map/MapGLPopup';
import { useSelector } from 'src/core/hooks/useSelector';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

export default function RouteMapHaulerLocationsGLPopup() {
  const dispatch = useDispatch();
  const { vendorLocations: haulerLocations } = useSelector(state => state.dashboard.vendorLocations);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const haulerLocation = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.vendorLocations) {
      return undefined;
    }

    return haulerLocations.find(location => location.id === selectedFeature.id);
  }, [selectedFeature, haulerLocations]);

  if (!haulerLocation) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={haulerLocation.latitude}
      longitude={haulerLocation.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle title={haulerLocation.locationName} />

        <MapGLPopupContent>
          {!!haulerLocation.customerType && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.locationType')}
              value={CUSTOMER_SUBTYPES[haulerLocation.customerSubTypeId].name}
            />
          )}

          {!!haulerLocation.address && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('insights.address')}
              value={haulerLocation.address}
            />
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
