import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { map, groupBy, keys } from 'lodash-es';

import { AppState } from 'src/store';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  routeLocations: any[];
}

class RouteCustomerDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      routeLocations,
      dropdownProps,
    } = this.props;

    const customers = groupBy(routeLocations, customer => customer.customerName);

    const customerOptions = map(keys(customers), customerKey => ({
      label: customerKey,
      options: map(customers[customerKey], ({ id, locationName }) => ({
        value: id,
        label: locationName,
      })),
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.customer') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('common.customer') : undefined),
          options: customerOptions as any,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  routeLocations: state.routes.routeStops.routeStops || [],
});

export default connect(mapStateToProps)(RouteCustomerDropdown);
