import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { isArray, map } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';
import DatePickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';

import { AppState } from 'src/store';
import { CALENDAR_INPUT_LENGTH } from '../constants/calendar';
import { CALENDAR_WEEKDAYS, MONTHS_SHORT } from '../../common/constants';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DataRetention } from 'src/vendors/interfaces/DataRetention';
import { DatePicker as DatePickerContainer, FormError, FormGroup, FormGroupClear, FormLabel } from './styled';
import { DuckFunction } from 'src/contracts/ducks';
import { getMaxSearchableMonths } from './DateRangePicker';
import { HorizontalAlign, VerticalAlign } from './styled/DatePicker';
import { loadDataRetention } from 'src/vendors/ducks';
import { MAX_SEARCHABLE_MONTHS } from '../../core/constants';
import { onCalendarInputKeyDown } from '../services/calendar';
import { TODAY, TOMORROW } from '../../core/constants';
import FormMeta from './styled/FormMeta';
import translate from '../../core/services/translate';

interface Props {
  customProp?: any;
  dataRetention: DataRetention[];
  disabled?: boolean;
  disabledDays?: any[];
  disableFormSubmitOnDateChange?: boolean;
  errorOnSingleLine?: boolean;
  fieldShouldClear?: boolean;
  horizontalAlign?: HorizontalAlign;
  id?: string;
  input: WrappedFieldInputProps;
  inputDefaultValue?: Date | string;
  isClearable?: boolean;
  isTodayWithNoStyle?: boolean;
  isValueHidden?: boolean;
  label?: string;
  loadDataRetention: DuckFunction<typeof loadDataRetention>;
  margin?: string;
  mask?: string;
  meta: any;
  metaInfo?: string;
  name?: string;
  onDateChange?: (date: Date | string) => void;
  placeholder?: string;
  raisedLabel?: boolean;
  shouldNotLoadDataRetention?: boolean;
  showErrorBeforeSubmit?: boolean;
  tabletAlignHalfLeft?: boolean;
  tabletAlignLeft?: boolean;
  vendorId?: number;
  verticalAlign?: VerticalAlign;
  width?: string;
}

interface State {
  currentMonth?: Date | string;
  hasChangedValue: boolean;
  inputValue: Date | string | Object;
  inputValueLength?: number;
  isDateValid: boolean;
  isLabelRaised?: boolean;
  maxSearchableMonths: number;
}

class DatePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      input: { value: inputValue },
      placeholder,
    } = this.props;

    this.state = {
      currentMonth: moment(inputValue).toDate(),
      hasChangedValue: false,
      inputValue: moment(inputValue).isValid() ? moment(inputValue).format('MM/DD/YYYY') : inputValue,
      inputValueLength: inputValue.length,
      isDateValid: true,
      isLabelRaised: !!inputValue.length || !!placeholder,
      maxSearchableMonths: MAX_SEARCHABLE_MONTHS,
    };
  }

  componentDidMount() {
    const { loadDataRetention, vendorId, shouldNotLoadDataRetention, dataRetention } = this.props;

    const setMaxSearchableMonths = (response: DataRetention[]) => {
      const dataRetentionValue = getMaxSearchableMonths(response);
      this.setState({ maxSearchableMonths: dataRetentionValue });
    };

    if (vendorId) {
      shouldNotLoadDataRetention
        ? setMaxSearchableMonths(dataRetention)
        : loadDataRetention().then(response => {
            setMaxSearchableMonths(response);
          });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { fieldShouldClear } = this.props;

    if (fieldShouldClear && fieldShouldClear !== prevProps.fieldShouldClear) {
      this.clearInput();
    }
  }

  onDayChange = (date: Date | string | Object, _: any, input?: DatePickerInput) => {
    const { onDateChange, placeholder, input: inputProps } = this.props;

    this.setState({ inputValueLength: input?.getInput().value.length });
    input?.getInput().value.length === 0 && this.setState({ isLabelRaised: !!placeholder });

    if (
      date &&
      moment(date).format('MM/DD/YYYY').length === CALENDAR_INPUT_LENGTH &&
      input?.getInput().value.length === CALENDAR_INPUT_LENGTH
    ) {
      const newValue = moment(date).format('MM/DD/YYYY');
      inputProps.onChange(newValue);
      this.setState({ inputValue: newValue, isDateValid: true, hasChangedValue: true });

      if (typeof onDateChange === 'function') {
        onDateChange(newValue);
      }
    } else if (input?.getInput().value.length === 0) {
      inputProps.onChange(null);
      this.setState({
        inputValue: '',
        inputValueLength: 0,
        isLabelRaised: !!placeholder,
        isDateValid: false,
      });
    }
  };

  onBlur = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as any).value;
    const { disableFormSubmitOnDateChange, disabledDays, input } = this.props;
    const { maxSearchableMonths, inputValue, hasChangedValue, inputValueLength } = this.state;

    let isDateValid = moment(value || inputValue, 'MM/DD/YYYY', true).isValid();
    if (value.length === 0 && inputValue.toString().length === 0) isDateValid = true;
    this.setState({ isDateValid });

    const isAfter =
      disabledDays &&
      disabledDays[0].after &&
      moment(moment(disabledDays[0].after).format('MM/DD/YYYY')).diff(moment(value), 'days') < 0;
    const isBefore =
      (disabledDays && disabledDays[0].before && moment(value).diff(moment(disabledDays[0].before), 'days') < 0) ||
      moment(value).diff(moment().subtract(maxSearchableMonths, 'months').subtract(1, 'days').toDate()) < 0;

    if (isDateValid && (isAfter || isBefore) && hasChangedValue) {
      const firstEnabledDay = disabledDays
        ? moment(disabledDays[0].before).format('MM/DD/YYYY')
        : moment(TODAY).format('MM/DD/YYYY');

      if (firstEnabledDay) {
        this.setState({ inputValue: firstEnabledDay, hasChangedValue: true });
        input.onChange(firstEnabledDay);
      }
    }

    if (!isDateValid && !disableFormSubmitOnDateChange) {
      this.setState({ inputValue: value, hasChangedValue: true });
      input.onChange(value);
    }

    if (
      value &&
      ((!moment(input.value).isValid() && !moment(inputValue).isValid()) ||
        (inputValueLength && inputValueLength > 10) ||
        (inputValueLength === 0 && input.value.length === 0) ||
        input.value.length > 10)
    ) {
      this.setState({
        inputValue: moment().format('MM/DD/YYYY'),
        inputValueLength: 10,
        isDateValid: true,
        hasChangedValue: true,
      });
      input.onChange(moment().format('MM/DD/YYYY'));
    }
  };

  clearInput = () => {
    const currentMonth = new Date(moment().year(), moment().month());
    const { placeholder, input } = this.props;

    this.setState({
      inputValue: '',
      currentMonth,
      isLabelRaised: !!placeholder,
      inputValueLength: 0,
      isDateValid: true,
      hasChangedValue: true,
    });
    input.onChange(null);
  };

  parseDate = (str: string, format: string) => {
    const date = moment(str, format, true);
    return date.isValid() ? date.toDate() : undefined;
  };

  formatDate = (date: Date | string, format: string) => moment(date).format(format);

  onInputChange = (ev: any) => {
    const str = ev.target.value;
    const {
      input,
      input: { value },
      disabledDays,
      placeholder,
    } = this.props;
    const { maxSearchableMonths } = this.state;

    str.length > 0
      ? this.setState({ isLabelRaised: true })
      : str.length === 0 && this.setState({ isLabelRaised: !!placeholder });
    if (str.length !== CALENDAR_INPUT_LENGTH) return;

    const currentDate = TODAY;
    const nextDay = TOMORROW;

    const isAfter =
      disabledDays && disabledDays[0].after
        ? moment(disabledDays[0].after).diff(moment(str), 'days') < 0
        : Math.abs(moment(currentDate).diff(moment(str), 'months')) > maxSearchableMonths;
    const isBefore =
      disabledDays && disabledDays[0].before
        ? moment(str).diff(moment(disabledDays[0].before), 'days') < 0
        : moment(str).diff(moment().subtract(maxSearchableMonths, 'months').toDate()) < 0;

    const resetToDay =
      disabledDays &&
      disabledDays[0].before !== currentDate &&
      moment(disabledDays[0].after).diff(moment(str), 'days') > -2
        ? nextDay
        : currentDate;
    const newValue = (isAfter || isBefore) && typeof value === 'string' ? resetToDay : str;
    const date = moment(newValue);
    input.onChange(date.format('MM/DD/YYYY'));
    this.setState({ inputValue: date.format('MM/DD/YYYY'), hasChangedValue: true });

    if (!date.isValid()) {
      this.setState({ inputValue: '', isLabelRaised: !!placeholder, hasChangedValue: true });
      input.onChange(null);
    }
  };

  render() {
    const {
      disabled,
      disabledDays = [],
      errorOnSingleLine,
      horizontalAlign,
      id,
      input,
      inputDefaultValue,
      isClearable,
      isTodayWithNoStyle,
      isValueHidden,
      label,
      margin,
      mask,
      meta: { submitFailed, error },
      metaInfo,
      name,
      placeholder,
      showErrorBeforeSubmit,
      tabletAlignHalfLeft,
      tabletAlignLeft,
      verticalAlign,
      width,
    } = this.props;
    const { inputValue, isLabelRaised, isDateValid, maxSearchableMonths, currentMonth: currentMonthState } = this.state;

    const months = map(MONTHS_SHORT, month => translate(month.translationKey));
    const weekdaysShort = map(CALENDAR_WEEKDAYS, day => translate(day.translationKey));
    const currentMonth = new Date(moment().year(), moment().month());
    const specificDisabledDays =
      disabledDays && disabledDays.length > 1 && disabledDays.slice(1).map(el => moment(el).format('MM/DD/YYYY'));

    const hasTodayButton = () => {
      if (!disabledDays) return true;
      let ok = true;
      if (disabledDays.length) {
        if (disabledDays[0].before)
          if (moment(disabledDays[0]?.before).endOf('day').isAfter(moment().endOf('day'))) ok = false;
        if (disabledDays[0].after)
          if (moment(disabledDays[0]?.after).endOf('day').isBefore(moment().endOf('day'))) ok = false;
      }
      if (isArray(specificDisabledDays)) {
        specificDisabledDays.forEach(el => {
          if (moment(el).endOf('day').isSame(moment().endOf('day'))) ok = false;
        });
      }
      return ok;
    };

    const value = input.value && !inputValue ? input.value : input.value && inputValue ? inputValue : '';

    return (
      <FormGroup hasValue={!!value} margin={margin} raisedLabel={isLabelRaised} width={width} disabled={disabled}>
        {!!label && <FormLabel>{label}</FormLabel>}

        <DatePickerContainer
          focus={true}
          horizontalAlign={horizontalAlign}
          isTodayWithNoStyle={isTodayWithNoStyle}
          mask={!!value && !!mask ? this.formatDate(value, mask) : undefined}
          position="relative"
          tabletAlignHalfLeft={tabletAlignHalfLeft}
          tabletAlignLeft={tabletAlignLeft}
          verticalAlign={verticalAlign}
          isValueHidden={isValueHidden}
          hasTodayButton={hasTodayButton()}
        >
          <DatePickerInput
            format="MM/DD/YYYY"
            onDayChange={this.onDayChange}
            parseDate={this.parseDate}
            formatDate={this.formatDate}
            value={
              inputDefaultValue && moment(inputDefaultValue).isValid()
                ? inputDefaultValue
                : moment(value).isValid()
                ? value
                : isClearable
                ? ''
                : ''
            }
            placeholder={placeholder || ''}
            inputProps={{
              disabled,
              name,
              id,
              readOnly: false,
              autoComplete: 'off',
              onChange: this.onInputChange,
              onKeyDown: onCalendarInputKeyDown,
              onBlur: this.onBlur,
            }}
            dayPickerProps={{
              month: moment(currentMonthState).isValid()
                ? (currentMonthState as Date | undefined)
                : (currentMonth as Date | undefined),
              disabledDays: [
                ...(disabledDays || []),
                disabledDays && disabledDays.length > 0 && disabledDays[0].before
                  ? { before: disabledDays[0].before }
                  : {
                      before: moment().subtract(maxSearchableMonths, 'months').toDate(),
                    },
              ],
              months,
              weekdaysShort,
              todayButton: hasTodayButton() && translate('common.today'),
            }}
          />

          {isClearable && !!value && <FormGroupClear disabled={disabled} onClick={this.clearInput} />}
        </DatePickerContainer>

        {!!metaInfo && <FormMeta>{metaInfo}</FormMeta>}

        {(((submitFailed || showErrorBeforeSubmit) && error) || !isDateValid) && (
          <FormError errorOnSingleLine={errorOnSingleLine}>
            {error || translate('common.validationMessages.invalidDate')}
          </FormError>
        )}
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  dataRetention: state.vendors.dataRetention.dataRetention,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
});

const mapDispatchToProps = {
  loadDataRetention,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
