import { FC, useEffect, useState } from 'react';
import { omit, reduce } from 'lodash-es';
import { Resizable } from 're-resizable';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import mapboxgl from 'mapbox-gl';

import { automaticPaymentsModalId } from '../modals/paymentsManagement/AutomaticPaymentsModal';
import { AutomaticPaymentsModalResolver } from '../modals/paymentsManagement/AutomaticPaymentsModalResolver';
import { billingFeatureStatusSelector, isServiceContractWorkflowFeatureEnabled } from 'src/vendors/ducks/features';
import { Button, MapContainer, Panel, PanelSection } from 'src/core/components/styled';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { CLOSED } from 'src/common/constants';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { getQueryParams } from 'src/utils/services/queryParams';
import {
  loadLocationPaymentMethods,
  saveLocationPaymentMethods,
  saveUnenrollAutomaticPayments,
} from 'src/customers/ducks/paymentMethods';
import { loadServices, resetLocation } from 'src/customers/ducks';
import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { MapDragHandle } from 'src/core/components';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { Service, ServiceContainer } from 'src/customers/interfaces/Services';
import { ServiceContractWorkflowModalResolver } from '../modals/serviceContractWorkflowModal/ServiceContractWorkflowModalResolver';
import { Services, ServicesMapGL } from './servicesPageSections';
import { SortOrder } from 'src/core/components/Table';
import { useSelector } from 'src/core/hooks/useSelector';
import ContactsPageSection from './ContactsPageSection';
import translate from 'src/core/services/translate';

interface Params {
  customerId: string;
}

interface QueryParams {
  endDate: string;
  locationId: string;
  pickupStatusTypeIds: string;
  serviceContractIds: string;
  sortedBy: string;
  sortOrder: SortOrder;
  startDate: string;
}

const ServicesPage: FC = () => {
  const dispatch = useDispatch();

  const isServiceContractWorkflowEnabled = useSelector(isServiceContractWorkflowFeatureEnabled);
  const services = useSelector(state => state.customers.services.services);
  const customerLocation = useSelector(state => state.customers.location.location);
  const customerAccountStatusTypeId = useSelector(
    state => state.customers.customer?.customer?.customerAccountStatusTypeId,
  );
  const customer = useSelector(state => state.customers.customer.customer);
  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));
  const { paymentMethods } = useSelector(state => state.customers.paymentMethods);

  const [isServiceUpdateModalOpen, setIsServiceUpdateModalOpen] = useState(false);
  const [isAutomaticPaymentsModalOpen, setIsAutomaticPaymentsModalOpen] = useState(false);

  const params = useParams<Params>();
  const { customerId } = params;
  const { search } = useLocation();
  const searchParams = getQueryParams<QueryParams>(search);
  const { locationId } = searchParams;

  useEffect(() => {
    return () => {
      dispatch(resetLocation());
    };
  }, [dispatch]);

  const toggleServiceUpdateModalOpen = () => {
    setIsServiceUpdateModalOpen(!isServiceUpdateModalOpen);
  };

  const toggleAutomaticPaymentsModalOpen = () => {
    setIsAutomaticPaymentsModalOpen(!isAutomaticPaymentsModalOpen);
  };

  const reloadServices = () => {
    const { locationId } = searchParams;
    loadServices(Number(locationId))(dispatch);
  };

  const saveAutomaticPayment = (paymentConnectPaymentMethodId?: string) => {
    const { locationId } = searchParams;

    scrollToTopOfModal(automaticPaymentsModalId);

    saveLocationPaymentMethods(
      Number(locationId),
      paymentConnectPaymentMethodId,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('customers.alertMessages.automaticPaymentSavedSuccessfully'));
        toggleAutomaticPaymentsModalOpen();
        loadLocationPaymentMethods(Number(locationId))(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('customers.alertMessages.automaticPaymentSaveError'));
      });
  };

  const unenrollAutomaticPayments = () => {
    const { locationId } = searchParams;

    scrollToTopOfModal(automaticPaymentsModalId);

    saveUnenrollAutomaticPayments(Number(locationId))(dispatch)
      .then(() => {
        createSuccessNotification(translate('customers.alertMessages.unenrollPaymentsSavedSuccessfully'));
        toggleAutomaticPaymentsModalOpen();
        loadLocationPaymentMethods(Number(locationId))(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('customers.alertMessages.unenrollPaymentsSaveError'));
      });
  };

  const serviceContainers: ServiceContainer[] = reduce(
    services,
    (serviceContainers: ServiceContainer[], { containers }: Service) => [...serviceContainers, ...containers],
    [],
  );

  const isViewOnly = checkIfViewOnly();
  const isSupport = checkIfSupport();
  const hasWorkflowAccess =
    (isViewOnly || isSupport ? false : isServiceContractWorkflowEnabled) &&
    customerAccountStatusTypeId !== CLOSED &&
    customerLocation?.accountStatusTypeId !== CLOSED;

  const paramsToSend = Object.entries(omit(searchParams, 'locationId'))
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  const urlParams = paramsToSend.toString();

  const isAutomaticPaymentSelected =
    (paymentMethods || []).filter(paymentMethod => !!paymentMethod.isDefaultAutoPay)?.length > 0;

  if (!customer || !customerLocation) return null;

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButton id="back-button" to={`/customers/customers/${customerId}/edit?${urlParams}`}>
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle>{customerLocation?.name}</PageTitle>

            <PageSubtitle margin="no no xSmall no">
              <b>{translate('customers.customerName')}: </b>
              {customer?.name}
              {'  |  '}
              <b>{translate('common.address')}: </b>
              {customerLocation?.address?.line1}
            </PageSubtitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions flex align="right">
          {hasWorkflowAccess && !isBillingFeatureActive && (
            <Button color="primary" onClick={toggleServiceUpdateModalOpen}>
              {translate('customers.serviceUpdate')}
            </Button>
          )}
          {isBillingFeatureActive && (
            <Button color="primary" onClick={toggleAutomaticPaymentsModalOpen} margin="no no no small">
              {translate(
                isAutomaticPaymentSelected ? 'customers.manageAutomaticPayments' : 'customers.setUpAutomaticPayments',
              )}
            </Button>
          )}
        </PageActions>
      </PageHeader>
      <Panel>
        <PanelSection vertical withBorder padding="small small">
          <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
            <MapContainer>
              <ServicesMapGL
                serviceContainers={serviceContainers}
                defaultLatLng={
                  !!customerLocation
                    ? new mapboxgl.LngLat(customerLocation.address.longitude, customerLocation.address.latitude)
                    : undefined
                }
              />
            </MapContainer>
          </Resizable>
        </PanelSection>
        {isBillingFeatureActive && (
          <ContactsPageSection
            customerId={customer.id}
            locationId={customerLocation.id}
            customerName={customer.name}
            title={translate('common.contact.locationContacts')}
          />
        )}
        <PanelSection vertical>
          <Services />
        </PanelSection>
      </Panel>

      {isServiceUpdateModalOpen && (
        <ServiceContractWorkflowModalResolver
          closeModal={toggleServiceUpdateModalOpen}
          onSubmitSuccess={reloadServices}
          selectedCustomerId={Number(customerId)}
          selectedLocationId={Number(locationId)}
        />
      )}

      {isAutomaticPaymentsModalOpen && (
        <AutomaticPaymentsModalResolver
          closeModal={toggleAutomaticPaymentsModalOpen}
          onSubmit={saveAutomaticPayment}
          onUnenrollAutomaticPayments={unenrollAutomaticPayments}
          selectedLocationId={Number(locationId)}
        />
      )}
    </PageContent>
  );
};

export default ServicesPage;
