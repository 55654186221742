import update from 'immutability-helper';
import { reverse } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { doLoadGeoFences } from '../services/geoFences';
import { GeoQueryPayload } from './geoFences';

// Interfaces
export interface GeoFenceZoneType {
  id: number;
  name: string;
  technicalName: string;
}

export interface GeoFence {
  geoFenceJson: any;
  geoFenceName: string;
  geoFenceZoneType: GeoFenceZoneType;
  id: number;
  routeId?: number;
  routeName?: string;
  routeTemplateId?: number;
  startingLocationName?: string;
}

export type GeoFenceJsonList = {
  geoFenceJson: number[][];
  id: number;
  name: string;
}[];

// Actions
const START_LOAD_PIN_ON_MAP_GEO_FENCES = 'routes/pinOnMapGeoFences/START_LOAD_GEO_FENCES';
const COMPLETE_LOAD_PIN_ON_MAP_GEO_FENCES = 'routes/pinOnMapGeoFences/COMPLETE_LOAD_GEO_FENCES';
const FAIL_LOAD_PIN_ON_MAP_GEO_FENCES = 'routes/pinOnMapGeoFences/FAIL_LOAD_GEO_FENCES';
const RESET_PIN_ON_MAP_GEO_FENCES = 'routes/pinOnMapGeoFences/RESET_GEO_FENCES';

// Initial state =
const initialState: any = {
  geoFences: {},
  isLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_PIN_ON_MAP_GEO_FENCES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_PIN_ON_MAP_GEO_FENCES:
      return update(state, {
        $merge: {
          isLoading: false,
          geoFences: reverse(action.geoFences.geoFences),
        },
      });

    case FAIL_LOAD_PIN_ON_MAP_GEO_FENCES:
      return update(state, {
        $merge: {
          isLoading: false,
          geoFences: undefined,
        },
      });
    case RESET_PIN_ON_MAP_GEO_FENCES:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoadPinOnMaoGeoFences = () => ({
  type: START_LOAD_PIN_ON_MAP_GEO_FENCES,
});

const completeLoadPinOnMapGeoFences = (geoFences: any) => ({
  type: COMPLETE_LOAD_PIN_ON_MAP_GEO_FENCES,
  geoFences,
});

const failLoadPinOnMapGeoFences = () => ({
  type: FAIL_LOAD_PIN_ON_MAP_GEO_FENCES,
});

export const resetPinOnMapGeoFences = () => ({ type: RESET_PIN_ON_MAP_GEO_FENCES });

export const loadPinOnMapGeoFences = (payload: GeoQueryPayload) => (dispatch: Dispatch) => {
  dispatch(startLoadPinOnMaoGeoFences());
  const loadPinOnMapGeoFencesPromise = doLoadGeoFences(payload);
  loadPinOnMapGeoFencesPromise
    .then(geoFences => dispatch(completeLoadPinOnMapGeoFences(geoFences)))
    .catch(() => dispatch(failLoadPinOnMapGeoFences()));
  return loadPinOnMapGeoFencesPromise;
};
