import React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { LoadsDumped } from 'src/routes/interfaces/LoadsDumped';
import { LoadsDumpedTableRow } from 'src/routes/components/modals';
import { Modal, Table } from 'src/core/components';
import { ModalSection, Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

const loadsDumpedCellWidths = ['31%', '19%', '13%', '15%', '12%', '10%'];

const getLoadsDumpedTableCells = () => {
  const loadsDumpedTableCells = [
    {
      name: 'location',
      label: translate('common.materialTickets.location'),
      width: loadsDumpedCellWidths[0],
    },
    {
      name: 'material',
      label: translate('common.material'),
      width: loadsDumpedCellWidths[1],
    },
    {
      name: 'quantity',
      label: translate('common.materialTickets.quantity'),
      width: loadsDumpedCellWidths[2],
    },
    {
      name: 'uom',
      label: translate('common.materialTickets.uom'),
      width: loadsDumpedCellWidths[3],
    },
    {
      name: 'timestamp',
      label: translate('common.materialTickets.materialTicketDate'),
      width: loadsDumpedCellWidths[4],
    },
    {
      name: 'images',
      label: translate('common.images'),
      width: loadsDumpedCellWidths[5],
    },
  ];

  return loadsDumpedTableCells;
};

interface Props {
  closeModal: () => void;
  loadsDumped: LoadsDumped[];
  modalSubTitle?: string;
  modalTitle?: string;
}

const LoadsDumpedModal: React.FC<Props> = props => {
  const { closeModal, modalSubTitle, modalTitle, loadsDumped } = props;

  return (
    <Modal size="large" subTitle={modalSubTitle} title={modalTitle} onClose={closeModal} padding="medium">
      <ModalSection margin="medium no no">
        {loadsDumped.length ? (
          <Table
            cells={getLoadsDumpedTableCells()}
            rows={loadsDumped}
            rowComponent={LoadsDumpedTableRow}
            rowProps={{
              isViewOnly: true,
              loadsDumpedCellWidths,
            }}
          />
        ) : (
          <Text block align="center" padding="no no medium no">
            {translate('common.loadsDumped.noLoadsDumped')}
          </Text>
        )}
      </ModalSection>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadsDumped: state.routes.loadsDumped.loadsDumped || [],
});

export default connect(mapStateToProps)(LoadsDumpedModal);
