import { useEffect, useMemo, useState } from 'react';
import { some, uniq } from 'lodash-es';

import {
  RouteTemplateBuilderDayOfService,
  RouteTemplateBuilderDayOfServiceBundle,
} from '../interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { useSelector } from 'src/core/hooks/useSelector';
import { ALL_STOPS_FILTER, ASSIGNED_FILTER, PUBLISHED, UNASSIGNED_FILTER } from '../constants/routeTemplateBuilder';
import { WEEKDAYS_BY_ID } from 'src/core/constants/weekdays';
import useServicesAvailableForSelection from './useServicesAvailableForSelection';

function processRouteTemplatesBundle(routeTemplatesBundle: RouteTemplateBuilderDayOfService['routeTemplates']) {
  const routeTemplates: RouteTemplateBuilderDayOfService['routeTemplates'] = [];

  routeTemplatesBundle.forEach(routeFromBundle => {
    const routeFromList = routeTemplates.find(route => route.id === routeFromBundle.id);

    if (!routeFromList) {
      routeTemplates.push({
        ...routeFromBundle,
        stopsCount: routeFromBundle.stopsCount,
      });
      return;
    }

    routeFromList.serviceContracts = routeFromList.serviceContracts.concat(routeFromBundle.serviceContracts);
    routeFromList.stopsCount = uniq(routeFromList.serviceContracts).length;
    routeFromList.statusId = routeFromList.statusId === PUBLISHED ? routeFromBundle.statusId : routeFromList.statusId;
  });

  return routeTemplates;
}

type SelectedDayOfServiceReturn = {
  availableDayOfServiceIds: number[];
  selectedDaysOfServiceBundle?: RouteTemplateBuilderDayOfServiceBundle;
};

export default function useSelectedDaysOfService(): SelectedDayOfServiceReturn {
  const servicesAvailableForSelection = useServicesAvailableForSelection();
  const servicesMeta = useSelector(state => state.routes.routeTemplateBuilder.servicesMeta);
  const selectedVehicleTypeId = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.vehicleTypeId);
  const selectedMaterialTypeIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.materialTypeIds);
  const selectedDayOfServiceIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds);
  const selectedDraft = useSelector(state => state.routes.routeTemplateBuilder.selectedDraft);
  const stopFilterType = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.stopFilterType);
  const selectedRouteTemplateIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);

  const availableDaysOfService = (selectedDraft ? [selectedDraft.dayOfService] : selectedDayOfServiceIds).map(
    dayId => WEEKDAYS_BY_ID[dayId],
  );
  const [assignedStopsCount, setAssignedStopsCount] = useState(0);
  const [unassignedStopsCount, setUnassignedStopsCount] = useState(0);

  const unassignedStops = servicesAvailableForSelection.filter(service => {
    const availableDays = service.daysOfService?.filter(day => availableDaysOfService.find(d => d.id === day.id));
    const isUnassigned = some(availableDays, d => !d.routeTemplateId && !d.draftRouteTemplateId);
    return isUnassigned;
  }).length;

  const assignedStops = servicesAvailableForSelection.filter(service => {
    const availableDays = service.daysOfService?.filter(day => availableDaysOfService.find(d => d.id === day.id));
    const isAssigned = some(
      availableDays,
      d =>
        (!!d.routeTemplateId || !!d.draftRouteTemplateId) &&
        selectedRouteTemplateIds.indexOf(d.routeName) !== -1,
    );

    return isAssigned;
  }).length;

  useEffect(() => {
    if (stopFilterType === ALL_STOPS_FILTER || stopFilterType === ASSIGNED_FILTER) setAssignedStopsCount(assignedStops);
    if (stopFilterType === ALL_STOPS_FILTER || stopFilterType === UNASSIGNED_FILTER)
      setUnassignedStopsCount(unassignedStops);
  }, [stopFilterType, assignedStops, unassignedStops]);

  const daysOfService = useMemo(() => {
    const daysOfService: RouteTemplateBuilderDayOfService[] = [];

    servicesMeta
      .filter(
        meta =>
          meta.vehicleTypeId === selectedVehicleTypeId && selectedMaterialTypeIds.indexOf(meta.materialTypeId) !== -1,
      )
      .forEach(meta => {
        meta.daysOfService.forEach(dayFromMeta => {
          const dayFromList = daysOfService.find(day => day.id === dayFromMeta.id);

          if (!dayFromList) {
            /**
             * Clone is necessary or else it will mutate the state when we
             * sum the unassigned stops and route templates outside this clause.
             */
            daysOfService.push({ ...dayFromMeta });
            return;
          }

          dayFromList.unassignedStopsCount += dayFromMeta.unassignedStopsCount;
          dayFromList.routeTemplates = processRouteTemplatesBundle(
            dayFromList.routeTemplates.concat(dayFromMeta.routeTemplates),
          );
        });
      });

    return daysOfService;
  }, [servicesMeta, selectedVehicleTypeId, selectedMaterialTypeIds]);

  const selectedDaysOfServiceBundle = useMemo(
    () =>
      (daysOfService as RouteTemplateBuilderDayOfServiceBundle[])
        .filter(day => selectedDayOfServiceIds.indexOf(day.id) !== -1)
        .reduce(
          (prev, current) => {
            const routeTemplates = processRouteTemplatesBundle(prev.routeTemplates.concat(current.routeTemplates));
            const routeTemplatesWithDayOfService = prev.routeTemplatesWithDayOfService.concat(
              current.routeTemplates.map(route => ({ ...route, dayOfServiceId: current.id })),
            );

            return {
              /**
               * Day of service id won't really matter here since all days
               * are reduced into one.
               */
              id: -1,
              ids: prev.ids.concat(current.id),
              assignedStopsCount,
              unassignedStopsCount,
              routeTemplates,
              routeTemplatesWithDayOfService,
            };
          },
          /**
           * Default value for this reducer.
           */
          {
            id: -1,
            ids: [],
            assignedStopsCount: 0,
            unassignedStopsCount: 0,
            routeTemplates: [],
            routeTemplatesWithDayOfService: [],
          },
        ),

    [daysOfService, selectedDayOfServiceIds, assignedStopsCount, unassignedStopsCount],
  );

  return {
    availableDayOfServiceIds: daysOfService.map(day => day.id),
    selectedDaysOfServiceBundle,
  };
}
