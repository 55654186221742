import { size } from 'lodash-es';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Button, ButtonSet, Grid, GridColumn, Message, ModalClose, ModalCloseIcon } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import VehicleTypesTableRow from '../VehicleTypesTableRow';
import { Table } from 'src/core/components';

type FuelingTicketsSettingsFormProps = {
  onCancel: (pristine: boolean) => void;
};

const FUELING_TICKETS_SETTINGS_FORM = 'FuelingTicketsSettingsForm';

type Props = FuelingTicketsSettingsFormProps & InjectedFormProps<any, FuelingTicketsSettingsFormProps>;

const FuelingTicketsSettingsForm = ({ onCancel, handleSubmit, pristine }: Props) => {
  const fuelingTicketsSettings = useSelector(state => state.vendors.fuelingTicketsSettings.fuelingTicketsSettings);

  const fuelingTicketsSettingsFormTableCells = [
    { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '80%' },
    { name: 'isActive', label: translate('common.active'), width: '20%' },
  ];

  return (
    <>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <form onSubmit={handleSubmit}>
        {size(fuelingTicketsSettings) ? (
          <>
            <Table
              cells={fuelingTicketsSettingsFormTableCells}
              rowComponent={VehicleTypesTableRow}
              rows={fuelingTicketsSettings}
            />
            <Grid centered>
              <GridColumn size="5/12">
                <ButtonSet margin="medium no">
                  <Button type="submit" color="primary">
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            </Grid>
          </>
        ) : (
          <Message padding="sMedium sMedium medium sMedium">{translate('vehicles.noVehiclesConfigured')}</Message>
        )}
      </form>
    </>
  );
};

export default reduxForm<any, FuelingTicketsSettingsFormProps>({ form: FUELING_TICKETS_SETTINGS_FORM })(
  FuelingTicketsSettingsForm,
);
