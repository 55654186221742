import { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  PageContent,
  PageDetails,
  PageTitleContainer,
  PageTitle,
  PageHeader,
  PageFooter,
} from 'src/common/components/styled';
import { RouteComponentProps, withRouter } from 'react-router';
import translate from 'src/core/services/translate';
import { GridColumn, Panel, PanelSection, PanelSectionGroup, Tab, Tabs, Button } from 'src/core/components/styled';
import RateBuilderFilterForm, { FormValues } from './rateBuilder/RateBuilderFilterForm';
import RateBuilderCharts from './rateBuilder/RateBuilderCharts';
import { loadServiceRatesContracts, resetRateEntries } from 'src/customers/ducks/rateBuilder';
import RateBuilderListView from './rateBuilder/RateBuilderListView';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import RateBuilderMapGL from './rateBuilder/RateBuilderMapGL';
import AddRateModalResolver from './rateBuilder/AddRateModalResolver';
import { saveServiceRatesContracts } from 'src/customers/services/rateBuilder';
import { useSelector } from 'src/core/hooks/useSelector';
import { getFormValues } from 'redux-form';
import { RateCodeFormValues } from './rateBuilder/AddRateModal';

interface Props extends RouteComponentProps {}
const formSelector = getFormValues('rateBuilderFilterForm');
const convertArrayItemsToNumbers = (array: string[] | number[] | undefined): number[] | undefined => {
  return array ? array.map(item => Number(item)) : undefined;
};
const RateBuilderPage = (props: Props) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const isLoading = useSelector(state => state.customers.rateBuilder.isLoading);
  const rateBuilderFormValues = useSelector(state => formSelector(state));
  const serviceContractsData = useSelector(state => state.customers.rateBuilder.serviceContracts);
  const [ratesModal, setRatesModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [rateTypeId, setRateTypeId] = useState<number | null>(null);
  const [checkedServiceContractIds, setCheckedServiceContractIds] = useState<number[]>([]); // Pass these IDs to the Add Rate modal when open

  const filteredContracts = useMemo(() => {
    if (Array.isArray(serviceContractsData?.serviceContracts) && checkedServiceContractIds.length > 0) {
      const filtered = serviceContractsData.serviceContracts.filter(contract =>
        checkedServiceContractIds.includes(contract?.serviceContractId),
      );
      if (filtered.length > 0 && filtered[0]?.serviceType?.id) {
        return filtered[0].serviceType.id;
      }
    }
    return null;
  }, [serviceContractsData, checkedServiceContractIds]);

  useEffect(() => {
    setRateTypeId(filteredContracts);
  }, [filteredContracts]);

  const closeModal = () => {
    dispatch(resetRateEntries());
    setRatesModal(false);
  };

  const handleSubmitFilterForm = (formValues: FormValues) => {
    const { equipmentSizeIds, excludedRateCodeIds, includedRateCodeIds } = formValues;

    loadServiceRatesContracts({
      vendorId,
      serviceTypeId: 0,
      ...formValues,
      equipmentSizeIds: convertArrayItemsToNumbers(equipmentSizeIds),
      excludedRateCodeIds: convertArrayItemsToNumbers(excludedRateCodeIds),
      includedRateCodeIds: convertArrayItemsToNumbers(includedRateCodeIds),
    })(dispatch).catch(({ code }) => {
      createErrorNotification(`${translate(createTranslationKey(code, 'customers.rateBuilder.alertMessages'))}`);
    });
  };
  // Pass this to RateBuilderFilterForm
  const refetchFilterForm = () => {
    handleSubmitFilterForm(rateBuilderFormValues); // This is the form refetch
  };

  const saveServiceRatesContract = (data: RateCodeFormValues) => {
    saveServiceRatesContracts(vendorId, checkedServiceContractIds, data.rates)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.ratesSaved'));
        setRatesModal(false);
        refetchFilterForm();
        dispatch(resetRateEntries());
      })
      .catch(({ code }: any) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
      });
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('customers.rateBuilder.rateBuilder')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      <Panel fluid>
        <RateBuilderFilterForm onSubmit={handleSubmitFilterForm} />
        <PanelSectionGroup>
          <PanelSection withBorder vertical>
            <RateBuilderCharts />
          </PanelSection>
        </PanelSectionGroup>
        <Panel>
          <PanelSection padding="small no no no" centered>
            <Tabs>
              <Tab isSelected={selectedTab === 1} onClick={() => setSelectedTab(1)}>
                {translate('common.map')}
              </Tab>
              <Tab isSelected={selectedTab === 2} onClick={() => setSelectedTab(2)}>
                {translate('common.list')}
              </Tab>
            </Tabs>
          </PanelSection>

          {/* Keep RateBuilderMapGL mounted and control visibility */}
          <Panel padding="small" withBorder>
            <PanelSectionGroup isLoading={isLoading}>
              <div style={{ height: 500 }}>
                <div style={{ display: selectedTab === 1 ? 'block' : 'none', height: '100%' }}>
                  <RateBuilderMapGL
                    setCheckedServiceContractIds={setCheckedServiceContractIds}
                    checkedServiceContractIds={checkedServiceContractIds}
                  />
                </div>
                <div style={{ display: selectedTab === 2 ? 'block' : 'none' }}>
                  <RateBuilderListView
                    setCheckedServiceContractIds={setCheckedServiceContractIds}
                    checkedServiceContractIds={checkedServiceContractIds}
                  />
                </div>
              </div>
            </PanelSectionGroup>
          </Panel>
        </Panel>
      </Panel>

      {rateTypeId && ratesModal && (
        <AddRateModalResolver
          checkedServiceContractIds={checkedServiceContractIds}
          onSubmit={saveServiceRatesContract}
          onClose={closeModal}
          rateTypeId={rateTypeId}
        />
      )}

      {rateTypeId && checkedServiceContractIds.length > 0 && (
        <PageFooter>
          <GridColumn size="12/12" verticalAlign="center" align="center">
            <Button color="primary" onClick={() => setRatesModal(true)} margin="no small no no">
              {`${translate('common.addRates')} (${checkedServiceContractIds.length})`}
            </Button>

            <Button color="secondary" onClick={() => setCheckedServiceContractIds([])}>
              {translate('common.cancel')}
            </Button>
          </GridColumn>
        </PageFooter>
      )}
    </PageContent>
  );
};

export default withRouter(RateBuilderPage);
