import * as turf from '@turf/turf';
import axios from 'axios';
import update from 'immutability-helper';
import { camelCase } from 'lodash-es';
import { Dispatch } from 'redux';

import { createErrorNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { loadStreetNetwork } from 'src/customers/services/streetNetwork';
import { HaulerLocation } from 'src/dashboard/interfaces/haulerLocations';
import { RouteVehiclePosition, RouteVehiclesBreadCrumbs } from 'src/dashboard/interfaces/routesData';
import { loadHaulerLocationsForMapbox as doLoadHaulerLocations } from 'src/dashboard/services/loadVendorLocations';
import { RouteStop } from '../interfaces/RouteStop';
import {
  ApplyEditsToTravelPathPayload,
  StreetNetworkPropertiesTravelPath,
  TravelPathBuilderFilterData,
  TravelPathEditProperties,
  TravelPathInitConfigs,
  TravelPathProperties,
  TravelPathTracerPayload,
  TurnRestrictionProperties,
} from '../interfaces/TravelPath';
import { doLoadGeoFences } from '../services/geoFences';
import { loadRouteStops as doLoadRouteStopsForRoute } from '../services/routeStops';
import { loadRouteTemplateRouteStops as doLoadRouteTemplateRouteStops } from '../services/routeTemplate';
import {
  applyEditsToTravelPath as doApplyEditsToTravelPath,
  extendTravelPathLock as doExtendTravelPathLock,
  finishTravelPathBuildOrEdit as doFinishTravelPathBuildOrEdit,
  initializeTravelPathBuildOrEdit as doInitializeTravelPathBuildOrEdit,
  loadTravelPathBuilderRouteVehicleInformation as doLoadRouteVehicleInformation,
  loadTravelPathBuilderRouteVehiclePositions as doLoadRouteVehiclePositions,
  loadTravelPathBuilderRouteVehiclesBreadCrumbs as doLoadRouteVehiclesBreadCrumbs,
  loadTravelPathBuilderFilters as doLoadTravelPathBuilderFilters,
  loadTravelPathGeoLayers as doLoadTravelPathGeoLayers,
  publishEditsToTravelPath as doPublishEditsToTravelPath,
  travelPathBuilderDoTracing as doTravelPathBuilderDoTracing,
} from '../services/travelPath';
import { GeoQueryPayload } from './geoFences';
import { STREET_SEGMENT_ASSIGNED_TYPES_STATUSES } from 'src/customers/constants/streetNetwork';

const SET_SELECTED_SEGMENT = 'travelPathBuildAndEdit/SET_SELECTED_SEGMENT';

const START_INIT_EDIT_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/START_INIT_EDIT_TRAVEL_PATH';
const COMPLETE_INIT_EDIT_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/COMPLETE_INIT_EDIT_TRAVEL_PATH';
const FAIL_INIT_EDIT_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/FAIL_INIT_EDIT_TRAVEL_PATH';

const START_LOAD_MAP_FEATURES = 'routes/travelPathBuildAndEdit/START_LOAD_MAP_FEATURES';
const COMPLETE_LOAD_MAP_FEATURES = 'routes/travelPathBuildAndEdit/COMPLETE_LOAD_MAP_FEATURES';
const FAIL_LOAD_MAP_FEATURES = 'routes/travelPathBuildAndEdit/FAIL_LOAD_MAP_FEATURES';
const RESET_MAP_FEATURES = 'routes/travelPathBuildAndEdit/RESET_MAP_FEATURES';

const START_LOAD_TRAVEL_PATH_STREET_NETWORK = 'routes/travelPathBuildAndEdit/START_LOAD_TRAVEL_PATH_STREET_NETWORK';
const COMPLETE_LOAD_TRAVEL_PATH_STREET_NETWORK =
  'routes/travelPathBuildAndEdit/COMPLETE_LOAD_TRAVEL_PATH_STREET_NETWORK';
const FAIL_LOAD_TRAVEL_PATH_STREET_NETWORK = 'routes/travelPathBuildAndEdit/FAIL_LOAD_TRAVEL_PATH_STREET_NETWORK';

const START_LOAD_BUILDER_FILTERS = 'routes/travelPathBuildAndEdit/START_LOAD_BUILDER_FILTERS';
const COMPLETE_LOAD_BUILDER_FILTERS = 'routes/travelPathBuildAndEdit/COMPLETE_LOAD_BUILDER_FILTERS';
const FAIL_LOAD_BUILDER_FILTERS = 'routes/travelPathBuildAndEdit/FAIL_LOAD_BUILDER_FILTERS';

const START_LOAD_VEHICLE_POSITION_AND_BREADCRUMB =
  'routes/travelPathBuildAndEdit/START_LOAD_VEHICLE_POSITION_AND_BREADCRUMB';
const COMPLETE_LOAD_VEHICLE_POSITION_AND_BREADCRUMB =
  'routes/travelPathBuildAndEdit/COMPLETE_LOAD_VEHICLE_POSITION_AND_BREADCRUMB';
const FAIL_LOAD_VEHICLE_POSITION_AND_BREADCRUMB =
  'routes/travelPathBuildAndEdit/FAIL_LOAD_VEHICLE_POSITION_AND_BREADCRUMB';

const START_LOAD_ROUTE_VEHICLE_INFORMATION = 'routes/travelPathBuildAndEdit/START_LOAD_ROUTE_VEHICLE_INFORMATION';
const COMPLETE_LOAD_ROUTE_VEHICLE_INFORMATION = 'routes/travelPathBuildAndEdit/COMPLETE_LOAD_ROUTE_VEHICLE_INFORMATION';
const FAIL_LOAD_ROUTE_VEHICLE_INFORMATION = 'routes/travelPathBuildAndEdit/FAIL_LOAD_ROUTE_VEHICLE_INFORMATION';

const START_LOAD_TRAVEL_PATH_EDITED = 'routes/travelPathBuildAndEdit/START_LOAD_TRAVEL_PATH_EDITED';
const COMPLETE_LOAD_TRAVEL_PATH_EDITED = 'routes/travelPathBuildAndEdit/COMPLETE_LOAD_TRAVEL_PATH_EDITED';
const FAIL_LOAD_TRAVEL_PATH_EDITED = 'routes/travelPathBuildAndEdit/FAIL_LOAD_TRAVEL_PATH_EDITED';

const START_APPLY_EDITS_TO_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/START_APPLY_EDITS_TO_TRAVEL_PATH';
const COMPLETE_APPLY_EDITS_TO_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/COMPLETE_APPLY_EDITS_TO_TRAVEL_PATH';
const FAIL_APPLY_EDITS_TO_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/FAIL_APPLY_EDITS_TO_TRAVEL_PATH';

const START_PUBLISH_EDITS_TO_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/START_PUBLISH_EDITS_TO_TRAVEL_PATH';
const COMPLETE_PUBLISH_EDITS_TO_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/COMPLETE_PUBLISH_EDITS_TO_TRAVEL_PATH';
const FAIL_PUBLISH_EDITS_TO_TRAVEL_PATH = 'routes/travelPathBuildAndEdit/FAIL_PUBLISH_EDITS_TO_TRAVEL_PATH';

const START_FINISH_TRAVEL_PATH_EDIT = 'routes/travelPathBuildAndEdit/START_FINISH_TRAVEL_PATH_EDIT';
const COMPLETE_FINISH_TRAVEL_PATH_EDIT = 'routes/travelPathBuildAndEdit/COMPLETE_FINISH_TRAVEL_PATH_EDIT';
const FAIL_FINISH_TRAVEL_PATH_EDIT = 'routes/travelPathBuildAndEdit/FAIL_FINISH_TRAVEL_PATH_EDIT';

const START_EXTEND_TRAVEL_PATH_LOCK = 'routes/travelPathBuildAndEdit/START_EXTEND_TRAVEL_PATH_LOCK';
const COMPLETE_EXTEND_TRAVEL_PATH_LOCK = 'routes/travelPathBuildAndEdit/COMPLETE_EXTEND_TRAVEL_PATH_LOCK';
const FAIL_EXTEND_TRAVEL_PATH_LOCK = 'routes/travelPathBuildAndEdit/FAIL_EXTEND_TRAVEL_PATH_LOCK';

const START_TRAVEL_PATH_BUILDER_TRACING = 'routes/travelPathBuildAndEdit/START_TRAVEL_PATH_BUILDER_TRACING';
const COMPLETE_TRAVEL_PATH_BUILDER_TRACING = 'routes/travelPathBuildAndEdit/COMPLETE_TRAVEL_PATH_BUILDER_TRACING';
const FAIL_TRAVEL_PATH_BUILDER_TRACING = 'routes/travelPathBuildAndEdit/FAIL_TRAVEL_PATH_BUILDER_TRACING';

const SET_IS_TRAVEL_PATH_LEGEND_OPEN = 'routes/travelPathBuildAndEdit/SET_IS_TRAVE_PATH_LEGEND_OPEN';

const RESET_START_END = 'routes/travelPathBuildAndEdit/RESET';
const RESET_TRAVEL_PATH_BUILD_TRACER = 'routes/travelPathBuildAndEdit/RESET_TRAVEL_PATH_BUILD_TRACER';
const RESET_TRAVEL_PATH_BUILD_EDIT = 'routes/travelPathBuildAndEdit/RESET_TRAVEL_PATH_BUILD_EDIT';

const CancelToken = axios.CancelToken;
let cancelTravelPathStreetNetworkRequest: any;
let cancelTravelPathStreetsTurnRestrictionsRequest: any;
let cancelLoadRouteVehiclePositionRequest: any;
let cancelLoadRouteVehicleBreadCrumbRequest: any;

// we cannot load them in their original ducks because they will be loaded also on the route map
type FeatureTypes = {
  geoFences: 'geoFences';
  routeSegments: 'routeSegments';
  routeStops: 'routeStops';
  haulerLocations: 'haulerLocations';
};
interface TravelPathEditState {
  travelPathEditData: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TravelPathEditProperties> | null;
  isLoadingTravelPathEdited: boolean;

  startSegment: any;
  endSegment: any;

  initEditConfigs?: TravelPathInitConfigs;
  isInitializingEditTravelPath: boolean;
  isExtendingTravelPathLock: boolean;

  //travel path builder
  travelPathBuilderFilters?: TravelPathBuilderFilterData[];
  isLoadingTravelPathBuilderFilters: boolean;
  routeVehiclePositions: RouteVehiclePosition[];
  routeVehicleInformation?: any;
  isLoadingRouteVehicleInformation: boolean;
  routeVehicleBreadCrumbs?: RouteVehiclesBreadCrumbs;
  isLoadingRouteVehiclePositionAndBreadcrumb: boolean;

  // the map features (layers)
  isLoadingMapFeatures: boolean;
  geoFences: any[];
  routeSegments: StreetNetwork[];
  routeStops: RouteStop[];
  haulerLocations: HaulerLocation[];

  isLoadingTravelPathGeoStreets: boolean;
  streetNetwork?: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
  streetTurnRestrictions?: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TurnRestrictionProperties>;
  streetNetworkBBOX?: turf.BBox;

  isApplyingEditsToTravelPath: boolean;
  hasAppliedEditsToTravelPath: boolean;
  isSavingEditsToTravelPath: boolean;

  isDoingTravelPathBuilderTracing: boolean;
  didDoAnyTravelPathBuilderTracing: boolean;

  isTravelPathLegendOpen: boolean;
}

const initialState: TravelPathEditState = {
  endSegment: null,
  geoFences: [],
  hasAppliedEditsToTravelPath: false,
  haulerLocations: [],
  isApplyingEditsToTravelPath: false,
  isExtendingTravelPathLock: false,
  isInitializingEditTravelPath: false,
  isLoadingMapFeatures: false,
  isLoadingRouteVehicleInformation: false,
  isLoadingRouteVehiclePositionAndBreadcrumb: false,
  isLoadingTravelPathBuilderFilters: false,
  isLoadingTravelPathEdited: false,
  isLoadingTravelPathGeoStreets: false,
  isSavingEditsToTravelPath: false,
  isTravelPathLegendOpen: false,
  routeSegments: [],
  routeStops: [],
  routeVehicleBreadCrumbs: undefined,
  routeVehiclePositions: [],
  startSegment: null,
  streetNetwork: undefined,
  streetNetworkBBOX: undefined,
  travelPathBuilderFilters: undefined,
  travelPathEditData: null,
  isDoingTravelPathBuilderTracing: false,
  didDoAnyTravelPathBuilderTracing: false,
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_SEGMENT:
      return update(state, {
        // if both are 0, set startSegment to action.payload
        // if startSegment is not 0, set endSegment to action.payload
        // if we have both, do nothing
        startSegment: {
          $set: state.startSegment === null ? action.payload : state.startSegment,
        },
        endSegment: {
          $set: state.startSegment !== null && state.endSegment === null ? action.payload : state.endSegment,
        },
      });

    case RESET_START_END:
      return update(state, {
        startSegment: {
          $set: null,
        },
        endSegment: {
          $set: null,
        },
      });

    case START_LOAD_TRAVEL_PATH_EDITED:
      return update(state, {
        $merge: {
          isLoadingTravelPathEdited: true,
        },
      });

    case COMPLETE_LOAD_TRAVEL_PATH_EDITED:
      return update(state, {
        $merge: {
          isLoadingTravelPathEdited: false,
          travelPathEditData: action.travelPathEditData,
        },
      });

    case FAIL_LOAD_TRAVEL_PATH_EDITED:
      return update(state, {
        $merge: {
          isLoadingTravelPathEdited: false,
          travelPathEditData: null,
        },
      });

    case START_INIT_EDIT_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isInitializingEditTravelPath: true,
        },
      });

    case COMPLETE_INIT_EDIT_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isInitializingEditTravelPath: false,
          initEditConfigs: action.initEditConfigs,
        },
      });

    case FAIL_INIT_EDIT_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isInitializingEditTravelPath: false,
        },
      });

    case START_LOAD_MAP_FEATURES:
      return update(state, {
        $merge: {
          isLoadingMapFeatures: true,
        },
      });

    case COMPLETE_LOAD_MAP_FEATURES:
      return update(state, {
        $merge: {
          isLoadingMapFeatures: false,
          [action.featureType]: action.features,
        },
      });

    case FAIL_LOAD_MAP_FEATURES:
      return update(state, {
        $merge: {
          isLoadingMapFeatures: false,
          [action.featureType]: [],
        },
      });

    case RESET_MAP_FEATURES:
      return update(state, {
        $merge: {
          [action.featureType]: [],
        },
      });

    case START_LOAD_TRAVEL_PATH_STREET_NETWORK:
      return update(state, {
        $merge: {
          isLoadingTravelPathGeoStreets: true,
        },
      });

    case COMPLETE_LOAD_TRAVEL_PATH_STREET_NETWORK:
      return update(state, {
        $merge: {
          isLoadingTravelPathGeoStreets: false,
          streetNetwork: action.streetNetwork,
          streetTurnRestrictions: action.streetTurnRestrictions,
          streetNetworkBBOX: action.bbox,
        },
      });

    case FAIL_LOAD_TRAVEL_PATH_STREET_NETWORK:
      return update(state, {
        $merge: {
          isLoadingTravelPathGeoStreets: false,
          streetNetwork: undefined,
          streetTurnRestrictions: undefined,
          streetNetworkBBOX: undefined,
        },
      });

    case START_APPLY_EDITS_TO_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isApplyingEditsToTravelPath: true,
        },
      });

    case COMPLETE_APPLY_EDITS_TO_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isApplyingEditsToTravelPath: false,
          hasAppliedEditsToTravelPath: true,
        },
      });

    case FAIL_APPLY_EDITS_TO_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isApplyingEditsToTravelPath: false,
        },
      });

    case START_PUBLISH_EDITS_TO_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isSavingEditsToTravelPath: true,
        },
      });

    case COMPLETE_PUBLISH_EDITS_TO_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isSavingEditsToTravelPath: false,
        },
      });

    case FAIL_PUBLISH_EDITS_TO_TRAVEL_PATH:
      return update(state, {
        $merge: {
          isSavingEditsToTravelPath: false,
        },
      });

    case START_FINISH_TRAVEL_PATH_EDIT:
      return update(state, {
        $merge: {
          isSavingEditsToTravelPath: true,
        },
      });

    case COMPLETE_FINISH_TRAVEL_PATH_EDIT:
      return update(state, {
        $merge: {
          isSavingEditsToTravelPath: false,
        },
      });

    case FAIL_FINISH_TRAVEL_PATH_EDIT:
      return update(state, {
        $merge: {
          isSavingEditsToTravelPath: false,
        },
      });

    case SET_IS_TRAVEL_PATH_LEGEND_OPEN:
      return update(state, {
        $merge: {
          isTravelPathLegendOpen: action.payload,
        },
      });

    case START_LOAD_BUILDER_FILTERS:
      return update(state, {
        $merge: {
          isLoadingTravelPathBuilderFilters: true,
        },
      });

    case COMPLETE_LOAD_BUILDER_FILTERS:
      return update(state, {
        $merge: {
          isLoadingTravelPathBuilderFilters: false,
          travelPathBuilderFilters: action.payload,
        },
      });

    case FAIL_LOAD_BUILDER_FILTERS:
      return update(state, {
        $merge: {
          isLoadingTravelPathBuilderFilters: false,
          travelPathBuilderFilters: undefined,
        },
      });

    case START_LOAD_VEHICLE_POSITION_AND_BREADCRUMB:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclePositionAndBreadcrumb: true,
        },
      });

    case COMPLETE_LOAD_VEHICLE_POSITION_AND_BREADCRUMB:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclePositionAndBreadcrumb: false,
          routeVehiclePositions: action.payload.vehiclePositions,
          routeVehicleBreadCrumbs: action.payload.vehicleBreadCrumbs,
        },
      });

    case FAIL_LOAD_VEHICLE_POSITION_AND_BREADCRUMB:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclePositionAndBreadcrumb: false,
          routeVehiclePositions: [],
          routeVehicleBreadCrumbs: undefined,
        },
      });

    case START_LOAD_ROUTE_VEHICLE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingRouteVehicleInformation: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_VEHICLE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingRouteVehicleInformation: false,
          routeVehicleInformation: action.payload,
        },
      });

    case FAIL_LOAD_ROUTE_VEHICLE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingRouteVehicleInformation: false,
          routeVehicleInformation: undefined,
        },
      });

    case START_EXTEND_TRAVEL_PATH_LOCK:
      return update(state, {
        $merge: {
          isExtendingTravelPathLock: true,
        },
      });

    case COMPLETE_EXTEND_TRAVEL_PATH_LOCK:
      return update(state, {
        $merge: {
          isExtendingTravelPathLock: false,
        },
      });

    case FAIL_EXTEND_TRAVEL_PATH_LOCK:
      return update(state, {
        $merge: {
          isExtendingTravelPathLock: false,
        },
      });

    case RESET_TRAVEL_PATH_BUILD_EDIT:
      return initialState;

    case START_TRAVEL_PATH_BUILDER_TRACING:
      return update(state, {
        $merge: {
          isDoingTravelPathBuilderTracing: true,
        },
      });

    case COMPLETE_TRAVEL_PATH_BUILDER_TRACING:
      return update(state, {
        $merge: {
          isDoingTravelPathBuilderTracing: false,
          didDoAnyTravelPathBuilderTracing: true,
        },
      });

    case FAIL_TRAVEL_PATH_BUILDER_TRACING:
      return update(state, {
        $merge: {
          isDoingTravelPathBuilderTracing: false,
        },
      });

    case RESET_TRAVEL_PATH_BUILD_TRACER:
      return update(state, {
        $merge: {
          didDoAnyTravelPathBuilderTracing: false,
          travelPathEditData: null,
        },
      });

    default:
      return state;
  }
};

// Action Creators
export const setSelectedSegment = (segment: any) => {
  return {
    type: SET_SELECTED_SEGMENT,
    payload: segment,
  };
};

export const startInitEditTravelPath = () => {
  return {
    type: START_INIT_EDIT_TRAVEL_PATH,
  };
};

export const completeInitEditTravelPath = (initEditConfigs: TravelPathInitConfigs) => {
  return {
    type: COMPLETE_INIT_EDIT_TRAVEL_PATH,
    initEditConfigs,
  };
};

export const failInitEditTravelPath = () => {
  return {
    type: FAIL_INIT_EDIT_TRAVEL_PATH,
  };
};

export const resetStartEndTravelPathEdit = () => {
  return {
    type: RESET_START_END,
  };
};

export const resetTravelPathBuildEdit = () => {
  return {
    type: RESET_TRAVEL_PATH_BUILD_EDIT,
  };
};

const startLoadMapFeatures = () => ({
  type: START_LOAD_MAP_FEATURES,
});

const completeLoadMapFeatures = (features: any, featureType: keyof FeatureTypes) => ({
  type: COMPLETE_LOAD_MAP_FEATURES,
  featureType,
  features,
});

const failLoadMapFeatures = (featureType: keyof FeatureTypes) => ({
  type: FAIL_LOAD_MAP_FEATURES,
  featureType,
});

export const resetMapFeatures = (featureType: keyof FeatureTypes) => ({
  type: RESET_MAP_FEATURES,
  featureType,
});

const startLoadTravelPathEdited = () => ({
  type: START_LOAD_TRAVEL_PATH_EDITED,
});

const completeLoadTravelPathEdited = (
  travelPathEditData: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TravelPathProperties>,
) => ({
  type: COMPLETE_LOAD_TRAVEL_PATH_EDITED,
  travelPathEditData,
});

const failLoadTravelPathEdited = () => ({
  type: FAIL_LOAD_TRAVEL_PATH_EDITED,
});

const startLoadTravelPathStreetNetwork = () => ({
  type: START_LOAD_TRAVEL_PATH_STREET_NETWORK,
});

const completeLoadTravelPathStreetNetwork = (
  streetNetwork: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>,
  turnRestrictions: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TurnRestrictionProperties>,
  bbox: turf.BBox,
) => ({
  type: COMPLETE_LOAD_TRAVEL_PATH_STREET_NETWORK,
  streetNetwork,
  streetTurnRestrictions: turnRestrictions,
  bbox,
});

const failLoadTravelPathStreetNetwork = (error: string) => ({
  type: FAIL_LOAD_TRAVEL_PATH_STREET_NETWORK,
  error,
});

const startApplyEditsToTravelPath = () => ({
  type: START_APPLY_EDITS_TO_TRAVEL_PATH,
});

const completeApplyEditsToTravelPath = () => ({
  type: COMPLETE_APPLY_EDITS_TO_TRAVEL_PATH,
});

const failApplyEditsToTravelPath = (error: string) => ({
  type: FAIL_APPLY_EDITS_TO_TRAVEL_PATH,
  error,
});

export const setIsTravelPathEditLegendOpen = (payload: boolean) => ({
  type: SET_IS_TRAVEL_PATH_LEGEND_OPEN,
  payload,
});

const startPublishEditsToTravelPath = () => ({
  type: START_PUBLISH_EDITS_TO_TRAVEL_PATH,
});

const completePublishEditsToTravelPath = () => ({
  type: COMPLETE_PUBLISH_EDITS_TO_TRAVEL_PATH,
});

const failPublishEditsToTravelPath = (error: string) => ({
  type: FAIL_PUBLISH_EDITS_TO_TRAVEL_PATH,
  error,
});

const startFinishTravelPathEdit = () => ({
  type: START_FINISH_TRAVEL_PATH_EDIT,
});

const completeFinishTravelPathEdit = () => ({
  type: COMPLETE_FINISH_TRAVEL_PATH_EDIT,
});

const failFinishTravelPathEdit = (error: string) => ({
  type: FAIL_FINISH_TRAVEL_PATH_EDIT,
  error,
});

const startLoadBuilderFilters = () => ({
  type: START_LOAD_BUILDER_FILTERS,
});

const completeLoadBuilderFilters = (payload: TravelPathBuilderFilterData) => ({
  type: COMPLETE_LOAD_BUILDER_FILTERS,
  payload,
});

const failLoadBuilderFilters = () => ({
  type: FAIL_LOAD_BUILDER_FILTERS,
});

const startLoadRouteVehiclePositionAndBreadcrumb = () => ({
  type: START_LOAD_VEHICLE_POSITION_AND_BREADCRUMB,
});

const completeLoadRouteVehiclePositionAndBreadcrumb = (payload: {
  vehiclePositions: RouteVehiclePosition[];
  vehicleBreadCrumbs?: RouteVehiclesBreadCrumbs;
}) => ({
  type: COMPLETE_LOAD_VEHICLE_POSITION_AND_BREADCRUMB,
  payload,
});

const failLoadRouteVehiclePositionAndBreadcrumb = () => ({
  type: FAIL_LOAD_VEHICLE_POSITION_AND_BREADCRUMB,
});

const startLoadRouteVehicleInformation = () => ({
  type: START_LOAD_ROUTE_VEHICLE_INFORMATION,
});

const completeLoadRouteVehicleInformation = (payload: any) => ({
  type: COMPLETE_LOAD_ROUTE_VEHICLE_INFORMATION,
  payload,
});

const failLoadRouteVehicleInformation = () => ({
  type: FAIL_LOAD_ROUTE_VEHICLE_INFORMATION,
});

const startExtendTravelPathLock = () => ({
  type: START_EXTEND_TRAVEL_PATH_LOCK,
});

const completeExtendTravelPathLock = () => ({
  type: COMPLETE_EXTEND_TRAVEL_PATH_LOCK,
});

const failExtendTravelPathLock = () => ({
  type: FAIL_EXTEND_TRAVEL_PATH_LOCK,
});

const startTravelPathBuilderTracing = () => ({
  type: START_TRAVEL_PATH_BUILDER_TRACING,
});

const completeTravelPathBuilderTracing = () => ({
  type: COMPLETE_TRAVEL_PATH_BUILDER_TRACING,
});

const failTravelPathBuilderTracing = () => ({
  type: FAIL_TRAVEL_PATH_BUILDER_TRACING,
});

export const resetTravelPathBuildTracer = () => ({
  type: RESET_TRAVEL_PATH_BUILD_TRACER,
});

// Thunks
export const loadGeoFencesForTravelPathModal = (payload: GeoQueryPayload) => (dispatch: Dispatch) => {
  dispatch(startLoadMapFeatures());
  const loadGeoFencesPromise = doLoadGeoFences(payload);

  loadGeoFencesPromise
    .then(geoFences => {
      dispatch(completeLoadMapFeatures(geoFences?.geoFences?.geoFences || [], 'geoFences'));
    })
    .catch(() => {
      dispatch(failLoadMapFeatures('geoFences'));
    });

  return loadGeoFencesPromise;
};

export const loadRouteStopsForTravelPathModal =
  (vendorId: number, routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
    dispatch(startLoadMapFeatures());
    let loadRouteStopsPromise;
    if (routeId) {
      loadRouteStopsPromise = doLoadRouteStopsForRoute(vendorId, routeId);
    } else if (routeTemplateId) {
      loadRouteStopsPromise = doLoadRouteTemplateRouteStops(routeTemplateId);
    } else {
      return Promise.reject();
    }

    loadRouteStopsPromise
      .then(routeStops => {
        dispatch(completeLoadMapFeatures(routeStops, 'routeStops'));
      })
      .catch(() => {
        dispatch(failLoadMapFeatures('routeStops'));
      });

    return loadRouteStopsPromise;
  };

export const loadHaulerLocationsForTravelPathModal = (vendorLocationTypes: string) => (dispatch: Dispatch) => {
  dispatch(startLoadMapFeatures());
  const loadHaulerLocationsPromise = doLoadHaulerLocations(vendorLocationTypes);

  loadHaulerLocationsPromise
    .then(haulerLocations => {
      dispatch(completeLoadMapFeatures(haulerLocations, 'haulerLocations'));
    })
    .catch(() => {
      dispatch(failLoadMapFeatures('haulerLocations'));
    });

  return loadHaulerLocationsPromise;
};

export const loadStreetNetworkForTravelPathModal =
  (vendorId: number, bbox: turf.BBox, configs: TravelPathInitConfigs) => (dispatch: Dispatch) => {
    if (!configs.streets.name || !configs.turnRestrictions.name) {
      dispatch(failLoadTravelPathStreetNetwork('errorWhileLoadingStreetNetwork'));
      return Promise.reject();
    }

    if (cancelTravelPathStreetNetworkRequest) cancelTravelPathStreetNetworkRequest();
    if (cancelTravelPathStreetsTurnRestrictionsRequest) cancelTravelPathStreetsTurnRestrictionsRequest();

    const cancelTokenStreets = new CancelToken(c => {
      cancelTravelPathStreetNetworkRequest = c;
    });

    const cancelTokenTurnRestrictions = new CancelToken(c => {
      cancelTravelPathStreetsTurnRestrictionsRequest = c;
    });

    dispatch(startLoadTravelPathStreetNetwork());

    const loadStreetNetworkPromise = doLoadTravelPathGeoLayers(
      vendorId,
      configs.streets.name,
      bbox,
      cancelTokenStreets,
    );

    const loadStreetTurnRestrictionsPromise = doLoadTravelPathGeoLayers(
      vendorId,
      configs.turnRestrictions.name,
      bbox,
      cancelTokenTurnRestrictions,
    );

    Promise.all([loadStreetNetworkPromise, loadStreetTurnRestrictionsPromise])
      .then(([streetNetwork, streetTurnRestrictions]) => {
        if (streetNetwork.error || streetTurnRestrictions.error) {
          dispatch(failLoadTravelPathStreetNetwork(streetNetwork.error.code || streetTurnRestrictions.error.code));
        } else {
          try {
            const parsedStreetNetwork = JSON.parse(streetNetwork.result);
            const parsedStreetTurnRestrictions = JSON.parse(streetTurnRestrictions.result);
            dispatch(completeLoadTravelPathStreetNetwork(parsedStreetNetwork, parsedStreetTurnRestrictions, bbox));
          } catch (e) {
            dispatch(failLoadTravelPathStreetNetwork('errorWhileLoadingStreetNetwork'));
          }
        }
      })
      .catch(() => {
        dispatch(failLoadTravelPathStreetNetwork('errorWhileLoadingStreetNetwork'));
      });
  };

export const loadTravelPathForBuildOrEdit =
  (vendorId: number, configs: TravelPathInitConfigs) => (dispatch: Dispatch) => {
    dispatch(startLoadTravelPathEdited());

    const loadTravelPathEditedPromise = doLoadTravelPathGeoLayers(vendorId, configs.path.name, undefined, undefined);

    loadTravelPathEditedPromise
      .then(travelPathEditData => {
        processTravelPathResponse(
          travelPathEditData,
          completeLoadTravelPathEdited,
          failLoadTravelPathEdited,
          dispatch,
          false,
          true,
        );
      })
      .catch(() => {
        dispatch(failLoadTravelPathEdited());
      });

    return loadTravelPathEditedPromise;
  };

export const loadRouteSegmentsForTravelPathModal =
  (vendorId: number, routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
    dispatch(startLoadMapFeatures());
    let loadRouteSegmentsPromise;

    if (routeId || routeTemplateId) {
      const includeRouteTemplateIds = undefined;
      const streetSegmentAssignedStatus = true;
      const streetSegmentAssignedTypesStatus = STREET_SEGMENT_ASSIGNED_TYPES_STATUSES[0].id;

      loadRouteSegmentsPromise = loadStreetNetwork(
        vendorId,
        routeId,
        routeTemplateId,
        includeRouteTemplateIds,
        streetSegmentAssignedStatus,
        streetSegmentAssignedTypesStatus,
      );
    } else {
      return Promise.reject();
    }

    loadRouteSegmentsPromise
      .then(routeSegments => {
        dispatch(completeLoadMapFeatures(routeSegments, 'routeSegments'));
      })
      .catch(() => {
        dispatch(failLoadMapFeatures('routeSegments'));
      });

    return loadRouteSegmentsPromise;
  };

export const initializeTravelPathBuildOrEdit =
  (vendorId: number, isBuilder: boolean, routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
    dispatch(startInitEditTravelPath());

    const initializeTravelPathBuildOrEditPromise = doInitializeTravelPathBuildOrEdit(
      vendorId,
      isBuilder,
      routeId,
      routeTemplateId,
    );

    initializeTravelPathBuildOrEditPromise
      .then(initEditConfigs => {
        processTravelPathResponse(
          initEditConfigs,
          completeInitEditTravelPath,
          failInitEditTravelPath,
          dispatch,
          true,
          false,
        );
      })
      .catch(() => {
        dispatch(failInitEditTravelPath());
      });

    return initializeTravelPathBuildOrEditPromise;
  };

export const applyEditsToTravelPath = (payload: ApplyEditsToTravelPathPayload) => (dispatch: Dispatch) => {
  dispatch(startApplyEditsToTravelPath());

  const applyEditsToTravelPathPromise = doApplyEditsToTravelPath(payload);

  applyEditsToTravelPathPromise
    .then(travelPathEditData => {
      processTravelPathResponse(
        travelPathEditData,
        completeApplyEditsToTravelPath,
        failApplyEditsToTravelPath,
        dispatch,
        true,
        false,
      );
    })
    .catch(() => {
      dispatch(failApplyEditsToTravelPath(translate('routes.travelPath.alertMessages.generalEditError')));
    });

  return applyEditsToTravelPathPromise;
};

export const publishEditsToTravelPath =
  (vendorId: number, isBuilder: boolean, routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
    dispatch(startPublishEditsToTravelPath());

    const publishEditsToTravelPathPromise = doPublishEditsToTravelPath(vendorId, isBuilder, routeId, routeTemplateId);

    publishEditsToTravelPathPromise
      .then(res => {
        processTravelPathResponse(
          res,
          completePublishEditsToTravelPath,
          failPublishEditsToTravelPath,
          dispatch,
          true,
          false,
        );
      })
      .catch(() => {
        dispatch(failPublishEditsToTravelPath(translate('routes.travelPath.alertMessages.generalEditError')));
      });

    return publishEditsToTravelPathPromise;
  };

export const finishTravelPathBuildOrEdit =
  (vendorId: number, isBuilder: boolean, routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
    dispatch(startFinishTravelPathEdit());

    const finishTravelPathBuildOrEditPromise = doFinishTravelPathBuildOrEdit(
      vendorId,
      isBuilder,
      routeId,
      routeTemplateId,
    );

    finishTravelPathBuildOrEditPromise
      .then(res => {
        processTravelPathResponse(res, completeFinishTravelPathEdit, failFinishTravelPathEdit, dispatch, true, false);
      })
      .catch(() => {
        dispatch(failFinishTravelPathEdit(translate('routes.travelPath.alertMessages.generalEditError')));
      });

    return finishTravelPathBuildOrEditPromise;
  };

export const loadTravelPathBuilderFilters = (vendorId: number, routeTemplateId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadBuilderFilters());

  const loadTravelPathBuilderFiltersPromise = doLoadTravelPathBuilderFilters(vendorId, routeTemplateId);

  loadTravelPathBuilderFiltersPromise
    .then(res => {
      dispatch(completeLoadBuilderFilters(res));
    })
    .catch(() => {
      dispatch(failLoadBuilderFilters());
    });

  return loadTravelPathBuilderFiltersPromise;
};

export const loadRouteVehiclePositionAndBreadcrumb =
  (routeId: number, date: string | Date, vehicleId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadRouteVehiclePositionAndBreadcrumb());

    if (cancelLoadRouteVehicleBreadCrumbRequest) cancelLoadRouteVehicleBreadCrumbRequest();
    if (cancelLoadRouteVehiclePositionRequest) cancelLoadRouteVehiclePositionRequest();

    const cancelTokenPosition = new CancelToken(c => {
      cancelLoadRouteVehiclePositionRequest = c;
    });

    const cancelTokenBreadCrumb = new CancelToken(c => {
      cancelLoadRouteVehicleBreadCrumbRequest = c;
    });

    const loadRouteVehiclePositionPromise = doLoadRouteVehiclePositions(routeId, date, vehicleId, cancelTokenPosition);
    const loadRouteVehicleBreadCrumbPromise = doLoadRouteVehiclesBreadCrumbs(
      routeId,
      date,
      vehicleId,
      cancelTokenBreadCrumb,
    );

    Promise.all([loadRouteVehiclePositionPromise, loadRouteVehicleBreadCrumbPromise])
      .then(([vehiclePositions, vehicleBreadCrumbs]) => {
        dispatch(
          completeLoadRouteVehiclePositionAndBreadcrumb({
            vehiclePositions: vehiclePositions?.vehiclePositions || [],
            vehicleBreadCrumbs,
          }),
        );
      })
      .catch(() => {
        dispatch(failLoadRouteVehiclePositionAndBreadcrumb());
      });
  };

// for api integrated with FleetRoute we have always result and error objects in response so that the endpoint return 200 status code
export const processTravelPathResponse = (
  response: any,
  onSuccess: any,
  onError: any,
  dispatch: Dispatch,
  withErrorNotification: boolean,
  shouldParse: boolean,
) => {
  if (response?.error?.code || !response) {
    const translatedError = translate(`routes.travelPath.alertMessages.${camelCase(response.error.code)}`);
    dispatch(onError(translatedError));
    if (withErrorNotification) {
      createErrorNotification(translatedError);
    }
  } else {
    try {
      const parsedResponse = shouldParse ? JSON.parse(response.result) : response.result;
      dispatch(onSuccess(parsedResponse));
    } catch (e) {
      const translatedError = translate('routes.travelPath.alertMessages.generalEditError');
      dispatch(onError(translatedError));
      if (withErrorNotification) {
        createErrorNotification(translatedError);
      }
    }
  }
};

export const loadRouteVehicleInformation = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteVehicleInformation());

  const loadRouteVehicleInformationPromise = doLoadRouteVehicleInformation(vehicleId, date);

  loadRouteVehicleInformationPromise
    .then(res => {
      dispatch(completeLoadRouteVehicleInformation(res));
    })
    .catch(() => {
      dispatch(failLoadRouteVehicleInformation());
    });

  return loadRouteVehicleInformationPromise;
};

export const extendTravelPathLock =
  (vendorId: number, isBuilder: boolean, routeId?: number, routeTemplateId?: number) => (dispatch: Dispatch) => {
    dispatch(startExtendTravelPathLock());

    const hoursToExtend = 1;

    const extendTravelPathLockPromise = doExtendTravelPathLock(
      vendorId,
      isBuilder,
      hoursToExtend,
      routeId,
      routeTemplateId,
    );

    extendTravelPathLockPromise
      .then(res => {
        processTravelPathResponse(res, completeExtendTravelPathLock, failExtendTravelPathLock, dispatch, true, false);
      })
      .catch(() => {
        dispatch(failExtendTravelPathLock());
      });

    return extendTravelPathLockPromise;
  };

export const travelPathBuilderDoTracing = (payload: TravelPathTracerPayload) => (dispatch: Dispatch) => {
  dispatch(startTravelPathBuilderTracing());

  const travelPathBuilderDoTracingPromise = doTravelPathBuilderDoTracing(payload);

  travelPathBuilderDoTracingPromise
    .then(res => {
      processTravelPathResponse(
        res,
        completeTravelPathBuilderTracing,
        failTravelPathBuilderTracing,
        dispatch,
        true,
        false,
      );
    })
    .catch(() => {
      dispatch(failTravelPathBuilderTracing());
    });

  return travelPathBuilderDoTracingPromise;
};
