import React from 'react';
import {
  Popover,
  DetailsListBodySection,
  DetailsListItemTitle,
  DetailsListItemDescription,
} from '../../../core/components/styled';
import { date } from '../../../utils/services/formatter';
import translate from '../../../core/services/translate';
import { PopoverDetailsContainer } from '../styled/RubiconDispatches';
import { DispatchStatus } from './RubiconDispatchesTableRow';

const renderDetail = (translation: string, value: string, isActive: boolean) => (
  <DetailsListBodySection>
    <DetailsListItemTitle isActive={isActive} size="small">
      {translate(translation)}
    </DetailsListItemTitle>
    <DetailsListItemDescription>{value}</DetailsListItemDescription>
  </DetailsListBodySection>
);

export const RubiconDispatchesServiceDatesPopover: React.SFC<{ serviceDates: any; status: string }> = ({
  serviceDates,
  status,
}) => (
  <Popover>
    <DetailsListItemDescription weight="medium" size="medium">
      {translate('autoDispatch.serviceDates')}
    </DetailsListItemDescription>
    <div>
      <PopoverDetailsContainer>
        {renderDetail(
          'autoDispatch.requestedDate',
          serviceDates.requestedDate && date(serviceDates.requestedDate),
          status === DispatchStatus.REQUESTED || status === DispatchStatus.PENDING || status === DispatchStatus.DECLINE,
        ) || '-'}
        {renderDetail(
          'opportunity.scheduledDate',
          serviceDates.scheduledDate && date(serviceDates.scheduledDate),
          status === DispatchStatus.SCHEDULED || status === DispatchStatus.DELIVERY_SUSPENDED,
        ) || '-'}
      </PopoverDetailsContainer>
      <PopoverDetailsContainer>
        {renderDetail(
          'autoDispatch.lastPickupDate',
          serviceDates.lastServiceDate && date(serviceDates.lastServiceDate),
          status === DispatchStatus.CLOSED,
        ) || '-'}
        {renderDetail(
          'autoDispatch.completedDate',
          serviceDates.completedDate && date(serviceDates.completedDate),
          status === DispatchStatus.CONFIRMED || status === DispatchStatus.COMPLETE,
        ) || '-'}
      </PopoverDetailsContainer>
    </div>
  </Popover>
);
