import React, { PureComponent } from 'react';

import TriggerMarker from './TriggerMarker';
import { RubiconMarkerClusterer } from '../../common/components/RubiconMarkerClusterer';

interface Props {
  triggers: any[];
  openedInfoWindows: any;
  toggleInfoWindow: (index?: string) => void;
}

class Triggers extends PureComponent<Props> {
  render() {
    const { triggers, openedInfoWindows, toggleInfoWindow } = this.props;

    return (
      <RubiconMarkerClusterer>
        {clusterer => (
          <>
            {triggers.map((trigger, index) => (
              <TriggerMarker
                key={index}
                index={index}
                trigger={trigger}
                isInfoWindowOpen={openedInfoWindows.triggers[index]}
                toggleInfoWindow={toggleInfoWindow}
                clusterer={clusterer}
              />
            ))}
          </>
        )}
      </RubiconMarkerClusterer>
    );
  }
}

export default Triggers;
