import { Modal, Table } from 'src/core/components';
import { ModalSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteTrackerCityAlertsModalTableRow from './RouteTrackerCityAlertsModalTableRow';
import translate from 'src/core/services/translate';

const routeTrackerCityAlertsTableCells = [
  {
    name: 'alertType',
    label: translate('vendors.cityAlerts.alertType'),
    width: '20%',
  },
  { name: 'address', label: translate('common.address'), width: '25%' },
  { name: 'timeStamp', label: translate('common.timestamp'), width: '20%' },
  { name: 'driverVehicle', label: `${translate('common.driver') + ' / ' + translate('common.vehicle')}`, width: '20%' },
  { name: 'images', label: translate('common.image'), width: '15%' },
];

interface Props {
  closeModal: () => void;
}

const RouteTrackerCityAlertsModal = (props: Props) => {
  const { closeModal } = props;
  const { cityAlerts } = useSelector(state => state.vendors.cityAlerts);

  return (
    <Modal title={translate('tooltips.cityAlerts')} onClose={closeModal} padding="medium" size="large">
      <ModalSection margin="medium no no">
        <Table
          cells={routeTrackerCityAlertsTableCells}
          rows={cityAlerts}
          rowComponent={RouteTrackerCityAlertsModalTableRow}
        />
      </ModalSection>
    </Modal>
  );
};

export default RouteTrackerCityAlertsModal;
