import { normalizeTimeDateToObject } from 'src/vendors/services/transformRouteSettings';
import { RESIDENTIAL_ID } from '../../fleet/constants';

const getRouteSequenceAdditionalConfigurations = (formData: any, vehicleTypeId: number) => {
  let additionalConfigurations = {};

  Object.entries(formData).forEach((obj: any) => {
    const key = obj[0];
    const additionalConfiguration = obj[1];
    const BOTH_SIDES = '1';

    if (Number(key) === Number(vehicleTypeId)) {
      additionalConfigurations = {
        maxRouteTime: normalizeTimeDateToObject(additionalConfiguration.maxRouteTime),
        postEndTime: normalizeTimeDateToObject(additionalConfiguration.postEndTime),
        preStartTime: normalizeTimeDateToObject(additionalConfiguration.preStartTime),
        routeStartTime: normalizeTimeDateToObject(additionalConfiguration.routeStartTime),
        serviceTimePerStop:
          vehicleTypeId === RESIDENTIAL_ID
            ? formData.serviceSide === BOTH_SIDES
              ? normalizeTimeDateToObject(additionalConfiguration.bothSidesServiceTimePerStop)
              : normalizeTimeDateToObject(additionalConfiguration.singleSideServiceTimePerStop)
            : normalizeTimeDateToObject(additionalConfiguration.serviceTimePerStop),
        breaks: [
          {
            ...additionalConfiguration.breaks[0],
            time: normalizeTimeDateToObject(additionalConfiguration.amBreakTime),
          },
          {
            ...additionalConfiguration.breaks[1],
            time: normalizeTimeDateToObject(additionalConfiguration.lunchBreakTime),
          },
          {
            ...additionalConfiguration.breaks[2],
            time: normalizeTimeDateToObject(additionalConfiguration.pmBreakTime),
          },
        ],
        vehicleStreetServicingSideId: Number(formData.serviceSide),
        vehicleCapacity: Number(additionalConfiguration.vehicleCapacity),
        intermediateLocationId: additionalConfiguration.intermediateLocationId,
      };
    }
  });

  return additionalConfigurations;
};

export default getRouteSequenceAdditionalConfigurations;
