import { reduce, identity, set, size } from 'lodash-es';
import { createSelector } from 'reselect';
import { DELIVERY, REMOVAL } from '../../fleet/constants/openDispatachesServices';

const getDeliveryFormInitialValues = (opportunityState: any, rubiconDispatchesState: any) => {
  const dispatchState =
    (size(rubiconDispatchesState) > 0 &&
      rubiconDispatchesState.filter(
        (disptach: any) => disptach.pickupTypeId === DELIVERY || disptach.pickupTypeId === REMOVAL,
      )) ||
    opportunityState;

  const deliveries = reduce(
    dispatchState,
    (initialValues, { caseId, agreement, response, itemId, status }) =>
      set(initialValues, `settings${caseId}`, {
        deliveryDate: agreement.deliveryDate,
        service: agreement.service,
        displayFees: agreement.displayFees,
        response,
        WasDelivered: { deliveryDate: agreement.deliveryDate },
        requestedChanges: { deliveryDate: agreement.deliveryDate },
        status,
        itemId,
      }),
    {},
  );
  return { deliveries };
};

const deliveryFormInitialValueSelector = createSelector(getDeliveryFormInitialValues, identity);

export default deliveryFormInitialValueSelector;
