import humps from 'humps';
import { camelCase, filter, get, join, map, reduce, size, uniq } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { INSIGHT_ICONS, PICKUP_STATUSES, ROUTE_PICKUP_TYPES, SCHEDULED } from 'src/common/constants';
import { ActionButtonTooltip } from 'src/core/components';
import LabeledDataView from 'src/core/components/LabeledDataView';
import {
  Button,
  DetailsListActionButton,
  DetailsListActionIcon,
  Grid,
  GridColumn,
  Link,
  TableActionButton,
  Text,
} from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import { CustomerProximitySearchModalResolver } from 'src/customers/components/modals';
import IssuesReportedModal from 'src/dashboard/components/modals/IssuesReportedModal';
import { WASTE_AUDIT_ID } from 'src/fleet/constants';
import { RouteImagesModal, StrobeImagesModalResolver } from 'src/routes/components/modals';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { time } from 'src/utils/services/formatter';
import { getYRouteStopIconType } from '../../../routes/routePageSections/routeMap/routeStops/utils';

export default function YRouteMapRouteStopsGLPopup() {
  const dispatch = useDispatch();

  const [isIssuesModalOpen, setIssuesModalOpen] = useState<boolean>(false);
  const [isIssueImageModalOpen, setIssueImageModalOpen] = useState<boolean>(false);
  const [isCustomerProximitySearchModalOpen, setCustomerProximitySearchModalOpen] = useState<boolean>(false);
  const [isStrobeImageModalOpen, setStrobeImageModalOpen] = useState<boolean>(false);

  const { selectedFeature } = useSelector((state: any) => state.routes.mapControls);
  const { routeLocations } = useSelector((state: any) => state.routes.route.route);
  const { containerInsights } = useSelector((state: any) => state.routes.routeMapDetails.routeMapDetails);
  const { strobeImages } = useSelector((state: any) => state.routes);

  const selectedRouteLocation: RouteLocation = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeStops) {
      return undefined;
    }

    return routeLocations.find((location: any) => location?.id === selectedFeature?.id);
  }, [routeLocations, selectedFeature]);

  if (!selectedRouteLocation) return null;

  const containerInsight = containerInsights.find((el: any) => el.insightId === selectedRouteLocation.id);
  const iconType = getYRouteStopIconType(selectedRouteLocation);
  const pickupStatusName = PICKUP_STATUSES[selectedRouteLocation.pickupStatusId].technicalName;
  const insightType = humps.camelize(pickupStatusName);

  const images = map(
    filter(containerInsight.insightSubTypes, (insightSubType: any) => !!insightSubType.imageUrl),
    (insightSubType: any) => ({
      customerName: containerInsight.customerName,
      imageUrl: insightSubType.imageUrl,
      rawImageUrl: insightSubType?.rawImageUrl || insightSubType.imageUrl,
      imageBlobId: insightSubType.imageBlobId,
      issueName: translate(`dashboard.${camelCase(insightSubType.name)}`),
      locationName: containerInsight.address,
      timeStamp:
        containerInsight.vehicleTypeId === WASTE_AUDIT_ID
          ? containerInsight.reportDateTime
          : get(insightSubType, 'pickupExceptionDetails.timeStamp'),
    }),
  );

  const translationKeys = {
    insightTypeName: iconType,
    insightMessageLabel: humps.camelize(containerInsight.insightMessageLabel),
  };

  const getExceptions = () =>
    reduce(
      containerInsight.insightSubTypes,
      (exceptions: any[], insightSubType: any, key: number) => {
        if (key > 0) {
          const insightSubTypeName = translate(`dashboard.${camelCase(insightSubType.name)}`);
          if (insightSubTypeName) {
            exceptions.push(insightSubTypeName);
          }
        }
        return exceptions;
      },
      [],
    ).join(', ');

  const getInsightMessages = () =>
    map(containerInsight.insightSubTypes, (insightSubType, index) => {
      const messageLabel =
        insightSubType.insightSubMessageLabel && humps.camelize(insightSubType.insightSubMessageLabel);
      const insightSubMessageValues = insightSubType.insightSubMessageValues
        ? uniq(insightSubType.insightSubMessageValues)
        : [];
      const messageValue = join(insightSubMessageValues, ', ');
      return (
        !!messageLabel && (
          <LabeledDataView
            key={index}
            width="calc(100% - 10px)"
            size="small"
            label={translate(`dashboard.${messageLabel}`)}
            value={messageValue}
          />
        )
      );
    });

  const getWasteAuditStatus = () =>
    map(
      containerInsight.insightSubTypes,
      (insightSubType, index: number) =>
        index > 0 && (
          <Box mt={5} display="flex" alignItems="center" key={index}>
            <Box mr={8} width={15} height={15}>
              <img src={INSIGHT_ICONS[humps.camelize(insightSubType.name)]} width="15" height="15" alt="" />
            </Box>
            <Text weight="medium" size="medium">
              {translate(`dashboard.${camelCase(insightSubType.name)}`)}
            </Text>
          </Box>
        ),
    );

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={selectedRouteLocation.location.address.latitude}
        longitude={selectedRouteLocation.location.address.longitude}
        onClose={() => dispatch(clearRouteMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={iconType?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${insightType}`)}
            afterTitleContent={
              <>
                {size(containerInsight.insightSubTypes) > 1 && containerInsight.vehicleTypeId !== WASTE_AUDIT_ID && (
                  <DetailsListActionButton onClick={() => setIssuesModalOpen(true)} margin="no no no xSmall">
                    <DetailsListActionIcon icon="issue" /> {size(containerInsight.insightSubTypes) - 1}
                  </DetailsListActionButton>
                )}

                {!!size(images) && containerInsight.vehicleTypeId === WASTE_AUDIT_ID && (
                  <DetailsListActionButton onClick={() => setIssueImageModalOpen(true)} margin="no xSmall">
                    <DetailsListActionIcon icon="image" /> {size(images)}
                  </DetailsListActionButton>
                )}
              </>
            }
            secondTitle={containerInsight.vehicleTypeId === WASTE_AUDIT_ID && getWasteAuditStatus()}
          />

          <MapGLPopupContent>
            <Grid>
              <GridColumn
                size={`${!!size(images) && containerInsight.vehicleTypeId === WASTE_AUDIT_ID ? '7/12' : '12/12'}`}
              >
                {!!containerInsight.vehicle.name && (
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('common.truck')}
                    value={containerInsight.vehicle.name}
                  />
                )}
                {!!containerInsight.reportDateTime && (
                  <LabeledDataView
                    noWrap
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('common.timestamp')}
                    value={time(containerInsight.reportDateTime)}
                  />
                )}
                {containerInsight.vehicleTypeId !== WASTE_AUDIT_ID && (
                  <LabeledDataView
                    noWrap
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('routes.pickupType')}
                    value={
                      ROUTE_PICKUP_TYPES[containerInsight.pickupType]
                        ? ROUTE_PICKUP_TYPES[containerInsight.pickupType].name
                        : '-'
                    }
                  />
                )}
                <LabeledDataView
                  noWrap
                  width="calc(100% - 10px)"
                  size="small"
                  label={translate('dashboard.stopNumber')}
                  value={selectedRouteLocation.orderNumber}
                />
                {!!containerInsight.route.name && (
                  <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('routes.route')}>
                    {containerInsight.routeId || containerInsight.isYRoute ? (
                      <Box textDecoration="underline">
                        <Link
                          to={
                            containerInsight.isYRoute
                              ? `/routes/y-daily-routes/${containerInsight.routeDetailId}`
                              : `/routes/route-tracker/${containerInsight.route.id}`
                          }
                        >
                          {containerInsight.route.name}
                        </Link>
                      </Box>
                    ) : (
                      containerInsight.route.name
                    )}
                  </LabeledDataView>
                )}
                {!!containerInsight.customerName && (
                  <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.customer')}>
                    {containerInsight.customerName}
                    <TableActionButton
                      margin="no no no xSmall"
                      color="primary"
                      onClick={() => setCustomerProximitySearchModalOpen(true)}
                    >
                      <ActionButtonTooltip
                        icon="searchVehicle"
                        size="sMedium"
                        sourceIsInfoWindow
                        tooltip="customerProximitySearch"
                      />
                    </TableActionButton>{' '}
                  </LabeledDataView>
                )}

                {containerInsight.address && (
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('common.address')}
                    value={containerInsight.address}
                  />
                )}

                {containerInsight.insightMessageValue && (
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate(`dashboard.${translationKeys.insightMessageLabel}`)}
                    value={containerInsight.insightMessageValue}
                  />
                )}

                {containerInsight.vehicleTypeId !== WASTE_AUDIT_ID && size(containerInsight.insightSubTypes) > 1 && (
                  <LabeledDataView
                    width="calc(100% - 10px)"
                    size="small"
                    label={translate('dashboard.exceptionType')}
                    value={getExceptions()}
                  />
                )}

                {containerInsight.vehicleTypeId === WASTE_AUDIT_ID &&
                  size(containerInsight.insightSubTypes) > 1 &&
                  getInsightMessages()}

                {!!size(strobeImages.closestStrobeImage) &&
                  containerInsight.vehicleTypeId !== WASTE_AUDIT_ID &&
                  containerInsight.insightSubTypes[0].name !== PICKUP_STATUSES[SCHEDULED].name && (
                    <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
                      <Box mt={5}>
                        <Button margin="no" padding="no" text onClick={() => setStrobeImageModalOpen(true)}>
                          <img alt="" width="100px" src={strobeImages.closestStrobeImage.imageUrl} />
                        </Button>
                      </Box>
                    </LabeledDataView>
                  )}
              </GridColumn>
            </Grid>
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isIssueImageModalOpen && <RouteImagesModal images={images} closeModal={() => setIssueImageModalOpen(false)} />}

      {isStrobeImageModalOpen && (
        <StrobeImagesModalResolver
          vehicleId={containerInsight.vehicle.id}
          vehicleName={containerInsight.vehicle.name}
          timeStamp={containerInsight.reportDateTime}
          closeModal={() => setStrobeImageModalOpen(false)}
        />
      )}

      {isIssuesModalOpen && (
        <IssuesReportedModal issues={containerInsight} closeModal={() => setIssuesModalOpen(false)} />
      )}

      {isCustomerProximitySearchModalOpen && (
        <CustomerProximitySearchModalResolver
          defaultDate={containerInsight.reportDateTime}
          locationId={selectedRouteLocation.id}
          onCancel={() => setCustomerProximitySearchModalOpen(false)}
        />
      )}
    </>
  );
}
