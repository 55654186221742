import { GeoJSONSource } from 'mapbox-gl';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getFeatureCollection } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import { RouteDriversVehiclesItem, RouteVehiclesBreadCrumbsItem } from 'src/dashboard/interfaces/routesData';
import { VehiclePosition } from 'src/routes/components/mapWithTimeline/Interfaces';
import { setRouteMapViewport } from 'src/routes/ducks';
import { setMapGeoJson, setMapGeoJsonForMap } from 'src/routes/ducks/routeMapSettings';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { RouteMapCityInsight } from 'src/routes/interfaces/RouteMapCityInsights';
import { RouteMapVehiclePosition, RouteMapVehicleTracking } from 'src/routes/interfaces/RouteMapVehicleData';
import { RouteMapVehicleInsight } from 'src/routes/interfaces/RouteMapVehicleInsights';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { getApplicationStatusForRouteGeoJSON, getApplicationStatusGeoJSON } from '../applicationStatus/utils';
import { getCityInsightsGeoJSON } from '../cityInsights/utils';
import { ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER, ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';
import { getHaulerLocationsGeoJSON } from '../haulerLocations/utils';
import { getRouteStopsGeoJSON, getYRouteStopsGeoJSON } from '../routeStops/utils';
import { getVehicleInsightsGeoJSON } from '../vehicleInsights/utils';
import { getVehiclePositionsGeoJSON, getYVehiclePositionsGeoJSON } from '../vehiclePositions/utils';
import RouteMapClustersGLSource from './RouteMapClustersGLSource';
import { getCityAlertsGeoJSON } from '../cityAlerts/utils';

type Props = {
  cityInsights: RouteMapCityInsight[];
  isTimelineMap?: boolean;
  isYRoute?: boolean;
  map: mapboxgl.Map;
  routeStops: RouteStop[];
  vehicleInsights: RouteMapVehicleInsight[];
  vehiclePositions: RouteMapVehiclePosition[] | VehiclePosition[];
  vehicleTrackings?: RouteVehiclesBreadCrumbsItem[];
  timelineVehicleTrackings?: RouteMapVehicleTracking[];
};

export default function RouteMapClustersGL({
  cityInsights,
  isTimelineMap,
  isYRoute,
  map,
  routeStops,
  vehicleInsights,
  vehiclePositions,
  vehicleTrackings,
  timelineVehicleTrackings,
}: Props) {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const vehicleTypeName = useSelector(state => state.routes.route.route?.vehicleTypeName);
  const { vendorLocations: haulerLocations } = useSelector(state => state.dashboard.vendorLocations);
  const cityAlerts = useSelector(state => state.vendors.cityAlerts.cityAlerts);
  const geoJson = useSelector(s => s.routes.routeMapSettings.mapGeoJson);
  const geoJsonForMap = useSelector(s => s.routes.routeMapSettings.mapGeoJsonForMap);
  const vehicleFilters = useSelector(
    state => state.routes.routeMapVehicleData.filters,
  ) as unknown as RouteDriversVehiclesItem[];

  useEffect(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (routeStops.length) {
      const routeStopsCollection = isYRoute
        ? getYRouteStopsGeoJSON(routeStops as unknown as RouteLocation[])
        : getRouteStopsGeoJSON(routeStops, routeSummary);
      collection.features = collection.features.concat(routeStopsCollection.features);
    }

    if (vehicleTrackings && vehicleTrackings.length) {
      const applicationStatusCollection = getApplicationStatusForRouteGeoJSON(vehicleTrackings, vehicleFilters);
      collection.features = collection.features.concat(applicationStatusCollection.features);
    }

    if (timelineVehicleTrackings && timelineVehicleTrackings.length) {
      const applicationStatusCollection = getApplicationStatusGeoJSON(timelineVehicleTrackings);
      collection.features = collection.features.concat(applicationStatusCollection.features);
    }

    if (!isTimelineMap && vehiclePositions && vehiclePositions.length) {
      const vehiclePositionsCollection = isYRoute
        ? getYVehiclePositionsGeoJSON(vehiclePositions as any, vehicleTypeName)
        : getVehiclePositionsGeoJSON(vehiclePositions, routeSummary?.vehicleTypeName);
      collection.features = collection.features.concat(vehiclePositionsCollection.features);
    }

    if (vehicleInsights.length) {
      const vehicleInsightsCollection = getVehicleInsightsGeoJSON(vehicleInsights, isYRoute, isTimelineMap);
      collection.features = collection.features.concat(vehicleInsightsCollection.features);
    }

    if (cityInsights.length) {
      const cityInsightsCollection = getCityInsightsGeoJSON(cityInsights);
      collection.features = collection.features.concat(cityInsightsCollection.features);
    }

    if (haulerLocations.length) {
      const haulerLocationsCollection = getHaulerLocationsGeoJSON(haulerLocations);
      collection.features = collection.features.concat(haulerLocationsCollection.features);
    }

    if (cityAlerts.length) {
      const cityAlertsCollection = getCityAlertsGeoJSON(cityAlerts);
      collection.features = collection.features.concat(cityAlertsCollection.features);
    }

    dispatch(isTimelineMap ? setMapGeoJsonForMap(collection) : setMapGeoJson(collection));
  }, [
    timelineVehicleTrackings,
    cityInsights,
    dispatch,
    haulerLocations,
    isTimelineMap,
    isYRoute,
    routeStops,
    routeSummary,
    routeSummary?.vehicleTypeName,
    vehicleInsights,
    vehiclePositions,
    vehicleTrackings,
    vehicleTypeName,
    vehicleFilters,
    cityAlerts,
  ]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER, event => {
        const [feature] = map
          .queryRenderedFeatures(event.point, {
            layers: [ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER],
          })
          .filter(feature => feature.source === ROUTE_MAP_CLUSTERS_SOURCE);

        const clusterId = feature.properties?.cluster_id;
        const source = map.getSource(ROUTE_MAP_CLUSTERS_SOURCE) as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          dispatch(
            setRouteMapViewport({
              latitude: (feature.geometry as any).coordinates[1],
              longitude: (feature.geometry as any).coordinates[0],
              zoom,
            }),
          );
        });
      });
      // moving the trackings layer before the clusters layer
      if (map.getLayer(ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER)) {
        if (map.getLayer('allVehicleTrackingsLine'))
          map.moveLayer('allVehicleTrackingsLine', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER);
        if (map.getLayer('allVehicleTrackingsPoints'))
          map.moveLayer('allVehicleTrackingsPoints', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER);
        if (map.getLayer('allVehicleTrackingsArrows'))
          map.moveLayer('allVehicleTrackingsArrows', ROUTE_MAP_CLUSTERS_CLUSTERS_LAYER);
      }
    });
  }, [map, dispatch]);

  return <RouteMapClustersGLSource geoJSON={isTimelineMap ? geoJsonForMap : geoJson} />;
}
