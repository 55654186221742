import { identity } from 'lodash-es';
import { createSelector } from 'reselect';
import moment from 'moment';

import { getQueryParams } from '../../utils/services/queryParams';
import { SEVEN_DAYS_AFTER_TODAY, TEN_DAYS_BEFORE_TODAY } from '../../core/constants/weekdays';
import { YES } from '../constants/status';

const getRubiconDispatchesFormInitialValues = (queryString: string) => {
  const { showActionRequired, startDate, endDate } = getQueryParams(queryString);
  const isActionRequired = showActionRequired === YES ? true : false;
  const fromDate = isActionRequired ? moment().subtract(60, 'days').format('MM/DD/YYYY') : TEN_DAYS_BEFORE_TODAY;
  const toDate = isActionRequired ? moment().add(30, 'days').format('MM/DD/YYYY') : SEVEN_DAYS_AFTER_TODAY;
  return {
    date: {
      from: startDate || fromDate,
      to: endDate || toDate,
    },
    isActionRequired
  };
};

const rubiconDispatchesFormInitialValuesSelector = createSelector(getRubiconDispatchesFormInitialValues, identity);

export default rubiconDispatchesFormInitialValuesSelector;
