import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { changePassword as doChangePassword } from '../services/changePassword';

// Actions
const START_CHANGE_PASSWORD = 'vendors/changePassword/START_CHANGE_PASSWORD';
export const COMPLETE_CHANGE_PASSWORD = 'vendors/changePassword/COMPLETE_CHANGE_PASSWORD';
const FAIL_CHANGE_PASSWORD = 'vendors/changePassword/FAIL_CHANGE_PASSWORD';
const RESET = 'vendors/changePassword/RESET';

// Initial state
const initialState = {
  isChangingPassword: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_CHANGE_PASSWORD:
      return update(state, {
        $merge: { isChangingPassword: true },
      });

    case COMPLETE_CHANGE_PASSWORD:
      return update(state, {
        $merge: { isChangingPassword: false },
      });

    case FAIL_CHANGE_PASSWORD:
      return update(state, {
        $merge: { isChangingPassword: false },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startChangePassword = () => ({
  type: START_CHANGE_PASSWORD,
});

const completeChangePassword = () => ({
  type: COMPLETE_CHANGE_PASSWORD,
});

const failChangePassword = () => ({
  type: FAIL_CHANGE_PASSWORD,
});

export const changePassword = (data: any) => (dispatch: Dispatch) => {
  dispatch(startChangePassword());
  const changePasswordPromise = doChangePassword(data);
  changePasswordPromise.then(() => dispatch(completeChangePassword())).catch(() => dispatch(failChangePassword()));
  return changePasswordPromise;
};

export const resetChangePassword = () => ({
  type: RESET,
});
