import { http } from '../../core/services/http';
import transformUnassignedLocations from './transformUnassignedLocations';

// NOTE: Temporarly we don't filter on waste type . See RVP-2001

export const loadRouteTemplateUnassignedLocations = (
  vendorId: number,
  vehicleTypeId: number,
  scheduledDay: Date | string,
  page = 1,
) =>
  http
    .get('/routeTemplates/unassignedServices', {
      params: { vendorId, vehicleTypeId, schedulerCode: scheduledDay, page },
    })
    .then(response => transformUnassignedLocations(response.data));
