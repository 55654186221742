import { FC } from 'react';
import moment from 'moment';

import { Box } from 'src/core/components/styled/Box';
import { getGroupsAssigned } from '../routes/SnowSweeperRouteHeadingDetails';
import {
  RouteDetail,
  RouteDetailLabel,
  RouteDetailValue,
  RouteDetailsWrapper,
  Wrapper,
} from 'src/routes/components/styled/RouteDetails';
import { ROUTE_PRIORITY_TYPES } from 'src/routes/constants/routePriorityTypes';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  isSnowPlowRoute?: boolean;
}

const SnowOrSweeperRouteTemplateSummary: FC<Props> = ({ isSnowPlowRoute }) => {
  const routeTemplate = useSelector(state => state.routes.routeTemplate.routeTemplate);

  if (!routeTemplate) return null;

  return (
    <Wrapper>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.vehicleType')}</RouteDetailLabel>
          <RouteDetailValue>
            {VEHICLE_TYPE_IDS[isSnowPlowRoute ? SNOW_PLOW_ID : STREET_SWEEPER_ID]?.name}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.snowPlow.streetSegments')}</RouteDetailLabel>
          <RouteDetailValue>{routeTemplate.routeLocations?.length}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('common.startDate')}</RouteDetailLabel>
          <RouteDetailValue>
            {routeTemplate.startDate ? moment(routeTemplate.startDate).format('MM/DD/YYYY') : '-'}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.snowPlow.priority')}</RouteDetailLabel>
          <RouteDetailValue>
            {routeTemplate.routePriorityTypeId ? ROUTE_PRIORITY_TYPES[routeTemplate.routePriorityTypeId]?.label : '-'}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.groups.groups')}</RouteDetailLabel>
          <RouteDetailValue>
            <Box maxWidth={170}>{getGroupsAssigned(routeTemplate.groups)}</Box>
          </RouteDetailValue>
        </RouteDetail>
      </RouteDetailsWrapper>
    </Wrapper>
  );
};

export default SnowOrSweeperRouteTemplateSummary;
