import React, { PureComponent, Fragment } from 'react';

import { camelCase } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  Popover,
  Text,
} from '../../../core/components/styled';
import { Input, Checkbox, PopoverWrapper } from '../../../core/components';
import { isEmail, isPhone, isRequired, isRequiredTrimmedValue } from '../../../utils/services/validator';
import contactEditorFormInitialValuesSelector from '../../services/contactEditorFormInitialValuesSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import normalizePhone from '../../../core/services/normalizePhone';
import translate from '../../../core/services/translate';

interface ComponentProps extends RouteComponentProps {
  contactId?: number;
  functionTypes: any;
  onCancel: () => void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class ContactEditorForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, functionTypes, initialValues } = this.props;

    return (
      <Fragment>
        <ModalClose onClick={() => onCancel()}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit} noValidate>
          <Grid margin="large no no" multiLine>
            <GridColumn size="12/12">
              <Field
                name="firstName"
                component={Input}
                label={translate('common.name')}
                validate={[isRequired, isRequiredTrimmedValue]}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                name="phoneNumber"
                component={Input}
                type="text"
                label={translate('common.phone')}
                format={normalizePhone}
                validate={[isRequired, isPhone]}
                margin="no no medium no"
              />
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                name="emailAddress"
                component={Input}
                label={translate('common.email')}
                validate={[isRequired, isEmail]}
                margin="no no medium no"
              />
            </GridColumn>

            <GridColumn size="12/12">
              <PopoverWrapper
                triggerButton={
                  <Field
                    name="isPrimary"
                    component={Checkbox}
                    label={translate('haulerProfile.primaryContact')}
                    disabled={initialValues.isPrimary}
                  />
                }
                popoverContent={
                  initialValues.isPrimary && (
                    <Popover>{translate('haulerProfile.alertMessages.primaryContactMessageRemoval')}</Popover>
                  )
                }
              />
            </GridColumn>

            <GridColumn size="12/12">
              <Text block size="medium" weight="light" margin="medium no xSmall no">
                {translate('common.roles')}
              </Text>
            </GridColumn>
          </Grid>
          <Grid multiLine>
            {functionTypes.map((obj: any) => (
              <GridColumn size="4/12" key={obj.id}>
                <Field
                  name={`contactTypeId:${obj.id}`}
                  component={Checkbox}
                  label={translate(`haulerProfile.roles.${camelCase(obj.title)}`)}
                  margin="no no small"
                  key={obj.id}
                  block
                />
              </GridColumn>
            ))}
          </Grid>
          <Grid centered>
            <GridColumn size="12/12">
              <ButtonSet margin="large no no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: (contactEditorFormInitialValuesSelector as any)(state.haulerProfile.contacts, ownProps.contactId),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'contactEditorForm',
      onSubmitFail: focusFirstInvalidField,
    })(ContactEditorForm),
  ),
);
