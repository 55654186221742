import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { NavLink } from 'react-router-dom';

import { BareButtonStyle } from './Button';
import { sizeMapper } from '../../../utils/styles';

export const Tab = styled.button<{ flexDisplay?: boolean; isSelected?: boolean; small?: boolean }>`
  ${BareButtonStyle};

  ${props =>
    props.flexDisplay &&
    css`
      flex: 1;
    `}

  padding: ${props => (props.small ? '5px 10px' : '10px 40px')};
  border-right: 1px solid ${props => props.theme.brandPrimary};
  text-transform: capitalize;
  font-weight: ${props => props.theme.fontWeightLight};
  color: ${props => props.theme.brandPrimary};

  &:first-child {
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-right: none;
    border-radius: 0 3px 3px 0;
  }

  ${props =>
    props.isSelected &&
    css`
      background-color: ${props => props.theme.brandPrimary};
      color: #fff;
    `};
`;

export const TabLink = (styled as any)(Tab.withComponent(NavLink))`
  &.active {
    background-color: ${(props: any) => props.theme.brandPrimary};
    color: #fff;
  }
`;

export const Tabs = styled.div<{ fullWidth?: boolean; margin?: string }>`
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  margin: ${props => sizeMapper(props.margin, 'no')};

  display: inline-flex;
  background-color: #fff;
  border: 1px solid ${props => props.theme.brandPrimary};
  border-radius: 3px;
  box-shadow: 0 2px 4px ${props => transparentize(0.9, props.theme.grayBase)};
`;
