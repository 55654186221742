import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { RouteImagesTableRow } from '.';
import { Table } from '../../../../../core/components';
import { Message, PanelSection } from '../../../../../core/components/styled';
import translate from '../../../../../core/services/translate';
import { AppState } from '../../../../../store';
import { RouteImage } from '../../../../interfaces/RouteImages';

const getRouteImagesTableCells = (isSnowPlowRoute: boolean) => {
  return [
    {
      name: 'stopNumber',
      label: translate(isSnowPlowRoute ? 'routes.snowPlow.order' : 'routes.stopNumber'),
      width: '10%',
    },
    { name: 'customerName', label: translate('common.customer'), width: '25%' },
    { name: 'timeStamp', label: translate('common.timestamp'), width: '15%' },
    { name: 'exception', label: translate('common.exception'), width: '15%' },
    { name: 'routeLocationStatusId', label: translate('common.status'), width: '15%' },
    { name: 'images', label: translate('common.image'), width: '10%' },
    { name: 'options', label: translate('common.options'), width: '10%', align: 'right' },
  ];
};

interface RouteImagesProps {
  onLoadTab?: () => void;
  routeId?: number;
  routeImages: RouteImage[];
  vehicleTypeId?: number;
  isSnowPlowRoute: boolean;
}

const RouteImages = ({ onLoadTab, routeId, routeImages, vehicleTypeId, isSnowPlowRoute }: RouteImagesProps) => {
  useEffect(() => {
    !!onLoadTab && onLoadTab();
  }, [onLoadTab]);

  return (
    <PanelSection id={`route-${routeId}-route-images-form`} vertical>
      {!!routeImages.length && (
        <Table
          cells={getRouteImagesTableCells(isSnowPlowRoute)}
          rowProps={{ vehicleTypeId, routeId }}
          rows={routeImages}
          rowComponent={RouteImagesTableRow}
        />
      )}

      {!routeImages.length && <Message padding="sMedium">{translate('routes.noRouteImages')}</Message>}
    </PanelSection>
  );
};

const mapStateToProps = (state: AppState) => {
  const route = state.routes.route.route as any;
  return {
    routeId: route && route.id,
    routeImages: state.routes.routeImages.routeImages,
    vehicleTypeId: route && route.vehicleTypeId,
  };
};

export default connect(mapStateToProps)(RouteImages);
