import { ChangeEvent, PureComponent } from 'react';

import { filter, map } from 'lodash-es';
import moment from 'moment';
import { connect } from 'react-redux';
import { InjectedFormProps, formValueSelector, reduxForm } from 'redux-form';

import { PermissionGuard } from 'src/account/components';
import { ROUTES_TRACKER_VIEW_EDIT } from 'src/account/constants';
import { WasteTypeDropdown } from 'src/common/components';
import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID, SCHEDULED } from 'src/common/constants';
import { getExcludeVehicleFilters, getExcludedFiltersIds } from 'src/common/utils/filters';
import { Checkbox, DatePicker, Dropdown, Input, PopoverWrapper, TypedField } from 'src/core/components';
import { DropdownOption } from 'src/core/components/Dropdown';
import TimePickerField from 'src/core/components/TimePickerField';
import { Button, ButtonSet, Grid, GridColumn, PanelSection, Popover } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TODAY, TODAY_FORMATTED } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { customerLocationsSelector } from 'src/customers/ducks';
import { VehicleDropdown, VehicleTypeForVendorDropdown } from 'src/fleet/components';
import { DELIVERY_UTILITY, FRONT_LOAD, RESIDENTIAL, ROLL_OFF, TOTER } from 'src/fleet/constants';
import { technicalNameByVehicleTypeIdSelector } from 'src/fleet/ducks';
import {
  ConfirmationTypeDropdown,
  GroupsMultiSelect,
  ServiceZoneDropdown,
  SupervisorDropdown,
} from 'src/routes/components';
import { InfoIcon } from 'src/routes/components/styled';
import { DISPATCH_BOARD_FORM_NAME } from 'src/routes/constants/dispatchBoard';
import { resetDispatchBoardRoute } from 'src/routes/ducks/dispatchBoard';
import { Group } from 'src/routes/interfaces/Groups';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import dispatchBoardRouteEditorFormInitialValuesSelector from 'src/routes/services/dispatchBoardRouteEditorFormInitialValuesSelector';
import { AppState } from 'src/store';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { isDateValidValidator, isRequired, isValidEndTime, maxLength50 } from 'src/utils/services/validator';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DISPATCH_BOARD_TARGET_FORM_NAME } from './DispatchBoardTargetForm';

const DISPATCH_BOARD_ROUTE_EDITOR_FORM = 'dispatchBoardRouteEditorForm';

interface PropsWithoutReduxForm {
  canChangeDate?: boolean;
  truckYardFacilities?: DropdownOption[];
  disposalFacilities?: DropdownOption[];
  disableVehicleType?: boolean;
  endTime?: string;
  excludeVehicleFilters?: string[];
  filterPreferences?: FilterSetting[];
  handleDateChange: (date: any | Date | string) => void;
  isLoadingSupervisors?: boolean;
  isLoadingVehicles?: boolean;
  isRouteInThePast?: boolean;
  isSaving?: boolean;
  jobIds?: number[];
  groups?: Group[];
  resetDispatchBoardRoute?: () => void;
  routeId?: number;
  serviceZones?: ServiceZone[];
  startTime?: string;
  supervisorExperienceEnabled?: boolean;
  supervisors?: Supervisor[];
  vehicleTypeId?: number;
  vehicleTypesForVendorState?: any;
  vehicleTypeTechnicalName?: string;

  /** TODO Intermediate Facility */
  // disposalFacilities: Facility[],
}

interface FormData {
  date: Date;
  endTime: string;
  intermediateLocationId: number;
  isTestRoute: boolean;
  routeName: string;
  startingLocationId: number;
  startTime: string;
  vehicleId: number;
  vehicleType: string;
  vehicleTypeId: SVGAnimatedNumberList;
  wasteMaterialTypeId: number;
  groupIds: number[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

class DispatchBoardRouteEditorForm extends PureComponent<Props, { isLoading: boolean }> {
  state = { isLoading: false };

  componentWillUnmount() {
    const { resetDispatchBoardRoute } = this.props;
    resetDispatchBoardRoute && resetDispatchBoardRoute();
  }

  onVehicleTypeChange = (event: ChangeEvent<HTMLInputElement>, vehicleTypeId: number) => {
    const { vehicleTypesForVendorState, change } = this.props;
    const vehicleTypeTechnicalName = technicalNameByVehicleTypeIdSelector(vehicleTypesForVendorState, vehicleTypeId);

    if (vehicleTypeTechnicalName === ROLL_OFF || vehicleTypeTechnicalName === DELIVERY_UTILITY) {
      change('wasteMaterialTypeId', '');
    }
  };

  onStartingLocationChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    const { change } = this.props;
    change('endingLocationId', value);
  };

  render() {
    const {
      canChangeDate,
      truckYardFacilities,
      disposalFacilities,
      disableVehicleType,
      endTime,
      excludeVehicleFilters,
      filterPreferences,
      handleDateChange,
      handleSubmit,
      initialValues: { startTime: initialStartTime, endTime: initialEndTime, groupIds },
      isLoadingSupervisors,
      isLoadingVehicles,
      isRouteInThePast,
      isSaving,
      routeId,
      serviceZones,
      startTime,
      supervisorExperienceEnabled,
      supervisors,
      vehicleTypeId,
      vehicleTypeTechnicalName,
      groups,

      /** TODO Intermediate Facility */
      // disposalFacilities,
    } = this.props;
    const { isLoading } = this.state;

    const isEditing = !!routeId;

    const excludeVehicleTypes = getExcludeVehicleFilters(filterPreferences);
    const excludedServiceZones = getExcludedFiltersIds(serviceZones, filterPreferences, FILTER_SERVICE_ZONE_ID);
    const excludedSupervisors = getExcludedFiltersIds(supervisors, filterPreferences, FILTER_SUPERVISOR_ID);

    // groupIds to exclude those inactive groups  except if initial value is inactive
    const groupIdsToExclude = map(
      filter(groups, group => !groupIds?.includes(group.id) && !group.isActive),
      'id',
    );

    let customerLocations = [] as any[];
    if (!!truckYardFacilities && !!disposalFacilities)
      customerLocations = [...truckYardFacilities, ...disposalFacilities];

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection
          padding="small xSmall no"
          isLoading={isLoading || isSaving || isLoadingSupervisors || isLoadingVehicles}
        >
          <Grid multiLine>
            <GridColumn size="12/12">
              <TypedField
                name="routeName"
                component={Input}
                validate={[isRequired, maxLength50]}
                props={{
                  label: translate('routes.routeName'),
                }}
              />
            </GridColumn>

            {isEditing ? (
              <GridColumn size="6/12">
                <TypedField
                  name="vehicleType"
                  component={Input}
                  props={{
                    label: translate('vehicles.vehicleType'),
                    disabled: true,
                  }}
                />
              </GridColumn>
            ) : (
              <GridColumn size="6/12">
                <TypedField
                  name="vehicleTypeId"
                  component={VehicleTypeForVendorDropdown}
                  validate={[isRequired]}
                  onChange={this.onVehicleTypeChange}
                  props={{
                    excludeVehicleTypes: [...(excludeVehicleFilters || []), ...excludeVehicleTypes],
                    disabled: disableVehicleType,
                    label: translate('vehicles.vehicleType'),
                    acceptedVehicleTypes: [FRONT_LOAD, ROLL_OFF, TOTER, DELIVERY_UTILITY, RESIDENTIAL],
                  }}
                />
              </GridColumn>
            )}

            <GridColumn size="6/12">
              <TypedField
                name="date"
                component={DatePicker}
                validate={[isRequired, isDateValidValidator]}
                props={{
                  label: translate('common.date'),
                  disabled: !canChangeDate,
                  disabledDays: [{ before: TODAY }],
                }}
                onChange={handleDateChange}
              />
            </GridColumn>

            <GridColumn size="6/12" margin="no no sMedium no">
              <TimePickerField
                defaultValue={initialStartTime}
                labelName={translate('routes.scheduledStartTime')}
                name="startTime"
                validate={!!endTime ? [isRequired] : undefined}
                formName={DISPATCH_BOARD_ROUTE_EDITOR_FORM}
                minuteStep={15}
              />
            </GridColumn>

            <GridColumn size="6/12" margin="no no sMedium no">
              <TimePickerField
                defaultValue={initialEndTime}
                labelName={translate('routes.scheduledEndTime')}
                name="endTime"
                validate={startTime ? [isRequired, isValidEndTime] : [isValidEndTime]}
                formName={DISPATCH_BOARD_ROUTE_EDITOR_FORM}
                minuteStep={15}
              />
            </GridColumn>

            <GridColumn size="12/12">
              <TypedField
                name="description"
                component={Input}
                props={{ label: translate('routes.description'), autoComplete: 'off' }}
                validate={[maxLength50]}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="vehicleId"
                component={VehicleDropdown}
                props={{
                  vehicleTypeId,
                  dropdownProps: {
                    disabled: isEditing,
                    isClearable: true,
                  },
                  withLabel: true,
                  shouldGroupByAvailability: true,
                }}
              />
            </GridColumn>

            {vehicleTypeTechnicalName !== ROLL_OFF && vehicleTypeTechnicalName !== DELIVERY_UTILITY && (
              <GridColumn size="6/12">
                <TypedField
                  name="wasteMaterialTypeId"
                  component={WasteTypeDropdown}
                  validate={[isRequired]}
                  props={{
                    dropdownProps: {
                      disabled: isEditing,
                    },
                    withLabel: true,
                  }}
                />
              </GridColumn>
            )}

            <GridColumn size="6/12">
              <TypedField
                name="startingLocationId"
                component={Dropdown}
                onChange={this.onStartingLocationChange}
                props={{
                  options: truckYardFacilities,
                  label: translate('routes.startingLocation'),
                  isClearable: true,
                  disabled: isEditing,
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <TypedField
                name="endingLocationId"
                component={Dropdown}
                props={{
                  options: customerLocations,
                  label: translate('routes.endingLocation'),
                  isClearable: true,
                  disabled: isEditing,
                }}
              />
            </GridColumn>

            {vehicleTypeTechnicalName === RESIDENTIAL && (
              <GridColumn size="6/12">
                <TypedField
                  name="routeConfirmationTypeId"
                  component={ConfirmationTypeDropdown}
                  props={{
                    label: translate('routes.confirmationType'),
                    dropdownProps: { disabled: isRouteInThePast || isEditing },
                  }}
                  validate={[isRequired]}
                />
              </GridColumn>
            )}

            <GridColumn size="6/12">
              <TypedField
                name="vendorServiceZoneId"
                component={ServiceZoneDropdown}
                props={{
                  withLabel: true,
                  excludeServiceZonesIds: excludedServiceZones,
                  dropdownProps: {
                    menuPosition: 'fixed',
                    width: '240px',
                  },
                }}
              />
            </GridColumn>

            <GridColumn size="6/12">
              <Box display="flex" alignItems="center">
                <TypedField
                  name="groupIds"
                  component={GroupsMultiSelect}
                  props={{
                    withLabel: true,
                    excludeGroupsIds: groupIdsToExclude,
                    cantSelectInactive: true,
                    multiSelectProps: {
                      defaultToAll: false,
                      minWidth: '240px',
                      maxWidth: '240px',
                    },
                  }}
                />
                <PopoverWrapper
                  margin="no no no small"
                  triggerButton={<InfoIcon />}
                  popoverContent={<Popover>{translate('routes.groups.groupChangeMessage')}</Popover>}
                  size="large"
                />
              </Box>
            </GridColumn>

            {supervisorExperienceEnabled && (
              <GridColumn size="6/12">
                <Box display="flex" alignItems="center">
                  <TypedField
                    name="supervisorId"
                    component={SupervisorDropdown}
                    props={{
                      withLabel: true,
                      excludeSupervisorsIds: excludedSupervisors,
                      shouldGroupByAvailability: true,
                      dropdownProps: {
                        menuPosition: 'fixed',
                        width: '240px',
                      },
                    }}
                  />
                  <PopoverWrapper
                    margin="no no no small"
                    triggerButton={<InfoIcon />}
                    popoverContent={<Popover>{translate('routes.supervisorsMessage')}</Popover>}
                    size="large"
                  />
                </Box>
              </GridColumn>
            )}

            {/** TODO Intermediate Facility */}
            {/*
              <GridColumn size="6/12">
                <TypedField
                  name="intermediateLocationId"
                  component={Dropdown}
                  props={{
                    isClearable: true,
                    label: translate('routes.intermediateFacility'),
                    width: '100%',
                    options: disposalFacilities.map(facility => ({
                      label: facility.name,
                      value: facility.locationId,
                    })),
                  }}
                />
              </GridColumn>
              */}

            <PermissionGuard permission={ROUTES_TRACKER_VIEW_EDIT}>
              <GridColumn size="12/12" padding="sMedium xSmall">
                <PopoverWrapper
                  triggerButton={
                    <TypedField
                      component={Checkbox}
                      name="isTestRoute"
                      props={{
                        label: translate('routes.testRoute'),
                      }}
                    />
                  }
                  popoverContent={<Popover>{translate('routes.testRoutePopover')}</Popover>}
                />
              </GridColumn>
            </PermissionGuard>

            <GridColumn size="12/12">
              <ButtonSet margin="large auto no">
                <Button type="submit" color="primary" onClick={handleSubmit} id="save-route-button">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const formSelector = formValueSelector(DISPATCH_BOARD_ROUTE_EDITOR_FORM);
const dispatchBoardFormSelector = formValueSelector(DISPATCH_BOARD_FORM_NAME);
const dispatchBoardTargetFormSelector = formValueSelector(DISPATCH_BOARD_TARGET_FORM_NAME);

const mapStateToProps = (state: AppState, ownProps: PropsWithoutReduxForm) => {
  const {
    routes: { dispatchBoard },
  } = state;

  const { route } = dispatchBoard.route;

  const vehicleTypeId = formSelector(state, 'vehicleTypeId');
  const startTime = formSelector(state, 'startTime');
  const endTime = formSelector(state, 'endTime');

  const vehicleTypesForVendorState = state.fleet.vehicleTypesForVendor;
  const dispatchBoardFormVehicleTypeId: number = dispatchBoardFormSelector(state, 'vehicleTypeId');
  const dispatchBoardTargetFormDate = dispatchBoardTargetFormSelector(state, 'targetDate');
  const vendorId = (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor);

  const dateInitialValue = moment(dispatchBoardTargetFormDate, 'MM/DD/YYYY').isBefore(moment(), 'day')
    ? TODAY_FORMATTED
    : dispatchBoardTargetFormDate;

  const isRouteInThePast = dateInitialValue && moment(dateInitialValue, 'MM/DD/YYYY').isBefore(moment().startOf('day'));
  const isRouteScheduled = route?.routeStatusTypeId === SCHEDULED;
  const canChangeDate = ownProps.routeId === null || !route || (!isRouteInThePast && isRouteScheduled);

  return {
    canChangeDate,
    truckYardFacilities: customerLocationsSelector(state.fleet.facilities.operationalFacilities) || [],
    disposalFacilities: customerLocationsSelector(state.fleet.facilities.disposalFacilities) || [],
    endTime,
    excludeVehicleFilters: getExcludeVehicleFilters(state.common.filters.filters),
    filterPreferences: state.common.filters.filters,
    initialValues: dispatchBoardRouteEditorFormInitialValuesSelector({
      vendorId,
      route,
      jobIds: ownProps.jobIds,
      vehicleTypesForVendorState: state.fleet.vehicleTypesForVendor,
      vehicleTypeId: dispatchBoardFormVehicleTypeId,
      date: dateInitialValue,
    }),
    isLoadingSupervisors: state.routes.supervisors.supervisorsLoading,
    isLoadingVehicles: state.fleet.vehicles.isLoading,
    isRouteInThePast,
    isSaving: dispatchBoard.route.isSaving,
    serviceZones: state.routes.serviceZones.serviceZones,
    startTime,
    supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
    supervisors: state.routes.supervisors.supervisors,
    vehicleTypeId,
    vehicleTypesForVendorState,
    vehicleTypeTechnicalName: technicalNameByVehicleTypeIdSelector(vehicleTypesForVendorState, vehicleTypeId),
    groups: state.routes.groups.groups,

    /** TODO Intermediate Facility */
    // disposalFacilities: state.fleet.facilities.disposalFacilities,
  };
};

const mapDispatchToProps = {
  resetDispatchBoardRoute,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<FormData, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: DISPATCH_BOARD_ROUTE_EDITOR_FORM,
    onSubmitFail: focusFirstInvalidField,
  })(DispatchBoardRouteEditorForm),
);
