import { http } from '../../core/services/http';

export const loadContacts = (haulerId: string) =>
  http.get(`/gus/hauler/${haulerId}/contacts`).then(response => response.data);

export const loadContactsByToken = (token: string) =>
  http.get(`/gus/token/${token}/contacts`).then(response => response.data);

export const saveContact = (haulerId: string, data: any) => http.post(`/gus/hauler/${haulerId}/contact/`, data);

export const saveContactByToken = (token: string, data: any) => http.post(`/gus/token/${token}/contact`, data);

export const updateContact = (haulerId: string, data: any) =>
  http.put(`/gus/hauler/${haulerId}/contact/${data.id}`, data);

export const updateContactByToken = (token: string, data: any) =>
  http.put(`/gus/token/${token}/contact/${data.id}`, data);

export const deleteContact = (haulerId: string, contactId: number) =>
  http.delete(`/gus/hauler/${haulerId}/contact/${contactId}`);

export const deleteContactByToken = (token: string, contactId: number) =>
  http.delete(`/gus/token/${token}/contact/${contactId}`);
