import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from '../../../store';
import { Button, ButtonSet, Grid, GridColumn, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { Table } from '../../../core/components';
import { TimeManagementSettings } from '../../interfaces/TimeManagement';
import translate from '../../../core/services/translate';
import timeManagementSettingsFormInitialValuesSelector from '../../../vendors/services/timeManagementSettingsFormInitialValuesSelector';
import TimeManagementTableRow from '../TimeManagementTableRow';

interface PropsWithoutReduxForm {
  onCancel: (pristine: boolean) => void;
  pristine?: boolean;
  timeManagementSettings?: TimeManagementSettings[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class TimeManagementForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine, timeManagementSettings } = this.props;
    const timeManagementFormTableCells = [
      { name: 'vehicleType', label: translate('vehicles.vehicleType'), noPaddingRight: true, width: '25%' },
      { name: 'isActive', label: translate('common.active'), noPaddingRight: true, width: '15%' },
      {
        name: 'breaktime',
        label: translate('vendors.timeManagement.breaktime'),
        noPaddingRight: true,
        width: '20%',
      },
      {
        name: 'drivingToFromFacility',
        label: translate('vendors.timeManagement.drivingToFromFacility'),
        noPaddingRight: true,
        width: '20%',
      },
      { name: 'offRoute', label: translate('vendors.timeManagement.offRoute'), noPaddingRight: true, width: '20%' },
    ];

    return (
      <>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit}>
          <Table
            cells={timeManagementFormTableCells}
            rowComponent={TimeManagementTableRow}
            rows={timeManagementSettings}
          />
          <Grid centered>
            <GridColumn size="5/12">
              <ButtonSet margin="medium no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: (timeManagementSettingsFormInitialValuesSelector as any)(state.vendors.timeManagementSettings),
  timeManagementSettings: state.vendors.timeManagementSettings.timeManagementSettings,
});

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'timeManagementForm',
  })(TimeManagementForm),
);
