import { Action, Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useState, useEffect, ReactNode } from 'react';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { formSegmentsFilterDateRange } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { Grid, GridColumn, ModalSection, Text } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import { RouteSectionCollapseButton, RouteSectionHeader } from './Styled';
import { useSelector } from 'src/core/hooks/useSelector';

type Props = {
  content: ReactNode;
  defaultOpen?: boolean;
  isLoading: boolean;
  loadDataFunction?: (
    vendorId: number,
    routeId: number,
    startDate?: Date | string,
    endDate?: Date | string,
  ) => (dispatch: Dispatch) => Promise<any>;
  loadDataFunctionWithoutVendor?: (routeId: number) => (dispatch: Dispatch) => Promise<any>;
  resetDataAction?: () => Action;
  title: string;
};

export default function RouteModalSection({
  content,
  isLoading,
  loadDataFunction,
  loadDataFunctionWithoutVendor,
  resetDataAction,
  title,
  defaultOpen,
}: Props) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const segmentsFilterStartDate = useSelector(state => formSegmentsFilterDateRange(state, 'startDate'));
  const segmentsFilterEndDate = useSelector(state => formSegmentsFilterDateRange(state, 'endDate'));

  const [isCollapsed, setCollapsed] = useState(defaultOpen);

  useEffect(() => {
    if (!routeSummary?.routeId || !isCollapsed) return;

    if (loadDataFunction)
      loadDataFunction(vendorId, routeSummary.routeId, segmentsFilterStartDate, segmentsFilterEndDate)(dispatch);
    if (loadDataFunctionWithoutVendor) loadDataFunctionWithoutVendor(routeSummary.routeId)(dispatch);

    if (resetDataAction) {
      return () => {
        dispatch(resetDataAction());
      };
    }
  }, [
    dispatch,
    isCollapsed,
    loadDataFunction,
    loadDataFunctionWithoutVendor,
    resetDataAction,
    routeSummary?.routeId,
    segmentsFilterEndDate,
    segmentsFilterStartDate,
    vendorId,
  ]);

  return (
    <ModalSection overflow="visible" isLoading={isLoading}>
      <Grid multiLine>
        <GridColumn size="12/12" padding="xxSmall no">
          <RouteSectionHeader>
            <Text margin="no xSmall" size="xLarge" color={isCollapsed ? 'primary' : 'grayDarker'}>
              {title}
            </Text>
            <RouteSectionCollapseButton isOpen={isCollapsed} onClick={() => setCollapsed(isCollapsed => !isCollapsed)}>
              <Icon icon="chevronDown" />
            </RouteSectionCollapseButton>
          </RouteSectionHeader>
        </GridColumn>

        {isCollapsed && (
          <GridColumn size="12/12" padding="no">
            {content}
          </GridColumn>
        )}
      </Grid>
    </ModalSection>
  );
}
