import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { Container, ButtonSet, Button } from '../../../core/components/styled';
import { Dropdown, TypedField } from '../../../core/components';
import { EndDestination } from '../../interfaces/ChangeRequests';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
interface ComponentProps {
  endDestinations: EndDestination[];
  onCancel(pristine: boolean): void;
}

interface PropsWithoutReduxForm extends ComponentProps {}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const BulkAcceptAwardsForm: React.FC<Props> = ({ endDestinations, handleSubmit, onCancel, pristine }) => {
  const paymentInfo = useSelector((state: AppState) => state.opportunity.opportunities.paymentInfo);
  const { paymentMethods, vendorPayments } = paymentInfo;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Container>
        <TypedField
          name="paymentMethodType"
          component={Dropdown}
          props={{
            options: paymentMethods,
            label: translate('opportunity.opportunities.paymentMethod'),
            margin: 'no xSmall',
          }}
        />
      </Container>
      <Container>
        <TypedField
          name={'paymentId'}
          component={Dropdown}
          props={{
            options: vendorPayments,
            label: translate('opportunity.opportunities.paymentInfo'),
            margin: 'medium no xSmall',
          }}
        />
      </Container>
      <Container>
        <TypedField
          name={'facilityId'}
          component={Dropdown}
          props={{
            options: endDestinations,
            label: translate('opportunity.opportunities.endDestination'),
            margin: 'medium no xSmall',
          }}
        />
      </Container>
      <ButtonSet>
        <Button type="submit" color="primary">
          {translate('opportunity.submit')}
        </Button>
        <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
          {translate('common.cancel')}
        </Button>
      </ButtonSet>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'bulkAcceptAwards',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(BulkAcceptAwardsForm);
