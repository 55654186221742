import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { Grid, GridColumn, PanelSection, Button } from '../../../core/components/styled';
import { Input, PanelSearch, DateRangePicker } from '../../../core/components';
import { ROUTE } from '../../../fleet/constants';
import { DisposalSiteLocationDropdown } from '../../../common/components';
import { VehicleTypeForVendorDropdown } from '../../../fleet/components';
import translate from '../../../core/services/translate';
import disposalSummaryFormInitialValuesSelector from '../../services/disposalSummaryFormInitialValuesSelector';
import { DATE_RANGE_PICKER_TODAY_3_7 } from '../../../core/constants';
import { AppState } from '../../../store';

class DisposalSummaryForm extends PureComponent<RouteComponentProps & InjectedFormProps<any, RouteComponentProps>> {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form noValidate onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="4/12">
              <Field name="searchString" component={PanelSearch} margin="no" id="disposal-summary-form-search" />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="dateRange"
                component={DateRangePicker}
                tabletAlignLeft
                options={DATE_RANGE_PICKER_TODAY_3_7}
                margin="no"
                props={{ hasMarginLeft: 'smaller' }}
                id="disposal-summary-form-date-range"
              />
            </GridColumn>
            <GridColumn size="2/12">
              <Field
                name="vehicleTypeId"
                component={VehicleTypeForVendorDropdown}
                vehicleRoleTypeId={ROUTE}
                withPlaceholder
                dropdownProps={{ isClearable: true, margin: 'no', id: 'disposal-summary-form-vehicle-type' }}
              />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="ticketNumber"
                component={Input}
                placeholder={translate('routes.ticketNumber')}
                margin="no"
                id="disposal-summary-form-ticket-number"
              />
            </GridColumn>

            <GridColumn size="2/12">
              <Field
                name="disposalSiteLocationId"
                component={DisposalSiteLocationDropdown}
                withPlaceholder
                dropdownProps={{ isClearable: true, margin: 'no', id: 'disposal-summary-form-disposal-site' }}
              />
            </GridColumn>

            <GridColumn size="2/12" align="right">
              <Button type="submit" color="primary" id="disposal-summary-form-search-button">
                {translate('common.search')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, { location: { search } }: RouteComponentProps) => ({
  initialValues: disposalSummaryFormInitialValuesSelector(search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, RouteComponentProps>({
      form: 'disposalSummary',
      enableReinitialize: true,
    })(DisposalSummaryForm),
  ),
);
