import React from 'react';

import { ButtonProps, Button } from './styled/Button';
import { TooltipButtonWrapper, TooltipButtonTooltip, TooltipButtonTooltipProps } from './styled/TooltipButtonStyles';
import translate from '../services/translate';

interface Props extends ButtonProps {
  tooltip?: string;
  tooltipProps?: any;
  tooltipAsString?: boolean;
  tooltipColor?: string;
  tooltipPosition?: TooltipButtonTooltipProps['position'];
  type?: 'button' | 'submit' | 'reset';
  form?: string;
  noWrap?: boolean;
}

const TooltipButton: React.FC<Props> = ({
  tooltip,
  tooltipProps,
  tooltipAsString,
  tooltipColor,
  tooltipPosition,
  margin,
  noWrap,
  ...buttonProps
}) => (
  <TooltipButtonWrapper margin={margin} noWrap={noWrap}>
    {!!tooltip && (
      <TooltipButtonTooltip color={tooltipColor} position={tooltipPosition || 'top'}>
        {tooltipAsString ? tooltip : translate(`tooltips.${tooltip}`, tooltipProps)}
      </TooltipButtonTooltip>
    )}

    <Button {...buttonProps} />
  </TooltipButtonWrapper>
);

export default TooltipButton;
