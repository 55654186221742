import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { get } from 'lodash-es';

import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import CalendarListViewSwitch from 'src/core/components/CalendarListViewSwitch';
import { Button, Grid, GridColumn, PanelSection, PanelSectionSubTitle } from 'src/core/components/styled';
import { generateMonthSpan } from 'src/core/constants/calendar';
import { CALENDAR_VIEW, LIST_VIEW, ViewType } from 'src/core/constants/calendarListView';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { ServiceEditorRouteParams } from 'src/customers/components/pages/ServiceDetailsEditorPageResolver';
import { loadServiceWorkOrders } from 'src/customers/ducks';
import { CustomerLocationServiceContract } from 'src/customers/interfaces/Customers';
import { ServiceWorkOrder } from 'src/customers/interfaces/Services';
import { AppState } from 'src/store';
import { BILLING_MODULE } from 'src/vendors/ducks/features';
import { DispatchBoardJobEditorModalResolver } from 'src/routes/components/modals';
import WorkOrdersCalendar from './WorkOrdersCalendar';
import WorkOrdersTable from './WorkOrdersTable';
import WorkOrderListFilters from './WorkOrderListFilters';

interface Props {
  allowEdit?: boolean;
  selectedServiceContract?: CustomerLocationServiceContract;
  vehicleTypeId?: number;
}

const WorkOrdersSection = ({ allowEdit = true, selectedServiceContract, vehicleTypeId }: Props) => {
  const dispatch = useDispatch();
  const { serviceId: sId } = useParams<ServiceEditorRouteParams>();
  const serviceId = Number(sId) !== -1 ? Number(sId) : -1;
  const workOrders: ServiceWorkOrder[] = useSelector((state: AppState) => state.customers.service.workOrders);
  const effectiveStartDate = useSelector((state: AppState) => state.customers.service.service?.effectiveDate || '');
  const billingModuleFeature = useSelector(state =>
    state.vendors.features.features.find(feature => feature.code === BILLING_MODULE),
  );
  const billingModuleEnabled = get(billingModuleFeature, 'enabled', false);

  const [jobEditorModalOpen, setJobEditorModalOpen] = useState(false);
  const [woView, setWoView] = useState<ViewType>(CALENDAR_VIEW);
  const [currentMonth, setCurrentMonth] = useState(generateMonthSpan(new Date()));
  const [showOperationalWorkOrders, setShowOperationalWorkOrders] = useState(billingModuleEnabled ? false : true);
  const [workOrdersListDateRange, setWorkOrdersListDateRange] = useState<{ from: StringOrDate; to: StringOrDate }>({
    from: moment().subtract(30, 'days').format('MM/DD/YYYY'),
    to: moment().add(60, 'days').format('MM/DD/YYYY'),
  });

  const toggleOperationalWorkOrders = () => setShowOperationalWorkOrders(!showOperationalWorkOrders);

  const handleDateChange = (date: Date) => {
    const monthSpan = generateMonthSpan(date);
    setCurrentMonth(monthSpan);
    setWorkOrdersListDateRange({
      from: moment(monthSpan.startDate).format('MM/DD/YYYY'),
      to: moment(monthSpan.endDate).format('MM/DD/YYYY'),
    });
  };

  const handleWorkOrdersListDateRange = (dateRange: { from: StringOrDate; to: StringOrDate }) => {
    setWorkOrdersListDateRange(dateRange);
    setCurrentMonth(generateMonthSpan(moment(dateRange.from, 'MM/DD/YYYY').add(1, 'day').toDate()));
  };

  const refreshWorkOrders = useCallback(() => {
    let sd;
    let ed;
    switch (woView) {
      case CALENDAR_VIEW:
        sd = moment(currentMonth.startDate).format('MM/DD/YYYY');
        ed = moment(currentMonth.endDate).format('MM/DD/YYYY');
        break;
      case LIST_VIEW:
        sd = workOrdersListDateRange.from;
        ed = workOrdersListDateRange.to;
        break;
      default:
        break;
    }
    loadServiceWorkOrders(serviceId, sd, ed)(dispatch);
  }, [woView, serviceId, dispatch, currentMonth, workOrdersListDateRange]);

  useEffect(() => {
    refreshWorkOrders();
  }, [workOrdersListDateRange, currentMonth, refreshWorkOrders]);

  const filteredWorkOrders = useMemo(() => {
    const isBillable = !showOperationalWorkOrders,
      isOperational = showOperationalWorkOrders;

    return workOrders.filter(
      workOrder => (isBillable && workOrder.isBillable) || (isOperational && workOrder.isOperational),
    );
  }, [showOperationalWorkOrders, workOrders]);

  return (
    <PanelSection padding="small small medium sMedium" fluid withBorder>
      <Grid padding="no" multiLine>
        <GridColumn size="12/12" margin="no">
          <Grid padding="small no sMedium no">
            <GridColumn size="3/12" padding="no" verticalAlign="center">
              <PanelSectionSubTitle margin="no">{translate('customers.serviceEditor.workOrders')}</PanelSectionSubTitle>
            </GridColumn>
            <GridColumn size="6/12" padding="no">
              <CalendarListViewSwitch currentView={woView} switchView={setWoView} />
            </GridColumn>
            <GridColumn size="3/12" padding="no" align="right">
              {allowEdit && (
                <Button
                  type="button"
                  line
                  color="primary"
                  margin="no xxSmall no small"
                  id="add-workorder-button"
                  onClick={() => setJobEditorModalOpen(true)}
                >
                  {translate('customers.serviceEditor.addNew')}
                </Button>
              )}
            </GridColumn>
          </Grid>
        </GridColumn>

        {woView === LIST_VIEW && (
          <>
            <GridColumn size="12/12" padding="no small no xSmall">
              <WorkOrderListFilters
                effectiveStartDate={effectiveStartDate}
                initialValues={{ workOrdersListDateRange }}
                setWorkOrdersListDateRange={handleWorkOrdersListDateRange}
                showOperationalWorkOrders={showOperationalWorkOrders}
                toggleOperationalWorkOrders={toggleOperationalWorkOrders}
                workOrdersListDateRange={workOrdersListDateRange}
                billingModuleEnabled={billingModuleEnabled}
              />
            </GridColumn>

            <GridColumn size="12/12">
              <WorkOrdersTable workOrders={filteredWorkOrders} showOperationalWorkOrders={showOperationalWorkOrders} />
            </GridColumn>
          </>
        )}
        {woView === CALENDAR_VIEW && (
          <GridColumn size="12/12" padding="no small no xSmall">
            <WorkOrdersCalendar
              currentMonth={currentMonth}
              effectiveStartDate={effectiveStartDate}
              handleDateChange={handleDateChange}
              showOperationalWorkOrders={showOperationalWorkOrders}
              toggleOperationalWorkOrders={toggleOperationalWorkOrders}
              workOrders={filteredWorkOrders}
              billingModuleEnabled={billingModuleEnabled}
            />
          </GridColumn>
        )}
        {jobEditorModalOpen && (
          <DispatchBoardJobEditorModalResolver
            closeModal={() => setJobEditorModalOpen(false)}
            selectedServiceContract={selectedServiceContract}
            vehicleTypeId={vehicleTypeId}
            refreshUnassignedJobs={() => refreshWorkOrders()}
          />
        )}
      </Grid>
    </PanelSection>
  );
};

export default WorkOrdersSection;
