import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { some, unionBy } from 'lodash-es';
import { connect, useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from '../../../../account/constants';
import { hasPermissionSelector } from '../../../../account/ducks';
import { TechnicalType } from '../../../../common/interfaces/TechnicalType';
import { Modal, PanelSearch } from '../../../../core/components';
import { Button } from '../../../../core/components/styled';
import { Grid as GridUntyped, GridColumn as GridColumnUntyped } from '../../../../core/components/styled/Grid';
import { REACT_WINDOW_CLASS_NAME } from '../../../../core/constants/reactWindow';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import { multiWordAndSearch } from '../../../../core/services/search';
import translate from '../../../../core/services/translate';
import { AppState } from '../../../../store';
import { reset as resetExceptionManagementConfiguration } from '../../../../vendors/ducks/exceptionManagementConfiguration';
import { saveExceptions } from '../../../ducks/dispatchBoard';
import {
  ExceptionManagerJobDispatchException,
  ExceptionManagerJobModalProps,
  ExceptionManagerJobRouteInfo,
} from '../../../interfaces/ExceptionManagerJob';
import { DispatchBoardExceptionManagerAssignerForm, DispatchBoardExceptionManagerFilterForm } from '../../forms';
import { LoadingContainer } from '../../styled';
import ExceptionManagerJobItem from './ExceptionManagerJobItem';
import {
  Centered,
  ContainerDiv,
  ContentContainer,
  ContentHeading,
  ModalContent,
  NumberOfAssignedItems,
  Overlay,
  SearchTermContainer,
  Wrapper,
} from './Styled';
import { TABLE_ROW_HEIGHT_xLARGE } from 'src/core/constants';

const Grid = GridUntyped as any;
const GridColumn = GridColumnUntyped as any;
const selector = hasPermissionSelector as any;

type Props = ExceptionManagerJobModalProps & InjectedFormProps<any, ExceptionManagerJobModalProps>;

const filterFormSelector = formValueSelector('exceptionManagerFilterForm');

// Functions may be used in the future?
// const getAssignedJobsByDates = (assignedJobs: ExceptionManagerJobDispatchException[]) => {
//   const result: { [key: string]: number } = {};
//   assignedJobs.forEach(j => {
//     if (result[j.routeLocationDate]) {
//       result[j.routeLocationDate]++;
//     } else {
//       result[j.routeLocationDate] = 1;
//     }
//   });
//   return result;
// };

// const getAssignedJobsInfo = (assignedJobs: ExceptionManagerJobDispatchException[]) => {
//   const assignedJobsByDates = getAssignedJobsByDates(assignedJobs);
//   return Object.keys(assignedJobsByDates).map(k => <div key={k}>{`${k} (${assignedJobsByDates[k]})`}</div>);
// };

const getUnassignedCheckedJobs = (assignedJobs: ExceptionManagerJobDispatchException[], checkedJobs: any[]) => {
  return checkedJobs.filter(cj => !assignedJobs.find(aj => aj.routeLocationId === cj.routeLocationId)).length;
};

const ExceptionManagerItem = ({ data, index, style }: any) => {
  const {
    removeAssignment,
    checkJob,
    checkedJobs,
    assignedJobs,
    isViewOnly,
    jobs,
    reasonCodes,
    selectedReasonCodes,
    setReasonCode,
  } = data;
  const job = jobs[index];

  return (
    <div style={style}>
      <ExceptionManagerJobItem
        removeAssignment={removeAssignment}
        key={job.routeLocationId}
        job={job}
        onCheckJob={checkJob}
        isChecked={!!checkedJobs.find((j: any) => j === job)}
        assignmentInfo={assignedJobs.find((j: any) => j.routeLocationId === job.routeLocationId)}
        isViewOnly={isViewOnly}
        reasonCodes={reasonCodes}
        selectedReasonCode={selectedReasonCodes[job.routeLocationId]}
        setReasonCode={setReasonCode}
      />
    </div>
  );
};

const ExceptionManagerModal: React.SFC<Props> = ({
  filterDate,
  isLoading,
  isSaving,
  jobs: rawJobs,
  onClose,
  saveExceptions,
  vehicleTypeId,
  vendorId,
  hasPermissionToManageScheduledJobs,
  hasPermissionToManageUnscheduledJobs,
  defaultExceptionReasonCodes,
  resetExceptionManagementConfiguration,
}) => {
  const [checkedJobs, setCheckedJobs] = useState<any[]>([]);
  const [selectedReasonCodes, setSelectedReasonCodes] = useState<{ [k: string]: number | undefined }>({});
  const [assignedJobs, setAssignedJobs] = useState<ExceptionManagerJobDispatchException[]>([]);
  const [infiniteHeight, setInfiniteHeight] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const reasonCodes = useSelector((state: AppState) =>
    state.common.reasonCodeTypes.reasonCodeTypes.map(type => ({
      label: type.name,
      value: type.id,
    })),
  );

  const containerDivRef = useRef<HTMLDivElement>(null);

  const isViewOnly = !hasPermissionToManageScheduledJobs && !hasPermissionToManageUnscheduledJobs;

  const setFixedHeight = useCallback(() => {
    const heightValue = isViewOnly ? 100 : 260;
    containerDivRef &&
      containerDivRef.current &&
      setInfiniteHeight(containerDivRef.current.getBoundingClientRect().height - heightValue);
  }, [isViewOnly]);

  const handleContainerResize = useCallback(() => {
    if (containerDivRef.current) {
      setFixedHeight();
    }
  }, [containerDivRef, setFixedHeight]);

  useEffect(() => {
    if (containerDivRef.current) {
      window.addEventListener('resize', handleContainerResize);
      setFixedHeight();
    }
  }, [containerDivRef, handleContainerResize, setFixedHeight]);

  const checkAll = (checked: boolean) => {
    setCheckedJobs(checked ? [...jobs] : []);
  };

  const checkJob = (checked: boolean, job: any) => {
    if (checked) {
      setCheckedJobs([...checkedJobs, job]);
    } else {
      checkedJobs.splice(checkedJobs.indexOf(job), 1);
      setCheckedJobs([...checkedJobs]);
    }
  };

  const setReasonCode = (reasonCodeTypeId: number | undefined, job: any) => {
    setSelectedReasonCodes({
      ...selectedReasonCodes,
      [job.routeLocationId]: reasonCodeTypeId,
    });
  };

  const assignJobs = (
    date: Date | string,
    route: ExceptionManagerJobRouteInfo,
    wasteMaterialType: TechnicalType,
    positionTypeId: number,
  ) => {
    const newlyAssignedJobs = checkedJobs.map(j => ({
      routeLocationId: j.routeLocationId,
      routeLocationDate: filterDate,
      date,
      route,
      wasteMaterialType,
      positionTypeId: positionTypeId || null,
    }));
    const allAssignedJobs = unionBy(newlyAssignedJobs, assignedJobs, 'routeLocationId');
    setAssignedJobs(allAssignedJobs);
  };

  const removeAssignment = (jobId: number) => {
    assignedJobs.splice(
      assignedJobs.findIndex(v => v.routeLocationId === jobId),
      1,
    );
    setAssignedJobs([...assignedJobs]);
  };

  const save = () => {
    saveExceptions(
      vehicleTypeId,
      vendorId,
      assignedJobs.map(job => ({
        ...job,
        reasonCodeTypeId: selectedReasonCodes[job.routeLocationId],
      })),
    )
      .then(() => {
        createSuccessNotification(translate('dispatchBoard.exceptionsDispatcher.successMessage'));
        onClose();
      })
      .catch(e => createErrorNotification(translate('dispatchBoard.exceptionsDispatcher.errorMessage')));
  };

  const changeSearchTerm = useCallback(
    (event: any, newSearchTerm: string) => {
      setSearchTerm(newSearchTerm.toLowerCase());
    },
    [setSearchTerm],
  );

  const jobs = React.useMemo(() => {
    if (typeof searchTerm !== 'string' || !searchTerm) {
      return rawJobs;
    }

    return rawJobs.filter(job => {
      const fields: (string | number | undefined)[] = [
        job.address,
        job.customerAccountNumber,
        job.customerName,
        job.equipmentType.name,
        job.locationAccountNumber,
        job.locationName,
        job.materialType?.name,
        job.wasteMaterialType?.name,
        job.pickupType.name,
        job.serviceType.name,
        job.routeId,
        job.routeLocationId,
      ];

      job.exceptions.forEach((exception: any) => {
        fields.push(exception.name);
      });

      const filteredAndMatchedFields = fields
        .filter(field => typeof field === 'string' && !!field)
        .map(field => multiWordAndSearch(field, searchTerm));

      return some(filteredAndMatchedFields, Boolean);
    });
  }, [rawJobs, searchTerm]);

  const hasAssignmentInfo = jobs.find(job => assignedJobs.find(j => j.routeLocationId === job.routeLocationId));

  React.useEffect(() => {
    const defaultsWithValue = defaultExceptionReasonCodes.filter(exception => !!exception.reasonCodeTypeId);
    const newSelectedReasonCodes: { [k: string]: number | undefined } = {};

    jobs.forEach(job => {
      const defaultReasonCodeConfiguration = defaultsWithValue.find(
        configuration => configuration.id === job.exceptions[0].id,
      );

      // Use the reason code from the job, otherwise the default one.
      const reasonCodeTypeId = job.reasonCodeType
        ? job.reasonCodeType.id
        : defaultReasonCodeConfiguration
        ? defaultReasonCodeConfiguration.reasonCodeTypeId
        : undefined;

      newSelectedReasonCodes[job.routeLocationId] = reasonCodeTypeId || undefined;
    });

    setSelectedReasonCodes(newSelectedReasonCodes);
  }, [defaultExceptionReasonCodes, jobs]);

  useEffect(() => {
    return function cleanup() {
      resetExceptionManagementConfiguration();
    };
  }, [resetExceptionManagementConfiguration]);

  return (
    <Modal
      flexDirection="column"
      padding="medium no no"
      verticalSize="large"
      size="large"
      title={translate('dispatchBoard.exceptionsDispatcher.title')}
      onClose={onClose}
    >
      <ModalContent>
        <Wrapper>
          <DispatchBoardExceptionManagerFilterForm
            onSearch={() => checkAll(false)}
            vendorId={vendorId}
            vehicleTypeId={vehicleTypeId}
          />
        </Wrapper>

        <ContainerDiv ref={containerDivRef}>
          <ContentContainer>
            {!!rawJobs.length && (
              <SearchTermContainer>
                <Field name="searchTerm" component={PanelSearch as any} onChange={changeSearchTerm} />
              </SearchTermContainer>
            )}

            {!!jobs.length && !isViewOnly && (
              <Overlay>
                <DispatchBoardExceptionManagerAssignerForm
                  allSelected={!!jobs.length && checkedJobs.length === jobs.length}
                  assignedNumberOfJobs={assignedJobs.length}
                  checkedNumber={checkedJobs.length}
                  handleCheckAll={checkAll}
                  newAssignedNumberOfJobs={getUnassignedCheckedJobs(assignedJobs, checkedJobs)}
                  onAssign={assignJobs}
                  partial={!!checkedJobs.length}
                />
              </Overlay>
            )}

            {isLoading || isSaving ? (
              <LoadingContainer isLoading={true} />
            ) : jobs.length ? (
              <Fragment>
                <ContentHeading isViewOnly={isViewOnly}>
                  <Grid multiLine>
                    <GridColumn size={'4/12'}>
                      <b>{translate('dispatchBoard.exceptionsDispatcher.customerInfo')}</b>
                    </GridColumn>
                    <GridColumn size={hasAssignmentInfo ? '2/12' : '4/12'}>
                      <b>{translate('dispatchBoard.exceptionsDispatcher.serviceInfo')}</b>
                    </GridColumn>
                    {hasAssignmentInfo && (
                      <GridColumn size={'2/12'}>
                        <b>{translate('dispatchBoard.exceptionsDispatcher.destination')}</b>
                      </GridColumn>
                    )}
                    <GridColumn size={'2/12'}>
                      <b>{translate('dispatchBoard.exceptionsDispatcher.exceptionDate')}</b>
                    </GridColumn>
                    <GridColumn size={'2/12'} align="right">
                      <b>{translate('dispatchBoard.exceptionsDispatcher.exceptionInfo')}</b>
                    </GridColumn>
                  </Grid>
                </ContentHeading>
                <List
                  width="100%"
                  itemData={{
                    removeAssignment,
                    checkJob,
                    checkedJobs,
                    assignedJobs,
                    isViewOnly,
                    jobs,
                    reasonCodes,
                    selectedReasonCodes,
                    setReasonCode,
                  }}
                  itemCount={jobs.length}
                  itemSize={TABLE_ROW_HEIGHT_xLARGE}
                  height={infiniteHeight}
                  className={REACT_WINDOW_CLASS_NAME}
                >
                  {ExceptionManagerItem}
                </List>
              </Fragment>
            ) : (
              <Centered>{translate('dispatchBoard.exceptionsDispatcher.noStopsFound')}</Centered>
            )}
          </ContentContainer>

          {!isViewOnly && (
            <Overlay center>
              <Button disabled={!!!assignedJobs.length} onClick={save} color="primary">
                {translate('common.save')}
              </Button>

              {!!assignedJobs.length && (
                <NumberOfAssignedItems>
                  {translate('dispatchBoard.exceptionsDispatcher.numberOfAssignedJobs', {
                    numberOfAssignedJobs: assignedJobs.length,
                  })}
                </NumberOfAssignedItems>
              )}
            </Overlay>
          )}
        </ContainerDiv>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  filterDate: filterFormSelector(state, 'date'),
  isLoading: state.routes.dispatchBoard.exceptions.isLoadingExceptions,
  isSaving: state.routes.dispatchBoard.exceptions.isSavingExceptions,
  jobs: state.routes.dispatchBoard.exceptions.jobsWithExceptions,
  hasPermissionToManageScheduledJobs: selector(state.account.permissions, ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS),
  hasPermissionToManageUnscheduledJobs: selector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
  ),
  defaultExceptionReasonCodes: state.vendors.exceptionManagementConfiguration.configurations,
});

export default connect(mapStateToProps, { saveExceptions, resetExceptionManagementConfiguration })(
  reduxForm<any, ExceptionManagerJobModalProps>({
    form: 'dispatchBoardExceptionsFilter',
    enableReinitialize: true,
  })(ExceptionManagerModal),
);
