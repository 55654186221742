import styled from 'styled-components';

import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';

const CARET_SIZE = 10;
const CARET_HALF_DIAGONAL = CARET_SIZE * Math.sqrt(2) / 2;

interface ChartTooltipProps {
  x: number;
  y: number;
  color?: string;
}

export const ChartTooltip = styled.div<ChartTooltipProps>`
  position: absolute;
  padding: 6px 12px;
  white-space: nowrap;
  max-width: 400px;
  min-width: 100px;
  background-color: ${props => colorMapperWithoutProps(props.color, 'grayDarker')};
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  text-align: left;
  z-index: 9999;
  pointer-events: none;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  transform: translate(-50%, calc(-100% - ${CARET_HALF_DIAGONAL}px));
`;

interface ChartTooltipCaretProps {
  x: number;
  y: number;
  color?: string;
}

export const ChartTooltipCaret = styled.div<ChartTooltipCaretProps>`
  display: inline-block;
  pointer-events: none;
  position: absolute;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  height: ${CARET_SIZE}px;
  width: ${CARET_SIZE}px;
  background-color: ${props => colorMapperWithoutProps(props.color, 'grayDarker')};
  transform: translate(-50%, calc(-50% - ${CARET_HALF_DIAGONAL}px)) rotate(45deg);
  z-index: 9999;
`;
