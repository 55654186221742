import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { Open311ConfigurationError, open311DismissError } from '../interfaces/Open311';
import {
  loadServiceRequestErrors as doLoadServiceRequestErrors,
  dismissServiceRequestError as doDismissErrors,
} from '../services/Open311';

const START_LOAD = 'vendors/configurationErrors/START_LOAD';
const COMPLETE_LOAD = 'vendors/configurationErrors/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/configurationErrors/FAIL_LOAD';
const START_DELETE = 'vendors/configurationErrors/START_DELETE';
const COMPLETE_DELETE = 'vendors/configurationErrors/COMPLETE_DELETE';
const FAIL_DELETE = 'vendors/configurationErrors/FAIL_DELETE';
const RESET = 'vendors/configurationErrors/RESET';

interface State {
  isDeleting: boolean;
  isLoading: boolean;
  serviceRequestErrors: Open311ConfigurationError[];
}

const initialState: State = {
  isDeleting: false,
  isLoading: false,
  serviceRequestErrors: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceRequestErrors: action.configurationErrors,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceRequestErrors: [],
        },
      });
    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (configurationErrors: Open311ConfigurationError[]) => ({
  type: COMPLETE_LOAD,
  configurationErrors,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({ type: FAIL_DELETE });

export const loadServiceRequestErrors = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadConfigurationsErrorPromise = doLoadServiceRequestErrors(vendorId);
  loadConfigurationsErrorPromise
    .then(configurationErrors => dispatch(completeLoad(configurationErrors)))
    .catch(() => dispatch(failLoad()));
  return loadConfigurationsErrorPromise;
};

export const dismissErrors = (vendorId: number, ticketIds: number[]) => (dispatch: Dispatch) => {
  const ticketIdsObj: open311DismissError = {};
  ticketIdsObj.ticketIds = ticketIds;
  dispatch(startDelete());
  const dismissErrorsPromise = doDismissErrors(vendorId, ticketIdsObj);
  dismissErrorsPromise.then(() => dispatch(completeDelete())).catch(() => dispatch(failDelete()));
  return dismissErrorsPromise;
};
