import styled from 'styled-components';
import { transparentize } from 'polished';

const MapDragHandle = styled.div`
  position: relative;
  top: -2px;
  z-index: 2000;
  height: 15px;
  width: 30px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 10px ${props => transparentize(0.9, props.theme.grayBase)};
`;

export default MapDragHandle;
