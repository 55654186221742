import { find } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';

import DoughnutChart from 'src/core/components/DoughnutChart';
import translate from 'src/core/services/translate';
import { theme } from 'src/core/styles';
import { ContainerStatisticsElement, ContainerStatisticsWrapper } from 'src/finance/components/styled/Statistics';
import { getChartData } from 'src/finance/components/utils/billingCharts';
import { BILLING_AGING_REPORTS, PREVIEW, UNBILLED, UNPAID } from 'src/finance/constants';
import { AppState } from 'src/store';
import { formatNumber } from 'src/utils/services/formatter';
import StatisticsHighlightedTable from './StatisticsHighlightedTable';

const chartColors = ['#9FC5FA', theme.brandGreenDark, '#BD10E0', '#FFCA00'];

const BillingStatistics = () => {
  const {
    billing: { agingReports, billingAmounts },
  } = useSelector((state: AppState) => state.finance.billing);

  const billingStatisticsRows = [
    { label: translate('finance.balanceTotal'), value: formatNumber(billingAmounts?.balanceTotal || 0) },
    { label: translate('finance.billTotal'), value: formatNumber(billingAmounts?.billTotal || 0) },
  ];

  const chartsMemo = React.useMemo(() => {
    if (!agingReports) {
      return null;
    }

    const previewStatus = find(agingReports, report => report.reportCode === BILLING_AGING_REPORTS[PREVIEW].code);
    const previewStatusData = getChartData(previewStatus);

    const unbilledStatus = find(agingReports, report => report.reportCode === BILLING_AGING_REPORTS[UNBILLED].code);
    const unbilledStatusData = getChartData(unbilledStatus);

    const unpaidStatus = find(agingReports, report => report.reportCode === BILLING_AGING_REPORTS[UNPAID].code);
    const unpaidStatusData = getChartData(unpaidStatus);

    return (
      <>
        {previewStatusData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              title={`${translate('billing.draft')}\n${previewStatusData.total}`}
              doughnutSize={130}
              dataset={{
                data: previewStatusData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={previewStatusData.rawDataValues}
              labels={previewStatusData.labels}
            />
          </ContainerStatisticsElement>
        )}
        {unbilledStatusData && (
          <ContainerStatisticsElement verticalAlign="middle" hasBorderRight alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              title={`${BILLING_AGING_REPORTS[UNBILLED].name}\n${unbilledStatusData.total}`}
              doughnutSize={130}
              dataset={{
                data: unbilledStatusData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={unbilledStatusData.rawDataValues}
              labels={unbilledStatusData.labels}
            />
          </ContainerStatisticsElement>
        )}
        {unpaidStatusData && (
          <ContainerStatisticsElement verticalAlign="middle" alignItems="center">
            <DoughnutChart
              showLegend
              showSummaryLegend
              title={`${BILLING_AGING_REPORTS[UNPAID].name}\n${unpaidStatusData.total}`}
              doughnutSize={130}
              dataset={{
                data: unpaidStatusData.data,
                backgroundColor: chartColors,
              }}
              rawDataValues={unpaidStatusData.rawDataValues}
              labels={unpaidStatusData.labels}
            />
          </ContainerStatisticsElement>
        )}
      </>
    );
  }, [agingReports]);

  return (
    <ContainerStatisticsWrapper>
      <ContainerStatisticsElement hasBorderRight verticalAlign="middle">
        <StatisticsHighlightedTable rows={billingStatisticsRows} />
      </ContainerStatisticsElement>
      {chartsMemo}
    </ContainerStatisticsWrapper>
  );
};

export default BillingStatistics;
