import { size } from 'lodash-es';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { Table } from 'src/core/components';
import { TableCell } from 'src/core/components/Table';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import confirm from 'src/core/services/confirm';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import * as UnbilledChargesDucks from 'src/finance/ducks/unbilledCharges';
import { UnbilledCharge } from 'src/finance/interfaces/UnbilledCharges';
import UnbilledChargesTableRow from './UnbilledChargesTableRow';

interface Props {
  billId?: number;
  hasAssignOption?: boolean;
  isViewOnly?: boolean;
  onEditClick?: (bill: any) => void;
  reloadBills?: () => void;
  selectBillsToAssign?: (
    id: number,
    workOrderId: number,
    vendorLocationId: number,
    billDate: StringOrDate,
    invoiceSubTotal: number,
    headerId: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
  unbilledCharges: UnbilledCharge[];
}

const UnbilledChargesTable = ({
  billId,
  hasAssignOption,
  isViewOnly,
  onEditClick,
  reloadBills,
  selectBillsToAssign,
  unbilledCharges,
}: Props) => {
  const dispatch = useDispatch();

  const assignToBill = async (
    workOrderId: number,
    billDate: StringOrDate,
    vendorLocationId: number,
    headerId: number,
  ) => {
    if (!(await confirm(translate('finance.alertMessages.confirmAssignToBill')))) return;
    UnbilledChargesDucks.assignToBill(
      workOrderId,
      billDate,
      vendorLocationId,
      billId,
      headerId,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('finance.alertMessages.billAssigned'));
        reloadBills?.();
      })
      .catch(e => {
        createErrorNotificationIncludingTechnicalMessage(
          e,
          translate('finance.alertMessages.billAssignError'),
          'finance.alertMessages',
        );
      });
  };

  const unbilledChargesTableCells: TableCell[] = [
    {
      name: 'workOrderId',
      label: translate('finance.workOrder'),
      width: '13%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'serviceContractId',
      label: translate('finance.serviceDetails'),
      width: isViewOnly ? '28%' : '20%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'customerId',
      label: translate('common.customer'),
      width: '10%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'locationId',
      label: translate('common.location'),
      width: '10%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'workOrderDescription',
      label: 'Work Order Description',
      width: '19%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'serviceDate',
      label: translate('finance.serviceDate'),
      width: '10%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'invoiceSubTotal',
      label: translate('finance.amount'),
      width: '10%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
  ];
  !isViewOnly &&
    unbilledChargesTableCells.push({
      name: 'options',
      label: translate('finance.options'),
      width: '8%',
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: false,
    });

  const virtualizedPropsUnbilledCharges = {
    height: Math.min(size(unbilledCharges) * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 4) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  return (
    <Table
      cells={unbilledChargesTableCells}
      rowComponent={UnbilledChargesTableRow}
      rows={unbilledCharges}
      rowProps={{
        assignToBill: assignToBill,
        hasAssignOption,
        selectBillsToAssign,
        onEditClick,
        isViewOnly,
      }}
      scrollMarker
      virtualized
      virtualizedProps={virtualizedPropsUnbilledCharges}
    />
  );
};

export default UnbilledChargesTable;
