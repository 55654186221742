import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { loadEquipmentSizes } from 'src/common/ducks';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteTemplateBuilderServicesModal from './RouteTemplateBuilderServicesModal';
import { loadServiceContractsExtraData } from 'src/routes/ducks/routeTemplateBuilder';

interface Props {
  closeModal: () => void;
  serviceIds: number[];
}

const RouteTemplateBuilderServicesModalResolver: FC<Props> = props => {
  const dispatch = useDispatch();
  const equipmentSizes = useSelector(state => state.common.equipmentSizes.equipmentSizes);

  const loadDependencies = () => {
    const dependencies = [];

    const { serviceIds } = props;

    if (!equipmentSizes.length) dependencies.push(dispatch(loadEquipmentSizes()));

    if (serviceIds.length) dependencies.push(dispatch(loadServiceContractsExtraData(serviceIds)));

    if (!dependencies.length) return Promise.resolve();

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoadingOverlay}
      successComponent={RouteTemplateBuilderServicesModal}
      successProps={props}
      onError={props.closeModal}
    />
  );
};

export default RouteTemplateBuilderServicesModalResolver;
