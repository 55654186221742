import React, { ChangeEvent } from 'react';

import { TableCell, TableRow, TableActionButton } from 'src/core/components/styled';
import { ActionButtonTooltip, UnconnectedCheckbox, UnconnectedSwitch } from 'src/core/components';
interface Props {
  id: number;
  code?: string;
  outcomeCode?: string;
  exceptionTypeName: string;
  serviceCode: string;
  serviceName: string;
  equipmentTypeName: string;
  wasteMaterialTypeName: string;
  pickupTypeName: string;
  vehicleTypeName: string;
  reasonCodeName: string;
  responseCode?: string;
  adHocJob: true;
  isChecked: boolean;
  isInbound: boolean;
  checkCreationRule: (id: number, e: ChangeEvent<HTMLInputElement>) => void;
  editCreationRule: (id: number) => void;
  viewDefaultNoteFields: (id: number) => void;
}

const CreationRulesTableRow: React.FC<Props> = ({
  id,
  outcomeCode,
  exceptionTypeName,
  serviceCode,
  wasteMaterialTypeName,
  pickupTypeName,
  vehicleTypeName,
  reasonCodeName,
  responseCode,
  adHocJob,
  isChecked,
  isInbound,
  checkCreationRule,
  editCreationRule,
  viewDefaultNoteFields,
}) => {
  return (
    <TableRow>
      <TableCell width="5%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
        <UnconnectedCheckbox
          block
          checked={isChecked}
          onChange={e => checkCreationRule(id, e)}
          onClick={e => e.stopPropagation()}
          id={`config-error-${id}-checkbox`}
        />
      </TableCell>
      {isInbound ? (
        <>
          <TableCell width="15%">{vehicleTypeName}</TableCell>
          <TableCell width="15%">{pickupTypeName}</TableCell>
          <TableCell width="18%">{serviceCode}</TableCell>
          <TableCell width="15%">{wasteMaterialTypeName}</TableCell>
          <TableCell width="15%">{reasonCodeName}</TableCell>
          <TableCell width="12%">
            <UnconnectedSwitch checked={adHocJob} disabled />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell width="20%">{serviceCode}</TableCell>
          <TableCell width="20%">{exceptionTypeName}</TableCell>
          <TableCell width="20%">{outcomeCode}</TableCell>
          <TableCell width="20%">{responseCode}</TableCell>
        </>
      )}
      <TableCell width="5%">
        <TableActionButton id={`creationRule-${id}-edit-button`} onClick={() => editCreationRule(id)}>
          <ActionButtonTooltip icon="edit" tooltip="edit" />
        </TableActionButton>
      </TableCell>
      {isInbound && (
        <TableCell width="5%">
          <TableActionButton id={`creationRule-${id}-view-button`} onClick={() => viewDefaultNoteFields(id)}>
            <ActionButtonTooltip
              customViewBox="0 0 26 26"
              iconSize="mLarge"
              icon="requestedNote"
              tooltip="addViewNotes"
            />
          </TableActionButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default CreationRulesTableRow;
