import Cookie from 'js-cookie';
import { debounce, filter, forEach, map, pickBy, replace, startsWith } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';

import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import useDataPolling from 'src/common/hooks/useDataPolling';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadGeoFences, resetGeoFences } from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  SNOW_SWEEPER_FILTERS_FORM_NAME,
  SnowSweeperFiltersFormValues,
} from '../components/forms/SnowSweeperFiltersForm';
import {
  DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME,
  DashboardExtraMapLayersFormValues,
} from '../components/pages/dashboardPageSections/dashboardWidgets/ExtraMapLayersWidgetContent';
import {
  filterAlternativeFleetSegments,
  loadAlternativeFleetSegments,
  loadMapInsightsMapbox,
  loadVehiclesList,
  resetMapInsightsMapbox,
  resetVehiclePositions,
  setVendorLocationsToDisplay,
} from '../ducks';
import { loadCityAlerts, loadVendor, resetCityAlerts } from 'src/vendors/ducks';
import { NameIdAndRouteType } from '../interfaces/alterativeFleetOps';

const getIds = (obj: any, prefix: string) => {
  return map(
    pickBy(obj, (value, key) => value === true && startsWith(key, prefix)),
    (value, key: string) => parseInt(replace(key, prefix, '')),
  );
};

const useLoadDataForSnowSweeperDashboard = (vehicleTypeId: number) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const haulerLocations = useSelector(state => state.dashboard.vendorLocations.vendorLocationsForMapbox);

  const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;

  const { routes } = useSelector(state => state.dashboard.alternativeFleetOps);

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');

  const extraMapLayersFormValues = useSelector(
    getFormValues(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME),
  ) as DashboardExtraMapLayersFormValues;

  const VEHICLES_REFRESH_INTERVAL_IN_MS = 60000; // 1 minute
  const SEGMENTS_REFRESH_INTERVAL_IN_MS = 60000; // 1 minute

  // KEEP TRACK OF FIRST RENDER
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  /* ===================================
    *        STREET SEGMENTS
    =================================== */
  // for the other filter is done on the api side
  useEffect(() => {
    if (firstRender.current || !vendorId || !vehicleTypeId) return;

    const priorityTypeIds: number[] = getIds(formValues?.priorityTypeIds, '_');
    const serviceActivityIds: number[] = getIds(formValues?.serviceActivityIds, '_');
    const serviceZoneIds: number[] = getIds(formValues?.serviceZoneIds, '_');
    const pickupStatusTypeIds: number[] = getIds(formValues?.pickupStatusTypeIds, '_');
    const lastActivity: number[] = getIds(formValues?.lastActivity, '_');
    const groupIds: number[] = getIds(formValues?.groupIds, '_');
    const routeIds: number[] = getIds(formValues?.routeId, '_');
    const isRouteTemplate =
      !!routeIds.length && routes.filter((route: NameIdAndRouteType) => route.id === routeIds[0])[0]?.isRouteTemplate;
    const searchTerm = currentSearchTerm;

    const isSectionWithNothingSelected =
      !serviceActivityIds.length || !priorityTypeIds.length || !serviceZoneIds.length || !groupIds.length;

    const shouldFilterLastActivity = formValues?.isLastActivity;

    if (isSectionWithNothingSelected) {
      // trick to do not call api when nothing is selected and do not show any segment
      filterAlternativeFleetSegments(
        vehicleTypeId,
        [],
        [],
        shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
      )(dispatch);
    } else {
      const noLoadingIndicator = false;

      loadAlternativeFleetSegments(
        {
          vendorId,
          vehicleTypeId,
          lastActivity: lastActivity,
          pickupStatusTypeIds: pickupStatusTypeIds,
          priorityTypeIds,
          serviceActivityIds,
          serviceZoneIds,
          groupIds,
          routeId: !isRouteTemplate ? (routeIds.length ? routeIds[0] : undefined) : undefined,
          routeTemplateId: isRouteTemplate ? (routeIds.length ? routeIds[0] : undefined) : undefined,
          searchTerm,
        },
        shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
        noLoadingIndicator,
      )(dispatch);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    dispatch,
    formValues?.groupIds,
    formValues?.priorityTypeIds,
    formValues?.serviceActivityIds,
    formValues?.routeId,
    formValues?.serviceZoneIds,
    vehicleTypeId,
    currentSearchTerm,
    vendorId,
    routes,
  ]);

  // changing tabs between last activity and pickup status we have to refilter data
  useEffect(() => {
    if (firstRender.current || !vendorId || !vehicleTypeId) return;

    const pickupStatusTypeIds: number[] = getIds(formValues?.pickupStatusTypeIds, '_');
    const lastActivity: number[] = getIds(formValues?.lastActivity, '_');

    const priorityTypeIds: number[] = getIds(formValues?.priorityTypeIds, '_');
    const serviceActivityIds: number[] = getIds(formValues?.serviceActivityIds, '_');
    const serviceZoneIds: number[] = getIds(formValues?.serviceZoneIds, '_');
    const groupIds: number[] = getIds(formValues?.groupIds, '_');

    const isSectionWithNothingSelected =
      !serviceActivityIds.length || !priorityTypeIds.length || !serviceZoneIds.length || !groupIds.length;

    const shouldFilterLastActivity = formValues?.isLastActivity;

    if (isSectionWithNothingSelected) {
      // trick to do not call api when nothing is selected and do not show any segment
      filterAlternativeFleetSegments(
        vehicleTypeId,
        [],
        [],
        shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
      )(dispatch);
    } else {
      filterAlternativeFleetSegments(
        vehicleTypeId,
        lastActivity,
        pickupStatusTypeIds,
        shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
      )(dispatch);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    formValues?.isLastActivity,
    formValues?.pickupStatusTypeIds,
    formValues?.lastActivity,
    vendorId,
    vehicleTypeId,
    dispatch,
    routes,
  ]);

  //debounce for search term
  const searchDebounce = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (searchTerm === currentSearchTerm) return;
        setCurrentSearchTerm(searchTerm);

        const pickupStatusTypeIds: number[] = getIds(formValues?.pickupStatusTypeIds, '_');
        const priorityTypeIds: number[] = getIds(formValues?.priorityTypeIds, '_');
        const serviceActivityIds: number[] = getIds(formValues?.serviceActivityIds, '_');
        const serviceZoneIds: number[] = getIds(formValues?.serviceZoneIds, '_');
        const groupIds: number[] = getIds(formValues?.groupIds, '_');
        const routeIds: number[] = getIds(formValues?.routeId, '_');
        const isRouteTemplate =
          !!routeIds.length &&
          routes.filter((route: NameIdAndRouteType) => route.id === routeIds[0])[0]?.isRouteTemplate;
        const lastActivity: number[] = getIds(formValues?.lastActivity, '_');
        const noLoadingIndicator = false;
        const shouldFilterLastActivity = formValues?.isLastActivity;

        const isSectionWithNothingSelected =
          !serviceActivityIds.length || !priorityTypeIds.length || !serviceZoneIds.length || !groupIds.length;

        if (isSectionWithNothingSelected) {
          // trick to do not call api when nothing is selected and do not show any segment
          filterAlternativeFleetSegments(
            vehicleTypeId,
            [],
            [],
            shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
          )(dispatch);
        } else {
          loadAlternativeFleetSegments(
            {
              vendorId,
              vehicleTypeId,
              lastActivity: lastActivity,
              pickupStatusTypeIds: pickupStatusTypeIds,
              priorityTypeIds,
              serviceActivityIds,
              serviceZoneIds,
              groupIds,
              routeId: !isRouteTemplate ? (routeIds.length ? routeIds[0] : undefined) : undefined,
              routeTemplateId: isRouteTemplate ? (routeIds.length ? routeIds[0] : undefined) : undefined,
              searchTerm,
            },
            shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
            noLoadingIndicator,
          )(dispatch);
        }
      }, 470),

    [
      currentSearchTerm,
      formValues?.pickupStatusTypeIds,
      formValues?.priorityTypeIds,
      formValues?.serviceActivityIds,
      formValues?.serviceZoneIds,
      formValues?.groupIds,
      formValues?.routeId,
      formValues?.lastActivity,
      formValues?.isLastActivity,
      routes,
      vehicleTypeId,
      dispatch,
      vendorId,
    ],
  );

  useEffect(() => {
    if (firstRender.current || !vendorId || !vehicleTypeId) return;
    formValues && searchDebounce(formValues?.searchTerm);
  }, [formValues?.searchTerm, vehicleTypeId, vendorId]); // eslint-disable-line react-hooks/exhaustive-deps

  // autoRefresh segments
  const refreshSegments = useCallback(async () => {
    if (
      getIsVendorNotChanged(vendorId) &&
      Cookie.get(SESSION_COOKIE_KEY) &&
      vehicleTypeId &&
      !firstRender.current &&
      formValues
    ) {
      const pickupStatusTypeIds: number[] = getIds(formValues.pickupStatusTypeIds, '_');
      const priorityTypeIds: number[] = getIds(formValues.priorityTypeIds, '_');
      const serviceActivityIds: number[] = getIds(formValues.serviceActivityIds, '_');
      const serviceZoneIds: number[] = getIds(formValues.serviceZoneIds, '_');
      const groupIds: number[] = getIds(formValues.groupIds, '_');
      const routeIds: number[] = getIds(formValues?.routeId, '_');
      const isRouteTemplate =
        !!routeIds.length && routes.filter((route: NameIdAndRouteType) => route.id === routeIds[0])[0]?.isRouteTemplate;
      const lastActivity: number[] = getIds(formValues.lastActivity, '_');
      const noLoadingIndicator = true;
      const shouldFilterLastActivity = formValues.isLastActivity;
      const searchTerm = formValues.searchTerm;

      const isSectionWithNothingSelected =
        !serviceActivityIds.length || !priorityTypeIds.length || !serviceZoneIds.length || !groupIds.length;

      if (isSectionWithNothingSelected) {
        // trick to do not call api when nothing is selected and do not show any segment
        filterAlternativeFleetSegments(
          vehicleTypeId,
          [],
          [],
          shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
        )(dispatch);
      } else {
        await loadVendor(vendorId)(dispatch);

        loadAlternativeFleetSegments(
          {
            vendorId,
            vehicleTypeId,
            lastActivity: lastActivity,
            pickupStatusTypeIds: pickupStatusTypeIds,
            priorityTypeIds,
            serviceActivityIds,
            serviceZoneIds,
            groupIds,
            routeId: !isRouteTemplate ? (routeIds.length ? routeIds[0] : undefined) : undefined,
            routeTemplateId: isRouteTemplate ? (routeIds.length ? routeIds[0] : undefined) : undefined,
            searchTerm,
          },
          shouldFilterLastActivity ? 'lastActivity' : 'pickupStatus',
          noLoadingIndicator,
        )(dispatch);
      }
    }
  }, [vendorId, vehicleTypeId, formValues, routes, dispatch]);

  useDataPolling(refreshSegments, SEGMENTS_REFRESH_INTERVAL_IN_MS, Infinity);

  /* ===================================
  *        VEHICLE POSITIONS
  =================================== */
  useEffect(() => {
    if (firstRender.current || !vendorId || !vehicleTypeId) return;

    const includeInactiveVehicles = true;

    if (formValues?.showVehiclePositions && getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
      loadVehiclesList(TODAY_FORMATTED, includeInactiveVehicles, vehicleTypeId.toString())(dispatch);
    } else {
      dispatch(resetVehiclePositions());
    }
  }, [dispatch, formValues?.showVehiclePositions, vehicleTypeId, vendorId]);

  // autoRefresh vehicle positions
  const refreshVehiclePositions = useCallback(() => {
    if (!formValues?.showVehiclePositions) return;
    loadVehiclesList(TODAY_FORMATTED, true, vehicleTypeId.toString(), undefined, undefined, undefined, true)(dispatch);
  }, [dispatch, formValues?.showVehiclePositions, vehicleTypeId]);

  useDataPolling(refreshVehiclePositions, VEHICLES_REFRESH_INTERVAL_IN_MS, Infinity);

  /* ===================================================
  *       GEO FENCES, CITY INSIGHTS, HAULER LOCATIONS
    =================================================== */

  // geoFences
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.geoFences?.groups) return;
    const geoIds: number[] = [];
    forEach(extraMapLayersFormValues?.geoFences?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true && startsWith(key, 'geoId_')) {
          const geoId = replace(key, 'geoId_', '');
          const geoIdNum = parseInt(geoId);
          if (!isNaN(geoIdNum)) {
            geoIds.push(geoIdNum);
          }
        }
      });
    });

    if (geoIds.length) {
      const geoFenceZoneTypeIds = undefined;
      const geoFenceName = undefined;
      const page = 1;
      const limit = 200;
      const sortOrder = undefined;
      const sortedBy = undefined;
      const routeDate = undefined;
      loadGeoFences({
        vendorId,
        geoFenceZoneTypeIds,
        geoFenceName,
        page,
        limit,
        sortOrder,
        sortedBy,
        geoFenceIdsCSV: geoIds.join(','),
        routeDate,
      })(dispatch);
    } else {
      dispatch(resetGeoFences());
    }
  }, [dispatch, extraMapLayersFormValues?.geoFences?.groups, vendorId]);

  // hauler locations
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.haulerLocations?.groups) return;
    const locationSubTypeIds: number[] = [];
    const locationIndividualIds: number[] = [];
    forEach(extraMapLayersFormValues?.haulerLocations?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true && startsWith(key, 'locationSubTypeId_')) {
          const haulerId = replace(key, 'locationSubTypeId_', '');
          const haulerIdNum = parseInt(haulerId);
          if (!isNaN(haulerIdNum)) {
            locationSubTypeIds.push(haulerIdNum);
          }
        } else if (value.groupFilters) {
          forEach(value.groupFilters, (value, key) => {
            if (value === true && startsWith(key, 'locationId_')) {
              const haulerId = replace(key, 'locationId_', '');
              const haulerIdNum = parseInt(haulerId);
              if (!isNaN(haulerIdNum)) {
                locationIndividualIds.push(haulerIdNum);
              }
            }
          });
        }
      });
    });
    if (locationSubTypeIds.length || locationIndividualIds.length) {
      const filteredHaulerLocations = filter(haulerLocations, location => {
        return locationSubTypeIds.includes(location.customerSubTypeId) || locationIndividualIds.includes(location.id);
      });
      dispatch(setVendorLocationsToDisplay(filteredHaulerLocations));
    } else dispatch(setVendorLocationsToDisplay([]));
  }, [dispatch, extraMapLayersFormValues?.haulerLocations?.groups, haulerLocations, vendorId]);

  // city insights
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.cityInsights?.groups) return;
    const cityInsightTypes: string[] = [];
    forEach(extraMapLayersFormValues?.cityInsights?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true) {
          cityInsightTypes.push(key);
        }
      });
    });
    if (cityInsightTypes.length) loadMapInsightsMapbox(TODAY_FORMATTED, cityInsightTypes)(dispatch);
    else dispatch(resetMapInsightsMapbox());
  }, [dispatch, extraMapLayersFormValues?.cityInsights?.groups, vendorId]);

  //city Alerts
  useEffect(() => {
    if (firstRender.current || !vendorId || !extraMapLayersFormValues?.cityAlerts?.groups) return;
    const cityAlertTypes: number[] = [];
    forEach(extraMapLayersFormValues?.cityAlerts?.groups, (group: { groupFilters: any }) => {
      forEach(group.groupFilters, (value, key) => {
        if (value === true) {
          const keyNum = Number(replace(key, 'alertType_', ''));
          cityAlertTypes.push(keyNum);
        }
      });
    });

    const routeIds: number[] = getIds(formValues?.routeId, '_');
    const routeId = routeIds.length ? routeIds[0] : undefined;

    if (cityAlertTypes.length) loadCityAlerts(cityAlertTypes, routeId)(dispatch);
    else dispatch(resetCityAlerts());
  }, [dispatch, extraMapLayersFormValues?.cityAlerts?.groups, vendorId, formValues?.routeId]);
};

export default useLoadDataForSnowSweeperDashboard;
