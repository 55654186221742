import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';

import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { isNavi3FeatureEnabled, isTravelPathNavigationFeatureEnabled } from 'src/vendors/ducks/features';
import { loadDisposalFacilities, loadOperationalFacilities } from 'src/fleet/ducks';
import { loadFeatures } from 'src/vendors/ducks';
import { loadFiltersSettings } from 'src/common/ducks';
import {
  loadGeoFenceFilterOptions,
  loadGroups,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  loadServiceZones,
  loadTravelPathStatusDetails,
} from 'src/routes/ducks';
import { loadRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { loadStreetNetwork, setIsEditSegmentsModeOn } from 'src/customers/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { PageLoading } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { STREET_SEGMENT_ASSIGNED_TYPES_STATUSES } from 'src/customers/constants/streetNetwork';
import SnowOrSweeperRouteTemplateEditorPage from './SnowOrSweeperRouteTemplateEditorPage';
import translate from 'src/core/services/translate';

interface TemplateParams {
  routeTemplateId?: string;
}

const batchedLocationsRequesterId = 'snowOrSweeperBatchId';

const SnowOrSweeperRouteTemplateEditorPageResolver: FC<RouteComponentProps<TemplateParams>> = ({
  match: {
    params: { routeTemplateId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);
  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);

  const { pathname } = history.location;
  const isSnowPlowRoute = pathname.includes('snow-plow');
  const isStreetSweeperRoute = pathname.includes('street-sweeper');

  const streetSegmentAssignedTypesStatus = routeTemplateId ? STREET_SEGMENT_ASSIGNED_TYPES_STATUSES[0].id : undefined;
  const routeId = undefined;
  const routeTemplateIdFormatted = routeTemplateId ? Number(routeTemplateId) : undefined;
  const streetSegmentAssignedStatus = undefined;
  const vehicleTypeId =
    !routeTemplateIdFormatted && isSnowPlowRoute ? SNOW_PLOW_ID : isStreetSweeperRoute ? STREET_SWEEPER_ID : undefined;

  const loadDependencies = async () => {
    if (!vendorId) Promise.reject();

    dispatch(setIsEditSegmentsModeOn(false));

    const includeRouteTemplateIds = true;

    const isActive = false;
    const includeInactiveFacilities = true;

    const dependencies: Promise<any>[] = [
      loadStreetNetwork({
        vendorId,
        routeId,
        routeTemplateId: routeTemplateIdFormatted,
        includeRouteTemplateIds,
        streetSegmentAssignedStatus,
        streetSegmentAssignedTypesStatus,
        vehicleTypeId,
      })(dispatch),
      loadServiceZones(vendorId, false)(dispatch),
      loadFeatures(vendorId)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      !!routeTemplateId
        ? loadOperationalFacilities(vendorId, isActive, includeInactiveFacilities)(dispatch)
        : loadOperationalFacilities(vendorId)(dispatch),
      !!routeTemplateId
        ? loadDisposalFacilities(vendorId, isActive, includeInactiveFacilities)(dispatch)
        : loadDisposalFacilities(vendorId)(dispatch),
      loadGeoFenceFilterOptions({ vendorId })(dispatch),
      loadGroups(vendorId)(dispatch),
    ];

    if (routeTemplateId) {
      const isTemplate = true;
      dependencies.push(loadRouteGeoFence(routeTemplateId, isTemplate)(dispatch));

      const { id } = await loadRouteTemplateOptimized(routeTemplateId)(dispatch);
      if (id) {
        const { total } = await loadRouteTemplateLocationsBatched(batchedLocationsRequesterId, id)(dispatch);
        if (total >= 2 && (isTravelPathFeatureEnabled || isNaviV3FeatureEnabled))
          dependencies.push(loadTravelPathStatusDetails(undefined, id)(dispatch));
      }
    }
    if (userId) {
      dependencies.push(loadFiltersSettings(vendorId, userId)(dispatch));
    }
    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>
        {routeTemplateId ? translate('routes.editPlannedRoute') : translate('routes.createPlannedRoute')}
      </DocumentTitle>
      <Resolver
        successComponent={SnowOrSweeperRouteTemplateEditorPage}
        successProps={{ routeTemplateId }}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/customers/service-network"
      />
    </>
  );
};

export default SnowOrSweeperRouteTemplateEditorPageResolver;
