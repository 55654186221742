import styled, { css } from 'styled-components';

import { mapper, sizeMapper } from '../../../utils/styles';
import Icon from '../Icon';

export const AccordionToggleIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  width: 12px;
  height: 12px;
  color: ${props => props.theme.brandPrimary};
  transform: none;
  transition: transform 0.3s ease-out;
`;

export const AccordionTitle = styled.span<{ size?: string; weight?: string }>`
  line-height: 22px;
  font-size: ${props =>
    mapper(
      props.size,
      {
        xxSmall: '5px',
        xSmall: '11px',
        small: '12px',
        sMedium: '13px',
        medium: '14px',
        large: '16px',
        xLarge: '18px',
        xxLarge: '24px',
      },
      'xLarge',
    )};

  ${props =>
    props.weight &&
    css`
      font-weight: ${() => mapper(props.weight, { light: 300, normal: 400, medium: 500 })};
    `};
`;

export const AccordionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top: 1px solid ${props => props.theme.grayLight};
  cursor: pointer;
`;

export const AccordionSection = styled.div<{ isOpen?: boolean }>`
  &:first-child {
    ${AccordionHead} {
      border-top: none;
    }
  }

  &:last-child {
    ${AccordionHead} {
      border-bottom: none;
    }
  }

  ${props =>
    props.isOpen &&
    css`
      ${AccordionToggleIcon} {
        transform: rotate(180deg);
      }
    `};
`;

export const Accordion = styled.div<{ margin?: string }>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  border: solid ${props => props.theme.grayLight};
  border-width: 1px 0;
`;

export const AdditionalParametersContainer = styled.div`
  margin-top: 10px;
  padding-left: 20px;
`;

export const AdditionalParametersTextArea = styled.textarea.attrs({
  rows: 3,
  type: 'text',
})`
  width: 90%;
  resize: none;
  margin-top: 10px;
  border: 1px solid #76768c;
`;
