import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const ACTIVE = 1;
export const NEW = 2;
export const SUSPENDED = 3;
export const ONHOLD = 4;
export const CLOSED = 5;

export const CUSTOMER_ACCOUNT_STATUSES_LIST = [
  { id: ACTIVE, name: translate('finance.customerAccountStatuses.active') },
  { id: NEW, name: translate('finance.customerAccountStatuses.new') },
  { id: SUSPENDED, name: translate('finance.customerAccountStatuses.suspended') },
  { id: ONHOLD, name: translate('finance.customerAccountStatuses.onHold') },
  { id: CLOSED, name: translate('finance.customerAccountStatuses.closed') },
];

export const CUSTOMER_ACCOUNT_STATUSES = mapKeys(CUSTOMER_ACCOUNT_STATUSES_LIST, 'id');
