import { PureComponent, ReactNode } from 'react';

import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID } from 'src/common/constants';
import { getExcludedFiltersIds } from 'src/common/utils/filters';
import { DatePicker, SimpleInput, TypedField } from 'src/core/components';
import { Button, Grid, GridColumn, ToggleIcon } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { ToggleContainer } from 'src/routes/components/styled';
import { DispatchBoardFilterContainer } from 'src/routes/components/styled/DispatchBoard';
import { JobTypes } from 'src/routes/constants';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { AppState } from 'src/store';
import { isDateValidValidator, isRequired } from 'src/utils/services/validator';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { RouteStatusesMultiSelect, ServiceZonesMultiSelect, SupervisorsMultiSelect } from 'src/routes/components';


export const DISPATCH_BOARD_SOURCE_FORM_NAME = 'dispatchBoardSourceForm';

export interface DispatchBoardSourceFormFilters {
  date: Date | string;
  routeStatuses: number[];
  searchTerm: string;
  serviceZones: number[];
  supervisors: number[];
  groupIds: number[],
}

interface Props {
  children: ReactNode;
  filtersPreferences?: FilterSetting[];
  serviceZones?: ServiceZone[];
  supervisorExperienceEnabled?: boolean;
  supervisors?: Supervisor[];
}

interface State {
  isExpanded: boolean;
}

class DispatchBoardSourceForm extends PureComponent<Props & InjectedFormProps<DispatchBoardSourceFormFilters, Props>, State> {
  state = { isExpanded: false };

  render() {
    const { handleSubmit, children, supervisorExperienceEnabled, serviceZones, filtersPreferences, supervisors } =
      this.props;
    const { isExpanded } = this.state;

    const onToggle = () => {
      this.setState({ isExpanded: !this.state.isExpanded });
    };

    const excludedServiceZonesFiltersIds = getExcludedFiltersIds(
      serviceZones,
      filtersPreferences,
      FILTER_SERVICE_ZONE_ID,
    );

    const excludedSupervisorsFiltersIds = getExcludedFiltersIds(supervisors, filtersPreferences, FILTER_SUPERVISOR_ID);

    return (
      <form onSubmit={handleSubmit}>
        <Grid multiLine>
          <GridColumn size="4/12" padding="no xSmall no no">
            <Field
              name="date"
              component={DatePicker}
              label={translate('common.date')}
              validate={[isRequired, isDateValidValidator]}
            />
          </GridColumn>

          <GridColumn size="5/12">
            <Field
              name="routeStatuses"
              component={RouteStatusesMultiSelect}
              withLabel
              multiSelectProps={{ normalizeValues: Number }}
            />
          </GridColumn>

          <GridColumn align="right" size="3/12">
            <ToggleContainer id={`toggle-filter-${JobTypes.source}-button`} onClick={onToggle} margin="no no xSmall no">
              <ToggleIcon size={10} isOpen={isExpanded} icon={isExpanded ? 'remove' : 'add'} />
              {` ${translate('common.filter')}`}
            </ToggleContainer>
            {children}
          </GridColumn>

          <DispatchBoardFilterContainer isOpen={isExpanded}>
            <GridColumn size={supervisorExperienceEnabled ? '4/12' : '9/12'} padding="no xSmall no no">
              <TypedField
                name="serviceZones"
                component={ServiceZonesMultiSelect}
                props={{
                  excludeServiceZonesIds: excludedServiceZonesFiltersIds,
                  placeholder: translate('routes.allServiceZones'),
                  multiSelectProps: {
                    normalizeValues: Number,
                    fitContentWidth: true,
                  },
                }}
              />
            </GridColumn>

            {supervisorExperienceEnabled && (
              <GridColumn size="5/12" padding="no xSmall">
                <TypedField
                  name="supervisors"
                  component={SupervisorsMultiSelect}
                  props={{
                    excludeSupervisorsIds: excludedSupervisorsFiltersIds,
                    placeholder: translate('routes.allSupervisors'),
                    multiSelectProps: {
                      normalizeValues: Number,
                    },
                  }}
                />
              </GridColumn>
            )}
          </DispatchBoardFilterContainer>

          <GridColumn size="9/12" padding="no xSmall no no">
            <Field
              name="searchTerm"
              component={SimpleInput}
              placeholder={translate('common.search')}
              margin="no"
              padding="xSmall small"
              withBorder
              withBoxShadow
            />
          </GridColumn>

          <GridColumn size="3/12">
            <Button fullWidth color="primary" id="source-apply-button">
              {translate('common.apply')}
            </Button>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
  serviceZones: state.routes.serviceZones.serviceZones,
  supervisorExperienceEnabled: supervisorExperienceFeatureIsEnabled(state),
  supervisors: state.routes.supervisors.supervisors,
});

export default connect(mapStateToProps)(
  reduxForm<DispatchBoardSourceFormFilters, Props>({
    form: DISPATCH_BOARD_SOURCE_FORM_NAME,
  })(DispatchBoardSourceForm),
);
