import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadGeoFenceFilterOptions } from 'src/routes/ducks/';
import { PanelSectionLoading } from 'src/core/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import PinOnMapModal from './PinOnMapModal';

type Props = {
  handleAddressSelection: (address: any) => void;
  handleCloseModal: () => void;
  mapCenterByVendor?: any;
  date?: Date | string;
  noLoad?: boolean;
};

export default function PinOnMapModalResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () => {
    return Promise.all(props.noLoad ? [] : [loadGeoFenceFilterOptions({ vendorId, routeDate: props.date })(dispatch)]);
  };

  return (
    <TypedResolver
      loadingComponent={PanelSectionLoading}
      resolve={loadDependencies}
      successComponent={PinOnMapModal}
      successProps={{ ...props }}
      loadingProps={{ minHeight: 500 }}
    />
  );
}
