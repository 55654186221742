import { useSelector, useDispatch } from 'react-redux';

import { AppState } from 'src/store';
import {
  ESRI_CREDENTIALS_LAYER,
  ESRI_CUSTOMER_LAYER,
  ESRI_GEO_FENCE_LAYER,
  ESRI_ROUTE_LAYER,
} from '../../constants/esriConfig';
import EsriConfigValidationModal from './EsriConfigValidationModal';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import {
  validateEsriCredentials,
  validateEsriCustomerConfig,
  validateEsriGeoFenceConfig,
  validateEsriRouteConfig,
  validateEsriServiceConfig,
} from 'src/vendors/ducks/esriIntegrationConfiguration';

interface Props {
  closeModal: () => void;
  configLayer: string;
  vendorId: number;
}

export default function EsriConfigValidationModalResolver(props: Props) {
  const { closeModal, configLayer, vendorId } = props;
  const esriConfig = useSelector((state: AppState) => state.vendors.esriIntegrationConfig);
  const { esriCredentials, esriCustomerConfig, esriRouteConfig, esriServiceConfig } = esriConfig;
  const dispatch = useDispatch();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();

    if (configLayer === ESRI_CREDENTIALS_LAYER) {
      return validateEsriCredentials(vendorId, esriCredentials)(dispatch);
    } else if (configLayer === ESRI_CUSTOMER_LAYER) {
      return validateEsriCustomerConfig(vendorId, esriCustomerConfig)(dispatch);
    } else if (configLayer === ESRI_ROUTE_LAYER) {
      return validateEsriRouteConfig(vendorId, esriRouteConfig)(dispatch);
    } else if (configLayer === ESRI_GEO_FENCE_LAYER) {
      return validateEsriGeoFenceConfig(vendorId, esriRouteConfig)(dispatch);
    } else {
      return validateEsriServiceConfig(vendorId, esriServiceConfig)(dispatch);
    }
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={EsriConfigValidationModal}
      successProps={{ closeModal, configLayer }}
    />
  );
}
