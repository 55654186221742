import { Feature } from '@turf/turf';
import { findIndex, filter, flatten, forEach } from 'lodash-es';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { APPLICATION_STATUS_ICONS, POLYLINE_COLORS } from 'src/common/constants';
import { DASHBOARD_APPLICATION_STATUS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { RouteDriversVehiclesItem, RouteVehiclesBreadCrumbs } from 'src/dashboard/interfaces/routesData';
import { VehicleBreadcrumbs } from 'src/dashboard/interfaces/vehiclePositions';
import { VehicleTracking } from 'src/dashboard/interfaces/vehicleTrackings';

export const getApplicationStatusId = (tracking: VehicleTracking, statusIndex: number) =>
  /**
   * I know it looks funky, but the id can be only number because of some Mapbox querying
   * problem.
   *
   * Read more: https://github.com/mapbox/mapbox-gl-js/issues/2716
   */
  +`${tracking.vehicle.id}.${statusIndex}`;

export type ApplicationStatusFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon: string;
  vehicleId: number;
  statusIndex: number;
};

export const getApplicationStatusGeoJSON = (vehicleTrackings: VehicleTracking[]) =>
  getFeatureCollection<GeoJSON.Point, ApplicationStatusFeatureProperties>(
    vehicleTrackings
      .map(tracking =>
        tracking.applicationModeChanges?.map((status, statusIndex) =>
          getPointFeature(
            getApplicationStatusId(tracking, statusIndex),
            [status.coordinates.longitude, status.coordinates.latitude],
            {
              id: getApplicationStatusId(tracking, statusIndex),
              clickable: true,
              layer: DASHBOARD_APPLICATION_STATUS_LAYER,
              icon: APPLICATION_STATUS_ICONS[status.applicationModeStatusId].iconType,
              vehicleId: tracking.vehicle.id,
              statusIndex,
            },
          ),
        ),
      )
      .reduce((acc, current) => acc.concat(current), []),
  );

export const getApplicationStatusForVehicleGeoJSON = (vehicleTrackings: VehicleBreadcrumbs) => {
  const flatTrackings = flatten(vehicleTrackings.coords);
  const applicationModeChanges: Feature<GeoJSON.Point, ApplicationStatusFeatureProperties>[] = [];
  const vehicleApplicationModeChanges = filter(flatTrackings, (coord, index) => {
    if (index === 0) return false;
    return coord.am !== flatTrackings[index - 1].am;
  });

  forEach(vehicleApplicationModeChanges, (change, index) => {
    const point = getPointFeature(index, [change.lng, change.lat], {
      id: index,
      clickable: true,
      layer: DASHBOARD_APPLICATION_STATUS_LAYER,
      icon: APPLICATION_STATUS_ICONS[change.am].iconType,
      vehicleId: vehicleTrackings.id,
      statusIndex: index,
      color: POLYLINE_COLORS[0],
    });
    applicationModeChanges.push(point);
  });

  return getFeatureCollection<GeoJSON.Point, ApplicationStatusFeatureProperties>(applicationModeChanges);
};

export const getApplicationStatusForRouteGeoJSON = (
  vehicleTrackings: RouteVehiclesBreadCrumbs,
  vehicleIds: number[],
  routeDriversVehicles: RouteDriversVehiclesItem[],
) => {
  const applicationModeChanges: Feature<GeoJSON.Point, ApplicationStatusFeatureProperties>[] = [];

  for (let index = 0; index < vehicleTrackings.vehicles.length; index++) {
    const vehicle = vehicleTrackings.vehicles[index];
    let color = index;

    const vehicleIndex = findIndex(
      routeDriversVehicles,
      routeDriversVehicle => routeDriversVehicle.vehicleId === vehicle.id,
    );
    if (vehicleIndex > -1) {
      color = vehicleIndex;
    }

    if (color > POLYLINE_COLORS.length - 1) color = 1;
    if (!vehicleIds.includes(vehicle.id)) continue;

    const flatTrackings = flatten(vehicle.coords);
    const vehicleApplicationModeChanges = filter(flatTrackings, (coord, index) => {
      if (index === 0) return false;
      return coord.am !== flatTrackings[index - 1].am;
    });

    forEach(vehicleApplicationModeChanges, (change, index) => {
      const point = getPointFeature(+`${vehicle.id}.${index}`, [change.lng, change.lat], {
        id: +(+`${vehicle.id}.${index}`),
        clickable: true,
        layer: DASHBOARD_APPLICATION_STATUS_LAYER,
        icon: APPLICATION_STATUS_ICONS[change.am].iconType,
        vehicleId: vehicle.id,
        statusIndex: index,
        color: POLYLINE_COLORS[color],
      });
      applicationModeChanges.push(point);
    });
  }

  return getFeatureCollection<GeoJSON.Point, ApplicationStatusFeatureProperties>(applicationModeChanges);
};
