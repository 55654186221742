import React, { Fragment } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { Button, ButtonSet, GridColumn } from '../../../core/components/styled';
import { HoursOfOperation } from './companyInfoFormSections';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface PropsWithoutReduxForm extends RouteComponentProps {
  initialValues: any;
  isEditable: boolean;
  closeModal: () => void;
  onEditIconClick: () => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const HoursOfOperationForm: React.FC<Props> = ({ handleSubmit, isEditable, closeModal }) => (
  <form onSubmit={handleSubmit} noValidate>
    {!!isEditable && (
      <Fragment>
        <HoursOfOperation />
        <GridColumn size="12/12" padding="no">
          <ButtonSet align="center" margin="large auto no">
            <Button type="submit" color="primary">
              {translate('common.save')}
            </Button>
            <Button type="button" margin="no small" color="secondary" onClick={() => closeModal()}>
              {translate('common.cancel')}
            </Button>
          </ButtonSet>
        </GridColumn>{' '}
      </Fragment>
    )}
  </form>
);

const mapStateToProps = (state: AppState) => ({
  initialValues: state.haulerProfile.hoursOfOperation.hoursOfOperation,
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'companyInfo',
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(HoursOfOperationForm),
  ),
);
