import DefaultNoteFieldsSection from '../ServiceRequestConfigurationSections/DefaultNoteFieldsConfigurationSection';
import { Modal } from '../../../../core/components';
import translate from '../../../../core/services/translate';

type Props = {
  configurationId: number;
  serviceTypeId?: number;
  onClose: (formPristine?: boolean) => void;
};

export default function DefaultNoteFieldsViewModal({ configurationId, serviceTypeId, onClose }: Props) {
  return (
    <Modal size="large" onClose={onClose} title={translate(`vendors.open311.serviceRequestNotes`)}>
      <DefaultNoteFieldsSection serviceTypeId={serviceTypeId} />
    </Modal>
  );
}
