import React, { Fragment } from 'react';

import { map } from 'lodash-es';

import { GridColumn, Text } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';

interface Props {
  yRouteServices: any;
}

const YRoutePageStatusDetailsPopover: React.FC<Props> = ({ yRouteServices }) => (
  <Fragment>
    <GridColumn width="100%" padding="no">
      <Text weight="medium" size="medium">
        {translate('routes.servicesAtLocation')}
      </Text>
    </GridColumn>

    {map(yRouteServices.services, (service, index) => (
      <GridColumn width="100%" padding="xxSmall no no no" key={index}>
        <Text weight="medium" size="small">
          {service.wasteMaterialType}
        </Text>
      </GridColumn>
    ))}
  </Fragment>
);

export default YRoutePageStatusDetailsPopover;
