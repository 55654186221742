import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import { CustomerLocationPaymentMethod } from 'src/customers/interfaces/CustomerPaymentMethod';
import { deleteLocationPaymentMethod } from 'src/customers/ducks/paymentMethods';
import { TableRow, TableCell, Text, TableActionButton } from '../../../../core/components/styled';
import ActionButtonTooltip from 'src/core/components/ActionButtonTooltip';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';

interface Props extends CustomerLocationPaymentMethod {
  paymentConnectPaymentMethodId: string;
  customerId: number;
}

export const CustomerLocationPaymentMethodTableRow: FC<Props> = ({ name, address, customerId, id }) => {
  const dispatch = useDispatch();

  const deletePaymentMethod = async (e: MouseEvent<HTMLButtonElement>) => {
    if (await confirm(translate('finance.paymentMethods.confirmDeletePaymentMethod'))) {
      deleteLocationPaymentMethod(customerId, id)(dispatch);
    }
    e.stopPropagation();
  };

  return (
    <TableRow>
      <TableCell vertical width={'85%'}>
        <Text>{name}</Text>
        <Text singleLine>{address}</Text>
      </TableCell>
      <TableCell width={'15%'} align="right">
        <TableActionButton onClick={deletePaymentMethod}>
          <ActionButtonTooltip
            icon="delete"
            tooltip={translate('finance.paymentMethods.deletePayment')}
            tooltipAsString
          />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
};
