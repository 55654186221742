import { useDispatch } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';

import {
  BinLocationMappingTypeId,
  COLLECTION_POINT,
  CURBSIDE_LOOKUP,
  LOCATION_ADDRESS,
  SET_BY_USER,
} from '../../common/constants/binMappingTypes';
import { BULK_ID, RESIDENTIAL_ID } from '../../common/constants/serviceTypes';
import { clearServiceSelectedFeature } from '../ducks/services';
import { CollectionWaypoint, ServicePickupLocation } from '../interfaces/Services';
import { getMapBounds } from 'src/common/components/map/util';
import { MAP_CITY_ZOOM_IN } from 'src/core/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { MapGLWrapper } from 'src/customers/components/styled';
import { setServiceSelectedFeature } from '../ducks/services';
import addressLocationPin from '../../common/assets/img/common/addressLocationPin.svg';
import collectionPointPin from '../../common/assets/img/common/collectionPointPin.svg';
import confirm from '../../core/services/confirm';
import customLocationPin from '../../common/assets/img/common/customLocationPin.svg';
import mapPin from '../../common/assets/img/common/mapPin.svg';
import PickupLocationEditorMapboxPopup from './PickupLocationEditorMapboxPopup';
import translate from '../../core/services/translate';

const pins = {
  [LOCATION_ADDRESS]: addressLocationPin,
  [CURBSIDE_LOOKUP]: mapPin,
  [SET_BY_USER]: customLocationPin,
  [COLLECTION_POINT]: collectionPointPin,
};

export const binMappingLocationTypeTranslationKeys = {
  [LOCATION_ADDRESS]: 'location',
  [CURBSIDE_LOOKUP]: 'curbside',
  [SET_BY_USER]: 'userGenerated',
  [COLLECTION_POINT]: 'collectionPoint',
};

export class PickupLocationMarker {
  constructor(
    public markerType: BinLocationMappingTypeId,
    public position: { lat: number; lng: number },
    public draggable: boolean = false,
    public data?: any,
  ) {}
  icon = pins[this.markerType];
}

export const getPrimaryLocationCoordinates = (servicePickupLocation: ServicePickupLocation) => {
  if (servicePickupLocation.binLocationMappingTypeId === LOCATION_ADDRESS) {
    return {
      lat: servicePickupLocation.addressLatitude,
      lng: servicePickupLocation.addressLongitude,
    };
  }
  if (servicePickupLocation.binLocationMappingTypeId === CURBSIDE_LOOKUP) {
    return {
      lat: servicePickupLocation.curbsideLatitude,
      lng: servicePickupLocation.curbsideLongitude,
    };
  }
  if (servicePickupLocation.binLocationMappingTypeId === SET_BY_USER) {
    return {
      lat: servicePickupLocation.userGeneratedLatitude,
      lng: servicePickupLocation.userGeneratedLongitude,
    };
  }
  if (servicePickupLocation.binLocationMappingTypeId === COLLECTION_POINT && servicePickupLocation.collectionWaypoint) {
    return {
      lat: servicePickupLocation.collectionWaypoint.latitude,
      lng: servicePickupLocation.collectionWaypoint.longitude,
    };
  }
};

export const isPrimaryLocation = (marker: PickupLocationMarker, pickupLocations: ServicePickupLocation) => {
  const isPrimaryType = marker.markerType === pickupLocations.binLocationMappingTypeId;
  if (marker.markerType === COLLECTION_POINT && isPrimaryType) {
    return !!pickupLocations.collectionWaypoint && pickupLocations.collectionWaypoint.id === marker.data.id;
  }
  return isPrimaryType;
};

const getMarkers = (
  servicePickupLocation: ServicePickupLocation,
  serviceTypeId: number | undefined,
  isReadOnly: boolean,
  collectionPoints: CollectionWaypoint[],
  isEditingCollectionPoints: boolean,
): PickupLocationMarker[] => {
  const markers: PickupLocationMarker[] = [];
  if (!isReadOnly || servicePickupLocation.binLocationMappingTypeId !== SET_BY_USER) {
    markers.push(
      new PickupLocationMarker(LOCATION_ADDRESS, {
        lat: servicePickupLocation.addressLatitude,
        lng: servicePickupLocation.addressLongitude,
      }),
    );
    if (
      servicePickupLocation.curbsideLatitude &&
      servicePickupLocation.curbsideLongitude &&
      (serviceTypeId === RESIDENTIAL_ID || serviceTypeId === BULK_ID) &&
      ((isReadOnly && servicePickupLocation.binLocationMappingTypeId === CURBSIDE_LOOKUP) || !isReadOnly)
    ) {
      markers.push(
        new PickupLocationMarker(CURBSIDE_LOOKUP, {
          lat: servicePickupLocation.curbsideLatitude,
          lng: servicePickupLocation.curbsideLongitude,
        }),
      );
    }
  }
  if (
    servicePickupLocation.userGeneratedLatitude &&
    servicePickupLocation.userGeneratedLongitude &&
    servicePickupLocation.binLocationMappingTypeId === SET_BY_USER
  ) {
    markers.push(
      new PickupLocationMarker(
        SET_BY_USER,
        {
          lat: servicePickupLocation.userGeneratedLatitude,
          lng: servicePickupLocation.userGeneratedLongitude,
        },
        !isEditingCollectionPoints && !isReadOnly,
      ),
    );
  }
  if (
    servicePickupLocation.collectionWaypoint &&
    (servicePickupLocation.binLocationMappingTypeId === COLLECTION_POINT || !isReadOnly)
  ) {
    markers.push(
      new PickupLocationMarker(
        COLLECTION_POINT,
        {
          lat: servicePickupLocation.collectionWaypoint.latitude,
          lng: servicePickupLocation.collectionWaypoint.longitude,
        },
        isEditingCollectionPoints && servicePickupLocation.collectionWaypoint.isFresh,
        servicePickupLocation.collectionWaypoint,
      ),
    );
  }
  collectionPoints.forEach(c => {
    if (!servicePickupLocation.collectionWaypoint || servicePickupLocation.collectionWaypoint.id !== c.id)
      markers.push(new PickupLocationMarker(COLLECTION_POINT, { lat: c.latitude, lng: c.longitude }, false, c));
  });
  return markers;
};

interface Props {
  collectionPoints: CollectionWaypoint[];
  handleRemoveCollectionPoints: (collectionPointId: number) => void;
  isEditingCollectionPoints: boolean;
  isPinOnMapActive?: boolean;
  isReadOnly: boolean;
  pickupLocations: ServicePickupLocation;
  serviceId?: number;
  serviceTypeId?: number;
  updatePickupLocations: (pickupLocations: ServicePickupLocation) => void;
  isServiceTypeChanged: boolean;
}

export const PickupLocationEditorMapbox: React.FunctionComponent<Props> = ({
  collectionPoints,
  handleRemoveCollectionPoints,
  isEditingCollectionPoints,
  isPinOnMapActive,
  isReadOnly,
  pickupLocations,
  serviceId,
  serviceTypeId,
  updatePickupLocations,
  isServiceTypeChanged,
}) => {
  const didMountRef = useRef(false);

  const dispatch = useDispatch();

  const [viewport, setViewport] = useState<MapGLViewport>({});
  const [map, setMap] = useState<mapboxgl.Map>();
  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);
  const [dragPan, setDragPan] = useState(true);
  const [draggableMarker, setDraggableMarker] = useState<any>(undefined);
  const [isDoubleClickZoomEnabled, setIsDoubleClickZoomEnabled] = useState<boolean>(true);

  const handleSatelliteViewChange = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  const getMarkerColor = (markerType: number, isPrimaryLocation?: boolean) => {
    let markerColor;

    switch (markerType) {
      case LOCATION_ADDRESS:
        markerColor = `rgba(236, 179, 117, ${isPrimaryLocation ? 1 : 0.5})`;
        break;

      case SET_BY_USER:
        markerColor = `rgba(62, 121, 220, ${isPrimaryLocation ? 1 : 0.5})`;
        break;

      case CURBSIDE_LOOKUP:
        markerColor = `rgba(65, 148, 136, ${isPrimaryLocation ? 1 : 0.5})`;
        break;

      case COLLECTION_POINT:
        markerColor = `rgba(118, 119, 138, ${isPrimaryLocation ? 1 : 0.5})`;
        break;
    }

    return markerColor;
  };

  const markers = useMemo(
    () => getMarkers(pickupLocations, serviceTypeId, isReadOnly, collectionPoints, isEditingCollectionPoints),
    [collectionPoints, pickupLocations, serviceTypeId, isReadOnly, isEditingCollectionPoints],
  );

  const currentMarkers = useMemo(() => [] as any[], []);

  const removeMarkers = useCallback(() => {
    currentMarkers.forEach(marker => marker.remove());
  }, [currentMarkers]);

  const createCollectionPoint = useCallback(
    (latitude, longitude) => {
      updatePickupLocations({
        ...pickupLocations,
        userGeneratedLatitude: undefined,
        userGeneratedLongitude: undefined,
        binLocationMappingTypeId: COLLECTION_POINT,
        previousCollectionWaypoint: pickupLocations.collectionWaypoint,
        collectionWaypoint: {
          latitude: latitude,
          longitude: longitude,
          parentServiceContractId: serviceId,
          isFresh: true,
        },
      });
    },
    [pickupLocations, serviceId, updatePickupLocations],
  );

  const updateUserGeneratedLocation = useCallback(
    (latitude, longitude) => {
      updatePickupLocations({
        ...pickupLocations,
        userGeneratedLatitude: latitude,
        userGeneratedLongitude: longitude,
        binLocationMappingTypeId: SET_BY_USER,
        collectionWaypoint: undefined,
      });
    },
    [pickupLocations, updatePickupLocations],
  );

  const updateMarkerLocation = useCallback(async () => {
    if (isEditingCollectionPoints) {
      if (pickupLocations.binLocationMappingTypeId === SET_BY_USER) {
        if (!(await confirmDeleteCustomPickupLocation())) {
          return;
        }
      }

      createCollectionPoint(draggableMarker._lngLat.lat, draggableMarker._lngLat.lng);
    } else {
      if (pickupLocations.binLocationMappingTypeId === COLLECTION_POINT) {
        if (
          !(await confirm(
            translate('customers.pickupLocations.deleteCustomPickupLocationTitle'),
            translate('customers.pickupLocations.removeCustomCollectionPointMessage'),
          ))
        ) {
          return;
        }
      }

      updateUserGeneratedLocation(draggableMarker._lngLat.lat, draggableMarker._lngLat.lng);
    }
  }, [
    draggableMarker,
    isEditingCollectionPoints,
    createCollectionPoint,
    updateUserGeneratedLocation,
    pickupLocations.binLocationMappingTypeId,
  ]);

  const onDragEnd = useCallback(() => {
    updateMarkerLocation();
    !dragPan && setDragPan(true);
  }, [dragPan, updateMarkerLocation]);

  const addEventListeners = useCallback(
    (marker, newMarker) => {
      let mouseDownTime = 0;
      let mouseDownInterval: any = null;

      const handleMouseDown = () => {
        mouseDownTime = 0;
        mouseDownInterval = setInterval(() => {
          mouseDownTime += 10;
        }, 10);
      };

      const handleMouseUp = (e: any) => {
        clearInterval(mouseDownInterval);
        if (mouseDownTime < 200)
          dispatch(
            setServiceSelectedFeature('servicesMarkers', {
              marker,
            }),
          );
      };

      newMarker.getElement().addEventListener('mousedown', handleMouseDown);
      newMarker.getElement().addEventListener('mouseup', handleMouseUp);
    },
    [dispatch],
  );

  const setPinOnMap = useCallback(
    async (clickedAddress: any) => {
      if (clickedAddress && clickedAddress.lngLat && map) {
        const latitude = clickedAddress.lngLat[1];
        const longitude = clickedAddress.lngLat[0];

        if (latitude && longitude) {
          if (pickupLocations.binLocationMappingTypeId === COLLECTION_POINT && !isEditingCollectionPoints) {
            if (
              !(await confirm(
                translate('customers.pickupLocations.deleteCustomPickupLocationTitle'),
                translate('customers.pickupLocations.removeCustomCollectionPointMessage'),
              ))
            ) {
              return;
            }
          }

          if (
            pickupLocations.binLocationMappingTypeId === SET_BY_USER &&
            isEditingCollectionPoints &&
            (!pickupLocations.collectionWaypoint || !pickupLocations.collectionWaypoint.isFresh)
          ) {
            if (!(await confirmDeleteCustomPickupLocation())) {
              return;
            }
          }

          const newMarker = new mapboxgl.Marker({
            draggable: true,
            color: getMarkerColor(isEditingCollectionPoints ? COLLECTION_POINT : SET_BY_USER, true),
          })
            .setLngLat([longitude, latitude])
            .addTo(map);

          if (newMarker) {
            currentMarkers.push(newMarker);
            draggableMarker && draggableMarker.remove();
            setDraggableMarker(newMarker);

            const currentDraggableMarker = markers.find(marker => marker.draggable);
            if (currentDraggableMarker) {
              const newCurrentDraggableMarker = {
                ...currentDraggableMarker,
                position: {
                  lat: latitude,
                  lng: longitude,
                },
              };

              addEventListeners(newCurrentDraggableMarker, newMarker);
            }
          }

          if (pickupLocations.binLocationMappingTypeId !== SET_BY_USER && !isEditingCollectionPoints) {
            updateUserGeneratedLocation(latitude, longitude);
          } else if (
            isEditingCollectionPoints &&
            (!pickupLocations.collectionWaypoint || !pickupLocations.collectionWaypoint.isFresh)
          ) {
            createCollectionPoint(latitude, longitude);
          }

          !dragPan && setDragPan(true);
        }
      }
    },
    [
      addEventListeners,
      createCollectionPoint,
      currentMarkers,
      draggableMarker,
      dragPan,
      isEditingCollectionPoints,
      map,
      markers,
      pickupLocations,
      updateUserGeneratedLocation,
    ],
  );

  const setMarkers = useCallback(
    async () => {
      if (map) {
        await removeMarkers();

        if (markers) {
          markers.forEach(marker => {
            const newMarker = new mapboxgl.Marker({
              draggable: marker.draggable,
              color: getMarkerColor(marker.markerType, isPrimaryLocation(marker, pickupLocations)),
            })
              .setLngLat([marker.position.lng, marker.position.lat])
              .addTo(map);

            if (newMarker) {
              currentMarkers.push(newMarker);
              draggableMarker && draggableMarker.remove();
              marker.draggable && setDraggableMarker(newMarker);

              addEventListeners(marker, newMarker);
            }
          });

          const points = markers.length
            ? markers.map(marker => {
                return { longitude: marker.position.lng, latitude: marker.position.lat };
              })
            : [];

          const bounds = getMapBounds(points, {
            capZoom: MAP_CITY_ZOOM_IN,
          });

          if (!didMountRef.current) {
            setViewport(bounds);
            didMountRef.current = true;
          }
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, markers, dispatch],
  );

  useEffect(() => {
    if (map) {
      if (draggableMarker) {
        draggableMarker.on('dragend', onDragEnd);
      }

      return () => {
        if (draggableMarker) {
          draggableMarker.off('dragend', onDragEnd);
        }
      };
    }
  }, [onDragEnd, draggableMarker, map]);

  useEffect(() => {
    if (map && markers) {
      if (!isServiceTypeChanged) removeMarkers();
      setMarkers();
    }
  }, [setMarkers, map, markers, isServiceTypeChanged, removeMarkers]);

  useEffect(() => {
    if (map && (!isReadOnly || isEditingCollectionPoints)) {
      if (draggableMarker) {
        const markerElement = draggableMarker.getElement();
        markerElement.style.cursor = 'default';

        markerElement.addEventListener('mouseover', () => {
          markerElement.style.cursor = 'move';
        });

        markerElement.addEventListener('mousedown', () => {
          markerElement.style.cursor = 'move';
          setDragPan(false);
        });

        markerElement.addEventListener('mouseup', () => {
          markerElement.style.cursor = 'default';
        });
      }
    }
  }, [map, draggableMarker, setPinOnMap, isReadOnly, isEditingCollectionPoints]);

  useEffect(() => {
    if (map && isReadOnly && !isEditingCollectionPoints) {
      if (draggableMarker) {
        const markerElement = draggableMarker.getElement();

        markerElement.addEventListener('mouseover', () => {
          markerElement.style.cursor = 'default';
        });

        markerElement.addEventListener('mousedown', () => {
          markerElement.style.cursor = 'default';
          setDragPan(true);
        });
      }
    }
  }, [map, draggableMarker, setPinOnMap, isReadOnly, isEditingCollectionPoints]);

  useEffect(() => {
    if (map) {
      map.once('load', async () => {
        dispatch(clearServiceSelectedFeature());
      });
    }
  }, [map, dispatch]);

  useEffect(() => {
    if (map && isReadOnly && !isEditingCollectionPoints) {
      dispatch(clearServiceSelectedFeature());
      setDragPan(true);
    }
  }, [map, dispatch, isReadOnly, isEditingCollectionPoints]);

  const onMapDblClick = async (e: any) => {
    if (map && !isReadOnly && isPinOnMapActive) {
      setIsDoubleClickZoomEnabled(false);
      await setPinOnMap(e);
      setIsDoubleClickZoomEnabled(true);
    }
  };

  const confirmDeleteCustomPickupLocation = () => {
    return confirm(
      translate('customers.pickupLocations.deleteCustomPickupLocationTitle'),
      translate('customers.pickupLocations.deleteCustomPickupLocationMessage'),
    );
  };

  const setAsPrimaryLocation = async (marker: PickupLocationMarker) => {
    const locationType = marker.markerType;
    const newPickupLocation: ServicePickupLocation = { ...pickupLocations, binLocationMappingTypeId: locationType };
    if (locationType === CURBSIDE_LOOKUP || locationType === LOCATION_ADDRESS || locationType === COLLECTION_POINT) {
      delete newPickupLocation.userGeneratedLatitude;
      delete newPickupLocation.userGeneratedLongitude;
      if (locationType === COLLECTION_POINT) {
        if (pickupLocations.binLocationMappingTypeId === SET_BY_USER) {
          if (!(await confirmDeleteCustomPickupLocation())) {
            return;
          }
        }
        newPickupLocation.collectionWaypoint = marker.data;
      } else {
        // if the collection point was just added, delete it
        if (newPickupLocation.collectionWaypoint) {
          delete newPickupLocation.collectionWaypoint;
        }
      }
    }

    !dragPan && setDragPan(true);
    updatePickupLocations(newPickupLocation);
  };

  const onHandleRemoveCollectionPoints = (collectionPointId: number) => {
    !dragPan && setDragPan(true);
    handleRemoveCollectionPoints(collectionPointId);
  };

  return (
    <MapGLWrapper>
      <MapGL
        disableDefaultNavigationControl
        disableDefaultSatelliteView
        doubleClickZoom={isDoubleClickZoomEnabled}
        dragPan={dragPan}
        enableNewNavigationControl
        enableNewSatelliteView
        onMapDblClick={onMapDblClick}
        onMapRefLoaded={setMap}
        setIsSatelliteViewEnabled={handleSatelliteViewChange}
        viewport={viewport}
      >
        <PickupLocationEditorMapboxPopup
          handleRemoveCollectionPoints={onHandleRemoveCollectionPoints}
          isEditingCollectionPoints={isEditingCollectionPoints}
          isReadOnly={isReadOnly}
          pickupLocations={pickupLocations}
          setAsPrimaryLocation={setAsPrimaryLocation}
        />
      </MapGL>
    </MapGLWrapper>
  );
};
