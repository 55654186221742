import { Validator, WrappedFieldProps } from 'redux-form';

import { BulkySchedulerLimitByDaySettings } from 'src/vendors/interfaces/BulkyItemScheduler';
import { FormError } from 'src/core/components/styled';
import translate from 'src/core/services/translate';

interface Props extends WrappedFieldProps {
  showErrorBeforeSubmit?: boolean;
}

const BulkyItemSchedulerServiceLimitsByDayValidationField: React.FC<Props> = ({
  showErrorBeforeSubmit = false,
  meta: { submitFailed, error },
}) => {
  if ((submitFailed || showErrorBeforeSubmit) && error) {
    return <FormError>{error}</FormError>;
  }

  return null;
};

export default BulkyItemSchedulerServiceLimitsByDayValidationField;

export const bulkyItemSchedulerServiceLimitsByDayValidate: Validator = (_, { bulkySchedulerLimitByDaySettings }) => {
  let isValidationMessageVisible = false;
  let validationMessage = '';

  bulkySchedulerLimitByDaySettings?.forEach(
    (bulkySchedulerLimitByDay1: BulkySchedulerLimitByDaySettings, index1: number) => {
      bulkySchedulerLimitByDaySettings?.forEach(
        (bulkySchedulerLimitByDay2: BulkySchedulerLimitByDaySettings, index2: number) => {
          const hasCommonDays = bulkySchedulerLimitByDay1?.days?.filter((day1: number | string) =>
            bulkySchedulerLimitByDay2?.days?.some(
              (day2: number | string) => Number(day1) === Number(day2) && index1 !== index2,
            ),
          );

          const hasCommonGeoFences = bulkySchedulerLimitByDay1?.geoFences?.filter((geoFence1: number | string) =>
            bulkySchedulerLimitByDay2?.geoFences?.some(
              (geoFence2: number | string) => Number(geoFence1) === Number(geoFence2) && index1 !== index2,
            ),
          );

          const hasNoGeoFencesSelected =
            !bulkySchedulerLimitByDay1?.geoFences?.length &&
            !bulkySchedulerLimitByDay2?.geoFences?.length &&
            index1 !== index2;

          if (hasCommonDays?.length && hasNoGeoFencesSelected) validationMessage = 'sameTimeRangeSelectedLimitByDay';

          if (hasCommonDays?.length && hasCommonGeoFences?.length) validationMessage = 'sameGeofenceSelectedLimitByDay';

          if (
            (hasCommonDays?.length && hasNoGeoFencesSelected) ||
            (hasCommonGeoFences?.length && hasCommonDays?.length)
          )
            isValidationMessageVisible = true;
        },
      );
    },
  );

  if (!isValidationMessageVisible) {
    return;
  }

  return translate(`vendors.bulkyItemScheduler.${validationMessage}`);
};
