import { FC } from 'react';
import { Field } from 'redux-form';
import { times } from 'lodash-es';

import translate from 'src/core/services/translate';
import { WEEKDAYS_STARTING_SUNDAY } from 'src/core/constants/weekdays';
import { Dropdown } from 'src/core/components';
import { GridColumn } from 'src/core/components/styled';
import { isRequired } from 'src/utils/services/validator';
import { billThruMonthOptions } from 'src/customers/constants/billThruMonthOptions';
import { billThruWeekOptions } from 'src/customers/constants/billThruWeekOptions';
import { billingStatus } from 'src/customers/constants/billingStatus';
import { billMonthOfQuarterOptions } from 'src/customers/constants/billMonthOfQuarterOptions';
import { billThruQuarterOptions } from 'src/customers/constants/billThruQuarterOptions';
import { BILLING_CYCLE_ANNUAL_ID, BILLING_CYCLE_MONTHLY_ID, BILLING_CYCLE_QUATERLY_ID, BILLING_CYCLE_WEEKLY_ID } from 'src/customers/constants';
import { billThruAnnualOptions } from 'src/customers/constants/billThruAnnualOptions';
import { billMonthOfAnnualOptions } from 'src/customers/constants/billMonthOfAnnualOptions';

type Props = { automaticBillsEnabled: boolean; billCycleType: number };

const AutomaticBillingFormSections: FC<Props> = ({ automaticBillsEnabled, billCycleType }) => {
  if (!automaticBillsEnabled) {
    return null;
  }

  if (billCycleType === BILLING_CYCLE_QUATERLY_ID || billCycleType === BILLING_CYCLE_ANNUAL_ID) {
    return (
      <>
        <GridColumn width="13%" verticalAlign="center">
          {translate('customers.billing.createBillsOnFirstDayOf')}
        </GridColumn>
        <GridColumn width="22%">
          {billCycleType === BILLING_CYCLE_ANNUAL_ID ? (
            <Field
              defaultValue={billMonthOfAnnualOptions[0]}
              id="billing-billMonthOfAnnual-field"
              name="customerBillSetting.billMonthOfAnnual"
              component={Dropdown}
              options={billMonthOfAnnualOptions}
              label={translate('customers.billing.whichMonth')}
              validate={[isRequired]}
              props={{ raisedLabel: true }}
            />
          ) : (
            <Field
              defaultValue={billMonthOfQuarterOptions[0]}
              id="billing-billMonthOfQuarter-field"
              name="customerBillSetting.billMonthOfQuarter"
              component={Dropdown}
              options={billMonthOfQuarterOptions}
              label={translate('customers.billing.whichQuarterMonth')}
              validate={[isRequired]}
              props={{ raisedLabel: true }}
            />
          )}
        </GridColumn>
        <GridColumn width="10%" verticalAlign="center">
          {translate('customers.billing.forThe')}
        </GridColumn>
        <GridColumn width="24%">
          {billCycleType === BILLING_CYCLE_ANNUAL_ID ? (
            <Field
              defaultValue={billThruAnnualOptions[1]}
              id="billing-billThruAnnual-field"
              name="customerBillSetting.billThruAnnual"
              component={Dropdown}
              options={billThruAnnualOptions}
              label={translate('customers.billing.whichYear')}
              validate={[isRequired]}
              props={{ raisedLabel: true }}
            />
          ) : (
            <Field
              defaultValue={billThruQuarterOptions[1]}
              id="billing-billThruQuarter-field"
              name="customerBillSetting.billThruQuarter"
              component={Dropdown}
              options={billThruQuarterOptions}
              label={translate('customers.billing.whichQuarter')}
              validate={[isRequired]}
              props={{ raisedLabel: true }}
            />
          )}
        </GridColumn>
        <GridColumn width="5%" verticalAlign="center">
          {translate('customers.billing.in')}
        </GridColumn>
        <GridColumn width="15%">
          <Field
            defaultValue={billingStatus[1]}
            id="billing-status-field"
            name="customerBillSetting.releaseInvoice"
            component={Dropdown}
            options={billingStatus}
            label={translate('customers.billing.billStatus')}
            props={{ raisedLabel: true }}
          />
        </GridColumn>
        <GridColumn width="6%" verticalAlign="center">
          {translate('customers.billing.status')}
        </GridColumn>
      </>
    );
  }

  const daysOfMonthOptions = times(31, n => ({ label: n + 1, value: n + 1 }));
  const daysOfWeekOptions = WEEKDAYS_STARTING_SUNDAY.map(wd => ({ label: wd.name, value: wd.id + 1 }));

  return (
    <>
      {billCycleType !== BILLING_CYCLE_MONTHLY_ID && (
        <GridColumn width="18%" verticalAlign="center">
          {translate('customers.billing.createBillsOn')}
        </GridColumn>
      )}
      {billCycleType !== BILLING_CYCLE_WEEKLY_ID ? (
        <>
          {billCycleType === BILLING_CYCLE_MONTHLY_ID && (
            <>
              <GridColumn width="21%" verticalAlign="center">
                {translate('customers.billing.createBillsOnThe')}
              </GridColumn>
              <GridColumn width="15%">
                <Field
                  name="customerBillSetting.billDayOfMonth"
                  component={Dropdown}
                  options={daysOfMonthOptions}
                  validate={[isRequired]}
                  label={translate('customers.billing.dayOfMonth')}
                  margin="no no sMedium"
                />
              </GridColumn>
              <GridColumn width="13%" verticalAlign="center">
                {translate('customers.billing.forThe')}
              </GridColumn>
            </>
          )}

          <GridColumn width="24%">
            <Field
              defaultValue={billThruMonthOptions[1]}
              id="billing-billThruMonth-field"
              name="customerBillSetting.billThruMonth"
              component={Dropdown}
              options={billThruMonthOptions}
              label={translate('customers.billing.whichMonth')}
              validate={[isRequired]}
              props={{ raisedLabel: true }}
            />
          </GridColumn>
        </>
      ) : (
        <>
          <GridColumn width="20%">
            <Field
              name="customerBillSetting.billDayOfWeek"
              component={Dropdown}
              options={daysOfWeekOptions}
              validate={[isRequired]}
              label={translate('customers.dayOfWeek')}
            />
          </GridColumn>
          <GridColumn width="24%">
            <Field
              defaultValue={billThruWeekOptions[1]}
              id="billing-billThruWeek-field"
              name="customerBillSetting.billThruWeek"
              component={Dropdown}
              options={billThruWeekOptions}
              label={translate('customers.billing.whichWeek')}
              validate={[isRequired]}
              props={{ raisedLabel: true }}
            />
          </GridColumn>
        </>
      )}
      <GridColumn width="5%" verticalAlign="center">
        {translate('customers.billing.in')}
      </GridColumn>
      <GridColumn width="15%">
        <Field
          defaultValue={billingStatus[1]}
          id="billing-status-field"
          name="customerBillSetting.releaseInvoice"
          component={Dropdown}
          options={billingStatus}
          label={translate('customers.billing.billStatus')}
          props={{ raisedLabel: true }}
        />
      </GridColumn>
      <GridColumn width="6%" verticalAlign="center">
        {translate('customers.billing.status')}
      </GridColumn>
    </>
  );
};

export default AutomaticBillingFormSections;
