import React, { PureComponent } from 'react';

import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ModalSearch } from '../../../core/components';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';

interface ComponentProps {
  onSearchTermChange: (searchTerm: string) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class UnassignedLocationsForm extends PureComponent<Props> {
  render() {
    const { onSearchTermChange } = this.props;

    return (
      <form>
        <PanelSection padding="no">
          <Grid>
            <GridColumn size="6/12">
              <Field
                name="search"
                component={ModalSearch}
                onChange={(event: any) => onSearchTermChange(event.target.value)}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

export default reduxForm<any, ComponentProps>({
  form: 'unassignedLocations',
  enableReinitialize: true,
})(UnassignedLocationsForm);
