import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, WrappedFieldInputProps } from 'redux-form';

import { Dropdown } from '../../core/components';
import { contactTypesSelector } from '../ducks';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { AppState } from '../../store';

interface ComponentProps {
  placeholder: string;
  withPlaceholder: boolean;
  label: string;
  withLabel: boolean;
  dropdownProps: any;
  input: WrappedFieldInputProps;
}

interface Props extends ComponentProps {
  contactTypes: any;
}

class ContactTypeDropdown extends PureComponent<Props> {
  onChange = (event: any, value: any) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name, value },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      contactTypes,
      dropdownProps,
    } = this.props;

    const contactTypeOptions = contactTypes.map(({ id }: { id: any }) => ({
      label: translate(createTranslationKey(id, 'haulerProfile.contactTypes')),
      value: id,
    }));

    return (
      <Field
        name={name}
        value={value}
        label={label || (withLabel ? translate('haulerProfile.contactType') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('haulerProfile.contactType') : undefined)}
        component={Dropdown}
        options={contactTypeOptions}
        onChange={this.onChange}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  contactTypes: contactTypesSelector(state.haulerProfile.companyInfo.companyInfo),
});

export default connect(mapStateToProps)(ContactTypeDropdown);
