import { memo } from 'react';
import { Source, Layer } from 'react-map-gl';
import {
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_LAYER,
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE,
  STREET_NETWORK_SERVICE_AREAS_CLUSTER_COUNTERS_LAYER,
} from 'src/customers/constants';

export type ServiceAreaMapClusterGLSourceProps<P extends object = {}> = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
};

const ServiceAreaMapClusterGLSource = ({ geoJSON }: ServiceAreaMapClusterGLSourceProps) => {
  return (
    <Source
      cluster
      type="geojson"
      id={STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE}
      data={geoJSON}
      tolerance={0.0001}
      clusterMaxZoom={11}
    >
      <Layer
        id={STREET_NETWORK_SERVICE_AREAS_CLUSTER_COUNTERS_LAYER}
        type="circle"
        source={STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        }}
      />

      <Layer
        id={STREET_NETWORK_SERVICE_AREAS_CLUSTERS_LAYER}
        type="symbol"
        source={STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'text-color': 'white',
        }}
        layout={{
          'text-field': '{point_count_abbreviated}',
          'text-size': 12,
          'text-allow-overlap': true,
        }}
      />
    </Source>
  );
};

export default memo(ServiceAreaMapClusterGLSource);
