import React, { PureComponent } from 'react';

import { get } from 'lodash-es';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { SelectDefaultVendorModal } from '../modals';

interface Props extends RouteComponentProps {
  push: typeof push;
}

class SelectDefaultVendorPage extends PureComponent<Props> {
  onDefaultVendorSelected = () => {
    const { location, push } = this.props;
    const redirectedFrom = get(location, 'state.from.pathname');
    const redirectTo = redirectedFrom || '/dashboard';
    push(redirectTo);
  };

  render() {
    return (
      <SelectDefaultVendorModal onDefaultVendorSelected={this.onDefaultVendorSelected} shouldRefreshPage={false} />
    );
  }
}

const mapDispatchToProps = {
  push,
};

export default withRouter(connect(undefined, mapDispatchToProps)(SelectDefaultVendorPage));
