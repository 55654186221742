import { FC } from 'react';

import { CityAlert } from 'src/vendors/interfaces/CityAlert';
import { dateAndTime } from 'src/utils/services/formatter';
import { getLatLng } from 'src/fleet/components/pages/locationAndCityAlertsSections/utils';
import { LocationAndCityAlert } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { Text } from 'src/core/components/styled';
import { TOP } from 'src/core/constants';
import createPopover from 'src/core/services/createPopover';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';
import LocationAndCityAlertsRoutesPopover from 'src/fleet/components/pages/LocationAndCityAlertsRoutesPopover';

interface Props {
  alert: CityAlert | LocationAndCityAlert;
  isCityAlert?: boolean;
}

const CityAlertsGLPopupContent: FC<Props> = ({ alert, isCityAlert }) => {
  const showRoutes = (event: any) => {
    event.stopPropagation();
    createPopover(
      event.currentTarget,
      LocationAndCityAlertsRoutesPopover,
      { routeNames: alert.routeNames },
      { position: TOP, size: 'large', maxHeight: 200, hasCloseButton: true },
    );
  };

  return (
    <>
      {!isCityAlert && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('common.description')}
          value={(alert as LocationAndCityAlert).description || '-'}
        />
      )}

      {!!alert.address && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vendors.cityAlerts.approximateLocation')}
          value={alert.address}
        />
      )}

      {!!alert.customerName && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('common.customerName')}
          value={alert.customerName}
        />
      )}

      {!!alert.locationName && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('common.locationName')}
          value={alert.locationName}
        />
      )}

      {!!alert.latitude && !!alert.longitude && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={`${translate('common.latitude')} / ${translate('common.longitude')}`}
          value={`${getLatLng(alert.latitude)} / ${getLatLng(alert.longitude)}`}
        />
      )}

      {!!alert.routeNames?.length && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('common.route')}
          value={
            (
              <>
                {alert.routeNames?.length > 0 ? alert.routeNames[0] : '-'}
                {alert.routeNames?.length > 1 && (
                  <Text
                    margin="no no no xxSmall"
                    color="primary"
                    whiteSpace="nowrap"
                    onClick={showRoutes}
                    cursor="pointer"
                  >
                    <b>+{alert.routeNames?.length - 1}</b>
                  </Text>
                )}
              </>
            ) as any
          }
        />
      )}

      {!!alert.lastFlagged && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vendors.cityAlerts.lastFlaggedAt')}
          value={dateAndTime(alert.lastFlagged)}
        />
      )}

      {!!alert.createdBy && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vendors.cityAlerts.createdBy')}
          value={alert.createdBy.toLowerCase()}
        />
      )}

      {!!alert.driverName && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vendors.cityAlerts.lastFlaggedBy')}
          value={alert.driverName}
        />
      )}

      {!!alert.vehicleName && (
        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('common.vehicle')}
          value={alert.vehicleName}
        />
      )}
    </>
  );
};

export default CityAlertsGLPopupContent;
