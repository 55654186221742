import { reduxForm, InjectedFormProps } from 'redux-form';

import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

import { Grid, GridColumn, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import Open311Section from '../modals/ServiceRequestConfigurationSections/Open311Section';
import translate from '../../../core/services/translate';
import CityWorksGeneralSettingsSection from '../modals/ServiceRequestConfigurationSections/CityWorksGeneralSettings';
import CityWorksServiceRequestErrorsSection from '../modals/ServiceRequestConfigurationSections/CityWorksServiceRequestErrorsSection';
import CityWorksServiceConfigSection from '../modals/ServiceRequestConfigurationSections/CityWorksServiceConfigSection';
interface ComponentProps {
  onCancel: (pristine: boolean) => void;
  pristine?: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

function CityWorksConfigurationForm({ onCancel, pristine }: Props) {

  return (
    <>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <Grid multiLine>
        <GridColumn size="12/12">
          <Open311Section
            title={translate('vendors.open311.serviceConfiguration')}
            content={<CityWorksGeneralSettingsSection />}
          />
        </GridColumn>

        <GridColumn size="12/12">
          <Open311Section
            title={translate('vendors.open311.serviceTypeConfig')}
            content={<CityWorksServiceConfigSection />}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <Open311Section
            title={translate('vendors.open311.serviceRequestErrors')}
            content={<CityWorksServiceRequestErrorsSection />}
          />
        </GridColumn>
      </Grid>
    </>
  );
}

export default reduxForm({ form: 'open311Form', onSubmitFail: focusFirstInvalidField })(CityWorksConfigurationForm);
