import createTransformer from '../../utils/services/createTransformer';

export const transformLoadMapboxInsights = (values: any) => {
  const cityInsights = createTransformer(values);

  return {
    cityInsights: {
      insightTypes: cityInsights.get('insightTypes').orderBy(['insightType', 'displayOrder']).value,
      insights: cityInsights.get('mapData').value,
    },
  };
};
