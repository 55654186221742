import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  deviceStatusCountSelector,
  deviceStatusesSelector,
  exportDeviceStatuses,
  resetDeviceStatuses,
} from '../../ducks';
import { DeviceStatusesForm } from '../forms';
import DeviceStatusesPageTableRow from './DeviceStatusesPageTableRow';
import { DuckFunction } from '../../../contracts/ducks';
import { loadYDeviceStatuses } from '../../ducks/deviceStatuses';
import { LIMIT_PER_PAGE } from '../../../core/constants';
import { Message, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { Pagination, Table } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  isExporting: boolean;
  isLoading: boolean;
  total: number;
  deviceStatuses?: any[];
  vendorId: number;
  loadYDeviceStatuses: DuckFunction<typeof loadYDeviceStatuses>;
  resetDeviceStatuses(...args: any[]): any;
  exportDeviceStatuses(...args: any[]): any;
  push(...args: any[]): any;
}

class DeviceStatusesPage extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { location, vendorId, loadYDeviceStatuses } = this.props;

    if (location.search !== prevProps.location.search) {
      const { vehicleTypeId, sortedBy, sortOrder, page = 1 } = getQueryParams(location.search);
      loadYDeviceStatuses(vendorId, vehicleTypeId, sortedBy, sortOrder, page, LIMIT_PER_PAGE);
    }
  }

  componentWillUnmount() {
    this.props.resetDeviceStatuses();
  }

  onVendorChange = (event: any, vendorId: number) =>
    this.loadDeviceStatuses({ vendorId, sortedBy: undefined, sortOrder: undefined, page: undefined });

  onVehicleTypeChange = (event: any, vehicleTypeId: number) =>
    this.loadDeviceStatuses({ vehicleTypeId, sortedBy: undefined, sortOrder: undefined, page: undefined });

  onSortOrderChange = (sortedBy: string, sortOrder: string) => {
    this.loadDeviceStatuses({ sortedBy, sortOrder, page: undefined });
  };

  onSubmit = ({ vendorId, vehicleTypeId }: { vendorId: number; vehicleTypeId: number }) => {
    const { exportDeviceStatuses, location } = this.props;
    const { sortedBy, sortOrder } = getQueryParams(location.search);
    exportDeviceStatuses(vendorId, vehicleTypeId, sortedBy, sortOrder);
  };

  loadDeviceStatuses = (updatedProps: any) => {
    const { location, push } = this.props;
    const { vendorId, vehicleTypeId, sortedBy, sortOrder, page = 1 } = getQueryParams(location.search);

    push(
      createUrl(location.pathname, location.search, {
        vendorId,
        vehicleTypeId,
        sortedBy,
        sortOrder,
        page,
        ...updatedProps,
      }),
    );
  };

  render() {
    const { deviceStatuses, total, isLoading, isExporting, location } = this.props;
    const { sortedBy, sortOrder } = getQueryParams(location.search);

    const deviceStatusTableColumns = [
      { name: 'vendor', label: translate('common.vendor'), width: '16%', sortable: true },
      { name: 'regplate', label: translate('common.regplate'), width: '10%', sortable: true },
      { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '12%', sortable: true },
      { name: 'deviceESN', label: translate('settings.deviceESN'), width: '10%', sortable: true },
      { name: 'lastRecordedOn', label: translate('settings.lastRecordedEvent'), width: '12%', sortable: true },
      {
        name: 'durationSinceLastCommunication',
        label: translate('settings.durationSinceLastCommunication'),
        width: '18%',
        sortable: true,
      },
      { name: 'latitude', label: translate('common.latitude'), width: '10%', sortable: false },
      { name: 'longitude', label: translate('common.longitude'), width: '12%', sortable: false },
    ];

    return (
      <PanelSectionGroup isLoading={isLoading || isExporting}>
        <DeviceStatusesForm
          onSubmit={this.onSubmit}
          onVendorChange={this.onVendorChange}
          onVehicleTypeChange={this.onVehicleTypeChange}
        />

        <PanelSection>
          {!!total && (
            <Table
              cells={deviceStatusTableColumns}
              rows={deviceStatuses}
              rowComponent={DeviceStatusesPageTableRow}
              sort={this.onSortOrderChange}
              sortedBy={sortedBy}
              sortOrder={sortOrder}
            />
          )}

          {!total && <Message padding="sMedium">{translate('settings.noDeviceStatuses')}</Message>}
        </PanelSection>

        <Pagination totalResults={total!} />
      </PanelSectionGroup>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  deviceStatuses: deviceStatusesSelector(state.settings.deviceStatuses),
  isExporting: state.settings.deviceStatuses.isExporting,
  isLoading: state.settings.deviceStatuses.isLoading,
  total: deviceStatusCountSelector(state.settings.deviceStatuses) as any as number,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadYDeviceStatuses,
  resetDeviceStatuses,
  exportDeviceStatuses,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceStatusesPage));
