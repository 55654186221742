import { map, upperFirst } from 'lodash-es';
import { camelize } from 'humps';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';

const getEquipmentSize = (equipmentSize: string) => parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string) =>
  `common.equipmentSizeShortCodes.x${upperFirst(
    camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
  )}`;

export const getServiceName = ({ equipmentSize, equipmentType }: any) => {
  const equipmentTypeShortCode = translate(createTranslationKey(equipmentType, 'common.equipmentTypeShortCodes'));
  const equipmentSizeShortCode = translate(getEquipmentSizeTranslationKey(equipmentSize), {
    size: getEquipmentSize(equipmentSize),
  });

  return `${equipmentSizeShortCode} - ${equipmentTypeShortCode}`;
};

export const transformSuspendSearchCustomerLocations = (customers: any) => {
  const result = [];
  const label = translate('customers.customers');
  const options = map(customers, customer => ({
    id: customer.id,
    label: customer.name,
    value: {
      id: customer.id,
      name: customer.name,
      status: customer.status,
      locations: map(customer.locations, location => ({
        id: location.id,
        name: location.name,
        address: location.address,
        serviceContracts: map(location.serviceContracts, serviceContract => ({
          id: serviceContract.id,
          name: getServiceName(serviceContract),
        })),
      })),
    },
  }));
  result.push({ label, options });
  return result;
};
