import React from 'react';
import humps from 'humps';

import {
  ActionButtonTooltip,
  MultiSelect,
  UnconnectedCheckbox,
  UnconnectedDaysOfWeekPicker,
  UnconnectedDropdown,
} from '../../../../core/components';
import { AssignedCheckmarkContainer, DayOfWeekPicker } from '../../styled/RouteRecommendations';
import { AsyncOptionsPositions, OptionDropdownPosition } from '../../../../core/components/MultiSelect';
import { CustomerServiceIcon } from '../customerDetailsPageSections/CustomerServiceIcon';
import { DropdownOption } from '../../../../core/components/Dropdown';
import { getServiceName } from '../../../../routes/services/transformRouteTemplate';
import { isOptimizedStopInsertionFeatureEnabled } from 'src/vendors/ducks/features';
import {
  ROUTE_RECOMMENDATION_POSITION_OPTIONS,
  ROUTE_RECOMMENDATION_POSITION_ALL_OPTIONS,
} from '../../../../core/constants/routeRecommendation';
import { RouteRecommendationOption } from '../../RouteRecommendationMultiSelectOption';
import { SERVICE_TYPE_ICONS } from '../../../constants';
import { ServiceContractRecommendationAssignment } from './RouteRecommendationsPage';
import { ServiceContractsWithRecommendation } from '../../../ducks/routeRecommendations';
import { TableCell, TableRow, Text } from '../../../../core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { WEEKDAYS_BY_ID } from '../../../../core/constants';
import createTranslationKey from '../../../../utils/services/createTranslationKey';
import loadRouteTemplatesByScheduledDays from '../../../services/loadRouteTemplatesByScheduledDays';
import translate from '../../../../core/services/translate';

interface Props {
  assignment: ServiceContractRecommendationAssignment;
  cacheRouteTemplates: (service: ServiceContractsWithRecommendation, routeTemplates: DropdownOption[]) => void;
  checked: boolean;
  index: number;
  onCheckChange: (service: ServiceContractsWithRecommendation) => void;
  recommendationsLength: number;
  service: ServiceContractsWithRecommendation;
  vendorId: number;
  onAssignmentChange: (
    service: ServiceContractsWithRecommendation,
    routeTemplateIds: number[],
    cachedRouteTemplates?: DropdownOption[],
    position?: string,
  ) => void;
}

export const recommendationsTableRowHeight = 80;

const formatMultiSelectText = (values: any[] | null, options: DropdownOption[]) => {
  if (!values) {
    return '';
  }
  if (values.length === 1) {
    return (options.find(o => o.value === values[0]) || {}).label;
  }
  return translate('common.xSelected', { selected: values.length });
};

const RouteRecommendationsTableRow: React.SFC<Props> = ({
  assignment,
  cacheRouteTemplates,
  checked,
  index,
  onAssignmentChange,
  onCheckChange,
  recommendationsLength,
  service,
  service: {
    address,
    customerName,
    equipmentType,
    locationName,
    recommendedRouteTemplate,
    schedulerType,
    serviceContractAccountStatusType,
    serviceType,
    wasteMaterialType,
    equipmentSize,
  },
  vendorId,
}) => {
  const isOptimizedStopInsertionEnabled = useSelector(state => isOptimizedStopInsertionFeatureEnabled(state));

  const loadRouteTemplates = () => {
    return loadRouteTemplatesByScheduledDays(vendorId, serviceType.id, schedulerType.id.toString()).then(result => {
      cacheRouteTemplates(service, result);
    }) as any;
  };

  const fakeInputForMultiselect = {
    value: assignment
      ? assignment.recommendedRouteTemplateIds
      : service.recommendedRouteTemplate
      ? [service.recommendedRouteTemplate.id]
      : undefined,
    onChange: (value: any) => {
      onAssignmentChange(
        service,
        value,
        assignment && assignment.cachedRouteTemplates,
        assignment.serviceContractPosition,
      );
    },
  };

  const id = `${service.serviceContractId}_${service.schedulerType.id}`;

  const optionDropdownPosition =
    recommendationsLength <= 7 || index >= recommendationsLength - 7
      ? OptionDropdownPosition.Top
      : OptionDropdownPosition.Bottom;

  return (
    <TableRow align={'center'} height={recommendationsTableRowHeight} key={id} id={id}>
      <TableCell width="5%">
        <UnconnectedCheckbox onChange={() => onCheckChange(service)} block checked={checked} size="small" />
      </TableCell>
      <TableCell width="20%" vertical>
        <Text block margin="no no xxSmall" singleLine>
          {customerName}
        </Text>
        <Text weight="light" size="small" margin="no no xxSmall" singleLine>
          {locationName}
        </Text>
        <Text weight="light" size="small" margin="no no xxSmall" singleLine>
          {address}
        </Text>
      </TableCell>
      <TableCell width="15%">
        <CustomerServiceIcon
          accountStatusId={serviceContractAccountStatusType.id}
          src={SERVICE_TYPE_ICONS[humps.camelize(equipmentType.name.split('.').join(''))]}
        />
        <Text block margin="no no xxSmall" singleLine>
          {translate(createTranslationKey(serviceType.name, 'common.serviceTypes'))}
        </Text>
      </TableCell>
      <TableCell width="13%">
        <Text>
          {getServiceName({
            equipmentTypeTechnicalName: equipmentType.technicalName,
            equipmentType: equipmentType.name,
            equipmentSizeTechnicalName: equipmentSize.technicalName,
            equipmentSize: equipmentSize.name,
            serviceTypeName: serviceType.name,
            wasteMaterialTypeTechnicalName: wasteMaterialType.technicalName,
          })}
        </Text>
      </TableCell>
      <DayOfWeekPicker width="17%">
        <UnconnectedDaysOfWeekPicker
          isReadOnly
          multiple
          selectedWeekdays={[WEEKDAYS_BY_ID[schedulerType.id].shortCode]}
          dayOfWeekProps={{ margin: "no xSmall no no'" }}
          daysOfWeekProps={{ margin: 'no' }}
        />
      </DayOfWeekPicker>
      <TableCell width="15%">
        <MultiSelect
          asyncOptionsPosition={AsyncOptionsPositions.Bottom}
          cachedAsyncOptions={assignment && assignment.cachedRouteTemplates}
          canCheckAll={false}
          defaultToAll={false}
          formatText={(values, allChecked, options) => formatMultiSelectText(values, options)}
          input={fakeInputForMultiselect as any}
          isClearable
          isSearchable
          loadAsyncOptions={loadRouteTemplates}
          margin="no"
          meta={{} as any}
          minWidth="250px"
          normalizeValues={Number}
          options={
            recommendedRouteTemplate
              ? [
                  {
                    isDisabled: false,
                    label: recommendedRouteTemplate.name,
                    renderCustomOption: () => <RouteRecommendationOption label={recommendedRouteTemplate.name} />,
                    value: recommendedRouteTemplate.id,
                  },
                ]
              : [
                  {
                    value: 'Unavailable',
                    label: translate('customers.routeRecommendations.recommendationUnavailable'),
                    unselectable: true,
                  },
                ]
          }
          optionDropdownPosition={optionDropdownPosition}
        />
      </TableCell>
      <TableCell width="15%">
        <UnconnectedDropdown
          disabled={
            assignment && (!assignment.recommendedRouteTemplateIds || !assignment.recommendedRouteTemplateIds.length)
          }
          width="calc(100% - 25px)"
          margin="no"
          value={assignment && assignment.serviceContractPosition}
          onChange={(values: string) =>
            onAssignmentChange(
              service,
              assignment.recommendedRouteTemplateIds || [],
              assignment.cachedRouteTemplates,
              values,
            )
          }
          options={
            isOptimizedStopInsertionEnabled
              ? ROUTE_RECOMMENDATION_POSITION_ALL_OPTIONS
              : ROUTE_RECOMMENDATION_POSITION_OPTIONS
          }
        />
        {assignment &&
          assignment.recommendedRouteTemplateIds &&
          !!assignment.recommendedRouteTemplateIds.length &&
          assignment.serviceContractPosition && (
            <AssignedCheckmarkContainer>
              <ActionButtonTooltip tooltip="routeRecommendationValid" icon="check" />
            </AssignedCheckmarkContainer>
          )}
      </TableCell>
    </TableRow>
  );
};

export default RouteRecommendationsTableRow;
