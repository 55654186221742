import update from 'immutability-helper';
import { findIndex, uniqueId } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Actions
const CREATE_NOTIFICATION = 'core/notifications/CREATE_NOTIFICATION';
const REMOVE_NOTIFICATION = 'core/notifications/REMOVE_NOTIFICATION';

export interface AppNotification {
  id: string;
  message: string;
  color: string;
  autoClose: boolean;
  seeMoreContent?: () => JSX.Element;
}

type Dispatch = ThunkDispatch<AppNotification, any, AnyAction>;

// Initial state
const initialState: AppNotification[] = [];

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return update(state, {
        $push: [action.notification],
      });

    case REMOVE_NOTIFICATION: {
      const index = findIndex(state, { id: action.id });
      return update(state, { $splice: [[index, 1]] });
    }

    default:
      return state;
  }
};

// Action creators
const addNotification = (notification: AppNotification) => ({
  type: CREATE_NOTIFICATION,
  notification,
});

export const removeNotification = (id: string) => ({
  type: REMOVE_NOTIFICATION,
  id,
});

export const createNotification =
  (message: string, color: string, autoClose?: number, seeMoreContent?: () => JSX.Element) => (dispatch: Dispatch) => {
    const id = uniqueId();

    const notification = {
      id,
      message,
      color,
      autoClose: !!autoClose,
      seeMoreContent,
    };
    dispatch(addNotification(notification));

    if (!!autoClose) {
      setTimeout(() => dispatch(removeNotification(id)), autoClose);
    }
  };
