import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadRouteJob as doLoadRouteJob,
  createRouteJob as doCreateRouteJob,
  updateRouteJob as doUpdateRouteJob,
  loadRouteHasStopsInPendingOptimization as doLoadRouteHasStopsInPendingOptimization,
  loadRouteTemplatesHaveStopsInPendingOptimization as doLoadRouteTemplatesHaveStopsInPendingOptimization,
  loadRouteHasMaterialTypeConfigured as doLoadRouteHasMaterialTypeConfigured,
} from '../../services/dispatchBoardRouteJobs';

// Actions
const START_LOAD = 'dispatchBoard/routeJob/START_LOAD';
const FAIL_LOAD = 'dispatchBoard/routeJob/FAIL_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/routeJob/COMPLETE_LOAD';
const START_SAVE = 'dispatchBoard/routeJob/START_SAVE';
const FAIL_SAVE = 'dispatchBoard/routeJob/FAIL_SAVE';
const COMPLETE_SAVE = 'dispatchBoard/routeJob/COMPLETE_SAVE';
const RESET = 'dispatchBoard/routeJob/RESET';
const START_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION =
  'dispatchBoard/routeJob/START_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION';
const COMPLETE_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION =
  'dispatchBoard/routeJob/COMPLETE_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION';
const FAIL_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION =
  'dispatchBoard/routeJob/FAIL_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION';

const START_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED = 'dispatchBoard/routeJob/START_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED';
const COMPLETE_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED =
  'dispatchBoard/routeJob/COMPLETE_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED';
const FAIL_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED = 'dispatchBoard/routeJob/FAIL_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED';

interface State {
  isLoading: boolean;
  isLoadingRouteHasStopsInPendingOptimization: boolean;
  isLoadingRouteHasMaterialTypeConfigured: boolean;
  isSaving: boolean;
  routeJob: any;
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isLoadingRouteHasStopsInPendingOptimization: false,
  isLoadingRouteHasMaterialTypeConfigured: false,
  isSaving: false,
  routeJob: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeJob: action.routeJob,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case START_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION:
      return update(state, {
        $merge: { isLoadingRouteHasStopsInPendingOptimization: true },
      });

    case COMPLETE_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION:
      return update(state, {
        $merge: { isLoadingRouteHasStopsInPendingOptimization: false },
      });

    case FAIL_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION:
      return update(state, {
        $merge: { isLoadingRouteHasStopsInPendingOptimization: false },
      });

    case START_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED:
      return update(state, {
        $merge: { isLoadingRouteHasMaterialTypeConfigured: true },
      });

    case COMPLETE_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED:
      return update(state, {
        $merge: { isLoadingRouteHasMaterialTypeConfigured: false },
      });

    case FAIL_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED:
      return update(state, {
        $merge: { isLoadingRouteHasMaterialTypeConfigured: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeJob: any) => ({
  type: COMPLETE_LOAD,
  routeJob,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadRouteHasStopsPendingOptimization = () => ({
  type: START_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION,
});

const completeLoadRouteHasStopsPendingOptimization = () => ({
  type: COMPLETE_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION,
});

const failLoadRouteHasStopsPendingOptimization = () => ({
  type: FAIL_LOAD_ROUTE_HAS_STOPS_IN_PENDING_OPTMIZATION,
});

const startLoadRouteHasMaterialTypeConfigured = () => ({
  type: START_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED,
});

const completeLoadRouteHasMaterialTypeConfigured = () => ({
  type: COMPLETE_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED,
});

const failLoadRouteHasMaterialTypeConfigured = () => ({
  type: FAIL_LOAD_ROUTE_HAS_MATERIAL_TYPE_CONFIGURED,
});

export const loadDispatchBoardRouteJob = (routeJobId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDispatchBoardRouteJobPromise = doLoadRouteJob(routeJobId);
  loadDispatchBoardRouteJobPromise.then(routeJob => dispatch(completeLoad(routeJob))).catch(() => dispatch(failLoad()));
  return loadDispatchBoardRouteJobPromise;
};

export const saveDispatchBoardRouteJob = (jobId: number, jobData: any) => (dispatch: Dispatch) => {
  dispatch(startSave());

  const saveDispatchBoardRouteJobPromise = jobId ? doUpdateRouteJob(jobId, jobData) : doCreateRouteJob(jobData);

  saveDispatchBoardRouteJobPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveDispatchBoardRouteJobPromise;
};

export const loadRouteHasStopsInPendingOptimization = (routeId?: number | null) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteHasStopsPendingOptimization());

  const loadRouteHasStopsPendingOptimizationPromise = doLoadRouteHasStopsInPendingOptimization(routeId);

  loadRouteHasStopsPendingOptimizationPromise
    .then(() => dispatch(completeLoadRouteHasStopsPendingOptimization()))
    .catch(() => dispatch(failLoadRouteHasStopsPendingOptimization()));
  return loadRouteHasStopsPendingOptimizationPromise;
};

export const loadRouteTemplatesHaveStopsInPendingOptimization = (routeTemplateIds?: string) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteHasStopsPendingOptimization());

  const loadRouteTemplatesHaveStopsPendingOptimizationPromise =
    doLoadRouteTemplatesHaveStopsInPendingOptimization(routeTemplateIds);

  loadRouteTemplatesHaveStopsPendingOptimizationPromise
    .then(() => dispatch(completeLoadRouteHasStopsPendingOptimization()))
    .catch(() => dispatch(failLoadRouteHasStopsPendingOptimization()));
  return loadRouteTemplatesHaveStopsPendingOptimizationPromise;
};

export const loadRouteHasMaterialTypeConfigured =
  (routeId?: number | null, materialTypeId?: string, isTemplate?: boolean) => (dispatch: Dispatch) => {
    dispatch(startLoadRouteHasMaterialTypeConfigured());

    const loadRouteHasMaterialTypeConfiguredPromise = doLoadRouteHasMaterialTypeConfigured(
      routeId,
      materialTypeId,
      isTemplate,
    );

    loadRouteHasMaterialTypeConfiguredPromise
      .then(() => dispatch(completeLoadRouteHasMaterialTypeConfigured()))
      .catch(() => dispatch(failLoadRouteHasMaterialTypeConfigured()));
    return loadRouteHasMaterialTypeConfiguredPromise;
  };

export const resetDispatchBoardRouteJob = () => ({
  type: RESET,
});
