import { size } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { YData } from 'src/vendors/interfaces/YData';
import { Modal } from '../../../core/components';
import { ModalFixedHeader, ModalTitle, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveYData } from 'src/vendors/ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';
import YDataForm from '../forms/YDataForm';

interface Props extends ModalProps {
  closeModal: (pristine?: boolean) => void;
}

export default function YDataModal({ closeModal }: Props) {
  const dispatch = useDispatch();

  const { yData, isLoading, isSaving } = useSelector((state: AppState) => state.vendors.yData);

  const [currentYData, setCurrentYData] = useState(yData || ([] as YData[]));

  const toggleYData = (id: number, isChecked: any | boolean) => {
    const newYData = [...currentYData] as YData[];
    const newConfig = newYData.map((val: YData) => {
      if (val.yDataType.id === id) {
        const newValue = { ...val, isActive: isChecked };
        return newValue;
      }
      return val;
    });

    setCurrentYData(newConfig);
  };

  const saveConfiguration = () => {
    saveYData(currentYData)(dispatch)
      .then(() => {
        closeModal();
        createSuccessNotification(translate('vendors.alertMessages.yDataSaved'));
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.yDataSaveError')));
  };

  return (
    <Modal isLoading={isLoading || isSaving} padding="no" size="smallMedium">
      <ModalFixedHeader padding="medium no no no">
        <ModalClose onClick={() => closeModal()}>
          <ModalCloseIcon />
        </ModalClose>
        <ModalTitle>{translate(createTranslationKey('yData', 'vendors.featureCodes'))}</ModalTitle>
      </ModalFixedHeader>

      {size(yData) && (
        <YDataForm
          initialValues={yData}
          onSubmit={saveConfiguration}
          currentYData={currentYData}
          toggleYData={toggleYData}
        />
      )}
    </Modal>
  );
}
