import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash-es';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
import { currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Grid, GridColumn } from '../../../core/components/styled';
import { HAULER_PROFILE_DIVISION_COOKIE } from '../../constants/HaulerProfileDivisionCookie';
import { loadMaterials, saveMaterial } from '../../ducks';
import { Material } from '../../interfaces/Materials';
import { UnconnectedCheckbox } from '../../../core/components';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';

export default function MaterialsForm() {
  const dispatch = useDispatch();

  const haulerId = useSelector((state: AppState) =>
    currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  );

  const materials = orderBy(
    useSelector((state: AppState) => state.haulerProfile.materials.materials),
    'matSubTypeDescription',
    'asc',
  );
  const userName = useSelector((state: AppState) => state.account.login.user.email);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, material: Material) => {
    const isChecked = event.target.checked;
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);

    const newMaterial = {
      ...material,
      isActive: isChecked,
      vendGroup: haulerId,
      updatedBy: userName,
      vendId: divisionIdFromCookie,
    };

    delete newMaterial.isUsedInServiceArea;

    if (!isChecked && material.isUsedInServiceArea) {
      if (await confirm(translate('haulerProfile.alertMessages.removeMaterialWarning'))) {
        saveMaterial(
          haulerId,
          newMaterial,
        )(dispatch).then(() => loadMaterials(haulerId, divisionIdFromCookie)(dispatch));
      }
    } else {
      saveMaterial(haulerId, newMaterial)(dispatch).then(() => loadMaterials(haulerId, divisionIdFromCookie)(dispatch));
    }
  };

  return (
    <Grid multiLine margin="small no">
      {materials.map(material => (
        <GridColumn size="4/12">
          <Grid key={material.matSubType} margin="no no ssMedium no">
            <GridColumn size="12/12">
              <UnconnectedCheckbox
                onChange={event => handleChange(event, material)}
                checked={material.isActive}
                label={translate(`haulerProfile.materialTypes.${material.matSubType}`)}
              />
            </GridColumn>
          </Grid>
        </GridColumn>
      ))}
    </Grid>
  );
}
