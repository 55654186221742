import { size } from 'lodash-es';
import translate from '../../core/services/translate';
import { PaymentStatusOptions } from '../interfaces/ApplyChecks';

export const paymentStatusFormatText = (selectedOptions: number[], allOptionsSelected?: boolean, options?: PaymentStatusOptions[]) => {

  if (allOptionsSelected) {
    return translate('common.allStatuses');
  }

  return selectedOptions.length === 1
    ? options && !!size(options) && options[selectedOptions[0] - 1]?.label
    : translate('common.xStatusesSelected', { selected: selectedOptions.length });
};
