import { PureComponent } from 'react';

import { cloneDeep, debounce, find, get, map, reduce, set, size, values, flatten, isEqual } from 'lodash-es';
import { connect } from 'react-redux';
import { GoogleMap } from '@react-google-maps/api';

import { AppState } from 'src/store';
import {
  CityInsights,
  ContainerInsights,
  RoadConditions,
  ServiceExceptions,
  StreetSweeperTrackings,
  VehicleInsights,
  VehiclePositions,
  VehicleTrackings,
  VendorLocations,
} from 'src/common/components';
import { GOOGLE as google, WASTE_AUDIT } from 'src/common/constants';
import { OpenedInfoWindows } from 'src/common/interfaces/OpenedInfoWindows';
import { DuckFunction, DuckAction } from 'src/contracts/ducks';
import {
  MAP_BOUNDS,
  MAP_CENTER,
  MAP_DEFAULT_ZOOM,
  MAP_GET_BOUNDS,
  MAP_GET_ZOOM,
  MAP_MAX_ZOOM,
  MAP_OPTIONS,
  MAP_STYLE,
} from 'src/core/constants';
import googleMap from 'src/core/services/googleMap';
import {
  cityMapInsightsSelector,
  containerMapInsightsSelector,
  serviceExceptionsSelector,
  showVehicleTrackingStatusSelector,
  streetSweeperSelector,
  streetSweeperTrackingsSelector,
  vehicleMapInsightsSelector,
  vehiclePositionsSelector,
  vehicleTrackingsSelector,
  vendorLocationsSelector,
} from 'src/dashboard/ducks';
import {
  checkIfSnowRoadConditionsAreEnabled,
  checkIfStreetSweepingConditionsAreEnabled,
} from 'src/vendors/ducks/features';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { CONTAINER_INSIGHTS, VEHICLE_TRACKINGS } from 'src/routes/constants/markerOptions';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { DASHBOARD_FILTERS } from 'src/account/constants';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { IconButtonIcon } from 'src/core/components/styled';
import { initialOpenedInfoWindows } from 'src/routes/constants';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import { loadClosestStrobeImage } from 'src/routes/ducks';
import { PermissionGuard } from 'src/account/components';
import { setNavigationMapControl } from 'src/dashboard/ducks/mapControls';
import { SNOW_ROAD_CONDITIONS, STREET_SWEEPER_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';
import { SnowRoadSegmentCondition } from 'src/dashboard/interfaces/snowRoadConditions';
import { vehicleStrobeImagesStatusSelector } from 'src/vendors/ducks';
import { VehicleTracking } from 'src/routes/components/mapWithTimeline/Interfaces';
import DashboardMapAuditLinks from '../DashboardMapAuditLinks';
import DashboardMapLegend from '../DashboardMapLegend';
import DashboardSnowRoadConditionsDeckGL from './DashboardSnowRoadConditionsDeckGL';
import NavigationControl from 'src/common/components/map/NavigationControl';
import TooltipIconButton from 'src/core/components/TooltipIconButton';

interface ComponentProps {
  conditionType?: string;
  isAutoRefresh: boolean;
  isMapLoading: boolean;
  isRubiconZ: boolean;
  shouldMapFitBounds: boolean;
  vehicleType?: string;
}

interface Props extends ComponentProps {
  cityInsights?: any[];
  containerInsights?: any[];
  isOnDemandVideoDownloadEnabled: boolean;
  loadClosestStrobeImage: DuckFunction<typeof loadClosestStrobeImage>;
  onOpenFilters(): void;
  roadConditions?: any[];
  serviceExceptions?: any[];
  snowRoadConditions: SnowRoadSegmentCondition[];
  snowRoadConditionsEnabled: boolean;
  streetSweeper?: any;
  streetSweeperConditions: SnowRoadSegmentCondition[];
  streetSweeperConditionsEnabled: boolean;
  streetSweeperTrackings?: any[];
  vehicleInsights?: any[];
  vehiclePositions?: any[];
  vehicleStrobeImagesEnabled?: boolean;
  vehicleTrackings?: VehicleTracking[];
  vendorId: number;
  vendorLocations?: any[];
  showVehicleTrackingStatus?: {
    showVehicleTracking: boolean;
    showAppStatus: boolean;
  };
  setNavigationMapControl: DuckAction<typeof setNavigationMapControl>;
  onOpenFilters(): void;
}

interface State {
  center: google.maps.LatLng;
  lastActivityRefreshed: boolean;
  mapBounds?: google.maps.LatLngBounds | null;
  mapZoom: number;
  openedInfoWindows: OpenedInfoWindows;
}

class DashboardMap extends PureComponent<Props, State> {
  readonly state: State = {
    center: MAP_CENTER,
    lastActivityRefreshed: false,
    mapBounds: MAP_BOUNDS,
    mapZoom: MAP_DEFAULT_ZOOM,
    openedInfoWindows: initialOpenedInfoWindows,
  };

  map?: google.maps.Map;

  componentDidUpdate(prevProps: Props) {
    const {
      cityInsights = [],
      containerInsights = [],
      roadConditions = [],
      serviceExceptions = [],
      streetSweeperTrackings = [],
      vehicleInsights = [],
      vehiclePositions = [],
      vehicleTrackings = [],
      vendorLocations = [],
      snowRoadConditions,
      streetSweeperConditions,
    } = this.props;

    if (
      prevProps.cityInsights !== cityInsights ||
      prevProps.containerInsights !== containerInsights ||
      prevProps.roadConditions !== roadConditions ||
      !isEqual(prevProps.snowRoadConditions, snowRoadConditions) ||
      !isEqual(prevProps.streetSweeperConditions, streetSweeperConditions) ||
      prevProps.serviceExceptions !== serviceExceptions ||
      prevProps.streetSweeperTrackings !== streetSweeperTrackings ||
      prevProps.vehicleInsights !== vehicleInsights ||
      prevProps.vehiclePositions !== vehiclePositions ||
      prevProps.vehicleTrackings !== vehicleTrackings ||
      prevProps.vendorLocations !== vendorLocations
    ) {
      const vehicleTrackingCoordinates = reduce(
        map(vehicleTrackings, ({ coordinateGroups }) => coordinateGroups),
        (vehicleTrackingsCoordinates, coordinateGroup) => [
          ...vehicleTrackingsCoordinates,
          ...reduce(
            coordinateGroup,
            (groupCoordinates: any[], { coordinates }) => [...groupCoordinates, ...coordinates],
            [],
          ),
        ],
        [] as any[],
      );

      const roadConditionsCoordinates = reduce(
        roadConditions,
        (roadConditionsCoordinates: any[], { geometry: { coordinates } }) => [
          ...roadConditionsCoordinates,
          ...coordinates,
        ],
        [],
      );

      const snowRoadConditionsCoordinates = flatten(
        snowRoadConditions.map(({ lineSegment }) => JSON.parse(lineSegment)),
      );

      const streetSweeperConditionsCoordinates = flatten(
        streetSweeperConditions.map(({ lineSegment }) => JSON.parse(lineSegment)),
      );

      const streetSweeperTrackingsCoordinates = reduce(
        streetSweeperTrackings,
        (streetSweeperTrackingsCoordinates: any[], { coordinates }) => [
          ...streetSweeperTrackingsCoordinates,
          ...coordinates,
        ],
        [],
      );

      const markerPositions = [
        ...vehiclePositions.map(vehiclePosition => ({
          latitude: vehiclePosition.coordinates.latitude,
          longitude: vehiclePosition.coordinates.longitude,
        })),
        ...vendorLocations.map(vendorLocation => ({
          latitude: vendorLocation.latitude,
          longitude: vendorLocation.longitude,
        })),
        ...containerInsights.map(containerInsight => ({
          latitude: containerInsight.latitude,
          longitude: containerInsight.longitude,
        })),
        ...cityInsights.map(cityInsight => ({
          latitude: cityInsight.latitude,
          longitude: cityInsight.longitude,
        })),
        ...vehicleInsights.map(vehicleInsight => ({
          latitude: vehicleInsight.latitude,
          longitude: vehicleInsight.longitude,
        })),
        ...vehicleTrackingCoordinates.map(vehicleTracking => ({
          latitude: vehicleTracking.latitude,
          longitude: vehicleTracking.longitude,
        })),
        ...roadConditionsCoordinates.map(([longitude, latitude]) => ({ latitude, longitude })),
        ...snowRoadConditionsCoordinates.map(([longitude, latitude]) => ({ latitude, longitude })),
        ...streetSweeperConditionsCoordinates.map(([longitude, latitude]) => ({ latitude, longitude })),
        ...streetSweeperTrackingsCoordinates.map(streetSweeperTracking => ({
          latitude: streetSweeperTracking.latitude,
          longitude: streetSweeperTracking.longitude,
        })),
        ...serviceExceptions.map(serviceException => ({
          latitude: serviceException.latitude,
          longitude: serviceException.longitude,
        })),
      ];

      if (markerPositions.length > 0) {
        this.fitBounds(markerPositions);
      }
    }
  }

  onZoomChange = debounce(() => {
    if (!this.map) return;
    if (MAP_GET_ZOOM(this.map) && MAP_GET_BOUNDS(this.map)) {
      this.setState({
        mapZoom: MAP_GET_ZOOM(this.map),
        mapBounds: MAP_GET_BOUNDS(this.map),
      });
    }
  }, 500);

  setMapRef = (map: google.maps.Map) => {
    this.map = map;
  };

  getClosestStrobeImage = async (path: string, vehicleIndex: number, endTimestamp: any) => {
    const { vendorId, vehicleType, vehicleTrackings = [], containerInsights, loadClosestStrobeImage } = this.props;
    const [key, index] = path.split('.');
    let vehicleId = null;
    let timeStamp = null;

    if (key === CONTAINER_INSIGHTS) {
      const containerInsight = find(containerInsights, { insightId: parseInt(index, 10) });
      vehicleId = containerInsight.vehicle.id;
      timeStamp = containerInsight.reportDateTime;
    } else if (key === VEHICLE_TRACKINGS) {
      vehicleId =
        size(vehicleTrackings) > 0 ? vehicleTrackings[vehicleIndex].vehicle.id : vehicleTrackings[0].vehicle.id;
      timeStamp = endTimestamp;
    }

    if (vehicleType !== WASTE_AUDIT && !!timeStamp) {
      await loadClosestStrobeImage(vendorId, vehicleId, timeStamp).then(response => {
        const strobeImageUrl = !!response && response.thumbnailImageUrl ? response.thumbnailImageUrl : undefined;
        this.setState(prevState => ({
          openedInfoWindows: {
            ...prevState.openedInfoWindows,
            imageUrl: strobeImageUrl,
          },
        }));
      });
    }
  };

  fitBounds = (markers: any[]) => {
    const { isAutoRefresh, shouldMapFitBounds } = this.props;
    const { lastActivityRefreshed } = this.state;

    if (!lastActivityRefreshed) {
      if ((!isAutoRefresh && markers) || (shouldMapFitBounds && markers)) {
        const bounds = new google.maps.LatLngBounds();

        if (!markers.length) {
          return;
        }

        markers.forEach(marker => {
          bounds.extend(new google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));
        });

        if (this.map) {
          this.map.fitBounds(bounds);

          if (MAP_GET_ZOOM(this.map) > MAP_MAX_ZOOM) {
            this.setState({ mapZoom: MAP_MAX_ZOOM });
          }
        }
      }
    }

    this.setState({
      lastActivityRefreshed: false,
    });
  };

  toggleInfoWindow = (path: string, vehicleIndex: number, endTimestamp: any) => {
    const { vehicleStrobeImagesEnabled } = this.props;

    this.setState(prevState => {
      const currentValue = get(prevState.openedInfoWindows, path);
      const openedInfoWindows = set(cloneDeep(initialOpenedInfoWindows), path, !currentValue);
      const isInfoWindowOpen =
        values(openedInfoWindows.containerInsights)[0] || values(openedInfoWindows.vehicleTrackings)[0];

      if (vehicleStrobeImagesEnabled && isInfoWindowOpen) {
        this.getClosestStrobeImage(path, vehicleIndex, endTimestamp);
      }

      return {
        ...prevState,
        openedInfoWindows,
      };
    });
  };

  setNavigationRef = (element: HTMLDivElement | null) => {
    const { setNavigationMapControl } = this.props;

    if (element) {
      setNavigationMapControl({ width: element.offsetWidth, height: element.offsetHeight, isVisible: true });
    } else {
      setNavigationMapControl({ isVisible: false });
    }
  };

  setLastActivityRefresh = () => {
    this.setState({
      lastActivityRefreshed: true,
    });
  };

  render() {
    const {
      cityInsights = [],
      conditionType,
      containerInsights = [],
      isMapLoading,
      isOnDemandVideoDownloadEnabled,
      isRubiconZ,
      onOpenFilters,
      roadConditions = [],
      serviceExceptions = [],
      showVehicleTrackingStatus = { showVehicleTracking: false, showAppStatus: false },
      snowRoadConditionsEnabled,
      streetSweeper = [],
      streetSweeperConditionsEnabled,
      streetSweeperTrackings = [],
      vehicleInsights = [],
      vehiclePositions = [],
      vehicleTrackings = [],
      vendorLocations = [],
    } = this.props;
    const { center, mapZoom, mapBounds } = this.state;

    const isSnowRoadConditionsVisible =
      snowRoadConditionsEnabled && conditionType && conditionType.includes(SNOW_ROAD_CONDITIONS);
    const isStreetSweeperConditionsVisible =
      streetSweeperConditionsEnabled && conditionType && conditionType.includes(STREET_SWEEPER_CONDITIONS);

    return (
      <GoogleMap
        options={{ ...(MAP_OPTIONS as any), disableDefaultUI: true, mapTypeId: this.map?.getMapTypeId() }}
        zoom={mapZoom}
        center={center}
        mapContainerStyle={MAP_STYLE}
        onLoad={this.setMapRef}
        onZoomChanged={this.onZoomChange}
        onDragEnd={this.onZoomChange}
      >
        <PermissionGuard permission={DASHBOARD_FILTERS}>
          <ComplexMapControl position="top-left">
            <TooltipIconButton
              tooltip="filters"
              tooltipPosition="right"
              tooltipColor="grayDarker"
              color="secondary"
              margin="no"
              onClick={onOpenFilters}
            >
              <IconButtonIcon icon="filter" color="primary" />
            </TooltipIconButton>
          </ComplexMapControl>
        </PermissionGuard>

        <NavigationControl ref={this.setNavigationRef} isGoogleMap disableCompassToggle map={this.map} />

        <DesktopWidthView>
          <DashboardMapLegend
            conditionType={
              isSnowRoadConditionsVisible
                ? SNOW_ROAD_CONDITIONS
                : isStreetSweeperConditionsVisible
                ? STREET_SWEEPER_CONDITIONS
                : ''
            }
            isRubiconZ={isRubiconZ}
            setLastActivityRefresh={this.setLastActivityRefresh}
          />
          <DashboardMapAuditLinks />
        </DesktopWidthView>

        {vehiclePositions.length > 0 && !isMapLoading && (
          <VehiclePositions
            vehiclePositions={vehiclePositions}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
          />
        )}

        {vehicleTrackings.length > 0 && !isMapLoading && (
          <VehicleTrackings
            isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled}
            mapBounds={mapBounds}
            mapZoom={mapZoom}
            openedInfoWindows={this.state.openedInfoWindows}
            showAppStatus={showVehicleTrackingStatus.showAppStatus}
            showVehicleTracking={showVehicleTrackingStatus.showVehicleTracking}
            toggleInfoWindow={this.toggleInfoWindow as any}
            unitOfMeasure={vehicleTrackings.length > 0 ? vehicleTrackings[0].unitOfMeasure : ''}
            vehicleTrackings={vehicleTrackings}
          />
        )}

        {roadConditions.length > 0 && !isMapLoading && (
          <RoadConditions roadConditions={roadConditions} mapZoom={mapZoom} mapBounds={mapBounds} />
        )}

        {vehicleInsights.length > 0 && !isMapLoading && (
          <VehicleInsights
            vehicleInsights={vehicleInsights}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
          />
        )}

        {containerInsights.length > 0 && !isMapLoading && (
          <ContainerInsights
            containerInsights={containerInsights}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
          />
        )}

        {cityInsights.length > 0 && !isMapLoading && (
          <CityInsights
            cityInsights={cityInsights}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
          />
        )}

        {vendorLocations.length > 0 && !isMapLoading && (
          <VendorLocations
            vendorLocations={vendorLocations}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
          />
        )}

        {serviceExceptions.length > 0 && !isMapLoading && (
          <ServiceExceptions
            serviceExceptions={serviceExceptions}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
            unitOfMeasure={streetSweeper.unitOfMeasure}
          />
        )}

        {streetSweeperTrackings.length > 0 && !isMapLoading && (
          <StreetSweeperTrackings
            streetSweeperTrackings={streetSweeperTrackings}
            vehicleName={streetSweeper.vehicleName}
            mapZoom={mapZoom}
            mapBounds={mapBounds}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
            unitOfMeasure={streetSweeper.unitOfMeasure}
          />
        )}

        {(isSnowRoadConditionsVisible || isStreetSweeperConditionsVisible) && !isMapLoading && !!this.map && (
          <DashboardSnowRoadConditionsDeckGL
            conditionType={
              isSnowRoadConditionsVisible
                ? SNOW_ROAD_CONDITIONS
                : isStreetSweeperConditionsVisible
                ? STREET_SWEEPER_CONDITIONS
                : ''
            }
            map={this.map}
            openedInfoWindows={this.state.openedInfoWindows}
            toggleInfoWindow={this.toggleInfoWindow as any}
          />
        )}
      </GoogleMap>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    cityInsights: cityMapInsightsSelector(state.dashboard.mapInsights),
    containerInsights: containerMapInsightsSelector(state.dashboard.mapInsights),
    isOnDemandVideoDownloadEnabled: isOnDemandVideoDownloadFeatureEnabled(state),
    roadConditions: state.dashboard.roadConditions.oldRoadConditions,
    serviceExceptions: serviceExceptionsSelector(state.dashboard.streetSweeper.streetSweeper),
    showVehicleTrackingStatus: showVehicleTrackingStatusSelector(state.dashboard.vehicleTrackings),
    streetSweeper: streetSweeperSelector(state.dashboard.streetSweeper.streetSweeper),
    streetSweeperTrackings: streetSweeperTrackingsSelector(state.dashboard.streetSweeper.streetSweeper),
    vehicleInsights: vehicleMapInsightsSelector(state.dashboard.mapInsights),
    vehiclePositions: vehiclePositionsSelector(state.dashboard.vehiclesData),
    vehicleStrobeImagesEnabled: vehicleStrobeImagesStatusSelector(state.vendors.features.features),
    vehicleTrackings: vehicleTrackingsSelector(state.dashboard.vehicleTrackings),
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
    vendorLocations: vendorLocationsSelector(state.dashboard.vendorLocations),
    snowRoadConditions: state.dashboard.snowRoadConditions.snowRoadConditions,
    snowRoadConditionsEnabled: checkIfSnowRoadConditionsAreEnabled(state),
    streetSweeperConditions: state.dashboard.streetSweeperConditions.streetSweeperConditions,
    streetSweeperConditionsEnabled: checkIfStreetSweepingConditionsAreEnabled(state),
  };
};

const mapDispatchToProps = { loadClosestStrobeImage, setNavigationMapControl };

export default googleMap(connect(mapStateToProps, mapDispatchToProps)(DashboardMap));