import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { useSelector } from 'src/core/hooks/useSelector';
import { ROUTE_PLANNER_SELECTED_DATE } from 'src/routes/constants/routePlanner';
import { exportRoutesTracker, exportRouteTemplates } from 'src/routes/ducks';
import {
  loadRoutePlannerCalendarData,
  resetRoutePlannerCalendarData,
  resetRoutePlannerTableData,
} from 'src/routes/ducks/routePlanner';
import { setRoutePlannerNewLayoutActive } from 'src/routes/services/routePlannerPersistentBetaFlag';
import { getRoutePlannerOptions } from 'src/routes/services/routePlannerVehicleTypesFormInitialValuesSelector';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { Panel, PanelSectionGroup, Separator } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';
import {
  DispatchBoardJobEditorModalResolver,
  RoutePlannerNewSchedulerModalResolver,
  RouteUploaderModal,
} from '../../modals';
import { BetaBaseWrapper, BetaButton } from '../../styled/RouteTracker';
import RoutePlannerCalendar from './RoutePlannerMainPageSections/RoutePlannerCalendar/RoutePlannerCalendar';
import RoutePlannerRoutes from './RoutePlannerMainPageSections/RoutePlannerRoutes';

const RoutePlannerMainPage: FC = () => {
  const dispatch = useDispatch();
  const { search, pathname, state = { prevPath: '' } } = useLocation();

  const filterPreferences = useSelector(state => state.common.filters.filters);

  const { selectedDate: selectedDateFromUrl } = getQueryParams(search);
  const vehicleTypes = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();

  const calendarDateToSet = selectedDateFromUrl
    ? moment(selectedDateFromUrl, 'MM-DD-YYYY').set('day', moment().day()).toDate()
    : new Date();

  const [calendarDate, setCalendarDate] = useState(calendarDateToSet);

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isAddJobModalOpen, setIsAddJobModalOpen] = useState(false);
  const [addJobRouteId, setAddJobRouteId] = useState<number | undefined>(undefined);
  const [addJobVehicleTypeId, setAddJobVehicleTypeId] = useState<number | undefined>(undefined);
  const [addJobDate, setAddJobDate] = useState<string | undefined>(undefined);

  const [templatesToSchedule, setTemplatesToSchedule] = useState<{ id: number; scheduledDay: number }[]>([]);
  const [defaultDate, setDefaultDate] = useState<Date | undefined>(undefined);

  const vendorId = useSelector(currentVendorId);

  const {
    vehicleTypeIds: selectedVehicleTypeIds,
    selectedDate: selectedDateFromStorage,
    groupIds,
  } = useMemo(() => {
    return getRoutePlannerOptions(vehicleTypes, filterPreferences, search);
  }, [filterPreferences, vehicleTypes, search]);

  useEffect(() => {
    if (!selectedDate) {
      if (selectedDateFromUrl) setSelectedDate(moment(selectedDateFromUrl, 'MM-DD-YYYY').toDate());
      else {
        const { prevPath } = state as { prevPath: string };

        if (
          prevPath &&
          selectedDateFromStorage &&
          !selectedDate &&
          [
            '/routes/service-zones',
            '/routes/holiday-planner',
            '/routes/route-planner/daily-routes',
            '/routes/route-planner/reoccurring-routes',
          ].includes(prevPath)
        ) {
          setSelectedDate(moment(selectedDateFromStorage, 'MM-DD-YYYY').toDate());
        }
      }
    }
  }, [selectedDate, selectedDateFromStorage, selectedDateFromUrl, state]);

  const onSelectDate = (date?: Date) => {
    if (selectedDate !== date) {
      setSelectedDate(date);
      if (date) {
        // adding it to the url with no reload
        window.history.pushState(
          { pathname: createUrl(pathname, search, { selectedDate: moment(date).format('MM-DD-YYYY') }) },
          '',
          createUrl(pathname, search, { selectedDate: moment(date).format('MM-DD-YYYY') }),
        );

        if (!moment(selectedDateFromStorage, 'MM-DD-YYYY').isSame(moment(date), 'day'))
          Cookies.set(ROUTE_PLANNER_SELECTED_DATE, moment(date).format('MM-DD-YYYY'));
      } else {
        Cookies.remove(ROUTE_PLANNER_SELECTED_DATE);
      }
    }
  };

  useEffect(
    () => () => {
      dispatch(resetRoutePlannerCalendarData());
      dispatch(resetRoutePlannerTableData());
    },
    [dispatch],
  );

  const openScheduleModal = (templates: { id: number; scheduledDay: number }[], defaultDate?: Date) => {
    setTemplatesToSchedule(templates);
    defaultDate && setDefaultDate(defaultDate);
    setIsScheduleModalOpen(true);
  };

  const closeScheduler = useCallback(
    (successful = false) => {
      setIsScheduleModalOpen(false);
      setTemplatesToSchedule([]);

      if (successful) {
        // force routesList refresh
        const date = selectedDate;
        setSelectedDate(undefined);
        setSelectedDate(date);

        // calendar refresh
        const beginDate = moment(calendarDate).format('MM-DD-YYYY');
        const endDate = moment(calendarDate).add(6, 'days').format('MM-DD-YYYY');
        loadRoutePlannerCalendarData({
          vendorId,
          beginDate,
          endDate,
          vehicleTypeIdsCSV: selectedVehicleTypeIds?.join(','),
        })(dispatch);
      }
    },
    [calendarDate, dispatch, selectedVehicleTypeIds, selectedDate, vendorId],
  );

  const exportHandler = (isDaily: boolean) => {
    // TODO: this export parameters should be refactored by API team because they look strange and not clear
    if (isDaily) {
      exportRoutesTracker(
        vendorId,
        '',
        selectedVehicleTypeIds.join(','),
        '',
        moment(calendarDate).format('MM-DD-YYYY'),
        moment(calendarDate).add(6, 'days').format('MM-DD-YYYY'),
        1,
        undefined,
        '',
        10000,
        '',
        '',
      )(dispatch);
    } else {
      exportRouteTemplates(vendorId, '', [], selectedVehicleTypeIds, '', 1, 10000, '', '')(dispatch);
    }
  };

  const importHandler = () => {
    setIsUploadModalOpen(true);
  };

  const openAddJobModal = (routeId?: number, vehicleTypeId?: number, date?: string) => {
    routeId && setAddJobRouteId(routeId);
    vehicleTypeId && setAddJobVehicleTypeId(vehicleTypeId);
    date && setAddJobDate(moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY'));
    setIsAddJobModalOpen(true);
  };

  const closeAddJobModal = () => {
    setIsAddJobModalOpen(false);
    setAddJobRouteId(undefined);
    setAddJobVehicleTypeId(undefined);
    setAddJobDate(undefined);
  };

  return (
    <>
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('routes.planner.routeScheduler')}</PageTitle>
              <PageSubtitle>
                <BetaBaseWrapper inline>
                  <BetaButton
                    noUnderline
                    align="right"
                    padding="no"
                    color="primary"
                    margin="no"
                    onClick={() => {
                      setRoutePlannerNewLayoutActive(false);
                      dispatch(push('/routes/route-templates'));
                    }}
                  >
                    {translate('routes.previousLayout')}
                  </BetaButton>
                </BetaBaseWrapper>
              </PageSubtitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions width="15%" flex></PageActions>
        </PageHeader>
        <PanelSectionGroup>
          <Panel padding="medium">
            <RoutePlannerCalendar
              selectedDate={selectedDate}
              calendarDate={calendarDate}
              vehicleTypeIds={selectedVehicleTypeIds}
              groupIds={groupIds}
              onSelectDate={onSelectDate}
              setCalendarDate={setCalendarDate}
              openScheduler={openScheduleModal}
              onExport={exportHandler}
              onImport={importHandler}
              openAddJobModal={openAddJobModal}
            />
          </Panel>
          <Separator color="grayLight" size={2} />
          <Panel padding="medium">
            <RoutePlannerRoutes
              date={selectedDate}
              calendarDate={calendarDate}
              vehicleTypeIds={selectedVehicleTypeIds}
              groupIds={groupIds}
              openScheduler={openScheduleModal}
              openAddJobModal={openAddJobModal}
            />
          </Panel>
        </PanelSectionGroup>
      </PageContent>

      {isScheduleModalOpen && (
        <RoutePlannerNewSchedulerModalResolver
          templates={templatesToSchedule}
          defaultDate={defaultDate}
          onClose={closeScheduler}
        />
      )}
      {isUploadModalOpen && <RouteUploaderModal vendorId={vendorId} onClose={() => setIsUploadModalOpen(false)} />}

      {isAddJobModalOpen && (
        <DispatchBoardJobEditorModalResolver
          routeId={addJobRouteId}
          vehicleTypeId={addJobVehicleTypeId}
          date={addJobDate}
          closeModal={closeAddJobModal}
        />
      )}
    </>
  );
};

export default RoutePlannerMainPage;
