import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash-es';

import { AppState } from 'src/store';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import EditWorkOrderForm from '../forms/EditWorkOrderForm';
import { Modal, UnconnectedDaysOfWeekPicker } from '../../../core/components';
import { ModalSection, PanelSection, PanelSectionSubTitle, Text } from '../../../core/components/styled';
import { PopoverDetailsContainer } from '../styled/CustomerLocations';
import { submitChangeRequest } from 'src/customers/ducks/addNewWorkOrder';
import { CHANGE_REQUEST } from 'src/customers/constants/requestTypeOptions';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: (pristine?: boolean, isReload?: boolean) => void;
  setTicketConfirmationValues: (values: any) => void;
  toggleTicketConfirmation: () => void;
  serviceHistory: any;
}

export default function EditWorkOrderModal({
  closeModal,
  setTicketConfirmationValues,
  toggleTicketConfirmation,
  serviceHistory,
}: Props) {
  const {
    containerCount,
    customerName,
    equipmentName,
    equipmentSize,
    frequencyDays,
    freqDescr,
    material,
    siteAddress,
    siteCity,
    siteState,
    svcID,
    vendID,
  } = serviceHistory;

  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const serviceRates = useSelector((state: AppState) => state.customers.rubiconServiceRates.rubiconServiceRates);
  const rubiconServiceOptions = useSelector((state: AppState) => state.customers.rubiconTypes.rubiconServicesOptions);

  const equipmentOptions = rubiconServiceOptions.equipments ? rubiconServiceOptions.equipments : [];
  const materialOptions = rubiconServiceOptions.materials ? rubiconServiceOptions.materials : [];
  const frequencyOptions = rubiconServiceOptions.frequency ? rubiconServiceOptions.frequency : [];

  const handleSubmit = (formValues: any) => {
    const {
      container,
      daysOfWeek,
      equipmentSize,
      equipmentType,
      files,
      frequencyType,
      materialType,
      notes,
      rateDescription,
      uom,
      rubiconServicesRates,
    } = formValues;

    const equipmentDescription = find(equipmentOptions, ['value', equipmentType]);
    const materialDescription = find(materialOptions, ['value', materialType]);
    const frequencyDescription = find(frequencyOptions, ['value', frequencyType]);

    const formData = {
      ServiceNumber: svcID,
      EquipmentSize: equipmentSize,
      EquipmentType: equipmentDescription?.label,
      MaterialType: materialDescription?.label,
      Frequency: frequencyDescription?.label,
      Container: container,
      Rates: rubiconServicesRates,
      RateDescription: rateDescription,
      UOM: uom,
      DayOfService: daysOfWeek?.toString().replace(/,/g, ''),
      Notes: notes,
      Files: files,
    };
    const ticketConfirmationData = {
      Customer: customerName,
      Location: `${siteAddress}, ${siteCity}, ${siteState}`,
      ServiceId: svcID,
      DatePerformed: null,
      ServiceRequestType: CHANGE_REQUEST,
      Amount: null,
      Notes: null,
      Files: null,
    };
    submitChangeRequest(
      vendorId,
      vendID,
      formData,
    )(dispatch)
      .then((response: any) => {
        setTicketConfirmationValues({ ...ticketConfirmationData, ...response });
        toggleTicketConfirmation();
        createSuccessNotification(translate('customers.rubiconService.alertMessages.success'));
        closeModal(true, true);
      })
      .catch(() => {
        createErrorNotification(translate('customers.rubiconService.alertMessages.error'));
      });
  };
  return (
    <Modal size="medium" title="Request Change to Service Details" onClose={closeModal}>
      <ModalSection margin="medium no no large" align="left">
        <PopoverDetailsContainer>
          <Text block align="center" weight="medium" size="medium" margin="no no small" singleLine>
            {translate('common.customer')}: {customerName}
          </Text>
          <Text block align="center" weight="medium" margin="no no small" singleLine>
            {translate('common.location')}: : {siteAddress}, {siteCity}, {siteState}
          </Text>
          <Text block align="center" weight="medium" size="medium" margin="no no small" singleLine>
            {translate('customers.serviceId')}: {svcID}
          </Text>
        </PopoverDetailsContainer>
      </ModalSection>
      <ModalSection margin="medium no no" align="left">
        <PopoverDetailsContainer>
          <PanelSectionSubTitle margin="no no xxSmall">
            {translate('opportunity.serviceChangeRequest.currentService')}
          </PanelSectionSubTitle>
          <PanelSection margin="no no medium" padding="no no no medium">
            <Text block weight="medium" size="small" singleLine>
              {translate('common.equipmentType')}: {equipmentName} {equipmentSize} {material}
            </Text>
          </PanelSection>
          <PanelSection margin="no no medium" padding="no no no medium">
            <Text block weight="medium" size="small" singleLine>
              {translate('common.serviceType')}: {freqDescr} {containerCount} {translate('customers.containers')}{' '}
              <UnconnectedDaysOfWeekPicker
                selectedWeekdays={frequencyDays?.split('')}
                isReadOnly
                multiple
                dayOfWeekProps={{ margin: "no xSmall no no'" }}
                daysOfWeekProps={{ margin: 'xSmall no' }}
              />
            </Text>
          </PanelSection>
          <PanelSection margin="no no medium" padding="no no no medium">
            <Text block weight="medium" size="small" minWidth="35px">
              {translate('insights.reportTypeUnits.rate')}:
            </Text>
            {serviceRates &&
              serviceRates.map(serviceRate => (
                <Text block weight="medium" size="small" padding="no xxSmall">
                  {serviceRate.rateDescr} {serviceRate.rate} {serviceRate.uOM}
                </Text>
              ))}
          </PanelSection>
        </PopoverDetailsContainer>
      </ModalSection>
      <PopoverDetailsContainer>
        <PanelSectionSubTitle margin="small no xxSmall">
          {translate('customers.rubiconService.requestChangeService')}
        </PanelSectionSubTitle>

        <ModalSection margin="no no no" align="left">
          <EditWorkOrderForm onSubmit={handleSubmit} onClose={closeModal}></EditWorkOrderForm>
        </ModalSection>
      </PopoverDetailsContainer>
    </Modal>
  );
}
