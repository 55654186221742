import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useRef } from 'react';

export const useMapDoubleClick = (
  layers: string[],
  handleDoubleClick: (lng: number, lat: number, feature?: mapboxgl.MapboxGeoJSONFeature) => void,
  map?: mapboxgl.Map,
) => {
  const handleDoubleClickRef = useRef(handleDoubleClick);

  useEffect(() => {
    handleDoubleClickRef.current = handleDoubleClick;
  }, [handleDoubleClick]);

  const handleEvent = useCallback(
    (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (!map) {
        return;
      }
      const features = map.queryRenderedFeatures(event.point, { layers });

      handleDoubleClickRef.current(event.lngLat.lng, event.lngLat.lat, features[0]);
    },
    [layers, map],
  );

  useEffect(() => {
    if (map) {
      map.on('dblclick', handleEvent);
      return () => {
        map.off('dblclick', handleEvent);
      };
    }
  }, [map, handleEvent]);
};
