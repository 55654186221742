import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const ActivitiesContainer = styled.div<{
  withBorder?: boolean;
}>`
  padding: 0;

  ${p =>
    p.withBorder &&
    css`
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-left: 3px solid ${p => p.theme.brandPrimary};
      border-bottom: 2px solid ${p => p.theme.brandPrimary};
    `}
`;
export const ActivityDetailsContainer = styled.div<{ isHighlighted?: boolean }>`
  height: 80px;
  display: flex;
  border-bottom: 1px solid ${p => p.theme.brandSecondary};
  padding: 10px 18px 20px 18px;
  background-color: ${p => transparentize(0.7)(p.theme.brandSecondary)};

  ${p =>
    p.isHighlighted &&
    css`
      background-color: ${p => transparentize(0.85)(p.theme.brandPrimary)};
      border: 1px solid ${p => p.theme.brandPrimary};
      border-radius: 5px;
    `}
`;
