import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { size, get, find } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';

import { AppState } from '../../store';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { MultiSelectProps } from '../../core/components/MultiSelect';
import { MultiSelectSeparator, TypedField } from '../../core/components';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  exceptionsForWasteAudit: any[];
  exceptionsForWasteAuditStatuses: any[];
  input: WrappedFieldInputProps;
  multiSelectProps?: Partial<MultiSelectProps>;
}

class ExceptionsForWasteAuditMultiSelect extends PureComponent<Props> {
  onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  formatText = (selectedOptions: any[]) => {
    const { exceptionsForWasteAudit, exceptionsForWasteAuditStatuses } = this.props;

    const message1 =
      size(selectedOptions) === 1
        ? get(
            find(
              exceptionsForWasteAudit,
              exception => exception.wasteAuditTypeOptionId === parseFloat(selectedOptions[0]),
            ),
            'name',
          )
        : null;

    const message2 =
      size(selectedOptions) === 1
        ? get(
            find(
              exceptionsForWasteAuditStatuses,
              exception => `status-${exception.wasteAuditLocationStatusTypeId}` === selectedOptions[0],
            ),
            'technicalName',
          )
        : null;

    return size(selectedOptions) === 1
      ? message1 || translate(createTranslationKey(message2, 'routes.wasteAuditStatuses'))
      : translate('routes.editJobStatus.xExceptionsSelected', { selected: size(selectedOptions) });
  };

  render() {
    const {
      input: { name },
      placeholder,
      label,
      multiSelectProps,
      exceptionsForWasteAudit,
      exceptionsForWasteAuditStatuses,
    } = this.props;

    const exceptionsForWasteAuditOptions = exceptionsForWasteAudit.map(exceptionForWasteAudit => ({
      label: translate(createTranslationKey(exceptionForWasteAudit.technicalName, 'routes.wasteAuditStatuses')),
      value: exceptionForWasteAudit.wasteAuditTypeOptionId,
      isDisabled: !exceptionForWasteAudit.isEnabled,
    }));

    const exceptionsForWasteAuditOptionsStatuses = exceptionsForWasteAuditStatuses.map(
      exceptionForWasteAuditStatuses => ({
        label: translate(
          createTranslationKey(exceptionForWasteAuditStatuses.technicalName, 'routes.wasteAuditStatuses'),
        ),
        value: `status-${exceptionForWasteAuditStatuses.wasteAuditLocationStatusTypeId}`,
      }),
    );

    return (
      <TypedField
        name={name}
        component={MultiSelectSeparator}
        onChange={this.onChange}
        props={{
          label: label,
          placeholder: placeholder,
          options1: exceptionsForWasteAuditOptionsStatuses,
          options2: exceptionsForWasteAuditOptions,
          formatText: this.formatText as any,
          ...multiSelectProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  exceptionsForWasteAudit: (
    state.vendors.wasteAuditConfiguration as any
  ).wasteAuditConfiguration[1].vendorWasteAuditConfigurationTypes.filter(
    (exceptionForWasteAudit: any) => exceptionForWasteAudit.isActive === true,
  ),
  exceptionsForWasteAuditStatuses: (
    state.vendors.wasteAuditConfiguration as any
  ).wasteAuditConfiguration[1].vendorWasteAuditConfigurationStatusTypes.filter(
    (exceptionForWasteAudit: any) => exceptionForWasteAudit.isActive === true,
  ),
});

export default connect(mapStateToProps)(ExceptionsForWasteAuditMultiSelect);
