import { GeoFence } from '../ducks/geoFences';

const transformCoordinates = (coordinates: any) => {
  if (coordinates.length === 0) {
    return [];
  }
  //  check if is object lat long
  if (coordinates[0].lat) {
    const newCoords = [coordinates.map((coord: any) => [coord.lng, coord.lat])];
    // check if last coord is equal to first
    if (newCoords[0][0][0] !== newCoords[0][newCoords[0].length - 1][0]) {
      newCoords[0].push(newCoords[0][0]);
    }
    return newCoords;
  }

  if (coordinates[0][0][0] !== coordinates[0][coordinates[0].length - 1][0]) {
    coordinates[0].push(coordinates[0][0]);
  }
  return coordinates;
};

const transformLoadGeoFences = (data: any) => {
  try {
    const geoFences = data.geoFences.geoFences;

    const geoFencesWithMultiPolygon = geoFences.map((geoFence: GeoFence) => {
      const parsedPolygon = JSON.parse(geoFence.geoFenceJson);
      if (geoFence.geoFenceJson && geoFence.geoFenceJson.indexOf('MultiPolygon') === -1) {
        const newGeoFence = {
          ...geoFence,
          geoFenceJson: JSON.stringify({
            ...parsedPolygon,
            geometry: {
              ...parsedPolygon.geometry,
              type: 'MultiPolygon',
              coordinates: [transformCoordinates(parsedPolygon.geometry.coordinates)],
            },
          }),
        };
        return newGeoFence;
      }

      return {
        ...geoFence,
        geoFenceJson: JSON.stringify({
          ...parsedPolygon,
          geometry: {
            ...parsedPolygon.geometry,
            coordinates: transformCoordinates(parsedPolygon.geometry.coordinates),
          },
        }),
      };
    });

    return {
      ...data,
      geoFences: {
        ...data.geoFences,
        geoFences: geoFencesWithMultiPolygon,
      },
    };
  } catch (e) {
    return data;
  }
};

export default transformLoadGeoFences;
