import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const BYOD = 1;
export const RUBICON = 2;
export const RUBICON_AND_BYOD = 3;

export const X_DEVICE_OWNERS = mapKeys(
  [
    { id: BYOD, name: translate('settings.xDeviceOwners.byod'), technicalName: 'byod' },
    { id: RUBICON, name: translate('settings.xDeviceOwners.rubiconLeasedDevice'), technicalName: 'rubiconLeasedDevice' },
  ],
  'id',
);
