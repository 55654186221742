import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ActiveVehicle } from '../interfaces/Vehicle';
import { AppState } from 'src/store';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  activeVehicles: ActiveVehicle[];
}

class ActiveVehicleDropDown extends PureComponent<Props> {
  onChange = (_: unknown, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      activeVehicles,
      dropdownProps,
    } = this.props;

    const activeVehiclesOptions = activeVehicles.map(({ name, id }) => ({
      label: name,
      value: id,
    }));

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vehicles.vehicle') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vehicles.vehicle') : undefined),
          options: activeVehiclesOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  activeVehicles: state.fleet.activeVehicles.activeVehicles,
});

export default connect(mapStateToProps)(ActiveVehicleDropDown);
