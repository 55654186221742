import { InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, GridColumn } from 'src/core/components/styled';
import { PanelSearch, TypedField } from 'src/core/components';

type RouteSequenceStopsSearchFormValues = {
  routeStopsSearchTerm: string;
};

type PropsWithoutReduxForm = {
  onSearchTermChange: (searchTerm: string) => void;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteSequenceStopsSearchFormValues, PropsWithoutReduxForm>;

export default reduxForm<RouteSequenceStopsSearchFormValues, PropsWithoutReduxForm>({
  form: 'routeStopsSearch',
  enableReinitialize: true,
})(function RouteSequenceStopsSearchForm({ onSearchTermChange }: Props) {
  const handleSearchTermChange = (_: any, searchTerm: string) => {
    onSearchTermChange(searchTerm);
  };

  return (
    <form>
      <Grid>
        <GridColumn size="9/12">
          <TypedField
            name="routeStopsSearchTerm"
            component={PanelSearch}
            onChange={handleSearchTermChange}
            props={{ padding: 'small no' }}
          />
        </GridColumn>
      </Grid>
    </form>
  );
});
