import { map } from 'lodash-es';
import moment from 'moment';

import { dateFormat } from 'src/utils/services/validator';
import { getServiceName } from './transformServiceHistory';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

export const transformLoadUpcomingServices = (upcomingServices: any[]) =>
  map(
    upcomingServices,
    ({ equipmentSize, equipmentType, routeDate, serviceType, wasteMaterialType, ...upcomingServices }) => ({
      ...upcomingServices,
      serviceName: getServiceName({
        equipmentSizeTechnicalName: equipmentSize.technicalName,
        equipmentType: equipmentType.name,
        equipmentTypeTechnicalName: equipmentType.technicalName,
        serviceType: serviceType.name,
      }),
      wasteMaterialTypeName: translate(createTranslationKey(wasteMaterialType.technicalName, 'common.wasteTypes')),
      routeDate: !!routeDate ? moment(routeDate).format(dateFormat) : undefined,
    }),
  );
