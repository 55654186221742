import React from 'react';
import { WrappedFieldProps, Validator } from 'redux-form';

import { FormError } from '../../../../core/components/styled';
import translate from '../../../../core/services/translate';

interface Props extends WrappedFieldProps {
  showErrorBeforeSubmit?: boolean;
}

const ResourceEditorFormRoleValidationField: React.FC<Props> = ({
  showErrorBeforeSubmit = false,
  meta: { submitFailed, error },
}) => {
  if ((submitFailed || showErrorBeforeSubmit) && error) {
    return <FormError>{error}</FormError>;
  }

  return null;
};

export default ResourceEditorFormRoleValidationField;

export const resourceEditorFormRoleValidate: Validator = (value, { isDriver, isSupervisor }) => {
  if (isDriver || isSupervisor) {
    return;
  }

  return translate('resources.atLeastOneRoleSelected');
};
