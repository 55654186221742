import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from '../interfaces/TechnicalType';
import { loadRequirementTypes as doLoadRequirementTypes } from '../services/requirementTypes';

// Actions
const START_LOAD = 'common/requirementTypes/START_LOAD';
const COMPLETE_LOAD = 'common/requirementTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/requirementTypes/FAIL_LOAD';

const RESET = 'common/requirementTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  requirementTypes: [] as TechnicalType[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          requirementTypes: action.requirementTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          requirementTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (requirementTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  requirementTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

// Thunks

export const loadRequirementTypes = () => async (dispatch: Dispatch) => {
  dispatch(startLoad());

  const requirementTypesPromise = doLoadRequirementTypes();

  requirementTypesPromise
    .then(requirementTypes => dispatch(completeLoad(requirementTypes)))
    .catch(() => dispatch(failLoad()));

  return requirementTypesPromise;
};
