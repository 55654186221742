import { http } from 'src/core/services/http';
import translate from 'src/core/services/translate';
import { SelectedBillsToUnassign } from 'src/finance/interfaces/BillDetails';
import { downloadExcelFile, previewPDFFile } from 'src/utils/services/downloadExcelFile';

export const loadBillDetails = (vendorId: number, billId: number) =>
  http
    .get(`statements/${billId}`, {
      params: { vendorId },
    })
    .then(response => response.data);

export const unassignBilledCharges = (
  vendorId: number,
  invoiceId: number,
  selectedBillsToUnassign: SelectedBillsToUnassign[],
) =>
  http.put(`statements/${invoiceId}/billedCharges/unassign`, {
    vendorId,
    selectedBillsToUnassign,
  });

export const releaseBill = (billId: number, confirmation?: boolean) => confirmation ? http.put(`statements/${billId}/release?confirmed=true`) : http.put(`statements/${billId}/release`);

export const previewBilledCharges = (billId: number) => previewPDFFile(`statements/${billId}/download`);

export const exportBilledCharges = (billId: number) =>
  downloadExcelFile(
    'export/billedCharges',
    `${translate('finance.billedCharges')}-${translate('finance.report')}`,
    billId,
  );

export const loadPaymentInfo = (invoiceId: string) =>
http
  .get(`statements/paymentInfo/${invoiceId}`)
  .then(response => response.data);