import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const DRIVER_SAFETY_FORM = 'driverSafetyForm';

export const DRIVER_SAFETY_VEHICLE_FORM = 'driverSafetyVehicleForm';

export const EVENT_NOTIFICATION_DELAY = 'EventNotificationDelay';

export const NONE_ID = 1;
export const VIDEO_ID = 2;
export const IMAGE_ID = 3;
export const EVENT_ONLY_ID = 4;

export const DRIVER_SAFETY_OPTIONS = mapKeys(
  [
    { id: NONE_ID, name: translate('vendors.driverSafety.dataCaptureType.none') },
    { id: VIDEO_ID, name: translate('vendors.driverSafety.dataCaptureType.video') },
    { id: IMAGE_ID, name: translate('vendors.driverSafety.dataCaptureType.image') },
    { id: EVENT_ONLY_ID, name: translate('vendors.driverSafety.dataCaptureType.eventOnly') },
  ],
  'id',
);
