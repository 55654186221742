import React, { PureComponent, Fragment } from 'react';
import Cookie from 'js-cookie';
import { connect } from 'react-redux';
import { map } from 'lodash-es';

import { AGREEMENT, OTH } from '../../constants/documentTypesOption';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { AppState } from '../../../store';
import { Districts } from 'src/vendors/interfaces/DivisionAccess';
// import DivisionForm from '../forms/DivisionForm';
import defaultDivisionFormInitialValuesSelector from '../../services/defaultDivisionFormInitialValuesSelector';
import { Button, Panel, PanelSectionGroup, Message } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentUserIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Agreements, HaulerProfileInvalidGusIdMessage } from '..';
import { loadFiles, saveFile, resetFiles } from '../../ducks';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { UploadFileModal } from '../modals';
import translate from '../../../core/services/translate';

interface ComponentProps {
  params: any;
  submitUploadFile(...args: any[]): any;
}

interface Props extends ComponentProps {
  exportFile(...args: any[]): any;
  formInitialValues: any;
  divisions: Districts[];
  files: any[];
  isLoading: boolean;
  isValidGusId: boolean;
  loadFiles(...args: any[]): any;
  resetFiles(...args: any[]): any;
  saveFile(...args: any[]): any;
  userId?: number;
  vendorGroupId?: number;
}

interface State {
  isUploadFileModalOpen: boolean;
  isFileUpload: boolean;
}

class AgreementsPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isUploadFileModalOpen: false,
      isFileUpload: true,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.params.vendorGroupId !== prevProps.params.vendorGroupId) {
      const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
      const { userId, vendorGroupId, loadFiles } = this.props;
      const params = { vendorGroupId, userId, divisionIdFromCookie };
      loadFiles({
        ...params,
        includeSubFolders: true,
      });
    }
  }

  componentWillUnmount() {
    const { resetFiles } = this.props;
    resetFiles();
  }

  loadFiles = () => {
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    const { userId, vendorGroupId, loadFiles } = this.props;
    const params = { vendorGroupId, userId, divisionIdFromCookie };
    loadFiles({
      ...params,
      includeSubFolders: true,
    });
  };

  openUploadFileModal = () => {
    this.setState({
      isUploadFileModalOpen: true,
    });
  };

  closeUploadFileModal = () => {
    this.setState({
      isUploadFileModalOpen: false,
    });
  };

  submitUploadFile = (data: any) => {
    const { loadFiles, vendorGroupId, userId, saveFile } = this.props;
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    return saveFile({
      data,
      vendorGroupId,
      userId,
      isFileUpload: data.file.length > 0 || false,
      //divisionId: divisionIdFromCookie,
    })
      .then(() => {
        this.closeUploadFileModal();
        this.setState({ isFileUpload: true });

        loadFiles({
          data,
          vendorGroupId,
          userId,
          includeSubFolders: true,
          divisionId: divisionIdFromCookie,
        });
        createSuccessNotification('Files uploaded successfully!');
      })
      .catch((error: any) => createErrorNotification(`File upload fail: ${error}`));
  };

  onDivisionChange = (divisionId: string) => {
    const { loadFiles, vendorGroupId, userId } = this.props;
    Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, { expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION });
    window.history.pushState(null, '', `?divisionId=${divisionId}`);
    loadFiles({
      vendorGroupId,
      userId,
      includeSubFolders: true,
      divisionId,
    });
  };

  render() {
    const { files, isLoading, isValidGusId, formInitialValues } = this.props;
    const { isUploadFileModalOpen } = this.state;

    const filesTableData = map(files, (file, index) => ({ ...file, rowId: index }));
    const divisionId = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE) || formInitialValues.divisionId;
    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('haulerProfile.agreements')}</PageTitle>
              {/* Hide DivisionForm for current Sprint-119 */}
              {/* <DivisionForm
                initialValues={formInitialValues}
                divisions={divisions}
                onDivisionChange={this.onDivisionChange}
              /> */}
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button color="primary" margin="no" onClick={this.openUploadFileModal} id="add-agreement-button">
              {translate('haulerProfile.addAgreement')}
            </Button>
          </PageActions>
        </PageHeader>

        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            {!isLoading &&
              (isValidGusId ? (
                <Fragment>
                  <Agreements tableData={filesTableData} />

                  {isUploadFileModalOpen && (
                    <UploadFileModal
                      closeModal={this.closeUploadFileModal}
                      documentType={OTH}
                      submitUploadFile={this.submitUploadFile}
                      uploadFileSource={AGREEMENT}
                    />
                  )}
                </Fragment>
              ) : !isValidGusId && !divisionId ? (
                <Message padding="sMedium">{translate('common.alertMessages.invalidDivisionId')}</Message>
              ) : (
                <HaulerProfileInvalidGusIdMessage />
              ))}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  files: state.haulerProfile.files.files,
  isLoading: state.haulerProfile.files.isLoading,
  isValidGusId: state.haulerProfile.files.isValidGusId,
  divisions: state.haulerProfile.divisions.divisions,
  formInitialValues: defaultDivisionFormInitialValuesSelector(state.haulerProfile.divisions),
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorGroupId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadFiles,
  resetFiles,
  saveFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementsPage);
