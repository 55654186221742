import { connect } from 'react-redux';
import { nonTechHaulerSelector } from '../ducks';
import { AppState } from '../../store';

interface Props {
  isNonTechHauler: boolean;
  children: any;
}

const NonTechHaulerGuard = ({ isNonTechHauler, children }: Props) => (isNonTechHauler ? children : null);

const mapStateToProps = (state: AppState) => ({
  isNonTechHauler: (nonTechHaulerSelector as any)(
    state.account.login,
    state.vendors.defaultVendor,
    state.account.createUserAccount,
  ),
});

export default connect(mapStateToProps)(NonTechHaulerGuard);
