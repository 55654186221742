import { capitalize, map, sum, uniqueId, upperCase } from 'lodash-es';

import { Collapsible } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import {
  ExpandableCalendarEventSection,
  ExpandableCalendarEventSectionContent,
  ExpandableCalendarEventSectionHeader,
  ExpandableCalendarEventSectionIcon,
} from 'src/routes/components/styled';
import {
  RoutePlannerCalendarStatisticSection,
  RoutePlannerCalendarStatisticSubSection,
} from 'src/routes/interfaces/routePlanner/RoutePlannerCalendarData';
import { getTranslatedCalendarSection } from 'src/routes/utils/routePlanner';

interface Props {
  title: string;
  isOpen: boolean;
  sections?: RoutePlannerCalendarStatisticSection[];
  hasTotal?: boolean;
  toggleSection: () => void;
}

const CalendarEventExpandableSection = ({ title, isOpen, sections, hasTotal, toggleSection }: Props) => {
  /// se the comments on RVP-22 where it says to kill the status for drivers and vehicles
  const allSectionsTotal =
    title === translate('routes.routes')
      ? map(sections, (section: RoutePlannerCalendarStatisticSection) =>
          hasTotal && section.name === 'Status'
            ? section.total
            : sum(map(section.counts, (subSection: RoutePlannerCalendarStatisticSubSection) => subSection.count)),
        ).join(' / ')
      : undefined;

  return (
    <ExpandableCalendarEventSection isOpen={isOpen}>
      <ExpandableCalendarEventSectionHeader onClick={toggleSection}>
        <Text size="medium" weight="medium">
          {capitalize(title)}
        </Text>
        <Box>
          {!isOpen && (
            <Text color="grayDarker" margin="no xxSmall" singleLine title={allSectionsTotal}>
              {allSectionsTotal}
            </Text>
          )}
          <ExpandableCalendarEventSectionIcon />
        </Box>
      </ExpandableCalendarEventSectionHeader>
      <ExpandableCalendarEventSectionContent>
        <Collapsible isOpen={isOpen}>
          {map(sections, (section: RoutePlannerCalendarStatisticSection) => (
            <Box display="block" key={uniqueId('subSection_')}>
              <Box display="flex" justifyContent="space-between" margin="small no no">
                <Text size="xSmall" weight="medium" color="grayDark" singleLine title={section.name}>
                  {upperCase(section.name)}
                </Text>
                {title === translate('routes.routes') && (
                  <Text size="small" weight="medium" color="grayDark">
                    {hasTotal && section.name === 'Status'
                      ? section.total
                      : sum(
                          map(
                            section.counts,
                            (subSection: RoutePlannerCalendarStatisticSubSection) => subSection.count,
                          ),
                        )}
                  </Text>
                )}
              </Box>

              {map(section.counts, row =>
                row.technicalName ? (
                  <Box key={uniqueId('row_')} display="flex" justifyContent="space-between" margin="xSmall no no">
                    <Text
                      size="xSmall"
                      margin="no xSmall no no"
                      singleLine
                      title={getTranslatedCalendarSection(row.technicalName, row.entityName)}
                    >
                      {getTranslatedCalendarSection(row.technicalName, row.entityName)}
                    </Text>
                    <Text size="xSmall" color="primary">
                      {row.count}
                    </Text>
                  </Box>
                ) : null,
              )}
            </Box>
          ))}
        </Collapsible>
      </ExpandableCalendarEventSectionContent>
    </ExpandableCalendarEventSection>
  );
};

export default CalendarEventExpandableSection;
