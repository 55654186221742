import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

const getRouteNotesFormInitialValues = (state: any) => ({
  routeNote: state.unassignedJobRouteNote,
});

const dispatchBoardRouteNotesFormInitialValuesSelector = createSelector(getRouteNotesFormInitialValues, identity);

export default dispatchBoardRouteNotesFormInitialValuesSelector;
