import React, { PureComponent } from 'react';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import moment from 'moment';

import { AppState } from '../../../store';
import { Banner } from '../../../common/components';
import confirm from '../../../core/services/confirm';
import { currentUserIdSelector, currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { EC, FC, MC } from '../../constants/changeRequestsType';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { hideNotesModal } from '../../../fleet/ducks';
import { loadServiceChangeRequests } from '../../ducks';
import { multiWordAndSearch } from '../../../core/services/search';
import { NotesState } from '../../../fleet/interfaces/RubiconDispatchesNotes';
import { PageContent, PageHeader, PageDetails, PageTitleContainer, PageTitle } from '../../../common/components/styled';
import { Panel, PanelSectionGroup, PanelSection, Text, Button } from '../../../core/components/styled';
import RubiconDispatchesNotesModalResolver from '../../../fleet/components/modal/RubiconDispatchesNotesModalResolver';
import { RubiconSelectBannerContainer } from '../../components/styled/rubiconSelect';
import { ServiceChangeRequestsFilterForm } from '../forms';
import ServiceChangeRequestsTableRow from './ServiceChangeRequestsTableRow';
import { Icon, Table } from '../../../core/components';
import { TABLE_ROW_HEIGHT_LARGEST, TEN_DAYS_BEFORE_TODAY, SEVEN_DAYS_AFTER_TODAY } from '../../../core/constants';
import translate from '../../../core/services/translate';
import { YES, NO } from '../../constants/status';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { MobileList } from 'src/core/components/MobileList';
import { MobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { ServiceChangeRequestMobileListRow } from '../ServiceChangeRequestMobileListRow';
import { changeMobilePageSecondaryContent, changeMobilePageSubTitle } from 'src/core/ducks/mobilePage';
import { ServiceChangeRequest } from 'src/opportunity/interfaces/ServiceChangeRequests';
import { ServiceChangeRequestsMobileFiltersModal } from '../modal/ServiceChangeRequestsMobileFiltersModal';

const serviceChangeRequestsTableCells = [
  {
    name: 'workOrder',
    label: translate('opportunity.serviceChangeRequest.workOrder'),
    width: '14%',
    sortable: true,
  },
  {
    name: 'currentService',
    label: translate('opportunity.serviceChangeRequest.currentService'),
    width: '15%',
    sortable: false,
  },
  {
    name: 'requestedService',
    label: translate('opportunity.serviceChangeRequest.requestedService'),
    width: '15%',
    sortable: false,
  },
  {
    name: 'customerName',
    label: translate('opportunity.serviceChangeRequest.customerInfo'),
    width: '23%',
    sortable: true,
  },
  {
    name: 'deliveryDate',
    label: translate('common.status'),
    width: '15%',
    sortable: true,
  },
  {
    name: 'actions',
    label: translate('opportunity.serviceChangeRequest.actions'),
    width: '18%',
    align: 'center',
    sortable: false,
  },
];
interface Props extends RouteComponentProps {
  userId: string;
  vendorId: number;
  match: any;
  divisions: any[];
  equipmentOptions: any[];
  hideNotesModal: DuckAction<typeof hideNotesModal>;
  isLoading: boolean;
  location: any;
  materialTypes: any[];
  notificationsCount: any;
  notesModal: NotesState['modal'];
  serviceChangeRequests: ServiceChangeRequest[];
  loadServiceChangeRequests: DuckFunction<typeof loadServiceChangeRequests>;
  push: typeof push;
  reset: typeof reset;
  changeMobilePageSecondaryContent: DuckFunction<typeof changeMobilePageSecondaryContent>;
  changeMobilePageSubTitle: DuckFunction<typeof changeMobilePageSubTitle>;
}

interface State {
  actionableRequestsCount: number;
  serviceChangeRequests: any[];
  fromDate: string;
  toDate: string;
  searchTerm: string;
  divisionIds: string[];
  equipmentTypeIds: string[];
  materialTypeIds: string[];
  prevEndDate: string;
  prevStartDate: string;
  requestTypeIds: number[];
  isMobileFilterModalOpen: boolean;
}

class ServiceChangeRequestsPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      actionableRequestsCount: 0,
      serviceChangeRequests: props.serviceChangeRequests,
      fromDate: '',
      toDate: '',
      divisionIds: [],
      equipmentTypeIds: [],
      materialTypeIds: [],
      prevEndDate: '',
      prevStartDate: '',
      requestTypeIds: [],
      searchTerm: '',
      isMobileFilterModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      location: { search },
      serviceChangeRequests,
    } = this.props;

    const { showActionRequired } = getQueryParams(search);
    if (showActionRequired === YES) this.setState({ actionableRequestsCount: size(serviceChangeRequests) });
    this.props.changeMobilePageSecondaryContent(this.getMobilePageFilter());
    this.updateMobilePageSubtitle();
  }

  getMobilePageFilter = () => (
    <Button margin="no small" onClick={() => this.setState({ isMobileFilterModalOpen: true })} text>
      <Icon width="24px" icon="filter" />
    </Button>
  );

  updateMobilePageSubtitle = () => {
    this.props.changeMobilePageSubTitle(
      <>
        <Text weight="normal" margin="no xxSmall" singleLine size="small">
          {translate('common.total')}: {this.props.serviceChangeRequests.length}
        </Text>
        <Text weight="normal" margin="no xxSmall" singleLine size="small" color="alert">
          {translate('common.overdue')}: {this.props.serviceChangeRequests.filter(d => !!d.isActionRequired).length}
        </Text>
      </>,
    );
  };

  componentDidUpdate(prevProps: Props) {
    const { location, userId, vendorId } = prevProps;
    const {
      loadServiceChangeRequests,
      serviceChangeRequests,
      location: { search },
      match,
    } = this.props;
    const { searchTerm, requestTypeIds, divisionIds, equipmentTypeIds, materialTypeIds } = this.state;
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    if (serviceChangeRequests !== prevProps.serviceChangeRequests) {
      this.filterServiceChangeRequests(
        startDate,
        endDate,
        searchTerm,
        requestTypeIds,
        equipmentTypeIds,
        materialTypeIds,
        divisionIds,
      );
    }
    if (search !== location.search) {
      loadServiceChangeRequests(userId, match.params.token, vendorId, startDate, endDate, isActionRequired).then(() => {
        this.filterServiceChangeRequests(
          startDate,
          endDate,
          searchTerm,
          requestTypeIds,
          equipmentTypeIds,
          materialTypeIds,
          divisionIds,
        );
      });
    }
    this.updateMobilePageSubtitle();
  }

  componentWillUnmount() {
    this.props.changeMobilePageSubTitle();
    this.props.changeMobilePageSubTitle();
  }

  onActionRequiredToggleChange = (event: any, isActionRequired: boolean) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    const { startDate, endDate } = getQueryParams(search);
    let currentStartDate;
    let currentEndDate;
    if (isActionRequired) {
      this.setState({ prevStartDate: startDate, prevEndDate: endDate });
      currentStartDate = moment().subtract(60, 'days').format('MM/DD/YYYY');
      currentEndDate = moment().add(30, 'days').format('MM/DD/YYYY');
    } else {
      const { prevStartDate, prevEndDate } = this.state;
      currentStartDate = !prevStartDate ? TEN_DAYS_BEFORE_TODAY : prevStartDate;
      currentEndDate = !prevEndDate ? SEVEN_DAYS_AFTER_TODAY : prevEndDate;
    }
    push(
      createUrl(pathname, search, {
        showActionRequired: isActionRequired ? YES : NO,
        startDate: currentStartDate,
        endDate: currentEndDate,
      }),
    );
  };

  onDateChange = (event: any, date: any) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    const { from, to } = event;
    push(createUrl(pathname, search, { startDate: from, endDate: to }));
  };

  onSearchChange = (event: any, searchTerm: string) => {
    const { fromDate, toDate, requestTypeIds, equipmentTypeIds, materialTypeIds, divisionIds } = this.state;
    this.filterServiceChangeRequests(
      fromDate,
      toDate,
      searchTerm,
      requestTypeIds,
      equipmentTypeIds,
      materialTypeIds,
      divisionIds,
    );
  };

  onRequestTypeChange = (event: any, requestTypeIds: number[]) => {
    const { fromDate, toDate, searchTerm, equipmentTypeIds, materialTypeIds, divisionIds } = this.state;
    this.filterServiceChangeRequests(
      fromDate,
      toDate,
      searchTerm,
      requestTypeIds,
      equipmentTypeIds,
      materialTypeIds,
      divisionIds,
    );
  };

  onEquipmentChange = (event: any, equipmentTypeIds: string[]) => {
    const { fromDate, toDate, searchTerm, requestTypeIds, materialTypeIds, divisionIds } = this.state;
    this.filterServiceChangeRequests(
      fromDate,
      toDate,
      searchTerm,
      requestTypeIds,
      equipmentTypeIds,
      materialTypeIds,
      divisionIds,
    );
  };

  onMaterialTypeChange = (event: any, materialTypeIds: string[]) => {
    const { fromDate, toDate, searchTerm, equipmentTypeIds, requestTypeIds, divisionIds } = this.state;
    this.filterServiceChangeRequests(
      fromDate,
      toDate,
      searchTerm,
      requestTypeIds,
      equipmentTypeIds,
      materialTypeIds,
      divisionIds,
    );
  };

  onDivisionChange = (event: any, divisionIds: string[]) => {
    const { fromDate, toDate, searchTerm, equipmentTypeIds, requestTypeIds, materialTypeIds } = this.state;
    this.filterServiceChangeRequests(
      fromDate,
      toDate,
      searchTerm,
      requestTypeIds,
      equipmentTypeIds,
      materialTypeIds,
      divisionIds,
    );
  };

  filterServiceChangeRequests = (
    fromDate: string,
    toDate: string,
    searchTerm: string,
    requestTypeIds: number[],
    equipmentTypeIds: string[],
    materialTypeIds: string[],
    divisionIds: string[],
  ) => {
    const { serviceChangeRequests } = this.props;
    const startDate = fromDate && moment(fromDate).format('MM/DD/YYYY');
    const endDate = toDate && moment(toDate).format('MM/DD/YYYY');
    const selectedRequestTypeIds = requestTypeIds && requestTypeIds.map(Number);

    const filteredChangeRequests =
      serviceChangeRequests &&
      serviceChangeRequests.filter(
        serviceChangeRequest =>
          (!size(selectedRequestTypeIds) ||
            (selectedRequestTypeIds.includes(EC) && serviceChangeRequest.equipmentChanged) ||
            (selectedRequestTypeIds.includes(FC) && serviceChangeRequest.frequencyChanged) ||
            (selectedRequestTypeIds.includes(MC) && serviceChangeRequest.materialChanged)) &&
          (!size(equipmentTypeIds) || equipmentTypeIds.includes(serviceChangeRequest.equipmentSubType)) &&
          (!size(materialTypeIds) || materialTypeIds.includes(serviceChangeRequest.materialId)) &&
          (!size(divisionIds) || divisionIds.includes(serviceChangeRequest.vendorId)) &&
          ((!fromDate && !toDate) ||
            (fromDate &&
              moment(serviceChangeRequest.offer.deliveryDate).isAfter(moment(fromDate)) &&
              toDate &&
              moment(serviceChangeRequest.offer.deliveryDate).isBefore(moment(toDate)))) &&
          (!searchTerm ||
            multiWordAndSearch(serviceChangeRequest.customerName, searchTerm) ||
            multiWordAndSearch(serviceChangeRequest.customerAddress, searchTerm) ||
            multiWordAndSearch(serviceChangeRequest.siteName, searchTerm) ||
            multiWordAndSearch(serviceChangeRequest.workOrder, searchTerm)),
      );

    this.setState({
      searchTerm,
      fromDate: startDate,
      toDate: endDate,
      divisionIds,
      equipmentTypeIds,
      materialTypeIds,
      requestTypeIds,
      serviceChangeRequests: filteredChangeRequests,
    });
  };

  closeNotesModal = async (isReload: boolean, formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    const { reset, hideNotesModal } = this.props;
    reset('notesForm');
    hideNotesModal();
  };

  render() {
    const { divisions, equipmentOptions, isLoading, materialTypes, notificationsCount, notesModal } = this.props;
    const { serviceChangeRequests, isMobileFilterModalOpen } = this.state;
    const virtualizedProps = {
      height: !!serviceChangeRequests
        ? Math.min(serviceChangeRequests.length * (TABLE_ROW_HEIGHT_LARGEST + 15), (TABLE_ROW_HEIGHT_LARGEST + 15) * 8)
        : 1,
      itemSize: TABLE_ROW_HEIGHT_LARGEST,
    };
    const totalActionRequiredCount = (!!notificationsCount && notificationsCount.serviceChangeRequests) || 0;

    return (
      <>
        <RubiconSelectBannerContainer>
          <Banner />
        </RubiconSelectBannerContainer>
        <PageContent>
          <DesktopWidthView>
            <PageHeader>
              <PageDetails>
                <PageTitleContainer>
                  <PageTitle>{translate('opportunity.serviceChangeRequests')}</PageTitle>
                </PageTitleContainer>
              </PageDetails>
            </PageHeader>
          </DesktopWidthView>
          <Panel>
            <PanelSectionGroup isLoading={isLoading}>
              <DesktopWidthView>
                <ServiceChangeRequestsFilterForm
                  divisionOptions={divisions}
                  equipmentOptions={equipmentOptions}
                  materialTypes={materialTypes}
                  totalActionRequiredCount={totalActionRequiredCount}
                  onActionRequiredToggleChange={this.onActionRequiredToggleChange}
                  onDateChange={this.onDateChange}
                  onDivisionChange={this.onDivisionChange}
                  onEquipmentChange={this.onEquipmentChange}
                  onMaterialTypeChange={this.onMaterialTypeChange}
                  onRequestTypeChange={this.onRequestTypeChange}
                  onSearchChange={this.onSearchChange}
                />
              </DesktopWidthView>
              <DesktopWidthView>
                <PanelSection>
                  <Table
                    cells={serviceChangeRequestsTableCells}
                    rows={serviceChangeRequests}
                    rowComponent={ServiceChangeRequestsTableRow}
                    virtualized
                    scrollMarker
                    noOverflow
                    virtualizedProps={virtualizedProps}
                  />
                </PanelSection>
              </DesktopWidthView>
              <MobileWidthView>
                <MobileList
                  onSearch={s => this.onSearchChange(null, s)}
                  rows={serviceChangeRequests}
                  rowComponent={ServiceChangeRequestMobileListRow}
                  detailsComponent={ServiceChangeRequestsTableRow}
                  detailsComponentProps={{ showMobileDetails: true }}
                />
              </MobileWidthView>
              <PanelSection>
                {!size(serviceChangeRequests) && (
                  <Text margin="small">
                    {translate('opportunity.serviceChangeRequest.noServiceChangeRequestsRecord')}
                  </Text>
                )}
              </PanelSection>
            </PanelSectionGroup>
          </Panel>
          {!!notesModal && <RubiconDispatchesNotesModalResolver onCancel={this.closeNotesModal} />}
          <MobileWidthView>
            <ServiceChangeRequestsMobileFiltersModal
              hidden={!isMobileFilterModalOpen}
              divisionOptions={divisions}
              equipmentOptions={equipmentOptions}
              materialTypes={materialTypes}
              totalActionRequiredCount={totalActionRequiredCount}
              onActionRequiredToggleChange={this.onActionRequiredToggleChange}
              onDateChange={this.onDateChange}
              onDivisionChange={this.onDivisionChange}
              onEquipmentChange={this.onEquipmentChange}
              onMaterialTypeChange={this.onMaterialTypeChange}
              onRequestTypeChange={this.onRequestTypeChange}
              onClose={() => this.setState({ isMobileFilterModalOpen: false })}
            />
          </MobileWidthView>
        </PageContent>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  divisions: state.opportunity.serviceChangeRequest.divisions,
  equipmentOptions: state.opportunity.serviceChangeRequest.equipments,
  isLoading: state.opportunity.serviceChangeRequest.isLoading,
  materialTypes: state.opportunity.serviceChangeRequest.materials,
  notificationsCount: state.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts,
  notesModal: state.fleet.notes.modal,
  serviceChangeRequests: state.opportunity.serviceChangeRequest.serviceChangeRequests,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  hideNotesModal,
  loadServiceChangeRequests,
  push,
  reset,
  changeMobilePageSecondaryContent,
  changeMobilePageSubTitle,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceChangeRequestsPage));
