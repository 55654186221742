import { PICKUP_STATUSES } from 'src/common/constants';
import { ActionButtonTooltip, Checkbox, TypedField } from 'src/core/components';
import { Label, TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TABLE_ROW_HEIGHT_LARGER } from 'src/core/constants';
import { RouteStopItem } from 'src/dashboard/interfaces/routesData';
import JobPriorityTypeIndicator from 'src/routes/components/pages/common/JobPriorityTypeIndicator';
import { StatusContainer } from 'src/routes/components/styled';
import { PICKUP_STATUS_LABEL_COLORS, ROUTE_PICKUP_TYPE_IDS } from 'src/routes/constants';
import { dateAndTime } from 'src/utils/services/formatter';

const DashboardMapboxRouteStopTableRow = ({
  assistingVehicleRegplate,
  orderNo,
  customerName,
  locationName,
  streetNumber,
  street,
  pickupStatusTypeId,
  pickupTypeId,
  statusDate,
  jobPriorityTypeId,
  id,
}: RouteStopItem) => {
  const address = `${streetNumber} ${street}`;

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGER}>
      <TableCell vertical padding="defaultCellVertical no" align="center" horizontalAlign="right" width="5%">
        <TypedField
          name={`routeStopsFilters.stops._${id}`}
          component={Checkbox}
          props={{
            size: 'small',
            margin: 'xxSmall no no',
          }}
        />
      </TableCell>
      <TableCell padding="no xxSmall no" width="13%" align="center">
        <Text size="small" weight="normal" align="center" block singleLine>
          {orderNo < 0 ? '' : orderNo}
        </Text>
      </TableCell>
      <TableCell padding="no xxSmall" width="42%" vertical align="center">
        <Text size="small" weight="medium" title={customerName} block singleLine>
          {customerName}
        </Text>
        <Text size="small" weight="normal" title={locationName} block singleLine>
          {locationName}
        </Text>
        <Text size="small" weight="light" title={address} block singleLine>
          {address}
        </Text>
      </TableCell>

      <TableCell padding="no xSmall no no" width="40%" vertical align="center" horizontalAlign="right">
        <Text size="small" block weight="medium" margin="no no xxSmall" align="right">
          {ROUTE_PICKUP_TYPE_IDS[pickupTypeId]?.name}
        </Text>
        <StatusContainer>
          {assistingVehicleRegplate && (
            <TableActionButton margin="no xxSmall no no">
              <ActionButtonTooltip
                icon="infoFill"
                tooltipProps={{ vehicleName: assistingVehicleRegplate }}
                tooltip="assistedBy"
              />
            </TableActionButton>
          )}
          <Box display="flex" alignItems="center">
            <Label fontSize="small" color={PICKUP_STATUS_LABEL_COLORS[pickupStatusTypeId]}>
              {PICKUP_STATUSES[pickupStatusTypeId].name}
            </Label>
            {jobPriorityTypeId && (
              <Box ml={5}>
                <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={jobPriorityTypeId} />
              </Box>
            )}
          </Box>
        </StatusContainer>
        {statusDate && (
          <Text block size="small" margin="xxSmall no no" align="right">
            {dateAndTime(statusDate)}
          </Text>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DashboardMapboxRouteStopTableRow;
