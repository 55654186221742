import { find } from 'lodash-es';
import { FC } from 'react';
import { ActionButtonTooltip } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface JobPriorityTypeIndicatorProps {
  jobPriorityTypeId?: number;
  iconSize?: string;
}

const JobPriorityTypeIndicator: FC<JobPriorityTypeIndicatorProps> = ({ jobPriorityTypeId, iconSize }) => {
  const jobPriorityTypes = useSelector(state => state.common.jobPriorityTypes.jobPriorityTypes);

  const jobPriorityType = find(jobPriorityTypes, jobPriorityType => jobPriorityType.id === jobPriorityTypeId);

  if (!jobPriorityType) {
    return null;
  }

  return (
    <ActionButtonTooltip
      icon={`gauge${jobPriorityType?.technicalName}`}
      tooltipAsString
      iconSize={iconSize}
      tooltip={`${translate('common.jobPriority')}: ${jobPriorityType?.name}`}
    />
  );
};

export default JobPriorityTypeIndicator;
