import { useDispatch, useSelector } from 'react-redux';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { loadContainersForReassignment } from 'src/fleet/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import ContainersRelocationModal from './ContainersRelocationModal';

interface Props {
  closeModal: () => void;
  onSubmit: (values: any) => void;
  facilityId: number;
  isDelete?: boolean;
}

const ContainersRelocationModalResolver = ({ facilityId, isDelete, closeModal, onSubmit }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [loadContainersForReassignment(facilityId)(dispatch)];

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={ContainersRelocationModal}
      successProps={{ closeModal, onSubmit, currentFacilityId: facilityId, isDelete }}
      loadingComponent={PageLoadingOverlay}
      onError={closeModal}
      resolve={loadDependencies}
    />
  );
};

export default ContainersRelocationModalResolver;
