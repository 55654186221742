import { find } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import {
  calculateIsDriverExperienceSimple,
  ROUTE_SEGMENT_LANE_COLORS,
} from 'src/customers/components/pages/streetNetwork/utils';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getMapBounds } from 'src/common/components/map/util';
import { getSegmentCenter, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { Grid, GridColumn, TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { loadStreetNetworkSegmentDetails, setStreetNetworkMapViewport } from 'src/customers/ducks';
import { PassesStatusComplex, StatusContainer } from '../../styled';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props extends StreetNetwork {
  deleteSegment: (id: number, routeStopId: number) => void;
  editSegment: (id: number) => void;
  isSnowPlowRoute: boolean;
  rowHeight?: number;
  selectedSegments?: number[];
  selectSegment: (id: number) => void;
}

type ComponentProps = Props & RouteSegment;

export default function SnowOrSweeperRouteTemplateTableRow({
  deleteSegment,
  id,
  isActive,
  isOneWay,
  isSelected,
  isSnowPlowRoute,
  lineSegment,
  name,
  routeStopId,
  selectedSegments,
  selectSegment,
  streetId,
  streetSegmentActivitySettings,
}: ComponentProps) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { isEditSegmentsModeOn } = useSelector(state => state.customers.streetNetwork);

  const status = isActive ? translate('common.active') : translate('common.inactive');

  const forwardPasses = streetSegmentActivitySettings[0].forwardPasses;
  const reversePasses = streetSegmentActivitySettings[0].reversePasses;
  const noOfPasses = reversePasses + forwardPasses || 0;

  const isDriverExperienceSimple = calculateIsDriverExperienceSimple(isSnowPlowRoute);

  const direction = isOneWay
    ? translate('customers.streetNetwork.oneWay')
    : translate('customers.streetNetwork.twoWay');

  const handleClick = (event: any) => {
    const isTableRowClicked = event ? event.target.parentNode.tagName === 'DIV' : false;

    if (isTableRowClicked) {
      if (!lineSegment) return;
      const parsedSegment = parseSegmentJSON(lineSegment);
      const segmentCenter = getSegmentCenter(parsedSegment);

      const point = { longitude: segmentCenter[0], latitude: segmentCenter[1] };
      const bounds = getMapBounds([point], {
        padding: 100,
        capZoom: 16,
      });

      dispatch(setStreetNetworkMapViewport(bounds));

      const vehicleTypeId = isSnowPlowRoute ? SNOW_PLOW_ID : STREET_SWEEPER_ID;
      loadStreetNetworkSegmentDetails(vendorId, Number(id), undefined, vehicleTypeId)(dispatch);
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeSegments, Number(id), { noTableScroll: true }));
    }
  };

  return (
    <>
      <TableRow
        onClick={handleClick}
        height={TABLE_ROW_HEIGHT_LARGE}
        isHighlighted={!!id && (!!find(selectedSegments, s => s === id) || selectedFeature?.id === id)}
      >
        {isEditSegmentsModeOn && (
          <TableCell width="3%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
            {!checkIfViewOnly() && !checkIfSupport() && (
              <UnconnectedCheckbox
                block
                checked={isSelected}
                onChange={e => selectSegment(id)}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            )}
          </TableCell>
        )}
        <TableCell width={`${isEditSegmentsModeOn ? '25%' : '43%'}`}>
          <Grid>
            <GridColumn width="100%" padding="no" multiLine>
              <Text block singleLine>
                {name}
              </Text>
              {streetId && (
                <Text block singleLine>
                  {`${translate('routes.snowPlow.streetId')}: ${streetId}`}
                </Text>
              )}
            </GridColumn>
          </Grid>
        </TableCell>
        <TableCell width="19%">{status}</TableCell>
        <TableCell width={`${isEditSegmentsModeOn ? '24%' : '19%'}`}>
          <StatusContainer vertical>
            <PassesStatusComplex
              forwardColor={!!forwardPasses ? ROUTE_SEGMENT_LANE_COLORS.blue : undefined}
              reverseColor={
                (!isDriverExperienceSimple && !!reversePasses) ||
                (!!reversePasses && isDriverExperienceSimple && !forwardPasses)
                  ? ROUTE_SEGMENT_LANE_COLORS.blue
                  : undefined
              }
              isArrowRightAligned={!!forwardPasses}
              isArrowLeftAligned={
                (!isDriverExperienceSimple && !!reversePasses) ||
                (!!reversePasses && isDriverExperienceSimple && !forwardPasses)
              }
            ></PassesStatusComplex>
            <Text margin="no no no small">{noOfPasses}</Text>
          </StatusContainer>
        </TableCell>
        <TableCell width="15%">{direction}</TableCell>

        {isEditSegmentsModeOn && (
          <TableCell width="12%" padding="defaultCellVertical xSmall" align="right">
            <TableActionButton onClick={() => deleteSegment(id, routeStopId)} margin="no no no small">
              <ActionButtonTooltip size="sMedium" icon="delete" tooltip="deleteSegment" />
            </TableActionButton>
          </TableCell>
        )}
      </TableRow>
    </>
  );
}
