import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch } from 'react-redux';

import { PreventativeMaintenanceConfiguration } from '../../interfaces/PreventativeMaintenance';
import PreventativeMaintenanceFormPiece from './PreventativeMaintenanceFormPiece';
import { PM_CONFIGURATION_FORM } from '../../constants/preventativeMaintenance';

const pmTypes: (keyof PreventativeMaintenanceConfiguration)[] = [
  'mileageThresholdConfiguration',
  'engineHoursThresholdConfiguration',
  'dateThresholdConfiguration',
];

interface PropsWithoutReduxForm {
  onlyAvailableConfiguration?: keyof PreventativeMaintenanceConfiguration;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<PreventativeMaintenanceConfiguration, PropsWithoutReduxForm>;

const PreventativeMaintenanceForm: React.FC<Props> = ({ onlyAvailableConfiguration, form, handleSubmit, change }) => {
  const dispatch = useDispatch();
  const onConfigurationStatusChange = (configurationIndex: number) => (status: boolean) => {
    if (!status) {
      return;
    }

    const pmTypesWithoutCurrentOne = pmTypes.slice();

    pmTypesWithoutCurrentOne.splice(configurationIndex, 1);

    pmTypesWithoutCurrentOne.forEach(pmType => {
      dispatch(change(`${pmType}.isActive`, false));
    });
  };

  return (
    <form onSubmit={handleSubmit} name={form}>
      {pmTypes.map((pieceKey, index) => (
        <PreventativeMaintenanceFormPiece
          key={pieceKey}
          pieceKey={pieceKey}
          form={form}
          isUnavailable={!!onlyAvailableConfiguration && onlyAvailableConfiguration !== pieceKey}
          onSwitch={onConfigurationStatusChange(index)}
        />
      ))}
    </form>
  );
};

export default reduxForm<PreventativeMaintenanceConfiguration, PropsWithoutReduxForm>({
  form: PM_CONFIGURATION_FORM,
})(PreventativeMaintenanceForm);
