import { MouseEvent, PureComponent } from 'react';

import { push } from 'connected-react-router';
import { debounce, filter, get, head, indexOf, map, size } from 'lodash-es';
import moment from 'moment';
import { Resizable } from 're-resizable';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { SortOrder, getOrderedRows } from 'src/core/components/Table';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { RouteMapFeature, setRouteMapViewport } from 'src/routes/ducks/mapControls';
import { AppState } from 'src/store';
import { PermissionGuard } from '../../../../account/components';
import {
  ROUTES_PLANNER_DETAILS_EDIT,
  ROUTES_PLANNER_DETAILS_EXPORT,
  ROUTES_TRACKER_SEQUENCE_ROUTE,
} from '../../../../account/constants';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { DuckAction, DuckFunction } from '../../../../contracts/ducks';
import { MapDragHandle, PopoverWrapper, Table } from '../../../../core/components';
import {
  Button,
  ButtonLink,
  Link,
  MapContainer,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Popover,
  Text,
} from '../../../../core/components/styled';
import { TABLE_ROW_HEIGHT_SMALL } from '../../../../core/constants';
import { removeLastLocation } from '../../../../core/ducks';
import confirm from '../../../../core/services/confirm';
import { createErrorNotification, createWarningNotification } from '../../../../core/services/createNotification';
import { multiWordAndSearch } from '../../../../core/services/search';
import translate from '../../../../core/services/translate';
import {
  DELIVERY_UTILITY,
  DELIVERY_UTILITY_ID,
  FRONT_LOAD,
  RESIDENTIAL,
  ROLL_OFF,
  STREET_SWEEPER_ID,
  TOTER,
  WASTE_AUDIT_ID,
} from '../../../../fleet/constants';
import { resetVehicleTypesForVendor, technicalNameByVehicleTypeIdSelector } from '../../../../fleet/ducks';
import { loadRouteSequenceSettings, routeSequencingStatusSelector } from '../../../../vendors/ducks';
import { SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE } from '../../../constants';
import {
  exportRouteTemplate,
  resetGeoFences,
  resetRouteTemplate,
  routeSequence,
  setRouteMapSelectedFeature,
} from '../../../ducks';
import getRouteSequenceAdditionalConfigurations from '../../../services/routeSequenceAdditionalConfigurations';
import { RouteLocationsLoader } from '../../RouteLocationsLoader';
import { SelectedTemplateLocation } from '../../RouteMap';
import { RouteTemplateLocationsForm } from '../../forms';
import { ServiceSideModalResolver } from '../../modals';
import { GoogleMapLoading } from '../../styled';
import { EditPickupLocationsContainer } from '../../styled/RouteTemplates';
import RouteMapGL from '../common/routeMapGL/RouteMapGL';
import RouteTemplateLocationsTableRow from './RouteTemplateLocationsTableRow';
import { RouteTemplateSummary } from './routeTemplatePageSections';
import { TravelPathStatusDetails } from 'src/routes/interfaces/TravelPath';

interface Props extends RouteComponentProps {
  exportRouteTemplate: DuckFunction<typeof exportRouteTemplate>;
  history: any;
  isSnowPlowFeatureEnabled: boolean;
  lastLocations: any;
  loadRouteSequenceSettings: DuckFunction<typeof loadRouteSequenceSettings>;
  push: typeof push;
  removeLastLocation: DuckAction<typeof removeLastLocation>;
  resetRouteTemplate: DuckAction<typeof resetRouteTemplate>;
  resetVehicleTypesForVendor: DuckAction<typeof resetVehicleTypesForVendor>;
  routeSequence: DuckFunction<typeof routeSequence>;
  resetGeoFences: DuckAction<typeof resetGeoFences>;
  setRouteMapSelectedFeature: DuckAction<typeof setRouteMapSelectedFeature>;
  setRouteMapViewport: DuckAction<typeof setRouteMapViewport>;
  routeSequenceHistory?: any;
  routeSequencingEnabled?: boolean;
  routeTemplate: any;
  userName: string;
  vehicleTypeId: number;
  isSnowPlowRoute: boolean;
  vehicleTypeTechnicalName?: string;
  travelPathStatusDetails: TravelPathStatusDetails | null;
}

interface State {
  isMapLoading: boolean;
  isServiceSideModalOpen: boolean;
  routeLocations: any[];
  routeSequenceButtonVisible?: boolean;
  routeSequencingEnabledSpecificForVehicle?: boolean;
  routeTemplateLocationsTableRows: any[];
  searchTerm?: string;
  selectedTemplateLocation?: SelectedTemplateLocation;
}

const initialState = {
  isMapLoading: false,
  isServiceSideModalOpen: false,
  routeLocations: [],
  routeTemplateLocationsTableRows: [],
  routeSequenceButtonVisible: undefined,
  routeSequencingEnabledSpecificForVehicle: undefined,
  searchTerm: undefined,
  selectedTemplateLocation: undefined,
};

class RouteTemplatePage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...initialState,
      routeLocations: props.routeTemplate.routeTemplate.routeLocations || [],
      routeTemplateLocationsTableRows: this.getRouteTemplateLocationsTableRows(
        props.routeTemplate.routeTemplate.routeLocations || [],
      ),
    };
  }

  componentDidMount() {
    this.enableRouteSequencingButton();
  }

  enableRouteSequencingButton() {
    const {
      loadRouteSequenceSettings,
      routeSequencingEnabled,
      routeTemplate: {
        routeTemplate: { vendorId },
      },
      vehicleTypeId,
      vehicleTypeTechnicalName,
    } = this.props;

    this.setState(
      {
        routeSequenceButtonVisible:
          indexOf([DELIVERY_UTILITY, FRONT_LOAD, RESIDENTIAL, ROLL_OFF, TOTER], vehicleTypeTechnicalName) > -1,
      },
      () => {
        if (routeSequencingEnabled && this.state.routeSequenceButtonVisible) {
          loadRouteSequenceSettings(vendorId).then(response => {
            const responseFiltered = response.filter(
              routeSequenceSetting => routeSequenceSetting.vehicleTypeId === vehicleTypeId,
            );
            this.setState({
              routeSequencingEnabledSpecificForVehicle:
                responseFiltered.length > 0 ? responseFiltered[0].isActive : false,
            });
          });
        }
      },
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      routeTemplate: {
        routeTemplate: { routeLocations },
      },
      vehicleTypeTechnicalName,
    } = this.props;
    const { searchTerm } = this.state;

    if (routeLocations.length && this.state.routeLocations !== prevState.routeLocations) {
      this.setState({
        routeTemplateLocationsTableRows: this.getRouteTemplateLocationsTableRows(this.state.routeLocations),
      });
    }

    if (
      // this is needed for an edge case when the page is refreshed before the
      // routeLocations had the chance to be added to the state
      (routeLocations.length && !this.state.routeLocations.length && !searchTerm) ||
      prevProps.routeTemplate.routeTemplate.routeLocations !== routeLocations
    ) {
      if (searchTerm) {
        this.filterLocations(searchTerm);
      } else {
        this.setState({ routeLocations });
      }
    }
    if (vehicleTypeTechnicalName && vehicleTypeTechnicalName !== prevProps.vehicleTypeTechnicalName) {
      this.enableRouteSequencingButton();
    }
  }

  componentWillUnmount() {
    const { resetRouteTemplate, resetVehicleTypesForVendor } = this.props;
    resetRouteTemplate();
    resetVehicleTypesForVendor();
    resetGeoFences();
    this.setState({ ...initialState });
  }

  getRouteTemplateLocationsTableRows = (routeTemplateLocationsTableRows: any[]) => {
    return map(routeTemplateLocationsTableRows, ({ location, ...routeLocation }) => {
      return {
        ...routeLocation,
        location,
        streetName: location.address.line1,
      };
    });
  };

  onSearchTermChange = debounce(searchTerm => {
    // set isMapLoading to true only if there are still locations
    // to avoid having a constantly loading map
    this.setState(({ routeLocations, isMapLoading }) => ({
      isMapLoading: routeLocations.length ? true : isMapLoading,
    }));
    this.filterLocations(searchTerm);
  }, 200);

  onMapLoadComplete = debounce(() => {
    this.setState({ isMapLoading: false });
  }, 200);

  onServiceSideFormSubmit = (formData: any) => {
    this.startRouteTemplateSequence(formData);
  };

  exportRouteTemplate = () => {
    const {
      exportRouteTemplate,
      routeTemplate: {
        routeTemplate: { id },
      },
    } = this.props;
    exportRouteTemplate(id);
  };

  closeServiceSideModal = () => {
    this.setState({ isServiceSideModalOpen: false });
  };

  filterLocations = (searchTerm: string) => {
    const {
      routeTemplate: {
        routeTemplate: { routeLocations },
      },
    } = this.props;

    const filteredRouteTemplateLocations = filter(
      routeLocations,
      routeTemplateLocation =>
        !searchTerm ||
        multiWordAndSearch(routeTemplateLocation.customer.name, searchTerm) ||
        multiWordAndSearch(routeTemplateLocation.location.name, searchTerm) ||
        multiWordAndSearch(routeTemplateLocation.location.address.line1, searchTerm) ||
        multiWordAndSearch(routeTemplateLocation.customer.accountNumber, searchTerm) ||
        multiWordAndSearch(routeTemplateLocation.location.vendorAccountNo, searchTerm),
    );

    this.setState({
      searchTerm,
      routeLocations: filteredRouteTemplateLocations || [],
    });
  };

  handleBackAction = () => {
    const {
      history: { length, goBack },
      lastLocations,
      push,
      removeLastLocation,
      routeTemplate: {
        routeTemplate: { id },
      },
    } = this.props;

    const createRoute = get(lastLocations, '/routes/route-templates/create');
    if (createRoute) {
      removeLastLocation(createRoute);
    }

    const sequenceRoute = get(lastLocations, `/routes/route-templates/${id}/route-sequence`);
    if (sequenceRoute) {
      removeLastLocation(sequenceRoute);
    }

    return length > 1 && !createRoute && !sequenceRoute ? goBack() : push('/routes/route-templates');
  };

  startRouteTemplateSequence = (formData: any) => {
    const {
      routeTemplate: {
        routeTemplate: { id, vendorId, routeLocations },
      },
      routeSequence,
      userName,
      vehicleTypeTechnicalName,
      vehicleTypeId,
    } = this.props;

    const routeSequenceLocations = map(
      routeLocations,
      ({ serviceContractRouteTemplateId, orderNumber, service, location: { name, address } }) => ({
        address: address.line1,
        id: serviceContractRouteTemplateId,
        latitude: get(head(service?.serviceContractBinDetails), 'binLatitude') || address.latitude,
        longitude: get(head(service?.serviceContractBinDetails), 'binLongitude') || address.longitude,
        name,
        orderNo: orderNumber,
        streetNumber: address.streetNumber,
        serviceContractId: get(head(service?.serviceContractBinDetails), 'serviceContractId'),
      }),
    );

    const additionalConfigurations = getRouteSequenceAdditionalConfigurations(formData, vehicleTypeId);

    routeSequence({
      requestedBy: userName,
      routeId: id,
      routeSequenceLocations,
      sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_ROUTE_TEMPLATE,
      vehicleType: vehicleTypeTechnicalName,
      vendorId,
      ...additionalConfigurations,
    })
      .then(() => {
        createWarningNotification(`${translate('routes.alertMessages.routeSequenceRequestProcessing')}`);
        this.handleBackAction();
      })
      .catch(() => {
        createErrorNotification(`${translate('routes.alertMessages.routeSequenceRequestError')}`);
      });
  };

  sequenceRoute = async () => {
    const {
      routeTemplate: { routeTemplate: route },
      routeSequenceHistory,
    } = this.props;

    const routeHasMoreThan250Stops = route.routeLocations.length > 250;
    const routeWasSequencedLessThan30DaysAgo =
      !!routeSequenceHistory && moment().subtract(30, 'days').isBefore(routeSequenceHistory.lastSequenceDate);
    const routeWasSequencedRecently = routeHasMoreThan250Stops && routeWasSequencedLessThan30DaysAgo;

    const optimizedStopsLength = route.routeLocations.filter(
      (routeLocation: any) => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
    ).length;

    if (routeWasSequencedRecently) {
      if (
        !(await confirm(
          translate(
            optimizedStopsLength
              ? 'routes.alertMessages.confirmRecentlySequencedRouteSequencingWithOptimizedStops'
              : 'routes.alertMessages.confirmRecentlySequencedRouteSequencing',
          ),
        ))
      ) {
        return;
      }
    } else if (
      !(await confirm(
        translate(
          optimizedStopsLength
            ? 'routes.alertMessages.confirmRouteSequencingWithOptimizedStops'
            : 'routes.alertMessages.confirmRouteSequencing',
        ),
      ))
    ) {
      return;
    }

    this.setState({
      isServiceSideModalOpen: true,
    });
  };

  selectTemplateLocation = (routeTemplateLocation: SelectedTemplateLocation, event: MouseEvent) => {
    const { setRouteMapSelectedFeature, setRouteMapViewport } = this.props;
    event.stopPropagation();
    setRouteMapSelectedFeature(RouteMapFeature.routeStops, routeTemplateLocation.serviceContractRouteTemplateId);
    setRouteMapViewport({
      latitude: routeTemplateLocation.latitude,
      longitude: routeTemplateLocation.longitude,
      zoom: 16,
    });
    this.setState({
      selectedTemplateLocation: routeTemplateLocation,
    });
  };

  onSortOrderChange = (sortedBy: string, sortOrder: SortOrder) => {
    const { routeTemplateLocationsTableRows } = this.state;
    const routeTemplateLocationsTableRowsOrdered = getOrderedRows(routeTemplateLocationsTableRows, sortedBy, sortOrder);

    this.setState({ routeTemplateLocationsTableRows: routeTemplateLocationsTableRowsOrdered });
  };

  render() {
    const {
      routeTemplate: {
        isLoadingLocations,
        locationsLimit,
        loadedPages,
        locationsTotal,
        routeTemplate: {
          description,
          endTime,
          id,
          intermediateLocationId,
          isEnabled,
          routeTemplateName,
          scheduledDay,
          startDate,
          startTime,
          supervisorId,
          vehicleName,
          vehicleTypeName,
          groups,
        },
      },
      routeSequencingEnabled,
      vehicleTypeId,
      travelPathStatusDetails,
    } = this.props;

    const {
      isMapLoading,
      isServiceSideModalOpen,
      routeLocations,
      routeSequenceButtonVisible,
      routeSequencingEnabledSpecificForVehicle,
      routeTemplateLocationsTableRows,
      selectedTemplateLocation,
    } = this.state;

    const pickupTypeEnabled = vehicleTypeId === DELIVERY_UTILITY_ID;

    const routeLocationsTableCells = [
      { name: 'stopNumber', label: translate('routes.stopNumber'), width: '10%' },
      { name: 'customer', label: translate('common.customer'), width: pickupTypeEnabled ? '25%' : '35%' },
      { name: 'accountStatus', label: translate('routes.accountStatus'), width: pickupTypeEnabled ? '10%' : '15%' },
      { name: 'service', label: translate('routes.service'), width: '20%' },
      { name: 'dayOfService', label: translate('routes.dayOfService'), width: '20%' },
    ];

    if (pickupTypeEnabled) {
      routeLocationsTableCells.push({ name: 'pickupType', label: translate('routes.pickupType'), width: '15%' });
    }

    const locationsInfo = {
      total: locationsTotal,
      limit: locationsLimit,
      loadedPages,
    };

    const showLoadingBatches = isLoadingLocations && locationsInfo.total > 0;
    const allRouteLocations = this.props.routeTemplate.routeTemplate.routeLocations;

    const routeLocationsWithStopNumber = routeTemplateLocationsTableRows.filter(
      routeLocation => routeLocation.orderNumber > 0,
    );
    const routeLocationsWithZeroStopNumber = routeTemplateLocationsTableRows.filter(
      routeLocation => routeLocation.orderNumber === 0,
    );

    const optimizedRouteLocations = routeTemplateLocationsTableRows.filter(
      routeLocation => routeLocation.orderNumber === JOB_PENDING_OPTIMIZATION_ID,
    );

    const isVisibleSupervisor = vehicleTypeId !== STREET_SWEEPER_ID && vehicleTypeId !== WASTE_AUDIT_ID;

    const routeTemplateLocationsRowProps = {
      selectTemplateLocation: this.selectTemplateLocation,
    };

    const isEditButtonDisabled =
      (!!travelPathStatusDetails && travelPathStatusDetails.inProgress) || isLoadingLocations;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction onClick={this.handleBackAction} id="back-button">
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle textTransform="none">{routeTemplateName}</PageTitle>
              <PageSubtitle margin="no">{description}</PageSubtitle>
            </PageTitleContainer>
          </PageDetails>

          <PageActions>
            <PermissionGuard permission={ROUTES_PLANNER_DETAILS_EDIT}>
              <PopoverWrapper
                triggerButton={
                  <ButtonLink
                    color="primary"
                    id="edit-route-template-button"
                    line
                    margin="no xSmall no no"
                    to={`/routes/route-templates/${id}/edit`}
                    disabled={isEditButtonDisabled}
                  >
                    {translate('common.edit')}
                  </ButtonLink>
                }
                popoverContent={
                  isEditButtonDisabled && (
                    <Popover>
                      <Text block weight="medium" margin="xxSmall no xxSmall">
                        {translate('routes.travelPath.alertMessages.cantMakeChangesRouteTemplate')}
                      </Text>
                    </Popover>
                  )
                }
              />
            </PermissionGuard>
            <PermissionGuard permission={ROUTES_PLANNER_DETAILS_EXPORT}>
              <Button
                color="primary"
                id="export-route-template-button"
                margin="no xSmall no no"
                onClick={this.exportRouteTemplate}
                disabled={isLoadingLocations}
              >
                {translate('common.export')}
              </Button>
            </PermissionGuard>
            {routeSequencingEnabled &&
              routeSequencingEnabledSpecificForVehicle &&
              routeLocations &&
              routeLocations.length > 1 &&
              routeSequenceButtonVisible && (
                <PermissionGuard permission={ROUTES_TRACKER_SEQUENCE_ROUTE}>
                  <PopoverWrapper
                    triggerButton={
                      <Button
                        color="primary"
                        disabled={!isEnabled || showLoadingBatches}
                        id="sequence-route-template-button"
                        onClick={this.sequenceRoute}
                      >
                        {translate('routes.sequenceRoute')}
                      </Button>
                    }
                    popoverContent={
                      !isEnabled ? (
                        <Popover>
                          <Text block weight="medium" margin="xxSmall no xxSmall">
                            {translate('routes.alertMessages.routeTemplateNotActive')}
                          </Text>
                        </Popover>
                      ) : undefined
                    }
                    size="large"
                  />
                </PermissionGuard>
              )}
          </PageActions>
        </PageHeader>
        <Panel margin="no no xLarge">
          <PanelSectionGroup>
            <PanelSection padding="small no">
              <RouteTemplateSummary
                endTime={endTime}
                noOfStops={locationsTotal || 0}
                scheduledDay={scheduledDay}
                startDate={startDate}
                startTime={startTime}
                supervisorId={isVisibleSupervisor ? supervisorId : 0}
                vehicleName={vehicleName}
                vehicleTypeName={vehicleTypeName}
                groups={groups}
              />
            </PanelSection>

            <PanelSection padding="no sMedium small" withBorder>
              <PermissionGuard permission={ROUTES_PLANNER_DETAILS_EDIT}>
                <PopoverWrapper
                  triggerButton={
                    <EditPickupLocationsContainer>
                      <Link
                        id={`route-templates-${id}-edit-pickup-locations-button`}
                        color="primary"
                        to={`/routes/route-templates/${id}/editPickupLocations`}
                        disabled={isEditButtonDisabled}
                      >
                        {translate('pickupLocationEditor.editPickupLocations')}
                      </Link>
                    </EditPickupLocationsContainer>
                  }
                  popoverContent={
                    isEditButtonDisabled && (
                      <Popover>
                        <Text block weight="medium" margin="xxSmall no xxSmall">
                          {translate('routes.travelPath.alertMessages.cantMakeChangesRoute')}
                        </Text>
                      </Popover>
                    )
                  }
                />
              </PermissionGuard>
            </PanelSection>

            {!!size(allRouteLocations) && <RouteTemplateLocationsForm onSearchTermChange={this.onSearchTermChange} />}

            {routeLocations.length > 0 && (
              <PanelSection withBorder>
                <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                  <MapContainer size="large">
                    {showLoadingBatches ? (
                      <RouteLocationsLoader {...locationsInfo} />
                    ) : (
                      <>
                        {(isMapLoading || isLoadingLocations) && <GoogleMapLoading />}
                        <RouteMapGL
                          isRoutePlannerPage
                          routeId={id}
                          alertsAreReadOnly
                          isMapLoading={isMapLoading || isLoadingLocations}
                          onMapLoadComplete={this.onMapLoadComplete}
                          routeLocations={routeLocations}
                          selectedTemplateLocation={selectedTemplateLocation}
                        />
                      </>
                    )}
                  </MapContainer>
                </Resizable>
              </PanelSection>
            )}

            {!routeLocations.length && (isMapLoading || isLoadingLocations) && (
              <PanelSection withBorder>
                <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                  <MapContainer>
                    {showLoadingBatches ? <RouteLocationsLoader {...locationsInfo} /> : <GoogleMapLoading />}
                  </MapContainer>
                </Resizable>
              </PanelSection>
            )}

            {!isLoadingLocations && (
              <>
                {!size(routeLocations) ? (
                  <Message padding="sMedium">{translate('routes.noRouteTemplateStops')}</Message>
                ) : (
                  <>
                    <>
                      {!!size(optimizedRouteLocations) && (
                        <PanelSection>
                          <Table
                            cells={routeLocationsTableCells}
                            rowComponent={RouteTemplateLocationsTableRow}
                            rows={optimizedRouteLocations}
                            tableHeading={translate('routes.pendingOptimization')}
                          />
                        </PanelSection>
                      )}
                      {!!size(routeLocationsWithZeroStopNumber) && (
                        <PanelSection>
                          <Table
                            cells={size(optimizedRouteLocations) ? [] : routeLocationsTableCells}
                            rowComponent={RouteTemplateLocationsTableRow}
                            rows={routeLocationsWithZeroStopNumber}
                            tableHeading={translate('routes.unableToSequenceRoute')}
                          />
                        </PanelSection>
                      )}

                      <PanelSection>
                        {!!size(routeLocationsWithStopNumber) && (
                          <Table
                            cells={
                              size(routeLocationsWithZeroStopNumber) || size(optimizedRouteLocations)
                                ? []
                                : routeLocationsTableCells
                            }
                            rowComponent={RouteTemplateLocationsTableRow}
                            rowProps={{ enablePickupType: pickupTypeEnabled, ...routeTemplateLocationsRowProps }}
                            rows={routeLocationsWithStopNumber}
                            virtualized
                            withClickableRows
                            virtualizedProps={{
                              itemSize: TABLE_ROW_HEIGHT_SMALL,
                              height:
                                Math.min(
                                  routeLocationsWithStopNumber.length * TABLE_ROW_HEIGHT_SMALL,
                                  TABLE_ROW_HEIGHT_SMALL * 8,
                                ) || 1,
                            }}
                          />
                        )}
                      </PanelSection>
                    </>
                  </>
                )}
              </>
            )}
          </PanelSectionGroup>
        </Panel>

        {!!isServiceSideModalOpen && (
          <ServiceSideModalResolver
            vehicleTypeId={vehicleTypeId}
            intermediateLocationId={intermediateLocationId}
            onSubmit={this.onServiceSideFormSubmit}
            closeModal={this.closeServiceSideModal}
          />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const vehicleTypeId = state.routes.routeTemplate.routeTemplate
    ? (state.routes.routeTemplate.routeTemplate as any).vehicleTypeId
    : 0;

  return {
    lastLocations: state.core.lastLocations,
    routeSequenceHistory: state.routes.routeSequence.routeSequenceHistory,
    routeSequencingEnabled: routeSequencingStatusSelector(state.vendors.features.features),
    routeTemplate: state.routes.routeTemplate,
    userName: state.account.login.user.email,
    vehicleTypeId,
    vehicleTypeTechnicalName: technicalNameByVehicleTypeIdSelector(state.fleet.vehicleTypesForVendor, vehicleTypeId),
    travelPathStatusDetails: state.routes.travelPath.travelPathStatusDetails,
  };
};

const mapDispatchToProps = {
  exportRouteTemplate,
  loadRouteSequenceSettings,
  push,
  removeLastLocation,
  resetRouteTemplate,
  resetVehicleTypesForVendor,
  routeSequence,
  resetGeoFences,
  setRouteMapSelectedFeature,
  setRouteMapViewport,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteTemplatePage));
