import React from 'react';
import { Layer } from 'react-map-gl';

import { DASHBOARD_APPLICATION_STATUS_LAYER, DASHBOARD_CLUSTERS_SOURCE } from 'src/dashboard/constants/dashboardMapGL';

const DashboardApplicationStatusGLLayers: React.FC = React.memo(() => (
  <Layer
    id={DASHBOARD_APPLICATION_STATUS_LAYER}
    type="symbol"
    source={DASHBOARD_CLUSTERS_SOURCE}
    filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], DASHBOARD_APPLICATION_STATUS_LAYER]]}
    paint={{
      'icon-color': ['get', 'color'],
      'icon-halo-color': '#ffffff',
      'icon-halo-width': 10,
      'icon-halo-blur': 10,
    }}
    layout={{
      'icon-image': ['get', 'icon'],
      'icon-size': 1,
      'icon-allow-overlap': true,
    }}
    minzoom={15}
  />
));

export default DashboardApplicationStatusGLLayers;
