import React, { Fragment, MouseEvent, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';

import { ActionButtonTooltip, DragHandle, UnconnectedCheckbox, UnconnectedSelect } from '../../../../core/components';
import { COMPLETED, IN_PROGRESS, PICKUP_STATUSES, PLACED_ON_HOLD } from '../../../../common/constants';
import { Container, Label, TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { LocationAddress } from '../../../interfaces/Route';
import { Option } from '../../../../common/interfaces/Option';
import { PICKUP_STATUS_LABEL_COLORS } from '../../../constants';
import { RouteLocation } from '../../../interfaces/RouteLocation';
import { RouteLocationNotesModalResolver } from '../../modals';
import { SelectedLocation } from '../../RouteMap';
import { TABLE_ROW_HEIGHT } from '../../../../core/constants';
import { TechnicalType } from '../../../../common/interfaces/TechnicalType';
import RouteSequenceStopDropZone from '../routeSequence/RouteSequenceStopDropZone';

const isDelete = (isManager: boolean, statusId: number) =>
  !isManager || (isManager && statusId !== COMPLETED && statusId !== IN_PROGRESS && statusId !== PLACED_ON_HOLD);

type Props = RouteLocation & {
  accountNumber?: number;
  accountStatusOptions: Option[];
  address: LocationAddress;
  customerName: string;
  deleteRouteLocation: (
    event: MouseEvent<HTMLElement>,
    orderNumber: number,
    locationId: number,
    stopId?: string,
  ) => void;
  editJobStatus: (event: MouseEvent<HTMLElement>, routeLocation: SelectedLocation) => void;
  editService: (event: MouseEvent<HTMLElement>, serviceId: number, routeLocationId: number, customerId: number) => void;
  hasStrobeData?: boolean;
  isChecked: boolean;
  isSearchInitialized: boolean;
  isVendorManager: boolean;
  locationName: string;
  newOptimizedRouteLocationsLength: number;
  onAccountStatusChange: (value: string | number, locationId: number, stopId?: string) => void;
  onLocationSelected: (locationId: number, orderNumber: number, stopId?: string) => void;
  onRouteLocationDrop?: (event: any, insertRouteLocationAtIndex: number) => void;
  onRoutePickupTypeChange: (value: string | number, locationId: number, stopId?: string) => void;
  openOrderNumberPopover: (oldOrderNumber: number, event: MouseEvent<HTMLElement>, locationId: number) => void;
  reasonCodeTypes: TechnicalType[];
  routeId?: number;
  routePickupTypeOptions: Option[];
  routeStatus: number;
  selectLocation: (routeLocation: SelectedLocation, event?: any) => void;
  serviceCount?: number;
  serviceName: string;
  stopId: string;
  vehicleId: number;
  vehicleName: string;
  vehicleTypeTechnicalName: string;
  vendorAccountNo?: number;
  wasteAuditType: TechnicalType;
  wasteMaterialTypeName?: string;
};

interface State {
  isRouteLocationImagesModalOpen: boolean;
  isRouteLocationNotesModalOpen: boolean;
}

class RouteLocationsTableRow extends PureComponent<Props, State> {
  state = {
    isRouteLocationImagesModalOpen: false,
    isRouteLocationNotesModalOpen: false,
  };

  openRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: true });
  };

  closeRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: false });
  };

  render() {
    const {
      accountStatusId,
      accountStatusOptions,
      customer: { name: customerName, id: customerId },
      deleteRouteLocation,
      editJobStatus,
      id,
      isChecked,
      isSearchInitialized,
      isVendorManager,
      location: {
        id: locationId,
        name: locationName,
        address: { street, streetNumber, latitude, longitude },
      },
      newOptimizedRouteLocationsLength,
      onAccountStatusChange,
      onLocationSelected,
      onRouteLocationDrop,
      onRoutePickupTypeChange,
      openOrderNumberPopover,
      orderNumber,
      pickupExceptions,
      pickupStatusId,
      pickupTypeId,
      routeId,
      routePickupTypeOptions,
      routeStatus,
      selectLocation,
      stopId,
      service,
      wasteAuditStatuses,
    } = this.props;

    const routeLocation = {
      customerName,
      id,
      latitude,
      locationName,
      longitude,
      orderNumber,
      pickupExceptions,
      pickupStatusId,
      pickupTypeId,
      service,
      wasteAuditStatuses,
    };

    const { isRouteLocationNotesModalOpen } = this.state;

    const tableCellWidths = ['5%', '5%', '8%', '17%', '15%', '15%', '15%', '10%', '10%'];

    const jobIsPendingOptimization = orderNumber === JOB_PENDING_OPTIMIZATION_ID;

    return (
      <Fragment>
        <TableRow
          height={TABLE_ROW_HEIGHT}
          onClick={event => selectLocation(routeLocation, event)}
          wrap={'wrap'}
          position={'relative'}
          id={`stop-${id}`}
        >
          {!jobIsPendingOptimization && onRouteLocationDrop && !newOptimizedRouteLocationsLength && (
            <RouteSequenceStopDropZone
              position="top"
              routeLocationIndex={orderNumber - 1}
              onRouteLocationDrop={onRouteLocationDrop}
            />
          )}
          <TableCell width={tableCellWidths[0]}>
            {!newOptimizedRouteLocationsLength && (
              <UnconnectedCheckbox
                block
                size="small"
                id={`stop-${id}-checkbox`}
                checked={isChecked}
                onChange={() => onLocationSelected(id, orderNumber, stopId)}
              />
            )}
          </TableCell>

          <TableCell width={tableCellWidths[1]} align="center" padding="no">
            {!isSearchInitialized && !jobIsPendingOptimization && !newOptimizedRouteLocationsLength && (
              <DragHandle id={`stop-${id}-drag-handle`} />
            )}
          </TableCell>

          <TableCell width={tableCellWidths[2]}>
            {!jobIsPendingOptimization && (
              <TableActionButton
                onClick={event =>
                  !newOptimizedRouteLocationsLength ? openOrderNumberPopover(orderNumber, event, id) : undefined
                }
                id={`stop-${id}-reorder-button`}
              >
                {orderNumber}
              </TableActionButton>
            )}
          </TableCell>

          <TableCell vertical align="center" width={tableCellWidths[3]}>
            <Text block weight="medium" margin="no no xxSmall" singleLine id={`stop-${id}-customer`}>
              {customerName}
            </Text>

            <Text weight="light" size="small" margin="no no xxSmall" singleLine id={`stop-${id}-location`}>
              {locationName}, {streetNumber} {street}
            </Text>
          </TableCell>

          <TableCell width={tableCellWidths[4]}>
            <Label
              color={PICKUP_STATUS_LABEL_COLORS[pickupStatusId.toString()]}
              onClick={event => editJobStatus(event, routeLocation)}
              id={`stop-${id}-job-status`}
              disabled={id === undefined}
            >
              {PICKUP_STATUSES[pickupStatusId].name}
            </Label>
          </TableCell>

          <TableCell width={tableCellWidths[5]}>
            <Container>
              <UnconnectedSelect
                value={accountStatusId}
                options={accountStatusOptions}
                margin="no"
                normalize={Number}
                onChange={value => onAccountStatusChange(value, id, stopId)}
                id={`stop-${id}-account-status`}
                disabled={newOptimizedRouteLocationsLength > 0}
              />
            </Container>
          </TableCell>

          <TableCell width={tableCellWidths[6]}>
            <Container>
              <UnconnectedSelect
                value={pickupTypeId}
                options={routePickupTypeOptions}
                margin="no"
                onClick={event => event.stopPropagation()}
                onChange={value => onRoutePickupTypeChange(value, id, stopId)}
                id={`stop-${id}-pickup-type`}
                disabled={newOptimizedRouteLocationsLength > 0}
              />
            </Container>
          </TableCell>

          <TableCell width={tableCellWidths[7]}>
            <Text block singleLine title={service && service.name} id={`stop-${id}-service`}>
              {service && service.name}
            </Text>
          </TableCell>

          <TableCell width={tableCellWidths[8]}>
            {routeId && !newOptimizedRouteLocationsLength && (
              <TableActionButton onClick={this.openRouteLocationNotesModal} id={`stop-${id}-notes-button`}>
                <ActionButtonTooltip icon="note" tooltip="notes" />
              </TableActionButton>
            )}

            {isDelete(isVendorManager, routeStatus) && (!newOptimizedRouteLocationsLength || !id) && (
              <TableActionButton
                onClick={event => deleteRouteLocation(event, orderNumber, id, stopId)}
                id={`stop-${id}-delete-button`}
              >
                <ActionButtonTooltip icon="delete" tooltip="delete" />
              </TableActionButton>
            )}

            {!!customerId && !newOptimizedRouteLocationsLength && (
              <TableActionButton id={`stop-${id}-edt-service-button`}>
                <Link
                  id="edit-service-link"
                  color="grayDark"
                  to={`/customers/customers/${customerId}/location/${locationId}/service/${service.id}`}
                >
                  <ActionButtonTooltip icon="edit" tooltip="editService" />
                </Link>
              </TableActionButton>
            )}
          </TableCell>

          {onRouteLocationDrop && (
            <RouteSequenceStopDropZone
              position="bottom"
              routeLocationIndex={orderNumber}
              onRouteLocationDrop={onRouteLocationDrop}
            />
          )}
        </TableRow>

        {!!isRouteLocationNotesModalOpen && routeId && (
          <RouteLocationNotesModalResolver
            modalTitle={customerName}
            modalSubTitle={locationName}
            routeId={routeId}
            routeLocationId={id}
            closeModal={this.closeRouteLocationNotesModal}
          />
        )}
      </Fragment>
    );
  }
}

export default SortableElement(RouteLocationsTableRow);

export const UnsortableRouteLocationsTableRow = RouteLocationsTableRow;
