import React, { PureComponent } from 'react';

import ReactDOM from 'react-dom';

import {
  Modal as ModalContainer,
  ModalClose,
  ModalCloseIcon,
  ModalDialog,
  ModalFixedHeader,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
} from './styled';

export interface ModalProps {
  fixedHeader?: boolean;
  flex?: boolean;
  flexDirection?: string;
  hasBorderRadius?: boolean;
  headerPadding?: string;
  height?: string;
  hidden?: boolean;
  id?: string;
  isLoading?: boolean;
  maxHeight?: string;
  minHeight?: string;
  minWidth?: string;
  onClose?: (e: any) => void;
  overflow?: string;
  padding?: string;
  size?: string;
  subTitle?: string;
  subTitleColor?: string;
  subTitleFontWeight?: string;
  subTitleSize?: string;
  title?: string;
  verticalSize?: string;
}

class Modal extends PureComponent<ModalProps> {
  private containerElement = document.createElement('div');

  componentDidMount() {
    document.body.appendChild(this.containerElement);
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    document.body.removeChild(this.containerElement);
    document.body.style.overflowY = 'initial';
  }
  render() {
    const {
      children,
      fixedHeader,
      flex,
      flexDirection,
      hasBorderRadius,
      headerPadding,
      height,
      id,
      maxHeight,
      minHeight,
      minWidth,
      onClose,
      overflow,
      padding,
      size,
      subTitle,
      subTitleColor,
      subTitleFontWeight,
      subTitleSize,
      title,
      verticalSize,
      ...props
    } = this.props;
    const HeaderComp = fixedHeader ? ModalFixedHeader : ModalHeader;

    return ReactDOM.createPortal(
      <ModalContainer {...props}>
        <ModalDialog
          minHeight={minHeight}
          maxHeight={maxHeight}
          height={height}
          minWidth={minWidth}
          size={size}
          verticalSize={verticalSize}
          padding={padding}
          flex={flex}
          flexDirection={flexDirection}
          overflow={overflow}
          hasBorderRadius={hasBorderRadius}
          id={id}
        >
          <HeaderComp padding={headerPadding}>
            {onClose && (
              <ModalClose onClick={onClose}>
                <ModalCloseIcon />
              </ModalClose>
            )}
            {title && <ModalTitle>{title}</ModalTitle>}
            {subTitle && (
              <ModalSubtitle color={subTitleColor} fontWeight={subTitleFontWeight} size={subTitleSize}>
                {subTitle}
              </ModalSubtitle>
            )}
          </HeaderComp>
          {children}
        </ModalDialog>
      </ModalContainer>,
      this.containerElement,
    );
  }
}

export default Modal;
