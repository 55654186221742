import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const EXCEPTIONS = 1;
export const PARTICIPATION = 3;

export const WASTE_AUDIT_TYPES = mapKeys(
  [
    { id: EXCEPTIONS, name: translate('vendors.wasteAuditConfigurationTypes.exceptions') },
    { id: PARTICIPATION, name: translate('vendors.wasteAuditConfigurationTypes.participation') },
  ],
  'id',
);
