import createTransformer from '../../utils/services/createTransformer';

const transformLogin = (values: any) => {
  const user = createTransformer(values);
  return {
    ...user.pick('name', 'email', 'enableGuidedTour', 'roles', 'userId', 'languageId', 'language', 'vendorList').value,
    vendor: user
      .get('vendor')
      .pick(
        'id',
        'name',
        'phone',
        'isActive',
        'vendorTypeId',
        'timeZone',
        'gusId',
        'languageId',
        'language',
        'vendorList',
      ).value,
  };
};

export default transformLogin;
