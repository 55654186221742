import { useDispatch } from 'react-redux';

import { loadEventTypes } from '../../../routes/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { useSelector } from '../../../core/hooks/useSelector';
import ZEventLogModal from './ZEventLogModal';
import { loadZEventLog } from '../../../routes/ducks/eventLog';

type Props = {
  closeModal: () => void;
};

export default function ZEventLogModalResolver(props: Props) {
  const dispatch = useDispatch();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  if (!routeSummary) return null;

  const loadDependencies = () =>
    Promise.all([loadEventTypes()(dispatch), loadZEventLog(routeSummary.routeId)(dispatch)]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={ZEventLogModal}
      successProps={{ ...props, routeSummary }}
    />
  );
}
