import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

import { Dropdown } from '../../../core/components';
import { Container, ButtonSet, Button, Text } from '../../../core/components/styled';
import { vendorPaymentMethodTypes } from '../../ducks';
import translate from '../../../core/services/translate';
import pricingInfoIntialValueSelector from '../../services/pricingInfoIntialValueSelector';
import { AppState } from '../../../store';

interface ComponentProps {
  paymentMethods?: any[];
  paymentVendors?: any[];
  pricingInfo?: any;
  onCancel(...args: any): any;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class AcceptPaymentForSelectedProcessedServiceForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, onCancel, pristine, pricingInfo, paymentMethods, paymentVendors } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container>
          <Fragment>
            <Text margin="large no xSmall" size="small" singleLine color="gray" />
            <Text block weight="medium" margin="large no xSmall" size="small" singleLine>
              {translate('payment.paymentTotal')}: ${pricingInfo.acceptedWOTotalAmount}
            </Text>
          </Fragment>
        </Container>
        <Container>
          <Field
            name={`pricingInfo.settings${pricingInfo.rubiconInvoiceNo}.paymentMethods`}
            component={Dropdown}
            options={paymentMethods}
            label={translate('opportunity.opportunities.paymentMethod')}
            margin="large no xSmall"
            // disabled={status === 'AwaitingHaulerApproval'}
          />
        </Container>
        <Container>
          <Field
            name={`pricingInfo.settings${pricingInfo.rubiconInvoiceNo}.paymentId`}
            component={Dropdown}
            options={paymentVendors}
            label={translate('opportunity.opportunities.paymentInfo')}
            margin="medium no xSmall"
            // disabled={status === 'AwaitingHaulerApproval'}
          />
        </Container>

        <ButtonSet>
          <Button type="submit" color="primary">
            {translate('opportunity.submit')}
          </Button>
          <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

const formSelector = formValueSelector('acceptPaymentForSelectedProcessedService');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const paymentInfo = state.payments.onCallServices.paymentInfo as any;

  return {
    initialValues: pricingInfoIntialValueSelector(state.payments.processedServices.pricingInfo),
    paymentMethods: paymentInfo ? paymentInfo.paymentMethods : [],
    paymentVendors: vendorPaymentMethodTypes(
      paymentInfo ? paymentInfo.vendorPayment : [],
      formSelector(state, `pricingInfo.settings${ownProps.pricingInfo.rubiconInvoiceNo}.paymentMethods`),
    ),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'acceptPaymentForSelectedProcessedService',
  })(AcceptPaymentForSelectedProcessedServiceForm),
);
