import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import * as Services from '../services/snowRoadConditions';
import { SnowRoadSegmentCondition, SnowRoadSegmentConditionUpdates } from '../interfaces/snowRoadConditions';
import { updateSnowRoadConditions } from '../services/snowRoadConditionsTransformers';

/**
 * Actions
 */
const SET_CONDITIONS_LOADING = 'dashboard/snowRoadConditions/SET_CONDITIONS_LOADING';
const SET_CONDITIONS = 'dashboard/snowRoadConditions/SET_CONDITIONS';
const RESET = 'dashboard/snowRoadConditions/RESET';

/**
 * Initial state
 */
interface SnowRoadConditionsState {
  isLoading: boolean;
  snowRoadConditions: SnowRoadSegmentCondition[];
}

const initialState: SnowRoadConditionsState = {
  isLoading: false,
  snowRoadConditions: [],
};

/**
 * Reducer
 * @param state
 * @param action
 */
export const snowRoadConditionsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_CONDITIONS_LOADING:
      return update(state, {
        $merge: {
          isLoading: action.loading,
        },
      });

    case SET_CONDITIONS:
      return update(state, {
        $merge: {
          snowRoadConditions: !action.updatesOnly
            ? action.conditions
            : updateSnowRoadConditions(state.snowRoadConditions, action.conditions),
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

/**
 * Action creators
 */
const setConditionsLoading = (loading: boolean) => ({
  type: SET_CONDITIONS_LOADING,
  loading,
});

const setConditions = (conditions: SnowRoadSegmentCondition[]) => ({
  type: SET_CONDITIONS,
  conditions,
});

const setConditionUpdates = (conditions: SnowRoadSegmentConditionUpdates[]) => ({
  type: SET_CONDITIONS,
  conditions,
  updatesOnly: true,
});

export const resetSnowRoadConditions = () => ({
  type: RESET,
});

/**
 * Complex action creators
 */
export const loadSnowRoadConditions = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(setConditionsLoading(true));

  const promise = Services.loadSnowRoadConditions(vendorId);

  promise
    .then(conditions => {
      dispatch(setConditions(conditions));
    })
    .finally(() => {
      dispatch(setConditionsLoading(false));
    });

  return promise;
};

export const loadSnowRoadConditionUpdates = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(setConditionsLoading(true));

  const promise = Services.loadSnowRoadConditionUpdates(vendorId);

  promise
    .then(conditions => {
      dispatch(setConditionUpdates(conditions));
    })
    .finally(() => {
      dispatch(setConditionsLoading(false));
    });

  return promise;
};
