import { http } from '../../core/services/http';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import {
  FILTER_VEHICLE_TYPE_ID,
  FILTER_SERVICE_ZONE_ID,
  FILTER_SUPERVISOR_ID,
  FILTER_SERVICE_TYPE_ID,
} from 'src/common/constants';

export const doLoadFiltersSettings = (vendorId: number, userId: string, isGlobal?: boolean) => {
  const userGlobalFilterTypeIds =
    !isGlobal &&
    [FILTER_VEHICLE_TYPE_ID, FILTER_SERVICE_ZONE_ID, FILTER_SUPERVISOR_ID, FILTER_SERVICE_TYPE_ID].toString();

  return http
    .get<FilterSetting[]>(`portal/vendor/${vendorId}/users/${userId}/globalFilters`, {
      params: { isGlobal, userGlobalFilterTypeIds },
    })
    .then(response => response.data);
};

export const doSaveGlobalFiltersSettings = (defaultFilters: any, vendorId: number, userId: number) =>
  http.put<FilterSetting[]>(`portal/vendor/${vendorId}/users/${userId}/globalFilters`, defaultFilters);

export const doSavePreferencesFiltersSettings = (defaultFilters: any, vendorId: number, userId: number) =>
  http.put<FilterSetting[]>(`portal/vendor/${vendorId}/users/${userId}/preferencesGlobalFilters`, defaultFilters);
