import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { mapper, sizeMapper } from '../../../utils/styles';

interface Props {
  alignEnd?: boolean;
  color?: string;
  inline?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  labelOnLeft?: boolean;
  labelSize?: string;
  margin?: string;
  size?: 'small' | 'medium' | 'large';
  whiteSpace?: string;
  width?: string;
  isHighlighted?: boolean;
}

export const SwitchText = styled.span<Props>`
  line-height: ${props => mapper(props.size, { small: '12px', medium: '16px', large: '18px' }, 'large')};
  font-size: ${props => mapper(props.size, { small: '10px', medium: '12px', large: '14px' }, 'large')};
  margin-left: ${props => mapper(props.size, { small: '5px', medium: '10px', large: '15px' }, 'large')};
  text-transform: capitalize;
  user-select: none;

  ${props =>
    props.color &&
    css`
      color: ${props.theme.brandAlert};
      font-size: 12px;
      margin-left: 10px;
    `};

  ${props =>
    props.labelSize === 'large' &&
    css`
      font-size: 16px;
      font-weight: 500;
    `};

  ${props =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `};

  ${props =>
    props.isHighlighted &&
    css`
      color: #fff;
    `}
`;

export const SwitchHandle = styled.div<Props>`
  position: absolute;
  top: 2px;
  left: 3px;
  width: ${props => mapper(props.size, { small: '8px', medium: '12px', large: '16px' }, 'large')};
  height: ${props => mapper(props.size, { small: '8px', medium: '12px', large: '16px' }, 'large')};
  background: #fff;
  border-radius: ${props => mapper(props.size, { small: '4px', medium: '6px', large: '8px' }, 'large')};
  box-shadow: 0 2px 4px ${props => transparentize(0.9, props.theme.grayBase)};
  transform: none;
  transition: transform 0.1s;
`;

export const SwitchBar = styled.div<Props>`
  position: relative;
  width: 35px;
  width: ${props => mapper(props.size, { small: '20px', medium: '26px', large: '35px' }, 'large')};
  min-width: ${props => mapper(props.size, { small: '20px', medium: '26px', large: '35px' }, 'large')};
  height: ${props => mapper(props.size, { small: '12px', medium: '16px', large: '20px' }, 'large')};
  background-color: ${props => props.theme.grayDark};
  border-radius: ${props => mapper(props.size, { small: '6px', medium: '8px', large: '10px' }, 'large')};
  cursor: pointer;
`;
export const Switch = styled.div<Props>`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.isActive &&
    css`
      ${SwitchBar} {
        background-color: ${props => props.theme.brandPrimary};
      }

      ${SwitchHandle} {
        transform: translateX(${props => mapper(props.size, { small: '5px', medium: '8px', large: '14px' }, 'large')});
      }
    `};

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      ${SwitchBar} {
        pointer-events: none;
      }
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.labelOnLeft &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;

      ${SwitchText} {
        margin: 0 15px 0 0;
      }
    `};

  ${props =>
    props.alignEnd &&
    css`
      justify-content: flex-end;
    `};
`;
