import React, { useState } from 'react';
import { camelize } from 'humps';
import { find, groupBy, map, size, uniqueId } from 'lodash-es';

import { ContainerLocationForMap } from 'src/fleet/interfaces/containers';
import { CONTAINERS_MAP_ICONS_BY_ID, MULTIPLE } from 'src/fleet/constants/containersMap';
import { useSelector } from 'src/core/hooks/useSelector';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import {
  ContainersMapControlContainer,
  ContainersMapControlToggle,
  ContainersLegendItems,
  ContainersLegendItem,
  ContainersLegendItemColor,
} from 'src/fleet/components/styled/ContainersMap';
import { Text } from 'src/core/components/styled';
import { Icon } from 'src/core/components';
import translate from 'src/core/services/translate';

interface Props {
  containerLocations: ContainerLocationForMap[];
}

export const RubiconServicesMapLegend: React.FC<Props> = ({ containerLocations }) => {
  const [isOpen, setIsOpen] = useState(false);
  const equipmentTypes = useSelector(state => state.common.equipmentTypes.equipmentTypes);

  const containers: { id: number; t: number }[] = [];
  containerLocations.forEach(location => containers.push(...location.con));

  const groupedContainers = groupBy(containers, 't');

  const sortedContainers = Object.keys(groupedContainers).sort();

  const legendItemsContainers = sortedContainers.map(st => {
    const containers = groupedContainers[Number(st)];
    const color = CONTAINERS_MAP_ICONS_BY_ID[Number(st)].color;
    const type = find(equipmentTypes, { id: Number(st) }) as TechnicalType;
    return {
      size: containers.length,
      color,
      name: translate(`common.equipmentTypes.${camelize(type?.technicalName || '')}`) || st,
      hasBorder: false,
    };
  });

  if (find(containerLocations, l => l.con.length > 1)) {
    legendItemsContainers.push({
      size: -1,
      color: CONTAINERS_MAP_ICONS_BY_ID[MULTIPLE].color,
      name: translate('dashboard.multiple'),
      hasBorder: true,
    });
  }

  if (size(legendItemsContainers) === 0) return null;

  return (
    <ContainersMapControlContainer isOpen={isOpen}>
      <ContainersMapControlToggle onClick={() => setIsOpen(!isOpen)}>
        <Icon height="14px" icon={isOpen ? 'arrowDownFill' : 'arrowUpFill'} />

        <div>{translate('routeTemplateBuilder.legend')}</div>
      </ContainersMapControlToggle>

      {isOpen && (
        <ContainersLegendItems>
          {map(legendItemsContainers, item => (
            <ContainersLegendItem key={uniqueId('legend_item_')} hasTopBorder={item.hasBorder}>
              <>
                <ContainersLegendItemColor color={item.color} hasBorder={true} />
                {item.size === -1 && (
                  <Text weight="medium" size="xLarge" padding="no no xSmall no">
                    +
                  </Text>
                )}
              </>
              <Text padding="no xSmall">
                {item.name} {item.size !== -1 ? `- ${item.size}` : ''}
              </Text>
            </ContainersLegendItem>
          ))}
        </ContainersLegendItems>
      )}
    </ContainersMapControlContainer>
  );
};
