import { filter, groupBy, map } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';

import { Checkbox, Switch, TypedField } from 'src/core/components';
import { Button } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { ExpandableMapFiltersSection } from 'src/customers/components/forms/ExpandableMapFiltersSection';
import { ExpandableMapFiltersSubsection } from 'src/customers/components/forms/ExpandableMapFiltersSubsection';
import { DAILY_ROUTE_GEO_FENCE_ID, GENERAL_GEO_FENCE_ID, GEO_FENCES_ALL_TYPES } from 'src/routes/constants';
import { GeoFenceMapFilterOption } from 'src/routes/ducks/geoFenceFilterOptions';
import { checkIfGeoFenceIsEnabled } from 'src/vendors/ducks/features';
import {
  HAULER_LOCATION_FILTERS,
  SNOW_SWEEPER_HAULER_LOCATION_FILTERS,
} from '../../pages/routes/routePageSections/routeMap/RouteMapFiltersForm';
import {
  RouteMapFiltersClose,
  RouteMapFiltersCloseIcon,
  RouteMapFiltersTitle,
  RouteMapFiltersWrapper,
} from '../../styled/RouteMapFilters';

export const MAP_LAYERS_FORM_NAME = 'MapLayersForm';

export interface MapLayersFormValues {
  showRouteSegments: boolean;
  showOrderNumbers: boolean;
  showRouteStops: boolean;
  geoFenceSearchTerm: string;
  geoFencesTypesFilters: boolean[];
  geoFenceSubFilters: {
    [key: string]: boolean;
  };
  haulerLocations: {
    [key: string]: boolean;
  };
  showTravelPath: boolean;
}

interface PropsWithoutReduxForm {
  closePanel: () => void;
  isPanelOpen: boolean;
  isAlternativeFleet?: boolean;
  hasTravelPathFilter?: boolean;
  hideDailyRouteGeoFence?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<MapLayersFormValues, PropsWithoutReduxForm>;

const TravelPathEditorMapForm: FC<Props> = ({
  handleSubmit,
  isPanelOpen,
  closePanel,
  change,
  isAlternativeFleet,
  hasTravelPathFilter,
  hideDailyRouteGeoFence,
}) => {
  const formValues = useSelector(getFormValues(MAP_LAYERS_FORM_NAME)) as MapLayersFormValues;
  const { geoFence: routeGeoFence } = useSelector(state => state.routes.geoFence);
  const { geoFenceMapFilterOptions } = useSelector(state => state.routes.geoFencesFilterOptions.geoFencesFilterOptions);
  const isGeoFenceEnabled = useSelector(checkIfGeoFenceIsEnabled);
  const [isGeoFenceFiltersOpen, setIsGeoFenceFiltersOpen] = useState(false);
  const [isHaulerLocationsFiltersOpen, setIsHaulerLocationsFiltersOpen] = useState(false);

  const { didDoAnyTravelPathBuilderTracing } = useSelector(state => state.routes.travelPathBuildAndEdit);

  const geoFencesGroupedByType = useMemo(
    () =>
      groupBy(
        filter(geoFenceMapFilterOptions, (geoFence: GeoFenceMapFilterOption) => geoFence.id !== routeGeoFence?.id),
        (geoFence: GeoFenceMapFilterOption) => geoFence.zoneTypeId,
      ),
    [geoFenceMapFilterOptions, routeGeoFence?.id],
  );

  const geoFencesFilters = useMemo(() => {
    /// General GeoFence has to be first in the list
    let geoFenceTypeIds = Object.keys(geoFencesGroupedByType);
    if (geoFenceTypeIds.includes(GENERAL_GEO_FENCE_ID.toString())) {
      geoFenceTypeIds.splice(geoFenceTypeIds.indexOf(GENERAL_GEO_FENCE_ID.toString()), 1);
      geoFenceTypeIds.unshift(GENERAL_GEO_FENCE_ID.toString());
    }

    if (hideDailyRouteGeoFence)
      geoFenceTypeIds = filter(geoFenceTypeIds, id => id !== DAILY_ROUTE_GEO_FENCE_ID.toString());

    const filters = map(geoFenceTypeIds, geoFenceZoneTypeId => {
      const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId];
      const geoFenceZoneTypeName = GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId];

      return {
        id: geoFenceZoneTypeId,
        label: geoFenceZoneTypeName?.name,
        rightLabel: `${geoFences?.length || ''}`,
        subFilters: map(geoFences, (option: GeoFenceMapFilterOption) => ({
          id: option.id,
          label: option.name,
        })) as any,
      };
    }) as any;

    if (routeGeoFence && routeGeoFence.id) {
      filters.unshift({
        id: routeGeoFence.id,
        label: translate('routes.geoFences.routeGeoFence'),
        subFilters: [],
      });
    }
    return filters;
  }, [geoFencesGroupedByType, hideDailyRouteGeoFence, routeGeoFence]);

  const subGeoFenceFilters = useMemo(() => {
    /// General GeoFence has to be first in the list
    const geoFenceTypeIds = Object.keys(geoFencesGroupedByType);
    if (geoFenceTypeIds.includes(GENERAL_GEO_FENCE_ID.toString())) {
      geoFenceTypeIds.splice(geoFenceTypeIds.indexOf(GENERAL_GEO_FENCE_ID.toString()), 1);
      geoFenceTypeIds.unshift(GENERAL_GEO_FENCE_ID.toString());
    }
    return map(
      filter(geoFenceTypeIds, (id: number) => formValues.geoFencesTypesFilters[id]) as any,
      geoFenceZoneTypeId => {
        const geoFences = geoFencesGroupedByType[geoFenceZoneTypeId] as GeoFenceMapFilterOption[];
        const filteredGeoFences = filter(
          geoFences,
          gf => gf.name.toLowerCase().indexOf(formValues.geoFenceSearchTerm.toLowerCase()) > -1,
        );
        return {
          label: GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId].name,
          id: geoFenceZoneTypeId,
          rightLabel: `${geoFences.length}`,
          subFilters: map(filteredGeoFences, (option: GeoFenceMapFilterOption) => ({
            id: `_${option.id}`,
            label: option.name,
          })) as any,
        };
      },
    );
  }, [geoFencesGroupedByType, formValues.geoFencesTypesFilters, formValues.geoFenceSearchTerm]);

  const showOrderNumbersDisabled = !formValues.showRouteStops;

  useEffect(() => {
    if (didDoAnyTravelPathBuilderTracing) change('showTravelPath', false);
  }, [change, didDoAnyTravelPathBuilderTracing]);

  useEffect(() => {
    if (showOrderNumbersDisabled) change('showOrderNumbers', false);
  }, [change, showOrderNumbersDisabled]);

  return isPanelOpen ? (
    <form noValidate>
      <RouteMapFiltersWrapper width="260">
        <RouteMapFiltersTitle>{translate('dashboard.filterKeys.mapLayers')}</RouteMapFiltersTitle>
        <RouteMapFiltersClose onClick={closePanel}>
          <RouteMapFiltersCloseIcon />
        </RouteMapFiltersClose>
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="95%" maxHeight="95%">
          <Box display="block" margin="medium no">
            {isAlternativeFleet ? (
              <TypedField
                name="showRouteSegments"
                component={Checkbox}
                props={{
                  label: translate('insights.routeSegments'),
                  size: 'medium',
                  margin: 'no no no small',
                }}
              />
            ) : (
              <>
                <TypedField
                  name="showRouteStops"
                  component={Checkbox}
                  props={{
                    label: translate('insights.routeStops'),
                    size: 'medium',
                    margin: 'no no no small',
                  }}
                />
                <TypedField
                  name="showOrderNumbers"
                  component={Switch}
                  props={{
                    label: translate('routes.filters.showOrderNumbers'),
                    margin: 'xSmall no no small',
                    size: 'medium',
                    disabled: showOrderNumbersDisabled,
                  }}
                />
              </>
            )}

            {hasTravelPathFilter && !didDoAnyTravelPathBuilderTracing && (
              <TypedField
                name="showTravelPath"
                component={Checkbox}
                props={{
                  label: translate('routes.travelPath.originalTravelPath'),
                  size: 'medium',
                  margin: 'xSmall no no small',
                }}
              />
            )}

            <ExpandableMapFiltersSection
              name="haulerLocations"
              title={translate('dashboard.vendorLocations')}
              change={change}
              isOpen={isHaulerLocationsFiltersOpen}
              setIsOpen={(isOpen: boolean) => {
                setIsHaulerLocationsFiltersOpen(isOpen);
                isGeoFenceFiltersOpen && setIsGeoFenceFiltersOpen(!false);
              }}
              filters={isAlternativeFleet ? SNOW_SWEEPER_HAULER_LOCATION_FILTERS : HAULER_LOCATION_FILTERS}
            />

            {/* General & Route & StreetSweeper GeoFences grouped */}
            {isGeoFenceEnabled && !!geoFenceMapFilterOptions?.length && (
              <ExpandableMapFiltersSection
                title={translate('routes.geoFences.geoFencesTitle')}
                filters={geoFencesFilters}
                name="geoFencesTypesFilters"
                change={change}
                isOpen={isGeoFenceFiltersOpen}
                setIsOpen={(isOpen: boolean) => {
                  setIsGeoFenceFiltersOpen(isOpen);
                  isHaulerLocationsFiltersOpen && setIsHaulerLocationsFiltersOpen(false);
                }}
              />
            )}
          </Box>
          <Button type="button" color="primary" fullWidth margin="no" onClick={handleSubmit}>
            {translate('common.apply')}
          </Button>
        </Box>
      </RouteMapFiltersWrapper>
      <ExpandableMapFiltersSubsection
        isOpen={isGeoFenceFiltersOpen}
        setIsOpen={setIsGeoFenceFiltersOpen}
        change={change}
        name="geoFenceSubFilters"
        filters={subGeoFenceFilters}
        leftPosition="250px"
      />
    </form>
  ) : null;
};

export default reduxForm<MapLayersFormValues, PropsWithoutReduxForm>({
  form: MAP_LAYERS_FORM_NAME,
  enableReinitialize: true,
  initialValues: {
    showOrderNumbers: false,
    showRouteSegments: false,
    showRouteStops: false,
    geoFenceSearchTerm: '',
    geoFencesTypesFilters: [],
    geoFenceSubFilters: {},
    haulerLocations: {},
    showTravelPath: false,
  },
})(TravelPathEditorMapForm);
