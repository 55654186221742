import React, { PureComponent } from 'react';

import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { DatePicker } from '../../../core/components';
import { Button, ButtonSet, Text } from '../../../core/components/styled';
import { TODAY } from '../../../core/constants';
import translate from '../../../core/services/translate';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';

interface CreateRouteFromTemplateFormProps {}

class CreateRouteFromTemplateForm extends PureComponent<CreateRouteFromTemplateFormProps & InjectedFormProps> {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Text block weight="medium" align="center" margin="no no small">
          {translate('routes.createRoute')}
        </Text>

        <Field
          name="date"
          component={DatePicker}
          placeholder={translate('common.date')}
          disabledDays={[{ before: TODAY }]}
          validate={[isRequired, isDateValidValidator]}
          margin="small"
          props={{ name: 'date' }}
        />
        <ButtonSet>
          <Button type="submit" color="primary" id="create-route-button">
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

export default reduxForm({
  form: 'createRouteFromTemplate',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(CreateRouteFromTemplateForm);
