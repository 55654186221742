import React, { useEffect, useMemo, useRef } from 'react';

import { isObject } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, formValueSelector, getFormValues, reduxForm } from 'redux-form';

import { ExceptionTypeMultiSelect, JobPriorityTypesMultiSelect } from 'src/common/components';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { DateRangePicker, TypedField } from 'src/core/components';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { Button, Grid, GridColumn as GridColumnUntyped } from 'src/core/components/styled';
import { TODAY, TODAY_FORMATTED } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { DISPATCH_BOARD_SOURCE_FORM_NAME } from 'src/routes/components/forms/DispatchBoardSourceForm';
import { DispatchBoardFlexWrapper } from 'src/routes/components/styled';
import { ButtonContainer, Form } from 'src/routes/components/styled/ExceptionManager';
import { loadExceptions } from 'src/routes/ducks/dispatchBoard';
import { AppState } from 'src/store';
import { activeExceptionConfigurationsSelector } from 'src/vendors/ducks/exceptionConfigurations';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import WasteTypesMultiSelect from './WasteTypesMultiSelect';
import EquipmentTypesMultiSelect from 'src/common/components/EquipmentTypesMultiSelect';
import PickupTypesMultiSelect from 'src/common/components/PickupTypesMultiSelect';
import ReasonCodeTypesMultiSelect from './ReasonCodeTypesMultiSelect';
import ServiceZonesMultiSelect from '../../ServiceZonesMultiSelect';
import SupervisorsMultiSelect from '../../SupervisorsMultiSelect';
import MaterialTypesMultiSelect from '../../MaterialTypesMultiSelect';
import { Supervisor } from 'src/routes/interfaces/Supervisors';

const GridColumn = GridColumnUntyped as any;

interface PropsWithoutReduxForm {
  wasteTypes: TechnicalType[];
  reasonCodeTypes: TechnicalType[];
  supervisorExperienceEnabled: boolean;
  vehicleTypeId: number;
}

interface FormValues {
  date: DateRangeOptionValue;
  exceptionTypes: string[];
  wasteTypes: string[];
  jobPriorityTypes: string[];
  equipmentTypes: string[];
  pickupTypes: string[];
  reasonCodeTypes: string[];
  serviceZoneIds: number[];
  supervisorsIds: number[];
  materialTypes: number[];
}

type ActualFormComponentProps = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const ActualFormComponent: React.FC<ActualFormComponentProps> = ({
  handleSubmit,
  wasteTypes,
  reasonCodeTypes,
  supervisorExperienceEnabled,
  vehicleTypeId,
}) => (
  <Form onSubmit={handleSubmit}>
    <DispatchBoardFlexWrapper>
      <Grid>
        <GridColumn size="4/12">
          <TypedField
            name="date"
            component={DateRangePicker}
            props={{
              label: translate('common.date'),
              hasMarginLeft: 'normal',
              margin: 'no',
              disabledDays: [{ after: TODAY }],
              maxInterval: {
                amount: 6,
                unit: 'days',
              },
            }}
          />
        </GridColumn>

        <GridColumn size="4/12">
          <Field
            name="exceptionTypes"
            component={ExceptionTypeMultiSelect}
            label={translate('dashboard.exceptionType')}
            dropdownProps={{ margin: 'no' }}
          />
        </GridColumn>

        <GridColumn size="4/12">
          <Field
            name="wasteTypes"
            component={WasteTypesMultiSelect}
            wasteTypes={wasteTypes}
            dropdownProps={{ margin: 'no' }}
          />
        </GridColumn>
      </Grid>

      <ButtonContainer>
        <Button type="submit" line color="primary">
          {translate('dispatchBoard.exceptionsDispatcher.filter')}
        </Button>
      </ButtonContainer>
    </DispatchBoardFlexWrapper>
    <DispatchBoardFlexWrapper>
      <Grid>
        <GridColumn size="3/12">
          <Field
            name="jobPriorityTypes"
            component={JobPriorityTypesMultiSelect}
            label={translate('common.jobPriority')}
            props={{
              withPlaceholder: true,
              multiSelectProps: { margin: 'no', isClearable: false, canCheckAll: true },
            }}
          />
        </GridColumn>

        <GridColumn size="3/12">
          <Field
            name="equipmentTypes"
            component={EquipmentTypesMultiSelect}
            label={translate('common.equipmentType')}
            props={{
              withPlaceholder: true,
              multiSelectProps: { margin: 'no', isClearable: false, canCheckAll: true },
            }}
          />
        </GridColumn>

        <GridColumn size="3/12">
          <Field
            name="pickupTypes"
            component={PickupTypesMultiSelect}
            label={translate('routes.pickupType')}
            props={{
              withPlaceholder: true,
              vehicleTypeId,
              multiSelectProps: { margin: 'no', isClearable: false, canCheckAll: true },
            }}
          />
        </GridColumn>
        <GridColumn size="3/12">
          <Field
            name="reasonCodeTypes"
            component={ReasonCodeTypesMultiSelect}
            reasonCodeTypes={reasonCodeTypes}
            dropdownProps={{ margin: 'no' }}
          />
        </GridColumn>
      </Grid>
    </DispatchBoardFlexWrapper>
    <DispatchBoardFlexWrapper>
      <Grid>
        <GridColumn size="3/12">
          <TypedField
            name="serviceZoneIds"
            component={ServiceZonesMultiSelect}
            props={{
              placeholder: translate('routes.allServiceZones'),
              inactiveCheckedByDefault: true,
              withLabel: true,
              multiSelectProps: {
                normalizeValues: Number,
                fitContentWidth: true,
              },
            }}
          />
        </GridColumn>

        {supervisorExperienceEnabled ? (
          <GridColumn size="3/12" padding="no xSmall">
            <TypedField
              name="supervisorsIds"
              component={SupervisorsMultiSelect}
              props={{
                placeholder: translate('routes.allSupervisors'),
                withLabel: true,
                multiSelectProps: {
                  normalizeValues: Number,
                },
              }}
            />
          </GridColumn>
        ) : null}

        <GridColumn size="3/12" padding="no xSmall">
          <TypedField
            name="materialTypes"
            component={MaterialTypesMultiSelect}
            props={{
              includeNoneOption: false,
              includeInactive: true,
              placeholder: translate('vendors.materialTypes.allMaterialTypes'),
              withLabel: true,
              multiSelectProps: {
                normalizeValues: Number,
                fitContentWidth: true,
              },
            }}
          />
        </GridColumn>
      </Grid>
    </DispatchBoardFlexWrapper>
  </Form>
);

const ActualForm = reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'exceptionManagerFilterForm',
  enableReinitialize: true,
})(ActualFormComponent);

const dBoardSourcesFormSelector = formValueSelector(DISPATCH_BOARD_SOURCE_FORM_NAME);

const getMaterialTypeIds = (materialTypes: any) => {
  if (!materialTypes) {
    return [];
  }

  return Object.values(materialTypes)
    .map((item: any) => item.subOptions)
    .filter(item => !!item)
    .map(option => Object.keys(option).filter(key => !!option[key]))
    .flat();
};

const isAllSelectedMaterialTypes = (options: any) => {
  const categories = Object.values(options);
  return categories.reduce((acc, curr: any) => acc && curr.all, true);
};

const getMaterialTypeInitialValues = (materialTypes: any) => {
  const formattedMaterialTypeList = materialTypes.map((item: any) => {
    return (
      item.bulkyItemTypes?.map((bulk: any) => bulk.bulkyItem.id) ||
      item.customBulkyItemTypes?.map((bulk: any) => bulk.id)
    );
  });

  const optionsObject: any = { 0: { all: true } };

  formattedMaterialTypeList.forEach((element: any, index: number) => {
    const subOptions: any = {};
    const key = materialTypes[index].category.id;
    element.forEach((optionId: any) => (subOptions[optionId] = true));
    optionsObject[key] = {
      all: true,
      subOptions,
    };
  });

  return optionsObject;
};

const selectedTypeIdsFormat = (itemType: any[], itemTypeIds: number[]) => {
  if (itemType && itemType.length === itemTypeIds.length) {
    return [];
  }
  return itemTypeIds;
};

interface Props {
  vendorId: number;
  vehicleTypeId: number;
  onSearch: () => void;
}

const DispatchBoardExceptionManagerFilterForm = ({ onSearch, vendorId, vehicleTypeId }: Props) => {
  const dispatch = useDispatch();

  const formValues: any = useSelector(getFormValues('exceptionManagerFilterForm'));
  const defaultDate: Date = useSelector((state: AppState) => dBoardSourcesFormSelector(state, 'date'));

  const exceptionTypesData = useSelector((state: AppState) =>
    activeExceptionConfigurationsSelector(state).map((e: any) => e.id),
  );
  const wasteTypesData: TechnicalType[] = useSelector((state: AppState) => state.common.wasteTypes.wasteTypes);
  const jobPriorityTypesData: TechnicalType[] = useSelector(
    (state: AppState) => state.common.jobPriorityTypes.jobPriorityTypes,
  );
  const equipmentTypesData: TechnicalType[] = useSelector(
    (state: AppState) => state.common.equipmentTypes.equipmentTypes,
  );
  const allVehiclePickupTypesData: any = useSelector((state: AppState) =>
    state.routes.pickupTypes.pickupTypes?.find((item: any) => item.vehicleTypeId === vehicleTypeId)
  );
  const reasonCodeTypesData: TechnicalType[] = useSelector(
    (state: AppState) => state.common.reasonCodeTypes.reasonCodeTypes,
  );
  const serviceZonesData: any[] = useSelector((state: AppState) =>
    (state.routes.serviceZones.serviceZones || [{ id: null }]).filter(zone => !!zone.id),
  );
  const supervisorsData: Supervisor[] = useSelector((state: AppState) => state.routes.supervisors.supervisors);
  const materialTypes = useSelector((state: AppState) => state.vendors.materialTypes.materialTypes);
  const materialTypesData = materialTypes ? getMaterialTypeInitialValues(materialTypes) : [];
  const supervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const exceptionTypeIds: number[] = useMemo(() => {
    return formValues?.exceptionTypes || exceptionTypesData || [];
  }, [formValues?.exceptionTypes, exceptionTypesData]);

  const wasteTypeIds: number[] = useMemo(() => {
    return formValues?.wasteTypes || wasteTypesData.map(w => w.id) || [];
  }, [formValues?.wasteTypes, wasteTypesData]);

  const jobPriorityTypeIds: number[] = useMemo(() => {
    return formValues?.jobPriorityTypes || jobPriorityTypesData.map(w => w.id) || [];
  }, [formValues?.jobPriorityTypes, jobPriorityTypesData]);

  const equipmentTypeIds: number[] = useMemo(() => {
    return formValues?.equipmentTypes || equipmentTypesData.map(w => w.id) || [];
  }, [formValues?.equipmentTypes, equipmentTypesData]);

  const vehiclePickupTypeIds: number[] = useMemo(() => {
    return formValues?.pickupTypes || allVehiclePickupTypesData?.pickupTypes.map((w: any) => w.id) || [];
  }, [formValues?.pickupTypes, allVehiclePickupTypesData]);

  const reasonCodeTypeIds: number[] = useMemo(() => {
    return formValues?.reasonCodeTypes || reasonCodeTypesData.map(w => w.id) || [];
  }, [formValues?.reasonCodeTypes, reasonCodeTypesData]);

  const serviceZoneIds: number[] = useMemo(() => {
    return formValues?.serviceZoneIds || serviceZonesData.map((w: any) => w.id) || [];
  }, [formValues?.serviceZoneIds, serviceZonesData]);

  const supervisorsIds: number[] = useMemo(() => {
    return formValues?.supervisorsIds || supervisorsData.map((w: any) => w.id) || [];
  }, [formValues?.supervisorsIds, supervisorsData]);

  const materialTypeIds: string[] = useMemo(() => {
    if (formValues?.materialTypes && isObject(formValues.materialTypes)) {
      const mTypeIds = getMaterialTypeIds(formValues.materialTypes);
      return mTypeIds;
    }
    return [];
  }, [formValues?.materialTypes]);

  const defaultFromDateWithFallback = formValues?.date?.from || defaultDate || TODAY_FORMATTED;
  const defaultToDateWithFallback = formValues?.date?.to || defaultDate || TODAY_FORMATTED;
  const didMountRef = useRef(false);

  useEffect(() => {
    // set to true if not the 1st render
    if (!didMountRef.current) {
      didMountRef.current = true;

      // load exceptions on 1st render only
      loadExceptions(
        vendorId,
        vehicleTypeId,
        defaultFromDateWithFallback,
        defaultToDateWithFallback,
        selectedTypeIdsFormat(exceptionTypesData, exceptionTypeIds),
        selectedTypeIdsFormat(wasteTypesData, wasteTypeIds),
        selectedTypeIdsFormat(jobPriorityTypesData, jobPriorityTypeIds),
        selectedTypeIdsFormat(equipmentTypesData, equipmentTypeIds),
        selectedTypeIdsFormat(allVehiclePickupTypesData?.pickupTypes, vehiclePickupTypeIds),
        selectedTypeIdsFormat(reasonCodeTypesData, reasonCodeTypeIds),
        selectedTypeIdsFormat(serviceZonesData, serviceZoneIds),
        selectedTypeIdsFormat(supervisorsData, supervisorsIds),
        isAllSelectedMaterialTypes(formValues?.materialTypes || {}) ? [] : materialTypeIds,
      )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultFromDateWithFallback,
    defaultToDateWithFallback,
    dispatch,
    exceptionTypeIds,
    vehicleTypeId,
    vendorId,
    wasteTypeIds,
    jobPriorityTypeIds,
    equipmentTypeIds,
    vehiclePickupTypeIds,
    reasonCodeTypeIds,
    serviceZoneIds,
    supervisorsIds,
    materialTypeIds,
  ]);

  const applyFilters = ({
    date,
    exceptionTypes,
    wasteTypes,
    jobPriorityTypes,
    equipmentTypes,
    pickupTypes,
    reasonCodeTypes,
    serviceZoneIds,
    supervisorsIds,
    materialTypes,
  }: any) => {
    onSearch();
    const materialTypeIds = getMaterialTypeIds(materialTypes);

    loadExceptions(
      vendorId,
      vehicleTypeId,
      date.from,
      date.to,
      selectedTypeIdsFormat(exceptionTypesData, exceptionTypes),
      selectedTypeIdsFormat(wasteTypesData, wasteTypes),
      selectedTypeIdsFormat(jobPriorityTypesData, jobPriorityTypes),
      selectedTypeIdsFormat(equipmentTypesData, equipmentTypes),
      selectedTypeIdsFormat(allVehiclePickupTypesData?.pickupTypes, pickupTypes),
      selectedTypeIdsFormat(reasonCodeTypesData, reasonCodeTypes),
      selectedTypeIdsFormat(serviceZonesData, serviceZoneIds),
      selectedTypeIdsFormat(supervisorsData, supervisorsIds),
      isAllSelectedMaterialTypes(materialTypes || {}) ? [] : materialTypeIds,
    )(dispatch);
  };

  return (
    <ActualForm
      initialValues={{
        date: {
          from: defaultFromDateWithFallback,
          to: defaultToDateWithFallback,
        },
        exceptionTypes: exceptionTypeIds.map(id => id.toString()),
        wasteTypes: wasteTypeIds.map(id => id.toString()),
        jobPriorityTypes: jobPriorityTypeIds.map(id => id.toString()),
        equipmentTypes: equipmentTypeIds.map(id => id.toString()),
        pickupTypes: vehiclePickupTypeIds.map(id => id.toString()),
        reasonCodeTypes: reasonCodeTypeIds.map(id => id.toString()),
        serviceZoneIds: serviceZoneIds,
        supervisorsIds: supervisorsIds,
        materialTypes: materialTypesData,
      }}
      wasteTypes={wasteTypesData}
      reasonCodeTypes={reasonCodeTypesData}
      vehicleTypeId={vehicleTypeId}
      supervisorExperienceEnabled={supervisorExperienceEnabled}
      onSubmit={applyFilters}
    />
  );
};

export default DispatchBoardExceptionManagerFilterForm;