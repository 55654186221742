import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { loadEquipmentTypes as doLoadEquipmentTypes } from '../services/equipmentTypes';
import { TechnicalType } from '../interfaces/TechnicalType';

const START_LOAD = 'common/equipmentTypes/START_LOAD';
const COMPLETE_LOAD = 'common/equipmentTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/equipmentTypes/FAIL_LOAD';
const RESET = 'common/equipmentTypes/RESET';

type State = {
  equipmentTypes: TechnicalType[];
  isLoading: boolean;
};

const initialState: State = {
  equipmentTypes: [],
  isLoading: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentTypes: action.equipmentTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (equipmentTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  equipmentTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadEquipmentTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadEquipmentTypesPromise = doLoadEquipmentTypes();
  loadEquipmentTypesPromise
    .then(equipmentTypes => dispatch(completeLoad(equipmentTypes)))
    .catch(() => dispatch(failLoad()));
  return loadEquipmentTypesPromise;
};

export const resetEquipmentTypes = () => ({
  type: RESET,
});
