import { http } from '../../core/services/http';
import { CollectionPoint } from '../interfaces/CollectionPoint';

// https://stackoverflow.com/questions/7477003/calculating-new-longitude-latitude-from-old-n-meters

// we add 200 additional meters to the NE SW bounds to retrieve collection points outside the route area
const additionalMeters = 200;
const coef = additionalMeters * 0.0000089;

// pi / 180 = 0.018
const r = 0.018;

export const getCollectionPointsByMapBounds = (
  vendorId: number,
  northEast: any,
  southWest: any,
  routeTemplateId: number,
) => {
  const northEastLatitude = northEast.lat() + coef;
  const northEastLongitude = northEast.lng() + coef / Math.cos(northEastLatitude * r);
  const southWestLatitude = southWest.lat() - coef;
  const southWestLongitude = southWest.lng() - coef / Math.cos(southWestLatitude * r);
  return http
    .get<CollectionPoint[]>(`vendors/${vendorId}/routeTemplates/${routeTemplateId}/collectionWaypointsForArea`, {
      params: {
        northEastLatitude,
        northEastLongitude,
        southWestLatitude,
        southWestLongitude,
      },
    })
    .then(response => response.data);
};
