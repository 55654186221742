import { filter, forEach } from 'lodash-es';
import moment, { Moment } from 'moment';

import { AlternativeFleetStreetSegment } from '../interfaces/alterativeFleetOps';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { COMPLEX } from 'src/common/constants/drivingComplexity';
import { DAYS_ID, HOURS_ID, WEEKS_ID } from 'src/vendors/constants/streetSegmentAgingIntervals';
import { multiWordOrSearch } from 'src/core/services/search';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { RouteSegment } from 'src/routes/interfaces/RouteSegment';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import store from 'src/store';

function isValidStatusCombination(forwardStatus?: number, reverseStatus?: number) {
  const validStatusCombinations: any = {
    [IN_PROGRESS]: [IN_PROGRESS, SCHEDULED, COMPLETED],
    [SCHEDULED]: [IN_PROGRESS, COMPLETED],
    [COMPLETED]: [IN_PROGRESS, SCHEDULED],
  };

  return forwardStatus && validStatusCombinations[forwardStatus]?.includes(reverseStatus);
}

const getLastPassType = (mostRecentPassDate: Moment | string, value: any) => {
  const vendor = store.getState().vendors.vendor.vendor as undefined | Vendor;
  return moment(vendor?.currentVendorTime).diff(moment(mostRecentPassDate), value);
};

const getLastPassValue = (
  timeMeasurementTypeId: number,
  lastPassInHours: number,
  lastPassInDays: number,
  lastPassInWeeks: number,
  lastPassInMonths: number,
) =>
  timeMeasurementTypeId === HOURS_ID
    ? lastPassInHours
    : timeMeasurementTypeId === DAYS_ID
    ? lastPassInDays
    : timeMeasurementTypeId === WEEKS_ID
    ? lastPassInWeeks
    : lastPassInMonths;

const filterDashboardSegments = (
  vehicleTypeId: number,
  segments: AlternativeFleetStreetSegment[],
  selectedLastActivityIds: number[],
  pickupStatusTypeIds: number[],
  filterMode: 'lastActivity' | 'pickupStatus',
) => {
  const snowSettings = store.getState().vendors.snowPlowSettings.snowPlowSettings;
  const sweeperSettings = store.getState().vendors.streetSweepingSettings.streetSweepingSettings;

  if (filterMode === 'lastActivity') {
    if (selectedLastActivityIds.length > 0) {
      // the configuration
      const selectedConfigurationsSnow = filter(snowSettings?.displayConfiguration?.segmentConfiguration, segment =>
        selectedLastActivityIds.includes(segment.id),
      );
      const selectedConfigurationsSweeper = filter(sweeperSettings?.segmentColorSettings, segment =>
        selectedLastActivityIds.includes(segment.id),
      );

      const settingsToUse = vehicleTypeId === SNOW_PLOW_ID ? selectedConfigurationsSnow : selectedConfigurationsSweeper;

      const hasSelectedAnOpenInterval = settingsToUse.some(
        setting => setting.streetSegmentAgingInterval.maxValue === null,
      );

      // based on the lastPass date and the configuration, we can filter the segments
      const filteredSegments = filter(segments, segment => {
        // segment has no last pass date and no open interval selected
        if (!segment.lastForwardPassDateTime && !segment.lastReversePassDateTime && !hasSelectedAnOpenInterval)
          return false;

        let mostRecentPassDate;

        if (segment.lastForwardPassDateTime && segment.lastReversePassDateTime)
          mostRecentPassDate = moment(segment.lastForwardPassDateTime).isAfter(segment.lastReversePassDateTime)
            ? segment.lastForwardPassDateTime
            : segment.lastReversePassDateTime;
        else
          mostRecentPassDate =
            segment.lastForwardPassDateTime || segment.lastReversePassDateTime || moment().subtract(1, 'year'); // if no last pass date, we set it to a year ago

        const lastPassInHours = getLastPassType(mostRecentPassDate, 'hours');
        const lastPassInDays = getLastPassType(mostRecentPassDate, 'days');
        const lastPassInWeeks = getLastPassType(mostRecentPassDate, 'weeks');
        const lastPassInMonths = getLastPassType(mostRecentPassDate, 'months');

        let isInInterval = false;

        forEach(settingsToUse, setting => {
          const {
            streetSegmentAgingInterval: {
              minValue,
              maxValue,
              timeMeasurementType: { id: timeMeasurementTypeId },
            },
          } = setting;

          const lastPassValue = getLastPassValue(
            timeMeasurementTypeId,
            lastPassInHours,
            lastPassInDays,
            lastPassInWeeks,
            lastPassInMonths,
          );

          if (maxValue) {
            if (lastPassValue >= minValue && lastPassValue < maxValue) {
              isInInterval = true;
            }
          } else if (lastPassValue >= minValue) {
            isInInterval = true;
          }
        });

        return isInInterval;
      });

      return filteredSegments;
    } else return [];
  } else {
    const isDriverExperienceComplex =
      (vehicleTypeId === SNOW_PLOW_ID ? snowSettings : sweeperSettings)?.drivingComplexityType?.id === COMPLEX;

    if (isDriverExperienceComplex ? pickupStatusTypeIds.length === 3 : pickupStatusTypeIds.length === 2)
      return segments;

    if (!pickupStatusTypeIds.length) return [];

    return filter(segments, segment => {
      const { forwardStatusId, reverseStatusId, isOneWay } = segment;

      for (const pickupStatusTypeId of pickupStatusTypeIds) {
        switch (pickupStatusTypeId) {
          case COMPLETED:
            if (
              (isOneWay && forwardStatusId === COMPLETED) ||
              (!isOneWay && forwardStatusId === COMPLETED && reverseStatusId === COMPLETED)
            )
              return true;
            break;

          case SCHEDULED:
            if (
              (isOneWay && forwardStatusId === SCHEDULED) ||
              (!isOneWay && forwardStatusId === SCHEDULED && reverseStatusId === SCHEDULED)
            )
              return true;
            break;

          case IN_PROGRESS:
            if (
              (isOneWay && forwardStatusId === IN_PROGRESS) ||
              (!isOneWay && isValidStatusCombination(forwardStatusId, reverseStatusId))
            )
              return true;
            break;

          default:
            return false;
        }
      }
    }) as AlternativeFleetStreetSegment[];
  }
};

const filterSegmentsPredicate = (searchTerm?: string, pickupStatusIds?: number[]) => (routeSegment: RouteSegment) =>
  (!pickupStatusIds || !pickupStatusIds.length || pickupStatusIds.includes(routeSegment.statusId)) &&
  (!searchTerm || multiWordOrSearch(routeSegment.name, searchTerm));

const getFilteredNewSegments = (newSegments: RouteLocation[], searchTerm?: string, pickupStatusIds?: number[]) =>
  newSegments.filter(
    newSegment =>
      (searchTerm && newSegment.name ? multiWordOrSearch(newSegment.name, searchTerm) : newSegment) &&
      (!!pickupStatusIds?.length && newSegment.pickupStatusTypeId
        ? pickupStatusIds.includes(newSegment.pickupStatusTypeId)
        : newSegment),
  );

export { filterDashboardSegments, getLastPassValue, getLastPassType, filterSegmentsPredicate, getFilteredNewSegments };
