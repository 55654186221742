import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  saveAddNewWorkOrder as doSaveAddNewWorkOrder,
  submitChangeRequest as doSubmitChangeRequest,
} from '../services/addNewWorkOrder';

// Actions
const START_LOAD = 'customers/addNewWorkOrder/START_LOAD';
const COMPLETE_LOAD = 'customers/addNewWorkOrder/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/addNewWorkOrder/FAIL_LOAD';
const START_SAVE = 'customers/addNewWorkOrder/START_SAVE';
const COMPLETE_SAVE = 'customers/addNewWorkOrder/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/addNewWorkOrder/FAIL_SAVE';
const RESET = 'customers/addNewWorkOrder/RESET';

interface State {
  addNewWorkOrderSettings: any;
  dataCorrectionResponse: any;
  isLoading: boolean;
  isSaving: boolean;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  addNewWorkOrderSettings: {},
  dataCorrectionResponse: {},
  isLoading: false,
  isSaving: false,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          addNewWorkOrderSettings: action.addNewWorkOrderSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          addNewWorkOrderSettings: {},
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          dataCorrectionResponse: action.dataCorrectionResponse,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          dataCorrectionResponse: {},
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (addNewWorkOrderSettings: any) => ({
  type: COMPLETE_LOAD,
  addNewWorkOrderSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (dataCorrectionResponse: any) => ({
  type: COMPLETE_SAVE,
  dataCorrectionResponse,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const saveAddNewWorkOrder = (vendorId: any, divisionId: any, formData: any) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const saveAddNewWorkOrderPromise = doSaveAddNewWorkOrder(vendorId, divisionId, formData);
  return saveAddNewWorkOrderPromise.then(setting => dispatch(completeLoad(setting))).catch(() => dispatch(failLoad()));
};

export const submitChangeRequest = (vendorId: any, divisionId: any, formData: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const submitChangeRequestPromise = doSubmitChangeRequest(vendorId, divisionId, formData);
  return submitChangeRequestPromise
    .then(response => dispatch(completeSave(response)))
    .catch(() => dispatch(failSave()));
};

export const resetAddNewWorkOrder = () => ({
  type: RESET,
});
