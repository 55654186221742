import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { BareButtonStyle } from './Button';
import { mapper, sizeMapper } from '../../../utils/styles';
import Icon from '../Icon';

type Props = Partial<{
  align: string;
  centered: boolean;
  margin: string;
  padding: string;
  size: string;
  vertical: boolean;
  width: string;
  withBorder: boolean;
  weight?: string;
  isActive?: boolean;
}>;

export const DetailsListItemDescription = styled.span<Props>`
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no')};
  line-height: 16px;
  color: ${props => props.theme.grayDarker};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px' }, 'medium')};
  word-break: break-word;
  width: ${props => props.width};
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
      display: inline-block;
    `};
  ${props =>
    props.weight &&
    css`
      font-weight: ${() => mapper(props.weight, { light: 300, normal: 400, medium: 500 })};
    `};
`;

export const DetailsListHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  padding: ${props => sizeMapper(props.padding, 'no')};
  ${props =>
    props.withBorder &&
    css`
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `};
`;

export const DetailsListHeaderTitle = styled.span<Props>`
  display: inline-block;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: ${props => mapper(props.size, { small: '14px', medium: '16px', large: '18px' }, 'large')};
  width: 100%;
`;

export const DetailsListItemTitle = styled.span<Props>`
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no')};
  line-height: 16px;
  color: ${props => props.theme.grayDark};
  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.brandDefault};
      font-weight: bold;
    `};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px' }, 'medium')};
`;

export const DetailsList = styled.div<Props>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
    `};

  ${props =>
    props.centered &&
    css`
      justify-content: ${props.vertical ? 'flex-start' : 'center'};
      align-items: ${props.vertical ? 'center' : 'flex-start'};
    `};
`;

export const DetailsListBody = styled.div<Props>`
  display: flex;
  width: 100%;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
`;

interface DetailsListBodySectionProps {
  width?: string;
  flex?: boolean;
  overflow?: string;
  padding?: string;
  justifyStart?: boolean;
  justifyBetween?: boolean;
}

export const DetailsListBodySection = styled.div<DetailsListBodySectionProps>`
  width: ${props => props.width || '100%'};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;

  ${props =>
    props.padding &&
    css`
      padding: ${sizeMapper(props.padding, 'no')};
    `}

  ${props =>
    props.justifyStart &&
    css`
      justify-content: flex-start;
    `}
    
    ${props =>
    props.justifyBetween &&
    css`
      justify-content: space-between;
    `}

  ${props =>
    props.flex &&
    css`
      flex: 1;
    `}

  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
`;

export const DetailsListActions = styled.div<Props>`
  width: 100%;
  text-align: ${props => mapper(props.align, { left: 'left', center: 'center', right: 'right' }, 'right')};
`;

export const DetailsListActionIcon = styled(Icon)`
  vertical-align: top;
  width: 13px;
  height: 13px;
`;

export const DetailsListActionLink = styled(Link)`
  margin-right: 15px;
  line-height: 14px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const DetailsListActionButton = styled(DetailsListActionLink.withComponent('button')).attrs({
  type: 'button',
})<Props>`
  ${BareButtonStyle};
  color: ${props => props.theme.grayDark};
  margin: ${props => sizeMapper(props.margin, 'no')};
`;

export const DetailsListItemLink = styled.a`
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }
`;
