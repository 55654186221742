import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';

const transformDailyReportTypes = (reportTypes: Array<{ technicalName: string }>) =>
  reportTypes.map(reportType => ({
    ...reportType,
    name: translate(createTranslationKey(reportType.technicalName, 'insights.dailyReportTypes')),
  }));

export default transformDailyReportTypes;
