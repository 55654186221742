import styled from 'styled-components';

export const RouteTemplateScheduleNotice = styled.p`
  line-height: 1.5;
  margin: 16px 0;
`;

export const EditPickupLocationsContainer = styled.div`
  margin: auto 0 0 auto;
  white-space: nowrap;
`;

export const RouteTemplateBuilderServiceDetailsWrapper = styled.div`
  span {
    font-size: 9px;
    line-height: 18px;
  }
`;
