import { COMPLETED, IN_PROGRESS } from '../../common/constants';
import { DispatchBoardSimpleRoute } from '../interfaces/DispatchBoardRoute';
import translate from '../../core/services/translate';

export default function routeJobCountFormatter(
  route: DispatchBoardSimpleRoute,
  filteredJobsLength?: number,
  filteredJobsCompletedOrInProgressLength?: number,
) {
  const completedJobs =
    filteredJobsCompletedOrInProgressLength && filteredJobsCompletedOrInProgressLength >= 0
      ? filteredJobsCompletedOrInProgressLength
      : route.numberOfCompletedJobs;
  const totalNumberJobs = filteredJobsLength && filteredJobsLength >= 0 ? filteredJobsLength : route.totalNumberOfJobs;

  if ((route && route.statusId === COMPLETED) || (route && route.statusId === IN_PROGRESS)) {
    return translate(
      route.totalNumberOfJobs === 1 ? 'dispatchBoard.routeJobCountSplit' : 'dispatchBoard.routeJobsCountSplit',
      { actual: completedJobs, total: totalNumberJobs },
    );
  }

  return translate(totalNumberJobs === 1 ? 'dispatchBoard.routeJobCount' : 'dispatchBoard.routeJobsCount', {
    count: totalNumberJobs || 0,
  });
}
