import { GeoFence, GeoFenceJsonList, GeoQueryPayload } from '../ducks/geoFences';
import { http } from '../../core/services/http';
import { LIMIT_PER_PAGE, PAGE } from '../../core/constants';
import transformLoadGeoFences from './transformLoadGeoFences';
import { pick } from 'lodash';
import { transformGeoFenceJson } from 'src/fleet/services/transformGeoFences';

export const doLoadGeoFences = (payload: GeoQueryPayload) =>
  http
    .get(`vendors/${payload.vendorId}/geoFenceList`, {
      params: {
        ...pick(payload, [
          'routeId',
          'routeTemplateId',
          'routeName',
          'vehicleTypeId',
          'geoFenceZoneTypeIds',
          'geoFenceName',
          'sortOrder',
          'geoFenceIdsCSV',
          'routeDate',
        ]),
        page: payload.page || PAGE,
        limit: payload.limit || LIMIT_PER_PAGE,
        sortDirection: payload.sortedBy,
      },
    })
    .then(response => transformLoadGeoFences(response.data));

export const doLoadGeoFenceAlert = (geoFenceId: number, vendorId: number) =>
  http.get('/geoFences/geoFenceSettings', { params: { vendorId, geoFenceId } }).then(response => response.data);

export const doLoadGeoFence = (geoFenceId: number) =>
  http.get(`geoFences/${geoFenceId}`).then(response => {
    let getCoord = null;
    if (response.data.geoFence.geoFenceJson) {
      getCoord = transformGeoFenceJson(response.data.geoFence.geoFenceJson);
    }
    const transformedGeoFence = {
      ...response.data.geoFence,
      geoFenceJson: getCoord,
    };
    return transformedGeoFence;
  });

export const doDeleteGeoFences = (vendorId: number, geoFencesIds: string) =>
  http.delete(`vendors/${vendorId}/deleteGeoFences`, {
    params: { geoFencesIds },
  });

export const doSaveGeoFence = (geoFence: GeoFence, vendorId: number) =>
  http
    .post(`geoFences`, {
      ...geoFence,
      vendorId,
    })
    .then(response => response.data);

export const doSaveGeoFences = (geoFences: GeoFenceJsonList, vendorId: number) =>
  http.put(`vendors/${vendorId}/simpleGeoFenceList`, [...geoFences]).then(response => response.data);

export const doUpdateGeoFence = (geoFence: GeoFence, vendorId: number) =>
  http
    .put(`geoFences/${geoFence.id}`, {
      ...geoFence,
      vendorId,
    })
    .then(response => response.data);

export const doSaveGeoFenceAlert = (geoFenceAlert: any, geoFenceId: number) =>
  http
    .put(`geoFences/${geoFenceId}/geoFenceSettings`, {
      vendorId: geoFenceAlert.vendorId,
      geoFenceSettings: geoFenceAlert,
    })
    .then(response => response.data);

export const doLoadGeoFenceFilterOptions = ({
  vendorId,
  routeId,
  routeTemplateId,
  routeName,
  routeDate,
  vehicleTypeId,
}: {
  vendorId: number;
  routeId?: number;
  routeTemplateId?: number;
  routeName?: string;
  routeDate?: string | Date;
  vehicleTypeId?: number;
}) =>
  http
    .get(`/vendors/${vendorId}/geoFenceMapFilterOptions`, {
      params: {
        routeId,
        routeTemplateId,
        routeName,
        routeDate,
        vehicleTypeId,
      },
    })
    .then(response => response.data);

export const doLoadGeoFenceBasicList = (vendorId: number, geoFenceZoneTypeIds?: string) =>
  http
    .get(`/vendors/${vendorId}/geoFenceBasicList`, {
      params: {
        geoFenceZoneTypeIds,
      },
    })
    .then(response => response.data);
