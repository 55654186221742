import React, { PureComponent, Fragment } from 'react';
import { Field } from 'redux-form';

import { Grid, GridColumn, Container } from '../../../core/components/styled';
import { Modal, Input } from '../../../core/components';
import translate from '../../../core/services/translate';
// import { AcceptOnCallServicesForm } from '../forms';
import { InputMask } from '../../../opportunity/components/styled';

interface Props {
  workOrderIdentifier: any;
  rateCodes: any[];
  onCancel(...args: any[]): any;
}

class DetailsWorkOrderRateModal extends PureComponent<Props> {
  stopPropagation = (event: any) => {
    event.preventDefault();
  };

  render() {
    const { onCancel, workOrderIdentifier, rateCodes } = this.props;

    return (
      <Modal title={translate('payment.rateDetails')} onClose={onCancel} size="small">
        <Grid centered>
          <GridColumn size="8/12">
            <Container>
              {rateCodes.map((fees, index) => (
                <Fragment key={index}>
                  {fees && (
                    <Fragment>
                      <InputMask>$</InputMask>
                      <Field
                        name={`workOrdersInfo.settings${workOrderIdentifier}.rateCodes[${index}].rate`}
                        component={Input}
                        type="number"
                        label={`${fees.rateDescr} (${translate(
                          `opportunity.${fees.uom ? fees.uom.toLowerCase() : ' '}`,
                        )})`}
                        size="small"
                        margin="xSmall"
                        disabled
                      />
                    </Fragment>
                  )}
                </Fragment>
              ))}
            </Container>
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

export default DetailsWorkOrderRateModal;
