import { connect } from 'react-redux';
import { debounce, find, remove, sum } from 'lodash-es';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import React, { Component } from 'react';

import { AppState } from '../../../../store';
import {
  BULKY_PICKUPS_GEO_FENCE_ID,
  GENERAL_GEO_FENCE_ID,
  ROUTE_TEMPLATE_GEO_FENCE_ID,
  STREET_SWEEPER_GEO_FENCE_ID,
} from '../../../constants';
import {
  ButtonLink,
  IconButton,
  IconButtonIcon,
  MapContainer,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Popover,
  Text,
} from '../../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../../core/services/createNotification';
import { createUrl, getQueryParams } from '../../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import {
  deleteGeoFences,
  GeoFence,
  GeoFenceJsonList,
  loadGeoFences,
  resetGeoFences,
  saveGeoFences,
} from '../../../ducks/geoFences';
import { DuckAction, DuckFunction } from 'src/contracts/ducks';
import { GEO_FENCES_CREATE_EDIT_DELETE } from '../../../../account/constants';
import { GeoFenceButtons, GeoFenceFilter, GeoFenceMapIconButton, GeoFencesFilters } from '../../styled/GeoFences';
import { GeoFencesSearchForm } from '../../forms';
import { hasPermissionSelector } from '../../../../account/ducks';
import { isBulkyItemSchedulerFeatureEnabled } from 'src/vendors/ducks/features';
import { LIMIT_PER_PAGE } from '../../../../core/constants';
import {
  MapDragHandle,
  NavigationPrompt,
  Pagination,
  PopoverWrapper,
  Table,
  UnconnectedCheckbox,
} from '../../../../core/components';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { PermissionGuard } from '../../../../account/components';
import { STREET_SWEEPER_ID } from '../../../../fleet/constants';
import { transformGeoFenceJson } from 'src/fleet/services/transformGeoFences';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../../../../fleet/ducks';
import confirm from '../../../../core/services/confirm';
import ConfirmationGeoFence from './components/ConfirmationGeoFence';
import GeoFencesMapGL from './components/mapGL/GeoFencesMapGL';
import GeoFencesPageTableRow from './components/GeoFencesPageTableRow';
import translate from '../../../../core/services/translate';

interface CheckAllServicesProps {
  checkAll: (checked: boolean) => void;
  getChecked: () => boolean;
}

const CheckAllGeoFencesCheckbox: React.SFC<CheckAllServicesProps> = ({ checkAll, getChecked }) => (
  <PopoverWrapper
    triggerButton={
      <UnconnectedCheckbox
        block
        checked={getChecked()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => checkAll(event.target.checked)}
        size="small"
      />
    }
    popoverContent={
      <Popover>
        {translate(getChecked() ? 'routes.geoFences.removeAllFromMap' : 'routes.geoFences.displayAllOnMap')}
      </Popover>
    }
    width="53px"
    size="small"
  />
);

interface Props {
  deleteGeoFences: (vendorId: number, geoFencesIds: string) => Promise<any>;
  geoFences: GeoFence[];
  hasCreateSaveEditGeoFencePermission?: boolean;
  isBulkyItemSchedulerEnabled: boolean;
  isLoading: boolean;
  isSavingGeoFences: boolean;
  loadGeoFences: DuckFunction<typeof loadGeoFences>;
  location: any;
  push: (url: string) => void;
  resetGeoFences: DuckAction<typeof resetGeoFences>;
  saveGeoFences: (geoFences: GeoFenceJsonList, vendorId: number) => Promise<any>;
  total: number;
  totalBulky: number;
  totalGenerals: number;
  totalGeoFences: number;
  totalRouteTemplates: number;
  totalStreetSweepers: number;
  vehicleTypesForVendor: any[];
  vendorId: number;
}

interface State {
  allGeoFencesFilter: boolean;
  bulkyGeoFencesFilter: boolean;
  checkedGeoFences: GeoFence[];
  generalGeoFencesFilter: boolean;
  geoFenceJsonList: GeoFenceJsonList;
  isAllGeoFencesChecked: boolean;
  isHideMapConfirmationModalOpen: boolean;
  isMapHidden: boolean;
  isShowOrHideAllConfirmationModalOpen: boolean;
  isShowOrHideConfirmationModalOpen: boolean;
  isTableExpanded: boolean;
  locationSearchChanged: boolean;
  mapHasChanges: boolean;
  routeGeoFencesFilter: boolean;
  selectedGeoFence?: GeoFence;
  streetSweeperGeoFencesFilter: boolean;
  isEditingGeoFences: boolean;
}

class GeoFencesPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      location: { search },
    } = this.props;
    const { geoFenceZoneTypeIds } = getQueryParams(search);

    this.state = {
      allGeoFencesFilter:
        !geoFenceZoneTypeIds?.toString().includes(BULKY_PICKUPS_GEO_FENCE_ID.toString()) &&
        !geoFenceZoneTypeIds?.toString().includes(GENERAL_GEO_FENCE_ID.toString()) &&
        !geoFenceZoneTypeIds?.toString().includes(ROUTE_TEMPLATE_GEO_FENCE_ID.toString()) &&
        !geoFenceZoneTypeIds?.toString().includes(STREET_SWEEPER_GEO_FENCE_ID.toString()),
      bulkyGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(BULKY_PICKUPS_GEO_FENCE_ID.toString()),
      checkedGeoFences: [],
      generalGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(GENERAL_GEO_FENCE_ID.toString()),
      geoFenceJsonList: [],
      isAllGeoFencesChecked: false,
      isHideMapConfirmationModalOpen: false,
      isMapHidden: false,
      isShowOrHideAllConfirmationModalOpen: false,
      isShowOrHideConfirmationModalOpen: false,
      isTableExpanded: true,
      locationSearchChanged: false,
      mapHasChanges: false,
      routeGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(ROUTE_TEMPLATE_GEO_FENCE_ID.toString()),
      selectedGeoFence: undefined,
      streetSweeperGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(STREET_SWEEPER_GEO_FENCE_ID.toString()),
      isEditingGeoFences: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const {
      loadGeoFences,
      location: { search },
      vendorId,
    } = this.props;

    if (prevProps.location.search !== search) {
      this.setState({
        locationSearchChanged: true,
      });

      const { geoFenceZoneTypeIds, geoFenceName, page, limit, sortOrder, sortedBy } = getQueryParams(search);

      this.setState({
        allGeoFencesFilter:
          !geoFenceZoneTypeIds?.toString().includes(BULKY_PICKUPS_GEO_FENCE_ID.toString()) &&
          !geoFenceZoneTypeIds?.toString().includes(GENERAL_GEO_FENCE_ID.toString()) &&
          !geoFenceZoneTypeIds?.toString().includes(ROUTE_TEMPLATE_GEO_FENCE_ID.toString()) &&
          !geoFenceZoneTypeIds?.toString().includes(STREET_SWEEPER_GEO_FENCE_ID.toString()),
        bulkyGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(BULKY_PICKUPS_GEO_FENCE_ID.toString()),
        generalGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(GENERAL_GEO_FENCE_ID.toString()),
        routeGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(ROUTE_TEMPLATE_GEO_FENCE_ID.toString()),
        streetSweeperGeoFencesFilter: geoFenceZoneTypeIds?.toString().includes(STREET_SWEEPER_GEO_FENCE_ID.toString()),
      });

      let geoFenceZoneTypesIdsFormatted = geoFenceZoneTypeIds || '';
      if (geoFenceZoneTypesIdsFormatted[geoFenceZoneTypesIdsFormatted.length - 1] === ',') {
        geoFenceZoneTypesIdsFormatted = geoFenceZoneTypesIdsFormatted.slice(0, -1);
      }
      loadGeoFences({
        vendorId,
        geoFenceZoneTypeIds:
          geoFenceZoneTypesIdsFormatted ||
          [
            GENERAL_GEO_FENCE_ID,
            STREET_SWEEPER_GEO_FENCE_ID,
            ROUTE_TEMPLATE_GEO_FENCE_ID,
            BULKY_PICKUPS_GEO_FENCE_ID,
          ].join(','),
        geoFenceName,
        page,
        limit,
        sortOrder,
        sortedBy,
      }).then(() => {
        this.clearMap();
      });
    }
  }

  componentWillUnmount(): void {
    this.props.resetGeoFences();
  }

  getTableCells = (checkAll: (checked: boolean) => void, allChecked: boolean, isTableExpanded: boolean) => [
    {
      name: 'selectAll',
      component: isTableExpanded && CheckAllGeoFencesCheckbox,
      componentProps: {
        checkAll: checkAll,
        getChecked: () => allChecked,
      },
      width: '5%',
      align: 'left',
      noPaddingRight: true,
    },
    { name: 'geoFenceName', label: translate('routes.geoFences.geoFenceName'), width: '50%', sortable: true },
    { name: 'geoFenceZoneTypeName', label: translate('routes.geoFences.type'), width: '35%', sortable: true },
    {
      name: 'options',
      label: translate('routes.geoFences.options'),
      width: '10%',
      sortable: false,
      align: 'right',
    },
  ];

  onSearchTermChange = debounce((geoFenceName: string) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;

    push(
      createUrl(pathname, search, {
        geoFenceName,
        page: undefined,
      }),
    );
  }, 500);

  onFilterGeoFence = (zoneTypeId?: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;

    const { geoFenceZoneTypeIds } = getQueryParams(search);
    const zoneTypeIdAsString = zoneTypeId?.toString();
    let newGeoFenceZoneTypes = geoFenceZoneTypeIds?.toString() || '';

    if (geoFenceZoneTypeIds && newGeoFenceZoneTypes?.includes(zoneTypeIdAsString)) {
      newGeoFenceZoneTypes = newGeoFenceZoneTypes?.replace(`${zoneTypeIdAsString},`, '');
    } else if (zoneTypeId) {
      newGeoFenceZoneTypes = newGeoFenceZoneTypes?.concat(`,${zoneTypeIdAsString}`);
    } else {
      newGeoFenceZoneTypes = '';
    }

    push(
      createUrl(pathname, search, {
        geoFenceZoneTypeIds: newGeoFenceZoneTypes,
        page: undefined,
      }),
    );
  };

  onSortOrderChange = (sortOrder: string, sortedBy: 'asc' | 'desc') => {
    const {
      location: { pathname, search },
      push,
    } = this.props;

    push(
      createUrl(pathname, search, {
        sortOrder,
        sortedBy,
      }),
    );
  };

  deleteGeoFence = async (id: number) => {
    const { deleteGeoFences, vendorId, loadGeoFences } = this.props;

    if (!(await confirm(translate('routes.geoFences.alertMessages.confirmDeleteGeoFence')))) {
      return;
    }

    deleteGeoFences(vendorId, id.toString())
      .then(() => {
        createSuccessNotification(translate('routes.geoFences.alertMessages.geoFenceDeleted'));

        const {
          location: { pathname, search },
          push,
        } = this.props;
        const { geoFenceJsonList, checkedGeoFences } = this.state;
        const { geoFenceZoneTypeIds, geoFenceName, limit, page, sortOrder, sortedBy } = getQueryParams(search);

        let geoFenceZoneTypesIdsFormatted = geoFenceZoneTypeIds || '';
        if (geoFenceZoneTypesIdsFormatted[geoFenceZoneTypesIdsFormatted.length - 1] === ',') {
          geoFenceZoneTypesIdsFormatted = geoFenceZoneTypesIdsFormatted.slice(0, -1);
        }

        loadGeoFences({
          vendorId,
          geoFenceZoneTypeIds:
            geoFenceZoneTypesIdsFormatted ||
            [
              GENERAL_GEO_FENCE_ID,
              STREET_SWEEPER_GEO_FENCE_ID,
              ROUTE_TEMPLATE_GEO_FENCE_ID,
              BULKY_PICKUPS_GEO_FENCE_ID,
            ].join(','),
          geoFenceName,
          page,
          limit,
          sortOrder,
          sortedBy,
        }).then(response => {
          const newGeoFenceJsonList = geoFenceJsonList.filter(gF => gF.id !== id);
          const newCheckedGeoFences = checkedGeoFences.filter(gF => gF.id !== id);
          this.setState({
            geoFenceJsonList: newGeoFenceJsonList,
            checkedGeoFences: newCheckedGeoFences,
          });

          const prevPage = Number(page) - 1;
          if (response.geoFences.total <= prevPage * response.geoFences.limit)
            push(
              createUrl(pathname, search, {
                page: prevPage,
              }),
            );
        });
      })
      .catch(() => {
        createErrorNotification(translate('routes.geoFences.alertMessages.geoFenceDeleteError'));
      });
  };

  toggleMapVisibility = () => {
    const { isMapHidden, mapHasChanges } = this.state;

    if (isMapHidden && mapHasChanges) {
      this.setState({
        isHideMapConfirmationModalOpen: true,
      });
    } else {
      this.setState({
        isMapHidden: !this.state.isMapHidden,
      });
    }
  };

  onCancelHideMap = () => {
    this.setState({
      isHideMapConfirmationModalOpen: false,
    });
  };

  onConfirmHideMap = () => {
    this.setState({
      isHideMapConfirmationModalOpen: false,
      isMapHidden: false,
    });
  };

  onCancelShowOrHideGeoFence = () => {
    this.setState({
      isShowOrHideConfirmationModalOpen: false,
    });
  };

  onConfirmShowOrHideGeoFence = () => {
    const { selectedGeoFence } = this.state;

    this.setState({
      isShowOrHideConfirmationModalOpen: false,
    });

    selectedGeoFence && this.showOrHideGeoFence(selectedGeoFence);
  };

  onCancelShowOrHideAllGeoFence = () => {
    this.setState({
      isShowOrHideAllConfirmationModalOpen: false,
    });
  };

  onConfirmShowOrHideAllGeoFence = () => {
    const { isAllGeoFencesChecked } = this.state;

    this.setState({
      isShowOrHideAllConfirmationModalOpen: false,
    });

    this.showOrHideAllGeoFences(isAllGeoFencesChecked);
  };

  toggleTableVisibility = () => {
    this.setState({
      isTableExpanded: !this.state.isTableExpanded,
    });
  };

  clearMap = () => {
    this.setState({
      checkedGeoFences: [],
      geoFenceJsonList: [],
    });
  };

  getMapHasChanges = (mapHasChanges: boolean) => {
    this.setState({
      mapHasChanges,
      locationSearchChanged: false,
    });
  };

  saveGeoFences = (geoFences: GeoFenceJsonList, vendorId: number) => {
    const {
      loadGeoFences,
      location: { search },
      saveGeoFences,
    } = this.props;

    saveGeoFences(geoFences, vendorId)
      .then(() => {
        createSuccessNotification(translate('routes.geoFences.alertMessages.changesSaved'));

        const { geoFenceZoneTypeIds, geoFenceName, page, limit, sortOrder, sortedBy } = getQueryParams(search);
        const { checkedGeoFences, geoFenceJsonList } = this.state;

        loadGeoFences({
          vendorId,
          geoFenceZoneTypeIds:
            geoFenceZoneTypeIds ||
            [
              GENERAL_GEO_FENCE_ID,
              STREET_SWEEPER_GEO_FENCE_ID,
              ROUTE_TEMPLATE_GEO_FENCE_ID,
              BULKY_PICKUPS_GEO_FENCE_ID,
            ].join(','),
          geoFenceName,
          page,
          limit,
          sortOrder,
          sortedBy,
        }).then(response => {
          const loadedGeoFences = response.geoFences.geoFences;

          const checkedGeoFencesWithNewGeoFenceJson = checkedGeoFences.map((checkedGeoFence: GeoFence) => {
            const geoFenceJson = transformGeoFenceJson(
              find(loadedGeoFences, { id: checkedGeoFence.id })?.geoFenceJson || '',
            );
            const json = JSON.parse(geoFenceJson);
            return {
              ...checkedGeoFence,
              geoFenceJson: geoFenceJson,
              geoFenceCoordinates:
                json.geometry.type === 'Polygon' ? [json.geometry.coordinates] : json.geometry.coordinates,
            };
          });
          const newGeoFenceJsonList = geoFenceJsonList.map((gF: any) => {
            const geoFenceJson = transformGeoFenceJson(
              find(checkedGeoFencesWithNewGeoFenceJson, { id: gF.id })?.geoFenceJson || '',
            );
            const json = JSON.parse(geoFenceJson);
            return {
              ...gF,
              geoFenceJson,
              geoFenceCoordinates:
                json.geometry.type === 'Polygon' ? [json.geometry.coordinates] : json.geometry.coordinates,
            };
          });
          this.setState({
            checkedGeoFences: checkedGeoFencesWithNewGeoFenceJson,
            geoFenceJsonList: newGeoFenceJsonList,
            isEditingGeoFences: false,
          });
        });
      })
      .catch(() => {
        createErrorNotification(translate(`routes.geoFences.alertMessages.changesSavedError`));
      });
  };

  getGeoFencesSelectedList = (checkedGeoFences: GeoFence[]) => {
    const geoFencesSelectedList = checkedGeoFences.map((checkedGeoFence: GeoFence) => {
      const json = JSON.parse(checkedGeoFence.geoFenceJson);
      const geoFenceJson = json;

      return {
        ...checkedGeoFence,
        name: checkedGeoFence.geoFenceName,
        geoFenceJson,
        geoFenceCoordinates: json.geometry.type === 'Polygon' ? [json.geometry.coordinates] : json.geometry.coordinates,
      };
    });

    this.setState({
      geoFenceJsonList: geoFencesSelectedList,
      isMapHidden: true,
    });
  };

  showOrHideGeoFence = (geoFence: GeoFence) => {
    const { checkedGeoFences } = this.state;
    let geoFenceExistsInList = false;

    checkedGeoFences.forEach(gF => {
      if (gF.id === geoFence.id) {
        geoFenceExistsInList = true;
      }
    });

    if (geoFenceExistsInList) {
      remove(checkedGeoFences, geoFence);
    } else {
      checkedGeoFences.push(geoFence);
    }

    this.setState(
      {
        checkedGeoFences: [...checkedGeoFences],
      },
      () => {
        this.getGeoFencesSelectedList(checkedGeoFences);
      },
    );
  };

  showOrHideAllGeoFences = (checked: boolean) => {
    const { geoFences } = this.props;

    this.setState(
      {
        checkedGeoFences: checked ? [...geoFences] : [],
      },
      () => {
        this.getGeoFencesSelectedList(this.state.checkedGeoFences);
      },
    );
  };

  getGeoFences = () => {
    const { geoFences } = this.props;
    const { checkedGeoFences } = this.state;

    return geoFences.map(geoFence => ({
      checked: checkedGeoFences.some(gF => gF.id === geoFence.id),
      geoFence,
    }));
  };

  render() {
    const {
      geoFences,
      hasCreateSaveEditGeoFencePermission,
      isBulkyItemSchedulerEnabled,
      isLoading,
      isSavingGeoFences,
      total,
      totalBulky,
      totalGenerals,
      totalGeoFences,
      totalRouteTemplates,
      totalStreetSweepers,
      vehicleTypesForVendor,
    } = this.props;

    const {
      allGeoFencesFilter,
      bulkyGeoFencesFilter,
      checkedGeoFences,
      generalGeoFencesFilter,
      geoFenceJsonList,
      isEditingGeoFences,
      isHideMapConfirmationModalOpen,
      isMapHidden,
      isShowOrHideAllConfirmationModalOpen,
      isShowOrHideConfirmationModalOpen,
      isTableExpanded,
      locationSearchChanged,
      mapHasChanges,
      routeGeoFencesFilter,
      streetSweeperGeoFencesFilter,
    } = this.state;

    const checkAllGeoFences = (checked: boolean) => {
      this.setState({
        isAllGeoFencesChecked: checked,
      });

      if (mapHasChanges && !checked) {
        this.setState({
          isShowOrHideAllConfirmationModalOpen: true,
        });
      } else {
        this.showOrHideAllGeoFences(checked);
      }
    };

    const checkGeoFence = (geoFence: GeoFence, checked: boolean) => {
      this.setState({
        selectedGeoFence: geoFence,
      });

      if (mapHasChanges && geoFenceJsonList.length && checked) {
        this.setState({
          isShowOrHideConfirmationModalOpen: true,
        });
      } else {
        this.showOrHideGeoFence(geoFence);
      }
    };

    const isStreetSweeperVehicleEnabled = vehicleTypesForVendor.filter(
      vehicleTypeForVendor => vehicleTypeForVendor.id === STREET_SWEEPER_ID,
    ).length;

    return (
      <PageContent>
        <PageHeader>
          {!locationSearchChanged && <NavigationPrompt when={mapHasChanges} />}

          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('routes.geoFences.geoFencesTitle')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PermissionGuard permission={GEO_FENCES_CREATE_EDIT_DELETE}>
            <PageActions>
              <ButtonLink
                to="/fleet/geo-fences/create"
                color="primary"
                id="add-geo-fence-button"
                disabled={isEditingGeoFences}
              >
                {translate('routes.geoFences.addGeoFence')}
              </ButtonLink>
            </PageActions>
          </PermissionGuard>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isSavingGeoFences}>
            <PanelSection disableAllContent={isEditingGeoFences} vertical>
              <GeoFencesSearchForm onSearchTermChange={this.onSearchTermChange} />
              <GeoFencesFilters>
                <GeoFenceFilter
                  type="button"
                  isActive={allGeoFencesFilter}
                  disabled={allGeoFencesFilter}
                  onClick={() => this.onFilterGeoFence()}
                >
                  <Text uppercase size="sMedium" weight="medium" margin="no" color="primary">
                    {translate('common.all')}
                  </Text>
                  <Text size="sMedium" margin="xxSmall no no" weight="medium">
                    {totalGeoFences}
                  </Text>
                </GeoFenceFilter>
                <GeoFenceFilter
                  type="button"
                  isActive={generalGeoFencesFilter}
                  onClick={() => this.onFilterGeoFence(GENERAL_GEO_FENCE_ID)}
                >
                  <Text uppercase size="sMedium" weight="medium" margin="no" color="primary">
                    {translate('routes.geoFences.zoneTypes.generalGeoFence')}
                  </Text>
                  <Text size="sMedium" margin="xxSmall no no" weight="medium">
                    {totalGenerals}
                  </Text>
                </GeoFenceFilter>
                <GeoFenceFilter
                  type="button"
                  isActive={routeGeoFencesFilter}
                  onClick={() => this.onFilterGeoFence(ROUTE_TEMPLATE_GEO_FENCE_ID)}
                >
                  <Text uppercase size="sMedium" weight="medium" margin="no" color="primary">
                    {translate('routes.geoFences.zoneTypes.route')}
                  </Text>
                  <Text size="sMedium" margin="xxSmall no no" weight="medium">
                    {totalRouteTemplates}
                  </Text>
                </GeoFenceFilter>
                {!!isStreetSweeperVehicleEnabled && (
                  <GeoFenceFilter
                    type="button"
                    isActive={streetSweeperGeoFencesFilter}
                    onClick={() => this.onFilterGeoFence(STREET_SWEEPER_GEO_FENCE_ID)}
                  >
                    <Text uppercase size="sMedium" weight="medium" margin="no" color="primary">
                      {translate('routes.geoFences.zoneTypes.streetSweeper')}
                    </Text>
                    <Text size="sMedium" margin="xxSmall no no" weight="medium">
                      {totalStreetSweepers}
                    </Text>
                  </GeoFenceFilter>
                )}
                {!!isBulkyItemSchedulerEnabled && (
                  <GeoFenceFilter
                    type="button"
                    isActive={bulkyGeoFencesFilter}
                    onClick={() => this.onFilterGeoFence(BULKY_PICKUPS_GEO_FENCE_ID)}
                  >
                    <Text uppercase size="sMedium" weight="medium" margin="no" color="primary">
                      {translate('routes.geoFences.zoneTypes.bulkyPickups')}
                    </Text>
                    <Text size="sMedium" margin="xxSmall no no" weight="medium">
                      {totalBulky}
                    </Text>
                  </GeoFenceFilter>
                )}
                <GeoFenceButtons>
                  <GeoFenceMapIconButton>
                    <IconButton
                      type="submit"
                      size="xSmall"
                      color="secondary"
                      margin="no"
                      onClick={() => this.toggleMapVisibility()}
                    >
                      {translate(isMapHidden ? 'routes.geoFences.hideMap' : 'routes.geoFences.showMap')}
                      <IconButtonIcon icon="map" color="secondary" size="large" margin="no no no xSmall" />
                    </IconButton>
                  </GeoFenceMapIconButton>
                  <GeoFenceMapIconButton>
                    <IconButton
                      type="submit"
                      size="xSmall"
                      color="secondary"
                      margin="medium no no no"
                      onClick={() => this.toggleTableVisibility()}
                    >
                      {translate(isTableExpanded ? 'routes.geoFences.hideTable' : 'routes.geoFences.showTable')}
                      <IconButtonIcon icon="list" color="secondary" size="large" margin="no no no xSmall" />
                    </IconButton>
                  </GeoFenceMapIconButton>
                </GeoFenceButtons>
              </GeoFencesFilters>
            </PanelSection>

            {isMapHidden && (
              <PanelSection>
                <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                  <MapContainer size="large">
                    <GeoFencesMapGL
                      geoFenceJsonList={geoFenceJsonList}
                      isEditingGeoFences={this.state.isEditingGeoFences}
                      setIsEditingGeoFences={() =>
                        this.setState({
                          isEditingGeoFences: !this.state.isEditingGeoFences,
                        })
                      }
                      updateGeoFence={this.saveGeoFences}
                    />
                  </MapContainer>
                </Resizable>
              </PanelSection>
            )}

            {isTableExpanded && (
              <PanelSection disableAllContent={isEditingGeoFences}>
                {!!geoFences.length ? (
                  <Table
                    cells={this.getTableCells(
                      checkAllGeoFences,
                      checkedGeoFences.length === geoFences.length,
                      isTableExpanded,
                    )}
                    rows={this.getGeoFences()}
                    rowComponent={GeoFencesPageTableRow}
                    rowProps={{
                      deleteGeoFence: this.deleteGeoFence,
                      hasCreateSaveEditGeoFencePermission,
                      onCheckChange: checkGeoFence,
                    }}
                    sort={this.onSortOrderChange}
                  />
                ) : (
                  <Message padding="sMedium">{translate('routes.geoFences.noGeoFences')}</Message>
                )}
              </PanelSection>
            )}
          </PanelSectionGroup>

          {isTableExpanded && <Pagination totalResults={total} limitPerPage={LIMIT_PER_PAGE} />}
        </Panel>

        {isHideMapConfirmationModalOpen && (
          <ConfirmationGeoFence
            onCancelClick={this.onCancelHideMap}
            onConfirmClick={this.onConfirmHideMap}
            title={translate('routes.geoFences.hideMapWithoutSaving')}
          />
        )}

        {isShowOrHideConfirmationModalOpen && (
          <ConfirmationGeoFence
            onCancelClick={this.onCancelShowOrHideGeoFence}
            onConfirmClick={this.onConfirmShowOrHideGeoFence}
            title={translate('routes.geoFences.showOrHideGeoFenceWithoutSaving')}
          />
        )}

        {isShowOrHideAllConfirmationModalOpen && (
          <ConfirmationGeoFence
            onCancelClick={this.onCancelShowOrHideAllGeoFence}
            onConfirmClick={this.onConfirmShowOrHideAllGeoFence}
            title={translate('routes.geoFences.showOrHideAllGeoFenceWithoutSaving')}
          />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  geoFences: state.routes.geoFences.geoFences.geoFences || [],
  hasCreateSaveEditGeoFencePermission: hasPermissionSelector(state.account.permissions, GEO_FENCES_CREATE_EDIT_DELETE),
  isBulkyItemSchedulerEnabled: isBulkyItemSchedulerFeatureEnabled(state),
  isLoading: state.routes.geoFences.isLoading || false,
  isSavingGeoFences: state.routes.geoFences.isSavingGeoFences,
  location: state.router.location,
  total: state.routes.geoFences.geoFences.total,
  totalBulky: state.routes.geoFences.geoFences.totalBulky,
  totalGenerals: state.routes.geoFences.geoFences.totalGenerals,
  totalGeoFences: sum([
    state.routes.geoFences.geoFences.totalBulky,
    state.routes.geoFences.geoFences.totalGenerals,
    state.routes.geoFences.geoFences.totalRouteTemplates,
    state.routes.geoFences.geoFences.totalStreetSweepers,
  ]),
  totalRouteTemplates: state.routes.geoFences.geoFences.totalRouteTemplates,
  totalStreetSweepers: state.routes.geoFences.geoFences.totalStreetSweepers,
  vehicleTypesForVendor: (vehicleTypesForVendorWithRoleTypeSelector as any)(state.fleet.vehicleTypesForVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  deleteGeoFences,
  loadGeoFences,
  push,
  saveGeoFences,
  resetGeoFences,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoFencesPage);
