import { useState } from 'react';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { connect, useSelector } from 'react-redux';


import { Button, ButtonSet, PanelSection, Grid, GridColumn, Text } from '../../../core/components/styled';
import { Input, TypedField, Switch } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import {
  isNumber,
  isRequired,
  isRequiredNumber,
  maxLength500,
  maxLength50,
  maxValueNumeric999,
  maxLength512,
} from 'src/utils/services/validator';
import { AppState } from '../../../../src/store';
import EsriSyncValidationModalResolver from '../modals/EsriSyncValidationModalResolver';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';

interface PropsWithoutReduxForm {
  canCreate: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

function EsriSyncForm({ canCreate, change, handleSubmit }: Props) {
  const vendorId = useSelector(currentVendorId);
  const [isEsriValidationModalOpen, toggleIsEsriValidationModalOpen] = useState(false);

  const handleCanCreate = (value: any) => {
    if (!value) {
      change('canUpdate', false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid margin="no no no no">
        <GridColumn size="12/12">
          <TypedField
            name={'baseUrl'}
            validate={[isRequired, maxLength512]}
            component={Input}
            props={{
              label: translate('vendors.esriSync.baseUrl'),
              margin: 'no no medium no',
            }}
          />
        </GridColumn>
      </Grid>
      <Grid margin="no no no no">
        <GridColumn size="10/12">
          <TypedField
            name={'userName'}
            validate={[maxLength50]}
            component={Input}
            props={{
              label: translate('vendors.esriSync.userName'),
              margin: 'no no medium no',
              autoComplete: 'off'
            }}
          />
        </GridColumn>
      </Grid>
      <Grid margin="no no no no">
        <GridColumn size="10/12">
          <TypedField
            name={'password'}
            validate={[maxLength50]}
            component={Input}
            props={{
              label: translate('vendors.esriSync.password'),
              margin: 'no no medium no',
              type: 'password',
              autoComplete: 'off'
            }}
          />
        </GridColumn>
      </Grid>

      <Grid margin="no no no no">
        <GridColumn size="12/12">
          <TypedField
            name={'customerFeatureServiceUrl'}
            validate={[isRequired, maxLength500]}
            component={Input}
            props={{
              label: translate('vendors.esriSync.customerFeatureServiceUrl'),
              margin: 'no no medium no',
            }}
          />
        </GridColumn>
      </Grid>
      <Grid margin="no no no no">
        <GridColumn size="6/12">
          <TypedField
            name={'customerLayer'}
            validate={[isRequired, maxLength50]}
            component={Input}
            props={{
              label: translate('vendors.esriSync.customerLayer'),
              margin: 'no no medium no',
            }}
          />
        </GridColumn>
      </Grid>
      <Grid margin="no no no no">
        <GridColumn size="6/12">
          <TypedField
            name={'batchSize'}
            validate={[isRequiredNumber, maxValueNumeric999, isNumber]}
            component={Input}
            props={{
              label: translate('vendors.esriSync.batchSize'),
              margin: 'no no medium no',
            }}
          />
        </GridColumn>
      </Grid>

      <Grid margin="no no no no">
        <GridColumn size="3/12" verticalAlign="center" padding="no no small">
          <Text size="large" weight="medium">
            {translate('vendors.esriSync.canCreate')}
          </Text>
        </GridColumn>
        <GridColumn size="9/12" verticalAlign="center" padding="no no small">
          <TypedField onChange={handleCanCreate} name={'canCreate'} component={Switch} />
        </GridColumn>
      </Grid>
      <Grid margin="no no no no">
        <GridColumn size="3/12" verticalAlign="center" padding="no no small">
          <Text size="large" weight="medium">
            {translate('vendors.esriSync.canUpdate')}
          </Text>
        </GridColumn>
        <GridColumn size="9/12" verticalAlign="center" padding="no no small">
          <TypedField name={'canUpdate'} props={{ disabled: !canCreate }} component={Switch} />
        </GridColumn>
      </Grid>

      <PanelSection vertical padding="small">
        <Grid centered>
          <GridColumn size="12/12">
            <ButtonSet>
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            </ButtonSet>
            <ButtonSet>
              <Button type="button" onClick={() => toggleIsEsriValidationModalOpen(true)} color="primary">
                {translate('vendors.esriSync.validate')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
        {isEsriValidationModalOpen && (
          <EsriSyncValidationModalResolver
            vendorId={vendorId}
            closeModal={() => toggleIsEsriValidationModalOpen(false)}
          ></EsriSyncValidationModalResolver>
        )}
      </PanelSection>
    </form>
  );
}
const formSelector = formValueSelector('esriSyncForm');

const mapStateToProps = (state: AppState) => ({
  canCreate: formSelector(state, 'canCreate'),
});

export default connect(mapStateToProps)(
  reduxForm({ form: 'esriSyncForm', onSubmitFail: focusFirstInvalidField })(EsriSyncForm),
);
