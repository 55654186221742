import React, { ChangeEvent, PureComponent } from 'react';

import { debounce, map } from 'lodash-es';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ACCOUNT_STATUSES } from 'src/common/constants';
import { MultiSelect, PanelSearch, TypedField } from 'src/core/components';
import { Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { accountStatusFormatText } from 'src/customers/services/accountStatusFormatText';
import customersLocationsFormInitialValuesSelector from 'src/customers/services/customersLocationsFormInitialValuesSelector';
import { AppState } from 'src/store';
import { isRequired } from 'src/utils/services/validator';

interface ComponentProps extends RouteComponentProps {
  onLocationsStatusChange: (locationStatus: string) => void;
  onSearchTermChange: (searchTerm: string) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class CustomerLocationsForm extends PureComponent<Props> {
  onSearchTermChange = debounce((_e, searchTerm: string) => {
    this.props.onSearchTermChange(searchTerm);
  }, 500);

  onLocationsStatusChange = (_event: ChangeEvent<HTMLInputElement>, locationsStatus: string) => {
    this.props.onLocationsStatusChange(locationsStatus.toString());
  };

  render() {
    const locationsStatusOptions = map(ACCOUNT_STATUSES, locationsStatus => ({
      label: locationsStatus.name,
      value: locationsStatus.id,
    }));

    return (
      <form onSubmit={this.props.handleSubmit}>
        <PanelSection padding="xSmall">
          <Grid>
            <GridColumn size="9/12">
              <Field
                component={PanelSearch}
                name="locationSearchTerm"
                onChange={this.onSearchTermChange}
                placeholder={translate('customers.searchLocations')}
              />
            </GridColumn>
            <GridColumn size="3/12">
              <TypedField
                component={MultiSelect}
                name="accountStatusTypeId"
                onChange={this.onLocationsStatusChange}
                validate={[isRequired]}
                props={{
                  formatText: accountStatusFormatText,
                  margin: 'no',
                  normalizeValues: Number,
                  options: locationsStatusOptions,
                  placeholder: translate('common.status'),
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (_state: AppState, ownProps: ComponentProps) => ({
  initialValues: customersLocationsFormInitialValuesSelector(ownProps.location.search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, ComponentProps>({
      enableReinitialize: true,
      form: 'customerLocationsForm',
    })(CustomerLocationsForm),
  ),
);
