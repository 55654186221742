import { filter, map } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import { DAYS_TYPE_ID, HOURS_TYPE_ID, WEEKS_TYPE_ID } from 'src/common/constants';
import { PanelSection, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { SegmentConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { SNOW_SWEEPER_FILTERS_FORM_NAME, SnowSweeperFiltersFormValues } from '../SnowSweeperFiltersForm';
import SnowSweeperFilterItem from './SnowSweeperFilterItem';
import { getNumberOfActiveFilters } from './utils';

interface Props {
  isSnowPlow: boolean;
  isStreetSweeper: boolean;
}

const LastActivityFiltersSection = ({ isSnowPlow, isStreetSweeper }: Props) => {
  const snowPlowSettings = useSelector(state => state.vendors.snowPlowSettings.snowPlowSettings);
  const streetSweepingSettings = useSelector(state => state.vendors.streetSweepingSettings.streetSweepingSettings);
  const { isLoadingSegments } = useSelector(state => state.dashboard.alternativeFleetOps);
  const dispatch = useDispatch();

  const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;

  const configs = useMemo(
    () =>
      filter(
        isSnowPlow
          ? snowPlowSettings?.displayConfiguration?.segmentConfiguration
          : streetSweepingSettings?.segmentColorSettings,
        (configuration: SegmentConfiguration) => !!configuration.enabled,
      ) as SegmentConfiguration[],
    [
      isSnowPlow,
      snowPlowSettings?.displayConfiguration?.segmentConfiguration,
      streetSweepingSettings?.segmentColorSettings,
    ],
  );

  const getLabel = (config: SegmentConfiguration) => {
    const { name, timeMeasurementType } = config.streetSegmentAgingInterval;

    switch (timeMeasurementType.id) {
      case HOURS_TYPE_ID:
        return translate('vendors.streetSweeper.noOfHours', {
          hours: name.replace('h', ''),
        });
      case DAYS_TYPE_ID:
        return translate('vendors.streetSweeper.noOfDays', {
          days: name.replace('d', ''),
        });
      case WEEKS_TYPE_ID:
        return translate('vendors.streetSweeper.noOfWeeks', {
          weeks: name.replace('w', ''),
        });
      default:
        return translate('vendors.streetSweeper.noOfMonths', {
          months: name.replace('m', ''),
        });
    }
  };

  const handleCheckAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const lastActivityTypeIds: any = {
        all: checked,
      };

      map(configs, config => {
        lastActivityTypeIds[`_${config.id}`] = checked;
      });

      dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'lastActivity', lastActivityTypeIds));
    },
    [configs, dispatch],
  );

  const handleCheckAny = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue, prevValue, name) => {
      // uncheck the all checkbox if any of the other checkboxes are unchecked
      if (!newValue) dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'lastActivity.all', false));
      else if (getNumberOfActiveFilters(formValues?.lastActivity) === configs.length - 1)
        dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'lastActivity.all', true));
    },
    [configs.length, dispatch, formValues?.lastActivity],
  );

  return (
    <Box display="block">
      <Text size="xSmall" fontStyle="italic" block margin="xSmall small" color="grayDarker">
        {translate('dashboard.filterKeys.lastActivityMessage')}
      </Text>
      <PanelSection isLoading={isLoadingSegments} display="block">
        <SnowSweeperFilterItem name="lastActivity.all" label={translate('common.all')} onChange={handleCheckAll} />
        {map(configs, config => {
          let label = getLabel(config);

          return (
            <SnowSweeperFilterItem
              name={`lastActivity._${config.id}`}
              label={label}
              key={config.id}
              onChange={handleCheckAny}
              rightContent={
                <Box
                  width={20}
                  height={20}
                  backgroundColor={`rgb(${config.red},${config.green},${config.blue})`}
                  mr={7}
                  borderRadius={3}
                />
              }
            />
          );
        })}
      </PanelSection>
    </Box>
  );
};

export default LastActivityFiltersSection;
