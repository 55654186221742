import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ButtonSet, Button, Grid, GridColumn } from '../../../core/components/styled';
import { Checkbox } from '../../../core/components';
import translate from '../../../core/services/translate';

interface ComponentProps {
  step: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const RouteInstructionsForm: React.FC<Props> = ({ handleSubmit, step }) => (
  <form onSubmit={handleSubmit} noValidate>
    <ButtonSet margin="no no medium no">
      <Button type="submit" color="primary" disabled={step !== 3}>
        {translate('common.ok')}
      </Button>
    </ButtonSet>
    <Grid centered>
      <GridColumn size="8/12">
        <Field
          name="hideRouteSequenceInstructions"
          component={Checkbox}
          disabled={step !== 3}
          label={translate('routes.doNotShowMessageAgain')}
        />
      </GridColumn>
    </Grid>
  </form>
);

export default reduxForm<any, ComponentProps>({
  form: 'orderNumber',
})(RouteInstructionsForm);
