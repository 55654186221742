const CONTAINER_SERVICE_INSIGHT_TYPES = [
  'scheduled',
  'inProgress',
  'serviced',
  'completed',
  'placedOnHold',
  'canceled',
];

export default CONTAINER_SERVICE_INSIGHT_TYPES;
