import { filter, find, groupBy, map, some } from 'lodash-es';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';

import { isSmartCitySelector } from 'src/account/ducks';
import {
  CUSTOMER_SUBTYPES,
  CUSTOMER_SUBTYPE_CONTAINER_YARD,
  CUSTOMER_SUBTYPE_FUELING_STATION,
  CUSTOMER_SUBTYPE_LANDFILL,
  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
  CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
  CUSTOMER_TYPES,
} from 'src/common/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { multiWordAndSearch } from 'src/core/services/search';
import translate from 'src/core/services/translate';
import { DashboardExtraFiltersPanel } from 'src/dashboard/components/forms/dashboardFilterFormSections';
import { WidgetContent } from 'src/dashboard/components/styled';
import { filtersByVehicleSearchTermSelector } from 'src/dashboard/ducks';
import { getActiveCityAlertTypes } from 'src/fleet/components/forms/LocationAndCityAlertsSearchForm';
import { DAILY_ROUTE_GEO_FENCE_ID, GENERAL_GEO_FENCE_ID, GEO_FENCES_ALL_TYPES } from 'src/routes/constants';
import { GeoFenceMapFilterOption } from 'src/routes/ducks/geoFenceFilterOptions';
import { flagLocationStatusSelector } from 'src/vendors/ducks/features';
import { CityAlertSetting } from 'src/vendors/interfaces/CityAlertSettings';

export interface DashboardExtraMapLayersFormValues {
  isGeoFencesLayerOn: boolean;
  isCityInsightsLayerOn: boolean;
  isHaulerLocationsLayerOn: boolean;
  isCityAlertsLayerOn: boolean;
  haulerLocations: {
    searchTerm: string;
    groups: any;
  };
  cityInsights: {
    searchTerm: string;
    groups: any;
  };
  cityAlerts: {
    searchTerm: string;
    groups: any;
  };
  geoFences: {
    searchTerm: string;
    groups: any;
  };
}

interface PropsWithoutReduxForm {
  isGeoFencesOpen: boolean;
  isCityInsightsOpen: boolean;
  isCityAlertsOpen: boolean;
  isHaulerLocationsOpen: boolean;
  isForSnowSweeper?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<DashboardExtraMapLayersFormValues, PropsWithoutReduxForm>;

export const DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME = 'dashboardExtraMapLayers';

const ExtraMapLayersWidgetContent: FC<Props> = ({
  change,
  isCityInsightsOpen,
  isHaulerLocationsOpen,
  isCityAlertsOpen,
  isGeoFencesOpen,
  isForSnowSweeper,
}) => {
  const dispatch = useDispatch();

  const thisFormValues = useSelector(
    getFormValues(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME),
  ) as DashboardExtraMapLayersFormValues;

  const geoFenceMapFilterOptions = useSelector(
    state => state.routes.geoFencesFilterOptions.geoFencesFilterOptions.geoFenceMapFilterOptions,
  );
  const { vendorLocationsForMapbox, isLoading: isLoadingVendorLocations } = useSelector(
    state => state.dashboard.vendorLocations,
  );

  const isSmartCity = useSelector(state => isSmartCitySelector(state.account.login, state.vendors.defaultVendor));
  const isFlagLocationActive = useSelector(state => flagLocationStatusSelector(state.vendors.features.features));
  const filters = useSelector(state =>
    filtersByVehicleSearchTermSelector(state.dashboard.filters.filters, thisFormValues),
  ) as any;

  const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
  const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
  const hasCityAlerts = getActiveCityAlertTypes(cityAlertSettings).hasCityAlerts;

  const cityFlaggingIsEnabled = !!filters?.cityInsightTypes.filters.length && isSmartCity && isFlagLocationActive;

  const geoFenceFilters = useMemo(() => {
    const geoFencesGroupedByType = groupBy(
      geoFenceMapFilterOptions,
      (geoFence: GeoFenceMapFilterOption) => geoFence.zoneTypeId,
    );

    return map(
      Object.keys(geoFencesGroupedByType).filter(key =>
        [DAILY_ROUTE_GEO_FENCE_ID, GENERAL_GEO_FENCE_ID].includes(Number(key)),
      ),
      geoFenceZoneTypeId => {
        const geoFences = filter(
          geoFencesGroupedByType[geoFenceZoneTypeId],
          geoFence =>
            !thisFormValues?.geoFences?.searchTerm ||
            multiWordAndSearch(geoFence.name, thisFormValues?.geoFences?.searchTerm),
        );
        const geoFenceZoneTypeName = GEO_FENCES_ALL_TYPES[geoFenceZoneTypeId];
        return {
          name: `geoTypeId_${geoFenceZoneTypeId}`,
          label: geoFenceZoneTypeName.name,
          subFilters: map(geoFences, (option: GeoFenceMapFilterOption) => ({
            name: `geoId_${option.id}`,
            label: option.name,
          })) as any,
        };
      },
    );
  }, [geoFenceMapFilterOptions, thisFormValues?.geoFences?.searchTerm]);

  const haulerLocationsFilters = useMemo(() => {
    const locationGroupedByType = groupBy(vendorLocationsForMapbox, location => location.customerTypeId);
    return map(Object.keys(locationGroupedByType), locationTypeId => {
      const locations = locationGroupedByType[locationTypeId];

      const locationsGroupedBySubType = groupBy(locations, location => location.customerSubType);
      const locationTypeName = CUSTOMER_TYPES[locationTypeId];
      return {
        name: `locationTypeId_${locationTypeId}`,
        label: locationTypeName.name,
        subFilters: map(
          filter(
            Object.keys(locationsGroupedBySubType),
            subType =>
              isForSnowSweeper
                ? ![CUSTOMER_SUBTYPE_LANDFILL, CUSTOMER_SUBTYPE_CONTAINER_YARD].includes(
                    locationsGroupedBySubType[subType][0].customerSubTypeId,
                  )
                : true, // hide landfills and container yards for street sweeping & snow plow
          ),
          locationSubType => {
            const subTypeId = locationsGroupedBySubType[locationSubType][0].customerSubTypeId;
            const locationSubTypeName = find(CUSTOMER_SUBTYPES, { id: subTypeId })?.name || '';
            return {
              name: `locationSubTypeId_${subTypeId}`,
              label: locationSubTypeName,
              subFilters:
                isForSnowSweeper &&
                [
                  CUSTOMER_SUBTYPE_FUELING_STATION,
                  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
                  CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
                ].includes(locationsGroupedBySubType[locationSubType][0].customerSubTypeId)
                  ? (map(
                      filter(
                        locationsGroupedBySubType[locationSubType],
                        l =>
                          !thisFormValues?.haulerLocations?.searchTerm ||
                          multiWordAndSearch(l.locationName, thisFormValues?.haulerLocations?.searchTerm),
                      ),
                      location => {
                        return {
                          name: `locationId_${location.id}`,
                          label: location.locationName,
                        };
                      },
                    ) as any) // show locations for fueling stations, salt/chemical filling stations, and water filling stations for snow plow & street sweeping
                  : null,
            };
          },
        ) as any,
      };
    });
  }, [isForSnowSweeper, vendorLocationsForMapbox, thisFormValues?.haulerLocations?.searchTerm]);

  const cityInsightsFilters = useMemo(() => {
    const cityInsightsFilters = [];
    if (cityFlaggingIsEnabled && filters.cityInsightTypes) {
      cityInsightsFilters.push({
        name: 'cityInsightTypes',
        label: translate('routes.filters.allCityInsights'),
        subFilters: map(filters.cityInsightTypes.filters, (option: any) => {
          const insightType = option.formName.split('.').pop();
          return {
            name: insightType,
            label: translate(option.label),
          };
        }),
      });
    }

    return cityInsightsFilters;
  }, [cityFlaggingIsEnabled, filters.cityInsightTypes]);

  const cityAlertsFilters = useMemo(() => {
    const cityAlertsFilters = [];
    if (hasCityAlerts) {
      cityAlertsFilters.push({
        name: 'cityAlertTypes',
        label: translate('routes.filters.allCityAlerts'),
        subFilters: map(activeCityAlertTypes, (option: CityAlertSetting) => {
          const alertType = option.cityAlertType;
          return {
            name: `alertType_${alertType.id}`,
            label: alertType.name,
          };
        }),
      });
    }
    return cityAlertsFilters;
  }, [activeCityAlertTypes, hasCityAlerts]);

  useEffect(() => {
    let isGeoFenceSelected = false;
    let isHaulerLocationSelected = false;
    let isCityInsightSelected = false;
    let isCityAlertSelected = false;

    if (thisFormValues?.geoFences?.groups)
      isGeoFenceSelected = some(thisFormValues?.geoFences.groups, group => {
        return some(group.groupFilters, value => value);
      });

    if (thisFormValues?.haulerLocations?.groups)
      isHaulerLocationSelected = some(thisFormValues?.haulerLocations.groups, group => {
        return some(group?.groupFilters, filter => {
          if (filter === true) return true;
          return some(filter.groupFilters, value => value);
        });
      });

    if (thisFormValues?.cityInsights?.groups)
      isCityInsightSelected = some(thisFormValues.cityInsights.groups, group => {
        return some(group.groupFilters, value => value);
      });

    if (thisFormValues?.cityAlerts?.groups)
      isCityAlertSelected = some(thisFormValues.cityAlerts.groups, group => {
        return some(group.groupFilters, value => value);
      });

    if (isGeoFenceSelected !== thisFormValues?.isGeoFencesLayerOn)
      dispatch(change('isGeoFencesLayerOn', isGeoFenceSelected));

    if (isHaulerLocationSelected !== thisFormValues?.isHaulerLocationsLayerOn)
      dispatch(change('isHaulerLocationsLayerOn', isHaulerLocationSelected));

    if (isCityInsightSelected !== thisFormValues?.isCityInsightsLayerOn)
      dispatch(change('isCityInsightsLayerOn', isCityInsightSelected));

    if (isCityAlertSelected !== thisFormValues?.isCityAlertsLayerOn)
      dispatch(change('isCityAlertsLayerOn', isCityAlertSelected));
  }, [
    thisFormValues?.geoFences?.groups,
    thisFormValues?.haulerLocations?.groups,
    thisFormValues?.cityInsights?.groups,
    thisFormValues?.isGeoFencesLayerOn,
    thisFormValues?.isHaulerLocationsLayerOn,
    thisFormValues?.isCityInsightsLayerOn,
    dispatch,
    change,
    thisFormValues?.cityAlerts?.groups,
    thisFormValues?.isCityAlertsLayerOn,
  ]);

  return (
    <WidgetContent className="widgetContent">
      {isGeoFencesOpen && (
        <DashboardExtraFiltersPanel
          title={translate('tooltips.geoFences')}
          customNoDataMessage={translate('dashboard.filterKeys.noGeoFencesConfigured')}
          name="geoFences"
          icon="pinWithCircle"
          filters={geoFenceFilters}
          formName={DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME}
          toggleName="isGeoFencesLayerOn"
          isSearchable
        />
      )}
      {isCityInsightsOpen && (
        <DashboardExtraFiltersPanel
          title={translate('tooltips.cityInsights')}
          name="cityInsights"
          icon="cityInsightsFilters"
          filters={cityInsightsFilters}
          formName={DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME}
          toggleName="isCityInsightsLayerOn"
        />
      )}

      {isCityAlertsOpen && (
        <DashboardExtraFiltersPanel
          title={translate('tooltips.cityAlerts')}
          name="cityAlerts"
          icon="alert"
          filters={cityAlertsFilters}
          formName={DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME}
          toggleName="isCityAlertsLayerOn"
        />
      )}

      {isHaulerLocationsOpen && (
        <DashboardExtraFiltersPanel
          title={translate('tooltips.haulerLocations')}
          name="haulerLocations"
          icon="geoFencesFilters"
          filters={haulerLocationsFilters}
          formName={DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME}
          isLoading={isLoadingVendorLocations}
          toggleName="isHaulerLocationsLayerOn"
          isSearchable={isForSnowSweeper}
        />
      )}
    </WidgetContent>
  );
};

export default reduxForm<DashboardExtraMapLayersFormValues, PropsWithoutReduxForm>({
  form: DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME,
  onSubmit: () => {},
  initialValues: {
    isGeoFencesLayerOn: false,
    isCityInsightsLayerOn: false,
    isHaulerLocationsLayerOn: false,
    isCityAlertsLayerOn: false,
    geoFences: {
      searchTerm: '',
      groups: {},
    },
    cityAlerts: {
      searchTerm: '',
      groups: {},
    },
    cityInsights: {
      searchTerm: '',
      groups: {},
    },
    haulerLocations: {
      searchTerm: '',
      groups: {},
    },
  },
})(ExtraMapLayersWidgetContent);
