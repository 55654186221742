import { AnyAction } from 'redux';
import { AppState } from '../../store';
import { createSelector } from 'reselect';
import { identity, merge } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { Dictionary } from '../../common/interfaces/Dictionary';
import { getRandomHexColor } from '../../utils/randomColor';
import { LEFT, RIGHT } from '../../vendors/constants';
import {
  loadMobileVehicleTrackings as doLoadMobileVehicleTrackings,
  loadRawVehicleTrackings as doLoadRawVehicleTrackings,
  loadVehicleSettings as doLoadVehicleSettings,
} from '../services/routeMapDebug';
import { TRAPEZOID } from '../../fleet/constants';
import { VehicleSettings, VehicleTrackingRaw } from '../components/mapWithTimeline/Interfaces';

// Actions
const START_LOAD = 'routes/routeMapDebug/START_LOAD_RAW_VEHICLE_TRACKINGS';
const COMPLETE_LOAD_RAW_VEHICLE_TRACKINGS = 'routes/routeMapDebug/COMPLETE_LOAD_RAW_VEHICLE_TRACKINGS';
const COMPLETE_LOAD_MOBILE_VEHICLE_TRACKINGS = 'routes/routeMapDebug/COMPLETE_LOAD_MOBILE_VEHICLE_TRACKINGS';
const SHOW_RAW_TRACKINGS = 'routes/routeMapDebug/SHOW_RAW_TRACKINGS';
const SHOW_MOBILE_TRACKINGS = 'routes/routeMapDebug/SHOW_MOBILE_TRACKINGS';
const CHANGE_RAW_TRACKING_VEHICLE_ID = 'routes/routeMapDebug/CHANGE_RAW_TRACKING_VEHICLE_ID';
const CHANGE_MOBILE_TRACKING_VEHICLE_ID = 'routes/routeMapDebug/CHANGE_MOBILE_TRACKING_VEHICLE_ID';

const COMPLETE_LOAD_VEHICLE_SETTINGS = 'routes/routeMapDebug/COMPLETE_LOAD_VEHICLE_SETTINGS';
const SHOW_VEHICLE_CONFIRMATION_POLYGON = 'routes/routeMapDebug/SHOW_VEHICLE_CONFIRMATION_POLYGON';

const FILTER_RAW_TRAPEZOIDS = 'routes/routeMapDebug/FILTER_RAW_TRAPEZOIDS';
const FILTER_MOBILE_TRAPEZOIDS = 'routes/routeMapDebug/FILTER_MOBILE_TRAPEZOIDS';
const CHANGE_VEHICLE_COLOR = 'routes/routeMapDebug/CHANGE_VEHICLE_COLOR';

const RESET = 'routes/routeMapDebug/RESET';

// Initial state
interface RouteMapDebugState {
  isLoading: boolean;
  mobileTrackingsVehicleIds: Dictionary<boolean>;
  mobileVehicleTrackings?: Dictionary<VehicleTrackingRaw[]>;
  mobileVehicleTrackingTrapezoidFilters: Dictionary<number[]>;
  mobileVehicleTrackingTrapezoids: Dictionary<VehicleTrackingRaw[]>;
  rawTrackingsVehicleIds: Dictionary<boolean>;
  rawVehicleTrackings?: Dictionary<VehicleTrackingRaw[]>;
  rawVehicleTrackingTrapezoidFilters: Dictionary<number[]>;
  rawVehicleTrackingTrapezoids: Dictionary<VehicleTrackingRaw[]>;
  showMobileTrackings: boolean;
  showRawTrackings: boolean;
  showVehicleSettings: Dictionary<boolean>;
  vehicleNames: Dictionary<string>;
  vehicleSettings: Dictionary<VehicleSettings>;
  vehicleTrackingColors: Dictionary<string>;
}

const initialState: RouteMapDebugState = {
  isLoading: false,
  mobileTrackingsVehicleIds: {},
  mobileVehicleTrackingTrapezoidFilters: {},
  mobileVehicleTrackingTrapezoids: {},
  rawTrackingsVehicleIds: {},
  rawVehicleTrackingTrapezoidFilters: {},
  rawVehicleTrackingTrapezoids: {},
  showMobileTrackings: false,
  showRawTrackings: false,
  showVehicleSettings: {},
  vehicleNames: {},
  vehicleSettings: {},
  vehicleTrackingColors: {},
};

export const isVehicleConfirmationPolygonSupported = (vehicleSettings?: VehicleSettings) => {
  if (vehicleSettings) {
    const confirmationMode = vehicleSettings.confirmationModes.find(cm => cm.id === vehicleSettings.confirmationModeId);
    if (confirmationMode) {
      if (confirmationMode.id !== TRAPEZOID) {
        return false;
      }
      const polygonSettings = confirmationMode.streetServicingSides.find(
        ss => ss.id === vehicleSettings.streetServicingSideId,
      );
      if (polygonSettings) {
        if (polygonSettings.id === LEFT || polygonSettings.id === RIGHT) return true;
      }
    }
  }
  return false;
};

// Reducer
export const routeMapDebugReducer = (state = initialState, action: AnyAction): RouteMapDebugState => {
  switch (action.type) {
    case RESET:
      return { ...initialState };

    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case SHOW_RAW_TRACKINGS:
      return update(state, {
        $merge: {
          showRawTrackings: !state.showRawTrackings,
          showMobileTrackings: state.showMobileTrackings && !state.showRawTrackings ? false : state.showMobileTrackings,
        },
      });

    case CHANGE_RAW_TRACKING_VEHICLE_ID:
      return update(state, {
        rawTrackingsVehicleIds: {
          $merge: {
            [action.vehicleId]: !state.rawTrackingsVehicleIds[action.vehicleId],
          },
        },
      });

    case CHANGE_VEHICLE_COLOR:
      return update(state, {
        vehicleTrackingColors: {
          $merge: {
            [action.vehicleId]: action.color,
          },
        },
      });

    case CHANGE_MOBILE_TRACKING_VEHICLE_ID:
      return update(state, {
        mobileTrackingsVehicleIds: {
          $merge: {
            [action.vehicleId]: !state.mobileTrackingsVehicleIds[action.vehicleId],
          },
        },
      });

    case COMPLETE_LOAD_VEHICLE_SETTINGS:
      return update(state, {
        vehicleSettings: {
          $merge: {
            [action.vehicleId]: action.vehicleSettings,
          },
        },
        showVehicleSettings: {
          $merge: {
            [action.vehicleId]: isVehicleConfirmationPolygonSupported(action.vehicleSettings),
          },
        },
      });

    case SHOW_VEHICLE_CONFIRMATION_POLYGON:
      return update(state, {
        showVehicleSettings: {
          $merge: {
            [action.vehicleId]: !state.showVehicleSettings[action.vehicleId],
          },
        },
      });

    case SHOW_MOBILE_TRACKINGS:
      return update(state, {
        $merge: {
          showMobileTrackings: !state.showMobileTrackings,
          showRawTrackings: !state.showMobileTrackings && state.showRawTrackings ? false : state.showRawTrackings,
        },
      });

    case FILTER_RAW_TRAPEZOIDS:
      return update(state, {
        rawVehicleTrackingTrapezoidFilters: {
          $merge: {
            [action.vehicleId]: action.filters,
          },
        },
      });

    case FILTER_MOBILE_TRAPEZOIDS:
      return update(state, {
        mobileVehicleTrackingTrapezoidFilters: {
          $merge: {
            [action.vehicleId]: action.filters,
          },
        },
      });

    case COMPLETE_LOAD_RAW_VEHICLE_TRACKINGS: {
      const filterValues: Dictionary<boolean> = {};
      const colors: Dictionary<string> = { ...state.vehicleTrackingColors };
      Object.keys(action.rawVehicleTrackings).forEach(k => {
        filterValues[k] = true;
        if (!colors[k]) {
          colors[k] = getRandomHexColor();
        }
      });

      const rawVehicleTrackingTrapezoidFilters: Dictionary<number[]> = {};
      Object.keys(action.rawVehicleTrackingTrapezoids).forEach(
        k => (rawVehicleTrackingTrapezoidFilters[k] = [0, action.rawVehicleTrackingTrapezoids[k].length]),
      );

      return update(state, {
        $merge: {
          isLoading: false,
          rawTrackingsVehicleIds: filterValues,
          rawVehicleTrackings: action.rawVehicleTrackings,
          vehicleNames: merge(state.vehicleNames, action.vehicleNames),
          rawVehicleTrackingTrapezoids: action.rawVehicleTrackingTrapezoids,
          rawVehicleTrackingTrapezoidFilters,
          showMobileTrackings: false,
          showRawTrackings: true,
          vehicleTrackingColors: colors,
        },
      });
    }

    case COMPLETE_LOAD_MOBILE_VEHICLE_TRACKINGS: {
      const filterValues: Dictionary<boolean> = {};
      const colors: Dictionary<string> = { ...state.vehicleTrackingColors };
      Object.keys(action.mobileVehicleTrackings).forEach(k => {
        filterValues[k] = true;
        if (!colors[k]) {
          colors[k] = getRandomHexColor();
        }
      });

      const mobileVehicleTrackingTrapezoidFilters: Dictionary<number[]> = {};
      Object.keys(action.mobileVehicleTrackingTrapezoids).forEach(
        k => (mobileVehicleTrackingTrapezoidFilters[k] = [0, action.mobileVehicleTrackingTrapezoids[k].length]),
      );

      return update(state, {
        $merge: {
          isLoading: false,
          mobileTrackingsVehicleIds: filterValues,
          mobileVehicleTrackings: action.mobileVehicleTrackings,
          mobileVehicleTrackingTrapezoids: action.mobileVehicleTrackingTrapezoids,
          vehicleNames: action.vehicleNames,
          mobileVehicleTrackingTrapezoidFilters,
          showMobileTrackings: true,
          showRawTrackings: false,
          vehicleTrackingColors: colors,
        },
      });
    }

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const showMobileVehicleTrackingsAction = () => ({
  type: SHOW_MOBILE_TRACKINGS,
});

const showRawVehicleTrackingsAction = () => ({
  type: SHOW_RAW_TRACKINGS,
});

const changeRawVehicleTrackingsVehicleId = (vehicleId: string) => ({
  type: CHANGE_RAW_TRACKING_VEHICLE_ID,
  vehicleId,
});

const changeMobileVehicleTrackingsVehicleId = (vehicleId: string) => ({
  type: CHANGE_MOBILE_TRACKING_VEHICLE_ID,
  vehicleId,
});

const completeLoadRawVehicleTrackings = (
  rawVehicleTrackings: Dictionary<VehicleTrackingRaw[]>,
  rawVehicleTrackingTrapezoids: Dictionary<VehicleTrackingRaw[]>,
  vehicleNames: Dictionary<string>,
) => ({
  type: COMPLETE_LOAD_RAW_VEHICLE_TRACKINGS,
  rawVehicleTrackings,
  rawVehicleTrackingTrapezoids,
  vehicleNames,
});

const completeLoadMobileVehicleTrackings = (
  mobileVehicleTrackings: Dictionary<VehicleTrackingRaw[]>,
  mobileVehicleTrackingTrapezoids: Dictionary<VehicleTrackingRaw[]>,
  vehicleNames: Dictionary<string>,
) => ({
  type: COMPLETE_LOAD_MOBILE_VEHICLE_TRACKINGS,
  mobileVehicleTrackings,
  mobileVehicleTrackingTrapezoids,
  vehicleNames,
});

const completeLoadVehicleSettings = (vehicleSettings: VehicleSettings, vehicleId: string) => ({
  type: COMPLETE_LOAD_VEHICLE_SETTINGS,
  vehicleSettings,
  vehicleId,
});

const showVehiclePolygonAction = (vehicleId: string) => ({
  type: SHOW_VEHICLE_CONFIRMATION_POLYGON,
  vehicleId,
});

const filterRawTrapezoids = (vehicleId: string, filters: number[]) => ({
  type: FILTER_RAW_TRAPEZOIDS,
  vehicleId,
  filters,
});

const filterMobileTrapezoids = (vehicleId: string, filters: number[]) => ({
  type: FILTER_MOBILE_TRAPEZOIDS,
  vehicleId,
  filters,
});

const changeVehicleColor = (vehicleId: string, color: string) => ({
  type: CHANGE_VEHICLE_COLOR,
  vehicleId,
  color,
});

type Dispatch = ThunkDispatch<RouteMapDebugState, any, AnyAction>;

export const filterRawTrackingsByVehicleId = (vehicleId: string) => (dispatch: Dispatch) => {
  dispatch(changeRawVehicleTrackingsVehicleId(vehicleId));
};

export const resetRouteMapDebug = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET });
};

export const filterMobileTrackingsByVehicleId = (vehicleId: string) => (dispatch: Dispatch) => {
  dispatch(changeMobileVehicleTrackingsVehicleId(vehicleId));
};

export const loadRawVehicleTrackings = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const rawVehicleTrackingsPromise = doLoadRawVehicleTrackings(routeId);
  rawVehicleTrackingsPromise.then(({ vehicleTrackings, trapezoids, vehicleNames }) =>
    dispatch(completeLoadRawVehicleTrackings(vehicleTrackings, trapezoids, vehicleNames)),
  );
  return rawVehicleTrackingsPromise;
};

export const loadMobileVehicleTrackings = (routeId: number, vehicleId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const mobileVehicleTrackingsPromise = doLoadMobileVehicleTrackings(routeId, vehicleId);
  mobileVehicleTrackingsPromise.then(({ vehicleTrackings, trapezoids, vehicleNames }) =>
    dispatch(completeLoadMobileVehicleTrackings(vehicleTrackings, trapezoids, vehicleNames)),
  );
  return mobileVehicleTrackingsPromise;
};

export const loadVehicleSettings = (vehicleId: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const vehicleSettingsPromise = doLoadVehicleSettings(vehicleId);
  vehicleSettingsPromise.then(vs => dispatch(completeLoadVehicleSettings(vs, vehicleId)));
  return vehicleSettingsPromise;
};

export const showVehiclePolygon = (vehicleId: string) => (dispatch: Dispatch) => {
  dispatch(showVehiclePolygonAction(vehicleId));
};

export const changeRawTrapezoidFilters = (vehicleId: string, filters: number[]) => (dispatch: Dispatch) => {
  dispatch(filterRawTrapezoids(vehicleId, filters));
};

export const changeMobileTrapezoidFilters = (vehicleId: string, filters: number[]) => (dispatch: Dispatch) => {
  dispatch(filterMobileTrapezoids(vehicleId, filters));
};

export const changeDebugVehicleColor = (vehicleId: string, color: string) => (dispatch: Dispatch) => {
  dispatch(changeVehicleColor(vehicleId, color));
};

export const showRawVehicleTrackings = () => (dispatch: Dispatch) => {
  dispatch(showRawVehicleTrackingsAction());
};

export const showMobileVehicleTrackings = () => (dispatch: Dispatch) => {
  dispatch(showMobileVehicleTrackingsAction());
};

export const rawVehicleTrackingsSelector = createSelector(
  (state: AppState) => state.routes.routeMapDebug.rawVehicleTrackings,
  identity,
);

export const mobileVehicleTrackingsSelector = createSelector(
  (state: AppState) => state.routes.routeMapDebug.mobileVehicleTrackings,
  identity,
);
