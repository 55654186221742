import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  loadRateCodeImportUploadedFilesStatus,
  loadAccountingCodeImportUploadedFilesStatus,
  loadRateImportUploadedFilesStatus,
} from 'src/finance/ducks';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RateManagerImportModal from './RateManagerImportModal';

interface Props {
  closeModal: () => void;
  isAccountingCodeImport?: boolean;
  isRateCodeImport?: boolean;
  isRateImport?: boolean;
}

export const RateManagerImportModalResolver: React.FC<Props> = ({
  closeModal,
  isAccountingCodeImport,
  isRateCodeImport,
  isRateImport,
}: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies = [];

    isRateCodeImport && dependencies.push(loadRateCodeImportUploadedFilesStatus(vendorId)(dispatch));
    isAccountingCodeImport && dependencies.push(loadAccountingCodeImportUploadedFilesStatus(vendorId)(dispatch));
    isRateImport && dependencies.push(loadRateImportUploadedFilesStatus(vendorId)(dispatch));

    return Promise.all(dependencies);
  }, [dispatch, isRateCodeImport, isAccountingCodeImport, isRateImport, vendorId]);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={RateManagerImportModal}
      successProps={{ closeModal, isAccountingCodeImport, isRateCodeImport, isRateImport }}
    />
  );
};
