import { useDispatch } from 'react-redux';
import { PageLoadingOverlay } from 'src/common/components/styled';

import { Resolver } from 'src/core/components';
import {
  createErrorNotificationIncludingTechnicalMessage,
  createSuccessNotification,
} from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { loadEmailRecipients } from 'src/finance/ducks/sendBill';
import { sendBill } from 'src/finance/services/sendBill';
import SendBillModal from './SendBillModal';

interface Props {
  closeModal: any;
  onSendBill?: any;
  billInfo: {
    status: string;
    siteAddress: string;
    siteName: string;
    customerName: string;
    billId: number;
  };
}

interface FormValues {
  emailRecipients: {
    customerName: string;
    customerEmail: string;
  }[];
}

const SendBillModalResolver = ({ closeModal, onSendBill, billInfo }: Props) => {
  const dispatch = useDispatch();

  const loadDependencies = () => {
    const dependencies: Promise<unknown>[] = [loadEmailRecipients(billInfo.billId)(dispatch)];
    return Promise.all(dependencies);
  };

  const handleSubmit = (formValues: FormValues) => {
    const emailsList = formValues.emailRecipients.map(recipient => recipient.customerEmail);
    sendBill(billInfo.billId, emailsList)
      .then(() => {
        createSuccessNotification(translate('finance.sendBillConfirmation'));
        closeModal();
      })
      .catch(err => {
        createErrorNotificationIncludingTechnicalMessage(err, translate('finance.sendBillError'));
      });
  };

  return (
    <Resolver
      successComponent={SendBillModal}
      loadingComponent={PageLoadingOverlay}
      successProps={{
        onCancel: closeModal,
        onSendBill: handleSubmit,
        billInfo,
      }}
      resolve={loadDependencies}
    />
  );
};

export default SendBillModalResolver;
