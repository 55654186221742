import React from 'react';

import {
  BREAKTIME,
  DRIVING_TO_FROM_FACILITY,
  DRIVING_TO_DUMP_DEBRIS,
  OFF_ROUTE,
} from 'src/common/constants/serviceConfiguration';
import { TableHead, TableHeadCell } from '../../../core/components/styled';
import { CheckboxGroup, TypedField } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props {
  isStreetSweeperRoute?: boolean;
}

const NonServiceSubTypes: React.FC<Props> = ({ isStreetSweeperRoute }) => {
  const items = [
    {
      value: BREAKTIME,
      label: translate('vendors.serviceConfiguration.breaktime'),
    },
    {
      value: DRIVING_TO_FROM_FACILITY,
      label: translate('vendors.serviceConfiguration.drivingToFromFacility'),
    },
    {
      value: OFF_ROUTE,
      label: translate('vendors.serviceConfiguration.offRoute'),
    },
  ];
  if (isStreetSweeperRoute)
    items.splice(1, 0, {
      value: DRIVING_TO_DUMP_DEBRIS,
      label: translate('vendors.serviceConfiguration.drivingToDumpDebris'),
    });
  return (
    <>
      <TableHead>
        <TableHeadCell>{translate('vendors.nonServiceSubTypes')}</TableHeadCell>
      </TableHead>

      <TypedField
        component={CheckboxGroup}
        name="nonServiceSubTypes"
        props={{
          padding: 'sMedium xSmall',
          formGroupProps: {
            margin: 'no',
            flex: true,
            justifyContent: true,
          },
          items: items,
        }}
      />
    </>
  );
};

export default NonServiceSubTypes;
