import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { FleetMaintenanceForm } from '../forms';
import { FleetMaintenance } from '../../interfaces/FleetMaintenance';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveFleetMaintenance, resetFleetMaintenance } from '../../ducks';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  fleetMaintenance?: FleetMaintenance;
  isLoading: boolean;
  isSaving: boolean;
  resetFleetMaintenance: DuckAction<typeof resetFleetMaintenance>;
  saveFleetMaintenance: DuckFunction<typeof saveFleetMaintenance>;
  vendorId: number;
}

class FleetMaintenanceModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetFleetMaintenance();
  }

  onSubmit = (fleetMaintenance: any) => {
    const { vendorId, saveFleetMaintenance, closeModal } = this.props;

    saveFleetMaintenance(vendorId, fleetMaintenance)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.fleetMaintenanceConfigurationSaved'));
        closeModal(true);
      })
      .catch(({ code }) => {
        createErrorNotification(
          code
            ? translate(createTranslationKey(code, 'vendors.alertMessages'))
            : translate('vendors.alertMessages.fleetMaintenanceConfigurationError'),
        );
      });
  };

  closeModal = (pristine: boolean) => {
    const { closeModal } = this.props;
    closeModal(pristine);
  };

  render() {
    const { isLoading, isSaving } = this.props;

    return (
      <Modal size="small" title={translate('vendors.featureCodes.fleetMaintenance')} isLoading={isLoading || isSaving}>
        <FleetMaintenanceForm onSubmit={this.onSubmit} onCancel={this.closeModal} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  fleetMaintenance: state.vendors.fleetMaintenance.fleetMaintenance,
  isLoading: state.vendors.fleetMaintenance.isLoading,
  isSaving: state.vendors.fleetMaintenance.isSaving,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { saveFleetMaintenance, resetFleetMaintenance };

export default connect(mapStateToProps, mapDispatchToProps)(FleetMaintenanceModal);
