import { FC } from 'react';

import { Button, ButtonSet } from 'src/core/components/styled';
import { CITY_ALERT_TYPE_ID, LOCATION_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import translate from 'src/core/services/translate';

interface Props {
  displayAlertsImportModalVisibility: (type: number) => void;
  isCityAlertEnabled?: boolean;
  isLocationAlertEnabled?: boolean;
}

const LocationAndCityAlertsImportAlerts: FC<Props> = ({
  displayAlertsImportModalVisibility,
  isCityAlertEnabled,
  isLocationAlertEnabled,
}) => {
  return (
    <ButtonSet vertical margin="no">
      <>
        {isCityAlertEnabled && (
          <Button
            onClick={() => displayAlertsImportModalVisibility(CITY_ALERT_TYPE_ID)}
            color="primary"
            id="import-city-alerts-button"
          >
            {translate('vendors.cityAlerts.importCityAlerts')}
          </Button>
        )}

        {isLocationAlertEnabled && (
          <Button
            onClick={() => displayAlertsImportModalVisibility(LOCATION_ALERT_TYPE_ID)}
            color="primary"
            margin={isCityAlertEnabled ? 'small no no' : 'no'}
            id="import-location-alerts-button"
          >
            {translate('vendors.cityAlerts.importLocationAlerts')}
          </Button>
        )}
      </>
    </ButtonSet>
  );
};

export default LocationAndCityAlertsImportAlerts;
