import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

export const TRAVEL_PATH_FLAGS_SOURCE = 'travelPathFlagsSource';
export const TRAVEL_PATH_FLAGS_LAYER = 'travelPathFlagsLayer';

interface Props {
  flagsGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point>;
}

const TravelPathBreadcrumbsFlagsGLSource: FC<Props> = ({ flagsGeoJSON }) => {
  return (
    <>
      <Source type="geojson" id={TRAVEL_PATH_FLAGS_SOURCE} data={flagsGeoJSON} tolerance={0.0001}>
        <Layer
          id={TRAVEL_PATH_FLAGS_LAYER}
          type="symbol"
          source={TRAVEL_PATH_FLAGS_SOURCE}
          layout={{
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
            'icon-size': 0.2,
            'icon-anchor': 'bottom-left',
            'icon-offset': [-25, 0],
            'text-field': ['get', 'reportedDateTime'],
            'text-size': 11,
            'text-allow-overlap': true,
            'text-anchor': 'bottom-left',
            'text-offset': [0, -4.8],
          }}
          paint={{
            'icon-opacity': 0.9,
            'icon-halo-color': '#ffffff',
            'icon-halo-width': 1,
            'text-halo-color': '#ffffff',
            'text-halo-width': 5,
          }}
        />
      </Source>
    </>
  );
};

export default memo(TravelPathBreadcrumbsFlagsGLSource);
