import React, { Fragment } from 'react';

import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { Button, ButtonSet, Grid, GridColumn, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { ContainerColorConfiguration } from 'src/vendors/interfaces/ContainerColorConfiguration';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import ContainerColorConfigurationFormFieldArray from './ContainerColorConfigurationFormFieldArray';
import translate from '../../../core/services/translate';

interface ComponentProps {
  binColors: TechnicalType[];
  containerColorConfiguration: ContainerColorConfiguration[];
  onCancel: (pristine: boolean) => void;
  pristine?: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const ContainerColorConfigurationForm: React.FC<Props> = ({
  binColors,
  containerColorConfiguration,
  handleSubmit,
  onCancel,
  pristine,
}) => {
  const tableCellsWidths = ['65%', '35%'];

  return (
    <Fragment>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <form onSubmit={handleSubmit}>
        <Fragment>
          <FieldArray
            name="containerColorConfiguration"
            component={ContainerColorConfigurationFormFieldArray}
            props={{ binColors, containerColorConfiguration, tableCellsWidths }}
          />
          <Grid centered>
            <GridColumn size="5/12">
              <ButtonSet margin="medium no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </Fragment>
      </form>
    </Fragment>
  );
};

export default reduxForm<any, ComponentProps>({
  enableReinitialize: true,
  form: 'containerColorConfigurationForm',
})(ContainerColorConfigurationForm);
