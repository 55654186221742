import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadContainerHistory as doLoadContainerHistory } from '../services/container';

// Actions
const START_LOAD = 'fleet/containerHistory/START_LOAD';
export const COMPLETE_LOAD = 'fleet/containerHistory/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/containerHistory/FAIL_LOAD';
const RESET = 'fleet/containerHistory/RESET';

// Initial state
const initialState = {
  isLoading: false,
  events: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          events: action.events,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          events: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (events: any) => ({
  type: COMPLETE_LOAD,
  events,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainerHistory = (containerId: number, startDate: string, endDate: string) => (
  dispatch: Dispatch,
) => {
  dispatch(startLoad());
  const loadContainerHistoryPromise = doLoadContainerHistory(containerId, startDate, endDate);
  loadContainerHistoryPromise.then(events => dispatch(completeLoad(events))).catch(() => dispatch(failLoad()));
  return loadContainerHistoryPromise;
};

export const resetContainerHistory = () => ({
  type: RESET,
});
