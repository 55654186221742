import { http, httpInsightsReporting } from '../../core/services/http';
import { RoutesDataPayload, RouteStopsPayload } from '../interfaces/routesData';
import { SearchType } from 'src/common/interfaces/SearchType';
import transformLoadGeoFenceIncidents from './transformLoadGeoFenceIncidents';

export const loadRoutesListData = (payload: RoutesDataPayload) =>
  httpInsightsReporting.get('/dashboard/routes/list', { params: payload }).then(response => response.data);

export const loadRoutesVehiclePositions = (payload: RoutesDataPayload) =>
  httpInsightsReporting.get('/dashboard/routes/vehicles', { params: payload }).then(response => response.data);

export const loadAdvancedSearchRoutes = (date: Date | string, searchField: SearchType, searchCriteria: string) =>
  httpInsightsReporting
    .get('/dashboard/routes/advancedSearchList', { params: { date, searchField, searchCriteria } })
    .then(response => response.data);

export const loadRouteInformation = (routeId: number, date: string | Date) =>
  httpInsightsReporting.get(`/dashboard/routes/${routeId}/info`, { params: { date } }).then(response => response.data);

export const loadRouteDriversVehicles = (routeId: number, date: string | Date) =>
  httpInsightsReporting
    .get(`/dashboard/routes/${routeId}/vehicles`, { params: { date } })
    .then(response => response.data);

export const loadRouteStops = (payload: RouteStopsPayload) =>
  httpInsightsReporting
    .get(`/dashboard/routes/${payload.routeId}/stops`, { params: payload })
    .then(response => response.data);

export const loadRouteGeoFenceIncidents = (routeId: number, date: string | Date, vehicleInsightTypes?: string) =>
  httpInsightsReporting
    .get(`/dashboard/routes/${routeId}/geoFenceIncidents`, { params: { date, vehicleInsightTypes } })
    .then(response => transformLoadGeoFenceIncidents(response.data?.geoFenceIncidents));

export const loadRouteVehiclePositions = (routeId: number, date: string | Date, vehicleIds?: string) =>
  httpInsightsReporting
    .get(`/dashboard/routes/${routeId}/vehiclePositions`, { params: { date, vehicleIds } })
    .then(response => response.data);

export const loadRouteVehiclesBreadCrumbs = (routeId: number, date: string | Date, vehicleIds?: string) =>
  httpInsightsReporting
    .get(`/dashboard/routes/${routeId}/breadcrumbs`, { params: { date, vehicleIds } })
    .then(response => response.data);

export const loadRouteHistory = (routeId: number, startDate?: Date | string, endDate?: Date | string) =>
  http
    .get(`/dashboard/routes/${routeId}/routesCreatedFromTheSameTemplate`, {
      params: { startDate, endDate },
    })
    .then(response => response.data);
