import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { requestPasswordReset, resetRequestPasswordReset } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { AccountPage, AccountPanel } from '../styled';
import { RequestPasswordResetForm } from '../forms';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';

interface Props {
  isRequestingPasswordReset: boolean;
  requestPasswordReset: DuckFunction<typeof requestPasswordReset>;
  resetRequestPasswordReset: DuckAction<typeof resetRequestPasswordReset>;
}

class RequestPasswordResetPage extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetRequestPasswordReset();
  }

  onSubmit = ({ email }: { email: string }) => {
    this.props.requestPasswordReset(email);
  };

  render() {
    return (
      <>
        <DocumentTitle>{translate('account.forgotYourPassword')}</DocumentTitle>
        <AccountPage>
          <AccountPanel isLoading={this.props.isRequestingPasswordReset}>
            <RequestPasswordResetForm onSubmit={this.onSubmit} />
          </AccountPanel>
        </AccountPage>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => state.account.requestPasswordReset;

const mapDispatchToProps = {
  requestPasswordReset,
  resetRequestPasswordReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestPasswordResetPage);
