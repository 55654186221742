import { ChangeEvent, useMemo, useState } from 'react';
import { debounce, filter, forEach, map, size, sum } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';

import {
  Button,
  Grid,
  GridColumn,
  ModalFixedFooter,
  PanelSection,
  PanelSectionGroup,
  Text,
} from 'src/core/components/styled';
import { CreateEditPaymentData, OpenBill } from 'src/finance/interfaces/ApplyChecks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { Customer } from 'src/customers/interfaces/Customers';
import {
  DatePicker,
  Dropdown,
  Input,
  InputFile,
  Table,
  TypeAhead,
  TypedField,
  UnconnectedCheckbox,
} from 'src/core/components';
import { INVOICE_PAYMENT_STATUS_PROCESSING_ID } from 'src/finance/constants/invoicePaymentStatuses';
import { isDateValidValidator, isDecimalUpTo2, isGreaterThan0, isRequired } from 'src/utils/services/validator';
import { loadCustomersSimplified } from 'src/customers/ducks';
import { NewPaymentBillsTableRow } from 'src/finance/components/pages/applyChecks/applyChecksPageSections';
import { SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { TableCell } from 'src/core/components/Table';
import { useSelector } from 'src/core/hooks/useSelector';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

export const CREATE_PAYMENT_FORM = 'createPaymentForm';

interface FormValues {
  customerId: number;
  files?: File[];
  paymentDate: StringOrDate;
  paymentMethodId: number;
  paymentNumber: string;
  paymentTotal: number;
  receivedDate: StringOrDate;
  receivedViaId: number;
}

interface ComponentProps {
  onApplyCheck(data: CreateEditPaymentData): void;
  onSaveCheck(data: CreateEditPaymentData): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  isFormDirty?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const CreatePaymentForm = ({ change, onApplyCheck, onSaveCheck, handleSubmit }: Props) => {
  const dispatch = useDispatch();
  const [selectedBillIds, setSelectedBillIds] = useState<number[]>([]);
  const [selectedBillsTotalAmountToApply, setSelectedBillsTotalAmountToApply] = useState<number[]>([]);
  const [filteredOpenBills, setFilteredOpenBills] = useState<OpenBill[]>([]);
  const vendorId = useSelector(currentVendorId);
  const receivedVia = useSelector(state => state.finance.receivedVia.receivedViaOptions);
  const paymentMethods = useSelector(state => state.common.billingMethods.billingMethods);

  const paymentMethodsOptions = map(paymentMethods, paymentMethod => ({
    value: paymentMethod.id,
    label: paymentMethod.name,
  }));

  const receivedViaOptions = map(receivedVia, receivedViaElement => ({
    value: receivedViaElement.id,
    label: receivedViaElement.name,
  }));

  const SINGLE_USE_ACCOUNT = 4;
  const paymentMethodsFiltered = paymentMethodsOptions.filter(option => option.value !== SINGLE_USE_ACCOUNT);

  const { openBills: openBillsData } = useSelector(state => state.finance.openBills);

  const openBills = openBillsData?.bills;
  const currentFormValues: Partial<FormValues> = useSelector(getFormValues(CREATE_PAYMENT_FORM));

  const checkAllBills = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked = false },
    } = e;
    if (checked) {
      let billIds: number[] = [];

      forEach(filteredOpenBills, bill => {
        const billIsProcessing = bill.billStatusId === INVOICE_PAYMENT_STATUS_PROCESSING_ID;
        !billIsProcessing && billIds.push(bill.invoiceId);
      });

      setSelectedBillIds(billIds);
    } else {
      setSelectedBillIds([]);
    }
  };

  const checkBill = (billId: number, e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const updatedSelectedBillIds = [...selectedBillIds];

    const billIdIndex = updatedSelectedBillIds.indexOf(billId);

    if (billIdIndex > -1) {
      updatedSelectedBillIds.splice(billIdIndex, 1);
    } else {
      updatedSelectedBillIds.push(billId);
    }

    setSelectedBillIds(updatedSelectedBillIds);
  };

  const onAmountToApplyChange = (invoiceId: number, amountToApply: number) => {
    const updatedSelectedBillsTotalAmountToApply = [...selectedBillsTotalAmountToApply];
    updatedSelectedBillsTotalAmountToApply[invoiceId] = amountToApply;
    setSelectedBillsTotalAmountToApply(updatedSelectedBillsTotalAmountToApply);
  };

  const newPaymentBillsTableRowTableCellWidths = {
    selectAll: '4%',
    billId: '10%',
    billDate: '12%',
    dueDate: '12%',
    customerInfo: '16%',
    billAmount: '12%',
    remainingBillBalance: '12%',
    paymentStatus: '10%',
    amountToApply: '10%',
  };

  const newPaymentBillsTableRowTableCells: (TableCell & {
    onClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  })[] = [
    {
      name: 'selectAll',
      component: UnconnectedCheckbox,
      componentProps: {
        onChange: checkAllBills,
        checked:
          selectedBillIds.length &&
          selectedBillIds.length ===
            size(filteredOpenBills) -
              filteredOpenBills.filter(bill => bill.billStatusId === INVOICE_PAYMENT_STATUS_PROCESSING_ID)?.length,
        partial:
          0 < selectedBillIds.length &&
          selectedBillIds.length <
            size(filteredOpenBills) -
              filteredOpenBills.filter(bill => bill.billStatusId === INVOICE_PAYMENT_STATUS_PROCESSING_ID)?.length,
      },
      width: newPaymentBillsTableRowTableCellWidths.selectAll,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      onClick: (e: ChangeEvent<HTMLElement>) => e.stopPropagation(),
    },
    {
      name: 'billId',
      label: translate('finance.applyChecks.billId'),
      width: newPaymentBillsTableRowTableCellWidths.billId,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'billDate',
      label: translate('finance.applyChecks.billDate'),
      width: newPaymentBillsTableRowTableCellWidths.billDate,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'dueDate',
      label: translate('finance.applyChecks.dueDate'),
      width: newPaymentBillsTableRowTableCellWidths.dueDate,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'customerInfo',
      label: translate('finance.applyChecks.customerInfo'),
      width: newPaymentBillsTableRowTableCellWidths.customerInfo,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'billAmount',
      label: translate('finance.applyChecks.billAmount'),
      width: newPaymentBillsTableRowTableCellWidths.billAmount,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'remainingBillBalance',
      label: translate('finance.applyChecks.remainingBillBalance'),
      width: newPaymentBillsTableRowTableCellWidths.remainingBillBalance,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'paymentStatus',
      label: translate('finance.applyChecks.paymentStatus'),
      width: newPaymentBillsTableRowTableCellWidths.paymentStatus,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: true,
    },
    {
      name: 'amountToApply',
      label: translate('finance.applyChecks.amountToApply'),
      width: newPaymentBillsTableRowTableCellWidths.amountToApply,
      padding: 'defaultCellVertical xSmall',
      noPaddingRight: true,
      sortable: false,
    },
  ];

  const loadCustomerOptions = debounce((searchTerm, onOptionsLoaded) => {
    const customerTypeIds = undefined;
    const customerStatusTypeIds = undefined;
    const page = undefined;
    const limit = undefined;
    const sortedBy = undefined;

    if (searchTerm.trim().length < 3) return onOptionsLoaded([]);

    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page,
      limit,
      sortedBy,
      searchType: SEARCH_BY_NAME_OR_ADDRESS,
    })(dispatch).then(res => {
      return onOptionsLoaded(
        map(res.customers, (customer: Customer) => ({ value: customer.id, label: customer.name })),
      );
    });
  }, 500);

  const virtualizedPropsPayments = {
    height: Math.min(filteredOpenBills.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const billOptions = map(filteredOpenBills, bill => {
    return {
      ...bill,
      isChecked: selectedBillIds.includes(bill.invoiceId),
    };
  });

  const dataToSubmit = useMemo(() => {
    if (!currentFormValues) return {} as CreateEditPaymentData;
    const {
      customerId,
      files,
      paymentTotal,
      paymentMethodId,
      receivedViaId,
      paymentNumber,
      receivedDate,
      paymentDate,
    } = currentFormValues;

    return {
      customerId,
      files,
      paymentTotal: Number(paymentTotal),
      paymentMethodId,
      receivedViaId,
      paymentNumber,
      receivedDate,
      paymentDate,
      applyPayment: false,
      paymentDetails: map(selectedBillIds, (billId: number, billIdIndex: number) => {
        return {
          customerInvoiceId: billId,
          paymentSubTotal: filter(selectedBillsTotalAmountToApply, (el: number) => Number(el))[billIdIndex],
        };
      }),
    };
  }, [currentFormValues, selectedBillIds, selectedBillsTotalAmountToApply]);

  const resetPaymentsToApply = () => {
    setSelectedBillsTotalAmountToApply([]);
    setSelectedBillIds([]);
    change('amountToApply', []);
  };

  const onCustomerChange = (_: ChangeEvent<HTMLInputElement>, value: number) => {
    let customerOpenBills: OpenBill[] = [];

    if (value) {
      customerOpenBills = filter(openBills, bill => bill?.customerId === value);
    }

    setFilteredOpenBills(customerOpenBills);
    resetPaymentsToApply();
  };

  const saveCheck = () => onSaveCheck(dataToSubmit as CreateEditPaymentData);

  const applyCheck = () => onApplyCheck(dataToSubmit as CreateEditPaymentData);

  const totalAmountToApply = sum(
    map(
      filter(selectedBillsTotalAmountToApply, (_, index) => selectedBillIds.includes(index)),
      amount => amount,
    ),
  );

  return (
    <form noValidate onSubmit={handleSubmit}>
      <PanelSectionGroup padding="small small no small">
        <PanelSection padding="no">
          <Grid multiLine>
            <GridColumn size="6/12">
              <Field
                name="paymentNumber"
                component={Input}
                label={translate('finance.applyChecks.paymentNumber')}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                name="paymentDate"
                component={DatePicker}
                disabledDays={[
                  {
                    before: undefined,
                    after: undefined,
                  },
                ]}
                margin="no"
                label={translate('finance.applyChecks.paymentDate')}
                isClearable
                validate={[isDateValidValidator, isRequired]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                name="paymentTotal"
                component={Input}
                parse={Number}
                label={translate('finance.applyChecks.paymentAmount')}
                type="number"
                validate={[isRequired, isDecimalUpTo2, isGreaterThan0]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                name="receivedDate"
                component={DatePicker}
                disabledDays={[
                  {
                    before: undefined,
                    after: undefined,
                  },
                ]}
                margin="no"
                label={translate('finance.applyChecks.paymentReceivedDate')}
                isClearable
                validate={[isDateValidValidator, isRequired]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                component={TypeAhead}
                name="customerId"
                props={{
                  getOptions: loadCustomerOptions,
                  isClearable: true,
                  label: `${translate('finance.applyChecks.customer')}`,
                }}
                onChange={onCustomerChange}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                component={Dropdown}
                name="paymentMethodId"
                props={{
                  options: paymentMethodsFiltered,
                  isClearable: true,
                  label: `${translate('opportunity.opportunities.paymentMethod')}`,
                }}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                component={Dropdown}
                name="receivedViaId"
                props={{
                  options: receivedViaOptions as any,
                  label: translate('finance.applyChecks.receivedVia'),
                  isClearable: true,
                  margin: 'no',
                }}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <Field
                name="files"
                component={InputFile}
                mode="File"
                multiple
                accept=".doc,.docx,.msg.txt,.csv,.ppt,.xml,.jpg,.png,.pdf,.xls,.xlsx,.zip,.zipx"
              />
            </GridColumn>
          </Grid>
        </PanelSection>
        <Grid>
          <GridColumn size="12/12">
            <Grid padding="xxSmall medium medium no">
              {!!size(billOptions) && (
                <Table
                  cells={newPaymentBillsTableRowTableCells}
                  rowComponent={NewPaymentBillsTableRow}
                  rowProps={{
                    checkBill,
                    onAmountToApplyChange: onAmountToApplyChange,
                    tableCellWidths: newPaymentBillsTableRowTableCellWidths,
                  }}
                  rows={billOptions}
                  scrollMarker
                  virtualized
                  virtualizedProps={virtualizedPropsPayments}
                />
              )}
            </Grid>
          </GridColumn>
        </Grid>
      </PanelSectionGroup>

      <ModalFixedFooter isShadowed>
        <Button type="button" color="primary" onClick={handleSubmit(saveCheck)}>
          <Text size="medium" weight="medium" block>
            {translate('finance.applyChecks.savePayment')}
          </Text>
          <Text size="xSmall" weight="normal">
            {translate('finance.applyChecks.reconcileLater')}
          </Text>
        </Button>
        <Button
          type="button"
          color="primary"
          margin="no small"
          onClick={handleSubmit(applyCheck)}
          disabled={!selectedBillsTotalAmountToApply?.length || totalAmountToApply !== currentFormValues?.paymentTotal}
        >
          <Text size="medium" weight="medium" block>
            {translate('finance.applyChecks.processPayment')}
          </Text>
          <Text size="xSmall" weight="normal">
            ${totalAmountToApply}
          </Text>
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: CREATE_PAYMENT_FORM,
  onSubmitFail: focusFirstInvalidField,
})(CreatePaymentForm);
