import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { loadPermissions, shouldLoadPermissions } from 'src/account/ducks';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import {
  loadEquipmentTypes,
  loadJobPriorityTypes,
  loadReasonCodeTypes,
  loadServiceTypes,
  loadWasteTypes,
} from 'src/common/ducks';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { DELIVERY_UTILITY_ID } from 'src/fleet/constants';
import { loadVehicleTypesForVendor, loadVehicles } from 'src/fleet/ducks';
import { loadGroups, loadServiceZones } from 'src/routes/ducks';
import { loadSupervisors } from 'src/routes/ducks/supervisors';
import { loadPickupTypes } from 'src/routes/ducks/pickupTypes';
import dispatchBoardFormInitialValuesSelector from 'src/routes/services/dispatchBoardFormInitialValuesSelector';
import {
  loadBulkyCategoryTypes,
  loadBulkyItemScheduler,
  loadBulkyItemTypes,
  loadFeatures,
  loadDispatchBoardMaterialTypes,
} from 'src/vendors/ducks';
import { isBulkyItemSchedulerFeatureEnabled, isMaterialTypesFeatureEnabled } from 'src/vendors/ducks/features';
import { currentUserId, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import DispatchBoardPage from './DispatchBoardPage';

const DispatchBoardPageResolver: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);

  const isMaterialTypesEnabled = useSelector(isMaterialTypesFeatureEnabled);
  const isBulkyItemSchedulerEnabled = useSelector(isBulkyItemSchedulerFeatureEnabled);

  const filtersPreferences = useSelector(state => state.common.filters.filters);
  const vehicleTypesForVendor = useSelector(state => state.fleet.vehicleTypesForVendor);
  const { vehicleTypeId } = dispatchBoardFormInitialValuesSelector(
    vehicleTypesForVendor,
    vendorId,
    filtersPreferences,
  ) as unknown as {
    vehicleTypeId: number;
  };

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadFeatures(vendorId)(dispatch),
      loadReasonCodeTypes()(dispatch),
      loadServiceTypes()(dispatch),
      loadServiceZones(vendorId)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      loadVehicles(vendorId)(dispatch),
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadEquipmentTypes()(dispatch),
      loadPickupTypes(vehicleTypeId)(dispatch),

      loadGroups(vendorId)(dispatch),
      loadJobPriorityTypes()(dispatch),
    ];
    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }
    if (userId) {
      dependencies.push(loadFiltersSettings(vendorId, userId)(dispatch));
    }
    if (isMaterialTypesEnabled) {
      dependencies.push(loadDispatchBoardMaterialTypes(vendorId, vehicleTypeId)(dispatch));
    } else if (vehicleTypeId === DELIVERY_UTILITY_ID && isBulkyItemSchedulerEnabled) {
      dependencies.push(
        loadBulkyCategoryTypes()(dispatch),
        loadBulkyItemTypes()(dispatch),
        loadBulkyItemScheduler(vendorId)(dispatch),
      );
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.dispatchBoard')}</DocumentTitle>
      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/dashboard"
        resolve={loadDependencies}
        successComponent={DispatchBoardPage}
      />
    </>
  );
};

export default DispatchBoardPageResolver;
