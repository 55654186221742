import { get } from 'lodash-es';
import moment from 'moment';
import { ROUTES_TRACKER_SEQUENCE_ROUTE } from 'src/account/constants';
import { checkIfSupport, checkIfViewOnly, hasPermission } from 'src/account/utils/permissions';

import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { ROUTE_SEQUENCE_STATUS_ICONS, ROUTE_SEQUENCE_TOOLTIP_COLORS } from 'src/routes/constants';
import { RoutePlannerRouteTemplate } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplate';
import { getTimeDifference, routePlannerRouteBuilderTableCellsWidths } from 'src/routes/utils/routePlanner';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';

interface Props extends RoutePlannerRouteTemplate {
  isChecked: boolean;
  routeSequenceStatus: string;
  jobId: number;
  selectTemplate: (id: number) => void;
  deleteTemplate: (id: number) => void;
  copyTemplate: (id: number) => void;
  scheduleTemplate: (id: number) => void;
  onTableRowClick: (
    id: number,
    routeSequenceStatus: string,
    jobId: number,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => void;
}

const RoutePlannerRouteTemplatesListingTableRow = ({
  description,
  driverName,
  endTime,
  isActive,
  isChecked,
  jobId,
  materialTypeId,
  numberOfStops,
  routeSequenceStatus,
  routeTemplateId,
  routeTemplateName,
  startTime,
  vehicleName,
  vehicleTypeId,
  deleteTemplate,
  scheduleTemplate,
  selectTemplate,
  onTableRowClick,
}: Props) => {
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const wasteTypes = useSelector(state => state.common.wasteTypes.wasteTypes);
  const hasRouteSequencePermission = hasPermission(ROUTES_TRACKER_SEQUENCE_ROUTE);

  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;

  const vehicleTypeName = VEHICLE_TYPE_IDS[vehicleTypeId]?.name;

  const materialType =
    materialTypeId && !isSnowPlowRoute && !isStreetSweeperRoute
      ? wasteTypes.find(wasteType => wasteType.id === materialTypeId)?.name
      : '';

  const isViewOnlyOrSupport = checkIfViewOnly() || checkIfSupport();

  return (
    <TableRow
      height={TABLE_ROW_HEIGHT_SMALL}
      onClick={e => {
        e.stopPropagation();
        onTableRowClick(routeTemplateId, routeSequenceStatus, jobId, false, vehicleTypeId);
      }}
    >
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[0]} padding="xxSmall xxSmall">
        {!isViewOnlyOrSupport && (
          <UnconnectedCheckbox
            name={`selected_${routeTemplateId}`}
            checked={isChecked}
            onChange={(e: any) => {
              e.stopPropagation();
              selectTemplate(routeTemplateId);
            }}
            onClick={e => e.stopPropagation()}
          />
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[1]} padding="xxSmall xxSmall">
        <Text block singleLine title={routeTemplateName} weight="normal">
          {routeTemplateName}
        </Text>
      </TableCell>
      <TableCell width="12%" padding="xxSmall xxSmall">
        {vehicleName && (
          <Box display="block" width="100%">
            <Text block singleLine title={vehicleName} weight="normal">
              {vehicleName}
            </Text>
            {vehicleTypeName && (
              <Text block singleLine title={vehicleTypeName}>
                {vehicleTypeName}
              </Text>
            )}
          </Box>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[3]} padding="xxSmall xxSmall">
        {driverName && (
          <Text block singleLine title={driverName}>
            {driverName}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[4]} padding="xxSmall xxSmall">
        <Text block singleLine title={materialType}>
          {materialType}
        </Text>
      </TableCell>
      <TableCell width="12%" padding="xxSmall xxSmall">
        <Text block singleLine title={description}>
          {description}
        </Text>
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[6]} padding="xxSmall xxSmall">
        {numberOfStops && (
          <Text block singleLine title={numberOfStops.toString()}>
            {numberOfStops}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[7]} padding="xxSmall xxSmall">
        {startTime && (
          <Text block singleLine title={moment(startTime).format(timeFormatWithoutSeconds)}>
            {moment(startTime, 'hh:mm:ss').format(timeFormatWithoutSeconds)}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[8]} padding="xxSmall xxSmall">
        {endTime && (
          <Text block singleLine title={moment(endTime).format(timeFormatWithoutSeconds)}>
            {moment(endTime, 'hh:mm:ss').format(timeFormatWithoutSeconds)}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[9]} padding="xxSmall xxSmall">
        {endTime && startTime && (
          <Text block singleLine title={getTimeDifference(endTime, startTime)}>
            {getTimeDifference(endTime, startTime)}
          </Text>
        )}
      </TableCell>

      <TableCell width="9%" padding="xxSmall xxSmall" align="right">
        {!isViewOnlyOrSupport && (
          <>
            {routeSequencingEnabled && routeSequenceStatus && hasRouteSequencePermission && (
              <TableActionButton>
                <ActionButtonTooltip
                  color={(ROUTE_SEQUENCE_TOOLTIP_COLORS as any)[routeSequenceStatus]}
                  imageSrc={get(ROUTE_SEQUENCE_STATUS_ICONS[routeSequenceStatus], 'icon')}
                  tooltip={`routeSequence${routeSequenceStatus}`}
                />
              </TableActionButton>
            )}
            <TableActionButton
              color="grayDarker"
              onClick={(e: any) => {
                e.stopPropagation();
                onTableRowClick(routeTemplateId, routeSequenceStatus, jobId, true, vehicleTypeId);
              }}
            >
              <ActionButtonTooltip iconSize="sMedium" icon="edit" tooltip="edit" />
            </TableActionButton>
            {isActive && !!numberOfStops && (
              <TableActionButton
                color="grayDarker"
                onClick={e => {
                  e.stopPropagation();
                  scheduleTemplate(routeTemplateId);
                }}
              >
                <ActionButtonTooltip
                  iconSize="sMedium"
                  icon="calendarReload"
                  tooltip="scheduleReoccurringRoutes"
                  customViewBox="0 0 20 20"
                />
              </TableActionButton>
            )}
            <TableActionButton
              color="grayDarker"
              onClick={e => {
                e.stopPropagation();
                deleteTemplate(routeTemplateId);
              }}
            >
              <ActionButtonTooltip iconSize="sMedium" icon="delete" tooltip="delete" />
            </TableActionButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RoutePlannerRouteTemplatesListingTableRow;
