import React, { Fragment } from 'react';
import { InjectedFormProps, WrappedFieldArrayProps } from 'redux-form';

import { PermissionGuard } from '../../../account/components';
import { ROUTES_TRACKER_VIEW_ADD_WEIGHT_TICKET } from '../../../account/constants';
import { Table } from '../../../core/components';
import { Button, ButtonSet, Message } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { ROLL_OFF } from '../../../fleet/constants';
import { WeightTicketsFormTableRow } from '.';
import { DuckFunction } from 'src/contracts/ducks';
import { deleteWeightTicket, loadRoute, saveWeightTicket } from 'src/routes/ducks';

const weightTicketsRollOffTableCells = [
  { name: 'ticketNumber', label: translate('routes.ticketNumber'), width: '10%' },
  { name: 'locationId', label: translate('common.disposalSite'), width: '17%' },
  { name: 'weight', label: translate('common.weight'), width: '9%' },
  { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '14%' },
  { name: 'activityTimeLocal', label: translate('common.timestamp'), width: '13%' },
  { name: 'customer', label: translate('common.customer'), width: '18%' },
  { name: 'images', label: translate('common.image'), width: '8%' },
  { name: 'options', label: translate('common.options'), width: '12%', align: 'right' },
];

const weightTicketsOtherTableCells = [
  { name: 'ticketNumber', label: translate('routes.ticketNumber'), width: '10%' },
  { name: 'locationId', label: translate('common.disposalSite'), width: '34%' },
  { name: 'weight', label: translate('common.weight'), width: '9%' },
  { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '14%' },
  { name: 'activityTimeLocal', label: translate('common.timestamp'), width: '13%' },
  { name: 'images', label: translate('common.image'), width: '8%' },
  { name: 'options', label: translate('common.options'), width: '12%', align: 'right' },
];

interface ComponentProps extends WrappedFieldArrayProps {
  routeId: number;
  routeStatusTypeId: number;
  vehicleTypeTechnicalName: string;
  loadRoute: DuckFunction<typeof loadRoute>;
  saveWeightTicket: DuckFunction<typeof saveWeightTicket>;
  deleteWeightTicket: DuckFunction<typeof deleteWeightTicket>;
  change: any;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const WeightTicketsFormFieldArray: React.FC<Props> = ({
  routeId,
  vehicleTypeTechnicalName,
  loadRoute,
  fields,
  saveWeightTicket,
  deleteWeightTicket,
  change,
}) => {
  const weightTicketsTableRows = fields.map((fieldArrayKey, index, fields) => ({
    fieldArrayKey,
    index,
    fields,
  }));

  const weightTicketsTableCells =
    vehicleTypeTechnicalName === ROLL_OFF ? weightTicketsRollOffTableCells : weightTicketsOtherTableCells;

  return (
    <Fragment>
      {!!weightTicketsTableRows.length && (
        <Table
          cells={weightTicketsTableCells}
          rows={weightTicketsTableRows}
          rowComponent={WeightTicketsFormTableRow}
          rowProps={{
            routeId,
            vehicleTypeTechnicalName,
            loadRoute,
            saveWeightTicket,
            deleteWeightTicket,
            change,
          }}
        />
      )}

      {!weightTicketsTableRows.length && <Message padding="sMedium">{translate('routes.noWeightTickets')}</Message>}

      <ButtonSet margin="sMedium no">
        <PermissionGuard permission={ROUTES_TRACKER_VIEW_ADD_WEIGHT_TICKET}>
          <Button
            id={`route-${routeId}-weight-tickets-add-button`}
            type="button"
            color="primary"
            line
            onClick={() => fields.push({})}
          >
            + {translate('routes.addWeightTicket')}
          </Button>
        </PermissionGuard>
      </ButtonSet>
    </Fragment>
  );
};

export default WeightTicketsFormFieldArray;
