import React, { PureComponent } from 'react';

import { FLEET, STREET_SWEEPER, SNOW_PLOW } from '../../constants/insightsGroupIds';
import { InsightsPageResolver } from '.';

class InsightsFleetPageResolver extends PureComponent {
  render() {
    return <InsightsPageResolver insightGroupIds={[FLEET, STREET_SWEEPER, SNOW_PLOW]} />;
  }
}

export default InsightsFleetPageResolver;
