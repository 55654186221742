import { date, time } from 'src/utils/services/formatter';
import { Modal } from '../../../core/components';
import { ModalSection, Text, Grid, GridColumn } from '../../../core/components/styled';
import { VehicleFuelEfficiencyLogDetail } from 'src/fleet/interfaces/VehicleFuelEfficiencyLog';
import translate from '../../../core/services/translate';
import OverviewMapGL from 'src/haulerProfile/components/OverviewMapGL';
import { MapGLWrapper } from 'src/customers/components/styled';
import { MAP_CITY_ZOOM_IN_BIGGER } from 'src/core/constants/mapOptions';

type Props = {
  closeModal: () => void;
  isImperialMeasurementSystem: boolean;
  vehicleFillUpDetails: VehicleFuelEfficiencyLogDetail;
};

export default function VehicleFillUpDetailsModal({
  closeModal,
  isImperialMeasurementSystem,
  vehicleFillUpDetails,
}: Props) {
  const vehicleFillUpDetailsColumns = [
    {
      id: 'fillUpTimeStamp',
      title: `${translate('common.date')} / ${translate('common.time')}`,
      value: `${date(vehicleFillUpDetails.fillUpTimeStamp)} ${time(vehicleFillUpDetails.fillUpTimeStamp)}`,
    },
    {
      id: 'fillUpLocation',
      title: translate('common.location'),
      value: vehicleFillUpDetails.fillUpLocation,
    },
    {
      id: 'gallonsAdded',
      title: translate(`vehicles.${isImperialMeasurementSystem ? 'gallonsAdded' : 'litersAdded'}`),
      value: vehicleFillUpDetails.gallonsAdded.toFixed(1),
    },
    {
      id: 'odometer',
      title: translate(`vehicles.${isImperialMeasurementSystem ? 'odometerMiles' : 'odometerKilometers'}`),
      value: vehicleFillUpDetails.odometer,
    },
    {
      id: 'distanceTravelled',
      title: translate(
        `vehicles.${isImperialMeasurementSystem ? 'distanceTravelledMiles' : 'distanceTravelledKilometers'}`,
      ),
      value: vehicleFillUpDetails.distanceTravelled.toFixed(0),
    },
    {
      id: 'gallonsUsed',
      title: translate(`vehicles.${isImperialMeasurementSystem ? 'gallonsUsed' : 'litersUsed'}`),
      value: vehicleFillUpDetails.gallonsUsed.toFixed(1),
    },
    {
      id: 'milesPerGallon',
      title: translate(`vehicles.${isImperialMeasurementSystem ? 'milesPerGallon' : 'kilometersPerLitter'}`),
      value: vehicleFillUpDetails.milesPerGallon.toFixed(1),
    },
  ];

  return (
    <Modal size="medium" padding="medium no no" title={translate('vehicles.fillUp')} onClose={closeModal}>
      <MapGLWrapper height={400}>
        {!!vehicleFillUpDetails.fillUpLongitude && !!vehicleFillUpDetails.fillUpLatitude && (
          <OverviewMapGL
            isMarker
            address={[[[vehicleFillUpDetails.fillUpLongitude, vehicleFillUpDetails.fillUpLatitude]]]}
            mapZoom={MAP_CITY_ZOOM_IN_BIGGER}
          />
        )}
      </MapGLWrapper>

      <ModalSection padding="medium">
        {vehicleFillUpDetailsColumns.map(column => (
          <Grid key={column.id} margin="xxSmall no">
            <GridColumn size="4/12">
              <Text block weight="medium">
                {column.title}
              </Text>
            </GridColumn>

            <GridColumn size="8/12">
              <Text block>{column.value}</Text>
            </GridColumn>
          </Grid>
        ))}
      </ModalSection>
    </Modal>
  );
}
