import { Box } from 'src/core/components/styled/Box';
import { Button } from 'src/core/components/styled';

interface Props {
  setIsStrobeImageModalOpen: (isOpen: boolean) => void;
  strobeImageUrl?: string;
}

const StrobeImages = ({ setIsStrobeImageModalOpen, strobeImageUrl }: Props) => {
  return !!strobeImageUrl ? (
    <Box mt={5}>
      <Button margin="no" padding="no" text onClick={() => setIsStrobeImageModalOpen(true)}>
        <img alt="" width="100px" src={strobeImageUrl} />
      </Button>
    </Box>
  ) : (
    <span>-</span>
  );
};

export default StrobeImages;
