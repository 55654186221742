import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadContainerStatuses as doLoadContainerStatuses } from '../services/containerStatuses';
import { TechnicalType } from '../interfaces/TechnicalType';

// Actions
const START_LOAD = 'common/containerStatuses/START_LOAD';
const COMPLETE_LOAD = 'common/containerStatuses/COMPLETE_LOAD';
const FAIL_LOAD = 'common/containerStatuses/FAIL_LOAD';
const RESET = 'common/containerStatuses/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  containerStatuses: TechnicalType[];
} = {
  isLoading: false,
  containerStatuses: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerStatuses: action.containerStatuses,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerStatuses: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerStatuses: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  containerStatuses,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadContainerStatuses = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerStatusesPromise = doLoadContainerStatuses();
  loadContainerStatusesPromise
    .then((containerStatuses: TechnicalType[]) => dispatch(completeLoad(containerStatuses)))
    .catch(() => dispatch(failLoad()));
  return loadContainerStatusesPromise;
};

export const resetContainerStatuses = () => ({
  type: RESET,
});
