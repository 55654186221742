import { mapKeys } from 'lodash-es';

import translate from 'src/core/services/translate';

export const REQUIRED = 1;
export const OPTIONAL = 3;
export const NONE = 4;

export const FLEET_MAINTENANCE_OPTIONS = mapKeys(
  [
    { id: REQUIRED, name: translate('vendors.pictureConfigurationTypes.required') },
    { id: OPTIONAL, name: translate('vendors.pictureConfigurationTypes.optional') },
    { id: NONE, name: translate('vendors.pictureConfigurationTypes.none') },
  ],
  'id',
);
