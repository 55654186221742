import Cookie from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { getExcludeVehicleFilters } from 'src/common/utils/filters';
import translate from 'src/core/services/translate';
import { VehicleTypeForVendorDropdown } from 'src/fleet/components';
import {
  DISPATCH_BOARD_VEHICLE_TYPE_COOKIE,
  DISPATCH_BOARD_VEHICLE_TYPE_COOKIE_EXPIRATION,
} from 'src/routes/constants';
import {
  DISPATCH_BOARD_FORM_NAME,
  DISPATCH_BOARD_VEHICLE_TYPES,
  ON_HOLD_ROUTE_ID,
} from 'src/routes/constants/dispatchBoard';
import { AppState } from 'src/store';
import { DispatchBoardQueryParams } from '../pages/dispatchBoard/DispatchBoardPage';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';

interface PropsWithoutReduxForm {}

interface FormData {
  vehicleTypeId?: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const DispatchBoardForm = (props: Props) => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();

  const excludeVehicleFilters = useSelector((state: AppState) =>
    getExcludeVehicleFilters(state.common.filters.filters),
  );

  const { pathname, search } = useLocation<DispatchBoardQueryParams>();
  const { sourceRouteId: _sourceRouteId } = getQueryParams<DispatchBoardQueryParams>(search);

  const sourceRouteId = _sourceRouteId ? Number(_sourceRouteId) : undefined;

  const onChange = (newVehicleTypeId: any) => {
    Cookie.set(DISPATCH_BOARD_VEHICLE_TYPE_COOKIE, newVehicleTypeId, {
      expires: DISPATCH_BOARD_VEHICLE_TYPE_COOKIE_EXPIRATION,
    });
    if (sourceRouteId === ON_HOLD_ROUTE_ID) {
      dispatch(
        push(
          createUrl(pathname, search, {
            sourceRouteId: undefined,
          }),
        ),
      );
    }
    window.history.pushState(null, '', `?vehicleTypeId=${newVehicleTypeId}`);
  };

  return (
    <form onSubmit={handleSubmit} id="vehicleTypeIdForm">
      <Field
        name="vehicleTypeId"
        component={VehicleTypeForVendorDropdown}
        label={translate('vehicles.vehicleType')}
        acceptedVehicleTypes={DISPATCH_BOARD_VEHICLE_TYPES}
        onChange={onChange}
        excludeVehicleTypes={excludeVehicleFilters}
      />
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: DISPATCH_BOARD_FORM_NAME,
  enableReinitialize: true,
})(DispatchBoardForm);
