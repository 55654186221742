import React from 'react';

import { connect } from 'react-redux';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import { deleteRouteNote, saveRouteNote } from '../../ducks';
import { DuckFunction } from 'src/contracts/ducks';
import { ModalSection } from '../../../core/components/styled';
import { RouteNotesFormFieldArray } from '.';
import routeNotesFormInitialValuesSelector from '../../services/routeNotesFormInitialValuesSelector';

interface ComponentProps {
  change?: any;
  deleteRouteNote: DuckFunction<typeof deleteRouteNote>;
  isDeletingRouteNote: boolean;
  isNewStop?: boolean;
  isReadOnly: boolean;
  isSavingRouteNote: boolean;
  routeId?: number;
  routeLocationId?: number;
  saveRouteNote: DuckFunction<typeof saveRouteNote>;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const RouteNotesForm: React.FC<Props> = ({
  change,
  deleteRouteNote,
  isDeletingRouteNote,
  isNewStop,
  isReadOnly,
  isSavingRouteNote,
  routeId,
  routeLocationId,
  saveRouteNote,
}) => (
  <form>
    <ModalSection padding="no" isLoading={isSavingRouteNote || isDeletingRouteNote}>
      <FieldArray
        change={change}
        component={RouteNotesFormFieldArray as any}
        deleteRouteNote={deleteRouteNote}
        isNewStop={isNewStop}
        isReadOnly={isReadOnly}
        name="routeNotes"
        routeId={routeId}
        routeLocationId={routeLocationId}
        saveRouteNote={saveRouteNote}
      />
    </ModalSection>
  </form>
);

const mapStateToProps = (state: AppState) => ({
  isSavingRouteNote: state.routes.routeLocationNotes.isSavingRouteNote,
  isDeletingRouteNote: state.routes.routeLocationNotes.isDeletingRouteNote,
  initialValues: routeNotesFormInitialValuesSelector(state.routes.routeLocationNotes as any),
});

const mapDispatchToProps = {
  saveRouteNote,
  deleteRouteNote,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    form: 'routeNotes',
  })(RouteNotesForm),
);
