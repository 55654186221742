import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ADMIN = 1;
export const MANAGER = 2;
export const VIEW_ONLY = 3;
export const SUPPORT = 4;

export const USER_ROLES = mapKeys(
  [
    { id: ADMIN, name: translate('vendors.userRoles.admin') },
    { id: MANAGER, name: translate('vendors.userRoles.manager') },
    { id: VIEW_ONLY, name: translate('vendors.userRoles.viewOnly') },
    { id: SUPPORT, name: translate('vendors.userRoles.support') },
  ],
  'id',
);
