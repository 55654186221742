import React from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { isSuperAdminSelector } from '../../../account/ducks';
import { loadVendors, loadAssignedVendors } from '../../../vendors/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import SelectDefaultVendorPage from './SelectDefaultVendorPage';

interface Props {
  isSuperAdmin: boolean;
  loadAssignedVendors: DuckFunction<typeof loadAssignedVendors>;
  loadVendors: DuckFunction<typeof loadVendors>;
  userId?: string;
}

const SelectDefaultVendorPageResolver: React.FC<Props> = ({
  isSuperAdmin,
  loadAssignedVendors,
  loadVendors,
  userId,
}) => {
  const loadDependencies = () => {
    const dependencies = isSuperAdmin ? [loadVendors()] : [loadAssignedVendors(undefined, userId)];
    return Promise.all(dependencies);
  };

  return (
    <Resolver successComponent={SelectDefaultVendorPage} loadingComponent={PageLoading} resolve={loadDependencies} />
  );
};

const mapStateToProps = (state: AppState) => ({
  isSuperAdmin: isSuperAdminSelector(state.account.login),
  userId: currentUserIdSelector(state.account.login),
});

const mapDispatchToProps = { loadVendors, loadAssignedVendors };

export default connect(mapStateToProps, mapDispatchToProps)(SelectDefaultVendorPageResolver);
