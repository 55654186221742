import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';
import { time } from '../../../utils/services/formatter';

interface Props {
  dateTime?: string;
  faultCode?: string;
  faultCodeNumber?: number;
  fmiDescription?: string;
  fmiNumber?: number;
  occurenceCount?: number;
}

const FaultCodeDetailsPageTableRow: React.FC<Props> = ({
  faultCodeNumber,
  faultCode,
  fmiNumber,
  fmiDescription,
  occurenceCount,
  dateTime,
}) => (
  <TableRow>
    <TableCell width="10%">{faultCodeNumber}</TableCell>
    <TableCell width="25%">{faultCode}</TableCell>
    <TableCell width="10%">{fmiNumber}</TableCell>
    <TableCell width="25%">{fmiDescription}</TableCell>
    <TableCell width="13%">{occurenceCount}</TableCell>
    <TableCell width="17%">{time(dateTime)}</TableCell>
  </TableRow>
);

export default FaultCodeDetailsPageTableRow;
