import React, { PureComponent } from 'react';

import { WASTE_AUDIT } from '../../constants/insightsGroupIds';
import { InsightsPageResolver } from '.';

class InsightsWasteAuditPageResolver extends PureComponent {
  render() {
    return <InsightsPageResolver insightGroupIds={[WASTE_AUDIT]} />;
  }
}

export default InsightsWasteAuditPageResolver;
