import React, { ChangeEvent, PureComponent } from 'react';

import { map } from 'lodash-es';

import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { ROUTE_PICKUP_TYPE_IDS } from '../constants';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  pickupTypes: any[];
}

class PickupTypeDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      pickupTypes,
    } = this.props;

    let pickupTypeOptions: any[] = [];

    if (pickupTypes.length) {
      pickupTypeOptions = map(pickupTypes, pickupType => ({
        label: ROUTE_PICKUP_TYPE_IDS[pickupType.id].name,
        value: pickupType.id,
      }));
    }

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('routes.pickupType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('routes.pickupType') : undefined),
          options: pickupTypeOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default PickupTypeDropdown;
