import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { Resolver } from '../../../../../core/components';
import { loadWeightTickets } from '../../../../ducks';
import { WeightTicketsModal } from '../../../../../customers/components/modals';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { DuckFunction } from '../../../../../contracts/ducks';
import { loadDisposalTrips } from 'src/routes/ducks/disposals';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { AppState } from 'src/store';

interface WeightTicketsModalResolverProps {
  closeModal: () => void;
  isDisposalSectionHidden?: boolean;
  loadDisposalTrips: DuckFunction<typeof loadDisposalTrips>;
  loadWeightTickets: DuckFunction<typeof loadWeightTickets>;
  modalTitle?: string;
  routeId: number;
  routeLocationId?: number;
  vehicleTypeId?: number;
  vendorId: number;
}

class WeightTicketsModalResolver extends PureComponent<WeightTicketsModalResolverProps> {
  loadDependencies = () => {
    const { loadDisposalTrips, loadWeightTickets, routeId, routeLocationId, vendorId } = this.props;
    return Promise.all([loadWeightTickets(routeId, routeLocationId), loadDisposalTrips(vendorId, routeId)]);
  };

  render() {
    const { closeModal, isDisposalSectionHidden, modalTitle, vehicleTypeId } = this.props;
    return (
      <Resolver
        successComponent={WeightTicketsModal}
        successProps={{
          closeModal,
          isDisposalSectionHidden,
          modalTitle,
          vehicleTypeId,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapDispatchToProps = { loadWeightTickets, loadDisposalTrips };

export default connect(
  (state: AppState) => ({
    vendorId: currentVendorId(state),
  }),
  mapDispatchToProps,
)(WeightTicketsModalResolver);
