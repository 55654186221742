import { createSelector } from 'reselect';
import { map, identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';
import { TODAY_FORMATTED } from '../../core/constants';

const getRoutesFormInitialValues = (
  queryString: string,
  vehicleFiltersPreferencesIds: number[],
  serviceZonesFiltersPreferencesIds: number[],
  supervisorsFiltersPreferencesIds: number[],
) => {
  const {
    agingIntervalIds,
    endDate,
    priorityTypeIds,
    routeStatusTypeIds,
    searchTerm,
    serviceZones,
    startDate,
    supervisors,
    groupIds,
    vehicleTypeIds,
  } = getQueryParams(queryString);

  return {
    agingIntervalIds: agingIntervalIds && map(agingIntervalIds.toString().split(','), Number),
    date: {
      from: startDate || TODAY_FORMATTED,
      to: endDate || TODAY_FORMATTED,
    },
    priorityTypeIds: priorityTypeIds && map(priorityTypeIds.toString().split(','), Number),
    routeStatusTypeIds: routeStatusTypeIds && map(routeStatusTypeIds.toString().split(','), Number),
    searchTerm,
    groupIds: groupIds ? map(groupIds.toString().split(','), Number) : [],
    serviceZones: serviceZones ? map(serviceZones.toString().split(','), Number) : serviceZonesFiltersPreferencesIds,
    supervisors: supervisors ? map(supervisors.toString().split(','), Number) : supervisorsFiltersPreferencesIds,
    vehicleTypeIds: vehicleTypeIds ? map(vehicleTypeIds.toString().split(','), Number) : vehicleFiltersPreferencesIds,
  };
};

const routesFormInitialValuesSelector = createSelector(getRoutesFormInitialValues, identity);
export default routesFormInitialValuesSelector;
