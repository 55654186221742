import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AccessContainer, DistrictItem } from './../styled/DivisionAccess';
import { Button, ButtonSet, Grid, GridColumn, Text } from '../../../core/components/styled';
import { DuckAction } from '../../../contracts/ducks';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { Districts } from '../../interfaces/DivisionAccess';
import { updateDistricts } from './../../ducks/';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  districts: Districts[];
  updateDistricts: DuckAction<typeof updateDistricts>;
}

interface State {
  selectedItem?: number;
}

class DivisionAccessModal extends PureComponent<Props, State> {
  state = {
    selectedItem: undefined,
  };

  handleSelectedItem = (id: number) => () => {
    this.setState({ selectedItem: id });
  };

  handleMoveAll = (value: boolean) => () => {
    const { updateDistricts, districts } = this.props;
    const newDistricts: any = districts.map(district => ({
      ...district,
      isEnabled: value,
    }));
    updateDistricts(newDistricts);
  };

  handleSingleMove = (value: boolean) => () => {
    const { updateDistricts, districts } = this.props;
    const { selectedItem } = this.state;
    if (selectedItem === undefined) return;

    const newDistricts: any = districts.map(district => {
      if (district.value === selectedItem) {
        return {
          ...district,
          isEnabled: value,
        };
      }
      return district;
    });
    updateDistricts(newDistricts);
  };

  render() {
    const { closeModal, districts } = this.props;
    const { selectedItem } = this.state;
    const availableList: Districts[] = [];
    const assignedList: Districts[] = [];
    if (districts) {
      districts.forEach(district => {
        if (district.isEnabled) {
          assignedList.push(district);
        } else availableList.push(district);
      });
    }
    return (
      <Modal title={translate('account.divisionAccess')} onClose={closeModal} padding="medium" size="smallMedium">
        <Grid centered multiLine>
          <GridColumn size="5/12">
            <GridColumn shift="5/12">
              <Text size="large">{translate('common.available')}</Text>
            </GridColumn>
            <AccessContainer right>
              {availableList.map(item => (
                <DistrictItem
                  selectedItem={selectedItem}
                  id={item.value}
                  key={item.value}
                  onClick={this.handleSelectedItem(item.value)}
                >
                  {item.name} ({item.value})
                </DistrictItem>
              ))}
            </AccessContainer>
          </GridColumn>
          <GridColumn size="2/12">
            <ButtonSet vertical style={{ height: '200px' }}>
              <Button color="primary" onClick={this.handleMoveAll(true)}>
                &gt;&gt;
              </Button>
              <Button color="primary" onClick={this.handleSingleMove(true)}>
                &gt;
              </Button>
              <Button color="primary" onClick={this.handleSingleMove(false)}>
                &lt;
              </Button>
              <Button color="primary" onClick={this.handleMoveAll(false)}>
                &lt;&lt;
              </Button>
            </ButtonSet>
          </GridColumn>
          <GridColumn size="5/12">
            <GridColumn shift="2/12">
              <Text size="large">{translate('common.assigned')}</Text>
            </GridColumn>
            <AccessContainer>
              {assignedList.map(item => (
                <DistrictItem
                  selectedItem={selectedItem}
                  id={item.value}
                  key={item.value}
                  onClick={this.handleSelectedItem(item.value)}
                >
                  {item.name} ({item.value})
                </DistrictItem>
              ))}
            </AccessContainer>
          </GridColumn>
          <GridColumn size="12/12" padding="sMedium no">
            <ButtonSet>
              <Button type="button" color="primary" onClick={closeModal}>
                {translate('common.close')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  updateDistricts,
};

export default connect(null, mapDispatchToProps)(DivisionAccessModal);
