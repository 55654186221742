import { MouseEvent } from 'react';

import { SortableElement } from 'react-sortable-hoc';

import { ACCOUNT_STATUSES } from '../../../constants';
import { Customer, Location } from 'src/routes/interfaces/Route';
import { DragHandle, UnconnectedDaysOfWeekPicker } from '../../../../core/components';
import { Service } from 'src/routes/interfaces/RouteLocation';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import RouteSequenceStopDropZone from './RouteSequenceStopDropZone';
import { getMapBounds } from 'src/common/components/map/util';
import { setRouteMapSelectedFeature, setRouteMapViewport } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { useDispatch } from 'react-redux';

interface Props {
  accountStatusId: number;
  customer: Customer;
  location: Location;
  newOrderNumber: number;
  onRouteLocationDrop: () => void;
  openOrderNumberPopover: (newOrderNumber: number, event: MouseEvent, id: number) => void;
  orderNumber: number;
  scheduledDays: any[];
  latitude: number;
  longitude: number;
  service: Service;
}

const RouteTemplateSequenceEditorPageTableRow = ({
  accountStatusId,
  customer: { name: customerName, accountNumber },
  location: {
    id,
    name: locationName,
    vendorAccountNo,
    address: { street, streetNumber },
  },
  latitude,
  longitude,
  newOrderNumber,
  onRouteLocationDrop,
  openOrderNumberPopover,
  orderNumber,
  scheduledDays,
  service,
}: Props) => {
  const dispatch = useDispatch();

  const handleClick = (event: any) => {
    const isTableRowClicked = event ? event.target.parentNode.tagName === 'DIV' : false;

    if (isTableRowClicked) {
      const point = { latitude, longitude };
      const bounds = getMapBounds([point]);

      dispatch(setRouteMapViewport(bounds));
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, id));
    }
  };

  const tableCellWidths = ['5%', '11%', '11%', '30%', '10%', '15%', '18%'];

  return (
    <TableRow height={70} wrap={'wrap'} position={'relative'} onClick={handleClick}>
      <RouteSequenceStopDropZone
        position="top"
        routeLocationIndex={newOrderNumber - 1}
        onRouteLocationDrop={onRouteLocationDrop}
      />
      <TableCell width={tableCellWidths[0]}>
        <DragHandle />
      </TableCell>
      <TableCell width={tableCellWidths[1]}>
        <TableActionButton
          onClick={(event: MouseEvent<Element, globalThis.MouseEvent>) =>
            openOrderNumberPopover(newOrderNumber, event, id)
          }
        >
          {newOrderNumber}
        </TableActionButton>
      </TableCell>
      <TableCell width={tableCellWidths[2]}>
        <TableActionButton>{orderNumber}</TableActionButton>
      </TableCell>
      <TableCell vertical width={tableCellWidths[3]}>
        <Text block weight="medium" margin="no no xxSmall">
          {customerName} {accountNumber && `(${accountNumber})`}
        </Text>
        <Text weight="light" size="small" margin="no no xxSmall">
          {locationName}, {streetNumber} {street} {vendorAccountNo && `(${vendorAccountNo})`}
        </Text>
      </TableCell>
      <TableCell width={tableCellWidths[4]}>
        {(!!accountStatusId && ACCOUNT_STATUSES[accountStatusId].name) || '-'}
      </TableCell>
      <TableCell width={tableCellWidths[5]}>{service && service.name}</TableCell>
      <TableCell width={tableCellWidths[6]}>
        <UnconnectedDaysOfWeekPicker
          selectedWeekdays={scheduledDays}
          isReadOnly
          multiple
          dayOfWeekProps={{ margin: "no xSmall no no'" }}
          daysOfWeekProps={{ margin: 'no' }}
        />
      </TableCell>
      <RouteSequenceStopDropZone
        position="bottom"
        routeLocationIndex={newOrderNumber}
        onRouteLocationDrop={onRouteLocationDrop}
      />
    </TableRow>
  );
};

export default SortableElement(RouteTemplateSequenceEditorPageTableRow);
