import { forEach } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MapGL } from 'src/common/components/map/MapGL';
import { getMapBounds } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import { MapGLWrapper } from 'src/customers/components/styled';
import {
  clearDashboardModalMapSelectedFeature,
  setDashboardModalMapViewport,
  setModalMapSatelliteMapControl,
} from 'src/dashboard/ducks/mapControls';
import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import SnowSweeperStreetSegmentsGL from './SnowSweeperStreetSegments/SnowSweeperStreetSegmentsGL';
import VehiclePositionsGL from './VehiclePositions/VehiclePositionsGL';
import VehicleTrackingsGL from './VehicleTrackings/VehicleTrackingsGL';

const RouteHistoryMapGL = () => {
  const dispatch = useDispatch();

  const [map, setMap] = useState<mapboxgl.Map>();
  const { routeVehiclePositions, isLoadingRouteVehiclesBreadCrumbs, isLoadingRouteSegments } = useSelector(
    state => state.dashboard.routeHistoryData,
  );

  const isOnDemandVideoDownloadEnabled = useSelector(isOnDemandVideoDownloadFeatureEnabled);
  const viewport = useSelector(state => state.dashboard.mapControls.modalMapViewport);

  const setIsSatelliteView = (isSatelliteView: boolean) => {
    dispatch(setModalMapSatelliteMapControl(isSatelliteView));
  };

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If any cluster is clicked, then any popup
             * should be closed.
             */
            !!feature.properties?.cluster_id,
        );

        if (features.length) {
          dispatch(clearDashboardModalMapSelectedFeature());
        }
      });

      map.on('mousemove', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true || !!feature.properties?.cluster_id,
        );

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch]);

  // ============== VIEWPORT ==============
  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    forEach(routeVehiclePositions, vehicle => {
      points.push({ latitude: vehicle.latitude, longitude: vehicle.longitude });
    });

    if (points.length) {
      const bounds = getMapBounds(points, {
        padding: 10,
        capZoom: 17,
      });

      dispatch(setDashboardModalMapViewport(bounds));
    }
  }, [routeVehiclePositions, map, dispatch]);

  return (
    <MapGLWrapper isLoading={isLoadingRouteVehiclesBreadCrumbs || isLoadingRouteSegments}>
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={setIsSatelliteView}
      >
        {map && (
          <>
            <SnowSweeperStreetSegmentsGL map={map} />
            <VehicleTrackingsGL map={map} isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled} />
            <VehiclePositionsGL map={map} />
          </>
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default RouteHistoryMapGL;
