import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { loadExceptions } from '../../ducks';
import { loadWasteAuditConfiguration } from '../../../vendors/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { WASTE_AUDIT_ID } from '../../../fleet/constants';
import JobStatusModal from './JobStatusModal';
import loadJobExceptions from '../../services/dispatchBoardJobExceptions';

interface Props {
  closeModal: (data: any) => void;
  loadExceptions: DuckFunction<typeof loadExceptions>;
  loadWasteAuditConfiguration: DuckFunction<typeof loadWasteAuditConfiguration>;
  routeId?: number;
  routeLocation?: any;
  routeLocationInfo?: any;
  vendorId: number;
  changeStatus?: (value: number) => void;
}

interface State {
  pickupExceptions: any[];
}

class JobStatusModalResolver extends PureComponent<Props, State> {
  state = {
    pickupExceptions: [],
  };

  loadDependencies = async () => {
    const { loadExceptions, loadWasteAuditConfiguration, routeLocation, routeLocationInfo, vendorId } = this.props;

    const pickupExceptions = await loadJobExceptions(routeLocation.id);

    this.setState({
      pickupExceptions,
    });

    return routeLocationInfo?.vehicleTypeId === WASTE_AUDIT_ID
      ? loadWasteAuditConfiguration(vendorId)
      : loadExceptions(vendorId, routeLocationInfo?.vehicleTypeId);
  };

  render() {
    const { closeModal, routeId, routeLocation, routeLocationInfo, changeStatus } = this.props;
    if (routeLocationInfo) routeLocationInfo.pickupExceptions = this.state.pickupExceptions;

    return (
      <Resolver
        successComponent={JobStatusModal}
        successProps={{
          closeModal,
          routeId,
          routeLocation,
          routeLocationInfo,
          changeStatus,
        }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadExceptions, loadWasteAuditConfiguration };

export default connect(mapStateToProps, mapDispatchToProps)(JobStatusModalResolver);
