import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import Cookie from 'js-cookie';
import { debounce, filter, find, get, includes, map, size } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';
import { change, getFormValues, submit } from 'redux-form';

import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { MapGL } from 'src/common/components/map/MapGL';
import MapGLWrapper, { EditableMapGLInfo } from 'src/common/components/map/MapGLWrapper';
import {
  DebugMapVehicleTrackingSources,
  DebugMapVehicleTrackingType,
} from 'src/common/components/map/sources/DebugMapVehicleTrackingSources';
import { getMapBounds } from 'src/common/components/map/util';
import useGeoFenceControllerMapbox, { NEW_GEO_FENCE_ID } from 'src/common/hooks/geoFenceControllerMapbox';
import useRefreshDataInterval from 'src/common/hooks/useRefreshDataInterval';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { Icon } from 'src/core/components';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon, Text } from 'src/core/components/styled';
import {
  GEO_FENCE_OPTIONS,
  GEO_FENCE_OPTIONS_SAT,
  GEO_FENCE_OPTIONS_SELECTED,
  MAP_CITY_ZOOM_IN,
  MAP_CITY_ZOOM_IN_BIGGER,
} from 'src/core/constants';
import { OPTIMIZED_JOB_POSITION_ID } from 'src/core/constants/jobPositionOptions';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { ROUTE } from 'src/customers/constants/streetNetwork';
import {
  loadRouteVehiclesBreadCrumbs,
  loadVendorLocations,
  resetRouteVehiclesBreadCrumbs,
  resetVendorLocations,
} from 'src/dashboard/ducks';
import { DELIVERY_UTILITY_ID, SNOW_PLOW_ID, WASTE_AUDIT_ID } from 'src/fleet/constants';
import { loadVehicleTypes } from 'src/fleet/ducks';
import { PassesStatus, StatusContainer } from 'src/routes/components/styled';
import { ComplexMapControl, MapLegend, MapLegendInfo } from 'src/routes/components/styled/RouteMap';
import {
  COMPLETED,
  IN_PROGRESS,
  JOB_PENDING_OPTIMIZATION_ID,
  PICKUP_STATUS_LABEL_COLORS,
  SCHEDULED,
} from 'src/routes/constants';
import {
  clearRouteMapSelectedFeature,
  downloadTravelPathGeoJsonFile,
  loadGeoFences,
  loadRouteMapVehicleData,
  loadRouteStops,
  loadUnscheduledStops,
  loadWasteAuditNotes,
  resetGeoFences,
  resetIncludeVehicleTracking,
  resetRouteMapCityInsights,
  resetRouteStops,
  resetTravelPathDetails,
  setRouteMapViewport,
  setShowTravelPath,
} from 'src/routes/ducks';
import {
  setJobPriorityTypeIds,
  setPickupStatusIds,
  setShowStopsWithLocationAlerts,
  setShowTemporaryStops,
} from 'src/routes/ducks/filters';
import { RouteMapFeature, setIsDrawingMode, setRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { loadRouteGeoFence, resetGeoFence } from 'src/routes/ducks/routeGeoFence';
import { resetRouteMapVehicleData } from 'src/routes/ducks/routeMapVehicleData';
import { resetRouteMapVehicleInsights } from 'src/routes/ducks/routeMapVehicleInsights';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { setPersistentMapFilters } from 'src/routes/services/persistentMapFilterSettingStorage';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DrawingInstructions } from '../../../routeTemplateBuilder/routeTemplateBuilderMap/DrawingInstructions';
import { filterStopsPredicate } from '../routeStops/RouteStops';
import { ROUTE_STOPS_FIELD_ARRAY_NAME, ROUTE_STOPS_FORM_NAME } from '../routeStops/RouteStopsForm';
import MapLastRefresh from './MapLastRefresh';
import RouteMapActions from './RouteMapActions';
import RouteMapDebug from './RouteMapDebug';
import RouteMapFiltersForm, { ROUTE_MAP_FILTERS_FORM_NAME, RouteMapFiltersFormValues } from './RouteMapFiltersForm';
import RouteMapApplicationStatusGL from './applicationStatus/RouteMapApplicationStatusGL';
import RouteMapCityInsightsGL from './cityInsights/RouteMapCityInsightsGL';
import RouteMapClustersGL from './cluster/RouteMapClustersGL';
import { ROUTE_MAP_CLUSTERS_SOURCE, ROUTE_MAP_VEHICLE_POSITIONS_LAYER } from './constants';
import RouteMapGeoFencesGL from './geoFences/RouteMapGeoFencesGL';
import RouteMapHaulerLocationsGL from './haulerLocations/RouteMapHaulerLocationsGL';
import RouteMapRouteStopsGL from './routeStops/RouteMapRouteStopsGL';
import RouteMapTravelPathGL from './travelPath/RouteMapTravelPathGL';
import RouteMapVehicleInsightsGL from './vehicleInsights/RouteMapVehicleInsightsGL';
import RouteMapVehiclePositionsGL from './vehiclePositions/RouteMapVehiclePositionsGL';
import RouteMapVehicleTrackingsGL from './vehicleTrackings/RouteMapVehicleTrackingsGL';
import CityAlertsGL from './cityAlerts/CityAlertsGL';
import { loadCityAlerts, resetCityAlerts } from 'src/vendors/ducks';
import { ALL_CITY_ALERTS } from 'src/fleet/constants/locationAndCityAlerts';

// returns the indexes of the elements that are true
const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

// returns the keys of the elements that are true
const normalizeFilterObject = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: string[], cur) => (cur[1] ? [...acc, cur[0]] : acc), []);

// returns the keys of the elements that are true as numbers
const normalizeFilterObjectAsNumbersAndRemovePrefix = (object: { [key: string]: boolean }) =>
  Object.entries(object).reduce((acc: number[], cur) => (cur[1] ? [...acc, Number(cur[0].replace('_', ''))] : acc), []);

type RouteMapFilters = {
  showStopConfirmation: boolean;
  showVehicleTrackings: boolean;
  showXDeviceTrackings: boolean;
  showYDeviceTrackings: boolean;
};

export type RouteMapGLProps = {
  isEditMode: boolean;
  isMapSmall?: boolean;
  isUnscheduledStopsVisible?: boolean;
  onFiltersReady: () => void;
};

type RouteStopsFormValues = {
  routeStops?: RouteStop[];
};

export default function RouteMap({
  isEditMode,
  isMapSmall,
  isUnscheduledStopsVisible,
  onFiltersReady,
}: RouteMapGLProps) {
  const dispatch = useDispatch();
  const editorRef = useRef<Editor>(null);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();
  const [isRouteMapFiltersOpen, setRouteMapFiltersOpen] = useState(false);
  const [isRouteMapActionsOpen, setRouteMapActionsOpen] = useState(false);
  const [interactiveLayersMap, setInteractiveLayersMap] = useState<{ [key: string]: string[] }>({});
  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });

  const [isAutoRefresh, setIsAutoRefresh] = useState<boolean>(false);
  const [showOrderNumbers, setShowOrderNumbers] = useState(false);
  const [vehicleInsightsFilters, setVehicleInsightsFilters] = useState<string[]>([]);
  const [isLegendCollapsed, setIsLegendCollapsed] = useState<boolean>(false);
  const [cityInsightsFilters, setCityInsightsFilters] = useState<number[]>([]);
  const [shouldCenterByVendor, setShouldCenterByVendor] = useState<boolean>(true);
  const [vehicleTrackingsFilters, setVehicleTrackingsFilters] = useState<RouteMapFilters>({
    showStopConfirmation: false,
    showVehicleTrackings: false,
    showXDeviceTrackings: false,
    showYDeviceTrackings: false,
  });
  const vehicleTypes = useSelector(state => state.fleet.vehicleTypes.vehicleTypes);

  const vendorId = useSelector(currentVendorId);
  const vendor = useSelector(state => state.vendors.vendor.vendor) as any as Vendor;
  const routeSummary = useSelector(state => state.routes.routeSummary?.routeSummary);
  const viewport = useSelector(state => state.routes.mapControls?.viewport);
  const isDrawingMode = useSelector(state => state.routes.mapControls?.isDrawingMode);
  const isDebugMode = useSelector(state => state.routes.mapControls?.isDebugMode);
  const { pickupStatusIds, jobPriorityTypeIds, searchTerm, showStopsWithLocationAlerts, showTemporaryStops } =
    useSelector(state => state.routes.filters);
  const vehiclePositions = useSelector(state => state.routes.routeMapVehicleData?.vehiclePositions);
  const mainVehicleId = useSelector(state => state.routes.routeMapVehicleData?.mainVehicleId);
  const vehicleTrackings = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs?.vehicles);

  const [vehicleFilters, setVehicleFilters] = useState<number[]>(mainVehicleId ? [mainVehicleId] : []);
  const vehicleInsights = useSelector(state => state.routes.routeMapVehicleInsights?.vehicleInsights);
  const cityInsights = useSelector(state => state.routes.routeMapCityInsights?.cityInsights);
  const cityAlerts = useSelector(state => state.vendors.cityAlerts?.cityAlerts);
  const routeStops = useSelector(state => state.routes.routeStops?.routeStops);
  const isRouteStopsLoaded = useSelector(state => state.routes.routeStops?.isLoaded);
  const haulerLocations = useSelector(state => state.dashboard.vendorLocations?.vendorLocations);

  const [polygon, setPolygon] = useState<any>();
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [editPolygon, setEditPolygon] = useState<boolean>(true);
  const [showRouteGeoFence, setShowRouteGeoFence] = useState(false);
  const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false);
  const geoFence = useSelector(state => state.routes.geoFence?.geoFence);
  const geoFences = useSelector(state => state.routes.geoFences.geoFences?.geoFences);
  const lastRefreshed = useSelector(state => state.routes.routeMapVehicleData.lastRefreshed);
  const showTravelPath = useSelector(state => state.routes.travelPath.showTravelPath);
  const travelPathData = useSelector(state => state.routes.travelPath?.travelPathData);
  const travelPathForRouteId = useSelector(state => state.routes.travelPath?.routeId);

  // TODO uncomment this when creating alert is enabled on map
  // const cityAlertSettings = useSelector(state => state.vendors.cityAlertSettings?.cityAlertSettings);
  // const activeCityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;

  const routeStopsFormValues = useSelector(getFormValues(ROUTE_STOPS_FORM_NAME)) as RouteStopsFormValues;

  const {
    getAllCoords,
    allGeoFencesGeoJson,
    mapGeoFences,
    selectedGeoFenceGeo,
    addGeoFence,
    addPolygonToGeoFence,
    bulkAddGeoFences,
    emptyGeoFences,
    getGeoJsonForGeoFence,
    removeGeoFence,
    removeUnsavedGeoFenceOrPolygons,
    removePolygonFromGeoFence,
    selectGeoFencePolygon,
    unselectGeoFencePolygon,
    updatePolygonInGeoFence,
    undoPolygonInGeoFence,
  } = useGeoFenceControllerMapbox();

  const dragPan = useSelector(s => s.routes.routeMapSettings.dragPan);

  const optimizedRouteStopsLength = useMemo(
    () =>
      routeStopsFormValues?.routeStops?.filter(routeStop => routeStop.orderNo === JOB_PENDING_OPTIMIZATION_ID).length ||
      0,
    [routeStopsFormValues?.routeStops],
  );

  const isWasteAuditRoute = useMemo(
    () => routeSummary?.vehicleTypeId === WASTE_AUDIT_ID,
    [routeSummary?.vehicleTypeId],
  );
  const isSnowPlowRoute = useMemo(() => routeSummary?.vehicleTypeId === SNOW_PLOW_ID, [routeSummary?.vehicleTypeId]);

  const mapCenterByVendor = useMemo(() => {
    if (vendor?.id) {
      return getMapBounds([{ latitude: vendor.homeAddress.latitude, longitude: vendor.homeAddress.longitude }], {
        capZoom: MAP_CITY_ZOOM_IN,
      });
    }
  }, [vendor.id, vendor.homeAddress]);

  const isMovingStopsRef = useRef(false);
  useEffect(() => {
    isMovingStopsRef.current = !dragPan;
  }, [dragPan]);

  if (isMapSmall) {
    if (isRouteMapActionsOpen) setRouteMapActionsOpen(false);
    if (isRouteMapFiltersOpen) setRouteMapFiltersOpen(false);
  }

  const filteredVehiclePositions = useMemo(
    () => vehiclePositions.filter(vehiclePosition => vehicleFilters.includes(vehiclePosition.vehicleId)),
    [vehiclePositions, vehicleFilters],
  );

  const filteredVehicleTrackings = useMemo(
    () =>
      vehicleTrackingsFilters.showVehicleTrackings && vehicleFilters.length
        ? filter(vehicleTrackings, tr => vehicleFilters.includes(tr.id))
        : [],
    [vehicleTrackings, vehicleFilters, vehicleTrackingsFilters.showVehicleTrackings],
  );

  const filteredVehicleInsights = useMemo(
    () => vehicleInsights.filter(vehicleInsight => vehicleInsightsFilters.includes(vehicleInsight.insightType.name)),
    [vehicleInsights, vehicleInsightsFilters],
  );

  const filteredCityInsights = useMemo(
    () => cityInsights.filter(cityInsight => cityInsightsFilters.includes(cityInsight.locationFlaggingTypeId)),
    [cityInsights, cityInsightsFilters],
  );

  // TODO uncomment this when creating alert is enabled on map
  // const cityAlertsFilters = useMemo(
  //   () =>
  //     map(activeCityAlertTypes, cityAlertType => ({
  //       id: cityAlertType.cityAlertType.id,
  //       label: cityAlertType.cityAlertType.name,
  //     })),
  //   [activeCityAlertTypes],
  // );

  const filteredStops = useMemo<RouteStop[]>(() => {
    if (isEditMode) {
      return map(routeStopsFormValues?.routeStops, (stop: any, index: number) => ({
        ...stop,
        orderNo:
          stop.positionTypeId === OPTIMIZED_JOB_POSITION_ID || stop.orderNo === -1
            ? -1
            : index + 1 - optimizedRouteStopsLength,
      })).filter(
        filterStopsPredicate(
          showStopsWithLocationAlerts,
          showTemporaryStops,
          pickupStatusIds,
          jobPriorityTypeIds,
          searchTerm,
        ),
      );
    }

    return routeStops.filter(
      filterStopsPredicate(
        showStopsWithLocationAlerts,
        showTemporaryStops,
        pickupStatusIds,
        jobPriorityTypeIds,
        searchTerm,
      ),
    );
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    isEditMode,
    routeStops,
    showStopsWithLocationAlerts,
    showTemporaryStops,
    pickupStatusIds,
    jobPriorityTypeIds,
    searchTerm,
    routeStopsFormValues?.routeStops,
    optimizedRouteStopsLength,
  ]);

  const addInteractiveLayers = useMemo(
    () => (fields: string[], key: string) => {
      setInteractiveLayersMap(i => {
        i[key] = fields;
        return { ...i };
      });
    },
    [],
  );

  const getInteractiveLayers = () => {
    let layers: string[] = [];
    Object.keys(interactiveLayersMap).forEach(k => (layers = [...layers, ...interactiveLayersMap[k]]));
    return layers;
  };

  // Select stops via laso tool
  const selectPointsInPolygon = useCallback(
    (points: RouteStop[], newPolygon: any) => {
      const includedPoints = points
        .filter(p => booleanPointInPolygon([p.displayLongitude, p.displayLatitude], newPolygon))
        .map(p => p.id);

      const routeStopsFormValuesAfterSelect = (routeStopsFormValues?.routeStops || []).map(routeStop => {
        const isChecked = includes(includedPoints, routeStop.id) && !!filteredStops.find(s => s.id === routeStop.id);
        return {
          ...routeStop,
          isChecked: (!routeStop.isCheckedByPolygon && routeStop.isChecked) || isChecked,
          isCheckedByPolygon: isChecked,
        };
      });

      dispatch(change(ROUTE_STOPS_FORM_NAME, ROUTE_STOPS_FIELD_ARRAY_NAME, routeStopsFormValuesAfterSelect));
    },
    [dispatch, filteredStops, routeStopsFormValues?.routeStops],
  );

  const selectPointsInPolygonDebounced = useMemo(() => debounce(selectPointsInPolygon, 200), [selectPointsInPolygon]);

  const checkLocationsInPolygon = (event: any) => {
    const newPolygon = event.data;
    setPolygon(newPolygon);

    if (event.editType === 'addFeature') {
      selectPointsInPolygon(routeStops, newPolygon[0]);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(routeStops, newPolygon[0]);
    }
  };

  const filtersFormInitialValues = useMemo(() => {
    const vehicleFiltersInitialValues: { [key: string]: boolean } = {};
    if (mainVehicleId) {
      vehicleFiltersInitialValues[`_${mainVehicleId}`] = true;
    }

    return {
      showAllGeoFences: false,
      showRouteGeoFence: false,
      showOrderNumbers: false,
      showAllStops: isRouteStopsLoaded,
      pickupStatusFilters: [],
      showTemporaryStops: false,
      showStopsWithLocationAlerts: false,
      showVehicleTrackings: false,
      showStopConfirmation: false,
      showXDeviceTrackings: false,
      showYDeviceTrackings: false,
      vehicleFilters: vehicleFiltersInitialValues,
      showAllVehicleInsights: false,
      vehicleInsightsFilters: {},
      showAllCityInsights: false,
      cityInsightsFilters: [],
      showAllHaulerLocations: false,
      haulerLocationsFilters: [],
      jobPriorityFilters: [],
      cityAlertsFilters: [],
      geoFencesTypesFilters: [],
      geoFenceSubFilters: {},
      geoFenceSearchTerm: '',
      isAutoRefresh: false,
    };
  }, [isRouteStopsLoaded, mainVehicleId]);

  useEffect(() => {
    if (shouldCenterByVendor && !isAutoRefresh) {
      mapCenterByVendor && !mapViewStyle.mapCenter && dispatch(setRouteMapViewport(mapCenterByVendor));
      setShouldCenterByVendor(false);
    } else if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      dispatch(setRouteMapViewport(bounds));
    }
  }, [mapCenterByVendor, dispatch, mapViewStyle.mapCenter, shouldCenterByVendor, mapViewStyle.mapZoom]); // eslint-disable-line react-hooks/exhaustive-deps

  // cleanup on unmount
  useEffect(
    () => () => {
      dispatch(resetGeoFence());
      dispatch(resetRouteMapVehicleData());
      dispatch(resetRouteMapVehicleInsights());
      dispatch(resetRouteMapCityInsights());
      dispatch(resetVendorLocations());
      dispatch(resetGeoFences());
      dispatch(setIsDrawingMode(false));
      dispatch(resetRouteVehiclesBreadCrumbs());
      dispatch(resetTravelPathDetails());
      dispatch(resetCityAlerts());
    },
    [dispatch],
  );

  // map mouse events
  useEffect(() => {
    mapInstance?.once('load', () => {
      mapInstance.on('click', event => {
        const [feature] = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === ROUTE_MAP_CLUSTERS_SOURCE &&
              feature.properties?.layer === ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
          );
        const features = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(feature => !!feature.properties?.cluster_id);

        if (feature) {
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.vehiclePositions, feature.id as number));
        }
        if (features.length) {
          dispatch(clearRouteMapSelectedFeature());
        }
      });

      mapInstance.on('mousemove', event => {
        const features = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);
        if (!isEditMode) {
          mapInstance.getCanvas().style.cursor = features.length ? 'pointer' : '';
        }
      });
      mapInstance.on('mouseleave', () => {
        mapInstance.getCanvas().style.cursor = '';
      });
      mapInstance.on('idle', () => {
        if (mapInstance.getLayer(ROUTE_MAP_VEHICLE_POSITIONS_LAYER)) {
          mapInstance.moveLayer(ROUTE_MAP_VEHICLE_POSITIONS_LAYER);
        }
      });
    });
  }, [mapInstance, dispatch, geoFences, geoFence, isEditMode, drawMode]);

  // map viewport based on displayed features
  useEffect(() => {
    if (isAutoRefresh) return;

    const points: { latitude: number; longitude: number }[] = [];

    filteredVehiclePositions.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    filteredVehicleTrackings.forEach(vehicleTracking => {
      vehicleTracking.coords.forEach(coordinateGroup => {
        coordinateGroup.forEach(({ lat, lng }) => {
          points.push({ latitude: lat, longitude: lng });
        });
      });
    });

    showTravelPath &&
      travelPathData?.features?.forEach(travelPath => {
        travelPath.geometry.coordinates.forEach((coordinate: any[]) => {
          points.push({ latitude: coordinate[1], longitude: coordinate[0] });
        });
      });

    filteredVehicleInsights.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    filteredStops.forEach(({ binLatitude, binLongitude }) => {
      points.push({ latitude: binLatitude, longitude: binLongitude });
    });

    filteredCityInsights.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    haulerLocations.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    cityAlerts.forEach(({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    if (mapGeoFences.length > 0) {
      points.push(...getAllCoords);
    }

    if (!!points.length && !isDrawingMode && !isMovingStopsRef.current) {
      const bounds = getMapBounds(points, {
        capZoom: MAP_CITY_ZOOM_IN_BIGGER,
      });
      dispatch(setRouteMapViewport(bounds));
    }
  }, [// eslint-disable-line react-hooks/exhaustive-deps
    dispatch,
    filteredCityInsights,
    filteredStops,
    mapGeoFences.length,
    filteredVehicleInsights,
    filteredVehiclePositions,
    filteredVehicleTrackings,
    haulerLocations,
    showTravelPath,
    travelPathData,
    cityAlerts,
  ]);

  /************************************************
   * GEO-FENCES Management
   ************************************************/

  // adding geoFences to controller
  useEffect(() => {
    if (!isDrawingMode && !isEditMode) {
      const bulkToSet = (geoFences || []).map((geoFence: any) => {
        const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
        return {
          ...geoFence,
          geoFenceCoordinates: parsedGeoFenceJson?.geometry?.coordinates,
        };
      });
      bulkToSet.length && bulkAddGeoFences(bulkToSet);
    }

    const geoFenceExists = !!geoFence?.geoFenceCoordinates?.length;
    if (geoFence && showRouteGeoFence && geoFenceExists) {
      addGeoFence(geoFence);
    } else if (geoFenceExists && !showRouteGeoFence && geoFence.id) {
      removeGeoFence(geoFence.id);
    }
  }, [geoFence, geoFences, isDrawingMode, isEditMode, showRouteGeoFence]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(resetGeoFences());
    dispatch(setIsDrawingMode(false));
  }, [dispatch]);

  useEffect(() => {
    if (isEditMode) {
      setPolygon([]);
      dispatch(setIsDrawingMode(false));
      setIsAddingPolygon(false);
    }
  }, [dispatch, isEditMode]);

  // when drawing mode is enabled, set the viewport to the geoFence
  useEffect(() => {
    if (isDrawingMode && !isEditMode && !isAutoRefresh) {
      const geoPoints: { latitude: number; longitude: number }[] = [];

      const geoFenceCoordinates = get(geoFence, 'geoFenceCoordinates', []);

      geoFenceCoordinates.forEach((polygon: any) => {
        const points = get(polygon, '[0]', []);

        points.forEach((point: any) => {
          const latitude = get(point, '[1]');
          const longitude = get(point, '[0]');

          if (latitude !== undefined && longitude !== undefined) {
            geoPoints.push({ latitude, longitude });
          }
        });
      });

      if (!!geoPoints.length) {
        const bounds = getMapBounds(geoPoints, {
          capZoom: MAP_CITY_ZOOM_IN_BIGGER,
        });
        dispatch(setRouteMapViewport(bounds));
      }
    }
  }, [dispatch, isDrawingMode, geoFence, isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  // clean up when drawing mode is disabled
  useEffect(() => {
    if (isDrawingMode) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
        !isEditMode && setIsAddingPolygon(true);
      }
    } else {
      if (polygon && editorRef.current) {
        setPolygon(undefined);
      }

      if (!!drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [isDrawingMode, drawMode, polygon, dispatch, isEditMode]);

  const updateGeoFenceHistoryDebounced = useMemo(
    () =>
      debounce((newPolygons: any) => {
        const updatedPolygon = find(newPolygons, (polygon: any) => {
          return polygon.properties?.polygonId === selectedGeoFenceGeo?.polygonId;
        });

        if (updatedPolygon && selectedGeoFenceGeo) {
          updatePolygonInGeoFence(
            selectedGeoFenceGeo?.id,
            selectedGeoFenceGeo?.polygonId,
            updatedPolygon.geometry.coordinates[0],
          );
        }
      }, 200),
    [selectedGeoFenceGeo, updatePolygonInGeoFence],
  );

  const onEditGeoFence = (event: any) => {
    const newPolygons = event.data;
    const last = newPolygons.length - 1;

    if (event.editType === 'addFeature') {
      setIsAddingPolygon(false);
      setDrawMode(new EditingMode());
      const polygonId = addPolygonToGeoFence(newPolygons[last].geometry.coordinates[0], geoFence?.id);
      setPolygon([...polygon, { ...newPolygons[last], properties: { polygonId, geoFenceId: geoFence?.id } }]);
    } else {
      updateGeoFenceHistoryDebounced(newPolygons);
      setPolygon(newPolygons);
    }
  };

  // action handlers
  const handleEnterDrawMode = useCallback(() => {
    dispatch(setIsDrawingMode(true));
    !drawMode && setDrawMode(new DrawPolygonMode());
    setIsAddingPolygon(true);
  }, [dispatch, drawMode]);

  const editGeoFence = useCallback(() => {
    dispatch(setIsDrawingMode(true));
    setDrawMode(new EditingMode());
    setShowRouteGeoFence(true);
    geoFence && dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, `geoFencesTypesFilters[${geoFence.id}]`, true));
    geoFence && setPolygon(getGeoJsonForGeoFence(geoFence.id || NEW_GEO_FENCE_ID, geoFence));
    setRouteMapFiltersOpen(false);
  }, [dispatch, geoFence, getGeoJsonForGeoFence]);

  const handleAddPolygon = useCallback(() => {
    dispatch(setIsDrawingMode(true));
    setDrawMode(new DrawPolygonMode());
    setShowRouteGeoFence(true);
    geoFence && dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, `geoFencesTypesFilters[${geoFence.id}]`, true));
    setIsAddingPolygon(true);
    unselectGeoFencePolygon();
  }, [dispatch, geoFence, unselectGeoFencePolygon]);

  const handleUndoGeoFence = () => {
    if (selectedGeoFenceGeo) {
      undoPolygonInGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
    }
  };

  useEffect(() => {
    if (selectedGeoFenceGeo) setPolygon(getGeoJsonForGeoFence(selectedGeoFenceGeo.id));
  }, [selectedGeoFenceGeo, getGeoJsonForGeoFence]);

  const handleExitDrawMode = useCallback(
    (uncheckStops?: boolean) => {
      dispatch(setIsDrawingMode(false));
      if (uncheckStops) {
        const routeStopsFormValuesAfterSelect = (routeStopsFormValues?.routeStops || []).map(routeStop => ({
          ...routeStop,
          isChecked: routeStop.isCheckedByPolygon ? false : routeStop.isChecked,
        }));

        dispatch(change(ROUTE_STOPS_FORM_NAME, ROUTE_STOPS_FIELD_ARRAY_NAME, routeStopsFormValuesAfterSelect));
      }
      // If there is an unsaved geoFence remove it from the list
      unselectGeoFencePolygon();
      removeUnsavedGeoFenceOrPolygons();
      setIsAddingPolygon(false);
    },
    [dispatch, removeUnsavedGeoFenceOrPolygons, routeStopsFormValues?.routeStops, unselectGeoFencePolygon],
  );

  const handleSelectInGeoFenceEditor = useCallback(
    (selected: any) => {
      if (selected?.selectedFeatureIndex === null) {
        unselectGeoFencePolygon();
        return;
      }

      if (selected?.selectedFeature?.properties?.polygonId && selected?.selectedFeature?.properties?.geoFenceId) {
        selectGeoFencePolygon(
          selected?.selectedFeature?.properties?.geoFenceId,
          selected?.selectedFeature?.properties?.polygonId,
        );
      } else {
        const geoFenceId = geoFence?.id || NEW_GEO_FENCE_ID;
        selectGeoFencePolygon(geoFenceId, selected?.selectedFeatureIndex);
      }
    },
    [geoFence?.id, selectGeoFencePolygon, unselectGeoFencePolygon],
  );
  const handleDeletePolygon = useCallback(() => {
    if (selectedGeoFenceGeo) {
      removePolygonFromGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
      setPolygon([...filter(polygon, (p: any) => p.properties?.polygonId !== selectedGeoFenceGeo.polygonId)]);
      unselectGeoFencePolygon();
    }
  }, [selectedGeoFenceGeo, removePolygonFromGeoFence, polygon, unselectGeoFencePolygon]);

  /************************************************
   * End of GEO-FENCES Management
   * *********************************************/

  const handleSubmitFilters = useCallback(
    async (formData: RouteMapFiltersFormValues) => {
      if (!routeSummary) return;

      emptyGeoFences();

      if (!formData.isLastFiltersApplied) {
        const pageType = ROUTE;
        const routeOrTemplateId = routeSummary.routeId;
        setPersistentMapFilters({ ...formData, routeOrTemplateId, pageType });
      }

      const routeStopsStatusIds = normalizeFilterArray(formData.pickupStatusFilters);
      if (routeStopsStatusIds.length) {
        !isAutoRefresh && dispatch(setPickupStatusIds(routeStopsStatusIds));
        await loadRouteStops(vendorId, routeSummary.routeId, routeStopsStatusIds, undefined, isAutoRefresh)(dispatch);

        if (isUnscheduledStopsVisible)
          await loadUnscheduledStops(vendorId, routeSummary?.routeId, isAutoRefresh)(dispatch);
        if (isWasteAuditRoute) await loadWasteAuditNotes(vendorId, routeSummary?.routeId)(dispatch);
      } else dispatch(resetRouteStops());

      const includeVehicleTracking = false;
      await loadRouteMapVehicleData(vendorId, routeSummary.routeId, includeVehicleTracking, isAutoRefresh)(dispatch);

      if (formData.showVehicleTrackings) {
        // we load the vehicle tracking data from the same endpoint as the dashboard map
        const vehicles = formData?.vehicleFilters || {};
        const vehicleChecked = Object.keys(vehicles).filter(key => vehicles[key]);
        const vehicleIds = vehicleChecked.map(id => Number(id.replace('_', '')));
        if (vehicleIds.length) {
          routeSummary.routeId &&
            (await loadRouteVehiclesBreadCrumbs(
              routeSummary.routeId,
              routeSummary.date,
              vehicleIds.join(','),
              isAutoRefresh,
            )(dispatch));
        } else dispatch(resetRouteVehiclesBreadCrumbs());
      }

      if (!isAutoRefresh) {
        const filterHaulerLocations = normalizeFilterArray(formData.haulerLocationsFilters || []);
        if (filterHaulerLocations.length) {
          await loadVendorLocations(vendorId, filterHaulerLocations, isAutoRefresh)(dispatch);
        } else dispatch(resetVendorLocations());

        const filterCityAlertTypes = normalizeFilterArray(formData.cityAlertsFilters || []);
        if (filterCityAlertTypes.length) {
          const filterCityAlertSubTypes = normalizeFilterObject(formData.cityAlertsSubFilters || {});

          if (filterCityAlertSubTypes.length) {
            const filterCityAlertTypesSelected = filterCityAlertSubTypes.map(cityAlertsSubFilter =>
              Number(cityAlertsSubFilter.toString().split('_')[0]),
            );
            const filterCityAlertSubTypesSelected = filterCityAlertSubTypes.map(cityAlertsSubFilter =>
              Number(cityAlertsSubFilter.toString().split('_')[1]),
            );
            const isAllCityAlertsSelected = filterCityAlertTypesSelected.includes(ALL_CITY_ALERTS);

            await loadCityAlerts(
              filterCityAlertSubTypesSelected,
              isAllCityAlertsSelected ? undefined : routeSummary.routeId,
            )(dispatch);
          }
        } else dispatch(resetCityAlerts());

        const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);

        const normalizedGeoFencesIds = normalizeFilterObjectAsNumbersAndRemovePrefix(formData.geoFenceSubFilters);

        if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
          emptyGeoFences();

          await loadGeoFences({
            vendorId,
            geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
            limit: 200,
            geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
            routeDate: routeSummary.date,
            noLoadingIndicator: isAutoRefresh,
          })(dispatch);
        } else {
          dispatch(resetGeoFences());
          emptyGeoFences();
        }

        if (geoFence && formData.geoFencesTypesFilters[geoFence.id]) {
          setShowRouteGeoFence(true);
          await loadRouteGeoFence(routeSummary.routeId, undefined, isAutoRefresh)(dispatch);
        } else if (!normalizedGeoFencesIds.length || (geoFence && !formData.geoFencesTypesFilters[geoFence.id])) {
          emptyGeoFences();
          setShowRouteGeoFence(false);
        }

        setVehicleTrackingsFilters({
          showVehicleTrackings: formData.showVehicleTrackings,
          showStopConfirmation: formData.showStopConfirmation,
          showXDeviceTrackings: formData.showXDeviceTrackings,
          showYDeviceTrackings: formData.showXDeviceTrackings,
        });

        if (formData.showTravelPath) {
          if (travelPathForRouteId !== routeSummary.routeId) {
            await downloadTravelPathGeoJsonFile(routeSummary.routeId)(dispatch);
          }
          dispatch(setShowTravelPath(true));
        } else {
          dispatch(setShowTravelPath(false));
        }

        !size(vehicleTypes) && loadVehicleTypes()(dispatch);
        const normalizedVehicleFiltersIds = normalizeFilterObjectAsNumbersAndRemovePrefix(formData.vehicleFilters);
        setVehicleFilters(normalizedVehicleFiltersIds);

        setVehicleInsightsFilters(normalizeFilterObject(formData.vehicleInsightsFilters));
        setCityInsightsFilters(normalizeFilterArray(formData.cityInsightsFilters));
        setShowOrderNumbers(formData.showOrderNumbers);
        dispatch(setShowStopsWithLocationAlerts(formData.showStopsWithLocationAlerts));
        dispatch(setShowTemporaryStops(formData.showTemporaryStops));
        dispatch(setJobPriorityTypeIds(normalizeFilterArray(formData.jobPriorityFilters || [])));
        setMapViewStyle({ ...mapViewStyle, mapZoom: null, mapCenter: null });
        setRouteMapFiltersOpen(false);
        const isVehicleTrackingLoaded = formData.showVehicleTrackings;
        dispatch(resetIncludeVehicleTracking(isVehicleTrackingLoaded));
      }

      setIsAutoRefresh(false);
      if (formData.defaultRouteLocationId) {
        setRouteMapFiltersOpen(true);
        dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, formData.defaultRouteLocationId));
      }
    },
    [
      dispatch,
      emptyGeoFences,
      geoFence,
      isAutoRefresh,
      isUnscheduledStopsVisible,
      isWasteAuditRoute,
      mapViewStyle,
      routeSummary,
      travelPathForRouteId,
      vehicleTypes,
      vendorId,
    ],
  );

  //  TODO uncomment this when creating alert is enabled on map
  // const handleLoadCityAlertsWhenCreatedNew = useCallback(() => {
  //   const newValues = cityAlertsFilters.reduce((acc, filter) => {
  //     acc[filter.id] = true;
  //     return acc;
  //   }, [] as any);
  //   dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, 'cityAlertsFilters', newValues));
  //   dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, 'cityAlertsFilters_checkAll', true));
  //   dispatch(submit(ROUTE_MAP_FILTERS_FORM_NAME));
  // }, [cityAlertsFilters, dispatch]);

  const handleApplyLastFilters = useCallback(
    (currentRouteFilters: RouteMapFiltersFormValues) => {
      const isLastFiltersApplied = true;
      handleSubmitFilters({ ...currentRouteFilters, isLastFiltersApplied });
    },
    [handleSubmitFilters],
  );

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: mapInstance?.getCenter(),
      mapZoom: mapInstance?.getZoom(),
    });
  };

  if (isEditMode && isRouteMapFiltersOpen) setRouteMapFiltersOpen(false);

  // AUTO_REFRESH - WHEN THE ROUTE IS IN PROGRESS
  // refresh the vehicle positions and trackings if route is in progress
  const REFRESH_INTERVAL = 30000; // 30 seconds

  const refreshVehiclePositionsAndTrackings = useCallback(() => {
    if (
      routeSummary?.routeStatusTypeId === IN_PROGRESS &&
      getIsVendorNotChanged(vendorId) &&
      Cookie.get(SESSION_COOKIE_KEY) &&
      !isEditMode
    ) {
      setIsAutoRefresh(true);
      // have to wrap it in a async timeout to wait for the auto refresh to be set
      setTimeout(() => {
        dispatch(submit(ROUTE_MAP_FILTERS_FORM_NAME));
      });
    }
  }, [dispatch, isEditMode, routeSummary?.routeStatusTypeId, vendorId]);

  useRefreshDataInterval({
    callback: refreshVehiclePositionsAndTrackings,
    interval: REFRESH_INTERVAL,
    refreshOnWindowFocus: true,
  });

  const getFeatureStyle = ({
    feature,
    index,
    state,
  }: {
    feature: any;
    index: number;
    state: 'SELECTED' | 'HOVERED' | 'INACTIVE' | 'UNCOMMITTED' | 'CLOSING';
  }) => {
    const cursor =
      drawMode instanceof EditingMode ? 'pointer' : drawMode instanceof DrawPolygonMode ? 'crosshair' : 'default';

    if (state === 'SELECTED' || state === 'HOVERED') {
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };
    }
    return {
      stroke: mapViewStyle.isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.strokeColor : GEO_FENCE_OPTIONS.strokeColor,
      fill: mapViewStyle.isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.fillColor : GEO_FENCE_OPTIONS.fillColor,
      strokeWidth: 2,
      fillOpacity: 0.1,
      cursor,
    };
  };

  // route does not exist
  if (!routeSummary) return null;

  return (
    <>
      {isDebugMode && <RouteMapDebug geoFenceIsActive={false} routeId={routeSummary.routeId} />}

      <MapGLWrapper
        isEditMode={isEditMode}
        drawingEnabled={!!drawMode && editPolygon}
        isDrawing={drawMode && drawMode instanceof DrawPolygonMode}
      >
        {isEditMode && <EditableMapGLInfo>{translate('maps.instructions.dragStops')}</EditableMapGLInfo>}
        <MapGL
          dragPan={dragPan}
          disableDefaultSatelliteView
          enableNewSatelliteView
          disableDefaultNavigationControl
          enableNewNavigationControl
          viewport={viewport}
          onMapRefLoaded={setMapInstance}
          interactiveLayerIds={getInteractiveLayers()}
          setIsSatelliteViewEnabled={handleMapViewStyleChange}
        >
          {!isMapSmall && (
            <>
              <ComplexMapControl position="top-left" vertical>
                {isEditMode ? (
                  <>
                    <TooltipIconButton
                      tooltipAsString
                      tooltip={translate(
                        `routeTemplateBuilder.${
                          optimizedRouteStopsLength > 0
                            ? 'drawPolygonUnavailable'
                            : drawMode
                            ? 'deletePolygon'
                            : 'drawPolygon'
                        }`,
                      )}
                      tooltipPosition="right"
                      tooltipColor="grayDarker"
                      color={drawMode ? 'warning' : 'secondary'}
                      margin="no"
                      disabled={optimizedRouteStopsLength > 0}
                      onClick={
                        drawMode
                          ? () => handleExitDrawMode(true)
                          : () => {
                              setEditPolygon(true);
                              handleEnterDrawMode();
                            }
                      }
                    >
                      <IconButtonIcon
                        icon={drawMode ? 'delete' : 'lasso'}
                        size="large"
                        color={drawMode ? 'white' : 'primary'}
                      />
                    </TooltipIconButton>
                    {polygon && (
                      <TooltipIconButton
                        tooltipAsString
                        tooltip={translate(
                          editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
                        )}
                        tooltipPosition="right"
                        tooltipColor="grayDarker"
                        margin="small no"
                        color={editPolygon ? 'primary' : 'secondary'}
                        onClick={() => {
                          setEditPolygon(!editPolygon);
                        }}
                      >
                        <IconButtonIcon margin="no" icon="edit" />
                      </TooltipIconButton>
                    )}
                  </>
                ) : (
                  <TooltipIconButton
                    tooltip="mapFilters"
                    tooltipPosition="right"
                    tooltipColor="grayDarker"
                    color="secondary"
                    margin="no"
                    onClick={() => setRouteMapFiltersOpen(true)}
                  >
                    <IconButtonIcon icon="mapFilter" color="primary" />
                  </TooltipIconButton>
                )}
              </ComplexMapControl>
              <ComplexMapControl position="top-right">
                <TooltipIconButton
                  tooltip="mapActions"
                  tooltipPosition="left"
                  tooltipColor="grayDarker"
                  color="secondary"
                  margin="no"
                  onClick={() => setRouteMapActionsOpen(true)}
                >
                  <IconButtonIcon icon="mapActions" color="primary" />
                </TooltipIconButton>
              </ComplexMapControl>

              {isSnowPlowRoute && (
                <ComplexMapControl vertical position="bottom-left" xOffset={50}>
                  <MapLegend collapsed={isLegendCollapsed} onClick={() => setIsLegendCollapsed(!isLegendCollapsed)}>
                    <b>{translate('routes.snowPlow.legend')}</b>
                    <Icon icon="chevronDown" />
                    {isLegendCollapsed && (
                      <MapLegendInfo>
                        <StatusContainer vertical>
                          <PassesStatus color={PICKUP_STATUS_LABEL_COLORS[COMPLETED]}></PassesStatus>
                          <Text margin="no no no small">{translate('routes.routeStatuses.completed')}</Text>
                        </StatusContainer>
                        <StatusContainer vertical margin="small no no no">
                          <PassesStatus color={PICKUP_STATUS_LABEL_COLORS[SCHEDULED]}></PassesStatus>
                          <Text margin="no no no small">{translate('routes.routeStatuses.scheduled')}</Text>
                        </StatusContainer>
                      </MapLegendInfo>
                    )}
                  </MapLegend>
                </ComplexMapControl>
              )}

              {/* TODO uncomment this when creating alert is enabled on map */}
              {/* <CityAlertCreateTrigger
                xOffset={0}
                yOffset={50}
                position="top-right"
                onSavedSuccess={handleLoadCityAlertsWhenCreatedNew}
                routeId={routeSummary.routeId}
              /> */}
            </>
          )}

          {drawMode && (
            <Editor
              ref={editorRef}
              clickRadius={12}
              mode={drawMode}
              features={polygon ? polygon : []}
              onUpdate={(feature: any) => (isEditMode ? checkLocationsInPolygon(feature) : onEditGeoFence(feature))}
              onSelect={handleSelectInGeoFenceEditor}
              featureStyle={getFeatureStyle}
            />
          )}

          {mapInstance && (
            <>
              <RouteMapTravelPathGL map={mapInstance} />
              <RouteMapClustersGL
                map={mapInstance}
                routeStops={filteredStops}
                vehicleTrackings={filteredVehicleTrackings}
                vehiclePositions={filteredVehiclePositions}
                vehicleInsights={filteredVehicleInsights}
                cityInsights={filteredCityInsights}
              />
              <RouteMapVehiclePositionsGL map={mapInstance} />
              <RouteMapRouteStopsGL
                map={mapInstance}
                showOrderNumbers={showOrderNumbers}
                isEditMode={isEditMode}
                zoomLevel={routeSummary.vehicleTypeId === DELIVERY_UTILITY_ID ? 10 : 15}
              />

              <RouteMapApplicationStatusGL map={mapInstance} />
              <RouteMapVehicleInsightsGL map={mapInstance} />
              <RouteMapCityInsightsGL map={mapInstance} />
              <RouteMapHaulerLocationsGL map={mapInstance} />

              <RouteMapVehicleTrackingsGL map={mapInstance} vehicleTrackings={filteredVehicleTrackings} />
              <RouteMapGeoFencesGL
                map={mapInstance}
                geoFencesGeoJSON={drawMode ? [] : allGeoFencesGeoJson}
                isSatellite={mapViewStyle.isSatelliteEnabled}
              />

              <CityAlertsGL map={mapInstance} />

              <DebugMapVehicleTrackingSources
                map={mapInstance}
                type={DebugMapVehicleTrackingType.Mobile}
                onAddInteractiveLayers={addInteractiveLayers}
              />
              <DebugMapVehicleTrackingSources
                map={mapInstance}
                type={DebugMapVehicleTrackingType.Raw}
                onAddInteractiveLayers={addInteractiveLayers}
              />
            </>
          )}
        </MapGL>
        {drawMode && <DrawingInstructions />}

        {routeSummary.routeStatusTypeId === IN_PROGRESS && !isEditMode && (
          <MapLastRefresh
            isSatelliteView={mapViewStyle.isSatelliteEnabled}
            lastRefreshed={lastRefreshed}
            onRefresh={refreshVehiclePositionsAndTrackings}
          />
        )}
      </MapGLWrapper>

      <RouteMapFiltersForm
        onMount={onFiltersReady}
        closeRouteMapFilters={() => setRouteMapFiltersOpen(false)}
        handleApplyLastFilters={handleApplyLastFilters}
        initialValues={filtersFormInitialValues}
        isRouteMapFiltersOpen={isRouteMapFiltersOpen}
        isInSomeSortOfEditMode={isEditMode || isDrawingMode || !!drawMode}
        onSubmit={handleSubmitFilters}
      />

      <RouteMapActions
        isRouteMapActionsOpen={isRouteMapActionsOpen}
        closeRouteMapActions={() => setRouteMapActionsOpen(false)}
        polygonsInEdit={polygon}
        drawMode={drawMode}
        isEditingStops={isEditMode}
        isAddingPolygon={isAddingPolygon}
        enterDrawMode={handleEnterDrawMode}
        editGeoFence={editGeoFence}
        exitDrawMode={handleExitDrawMode}
        handleAddPolygon={handleAddPolygon}
        handleDeletePolygon={handleDeletePolygon}
        undoDraw={handleUndoGeoFence}
        showRouteGeoFence={(id?: number) => {
          setShowRouteGeoFence(true);
          (geoFence || id) &&
            dispatch(change(ROUTE_MAP_FILTERS_FORM_NAME, `geoFencesTypesFilters[${geoFence?.id || id}]`, true));
        }}
        removeRouteGeoFence={removeGeoFence}
        selectedGeoFence={selectedGeoFenceGeo}
      />
    </>
  );
}
