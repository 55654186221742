import React, { Component } from 'react';

import { WrappedFieldProps } from 'redux-form';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import { FormGroup, FormError, IntlPhoneInputWrapper } from './styled';
import { UNITED_STATES } from '../../common/constants';
import translate from '../services/translate';

interface Props extends WrappedFieldProps {
  changeFieldValue: (value: string) => void;
  initialValue: string;
}

class IntlPhoneInput extends Component<Props, { value?: string }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.initialValue,
    };
  }

  onChange = (phone: string) => {
    this.setState({ value: phone });
    this.props.changeFieldValue(phone);
  };

  render() {
    const {
      input,
      meta: { asyncValidating, submitFailed, error },
    } = this.props;

    return (
      <FormGroup hasValue={this.state.value} isLoading={asyncValidating}>
        <IntlPhoneInputWrapper>
          <PhoneInput
            international
            country={UNITED_STATES}
            placeholder={translate('common.phone')}
            defaultCountry={UNITED_STATES}
            {...input}
          />
        </IntlPhoneInputWrapper>
        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

export default IntlPhoneInput;
