import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const PILOT_ID = 1;
export const CONTRACT_ID = 2;

export const AGREEMENT_TYPES = mapKeys(
  [
    { id: PILOT_ID, name: translate('vendors.agreementTypes.pilot'), technicalName: 'Pilot' },
    { id: CONTRACT_ID, name: translate('vendors.agreementTypes.contract'), technicalName: 'Contract' },
  ],
  'id',
);
