import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash-es';

import { Text, Button } from 'src/core/components/styled';
import Icon from '../../../../../core/components/Icon';
import { WEEKDAYS_BY_ID } from '../../../../../core/constants/weekdays';
import translate from '../../../../../core/services/translate';
import { VEHICLE_TYPE_IDS } from '../../../../../fleet/constants/vehicleTypes';
import store from '../../../../../store';
import { DRAFT, NEW, RTB_TEMPLATE_STATUSES_MAP } from '../../../../constants/routeTemplateBuilder';
import {
  ChangeLogRow,
  ChangeLogChangesContainer,
  RouteTemplateBuilderMapControlContainer,
  ChangeLogRouteTemplateTitleContainer,
  RouteTemplateBuilderMapControlToggle,
  RouteTemplateStatus,
  ChangeLogRowServicesText,
  ChangeLogRowIcon,
  ChangeLogDetailsContainer,
  ChangeLogDetails,
  ChangeLogRouteTemplateEditButton,
} from '../../../styled/RouteTemplateBuilderMap';
import {
  loadRouteTemplateDraftServices,
  clearSelectedDraft,
  setSelectedDraftId,
  refreshWorkSession,
  setMapDrawingEnabled,
  deleteWorkSessionRouteTemplateDraft,
} from 'src/routes/ducks/routeTemplateBuilder';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { revertRouteTemplateDraftVersion } from 'src/routes/services/routeTemplateBuilder';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import confirm from 'src/core/services/confirm';
import RouteTemplateBuilderCreateRouteModalResolver from '../routeTemplateBuilderModals/RouteTemplateBuilderCreateRouteModalResolver';
import { useSelector } from 'src/core/hooks/useSelector';

const handleErrorCode = (code: string) =>
  createErrorNotification(translate(createTranslationKey(code, 'routeTemplateBuilder.publishErrors')));

export const RouteTemplateBuilderChangeLog: React.FC = () => {
  const [activeChangeLogId, setActiveChangeLogId] = useState<number | null>(null);
  const [isEditRouteTemplateModalOpen, setIsEditRouteTemplateModalOpen] = useState(false);

  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const vehicleTypeId = useSelector(s => s.routes.routeTemplateBuilder.mapFilters.vehicleTypeId);
  const workSessionId = useSelector(s => s.routes.routeTemplateBuilder.workSession?.id);
  const rawChangeLog = useSelector(s => orderBy(s.routes.routeTemplateBuilder.changeLogs, 'id', 'desc'));
  const selectedDraftLoading = useSelector(s => s.routes.routeTemplateBuilder.selectedDraftLoading);
  const selectedDraftId = useSelector(s => s.routes.routeTemplateBuilder.selectedDraftId);
  const selectedDraftVersionId = useSelector(s => s.routes.routeTemplateBuilder.selectedDraftVersionId);
  const selectedDraft = useSelector(s => s.routes.routeTemplateBuilder.selectedDraft);
  const [isOpen, setIsOpen] = useState(!!selectedDraft);

  const changeLog = React.useMemo(
    () => rawChangeLog.filter(log => log.vehicleTypeId === vehicleTypeId),
    [rawChangeLog, vehicleTypeId],
  );

  const activeChangeLog = React.useMemo(
    () => changeLog.find(log => log.id === activeChangeLogId),
    [changeLog, activeChangeLogId],
  );

  React.useEffect(() => {
    if (activeChangeLog) {
      dispatch(setSelectedDraftId(activeChangeLog.id, activeChangeLog.changeLogs[0].id, true));
    }
  }, [activeChangeLog, dispatch]);

  React.useEffect(() => {
    if (
      !selectedDraftId ||
      (!!selectedDraft &&
        selectedDraft.id === selectedDraftId &&
        (!selectedDraftVersionId || selectedDraft.changeLogId === selectedDraftVersionId))
    ) {
      return;
    }

    loadRouteTemplateDraftServices(selectedDraftId, selectedDraftVersionId)(dispatch);
  }, [selectedDraftId, selectedDraftVersionId, selectedDraft, dispatch]);

  React.useEffect(() => {
    if (!!selectedDraft && selectedDraft.id !== activeChangeLogId) {
      setActiveChangeLogId(selectedDraft.id);
    }
  }, [selectedDraft, activeChangeLogId]);

  const activeChangeLogPublishedStatus = !!activeChangeLog?.routeTemplateId ? DRAFT : NEW;

  const revertVersion = async (draftId: number, versionId: number) => {
    if (
      !workSessionId ||
      !vendorId ||
      !(await confirm(
        translate('routeTemplateBuilder.confirmDraftRevert'),
        translate('routeTemplateBuilder.confirmDraftRevertDescription'),
      ))
    ) {
      return;
    }

    revertRouteTemplateDraftVersion(draftId, versionId)
      .then(() => {
        createSuccessNotification(translate('routeTemplateBuilder.versionRevertedSuccessfully'));
        refreshWorkSession()(dispatch, store.getState);
        dispatch(clearSelectedDraft());
        dispatch(setSelectedDraftId(draftId));
      })
      .catch(error => {
        switch (error?.response?.data?.code) {
          case 'RouteTemplatePlannerDeleteDestinationRouteTemplate':
            handleErrorCode(error.response.data.code);
            break;

          default:
            createErrorNotification(translate('routeTemplateBuilder.versionRevertedFailed'));
            break;
        }
      });
  };

  return (
    <>
      <RouteTemplateBuilderMapControlContainer isOpen={isOpen}>
        <RouteTemplateBuilderMapControlToggle
          onClick={() => {
            if (activeChangeLog) {
              dispatch(clearSelectedDraft());
              dispatch(setMapDrawingEnabled(false));
              setActiveChangeLogId(null);
            } else {
              setIsOpen(!isOpen);
            }
          }}
        >
          <Icon height="14px" icon={activeChangeLog ? 'arrowLeft' : !isOpen ? 'arrowDownFill' : 'arrowUpFill'} />

          <div>
            {activeChangeLog
              ? translate('routeTemplateBuilder.goBack')
              : translate('routeTemplateBuilder.changeLog', { count: changeLog.length })}
          </div>
        </RouteTemplateBuilderMapControlToggle>

        {isOpen && (
          <ChangeLogChangesContainer>
            {!activeChangeLog &&
              changeLog.map(log => {
                const { id, routeTemplateId, name, dayOfService, vehicleTypeId, serviceContractsCount } = log;
                const publishedStatus = !!routeTemplateId ? DRAFT : NEW;

                return (
                  <ChangeLogRow key={id} onClick={() => setActiveChangeLogId(id)}>
                    <ChangeLogDetailsContainer>
                      <ChangeLogDetails>
                        <ChangeLogRouteTemplateTitleContainer>
                          <Text weight="medium">{name}</Text>

                          {!log.isRouteTemplateEnabled && !!log.routeTemplateId && (
                            <RouteTemplateStatus neutral margin="no no no xxSmall">
                              {translate('common.inactive')}
                            </RouteTemplateStatus>
                          )}

                          <RouteTemplateStatus statusId={publishedStatus} margin="no no no xxSmall">
                            {RTB_TEMPLATE_STATUSES_MAP[publishedStatus].name}
                          </RouteTemplateStatus>
                        </ChangeLogRouteTemplateTitleContainer>

                        <Text block size="small" margin="xxSmall no no">
                          {`${VEHICLE_TYPE_IDS[vehicleTypeId].name}${
                            !!log.wasteMaterialTypeName
                              ? `, ${translate(createTranslationKey(log.wasteMaterialTypeName, 'common.wasteTypes'))}`
                              : ''
                          }, ${WEEKDAYS_BY_ID[dayOfService].name}`}
                        </Text>

                        <ChangeLogRowServicesText>
                          {translate('routeTemplateBuilder.stopsCount', { count: serviceContractsCount })}
                        </ChangeLogRowServicesText>
                      </ChangeLogDetails>

                      <ChangeLogRowIcon />
                    </ChangeLogDetailsContainer>
                  </ChangeLogRow>
                );
              })}

            {activeChangeLog && (
              <>
                <ChangeLogRow visibleButtons noInteraction noBorder>
                  <ChangeLogDetailsContainer>
                    <ChangeLogDetails>
                      <ChangeLogRouteTemplateTitleContainer>
                        <Text weight="medium">{activeChangeLog.name}</Text>

                        {!activeChangeLog.isRouteTemplateEnabled && !!activeChangeLog.routeTemplateId && (
                          <RouteTemplateStatus neutral margin="no no no xxSmall">
                            {translate('common.inactive')}
                          </RouteTemplateStatus>
                        )}

                        <RouteTemplateStatus statusId={activeChangeLogPublishedStatus} margin="no no no xxSmall">
                          {RTB_TEMPLATE_STATUSES_MAP[activeChangeLogPublishedStatus].name}
                        </RouteTemplateStatus>
                      </ChangeLogRouteTemplateTitleContainer>

                      <Text block size="small" margin="xxSmall no no">
                        {`${VEHICLE_TYPE_IDS[activeChangeLog.vehicleTypeId].name}${
                          activeChangeLog.wasteMaterialTypeName
                            ? `, ${translate(
                                createTranslationKey(activeChangeLog.wasteMaterialTypeName, 'common.wasteTypes'),
                              )}`
                            : ''
                        }, ${WEEKDAYS_BY_ID[activeChangeLog.dayOfService].name}`}
                      </Text>
                    </ChangeLogDetails>

                    {activeChangeLogPublishedStatus === NEW && (
                      <ChangeLogRouteTemplateEditButton onClick={() => setIsEditRouteTemplateModalOpen(true)}>
                        <Icon height="14px" icon={'edit'} />
                      </ChangeLogRouteTemplateEditButton>
                    )}
                    <ChangeLogRouteTemplateEditButton
                      margin="no small"
                      onClick={async () => {
                        if (
                          await confirm(
                            translate('routeTemplateBuilder.deleteRouteTemplateDraftAlert'),
                            translate('routeTemplateBuilder.deleteRouteTemplateDraftAlertAdditionalInfo'),
                          )
                        ) {
                          deleteWorkSessionRouteTemplateDraft(activeChangeLog.id)(dispatch)
                            .then(() => {
                              refreshWorkSession(true)(dispatch, store.getState);
                              dispatch(clearSelectedDraft());
                              dispatch(setSelectedDraftId(activeChangeLog.id));
                            })
                            .catch(error => {
                              switch (error?.response?.data?.code) {
                                case 'RouteTemplatePlannerDeleteDestinationRouteTemplate':
                                case 'RouteTemplatePlannerInvalidWorkSessionRouteTemplate':
                                  handleErrorCode(error.response.data.code);
                                  break;

                                default:
                                  handleErrorCode('RouteTemplatePlannerRouteTemplateDeleteError');
                                  break;
                              }
                            });
                        }
                      }}
                    >
                      <Icon height="14px" icon={'delete'} />
                    </ChangeLogRouteTemplateEditButton>
                  </ChangeLogDetailsContainer>
                </ChangeLogRow>

                {orderBy(activeChangeLog.changeLogs, 'createdOn', 'desc').map((log, index) => {
                  const { id, createdOn, addedStops, deletedStops } = log;
                  const isSelectedVersion = id === selectedDraft?.changeLogId;
                  const isLatestVersion = !index;

                  return (
                    <ChangeLogRow
                      isLoading={
                        selectedDraftLoading && selectedDraftId === activeChangeLog.id && selectedDraftVersionId === id
                      }
                      highlight={isSelectedVersion}
                      key={id}
                      noBorder={index === 0}
                      onClick={() => {
                        dispatch(setSelectedDraftId(activeChangeLog.id, id, isLatestVersion));
                        dispatch(setMapDrawingEnabled(false));
                      }}
                    >
                      <Text block flex flexAlignCenter size="small">
                        {moment(createdOn).format('LLL')}

                        {isLatestVersion && (
                          <RouteTemplateStatus neutral>
                            {translate('routeTemplateBuilder.latestVersion')}
                          </RouteTemplateStatus>
                        )}
                      </Text>

                      <ChangeLogRowServicesText>
                        {translate('routeTemplateBuilder.stopsCount', { count: log.allStops })}
                      </ChangeLogRowServicesText>

                      {!!addedStops && (
                        <ChangeLogRowServicesText color="primary">
                          {translate('routeTemplateBuilder.addedStopsCount', { count: addedStops })}
                        </ChangeLogRowServicesText>
                      )}

                      {!!deletedStops && (
                        <ChangeLogRowServicesText color="alert">
                          {translate('routeTemplateBuilder.removedStopsCount', { count: deletedStops })}
                        </ChangeLogRowServicesText>
                      )}

                      {!isLatestVersion && (
                        <Button
                          line
                          size="xSmall"
                          color="primary"
                          onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            revertVersion(activeChangeLog.id, id);
                          }}
                        >
                          {translate('routeTemplateBuilder.revert')}
                        </Button>
                      )}
                    </ChangeLogRow>
                  );
                })}
              </>
            )}
          </ChangeLogChangesContainer>
        )}
      </RouteTemplateBuilderMapControlContainer>

      {isEditRouteTemplateModalOpen && activeChangeLog && (
        <RouteTemplateBuilderCreateRouteModalResolver
          hasAssignedStops={false}
          newRouteTemplateId={activeChangeLog.id}
          onClose={() => setIsEditRouteTemplateModalOpen(false)}
        />
      )}
    </>
  );
};
