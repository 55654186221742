import { MouseEvent, PureComponent } from 'react';

import { size } from 'lodash-es';
import { connect } from 'react-redux';

import { AdminGuard } from 'src/account/components';
import { updateSessionUser } from 'src/account/services/session';
import { isSingleSignOnFeatureEnabled } from 'src/vendors/ducks/features';
import { isSuperAdminSelector } from '../../../account/ducks';
import { requestPasswordReset } from '../../../account/services/account';
import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { Table } from '../../../core/components';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { setCurrentLanguageId } from '../../../core/services/i18n';
import { multiWordAndSearch } from '../../../core/services/search';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { ACTIVE_USER_STATUS, INACTIVE_USER_STATUS } from '../../constants/userStatuses';
import {
  changePassword,
  deleteUser,
  loadUsers,
  resetUsers,
  saveAssignedVendors,
  saveDivision,
  saveUser,
  saveVendorAccess,
} from '../../ducks';
import { Districts } from '../../interfaces/DivisionAccess';
import { AssignedVendors, UserChangePassword } from '../../interfaces/UserEditor';
import { UsersFilterForm } from '../forms';
import { ChangePasswordModalResolver, UserEditorModal } from '../modals';
import { UsersPageTableRow } from './';
import { Users } from './../../interfaces/UserEditor';

interface Props {
  assignedVendors: AssignedVendors[];
  changePassword: DuckFunction<typeof changePassword>;
  deleteUser: DuckFunction<typeof deleteUser>;
  districts: Districts[];
  isLoading: boolean;
  isSingleSignOnEnabled: boolean;
  isSuperAdmin: boolean;
  loadUsers: DuckFunction<typeof loadUsers>;
  resetUsers: DuckAction<typeof resetUsers>;
  saveAssignedVendors: DuckAction<typeof saveAssignedVendors>;
  saveDivision: DuckFunction<typeof saveDivision>;
  saveUser: DuckFunction<typeof saveUser>;
  saveVendorAccess: DuckFunction<typeof saveVendorAccess>;
  userId: string;
  users?: Users[];
  vendorId: number;
}

interface FilterState {
  userRole?: number;
  userSearchTerm?: string;
  userStatus?: string;
}

interface State {
  filters: FilterState;
  isUserEditorModalOpen: boolean;
  isUserSuperAdmin: boolean;
  passwordManagementModalIsOpen: boolean;
  selectedUserId?: string;
}

class UsersPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filters: {
        userSearchTerm: undefined,
        userRole: undefined,
        userStatus: undefined,
      },
      isUserEditorModalOpen: false,
      isUserSuperAdmin: false,
      passwordManagementModalIsOpen: false,
      selectedUserId: undefined,
    };
  }

  componentWillUnmount() {
    this.props.resetUsers();
  }

  onSubmit = (formData?: Users) => {
    const {
      assignedVendors,
      districts,
      isSuperAdmin,
      loadUsers,
      saveUser,
      saveDivision,
      saveVendorAccess,
      userId,
      vendorId,
    } = this.props;

    if (formData?.userTypeId === -1) formData.userTypeId = null;
    saveUser(vendorId, formData)
      .then(({ data }) => {
        if (isSuperAdmin && size(assignedVendors)) {
          saveVendorAccess(vendorId, data.userId, assignedVendors);
        }
        if (districts) {
          saveDivision(vendorId, data.userId, districts);
        }
        if (userId === formData?.userId) {
          updateSessionUser(data);
          setCurrentLanguageId(formData?.languageId);
          window.location.reload();
        }
        loadUsers(vendorId);
        createSuccessNotification(`${translate('vendors.alertMessages.userSaved')}`);
        this.closeUserEditorModal(true);
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
      });
  };

  onSubmitChangePassword = (formData: UserChangePassword) => {
    this.props
      .changePassword(formData)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.passwordChanged'));
        this.closePasswordManagementModal(true);
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
      });
  };

  openPasswordManagementModal = (event: MouseEvent) => {
    event.stopPropagation();
    this.setState({ passwordManagementModalIsOpen: true });
  };

  closePasswordManagementModal = async (pristine = true) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
      return;
    }

    this.setState({ passwordManagementModalIsOpen: false });
  };

  openUserEditorModal = (event: MouseEvent, isRubiconSuperAdmin: boolean, userId?: string) => {
    event.stopPropagation();
    this.setState({ isUserEditorModalOpen: true, selectedUserId: userId, isUserSuperAdmin: isRubiconSuperAdmin });
  };

  closeUserEditorModal = async (formPristine: boolean) => {
    const { isSuperAdmin } = this.props;
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({ isUserEditorModalOpen: false, selectedUserId: undefined, isUserSuperAdmin: false });
    if (isSuperAdmin) this.props.saveAssignedVendors([], [], false);
  };

  deleteUser = async (userId: string, event: MouseEvent) => {
    const { deleteUser, vendorId } = this.props;
    event.stopPropagation();
    if (!(await confirm(translate('vendors.alertMessages.confirmDeleteUser')))) {
      return;
    }

    deleteUser(vendorId, userId)
      .then(() => {
        createSuccessNotification(`${translate('vendors.alertMessages.userDeleted')}`);
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
      });
  };

  resetUserPassword = async (event: MouseEvent, userId: string) => {
    const { users } = this.props;
    const selectedUser = users?.find(user => user.userId === userId);

    event.stopPropagation();

    if (!selectedUser || !(await confirm(translate('vendors.alertMessages.confirmResetUserPassword')))) {
      return;
    }

    requestPasswordReset(selectedUser.email, true)
      .then(() => createSuccessNotification(translate('vendors.alertMessages.resetUserPasswordSuccessful')))
      .catch(() => createErrorNotification(translate('vendors.alertMessages.resetUserPasswordFailed')));
  };

  filterUsers = () => {
    const { userSearchTerm, userRole, userStatus } = this.state.filters;
    const { users } = this.props;

    const filteredUsers = users?.filter(
      user =>
        (!userRole || user.roleId === userRole) &&
        (!userStatus || (user.isAccountActive ? ACTIVE_USER_STATUS : INACTIVE_USER_STATUS) === userStatus) &&
        (!userSearchTerm ||
          multiWordAndSearch(user.name, userSearchTerm) ||
          multiWordAndSearch(user.email, userSearchTerm)),
    );

    return filteredUsers;
  };

  setFilterValue = (filter: string, value: any) => {
    this.setState(prevState => ({ filters: { ...prevState.filters, [filter]: value } }));
  };

  render() {
    const { isLoading, isSingleSignOnEnabled } = this.props;
    const { isUserEditorModalOpen, isUserSuperAdmin, selectedUserId, passwordManagementModalIsOpen } = this.state;
    const usersTableCells = [
      { name: 'name', label: translate('common.name'), width: '20%' },
      { name: 'email', label: translate('common.email'), width: '30%' },
      { name: 'roleName', label: translate('vendors.role'), width: '10%' },
      { name: 'isAccountActive', label: translate('common.active'), width: '10%' },
      { name: 'lastLoginDateTime', label: translate('vendors.lastLoggedIn'), width: '20%' },
      { name: 'options', label: translate('common.options'), width: '10%', align: 'right' },
    ];

    const filteredUsers = this.filterUsers();

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to="/account-settings">
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle>{translate('vendors.users')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>

          {!isSingleSignOnEnabled && (
            <PageActions>
              <AdminGuard>
                <Button
                  line
                  color="primary"
                  onClick={(event: MouseEvent) => this.openPasswordManagementModal(event)}
                  margin="no small no no"
                >
                  {translate('vendors.managePasswords')}
                </Button>
              </AdminGuard>

              <Button
                color="primary"
                onClick={(event: MouseEvent) => this.openUserEditorModal(event, isUserSuperAdmin)}
                margin="no"
              >
                {translate('vendors.addUser')}
              </Button>
            </PageActions>
          )}
        </PageHeader>

        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <UsersFilterForm setFilterValue={this.setFilterValue} />
            <PanelSection>
              {!!size(filteredUsers) && (
                <Table
                  cells={usersTableCells}
                  rows={filteredUsers}
                  rowProps={{
                    deleteUser: this.deleteUser,
                    editUser: this.openUserEditorModal,
                    resetUserPassword: this.resetUserPassword,
                  }}
                  rowComponent={UsersPageTableRow}
                />
              )}
            </PanelSection>

            {!size(filteredUsers) && <Message padding="sMedium">{translate('vendors.noUsers')}</Message>}
            {!!isUserEditorModalOpen && (
              <UserEditorModal
                closeModal={this.closeUserEditorModal}
                onSaveUser={this.onSubmit}
                userId={selectedUserId}
                isUserSuperAdmin={isUserSuperAdmin}
              />
            )}
          </PanelSectionGroup>
        </Panel>

        {!!passwordManagementModalIsOpen && (
          <ChangePasswordModalResolver
            onSubmit={this.onSubmitChangePassword}
            closeModal={this.closePasswordManagementModal}
          />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  assignedVendors: state.vendors.vendorAccess.assignedUserVendors,
  districts: state.vendors.users.districts,
  isLoading: state.vendors.vendors.isLoading,
  isSingleSignOnEnabled: isSingleSignOnFeatureEnabled(state),
  isSuperAdmin: isSuperAdminSelector(state.account.login),
  userId: state.account.login.user.userId,
  users: state.vendors.users.users,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  changePassword,
  deleteUser,
  loadUsers,
  resetUsers,
  saveAssignedVendors,
  saveDivision,
  saveUser,
  saveVendorAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
