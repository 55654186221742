import { camelCase, round } from 'lodash-es';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Box } from 'src/core/components/styled/Box';
import { Button } from 'src/core/components/styled';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { getSpeedInformation } from 'src/common/services/getSpeedInformation';
import { isPostTripFeatureEnabled, isPreTripFeatureEnabled } from 'src/vendors/ducks/features';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupSubtitle,
  MapGLPopupTitle,
} from 'src/common/components/map/MapGLPopup';
import { setMapModalData } from 'src/dashboard/ducks/mapControls';
import { time } from 'src/utils/services/formatter';
import { useSelector } from 'src/core/hooks/useSelector';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

interface Props {
  isRouteVehicle?: boolean;
  vehicleDateTime?: Date | string;
  vehicleDriverName?: string;
  vehicleId: number;
  vehicleMilesDriven?: number;
  vehicleName: string;
  vehicleOdometer?: number;
  vehicleStatus: string;
  vehicleType: string;
}

const DashboardVehiclePositionsGLPopup: FC<Props> = ({
  isRouteVehicle,
  vehicleDateTime,
  vehicleDriverName,
  vehicleId,
  vehicleMilesDriven,
  vehicleName,
  vehicleOdometer,
  vehicleStatus,
  vehicleType,
}) => {
  const dispatch = useDispatch();

  const isPreTripEnabled = useSelector(state => isPreTripFeatureEnabled(state));
  const isPostTripEnabled = useSelector(state => isPostTripFeatureEnabled(state));
  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);

  return (
    <>
      <MapGLPopup>
        <MapGLPopupTitle title={vehicleName} />

        <MapGLPopupSubtitle subtitle={translate(`vehicles.vehicleTypes.${camelCase(vehicleType)}`)} />

        <MapGLPopupContent>
          <LabeledDataView
            noWrap
            width="calc(100% - 10px)"
            size="small"
            label={translate('common.timestamp')}
            value={vehicleDateTime ? time(vehicleDateTime) : '-'}
          />

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('drivers.driverName')}
            value={vehicleDriverName || '-'}
          />

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('vehicles.vehicleStatus')}
            value={translate(`vehicles.vehicleStatuses.${vehicleStatus}`)}
          />

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('vehicles.lastReportedSpeed')}
            value={
              ((vehicleOdometer || vehicleOdometer === 0) &&
                getSpeedInformation(round(vehicleOdometer), getMeasurementType(systemOfMeasurementId))) ||
              '-'
            }
          />

          <LabeledDataView
            width="calc(100% - 10px)"
            size="small"
            label={translate('vehicles.totalMilesDriven')}
            value={vehicleMilesDriven || '-'}
          />

          {(isPreTripEnabled || isPostTripEnabled) && (
            <LabeledDataView noWrap width="calc(100% - 10px)" size="small">
              <Box textDecoration="underline">
                <Link to={`/fleet/vehicles/${vehicleId}/inspection-data?&source=dashboard`}>
                  {translate('vehicles.vehicleInspections.viewInspections')}
                </Link>
              </Box>
            </LabeledDataView>
          )}
          {isRouteVehicle && (
            <LabeledDataView noWrap width="calc(100% - 10px)" size="small">
              <Button
                size="small"
                color="primary"
                onClick={() =>
                  dispatch(
                    setMapModalData({
                      date: vehicleDateTime ? vehicleDateTime.toString() : null,
                      vehicleId,
                      isSourceRoute: true,
                      isModalMapOpen: true,
                    }),
                  )
                }
                text
                underline
              >
                {translate('dashboard.filterKeys.viewFullVehicleTrackingData')}
              </Button>
            </LabeledDataView>
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </>
  );
};

export default DashboardVehiclePositionsGLPopup;
