import React, { Fragment, MouseEvent, PureComponent } from 'react';

import { COMPLETED, IN_PROGRESS, PICKUP_STATUSES, PLACED_ON_HOLD } from '../../../../common/constants';
import { Option } from '../../../../common/interfaces/Option';
import { TechnicalType } from '../../../../common/interfaces/TechnicalType';
import { ActionButtonTooltip, DragHandle, UnconnectedCheckbox, UnconnectedSelect } from '../../../../core/components';
import {
  Container,
  Label,
  TableActionButton,
  TableCell,
  TableRow,
  TableRowDraggable,
  Text,
} from '../../../../core/components/styled';
import { TABLE_ROW_HEIGHT } from '../../../../core/constants';
import { PICKUP_STATUS_LABEL_COLORS } from '../../../constants';
import { RouteLocation } from '../../../interfaces/RouteLocation';
import { RouteLocationNotesModalResolver } from '../../modals';
import { SelectedLocation } from '../../RouteMap';

const isDelete = (isManager: boolean, statusId: number) =>
  !isManager || (isManager && statusId !== COMPLETED && statusId !== IN_PROGRESS && statusId !== PLACED_ON_HOLD);

interface Address {
  street: string;
  streetNumber: number;
}

type Props = RouteLocation & {
  accountNumber?: number;
  accountStatusOptions: Option[];
  address: Address;
  customerName: string;
  deleteRouteLocation: (event: MouseEvent<HTMLElement>, orderNumber: number, locationId: number) => void;
  deleteRouteTemplateLocation: (event: MouseEvent<HTMLElement>, orderNumber: number, locationId: number) => void;
  editJobStatus: (event: MouseEvent<HTMLElement>, routeLocation: SelectedLocation) => void;
  editService: (event: MouseEvent<HTMLElement>, serviceId: number, routeLocationId: number, customerId: number) => void;
  enablePickupType?: boolean;
  hasStrobeData?: boolean;
  isChecked: boolean;
  isSearchInitialized: boolean;
  isVendorManager: boolean;
  locationName: string;
  onAccountStatusChange: (value: string | number, locationId: number, orderNumber: number) => void;
  onLocationSelected: (locationId: number, orderNumber: number) => void;
  onRoutePickupTypeChange: (value: string | number, locationId: number) => void;
  openOrderNumberPopover: (oldOrderNumber: number, event: MouseEvent<HTMLElement>, locationId: number) => void;
  reasonCodeTypes: TechnicalType[];
  routeId: number;
  routePickupTypeOptions: Option[];
  routeStatus: number;
  selectLocation: (routeLocation: SelectedLocation, event?: any) => void;
  selectRouteTemplateLocation: (locationId: number, orderNumber: number) => void;
  serviceCount?: number;
  serviceName: string;
  vehicleId: number;
  vehicleName: string;
  vehicleTypeTechnicalName: string;
  vendorAccountNo?: number;
  wasteAuditType: TechnicalType;
  wasteMaterialTypeName?: string;
};

interface State {
  isRouteLocationImagesModalOpen: boolean;
  isRouteLocationNotesModalOpen: boolean;
}

class RouteLocationsWithZeroStopTableRow extends PureComponent<Props, State> {
  state = {
    isRouteLocationImagesModalOpen: false,
    isRouteLocationNotesModalOpen: false,
  };

  openRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: true });
  };

  closeRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: false });
  };

  render() {
    const {
      accountStatusId,
      accountStatusOptions,
      customer: { name: customerName, id: customerId },
      deleteRouteLocation,
      editJobStatus,
      editService,
      id,
      isChecked,
      isVendorManager,
      location: {
        id: locationId,
        name: locationName,
        address: { street, streetNumber, latitude, longitude },
      },
      onAccountStatusChange,
      onLocationSelected,
      onRoutePickupTypeChange,
      openOrderNumberPopover,
      orderNumber,
      pickupExceptions,
      pickupStatusId,
      pickupTypeId,
      routeId,
      routePickupTypeOptions,
      routeStatus,
      selectLocation,
      service,
      wasteAuditStatuses,
    } = this.props;

    const routeLocation = {
      customerName,
      id,
      latitude,
      locationName,
      longitude,
      orderNumber,
      pickupExceptions,
      pickupStatusId,
      pickupTypeId,
      service,
      wasteAuditStatuses,
    };

    const { isRouteLocationNotesModalOpen } = this.state;

    const tableCellWidths = ['5%', '5%', '8%', '17%', '15%', '15%', '15%', '10%', '10%'];

    const handleDragStart = (id: number) => (event: any) => {
      const dt = event.nativeEvent.dataTransfer;
      dt.setData('routeLocationId', id);
    };

    return (
      <Fragment>
        <TableRow position="relative" height={TABLE_ROW_HEIGHT} onClick={event => selectLocation(routeLocation, event)}>
          <TableRowDraggable draggable onDragStart={handleDragStart(id)} />
          <TableCell width={tableCellWidths[0]} position="relative">
            <UnconnectedCheckbox
              block
              size="small"
              checked={isChecked}
              onChange={() => onLocationSelected(id, orderNumber)}
            />
          </TableCell>

          <TableCell width={tableCellWidths[1]} align="center" padding="no">
            <DragHandle />
          </TableCell>

          <TableCell width={tableCellWidths[2]}>
            <TableActionButton onClick={event => openOrderNumberPopover(orderNumber, event, id)}>
              {orderNumber}
            </TableActionButton>
          </TableCell>

          <TableCell vertical align="center" width={tableCellWidths[3]}>
            <Text block weight="medium" margin="no no xxSmall" singleLine>
              {customerName}
            </Text>

            <Text weight="light" size="small" margin="no no xxSmall" singleLine>
              {locationName}, {streetNumber} {street}
            </Text>
          </TableCell>

          <TableCell width={tableCellWidths[4]}>
            <Label
              color={PICKUP_STATUS_LABEL_COLORS[pickupStatusId.toString()]}
              onClick={event => editJobStatus(event, routeLocation)}
            >
              {PICKUP_STATUSES[pickupStatusId].name}
            </Label>
          </TableCell>

          <TableCell width={tableCellWidths[5]}>
            <Container>
              <UnconnectedSelect
                value={accountStatusId}
                options={accountStatusOptions}
                margin="no"
                normalize={Number}
                onChange={value => onAccountStatusChange(value, id, orderNumber)}
              />
            </Container>
          </TableCell>

          <TableCell width={tableCellWidths[6]}>
            <Container>
              <UnconnectedSelect
                value={pickupTypeId}
                options={routePickupTypeOptions}
                margin="no"
                onClick={event => event.stopPropagation()}
                onChange={value => onRoutePickupTypeChange(value, id)}
              />
            </Container>
          </TableCell>

          <TableCell width={tableCellWidths[7]}>
            <Text block singleLine title={service && service.name}>
              {service && service.name}
            </Text>
          </TableCell>

          <TableCell width={tableCellWidths[8]}>
            {routeId && (
              <TableActionButton onClick={this.openRouteLocationNotesModal}>
                <ActionButtonTooltip icon="note" tooltip="notes" />
              </TableActionButton>
            )}

            {isDelete(isVendorManager, routeStatus) && (
              <TableActionButton onClick={event => deleteRouteLocation(event, orderNumber, id)}>
                <ActionButtonTooltip icon="delete" tooltip="delete" />
              </TableActionButton>
            )}

            <TableActionButton onClick={event => editService(event, service.id, locationId, customerId)}>
              <ActionButtonTooltip icon="edit" tooltip="edit" />
            </TableActionButton>
          </TableCell>
        </TableRow>

        {!!isRouteLocationNotesModalOpen && (
          <RouteLocationNotesModalResolver
            modalTitle={customerName}
            modalSubTitle={locationName}
            routeId={routeId}
            routeLocationId={id}
            closeModal={this.closeRouteLocationNotesModal}
          />
        )}
      </Fragment>
    );
  }
}

export default RouteLocationsWithZeroStopTableRow;
