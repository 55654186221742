export const onCalendarInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const supportedCharacters = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    '/',
  ];

  if (!supportedCharacters.includes(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};
