import React from 'react';
import { Layer } from 'react-map-gl';

import { ROUTE_MAP_CLUSTERS_SOURCE, ROUTE_MAP_VEHICLE_INSIGHTS_LAYER } from '../constants';

export default React.memo(function RouteMapVehicleInsightsGLLayers() {
  return (
    <>
      <Layer
        id={ROUTE_MAP_VEHICLE_INSIGHTS_LAYER}
        type="symbol"
        source={ROUTE_MAP_CLUSTERS_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], ROUTE_MAP_VEHICLE_INSIGHTS_LAYER]]}
        paint={{}}
        layout={{
          'icon-image': ['get', 'icon'],
          'icon-size': 0.7,
          'icon-allow-overlap': true,
        }}
      />
    </>
  );
});
