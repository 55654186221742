import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { saveTrackingSettings, resetTrackingSettings } from '../../ducks';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { TrackingSettingsForm } from '../forms';
import { createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface Props {
  isSaving: boolean;
  initialFormValues: any;
  saveTrackingSettings(...args: any[]): any;
  resetTrackingSettings(...args: any[]): any;
}

class TrackingSettingsPage extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetTrackingSettings();
  }

  onSubmit = async (formData: any) => {
    const { saveTrackingSettings } = this.props;

    await saveTrackingSettings(formData);
    createSuccessNotification(`${translate('settings.alertMessages.settingsSaved')}`);
  };

  render() {
    const { isSaving, initialFormValues } = this.props;

    return (
      <PanelSection centered padding="large" isLoading={isSaving}>
        <Grid centered>
          <GridColumn size="4/12">
            <TrackingSettingsForm onSubmit={this.onSubmit} initialValues={initialFormValues} />
          </GridColumn>
        </Grid>
      </PanelSection>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isSaving: state.settings.trackingSettings.isSaving,
  initialFormValues: state.settings.trackingSettings.trackingSettings,
});

const mapDispatchToProps = {
  saveTrackingSettings,
  resetTrackingSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingSettingsPage);
