import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const SCHEDULED = 1;
export const INPROGRESS = 2;
export const COMPLETED = 3;
export const CANCELED = 4;
export const REASSIGNED = 5;
export const ISSUE_REPORTED = 6;
export const SERVICED = 7;
export const PLACED_ON_HOLD = 8;

export const CHART_COLORS_BY_STATUS = mapKeys(
  [
    { id: SCHEDULED, name: translate('common.pickupStatuses.scheduled'), color: '#9fc5f9' },
    { id: INPROGRESS, name: translate('common.pickupStatuses.inProgress'), color: '#fcfe01' },
    { id: COMPLETED, name: translate('common.pickupStatuses.completed'), color: '#1e9f0e' },
    { id: CANCELED, name: translate('common.pickupStatuses.canceled'), color: '#fb9901' },
    { id: REASSIGNED, name: translate('common.pickupStatuses.reassigned'), color: '#50d38f' },
    { id: ISSUE_REPORTED, name: translate('common.pickupStatuses.issueReported'), color: '#d02b27' },
    { id: SERVICED, name: translate('common.pickupStatuses.serviced'), color: '#8348bf' },
    { id: PLACED_ON_HOLD, name: translate('common.pickupStatuses.placedOnHold'), color: '#bd10e0' },
  ],
  'id',
);

export const SNOW_OR_SWEEPER_CHART_COLORS_BY_STATUS = mapKeys(
  [
    { id: SCHEDULED, name: translate('common.pickupStatuses.notServiced'), color: '#9fc5f9' },
    { id: INPROGRESS, name: translate('common.pickupStatuses.partiallyServiced'), color: '#fcfe01' },
    { id: COMPLETED, name: translate('common.pickupStatuses.serviced'), color: '#1e9f0e' },
  ],
  'id',
);
