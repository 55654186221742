import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { Button, ButtonSet, ModalFooter, ModalSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import ContainerRelocationFieldArray from './ContainersRelocationFieldArray';

interface PropsWithoutReduxForm {
  closeModal: () => void;
  currentFacilityId: number;
  change: InjectedFormProps['change'];
}
export interface ContainersRelocationFormValues {
  relocatedContainers: any[];
  applyToAll: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<ContainersRelocationFormValues, PropsWithoutReduxForm>;

const ContainersRelocationForm = ({ currentFacilityId, pristine, change, handleSubmit, closeModal }: Props) => {
  return (
    <form onSubmit={handleSubmit}>
      <ModalSection padding="no">
        <FieldArray
          name="relocatedContainers"
          change={change}
          component={ContainerRelocationFieldArray}
          currentFacilityId={currentFacilityId}
        />
      </ModalSection>
      <ModalFooter padding="no">
        <ButtonSet margin="large auto no">
          <Button type="button" color="secondary" margin="no small" onClick={closeModal}>
            {translate('common.cancel')}
          </Button>
          <Button type="submit" color="primary" disabled={pristine}>
            {translate('common.proceed')}
          </Button>
        </ButtonSet>
      </ModalFooter>
    </form>
  );
};

export default reduxForm({
  form: 'containersRelocationForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(ContainersRelocationForm);
