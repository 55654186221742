import React from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Modal, Table } from 'src/core/components';
import { ModalSection } from 'src/core/components/styled';
import { RouteTrackerIssue } from 'src/routes/interfaces/RouteTrackerIssue';
import { RouteTrackerIssuesModalTableRow } from '.';
import translate from 'src/core/services/translate';

const getRouteTrackerIssuesTableCells = (isSnowPlowRoute: boolean, isStreetSweeperRoute: boolean) => {
  return [
    {
      name: 'stopNumber',
      label: translate(isSnowPlowRoute || isStreetSweeperRoute ? 'routes.snowPlow.order' : 'routes.stopNumber'),
      width: '11%',
    },
    { name: 'customerName', label: translate('common.customer'), width: '23%' },
    { name: 'timeStamp', label: translate('common.timestamp'), width: '14%' },
    { name: 'exception', label: translate('common.exception'), width: '15%' },
    { name: 'detail', label: translate('common.detail'), width: '15%' },
    { name: 'routeLocationStatusId', label: translate('common.status'), width: '13%' },
    { name: 'images', label: translate('common.image'), width: '9%' },
  ];
};

interface Props {
  closeModal: () => void;
  routeTrackerIssues?: RouteTrackerIssue[];
  isSnowPlowRoute: boolean;
  isStreetSweeperRoute: boolean;
  vehicleTypeTechnicalName?: string;
}

const RouteTrackerIssuesModal = (props: Props) => {
  const {
    closeModal,
    routeTrackerIssues = [],
    isSnowPlowRoute,
    isStreetSweeperRoute,
    vehicleTypeTechnicalName,
  } = props;

  return (
    <Modal title={translate('tooltips.issues')} onClose={closeModal} padding="medium" size="large">
      <ModalSection margin="medium no no">
        <Table
          cells={getRouteTrackerIssuesTableCells(isSnowPlowRoute, isStreetSweeperRoute)}
          rows={routeTrackerIssues}
          rowComponent={RouteTrackerIssuesModalTableRow}
          rowProps={{
            vehicleTypeTechnicalName,
          }}
        />
      </ModalSection>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  routeTrackerIssues: state.routes.routeTrackerIssues.routeTrackerIssues,
});

export default connect(mapStateToProps)(RouteTrackerIssuesModal);
