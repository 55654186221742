import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const CONE = 1;
export const REVERSE_CONE = 2;
export const RECTANGLE = 3;
export const TRAPEZOID = 4;

export const CONFIRMATION_MODES = mapKeys(
  [
    { id: CONE, name: translate('vendors.confirmationModes.cone') },
    { id: REVERSE_CONE, name: translate('vendors.confirmationModes.reverseCone') },
    { id: RECTANGLE, name: translate('vendors.confirmationModes.rectangle') },
    { id: TRAPEZOID, name: translate('vendors.confirmationModes.trapezoid') },
  ],
  'id',
);
