import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { RoutePlannerRoutesPayload } from 'src/routes/interfaces/routePlanner/RoutePlannerEndpointsPayload';

import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { loadRoutePlannerRouteVehicles as doLoadRoutePlannerRouteVehicles } from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerRouteVehicles/START_LOAD';
const COMPLETE_LOAD = 'routePlannerRouteVehicles/COMPLETE_LOAD';
const FAIL_LOAD = 'routePlannerRouteVehicles/FAIL_LOAD';
const START_LOAD_VEHICLES_FOR_AVAILABILITY = 'routePlannerRouteVehicles/START_LOAD_VEHICLES_FOR_AVAILABILITY';
const COMPLETE_LOAD_VEHICLES_FOR_AVAILABILITY = 'routePlannerRouteVehicles/COMPLETE_LOAD_VEHICLES_FOR_AVAILABILITY';
const FAIL_LOAD_VEHICLES_FOR_AVAILABILITY = 'routePlannerRouteVehicles/FAIL_LOAD_VEHICLES_FOR_AVAILABILITY';

const RESET = 'routePlannerRouteVehicles/RESET';

interface State {
  isLoading: boolean;
  isLoadingVehiclesForAvailability: boolean;
  vehicles: RoutePlannerRouteVehicle[];
  vehiclesForAvailability: RoutePlannerRouteVehicle[];
}

const initialState: State = {
  isLoading: false,
  isLoadingVehiclesForAvailability: false,
  vehicles: [],
  vehiclesForAvailability: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: action.vehicles,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicles: [],
        },
      });

    case START_LOAD_VEHICLES_FOR_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingVehiclesForAvailability: true,
        },
      });

    case COMPLETE_LOAD_VEHICLES_FOR_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingVehiclesForAvailability: false,
          vehiclesForAvailability: action.vehicles,
        },
      });

    case FAIL_LOAD_VEHICLES_FOR_AVAILABILITY:
      return update(state, {
        $merge: {
          isLoadingVehiclesForAvailability: false,
          vehiclesForAvailability: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicles: RoutePlannerRouteVehicle[]) => ({
  type: COMPLETE_LOAD,
  vehicles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadVehiclesForAvailability = () => ({
  type: START_LOAD_VEHICLES_FOR_AVAILABILITY,
});

const completeLoadVehiclesForAvailability = (vehicles: RoutePlannerRouteVehicle[]) => ({
  type: COMPLETE_LOAD_VEHICLES_FOR_AVAILABILITY,
  vehicles,
});

const failLoadVehiclesForAvailability = () => ({
  type: FAIL_LOAD_VEHICLES_FOR_AVAILABILITY,
});

export const resetRoutePlannerRouteVehicles = () => ({
  type: RESET,
});

export const loadRoutePlannerRouteVehicles = (params: RoutePlannerRoutesPayload) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePlannerVehiclesPromise = doLoadRoutePlannerRouteVehicles(params);

  loadRoutePlannerVehiclesPromise
    .then((vehicles: RoutePlannerRouteVehicle[]) => dispatch(completeLoad(vehicles)))
    .catch(() => dispatch(failLoad()));

  return loadRoutePlannerVehiclesPromise;
};

export const loadRoutePlannerRouteVehiclesForAvailability =
  (params: RoutePlannerRoutesPayload) => (dispatch: Dispatch) => {
    dispatch(startLoadVehiclesForAvailability());
    const loadRoutePlannerVehiclesPromise = doLoadRoutePlannerRouteVehicles(params);

    loadRoutePlannerVehiclesPromise
      .then((vehicles: RoutePlannerRouteVehicle[]) => dispatch(completeLoadVehiclesForAvailability(vehicles)))
      .catch(() => dispatch(failLoadVehiclesForAvailability()));

    return loadRoutePlannerVehiclesPromise;
  };
