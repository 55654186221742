import React, { Fragment } from 'react';
import { size } from 'lodash-es';

import { DetailsListItemDescription, Grid, GridColumn, Popover, Text } from '../../core/components/styled';
import translate from '../../core/services/translate';

const PriceDetailsPopover: React.SFC<{ displayFees: any[]; overallCharges: string; title: string }> = ({
  displayFees,
  overallCharges,
  title,
}) => (
  <Popover>
    <DetailsListItemDescription weight="medium" align="left" size="medium" margin="no no small">
      {title}
    </DetailsListItemDescription>
    <Fragment>
      {!!overallCharges && (
        <Grid margin="no no small no">
          <GridColumn size="7/12">
            <Text size="small">{translate('opportunity.overallMonthlyTotal')}</Text>
          </GridColumn>
          <GridColumn size="5/12">
            <Text underlined size="small">
              {translate('opportunity.overallMonthlyCharges', { overallCharges: overallCharges })}
            </Text>
          </GridColumn>
        </Grid>
      )}
      {!!size(displayFees) &&
        displayFees.map((fee, index) => (
          <Grid margin="no no small no" key={index}>
            <GridColumn size="7/12">
              <Text size="small">{fee.name}</Text>
            </GridColumn>
            <GridColumn size="5/12">
              <Text underlined size="small" lineHeight="8px">
                {' '}
                {fee.value}
              </Text>
            </GridColumn>
          </Grid>
        ))}
    </Fragment>
  </Popover>
);

export default PriceDetailsPopover;
