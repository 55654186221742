import { ActionButtonTooltip, Checkbox, TypedField } from 'src/core/components';
import { dateAndTime } from 'src/utils/services/formatter';
import { Label, TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { PICKUP_STATUS_LABEL_COLORS } from 'src/routes/constants';
import { RouteSegmentExtended } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import { RouteStopItem } from 'src/dashboard/interfaces/routesData';
import { SNOW_OR_SWEEPER_ROUTE_STATUSES } from 'src/common/constants';
import { StatusContainer } from 'src/routes/components/styled';
import { TABLE_ROW_HEIGHT_xLARGE } from 'src/core/constants';
import translate from 'src/core/services/translate';

const DashboardMapboxSnowPlowOrStreetSweeperRouteStopTableRow = ({
  assistingVehicleRegplate,
  id,
  isOneWay,
  locationName,
  passDateTime,
  statusId,
  streetPasses,
}: RouteStopItem & RouteSegmentExtended) => {
  return (
    <TableRow height={TABLE_ROW_HEIGHT_xLARGE}>
      <TableCell vertical padding="defaultCellVertical no" align="center" horizontalAlign="right" width="7%">
        <TypedField
          name={`routeStopsFilters.stops._${id}`}
          component={Checkbox}
          props={{
            size: 'small',
            margin: 'xxSmall no no',
          }}
        />
      </TableCell>
      <TableCell padding="no xxSmall" width="53%" vertical align="center">
        <Text size="small" weight="light" title={locationName} block>
          {locationName}
        </Text>
      </TableCell>

      <TableCell padding="no xSmall no no" width="40%" vertical align="center" horizontalAlign="right">
        <Text size="small" block weight="medium" margin="no no xxSmall" align="right">
          {translate(`customers.streetNetwork.${isOneWay ? 'oneWay' : 'twoWay'}`)}
        </Text>
        {statusId && (
          <StatusContainer>
            {assistingVehicleRegplate && (
              <TableActionButton margin="no xxSmall no no">
                <ActionButtonTooltip
                  icon="infoFill"
                  tooltipProps={{ vehicleName: assistingVehicleRegplate }}
                  tooltip="assistedBy"
                />
              </TableActionButton>
            )}
            <Label fontSize="small" color={PICKUP_STATUS_LABEL_COLORS[statusId]}>
              {SNOW_OR_SWEEPER_ROUTE_STATUSES[statusId].name}
            </Label>
          </StatusContainer>
        )}
        <Text size="small" block align="right">
          {translate('routes.snowPlow.passes')}: {`${streetPasses ? streetPasses.length : 0}`}
        </Text>
        {passDateTime && (
          <Text size="small" block title={dateAndTime(passDateTime)} align="right">
            {dateAndTime(passDateTime)}
          </Text>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DashboardMapboxSnowPlowOrStreetSweeperRouteStopTableRow;
