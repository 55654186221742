import update from 'immutability-helper';
import { findIndex, groupBy, identity, keys, map, camelCase } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { AppState } from 'src/store';
import {
  CUSTOMER_SUBTYPE_COMPOST,
  CUSTOMER_SUBTYPE_CONTAINER_YARD,
  CUSTOMER_SUBTYPE_DISPOSAL_OTHER,
  CUSTOMER_SUBTYPE_LANDFILL,
  CUSTOMER_SUBTYPE_MAINTENANCE,
  CUSTOMER_SUBTYPE_MRF,
  CUSTOMER_SUBTYPE_TRANSFER_STATION,
  CUSTOMER_SUBTYPE_TRUCK_YARD,
  CUSTOMER_SUBTYPE_WASTE_TO_ENERGY,
} from 'src/common/constants';
import { Facility } from 'src/common/interfaces/Facility';
import { Customer, CustomersQuickParams } from 'src/customers/interfaces/Customers';
import {
  deleteCustomer as doDeleteCustomer,
  loadCustomersSimplified as doLoadCustomersSimplified,
} from 'src/customers/services/customers';
import translate from 'src/core/services/translate';

// Actions
const START_LOAD = 'customers/customers/START_LOAD';
export const COMPLETE_LOAD = 'customers/customers/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/customers/FAIL_LOAD';
const START_DELETE = 'customers/customer/START_DELETE';
export const COMPLETE_DELETE = 'customers/customer/COMPLETE_DELETE';
const FAIL_DELETE = 'customers/customer/FAIL_DELETE';
const RESET = 'customers/customers/RESET';

interface State {
  isLoading: boolean;
  isDeleting: boolean;
  customers: Customer[];
  total?: number;
  sortedBy?: string;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isDeleting: false,
  customers: [],
  total: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          customers: action.customers,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          customers: undefined,
          total: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const customerIndex = findIndex(state.customers, { id: action.customerId });
      return update(state, {
        customers: { $splice: [[customerIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (customers: Customer[], total: number, sortedBy?: string) => ({
  type: COMPLETE_LOAD,
  customers,
  total,
  sortedBy,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadCustomersSimplified =
  ({
    vendorId,
    searchTerm,
    customerTypeIds,
    customerStatusTypeIds,
    isPaymentOverdue,
    page,
    limit,
    sortedBy,
    sortOrder,
    searchType,
  }: CustomersQuickParams) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadCustomersPromise = doLoadCustomersSimplified(
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      isPaymentOverdue,
      page,
      limit,
      sortedBy,
      sortOrder,
      searchType,
    );
    loadCustomersPromise
      .then(({ customers, total }) => dispatch(completeLoad(customers, total, sortedBy)))
      .catch(() => dispatch(failLoad()));
    return loadCustomersPromise;
  };

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (customerId: number) => ({
  type: COMPLETE_DELETE,
  customerId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const deleteCustomer =
  (customerId: number, shouldRecreateRoutes: boolean, moveContainersToFacilityId?: number) => (dispatch: Dispatch) => {
    dispatch(startDelete());
    const deleteCustomerPromise = doDeleteCustomer(customerId, shouldRecreateRoutes, moveContainersToFacilityId);
    deleteCustomerPromise.then(() => dispatch(completeDelete(customerId))).catch(() => dispatch(failDelete()));
    return deleteCustomerPromise;
  };

export const resetCustomers = () => ({
  type: RESET,
});

export const getCustomerLocations = (facilities: Facility[]) => {
  const facilitiesGroupBySubType = groupBy(
    facilities?.filter(
      facility =>
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_TRUCK_YARD ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_CONTAINER_YARD ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_MAINTENANCE ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_LANDFILL ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_TRANSFER_STATION ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_MRF ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_WASTE_TO_ENERGY ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_COMPOST ||
        facility.facilitySubType.id === CUSTOMER_SUBTYPE_DISPOSAL_OTHER,
    ),
    'facilitySubType.technicalName',
  );
  const customerLocations = map(keys(facilitiesGroupBySubType), customerKey => ({
    label: translate(
      `facilities.facilitySubTypes.${camelCase(facilitiesGroupBySubType[customerKey][0].facilitySubType.name)}`,
    ),
    id: facilitiesGroupBySubType[customerKey][0].facilitySubType.id,
    options: map(facilitiesGroupBySubType[customerKey], ({ locationId, name }) => ({ value: locationId, label: name })),
    isDisabled: true,
    isLabel: true,
  }));
  return customerLocations;
};

export const customerLocationsSelector = createSelector(getCustomerLocations, identity);

export const esriImportFeatureSelector = createSelector(
  (state: AppState) => state.vendors.features.features,
  featuresState => (featuresState as any as any[]).find(f => f.code === 'EsriSync' || f.code === 'ESRIIntegration'),
);
