import { Modal, Table } from 'src/core/components';
import { ModalSection, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import FuelTicketTableRow from './FuelTicketTableRow';

interface Props {
  closeModal: () => void;
  modalSubTitle?: string;
  modalTitle?: string;
}

const FuelTicketsModal = ({ closeModal, modalSubTitle, modalTitle }: Props) => {
  const fuelTickets = useSelector(state => state.routes.fuelTickets.fuelTickets);

  const tableCells = [
    { name: 'fuelingDateTime', label: translate('common.timestamp'), width: '14%' },
    { name: 'locationId', label: translate('routes.fuelingLocation'), width: '40%' },
    { name: 'fuelAmount', label: translate('routes.fuelAmount'), width: '14%' },
    { name: 'unitOfMeasureTypeId', label: translate('common.unit'), width: '10%' },
    { name: 'fuelCost', label: translate('routes.fuelCost'), width: '12%' },
    { name: 'image', label: translate('common.image'), width: '10%' },
  ];

  return (
    <Modal size="large" subTitle={modalSubTitle} title={modalTitle} onClose={closeModal} padding="medium">
      <ModalSection margin="medium no no">
        {fuelTickets.length ? (
          <Table cells={tableCells} rows={fuelTickets} rowComponent={FuelTicketTableRow} />
        ) : (
          <Text block align="center" padding="no no medium no">
            {translate('routes.noFuelTickets')}
          </Text>
        )}
      </ModalSection>
    </Modal>
  );
};

export default FuelTicketsModal;
