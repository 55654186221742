import { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadPermissions, shouldLoadPermissions } from 'src/account/ducks';
import { loadSuspendedLocations } from '../../ducks';
import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import SuspendedLocationsPage from './SuspendedLocationsPage';
import translate from 'src/core/services/translate';

const SuspendedLocationsPageResolver: FC<RouteComponentProps> = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);

  const { searchTerm, accountStatusTypeId, sortedBy, sortOrder, page } = getQueryParams(search);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadSuspendedLocations(vendorId, searchTerm, accountStatusTypeId, sortedBy, sortOrder, page)(dispatch),
    ];
    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('customers.suspendedLocations')}</DocumentTitle>
      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/customers/customers"
        resolve={loadDependencies}
        successComponent={SuspendedLocationsPage}
      />
    </>
  );
};

export default SuspendedLocationsPageResolver;
