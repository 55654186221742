import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TypedResolver } from '../../../core/components';
import PreventativeMaintenanceModal, { PreventativeMaintenanceModalProps } from './PreventativeMaintenanceModal';
import { PageLoadingOverlay } from '../../../common/components/styled';
import * as Ducks from '../../ducks/preventativeMaintenance';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadVehicle } from '../../ducks';

interface Props extends PreventativeMaintenanceModalProps {
  vehicleTypeId?: number;
  vehicleId?: number;
}

const PreventativeMaintenanceModalResolver: React.FC<Props> = ({ vehicleTypeId: rawVehicleTypeId, vehicleId, ...props }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    let vehicleTypeId: number | undefined = rawVehicleTypeId;

    if (!vehicleTypeId && !vehicleId) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [];

    if (vehicleId) {
      const { vehicleTypeId: vehicleTypeIdFromRequestedVehicleId } = await loadVehicle(vehicleId)(dispatch);

      vehicleTypeId = vehicleTypeIdFromRequestedVehicleId;
      dependencies.push(Ducks.loadPMConfigurationForVehicle(vehicleId)(dispatch));
    }

    if (vehicleTypeId) {
      const configurationIsTopLevel = !!vehicleId;

      dependencies.push(Ducks.loadPMConfigurationForVehicleType(vendorId, vehicleTypeId, configurationIsTopLevel)(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={PreventativeMaintenanceModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default PreventativeMaintenanceModalResolver;
