import humps from 'humps';
import { upperCase } from 'lodash-es';

const createTranslationKey = (value?: string, nameSpace?: string) => {
  if (!value || !nameSpace) return '';

  return value === upperCase(value)
    ? `${nameSpace}.${value.replace('&', '')}`
    : `${nameSpace}.${humps.camelize(
        value.replace('&', '').replace(',', '').replace('!', '').replace('.', '').replace('(', '').replace(')', ''),
      )}`;
};

export default createTranslationKey;
