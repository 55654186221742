import { http } from '../../core/services/http';
import { CustomerPaymentMethod } from '../interfaces/CustomerPaymentMethod';

export const getCustomerPaymentMethods = (customerId: number) =>
  http.get<CustomerPaymentMethod[]>(`payment/customer/${customerId}/paymentmethods`).then(response => response.data);

export const getLocationPaymentMethods = (locationId: number) =>
  http.get(`payment/location/${locationId}/paymentmethods`).then(response => response.data);

export const postLocationPaymentMethods = (locationId: number, paymentConnectPaymentMethodId?: string) =>
  http
    .post(`payment/paymentmethod/autopay`, { locationId, paymentConnectPaymentMethodId })
    .then(response => response.data);

export const postUnenrollAutomaticPayments = (locationId: number) =>
  http.post(`payment/autopay/unenrollment`, { locationId }).then(response => response.data);

export const postSetDefaultPayment = (locationId: number, paymentConnectPaymentMethodId: string) =>
  http
    .post(`payment/paymentmethod/default`, { locationId, paymentConnectPaymentMethodId })
    .then(response => response.data);

export const postSetAutopay = (locationId: number, paymentConnectPaymentMethodId: string) =>
  http
    .post(`payment/paymentmethod/autopay`, { locationId, paymentConnectPaymentMethodId })
    .then(response => response.data);

export const postAssignPaymentToLocations = (customerLocationIds: number[], customerExternalPaymentMethodId: number) =>
  http.post('payment/paymentmethod/customerlocation/assignment', {
    customerLocationIds,
    customerExternalPaymentMethodId,
  });

export const postDeleteCustomerPaymentMethod = (customerExternalPaymentMethodId: number) =>
  http.post('payment/paymentmethod/deletion', {
    customerExternalPaymentMethodId,
  });

export const postDeleteLocationPaymentMethod = (customerLocationExternalPaymentMethodId: number) =>
  http.post('payment/paymentmethod/customerlocation/deletion', {
    customerLocationExternalPaymentMethodId,
  });
