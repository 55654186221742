import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import {
  DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER,
  DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_SOURCE,
} from 'src/dashboard/constants/dashboardMapGL';

interface Props {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, any>;
}

const VehiclePositionsGLSource = memo(({ geoJSON }: Props) => (
  <Source type="geojson" id={DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_SOURCE} data={geoJSON} tolerance={0.0001}>
    <Layer
      id={DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER}
      type="symbol"
      source={DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_SOURCE}
      filter={[
        'all',
        ['!', ['has', 'point_count']],
        ['==', ['get', 'layer'], DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER],
      ]}
      paint={{}}
      layout={{
        'icon-image': ['get', 'icon'],
        'icon-size': 0.75,
        'icon-allow-overlap': true,
      }}
    />
  </Source>
));

export default VehiclePositionsGLSource;
