import { combineReducers } from 'redux';

import { reducer as alertTypesReducer } from './alertTypes';
import { reducer as billingCycleOptions } from './billingCycleOptions';
import { reducer as billingMethodOptions } from './billingMethodOptions';
import { reducer as contactTypesReducer } from './contactTypes';
import { reducer as containerLocationTypesReducer } from './containerLocationTypes';
import { reducer as containerStatusesReducer } from './containerStatuses';
import { reducer as containerTypesReducer } from './containerTypes';
import { reducer as dispatchOpportunitiesCountReducer } from './dispatchOpportunitiesMenuCount';
import { reducer as disposalSiteLocationsReducer } from './disposalSiteLocations';
import { reducer as equipmentConditionsReducer } from './equipmentConditions';
import { reducer as equipmentSizesReducer } from './equipmentSizes';
import { reducer as equipmentTypesReducer } from './equipmentTypes';
import { reducer as feedbackReducer } from './sendFeedback';
import { reducer as feeStructureOptionsReducer } from './feeStructure';
import { reducer as filtersReducer } from './filters';
import { reducer as fleetRouteInstancesReducer } from './fleetRouteInstances';
import { reducer as holidaysReducer } from './holidays';
import { reducer as jobPriorityTypesReducer } from './jobPriorityTypes';
import { reducer as languageOptionsReducer } from './languageOptions';
import { reducer as locationFlagTypesReducer } from './locationFlagTypes';
import { reducer as pickupFrequencyTypesReducer } from './pickupFrequencyTypes';
import { reducer as reasonCodeTypesReducer } from './reasonCodeTypes';
import { reducer as requirementTypesReducer } from './requirementTypes';
import { reducer as serviceTypesReducer } from './serviceTypes';
import { reducer as streetSegmentAgingIntervalsReducer } from './streetSegmentAgingIntervals';
import { reducer as timeMeasurementTypesReducer } from './timeMeasurementTypes';
import { reducer as unitOfMeasureTypesReducer } from './unitOfMeasureTypes';
import { reducer as userTypesReducer } from './userTypes';
import { reducer as wasteAuditTypesReducer } from './wasteAuditTypes';
import { reducer as wasteTypesReducer } from './wasteTypes';
import { reducer as businessTypesReducer } from './businessTypes';
import { reducer as wasteMaterialTypes } from './wasteMaterialTypes';
import { reducer as bulkyCancellationTimeFrameTypesReducer } from './bulkyCancellationTimeFrameTypes';
import { reducer as rateCodesReducer } from './rateCodes';
import { reducer as rateAccountingCodesReducer } from './rateAccountingCodes';

export { loadRateCodeTypes } from './rateCodes'
export { loadRateAccountingCodes } from './rateAccountingCodes'
export { loadContactTypes, resetContactTypes } from './contactTypes';
export { loadContainerLocationTypes, resetContainerLocationTypes } from './containerLocationTypes';
export { loadContainerStatuses, resetContainerStatuses } from './containerStatuses';
export { loadContainerTypes, resetContainerTypes } from './containerTypes';
export { loadDispatchOpportunitiesCount } from './dispatchOpportunitiesMenuCount';
export {
  disposalSiteLocationsByIdSelector,
  loadDisposalSiteLocations,
  resetDisposalSiteLocations,
} from './disposalSiteLocations';
export { loadBillingCycles } from './billingCycleOptions';
export { loadBillingMethods } from './billingMethodOptions';
export { loadEquipmentConditions, resetEquipmentConditions } from './equipmentConditions';
export { loadEquipmentSizes } from './equipmentSizes';
export { loadEquipmentTypes } from './equipmentTypes';
export { loadFiltersSettings, saveGlobalFiltersSettings, savePreferencesFiltersSettings } from './filters';
export { loadJobPriorityTypes, resetJobPriorityTypes } from './jobPriorityTypes';
export {
  completeSave,
  loadLanguageOptions,
  localeValue,
  resetLanguageOptions,
  saveLanguageOptions,
} from './languageOptions';
export { loadLocationFlagTypes } from './locationFlagTypes';
export {
  loadMonthlyServicesMonthlyFrequencyTypes,
  loadMonthlyServicesWeeklyFrequencyTypes,
  loadMonthlyServicesYearlyFrequencyTypes,
  loadPickupFrequencyTypes,
  technicalNameByPickupFrequencyTypeIdSelector,
} from './pickupFrequencyTypes';
export { loadReasonCodeTypes } from './reasonCodeTypes';
export {
  equipmentSizesSelector,
  equipmentTypesSelector,
  loadServiceTypes,
  resetServiceTypes,
  serviceTypeIdByTechnicalNameSelector,
  technicalNameByServiceTypeIdSelector,
} from './serviceTypes';
export { loadStreetSegmentAgingIntervals } from './streetSegmentAgingIntervals';
export { loadTimeMeasurementTypes } from './timeMeasurementTypes';
export { loadUserTypes } from './userTypes';
export { loadWasteAuditTypes } from './wasteAuditTypes';
export {
  filterWasteTypesByVehicleType,
  loadWasteTypes,
  resetWasteTypes,
  technicalNameByWasteTypeIdSelector,
} from './wasteTypes';
export { loadFleetRouteInstances } from './fleetRouteInstances';
export { loadRequirementTypes } from './requirementTypes';
export { loadUnitOfMeasureTypes } from './unitOfMeasureTypes';

export { loadAlertTypes } from './alertTypes';

export { loadFeeStructure } from './feeStructure';
export { loadBusinessTypes } from './businessTypes';

export { loadBulkyCancellationTimeFrameTypes } from './bulkyCancellationTimeFrameTypes';

export const reducer = combineReducers({
  alertTypes: alertTypesReducer,
  billingCycles: billingCycleOptions,
  billingMethods: billingMethodOptions,
  contactTypes: contactTypesReducer,
  containerLocationTypes: containerLocationTypesReducer,
  containerStatuses: containerStatusesReducer,
  containerTypes: containerTypesReducer,
  dispatchOpportunitiesCount: dispatchOpportunitiesCountReducer,
  disposalSiteLocations: disposalSiteLocationsReducer,
  equipmentConditions: equipmentConditionsReducer,
  equipmentSizes: equipmentSizesReducer,
  equipmentTypes: equipmentTypesReducer,
  feedback: feedbackReducer,
  feeStructure: feeStructureOptionsReducer,
  filters: filtersReducer,
  fleetRouteInstances: fleetRouteInstancesReducer,
  holidays: holidaysReducer,
  jobPriorityTypes: jobPriorityTypesReducer,
  languageOptions: languageOptionsReducer,
  locationFlagTypes: locationFlagTypesReducer,
  pickupFrequencyTypes: pickupFrequencyTypesReducer,
  reasonCodeTypes: reasonCodeTypesReducer,
  rateCodeTypes: rateCodesReducer,
  rateAccountingCodes: rateAccountingCodesReducer,
  requirementTypes: requirementTypesReducer,
  serviceTypes: serviceTypesReducer,
  streetSegmentAgingIntervals: streetSegmentAgingIntervalsReducer,
  timeMeasurementTypes: timeMeasurementTypesReducer,
  unitOfMeasureTypes: unitOfMeasureTypesReducer,
  userTypes: userTypesReducer,
  wasteAuditTypes: wasteAuditTypesReducer,
  wasteTypes: wasteTypesReducer,
  businessTypes: businessTypesReducer,
  wasteMaterialTypes: wasteMaterialTypes,
  bulkyCancellationTimeFrameTypes: bulkyCancellationTimeFrameTypesReducer,
});
