import { FC } from 'react';

import { Box } from 'src/core/components/styled/Box';
import { Button } from 'src/core/components/styled';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  onUpdateSegmentAttributes: (isSnowPlow: boolean) => {};
}

const UpdateSegmentAttributesExtended: FC<Props> = ({ onUpdateSegmentAttributes }) => {
  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const isStreetSweepingFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);

  return (
    <Box display="flex" flexDirection="column">
      {isSnowPlowFeatureEnabled && (
        <Button
          onClick={() => onUpdateSegmentAttributes(true)}
          color="primary"
          id="update-snow-data-button"
          size="small"
        >
          {translate('customers.streets.updateSnowData')}
        </Button>
      )}
      {isStreetSweepingFeatureEnabled && (
        <Button
          onClick={() => onUpdateSegmentAttributes(false)}
          color="primary"
          id="update-street-sweeping-data-button"
          margin="small no no"
          size="small"
        >
          {translate('customers.streets.updateStreetSweepingData')}
        </Button>
      )}
    </Box>
  );
};

export default UpdateSegmentAttributesExtended;
