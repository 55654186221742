import React from 'react';
import { camelize } from 'humps';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { filter, find, map, orderBy, sortBy, upperFirst } from 'lodash-es';

import { ContainerStatisticsElement, ContainerStatisticsWrapper } from '../../styled/ContainersStatistics';
import { Message, Panel, PanelSection } from '../../../../core/components/styled';
import { ContainersFiltersFormValues, CONTAINERS_FILTERS_FORM } from '../../forms/ContainersFormNew';
import { AppState } from '../../../../store';
import { getChartData } from '../../utils/containerStatistics';
import {
  CONTAINER_STATUS_AVAILABLE,
  CONTAINER_STATUS_IN_USE,
  CONTAINER_STATUS_UNAVAILABLE,
  CONTAINER_TYPE_ROLL_OFF_ID,
} from 'src/common/constants';
import translate from '../../../../core/services/translate';
import StatisticsTable from './StatisticsTable';
import DoughnutChart from 'src/core/components/DoughnutChart';

import { getEquipmentSize } from 'src/common/services/transformLoadServiceTypes';
import { CONTAINERS_CHART_COLORS } from 'src/core/constants/chartColors';

const ContainerStatisticsSection = () => {
  const { isLoading, containersStatistics } = useSelector((state: AppState) => state.fleet.containersStatistics);
  const equipmentSizes = useSelector((state: AppState) => state.common.equipmentSizes.equipmentSizes);
  const formValues = useSelector(getFormValues(CONTAINERS_FILTERS_FORM)) as ContainersFiltersFormValues;

  const countsRowsData = [
    { label: translate('common.available'), value: containersStatistics?.counts?.availableContainers },
    { label: translate('containers.inUse'), value: containersStatistics?.counts?.inUseContainers },
    { label: translate('containers.unavailable'), value: containersStatistics?.counts?.unavailableContainers },
  ];

  const lowActivityRowsData =
    map(containersStatistics?.containersWithLowActivity, data => ({
      label: translate('routes.holidayPlanner.numberOfDaysPlural', { count: data.daysWithNoActivity }),
      value: data.containersCount,
    })) || [];

  const scheduledDeliveryContainersRowsData =
    map(containersStatistics?.scheduledDeliveriesByContainerSize, data => {
      const size = find(equipmentSizes, { id: Number(data.equipmentSizeId) });
      return {
        label: translate(
          `common.equipmentSizes.x${upperFirst(
            camelize(size?.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
          )}`,
          { size: size ? getEquipmentSize(size.technicalName, size?.name) : 0 },
        ),
        value: data.containersCount,
      };
    }) || [];

  const frequentlyUsedContainersRowsData =
    map(orderBy(containersStatistics?.frequentlyUsedContainerSizes, ['containersCount'], ['desc']), el => {
      const size = find(equipmentSizes, { id: Number(el.equipmentSizeId) });
      return {
        label: translate(
          `common.equipmentSizes.x${upperFirst(
            camelize(size?.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
          )}`,
          { size: size ? getEquipmentSize(size.technicalName, size?.name) : 0 },
        ),
        value: el.containersCount,
      };
    }) || [];

  const estWeeksToNoInventoryRowsData =
    map(sortBy(containersStatistics?.numberOfWeeksUntilNoInventoryByContainerSize, 'weeksUntilNoInventory'), el => {
      const size = find(equipmentSizes, { id: Number(el.equipmentSizeId) });
      return {
        label: translate(
          `common.equipmentSizes.x${upperFirst(
            camelize(size?.technicalName.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '') || ''),
          )}`,
          { size: size ? getEquipmentSize(size.technicalName, size?.name) : 0 },
        ),
        value: el.weeksUntilNoInventory > 50 ? '50+' : el.weeksUntilNoInventory,
      };
    }) || [];

  const lowInventoryPartsRowsData =
    map(sortBy(containersStatistics?.lowInventoryParts, 'weeksUntilNoInventory'), el => {
      return {
        label: el.name,
        value: el.currentQuantity,
      };
    }) || [];

  const chartsMemo = React.useMemo(() => {
    if (!containersStatistics?.containers) {
      return null;
    }

    const availableContainers = filter(containersStatistics.containers, c => c.st === CONTAINER_STATUS_AVAILABLE);
    const availableContainersData = getChartData(availableContainers, equipmentSizes);

    const inUseContainers = filter(containersStatistics.containers, c => c.st === CONTAINER_STATUS_IN_USE);
    const inUseContainersData = getChartData(inUseContainers, equipmentSizes);

    const unavailableContainers = filter(containersStatistics.containers, c => c.st === CONTAINER_STATUS_UNAVAILABLE);
    const unavailableContainersData = getChartData(unavailableContainers, equipmentSizes);

    return (
      <>
        <ContainerStatisticsElement hasBorderRight alignItems="center">
          <DoughnutChart
            showLegend
            showSummaryLegend
            showSummaryOnHover
            topTitle={translate('containers.containerStatusTypes.available')}
            title={availableContainers.length.toString()}
            doughnutSize={130}
            dataset={{
              data: availableContainersData.data,
              backgroundColor: CONTAINERS_CHART_COLORS,
            }}
            rawDataValues={availableContainersData.rawDataValues}
            labels={availableContainersData.labels}
          />
        </ContainerStatisticsElement>
        <ContainerStatisticsElement hasBorderRight alignItems="center">
          <DoughnutChart
            showLegend
            showSummaryLegend
            showSummaryOnHover
            topTitle={translate('containers.containerStatusTypes.inUse')}
            title={inUseContainers.length.toString()}
            doughnutSize={130}
            dataset={{
              data: inUseContainersData.data,
              backgroundColor: CONTAINERS_CHART_COLORS,
            }}
            rawDataValues={inUseContainersData.rawDataValues}
            labels={inUseContainersData.labels}
          />
        </ContainerStatisticsElement>
        <ContainerStatisticsElement hasBorderRight alignItems="center">
          <DoughnutChart
            showLegend
            showSummaryLegend
            showSummaryOnHover
            topTitle={translate('containers.containerStatusTypes.unavailable')}
            title={unavailableContainers.length.toString()}
            doughnutSize={130}
            dataset={{
              data: unavailableContainersData.data,
              backgroundColor: CONTAINERS_CHART_COLORS,
            }}
            rawDataValues={unavailableContainersData.rawDataValues}
            labels={unavailableContainersData.labels}
          />
        </ContainerStatisticsElement>
      </>
    );
  }, [containersStatistics?.containers, equipmentSizes]);

  const containerTypeIsRollOff = formValues.containerTypeId === CONTAINER_TYPE_ROLL_OFF_ID;

  return (
    <PanelSection withBorder isLoading={isLoading}>
      <Panel fluid padding="small">
        {formValues && formValues.containerTypeId ? (
          <ContainerStatisticsWrapper>
            <ContainerStatisticsElement hasBorderRight>
              <StatisticsTable
                title={translate('containers.containers')}
                rows={countsRowsData}
                featuredValue={containersStatistics?.counts?.totalContainers}
              />
            </ContainerStatisticsElement>

            {chartsMemo}

            {lowActivityRowsData.length > 0 && containerTypeIsRollOff && (
              <ContainerStatisticsElement hasBorderRight>
                <StatisticsTable title={translate('containers.lowActivityContainers')} rows={lowActivityRowsData} />
              </ContainerStatisticsElement>
            )}

            {scheduledDeliveryContainersRowsData.length > 0 && !containerTypeIsRollOff && (
              <ContainerStatisticsElement hasBorderRight>
                <StatisticsTable
                  title={translate('containers.scheduledDeliveries')}
                  rows={scheduledDeliveryContainersRowsData}
                />
              </ContainerStatisticsElement>
            )}

            {frequentlyUsedContainersRowsData.length > 0 && !containerTypeIsRollOff && (
              <ContainerStatisticsElement hasBorderRight>
                <StatisticsTable
                  title={translate('containers.weeklyContainerUsage')}
                  rows={frequentlyUsedContainersRowsData}
                />
              </ContainerStatisticsElement>
            )}

            {estWeeksToNoInventoryRowsData.length > 0 && !containerTypeIsRollOff && (
              <ContainerStatisticsElement hasBorderRight>
                <StatisticsTable
                  title={translate('containers.estWeeksToNoInventory')}
                  rows={estWeeksToNoInventoryRowsData}
                />
              </ContainerStatisticsElement>
            )}

            {!containerTypeIsRollOff && (
              <ContainerStatisticsElement hasBorderRight>
                <StatisticsTable
                  title={translate('containers.lowInventoryParts')}
                  rows={lowInventoryPartsRowsData}
                  hasLowInventoryPartsRowsData
                  containerTypeId={formValues.containerTypeId}
                />
              </ContainerStatisticsElement>
            )}
          </ContainerStatisticsWrapper>
        ) : (
          <Message padding="sMedium">{translate('containers.loadDataMessage')}</Message>
        )}
      </Panel>
    </PanelSection>
  );
};

export default ContainerStatisticsSection;
