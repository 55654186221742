import React, { PureComponent } from 'react';

import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { COUNTRIES } from 'src/common/constants';
import { DuckFunction } from 'src/contracts/ducks';
import { Dropdown, Input } from 'src/core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
} from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { completeLoad, reverseGeocode } from 'src/customers/ducks';
import { TemporaryAddress } from 'src/customers/interfaces/Customers';
import temporaryAddressEditorFormInitialValues from 'src/customers/services/temporaryAddressEditorFormInitialValues';
import { AppState } from 'src/store';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import { isRequired } from 'src/utils/services/validator';

interface ComponentProps {
  locationId: number;
  closePopup: (pristine: boolean) => void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  geocodeValues: {
    latitude: number;
    longitude: number;
  };
  reverseGeocode: DuckFunction<typeof reverseGeocode>;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<TemporaryAddress, PropsWithoutReduxForm>;

class TemporaryAddressEditorForm extends PureComponent<Props> {
  readonly state = {
    isLoading: false,
  };

  handleReverseGeocode = () => {
    const {
      reverseGeocode,
      geocodeValues: { latitude, longitude },
      change,
    } = this.props;

    this.setState({ isLoading: true });
    reverseGeocode(latitude, longitude)
      .then(location => {
        const fields = Object.keys(location);
        fields.forEach(value => change(value, location[value]));

        this.setState({ isLoading: false });
        createSuccessNotification(translate('common.alertMessages.geocodeSucess'));
      })
      .catch(() => {
        this.setState({ isLoading: false });
        createErrorNotification(translate('common.alertMessages.geocodeFailure'));
      });
  };

  render() {
    const { handleSubmit, pristine, closePopup } = this.props;
    const { isLoading } = this.state;

    const countryOptions = map(COUNTRIES, country => ({
      value: country.id,
      label: country.name,
    }));

    return (
      <form onSubmit={handleSubmit}>
        <ModalClose onClick={() => closePopup(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <PanelSection isLoading={isLoading} padding="xSmall">
          <Grid multiLine>
            <GridColumn size="12/12">
              <Field name="streetNumber" component={Input} placeholder={translate('customers.streetNumber')} />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                name="street"
                component={Input}
                placeholder={translate('customers.streetName')}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="12/12">
              <Field name="city" component={Input} placeholder={translate('customers.city')} validate={[isRequired]} />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                name="state"
                component={Input}
                placeholder={translate('customers.state')}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="12/12">
              <Field name="zip" component={Input} placeholder={translate('customers.zip')} validate={[isRequired]} />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                name="country"
                component={Dropdown}
                options={countryOptions}
                placeholder={translate('customers.country')}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="4/12">
              <Field name="latitude" component={Input} placeholder={translate('common.latitude')} />
            </GridColumn>
            <GridColumn size="4/12">
              <Field name="longitude" component={Input} placeholder={translate('common.longitude')} />
            </GridColumn>
            <GridColumn size="4/12">
              <Button type="button" onClick={this.handleReverseGeocode} color="primary">
                {translate('common.reverseGeocode')}
              </Button>
            </GridColumn>
            <GridColumn size="12/12">
              <ButtonSet>
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const selector = formValueSelector('temporaryAddressEditor');
const locationSelector = formValueSelector('customerLocationEditor');

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: (temporaryAddressEditorFormInitialValues as any)(
    state.customers.locations,
    ownProps.locationId,
    locationSelector(state, 'address'),
  ),
  geocodeValues: selector(state, 'latitude', 'longitude'),
});

const mapDispatchToProps = {
  reverseGeocode,
  completeLoad,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<TemporaryAddress, PropsWithoutReduxForm>({
    form: 'temporaryAddressEditor',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(TemporaryAddressEditorForm),
);
