import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';

import {
  PreventativeMaintenanceMilestoneCompletePayload,
  PreventativeMaintenanceTypeId,
} from '../../interfaces/PreventativeMaintenance';
import {
  PM_COMPLETE_MILESTONE_FORM,
  PM_TYPE_MILEAGE,
  PM_TYPE_ENGINE_HOURS,
} from '../../constants/preventativeMaintenance';
import { Grid, GridColumn } from '../../../core/components/styled';
import { TypedField, DatePicker, Input } from '../../../core/components';
import {
  isRequired,
  minValueNumeric1,
  isRequiredNumber,
  isNumberWithNoDecimals,
  maxValueNumeric99M,
  isDateValidValidator,
} from '../../../utils/services/validator';
import translate from '../../../core/services/translate';
import { trimAndNormalizeNumber } from '../../../core/services/normalizeNumber';
import { TODAY } from '../../../core/constants/weekdays';

interface PropsWithoutReduxForm {
  typeId: PreventativeMaintenanceTypeId;
}

type Props = PropsWithoutReduxForm &
  InjectedFormProps<PreventativeMaintenanceMilestoneCompletePayload, PropsWithoutReduxForm>;

const CompletePreventativeMaintenanceMilestoneForm: React.FC<Props> = ({ typeId, form, handleSubmit }) => (
  <form onSubmit={handleSubmit} name={form}>
    <Grid multiLine centered>
      <GridColumn size="10/12">
        <TypedField
          name="dateOfMaintenance"
          validate={[isRequired, isDateValidValidator]}
          component={DatePicker}
          props={{
            disabledDays: [{ after: TODAY }],
            label: translate('vehicles.dateOfMaintenance'),
          }}
        />
      </GridColumn>

      {typeId === PM_TYPE_MILEAGE && (
        <GridColumn size="10/12">
          <TypedField
            name="mileageAtCompletion"
            normalize={trimAndNormalizeNumber}
            validate={[isRequiredNumber, isNumberWithNoDecimals, minValueNumeric1, maxValueNumeric99M]}
            component={Input}
            props={{
              raisedLabel: true,
              label: translate('vehicles.mileageAtCompletion'),
              placeholder: 'Eg. 1000',
            }}
          />
        </GridColumn>
      )}

      {typeId === PM_TYPE_ENGINE_HOURS && (
        <GridColumn size="10/12">
          <TypedField
            name="engineHoursAtCompletion"
            normalize={trimAndNormalizeNumber}
            validate={[isRequiredNumber, isNumberWithNoDecimals, minValueNumeric1, maxValueNumeric99M]}
            component={Input}
            props={{
              raisedLabel: true,
              label: translate('vehicles.engineHoursAtCompletion'),
              placeholder: 'Eg. 1000',
            }}
          />
        </GridColumn>
      )}

      <GridColumn padding="xxLarge no no" />
    </Grid>
  </form>
);

export default reduxForm<PreventativeMaintenanceMilestoneCompletePayload, PropsWithoutReduxForm>({
  form: PM_COMPLETE_MILESTONE_FORM,
  initialValues: {
    dateOfMaintenance: moment().format('MM/DD/YYYY'),
  },
})(CompletePreventativeMaintenanceMilestoneForm);
