import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { PureComponent } from 'react';
import { throttle } from 'lodash-es';

import { AppState } from 'src/store';
import { ButtonSet, Button, Text, PanelSection } from '../../../core/components/styled';
import { DatePicker, Input } from '../../../core/components';
import { isDateValidValidator, isRequired } from '../../../utils/services/validator';
import { TODAY } from '../../../core/constants';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface ComponentProps {
  isCloning: boolean;
  isLoadingRoutesResourceAvailability?: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class RouteCloneForm extends PureComponent<Props> {
  state = {
    isCloningRoute: false,
  };

  handleCloneClick = throttle(async () => {
    this.setState({ isCloningRoute: true });
    (this.props.handleSubmit as any)();

    setTimeout(() => {
      this.setState({ isCloningRoute: false });
    }, 1000);
  }, 1000);

  render() {
    const { isCloningRoute } = this.state;
    const { isCloning, isLoadingRoutesResourceAvailability } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <PanelSection vertical isLoading={isCloning || isLoadingRoutesResourceAvailability}>
          <Text block weight="medium" align="center" margin="no no small">
            {translate('routes.copyRoute')}
          </Text>

          <Field
            name="routeName"
            component={Input}
            placeholder={translate('routes.routeName')}
            validate={[isRequired]}
            margin="small"
          />
          <Field
            name="routeCloneDate"
            component={DatePicker}
            placeholder={translate('common.date')}
            disabledDays={[{ before: TODAY }]}
            validate={[isRequired, isDateValidValidator]}
            margin="small"
          />

          <ButtonSet>
            <Button type="button" color="primary" disabled={isCloningRoute} onClick={this.handleCloneClick}>
              {translate('common.clone')}
            </Button>
          </ButtonSet>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isCloning: state.routes.routeTracker.isCloning,
  isLoadingRoutesResourceAvailability: state.routes.routeTracker.isLoadingRoutesResourceAvailability,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'routeClone',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(RouteCloneForm),
);
