import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { isEqual, every, difference, filter } from 'lodash-es';

import useSelectedDaysOfService from './useSelectedDaysOfService';
import { useSelector } from 'src/core/hooks/useSelector';
import { setFilterRouteTemplates } from '../ducks/routeTemplateBuilder';
import { ROUTE_TEMPLATE_BUILDER_WORK_SESSION_FILTERS_FORM } from '../constants/routeTemplateBuilder';

export default function useWorkSessionRouteTemplatesFilter(routeTemplateIds: string[] = []) {
  const dispatch = useDispatch();
  const { selectedDaysOfServiceBundle } = useSelectedDaysOfService();

  const [lastVehicleTypeId, setLastVehicleTypeId] = useState<number | undefined>();
  const [lastMaterialTypeIds, setLastMaterialTypeIds] = useState<number[]>([]);
  const [lastServiceTypeIds, setLastServiceTypeIds] = useState<number[]>([]);
  const [lastDayOfServiceIds, setLastDayOfServiceIds] = useState<number[]>([]);

  const vehicleTypeId = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.vehicleTypeId);
  const materialTypeIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.materialTypeIds);
  const serviceTypeIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.serviceTypeIds);
  const bundlesLoadedByMaterialTypeId = useSelector(
    state => state.routes.routeTemplateBuilder.bundlesLoadedByMaterialTypeId,
  );
  const routeTemplateIdsShouldReset = useSelector(
    state => state.routes.routeTemplateBuilder.mapFilters.routeTemplateIdsShouldReset,
  );

  const [lastRouteTemplateIds, setLastRouteTemplateIds] = useState<string[]>(routeTemplateIds);

  useEffect(() => {
    const vehicleTypeChanged = vehicleTypeId !== lastVehicleTypeId;
    const materialTypesChanged = !isEqual(materialTypeIds, lastMaterialTypeIds);
    const dayOfServiceChanged =
      !!selectedDaysOfServiceBundle && !isEqual(selectedDaysOfServiceBundle.ids, lastDayOfServiceIds);
    const routeTemplatesChanged = difference(routeTemplateIds, lastRouteTemplateIds).length;

    const shouldResetRouteTemplatesFilter =
      routeTemplateIdsShouldReset ||
      vehicleTypeChanged ||
      materialTypesChanged ||
      dayOfServiceChanged ||
      routeTemplatesChanged;
    const everyMaterialIsLoaded = every(materialTypeIds, id => bundlesLoadedByMaterialTypeId.indexOf(id) !== -1);

    if (shouldResetRouteTemplatesFilter && !!selectedDaysOfServiceBundle && everyMaterialIsLoaded) {
      const allRouteTemplateIds = filter(
        selectedDaysOfServiceBundle.routeTemplates.map(template => template.name),
        id => id !== null,
      );

      setLastVehicleTypeId(vehicleTypeId);
      setLastMaterialTypeIds(materialTypeIds);
      setLastDayOfServiceIds(selectedDaysOfServiceBundle.ids);
      setLastRouteTemplateIds(routeTemplateIds);
      setLastServiceTypeIds(serviceTypeIds);

      dispatch(setFilterRouteTemplates(allRouteTemplateIds));
      dispatch(change(ROUTE_TEMPLATE_BUILDER_WORK_SESSION_FILTERS_FORM, 'routeTemplateIds', allRouteTemplateIds));
    }
  }, [
    vehicleTypeId,
    lastVehicleTypeId,
    materialTypeIds,
    lastMaterialTypeIds,
    lastRouteTemplateIds,
    bundlesLoadedByMaterialTypeId,
    selectedDaysOfServiceBundle,
    serviceTypeIds,
    lastDayOfServiceIds,
    lastServiceTypeIds,
    routeTemplateIds,
    routeTemplateIdsShouldReset,
    dispatch,
  ]);
}
