import styled from 'styled-components';
import { css } from 'styled-components';
import { transparentize } from 'polished';
import { Input } from './Input';

export const SelectStyle = `
  appearance: none;
  padding-right: 36px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2Utb3BhY2l0eT0iLjcwNCIvPjwvc3ZnPg==') right 2px center no-repeat;
  &:focus {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
  }
  &:disabled {
    cursor: not-allowed;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiM5Njk2OTYiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
  }
`;

export const Select = styled(Input.withComponent('select'))<{ isEmpty?: boolean }>`
  ${SelectStyle};
  ${props =>
    props.isEmpty &&
    css`
      color: ${transparentize(0.6, '#000')};
    `};
`;
