import { http } from '../../core/services/http';
import { Service, ServiceWorkflowSavePayload } from '../interfaces/Services';
import { transformLoadRateTypes } from './transformLoadRateTypes';
import { transformLoadService, transformSaveService } from './transformService';

export const createService = (service: Service) =>
  http
    .post('services', transformSaveService(service), {
      params: { shouldRecreateRoutes: service.shouldRecreateRoutes },
    })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const updateService = (service: Service) =>
  http
    .put(`services/${service.id}`, transformSaveService(service), {
      params: { shouldRecreateRoutes: service.shouldRecreateRoutes || false },
    })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const loadService = (serviceId: number) =>
  http.get(`services/${serviceId}`).then(response => transformLoadService(response.data));

export const deleteService = (serviceId: number, shouldRecreateRoutes: boolean, moveContainersToFacilityId?: number) =>
  http.delete(`services/${serviceId}`, {
    params: { shouldRecreateRoutes, moveContainersToFacilityId },
  });

export const loadServiceWorkOrders = (serviceId: number, startDate?: string | Date, endDate?: string | Date) =>
  http.get(`services/${serviceId}/workorders`, { params: { startDate, endDate } }).then(response => {
    return response.data;
  });

export const loadRateTypes = () =>
  http.get(`common/systemTypes/rateCodes`).then(response => transformLoadRateTypes(response.data));

export const loadRateTypesByServiceType = (serviceTypeId?: number) =>
  http.get(`rateCodes/byServiceType/${serviceTypeId}`).then(response => response.data);

export const loadRateTypesByWorkOrder = (workOrderId?: number) =>
  http.get(`rateCodes/byWorkorder/${workOrderId}`).then(response => response.data);

export const loadRateTypesByServiceContractId = (serviceContractId?: number) =>
  http.get(`rateCodes/byServiceContract/${serviceContractId}`).then(response => response.data);

export const checkServiceHasVendorContainers = (serviceContractId: number) =>
  http
    .get(`services/${serviceContractId}/hasVendorContainers`)
    .then(res => res.data)
    .catch(error => Promise.reject(error));

export const saveServiceWorkflow = (payload: ServiceWorkflowSavePayload) =>
  http
    .put('/services/workflow/save', payload)
    .then(res => res.data)
    .catch(error => Promise.reject(error));
