import { httpInsightsReporting } from '../../core/services/http';
import paramsSerializer from '../../utils/services/paramsSerializer';

const loadReportingDataTrends = (
  vendorId: number,
  reportType: string,
  startDate: string,
  endDate: string,
  vehicleTypeId: number,
  daysOfOperation: any[],
) =>
  httpInsightsReporting
    .get('/reporting/insightsDataTrends', {
      params: {
        vendorId,
        reportType,
        startDate,
        endDate,
        vehicleTypeId,
        daysOfOperation,
      },
      paramsSerializer,
    } as any)
    .then(response => response.data);

export default loadReportingDataTrends;
