import { forwardRef, useContext, useEffect, useState } from 'react';
import { MapContext, WebMercatorViewport } from 'react-map-gl';

import TooltipIconButton from '../../../core/components/TooltipIconButton';
import { IconButtonIcon } from '../../../core/components/styled';
import { ComplexMapControl, ComplexMapControlPosition } from '../../../routes/components/styled/RouteMap';

const noop = () => {};
const googleMapsSatellite = google.maps.MapTypeId.HYBRID;

interface Props {
  satelliteViewEnabled?: boolean;
  map?: google.maps.Map;
  isGoogleMap?: boolean;
  disableCompassToggle?: boolean;
  disableSatelliteViewToggle?: boolean;
  position?: ComplexMapControlPosition;
  xOffset?: number;
  yOffset?: number;
  onViewportChange?: (opts: Partial<WebMercatorViewport>) => void;
  onSatelliteViewToggle?: () => void;
}

const NavigationControl = forwardRef<HTMLDivElement, Props>(
  (
    {
      satelliteViewEnabled,
      map,
      isGoogleMap = false,
      disableCompassToggle = false,
      disableSatelliteViewToggle = false,
      position = 'bottom-left',
      xOffset = 0,
      yOffset = 0,
      onViewportChange = noop,
      onSatelliteViewToggle = noop,
    },
    ref,
  ) => {
    const currentMapTypeId = map?.getMapTypeId();

    const [satelliteIsToggledOn, setSatelliteIsToggledOn] = useState<boolean>(
      (isGoogleMap ? currentMapTypeId === googleMapsSatellite : satelliteViewEnabled) || false,
    );

    const { viewport } = useContext(MapContext);

    const updateViewport = (opts: Partial<WebMercatorViewport>) => {
      onViewportChange({
        ...viewport,
        ...opts,
      });
    };

    const getZoom = () => (isGoogleMap ? map?.getZoom() : viewport?.zoom) || 0;

    const setZoom = (newZoom: number) => {
      if (isGoogleMap) {
        map?.setZoom(newZoom);
      } else {
        updateViewport({ zoom: newZoom });
      }
    };

    const getBearing = () => (isGoogleMap ? map?.getHeading() : viewport?.bearing) || 0;

    const onZoomIn = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault()
      setZoom(getZoom() + 1);
    };

    const onZoomOut = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault()
      let newZoom = getZoom() - 1;

      if (newZoom < 0) {
        newZoom = 0;
      }

      setZoom(newZoom);
    };

    const onResetNorth = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault()
      if (isGoogleMap) {
        map?.setHeading(0);
        map?.setTilt(0);
      } else {
        updateViewport({ bearing: 0 });
      }
    };

    const onSatelliteClick = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault()
      if (isGoogleMap) {
        map?.setMapTypeId(satelliteIsToggledOn ? google.maps.MapTypeId.ROADMAP : googleMapsSatellite);
        setSatelliteIsToggledOn(!satelliteIsToggledOn);
      } else {
        onSatelliteViewToggle();
      }
    };

    useEffect(() => {
      if (isGoogleMap) {
        setSatelliteIsToggledOn(currentMapTypeId === googleMapsSatellite);
      }
    }, [isGoogleMap, currentMapTypeId]);

    return (
      <ComplexMapControl vertical position={position} xOffset={xOffset} yOffset={yOffset} ref={ref} zIndex={100}>
        <TooltipIconButton
          tooltip="zoomIn"
          tooltipPosition="right"
          tooltipColor="grayDarker"
          color="secondary"
          margin="no"
          onClick={onZoomIn}
        >
          <IconButtonIcon icon="mapboxZoomIn" />
        </TooltipIconButton>

        <TooltipIconButton
          tooltip="zoomOut"
          tooltipPosition="right"
          tooltipColor="grayDarker"
          color="secondary"
          margin="small no no"
          onClick={onZoomOut}
        >
          <IconButtonIcon icon="mapboxZoomOut" />
        </TooltipIconButton>

        {!disableCompassToggle && (
          <TooltipIconButton
            tooltip="resetNorth"
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color="secondary"
            margin="small no no"
            onClick={onResetNorth}
          >
            <IconButtonIcon icon="compass" rotate={-getBearing() - 45} />
          </TooltipIconButton>
        )}

        {!disableSatelliteViewToggle && (
          <TooltipIconButton
            tooltip={satelliteIsToggledOn ? 'disableSatelliteView' : 'enableSatelliteView'}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color={satelliteIsToggledOn ? 'primary' : 'secondary'}
            margin="small no no"
            onClick={onSatelliteClick}
          >
            <IconButtonIcon icon="map" />
          </TooltipIconButton>
        )}
      </ComplexMapControl>
    );
  },
);

export default NavigationControl;
