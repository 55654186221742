import { Dispatch, AnyAction } from 'redux';
import update from 'immutability-helper';

import { FuelTicket } from '../interfaces/FuelTicket';
import { FuelingStation } from 'src/common/interfaces/Facility';

import {
  loadFuelTickets as doLoadFuelTickets,
  loadFuelTicket as doLoadFuelTicket,
  createFuelTicket as doCreateFuelTicket,
  updateFuelTicket as doUpdateFuelTicket,
  deleteFuelTicket as doDeleteFuelTicket,
  loadFuelTicketUnitOfMeasureValues as doLoadFuelTicketUnitOfMeasureValues,
  loadFuelingStations as doLoadFuelingStations,
  uploadFuelTicketImage as doUploadFuelTicketImage,
  linkImageToFuelTicket as doLinkImageToFuelTicket,
} from '../services/fuelTickets';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD_FUEL_TICKET = 'routes/fuelTickets/START_LOAD_FUEL_TICKET';
const COMPLETE_LOAD_FUEL_TICKET = 'routes/fuelTickets/COMPLETE_LOAD_FUEL_TICKET';
const FAIL_LOAD_FUEL_TICKET = 'routes/fuelTickets/FAIL_LOAD_FUEL_TICKET';
const START_SAVE_FUEL_TICKET = 'routes/fuelTickets/START_SAVE_FUEL_TICKET';
const COMPLETE_SAVE_FUEL_TICKET = 'routes/fuelTickets/COMPLETE_SAVE_FUEL_TICKET';
const FAIL_SAVE_FUEL_TICKET = 'routes/fuelTickets/FAIL_SAVE_FUEL_TICKET';
const START_DELETE_FUEL_TICKET = 'routes/fuelTickets/START_DELETE_FUEL_TICKET';
const COMPLETE_DELETE_FUEL_TICKET = 'routes/fuelTickets/COMPLETE_DELETE_FUEL_TICKET';
const FAIL_DELETE_FUEL_TICKET = 'routes/fuelTickets/FAIL_DELETE_FUEL_TICKET';
const RESET = 'routes/fuelTickets/RESET';

const START_UPLOAD_FUEL_TICKET_IMAGE = 'routes/fuelTickets/START_UPLOAD_FUEL_TICKET_IMAGE';
const COMPLETE_UPLOAD_FUEL_TICKET_IMAGE = 'routes/fuelTickets/COMPLETE_UPLOAD_FUEL_TICKET_IMAGE';
const FAIL_UPLOAD_FUEL_TICKET_IMAGE = 'routes/fuelTickets/FAIL_UPLOAD_FUEL_TICKET_IMAGE';
const START_LINK_FUEL_TICKET_IMAGE = 'routes/fuelTickets/START_LINK_FUEL_TICKET_IMAGE';
const COMPLETE_LINK_FUEL_TICKET_IMAGE = 'routes/fuelTickets/COMPLETE_LINK_FUEL_TICKET_IMAGE';
const FAIL_LINK_FUEL_TICKET_IMAGE = 'routes/fuelTickets/FAIL_LINK_FUEL_TICKET_IMAGE';

const START_LOAD_FUEL_TICKETS = 'routes/fuelTickets/START_LOAD_FUEL_TICKETS';
const COMPLETE_LOAD_FUEL_TICKETS = 'routes/fuelTickets/COMPLETE_LOAD_FUEL_TICKETS';
const FAIL_LOAD_FUEL_TICKETS = 'routes/fuelTickets/FAIL_LOAD_FUEL_TICKETS';

const START_LOAD_FUEL_TICKET_UNIT_OF_MEASURE = 'routes/fuelTickets/START_LOAD_FUEL_TICKET_UNIT_OF_MEASURE';
const COMPLETE_LOAD_FUEL_TICKET_UNIT_OF_MEASURE = 'routes/fuelTickets/COMPLETE_LOAD_FUEL_TICKET_UNIT_OF_MEASURE';
const FAIL_LOAD_FUEL_TICKET_UNIT_OF_MEASURE = 'routes/fuelTickets/FAIL_LOAD_FUEL_TICKET_UNIT_OF_MEASURE';

const START_LOAD_FUELING_STATION = 'routes/fuelTickets/START_LOAD_FUELING_STATION';
const COMPLETE_LOAD_FUELING_STATION = 'routes/fuelTickets/COMPLETE_LOAD_FUELING_STATION';
const FAIL_LOAD_FUELING_STATION = 'routes/fuelTickets/FAIL_LOAD_FUELING_STATION';

// Initial state
const initialState = {
  isDeletingFuelTicket: false,
  isLoadingFuelTickets: false,
  isLoadingFuelTicket: false,
  isLoadingUnitsOfMeasure: false,
  isLoadingFuelingStations: false,
  isSavingFuelTicket: false,
  isUploadingFuelTicketImage: false,
  isLinkingFuelTicketImage: false,
  fuelTickets: [] as FuelTicket[],
  fuelTicket: {} as FuelTicket,
  fuelingStations: [] as FuelingStation[],
  unitsOfMeasureValues: [] as TechnicalType[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_FUEL_TICKET:
      return update(state, {
        $merge: {
          isLoadingFuelTicket: true,
        },
      });

    case COMPLETE_LOAD_FUEL_TICKET:
      return update(state, {
        $merge: {
          isLoadingFuelTicket: false,
          fuelTicket: action.fuelTicket,
        },
      });

    case FAIL_LOAD_FUEL_TICKET:
      return update(state, {
        $merge: {
          isLoadingFuelTicket: false,
          fuelTicket: {} as FuelTicket,
        },
      });

    case START_SAVE_FUEL_TICKET:
      return update(state, {
        $merge: {
          isSavingFuelTicket: true,
        },
      });

    case COMPLETE_SAVE_FUEL_TICKET:
      return update(state, {
        $merge: {
          isSavingFuelTicket: false,
          fuelTicket: action.fuelTicket,
        },
      });

    case FAIL_SAVE_FUEL_TICKET:
      return update(state, {
        $merge: {
          isSavingFuelTicket: false,
          fuelTicket: {} as FuelTicket,
        },
      });

    case START_DELETE_FUEL_TICKET:
      return update(state, {
        $merge: {
          isDeletingFuelTicket: true,
        },
      });

    case COMPLETE_DELETE_FUEL_TICKET:
      return update(state, {
        $merge: {
          isDeletingFuelTicket: false,
        },
      });

    case FAIL_DELETE_FUEL_TICKET:
      return update(state, {
        $merge: {
          isDeletingFuelTicket: false,
        },
      });

    case START_LOAD_FUEL_TICKET_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitsOfMeasure: true,
        },
      });

    case COMPLETE_LOAD_FUEL_TICKET_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitsOfMeasure: false,
          unitsOfMeasureValues: action.unitsOfMeasureValues,
        },
      });

    case FAIL_LOAD_FUEL_TICKET_UNIT_OF_MEASURE:
      return update(state, {
        $merge: {
          isLoadingUnitsOfMeasure: false,
          unitsOfMeasureValues: [],
        },
      });

    case START_LOAD_FUELING_STATION:
      return update(state, {
        $merge: {
          isLoadingFuelingStations: true,
        },
      });

    case COMPLETE_LOAD_FUELING_STATION:
      return update(state, {
        $merge: {
          isLoadingFuelingStations: false,
          fuelingStations: action.fuelingStations,
        },
      });

    case FAIL_LOAD_FUELING_STATION:
      return update(state, {
        $merge: {
          isLoadingFuelingStations: false,
          fuelingStations: [],
        },
      });

    case START_UPLOAD_FUEL_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isUploadingFuelTicketImage: true,
        },
      });

    case COMPLETE_UPLOAD_FUEL_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isUploadingFuelTicketImage: false,
        },
      });

    case FAIL_UPLOAD_FUEL_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isUploadingFuelTicketImage: false,
        },
      });

    case START_LINK_FUEL_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isLinkingFuelTicketImage: true,
        },
      });

    case COMPLETE_LINK_FUEL_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isLinkingFuelTicketImage: false,
        },
      });

    case FAIL_LINK_FUEL_TICKET_IMAGE:
      return update(state, {
        $merge: {
          isLinkingFuelTicketImage: false,
        },
      });

    case START_LOAD_FUEL_TICKETS:
      return update(state, {
        $merge: {
          isLoadingFuelTickets: true,
        },
      });

    case COMPLETE_LOAD_FUEL_TICKETS:
      return update(state, {
        $merge: {
          isLoadingFuelTickets: false,
          fuelTickets: action.fuelTickets,
        },
      });

    case FAIL_LOAD_FUEL_TICKETS:
      return update(state, {
        $merge: {
          isLoadingFuelTickets: false,
          fuelTickets: [],
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// actions
const startLoadFuelTicket = () => ({ type: START_LOAD_FUEL_TICKET });
const completeLoadFuelTicket = (fuelTicket: FuelTicket) => ({ type: COMPLETE_LOAD_FUEL_TICKET, fuelTicket });
const failLoadFuelTicket = () => ({ type: FAIL_LOAD_FUEL_TICKET });

const startUploadFuelTicketImage = () => ({ type: START_UPLOAD_FUEL_TICKET_IMAGE });
const completeUploadFuelTicketImage = () => ({ type: COMPLETE_UPLOAD_FUEL_TICKET_IMAGE });
const failUploadFuelTicketImage = () => ({ type: FAIL_UPLOAD_FUEL_TICKET_IMAGE });

const startLinkFuelTicketImage = () => ({ type: START_LINK_FUEL_TICKET_IMAGE });
const completeLinkFuelTicketImage = () => ({ type: COMPLETE_LINK_FUEL_TICKET_IMAGE });
const failLinkFuelTicketImage = () => ({ type: FAIL_LINK_FUEL_TICKET_IMAGE });

const startSaveFuelTicket = () => ({ type: START_SAVE_FUEL_TICKET });
const completeSaveFuelTicket = (fuelTicket: FuelTicket) => ({ type: COMPLETE_SAVE_FUEL_TICKET, fuelTicket });
const failSaveFuelTicket = () => ({ type: FAIL_SAVE_FUEL_TICKET });

const startDeleteFuelTicket = () => ({ type: START_DELETE_FUEL_TICKET });
const completeDeleteFuelTicket = () => ({ type: COMPLETE_DELETE_FUEL_TICKET });
const failDeleteFuelTicket = () => ({ type: FAIL_DELETE_FUEL_TICKET });

const startLoadFuelTicketUnitOfMeasure = () => ({ type: START_LOAD_FUEL_TICKET_UNIT_OF_MEASURE });
const completeLoadFuelTicketUnitOfMeasure = (unitsOfMeasureValues: TechnicalType[]) => ({
  type: COMPLETE_LOAD_FUEL_TICKET_UNIT_OF_MEASURE,
  unitsOfMeasureValues,
});
const failLoadFuelTicketUnitOfMeasure = () => ({ type: FAIL_LOAD_FUEL_TICKET_UNIT_OF_MEASURE });

const startLoadFuelingStation = () => ({ type: START_LOAD_FUELING_STATION });
const completeLoadFuelingStation = (fuelingStations: FuelingStation[]) => ({
  type: COMPLETE_LOAD_FUELING_STATION,
  fuelingStations,
});
const failLoadFuelingStation = () => ({ type: FAIL_LOAD_FUELING_STATION });

const startLoadFuelTickets = () => ({ type: START_LOAD_FUEL_TICKETS });
const completeLoadFuelTickets = (fuelTickets: FuelTicket[]) => ({ type: COMPLETE_LOAD_FUEL_TICKETS, fuelTickets });
const failLoadFuelTickets = () => ({ type: FAIL_LOAD_FUEL_TICKETS });

export const resetFuelTickets = () => ({ type: RESET });

// thunks
export const loadFuelTicket = (id: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadFuelTicket());
  const loadFuelTicketPromise = doLoadFuelTicket(id);
  loadFuelTicketPromise
    .then((fuelTicket: FuelTicket) => {
      dispatch(completeLoadFuelTicket(fuelTicket));
    })
    .catch(() => {
      dispatch(failLoadFuelTicket());
    });
  return loadFuelTicketPromise;
};

export const saveFuelTicket = (routeId: number, fuelTicket: FuelTicket) => async (dispatch: Dispatch) => {
  const saveFuelTicketPromise = fuelTicket.id
    ? doUpdateFuelTicket(fuelTicket)
    : doCreateFuelTicket(routeId, fuelTicket);
  dispatch(startSaveFuelTicket());
  saveFuelTicketPromise
    .then((fuelTicket: FuelTicket) => {
      dispatch(completeSaveFuelTicket(fuelTicket));
    })
    .catch(() => {
      dispatch(failSaveFuelTicket());
    });
  return saveFuelTicketPromise;
};

export const uploadFuelTicketImage = (image: File, routeId: number) => async (dispatch: Dispatch) => {
  dispatch(startUploadFuelTicketImage());
  const uploadFuelTicketImagePromise = doUploadFuelTicketImage(image, routeId);
  uploadFuelTicketImagePromise
    .then(fuelTicketImage => {
      dispatch(completeUploadFuelTicketImage());
      return fuelTicketImage;
    })
    .catch(() => {
      dispatch(failUploadFuelTicketImage());
    });
  return uploadFuelTicketImagePromise;
};

export const linkFuelTicketImage = (fuelTicketImageId: number, fuelTicketId: number) => async (dispatch: Dispatch) => {
  dispatch(startLinkFuelTicketImage());
  const linkFuelTicketImagePromise = doLinkImageToFuelTicket(fuelTicketImageId, fuelTicketId);
  linkFuelTicketImagePromise
    .then(() => {
      dispatch(completeLinkFuelTicketImage());
    })
    .catch(() => {
      dispatch(failLinkFuelTicketImage());
    });
  return linkFuelTicketImagePromise;
};

export const deleteFuelTicket = (id: number) => async (dispatch: Dispatch) => {
  dispatch(startDeleteFuelTicket());
  const deleteFuelTicketPromise = doDeleteFuelTicket(id);
  deleteFuelTicketPromise
    .then(() => {
      dispatch(completeDeleteFuelTicket());
    })
    .catch(() => {
      dispatch(failDeleteFuelTicket());
    });
  return deleteFuelTicketPromise;
};

export const loadFuelTicketUnitOfMeasure = () => async (dispatch: Dispatch) => {
  dispatch(startLoadFuelTicketUnitOfMeasure());
  const loadFuelTicketUnitOfMeasurePromise = doLoadFuelTicketUnitOfMeasureValues();
  loadFuelTicketUnitOfMeasurePromise
    .then((unitsOfMeasureValues: TechnicalType[]) => {
      dispatch(completeLoadFuelTicketUnitOfMeasure(unitsOfMeasureValues));
    })
    .catch(() => {
      dispatch(failLoadFuelTicketUnitOfMeasure());
    });
  return loadFuelTicketUnitOfMeasurePromise;
};

export const loadFuelingStation = (vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadFuelingStation());
  const loadFuelingStationPromise = doLoadFuelingStations(vendorId);
  loadFuelingStationPromise
    .then((fuelingStations: FuelingStation[]) => {
      dispatch(completeLoadFuelingStation(fuelingStations));
    })
    .catch(() => {
      dispatch(failLoadFuelingStation());
    });
  return loadFuelingStationPromise;
};

export const loadFuelTickets = (routeId: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadFuelTickets());
  const loadFuelTicketsPromise = doLoadFuelTickets(routeId);
  loadFuelTicketsPromise
    .then((fuelTickets: FuelTicket[]) => {
      dispatch(completeLoadFuelTickets(fuelTickets));
    })
    .catch(() => {
      dispatch(failLoadFuelTickets());
    });
  return loadFuelTicketsPromise;
};
