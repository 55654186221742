import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ACCEPT_CHANGES = 'AcceptsChanges';
export const ACTIONNEEDED = 'ACTIONNEEDED';
export const UNKNOWN = 'UNKNOWN';
export const PENDING = 'PENDING';
export const REQUESTED = 'REQUESTED';
export const YES = 'Yes';
export const NO = 'No';
export const REQUEST_CHANGE = 'RequestChanges';
export const NO_RESPONSE = 'NoResponse';
export const AWAITING_HAULER_APPROVAL = 'AwaitingHaulerApproval';
export const REQUEST_CUSTOMER_APPROVAL = 'RequireCustomerApproval';
export const VENDOR_PORTAL = 'VENDOR PORTAL';
export const PENDING_RUBICON_APPROVAL = 'PENDING_RUBICON_APPROVAL';
export const PENDING_HAULER_CONFIRMATION = 'PENDING_HAULER_CONFIRMATION';
export const REJECT_CHANGES = 'RejectsChanges';
export const HAUL_FEE = 'Haul Fee';
export const HAULING = 'Hauling';
export const HAULING_EXTRA_PICKUP = 'Hauling - Extra Pickup';
export const ROLL_OFF = 'RO';
export const AUTO_DISPATCH = 'autoDispatch';
export const OPPORTUNITIES = 'Opportunities';
export const PAYMENTS = 'payments';
export const SERVICE_CHANGE_REQUESTS = 'ServiceChangeRequests';
export const WORK_ORDERS = 'WorkOrders';
export const MONTH = 'MONTH';
export const HAUL = 'HAU';
export const DISP = 'DISP';
export const FUEL = 'FUEL';
export const EXCFEE = 'EXCFEE';
export const JETTNG = 'JETT';
export const RENTVO = 'RENTVO';
export const HAND_PICKUP = 'HP';
export const TRAP = 'TP';
export const BUCKET = 'BU';
export const CAGE = 'CG';
export const CADDY = 'CD';
export const DRUM = 'DM';
export const TANK = 'TN';
export const UNSPECIFIED = 'UN';
export const COOKING_OIL = 'CK';
export const GREASE = 'GR';
export const TIRES = 'TI';
export const OVERSIZED_TIRES = 'TO';
export const PASSENGER_TIRES = 'TG';
export const TRAILER = 'TL';
export const COMPACTER = 'CP';
export const RENTAL_RECEIVER_BOX = 'RR';
export const RENTAL_SELF_CONTAINED = 'RS';
export const RENTAL_VERTICAL_COMPACTOR = 'RV';
export const COMBINE_HAUL_FEE = 'CMBHAU';

export const CHANGE_SERVICE_STATUS = mapKeys(
  [
    {
      technicalName: PENDING_HAULER_CONFIRMATION,
      name: translate('opportunity.serviceChangeRequest.pendingHaulerConfirmation'),
    },
    {
      technicalName: PENDING_RUBICON_APPROVAL,
      name: translate('opportunity.serviceChangeRequest.pendingRubiconApproval'),
    },
    { technicalName: REQUESTED, name: translate('common.requested') },
  ],
  'technicalName',
);

export default CHANGE_SERVICE_STATUS;
