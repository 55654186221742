import { connect } from 'react-redux';
import { vendorGusIdSelector } from '../ducks';
import { AppState } from '../../store';

interface Props {
  isVendorWithGusId: boolean;
  children: any;
}

const VendorWithGusIdGuard = ({ isVendorWithGusId, children }: Props) => (isVendorWithGusId ? children : null);

const mapStateToProps = (state: AppState) => ({
  isVendorWithGusId: (vendorGusIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

export default connect(mapStateToProps)(VendorWithGusIdGuard);
