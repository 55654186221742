import React from 'react';
import { theme } from '../styles';
import { DragHandleIcon, MapDragHandle as MapDragHandleContainer } from './styled';

const MapDragHandle = () => (
  <MapDragHandleContainer>
    <DragHandleIcon color={theme.brandPrimary} cursor="row-resize" />
  </MapDragHandleContainer>
);

export default MapDragHandle;
