import React from 'react';
import { useDispatch } from 'react-redux';

import { MapPopup, ServicePopupLoading } from '../../../styled/RouteTemplateBuilderMap';
import { RouteTemplateBuilderServiceFeatureProperties } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import * as Ducks from '../../../../ducks/routeTemplateBuilder';
import RouteTemplateBuilderServicePopup from './RouteTemplateBuilderServicePopup';
import { loadService } from 'src/customers/ducks';

interface Props {
  feature: mapboxgl.MapboxGeoJSONFeature;
  onClose(): void;
}

const RouteTemplateBuilderServicePopupResolver: React.FC<Props> = ({
  feature: { properties: rawProperties },
  onClose,
}) => {
  const dispatch = useDispatch();

  const workSessionId = useSelector(state => state.routes.routeTemplateBuilder.workSession?.id);

  const { id: serviceContractId, lat, lng } = rawProperties as RouteTemplateBuilderServiceFeatureProperties;

  const loadDependencies = () => {
    const dependencies: Promise<any>[] = [];

    if (workSessionId) {
      dependencies.push(Ducks.loadServiceContract(workSessionId, serviceContractId)(dispatch));
      dependencies.push(loadService(serviceContractId)(dispatch));
    }

    return Promise.all(dependencies);
  };

  const onCloseHandler = () => {
    onClose();
    dispatch(Ducks.clearSelectedServiceContract());
  };

  return (
    <>
      <MapPopup closeOnClick={false} latitude={lat} longitude={lng} onClose={onCloseHandler}>
        <TypedResolver
          key={serviceContractId}
          successComponent={RouteTemplateBuilderServicePopup}
          loadingComponent={ServicePopupLoading}
          resolve={loadDependencies}
        />
      </MapPopup>
    </>
  );
};

export default RouteTemplateBuilderServicePopupResolver;
