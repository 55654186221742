import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import translate from '../../../core/services/translate';
import VehicleInspectionDataPage from './VehicleInspectionDataPage';
import { loadVehicle } from '../../ducks/index';
import { loadVehicleInspections } from '../../ducks/vehicleInspections';

interface Props {
  vendorId: number;
  match: any;
  loadVehicleInspections: (vendorId: number, vehicleId: number, startDate: string, endDate: string) => Promise<any>;
  loadVehicle: (vehicleId: number) => Promise<any>;
}

class VehicleInspectionDataResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      vendorId,
      loadVehicleInspections,
      loadVehicle,
      match: {
        params: { vehicleId },
      },
    } = this.props;

    const startDate = moment().subtract(14, 'days').format('MM/DD/YYYY');
    const endDate = moment().format('MM/DD/YYYY');

    const dependencies = [];

    if (vehicleId) {
      dependencies.push(loadVehicleInspections(vendorId, Number(vehicleId), startDate, endDate));
      dependencies.push(loadVehicle(vehicleId));
    }

    return Promise.all(dependencies);
  };

  render() {
    const {
      match: {
        params: { vehicleId },
      },
    } = this.props;

    return (
      <Fragment>
        <DocumentTitle>{translate('vehicles.vehicleInspections.inspectionLog')}</DocumentTitle>

        <Resolver
          successComponent={VehicleInspectionDataPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/fleet/vehicles"
          successProps={{
            vehicleId,
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadVehicleInspections,
  loadVehicle,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInspectionDataResolver);
