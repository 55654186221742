import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { map } from 'lodash-es';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

import { AppState } from '../../../store';
import { ButtonSet, Button, Grid, GridColumn, Text } from '../../../core/components/styled';
import { DatePicker, DaysOfWeekPicker, Dropdown, TypedField } from '../../../core/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import isFalsy from '../../../utils/services/isFalsy';
import { isDateValidValidator, isRequired, mustLength } from '../../../utils/services/validator';
import { ONE_X_PER_MONTH } from '../../constants';
import serviceChangeRequestFormInitialValueSelector from '../../services/serviceChangeRequestFormInitialValuesSelector';
import { STATUSES, YES } from '../../../fleet/constants';
import translate from '../../../core/services/translate';
import { vendorPaymentMethodTypes } from '../../ducks';
import WEEK_OF_MONTHS from '../../constants/weekOfMonths';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

const formSelector = formValueSelector('acceptServiceChangeRequest');

interface ComponentProps {
  afterDeliveryDate?: string;
  beforeDeliveryDate?: string;
  caseId: number;
  daysInWeekSchedule?: any;
  equipmentChanged: boolean;
  frequencyChanged: boolean;
  frequencyId: string;
  materialChanged: boolean;
}
interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
  endDestinations?: any[];
  holidays: any;
  paymentInfo?: any;
  paymentMethods: any;
  response: any;
  requestedChanges: any;
  status?: string;
  onCancel(pristine: boolean): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class AcceptServiceChangeRequestForm extends PureComponent<Props> {
  isValidNumberOfDays = mustLength(this.props.daysInWeekSchedule);

  render() {
    const {
      afterDeliveryDate,
      beforeDeliveryDate,
      caseId,
      daysInWeekSchedule,
      endDestinations,
      equipmentChanged,
      frequencyChanged,
      frequencyId,
      handleSubmit,
      holidays,
      materialChanged,
      onCancel,
      pristine,
      requestedChanges,
    } = this.props;

    const swapOptions = map(STATUSES, obj => ({
      label: obj.name,
      value: obj.id,
    }));

    const weekOptions = map(WEEK_OF_MONTHS, w => ({
      label: w.name,
      value: w.id,
    }));
    const isSwapRequiredDisabled = requestedChanges && requestedChanges.swapRequired === YES ? true : false;
    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <form onSubmit={handleSubmit} noValidate>
            <Fragment>
              <Grid multiLine>
                <GridColumn size="12/12">
                  <Field
                    name={`changeRequests.settings${caseId}.requestedChanges.deliveryDate`}
                    component={DatePicker}
                    label={translate('common.startDate')}
                    validate={[isRequired, isDateValidValidator]}
                    disabledDays={[
                      {
                        before: moment(beforeDeliveryDate, 'MM/DD/YYYY').toDate(),
                        after: moment(afterDeliveryDate, 'MM/DD/YYYY').toDate(),
                      },
                      { daysOfWeek: [0, 6] },
                      ...holidays,
                    ]}
                    margin={isMobile ? 'no' : 'small'}
                  />
                </GridColumn>

                {frequencyChanged && !isFalsy(daysInWeekSchedule, { allowZero: false }) && (
                  <GridColumn size="12/12">
                    <Field
                      name={`changeRequests.settings${caseId}.requestedChanges.schedule`}
                      component={DaysOfWeekPicker}
                      label={translate('opportunity.deliveries.pickupWillBeOn')}
                      validate={[isRequired, this.isValidNumberOfDays]}
                      multiple
                      daysOfWeekProps={{ margin: 'Small no Small' }}
                      dayOfWeekProps={{ margin: 'no small no no' }}
                      margin={isMobile ? 'no' : 'small small no'}
                    />
                  </GridColumn>
                )}
                {frequencyId === ONE_X_PER_MONTH && (
                  <GridColumn size="12/12">
                    <TypedField
                      name={`changeRequests.settings${caseId}.requestedChanges.week`}
                      component={Dropdown}
                      validate={[isRequired]}
                      props={{
                        options: weekOptions,
                        label: translate('opportunity.serviceChangeRequest.week'),
                        margin: isMobile ? 'no' : 'small',
                      }}
                    />
                  </GridColumn>
                )}

                {(materialChanged || equipmentChanged) && (
                  <Fragment>
                    <GridColumn size={isMobile ? '12/12' : '9/12'} padding="large no small">
                      <Text weight="light" margin={isMobile ? 'no' : 'ssMedium'}>
                        {translate('opportunity.serviceChangeRequest.swapRequired')}
                      </Text>
                    </GridColumn>
                    <GridColumn size={isMobile ? '12/12' : '3/12'}>
                      <Field
                        name={`changeRequests.settings${caseId}.requestedChanges.swapRequired`}
                        component={Dropdown}
                        options={swapOptions}
                        validate={isRequired}
                        label={translate('tooltips.select')}
                        margin={isMobile ? 'no' : 'small'}
                        disabled={isSwapRequiredDisabled}
                      />
                    </GridColumn>

                    <GridColumn size="12/12" padding="large no small">
                      <Text weight="light" margin={isMobile ? 'no' : 'ssMedium'}>
                        {translate('opportunity.serviceChangeRequest.selectEndDestination')}
                      </Text>
                    </GridColumn>
                    <GridColumn size="12/12">
                      <Field
                        name={`changeRequests.settings${caseId}.requestedChanges.facilityId`}
                        component={Dropdown}
                        options={endDestinations}
                        validate={isRequired}
                        label={translate('opportunity.opportunities.endDestination')}
                        margin={isMobile ? 'no' : 'small'}
                      />
                    </GridColumn>
                  </Fragment>
                )}
              </Grid>

              <ButtonSet>
                <Button type="submit" color="primary">
                  {translate('opportunity.submit')}
                </Button>
                <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
                  {translate('common.cancel')}
                </Button>
              </ButtonSet>
            </Fragment>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { paymentInfo } = state.opportunity.serviceChangeRequest as any;
  return {
    initialValues: serviceChangeRequestFormInitialValueSelector(
      state.opportunity.serviceChangeRequest.serviceChangeRequests,
    ),
    response: formSelector(state, `changeRequests.settings${ownProps.caseId}.response`),
    requestedChanges: formSelector(state, `changeRequests.settings${ownProps.caseId}.requestedChanges`),
    holidays: state.common.holidays.holidays,
    paymentMethods: paymentInfo.paymentMethods,
    paymentInfo: vendorPaymentMethodTypes(
      paymentInfo.vendorPayments,
      formSelector(state, `changeRequests.settings${ownProps.caseId}.requestedChanges.paymentMethod`),
    ),
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'acceptServiceChangeRequest',
    onSubmitFail: focusFirstInvalidField,
  })(AcceptServiceChangeRequestForm),
);
