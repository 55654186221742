import { http } from '../../core/services/http';
import { transformRecurringServices } from './transformRecurringServices';

// Processed Services
export const loadProcessedServices = (token: string, date: Date | string, page: number, limit: number) =>
  http
    .get(`gus/vendor/processed/services/${token}`, { params: { date, page, limit } })
    .then(response => transformRecurringServices(response.data));

export const loadProcessedServicesByVendorId = (vendorId: number) =>
  http.get(`gus/vendor/processed/services/${vendorId}`).then(response => transformRecurringServices(response.data));
