import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const WEIGHT_TICKET_KITCHENWASTEFOODWASTE = 1;
export const WEIGHT_TICKET_DIRTYPAPERSCRAPS = 2;
export const WEIGHT_TICKET_CARDBOARD = 3;
export const WEIGHT_TICKET_PET = 4;
export const WEIGHT_TICKET_PLASTICS = 5;
export const WEIGHT_TICKET_PAPER = 6;
export const WEIGHT_TICKET_MIXPAPER = 7;
export const WEIGHT_TICKET_METALS = 8;
export const WEIGHT_TICKET_NEWSPAPER = 9;
export const WEIGHT_TICKET_MAGAZINEBOOK = 10;
export const WEIGHT_TICKET_CERAMICS = 11;
export const WEIGHT_TICKET_GLASS_BOTTLES = 12;
export const WEIGHT_TICKET_CANS_EMPTY = 13;

export const WEIGHT_TICKET_MATERIAL_TYPES = mapKeys(
  [
    { id: WEIGHT_TICKET_KITCHENWASTEFOODWASTE, name: translate('common.weightTicketMaterialTypes.kitchenWasteFoodWaste') },
    { id: WEIGHT_TICKET_DIRTYPAPERSCRAPS, name: translate('common.weightTicketMaterialTypes.dirtyPaperScraps') },
    { id: WEIGHT_TICKET_CARDBOARD, name: translate('common.weightTicketMaterialTypes.cardboard') },
    { id: WEIGHT_TICKET_PET, name: translate('common.weightTicketMaterialTypes.pet') },
    { id: WEIGHT_TICKET_PLASTICS, name: translate('common.weightTicketMaterialTypes.plastics') },
    { id: WEIGHT_TICKET_PAPER, name: translate('common.weightTicketMaterialTypes.paper') },
    { id: WEIGHT_TICKET_MIXPAPER, name: translate('common.weightTicketMaterialTypes.mixPaper') },
    { id: WEIGHT_TICKET_METALS, name: translate('common.weightTicketMaterialTypes.metals') },
    { id: WEIGHT_TICKET_NEWSPAPER, name: translate('common.weightTicketMaterialTypes.newspaper') },
    { id: WEIGHT_TICKET_MAGAZINEBOOK, name: translate('common.weightTicketMaterialTypes.magazineBook') },
    { id: WEIGHT_TICKET_CERAMICS, name: translate('common.weightTicketMaterialTypes.ceramics') },
    { id: WEIGHT_TICKET_GLASS_BOTTLES, name: translate('common.weightTicketMaterialTypes.glassBottles') },
    { id: WEIGHT_TICKET_CANS_EMPTY, name: translate('common.weightTicketMaterialTypes.cansEmpty') },
  ],
  'id',
);

export default WEIGHT_TICKET_MATERIAL_TYPES;
