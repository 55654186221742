import { omit } from 'lodash-es';

import {
  calculateIsDriverExperienceSimple,
  calculateIsDriverExperienceModerate,
} from 'src/customers/components/pages/streetNetwork/utils';
import { COMPLETED, IN_PROGRESS } from 'src/routes/constants';
import { http } from '../../core/services/http';
import { RouteSegment, RouteSegmentsQueryParams } from '../interfaces/RouteSegment';

export const loadRouteSegments = (params: RouteSegmentsQueryParams) =>
  http
    .get<RouteSegment[]>(`${params.vendorId}/routes/${params.routeId}/routeDetail/segments`, {
      params: {
        ...omit(params, ['vendorId', 'routeId', 'noLoadingIndicator', 'isForDashboard']),
      },
    })
    .then(response => {
      const isDriverExperienceSimple = calculateIsDriverExperienceSimple(params.isSnowPlowRoute);
      const isDriverExperienceModerate = calculateIsDriverExperienceModerate(params.isSnowPlowRoute);

      return response.data.map(data => ({
        ...data,
        statusId:
          (isDriverExperienceSimple || isDriverExperienceModerate) && data.statusId === IN_PROGRESS
            ? COMPLETED
            : data.statusId,
      }));
    });

export const loadRouteSegmentsForDashboard = (params: RouteSegmentsQueryParams) =>
  http
    .get<RouteSegment[]>(`/dashboard/routes/${params.routeId}/segments`, {
      params: {
        ...omit(params, ['vendorId', 'routeId', 'noLoadingIndicator', 'isForDashboard']),
      },
    })
    .then(response => response.data);
