import { DASHBOARD_VEHICLE_POSITIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getIconTypeForVehiclePosition, getIconTypeForVehiclesListPosition } from 'src/common/constants/vehicleIcons';
import { PICKUP_TRUCK } from 'src/fleet/constants';
import { RouteVehiclePosition } from 'src/dashboard/interfaces/routesData';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';

export type VehiclePositionFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getVehiclePositionsGeoJSON = (vehiclePositions: VehiclePosition[]) =>
  getFeatureCollection<GeoJSON.Point, VehiclePositionFeatureProperties>(
    vehiclePositions.map(position =>
      getPointFeature(position.vehicle.id, [position.coordinates.longitude, position.coordinates.latitude], {
        id: position.vehicle.id,
        clickable: true,
        layer: DASHBOARD_VEHICLE_POSITIONS_LAYER,
        icon: getIconTypeForVehiclePosition(position)?.id,
      }),
    ),
  );

export const getVehiclesListPositionsGeoJSON = (vehiclePositions: VehiclePosition[] | RouteVehiclePosition[]) =>
  getFeatureCollection<GeoJSON.Point, VehiclePositionFeatureProperties>(
    vehiclePositions.map(position => {
      const id = 'id' in position ? position.id : position.vehicleId;

      return getPointFeature(id, [position.longitude, position.latitude], {
        id,
        clickable: true,
        layer: DASHBOARD_VEHICLE_POSITIONS_LAYER,
        icon: getIconTypeForVehiclesListPosition(position)?.id,
        isPickupTruck: position.type === PICKUP_TRUCK,
      });
    }),
  );
