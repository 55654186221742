import { ChangeEvent, PureComponent } from 'react';

import { filter, map } from 'lodash-es';
import { connect } from 'react-redux';

import { DropDownFieldProps } from 'src/common/components/DropDownFieldProps';
import { ACCOUNT_STATUSES } from 'src/common/constants';
import { Dropdown, TypedField } from 'src/core/components';
import translate from 'src/core/services/translate';

interface Props extends DropDownFieldProps {
  withPlaceholder?: boolean;
  withLabel?: boolean;
  accountStatusId?: number;
}

class FutureAccountStatusDropdown extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      accountStatusId,
      input: { onChange },
    } = this.props;
    if (accountStatusId && accountStatusId !== prevProps.accountStatusId) {
      onChange(null);
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      label,
      withLabel,
      accountStatusId,
      dropdownProps,
    } = this.props;

    const accountStatusOptions = map(
      filter(ACCOUNT_STATUSES, ({ id }) => id !== accountStatusId),
      ({ name, id }) => ({
        label: name,
        value: id,
      }),
    );

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('common.updateStatusTo') : undefined),
          options: accountStatusOptions,
          ...dropdownProps,
        }}
      />
    );
  }
}

export default connect(undefined, undefined)(FutureAccountStatusDropdown);
