import styled, { css } from 'styled-components';

import { mapper, sizeMapper, colorMapper } from '../../../utils/styles';
import { BareButtonStyle } from './Button';
import { COLOR_SECONDARY } from '../../styles';
import { CheckboxCheck } from './Checkbox';

interface Props {
  block?: boolean;
  border?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  isDisabled?: boolean;
  margin?: string;
  size?: string;
  checkboxMargin?: string;
  noLabel?: boolean;
  
}

export const RadioCheck = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 12px;
  vertical-align: top;
  border: 1px solid ${props => props.theme.gray};
  border-radius: 8px;
  cursor: pointer;
`;

export const RadioText = styled.span`
  vertical-align: top;
  text-transform: capitalize;
  font-size: 14px;
`;

export const RadioTextButton = styled.span<Props>`
  ${BareButtonStyle};
  padding: ${props => mapper(props.size, { small: '8px 20px', medium: '8px 30px', xLarge: '8px 50px' })};
  background-color: ${colorMapper};
  border-color: ${colorMapper};
  border-style: solid;
  border-width: ${props => (props.border === "none" ? '0px' : '1px')};
  border-radius: 5px;
  text-transform: capitalize;
  line-height: ${props => mapper(props.size, { small: '15px', medium: '18px' })};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px' })};
  color: ${props => (props.color === COLOR_SECONDARY ? props.theme.grayDarker : '#fff')};

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}; 

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + ${RadioCheck} {
    border-color: ${props => props.theme.brandPrimary};
    position: relative;

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      background: ${props => props.theme.brandPrimary};
      display: inline-block;
      border-radius: 100%;
      position: absolute;
      left: 3px;
      top: 3px;
    }
  }

  &:not(:checked) + ${RadioTextButton} {
    background-color: transparent;
    border-color: ${colorMapper};
    color: ${colorMapper};
  }

  &:checked + ${CheckboxCheck} {
    background-color: ${props => props.theme.brandPrimary};
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xIDMuMjQzTDMuMzkgNiA4IDEiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIxLjQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
    background-position: center center;
    background-repeat: no-repeat;
    border-color: ${props => props.theme.brandPrimary};
  }
`;

export const Radio = styled.label<Props>`
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
    `};

  ${RadioCheck} {
    width: ${props => mapper(props.size, { small: '13px', medium: '16px' })};
    height: ${props => mapper(props.size, { small: '13px', medium: '16px' })};
    margin-right: ${props => mapper(props.size, { small: '10px', medium: '12px' })};
    background-size: ${props => mapper(props.size, { small: '7px 7px', medium: '8px 8px' })};
  }

  ${RadioText} {
    line-height: ${props => mapper(props.size, { small: '13px', medium: '15px' })};
    font-size: ${props => mapper(props.size, { small: '12px', medium: '14px' })};
  }

  ${CheckboxCheck} {
    width: ${props => mapper(props.size, { small: '13px', medium: '15px' })};
    height: ${props => mapper(props.size, { small: '13px', medium: '15px' })};
    margin-right: ${props => mapper(props.size, { small: '10px', medium: '12px' })};

    ${props =>
      props.checkboxMargin &&
      css`
        margin: ${sizeMapper(props.checkboxMargin)};
      `};

    ${props =>
      props.noLabel &&
      css`
        margin-top: ${mapper(props.size, { small: '10px', medium: '12px' })};
      `};
  }
`;
