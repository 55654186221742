import { camelCase } from 'lodash-es';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { Grid, GridColumn, ModalSection } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { PopoverDetailsContainer } from '../styled/CustomerLocations';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: (pristine?: boolean) => void;
}
const renderDetail = (value: String | number) => <Grid>{value}</Grid>;

export default function RubiconServicesRatesModal({ closeModal }: Props) {
  const serviceArray = useSelector((state: AppState) => state.customers.rubiconServiceRates.rubiconServiceRates);

  return (
    <Modal size="small" title={translate('opportunity.vendorRates.vendorRates')} onClose={closeModal}>
      <ModalSection margin="medium no no" align="center">
        <PopoverDetailsContainer>
          <Grid multiLine>
            <GridColumn>
              <Grid>
                <u>{translate('opportunity.vendorRates.description')}</u>
              </Grid>
              {serviceArray &&
                serviceArray.map((service: any) =>
                  renderDetail(translate(`opportunity.haulingRates.${camelCase(service.rateDescr)}`)),
                )}
            </GridColumn>
            <GridColumn>
              <Grid>
                <u>{translate('opportunity.vendorRates.vendorRate')}</u>
              </Grid>
              {serviceArray && serviceArray.map(service => renderDetail(service.rate))}
            </GridColumn>
            <GridColumn>
              <Grid>
                <u>{translate('opportunity.vendorRates.vendorUOM')}</u>
              </Grid>
              {serviceArray &&
                serviceArray.map(service =>
                  renderDetail(translate(`opportunity.vendorRates.uom.${camelCase(service.uOMDescr)}`)),
                )}
            </GridColumn>
          </Grid>
          <Grid multiLine></Grid>
        </PopoverDetailsContainer>
      </ModalSection>
    </Modal>
  );
}
