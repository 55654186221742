import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import {
  loadDocuments as doLoadDocuments,
  loadDocumentsByToken as doLoadDocumentsByToken,
} from '../services/rubiconDispatches';
// Actions
const START_LOAD = 'vendors/vehicle/faultCodes/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicle/faultCodes/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicle/faultCodes/FAIL_LOAD';

interface DocumentListsState {
  documents: any[];
  isLoading: boolean;
}

const initialState: DocumentListsState = {
  documents: [],
  isLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          documents: action.documents || [],
          isLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          documents: [],
          isLoading: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (documents: any[]) => ({
  type: COMPLETE_LOAD,
  documents,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDocuments = (scheduledDate: string, token: string, userId:string, vendorId: number, workOrderNumber: string) => (
  dispatch: Dispatch,
) => {
  dispatch(startLoad());
  if (token) {
    const promise = doLoadDocumentsByToken(scheduledDate, token, workOrderNumber);
    promise
      .then(documents => {
        dispatch(completeLoad(documents));
      })
      .catch(() => {
        dispatch(failLoad());
      });
    return promise;
  } else {
    const promise = doLoadDocuments(scheduledDate, userId, vendorId, workOrderNumber);
    promise
      .then(documents => {
        dispatch(completeLoad(documents));
      })
      .catch(() => {
        dispatch(failLoad());
      });
    return promise;
  }
};

