import { includes, size } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import humps from 'humps';

import { Button, ModalFixedFooter, PanelSection, Text } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  downloadSampleRateCodeTemplate,
  downloadSampleAccountingCodeTemplate,
  downloadSampleRateTemplate,
} from 'src/finance/services/rateManager';
import { EXCEL_MIME_TYPES, TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { Loader } from 'src/customers/components/styled/CustomerLocations';
import {
  loadRateCodeImportUploadedFilesStatus,
  loadAccountingCodeImportUploadedFilesStatus,
  loadRateImportUploadedFilesStatus,
} from 'src/finance/ducks';
import { Modal, Table } from 'src/core/components';
import { RateManagerImportModalTableRow } from './RateManagerImportModalTableRow';
import { UploadArea } from 'src/customers/components/styled/CustomerImport';
import { uploadRateCodeImportFile, uploadAccountingCodeImportFile, uploadRateImportFile } from 'src/finance/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

interface Props {
  closeModal: () => void;
  isAccountingCodeImport?: boolean;
  isRateCodeImport?: boolean;
  isRateImport?: boolean;
}

type Drag = React.DragEvent<HTMLDivElement>;

const RateManagerImportModal = ({ closeModal, isAccountingCodeImport, isRateCodeImport, isRateImport }: Props) => {
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { isUploading, uploadedFiles, isLoadingRateImport } = useSelector(state => state.finance.rateManager);

  const [isValidHover, setIsValidHover] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (getIsVendorNotChanged(vendorId)) {
        isRateCodeImport && loadRateCodeImportUploadedFilesStatus(vendorId)(dispatch);
        isAccountingCodeImport && loadAccountingCodeImportUploadedFilesStatus(vendorId)(dispatch);
        isRateImport && loadRateImportUploadedFilesStatus(vendorId)(dispatch);
      }
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch, isRateCodeImport, isAccountingCodeImport, isRateImport, uploadedFiles, vendorId]);

  const isValidFile = (items: DataTransferItemList) => {
    if (items.length !== 1) return false;
    return items[0].kind === 'file' && includes([EXCEL_MIME_TYPES], items[0].type);
  };

  const onDragEnter = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      setIsDragging(true);
      if (isValidFile(e.dataTransfer.items)) setIsValidHover(true);
      else setIsValidHover(false);
    }
  };

  const onDragOver = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (!isDragging) setIsDragging(true);
      if (!isValidHover && isValidFile(e.dataTransfer.items)) {
        setIsValidHover(true);
      }
    }
  };

  const onDragLeave = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    setIsValidHover(false);
    setIsDragging(false);
  };

  const onDrop = (e: Drag) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      if (isValidFile(e.dataTransfer.items)) {
        uploadFile(e.dataTransfer.files[0]);
      }
    }
    setIsValidHover(false);
    setIsDragging(false);
  };

  const uploadFile = (file: File) => {
    const uploadImportFile = isRateCodeImport
      ? uploadRateCodeImportFile
      : isAccountingCodeImport
      ? uploadAccountingCodeImportFile
      : isRateImport
      ? uploadRateImportFile
      : undefined;

    uploadImportFile &&
      uploadImportFile(
        file,
        vendorId,
      )(dispatch)
        .then(() => createSuccessNotification(translate('customers.customerUpload.successMessage')))
        .catch(e => {
          const errorMessageCode =
            e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

          createErrorNotification(
            errorMessageCode
              ? translate(`customers.alertMessages.${errorMessageCode}`)
              : translate('customers.customerUpload.errorMessage'),
          );
        });
  };

  const onFileInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target && e.target.files) {
      uploadFile(e.target.files[0]);
      e.target.value = '';
    }
  };

  const openFileUploader = () => {
    fileUploaderRef.current && fileUploaderRef.current.click();
  };

  const modalTitle = isRateCodeImport
    ? translate('finance.rateManager.rateCodeImport')
    : isAccountingCodeImport
    ? translate('finance.rateManager.accountingCodeImport')
    : isRateImport
    ? translate('finance.rateManager.rateImport')
    : undefined;

  const downloadSampleTemplate = isRateCodeImport
    ? downloadSampleRateCodeTemplate
    : isAccountingCodeImport
    ? downloadSampleAccountingCodeTemplate
    : isRateImport
    ? downloadSampleRateTemplate
    : undefined;

  const tableCellsWidths = ['22%', '19%', '18%', '18%', '23%'];
  const tableCellsRateWidths = ['15%', '12%', '11%', '12%', '11%', '11%', '11%', '17%'];

  const tableCells = [
    {
      name: 'fileName',
      label: translate('customers.customerUpload.file'),
      width: tableCellsWidths[0],
      sortable: true,
    },
    {
      name: 'status',
      label: translate('customers.customerUpload.fileStatus'),
      width: tableCellsWidths[1],
      sortable: true,
    },
    {
      name: 'totalRecordsCount',
      label: translate('customers.customerUpload.numberOfRecords'),
      width: tableCellsWidths[2],
      sortable: true,
    },
    {
      name: 'errorFileDownloadUrl',
      label: translate('customers.customerUpload.outputFile'),
      width: tableCellsWidths[3],
    },
    {
      name: 'date',
      label: translate('customers.customerUpload.dateTime'),
      width: tableCellsWidths[4],
      sortable: true,
    },
  ];

  const tableRateCells = [
    {
      name: 'fileName',
      label: translate('customers.customerUpload.file'),
      width: tableCellsRateWidths[0],
      sortable: true,
    },
    {
      name: 'status',
      label: translate('customers.customerUpload.fileStatus'),
      width: tableCellsRateWidths[1],
      sortable: true,
    },
    {
      name: 'totalRecordsCount',
      label: translate('customers.customerUpload.numberOfRecords'),
      width: tableCellsRateWidths[2],
      sortable: true,
    },
    {
      name: 'uploadedRecordsCount',
      label: translate('customers.customerUpload.numberOfImportedRecords'),
      width: tableCellsRateWidths[3],
      sortable: true,
    },
    {
      name: 'validRecordsCount',
      label: translate('customers.customerUpload.numberOfValidRecords'),
      width: tableCellsRateWidths[4],
      sortable: true,
    },
    {
      name: 'invalidRecordsCount',
      label: translate('customers.customerUpload.numberOfInvalidRecords'),
      width: tableCellsRateWidths[5],
      sortable: true,
    },
    {
      name: 'errorFileDownloadUrl',
      label: translate('customers.customerUpload.outputFile'),
      width: tableCellsRateWidths[6],
    },
    {
      name: 'date',
      label: translate('customers.customerUpload.dateTime'),
      width: tableCellsRateWidths[7],
      sortable: true,
    },
  ];

  const virtualizedProps = {
    itemSize: TABLE_ROW_HEIGHT_SMALL,
    height: Math.min(uploadedFiles.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 4) || 1,
  };

  return (
    <Modal size="xLarge" title={modalTitle} onClose={() => closeModal()} padding="medium no no no">
      <UploadArea
        isValidHover={isValidHover}
        isDragging={isDragging}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {!isUploading && (
          <Text color={`${isDragging ? 'white' : 'primary'}`}>
            <Button
              color={`${isDragging ? 'white' : 'primary'}`}
              text
              underline
              margin="no xxSmall no"
              onClick={openFileUploader}
            >
              <Text weight="medium">{translate('common.browse')}</Text>
            </Button>
            {translate('common.or')} {translate('customers.customerUpload.dropYourExcelFile')}
          </Text>
        )}
        {isUploading && <Loader />}
      </UploadArea>

      {!!size(uploadedFiles) && (
        <PanelSection padding="small" vertical>
          <Table
            cells={isRateCodeImport || isAccountingCodeImport ? tableCells : tableRateCells}
            rows={uploadedFiles}
            rowComponent={RateManagerImportModalTableRow}
            rowProps={{
              isAccountingCodeImport,
              isRateCodeImport,
              loading: isLoadingRateImport,
              tableCellsRateWidths,
              tableCellsWidths,
            }}
            virtualized
            virtualizedProps={virtualizedProps}
          />
          <Text margin="small no medium" fontStyle="italic" color="info" block>
            {translate('customers.customerUpload.informativeMessage')}
          </Text>
        </PanelSection>
      )}

      <ModalFixedFooter isShadowed>
        <Button color="primary" size="medium" line onClick={downloadSampleTemplate} margin="no xSmall no no">
          {translate('customers.customerUpload.downloadSample')}
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={openFileUploader}
          type="button"
          disabled={isUploading}
          margin="no no no xSmall"
        >
          {translate('haulerProfile.uploadFile')}
        </Button>
        <input
          accept={EXCEL_MIME_TYPES}
          id="fileSelect"
          onChange={onFileInputChanged}
          ref={fileUploaderRef}
          style={{ display: 'none' }}
          type="file"
        />
      </ModalFixedFooter>
    </Modal>
  );
};

export default RateManagerImportModal;
