import { http } from '../../core/services/http';
import {
  transformLoadExceptionConfigurations,
  transformSaveExceptionConfigurations,
} from './transformExceptionConfigurations';

export const loadExceptionConfigurations = (
  vendorId: number,
  vehicleTypeId: number,
  vehicleTypeName?: string,
  includeSubtypes?: boolean,
) =>
  http
    .get(`/vendors/${vendorId}/pickupExceptionTypes`, { params: { vehicleTypeId, includeSubtypes } })
    .then(response => transformLoadExceptionConfigurations(response.data, vehicleTypeName));

export const loadDefaultExceptionConfigurations = (vehicleTypeId: number, vehicleTypeName: string) =>
  http
    .get('/vendors/defaultPickupExceptionSettings', { params: { vehicleTypeId } })
    .then(response => transformLoadExceptionConfigurations(response.data, vehicleTypeName));

export const saveExceptionConfigurations = (
  vendorId: number,
  vehicleTypeId: number,
  vehicleTypeName: string,
  pickupExceptionTypes: any,
) =>
  http
    .post(
      `/vendors/${vendorId}/pickupExceptionTypes?vehicleTypeId=${vehicleTypeId}`,
      transformSaveExceptionConfigurations(pickupExceptionTypes, vehicleTypeName),
    )
    .then(response => response.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
