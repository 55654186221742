import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import EsriSyncForm from '../forms/EsriSyncForm';
import { saveEsriSyncSettings } from 'src/vendors/ducks/esriSyncSettings';
import { EsriSyncSettings } from 'src/vendors/interfaces/EsriSyncSettings';

interface Props {
  closeModal: (pristine?: boolean) => void;
}

export default function EsriSyncModal({ closeModal }: Props) {
  const dispatch = useDispatch();

  const { esriSyncSettings } = useSelector((state: AppState) => state.vendors.esriSyncSettings);
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const handleSubmit = (formData: EsriSyncSettings) => {
    saveEsriSyncSettings(vendorId,formData)(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.esriSyncSettingSaved'));
        closeModal();
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.esriSyncSettingError')));  };
  
  return (
    <Modal size="small" title={translate('vendors.esriSync.esriSync')} onClose={closeModal}>
      <ModalSection padding="no" isLoading={false}>
        <EsriSyncForm onCancel={closeModal} onSubmit={handleSubmit} initialValues={esriSyncSettings} />
      </ModalSection>
    </Modal>
  );
}
