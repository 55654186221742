
import { Table } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';
import CityWorksServiceRequestErrorsTableRow from './CityWorksServiceRequestErrorsTableRow';

export default function CityWorksServiceConfigSection() {
    const serviceRequestErrors = useSelector(state => state.vendors.cityWorks.dataConfig || []);

    const woTableCellWidths = {
        problemTypeId: '12%',
        vehicleTypeId: '12%',
        pickupTypeId: '11%',
        wasteMaterialTypeId: '11%',
        equipmentTypeId: '12%',
        useScheduledDate: '10%',
        isAdhoc: '10%',
        createServiceContractIfNotFound: '10%',
        responseCode: '12%',
    };

    const getServiceRequestsErrorTableCells = [
        {
            name: 'problemTypeId',
            label: translate('vendors.cityworks.problemTypeId'),
            width: woTableCellWidths.problemTypeId,
            sortable: false,
        },
        {
            name: 'vehicleTypeId',
            label: translate('vendors.cityworks.vehicleTypeId'),
            width: woTableCellWidths.vehicleTypeId,
            sortable: false,
        },

        {
            name: 'pickupTypeId',
            label: translate('vendors.cityworks.pickupTypeId'),
            width: woTableCellWidths.pickupTypeId,
            sortable: false,
        },
        {
            name: 'wasteMaterialTypeId',
            label: translate('vendors.cityworks.wasteMaterialTypeId'),
            width: woTableCellWidths.wasteMaterialTypeId,
            sortable: false,
        },
        {
            name: 'equipmentTypeId',
            label: translate('vendors.cityworks.equipmentTypeId'),
            width: woTableCellWidths.equipmentTypeId,
            sortable: false,
        },
        // {
        //     name: 'reasonCodeId',
        //     label: translate('vendors.cityworks.reasonCodeId'),
        //     width: '10%',
        //     sortable: false,
        // },
        {
            name: 'useScheduledDate',
            label: translate('vendors.cityworks.useScheduledDate'),
            width: woTableCellWidths.useScheduledDate,
            sortable: false,
        },
        {
            name: 'isAdhoc',
            label: translate('vendors.cityworks.isAdhoc'),
            width: woTableCellWidths.isAdhoc,
            sortable: false,
        },

        {
            name: 'createServiceContractIfNotFound',
            label: translate('vendors.cityworks.createServiceContractIfNotFound'),
            width: woTableCellWidths.createServiceContractIfNotFound,
            sortable: false,
        },
        {
            name: 'responseCode',
            label: translate('vendors.cityworks.responseCode'),
            width: woTableCellWidths.responseCode,
            sortable: false,
        },


    ];

    return (
        <>
            {serviceRequestErrors.length ? (
                <Table
                    cells={getServiceRequestsErrorTableCells}
                    rows={serviceRequestErrors}
                    rowProps={{
                        cellWidths: woTableCellWidths,

                    }}
                    rowComponent={CityWorksServiceRequestErrorsTableRow}

                />
            ) : (
                <Text block align="center">
                    {translate('vendors.open311.noServiceRequestErrors')}
                </Text>
            )}

        </>
    );
}
