import { useState } from 'react';

import { ActionButtonTooltip } from 'src/core/components';
import { ActivitiesContainer } from '../../styled';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { TimeManagement } from 'src/routes/interfaces/TimeManagement';
import { timeManagementTableCellWidths } from './TimeManagementSection';
import ActivityDetail from './TimeManagementActivitySectionDetails';

type Props = {
  id: number;
};
export default function TimeManagementTableRow({
  driverName,
  alternativeFleetServiceActivities,
  vehicleName,
  id,
}: Props & TimeManagement) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpandActivities = () => {
    if (isExpanded) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell width={timeManagementTableCellWidths[0]} vertical>
          <Text>{vehicleName}</Text>
          <Text>{driverName}</Text>
        </TableCell>
        <TableCell width={timeManagementTableCellWidths[1]} vertical>
          <TableActionButton
            id={
              isExpanded ? `hide-route-${id}-service-activities-button` : `show-route-${id}-service-activities-button`
            }
            onClick={handleExpandActivities}
          >
            <ActionButtonTooltip
              icon={isExpanded ? 'arrowUpFill' : 'arrowDownFill'}
              tooltip={isExpanded ? 'hideServiceActivities' : 'showServiceActivities'}
            />
          </TableActionButton>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <ActivitiesContainer>
          {alternativeFleetServiceActivities &&
            alternativeFleetServiceActivities.map((activity, index) => (
              <ActivityDetail activity={activity} key={index} />
            ))}
        </ActivitiesContainer>
      )}
    </>
  );
}
