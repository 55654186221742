import { replace } from 'lodash-es';

import translate from '../../core/services/translate';
import createTransformer from '../../utils/services/createTransformer';
import createTranslationKey from '../../utils/services/createTranslationKey';

const transformLoadProximitySettings = (values: any) => {
  const proximitySettings = createTransformer(values);

  return {
    ...proximitySettings.omit('proximitySettings').value,
    proximitySettings: proximitySettings.get('proximitySettings').map((proximitySettings: any) => {
      const customerTypeName = replace(proximitySettings.customerTypeName, 'Boxyard', 'BoxYard');
      const locationType = replace(replace(proximitySettings.locationType, 'Boxyard', 'BoxYard'), 'Bin', 'Container');

      return {
        ...proximitySettings,
        customerTypeName: translate(createTranslationKey(customerTypeName, 'common.customerTypes')),
        vehicleTypeName: translate(createTranslationKey(proximitySettings.vehicleTypeName, 'vehicles.vehicleTypes')),
        locationTypeName: translate(createTranslationKey(locationType, 'common.locationTypes')),
      };
    }).value,
  };
};

export default transformLoadProximitySettings;
