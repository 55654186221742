import React from 'react';
import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { loadBillingMethods } from 'src/common/ducks';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { INVOICE_PAYMENT_DEFAULT_STATUSES } from 'src/finance/constants/invoicePaymentStatuses';
import { loadOpenBills, loadReceivedViaOptions } from 'src/finance/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import CreatePaymentModal from './CreatePaymentModal';

interface Props {
  onClose(): void;
}
const CreatePaymentModalResolver: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    const dependencies: Promise<unknown>[] = [];

    const noLoadingIndicator = true;
    dependencies.push(
      loadReceivedViaOptions()(dispatch),
      loadBillingMethods()(dispatch),
      loadOpenBills({
        billStatusIds: INVOICE_PAYMENT_DEFAULT_STATUSES,
        vendorId,
        noLoadingIndicator,
      })(dispatch),
    );

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoadingOverlay}
      successComponent={CreatePaymentModal}
      successProps={{
        onClose: onClose,
      }}
    />
  );
};

export default CreatePaymentModalResolver;
