import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { mapper, sizeMapper } from '../../../utils/styles';
import { loadingOverlay } from 'src/core/styles';

export const COLUMN_SIZES = {
  '1/12': '8.33%',
  '2/12': '16.66%',
  '3/12': '25%',
  '4/12': '33.33%',
  '5/12': '41.66%',
  '6/12': '50%',
  '7/12': '58.33%',
  '8/12': '66.66%',
  '9/12': '75%',
  '10/12': '83.33%',
  '11/12': '91.66%',
  '12/12': '100%',
};

interface GridProps {
  align?: string;
  alignRight?: boolean;
  alignVerticalCenter?: boolean;
  animated?: boolean;
  borderBottom?: boolean;
  borderRight?: boolean;
  centered?: boolean;
  column?: boolean;
  darken?: boolean;
  flexDirection?: string;
  height?: number;
  isDeliveryUtility?: boolean;
  isFieldFullWidth?: boolean;
  isGridWithIconAbsolute?: boolean;
  isLoading?: boolean;
  isPinIconAbsolute?: boolean;
  isPinOnMapSelected?: boolean;
  margin?: string;
  marginLeft?: string;
  marginRight?: string;
  maxHeight?: number;
  minHeight?: number;
  multiLine?: boolean;
  padding?: string;
  position?: string;
  scroll?: boolean;
  shift?: string;
  size?: string;
  spaceBetween?: boolean;
  tabletLeftAlign?: boolean;
  tabletPadding?: string;
  tabletRightAlign?: boolean;
  tabletSize?: string;
  textTransform?: string;
  verticalAlign?: string;
  width?: string;
  withBoxShadow?: boolean;
  zIndex?: number;
}

export const GridColumn = styled.div<GridProps>`
  position: relative;
  text-align: ${props => mapper(props.align, { left: 'left', center: 'center', right: 'right' }, 'left')};
  padding: ${props => sizeMapper(props.padding, 'no xSmall')};
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `};

  ${props =>
    props.withBoxShadow &&
    css`
      box-shadow: 0 4px 10px ${props => transparentize(0.95, props.theme.grayBase)};
    `};

  ${props =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `};

  ${props =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `};

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    padding: ${props => sizeMapper(props.tabletPadding, sizeMapper(props.padding))};
  }

  ${props =>
    props.size &&
    css`
      width: calc(
        ${() => {
          let width = mapper(props.size, COLUMN_SIZES);
          if (props.marginLeft || props.marginRight) {
            const { marginLeft, marginRight } = props;
            width = `${width}${marginLeft ? ` - ${marginLeft}` : ''}${marginRight ? ` - ${marginRight}` : ''}`;
          }
          return width;
        }}
      );
    `};

  ${props =>
    props.tabletSize &&
    css`
      @media (max-width: ${props => props.theme.tabletBreakpoint}) {
        width: ${mapper(props.tabletSize, COLUMN_SIZES)};
      }
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.shift &&
    css`
      margin-left: ${mapper(props.shift, COLUMN_SIZES)};
    `};

  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.borderRight &&
    css`
      border-right: 1px solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.verticalAlign &&
    css`
      display: flex;
      align-items: ${props.verticalAlign};
      justify-content: ${() =>
        mapper(
          props.align,
          {
            left: 'flex-start',
            center: 'center',
            right: 'flex-end',
          },
          'flex-start',
        )};
    `}

  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight}px;
    `};

  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `};

  ${props =>
    props.scroll &&
    css`
      overflow-y: auto;
    `};

  ${props =>
    props.isPinIconAbsolute &&
    css`
      position: absolute;
      left: calc(25% - 35px);
      width: auto;
    `}

  ${props =>
    props.isGridWithIconAbsolute &&
    css`
      top: 8px;
    `}

  ${props =>
    props.isDeliveryUtility &&
    props.isPinIconAbsolute &&
    !props.isPinOnMapSelected &&
    css`
      left: calc(33.33% - 35px);
    `}

  ${props =>
    props.isFieldFullWidth &&
    props.isPinIconAbsolute &&
    css`
      left: calc(100% - 35px);
      top: 20px;
      margin-left: -5px;
    `}

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')}
    `}

  ${props =>
    props.alignVerticalCenter &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}

  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `};

  ${props =>
    props.darken &&
    css`
      background-color: ${props => props.theme.grayLighter};
    `};
`;

export const Grid = styled.div<GridProps>`
  display: flex;
  width: 100%;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  flex-direction: ${p => (p.column ? 'column' : 'row')};

  ${props =>
    props.centered &&
    css`
      justify-content: center;
    `};

  ${props =>
    props.verticalAlign &&
    css`
      align-items: ${props.verticalAlign};
    `};

  ${props =>
    props.alignRight &&
    css`
      justify-content: flex-end;
    `};

  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `};

  ${props =>
    props.multiLine &&
    css`
      flex-wrap: wrap;
    `};

  ${props =>
    props.darken &&
    css`
      background-color: ${props => props.theme.grayLighter};
    `};

  ${props =>
    props.height &&
    css`
      height: ${() => props.height}px;
      overflow: auto;
    `};

  ${props =>
    props.hidden &&
    css`
      display: none;
    `};

  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `};

  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `};

  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `}
`;

export const GridFieldWrapper = styled.div<GridProps>`
  ${props =>
    props.isPinIconAbsolute &&
    css`
      width: calc(100% - 35px);
    `};

  ${props =>
    props.isPinOnMapSelected &&
    props.isPinIconAbsolute &&
    css`
      width: calc(100% - 65px);
    `};
`;
