import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const PICKUP_TYPE_PICKUP = 'Pickup';
export const PICKUP_TYPE_FINAL = 'Final';
export const PICKUP_TYPE_DELIVERY = 'Delivery';
export const PICKUP_TYPE_EMPTY_AND_RETURN = 'EmptyAndReturn';
export const PICKUP_TYPE_RELOCATE = 'Relocate';
export const PICKUP_TYPE_SWAP = 'Swap';
export const PICKUP_TYPE_REMOVAL = 'Removal';
export const PICKUP_TYPE_REPLACEMENT = 'Replacement';
export const PICKUP_TYPE_EXTRA_PICKUP = 'ExtraPickup';
export const PICKUP_TYPE_BULK_ITEM = 'BulkItem';
export const PICKUP_TYPE_CLEAN_UP = 'CleanUp';
export const PICKUP_TYPE_MAINTENANCE = 'Maintenance';
export const PICKUP_TYPE_WASH_OUT = 'WashOut';
export const PICKUP_TYPE_SERVICE_CHANGE = 'ServiceChange';
export const PICKUP_TYPE_SERVICE = 'Service';

export const PICKUP_TYPE_PICKUP_ID = 1;
export const PICKUP_TYPE_FINAL_ID = 2;
export const PICKUP_TYPE_DELIVERY_ID = 3;
export const PICKUP_TYPE_EMPTY_AND_RETURN_ID = 4;
export const PICKUP_TYPE_RELOCATE_ID = 5;
export const PICKUP_TYPE_SWAP_ID = 6;
export const PICKUP_TYPE_REMOVAL_ID = 7;
export const PICKUP_TYPE_REPLACEMENT_ID = 8;
export const PICKUP_TYPE_EXTRA_PICKUP_ID = 9;
export const PICKUP_TYPE_BULK_ITEM_ID = 10;
export const PICKUP_TYPE_CLEAN_UP_ID = 11;
export const PICKUP_TYPE_MAINTENANCE_ID = 12;
export const PICKUP_TYPE_WASH_OUT_ID = 13;
export const PICKUP_TYPE_SERVICE_CHANGE_ID = 14;
export const PICKUP_TYPE_SERVICE_ID = 15;

const routePickupTypes = [
  { technicalName: PICKUP_TYPE_PICKUP, name: translate('routes.pickupTypes.pickup'), id: PICKUP_TYPE_PICKUP_ID },
  { technicalName: PICKUP_TYPE_FINAL, name: translate('routes.pickupTypes.final'), id: PICKUP_TYPE_FINAL_ID },
  { technicalName: PICKUP_TYPE_DELIVERY, name: translate('routes.pickupTypes.delivery'), id: PICKUP_TYPE_DELIVERY_ID },
  { technicalName: PICKUP_TYPE_EMPTY_AND_RETURN, name: translate('routes.pickupTypes.emptyAndReturn'), id: PICKUP_TYPE_EMPTY_AND_RETURN_ID },
  { technicalName: PICKUP_TYPE_RELOCATE, name: translate('routes.pickupTypes.relocate'), id: PICKUP_TYPE_RELOCATE_ID },
  { technicalName: PICKUP_TYPE_SWAP, name: translate('routes.pickupTypes.swap'), id: PICKUP_TYPE_SWAP_ID },
  { technicalName: PICKUP_TYPE_REMOVAL, name: translate('routes.pickupTypes.removal'), id: PICKUP_TYPE_REMOVAL_ID },
  { technicalName: PICKUP_TYPE_REPLACEMENT, name: translate('routes.pickupTypes.replacement'), id: PICKUP_TYPE_REPLACEMENT_ID },
  { technicalName: PICKUP_TYPE_EXTRA_PICKUP, name: translate('routes.pickupTypes.extraPickup'), id: PICKUP_TYPE_EXTRA_PICKUP_ID },
  { technicalName: PICKUP_TYPE_BULK_ITEM, name: translate('routes.pickupTypes.bulkItem'), id: PICKUP_TYPE_BULK_ITEM_ID },
  { technicalName: PICKUP_TYPE_CLEAN_UP, name: translate('routes.pickupTypes.cleanUp'), id: PICKUP_TYPE_CLEAN_UP_ID },
  { technicalName: PICKUP_TYPE_MAINTENANCE, name: translate('routes.pickupTypes.maintenance'), id: PICKUP_TYPE_MAINTENANCE_ID },
  { technicalName: PICKUP_TYPE_WASH_OUT, name: translate('routes.pickupTypes.washOut'), id: PICKUP_TYPE_WASH_OUT_ID },
  { technicalName: PICKUP_TYPE_SERVICE_CHANGE, name: translate('routes.pickupTypes.serviceChange'), id: PICKUP_TYPE_SERVICE_CHANGE_ID },
  { technicalName: PICKUP_TYPE_SERVICE, name: translate('routes.pickupTypes.service'), id: PICKUP_TYPE_SERVICE_ID },
];

export const ROUTE_PICKUP_TYPES = mapKeys(routePickupTypes, 'technicalName');
export const ROUTE_PICKUP_TYPE_IDS = mapKeys(routePickupTypes, 'id');
