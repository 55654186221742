import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { DocumentTitle } from 'src/common/components';
import { PageLoading } from 'src/common/components/styled';
import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { TypedResolver } from 'src/core/components';
import { FIFTEEN_DAYS_AFTER_TODAY, NINETY_DAYS_BEFORE_TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { loadBilling, loadContainerTypes, loadInvoiceStatuses, loadWorkFlowTypes } from 'src/finance/ducks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import BillingPage from './BillingPage';

interface QueryParams {
  billsSearchTerm?: string;
  billStatusId?: number;
  startDate?: StringOrDate;
  endDate?: StringOrDate;
  unbilledSearchTerm?: string;
  workOrderTypeId?: number;
  containerTypeId?: number;
}

const BillingPageResolver: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const {
    billsSearchTerm,
    billStatusId,
    startDate = NINETY_DAYS_BEFORE_TODAY,
    endDate = FIFTEEN_DAYS_AFTER_TODAY,
    unbilledSearchTerm,
    workOrderTypeId,
    containerTypeId,
  } = getQueryParams<QueryParams>(search);

  const loadDependencies = () => {
    return Promise.all([
      loadBilling(
        vendorId,
        startDate,
        endDate,
        billsSearchTerm,
        billStatusId,
        unbilledSearchTerm,
        workOrderTypeId,
        containerTypeId,
        true,
      )(dispatch),
      loadWorkFlowTypes()(dispatch),
      loadContainerTypes()(dispatch),
      loadInvoiceStatuses()(dispatch),
    ]);
  };

  return (
    <Fragment>
      <DocumentTitle>{translate('finance.billing')}</DocumentTitle>
      <TypedResolver
        successComponent={BillingPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/dashboard"
      />
    </Fragment>
  );
};

export default BillingPageResolver;
