import { camelCase, debounce, filter, map } from 'lodash-es';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants';

import { multiSelectTextFormat } from 'src/common/services/multiSelectTextFormat';
import { DateRangePicker, MultiSelect, Switch, TypeAhead, TypedField } from 'src/core/components';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { MultiSelectOption } from 'src/core/components/MultiSelect';
import { Button, Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import { DATE_RANGE_PICKER_TODAY_7_30_90_PAST } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { loadCustomersSimplified } from 'src/customers/ducks';
import { Customer } from 'src/customers/interfaces/Customers';
import { CustomerLocation, TypeAheadOption } from 'src/finance/interfaces/ApplyChecks';
import { isRequired } from 'src/utils/services/validator';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';

const BILL_CREATE_FILTER_FORM = 'billCreateFilterForm';

const formSelector = formValueSelector(BILL_CREATE_FILTER_FORM);

const getCustomerStatusText = multiSelectTextFormat('finance.allCustomerStatuses', 'finance.xCustomerStatusesSelected');

const getCustomerTypeText = multiSelectTextFormat('finance.allCustomerTypes', 'finance.xCustomerTypesSelected');

const getPaymentTermsText = multiSelectTextFormat('finance.allPaymentTerms', 'finance.xPaymentTermsSelected');

export interface BillCreateFilterFormValues {
  newBillDateRange: DateRangeOptionValue;
  customer?: TypeAheadOption;
  location?: TypeAheadOption;
  customerStatusIds?: number[];
  customerTypeIds?: number[];
  billMethodIds?: number[];
  paymentTermsIds?: number[];
}

interface PropsWithoutReduxForm extends RouteComponentProps {
  change?: any;
  formValues?: BillCreateFilterFormValues;
  initialValues?: BillCreateFilterFormValues;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<BillCreateFilterFormValues, PropsWithoutReduxForm>;

const BillCreateFilterForm: React.FC<Props> = ({ handleSubmit, initialValues, change }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const customer = useSelector(state => formSelector(state, 'customer'));
  const customerId = customer?.customerId;

  const customerAccountStatusesOptions = useSelector(
    state => state.finance.customerAccountStatuses.customerAccountStatuses,
  );
  const customerTypeOptions = useSelector(state => state.customers.customerTypes.customerTypes);
  const paymentTermOptions = useSelector(state => state.customers.paymentTerms.paymentTerms);

  const customerAccountStatuses: MultiSelectOption[] = map(customerAccountStatusesOptions, ({ name, id }) => ({
    label: name,
    value: id,
  }));

  const customerTypes: MultiSelectOption[] = map(customerTypeOptions, ({ technicalName, id }) => ({
    label: translate(`customers.customerTypes.${camelCase(technicalName)}`),
    value: id,
  }));

  const paymentTerms: MultiSelectOption[] = map(paymentTermOptions, ({ name, id }) => ({
    label: name,
    value: id,
  }));

  const updateFormValue = (name: string, value: any) => dispatch(change(name, value));

  const loadCustomerLocationOptions = debounce((searchTerm, onOptionsLoaded) => {
    const customerTypeIds = undefined;
    const customerStatusTypeIds = undefined;
    const page = undefined;
    const limit = undefined;
    const sortedBy = undefined;

    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page,
      limit,
      sortedBy,
      searchType: SEARCH_BY_NAME_OR_ADDRESS,
    })(dispatch).then(res => {
      const customerLocations = map(
        filter(res.customers, (customer: Customer) => customer.id === customerId),
        (customer: Customer) =>
          map(customer.locations, (location: CustomerLocation) => ({
            value: { locationId: location.id, locationName: location.name },
            label: location.name,
          })),
      );

      return onOptionsLoaded(...customerLocations);
    });
  }, 500);

  const loadCustomerOptions = debounce((searchTerm, onOptionsLoaded) => {
    const customerTypeIds = undefined;
    const customerStatusTypeIds = undefined;
    const page = undefined;
    const limit = undefined;
    const sortedBy = undefined;

    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    loadCustomersSimplified({
      vendorId,
      searchTerm,
      customerTypeIds,
      customerStatusTypeIds,
      page,
      limit,
      sortedBy,
      searchType: SEARCH_BY_NAME_OR_ADDRESS,
    })(dispatch).then(res => {
      return onOptionsLoaded(
        map(res.customers, (customer: Customer) => ({
          value: { customerId: customer.id, customerName: customer.name },
          label: customer.name,
        })),
      );
    });
  }, 500);

  const onCustomerChange = () => {
    updateFormValue('location', null);
    initialValues.customer = undefined;
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection padding="small xSmall no" withBorder>
        <Grid multiLine>
          <GridColumn size="3/12">
            <Field
              name="newBillDateRange"
              component={DateRangePicker}
              options={DATE_RANGE_PICKER_TODAY_7_30_90_PAST}
              placeholder={translate('finance.dateRange')}
              props={{ hasMarginLeft: 'small' }}
              validate={[isRequired]}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              component={TypeAhead}
              name="customer"
              props={{
                getOptions: loadCustomerOptions,
                isClearable: true,
                defaultValue: initialValues.customer,
                placeholder: `${translate('finance.customerName')}`,
              }}
              onChange={onCustomerChange}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              component={TypeAhead}
              name="location"
              props={{
                getOptions: loadCustomerLocationOptions,
                isClearable: true,
                defaultValue: initialValues.location,
                placeholder: `${translate('finance.locationName')}`,
                disabled: !customerId && !initialValues.customer,
              }}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <Field
              name="customerStatusIds"
              component={MultiSelect}
              options={customerAccountStatuses}
              placeholder={translate('finance.customerStatus')}
              formatText={getCustomerStatusText}
              normalizeValues={Number}
              isSearchable
              isClearable
            />
          </GridColumn>
          <GridColumn size="3/12">
            <Field
              name="customerTypeIds"
              component={MultiSelect}
              options={customerTypes}
              placeholder={translate('finance.customerType')}
              formatText={getCustomerTypeText}
              normalizeValues={Number}
              isSearchable
              isClearable
            />
          </GridColumn>
          <GridColumn size="4/12">
            <Field
              name="paymentTermsIds"
              component={MultiSelect}
              options={paymentTerms}
              placeholder={translate('finance.paymentTerms')}
              formatText={getPaymentTermsText}
              normalizeValues={Number}
              isSearchable
              isClearable
            />
          </GridColumn>
          <GridColumn size="2/12">
            <Field
              name="isOverdue"
              component={Switch}
              props={{
                label: translate('common.pastDue'),
                margin: 'xxSmall no no',
              }}
            />
          </GridColumn>
          <GridColumn size="3/12" align="right">
            <Button line id="show-data-button" color="primary">
              {translate('finance.showData')}
            </Button>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default withRouter(
  reduxForm<BillCreateFilterFormValues, PropsWithoutReduxForm>({
    enableReinitialize: true,
    form: BILL_CREATE_FILTER_FORM,
  })(BillCreateFilterForm),
);
