import translate from 'src/core/services/translate';
import theme from 'src/core/styles/theme';
import {
  DispatchBoardJobGhostContainer,
  DispatchBoardJobGhostSecondary,
} from 'src/routes/components/styled/DispatchBoard/DispatchBoardJobs';
import { ThemeProvider } from 'styled-components';
import ReactDOM from 'react-dom';

export const createDispatchBoardMultipleJobsDragGhost = (dt: DataTransfer) => {
  const containerId = 'dispatch-board-multiple-jobs-drag-ghost';
  let ghost = document.getElementById(containerId);
  if (!ghost) {
    ghost = document.createElement('div');
    ghost.id = 'dispatch-board-multiple-jobs-drag-ghost';
    ghost.style.position = 'absolute';
    ghost.style.top = '-1000px';
    document.body.appendChild(ghost);
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <DispatchBoardJobGhost />
      </ThemeProvider>,
      ghost,
    );
  }
  dt.setDragImage(ghost, 0, 0);
};

export const DispatchBoardJobGhost: React.FC = () => {
  return (
    <DispatchBoardJobGhostContainer>
      <DispatchBoardJobGhostSecondary>
        {translate('dispatchBoard.transferringMultipleJobs')}
      </DispatchBoardJobGhostSecondary>
    </DispatchBoardJobGhostContainer>
  );
};
