import { camelCase, indexOf } from 'lodash-es';
import { Fragment, PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { CartFillLevelPopover } from '../modals';
import { date, dateAndTime } from 'src/utils/services/formatter';
import { Label, TableActionButton, TableActionLink, TableCell, TableRow, Text } from 'src/core/components/styled';
import { OTHER_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { PermissionGuard } from 'src/account/components';
import {
  PICKUP_STATUS_LABEL_COLORS,
  PICKUP_TYPE_PICKUP,
  ROUTE_PICKUP_TYPES,
  ROUTE_STATUSES_BY_ID,
} from 'src/routes/constants';
import { PICKUP_STATUSES, SCHEDULED } from 'src/common/constants';
import {
  RouteLocationImagesModalResolver,
  RouteLocationIssuesModalResolver,
  RouteLocationNotesModalResolver,
} from 'src/routes/components/modals';
import { ROUTES_TRACKER_VIEW_ADD_NOTE } from 'src/account/constants';
import { ServiceHistory } from 'src/customers/interfaces/ServiceHistory';
import { StatusDetailsPopover } from './';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { TOP } from 'src/core/constants';
import { TOTER, WASTE_AUDIT } from 'src/fleet/constants';
import { WeightTicketsResolver } from 'src/routes/components/pages/routes/routePageSections';
import createPopover from 'src/core/services/createPopover';
import JobPriorityTypeIndicator from 'src/routes/components/pages/common/JobPriorityTypeIndicator';
import translate from 'src/core/services/translate';

type Props = RouteComponentProps<{ customerId: string }> &
  ServiceHistory & {
    isMaterialTypesEnabled: boolean;
    reasonCodeTypes: TechnicalType[];
    wasteAuditLocationStatusTypeId?: number;
    wasteAuditStatuses?: number[];
  };

interface State {
  isRouteLocationImagesModalOpen: boolean;
  isRouteLocationIssuesModalOpen: boolean;
  isRouteLocationNotesModalOpen: boolean;
  isWeightTicketModalOpen: boolean;
}

class ServiceHistoryTableRow extends PureComponent<Props, State> {
  closePopover?: () => void;

  state = {
    isRouteLocationImagesModalOpen: false,
    isRouteLocationIssuesModalOpen: false,
    isRouteLocationNotesModalOpen: false,
    isWeightTicketModalOpen: false,
  };

  openRouteLocationIssuesModal = () => {
    this.setState({ isRouteLocationIssuesModalOpen: true });
  };

  closeRouteLocationIssuesModal = () => {
    this.setState({ isRouteLocationIssuesModalOpen: false });
  };

  openRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: true });
  };

  closeRouteLocationNotesModal = () => {
    this.setState({ isRouteLocationNotesModalOpen: false });
  };

  openRouteLocationImagesModal = () => {
    this.setState({ isRouteLocationImagesModalOpen: true });
  };

  closeRouteLocationImagesModal = () => {
    this.setState({ isRouteLocationImagesModalOpen: false });
  };

  openWeightTicketModal = () => {
    this.setState({ isWeightTicketModalOpen: true });
  };

  closeWeightTicketModal = () => {
    this.setState({ isWeightTicketModalOpen: false });
  };

  showCartFillLevelPopover = (event: any) => {
    const { routeId, routeLocationId, cartCount } = this.props;
    if (!cartCount) return;
    event.stopPropagation();
    this.closePopover = createPopover(
      event.currentTarget,
      CartFillLevelPopover,
      { routeId, routeLocationId },
      { position: TOP, size: 'small' },
    );
  };

  closeCartFillLevelPopover = () => {
    !!this.closePopover && this.closePopover();
  };

  showStatusDetailsPopover = (event: any) => {
    const { wasterRecorders, wasteAuditStatuses, vehicleType, wasteAuditType, wasteAuditLocationStatusTypeId } =
      this.props;

    if (indexOf([WASTE_AUDIT, TOTER], vehicleType) === -1) return;

    event.stopPropagation();
    createPopover(
      event.currentTarget,
      StatusDetailsPopover,
      { wasterRecorders, wasteAuditStatuses, vehicleType, wasteAuditType, wasteAuditLocationStatusTypeId },
      { position: TOP, size: 'small' },
    );
  };

  render() {
    const {
      isMaterialTypesEnabled,
      routeId,
      match: {
        params: { customerId },
      },
      ...routeLocation
    } = this.props;
    const {
      isRouteLocationImagesModalOpen,
      isRouteLocationIssuesModalOpen,
      isRouteLocationNotesModalOpen,
      isWeightTicketModalOpen,
    } = this.state;

    const {
      address,
      cartCount,
      customerName,
      driverName,
      imageCount,
      isOneTimeService,
      issueReportedCount,
      issueReportedEmailSentCount,
      jobPriorityTypeId,
      jobType,
      locationName,
      materialType,
      noteCount,
      pickupStatus,
      pickupStatusId,
      reasonCodeTypeId,
      reasonCodeTypes,
      refNumber,
      routeLocationId,
      routeName,
      serviceDate,
      serviceName,
      vehicleName,
      vehicleType,
      wasteAuditType,
      wasteMaterialTypeName,
      weightTicketCount,
      workOrderNumber,
    } = routeLocation;

    const reasonCode = reasonCodeTypes.find(code => code.id === reasonCodeTypeId);

    const materialTypeName =
      materialType?.categoryId === OTHER_ID
        ? materialType?.technicalName
        : translate(`vendors.bulkyItemScheduler.itemTypes.${camelCase(materialType?.technicalName)}`);

    return (
      <Fragment>
        <TableRow>
          <TableCell vertical width="17%" justifyContent="center">
            <Text block weight="medium" margin="no no xxSmall" singleLine title={locationName}>
              {locationName}
            </Text>
            <Text weight="light" margin="no no xxSmall" title={address} doubleLine>
              {address}
            </Text>
          </TableCell>
          <TableCell vertical width="14%" align="center">
            <Text block weight="medium" margin="no no xxSmall" singleLine title={serviceName}>
              {serviceName}
            </Text>
            <Text weight="light" margin="no no xxSmall" singleLine title={wasteMaterialTypeName}>
              {wasteMaterialTypeName}
            </Text>
          </TableCell>
          <TableCell width="12%">
            {pickupStatus === ROUTE_STATUSES_BY_ID[SCHEDULED].technicalName
              ? date(serviceDate)
              : dateAndTime(serviceDate)}
          </TableCell>
          <TableCell width="11%" vertical align="center">
            <Label
              color={PICKUP_STATUS_LABEL_COLORS[pickupStatusId.toString()]}
              onClick={this.showStatusDetailsPopover}
            >
              {PICKUP_STATUSES[pickupStatusId].name}
            </Label>
          </TableCell>

          <TableCell vertical align="center" width="17%">
            <Text weight="medium" margin="no" position="relative">
              {routeName}
              {isOneTimeService && (
                <ActionButtonTooltip
                  margin="no no no xSmall"
                  iconSize="mMedium"
                  icon="calendar"
                  tooltip="oneTimeService"
                  color="alert"
                />
              )}
            </Text>

            {!!vehicleName && (
              <Text weight="light" margin="xxSmall no no" singleLine>
                {vehicleName}
              </Text>
            )}

            {!!driverName && (
              <Text weight="light" margin="xxSmall no no" singleLine>
                {driverName}
              </Text>
            )}
          </TableCell>

          <TableCell vertical align="center" width="13%" padding="defaultCellVertical no">
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
              <Box width="84%">
                <Text block weight="medium" margin="no" singleLine>
                  {jobType
                    ? jobType === PICKUP_TYPE_PICKUP
                      ? translate('routes.pickupTypes.pickup')
                      : ROUTE_PICKUP_TYPES[jobType].name
                    : '-'}
                </Text>

                {isMaterialTypesEnabled && materialType?.technicalName && (
                  <Text block weight="light" size="small" margin="no" singleLine title={materialTypeName}>
                    {materialTypeName}
                  </Text>
                )}

                {!!reasonCode && (
                  <Text block weight="light" size="small" margin="no" singleLine title={reasonCode.name}>
                    {reasonCode.name}
                  </Text>
                )}

                {!!workOrderNumber && (
                  <Text block weight="light" size="small" margin="no" singleLine title={workOrderNumber.toString()}>
                    {translate('routes.woNumber')}: {workOrderNumber}
                  </Text>
                )}

                {!!refNumber && (
                  <Text block weight="light" size="small" margin="no" singleLine title={refNumber.toString()}>
                    {translate('routes.refNumber')}: {refNumber}
                  </Text>
                )}
              </Box>
              {jobPriorityTypeId && (
                <Box ml={5}>
                  <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={jobPriorityTypeId} />
                </Box>
              )}
            </Box>
          </TableCell>

          <TableCell width="16%" align="right">
            {vehicleType === TOTER && !!cartCount && (
              <TableActionButton
                onMouseOver={this.showCartFillLevelPopover}
                onMouseOut={this.closeCartFillLevelPopover}
              >
                <ActionButtonTooltip
                  icon="cart"
                  iconSize="mLarge"
                  customViewBox="-15 -7.5 22 31"
                  marginTop="8px"
                  content={cartCount}
                />
              </TableActionButton>
            )}
            {!!issueReportedCount && (
              <TableActionButton onClick={this.openRouteLocationIssuesModal}>
                <ActionButtonTooltip icon="issue" tooltip="issues" content={issueReportedCount} />
              </TableActionButton>
            )}
            {!!weightTicketCount && (
              <TableActionButton onClick={this.openWeightTicketModal}>
                <ActionButtonTooltip icon="ticket" tooltip="tickets" content={weightTicketCount} rotationAngle="-45" />
              </TableActionButton>
            )}
            {!!noteCount && (
              <PermissionGuard permission={ROUTES_TRACKER_VIEW_ADD_NOTE}>
                <TableActionButton onClick={this.openRouteLocationNotesModal}>
                  <ActionButtonTooltip icon="note" tooltip="notes" content={noteCount} />
                </TableActionButton>
              </PermissionGuard>
            )}
            {!!imageCount && (
              <TableActionButton onClick={this.openRouteLocationImagesModal}>
                <ActionButtonTooltip icon="image" tooltip="images" content={imageCount} />
              </TableActionButton>
            )}
            {!!issueReportedEmailSentCount && (
              <TableActionLink
                to={`/customers/customers/${customerId}/serviceHistory/communication/${routeLocationId}`}
              >
                <ActionButtonTooltip icon="email" tooltip="sentEmails" content={issueReportedEmailSentCount} />
              </TableActionLink>
            )}
          </TableCell>
        </TableRow>
        {!!isRouteLocationIssuesModalOpen && (
          <RouteLocationIssuesModalResolver
            routeId={routeId}
            routeLocationId={routeLocationId}
            modalTitle={customerName}
            modalSubTitle={locationName}
            closeModal={this.closeRouteLocationIssuesModal}
            wasteAuditType={wasteAuditType}
          />
        )}
        {!!isRouteLocationNotesModalOpen && (
          <RouteLocationNotesModalResolver
            modalTitle={customerName}
            modalSubTitle={locationName}
            routeId={routeId}
            routeLocationId={routeLocationId}
            siteAddress={address}
            closeModal={this.closeRouteLocationNotesModal}
            isReadOnly
          />
        )}
        {!!isRouteLocationImagesModalOpen && (
          <RouteLocationImagesModalResolver
            routeId={routeId}
            routeLocationId={routeLocationId}
            closeModal={this.closeRouteLocationImagesModal}
          />
        )}
        {!!isWeightTicketModalOpen && (
          <WeightTicketsResolver
            routeId={routeId}
            closeModal={this.closeWeightTicketModal}
            routeLocationId={routeLocationId}
            modalTitle={customerName}
            modalSubTitle={locationName}
            siteAddress={address}
            isModal
            isDisposalSectionHidden
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(ServiceHistoryTableRow);
