import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadGeoFenceFilterOptions } from 'src/routes/ducks/';
import { PanelSectionLoading } from 'src/core/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import DispatchBoardMapGL from './DispatchBoardMapGL';
import { isCityAlertFeatureEnabled } from 'src/vendors/ducks/features';
import { loadCityAlertSettings, resetCityAlerts } from 'src/vendors/ducks';

type Props = {
  searchTerm?: string;
  date?: string | Date;
  vehicleTypeId?: number;
};

export default function DispatchBoardMapResolver(props: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const isCityAlertEnabled = useSelector(isCityAlertFeatureEnabled);

  const loadDependencies = () => {
    const { date, vehicleTypeId } = props;
    const dependencies = [loadGeoFenceFilterOptions({ vendorId, routeDate: date, vehicleTypeId })(dispatch)];

    if (isCityAlertEnabled) {
      dependencies.push(loadCityAlertSettings(vendorId)(dispatch));
      dispatch(resetCityAlerts());
    }
    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PanelSectionLoading}
      resolve={loadDependencies}
      successComponent={DispatchBoardMapGL}
      successProps={props}
      loadingProps={{ minHeight: 500 }}
    />
  );
}
