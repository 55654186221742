import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ACTIVE = 'ACTIVE';
export const IN_ACTIVE = 'INACTIVE';
export const ON_HOLD = 'ON-HOLD';

export const SERVICE_STATUSES = mapKeys(
  [
    { id: ACTIVE, name: translate('common.accountStatuses.active') },
    { id: IN_ACTIVE, name: translate('dashboard.inactive') },
    { id: ON_HOLD, name: translate('common.accountStatuses.onHold') },
  ],
  'id',
);

export const NEW_SERVICE_ID = -1;
