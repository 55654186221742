import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { AppState } from '../../../store';
import { Resolver } from '../../../core/components';
import { TODAY_FORMATTED } from '../../../core/constants';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { loadUnassignedWeightTickets } from '../../ducks/index';
import { UnassignedWeightTicketsModal } from '../modal/index';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';

interface Props {
  token: string;
  closeModal: () => void;
  selectedFile: (fileId: number, fileName: string) => void;
  vendorId: number;
  loadUnassignedWeightTickets: (
    token: string,
    vendorId: number,
    startDate: string,
    endDate: string,
    searchTerm: string,
    page: number,
  ) => Promise<any>;
}

class UnassignedWeightTicketsModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadUnassignedWeightTickets, token, vendorId } = this.props;
    const from = moment().subtract(3, 'days').toDate();
    const searchString = '';
    const page = 1;
    return loadUnassignedWeightTickets(
      token,
      vendorId,
      moment(from).format('MM/DD/YYYY'),
      TODAY_FORMATTED,
      searchString,
      page,
    );
  };

  render() {
    const { token, closeModal, selectedFile } = this.props;

    return (
      <Resolver
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
        successComponent={UnassignedWeightTicketsModal}
        successProps={{ token, closeModal, selectedFile }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadUnassignedWeightTickets };

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedWeightTicketsModalResolver);
