import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, Modal } from '../../../../core/components';
import { ActivityDetailsContainer } from '../../styled';
import { AlternativeFleetServiceActivity } from 'src/routes/interfaces/TimeManagement';
import { duration as durationFormatter } from 'src/utils/services/formatter';
import { MAP_CITY_ZOOM_IN_BIGGER } from 'src/core/constants';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import {
  Button,
  DetailsListBodySection,
  DetailsListItemDescription,
  DetailsListItemTitle,
  Text,
} from '../../../../core/components/styled';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import OverviewMapGL from 'src/haulerProfile/components/OverviewMapGL';
import translate from '../../../../core/services/translate';

interface Props {
  activity: AlternativeFleetServiceActivity;
}

export const activityDetailsWidths = ['20%', '40%', '20%', '20%'];

const ActivityDetail = ({ activity }: Props) => {
  const [isTimeManagementLocationModalOpen, setIsTimeManagementLocationModalOpen] = useState(false);

  const isNonService = activity.activityType.includes('Non Service');

  const timestampMoment = moment(activity.activityInitiation);
  const formattedAddress = activity.lineSegment
    ? isNonService
      ? [[activity.lineSegment.split(', ').map(Number)]]
      : JSON.parse(activity.lineSegment)
    : null;

  const timeManagementActivitySectionDetails = [
    {
      id: 'activityInitiation',
      title: translate('routes.timeManagementData.dateTimestamp'),
      description: (
        <>
          <Text weight="medium">{timestampMoment.format('MM/DD/YYYY')}</Text>
          <br />
          <Text>{timestampMoment.format(timeFormatWithoutSeconds)}</Text>
        </>
      ),
    },
    {
      id: 'activityType',
      title: translate('routes.timeManagementData.activityType'),
      description: translate(createTranslationKey(activity.activityType, 'routes.timeManagementData')) || '-',
    },
    {
      id: 'durationTime',
      title: translate('routes.timeManagementData.durationTime'),
      description: durationFormatter(activity.duration) || '-',
    },
    {
      id: 'locationName',
      title: translate('routes.timeManagementData.locationName'),
      description: (
        <>
          <Button
            type="button"
            text
            margin="no small"
            padding="no"
            disabled={!activity.lineSegment}
            onClick={() => setIsTimeManagementLocationModalOpen(true)}
          >
            <ActionButtonTooltip
              icon="map"
              size="medium"
              tooltip={!!activity.lineSegment ? translate('routes.seeLocationOnMap') : undefined}
              tooltipAsString
            />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <ActivityDetailsContainer>
        {timeManagementActivitySectionDetails.map((activityDetail, index) => (
          <DetailsListBodySection key={activityDetail.id} width={activityDetailsWidths[index]} justifyBetween>
            <DetailsListItemTitle size="small" color="secondary" margin="no no xSmall">
              {activityDetail.title}
            </DetailsListItemTitle>
            <DetailsListItemDescription width="100%">{activityDetail.description}</DetailsListItemDescription>
          </DetailsListBodySection>
        ))}
      </ActivityDetailsContainer>

      {isTimeManagementLocationModalOpen && (
        <Modal verticalSize="medium" onClose={() => setIsTimeManagementLocationModalOpen(false)}>
          {!!activity.lineSegment && (
            <OverviewMapGL address={formattedAddress} isMarker={isNonService} mapZoom={MAP_CITY_ZOOM_IN_BIGGER} />
          )}
        </Modal>
      )}
    </>
  );
};

export default ActivityDetail;
