import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const PROPERTY = 1;
export const ATTRIBUTE = 2;

export const SCHEDULED_DATE_TYPE= mapKeys(
  [
    { id: PROPERTY, name: translate('vendors.open311.property'), technicalName: 'Property' },
    { id: ATTRIBUTE, name: translate('vendors.open311.attribute'), technicalName: 'Attribute' },
  ],
  'id',
);
