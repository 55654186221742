import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { PureComponent } from 'react';
import { throttle } from 'lodash-es';

import { AppState } from 'src/store';
import { Button, ButtonSet, Grid, GridColumn, PanelSection, Text } from 'src/core/components/styled';
import { DatePicker, Input, TypedField } from 'src/core/components';
import { isRequired } from 'src/utils/services/validator';
import { TODAY } from 'src/core/constants';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

interface ComponentProps {
  isLoadingNextRunNumber: boolean;
  isReseting?: boolean;
  routeName: string;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class RouteResetForm extends PureComponent<Props> {
  state = {
    isResetingRoute: false,
  };

  handleResetClick = throttle(async () => {
    this.setState({ isResetingRoute: true });
    (this.props.handleSubmit as any)();

    setTimeout(() => {
      this.setState({ isResetingRoute: false });
    }, 1000);
  }, 1000);

  render() {
    const { isResetingRoute } = this.state;
    const { isLoadingNextRunNumber, isReseting } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <PanelSection vertical isLoading={isLoadingNextRunNumber || isReseting}>
          <Text block weight="medium" align="center" margin="no no small">
            {translate('routes.resetRoute')}
          </Text>

          <Grid multiLine>
            <GridColumn size="12/12" align="left">
              <TypedField
                name="routeName"
                component={Input}
                props={{ label: translate('routes.routeName'), margin: 'xxSmall small small small' }}
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="12/12" align="left">
              <TypedField
                name="date"
                component={DatePicker}
                props={{
                  disabledDays: [{ after: TODAY }],
                  label: translate('common.date'),
                  disabled: true,
                  margin: 'small small xxSmall small',
                }}
              />
            </GridColumn>
          </Grid>

          <ButtonSet>
            <Button type="button" color="primary" disabled={isResetingRoute} onClick={this.handleResetClick}>
              {translate('common.reset')}
            </Button>
          </ButtonSet>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const nextRunNumber = state.routes.routeTracker.nextRunNumber;
  const routeName = ownProps.routeName;

  const getNewRouteName = (routeName: string, nextRunNumber: number) => {
    if (/R\d+$/.test(routeName)) {
      // if already has R# in name at the end, repace with new nextRunNumber
      return routeName.replace(/R\d+$/, `R${nextRunNumber}`);
    }
    return `${routeName} R${nextRunNumber}`;
  };

  return {
    initialValues: { date: TODAY, routeName: getNewRouteName(routeName, nextRunNumber) },
    isLoadingNextRunNumber: state.routes.routeTracker.isLoadingNextRunNumber,
    isReseting: state.routes.routeTracker.isReseting,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'routeReset',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(RouteResetForm),
);
