import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { AWAITING_HAULER_APPROVAL, REJECT_CHANGES, YES, NO } from '../../constants/status';
import { AWARD, REQUEST_FOR_PRICING } from '../../constants/requestType';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DeclineOpportunityForm } from '../forms';
import { CHANGE_OF_BILLING } from 'src/opportunity/constants/requestType';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadOpportunity, updateHaulerOpportunity } from '../../ducks';
import { loadOpportunities } from '../../ducks/opportunities';
import { Modal } from '../../../core/components';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import translate from '../../../core/services/translate';
import { updateSourcingBid } from '../../ducks/opportunities';

import { useIsMobileView } from 'src/utils/hooks';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';

interface RouteParams {
  token: string;
}

interface ComponentProps {
  caseId: number;
  eventType: string;
  itemId?: number;
  requestType: TechnicalType;
  status: any;
  userId: string;
  vendorId: number;
  onCancel(pristine: boolean): void;
}

interface ReduxProps {
  response: any;
  isSaving: boolean;
  respondedNo?: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadOpportunity: DuckFunction<typeof loadOpportunity>;
  loadOpportunities: DuckFunction<typeof loadOpportunities>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
  updateSourcingBid: DuckFunction<typeof updateSourcingBid>;
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const DeclineOpportunityModal: React.FC<Props> = props => {
  const { eventType, isSaving, requestType, onCancel } = props;

  const isMobile = useIsMobileView();

  const onSubmit = React.useCallback(async () => {
    const {
      itemId,
      location: { search },
      match: {
        params: { token },
      },
      requestType,
      respondedNo,
      userId,
      onCancel,
      status,
      vendorId,
      loadDispatchOpportunitiesCount,
      loadOpportunity,
      loadOpportunities,
      updateHaulerOpportunity,
      updateSourcingBid,
      closeMobilePageDetailOverlay,
    } = props;
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES;

    const requestOpportunityObj: any = {};
    requestOpportunityObj.itemId = itemId;
    requestOpportunityObj.Response = status === AWAITING_HAULER_APPROVAL ? REJECT_CHANGES : NO;

    if (status === AWAITING_HAULER_APPROVAL) {
      requestOpportunityObj.requestedChanges = respondedNo;
    } else {
      requestOpportunityObj.RespondedNo = respondedNo;
    }

    const promise =
      requestType?.id === REQUEST_FOR_PRICING
        ? updateSourcingBid(requestOpportunityObj)
        : updateHaulerOpportunity(
            requestOpportunityObj,
            userId,
            token,
            vendorId,
            isActionRequired,
            startDate,
            endDate,
            requestType?.id === AWARD,
          );

    if (!promise) {
      return;
    }

    await promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        if (requestType?.id === AWARD || requestType?.id === REQUEST_FOR_PRICING)
          loadOpportunities(userId, vendorId, isActionRequired, startDate, endDate);
        else loadOpportunity(userId, token, vendorId, isActionRequired);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);

  const modalTitle =
    requestType?.id === REQUEST_FOR_PRICING
      ? translate('opportunity.declineBid')
      : eventType === CHANGE_OF_BILLING
      ? translate('opportunity.declineChangeOfBilling')
      : translate('opportunity.declineService');
  return (
    <Modal size="small" isLoading={isSaving}>
      <ModalTitle>{modalTitle}</ModalTitle>
      <ModalSubtitle />

      <Grid centered>
        <GridColumn size={isMobile ? '12/12' : '8/12'}>
          <DeclineOpportunityForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('declineOpportunity');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `opportunities.settings${ownProps.caseId}.response`),
  respondedNo: formSelector(state, `opportunities.settings${ownProps.caseId}.respondedNo`),
  isSaving: state.opportunity.opportunity.isSaving,
});

const mapDispatchToProps = {
  loadOpportunity,
  loadOpportunities,
  updateSourcingBid,
  closeMobilePageDetailOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineOpportunityModal);
