import React, { Fragment, PureComponent } from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { DuckFunction } from 'src/contracts/ducks';
import { deleteRouteNote, saveRouteNote } from 'src/routes/ducks';

import { DateTimeDisplay, InlineEditor, TextArea, TextDisplay, ActionButtonTooltip } from '../../../core/components';
import { Button, Container, TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import translate from '../../../core/services/translate';

const validateRouteNote = ({ notes }: any) => !!notes;

interface Props extends WrappedFieldArrayProps {
  change: any;
  deleteRouteNote: DuckFunction<typeof deleteRouteNote>;
  fieldArrayKey: string;
  index: number;
  isReadOnly: boolean;
  routeId: number;
  routeLocationId: number;
  saveRouteNote: DuckFunction<typeof saveRouteNote>;
}

interface State {
  routeNote: any;
  isInEditMode: boolean;
}

class RouteNotesFormTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { index, fields } = props;
    const routeNote = fields.get(index);

    this.state = { routeNote, isInEditMode: !routeNote.id };
  }

  startEditing = () => {
    this.setState({ isInEditMode: true });
  };

  cancelEditing = () => {
    const { index, fields, change } = this.props;
    const { routeNote } = this.state;

    const isExisting = !!fields.get(index).id;

    if (!isExisting) {
      fields.remove(index);
      return;
    }

    change(`routeNotes[${index}].notes`, routeNote.notes);
    this.setState({ isInEditMode: false });
  };

  completeEditing = async () => {
    const { routeId, routeLocationId, index, fields, saveRouteNote, change } = this.props;
    const routeNote = fields.get(index);

    if (!validateRouteNote(routeNote)) {
      createErrorNotification(translate('routes.alertMessages.routeNoteValidationFailed'));
      return;
    }

    const { id, date, sourceName, sourceType } = await saveRouteNote(routeId, {
      ...routeNote,
      routeLocationId,
    });

    if (!routeNote.id) {
      change(`routeNotes[${index}].id`, id);
      change(`routeNotes[${index}].date`, date);
      change(`routeNotes[${index}].sourceName`, sourceName);
      change(`routeNotes[${index}].sourceType`, sourceType);
    }

    this.setState({ routeNote, isInEditMode: false });
    createSuccessNotification(translate('routes.alertMessages.routeNoteSaved'));
  };

  deleteRouteNote = async () => {
    const { index, fields, deleteRouteNote } = this.props;
    const { id } = fields.get(index);

    if (!(await confirm(translate('routes.alertMessages.confirmDeleteRouteNote')))) {
      return;
    }

    await deleteRouteNote(id);
    fields.remove(index);
  };

  render() {
    const { fieldArrayKey, isReadOnly, routeLocationId } = this.props;
    const { isInEditMode } = this.state;

    return (
      <Fragment>
        <TableRow isHighlighted={isInEditMode}>
          <TableCell width="35%">
            <Container>
              <Field
                name={`${fieldArrayKey}.notes`}
                component={InlineEditor}
                isInEditMode={isInEditMode}
                inputComponent={TextArea}
                inputProps={{
                  placeholder: translate('common.note'),
                  margin: 'no',
                  id: `${fieldArrayKey}-notes`,
                }}
              />
            </Container>
          </TableCell>

          <TableCell width="20%" vertical justifyContent="center">
            <Field name={`${fieldArrayKey}.sourceName`} component={TextDisplay} block margin="no no xxSmall" />
            <Field name={`${fieldArrayKey}.sourceType`} component={TextDisplay} block size="small" />
          </TableCell>

          <TableCell vertical width="15%" justifyContent="center">
            <Field name={`${fieldArrayKey}.date`} component={DateTimeDisplay} />
          </TableCell>

          <TableCell width="30%" align="right">
            {!isInEditMode && !isReadOnly && !!routeLocationId && (
              <Fragment>
                <TableActionButton onClick={this.startEditing}>
                  <ActionButtonTooltip icon="edit" tooltip="edit" />
                </TableActionButton>

                <TableActionButton onClick={this.deleteRouteNote}>
                  <ActionButtonTooltip icon="delete" tooltip="delete" />
                </TableActionButton>
              </Fragment>
            )}

            {isInEditMode && (
              <Fragment>
                <Button
                  type="button"
                  size="small"
                  color="secondary"
                  margin="no xxSmall no no"
                  onClick={this.cancelEditing}
                  id={`${fieldArrayKey}-cancel`}
                >
                  {translate('common.cancel')}
                </Button>

                <Button
                  type="button"
                  size="small"
                  color="primary"
                  onClick={this.completeEditing}
                  id={`${fieldArrayKey}-save`}
                >
                  {translate('common.save')}
                </Button>
              </Fragment>
            )}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

export default RouteNotesFormTableRow;
