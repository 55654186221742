import React from 'react';
import { Source, Layer } from 'react-map-gl';

import {
  TRIGGER_MAP_CLUSTER_LAYER,
  TRIGGER_MAP_CLUSTER_SOURCE,
  TRIGGER_MAP_CLUSTER_COUNTERS_LAYER,
} from 'src/routes/constants/triggerMap';

type Props<P extends object = {}> = {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
};

export default React.memo(function TriggerMapClustersGLSource({ geoJSON }: Props) {
  return (
    <Source
      cluster
      type="geojson"
      id={TRIGGER_MAP_CLUSTER_SOURCE}
      data={geoJSON}
      tolerance={0.0001}
      clusterMaxZoom={11}
    >
      <Layer
        id={TRIGGER_MAP_CLUSTER_LAYER}
        type="circle"
        source={TRIGGER_MAP_CLUSTER_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'circle-color': '#009688',
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        }}
      />

      <Layer
        id={TRIGGER_MAP_CLUSTER_COUNTERS_LAYER}
        type="symbol"
        source={TRIGGER_MAP_CLUSTER_SOURCE}
        filter={['has', 'point_count']}
        paint={{
          'text-color': 'white',
        }}
        layout={{
          'text-field': '{point_count_abbreviated}',
          'text-size': 12,
          'text-allow-overlap': true,
        }}
      />
    </Source>
  );
});
