import { useMemo } from 'react';

import { ActionButtonTooltip } from 'src/core/components';
import { currency, date } from 'src/utils/services/formatter';
import { INVOICE_PAYMENT_STATUS_PROCESSING_ID } from 'src/finance/constants/invoicePaymentStatuses';
import { OpenBill, PaymentStatusOptions } from 'src/finance/interfaces/ApplyChecks';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';

export interface SelectedOpenBill {
  invoiceId: number;
  invoiceDate: string | Date;
  dueDate: string | Date;
  customerId: number; 
  customerName: string;
  locationName: string;
  invoiceTotal: number;
  unappliedBillBalance: number;
  availableBillBalance: number;
  paymentStatus: string;
}

interface Props extends OpenBill {
  handleAssignBillToPayment?: (invoiceId: number) => void;
  isEditable?: boolean;
  openBillAssignModal?: (selectedOpenBill: SelectedOpenBill) => void;
  openBillPdfViewerModal: (openBillId: number, openBillDocumentUrl: string) => void;
  billStatuses: PaymentStatusOptions[];
}

const OpenBillsTableRow = ({
  assignedPayments,
  availableBillBalance,
  billStatusId,
  customerId,
  customerLocationId,
  customerName,
  dueDate,
  handleAssignBillToPayment,
  invoiceDate,
  invoiceId,
  invoiceTotal,
  isEditable = true,
  locationName,
  openBillAssignModal,
  openBillPdfViewerModal,
  billStatuses,
  unappliedBillBalance,
}: Props) => {
  const billIsProcessing = billStatusId === INVOICE_PAYMENT_STATUS_PROCESSING_ID;
  const paymentNumber = assignedPayments[0]?.paymentNumber;

  const paymentStatus = useMemo(() => {
    return billStatuses?.find(item => item.value === billStatusId)?.label || '-';
  }, [billStatuses, billStatusId]);

  const selectedOpenBill = {
    invoiceId,
    invoiceDate,
    dueDate,
    customerId,
    customerName,
    locationName,
    invoiceTotal,
    unappliedBillBalance,
    availableBillBalance,
    paymentStatus,
  } as SelectedOpenBill;

  return (
    <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
      <TableCell
        id={`openBill-${invoiceId}-billId-cell`}
        justifyContent="center"
        vertical
        width="8%"
        padding="defaultCellVertical xSmall"
      >
        <Text>{invoiceId || '-'}</Text>
      </TableCell>
      <TableCell id={`openBill-${invoiceId}-billDate-cell`} width="10%" padding="defaultCellVertical xSmall">
        <Text>{invoiceDate ? date(invoiceDate) : '-'}</Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-dueDate-cell`}
        justifyContent="center"
        vertical
        width="10%"
        padding="defaultCellVertical xSmall"
      >
        <Text>{dueDate ? date(dueDate) : '-'}</Text>
      </TableCell>
      <TableCell
        id={`openBill-${invoiceId}-customerInfo-cell`}
        justifyContent="center"
        vertical
        width="21%"
        padding="defaultCellVertical xSmall"
      >
        <Text block>
          {customerName || '-'} • {customerId || '-'}
        </Text>
        <Text block color="grayDarker">
          {locationName || '-'} • {customerLocationId || '-'} •{' '}
        </Text>
      </TableCell>
      <TableCell id={`openBill-${invoiceId}-billAmount-cell`} width="12%" padding="defaultCellVertical xSmall">
        <Text>{currency(invoiceTotal || 0)}</Text>
      </TableCell>
      <TableCell id={`openBill-${invoiceId}-unappliedBillBalance-cell`} width="9%" padding="defaultCellVertical xSmall">
        <Text>{currency(unappliedBillBalance || 0)}</Text>
      </TableCell>
      <TableCell id={`openBill-${invoiceId}-availableBillBalance-cell`} width="8%" padding="defaultCellVertical xSmall">
        <Text>{currency(availableBillBalance || 0)}</Text>
      </TableCell>
      <TableCell id={`openBill-${invoiceId}-paymentStatus-cell`} width="14%" padding="defaultCellVertical xSmall">
        <Text>{paymentStatus || '-'}</Text>
      </TableCell>
      <TableCell width="8%" align="center">
        {isEditable && (
          <>
            {billIsProcessing ? (
              <TableActionButton disabled>
                <ActionButtonTooltip
                  icon="note"
                  iconSize="small"
                  tooltip="assignedTo"
                  tooltipProps={{
                    paymentNumber,
                  }}
                />
              </TableActionButton>
            ) : (
              <TableActionButton
                onClick={() =>
                  openBillAssignModal
                    ? openBillAssignModal(selectedOpenBill)
                    : handleAssignBillToPayment && handleAssignBillToPayment(invoiceId)
                }
                id={`openBill-${invoiceId}-assign-cell`}
              >
                <ActionButtonTooltip icon="reply" margin="no small no no" tooltip="assignToPayment" />
              </TableActionButton>
            )}
          </>
        )}

        {/* <TableActionButton
          onClick={
            () => {}
            openBillPdfViewerModal(invoiceId, openBill?.openBillDocumentUrl)
          }
          id={`openBill-${invoiceId}-viewBill-cell`}
        >
          <ActionButtonTooltip iconSize="small" icon="files" tooltip="viewBill" />
        </TableActionButton> */}
      </TableCell>
    </TableRow>
  );
};

export default OpenBillsTableRow;
