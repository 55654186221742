import { combineReducers } from 'redux';
import { reducer as activeVehiclesReducer } from './activeVehicles';
import { reducer as conditionTypesReducer } from './conditionTypes';
import { reducer as contactsReducer } from './contacts';
import { reducer as containerActivityReducer } from './containerActivity';
import { reducer as containerFacilitiesReducer } from './containerFacilities';
import { reducer as containerHistoryReducer } from './containerHistory';
import { reducer as containerLocationsForMapReducer } from './containerLocationsForMap';
import { reducer as containerReducer } from './container';
import { reducer as containersForMapLocationReducer } from './containersForMapLocation';
import { reducer as containersListReducer } from './containersList';
import { reducer as containersLocationsForCreateEditReducer } from './containersLocationsForCreateEdit';
import { reducer as containersReducer } from './containers';
import { reducer as containersStatisticsReducer } from './containersStatistics';
import { reducer as customerTypesReducer } from './customerTypes';
import { reducer as driverInspectionReducer } from './driverInspection';
import { reducer as driverReducer } from './driver';
import { reducer as driversReducer } from './drivers';
import { reducer as facilitiesReducer } from './facilities';
import { reducer as facilityReducer } from './facility';
import { reducer as facilitySubTypeReducer } from './facilitySubTypes';
import { reducer as facilityTypesReducer } from './facilityTypes';
import { reducer as locationAndCityAlertsImportsReducer } from './locationAndCityAlertsImports';
import { reducer as locationAndCityAlertsReducer } from './locationAndCityAlerts';
import { reducer as lowInventoryPartsReducer } from './lowInventoryParts';
import { reducer as notesReducer } from './notes';
import { reducer as openDispatchesReducer } from './openDispatches';
import { reducer as preventativeMaintenanceReducer } from './preventativeMaintenance';
import { reducer as rubiconDispatchesReducer } from './rubiconDispatches';
import { reducer as serviceConfirmationReducer } from './serviceConfirmation';
import { reducer as unassignedWeightTicketsReducer } from './unassignedWeightTickets';
import { reducer as usersForDriverReducer } from './usersForDriver';
import { reducer as vehicleFaultCodesReducer } from './vehicleFaultCodes';
import { reducer as vehicleFuelEfficiencyLogReducer } from './vehicleFuelEfficiencyLog';
import { reducer as vehicleInspectionsReducer } from './vehicleInspections';
import { reducer as vehicleIssuesReducer } from './vehicleIssues';
import { reducer as vehicleNotesReducer } from './vehicleNotes';
import { reducer as vehicleReducer } from './vehicle';
import { reducer as vehiclesReducer } from './vehicles';
import { reducer as vehicleTripInspectionDetailsReducer } from './vehicleTripInspectionDetails';
import { reducer as vehicleTypesForVendorReducer } from './vehicleTypesForVendor';
import { reducer as vehicleTypesReducer } from './vehicleTypes';
import { reducer as workOrderDocumentsReducer } from './workOrderDocuments';
import zDeviceConfigurationReducer from './zDeviceConfiguration';
import visionConfigurationReducer from './visionConfiguration';

export { loadFacilitySubType } from './facilitySubTypes';
export { loadCustomerTypesForContainers } from './customerTypes';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_DRIVERS,
  assignResources,
  deleteDriver,
  deleteResources,
  FILTER_DRIVERS,
  filterDrivers,
  loadDrivers,
  resetDrivers,
  SEARCH_DRIVERS,
  searchDrivers,
  SORT_DRIVERS,
} from './drivers';

export {
  checkSupervisorsHaveRoutesInRange,
  loadDriver,
  loadDriverFacilites,
  profileImageUrlSelector,
  resetDriver,
  saveDriver,
  uploadProfileImage,
  COMPLETE_LOAD as COMPLETE_LOAD_DRIVER,
  COMPLETE_SAVE as COMPLETE_SAVE_DRIVER,
} from './driver';

export { loadDriverInspectionLogs, loadDriverInspectionDetails } from './driverInspection';
export { loadWeatherConditionTypes, loadLightConditionTypes } from './conditionTypes';
export { loadActiveVehicles, resetActiveVehicles } from './activeVehicles';
export {
  assignVehicles,
  COMPLETE_LOAD as COMPLETE_LOAD_VEHICLES,
  deleteVehicle,
  deleteVehicles,
  FILTER_VEHICLES,
  filterVehicles,
  loadVehicles,
  resetVehicles,
  SEARCH_VEHICLES,
  searchVehicles,
  SORT_VEHICLES,
  sortVehicles,
  vehiclesByVehicleTypeIdSelector,
  vehiclesWithoutVehicleTypeIdSelector,
} from './vehicles';
export {
  checkVehiclesHaveRoutesInRange,
  loadVehicle,
  loadVehicleFacilites,
  resetVehicle,
  saveVehicle,
  loadAiModel,
  clearAiModel,
  aiModelTypesSelector,
  vendorAiModelTypesSelector,
  modelConfigurationSelector,
  vehicleIdSelector,
  selectCommands,
  saveVehicleModelConfig,
  loadBinColors,
  COMPLETE_LOAD as COMPLETE_LOAD_VEHICLE,
  COMPLETE_VEHICLE_SAVE,
} from './vehicle';
export {
  loadVehicleTypes,
  resetVehicleTypes,
  vehicleTypesByIdSelector,
  vehicleTypesForRoleTypeSelector,
  vehicleSubTypesSelector,
} from './vehicleTypes';
export {
  loadVehicleTypesForVendor,
  saveVehicleTypesForVendor,
  resetVehicleTypesForVendor,
  vehicleTypesForVendorWithRoleTypeSelector,
  technicalNameByVehicleTypeIdSelector,
  vehicleIdByTechnicalNameSelector,
  vehicleIdsByTechnicalNamesSelector,
} from './vehicleTypesForVendor';
export {
  deleteContainer,
  exportContainers,
  resetContainers,
  deleteContainersBatched,
  transferContainersBatched,
  containersSelector,
  containersCountSelector,
  containersByLocationSelector,
  COMPLETE_DELETE as COMPLETE_DELETE_CONTAINER,
  COMPLETE_EXPORT as COMPLETE_EXPORT_CONTAINER,
  COMPLETE_LOAD as COMPLETE_LOAD_CONTAINERS,
  VIEW_CONTAINER_ON_MAP,
  viewContainerOnMap,
} from './containers';
export {
  saveContainer,
  loadContainerNew,
  resetContainer,
  COMPLETE_LOAD as COMPLETE_LOAD_CONTAINER,
  COMPLETE_SAVE as COMPLETE_SAVE_CONTAINER,
} from './container';
export {
  loadContainerHistory,
  resetContainerHistory,
  COMPLETE_LOAD as COMPLETE_LOAD_CONTAINER_HISTORY,
} from './containerHistory';
export {
  authenticateToken,
  loadOpenDispatches,
  updateOpenDispatches,
  getServiceContractByCode,
  createUnassignedJob,
  saveServiceContract,
  setNewServiceContractId,
} from './openDispatches';
export { loadRubiconDispatches, resetRubiconDispatches } from './rubiconDispatches';
export { loadDocuments } from './workOrderDocuments';
export {
  loadServiceConfirmation,
  updateServiceConfirmation,
  uploadDocumentImage,
  downloadDocument,
} from './serviceConfirmation';
export { getNotes, addNotes, showModal as showNotesModal, hideModal as hideNotesModal } from './notes';
export {
  deleteFacility,
  loadDisposalFacilities,
  loadFacilities,
  loadOperationalFacilities,
  loadContainersForReassignment,
  resetContainersForReassignment,
} from './facilities';
export { loadFacilityTypes, loadFacilityRateTypes } from './facilityTypes';
export {
  loadFacility,
  resetFacility,
  facilityDetailsSelector,
  vehicleTypesForVendorSelector,
  saveFacility,
} from './facility';

export {
  loadDeviceDefaultVehicle,
  clearDeviceConfig,
  saveVehicleDeviceConfig,
  deviceServicesSelector,
  selectZDeviceServices,
} from './zDeviceConfiguration';

export {
  clearVisionConfiguration,
  loadVisionConfigurationForVehicle,
  loadVisionConfigurationWithVehicleTypeId,
  saveVisionConfigurationForVehicle,
  saveVisionConfigurationWithVehicleTypeId,
  visionConfigurationSelector,
} from './visionConfiguration';

export { loadVehicleTripInspectionDetails } from './vehicleTripInspectionDetails';
export { loadUnassignedWeightTickets, deleteWeightTicket, uploadDocument } from './unassignedWeightTickets';

export { loadContacts, saveContact, updateContact, deleteContact } from './contacts';

export { loadVehicleFuelEfficiencyLog, resetVehicleFuelEfficiencyLog } from './vehicleFuelEfficiencyLog';

export { loadUsersForDriver, resetUsersForDriver } from './usersForDriver';

export { loadContainersStatistics, resetContainersStatistics } from './containersStatistics';
export { loadContainersList, resetContainersList } from './containersList';
export { loadContainerFacilities, resetContainerFacilities } from './containerFacilities';
export { loadContainerLocationsForMap, resetContainerLocationsForMap } from './containerLocationsForMap';
export {
  loadContainersLocationsForCreateEdit,
  resetContainersLocationsForCreateEdit,
} from './containersLocationsForCreateEdit';
export { loadContainersForMapLocation, resetContainersForMapLocation } from './containersForMapLocation';
export { loadContainerActivity, resetContainerActivity } from './containerActivity';
export { loadVehicleNotes, saveVehicleNote, deleteVehicleNote, resetVehicleNotes } from './vehicleNotes';

export {
  deleteLowInventoryPart,
  editLowInventoryPart,
  loadLowInventoryPartHistory,
  loadLowInventoryParts,
  saveLowInventoryPart,
} from './lowInventoryParts';

export {
  loadLocationAndCityAlertsForMap,
  loadLocationAndCityAlertsForList,
  saveLocationAndCityAlert,
  loadLocationAndCityAlert,
  setLocationAndCityAlertsMapViewport,
} from './locationAndCityAlerts';

export const reducer = combineReducers({
  activeVehicles: activeVehiclesReducer,
  conditionTypes: conditionTypesReducer,
  contacts: contactsReducer,
  container: containerReducer,
  containerActivity: containerActivityReducer,
  containerFacilities: containerFacilitiesReducer,
  containerHistory: containerHistoryReducer,
  containerLocationsForMap: containerLocationsForMapReducer,
  containers: containersReducer,
  containersForMapLocation: containersForMapLocationReducer,
  containersList: containersListReducer,
  containersLocationsForCreateEdit: containersLocationsForCreateEditReducer,
  containersStatistics: containersStatisticsReducer,
  customerTypes: customerTypesReducer,
  driver: driverReducer,
  driverInspection: driverInspectionReducer,
  drivers: driversReducer,
  facilities: facilitiesReducer,
  facility: facilityReducer,
  facilitySubTypes: facilitySubTypeReducer,
  facilityTypes: facilityTypesReducer,
  locationAndCityAlerts: locationAndCityAlertsReducer,
  locationAndCityAlertsImports: locationAndCityAlertsImportsReducer,
  lowInventoryParts: lowInventoryPartsReducer,
  notes: notesReducer,
  openDispatches: openDispatchesReducer,
  preventativeMaintenance: preventativeMaintenanceReducer,
  rubiconDispatches: rubiconDispatchesReducer,
  serviceConfirmation: serviceConfirmationReducer,
  unassignedWeightTickets: unassignedWeightTicketsReducer,
  usersForDriver: usersForDriverReducer,
  vehicle: vehicleReducer,
  vehicleFaultCodes: vehicleFaultCodesReducer,
  vehicleFuelEfficiencyLog: vehicleFuelEfficiencyLogReducer,
  vehicleInspections: vehicleInspectionsReducer,
  vehicleIssues: vehicleIssuesReducer,
  vehicleNotes: vehicleNotesReducer,
  vehicles: vehiclesReducer,
  vehicleTripInspectionDetails: vehicleTripInspectionDetailsReducer,
  vehicleTypes: vehicleTypesReducer,
  vehicleTypesForVendor: vehicleTypesForVendorReducer,
  visionConfiguration: visionConfigurationReducer,
  workOrderDocuments: workOrderDocumentsReducer,
  zDeviceConfiguration: zDeviceConfigurationReducer,
});
