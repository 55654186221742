import moment from 'moment';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorBoundary } from 'src/common/components';
import { Modal } from 'src/core/components';
import { ModalHeader, ModalSection, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import {
  clearRouteMapSelectedFeature,
  finishTravelPathBuildOrEdit,
  resetTravelPathBuildEdit,
  resetTravelPathDetailsForBuildOrEdit,
} from 'src/routes/ducks';
import useTravelPathLockHandler from 'src/routes/hooks/useTravelPathLockHandler';
import { dateFormat } from 'src/utils/services/validator';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { TravelPathBuilderFiltersForm } from '../../forms';
import { TravelPathModalTitleWrapper } from '../../styled';
import TravelPathBuilderMapGL from './travelPathBuilderSections/TravelPathBuilderMapGL';

interface Props {
  closeModal: (shouldRefreshDisplayedTP: boolean) => void;
  date?: string;
  isAlternativeFleet?: boolean;
  isSnowPlow?: boolean;
  name: string;
  routeTemplateId?: number;
}

const TravelPathBuilderModal: FC<Props> = ({
  closeModal,
  routeTemplateId,
  isAlternativeFleet,
  name,
  date,
  isSnowPlow,
}) => {
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  useTravelPathLockHandler({
    closeModal,
    isBuilder: true,
    routeId: undefined,
    routeTemplateId,
  });

  //cleanup
  useEffect(() => {
    return () => {
      dispatch(resetTravelPathBuildEdit());
      dispatch(clearRouteMapSelectedFeature(true));
      dispatch(resetTravelPathDetailsForBuildOrEdit());
    };
  }, [dispatch]);

  const handleCloseModal = async () => {
    if (await confirm(translate('routes.travelPath.alertMessages.confirmCLoseBuilderModal')))
      finishTravelPathBuildOrEdit(
        vendorId,
        true,
        undefined,
        routeTemplateId,
      )(dispatch).then(res => {
        if (!res.error?.code) {
          closeModal(false);
        }
      });
  };

  return (
    <Modal
      headerPadding="small no small"
      flexDirection="column"
      onClose={handleCloseModal}
      size="xLarge"
      padding="no"
      verticalSize="mediumLarge"
    >
      <ModalHeader padding="no no small">
        <TravelPathModalTitleWrapper>
          <Text color="black" size="xLarge">
            {translate('routes.travelPath.travelPathBuilderTitle')}
          </Text>
        </TravelPathModalTitleWrapper>
        <Box display="block">
          <Text block align="center" size="xLarge">
            {name}
          </Text>
          {date && (
            <Text block align="center" margin="xSmall no no">
              {moment(date).format(dateFormat)}
            </Text>
          )}
        </Box>
      </ModalHeader>
      <ModalSection noOutline overflow="hidden">
        <TravelPathBuilderFiltersForm routeTemplateId={routeTemplateId} />
      </ModalSection>
      <ModalSection noOutline overflow="hidden">
        <ErrorBoundary>
          <TravelPathBuilderMapGL
            isAlternativeFleet={isAlternativeFleet}
            routeTemplateId={routeTemplateId}
            isSnowPlow={isSnowPlow}
            closeModal={closeModal}
          />
        </ErrorBoundary>
      </ModalSection>
    </Modal>
  );
};

export default TravelPathBuilderModal;
