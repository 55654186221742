import { AnyAction } from 'redux';
import { findIndex } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadServiceZones as doLoadServiceZones,
  createServiceZone,
  updateServiceZone,
  deleteServiceZone as doDeleteServiceZone,
} from '../services/serviceZones';

import { transformLoadServiceZone } from '../services/transformLoadServiceZones';
import { ServiceZone } from '../interfaces/ServiceZones';

// Actions
const START_LOAD = 'routes/serviceZones/START_LOAD';
export const COMPLETE_LOAD = 'routes/serviceZones/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/serviceZones/FAIL_LOAD';
const START_SAVE = 'customers/serviceZones/START_SAVE';
export const COMPLETE_SAVE = 'customers/serviceZones/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/serviceZones/FAIL_SAVE';
const START_DELETE = 'customers/serviceZones/START_DELETE';
export const COMPLETE_DELETE = 'customers/serviceZones/COMPLETE_DELETE';
const FAIL_DELETE = 'customers/serviceZones/FAIL_DELETE';
const RESET = 'routes/serviceZones/RESET';

interface State {
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  serviceZones?: any;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isDeleting: false,
  isLoading: false,
  isSaving: false,
  serviceZones: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceZones: action.serviceZones,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceZones: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE: {
      const index = findIndex(state.serviceZones, { id: action.serviceZone.id });
      return index >= 0
        ? update(state, {
            serviceZones: { [index]: { $set: action.serviceZone } } as any,
            $merge: {
              isSaving: false,
            },
          })
        : update(state, {
            serviceZones: { $push: [action.serviceZone] } as any,
            $merge: {
              isSaving: false,
            },
          });
    }

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const index = findIndex(state.serviceZones, { id: action.serviceZoneId });
      return update(state, {
        serviceZones: { $splice: [[index, 1]] } as any,
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceZones: any) => ({
  type: COMPLETE_LOAD,
  serviceZones,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (serviceZone: ServiceZone, saveType: string) => ({
  type: COMPLETE_SAVE,
  serviceZone,
  saveType,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (serviceZoneId: number) => ({
  type: COMPLETE_DELETE,
  serviceZoneId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadServiceZones =
  (vendorId: number, allRecords?: boolean, includeCounts?: boolean) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadServiceZonesPromise = doLoadServiceZones(vendorId, allRecords, includeCounts);
    loadServiceZonesPromise
      .then(serviceZones => dispatch(completeLoad(serviceZones)))
      .catch(() => dispatch(failLoad()));
    return loadServiceZonesPromise;
  };

export const saveServiceZone = (vendorId: number, serviceZone: ServiceZone) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveServiceZonePromise = serviceZone.id
    ? updateServiceZone(serviceZone)
    : createServiceZone(vendorId, serviceZone);
  const saveType = serviceZone.id ? 'Update' : 'Create';
  saveServiceZonePromise
    .then(res => dispatch(completeSave(transformLoadServiceZone(res.data), saveType)))
    .catch(() => dispatch(failSave()));
  return saveServiceZonePromise;
};

export const deleteServiceZone = (serviceZoneId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteServiceZonePromise = doDeleteServiceZone(serviceZoneId);
  deleteServiceZonePromise.then(() => dispatch(completeDelete(serviceZoneId))).catch(() => dispatch(failDelete()));
  return deleteServiceZonePromise;
};

export const resetServiceZones = () => ({
  type: RESET,
});
