import styled, { css } from 'styled-components';
import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';

interface VehicleSectionCollapseButtonProps {
  collapsed?: boolean;
}

export const VehicleSectionCollapseButton = styled.button<VehicleSectionCollapseButtonProps>`
  background: none;
  outline: none;
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${colorMapperWithoutProps('grayDarker')};

  & > svg {
    width: 20px;
    height: 20px;
    transition: transform 0.2s;
  }

  &:not(:disabled) {
    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }

    ${props =>
      props.collapsed &&
      css`
        & > svg {
          transform: rotate(180deg);
        }
      `}
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.03);
  }
`;

export const VehicleSectionHeader = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

export const VehicleSectionHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
`;

export const VehicleSectionHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VehicleSectionHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 2;
`;

interface VehicleSectionsHeaderFiltersProps {
  hidden?: boolean;
}

export const VehicleSectionsHeaderFilters = styled.div<VehicleSectionsHeaderFiltersProps>`
  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}
`;

export const VehicleFuelEfficiencyLogHeaderContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 16px;
`;

export const VehicleFillUpDetailsMapWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 400px;
`;

export const VehicleFuelEfficiencyLogHeaderItemWrapper = styled.div``;
