import styled from 'styled-components';

export const Legend = `
  width: 38px;
  height: 6px;
  position: relative;
  top: 4px;
`;

export const LegendGood = styled.div`
  ${Legend};
  background-color: #008000;
`;

export const LegendModerate = styled.div`
  ${Legend};
  background-color: #fff200;
`;

export const LegendSevere = styled.div`
  ${Legend};
  background-color: #ff0000;
`;

export const LegendNotSweeping = styled.div`
  ${Legend};
  background-color: #0000ff;
`;

export const LegendUnderReview = styled.div`
  ${Legend};
  background-color: #fff200;
`;
