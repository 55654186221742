import React, { useEffect, useRef, useState } from 'react';

import { throttle } from 'lodash-es';
import { Button, ButtonSet, Grid, GridColumn, Message, PanelSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import {
  PdfDocument,
  PdfFilename,
  PdfHeader,
  PdfLoader,
  PdfPage,
  PdfPageIndicator,
  PdfWrapper,
  ProgressBar,
  ProgressContainer,
} from 'src/common/components/styled';

interface Props {
  openBillDocumentUrl: string | null;
  onDownload(...args: any[]): void;
}

const OpenBillsPdfViewer = (props: Props) => {
  const pdfWrapperRef = useRef<HTMLDivElement>(null);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [scrolled, setScrolled] = useState<number>(0);

  const { openBillDocumentUrl, onDownload } = props;

  useEffect(() => {
    const wrapper = pdfWrapperRef.current;

    const scrollProgress = throttle(() => {
      if (wrapper) {
        const scrollPx = wrapper.scrollTop;
        const winHeightPx = wrapper.scrollHeight - wrapper.clientHeight;
        const progress = Math.ceil((scrollPx / winHeightPx) * 100);
        const currentPage = Math.ceil((1 * progress) / 100);

        setScrolled(progress);
        setPageNumber(currentPage);
      }
    }, 500);

    if (numPages > 0 && wrapper) {
      wrapper.addEventListener('scroll', scrollProgress);
    }

    return () => (wrapper ? wrapper.removeEventListener('scroll', scrollProgress) : undefined);
  }, [numPages]);

  const onDocumentLoadSuccess = (pdf: any) => {
    setPageNumber(1);
    setNumPages(pdf.numPages);
  };

  return (
    <PanelSection padding="small xSmall">
      {openBillDocumentUrl ? (
        <Grid multiLine>
          <GridColumn size="12/12">
            <ProgressContainer>
              <ProgressBar width={`${scrolled}%`} />
            </ProgressContainer>
            <PdfHeader>
              <PdfFilename>{openBillDocumentUrl.replace(/^.*[\\/]/, '')}</PdfFilename>
              <PdfPageIndicator>{`${translate('common.page')}: ${pageNumber}/${numPages}`}</PdfPageIndicator>
            </PdfHeader>
            <PdfWrapper ref={pdfWrapperRef}>
              <PdfDocument file={openBillDocumentUrl} onLoadSuccess={onDocumentLoadSuccess} loading={<PdfLoader />}>
                {Array.from(new Array(numPages), (el, index) => (
                  <PdfPage key={`page_${index + 1}`} loading=" " pageNumber={index + 1} />
                ))}
              </PdfDocument>
            </PdfWrapper>
          </GridColumn>

          <GridColumn size="12/12">
            <ButtonSet align="center">
              <Button type="button" color="primary" onClick={onDownload}>
                {translate('common.export')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      ) : (
        <Message padding="sMedium">{translate('finance.applyChecks.noDocument')}</Message>
      )}
    </PanelSection>
  );
};

export default OpenBillsPdfViewer;
