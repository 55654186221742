import humps from 'humps';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';

import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { loadVehicleInformation } from 'src/dashboard/ducks';
import { clearDashboardModalMapSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import VehiclePositionsGLPopup from './VehiclePositionsGLPopup';

const VehiclePositionsGLPopupResolver = () => {
  const dispatch = useDispatch();

  const vehiclePositions = useSelector(state => (state.dashboard.vehiclesData.vehiclesList || []) as VehiclePosition[]);
  const routeVehiclePositions = useSelector(state => state.dashboard.routesData.routeVehiclePositions);

  const vehicleInformation = useSelector(state => state.dashboard.vehiclesData.vehicleInformation);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedModalMapFeature);
  const formValues = useSelector(getFormValues(DASHBOARD_FILTER_FORM_MAPBOX)) as any;

  const { isSourceRoute: isRouteVehicle } = useSelector(state => state.dashboard.mapControls.mapModalData);

  const vehiclePosition = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'vehiclePositions') {
      return undefined;
    }
    const vehiclePosition = vehiclePositions.find(position => position.id === selectedFeature?.id);
    const routeVehiclePosition = routeVehiclePositions.find(position => position.vehicleId === selectedFeature?.id);

    if (isRouteVehicle && routeVehiclePosition) {
      return {
        ...routeVehiclePosition,
        name: routeVehiclePosition.vehicleName,
        type: VEHICLE_TYPE_IDS[routeVehiclePosition.vehicleTypeId]?.technicalName,
        id: routeVehiclePosition.vehicleId,
      };
    }
    return vehiclePosition;
  }, [isRouteVehicle, routeVehiclePositions, selectedFeature, vehiclePositions]);

  if (!vehiclePosition || !selectedFeature) {
    return null;
  }

  const loadDependencies = () => {
    const promise = loadVehicleInformation(vehiclePosition.id, formValues.date)(dispatch);

    return Promise.all([promise]);
  };

  const vehicleType = humps.camelize(vehiclePosition.type);

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedFeature.coordinates?.lat || vehiclePosition.latitude}
      longitude={selectedFeature.coordinates?.lng || vehiclePosition.longitude}
      onClose={() => dispatch(clearDashboardModalMapSelectedFeature())}
    >
      <TypedResolver
        key={selectedFeature.id}
        successComponent={VehiclePositionsGLPopup}
        successProps={{
          vehicleDateTime: vehicleInformation.reportDateTime,
          vehicleDriverName: vehicleInformation.driverName,
          vehicleId: selectedFeature!.id,
          vehicleMilesDriven: vehicleInformation.totalMilesDriven,
          vehicleName: vehiclePosition.name,
          vehicleOdometer: vehicleInformation.lastReportedSpeed,
          vehicleStatus: vehicleInformation.isActive,
          vehicleType,
        }}
        loadingComponent={MapGLPopupLoading}
        resolve={loadDependencies}
      />
    </MapGLPopupWrapper>
  );
};

export default VehiclePositionsGLPopupResolver;
