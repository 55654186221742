import { duration } from 'src/utils/services/formatter';
import { RouteDetail, RouteDetailLabel, RouteDetailsWrapper, RouteDetailValue } from '../../styled/RouteDetails';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { Table } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteAssistsTableRow from './RouteAssistsTableRow';
import translate from 'src/core/services/translate';

export const routeAssistsTableCellWidths = ['25%', '25%', '25%', '25%'];

const getRouteAssistsTableCells = (isSnowPlowOrStreetSweeperRoute: boolean) => [
  {
    name: 'vehicleName',
    label: translate('common.vehicle'),
    width: routeAssistsTableCellWidths[0],
    sortable: false,
  },
  {
    name: 'driverName',
    label: translate('common.driver'),
    width: routeAssistsTableCellWidths[1],
    sortable: false,
  },
  {
    name: 'assistedStops',
    label: translate(isSnowPlowOrStreetSweeperRoute ? 'routes.assistedStreetSegments' : 'routes.assistedStops'),
    width: routeAssistsTableCellWidths[2],
    sortable: false,
  },
  {
    name: 'assistedTime',
    label: translate('routeAssist.assistTime'),
    width: routeAssistsTableCellWidths[3],
    sortable: false,
  },
];

export default function RouteAssistSection() {
  const { routeAssist } = useSelector(state => state.routes.routeAssist);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  if (!routeSummary || !routeAssist) return null;

  const isSnowPlowRoute = routeSummary.vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = routeSummary.vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  return (
    <>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>
            {translate(isSnowOrSweeperRoute ? 'routes.totalAssistedStreetSegments' : 'routes.totalAssistedStops')}
          </RouteDetailLabel>
          <RouteDetailValue>
            {isSnowOrSweeperRoute ? routeAssist.totalAssistedSegments : routeAssist.totalAssistedStops}
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('routeAssist.totalAssistTime')}</RouteDetailLabel>
          <RouteDetailValue>
            {duration(routeAssist.totalAssistTime, isSnowOrSweeperRoute ? 'seconds' : 'minutes', 'hh:mm:ss')}
          </RouteDetailValue>
        </RouteDetail>
      </RouteDetailsWrapper>

      {routeAssist.assists.length ? (
        <Table
          cells={getRouteAssistsTableCells(isSnowOrSweeperRoute)}
          rows={routeAssist.assists}
          rowComponent={RouteAssistsTableRow}
          rowProps={{ isSnowOrSweeperRoute }}
        />
      ) : (
        <Text block align="center">
          {translate('routes.noRouteAssist')}
        </Text>
      )}
    </>
  );
}
