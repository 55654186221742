import { Children, cloneElement } from 'react';

const CalendarDateCellWrapper = ({ children, value }: any) => {
  return cloneElement(Children.only(children), {
    style: {
      ...children.style,
      backgroundColor: 'white',
    },
  });
};

export default CalendarDateCellWrapper;
