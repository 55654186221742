import { http } from '../../core/services/http';

const loadRoutesForReassignment = (
  vendorId: number,
  sourceRouteTemplateId: number,
  vehicleTypeId: number,
  wasteMaterialTypeId?: number,
  scheduledDay?: Date | string,
) =>
  http
    .get('routeTemplates/forReassignment', {
      params: { vendorId, sourceRouteTemplateId, vehicleTypeId, wasteMaterialTypeId, scheduledDay },
    })
    .then(response => response.data);

export default loadRoutesForReassignment;
