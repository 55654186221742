import React from 'react';
import { connect } from 'react-redux';

import { Resolver } from '../../../../core/components';
import { PageLoadingOverlay } from '../../../../common/components/styled';
import RouteRecommendationsPage from './RouteRecommendationsPage';
import { loadRouteRecommendations } from '../../../ducks/routeRecommendations';
import { currentVendorIdSelector } from '../../../../vendors/services/currentVendorSelector';
import { AppState } from '../../../../store';
import { DuckFunction } from '../../../../contracts/ducks';

interface Props {
  onClose: () => void;
  vendorId: number;
  loadRouteRecommendations: DuckFunction<typeof loadRouteRecommendations>;
}

const RouteRecommendationsPageResolver: React.SFC<Props> = ({ loadRouteRecommendations, vendorId, onClose }) => (
  <Resolver
    resolve={() => loadRouteRecommendations(vendorId)}
    loadingComponent={PageLoadingOverlay}
    successComponent={RouteRecommendationsPage}
    successProps={{ onClose }}
  />
);

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

export default connect(mapStateToProps, { loadRouteRecommendations })(RouteRecommendationsPageResolver);
