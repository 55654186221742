import { AnyAction } from 'redux';
import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import {
  loadUnassignedWeightTickets as doLoadUnassignedWeightTickets,
  loadUnassignedWeightTicketsByToken as doLoadUnassignedWeightTicketsByToken,
  uploadDocument as doUploadDocument,
  uploadDocumentByToken as doUploadDocumentByToken,
  deleteWeightTicket as doDeleteWeightTicket,
  deleteWeightTicketByToken as doDeleteWeightTicketByToken,
} from '../services/unassignedWeightTickets';

// Actions
const START_LOAD = 'fleet/unassignedWeightTicets/START_LOAD';
const COMPLETE_LOAD = 'fleet/unassignedWeightTicets/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/unassignedWeightTicets/FAIL_LOAD';
const START_DELETE = 'fleet/unassignedWeightTicets/START_DELETE';
const COMPLETE_DELETE = 'fleet/unassignedWeightTicets/COMPLETE_DELETE';
const FAIL_DELETE = 'fleet/unassignedWeightTicets/FAIL_DELETE';
const START_UPLOAD = 'fleet/unassignedWeightTicets/START_UPLOAD';
const COMPLETE_UPLOAD = 'fleet/unassignedWeightTicets/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'fleet/unassignedWeightTicets/FAIL_UPLOAD';
const RESET = 'fleet/unassignedWeightTicets/RESET';

interface UnassignedWeightTicketsState {
  isLoading: boolean;
  isDeleting: boolean;
  isUploading: boolean;
  unassignedWeightTickets: any[];
  uploadedFileDetail: any;
}

const initialState: UnassignedWeightTicketsState = {
  isLoading: false,
  isDeleting: false,
  isUploading: false,
  unassignedWeightTickets: [],
  uploadedFileDetail: {},
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedWeightTickets: action.unassignedWeightTickets,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedWeightTickets: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      const fileIndex = findIndex(state.unassignedWeightTickets, { id: action.fileId });
      return update(state, {
        unassignedWeightTickets: { $splice: [[fileIndex, 1]] },
        $merge: { isDeleting: false },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: true,
        },
      });

    case COMPLETE_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
          uploadedFileDetail: action.file, //action.unassignedWeightTickets,
        },
      });

    case FAIL_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedWeightTickets: undefined,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (unassignedWeightTickets: any[]) => {
  return {
    type: COMPLETE_LOAD,
    unassignedWeightTickets,
  };
};

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (fileId: Number) => {
  return {
    type: COMPLETE_DELETE,
    fileId,
  };
};

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startUpload = () => ({
  type: START_UPLOAD,
});

const completeUpload = (file: any) => {
  return {
    type: COMPLETE_UPLOAD,
    file,
  };
};

const failUpload = () => ({
  type: FAIL_UPLOAD,
});

export const loadUnassignedWeightTickets = (
  token: string,
  vendorId: number,
  startDate: string,
  endDate: string,
  searchTerm: string,
  page: number,
) => (dispatch: ThunkDispatch<UnassignedWeightTicketsState, any, AnyAction>) => {
  dispatch(startLoad());
  if (token) {
    const loadUnassignedWeightTicketsPromise = doLoadUnassignedWeightTicketsByToken(
      token,
      startDate,
      endDate,
      searchTerm,
      page,
    );
    loadUnassignedWeightTicketsPromise
      .then(unassignedWeightTickets => dispatch(completeLoad(unassignedWeightTickets)))
      .catch(() => dispatch(failLoad()));

    return loadUnassignedWeightTicketsPromise;
  } else {
    const loadUnassignedWeightTicketsPromise = doLoadUnassignedWeightTickets(
      vendorId,
      startDate,
      endDate,
      searchTerm,
      page,
    );
    loadUnassignedWeightTicketsPromise
      .then(unassignedWeightTickets => dispatch(completeLoad(unassignedWeightTickets)))
      .catch(() => dispatch(failLoad()));

    return loadUnassignedWeightTicketsPromise;
  }
};

export const deleteWeightTicket = (token: string, fileId: number, vendorId: number) => (
  dispatch: ThunkDispatch<UnassignedWeightTicketsState, any, AnyAction>,
) => {
  if (token) {
    dispatch(startDelete());
    const deleteWeightTicketPromise = doDeleteWeightTicketByToken(fileId, token);
    deleteWeightTicketPromise.then(() => dispatch(completeDelete(fileId))).catch(() => dispatch(failDelete()));
    return deleteWeightTicketPromise;
  } else {
    dispatch(startDelete());
    const deleteWeightTicketPromise = doDeleteWeightTicket(fileId, vendorId);
    deleteWeightTicketPromise.then(() => dispatch(completeDelete(fileId))).catch(() => dispatch(failDelete()));
    return deleteWeightTicketPromise;
  }
};

export const uploadDocument = (token: string, file: any, vendorId: number) => (
  dispatch: ThunkDispatch<UnassignedWeightTicketsState, any, AnyAction>,
) => {
  dispatch(startUpload());
  if (token) {
    const uploadDocumentPromise = doUploadDocumentByToken(file, token);
    uploadDocumentPromise
      .then(response => {
        dispatch(completeUpload(response));
      })
      .catch(() => dispatch(failUpload()));
    return uploadDocumentPromise;
  } else {
    const uploadDocumentPromise = doUploadDocument(file, vendorId);
    uploadDocumentPromise
      .then(response => {
        dispatch(completeUpload(response));
      })
      .catch(() => dispatch(failUpload()));
    return uploadDocumentPromise;
  }
};

export const resetVehicleTripInspectionsDetails = () => ({
  type: RESET,
});
