import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadStrobeImages as doLoadStrobeImages,
  loadNextStrobeImages as doLoadNextStrobeImages,
  loadPrevStrobeImages as doLoadPrevStrobeImages,
  loadClosestStrobeImage as doLoadclosestStrobeImage,
} from '../services/strobeImages';

// Actions
const START_LOAD = 'routes/strobeImages/START_LOAD';
export const COMPLETE_LOAD = 'routes/strobeImages/COMPLETE_LOAD';
export const COMPLETE_LOAD_IMAGES = 'routes/nextStrobeImages/COMPLETE_LOAD_IMAGES';
export const COMPLETE_LOAD_STROBE_IMAGE = 'routes/closestStrobeImages/COMPLETE_LOAD_STROBE_IMAGE';
const FAIL_LOAD = 'routes/strobeImages/FAIL_LOAD';
const RESET = 'routes/strobeImages/RESET';

interface State {
  closestStrobeImage?: any;
  isLoading: boolean;
  strobeImages: any[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  closestStrobeImage: undefined,
  isLoading: false,
  strobeImages: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          strobeImages: action.strobeImages,
        },
      });

    case COMPLETE_LOAD_IMAGES:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case COMPLETE_LOAD_STROBE_IMAGE:
      return update(state, {
        $merge: {
          isLoading: false,
          closestStrobeImage: action.strobeImage,
        },
      });
    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (strobeImages: any[]) => ({
  type: COMPLETE_LOAD,
  strobeImages,
});

const completeLoadImages = () => ({
  type: COMPLETE_LOAD_IMAGES,
});

const completeLoadStrobeImage = (strobeImage: any) => ({
  type: COMPLETE_LOAD_STROBE_IMAGE,
  strobeImage,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadStrobeImages =
  (vendorId: number, vehicleId: number, timeStamp?: Date | string, cameraSerialNumber?: string) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadStrobeImagesPromise = doLoadStrobeImages(vendorId, vehicleId, timeStamp, cameraSerialNumber);
    loadStrobeImagesPromise
      .then(strobeImages => dispatch(completeLoad(strobeImages)))
      .catch(() => dispatch(failLoad()));
    return loadStrobeImagesPromise;
  };

export const loadNextStrobeImages =
  (vendorId: number, vehicleId: number, strobeImageId: number, cameraSerialNumber: string, numberOfImages: number) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadNextStrobeImagesPromise = doLoadNextStrobeImages(
      vendorId,
      vehicleId,
      strobeImageId,
      cameraSerialNumber,
      numberOfImages,
    );
    loadNextStrobeImagesPromise.then(() => dispatch(completeLoadImages())).catch(() => dispatch(failLoad()));
    return loadNextStrobeImagesPromise;
  };

export const loadPrevStrobeImages =
  (vendorId: number, vehicleId: number, strobeImageId: number, cameraSerialNumber: string, numberOfImages: number) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadNextStrobeImagesPromise = doLoadPrevStrobeImages(
      vendorId,
      vehicleId,
      strobeImageId,
      cameraSerialNumber,
      numberOfImages,
    );
    loadNextStrobeImagesPromise.then(() => dispatch(completeLoadImages())).catch(() => dispatch(failLoad()));
    return loadNextStrobeImagesPromise;
  };

export const loadClosestStrobeImage =
  (vendorId: number, vehicleId: number, timeStamp?: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadStrobeImagePromise = doLoadclosestStrobeImage(vendorId, vehicleId, timeStamp);
    loadStrobeImagePromise
      .then(strobeImage => dispatch(completeLoadStrobeImage(strobeImage)))
      .catch(() => dispatch(failLoad()));
    return loadStrobeImagePromise;
  };

export const resetStrobeImages = () => ({
  type: RESET,
});
