import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadFuelingTicketsSettings as doLoadFuelingTicketsSettings,
  saveFuelingTicketsSettings as doSaveFuelingTicketsSettings,
} from '../services/fuelingTicketsSettings';
import { FuelTicketSettingItem } from 'src/routes/interfaces/FuelTicket';

// Actions
const COMPLETE_LOAD = 'vendors/fuelingTicketsSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/fuelingTicketsSettings/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/fuelingTicketsSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/fuelingTicketsSettings/FAIL_SAVE';
const START_LOAD = 'vendors/fuelingTicketsSettings/START_LOAD';
const START_SAVE = 'vendors/fuelingTicketsSettings/START_SAVE';

interface Setting {
  id: number;
  vehicleTypeId: number;
  isActive: boolean;
}

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  fuelingTicketsSettings: [] as Setting[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          fuelingTicketsSettings: action.fuelingTicketsSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (fuelingTicketsSettings: any) => ({
  type: COMPLETE_LOAD,
  fuelingTicketsSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadFuelingTicketsSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadFuelingTicketsSettings = doLoadFuelingTicketsSettings(vendorId);
  loadFuelingTicketsSettings
    .then(fuelingTicketsSettings => dispatch(completeLoad(fuelingTicketsSettings)))
    .catch(() => dispatch(failLoad()));

  return loadFuelingTicketsSettings;
};

export const saveFuelingTicketsSettings =
  (vendorId: number, fuelingTicketsSettings: FuelTicketSettingItem[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveFuelingTicketsSettings = doSaveFuelingTicketsSettings(vendorId, fuelingTicketsSettings);
    saveFuelingTicketsSettings.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveFuelingTicketsSettings;
  };
