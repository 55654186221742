import { map } from 'lodash-es';

import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformUnassignedLocations = (locations: any[]) =>
  map(
    locations,
    ({
      customer,
      location,
      address,
      scheduledDay,
      scheduledDays,
      serviceId,
      serviceContractBinDetail,
      serviceContractName,
      materialTypeTechnicalName,
      vehicleTypeId,
    }) => ({
      accountStatusId: 1,
      customer,
      location: {
        ...location,
        address,
      },
      latitude: address.latitude,
      longitude: address.longitude,
      scheduledDay,
      scheduledDays,
      vehicleTypeId,
      service: {
        id: serviceId,
        name: serviceContractName,
        serviceContractBinDetails: [serviceContractBinDetail],
      },
      wasteMaterialType: translate(createTranslationKey(materialTypeTechnicalName, 'common.wasteTypes')),
      wasteMaterialTypeTechnicalName: materialTypeTechnicalName,
    }),
  );

export default transformUnassignedLocations;
