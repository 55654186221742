import React, { useState } from 'react';

import { remove } from 'lodash-es';

import { Modal } from 'src/core/components';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { CustomerLocationEmail } from 'src/customers/interfaces/CustomerLocationEmail';
import { Email } from 'src/customers/interfaces/Email';
import { sendCustomerEmail } from 'src/customers/services/customerCommunication';
import CustomerEmailForm from '../forms/CustomerEmailForm';

interface Props {
  email: Partial<Email>;
  customerId?: number;
  routeLocationId?: number;
  routeId?: number;
  onClose: () => void;
  readOnly?: boolean;
  routeLocationDetailPickupExceptionId?: number;
  isInfoEmail?: boolean;
  customerLocationsEmails: CustomerLocationEmail[];
}

export const CustomerEmailModal: React.FC<Props> = ({
  email,
  onClose,
  readOnly,
  routeId,
  routeLocationDetailPickupExceptionId,
  routeLocationId,
  customerId,
  isInfoEmail,
  customerLocationsEmails,
}) => {
  const [isEmailing, setIsEmailing] = useState(false);
  const [selectedImageBlobIds, setSelectedImageBlobIds] = useState<string[]>([]);

  const onSend = (formMail: Email) => {
    setIsEmailing(true);
    const to = formMail.toEmail.split(',');

    // in case we have a custom email recipient, we need to take the location id from the email list
    if (to.length < 2 && routeLocationId && customerLocationsEmails.length) {
      to.unshift(customerLocationsEmails[0].locationId.toString());
    }

    sendCustomerEmail({
      emailMessage: formMail.emailMessage,
      emailSubject: formMail.emailSubject,
      fromEmail: formMail.fromEmail,
      locationId: Number(to[0]),
      toEmail: to[1],
      imageBlobIds: selectedImageBlobIds,
      vehicleTypeId: email.vehicleTypeId,
      routeLocationId,
      routeLocationDetailPickupExceptionId,
      customerId,
      isInfoEmail,
      routeId,
    })
      .then(() => {
        setIsEmailing(false);
        createSuccessNotification(translate('customers.emails.emailSent'));
        onClose();
      })
      .catch(() => {
        setIsEmailing(false);
        createErrorNotification(translate('common.alertMessages.feedbackSentFail'));
      });
  };

  const subTitle =
    !readOnly && selectedImageBlobIds?.length
      ? translate('customers.emails.imageAttached', { noImages: selectedImageBlobIds?.length })
      : undefined;

  const toggleImageBlobId = (imageBlobId: string) => {
    const newIds = [...selectedImageBlobIds];
    if (!newIds.includes(imageBlobId)) {
      newIds.push(imageBlobId);
    } else {
      remove(newIds, id => id === imageBlobId);
    }
    setSelectedImageBlobIds(newIds);
  };

  return (
    <Modal
      onClose={onClose}
      title={translate(`customers.emails.${readOnly ? 'modalTitleReadonly' : 'modalTitle'}`)}
      subTitle={subTitle}
      isLoading={isEmailing}
    >
      <CustomerEmailForm
        allowCustomRecipient={!!routeLocationId}
        customerLocationsEmails={customerLocationsEmails}
        email={email}
        onSubmit={onSend}
        readOnly={readOnly}
        toggleImageBlobId={toggleImageBlobId}
        selectedImageBlobIds={selectedImageBlobIds}
      />
    </Modal>
  );
};
