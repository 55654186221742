import { useSelector, useDispatch } from 'react-redux';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';

import { AppState } from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadRubiconServiceRates } from 'src/customers/ducks/rubiconServicesRates';
import RubiconServicesRatesModal from './RubiconServicesRatesModal';

interface Props {
  closeModal: () => void;
  serviceId: number;
  serviceStatus: string;
}

export default function RubiconServicesRatesModalResolver(props: Props) {
  const { closeModal, serviceId, serviceStatus } = props;

  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();
    return loadRubiconServiceRates(serviceId, serviceStatus, vendorId, userId)(dispatch);
  };

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={RubiconServicesRatesModal}
      successProps={{ closeModal }}
    />
  );
}
