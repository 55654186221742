import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { logout } from '../../ducks';
import { DuckFunction } from '../../../contracts/ducks';

interface Props extends RouteComponentProps {
  logout: DuckFunction<typeof logout>;
}

const LogoutPage: React.FC<Props> = ({ logout }) => {
  React.useEffect(() => logout());

  return null;
};

const mapDispatchToProps = { logout };

export default connect(null, mapDispatchToProps)(LogoutPage);
