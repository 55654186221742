import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Icon } from 'src/core/components';
import { loadingOverlay } from '../../../core/styles';
import { sizeMapper } from '../../../utils/styles';

interface Props {
  isLoadingSegmentDetails?: boolean;
  drawingEnabled?: boolean;
  height?: string | number;
  hidden?: boolean;
  isDrawing?: boolean;
  isEditMode?: boolean;
  isLoading?: boolean;
  margin?: string;
}

export const StreetNetworkMapWrapper = styled.div<Props>`
  width: 100%;
  height: ${props => props.height}px;

  *:focus {
    outline: none;
  }

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
`;

export const StyledPopUpWrapper = styled.div<Props>`
  .mapboxgl-popup {
    left: auto !important;
    right: 0;
    top: 0;
    transform: none !important;
    height: 100%;

    .mapboxgl-popup-content {
      border-radius: 0 !important;
      height: 100%;
      box-shadow: -5px 4px 14px rgb(0 0 0 / 7%);
      padding: 16px 20px !important;

      ${props =>
        props.isLoadingSegmentDetails &&
        css`
          ${props => loadingOverlay('24px', props.theme.bodyBackgroundColor)};
        `};

      .mapboxgl-popup-close-button {
        color: ${props => props.theme.grayDark};
        font-size: 28px;

        &:hover {
          color: ${props => props.theme.brandPrimary};
          background: none !important;
        }
      }
    }
  }
  .mapboxgl-popup-tip {
    display: none;
  }
`;
export const MapGLWrapper = styled.div<Props>`
  height: ${props =>
    typeof props.height === 'number' ? `${props.height}px` : typeof props.height === 'string' ? props.height : '100%'};
  position: relative;

  ${props =>
    props.isLoading &&
    css`
      ${props => loadingOverlay('24px', props.theme.bodyBackgroundColor)};
    `};

  & .overlays {
    cursor: ${p => (p.isDrawing ? 'crosshair' : 'initial')};
    pointer-events: ${p => (p.drawingEnabled ? 'all' : 'none !important')};

    & > :not(#editor) {
      pointer-events: all;
    }
  }

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    display: none;
  }
`;

export const MapGLPopupContent = styled.div<Props>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  width: calc(100% + 10px);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

export const ExpandableMapFilterSectionIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  position: absolute;
  top: 14px;
  right: 22px;
  width: 12px;
  height: 12px;
  color: ${props => transparentize(0.3, props.theme.brandPrimary)};
  transition: transform 0.3s ease-out;
`;

export const ExpandableMapFilterSectionHeader = styled.div`
  position: relative;
  padding: 12px 20px 10px;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  text-transform: capitalize;
  font-size: 13px;
  cursor: pointer;
`;

export const ExpandableMapFilterSectionContent = styled.div<{ isVisible?: boolean }>`
  overflow: hidden;
  padding: 0px;
  visibility: hidden;
  ${props =>
    props.isVisible &&
    css`
      visibility: visible;
      padding: 12px 20px 10px;
    `};
`;

export const ExpandableMapFilterSection = styled.div<{
  isDisabled?: boolean;
  isOpen?: boolean;
  hasMarginBottom?: boolean;
}>`
  transition: background-color 0.3s ease-out;
  margin-top: 10px;

  ${props =>
    props.hasMarginBottom &&
    css`
      margin-bottom: 30px;
    `};

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
      ${ExpandableMapFilterSectionHeader} {
        color: ${props.theme.grayDark};
      }
      ${ExpandableMapFilterSectionIcon} {
        color: ${props => props.theme.grayDark};
      }
    `};
  ${props =>
    props.isOpen &&
    css`
      background-color: ${props => props.theme.grayLighter};
      ${ExpandableMapFilterSectionIcon} {
        transform: rotate(180deg);
      }
    `};
`;

export const ExpandableMapFiltersSubSection = styled.div<{
  isOpen: boolean;
  leftPosition?: string;
  rightPosition?: string;
}>`
  padding: 12px 20px 10px;
  position: absolute;
  top: 0;
  z-index: 99;
  height: 100%;
  width: 230px;
  overflow-y: scroll;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-out;
  background-color: white;
  box-shadow: 13px 4px 14px rgba(0, 0, 0, 0.07);

  ${props =>
    props.isOpen &&
    css`
      transform: none;
      width: 250px;
    `};

  ${props =>
    props.rightPosition &&
    css`
      left: auto;
      right: ${props.rightPosition};
    `};

  ${props =>
    props.leftPosition &&
    css`
      left: ${props.leftPosition};
      right: auto;
    `};
`;

export const ExpandableMapFilterSubSectionSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 14px;
  height: 14px;
  margin-top: 6px;
  color: ${props => props.theme.gray};
`;

export const ExpandableMapFilterSubSectionSearch = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const ExpandableMapFilterSubSectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 9px !important;
  color: ${props => props.theme.grayDarker};
  background-color: ${props => props.theme.grayLight};
`;

export const ExpandableMapFilterSubSectionInline = styled.div<{ noPaddingLeft?: boolean }>`
  padding: 0px 10px 10px 25px;
  & > label {
    font-size: 11px;
  }

  ${props =>
    props.noPaddingLeft &&
    css`
      padding-left: 0px;
    `};
`;

export const ServiceAreaModalWarning = styled.div`
  position: absolute;
  bottom: 20px;
  /* make it on center */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 300px);

  & span {
    /* From https://css.glass */
    background: rgba(255, 90, 0, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 90, 0, 0.07);

    padding: 10px 20px;
  }
`;

export const StreetNetworkMapInformationWrapper = styled.div<{ color?: string }>`
  position: absolute;
  top: -10px;
  /* make it on center */
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.07);
  }
`;
