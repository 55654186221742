import { find } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { VEHICLE_ICON_TYPES } from 'src/common/constants/vehicleIcons';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER,
  DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_SOURCE,
} from 'src/dashboard/constants/dashboardMapGL';
import { setDashboardModalMapSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { getVehiclesListPositionsGeoJSON } from './utils';
import VehiclePositionsGLPopupResolver from './VehiclePositionPopupResolver';
import VehiclePositionsGLSource from './VehiclePositionsGLSource';

const mapImages = VEHICLE_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const VehiclePositionsGL: React.FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();
  const vehiclePositions = useSelector(state => state.dashboard.vehiclesData.vehiclesList);
  const routeVehiclePositions = useSelector(state => state.dashboard.routesData.routeVehiclePositions);

  const { vehicleId, isSourceRoute: isRouteVehicle } = useSelector(state => state.dashboard.mapControls.mapModalData);

  const selectedVehicle = useMemo(() => {
    if (!vehicleId) {
      return null;
    }
    if (isRouteVehicle) {
      return find(routeVehiclePositions, { vehicleId });
    }

    return find(vehiclePositions, { id: vehicleId });
  }, [isRouteVehicle, routeVehiclePositions, vehicleId, vehiclePositions]);

  const geoJSONVehiclePositions = useMemo(() => {
    if (!selectedVehicle) {
      return getVehiclesListPositionsGeoJSON([]);
    }

    return getVehiclesListPositionsGeoJSON([selectedVehicle]);
  }, [selectedVehicle]);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_SOURCE &&
              feature.properties?.layer === DASHBOARD_MAP_MODAL_VEHICLE_POSITIONS_LAYER,
          );

        if (!!feature) {
          dispatch(setDashboardModalMapSelectedFeature('vehiclePositions', feature.id as number));
        }
      });
    });
  }, [map, dispatch]);

  return (
    <>
      <VehiclePositionsGLSource geoJSON={geoJSONVehiclePositions} />
      <VehiclePositionsGLPopupResolver />
    </>
  );
};

export default VehiclePositionsGL;
