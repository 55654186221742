import React, { PureComponent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { size } from 'lodash-es';
import {
  FormGroup,
  FormError,
  FileInputToggle as FileInputToggleContainer,
  FileInputToggleLabel,
  FileInputToggleInput,
  FileInputToggleText,
} from './styled';
import translate from '../services/translate';

interface Props extends WrappedFieldProps {
  multiple: boolean;
  accept: string;
  margin: string;
}

class FileInput extends PureComponent<Props> {
  onFilesSelected = (event: any) => {
    if (event.target && event.target.files.length) {
      this.props.input.onChange(event.target.files);
    }
  };

  render() {
    const {
      multiple,
      accept,
      margin,
      input: { value },
      meta: { submitFailed, error },
    } = this.props;

    return (
      <FormGroup margin={margin}>
        <FileInputToggleContainer margin={margin}>
          <FileInputToggleLabel>
            <FileInputToggleInput
              name="file"
              multiple={multiple}
              accept={accept}
              onChange={this.onFilesSelected}
              type="file"
            />
            <FileInputToggleText>
              <u>
                {size(value)
                  ? translate(`routes.editJobStatus.${value.length > 1 ? 'xPicturesUploaded' : 'xPictureUploaded'}`, {
                      numberOfPictures: value.length,
                    })
                  : translate(
                      multiple
                        ? 'routes.editJobStatus.uploadPicturesByClickingBrowse'
                        : 'routes.editJobStatus.uploadPictureByClickingBrowse',
                    )}
              </u>
            </FileInputToggleText>
          </FileInputToggleLabel>
        </FileInputToggleContainer>

        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

export default FileInput;
