import { map } from 'lodash-es';

import translate from 'src/core/services/translate';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { Service } from '../interfaces/Services';

const transformLoadYServices = (services: any[]): Service[] =>
  map(services, service => ({
    ...service,
    wasteMaterialType: translate(createTranslationKey(service.technicalName, 'common.wasteTypes')),
  }));

export default transformLoadYServices;
