import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadExceptions as doLoadExceptions,
  saveJobStatus as doSaveJobStatus,
  saveWasteAuditJobStatus as doSaveWasteAuditJobStatus,
} from '../services/exceptions';
import { JobStatus } from '../interfaces/DispatchBoardRouteJob';
import { ExceptionType } from '../interfaces/Exceptions';

// Actions
const START_LOAD = 'routes/exceptions/START_LOAD';
const COMPLETE_LOAD = 'routes/exceptions/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/exceptions/FAIL_LOAD';
const START_SAVE = 'routes/jobStatus/START_SAVE';
const COMPLETE_SAVE = 'routes/jobStatus/COMPLETE_SAVE';
const FAIL_SAVE = 'routes/jobStatus/FAIL_SAVE';

interface State {
  exceptions?: ExceptionType[];
  isLoading: boolean;
  isSaving: boolean;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  exceptions: undefined,
  isLoading: false,
  isSaving: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          exceptions: action.exceptions,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (exceptions: ExceptionType[]) => ({
  type: COMPLETE_LOAD,
  exceptions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadExceptions = (vendorId: number, vehicleTypeId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadExceptionsPromise = doLoadExceptions(vendorId, vehicleTypeId);
  loadExceptionsPromise.then(exceptions => dispatch(completeLoad(exceptions))).catch(() => dispatch(failLoad()));

  return loadExceptionsPromise;
};

export const saveJobStatus =
  (formData: JobStatus, routeId: number, routeLocationId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveJobStatusPromise = doSaveJobStatus(formData, routeId, routeLocationId);
    saveJobStatusPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveJobStatusPromise;
  };

export const saveWasteAuditJobStatus =
  (formData: JobStatus, routeId: number, routeLocationId: number) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveJobStatusPromise = doSaveWasteAuditJobStatus(formData, routeId, routeLocationId);
    saveJobStatusPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveJobStatusPromise;
  };
