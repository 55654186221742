import React, { PureComponent, Fragment } from 'react';

import { Coordinates } from 'src/routes/components/mapWithTimeline/Interfaces';
import { getColorIndexByServiceMode, getServiceModeIcon } from '../services/serviceMode';
import { GOOGLE as google } from '../constants';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';
import StreetSweeperTrackingMarker from './StreetSweeperTrackingMarker';

const isCoordinateGroupInsideViewBoundaries = (coordinateGroup: any, mapBounds: any, mapZoom: number) => {
  if (mapZoom <= 10) return false;

  const groupBounds = new google.maps.LatLngBounds();
  coordinateGroup.coordinates.forEach((position: Coordinates) => {
    groupBounds.extend(new google.maps.LatLng(position.latitude, position.longitude));
  });

  return mapBounds.intersects(groupBounds);
};

interface Props {
  coordinateGroup: any;
  index: number;
  mapBounds: any;
  mapZoom: number;
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string) => void;
  unitOfMeasure: string;
  vehicleName: string;
}

class StreetSweeperTrackingGroup extends PureComponent<Props> {
  getId = (index: number) => `coordinateGroup_${index}`;

  render() {
    const {
      coordinateGroup,
      mapBounds,
      mapZoom,
      vehicleName,
      openedInfoWindows,
      toggleInfoWindow,
      unitOfMeasure,
      index,
    } = this.props;

    return (
      <Fragment>
        {isCoordinateGroupInsideViewBoundaries(coordinateGroup, mapBounds, mapZoom) && (
          <StreetSweeperTrackingMarker
            key={this.getId(index)}
            colorIndex={getColorIndexByServiceMode(coordinateGroup.serviceMode)}
            markerIconType={getServiceModeIcon(coordinateGroup.serviceMode)}
            vehicleName={vehicleName}
            coordinateGroupId={this.getId(index)}
            coordinates={coordinateGroup.coordinates}
            speed={coordinateGroup.speed}
            endTimestamp={coordinateGroup.endTimestamp}
            isInfoWindowOpen={openedInfoWindows.streetSweeperTrackings[this.getId(index)]}
            toggleInfoWindow={toggleInfoWindow}
            unitOfMeasure={unitOfMeasure}
            serviceMode={coordinateGroup.serviceMode}
          />
        )}
      </Fragment>
    );
  }
}

export default StreetSweeperTrackingGroup;
