import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { loadFiltersSettings, loadWasteTypes } from 'src/common/ducks';
import { useSelector } from 'src/core/hooks/useSelector';
import store from 'src/store';
import { loadCityAlertSettings, loadYData } from 'src/vendors/ducks';
import {
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  isCityAlertFeatureEnabled,
  isYDataFeatureEnabled,
} from 'src/vendors/ducks/features';
import { loadPermissions, shouldLoadPermissions } from '../../../account/ducks';
import { DocumentTitle } from '../../../common/components';
import { PageLoading } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { TODAY_FORMATTED } from '../../../core/constants';
import translate from '../../../core/services/translate';
import { loadVehicleTypes, loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import {
  loadFeatures,
  loadSnowPlowSettings,
  loadStreetSweepingSettings,
  loadUserById,
  loadVendor,
} from '../../../vendors/ducks';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadCityInsightTypes, loadHaulerLocationsForMapbox, loadPickupExceptionTypes } from '../../ducks';
import { getPersistentFilters, setPersistentFilters } from '../../services/persistentFilterStorage';
import { loadGroups } from 'src/routes/ducks';
import DashboardPageESRI from './DashboardPageESRI';

const DashboardPageESRIResolver: FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const permissionsShouldBeLoaded = useSelector(shouldLoadPermissions);
  const userId = useSelector(currentUserId);

  const { search } = useLocation();

  const loadDependencies = async () => {
    const { date: urlDate } = getQueryParams(search);
    const { date: persistentDate } = getPersistentFilters(vendorId);
    const defaultDate = TODAY_FORMATTED;

    let date = defaultDate;

    if (urlDate) {
      date = urlDate;
    } else if (persistentDate) {
      date = persistentDate;
    }

    if (date !== persistentDate) {
      setPersistentFilters({});
    }

    await loadFeatures(vendorId)(dispatch);

    const isSnowPlowFeatureEnabled = checkIfSnowPlowIsEnabled(store.getState());
    const isStreetSweepingFeatureEnabled = checkIfStreetSweepingIsEnabled(store.getState());
    const isYDataEnabled = isYDataFeatureEnabled(store.getState());
    const isCityAlertEnabled = isCityAlertFeatureEnabled(store.getState());

    const dependencies: Promise<unknown>[] = [
      loadVehicleTypesForVendor(vendorId)(dispatch),
      loadVehicleTypes()(dispatch),
      loadCityInsightTypes(vendorId)(dispatch),
      loadPickupExceptionTypes(vendorId)(dispatch),
      loadVendor(vendorId)(dispatch),
      loadUserById(vendorId, userId)(dispatch),
      loadGroups(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadHaulerLocationsForMapbox()(dispatch),
    ];

    if (userId) dependencies.push(loadFiltersSettings(vendorId, userId)(dispatch));

    if (isSnowPlowFeatureEnabled) {
      dependencies.push(loadSnowPlowSettings(vendorId)(dispatch));
    }

    if (isCityAlertEnabled) dependencies.push(loadCityAlertSettings(vendorId)(dispatch));

    if (isStreetSweepingFeatureEnabled) dependencies.push(loadStreetSweepingSettings(vendorId)(dispatch));

    if (isYDataEnabled) dependencies.push(loadYData()(dispatch));

    if (permissionsShouldBeLoaded) {
      dependencies.push(loadPermissions()(dispatch));
    }

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{`${translate('dashboard.dashboard')} ${translate('dashboard.allOps')}`}</DocumentTitle>

      <TypedResolver
        successComponent={DashboardPageESRI}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        successProps={{ vendorId }}
      />
    </>
  );
};

export default DashboardPageESRIResolver;
