import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getContainerTypes } from 'src/finance/services/containerTypes';

// Actions
const START_LOAD_CONTAINER_TYPES = 'finance/billing/containerTypes/START_LOAD_CONTAINER_TYPES';
const COMPLETE_LOAD_CONTAINER_TYPES = 'finance/billing/containerTypes/COMPLETE_LOAD_CONTAINER_TYPES';
const FAIL_LOAD_CONTAINER_TYPES = 'finance/billing/containerTypes/FAIL_LOAD_CONTAINER_TYPES';

// Initial state
const initialState: {
  isLoading: boolean;
  containerTypes: TechnicalType[];
} = {
  isLoading: false,
  containerTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CONTAINER_TYPES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_CONTAINER_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          containerTypes: action.containerTypes,
        },
      });

    case FAIL_LOAD_CONTAINER_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          containerTypes: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD_CONTAINER_TYPES,
});

const completeLoad = (containerTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_CONTAINER_TYPES,
  containerTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD_CONTAINER_TYPES,
});

export const loadContainerTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getContainerTypesPromise = getContainerTypes();
  getContainerTypesPromise
    .then((containerTypes: any[]) => dispatch(completeLoad(containerTypes)))
    .catch(() => dispatch(failLoad()));
  return getContainerTypesPromise;
};
