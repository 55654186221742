import { InjectedFormProps, reduxForm } from 'redux-form';
import { size } from 'lodash-es';

import {
  Button,
  Message,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  PanelSection,
} from '../../../core/components/styled';
import {
  DELIVERY_UTILITY_ID,
  FRONT_LOAD_ID,
  RESIDENTIAL_ID,
  ROLL_OFF_ID,
  TOTER_ID,
  WASTE_AUDIT_ID,
} from 'src/fleet/constants';
import { DriverPickListDefaults } from 'src/vendors/interfaces/DriverPickListDefaults';
import { DriverPickListDefaultsTableRow } from '.';
import { Table } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../core/services/translate';

interface ComponentProps {
  closeModal: () => void;
}

type Props = ComponentProps & InjectedFormProps<DriverPickListDefaults[], ComponentProps>;

const DriverPickListDefaultsForm: React.FC<Props> = ({ handleSubmit, closeModal }) => {
  const { pickupTypes } = useSelector(state => state.routes.pickupTypes);

  const driverPickListDefaultsTableCells = [
    { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '50%' },
    { name: 'defaultPickupTypes', label: translate('vendors.defaultPickupTypes'), width: '50%' },
  ];

  const acceptedPickupTypes = [
    TOTER_ID,
    DELIVERY_UTILITY_ID,
    FRONT_LOAD_ID,
    RESIDENTIAL_ID,
    ROLL_OFF_ID,
    WASTE_AUDIT_ID,
  ];

  const filteredPickupTypes =
    pickupTypes?.filter(pickupType => acceptedPickupTypes.includes(pickupType.vehicleTypeId)) || [];

  return (
    <form onSubmit={handleSubmit}>
      <ModalClose onClick={() => closeModal()}>
        <ModalCloseIcon />
      </ModalClose>

      <PanelSection padding="small">
        {size(filteredPickupTypes) ? (
          <Table
            cells={driverPickListDefaultsTableCells}
            rowComponent={DriverPickListDefaultsTableRow}
            rows={filteredPickupTypes}
            rowProps={{ filteredPickupTypesCount: filteredPickupTypes.length }}
          />
        ) : (
          <Message padding="sMedium sMedium medium sMedium">{translate('vehicles.noVehiclesConfigured')}</Message>
        )}
      </PanelSection>

      <ModalFixedFooter isShadowed>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => closeModal()}>
          {translate('common.cancel')}
        </Button>
        <Button type="submit" color="primary">
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<DriverPickListDefaults[], ComponentProps>({
  form: 'driverPickListDefaultsForm',
  enableReinitialize: true,
})(DriverPickListDefaultsForm);
