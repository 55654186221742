import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const NEW = 1;
export const GEOLOCATION_COMPLETED = 2;
export const GEOLOCATION_ERROR = 3;
export const CUSTOMER_CREATION_ERROR = 4;
export const CUSTOMER_CREATION_COMPLETED = 5;
export const ADDRESS_CREATION_ERROR = 6;
export const ADDRESS_CREATION_COMPLETED = 7;
export const LOCATION_CREATION_ERROR = 8;
export const LOCATION_CREATION_COMPLETED = 9;
export const SERVICE_CONTRACT_CREATION_ERROR = 10;
export const SERVICE_CONTRACT_CREATION_COMPLETED = 11;
export const SERVICE_CONTRACT_PLAN_CREATION_ERROR = 12;
export const SERVICE_CONTRACT_PLAN_CREATION_COMPLETED = 13;
export const ROUTE_TEMPLATE_DATA_CREATION_ERROR = 14;
export const COMPLETED = 15;

export const IMPORT_STATUSES = mapKeys(
  [
    { id: NEW, name: translate('routeImport.importStatuses.new') },
    { id: GEOLOCATION_COMPLETED, name: translate('routeImport.importStatuses.geolocationCompleted') },
    { id: GEOLOCATION_ERROR, name: translate('routeImport.importStatuses.geolocationError') },
    { id: CUSTOMER_CREATION_ERROR, name: translate('routeImport.importStatuses.customerCreationError') },
    { id: CUSTOMER_CREATION_COMPLETED, name: translate('routeImport.importStatuses.customerCreationCompleted') },
    { id: ADDRESS_CREATION_ERROR, name: translate('routeImport.importStatuses.addressCreationError') },
    { id: ADDRESS_CREATION_COMPLETED, name: translate('routeImport.importStatuses.addressCreationCompleted') },
    { id: LOCATION_CREATION_ERROR, name: translate('routeImport.importStatuses.locationCreationError') },
    { id: LOCATION_CREATION_COMPLETED, name: translate('routeImport.importStatuses.locationCreationCompleted') },
    { id: SERVICE_CONTRACT_CREATION_ERROR, name: translate('routeImport.importStatuses.serviceContractCreationError') },
    {
      id: SERVICE_CONTRACT_CREATION_COMPLETED,
      name: translate('routeImport.importStatuses.serviceContractCreationCompleted'),
    },
    {
      id: SERVICE_CONTRACT_PLAN_CREATION_ERROR,
      name: translate('routeImport.importStatuses.serviceContractPlanCreationError'),
    },
    {
      id: SERVICE_CONTRACT_PLAN_CREATION_COMPLETED,
      name: translate('routeImport.importStatuses.serviceContractPlanCreationCompleted'),
    },
    {
      id: ROUTE_TEMPLATE_DATA_CREATION_ERROR,
      name: translate('routeImport.importStatuses.routeTemplateDataCreationError'),
    },
    { id: COMPLETED, name: translate('routeImport.importStatuses.completed') },
  ],
  'id',
);
