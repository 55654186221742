import React, { DragEvent, MouseEvent, PureComponent } from 'react';

import { RouteSequenceStopDropZone as RouteSequenceStopDropZoneContainer } from '../../styled';

interface Props {
  onRouteLocationDrop: (event: MouseEvent, jobIndex: number) => void;
  position: string;
  routeLocationIndex: number;
}

interface State {
  isReadyToDrop: boolean;
  isUnassignedLocationsModalOpen: boolean;
}

class RouteSequenceStopDropZone extends PureComponent<Props, State> {
  state = {
    isReadyToDrop: false,
    isUnassignedLocationsModalOpen: false,
  };

  onDragOver = (event: DragEvent) => {
    event.preventDefault();
  };

  onDragEnter = (event: DragEvent) => {
    event.preventDefault();
    this.setState({ isReadyToDrop: true });
  };

  onDragLeave = () => {
    this.setState({ isReadyToDrop: false });
  };

  onDrop = (event: DragEvent, jobIndex: number) => {
    event.preventDefault();
    this.setState({ isReadyToDrop: false });

    const { onRouteLocationDrop } = this.props;
    onRouteLocationDrop(event, jobIndex);
  };

  render() {
    const { position, routeLocationIndex } = this.props;
    const { isReadyToDrop } = this.state;

    return (
      <RouteSequenceStopDropZoneContainer
        isReadyToDrop={isReadyToDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={event => this.onDrop(event, routeLocationIndex)}
        position={position}
      />
    );
  }
}

export default RouteSequenceStopDropZone;
