import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import mapboxgl, { LngLat } from 'mapbox-gl';

import { CustomerLocation } from 'src/customers/interfaces/Customers';
import { getFeatureCollection, getMapBounds, getPointFeature } from 'src/common/components/map/util';
import { MAP_CITY_ZOOM_IN, MAP_DEFAULT_ZOOM } from 'src/core/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLWrapper } from '../styled';
import { setCustomerLocationsMapViewport } from '../../ducks/mapControl';
import { theme } from 'src/core/styles';
import { useSelector } from 'src/core/hooks/useSelector';
import CustomerLocationsClustersGL from './CustomerLocationsClustersGL';
import CustomerLocationsGL from './CustomerLocationsGL';

interface Props {
  addressChanged?: boolean;
  onAddressChange: (pinSelection: LngLat) => void;
  customerLocations: CustomerLocation[];
  singlePin?: boolean;
  draggablePin?: boolean;
}

const CustomerMapMapbox: FC<Props> = ({
  customerLocations,
  singlePin,
  draggablePin,
  onAddressChange,
  addressChanged,
}) => {
  const dispatch = useDispatch();
  const { viewport } = useSelector(state => state.customers.mapControl);
  const { homeAddress: vendorAddress } = useSelector(state => state.vendors.vendor.vendor);

  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);
  const [dragPan, setDragPan] = useState<boolean>(true);

  const formattedCustomerLocations = customerLocations?.map((location: CustomerLocation) => {
    return { id: location.id, longitude: location.address.longitude, latitude: location.address.latitude };
  });

  const handleSatelliteViewChange = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  const marker = useRef<mapboxgl.Marker | null>(
    singlePin && formattedCustomerLocations.length
      ? new mapboxgl.Marker({ draggable: draggablePin, color: theme.brandPrimary }).setLngLat([
          formattedCustomerLocations[0].longitude,
          formattedCustomerLocations[0].latitude,
        ])
      : null,
  );

  useEffect(() => {
    if (marker && marker.current) {
      marker.current.setDraggable(draggablePin || false);
      !draggablePin && !dragPan && setDragPan(true);
    }
  }, [dragPan, draggablePin]);

  // Fit map bounds

  useEffect(() => {
    const points = customerLocations.length
      ? customerLocations.map(location => {
          return { longitude: location.address.longitude, latitude: location.address.latitude };
        })
      : [
          {
            latitude: vendorAddress.latitude,
            longitude: vendorAddress.longitude,
          },
        ];

    const bounds = getMapBounds(points, {
      padding: 10,
      capZoom: customerLocations?.length ? MAP_CITY_ZOOM_IN : MAP_DEFAULT_ZOOM,
    });

    if (!addressChanged || !marker.current || !draggablePin) {
      dispatch(setCustomerLocationsMapViewport(bounds));
    }
  }, [addressChanged, customerLocations, dispatch, draggablePin, singlePin, vendorAddress]);

  // Update marker location

  useEffect(() => {
    if (formattedCustomerLocations.length && singlePin) {
      if (marker && marker.current) {
        marker.current.setLngLat([formattedCustomerLocations[0]?.longitude, formattedCustomerLocations[0]?.latitude]);
      } else {
        marker.current = new mapboxgl.Marker({
          draggable: draggablePin,
          color: theme.brandPrimary,
        }).setLngLat([formattedCustomerLocations[0]?.longitude, formattedCustomerLocations[0]?.latitude]);
      }
    }
  }, [draggablePin, formattedCustomerLocations, marker, singlePin]);

  const onDragEnd = useCallback(() => {
    if (!marker || !marker.current) return;

    const lngLat = marker.current.getLngLat();
    onAddressChange(lngLat);

    !dragPan && setDragPan(true);
  }, [dragPan, onAddressChange]);

  const setPinOnMap = useCallback(
    (clickedAddress: any) => {
      if (clickedAddress && clickedAddress.lngLat && marker.current?.isDraggable()) {
        onAddressChange(clickedAddress.lngLat);
      }
    },
    [onAddressChange],
  );

  useEffect(() => {
    if (marker && marker.current) {
      marker.current.on('dragend', onDragEnd);
    }

    return () => {
      if (marker && marker.current) {
        marker.current.off('dragend', onDragEnd);
      }
    };
  }, [onDragEnd]);

  useEffect(() => {
    if (marker.current && map) {
      marker.current.remove();
      marker.current.addTo(map);

      const markerElement = marker.current.getElement();

      markerElement.addEventListener('mouseover', () => {
        markerElement.style.cursor = draggablePin ? 'move' : 'default';
      });

      markerElement.addEventListener('mousedown', () => {
        markerElement.style.cursor = draggablePin ? 'move' : 'default';
        draggablePin && setDragPan(false);
      });

      markerElement.addEventListener('mouseup', () => {
        markerElement.style.cursor = 'default';
        !dragPan && setDragPan(true);
      });
    }
  }, [draggablePin, map, formattedCustomerLocations, marker, setPinOnMap, dragPan]);

  useEffect(() => {
    if (map) {
      map.once('load', () => {
        //count for how long is the mouse down on the map
        let mouseDownTime = 0;
        let mouseDownInterval: any = null;

        const handleMouseDown = () => {
          mouseDownTime = 0;
          mouseDownInterval = setInterval(() => {
            mouseDownTime += 10;
          }, 10);
        };

        const handleMouseUp = (e: any) => {
          clearInterval(mouseDownInterval);
          if (mouseDownTime < 200) setPinOnMap(e);
        };

        map.on('mousedown', handleMouseDown);
        map.on('mouseup', handleMouseUp);

        map.on('mousemove', event => {
          const features = map.queryRenderedFeatures(event.point).filter(
            feature =>
              /**
               * If there is any clickable feature or a cluster on hover,
               * set the pointer cursor.
               */
              feature.properties?.clickable === true || !!feature.properties?.cluster_id,
          );

          map.getCanvas().style.cursor = features.length ? 'pointer' : '';
        });

        map.on('mouseleave', () => {
          map.getCanvas().style.cursor = '';
        });
      });
    }
  }, [map, setPinOnMap]);

  const geoJSON = useMemo(
    () =>
      getFeatureCollection<GeoJSON.Point, { id: number }>(
        customerLocations.map((location, index) =>
          getPointFeature(location.id, [location.address.longitude, location.address.latitude], {
            id: location.id,
            orderNo: index + 1,
          }),
        ),
      ),
    [customerLocations],
  );

  return (
    <MapGLWrapper>
      <MapGL
        key="customer-locations-map"
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        dragPan={dragPan}
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={handleSatelliteViewChange}
      >
        {!!map && !singlePin && (
          <>
            <CustomerLocationsClustersGL geoJSON={geoJSON} map={map} />
            <CustomerLocationsGL />
          </>
        )}
      </MapGL>
    </MapGLWrapper>
  );
};

export default CustomerMapMapbox;
