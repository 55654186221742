import { filter, map, sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { formValueSelector } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Box } from 'src/core/components/styled/Box';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';
import { HOURS_ID, DAYS_ID, WEEKS_ID } from 'src/vendors/constants/streetSegmentAgingIntervals';
import { Icon } from 'src/core/components';
import { loadSnowPlowSettings, loadStreetSweepingSettings } from 'src/vendors/ducks';
import { loadSnowRoadConditionUpdates } from 'src/dashboard/ducks/snowRoadConditions';
import { loadStreetSweeperConditionUpdates } from 'src/dashboard/ducks/streetSweeperConditions';
import { loadVendor } from 'src/vendors/ducks';
import { SegmentConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { setLegendMapControl } from 'src/dashboard/ducks/mapControls';
import { SNOW_ROAD_CONDITIONS, STREET_SWEEPER_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import FloatingCollapsible from 'src/core/components/FloatingCollapsible';
import Tooltip from 'src/core/components/Tooltip';
import translate from 'src/core/services/translate';

interface Props {
  conditionType?: string;
  isRubiconZ: boolean;
  setLastActivityRefresh?: () => void;
}
const LegendColorBox = ({ color, label, width = '33.33%' }: { color: string; label: string; width?: string }) => (
  <Box flex={`0 0 ${width}`} padding="xxSmall no" display="flex" alignItems="center">
    <Box width={14} height={14} backgroundColor={color} mr={7} borderRadius={3} />
    <Text size="sMedium">{label}</Text>
  </Box>
);

const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);

export default function DashboardMapLegend({ conditionType, isRubiconZ, setLastActivityRefresh }: Props) {
  const dispatch = useDispatch();

  const [snowRoadConditionsConfiguration, setSnowRoadConditionsConfiguration] = useState<SegmentConfiguration[]>([]);

  const vendorId = useSelector(currentVendorId);
  const streetSweeperSelected = useSelector(
    state => formSelector(state, 'vehicleTypes.filters.StreetSweeper') || false,
  );
  const vehicleTrackingsVisible = useSelector(
    state => formSelector(state, 'vehicleTracking.filters.showVehicleTracking') || false,
  );
  const roadConditionsVisible = useSelector(
    state => formSelector(state, 'roadConditions.filters.showRoadConditions') || false,
  );

  const controlSpacing = useSelector(state => state.dashboard.mapControls.spacing);
  const navigationControl = useSelector(state => state.dashboard.mapControls.navigation);

  const isSnowPlowFeatureEnabled = useSelector(state => checkIfSnowPlowIsEnabled(state));
  const isStreetSweeperFeatureEnabled = useSelector(state => checkIfStreetSweepingIsEnabled(state));

  const snowRoadConditionsVisible = conditionType && conditionType === SNOW_ROAD_CONDITIONS;
  const streetSweeperConditionsVisible = conditionType && conditionType === STREET_SWEEPER_CONDITIONS;

  const streetSweeperLegendVisible = streetSweeperSelected && vehicleTrackingsVisible && isRubiconZ;
  const noSectionIsEnabled =
    !streetSweeperLegendVisible &&
    !roadConditionsVisible &&
    !snowRoadConditionsVisible &&
    !streetSweeperConditionsVisible;

  const setLegendRef = (element: HTMLDivElement | null) => {
    if (element) {
      dispatch(setLegendMapControl({ width: element.offsetWidth, height: element.offsetHeight, isVisible: true }));
    } else {
      dispatch(setLegendMapControl({ isVisible: false }));
    }
  };

  useEffect(() => {
    if (noSectionIsEnabled) {
      dispatch(setLegendMapControl({ isVisible: false }));
    } else {
      dispatch(setLegendMapControl({ isVisible: true }));
    }
    if (snowRoadConditionsVisible && isSnowPlowFeatureEnabled) {
      loadSnowPlowSettings(vendorId)(dispatch).then(el =>
        setSnowRoadConditionsConfiguration(el.displayConfiguration.segmentConfiguration),
      );
    } else if (streetSweeperConditionsVisible && isStreetSweeperFeatureEnabled) {
      loadStreetSweepingSettings(vendorId)(dispatch).then(el =>
        setSnowRoadConditionsConfiguration(el.segmentColorSettings),
      );
    }
  }, [
    dispatch,
    noSectionIsEnabled,
    snowRoadConditionsVisible,
    vendorId,
    isSnowPlowFeatureEnabled,
    streetSweeperConditionsVisible,
    isStreetSweeperFeatureEnabled,
  ]);

  if (noSectionIsEnabled) {
    return null;
  }

  const xOffset = controlSpacing * 2 + navigationControl.width;

  const sortedSnowRoadConditionsConfiguration = sortBy(snowRoadConditionsConfiguration, [
    (el: SegmentConfiguration) => el.streetSegmentAgingInterval.minValue,
  ]);

  const onSetLastActivityRefresh = () => {
    loadVendor(vendorId)(dispatch).then(() => {
      if (snowRoadConditionsVisible) {
        loadSnowRoadConditionUpdates(vendorId)(dispatch);
        isSnowPlowFeatureEnabled && loadSnowPlowSettings(vendorId)(dispatch);
      } else if (streetSweeperConditionsVisible) {
        loadStreetSweeperConditionUpdates(vendorId)(dispatch);
        isStreetSweeperFeatureEnabled && loadStreetSweepingSettings(vendorId)(dispatch);
      }
      setLastActivityRefresh && setLastActivityRefresh();
    });
  };

  return (
    <ComplexMapControl zIndex={99} position="bottom-left" normalMargin={0} xOffset={xOffset} yOffset={controlSpacing}>
      <FloatingCollapsible
        isOpenByDefault
        title={translate('dashboard.legend')}
        widthWhenOpen={340}
        widthWhenClosed={150}
        onInstanceChange={setLegendRef}
      >
        {streetSweeperLegendVisible && (
          <Box display="flex" flexWrap="wrap" margin="xSmall">
            <Box width="100%">
              <Text size="xSmall" weight="medium" color="grayDarker">
                {translate('common.serviceTypes.streetSweeping')}
              </Text>
            </Box>

            <Box width="100%" display="flex" flexWrap="wrap" mt={3}>
              <LegendColorBox color="#0000ff" label={translate('dashboard.serviceModes.notSweeping')} width="50%" />
              <LegendColorBox color="#008000" label={translate('dashboard.serviceModes.onCurb')} width="50%" />
              <LegendColorBox color="#ff0000" label={translate('dashboard.serviceModes.offCurb')} width="50%" />
              <LegendColorBox color="#fff200" label={translate('dashboard.serviceModes.underReview')} width="50%" />
            </Box>
          </Box>
        )}

        {roadConditionsVisible && (
          <Box display="flex" flexWrap="wrap" margin="xSmall">
            <Box width="100%">
              <Text size="xSmall" weight="medium" color="grayDarker">
                {translate('dashboard.roadConditions')}
              </Text>
            </Box>

            <Box width="100%" display="flex" flexWrap="wrap" mt={3}>
              <LegendColorBox color="#008000" label={translate('dashboard.legends.good')} />
              <LegendColorBox color="#fff200" label={translate('dashboard.legends.moderate')} />
              <LegendColorBox color="#ff0000" label={translate('dashboard.legends.severe')} />
            </Box>
          </Box>
        )}

        {(snowRoadConditionsVisible || streetSweeperConditionsVisible) &&
          !!sortedSnowRoadConditionsConfiguration.length && (
            <Box display="flex" flexWrap="wrap" margin="xSmall">
              <Box width="100%">
                <Text size="xSmall" weight="medium" color="grayDarker">
                  {snowRoadConditionsVisible
                    ? translate(`dashboard.${SNOW_ROAD_CONDITIONS}`)
                    : translate(`dashboard.${STREET_SWEEPER_CONDITIONS}`)}
                </Text>
              </Box>

              <Box width="100%" display="flex" alignItems="center" mt={3}>
                <Text size="sMedium" weight="medium">
                  {translate('dashboard.lastActivity')}
                </Text>

                <Tooltip
                  tooltip="refresh"
                  tooltipColor="grayDarker"
                  width={14}
                  height={14}
                  borderRadius={14}
                  ml={5}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={onSetLastActivityRefresh}
                >
                  <Icon icon="refresh" height="14px" width="14px" />
                </Tooltip>
              </Box>

              <Box width="100%" display="flex" flexWrap="wrap" mt={3}>
                {map(
                  filter(
                    sortedSnowRoadConditionsConfiguration,
                    (configuration: SegmentConfiguration) => configuration.enabled,
                  ),
                  (el: SegmentConfiguration) => {
                    const value =
                      el.streetSegmentAgingInterval.maxValue === null
                        ? `${el.streetSegmentAgingInterval.minValue}+`
                        : `${el.streetSegmentAgingInterval.minValue} - ${el.streetSegmentAgingInterval.maxValue}`;
                    const color = `rgb(${el.red}, ${el.green}, ${el.blue})`;
                    const label =
                      el.streetSegmentAgingInterval.timeMeasurementType.id === HOURS_ID
                        ? 'dashboard.noOfHours'
                        : el.streetSegmentAgingInterval.timeMeasurementType.id === DAYS_ID
                        ? 'dashboard.noOfDays'
                        : el.streetSegmentAgingInterval.timeMeasurementType.id === WEEKS_ID
                        ? 'dashboard.noOfWeeks'
                        : 'dashboard.noOfMonths';
                    return <LegendColorBox key={el.id} color={color} label={translate(label, { value })} />;
                  },
                )}
              </Box>
            </Box>
          )}
      </FloatingCollapsible>
    </ComplexMapControl>
  );
}
