import { Modal } from 'src/core/components';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import EventLog from '../pages/routes/routePageSections/EventLog';
import translate from 'src/core/services/translate';

type Props = {
  closeModal: () => void;
  routeSummary: RouteSummary;
};

export default function EventLogModal({ closeModal, routeSummary }: Props) {
  return (
    <Modal minHeight="550px" title={translate('routes.eventLog')} size="xLarge" onClose={closeModal}>
      <EventLog routeId={routeSummary.routeId} isSnowPlowRoute={routeSummary.vehicleTypeId === SNOW_PLOW_ID} />
    </Modal>
  );
}
