import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadOpportunity, updateHaulerOpportunity } from '../../ducks';
import { loadOpportunities } from '../../ducks/opportunities';
import { Modal } from '../../../core/components';
import { RequestChangesForOpportunityForm } from '../forms';
import translate from '../../../core/services/translate';
import { YES } from '../../constants/status';
import { useIsMobileView } from 'src/utils/hooks';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';

interface RouteParams {
  token: string;
}

interface ComponentProps {
  caseId: number;
  isBiddingSourcing?: boolean;
  itemId: number;
  userId: string | number;
  vendorId: number;
  offer: any;
  onCancel(pristine: boolean): void;
}

interface ReduxProps {
  response: any;
  requestedChanges: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadOpportunity: DuckFunction<typeof loadOpportunity>;
  loadOpportunities: DuckFunction<typeof loadOpportunities>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const RequestChangesForOpportunityModal: React.FC<Props> = props => {
  const { onCancel } = props;

  const isMobile = useIsMobileView();

  const onSubmit = React.useCallback(async () => {
    const {
      isBiddingSourcing,
      itemId,
      location: { search },
      match: {
        params: { token },
      },
      userId,
      vendorId,
      requestedChanges,
      loadDispatchOpportunitiesCount,
      loadOpportunity,
      loadOpportunities,
      onCancel,
      updateHaulerOpportunity,
      closeMobilePageDetailOverlay,
    } = props;
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES;

    const requestOpportunityObj: any = {};

    requestOpportunityObj.itemId = itemId;
    requestOpportunityObj.Response = 'RequestChanges';
    requestOpportunityObj.RequestedChanges = requestedChanges;
    requestOpportunityObj.RequestedChanges.deliveryDate = moment(requestedChanges.deliveryDate).format('YYYY-MM-DD');

    const promise = updateHaulerOpportunity(
      requestOpportunityObj,
      userId,
      token,
      vendorId,
      isActionRequired,
      startDate,
      endDate,
      isBiddingSourcing,
    );

    if (!promise) {
      return;
    }

    await promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        if (isBiddingSourcing) {
          loadOpportunities(userId, vendorId, isActionRequired, startDate, endDate);
        } else {
          loadOpportunity(userId, token, vendorId, isActionRequired);
        }
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);

  return (
    <Modal title={translate('opportunity.requestChanges')} size="small">
      <Grid centered>
        <GridColumn size={isMobile ? '12/12' : '10/12'}>
          <RequestChangesForOpportunityForm isMobile={isMobile} {...props} onSubmit={onSubmit} onCancel={onCancel} />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const formSelector = formValueSelector('requestChangesForOpportunity');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  response: formSelector(state, `opportunities.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `opportunities.settings${ownProps.caseId}.requestedChanges`),
});

const mapDispatchToProps = {
  loadOpportunity,
  loadOpportunities,
  closeMobilePageDetailOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestChangesForOpportunityModal);
