import styled, { css } from 'styled-components';

interface Props {
  singleLine?: boolean;
}

const FormMeta = styled.span<Props>`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};

  ${props =>
    props.singleLine &&
    css`
      position: absolute;
      white-space: nowrap;
    `};
`;

export default FormMeta;
