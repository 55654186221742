export const TABLE_ROW_HEIGHT_xxLARGE = 140;
export const TABLE_ROW_HEIGHT_xLARGE = 110;
export const TABLE_ROW_HEIGHT_LARGEST = 100;
export const TABLE_ROW_HEIGHT_LARGER = 90;
export const TABLE_ROW_HEIGHT_LARGE = 80;
export const TABLE_ROW_HEIGHT = 70;
export const TABLE_ROW_HEIGHT_SMALL = 65;
export const TABLE_ROW_HEIGHT_SMALLER = 50;
export const TABLE_ROW_HEIGHT_SMALLEST = 40;
export const TABLE_HEAD_PROPS = { sticky: true, top: 400 };
export const TABLE_ROW_LIMIT = 3;
export const TABLE_ROW_HEIGHT_EXPANDED = 250;
export const TABLE_ROW_HEIGHT_EXPANDED_SMALL = 210;
export const TABLE_ROW_HEIGHT_EXPANDED_LARGE = 300;
export const TABLE_ROW_MAX_ITEMS = 9;
