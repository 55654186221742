import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const CLIADMIN = 1;
export const CLIBULKPU = 2;
export const CLICASTERS = 3;
export const CLICMBHAUL = 4;
export const CLICNTPERM = 5;
export const CLICONTPEN = 6;
export const CLICREDIT = 7;
export const CLIDAMCR = 8;
export const CLIDELIVER = 9;
export const CLIDISCNT = 10;
export const CLIDISPFEE = 11;
export const CLIDRYRUN = 12;
export const CLIENERGY = 13;
export const CLIENVIRO = 14;
export const CLIEXCFEE = 15;
export const CLIEXTRAPU = 16;
export const CLIEXTRYDG = 17;
export const CLIFEECITY = 18;
export const CLIFINANCE = 19;
export const CLIFRANCH = 20;
export const CLIFREIGHT = 21;
export const CLIFUEL = 22;
export const CLIGATFEE = 23;
export const CLIHAUFLAT = 24;
export const CLIHAULING = 25;
export const CLIHAULMIN = 26;
export const CLIINACTIV = 27;
export const CLILABOR = 28;
export const CLILATEFEE = 29;
export const CLILFACC = 30;
export const CLILINER = 31;
export const CLILOCKFEE = 32;
export const CLIPREVMNT = 33;
export const CLIPROCFEE = 34;
export const CLIPROGFEE = 35;
export const CLIREBOT = 36;
export const CLIREGULA = 37;
export const CLIREMOVAL = 38;
export const CLIRENTFEE = 39;
export const CLIROLLOUT = 40;
export const CLISENSOR = 41;
export const CLISERVICE = 42;
export const CLITAXCARB = 43;
export const CLITAXCITY = 44;
export const CLITAXCNTY = 45;
export const CLITAXSALE = 46;
export const CLITAXSTAT = 47;
export const CLITONNAGE = 48;
export const CLITRANFE = 49;
export const CLITRAVEL = 50;
export const CLIWSTAUD = 51;

export const CLIENOTOUT = 52;
export const CLIEBLOCK = 53;
export const CLIECONTAM = 54;
export const CLIEBULKIT = 55;
export const CLIEBROKEN = 56;
export const CLIEOTHER = 57;
export const CLIEPICKUP = 58;
export const CLIECBLOCK = 59;
export const CLIECEPROP = 60;
export const CLIECLOC = 61;
export const CLIENREADY = 62;
export const CLIEDAMCT = 63;
export const CLIECRADD = 64;
export const CLIEOVERFL = 65;
export const CLIECEADD = 66;
export const CLIECAR = 67;
export const CLIEOVRTRE = 68;
export const CLIEWSTCT = 69;
export const CLIESTSIGN = 70;
export const CLIEPEDES = 71;
export const CLIEEXTRA = 72;
export const CLIEMVCT = 73;
export const CLIEOVRWG = 74;
export const CLIEPREP = 75;
export const CLIEGATE = 76;
export const CLIEWAIT = 77;
export const CLIELABOR = 78;
export const CLIERETURN = 79;
export const CLIECTREPL = 80;
export const CLIEFROZEN = 81;
export const CLIELOCKED = 82;
export const CLIEBEFORE = 83;
export const CLIEAFTER = 84;
export const CLIEADDLD = 85;
export const CLIEPULOUT = 86;
export const CLIECLEANF = 87;
export const CLIEOVRTRN = 88;
export const CLIEOUTRCH = 89;
export const CLIEPROHIB = 90;
export const CLIEOBSTR = 91;
export const CLIEAPPL = 92;
export const CLIEDIFTRK = 93;
export const CLIEILDUMP = 94;
export const CLIEILDMSC = 95;
export const CLIEILDMR = 96;
export const CLIEILDMC = 97;
export const CLIEILDMNF = 98;
export const CLIEILDMUR = 99;
export const CLIEAPPVIO = 100;
export const CLIEYDWAS = 101;
export const CLIEBLCONS = 102;
export const CLIETIRES = 103;
export const CLIEFREON = 104;
export const CLIEYDWASR = 105;
export const CLIEDDANI = 106;
export const CLIEBOBCAT = 107;
export const CLIEABATE = 108;
export const CLIEHHW = 109;
export const CLIETRBAG = 110;
export const CLIEIMPAR = 111;
export const CLIEDAMPP = 112;
export const CLIESRTRS = 113;
export const CLIERETTRI = 114;
export const CLIEEXCCT = 115;
export const CLIENSERC = 116;
export const CLIEEVICT = 117;
export const CLIECONGEN = 118;
export const CLIECITER = 119;
export const CLIECREWER = 120;
export const CLIEICLDAY = 121;
export const CLIEINVADD = 122;
export const CLIELTOUT = 123;
export const CLIEPETPAC = 124;
export const CLIEREFTAG = 125;
export const CLIERTINT = 126;
export const CLIESNOW = 127;
export const CLIEWRLOC = 128;
export const CLIEDUP = 129;
export const CLIEPLBAG = 130;
export const CLIEBLSTR = 131;
export const CLIEBLALL = 132;
export const CLIECALLE = 133;
export const CLIEPURCAN = 134;
export const CLIEPRICOL = 135;
export const CLIECDENF = 136;
export const CLIEOPENLD = 137;
export const CLI311 = 146;
export const CLIHT = 138;
export const CLIMP = 139;
export const CLIISET = 140;
export const CLITBAG = 141;
export const CLIELE = 142;
export const CLICBO = 143;
export const CLINCOL = 145;
export const CLIRDEL = 147;
export const CLIOJUR = 148;
export const CLIHLST = 149;
export const CLIASTO = 150;
export const CLIMIS = 151;
export const CLIMOU = 152;
export const CKLIISET = 153;
export const CLIUTRA = 154;
export const CLICTYC = 155;
export const CLIANF = 156;
export const CLIPARK = 157;
export const CLIWATE = 158;
export const CLINAT = 159;
export const CLIDSIZ = 160;
export const CLIIFA = 161;
export const CLIWAR = 162;
export const CLICTY = 163;
export const CLINCY = 164;
export const CLIOAG = 165;
export const CLIEDOT = 166;
export const CLISINC = 167;
export const CLISCON = 168;
export const CLICOBS = 169;
export const CLIUNCUR = 170;
export const CLIHMAT = 171;
export const CLIPPRO = 172;
export const CLIDAG = 173;
export const CLICNE = 174;
export const CLIAU = 175;

export const RATE_TYPES_LIST = [
  {
    id: CLI311,
    name: translate('customers.rateTypes.CLI311'),
    technicalName: 'CLI311',
  },
  {
    id: CLIADMIN,
    name: translate('customers.rateTypes.CLIADMIN'),
    technicalName: 'CLIADMIN',
  },
  {
    id: CLIBULKPU,
    name: translate('customers.rateTypes.CLIBULKPU'),
    technicalName: 'CLIBULKPU',
  },
  {
    id: CLICASTERS,
    name: translate('customers.rateTypes.CLICASTERS'),
    technicalName: 'CLICASTERS',
  },
  {
    id: CLICMBHAUL,
    name: translate('customers.rateTypes.CLICMBHAUL'),
    technicalName: 'CLICMBHAUL',
  },
  {
    id: CLICNTPERM,
    name: translate('customers.rateTypes.CLICNTPERM'),
    technicalName: 'CLICNTPERM',
  },
  {
    id: CLICONTPEN,
    name: translate('customers.rateTypes.CLICONTPEN'),
    technicalName: 'CLICONTPEN',
  },
  {
    id: CLICREDIT,
    name: translate('customers.rateTypes.CLICREDIT'),
    technicalName: 'CLICREDIT',
  },
  {
    id: CLIDAMCR,
    name: translate('customers.rateTypes.CLIDAMCR'),
    technicalName: 'CLIDAMCR',
  },
  {
    id: CLIDELIVER,
    name: translate('customers.rateTypes.CLIDELIVER'),
    technicalName: 'CLIDELIVER',
  },
  {
    id: CLIDISCNT,
    name: translate('customers.rateTypes.CLIDISCNT'),
    technicalName: 'CLIDISCNT',
  },
  {
    id: CLIDISPFEE,
    name: translate('customers.rateTypes.CLIDISPFEE'),
    technicalName: 'CLIDISPFEE',
  },
  {
    id: CLIDRYRUN,
    name: translate('customers.rateTypes.CLIDRYRUN'),
    technicalName: 'CLIDRYRUN',
  },
  {
    id: CLIENERGY,
    name: translate('customers.rateTypes.CLIENERGY'),
    technicalName: 'CLIENERGY',
  },
  {
    id: CLIENVIRO,
    name: translate('customers.rateTypes.CLIENVIRO'),
    technicalName: 'CLIENVIRO',
  },
  {
    id: CLIEXCFEE,
    name: translate('customers.rateTypes.CLIEXCFEE'),
    technicalName: 'CLIEXCFEE',
  },
  {
    id: CLIEXTRAPU,
    name: translate('customers.rateTypes.CLIEXTRAPU'),
    technicalName: 'CLIEXTRAPU',
  },
  {
    id: CLIEXTRYDG,
    name: translate('customers.rateTypes.CLIEXTRYDG'),
    technicalName: 'CLIEXTRYDG',
  },
  {
    id: CLIFEECITY,
    name: translate('customers.rateTypes.CLIFEECITY'),
    technicalName: 'CLIFEECITY',
  },
  {
    id: CLIFINANCE,
    name: translate('customers.rateTypes.CLIFINANCE'),
    technicalName: 'CLIFINANCE',
  },
  {
    id: CLIFRANCH,
    name: translate('customers.rateTypes.CLIFRANCH'),
    technicalName: 'CLIFRANCH',
  },
  {
    id: CLIFREIGHT,
    name: translate('customers.rateTypes.CLIFREIGHT'),
    technicalName: 'CLIFREIGHT',
  },
  {
    id: CLIFUEL,
    name: translate('customers.rateTypes.CLIFUEL'),
    technicalName: 'CLIFUEL',
  },
  {
    id: CLIGATFEE,
    name: translate('customers.rateTypes.CLIGATFEE'),
    technicalName: 'CLIGATFEE',
  },
  {
    id: CLIHAUFLAT,
    name: translate('customers.rateTypes.CLIHAUFLAT'),
    technicalName: 'CLIHAUFLAT',
  },
  {
    id: CLIHAULING,
    name: translate('customers.rateTypes.CLIHAULING'),
    technicalName: 'CLIHAULING',
  },
  {
    id: CLIHAULMIN,
    name: translate('customers.rateTypes.CLIHAULMIN'),
    technicalName: 'CLIHAULMIN',
  },
  {
    id: CLIINACTIV,
    name: translate('customers.rateTypes.CLIINACTIV'),
    technicalName: 'CLIINACTIV',
  },
  {
    id: CLILABOR,
    name: translate('customers.rateTypes.CLILABOR'),
    technicalName: 'CLILABOR',
  },
  {
    id: CLILATEFEE,
    name: translate('customers.rateTypes.CLILATEFEE'),
    technicalName: 'CLILATEFEE',
  },
  {
    id: CLILFACC,
    name: translate('customers.rateTypes.CLILFACC'),
    technicalName: 'CLILFACC',
  },
  {
    id: CLILINER,
    name: translate('customers.rateTypes.CLILINER'),
    technicalName: 'CLILINER',
  },
  {
    id: CLILOCKFEE,
    name: translate('customers.rateTypes.CLILOCKFEE'),
    technicalName: 'CLILOCKFEE',
  },
  {
    id: CLIPREVMNT,
    name: translate('customers.rateTypes.CLIPREVMNT'),
    technicalName: 'CLIPREVMNT',
  },
  {
    id: CLIPROCFEE,
    name: translate('customers.rateTypes.CLIPROCFEE'),
    technicalName: 'CLIPROCFEE',
  },
  {
    id: CLIPROGFEE,
    name: translate('customers.rateTypes.CLIPROGFEE'),
    technicalName: 'CLIPROGFEE',
  },
  {
    id: CLIREBOT,
    name: translate('customers.rateTypes.CLIREBOT'),
    technicalName: 'CLIREBOT',
  },
  {
    id: CLIREGULA,
    name: translate('customers.rateTypes.CLIREGULA'),
    technicalName: 'CLIREGULA',
  },
  {
    id: CLIREMOVAL,
    name: translate('customers.rateTypes.CLIREMOVAL'),
    technicalName: 'CLIREMOVAL',
  },
  {
    id: CLIRENTFEE,
    name: translate('customers.rateTypes.CLIRENTFEE'),
    technicalName: 'CLIRENTFEE',
  },
  {
    id: CLIROLLOUT,
    name: translate('customers.rateTypes.CLIROLLOUT'),
    technicalName: 'CLIROLLOUT',
  },
  {
    id: CLISENSOR,
    name: translate('customers.rateTypes.CLISENSOR'),
    technicalName: 'CLISENSOR',
  },
  {
    id: CLISERVICE,
    name: translate('customers.rateTypes.CLISERVICE'),
    technicalName: 'CLISERVICE',
  },
  {
    id: CLITAXCARB,
    name: translate('customers.rateTypes.CLITAXCARB'),
    technicalName: 'CLITAXCARB',
  },
  {
    id: CLITAXCITY,
    name: translate('customers.rateTypes.CLITAXCITY'),
    technicalName: 'CLITAXCITY',
  },
  {
    id: CLITAXCNTY,
    name: translate('customers.rateTypes.CLITAXCNTY'),
    technicalName: 'CLITAXCNTY',
  },
  {
    id: CLITAXSALE,
    name: translate('customers.rateTypes.CLITAXSALE'),
    technicalName: 'CLITAXSALE',
  },
  {
    id: CLITAXSTAT,
    name: translate('customers.rateTypes.CLITAXSTAT'),
    technicalName: 'CLITAXSTAT',
  },
  {
    id: CLITONNAGE,
    name: translate('customers.rateTypes.CLITONNAGE'),
    technicalName: 'CLITONNAGE',
  },
  {
    id: CLITRANFE,
    name: translate('customers.rateTypes.CLITRANFE'),
    technicalName: 'CLITRANFE',
  },
  {
    id: CLITRAVEL,
    name: translate('customers.rateTypes.CLITRAVEL'),
    technicalName: 'CLITRAVEL',
  },
  {
    id: CLIWSTAUD,
    name: translate('customers.rateTypes.CLIWSTAUD'),
    technicalName: 'CLIWSTAUD',
  },
  {
    id: CLIENOTOUT,
    name: translate('customers.rateTypes.CLIENOTOUT'),
    technicalName: 'CLIENOTOUT',
  },
  {
    id: CLIEBLOCK,
    name: translate('customers.rateTypes.CLIEBLOCK'),
    technicalName: 'CLIEBLOCK',
  },
  {
    id: CLIECONTAM,
    name: translate('customers.rateTypes.CLIECONTAM'),
    technicalName: 'CLIECONTAM',
  },
  {
    id: CLIEBULKIT,
    name: translate('customers.rateTypes.CLIEBULKIT'),
    technicalName: 'CLIEBULKIT',
  },
  {
    id: CLIEBROKEN,
    name: translate('customers.rateTypes.CLIEBROKEN'),
    technicalName: 'CLIEBROKEN',
  },
  {
    id: CLIEOTHER,
    name: translate('customers.rateTypes.CLIEOTHER'),
    technicalName: 'CLIEOTHER',
  },
  {
    id: CLIEPICKUP,
    name: translate('customers.rateTypes.CLIEPICKUP'),
    technicalName: 'CLIEPICKUP',
  },
  {
    id: CLIECBLOCK,
    name: translate('customers.rateTypes.CLIECBLOCK'),
    technicalName: 'CLIECBLOCK',
  },
  {
    id: CLIECEPROP,
    name: translate('customers.rateTypes.CLIECEPROP'),
    technicalName: 'CLIECEPROP',
  },
  {
    id: CLIECLOC,
    name: translate('customers.rateTypes.CLIECLOC'),
    technicalName: 'CLIECLOC',
  },
  {
    id: CLIENREADY,
    name: translate('customers.rateTypes.CLIENREADY'),
    technicalName: 'CLIENREADY',
  },
  {
    id: CLIEDAMCT,
    name: translate('customers.rateTypes.CLIEDAMCT'),
    technicalName: 'CLIEDAMCT',
  },
  {
    id: CLIECRADD,
    name: translate('customers.rateTypes.CLIECRADD'),
    technicalName: 'CLIECRADD',
  },
  {
    id: CLIEOVERFL,
    name: translate('customers.rateTypes.CLIEOVERFL'),
    technicalName: 'CLIEOVERFL',
  },
  {
    id: CLIECEADD,
    name: translate('customers.rateTypes.CLIECEADD'),
    technicalName: 'CLIECEADD',
  },
  {
    id: CLIECAR,
    name: translate('customers.rateTypes.CLIECAR'),
    technicalName: 'CLIECAR',
  },
  {
    id: CLIEOVRTRE,
    name: translate('customers.rateTypes.CLIEOVRTRE'),
    technicalName: 'CLIEOVRTRE',
  },
  {
    id: CLIEWSTCT,
    name: translate('customers.rateTypes.CLIEWSTCT'),
    technicalName: 'CLIEWSTCT',
  },
  {
    id: CLIESTSIGN,
    name: translate('customers.rateTypes.CLIESTSIGN'),
    technicalName: 'CLIESTSIGN',
  },
  {
    id: CLIEPEDES,
    name: translate('customers.rateTypes.CLIEPEDES'),
    technicalName: 'CLIEPEDES',
  },
  {
    id: CLIEEXTRA,
    name: translate('customers.rateTypes.CLIEEXTRA'),
    technicalName: 'CLIEEXTRA',
  },
  {
    id: CLIEMVCT,
    name: translate('customers.rateTypes.CLIEMVCT'),
    technicalName: 'CLIEMVCT',
  },
  {
    id: CLIEOVRWG,
    name: translate('customers.rateTypes.CLIEOVRWG'),
    technicalName: 'CLIEOVRWG',
  },
  {
    id: CLIEPREP,
    name: translate('customers.rateTypes.CLIEPREP'),
    technicalName: 'CLIEPREP',
  },
  {
    id: CLIEGATE,
    name: translate('customers.rateTypes.CLIEGATE'),
    technicalName: 'CLIEGATE',
  },
  {
    id: CLIEWAIT,
    name: translate('customers.rateTypes.CLIEWAIT'),
    technicalName: 'CLIEWAIT',
  },
  {
    id: CLIELABOR,
    name: translate('customers.rateTypes.CLIELABOR'),
    technicalName: 'CLIELABOR',
  },
  {
    id: CLIERETURN,
    name: translate('customers.rateTypes.CLIERETURN'),
    technicalName: 'CLIERETURN',
  },
  {
    id: CLIECTREPL,
    name: translate('customers.rateTypes.CLIECTREPL'),
    technicalName: 'CLIECTREPL',
  },
  {
    id: CLIEFROZEN,
    name: translate('customers.rateTypes.CLIEFROZEN'),
    technicalName: 'CLIEFROZEN',
  },
  {
    id: CLIELOCKED,
    name: translate('customers.rateTypes.CLIELOCKED'),
    technicalName: 'CLIELOCKED',
  },
  {
    id: CLIEBEFORE,
    name: translate('customers.rateTypes.CLIEBEFORE'),
    technicalName: 'CLIEBEFORE',
  },
  {
    id: CLIEAFTER,
    name: translate('customers.rateTypes.CLIEAFTER'),
    technicalName: 'CLIEAFTER',
  },
  {
    id: CLIEADDLD,
    name: translate('customers.rateTypes.CLIEADDLD'),
    technicalName: 'CLIEADDLD',
  },
  {
    id: CLIEPULOUT,
    name: translate('customers.rateTypes.CLIEPULOUT'),
    technicalName: 'CLIEPULOUT',
  },
  {
    id: CLIECLEANF,
    name: translate('customers.rateTypes.CLIECLEANF'),
    technicalName: 'CLIECLEANF',
  },
  {
    id: CLIEOVRTRN,
    name: translate('customers.rateTypes.CLIEOVRTRN'),
    technicalName: 'CLIEOVRTRN',
  },
  {
    id: CLIEOUTRCH,
    name: translate('customers.rateTypes.CLIEOUTRCH'),
    technicalName: 'CLIEOUTRCH',
  },
  {
    id: CLIEPROHIB,
    name: translate('customers.rateTypes.CLIEPROHIB'),
    technicalName: 'CLIEPROHIB',
  },
  {
    id: CLIEOBSTR,
    name: translate('customers.rateTypes.CLIEOBSTR'),
    technicalName: 'CLIEOBSTR',
  },
  {
    id: CLIEAPPL,
    name: translate('customers.rateTypes.CLIEAPPL'),
    technicalName: 'CLIEAPPL',
  },
  {
    id: CLIEDIFTRK,
    name: translate('customers.rateTypes.CLIEDIFTRK'),
    technicalName: 'CLIEDIFTRK',
  },
  {
    id: CLIEILDUMP,
    name: translate('customers.rateTypes.CLIEILDUMP'),
    technicalName: 'CLIEILDUMP',
  },
  {
    id: CLIEILDMSC,
    name: translate('customers.rateTypes.CLIEILDMSC'),
    technicalName: 'CLIEILDMSC',
  },
  {
    id: CLIEILDMR,
    name: translate('customers.rateTypes.CLIEILDMR'),
    technicalName: 'CLIEILDMR',
  },
  {
    id: CLIEILDMC,
    name: translate('customers.rateTypes.CLIEILDMC'),
    technicalName: 'CLIEILDMC',
  },
  {
    id: CLIEILDMNF,
    name: translate('customers.rateTypes.CLIEILDMNF'),
    technicalName: 'CLIEILDMNF',
  },
  {
    id: CLIEILDMUR,
    name: translate('customers.rateTypes.CLIEILDMUR'),
    technicalName: 'CLIEILDMUR',
  },
  {
    id: CLIEAPPVIO,
    name: translate('customers.rateTypes.CLIEAPPVIO'),
    technicalName: 'CLIEAPPVIO',
  },
  {
    id: CLIEYDWAS,
    name: translate('customers.rateTypes.CLIEYDWAS'),
    technicalName: 'CLIEYDWAS',
  },
  {
    id: CLIEBLCONS,
    name: translate('customers.rateTypes.CLIEBLCONS'),
    technicalName: 'CLIEBLCONS',
  },
  {
    id: CLIETIRES,
    name: translate('customers.rateTypes.CLIETIRES'),
    technicalName: 'CLIETIRES',
  },
  {
    id: CLIEFREON,
    name: translate('customers.rateTypes.CLIEFREON'),
    technicalName: 'CLIEFREON',
  },
  {
    id: CLIEYDWASR,
    name: translate('customers.rateTypes.CLIEYDWASR'),
    technicalName: 'CLIEYDWASR',
  },
  {
    id: CLIEDDANI,
    name: translate('customers.rateTypes.CLIEDDANI'),
    technicalName: 'CLIEDDANI',
  },
  {
    id: CLIEBOBCAT,
    name: translate('customers.rateTypes.CLIEBOBCAT'),
    technicalName: 'CLIEBOBCAT',
  },
  {
    id: CLIEABATE,
    name: translate('customers.rateTypes.CLIEABATE'),
    technicalName: 'CLIEABATE',
  },
  {
    id: CLIEHHW,
    name: translate('customers.rateTypes.CLIEHHW'),
    technicalName: 'CLIEHHW',
  },
  {
    id: CLIETRBAG,
    name: translate('customers.rateTypes.CLIETRBAG'),
    technicalName: 'CLIETRBAG',
  },
  {
    id: CLIEIMPAR,
    name: translate('customers.rateTypes.CLIEIMPAR'),
    technicalName: 'CLIEIMPAR',
  },
  {
    id: CLIEDAMPP,
    name: translate('customers.rateTypes.CLIEDAMPP'),
    technicalName: 'CLIEDAMPP',
  },
  {
    id: CLIESRTRS,
    name: translate('customers.rateTypes.CLIESRTRS'),
    technicalName: 'CLIESRTRS',
  },
  {
    id: CLIERETTRI,
    name: translate('customers.rateTypes.CLIERETTRI'),
    technicalName: 'CLIERETTRI',
  },
  {
    id: CLIEEXCCT,
    name: translate('customers.rateTypes.CLIEEXCCT'),
    technicalName: 'CLIEEXCCT',
  },
  {
    id: CLIENSERC,
    name: translate('customers.rateTypes.CLIENSERC'),
    technicalName: 'CLIENSERC',
  },
  {
    id: CLIEEVICT,
    name: translate('customers.rateTypes.CLIEEVICT'),
    technicalName: 'CLIEEVICT',
  },
  {
    id: CLIECONGEN,
    name: translate('customers.rateTypes.CLIECONGEN'),
    technicalName: 'CLIECONGEN',
  },
  {
    id: CLIECITER,
    name: translate('customers.rateTypes.CLIECITER'),
    technicalName: 'CLIECITER',
  },
  {
    id: CLIECREWER,
    name: translate('customers.rateTypes.CLIECREWER'),
    technicalName: 'CLIECREWER',
  },
  {
    id: CLIEICLDAY,
    name: translate('customers.rateTypes.CLIEICLDAY'),
    technicalName: 'CLIEICLDAY',
  },
  {
    id: CLIEINVADD,
    name: translate('customers.rateTypes.CLIEINVADD'),
    technicalName: 'CLIEINVADD',
  },
  {
    id: CLIELTOUT,
    name: translate('customers.rateTypes.CLIELTOUT'),
    technicalName: 'CLIELTOUT',
  },
  {
    id: CLIEPETPAC,
    name: translate('customers.rateTypes.CLIEPETPAC'),
    technicalName: 'CLIEPETPAC',
  },
  {
    id: CLIEREFTAG,
    name: translate('customers.rateTypes.CLIEREFTAG'),
    technicalName: 'CLIEREFTAG',
  },
  {
    id: CLIERTINT,
    name: translate('customers.rateTypes.CLIERTINT'),
    technicalName: 'CLIERTINT',
  },
  {
    id: CLIESNOW,
    name: translate('customers.rateTypes.CLIESNOW'),
    technicalName: 'CLIESNOW',
  },
  {
    id: CLIEWRLOC,
    name: translate('customers.rateTypes.CLIEWRLOC'),
    technicalName: 'CLIEWRLOC',
  },
  {
    id: CLIEDUP,
    name: translate('customers.rateTypes.CLIEDUP'),
    technicalName: 'CLIEDUP',
  },
  {
    id: CLIEPLBAG,
    name: translate('customers.rateTypes.CLIEPLBAG'),
    technicalName: 'CLIEPLBAG',
  },
  {
    id: CLIEBLSTR,
    name: translate('customers.rateTypes.CLIEBLSTR'),
    technicalName: 'CLIEBLSTR',
  },
  {
    id: CLIEBLALL,
    name: translate('customers.rateTypes.CLIEBLALL'),
    technicalName: 'CLIEBLALL',
  },
  {
    id: CLIECALLE,
    name: translate('customers.rateTypes.CLIECALLE'),
    technicalName: 'CLIECALLE',
  },
  {
    id: CLIEPURCAN,
    name: translate('customers.rateTypes.CLIEPURCAN'),
    technicalName: 'CLIEPURCAN',
  },
  {
    id: CLIEPRICOL,
    name: translate('customers.rateTypes.CLIEPRICOL'),
    technicalName: 'CLIEPRICOL',
  },
  {
    id: CLIECDENF,
    name: translate('customers.rateTypes.CLIECDENF'),
    technicalName: 'CLIECDENF',
  },
  {
    id: CLIEOPENLD,
    name: translate('customers.rateTypes.CLIEOPENLD'),
    technicalName: 'CLIEOPENLD',
  },
  {
    id: CLIHT,
    name: translate("customers.rateTypes.CLIHT"),
    technicalName: 'CLIHT',
  },
  {
    id: CLIMP,
    name: translate("customers.rateTypes.CLIMP"),
    technicalName: 'CLIMP',
  },
  {
    id: CLIISET,
    name: translate("customers.rateTypes.CLIISET"),
    technicalName: 'CLIISET',
  },
  {
    id: CLITBAG,
    name: translate("customers.rateTypes.CLITBAG"),
    technicalName: 'CLITBAG',
  },
  {
    id: CLIELE,
    name: translate("customers.rateTypes.CLIELE"),
    technicalName: 'CLIELE',
  },
  {
    id: CLICBO,
    name: translate("customers.rateTypes.CLICBO"),
    technicalName: 'CLICBO',
  },
  {
    id: CLINCOL,
    name: translate("customers.rateTypes.CLINCOL"),
    technicalName: 'CLINCOL',
  },
  {
    id: CLI311,
    name: translate("customers.rateTypes.CLI311"),
    technicalName: 'CLI311',
  },
  {
    id: CLIRDEL,
    name: translate("customers.rateTypes.CLIRDEL"),
    technicalName: 'CLIRDEL',
  },
  {
    id: CLIOJUR,
    name: translate("customers.rateTypes.CLIOJUR"),
    technicalName: 'CLIOJUR',
  },
  {
    id: CLIHLST,
    name: translate("customers.rateTypes.CLIHLST"),
    technicalName: 'CLIHLST',
  },
  {
    id: CLIASTO,
    name: translate("customers.rateTypes.CLIASTO"),
    technicalName: 'CLIASTO',
  },
  {
    id: CLIMIS,
    name: translate("customers.rateTypes.CLIMIS"),
    technicalName: 'CLIMIS',
  },
  {
    id: CLIMOU,
    name: translate("customers.rateTypes.CLIMOU"),
    technicalName: 'CLIMOU',
  },
  {
    id: CKLIISET,
    name: translate("customers.rateTypes.CKLIISET"),
    technicalName: 'CKLIISET',
  },
  {
    id: CLIUTRA,
    name: translate("customers.rateTypes.CLIUTRA"),
    technicalName: 'CLIUTRA',
  },
  {
    id: CLICTYC,
    name: translate("customers.rateTypes.CLICTYC"),
    technicalName: 'CLICTYC',
  },
  {
    id: CLIANF,
    name: translate("customers.rateTypes.CLIANF"),
    technicalName: 'CLIANF',
  },
  {
    id: CLIPARK,
    name: translate("customers.rateTypes.CLIPARK"),
    technicalName: 'CLIPARK',
  },
  {
    id: CLIWATE,
    name: translate("customers.rateTypes.CLIWATE"),
    technicalName: 'CLIWATE',
  },
  {
    id: CLINAT,
    name: translate("customers.rateTypes.CLINAT"),
    technicalName: 'CLINAT',
  },
  {
    id: CLIDSIZ,
    name: translate("customers.rateTypes.CLIDSIZ"),
    technicalName: 'CLIDSIZ',
  },
  {
    id: CLIIFA,
    name: translate("customers.rateTypes.CLIIFA"),
    technicalName: 'CLIIFA',
  },
  {
    id: CLIWAR,
    name: translate("customers.rateTypes.CLIWAR"),
    technicalName: 'CLIWAR',
  },
  {
    id: CLICTY,
    name: translate("customers.rateTypes.CLICTY"),
    technicalName: 'CLICTY',
  },
  {
    id: CLINCY,
    name: translate("customers.rateTypes.CLINCY"),
    technicalName: 'CLINCY',
  },
  {
    id: CLIOAG,
    name: translate("customers.rateTypes.CLIOAG"),
    technicalName: 'CLIOAG',
  },
  {
    id: CLIEDOT,
    name: translate("customers.rateTypes.CLIEDOT"),
    technicalName: 'CLIEDOT',
  },
  {
    id: CLISINC,
    name: translate("customers.rateTypes.CLISINC"),
    technicalName: 'CLISINC',
  },
  {
    id: CLISCON,
    name: translate("customers.rateTypes.CLISCON"),
    technicalName: 'CLISCON',
  },
  {
    id: CLICOBS,
    name: translate("customers.rateTypes.CLICOBS"),
    technicalName: 'CLICOBS',
  },
  {
    id: CLIUNCUR,
    name: translate("customers.rateTypes.CLIUNCUR"),
    technicalName: 'CLIUNCUR',
  },
  {
    id: CLIHMAT,
    name: translate("customers.rateTypes.CLIHMAT"),
    technicalName: 'CLIHMAT',
  },
  {
    id: CLIPPRO,
    name: translate("customers.rateTypes.CLIPPRO"),
    technicalName: 'CLIPPRO',
  },
  {
    id: CLIDAG,
    name: translate("customers.rateTypes.CLIDAG"),
    technicalName: 'CLIDAG',
  },
  {
    id: CLICNE,
    name: translate("customers.rateTypes.CLICNE"),
    technicalName: 'CLICNE',
  },
  {
    id: CLIAU,
    name: translate("customers.rateTypes.CLIAU"),
    technicalName: 'CLIAU',
  },

];

export const RATE_TYPES = mapKeys(RATE_TYPES_LIST, 'id');
