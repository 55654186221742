import { mapKeys } from 'lodash-es';
import moment from 'moment';

import translate from '../services/translate';

export const START_DATE_BETA_REPORTS = moment('01-01-2015', 'MM-DD-YYYY').format('M-D-YYYY');

export const MAX_INTERVAL_THIRTY_DAYS = 30;
export const MAX_INTERVAL_SIXTY_DAYS = 60;
export const DEFAULT_MAX_INTERVAL_AMOUNT = MAX_INTERVAL_SIXTY_DAYS;
export const MAX_INTERVAL_NINETY_DAYS = 90;

export type DaysOfWeekIds = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 7;
export const SUNDAY_FIRST = 0;

export type DaysOfWeekShortCodes = 'M' | 'T' | 'W' | 'R' | 'F' | 'S' | 'U';

export const MONDAY_SHORTCODE = 'M';
export const TUESDAY_SHORTCODE = 'T';
export const WEDNESDAY_SHORTCODE = 'W';
export const THURSDAY_SHORTCODE = 'R';
export const FRIDAY_SHORCUT = 'F';
export const SATURDAY_SHORTCODE = 'S';
export const SUNDAY_SHORTCODE = 'U';

export type Weekday = {
  id: DaysOfWeekIds;
  middleCodeName: string;
  name: string;
  shortCode: DaysOfWeekShortCodes;
  shortCodeName: string;
};

export const WEEKDAYS: Weekday[] = [
  {
    id: MONDAY,
    shortCode: MONDAY_SHORTCODE,
    name: translate('common.weekdays.monday'),
    shortCodeName: translate('common.weekdayShortcodes.monday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.M'),
  },
  {
    id: TUESDAY,
    shortCode: TUESDAY_SHORTCODE,
    name: translate('common.weekdays.tuesday'),
    shortCodeName: translate('common.weekdayShortcodes.tuesday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.T'),
  },
  {
    id: WEDNESDAY,
    shortCode: WEDNESDAY_SHORTCODE,
    name: translate('common.weekdays.wednesday'),
    shortCodeName: translate('common.weekdayShortcodes.wednesday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.W'),
  },
  {
    id: THURSDAY,
    shortCode: THURSDAY_SHORTCODE,
    name: translate('common.weekdays.thursday'),
    shortCodeName: translate('common.weekdayShortcodes.thursday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.R'),
  },
  {
    id: FRIDAY,
    shortCode: FRIDAY_SHORCUT,
    name: translate('common.weekdays.friday'),
    shortCodeName: translate('common.weekdayShortcodes.friday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.F'),
  },
  {
    id: SATURDAY,
    shortCode: SATURDAY_SHORTCODE,
    name: translate('common.weekdays.saturday'),
    shortCodeName: translate('common.weekdayShortcodes.saturday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.S'),
  },
  {
    id: SUNDAY,
    shortCode: SUNDAY_SHORTCODE,
    name: translate('common.weekdays.sunday'),
    shortCodeName: translate('common.weekdayShortcodes.sunday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.U'),
  },
];

export const WEEKDAYS_STARTING_SUNDAY: Weekday[] = [
  {
    id: MONDAY,
    shortCode: MONDAY_SHORTCODE,
    name: translate('common.weekdays.monday'),
    shortCodeName: translate('common.weekdayShortcodes.monday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.M'),
  },
  {
    id: TUESDAY,
    shortCode: TUESDAY_SHORTCODE,
    name: translate('common.weekdays.tuesday'),
    shortCodeName: translate('common.weekdayShortcodes.tuesday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.T'),
  },
  {
    id: WEDNESDAY,
    shortCode: WEDNESDAY_SHORTCODE,
    name: translate('common.weekdays.wednesday'),
    shortCodeName: translate('common.weekdayShortcodes.wednesday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.W'),
  },
  {
    id: THURSDAY,
    shortCode: THURSDAY_SHORTCODE,
    name: translate('common.weekdays.thursday'),
    shortCodeName: translate('common.weekdayShortcodes.thursday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.R'),
  },
  {
    id: FRIDAY,
    shortCode: FRIDAY_SHORCUT,
    name: translate('common.weekdays.friday'),
    shortCodeName: translate('common.weekdayShortcodes.friday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.F'),
  },
  {
    id: SATURDAY,
    shortCode: SATURDAY_SHORTCODE,
    name: translate('common.weekdays.saturday'),
    shortCodeName: translate('common.weekdayShortcodes.saturday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.S'),
  },
  {
    id: SUNDAY_FIRST,
    shortCode: SUNDAY_SHORTCODE,
    name: translate('common.weekdays.sunday'),
    shortCodeName: translate('common.weekdayShortcodes.sunday'),
    middleCodeName: translate('routeTemplateBuilder.dayShortCodes.U'),
  },
];

export const WEEKDAYS_BY_ID = mapKeys(WEEKDAYS, 'id');
export const WEEKDAYS_STARTING_SUNDAY_BY_ID = mapKeys(WEEKDAYS_STARTING_SUNDAY, 'id');
export const WEEKDAYS_BY_SHORTCODE = mapKeys(WEEKDAYS, 'shortCode');

export const TODAY = moment().toDate();
export const TODAY_FORMATTED = moment().format('MM/DD/YYYY');
export const TODAY_END_OF_DAY = moment().endOf('day');
export const YESTERDAY = moment().subtract(1, 'days').format('MM/DD/YYYY');
export const TOMORROW = moment().add(1, 'days').format('MM/DD/YYYY');
export const TOMORROW_TO_DATE = moment().add(1, 'days').toDate();
export const THREE_DAYS_BEFORE_TODAY = moment().subtract(3, 'days').format('MM/DD/YYYY');
export const TEN_DAYS_BEFORE_TODAY = moment().subtract(10, 'days').format('MM/DD/YYYY');
export const TEN_DAYS_AFTER_TODAY = moment().add(10, 'days').format('MM/DD/YYYY');
export const SEVEN_DAYS_BEFORE_TODAY = moment().subtract(7, 'days').format('MM/DD/YYYY');
export const SEVEN_DAYS_AFTER_TODAY = moment().add(7, 'days').format('MM/DD/YYYY');
export const FIFTEEN_DAYS_AFTER_TODAY = moment().add(15, 'days').format('MM/DD/YYYY');
export const FOURTEEN_DAYS_AFTER_TODAY = moment().add(14, 'days').format('MM/DD/YYYY');
export const ONE_MONTH_AGO = moment().subtract(1, 'months').format('MM/DD/YYYY');
export const SIXTY_DAYS_BEFORE_TODAY = moment().subtract(60, 'days').format('MM/DD/YYYY');
export const NINETY_DAYS_BEFORE_TODAY = moment().subtract(90, 'days').format('MM/DD/YYYY');
export const ONE_YEAR_AFTER = moment().add(12, 'months').format('MM/DD/YYYY');
export const GET_MONTHS_BEFORE = (value: number) => moment().subtract(value, 'months').toDate();
export const GET_DAYS_BEFORE = (value: number) => moment().subtract(value, 'days').toDate();

export const DATE_RANGE_PICKER_30_60_90 = [
  {
    label: translate('common.lastXDays', { days: 30 }),
    value: { from: moment(TODAY).subtract(30, 'days'), to: TODAY },
  },
  {
    label: translate('common.lastXDays', { days: 60 }),
    value: { from: moment(TODAY).subtract(60, 'days'), to: TODAY },
  },
  {
    label: translate('common.lastXDays', { days: 90 }),
    value: { from: moment(TODAY).subtract(90, 'days'), to: TODAY },
  },
];

export const DATE_RANGE_PICKER_TODAY_3_7 = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.xDays', { days: 3 }),
    value: { from: moment(TODAY).subtract(1, 'days'), to: moment(TODAY).add(1, 'days') },
  },
  {
    label: translate('common.xDays', { days: 7 }),
    value: { from: moment(TODAY).subtract(1, 'days'), to: moment(TODAY).add(5, 'days') },
  },
];

export const DATE_RANGE_PICKER_TODAY_3 = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.xDays', { days: 3 }),
    value: { from: moment(TODAY).subtract(1, 'days'), to: moment(TODAY).add(1, 'days') },
  },
];

export const DATE_RANGE_PICKER_TODAY_3_7_PAST = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.lastXDays', { days: 3 }),
    value: {
      from: moment().subtract(2, 'days').toDate(),
      to: TODAY,
    },
  },
  {
    label: translate('common.lastXDays', { days: 7 }),
    value: {
      from: moment().subtract(6, 'days').toDate(),
      to: TODAY,
    },
  },
];

export const DATE_RANGE_PICKER_TODAY_7_FUTURE = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.xDays', { days: 7 }),
    value: {
      from: TODAY,
      to: moment().add(7, 'days').toDate(),
    },
  },
];

export const DATE_RANGE_PICKER_TODAY_7_30_FUTURE = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.xDays', { days: 7 }),
    value: {
      from: TODAY,
      to: moment().add(7, 'days').toDate(),
    },
  },
  {
    label: translate('common.xDays', { days: 30 }),
    value: {
      from: TODAY,
      to: moment().add(30, 'days').toDate(),
    },
  },
];

export const DATE_RANGE_PICKER_TODAY_14_30_FUTURE = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.xDays', { days: 14 }),
    value: {
      from: TODAY,
      to: moment().add(14, 'days').toDate(),
    },
  },
  {
    label: translate('common.xDays', { days: 30 }),
    value: {
      from: TODAY,
      to: moment().add(30, 'days').toDate(),
    },
  },
];

export const DATE_RANGE_PICKER_TODAY_7_30 = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.lastXDays', { days: 7 }),
    value: {
      from: moment().subtract(7, 'days').toDate(),
      to: TODAY,
    },
  },
  {
    label: translate('common.lastXDays', { days: 30 }),
    value: {
      from: moment().subtract(30, 'days').toDate(),
      to: TODAY,
    },
  },
];

export const DATE_RANGE_PICKER_3_7_30 = [
  {
    label: translate('common.lastXDays', { days: 3 }),
    value: {
      from: moment().subtract(3, 'days').toDate(),
      to: TODAY,
    },
  },
  {
    label: translate('common.lastXDays', { days: 7 }),
    value: {
      from: moment().subtract(7, 'days').toDate(),
      to: TODAY,
    },
  },
  {
    label: translate('common.lastXDays', { days: 30 }),
    value: {
      from: moment().subtract(30, 'days').toDate(),
      to: TODAY,
    },
  },
];

export const DATE_RANGE_PICKER_TODAY_7_30_90_PAST = [
  {
    label: translate('common.today'),
    value: { from: TODAY, to: TODAY },
  },
  {
    label: translate('common.lastXDays', { days: 7 }),
    value: {
      from: moment().subtract(7, 'days').toDate(),
      to: TODAY,
    },
  },
  {
    label: translate('common.lastXDays', { days: 30 }),
    value: {
      from: moment().subtract(30, 'days').toDate(),
      to: TODAY,
    },
  },
  {
    label: translate('common.lastXDays', { days: 90 }),
    value: {
      from: moment().subtract(90, 'days').toDate(),
      to: TODAY,
    },
  },
];

export const DEFAULT_TIME = '00:00:00';

export const MAX_SEARCHABLE_MONTHS = 6;

const FIRST_DAY_ID = 1;
const LAST_DAY_ID = 6;

export const RECURRENCE_TYPES = [
  {
    label: translate('customers.dayOfMonthList.first'),
    value: FIRST_DAY_ID,
  },
  {
    label: translate('customers.dayOfMonthList.last'),
    value: LAST_DAY_ID,
  },
];

export const SPECIFIC_DATE_RANGE = 'yes';
export const DESCRIPTIVE_DATE_RANGE = 'no';

export const DAYS_OF_MONTH: any[] = [];
for (let i = 1; i <= 31; i++) {
  DAYS_OF_MONTH.push({ id: i, name: i });
}
