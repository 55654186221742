import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import {
  LOCATION_AND_CITY_ALERTS_HEATMAP,
  LOCATION_AND_CITY_ALERTS_HEATMAP_SOURCE,
} from 'src/fleet/constants/locationAndCityAlerts';

interface Props {
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, any>;
}

const LocationAndCityAlertsGLHeatmap = memo<Props>(({ geoJSON }) => {
  return (
    <Source type="geojson" id={LOCATION_AND_CITY_ALERTS_HEATMAP_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id={LOCATION_AND_CITY_ALERTS_HEATMAP}
        type="heatmap"
        source={LOCATION_AND_CITY_ALERTS_HEATMAP_SOURCE}
        paint={{
          'heatmap-weight': {
            type: 'exponential',
            stops: [
              [0, 0],
              [5, 0.5],
            ],
          },
          'heatmap-intensity': {
            stops: [
              [0, 0],
              [5, 1],
            ],
          },
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(0, 255, 0, 0)',
            0.2,
            'rgba(0, 255, 0, 1)',
            0.4,
            'rgba(255, 255, 0, 1)',
            0.6,
            'rgba(255, 165, 0, 1)',
            1.0,
            'rgba(255, 69, 0, 1)',
            2.0,
            'rgba(255, 0, 0, 1)',
          ],

          'heatmap-radius': {
            stops: [
              [0, 1],
              [5, 20],
            ],
          },
        }}
      />
    </Source>
  );
});

export default LocationAndCityAlertsGLHeatmap;
