import update from 'immutability-helper';
import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';

import translate from 'src/core/services/translate';
import { dateTimeSecondsFormat } from 'src/utils/services/validator';
import {
  RouteMapFilters,
  RouteMapVehicleData,
  RouteMapVehicleDataFilter,
  RouteMapVehiclePosition,
  RouteMapVehicleTracking,
} from '../interfaces/RouteMapVehicleData';
import {
  loadRouteMapFilters as doLoadRouteMapFilters,
  loadRouteMapVehicleData as doLoadRouteMapVehicleData,
} from '../services/routeMapVehicleData';

const START_LOAD = 'routes/routeMapVehicleData/START_LOAD';
const COMPLETE_LOAD = 'routes/routeMapVehicleData/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeMapVehicleData/FAIL_LOAD';
const START_LOAD_ROUTE_MAP_DRIVERS = 'routes/routeMapVehicleData/START_LOAD_ROUTE_MAP_DRIVERS';
const COMPLETE_LOAD_ROUTE_MAP_DRIVERS = 'routes/routeMapVehicleData/COMPLETE_LOAD_ROUTE_MAP_DRIVERS';
const FAIL_LOAD_ROUTE_MAP_DRIVERS = 'routes/routeMapVehicleData/FAIL_LOAD_ROUTE_MAP_DRIVERS';
const RESET = 'routes/routeMapVehicleData/RESET';
const RESET_INCLUDE_VEHICLE_TRACKING = 'routes/routeMapVehicleData/RESET_INCLUDE_VEHICLE_TRACKING';

interface State {
  filters: RouteMapVehicleDataFilter[];
  isLoading: boolean;
  isLoadingDataForMap: boolean;
  isVehicleTrackingLoaded?: boolean;
  mainVehicleId?: number;
  routeMapFilters: RouteMapFilters;
  unitOfMeasure: string;
  lastRefreshed: string;
  vehiclePositions: RouteMapVehiclePosition[];
  vehicleTrackings: RouteMapVehicleTracking[];
}

const initialState: State = {
  filters: [],
  isLoading: false,
  isLoadingDataForMap: false,
  isVehicleTrackingLoaded: false,
  mainVehicleId: undefined,
  routeMapFilters: {} as RouteMapFilters,
  lastRefreshed: translate('opportunity.serviceFrequency.nA'),
  unitOfMeasure: '',
  vehiclePositions: [],
  vehicleTrackings: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          mainVehicleId: action.mainVehicleId,
          filters: action.routeMapVehicleData.filters,
          unitOfMeasure: action.routeMapVehicleData.records.unitOfMeasure,
          vehiclePositions: action.routeMapVehicleData.records.vehiclePositions,
          vehicleTrackings: action.routeMapVehicleData.records.vehicleTrackings,
          isVehicleTrackingLoaded: action.includeVehicleTracking,
          lastRefreshed: moment().format(dateTimeSecondsFormat),
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          filters: [],
          unitOfMeasure: '',
          vehiclePositions: [],
          vehicleTrackings: [],
        },
      });

    case START_LOAD_ROUTE_MAP_DRIVERS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_ROUTE_MAP_DRIVERS:
      return update(state, {
        $merge: {
          isLoading: false,
          routeMapFilters: action.routeMapFilters,
        },
      });

    case FAIL_LOAD_ROUTE_MAP_DRIVERS:
      return update(state, {
        $merge: {
          isLoading: false,
          routeMapFilters: {} as RouteMapFilters,
        },
      });

    case RESET:
      return initialState;

    case RESET_INCLUDE_VEHICLE_TRACKING:
      return update(state, {
        $merge: {
          isVehicleTrackingLoaded: action.isVehicleTrackingLoaded,
        },
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeMapVehicleData: RouteMapVehicleData, includeVehicleTracking?: boolean) => {
  const mainVehicle = routeMapVehicleData.filters.find(vehicle => !vehicle.isAssisting);

  return {
    type: COMPLETE_LOAD,
    routeMapVehicleData,
    mainVehicleId: mainVehicle?.vehicleId,
    includeVehicleTracking,
  };
};

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadRouteMapFilters = () => ({
  type: START_LOAD_ROUTE_MAP_DRIVERS,
});

const completeLoadRouteMapFilters = (routeMapFilters: RouteMapFilters) => ({
  type: COMPLETE_LOAD_ROUTE_MAP_DRIVERS,
  routeMapFilters,
});

const failLoadRouteMapFilters = () => ({
  type: FAIL_LOAD_ROUTE_MAP_DRIVERS,
});

export const loadRouteMapVehicleData =
  (vendorId: number, routeId: number, includeVehicleTracking?: boolean, noLoadingIndicator?: boolean) =>
  (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoad());
    const loadRouteMapVehicleData = doLoadRouteMapVehicleData(vendorId, routeId, includeVehicleTracking);
    loadRouteMapVehicleData
      .then(routeMapVehicleData => dispatch(completeLoad(routeMapVehicleData, includeVehicleTracking)))
      .catch(() => dispatch(failLoad()));
    return loadRouteMapVehicleData;
  };

export const loadRouteMapFilters =
  (vendorId: number, routeId?: number, startDate?: Date | string, endDate?: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoadRouteMapFilters());
    const loadRouteMapFiltersPromise = doLoadRouteMapFilters(vendorId, routeId, startDate, endDate);
    loadRouteMapFiltersPromise
      .then((routeMapFilters: RouteMapFilters) => dispatch(completeLoadRouteMapFilters(routeMapFilters)))
      .catch(() => dispatch(failLoadRouteMapFilters()));
    return loadRouteMapFiltersPromise;
  };

export const resetRouteMapVehicleData = () => ({
  type: RESET,
});

export const resetIncludeVehicleTracking = (isVehicleTrackingLoaded: boolean) => ({
  type: RESET_INCLUDE_VEHICLE_TRACKING,
  isVehicleTrackingLoaded,
});
