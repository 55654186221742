import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash-es';
import moment from 'moment';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { date } from '../../../utils/services/formatter';
import { loadMaterialTickets, saveMaterialTicket, uploadMaterialTicketImage } from '../../ducks';
import { Modal } from '../../../core/components';
import MaterialTicketEditorForm, { MaterialTicketFormValues } from '../forms/MaterialTicketEditorForm';
import translate from '../../../core/services/translate';

type Props = {
  onClose: (formPristine?: boolean) => void;
  routeId: number;
  routeLocationId?: number;
  materialTicketId?: number;
};

export default function MaterialTicketEditorModal({ onClose, routeId, routeLocationId, materialTicketId }: Props) {
  const dispatch = useDispatch();
  const isEditMode = materialTicketId ? true : false;

  const [uploadedImageId, setUploadedImageId] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(undefined);
  const [uploadedImageBlob, setUploadedImageBlob] = useState(undefined);
  const [uploadedImageName, setUploadedImageName] = useState(undefined);

  const { isUploadingMaterialImage, isSaving } = useSelector((state: AppState) => state.routes.materialTickets);
  const vehicleId = useSelector((state: AppState) => state.routes.routeSummary.routeSummary?.vehicleId);
  const routeDate = useSelector((state: AppState) => state.routes.route.route?.routeDate);
  const allMaterialTickets = useSelector((state: AppState) => state.routes.materialTickets.materialTickets) || [];

  const rawMaterialTicket = allMaterialTickets.find(mt => mt.id === materialTicketId);
  let materialTicketInitialValues = {};
  if (rawMaterialTicket) {
    materialTicketInitialValues = {
      ...rawMaterialTicket,
      materialTicketDate: date(rawMaterialTicket.pickUpDateTime),
      materialTicketTime: moment(rawMaterialTicket.pickUpDateTime).format('HH:mm'),
    };
  } else {
    materialTicketInitialValues = {
      materialTicketDate: date(routeDate),
      materialTicketTime: moment().format('HH:mm'),
    };
  }

  const handleSubmitMaterialTicket = async (formData: MaterialTicketFormValues) => {
    const { materialTicketDate, materialTicketTime } = formData;

    const pickupDateTimeLocal = moment(materialTicketDate, 'MM/DD/YYYY')
      .set({
        hour: materialTicketTime ? Number(materialTicketTime.split(':')[0]) : 0,
        minute: materialTicketTime ? Number(materialTicketTime.split(':')[1]) : 0,
      })
      .utc(true)
      .format();

    const currentFormData = {
      ...omit(formData, ['materialTicketDate', 'materialTicketTime']),
      pickupDateTimeLocal,
    };

    const materialTicket = materialTicketId
      ? {
          ...rawMaterialTicket,
          ...currentFormData,
        }
      : {
          vehicleId,
          ...currentFormData,
        };

    if (uploadedImageId) {
      materialTicket.image = {
        blobId: uploadedImageBlob,
        imageId: uploadedImageId,
        url: uploadedImageUrl,
      };
    }

    saveMaterialTicket(
      routeId,
      materialTicket,
    )(dispatch)
      .then(() => {
        onClearFileName();
        loadMaterialTickets(routeId, routeLocationId)(dispatch);

        createSuccessNotification(translate('routes.alertMessages.materialTicketSaved'));
        onClose(true);
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.materialTicketSaveError'));
      });
  };

  const onImageUpload = (files: any) => {
    uploadMaterialTicketImage(
      files[0],
      routeId,
    )(dispatch)
      .then(async response => {
        setUploadedImageBlob(response[0].imageBlobId);
        setUploadedImageId(response[0].id);
        setUploadedImageName(response[0].imageFileName);
        setUploadedImageUrl(response[0].imageUrl);

        createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
      })
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.invalidImageFormat'));
      });
  };

  const onClearFileName = () => {
    setUploadedImageBlob(undefined);
    setUploadedImageId(null);
    setUploadedImageName(undefined);
    setUploadedImageUrl(undefined);
  };

  return (
    <Modal
      size="large"
      isLoading={isSaving || isUploadingMaterialImage}
      onClose={onClose}
      title={translate(`common.materialTickets.${isEditMode ? 'edit' : 'add'}MaterialTicket`)}
    >
      <MaterialTicketEditorForm
        initialValues={materialTicketInitialValues}
        onSubmit={handleSubmitMaterialTicket}
        onClose={onClose}
        onImageUpload={onImageUpload}
        onClearfileName={onClearFileName}
        selectedFileName={uploadedImageName}
      />
    </Modal>
  );
}
