import React, { useState } from 'react';
import { change, isPristine } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { Button, Grid, GridColumn, Message, ModalSection } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { DRIVER_SAFETY_FORM, EVENT_NOTIFICATION_DELAY } from '../../constants/driverSafety';
import { DriverSafetyConfigurations } from 'src/vendors/interfaces/DriverSafety';
import {
  loadDriverSafetyConfigurations,
  saveDriverSafetyConfigurations,
  saveDriverSafetyConfigurationsForVehicle,
} from 'src/vendors/ducks';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import DriverSafetyForm from '../forms/DriverSafetyForm';
import DriverSafetyVehicleForm from '../forms/DriverSafetyVehicleForm';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  vehicleId?: number;
  vehicleTypeIdSpecificForVehicle?: number;
}

const DriverSafetyModal: React.FC<Props> = ({ closeModal, vehicleId, vehicleTypeIdSpecificForVehicle }) => {
  const dispatch = useDispatch();

  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(0);

  const vendorId = useSelector(currentVendorId);
  const { driverSafetyConfigurations, isSaving, isLoading } = useSelector(
    (state: AppState) => state.vendors.driverSafety,
  );
  const formPristine = useSelector((state: AppState) => isPristine(DRIVER_SAFETY_FORM)(state));

  const onVehicleTypeIdChange = (vehicleTypeId: number) => {
    setSelectedVehicleTypeId(vehicleTypeId);

    const shouldUpdateState = true;
    dispatch(loadDriverSafetyConfigurations(vendorId, vehicleTypeId, shouldUpdateState));
  };

  const onClose = (pristine = formPristine) => {
    closeModal(pristine);
  };

  const onSubmit = (formData: any) => {
    if (!vendorId) {
      return;
    }

    const saveDriverSafety = vehicleId ? saveDriverSafetyConfigurationsForVehicle : saveDriverSafetyConfigurations;
    const id = vehicleId ? vehicleId : selectedVehicleTypeId;

    const formDataFormatted = formData.map((driverSafetyConfiguration: DriverSafetyConfigurations) => {
      const isEventNotificationDelay =
        driverSafetyConfiguration.driverSafetySettingType.technicalName === EVENT_NOTIFICATION_DELAY;
      const safetyConfigurationData = {
        driverSafetySettingType: driverSafetyConfiguration?.driverSafetySettingType,
        isEventActive: driverSafetyConfiguration?.isEventActive,
        vehicleId: driverSafetyConfiguration?.vehicleId,
        id: driverSafetyConfiguration?.id,
      };

      const safetyConfiguration = isEventNotificationDelay
        ? {
            ...safetyConfigurationData,
          }
        : {
            ...safetyConfigurationData,
            driverSafetyDataCaptureType: driverSafetyConfiguration?.driverSafetyDataCaptureType,
            isAudioAlertActive: driverSafetyConfiguration?.isAudioAlertActive,
            isVisualAlertActive: driverSafetyConfiguration?.isVisualAlertActive,
          };

      return safetyConfiguration;
    });

    saveDriverSafety(
      vendorId,
      id,
      formDataFormatted,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.driverSafetyConfigurationSaved'));
        onClose(true);
      })
      .catch(() => {
        createErrorNotification(translate('vendors.alertMessages.driverSafetyConfigurationSavedError'));
      });
  };

  const loadDefaultDriverSafetyConfigurations = () => {
    loadDriverSafetyConfigurations(
      vendorId,
      vehicleTypeIdSpecificForVehicle,
    )(dispatch).then(driverSafetyConfigurations => {
      driverSafetyConfigurations.forEach((config: DriverSafetyConfigurations, index: number) => {
        dispatch(change(DRIVER_SAFETY_FORM, `${index}.isEventActive`, config.isEventActive));
        dispatch(change(DRIVER_SAFETY_FORM, `${index}.isAudioAlertActive`, config.isAudioAlertActive));
        dispatch(change(DRIVER_SAFETY_FORM, `${index}.isVisualAlertActive`, config.isVisualAlertActive));

        dispatch(
          change(DRIVER_SAFETY_FORM, `${index}.driverSafetyDataCaptureType.id`, config.driverSafetyDataCaptureType?.id),
        );
        !config.driverSafetyDataCaptureType &&
          dispatch(change(DRIVER_SAFETY_FORM, `${index}.driverSafetyDataCaptureType`, null));
      });
    });
  };

  return (
    <Modal
      verticalSize="small"
      size="xLarge"
      overflow="hidden"
      padding="medium no no"
      flexDirection="column"
      title={translate(`vendors.featureCodes.${!!vehicleId ? 'surfsightConfiguration' : 'driverSafetyFeatures'}`)}
      onClose={() => onClose()}
    >
      <ModalSection flexGrow isLoading={isLoading || isSaving}>
        {!!vehicleId && (
          <Grid centered margin="small no medium no">
            <GridColumn size="6/12" align="center">
              <Button color="primary" onClick={loadDefaultDriverSafetyConfigurations} margin="no small no no">
                {translate('vehicles.loadDefault')}
              </Button>
            </GridColumn>
          </Grid>
        )}

        {!vehicleId && <DriverSafetyVehicleForm onVehicleTypeIdChange={onVehicleTypeIdChange} />}

        {!driverSafetyConfigurations.length && !!selectedVehicleTypeId && (
          <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
        )}

        {!!driverSafetyConfigurations.length && (!!selectedVehicleTypeId || !!vehicleId) && (
          <DriverSafetyForm initialValues={driverSafetyConfigurations} onSubmit={onSubmit} />
        )}
      </ModalSection>
    </Modal>
  );
};

export default DriverSafetyModal;
