import { AnyAction, Dispatch } from 'redux';
import { orderBy } from 'lodash-es';
import update from 'immutability-helper';

import {
  ChangedRateCodes,
  RateAccountingCode,
  RateCodes,
  RateConfigurationFormValuesToSubmit,
  RateManagerReducerState,
} from '../interfaces/RateManager';
import { CustomerImportFile, RateImportFile } from 'src/customers/interfaces/CustomerImport';
import {
  loadRateCodes as doLoadRateCodes,
  saveRateCode as doSaveRateCode,
  saveRateCodes as doSaveRateCodes,
  saveRateAccountingCode as doSaveRateAccountingCode,
  loadRateAccountingCodes as doLoadRateAccountingCodes,
  loadBillingUnitOfMeasureTypes as doLoadBillingUnitOfMeasureTypes,
  exportRateConfiguration as doExportRateConfiguration,
  uploadRateCodeImportFile as doUploadRateCodeImportFile,
  uploadAccountingCodeImportFile as doUploadAccountingCodeImportFile,
  uploadRateImportFile as doUploadRateImportFile,
  loadRateCodeImportUploadedFilesStatus as doLoadRateCodeImportUploadedFilesStatus,
  loadAccountingCodeImportUploadedFilesStatus as doLoadAccountingCodeImportUploadedFilesStatus,
  loadRateImportUploadedFilesStatus as doLoadRateImportUploadedFilesStatus,
} from '../services/rateManager';
import { RateConfigurationAddRateFormValues } from '../components/forms/RateConfigurationAddRateForm';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'finance/rateManager/rateCodes/START_LOAD';
const COMPLETE_LOAD = 'finance/rateManager/rateCodes/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/rateManager/rateCodes/FAIL_LOAD';
const START_SAVE = 'finance/rateManager/rateCodes/START_SAVE';
const COMPLETE_SAVE = 'finance/rateManager/rateCodes/COMPLETE_SAVE';
const FAIL_SAVE = 'finance/rateManager/rateCodes/FAIL_SAVE';
const START_SAVE_RATE_CODES = 'finance/rateManager/rateCodes/START_SAVE_RATE_CODES';
const COMPLETE_SAVE_CODES = 'finance/rateManager/rateCodes/COMPLETE_SAVE_CODES';
const FAIL_SAVE_RATE_CODES = 'finance/rateManager/rateCodes/FAIL_SAVE_RATE_CODES';
const START_SAVE_RATE_ACCOUNTING_CODE = 'finance/rateManager/rateCodes/START_SAVE_RATE_ACCOUNTING_CODE';
const COMPLETE_SAVE_RATE_ACCOUNTING_CODE = 'finance/rateManager/rateCodes/COMPLETE_SAVE_RATE_ACCOUNTING_CODE';
const FAIL_SAVE_RATE_ACCOUNTING_CODE = 'finance/rateManager/rateCodes/FAIL_SAVE_RATE_ACCOUNTING_CODE';
const START_LOAD_UOM = 'finance/rateManager/rateCodes/START_LOAD_UOM';
const COMPLETE_LOAD_UOM = 'finance/rateManager/rateCodes/COMPLETE_LOAD_UOM';
const FAIL_LOAD_UOM = 'finance/rateManager/rateCodes/FAIL_LOAD_UOM';
const START_LOAD_ACCOUNTING_CODES = 'finance/rateManager/rateCodes/START_LOAD_ACCOUNTING_CODES';
const COMPLETE_LOAD_ACCOUNTING_CODES = 'finance/rateManager/rateCodes/COMPLETE_LOAD_ACCOUNTING_CODES';
const FAIL_LOAD_ACCOUNTING_CODES = 'finance/rateManager/rateCodes/FAIL_LOAD_ACCOUNTING_CODES';
const START_EXPORT = 'finance/rateManager/rateCodes/START_EXPORT';
const COMPLETE_EXPORT = 'finance/rateManager/rateCodes/COMPLETE_EXPORT';
const FAIL_EXPORT = 'finance/rateManager/rateCodes/FAIL_EXPORT';
const SET_CHANGED_RATE_CODES = 'finance/rateManager/rateCodes/SET_CHANGED_RATE_CODES';
const START_UPLOAD = 'finance/rateManager/rateCodes/START_UPLOAD';
const COMPLETE_UPLOAD = 'finance/rateManager/rateCodes/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'finance/rateManager/rateCodes/FAIL_UPLOAD';
const UPDATE_UPLOAD_PERCENT = 'finance/rateManager/rateCodes/UPDATE_UPLOAD_PERCENT';
const START_LOAD_RATE_IMPORT = 'finance/rateManager/rateCodes/START_LOAD_RATE_IMPORT';
const COMPLETE_LOAD_RATE_IMPORT = 'finance/rateManager/rateCodes/COMPLETE_LOAD_RATE_IMPORT';
const FAIL_LOAD_RATE_IMPORT = 'finance/rateManager/rateCodes/FAIL_LOAD_RATE_IMPORT';

// Initial state
const initialState: RateManagerReducerState = {
  billingUnitOfMeasureTypes: [],
  changedRateCodes: [],
  isExporting: false,
  isLoading: false,
  isLoadingRateAccountinCodes: false,
  isLoadingRateImport: false,
  isSaving: false,
  isSavingRateCodes: false,
  isUploading: false,
  rateAccountingCodes: [],
  rateCodes: [],
  uploadedFiles: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rateCodes: action.rateCodes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          rateCodes: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_SAVE_RATE_CODES:
      return update(state, {
        $merge: {
          isSavingRateCodes: true,
        },
      });

    case COMPLETE_SAVE_CODES:
      return update(state, {
        $merge: {
          isSavingRateCodes: false,
        },
      });

    case FAIL_SAVE_RATE_CODES:
      return update(state, {
        $merge: {
          isSavingRateCodes: false,
        },
      });

    case START_SAVE_RATE_ACCOUNTING_CODE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE_RATE_ACCOUNTING_CODE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE_RATE_ACCOUNTING_CODE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_UOM:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_UOM:
      return update(state, {
        $merge: {
          isLoading: false,
          billingUnitOfMeasureTypes: action.billingUnitOfMeasureTypes,
        },
      });

    case FAIL_LOAD_UOM:
      return update(state, {
        $merge: {
          isLoading: false,
          billingUnitOfMeasureTypes: [],
        },
      });

    case START_LOAD_ACCOUNTING_CODES:
      return update(state, {
        $merge: {
          isLoadingRateAccountinCodes: true,
        },
      });

    case COMPLETE_LOAD_ACCOUNTING_CODES:
      return update(state, {
        $merge: {
          isLoadingRateAccountinCodes: false,
          rateAccountingCodes: action.rateAccountingCodes,
        },
      });

    case FAIL_LOAD_ACCOUNTING_CODES:
      return update(state, {
        $merge: {
          isLoadingRateAccountinCodes: false,
          rateAccountingCodes: [],
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case SET_CHANGED_RATE_CODES:
      return update(state, {
        $merge: {
          changedRateCodes: action.changedRateCodes,
        },
      });

    case START_UPLOAD:
      return update(state, {
        isUploading: { $set: true },
      });

    case COMPLETE_UPLOAD:
      return update(state, {
        isUploading: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });

    case FAIL_UPLOAD:
      return update(state, {
        isUploading: { $set: false },
      });

    case START_LOAD_RATE_IMPORT:
      return update(state, {
        isLoadingRateImport: { $set: true },
      });

    case COMPLETE_LOAD_RATE_IMPORT:
      return update(state, {
        isLoadingRateImport: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });

    case FAIL_LOAD_RATE_IMPORT:
      return update(state, {
        isLoadingRateImport: { $set: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rateCodes: RateCodes[]) => ({
  type: COMPLETE_LOAD,
  rateCodes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startSaveRateCodes = () => ({
  type: START_SAVE_RATE_CODES,
});

const completeSaveRateCodes = () => ({
  type: COMPLETE_SAVE_CODES,
});

const failSaveRateCodes = () => ({
  type: FAIL_SAVE_RATE_CODES,
});

const startSaveRateAccountingCode = () => ({
  type: START_SAVE_RATE_ACCOUNTING_CODE,
});

const completeSaveRateAccountingCode = () => ({
  type: COMPLETE_SAVE_RATE_ACCOUNTING_CODE,
});

const failSaveRateAccountingCode = () => ({
  type: FAIL_SAVE_RATE_ACCOUNTING_CODE,
});

const startLoadUom = () => ({
  type: START_LOAD_UOM,
});

const completeLoadUom = (billingUnitOfMeasureTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD_UOM,
  billingUnitOfMeasureTypes,
});

const failLoadUom = () => ({
  type: FAIL_LOAD_UOM,
});

const startLoadRateAccountingCodes = () => ({
  type: START_LOAD_ACCOUNTING_CODES,
});

const completeLoadRateAccountingCodes = (rateAccountingCodes: RateAccountingCode[]) => ({
  type: COMPLETE_LOAD_ACCOUNTING_CODES,
  rateAccountingCodes,
});

const failLoadRateAccountingCodes = () => ({
  type: FAIL_LOAD_ACCOUNTING_CODES,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const setChangedRates = (changedRateCodes: ChangedRateCodes[]) => ({
  type: SET_CHANGED_RATE_CODES,
  changedRateCodes,
});

const startUpload = () => ({ type: START_UPLOAD });
const completeUpload = (uploadedFiles: CustomerImportFile[] | RateImportFile[]) => ({
  type: COMPLETE_UPLOAD,
  uploadedFiles,
});
const failUpload = () => ({ type: FAIL_UPLOAD });

const startLoadRateImport = () => ({ type: START_LOAD_RATE_IMPORT });
const completeLoadRateImport = (uploadedFiles: CustomerImportFile[] | RateImportFile[]) => ({
  type: COMPLETE_LOAD_RATE_IMPORT,
  uploadedFiles,
});
const failLoadRateImport = () => ({ type: FAIL_LOAD_RATE_IMPORT });

export const loadRateCodes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRateAccountingCodesPromise = doLoadRateCodes();
  loadRateAccountingCodesPromise
    .then((rateCodes: RateCodes[]) => dispatch(completeLoad(rateCodes)))
    .catch(() => dispatch(failLoad()));
  return loadRateAccountingCodesPromise;
};

export const saveRateCode = (formData: RateConfigurationAddRateFormValues) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveRateCodePromise = doSaveRateCode(formData);
  saveRateCodePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveRateCodePromise;
};

export const saveRateCodes =
  (formData: RateConfigurationFormValuesToSubmit[], serviceTypeId?: number) => (dispatch: Dispatch) => {
    dispatch(startSaveRateCodes());
    const saveRateCodesPromise = doSaveRateCodes(formData, serviceTypeId);
    saveRateCodesPromise.then(() => dispatch(completeSaveRateCodes())).catch(() => dispatch(failSaveRateCodes()));
    return saveRateCodesPromise;
  };

export const saveRateAccountingCode = (formData: RateConfigurationAddRateFormValues) => (dispatch: Dispatch) => {
  dispatch(startSaveRateAccountingCode());
  const saveRateAccountingCodePromise = doSaveRateAccountingCode(formData);
  saveRateAccountingCodePromise
    .then(() => dispatch(completeSaveRateAccountingCode()))
    .catch(() => dispatch(failSaveRateAccountingCode()));
  return saveRateAccountingCodePromise;
};

export const loadRateAccountingCodes = () => (dispatch: Dispatch) => {
  dispatch(startLoadRateAccountingCodes());
  const loadRateAccountingCodesPromise = doLoadRateAccountingCodes();
  loadRateAccountingCodesPromise
    .then((rateAccountingCodes: RateAccountingCode[]) => dispatch(completeLoadRateAccountingCodes(rateAccountingCodes)))
    .catch(() => dispatch(failLoadRateAccountingCodes()));
  return loadRateAccountingCodesPromise;
};

export const loadBillingUnitOfMeasureTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadUom());
  const loadBillingUnitOfMeasureTypesPromise = doLoadBillingUnitOfMeasureTypes();
  loadBillingUnitOfMeasureTypesPromise
    .then((billingUnitOfMeasureTypes: TechnicalType[]) => dispatch(completeLoadUom(billingUnitOfMeasureTypes)))
    .catch(() => dispatch(failLoadUom()));
  return loadBillingUnitOfMeasureTypesPromise;
};

export const exportRateConfiguration =
  (serviceTypeId?: number, searchText?: string, enabled?: boolean) => (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportPaymentsPromise = doExportRateConfiguration(serviceTypeId, searchText, enabled);
    exportPaymentsPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportPaymentsPromise;
  };

export const setChangedRateCodes = (changedRateCodes: ChangedRateCodes[]) => (dispatch: Dispatch) => {
  dispatch(setChangedRates(changedRateCodes));
};

export const uploadRateCodeImportFile = (fileData: File, vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadRateCodeImportFilePromise = doUploadRateCodeImportFile(fileData, vendorId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadRateCodeImportFilePromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeUpload(uploadedFiles));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadRateCodeImportFilePromise;
};

export const uploadAccountingCodeImportFile = (fileData: File, vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadRateCodeImportFilePromise = doUploadAccountingCodeImportFile(fileData, vendorId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadRateCodeImportFilePromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeUpload(uploadedFiles));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadRateCodeImportFilePromise;
};

export const uploadRateImportFile = (fileData: File, vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadRateCodeImportFilePromise = doUploadRateImportFile(fileData, vendorId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadRateCodeImportFilePromise
    .then((uploadedFiles: RateImportFile) => {
      dispatch(completeUpload(uploadedFiles.importsBatch));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadRateCodeImportFilePromise;
};

export const loadRateCodeImportUploadedFilesStatus = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRateImport());
  const loadRateCodeImportUploadedFilesStatusPromise = doLoadRateCodeImportUploadedFilesStatus(vendorId);

  loadRateCodeImportUploadedFilesStatusPromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeLoadRateImport(orderBy(uploadedFiles, 'date', 'desc')));
    })
    .catch(() => {
      dispatch(failLoadRateImport());
    });
  return loadRateCodeImportUploadedFilesStatusPromise;
};

export const loadAccountingCodeImportUploadedFilesStatus = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRateImport());
  const loadAccountingCodeImportUploadedFilesStatusPromise = doLoadAccountingCodeImportUploadedFilesStatus(vendorId);

  loadAccountingCodeImportUploadedFilesStatusPromise
    .then((uploadedFiles: CustomerImportFile[]) => {
      dispatch(completeLoadRateImport(orderBy(uploadedFiles, 'date', 'desc')));
    })
    .catch(() => {
      dispatch(failLoadRateImport());
    });
  return loadAccountingCodeImportUploadedFilesStatusPromise;
};

export const loadRateImportUploadedFilesStatus = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRateImport());
  const loadRateImportUploadedFilesStatusPromise = doLoadRateImportUploadedFilesStatus(vendorId);

  loadRateImportUploadedFilesStatusPromise
    .then((uploadedFiles: RateImportFile) => {
      dispatch(completeLoadRateImport(orderBy(uploadedFiles.importsBatch, 'date', 'desc')));
    })
    .catch(() => {
      dispatch(failLoadRateImport());
    });
  return loadRateImportUploadedFilesStatusPromise;
};
