import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import TripConfigurationModal from './TripConfigurationModal';

interface Props extends ModalProps {
  tripInspectionTypeId: number;
}

const TripConfigurationModalResolver: React.FC<Props> = ({ closeModal, featureCode, tripInspectionTypeId }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    return Promise.all([loadVehicleTypesForVendor(vendorId)(dispatch)]);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={TripConfigurationModal}
      successProps={{ closeModal, featureCode, tripInspectionTypeId, vendorId }}
    />
  );
};

export default TripConfigurationModalResolver;
