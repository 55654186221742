import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { BareButtonStyle } from '../../../core/components/styled';
import { Icon } from '../../../core/components';
import { loadingOverlay } from '../../../core/styles';

export const DashboardFilterCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 13px;
  height: 13px;
  margin-top: 4px;
`;

export const DashboardFilterClose = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  width: 40px;
  height: 40px;
  margin: 20px 0;
  border-radius: 50%;
  box-shadow: 0 0 6px ${props => transparentize(0.8, props.theme.grayBase)};
  color: ${props => props.theme.brandPrimary};
  background-color: #fff;

  &:active {
    box-shadow: inset 0 0 2px ${props => transparentize(0.8, props.theme.grayBase)};
  }
`;

export const DashboardFilterTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 24px;
  text-transform: capitalize;
`;

export const DashboardFilterSubtitle = styled.span`
  display: block;
  margin-bottom: 20px;
  line-height: 18px;
  font-size: 12px;
  color: ${props => props.theme.grayDarker};
`;

export const DashboardFilterListTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  padding: 0 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 9px;
  color: ${props => props.theme.grayDarker};
`;

interface DashboardFilterListItemProps {
  hasSubFilters?: boolean;
  isFieldDisabled?: boolean;
}

export const DashboardFilterListItem = styled.div<DashboardFilterListItemProps>`
  position: relative;
  margin-bottom: 10px;
  text-transform: capitalize;

  @media (max-width: ${props => props.theme.tabletBreakpoint}) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.hasSubFilters &&
    css`
      &::after {
        position: absolute;
        content: '+';
        top: -3px;
        margin-left: 5px;
        color: ${props.theme.brandPrimary};
      }
    `};

  ${props =>
    props.isFieldDisabled &&
    css`
      &::after {
        display: none;
      }
    `};
`;

export const DashboardFilterListItems = styled.div`
  padding: 0 20px;
`;

export const DashboardFilterListDisabledItem = styled.div<DashboardFilterListItemProps>`
  line-height: 16px;
  font-size: 12px;
  position: relative;
  padding-left: 23px;
  word-break: break-word;
  cursor: not-allowed;
  opacity: 0.5;

  &:before {
    content: '';
    width: 13px;
    height: 13px;
    display: inline-block;
    border: 1px solid rgb(217, 217, 225);
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
  }

  ${props =>
    props.hasSubFilters &&
    css`
      &::after {
        position: absolute;
        content: '+';
        top: -3px;
        margin-left: 5px;
        color: ${props.theme.brandPrimary};
      }
    `};
`;

interface DashboardFilterListProps {
  secondary?: boolean;
}

export const DashboardFilterList = styled.div<DashboardFilterListProps>`
  padding: 15px 0;

  &:not(:first-child) {
    padding-top: 0;
  }

  ${props =>
    props.secondary &&
    css`
      padding-top: 0;

      ${DashboardFilterListTitle} {
        background-color: ${props => props.theme.grayLighter};
      }
    `};
`;

export const DashboardFilterCategoryHeaderIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  position: absolute;
  top: 14px;
  right: 22px;
  width: 12px;
  height: 12px;
  color: ${props => transparentize(0.3, props.theme.brandPrimary)};
  transition: transform 0.3s ease-out;
`;

export const DashboardFilterCategoryHeader = styled.div`
  position: relative;
  padding: 12px 20px 10px;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  text-transform: capitalize;
  font-size: 13px;
  cursor: pointer;
`;

export const DashboardFilterCategoryBody = styled.div`
  overflow: hidden;
`;

interface DashboardFilterCategoryProps {
  isDisabled?: boolean;
  isOpen?: boolean;
}

export const DashboardFilterCategory = styled.div<DashboardFilterCategoryProps>`
  transition: background-color 0.3s ease-out;

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;

      ${DashboardFilterCategoryHeader} {
        color: ${props.theme.grayDark};
      }

      ${DashboardFilterCategoryHeaderIcon} {
        color: ${props => props.theme.grayDark};
      }
    `};

  ${props =>
    props.isOpen &&
    css`
      background-color: ${props => props.theme.grayLighter};

      ${DashboardFilterCategoryHeaderIcon} {
        transform: rotate(180deg);
      }
    `};
`;

export const DashboardFilterSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 12px;
  height: 12px;
  margin-top: 6px;
  color: ${props => props.theme.gray};
`;

export const DashboardFilterSearch = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`;

export const DashboardFilterTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
`;

export const DashboardFilterSection = styled.div`
  padding: 0 20px;
`;

export const DashboardVehiclesList = styled.div`
  margin: 20px 20px 0 20px;
  background: #f4f4f4;
  padding: 6px 10px 0 10px;
  height: calc(100% - 420px);
  min-height: 200px;
  overflow: scroll;
  cursor: pointer;

  &:hover {
    background: #f1f1f1;
  }
`;

export const DashboardVehiclesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: 0;
  }
`;

export const DashboardVehicleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

export const DashboardFilterPanelContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const DashboardFilterPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2200;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 3px 6px ${props => transparentize(0.9, props.theme.grayBase)};
`;

export const DashboardFilterSubPanel = styled(DashboardFilterPanel)`
  z-index: 2100;
  width: 322px;
  left: 295px;
  overflow-y: auto;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-out;
`;

interface DashboardFilterProps {
  isSubMenuHidden?: boolean;
  isOpen?: boolean;
  isLoading?: boolean;
}

export const DashboardFilter = styled.div<DashboardFilterProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2100;
  width: 295px;
  height: ${props => (props.isSubMenuHidden ? 'calc(100vh - 70px)' : 'calc(100vh - 140px)')};
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-out;

  ${props =>
    props.isOpen &&
    css`
      transform: none;

      ${DashboardFilterSubPanel} {
        transform: none;
      }
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px', '#fff', 0.05, 2300)};
    `};
`;
