import { http } from '../../core/services/http';
import { ContainerForReassignmentPayload } from '../interfaces/containers';

const facilityRateTypes = [
  {
    value: 1,
    label: 'Per Ton',
  },
  {
    value: 2,
    label: 'Per Pound',
  },
  {
    value: 3,
    label: 'Per Load',
  },
  {
    value: 4,
    label: 'Per Yard',
  },
];

export const loadFacilityRateTypes = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(facilityRateTypes);
    }, 2000);
  });

export const putFacility = (data: any) => http.put(`facilities/${data.id}`, data).then(response => response.data);

export const postFacility = (data: any) => http.post('/facilities', data).then(response => response.data);

export const loadFacilities = (vendorId: number) =>
  http.get('/facilities', { params: { vendorId } }).then(response => response.data);

export const loadFacilitiesByType = (vendorId: number, facilityTypeId: number, isActive?: boolean) =>
  http.get('/facilities', { params: { vendorId, facilityTypeId, isActive } }).then(response => response.data);

export const loadFacilityTypes = () => http.get('common/systemTypes/facilityTypes').then(response => response.data);

export const getFacilitySubType = (facilityTypeId: number) =>
  http.get('/common/systemTypes/facilitySubTypes', { params: { facilityTypeId } }).then(response => response.data);

export const deleteFacility = (id: number, relocateContainers?: ContainerForReassignmentPayload[]) =>
  http.delete(`/facilities/${id}`, { data: relocateContainers }).then(response => response.data);

export const loadMaterialTypes = () => http.get('V2/services/wasteTypes').then(response => response.data);

export const getFacility = (facilityId: number) =>
  http.get(`/facilities/${facilityId}`).then(response => response.data);

export const getAssignedContainers = (facilityId: number) =>
  http.get(`/facilities/${facilityId}/assignedContainers`).then(response => response.data);
