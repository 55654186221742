import React from 'react';

import { TooltipButtonTooltip, TooltipButtonTooltipProps, TooltipButtonBoxWrapper } from './styled/TooltipButtonStyles';
import translate from '../services/translate';
import { BoxProps } from './styled/Box';

interface Props extends BoxProps {
  tooltip?: string;
  tooltipAsString?: boolean;
  tooltipColor?: string;
  tooltipPosition?: TooltipButtonTooltipProps['position'];
  tooltipTranslationProps?: object;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Tooltip: React.FC<Props> = ({
  tooltip,
  tooltipTranslationProps,
  tooltipPosition,
  tooltipAsString,
  tooltipColor,
  children,
  onClick,
  ...boxProps
}) => (
  <TooltipButtonBoxWrapper {...boxProps} onClick={onClick}>
    {!!tooltip && (
      <TooltipButtonTooltip color={tooltipColor} position={tooltipPosition || 'top'}>
        {tooltipAsString ? tooltip : translate(`tooltips.${tooltip}`, tooltipTranslationProps)}
      </TooltipButtonTooltip>
    )}

    {children}
  </TooltipButtonBoxWrapper>
);

export default Tooltip;
