import React from 'react';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import { OFF_CURB, OFF_CURB_OBSTACLE } from '../constants';
import ServiceExceptionMarker, { ServiceException } from './ServiceExceptionMarker';
import { RubiconMarkerClusterer } from './RubiconMarkerClusterer';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';

interface Props {
  serviceExceptions: ServiceException[];
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string) => void;
  unitOfMeasure: string;
}

const ServiceExceptions: React.SFC<Props> = ({
  serviceExceptions,
  openedInfoWindows,
  toggleInfoWindow,
  unitOfMeasure,
}) => (
  <RubiconMarkerClusterer maxZoom={20}>
    {(clusterer: Clusterer) => (
      <>
        {serviceExceptions.map(serviceException => (
          <ServiceExceptionMarker
            key={serviceException.id}
            serviceException={serviceException}
            isInfoWindowOpen={openedInfoWindows.serviceExceptions[serviceException.id]}
            toggleInfoWindow={toggleInfoWindow}
            unitOfMeasure={unitOfMeasure}
            serviceMode={serviceException.serviceMode === OFF_CURB ? OFF_CURB_OBSTACLE : serviceException.serviceMode}
            clusterer={clusterer}
          />
        ))}
      </>
    )}
  </RubiconMarkerClusterer>
);

export default ServiceExceptions;
