import { Layer } from 'react-map-gl';
import { memo } from 'react';

import { SERVICE_CONTAINERS_CLUSTERS_SOURCE } from './ServiceContainersClustersSource';

export const SERVICE_CONTAINERS_LAYER = 'serviceContainersLayer';
export const SERVICE_CONTAINERS_NUMBERS_LAYER = 'serviceContainersNumbersLayer';

const ServiceContainersGL = memo(() => {
  return (
    <Layer
      id={SERVICE_CONTAINERS_LAYER}
      type="symbol"
      source={SERVICE_CONTAINERS_CLUSTERS_SOURCE}
      filter={['!', ['has', 'point_count']]}
      paint={{}}
      layout={{
        'icon-image': 'mapPin',
        'icon-allow-overlap': true,
      }}
    />
  );
});

export default ServiceContainersGL;
