import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { DARK, LIGHT, loading } from '../../../core/styles';
import { GridColumn } from '../../../core/components/styled';

export const CustomerLocationServices = styled.div`
  position: relative;
  display: flex;
  width: 290px;
  align-items: center;
`;

export const DaysOfServiceInfo = styled.span`
  width: 15px;
  height: 15px;
  cursor: help;
  margin-left: 10px;
`;

export const Loader = styled.div<{ light?: boolean }>`
  ${p => loading('14px', p.light ? LIGHT : DARK)};
`;

export const MoreServicesContainer = styled.span`
  margin-top: -10px;
`;

export const ExpandServices = styled.div`
  height: 100%;
  width: 15px;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 10px;
`;

export const ServicesContainer = styled.div<{
  withBorder?: boolean;
}>`
  padding: 0 10px;

  ${p =>
    p.withBorder &&
    css`
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-left: 3px solid ${p => p.theme.brandPrimary};
      border-bottom: 2px solid ${p => p.theme.brandPrimary};
    `}
`;

export const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
`;

export const PinOnMapContainer = styled(GridColumn)`
  display: flex;
  align-items: center;

  *:focus {
    outline: none;
  }
`;

export const ScheduleContainer = styled.div`
  width: 140px;
  margin: auto;
`;

export const FrequencyContainer = styled.div`
  display: flex;
`;

export const ServiceDetailsContainer = styled.div<{
  isHighlighted?: boolean;
  isParent?: boolean;
  isChildren?: boolean;
  childLevel?: number;
}>`
  height: 80px;
  display: flex;
  border-bottom: 1px solid ${p => p.theme.brandSecondary};
  padding: 10px;
  background-color: ${p => transparentize(0.7)(p.theme.brandSecondary)};

  ${p =>
    p.isHighlighted &&
    css`
      background-color: ${p => transparentize(0.85)(p.theme.brandPrimary)};
      border: 1px solid ${p => p.theme.brandPrimary};
      border-radius: 5px;
    `}
  ${p =>
    p.isParent &&
    css`
      border-left: 15px solid ${p => transparentize(0.8)(p.theme.brandInfoLight)};
    `}

    ${p =>
    p.isChildren &&
    css`
      background-color: ${p => transparentize(0.8)(p.theme.brandInfoLight)};
    `}

    ${p =>
    p.childLevel &&
    css`
      border-left: ${p.childLevel * 15}px solid ${p => transparentize(0.8)(p.theme.brandInfoLight)};
    `}
`;

export const ServiceRouteTemplateTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: ${props => props.theme.grayLighter};
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 9px;
  color: ${props => props.theme.grayBase};
`;

export const ServiceDetailsActions = styled.div`
  display: flex;
  width: 60px;
  min-width: 60px;
`;

export const PopoverDetailsContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PopoverDetailsContainer = styled.div`
  padding: 0 10px 10px 10px;
  flex: 1 0 50%;

  & > div {
    padding-top: 10px;
  }
`;
