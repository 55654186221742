import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, RouteComponentProps, RouteProps } from 'react-router';

import { AppState } from '../../store';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { getQueryParams } from '../../utils/services/queryParams';

export interface AuthRouteProps extends RouteComponentProps {
  isLoggedIn: boolean;
  component: RouteProps['component'];
  lastLocations?: Dictionary<string>;
}

interface QueryParams {
  passwordChangeRequired?: string;
}

const getRoute = (props: RouteComponentProps, Component: RouteProps['component'], isLoggedIn: boolean) => {
  const { passwordChangeRequired } = getQueryParams<QueryParams>(props.location.search);

  const redirectTo = {
    pathname: !!passwordChangeRequired ? '/account/change-password' : '/account/login',
    state: { from: props.location },
  };

  if (isLoggedIn && Component) {
    return <Component {...props} />;
  }

  return <Redirect to={redirectTo} />;
};

const AuthRoute: React.FC<AuthRouteProps & RouteProps> = ({ isLoggedIn, component, ...rest }) => (
  <Route {...rest} render={props => getRoute(props, component, isLoggedIn)} />
);

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.account.login.isLoggedIn,
});

export default withRouter(connect(mapStateToProps)(AuthRoute));
