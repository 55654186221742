import { createSelector } from 'reselect';
import { getQueryParams } from '../../utils/services/queryParams';
import { identity } from 'lodash-es';
import moment from 'moment';

const getReportingDataTrendsFormInitialValues = (queryString: string) => {
  const { vehicleTypeId, startDate, endDate, daysOfOperation } = getQueryParams(queryString);

  const defaultStartDate = moment(endDate, 'MM/DD/YYYY')
    .subtract(2, 'weeks')
    .format('MM/DD/YYYY');

  return {
    vehicleTypeId: Number(vehicleTypeId),
    dateRange: {
      from: startDate || defaultStartDate,
      to: endDate,
    },
    daysOfOperation,
  };
};

const reportingDataTrendsInitialValuesSelector = createSelector(
  getReportingDataTrendsFormInitialValues,
  identity,
);

export default reportingDataTrendsInitialValuesSelector;
