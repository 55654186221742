import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import translate from '../../../../core/services/translate';
import ExceptionManagerModalResolver from './ExceptionManagerModalResolver';
import { AppState } from '../../../../store';
import { exceptionsDispatcherEnabledSelector } from '../../../../vendors/ducks/features';
import { ExceptionDispatcherButton } from './Styled';

const ExceptionManager: React.SFC<{ isEnabled: boolean; isViewOnly: boolean; onClose: () => void }> = ({
  isEnabled,
  isViewOnly,
  onClose,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const prev = useRef<boolean>();

  useEffect(() => {
    if (!isModalOpen && prev.current) {
      onClose();
    }
    prev.current = isModalOpen;
  }, [isModalOpen, onClose]);

  if (!isEnabled) {
    return null;
  }

  return (
    <React.Fragment>
      <ExceptionDispatcherButton
        color="primary"
        line
        onClick={() => setIsModalOpen(true)}
        id="manage-exceptions-button"
        isViewOnly={isViewOnly}
      >
        {translate('dispatchBoard.exceptionsDispatcher.title')}
      </ExceptionDispatcherButton>
      {isModalOpen && <ExceptionManagerModalResolver onClose={() => setIsModalOpen(false)} />}
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  isEnabled: exceptionsDispatcherEnabledSelector(state),
});

export default connect(mapStateToProps)(ExceptionManager);
