import React, { useState } from 'react';
import ImageSlider from './ImageSlider';
import Modal from './Modal';
import ThumbnailSet from './ThumbnailSet';

export const ThumbnailSetImageSlider: React.FC<{
  images: string[];
  imagesToDownload: string[];
  size?: string;
}> = ({ images, imagesToDownload, size }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ThumbnailSet
        images={images}
        size={size}
        onClick={e => {
          e.stopPropagation();
          setIsModalOpen(true);
        }}
      />
      {isModalOpen && (
        <Modal
          onClose={e => {
            e.stopPropagation();
            setIsModalOpen(false);
          }}
        >
          <ImageSlider imageTitle="" images={images} imagesToDownload={imagesToDownload} />
        </Modal>
      )}
    </>
  );
};
