import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { push } from 'connected-react-router';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { loadFeatures, loadRouteSequenceSettings } from 'src/vendors/ducks';
import { loadRouteSequence, loadRouteSequenceHistory, loadRouteSequenceStatus, loadRouteStops } from 'src/routes/ducks';
import { loadRouteSummary } from 'src/routes/ducks/routeSummary';
import { loadWasteTypes } from 'src/common/ducks';
import { PageLoading } from 'src/common/components/styled';
import { SEQUENCE_SOURCE_TYPE_DAILY_ROUTE } from 'src/routes/constants';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import NewRouteSequenceEditorPage from './NewRouteSequenceEditorPage';
import translate from 'src/core/services/translate';

export type RouteSequencePageParams = {
  routeId?: string;
};

export default function NewRouteSequenceEditorPageResolver() {
  const dispatch = useDispatch();
  const params = useParams<RouteSequencePageParams>();
  const routeId = Number(params.routeId);
  const vendorId = useSelector(currentVendorId);
  const routeUrl = 'route-tracker';

  const loadDependencies = async () => {
    if (!isFinite(routeId)) return Promise.reject();

    const dependencies = [
      loadRouteSummary(vendorId, routeId)(dispatch),
      loadFeatures(vendorId)(dispatch),
      loadRouteSequence(
        routeId,
        SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
      )(dispatch).then(data => {
        if (!data || !data.sequence || !data.sequence.length) {
          dispatch(push(`/routes/${routeUrl}/${routeId}`));
        }
      }),
      loadRouteStops(vendorId, routeId)(dispatch),
      loadRouteSequenceHistory(routeId, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)(dispatch),
      loadRouteSequenceSettings(vendorId)(dispatch),
      loadWasteTypes()(dispatch),
      loadRouteSequenceStatus([routeId], SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('routes.routeDetails')}</DocumentTitle>

      <TypedResolver
        loadingComponent={PageLoading}
        redirectOnError="/routes/route-tracker"
        resolve={loadDependencies}
        successComponent={NewRouteSequenceEditorPage}
      />
    </>
  );
}
