import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadChangeRequestServiceOptions as doLoadChangeRequestServiceOptions } from '../services/serviceChangeRequests';

//  Actions
const START_LOAD = 'serviceChangeRequest/options/START_LOAD';
export const COMPLETE_LOAD = 'serviceChangeRequest/options/COMPLATE_LOAD';
const FAIL_LOAD = 'serviceChangeRequest/options/FAIL_LOAD';

interface ServiceOptionsState {
  isLoading: boolean;
  isSaving: boolean;
  equipmentSizes: any;
  frequencies: any[];
}
//  Initial State
const intialState: ServiceOptionsState = {
  isLoading: false,
  isSaving: false,
  equipmentSizes: undefined,
  frequencies: [],
};

//  Reducer
export const reducer = (state = intialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentSizes: action.serviceOptions.equipmentSizes,
          frequencies: action.serviceOptions.serviceFrequencies,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          equipmentSizes: undefined,
          frequencies: undefined,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceOptions: any) => ({
  type: COMPLETE_LOAD,
  serviceOptions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadChangeRequestServiceOptions = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadChangeRequestServiceOptions()
    .then(options => dispatch(completeLoad(options)))
    .catch(() => dispatch(failLoad()));
};
