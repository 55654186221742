import update from 'immutability-helper';
import { DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { AnyAction } from 'redux';

import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';

const SET_VIEWPORT = 'routes/mapControls/SET_VIEWPORT';
const SET_MAP_TRIGGER_VIEWPORT = 'routes/mapControls/SET_MAP_TRIGGER_VIEWPORT';
const SET_IS_DRAWING_MODE = 'routes/mapControls/SET_IS_DRAWING_MODE';
const SET_SELECTED_FEATURE = 'routes/mapControls/SET_SELECTED_FEATURE';
const CLEAR_SELECTED_FEATURE = 'routes/mapControls/CLEAR_SELECTED_FEATURE';
const SET_DEBUG_MODE = 'routes/mapControls/SET_DEBUG_MODE';
const TOGGLE_SPEED_COLOR = 'routes/mapControls/TOGGLE_SPEED_COLOR';
const RESET = 'routes/mapControls/RESET';

export enum RouteMapFeature {
  'applicationModeChanges',
  'cityInsights',
  'containerLocation',
  'facility',
  'routeSegments',
  'routeStops',
  'vehicleInsights',
  'vehiclePositions',
  'vehicleTrackings',
  'vehicleDebugTrackings',
  'vendorLocations',
  'triggers',
  'travelPath',
  'cityAlert',
}

type SelectedFeature = {
  feature: RouteMapFeature;
  id: number;
  coordinates?: {
    lat: number;
    lng: number;
  };
  vehicleTrackingCoordinateGroupIndex?: number;
  vehicleTrackingGroupIndex?: number;
  vehicleTrackingApplicationStatusIndex?: number;
  coordinateGroup?: string;
  noTableScroll?: boolean;
};

interface State {
  drawMode?: DrawPolygonMode | EditingMode;
  isDebugMode: boolean;
  isDrawingMode: boolean;
  mapTriggerViewport: MapGLViewport;
  polygon?: any;
  selectedFeature?: SelectedFeature;
  selectedFeatureForTravelPath?: SelectedFeature;
  sosAlertVehicleId?: number;
  spacing: number;
  viewport: MapGLViewport;
  showSpeedColors: boolean;
}

const initialState: State = {
  isDebugMode: false,
  isDrawingMode: false,
  mapTriggerViewport: {},
  spacing: 20,
  viewport: {},
  showSpeedColors: false,
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SELECTED_FEATURE:
      if (action.isForTravelPath) {
        return update(state, {
          $merge: {
            selectedFeatureForTravelPath: {
              feature: action.feature,
              id: action.id,
              ...action.additionalFields,
            },
          },
        });
      }
      return update(state, {
        $merge: {
          selectedFeature: {
            feature: action.feature,
            id: action.id,
            ...action.additionalFields,
          },
        },
      });

    case CLEAR_SELECTED_FEATURE:
      if (action.isForTravelPath) {
        return update(state, {
          $merge: {
            selectedFeatureForTravelPath: undefined,
          },
        });
      }
      return update(state, {
        $merge: {
          selectedFeature: undefined,
        },
      });

    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    case SET_MAP_TRIGGER_VIEWPORT:
      return update(state, {
        $merge: {
          mapTriggerViewport: action.mapTriggerViewport,
        },
      });

    case SET_DEBUG_MODE:
      return update(state, {
        $merge: {
          isDebugMode: action.isDebugMode,
        },
      });

    case SET_IS_DRAWING_MODE:
      return update(state, {
        $merge: {
          isDrawingMode: action.isDrawingMode,
        },
      });

    case TOGGLE_SPEED_COLOR:
      return update(state, {
        $merge: {
          showSpeedColors: !state.showSpeedColors,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export const setRouteMapViewport = (viewport: MapGLViewport) => {
  return {
    type: SET_VIEWPORT,
    viewport,
  };
};

export const toggleSpeedColor = () => ({
  type: TOGGLE_SPEED_COLOR,
});

export const setTriggerMapViewport = (mapTriggerViewport: MapGLViewport) => {
  return {
    type: SET_MAP_TRIGGER_VIEWPORT,
    mapTriggerViewport,
  };
};

export const setRouteMapSelectedFeature = (
  feature: RouteMapFeature,
  id: number,
  additionalFields: Partial<Omit<SelectedFeature, 'feature' | 'id'>> = {},
  isForTravelPath?: boolean,
) => ({
  type: SET_SELECTED_FEATURE,
  feature,
  id,
  additionalFields,
  isForTravelPath,
});

export const clearRouteMapSelectedFeature = (isForTravelPath?: boolean) => ({
  type: CLEAR_SELECTED_FEATURE,
  isForTravelPath,
});

export const setDebugMode = (isDebugMode: boolean) => ({
  type: SET_DEBUG_MODE,
  isDebugMode,
});

export const resetRouteMapControls = () => ({
  type: RESET,
});

export const setIsDrawingMode = (isDrawingMode: boolean) => ({
  type: SET_IS_DRAWING_MODE,
  isDrawingMode,
});
