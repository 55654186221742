import { useState } from 'react';
import { camelCase } from 'lodash-es';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store';
import { date } from '../../../utils/services/formatter';
import { ActionButtonTooltip, Modal, Table } from '../../../core/components';
import { ModalSection, TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';
import RubiconServicesTicketNotesModal from './RubiconServicesTicketNotesModal';
import { PopoverDetailsContainer } from '../styled/CustomerLocations';
import translate from 'src/core/services/translate';

interface Props {
  dateSubmitted: string;
  notes: string[];
  serviceOrder: string;
  status: string;
}

const rubiconServicesTicketTableCells = [
  { name: 'serviceOrder', label: translate('routes.ticketNumber'), width: '30%' },
  { name: 'dateSubmitted', label: translate('customers.rubiconService.submittedDate'), width: '30%' },
  { name: 'status', label: translate('common.status'), width: '25%' },
  { name: 'notes', label: translate('common.note'), width: '15%' },
];

const RubiconServicesTicketsTableRow: React.FC<Props> = ({ dateSubmitted, notes, serviceOrder, status }: Props) => {
  const [isTicketNotesModalOpen, openTicketNoteModal] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell width="30%">
          <Text block>{serviceOrder}</Text>
        </TableCell>
        <TableCell width="30%">
          <Text block>{date(dateSubmitted)}</Text>
        </TableCell>

        <TableCell width="25%">
          <Text block>{translate(`common.${camelCase(status)}`)}</Text>
        </TableCell>
        {notes.length > 0 && (
          <TableCell width="15%">
            <TableActionButton onClick={() => openTicketNoteModal(true)}>
              <ActionButtonTooltip size="medium" icon="page-content" color="primary" tooltip="viewNote" />
            </TableActionButton>
          </TableCell>
        )}
      </TableRow>
      {isTicketNotesModalOpen && (
        <RubiconServicesTicketNotesModal closeModal={() => openTicketNoteModal(false)} notes={notes} />
      )}
    </>
  );
};

interface Props {
  closeModal: (pristine?: boolean) => void;
  customerName: String;
  location: string;
  serviceId: number;
}
export default function RubiconServicesTicketStatusModal({ closeModal, customerName, location, serviceId }: Props) {
  const submiitedTickets = useSelector(
    (state: AppState) => state.customers.rubiconServices.rubiconServicesSubmittedTickets,
  );

  return (
    <Modal size="medium" onClose={closeModal}>
      <ModalSection margin="medium no no large" align="left">
        <PopoverDetailsContainer>
          <Text block align="center" weight="medium" size="medium" margin="no no xSmall" singleLine>
            {translate('customers.serviceId')}: {serviceId}
          </Text>
          <Text block align="center" weight="medium" size="medium" margin="no no xSmall" singleLine>
            {translate('common.customer')}: {customerName}
          </Text>
          <Text block align="center" weight="medium" margin="no no xSmall" singleLine>
            {translate('common.location')}: : {location}
          </Text>
        </PopoverDetailsContainer>
      </ModalSection>
      <ModalSection margin="medium no no" align="center">
        <Table
          cells={rubiconServicesTicketTableCells}
          rows={submiitedTickets}
          rowComponent={RubiconServicesTicketsTableRow}
        />
      </ModalSection>
    </Modal>
  );
}
