import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { debounce, some, xor } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WebMercatorViewport } from 'react-map-gl';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { loadGeoFences, resetGeoFences } from 'src/routes/ducks';
import {
  MapFilters,
  clearSelectedServiceContractFeature,
  setMapDrawingEnabled,
  setSelectedServiceContractFeature,
  setSelectedServiceContracts,
} from 'src/routes/ducks/routeTemplateBuilder';
import useServicesAvailableForSelection from 'src/routes/hooks/useServicesAvailableForSelection';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { MapGL } from '../../../../../common/components/map/MapGL';
import { getMapBounds } from '../../../../../common/components/map/util';
import { IconButtonIcon } from '../../../../../core/components/styled/Button';
import TooltipIconButton from '../../../../../core/components/TooltipIconButton';
import translate from '../../../../../core/services/translate';
import { SOURCE_UNIQUE_KEY, UNASSIGNED_FILTER } from '../../../../constants/routeTemplateBuilder';
import { RouteTemplateBuilderService } from '../../../../interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { ComplexMapControl } from '../../../styled/RouteMap';
import { Wrapper } from '../../../styled/RouteTemplateBuilderMap';
import OnMapFiltersForm, {
  OnMapFiltersFormValues,
} from '../../dispatchBoard/dispatchBoardMapSections/map/OnMapFiltersForm';
import RouteMapGeoFencesGL from '../../routes/routePageSections/routeMap/geoFences/RouteMapGeoFencesGL';
import RouteTemplateBuilderAssignToRouteModalResolver from '../routeTemplateBuilderModals/RouteTemplateBuilderAssignToRouteModalResolver';
import RouteTemplateBuilderCreateRouteModalResolver from '../routeTemplateBuilderModals/RouteTemplateBuilderCreateRouteModalResolver';
import RouteTemplateBuilderStopCountsPerDay from '../routeTemplateBuilderSections/RouteTemplateBuilderStopCountsPerDay';
import { DrawingInstructions } from './DrawingInstructions';
import { RouteTemplateBuilderChangeLog } from './RouteTemplateBuilderChangeLog';
import { RouteTemplateBuilderLegend } from './RouteTemplateBuilderLegend';
import { RouteTemplateBuilderMapServiceActions } from './RouteTemplateBuilderMapServiceActions';
import { RouteTemplateBuilderServiceMarkerSource } from './RouteTemplateBuilderServiceMarkerSource';
import RouteTemplateBuilderServicePopupResolver from './RouteTemplateBuilderServicePopupResolver';

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

interface Props {
  hidden?: boolean;
  currentFilters?: MapFilters;
}

export const RouteTemplateBuilderMapGL: React.FC<Props> = ({ hidden, currentFilters }) => {
  const vendorId = useSelector(currentVendorId);

  const editorRef = useRef<Editor>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isMapFiltersOpen, setIsMapFiltersOpen] = useState(false);
  const [map, setMap] = useState<mapboxgl.Map>();
  const [assignToRouteModalVisible, setAssignToRouteModalVisible] = useState<boolean>(false);
  const [createRouteModalVisible, setCreateRouteModalVisible] = useState<boolean>(false);
  const [editPolygon, setEditPolygon] = useState<boolean>(true);
  const [actionsSectionHeight, setActionsSectionHeight] = useState<number>(0);
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [polygon, setPolygon] = useState<any>();
  const [bounds, setBounds] = useState<Partial<WebMercatorViewport>>({});
  const [isSatelliteView, setIsSatelliteView] = useState<boolean>(false);

  const dispatch = useDispatch();
  const selectedDayOfServiceIdsOnMainFilter = useSelector(
    state => state.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds,
  );
  const selectedRouteTemplateIds = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.routeTemplateIds);

  const mapDrawingEnabled = useSelector(state => state.routes.routeTemplateBuilder.mapDrawingEnabled);
  const fullScreen = useSelector(state => state.routes.routeTemplateBuilder.fullScreen);
  const selectedPoints = useSelector(state => state.routes.routeTemplateBuilder.selectedServiceContracts);
  const selectedServiceContractFeature = useSelector(
    state => state.routes.routeTemplateBuilder.selectedServiceContractFeature,
  );

  const selectedDraftId = useSelector(state => state.routes.routeTemplateBuilder.selectedDraftId);
  const servicesAvailableForSelection = useServicesAvailableForSelection();
  const stopFilterType = useSelector(state => state.routes.routeTemplateBuilder.mapFilters.stopFilterType);

  const [lastServicesAvailableForSelection, setLastServicesAvailableForSelection] =
    useState(servicesAvailableForSelection);

  const { geoFences } = useSelector(state => state.routes.geoFences.geoFences);

  const geoFencesGeoJSON = useMemo(() => {
    const allGeoFencesGeoJSON = (geoFences || []).map((geoFence: any) => {
      const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
      return {
        ...parsedGeoFenceJson,
        id: geoFence.id,
        properties: {
          ...parsedGeoFenceJson?.properties,
          name: geoFence.geoFenceName,
        },
      };
    });
    return allGeoFencesGeoJSON;
  }, [geoFences]);

  const handleSubmitGeoFenceFilters = (formData: OnMapFiltersFormValues) => {
    const normalizedGeoFencesIds = normalizeFilterArray(formData.geoFenceSubFilters);
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);
    dispatch(resetGeoFences());
    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      dispatch(
        loadGeoFences({
          vendorId,
          geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
          limit: 200,
          geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
        }),
      );
    }
    setIsMapFiltersOpen(false);
  };

  const setSelectedPoints = React.useCallback(
    (services: RouteTemplateBuilderService[]) => {
      dispatch(setSelectedServiceContracts(services));
    },
    [dispatch],
  );

  const selectedPointsIds = selectedPoints.map(p => p.id);

  const selectedPointsContainAlreadyAssignedStops =
    !!selectedDraftId ||
    (stopFilterType !== UNASSIGNED_FILTER &&
      some(selectedPoints, p =>
        some(
          (p.daysOfService || []).filter(d => selectedDayOfServiceIdsOnMainFilter.indexOf(d.id) !== -1),
          d => !!d.draftRouteTemplateId || !!d.routeTemplateId,
        ),
      ));

  const selectPointsInPolygon = React.useCallback(
    (points: RouteTemplateBuilderService[], newPolygon: any) => {
      const includedPoints = points.filter(p => booleanPointInPolygon([p.longitude, p.latitude], newPolygon));

      setSelectedPoints(includedPoints);
    },
    [setSelectedPoints],
  );

  const selectPointsInPolygonDebouncedButNotTyped = React.useMemo(
    () => debounce(selectPointsInPolygon, 200),
    [selectPointsInPolygon],
  );

  const selectPointsInPolygonDebounced = React.useCallback(selectPointsInPolygonDebouncedButNotTyped, [
    selectPointsInPolygonDebouncedButNotTyped,
  ]);

  const checkLocationsInPolygon = (event: any) => {
    const newPolygon = event.data[0];

    setPolygon(newPolygon);

    if (event.editType === 'addFeature') {
      selectPointsInPolygon(servicesAvailableForSelection, newPolygon);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(servicesAvailableForSelection, newPolygon);
    }
  };

  const confirmAssignment = async (callback: () => void) => {
    if (
      selectedPointsContainAlreadyAssignedStops &&
      !(await confirm(
        translate('routeTemplateBuilder.assignedStopsNoticeTitle'),
        translate('routeTemplateBuilder.assignedStopsNotice'),
      ))
    ) {
      return;
    }

    callback();
  };

  useEffect(() => {
    if (
      servicesAvailableForSelection.length &&
      xor(
        servicesAvailableForSelection.map(s => s.id),
        lastServicesAvailableForSelection.map(s => s.id),
      ).length
    ) {
      const bounds = getMapBounds(servicesAvailableForSelection, {
        width: wrapperRef.current?.offsetWidth,
        height: wrapperRef.current?.offsetHeight,
        padding: 150,
        capZoom: 20,
      });
      const zoom = bounds?.zoom || 0;

      setBounds({
        latitude: bounds.latitude as number,
        longitude: bounds.longitude as number,
        zoom,
      });
      setLastServicesAvailableForSelection(servicesAvailableForSelection);
    }
  }, [servicesAvailableForSelection, wrapperRef, lastServicesAvailableForSelection]);

  useEffect(() => {
    if (!selectedPoints.length) {
      setActionsSectionHeight(0);
    }
  }, [selectedPoints]);

  useEffect(() => {
    if (mapDrawingEnabled) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
      }
    } else {
      if (polygon && editorRef.current) {
        editorRef.current.deleteFeatures(0);
        setSelectedPoints([]);
        setPolygon(undefined);
      }

      if (!!drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [mapDrawingEnabled, drawMode, polygon, setSelectedPoints]);

  useEffect(() => {
    if (!map) {
      return;
    }

    map.on('click', event => {
      const features = map.queryRenderedFeatures(event.point).filter(feature => feature.source === SOURCE_UNIQUE_KEY);

      if (features.length) {
        dispatch(setSelectedServiceContractFeature(features[0]));
      }
    });

    map.on('mousemove', event => {
      const features = map.queryRenderedFeatures(event.point).filter(feature => feature.source === SOURCE_UNIQUE_KEY);

      map.getCanvas().style.cursor = features.length ? 'pointer' : '';
    });

    map.on('mouseleave', () => {
      map.getCanvas().style.cursor = '';
    });
  }, [map, dispatch, geoFences]);

  return (
    <Wrapper
      ref={wrapperRef}
      drawingEnabled={!!drawMode && editPolygon}
      isDrawing={!!drawMode && !polygon}
      hidden={hidden}
    >
      <MapGL
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        bounds={bounds}
        onMapRefLoaded={setMap}
        navigationControlYOffset={actionsSectionHeight}
        setIsSatelliteViewEnabled={setIsSatelliteView}
      >
        {!!drawMode && (
          <Editor
            ref={editorRef}
            clickRadius={12}
            mode={drawMode}
            onUpdate={checkLocationsInPolygon}
            features={polygon ? [polygon] : []}
          />
        )}

        {map && (
          <>
            <RouteMapGeoFencesGL map={map} geoFencesGeoJSON={geoFencesGeoJSON} isSatellite={isSatelliteView} />
            <RouteTemplateBuilderServiceMarkerSource
              map={map}
              services={servicesAvailableForSelection}
              selectedPoints={selectedPointsIds}
              selectedRouteTemplateIds={selectedRouteTemplateIds}
              isSatelliteView={isSatelliteView}
            />
          </>
        )}

        <OnMapFiltersForm
          isOnMapFiltersFormValuesOpen={isMapFiltersOpen}
          closeRouteMapFilters={() => setIsMapFiltersOpen(false)}
          initialValues={{ geoFencesTypesFilters: [], geoFenceSubFilters: [], geoFenceSearchTerm: '' }}
          onSubmit={handleSubmitGeoFenceFilters}
          hideDailyRouteGeoFence
        />

        {selectedServiceContractFeature && (
          <RouteTemplateBuilderServicePopupResolver
            feature={selectedServiceContractFeature}
            onClose={() => dispatch(clearSelectedServiceContractFeature())}
          />
        )}
      </MapGL>

      {drawMode && <DrawingInstructions />}

      <ComplexMapControl vertical position="top-left">
        <TooltipIconButton
          tooltip="mapFilters"
          tooltipPosition="right"
          tooltipColor="grayDarker"
          color="secondary"
          margin="no"
          onClick={() => setIsMapFiltersOpen(true)}
        >
          <IconButtonIcon icon="mapFilter" color="primary" />
        </TooltipIconButton>
        <TooltipIconButton
          tooltipAsString
          tooltip={translate(drawMode ? 'routeTemplateBuilder.deletePolygon' : 'routeTemplateBuilder.drawPolygon')}
          tooltipPosition="right"
          tooltipColor="grayDarker"
          margin="small no no"
          color={drawMode ? 'warning' : 'secondary'}
          onClick={() => {
            setEditPolygon(true);
            dispatch(setMapDrawingEnabled(!mapDrawingEnabled));
          }}
        >
          <IconButtonIcon margin="no" icon={drawMode ? 'delete' : 'highlight'} />
        </TooltipIconButton>

        {polygon && (
          <TooltipIconButton
            tooltipAsString
            tooltip={translate(
              editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
            )}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            margin="small no"
            color={editPolygon ? 'primary' : 'secondary'}
            onClick={() => {
              setEditPolygon(!editPolygon);

              if (!editPolygon) {
                dispatch(clearSelectedServiceContractFeature());
              }
            }}
          >
            <IconButtonIcon margin="no" icon="edit" />
          </TooltipIconButton>
        )}
      </ComplexMapControl>

      <ComplexMapControl vertical position="top-right">
        <RouteTemplateBuilderChangeLog />
      </ComplexMapControl>

      <ComplexMapControl vertical position="bottom-left" xOffset={60}>
        <RouteTemplateBuilderStopCountsPerDay services={servicesAvailableForSelection} />
      </ComplexMapControl>

      <ComplexMapControl
        vertical
        position="bottom-right"
        xOffset={fullScreen ? 34 : undefined}
        yOffset={actionsSectionHeight}
      >
        <RouteTemplateBuilderLegend services={servicesAvailableForSelection} isSatelliteView={isSatelliteView} />
      </ComplexMapControl>

      {!!selectedPoints.length && (
        <RouteTemplateBuilderMapServiceActions
          services={selectedPoints}
          onAssignToExistingRouteClick={() => confirmAssignment(() => setAssignToRouteModalVisible(true))}
          onAssignToNewRouteClick={() => confirmAssignment(() => setCreateRouteModalVisible(true))}
          onSectionHeightUpdate={setActionsSectionHeight}
        />
      )}

      {assignToRouteModalVisible && (
        <RouteTemplateBuilderAssignToRouteModalResolver
          hasAssignedStops={selectedPointsContainAlreadyAssignedStops}
          services={selectedPoints}
          onClose={(actionSuccessful = false) => {
            setAssignToRouteModalVisible(false);

            if (actionSuccessful) {
              dispatch(setMapDrawingEnabled(false));
            }
          }}
        />
      )}

      {createRouteModalVisible && (
        <RouteTemplateBuilderCreateRouteModalResolver
          hasAssignedStops={selectedPointsContainAlreadyAssignedStops}
          services={selectedPoints}
          onClose={(actionSuccessful = false) => {
            setCreateRouteModalVisible(false);

            if (actionSuccessful) {
              dispatch(setMapDrawingEnabled(false));
            }
          }}
        />
      )}
    </Wrapper>
  );
};
