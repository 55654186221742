import { Vehicle } from '../../../interfaces/Vehicles';
import { SmartFilters } from '../../../../common/interfaces/SmartFilters';
import translate from '../../../../core/services/translate';
import { PM_STATUS_ALMOST_DUE, PM_STATUS_DUE, PM_STATUS_PAST_DUE } from '../../../constants/preventativeMaintenance';

const dynamicSubtitle = (count = 0) => {
  return count === 1 ? '1 Vehicle' : `${count} Vehicles`;
};

export const vehiclesSmartFilters = (vendorHasActivePM: boolean = false): SmartFilters<Vehicle> => {
  const filters: SmartFilters<Vehicle> = [
    {
      id: 0,
      title: translate('vehicles.smartFilters.allVehicles'),
      dynamicSubtitle,
      isActive: true,
      filter() {
        return true;
      },
    },
    {
      id: 1,
      title: translate('vehicles.smartFilters.vehiclesWithOpenIssues'),
      dynamicSubtitle,
      filter(vehicle) {
        return !!vehicle.numberOfOpenIssues;
      },
    },
    {
      id: 2,
      title: translate('vehicles.smartFilters.vehiclesWithPendingIssues'),
      dynamicSubtitle,
      filter(vehicle) {
        return !!vehicle.numberOfPendingIssues;
      },
    },
    {
      id: 3,
      title: translate('vehicles.smartFilters.vehiclesAlmostDueForPM'),
      dynamicSubtitle,
      filter(vehicle) {
        return (
          !!vehicle.preventativeMaintenance &&
          vehicle.preventativeMaintenance.preventativeMaintenanceStatusType.id === PM_STATUS_ALMOST_DUE
        );
      },
    },
    {
      id: 4,
      title: translate('vehicles.smartFilters.vehiclesDueForPM'),
      dynamicSubtitle,
      filter(vehicle) {
        return (
          !!vehicle.preventativeMaintenance &&
          vehicle.preventativeMaintenance.preventativeMaintenanceStatusType.id === PM_STATUS_DUE
        );
      },
    },
    {
      id: 5,
      title: translate('vehicles.smartFilters.vehiclesPastDueForPM'),
      dynamicSubtitle,
      filter(vehicle) {
        return (
          !!vehicle.preventativeMaintenance &&
          vehicle.preventativeMaintenance.preventativeMaintenanceStatusType.id === PM_STATUS_PAST_DUE
        );
      },
    },
  ];

  if (!vendorHasActivePM) {
    return filters.slice(0, 3);
  }

  return filters;
};
