import { camelize } from 'humps';
import { map, upperFirst, groupBy, keys } from 'lodash-es';

import { BULK, PORTABLE_TOILET, PORTABLE_RESTROOM_TRAILER, LOCATION_CUSTOMER_TYPE_ID } from '../../common/constants';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const getEquipmentSize = (equipmentSize: string, equipmentName: string) =>
  equipmentName === PORTABLE_RESTROOM_TRAILER ? equipmentSize.match(/\d+/g) : parseFloat(equipmentSize);

const getEquipmentSizeTranslationKey = (equipmentSize: string, serviceTypeName: string, equipmentName: string) => {
  let equipmentSizesTranslationName;

  if (serviceTypeName === BULK || serviceTypeName === PORTABLE_TOILET) {
    if (equipmentName === PORTABLE_RESTROOM_TRAILER) {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${upperFirst(
        camelize(equipmentSize.replace(new RegExp('([0-9]*[.])?[0-9]+', 'g'), '')),
      )}`;
    } else {
      equipmentSizesTranslationName = `common.equipmentSizeShortCodes.${camelize(equipmentSize)}`;
    }
  } else {
    equipmentSizesTranslationName = `common.equipmentSizeShortCodes.x${equipmentSize.replace(
      new RegExp('([0-9]*[.])?[0-9]+', 'g'),
      '',
    )}`;
  }

  return equipmentSizesTranslationName;
};

export const getServiceName = (
  equipmentTypeTechnicalName?: string,
  equipmentTypeName?: string,
  equipmentSizeTechnicalName?: string,
  equipmentSizeName?: string,
  serviceTypeName?: string,
  wasteMaterialTypeTechnicalName?: string,
) => {
  const equipmentTypeShortCode = equipmentTypeTechnicalName
    ? translate(createTranslationKey(equipmentTypeTechnicalName, 'common.equipmentTypeShortCodes'))
    : '';
  const equipmentSizeShortCode =
    equipmentTypeTechnicalName &&
    equipmentTypeName &&
    equipmentSizeTechnicalName &&
    equipmentSizeName &&
    serviceTypeName
      ? translate(getEquipmentSizeTranslationKey(equipmentSizeTechnicalName, serviceTypeName, equipmentTypeName), {
          size: getEquipmentSize(equipmentSizeName, equipmentSizeTechnicalName),
        })
      : '';
  const wasteMaterialTypeName = wasteMaterialTypeTechnicalName
    ? translate(createTranslationKey(wasteMaterialTypeTechnicalName, 'common.wasteTypes'))
    : '';
  return `${equipmentSizeShortCode} ${equipmentTypeShortCode ? '-' : ''} ${equipmentTypeShortCode} ${
    wasteMaterialTypeName ? '- ' : ''
  } ${wasteMaterialTypeName}`;
};

export const transformContainersLocationsForCreateEdit = (data: any) => {
  const result = [] as any[];
  map(data, el => {
    if (el.containerLocationType.id === LOCATION_CUSTOMER_TYPE_ID) {
      const groupByCustomer = groupBy(el.locations, 'customerId');
      map(keys(groupByCustomer), customerKey => {
        const customerNameLabel = groupByCustomer[customerKey][0]?.customerName;
        const locationsGroupedByLocationId = groupBy(groupByCustomer[customerKey], 'locationId');
        map(keys(locationsGroupedByLocationId), locationKey => {
          const locationNameLabel = locationsGroupedByLocationId[locationKey][0]?.locationName;
          const locationAddressLabel = locationsGroupedByLocationId[locationKey][0]?.address;
          const serviceOptions = map(locationsGroupedByLocationId[locationKey], service => {
            const serviceNameLabel = getServiceName(
              service.equipmentType?.technicalName,
              service.equipmentType?.name,
              service.equipmentSize?.technicalName,
              service.equipmentSize?.name,
              service.serviceType?.name,
              service.wasteMaterialType?.technicalName,
            );
            return {
              label: serviceNameLabel,
              value: { locationId: service.locationId, serviceContractId: service.serviceContractId },
            };
          });
          const label = `${customerNameLabel} > ${locationNameLabel} > ${locationAddressLabel}`;
          result.push({ label, options: serviceOptions });
        });
      });
    } else
      result.push({
        label: el.containerLocationType.name,
        options: map(el.locations, loc => ({ label: loc.locationName, value: loc.locationId })),
      });
  });
  return result;
};
