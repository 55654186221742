import styled, { css } from 'styled-components';
import { FormError } from '../../../core/components/styled';

interface Props {
  isPinOnMapVisible?: boolean;
}

export const ProximitySearchMapContainer = styled.div<Props>`
  height: ${props => (props.isPinOnMapVisible ? '425px' : '300px')};
  width: 100%;

  *:focus {
    outline: none;
  }

  ${props =>
    props.isPinOnMapVisible &&
    css`
      margin-top: -124px;
    `};

  ${FormError} {
    position: absolute;
    font-size: 11px;
    margin-top: 2px;
  }
`;

export const ProximitySearchForm = styled.div<Props>`
  position: relative;
  z-index: 3;

  ${props =>
    props.isPinOnMapVisible &&
    css`
      margin-top: 52px;
    `};

  ${FormError} {
    position: absolute;
    font-size: 11px;
    margin-top: 2px;
  }
`;
