import React, { useCallback, useEffect, useState } from 'react';

import { filter, includes, intersection, remove, uniq, uniqBy, values } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { deleteCollectionPoints } from 'src/customers/ducks';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import DocumentTitle from '../../../../common/components/DocumentTitle';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import {
  BinLocationMappingTypeId,
  COLLECTION_POINT,
  CURBSIDE_LOOKUP,
  LOCATION_ADDRESS,
  SET_BY_USER,
} from '../../../../common/constants/binMappingTypes';
import { Icon } from '../../../../core/components';
import { Button, Panel } from '../../../../core/components/styled';
import confirm from '../../../../core/services/confirm';
import { createSuccessNotification } from '../../../../core/services/createNotification';
import translate from '../../../../core/services/translate';
import { RESIDENTIAL_ID } from '../../../../fleet/constants';
import { AppState } from '../../../../store';
import { useStateRef } from '../../../../utils/hooks';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { CollectionPoint } from '../../../interfaces/CollectionPoint';
import { DirtyCollectionPoint } from '../../../interfaces/DirtyCollectionPoint';
import { DirtyPickupLocation } from '../../../interfaces/DirtyPickupLocation';
import { getCollectionPointsByMapBounds } from '../../../services/collectionPoints';
import { updatePickupLocations } from '../../../services/pickupLocations';
import { RouteLocationsLoader } from '../../RouteLocationsLoader';
import { ActiveLocationIndexesInfo, DirtyPickupLocationsDictionaryWrapper } from '../../styled';
import { PickupEditorModeToggle } from './PickupEditorModeToggle';
import { PickupLocationEditorInstructions } from './PickupLocationEditorInstructions';
import PickupLocationsEditorMapGL from './PickupLocationsEditorMapGL';
import { RouteTemplate } from 'src/routes/interfaces/RouteTemplates';
import { CollectionPointsLoadingContainer } from '../../styled/DirtyPickupLocationsDictionary';

export type DirtyPickupLocationsDictionary = { [routeLocationId: string]: DirtyPickupLocation };

const PickupLocationsEditorPage: React.FC<RouteComponentProps<{ routeTemplateId: string }>> = ({
  match: {
    params: { routeTemplateId },
  },
  history,
}) => {
  const dispatch = useDispatch();

  const [isEditingCollectionPoints, setIsEditingCollectionPoints] = useState(false);
  const [collectionPoints, setCollectionPoints, collectionPointsRef] = useStateRef<CollectionPoint[]>([]);

  const routeTemplateState = useSelector((state: AppState) => state.routes.routeTemplate);
  const routeTemplate = useSelector((state: AppState) => state.routes.routeTemplate.routeTemplate) as RouteTemplate;

  const isResidential = routeTemplate.vehicleTypeId === RESIDENTIAL_ID;

  const vendorId = useSelector((state: AppState) => currentVendorId(state)) as number;

  const [isLoading, setIsLoading] = useState(false);

  const [dirtyPickupLocations, setDirtyPickupLocations] = useState<DirtyPickupLocationsDictionary>({});

  const [activeRouteLocationIndexes, setActiveRouteLocationIndexes] = useState<number[]>([]);

  const [activeCollectionPoint, setActiveCollectionPoint] = useState<CollectionPoint | undefined>();

  const [collectionPointsToDelete, setCollectionPointsToDelete] = useState<(number | string)[]>([]);

  const [modifiedCollectionPoints, setModifiedCollectionPoints] = useState<DirtyCollectionPoint[]>([]);

  const [isLoadingCollectionPoints, setIsLoadingCollectionPoints] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (
      Object.keys(dirtyPickupLocations).length ||
      modifiedCollectionPoints.length ||
      collectionPointsToDelete.length
    ) {
      setIsDirty(true);
    }
  }, [dirtyPickupLocations, modifiedCollectionPoints, collectionPointsToDelete]);

  const getContainer = (routeLocationIndex: number) =>
    routeTemplate.routeLocations[routeLocationIndex].service.serviceContractBinDetails[0];

  const fetchCollectionPoints = (
    vendorId: number,
    sw: any,
    ne: any,
    routeTemplateId: number,
    updateCollectionPoints: (newCollectionPoints: CollectionPoint[]) => void,
  ) => {
    setIsLoadingCollectionPoints(true);
    getCollectionPointsByMapBounds(vendorId, ne, sw, routeTemplateId)
      .then(updateCollectionPoints)
      .finally(() => setIsLoadingCollectionPoints(false));
  };

  const switchEditMode = (editCollectionPoints: boolean) => {
    if (!editCollectionPoints) {
      setActiveCollectionPoint(undefined);
      setActiveRouteLocationIndexes([]);
    }
    setIsEditingCollectionPoints(editCollectionPoints);
  };

  const updateCollectionPoints = useCallback(
    (newCollectionPoints: CollectionPoint[]) => {
      const allCollectionPoints = uniqBy([...collectionPointsRef.current, ...newCollectionPoints], 'id');
      if (collectionPointsRef.current.length !== allCollectionPoints.length) {
        setCollectionPoints(allCollectionPoints);
      }
    },
    [collectionPointsRef, setCollectionPoints],
  );

  const onBoundsChanged = useCallback(
    (ne: any, sw: any) => {
      if (isResidential) fetchCollectionPoints(vendorId, sw, ne, routeTemplate.id, updateCollectionPoints);
    },
    [isResidential, routeTemplate.id, updateCollectionPoints, vendorId],
  );

  const addressChange = (
    routeLocationIndex: number,
    serviceContractId: number,
    latitude: number,
    longitude: number,
  ) => {
    const newDirtyPickupLocations = { ...dirtyPickupLocations };
    newDirtyPickupLocations[routeLocationIndex] = {
      serviceContractId: serviceContractId,
      latitude,
      longitude,
      binMappingTypeId: SET_BY_USER,
    };
    collectionPoints.forEach(cp => {
      const index = cp.serviceContractIds.indexOf(serviceContractId);
      if (index >= 0) {
        cp.serviceContractIds.splice(index, 1);
        cp.totalNoOfServiceContracts--;
      }
    });
    setCollectionPoints([...collectionPoints]);
    setDirtyPickupLocations(newDirtyPickupLocations);
  };

  const addNewCollectionPoint = (latitude: number, longitude: number) => {
    const newCollectionPoints = [...collectionPoints];
    newCollectionPoints.push({
      id: `new_${newCollectionPoints.length}`,
      latitude,
      longitude,
      serviceContractIds: [],
      totalNoOfServiceContracts: 0,
    });
    setCollectionPoints(newCollectionPoints);
  };

  const markRouteLocationActive = (index: number) => {
    if (!isEditingCollectionPoints) return;
    if (includes(activeRouteLocationIndexes, index)) {
      remove(activeRouteLocationIndexes, i => i === index);
      setActiveRouteLocationIndexes([...activeRouteLocationIndexes]);
    } else {
      setActiveRouteLocationIndexes([...activeRouteLocationIndexes, index]);
    }
  };

  const assignToCollectionPoint = async (collectionPoint: CollectionPoint) => {
    const userGeneratedServices = activeRouteLocationIndexes.filter(i => {
      if (dirtyPickupLocations[i]) {
        return dirtyPickupLocations[i].binMappingTypeId === SET_BY_USER;
      }
      return getContainer(i).binLocationMappingTypeId === SET_BY_USER;
    });

    if (userGeneratedServices.length) {
      if (
        !(await confirm(
          translate('pickupLocationEditor.alerts.areYouSure'),
          translate('pickupLocationEditor.alerts.pointHasUserGeneratedLocation', {
            numberOfContainers: userGeneratedServices.length,
          }),
        ))
      ) {
        return;
      }
    }

    const collectionPointId = collectionPoint.id;
    const isNewCollectionPoint = typeof collectionPointId === 'string';

    const serviceContractIds = activeRouteLocationIndexes.map(i => {
      const serviceContractId = getContainer(i).serviceContractId;
      const previousCollectionPoint = collectionPoints.find(cp => cp.serviceContractIds.includes(serviceContractId));
      dirtyPickupLocations[i] = {
        serviceContractId: serviceContractId,
        latitude: collectionPoint.latitude,
        longitude: collectionPoint.longitude,
        binMappingTypeId: COLLECTION_POINT,
        collectionWaypointId: isNewCollectionPoint ? undefined : (collectionPointId as number),
        newCollectionWaypointIdentifier: isNewCollectionPoint ? (collectionPointId as string) : undefined,
        previousCollectionWaypointIdentifier:
          typeof previousCollectionPoint?.id === 'number' ? previousCollectionPoint.id : undefined,
      };
      return serviceContractId;
    });

    collectionPoints.forEach(cp => {
      if (cp.id === collectionPointId) {
        const duplicateServiceContractIds = intersection(cp.serviceContractIds, serviceContractIds);
        cp.serviceContractIds = uniq([...cp.serviceContractIds, ...serviceContractIds]);
        cp.totalNoOfServiceContracts =
          cp.totalNoOfServiceContracts + serviceContractIds.length - duplicateServiceContractIds.length;
      } else {
        serviceContractIds.forEach(i => {
          const index = cp.serviceContractIds.indexOf(i);
          if (index >= 0) {
            cp.serviceContractIds.splice(index, 1);
            cp.totalNoOfServiceContracts--;
          }
        });
      }
    });

    setDirtyPickupLocations({ ...dirtyPickupLocations });
    setCollectionPoints([...collectionPoints]);
    setActiveRouteLocationIndexes([]);
    setActiveCollectionPoint(undefined);

    createSuccessNotification(translate('pickupLocationEditor.alerts.successfullyAssigned'));
  };

  const handleCollectionPointsToDelete = (collectionPointId: number | string) => {
    if (!collectionPointsToDelete.includes(collectionPointId)) {
      const collectionPoint = collectionPoints.find(cp => cp.id === collectionPointId);
      const serviceContractIds = collectionPoint?.serviceContractIds || [];

      routeTemplate.routeLocations?.forEach((pickupLocation: RouteLocation, index: number) => {
        if (serviceContractIds.includes(pickupLocation.service.id)) {
          const container = getContainer(index);

          let latitude = container.binLatitude;
          let longitude = container.binLongitude;

          if (container.binLocationMappingTypeId === COLLECTION_POINT) {
            if (pickupLocation.location.address.binLatitude && pickupLocation.location.address.binLongitude) {
              latitude = pickupLocation.location.address.binLatitude;
              longitude = pickupLocation.location.address.binLongitude;
            } else {
              latitude = pickupLocation.location.address.latitude;
              longitude = pickupLocation.location.address.longitude;
            }
          }

          dirtyPickupLocations[index] = {
            ...dirtyPickupLocations[index],
            serviceContractId: container.serviceContractId,
            latitude,
            longitude,
            binMappingTypeId: (typeof collectionPointId === 'string'
              ? container.binLocationMappingTypeId
              : pickupLocation.location.address.binLongitude
              ? CURBSIDE_LOOKUP
              : LOCATION_ADDRESS) as BinLocationMappingTypeId,
            collectionWaypointId: undefined,
            newCollectionWaypointIdentifier: undefined,
            isTemporary: true,
          };

          // if previously the container was associated to a collection point, it will be associated back to it
          if (typeof collectionPointId === 'string' && container.binLocationMappingTypeId === COLLECTION_POINT) {
            collectionPoints.forEach(cp => {
              if (cp.id === dirtyPickupLocations[index].previousCollectionWaypointIdentifier) {
                cp.serviceContractIds = uniq([...cp.serviceContractIds, container.serviceContractId]);
                cp.totalNoOfServiceContracts = cp.totalNoOfServiceContracts + 1;
              } else {
                const index = cp.serviceContractIds.indexOf(container.serviceContractId);
                if (index >= 0) {
                  cp.serviceContractIds.splice(index, 1);
                  cp.totalNoOfServiceContracts--;
                }
              }
            });
          }
        }
      });
      setCollectionPoints([...collectionPoints]);
      setActiveRouteLocationIndexes([]);
      setDirtyPickupLocations({ ...dirtyPickupLocations });
      setActiveCollectionPoint(undefined);
      setCollectionPointsToDelete([...collectionPointsToDelete, collectionPointId]);
    }
  };

  const moveCollectionPoint = async (collectionPoint: CollectionPoint, latitude: number, longitude: number) => {
    const cp = collectionPoint;

    if (typeof cp.id === 'number') {
      let dirtyColPoint = modifiedCollectionPoints.find(dcp => dcp.collectionWaypointId === cp.id);
      if (!dirtyColPoint) {
        dirtyColPoint = {
          collectionWaypointId: cp.id,
          latitude,
          longitude,
        };
        modifiedCollectionPoints.push(dirtyColPoint);
      } else {
        dirtyColPoint.latitude = latitude;
        dirtyColPoint.longitude = longitude;
      }
    }

    cp.latitude = latitude;
    cp.longitude = longitude;

    setModifiedCollectionPoints([...modifiedCollectionPoints]);
    setCollectionPoints([...collectionPoints]);
  };

  const saveChanges = () => {
    setIsLoading(true);
    const pickupLocationChanges = filter(values(dirtyPickupLocations), (pl: DirtyPickupLocation) => !pl.isTemporary);
    const newCollectionPointsMap: any = {};
    collectionPoints.forEach(cp => {
      if (typeof cp.id === 'string') newCollectionPointsMap[cp.id] = cp;
    });
    pickupLocationChanges.forEach(plc => {
      if (plc.newCollectionWaypointIdentifier) {
        plc.latitude = newCollectionPointsMap[plc.newCollectionWaypointIdentifier].latitude;
        plc.longitude = newCollectionPointsMap[plc.newCollectionWaypointIdentifier].longitude;
      }
    });
    updatePickupLocations(vendorId, pickupLocationChanges, modifiedCollectionPoints)
      .then(() => {
        if (collectionPointsToDelete.length) {
          // just the existing collection points, not the new ones that have id as string
          const collectionPointIds = filter(collectionPointsToDelete, cp => typeof cp === 'number') as number[];
          deleteCollectionPoints(vendorId, collectionPointIds)(dispatch);
        }
        setIsDirty(false);
        createSuccessNotification(translate('pickupLocationEditor.alerts.saveSuccess'));
        history.push(`/routes/route-templates/${routeTemplateId}`);
      })
      .catch(() => setIsLoading(false));
  };

  const handleGoBack = async () => {
    if (isDirty) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    if (history.length) {
      history.goBack();
    } else {
      history.push(`/routes/route-templates/${routeTemplateId}`);
    }
  };

  const locationsInfo = {
    loading: routeTemplateState.isLoadingLocations,
    total: routeTemplateState.locationsTotal,
    limit: routeTemplateState.locationsLimit,
    loadedPages: routeTemplateState.loadedPages,
  } as any;

  return (
    <PageContent isLoading={isLoading}>
      <DocumentTitle>{translate('pickupLocationEditor.editPickupLocations')}</DocumentTitle>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction disabled={locationsInfo.loading} onClick={handleGoBack} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>

            <PageTitle>{translate('pickupLocationEditor.editPickupLocations')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions align="right">
          <Button color="primary" disabled={!isDirty} onClick={saveChanges}>
            {translate('common.save')}
          </Button>
        </PageActions>
      </PageHeader>

      <Panel noBackground noBoxShadow>
        {isResidential && (
          <PickupEditorModeToggle
            setIsEditingCollectionPoints={switchEditMode}
            isEditingCollectionPoints={isEditingCollectionPoints}
          />
        )}

        <DirtyPickupLocationsDictionaryWrapper>
          {routeTemplateState.isLoadingLocations ? (
            <RouteLocationsLoader {...locationsInfo} />
          ) : (
            <>
              {!!activeRouteLocationIndexes.length && (
                <ActiveLocationIndexesInfo>
                  <div>
                    {translate('pickupLocationEditor.activeStops', {
                      numberOfStops: activeRouteLocationIndexes.length,
                    })}
                  </div>
                  <div title={translate('common.clearSelection')} onClick={() => setActiveRouteLocationIndexes([])}>
                    <Icon icon="close" />
                  </div>
                </ActiveLocationIndexesInfo>
              )}
              {isLoadingCollectionPoints && <CollectionPointsLoadingContainer />}
              <PickupLocationEditorInstructions
                hasMarkedLocations={!!activeRouteLocationIndexes.length}
                isEditingCollectionPoints={isEditingCollectionPoints}
              />
              <PickupLocationsEditorMapGL
                handleCollectionPointsToDelete={handleCollectionPointsToDelete}
                markMultipleLocationsActive={setActiveRouteLocationIndexes}
                activeCollectionPoint={activeCollectionPoint}
                activeRouteLocationIndexes={activeRouteLocationIndexes}
                addNewCollectionPoint={addNewCollectionPoint}
                assignToCollectionPoint={assignToCollectionPoint}
                collectionPoints={collectionPoints}
                dirtyPickupLocations={dirtyPickupLocations}
                isEditingCollectionPoints={isEditingCollectionPoints}
                markLocationActive={markRouteLocationActive}
                onAddressChange={addressChange}
                onBoundsChanged={onBoundsChanged}
                onMoveCollectionPoint={moveCollectionPoint}
                routeLocations={routeTemplate.routeLocations || []}
                setActiveCollectionPoint={setActiveCollectionPoint}
              />
            </>
          )}
        </DirtyPickupLocationsDictionaryWrapper>
      </Panel>
    </PageContent>
  );
};

export default withRouter(PickupLocationsEditorPage);
