import React from 'react';

import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { Modal, Table } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import { RouteIssues } from 'src/routes/interfaces/Route';
import { RouteLocationIssuesModalTableRow } from '.';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import translate from '../../../core/services/translate';

const routeLocationIssueTableCells = [
  { name: 'date', label: translate('common.timestamp'), width: '15%' },
  { name: 'pickupIssueReportedReasonType', label: translate('common.issue'), width: '20%' },
  { name: 'detail', label: translate('common.detail'), width: '20% ' },
  { name: 'source', label: translate('common.source'), width: '17% ' },
  { name: 'options', label: translate('common.options'), width: '15%' },
  { name: 'images', label: translate('common.images'), width: '13%' },
];

interface Props {
  closeModal: () => void;
  modalSubTitle?: string;
  modalTitle?: string;
  routeLocationId?: number;
  routeLocationIssues: RouteIssues[];
  vehicleTypeTechnicalName?: string;
  vehicleTypeId?: number;
  wasteAuditType: TechnicalType;
  customerEmail: string;
}

const RouteLocationIssuesModal = (props: Props) => {
  const {
    modalTitle,
    modalSubTitle,
    routeLocationId,
    routeLocationIssues,
    closeModal,
    vehicleTypeTechnicalName,
    vehicleTypeId,
    wasteAuditType,
    customerEmail,
  } = props;

  return (
    <Modal title={modalTitle} subTitle={modalSubTitle} onClose={closeModal} padding="medium no no">
      <ModalSection margin="medium no no">
        <Table
          cells={routeLocationIssueTableCells}
          rows={routeLocationIssues}
          rowComponent={RouteLocationIssuesModalTableRow}
          rowProps={{
            customerName: modalTitle,
            locationName: modalSubTitle,
            vehicleTypeTechnicalName,
            vehicleTypeId,
            routeLocationId,
            routeLocationIssues,
            wasteAuditType,
            customerEmail,
          }}
        />
      </ModalSection>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  routeLocationIssues: state.routes.routeLocationIssues.routeLocationIssues,
});

export default connect(mapStateToProps)(RouteLocationIssuesModal);
