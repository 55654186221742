import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { Accordion, AccordionSection, Modal } from '../../../core/components';
import { DuckAction } from 'src/contracts/ducks';
import { resetVehicleNotes } from 'src/fleet/ducks';
import { VehicleNotesForm } from '../forms';
import translate from '../../../core/services/translate';

interface Props {
  closeModal: () => void;
  fleetMaintenanceConfigIsOptional: boolean;
  isPendingOrClosingProcess: boolean;
  modalTitle?: string;
  newIssueStatus: number;
  resetVehicleNotes: DuckAction<typeof resetVehicleNotes>;
  tripInspectionDetailId: number;
  updateVehicleIssue: (status: number, vehicleNote?: string) => void;
  vehicleId: number;
}

class VehicleNotesModal extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetVehicleNotes();
  }

  render() {
    const {
      closeModal,
      fleetMaintenanceConfigIsOptional,
      isPendingOrClosingProcess,
      modalTitle,
      newIssueStatus,
      tripInspectionDetailId,
      updateVehicleIssue,
      vehicleId,
    } = this.props;

    return (
      <Modal title={modalTitle} onClose={closeModal} padding="medium no no">
        <Accordion margin="medium no no" openedSections={['vehicleNotes']}>
          <AccordionSection name="vehicleNotes" title={translate('vehicles.vehicleNotes')} isOpen>
            <VehicleNotesForm
              fleetMaintenanceConfigIsOptional={fleetMaintenanceConfigIsOptional}
              isPendingOrClosingProcess={isPendingOrClosingProcess}
              newIssueStatus={newIssueStatus}
              tripInspectionDetailId={tripInspectionDetailId}
              updateVehicleIssue={updateVehicleIssue}
              vehicleId={vehicleId}
            />
          </AccordionSection>
        </Accordion>
      </Modal>
    );
  }
}

const mapDispatchToProps = { resetVehicleNotes };

export default connect(undefined, mapDispatchToProps)(VehicleNotesModal);
