import React from 'react';

import { PanelSectionGroup, Grid, GridColumn, Text, PanelSection } from '../../../../core/components/styled';
import { Icon } from '../../../../core/components';
import {
  VehicleSectionCollapseButton,
  VehicleSectionHeader,
  VehicleSectionHeaderContent,
  VehicleSectionHeaderTitle,
  VehicleSectionHeaderTitleWrapper,
  VehicleSectionsHeaderFilters,
} from '../../styled/VehiclePage';

interface VehiclePageSectionProps {
  content: React.ReactNode;
  title: string;
  subtitle?: string;
  subtitleColor?: string;
  headerAction?: React.ReactNode;
  headerContent?: React.ReactNode;
  headerFilters?: React.ReactNode;
  header?: React.ReactNode;
  sectionLoading?: boolean;
  sectionHasBorder?: boolean;
  sectionPadding?: string;
  sectionDisabled?: boolean;
  collapsedInitially?: boolean;
}

const VehiclePageSection: React.FC<VehiclePageSectionProps> = ({
  content,
  title,
  headerAction,
  headerContent,
  headerFilters,
  sectionLoading = false,
  sectionHasBorder = true,
  collapsedInitially = false,
  sectionDisabled = false,
  subtitle,
  subtitleColor = 'grayDark',
  sectionPadding = 'sMedium no',
}) => {
  const [sectionCollapsed, setSectionCollapsed] = React.useState<boolean>(collapsedInitially);

  return (
    <PanelSection vertical padding={sectionPadding} isLoading={sectionLoading} withBorder={sectionHasBorder}>
      <PanelSectionGroup padding="no" width="100%">
        <Grid multiLine>
          <GridColumn size="12/12" padding="no">
            <VehicleSectionHeader>
              <VehicleSectionHeaderTitleWrapper>
                <VehicleSectionHeaderTitle>
                  <Text size="xLarge">{title}</Text>

                  {!!subtitle && (
                    <Text color={subtitleColor} size="small" margin="xxSmall no no">
                      {subtitle}
                    </Text>
                  )}
                </VehicleSectionHeaderTitle>

                {!!headerAction && sectionCollapsed && headerAction}
              </VehicleSectionHeaderTitleWrapper>

              <VehicleSectionHeaderContent>
                {headerContent}

                {!!headerFilters && (
                  <VehicleSectionsHeaderFilters hidden={!sectionCollapsed}>
                    {headerFilters}
                  </VehicleSectionsHeaderFilters>
                )}

                <VehicleSectionCollapseButton
                  disabled={sectionDisabled}
                  collapsed={sectionCollapsed}
                  onClick={() => setSectionCollapsed(!sectionCollapsed)}
                >
                  <Icon icon="chevronDown" />
                </VehicleSectionCollapseButton>
              </VehicleSectionHeaderContent>
            </VehicleSectionHeader>
          </GridColumn>

          {!sectionDisabled && sectionCollapsed && (
            <GridColumn size="12/12" padding="no">
              {content}
            </GridColumn>
          )}
        </Grid>
      </PanelSectionGroup>
    </PanelSection>
  );
};

export default VehiclePageSection;
