import humps from 'humps';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { ROUTE_MAP_VEHICLE_INSIGHTS_LAYER } from '../constants';
import { RouteMapVehicleInsight } from 'src/routes/interfaces/RouteMapVehicleInsights';
import { VEHICLE_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';

export type VehicleInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
};

export const getVehicleInsightIconType = (
  vehicleInsight: RouteMapVehicleInsight | any,
  isYRoute?: boolean,
  isTimelineMap?: boolean,
) => {
  const insightType = humps.camelize(
    isYRoute
      ? vehicleInsight.insightSubTypes[0]?.name
      : isTimelineMap
      ? vehicleInsight.insightSubTypes[0]?.name
      : vehicleInsight.insightType.name,
  );
  const iconType = VEHICLE_INSIGHT_ICON_TYPES.find(({ types }) => types.indexOf(insightType) !== -1);

  return iconType;
};

export const getVehicleInsightsGeoJSON = (
  vehicleInsights: RouteMapVehicleInsight[],
  isYRoute?: boolean,
  isTimelineMap?: boolean,
) =>
  getFeatureCollection<GeoJSON.Point, VehicleInsightFeatureProperties>(
    vehicleInsights.map(vehicleInsight =>
      getPointFeature(vehicleInsight.insightId, [vehicleInsight.longitude, vehicleInsight.latitude], {
        id: vehicleInsight.insightId,
        clickable: true,
        layer: ROUTE_MAP_VEHICLE_INSIGHTS_LAYER,
        icon: getVehicleInsightIconType(vehicleInsight, isYRoute, isTimelineMap)?.id,
      }),
    ),
  );
