import { http } from 'src/core/services/http';
import { CityAlertSettings, CityAlertSettingsToSave } from '../interfaces/CityAlertSettings';
import { transformLoadCityAlertSettings } from './transformLoadCityAlerts';

export const loadCityAlertSettings = (vendorId: number) =>
  http
    .get<CityAlertSettings>(`/vendors/${vendorId}/cityAlertSettings`)
    .then(response => transformLoadCityAlertSettings(response.data));

export const saveCityAlertSetting = (vendorId: number, cityAlertSetting: CityAlertSettingsToSave) =>
  http.post(`/vendors/${vendorId}/cityAlertSettings`, cityAlertSetting).then(response => response.data);
