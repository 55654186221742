import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { date as dateFormatter, duration } from 'src/utils/services/formatter';
import {
  EditRouteButton,
  RouteDetail,
  RouteDetailLabel,
  RouteDetailsWrapper,
  RouteDetailValue,
  SeeMoreButton,
  Wrapper,
} from 'src/routes/components/styled/RouteDetails';
import { getGroupsAssigned } from '../SnowSweeperRouteHeadingDetails';
import { getRouteTime } from 'src/routes/utils/routeDetails';
import { Label } from 'src/core/components/styled';
import { OverrideDate } from 'src/routes/components';
import { ROUTE_LABEL_COLORS, ROUTE_STATUSES_BY_ID, SCHEDULED } from 'src/routes/constants';
import { RouteDetailsModalResolver } from 'src/routes/components/modals';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS, WASTE_AUDIT_ID } from 'src/fleet/constants';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';
import EditRouteDetailsModalResolver from 'src/routes/components/modals/EditRouteDetailsModalResolver';
import EffectiveDate from 'src/routes/components/EffectiveDate';
import translate from 'src/core/services/translate';

export default function RouteSummaryDetails() {
  const [isRouteDetailsModalOpen, setRouteDetailsModalOpen] = useState(false);
  const [isEditRouteDetailsModalOpen, setEditRouteDetailsModalOpen] = useState(false);

  const isSupervisorExperienceEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();

  if (!routeSummary) return null;

  const {
    completedStopsCount,
    date,
    endTime,
    routeRunDate,
    routeStatusTypeId,
    startTime,
    supervisorFirstName,
    supervisorId,
    supervisorLastName,
    templateEffectiveDate,
    totalStopsCount,
    totalTripTime,
    groups,
    vehicleTypeId,
  } = routeSummary;

  const isSupervisorHidden =
    !isSupervisorExperienceEnabled || [SNOW_PLOW_ID, WASTE_AUDIT_ID, STREET_SWEEPER_ID].includes(vehicleTypeId);

  const selectedStartTime = getRouteTime(startTime);
  const selectedEndTime = getRouteTime(endTime);

  return (
    <Wrapper>
      <RouteDetailsWrapper>
        <RouteDetail>
          <RouteDetailLabel>{translate('common.status')}</RouteDetailLabel>
          <RouteDetailValue>
            <Label fontSize="xLarge" color={ROUTE_LABEL_COLORS[routeStatusTypeId]}>
              {ROUTE_STATUSES_BY_ID[routeStatusTypeId].name}
            </Label>
          </RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('vehicles.vehicleType')}</RouteDetailLabel>
          <RouteDetailValue>{VEHICLE_TYPE_IDS[vehicleTypeId].name}</RouteDetailValue>
        </RouteDetail>
        <RouteDetail>
          <RouteDetailLabel>{translate('common.date')}</RouteDetailLabel>
          <RouteDetailValue>
            {moment(date).format('MM/DD/YYYY')}{' '}
            {routeRunDate && <OverrideDate routeRunDate={dateFormatter(routeRunDate)} />}
            {templateEffectiveDate && <EffectiveDate templateEffectiveDate={dateFormatter(templateEffectiveDate)} />}
          </RouteDetailValue>
          <RouteDetailValue isSizeSmall>{startTime && `${selectedStartTime} - ${selectedEndTime}`}</RouteDetailValue>
        </RouteDetail>
        {routeStatusTypeId !== SCHEDULED && (
          <RouteDetail>
            <RouteDetailLabel>{translate('routes.totalTripTime')}</RouteDetailLabel>
            <RouteDetailValue>{duration(totalTripTime || 0, 'minutes', 'hh:mm')}</RouteDetailValue>
          </RouteDetail>
        )}
        <RouteDetail>
          <RouteDetailLabel>
            {translate(`routes.${vehicleTypeId === SNOW_PLOW_ID ? 'snowPlow.streetSegments' : 'stops'}`)}
          </RouteDetailLabel>
          <RouteDetailValue>
            {routeStatusTypeId === SCHEDULED ? totalStopsCount : `${completedStopsCount} / ${totalStopsCount}`}
          </RouteDetailValue>
        </RouteDetail>
        {!isSupervisorHidden && (
          <RouteDetail>
            <RouteDetailLabel>{translate('routes.supervisor')}</RouteDetailLabel>
            <RouteDetailValue>{supervisorId ? `${supervisorFirstName} ${supervisorLastName}` : '-'}</RouteDetailValue>
          </RouteDetail>
        )}
        <RouteDetail>
          <RouteDetailLabel>{translate('routes.groups.groups')}</RouteDetailLabel>
          <RouteDetailValue>
            <Box maxWidth={170}>{getGroupsAssigned(groups)}</Box>
          </RouteDetailValue>
        </RouteDetail>
        {routeSummary.vehicleTypeId !== SNOW_PLOW_ID && !isViewOnly && !isSupport && (
          <EditRouteButton onClick={() => setEditRouteDetailsModalOpen(true)}>
            <ActionButtonTooltip iconColor="grayDarker" icon="edit" tooltip="edit" size="sMedium" />
          </EditRouteButton>
        )}
      </RouteDetailsWrapper>
      <SeeMoreButton onClick={() => setRouteDetailsModalOpen(true)}>{translate('routes.seeMoreDetails')}</SeeMoreButton>
      {isEditRouteDetailsModalOpen && (
        <EditRouteDetailsModalResolver closeModal={() => setEditRouteDetailsModalOpen(false)} />
      )}
      {isRouteDetailsModalOpen && <RouteDetailsModalResolver closeModal={() => setRouteDetailsModalOpen(false)} />}
    </Wrapper>
  );
}
