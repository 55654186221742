import { Address } from 'src/common/interfaces/Facility';

export const getFormattedAddress = (address: Address) => {
  if (!address) {
    return '';
  }

  const { streetNumber, city, state, country, zip, street } = address;

  return `${street ? `${street}` : ' '}${streetNumber ? ` ${streetNumber}, ` : ', '}${city ? `${city}, ` : ' '}${
    state ? `${state}, ` : ' '
  }${zip ? `${zip}, ` : ' '}${country ? `${country}` : ' '} `;
};
