import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const COMPLETED_NOTIFICATION = 'ServiceCompletedNotification';
export const IN_PROGRESS_NOTIFICATION = 'ServiceInProgressNotification';
export const ISSUE_REPORTED_NOTIFICATION = 'ServiceIssueReportedNotification';

export const NOTIFICATION_TYPES = mapKeys(
  [
    { id: COMPLETED_NOTIFICATION, name: translate('vendors.notificationTypes.completed') },
    { id: IN_PROGRESS_NOTIFICATION, name: translate('vendors.notificationTypes.inProgress') },
    { id: ISSUE_REPORTED_NOTIFICATION, name: translate('vendors.notificationTypes.issueReported') },
  ],
  'id',
);

export const RESIDENTIAL_NOTIFICATION_TYPES = mapKeys(
  [
    { id: COMPLETED_NOTIFICATION, name: translate('vendors.notificationTypes.completed') },
    { id: ISSUE_REPORTED_NOTIFICATION, name: translate('vendors.notificationTypes.issueReported') },
  ],
  'id',
);
