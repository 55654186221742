import { InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, GridColumn, ButtonSet, Button } from '../../../core/components/styled';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {
  onCancel: () => void;
  onDelete: () => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const DeleteCustomerContactsForm = ({ onDelete, onCancel }: Props) => (
  <form>
    <Grid multiLine>
      <GridColumn size="12/12" verticalAlign="center">
        <ButtonSet margin="small auto no" align="center">
          <Button type="button" color="primary" onClick={() => onDelete()} id="yes-button">
            {translate('common.yes')}
          </Button>
          <Button type="button" color="secondary" margin="no no no small" id="no-button" onClick={() => onCancel()}>
            {translate('common.no')}
          </Button>
        </ButtonSet>
      </GridColumn>
    </Grid>
  </form>
);

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'deleteCustomerContactForm',
  enableReinitialize: true,
})(DeleteCustomerContactsForm);
