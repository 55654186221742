import { FC, useMemo } from 'react';

import { StreetNetworkPropertiesTravelPath } from 'src/routes/interfaces/TravelPath';
import { getArrowsForStreetSegmentsDirection } from '../../utils';
import StreetNetworkOptionsSelectedGLSource from './StreetNetworkOptionsSelectedGLSource';

interface Props {
  streetNetworkOptionsSelected: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
  mapBearing: number;
}

const StreetNetworkOptionsSelectedGL: FC<Props> = ({ streetNetworkOptionsSelected, mapBearing }) => {
  const arrowsGeoJson = useMemo(() => {
    if (!streetNetworkOptionsSelected) return null;
    return getArrowsForStreetSegmentsDirection(streetNetworkOptionsSelected.features, mapBearing);
  }, [mapBearing, streetNetworkOptionsSelected]);

  if (!arrowsGeoJson || !streetNetworkOptionsSelected) return null;

  return (
    <>
      <StreetNetworkOptionsSelectedGLSource
        streetNetworkOptionsSelected={streetNetworkOptionsSelected}
        streetNetworkOptionsSelectedArrowsPoints={arrowsGeoJson}
      />
    </>
  );
};

export default StreetNetworkOptionsSelectedGL;
