import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import applyBatching from '../../../common/services/applyBatching';
import { transferRouteJobs as doTransferRouteJobs } from '../../services/dispatchBoardRouteJobs';
import {
  baseReducer,
  baseInitialState,
  loadDispatchBoardJobs,
  moveDispatchBoardJobToPosition,
  deleteDispatchBoardJob,
  resetDispatchBoardJobs,
} from './dispatchBoardBaseJobs';

const actionMap = {
  START_LOAD: 'dispatchBoard/sourceRouteJobs/START_LOAD',
  COMPLETE_LOAD: 'dispatchBoard/sourceRouteJobs/COMPLETE_LOAD',
  FAIL_LOAD: 'dispatchBoard/sourceRouteJobs/FAIL_LOAD',
  START_DELETE_JOB: 'dispatchBoard/sourceRouteJobs/START_DELETE_JOB',
  COMPLETE_DELETE_JOB: 'dispatchBoard/sourceRouteJobs/COMPLETE_DELETE_JOB',
  FAIL_DELETE_JOB: 'dispatchBoard/sourceRouteJobs/FAIL_DELETE_JOB',
  MOVE_JOB_TO_POSITION: 'dispatchBoard/sourceRouteJobs/MOVE_JOB_TO_POSITION',
  RESET: 'dispatchBoard/sourceRouteJobs/RESET',
};

const START_TRANSFER_JOB = 'dispatchBoard/sourceRouteJobs/START_TRANSFER_JOB';
const BATCH_UPDATE = 'dispatchBoard/sourceRouteJobs/BATCH_UPDATE';
const COMPLETE_TRANSFER_JOB = 'dispatchBoard/sourceRouteJobs/COMPLETE_TRANSFER_JOB';
const FAIL_TRANSFER_JOB = 'dispatchBoard/sourceRouteJobs/FAIL_TRANSFER_JOB';

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

interface State {
  isTransferringJob: boolean;
  batchTransfererProgress: number;
}

// Initial state
const initialState: State = {
  isTransferringJob: false,
  batchTransfererProgress: 0,
};

// Reducer
export const reducer = (state = { ...initialState, ...baseInitialState }, action: AnyAction) => {
  switch (action.type) {
    case START_TRANSFER_JOB:
      return update(state, {
        $merge: { isTransferringJob: true },
      });

    case BATCH_UPDATE:
      return update(state, {
        $merge: {
          batchTransfererProgress: action.progress,
        },
      });

    case COMPLETE_TRANSFER_JOB:
      return update(state, {
        $merge: { isTransferringJob: false },
      });

    case FAIL_TRANSFER_JOB:
      return update(state, {
        $merge: { isTransferringJob: false },
      });

    default:
      break;
  }

  return baseReducer(state, action, actionMap);
};

const startTransferJob = () => ({
  type: START_TRANSFER_JOB,
});

const batchUpdate = (progress: number) => ({
  type: BATCH_UPDATE,
  progress,
});

const completeTransferJob = () => ({
  type: COMPLETE_TRANSFER_JOB,
});

const failTransferJob = () => ({
  type: FAIL_TRANSFER_JOB,
});

export const loadDispatchBoardSourceRouteJobs = (routeId: number, searchTerm?: string) => (dispatch: Dispatch) => {
  loadDispatchBoardJobs(dispatch, actionMap, routeId, searchTerm);
};

export const moveDispatchBoardSourceRouteJobToPosition =
  (fromPosition: number, toPosition: number) => (dispatch: Dispatch, getState: () => any) => {
    const isSource = true;
    const promise = moveDispatchBoardJobToPosition(dispatch, getState, actionMap, fromPosition, toPosition, isSource);
    return promise;
  };

export const deleteDispatchBoardSourceRouteJob = (routeId: number, jobId: number) => (dispatch: Dispatch) =>
  deleteDispatchBoardJob(dispatch, actionMap, routeId, jobId);

export const resetDispatchBoardSourceRouteJobs = () => resetDispatchBoardJobs(actionMap);

export const transferDispatchBoardSourceRouteJob =
  (routeId: number, jobIds: any[], reqPosition: number, isOnHoldJob?: boolean) => async (dispatch: Dispatch) => {
    dispatch(startTransferJob());
    const batchSize = 50;
    let position = reqPosition;

    const promise = applyBatching(jobIds, batchSize, async (batchJobs, progress) => {
      await doTransferRouteJobs(batchJobs, routeId, position, isOnHoldJob);
      position += batchSize;
      dispatch(batchUpdate(progress));
    });

    promise.then(() => dispatch(completeTransferJob())).catch(() => dispatch(failTransferJob()));
    return promise;
  };
