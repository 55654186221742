import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadRouteTemplateDraftServices } from 'src/routes/services/routeTemplateBuilder';
import { AppState } from 'src/store';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { loadWasteTypes } from '../../../../../common/ducks';
import { TypedResolver } from '../../../../../core/components';
import { loadOperationalFacilities } from '../../../../../fleet/ducks';
import { currentVendorId } from '../../../../../vendors/services/currentVendorSelector';
import { loadServiceZones } from '../../../../ducks';
import RouteTemplateBuilderCreateRouteModal, {
  RouteTemplateBuilderCreateRouteModalProps,
  RouteTemplateBuilderCreateRouteModalPropsFormValues,
} from './RouteTemplateBuilderCreateRouteModal';

const RouteTemplateBuilderCreateRouteModalResolver: FC<RouteTemplateBuilderCreateRouteModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const [initialValues, setInitialValues] = useState<Partial<RouteTemplateBuilderCreateRouteModalPropsFormValues>>({});

  const vehicleTypeId = useSelector((state: AppState) => state.routes.routeTemplateBuilder.mapFilters.vehicleTypeId);
  const dayOfServiceIds = useSelector(
    (state: AppState) => state.routes.routeTemplateBuilder.mapFilters.dayOfServiceIds,
  );

  return (
    <TypedResolver
      resolve={async () => {
        if (props.newRouteTemplateId) {
          const draft = await loadRouteTemplateDraftServices(props.newRouteTemplateId);
          setInitialValues(draft);
        }

        return Promise.all([
          loadWasteTypes()(dispatch),
          loadOperationalFacilities(vendorId)(dispatch),
          loadServiceZones(vendorId, false)(dispatch),
        ]);
      }}
      loadingComponent={PageLoadingOverlay}
      successComponent={RouteTemplateBuilderCreateRouteModal}
      successProps={{
        ...props,
        initialValues: !props.newRouteTemplateId
          ? {
              vehicleTypeId,
              dayOfService: dayOfServiceIds[0],
            }
          : initialValues,
      }}
    />
  );
};

export default RouteTemplateBuilderCreateRouteModalResolver;
