import styled, { css } from 'styled-components';

import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';
import { sizeMapper } from '../../../utils/styles';
import { Box } from './Box';

export interface TooltipButtonTooltipProps {
  position: 'top' | 'right' | 'bottom' | 'left';
  color?: string;
}

export const TooltipButtonTooltip = styled.span<TooltipButtonTooltipProps>`
  display: none;
  position: absolute;
  padding: 6px 24px;
  white-space: nowrap;
  min-width: 100px;
  background-color: ${props => colorMapperWithoutProps(props.color, 'primary')};
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  z-index: 9999;
  pointer-events: none;

  ${props =>
    props.position === 'top' &&
    css`
      bottom: calc(100% + 9px);
      left: 50%;
      transform: translateX(-50%);
    `}

  ${props =>
    props.position === 'right' &&
    css`
      left: calc(100% + 9px);
      top: 50%;
      transform: translateY(-50%);
    `}

  ${props =>
    props.position === 'bottom' &&
    css`
      top: calc(100% + 9px);
      left: 50%;
      transform: translateX(-50%);
    `}

  ${props =>
    props.position === 'left' &&
    css`
      right: calc(100% + 9px);
      top: 50%;
      transform: translateY(-50%);
    `}

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: ${props => colorMapperWithoutProps(props.color, 'primary')};
    z-index: -1;

    ${props =>
      props.position === 'top' &&
      css`
        left: 50%;
        top: 100%;
        transform: translate(-50%, -50%) rotate(45deg);
      `}

    ${props =>
      props.position === 'right' &&
      css`
        top: 50%;
        right: 100%;
        transform: translate(50%, -50%) rotate(45deg);
      `}

    ${props =>
      props.position === 'bottom' &&
      css`
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, 50%) rotate(45deg);
      `}

    ${props =>
      props.position === 'left' &&
      css`
        top: 50%;
        left: 100%;
        transform: translate(-50%, -50%) rotate(45deg);
      `}
  }
`;

interface TooltipButtonWrapperProps {
  margin?: string;
  noWrap?: boolean;
  inlineFlex?: boolean;
  showTooltip?: boolean;
}

export const TooltipButtonWrapper = styled.div<TooltipButtonWrapperProps>`
  position: relative;
  display: inline-block;
  margin: ${props => sizeMapper(props.margin, 'no xSmall no no')};

  &:hover ${TooltipButtonTooltip} {
    display: block;
  }

  ${props =>
    props.showTooltip &&
    css`
      ${TooltipButtonTooltip} {
        display: block;
      }
    `}

  ${props =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}

  ${props =>
    props.inlineFlex &&
    css`
      display: inline-flex;
    `}
`;

export const TooltipButtonBoxWrapper = styled(Box).attrs({
  whiteSpace: 'noWrap',
})`
  position: relative;

  &:hover ${TooltipButtonTooltip} {
    display: block;
  }
`;
