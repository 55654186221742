import humps from 'humps';
import { get, map } from 'lodash-es';

import translate from 'src/core/services/translate';
import { getEquipmentSize, getEquipmentSizeTranslationKey } from 'src/customers/services/transformLoadServices';
import createFormattedAddress from 'src/utils/services/createFormattedAddress';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { date } from 'src/utils/services/formatter';

const transformServiceContracts = (serviceContracts: any[]) => {
  return serviceContracts.map(sc => ({
    ...sc,
    serviceName: translate(createTranslationKey(sc.serviceType.name, 'common.serviceTypes')),
    serviceTypeIcon: humps.camelize(sc.equipmentType.name.split('.').join('')),
    wasteMaterialType: translate(createTranslationKey(sc.wasteMaterialType.technicalName, 'common.wasteTypes')),
    equipmentType: translate(createTranslationKey(sc.equipmentType.technicalName, 'common.equipmentTypes')),
    equipmentSize: translate(
      getEquipmentSizeTranslationKey(sc.equipmentSize.technicalName, sc.serviceType.name, sc.equipmentType.name),
      {
        size: getEquipmentSize(sc.equipmentSize.name),
      },
    ),
    pickupFrequencyType: translate(createTranslationKey(sc.pickupFrequencyType.name, 'common.pickupFrequencyTypes')),
  }));
};

export const transformLoadLocations = (locations: any) =>
  map(locations, location => {
    const fromDate = get(location.futureAccountStatus, 'fromDate');
    const toDate = get(location.futureAccountStatus, 'toDate');
    return {
      ...location,
      accountStatusId: location.accountStatusTypeId,
      accountNumber: location.vendorAccountNo,
      address: {
        ...location.address,
        formattedAddress: location.address.line1 || createFormattedAddress(location.address),
      },
      temporaryAddress: location.address.line1 || createFormattedAddress(location.address),
      locationAlerts: map(location.locationAlerts, locationAlert => Number(locationAlert.id)),

      futureAccountStatus: {
        ...location.futureAccountStatus,
        fromDate: fromDate ? date(fromDate, 'MM/DD/YYYY') : undefined,
        toDate: toDate ? date(toDate, 'MM/DD/YYYY') : undefined,
      },

      serviceContracts: transformServiceContracts(location.serviceContracts),
    };
  });

export const transformSaveLocation = (location: any) => ({
  ...location,
  accountStatusTypeId: location.accountStatusId,
  vendorAccountNo: location.accountNumber,
  address: {
    ...location.address,
    line1: location.address.formattedAddress,
  },
  locationAlerts: map(location.locationAlerts, locationAlert => ({ id: Number(locationAlert) })),
});
