import { FC, useMemo } from 'react';
import { Modal } from 'src/core/components';
import { ModalTitle } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { Group } from 'src/routes/interfaces/Groups';
import { GroupEditorForm } from '../forms';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';

interface Props {
  groupId?: number;
  closeModal: (pristine: boolean) => void;
  onGroupSave: (data: Group) => void;
}
const GroupEditorModal: FC<Props> = ({ groupId, closeModal, onGroupSave }) => {
  const isSaving = useSelector(state => state.routes.groups.isSaving);
  const groups = useSelector(state => state.routes.groups.groups);
  const vendorId = useSelector(currentVendorId);

  const initialValues = useMemo(() => {
    const group = groups.find(group => group.id === groupId);
    return (
      group || {
        groupName: '',
        isActive: true,
        vendorId,
      }
    );
  }, [groupId, groups, vendorId]);

  return (
    <Modal size="small" padding="medium" isLoading={isSaving}>
      <ModalTitle>{translate(`routes.groups.${groupId ? 'edit' : 'create'}Group`)}</ModalTitle>
      <GroupEditorForm initialValues={initialValues} onCancel={closeModal} onSubmit={onGroupSave} />
    </Modal>
  );
};

export default GroupEditorModal;
