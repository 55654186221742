import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { loadWasteAuditTypes as doLoadWasteAuditTypes } from '../services/wasteAuditTypes';
import { TechnicalType } from '../interfaces/TechnicalType';

const START_LOAD = 'common/wasteAuditTypes/START_LOAD';
const COMPLETE_LOAD = 'common/wasteAuditTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/wasteAuditTypes/FAIL_LOAD';
const RESET = 'common/wasteAuditTypes/RESET';

type State = {
  isLoading: boolean;
  wasteAuditTypes: TechnicalType[];
};

const initialState: State = {
  isLoading: false,
  wasteAuditTypes: [],
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteAuditTypes: action.wasteAuditTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          wasteAuditTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (wasteAuditTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  wasteAuditTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadWasteAuditTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadEquipmentTypesPromise = doLoadWasteAuditTypes();
  loadEquipmentTypesPromise
    .then(wasteAuditTypes => dispatch(completeLoad(wasteAuditTypes)))
    .catch(() => dispatch(failLoad()));
  return loadEquipmentTypesPromise;
};

export const resetWasteAuditTypes = () => ({
  type: RESET,
});
