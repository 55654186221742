import React from 'react';
import { InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';

import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { Grid, GridColumn, ButtonSet, Button } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { ROUTE_BUILDER_SETTINGS_FORM_NAME } from '../../constants/routeSettingsForm';
import RouteSettingsForm from './RouteSettingsForm';
import { RouteSettings } from '../../interfaces/RouteSettings';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { loadRouteBuilderDefaultSettings } from '../../services/routeBuilderSettings';
import { find } from 'lodash-es';

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteSettings, PropsWithoutReduxForm>;

const RouteBuilderForm: React.FC<Props> = ({ initialValues, form, change, handleSubmit }) => {
  const formSelector = formValueSelector(form);
  const [hasError, setHasError] = React.useState([false]);

  const globalTurnTimeSettingsEnabled = useSelector((state: AppState) =>
    formSelector(state, 'globalTurnTimeSettingsEnabled'),
  );

  const restoreDefaults = React.useCallback(async () => {
    const defaults = await loadRouteBuilderDefaultSettings();

    Object.keys(defaults).forEach(key => {
      if (['id', 'isActive'].indexOf(key) !== -1) {
        return;
      }

      change(`${key}`, defaults[key as keyof RouteSettings]);
    });
  }, [change]);

  return (
    <form onSubmit={handleSubmit}>
      <RouteSettingsForm
        isRouteBuilder
        change={change}
        defaultRouteStartTime={initialValues.routeStartTime}
        globalTurnTimeSettingsEnabled={globalTurnTimeSettingsEnabled}
        hasError={hasError}
        setHasError={setHasError}
      />

      <Grid centered>
        <GridColumn size="12/12">
          <ButtonSet margin="medium no" align="center">
            <Button type="button" color="secondary" margin="no xSmall no no" onClick={restoreDefaults}>
              {translate('routes.restoreToDefaults')}
            </Button>

            <Button type="submit" color="primary" disabled={find(hasError, el => el && true)}>
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </form>
  );
};

export default reduxForm<RouteSettings, PropsWithoutReduxForm>({
  form: ROUTE_BUILDER_SETTINGS_FORM_NAME,
  onSubmitFail: focusFirstInvalidField,
})(RouteBuilderForm);
