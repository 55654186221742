import { http } from 'src/core/services/http';
import translate from 'src/core/services/translate';
import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';

export const uploadCityAlertsFile = (
  fileData: any,
  vendorId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http
    .post('/cityAlertImport/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};

export const uploadLocationAlertsFile = (
  fileData: any,
  vendorId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http
    .post('/locationAlertImport/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};

export const loadCityAlertsImportUploadedFilesStatus = (vendorId: number) =>
  http.get('/cityAlertImport/history', { params: { vendorId } }).then(response => response.data);

export const loadLocationAlertsImportUploadedFilesStatus = (vendorId: number) =>
  http.get('/locationAlertImport/history', { params: { vendorId } }).then(response => response.data);

export const downloadSampleCityAlertsTemplate = () =>
  downloadExcelFile('/cityAlertImport/downloadTemplate', translate('vendors.cityAlerts.sampleFileCityAlerts'));

export const downloadSampleLocationAlertsTemplate = () =>
  downloadExcelFile('/locationAlertImport/downloadTemplate', translate('vendors.cityAlerts.sampleFileLocationAlerts'));
