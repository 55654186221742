import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

const PROXIMITY_SETTINGS = mapKeys(
  [
    { value: 50, name: `50 ${translate('customers.yds')}` },
    { value: 100, name: `100 ${translate('customers.yds')}` },
    { value: 150, name: `150 ${translate('customers.yds')}` },
    { value: 200, name: `200 ${translate('customers.yds')}` },
    { value: 250, name: `250 ${translate('customers.yds')}` },
    { value: 500, name: `500 ${translate('customers.yds')}` },
    { value: 750, name: `750 ${translate('customers.yds')}` },
    { value: 1000, name: `1000 ${translate('customers.yds')}` },
    { value: 1250, name: `1250 ${translate('customers.yds')}` },
    { value: 1500, name: `1500 ${translate('customers.yds')}` },
    { value: 1760, name: `1 ${translate('customers.mi')}` },
  ],
  'value',
);

export default PROXIMITY_SETTINGS;
