import { useState, useEffect, FC } from 'react';
import { filter, findIndex, size } from 'lodash-es';
import { useDispatch } from 'react-redux';

import {
  Button,
  ButtonSet,
  FormError,
  Grid,
  GridColumn,
  ListBoxContainer,
  ListBoxItem,
  Text,
} from 'src/core/components/styled';
import { saveAssignedLocations, resetContactLocations } from 'src/customers/ducks';
import { ContactLocation } from 'src/customers/interfaces/Customers';
import translate from 'src/core/services/translate';
import { clearSubmitErrors } from 'redux-form';
import { CONTACTS_EDITOR_FORM } from './forms/ContactsEditorForm';

interface Props {
  assignedLocations: (ContactLocation | undefined)[];
  availableLocations: (ContactLocation | undefined)[];
  submissionError?: string;
}

const ContactLocationsFormSection: FC<Props> = ({ assignedLocations, availableLocations, submissionError }) => {
  const [assignedLocationsList, setAssignedLocationsList] = useState<any[]>(assignedLocations);
  const [availableLocationsList, setAvailableLocationsList] = useState<any[]>(availableLocations);
  const [selectedItem, setSelectedItem] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    // save initial assigned locations to redux state
    dispatch(saveAssignedLocations(assignedLocations));
    return () => {
      dispatch(resetContactLocations());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSelectedItem = (id: number) => () => setSelectedItem(id);

  const handleMoveAll = (value: boolean) => () => {
    if (value) {
      setAssignedLocationsList([...availableLocationsList, ...assignedLocationsList]);
      // TODO: this line saves the assigned locations everytime the button is pressed
      //       find a way to save and access the values for form submission without saving them to redux on every button press
      setAvailableLocationsList([]);
      dispatch(saveAssignedLocations([...availableLocationsList, ...assignedLocationsList]));
      dispatch(clearSubmitErrors(CONTACTS_EDITOR_FORM));
    } else {
      setAvailableLocationsList([...availableLocationsList, ...assignedLocationsList]);
      setAssignedLocationsList([]);
      // TODO: this line saves the assigned locations everytime the button is pressed
      //       find a way to save and access the values for form submission without saving them to redux on every button press
      dispatch(saveAssignedLocations([]));
    }
  };

  const handleSingleMove = (value: boolean) => () => {
    const selectedLocation = value
      ? filter(availableLocationsList, item => item.id === selectedItem)
      : filter(assignedLocationsList, item => item.id === selectedItem);
    if (value && size(selectedLocation)) {
      const index = findIndex(availableLocationsList, ['id', selectedItem]);
      setAvailableLocationsList([
        ...availableLocationsList.slice(0, index),
        ...availableLocationsList.slice(index + 1),
      ]);
      setAssignedLocationsList([...selectedLocation, ...assignedLocationsList]);
      // TODO: this line saves the assigned locations everytime the button is pressed
      //       find a way to save and access the values for form submission without saving them to redux on every button press
      dispatch(saveAssignedLocations([...selectedLocation, ...assignedLocationsList]));
      dispatch(clearSubmitErrors(CONTACTS_EDITOR_FORM));
    } else if (!value && size(selectedLocation)) {
      const index = findIndex(assignedLocationsList, ['id', selectedItem]);
      setAssignedLocationsList([...assignedLocationsList.slice(0, index), ...assignedLocationsList?.slice(index + 1)]);
      setAvailableLocationsList([...selectedLocation, ...availableLocationsList]);
      // TODO: this line saves the assigned locations everytime the button is pressed
      //       find a way to save and access the values for form submission without saving them to redux on every button press
      dispatch(
        saveAssignedLocations([...assignedLocationsList.slice(0, index), ...assignedLocationsList?.slice(index + 1)]),
      );
    }
  };

  return (
    <Grid centered multiLine>
      <GridColumn size="5/12">
        <GridColumn shift="5/12">
          <Text size="large">{translate('common.available')}</Text>
        </GridColumn>
        <ListBoxContainer right>
          {!!size(availableLocationsList) &&
            availableLocationsList.map(item => (
              <ListBoxItem selectedItem={selectedItem} id={item.id} key={item.id} onClick={handleSelectedItem(item.id)}>
                {item.name}
              </ListBoxItem>
            ))}
        </ListBoxContainer>
      </GridColumn>
      <GridColumn size="2/12">
        <ButtonSet vertical style={{ height: '200px' }}>
          <Button
            color="primary"
            type="button"
            margin="no no xSmall no"
            onClick={handleMoveAll(true)}
            disabled={size(availableLocationsList) ? false : true}
          >
            &gt;&gt;
          </Button>
          <Button color="primary" type="button" margin="no no xSmall no" onClick={handleSingleMove(true)}>
            &gt;
          </Button>
          <Button color="primary" type="button" margin="no no xSmall no" onClick={handleSingleMove(false)}>
            &lt;
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={handleMoveAll(false)}
            disabled={size(assignedLocationsList) ? false : true}
          >
            &lt;&lt;
          </Button>
        </ButtonSet>
      </GridColumn>
      <GridColumn size="5/12">
        <GridColumn shift="2/12">
          <Text size="large">{translate('common.assigned')}</Text>
        </GridColumn>
        <ListBoxContainer>
          {!!size(assignedLocationsList) &&
            assignedLocationsList.map(item => (
              <ListBoxItem selectedItem={selectedItem} id={item.id} key={item.id} onClick={handleSelectedItem(item.id)}>
                {item.name}
              </ListBoxItem>
            ))}
        </ListBoxContainer>
      </GridColumn>
      {submissionError && <FormError>{translate('common.contact.alertMessages.contactLocationError')}</FormError>}
    </Grid>
  );
};

export default ContactLocationsFormSection;
