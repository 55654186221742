export const ACCOUNT_SETTINGS_ADVANCED_PROFILE = 'AccountSettings_AdvancedProfile';
export const ACCOUNT_SETTINGS_EDIT_VENDOR = 'AccountSettings_EditVendor';
export const ACCOUNT_SETTINGS_FEATURES = 'AccountSettings_Features';
export const ACCOUNT_SETTINGS_MANAGE_USERS = 'AccountSettings_ManageUsers';
export const CUSTOMERS_SUSPENDED_LOCATIONS_EDIT = 'Customers_SuspendedLocations_Edit';
export const CUSTOMERS_CREATE = 'Customers_Create';
export const CUSTOMERS_DELETE = 'Customers_Delete';
export const CUSTOMERS_DETAILS_ADD_LOCATION = 'Customers_Details_AddLocation';
export const CUSTOMERS_DETAILS_DELETE_LOCATION = 'Customers_Details_DeleteLocation';
export const CUSTOMERS_DETAILS_EDIT = 'Customers_Details_Edit';
export const CUSTOMERS_DETAILS_EDIT_LOCATION = 'Customers_Details_EditLocation';
export const CUSTOMERS_LOCATION_DETAILS_ADD_SERVICE = 'Customers_LocationDetails_AddService';
export const CUSTOMERS_LOCATION_DETAILS_DELETE_SERVICE = 'Customers_LocationDetails_DeleteService';
export const CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE = 'Customers_LocationDetails_EditService';
export const CUSTOMERS_SUSPENDED_LOCATIONS_DROPDOWN = 'Customers_SuspendedLocations_Dropdowns';
export const CUSTOMERS_SUSPENDED_LOCATIONS_EXPORT = 'Customers_SuspendedLocations_Export';
export const CUSTOMERS_SUSPENDED_LOCATIONS_SAVE = 'Customers_SuspendedLocations_Save';
export const CUSTOMERS_SUSPENDED_LOCATIONS_STATUS = 'Customers_SuspendedLocations_Status';
export const DASHBOARD_FILTERS = 'Dashboard_Filters';
export const DASHBOARD_REAL_TIME_INSIGHTS_FEED = 'Dashboard_RealTimeInsightsFeed';
export const DASHBOARD_SERVICE_STATS_BOX = 'Dashboard_ServiceStatsBox';
export const FLEET_CONTAINERS_CREATE = 'Fleet_Containers_Create';
export const FLEET_CONTAINERS_DELETE = 'Fleet_Containers_Delete';
export const FLEET_CONTAINERS_EDIT = 'Fleet_Containers_Edit';
export const FLEET_CONTAINERS_EXPORT = 'Fleet_Containers_Export';
export const FLEET_DRIVERS_CREATE = 'Fleet_Drivers_Create';
export const FLEET_DRIVERS_DELETE = 'Fleet_Drivers_Delete';
export const FLEET_DRIVERS_EDIT = 'Fleet_Drivers_Edit';
export const FLEET_FACILITIES_CREATE = 'Fleet_Facilities_Create';
export const FLEET_FACILITIES_DELETE = 'Fleet_Facilities_Delete';
export const FLEET_FACILITIES_EDIT = 'Fleet_Facilities_Edit';
export const FLEET_VEHICLES_CONFIGURE_VEHICLE_TYPES = 'Fleet_Vehicles_ConfigureVehicleTypes';
export const FLEET_VEHICLES_CREATE = 'Fleet_Vehicles_Create';
export const FLEET_VEHICLES_DELETE = 'Fleet_Vehicles_Delete';
export const FLEET_VEHICLES_EDIT = 'Fleet_Vehicles_Edit';
export const INSIGHTS_EXPORT = 'Insights_Export';
export const INSIGHTS_PREFERENCES = 'Insights_Preferences';
export const INSIGHTS_SEE_DETAILS = 'Insights_SeeDetails';
export const ROUTES_TRACKER_EXPORT = 'Routes_Tracker_Export';
export const ROUTE_SUMMARY_EXPORT = 'RouteSummary_Export';
export const ROUTES_DISPATCH_BOARD_ADD_JOB = 'Routes_DispatchBoard_AddJob';
export const ROUTES_DISPATCH_BOARD_DELETE = 'Routes_DispatchBoard_Delete';
export const ROUTES_DISPATCH_BOARD_DROPDOWNS = 'Routes_DispatchBoard_Dropdowns';
export const ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS = 'Routes_DispatchBoard_Manage_Scheduled_Jobs';
export const ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS = 'Routes_DispatchBoard_Manage_Unscheduled_Jobs';
export const ROUTES_DISPATCH_BOARD_ROUTE_NOTE = 'Routes_DispatchBoard_RouteNote';
export const ROUTES_DISPATCH_BOARD_SAVE = 'Routes_DispatchBoard_Save';
export const ROUTES_PLANNER_CREATE = 'Routes_Planner_Create';
export const ROUTES_PLANNER_DELETE = 'Routes_Planner_Delete';
export const ROUTES_PLANNER_DETAILS_EDIT = 'Routes_Planner_Details_Edit';
export const ROUTES_PLANNER_DETAILS_EXPORT = 'Routes_Planner_Details_Export';
export const ROUTES_PLANNER_EXPORT = 'Routes_Planner_Export';
export const ROUTES_PLANNER_PLAN = 'Routes_Planner_Plan';
export const ROUTES_PLANNER_SCHEDULE = 'Routes_Planner_Schedule';
export const ROUTES_PLANNER_SELECTION = 'Routes_Planner_Selection';
export const ROUTES_PLANNER_SERVICE_ZONES = 'Routes_Planner_ServiceZones';
export const ROUTES_PLANNER_SERVICE_ZONES_CREATE = 'Routes_Planner_ServiceZones_Create';
export const ROUTES_PLANNER_SERVICE_ZONES_DELETE = 'Routes_Planner_ServiceZones_Delete';
export const ROUTES_PLANNER_SERVICE_ZONES_EDIT = 'Routes_Planner_ServiceZones_Edit';
export const ROUTES_PLANNER_HOLIDAY_PLANNER = 'Routes_Planner_Holiday_Planner';
export const ROUTE_ROUTE_TEMPLATE_BUILDER = 'Route_Route_Template_Builder';
export const ROUTES_TRACKER_COPY = 'Routes_Tracker_Copy';
export const ROUTES_TRACKER_DELETE = 'Routes_Tracker_Delete';
export const ROUTES_TRACKER_SEQUENCE_ROUTE = 'Routes_Tracker_Sequence_Route';
export const ROUTES_TRACKER_VIEW_ADD_NOTE = 'Routes_Tracker_View_AddNote';
export const ROUTES_TRACKER_VIEW_ADD_WEIGHT_TICKET = 'Routes_Tracker_View_AddWeightTicket';
export const ROUTES_TRACKER_VIEW_EDIT = 'Routes_Tracker_View_Edit';
export const ROUTES_TRACKER_VIEW_EMAIL = 'Routes_Tracker_View_Email';
export const ROUTES_TRACKER_VIEW_EXPORT = 'Routes_Tracker_View_Export';
export const DISPATCH_OPEN_SERVICE_REQUESTS = 'Dispatch_Open_Service_Requests';
export const DISPATCH_SERVICE_CONFIRMATION = 'Dispatch_Service_Confirmation';
export const OPPORTUNITIES = 'Opportunities';
export const GEO_FENCES_CREATE_EDIT_DELETE = 'Geo_Fences';
export const GEO_FENCES_ROUTE_TEMPLATE = 'Route_Template_Geo_Fences';
export const GEO_FENCES_DAILY_ROUTE = 'Daily_Routes_Geo_Fences';
