import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { ModalProps } from 'src/core/components/Modal';
import { loadStreetNetworkHaulerLocations, setSelectedHaulerLocationsForDisplay } from 'src/customers/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import ServiceAreaModal from './ServiceAreaModal';
import { loadVendor } from 'src/vendors/ducks';

interface Props extends ModalProps {}

const ServiceAreaModalResolver: FC<Props> = ({ onClose }) => {
  const vendorId = useSelector(currentVendorId);
  const dispatch = useDispatch();

  const loadDependencies = async () => {
    if (!vendorId) return Promise.reject();
    const dependencies: Promise<any>[] = [loadVendor(vendorId)(dispatch)];
    const haulerLocations = await loadStreetNetworkHaulerLocations()(dispatch);

    dispatch(setSelectedHaulerLocationsForDisplay(haulerLocations));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={ServiceAreaModal}
      successProps={{ onClose, vendorId }}
      onError={onClose}
    />
  );
};

export default ServiceAreaModalResolver;
