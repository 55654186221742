import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/core/hooks/useSelector';
import { Button } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import {
  checkIsQuickBooksActive,
  quickBooksAuthorization,
  completeQuickBooksSetup,
} from 'src/vendors/ducks/quickBooks';
import { useLocation } from 'react-router';
import QuickBooksActivateModal from '../../modals/QuickBooksActivateModal';

const getParamValue = (paramsList: string[], paramName: string) =>
  paramsList.find(param => param.includes(paramName))?.split(paramName)[1];

const QuickBooksButton: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isQuickBooksActive = useSelector(state => state.vendors.quickBooks.isQuickBooksActive);
  const isLoading = useSelector(state => state.vendors.quickBooks.isLoading);

  useEffect(() => {
    checkIsQuickBooksActive()(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = location.search;

    if (
      searchParams &&
      searchParams.includes('code') &&
      searchParams.includes('state') &&
      searchParams.includes('realmId')
    ) {
      const paramsList = searchParams.split('&');
      const code = getParamValue(paramsList, 'code=');
      const state = getParamValue(paramsList, 'state=');
      const realmId = getParamValue(paramsList, 'realmId=');

      if (code && state && realmId) {
        completeQuickBooksSetup({ code, state, realmId })(dispatch);
      }
    }
  }, [location.search, dispatch]);

  const startQuickBooksAuth = (clientId: string, clientSecret: string) => {
    quickBooksAuthorization(
      clientId, //"ABiYEjkUf5q6Pso1xx7VTEPuCGkU6NHROOADX2sag1sP1cBaj0",
      clientSecret, //"iTDy8IX81UQX3wop7e6a0WYRtBG6q8VyVQoKMTyy",
    )(dispatch);
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        disabled={isQuickBooksActive || isLoading}
        color="primary"
        size="small"
        line
      >
        {translate(`finance.quickBooksIntegration.${isQuickBooksActive ? 'quickBooksActive' : 'activateQuickBooks'}`)}
      </Button>
      <QuickBooksActivateModal
        startQuickBooksAuth={startQuickBooksAuth}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default QuickBooksButton;
