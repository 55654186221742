import { camelCase, debounce, every, map, size, some, sortBy } from 'lodash-es';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import { useSelector } from 'src/core/hooks/useSelector';
import WEEKDAY_SHORTCODES from '../../../common/constants/weekdayShortcodes';
import {
  Checkbox,
  Dropdown,
  HourPickerTimeSpan,
  Input,
  LocationPicker,
  PopoverWrapper,
  Switch,
  TypedField,
  UnconnectedCheckbox,
} from '../../../core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalSection,
  ModalTitle,
  Popover,
  Text,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import {
  hasCity,
  hasCoordinates,
  hasCountry,
  hasStreet,
  hasStreetNumber,
  hasZip,
  isRequired,
} from '../../../utils/services/validator';
import { loadFacilitySubType, vehicleTypesForVendorSelector } from '../../ducks';
import ContainersRelocationModalResolver from '../modal/ContainersRelocationModalResolver';
import { DaysOfOperationContainer, DaysOfOperationLabel, HoursOfOperationContainer } from '../styled/Facilities';
import { ContainersRelocationFormValues } from './ContainersRelocationForm';

type PropsWithoutReduxForm = {
  facilityId?: number;
  closeModal: (pristine: boolean) => void;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

export const FACILITY_EDITOR_FORM_NAME = 'facilityEditorForm';

const FacilityEditorForm = ({ facilityId, handleSubmit, closeModal, change, pristine }: Props) => {
  const [allVehiclesChecked, setAllVehiclesChecked] = useState(false);
  const [weekdayEnabled, setWeekdayEnabled] = useState(false);
  const [weekendEnabled, setWeekendEnabled] = useState(false);
  const [isContainersRelocationModalOpen, setIsContainersRelocationModalOpen] = useState(false);

  const dispatch = useDispatch();

  const vehicleTypes = useSelector(vehicleTypesForVendorSelector);
  const wasteTypes = useSelector(state => state.common.wasteTypes.wasteTypes);
  const facilityTypes = useSelector(state => state.fleet.facilityTypes.facilityTypes);
  const locationId = useSelector(state => state.fleet.facility.facility?.locationId);
  const facilityRateTypes = useSelector(state => state.fleet.facilityTypes.facilityRateTypes);
  const { facilitySubTypes, isLoading: isLoadingFacilitySubTypes } = useSelector(state => state.fleet.facilitySubTypes);
  const {
    facilityDaysOfOperation: daysOfOperation,
    facilityType: facilityTypeSelected,
    hasContainerAssociated,
    isActive,
    isWeightTicketEnabled,
    materialsAccepted: materialFormValues,
    weightTicketsDetails: weightTickets,
  } = useSelector(getFormValues(FACILITY_EDITOR_FORM_NAME)) || ({} as any);

  useEffect(() => {
    if (!facilityId) {
      const newVehicleTypes = map(vehicleTypes, vehicle => ({
        vehicleType: vehicle,
        isEnabled: true,
      }));
      const newWasteTypes = map(wasteTypes, waste => ({
        material: waste,
        rateType: { id: null },
        rateValue: null,
        isEnabled: false,
      }));
      dispatch(change('weightTicketsDetails', newVehicleTypes));
      dispatch(change('materialsAccepted', newWasteTypes));
    }
  }, [vehicleTypes, wasteTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClearHours = useCallback(
    (weekday: boolean, weekend: boolean) => {
      const days = daysOfOperation || [];
      if (!weekday && !weekend) {
        const reset = {
          ...days,
          weekHoursBegin: null,
          weekHoursEnd: null,
          weekendHoursBegin: null,
          weekendHoursEnd: null,
        };
        dispatch(change('facilityDaysOfOperation', reset));
      } else if (!weekend) {
        const reset = {
          ...daysOfOperation,
          weekendHoursBegin: null,
          weekendHoursEnd: null,
        };
        dispatch(change('facilityDaysOfOperation', reset));
      } else if (!weekday) {
        const reset = {
          ...daysOfOperation,
          weekHoursBegin: null,
          weekHoursEnd: null,
        };
        dispatch(change('facilityDaysOfOperation', reset));
      }
    },
    [change, dispatch, daysOfOperation],
  );

  const handleDayEnabled = useCallback(() => {
    const days = daysOfOperation || [];
    let weekdayEnabledL = false;
    let weekendEnabledL = false;

    if (size(days)) {
      if (days.isOpenSaturday || days.isOpenSunday) {
        weekendEnabledL = true;
      }
      if (days.isOpenMonday || days.isOpenTuesday || days.isOpenWednesday || days.isOpenThursday || days.isOpenFriday) {
        weekdayEnabledL = true;
      }

      if (weekdayEnabledL !== weekdayEnabled || weekendEnabledL !== weekendEnabled) {
        handleClearHours(weekdayEnabledL, weekendEnabledL);
        setWeekdayEnabled(weekdayEnabledL);
        setWeekendEnabled(weekendEnabledL);
      }
    }
  }, [daysOfOperation, handleClearHours, weekdayEnabled, weekendEnabled]);

  useEffect(() => {
    if (weightTickets && some(weightTickets, { isEnabled: false }) && allVehiclesChecked) {
      setAllVehiclesChecked(false);
    }
    handleDayEnabled();
    if (weightTickets && every(weightTickets, { isEnabled: true }) && !allVehiclesChecked) setAllVehiclesChecked(true);
  }, [weightTickets, daysOfOperation, allVehiclesChecked, handleDayEnabled]);

  const onFacilityTypeChange = useCallback(
    (_: unknown, selectedFacilityType: number) => {
      dispatch(change('facilitySubType', null));
      loadFacilitySubType(selectedFacilityType)(dispatch);
      if (!facilityId) {
        const newVehicleTypes = vehicleTypes.map(vehicle => ({
          vehicleType: vehicle,
          isEnabled: true,
        }));
        const newWasteTypes = wasteTypes.map(waste => ({
          material: waste,
          rateType: { id: null },
          rateValue: null,
          isEnabled: false,
        }));
        dispatch(change('weightTicketsDetails', newVehicleTypes));
        dispatch(change('materialsAccepted', newWasteTypes));
      }
    },
    [change, dispatch, facilityId, vehicleTypes, wasteTypes],
  );

  const onAddressChange = debounce((event, address) => {
    const newAddress = {
      ...address,
      line1: address.formattedAddress,
    };
    dispatch(change('address', newAddress));
  }, 800);

  const handleAllVehicles = useCallback(() => {
    const newChecked = !allVehiclesChecked;
    setAllVehiclesChecked(newChecked);
    const newWeightTickets = weightTickets.map((ticket: any) => ({
      ...ticket,
      isEnabled: newChecked,
    }));
    dispatch(change('weightTicketsDetails', newWeightTickets));
  }, [allVehiclesChecked, change, dispatch, weightTickets]);

  const handleWeightToggle = useCallback(() => {
    if (!!isWeightTicketEnabled) {
      const newWeightTickets = weightTickets.map((ticket: any) => ({
        ...ticket,
        isEnabled: false,
      }));
      dispatch(change('weightTicketsDetails', newWeightTickets));
    }
  }, [change, dispatch, isWeightTicketEnabled, weightTickets]);

  const handleWasteCheckBox = useCallback(
    (index: number) => () => {
      if (materialFormValues[index].isEnabled) {
        const newFormValues = [...materialFormValues];
        newFormValues[index] = { ...newFormValues[index], rateType: { id: null }, rateValue: null };
        dispatch(change('materialsAccepted', newFormValues));
      }
    },
    [change, dispatch, materialFormValues],
  );

  const handleIsActiveCheckBox = useCallback(
    e => {
      if (isActive) {
        if (facilityId && hasContainerAssociated) {
          e.preventDefault();
          setIsContainersRelocationModalOpen(true);
        } else {
          dispatch(change('isDefaultStartingLocation', false));
          dispatch(change('isDefaultIntermediateFacility', false));
        }
      }
    },
    [change, dispatch, facilityId, hasContainerAssociated, isActive],
  );

  const handleRelocateContainers = useCallback(
    ({ relocatedContainers }: ContainersRelocationFormValues) => {
      const containers = map(relocatedContainers, ({ containerNumber, ...rest }) => rest);
      dispatch(change('isDefaultStartingLocation', false));
      dispatch(change('isDefaultIntermediateFacility', false));
      dispatch(change('isActive', false));
      dispatch(change('relocatedContainers', containers));
      setIsContainersRelocationModalOpen(false);
    },
    [change, dispatch],
  );

  const sortedVehicleTypes = useMemo(() => {
    return sortBy(vehicleTypes, ['name']);
  }, [vehicleTypes]);

  const facilityTypeOptions = useMemo(() => {
    return facilityTypes.map((facilityType: any) => ({
      label: translate(`facilities.facilityTypes.${camelCase(facilityType.technicalName)}`),
      value: facilityType.id,
    }));
  }, [facilityTypes]);

  const facilitySubTypeOptions = useMemo(() => {
    return facilitySubTypes.map((facilitySubType: any) => ({
      label: translate(`facilities.facilitySubTypes.${camelCase(facilitySubType.technicalName)}`),
      value: facilitySubType.id,
    }));
  }, [facilitySubTypes]);

  const isDisposalFacility = facilityTypeSelected && facilityTypeSelected !== 4;
  const isOperationalFacility = facilityTypeSelected && facilityTypeSelected !== 3;

  return (
    <ModalSection padding="medium" fluid>
      <form onSubmit={handleSubmit}>
        <Grid margin="no no small" padding="no small no no" multiLine>
          <GridColumn size="12/12">
            <ModalTitle align="center" size="large" margin="no no xSmall">
              {translate(`facilities.${facilityId ? 'edit' : 'add'}Facility`)}
            </ModalTitle>
          </GridColumn>
          {locationId && facilityId && (
            <GridColumn align="center" size="12/12">
              <Text fontStyle="italic" color="grayDarker">{`${translate(
                `facilities.facilityId`,
              )}: ${locationId}`}</Text>
            </GridColumn>
          )}
        </Grid>

        <Grid padding="no small no no" multiLine>
          <GridColumn size="6/12">
            <TypedField
              name="facilityType"
              component={Dropdown}
              onChange={onFacilityTypeChange}
              validate={isRequired}
              props={{
                options: facilityTypeOptions,
                label: translate('facilities.facilityType'),
                disabled: !!facilityId,
                menuPosition: 'fixed',
              }}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              name="facilitySubType"
              component={Dropdown}
              options={facilitySubTypeOptions}
              isLoading={isLoadingFacilitySubTypes}
              label={translate('facilities.facilitySubType')}
              validate={[isRequired]}
              disabled={!facilityTypeSelected}
            />
          </GridColumn>
          <GridColumn size="12/12">
            <Field name="name" component={Input} label={translate('facilities.facilityName')} validate={[isRequired]} />
          </GridColumn>
          <GridColumn size="12/12">
            <TypedField
              name="address"
              component={LocationPicker}
              validate={[isRequired, hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
              onChange={onAddressChange}
              props={{
                label: translate('facilities.facilityAddress'),
              }}
            />
          </GridColumn>
          <GridColumn size="12/12">
            <Field name="owner" component={Input} label={translate('facilities.facilityOwner')} />
          </GridColumn>
          <GridColumn padding="xSmall xSmall no xSmall" size="4/12">
            <DaysOfOperationLabel>{translate('facilities.daysOfOperation')}</DaysOfOperationLabel>
          </GridColumn>
          <GridColumn size="8/12">
            <Grid padding="no" multiLine>
              {map(WEEKDAY_SHORTCODES, day => (
                <GridColumn key={day.translationKey} size="4/12" padding="xSmall no xSmall no">
                  <Field
                    name={`facilityDaysOfOperation.${camelCase(day.name)}`}
                    component={Checkbox}
                    label={translate(day.translationKey)}
                  />
                </GridColumn>
              ))}
            </Grid>
          </GridColumn>
          <GridColumn padding="small xSmall no xSmall" size="12/12">
            <DaysOfOperationContainer>
              <GridColumn size="4/12" padding="no">
                <DaysOfOperationLabel>{translate('facilities.weekdayHours')}</DaysOfOperationLabel>
              </GridColumn>
              <HoursOfOperationContainer>
                <GridColumn size="5/12" padding="no" shift="1/12">
                  <Field
                    name="facilityDaysOfOperation.weekHoursBegin"
                    component={HourPickerTimeSpan}
                    label={translate('common.from')}
                    disabled={!weekdayEnabled}
                    menuPosition="fixed"
                  />
                </GridColumn>
                <GridColumn size="5/12" padding="no" shift="1/12">
                  <Field
                    name="facilityDaysOfOperation.weekHoursEnd"
                    component={HourPickerTimeSpan}
                    label={translate('common.to')}
                    disabled={!weekdayEnabled}
                    menuPosition="fixed"
                  />
                </GridColumn>
              </HoursOfOperationContainer>
            </DaysOfOperationContainer>
          </GridColumn>

          <GridColumn padding="no xSmall small xSmall" size="12/12">
            <DaysOfOperationContainer>
              <GridColumn size="4/12" padding="no">
                <DaysOfOperationLabel>{translate('facilities.weekendHours')}</DaysOfOperationLabel>
              </GridColumn>
              <HoursOfOperationContainer>
                <GridColumn size="5/12" padding="no" shift="1/12">
                  <Field
                    name="facilityDaysOfOperation.weekendHoursBegin"
                    component={HourPickerTimeSpan}
                    label={translate('common.from')}
                    disabled={!weekendEnabled}
                    menuPosition="fixed"
                  />
                </GridColumn>
                <GridColumn size="5/12" padding="no" shift="1/12">
                  <Field
                    name="facilityDaysOfOperation.weekendHoursEnd"
                    component={HourPickerTimeSpan}
                    label={translate('common.to')}
                    disabled={!weekendEnabled}
                    menuPosition="fixed"
                  />
                </GridColumn>
              </HoursOfOperationContainer>
            </DaysOfOperationContainer>
          </GridColumn>
          {isDisposalFacility && (
            <Fragment>
              <GridColumn size="12/12">
                <Text block size="medium" weight="light" margin="no no xSmall">
                  {translate('facilities.materialAccepted')}
                </Text>
              </GridColumn>
              <Grid multiLine height={200}>
                {map(wasteTypes, (materialsAccepted, index) => (
                  <Fragment key={materialsAccepted.id}>
                    <GridColumn size="6/12" padding="xSmall">
                      <Field
                        component={Checkbox}
                        name={`materialsAccepted[${index}].isEnabled`}
                        label={materialsAccepted.name}
                        onChange={handleWasteCheckBox(index)}
                      />
                    </GridColumn>
                    <GridColumn size="3/12">
                      <Field
                        component={Dropdown}
                        name={`materialsAccepted[${index}].rateType.id`}
                        placeholder={translate('facilities.facilityRateType')}
                        options={facilityRateTypes}
                        disabled={materialFormValues && !materialFormValues[index].isEnabled}
                        validate={materialFormValues && materialFormValues[index].isEnabled ? [isRequired] : undefined}
                      />
                    </GridColumn>
                    <GridColumn size="3/12">
                      <Field
                        name={`materialsAccepted[${index}].rateValue`}
                        parse={(value: any) => Number(value)}
                        placeholder={translate('facilities.facilityRateValue')}
                        component={Input}
                        type="number"
                        min={0}
                        disabled={materialFormValues && !materialFormValues[index].isEnabled}
                        validate={materialFormValues && materialFormValues[index].isEnabled ? [isRequired] : undefined}
                      />
                    </GridColumn>
                  </Fragment>
                ))}
              </Grid>
            </Fragment>
          )}
          {isDisposalFacility && (
            <Fragment>
              <GridColumn size="12/12" padding="medium xSmall small">
                <Field
                  name="isWeightTicketEnabled"
                  component={Switch}
                  onChange={handleWeightToggle}
                  label={translate('facilities.weightTicket')}
                />
              </GridColumn>
              <GridColumn size="3/12" padding="xSmall xSmall">
                <UnconnectedCheckbox
                  label={translate('vehicles.allVehicles')}
                  checked={allVehiclesChecked}
                  onChange={handleAllVehicles}
                  disabled={!isWeightTicketEnabled}
                />
              </GridColumn>
              <GridColumn size="9/12">
                <Grid multiLine>
                  {sortedVehicleTypes.map((sortedVehicleType, index) => (
                    <Fragment key={index}>
                      <GridColumn padding="xSmall small" size="6/12">
                        <Field
                          name={`weightTicketsDetails.${index}.isEnabled`}
                          component={Checkbox}
                          label={translate(`vehicles.vehicleTypes.${camelCase(sortedVehicleType.technicalName)}`)}
                          disabled={!isWeightTicketEnabled}
                        />
                      </GridColumn>
                    </Fragment>
                  ))}
                </Grid>
              </GridColumn>
            </Fragment>
          )}

          <GridColumn size="3/12" padding="medium xSmall xxSmall">
            <Field
              name="isActive"
              component={Checkbox}
              label={translate('common.active')}
              onChange={handleIsActiveCheckBox}
            />
          </GridColumn>

          {/** TODO Intermediate Facility */}
          {/* {isDisposalFacility && (
              <GridColumn size="9/12" padding="medium xSmall xxSmall">
                <GridColumn size="12/12" padding="no small">
                  <Field
                    name="isDefaultIntermediateFacility"
                    component={Checkbox}
                    label={translate('routes.defaultIntermediateFacility')}
                    props={{
                      disabled: !isActive,
                    }}
                  />
                </GridColumn>
              </GridColumn>
            )} */}

          {isOperationalFacility && (
            <GridColumn size="9/12" padding="medium xSmall xxSmall">
              <GridColumn size="12/12" padding="no small">
                <PopoverWrapper
                  triggerButton={
                    <Field
                      name="isDefaultStartLocation"
                      component={Checkbox}
                      label={translate('facilities.defaultStartLocation')}
                      disabled={!isActive}
                    />
                  }
                  popoverContent={<Popover>{translate('facilities.defaultStartLocationPopover')}</Popover>}
                />
              </GridColumn>
            </GridColumn>
          )}

          <GridColumn size="12/12" padding="no">
            <ButtonSet align="center" margin="large auto no">
              <Button type="submit" id="facility-form-save-button" color="primary">
                {translate('common.save')}
              </Button>
              <Button type="button" margin="no small" color="secondary" onClick={() => closeModal(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </form>
      {!!isContainersRelocationModalOpen && facilityId && (
        <ContainersRelocationModalResolver
          facilityId={facilityId}
          closeModal={() => setIsContainersRelocationModalOpen(false)}
          onSubmit={handleRelocateContainers}
        />
      )}
    </ModalSection>
  );
};

export default reduxForm({
  form: FACILITY_EDITOR_FORM_NAME,
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(FacilityEditorForm);
