import { FleetMaintenance } from '../interfaces/FleetMaintenance';
import { http } from '../../core/services/http';
import { transformSaveFleetMaintenance } from './transformFleetMaintenance';

export const loadFleetMaintenance = (vendorId: number) =>
  http.get(`/vendors/${vendorId}/fleetMaintenanceSettings`).then(response => response.data);

export const saveFleetMaintenance = (vendorId: number, fleetMaintenance: FleetMaintenance) =>
  http
    .post(`/vendors/${vendorId}/fleetMaintenanceSettings`, transformSaveFleetMaintenance(fleetMaintenance))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
