import styled, { css } from 'styled-components';
import { loadingOverlay } from 'src/core/styles';
import { mapper } from 'src/utils/styles';

interface WidgetsContainerProps {
  position: 'left' | 'right';
}

export const WidgetsContainer = styled.div<WidgetsContainerProps>`
  position: absolute;
  z-index: 99;
  top: 0;
  width: 370px;
  padding-left: 20px;
  height: calc(100% - 170px);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;

  ${props =>
    props.position === 'left' &&
    css`
      left: 0;
    `}
  ${props =>
    props.position === 'right' &&
    css`
      right: 0;
    `}
`;

interface WidgetProps {
  isOpen: boolean;
  titleWidth?: number;
  marginTop?: number;
  size?: 'small' | 'medium' | 'large';
}

export const WidgetHeader = styled.div<WidgetProps>`
  display: flex;
  align-items: stretch;
  background-color: #fff;
  border-radius: 15px 0 0 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.3s ease-in-out;
  flex: 1;

  #widget-title {
    display: block;
    word-break: keep-all;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.isOpen &&
    css`
      background-color: ${props => props.theme.brandPrimary};
      & div {
        color: #fff;
      }
      #widget-title {
        color: #fff;
      }
    `};

  ${props =>
    props.isOpen &&
    css`
      border-bottom-left-radius: 0;
    `};
`;

export const WidgetWrapper = styled.div<WidgetProps>`
  position: relative;
  transition: transform 0.3s ease-in-out;
  padding-bottom: 10px;
  width: ${props => mapper(props.size, { medium: '350px', small: '200px', large: '450px' }, 'medium')};
  margin-top: ${props => props.marginTop || 0}px;
  pointer-events: auto;

  ${props =>
    props.isOpen
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(calc(100% - 40px));
          &:hover {
            ${WidgetHeader} {
              transform: translateX(calc(-1 * ${props.titleWidth || 0}px));
            }
          }
        `}
`;

export const WidgetContent = styled.div<{ isLoading?: boolean }>`
  padding: 5px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 0 0 0 2px;
  transition: unset;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-bottom: 20px;

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px', '#fff', 0.05, 2300)};
    `};

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${props => props.theme.brandPrimary};
    margin-right: 5px;
  }
`;

export const WidgetDragHandleContainer = styled.div`
  position: relative;
  top: 15px;
  z-index: 2000;
  height: 15px;
  width: 30px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  cursor: move;
`;
export const WidgetDragHandleBackground = styled.div`
  position: absolute;
  top: -30px;
  z-index: 1999;
  left: 0;
  height: 25px;
  width: 100%;
  overflow: visible;
  border-radius: 0 0 0 15px;
  cursor: move;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.40800070028011204) 92%,
    rgba(255, 255, 255, 0.13629201680672265) 100%
  );
`;
