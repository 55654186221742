import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const ACTIVE = true;
export const INACTIVE = false;

export const VENDOR_STATUSES = mapKeys(
  [
    { id: ACTIVE, name: translate('vendors.vendorStatuses.active') },
    { id: INACTIVE, name: translate('vendors.vendorStatuses.inactive') },
  ],
  'id',
);
