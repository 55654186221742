import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from 'src/core/components/styled/Box';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { getVehicleInsightIconType } from './utils';
import {
  MapGLPopupWrapper,
  MapGLPopup,
  MapGLPopupTitle,
  MapGLPopupContent,
} from 'src/common/components/map/MapGLPopup';
import { time } from 'src/utils/services/formatter';
import { useSelector } from 'src/core/hooks/useSelector';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

export default function RouteMapVehicleInsightsGLPopup() {
  const dispatch = useDispatch();
  const { vehicleInsights } = useSelector(state => state.routes.routeMapVehicleInsights);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { vehicleTypes } = useSelector(state => state.fleet.vehicleTypes);
  const { vehicleTypesForVendor } = useSelector(state => state.fleet.vehicleTypesForVendor);

  const vehicleInsight = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.vehicleInsights) {
      return undefined;
    }

    return vehicleInsights.find(insight => insight.insightId === selectedFeature.id);
  }, [selectedFeature, vehicleInsights]);

  if (!vehicleInsight) {
    return null;
  }

  const iconType = getVehicleInsightIconType(vehicleInsight);

  const currentVehicleTypes = !!vehicleTypes.length
    ? vehicleTypes
    : !!vehicleTypesForVendor.length
    ? vehicleTypesForVendor
    : [];

  const translationKeys = {
    insightTypeName: createTranslationKey(vehicleInsight.insightType.name, 'dashboard'),
    vehicleTypeName: createTranslationKey(
      currentVehicleTypes.find(vehicleType => vehicleType.id === vehicleInsight.vehicleTypeId)?.technicalName,
      'vehicles.vehicleTypes',
    ),
    reportDateTimeLabel: createTranslationKey(vehicleInsight.reportDateTimeLabel, 'dashboard'),
    insightMessageLabel: createTranslationKey(vehicleInsight.insightMessageLabel, 'dashboard'),
    insightFormattedLabel: createTranslationKey(vehicleInsight.insightType.insightFormattedLabel, 'dashboard'),
    insightFormattedTotalLabel: createTranslationKey(
      vehicleInsight.insightType.insightFormattedTotalLabel,
      'dashboard',
    ),
  };

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={vehicleInsight.latitude}
      longitude={vehicleInsight.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup>
        <MapGLPopupTitle
          beforeTitleContent={
            <Box mr={8} width={15} height={15}>
              <img src={iconType?.iconUrl} width="15" height="15" alt="" />
            </Box>
          }
          title={translate(`${translationKeys.insightTypeName}`)}
        />

        <MapGLPopupContent>
          {!!vehicleInsight.vehicleName && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.truck')}
              value={`${vehicleInsight.vehicleName} - ${translate(`${translationKeys.vehicleTypeName}`)}`}
            />
          )}

          {!!vehicleInsight.reportDateTime && (
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate(`${translationKeys.reportDateTimeLabel}`)}
              value={time(vehicleInsight.reportDateTime)}
            />
          )}

          {!!vehicleInsight.insightMessageValue && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate(`${translationKeys.insightMessageLabel}`)}
              value={
                vehicleInsight.messageFormatUi
                  ? translate(`dashboard.${vehicleInsight.messageFormatUi}`, {
                      insightValue: vehicleInsight.value1Formatted,
                      insightSecondaryValue: vehicleInsight.value2Formatted,
                    })
                  : vehicleInsight.insightMessageValue
              }
            />
          )}

          {!!vehicleInsight.insightType.insightFormattedValue && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate(`${translationKeys.insightFormattedLabel}`)}
              value={vehicleInsight.insightType.insightFormattedValue}
            />
          )}

          {!!vehicleInsight.insightType.insightFormattedTotalValue && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate(`${translationKeys.insightFormattedTotalLabel}`)}
              value={vehicleInsight.insightType.insightFormattedTotalValue}
            />
          )}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
