import { isEqual, omit } from 'lodash-es';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CityAlertSettingsForm } from '../forms';
import { CityAlertSettingsFormValues } from '../forms/CityAlertSettingsForm';
import { CityAlertSettingsToSave, CityAlertTypeToSave } from 'src/vendors/interfaces/CityAlertSettings';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { Modal } from 'src/core/components';
import { OPTIONAL_ID, REQUIRED_ID } from 'src/common/constants';
import { saveCityAlertSettings } from 'src/vendors/ducks';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { useSelector } from 'src/core/hooks/useSelector';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

const modalId = 'city-alerts-seetings-modal';

interface Props {
  closeModal: (pristine: boolean) => void;
}

const CityAlertSettingsModal: FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { isLoading, isSaving, cityAlertSettings } = useSelector(state => state.vendors.cityAlertSettings);

  const initialValues = useMemo(() => {
    return {
      cityAlertTypes: cityAlertSettings?.cityAlertTypes.reduce((acc, cityAlertSetting) => {
        acc[cityAlertSetting.cityAlertType.technicalName] = {
          ...cityAlertSetting,
          canTakePicture: isEqual(REQUIRED_ID, cityAlertSetting.canTakePicture?.id),
        };
        return acc;
      }, {} as any),
    };
  }, [cityAlertSettings]);

  const handleSubmit = useCallback(
    (v: CityAlertSettingsFormValues) => {
      // reformat values to match the API

      const alertTypes = Object.keys(v.cityAlertTypes).map(key => {
        return {
          ...omit(v.cityAlertTypes[key], [
            'proximityUnitMeasurementType',
            'cityAlertType',
            'canTakePicture',
            'proximityValue',
            'expirationUnitTimeMeasurementType',
          ]),
          canTakePictureTypeId: v.cityAlertTypes[key].canTakePicture ? REQUIRED_ID : OPTIONAL_ID,
          cityAlertTypeId: v.cityAlertTypes[key].cityAlertType.id,
          expirationUnit: v.cityAlertTypes[key].expirationUnit
            ? Number(v.cityAlertTypes[key].expirationUnit)
            : undefined,
          expirationUnitTimeMeasurementTypeId: v.cityAlertTypes[key].expirationUnitTimeMeasurementType.id,
          proximityUnitOfMeasureTypeId: v.cityAlertTypes[key].proximityUnitMeasurementType.id,
          proximityValue: v.cityAlertTypes[key].proximityValue,
        } as CityAlertTypeToSave;
      });

      const settings: CityAlertSettingsToSave = {
        cityAlertTypes: alertTypes,
      };

      scrollToTopOfModal(modalId);

      saveCityAlertSettings(
        vendorId,
        settings,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('vendors.alertMessages.cityAlertSettingsSaved'));
          closeModal(true);
        })
        .catch(({ code }) => {
          createErrorNotification(`${translate(createTranslationKey(code, 'vendors.alertMessages'))}`);
        });
    },
    [closeModal, dispatch, vendorId],
  );

  return (
    <Modal
      title={translate('vendors.featureCodes.cityAlertNew')}
      isLoading={isLoading || isSaving}
      size="large"
      padding="medium no no"
      id={modalId}
      overflow={isSaving ? 'hidden' : 'auto'}
    >
      <CityAlertSettingsForm onCancel={closeModal} initialValues={initialValues} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default CityAlertSettingsModal;
