import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ServiceHistory } from '../interfaces/ServiceHistory';
import { loadAdhocServiceHistory as doLoadAdhocServiceHistory } from '../services/adhocServiceHistory';

// Actions
const START_LOAD = 'customers/adhocServiceHistory/START_LOAD';
const COMPLETE_LOAD = 'customers/adhocServiceHistory/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/adhocServiceHistory/FAIL_LOAD';
const RESET = 'customers/adhocServiceHistory/RESET';

interface State {
  adhocServiceHistory: ServiceHistory[];
  isLoading: boolean;
  total?: number;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  adhocServiceHistory: [],
  isLoading: false,
  total: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          adhocServiceHistory: action.adhocServiceHistory,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          adhocServiceHistory: [],
          total: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (adhocServiceHistory: ServiceHistory[], total: number) => ({
  type: COMPLETE_LOAD,
  adhocServiceHistory,
  total,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadAdhocServiceHistory =
  (
    vendorId: number,
    locationId: string,
    pickupStatusTypeIds: string,
    startDate: Date | string,
    endDate: Date | string,
    workOrder: string | undefined,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadAdhocServiceHistoryPromise = doLoadAdhocServiceHistory(
      vendorId,
      locationId,
      pickupStatusTypeIds,
      startDate,
      endDate,
      workOrder,
    );
    loadAdhocServiceHistoryPromise
      .then(({ serviceHistory, total }) => dispatch(completeLoad(serviceHistory, total)))
      .catch(() => dispatch(failLoad()));
    return loadAdhocServiceHistoryPromise;
  };

export const resetServiceHistory = () => ({
  type: RESET,
});
