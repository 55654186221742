import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { loadDeviceManufacturerTypeList, loadVehicleCameraConfigurations } from '../../ducks/vehicles';
import { loadVehicle, loadVehicleFacilites, loadVehicleTypesForVendor, loadBinColors } from '../../ducks';
import { loadVehicleTypes } from '../../ducks/vehicleTypes';
import { PageLoading } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import translate from '../../../core/services/translate';
import VehicleEditorPage from './VehicleEditorPage';

interface RouteParams {
  vehicleId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  loadBinColors: DuckFunction<typeof loadBinColors>;
  loadDeviceManufacturerTypeList: DuckFunction<typeof loadDeviceManufacturerTypeList>;
  loadVehicle: DuckFunction<typeof loadVehicle>;
  loadVehicleCameraConfigurations: DuckFunction<typeof loadVehicleCameraConfigurations>;
  loadVehicleFacilites: DuckFunction<typeof loadVehicleFacilites>;
  loadVehicleTypes: DuckFunction<typeof loadVehicleTypes>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  vendorId: number;
}
class VehicleEditorPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      loadBinColors,
      loadDeviceManufacturerTypeList,
      loadVehicle,
      loadVehicleCameraConfigurations,
      loadVehicleFacilites,
      loadVehicleTypes,
      loadVehicleTypesForVendor,
      vendorId,
      match: {
        params: { vehicleId },
      },
    } = this.props;

    if (!vendorId) {
      return Promise.all([]);
    }

    const dependencies = [
      loadDeviceManufacturerTypeList(),
      loadVehicleTypesForVendor(vendorId),
      loadVehicleTypes(),
      loadBinColors(),
      loadVehicleCameraConfigurations(),
      loadVehicleFacilites(vendorId),
    ];

    if (vehicleId) {
      dependencies.push(loadVehicle(+vehicleId));
    }

    return Promise.all(dependencies);
  };

  render() {
    const {
      match: {
        params: { vehicleId },
      },
      vendorId,
    } = this.props;

    return (
      <>
        <DocumentTitle>
          {vehicleId ? translate('vehicles.editVehicle') : translate('vehicles.createVehicle')}
        </DocumentTitle>

        <TypedResolver
          successComponent={VehicleEditorPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/fleet/vehicles"
          successProps={{
            vendorId,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorId(state),
});

const mapDispatchToProps = {
  loadBinColors,
  loadDeviceManufacturerTypeList,
  loadVehicle,
  loadVehicleCameraConfigurations,
  loadVehicleFacilites,
  loadVehicleTypes,
  loadVehicleTypesForVendor,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleEditorPageResolver));
