import { http, httpInsightsReporting } from '../../core/services/http';
import transformLoadDeviceStatuses from './transformLoadDeviceStatuses';
import translate from '../../core/services/translate';
import { downloadExcelFile, downloadInsightsExcelFile } from '../../utils/services/downloadExcelFile';

export const loadYDeviceStatuses = (
  vendorId: number,
  vehicleTypeId: number,
  sortOrder: string,
  sortDirection: string,
  page: number,
  limit: number,
) =>
  httpInsightsReporting
    .get('device', {
      params: {
        vendorId,
        vehicleTypeId,
        sortOrder,
        sortDirection,
        page,
        limit,
      },
    })
    .then(response => transformLoadDeviceStatuses(response.data));

export const exportDeviceStatuses = (
  vendorId: number,
  vehicleTypeId: number,
  sortOrder: string,
  sortDirection: string,
) =>
  downloadInsightsExcelFile('export/device', translate('common.export'), {
    vendorId,
    vehicleTypeId,
    sortOrder,
    sortDirection,
  });

export const exportXDeviceStatuses = (vendorId: number, searchTerm: string) =>
  downloadExcelFile(`/settings/vendor/${vendorId}/export/xDevicesStatus`, 'xDeviceReport', {
    searchFilter: searchTerm,
  });

export const loadXDeviceStatuses = (vendorId: number) =>
  http.get(`/settings/vendor/${vendorId}/xDevicesStatus`).then(response => response.data);

export const deleteXDevice = (deviceId: string) =>
  http.delete(`/settings/devices/${deviceId}/unregister`).then(response => response.data);

export const updateXDeviceData = (deviceOwnerTypeId: number, phoneName: string, serialNumber: string, uniqueDeviceIdentifier: string) => {
  const xDeviceData = {
    deviceOwnerTypeId,
    phoneName,
    serialNumber,
    uniqueDeviceIdentifier,
  };

  return http.put('/account/device/updateDeviceHybridDetails', xDeviceData).then(response => response.data);
};
