import React, { PureComponent } from 'react';

import { camelCase, filter, map } from 'lodash-es';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';

import { AppState } from 'src/store';
import { RouteMap } from '../../';
import { PermissionGuard } from '../../../../account/components';
import { ROUTES_TRACKER_VIEW_EXPORT } from '../../../../account/constants';
import routeAssistance from '../../../../common/assets/img/insights/routeAssistance.png';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
} from '../../../../common/components/styled';
import { SCHEDULED } from '../../../../common/constants';
import { MapDragHandle, Table } from '../../../../core/components';
import {
  Button,
  Grid,
  Label,
  MapContainer,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  TableActionImage,
} from '../../../../core/components/styled';
import { multiWordAndSearch } from '../../../../core/services/search';
import translate from '../../../../core/services/translate';
import { TABLE_ROW_HEIGHT_SMALL } from '../../../../core/constants';
import { loadYServices } from '../../../../customers/ducks';
import { resetVehicleTypesForVendor, technicalNameByVehicleTypeIdSelector } from '../../../../fleet/ducks';
import { date, duration } from '../../../../utils/services/formatter';
import { ROUTE_STATUSES_BY_ID, ROUTE_LABEL_COLORS } from '../../../constants';
import {
  completedStopCountSelector,
  containerInsightsSelector,
  exportYRoute,
  getUnitOfMeasureSelector,
  resetRoute,
  resetRouteMapDetails,
  vehiclePositionsSelector,
  vehicleTrackingsSelector,
} from '../../../ducks';
import { RouteLocationsForm } from '../../forms';
import { RouteSummary } from '../routes/routePageSections';
import YRoutePageTableRow from './YRoutePageTableRow';
import { Route } from 'src/routes/interfaces/Route';
import {
  ContainerInsights,
  VehicleTracking,
  VehiclePosition,
} from '../../../../routes/components/mapWithTimeline/Interfaces';
import { DuckAction, DuckFunction } from '../../../../contracts/ducks';

interface Props extends RouteComponentProps {
  containerInsights: ContainerInsights[];
  exportYRoute: DuckFunction<typeof exportYRoute>;
  history: any;
  isExporting: boolean;
  loadYServices: DuckFunction<typeof loadYServices>;
  push: Push;
  resetRoute: DuckAction<typeof resetRoute>;
  resetRouteMapDetails: DuckAction<typeof resetRouteMapDetails>;
  resetVehicleTypesForVendor: DuckAction<typeof resetVehicleTypesForVendor>;
  route: Route;
  unitOfMeasure?: string;
  vehiclePositions: VehiclePosition[];
  vehicleTrackings: VehicleTracking[];
  vehicleTypeTechnicalName: string;
}

interface State {
  containerInsights: ContainerInsights[];
  locationId?: number;
  mapShouldFitBounds?: boolean;
  pickupStatusId?: string | number;
  routeLocations: any[];
  searchTerm?: string;
  selectedLocation?: any;
}

class YRoutePage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      containerInsights: props.containerInsights,
      locationId: undefined,
      mapShouldFitBounds: undefined,
      pickupStatusId: undefined,
      routeLocations: props.route.routeLocations || [],
      searchTerm: undefined,
      selectedLocation: undefined,
    };
  }

  componentWillUnmount() {
    const { resetRoute, resetRouteMapDetails, resetVehicleTypesForVendor } = this.props;

    resetRoute();
    resetRouteMapDetails();
    resetVehicleTypesForVendor();
  }

  onSearchTermChange = (searchTerm: string) => {
    this.filterLocations(searchTerm, this.state.pickupStatusId);
  };

  exportYRoute = () => {
    const {
      route: { id },
      exportYRoute,
    } = this.props;
    exportYRoute(id);
  };

  filterLocations = (searchTerm: string, pickupStatusId?: string | number) => {
    const filteredRouteLocations = filter(
      this.props.route?.routeLocations,
      routeLocation =>
        ((!pickupStatusId || routeLocation.pickupStatusId === pickupStatusId) &&
          (!searchTerm ||
            multiWordAndSearch(routeLocation.customer.name, searchTerm) ||
            multiWordAndSearch(routeLocation.location.name, searchTerm) ||
            multiWordAndSearch(routeLocation.location.address.line1, searchTerm))) ||
        multiWordAndSearch(routeLocation.customer.accountNumber, searchTerm) ||
        multiWordAndSearch(routeLocation.location.vendorAccountNo, searchTerm),
    );

    const filteredContainerInsights = filter(this.props.containerInsights, containerInsight =>
      filteredRouteLocations.some(
        routeLocation =>
          routeLocation.id ===
          (containerInsight.isYRoute ? containerInsight.insightId : containerInsight.routeLocationId),
      ),
    );

    this.setState({
      searchTerm,
      pickupStatusId,
      routeLocations: filteredRouteLocations,
      containerInsights: filteredContainerInsights,
    });
  };

  handleBackAction = () => {
    const {
      history: { length, goBack },
      push,
    } = this.props;

    return length > 1 ? goBack() : push('/routes/route-tracker');
  };

  selectLocation = (routeLocation: any, event: any) => {
    event.stopPropagation();
    const {
      id,
      orderNumber,
      customerName,
      locationName,
      address: { latitude, longitude },
    } = routeLocation;
    const binLatitude = latitude;
    const binLongitude = longitude;
    const selectedLocation = {
      id,
      orderNumber,
      customerName,
      locationName,
      latitude: binLatitude,
      longitude: binLongitude,
    };
    const isTableRowClicked = event ? camelCase(event.target.parentNode.tagName) !== 'label' : false;

    this.setState({
      selectedLocation,
      mapShouldFitBounds: isTableRowClicked,
    });
  };

  render() {
    const {
      route: {
        id,
        routeName,
        routeDate,
        routeStatusTypeId,
        routeTotalTime,
        routeLocations,
        vehicleName,
        vehicleTypeName,
        routeAssistanceEnabled,
      },
      vehiclePositions,
      vehicleTrackings,
      isExporting,
      vehicleTypeTechnicalName,
      unitOfMeasure,
      loadYServices,
    } = this.props;

    const { mapShouldFitBounds } = this.state;

    const tableCellWidths = ['8%', '60%', '32%'];

    const routeLocationsTableCells = [
      { name: 'orderNumber', label: translate('routes.stopNumber'), width: tableCellWidths[0], sortable: true },
      { name: 'customerName', label: translate('common.customer'), width: tableCellWidths[1], sortable: true },
      { name: 'pickupStatusId', label: translate('common.status'), width: tableCellWidths[2], sortable: true },
    ];

    const routeLocationsTableRows = map(this.state.routeLocations, ({ customer, location, ...routeLocation }) => ({
      ...routeLocation,
      customerName: customer.name,
      accountNumber: customer.accountNumber,
      locationName: location.address.line1,
      locationId: location.id,
      address: location.address,
      vendorAccountNo: location.vendorAccountNo,
    }));

    const virtualizedProps = {
      itemSize: TABLE_ROW_HEIGHT_SMALL,
      height: Math.min(this.state.routeLocations.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
    };

    return (
      <PageContent>
        <PageHeader withSubTitle>
          <PageDetails withBackButton>
            <Grid>
              <Label color={(ROUTE_LABEL_COLORS as any)[routeStatusTypeId]} id="route-status">
                {ROUTE_STATUSES_BY_ID[routeStatusTypeId].name}
              </Label>
              {routeAssistanceEnabled && <TableActionImage src={routeAssistance} />}
            </Grid>
            <PageTitleContainer>
              <PageBackButtonAction onClick={this.handleBackAction} id="back-button">
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle textTransform="none">{routeName}</PageTitle>

              {!!routeDate && <PageSubtitle> {date(routeDate)} </PageSubtitle>}
              {!!routeTotalTime && (
                <PageSubtitle>
                  {translate('routes.totalTime')}: {duration(routeTotalTime, 'minutes', 'hh:mm')}
                </PageSubtitle>
              )}
            </PageTitleContainer>
          </PageDetails>

          <PageActions>
            {/* REMOVED Y ROUTE BETA PAGE */}
            {/* <BetaBaseWrapper inline>
              <BetaButton
                noUnderline
                align="right"
                padding="no"
                color="primary"
                margin="no small no no"
                onClick={() => {
                  push(`/routes/y-daily-routes-beta/${id}`);
                }}
              >
                {translate('routes.tryBeta')}
              </BetaButton>
            </BetaBaseWrapper> */}
            <PermissionGuard permission={ROUTES_TRACKER_VIEW_EXPORT}>
              <Button color="primary" onClick={this.exportYRoute} margin="no xSmall no no" id="export-y-route-button">
                {translate('common.export')}
              </Button>
            </PermissionGuard>
          </PageActions>
        </PageHeader>

        <Panel margin="no no xLarge">
          <PanelSectionGroup isLoading={isExporting}>
            <PanelSection padding="medium sMedium" tabletPadding="medium sMedium no sMedium" withBorder>
              <RouteSummary
                routeStatusTypeId={routeStatusTypeId}
                vehicleName={vehicleName}
                vehicleTypeName={vehicleTypeName}
                totalStopCount={routeLocations.length}
                isYRoute
              />
            </PanelSection>

            {!!routeLocations.length && <RouteLocationsForm onSearchTermChange={this.onSearchTermChange} />}

            {!!routeLocations.length && (
              <PanelSection>
                <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                  <MapContainer>
                    <RouteMap
                      containerInsights={this.state.containerInsights}
                      isGeoFenceHidden
                      isYRoute
                      mapShouldFitBounds={mapShouldFitBounds}
                      routeId={id}
                      routeLocations={routeStatusTypeId === SCHEDULED ? this.state.routeLocations : []}
                      routeStatusTypeId={routeStatusTypeId}
                      selectedLocation={this.state.selectedLocation}
                      unitOfMeasure={unitOfMeasure}
                      vehiclePositions={vehiclePositions}
                      vehicleTrackings={vehicleTrackings}
                    />
                  </MapContainer>
                </Resizable>
              </PanelSection>
            )}

            {!this.state.routeLocations.length && (
              <Message padding="sMedium">{translate('routes.noRouteLocations')}</Message>
            )}

            {!!this.state.routeLocations.length && (
              <PanelSection>
                <Table
                  virtualized
                  cells={routeLocationsTableCells}
                  rows={routeLocationsTableRows}
                  rowComponent={YRoutePageTableRow}
                  rowProps={{
                    routeId: id,
                    vehicleTypeTechnicalName,
                    selectLocation: this.selectLocation,
                    loadYServices,
                  }}
                  virtualizedProps={virtualizedProps}
                  withClickableRows
                  noOverflow
                />
              </PanelSection>
            )}
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  completedStopCount: completedStopCountSelector(state.routes.route),
  containerInsights: containerInsightsSelector(state.routes.routeMapDetails) || [],
  isExporting: state.routes.route.isExporting,
  lastLocations: state.core.lastLocations,
  route: state.routes.route.route || ({} as Route),
  unitOfMeasure: getUnitOfMeasureSelector(state.routes.routeMapDetails.routeMapDetails),
  userName: state.account.login.user.email,
  vehiclePositions: vehiclePositionsSelector(state.routes.routeMapDetails) || [],
  vehicleTrackings: vehicleTrackingsSelector(state.routes.routeMapDetails) || [],
  vehicleTypeTechnicalName: (technicalNameByVehicleTypeIdSelector as any)(
    state.fleet.vehicleTypesForVendor,
    state.routes.route.route?.vehicleTypeId,
  ),
});

const mapDispatchToProps = {
  exportYRoute,
  resetRoute,
  resetRouteMapDetails,
  resetVehicleTypesForVendor,
  push,
  loadYServices,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YRoutePage));
