import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import {
  ChipInputWrapper,
  ChipInputField,
  ChipInputChips,
  ChipInputChip,
  ChipInputChipButton,
} from './styled/ChipInput';
import Icon from './Icon';
import { FormError } from './styled';
import FormMeta from './styled/FormMeta';

interface Props extends WrappedFieldProps {
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  errorOnSingleLine?: boolean;
  showErrorBeforeSubmit?: boolean;
  margin?: string;
  metaTextOnFocus?: string;
  validateContent?: (newChip: string) => string | undefined;
  onTyping?: (typing: boolean) => void;
}

const ChipInput: React.FC<Props> = ({
  input: { value, onChange, name },
  meta: { submitFailed, error },
  errorOnSingleLine,
  showErrorBeforeSubmit,
  margin,
  metaTextOnFocus,
  validateContent = () => undefined,
  onTyping = () => {},
  ...inputProps
}) => {
  const [localError, setLocalError] = React.useState<string | null>(null);
  const [focused, setFocused] = React.useState<boolean>(false);
  const chips = value instanceof Array ? value : [];

  const removeChip = (index: number) => {
    if (index < 0 || index > chips.length - 1) {
      return;
    }

    const newChips = chips.slice();

    newChips.splice(index, 1);
    onChange(newChips);
  };

  const preventDefault = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const checkEntry = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value: newChip } = event.currentTarget;

    preventDefault(event);
    onTyping(!!newChip);

    if (event.keyCode === 13) {
      if (!!newChip) {
        const validationMessage = validateContent(newChip);

        if (!!validationMessage) {
          setLocalError(validationMessage);
        } else {
          setLocalError(null);
          onChange([...chips, newChip]);
          onTyping(false);

          event.currentTarget.value = '';
        }
      }
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    setLocalError(null);

    const { value: newChip } = event.currentTarget;

    if (!!newChip) {
      onChange([...chips, newChip]);
      onTyping(false);

      event.currentTarget.value = '';
    }
  };

  const hasError = !!localError || ((submitFailed || showErrorBeforeSubmit) && !!error);

  return (
    <ChipInputWrapper margin={margin}>
      <ChipInputChips>
        {chips.map((chip, index) => (
          <ChipInputChip key={index} isInvalid={!!validateContent && !!validateContent(chip)}>
            {chip}

            <ChipInputChipButton onClick={() => removeChip(index)}>
              <Icon icon="cancelClear" height="12px" width="12px" customViewBox="0 0 24 24" />
            </ChipInputChipButton>
          </ChipInputChip>
        ))}
      </ChipInputChips>

      <ChipInputField
        {...inputProps}
        hasError={hasError}
        onKeyDown={preventDefault}
        onKeyUp={checkEntry}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {hasError && <FormError errorOnSingleLine={errorOnSingleLine}>{localError || error}</FormError>}

      {focused && !!metaTextOnFocus && <FormMeta>{metaTextOnFocus}</FormMeta>}
    </ChipInputWrapper>
  );
};

export default ChipInput;
