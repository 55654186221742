import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { size } from 'lodash-es';
import {
  FormGroup,
  FormError,
  FileInput as FileInputContainer,
  FileInputText,
  FileInputLabel,
  FileInputInput,
} from './styled';
import translate from '../services/translate';

interface Props extends WrappedFieldProps {
  disabled: boolean;
  margin?: string;
}

const FileInput: React.SFC<Props> = ({
  input: { value, ...input },
  meta: { submitFailed, error },
  disabled,
  margin,
  ...props
}) => (
  <FormGroup margin={margin}>
    <FileInputContainer isEmpty={!value}>
      <FileInputLabel>
        <FileInputInput {...input} {...props} disabled={disabled} type="file" /> {translate('common.chooseFile')}
      </FileInputLabel>
      <FileInputText>{size(value) ? value[0].name : translate('common.noFileChosen')}</FileInputText>
    </FileInputContainer>

    {submitFailed && error && <FormError>{error}</FormError>}
  </FormGroup>
);

export default FileInput;
