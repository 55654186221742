import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { getQueryParams } from '../../utils/services/queryParams';

const getDispatchesSearchFilterFormInitialValues = (queryString: string) => {
  const { dispatchStatus, startDate, endDate, workOrder, workOrderType } = getQueryParams(queryString);

  return {
    dispatchStatus,
    date: {
      from: startDate,
      to: endDate,
    },
    endDate,
    workOrder,
    workOrderType,
  };
};

const openDispatchesSearchFilterFormInitialValuesSelector = createSelector(
  getDispatchesSearchFilterFormInitialValues,
  identity,
);
export default openDispatchesSearchFilterFormInitialValuesSelector;
