import { AxiosResponse } from 'axios';

import { http } from '../../core/services/http';
import { RouteSettings, RawRouteSettings } from '../interfaces/RouteSettings';
import { transformRouteBuilderRawSettings, transformRouteBuilderSettings } from './transformRouteSettings';

export const loadRouteBuilderSettings = (vendorId: number): Promise<RouteSettings> =>
  http
    .get(`/vendors/${vendorId}/routeBuilderSettings`)
    .then((response: AxiosResponse<RawRouteSettings>) => transformRouteBuilderRawSettings(response.data));

export const loadRouteBuilderDefaultSettings = (): Promise<RouteSettings> =>
  http
    .get(`/vendors/routeBuilderDefaultSettings`)
    .then((response: AxiosResponse<RawRouteSettings>) => transformRouteBuilderRawSettings(response.data));

export const saveRouteBuilderSettings = (vendorId: number, settings: RouteSettings) =>
  http
    .post(`/vendors/${vendorId}/routeBuilderSettings`, transformRouteBuilderSettings(settings))
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
