import React, { ChangeEvent } from 'react';

import { connect } from 'react-redux';

import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { TechnicalType } from '../../common/interfaces/TechnicalType';
import { Dropdown, TypedField } from '../../core/components';
import translate from '../../core/services/translate';
import { AppState } from '../../store';

interface MonthlyServicesYearlyFrequencyDropdownProps extends DropDownFieldProps {
  monthlyServicesYearlyFrequency: TechnicalType[];
}

const MonthlyServicesYearlyFrequencyDropdown = (props: MonthlyServicesYearlyFrequencyDropdownProps) => {
  const {
    input: { name, onChange: onInputChange },
    placeholder,
    withPlaceholder,
    label,
    withLabel,
    monthlyServicesYearlyFrequency,
    dropdownProps,
  } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>, value: number) => {
    onInputChange(value);
  };

  const monthlyServicesYearlyFrequencyOptions = monthlyServicesYearlyFrequency.map(option => ({
    label: option.name,
    value: option.id,
  }));

  return (
    <TypedField
      name={name}
      component={Dropdown}
      onChange={onChange}
      props={{
        label: label || (withLabel ? translate('common.everyMonth') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('common.everyMonth') : undefined),
        options: monthlyServicesYearlyFrequencyOptions,
        ...dropdownProps,
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  monthlyServicesYearlyFrequency: state.common.pickupFrequencyTypes.monthlyServicesYearlyFrequency || [],
});

export default connect(mapStateToProps)(MonthlyServicesYearlyFrequencyDropdown);
