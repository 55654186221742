import React from 'react';

import { TableCell, TableRow } from '../../../core/components/styled';

interface Props {
  applicationBuildNumber?: string;
  regplate?: string;
}

const VersionStatusesPageTableRow: React.FC<Props> = ({ regplate, applicationBuildNumber }) => (
  <TableRow>
    <TableCell width="50%">{regplate}</TableCell>
    <TableCell width="50%">{applicationBuildNumber}</TableCell>
  </TableRow>
);

export default VersionStatusesPageTableRow;
