import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { sizeMapper } from '../../../utils/styles';
import { DoughnutChartWrapper } from '../../../core/components/styled/DoughnutChartStyles';
import { GridColumn, ActionButton } from '../../../core/components/styled';
import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';

export const VehiclesFormWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

interface VehiclesFormItemWrapperProps {
  size?: number;
  grow?: boolean;
  hasSeparator?: boolean;
}

export const VehiclesFormItemWrapper = styled.div<VehiclesFormItemWrapperProps>`
  padding: ${sizeMapper('no small')};

  ${props =>
    !!props.size &&
    css`
      flex: 0 0 ${props.size}%;
    `}

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${props =>
    props.hasSeparator &&
    css`
      border-left: 1px solid ${props.theme.grayLight};
    `}
`;

export const VehiclesPageChartsWrapper = styled.div`
  display: flex;
  align-items: center;

  & ${DoughnutChartWrapper} {
    flex-grow: 1;
  }
`;

interface VehiclesPageFleetInsightWrapperProps {
  isHidden?: boolean;
}

export const VehiclesPageFleetInsightWrapper = styled.div<VehiclesPageFleetInsightWrapperProps>`
  ${props =>
    props.isHidden &&
    css`
      visibility: hidden;
    `}
`;

/**
 * This value was calculated so that every section
 * will show a hint that there are more entries, hence
 * giving the possibility for the user to scroll.
 */
const ARBITRARY_CONTAINER_HEIGHT = 8.39 * 60;

export const VehicleTableWrapper = styled.div`
  position: relative;
  overflow: auto;
  max-height: ${ARBITRARY_CONTAINER_HEIGHT}px;
  margin: 20px -30px 0;
  width: calc(100% + 60px);
  padding: 0;
`;

export const VehicleIssuesFormElement = styled.form`
  width: 100%;
`;

interface VehicleMoreIndicatorWrapperProps {
  disabled?: boolean;
}

export const VehicleMoreIndicatorWrapper = styled.div<VehicleMoreIndicatorWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -15px 0 -5px;
  position: relative;
  color: ${props => colorMapperWithoutProps(props.disabled ? 'grayDark' : 'primary')}; ;
  pointer-events: none;

  ${props => !props.disabled && css`
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 15px;
      height: 50px;
      background: linear-gradient(to bottom, transparent, white);
    }
  `}

  & ${ActionButton} {
    z-index: 1;

    ${props => props.disabled && css`
      opacity: 0;
    `}
  }
`;

export const VehicleTypeWrapperColumn = styled(GridColumn)`
  &:hover {
    box-shadow: 0 0 0 2px ${props => props.theme.brandPrimary};
    background: ${props => transparentize(0.97, props.theme.brandPrimary)};
  }
`;
