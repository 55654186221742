import {
  getDispatchOpportunitiesCount,
  getDispatchOpportunitiesCountForToken,
} from '../services/getDispatchOpportunitiesCount';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

// Constants
const START_LOAD = 'common/dispatchOpportunitiesCounts/START_LOAD';
const COMPLETE_LOAD = 'common/dispatchOpportunitiesCounts/COMPLETE_LOAD';
const FAIL_LOAD = 'common/dispatchOpportunitiesCounts/FAIL_LOAD';

interface OpportunitiesMenuCountState {
  isLoading: boolean;
  dispatchOpportunitiesCounts?: any;
}

// Initial State
const initialState: OpportunitiesMenuCountState = {
  isLoading: false,
  dispatchOpportunitiesCounts: undefined,
};

type Dispatch = ThunkDispatch<OpportunitiesMenuCountState, any, AnyAction>;

// Reducer
export const reducer = (
  state: OpportunitiesMenuCountState = initialState,
  action: AnyAction,
): OpportunitiesMenuCountState => {
  switch (action.type) {
    case START_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case COMPLETE_LOAD:
      return {
        ...state,
        isLoading: false,
        dispatchOpportunitiesCounts: action.data || {},
      };
    case FAIL_LOAD:
      return {
        ...state,
        isLoading: false,
        dispatchOpportunitiesCounts: {},
      };
    default:
      return { ...state };
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (action: AnyAction) => ({
  type: COMPLETE_LOAD,
  data: action.data,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

// Async Actions
export const loadDispatchOpportunitiesCount =
  (userId: string | number | undefined, vendorId: number | undefined, token: string | undefined) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    if (token) {
      return getDispatchOpportunitiesCountForToken(token)
        .then((data: any) => dispatch(completeLoad(data)))
        .catch(() => dispatch(failLoad()));
    } else if (userId) {
      return getDispatchOpportunitiesCount(userId, vendorId)
        .then((data: any) => dispatch(completeLoad(data)))
        .catch(() => dispatch(failLoad()));
    }
  };
