import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { loadDocuments } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import DocumentListModal from './DocumentListModal';

interface Props extends RouteComponentProps {
  match: any;
  scheduledDate: string;
  userId: string;
  vendorId: number;
  workOrderNo: string;
  closeModal: () => void;
  loadDocuments: DuckFunction<typeof loadDocuments>;
}

class DocumentListModalResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      loadDocuments,
      match: {
        params: { token },
      },
      scheduledDate,
      userId,
      vendorId,
      workOrderNo,
    } = this.props;
    return loadDocuments(scheduledDate, token, userId, vendorId, workOrderNo);
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Fragment>
        <Resolver
          successComponent={DocumentListModal}
          loadingComponent={PageLoadingOverlay}
          successProps={{ closeModal }}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadDocuments,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentListModalResolver));
