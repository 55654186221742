import { Position } from 'deck.gl';
import { WebMercatorViewport } from 'react-map-gl';

export const createFeatureCollection = (features: GeoJSON.Feature<any>) => ({ type: 'FeatureCollection', features });

export const getLineString = (coordinates: number[][]) => {
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: coordinates,
    },
  };
};

export const getLineStringFeature = <P extends GeoJSON.GeoJsonProperties>(
  /**
   * The @param id can be only number because of some Mapbox querying
   * problem.
   *
   * Read more: https://github.com/mapbox/mapbox-gl-js/issues/2716
   */
  id: number,
  coordinates: number[][],
  properties: P,
): GeoJSON.Feature<GeoJSON.LineString, P> => ({
  id,
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: coordinates,
  },
  properties,
});

export const getPointFeature = <P extends GeoJSON.GeoJsonProperties>(
  /**
   * The @param id can be only number because of some Mapbox querying
   * problem.
   *
   * Read more: https://github.com/mapbox/mapbox-gl-js/issues/2716
   */
  id: number,
  coordinates: [number, number],
  properties: P,
): GeoJSON.Feature<GeoJSON.Point, P> => ({
  id,
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: coordinates,
  },
  properties,
});

export const getPolygonFeature = <P extends GeoJSON.GeoJsonProperties>(
  /**
   * The @param id can be only number because of some Mapbox querying
   * problem.
   *
   * Read more: https://github.com/mapbox/mapbox-gl-js/issues/2716
   */
  id: number,
  coordinates: Position[][],
  properties: P,
): GeoJSON.Feature<GeoJSON.Polygon, P> => ({
  id,
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: coordinates,
  },
  properties,
});

export const getFeatureCollection = <G extends GeoJSON.Geometry, P extends GeoJSON.GeoJsonProperties>(
  featurePoints: GeoJSON.Feature<G, P>[],
): GeoJSON.FeatureCollection<G, P> => ({
  type: 'FeatureCollection',
  features: featurePoints,
});

export const getMapBounds = (
  points: { latitude: number; longitude: number }[],
  {
    width = 800,
    height = 300,
    padding,
    capZoom = 15,
  }: { width?: number; height?: number; padding?: number; capZoom?: number } = {},
) => {
  if (!points) return {};

  let minLongitude = Infinity;
  let maxLongitude = -Infinity;
  let minLatitude = Infinity;
  let maxLatitude = -Infinity;

  for (const point of points) {
    minLongitude = Math.min(minLongitude, point.longitude);
    maxLongitude = Math.max(maxLongitude, point.longitude);
    minLatitude = Math.min(minLatitude, point.latitude);
    maxLatitude = Math.max(maxLatitude, point.latitude);
  }

  if (
    minLongitude === Infinity ||
    maxLongitude === -Infinity ||
    minLatitude === Infinity ||
    maxLatitude === -Infinity
  ) {
    return {};
  }

  const finalCornersLongLat = [
    [minLongitude, minLatitude],
    [maxLongitude, maxLatitude],
  ];

  const viewport = new WebMercatorViewport({ height, width }).fitBounds(finalCornersLongLat as any, { padding });
  const { longitude, latitude, zoom } = viewport;

  return { longitude, latitude, zoom: Math.max(0, Math.min(zoom, capZoom)) };
};
