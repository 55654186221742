import { mapKeys } from 'lodash-es';

import manualStop from 'src/common/assets/img/triggers/manual-trigger.png';
import motionStop from 'src/common/assets/img/triggers/motion-trigger.png';
import proximity from 'src/common/assets/img/triggers/proximity-trigger.png';
import stationary from 'src/common/assets/img/triggers/stationary-trigger.png';
import multiple from 'src/common/assets/img/triggers/multiple-trigger.png';
import translate from 'src/core/services/translate';

export const TRIGGER_MAP_LAYER = 'triggerMapLayer';
export const TRIGGER_MAP_CLUSTER_LAYER = 'triggerMapClusterLayer';
export const TRIGGER_MAP_CLUSTER_SOURCE = 'triggerMapCluserSource';
export const TRIGGER_MAP_CLUSTER_COUNTERS_LAYER = 'triggerMapCluserCountersSource';

export const MANUAL_STOP = translate('routes.triggerTypes.manualStops');
export const PROXIMITY = translate('routes.triggerTypes.proximity');
export const STATIONARY = translate('routes.triggerTypes.stationary');
export const MOTION_STOP = translate('routes.triggerTypes.motionStops');
export const MULTIPLE_TRIGGERS = translate('routes.triggerTypes.multiple');

export const MANUAL_STOP_ID = 1;
export const PROXIMITY_ID = 2;
export const STATIONARY_ID = 3;
export const MOTION_STOP_ID = 4;
export const MULTIPLE_TRIGGERS_ID = 5;

export const TRIGGERS_MAP_COLORS_LIST = [
  { id: MANUAL_STOP_ID, icon: manualStop, iconType: MANUAL_STOP, color: '#966700' },
  { id: PROXIMITY_ID, icon: proximity, iconType: PROXIMITY, color: '#005696' },
  { id: STATIONARY_ID, icon: stationary, iconType: STATIONARY, color: '#009688' },
  { id: MOTION_STOP_ID, icon: motionStop, iconType: MOTION_STOP, color: '#96000e' },
  { id: MULTIPLE_TRIGGERS_ID, icon: multiple, iconType: MULTIPLE_TRIGGERS, color: '#710096' },
];

export const TRIGGERS_MAP_COLORS = mapKeys(TRIGGERS_MAP_COLORS_LIST, 'id');
