import { http } from '../../core/services/http';
import { transformLoadServiceHistory } from './transformServiceHistory';
import translate from '../../core/services/translate';
import { downloadExcelFile } from '../../utils/services/downloadExcelFile';
import { TODAY_FORMATTED, ONE_MONTH_AGO } from '../../core/constants';

export const loadServiceHistory = (
  customerId: number,
  locationId: number,
  serviceContractIds: string | undefined,
  pickupStatusTypeIds: string | undefined,
  jobPriorityTypeIds: string | undefined,
  startDate: Date | string = ONE_MONTH_AGO,
  endDate: Date | string = TODAY_FORMATTED,
) =>
  http
    .get(`customers/${customerId}/serviceHistory`, {
      params: {
        locationId,
        serviceContractIds,
        pickupStatusTypeIds,
        jobPriorityTypeIds,
        startDate,
        endDate,
      },
    })
    .then(response => transformLoadServiceHistory(response.data));

export const exportServiceHistory = (
  customerId: number,
  customerName: string | undefined,
  locationId: number,
  serviceContractIds: string | undefined,
  pickupStatusTypeIds: string | undefined,
  jobPriorityTypeIds: string | undefined,
  startDate: Date | string = ONE_MONTH_AGO,
  endDate: Date | string = TODAY_FORMATTED,
) =>
  downloadExcelFile(
    `customers/${customerId}/exportServiceHistory`,
    `${customerName} - ${translate('customers.serviceHistory')}`,
    {
      locationId,
      serviceContractIds,
      pickupStatusTypeIds,
      jobPriorityTypeIds,
      startDate,
      endDate,
    },
  );
