import { camelCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { MapGLPopupWrapper, MapGLPopup, MapGLPopupContent } from 'src/common/components/map/MapGLPopup';
import { Trigger } from 'src/routes/interfaces/Trigger';
import { useSelector } from 'src/core/hooks/useSelector';
import LabeledDataView from 'src/core/components/LabeledDataView';
import translate from 'src/core/services/translate';

type Props = {
  triggers: Trigger[];
};

export default function TriggerMapGLPopup({ triggers }: Props) {
  const dispatch = useDispatch();
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const trigger: any = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.triggers) {
      return undefined;
    }

    return triggers.find(vehiclePosition => vehiclePosition.orderNo === selectedFeature.id) || [];
  }, [selectedFeature, triggers]);

  if (!trigger) return null;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={trigger.latitude}
      longitude={trigger.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup minHeight={70}>
        <MapGLPopupContent padding="no no small no">
          {trigger.triggers.map(({ name, count }: any) => (
            <LabeledDataView
              noWrapLabel
              flex
              key={name}
              width="calc(100% - 10px)"
              size="small"
              label={translate(`routes.triggers.${camelCase(name)}`)}
              value={count}
            />
          ))}
        </MapGLPopupContent>
      </MapGLPopup>
    </MapGLPopupWrapper>
  );
}
