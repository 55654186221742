import { filter, flatMap, groupBy, map, size, sortBy, uniqBy } from 'lodash-es';
import React from 'react';
import { useLocation } from 'react-router';
import DoughnutChart from 'src/core/components/DoughnutChart';
import { Panel, PanelSection } from 'src/core/components/styled';
import { CONTAINERS_CHART_COLORS } from 'src/core/constants/chartColors';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  ContainerStatisticsElement,
  ContainerStatisticsWrapper,
} from 'src/fleet/components/styled/ContainersStatistics';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { ROUTE_PLANNER_METRICS } from 'src/routes/constants';
import { RoutePlannerRoute } from 'src/routes/interfaces/routePlanner/RoutePlannerRoute';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteTemplate } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplate';
import { RoutePlannerRouteTemplateDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateDriver';
import { RoutePlannerRouteTemplateVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteTemplateVehicle';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { supervisorExperienceFeatureIsEnabled } from 'src/vendors/ducks/features';

type Drivers = RoutePlannerRouteDriver | RoutePlannerRouteTemplateDriver;
type Vehicles = RoutePlannerRouteVehicle | RoutePlannerRouteTemplateVehicle;
type Routes = RoutePlannerRoute | RoutePlannerRouteTemplate;

interface Props {
  drivers: Drivers[];
  vehicles: Vehicles[];
  routes: Routes[];
}

export default React.memo(function DailyAndReoccurringStatisticsSection({ drivers, vehicles, routes }: Props) {
  const { pathname } = useLocation();
  const isDailyRoutesPage = pathname.includes('daily');

  const { routeMetricsSettings, routeTemplateMetricsSettings } = useSelector(
    state => state.routes.routePlanner.routePlannerMetricsSettings,
  );

  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);

  const chartsMemo = React.useMemo(() => {
    const chartsSourceData = map(
      filter(isDailyRoutesPage ? routeMetricsSettings : routeTemplateMetricsSettings, el => el.isActive),
      el => {
        switch (el.metricTypeId) {
          case 1: {
            // routes
            const labels = [translate('routes.planner.planned'), translate('routes.planner.unplanned')];
            const totalValue = size(routes).toString();
            const rawValue = [
              filter(routes, el => el.driverId && el.vehicleId).length,
              filter(routes, el => !el.driverId || !el.vehicleId).length,
            ];
            const chartTitle = ROUTE_PLANNER_METRICS[el.metricTypeId]?.name;
            return { labels, totalValue, rawValue, chartTitle };
          }
          case 2: {
            // drivers
            const labels = isDailyRoutesPage
              ? [
                  translate('common.assigned'),
                  translate('common.available'),
                  translate('common.unavailable'),
                  translate('routes.planner.doubleBooked'),
                ]
              : [translate('common.assigned'), translate('common.available')];
            const totalValue = size(drivers).toString();
            const rawValue = isDailyRoutesPage
              ? [
                  filter(
                    drivers,
                    el =>
                      (el as RoutePlannerRouteDriver).routes.length &&
                      (el as RoutePlannerRouteDriver).routes.length === 1,
                  ).length,
                  filter(drivers, el => !(el as RoutePlannerRouteDriver).routes.length).length -
                    filter(drivers, el => !(el as RoutePlannerRouteDriver).isAvailable).length,
                  filter(drivers, el => !(el as RoutePlannerRouteDriver).isAvailable).length,
                  filter(
                    drivers,
                    el =>
                      (el as RoutePlannerRouteDriver).routes.length &&
                      (el as RoutePlannerRouteDriver).routes.length > 1,
                  ).length,
                ]
              : [
                  filter(drivers, el => (el as RoutePlannerRouteTemplateDriver).routesTemplates.length).length,
                  filter(drivers, el => !(el as RoutePlannerRouteTemplateDriver).routesTemplates.length).length,
                ];
            const chartTitle = ROUTE_PLANNER_METRICS[el.metricTypeId]?.name;
            return { labels, totalValue, rawValue, chartTitle };
          }
          case 3: {
            // vehicles
            const labels = isDailyRoutesPage
              ? [
                  translate('common.assigned'),
                  translate('common.available'),
                  translate('common.unavailable'),
                  translate('routes.planner.doubleBooked'),
                ]
              : [translate('common.assigned'), translate('common.available')];
            const totalValue = size(vehicles).toString();
            const rawValue = isDailyRoutesPage
              ? [
                  filter(
                    vehicles,
                    el =>
                      (el as RoutePlannerRouteVehicle).routes.length &&
                      (el as RoutePlannerRouteVehicle).routes.length === 1,
                  ).length,
                  filter(vehicles, el => !(el as RoutePlannerRouteVehicle).routes.length).length -
                    filter(vehicles, el => !(el as RoutePlannerRouteVehicle).isAvailable).length,
                  filter(vehicles, el => !(el as RoutePlannerRouteVehicle).isAvailable).length,
                  filter(vehicles, el => (el as RoutePlannerRouteVehicle).routes.length > 1).length,
                ]
              : [
                  filter(vehicles, el => (el as RoutePlannerRouteTemplateVehicle).routesTemplates.length).length,
                  filter(vehicles, el => !(el as RoutePlannerRouteTemplateVehicle).routesTemplates.length).length,
                ];
            const chartTitle = ROUTE_PLANNER_METRICS[el.metricTypeId]?.name;
            return { labels, totalValue, rawValue, chartTitle };
          }
          case 4: {
            // service zones

            const routesWithServiceZones = filter(routes, route => route.serviceZoneName);

            const labels = flatMap(sortBy(groupBy(routesWithServiceZones, 'serviceZoneName')).reverse(), route =>
              map(uniqBy(route, 'serviceZoneName'), 'serviceZoneName'),
            );
            const totalValue = size(flatMap(groupBy(routesWithServiceZones, 'serviceZoneName'))).toString();
            const rawValue = map(sortBy(groupBy(routesWithServiceZones, 'serviceZoneName')).reverse(), el => el.length);
            const chartTitle = ROUTE_PLANNER_METRICS[el.metricTypeId]?.name;
            return { labels, totalValue, rawValue, chartTitle };
          }
          case 5: {
            // supervisors
            if (!supervisorEnabled) {
              return null;
            }
            const routesWithSupervisors = filter(
              routes,
              route => route.supervisorName && route.supervisorName.split(' ').join('').length,
            );
            const labels = flatMap(sortBy(groupBy(routesWithSupervisors, 'supervisorName')).reverse(), route =>
              map(uniqBy(route, 'supervisorName'), 'supervisorName'),
            );
            const totalValue = size(flatMap(groupBy(routesWithSupervisors, 'supervisorName'))).toString();
            const rawValue = map(sortBy(groupBy(routesWithSupervisors, 'supervisorName')).reverse(), el => el.length);

            const chartTitle = ROUTE_PLANNER_METRICS[el.metricTypeId]?.name;
            return { labels, totalValue, rawValue, chartTitle };
          }
          case 6: {
            // status
            const labels = [translate('common.active'), translate('common.inactive')];
            const totalValue = size(routes).toString();
            const rawValue = [filter(routes, el => el.isActive).length, filter(routes, el => !el.isActive).length];
            const chartTitle = ROUTE_PLANNER_METRICS[el.metricTypeId]?.name;
            return { labels, totalValue, rawValue, chartTitle };
          }
          case 7: {
            // vehicle types from routes
            const labels = map(groupBy(routes, 'vehicleTypeId'), el => VEHICLE_TYPE_IDS[el[0].vehicleTypeId].name);
            const totalValue = size(groupBy(routes, 'vehicleTypeId')).toString();
            const rawValue = map(groupBy(routes, 'vehicleTypeId'), el => el.length);
            const chartTitle = ROUTE_PLANNER_METRICS[el.metricTypeId]?.name;
            return { labels, totalValue, rawValue, chartTitle };
          }
        }
      },
    );
    return map(chartsSourceData, data => {
      return (
        data && (
          <ContainerStatisticsElement hasBorderRight alignItems="center" key={data.chartTitle}>
            <DoughnutChart
              showLegend
              showSummaryLegend
              showSummaryOnHover
              topTitle={data.chartTitle}
              title={data.totalValue}
              doughnutSize={130}
              dataset={{
                data: data.rawValue,
                backgroundColor: CONTAINERS_CHART_COLORS,
              }}
              rawDataValues={data.rawValue}
              labels={data.labels}
            />
          </ContainerStatisticsElement>
        )
      );
    });
  }, [
    isDailyRoutesPage,
    routeMetricsSettings,
    routeTemplateMetricsSettings,
    routes,
    drivers,
    vehicles,
    supervisorEnabled,
  ]);

  return (
    <PanelSection withTopAndBottomBorder>
      <Panel fluid padding="no small">
        <ContainerStatisticsWrapper padding="small no no no">{chartsMemo}</ContainerStatisticsWrapper>
      </Panel>
    </PanelSection>
  );
});
