import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Text, PanelSectionGroup } from '../../../core/components/styled';
import { loadWasteRecorder } from '../../../routes/ducks';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { DuckFunction } from '../../../contracts/ducks';

interface Props {
  wasteRecorder?: any;
  isLoading: boolean;
  routeId: number;
  loadWasteRecorder: DuckFunction<typeof loadWasteRecorder>;
  routeLocationId: number;
}

class CartFillLevelPopover extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    const { routeId, routeLocationId, loadWasteRecorder } = this.props;
    loadWasteRecorder(routeId, routeLocationId);
  }

  render() {
    const { isLoading, wasteRecorder } = this.props;
    return (
      <PanelSectionGroup isLoading={isLoading}>
        {!!wasteRecorder && (
          <Fragment key={wasteRecorder.equipmentTypeName}>
            <Text weight="medium" block underlined size="medium">
              {translate('common.total')} - {wasteRecorder.numberOfBins} {translate('routes.carts')}
            </Text>
            {!!wasteRecorder.fillLevelCount100 && (
              <Text weight="medium" block size="small">
                100% {translate('common.full')} - {wasteRecorder.fillLevelCount100} {translate('routes.carts')}
              </Text>
            )}
            {!!wasteRecorder.fillLevelCount75 && (
              <Text weight="medium" block size="small">
                75% {translate('common.full')} - {wasteRecorder.fillLevelCount75} {translate('routes.carts')}
              </Text>
            )}
            {!!wasteRecorder.fillLevelCount50 && (
              <Text weight="medium" block size="small">
                50% {translate('common.full')} - {wasteRecorder.fillLevelCount50} {translate('routes.carts')}
              </Text>
            )}
            {!!wasteRecorder.fillLevelCount25 && (
              <Text weight="medium" block size="small">
                25% {translate('common.full')} - {wasteRecorder.fillLevelCount25} {translate('routes.carts')}
              </Text>
            )}
          </Fragment>
        )}
      </PanelSectionGroup>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.routes.wasteRecorder.isLoading,
  wasteRecorder: state.routes.wasteRecorder.wasteRecorder,
});

const mapDispatchToProps = {
  loadWasteRecorder,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartFillLevelPopover);
