import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { BareButtonStyle } from './Button';

interface StepTabLinkProps {
  disabled?: boolean;
}

export const StepTab = styled.button`
  ${BareButtonStyle};
  padding: 20px;
  text-transform: capitalize;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 16px;
`;

export const StepTabLink = styled(StepTab.withComponent(NavLink))<StepTabLinkProps>`
  &.active {
    border-bottom: 4px solid ${props => props.theme.brandPrimary};
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const StepTabs = styled.div`
  display: inline-flex;
  background-color: #fff;
  border-radius: 3px;
`;
