import { AnyAction, Dispatch } from 'redux';
import { getFacilitySubType } from '../services/facilities';

// Constants
const START_LOAD_SUB_TYPES = 'fleet/facilitySubTypes/START_LOAD_SUB_TYPES';
const FAIL_LOAD_SUB_TYPES = 'fleet/facilitySubTypes/FAIL_LOAD_SUB_TYPES';
const COMPLETE_LOAD_SUB_TYPES = 'fleet/facilitySubTypes/COMPLETE_LOAD_SUB_TYPES';

// Actions Creators
const startLoad = () => ({
  type: START_LOAD_SUB_TYPES,
});

const failLoad = () => ({
  type: FAIL_LOAD_SUB_TYPES,
});

const completeLoad = (data: any) => ({
  type: COMPLETE_LOAD_SUB_TYPES,
  facilitySubTypes: data,
});

// Async Actions
export const loadFacilitySubType = (facilityType: any) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getFacilitySubTypePromise = getFacilitySubType(facilityType);
  getFacilitySubTypePromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getFacilitySubTypePromise;
};

// InitialState
const initialState = {
  isLoading: false,
  facilitySubTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_SUB_TYPES:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_SUB_TYPES:
      return {
        ...state,
        isLoading: false,
        facilitySubTypes: [],
      };
    case COMPLETE_LOAD_SUB_TYPES:
      return {
        ...state,
        isLoading: false,
        facilitySubTypes: action.facilitySubTypes,
      };
    default:
      return state;
  }
};
