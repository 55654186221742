import { combineReducers } from 'redux';
import { mobilePageReducer } from './mobilePage';
import { reducer as lastLocationsReducer } from './lastLocations';
import { reducer as notificationsReducer } from './notifications';

export { addLastLocation, getLastUrlSelector, removeLastLocation } from './lastLocations';
export { createNotification, removeNotification } from './notifications';

export const reducer = combineReducers({
  lastLocations: lastLocationsReducer,
  notifications: notificationsReducer,
  mobilePage: mobilePageReducer,
});
