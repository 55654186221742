import React from 'react';
import moment from 'moment';
import { find } from 'lodash-es';

import { getServiceName } from 'src/fleet/services/transformContainersLocationsForCreateEdit';
import { TableCell, TableRow, Text } from '../../../core/components/styled';
import { ContainerActivityItem } from 'src/fleet/interfaces/containers';
import { TABLE_ROW_HEIGHT } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';

const ContainerActivityTableRow: React.FC<ContainerActivityItem> = ({
  date,
  activityType,
  activityBy,
  vehicleRegplate,
  locationType,
  locationName,
  customerName,
  address,
  daysSince,
  serviceContract,
}) => {
  const equipmentSizes = useSelector(state => state.common.equipmentSizes.equipmentSizes);
  const equipmentTypes = useSelector(state => state.common.equipmentTypes.equipmentTypes);
  const serviceTypes = useSelector(state => state.common.serviceTypes.serviceTypes);

  const equipmentSizeService = find(equipmentSizes, { id: serviceContract?.s });
  const equipmentTypeService = find(equipmentTypes, { id: serviceContract?.t });
  const serviceTypeService = find(serviceTypes, { id: serviceContract?.st });

  const containerServiceContractInfo = getServiceName(
    equipmentTypeService?.technicalName,
    equipmentTypeService?.name,
    equipmentSizeService?.technicalName,
    equipmentSizeService?.name,
    serviceTypeService?.name,
  );

  return (
    <TableRow height={TABLE_ROW_HEIGHT}>
      <TableCell width="15%">{moment(date).format('MM/DD/YYYY')}</TableCell>
      <TableCell width="20%" vertical>
        <Text weight="medium" block margin="no no xxSmall">
          {activityType}
        </Text>
        <Text
          singleLine
          title={`${activityBy || ''} ${activityBy && vehicleRegplate ? '|' : ''} ${vehicleRegplate || ''}`}
          block
          margin="no no xxSmall"
        >
          {`${activityBy || ''} ${activityBy && vehicleRegplate ? '|' : ''} ${vehicleRegplate || ''}`}
        </Text>
      </TableCell>
      <TableCell width="15%">
        <Text singleLine title={locationType?.name}>
          {locationType?.name}
        </Text>
      </TableCell>
      <TableCell width="34%" vertical>
        <Text singleLine title={locationName} weight="medium" block margin="no no xxSmall">
          {locationName}
        </Text>
        <Text block singleLine title={containerServiceContractInfo}>
          {containerServiceContractInfo}
        </Text>
        <Text singleLine title={address} block margin="no no xxSmall">
          {address}
        </Text>
      </TableCell>
      <TableCell width="15%">{daysSince}</TableCell>
    </TableRow>
  );
};

export default ContainerActivityTableRow;
