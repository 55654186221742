import translate from '../../core/services/translate';

export const FRONT_LOAD = 'FrontLoad';
export const ROLL_OFF = 'RollOff';
export const CART = 'Cart';
export const UNKNOWN = 'Unknown';
export const RESIDENTIAL = 'Residential';
export const WASTE_AUDIT = 'WasteAudit';
export const STREET_SWEEPING = 'StreetSweeping';
export const DELIVERY_UTILITY = 'Delivery';
export const BULK = 'Bulk';
export const PORTABLE_TOILET = 'Portable Toilet';

export const FRONT_LOAD_ID = 1;
export const ROLL_OFF_ID = 2;
export const UNKNOWN_ID = 3;
export const CART_ID = 4;
export const RESIDENTIAL_ID = 5;
export const WASTE_AUDIT_ID = 6;
export const STREET_SWEEPING_ID = 7;
export const PORTABLE_TOILET_ID = 8;
export const BULK_ID = 9;
export const STREET_ID = 10;

export const SERVICE_TYPES_LIST = [
  {
    id: FRONT_LOAD_ID,
    name: translate('common.serviceTypes.frontLoad'),
  },
  {
    id: ROLL_OFF_ID,
    name: translate('common.serviceTypes.rollOff'),
  },
  {
    id: UNKNOWN_ID,
    name: translate('common.serviceTypes.unknown'),
  },
  {
    id: CART_ID,
    name: translate('common.serviceTypes.cart'),
  },
  {
    id: RESIDENTIAL_ID,
    name: translate('common.serviceTypes.residential'),
  },
  {
    id: WASTE_AUDIT_ID,
    name: translate('common.serviceTypes.wasteAudit'),
  },
  {
    id: STREET_SWEEPING_ID,
    name: translate('common.serviceTypes.streetSweeping'),
  },
  {
    id: PORTABLE_TOILET_ID,
    name: translate('common.serviceTypes.portableToilet'),
  },
  {
    id: BULK_ID,
    name: translate('common.serviceTypes.bulk'),
  },
];
