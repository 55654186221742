import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { currency } from 'src/utils/services/formatter';
import { FooterSums } from '../BillCreatePage';

const BillPreviewFooterRow = ({length, onCallWOs, recurringWOs, scheduledWOs, totalWOs, cellWidths}: FooterSums) => (
  <TableRow height={TABLE_ROW_HEIGHT_LARGE}>
    <TableCell
      id={`total-bills`}
      justifyContent="center"
      vertical
      width="47%"
      padding="defaultCellVertical xSmall"
    >
      <Text block color="grayDarker">
        {length} Bills
      </Text>
    </TableCell>
    <TableCell
      id={`bill-total-reoccurringWOs-cell`}
      justifyContent="center"
      vertical
      width={cellWidths?.recurringWOsCount}
      padding="defaultCellVertical xSmall"
    >
      <Text block>{recurringWOs?.count || '–'}</Text>
      <Text block color="grayDarker">
        {currency(recurringWOs?.amount || 0)}
      </Text>
    </TableCell>
    <TableCell
      id={`bill-total-scheduledWOs-cell`}
      justifyContent="center"
      vertical
      width={cellWidths?.scheduledWOsCount}
      padding="defaultCellVertical xSmall"
    >
      <Text block>{scheduledWOs?.count || '–'}</Text>
      <Text block color="grayDarker">
        {currency(scheduledWOs?.amount || 0)}
      </Text>
    </TableCell>
    <TableCell
      id={`bill-total-onCallWOs-cell`}
      justifyContent="center"
      vertical
      width={cellWidths?.onCallWOsCount}
      padding="defaultCellVertical xSmall"
    >
      <Text block>{onCallWOs?.count || '–'}</Text>
      <Text block color="grayDarker">
        {currency(onCallWOs?.amount || 0)}
      </Text>
    </TableCell>
    <TableCell
      id={`bill-total-totalWOs-cell`}
      justifyContent="center"
      vertical
      width='17%'
      padding="defaultCellVertical xSmall"
    >
      <Text block>{totalWOs?.count || '–'}</Text>
      <Text block color="grayDarker">
        {currency(totalWOs?.amount || 0)}
      </Text>
    </TableCell>
  </TableRow>
);

export default BillPreviewFooterRow;
