import { filter } from 'lodash-es';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'src/core/components';
import { Separator, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

import { ComplexMapControl, MapLegend } from 'src/routes/components/styled/RouteMap';
import {
  DEADHEAD_ID1,
  DEADHEAD_ID2,
  DEADHEAD_ID3,
  DOUBLE_SIDE_SERVICE,
  FROM_TIP_ID,
  SINGLE_SIDE_SERVICE,
  TO_TIP_ID,
  TRAVEL_PATH_SERVICE_TYPES,
  TRAVEL_PATH_SERVICE_TYPES_IDS,
} from 'src/routes/constants';
import { setIsTravelPathEditLegendOpen, setIsTravelPathLegendOpen } from 'src/routes/ducks';

const LegendColorBox = ({
  color,
  label,
  width = '100%',
  dashed,
}: {
  color: string;
  label: string;
  width?: string;
  dashed?: boolean;
}) => (
  <Box flex={`0 0 ${width}`} padding="xxxSmall no" display="flex" alignItems="center">
    <Box style={{ transform: 'rotate(90deg)' }} margin="no xxSmall no no">
      <Icon icon={dashed ? 'boldArrowDashed' : 'boldArrow'} color={color} width="20px" height="20px" />
    </Box>
    <Text size="small">{label}</Text>
  </Box>
);

const TravelPathEditLegend: FC = () => {
  const dispatch = useDispatch();
  const { isTravelPathLegendOpen } = useSelector(state => state.routes.travelPathBuildAndEdit);

  const nonServiceTPs = filter(
    TRAVEL_PATH_SERVICE_TYPES,
    service =>
      ![SINGLE_SIDE_SERVICE, DOUBLE_SIDE_SERVICE, DEADHEAD_ID2, DEADHEAD_ID3, FROM_TIP_ID, TO_TIP_ID].includes(
        service.id,
      ),
  );

  const toggleLegend = () => {
    dispatch(setIsTravelPathEditLegendOpen(!isTravelPathLegendOpen));
  };

  useEffect(
    () => () => {
      dispatch(setIsTravelPathLegendOpen(!isTravelPathLegendOpen));
    },
    [dispatch, isTravelPathLegendOpen],
  );

  return (
    <ComplexMapControl position="bottom-right">
      <MapLegend collapsed={isTravelPathLegendOpen} onClick={toggleLegend}>
        <Text size="small">
          <b>{translate('dashboard.legend')}</b>
        </Text>
        <Icon icon="chevronDown" />
        {isTravelPathLegendOpen && (
          <>
            <Box display="flex" flexWrap="wrap" margin="xxSmall" width={230}>
              <Text size="xSmall" weight="medium" color="grayDarker" margin="xxSmall no no no">
                {translate('routes.travelPath.editedPath')}
              </Text>
              <Box width="100%" display="flex" flexWrap="wrap" mt={3}>
                <LegendColorBox color="#008000" label={translate('routes.travelPath.startSegment')} />
                <LegendColorBox color="#e70000" label={translate('routes.travelPath.endSegment')} />
                <LegendColorBox color="#1E90FF" label={translate('routes.travelPath.pathInBetween')} />
                <LegendColorBox color="#00BFFF" label={translate('routes.travelPath.availableDirections')} />
              </Box>
              <Separator color="gray" margin="xxSmall no" />
              <Text size="xSmall" weight="medium" color="grayDarker" margin="xxSmall no no no">
                {translate('routes.travelPath.travelPath')}
              </Text>
              <Box width="100%" display="flex" flexWrap="wrap" mt={3}>
                <LegendColorBox
                  color={TRAVEL_PATH_SERVICE_TYPES_IDS[SINGLE_SIDE_SERVICE].color}
                  label={TRAVEL_PATH_SERVICE_TYPES_IDS[SINGLE_SIDE_SERVICE].name}
                />
                {nonServiceTPs.map(service => (
                  <LegendColorBox
                    dashed={service.id === DEADHEAD_ID1}
                    key={service.id}
                    color={service.color}
                    label={service.name}
                    width="100%"
                  />
                ))}
              </Box>
            </Box>
          </>
        )}
      </MapLegend>
    </ComplexMapControl>
  );
};

export default TravelPathEditLegend;
