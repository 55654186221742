import { Icon } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { MonthlyEventHeader, MonthlyEventItemPopoverButtonWrapper } from 'src/core/components/styled/Calendar';
import translate from 'src/core/services/translate';
import { CalendarUnbilledCharge } from 'src/finance/interfaces/Billing';
import { currency } from 'src/utils/services/formatter';

interface Props {
  charge: CalendarUnbilledCharge;
}

const UnbilledChargesMonthlyEventItemPopoverButton: React.FC<Props> = ({ charge }) => (
  <MonthlyEventItemPopoverButtonWrapper>
    <MonthlyEventHeader align="right" margin="no no xSmall">
      {charge.onCallWorkOrders > 0 && (
        <Text size="small" color="grayDarker" margin="no no no xSmall">
          <Icon icon="onCall" width="15px" />
        </Text>
      )}
      {charge.recurringWorkOrders > 0 && (
        <Text size="small" color="grayDarker" margin="no no no xSmall">
          <Icon icon="recurring" width="18px" />
        </Text>
      )}
      {charge.scheduledWorkOrders > 0 && (
        <Text size="small" color="primary" margin="no no no xSmall">
          <Icon icon="scheduled" width="18px" />
        </Text>
      )}
    </MonthlyEventHeader>
    <Text block size="small" margin="no no xSmall">
      {translate('finance.xTotalUnbilled', {
        amount: currency(charge.totalAmount),
      })}
    </Text>
    <Text block size="small">
      {translate('finance.xWorkOrders', { number: charge.totalWorkOrders })}
    </Text>
  </MonthlyEventItemPopoverButtonWrapper>
);

export default UnbilledChargesMonthlyEventItemPopoverButton;
