import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { equipmentSizesSelector } from 'src/common/ducks';
import { Dropdown } from 'src/core/components';
import translate from 'src/core/services/translate';
import { EquipmentSize } from 'src/customers/interfaces/LocationServiceTypes';
import { AppState } from 'src/store';
import { DropDownFieldProps } from './DropDownFieldProps';

interface Props extends DropDownFieldProps {
  equipmentSizes?: EquipmentSize[];
  equipmentTypeId?: number;
  menuPosition?: 'fixed' | 'absolute';
}

class EquipmentSizeDropdown extends PureComponent<Props> {
  onChange = (event: any, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      equipmentSizes,
      dropdownProps,
      menuPosition,
    } = this.props;

    const equipmentSizeOptions = equipmentSizes?.map(equipmentType => ({
      label: equipmentType.name,
      value: equipmentType.id,
    }));

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.equipmentSize') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.equipmentSize') : undefined)}
        component={Dropdown as any}
        options={equipmentSizeOptions}
        onChange={this.onChange}
        menuPosition={menuPosition}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  equipmentSizes: (equipmentSizesSelector as any)(state.common.serviceTypes, ownProps.equipmentTypeId),
});

export default connect(mapStateToProps)(EquipmentSizeDropdown);
