import { camelize } from 'humps';
import { Fragment } from 'react';

import INSIGHT_ICONS from 'src/common/constants/insightIcons';
import { Text } from 'src/core/components/styled';
import { MonthlyEventHeader, MonthlyEventItemPopoverButtonWrapper } from 'src/core/components/styled/Calendar';
import translate from 'src/core/services/translate';
import { ServiceWorkOrder } from 'src/customers/interfaces/Services';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { currency } from 'src/utils/services/formatter';

interface Props {
  isOperationalView: boolean;
  workOrders: ServiceWorkOrder[];
}

const WorkOrdersMonthlyEventItemPopoverButton: React.FC<Props> = ({ isOperationalView, workOrders }) => (
  <MonthlyEventItemPopoverButtonWrapper>
    {workOrders.map(workOrder => {
      const ps = workOrder.pickupStatus ? camelize(workOrder.pickupStatus) : 'unScheduled';
      return (
        <Fragment key={workOrder.workOrderNumber}>
          <MonthlyEventHeader>
            {workOrder.workOrderNumber}
            {isOperationalView && (
              <img
                src={INSIGHT_ICONS[ps]}
                width="18"
                height="18"
                alt={translate(createTranslationKey(ps, 'common.pickupStatuses'))}
              />
            )}
          </MonthlyEventHeader>
          {!isOperationalView ? (
            <>
              <Text block size="small">
                {translate('customers.serviceEditor.invoiced')}
                <Text weight="medium" size="medium" margin="no no no xSmall">
                  {workOrder.invoiced || '-'}
                </Text>
              </Text>
              <Text block size="small" margin="no no xSmall no">
                {translate('customers.serviceEditor.amount')}
                <Text weight="medium" size="medium" margin="no no no xSmall">
                  {currency(workOrder.amount)}
                </Text>
              </Text>
            </>
          ) : (
            <>
              <Text block size="small" margin="no no xSmall no">
                {translate('customers.serviceEditor.workFlow')}
                <Text weight="medium" size="medium" margin="no no no xSmall">
                  {translate(createTranslationKey(workOrder.workFlow, 'customers.serviceEditor.workFlows'))}
                </Text>
              </Text>
            </>
          )}
        </Fragment>
      );
    })}
  </MonthlyEventItemPopoverButtonWrapper>
);

export default WorkOrdersMonthlyEventItemPopoverButton;
