import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { map, find, intersectionWith, get, omit, filter } from 'lodash-es';

import { useSelector } from 'src/core/hooks/useSelector';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { Button, ButtonSet, GridColumn } from '../../../core/components/styled';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import translate from '../../../core/services/translate';
import { ServiceType } from 'src/common/interfaces/ServiceType';
import DefaultFiltersModalSection from './DefaultFiltersModalSection';
import { currentUserIdSelector, currentVendorId } from 'src/vendors/services/currentVendorSelector';
import {
  FILTER_SERVICE_TYPE_ID,
  FILTER_SERVICE_ZONE_ID,
  FILTER_SUPERVISOR_ID,
  FILTER_TYPES,
  FILTER_VEHICLE_TYPE_ID,
} from 'src/common/constants';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { saveGlobalFiltersSettings, savePreferencesFiltersSettings } from 'src/common/ducks/filters';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { removeItem } from 'src/core/services/persistentStorage';
import { DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY } from 'src/dashboard/services/persistentFilterStorage';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { getAllWithOptionNone } from 'src/routes/services/dispatchBoardGetAllWithOptionNone';
import { NONE_ID } from 'src/routes/constants/dispatchBoard';

interface Props extends ModalProps {
  isAdmin: boolean;
  user?: any;
}

const DefaultFiltersModal = ({ closeModal, isAdmin, user }: Props) => {
  const dispatch = useDispatch();

  const vehicleTypes = useSelector(state => state.fleet.vehicleTypesForVendor.vehicleTypesForVendor);
  const supervisors = useSelector(state => getAllWithOptionNone(state.routes.supervisors.supervisors || []));
  const serviceZones = useSelector(state =>
    getAllWithOptionNone(state.routes.serviceZones.serviceZones || []),
  ) as ServiceZone[];
  const serviceTypes = useSelector((state: any) => state.common.serviceTypes.serviceTypes || []) as ServiceType[];

  const loggedInUserId = useSelector(state => currentUserIdSelector(state.account.login)) as any;
  const vendorId = useSelector(state => currentVendorId(state));
  const userId = isAdmin ? user.userId : loggedInUserId;

  const { filters } = useSelector(state => state.common.filters);

  const getFiltersCategorySettings = (
    allFiltersCategory: any[],
    existingSettings: any[],
    filtersCategoryId: number,
    isAdmin: boolean,
  ) => {
    const isSectionLocked = !!find(filters, {
      userGlobalFilterTypeId: filtersCategoryId,
      isLockedSection: true,
    });

    const filtersAlreadySet = intersectionWith(
      existingSettings,
      allFiltersCategory,
      (a: FilterSetting, b: any) => b.id === a.filterValueId && a.userGlobalFilterTypeId === filtersCategoryId,
    )
      .map(filterItem => ({
        ...filterItem,
        isEnabled: true,
        name:
          filtersCategoryId === FILTER_VEHICLE_TYPE_ID
            ? VEHICLE_TYPE_IDS[filterItem.filterValueId].name
            : filtersCategoryId === FILTER_SUPERVISOR_ID
            ? `${get(find(allFiltersCategory, { id: filterItem.filterValueId }), 'firstName')} ${get(
                find(allFiltersCategory, { id: filterItem.filterValueId }),
                'lastName',
              )}`
            : get(find(allFiltersCategory, { id: filterItem.filterValueId }), 'name'),
        value: `catId_${filtersCategoryId}_optId_${get(
          find(allFiltersCategory, { id: filterItem.filterValueId }),
          'id',
        )}`,
      }))
      .filter(filterItem => filterItem.isGlobal);

    const allOptions = map(allFiltersCategory, filterItem => {
      const existingFilterSetting = find(existingSettings, {
        filterValueId: filterItem.id,
        userGlobalFilterTypeId: filtersCategoryId,
        userGlobalFilterTypeTechnicalName: FILTER_TYPES[filtersCategoryId].technicalName,
      });
      return {
        ...(existingFilterSetting || {
          vendorId: vendorId,
          userId: userId,
          filterValueId: filterItem.id,
          userGlobalFilterTypeId: filtersCategoryId,
          userGlobalFilterTypeTechnicalName: FILTER_TYPES[filtersCategoryId].technicalName,
          isGlobal: isAdmin,
        }),
        name:
          filtersCategoryId === FILTER_VEHICLE_TYPE_ID
            ? VEHICLE_TYPE_IDS[filterItem.id].name
            : filtersCategoryId === FILTER_SUPERVISOR_ID
            ? `${filterItem.firstName} ${filterItem.lastName}`
            : filterItem.name,
        value: `catId_${filtersCategoryId}_optId_${get(find(allFiltersCategory, { id: filterItem.id }), 'id')}`,
        isEnabled: !!existingFilterSetting,
      };
    });

    // the user will not be abe to se the rest of the options if the section is locked
    if (!isAdmin && isSectionLocked && filtersAlreadySet && filtersAlreadySet.length)
      return { isSectionLocked, options: filtersAlreadySet };

    return { isSectionLocked, options: allOptions };
  };

  const vehicleTypesOptionsSettings = getFiltersCategorySettings(
    vehicleTypes,
    filters,
    FILTER_VEHICLE_TYPE_ID,
    isAdmin,
  );
  const [vehicleTypesFilters, setVehicleTypesFilters] = useState(vehicleTypesOptionsSettings.options);
  const [vehicleTypesFiltersLocked, setVehicleTypesFiltersLocked] = useState(
    vehicleTypesOptionsSettings.isSectionLocked,
  );

  const serviceTypesOptionsSettings = getFiltersCategorySettings(
    serviceTypes,
    filters,
    FILTER_SERVICE_TYPE_ID,
    isAdmin,
  );
  const [serviceTypesFilters, setServiceTypesFilters] = useState(serviceTypesOptionsSettings.options);
  const [serviceTypesFiltersLocked, setServiceTypesFiltersLocked] = useState(
    serviceTypesOptionsSettings.isSectionLocked,
  );

  const supervisorsOptionsSettings = getFiltersCategorySettings(supervisors, filters, FILTER_SUPERVISOR_ID, isAdmin);
  const [supervisorsFilters, setSupervisorsFilters] = useState(supervisorsOptionsSettings.options);
  const [supervisorsFiltersLocked, setSupervisorsFiltersLocked] = useState(supervisorsOptionsSettings.isSectionLocked);

  const serviceZonesOptionsSettings = getFiltersCategorySettings(
    filter(serviceZones, s => s.isActive === true || s.id === NONE_ID),
    filters,
    FILTER_SERVICE_ZONE_ID,
    isAdmin,
  );
  const [serviceZonesFilters, setServiceZonesFilters] = useState(serviceZonesOptionsSettings.options);
  const [serviceZonesFiltersLocked, setServiceZonesFiltersLocked] = useState(
    serviceZonesOptionsSettings.isSectionLocked,
  );

  const saveGlobalFilters = () => {
    const globalFiltersForUser = [
      ...map(
        vehicleTypesFilters.filter(filter => filter.isEnabled),
        filter => ({ ...omit(filter, ['value', 'name', 'isEnabled']), isLockedSection: vehicleTypesFiltersLocked }),
      ),
      ...map(
        serviceTypesFilters.filter(filter => filter.isEnabled),
        filter => ({ ...omit(filter, ['value', 'name', 'isEnabled']), isLockedSection: serviceTypesFiltersLocked }),
      ),
      ...map(
        supervisorsFilters.filter(filter => filter.isEnabled),
        filter => ({ ...omit(filter, ['value', 'name', 'isEnabled']), isLockedSection: supervisorsFiltersLocked }),
      ),
      ...map(
        serviceZonesFilters.filter(filter => filter.isEnabled),
        filter => ({
          ...omit(filter, ['value', 'name', 'isEnabled']),
          isLockedSection: serviceZonesFiltersLocked,
        }),
      ),
    ];

    if (isAdmin)
      saveGlobalFiltersSettings(
        vendorId,
        userId,
        globalFiltersForUser,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('common.defaultFiltersAlertMessages.filtersSaved'));
          removeItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY);
          closeModal(true);
        })
        .catch(() => {
          createErrorNotification(translate('common.defaultFiltersAlertMessages.filtersSaveError'));
        });
    else {
      savePreferencesFiltersSettings(
        vendorId,
        userId,
        globalFiltersForUser,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('common.defaultFiltersAlertMessages.filtersSaved'));
          removeItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY);
          closeModal(true);
        })
        .catch(() => {
          createErrorNotification(translate('common.defaultFiltersAlertMessages.filtersSaveError'));
        });
    }
  };

  return (
    <Modal
      title={`${user ? `${user.name}: ${translate('common.defaultFilters')}` : translate('account.myDefaultFilters')} `}
      onClose={closeModal}
      padding="medium"
      size="medium"
    >
      <DefaultFiltersModalSection
        filters={vehicleTypesFilters}
        setFilters={setVehicleTypesFilters}
        locked={vehicleTypesFiltersLocked}
        setLocked={setVehicleTypesFiltersLocked}
        title={FILTER_TYPES[FILTER_VEHICLE_TYPE_ID].name}
        isAdmin={isAdmin}
        bottomLine
      />
      <DefaultFiltersModalSection
        filters={serviceTypesFilters}
        setFilters={setServiceTypesFilters}
        locked={serviceTypesFiltersLocked}
        setLocked={setServiceTypesFiltersLocked}
        title={FILTER_TYPES[FILTER_SERVICE_TYPE_ID].name}
        isAdmin={isAdmin}
        bottomLine
      />
      <DefaultFiltersModalSection
        filters={supervisorsFilters}
        setFilters={setSupervisorsFilters}
        locked={supervisorsFiltersLocked}
        setLocked={setSupervisorsFiltersLocked}
        title={FILTER_TYPES[FILTER_SUPERVISOR_ID].name}
        isAdmin={isAdmin}
        bottomLine
      />
      <DefaultFiltersModalSection
        filters={serviceZonesFilters}
        setFilters={setServiceZonesFilters}
        locked={serviceZonesFiltersLocked}
        setLocked={setServiceZonesFiltersLocked}
        title={FILTER_TYPES[FILTER_SERVICE_ZONE_ID].name}
        isAdmin={isAdmin}
      />
      <GridColumn size="12/12" padding="sMedium no">
        <ButtonSet margin="large auto no" align="center">
          <Button line color="primary" margin="no small" onClick={closeModal}>
            {translate('common.cancel')}
          </Button>
          <Button color="primary" onClick={saveGlobalFilters}>
            {translate('common.save')}
          </Button>
        </ButtonSet>
      </GridColumn>
    </Modal>
  );
};

export default DefaultFiltersModal;
