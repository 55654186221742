import { mapKeys } from 'lodash-es';

const CUSTOMER_TIME_OPTIONS = mapKeys(
  [
    {
      id: 1,
      value: '12:00 AM - 01:59 AM',
      name: '12:00 AM - 01:59 AM',
      startDateTime: '12:00 AM',
      endDateTime: '01:59 AM',
    },
    {
      id: 2,
      value: '02:00 AM - 03:59 AM',
      name: '02:00 AM - 03:59 AM',
      startDateTime: '02:00 AM',
      endDateTime: '03:59 AM',
    },
    {
      id: 3,
      value: '04:00 AM - 05:59 AM',
      name: '04:00 AM - 05:59 AM',
      startDateTime: '04:00 AM',
      endDateTime: '05:59 AM',
    },
    {
      id: 4,
      value: '06:00 AM - 07:59 AM',
      name: '06:00 AM - 07:59 AM',
      startDateTime: '06:00 AM',
      endDateTime: '07:59 AM',
    },
    {
      id: 5,
      value: '08:00 AM - 09:59 AM',
      name: '08:00 AM - 09:59 AM',
      startDateTime: '08:00 AM',
      endDateTime: '09:59 AM',
    },
    {
      id: 6,
      value: '10:00 AM - 11:59 AM',
      name: '10:00 AM - 11:59 AM',
      startDateTime: '10:00 AM',
      endDateTime: '11:59 AM',
    },
    {
      id: 7,
      value: '12:00 PM - 01:59 PM',
      name: '12:00 PM - 01:59 PM',
      startDateTime: '12:00 PM',
      endDateTime: '01:59 PM',
    },
    {
      id: 8,
      value: '02:00 PM - 03:59 PM',
      name: '02:00 PM - 03:59 PM',
      startDateTime: '02:00 PM',
      endDateTime: '03:59 PM',
    },
    {
      id: 9,
      value: '04:00 PM - 05:59 PM',
      name: '04:00 PM - 05:59 PM',
      startDateTime: '04:00 PM',
      endDateTime: '05:59 PM',
    },
    {
      id: 10,
      value: '06:00 PM - 07:59 PM',
      name: '06:00 PM - 07:59 PM',
      startDateTime: '06:00 PM',
      endDateTime: '07:59 PM',
    },
    {
      id: 11,
      value: '08:00 PM - 09:59 PM',
      name: '08:00 PM - 09:59 PM',
      startDateTime: '08:00 PM',
      endDateTime: '09:59 PM',
    },
    {
      id: 12,
      value: '10:00 PM - 11:59 PM',
      name: '10:00 PM - 11:59 PM',
      startDateTime: '10:00 PM',
      endDateTime: '11:59 PM',
    },
    {
      id: 13,
      value: '12:00 AM - 11:59 PM',
      name: '12:00 AM - 11:59 PM',
      startDateTime: '12:00 AM',
      endDateTime: '11:59 PM',
    },
  ],
  'id',
);

export default CUSTOMER_TIME_OPTIONS;
