import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const WEIGHT_TICKET = 'Weight Ticket';
export const OTHER = 'Other';
export const MANIFEST = 'MAN';
export const CERTIFICATE_OF_DESTRUCTION = 'COD';

export const DOCUMENT_TYPE = mapKeys(
  [
    { id: WEIGHT_TICKET, name: translate('common.weightTicket') },
    { id: OTHER, name: translate('opportunity.other') },
    { id: MANIFEST, name: translate('opportunity.documentType.manifest') },
    { id: CERTIFICATE_OF_DESTRUCTION, name: translate('opportunity.documentType.certificationOfDestruction') },
  ],
  'id',
);
