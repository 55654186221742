import React from 'react';
import { useDispatch } from 'react-redux';
import { PageLoading } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { loadPublicReleaseNotes } from '../../ducks/releaseNotes';
import ReleaseNotesPage from './ReleaseNotesPage';

export const ReleaseNotesPageResolver: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <TypedResolver
      resolve={() => loadPublicReleaseNotes()(dispatch)}
      successComponent={ReleaseNotesPage}
      loadingComponent={PageLoading}
    />
  );
};
