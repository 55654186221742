import styled from 'styled-components';

import { Devices } from '../../../core/styles';

export const TileText = styled.div`
  max-width: 400px;
  margin: auto;
  @media ${Devices.mobile} {
    padding: 0 30px;
  }
`;

export const TileImage = styled.img`
  max-height: 500px;
`;

export const Tile = styled.div<{ reverse: boolean }>`
  display: flex;
  position: absolute;
  width: 100%;
  flex-flow: ${p => (p.reverse ? 'row-reverse' : 'row')};
  align-items: center;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;

  &[data-active='true'] {
    opacity: 1;
    visibility: visible;
  }

  @media ${Devices.mobile} {
    display: block;
    overflow: hidden;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 25px;
`;

export const Subtitle = styled.div`
  font-size: 35px;
  line-height: 42px;
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.5em;
`;
