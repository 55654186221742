import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { FleetDetails } from '../interfaces/FleetDetails';
import { loadFleetDetails as doLoadFleetDetails } from '../services/fleetDetails';

const START_LOAD = 'routes/fleetDetails/START_LOAD';
const COMPLETE_LOAD = 'routes/fleetDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/fleetDetails/FAIL_LOAD';
const RESET = 'routes/fleetDetails/RESET';

interface State {
  fleetDetails?: FleetDetails;
  isLoading: boolean;
}

const initialState: State = {
  fleetDetails: undefined,
  isLoading: false,
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          fleetDetails: action.fleetDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          fleetDetails: undefined,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (fleetDetails: FleetDetails) => ({
  type: COMPLETE_LOAD,
  fleetDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadFleetDetails = (vendorId: number, routeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadFleetDetailsPromise = doLoadFleetDetails(vendorId, routeId);
  loadFleetDetailsPromise.then(fleetDetails => dispatch(completeLoad(fleetDetails))).catch(() => dispatch(failLoad()));
  return loadFleetDetailsPromise;
};

export const resetFleetDetails = () => ({
  type: RESET,
});
