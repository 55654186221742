import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { CustomerPaymentMethod } from '../interfaces/CustomerPaymentMethod';
import {
  getCustomerPaymentMethods,
  getLocationPaymentMethods,
  postAssignPaymentToLocations,
  postDeleteCustomerPaymentMethod,
  postDeleteLocationPaymentMethod,
  postLocationPaymentMethods,
  postSetAutopay,
  postSetDefaultPayment,
  postUnenrollAutomaticPayments,
} from '../services/customerPaymentMethods';

// Actions
const START_LOAD = 'customers/paymentMethods/START_LOAD';
const COMPLETE_LOAD = 'customers/paymentMethods/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/paymentMethods/FAIL_LOAD';
const START_SAVE = 'customers/paymentMethods/START_SAVE';
const COMPLETE_SAVE = 'customers/paymentMethods/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/paymentMethods/FAIL_SAVE';
const RESET = 'customers/paymentMethods/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  paymentMethods?: CustomerPaymentMethod[];
}

// Initial state
const initialState: State = {
  isLoading: false,
  isSaving: false,
  paymentMethods: [],
};

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethods: action.paymentMethods,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentMethods: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (paymentMethods: CustomerPaymentMethod[]) => ({
  type: COMPLETE_LOAD,
  paymentMethods,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const reset = () => ({
  type: RESET,
});

export const loadCustomerPaymentMethods = (customerId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadPaymentMethods = getCustomerPaymentMethods(customerId);
  loadPaymentMethods.then(paymentMethods => dispatch(completeLoad(paymentMethods))).catch(() => dispatch(failLoad()));
  return loadPaymentMethods;
};

export const loadLocationPaymentMethods = (locationId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadPaymentMethods = getLocationPaymentMethods(locationId);
  loadPaymentMethods.then(paymentMethods => dispatch(completeLoad(paymentMethods))).catch(() => dispatch(failLoad()));
  return loadPaymentMethods;
};

export const saveLocationPaymentMethods =
  (locationId: number, paymentConnectPaymentMethodId?: string) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const savePaymentMethods = postLocationPaymentMethods(locationId, paymentConnectPaymentMethodId);
    savePaymentMethods.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return savePaymentMethods;
  };

export const saveUnenrollAutomaticPayments = (locationId: number) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const savePaymentMethods = postUnenrollAutomaticPayments(locationId);
  savePaymentMethods.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return savePaymentMethods;
};

export const setDefaultAutopay =
  (locationId: number, paymentConnectPaymentMethodId: string, customerId: number) => (dispatch: Dispatch) => {
    postSetAutopay(locationId, paymentConnectPaymentMethodId).then(() => {
      loadCustomerPaymentMethods(customerId)(dispatch);
    });
  };

export const setDefaultManual =
  (locationId: number, paymentConnectPaymentMethodId: string, customerId: number) => (dispatch: Dispatch) => {
    postSetDefaultPayment(locationId, paymentConnectPaymentMethodId).then(() => {
      loadCustomerPaymentMethods(customerId)(dispatch);
    });
  };

export const assignPaymentToLocation =
  (customerId: number, customerLocationIds: number[], customerExternalPaymentMethodId: number) =>
  async (dispatch: Dispatch) => {
    await postAssignPaymentToLocations(customerLocationIds, customerExternalPaymentMethodId);
    return loadCustomerPaymentMethods(customerId)(dispatch);
  };

export const deleteCustomerPaymentMethod =
  (customerId: number, customerExternalPaymentMethodId: number) => async (dispatch: Dispatch) => {
    postDeleteCustomerPaymentMethod(customerExternalPaymentMethodId).then(() =>
      loadCustomerPaymentMethods(customerId)(dispatch),
    );
  };

export const deleteLocationPaymentMethod =
  (customerId: number, customerExternalPaymentMethodId: number) => async (dispatch: Dispatch) => {
    postDeleteLocationPaymentMethod(customerExternalPaymentMethodId).then(() =>
      loadCustomerPaymentMethods(customerId)(dispatch),
    );
  };

export const resetPaymentMethods = () => (dispatch: Dispatch) => {
  dispatch(reset());
};
