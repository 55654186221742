import { getLanguageOptions, saveLanguageSetting as doSaveLanguageSetting } from '../services/getLanguageOptions';
import { currentVendorIdSelector } from '../../vendors/services/currentVendorSelector';
import { setCurrentLanguageId } from '../../core/services/i18n';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Constants
const START_LOAD = 'common/languageOptions/START_LOAD';
const COMPLETE_LOAD = 'common/languageOptions/COMPLETE_LOAD';
const FAIL_LOAD = 'common/languageOptions/FAIL_LOAD';
const START_SAVE = 'common/languageOptions/START_SAVE';
const COMPLETE_SAVE = 'common/languageOptions/COMPLETE_SAVE';
const FAIL_SAVE = 'common/languageOptions/FAIL_SAVE';
const LOCALE_VALUE = 'common/languageOptions/LOCALE_VALUE';
const RESET = 'common/languageOptions/RESET';

// Initial State
const initialState = {
  isLoading: false,
  languageOptions: null,
};

interface LanguageSettings {
  isLoading: boolean;
  languageOptions: any | null;
  localValue?: any;
  language?: any;
  languageId?: number;
}

// Reducer
export const reducer = (state: LanguageSettings = initialState, action: AnyAction): LanguageSettings => {
  switch (action.type) {
    case LOCALE_VALUE:
      return {
        ...state,
        localValue: action.localValue,
      };
    case START_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case START_SAVE:
      return {
        ...state,
        isLoading: true,
      };
    case COMPLETE_LOAD:
      return {
        ...state,
        isLoading: false,
        languageOptions: action.data,
      };
    case FAIL_LOAD:
      return {
        ...state,
        isLoading: false,
        languageOptions: null,
      };
    case COMPLETE_SAVE:
      return {
        ...state,
        isLoading: false,
        language: action.language,
        languageId: action.languageId,
      };
    case FAIL_SAVE:
      return {
        ...state,
        isLoading: false,
      };
    case RESET:
      return initialState;
    default:
      return { ...state };
  }
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (action: AnyAction) => ({
  type: COMPLETE_LOAD,
  data: action.data,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

export const completeSave = (language: string, languageId: number) => {
  setCurrentLanguageId(languageId);
  return {
    type: COMPLETE_SAVE,
    language,
    languageId,
  };
};

const failSave = () => ({
  type: FAIL_SAVE,
});

export const resetLanguageOptions = () => {
  setCurrentLanguageId(null);
  return {
    type: RESET,
  };
};

export const localeValue = (localeValue: number) => ({
  type: LOCALE_VALUE,
  localeValue,
});

type Dispatch = ThunkDispatch<LanguageSettings, any, AnyAction>;

// Async Actions
export const loadLanguageOptions = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  getLanguageOptions()
    .then((data: any) => dispatch(completeLoad(data)))
    .catch(() => dispatch(failLoad()));
  return getLanguageOptions;
};

export const saveLanguageOptions = (languageId: number) => (dispatch: Dispatch, getState: any) => {
  const state = getState();
  const vendorId = currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any as number;
  const userId = state.account.login.user.userId;
  const saveLanguagePromise = doSaveLanguageSetting(languageId, vendorId, userId);
  dispatch(startSave());
  saveLanguagePromise
    .then(data => {
      const {
        data: { language, languageId },
      } = data;
      dispatch(completeSave(language, languageId));
    })
    .catch(() => dispatch(failSave()));
  return saveLanguagePromise;
};
