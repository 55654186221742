import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import humps from 'humps';

import { Button } from 'src/core/components/styled/Button';
import { changeMobilePageDetailOverlayTitleContent } from 'src/core/ducks/mobilePage';
import { ModalTitle } from 'src/core/components/styled/Modal';
import { OpportunityRow } from '../interfaces/Opportunities';
import { ServiceIconImage } from 'src/fleet/components/styled/RubiconDispatches';
import Icon from 'src/core/components/Icon';
import SERVICE_TYPE_ICONS from 'src/customers/constants/serviceTypeIcons';
import styled from 'styled-components';
import translate from 'src/core/services/translate';
import { MobileDataFields } from 'src/core/components/MobileDataFields';

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OpportunityMobileListRowDetailsView: React.FC<
  OpportunityRow & {
    onAcceptBid: () => void;
    onDeclineBid: () => void;
    onSubmitBid: () => void;
    onRequestChanges: () => void;
  }
> = ({
  requestType,
  customerAddress,
  customerName,
  equipment,
  siteName,
  offer: { overallserviceCharge, service, deliveryDate },
  options: { canAccept, canDecline, canDeclineBid, canSubmitBid, canRequestChanges },
  opportunityStatus,
  onAcceptBid,
  onDeclineBid,
  onSubmitBid,
  onRequestChanges,
}) => {
  const fields = [
    {
      label: translate('opportunity.opportunities.mobileOpportunitiesPage.requestType'),
      text: requestType.name,
    },
    {
      label: translate('opportunity.opportunities.mobileOpportunitiesPage.opportunityStatus'),
      text: opportunityStatus.name,
    },
    { label: translate('opportunity.opportunities.mobileOpportunitiesPage.targetRate'), text: service },
    { label: translate('opportunity.opportunities.mobileOpportunitiesPage.bidRate'), text: overallserviceCharge },
    {
      label: translate('opportunity.opportunities.mobileOpportunitiesPage.bidDate'),
      text: deliveryDate,
    },
    {
      label: translate('common.equipmentType'),
      text: equipment.subType
        ? translate(`haulerProfile.equipments.equipmentSubTypes.${equipment.subType}`)
        : equipment.type,
    },
    { label: translate('common.equipmentSize'), text: equipment.size ? `${parseInt(equipment.size)} ${equipment.units}` : null},
    { label: translate('customers.wasteMaterial'), text: equipment.wasteMaterial },
    { label: translate('customers.frequency'), text: equipment.frequency },
    { label: translate('opportunity.quantity'), text: equipment.quantity || 0 },
    { label: translate('payment.serviceDescription'), text: equipment.serviceDescription },
    { label: translate('customers.customerName'), text: customerName },
    { label: translate('autoDispatch.siteName'), text: siteName },
    { label: translate('common.address'), text: customerAddress },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    changeMobilePageDetailOverlayTitleContent(
      <TitleSectionContainer>
        <TitleContainer>
          <ServiceIconImage
            width="50px"
            src={SERVICE_TYPE_ICONS[humps.camelize(equipment.iconType.split('.').join(''))]}
          />
          <div>
            <ModalTitle textAlign="left" margin="no xxSmall" fontSize="18px">
              {requestType.name}
            </ModalTitle>
            <ModalTitle textAlign="left" fontSize="16px" margin="no xxSmall">
              {translate('opportunity.opportunities.mobileOpportunitiesPage.targetRate')}: {service}
            </ModalTitle>
          </div>
        </TitleContainer>

        <Actions>
          {canAccept && (
            <Button padding="no" margin="no xSmall" text onClick={onAcceptBid}>
              <Icon customViewBox="0 0 20 20" width="24px" icon="checkCircle" />
            </Button>
          )}
          {canSubmitBid && (
            <Button color="primary" padding="no" margin="no xSmall" text onClick={onSubmitBid}>
              <Icon customViewBox="0 0 28 28" width="24px" icon="bid" />
            </Button>
          )}
          {(canDecline || canDeclineBid) && (
            <Button padding="no" margin="no xSmall" text onClick={onDeclineBid}>
              <Icon customViewBox="0 0 22 22" width="26px" icon="closedIssue" />
            </Button>
          )}
          {canRequestChanges && (
            <Button color="primary" padding="no" margin="no xSmall" text onClick={onRequestChanges}>
              <Icon width="24px" icon="changeRequest" />
            </Button>
          )}
        </Actions>
      </TitleSectionContainer>,
    )(dispatch);
  }, [
    canAccept,
    canDecline,
    canDeclineBid,
    canRequestChanges,
    canSubmitBid,
    dispatch,
    equipment,
    onAcceptBid,
    onDeclineBid,
    onRequestChanges,
    onSubmitBid,
    requestType,
    service,
  ]);

  return (
    <div>
      <MobileDataFields fields={fields} />
    </div>
  );
};
