import styled, { css } from 'styled-components';

import Icon from '../Icon';

interface ToggleIconProps {
  size?: number;
  isOpen?: boolean;
}

export const ToggleIcon = styled(Icon)<ToggleIconProps>`
  width: ${props => props.size || 10}px;
  height: ${props => props.size || 10}px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  color: ${props => props.theme.brandPrimary};

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;
