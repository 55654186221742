import { Modal } from 'src/core/components';
import translate from 'src/core/services/translate';
import QuickBooksConnectForm from '../forms/QuickBooksConnectForm';
import { useSelector } from 'src/core/hooks/useSelector';

interface ComponentProps {
  startQuickBooksAuth: (clientId: string, clientSecret: string) => void;
  isModalOpen: boolean;
  closeModal: () => void;
}

const QuickBooksActivateModal: React.FC<ComponentProps> = ({ startQuickBooksAuth, isModalOpen, closeModal }) => {
  const isLoading = useSelector(state => state.vendors.quickBooks.isLoading);

  if (!isModalOpen) {
    return null;
  }

  const handleSubmit = (values: any) => {
    startQuickBooksAuth(values.clientId, values.clientSecret);
  };

  return (
    <Modal
      title={translate('finance.quickBooksIntegration.activateQuickBooks')}
      onClose={closeModal}
      isLoading={isLoading}
      padding="medium"
      size="small"
    >
      <QuickBooksConnectForm onSubmit={handleSubmit} onCloseModal={closeModal} />
    </Modal>
  );
};

export default QuickBooksActivateModal;
