import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { PageLoadingOverlay } from '../../../../../common/components/styled';
import { TypedResolver } from '../../../../../core/components';
import { loadVehicleTypes } from '../../../../../fleet/ducks';
import { currentVendorId } from '../../../../../vendors/services/currentVendorSelector';
import { loadServiceZones } from '../../../../ducks';
import RouteTemplateBuilderAssignToRouteModal, {
  RouteTemplateBuilderAssignToRouteModalProps,
} from './RouteTemplateBuilderAssignToRouteModal';

const RouteTemplateBuilderAssignToRouteModalResolver: FC<RouteTemplateBuilderAssignToRouteModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = () =>
    Promise.all([loadVehicleTypes()(dispatch), loadServiceZones(vendorId, false)(dispatch)]);

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoadingOverlay}
      successComponent={RouteTemplateBuilderAssignToRouteModal}
      successProps={props}
    />
  );
};

export default RouteTemplateBuilderAssignToRouteModalResolver;
