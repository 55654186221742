import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { VehicleInProximity } from '../interfaces/Customers';
import { getVehiclesInProximity as doGetVehiclesInProximity } from '../services/vehiclesInProximity';

// Actions
const START_LOAD = 'vehiclesInProximity/vehiclesInProximity/START_LOAD';
const COMPLETE_LOAD = 'vehiclesInProximity/vehiclesInProximity/COMPLETE_LOAD';
const FAIL_LOAD = 'vehiclesInProximity/vehiclesInProximity/FAIL_LOAD';
const RESET = 'vehiclesInProximity/vehiclesInProximity/RESET';

interface State {
  isLoading: boolean;
  vehiclesInProximity: VehicleInProximity[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  vehiclesInProximity: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehiclesInProximity: action.vehiclesInProximity,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehiclesInProximity: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          vehiclesInProximity: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehiclesInProximity: VehicleInProximity[]) => ({
  type: COMPLETE_LOAD,
  vehiclesInProximity,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const getVehiclesInProximity =
  (formData: {
    latitude: number;
    longitude: number;
    radius: number;
    startTime?: string;
    endTime?: string;
    date?: Date | string;
    vendorId: number;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const getVehiclesInProximityPromise = doGetVehiclesInProximity(formData);
    getVehiclesInProximityPromise
      .then(vehiclesInProximity => {
        dispatch(completeLoad(vehiclesInProximity));
      })
      .catch(() => dispatch(failLoad()));
    return getVehiclesInProximityPromise;
  };

export const resetVehiclesInProximity = () => ({
  type: RESET,
});
