import React, { useState } from 'react';

import { orderBy } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { AppState } from '../../../store';
import { createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { deleteXDevice, loadXDeviceStatuses } from '../../ducks/deviceStatuses';
import { Button, GridColumn, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import {
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled/Page';
import { Table, UnconnectedInput } from '../../../core/components';
import { TableCell } from '../../../core/components/Table';
import { XDevicesStatus } from '../../interfaces/XDevicesStatus';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';
import LastKnownLocationModalResolver from '../modals/LastKnownLocationModalResolver';
import XDeviceStatusesPageTableRow, { xDeviceStatusesPageTableRowHeight } from './XDeviceStatusesPageTableRow';
import EditDeviceDetailsModalResolver from '../modals/EditDeviceDetailsModalResolver';
import { exportXDeviceStatuses } from 'src/settings/services/deviceStatuses';
import { isAdminSelector, isSuperAdminSelector } from 'src/account/ducks';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { User } from 'src/account/ducks/login';
import { ADMIN } from '../../../vendors/constants/userRoles';

const SummaryInfoContainer = styled.div`
  width: 250px;
  display: inline-block;
  div {
    margin: 10px 0;
  }
`;

const phoneNameKey = 'phoneName';
const applicationTypeKey = 'applicationType';
const deviceUniqueIdKey = 'deviceUniqueId';
const deviceOwnerKey = 'deviceOwner';
const lastRecordedEventKey = 'lastRecordedEvent';
const durationSinceLastCommKey = 'durationSinceLastCommunicationInDays';
const lastRouteKey = 'routeName';
const driverKey = 'driverName';

const deviceStatusTableColumns: TableCell[] = [
  { name: phoneNameKey, label: translate('settings.deviceName'), width: '14%', sortable: true },
  { name: applicationTypeKey, label: translate('settings.applicationType'), width: '15%', sortable: true },
  { name: deviceOwnerKey, label: translate('settings.deviceOwner'), width: '10%', sortable: true },
  { name: lastRecordedEventKey, label: translate('settings.lastLoginDate'), width: '11%', sortable: true },
  {
    name: durationSinceLastCommKey,
    label: translate('settings.daysSinceLastLogin'),
    width: '10%',
    sortable: true,
  },
  {
    name: lastRouteKey,
    label: translate('settings.lastRoute'),
    width: '15%',
    sortable: true,
  },
  {
    name: driverKey,
    label: translate('settings.lastDriver'),
    width: '15%',
    sortable: true,
  },
  { name: 'options', label: translate('common.options'), width: '10%' },
];

const XDeviceStatusesPage: React.FC<RouteComponentProps> = ({ location }) => {
  const { xDeviceStatuses, isLoading } = useSelector((s: AppState) => s.settings.deviceStatuses);
  const vendor = useSelector((state: AppState) => state.vendors.vendor.vendor) as any as Vendor;
  const isRubiconAdmin = useSelector((state: AppState) => {
    const loginState = state.account.login;
    return isAdminSelector(loginState) || isSuperAdminSelector(loginState);
  });

  const currentUser = useSelector((state: AppState) => state.vendors.users.user) || undefined;
  const isHaulerAdmin = currentUser ? (currentUser as User).roleId === ADMIN : false;
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedBy, setSortedBy] = useState<string>();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>();

  const [isEditDeviceOwnerModalOpen, setEditDeviceOwnerModalOpen] = useState<boolean>(false);
  const [isLastKnownPositionModalOpen, setLastKnownPositionModalOpen] = useState<boolean>(false);
  const [phoneName, setPhoneName] = useState<string>();
  const [deviceId, setDeviceId] = useState<string>();
  const [deviceOwnerId, setDeviceOwnerId] = useState<number>();
  const [deviceSerial, setDeviceSerialId] = useState<string>();

  const vendorId = useSelector(currentVendorId);

  const dispatch = useDispatch();

  const deleteDevice = async (deviceId: string) => {
    if (await confirm(translate('settings.removeDeviceConfirm'))) {
      deleteXDevice(deviceId)(dispatch).then(() => {
        loadXDeviceStatuses(vendorId)(dispatch);
        createSuccessNotification(translate('settings.deviceUnregisteredAlert'));
      });
    }
  };

  const exportStatus = () => {
    exportXDeviceStatuses(vendorId, searchTerm);
  };

  const openEditDeviceOwnerModal = (
    deviceOwnerId: number,
    phoneName: string,
    deviceSerial: string,
    deviceId: string,
  ) => {
    setEditDeviceOwnerModalOpen(true);
    setDeviceOwnerId(deviceOwnerId);
    setPhoneName(phoneName);
    setDeviceSerialId(deviceSerial);
    setDeviceId(deviceId);
  };
  const openLastKnownPositionModal = (phoneName: string, deviceId: string, lastRecordedEvent: string) => {
    setLastKnownPositionModalOpen(true);
    setPhoneName(phoneName);
    setDeviceId(deviceId);
  };

  const closeEditDeviceOwnerModal = () => {
    setEditDeviceOwnerModalOpen(false);
  };
  const closeLastKnownPositionModal = () => {
    setLastKnownPositionModalOpen(false);
  };
  let devices: XDevicesStatus[] = [];

  if (xDeviceStatuses) {
    const lowerCaseSearch = searchTerm.toLowerCase();
    devices = xDeviceStatuses.xDevicesStatus.filter(
      s =>
        s.deviceUniqueId.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
        (s.phoneName && s.phoneName.toLowerCase().indexOf(lowerCaseSearch) !== -1),
    );
    if (sortedBy && sortOrder) {
      if (
        sortedBy === deviceUniqueIdKey ||
        sortedBy === phoneNameKey ||
        sortedBy === lastRouteKey ||
        sortedBy === driverKey
      ) {
        devices = orderBy(devices, [device => device[sortedBy]?.toLowerCase()], sortOrder);
      } else if (sortedBy === lastRecordedEventKey) {
        devices = orderBy(
          devices,
          [
            ({ lastRecordedEvent }) =>
              lastRecordedEvent ? moment(lastRecordedEvent).toISOString() : lastRecordedEventKey,
          ],
          sortOrder,
        );
      } else if (sortedBy === durationSinceLastCommKey) {
        devices = orderBy(
          devices,
          [
            ({ durationSinceLastCommunicationInDays, lastRecordedEvent }) =>
              lastRecordedEvent === null ? 0 : durationSinceLastCommunicationInDays,
          ],
          sortOrder,
        );
      }
    }
  }

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButton to="/account-settings">
              <PageBackButtonIcon />
            </PageBackButton>
            <PageTitle>{translate('vendors.manageDevices')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>

      <Panel>
        <PanelSectionGroup isLoading={isLoading}>
          <PanelSection padding="small">
            {xDeviceStatuses && (
              <>
                <SummaryInfoContainer>
                  <div>{translate('settings.totalLicenses')}</div>
                  <div>{translate('settings.usedLicenses')}</div>
                  <div>{translate('settings.availableLicenses')}</div>
                </SummaryInfoContainer>
                <SummaryInfoContainer>
                  <div>
                    {xDeviceStatuses.maxNumberOfAppLicenses !== null
                      ? xDeviceStatuses.maxNumberOfAppLicenses
                      : translate('common.notAvailable')}
                  </div>
                  <div>
                    {xDeviceStatuses.usedLicenses !== null
                      ? xDeviceStatuses.usedLicenses
                      : translate('common.notAvailable')}
                  </div>
                  <div>
                    {xDeviceStatuses.availableLicenses !== null
                      ? xDeviceStatuses.availableLicenses > 0
                        ? xDeviceStatuses.availableLicenses
                        : 0
                      : translate('common.notAvailable')}
                  </div>
                </SummaryInfoContainer>
              </>
            )}
            <GridColumn alignRight>
              <Button onClick={exportStatus} color="primary">
                {translate('common.export')}
              </Button>
            </GridColumn>
          </PanelSection>
          <PanelSection>
            <UnconnectedInput
              onChange={setSearchTerm}
              width="50%"
              margin="small"
              placeholder={translate('common.search')}
            />
          </PanelSection>
          <PanelSection>
            {!!devices.length ? (
              <Table
                virtualized
                virtualizedProps={{
                  itemSize: xDeviceStatusesPageTableRowHeight,
                  height: xDeviceStatusesPageTableRowHeight * Math.min(devices.length, 9),
                }}
                cells={deviceStatusTableColumns}
                rows={devices}
                rowComponent={XDeviceStatusesPageTableRow}
                rowProps={{
                  onDeleteDevice: deleteDevice,
                  onOpenEditDeviceOwnerModal: openEditDeviceOwnerModal,
                  onOpenLastKnownPositionModal: openLastKnownPositionModal,
                  isRubiconAdmin: isRubiconAdmin,
                  isHaulerAdmin: isHaulerAdmin,
                  deviceOwnerTypeId: deviceOwnerId,
                  vendorXDeviceTypeId: vendor?.xDeviceType && vendor.xDeviceType?.id,
                }}
                sort={(sb, so) => {
                  setSortedBy(sb);
                  setSortOrder(so);
                }}
              />
            ) : (
              <Message padding="sMedium">{translate('settings.noDeviceStatuses')}</Message>
            )}
          </PanelSection>
        </PanelSectionGroup>
      </Panel>
      {isLastKnownPositionModalOpen && (
        <LastKnownLocationModalResolver
          deviceId={deviceId}
          deviceName={phoneName}
          vendorId={vendorId}
          closeModal={() => closeLastKnownPositionModal()}
        />
      )}
      {isEditDeviceOwnerModalOpen && (
        <EditDeviceDetailsModalResolver
          deviceId={deviceId}
          serialNumber={deviceSerial}
          deviceOwnerId={deviceOwnerId}
          phoneName={phoneName}
          vendorId={vendorId}
          closeModal={() => closeEditDeviceOwnerModal()}
        />
      )}
    </PageContent>
  );
};

export default withRouter(XDeviceStatusesPage);
