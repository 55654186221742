import { identity, map, split } from 'lodash-es';
import { createSelector } from 'reselect';

const getNotificationPhoneNumbers = (phoneNumbers: string) => ({
  textNotificationPhones: map(split(phoneNumbers, ','), number => ({
    phoneNumber: number,
  })),
});

const notificationPhoneNumbersFormInitialValuesSelector = createSelector(getNotificationPhoneNumbers, identity);

export default notificationPhoneNumbersFormInitialValuesSelector;
