import { connect } from 'react-redux';
import { get, map } from 'lodash-es';
import { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { AVAILABLE } from 'src/fleet/constants/status';
import { checkVehiclesHaveRoutesInRange } from 'src/fleet/ducks/vehicle';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { Facility } from 'src/common/interfaces/Facility';
import { getIsFacilityActive } from '../utils/vehiclesPageHooks';
import { Panel, PanelSection } from '../../../core/components/styled';
import {
  PageContent,
  PageHeader,
  PageDetails,
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageTitleContainer,
  PageTitle,
} from '../../../common/components/styled';
import { removeLastLocation } from 'src/core/ducks';
import { RIGHT } from 'src/vendors/constants';
import { saveVehicle, resetVehicle } from '../../ducks';
import { SNOW_PLOW_ID, TRAPEZOID } from 'src/fleet/constants';
import { TODAY } from 'src/core/constants';
import { VehicleEditorForm } from '../forms';
import confirm from '../../../core/services/confirm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

interface RouteParams {
  vehicleId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  checkVehiclesHaveRoutesInRange: DuckFunction<typeof checkVehiclesHaveRoutesInRange>;
  isCheckingVehiclesHaveRoutesInRange: boolean;
  isSaving: boolean;
  lastLocations: Dictionary<string>;
  push: any;
  removeLastLocation: (location: string) => void;
  resetVehicle: DuckAction<typeof resetVehicle>;
  saveVehicle: DuckFunction<typeof saveVehicle>;
  vehicleFacilities: Facility[];
  vendorId: number;
}

export const getVehicleUnavailable = (formData: any, isAvailable: boolean) => {
  return formData.id && formData.vehicleUnavailableInitialValues?.id
    ? {
        id: formData.vehicleUnavailableInitialValues?.id,
        startDate: formData.vehicleUnavailableStartDate || TODAY,
        endDate: formData.vehicleUnavailableEndDate,
        isDeleted: isAvailable && !formData.vehicleUnavailableStartDate && !formData.vehicleUnavailableEndDate,
      }
    : (formData.id && !formData.vehicleUnavailableInitialValues?.id && isAvailable) || (!formData.id && isAvailable)
    ? null
    : {
        startDate: formData.vehicleUnavailableStartDate || TODAY,
        endDate: formData.vehicleUnavailableEndDate,
        isDeleted: isAvailable && !formData.vehicleUnavailableStartDate && !formData.vehicleUnavailableEndDate,
      };
};

class VehicleEditorPage extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetVehicle();
  }

  handleBackButton = () => {
    const { history, lastLocations, push, removeLastLocation } = this.props;

    const createVehicle = get(lastLocations, '/fleet/vehicles/create');
    if (createVehicle) {
      removeLastLocation(createVehicle);
    }

    const hasLastLocations = Object.keys(lastLocations).length > 1;
    hasLastLocations && !createVehicle ? history.goBack() : push('/fleet/vehicles');
  };

  onSubmit = async (formData: any) => {
    const { saveVehicle, vehicleFacilities } = this.props;

    const isAvailable = formData.isAvailable === AVAILABLE;
    const isFacilityActive = getIsFacilityActive(vehicleFacilities, formData);

    if (!isFacilityActive) {
      if (!(await confirm(translate('vehicles.alertMessages.saveVehicleWithInactiveFacility')))) {
        return;
      }
    }

    // TODO: uncomment after BE is done (see RVP-5369)

    // const { checkVehiclesHaveRoutesInRange, vendorId } = this.props;

    // const currentVehicle = isAvailable
    //   ? []
    //   : await checkVehiclesHaveRoutesInRange(
    //       vendorId,
    //       [formData.id],
    //       formData.vehicleUnavailableStartDate || TODAY,
    //       formData.vehicleUnavailableEndDate,
    //     );

    // if (currentVehicle[0]?.hasRoutes)
    //   if (
    //     !(await confirm(
    //       translate('vehicles.alertMessages.vehicleHasRoutesInRange', {
    //         selectedVehicle: formData.regplate,
    //       }),
    //       '',
    //       translate('common.cancel'),
    //       translate('common.continue'),
    //     ))
    //   )
    //     return;

    const binColors = map(formData.binColors, binColor => ({ id: binColor }));
    const vehicleUnavailable = getVehicleUnavailable(formData, isAvailable);

    const data = {
      ...formData,
      vehicleUnavailable,
      isAvailable,
      aiModelTypes: get(formData, 'aiModelTypes') ? formData.aiModelTypes : [],
      binColors: formData.rubiconZDeviceId ? binColors : [],
    };

    if (formData.vehicleTypeId === SNOW_PLOW_ID) {
      data.streetServicingSideId = RIGHT;
      data.confirmationModeId = TRAPEZOID;
    }

    delete data.vehicleUnavailableInitialValues;
    delete data.vehicleUnavailableStartDate;
    delete data.vehicleUnavailableEndDate;

    saveVehicle({ ...data })
      .then(() => {
        createSuccessNotification(`${translate('vehicles.alertMessages.vehicleSaved')}`);

        this.handleBackButton();
      })
      .catch(({ code }) => {
        createErrorNotification(`${translate(createTranslationKey(code, 'vehicles.alertMessages'))}`);
      });
  };

  render() {
    const {
      isCheckingVehiclesHaveRoutesInRange,
      isSaving,
      match: {
        params: { vehicleId },
      },
    } = this.props;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction
                id="back-button"
                onClick={() => {
                  this.handleBackButton();
                }}
              >
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate(`vehicles.${vehicleId ? 'edit' : 'create'}Vehicle`)}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions />
        </PageHeader>
        <Panel>
          <PanelSection vertical padding="large" isLoading={isSaving || isCheckingVehiclesHaveRoutesInRange}>
            <VehicleEditorForm
              isCreate={!vehicleId}
              onSubmit={this.onSubmit}
              vehicleId={vehicleId ? Number(vehicleId) : undefined}
            />
          </PanelSection>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isCheckingVehiclesHaveRoutesInRange: state.fleet.vehicle.isCheckingVehiclesHaveRoutesInRange,
  isSaving: state.fleet.vehicle.isSaving,
  lastLocations: state.core.lastLocations,
  vehicleFacilities: state.fleet.vehicle.vehicleFacilities,
});

const mapDispatchToProps = {
  checkVehiclesHaveRoutesInRange,
  push,
  removeLastLocation,
  resetVehicle,
  saveVehicle,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleEditorPage));
