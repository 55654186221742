export const BREAK_TIME = 'Breaktime';
export const BRINING = 'Brining';
export const DISPOSAL_FACILITY = 'DisposalFacility';
export const DRIVING_TO_DUMP_DEBRIS = 'DrivingToDumpDebris';
export const DRIVING_TO_FROM_FACILITY = 'DrivingToFromFacility';
export const FIRST_GPS_MOVEMENT = 'FirstGPSMovement';
export const IN_SERVICE = 'InService';
export const IN_TRANSIT = 'InTransit';
export const LAST_GPS_MOVEMENT = 'LastGPSMovement';
export const NOT_SERVICING = 'NotServicing';
export const OFFROUTE = 'OffRoute';
export const OPERATIONAL_FACILITY = 'OperationalFacility';
export const PLOWING = 'Plowing';
export const SALTING = 'Salting';
export const STATIONARY_TIME = 'StationaryTime';
export const SWEPT = 'Sweeping';
