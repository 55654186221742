import styled from 'styled-components';

export const ErrorBoundaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 0, 0, 0.26);
  border-radius: 16px;
  backdrop-filter: blur(12.8px);
  -webkit-backdrop-filter: blur(12.8px);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  padding: 16px;
  margin: 30px;

  svg {
    width: 35px;
    height: 35px;
    color: red;
  }
`;
