import { map } from 'lodash-es';

import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import { http } from 'src/core/services/http';
import { RateCodes, RateConfigurationFormValuesToSubmit } from '../interfaces/RateManager';
import { RateConfigurationAddRateFormValues } from '../components/forms/RateConfigurationAddRateForm';
import translate from 'src/core/services/translate';

const transformLoadRateCodes = (rateCodes: RateCodes[]) =>
  map(rateCodes, rateCode => ({
    ...rateCode,
  }));

export const loadRateCodes = () =>
  http.get('/rateCodes/full', {}).then(response => transformLoadRateCodes(response.data));

export const saveRateCode = (formData: RateConfigurationAddRateFormValues) =>
  http.post('/rateCodes/add', { ...formData }).then(response => response.data);

export const saveRateCodes = (formData: RateConfigurationFormValuesToSubmit[], serviceTypeId?: number) =>
  http.post(`/rateCodes/update/${serviceTypeId}`, formData).then(response => response.data);

export const saveRateAccountingCode = (formData: RateConfigurationAddRateFormValues) =>
  http.post('/rateAccountingCodes', { ...formData }).then(response => response.data);

export const loadBillingUnitOfMeasureTypes = () =>
  http.get('/common/systemTypes/unitOfMeasureType/billing', {}).then(response => response.data);

export const loadRateAccountingCodes = () => http.get('/rateAccountingCodes', {}).then(response => response.data);

export const exportRateConfiguration = (serviceTypeId?: number, searchText?: string, enabled?: boolean) =>
  downloadExcelFile('rateCodes/export', `${translate('finance.rateManager.rateConfiguration')}`, {
    serviceTypeId,
    searchText,
    enabled,
  });

export const uploadRateCodeImportFile = (
  fileData: File,
  vendorId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http
    .post('/rateCodeImport/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};

export const uploadAccountingCodeImportFile = (
  fileData: File,
  vendorId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http
    .post('/accountingCodeImport/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};

export const uploadRateImportFile = (
  fileData: File,
  vendorId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http
    .post('/customer/rate/import/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};

export const loadRateCodeImportUploadedFilesStatus = (vendorId: number) =>
  http.get('/rateCodeImport/list', { params: { vendorId } }).then(response => response.data);

export const loadAccountingCodeImportUploadedFilesStatus = (vendorId: number) =>
  http.get('/accountingCodeImport/list', { params: { vendorId } }).then(response => response.data);

export const loadRateImportUploadedFilesStatus = (vendorId: number) =>
  http.get('/customer/rate/import/list', { params: { vendorId } }).then(response => response.data);

export const downloadSampleRateCodeTemplate = () =>
  downloadExcelFile('/rateCodeImport/downloadTemplate', translate('finance.rateManager.rateCodeSampleFile'));

export const downloadSampleAccountingCodeTemplate = () =>
  downloadExcelFile(
    '/accountingCodeImport/downloadTemplate',
    translate('finance.rateManager.accountingCodeSampleFile'),
  );

export const downloadSampleRateTemplate = () =>
  downloadExcelFile('/customer/rate/import/template', translate('finance.rateManager.rateSampleFile'));
