import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const ALUMINIUM = 'AL';
export const APPLIANCES = 'AP';
export const ASH = 'AS';
export const UNIVERSAL_WASTE_BATTERIES = 'BA';
export const BOTTLE_CANS_RECYCLING = 'BC';
export const BOOK_MATERIAL = 'BK';
export const BULK_WASTE = 'BW';
export const CARDBOARD = 'CB';
export const C_D_WASTE = 'CD';
export const CORNERBOARD = 'CE';
export const COLORED_LEDGER = 'CL';
export const COMPOST = 'CM';
export const CONTAMINATION = 'CN';
export const COMPUTER_PRINTOUT = 'CP';
export const C_D_RECYCLING = 'CR';
export const CONCRETE = 'CT';
export const DOUGHT = 'DO';
export const DRUMS_METAL_PLASTIC = 'DR';
export const DRY_WASTE = 'DW';
export const ENVELOPE = 'EN';
export const EXPANDED_POLYSTYRENE = 'EP';
export const EWASTE = 'EW';
export const FOOD_DONATION = 'FD';
export const FILESTOCK = 'FS';
export const FOOD_WASTE = 'FW';
export const GLASS = 'GL';
export const OIL_GREASE = 'GR';
export const GAYLORDS = 'GY';
export const HANGERS = 'HN';
export const HAULER_TECH = 'HT';
export const HAZARDOUS_WASTE = 'HW';
export const LIGHT_BULBS = 'LB';
export const LIGHT_IRON = 'LI';
export const LOW_DESTINY_POLY = 'LP';
export const LIQUID_WASTE = 'LQ';
export const MASONRY = 'MA';
export const MEAT_FAT_AND_BONE = 'MB';
export const METAL_FERROUS = 'MF';
export const MIXED_PLASTIC = 'ML';
export const METAL_NON_FERROUS = 'MN';
export const MOTOR_OIL = 'MO';
export const MIXED_PAPER = 'MP';
export const MIXED_RECYCLING = 'MR';
export const MIXED_SHRED = 'MS';
export const METAL = 'MT';
export const MULCH = 'MU';
export const MIXED = 'MX';
export const NEWSPRINT = 'NP';
export const OFFICE_PAPER = 'OP';
export const ORGANICS_RECYCLING = 'OR';
export const OTHER = 'OT';
export const PLASTIC_1 = 'P1';
export const PLASTIC_2 = 'P2';
export const PLASTIC_3 = 'P3';
export const PLASTIC_4 = 'P4';
export const PLASTIC_5 = 'P5';
export const PLASTIC_6 = 'P6';
export const PLASTIC_7 = 'P7';
export const PALETS = 'PA';
export const PLASTIC = 'PL';
export const PLASTIC_SLIPSHEETS = 'PS';
export const PHARMACY_WASTE = 'PW';
export const NON_RCA_LIQUID = 'RC';
export const ROOFING_MATERIALS = 'RO';
export const RECYCLYNG_MISC = 'RM';
export const REGULATED_WASTE = 'RW';
export const METAL_SCRAP = 'SC';
export const SAWDUST = 'SD';
export const SEPTAGE = 'SG';
export const SHARPS = 'SH';
export const SKIVING = 'SK';
export const SLUDGE = 'SL';
export const SORTABLE_MIX = 'SM';
export const SAND_DIRT_GRIT = 'SN';
export const SPECIAL_WASTE = 'SW';
export const SSR_OCC = "SA";
export const SINGLE_STREAM_RECYCLING = 'SR';
export const SINGLE_STREAM_CARDBOARD = 'SS';
export const STEEL = 'ST';
export const STYROFOAM = 'SY';
export const TEXTILES = 'TE';
export const PASSENGER_TIRES = 'TG';
export const TIRES = 'TI';
export const TIN_LIGHT_IRON = 'TL';
export const TRASH = 'TR';
export const TAX = 'TX';
export const UNSPECIFIED = 'UN';
export const UNIVERSAL_WASTE = 'UW';
export const WOOD_WASTE = 'WD';
export const WAX_FIBER = 'WF';
export const WHITE_GOODS = 'WG';
export const WHITE_LEDGER = 'WL';
export const WIRE = 'WR';
export const WOOD_RECYCLING = 'WW';
export const YARD_WASTE = 'YW';

export const HAULER_PROFILE_MATERIAL_TYPES = mapKeys(
  [
    { id: ALUMINIUM, name: translate('haulerProfile.materialTypes.AL') },
    { id: APPLIANCES, name: translate('haulerProfile.materialTypes.AP') },
    { id: ASH, name: translate('haulerProfile.materialTypes.AS') },
    { id: UNIVERSAL_WASTE_BATTERIES, name: translate('haulerProfile.materialTypes.BA') },
    { id: BOTTLE_CANS_RECYCLING, name: translate('haulerProfile.materialTypes.BC') },
    { id: BOOK_MATERIAL, name: translate('haulerProfile.materialTypes.BK') },
    { id: BULK_WASTE, name: translate('haulerProfile.materialTypes.BW') },
    { id: CARDBOARD, name: translate('haulerProfile.materialTypes.CB') },
    { id: C_D_WASTE, name: translate('haulerProfile.materialTypes.CD') },
    { id: CORNERBOARD, name: translate('haulerProfile.materialTypes.CE') },
    { id: COLORED_LEDGER, name: translate('haulerProfile.materialTypes.CL') },
    { id: COMPOST, name: translate('haulerProfile.materialTypes.CM') },
    { id: CONTAMINATION, name: translate('haulerProfile.materialTypes.CN') },
    { id: COMPUTER_PRINTOUT, name: translate('haulerProfile.materialTypes.CP') },
    { id: C_D_RECYCLING, name: translate('haulerProfile.materialTypes.CR') },
    { id: CONCRETE, name: translate('haulerProfile.materialTypes.CT') },
    { id: DOUGHT, name: translate('haulerProfile.materialTypes.DO') },
    { id: DRUMS_METAL_PLASTIC, name: translate('haulerProfile.materialTypes.DR') },
    { id: DRY_WASTE, name: translate('haulerProfile.materialTypes.DW') },
    { id: ENVELOPE, name: translate('haulerProfile.materialTypes.EN') },
    { id: EXPANDED_POLYSTYRENE, name: translate('haulerProfile.materialTypes.EP') },
    { id: EWASTE, name: translate('haulerProfile.materialTypes.EW') },
    { id: FOOD_DONATION, name: translate('haulerProfile.materialTypes.FD') },
    { id: FILESTOCK, name: translate('haulerProfile.materialTypes.FS') },
    { id: FOOD_WASTE, name: translate('haulerProfile.materialTypes.FW') },
    { id: GLASS, name: translate('haulerProfile.materialTypes.GL') },
    { id: OIL_GREASE, name: translate('haulerProfile.materialTypes.GR') },
    { id: GAYLORDS, name: translate('haulerProfile.materialTypes.GY') },
    { id: HANGERS, name: translate('haulerProfile.materialTypes.HN') },
    { id: HAULER_TECH, name: translate('haulerProfile.materialTypes.HT') },
    { id: HAZARDOUS_WASTE, name: translate('haulerProfile.materialTypes.HW') },
    { id: LIGHT_BULBS, name: translate('haulerProfile.materialTypes.LB') },
    { id: LIGHT_IRON, name: translate('haulerProfile.materialTypes.LI') },
    { id: LOW_DESTINY_POLY, name: translate('haulerProfile.materialTypes.LP') },
    { id: LIQUID_WASTE, name: translate('haulerProfile.materialTypes.LQ') },
    { id: MASONRY, name: translate('haulerProfile.materialTypes.MA') },
    { id: MEAT_FAT_AND_BONE, name: translate('haulerProfile.materialTypes.MB') },
    { id: METAL_FERROUS, name: translate('haulerProfile.materialTypes.MF') },
    { id: MIXED_PLASTIC, name: translate('haulerProfile.materialTypes.ML') },
    { id: METAL_NON_FERROUS, name: translate('haulerProfile.materialTypes.MN') },
    { id: MOTOR_OIL, name: translate('haulerProfile.materialTypes.MO') },
    { id: MIXED_PAPER, name: translate('haulerProfile.materialTypes.MP') },
    { id: MIXED_RECYCLING, name: translate('haulerProfile.materialTypes.MR') },
    { id: MIXED_SHRED, name: translate('haulerProfile.materialTypes.MS') },
    { id: METAL, name: translate('haulerProfile.materialTypes.MT') },
    { id: MULCH, name: translate('haulerProfile.materialTypes.MU') },
    { id: MIXED, name: translate('haulerProfile.materialTypes.MX') },
    { id: NEWSPRINT, name: translate('haulerProfile.materialTypes.NP') },
    { id: OFFICE_PAPER, name: translate('haulerProfile.materialTypes.OP') },
    { id: ORGANICS_RECYCLING, name: translate('haulerProfile.materialTypes.OR') },
    { id: OTHER, name: translate('haulerProfile.materialTypes.OT') },
    { id: PLASTIC_1, name: translate('haulerProfile.materialTypes.P1') },
    { id: PLASTIC_2, name: translate('haulerProfile.materialTypes.P2') },
    { id: PLASTIC_3, name: translate('haulerProfile.materialTypes.P3') },
    { id: PLASTIC_4, name: translate('haulerProfile.materialTypes.P4') },
    { id: PLASTIC_5, name: translate('haulerProfile.materialTypes.P5') },
    { id: PLASTIC_6, name: translate('haulerProfile.materialTypes.P6') },
    { id: PLASTIC_7, name: translate('haulerProfile.materialTypes.P7') },
    { id: PALETS, name: translate('haulerProfile.materialTypes.PA') },
    { id: PLASTIC, name: translate('haulerProfile.materialTypes.PL') },
    { id: PLASTIC_SLIPSHEETS, name: translate('haulerProfile.materialTypes.PS') },
    { id: PHARMACY_WASTE, name: translate('haulerProfile.materialTypes.PW') },
    { id: NON_RCA_LIQUID, name: translate('haulerProfile.materialTypes.RC') },
    { id: ROOFING_MATERIALS, name: translate('haulerProfile.materialTypes.RO') },
    { id: RECYCLYNG_MISC, name: translate('haulerProfile.materialTypes.RM') },
    { id: REGULATED_WASTE, name: translate('haulerProfile.materialTypes.RW') },
    { id: METAL_SCRAP, name: translate('haulerProfile.materialTypes.SC') },
    { id: SAWDUST, name: translate('haulerProfile.materialTypes.SD') },
    { id: SEPTAGE, name: translate('haulerProfile.materialTypes.SG') },
    { id: SHARPS, name: translate('haulerProfile.materialTypes.SH') },
    { id: SKIVING, name: translate('haulerProfile.materialTypes.SK') },
    { id: SLUDGE, name: translate('haulerProfile.materialTypes.SL') },
    { id: SORTABLE_MIX, name: translate('haulerProfile.materialTypes.SM') },
    { id: SAND_DIRT_GRIT, name: translate('haulerProfile.materialTypes.SN') },
    { id: SPECIAL_WASTE, name: translate('haulerProfile.materialTypes.SW') },
    { id: SSR_OCC, name: translate('haulerProfile.materialTypes.SA') },
    { id: SINGLE_STREAM_RECYCLING, name: translate('haulerProfile.materialTypes.SR') },
    { id: SINGLE_STREAM_CARDBOARD, name: translate('haulerProfile.materialTypes.SS') },
    { id: STEEL, name: translate('haulerProfile.materialTypes.ST') },
    { id: STYROFOAM, name: translate('haulerProfile.materialTypes.SY') },
    { id: TEXTILES, name: translate('haulerProfile.materialTypes.TE') },
    { id: TIRES, name: translate('haulerProfile.materialTypes.TG') },
    { id: PASSENGER_TIRES, name: translate('haulerProfile.materialTypes.TI') },
    { id: TIN_LIGHT_IRON, name: translate('haulerProfile.materialTypes.TL') },
    { id: TRASH, name: translate('haulerProfile.materialTypes.TR') },
    { id: TAX, name: translate('haulerProfile.materialTypes.TX') },
    { id: UNSPECIFIED, name: translate('haulerProfile.materialTypes.UN') },
    { id: UNIVERSAL_WASTE, name: translate('haulerProfile.materialTypes.UW') },
    { id: WOOD_WASTE, name: translate('haulerProfile.materialTypes.WD') },
    { id: WAX_FIBER, name: translate('haulerProfile.materialTypes.WF') },
    { id: WHITE_GOODS, name: translate('haulerProfile.materialTypes.WG') },
    { id: WHITE_LEDGER, name: translate('haulerProfile.materialTypes.WL') },
    { id: WIRE, name: translate('haulerProfile.materialTypes.WR') },
    { id: WOOD_RECYCLING, name: translate('haulerProfile.materialTypes.WW') },
    { id: YARD_WASTE, name: translate('haulerProfile.materialTypes.YW') },
  ],
  'id',
);
