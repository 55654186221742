import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

// where are these used cus theyre all wrong.

// bill status
export const DRAFT = 2;
export const BILLED = 3;

// payment status
export const PAYMENT_STATUS_PAID = 4;

export const INVOICE_STATUSES_LIST = [
  { id: DRAFT, name: translate('finance.invoiceStatuses.P') },
  { id: BILLED, name: translate('finance.invoiceStatuses.R') },
];

export const INVOICE_STATUSES = mapKeys(INVOICE_STATUSES_LIST, 'id');
