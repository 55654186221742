import { http } from '../../core/services/http';
import { transformLoadVehicleTypes, transformLoadVehicleTypesForVendor } from './transformLoadVehicleTypes';
import { VehicleType } from '../interfaces/VehicleTypes';

export const loadVehicleTypes = () =>
  http.get<VehicleType[]>('vehicles/vehicleTypes').then(response => transformLoadVehicleTypes(response.data));

export const loadVehicleTypesForVendor = (vendorId: number, onlyRouteVehicleTypes?: boolean) =>
  http
    .get(`vendors/${vendorId}/vehicleTypes`, {
      params: onlyRouteVehicleTypes ? { vehicleRoleType: 1 } : undefined,
    })
    .then(response => transformLoadVehicleTypesForVendor(response.data));

export const saveVehicleTypesForVendor = (vendorId: number, vehicleTypeIds: string[]) =>
  http.post(`vendors/${vendorId}/vehicleTypes`, vehicleTypeIds);
