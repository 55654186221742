import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createErrorNotification } from '../../../core/services/createNotification';
import { NoteItem } from '../../interfaces/RubiconDispatchesNotes';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import * as Ducks from '../../ducks/notes';
import RubiconDispatchesNotesTableRowForm from './RubiconDispatchesNotesTableRowForm';
import translate from '../../../core/services/translate';

interface RouteParams {
  token?: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  index: number;
  note: NoteItem;
}

const RubiconDispatchesNotesTableRow: React.FC<Props> = ({ index, note, match }) => {
  const dispatch = useDispatch();

  const userId = useSelector((state: AppState) => currentUserId(state));
  const vendorId = useSelector((state: AppState) => currentVendorId(state));

  const cancelEditing = React.useCallback(() => {
    dispatch(Ducks.removeFreshNote(index));
  }, [index, dispatch]);

  const submit = React.useCallback(
    async (note: NoteItem) => {
      const { token } = match.params;

      delete note.isFresh;

      const promise = Ducks.addNotes({
        body: note,
        userId,
        vendorId,
        token,
      })(dispatch);

      if(!promise){
        return;
      }

      await promise.then(() => {
        const { workOrder, serviceDate } = note;
        Ducks.getNotes({ token, userId, vendorId, workOrder, serviceDate })(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
    },
    [userId, vendorId, dispatch, match.params],
  );

  return (
    <RubiconDispatchesNotesTableRowForm
      form={`rubiconDispatchesNotesFor${index}`}
      index={index}
      isInEditMode={!!note.isFresh}
      onCancel={cancelEditing}
      initialValues={note}
      onSubmit={submit}
    />
  );
};

export default withRouter(RubiconDispatchesNotesTableRow);
