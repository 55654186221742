import { FC, useMemo } from 'react';

import { useSelector } from 'src/core/hooks/useSelector';
import {
  getTravelPathArrowsGeoJSON,
  getTravelPathGeoJSON,
} from 'src/routes/components/pages/routes/routePageSections/routeMap/travelPath/utils';
import TravelPathGLSource from './TravelPathGLSource';
import { size } from 'lodash-es';
import TravelPathLegend from 'src/routes/components/pages/routes/routePageSections/routeMap/travelPath/TravelPathLegend';

interface Props {
  mapBearing: number;
}

const TravelPathOriginalGL: FC<Props> = ({ mapBearing }) => {
  const { travelPathDataForBuildOrEdit, showTravelPath } = useSelector(state => state.routes.travelPath);

  const linesGeoJson = useMemo(() => {
    if (!travelPathDataForBuildOrEdit || !showTravelPath) return null;
    return getTravelPathGeoJSON(travelPathDataForBuildOrEdit);
  }, [showTravelPath, travelPathDataForBuildOrEdit]);

  const arrowsGeoJson = useMemo(() => {
    if (!travelPathDataForBuildOrEdit || !showTravelPath) return null;
    return getTravelPathArrowsGeoJSON(travelPathDataForBuildOrEdit, mapBearing);
  }, [mapBearing, showTravelPath, travelPathDataForBuildOrEdit]);

  if (!linesGeoJson) return null;
  if (!linesGeoJson || !arrowsGeoJson) return null;

  return (
    <>
      <TravelPathGLSource uniquePrefix="original_" linesGeoJson={linesGeoJson} arrowsGeoJson={arrowsGeoJson} />;
      {!!size(travelPathDataForBuildOrEdit?.features) && showTravelPath && (
        <TravelPathLegend isEditModal position={'top-right'} />
      )}
    </>
  );
};

export default TravelPathOriginalGL;
