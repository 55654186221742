import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { AppState } from 'src/store';
import { getUIConfig } from 'src/finance/ducks/paymentManagement';
import { useSelector } from 'src/core/hooks/useSelector';
import CreateCardPayment from './CreateCardPayment';

interface Props {
  onCancel: () => void;
  returnToPreviousPage?: () => void;
}

const NewPaymentForm = (props: Props) => {
  const dispatch = useDispatch();

  const { stripeClientSecret: clientSecret, stripeAccountId: stripeAccount } = useSelector(
    (state: AppState) => state.finance.paymentManagement.stripePaymentData,
  );
  const { publishableKey: stripeKey } = useSelector((state: AppState) => state.finance.paymentManagement.uiConfig);

  useEffect(() => {
    dispatch(getUIConfig());
  }, [dispatch]);

  const stripePromise = useMemo(() => {
    if (!stripeKey || !stripeAccount) return null;
    return loadStripe(stripeKey, { stripeAccount });
  }, [stripeKey, stripeAccount]);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
      }}
    >
      <CreateCardPayment {...props} />
    </Elements>
  );
};

export default NewPaymentForm;
