import styled from 'styled-components';

export const NoDataMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
  width: 100%;
  font-size: 18px;
  color: ${props => props.theme.grayBase};
  background-color: ${props => props.theme.grayLight};
  font-weight: 600;
`;
