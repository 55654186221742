import React from 'react';

import {
  Button,
  ButtonSet,
  Container,
  Grid,
  GridColumn,
  ModalTitle,
  ModalSubtitle,
  PanelSection,
  Panel,
  PanelSectionGroup,
} from '../../../core/components/styled';
import { Modal,Table } from '../../../core/components';
import translate from '../../../core/services/translate';
import { useSelector } from 'react-redux';
import { DocumentListTableRow } from 'src/fleet/components/forms';
import { AppState } from 'src/store';
interface Props {
  closeModal: () => void;
}

const RubiconServicesDocumentListModal: React.FC<Props> = ({closeModal}) => {
  const fileDetails = useSelector((state: AppState) => state.customers.rubiconServicesDocuments.documents);
  const isExporting = useSelector((state: AppState) => state.customers.rubiconServicesDocuments.isLoading);
  const documentListTableCells = [{ name: 'fileName', label: translate('autoDispatch.fileName'), width: '100%' }];

  return (
    <Modal size="small">
      <ModalTitle>{translate('autoDispatch.fileDetail')}</ModalTitle>
      <ModalSubtitle />
      <Grid centered>
        <GridColumn size="12/12">
          <Panel>
            <PanelSection vertical centered padding="small" isLoading={isExporting}>
              <Container>
                <Panel margin="no no xLarge">
                  <PanelSectionGroup>
                    <PanelSection vertical>
                      {!!fileDetails && (
                        <Table cells={documentListTableCells} rows={fileDetails} rowComponent={DocumentListTableRow} />
                      )}
                    </PanelSection>
                  </PanelSectionGroup>
                </Panel>
              </Container>
              <Container>
                <ButtonSet margin="medium small small">
                  <Button type="button" color="secondary" onClick={() => closeModal()}>
                    {translate('autoDispatch.close')}
                  </Button>
                </ButtonSet>
              </Container>
            </PanelSection>
          </Panel>
        </GridColumn>
      </Grid>
    </Modal>
  );
};

export default RubiconServicesDocumentListModal;