import { CommunityInsightItem } from 'src/insights/interfaces/communityInsights';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { getIconTypeForCityInsight } from 'src/common/constants/insightIcons';

export const getInsightId = (insight: CommunityInsightItem) => +insight.mapSelectorId.replace('S:', '');

export type CommunityInsightsFeatureProperties = {
  id: number;
  clickable: boolean;
  icon?: string;
};

export const getCommunityInsightsGeoJSON = (insights: CommunityInsightItem[]) =>
  getFeatureCollection<GeoJSON.Point, CommunityInsightsFeatureProperties>(
    insights.map(insight =>
      getPointFeature(getInsightId(insight), [insight.longitude, insight.latitude], {
        id: getInsightId(insight),
        clickable: true,
        icon: getIconTypeForCityInsight(insight)?.id,
      }),
    ),
  );
