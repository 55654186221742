import { http } from 'src/core/services/http';
import translate from 'src/core/services/translate';
import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';

export const uploadCustomersFile = (
  fileData: any,
  vendorId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vendorId }));

  return http
    .post('/customerImport/batchUpload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};

export const loadCustomerImportUploadedFilesStatus = (vendorId: number) =>
  http.get('/customerImport/batchList', { params: { vendorId } }).then(response => response.data);

export const downloadSampleCustomerTemplate = () =>
  downloadExcelFile('/customerImport/downloadTemplate', translate('customers.accountStatusUpload.sampleFile'));
