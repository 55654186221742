import styled, { css } from 'styled-components';
import { sizeMapper } from 'src/utils/styles';

export interface BoxProps {
  margin?: string;
  padding?: string;
  display?: 'block' | 'inline-block' | 'flex' | 'inline-flex';
  flex?: string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  textAlign?: 'left' | 'center' | 'right';
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap';
  flexWrap?: 'wrap' | 'wrap-reverse' | 'revert' | 'no-wrap';
  m?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  p?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  backgroundColor?: string;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  borderRadius?: number | string;
  boxShadow?: string;
  cursor?: 'pointer' | 'default' | 'not-allowed';
  overflow?: 'hidden' | 'visible' | 'scroll' | 'auto';
  textOverflow?: 'clip' | 'ellipsis' | 'initial' | 'inherit' | 'unset';
  textDecoration?:
    | 'dashed'
    | 'dotted'
    | 'double'
    | 'line-through'
    | 'none'
    | 'overline'
    | 'solid'
    | 'underline'
    | 'wavy';
  fontSize?: number | string;
  color?: string;
  withBorderRight?: boolean;
  withBorderLeft?: boolean;
  withBorderBottom?: boolean;
  withBorderTop?: boolean;
  marginLeftAuto?: boolean; // used for align last flex item to the right
  border?: string;
}

export const Box = styled.div<BoxProps>`
  display: inline-block;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  color: ${p => p.color || 'initial'};

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.textDecoration &&
    css`
      text-decoration: ${props.textDecoration};
    `}

  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}

  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}

  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}

  ${props =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `}

  ${props =>
    props.m &&
    css`
      margin: ${props.m}px;
    `}

  ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt}px;
    `}

  ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr}px;
    `}

  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}px;
    `}

  ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml}px;
    `}

  ${props =>
    props.p &&
    css`
      padding: ${props.p}px;
    `}

  ${props =>
    props.pt &&
    css`
      padding-top: ${props.pt}px;
    `}

  ${props =>
    props.pr &&
    css`
      padding-right: ${props.pr}px;
    `}

  ${props =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}px;
    `}

  ${props =>
    props.pl &&
    css`
      padding-left: ${props.pl}px;
    `}

  ${props =>
    props.flexWrap &&
    css`
      flex-wrap: ${props.flexWrap};
    `}

  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}

  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `}

  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  ${props =>
    // eslint-disable-next-line
    typeof props.width !== undefined &&
    css`
      width: ${typeof props.width === 'number' ? `${props.width}px` : props.width};
    `}

  ${props =>
    // eslint-disable-next-line
    typeof props.height !== undefined &&
    css`
      height: ${typeof props.height === 'number' ? `${props.height}px` : props.height};
    `}

  ${props =>
    // eslint-disable-next-line
    typeof props.minWidth !== undefined &&
    css`
      min-width: ${typeof props.minWidth === 'number' ? `${props.minWidth}px` : props.minWidth};
    `}

  ${props =>
    // eslint-disable-next-line
    typeof props.minHeight !== undefined &&
    css`
      min-height: ${typeof props.minHeight === 'number' ? `${props.minHeight}px` : props.minHeight};
    `}

  ${props =>
    // eslint-disable-next-line
    typeof props.maxWidth !== undefined &&
    css`
      max-width: ${typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth};
    `}

  ${props =>
    // eslint-disable-next-line
    typeof props.maxHeight !== undefined &&
    css`
      max-height: ${typeof props.maxHeight === 'number' ? `${props.maxHeight}px` : props.maxHeight};
    `}

  ${props =>
    props.borderRadius &&
    css`
      border-radius: ${typeof props.borderRadius === 'number' ? `${props.borderRadius}px` : props.borderRadius};
    `}

  ${props =>
    props.boxShadow &&
    css`
      box-shadow: ${props.boxShadow};
    `}

  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}

  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}

  ${props =>
    props.textOverflow &&
    css`
      text-overflow: ${props.textOverflow};
    `}

  ${props =>
    props.fontSize &&
    css`
      font-size: ${typeof props.fontSize === 'number' ? `${props.fontSize}px` : props.fontSize};
    `}

    ${props =>
    props.withBorderRight &&
    css`
      border-right: 1px solid ${props => props.theme.grayLight};
    `}

    ${props =>
    props.withBorderLeft &&
    css`
      border-left: 1px solid ${props => props.theme.grayLight};
    `}

    ${props =>
    props.withBorderBottom &&
    css`
      border-bottom: 1px solid ${props => props.theme.grayLight};
    `}

    ${props =>
    props.withBorderTop &&
    css`
      border-top: 1px solid ${props => props.theme.grayLight};
    `}

    ${props =>
      props.marginLeftAuto &&
      css`
        margin-left: auto;
      `}

    ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
`;
