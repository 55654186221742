import React, { PureComponent } from 'react';
import { WrappedFieldProps } from 'redux-form';

import { Switch as SwitchContainer, SwitchBar, SwitchHandle, SwitchText } from './styled';

interface Props extends WrappedFieldProps {
  alignEnd?: boolean;
  color?: string;
  disabled?: boolean;
  id?: string;
  inline?: boolean;
  label?: string;
  labelOnLeft?: boolean;
  labelSize?: string;
  margin?: string;
  onSwitch?: (value: boolean) => void;
  readOnly?: boolean;
  size?: 'small' | 'medium' | 'large';
  whiteSpace?: string;
  width?: string;
}

class Switch extends PureComponent<Props> {
  toggle = () => {
    const { input, readOnly, onSwitch } = this.props;

    if (onSwitch) {
      onSwitch(!input.value);
    }

    if (!readOnly) {
      input.onChange(!input.value);
    }
  };

  render() {
    const {
      alignEnd,
      color,
      disabled,
      id,
      inline,
      input: { value },
      label,
      labelOnLeft,
      labelSize,
      margin,
      size,
      whiteSpace,
      width,
    } = this.props;

    return (
      <SwitchContainer
        id={id}
        isActive={value}
        isDisabled={disabled}
        labelOnLeft={labelOnLeft}
        alignEnd={alignEnd}
        margin={margin}
        size={size}
        width={width}
        inline={inline}
      >
        <SwitchBar size={size} onClick={this.toggle}>
          <SwitchHandle size={size} />
        </SwitchBar>
        {!!label && (
          <SwitchText size={size} color={color} labelSize={labelSize} whiteSpace={whiteSpace}>
            {label}
          </SwitchText>
        )}
      </SwitchContainer>
    );
  }
}

export default Switch;
