import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import {
  createFaultCodeEmail,
  deleteFaultCodeEmail as doDeleteFaultCodeEmail,
  loadFaultCodeEmails as doLoadFaultCodeEmails,
  updateFaultCodeEmail,
} from '../services/faultCodeEmails';

// Actions
const START_LOAD = 'vendors/faultCodeEmails/START_LOAD';
const COMPLETE_LOAD = 'vendors/faultCodeEmails/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/faultCodeEmails/FAIL_LOAD';
const START_SAVE = 'vendors/faultCodeEmails/START_SAVE';
const COMPLETE_SAVE = 'vendors/faultCodeEmails/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/faultCodeEmails/FAIL_SAVE';
const START_DELETE = 'vendors/faultCodeEmails/START_DELETE';
const COMPLETE_DELETE = 'vendors/faultCodeEmails/COMPLETE_DELETE';
const FAIL_DELETE = 'vendors/faultCodeEmails/FAIL_DELETE';
const RESET = 'vendors/faultCodeEmails/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  faultCodeEmails: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          faultCodeEmails: action.faultCodeEmails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE: {
      const { faultCodeEmail } = action;
      const faultCodeEmailIndex = findIndex(state.faultCodeEmails, { id: faultCodeEmail.id });
      if (faultCodeEmailIndex === -1) {
        return update(state, {
          faultCodeEmails: { $push: [faultCodeEmail] },
          $merge: { isSaving: false },
        } as any);
      }
      return update(state, {
        faultCodeEmails: { [faultCodeEmailIndex]: { $set: faultCodeEmail } },
        $merge: { isSaving: false },
      } as any);
    }

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const faultCodeEmailIndex = findIndex(state.faultCodeEmails, { id: action.id });
      return update(state, {
        faultCodeEmails: { $splice: [[faultCodeEmailIndex, 1]] },
        $merge: { isDeleting: false },
      } as any);
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (faultCodeEmails: any) => ({
  type: COMPLETE_LOAD,
  faultCodeEmails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (faultCodeEmail: any) => ({
  type: COMPLETE_SAVE,
  faultCodeEmail,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (id: any) => ({
  type: COMPLETE_DELETE,
  id,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadFaultCodeEmails = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadFaultCodeEmailsPromise = doLoadFaultCodeEmails(vendorId);
  loadFaultCodeEmailsPromise
    .then(faultCodeEmails => dispatch(completeLoad(faultCodeEmails)))
    .catch(() => dispatch(failLoad()));

  return loadFaultCodeEmailsPromise;
};

export const saveFaultCodeEmail = (vendorId: number, faultCodeEmail: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveFaultCodeEmailPromise = faultCodeEmail.id
    ? updateFaultCodeEmail(vendorId, faultCodeEmail)
    : createFaultCodeEmail(vendorId, faultCodeEmail);
  saveFaultCodeEmailPromise
    .then(savedFaultCodeEmail => dispatch(completeSave(savedFaultCodeEmail)))
    .catch(() => dispatch(failSave()));

  return saveFaultCodeEmailPromise;
};

export const deleteFaultCodeEmail = (vendorId: number, faultCodeEmailId: any) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteFaultCodeEmailPromise = doDeleteFaultCodeEmail(vendorId, faultCodeEmailId);
  deleteFaultCodeEmailPromise
    .then(() => dispatch(completeDelete(faultCodeEmailId)))
    .catch(() => dispatch(failDelete()));
  return deleteFaultCodeEmailPromise;
};

export const resetFaultCodeEmails = () => ({
  type: RESET,
});
