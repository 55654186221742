import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import StationaryAlertsModal from './StationaryAlertsModal';

interface Props {
  closeModal: (pristine?: boolean) => void;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  vendorId: number;
}

class StationaryAlertsModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadVehicleTypesForVendor, vendorId } = this.props;
    return loadVehicleTypesForVendor(vendorId);
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Resolver
        successComponent={StationaryAlertsModal}
        successProps={{ closeModal }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
});

const mapDispatchToProps = { loadVehicleTypesForVendor };

export default connect(mapStateToProps, mapDispatchToProps)(StationaryAlertsModalResolver);
