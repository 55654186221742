import mapboxgl, { AnyLayout } from 'mapbox-gl';
import React, { useCallback } from 'react';
import { Layer } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { Coordinates } from 'src/common/components/map/Coordinates';
import { useDraggablePoints } from 'src/common/components/map/hooks/useDraggablePoints';

import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import {
  ROUTE_MAP_CLUSTERS_SOURCE,
  ROUTE_MAP_ROUTE_STOPS_LAYER,
  ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER,
  ROUTE_MAP_VEHICLE_POSITIONS_LAYER,
} from '../constants';
import { ROUTE_STOPS_FIELD_ARRAY_NAME, ROUTE_STOPS_FORM_NAME } from '../../routeStops/RouteStopsForm';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { setMapDragPan, setMapGeoJson } from 'src/routes/ducks/routeMapSettings';
import { useSelector } from 'src/core/hooks/useSelector';

type Props = {
  isEditMode?: boolean;
  map: mapboxgl.Map;
  showOrderNumbers?: boolean;
  zoomLevel?: number;
  isPlaybackMode?: boolean;
};

const formSelector = formValueSelector(ROUTE_STOPS_FORM_NAME);

const EditableStopLayer: React.FC<Props> = props => {
  const { map } = props;

  const dispatch = useDispatch();

  const geoJson = useSelector(s => s.routes.routeMapSettings.mapGeoJson!);

  const fields = useSelector(s => formSelector(s, ROUTE_STOPS_FIELD_ARRAY_NAME)) as RouteStop[];

  const setDragPan = useCallback(
    (dragPan: boolean) => {
      dispatch(setMapDragPan(dragPan));
    },
    [dispatch],
  );

  const setData = useCallback(
    (geoJson: GeoJSON.FeatureCollection<GeoJSON.Point, any>) => {
      dispatch(setMapGeoJson(geoJson));
    },
    [dispatch],
  );

  const updateAddress = useCallback(
    (markerId: number, updatedAddress: Coordinates) => {
      const fieldIndex = fields.findIndex(f => f.id === markerId);
      if (fieldIndex > -1) {
        const field = { ...fields[fieldIndex] };
        field.binLatitude = updatedAddress.lat;
        field.displayLatitude = updatedAddress.lat;
        field.binLongitude = updatedAddress.lng;
        field.displayLongitude = updatedAddress.lng;
        dispatch(
          change(ROUTE_STOPS_FORM_NAME, `${ROUTE_STOPS_FIELD_ARRAY_NAME}[${fieldIndex}]`, {
            ...field,
            isUpdatedLatLng: true,
            isChanged: !field.isNew && !field.isPinned,
          }),
        );
      }
    },
    [dispatch, fields],
  );

  useDraggablePoints(map, geoJson, ROUTE_MAP_CLUSTERS_SOURCE, 'stopsLayer', setDragPan, updateAddress, setData);

  return <StopLayer {...props} />;
};

const StopLayer: React.FC<Props> = ({ showOrderNumbers, zoomLevel, isPlaybackMode }) => {
  let layoutForStops: AnyLayout = {
    'icon-image': ['get', 'icon'],
    'icon-size': 1,
    'icon-allow-overlap': true,
  };
  if (!isPlaybackMode) {
    layoutForStops['symbol-z-order'] = 'source';
    layoutForStops['symbol-sort-key'] = 1;
  }

  return (
    <>
      <Layer
        key="stopsLayer"
        beforeId={isPlaybackMode ? ROUTE_MAP_TIMELINE_VEHICLE_POSITIONS_LAYER : ROUTE_MAP_VEHICLE_POSITIONS_LAYER}
        id="stopsLayer"
        type="symbol"
        source={ROUTE_MAP_CLUSTERS_SOURCE}
        filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], ROUTE_MAP_ROUTE_STOPS_LAYER]]}
        paint={{}}
        layout={layoutForStops}
      />
      {showOrderNumbers && (
        <Layer
          key="stopsLayerOrderNumber"
          id="stopsLayerOrderNumber"
          type="symbol"
          minzoom={zoomLevel || 15}
          source={ROUTE_MAP_CLUSTERS_SOURCE}
          filter={[
            'all',
            ['!', ['has', 'point_count']],
            ['!=', ['get', 'orderNo'], JOB_PENDING_OPTIMIZATION_ID],
            ['==', ['get', 'layer'], ROUTE_MAP_ROUTE_STOPS_LAYER],
          ]}
          paint={{ 'text-halo-color': '#fff', 'text-halo-width': 10 }}
          layout={{
            'symbol-z-order': 'source',
            'icon-image': ['get', 'icon'],
            'icon-size': 1,
            'text-field': showOrderNumbers ? ['get', 'orderNo'] : '',
            'text-offset': [0, 1.25],
            'text-size': 15,
          }}
        />
      )}
    </>
  );
};

export default React.memo(function RouteMapRouteStopsGLLayers(props: Props) {
  if (props.isEditMode) {
    return <EditableStopLayer {...props} />;
  }
  return <StopLayer {...props} />;
});
