import translate from 'src/core/services/translate';

export const DAILY = 'Daily';
export const SAFETY = 'Safety';
export const SERVICE_HISTORY = 'ServiceHistory';
export const ROUTE_SUMMARY = 'RouteSummary';
export const DISPOSAL = 'Disposal';
export const EXCEPTIONS = 'Exceptions';
export const PARTICIPATIONS = 'Participations';
export const ROUTE_EXCEPTIONS = 'RouteException';
export const CUSTOMER = 'Customer';
export const USER_ACTIVITY = 'UserActivity';
export const MAX_REPORT_RECIPIENTS = 10;

export const QUEUED_ID = 1;
export const IN_PROGRESS_ID = 2;
export const COMPLETED_ID = 3;
export const CANCELLED_ID = 4;
export const FAILED_ID = 5;

export const QUEUED = translate('insights.dailyReportTypes.status.queued');
export const FAILED = translate('insights.dailyReportTypes.status.failed');
export const COMPLETED = translate('insights.dailyReportTypes.status.completed');
