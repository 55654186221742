import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import { COMPLEX } from 'src/common/constants/drivingComplexity';
import { PanelSection, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { ROUTE_SEGMENT_LANE_COLORS } from 'src/customers/components/pages/streetNetwork/utils';
import { PassesStatusComplex } from 'src/routes/components/styled';
import { COMPLETED, IN_PROGRESS, SCHEDULED } from 'src/routes/constants';
import { SNOW_SWEEPER_FILTERS_FORM_NAME, SnowSweeperFiltersFormValues } from '../SnowSweeperFiltersForm';
import SnowSweeperFilterItem from './SnowSweeperFilterItem';
import { getNumberOfActiveFilters } from './utils';

interface Props {
  isSnowPlow: boolean;
  isStreetSweeper: boolean;
}

const SegmentStatusFilterSection = ({ isSnowPlow, isStreetSweeper }: Props) => {
  const dispatch = useDispatch();

  const snowPlowSettings = useSelector(state => state.vendors.snowPlowSettings.snowPlowSettings);
  const streetSweepingSettings = useSelector(state => state.vendors.streetSweepingSettings.streetSweepingSettings);

  const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;

  const settingsToUse = useMemo(() => {
    return isSnowPlow ? snowPlowSettings : streetSweepingSettings;
  }, [isSnowPlow, snowPlowSettings, streetSweepingSettings]);

  const { isLoadingSegments } = useSelector(state => state.dashboard.alternativeFleetOps);

  const isDriverExperienceComplex = settingsToUse.drivingComplexityType?.id === COMPLEX;

  const handleCheckAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const pickupStatusTypeIds = {
        all: checked,
        [`_${COMPLETED}`]: checked,
        [`_${IN_PROGRESS}`]: checked,
        [`_${SCHEDULED}`]: checked,
      };
      dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'pickupStatusTypeIds', pickupStatusTypeIds));
    },
    [dispatch],
  );

  const handleCheckAny = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue, prevValue, name) => {
      // uncheck the all checkbox if any of the other checkboxes are unchecked
      if (!newValue) dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'pickupStatusTypeIds.all', false));
      else if (getNumberOfActiveFilters(formValues?.pickupStatusTypeIds) === 2)
        dispatch(change(SNOW_SWEEPER_FILTERS_FORM_NAME, 'pickupStatusTypeIds.all', true));
    },
    [dispatch, formValues?.pickupStatusTypeIds],
  );

  return (
    <Box display="block">
      <Text size="xSmall" fontStyle="italic" block margin="xSmall small" color="grayDarker">
        {translate('dashboard.filterKeys.statusForTodayMessage')}
      </Text>
      <PanelSection isLoading={isLoadingSegments} display="block">
        <SnowSweeperFilterItem
          name="pickupStatusTypeIds.all"
          label={translate('common.all')}
          onChange={handleCheckAll}
        />
        <SnowSweeperFilterItem
          name={`pickupStatusTypeIds._${COMPLETED}`}
          label={translate('common.pickupStatuses.serviced')}
          onChange={handleCheckAny}
          rightContent={<PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.green} />}
        />
        <SnowSweeperFilterItem
          name={`pickupStatusTypeIds._${SCHEDULED}`}
          label={translate('common.pickupStatuses.notServiced')}
          onChange={handleCheckAny}
          rightContent={<PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.blue} />}
        />
        {isDriverExperienceComplex && (
          <SnowSweeperFilterItem
            name={`pickupStatusTypeIds._${IN_PROGRESS}`}
            label={translate('common.pickupStatuses.partiallyServiced')}
            onChange={handleCheckAny}
            rightContent={<PassesStatusComplex forwardColor={ROUTE_SEGMENT_LANE_COLORS.yellow} />}
          />
        )}
      </PanelSection>
    </Box>
  );
};

export default SegmentStatusFilterSection;
