import { hasPermissionSelector } from '../ducks';
import store, { AppState } from '../../store';
import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from '../constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { isAdmin } from '../ducks/login';
import { ROUTE_ROUTE_TEMPLATE_BUILDER } from '../constants/permissions';
import { routeTemplateBuilderIsEnabled } from 'src/vendors/ducks/features';

const selector = hasPermissionSelector as any;

export const hasPermission = (permission: string) => {
  const state = store.getState();

  return Boolean(selector(state.account.permissions, permission));
};

export const checkIfViewOnly = () =>
  !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS) &&
  !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS);

export const checkIfSupport = () =>
  !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS) &&
  hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS);

export const checkIfHasRouteTemplateBuilderAccess = (state: AppState) => {
  const hasAdminPrivileges = isAdmin(state);
  const hasRouteTemplateBuilderPermission = hasPermission(ROUTE_ROUTE_TEMPLATE_BUILDER);
  const routeTemplateBuilderEnabled = routeTemplateBuilderIsEnabled(state);

  return hasAdminPrivileges || (hasRouteTemplateBuilderPermission && routeTemplateBuilderEnabled);
};

export const useHasRouteTemplateBuilderAccess = () => {
  return useSelector(checkIfHasRouteTemplateBuilderAccess);
};
