import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { size } from 'lodash-es';

import translate from '../../../core/services/translate';
import { Button, ButtonSet, Grid, GridColumn, Message } from '../../../core/components/styled';
import { DriverSafetyConfigurations, DriverSafetyConfigurationsFormValues } from '../../interfaces/DriverSafety';
import { Table } from '../../../core/components';
import { DRIVER_SAFETY_FORM } from '../../constants/driverSafety';
import DriverSafetyFormTableRow from './DriverSafetyFormTableRow';

interface PropsWithoutReduxForm {
  initialValues: DriverSafetyConfigurations[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<DriverSafetyConfigurationsFormValues, {}>;

const DriverSafetyForm: React.FC<Props> = ({ initialValues: driverSafetyConfigurations, handleSubmit }) => {
  const driverSafetyConfigurationsTableCells = [
    { name: 'alertTitle', label: translate('vendors.driverSafety.alertTitle'), width: '20%' },
    {
      name: 'alertDescription',
      label: translate('vendors.driverSafety.alertDescription'),
      width: '30%',
    },
    { name: 'isActive', label: translate('common.active'), width: '9%', align: 'center' },
    { name: 'isAudioActive', label: translate('vendors.driverSafety.alertAudio'), width: '13%', align: 'center' },
    { name: 'isVisualActive', label: translate('vendors.driverSafety.alertVisual'), width: '13%', align: 'center' },
    { name: 'dataCapture', label: translate('vendors.driverSafety.dataCapture'), width: '15%' },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Grid centered margin="small no no no">
        <GridColumn size="12/12">
          {!!size(driverSafetyConfigurations) ? (
            <Table
              cells={driverSafetyConfigurationsTableCells}
              rows={driverSafetyConfigurations}
              rowComponent={DriverSafetyFormTableRow}
            />
          ) : (
            <Message padding="sMedium">{translate('vendors.noDriverSafetyConfigurations')}</Message>
          )}
        </GridColumn>
      </Grid>
      <Grid centered>
        <GridColumn size="6/12">
          <ButtonSet margin="medium no">
            <Button color="primary" type="submit">
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </form>
  );
};

export default reduxForm<any, any>({
  form: DRIVER_SAFETY_FORM,
  enableReinitialize: true,
})(DriverSafetyForm);
