import { http } from '../../core/services/http';
import { ExceptionManagementConfigurationRaw } from '../interfaces/ExceptionManagementConfigurations';

export const loadExceptionManagementConfigurations = (vendorId: number, vehicleTypeId: number) =>
  http
    .get<ExceptionManagementConfigurationRaw[]>(`/vendors/${vendorId}/pickupExceptionReasonCodes`, {
      params: { vehicleTypeId },
    })
    .then(response => response.data);

export const saveExceptionManagementConfigurations = (
  vendorId: number,
  vehicleTypeId: number,
  configurations: ExceptionManagementConfigurationRaw[],
) =>
  http.post(`/vendors/${vendorId}/pickupExceptionReasonCodes`, configurations, {
    params: {
      vehicleTypeId,
    },
  });
