import translate from 'src/core/services/translate';
import styled from 'styled-components';
import { IconButtonIcon } from 'src/core/components/styled/Button';
import { Text } from 'src/core/components/styled';
import { ServiceChangeRequest } from '../interfaces/ServiceChangeRequests';
import CHANGE_REQUESTS_TYPE, { EC, FC, MC } from '../constants/changeRequestsType';
import CHANGE_SERVICE_STATUS from '../constants/status';

const MobileListRowContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 10px 0;
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const ContentRowContainer = styled.div`
  width: 95%;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0;
`;

const HeaderInfoContainer = styled(ContentRowContainer)`
  display: flex;
`;

export const ServiceChangeRequestMobileListRow: React.FC<ServiceChangeRequest> = ({
  customerName,
  isActionRequired,
  requestedServiceLevelSummary: { equipmentName, equipmentSize, equipmentSubType, equipmentUnits },
  offer,
  workOrder,
  daysLeft,
  material,
  frequency,
  equipmentChanged,
  frequencyChanged,
  materialChanged,
  portalStatus,
}) => {
  return (
    <MobileListRowContainer>
      <ContentContainer>
        {isActionRequired && !!daysLeft && (
          <Text block weight="normal" margin="no xxSmall" singleLine size="small" color="alert">
            {translate('common.xDaysOverdue', { days: daysLeft })}
          </Text>
        )}
        <HeaderInfoContainer>
          <Text size="small" weight="medium" margin="no xxSmall">
            {CHANGE_SERVICE_STATUS[portalStatus].name}
          </Text>
          <Text margin="no xxSmall" weight="normal" size="small" color="grayDarker">
            {offer && offer.deliveryDate}
            {isActionRequired && <IconButtonIcon icon="bell" color="alert" size="medium" margin="no no no xxSmall" />}
          </Text>
        </HeaderInfoContainer>
        <ContentRowContainer>
          <Text margin="no xxSmall" weight="medium" singleLine size="large">
            {workOrder}
          </Text>
        </ContentRowContainer>
        <ContentRowContainer>
          <Text margin="no xxSmall" weight="medium" singleLine>
            {equipmentSubType
              ? translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentSubType}`)
              : equipmentName}
          </Text>
          {equipmentSize && (
            <Text margin="no xxSmall" weight="light" size="small" singleLine>
              {parseInt(equipmentSize)} {equipmentUnits}
            </Text>
          )}
          <Text margin="no xxSmall" weight="light" singleLine>
            {material}
          </Text>
        </ContentRowContainer>
        <ContentRowContainer>
          {equipmentChanged && (
            <Text size="small" margin="no xxSmall" weight="light" singleLine>
              {CHANGE_REQUESTS_TYPE[EC].name}
            </Text>
          )}
          {frequencyChanged && (
            <Text size="small" margin="no xxSmall" weight="light" singleLine>
              {CHANGE_REQUESTS_TYPE[FC].name}
            </Text>
          )}
          {materialChanged && (
            <Text size="small" margin="no xxSmall" weight="light" singleLine>
              {CHANGE_REQUESTS_TYPE[MC].name}
            </Text>
          )}
        </ContentRowContainer>
        <ContentRowContainer>
          <Text margin="no xxSmall" singleLine weight="light" size="small">
            {customerName}
          </Text>
        </ContentRowContainer>
      </ContentContainer>
    </MobileListRowContainer>
  );
};
