export const TOGGLE_ID = 1;
export const TEXT_FIELD_ID = 2;
export const NUMERIC_TEXT_FIELD_ID = 3;
export const SLIDER_ID = 4;

export const FRONT_STREAM_SETTINGS_ID = 1;
export const HOPPER_STREAM_SETTINGS_ID = 2;
export const GENERAL_SETTINGS_ID = 3;
export const STROBE_SETTINGS_ID = 4;

export const FRONT_STREAM_SETTINGS = 'Front Stream Settings';
export const HOPPER_STREAM_SETTINGS = 'Hopper Stream Settings';
export const GENERAL_SETTINGS = 'General Settings';
export const STROBE_SETTINGS = 'Strobe Settings';
