import { filter, find, forEach, size } from 'lodash-es';
import { useMemo, useState } from 'react';

import { ActionButtonTooltip, Table, UnconnectedSwitch } from 'src/core/components';
import { TableActionButton, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TableCell } from 'src/core/components/Table';
import { TABLE_ROW_HEIGHT_SMALLER } from 'src/core/constants';
import translate from 'src/core/services/translate';
import { RoutePlannerFilterableSectionSearchForm } from 'src/routes/components/forms';
import {
  FilterableTableSectionHeader,
  FilterableTableSectionWrapper,
  FilterableTableSectionTableWrapper,
  FilterableTableSectionSearch,
} from 'src/routes/components/styled';
import {} from 'src/routes/components/styled/RoutePlanner/RoutePlannerDailyAndReoccurring';

type Props = {
  cells?: TableCell[];
  filters?: { label: string; condition: any; onChange?: any }[];
  itemSize?: number;
  rowComponent: any;
  rowComponentProps?: any;
  rows: Array<any>;
  searchFormFilterCondition?: { condition: any; onChange?: any };
  shadowedTable?: boolean;
  onGroupEdit?: any;
  title?: string;
  visibleRows?: number;
};

const FilterableTableSection = ({
  cells,
  filters,
  itemSize = TABLE_ROW_HEIGHT_SMALLER,
  onGroupEdit,
  rowComponent,
  rowComponentProps,
  rows,
  searchFormFilterCondition,
  shadowedTable,
  title,
  visibleRows = 6,
}: Props) => {
  const [appliedFilters, setAppliedFilters] = useState(filters?.map(() => false) || []);
  const [search, setSearch] = useState<string | undefined>();

  const onSearch = (value?: string) => {
    setSearch(value);
    searchFormFilterCondition?.onChange && searchFormFilterCondition.onChange(value);
  };

  const filteredRows = useMemo(() => {
    if ((!filters || !find(appliedFilters, Boolean)) && !search) {
      return rows;
    }
    let filtered = rows;
    if (filters) {
      forEach(appliedFilters, (condition, index) => {
        if (condition) {
          filtered = filter(filtered, filters[index].condition);
        }
      });
    }

    if (search) {
      filtered = filter(filtered, row => {
        return searchFormFilterCondition?.condition(row, search);
      });
    }

    return filtered;
  }, [appliedFilters, filters, rows, search, searchFormFilterCondition]);

  const onFiltersChange = (index: number, value: boolean) => {
    const newAppliedFilters = [...appliedFilters];
    newAppliedFilters[index] = value;
    setAppliedFilters(newAppliedFilters);
  };

  const virtualizedProps = {
    height: Math.min(size(filteredRows) * itemSize, itemSize * visibleRows) || 1,
    itemSize,
    margin: 'small no',
  };

  return (
    <FilterableTableSectionWrapper>
      <FilterableTableSectionHeader>
        <Box display="flex" alignItems="center">
          <Text size="xxLarge">{title}</Text>
          {onGroupEdit && (
            <TableActionButton color="grayDarker" margin="no small" onClick={onGroupEdit}>
              <ActionButtonTooltip iconSize="mMedium" icon="edit" tooltip="editAvailability" />
            </TableActionButton>
          )}
        </Box>
        {filters && (
          <Box display="flex" alignItems="center">
            {filters.map((filter, index) => (
              <UnconnectedSwitch
                key={filter.label}
                checked={appliedFilters[index]}
                label={filter.label}
                onChange={value => {
                  onFiltersChange(index, value);
                  filter.onChange && filter.onChange(value);
                }}
                margin="no small"
              />
            ))}
          </Box>
        )}
      </FilterableTableSectionHeader>
      <FilterableTableSectionSearch>
        <RoutePlannerFilterableSectionSearchForm
          form={`${title}_search`}
          placeholder={`${translate('routes.planner.searchFor')} ${title}`}
          onSearchTermChange={onSearch}
        />
      </FilterableTableSectionSearch>
      <FilterableTableSectionTableWrapper shadowed={shadowedTable}>
        {!!size(filteredRows) ? (
          <Table
            cells={cells || []}
            rowComponent={rowComponent}
            rowProps={rowComponentProps}
            rows={filteredRows}
            virtualized
            virtualizedProps={virtualizedProps}
          />
        ) : (
          <Text block size="medium" color="grayDarker" margin="small no">
            {translate('routes.planner.noDataAvailable', { data: title })}
          </Text>
        )}
      </FilterableTableSectionTableWrapper>
    </FilterableTableSectionWrapper>
  );
};

export default FilterableTableSection;
