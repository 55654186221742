import { reduxForm } from 'redux-form';
import { Option } from 'src/common/interfaces/Option';
import { MultiSelect, PanelSearch, TypedField } from 'src/core/components';
import { FormGroupClear, FormGroupClearContainer, Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { paymentStatusFormatText } from 'src/finance/services/paymentStatusFormatText';

interface FormValues {
  customerId: string;
  locationId: string;
  paymentStatusId: string;
  searchTerm: string;
}

interface PropsWithoutReduxForm {
  customerOptions: Option[];
}
const OpenBillsForm: React.FC<PropsWithoutReduxForm> = ({ customerOptions }) => {
  const billStatuses = useSelector(state => state.finance.billStatuses.billStatuses);
  
  return (
    <form>
      <PanelSection>
        <Grid margin="sMedium no" alignRight>
          <GridColumn size="5/12">
            <FormGroupClearContainer>
              <TypedField
                props={{
                  placeholder: translate('finance.applyChecks.customerLocationSearch'),
                  margin: 'no',
                }}
                name="searchTerm"
                component={PanelSearch}
              />
              {false && <FormGroupClear />}
            </FormGroupClearContainer>
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="paymentStatusId"
              component={MultiSelect}
              props={{
                margin: 'no',
                normalizeValues: Number,
                placeholder: translate('finance.applyChecks.status'),
                options: billStatuses,
                formatText: paymentStatusFormatText,
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'openBillsForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(OpenBillsForm);
