import React, { MouseEvent } from 'react';

import { UnconnectedSwitch, ActionButtonTooltip } from '../../../core/components';
import { TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import { FaultCode } from '../../interfaces/FaultCode';

interface Props extends FaultCode {
  removeFaultCodeEmail(id: number): void;
  openFaultCodeEditorModal(event: MouseEvent, { id, email, isActive }: FaultCode): void;
}

const FaultCodeEmailsModalTableRow: React.FC<Props> = ({
  id,
  email,
  isActive,
  removeFaultCodeEmail,
  openFaultCodeEditorModal,
}) => (
  <TableRow>
    <TableCell width="70%">{email}</TableCell>
    <TableCell width="15%">
      <UnconnectedSwitch checked={isActive} disabled />
    </TableCell>
    <TableCell width="15%" align="right">
      <TableActionButton onClick={event => openFaultCodeEditorModal(event, { id, email, isActive })}>
        <ActionButtonTooltip icon="edit" tooltip="editCode" />
      </TableActionButton>
      <TableActionButton onClick={() => removeFaultCodeEmail(id)}>
        <ActionButtonTooltip icon="delete" tooltip="deleteCode" />
      </TableActionButton>
    </TableCell>
  </TableRow>
);

export default FaultCodeEmailsModalTableRow;
