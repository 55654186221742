import React from 'react';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';

import { Grid, GridColumn, Button, PanelSection } from '../../../core/components/styled';
import { Dropdown } from '../../../core/components';
import { IMPORT_STATUSES } from '../../constants';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import routeImportStatusesFormInitialValuesSelector from '../../services/routeImportStatusesFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';

interface PropsWithoutReduxForm extends RouteComponentProps {
  initialValues: any;
  vendors: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const RouteImportStatusesForm: React.FC<Props> = ({ handleSubmit }) => {
  const importStatusSelectOptions = map(IMPORT_STATUSES, ({ id, name }) => ({
    label: name,
    value: id,
  }));

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection padding="small xSmall" withBorder>
        <Grid>
          <GridColumn size="4/12">
            <Field
              name="haulerRouteImportStatusTypeId"
              component={Dropdown}
              options={importStatusSelectOptions}
              placeholder={translate('common.allStatuses')}
              margin="no"
              isClearable
            />
          </GridColumn>

          <GridColumn size="8/12" align="right">
            <Button type="submit" color="primary">
              {translate('common.search')}
            </Button>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps) => ({
  initialValues: routeImportStatusesFormInitialValuesSelector(ownProps.location.search),
  vendors: state.vendors.vendors.vendors,
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'routeImportStatuses',
      onSubmitFail: focusFirstInvalidField,
    })(RouteImportStatusesForm),
  ),
);
