import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { PanelSection, Button, GridColumn, Grid } from '../../../core/components/styled';
import { ROUTE } from '../../../fleet/constants';
import { VehicleTypeDropdown } from '../../../fleet/components';
import translate from '../../../core/services/translate';
import deviceStatusesFormInitialValuesSelector from '../../services/deviceStatusesFormInitialValuesSelector';
import { AppState } from '../../../store';

interface ComponentProps extends RouteComponentProps {
  onVehicleTypeChange(...args: any[]): any;
  onVendorChange(...args: any[]): any;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const DeviceStatusesForm: React.FC<Props> = ({ handleSubmit, onVendorChange, onVehicleTypeChange }) => (
  <form onSubmit={handleSubmit} noValidate>
    <PanelSection padding="small xSmall" withBorder>
      <Grid>
        <GridColumn size="3/12">
          <Field
            name="vehicleTypeId"
            component={VehicleTypeDropdown}
            placeholder={translate('vehicles.allVehicleTypes')}
            vehicleRoleTypeId={ROUTE}
            onChange={onVehicleTypeChange}
            margin="no"
            dropdownProps={{
              isClearable: true,
            }}
          />
        </GridColumn>
        <GridColumn size="6/12" align="right">
          <Button type="submit" color="primary">
            {translate('common.export')}
          </Button>
        </GridColumn>
      </Grid>
    </PanelSection>
  </form>
);

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  initialValues: deviceStatusesFormInitialValuesSelector(ownProps.history.location.search),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'deviceStatus',
      enableReinitialize: true,
    })(DeviceStatusesForm),
  ),
);
