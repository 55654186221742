import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { useSelector } from 'src/core/hooks/useSelector';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { loadFacilities, saveFacility } from 'src/fleet/ducks';
import facilityEditorFormInitialValues from 'src/fleet/services/faciltyEditorFormInitialValuesSelector';
import store from 'src/store';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import FacilityEditorForm, { FACILITY_EDITOR_FORM_NAME } from '../forms/FacilityEditorForm';
import FacilitiesMapGL from '../pages/facilitiesPageSections/FacilitiesMapGL';

interface Props {
  facilityId?: number;
  closeFacilityEditorModal: () => void;
  onCancel: (formPristine: boolean) => void;
}

const FacilityEditorModal = ({ facilityId, onCancel, closeFacilityEditorModal }: Props) => {
  const facility = useSelector((state: any) => state.fleet.facility.facility);
  const { address, geoFenceJson } = useSelector(getFormValues(FACILITY_EDITOR_FORM_NAME)) || ({} as any);
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const initialValues = useMemo(() => {
    if (facilityId && facility) return facilityEditorFormInitialValues(facility);
    else return facilityEditorFormInitialValues(undefined);
  }, [facilityId, facility]);

  const updateGeoFence = useCallback(
    (geoFenceCoord: any) => {
      dispatch(change(FACILITY_EDITOR_FORM_NAME, 'geoFenceJson', geoFenceCoord));
    },
    [dispatch],
  );

  const onSubmitFacility = async (formData: any) => {
    const data = {
      ...formData,
      vendorId,
      materialsAccepted: formData.materialsAccepted.map((material: any) => {
        if (material.rateType?.id !== null) {
          return material;
        }

        const materialWithNullRateType = {
          ...material,
          rateType: null,
        };

        return materialWithNullRateType;
      }),
    };

    saveFacility(data)(dispatch, store.getState)
      .then(async () => {
        createSuccessNotification(translate('facilities.alertMessages.saveFacility'));
        await loadFacilities(vendorId)(dispatch);
        closeFacilityEditorModal();
      })
      .catch(() => {
        createErrorNotification(translate('facilities.alertMessages.saveFacilityError'));
        closeFacilityEditorModal();
      });
  };

  return (
    <Modal padding="no" isLoading={false} flex size="xLarge">
      <FacilityEditorForm
        facilityId={facilityId}
        initialValues={initialValues}
        onSubmit={onSubmitFacility}
        closeModal={onCancel}
      />
      <ModalSection padding="no" fluid noOutline>
        <FacilitiesMapGL
          isSingleFacility
          address={address}
          geoFenceCoord={geoFenceJson}
          updateGeoFence={updateGeoFence}
        />
      </ModalSection>
    </Modal>
  );
};

export default FacilityEditorModal;
