import { useEffect, useMemo } from "react";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import { useEsriMap } from "./EsriMapContext";
import { getFormValues } from "redux-form";
import { SNOW_SWEEPER_FILTERS_FORM_NAME, SnowSweeperFiltersFormValues } from "src/dashboard/components/forms/SnowSweeperFiltersForm";
import { useSelector } from 'src/core/hooks/useSelector';
import { getDashboardSnowSweeperStreetSegmentsGeoJSON } from "../../dashboardPageSections/mapGL/dashboardSnowSweeperStreetSegments/utils";
import { AppState } from "src/store";

interface Props {
    vehicleTypeId: number;
}

// Define a UniqueValueRenderer to dynamically set the color based on the GeoJSON feature's properties
const lineRenderer = new UniqueValueRenderer({
    field: "color", // The property in the GeoJSON feature to use for the color
    uniqueValueInfos: [
        {
            value: "default",
            symbol: new SimpleLineSymbol({
                color: "#000000", // Default color if no match found
                width: 1,
            }),
        },
    ],
    defaultSymbol: new SimpleLineSymbol({
        color: "#000000",
        width: 1,
    }),
});

// Define a PopupTemplate for the line layer
const linePopupTemplate = {
    title: "Snow",
    content: [
        {
            type: "fields",
            fieldInfos: [
                {
                    fieldName: "hasForwardPass",
                    label: "hasForwardPass",
                },
                {
                    fieldName: "hasReversePass",
                    label: "hasReversePass",
                },
                {
                    fieldName: "status",
                    label: "Status",
                },
                // Add more fields as needed
            ],
        },
    ],
};


export const EsriSnowOps = ({ vehicleTypeId }: Props) => {
    const { map } = useEsriMap();

    const segments = useSelector((state: AppState) => state.dashboard.alternativeFleetOps.filteredSegments);
    const formValues = useSelector(getFormValues(SNOW_SWEEPER_FILTERS_FORM_NAME)) as SnowSweeperFiltersFormValues;

    const geoJson = useMemo(
        () => getDashboardSnowSweeperStreetSegmentsGeoJSON(
            segments,
            vehicleTypeId,
            formValues.isLastActivity,
        ),
        [segments, vehicleTypeId, formValues.isLastActivity]
    );

    // Create blob URLs for the GeoJSON data
    const lineBlob = new Blob([JSON.stringify(geoJson)], { type: "application/json" });
    const lineUrl = URL.createObjectURL(lineBlob);



    useEffect(() => {

        const lineLayer = new GeoJSONLayer({
            url: lineUrl,
            renderer: lineRenderer,
            popupTemplate: linePopupTemplate,
            copyright: "Rubicon",
            listMode: "hide",
        });

        if (!map) return;

        const uniqueColors = Array.from(new Set(geoJson.features.map((feature: any) => feature.properties.color)));

        if (lineRenderer) {
            //@ts-ignore
            lineRenderer.uniqueValueInfos = uniqueColors.map((color: string) => ({
                value: color,
                symbol: new SimpleLineSymbol({
                    color,
                    width: 6,
                }),
            })

            );
        }

        map.add(lineLayer);

        return () => {
            map.remove(lineLayer);
        };
    }, [map, geoJson, lineUrl]);

    return null;
};

export default EsriSnowOps;
