import { mapKeys } from 'lodash-es';
import translate from 'src/core/services/translate';

export const PAYMENT_STATUS_UNAPPLIED_ID = 1;
export const PAYMENT_STATUS_PARTIALLY_APPLIED_ID = 2;
export const PAYMENT_STATUS_VOIDED_ID = 3;
export const PAYMENT_STATUS_APPLIED_ID = 4;

export const PAYMENT_STATUS_OPEN_ID = 1;

export const PAYMENT_STATUS_SUCCEEDED = 'succeeded';
export const PAYMENT_STATUS_PROCESSING = 'processing';

export const APPLY_PAYMENTS_DEFAULT_STATUS = PAYMENT_STATUS_UNAPPLIED_ID;

export const PAYMENT_STATUSES_LIST = [
  { id: PAYMENT_STATUS_UNAPPLIED_ID, name: translate('finance.applyChecks.paymentStatuses.unapplied') },
  {
    id: PAYMENT_STATUS_PARTIALLY_APPLIED_ID,
    name: translate('finance.applyChecks.paymentStatuses.partiallyApplied'),
  },
  { id: PAYMENT_STATUS_VOIDED_ID, name: translate('finance.applyChecks.paymentStatuses.voided') },
  { id: PAYMENT_STATUS_APPLIED_ID, name: translate('finance.applyChecks.paymentStatuses.applied') },
];

export const PAYMENT_STATUSES = mapKeys(PAYMENT_STATUSES_LIST, 'id');
