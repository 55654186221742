import { httpInsightsReporting } from '../../core/services/http';
import transformSearchDashboardVehicles from './transformSearchDashboardVehicles';

const searchDashboardVehicles = (vendorId: number, routeDate: string, searchString: string) =>
  httpInsightsReporting
    .get(`vendors/${vendorId}/dashboardsearch`, {
      params: { routeDate, searchString },
    })
    .then(response => transformSearchDashboardVehicles(response.data));

export default searchDashboardVehicles;
