import { uniqBy } from 'lodash-es';
import { useState } from 'react';

import { CITY_ALERT_TYPES, CITY_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { CommunityInsightsIcon } from 'src/insights/components/styled';
import { ComplexMapControl, MapLegend, MapLegendInfo } from 'src/routes/components/styled/RouteMap';
import { Icon } from 'src/core/components';
import { IconButtonIcon, MapInfoWindowTitleContainer, Separator, Text } from 'src/core/components/styled';
import { LocationAndCityAlertsForMap } from 'src/fleet/interfaces/LocationAndCityAlerts';
import locationAlert from 'src/common/assets/img/cityAlerts/locationAlert.png';
import translate from 'src/core/services/translate';

interface Props {
  isHeatmapVisible: boolean;
  locationAndCityAlertsForMap: LocationAndCityAlertsForMap[];
  toggleHeatMap: () => void;
}

export default function LocationAndCityAlertsMapLegend({
  isHeatmapVisible,
  locationAndCityAlertsForMap,
  toggleHeatMap,
}: Props) {
  const [isLegendCollapsed, setIsLegendCollapsed] = useState(false);

  return (
    <ComplexMapControl vertical position="bottom-right" zIndex={1}>
      <MapLegend collapsed={isLegendCollapsed}>
        <Text size="small" onClick={() => setIsLegendCollapsed(!isLegendCollapsed)}>
          <b>{translate('routes.snowPlow.legend')}</b>
          <Icon icon="chevronDown" />
        </Text>

        {isLegendCollapsed && (
          <MapLegendInfo>
            {uniqBy(locationAndCityAlertsForMap, 'cityAlertTypeId').map((alert, index) => (
              <MapInfoWindowTitleContainer key={index}>
                <CommunityInsightsIcon
                  small
                  src={alert.cityAlertTypeId ? CITY_ALERT_TYPES[alert.cityAlertTypeId].iconUrl : locationAlert}
                />
                <Text size="small" margin="no no small no">
                  {alert.cityAlertTypeId && alert.alertTypeId === CITY_ALERT_TYPE_ID
                    ? CITY_ALERT_TYPES[alert.cityAlertTypeId].name
                    : translate('customers.locationAlerts')}
                </Text>
              </MapInfoWindowTitleContainer>
            ))}
            <Separator margin="xSmall no" />

            <Text cursor="pointer" size="small" color="primary" onClick={toggleHeatMap}>
              <IconButtonIcon icon="map" margin="no" />{' '}
              {isHeatmapVisible ? translate('insights.streetMap') : translate('insights.heatMap')}
            </Text>
          </MapLegendInfo>
        )}
      </MapLegend>
    </ComplexMapControl>
  );
}
