import React, { PureComponent } from 'react';
import { map, indexOf } from 'lodash-es';
import { WEEKDAYS_BY_SHORTCODE } from '../constants';
import { DaysOfWeek, DayOfWeek } from './styled';

interface Props {
  multiple?: boolean;
  isReadOnly?: boolean;
  daysOfWeekProps?: any;
  dayOfWeekProps?: any;
  selectedWeekdays?: string[];
  onClick?: () => void;
}

class UnconnectedDaysOfWeekPicker extends PureComponent<Props> {
  isDayOfWeekSelected = (dayOfWeekShortCode: any) => {
    const { selectedWeekdays, multiple } = this.props;
    return multiple ? indexOf(selectedWeekdays, dayOfWeekShortCode) > -1 : selectedWeekdays === dayOfWeekShortCode;
  };

  render() {
    const { isReadOnly, daysOfWeekProps, dayOfWeekProps, onClick } = this.props;

    const weekdays = map(WEEKDAYS_BY_SHORTCODE, weekday => ({
      ...weekday,
      isSelected: this.isDayOfWeekSelected(weekday.shortCode),
    }));

    return (
      <DaysOfWeek {...daysOfWeekProps} isReadOnly={isReadOnly}>
        {map(weekdays, weekday => (
          <DayOfWeek key={weekday.id} isSelected={weekday.isSelected} onClick={onClick} {...dayOfWeekProps}>
            {weekday.shortCodeName}
          </DayOfWeek>
        ))}
      </DaysOfWeek>
    );
  }
}

export default UnconnectedDaysOfWeekPicker;
