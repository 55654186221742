import React, { useEffect, useMemo, useState } from 'react';

import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { getFormValues } from 'redux-form';

import { AppState } from '../../../store';
import { ACTIVE, INACTIVE } from '../../constants/status';
import {
  assignResources,
  deleteDriver,
  deleteResources,
  filterDrivers,
  loadDrivers,
  resetDrivers,
  searchDrivers,
} from '../../ducks';
import { AVAILABLE, UNAVAILABLE } from 'src/fleet/constants/status';
import { Button, ButtonLink, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
// import { checkSupervisorsHaveRoutesInRange } from 'src/fleet/ducks/driver';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { Driver } from 'src/fleet/interfaces/Driver';
import { FACILITY_NONE_VALUE } from 'src/fleet/constants/drivers';
import { FLEET_DRIVERS_CREATE, FLEET_DRIVERS_DELETE, FLEET_DRIVERS_EDIT } from '../../../account/constants';
import { getIsFacilityActive } from '../utils/vehiclesPageHooks';
import { getQueryParams, createUrl } from 'src/utils/services/queryParams';
import { hasPermissionSelector } from '../../../account/ducks';
import { multiWordAndSearch } from '../../../core/services/search';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { PageFooter } from 'src/common/components/styled';
import { PermissionGuard } from '../../../account/components';
import { supervisorExperienceFeatureIsEnabled } from '../../../vendors/ducks/features';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { Table, UnconnectedCheckbox } from '../../../core/components';
import { TODAY } from 'src/core/constants';
import { uploadResourcesFile, downloadSampleResourceTemplate } from '../../services/resourceImport';
import confirm from 'src/core/services/confirm';
import RecordsUploaderModal from '../../../common/components/RecordsUploaderModal';
import ResourcesAssignForm, { RESOURCES_ASSIGN_FORM } from '../forms/ResourcesAssignForm';
import ResourcesFiltersForm from '../forms/ResourcesFiltersForm';
import ResourcesPageTableRow from './ResourcesPageTableRow';
import translate from '../../../core/services/translate';

interface CheckAllResourcesProps {
  checkAll: () => void;
  getChecked: () => boolean;
  partialChecked: boolean;
}

export const CheckAlResourcesCheckbox: React.FC<CheckAllResourcesProps> = ({
  checkAll,
  getChecked,
  partialChecked,
}) => (
  <UnconnectedCheckbox block checked={getChecked()} onChange={() => checkAll()} size="small" partial={partialChecked} />
);

const useCells = (
  isNotSupportAndIsNotViewOnly: boolean,
  checkAll: () => void,
  allChecked: boolean,
  partialChecked: boolean,
) => {
  const supervisorEnabled = useSelector(supervisorExperienceFeatureIsEnabled);
  const hasDeletePermission = useSelector((state: AppState) =>
    hasPermissionSelector(state.account.permissions, FLEET_DRIVERS_DELETE),
  );
  const hasEditPermission = useSelector((state: AppState) =>
    hasPermissionSelector(state.account.permissions, FLEET_DRIVERS_EDIT),
  );

  return useMemo(
    () => [
      {
        name: 'selectAll',
        component: isNotSupportAndIsNotViewOnly ? CheckAlResourcesCheckbox : undefined,
        componentProps: {
          checkAll: checkAll,
          getChecked: () => allChecked,
          partialChecked: partialChecked,
        },
        width: isNotSupportAndIsNotViewOnly ? '5%' : '0',
        align: 'center',
        noPaddingRight: true,
      },
      {
        name: 'name',
        label: translate('common.name'),
        width: '23%',
        sortable: true,
      },
      {
        name: 'driverLicenseNumber',
        label: translate('drivers.licenseNumber'),
        width: '18%',
        sortable: true,
      },
      {
        name: 'phoneNumber',
        label: translate('common.phone'),
        width: '14%',
        sortable: true,
      },
      {
        name: 'role',
        label: supervisorEnabled && translate('vendors.role'),
        width: '14%',
        sortable: false,
      },
      {
        name: 'isActive',
        label: translate('common.status'),
        width: '10%',
        sortable: true,
      },
      {
        name: 'options',
        label: hasEditPermission && hasDeletePermission && translate('common.options'),
        width: '16%',
        align: 'right',
        noPaddingRight: true,
      },
    ],
    [
      allChecked,
      checkAll,
      hasDeletePermission,
      hasEditPermission,
      isNotSupportAndIsNotViewOnly,
      partialChecked,
      supervisorEnabled,
    ],
  );
};

interface QueryParams {
  operationalFacility?: number;
  roleId?: number;
  searchTerm?: string;
  serviceStatus?: string;
  status?: string;
}

interface Props extends RouteComponentProps {}

const ResourcesPage: React.FC<Props> = ({ location: { pathname, search } }) => {
  const {
    operationalFacility,
    roleId: rawRoleId,
    searchTerm,
    serviceStatus,
    status,
  } = getQueryParams<QueryParams>(search);
  const roleId = typeof rawRoleId === 'undefined' ? undefined : +rawRoleId;
  const dispatch = useDispatch();

  const [isResourceImportModalOpen, setIsResourceImportModalOpen] = useState<boolean>(false);
  const [checkedResources, setCheckedResources] = useState<Driver[]>([]);

  const vendorId = useSelector(currentVendorId);
  const {
    isLoading,
    isDeletingResources,
    isAssigningResources,
    drivers: rawDrivers,
  } = useSelector((state: AppState) => state.fleet.drivers);
  const { driverFacilities, isCheckingSupervisorsHaveRoutesInRange } = useSelector(
    (state: AppState) => state.fleet.driver,
  );

  const formValues = useSelector(getFormValues(RESOURCES_ASSIGN_FORM)) as any;
  const isNewServiceStatusUnavailable = formValues?.serviceStatus === UNAVAILABLE;

  useEffect(
    () => () => {
      dispatch(resetDrivers());
    },
    [dispatch],
  );

  const serviceStatusBoolean = serviceStatus === AVAILABLE;
  const drivers = useMemo(
    () =>
      rawDrivers.filter(
        driver =>
          (!status ||
            (status === ACTIVE && driver.isActiveBoolean) ||
            (status === INACTIVE && !driver.isActiveBoolean)) &&
          (!roleId || driver.resourceRoleType.id === roleId) &&
          (!serviceStatus || driver.isAvailable === serviceStatusBoolean) &&
          (!operationalFacility ||
            (Number(operationalFacility) === -1
              ? driver.locationId === null
              : driver.locationId === Number(operationalFacility))) &&
          (!searchTerm ||
            multiWordAndSearch(`${driver.firstName} ${driver.lastName}`, searchTerm) ||
            multiWordAndSearch(driver.driverLicenseNumber, searchTerm) ||
            multiWordAndSearch(driver.phoneNumber, searchTerm)),
      ),
    [rawDrivers, status, roleId, searchTerm, serviceStatus, serviceStatusBoolean, operationalFacility],
  );

  const checkAllResources = () => {
    if (!!checkedResources.length && checkedResources.length <= drivers.length) {
      setCheckedResources([]);
    } else {
      setCheckedResources(drivers);
    }
  };

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();
  const isNotSupportAndIsNotViewOnly = !isSupport && !isViewOnly;

  const allChecked = checkedResources.length === drivers.length;
  const partialChecked = 0 < checkedResources.length && checkedResources.length < drivers.length;
  const cells = useCells(isNotSupportAndIsNotViewOnly, checkAllResources, allChecked, partialChecked);

  const onFilterDrivers = () => {
    setCheckedResources([]);
    dispatch(filterDrivers());
  };

  const onSearchDrivers = () => {
    setCheckedResources([]);
    dispatch(searchDrivers());
  };

  const debOnSearchTermChange = (newSearchTerm?: string) => {
    dispatch(replace(createUrl(pathname, search, { searchTerm: newSearchTerm })));
    onSearchDrivers();
  };

  const onRoleChange = (newRoleId?: number) => {
    dispatch(replace(createUrl(pathname, search, { roleId: newRoleId })));
    onFilterDrivers();
  };

  const onDriverStatusChange = (newStatus?: string) => {
    dispatch(replace(createUrl(pathname, search, { status: newStatus })));
    onFilterDrivers();
  };

  const onDriverServiceStatusChange = (newServiceStatus?: string) => {
    dispatch(replace(createUrl(pathname, search, { serviceStatus: newServiceStatus })));
    onFilterDrivers();
  };

  const onDriverOperationalFacilityChangeChange = (newOperationalFacility?: number) => {
    dispatch(replace(createUrl(pathname, search, { operationalFacility: newOperationalFacility })));
    onFilterDrivers();
  };

  const openResourceImportModal = () => {
    setIsResourceImportModalOpen(true);
  };

  const closeResourceImportModal = () => {
    setIsResourceImportModalOpen(false);
  };

  const uploadFile = async (fileData: any) => {
    const result = await uploadResourcesFile(fileData, vendorId);

    if (result.data?.successfullyImportedCount > 0) {
      await loadDrivers(vendorId)(dispatch);
    }

    return result;
  };

  const virtualizedProps = {
    height: Math.min(drivers.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_SMALL,
  };

  const checkResource = (event: any, id: number) => {
    let resourceExistsInList = false;

    checkedResources.forEach(resource => {
      if (resource.id === id) {
        resourceExistsInList = true;
      }
    });

    if (resourceExistsInList) {
      const checkedAllResources = checkedResources.filter(driver => driver.id !== id);
      setCheckedResources([...checkedAllResources]);
    } else {
      const checkedResource = drivers.filter(driver => driver.id === id);
      setCheckedResources([...checkedResources, ...(checkedResource || [])]);
    }
  };

  const getResources = () =>
    drivers.map(driver => ({
      checked: checkedResources.some(resource => resource.id === driver.id),
      ...driver,
    }));

  const onDeleteResource = async (resourceId: number) => {
    if (!(await confirm(translate('resources.alertMessages.confirmDeleteResource')))) {
      return;
    }

    deleteDriver(resourceId)(dispatch)
      .then(() => {
        createSuccessNotification(`${translate('resources.alertMessages.resourceDeleted')}`);

        const checkedResourcesAfterDelete = checkedResources.filter(resource => resource.id !== resourceId);
        setCheckedResources(checkedResourcesAfterDelete);
      })
      .catch(() => {
        createErrorNotification(`${translate('resources.alertMessages.resourceDeleteError')}`);
      });
  };

  const onDeleteResources = async () => {
    if (!(await confirm(translate('resources.alertMessages.confirmDeleteResources')))) {
      return;
    }

    const deletedResourceIds = checkedResources.map(resource => resource.id);

    deleteResources(
      vendorId,
      deletedResourceIds,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('drivers.alertMessages.resourcesDeleted'));

        setCheckedResources([]);

        loadDrivers(vendorId)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('drivers.alertMessages.resourcesDeletedError'));
      });
  };

  const onAssignResources = async (
    newStatus?: boolean,
    newServiceStatus?: boolean,
    newFacility?: number,
    resourceUnavailableEndDate?: Date | string,
    resourceUnavailableStartDate?: Date | string,
  ) => {
    const isFacilityActive = getIsFacilityActive(driverFacilities, undefined, newFacility);
    if (!isFacilityActive) {
      if (
        !(await confirm(
          translate(
            `resources.alertMessages.${
              checkedResources.length > 1 ? 'saveDriversWithInactiveFacility' : 'saveDriverWithInactiveFacility'
            }`,
          ),
        ))
      ) {
        return;
      }
    }

    // TODO: uncomment after BE is done (see RVP-5369)

    // const currrentResourcesWithRoutesInRange =
    //   newServiceStatus === false
    //     ? await checkSupervisorsHaveRoutesInRange(
    //         vendorId,
    //         checkedResources.map(checkedResource => checkedResource.id),
    //         resourceUnavailableStartDate || TODAY,
    //         resourceUnavailableEndDate,
    //       )(dispatch)
    //     : [];

    // if (!!currrentResourcesWithRoutesInRange.length) {
    //   const resourceNamesWithRoutesInRange = checkedResources
    //     .filter(checkedResource =>
    //       currrentResourcesWithRoutesInRange.find(
    //         currrentResource => currrentResource.supervisorId === checkedResource.id,
    //       ),
    //     )
    //     .map(checkedResource => checkedResource.name);

    //   if (
    //     !(await confirm(
    //       translate('resources.alertMessages.resourcesHaveRoutesInRange', {
    //         selectedResources: resourceNamesWithRoutesInRange.toString(),
    //       }),
    //       '',
    //       translate('common.cancel'),
    //       translate('common.continue'),
    //     ))
    //   )
    //     return;
    // }

    const assignedResources = checkedResources.map(resource => {
      const isActive = newStatus !== undefined ? newStatus : resource.isActiveBoolean;
      const isAvailable = newServiceStatus !== undefined ? newServiceStatus : resource.isAvailable;
      const driverUnavailable =
        newServiceStatus && !resource.driverUnavailable
          ? null
          : newServiceStatus
          ? {
              id: resource.driverUnavailable?.id,
              startDate: resource.driverUnavailable?.startDate || TODAY,
              endDate: resource.driverUnavailable?.endDate,
              isDeleted: !!resource.driverUnavailable,
            }
          : newServiceStatus === false
          ? {
              id: resource.driverUnavailable?.id,
              startDate: resourceUnavailableStartDate || TODAY,
              endDate: resourceUnavailableEndDate,
              isDeleted: resource.driverUnavailable?.isDeleted,
            }
          : resource.driverUnavailable;

      return {
        ...resource,
        driverUnavailable,
        isActive,
        isAvailable,
        locationId:
          newFacility !== undefined ? (newFacility === FACILITY_NONE_VALUE ? null : newFacility) : resource.locationId,
      };
    });

    assignResources(
      vendorId,
      assignedResources,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('drivers.alertMessages.resourcesAssigned'));

        setCheckedResources([]);

        loadDrivers(vendorId)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('drivers.alertMessages.resourcesAssignedError'));
      });
  };

  return (
    <PageContent
      paddingBottom={isNewServiceStatusUnavailable ? '180px' : !!checkedResources.length ? '100px' : '70px'}
      marginBottom={'0'}
    >
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('resources.resources')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions flex>
          <Button
            color="primary"
            id="resource-import-button"
            line
            margin="no xSmall no no"
            onClick={openResourceImportModal}
          >
            {translate('resources.resourceImport.buttonTitle')}
          </Button>
          <PermissionGuard permission={FLEET_DRIVERS_CREATE}>
            <ButtonLink to="/fleet/resources/create" color="primary" id="create-driver-button">
              {translate('resources.createResource')}
            </ButtonLink>
          </PermissionGuard>
        </PageActions>
      </PageHeader>

      <Panel>
        <PanelSectionGroup
          isLoading={isLoading || isDeletingResources || isAssigningResources || isCheckingSupervisorsHaveRoutesInRange}
        >
          <ResourcesFiltersForm
            initialValues={{
              searchTerm,
              roleId,
              status,
              serviceStatus,
              operationalFacility: Number(operationalFacility),
            }}
            onSearchTermChange={debOnSearchTermChange}
            onRoleChange={onRoleChange}
            onStatusChange={onDriverStatusChange}
            onServiceStatusChange={onDriverServiceStatusChange}
            onOperationalFacilityChange={onDriverOperationalFacilityChangeChange}
          />

          <PanelSection>
            {!!drivers.length && (
              <Table
                cells={cells}
                rowComponent={ResourcesPageTableRow}
                rows={getResources()}
                scrollMarker
                virtualized
                virtualizedProps={virtualizedProps}
                withTopBorder
                rowProps={{
                  onDeleteResource,
                  onCheckChange: checkResource,
                  isNotSupportAndIsNotViewOnly,
                }}
              />
            )}

            {!drivers.length && <Message padding="sMedium">{translate('resources.noResources')}</Message>}
          </PanelSection>
        </PanelSectionGroup>
      </Panel>

      {isResourceImportModalOpen && (
        <RecordsUploaderModal
          downloadSample={downloadSampleResourceTemplate}
          onClose={closeResourceImportModal}
          parentTranslationsPath="resources.resourceImport"
          uploadFile={uploadFile}
          vendorId={vendorId}
        />
      )}

      {!!checkedResources.length && !isDeletingResources && !isAssigningResources && (
        <PageFooter>
          <ResourcesAssignForm
            assignResources={onAssignResources}
            checkedResources={checkedResources}
            deleteResources={onDeleteResources}
            facilities={driverFacilities}
          />
        </PageFooter>
      )}
    </PageContent>
  );
};

export default withRouter(ResourcesPage);
