import React from 'react';

import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { loadRouteLocationImages } from '../../ducks';
import RouteLocationImagesModal from './RouteLocationImagesModal';

interface Props {
  closeModal: () => void;
  imageId?: number;
  imageIds?: number[];
  routeId?: number;
  routeLocationId?: number;
}

const RouteLocationImagesModalResolver: React.FC<Props> = ({ routeId, routeLocationId, ...successProps }) => {
  const dispatch = useDispatch();
  const loadDependencies = () => loadRouteLocationImages(routeId, routeLocationId)(dispatch);

  return (
    <TypedResolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={RouteLocationImagesModal}
      successProps={successProps}
    />
  );
};

export default RouteLocationImagesModalResolver;
