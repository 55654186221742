import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';

import {
  ActionButtonTooltip,
  DragHandle,
  UnconnectedCheckbox,
  UnconnectedDaysOfWeekPicker,
  UnconnectedSelect,
} from '../../../../core/components';
import { ACCOUNT_STATUSES, ROUTE_PICKUP_TYPE_IDS } from '../../../constants';
import { AppState } from '../../../../store';
import { DropdownOption } from '../../../../core/components/Dropdown';
import { getMapBounds } from 'src/common/components/map/util';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { TABLE_ROW_HEIGHT } from '../../../../core/constants';
import { TableActionButton, TableCell, TableRow, Text, Container } from '../../../../core/components/styled';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import RouteSequenceStopDropZone from '../routeSequence/RouteSequenceStopDropZone';
import { setRouteMapSelectedFeature, setRouteMapViewport } from 'src/routes/ducks';

interface Props {
  accountStatusId: number;
  customer: any;
  deleteRouteTemplateLocation: (event: any, orderNumber: number, locationId: number, stopId?: string) => void;
  editService: (event: any, serviceId: number, locationId: number, customerId: number) => void;
  enablePickupType: boolean;
  stopId: string;
  isChecked?: boolean;
  isSearchInitialized: boolean;
  location: any;
  newOptimizedRouteLocationsLength: number;
  onRouteLocationDrop?: () => void;
  onRoutePickupTypeChange: (value: any, serviceContractRouteTemplateId: number, stopId?: string) => void;
  openOrderNumberPopover: (orderNumber: number, event: any, serviceContractRouteTemplateId: number) => void;
  orderNumber: number;
  pickupTypeId: number;
  pickupTypes: DropdownOption[];
  scheduledDays: string[];
  selectLocation: (routeTemplateLocation: any, event: any) => void;
  selectRouteTemplateLocation: (serviceContractRouteTemplateId: number, orderNumber: number, stopId?: string) => void;
  service: any;
  serviceContractRouteTemplateId: number;
}

const RouteLocationsTableRow: React.SFC<Props> = ({
  accountStatusId,
  customer,
  deleteRouteTemplateLocation,
  editService,
  enablePickupType,
  stopId,
  isChecked,
  isSearchInitialized,
  location: {
    address: { street, streetNumber, latitude, longitude },
    id: locationId,
    name: locationName,
  },
  newOptimizedRouteLocationsLength,
  onRouteLocationDrop,
  onRoutePickupTypeChange,
  openOrderNumberPopover,
  orderNumber,
  pickupTypeId,
  pickupTypes,
  scheduledDays,
  selectLocation,
  selectRouteTemplateLocation,
  service,
  serviceContractRouteTemplateId,
}) => {
  const dispatch = useDispatch();
  const routeTemplateLocation = { latitude, longitude, orderNumber };
  const jobIsPendingOptimization = orderNumber === JOB_PENDING_OPTIMIZATION_ID;

  const centerMapZoomToLocation = (event: any, latitude: number, longitude: number) => {
    const isTableRowClicked = event ? event.target.parentNode.tagName === 'DIV' : false;

    if (isTableRowClicked) {
      const bounds = getMapBounds([{ latitude, longitude }]);

      dispatch(setRouteMapViewport(bounds));
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.routeStops, serviceContractRouteTemplateId));
    }
  };
  return (
    <TableRow
      height={TABLE_ROW_HEIGHT}
      onClick={event => {
        selectLocation(routeTemplateLocation, event);
        centerMapZoomToLocation(event, latitude, longitude);
      }}
      wrap={'wrap'}
      position={'relative'}
      id={`stop-${locationId}`}
    >
      {onRouteLocationDrop && jobIsPendingOptimization && (
        <RouteSequenceStopDropZone
          position="top"
          routeLocationIndex={orderNumber - 1}
          onRouteLocationDrop={onRouteLocationDrop}
        />
      )}

      <TableCell width="5%">
        {!newOptimizedRouteLocationsLength && (
          <UnconnectedCheckbox
            block
            checked={isChecked || false}
            onChange={() => selectRouteTemplateLocation(serviceContractRouteTemplateId, orderNumber, stopId)}
            id={`stop-${locationId}-checkbox`}
            size="small"
          />
        )}
      </TableCell>

      <TableCell width="5%">
        {!isSearchInitialized && !jobIsPendingOptimization && !newOptimizedRouteLocationsLength && (
          <DragHandle id={`stop-${locationId}-drag-handle`} />
        )}
      </TableCell>

      <TableCell width="7%">
        {!jobIsPendingOptimization && (
          <TableActionButton
            onClick={event =>
              !newOptimizedRouteLocationsLength
                ? openOrderNumberPopover(orderNumber, event, serviceContractRouteTemplateId)
                : undefined
            }
            id={`stop-${locationId}-reorder-button`}
          >
            {orderNumber}
          </TableActionButton>
        )}
      </TableCell>

      <TableCell width={enablePickupType ? '17%' : '20%'} vertical>
        <Text block weight="medium" margin="no no xxSmall" singleLine id={`stop-${locationId}-customer`}>
          {customer.name}
        </Text>

        <Text weight="light" size="small" margin="no no xxSmall" singleLine id={`stop-${locationId}-location`}>
          {locationName}, {streetNumber} {street}
        </Text>
      </TableCell>

      <TableCell width={enablePickupType ? '9%' : '13%'} id={`stop-${locationId}-account-status`}>
        {(!!accountStatusId && ACCOUNT_STATUSES[accountStatusId].name) || '-'}
      </TableCell>

      <TableCell width={enablePickupType ? '17%' : '20%'}>
        <Text block singleLine title={service && service.name} id={`stop-${locationId}-service`}>
          {service && service.name}
        </Text>
      </TableCell>

      <TableCell width={enablePickupType ? '17%' : '20%'} id={`stop-${locationId}-day-of-service`}>
        {
          <UnconnectedDaysOfWeekPicker
            dayOfWeekProps={{ margin: "no xSmall no no'" }}
            daysOfWeekProps={{ margin: 'no' }}
            isReadOnly
            multiple
            selectedWeekdays={scheduledDays}
          />
        }
      </TableCell>

      {enablePickupType && (
        <TableCell width={'13%'}>
          <Container>
            <UnconnectedSelect
              value={pickupTypeId || ''}
              options={pickupTypes}
              margin="no"
              onClick={(event: Event) => event.stopPropagation()}
              onChange={(value: any) => onRoutePickupTypeChange(value, serviceContractRouteTemplateId, stopId)}
              disabled={newOptimizedRouteLocationsLength > 0}
            />
          </Container>
        </TableCell>
      )}

      <TableCell width="10%">
        {(!newOptimizedRouteLocationsLength || !serviceContractRouteTemplateId) && (
          <TableActionButton
            id={`stop-${locationId}-delete-button`}
            onClick={event => deleteRouteTemplateLocation(event, orderNumber, locationId, stopId)}
          >
            <ActionButtonTooltip icon="delete" tooltip="deleteLocation" />
          </TableActionButton>
        )}

        {!newOptimizedRouteLocationsLength && (
          <TableActionButton id={`stop-${locationId}-edt-service-button`}>
            <Link
              id="edit-service-link"
              color="grayDark"
              to={`/customers/customers/${customer.id}/location/${locationId}/service/${service.id}`}
            >
              <ActionButtonTooltip icon="edit" tooltip="editService" />
            </Link>
          </TableActionButton>
        )}
      </TableCell>

      {onRouteLocationDrop && (
        <RouteSequenceStopDropZone
          position="bottom"
          routeLocationIndex={orderNumber}
          onRouteLocationDrop={onRouteLocationDrop}
        />
      )}
    </TableRow>
  );
};

const mapStateToProps = (state: AppState) => {
  const statePickupTypes = state.routes.pickupTypes.pickupTypes as any;
  let pickupTypes = [];
  if (statePickupTypes)
    pickupTypes = statePickupTypes[0].pickupTypes.map((pickupType: any) => ({
      label: ROUTE_PICKUP_TYPE_IDS[pickupType.id].name,
      value: pickupType.id,
    }));
  return {
    pickupTypes: pickupTypes,
  };
};

export default connect(mapStateToProps)(SortableElement(RouteLocationsTableRow));

export const UnsortableRouteLocationsTableRow = connect(mapStateToProps)(RouteLocationsTableRow);
