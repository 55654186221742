import update from 'immutability-helper';
import { find, findIndex, identity } from 'lodash-es';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { TODAY_FORMATTED } from 'src/core/constants';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';
import {
  LoadRoutesParams,
  deleteRoutes as doDeleteRoutes,
  loadRoutes as doLoadRoutes,
} from 'src/routes/services/dispatchBoardRoutes';

// Actions
const START_LOAD = 'dispatchBoard/sourceRoutes/START_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/sourceRoutes/COMPLETE_LOAD';
const FAIL_LOAD = 'dispatchBoard/sourceRoutes/FAIL_LOAD';
const ADD_ROUTE = 'dispatchBoard/sourceRoutes/ADD_ROUTE';
const UPDATE_ROUTE = 'dispatchBoard/sourceRoutes/UPDATE_ROUTE';
const START_DELETE_ROUTE = 'dispatchBoard/sourceRoutes/START_DELETE_ROUTE';
const COMPLETE_DELETE_ROUTE = 'dispatchBoard/sourceRoutes/COMPLETE_DELETE_ROUTE';
const FAIL_DELETE_ROUTE = 'dispatchBoard/sourceRoutes/FAIL_DELETE_ROUTE';
const REMOVE_ROUTE = 'dispatchBoard/sourceRoutes/REMOVE_ROUTE';
const RESET = 'dispatchBoard/sourceRoutes/RESET';
const SET_SOURCE_DATE = 'dispatchBoard/sourceRoutes/SET_SOURCE_DATE';
const SET_IMAGE_MODAL_VISIBLE_FOR_JOB = 'dispatchBoard/sourceRoutes/SET_IMAGE_MODAL_VISIBLE_FOR_JOB';

interface State {
  isLoading: boolean;
  isDeletingRoute: boolean;
  sourceRoutes: DispatchBoardSimpleRoute[];
  selectedDate: Date | string;
  imagesForSelectedJob?: any[];
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isDeletingRoute: false,
  sourceRoutes: [],
  selectedDate: TODAY_FORMATTED,
  imagesForSelectedJob: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          sourceRoutes: action.sourceRoutes.sort((a: DispatchBoardSimpleRoute, b: DispatchBoardSimpleRoute) =>
            a.name > b.name ? 1 : -1,
          ),
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          sourceRoutes: [],
        },
      });

    case ADD_ROUTE:
      return update(state, {
        sourceRoutes: { $push: action.route },
      });

    case UPDATE_ROUTE: {
      const index = findIndex(state.sourceRoutes, { id: action.route.id });
      if (index === -1) return state;

      return update(state, {
        sourceRoutes: {
          [index]: { $set: action.route },
        },
      });
    }

    case START_DELETE_ROUTE:
      return update(state, {
        $merge: { isDeletingRoute: true },
      });

    case COMPLETE_DELETE_ROUTE: {
      const index = findIndex(state.sourceRoutes, { id: action.routeId });
      if (index === -1) return state;

      return update(state, { isDeletingRoute: { $set: false }, sourceRoutes: { $splice: [[index, 1]] } });
    }

    case FAIL_DELETE_ROUTE:
      return update(state, {
        $merge: { isDeletingRoute: false },
      });

    case REMOVE_ROUTE: {
      const index = findIndex(state.sourceRoutes, { id: action.routeId });
      if (index === -1) return state;

      return update(state, { sourceRoutes: { $splice: [[index, 1]] } });
    }

    case RESET:
      return update(state, { $merge: initialState });

    case SET_SOURCE_DATE: {
      return update(state, {
        $merge: { selectedDate: action.date },
      });
    }

    case SET_IMAGE_MODAL_VISIBLE_FOR_JOB:
      return update(state, {
        $merge: {
          imagesForSelectedJob: action.images,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (sourceRoutes: DispatchBoardSimpleRoute[]) => ({
  type: COMPLETE_LOAD,
  sourceRoutes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDeleteRoute = () => ({
  type: START_DELETE_ROUTE,
});

const completeDeleteRoute = (routeId: number) => ({
  type: COMPLETE_DELETE_ROUTE,
  routeId,
});

const failDeleteRoute = () => ({
  type: FAIL_DELETE_ROUTE,
});

export const removeDispatchBoardSourceRoute = (routeId: number) => ({
  type: REMOVE_ROUTE,
  routeId,
});

export const loadDispatchBoardSourceRoutes = (params: LoadRoutesParams) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const promise = doLoadRoutes(params);
  promise.then(routes => dispatch(completeLoad(routes))).catch(() => dispatch(failLoad()));
  return promise;
};

export const addDispatchBoardSourceRoute = (route: any) => ({
  type: ADD_ROUTE,
  route,
});

export const updateDispatchBoardSourceRoute = (route: any) => ({
  type: UPDATE_ROUTE,
  route,
});

export const deleteDispatchBoardSourceRoute = (routeId: number) => (dispatch: Dispatch) => {
  dispatch(startDeleteRoute());
  const promise = doDeleteRoutes([routeId]);
  promise.then(() => dispatch(completeDeleteRoute(routeId))).catch(() => dispatch(failDeleteRoute()));
  return promise;
};

export const resetDispatchBoardSourceRoutes = () => ({
  type: RESET,
});

// Selector
const getDispatchBoardSourceRouteById = (dispatchBoardSourceRoutesState: State, routeId: number) =>
  find(dispatchBoardSourceRoutesState.sourceRoutes, ({ id }) => id === routeId);

export const dispatchBoardSourceRouteByIdSelector = createSelector(getDispatchBoardSourceRouteById, identity);

export const setSourceDate = (date: Date | string) => ({
  type: SET_SOURCE_DATE,
  date,
});

const setImagesFromSelectedJob = (images?: any[]) => ({
  type: SET_IMAGE_MODAL_VISIBLE_FOR_JOB,
  images,
});

export const showImagesForJob = (jobId: number) => (dispatch: Dispatch, getState: () => any) => {
  const state = getState();
  const job = state.routes.dispatchBoard.unassignedJobs.unassignedJobs.find((job: any) => job.id === jobId);

  const images = job
    ? [
        {
          imageUrl: job.imageUrl,
          locationName: job.locationAddress.formattedAddress,
          routeName: job.locationName,
          timeStamp: job.imageTimeStamp || job.date,
          issueName: job.jobTypeName,
          jobSourceTypeId: job.jobSourceType.id,
        },
      ]
    : undefined;

  dispatch(setImagesFromSelectedJob(images));
};

export const hideImages = () => (dispatch: Dispatch) => dispatch(setImagesFromSelectedJob(undefined));
