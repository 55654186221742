import { PureComponent, Fragment, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  getFormValues,
  InjectedFormProps,
  isValid,
  reduxForm,
  submit,
} from 'redux-form';
import { map, orderBy, uniqBy } from 'lodash-es';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';

import { AppState } from '../../../store';
import {
  ActionButtonTooltip,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  NavigationPrompt,
  TypedField,
} from '../../../core/components';
import {
  VisionConfigurationEditorModal,
} from '../modal';
import { aiModelTypesSelector, saveVehicle } from '../../ducks';
import { AVAILABLE, SERVICE_STATUSES } from 'src/fleet/constants/status';
import { BATCH_SIDE_LOAD_ID, RESIDENTIAL_ID, SIDELOAD_ID, WASTE_AUDIT } from '../../constants';
import { BinColorsMultiSelect, VehicleSubTypeDropdown, VehicleTypeForVendorDropdown } from '..';
import { BOTH, RIGHT } from '../../../vendors/constants';
import { Button, ButtonSet, Grid, GridColumn } from '../../../core/components/styled';
import { CameraConfigurations } from 'src/routes/components/pages/routes/routePageSections/routeMap/vehicleTrackings/VideoRequestForm';
import { ConfirmationModeDropdown } from '../../components';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DeviceRoleTypeCheckboxes, ServicingSideDropdown } from '../../../vendors/components';
import { DriverSafetyModalResolver } from 'src/vendors/components/modals';
import { DuckFunction } from '../../../contracts/ducks';
import { Facility } from 'src/common/interfaces/Facility';
import { getIsFacilityActive } from '../utils/vehiclesPageHooks';
import { getQueryParams } from 'src/utils/services/queryParams';
import { getVehicleUnavailable } from '../pages/VehicleEditorPage';
import { isRequired, maxLength100, maxLength50 } from '../../../utils/services/validator';
import { isSuperAdminSelector, isAdminSelector } from 'src/account/ducks';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { VehicleUnavailable } from 'src/fleet/interfaces/Vehicle';
import { visionConfigurationStatusSelector } from 'src/vendors/ducks';
import confirm from '../../../core/services/confirm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import VehicleCameraConfigurations from '../VehicleCameraConfigurations';
import vehicleEditorFormInitialValuesSelector from '../../services/vehicleEditorFormInitialValuesSelector';

const areCameraConfigsValid = (value: any[]) => {
  return value &&
    value.some(v => !v.name || !v.displayName || !v.cameraSerialNumber || !v.vehicleCameraConfigurationTypeId)
    ? translate('vehicles.cameraConfigurations.validationError')
    : isDuplicateCameraConfigurations(value)
    ? translate('vehicles.cameraConfigurations.duplicateValidationError')
    : undefined;
};

const isDuplicateCameraConfigurations = (cameraConfigurations: CameraConfigurations[]) => {
  const uniqueCameraConfigs = uniqBy(
    cameraConfigurations,
    (obj: CameraConfigurations) => obj.vehicleCameraConfigurationTypeId,
  );

  if(Array.isArray(cameraConfigurations) && Array.isArray(uniqueCameraConfigs)){
    return cameraConfigurations?.length !== uniqueCameraConfigs?.length;
  }

};
interface ComponentProps extends RouteComponentProps {
  isCreate?: boolean;
  vehicleId?: number;
}

interface PropsWithoutReduxForm extends ComponentProps {
  aiModelTypes?: any[];
  change: any;
  deviceId: string;
  formValues: any;
  hasCustomVisionConfigurationSettings: boolean;
  isAdmin: boolean;
  isAvailable: boolean;
  isFormValid: boolean;
  isSuperAdmin: boolean;
  location: any;
  push: any;
  rubiconYDeviceManufacturerList: TechnicalType[];
  rubiconZDeviceId?: string;
  saveVehicle: DuckFunction<typeof saveVehicle>;
  submit: any;
  surfSightDeviceNumber?: string;
  vehicleFacilities: Facility[];
  vehicleTypeId?: number;
  vehicleUnavailableEndDate?: Date | string;
  vehicleUnavailableInitialValues?: VehicleUnavailable;
  vehicleUnavailableStartDate?: Date | string;
  visionConfigurationEnabled: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

interface State {
  currentVehicleId?: number;
  hasCustomVisionConfigurationSettings: boolean;
  isAvailableStatusSelected: boolean;
  isNewVehicleSaved: boolean;
  isSurfsightConfigurationModalOpen: boolean;
  isVisionConfigurationModalOpen: boolean;
}

class VehicleEditorForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentVehicleId: props.vehicleId,
      hasCustomVisionConfigurationSettings: props.hasCustomVisionConfigurationSettings,
      isAvailableStatusSelected: false,
      isNewVehicleSaved: false,
      isSurfsightConfigurationModalOpen: false,
      isVisionConfigurationModalOpen: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    const params = getQueryParams(location.search);

    if (location.pathname !== prevProps.location.pathname) {
      !!params.isVisionConfigurationModalOpen && this.openEditVisionConfigurationModal();
      !!params.isSurfsightConfigurationModalOpen && this.openSurfsightConfigurationModal();
    }
  }

  onVehicleTypeChange = () => {
    const { change } = this.props;
    change('vehicleSubTypeId', null);
    change('streetServicingSideId', BOTH);
  };

  onDeviceTypeChange = (value: any) => {
    const { change } = this.props;
    change('deviceRoleTypeId', value);
  };

  onVehicleSubTypeChange = (event: any) => {
    const { change } = this.props;
    change('streetServicingSideId', event === BATCH_SIDE_LOAD_ID || event === SIDELOAD_ID ? RIGHT : BOTH);
  };

  changeServiceDate = (date: string, initialDate?: Date | string, isAvailableStatusSelected?: boolean) => {
    const { change } = this.props;

    change(date, isAvailableStatusSelected ? null : initialDate ? moment(initialDate).format('MM/DD/YYYY') : null);

    this.setState({
      isAvailableStatusSelected: isAvailableStatusSelected || false,
    });
  };

  onServiceStatusChange = (event: any) => {
    const { vehicleUnavailableInitialValues } = this.props;
    const isAvailableStatusSelected = event === AVAILABLE;

    this.changeServiceDate(
      'vehicleUnavailableStartDate',
      vehicleUnavailableInitialValues?.startDate,
      isAvailableStatusSelected,
    );
    this.changeServiceDate(
      'vehicleUnavailableEndDate',
      vehicleUnavailableInitialValues?.endDate,
      isAvailableStatusSelected,
    );
  };


  openEditVisionConfiguration = async () => {
    const { pristine, aiModelTypes, saveVehicle, formValues, isFormValid, submit, vehicleFacilities, push } =
      this.props;
    if (!pristine) {
      if (!(await confirm(translate('vehicles.alertMessages.saveVehicleBeforeEditingVisionConfiguration')))) {
        return;
      }

      const isFacilityActive = getIsFacilityActive(vehicleFacilities, formValues);
      if (!isFacilityActive) {
        if (!(await confirm(translate('vehicles.alertMessages.saveVehicleWithInactiveFacility')))) {
          return;
        }
      }

      const isAvailable = formValues.isAvailable === AVAILABLE;
      const vehicleUnavailable = getVehicleUnavailable(formValues, isAvailable);

      const formData = {
        ...formValues,
        vehicleUnavailable,
        isAvailable,
        aiModelTypes,
      };

      if (!isFormValid) {
        submit('vehicleEditorForm');
      } else {
        saveVehicle({ ...formData })
          .then(response => {
            createSuccessNotification(`${translate('vehicles.alertMessages.vehicleSaved')}`);
            const isNewVehicleSaved = response.config.method === 'post';

            if (isNewVehicleSaved) {
              push(`/fleet/vehicles/${response.data.id}/edit?isVisionConfigurationModalOpen=true`);
            } else {
              this.openEditVisionConfigurationModal();
            }

            this.setState({
              isNewVehicleSaved: isNewVehicleSaved,
            });
          })
          .catch(({ code }) => {
            createErrorNotification(`${translate(createTranslationKey(code, 'vehicles.alertMessages'))}`);
          });
      }
    } else {
      this.openEditVisionConfigurationModal();
    }
  };

  openSurfsightConfiguration = async () => {
    const { pristine, aiModelTypes, saveVehicle, formValues, isFormValid, submit, vehicleFacilities, push } =
      this.props;
    if (!pristine) {
      if (!(await confirm(translate('vehicles.alertMessages.saveVehicleBeforeEditingSurfsightConfiguration')))) {
        return;
      }

      const isFacilityActive = getIsFacilityActive(vehicleFacilities, formValues);
      if (!isFacilityActive) {
        if (!(await confirm(translate('vehicles.alertMessages.saveVehicleWithInactiveFacility')))) {
          return;
        }
      }

      const isAvailable = formValues.isAvailable === AVAILABLE;
      const vehicleUnavailable = getVehicleUnavailable(formValues, isAvailable);

      const formData = {
        ...formValues,
        vehicleUnavailable,
        isAvailable,
        aiModelTypes,
      };

      if (!isFormValid) {
        submit('vehicleEditorForm');
      } else {
        saveVehicle({ ...formData })
          .then(response => {
            createSuccessNotification(`${translate('vehicles.alertMessages.vehicleSaved')}`);
            const isNewVehicleSaved = response.config.method === 'post';

            this.setState(
              {
                currentVehicleId: response.data.id,
              },
              () => {
                if (isNewVehicleSaved) {
                  push(`/fleet/vehicles/${response.data.id}/edit?isSurfsightConfigurationModalOpen=true`);
                } else {
                  this.openSurfsightConfigurationModal();
                }

                this.setState({
                  isNewVehicleSaved: isNewVehicleSaved,
                });
              },
            );
          })
          .catch(({ code }) => {
            createErrorNotification(`${translate(createTranslationKey(code, 'vehicles.alertMessages'))}`);
          });
      }
    } else {
      this.openSurfsightConfigurationModal();
    }
  };

  openEditVisionConfigurationModal = () =>
    this.setState({
      isVisionConfigurationModalOpen: true,
    });

  openSurfsightConfigurationModal = () => {
    this.setState({
      isSurfsightConfigurationModalOpen: true,
    });
  };

  closeEditVisionConfigurationModel = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({
      isVisionConfigurationModalOpen: false,
    });
  };

  closeSurfsightConfigurationModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({
      isSurfsightConfigurationModalOpen: false,
    });
  };

  changeCustomVisionConfigurationDefaultValue = (value: boolean) => {
    this.setState({
      hasCustomVisionConfigurationSettings: value,
    });
  };

  render() {
    const {
      deviceId,
      handleSubmit,
      isAdmin,
      isAvailable,
      isSuperAdmin,
      pristine,
      rubiconYDeviceManufacturerList,
      rubiconZDeviceId,
      submitSucceeded,
      surfSightDeviceNumber,
      vehicleFacilities,
      vehicleTypeId,
      vehicleUnavailableEndDate,
      vehicleUnavailableInitialValues,
      vehicleUnavailableStartDate,
      visionConfigurationEnabled,
    } = this.props;

    const {
      currentVehicleId,
      hasCustomVisionConfigurationSettings,
      isAvailableStatusSelected,
      isNewVehicleSaved,
      isSurfsightConfigurationModalOpen,
      isVisionConfigurationModalOpen,
    } = this.state;

    const deviceManufacturers = rubiconYDeviceManufacturerList.map(deviceManufacturer => ({
      label: deviceManufacturer.name,
      value: deviceManufacturer.id,
    }));

    const vehicleFacilitiesOptions = map(vehicleFacilities, ({ locationId, name, isActive }: Facility) => ({
      label: name,
      value: locationId,
      isActive: isActive,
    }));
    const vehicleFacilitiesOptionsOrdered = orderBy(vehicleFacilitiesOptions, 'isActive', 'desc');

    return (
      <Fragment>
        <NavigationPrompt when={!pristine && !submitSucceeded && isNewVehicleSaved} />
        <form onSubmit={handleSubmit} noValidate>
          <Grid
            multiLine
            verticalAlign={!isAdmin ? 'center' : undefined}
            column={!isAdmin || false}
            centered={!isAdmin || false}
          >
            <GridColumn size="6/12" padding="no medium no no">
              <Field
                name="regplate"
                component={Input}
                label={translate('vehicles.vehicleName')}
                validate={[isRequired, maxLength50]}
              />

              <Field
                name="vehicleTypeId"
                component={VehicleTypeForVendorDropdown}
                label={translate('vehicles.vehicleType')}
                excludeVehicleTypes={[WASTE_AUDIT]}
                validate={[isRequired]}
                onChange={this.onVehicleTypeChange}
                dropdownProps={{
                  isClearable: true,
                }}
              />

              <Field
                name="vehicleSubTypeId"
                component={VehicleSubTypeDropdown}
                vehicleTypeId={vehicleTypeId}
                label={translate('vehicles.vehicleSubType')}
                validate={[isRequired]}
                onChange={this.onVehicleSubTypeChange}
                dropdownProps={{
                  isClearable: true,
                }}
              />

              <Field name="isActive" component={Checkbox} label={translate('common.active')} />

              <Field
                name="isAvailable"
                component={Dropdown}
                label={translate('common.serviceStatus')}
                options={SERVICE_STATUSES}
                validate={[isRequired]}
                margin="sMedium no"
                onChange={this.onServiceStatusChange}
              />

              {(!isAvailable || !!vehicleUnavailableInitialValues) && !isAvailableStatusSelected && (
                <Grid multiLine margin="no no sMedium no">
                  <Field margin="no" name="vehicleUnavailableInitialValues" component={Input} type="hidden" />
                  <GridColumn size="6/12" padding="no small no no">
                    <Field
                      name="vehicleUnavailableStartDate"
                      component={DatePicker}
                      disabledDays={[
                        {
                          before: moment().toDate(),
                          after: vehicleUnavailableEndDate
                            ? moment(vehicleUnavailableEndDate).subtract(1, 'days').toDate()
                            : undefined,
                        },
                      ]}
                      margin="no"
                      label={translate('common.unavailableFrom')}
                      isClearable
                    />
                  </GridColumn>
                  <GridColumn size="6/12" padding="no no no small">
                    <Field
                      name="vehicleUnavailableEndDate"
                      component={DatePicker}
                      disabledDays={[
                        {
                          before: vehicleUnavailableStartDate
                            ? moment(vehicleUnavailableStartDate).toDate()
                            : moment().toDate(),
                          after: undefined,
                        },
                      ]}
                      margin="no"
                      label={translate('common.unavailableTo')}
                      isClearable
                    />
                  </GridColumn>
                </Grid>
              )}

              <Field
                name="locationId"
                component={Dropdown}
                label={translate('common.customerTypes.operationalFacility')}
                options={vehicleFacilitiesOptionsOrdered}
                isClearable
                width="100%"
              />

              {vehicleTypeId === RESIDENTIAL_ID && (
                <>
                  <Field
                    name="streetServicingSideId"
                    component={ServicingSideDropdown}
                    withLabel
                    validate={[isRequired]}
                    margin="medium no no no"
                  />

                  {isSuperAdmin && (
                    <Field
                      name="confirmationModeId"
                      component={ConfirmationModeDropdown}
                      withLabel
                      validate={[isRequired]}
                    />
                  )}
                </>
              )}
            </GridColumn>

            {isAdmin && (
              <GridColumn size="6/12" padding="no no no medium">
                <>
                  <TypedField
                    name="deviceRoles"
                    component={DeviceRoleTypeCheckboxes}
                    onChange={(event: ChangeEvent<HTMLInputElement>, values: number[]) => {
                      this.onDeviceTypeChange(
                        values.length ? values.reduce((acc: number, curr: number) => acc + curr) : null,
                      );
                    }}
                    props={{
                      withLabel: true,
                      formGroupProps: {
                        grid: true,
                        gridColumns: 'repeat(3, 1fr)',
                        gridColumnGap: '0px',
                        gridRowGap: '0px',
                      },
                    }}
                  />
                  <Field
                    name="deviceId"
                    component={Input}
                    label={translate('common.rubiconYDeviceId')}
                    validate={[maxLength50]}
                  />

                  <Field
                    name="deviceManufacturerId"
                    component={Dropdown}
                    label={translate('common.deviceManufacturer')}
                    options={deviceManufacturers}
                    validate={deviceId ? [isRequired] : null}
                    isClearable
                  />

                  <Grid centered>
                    <GridColumn size="11/12" padding="no no sMedium no">
                      <Field
                        name="surfSightDeviceNumber"
                        component={Input}
                        label={translate('vehicles.surfsightCameraID')}
                        validate={[maxLength50]}
                      />
                    </GridColumn>
                    <GridColumn size="1/12" align="center">
                      <Button
                        type="button"
                        text
                        margin="medium no no no"
                        color="primary"
                        padding="no"
                        disabled={!surfSightDeviceNumber}
                        onClick={this.openSurfsightConfiguration}
                      >
                        <ActionButtonTooltip
                          icon="settings"
                          size="sMedium"
                          tooltip="editSurfsightCameraConfiguration"
                        />
                      </Button>
                    </GridColumn>
                  </Grid>

                  <Grid centered>
                    <GridColumn size={visionConfigurationEnabled ? '11/12' : '12/12'} padding="no no sMedium no">
                      <Field
                        name="rubiconZDeviceId"
                        component={Input}
                        label={translate('common.rubiconZDeviceId')}
                        margin="no"
                        validate={[maxLength100]}
                      />
                    </GridColumn>
                    {visionConfigurationEnabled && (
                      <GridColumn size="1/12" align="center">
                        <Button
                          type="button"
                          text
                          margin="medium no no no"
                          color="primary"
                          padding="no"
                          disabled={!rubiconZDeviceId}
                          onClick={this.openEditVisionConfiguration}
                        >
                          <ActionButtonTooltip icon="redeye" size="sMedium" tooltip="editVisionConfiguration" />
                        </Button>
                      </GridColumn>
                    )}
                  </Grid>
                </>

                {rubiconZDeviceId && (
                  <Fragment>
                    <Field
                      label="Vehicle Camera Configurations"
                      name="vehicleCameraConfigurations"
                      component={VehicleCameraConfigurations}
                      validate={[areCameraConfigsValid]}
                    />
                    <Field
                      name="binColors"
                      component={BinColorsMultiSelect}
                      multiSelectProps={{ normalizeValues: Number }}
                      withPlaceholder
                    />
                  </Fragment>
                )}
              </GridColumn>
            )}

            <GridColumn size={isAdmin ? '12/12' : '6/12'}>
              <ButtonSet margin="large no no">
                <Button type="submit" color="primary" id="save-vehicle-button">
                  {translate('common.save')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        </form>
        {isVisionConfigurationModalOpen && (
          <VisionConfigurationEditorModal
            changeCustomVisionConfigurationDefaultValue={this.changeCustomVisionConfigurationDefaultValue}
            closeModal={this.closeEditVisionConfigurationModel}
            hasCustomVisionConfigurationSettings={hasCustomVisionConfigurationSettings}
          />
        )}

        {isSurfsightConfigurationModalOpen && (
          <DriverSafetyModalResolver
            closeModal={this.closeSurfsightConfigurationModal}
            vehicleId={currentVehicleId}
            vehicleTypeIdSpecificForVehicle={vehicleTypeId}
          />
        )}
      </Fragment>
    );
  }
}

const formSelector = formValueSelector('vehicleEditorForm');

const mapStateToProps = (state: AppState, { isCreate }: ComponentProps) => ({
  aiModelTypes: aiModelTypesSelector(state.fleet.vehicle.vehicle),
  deviceId: formSelector(state, 'deviceId'),
  formValues: getFormValues('vehicleEditorForm')(state),
  hasCustomVisionConfigurationSettings: state.fleet.vehicle.vehicle?.hasCustomVisionConfigurationSettings,
  initialValues: (vehicleEditorFormInitialValuesSelector as any)(state.fleet.vehicle, isCreate),
  isAdmin: isAdminSelector(state.account.login),
  isAvailable: formSelector(state, 'isAvailable') === AVAILABLE,
  isFormValid: isValid('vehicleEditorForm')(state),
  isSuperAdmin: isSuperAdminSelector(state.account.login),
  location: state.router.location,
  rubiconYDeviceManufacturerList: state.fleet.vehicles.deviceManufacturerList,
  rubiconZDeviceId: formSelector(state, 'rubiconZDeviceId'),
  surfSightDeviceNumber: formSelector(state, 'surfSightDeviceNumber'),
  vehicleFacilities: state.fleet.vehicle.vehicleFacilities,
  vehicleTypeId: formSelector(state, 'vehicleTypeId'),
  vehicleUnavailableEndDate: formSelector(state, 'vehicleUnavailableEndDate'),
  vehicleUnavailableInitialValues: formSelector(state, 'vehicleUnavailable'),
  vehicleUnavailableStartDate: formSelector(state, 'vehicleUnavailableStartDate'),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  visionConfigurationEnabled: visionConfigurationStatusSelector(state.vendors.features.features) || false,
});

const mapDispatchToProps = {
  change,
  push,
  saveVehicle,
  submit,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'vehicleEditorForm',
      enableReinitialize: true,
      onSubmitFail: focusFirstInvalidField,
    })(VehicleEditorForm),
  ),
);
