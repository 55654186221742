import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import { AccountSettingsPageResolver, UsersPageResolver } from './pages';
import { RestrictedRoute } from '../../account/components';
import { ACCOUNT_SETTINGS_MANAGE_USERS } from '../../account/constants';
import XDeviceStatusesPageResolver from '../../settings/components/pages/XDeviceStatusesPageResolver';

const Vendors = () => (
  <Switch>
    <RestrictedRoute permission={ACCOUNT_SETTINGS_MANAGE_USERS} exact path="/users" component={UsersPageResolver} />
    <Route exact path="/account-settings/manage-devices" component={XDeviceStatusesPageResolver} />
    <Route exact path="/account-settings" component={AccountSettingsPageResolver} />
    <Redirect to="/account-settings" />
  </Switch>
);

export default Vendors;
