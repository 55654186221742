import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoadingOverlay } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadFuelingTicketsSettings } from 'src/vendors/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import FuelingTicketsSettingsModal from './FuelingTicketsSettingsModal';

interface Props {
  closeModal: () => void;
}

const FuelingTicketsSettingsModalResolver = ({ closeModal }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = useCallback(() => {
    if (!vendorId) return Promise.reject();

    return loadFuelingTicketsSettings(vendorId as number)(dispatch);
  }, [dispatch, vendorId]);

  return (
    <Resolver
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
      successComponent={FuelingTicketsSettingsModal}
      successProps={{ closeModal }}
    />
  );
};

export default FuelingTicketsSettingsModalResolver;
