import styled from 'styled-components';

export const RubiconSelectTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: evenly;
  position: relative;
`;

export const RubiconSelectTile = styled.div`
  flex-basis: 32.6%;
  margin: 0 1% 50px 0;
  background: white;
  transition: box-shadow 0.3s ease-out;

  &:nth-child(3n) {
    margin-right: 0;
  }
`;

export const RubiconSelectTileFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

export const RubiconSelectTileContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
`;

export const RubiconSelectImageContainer = styled.img`
  cursor: auto;
  height: 230px;
  width: 365px;
  object-fit: cover;
`;

export const RubiconSelectBannerContainer = styled.div`
  position: absolute;
  left: 65px;
`;
