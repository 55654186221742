import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import {
  open311Settings,
  open311Lookup,
  deleteCreationRule,
  inboundCreationRule,
  outboundCreationRule,
  open311Configuration,
  customField,
} from '../interfaces/Open311';
import {
  saveGlobalNoteField as doSaveGlobalNoteField,
  saveServiceTypeNoteField as doSaveServiceTypeNoteField,
  updateServiceTypelNoteField as doUpdateServiceTypeNoteField,
  updateGlobalNoteField as doUpdateGlobalNoteField,
  loadOpen311Lookup as doLoadOpen311Lookup,
  loadOpen311Configuration as doLoadOpen311Configuration,
  deleteCreationRules as doDeleteCreationRules,
  deleteOutboundCreationRules as doDeleteOutboundCreationRules,
  deleteGlobalNoteField as doDeleteDefaultNoteFields,
  deleteServiceTypeNoteField as doDeleteServiceTypeNoteFields,
  updateCreationRule as doUpdateCreationRule,
  creationNewRule as doCreateNewRule,
  updateOutboundCreationRule as doUpdateOutboundCreationRule,
  creationNewOutboundRule as doCreateNewOutboundRule,
  updateFieldMappings as doUpdateFieldMappings,
  updateServiceConfiguration as doUpdateServiceConfiguration,
} from '../services/Open311';

const START_LOAD = 'vendors/open311Settings/START_LOAD';
const COMPLETE_LOAD = 'vendors/open311Settings/COMPLETE_LOAD';
const COMPLETE_LOAD_OPEN311 = 'vendors/open311Settings/COMPLETE_LOAD_OPEN311';
const FAIL_LOAD = 'vendors/open311Settings/FAIL_LOAD';
const START_SAVE = 'vendors/open311Settings/START_SAVE';
const COMPLETE_SAVE = 'vendors/open311Settings/COMPLETE_SAVE';
const COMPLETE_SAVE_OUTBOUND_RULE = 'vendors/open311Settings/COMPLETE_SAVE_OUTBOUND_RULE';
const FAIL_SAVE = 'vendors/open311Settings/FAIL_SAVE';
const START_DELETE = 'vendors/open311Settings/START_DELETE';
const COMPLETE_DELETE = 'vendors/open311Settings/COMPLETE_DELETE';
const COMPLETE_DELETE_OUTBOUND_RULE = 'vendors/open311Settings/COMPLETE_DELETE_OUTBOUND_RULE';
const FAIL_DELETE = 'vendors/open311Settings/FAIL_DELETE';
const START_UPDATE = 'vendors/open311Settings/START_DELETE';
const COMPLETE_UPDATE = 'vendors/open311Settings/COMPLETE_DELETE';
const FAIL_UPDATE = 'vendors/open311Settings/FAIL_DELETE';
const START_ADD_GLOBAL_NOTE_FIELD = 'vendors/open311Settings/START_ADD_GLOBAL_NOTE_FIELD';
const COMPLETE_ADD_GLOBAL_NOTE_FIELD = 'vendors/open311Settings/COMPLETE_ADD_GLOBAL_NOTE_FIELD';
const START_ADD_SERVICE_TYPE_NOTE_FIELD = 'vendors/open311Settings/START_ADD_SERVICE_TYPE_NOTE_FIELD';
const COMPLETE_ADD_SERVICE_TYPE_NOTE_FIELD = 'vendors/open311Settings/COMPLETE_ADD_SERVICE_TYPE_NOTE_FIELD';
const START_UPDATE_GLOBAL_NOTE_FIELD = 'vendors/open311Settings/START_UPDATE_GLOBAL_NOTE_FIELD';
const COMPLETE_UPDATE_GLOBAL_NOTE_FIELD = 'vendors/open311Settings/COMPLETE_UPDATE_GLOBAL_NOTE_FIELD';
const START_DELETE_GLOBAL_NOTE_FIELD = 'vendors/open311Settings/START_DELETE_GLOBAL_NOTE_FIELD';
const COMPLETE_DELETE_GLOBAL_NOTE_FIELD = 'vendors/open311Settings/COMPLETE_DELETE_GLOBAL_NOTE_FIELD';
const COMPLETE_DELETE_SERVICE_TYPE_NOTE_FIELD = 'vendors/open311Settings/COMPLETE_DELETE_SERVICE_TYPE_NOTE_FIELD';
const RESET = 'vendors/open311Settings/RESET';

interface State {
  isAddingServiceNoteField: boolean;
  isDeleting: boolean;
  isDeletingGlobalNoteField: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  isSaving: boolean;
  isAddingGlobalNoteField: boolean;
  open311Settings?: open311Settings;
  open311Configuration: open311Configuration;
  equipmentTypes: TechnicalType[];
  pickupTypes: TechnicalType[];
  pickupExceptionTypes: TechnicalType[];
  reasonCodeTypes: TechnicalType[];
  vehicleTypes: TechnicalType[];
  wasteMaterialTypes: TechnicalType[];
  creationRules: inboundCreationRule[];
  outboundCreationRules: outboundCreationRule[];
  globalNoteFields: customField[];
  serviceTypes: inboundCreationRule[];
  exceptions: outboundCreationRule[];
}

const initialState: State = {
  isAddingServiceNoteField: false,
  isDeleting: false,
  isDeletingGlobalNoteField: false,
  isLoading: false,
  isUpdating: false,
  isSaving: false,
  isAddingGlobalNoteField: false,
  open311Settings: undefined,
  open311Configuration: {} as open311Configuration,
  equipmentTypes: [],
  pickupTypes: [],
  pickupExceptionTypes: [],
  reasonCodeTypes: [],
  vehicleTypes: [],
  wasteMaterialTypes: [],
  creationRules: [],
  outboundCreationRules: [],
  globalNoteFields: [],
  serviceTypes: [],
  exceptions: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          open311Settings: action.open311Lookup,
          reasonCodeTypes: action.open311Lookup?.reasonCodeTypes,
          vehicleTypes: action.open311Lookup?.vendorVehicleTypes,
          wasteMaterialTypes: action.open311Lookup?.wasteMaterialTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          open311Configuration: undefined,
          reasonCodeTypes: [],
          vehicleTypes: [],
          wasteMaterialTypes: [],
        },
      });
    case COMPLETE_LOAD_OPEN311:
      return update(state, {
        $merge: {
          open311Configuration: action.open311Configuration,
          globalNoteFields: action.open311Configuration?.noteFields,
          serviceTypes: action.open311Configuration?.serviceTypes,
          exceptions: action.open311Configuration?.exceptions,
        },
      });
    case START_ADD_GLOBAL_NOTE_FIELD:
      return update(state, {
        $merge: {
          isAddingGlobalNoteField: true,
        },
      });

    case COMPLETE_ADD_GLOBAL_NOTE_FIELD:
      return update(state, {
        $merge: {
          isAddingGlobalNoteField: false,
          globalNoteFields: action.globalNoteFields,
        },
      });

    case START_DELETE_GLOBAL_NOTE_FIELD:
      return update(state, {
        $merge: {
          isDeletingGlobalNoteField: true,
        },
      });

    case COMPLETE_DELETE_GLOBAL_NOTE_FIELD:
      return update(state, {
        $merge: {
          isDeletingGlobalNoteField: false,
          globalNoteFields: action.globalNoteFields,
        },
      });

    case START_ADD_SERVICE_TYPE_NOTE_FIELD:
      return update(state, {
        $merge: {
          isAddingServiceNoteField: true,
        },
      });

    case COMPLETE_ADD_SERVICE_TYPE_NOTE_FIELD:
      const updatedServiceTypes = state.serviceTypes.map(serviceType => {
        if (serviceType.id === action.serviceTypeId) {
          return { ...serviceType, noteFields: action.serviceTypeNoteFields };
        }
        return serviceType;
      });

      return update(state, {
        $merge: {
          isAddingServiceNoteField: false,
          serviceTypes: updatedServiceTypes,
        },
      });

    case START_UPDATE_GLOBAL_NOTE_FIELD:
      return update(state, {
        $merge: {
          isUpdating: true,
        },
      });

    case COMPLETE_UPDATE_GLOBAL_NOTE_FIELD:
      return update(state, {
        $merge: {
          isUpdating: false,
          globalNoteFields: action.globalNoteFields,
        },
      });

    case COMPLETE_DELETE_SERVICE_TYPE_NOTE_FIELD: {
      const updatedServiceTypes = state.serviceTypes.map(serviceType => {
        if (serviceType.id === action.serviceTypeId) {
          return { ...serviceType, noteFields: action.serviceTypeNoteFields };
        }
        return serviceType;
      });
      return update(state, {
        $merge: {
          isDeleting: false,
          serviceTypes: updatedServiceTypes,
        },
      });
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE: {
      return update(state, {
        $merge: {
          isSaving: false,
          serviceTypes: action.inboundCreationRules,
        },
      });
    }

    case COMPLETE_SAVE_OUTBOUND_RULE: {
      return update(state, {
        $merge: {
          isSaving: false,
          exceptions: action.outboundCreationRule,
        },
      });
    }

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });
    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
          serviceTypes: action.creationRules,
        },
      });

    case COMPLETE_DELETE_OUTBOUND_RULE:
      return update(state, {
        $merge: {
          isDeleting: false,
          exceptions: action.creationRules,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: true,
        },
      });
    case COMPLETE_UPDATE:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case FAIL_UPDATE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (open311Lookup: open311Lookup) => ({
  type: COMPLETE_LOAD,
  open311Lookup,
});

const completeLoadOpen311 = (open311Configuration: open311Configuration) => ({
  type: COMPLETE_LOAD_OPEN311,
  open311Configuration,
});
const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (creationRules: inboundCreationRule[]) => ({
  type: COMPLETE_DELETE,
  creationRules,
});

const completeDeleteOutboundRule = (creationRules: outboundCreationRule[]) => ({
  type: COMPLETE_DELETE_OUTBOUND_RULE,
  creationRules,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startUpdate = () => ({
  type: START_UPDATE,
});

const completeUpdate = () => ({
  type: COMPLETE_UPDATE,
});

const failUpdate = () => ({
  type: FAIL_UPDATE,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (inboundCreationRules: inboundCreationRule[]) => ({
  type: COMPLETE_SAVE,
  inboundCreationRules,
});

const completeSaveOutboundRule = (outboundCreationRule: outboundCreationRule[]) => ({
  type: COMPLETE_SAVE_OUTBOUND_RULE,
  outboundCreationRule,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startAddGlobalNoteField = () => ({
  type: START_ADD_GLOBAL_NOTE_FIELD,
});

const completeAddGlobalNoteField = (globalNoteFields: customField[]) => ({
  type: COMPLETE_ADD_GLOBAL_NOTE_FIELD,
  globalNoteFields,
});

const startAddServiceNoteField = () => ({
  type: START_ADD_SERVICE_TYPE_NOTE_FIELD,
});
const completeAddServiceNoteField = (serviceTypeNoteFields: customField[], serviceTypeId: number) => ({
  type: COMPLETE_ADD_SERVICE_TYPE_NOTE_FIELD,
  serviceTypeNoteFields,
  serviceTypeId,
});

const startDeleteGlobalNoteField = () => ({
  type: START_DELETE_GLOBAL_NOTE_FIELD,
});

const completeDeleteGlobalNoteField = (globalNoteFields: customField[]) => ({
  type: COMPLETE_DELETE_GLOBAL_NOTE_FIELD,
  globalNoteFields,
});

const completeDeleteServiceTYpeNoteField = (serviceTypeNoteFields: customField[], serviceTypeId: number) => ({
  type: COMPLETE_DELETE_SERVICE_TYPE_NOTE_FIELD,
  serviceTypeNoteFields,
  serviceTypeId,
});

export const loadOpen311Lookup = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadOpen311LookupPromise = doLoadOpen311Lookup(vendorId);
  loadOpen311LookupPromise.then(response => dispatch(completeLoad(response))).catch(() => dispatch(failLoad()));
  return loadOpen311LookupPromise;
};

export const loadOpen311Configuration = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadOpen311ConfigurationPromise = doLoadOpen311Configuration(vendorId);
  loadOpen311ConfigurationPromise
    .then(open311Configuration => dispatch(completeLoadOpen311(open311Configuration)))
    .catch(() => dispatch(failLoad()));
  return loadOpen311ConfigurationPromise;
};

export const saveGlobalNoteField =
  (configurationId: number, globalNoteField: customField, notefieldId?: number) => (dispatch: Dispatch) => {
    dispatch(startAddGlobalNoteField());
    let promise;
    if (notefieldId) {
      promise = doUpdateGlobalNoteField(configurationId, notefieldId, globalNoteField);
    } else {
      promise = doSaveGlobalNoteField(configurationId, globalNoteField);
    }
    promise
      .then((globalNoteFields: customField[]) => dispatch(completeAddGlobalNoteField(globalNoteFields)))
      .catch(() => dispatch(failSave()));
    return promise;
  };

export const saveServiceRequestNoteField =
  (configurationId: number, serviceTypeId: number, serviceRequestNoteField: customField, notefieldId?: number) =>
  (dispatch: Dispatch) => {
    dispatch(startAddServiceNoteField());
    let promise;
    if (notefieldId) {
      promise = doUpdateServiceTypeNoteField(configurationId, serviceTypeId, notefieldId, serviceRequestNoteField);
    } else {
      promise = doSaveServiceTypeNoteField(configurationId, serviceTypeId, serviceRequestNoteField);
    }
    promise
      .then((noteFields: customField[]) => dispatch(completeAddServiceNoteField(noteFields, serviceTypeId)))
      .catch(() => dispatch(failSave()));
    return promise;
  };

export const updateServiceConfiguration =
  (formData: open311Configuration, Open311ConfigurationId?: number | undefined) => (dispatch: Dispatch) => {
    dispatch(startUpdate());
    const updateServiceConfigurationPromise = doUpdateServiceConfiguration(formData, Open311ConfigurationId)
      .then(() => dispatch(completeUpdate()))
      .catch(() => dispatch(failUpdate()));

    return updateServiceConfigurationPromise;
  };

export const updateFieldMappings = (fieldMapping: any, Open311ConfigurationId: number) => (dispatch: Dispatch) => {
  dispatch(startUpdate());
  const updateFieldMappingsPromise = doUpdateFieldMappings(fieldMapping, Open311ConfigurationId);
  updateFieldMappingsPromise.then(() => dispatch(completeUpdate())).catch(() => dispatch(failUpdate()));
  return updateFieldMappingsPromise;
};

export const deleteCreationRules = (configurationId: number, creationRuleIds: number[]) => (dispatch: Dispatch) => {
  const deleteCreationRulesObj: deleteCreationRule = {};
  deleteCreationRulesObj.ids = creationRuleIds;
  dispatch(startDelete());
  const deleteCreationRulePromise = doDeleteCreationRules(configurationId, deleteCreationRulesObj);
  deleteCreationRulePromise
    .then(response => {
      dispatch(completeDelete(response));
      return response;
    })
    .catch(() => dispatch(failDelete()));
  return deleteCreationRulePromise;
};

export const saveCreationRule =
  (configurationId: number, creationRuleId: number | undefined, saveCreationRulesObj: inboundCreationRule) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveCreationRulePromise = creationRuleId
      ? doUpdateCreationRule(configurationId, creationRuleId, saveCreationRulesObj)
      : doCreateNewRule(configurationId, saveCreationRulesObj);
    saveCreationRulePromise
      .then(response => {
        dispatch(completeSave(response));
        return response;
      })
      .catch(() => dispatch(failSave()));
    return saveCreationRulePromise;
  };

export const saveOutboundCreationRule =
  (configurationId: number, creationRuleId: number | undefined, saveCreationRulesObj: inboundCreationRule) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveCreationRulePromise = creationRuleId
      ? doUpdateOutboundCreationRule(configurationId, creationRuleId, saveCreationRulesObj)
      : doCreateNewOutboundRule(configurationId, saveCreationRulesObj);
    saveCreationRulePromise
      .then(response => {
        dispatch(completeSaveOutboundRule(response));
        return response;
      })
      .catch(() => dispatch(failSave()));
    return saveCreationRulePromise;
  };

export const deleteOutboundCreationRules =
  (configurationId: number, creationRuleIds: number[]) => (dispatch: Dispatch) => {
    const deleteCreationRulesObj: deleteCreationRule = {};
    deleteCreationRulesObj.ids = creationRuleIds;
    dispatch(startDelete());
    const deleteCreationRulePromise = doDeleteOutboundCreationRules(configurationId, deleteCreationRulesObj);
    deleteCreationRulePromise
      .then(response => {
        dispatch(completeDeleteOutboundRule(response));
        return response;
      })
      .catch(() => dispatch(failDelete()));
    return deleteCreationRulePromise;
  };

export const deleteDefaultNoteFields =
  (configurationId: number, noteFieldIds: number[], serviceTypeId?: number) => (dispatch: Dispatch) => {
    dispatch(startDeleteGlobalNoteField());
    const deleteDefaultNoteFieldsObj: deleteCreationRule = {};
    deleteDefaultNoteFieldsObj.ids = noteFieldIds;
    if (serviceTypeId) {
      const deleteDefaultNoteFieldsPromise = doDeleteServiceTypeNoteFields(
        configurationId,
        serviceTypeId,
        deleteDefaultNoteFieldsObj,
      );
      deleteDefaultNoteFieldsPromise
        .then((response: customField[]) => {
          dispatch(completeDeleteServiceTYpeNoteField(response, serviceTypeId));
          return response;
        })
        .catch(() => dispatch(failDelete()));
      return deleteDefaultNoteFieldsPromise;
    } else {
      const deleteDefaultNoteFieldsPromise = doDeleteDefaultNoteFields(configurationId, deleteDefaultNoteFieldsObj);
      deleteDefaultNoteFieldsPromise
        .then((response: customField[]) => {
          dispatch(completeDeleteGlobalNoteField(response));
          return response;
        })
        .catch(() => dispatch(failDelete()));
      return deleteDefaultNoteFieldsPromise;
    }
  };
