import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { TypedResolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { getQueryParams } from '../../../utils/services/queryParams';
import { validateResetPasswordToken } from '../../ducks/validateResetPasswordToken';
import ResetPasswordPage from './ResetPasswordPage';

const ResetPasswordPageResolver: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const { code, email } = getQueryParams(search);

  return (
    <TypedResolver
      successComponent={ResetPasswordPage}
      loadingComponent={PageLoadingOverlay}
      resolve={() => validateResetPasswordToken(code, email)(dispatch)}
    />
  );
};

export default ResetPasswordPageResolver;
