import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import DocumentTitle from '../../../../common/components/DocumentTitle';
import { PageLoading } from '../../../../common/components/styled';
import { CUSTOMER_TYPES } from '../../../../common/constants/customerTypes';
import { TypedResolver } from '../../../../core/components';
import translate from '../../../../core/services/translate';
import { AppState } from '../../../../store';
import { loadCustomer } from '../../../ducks';
import { Email } from '../../../interfaces/Email';
import { loadCustomerCommunication } from '../../../services/customerCommunication';
import CustomerCommunicationsPage from './CustomerCommunicationsPage';

interface Props extends RouteComponentProps<{ customerId: string; routeLocationId?: string }> {}

const CustomerCommunicationsPageResolver: React.FC<Props> = ({
  match: {
    params,
    params: { routeLocationId },
  },
}) => {
  const customerId = Number(params.customerId);
  const [emails, setEmails] = useState<Email[]>([]);
  const customer = useSelector((state: AppState) => state.customers.customer.customer) as any;
  const dispatch = useDispatch();

  const loadDependencies = () => {
    const dependencies = [];
    if (!customer) {
      dependencies.push(loadCustomer(customerId)(dispatch));
    }
    dependencies.push(
      loadCustomerCommunication(customerId, routeLocationId).then((emails: Email[]) => setEmails(emails)),
    );
    return Promise.all(dependencies);
  };

  return (
    <>
      <DocumentTitle>{translate('customers.emails.communication')}</DocumentTitle>
      <TypedResolver
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successProps={{
          emails,
          customerName: customer ? customer.name : '',
          customerType: customer ? CUSTOMER_TYPES[customer.customerTypeId].name : '',
          customerAccountNumber: customer ? customer.accountNumber : '',
        }}
        successComponent={CustomerCommunicationsPage}
      />
    </>
  );
};

export default withRouter(CustomerCommunicationsPageResolver);
