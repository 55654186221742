import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadSnowPlowSettings as doLoadSnowPlowSettings,
  saveSnowPlowSettings as doSaveSnowPlowSettings,
  loadSnowPlowDefaultSettings as doLoadSnowPlowDefaultSettings,
} from '../services/snowPlowSettings';
import { SnowPlowSettings } from '../interfaces/SnowPlowSettings';

// Actions
const COMPLETE_LOAD = 'vendors/snowPlowSettings/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/snowPlowSettings/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/snowPlowSettings/FAIL_LOAD';
const FAIL_SAVE = 'vendors/snowPlowSettings/FAIL_SAVE';
const START_LOAD = 'vendors/snowPlowSettings/START_LOAD';
const START_SAVE = 'vendors/snowPlowSettings/START_SAVE';
const START_LOAD_DEFAULT_SETTINGS = 'vendors/snowPlowSettings/START_LOAD_DEFAULT_SETTINGS';
const COMPLETE_LOAD_DEFAULT_SETTINGS = 'vendors/snowPlowSettings/COMPLETE_LOAD_DEFAULT_SETTINGS';
const FAIL_LOAD_DEFAULT_SETTINGS = 'vendors/snowPlowSettings/FAIL_LOAD_DEFAULT_SETTINGS';

// Initial state
const initialState = {
  isLoading: false,
  isLoadingDefaultSettings: false,
  isSaving: false,
  snowPlowSettings: {} as SnowPlowSettings,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          snowPlowSettings: action.snowPlowSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_DEFAULT_SETTINGS:
      return update(state, {
        $merge: {
          isLoadingDefaultSettings: true,
        },
      });

    case COMPLETE_LOAD_DEFAULT_SETTINGS:
      return update(state, {
        $merge: {
          isLoadingDefaultSettings: false,
        },
      });

    case FAIL_LOAD_DEFAULT_SETTINGS:
      return update(state, {
        $merge: {
          isLoadingDefaultSettings: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (snowPlowSettings: SnowPlowSettings) => ({
  type: COMPLETE_LOAD,
  snowPlowSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoadDefaultSettings = () => ({
  type: START_LOAD_DEFAULT_SETTINGS,
});

const completeLoadDefaultSettings = () => ({
  type: COMPLETE_LOAD_DEFAULT_SETTINGS,
});

const failLoadDefaultSettings = () => ({
  type: FAIL_LOAD_DEFAULT_SETTINGS,
});

export const loadSnowPlowSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadSnowPlowSettingsPromise = doLoadSnowPlowSettings(vendorId);
  loadSnowPlowSettingsPromise
    .then(snowPlowSettings => dispatch(completeLoad(snowPlowSettings)))
    .catch(() => dispatch(failLoad()));

  return loadSnowPlowSettingsPromise;
};

export const saveSnowPlowSettings = (vendorId: number, snowPlowSettings: SnowPlowSettings) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveSnowPlowSettingsPromise = doSaveSnowPlowSettings(vendorId, snowPlowSettings);
  saveSnowPlowSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveSnowPlowSettingsPromise;
};

export const loadSnowPlowDefaultSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadDefaultSettings());
  const loadSnowPlowDefaultSettingsPromise = doLoadSnowPlowDefaultSettings(vendorId);
  loadSnowPlowDefaultSettingsPromise
    .then(() => dispatch(completeLoadDefaultSettings()))
    .catch(() => dispatch(failLoadDefaultSettings()));
  return loadSnowPlowDefaultSettingsPromise;
};
