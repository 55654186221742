import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const TONS = 1;
export const POUND = 2;

export const UNIT_OF_MEASUREMENT = mapKeys(
  [
    { id: TONS, name: translate('common.tons') },
    { id: POUND, name: translate('common.weightMeasurementUnits.pounds') },
  ],
  'id',
);
