import React from 'react';
import { InjectedFormProps, reduxForm, submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import { toLower } from 'lodash-es';

import { Button, ButtonSet, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { Dropdown, TypedField } from '../../../core/components';
import translate from 'src/core/services/translate';
import { isRequired } from 'src/utils/services/validator';
import { TransferContainerDropdownWrapper } from '../styled/TransferContainerDropdownWrapper';

export const FACILITY_TYPE_FORM = 'facilityTypeForm';
interface PropsWithoutReduxForm {
  facilityTypes: any[];
  closeModal(): void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const FacilityTypeForm: React.FC<Props> = ({ closeModal, facilityTypes, handleSubmit }) => {
  const dispatch = useDispatch();
  const filterOption = (option: any, input: any) => {
    return option.data.value === -1 || toLower(option.label).includes(toLower(input));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(submit(FACILITY_TYPE_FORM));
      }}
    >
      <PanelSection padding="medium medium ssMedium">
        <Grid centered>
          <GridColumn size="6/12">
            <TransferContainerDropdownWrapper>
              <TypedField
                name="facilityTypeId"
                component={Dropdown}
                props={{
                  label: translate('common.selectLocation'),
                  menuPosition: 'fixed',
                  options: facilityTypes,
                  filterOption,
                }}
                validate={[isRequired]}
              />
            </TransferContainerDropdownWrapper>
          </GridColumn>
        </Grid>
      </PanelSection>
      <Grid centered>
        <GridColumn size="6/12">
          <ButtonSet margin="medium no">
            <Button color="primary" margin="no small" type="submit">
              {translate('common.continue')}
            </Button>

            <Button color="secondary" margin="no small" onClick={closeModal}>
              {translate('common.cancel')}
            </Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: FACILITY_TYPE_FORM,
  enableReinitialize: true,
})(FacilityTypeForm);
