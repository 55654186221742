import { find } from 'lodash-es';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MapGLPopup, MapGLPopupTitle, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import { ActionButtonTooltip } from 'src/core/components';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { TableActionButton } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import { CustomerProximitySearchModalResolver } from 'src/customers/components/modals';
import { MapGLPopupContent } from 'src/customers/components/styled';
import { ROUTE_PICKUP_TYPE_IDS } from 'src/routes/constants';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';

type Props = {
  routeLocation: RouteLocation;
};

export default function RouteTemplateSequenceStopPopup({ routeLocation }: Props) {
  const dispatch = useDispatch();
  const [isCustomerProximitySearchModalOpen, setCustomerProximitySearchModalOpen] = useState(false);
  const {
    location: {
      address: { line1 },
      name,
    },
    latitude,
    longitude,
    pickupTypeId,
    customer: { name: customerName },
    service: { serviceContractBinDetails },
    serviceContractRouteTemplateId,
  } = routeLocation as any;

  const serviceContractBinDetailsData = serviceContractBinDetails?.[0];

  const displayLatitude = serviceContractBinDetailsData.displayLatitude || latitude;
  const displayLongitude = serviceContractBinDetailsData.displayLongitude || longitude;

  const iconType = find(NEW_INSIGHT_ICON_TYPES, ({ types }) => types.indexOf('icScheduled') !== -1);

  const pickupType = ROUTE_PICKUP_TYPE_IDS[pickupTypeId];

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={displayLatitude}
        longitude={displayLongitude}
        onClose={() => dispatch(clearRouteMapSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={iconType?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={''}
          />
          <MapGLPopupContent>
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('routes.newStopNumber')}
              value={routeLocation.newOrderNumber}
            />
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('dashboard.stopNumber')}
              value={routeLocation.orderNumber}
            />
            <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.customer')}>
              {customerName}
              <TableActionButton
                margin="no no no xSmall"
                color="primary"
                onClick={() => setCustomerProximitySearchModalOpen(true)}
              >
                <ActionButtonTooltip
                  icon="searchVehicle"
                  size="sMedium"
                  sourceIsInfoWindow
                  tooltip="customerProximitySearch"
                />
              </TableActionButton>
            </LabeledDataView>
            <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.location')} value={name} />
            <LabeledDataView width="calc(100% - 10px)" size="small" label={translate('common.address')} value={line1} />
            {pickupType && (
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('routes.pickupType')}
                value={pickupType.name}
              />
            )}
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isCustomerProximitySearchModalOpen && (
        <CustomerProximitySearchModalResolver
          onCancel={() => setCustomerProximitySearchModalOpen(false)}
          locationId={serviceContractRouteTemplateId}
        />
      )}
    </>
  );
}
