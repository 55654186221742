import { http } from '../../core/services/http';
import { TODAY_FORMATTED } from '../../core/constants';

export const loadDisposalsSummary = (
  vendorId: number,
  searchTerm: string,
  vehicleTypeIds: number[],
  routeStatusTypeIds: number[],
  startDate: Date | string = TODAY_FORMATTED,
  endDate: Date | string = TODAY_FORMATTED,
  useUserGlobalFilters?: boolean,
) =>
  http
    .get('routes/weightTickets', {
      params: { vendorId, vehicleTypeIds, routeStatusTypeIds, startDate, endDate, searchTerm, useUserGlobalFilters },
    })
    .then(response => response.data);
