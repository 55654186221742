import { get, reduce } from 'lodash-es';

import { EN, ES, FR, DE, DEFAULT_LOCALE, LOCALES, LANGUAGES } from '../constants';
import { getCurrentLanguageId, setInitialLocale } from './i18n';

const translationsEn = require('../assets/locale/locale-en.json');
const translationsFr = require('../assets/locale/locale-fr.json');
const translationsEs = require('../assets/locale/locale-es.json');
const translationsDe = require('../assets/locale/locale-de.json');

const getLocale = () => {
  let locale = navigator.language.substr(0, 2);
  const currentLanguage = getCurrentLanguageId();
  setInitialLocale(currentLanguage);
  if (currentLanguage > 0) {
    const languageId = currentLanguage;
    locale = LANGUAGES[languageId - 1];
  }
  return LOCALES.indexOf(locale) > -1 ? locale : DEFAULT_LOCALE;
};

export const locale = getLocale();

const translations: any = {
  [EN]: translationsEn,
  [FR]: translationsFr,
  [ES]: translationsEs,
  [DE]: translationsDe,
};

const getTranslation = (translationKey: string) => get(translations[locale], translationKey, translationKey);

const interpolate = (translation: string, translationMap: any) =>
  reduce(
    translationMap,
    (interpolatedTranslation, value, key) =>
      interpolatedTranslation.replace(`{{${key}}}`, value).replace(`{{${key}}}`, value),
    translation,
  );

const translate = (translationKey?: string, translationMap?: any) => {
  if (translationKey) {
    return translationMap
      ? interpolate(getTranslation(translationKey), translationMap)
      : getTranslation(translationKey);
  }
};

export default translate;
