import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { TechnicalType } from '../interfaces/TechnicalType';

const transformTimeMeasurementTypes = (timeMeasurementTypes: TechnicalType[]) =>
  map(timeMeasurementTypes, timeMeasurement => ({
    ...timeMeasurement,
    name: translate(createTranslationKey(timeMeasurement.technicalName, 'common.timeMeasurementTypes')),
  }));

export default transformTimeMeasurementTypes;
