import React from 'react';

import { ActionButtonTooltip, PopoverWrapper } from '../../core/components';
import { Popover, Text } from '../../core/components/styled';
import translate from '../../core/services/translate';

interface Props {
  margin?: string;
  templateEffectiveDate?: string;
}

const EffectiveDate: React.FC<Props> = ({ margin, templateEffectiveDate }) => {
  return (
    <PopoverWrapper
      size="medium"
      color="black"
      triggerButton={<ActionButtonTooltip margin="no no no xxSmall" icon="infoFill" size="sMedium" />}
      width="14px"
      margin={margin || 'no'}
      popoverContent={
        <Popover>
          <Text weight="light">
            <b>{translate('tooltips.routeEffectiveDate')}</b>: {templateEffectiveDate}
          </Text>
        </Popover>
      }
    />
  );
};

export default EffectiveDate;
