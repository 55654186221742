import { GeoJSONSource } from 'mapbox-gl';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getFeatureCollection } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_LAYER,
  STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE,
  STREET_NETWORK_SERVICE_AREAS_CLUSTER_COUNTERS_LAYER,
} from 'src/customers/constants';
import { setStreetNetworkServiceAreasViewport } from 'src/customers/ducks/streetNetworkServiceAreas';
import { getCustomerLocationGeoJson, getHaulerLocationsGeoJSON } from '../utils';
import ServiceAreaMapClusterGLSource from './ServiceAreaMapClusterGLSource';

type Props = {
  map: mapboxgl.Map;
};

const ServiceAreaMapClusterGL = ({ map }: Props) => {
  const dispatch = useDispatch();
  const haulerLocations = useSelector(s => s.customers.streetNetworkServiceAreas.selectedHaulerLocationsForDisplay);
  const customerLocations = useSelector(s => s.customers.streetNetworkServiceAreas.customerLocations);
  const showCustomerLocations = useSelector(s => s.customers.streetNetworkServiceAreas.showCustomerLocations);

  const geoJson = useMemo(() => {
    let collection = getFeatureCollection<GeoJSON.Point, any>([]);

    if (haulerLocations.length) {
      const routeStopsCollection = getHaulerLocationsGeoJSON(haulerLocations);
      collection.features = collection.features.concat(routeStopsCollection.features);
    }

    if (customerLocations.length && showCustomerLocations) {
      const customerLocationsCollection = getCustomerLocationGeoJson(customerLocations);

      collection.features = collection.features.concat(customerLocationsCollection.features);
    }

    return collection;
  }, [haulerLocations, showCustomerLocations, customerLocations]);

  const handleClusterClick = useCallback(
    event => {
      const [feature] = map
        .queryRenderedFeatures(event.point, {
          layers: [STREET_NETWORK_SERVICE_AREAS_CLUSTERS_LAYER, STREET_NETWORK_SERVICE_AREAS_CLUSTER_COUNTERS_LAYER],
        })
        .filter(feature => feature.source === STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE);

      const clusterId = feature.properties?.cluster_id;
      const source = map.getSource(STREET_NETWORK_SERVICE_AREAS_CLUSTERS_SOURCE) as GeoJSONSource;

      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        dispatch(
          setStreetNetworkServiceAreasViewport({
            latitude: (feature.geometry as any).coordinates[1],
            longitude: (feature.geometry as any).coordinates[0],
            zoom,
          }),
        );
      });
    },
    [map, dispatch],
  );

  useEffect(() => {
    map.once('load', () => {
      map.on('click', STREET_NETWORK_SERVICE_AREAS_CLUSTERS_LAYER, handleClusterClick);
    });

    return () => {
      map.off('click', STREET_NETWORK_SERVICE_AREAS_CLUSTERS_LAYER, handleClusterClick);
    };
  }, [map, dispatch, handleClusterClick]);

  return <ServiceAreaMapClusterGLSource geoJSON={geoJson} />;
};

export default ServiceAreaMapClusterGL;
