import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { loadDriverInspectionDetails } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import DriverInspectionPage from './DriverInspectionPage';
import { RouteComponentProps } from 'react-router-dom';

const DriverInspectionPageResolver: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [loadDriverInspectionDetails(vendorId, parseInt(id))(dispatch)];

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      loadingComponent={PageLoading}
      redirectOnError="/fleet/resources"
      resolve={loadDependencies}
      successComponent={DriverInspectionPage}
    />
  );
};

export default DriverInspectionPageResolver;
