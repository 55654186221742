import styled, { css } from 'styled-components';
import FormLabel from './FormLabel';
import { loading } from '../../styles';
import { sizeMapper, mapper } from '../../../utils/styles';

export interface FormGroupProps {
  disabled?: boolean;
  flex?: boolean;
  grid?: boolean;
  gridColumnGap?: string;
  gridColumns?: string;
  gridRowGap?: string;
  hasValue?: number | string | boolean;
  inline?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  justifyContent?: boolean;
  margin?: string;
  padding?: string;
  raisedLabel?: boolean;
  selfFlexStart?: boolean;
  size?: 'normal' | 'small';
  width?: string;
}

const FormGroup = styled.div<FormGroupProps>`
  margin: ${props => sizeMapper(props.margin, 'no no sMedium')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  position: relative;
  text-transform: none;

  ${props =>
    props.inline &&
    css`
      display: inline-block;
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.grid &&
    css`
      width: 100%;
      display: grid;
    `};

  ${props =>
    props.gridColumns &&
    css`
      grid-template-columns: ${props.gridColumns};
    `};

  ${props =>
    props.gridColumnGap &&
    css`
      grid-column-gap: ${props.gridColumnGap};
    `};

  ${props =>
    props.gridRowGap &&
    css`
      grid-row-gap: ${props.gridRowGap};
    `};

  ${props =>
    props.isLoading &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        right: 0;
        ${loading('20px')};
      }
    `};

  ${props =>
    (props.hasValue === 0 || props.hasValue || props.raisedLabel) &&
    css`
      ${FormLabel} {
        font-size: 12px;
        transform: none;
      }
    `};

  ${props =>
    props.selfFlexStart &&
    css`
      align-self: flex-start;
    `};

  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}

  ${props =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
    `}
    ${props =>
    props.justifyContent &&
    css`
      justify-content: space-between;
    `}

  ${FormLabel} {
    transition: transform 0.2s, font-size 0.2s;
    font-size: ${props => mapper(props.size, { small: '10px', normal: '12px' }, '14px')};

    ${props =>
      props.flex &&
      css`
        margin-right: 5px;
      `}
  }
`;

export default FormGroup;
