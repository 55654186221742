import styled, { css } from 'styled-components';

import { Icon } from 'src/core/components';
import { theme } from '../../../core/styles';

interface Props {
  noLeftMargin?: boolean;
  position?: string;
  row?: boolean;
}

interface ActionProps {
  disabled?: boolean;
  noMarginBottom?: boolean;
}

export const RouteMapActionsWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;

  padding: 10px 30px 30px;

  background-color: ${theme.brandWhite};
  box-shadow: -13px 4px 14px rgba(0, 0, 0, 0.07);
`;

export const RouteMapActionsIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const RouteMapActionsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;

  margin-top: 18px;
  margin-bottom: 24px;
`;

export const RouteMapActionsClose = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
`;

export const RouteMapActionsCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 10px;
  height: 10px;
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const RouteMapActionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const RouteMapActionsSection = styled.div<Props>`
  margin-bottom: 32px;

  ${props =>
    props.position === 'bottom' &&
    css`
      position: absolute;
      bottom: 10px;
      margin: 0;
    `}
`;

export const RouteMapAction = styled.div<ActionProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.noMarginBottom ? 0 : 12)}px;

  cursor: pointer;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const RouteMapActionIcon = styled.div`
  width: 18px;
`;

export const RouteMapActionText = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  user-select: none;

  font-size: 12px;
  line-height: 14px;
  span {
    font-size: 12px;
    line-height: 14px;
  }

  margin-left: 10px;
  ${props =>
    props.noLeftMargin &&
    css`
      margin-left: 0;
      margin-top: -6px;
    `}

  ${props =>
    props.row &&
    css`
      flex-direction: row;
    `}
`;
