import { getFeatureCollection, getPointFeature } from "src/common/components/map/util";

export const getRateBuilderMapGeoJSON = (jobRoutes: any) =>
    getFeatureCollection<GeoJSON.Point, any>(
      jobRoutes.map((job : any) =>{
       return getPointFeature(job.serviceContractId, [job.displayLongitude, job.displayLatitude], {
          id: job.serviceContractId,
          clickable: true,
        })}
      ),
    );