import { http } from '../../core/services/http';
import { RouteBuilderRoutesData } from '../../routes/interfaces/DispatchBoardRoute';

export const checkActiveRouteBuilder = (vendorId: number) =>
  http.get(`/routeBuilder/job/id?vendorId=${vendorId}`).then(response => response.data);

export const createRouteBuilderRoutes = (routeData: RouteBuilderRoutesData) =>
  http.post('/routeBuilder/request', routeData).then(response => response.data);

export const checkRouteBuilderStatus = async (vendorId: number, jobId: number) =>
  http.get(`/routeBuilder/job?vendorId=${vendorId}&jobId=${jobId}`).then(response => response.data);

export const finishRouteBuilderJob = (vendorId: number, jobId?: number) =>
  http.put(`/routeBuilder/job/${jobId}/finish?vendorId=${vendorId}`).then(response => response.data);
