import { map, size, uniqueId } from 'lodash-es';
import moment from 'moment';
import { FC } from 'react';

import { Button, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from 'src/core/services/translate';
import { RouteHistoryDriverVehicle, RouteHistoryItem } from 'src/dashboard/interfaces/routesData';
import { getTimeFromMinutes } from 'src/dashboard/utils/dashboardMap';
import { dateFormat, dateTimeFormat } from 'src/utils/services/validator';
import {
  DashboardDriverVehicleItemContainer,
  DashboardDriverVehicleItemData,
} from '../../styled/DashboardFilterMapbox';

interface Props {
  item: RouteHistoryItem;
  isCurrent: boolean;
  openRouteHistoryModal: (item: RouteHistoryItem, driverVehicle: RouteHistoryDriverVehicle) => void;
}

const DashboardRouteHistoryItem: FC<Props> = ({ item, openRouteHistoryModal, isCurrent }) => {
  return (
    <DashboardDriverVehicleItemContainer>
      <DashboardDriverVehicleItemData>
        <Text size="medium" color="primary" singleLine>
          <b>{moment(item.routeDate).format(dateFormat)}</b>{' '}
          {isCurrent && <Text size="xSmall">- {translate('common.currentRoute')}</Text>}
        </Text>
      </DashboardDriverVehicleItemData>

      <Text block size="small" margin="xSmall no no">
        {`${translate('drivers.drivers')} / ${translate('vehicles.vehicles')} - ${
          size(item.vehicles) ? `${size(item.vehicles)}` : translate('opportunity.serviceFrequency.nA')
        }`}
      </Text>
      <Box display="flex" flexDirection="column" width={300}>
        {map(item.vehicles, (vehicle, i) => (
          <Box
            display="block"
            key={uniqueId('_driver_vehicle')}
            margin={i === 0 ? 'no no no small' : 'xSmall no no small'}
            padding="xSmall no"
            withBorderBottom
          >
            <Text size="small" color="primary" singleLine block title={`${vehicle.driverName}/${vehicle.vehicleName}`}>
              {vehicle.driverName} / {vehicle.vehicleName}
            </Text>

            {vehicle.isRouteAssist && (
              <Text
                size="small"
                margin="xxSmall no no no"
                color="alert"
                singleLine
                title={`${vehicle.driverName}/${vehicle.vehicleName}`}
              >
                {translate('vehicles.assisted')}
              </Text>
            )}

            <DashboardDriverVehicleItemData>
              <Text color="grayDark" size="xSmall">
                {translate('dashboard.filterKeys.startTime')}:
              </Text>
              <Text color="grayDarker" size="small" margin="no no no xxSmall" align="right" block>
                {moment(vehicle.sessionStartTime).format(dateTimeFormat)}
              </Text>
            </DashboardDriverVehicleItemData>
            <DashboardDriverVehicleItemData>
              <Text color="grayDark" size="xSmall">
                {translate('common.endTime')}:
              </Text>
              <Text color="grayDarker" size="small" margin="no no no xxSmall" align="right" block>
                {!!vehicle.sessionEndTime ? moment(vehicle.sessionEndTime).format(dateTimeFormat) : 'NA'}
              </Text>
            </DashboardDriverVehicleItemData>
            <DashboardDriverVehicleItemData>
              <Text color="grayDark" size="xSmall">
                {translate('customers.duration')}:
              </Text>
              <Text color="grayDarker" size="xSmall">
                {!!vehicle.sessionEndTime
                  ? getTimeFromMinutes(moment(vehicle.sessionEndTime).diff(moment(vehicle.sessionStartTime), 'minutes'))
                  : 'NA'}
              </Text>
            </DashboardDriverVehicleItemData>
            <DashboardDriverVehicleItemData>
              <Text color="grayDark" size="xSmall">
                {translate('dashboard.filterKeys.percentageCompleted')}:
              </Text>
              <Text color="grayDarker" size="small">
                {`${(vehicle.percentageCompleted || 0).toFixed(2)}%`}
              </Text>
            </DashboardDriverVehicleItemData>
            {vehicle?.vehicleId && (
              <Box display="flex" justifyContent="flex-end" margin="xSmall no no">
                <Button margin="no" size="xSmall" color="primary" onClick={() => openRouteHistoryModal(item, vehicle)}>
                  {translate('tooltips.view')}
                </Button>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </DashboardDriverVehicleItemContainer>
  );
};

export default DashboardRouteHistoryItem;
