import { size } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import arrow from 'src/common/assets/img/common/arrowSmall.png';
import dot from 'src/common/assets/img/common/dot.png';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  DASHBOARD_CLUSTERS_SOURCE,
  DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_POINT_SOURCE,
  DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_SOURCE,
} from 'src/dashboard/constants/dashboardMapGL';
import { DASHBOARD_VEHICLE_TRACKINGS_SOURCE } from 'src/dashboard/constants/vehicleTrackings';
import {
  clearDashboardModalMapSelectedFeature,
  setDashboardModalMapSelectedFeature,
} from 'src/dashboard/ducks/mapControls';
import VehicleTrackingsGLPopupResolver from './VehicleTrackingsGLPopupResolver';
import VehicleTrackingsGLSource from './VehicleTrackingsGLSource';
import {
  VehicleTrackingFeatureProperties,
  getMapboxVehicleTrackingsForRouteGeoJSON,
  getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON,
} from './utils';

const mapImages = [
  {
    id: 'arrow',
    url: arrow,
    sourceId: DASHBOARD_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
  {
    id: 'dot',
    url: dot,
    sourceId: DASHBOARD_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
];

const mapImagesOptions = { addLayers: false };

interface Props {
  isOnDemandVideoDownloadEnabled: boolean;
  map: mapboxgl.Map;
  selectedVehicleId?: number;
}

const VehicleTrackingsGL: React.FC<Props> = ({ isOnDemandVideoDownloadEnabled, map, selectedVehicleId }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  const vehiclePositionsForRoute = useSelector(state => state.dashboard.routeHistoryData.routeVehiclePositions);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routeHistoryData.routeVehiclesBreadCrumbs);

  const geoJSONForRoute = useMemo(() => {
    return {
      lineFeature: getMapboxVehicleTrackingsForRouteGeoJSON(vehicleTrackingsForRoute?.vehicles || []),
      pointFeature: getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON(vehicleTrackingsForRoute?.vehicles || []),
    };
  }, [vehicleTrackingsForRoute?.vehicles]);

  const showVehicleTracking = !!size(geoJSONForRoute.lineFeature.features) && size(vehiclePositionsForRoute) > 0;

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const feature = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_POINT_SOURCE ||
              feature.source === DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_SOURCE,
          )[0] as any as GeoJSON.Feature<GeoJSON.LineString, VehicleTrackingFeatureProperties> | undefined;

        const [otherFeatures] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === DASHBOARD_CLUSTERS_SOURCE);

        if (!!feature && !otherFeatures) {
          /**
           * Yes, setting the id from the properties since Mapbox removes the floating numbers
           * from the main id of the feature. We need to set some other ids for these things,
           * but API changes will be necessary.
           */

          dispatch(clearDashboardModalMapSelectedFeature());
          dispatch(
            setDashboardModalMapSelectedFeature('vehicleTrackings', feature.properties.vehicleId, {
              coordinates: event.lngLat,
              vehicleTrackingGroupIndex: feature.properties.groupIndex,
              vehicleTrackingsSource: feature.properties.source,
            }),
          );
        }
      });
    });
  }, [map, dispatch]);

  return showVehicleTracking ? (
    <>
      <VehicleTrackingsGLSource
        geoJSONForLine={geoJSONForRoute.lineFeature}
        geoJsonForPoints={geoJSONForRoute.pointFeature}
      />
      <VehicleTrackingsGLPopupResolver isOnDemandVideoDownloadEnabled={isOnDemandVideoDownloadEnabled} />
    </>
  ) : null;
};

export default VehicleTrackingsGL;
