import { FC, MouseEvent, useMemo } from 'react';
import { debounce, orderBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, submit } from 'redux-form';

import { ActionButtonTooltip, Checkbox } from '../../../core/components';
import {
  Button,
  Grid,
  GridColumn,
  PanelSection,
  PanelSectionGroup,
  PanelSectionSubTitle,
  PanelSectionTitle,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { featuresForLoggedInUser, NAVI_3, OPEN_311_CONFIG } from '../../ducks/features';
import { Feature, FeaturesFormValues } from '../../interfaces/Features';
import { FLEET_MANAGEMENT, ACCOUNT_MANAGEMENT, ROUTE_OPTIMIZATION } from '../../constants';

interface AccountSettingsFeatureProps extends Feature {
  formValues: FeaturesFormValues;
  openFeatureModal: PropsWithoutReduxForm['openFeatureModal'];
}

const AccountSettingsFeature: FC<AccountSettingsFeatureProps> = ({
  canEditDetails,
  code,
  formValues,
  isReadonly,
  name,
  openFeatureModal,
}) => {
  const hasVendorNetworkFile = useSelector((state: AppState) => state.vendors.vendor.hasVendorNetworkFile);

  return (
    <>
      <GridColumn size="11/12" verticalAlign="center" padding="no" minHeight={42}>
        <Field
          component={Checkbox}
          name={code}
          props={{
            block: true,
            margin: 'no',
            size: 'medium',
            disabled: code === NAVI_3 ? !hasVendorNetworkFile : isReadonly,
            label: name,
          }}
        />
      </GridColumn>

      <GridColumn size="1/12" verticalAlign="center" padding="no" align="right" minHeight={42}>
        {canEditDetails && (
          <Button
            text
            color="grayDark"
            margin="no"
            padding="no"
            type="button"
            disabled={code === OPEN_311_CONFIG ? false : !formValues[code]}
            onClick={(event: MouseEvent) => openFeatureModal(code, event)}
          >
            <ActionButtonTooltip icon="edit" tooltip="editFeature" iconSize="sMedium" />
          </Button>
        )}
      </GridColumn>
    </>
  );
};

interface PropsWithoutReduxForm {
  openFeatureModal(code: string, event: MouseEvent): void;
}

type AccountSettingsFeaturesFormProps = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

const AccountSettingsFeaturesForm: FC<AccountSettingsFeaturesFormProps> = ({ form, openFeatureModal }) => {
  const dispatch = useDispatch();

  const features = useSelector(featuresForLoggedInUser);
  const featuresParent = features.filter(feature => !feature.parentId);
  const formValues = useSelector((state: AppState) => getFormValues(form)(state));

  const isSaving = useSelector((state: AppState) => state.vendors.features.isSaving);
  const isLoading = useSelector((state: AppState) => state.vendors.features.isLoading);

  const onChange = debounce(() => {
    dispatch(submit(form));
  }, 100);

  const { adminFeatureCollections, fleetManagementFeatures, accountManagementFeatures, routeOptimizationFeatures } =
    useMemo(() => {
      const adminFeatureCollections: Feature[][] = [[], [], []];
      const fleetManagementFeatures: Feature[] = [];
      const accountManagementFeatures: Feature[] = [];
      const routeOptimizationFeatures: Feature[] = [];

      const adminFeatures = orderBy(
        featuresParent.filter(f => f.isAdminOnly),
        ['name'],
        ['asc'],
      );

      const adminFeatureMaxRowsPerColumn = Math.round(adminFeatures.length / 3);
      const remainder = adminFeatures.length % 3;

      adminFeatures.forEach((f, i) => {
        adminFeatureCollections[
          i < (remainder === 1 ? adminFeatureMaxRowsPerColumn + 1 : adminFeatureMaxRowsPerColumn)
            ? 0
            : i < (remainder === 1 ? adminFeatureMaxRowsPerColumn + 1 : adminFeatureMaxRowsPerColumn) * 2 - 1
            ? 1
            : 2
        ].push(f);
      });

      featuresParent.forEach((f, i) => {
        if (!f.isAdminOnly) {
          switch (f.featureCategoryTypeId) {
            case FLEET_MANAGEMENT:
              fleetManagementFeatures.push(f);
              break;
            case ACCOUNT_MANAGEMENT:
              accountManagementFeatures.push(f);
              break;
            case ROUTE_OPTIMIZATION:
              routeOptimizationFeatures.push(f);
              break;

            default:
              break;
          }
        }
      });

      return {
        adminFeatureCollections,
        fleetManagementFeatures: orderBy(fleetManagementFeatures, ['name'], ['asc']),
        accountManagementFeatures: orderBy(accountManagementFeatures, ['name'], ['asc']),
        routeOptimizationFeatures: orderBy(routeOptimizationFeatures, ['name'], ['asc']),
      };
    }, [featuresParent]);

  return (
    <form onChange={onChange} noValidate>
      <PanelSection padding="medium no no">
        <PanelSectionGroup padding="no" width="100%">
          <Grid margin="no">
            <GridColumn size="12/12" padding="no">
              <PanelSectionTitle margin="no">{translate('vendors.features')}</PanelSectionTitle>
            </GridColumn>
          </Grid>
        </PanelSectionGroup>
      </PanelSection>
      <PanelSection isLoading={isSaving || isLoading} withBorder padding="medium no medium no">
        <PanelSectionGroup withBorder padding="no medium no no" width="33.33%">
          <PanelSectionSubTitle margin="no no small">{translate('vendors.accountManagement')}</PanelSectionSubTitle>
          <Grid multiLine>
            {accountManagementFeatures.map(feature => (
              <AccountSettingsFeature
                {...feature}
                key={feature.id}
                formValues={formValues}
                openFeatureModal={openFeatureModal}
              />
            ))}
          </Grid>
        </PanelSectionGroup>
        <PanelSectionGroup withBorder padding="no medium" width="33.33%">
          <PanelSectionSubTitle margin="no no small">{translate('vendors.fleetManagement')}</PanelSectionSubTitle>
          <Grid multiLine>
            {fleetManagementFeatures.map(feature => (
              <AccountSettingsFeature
                {...feature}
                key={feature.id}
                formValues={formValues}
                openFeatureModal={openFeatureModal}
              />
            ))}
          </Grid>
        </PanelSectionGroup>
        <PanelSectionGroup padding="no no no medium" width="33.33%">
          <PanelSectionSubTitle margin="no no small">{translate('vendors.routeOptimization')}</PanelSectionSubTitle>
          <Grid multiLine>
            {routeOptimizationFeatures.map(feature => (
              <AccountSettingsFeature
                {...feature}
                key={feature.id}
                formValues={formValues}
                openFeatureModal={openFeatureModal}
              />
            ))}
          </Grid>
        </PanelSectionGroup>
      </PanelSection>
      {!!adminFeatureCollections[0].length && (
        <>
          <PanelSection padding="medium no no">
            <PanelSectionGroup padding="no" width="100%">
              <Grid margin="no">
                <GridColumn size="12/12" padding="no">
                  <PanelSectionTitle margin="no">{translate('vendors.rubiconAdminFeatures')}</PanelSectionTitle>
                </GridColumn>
              </Grid>
            </PanelSectionGroup>
          </PanelSection>
          <PanelSection isLoading={isSaving || isLoading} padding="medium no medium no">
            <Grid multiLine>
              {adminFeatureCollections.map((featureCollection, index) => (
                <GridColumn
                  key={index}
                  borderRight={(index + 1) % 3 !== 0}
                  size="4/12"
                  padding={
                    (index + 1) % 3 === 0 ? 'no no no medium' : index % 3 === 0 ? 'no medium no no' : 'no medium'
                  }
                >
                  {featureCollection.map(feature => (
                    <Grid key={feature.id} multiLine>
                      <AccountSettingsFeature
                        {...feature}
                        formValues={formValues}
                        openFeatureModal={openFeatureModal}
                      />
                    </Grid>
                  ))}
                </GridColumn>
              ))}
            </Grid>
          </PanelSection>
        </>
      )}
    </form>
  );
};

export default reduxForm<FeaturesFormValues, PropsWithoutReduxForm>({
  form: 'accountSettingsFeatures',
  enableReinitialize: true,
})(AccountSettingsFeaturesForm);
