import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadUnassignedJobsRouteNote as doLoadUnassignedJobsRouteNote,
  updateUnassignedJobsRouteNote as doUpdateupdateUnassignedJobsRouteNote,
} from '../../services/dispatchBoardUnassignedJobs';

// Actions
const START_LOAD_ROUTE_NOTE = 'dispatchBoard/unassignedJob/START_LOAD_ROUTE_NOTE';
const FAIL_LOAD_ROUTE_NOTE = 'dispatchBoard/unassignedJob/FAIL_LOAD_ROUTE_NOTE';
const COMPLETE_LOAD_ROUTE_NOTE = 'dispatchBoard/unassignedJob/COMPLETE_LOAD_ROUTE_NOTE';
const START_SAVE_ROUTE_NOTE = 'dispatchBoard/unassignedJob/START_SAVE_ROUTE_NOTE';
const FAIL_SAVE_ROUTE_NOTE = 'dispatchBoard/unassignedJob/FAIL_SAVE_ROUTE_NOTE';
const COMPLETE_SAVE_ROUTE_NOTE = 'dispatchBoard/unassignedJob/COMPLETE_SAVE_ROUTE_NOTE';
const RESET = 'dispatchBoard/unassignedJobRouteNote/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  unassignedJobRouteNote: any;
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  unassignedJobRouteNote: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_ROUTE_NOTE:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedJobRouteNote: action.unassignedJobRouteNote.routeNote,
        },
      });

    case FAIL_LOAD_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE_ROUTE_NOTE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE_ROUTE_NOTE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE_ROUTE_NOTE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoadRouteNote = () => ({
  type: START_LOAD_ROUTE_NOTE,
});

const completeLoadRouteNote = (unassignedJobRouteNote: any) => ({
  type: COMPLETE_LOAD_ROUTE_NOTE,
  unassignedJobRouteNote,
});

const failLoadRouteNote = () => ({
  type: FAIL_LOAD_ROUTE_NOTE,
});

const startSave = () => ({
  type: START_SAVE_ROUTE_NOTE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE_ROUTE_NOTE,
});

const failSave = () => ({
  type: FAIL_SAVE_ROUTE_NOTE,
});

export const loadDispatchBoardUnassignedJobRouteNote = (unassignedJobId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteNote());
  const loadDispatchBoardUnassignedJobNotePromise = doLoadUnassignedJobsRouteNote(unassignedJobId);
  loadDispatchBoardUnassignedJobNotePromise
    .then(unassignedJobRouteNote => dispatch(completeLoadRouteNote(unassignedJobRouteNote)))
    .catch(() => dispatch(failLoadRouteNote()));
  return loadDispatchBoardUnassignedJobNotePromise;
};

export const saveDispatchBoardUnassignedJobRouteNote = (jobId: number, routeNote: string) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveDispatchBoardUnassignedJobRouteNotePromise = doUpdateupdateUnassignedJobsRouteNote(jobId, routeNote);
  saveDispatchBoardUnassignedJobRouteNotePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveDispatchBoardUnassignedJobRouteNotePromise;
};

export const resetDispatchBoardUnassignedJobRouteNote = () => ({
  type: RESET,
});
