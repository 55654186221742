import { AnyAction, Dispatch } from 'redux';
import moment from 'moment';
import update from 'immutability-helper';

import { dateTimeFormat } from 'src/utils/services/validator';
import { SearchType } from 'src/common/interfaces/SearchType';
import {
  AdvancedSearchRoutes,
  AdvancedSearchRoutesResponse,
  GeoFenceIncidentsItem,
  RouteDriversVehiclesItem,
  RouteHistoryItem,
  RouteInformation,
  RoutesDataPayload,
  RoutesListItem,
  RouteStopItem,
  RouteStopsPayload,
  RouteVehiclePosition,
  RouteVehiclesBreadCrumbs,
} from '../interfaces/routesData';
import { VehicleBreadcrumbs } from '../interfaces/vehiclePositions';
import { loadVehicleBreadcrumbs as doLoadVehiclesFullTrackings } from '../services/loadVehiclesList';
import {
  loadAdvancedSearchRoutes as doLoadAdvancedSearchRoutes,
  loadRouteDriversVehicles as doLoadRouteDriversVehicles,
  loadRouteGeoFenceIncidents as doLoadRouteGeoFenceIncidents,
  loadRouteHistory as doLoadRouteHistory,
  loadRouteInformation as doLoadRouteInformation,
  loadRoutesListData as doLoadRoutesList,
  loadRoutesVehiclePositions as doLoadRoutesVehiclePositions,
  loadRouteStops as doLoadRouteStops,
  loadRouteVehiclePositions as doLoadRouteVehiclePositions,
  loadRouteVehiclesBreadCrumbs as doLoadRouteVehiclesBreadCrumbs,
} from '../services/routesData';

// Actions
const START_LOAD_ROUTES_LIST = 'dashboard/routesData/START_LOAD_ROUTES_LIST';
const COMPLETE_LOAD_ROUTES_LIST = 'dashboard/routesData/COMPLETE_LOAD_ROUTES_LIST';
const FAIL_LOAD_ROUTES_LIST = 'dashboard/routesData/FAIL_LOAD_ROUTES_LIST';
const START_LOAD_ROUTES_VEHICLE_POSITIONS = 'dashboard/routesData/START_LOAD_ROUTES_VEHICLE_POSITIONS';
const COMPLETE_LOAD_ROUTES_VEHICLE_POSITIONS = 'dashboard/routesData/COMPLETE_LOAD_ROUTES_VEHICLE_POSITIONS';
const FAIL_LOAD_ROUTES_VEHICLE_POSITIONS = 'dashboard/routesData/FAIL_LOAD_ROUTES_VEHICLE_POSITIONS';
const START_LOAD_ADVANCED_SEARCH_ROUTES = 'dashboard/routesData/START_LOAD_ADVANCED_SEARCH_ROUTES';
const COMPLETE_LOAD_ADVANCED_SEARCH_ROUTES = 'dashboard/routesData/COMPLETE_LOAD_ADVANCED_SEARCH_ROUTES';
const FAIL_LOAD_ADVANCED_SEARCH_ROUTES = 'dashboard/routesData/FAIL_LOAD_ADVANCED_SEARCH_ROUTES';
const RESET_ADVANCED_SEARCH_ROUTES = 'dashboard/routesData/RESET_ADVANCED_SEARCH_ROUTES';
const START_LOAD_ROUTE_INFORMATION = 'dashboard/routesData/START_LOAD_ROUTE_INFORMATION';
const COMPLETE_LOAD_ROUTE_INFORMATION = 'dashboard/routesData/COMPLETE_LOAD_ROUTE_INFORMATION';
const FAIL_LOAD_ROUTE_INFORMATION = 'dashboard/routesData/FAIL_LOAD_ROUTE_INFORMATION';
const START_LOAD_ROUTE_DRIVERS_VEHICLES = 'dashboard/routesData/START_LOAD_ROUTE_DRIVERS_VEHICLES';
const COMPLETE_LOAD_ROUTE_DRIVERS_VEHICLES = 'dashboard/routesData/COMPLETE_LOAD_ROUTE_DRIVERS_VEHICLES';
const FAIL_LOAD_ROUTE_DRIVERS_VEHICLES = 'dashboard/routesData/FAIL_LOAD_ROUTE_DRIVERS_VEHICLES';
const START_LOAD_ROUTE_STOPS = 'dashboard/routesData/START_LOAD_ROUTE_STOPS';
const COMPLETE_LOAD_ROUTE_STOPS = 'dashboard/routesData/COMPLETE_LOAD_ROUTE_STOPS';
const FAIL_LOAD_ROUTE_STOPS = 'dashboard/routesData/FAIL_LOAD_ROUTE_STOPS';
const START_LOAD_GEO_FENCE_INCIDENTS = 'dashboard/routesData/START_LOAD_GEO_FENCE_INCIDENTS';
const COMPLETE_LOAD_GEO_FENCE_INCIDENTS = 'dashboard/routesData/COMPLETE_LOAD_GEO_FENCE_INCIDENTS';
const FAIL_LOAD_GEO_FENCE_INCIDENTS = 'dashboard/routesData/FAIL_LOAD_GEO_FENCE_INCIDENTS';
const RESET_GEO_FENCE_INCIDENTS = 'dashboard/routesData/RESET_GEO_FENCE_INCIDENTS';
const SET_MAP_ROUTE_STOPS = 'dashboard/routesData/SET_MAP_ROUTE_STOPS';
const RESET_MAP_ROUTE_STOPS = 'dashboard/routesData/RESET_MAP_ROUTE_STOPS';
const START_LOAD_ROUTE_VEHICLE_POSITIONS = 'dashboard/routesData/START_LOAD_ROUTE_VEHICLE_POSITIONS';
const FAIL_LOAD_ROUTE_VEHICLE_POSITIONS = 'dashboard/routesData/FAIL_LOAD_ROUTE_VEHICLE_POSITIONS';
const COMPLETE_LOAD_ROUTE_VEHICLE_POSITIONS = 'dashboard/routesData/COMPLETE_LOAD_ROUTE_VEHICLE_POSITIONS';
const RESET_ROUTE_VEHICLE_POSITIONS = 'dashboard/routesData/RESET_ROUTE_VEHICLE_POSITIONS';
const START_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS = 'dashboard/routesData/START_LOAD_ROUTE_VEHICLES_BREADCRUMBS';
const COMPLETE_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS = 'dashboard/routesData/COMPLETE_LOAD_ROUTE_VEHICLES_BREADCRUMBS';
const FAIL_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS = 'dashboard/routesData/FAIL_LOAD_ROUTE_VEHICLES_BREADCRUMBS';
const RESET_ROUTE_VEHICLE_BREAD_CRUMBS = 'dashboard/routesData/RESET_ROUTE_VEHICLE_BREAD_CRUMBS';
const SET_SHOW_APP_STATUS = 'dashboard/routesData/SET_SHOW_APP_STATUS';
const START_LOAD_VEHICLE_FULL_TRACKINGS = 'dashboard/routesData/START_LOAD_VEHICLE_FULL_TRACKINGS';
const COMPLETE_LOAD_VEHICLE_FULL_TRACKINGS = 'dashboard/routesData/COMPLETE_LOAD_VEHICLE_FULL_TRACKINGS';
const FAIL_LOAD_VEHICLE_FULL_TRACKINGS = 'dashboard/routesData/FAIL_LOAD_VEHICLE_FULL_TRACKINGS';
const START_LOAD_ROUTE_HISTORY = 'dashboard/routesData/START_LOAD_ROUTE_HISTORY';
const COMPLETE_LOAD_ROUTE_HISTORY = 'dashboard/routesData/COMPLETE_LOAD_ROUTE_HISTORY';
const FAIL_LOAD_ROUTE_HISTORY = 'dashboard/routesData/FAIL_LOAD_ROUTE_HISTORY';

const RESET = 'dashboard/routesData/RESET';

// initial state
const initialState = {
  advancedSearchRoutes: [] as AdvancedSearchRoutes[],
  geoFenceIncidents: [] as GeoFenceIncidentsItem[],
  isLoadingAdvancedSearchRoutes: false,
  isLoadingGeoFenceIncidents: false,
  isLoadingRouteDriversVehicles: false,
  isLoadingRouteInformation: false,
  isLoadingRoutesList: false,
  isLoadingRouteStops: false,
  isLoadingRouteVehiclePositions: false,
  isLoadingRouteVehiclesBreadCrumbs: false,
  isLoadingVehicleFullTrackings: false,
  isLoadingRouteHistory: false,
  mapRouteStops: [] as RouteStopItem[],
  routeDriversVehicles: [] as RouteDriversVehiclesItem[],
  routeInformation: null as RouteInformation | null,
  routesList: [] as RoutesListItem[],
  routeStops: [] as RouteStopItem[],
  routeVehiclePositions: [] as RouteVehiclePosition[],
  routeVehiclesBreadCrumbs: null as RouteVehiclesBreadCrumbs | null,
  showAppStatus: [] as number[], //vehicle ids to show app status for
  lastRefreshed: '',
  vehicleFullTrackings: null as VehicleBreadcrumbs | null,
  routeHistory: [] as RouteHistoryItem[],
};

// reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_ROUTES_LIST:
      return update(state, {
        $merge: {
          isLoadingRoutesList: true,
        },
      });
    case COMPLETE_LOAD_ROUTES_LIST:
      return update(state, {
        $merge: {
          routesList: action.payload,
          isLoadingRoutesList: false,
          lastRefreshed: action.lastRefreshed,
        },
      });
    case FAIL_LOAD_ROUTES_LIST:
      return update(state, {
        $merge: {
          isLoadingRoutesList: false,
        },
      });
    case START_LOAD_ROUTES_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclePositions: true,
        },
      });
    case COMPLETE_LOAD_ROUTES_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          routeVehiclePositions: action.payload,
          isLoadingRouteVehiclePositions: false,
        },
      });
    case FAIL_LOAD_ROUTES_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclePositions: false,
        },
      });
    case START_LOAD_ADVANCED_SEARCH_ROUTES:
      return update(state, {
        $merge: {
          isLoadingAdvancedSearchRoutes: true,
        },
      });
    case COMPLETE_LOAD_ADVANCED_SEARCH_ROUTES:
      return update(state, {
        $merge: {
          advancedSearchRoutes: action.advancedSearchRoutes.routes,
          isLoadingAdvancedSearchRoutes: false,
        },
      });
    case FAIL_LOAD_ADVANCED_SEARCH_ROUTES:
      return update(state, {
        $merge: {
          isLoadingAdvancedSearchRoutes: false,
        },
      });
    case RESET_ADVANCED_SEARCH_ROUTES:
      return update(state, {
        $merge: {
          advancedSearchRoutes: [],
        },
      });
    case START_LOAD_ROUTE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingRouteInformation: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_INFORMATION:
      return update(state, {
        $merge: {
          routeInformation: action.payload,
          isLoadingRouteInformation: false,
        },
      });
    case FAIL_LOAD_ROUTE_INFORMATION:
      return update(state, {
        $merge: {
          isLoadingRouteInformation: false,
        },
      });
    case START_LOAD_ROUTE_DRIVERS_VEHICLES:
      return update(state, {
        $merge: {
          isLoadingRouteDriversVehicles: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_DRIVERS_VEHICLES:
      return update(state, {
        $merge: {
          routeDriversVehicles: action.payload,
          isLoadingRouteDriversVehicles: false,
        },
      });
    case FAIL_LOAD_ROUTE_DRIVERS_VEHICLES:
      return update(state, {
        $merge: {
          isLoadingRouteDriversVehicles: false,
        },
      });
    case START_LOAD_ROUTE_STOPS:
      return update(state, {
        $merge: {
          isLoadingRouteStops: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_STOPS:
      return update(state, {
        $merge: {
          routeStops: action.payload,
          isLoadingRouteStops: false,
        },
      });
    case FAIL_LOAD_ROUTE_STOPS:
      return update(state, {
        $merge: {
          isLoadingRouteStops: false,
        },
      });
    case START_LOAD_GEO_FENCE_INCIDENTS:
      return update(state, {
        $merge: {
          isLoadingGeoFenceIncidents: true,
        },
      });
    case COMPLETE_LOAD_GEO_FENCE_INCIDENTS:
      return update(state, {
        $merge: {
          geoFenceIncidents: action.payload,
          isLoadingGeoFenceIncidents: false,
          lastRefreshed: action.lastRefreshed,
        },
      });
    case FAIL_LOAD_GEO_FENCE_INCIDENTS:
      return update(state, {
        $merge: {
          isLoadingGeoFenceIncidents: false,
        },
      });
    case RESET_GEO_FENCE_INCIDENTS:
      return update(state, {
        $merge: {
          geoFenceIncidents: [],
        },
      });
    case SET_MAP_ROUTE_STOPS:
      return update(state, {
        $merge: {
          mapRouteStops: action.payload,
        },
      });
    case RESET_MAP_ROUTE_STOPS:
      return update(state, {
        $merge: {
          mapRouteStops: [],
        },
      });
    case START_LOAD_ROUTE_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclePositions: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          routeVehiclePositions: action.payload,
          isLoadingRouteVehiclePositions: false,
          lastRefreshed: action.lastRefreshed,
        },
      });

    case FAIL_LOAD_ROUTE_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclePositions: false,
        },
      });

    case RESET_ROUTE_VEHICLE_POSITIONS:
      return update(state, {
        $merge: {
          routeVehiclePositions: [],
        },
      });

    case START_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclesBreadCrumbs: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS:
      return update(state, {
        $merge: {
          routeVehiclesBreadCrumbs: action.payload,
          isLoadingRouteVehiclesBreadCrumbs: false,
          lastRefreshed: action.lastRefreshed,
        },
      });
    case FAIL_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS:
      return update(state, {
        $merge: {
          isLoadingRouteVehiclesBreadCrumbs: false,
        },
      });
    case RESET_ROUTE_VEHICLE_BREAD_CRUMBS:
      return update(state, {
        $merge: {
          routeVehiclesBreadCrumbs: null,
        },
      });
    case SET_SHOW_APP_STATUS:
      return update(state, {
        $merge: {
          showAppStatus: action.payload,
        },
      });
    case START_LOAD_VEHICLE_FULL_TRACKINGS:
      return update(state, {
        $merge: {
          isLoadingVehicleFullTrackings: true,
        },
      });
    case COMPLETE_LOAD_VEHICLE_FULL_TRACKINGS:
      return update(state, {
        $merge: {
          vehicleFullTrackings: action.payload,
          isLoadingVehicleFullTrackings: false,
        },
      });
    case FAIL_LOAD_VEHICLE_FULL_TRACKINGS:
      return update(state, {
        $merge: {
          isLoadingVehicleFullTrackings: false,
        },
      });

    case START_LOAD_ROUTE_HISTORY:
      return update(state, {
        $merge: {
          isLoadingRouteHistory: true,
        },
      });
    case COMPLETE_LOAD_ROUTE_HISTORY:
      return update(state, {
        $merge: {
          routeHistory: action.payload,
          isLoadingRouteHistory: false,
        },
      });
    case FAIL_LOAD_ROUTE_HISTORY:
      return update(state, {
        $merge: {
          isLoadingRouteHistory: false,
        },
      });

    case RESET:
      // reset except routesList
      return update(state, {
        $merge: {
          geoFenceIncidents: [],
          isLoadingGeoFenceIncidents: false,
          isLoadingRouteDriversVehicles: false,
          isLoadingRouteInformation: false,
          isLoadingRouteStops: false,
          isLoadingRouteVehiclePositions: false,
          isLoadingRouteVehiclesBreadCrumbs: false,
          mapRouteStops: [],
          routeDriversVehicles: [],
          routeInformation: null,
          routeStops: [],
          routeVehiclePositions: [],
          routeVehiclesBreadCrumbs: null,
          showAppStatus: [],
          vehicleFullTrackings: null,
          routeHistory: [],
        },
      });

    default:
      return state;
  }
};

// action creators
const startLoadRoutesList = () => ({
  type: START_LOAD_ROUTES_LIST,
});

const completeLoadRoutesList = (payload: RoutesListItem[]) => ({
  type: COMPLETE_LOAD_ROUTES_LIST,
  payload,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadRoutesList = () => ({
  type: FAIL_LOAD_ROUTES_LIST,
});

const startLoadRoutesVehiclePositions = () => ({
  type: START_LOAD_ROUTES_VEHICLE_POSITIONS,
});

const completeLoadRoutesVehiclePositions = (payload: RouteVehiclePosition[]) => ({
  type: COMPLETE_LOAD_ROUTES_VEHICLE_POSITIONS,
  payload,
});

const failLoadRoutesVehiclePositions = () => ({
  type: FAIL_LOAD_ROUTES_VEHICLE_POSITIONS,
});

const startLoadAdvancedSearchRoutes = () => ({
  type: START_LOAD_ADVANCED_SEARCH_ROUTES,
});

const completeLoadAdvancedSearchRoutes = (advancedSearchRoutes: AdvancedSearchRoutesResponse) => ({
  type: COMPLETE_LOAD_ADVANCED_SEARCH_ROUTES,
  advancedSearchRoutes,
});

const failLoadAdvancedSearchRoutes = () => ({
  type: FAIL_LOAD_ADVANCED_SEARCH_ROUTES,
});

export const resetAdvancedSearchRoutes = () => ({
  type: RESET_ADVANCED_SEARCH_ROUTES,
});

const startLoadRouteInformation = () => ({
  type: START_LOAD_ROUTE_INFORMATION,
});

const completeLoadRouteInformation = (payload: RouteInformation) => ({
  type: COMPLETE_LOAD_ROUTE_INFORMATION,
  payload,
});

const failLoadRouteInformation = () => ({
  type: FAIL_LOAD_ROUTE_INFORMATION,
});

const startLoadRouteDriversVehicles = () => ({
  type: START_LOAD_ROUTE_DRIVERS_VEHICLES,
});

const completeLoadRouteDriversVehicles = (payload: RouteDriversVehiclesItem[]) => ({
  type: COMPLETE_LOAD_ROUTE_DRIVERS_VEHICLES,
  payload,
});

const failLoadRouteDriversVehicles = () => ({
  type: FAIL_LOAD_ROUTE_DRIVERS_VEHICLES,
});

const startLoadRouteStops = () => ({
  type: START_LOAD_ROUTE_STOPS,
});

const completeLoadRouteStops = (payload: RouteStopItem[]) => ({
  type: COMPLETE_LOAD_ROUTE_STOPS,
  payload,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadRouteStops = () => ({
  type: FAIL_LOAD_ROUTE_STOPS,
});

export const resetRoutesData = () => ({
  type: RESET,
});

const startLoadGeoFenceIncidents = () => ({
  type: START_LOAD_GEO_FENCE_INCIDENTS,
});

const completeLoadGeoFenceIncidents = (payload: GeoFenceIncidentsItem[]) => ({
  type: COMPLETE_LOAD_GEO_FENCE_INCIDENTS,
  payload,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadGeoFenceIncidents = () => ({
  type: FAIL_LOAD_GEO_FENCE_INCIDENTS,
});

export const resetGeoFenceIncidents = () => ({
  type: RESET_GEO_FENCE_INCIDENTS,
});

export const setMapRouteStops = (payload: RouteStopItem[]) => ({
  type: SET_MAP_ROUTE_STOPS,
  payload,
});

export const resetMapRouteStops = () => ({
  type: RESET_MAP_ROUTE_STOPS,
});

const startLoadRouteVehiclePositions = () => ({
  type: START_LOAD_ROUTE_VEHICLE_POSITIONS,
});

const completeLoadRouteVehiclePositions = (payload: RouteVehiclePosition[]) => ({
  type: COMPLETE_LOAD_ROUTE_VEHICLE_POSITIONS,
  payload,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadRouteVehiclePositions = () => ({
  type: FAIL_LOAD_ROUTE_VEHICLE_POSITIONS,
});

export const resetRouteVehiclePositions = () => ({
  type: RESET_ROUTE_VEHICLE_POSITIONS,
});

const startLoadRouteVehiclesBreadCrumbs = () => ({
  type: START_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS,
});

const completeLoadRouteVehiclesBreadCrumbs = (payload: RouteVehiclesBreadCrumbs) => ({
  type: COMPLETE_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS,
  payload,
  lastRefreshed: moment().format(dateTimeFormat),
});

const failLoadRouteVehiclesBreadCrumbs = () => ({
  type: FAIL_LOAD_ROUTE_VEHICLES_BREAD_CRUMBS,
});

export const resetRouteVehiclesBreadCrumbs = () => ({
  type: RESET_ROUTE_VEHICLE_BREAD_CRUMBS,
});

export const setShowRouteVehiclesAppStatus = (payload: number[]) => ({
  type: SET_SHOW_APP_STATUS,
  payload,
});

const startLoadVehicleFullTrackings = () => ({
  type: START_LOAD_VEHICLE_FULL_TRACKINGS,
});

const completeLoadVehicleFullTrackings = (payload: VehicleBreadcrumbs) => ({
  type: COMPLETE_LOAD_VEHICLE_FULL_TRACKINGS,
  payload,
});

const failLoadVehicleFullTrackings = () => ({
  type: FAIL_LOAD_VEHICLE_FULL_TRACKINGS,
});

const startLoadRouteHistory = () => ({
  type: START_LOAD_ROUTE_HISTORY,
});

const completeLoadRouteHistory = (payload: RouteHistoryItem[]) => ({
  type: COMPLETE_LOAD_ROUTE_HISTORY,
  payload,
});

const failLoadRouteHistory = () => ({
  type: FAIL_LOAD_ROUTE_HISTORY,
});

// thunks
export const loadRoutesList = (payload: RoutesDataPayload) => (dispatch: Dispatch) => {
  dispatch(startLoadRoutesList());
  return doLoadRoutesList(payload)
    .then(data => dispatch(completeLoadRoutesList(data?.routes.filter((route: RoutesListItem) => !!route.id))))
    .catch(() => dispatch(failLoadRoutesList()));
};

export const loadRoutesVehiclePositions = (payload: RoutesDataPayload) => (dispatch: Dispatch) => {
  dispatch(startLoadRoutesVehiclePositions());
  return doLoadRoutesVehiclePositions(payload)
    .then(data => dispatch(completeLoadRoutesVehiclePositions(data?.vehicles)))
    .catch(() => dispatch(failLoadRoutesVehiclePositions()));
};

export const loadAdvancedSearchRoutes =
  (date: Date | string, searchField: SearchType, searchCriteria: string) => (dispatch: Dispatch) => {
    dispatch(startLoadAdvancedSearchRoutes());
    return doLoadAdvancedSearchRoutes(date, searchField, searchCriteria)
      .then((response: AdvancedSearchRoutesResponse) => dispatch(completeLoadAdvancedSearchRoutes(response)))
      .catch(() => dispatch(failLoadAdvancedSearchRoutes()));
  };

export const loadRouteInformation = (routeId: number, date: string | Date) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteInformation());
  return doLoadRouteInformation(routeId, date)
    .then(data => dispatch(completeLoadRouteInformation(data)))
    .catch(() => dispatch(failLoadRouteInformation()));
};

export const loadRouteDriversVehicles = (routeId: number, date: string | Date) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteDriversVehicles());

  const loadRouteDriversVehiclesPromise = doLoadRouteDriversVehicles(routeId, date);
  loadRouteDriversVehiclesPromise
    .then(data => dispatch(completeLoadRouteDriversVehicles(data?.vehicles)))
    .catch(() => dispatch(failLoadRouteDriversVehicles()));

  return loadRouteDriversVehiclesPromise;
};

export const loadRouteStops = (payload: RouteStopsPayload) => (dispatch: Dispatch) => {
  dispatch(startLoadRouteStops());
  return doLoadRouteStops(payload)
    .then(data => dispatch(completeLoadRouteStops(data?.stops)))
    .catch(() => dispatch(failLoadRouteStops()));
};

export const loadRouteGeoFenceIncidents =
  (routeId: number, date: string | Date, vehicleInsightTypes?: string) => (dispatch: Dispatch) => {
    dispatch(startLoadGeoFenceIncidents());
    return doLoadRouteGeoFenceIncidents(routeId, date, vehicleInsightTypes)
      .then(data => dispatch(completeLoadGeoFenceIncidents(data)))
      .catch(() => dispatch(failLoadGeoFenceIncidents()));
  };

export const loadRouteVehiclePositions =
  (routeId: number, date: string | Date, vehicleIds?: string, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    !noLoadingIndicator && dispatch(startLoadRouteVehiclePositions());
    return doLoadRouteVehiclePositions(routeId, date, vehicleIds)
      .then(data => dispatch(completeLoadRouteVehiclePositions(data?.vehiclePositions)))
      .catch(() => dispatch(failLoadRouteVehiclePositions()));
  };

export const loadRouteVehiclesBreadCrumbs =
  (routeId: number, date: string | Date, vehicleIds?: string, noLoadingIndicator?: boolean) => (dispatch: Dispatch) => {
    if (!noLoadingIndicator) dispatch(startLoadRouteVehiclesBreadCrumbs());
    return doLoadRouteVehiclesBreadCrumbs(routeId, date, vehicleIds)
      .then(data => dispatch(completeLoadRouteVehiclesBreadCrumbs(data)))
      .catch(() => dispatch(failLoadRouteVehiclesBreadCrumbs()));
  };

export const loadVehiclesFullTrackings = (vehicleId: number, date: Date | string) => (dispatch: Dispatch) => {
  dispatch(startLoadVehicleFullTrackings());
  return doLoadVehiclesFullTrackings(vehicleId, date)
    .then(data => dispatch(completeLoadVehicleFullTrackings(data)))
    .catch(() => dispatch(failLoadVehicleFullTrackings()));
};

export const loadRouteHistory =
  (routeId: number, startDate?: Date | string, endDate?: Date | string) => (dispatch: Dispatch) => {
    dispatch(startLoadRouteHistory());
    return doLoadRouteHistory(routeId, startDate, endDate)
      .then(data => dispatch(completeLoadRouteHistory(data)))
      .catch(() => dispatch(failLoadRouteHistory()));
  };
