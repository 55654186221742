import React, { MouseEvent } from 'react';

import moment from 'moment';

import { ActionButtonTooltip } from '../../../core/components';
import { TableActionButton, TableCell, TableRow } from '../../../core/components/styled';
import { USER_ROLES } from '../../constants';
import translate from '../../../core/services/translate';
import { isSingleSignOnFeatureEnabled } from 'src/vendors/ducks/features';
import { useSelector } from 'src/core/hooks/useSelector';

interface Props {
  editUser: (event: MouseEvent, isRubiconSuperAdmin: boolean, userId: string) => void;
  deleteUser: (userId: string, event: MouseEvent) => void;
  email: string;
  isAccountActive: boolean;
  isRubiconSuperAdmin: boolean;
  lastLoginDateTime: string;
  name: string;
  resetUserPassword: (event: MouseEvent, userId: string) => void;
  roleId: number;
  userId: string;
}

const UsersPageTableRow: React.FC<Props> = ({
  deleteUser,
  editUser,
  email,
  isAccountActive,
  isRubiconSuperAdmin,
  lastLoginDateTime,
  name,
  resetUserPassword,
  roleId,
  userId,
}) => {
  const isSingleSignOnEnabled = useSelector(isSingleSignOnFeatureEnabled);
  return (
    <TableRow>
      <TableCell width="20%">{name}</TableCell>

      <TableCell width="30%">{email}</TableCell>

      <TableCell width="10%">{USER_ROLES[roleId].name}</TableCell>

      <TableCell width="10%">{isAccountActive ? translate('common.yes') : translate('common.no')}</TableCell>

      <TableCell width="20%">
        {lastLoginDateTime ? moment(lastLoginDateTime).format('hh:mm A MM/DD/YYYY') : '-'}
      </TableCell>

      <TableCell align="right" width="10%">
        {!isSingleSignOnEnabled && (
          <TableActionButton onClick={event => resetUserPassword(event, userId)}>
            <ActionButtonTooltip
              icon="reset-password"
              tooltip="resetUserPassword"
              iconSize="resetPasswordSize"
              customViewBox="0 0 24 19"
            />
          </TableActionButton>
        )}

        <TableActionButton onClick={event => editUser(event, isRubiconSuperAdmin, userId)}>
          <ActionButtonTooltip icon="edit" tooltip="editUser" />
        </TableActionButton>

        <TableActionButton onClick={event => deleteUser(userId, event)}>
          <ActionButtonTooltip icon="delete" tooltip="deleteUser" />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
};

export default UsersPageTableRow;
