import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { AccountPage, AccountPanel, AccountPanelLogo, AccountPanelTitle, AccountPanelDescription } from '../styled';
import { ClickMe } from '../styled/AccountPage';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { ResetPasswordForm } from '../forms';
import { resetPassword, resetResetPassword, requestPasswordReset } from '../../ducks';
import translate from '../../../core/services/translate';
interface Props extends RouteComponentProps {
  isResettingPassword: boolean;
  isResetPasswordFailed: boolean;
  isResetPasswordFailedMessage: string | null;
  isValidToken: boolean;
  resetPassword: DuckFunction<typeof resetPassword>;
  resetResetPassword: DuckAction<typeof resetResetPassword>;
  requestPasswordReset: DuckFunction<typeof requestPasswordReset>;
}

class ResetPasswordPage extends PureComponent<Props> {
  componentWillUnmount() {
    this.props.resetResetPassword();
  }

  onSubmit = ({ password }: { password: string }) => {
    const { resetPassword, location } = this.props;
    const { email, code: token } = parse(location.search.substr(1));

    resetPassword(token as string, email as string, password);
  };

  onRequestPasswordResetClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { requestPasswordReset, location } = this.props;
    const { email } = getQueryParams(location.search);
    requestPasswordReset(email);
  };

  render() {
    const { isValidToken, location } = this.props;
    const { isResetPasswordFailed, isResetPasswordFailedMessage } = this.props;
    const isCreatePassword = /\/account\/create-password/.test(location.pathname);

    return (
      <>
        <DocumentTitle>
          {isCreatePassword ? translate('account.createYourPassword') : translate('account.resetYourPassword')}
        </DocumentTitle>
        <AccountPage>
          {isValidToken ? (
            <AccountPanel isLoading={this.props.isResettingPassword}>
              <ResetPasswordForm
                isResetPasswordFailed={isResetPasswordFailed}
                isResetPasswordFailedMessage={isResetPasswordFailedMessage}
                isCreatePassword={isCreatePassword}
                onSubmit={this.onSubmit}
              />
            </AccountPanel>
          ) : (
            <AccountPanel>
              <AccountPanelLogo />
              <AccountPanelTitle>{translate('account.invalidLinkTitle')}</AccountPanelTitle>
              <AccountPanelDescription>
                {translate('account.invalidLinkMessage1')}
                <ClickMe onClick={event => this.onRequestPasswordResetClick(event)}>
                  {translate('account.clickHere')}
                </ClickMe>
                {translate('account.invalidLinkMessage2')}
              </AccountPanelDescription>
            </AccountPanel>
          )}
        </AccountPage>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isResetPasswordFailed: state.account.resetPassword.isResetPasswordFailed,
  isResettingPassword: state.account.resetPassword.isResettingPassword,
  isResetPasswordFailedMessage: state.account.resetPassword.isResetPasswordFailedMessage,
  isValidToken: state.account.validateResetPasswordToken.isValidToken,
  location: state.router.location,
});

const mapDispatchToProps = {
  resetPassword,
  resetResetPassword,
  requestPasswordReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
