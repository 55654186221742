import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { DuckFunction } from '../../../../../contracts/ducks';
import { Resolver } from '../../../../../core/components';
import { PanelSectionLoading } from '../../../../../core/components/styled';
import { loadTriggers } from '../../../../ducks';
import TriggerMap from './TriggerMap';

interface TriggerMapResolverProps {
  routeId: number;
  loadTriggers: DuckFunction<typeof loadTriggers>;
  onLoadTab?: () => void;
}

class TriggerMapResolver extends PureComponent<TriggerMapResolverProps> {
  loadDependencies = () => {
    const { routeId, loadTriggers } = this.props;
    return loadTriggers(routeId);
  };

  render() {
    return (
      <Resolver
        loadingComponent={PanelSectionLoading}
        resolve={this.loadDependencies}
        successComponent={TriggerMap}
        successProps={{
          onLoadTab: this.props.onLoadTab,
          routeId: this.props.routeId,
        }}
      />
    );
  }
}

const mapDispatchToProps = { loadTriggers };

export default connect(undefined, mapDispatchToProps)(TriggerMapResolver);
