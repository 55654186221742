import React, { ChangeEvent } from 'react';
import moment from 'moment';

import { UnconnectedCheckbox } from '../../../../core/components';
import { TableCell, TableRow } from '../../../../core/components/styled';

interface Props {
  id: number;
  createdOn: string;
  serviceRequestId: boolean;
  error: number;
  isChecked: boolean;
  checkConfigError: (id: number, e: ChangeEvent<HTMLInputElement>) => void;
}

const ServiceRequestErrorsTableRow: React.FC<Props> = ({
  checkConfigError,
  id,
  createdOn,
  isChecked,
  serviceRequestId,
  error,
}) => (
  <TableRow>
    <TableCell width="5%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
      <UnconnectedCheckbox
        block
        checked={isChecked}
        onChange={e => checkConfigError(id, e)}
        onClick={e => e.stopPropagation()}
        id={`config-error-${id}-checkbox`}
      />
    </TableCell>
    <TableCell width="25%">{moment(createdOn).format('MM/DD/YYYY')}</TableCell>
    <TableCell width="25%">{serviceRequestId}</TableCell>
    <TableCell width="45%">{error}</TableCell>
  </TableRow>
);

export default ServiceRequestErrorsTableRow;
