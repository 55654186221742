import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import doUpdateReportsEmails from '../services/updateReportsEmails';

// Actions
const START_UPDATE_EMAILS = 'insights/reportsList/START_UPDATE_EMAILS';
const COMPLETE_UPDATE_EMAILS = 'insights/reportsList/COMPLETE_UPDATE_EMAILS';
const FAIL_UPDATE_EMAILS = 'insights/reportsList/FAIL_UPDATE_EMAILS';

// Initial state
const initialState = {
  isUpdating: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_UPDATE_EMAILS:
      return update(state, {
        $merge: {
          isUpdating: true,
        },
      });

    case COMPLETE_UPDATE_EMAILS:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    case FAIL_UPDATE_EMAILS:
      return update(state, {
        $merge: {
          isUpdating: false,
        },
      });

    default:
      return state;
  }
};

const startUpdate = () => ({
  type: START_UPDATE_EMAILS,
});

const completeUpdate = () => ({
  type: COMPLETE_UPDATE_EMAILS,
});

const failUpdate = () => ({
  type: FAIL_UPDATE_EMAILS,
});

export const updateReportsEmails =
  (vendorId: number, reportTypeId: number, emailAddresses: string[]) => (dispatch: Dispatch) => {
    dispatch(startUpdate());
    const updateReportsEmailsPromise = doUpdateReportsEmails(vendorId, reportTypeId, emailAddresses);
    updateReportsEmailsPromise
      .then(() => {
        dispatch(completeUpdate());
      })
      .catch(() => dispatch(failUpdate()));
    return updateReportsEmailsPromise;
  };
