import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, setTriggerMapViewport } from 'src/routes/ducks/mapControls';
import {
  ComplexMapControl,
  MapLegend,
  MapLegendInfo,
  MapLegendInfoBullet,
} from 'src/routes/components/styled/RouteMap';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getMapBounds } from 'src/common/components/map/util';
import { Icon } from 'src/core/components';
import { MapGL } from 'src/common/components/map/MapGL';
import { Message, Text } from 'src/core/components/styled';
import { TRIGGERS_MAP_COLORS_LIST } from 'src/routes/constants/triggerMap';
import { useSelector } from 'src/core/hooks/useSelector';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import translate from 'src/core/services/translate';
import TriggerGL from './TriggerGL';
import TriggerMapClustersGL from './TriggerMapClustersGL';

const TriggerMapGL = () => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<mapboxgl.Map>();
  const [isLegendCollapsed, setIsLegendCollapsed] = useState<boolean>(false);

  const { mapTriggerViewport } = useSelector(state => state.routes.mapControls);
  const { triggers } = useSelector(state => state.routes.triggers);

  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });

  const vendorId = useSelector(currentVendorId);
  const dragPan = useSelector(s => s.routes.routeMapSettings.dragPan);

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const features = map.queryRenderedFeatures(event.point).filter(feature => !!feature.properties?.cluster_id);
        if (features.length) {
          dispatch(clearRouteMapSelectedFeature());
        }
      });
      map.on('mousemove', event => {
        const features = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch]);

  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    triggers.forEach(({ latitude, longitude }) => {
      points.push({ latitude: latitude, longitude: longitude });
    });

    if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      dispatch(setTriggerMapViewport(bounds));
    } else if (!!points.length) {
      const bounds = getMapBounds(points, {
        capZoom: 16,
      });
      dispatch(setTriggerMapViewport(bounds));
    }
  }, [dispatch, map, mapViewStyle.mapCenter, mapViewStyle.mapZoom, triggers, vendorId]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: map?.getCenter(),
      mapZoom: map?.getZoom(),
    });
  };

  return (
    <MapGLWrapper>
      {!!triggers.length ? (
        <MapGL
          dragPan={dragPan}
          disableDefaultSatelliteView
          enableNewSatelliteView
          disableDefaultNavigationControl
          enableNewNavigationControl
          viewport={mapTriggerViewport}
          onMapRefLoaded={setMap}
          setIsSatelliteViewEnabled={handleMapViewStyleChange}
        >
          <ComplexMapControl vertical position="bottom-left" xOffset={50} zIndex={1}>
            <MapLegend collapsed={isLegendCollapsed} onClick={() => setIsLegendCollapsed(!isLegendCollapsed)}>
              <b>{translate('routes.snowPlow.legend')}</b>
              <Icon icon="chevronDown" />
              {isLegendCollapsed && (
                <>
                  {TRIGGERS_MAP_COLORS_LIST.map((item: any, index) => (
                    <MapLegendInfo position="relative" key={index}>
                      <MapLegendInfoBullet color={item.color} />
                      <Text margin="no no no small">{item.iconType}</Text>
                    </MapLegendInfo>
                  ))}
                </>
              )}
            </MapLegend>
          </ComplexMapControl>
          {map && (
            <>
              <TriggerMapClustersGL map={map} triggers={triggers} />
              <TriggerGL map={map} triggers={triggers} />
            </>
          )}
        </MapGL>
      ) : (
        <Message padding="sMedium">{translate('routes.noTriggers')}</Message>
      )}
    </MapGLWrapper>
  );
};

export default TriggerMapGL;
