import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PageLoading } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadFeatures } from 'src/vendors/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import Finance from './Finance';

const FinanceResolver = () => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);

  const loadDependencies = useCallback(() => {
    const dependencies = [];

    if (vendorId) {
      dependencies.push(loadFeatures(vendorId)(dispatch));
    }

    return Promise.all(dependencies);
  }, [dispatch, vendorId]);

  return <TypedResolver successComponent={Finance} loadingComponent={PageLoading} resolve={loadDependencies} />;
};

export default FinanceResolver;
