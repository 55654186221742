import { Field, InjectedFormProps, formValueSelector, reduxForm, submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Checkbox, Dropdown, FileInput, Input, TextArea } from 'src/core/components';
import {
  Button,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  PanelSectionGroup,
  PanelSectionSubTitle,
  Text,
  ModalFixedFooter,
  Link,
} from 'src/core/components/styled';
import { createErrorNotificationIncludingTechnicalMessage } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { http } from 'src/core/services/http';
import { isRequired, minValueNumeric0 } from 'src/utils/services/validator';
import { LogoImageContainer, LogoImageThumbnail } from '../styled/LogoImage';
import { useSelector } from 'src/core/hooks/useSelector';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';

const allowedFileTypes = '.jpg,.jpeg,.png';

const CurrencyContainer = styled.div<{ top?: string }>`
  position: relative;
  top: 25px;
  left: 9px;

  ${props =>
    props.top &&
    css`
      top: ${props.top};
    `};
`;

const PercentageContainer = styled.div<{ top?: string }>`
  position: relative;
  top: 25px;
  left: -44px;

  ${props =>
    props.top &&
    css`
      top: ${props.top};
    `};
`;

export const BILLING_MODULE_INVOICE_DETAILS_FORM = 'BillingModuleInvoiceDetails';
export const FEE_AMOUNT: { [key: string]: number } = {
  AMOUNT: 1,
  PERCENTAGE: 2,
};

const formSelector = formValueSelector(BILLING_MODULE_INVOICE_DETAILS_FORM);

interface ComponentProps {
  onCancel(pristine: boolean): void;
  handleSave(isFormValid: boolean): void;
  setEmailModal(flag: boolean): void;
  previewPDF(): void;
  isLoading: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

const BillingModuleInvoiceDetailsForm = ({
  handleSubmit,
  handleSave,
  onCancel,
  pristine,
  previewPDF,
  setEmailModal,
  isLoading,
  valid,
}: Props) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const feeStructureOptions = useSelector(state => state.common.feeStructure.feeStructure);
  const feeStructureValue = useSelector(state => formSelector(state, 'lateFeeSetting.feeStructure'));
  const useLateFeesValue = useSelector(state => formSelector(state, 'useLateFees'));
  const displayPaymentRemitValue = useSelector(state => formSelector(state, 'emailOptions.displayPaymentRemit'));
  const displayManualPaymentRemitValue = useSelector(state => formSelector(state, 'pdfOptions.showManualRemitInfo'));
  const displayAdditionalEmailLanguageValue = useSelector(state =>
    formSelector(state, 'emailOptions.displayAdditionalEmailLanguage'),
  );
  const showAdditionalLanguageValue = useSelector(state => formSelector(state, 'pdfOptions.showAdditionalLanguage'));
  const showAutoRemitAdditionalLanguage = useSelector(state =>
    formSelector(state, 'pdfOptions.showAutoRemitAdditionalLanguage'),
  );

  const [isLogoLoading, setIsLogoLoading] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const vendorLogoFileId = useSelector(state => formSelector(state, 'pdfOptions.vendorLogoFileId'));
  const vendorLogoFileName = useSelector(state => formSelector(state, 'pdfOptions.vendorLogoFileName'));
  const vendorLogoFileContent = useSelector(state => formSelector(state, 'pdfOptions.vendorLogoFileContent'));
  const showManualRemitAdditionalLanguage = useSelector(state =>
    formSelector(state, 'pdfOptions.showManualRemitAdditionalLanguage'),
  );

  const rateAccountingCodes = useSelector((state: AppState) => state.common.rateAccountingCodes.rateAccountingCodes);
  const rateCodeTypes = useSelector((state: AppState) => state.common.rateCodeTypes.rateCodes);

  const openEmailModal = () => {
    dispatch(submit(BILLING_MODULE_INVOICE_DETAILS_FORM));
    if (valid) {
      setEmailModal(true);
    }
  };

  useEffect(() => {
    if (vendorLogoFileId) {
      setIsLogoLoading(true);
      http
        .get(`/vendor/${vendorId}/files/${vendorLogoFileId}`)
        .then(response => {
          setLogoUrl(response.data.url);
          setIsLogoLoading(false);
        })
        .catch(error => {
          setIsLogoLoading(false);
          createErrorNotificationIncludingTechnicalMessage(error, translate('common.alertMessages.feedbackSentFail'));
        });
    }
  }, [vendorLogoFileId, vendorId]);

  return (
    <>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <form onSubmit={handleSubmit} noValidate>
        <PanelSectionGroup isLoading={isLoading} vertical padding="small small no small" width="100%">
          <Grid multiLine margin="no">
            <GridColumn size="12/12" padding="small no small xSmall">
              <PanelSectionSubTitle margin="no no xSmall no">
                <b>{translate('common.options')}</b>
              </PanelSectionSubTitle>
            </GridColumn>

            <GridColumn size="12/12">
              <Field
                component={Checkbox}
                name={'useLateFees'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.addLateFee'),
                }}
              />
            </GridColumn>

            <GridColumn size="4/12" padding="small small xxSmall xSmall">
              <Field
                name="lateFeeSetting.rateCodeId"
                validate={useLateFeesValue ? [isRequired] : []}
                component={Dropdown}
                label={translate('vendors.lateFeeRateCode')}
                options={rateCodeTypes}
                disabled={!useLateFeesValue}
              />
            </GridColumn>
            <GridColumn size="4/12" padding="small small xxSmall xSmall">
              <Field
                name="lateFeeSetting.rateAccountingCodeId"
                validate={useLateFeesValue ? [isRequired] : []}
                component={Dropdown}
                label={translate('vendors.accountingCode')}
                options={rateAccountingCodes}
                disabled={!useLateFeesValue}
              />
            </GridColumn>

            <GridColumn size="4/12" padding="small small xxSmall xSmall">
              <Field
                name="lateFeeSetting.feeStructure"
                validate={useLateFeesValue ? [isRequired] : []}
                component={Dropdown}
                label={translate('vendors.feeStructure')}
                options={feeStructureOptions}
                disabled={!useLateFeesValue}
              />
            </GridColumn>

            {feeStructureValue === FEE_AMOUNT.AMOUNT && (
              <>
                <CurrencyContainer top="40px">$</CurrencyContainer>
                <GridColumn size="3/12" padding="small small xxSmall xSmall">
                  <Field
                    name="lateFeeSetting.feeAmount"
                    component={Input}
                    label={translate('customers.rubiconService.amount')}
                    validate={useLateFeesValue ? [minValueNumeric0, isRequired] : []}
                    disabled={!useLateFeesValue}
                    type="number"
                  />
                </GridColumn>
              </>
            )}
            {feeStructureValue === FEE_AMOUNT.PERCENTAGE && (
              <>
                <GridColumn size="3/12" padding="small small xxSmall xSmall">
                  <Field
                    name="lateFeeSetting.feeAmount"
                    component={Input}
                    label={translate('vendors.percentage')}
                    validate={useLateFeesValue ? [minValueNumeric0, isRequired] : []}
                    disabled={!useLateFeesValue}
                    type="number"
                  />
                </GridColumn>
                <PercentageContainer top="40px">%</PercentageContainer>
              </>
            )}

            <GridColumn size="4/12" padding="small small xxSmall xSmall">
              <Field
                name={`lateFeeSetting.gracePeriodInDays`}
                label={translate('vendors.gracePeriodDays')}
                component={Input}
                type="number"
                validate={useLateFeesValue ? [minValueNumeric0, isRequired] : []}
                disabled={!useLateFeesValue}
              />
            </GridColumn>

            <GridColumn size="12/12">
              <Field
                component={Checkbox}
                name={'useExceptionCharges'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.addExceptionChargesMsg'),
                }}
              />
            </GridColumn>

            <GridColumn size="12/12" margin="small no xSmall no">
              <Field
                component={Checkbox}
                name={'passProcessingFee'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.passProcessingFeeToCustomer'),
                }}
              />
            </GridColumn>

            <GridColumn size="12/12" margin="small no xSmall no">
              <Link
                to="/finance/rate-configuration"
                color="primary"
                id="rateCodeConfiguration"
                target="_blank" 
                rel="noopener noreferrer"
              >
                {translate('vendors.rateCodeConfiguration')}
              </Link>
            </GridColumn>


            <GridColumn size="12/12" margin="medium no xSmall no">
              <PanelSectionSubTitle margin="no no xSmall no">
                {translate('vendors.paymentRemitToInfo')}
              </PanelSectionSubTitle>
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                name="remitContactName"
                validate={displayPaymentRemitValue || displayManualPaymentRemitValue ? [isRequired] : []}
                component={Input}
                label={translate('customers.mailToMe')}
                margin="no"
              />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                validate={displayPaymentRemitValue || displayManualPaymentRemitValue ? [isRequired] : []}
                name="remitAddress.formattedAddress"
                component={Input}
                label={translate('customers.streetName')}
                margin="small no no no"
              />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                name="remitAddress.city"
                validate={displayPaymentRemitValue || displayManualPaymentRemitValue ? [isRequired] : []}
                component={Input}
                label={translate('customers.city')}
                margin="small no no no"
              />
            </GridColumn>
            <GridColumn size="8/12">
              <Field
                name="remitAddress.state"
                validate={displayPaymentRemitValue || displayManualPaymentRemitValue ? [isRequired] : []}
                component={Input}
                label={translate('customers.state')}
                margin="small no no no"
              />
            </GridColumn>
            <GridColumn size="4/12">
              <Field
                name="remitAddress.zip"
                validate={displayPaymentRemitValue || displayManualPaymentRemitValue ? [isRequired] : []}
                component={Input}
                label={translate('customers.zip')}
                margin="small no no no"
              />
            </GridColumn>

            <GridColumn size="12/12" padding="medium no small xSmall">
              <PanelSectionSubTitle margin="no">{translate('vendors.emailOptions')}</PanelSectionSubTitle>
            </GridColumn>
            <GridColumn margin="no no no no" size="9/12">
              <Field
                name={'emailOptions.fromEmailAddress'}
                component={Input}
                label={translate('vendors.electronicInvoiceFrom')}
                margin="no"
                validate={[isRequired]}
              />
            </GridColumn>
            <GridColumn size="3/12" padding="no small small no" align="right">
              <Button type="button" onClick={() => openEmailModal()} line color="primary">
                {translate('vendors.previewEmail')}
              </Button>
            </GridColumn>
            <GridColumn margin="small no small no" size="12/12">
              <Field
                component={Checkbox}
                name={'emailOptions.displayPaymentRemit'}
                props={{
                  block: true,
                  margin: 'xSmall no no no',
                  size: 'medium',
                  label: translate('vendors.displayPaymentRemitInfo'),
                }}
              />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                component={Checkbox}
                name={'emailOptions.displayAdditionalEmailLanguage'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.additionalEmailLanguage'),
                }}
              />
            </GridColumn>
            <GridColumn margin="sMedium no no no" size="12/12">
              <Field
                name={'emailOptions.additionalEmailLanguage'}
                component={TextArea}
                rows={4}
                label={translate('vendors.additionalEmailLanguage')}
                size="large"
                margin="no"
                validate={displayAdditionalEmailLanguageValue ? [isRequired] : []}
                disabled={!displayAdditionalEmailLanguageValue}
              />
            </GridColumn>

            <GridColumn size="12/12" padding="medium no small xSmall">
              <PanelSectionSubTitle margin="no">
                <b>{translate('vendors.pdfInvoiceDetails')}</b>
              </PanelSectionSubTitle>
            </GridColumn>

            <GridColumn size="12/12" padding="small small small xSmall">
              <PanelSectionSubTitle margin="no">{translate('vendors.generalInfo')}</PanelSectionSubTitle>
            </GridColumn>

            <GridColumn size="6/12">
              <Field
                name="pdfOptions.vendorLogoFileContent"
                component={FileInput}
                type="text"
                accept={allowedFileTypes}
                validate={[]}
                margin="sMedium no sMedium no"
              />
            </GridColumn>
            <GridColumn size="3/12" padding="no no no no">
              <LogoImageContainer isLoading={isLogoLoading}>
                {!vendorLogoFileContent && logoUrl && (
                  <>
                    <LogoImageThumbnail src={logoUrl} alt="vendorLogo" />
                    <Text
                      wordWrap="anywhere"
                      align="center"
                      doubleLine
                      minWidth="150"
                      margin="xSmall no no no"
                      size="small"
                    >
                      {vendorLogoFileName}
                    </Text>
                  </>
                )}
                {vendorLogoFileContent &&
                  vendorLogoFileContent?.length > 0 &&
                  vendorLogoFileContent instanceof FileList && (
                    <>
                      <LogoImageThumbnail src={URL.createObjectURL(vendorLogoFileContent[0])} alt="vendorLogo" />
                      <Text
                        wordWrap="anywhere"
                        align="center"
                        doubleLine
                        minWidth="150"
                        margin="xSmall no no no"
                        size="small"
                      >
                        {vendorLogoFileContent[0].name}
                      </Text>
                    </>
                  )}
              </LogoImageContainer>
            </GridColumn>
            <GridColumn size="3/12" padding="no small no no" align="right">
              <Button type="button" onClick={previewPDF} line color="primary">
                {translate('vendors.previewInvoice')}
              </Button>
            </GridColumn>

            <GridColumn size="12/12" padding="small small small xSmall">
              <PanelSectionSubTitle margin="no">
                {translate('vendors.electronicPaymentRemitToInfo')}
              </PanelSectionSubTitle>
            </GridColumn>
            <GridColumn margin="small no small no" size="12/12">
              <Field
                component={Checkbox}
                name={'pdfOptions.showAutoRemitBankInfo'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.displayBankingDetails'),
                }}
              />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                component={Checkbox}
                name={'pdfOptions.showAutoRemitAdditionalLanguage'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.displayAdditionalE'),
                }}
              />
            </GridColumn>

            <GridColumn margin="medium no small no" size="12/12">
              <Field
                name={'pdfOptions.autoRemitAdditionalLanguageText'}
                component={TextArea}
                noTransform
                rows={4}
                label={translate('vendors.additionalLanguage')}
                placeholder={translate('vendors.additionalLanguageText')}
                size="large"
                validate={showAutoRemitAdditionalLanguage ? [isRequired] : []}
                disabled={!showAutoRemitAdditionalLanguage}
                margin="no"
              />
            </GridColumn>

            <GridColumn size="12/12" padding="small no small xSmall">
              <PanelSectionSubTitle margin="no">{translate('vendors.manualRemitToInfo')}</PanelSectionSubTitle>
            </GridColumn>
            <GridColumn margin="small no small no" size="12/12">
              <Field
                component={Checkbox}
                name={'pdfOptions.showManualRemitInfo'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.displayManualPaymentRemitInfo'),
                }}
              />
            </GridColumn>
            <GridColumn size="12/12">
              <Field
                component={Checkbox}
                name={'pdfOptions.showManualRemitAdditionalLanguage'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.pmAdditionalLanguage'),
                }}
              />
            </GridColumn>

            <GridColumn margin="medium no small no" size="12/12">
              <Field
                name={'pdfOptions.manualRemitAdditionalLanguageText'}
                component={TextArea}
                noTransform
                rows={4}
                placeholder={translate('vendors.remitAdditionalLanguage')}
                label={translate('vendors.additionalLanguage')}
                size="large"
                validate={showManualRemitAdditionalLanguage ? [isRequired] : []}
                disabled={!showManualRemitAdditionalLanguage}
                margin="no"
              />
            </GridColumn>

            <GridColumn margin="small no no no" size="12/12">
              <Field
                component={Checkbox}
                name={'pdfOptions.showAdditionalLanguage'}
                props={{
                  block: true,
                  margin: 'no',
                  size: 'medium',
                  label: translate('vendors.displayAdInvoice'),
                }}
              />
            </GridColumn>
            <GridColumn margin="sMedium no small no" size="12/12">
              <Field
                name={'pdfOptions.additionalLanguageText'}
                component={TextArea}
                noTransform
                rows={5}
                placeholder={translate('vendors.pdfAdditional')}
                label={translate('vendors.additionalLanguage')}
                size="large"
                disabled={!showAdditionalLanguageValue}
                validate={showAdditionalLanguageValue ? [isRequired] : []}
                margin="no"
              />
            </GridColumn>
          </Grid>
        </PanelSectionGroup>

        <ModalFixedFooter isShadowed>
          <Button type="button" color="secondary" margin="no xSmall" onClick={() => onCancel(pristine)}>
            {translate('common.cancel')}
          </Button>
          <Button type="submit" color="primary" onClick={() => handleSave(valid)}>
            {translate('common.save')}
          </Button>
        </ModalFixedFooter>
      </form>
    </>
  );
};

export default reduxForm<any, ComponentProps>({
  form: BILLING_MODULE_INVOICE_DETAILS_FORM,
  enableReinitialize: true,
  initialValues: { lateFeeSetting: { feeAmount: 0, gracePeriodInDays: 0 } },
  onSubmitFail: focusFirstInvalidField,
  onSubmit: () => { },
})(BillingModuleInvoiceDetailsForm);
