import React from 'react';

import { RouteSegmentExtended } from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
// import { DASHBOARD_VEHICLE_POSITIONS_LAYER } from 'src/dashboard/constants/DashboardESRIMap';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import MapWrapper from './esri/MapWrapper';
import { EsriBreadcrumbs } from './esri/EsriBreadcrumbs';
import EsriRoutesData from './esri/EsriRoutesData';
import { DashboardWidgets } from '../dashboardPageSections';
import { EsriGeoFences } from './esri/EsriGeoFences';
import EsriHostedLayer from './esri/EsriHostedLayer';
import { EsriSnowOps } from './esri/EsriSnowOps';
interface Props {
    showVehicleTypesPanel?: boolean;
    filteredSegments?: RouteSegmentExtended[];
    isRubiconZ: boolean;
    selectedVehicleId?: number;
    streetNetwork?: RouteSegmentExtended[];
    isSnowSweeperDashboard?: boolean;
    vehicleTypeId?: number;
    routeId?: number;
}

const DashboardESRIMap: React.FC<Props> = ({
    showVehicleTypesPanel = false,
    selectedVehicleId,
    isSnowSweeperDashboard,
    vehicleTypeId,
}) => {

    return (
        <>
            <DashboardWidgets
                displayActiveVehicleTypes={showVehicleTypesPanel}
                isForSnowSweeper={
                    isSnowSweeperDashboard || vehicleTypeId === SNOW_PLOW_ID || vehicleTypeId === STREET_SWEEPER_ID
                }
            />
            <EsriHostedLayer />
            <MapWrapper basemap={"topo-vector"} />
            {isSnowSweeperDashboard && <EsriSnowOps
                vehicleTypeId={vehicleTypeId || SNOW_PLOW_ID}
            />}
            {!isSnowSweeperDashboard && <EsriBreadcrumbs showBearing={true} selectedVehicleId={selectedVehicleId} />}
          
            <EsriRoutesData selectedVehicleId={selectedVehicleId} />
            <EsriGeoFences />

            {/* {activeTab === FILTER_TABS.vehicles && !selectedVehicleId && <EsriMapStoppedVehicles geoJsonForPoints={vehiclesList} />} */}
        </>
    );
};

export default DashboardESRIMap;
