import { get } from 'lodash-es';
import moment from 'moment';
import { ROUTES_TRACKER_SEQUENCE_ROUTE } from 'src/account/constants';
import { checkIfSupport, checkIfViewOnly, hasPermission } from 'src/account/utils/permissions';

import { ActionButtonTooltip, UnconnectedCheckbox } from 'src/core/components';
import { Label, TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import { TABLE_ROW_HEIGHT_SMALL } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import {
  ROUTE_SEQUENCE_TOOLTIP_COLORS,
  ROUTE_SEQUENCE_STATUS_ICONS,
  LABEL_COLORS,
  ROUTE_STATUSES_BY_ID,
} from 'src/routes/constants';
import { RoutePlannerRoute } from 'src/routes/interfaces/routePlanner/RoutePlannerRoute';
import { getTimeDifference, routePlannerRouteBuilderTableCellsWidths } from 'src/routes/utils/routePlanner';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';

interface Props extends RoutePlannerRoute {
  isChecked: boolean;
  routeSequenceStatus: string;
  jobId: number;
  selectRoute: (id: number) => void;
  deleteRoute: (id: number) => void;
  copyRoute: (id: number) => void;
  onTableRowClick: (
    id: number,
    routeSequenceStatus: string,
    jobId: number,
    isEdit: boolean,
    vehicleTypeId: number,
  ) => void;
}

const RoutePlannerRouteListingTableRow = ({
  routeId,
  routeName,
  isChecked,
  vehicleName,
  description,
  driverName,
  materialTypeId,
  numberOfStops,
  startTime,
  endTime,
  jobId,
  routeSequenceStatus,
  routeStatusTypeId,
  vehicleTypeId,
  selectRoute,
  deleteRoute,
  onTableRowClick,
}: Props) => {
  const routeSequencingEnabled = useSelector(state => routeSequencingStatusSelector(state.vendors.features.features));
  const wasteTypes = useSelector(state => state.common.wasteTypes.wasteTypes);
  const hasRouteSequencePermission = hasPermission(ROUTES_TRACKER_SEQUENCE_ROUTE);

  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;

  const vehicleTypeName = VEHICLE_TYPE_IDS[vehicleTypeId]?.name;

  const materialType =
    materialTypeId && !isSnowPlowRoute && !isStreetSweeperRoute
      ? wasteTypes.find(wasteType => wasteType.id === materialTypeId)?.name
      : '';

  const isViewOnlyOrSupport = checkIfViewOnly() || checkIfSupport();

  return (
    <TableRow
      height={TABLE_ROW_HEIGHT_SMALL}
      onClick={() => onTableRowClick(routeId, routeSequenceStatus, jobId, false, vehicleTypeId)}
    >
      {!isViewOnlyOrSupport && (
        <TableCell width={routePlannerRouteBuilderTableCellsWidths[0]} padding="xxSmall xxSmall">
          <UnconnectedCheckbox
            name={`selected_${routeId}`}
            checked={isChecked}
            onChange={(e: any) => {
              e.stopPropagation();
              selectRoute(routeId);
            }}
            onClick={e => e.stopPropagation()}
          />
        </TableCell>
      )}
      <TableCell
        width={routePlannerRouteBuilderTableCellsWidths[1]}
        padding={isViewOnlyOrSupport ? 'xxSmall xxSmall xxSmall small' : 'xxSmall xxSmall'}
      >
        <Text block singleLine title={routeName} weight="normal">
          {routeName}
        </Text>
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[2]} padding="xxSmall xxSmall">
        {vehicleName && (
          <Box display="block" width="100%">
            <Text block singleLine title={vehicleName} weight="normal">
              {vehicleName}
            </Text>
            {vehicleTypeName && (
              <Text block singleLine title={vehicleTypeName}>
                {vehicleTypeName}
              </Text>
            )}
          </Box>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[3]} padding="xxSmall xxSmall">
        {driverName && (
          <Text block singleLine title={driverName}>
            {driverName}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[4]} padding="xxSmall xxSmall">
        <Text block singleLine title={materialType}>
          {materialType}
        </Text>
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[5]} padding="xxSmall xxSmall">
        <Text block singleLine title={description}>
          {description}
        </Text>
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[6]} padding="xxSmall xxSmall">
        {numberOfStops && (
          <Text block singleLine title={numberOfStops.toString()}>
            {numberOfStops}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[7]} padding="xxSmall xxSmall">
        {routeStatusTypeId && (
          <Label color={LABEL_COLORS[routeStatusTypeId]}>{ROUTE_STATUSES_BY_ID[routeStatusTypeId].name}</Label>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[8]} padding="xxSmall xxSmall">
        {startTime && (
          <Text block singleLine title={moment(startTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}>
            {moment(startTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[9]} padding="xxSmall xxSmall">
        {endTime && (
          <Text block singleLine title={moment(endTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}>
            {moment(endTime, 'HH:mm:ss').format(timeFormatWithoutSeconds)}
          </Text>
        )}
      </TableCell>
      <TableCell width={routePlannerRouteBuilderTableCellsWidths[10]} padding="xxSmall xxSmall">
        {endTime && startTime && (
          <Text block singleLine title={getTimeDifference(endTime, startTime)}>
            {getTimeDifference(endTime, startTime)}
          </Text>
        )}
      </TableCell>

      <TableCell width={routePlannerRouteBuilderTableCellsWidths[11]} padding="xxSmall xxSmall" align="right">
        {!isViewOnlyOrSupport && (
          <>
            {routeSequencingEnabled && routeSequenceStatus && hasRouteSequencePermission && (
              <TableActionButton>
                <ActionButtonTooltip
                  color={(ROUTE_SEQUENCE_TOOLTIP_COLORS as any)[routeSequenceStatus]}
                  imageSrc={get(ROUTE_SEQUENCE_STATUS_ICONS[routeSequenceStatus], 'icon')}
                  tooltip={`routeSequence${routeSequenceStatus}`}
                />
              </TableActionButton>
            )}
            <TableActionButton
              color="grayDarker"
              onClick={e => {
                e.stopPropagation();
                onTableRowClick(routeId, routeSequenceStatus, jobId, true, vehicleTypeId);
              }}
            >
              <ActionButtonTooltip iconSize="sMedium" icon="edit" tooltip="edit" />
            </TableActionButton>
            <TableActionButton
              color="grayDarker"
              onClick={e => {
                e.stopPropagation();
                deleteRoute(routeId);
              }}
            >
              <ActionButtonTooltip iconSize="sMedium" icon="delete" tooltip="delete" />
            </TableActionButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RoutePlannerRouteListingTableRow;
