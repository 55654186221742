import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { VideoRequestFormValues } from './VideoRequestForm';
import VideoRequestModal from './VideoRequestModal';
import { loadCameraConfigurationsByVehicle } from 'src/routes/ducks/videoRequest';

interface Props {
  closeModal: () => void;
  initialValues: VideoRequestFormValues;
  onSubmit: (formValues: VideoRequestFormValues) => void;
}

const VideoRequestModalResolver = ({ onSubmit, closeModal, initialValues }: Props) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<any>[] = [loadCameraConfigurationsByVehicle(initialValues?.vehicleId)(dispatch)];

    return Promise.all(dependencies);
  };
  return (
    <TypedResolver
      successComponent={VideoRequestModal}
      successProps={{ closeModal, onSubmit, initialValues }}
      loadingComponent={PageLoadingOverlay}
      onError={closeModal}
      resolve={loadDependencies}
    />
  );
};

export default VideoRequestModalResolver;
