import React, { PureComponent } from 'react';

import { debounce } from 'lodash-es';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch } from '../../../core/components';
import { PickupStatusDropdown } from '../../../common/components';

interface ComponentProps {
  onPickupStatusChange?: (event: any, pickupStatus: number[]) => void;
  onSearchTermChange: (searchTerm: string) => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class RouteLocationsForm extends PureComponent<Props> {
  onSearchTermChange = debounce((event, searchTerm) => {
    this.props.onSearchTermChange(searchTerm);
  }, 300);

  render() {
    const { onPickupStatusChange } = this.props;

    return (
      <form>
        <PanelSection padding="xSmall" withBorder>
          <Grid>
            <GridColumn size={'10/12'}>
              <Field
                name="routeLocationSearchTerm"
                component={PanelSearch}
                onChange={this.onSearchTermChange}
                margin="no"
              />
            </GridColumn>

            {onPickupStatusChange && (
              <GridColumn size="2/12">
                <Field
                  acceptedPickupStatuses={[]}
                  component={PickupStatusDropdown as any}
                  dropdownProps={{ margin: 'no', isClearable: true, id: 'route-pickup-status' }}
                  name="pickupStatus"
                  onChange={onPickupStatusChange}
                  withPlaceholder
                />
              </GridColumn>
            )}
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

export default reduxForm<any, ComponentProps>({
  form: 'routeLocations',
  enableReinitialize: true,
})(RouteLocationsForm);
