import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformReportingDetails = (reportingDetails: any[]) =>
  reportingDetails.map(reportingDetail => ({
    ...reportingDetail,
    vehicleTypeName: translate(createTranslationKey(reportingDetail.vehicleType, 'vehicles.vehicleTypes')),
  }));

export default transformReportingDetails;
