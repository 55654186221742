import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadContainerManagementSettings as doLoadContainerManagementSettings,
  saveContainerManagementSettings as doSaveContainerManagementSettings,
} from '../services/containerManagement';
import { ContainerManagementSettings } from '../interfaces/ContainerManagement';

// Actions
const START_LOAD = 'vendors/containerManagement/START_LOAD';
const COMPLETE_LOAD = 'vendors/containerManagement/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/containerManagement/FAIL_LOAD';
const START_SAVE = 'vendors/containerManagement/START_SAVE';
const COMPLETE_SAVE = 'vendors/containerManagement/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/containerManagement/FAIL_SAVE';
const RESET = 'vendors/containerManagement/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  containerManagementSettings: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          containerManagementSettings: action.containerManagementSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (containerManagementSettings: ContainerManagementSettings) => ({
  type: COMPLETE_LOAD,
  containerManagementSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadContainerManagementSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadContainerManagementSettingsPromise = doLoadContainerManagementSettings(vendorId);
  loadContainerManagementSettingsPromise
    .then(containerManagementSettings => dispatch(completeLoad(containerManagementSettings)))
    .catch(() => dispatch(failLoad()));

  return loadContainerManagementSettingsPromise;
};

export const saveContainerManagementSettings =
  (vendorId: number, containerManagementSettings: ContainerManagementSettings) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveContainerManagementSettingsPromise = doSaveContainerManagementSettings(
      vendorId,
      containerManagementSettings,
    );
    saveContainerManagementSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveContainerManagementSettingsPromise;
  };

export const resetContainerManagementSettings = () => ({
  type: RESET,
});
