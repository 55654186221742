import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

import { NINETY_DAYS_BEFORE_TODAY, TODAY_FORMATTED } from 'src/core/constants';
import { getQueryParams } from 'src/utils/services/queryParams';
import { BillCreateQueryParams } from 'src/finance/components/pages/billing/BillCreatePage';

const getBillCreateFilterFormInitialValueSelector = (queryString: string) => {
  const { startDate, endDate, customerId, customerName, locationId, locationName, isOverdue } =
    getQueryParams<BillCreateQueryParams>(queryString);

  const { customerStatusIds, customerTypeIds, billMethodIds, paymentTermsIds } = getQueryParams<BillCreateQueryParams>(
    queryString,
    {
      mapTypeIdsToArray: true,
    },
  );

  return {
    newBillDateRange: {
      from: startDate || NINETY_DAYS_BEFORE_TODAY,
      to: endDate || TODAY_FORMATTED,
    },
    customer:
      customerId && customerName
        ? {
            label: customerName,
            value: { customerId, customerName },
          }
        : undefined,
    location:
      locationId && locationName
        ? {
            label: locationName,
            value: { locationId, locationName },
          }
        : undefined,
    customerStatusIds,
    customerTypeIds,
    billMethodIds,
    paymentTermsIds,
    isOverdue
  };
};

const billCreateFilterFormInitialValueSelector = createSelector(getBillCreateFilterFormInitialValueSelector, identity);

export default billCreateFilterFormInitialValueSelector;
