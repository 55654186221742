import { mapKeys } from 'lodash-es';

const MONDAY = 'M';
const TUESDAY = 'T';
const WEDNESDAY = 'W';
const THURSDAY = 'R';
const FRIDAY = 'F';
const SATURDAY = 'S';
const SUNDAY = 'U';

const WEEKDAY_SHORTCODES = mapKeys(
  [
    { id: MONDAY, translationKey: 'common.weekdays.monday', name: 'isOpenMonday' },
    { id: TUESDAY, translationKey: 'common.weekdays.tuesday', name: 'isOpenTuesday' },
    { id: WEDNESDAY, translationKey: 'common.weekdays.wednesday', name: 'isOpenWednesday' },
    { id: THURSDAY, translationKey: 'common.weekdays.thursday', name: 'isOpenThursday' },
    { id: FRIDAY, translationKey: 'common.weekdays.friday', name: 'isOpenFriday' },
    { id: SATURDAY, translationKey: 'common.weekdays.saturday', name: 'isOpenSaturday' },
    { id: SUNDAY, translationKey: 'common.weekdays.sunday', name: 'isOpenSunday' },
  ],
  'id',
);

export default WEEKDAY_SHORTCODES;
