import { camelCase } from 'lodash-es';
import { FC } from 'react';

import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import {
  DashboardMapInsight,
  DashboardMapInsightIcon,
  DashboardMapInsightName,
  DashboardMapInsightValue,
  WidgetContent,
} from 'src/dashboard/components/styled';

const ActiveVehicleWidgetContent: FC = () => {
  const { vehicleInsights = [] as any[], isLoading: isLoadingVehicleInsights } = useSelector(
    state => state.dashboard.vehiclesData,
  );

  return (
    <WidgetContent className="widgetContent" isLoading={isLoadingVehicleInsights}>
      {vehicleInsights.length ? (
        <>
          {vehicleInsights.map((insight, insightIndex) => {
            const insightType = camelCase(insight.name);
            const insightFormattedValue = insight.insightFormattedValue;
            const insightID = undefined;

            if (insightFormattedValue !== '0') {
              return (
                <DashboardMapInsight key={insight.name} category={'vehicles'} type={insightType} id={insightID}>
                  <>
                    <DashboardMapInsightIcon />
                    <DashboardMapInsightName>
                      {insight.insightType === 'Issue'
                        ? translate(`routes.pickupIssueTypes.${insightType}`)
                        : translate(`dashboard.${insightType}`)}
                    </DashboardMapInsightName>
                    <DashboardMapInsightValue>{insightFormattedValue}</DashboardMapInsightValue>
                  </>
                </DashboardMapInsight>
              );
            }

            return null;
          })}
        </>
      ) : (
        <Text padding="no small" size="small">
          {translate('common.noActiveVehicleTypes')}
        </Text>
      )}
    </WidgetContent>
  );
};

export default ActiveVehicleWidgetContent;
