import React from 'react';

import { ActionButtonTooltip } from '../../../core/components';
import { EQUIPMENT_TYPE_ICONS } from '../../constants/';
import { EquipmentIcon } from '../styled';
import { TableActionButton, TableCell, TableRow, Text } from '../../../core/components/styled';

type Props = {
  editServiceArea: (equipmentId: number) => void;
  equipmentCode: string;
  equipmentId: number;
  equipmentSize: string;
  equipmentType: string;
  materials: string;
  removeServiceArea: (equipmentId: number) => void;
  zipCodes: string;
};

export default function ServiceAreasTableRow({
  editServiceArea,
  equipmentCode,
  equipmentId,
  equipmentSize,
  equipmentType,
  materials,
  removeServiceArea,
  zipCodes,
}: Props) {
  return (
    <TableRow>
      <TableCell width="20%">
        <EquipmentIcon src={EQUIPMENT_TYPE_ICONS[equipmentCode]} alt={equipmentCode} />
        <Text margin="no no no small">{equipmentType}</Text>
      </TableCell>
      <TableCell width="15%">
        <Text>{equipmentSize}</Text>
      </TableCell>
      <TableCell width="30%">
        <Text>{materials}</Text>
      </TableCell>
      <TableCell width="25%">
        <Text>{zipCodes}</Text>
      </TableCell>
      <TableCell width="10%">
        <TableActionButton onClick={() => editServiceArea(equipmentId)}>
          <ActionButtonTooltip icon="edit" tooltip="editServiceArea" />
        </TableActionButton>

        <TableActionButton onClick={() => removeServiceArea(equipmentId)}>
          <ActionButtonTooltip icon="delete" tooltip="deleteServiceArea" />
        </TableActionButton>
      </TableCell>
    </TableRow>
  );
}
