import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { EventTypes } from '../interfaces/EventTypes';
import doLoadEventTypes from '../services/loadEventTypes';

interface State {
  isLoading: boolean;
  eventTypes: EventTypes[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Actions
const START_LOAD = 'routes/eventTypes/START_LOAD';
const COMPLETE_LOAD = 'routes/eventTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/eventTypes/FAIL_LOAD';
const RESET = 'routes/eventTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  eventTypes: [],
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          eventTypes: action.eventTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          eventTypes: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (eventTypes: EventTypes[]) => ({
  type: COMPLETE_LOAD,
  eventTypes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadEventTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadEventTypesPromise = doLoadEventTypes();
  loadEventTypesPromise.then(eventTypes => dispatch(completeLoad(eventTypes))).catch(() => dispatch(failLoad()));
  return loadEventTypesPromise;
};

export const resetEventTypes = () => ({
  type: RESET,
});
