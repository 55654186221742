import { http } from '../../core/services/http';
import { SnowRoadSegmentCondition, SnowRoadSegmentConditionUpdates } from '../interfaces/snowRoadConditions';

export const loadStreetSweeperConditions = (vendorId: number) =>
  http
    .get<SnowRoadSegmentCondition[]>(`vendors/${vendorId}/streetSweeperRoadConditions`)
    .then(response => response.data);

export const loadStreetSweeperConditionUpdates = (vendorId: number) =>
  http
    .get<SnowRoadSegmentConditionUpdates[]>(`vendors/${vendorId}/streetSweeperRoadConditions`, {
      params: {
        optimizedResultsOnly: true,
      },
    })
    .then(response => response.data);
