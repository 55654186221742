import React, { Fragment } from 'react';

import { map } from 'lodash-es';
import { connect } from 'react-redux';

import { Weekday } from 'src/core/constants/weekdays';
import { RouteTemplate } from 'src/routes/interfaces/RouteTemplates';
import { AppState } from 'src/store';
import { UnconnectedCheckbox, UnconnectedDropdown } from '../../core/components';
import { Grid, GridColumn } from '../../core/components/styled';
import { WEEKDAYS_BY_SHORTCODE } from '../../core/constants';

interface Props {
  routeTemplates?: RouteTemplate[];
}

const ServiceDayWithRouteTemplate = ({ routeTemplates = [] }: Props) => (
  <Grid multiLine>
    {map(WEEKDAYS_BY_SHORTCODE, (weekday: Weekday & { isChecked?: boolean }) => (
      <Fragment>
        <GridColumn size="6/12">
          <UnconnectedCheckbox block size="small" name={weekday.shortCode} label={weekday.name} />
        </GridColumn>
        <GridColumn size="6/12">
          <UnconnectedDropdown disabled={!weekday.isChecked} options={routeTemplates} />
        </GridColumn>
      </Fragment>
    ))}
  </Grid>
);

ServiceDayWithRouteTemplate.defaultProps = {
  routeTemplates: [],
};

const mapStateToProps = (state: AppState) => ({ routeTemplates: state.routes.routeTemplates.routeTemplates });

export default connect(mapStateToProps, undefined)(ServiceDayWithRouteTemplate);
