import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { omit, size } from 'lodash-es';

import { AppState } from '../../../store';
import { AcceptServiceChangeRequestForm } from '../forms';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadServiceChangeRequests } from '../../ducks';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { updateServiceChangeRequest } from '../../ducks';
import { YES } from '../../../fleet/constants/status';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';
interface RouteParams {
  token: string;
}

interface ComponentProps {
  caseId: number;
  equipmentChanged: boolean;
  frequencyChanged: boolean;
  frequencyId: string;
  itemId: number;
  location: any;
  materialChanged: boolean;
  onCancel(pristine: boolean): void;
  offer: any;
  status: any;
  userId: string;
  vendorId: number;
}

interface ReduxProps {
  isSaving: boolean;
  response: any;
  requestedChanges: any;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadServiceChangeRequests: DuckFunction<typeof loadServiceChangeRequests>;
  updateServiceChangeRequest: DuckFunction<typeof updateServiceChangeRequest>;
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps<RouteParams>;

const AcceptServiceChangeRequestModal: React.FC<Props> = props => {
  const { isSaving, onCancel } = props;

  const onSubmit = React.useCallback(async () => {
    const {
      frequencyChanged,
      frequencyId,
      itemId,
      loadDispatchOpportunitiesCount,
      loadServiceChangeRequests,
      location: { search },
      match: {
        params: { token },
      },
      onCancel,
      requestedChanges,
      status,
      userId,
      updateServiceChangeRequest,
      vendorId,
      closeMobilePageDetailOverlay,
    } = props;

    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    const requestOpportunityObj: any = {};
    requestOpportunityObj.itemId = itemId;
    requestOpportunityObj.Response = status === 'AwaitingHaulerApproval' ? 'AcceptsChanges' : 'Yes';
    requestOpportunityObj.liftAndShiftVendor = true;

    if (status === 'AwaitingHaulerApproval') {
      requestOpportunityObj.requestedChanges = omit(requestedChanges, ['displayFees']);
      requestOpportunityObj.requestedChanges.deliveryDate = moment(requestedChanges.deliveryDate).format('YYYY-MM-DD');
      requestOpportunityObj.requestedChanges.swapRequired = requestedChanges.swapRequired === 'Yes' ? true : false;

      if (frequencyChanged && requestedChanges.schedule) {
        requestOpportunityObj.requestedChanges.schedule = Object.assign(
          {},
          ...requestedChanges.schedule.map((d: any) => ({ [d[0]]: true })),
        );
      }
    } else {
      requestOpportunityObj.respondedYes = omit(requestedChanges, ['displayFees']);
      requestOpportunityObj.respondedYes.deliveryDate = moment(requestedChanges.deliveryDate).format('YYYY-MM-DD');
      requestOpportunityObj.respondedYes.swapRequired = requestedChanges.swapRequired === YES ? true : false;

      if (frequencyChanged && requestedChanges.schedule) {
        requestOpportunityObj.respondedYes.schedule = Object.assign(
          {},
          ...requestedChanges.schedule.map((d: any) => ({ [d[0]]: true })),
        );
        requestOpportunityObj.respondedYes.frequencyId = frequencyId;
      }
    }
    if (size(requestedChanges.serviceRecommendation)) {
      const frequencyDays = requestedChanges.serviceRecommendation[0].frequencyDays;
      requestOpportunityObj.requestedChanges.serviceRecommendation[0].frequencyDays =
        frequencyDays.length > 0 ? frequencyDays.join('') : frequencyDays;
    }

    const promise = updateServiceChangeRequest(requestOpportunityObj, userId, token, vendorId);

    if (!promise) {
      return;
    }

    await promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, token);
        loadServiceChangeRequests(userId, token, vendorId, startDate, endDate, isActionRequired);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  }, [props]);

  return (
    <Modal title={translate('opportunity.acceptService')} size="small" isLoading={isSaving}>
      <AcceptServiceChangeRequestForm {...props} onSubmit={onSubmit} onCancel={onCancel} />
    </Modal>
  );
};

const formSelector = formValueSelector('acceptServiceChangeRequest');

const mapStateToProps = (state: AppState, ownProps: any) => ({
  isSaving: state.opportunity.serviceChangeRequest.isSaving,
  response: formSelector(state, `changeRequests.settings${ownProps.caseId}.response`),
  requestedChanges: formSelector(state, `changeRequests.settings${ownProps.caseId}.requestedChanges`),
});

const mapDispatchToProps = {
  loadDispatchOpportunitiesCount,
  loadServiceChangeRequests,
  updateServiceChangeRequest,
  closeMobilePageDetailOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptServiceChangeRequestModal);
