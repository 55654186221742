import { filter, map } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button, ButtonSet, Grid, GridColumn, IconButtonIcon, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { DriversAndVehiclesStatusEditContainer, DriversAndVehiclesStatusEditItem } from '../../styled';

interface Props {
  drivers: RoutePlannerRouteDriver[];
  onStatusChanged: (drivers: RoutePlannerRouteDriver[], vehicles: RoutePlannerRouteVehicle[]) => void;
}

const DriversStatusEditZone: React.FC<Props> = ({ drivers, onStatusChanged }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { isLoading } = useSelector(state => state.routes.routePlanner.routePlannerDriverStatus);

  const handleSelectedItem = (value: number) => () => {
    if (!selectedItems.includes(value)) {
      setSelectedItems([...selectedItems, value]);
    } else {
      const spliced = selectedItems.filter(item => item !== value);
      setSelectedItems(spliced);
    }
  };

  const availableList = useMemo(() => {
    return map(
      filter(drivers, d => d.isAvailable),
      (item: RoutePlannerRouteDriver) => ({
        name: item.driverName,
        value: item.driverId,
      }),
    );
  }, [drivers]);

  const unavailableList = useMemo(() => {
    return map(
      filter(drivers, d => !d.isAvailable),
      (item: RoutePlannerRouteDriver) => ({
        name: item.driverName,
        value: item.driverId,
      }),
    );
  }, [drivers]);

  const handleMove = (available: boolean) => {
    const driversToBeChanged = map(
      filter(drivers, d => selectedItems.includes(d.driverId)),
      selectedDriver => {
        return {
          ...selectedDriver,
          isAvailable: available,
        };
      },
    );
    onStatusChanged(driversToBeChanged, []);
    setSelectedItems([]);
  };

  return (
    <Grid centered multiLine>
      <GridColumn size="5/12" isLoading={isLoading}>
        <Text size="large" align="center" block margin="small" weight="medium">
          {translate('common.available')}
        </Text>
        <DriversAndVehiclesStatusEditContainer right>
          {map(availableList, (item, i) => (
            <DriversAndVehiclesStatusEditItem
              selectedItem={selectedItems.includes(item.value)}
              key={item.value}
              onClick={handleSelectedItem(item.value)}
              grayed={i % 2 !== 0}
            >
              {item.name}
            </DriversAndVehiclesStatusEditItem>
          ))}
        </DriversAndVehiclesStatusEditContainer>
      </GridColumn>
      <GridColumn size="2/12" alignVerticalCenter>
        <ButtonSet vertical margin="xLarge no" style={{ height: '80px' }}>
          <Button text color="primary" size="xxLarge" onClick={() => handleMove(false)} disabled={isLoading}>
            <IconButtonIcon icon="fastForward" margin="no" color="primary" size="large" />
          </Button>
          <Button text color="primary" size="xxLarge" onClick={() => handleMove(true)} disabled={isLoading}>
            <IconButtonIcon icon="fastForward" rotate={180} margin="no" color="primary" size="large" />
          </Button>
        </ButtonSet>
      </GridColumn>
      <GridColumn size="5/12" isLoading={isLoading}>
        <Text size="large" align="center" block margin="small" weight="medium">
          {translate('common.unavailable')}
        </Text>
        <DriversAndVehiclesStatusEditContainer>
          {map(unavailableList, (item, i) => (
            <DriversAndVehiclesStatusEditItem
              selectedItem={selectedItems.includes(item.value)}
              key={item.value}
              onClick={handleSelectedItem(item.value)}
              grayed={i % 2 !== 0}
            >
              {item.name}
            </DriversAndVehiclesStatusEditItem>
          ))}
        </DriversAndVehiclesStatusEditContainer>
      </GridColumn>
    </Grid>
  );
};

export default DriversStatusEditZone;
