import React, { PureComponent } from 'react';

import humps from 'humps';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import { getItem } from '../../core/services/persistentStorage';
import {
  Link,
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowIcon,
  MapInfoWindowTitle,
} from '../../core/components/styled';
import { SOS_ALERT_VEHICLE_ID_KEY } from '../../common/constants/sosAlert';
import { time } from '../../utils/services/formatter';
import { VEHICLE_ICONS, VEHICLE_STATUSES, GOOGLE as google } from '../constants';
import translate from '../../core/services/translate';

export const getVehicleIconType = (vehicleTypeName: string, isStationary: boolean, isActive: boolean) => {
  const camelizedVehicleTypeName = humps.camelize(vehicleTypeName);
  const normalizedVehicleTypeName = VEHICLE_ICONS[camelizedVehicleTypeName] ? camelizedVehicleTypeName : 'vehicle';
  return `${normalizedVehicleTypeName}${(isStationary && 'Stationary') || (!isActive && 'Inactive') || ''}`;
};

interface Props {
  vehiclePosition: any;
  isInfoWindowOpen?: boolean;
  toggleInfoWindow: (key: string) => void;
  clusterer: Clusterer;
}

export const VehiclePositionInfoWindowContent = ({ vehiclePosition }: any) => {
  const translationKeys = {
    vehicleSubType: humps.camelize(vehiclePosition.vehicleSubType),
  };

  const vehicleIconType = getVehicleIconType(
    vehiclePosition.vehicleType.technicalName,
    vehiclePosition.isStationary,
    vehiclePosition.isActive,
  );

  return (
    <MapInfoWindow>
      <MapInfoWindowIcon src={VEHICLE_ICONS[vehicleIconType]} />
      <MapInfoWindowDetails>
        <MapInfoWindowTitle>
          {vehiclePosition.vehicle.name} - {translate(`vehicles.vehicleTypes.${translationKeys.vehicleSubType}`)}
        </MapInfoWindowTitle>
        {!!vehiclePosition.eventDateTime && (
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('common.timestamp')}:</MapInfoWindowDetailLabel>
            {time(vehiclePosition.eventDateTime)}
          </MapInfoWindowDetail>
        )}

        {!!vehiclePosition.driverName && (
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('drivers.driverName')}:</MapInfoWindowDetailLabel>
            {vehiclePosition.driverName}
          </MapInfoWindowDetail>
        )}

        {!!vehiclePosition.vehicleStatus && (
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('vehicles.vehicleStatus')}:</MapInfoWindowDetailLabel>
            {VEHICLE_STATUSES[vehiclePosition.vehicleStatus].name}
          </MapInfoWindowDetail>
        )}

        {!!vehiclePosition.totalMilesDriven && (
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('vehicles.totalMilesDriven')}:</MapInfoWindowDetailLabel>
            {vehiclePosition.totalMilesDriven}
          </MapInfoWindowDetail>
        )}

        {/* TODO - hide totalMilesSwept until we have correct data */}
        {/* {!!vehiclePosition.totalMilesSwept && (
          <MapInfoWindowDetail>
            <MapInfoWindowDetailLabel>{translate('vehicles.totalMilesSwept')}:</MapInfoWindowDetailLabel>
            {vehiclePosition.totalMilesSwept}
          </MapInfoWindowDetail>
        )} */}
        <MapInfoWindowDetail>
          <Link to={`/fleet/vehicles/${vehiclePosition.vehicle.id}/inspection-data?&source=dashboard`}>
            {translate('vehicles.vehicleInspections.viewInspections')}
          </Link>
        </MapInfoWindowDetail>
      </MapInfoWindowDetails>
    </MapInfoWindow>
  );
};

class VehiclePositionMarker extends PureComponent<Props> {
  toggleInfoWindow = () => {
    const {
      toggleInfoWindow,
      vehiclePosition: {
        vehicle: { id },
      },
    } = this.props;

    toggleInfoWindow(`vehiclePositions.${id}`);
  };

  render() {
    const { vehiclePosition, isInfoWindowOpen, clusterer } = this.props;

    const storageKey = getItem(SOS_ALERT_VEHICLE_ID_KEY);
    const sosAlertVehicleId = storageKey && storageKey !== 'undefined' ? JSON.parse(storageKey) : undefined;

    const markerPosition = {
      lat: parseFloat(vehiclePosition.coordinates.latitude),
      lng: parseFloat(vehiclePosition.coordinates.longitude),
    };

    const vehicleIconType = getVehicleIconType(
      vehiclePosition.vehicleType.technicalName,
      vehiclePosition.isStationary,
      vehiclePosition.isActive,
    );

    const markerIcon = {
      url: VEHICLE_ICONS[vehicleIconType],
      size: new google.maps.Size(40, 40),
      scaledSize: new google.maps.Size(40, 40),
    };
    const pixelOffset = new google.maps.Size(0, sosAlertVehicleId ? -75 : 0);

    const markerIconSOSAlert = {
      url: VEHICLE_ICONS['exclamation'],
      size: new google.maps.Size(30, 30),
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 70),
    };

    return (
      <>
        <Marker
          icon={markerIcon}
          position={markerPosition}
          onClick={this.toggleInfoWindow}
          clusterer={clusterer}
          noClustererRedraw
        >
          {isInfoWindowOpen && markerPosition && (
            <InfoWindow onCloseClick={this.toggleInfoWindow} position={markerPosition} options={{ pixelOffset }}>
              <VehiclePositionInfoWindowContent vehiclePosition={vehiclePosition} />
            </InfoWindow>
          )}
        </Marker>

        {sosAlertVehicleId && (
          <Marker
            icon={markerIconSOSAlert}
            position={markerPosition}
            onClick={this.toggleInfoWindow}
            clusterer={clusterer}
            noClustererRedraw
          ></Marker>
        )}
      </>
    );
  }
}

export default VehiclePositionMarker;
