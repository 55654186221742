import { ChangeEvent } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Districts } from 'src/vendors/interfaces/DivisionAccess';
import { Dropdown, TypedField } from 'src/core/components';
import { Grid, GridColumn } from 'src/core/components/styled';
import translate from '../../../core/services/translate';

interface ComponentProps {
  divisions: Districts[];
}
interface FormData {
  divisionId?: number;
}

interface PropsWithoutReduxForm extends ComponentProps {
  onDivisionChange: (value: string) => void;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormData, PropsWithoutReduxForm>;

const DivisionForm: React.FC<Props> = ({ onDivisionChange, divisions, handleSubmit }) => {
  const divisionOptions = divisions.map((division: Districts) => ({
    label: division.name,
    value: division.value,
  }));

  const onDivisionChangeHandler = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    onDivisionChange(value);
  };

  return (
    <form onSubmit={handleSubmit} id="divisionForm">
      <Grid margin="large no no">
        <GridColumn size="12/12">
          <TypedField
            name="divisionId"
            component={Dropdown}
            onChange={onDivisionChangeHandler}
            props={{
              label: translate('haulerProfile.division'),
              margin: 'no',
              options: divisionOptions,
            }}
          />
        </GridColumn>
      </Grid>
    </form>
  );
};

export default reduxForm<any, PropsWithoutReduxForm>({
  form: 'division',
  enableReinitialize: true,
})(DivisionForm);
