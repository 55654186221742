import { http } from 'src/core/services/http';

export const loadLowInventoryParts = (vendorId: number, containerTypeId?: number) =>
  http
    .get(`${vendorId}/inventories/parts`, {
      params: { containerTypeId },
    })
    .then(response => response.data);

export const loadLowInventoryPartHistory = (vendorId: number, id: number) =>
  http.get(`${vendorId}/inventories/parts/${id}/history`).then(response => response.data);

export const saveLowInventoryPart = (vendorId: number, payLoad: any) =>
  http.post(`${vendorId}/inventories/parts`, payLoad).then(response => response.data);

export const editLowInventoryPart = (vendorId: number, payLoad: any, id?: number) =>
  http.put(`${vendorId}/inventories/parts/${id}/quantity`, payLoad).then(response => response.data);

export const deleteLowInventoryPart = (vendorId: number, id: number) =>
  http.delete(`${vendorId}/inventories/parts/${id}`);

export const editInventoryPart = (vendorId: number, id: number, partName: string) =>
  http.put(`${vendorId}/inventories/parts/${id}?name=${partName}`).then(response => response.data);
