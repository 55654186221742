import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { size } from 'lodash-es';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DATE_RANGE_PICKER_3_7_30 } from '../../../core/constants/weekdays';
import { exportTripInspectionReport } from '../../services/vehicleInspections';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { loadVehicleInspections } from '../../ducks/vehicleInspections';
import {
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { Table, DateRangePicker } from '../../../core/components';
import { VehicleInspection } from '../../interfaces/VehicleInspections';
import translate from '../../../core/services/translate';
import VehicleInspectionDataTableRow from './VehicleInspectionDataTableRow';

interface Props extends RouteComponentProps {
  vendorId: number;
  vehicleId: string;
  isLoading: boolean;
  vehicleInspections: VehicleInspection[];
  vehicleName: string;
  alignRight?: boolean;
  loadVehicleInspections: (vendorId: number, vehicleId: number, startDate?: string, endDate?: string) => void;
}

class VehicleInspectionDataPage extends Component<Props & InjectedFormProps<any, Props>> {
  exportTripInspection = (id: number, routeDate: Date | string) => {
    const { vendorId } = this.props;
    exportTripInspectionReport(vendorId, routeDate, id);
  };

  onDateChanged = (event: any, range: any) => {
    const { vendorId, vehicleId, loadVehicleInspections } = this.props;

    loadVehicleInspections(Number(vendorId), Number(vehicleId), range.from, range.to);
  };

  vehicleInspectionsTableCells = [
    { name: 'date', label: translate('routes.routeDate'), width: '13%', sortable: true },
    { name: 'driver', label: translate('common.driver'), width: '20%' },
    { name: 'inspection', label: translate('vehicles.vehicleInspections.inspection'), width: '15%' },
    { name: 'startTime', label: translate('common.startTime'), width: '10%' },
    { name: 'endTime', label: translate('common.endTime'), width: '10%' },
    { name: 'issues', label: translate('vehicles.vehicleInspections.numberOfIssues'), width: '12%', sortable: true },
    { name: 'options', label: translate('common.options'), width: '20%' },
  ];

  render() {
    const { vehicleInspections, vehicleName, isLoading, location } = this.props;

    const params = getQueryParams(location.search);
    const previousPageUrl = params.source ? `/${params.source}` : '/fleet/vehicles';

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButton to={previousPageUrl}>
                <PageBackButtonIcon />
              </PageBackButton>
              <PageTitle>{vehicleName + ' ' + translate('vehicles.vehicleInspections.inspectionLog')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions />
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <form>
              <PanelSection padding="small xSmall" withBorder>
                <Grid>
                  <GridColumn size="8/12" />
                  <GridColumn size="4/12">
                    <Field
                      name="date"
                      component={DateRangePicker as any}
                      onChange={this.onDateChanged}
                      props={{
                        alignRight: true,
                        margin: 'no',
                        options: DATE_RANGE_PICKER_3_7_30,
                        maxInterval: { amount: 30, unit: 'days' },
                      }}
                    />
                  </GridColumn>
                </Grid>
              </PanelSection>
            </form>
            <PanelSection>
              {!!size(vehicleInspections) && (
                <Table
                  cells={this.vehicleInspectionsTableCells}
                  rows={vehicleInspections}
                  rowComponent={VehicleInspectionDataTableRow}
                  rowProps={{ exportTripInspection: this.exportTripInspection }}
                  withTopBorder
                />
              )}

              {!size(vehicleInspections) && (
                <Message padding="sMedium">{translate('vehicles.vehicleInspections.noInspections')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.fleet.vehicleInspections.inspectionsLoading || state.fleet.vehicle.isLoading,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vehicleInspections: state.fleet.vehicleInspections.inspections,
  vehicleName: state.fleet.vehicle.vehicle.regplate,
  initialValues: {
    date: {
      from: moment().subtract(14, 'days').format('MM/DD/YYYY'),
      to: moment().format('MM/DD/YYYY'),
    },
  },
});

const mapDispatchToProps = {
  loadVehicleInspections,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<{}, Props>({
      form: 'vehicleInspectionForm',
      enableReinitialize: true,
    })(VehicleInspectionDataPage),
  ),
);
