import { combineReducers } from 'redux';
import { reducer as alternativeFleetReducer } from './alternativeFleetOps';
import { reducer as filtersReducer } from './filters';
import { reducer as fleetInsightsReducer } from './fleetInsights';
import { reducer as initialFilterFormValuesReducer } from './initialFilterFormValues';
import { mapControlsReducer } from './mapControls';
import { reducer as mapInsightsReducer } from './mapInsights';
import { reducer as mapInsightsMapboxReducer } from './mapInsightsMapbox';
import { reducer as roadConditionsReducer } from './roadConditions';
import { reducer as routesDataReducer } from './routesData';
import { snowRoadConditionsReducer } from './snowRoadConditions';
import { reducer as streetSweeperReducer } from './streetSweeper';
import { streetSweeperConditionsReducer } from './streetSweeperConditions';
import { reducer as vehicleTrackingsReducer } from './vehicleTrackings';
import { reducer as vehicleDataReducer } from './vehiclesData';
import { reducer as vendorLocationsReducer } from './vendorLocations';
import { reducer as routeHistoryDataReducer } from './routeHistoryData';

export {
  COMPLETE_LOAD_CITY_INSIGHT_TYPES,
  COMPLETE_LOAD_VEHICLES,
  filterPickupExceptionTypesByVehicle,
  filtersByVehicleSearchTermSelector,
  loadCityInsightTypes,
  loadPickupExceptionTypes,
  loadVehicles,
  resetFilters,
} from './filters';
export {
  COMPLETE_DELETE as COMPLETE_DELETE_FLEET_INSIGHTS,
  COMPLETE_LOAD as COMPLETE_LOAD_FLEET_INSIGHTS,
  TOGGLE_FLEET_INSIGHTS,
  fleetInsightCountSelector,
  fleetInsightsSelector,
  loadFleetInsights,
  removeFleetInsight,
  resetFleetInsights,
  toggleFleetInsightsContainer,
} from './fleetInsights';
export {
  getInitialFilterFormValues,
  resetInitialFilterFormValues,
  updateInitialFilterFormValues,
} from './initialFilterFormValues';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_MAP_INSIGHTS,
  TOGGLE_MAP_INSIGHTS,
  cityMapInsightTypesSelector,
  cityMapInsightsSelector,
  containerMapInsightTypesSelector,
  containerMapInsightsSelector,
  loadMapInsights,
  resetMapInsights,
  toggleMapInsightsContainer,
  vehicleMapInsightTypesSelector,
  vehicleMapInsightsSelector,
} from './mapInsights';
export { loadMapInsightsMapbox, resetMapInsightsMapbox } from './mapInsightsMapbox';
export {
  loadRouteDriversVehicles,
  loadRouteGeoFenceIncidents,
  loadRouteHistory,
  loadRouteInformation,
  loadRouteStops,
  loadRouteVehiclePositions,
  loadRouteVehiclesBreadCrumbs,
  loadRoutesList,
  loadRoutesVehiclePositions,
  loadVehiclesFullTrackings,
  resetGeoFenceIncidents,
  resetMapRouteStops,
  resetRouteVehiclePositions,
  resetRouteVehiclesBreadCrumbs,
  resetRoutesData,
  setMapRouteStops,
  setShowRouteVehiclesAppStatus,
} from './routesData';
export {
  loadStreetSweeper,
  resetStreetSweeper,
  serviceExceptionsSelector,
  streetSweeperSelector,
  streetSweeperTrackingsSelector,
} from './streetSweeper';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_VEHICLE_TRACKINGS,
  loadVehicleTrackings,
  resetVehicleTrackings,
  showVehicleTrackingStatusSelector,
  vehicleTrackingsSelector,
} from './vehicleTrackings';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_VEHICLE_POSITIONS,
  loadDevices,
  loadRouteData,
  loadVehicleBreadCrumbsByRoutes,
  loadVehicleBreadcrumbs,
  loadVehicleInformation,
  loadVehicleInsightDetails,
  loadVehicleInspection,
  loadVehiclePositions,
  loadVehicleSafety,
  loadVehicleStatus,
  loadVehiclesList,
  resetActiveVehiclePositions,
  resetVehicleDetails,
  resetVehiclePositions,
  setShowVehicleAppStatus,
  setVehicleInsightDetails,
  vehicleInsightsSelector,
  vehiclePositionsSelector,
} from './vehiclesData';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_VENDOR_LOCATIONS,
  loadHaulerLocationsForMapbox,
  loadVendorLocations,
  resetVendorLocations,
  setVendorLocationsToDisplay,
  vendorLocationsSelector,
} from './vendorLocations';

export {
  filterAlternativeFleetSegments,
  loadAlternativeFleetRoutes,
  loadAlternativeFleetSegments,
  loadAlternativeFleetSnowPlowServiceActivities,
  loadAlternativeFleetStreetSweeperServiceActivities,
  resetAlternativeFleetOps,
} from './alternativeFleetOps';

export {
  loadRouteHistoryVehiclePosition,
  loadRouteHistoryVehicleBreadCrumb,
  setRouteHistoryModalOpen,
  resetRouteHistoryData,
  loadRouteHistorySegments,
} from './routeHistoryData';

export const reducer = combineReducers({
  alternativeFleetOps: alternativeFleetReducer,
  filters: filtersReducer,
  fleetInsights: fleetInsightsReducer,
  initialFilterFormValues: initialFilterFormValuesReducer,
  mapControls: mapControlsReducer,
  mapInsights: mapInsightsReducer,
  mapInsightsMapbox: mapInsightsMapboxReducer,
  roadConditions: roadConditionsReducer,
  routesData: routesDataReducer,
  routeHistoryData: routeHistoryDataReducer,
  snowRoadConditions: snowRoadConditionsReducer,
  streetSweeper: streetSweeperReducer,
  streetSweeperConditions: streetSweeperConditionsReducer,
  vehiclesData: vehicleDataReducer,
  vehicleTrackings: vehicleTrackingsReducer,
  vendorLocations: vendorLocationsReducer,
});
