import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { loadRouteBuilderSettings } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import RouteBuilderModal from './RouteBuilderModal';

interface Props {
  closeModal: () => void;
  loadRouteBuilderSettings: (vendorId: number) => Promise<any>;
  vendorId?: number;
}

class RouteBuilderModalResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadRouteBuilderSettings, vendorId } = this.props;
    return loadRouteBuilderSettings(vendorId as number);
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Resolver
        loadingComponent={PageLoadingOverlay}
        resolve={this.loadDependencies}
        successComponent={RouteBuilderModal}
        successProps={{ closeModal }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { loadRouteBuilderSettings };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteBuilderModalResolver);
