import React from 'react';

import {
  Button,
  ButtonSet,
  Confirmation as ConfirmationContainer,
  ConfirmationDialog,
  ConfirmationTitle,
} from '../../../../../core/components/styled';
import translate from '../../../../../core/services/translate';

interface Props {
  onCancelClick: () => void;
  onConfirmClick: () => void;
  title: string;
}

const ConfirmationGeoFence: React.FC<Props> = ({ onCancelClick, onConfirmClick, title }) => (
  <ConfirmationContainer>
    <ConfirmationDialog>
      <ConfirmationTitle>{title}</ConfirmationTitle>
      <ButtonSet align="right">
        <Button line color="primary" margin="no small no no" onClick={onCancelClick}>
          {translate('common.no')}
        </Button>
        <Button color="primary" onClick={onConfirmClick}>
          {translate('common.yes')}
        </Button>
      </ButtonSet>
    </ConfirmationDialog>
  </ConfirmationContainer>
);

export default ConfirmationGeoFence;
