import React from 'react';

import { date, timeStamp } from '../../../../../utils/services/formatter';
import { Grid, GridColumn, TableCell, TableRow, Text } from '../../../../../core/components/styled';
import { ZLogSessionDetail } from '../../../../interfaces/EventLog';
import { TABLE_ROW_HEIGHT } from '../../../../../core/constants';

const ZEventLogTableRow = ({
  appStatus,
  imageQueue,
  creationTimeStamp,
  wiFiStatus,
  camera1Status,
  camera2Status,
  camera1FPS,
  camera2FPS,
  speed,
}: ZLogSessionDetail) => (
  <TableRow height={TABLE_ROW_HEIGHT}>
    <TableCell width="10%">
      <Grid>
        <GridColumn width="100%" padding="no">
          <Text block margin="no no xxSmall">
            {date(creationTimeStamp)}
          </Text>
          <Text block size="small">
            {timeStamp(creationTimeStamp)}
          </Text>
        </GridColumn>
      </Grid>
    </TableCell>
    <TableCell width="12%">
      <Text block margin="no no" size="xSmall" title={appStatus}>
        {appStatus}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block margin="no no" size="xSmall" title={wiFiStatus}>
        {wiFiStatus}
      </Text>
    </TableCell>
    <TableCell width="8%">
      <Text block margin="no no" size="xSmall" title={`${speed}`}>
        {speed}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block margin="no no" size="xSmall" title={camera1Status}>
        {camera1Status}
      </Text>
    </TableCell>
    <TableCell width="10%">
      <Text block margin="no no" size="xSmall" title={camera2Status}>
        {camera2Status}
      </Text>
    </TableCell>
    <TableCell width="9%">
      <Text block margin="no small" size="xSmall" title={`${camera1FPS}`}>
        {camera1FPS}
      </Text>
    </TableCell>
    <TableCell width="9%">
      <Text block margin="no small" size="xSmall" title={`${camera2FPS}`}>
        {camera2FPS}
      </Text>
    </TableCell>
    <TableCell width="22%" align="right">
      <Text block margin="no no" size="xSmall" title={imageQueue}>
        {imageQueue}
      </Text>
    </TableCell>
  </TableRow>
);

export default ZEventLogTableRow;
