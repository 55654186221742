import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

const WEEK_OF_MONTHS = mapKeys(
  [
    { id: '1', name: translate('customers.dayOfMonthList.first') },
    { id: '2', name: translate('customers.dayOfMonthList.second') },
    { id: '3', name: translate('customers.dayOfMonthList.third') },
    { id: '4', name: translate('customers.dayOfMonthList.fourth') },
    { id: '5', name: translate('customers.dayOfMonthList.last') },
  ],
  'id',
);

export default WEEK_OF_MONTHS;
