import { Fragment } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { CityAlertSetting } from 'src/vendors/interfaces/CityAlertSettings';
import { clearRouteMapSelectedFeature } from 'src/routes/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { getActiveCityAlertTypes } from 'src/fleet/components/forms/LocationAndCityAlertsSearchForm';
import { getQueryParams } from 'src/utils/services/queryParams';
import { isCityAlertFeatureEnabled, isLocationAlertFeatureEnabled } from 'src/vendors/ducks/features';
import { loadAlertTypes } from 'src/common/ducks';
import { loadCityAlertSettings, loadLocationAlerts, resetCityAlerts } from 'src/vendors/ducks';
import { loadFeatures } from 'src/vendors/ducks';
import { loadLocationAndCityAlertsForMap } from 'src/fleet/ducks';
import { LocationAlert } from 'src/vendors/interfaces/LocationAlert';
import { LocationAndCityAlertsParams } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { PageLoading } from 'src/common/components/styled';
import { resetSelectedLocationAlerts } from 'src/fleet/ducks/locationAndCityAlerts';
import { Resolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import LocationAndCityAlertsPage from './LocationAndCityAlertsPage';
import store from 'src/store';
import translate from 'src/core/services/translate';

const LocationAndCityAlertsPageResolver: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const vendorId = useSelector(currentVendorId);

  const {
    cityAlertTypeIds,
    endDate,
    noCityAlertTypeSelected,
    noLocationAlertTypeSelected,
    searchTerm,
    showCityAlertsUploadStatus,
    showLocationAlertsUploadStatus,
    startDate,
    statusTypeIds,
  } = getQueryParams<LocationAndCityAlertsParams>(search);

  const isCityAlertEnabled = isCityAlertFeatureEnabled(store.getState());
  const isLocationAlertEnabled = isLocationAlertFeatureEnabled(store.getState());

  useEffect(
    () => () => {
      dispatch(resetSelectedLocationAlerts());
      dispatch(clearRouteMapSelectedFeature());
    },
    [dispatch],
  );

  const loadDependencies = async () => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadAlertTypes()(dispatch).then(async () => {
        await loadFeatures(vendorId)(dispatch);

        const isCityAlertEnabled = isCityAlertFeatureEnabled(store.getState());
        const isLocationAlertEnabled = isLocationAlertFeatureEnabled(store.getState());

        dispatch(resetCityAlerts());

        let cityAlertOptions = undefined;
        let locationAlertOptions = undefined;

        if (isCityAlertEnabled) {
          const cityAlertSettings = await loadCityAlertSettings(vendorId)(dispatch);
          const cityAlertTypes = getActiveCityAlertTypes(cityAlertSettings).activeCityAlertSettings;
          cityAlertOptions = cityAlertTypes?.map((option: CityAlertSetting) => option.cityAlertType.id).toString();
        }

        if (isLocationAlertEnabled) {
          const locationAlerts = await loadLocationAlerts(vendorId)(dispatch);
          locationAlertOptions = locationAlerts?.map((locationAlert: LocationAlert) => locationAlert.id).toString();
        }

        const searchParams = {
          startDate,
          endDate,
          searchTerm,
          statusTypeIds,
          cityAlertTypeIds: noCityAlertTypeSelected ? undefined : cityAlertTypeIds || cityAlertOptions,
          locationAlertTypeIds: noLocationAlertTypeSelected ? undefined : locationAlertOptions,
        };

        loadLocationAndCityAlertsForMap(searchParams)(dispatch);
      }),
    ];

    return Promise.all(dependencies);
  };

  let alertsPageTitle = translate('vendors.cityAlerts.locationAndCityAlerts');
  if (isCityAlertEnabled && !isLocationAlertEnabled) alertsPageTitle = translate('vendors.cityAlerts.cityAlerts');
  if (!isCityAlertEnabled && isLocationAlertEnabled) alertsPageTitle = translate('vendors.cityAlerts.locationAlerts');

  return (
    <Fragment>
      <DocumentTitle>{alertsPageTitle}</DocumentTitle>

      <Resolver
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        successComponent={LocationAndCityAlertsPage}
        successProps={{
          alertsPageTitle,
          showCityAlertsUploadStatus,
          showLocationAlertsUploadStatus,
          vendorId,
        }}
      />
    </Fragment>
  );
};

export default LocationAndCityAlertsPageResolver;
