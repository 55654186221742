import { http } from '../../core/services/http';
import { LIMIT_PER_PAGE } from '../../core/constants';
import { TODAY_FORMATTED } from '../../core/constants';
import transformInferenceAudits from './transformInferenceAudits';

const loadInferenceAudits = (
  vendorId: number,
  date: Date | string = TODAY_FORMATTED,
  vehicleId: number,
  status: string,
  page: number = 1,
  limit: number = LIMIT_PER_PAGE,
) => {
  const inferenceAudits = {
    vendorId,
    date,
    vehicleId,
    status,
    page,
    limit,
  };
  const inferenceAuditsFormatted = Object.fromEntries(Object.entries(inferenceAudits).filter(([_, v]) => v != null));

  return http
    .get('inferenceAudit', {
      params: inferenceAuditsFormatted,
    })
    .then(response => transformInferenceAudits(response.data));
};

export default loadInferenceAudits;
