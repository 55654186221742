import React, { useState } from 'react';
import { camelize } from 'humps';
import { find, groupBy, keys, map, size, uniqueId } from 'lodash-es';

import { Icon } from '../../../../../core/components';
import { ContainerFacility, ContainerLocationForMap } from 'src/fleet/interfaces/containers';
import { CONTAINERS_MAP_ICONS_BY_ID, FACILITIES_MAP_COLORS_BY_ID, MULTIPLE } from 'src/fleet/constants/containersMap';
import { useSelector } from 'src/core/hooks/useSelector';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import {
  ContainersMapControlContainer,
  ContainersMapControlToggle,
  ContainersLegendItems,
  ContainersLegendItem,
  ContainersLegendItemColor,
} from 'src/fleet/components/styled/ContainersMap';
import { IconButtonIcon, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from '../../../../../core/services/translate';

interface Props {
  containerLocations: ContainerLocationForMap[];
  facilities: ContainerFacility[];
  isFacilitiesDisplayed?: boolean;
}

export const ContainersMapLegend: React.FC<Props> = ({ containerLocations, facilities, isFacilitiesDisplayed }) => {
  const [isOpen, setIsOpen] = useState(true);
  const equipmentTypes = useSelector(state => state.common.equipmentTypes.equipmentTypes);

  const containers: { id: number; t: number }[] = [];
  containerLocations.forEach(location => containers.push(...location.con));

  const groupedContainers = groupBy(containers, 't');

  const sortedContainers = Object.keys(groupedContainers).sort();

  const legendItemsContainers = sortedContainers.map(st => {
    const containers = groupedContainers[Number(st)];
    const color = CONTAINERS_MAP_ICONS_BY_ID[Number(st)].color;
    const type = find(equipmentTypes, { id: Number(st) }) as TechnicalType;
    return {
      size: containers.length,
      color,
      name: translate(`common.equipmentTypes.${camelize(type?.technicalName || '')}`) || st,
      hasBorder: false,
    };
  });

  const legendItemsFacilities: any[] | null | undefined = [];
  const legendItemsFacilitiesGrouped = groupBy(facilities, 'facilitySubType.id');
  keys(legendItemsFacilitiesGrouped).forEach(key => {
    const facilities = legendItemsFacilitiesGrouped[key];
    const color = FACILITIES_MAP_COLORS_BY_ID[Number(key)].color;
    const type = find(facilities, f => f.facilitySubType.id === Number(key))?.facilitySubType as TechnicalType;
    legendItemsFacilities.push({
      size: facilities.length,
      color,
      name: translate(`facilities.facilitySubTypes.${camelize(type?.technicalName || '')}`) || key,
      hasBorder: false,
    });
  });

  if (find(containerLocations, l => l.con.length > 1)) {
    legendItemsContainers.push({
      size: -1,
      color: CONTAINERS_MAP_ICONS_BY_ID[MULTIPLE].color,
      name: translate('dashboard.multiple'),
      hasBorder: true,
    });
  }

  if (
    (size(legendItemsFacilities) === 0 && isFacilitiesDisplayed) ||
    (size(legendItemsContainers) === 0 && !isFacilitiesDisplayed)
  )
    return null;

  return (
    <ContainersMapControlContainer isOpen={isOpen}>
      <ContainersMapControlToggle onClick={() => setIsOpen(!isOpen)}>
        <Icon height="14px" icon={isOpen ? 'arrowDownFill' : 'arrowUpFill'} />

        <div>{translate('routeTemplateBuilder.legend')}</div>
      </ContainersMapControlToggle>

      {isOpen && (
        <ContainersLegendItems>
          {map(isFacilitiesDisplayed ? legendItemsFacilities : legendItemsContainers, item => (
            <ContainersLegendItem key={uniqueId('legend_item_')} hasTopBorder={item.hasBorder}>
              {isFacilitiesDisplayed ? (
                <Box color={item.color}>
                  <IconButtonIcon customViewBox="0 0 15 15" icon="facility" margin="no xSmall no no" />
                </Box>
              ) : (
                <>
                  <ContainersLegendItemColor color={item.color} hasBorder={!isFacilitiesDisplayed} />
                  {item.size === -1 && (
                    <Text weight="medium" size="xLarge" padding="no no xSmall no">
                      +
                    </Text>
                  )}
                </>
              )}
              <Text padding="no xSmall">
                {item.name} {item.size !== -1 ? `- ${item.size}` : ''}
              </Text>
            </ContainersLegendItem>
          ))}
        </ContainersLegendItems>
      )}
    </ContainersMapControlContainer>
  );
};
