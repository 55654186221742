import React, { ChangeEvent, PureComponent } from 'react';

import { camelCase, debounce, map } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';

import { ACCOUNT_STATUSES } from '../../../common/constants';
import { accountStatusFormatText } from '../../services/accountStatusFormatText';
import { createUrl } from '../../../utils/services/queryParams';
import { Dropdown, MultiSelect, Input, TypedField, Switch } from '../../../core/components';
import {
  FormGroupClear,
  FormGroupClearContainer,
  Grid,
  GridColumn,
  PanelSearchIcon,
  PanelSection,
} from '../../../core/components/styled';
import { isRequired } from '../../../utils/services/validator';
import { loadCustomerTypes } from '../../ducks';
import { loadCustomerImportStatusTypeValues } from '../../ducks';
import customersFormInitialValuesSelector from '../../services/customersFormInitialValuesSelector';
import translate from '../../../core/services/translate';
import { DuckFunction } from '../../../contracts/ducks';
import { AppState } from '../../../store';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';

interface FormValues {
  accountNumber: string;
  customerStatusTypeIds: number;
  customerTypeId: number;
  searchTerm: string;
}

interface OwnProps extends RouteComponentProps {}

interface PropsWithoutReduxForm extends OwnProps {
  accountNumber?: string;
  customerTypeOptions: TechnicalType[];
  loadCustomerTypes: DuckFunction<typeof loadCustomerTypes>;
  loadCustomerImportStatusTypeValues: DuckFunction<typeof loadCustomerImportStatusTypeValues>;
  push: (url: string) => void;
  searchTerm?: string;
  isBillingFeatureActive?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

class CustomersForm extends PureComponent<Props> {
  static defaultProps = {
    customerTypeOptions: [],
  };

  componentDidMount() {
    const { loadCustomerTypes, loadCustomerImportStatusTypeValues } = this.props;
    loadCustomerTypes();
    loadCustomerImportStatusTypeValues();
  }

  onSearchTermChange = debounce((event, searchTerm) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { searchTerm, page: undefined }));
  }, 1200);

  onAccountSearchTermChange = debounce((event, accountNumber) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { accountNumber, page: undefined }));
  }, 1200);

  onResetSearch = () => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { accountNumber: undefined, searchTerm: undefined, page: undefined }));
  };

  onCustomerTypeChange = (_event: ChangeEvent<HTMLInputElement>, customerTypeId: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { customerTypeId: customerTypeId && customerTypeId.toString(), page: undefined }));
  };

  onCustomerStatusTypeChange = (_event: ChangeEvent<HTMLInputElement>, customerStatusTypeIds: number) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, {
        customerStatusTypeIds: customerStatusTypeIds && customerStatusTypeIds.toString(),
        page: undefined,
      }),
    );
  };

  onOverdueTypeChange = (_event: ChangeEvent<HTMLInputElement>, isOverdue: boolean) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, {
        isOverdue: isOverdue && isOverdue.toString(),
        page: undefined,
      }),
    );
  };

  render() {
    const { customerTypeOptions, accountNumber, searchTerm, isBillingFeatureActive } = this.props;

    const customerOptions = map(customerTypeOptions, value => ({
      label: translate(`common.customerTypes.${camelCase(value.technicalName)}`),
      value: value.id,
    }));

    const customerStatusTypeOptions = map(ACCOUNT_STATUSES, customerStatusType => ({
      label: customerStatusType.name,
      value: customerStatusType.id,
    }));

    return (
      <form>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <PanelSearchIcon />
            <GridColumn size="4/12">
              <FormGroupClearContainer>
                <Field
                  name="searchTerm"
                  placeholder={translate('customers.customerSearch')}
                  component={Input}
                  onChange={this.onSearchTermChange}
                  margin="no"
                  disabled={accountNumber ? true : false}
                />
                {searchTerm && <FormGroupClear onClick={this.onResetSearch} />}
              </FormGroupClearContainer>
            </GridColumn>
            <GridColumn size={isBillingFeatureActive ? '2/12' : '4/12'}>
              <FormGroupClearContainer>
                <Field
                  name="accountNumber"
                  placeholder={translate('customers.accountNumber')}
                  component={Input}
                  onChange={this.onAccountSearchTermChange}
                  margin="no"
                  disabled={searchTerm ? true : false}
                />
                {accountNumber && <FormGroupClear onClick={this.onResetSearch} />}
              </FormGroupClearContainer>
            </GridColumn>
            <GridColumn size="2/12">
              <TypedField
                component={Dropdown}
                onChange={this.onCustomerTypeChange}
                name="customerTypeId"
                validate={[isRequired]}
                props={{
                  margin: 'no',
                  options: customerOptions,
                  placeholder: translate('common.customerType'),
                  isClearable: true,
                }}
              />
            </GridColumn>
            <GridColumn size="2/12">
              <TypedField
                component={MultiSelect}
                onChange={this.onCustomerStatusTypeChange}
                name="customerStatusTypeIds"
                validate={[isRequired]}
                props={{
                  margin: 'no',
                  normalizeValues: Number,
                  options: customerStatusTypeOptions,
                  placeholder: translate('common.status'),
                  formatText: accountStatusFormatText,
                }}
              />
            </GridColumn>
            {isBillingFeatureActive && (
              <GridColumn size="2/12">
                <TypedField
                  name="isOverdue"
                  component={Switch}
                  onChange={this.onOverdueTypeChange}
                  props={{
                    label: translate('common.pastDue'),
                    margin: 'xxSmall no no',
                  }}
                />
              </GridColumn>
            )}
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const initialValues = customersFormInitialValuesSelector(ownProps.location.search);
  const formSelector = formValueSelector('customers');

  return {
    accountNumber: formSelector(state, 'accountNumber'),
    searchTerm: formSelector(state, 'searchTerm'),
    customerTypeOptions: state.customers.customerTypes.customerTypes,
    initialValues,
    isBillingFeatureActive: billingFeatureStatusSelector(state.vendors.features.features),
  };
};

const mapDispatchToProps = { loadCustomerTypes, loadCustomerImportStatusTypeValues, push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm<FormValues, PropsWithoutReduxForm>({
      enableReinitialize: true,
      form: 'customers',
    })(CustomersForm),
  ),
);
