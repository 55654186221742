import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { withRouter, Redirect, RouteComponentProps } from 'react-router';
import { size } from 'lodash-es';

import { AppState } from '../../../store';
import { currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { integerRegex, isGuid } from '../../../utils/services/validator';
import { isSuperAdminSelector } from '../../../account/ducks';
import { LabelButton, LabelButtonWrapper, Popover } from '../../../core/components/styled';
import { deleteDefaultVendor, loadVendors, loadAssignedVendors } from '../../ducks';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { PopoverWrapper, TypedResolver } from '../../../core/components';
import SelectDefaultVendorModal from './SelectDefaultVendorModal';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  allVendors: any[];
  deleteDefaultVendor: () => void;
  isSuperAdmin: boolean;
  loadAssignedVendors: (vendorId: number, userId?: string) => Promise<any>;
  loadVendors: () => Promise<any>;
  margin?: string;
  onDefaultVendorSelected?: (vendor: number) => {} | void;
  userAssignedVendors: any[];
  userId: string;
  vendorId: number;
  vendorName: string;
}

interface State {
  isModalOpen: boolean;
}

class SelectDefaultVendorModalToggle extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isModalOpen: false };
  }

  componentDidMount() {
    const { isSuperAdmin, userId, vendorId, loadAssignedVendors, loadVendors } = this.props;
    return isSuperAdmin ? loadVendors() : loadAssignedVendors(vendorId, userId);
  }

  onDefaultVendorSelected = (vendor: number) => {
    this.closeModal();
    if (this.props.onDefaultVendorSelected) this.props.onDefaultVendorSelected(vendor);
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  isChangeVendorDisabled = () => {
    let isChangeVendorDisabled = false;
    const currentPath = window.location.pathname.split('/');

    currentPath.forEach(path => {
      if ((path && integerRegex.test(path)) || (path && isGuid(path))) {
        isChangeVendorDisabled = true;
      }
    });
    return isChangeVendorDisabled;
  };

  render() {
    const { allVendors, deleteDefaultVendor, isSuperAdmin, userAssignedVendors, vendorId, vendorName } = this.props;

    const availableVendors = isSuperAdmin ? allVendors : userAssignedVendors;
    const isDefaultVendorExist = availableVendors.find(vendor => vendor.id === vendorId);

    if (size(availableVendors) && !isDefaultVendorExist) {
      deleteDefaultVendor();
      return <Redirect to="/select-default-vendor" />;
    }
    const { isModalOpen } = this.state;

    return (
      <Fragment>
        <LabelButtonWrapper>
          <PopoverWrapper
            triggerButton={
              <LabelButton onClick={this.openModal} id="vendor-button" disabled={this.isChangeVendorDisabled()}>
                {vendorName}
              </LabelButton>
            }
            popoverContent={
              this.isChangeVendorDisabled() && (
                <Popover>{translate('common.alertMessages.unableToChangeVendorFromThisPage')}</Popover>
              )
            }
            isBottomPositioned
          />
        </LabelButtonWrapper>

        {isModalOpen && (
          <TypedResolver
            loadingComponent={PageLoadingOverlay}
            successComponent={SelectDefaultVendorModal}
            successProps={{ onDefaultVendorSelected: this.onDefaultVendorSelected, closeModal: this.closeModal }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  allVendors: state.vendors.vendors.vendors,
  isSuperAdmin: isSuperAdminSelector(state.account.login),
  userId: currentUserIdSelector(state.account.login) as any,
  userAssignedVendors: state.vendors.vendorAccess.assignedUserVendors,
  vendorId: state.vendors.defaultVendor.defaultVendor.id,
  vendorName: state.vendors.defaultVendor.defaultVendor.name,
});

const mapDispatchToProps = { deleteDefaultVendor, loadVendors, loadAssignedVendors };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectDefaultVendorModalToggle));
