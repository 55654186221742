import React, { PureComponent } from 'react';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import { reset } from 'redux-form';

import { AppState } from '../../../store';
import { BetaLink, BetaBaseWrapper } from '../../../routes/components/styled/RouteTracker';
import confirm from '../../../core/services/confirm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { hideNotesModal } from '../../../fleet/ducks';
import { loadOpportunity, updateHaulerOpportunity } from '../../ducks';
import { NotesState } from '../../../fleet/interfaces/RubiconDispatchesNotes';
import { OpportunityForm, OpportunitiesFilterForm } from '../forms';
import { OpportunityInfoIcon, OpportunityToggleContainer, OpportunityToggle, OpportunityLink } from '../styled';
import {
  PageActions,
  PageContent,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageSubtitle,
  PageTitle,
} from '../../../common/components/styled';
import { Panel, PanelSectionGroup, PanelSection, Text, Grid, GridColumn, Hide } from '../../../core/components/styled';
import RubiconDispatchesNotesModalResolver from '../../../fleet/components/modal/RubiconDispatchesNotesModalResolver';
import translate from '../../../core/services/translate';
import { YES, NO } from '../../constants';

interface Props extends RouteComponentProps {
  isLoading: boolean;
  notificationsCount: any;
  opportunities?: any[];
  deliveries?: any[];
  haulerName?: string;
  errorMessage?: string;
  location: any;
  userId: number;
  vendorId: number;
  match: any;
  notesModal: NotesState['modal'];
  hideNotesModal: DuckAction<typeof hideNotesModal>;
  loadOpportunity: DuckFunction<typeof loadOpportunity>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
  push: typeof push;
  reset: typeof reset;
}

interface State {
  isExpanded: boolean;
}

class OpportunityPage extends PureComponent<Props, State> {
  readonly state: State = {
    isExpanded: true,
  };

  componentDidUpdate(prevProps: Props) {
    const { location, userId, vendorId } = prevProps;
    const {
      loadOpportunity,
      location: { search },
      match: {
        params: { token },
      },
    } = this.props;
    const { showActionRequired } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;

    if (search !== location.search) {
      loadOpportunity(userId, token, vendorId, isActionRequired);
    }
  }

  toggle = () => {
    this.setState(({ isExpanded }) => ({
      isExpanded: !isExpanded,
    }));
  };

  onActionRequiredToggleChange = (event: any, isActionRequired: boolean) => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(createUrl(pathname, search, { showActionRequired: isActionRequired ? YES : NO }));
  };

  closeNotesModal = async (isReload: boolean, formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    const { reset, hideNotesModal } = this.props;
    reset('notesForm');
    hideNotesModal();
  };

  render() {
    const {
      opportunities,
      isLoading,
      haulerName,
      errorMessage,
      match: {
        params: { token },
      },
      notificationsCount,
      notesModal,
      userId,
    } = this.props;
    const { isExpanded } = this.state;
    const opportunitiesCount = (!!notificationsCount && notificationsCount.opportunities) || 0;
    const betaOpportunitiesPageUrl =
      token && !userId ? `/account/login?opportunitiesToken=${token}` : '/opportunities/';
    return (
      <PageContent>
        <PageHeader withSubTitle>
          <PageDetails>
            <PageTitleContainer>
              {(!errorMessage || !opportunities || !!size(opportunities) || opportunities.length > 1) && (
                <PageTitle>{translate('opportunity.opportunities.currentOpportunities')}</PageTitle>
              )}
            </PageTitleContainer>
          </PageDetails>
          <PageActions flex>
            <BetaBaseWrapper>
              <BetaLink to={betaOpportunitiesPageUrl} color="primary">
                {translate('routes.newLayout')}
              </BetaLink>
            </BetaBaseWrapper>
          </PageActions>
        </PageHeader>
        <PageTitleContainer>
          <PageSubtitle size="medium">{haulerName}</PageSubtitle>
        </PageTitleContainer>
        <Panel>
          <PanelSectionGroup>
            {!!size(opportunities) && (
              <Grid>
                <OpportunityInfoIcon color="primary" margin="xSmall xSmall xxSmall" />

                <GridColumn size="12/12" align="left">
                  <Hide hide={!isExpanded}>
                    <PanelSection>
                      <Text weight="normal" size="medium" margin="xSmall no xxSmall">
                        {translate('opportunity.opportunities.messages.message1')}
                      </Text>
                    </PanelSection>
                  </Hide>
                  <Hide hide={isExpanded}>
                    <PanelSection>
                      <Text weight="normal" size="medium" margin="xSmall no xxSmall">
                        {translate('opportunity.opportunities.messages.message2')}
                      </Text>
                    </PanelSection>
                    <PanelSection>
                      <Text weight="normal" size="medium" margin="xSmall xSmall xxSmall">
                        * {translate('opportunity.opportunities.messages.disclaimer')}
                      </Text>
                    </PanelSection>
                    <PanelSection>
                      <Text block weight="normal" size="medium" margin="xSmall xSmall xxSmall">
                        {translate('opportunity.opportunities.messages.common')}
                        <OpportunityLink href="mailto:smbsourcing@rubicon.com?cc=smbsourcing@rubicon.com&subject=Greetings%20from%20Rubicon!&body=Greetings Of The Day!">
                          smbsourcing@rubicon.com.
                        </OpportunityLink>
                      </Text>
                    </PanelSection>
                  </Hide>
                  <OpportunityToggleContainer>
                    <OpportunityToggle onClick={this.toggle}>
                      {isExpanded ? translate('common.showMore') : translate('common.showLess')}
                    </OpportunityToggle>
                  </OpportunityToggleContainer>
                </GridColumn>
              </Grid>
            )}

            {errorMessage && (
              <Text block weight="medium" size="large" color="alert">
                {createTranslationKey(errorMessage, 'opportunity.opportunities.alertMessages') ===
                'opportunity.opportunities.alertMessages.vendorInformationNotFound'
                  ? translate(createTranslationKey(errorMessage, 'opportunity.opportunities.alertMessages'))
                  : translate('opportunity.opportunities.alertMessages.errorInSubmit')}
              </Text>
            )}

            <OpportunitiesFilterForm
              notificationsCount={opportunitiesCount}
              onActionRequiredToggleChange={this.onActionRequiredToggleChange}
            />
          </PanelSectionGroup>
        </Panel>
        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <PanelSection>
              {!!size(opportunities) ? (
                <OpportunityForm />
              ) : (
                <Text block weight="medium" size="large" margin="small">
                  {translate('opportunity.opportunities.noRecord')}
                </Text>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
        {!!notesModal && <RubiconDispatchesNotesModalResolver onCancel={this.closeNotesModal} />}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  opportunities: state.opportunity.opportunity.opportunities,
  notificationsCount: state.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts,
  notesModal: state.fleet.notes.modal,
  deliveries: state.opportunity.opportunity.deliveries,
  haulerName: state.opportunity.opportunity.haulerName,
  errorMessage: state.opportunity.opportunity.errorMessage,
  isLoading: state.opportunity.opportunity.isLoading,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  hideNotesModal,
  loadOpportunity,
  updateHaulerOpportunity,
  push,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpportunityPage));
