import { useEffect } from 'react';

import { fitMapboxBounds } from '../utils/dashboardMapbox';
import { useSelector } from 'src/core/hooks/useSelector';

const useFitBoundsDashboardMapGL = (isLoading: boolean, isSourceAdvancedSearch: boolean, isAutoRefresh?: boolean) => {
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedFeature);

  // after loading data, fit map to bounds
  useEffect(() => {
    if (!isLoading && !isSourceAdvancedSearch && !isAutoRefresh) fitMapboxBounds();
  }, [isLoading, isSourceAdvancedSearch, isAutoRefresh]);

  // after selecting feature, fit map to that feature
  useEffect(() => {
    if (selectedFeature && selectedFeature.zoomToIt && !isSourceAdvancedSearch && !isAutoRefresh)
      fitMapboxBounds(selectedFeature.id);
  }, [selectedFeature, isSourceAdvancedSearch, isAutoRefresh]);
};

export default useFitBoundsDashboardMapGL;
