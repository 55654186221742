import React, { PureComponent } from 'react';

import { filter, find, get, map, size } from 'lodash-es';
import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { MultiSelect, TypedField } from 'src/core/components';
import { FieldProps } from 'src/core/components/FieldProps';
import { MultiSelectProps } from 'src/core/components/MultiSelect';
import translate from 'src/core/services/translate';
import { AppState } from 'src/store';
import { LocationAlert } from 'src/vendors/interfaces/LocationAlert';

interface OwnProps {
  multiSelectProps: Partial<MultiSelectProps>;
  locationAlerts: LocationAlert[];
}

type Props = OwnProps & FieldProps & WrappedFieldProps;

class LocationAlertsMultiSelect extends PureComponent<Props> {
  onChange = (_: unknown, value: number[]) => {
    this.props.input.onChange(value);
  };

  formatText = (selectedOptions: number[], allOptionsSelected: boolean) => {
    if (allOptionsSelected && size(selectedOptions) !== 1) {
      return translate('customers.allLocationAlerts');
    }

    return size(selectedOptions) === 1
      ? get(find(this.props.locationAlerts, { id: Number(selectedOptions[0]) }), 'title')
      : translate('customers.xLocationAlertsSelected', { selected: size(selectedOptions) });
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      multiSelectProps,
      locationAlerts,
    } = this.props;

    const locationAlertOptions = map(filter(locationAlerts, { isActive: true }), locationAlert => ({
      label: locationAlert.title,
      value: locationAlert.id,
    }));

    return (
      <TypedField
        name={name}
        component={MultiSelect}
        onChange={this.onChange}
        props={{
          ...multiSelectProps,
          canCheckAll: false,
          defaultToAll: false,
          formatText: this.formatText,
          isClearable: true,
          label: label || (withLabel ? translate('customers.locationAlerts') : undefined),
          normalizeValues: Number,
          options: locationAlertOptions,
          placeholder: placeholder || (withPlaceholder ? translate('customers.locationAlerts') : undefined),
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  locationAlerts: state.customers.locationAlerts.locationAlerts,
});

export default connect(mapStateToProps)(LocationAlertsMultiSelect);
