import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { debounce, filter, find, flatten, forEach, get, map, size } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import { PermissionGuard } from 'src/account/components';
import { GEO_FENCES_DAILY_ROUTE, GEO_FENCES_ROUTE_TEMPLATE } from 'src/account/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { getMapBounds } from 'src/common/components/map/util';
import useGeoFenceControllerMapbox, { NEW_GEO_FENCE_ID } from 'src/common/hooks/geoFenceControllerMapbox';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButtonIcon } from 'src/core/components/styled';
import {
  GEO_FENCE_OPTIONS,
  GEO_FENCE_OPTIONS_SAT,
  GEO_FENCE_OPTIONS_SELECTED,
  MAP_CITY_ZOOM_IN_BIGGER,
} from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { MapGLWrapper } from 'src/customers/components/styled';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import {
  downloadTravelPathGeoJsonFile,
  loadGeoFences,
  resetGeoFences,
  resetTravelPathDetails,
  setRouteMapViewport,
  setShowTravelPath,
} from 'src/routes/ducks';
import { setIsDrawingMode } from 'src/routes/ducks/mapControls';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import {
  checkIfGeoFenceIsEnabled,
  isNavi3FeatureEnabled,
  isTravelPathNavigationFeatureEnabled,
} from 'src/vendors/ducks/features';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import OnMapFiltersForm, {
  OnMapFiltersFormValues,
} from '../../dispatchBoard/dispatchBoardMapSections/map/OnMapFiltersForm';
import RouteGeoFenceAlerts from '../../geoFences/components/RouteGeoFenceAlerts';
import { DrawingInstructions } from '../../routeTemplateBuilder/routeTemplateBuilderMap/DrawingInstructions';
import RouteMapGeoFencesGL from '../../routes/routePageSections/routeMap/geoFences/RouteMapGeoFencesGL';
import RouteMapTravelPathGL from '../../routes/routePageSections/routeMap/travelPath/RouteMapTravelPathGL';
import RouteMapClusters from './RouteMapClusters';
import RouteMapRouteStopsGL from './routeStops/RouteMapRouteStopsGL';

const normalizeFilterArray = (array: boolean[]) =>
  array.reduce((acc: number[], cur, index) => (cur ? [...acc, index] : acc), []);

export interface SelectedLocation {
  customerName: string;
  id: number;
  latitude: number;
  locationName: string;
  longitude: number;
  orderNumber: number;
}

export interface SelectedTemplateLocation {
  latitude: number;
  longitude: number;
  serviceContractRouteTemplateId: number;
}

type RouteMapGLProps = {
  alertsAreReadOnly?: boolean;
  isMapLoading?: boolean;
  hideTimeline?: boolean;
  mapShouldFitBounds?: boolean;
  optimizedRouteLocationsLength?: number;
  routeId?: number;
  routeLocations?: RouteLocation[];
  routeName?: string;
  routeStatusTypeId?: number;
  selectedLocation?: SelectedLocation;
  selectedTemplateLocation?: SelectedTemplateLocation;
  showTimeline?: boolean;
  formKey?: string;
  routeIsNotScheduled?: boolean;
  isRoutePlannerPage?: boolean;
  isGeoFenceHidden?: boolean;
  isInteractive?: boolean;
  onSelectLocations?: (locations: any[]) => void;
  onMapLoadComplete?: () => void;
};

const RouteMapGL = ({
  routeIsNotScheduled,
  routeLocations,
  onSelectLocations,
  onMapLoadComplete,
  optimizedRouteLocationsLength,
  formKey,
  alertsAreReadOnly,
  isGeoFenceHidden,
  isRoutePlannerPage,
  routeId,
  isInteractive,
}: RouteMapGLProps) => {
  const vendorId = useSelector(currentVendorId);
  const vendor = useSelector(state => state.vendors.vendor.vendor) as any as Vendor;
  const dispatch = useDispatch();
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();

  const editorRef = useRef<Editor>(null);

  const [isSelectingWithPolygon, setIsSelectingWithPolygon] = useState<boolean>(false);
  const [polygon, setPolygon] = useState<any>();
  const [editPolygon, setEditPolygon] = useState<boolean>(true);
  const [shouldCenterByVendor, setShouldCenterByVendor] = useState<boolean>(!routeLocations?.length);
  const [editedGeoFence, setEditedGeoFence] = useState<any>();
  const [drawMode, setDrawMode] = useState<EditingMode | DrawPolygonMode>();
  const [showRouteGeoFence, setShowRouteGeoFence] = useState(true);
  const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false);
  const [isInGeoFenceEditMode, setIsInGeoFenceEditMode] = useState<boolean>(false);

  const { viewport, isDrawingMode } = useSelector(state => state.routes.mapControls);

  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });
  const [isMapFiltersOpen, setIsMapFiltersOpen] = useState(false);

  const routeTemplate = useSelector(state => state.routes.routeTemplate.routeTemplate) as any;

  const isRouteTemplate = routeTemplate != null;

  const route = useSelector(state => state.routes.route.route) as any;
  const { isDownloadingGeoJsonFile, routeTemplateId, showTravelPath, travelPathData } = useSelector(
    state => state.routes.travelPath,
  );
  const geoFenceJsonList = useSelector(state => state.routes.geoFences?.geoFences?.geoFences);
  const geoFenceIsActive = useSelector(state => checkIfGeoFenceIsEnabled(state));
  const stateGeoFence = useSelector(state => state.routes.geoFence.geoFence);
  const geoFenceEditing = useSelector(state => state.routes.geoFence.geoFenceEditing);

  const isNaviV3FeatureEnabled = useSelector(isNavi3FeatureEnabled);
  const isTravelPathFeatureEnabled = useSelector(isTravelPathNavigationFeatureEnabled);

  const formValues = useSelector(state => (formKey ? getFormValues(formKey)(state) : null)) as any;
  const dragPan = useSelector(s => s.routes.routeMapSettings.dragPan);

  const geoFenceExists = useMemo(() => {
    const hasFormGeoFence =
      formValues?.geoFence?.geoFenceCoordinates?.length > 0 || formValues?.geoFence?.hasDeletedAllPolygons;
    const hasStateGeoFence = stateGeoFence?.id && stateGeoFence?.geoFenceCoordinates?.length > 0;

    if (hasFormGeoFence || (hasStateGeoFence && (routeTemplate?.id || route?.id))) {
      setShowRouteGeoFence(true);
      return true;
    }

    return false;
  }, [
    formValues?.geoFence?.geoFenceCoordinates?.length,
    formValues?.geoFence?.hasDeletedAllPolygons,
    stateGeoFence?.id,
    stateGeoFence?.geoFenceCoordinates?.length,
    routeTemplate?.id,
    route?.id,
  ]);

  const geoFenceId = useMemo(() => {
    if (stateGeoFence && stateGeoFence.id && (routeTemplate?.id || route?.id)) return stateGeoFence.id;
    return undefined;
  }, [stateGeoFence, routeTemplate?.id, route?.id]);

  const geoFence = useMemo(() => {
    if (formValues && formValues.geoFence) return formValues.geoFence;
    return stateGeoFence;
  }, [formValues, stateGeoFence]);

  const mapCenterByVendor = useMemo(() => {
    if (vendor?.id) {
      return getMapBounds([{ latitude: vendor.homeAddress.latitude, longitude: vendor.homeAddress.longitude }], {
        capZoom: MAP_CITY_ZOOM_IN_BIGGER,
      });
    }
  }, [vendor?.id, vendor?.homeAddress]);

  const {
    getAllCoords,
    allGeoFencesGeoJson,
    mapGeoFences,
    selectedGeoFenceGeo,
    hasChanges,
    addGeoFence,
    addPolygonToGeoFence,
    bulkAddGeoFences,
    emptyGeoFences,
    getGeoFenceCoords,
    getGeoJsonForGeoFence,
    removeUnsavedGeoFenceOrPolygons,
    removePolygonFromGeoFence,
    selectGeoFencePolygon,
    unselectGeoFencePolygon,
    updatePolygonInGeoFence,
    undoPolygonInGeoFence,
    checkIfGeoFenceExists,
  } = useGeoFenceControllerMapbox();

  /************************************************
   * GEO-FENCES Management
   ************************************************/

  const updateGeoFence = useCallback(() => {
    if (isInteractive && mapInstance && formKey) {
      let geoFence;
      if (geoFenceId && checkIfGeoFenceExists(geoFenceId)) {
        geoFence = find(mapGeoFences, { id: geoFenceId });
      } else {
        geoFence = find(mapGeoFences, { id: NEW_GEO_FENCE_ID });
      }
      if (geoFence) {
        const hasOnlyOnePolygon = geoFence.polygons.length === 1;
        const coordinates = hasOnlyOnePolygon
          ? [[geoFence.polygons[0].coordinates]]
          : geoFence.polygons.map(polygon => [polygon.coordinates]);

        dispatch(change(formKey, 'geoFence.geoFenceCoordinates', coordinates ? coordinates : []));

        if (geoFence.polygons.length === 0) dispatch(change(formKey, 'geoFence.hasDeletedAllPolygons', true));
        else dispatch(change(formKey, 'geoFence.hasDeletedAllPolygons', false));
      }
    }
  }, [checkIfGeoFenceExists, dispatch, formKey, geoFenceId, isInteractive, mapGeoFences, mapInstance]);

  // adding geo-fences from the list
  useEffect(() => {
    if (!drawMode && !geoFenceEditing) {
      const bulkToSet = (geoFenceJsonList || []).map((geoFence: any) => {
        const parsedGeoFenceJson = JSON.parse(geoFence.geoFenceJson);
        return {
          ...geoFence,
          geoFenceCoordinates: parsedGeoFenceJson?.geometry?.coordinates,
        };
      });
      bulkToSet.length && bulkAddGeoFences(bulkToSet);
    }

    if (geoFence && !isGeoFenceHidden && showRouteGeoFence && geoFenceExists) {
      addGeoFence(geoFence);
    }
  }, [geoFenceJsonList, showRouteGeoFence, isInGeoFenceEditMode, geoFenceExists, showTravelPath]); // eslint-disable-line react-hooks/exhaustive-deps

  // clean up when drawing mode is disabled
  useEffect(() => {
    if (isDrawingMode) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
        setIsAddingPolygon(true);
      }
    } else {
      if (editedGeoFence && editorRef.current) {
        setEditedGeoFence(undefined);
      }

      if (!!drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [drawMode, dispatch, isDrawingMode, editedGeoFence]);

  useEffect(
    () => () => {
      dispatch(resetGeoFences());
      dispatch(setIsDrawingMode(false));
      dispatch(resetTravelPathDetails());
    },
    [dispatch],
  );

  const updateGeoFenceHistoryDebounced = useMemo(
    () =>
      debounce((newPolygons: any) => {
        const updatedPolygon = find(newPolygons, (polygon: any) => {
          return polygon.properties?.polygonId === selectedGeoFenceGeo?.polygonId;
        });

        if (updatedPolygon && selectedGeoFenceGeo) {
          updatePolygonInGeoFence(
            selectedGeoFenceGeo?.id,
            selectedGeoFenceGeo?.polygonId,
            updatedPolygon.geometry.coordinates[0],
          );
        }
      }, 200),
    [selectedGeoFenceGeo, updatePolygonInGeoFence],
  );

  const onEditGeoFence = (event: any) => {
    const newPolygons = event.data;
    const last = newPolygons.length - 1;

    if (event.editType === 'addFeature') {
      setIsAddingPolygon(false);
      setDrawMode(new EditingMode());
      const polygonId = addPolygonToGeoFence(newPolygons[last].geometry.coordinates[0], geoFence?.id);
      setEditedGeoFence([
        ...editedGeoFence,
        { ...newPolygons[last], properties: { polygonId, geoFenceId: geoFence?.id } },
      ]);
    } else {
      updateGeoFenceHistoryDebounced(newPolygons);
      setEditedGeoFence(newPolygons);
    }
  };

  // action handlers
  const handleEnterDrawMode = useCallback(() => {
    dispatch(setIsDrawingMode(true));
    !drawMode && setDrawMode(new DrawPolygonMode());
    setIsAddingPolygon(true);
  }, [dispatch, drawMode]);

  const editGeoFence = useCallback(() => {
    dispatch(setIsDrawingMode(true));
    setIsInGeoFenceEditMode(true);
    setDrawMode(new EditingMode());
    setEditedGeoFence(getGeoJsonForGeoFence(geoFenceId || NEW_GEO_FENCE_ID, geoFence));
  }, [dispatch, geoFence, geoFenceId, getGeoJsonForGeoFence]);

  const handleAddPolygon = useCallback(() => {
    setDrawMode(new DrawPolygonMode());
    setIsAddingPolygon(true);
    unselectGeoFencePolygon();
  }, [unselectGeoFencePolygon]);

  const handleUndoGeoFence = useCallback(() => {
    if (selectedGeoFenceGeo) undoPolygonInGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
  }, [selectedGeoFenceGeo, undoPolygonInGeoFence]);

  useEffect(() => {
    setEditedGeoFence(getGeoJsonForGeoFence(geoFence.id || NEW_GEO_FENCE_ID));
  }, [geoFence.id, getGeoJsonForGeoFence]);

  const handleExitDrawMode = useCallback(
    (uncheckStops?: boolean) => {
      dispatch(setIsDrawingMode(false));
      // If there is an unsaved geoFence remove it from the list
      unselectGeoFencePolygon();
      !uncheckStops && removeUnsavedGeoFenceOrPolygons();
      setIsAddingPolygon(false);
      setPolygon(null);
      setEditPolygon(true);
      setEditedGeoFence(undefined);
      setIsInGeoFenceEditMode(false);
      uncheckStops && onSelectLocations && onSelectLocations([]);
    },
    [dispatch, onSelectLocations, removeUnsavedGeoFenceOrPolygons, unselectGeoFencePolygon],
  );

  const handleSelectInGeoFenceEditor = useCallback(
    (selected: any) => {
      if (selected?.selectedFeatureIndex === null) {
        unselectGeoFencePolygon();
        return;
      }

      if (selected?.selectedFeature?.properties?.polygonId && selected?.selectedFeature?.properties?.geoFenceId) {
        selectGeoFencePolygon(
          selected?.selectedFeature?.properties?.geoFenceId,
          selected?.selectedFeature?.properties?.polygonId,
        );
      } else {
        const geoFenceId = geoFence?.id || NEW_GEO_FENCE_ID;
        selectGeoFencePolygon(geoFenceId, selected?.selectedFeatureIndex);
      }
    },
    [geoFence?.id, selectGeoFencePolygon, unselectGeoFencePolygon],
  );

  const handleDeletePolygon = useCallback(() => {
    if (selectedGeoFenceGeo) {
      removePolygonFromGeoFence(selectedGeoFenceGeo.id, selectedGeoFenceGeo.polygonId);
      setEditedGeoFence([
        ...filter(editedGeoFence, (p: any) => p.properties?.polygonId !== selectedGeoFenceGeo.polygonId),
      ]);
      unselectGeoFencePolygon();
    }
  }, [selectedGeoFenceGeo, removePolygonFromGeoFence, editedGeoFence, unselectGeoFencePolygon]);

  const handleSaveGeoFence = useCallback(() => {
    updateGeoFence();

    handleExitDrawMode();
  }, [handleExitDrawMode, updateGeoFence]);

  /************************************************
   * End of GEO-FENCES Management
   * *********************************************/

  // Select stops via laso tool
  const selectPointsInPolygon = useCallback(
    (newPolygon: any, points?: RouteLocation[]) => {
      const includedPoints =
        points
          ?.filter(p => {
            const serviceContractBinDetails = get(p.service, 'serviceContractBinDetails');
            const serviceLatitude = serviceContractBinDetails
              ? serviceContractBinDetails[0].displayLatitude
              : p.location.address.latitude;
            const serviceLongitude = serviceContractBinDetails
              ? serviceContractBinDetails[0].displayLongitude
              : p.location.address.longitude;

            return (
              serviceLongitude &&
              serviceLatitude &&
              booleanPointInPolygon([serviceLongitude, serviceLatitude], newPolygon)
            );
          })
          ?.map(p => p) || [];

      onSelectLocations && onSelectLocations(includedPoints);
    },
    [onSelectLocations],
  );

  const selectPointsInPolygonDebounced = useMemo(() => debounce(selectPointsInPolygon, 200), [selectPointsInPolygon]);

  const checkLocationsInPolygon = (event: any) => {
    const newPolygon = event.data;
    setPolygon(newPolygon);

    if (event.editType === 'addFeature') {
      selectPointsInPolygon(newPolygon[0], routeLocations);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(newPolygon[0], routeLocations);
    }
  };

  const geoFenceFiltersInitialValues = {
    geoFenceSubFilters: [] as boolean[],
    geoFencesTypesFilters: [] as boolean[],
    geoFenceSearchTerm: '',
    showTravelPath: false,
  } as OnMapFiltersFormValues;

  const handleSubmitGeoFenceFilters = async (formData: OnMapFiltersFormValues) => {
    const normalizedGeoFencesIds = normalizeFilterArray(formData.geoFenceSubFilters);
    const normalizedGeoFencesTypesIds = normalizeFilterArray(formData.geoFencesTypesFilters);
    emptyGeoFences();
    dispatch(resetGeoFences());

    if (normalizedGeoFencesIds.length > 0 && normalizedGeoFencesTypesIds.length > 0) {
      vendorId &&
        loadGeoFences({
          vendorId,
          geoFenceZoneTypeIds: normalizedGeoFencesTypesIds.toString(),
          limit: 200,
          geoFenceIdsCSV: normalizedGeoFencesIds.toString(),
        })(dispatch);
    } else {
      emptyGeoFences();
      dispatch(resetGeoFences());
    }

    if (formData.showTravelPath) {
      if (routeId && routeId !== routeTemplateId) await downloadTravelPathGeoJsonFile(undefined, routeId)(dispatch);
      dispatch(setShowTravelPath(true));
    } else {
      dispatch(setShowTravelPath(false));
    }

    setIsMapFiltersOpen(false);
  };

  // map mouse events
  useEffect(() => {
    mapInstance?.once('load', () => {
      mapInstance.on('mousemove', event => {
        const features = mapInstance
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);

        mapInstance.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      mapInstance.on('mouseleave', () => {
        mapInstance.getCanvas().style.cursor = '';
      });
    });
  }, [dispatch, mapInstance]);

  // viewport vendor
  useEffect(() => {
    if (shouldCenterByVendor) {
      mapCenterByVendor && !mapViewStyle.mapCenter && dispatch(setRouteMapViewport(mapCenterByVendor));
      setShouldCenterByVendor(false);
    } else if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      dispatch(setRouteMapViewport(bounds));
    }
  }, [mapCenterByVendor, dispatch, mapViewStyle.mapCenter, shouldCenterByVendor, mapViewStyle.mapZoom]);

  // map viewport based on displayed features
  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    const routeLocationsPositions = flatten(
      map(
        routeLocations,
        ({
          service,
          location: {
            address: { latitude, longitude },
          },
        }) => {
          const serviceContractBinDetails = get(service, 'serviceContractBinDetails');

          return serviceContractBinDetails
            ? map(serviceContractBinDetails, ({ displayLatitude, displayLongitude }) => ({
                latitude: displayLatitude || latitude,
                longitude: displayLongitude || longitude,
              }))
            : {
                latitude,
                longitude,
              };
        },
      ),
    );

    forEach(routeLocationsPositions, ({ latitude, longitude }) => {
      points.push({ latitude, longitude });
    });

    showTravelPath &&
      travelPathData?.features?.forEach(travelPath => {
        travelPath.geometry.coordinates.forEach((coordinate: any[]) => {
          points.push({ latitude: coordinate[1], longitude: coordinate[0] });
        });
      });

    if (!!points.length && !isInGeoFenceEditMode && !isSelectingWithPolygon) {
      points.push(...getAllCoords);
      const bounds = getMapBounds(points, {
        capZoom: MAP_CITY_ZOOM_IN_BIGGER,
      });
      dispatch(setRouteMapViewport(bounds));
    }
  }, [dispatch, routeLocations?.length, mapGeoFences.length, showTravelPath, travelPathData]); // eslint-disable-line react-hooks/exhaustive-deps

  // focus on geoFence when entering edit mode
  useEffect(() => {
    if (isInGeoFenceEditMode && !isSelectingWithPolygon) {
      const points = getGeoFenceCoords(geoFenceId || NEW_GEO_FENCE_ID);

      if (!!points.length) {
        const bounds = getMapBounds(points, {
          capZoom: MAP_CITY_ZOOM_IN_BIGGER,
        });
        dispatch(setRouteMapViewport(bounds));
      }
    }
  }, [isInGeoFenceEditMode, isSelectingWithPolygon]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      dispatch(setIsDrawingMode(false));
      dispatch(resetGeoFences());
    },
    [dispatch],
  );

  useEffect(() => {
    handleExitDrawMode(true);
    setIsSelectingWithPolygon(false);
    setEditPolygon(true);
  }, [handleExitDrawMode, routeLocations?.length]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: mapInstance?.getCenter(),
      mapZoom: mapInstance?.getZoom(),
    });
  };

  const getFeatureStyle = ({
    feature,
    index,
    state,
  }: {
    feature: any;
    index: number;
    state: 'SELECTED' | 'HOVERED' | 'INACTIVE' | 'UNCOMMITTED' | 'CLOSING';
  }) => {
    const cursor =
      drawMode instanceof EditingMode ? 'pointer' : drawMode instanceof DrawPolygonMode ? 'crosshair' : 'default';

    if (state === 'SELECTED' || state === 'HOVERED') {
      return {
        stroke: GEO_FENCE_OPTIONS_SELECTED.strokeColor,
        fill: GEO_FENCE_OPTIONS_SELECTED.fillColor,
        strokeWidth: 2,
        fillOpacity: 0.1,
        cursor,
      };
    }
    return {
      stroke: mapViewStyle.isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.strokeColor : GEO_FENCE_OPTIONS.strokeColor,
      fill: mapViewStyle.isSatelliteEnabled ? GEO_FENCE_OPTIONS_SAT.fillColor : GEO_FENCE_OPTIONS.fillColor,
      strokeWidth: 2,
      fillOpacity: 0.1,
      cursor,
    };
  };

  const shouldDisplayMapFilters = geoFenceIsActive || isNaviV3FeatureEnabled || isTravelPathFeatureEnabled;

  return (
    <MapGLWrapper
      drawingEnabled={!!drawMode && editPolygon}
      isDrawing={drawMode && drawMode instanceof DrawPolygonMode}
      isLoading={isDownloadingGeoJsonFile}
    >
      <MapGL
        dragPan={dragPan}
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={(map: any) => {
          setMapInstance(map);
          onMapLoadComplete && onMapLoadComplete();
        }}
        setIsSatelliteViewEnabled={handleMapViewStyleChange}
      >
        {shouldDisplayMapFilters && (
          <ComplexMapControl vertical position="top-left">
            <TooltipIconButton
              tooltip="filters"
              tooltipPosition="right"
              tooltipColor="grayDarker"
              color="secondary"
              margin="no"
              onClick={() => setIsMapFiltersOpen(true)}
            >
              <IconButtonIcon icon="filter" color="primary" />
            </TooltipIconButton>
          </ComplexMapControl>
        )}

        {!isInteractive && !isGeoFenceHidden && (
          <>
            {geoFenceIsActive && (
              <PermissionGuard permission={GEO_FENCES_DAILY_ROUTE || GEO_FENCES_ROUTE_TEMPLATE}>
                <ComplexMapControl vertical position="top-right">
                  <TooltipIconButton
                    disabled={!geoFenceExists}
                    color={showRouteGeoFence && geoFenceExists ? 'primary' : 'secondary'}
                    tooltipAsString
                    tooltip={
                      geoFenceExists
                        ? showRouteGeoFence
                          ? translate('routes.geoFences.hideGeoFence')
                          : translate('routes.geoFences.showGeoFence')
                        : translate('routes.geoFences.noGeoFence')
                    }
                    tooltipPosition="left"
                    margin="small no no"
                    onClick={() => setShowRouteGeoFence(!showRouteGeoFence)}
                  >
                    <IconButtonIcon icon="geoFence" size="large" />
                  </TooltipIconButton>

                  <RouteGeoFenceAlerts geoFenceId={geoFenceId} alertsAreReadOnly={alertsAreReadOnly} />
                </ComplexMapControl>
              </PermissionGuard>
            )}
          </>
        )}
        {isInteractive && !isInGeoFenceEditMode && (
          <ComplexMapControl vertical position="top-left" yOffset={shouldDisplayMapFilters ? 50 : undefined}>
            <TooltipIconButton
              tooltipAsString
              tooltip={translate(
                `routeTemplateBuilder.${
                  (optimizedRouteLocationsLength || 0) > 0
                    ? 'drawPolygonUnavailable'
                    : drawMode
                    ? 'deletePolygon'
                    : 'drawPolygon'
                }`,
              )}
              tooltipPosition="right"
              tooltipColor="grayDarker"
              color={drawMode && isSelectingWithPolygon ? 'warning' : 'secondary'}
              margin="no"
              disabled={(optimizedRouteLocationsLength || 0) > 0}
              onClick={
                drawMode && isSelectingWithPolygon
                  ? () => {
                      handleExitDrawMode(true);
                      setIsSelectingWithPolygon(false);
                    }
                  : () => {
                      setEditPolygon(true);
                      handleEnterDrawMode();
                      setIsSelectingWithPolygon(true);
                    }
              }
            >
              <IconButtonIcon
                icon={drawMode && isSelectingWithPolygon ? 'delete' : 'lasso'}
                size="large"
                color={drawMode && isSelectingWithPolygon ? 'white' : 'primary'}
              />
            </TooltipIconButton>
            {polygon && drawMode && (
              <TooltipIconButton
                tooltipAsString
                tooltip={translate(
                  editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
                )}
                tooltipPosition="right"
                tooltipColor="grayDarker"
                margin="small no"
                color={editPolygon ? 'primary' : 'secondary'}
                onClick={() => {
                  setEditPolygon(!editPolygon);
                }}
              >
                <IconButtonIcon margin="no" icon="edit" />
              </TooltipIconButton>
            )}
          </ComplexMapControl>
        )}
        {geoFenceIsActive && !isGeoFenceHidden && isInteractive && (
          <PermissionGuard permission={GEO_FENCES_DAILY_ROUTE || GEO_FENCES_ROUTE_TEMPLATE}>
            <ComplexMapControl vertical position="top-right">
              {!isDrawingMode && (
                <TooltipIconButton
                  disabled={routeIsNotScheduled || !routeLocations?.length}
                  color={geoFenceEditing ? 'primary' : 'secondary'}
                  tooltipAsString
                  tooltip={
                    routeIsNotScheduled
                      ? translate('routes.geoFences.availableForScheduledOnly')
                      : geoFenceExists && !formValues?.geoFence?.hasDeletedAllPolygons
                      ? translate('routes.geoFences.editGeoFence')
                      : translate('routes.geoFences.doCreateGeoFence')
                  }
                  tooltipPosition="left"
                  margin="small no no"
                  onClick={() => {
                    if (geoFenceExists) editGeoFence();
                    else {
                      handleEnterDrawMode();
                      setIsInGeoFenceEditMode(true);
                    }
                  }}
                >
                  <IconButtonIcon icon="geoFence" size="large" />
                </TooltipIconButton>
              )}
              {isDrawingMode && !isSelectingWithPolygon && (
                <>
                  <TooltipIconButton
                    color="secondary"
                    tooltip="cancel"
                    tooltipPosition="left"
                    margin="small no no"
                    onClick={handleExitDrawMode}
                    type="button"
                  >
                    <IconButtonIcon icon="close" size="large" />
                  </TooltipIconButton>
                  {selectedGeoFenceGeo && (
                    <TooltipIconButton
                      disabled={size(selectedGeoFenceGeo.history) <= 1}
                      color="secondary"
                      tooltip="undo"
                      tooltipPosition="left"
                      margin="small no no"
                      onClick={handleUndoGeoFence}
                      type="button"
                    >
                      <IconButtonIcon icon="betterUndo" size="large" />
                    </TooltipIconButton>
                  )}
                  <TooltipIconButton
                    disabled={isAddingPolygon || !selectedGeoFenceGeo}
                    color="secondary"
                    tooltip="deletePolygon"
                    tooltipPosition="left"
                    margin="small no no"
                    onClick={handleDeletePolygon}
                    type="button"
                  >
                    <IconButtonIcon icon="deleteGeoFence" size="large" />
                  </TooltipIconButton>
                  <TooltipIconButton
                    disabled={isAddingPolygon}
                    color="secondary"
                    tooltip="addPolygon"
                    tooltipPosition="left"
                    margin="small no no"
                    onClick={handleAddPolygon}
                    type="button"
                  >
                    <IconButtonIcon icon="createGeoFence" size="large" />
                  </TooltipIconButton>
                  <TooltipIconButton
                    color="secondary"
                    tooltip="save"
                    tooltipPosition="left"
                    margin="small no no"
                    disabled={!hasChanges}
                    onClick={handleSaveGeoFence}
                    type="button"
                  >
                    <IconButtonIcon icon="check" size="large" />
                  </TooltipIconButton>
                </>
              )}
              <RouteGeoFenceAlerts geoFenceId={geoFenceId} alertsAreReadOnly={alertsAreReadOnly} />
            </ComplexMapControl>
          </PermissionGuard>
        )}

        {drawMode && (
          <Editor
            ref={editorRef}
            clickRadius={12}
            mode={drawMode}
            features={isSelectingWithPolygon ? polygon || [] : editedGeoFence || []}
            onUpdate={(feature: any) =>
              isSelectingWithPolygon ? checkLocationsInPolygon(feature) : onEditGeoFence(feature)
            }
            onSelect={handleSelectInGeoFenceEditor}
            featureStyle={getFeatureStyle}
          />
        )}

        {drawMode && !isSelectingWithPolygon && <DrawingInstructions />}

        {mapInstance && (
          <>
            <RouteMapTravelPathGL map={mapInstance} />

            <RouteMapClusters map={mapInstance} routeStops={routeLocations || []} />

            <RouteMapRouteStopsGL
              map={mapInstance}
              showOrderNumbers={true}
              zoomLevel={15}
              routeStops={routeLocations}
            />
            {showRouteGeoFence && (
              <RouteMapGeoFencesGL
                map={mapInstance}
                geoFencesGeoJSON={drawMode ? [] : allGeoFencesGeoJson}
                isSatellite={mapViewStyle.isSatelliteEnabled}
              />
            )}
          </>
        )}
      </MapGL>

      <OnMapFiltersForm
        isOnMapFiltersFormValuesOpen={isMapFiltersOpen}
        closeRouteMapFilters={() => setIsMapFiltersOpen(false)}
        initialValues={geoFenceFiltersInitialValues}
        onSubmit={handleSubmitGeoFenceFilters}
        excludeOptionsIds={geoFenceId ? [geoFenceId] : []}
        hideDailyRouteGeoFence={isRouteTemplate || isRoutePlannerPage}
        routeTemplateId={routeTemplate?.id}
        showTravelPath={routeTemplate?.routeLocations?.length >= 2 && isRoutePlannerPage && !isInteractive}
        routeName={routeTemplate?.routeTemplateName}
      />
    </MapGLWrapper>
  );
};

export default RouteMapGL;
