import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { getQueryParams } from '../../utils/services/queryParams';

const getDeviceStatusesFormInitialValues = (queryString: string) => {
  const { vendorId, vehicleTypeId } = getQueryParams(queryString);

  return {
    vendorId: vendorId && Number(vendorId),
    vehicleTypeId: vehicleTypeId && Number(vehicleTypeId),
  };
};

const deviceStatusesFormInitialValuesSelector = createSelector(getDeviceStatusesFormInitialValues, identity);

export default deviceStatusesFormInitialValuesSelector;
