import { FC, useEffect, useMemo } from 'react';
import { StreetNetworkPropertiesTravelPath } from 'src/routes/interfaces/TravelPath';
import { getArrowsForStreetSegmentsDirection } from '../../utils';
import StreetNetworkOptionsToChooseGLSource, {
  STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER,
} from './StreetNetworkOptionsToChooseGLSource';

interface Props {
  streetNetworkOptions: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;
  map: mapboxgl.Map;
  mapBearing: number;
  onSelect: (streetId: number, isReversedDigitization: boolean) => void;
}

const StreetNetworkOptionsToChooseGL: FC<Props> = ({ streetNetworkOptions, map, mapBearing, onSelect }) => {
  const arrowsGeoJson = useMemo(
    () => getArrowsForStreetSegmentsDirection(streetNetworkOptions.features, mapBearing),
    [mapBearing, streetNetworkOptions.features],
  );

  useEffect(() => {
    map.once('load', () => {
      map.on('click', STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER, e => {
        if (map.getLayer(STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER)) {
          const [feature] = map.queryRenderedFeatures(e.point, {
            layers: [STREET_NETWORK_OPTIONS_TO_CHOOSE_LAYER],
          });

          if (feature && feature.properties) {
            onSelect(feature.properties.Street_Id, feature.properties.isReversedDigitization);
          }
        }
      });
    });
  }, [map, onSelect]);

  if (!arrowsGeoJson || !streetNetworkOptions) return null;

  return (
    <>
      <StreetNetworkOptionsToChooseGLSource
        streetNetworkOptions={streetNetworkOptions}
        streetNetworkOptionsArrowsPoints={arrowsGeoJson}
      />
    </>
  );
};

export default StreetNetworkOptionsToChooseGL;
