import React from 'react';
import { Source, Layer } from 'react-map-gl';

import {
  DASHBOARD_VEHICLE_TRACKINGS_POINTS_SOURCE,
  DASHBOARD_VEHICLE_TRACKINGS_SOURCE,
} from 'src/dashboard/constants/vehicleTrackings';
import { VehicleTrackingFeatureProperties } from './utils';

interface Props {
  geoJSONForLine: GeoJSON.FeatureCollection<GeoJSON.LineString, VehicleTrackingFeatureProperties>;
  geoJsonForPoints?: GeoJSON.FeatureCollection<GeoJSON.Point, VehicleTrackingFeatureProperties>;
}

const DashboardVehicleTrackingsGLSource: React.FC<Props> = React.memo(({ geoJSONForLine, geoJsonForPoints }) => (
  <>
    <Source type="geojson" id={DASHBOARD_VEHICLE_TRACKINGS_SOURCE} data={geoJSONForLine} tolerance={0.0001}>
      <Layer
        id="allVehicleTrackingsLine"
        type="line"
        source={DASHBOARD_VEHICLE_TRACKINGS_SOURCE}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': ['get', 'color'],
          'line-width': ['interpolate', ['exponential', 1], ['zoom'], 10, 1, 30, 4],
          'line-blur': 0.5,
          'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5],
        }}
      />
    </Source>
    {geoJsonForPoints && (
      <Source type="geojson" id={DASHBOARD_VEHICLE_TRACKINGS_POINTS_SOURCE} data={geoJsonForPoints} tolerance={0.0001}>
        <Layer
          id="allVehicleTrackingsPoints"
          type="circle"
          minzoom={15}
          source={DASHBOARD_VEHICLE_TRACKINGS_POINTS_SOURCE}
          paint={{
            'circle-radius': ['interpolate', ['exponential', 1], ['zoom'], 10, 5, 30, 10],
            'circle-color': ['get', 'color'],
          }}
        />
        <Layer
          id="allVehicleTrackingsArrows"
          type="symbol"
          minzoom={15}
          source={DASHBOARD_VEHICLE_TRACKINGS_POINTS_SOURCE}
          layout={{
            'symbol-placement': 'point',
            'icon-allow-overlap': true,
            'icon-image': ['case', ['has', 'bearing'], 'arrow', 'dot'],
            'icon-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 0.8, 30, 1.5],
            'icon-rotate': ['get', 'bearing'],
          }}
          paint={{
            'icon-color': 'white',
          }}
        />
      </Source>
    )}
  </>
));

export default DashboardVehicleTrackingsGLSource;
