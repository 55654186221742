import { getFormValues, initialize } from 'redux-form';
import { useSelector } from 'src/core/hooks/useSelector';
import { DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME } from '../components/pages/dashboardPageSections/dashboardWidgets/ExtraMapLayersWidgetContent';
import { useCallback, useEffect, useMemo } from 'react';
import { getItem, setItem } from 'src/core/services/persistentStorage';
import { useDispatch } from 'react-redux';

export const useMemoizedDashboardFilters = (filtersKey: string, filterFormKey: string) => {
  const filterFormValues = useSelector(getFormValues(filterFormKey));
  const mapLayersFormValues = useSelector(getFormValues(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME));

  const dispatch = useDispatch();

  const lastFiltersJSON = useMemo(() => getItem(filtersKey), [filtersKey]);

  const reapplyLastFilters = useCallback(() => {
    if (lastFiltersJSON) {
      const filters = JSON.parse(lastFiltersJSON);
      dispatch(initialize(filterFormKey, filters[filterFormKey]));
      dispatch(initialize(DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME, filters[DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME]));
    }
  }, [dispatch, filterFormKey, lastFiltersJSON]);

  useEffect(() => {
    setItem(
      filtersKey,
      JSON.stringify({
        [filterFormKey]: filterFormValues,
        [DASHBOARD_EXTRA_MAP_LAYERS_FORM_NAME]: mapLayersFormValues,
      }),
    );
  }, [filterFormKey, filterFormValues, filtersKey, mapLayersFormValues]);

  return reapplyLastFilters;
};
