import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../store';
import { SocialMediaAreaWrapper, TwitterFeedAreaWrapper } from './styled/News';
import { SocialMediaLinks, SocialPulsePost } from './';

export default function TwitterPosts() {
  const twitterPosts = useSelector((state: AppState) => state.account.news.twitterPosts);
  const firstTwitterPost = twitterPosts[0];

  return (
    <SocialMediaAreaWrapper>
      <SocialMediaLinks />
      <TwitterFeedAreaWrapper>
        <SocialPulsePost post={firstTwitterPost} />
      </TwitterFeedAreaWrapper>
    </SocialMediaAreaWrapper>
  );
}
