import styled from 'styled-components';
import { Panel, PanelSection } from '../../../core/components/styled/Panel';

export const ReleaseItemContainer = styled.div`
  display: flex;
  margin-top: 5px;
  padding-top: 10px;
  &:not(:first-child) {
    border-top: 1px solid ${p => p.theme.grayLight};
  }
`;

export const ReleaseItemFieldsContainer = styled.div`
  flex: 1;
`;

export const ReleaseItemsContainer = styled.div<{ name: string }>`
  margin-top: 10px;
  padding-top: 10px;

  h3 {
    text-align: center;
  }
`;

export const RemoveReleaseItemContainer = styled.div`
  margin-right: 10px;
  margin-top: 25px;
`;

export const AddReleaseItemContainer = styled.div`
  text-align: center;
`;

export const SideNavigationContainer = styled.ul`
  width: 15%;
  list-style: none;
  border-right: 1px solid ${p => p.theme.grayLight};
  text-align: center;
`;

export const SideNavigationItem = styled.li<{ isActive: boolean }>`
  padding: 10px;
  cursor: pointer;
  color: ${p => (p.isActive ? 'white' : 'initial')};
  background: ${p => (p.isActive ? p.theme.brandPrimary : 'initial')};
`;

export const SideNavigationHeader = styled.li`
  padding: 10px;
  border-bottom: 1px solid ${p => p.theme.grayLight};
  font-weight: 500;
`;

export const PageContent = styled(Panel)`
  display: flex;
  min-height: 500px;
`;

export const ReleaseNoteContentContainer = styled.div`
  display: flex-column;
  flex: 1;
  padding: 20px;
`;

export const ReleaseItemDescription = styled(PanelSection)`
  white-space: break-spaces;
`;
