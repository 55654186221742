import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { PaymentDetail } from '../interfaces/ApplyChecks';

import { loadPaymentDetails as doLoadPaymentDetails } from '../services/paymentDetails';

// Actions
export const START_LOAD = 'finance/applyChecks/paymentDetails/START_LOAD';
const COMPLETE_LOAD = 'finance/applyPayemnts/paymentDetails/COMPLETE_LOAD';
const FAIL_LOAD = 'finance/applyChecks/paymentDetails/FAIL_LOAD';
const RESET = 'finance/applyChecks/paymentDetails/RESET';

// Initial state
const initialState: {
  isLoading: boolean;
  isSaving: boolean;
  paymentDetails: PaymentDetail;
} = {
  isLoading: false,
  isSaving: false,
  paymentDetails: {} as PaymentDetail,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentDetails: action.paymentDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          paymentDetails: {} as PaymentDetail,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (paymentDetails: PaymentDetail) => ({
  type: COMPLETE_LOAD,
  paymentDetails,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadPaymentDetails =
  ({ vendorId, paymentId }: { vendorId: number; paymentId: number }) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadPaymentDetailsPromise = doLoadPaymentDetails(vendorId, paymentId);
    loadPaymentDetailsPromise
      .then((paymentDetails: PaymentDetail) => dispatch(completeLoad(paymentDetails)))
      .catch(() => dispatch(failLoad()));
    return loadPaymentDetailsPromise;
  };

export const resetPaymentDetails = () => ({
  type: RESET,
});
