import { createSelector } from 'reselect';
import { filter, identity } from 'lodash-es';
import Cookie from 'js-cookie';

import {
  DELIVERY_UTILITY_ID,
  DELIVERY_UTILITY,
  FRONT_LOAD_ID,
  FRONT_LOAD,
  RESIDENTIAL_ID,
  RESIDENTIAL,
  ROLL_OFF_ID,
  ROLL_OFF,
  TOTER_ID,
  TOTER,
} from '../../fleet/constants';
import { DISPATCH_BOARD_VEHICLE_TYPE_COOKIE } from '../constants';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import { SELECTED_VENDOR_ID_COOKIE } from 'src/common/constants';

const acceptedVehicleTypes = [ROLL_OFF, FRONT_LOAD, DELIVERY_UTILITY, TOTER, RESIDENTIAL];
export const acceptedVehicleTypesId = [ROLL_OFF_ID, FRONT_LOAD_ID, DELIVERY_UTILITY_ID, TOTER_ID, RESIDENTIAL_ID];

const isAcceptedVehicleType = ({ technicalName }: any) => acceptedVehicleTypes.indexOf(technicalName) !== -1;

export const getFirstAcceptedVehicleTypeId = (vehicleTypesForVendor: any, filtersPreferences: FilterSetting[]) => {
  const acceptableVehicleTypes = filter(vehicleTypesForVendor, isAcceptedVehicleType);
  const sorted = acceptableVehicleTypes.sort(
    (a, b) => acceptedVehicleTypes.indexOf(a.technicalName) - acceptedVehicleTypes.indexOf(b.technicalName),
  );
  for (let i = 0; i < sorted.length; i++) {
    if (sorted[i] && hasPermissionBasedOnProperties(filtersPreferences, { vehicleTypeId: sorted[i].id })) {
      return sorted[i].id;
    }
  }
  return undefined;
};

const getDispatchBoardFormInitialValues = (
  vehicleTypesForVendor: any,
  vendorId: number,
  filtersPreferences: FilterSetting[],
) => {
  const currentUrl = new URL(window.location.href);
  const dispatchBoardVehicleTypeIdFromUrl =
    acceptedVehicleTypesId.indexOf(Number(currentUrl.searchParams.get('vehicleTypeId'))) !== -1 &&
    hasPermissionBasedOnProperties(filtersPreferences, {
      vehicleTypeId: Number(currentUrl.searchParams.get('vehicleTypeId')),
    })
      ? currentUrl.searchParams.get('vehicleTypeId')
      : undefined;
  const vendorIdFromCookie = Cookie.get(SELECTED_VENDOR_ID_COOKIE);
  const dispatchBoardVehicleTypeIdFromCookie =
    Number(vendorIdFromCookie) === vendorId &&
    hasPermissionBasedOnProperties(filtersPreferences, {
      vehicleTypeId: Number(Cookie.get(DISPATCH_BOARD_VEHICLE_TYPE_COOKIE)),
    })
      ? Cookie.get(DISPATCH_BOARD_VEHICLE_TYPE_COOKIE)
      : undefined;

  return {
    vehicleTypeId:
      Number(dispatchBoardVehicleTypeIdFromUrl) ||
      Number(dispatchBoardVehicleTypeIdFromCookie) ||
      getFirstAcceptedVehicleTypeId(vehicleTypesForVendor, filtersPreferences),
  };
};

const dispatchBoardFormInitialValuesSelector = createSelector(getDispatchBoardFormInitialValues, identity);

export default dispatchBoardFormInitialValuesSelector;
