import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { loadFiltersSettings } from 'src/common/ducks/filters';
import { useSelector } from 'src/core/hooks/useSelector';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { loadFuelingTicketsSettings, loadSnowPlowSettings, loadStreetSweepingSettings } from 'src/vendors/ducks';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { loadPermissions, shouldLoadPermissionsSelector } from '../../../../account/ducks';
import { DocumentTitle } from '../../../../common/components';
import { PageLoading } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import translate from '../../../../core/services/translate';
import { loadVehicleTypesForVendor } from '../../../../fleet/ducks';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadFeatures } from '../../../../vendors/ducks';
import { currentUserId, currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import { SEQUENCE_SOURCE_TYPE_DAILY_ROUTE } from '../../../constants';
import {
  loadGroups,
  loadRouteSequenceStatusByVendor,
  loadServiceZones,
  loadSnowOrSweeperRoutesHeader,
  loadSnowOrSweeperRoutesTracker,
} from '../../../ducks';
import { loadSupervisors } from '../../../ducks/supervisors';
import SnowOrSweeperTrackerPage from './SnowOrSweeperTrackerPage';

const SnowOrSweeperTrackerPageResolver: FC = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();

  const userId = useSelector(currentUserId);
  const vendorId = useSelector(currentVendorId);
  const shouldLoadPermissions = useSelector(state =>
    shouldLoadPermissionsSelector(state.account.login, state.account.permissions),
  );
  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const isStreetSweepingFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);

  const {
    agingIntervalIds,
    endDate,
    priorityTypeIds,
    routeStatusTypeIds,
    searchTerm,
    serviceZones,
    startDate,
    supervisors,
    vehicleTypeIds,
    groupIds,
  } = getQueryParams(search);

  const loadDependencies = useCallback(async () => {
    await loadFeatures(vendorId);

    const promises = [
      loadSnowOrSweeperRoutesTracker({
        vendorId,
        searchTerm,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        serviceZones,
        supervisors,
        useUserGlobalFilters: !(serviceZones || supervisors || vehicleTypeIds),
        groupIds,
        priorityTypeIds,
        agingIntervalIds,
      })(dispatch),
      loadSnowOrSweeperRoutesHeader({
        vendorId,
        searchTerm,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        serviceZones,
        supervisors,
        useUserGlobalFilters: !(serviceZones || supervisors || vehicleTypeIds),
        priorityTypeIds,
        groupIds,
        agingIntervalIds,
      })(dispatch),
      loadRouteSequenceStatusByVendor(vendorId, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE)(dispatch),
      loadServiceZones(vendorId)(dispatch),
      loadSupervisors(vendorId)(dispatch),
      loadGroups(vendorId)(dispatch),
      loadFuelingTicketsSettings(vendorId)(dispatch),
      loadVehicleTypesForVendor(vendorId)(dispatch),
    ];

    if (userId) promises.push(loadFiltersSettings(vendorId, userId)(dispatch));

    if (isSnowPlowFeatureEnabled) promises.push(loadSnowPlowSettings(vendorId)(dispatch));
    if (isStreetSweepingFeatureEnabled) promises.push(loadStreetSweepingSettings(vendorId)(dispatch));

    if (shouldLoadPermissions) promises.push(loadPermissions()(dispatch));

    return Promise.all(promises);
  }, [
    agingIntervalIds,
    dispatch,
    endDate,
    groupIds,
    isSnowPlowFeatureEnabled,
    isStreetSweepingFeatureEnabled,
    priorityTypeIds,
    routeStatusTypeIds,
    searchTerm,
    serviceZones,
    shouldLoadPermissions,
    startDate,
    supervisors,
    userId,
    vehicleTypeIds,
    vendorId,
  ]);

  return (
    <>
      <DocumentTitle>
        {translate(Number(vehicleTypeIds) === SNOW_PLOW_ID ? 'routes.snowTracker' : 'routes.sweeperTracker')}
      </DocumentTitle>
      <TypedResolver
        successComponent={SnowOrSweeperTrackerPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/dashboard"
        successProps={{ vehicleTypeIds }}
        key={pathname}
      />
    </>
  );
};

export default SnowOrSweeperTrackerPageResolver;
