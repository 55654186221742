import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { BillDetails, SelectedBillsToUnassign } from 'src/finance/interfaces/BillDetails';
import * as Services from 'src/finance/services/billDetails';

// Actions
const START_LOAD_DETAILS = 'finance/billing/billDetails/START_LOAD_DETAILS';
const COMPLETE_LOAD_DETAILS = 'finance/billing/billDetails/COMPLETE_LOAD_DETAILS';
const FAIL_LOAD_DETAILS = 'finance/billing/billDetails/FAIL_LOAD_DETAILS';
const START_LOAD_PAYMENTINFO = 'finance/billing/billDetails/START_LOAD_PAYMENTINFO';
const COMPLETE_LOAD_PAYMENTINFO = 'finance/billing/billDetails/COMPLETE_LOAD_PAYMENTINFO';
const FAIL_LOAD_PAYMENTINFO = 'finance/billing/billDetails/FAIL_LOAD_PAYMENTINFO';
const START_UNASSIGN_BILLED_CHARGES = 'finance/billing/billDetails/START_UNASSIGN_BILLED_CHARGES';
const COMPLETE_UNASSIGN_BILLED_CHARGES = 'finance/billing/billDetails/COMPLETE_UNASSIGN_BILLED_CHARGESL';
const FAIL_UNASSIGN_BILLED_CHARGES = 'finance/billing/billDetails/FAIL_UNASSIGN_BILLED_CHARGES';
const START_RELEASE_BILL = 'finance/billing/billDetails/START_RELEASE_BILL';
const COMPLETE_RELEASE_BILL = 'finance/billing/billDetails/COMPLETE_RELEASE_BILL';
const FAIL_RELEASE_BILL = 'finance/billing/billDetails/FAIL_RELEASE_BILL';
const START_EXPORT_BILLED_CHARGES = 'finance/billing/billDetails/START_EXPORT_BILLED_CHARGES';
const COMPLETE_EXPORT_BILLED_CHARGES = 'finance/billing/billDetails/COMPLETE_EXPORT_BILLED_CHARGES';
const FAIL_EXPORT_BILLED_CHARGES = 'finance/billing/billDetails/FAIL_EXPORT_BILLED_CHARGES';
const START_LOAD_PREVIEW_BILL_PDF = 'finance/billing/billDetails/START_LOAD_PREVIEW_BILL_PDF';
const END_LOAD_PREVIEW_BILL_PDF = 'finance/billing/billDetails/END_LOAD_PREVIEW_BILL_PDF';
const RESET = 'finance/billing/billDetails/RESET';

// Initial state
const initialState: {
  billDetails: BillDetails;
  paymentInfo: any;
  billDetailsLoading: boolean;
  paymentInfoisLoading: boolean;
  isUnassigningBilledCharges: boolean;
  isReleasingBill: boolean;
  isExportingBilledCharges: boolean;
  isLoadingBillPreviewPDF: boolean;
} = {
  billDetails: {} as BillDetails,
  paymentInfo: {} as any,
  billDetailsLoading: false,
  paymentInfoisLoading: false,
  isUnassigningBilledCharges: false,
  isReleasingBill: false,
  isExportingBilledCharges: false,
  isLoadingBillPreviewPDF: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_DETAILS:
      return update(state, {
        $merge: {
          billDetailsLoading: true,
        },
      });

    case COMPLETE_LOAD_DETAILS:
      return update(state, {
        $merge: {
          billDetailsLoading: false,
          billDetails: action.billDetails,
        },
      });

    case FAIL_LOAD_DETAILS:
      return update(state, {
        $merge: {
          billDetailsLoading: false,
          billDetails: {} as BillDetails,
        },
      });

    case START_LOAD_PAYMENTINFO:
      return update(state, {
        $merge: {
          paymentInfoisLoading: true,
        },
      });

    case COMPLETE_LOAD_PAYMENTINFO:
      return update(state, {
        $merge: {
          paymentInfoisLoading: false,
          paymentInfo: action.paymentInfo,
        },
      });

    case FAIL_LOAD_PAYMENTINFO:
      return update(state, {
        $merge: {
          paymentInfoisLoading: false,
          paymentInfo: {} as any,
        },
      });

    case START_UNASSIGN_BILLED_CHARGES:
      return update(state, {
        $merge: {
          isUnassigningBilledCharges: true,
        },
      });

    case COMPLETE_UNASSIGN_BILLED_CHARGES:
      return update(state, {
        $merge: {
          isUnassigningBilledCharges: false,
        },
      });

    case FAIL_UNASSIGN_BILLED_CHARGES:
      return update(state, {
        $merge: {
          isUnassigningBilledCharges: false,
        },
      });

    case START_RELEASE_BILL:
      return update(state, {
        $merge: {
          isReleasingBill: true,
        },
      });

    case COMPLETE_RELEASE_BILL:
      return update(state, {
        $merge: {
          isReleasingBill: false,
        },
      });

    case FAIL_RELEASE_BILL:
      return update(state, {
        $merge: {
          isReleasingBill: false,
        },
      });

    case START_EXPORT_BILLED_CHARGES:
      return update(state, {
        $merge: {
          isExportingBilledCharges: true,
        },
      });

    case COMPLETE_EXPORT_BILLED_CHARGES:
      return update(state, {
        $merge: {
          isExportingBilledCharges: false,
        },
      });

    case FAIL_EXPORT_BILLED_CHARGES:
      return update(state, {
        $merge: {
          isExportingBilledCharges: false,
        },
      });

    case START_LOAD_PREVIEW_BILL_PDF:
      return update(state, {
        $merge: {
          isLoadingBillPreviewPDF: true,
        },
      });

    case END_LOAD_PREVIEW_BILL_PDF:
      return update(state, {
        $merge: {
          isLoadingBillPreviewPDF: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoadDetails = () => ({
  type: START_LOAD_DETAILS,
});

const completeLoadDetails = (billDetails: BillDetails) => ({
  type: COMPLETE_LOAD_DETAILS,
  billDetails,
});

const failLoadDetails = () => ({
  type: FAIL_LOAD_DETAILS,
});

const startLoadPaymentInfo = () => ({
  type: START_LOAD_PAYMENTINFO,
});

const completeLoadPaymentInfo = (paymentInfo: any) => ({
  type: COMPLETE_LOAD_PAYMENTINFO,
  paymentInfo,
});

const failLoadPaymentInfo = () => ({
  type: FAIL_LOAD_PAYMENTINFO,
});

const startUnassignBilledCharges = () => ({
  type: START_UNASSIGN_BILLED_CHARGES,
});

const completeUnassignBilledCharges = () => ({
  type: COMPLETE_UNASSIGN_BILLED_CHARGES,
});

const failUnassignBilledCharges = () => ({
  type: FAIL_UNASSIGN_BILLED_CHARGES,
});

const startReleaseBill = () => ({
  type: START_RELEASE_BILL,
});

const completeReleaseBill = () => ({
  type: COMPLETE_RELEASE_BILL,
});

const failReleaseBill = () => ({
  type: FAIL_RELEASE_BILL,
});

const startExportBilledCharges = () => ({
  type: START_EXPORT_BILLED_CHARGES,
});

const completeExportBilledCharges = () => ({
  type: COMPLETE_EXPORT_BILLED_CHARGES,
});

export const startLoadPreviewBillPDF = () => ({
  type: START_LOAD_PREVIEW_BILL_PDF,
});

export const endLoadPreviewBillPDF = () => ({
  type: END_LOAD_PREVIEW_BILL_PDF,
});

const failExportBilledCharges = () => ({
  type: FAIL_EXPORT_BILLED_CHARGES,
});

export const loadBillDetails = (vendorId: number, billId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadDetails());
  const loadBillDetailsPromise = Services.loadBillDetails(vendorId, billId);
  loadBillDetailsPromise
    .then((billDetails: BillDetails) => dispatch(completeLoadDetails(billDetails)))
    .catch(() => dispatch(failLoadDetails()));
  return loadBillDetailsPromise;
};

export const loadPaymentInfo = (invoiceId: string) => (dispatch: Dispatch) => {
  dispatch(startLoadPaymentInfo());
  const loadPaymentInfoPromise = Services.loadPaymentInfo(invoiceId);
  loadPaymentInfoPromise
    .then((paymentInfo: any) => dispatch(completeLoadPaymentInfo(paymentInfo)))
    .catch(() => dispatch(failLoadPaymentInfo()));
  return loadPaymentInfoPromise;
};

export const unassignBilledCharges =
  (vendorId: number, invoiceId: number, selectedBillsToUnassign: SelectedBillsToUnassign[]) => (dispatch: Dispatch) => {
    dispatch(startUnassignBilledCharges());
    const unassignBilledChargesPromise = Services.unassignBilledCharges(vendorId, invoiceId, selectedBillsToUnassign);
    unassignBilledChargesPromise
      .then(() => dispatch(completeUnassignBilledCharges()))
      .catch(() => dispatch(failUnassignBilledCharges()));
    return unassignBilledChargesPromise;
  };

export const releaseBill = (billId: number, confirmation?: boolean) => (dispatch: Dispatch) => {
  dispatch(startReleaseBill());
  const releaseBillPromise = Services.releaseBill(billId, confirmation);
  releaseBillPromise.then(() => dispatch(completeReleaseBill())).catch(() => dispatch(failReleaseBill()));
  return releaseBillPromise;
};

export const exportBilledCharges = (billId: number) => (dispatch: Dispatch) => {
  dispatch(startExportBilledCharges());
  const exportBilledChargesPromise = Services.exportBilledCharges(billId);
  exportBilledChargesPromise
    .then(() => dispatch(completeExportBilledCharges()))
    .catch(() => dispatch(failExportBilledCharges()));
  return exportBilledChargesPromise;
};

export const previewBilledCharges = (billId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadPreviewBillPDF());
  const previewBilledChargesPromise = Services.previewBilledCharges(billId);
  previewBilledChargesPromise
    .then(() => dispatch(endLoadPreviewBillPDF()))
    .catch(() => dispatch(endLoadPreviewBillPDF()));
  return previewBilledChargesPromise;
};

export const resetBillDetails = () => ({
  type: RESET,
});
