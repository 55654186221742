import { camelCase } from 'lodash-es';

import {
  AUDIT_RATE,
  AUDIT_TIME,
  BREAK_TIME,
  BRINING,
  DISPOSAL_FACILITY,
  DRIVER_SAFETY,
  DRIVING_TO_DUMP_DEBRIS,
  DRIVING_TO_FROM_FACILITY,
  EXCEPTION_RATE,
  EXCEPTIONS,
  FAULT_CODES,
  FIRST_GPS_MOVEMENT,
  GEO_FENCES_COVERAGE_STREET_SWEEPING,
  GEO_FENCES_STREET_SWEEPING,
  HARD_ACCELERATION,
  HARD_BREAKING,
  HARD_TURNING,
  IN_SERVICE,
  IN_TRANSIT,
  LAST_GPS_MOVEMENT,
  MATERIAL_CONTAMINATION,
  NOT_SERVICING,
  OBSTACLES_STREET_SWEEPING,
  OFF_ROUTE,
  OFFROUTE,
  ON_CURB_STREET_SWEEPER,
  OPERATIONAL_FACILITY,
  PARTICIPATION_RATE,
  PICKUPS_PER_HOUR,
  PLOWING,
  RECYCLE_PARTICIPATION_RATE,
  SALTING,
  SET_OUT_RATE,
  SNOW_PLOW_CHART,
  SPEEDING,
  STATIONARY_OFF_ROUTE,
  STATIONARY_TIME,
  STATIONARY,
  SWEPT,
  TRIP_TIME_STREET_SWEEPER,
  TRIP_TIME,
} from '../constants';
import { createUrl } from '../../utils/services/queryParams';
import {
  date as formatDate,
  dateAndTime,
  decimal,
  duration,
  number,
  time as formatTime,
  timeStamp as formatTimeStamp,
} from '../../utils/services/formatter';
import {
  Popover,
  TableActionButton,
  TableCell,
  TableHeadCell,
  TableRow,
  Text,
  TimelineElement,
  TimelineLegend,
  TimelineLegendItem,
  TimelineWrapper,
  ToggleIcon,
} from '../../core/components/styled';
import {
  EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD,
  EXCEPTIONS_BY_HOUSEHOLD,
  STREET_SWEEPING_COMPLETION,
  STREET_SWEEPING_LOADS_DUMPED,
  STREET_SWEEPING_MILES_SWEPT,
  STREET_SWEEPING_WATER_FILLUP,
} from '../constants/reportTypes';
import { getStationaryTimeInfo } from '../../routes/components/mapWithTimeline/TimelineUtils';
import { MapWithTimelineModal } from '../../routes/components/modals/MapWithTimelineModal';
import { ActionButtonTooltip, PopoverWrapper } from '../../core/components';
import { renderStationaryTime } from '../../routes/components/mapWithTimeline/MapTimeline';
import { theme } from '../../core/styles';
import { TimelineBox, TripDetailsWrapper } from '../../core/components/styled/Timeline';
import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';
import { DRIVER_FACING, FRONT_FACING } from '../components/modals/DriverSafetyImageModal';

interface PopoverText {
  endTime: string;
  locationName: string;
  startTime: string;
  type: string;
}

interface TimelineLegendContent {
  text: string;
  type: string;
  id?: string;
}

export interface DriverSafetyEvent {
  cameraType?: string;
  driverFacingImageUrl?: string;
  driverFacingVideoUrl?: string;
  driverName: string;
  durationInSeconds: number;
  frontFacingImageUrl?: string;
  frontFacingVideoUrl?: string;
  latitude: number;
  longitude: number;
  routeName: string;
  speed: number;
  timeOfEvent: Date | string;
  typeOfEvent: string;
}

export interface ReportingDetails {
  date: Date | string;
  driver: any;
  driverName: string;
  driverSafetyEvents: DriverSafetyEvent[];
  index: number;
  instances: string;
  isRouteDeleted?: boolean;
  isYRoute: boolean;
  metric: string;
  metric2: string;
  metric3: number;
  metric4: number;
  metricName: string;
  metricNumeric: number;
  metricNumeric2: number;
  metricNumeric3: number;
  metricNumeric4: number;
  metricTechnicalName: string;
  offRouteStationaryTime: number;
  offRouteTime: number;
  push: any;
  routeDate: string;
  routeId: number;
  routeName: string;
  totalInTransitTime: number;
  totalServiceTime: number;
  totalStationaryTime: number;
  totalTimeAtDisposalFacility: number;
  totalTimeAtOperationalFacility: number;
  totalTripTime: number;
  tripTime: number;
  tripTimeDetails: any[];
  tripTimeIncidents: any[];
  vehicle: any;
  vehicleId: number;
  vehicleName: string;
  vehicleTypeName: string;
  vehicleType?: string;
  routeAssistancePickupRateDetails?: {
    date: Date | string;
    driverName: string;
    routeName: string;
    pickupsPerHour: number;
  }[];
  routeAssistanceTripTimeDetails?: {
    date: Date | string;
    route: { id: number; name: string };
    totalTripTime: number;
    totalInTransitTime: number;
    totalServiceTime: number;
    totalTimeAtDisposalFacility: number;
    totalTimeAtOperationalFacility: number;
    totalStationaryTime: number;
  }[];
}

export const getReportingDetailsTableColumns = (reportType: string) => {
  switch (reportType) {
    case AUDIT_RATE:
      return [
        { name: 'date', label: translate('common.date'), width: '16%', sortable: true },
        { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '16%', sortable: true },
        { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '16%', sortable: true },
        { name: 'routeName', label: translate('routes.routeName'), width: '16%', sortable: true },
        { name: 'driverName', label: translate('drivers.driverName'), width: '16%', sortable: true },
        {
          name: 'metric',
          label: translate('insights.reportTypeUnits.containers/Hr'),
          width: '20%',
          sortable: true,
        },
      ];

    case AUDIT_TIME:
      return [
        { name: 'date', label: translate('common.date'), width: '16%', sortable: true },
        { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '16%', sortable: true },
        { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '16%', sortable: true },
        { name: 'routeName', label: translate('routes.routeName'), width: '16%', sortable: true },
        { name: 'driverName', label: translate('drivers.driverName'), width: '16%', sortable: true },
        {
          name: 'metric',
          label: translate('insights.reportTypeUnits.tripTimeHHMM'),
          width: '20%',
          sortable: true,
        },
      ];

    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
    case PICKUPS_PER_HOUR:
    case SPEEDING:
    case STATIONARY:
      return [
        {
          name: 'date',
          label: translate('common.date'),
          width: '16%',
          sortable: true,
          id: 'report-details-header-date',
        },
        {
          name: 'vehicleType',
          label: translate('vehicles.vehicleType'),
          width: '16%',
          sortable: true,
          id: 'report-details-header-vehicle-type',
        },
        {
          name: 'vehicleName',
          label: translate('vehicles.vehicle'),
          width: '16%',
          sortable: true,
          id: 'report-details-header-vehicle',
        },
        {
          name: 'routeName',
          label: translate('routes.routeName'),
          width: '16%',
          sortable: true,
          id: 'report-details-header-route',
        },
        {
          name: 'driverName',
          label: translate('drivers.driverName'),
          width: '16%',
          sortable: true,
          id: 'report-details-header-driver',
        },
        {
          name: 'metric',
          label: translate(createTranslationKey(reportType, 'insights.reportTypes')),
          width: '20%',
          sortable: true,
          id: 'report-details-header-value',
        },
      ];

    case STREET_SWEEPING_LOADS_DUMPED:
      return [
        { name: 'date', label: translate('common.date'), width: '16%', sortable: true },
        { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '20%', sortable: true },
        { name: 'routeName', label: translate('routes.routeName'), width: '20%', sortable: true },
        { name: 'driverName', label: translate('drivers.driverName'), width: '20%', sortable: true },
        {
          name: 'metric',
          label: translate('common.loadsDumped.loadsDumped'),
          width: '24%',
          sortable: true,
        },
      ];

    case STREET_SWEEPING_WATER_FILLUP:
      return [
        { name: 'date', label: translate('common.date'), width: '16%', sortable: true },
        { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '20%', sortable: true },
        { name: 'routeName', label: translate('routes.routeName'), width: '20%', sortable: true },
        { name: 'driverName', label: translate('drivers.driverName'), width: '20%', sortable: true },
        {
          name: 'metric',
          label: translate('common.waterFill.waterFill'),
          width: '24%',
          sortable: true,
        },
      ];
    case TRIP_TIME:
      return [
        { name: 'date', label: translate('common.date'), width: '11%', sortable: true, wordBreak: 'normal' },
        {
          name: 'vehicleName',
          label: translate('vehicles.vehicleTypeAndName'),
          width: '19%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'driverName',
          label: translate('drivers.driverName'),
          width: '13%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'totalTripTime',
          label: translate('insights.reportTypes.tripTime'),
          width: '7%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'totalInTransitTime',
          label: translate('insights.reportTypes.tripTimeInsights.transitTime'),
          width: '8%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'totalServiceTime',
          label: translate('insights.reportTypes.tripTimeInsights.serviceTime'),
          width: '8%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'totalTimeAtOperationalFacility',
          label: translate('insights.reportTypes.tripTimeInsights.operationalFacilityTime'),
          width: '11%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'totalTimeAtDisposalFacility',
          label: translate('common.disposalTime'),
          width: '9%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'totalStationaryTime',
          label: translate('insights.reportTypes.tripTimeInsights.stationaryTime'),
          width: '10%',
          sortable: true,
          wordBreak: 'normal',
        },
        { name: 'toggle', label: '', width: '4%', sortable: false },
      ];

    case FAULT_CODES:
      return [
        { name: 'date', label: translate('common.date'), width: '25%', sortable: true },
        { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '25%', sortable: true },
        { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '25%', sortable: true },
        {
          name: 'metric',
          label: translate(createTranslationKey(reportType, 'insights.reportTypes')),
          width: '25%',
          sortable: true,
        },
      ];

    case EXCEPTION_RATE:
    case PARTICIPATION_RATE:
    case SET_OUT_RATE:
      return [
        { name: 'date', label: translate('common.date'), width: '25%', sortable: true },
        { name: 'routeName', label: translate('routes.routeName'), width: '25%', sortable: true },
        { name: 'driverName', label: translate('drivers.driverName'), width: '25%', sortable: true },
        {
          name: 'metric',
          label: translate(createTranslationKey(reportType, 'insights.reportTypes')),
          width: '25%',
          sortable: true,
        },
      ];

    case RECYCLE_PARTICIPATION_RATE:
      return [
        { name: 'date', label: translate('common.date'), width: '10%', sortable: true },
        { name: 'routeName', label: translate('routes.routeName'), width: '30%', sortable: true },
        {
          name: 'metricNumeric',
          label: translate('insights.reportTypeUnits.participationRate'),
          width: '15%',
          sortable: true,
        },
        { name: 'metricNumeric2', label: translate('insights.completedStops'), width: '15%', sortable: true },
        { name: 'metricNumeric3', label: translate('insights.overallStops'), width: '15%', sortable: true },
        {
          name: 'metricNumeric4',
          label: translate('insights.recyclingBinsDetected'),
          width: '15%',
          sortable: true,
        },
      ];

    case EXCEPTIONS:
      return [
        { name: 'metricName', label: translate('insights.exception'), width: '33%', sortable: true },
        { name: 'instances', label: translate('insights.instances'), width: '33%', sortable: true },
        {
          name: 'metric',
          label: translate(createTranslationKey(reportType, 'insights.reportTypes')),
          width: '33%',
          sortable: true,
        },
      ];

    case EXCEPTIONS_BY_HOUSEHOLD:
    case EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD:
      return [
        { name: 'metricName', label: translate('insights.exception'), width: '33%', sortable: true },
        { name: 'instances', label: translate('insights.instances'), width: '33%', sortable: true },
        {
          name: 'metric',
          label: translate('insights.percentageOfTotal'),
          width: '33%',
          sortable: true,
        },
      ];

    case TRIP_TIME_STREET_SWEEPER:
      return [
        { name: 'date', label: translate('common.date'), width: '20%', sortable: true },
        { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '40%', sortable: true },
        { name: 'metric', label: translate('insights.reportTypes.routeHours'), width: '20%', sortable: true },
        {
          name: 'metric2',
          label: translate('insights.reportTypes.serviceHours'),
          width: '20%',
          sortable: true,
        },
      ];

    case ON_CURB_STREET_SWEEPER:
      return [
        { name: 'date', label: translate('common.date'), width: '20%', sortable: true },
        { name: 'vehicleName', label: translate('vehicles.vehicle'), width: '20%', sortable: true },
        { name: 'metric', label: translate('insights.reportTypes.onCurbPercent'), width: '15%', sortable: true },
        {
          name: 'metric2',
          label: translate('insights.reportTypes.totalServiceMiles'),
          width: '15%',
          sortable: true,
        },
        {
          name: 'metric3',
          label: translate('insights.reportTypes.onCurbMiles'),
          width: '15%',
          sortable: true,
        },
        {
          name: 'metric4',
          label: translate('insights.reportTypes.offCurbMiles'),
          width: '15%',
          sortable: true,
        },
      ];

    case OBSTACLES_STREET_SWEEPING:
      return [
        { name: 'date', label: translate('common.date'), width: '25%', sortable: true },
        { name: 'metric', label: translate('insights.obstacle'), width: '25%', sortable: true },
        { name: 'metric2', label: translate('insights.instances'), width: '25%', sortable: true },
        { name: 'metric3', label: translate('insights.percentageOfTotal'), width: '25%', sortable: true },
      ];

    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
      return [
        { name: 'date', label: translate('common.date'), width: '20%', sortable: true },
        { name: 'metric', label: translate('insights.name'), width: '40%', sortable: true },
        { name: 'metric3', label: translate('insights.percentageOfTotal'), width: '20%', sortable: true },
        { name: 'metric4', label: translate('insights.timeSpent'), width: '20%', sortable: true },
      ];

    case GEO_FENCES_STREET_SWEEPING:
      return [
        { name: 'date', label: translate('common.date'), width: '20%', sortable: true },
        { name: 'metric', label: translate('insights.name'), width: '30%', sortable: true },
        { name: 'metric2', label: translate('insights.obstacle'), width: '20%', sortable: true },
        { name: 'metric3', label: translate('insights.instances'), width: '15%', sortable: true },
        { name: 'metric4', label: translate('insights.percentageOfTotal'), width: '15%', sortable: true },
      ];

    case MATERIAL_CONTAMINATION:
      return [
        { name: 'date', label: translate('common.date'), width: '25%', sortable: true },
        { name: 'metricName', label: translate('insights.materialContamination'), width: '25%', sortable: true },
        { name: 'instances', label: translate('insights.instances'), width: '25%', sortable: true },
        { name: 'metric', label: translate('insights.percentageOfTotal'), width: '25%', sortable: true },
      ];

    case OFF_ROUTE:
    case STATIONARY_OFF_ROUTE:
      return [
        { name: 'date', label: translate('common.date'), width: '10%', sortable: true },
        {
          name: 'vehicleName',
          label: translate('vehicles.vehicleTypeAndName'),
          width: '25%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'driverName',
          label: translate('drivers.driverName'),
          width: '15%',
          sortable: true,
        },
        {
          name: 'routeName',
          label: translate('routes.routeName'),
          width: '15%',
          sortable: true,
        },
        {
          name: 'tripTime',
          label: translate('insights.reportTypes.tripTime'),
          width: '10%',
          sortable: true,
        },
        {
          name: 'offRouteTime',
          label: translate('insights.reportTypes.offRouteTime'),
          width: '10%',
          sortable: true,
        },
        {
          name: 'offRouteStationaryTime',
          label: translate('insights.reportTypes.offRouteStationaryTime'),
          width: '15%',
          sortable: true,
        },
      ];
    case STREET_SWEEPING_COMPLETION:
    case STREET_SWEEPING_MILES_SWEPT:
    case SNOW_PLOW_CHART:
      return [
        { name: 'date', label: translate('common.date'), width: '15%', sortable: true },
        {
          name: 'routeName',
          label: translate('routes.routeName'),
          width: '15%',
          sortable: true,
        },
        {
          name: 'vehicleName',
          label: translate('vehicles.vehicleName'),
          width: '25%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'metric3',
          label: translate('insights.reportTypes.totalStreetSegments'),
          width: '15%',
          sortable: true,
        },
        {
          name: 'metric2',
          label: translate('insights.reportTypes.completedStreetSegments'),
          width: '15%',
          sortable: true,
        },
        {
          name: 'metric',
          label: translate('insights.reportTypes.completedTotal'),
          width: '15%',
          sortable: true,
        },
      ];

    case DRIVER_SAFETY:
      return [
        { name: 'date', label: translate('common.date'), width: '15%', sortable: true },
        {
          name: 'vehicleType',
          label: translate('vehicles.vehicleType'),
          width: '18%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'vehicleName',
          label: translate('vehicles.vehicleName'),
          width: '18%',
          sortable: true,
          wordBreak: 'normal',
        },
        {
          name: 'routeName',
          label: translate('routes.routeName'),
          width: '19%',
          sortable: true,
        },
        {
          name: 'driverName',
          label: translate('drivers.driverName'),
          width: '18%',
          sortable: true,
        },
        {
          name: 'metric',
          label: translate('insights.safetyEvents'),
          width: '12%',
          sortable: true,
        },
      ];

    default:
      return undefined;
  }
};

const getFormattedValue = (reportType: string, insightValue: number) => {
  switch (reportType) {
    case AUDIT_TIME:
    case OFF_ROUTE:
    case STATIONARY:
    case TRIP_TIME_STREET_SWEEPER:
    case TRIP_TIME:
      return duration(Number(insightValue), 'minutes', 'hh:mm');

    case AUDIT_RATE:
    case PICKUPS_PER_HOUR:
      return Math.round(insightValue);

    case EXCEPTION_RATE:
    case ON_CURB_STREET_SWEEPER:
    case PARTICIPATION_RATE:
    case RECYCLE_PARTICIPATION_RATE:
    case SET_OUT_RATE:
      return `${Math.round(insightValue * 100)}%`;

    case EXCEPTIONS:
    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
    case GEO_FENCES_STREET_SWEEPING:
    case MATERIAL_CONTAMINATION:
    case OBSTACLES_STREET_SWEEPING:
      return `${number(insightValue)}%`;

    default:
      return number(insightValue);
  }
};

export const getFormattedTime = (insightValue: number) => {
  const hours = insightValue / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return (rhours < 10 ? '0' : '') + rhours + ':' + (rminutes < 10 ? '0' : '') + rminutes;
};

export const getReportingDetailsPageTitle = (reportType: string) => {
  switch (reportType) {
    case AUDIT_RATE:
      return translate('insights.auditRateSummary');
    case AUDIT_TIME:
      return translate('insights.auditTimeSummary');
    case SPEEDING:
      return translate('insights.speedingSummary');
    case STATIONARY:
      return translate('insights.stationarySummary');
    case TRIP_TIME:
    case TRIP_TIME_STREET_SWEEPER:
      return translate('insights.tripTimeSummary');
    case PICKUPS_PER_HOUR:
      return translate('insights.pickupRateSummary');
    case RECYCLE_PARTICIPATION_RATE:
      return translate('insights.recycleParticipationRateSummary');
    case HARD_ACCELERATION:
      return translate('insights.hardAccelerationSummary');
    case HARD_BREAKING:
      return translate('insights.hardBrakingSummary');
    case HARD_TURNING:
      return translate('insights.hardTurningSummary');
    case FAULT_CODES:
      return translate('insights.faultCodeSummary');
    case PARTICIPATION_RATE:
      return translate('insights.participationRateSummary');
    case SET_OUT_RATE:
      return translate('insights.setOutRateSummary');
    case STREET_SWEEPING_MILES_SWEPT:
    case STREET_SWEEPING_COMPLETION:
      return translate('insights.streetSweepingSummary');
    case STREET_SWEEPING_LOADS_DUMPED:
      return translate('insights.loadsDumpedSummary');
    case STREET_SWEEPING_WATER_FILLUP:
      return translate('insights.fillUpSummary');
    case EXCEPTION_RATE:
      return translate('insights.exceptionRateSummary');
    case EXCEPTIONS:
      return translate('insights.exceptionsSummary');
    case EXCEPTIONS_BY_HOUSEHOLD:
      return translate('insights.exceptionsByHouseholdSummary');
    case EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD:
      return translate('insights.exceptionsByContaminatedHouseholdSummary');
    case ON_CURB_STREET_SWEEPER:
      return translate('insights.onCurbPercentageSummary');
    case OBSTACLES_STREET_SWEEPING:
      return translate('insights.obstacleSummary');
    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
      return translate('insights.geoFencesCoverageSummary');
    case GEO_FENCES_STREET_SWEEPING:
      return translate('insights.geoFencesSummary');
    case MATERIAL_CONTAMINATION:
      return translate('insights.materialSummary');
    case OFF_ROUTE:
      return translate('insights.offRouteSummary');
    case STATIONARY_OFF_ROUTE:
      return translate('insights.offRouteStationarySummary');
    case SNOW_PLOW_CHART:
      return translate('insights.snowPlowSummary');
    case DRIVER_SAFETY:
      return translate('insights.driverSafetySummary');

    default:
      return translate('insights.reportingSummary');
  }
};

export const getReportingDetailsFormFields = (reportType: string) => {
  switch (reportType) {
    case AUDIT_RATE:
    case AUDIT_TIME:
    case STREET_SWEEPING_WATER_FILLUP:
    case STREET_SWEEPING_LOADS_DUMPED:
      return {
        search: true,
        startDate: true,
        endDate: true,
        vehicleId: true,
      };
    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
    case PICKUPS_PER_HOUR:
    case SPEEDING:
    case STATIONARY:
    case TRIP_TIME:
      return {
        search: true,
        startDate: true,
        endDate: true,
        vehicleId: true,
        vehicleTypeId: true,
        routeName: false,
        driverName: true,
        exceptions: false,
      };
    case FAULT_CODES:
      return {
        search: true,
        startDate: true,
        endDate: true,
        vehicleId: true,
        vehicleTypeId: true,
        routeName: false,
        driverName: false,
        exceptions: false,
      };
    case EXCEPTION_RATE:
    case PARTICIPATION_RATE:
    case SET_OUT_RATE:
      return {
        search: true,
        startDate: false,
        endDate: false,
        singleDate: true,
        vehicleId: false,
        vehicleTypeId: false,
        routeName: false,
        driverName: true,
        exceptions: false,
      };
    case EXCEPTIONS:
    case EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD:
    case EXCEPTIONS_BY_HOUSEHOLD:
      return {
        startDate: true,
        endDate: true,
        vehicleId: false,
        vehicleTypeId: false,
        routeName: false,
        driverName: false,
        exceptions: false,
      };
    case ON_CURB_STREET_SWEEPER:
    case TRIP_TIME_STREET_SWEEPER:
      return {
        search: true,
        startDate: true,
        endDate: true,
        vehicleId: true,
        vehicleTypeId: false,
        routeName: false,
        driverName: false,
        exceptions: false,
      };
    case OBSTACLES_STREET_SWEEPING:
      return {
        startDate: true,
        endDate: true,
        vehicleId: false,
        vehicleTypeId: false,
        routeName: false,
        driverName: false,
        exceptions: false,
        obstacles: true,
      };

    case GEO_FENCES_STREET_SWEEPING:
    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
      return {
        search: true,
        startDate: true,
        endDate: true,
        vehicleId: false,
        vehicleTypeId: false,
        routeName: false,
        driverName: false,
        exceptions: false,
        obstacles: false,
      };

    case MATERIAL_CONTAMINATION:
      return {
        startDate: true,
        endDate: true,
        vehicleId: false,
        vehicleTypeId: false,
        routeName: false,
        driverName: false,
        exceptions: false,
        materialContaminations: true,
      };

    case OFF_ROUTE:
    case STATIONARY_OFF_ROUTE:
    case DRIVER_SAFETY:
      return {
        search: true,
        startDate: true,
        endDate: true,
        vehicleId: true,
        vehicleTypeId: true,
      };

    case RECYCLE_PARTICIPATION_RATE:
      return {
        search: true,
        startDate: true,
        endDate: true,
        routeName: false,
        participationRate: true,
        completedStops: true,
        overallStops: true,
        recycledBinsDetected: true,
      };
    case STREET_SWEEPING_COMPLETION:
    case STREET_SWEEPING_MILES_SWEPT:
    case SNOW_PLOW_CHART:
      return {
        search: true,
        startDate: true,
        endDate: true,
        vehicleId: true,
        vehicleTypeId: false,
        routeName: false,
        driverName: false,
        exceptions: false,
      };

    default:
      return undefined;
  }
};

const getPopoverText = (detail: PopoverText, isSnowOrSweeperRoute?: boolean) => {
  let title;
  switch (detail.type) {
    case FIRST_GPS_MOVEMENT:
    case LAST_GPS_MOVEMENT:
      break;
    case IN_TRANSIT:
      title = translate('insights.reportTypes.tripTimeInsights.transitTime');
      break;
    case IN_SERVICE:
      title = translate('insights.reportTypes.tripTimeInsights.serviceTime');
      break;
    case OPERATIONAL_FACILITY:
      title = translate('insights.reportTypes.tripTimeInsights.operationalFacilityTime');
      break;
    case DISPOSAL_FACILITY:
      title = translate('insights.reportTypes.tripTimeInsights.disposalFacilityTime');
      break;
    case BREAK_TIME:
      title = translate('vendors.serviceConfiguration.breaktime');
      break;
    case DRIVING_TO_FROM_FACILITY:
      title = translate('vendors.serviceConfiguration.drivingToFromFacility');
      break;
    case DRIVING_TO_DUMP_DEBRIS:
      title = translate('vendors.serviceConfiguration.drivingToDumpDebris');
      break;
    case OFFROUTE:
      title = translate('vendors.serviceConfiguration.offRoute');
      break;
    case PLOWING:
      title = translate('vendors.serviceConfiguration.plowing');
      break;
    case SALTING:
      title = translate('vendors.serviceConfiguration.salting');
      break;
    case BRINING:
      title = translate('vendors.serviceConfiguration.brining');
      break;
    case SWEPT:
      title = translate('vendors.serviceConfiguration.sweeping');
      break;
    case NOT_SERVICING:
      title = translate('insights.reportTypes.tripTimeInsights.notServicing');
      break;

    default:
      break;
  }

  return (
    <>
      <Text block margin="xxSmall">
        {title}
      </Text>
      <Text block margin="xxSmall" weight="medium">
        {detail.locationName}
      </Text>
      <Text block margin="xxSmall" weight="medium">
        {`${(isSnowOrSweeperRoute ? formatTimeStamp : formatTime)(detail.startTime)} - ${(isSnowOrSweeperRoute
          ? formatTimeStamp
          : formatTime)(detail.endTime)}`}
      </Text>
    </>
  );
};

export const getTimelineLegendItems = (
  totalStationaryTimeMs: number,
  isSnowPlowRoute?: boolean,
  isStreetSweeperRoute?: boolean,
) => {
  const timelineLegendItems = [
    {
      id: 'report-details-timeline-in-transit',
      type: IN_TRANSIT,
      text: translate('insights.reportTypes.tripTimeInsights.transitTime'),
    },
    {
      id: 'report-details-timeline-in-service',
      type: IN_SERVICE,
      text: translate('insights.reportTypes.tripTimeInsights.serviceTime'),
    },
    {
      id: 'report-details-timeline-operational-facility',
      type: OPERATIONAL_FACILITY,
      text: translate('insights.reportTypes.tripTimeInsights.operationalFacilityTime'),
    },
    {
      id: 'report-details-timeline-disposal-facility',
      type: DISPOSAL_FACILITY,
      text: translate('insights.reportTypes.tripTimeInsights.disposalFacilityTime'),
    },
    {
      id: 'report-details-timeline-stationary-time',
      type: STATIONARY_TIME,
      text: totalStationaryTimeMs
        ? `${translate('insights.reportTypes.tripTimeInsights.stationaryTime')} (${
            duration(totalStationaryTimeMs / 1000, 'seconds', 'mm:ss').split(':')[0]
          } min ${duration(totalStationaryTimeMs / 1000, 'seconds', 'mm:ss').split(':')[1]} sec)`
        : translate('insights.reportTypes.tripTimeInsights.stationaryTime'),
    },
  ];

  let snowSweeperTimelineLegendItems = [
    {
      id: 'report-details-timeline-breaktime',
      type: BREAK_TIME,
      text: translate('vendors.serviceConfiguration.breaktime'),
    },
    {
      id: 'report-details-timeline-drivingToFromFacility',
      type: DRIVING_TO_FROM_FACILITY,
      text: translate('vendors.serviceConfiguration.drivingToFromFacility'),
    },
    {
      id: 'report-details-timeline-drivingToDumpDebris',
      type: DRIVING_TO_DUMP_DEBRIS,
      text: translate('vendors.serviceConfiguration.drivingToDumpDebris'),
    },
    {
      id: 'report-details-timeline-offRoute',
      type: OFFROUTE,
      text: translate('vendors.serviceConfiguration.offRoute'),
    },
    {
      id: 'report-details-timeline-disposalFacility',
      type: DISPOSAL_FACILITY,
      text: translate('insights.reportTypes.tripTimeInsights.disposalFacilityTime'),
    },
    {
      id: 'report-details-timeline-operational-facility',
      type: OPERATIONAL_FACILITY,
      text: translate('insights.reportTypes.tripTimeInsights.operationalFacilityTime'),
    },
    {
      id: 'report-details-timeline-non-servicing',
      type: NOT_SERVICING,
      text: translate('insights.reportTypes.tripTimeInsights.notServicing'),
    },
  ];

  const snowPlowTimelineLegendItems = [
    {
      id: 'report-details-timeline-plowing',
      type: PLOWING,
      text: translate('vendors.serviceConfiguration.plowing'),
    },
    {
      id: 'report-details-timeline-salting',
      type: SALTING,
      text: translate('vendors.serviceConfiguration.salting'),
    },
    {
      id: 'report-details-timeline-brining',
      type: BRINING,
      text: translate('vendors.serviceConfiguration.brining'),
    },
  ];

  const sweeperTimelineLegendItems = [
    {
      id: 'report-details-timeline-swept',
      type: SWEPT,
      text: translate('vendors.serviceConfiguration.sweeping'),
    },
  ];

  const stationaryTimelineLegendItem = {
    id: 'report-details-timeline-stationary-time',
    type: STATIONARY_TIME,
    text: totalStationaryTimeMs
      ? `${translate('insights.reportTypes.tripTimeInsights.stationaryTime')} (${
          duration(totalStationaryTimeMs / 1000, 'seconds', 'mm:ss').split(':')[0]
        } min ${duration(totalStationaryTimeMs / 1000, 'seconds', 'mm:ss').split(':')[1]} sec)`
      : translate('insights.reportTypes.tripTimeInsights.stationaryTime'),
  };

  if (isSnowPlowRoute) {
    snowSweeperTimelineLegendItems = [
      ...snowSweeperTimelineLegendItems,
      ...snowPlowTimelineLegendItems,
      stationaryTimelineLegendItem,
    ];
  }

  if (isStreetSweeperRoute) {
    snowSweeperTimelineLegendItems = [
      ...snowSweeperTimelineLegendItems,
      ...sweeperTimelineLegendItems,
      stationaryTimelineLegendItem,
    ];
  }

  return isSnowPlowRoute || isStreetSweeperRoute ? snowSweeperTimelineLegendItems : timelineLegendItems;
};

export const renderTimelineLegendItem = (itemInfo: TimelineLegendContent) => (
  <TimelineLegendItem key={itemInfo.type} type={itemInfo.type} id={itemInfo.id}>
    <Text>{itemInfo.text}</Text>
  </TimelineLegendItem>
);

export const renderTimelineLegend = (totalStationaryTime: number) => (
  <TimelineLegend>{getTimelineLegendItems(totalStationaryTime).map(renderTimelineLegendItem)}</TimelineLegend>
);

export const renderRouteTimeline = (
  totalTripTime: number,
  tripTimeDetails: any[],
  height?: number,
  hasNotEnoughData?: boolean,
  isSnowOrSweeperRoute?: boolean,
) => {
  return (
    <TimelineWrapper height={height} hasBorder={!tripTimeDetails || !tripTimeDetails.length}>
      {tripTimeDetails && tripTimeDetails.length
        ? hasNotEnoughData
          ? translate('routeTimeline.tripTimeDetailsNotFound')
          : tripTimeDetails.map((detail, index) => (
              <PopoverWrapper
                key={index}
                triggerButton={<TimelineElement width={100} type={detail.type} id="report-details-timeline" />}
                popoverContent={
                  <Popover>
                    <Text block margin="xxSmall">
                      {getPopoverText(detail, isSnowOrSweeperRoute)}
                    </Text>
                  </Popover>
                }
                size="medium"
                width={`${(detail.duration * 100) / totalTripTime}%`}
                height="100%"
              />
            ))
        : translate('insights.reportTypes.tripTimeInsights.tripTimeDetailsMissing')}
    </TimelineWrapper>
  );
};

export const getReportingDetailsTableRowComponent = (
  reportType: string,
  startDate: string,
  endDate: string,
  vehicleTypeId: number,
  toggleHighlightedRow: (index: number, highlightedRowHeight?: number) => void,
  openDriverSafetyImageModal: (driverSafetyEvent: DriverSafetyEvent) => void,
  openDriverSafetyVideoModal: (driverSafetyEvent: DriverSafetyEvent, cameraType: string) => void,
  highlightedRow: number,
  highlightedRowHeight?: number,
) => {
  switch (reportType) {
    case AUDIT_RATE:
    case AUDIT_TIME:
    case HARD_ACCELERATION:
    case HARD_BREAKING:
    case HARD_TURNING:
    case SPEEDING:
    case STATIONARY:
      return ({
        date,
        vehicleTypeName,
        vehicleName,
        routeName,
        driverName,
        metricNumeric,
        index,
      }: ReportingDetails) => (
        <TableRow>
          <TableCell width="16%" id={`report-details-${index}-date`}>
            {formatDate(date)}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-vehicle-type`}>
            {vehicleTypeName}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-vehicle`}>
            {vehicleName}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-route`}>
            {routeName}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-driver`}>
            {driverName}
          </TableCell>
          <TableCell width="20%" id={`report-details-${index}-value`}>
            {getFormattedValue(reportType, metricNumeric)}
          </TableCell>
        </TableRow>
      );

    case PICKUPS_PER_HOUR:
      return ({
        date,
        vehicleTypeName,
        vehicleName,
        routeName,
        driverName,
        metricNumeric,
        index,
        routeAssistancePickupRateDetails,
      }: ReportingDetails) => (
        <TableRow
          wrap="wrap"
          isHighlighted={highlightedRow === index}
          borderWidth="2px"
          borderColor={highlightedRow === index ? theme.brandPrimary : 'transparent'}
        >
          <TableCell width="16%" id={`report-details-${index}-date`}>
            {formatDate(date)}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-vehicle-type`}>
            {vehicleTypeName}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-vehicle`}>
            {vehicleName}
            {routeAssistancePickupRateDetails && !!routeAssistancePickupRateDetails.length && (
              <TableActionButton
                margin="no xSmall"
                onClick={() => toggleHighlightedRow(index, 60 + 45 + routeAssistancePickupRateDetails.length * 60)}
              >
                <ActionButtonTooltip
                  icon="infoFill"
                  tooltip="assistedOnXRoutes"
                  tooltipProps={{ numberOfRoutes: routeAssistancePickupRateDetails.length }}
                />
              </TableActionButton>
            )}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-route`}>
            {routeName}
          </TableCell>
          <TableCell width="16%" id={`report-details-${index}-driver`}>
            {driverName}
          </TableCell>
          <TableCell width="20%" id={`report-details-${index}-value`}>
            {getFormattedValue(reportType, metricNumeric)}
          </TableCell>
          {highlightedRow === index &&
            highlightedRowHeight &&
            routeAssistancePickupRateDetails &&
            routeAssistancePickupRateDetails.map((td, i) => (
              <div key={i} style={{ width: '100%', display: 'flex' }}>
                <TableCell width="48%" id="report-details-date">
                  {formatDate(td.date)}
                </TableCell>
                <TableCell width="16%" id="report-details-driver">
                  {td.routeName}
                </TableCell>
                <TableCell width="16%" id="report-details-driver">
                  {td.driverName}
                </TableCell>
                <TableCell width="15%" id="report-details-trip-time">
                  {td.pickupsPerHour}
                </TableCell>
              </div>
            ))}
        </TableRow>
      );

    case TRIP_TIME:
      return ({
        driver,
        index,
        routeDate,
        routeId,
        isYRoute,
        totalInTransitTime,
        totalServiceTime,
        totalStationaryTime,
        totalTimeAtDisposalFacility,
        totalTimeAtOperationalFacility,
        totalTripTime,
        tripTimeDetails,
        tripTimeIncidents,
        vehicle,
        vehicleTypeName,
        routeAssistanceTripTimeDetails,
        isRouteDeleted,
      }: ReportingDetails) => {
        const stationaryTimeInfo = getStationaryTimeInfo(
          tripTimeIncidents,
          tripTimeDetails && tripTimeDetails.length ? tripTimeDetails[0].startTime : 0,
        );

        const hasNotEnoughData =
          totalServiceTime === 0 &&
          totalStationaryTime === 0 &&
          totalTimeAtDisposalFacility === 0 &&
          totalTimeAtOperationalFacility === 0 &&
          totalTripTime === 0;

        return (
          <TableRow
            wrap="wrap"
            isHighlighted={highlightedRow === index}
            borderWidth="2px"
            borderColor={highlightedRow === index ? theme.brandPrimary : 'transparent'}
          >
            <TableCell width="11%" id="report-details-date">
              {formatDate(routeDate)}
            </TableCell>
            <TableCell width="19%" id="report-details-vehicle">
              {vehicleTypeName} / {vehicle.name}
              {routeAssistanceTripTimeDetails && !!routeAssistanceTripTimeDetails.length && (
                <TableActionButton
                  margin="no xSmall"
                  onClick={() => toggleHighlightedRow(index, 60 + 45 + routeAssistanceTripTimeDetails.length * 60)}
                >
                  <ActionButtonTooltip
                    icon="infoFill"
                    tooltip="assistedTotalDuration"
                    tooltipProps={{
                      assistedDuration: duration(
                        routeAssistanceTripTimeDetails.reduce((acc, curr) => acc + curr.totalTripTime, 0),
                        'seconds',
                        'hh:mm',
                      ),
                      totalDuration: duration(Number(totalTripTime), 'seconds', 'hh:mm'),
                    }}
                  />
                </TableActionButton>
              )}
            </TableCell>
            <TableCell width="13%" id="report-details-driver">
              {driver.name}
            </TableCell>
            <TableCell width="7%" id="report-details-trip-time">
              {duration(Number(totalTripTime), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell width="8%" id="report-details-transit-time">
              {duration(Number(totalInTransitTime), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell width="8%" id="report-details-service-time">
              {duration(Number(totalServiceTime), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell width="11%" id="report-details-operational-facility-time">
              {duration(Number(totalTimeAtOperationalFacility), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell width="9%" id="report-details-disposal-time">
              {duration(Number(totalTimeAtDisposalFacility), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell width="10%" id="report-details-stationary-time">
              {duration(Number(totalStationaryTime), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell
              onClick={() => (totalTripTime > 0 ? toggleHighlightedRow(index) : undefined)}
              width="4%"
              id="report-details-arrow-button"
            >
              {totalTripTime > 0 && <ToggleIcon size={10} isOpen={highlightedRow === index} icon="arrowDownFill" />}
            </TableCell>
            {highlightedRow === index && !highlightedRowHeight && (
              <TableCell width="96%">
                <TripDetailsWrapper>
                  <TimelineBox>
                    {renderRouteTimeline(totalTripTime, tripTimeDetails, undefined, hasNotEnoughData)}
                    {renderStationaryTime(stationaryTimeInfo.map, totalTripTime)}
                  </TimelineBox>
                  {renderTimelineLegend(stationaryTimeInfo.total)}
                  {routeId && (
                    <MapWithTimelineModal isDisabled={isRouteDeleted} routeId={routeId} notOnMap isYRoute={isYRoute} />
                  )}
                </TripDetailsWrapper>
              </TableCell>
            )}
            {highlightedRow === index && highlightedRowHeight && routeAssistanceTripTimeDetails && (
              <>
                <div style={{ width: '100%', paddingLeft: '30%' }}>
                  <TableHeadCell>{translate('routes.route')}</TableHeadCell>
                </div>
                {routeAssistanceTripTimeDetails.map((td, i) => (
                  <div key={i} style={{ width: '100%', display: 'flex' }}>
                    <TableCell width="30%" id="report-details-date">
                      {formatDate(td.date)}
                    </TableCell>
                    <TableCell width="13%" id="report-details-driver">
                      {td.route.name}
                    </TableCell>
                    <TableCell width="7%" id="report-details-trip-time">
                      {duration(Number(td.totalTripTime), 'seconds', 'hh:mm')}
                    </TableCell>
                    <TableCell width="8%" id="report-details-transit-time">
                      {duration(Number(td.totalInTransitTime), 'seconds', 'hh:mm')}
                    </TableCell>
                    <TableCell width="8%" id="report-details-service-time">
                      {duration(Number(td.totalServiceTime), 'seconds', 'hh:mm')}
                    </TableCell>
                  </div>
                ))}
              </>
            )}
          </TableRow>
        );
      };

    case TRIP_TIME_STREET_SWEEPER:
      return ({ date, vehicleName, metricNumeric, metricNumeric2 }: ReportingDetails) => (
        <TableRow>
          <TableCell width="20%">{formatDate(date)}</TableCell>
          <TableCell width="40%">{vehicleName}</TableCell>
          <TableCell width="20%">{getFormattedValue(reportType, metricNumeric)}</TableCell>
          <TableCell width="20%">{getFormattedValue(reportType, metricNumeric2)}</TableCell>
        </TableRow>
      );

    case ON_CURB_STREET_SWEEPER:
      return ({
        date,
        vehicleName,
        metricNumeric,
        metricNumeric2,
        metricNumeric3,
        metricNumeric4,
      }: ReportingDetails) => (
        <TableRow>
          <TableCell width="20%">{formatDate(date)}</TableCell>
          <TableCell width="20%">{vehicleName}</TableCell>
          <TableCell width="15%">{getFormattedValue(reportType, metricNumeric)}</TableCell>
          <TableCell width="15%">{decimal(metricNumeric2, 1)}</TableCell>
          <TableCell width="15%">{decimal(metricNumeric3, 1)}</TableCell>
          <TableCell width="15%">{decimal(metricNumeric4, 1)}</TableCell>
        </TableRow>
      );

    case FAULT_CODES:
      return ({ date, vehicleTypeName, vehicleName, metricNumeric, vehicleId, push, index }: ReportingDetails) => (
        <TableRow
          onClick={() =>
            push(
              createUrl('/insights/fault-code-details', undefined, {
                date: formatDate(date),
                startDate,
                endDate,
                vehicleId,
                vehicleName,
                reportType,
                vehicleTypeId,
              }),
            )
          }
        >
          <TableCell width="25%" id={`report-details-${index}-date`}>
            {formatDate(date)}
          </TableCell>
          <TableCell width="25%" id={`report-details-${index}-vehicle-type`}>
            {vehicleTypeName}
          </TableCell>
          <TableCell width="25%" id={`report-details-${index}-vehicle`}>
            {vehicleName}
          </TableCell>
          <TableCell width="25%" id={`report-details-${index}-value`}>
            {metricNumeric}
          </TableCell>
        </TableRow>
      );

    case EXCEPTION_RATE:
    case PARTICIPATION_RATE:
    case SET_OUT_RATE:
      return ({ date, routeName, driverName, metricNumeric }: ReportingDetails) => (
        <TableRow>
          <TableCell width="25%">{formatDate(date)}</TableCell>
          <TableCell width="25%">{routeName}</TableCell>
          <TableCell width="25%">{driverName}</TableCell>
          <TableCell width="25%">{getFormattedValue(reportType, metricNumeric)}</TableCell>
        </TableRow>
      );

    case EXCEPTIONS:
      return ({ metricTechnicalName, instances, metricNumeric }: ReportingDetails) => (
        <TableRow>
          <TableCell width="33%">
            {translate(createTranslationKey(metricTechnicalName, 'routes.wasteAuditStatuses'))}
          </TableCell>
          <TableCell width="33%">{instances}</TableCell>
          <TableCell width="33%">{getFormattedValue(reportType, metricNumeric)}</TableCell>
        </TableRow>
      );

    case EXCEPTIONS_BY_CONTAMINATED_HOUSEHOLD:
    case EXCEPTIONS_BY_HOUSEHOLD:
      return ({ metricTechnicalName, instances, metricNumeric }: ReportingDetails) => (
        <TableRow>
          <TableCell width="33%">
            {translate(createTranslationKey(metricTechnicalName, 'routes.wasteAuditStatuses'))}
          </TableCell>
          <TableCell width="33%">{instances}</TableCell>
          <TableCell width="33%">{metricNumeric}%</TableCell>
        </TableRow>
      );

    case OBSTACLES_STREET_SWEEPING:
      return ({ date, metric, metric2, metric3 }: ReportingDetails) => (
        <TableRow>
          <TableCell width="25%">{formatDate(date)}</TableCell>
          <TableCell width="25%">{translate(`insights.obstacles.${camelCase(metric)}`)}</TableCell>
          <TableCell width="25%">{metric2}</TableCell>
          <TableCell width="25%">{getFormattedValue(reportType, metric3)}</TableCell>
        </TableRow>
      );

    case GEO_FENCES_COVERAGE_STREET_SWEEPING:
      return ({ date, metric, metric2, metric3 }: ReportingDetails) => (
        <TableRow>
          <TableCell width="20%">{formatDate(date)}</TableCell>
          <TableCell width="40%">{metric}</TableCell>
          <TableCell width="20%">{getFormattedValue(reportType, Number(metric2))}</TableCell>
          <TableCell width="20%">{getFormattedTime(metric3)}</TableCell>
        </TableRow>
      );

    case GEO_FENCES_STREET_SWEEPING:
      return ({ date, metric, metric2, metric3, metric4 }: ReportingDetails) => (
        <TableRow>
          <TableCell width="20%">{formatDate(date)}</TableCell>
          <TableCell width="30%">{metric}</TableCell>
          <TableCell width="20%">{translate(`insights.obstacles.${camelCase(metric2)}`)}</TableCell>
          <TableCell width="15%">{metric3}</TableCell>
          <TableCell width="15%">{getFormattedValue(reportType, metric4)}</TableCell>
        </TableRow>
      );

    case MATERIAL_CONTAMINATION:
      return ({ date, metricName, instances, metric }: ReportingDetails) => (
        <TableRow>
          <TableCell width="25%">{formatDate(date)}</TableCell>
          <TableCell width="25%">{translate(`insights.contaminationSubType.${camelCase(metricName)}`)}</TableCell>
          <TableCell width="25%">{instances}</TableCell>
          <TableCell width="25%">{getFormattedValue(reportType, parseFloat(metric))}</TableCell>
        </TableRow>
      );

    case OFF_ROUTE:
    case STATIONARY_OFF_ROUTE:
      return ({
        date,
        driverName,
        index,
        offRouteStationaryTime,
        offRouteTime,
        routeName,
        tripTime,
        vehicleName,
        vehicleTypeName,
      }: ReportingDetails) => {
        return (
          <TableRow
            wrap="wrap"
            isHighlighted={highlightedRow === index}
            borderWidth="2px"
            borderColor={highlightedRow === index ? theme.brandPrimary : 'transparent'}
          >
            <TableCell width="10%" id="report-details-date">
              {formatDate(date)}
            </TableCell>
            <TableCell width="25%" id="report-details-vehicle">
              {vehicleTypeName} / {vehicleName}
            </TableCell>
            <TableCell width="15%" id="report-details-driver">
              {driverName}
            </TableCell>
            <TableCell width="15%" id="report-details-route">
              {routeName}
            </TableCell>
            <TableCell width="10%" id="report-details-trip-time">
              {duration(Number(tripTime), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell width="10%" id="report-details-off-route-time">
              {duration(Number(offRouteTime), 'seconds', 'hh:mm')}
            </TableCell>
            <TableCell width="15%" id="report-details-off-route-stationary-time">
              {duration(Number(offRouteStationaryTime), 'seconds', 'hh:mm')}
            </TableCell>
          </TableRow>
        );
      };

    case STREET_SWEEPING_LOADS_DUMPED:
    case STREET_SWEEPING_WATER_FILLUP:
      return ({ date, vehicleName, metricNumeric, routeName, driverName }: ReportingDetails) => (
        <TableRow>
          <TableCell width="16%">{formatDate(date)}</TableCell>
          <TableCell width="20%">{vehicleName}</TableCell>
          <TableCell width="20%">{routeName}</TableCell>
          <TableCell width="20%">{driverName}</TableCell>
          <TableCell width="24%">{metricNumeric}</TableCell>
        </TableRow>
      );
    case STREET_SWEEPING_COMPLETION:
    case STREET_SWEEPING_MILES_SWEPT:
    case SNOW_PLOW_CHART:
      return ({
        date,
        index,
        metricNumeric,
        metricNumeric2,
        metricNumeric3,
        routeName,
        vehicleName,
      }: ReportingDetails) => {
        return (
          <TableRow
            wrap="wrap"
            isHighlighted={highlightedRow === index}
            borderWidth="2px"
            borderColor={highlightedRow === index ? theme.brandPrimary : 'transparent'}
          >
            <TableCell width="15%" id="report-details-date">
              {formatDate(date)}
            </TableCell>
            <TableCell width="15%" id="report-details-route">
              {routeName}
            </TableCell>
            <TableCell width="25%" id="report-details-vehicle">
              {vehicleName}
            </TableCell>
            <TableCell width="15%" id="report-details-total-street-segments">
              {metricNumeric3}
            </TableCell>
            <TableCell width="15%" id="report-details-completeed-street-segments">
              {metricNumeric2}
            </TableCell>
            <TableCell width="15%" id="report-details-completed">
              {metricNumeric ? decimal(metricNumeric, 2) : 0} %
            </TableCell>
          </TableRow>
        );
      };

    case RECYCLE_PARTICIPATION_RATE:
      return ({
        date,
        index,
        routeName,
        metricNumeric,
        metricNumeric2,
        metricNumeric3,
        metricNumeric4,
      }: ReportingDetails) => {
        return (
          <TableRow
            wrap="wrap"
            isHighlighted={highlightedRow === index}
            borderWidth="2px"
            borderColor={highlightedRow === index ? theme.brandPrimary : 'transparent'}
          >
            <TableCell width="10%" id="report-details-date">
              {formatDate(date)}
            </TableCell>
            <TableCell width="30%" id="report-details-route">
              {routeName}
            </TableCell>
            <TableCell width="15%" id="report-details-participation">
              {`${metricNumeric}%`}
            </TableCell>
            <TableCell width="15%" id="report-details-completedStops">
              {metricNumeric2}
            </TableCell>
            <TableCell width="15%" id="report-details-overallStops">
              {metricNumeric3}
            </TableCell>
            <TableCell width="15%" id="report-details-trip-binsDetected">
              {metricNumeric4}
            </TableCell>
          </TableRow>
        );
      };

    case DRIVER_SAFETY:
      return ({
        date,
        driverName,
        driverSafetyEvents,
        index,
        metric,
        routeName,
        vehicleName,
        vehicleType,
      }: ReportingDetails) => {
        return (
          <TableRow
            wrap="wrap"
            isHighlighted={highlightedRow === index}
            borderWidth="2px"
            borderColor={highlightedRow === index ? theme.brandPrimary : 'transparent'}
          >
            <TableCell width="15%" id="report-details-date">
              {formatDate(date)}
            </TableCell>
            <TableCell width="18%" id="report-details-vehicleType">
              {translate(`vehicles.vehicleTypes.${camelCase(vehicleType)}`)}
            </TableCell>
            <TableCell width="18%" id="report-details-vehicleName">
              {vehicleName}
            </TableCell>
            <TableCell width="19%" id="report-details-routeName">
              {routeName || '-'}
            </TableCell>
            <TableCell width="18%" id="report-details-driverName">
              {driverName || '-'}
            </TableCell>
            <TableCell
              onClick={() => (Number(metric) > 0 ? toggleHighlightedRow(index) : undefined)}
              width="12%"
              id="report-details-arrow-button"
              justifyContent="space-between"
            >
              {metric}{' '}
              {Number(metric) > 0 && <ToggleIcon size={10} isOpen={highlightedRow === index} icon="arrowDownFill" />}
            </TableCell>

            {highlightedRow === index && driverSafetyEvents.length && (
              <>
                <div key={index} style={{ width: '70%', display: 'flex', marginLeft: '15%' }}>
                  <TableCell minHeight="0" width="25%">
                    <b>{translate('insights.driverSafety.timeOfEvent')}</b>
                  </TableCell>
                  <TableCell minHeight="0" width="50%">
                    <b>{translate('insights.driverSafety.typeOfEvent')}</b>
                  </TableCell>
                  <TableCell minHeight="0" width="25%">
                    <b>{translate('insights.driverSafety.imageOrVideo')}</b>
                  </TableCell>
                </div>
                {driverSafetyEvents.map((driverSafetyEvent: DriverSafetyEvent, index: number) => (
                  <div key={index} style={{ width: '70%', display: 'flex', marginLeft: '15%' }}>
                    <TableCell minHeight="0" width="25%" id="report-details-timeOfEvent">
                      {dateAndTime(driverSafetyEvent.timeOfEvent)}
                    </TableCell>
                    <TableCell minHeight="0" width="50%" id="report-details-typeOfEvent">
                      {translate(`insights.driverSafety.events.${camelCase(driverSafetyEvent.typeOfEvent)}`)}
                    </TableCell>
                    <TableCell minHeight="0" width="25%" id="report-details-trip-time">
                      {!!(driverSafetyEvent.driverFacingImageUrl || driverSafetyEvent.frontFacingImageUrl) && (
                        <TableActionButton onClick={() => openDriverSafetyImageModal(driverSafetyEvent)}>
                          <ActionButtonTooltip
                            icon="image"
                            tooltip={
                              driverSafetyEvent.driverFacingImageUrl && driverSafetyEvent.frontFacingImageUrl
                                ? 'driverAndFrontimages'
                                : driverSafetyEvent.driverFacingImageUrl && !driverSafetyEvent.frontFacingImageUrl
                                ? 'driverImage'
                                : !driverSafetyEvent.driverFacingImageUrl && driverSafetyEvent.frontFacingImageUrl
                                ? 'frontImage'
                                : 'images'
                            }
                          />
                        </TableActionButton>
                      )}
                      {!!driverSafetyEvent.driverFacingVideoUrl && (
                        <TableActionButton onClick={() => openDriverSafetyVideoModal(driverSafetyEvent, DRIVER_FACING)}>
                          <ActionButtonTooltip icon="playRoutePlayback" tooltip={'driverVideo'} />
                        </TableActionButton>
                      )}
                      {!!driverSafetyEvent.frontFacingVideoUrl && (
                        <TableActionButton onClick={() => openDriverSafetyVideoModal(driverSafetyEvent, FRONT_FACING)}>
                          <ActionButtonTooltip icon="playRoutePlayback" tooltip={'frontVideo'} />
                        </TableActionButton>
                      )}{' '}
                    </TableCell>
                  </div>
                ))}
              </>
            )}
          </TableRow>
        );
      };

    default:
      return undefined;
  }
};
