import { http } from '../../core/services/http';
import { transformContainersLocationsForCreateEdit } from './transformContainersLocationsForCreateEdit';

export const loadContainersLocationsForCreateEdit = (
  vendorId: number,
  equipmentTypeId?: number,
  equipmentSizeId?: number,
  wasteMaterialTypeId?: number,
  searchTerm?: string,
) =>
  http
    .get(`/inventories/${vendorId}/locations`, {
      params: { equipmentTypeId, equipmentSizeId, wasteMaterialTypeId, searchTerm },
    })
    .then(response => transformContainersLocationsForCreateEdit(response.data));
