import { size } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import arrow from 'src/common/assets/img/common/arrowSmall.png';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { useSelector } from 'src/core/hooks/useSelector';
import {
  DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_POINT_SOURCE,
  DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_SOURCE,
} from 'src/dashboard/constants/dashboardMapGL';
import { setDashboardModalMapSelectedFeature } from 'src/dashboard/ducks/mapControls';
import {
  getMapboxVehicleTrackingsForVehicleGeoJSON,
  getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON,
  getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON,
  getVehicleBreadcrumbsByRoutesGeoJSON,
  VehicleTrackingFeatureProperties,
} from './utils';
import VehicleTrackingsGLPopupResolver from './VehicleTrackingsGLPopupResolver';
import VehicleTrackingsGLSource from './VehicleTrackingsGLSource';

const mapImages = [
  {
    id: 'arrow',
    url: arrow,
    sourceId: DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_SOURCE,
    sdf: true,
  },
];

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const DashboardVehicleTrackingsGL: React.FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();

  const vehicleTrackings = useSelector(state => state.dashboard.vehiclesData.vehicleBreadcrumbsByRoutes);
  const vehicleTrackingsForRouteVehicle = useSelector(state => state.dashboard.routesData.vehicleFullTrackings);

  const { isSourceRoute } = useSelector(state => state.dashboard.mapControls.mapModalData);

  const geoJSONVehicleBreadcrumbsByRoutes = useMemo(() => {
    if (isSourceRoute && vehicleTrackingsForRouteVehicle)
      return {
        lineFeature: getMapboxVehicleTrackingsForVehicleGeoJSON(vehicleTrackingsForRouteVehicle),
        pointFeature: getMapboxVehicleTrackingsIndividualPointsForVehicleGeoJSON(vehicleTrackingsForRouteVehicle),
      };
    return {
      lineFeature: getVehicleBreadcrumbsByRoutesGeoJSON(vehicleTrackings),
      pointFeature: getMapboxVehicleTrackingsIndividualPointsForRouteGeoJSON(vehicleTrackings),
    };
  }, [isSourceRoute, vehicleTrackings, vehicleTrackingsForRouteVehicle]);

  const showVehicleTracking = !!size(geoJSONVehicleBreadcrumbsByRoutes.lineFeature.features);

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const feature = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              feature.source === DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_SOURCE ||
              feature.source === DASHBOARD_MAP_MODAL_VEHICLE_TRACKINGS_POINT_SOURCE,
          )[0] as any as GeoJSON.Feature<GeoJSON.LineString, VehicleTrackingFeatureProperties> | undefined;

        if (!!feature) {
          /**
           * Yes, setting the id from the properties since Mapbox removes the floating numbers
           * from the main id of the feature. We need to set some other ids for these things,
           * but API changes will be necessary.
           */
          if (isSourceRoute)
            dispatch(
              feature.properties.vehicleId &&
                setDashboardModalMapSelectedFeature('vehicleTrackings', feature.properties.vehicleId, {
                  coordinates: event.lngLat,
                  vehicleTrackingGroupIndex: feature.properties.groupIndex,
                  vehicleTrackingsSource: feature.properties.source,
                }),
            );
          else
            dispatch(
              feature.properties.routeId &&
                setDashboardModalMapSelectedFeature('vehicleTrackings', feature.properties.routeId, {
                  coordinates: event.lngLat,
                  vehicleTrackingGroupIndex: feature.properties.groupIndex,
                  vehicleTrackingsSource: feature.properties.source,
                }),
            );
        }
      });
    });
  }, [map, dispatch, vehicleTrackings, isSourceRoute]);

  return showVehicleTracking ? (
    <>
      <VehicleTrackingsGLSource
        geoJSONForLine={geoJSONVehicleBreadcrumbsByRoutes.lineFeature}
        geoJsonForPoints={geoJSONVehicleBreadcrumbsByRoutes.pointFeature}
      />
      <VehicleTrackingsGLPopupResolver />
    </>
  ) : null;
};

export default DashboardVehicleTrackingsGL;
