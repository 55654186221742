import { VehicleBreadcrumbs } from '../interfaces/vehiclePositions';
import { VehicleTrackingGroup } from '../interfaces/vehicleTrackings';

export const getVehicleTrackingGroupCenter = (group: VehicleTrackingGroup) =>
  group.coordinates[Math.floor(group.coordinates.length / 2)];

export const getVehicleTrackingGroupCenterMapBox = (group: VehicleBreadcrumbs) => ({
  latitude: group.coords[0][Math.floor(group.coords.length / 2)].lat,
  longitude: group.coords[0][Math.floor(group.coords.length / 2)].lng,
});
