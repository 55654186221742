import { FC } from 'react';
import { formValueSelector } from 'redux-form';

import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import {
  BulkySchedulerLimitByDaySettings,
  BulkySchedulerLimitByTimeRangeSettings,
} from 'src/vendors/interfaces/BulkyItemScheduler';
import { GridColumn, Separator, Text } from '../../../core/components/styled';
import { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } from 'src/core/constants/weekdays';
import { useSelector } from 'src/core/hooks/useSelector';
import BulkyItemSchedulerSchedulingAndCancellationWindows from './BulkyItemSchedulerSchedulingAndCancellationWindows';
import BulkyItemSchedulerServiceLimitsByLocationSection from './BulkyItemSchedulerServiceLimitsByLocationSection';
import BulkyItemSchedulerServiceLimitsByZoneSection from './BulkyItemSchedulerServiceLimitsByZoneSection';
import BulkyItemSeasonalPickupScheduleAndBlackoutDatesSection from './BulkyItemSeasonalPickupScheduleAndBlackoutDatesSection';
import translate from 'src/core/services/translate';

const formSelector = formValueSelector(BULKY_ITEM_SCHEDULER_FORM);

type Props = {
  initialValueBulkySchedulerLimitByDaySettings?: BulkySchedulerLimitByDaySettings[];
  initialValueBulkySchedulerLimitByTimeRangeSettings?: BulkySchedulerLimitByTimeRangeSettings[];
};

const BulkyItemSchedulerDispatchAndSchedulingSection: FC<Props> = ({
  initialValueBulkySchedulerLimitByDaySettings,
  initialValueBulkySchedulerLimitByTimeRangeSettings,
}) => {
  const bulkySchedulerBlackoutSettings =
    useSelector(state => formSelector(state, 'bulkySchedulerBlackoutSettings')) || [];
  const bulkySchedulerSeasonalPickUpSettings =
    useSelector(state => formSelector(state, 'bulkySchedulerSeasonalPickUpSettings')) || [];

  const bulkySchedulerBlackoutDateTypes = useSelector(state => formSelector(state, 'blackoutDateTypes')) || [];
  const bulkySchedulerSeasonalDateTypes = useSelector(state => formSelector(state, 'seasonalDateTypes')) || [];

  const restrictMondayPickup = useSelector(state => formSelector(state, 'restrictMondayPickup'));
  const restrictTuesdayPickup = useSelector(state => formSelector(state, 'restrictTuesdayPickup'));
  const restrictWednesdayPickup = useSelector(state => formSelector(state, 'restrictWednesdayPickup'));
  const restrictThursdayPickup = useSelector(state => formSelector(state, 'restrictThursdayPickup'));
  const restrictFridayPickup = useSelector(state => formSelector(state, 'restrictFridayPickup'));
  const restrictSaturdayPickup = useSelector(state => formSelector(state, 'restrictSaturdayPickup'));
  const restrictSundayPickup = useSelector(state => formSelector(state, 'restrictSundayPickup'));

  const restrictPickupDays = [];
  restrictMondayPickup && restrictPickupDays.push(MONDAY);
  restrictTuesdayPickup && restrictPickupDays.push(TUESDAY);
  restrictWednesdayPickup && restrictPickupDays.push(WEDNESDAY);
  restrictThursdayPickup && restrictPickupDays.push(THURSDAY);
  restrictFridayPickup && restrictPickupDays.push(FRIDAY);
  restrictSaturdayPickup && restrictPickupDays.push(SATURDAY);
  restrictSundayPickup && restrictPickupDays.push(SUNDAY);

  return (
    <GridColumn size="12/12" padding="no xSmall small xSmall">
      <Text block size="xLarge" weight="medium" margin="no no medium no">
        {translate('vendors.bulkyItemScheduler.dispatchScheduling')}
      </Text>

      <BulkyItemSchedulerServiceLimitsByLocationSection />

      <BulkyItemSchedulerServiceLimitsByZoneSection
        initialValueBulkySchedulerLimitByDaySettings={initialValueBulkySchedulerLimitByDaySettings}
        initialValueBulkySchedulerLimitByTimeRangeSettings={initialValueBulkySchedulerLimitByTimeRangeSettings}
        restrictPickupDays={restrictPickupDays}
      />

      <BulkyItemSchedulerSchedulingAndCancellationWindows />

      <BulkyItemSeasonalPickupScheduleAndBlackoutDatesSection
        bulkySettings={bulkySchedulerSeasonalPickUpSettings}
        dateTypes={bulkySchedulerSeasonalDateTypes}
      />

      <BulkyItemSeasonalPickupScheduleAndBlackoutDatesSection
        bulkySettings={bulkySchedulerBlackoutSettings}
        dateTypes={bulkySchedulerBlackoutDateTypes}
        isForBlackOutDates
      />

      <Separator color="grayLight" size={2} margin="sMedium no xSmall no" />
    </GridColumn>
  );
};

export default BulkyItemSchedulerDispatchAndSchedulingSection;
