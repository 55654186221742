import { useState, useRef, useEffect } from 'react';
import ReactMapGL, {
  NavigationControl as DefaultNavigationControl,
  Popup,
  MapRef,
  WebMercatorViewport,
} from 'react-map-gl';
import styled from 'styled-components';

import { ToggleSwitch } from '../../../core/components';
import {
  getMapboxApiKey,
  getMapboxMonochromeThemeUrl,
  getMapboxSatelliteThemeUrl,
} from '../../../core/services/environment';
import translate from '../../../core/services/translate';
import NavigationControl from './NavigationControl';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';

const Navigation = styled(DefaultNavigationControl)`
  width: 30px;
  bottom: 35px;
  left: 20px;
  position: absolute;
`;

const SatelliteController = styled.div`
  opacity: 0.7;
  background: rgb(255, 255, 255, 0.5);
  bottom: 34px;
  left: 60px;
  border-radius: 5px;
  padding-top: 10px;
  transition: 0.3s;
  color: white;

  &:hover {
    opacity: 1;
    background: white;
    color: initial;
  }
`;

type ActiveMarker = { markerType: string; marker: any; lat: number; long: number };

interface MapBounds {
  zoom?: number;
  latitude?: number;
  longitude?: number;
}

interface Props {
  activeMarker?: ActiveMarker;
  bounds?: MapBounds;
  defaultToSatelliteView?: boolean;
  disableDefaultNavigationControl?: boolean;
  disableDefaultSatelliteView?: boolean;
  doubleClickZoom?: boolean;
  dragPan?: boolean;
  enableNewNavigationControl?: boolean;
  enableNewSatelliteView?: boolean;
  getPopupContent?: (marker: ActiveMarker) => JSX.Element | null;
  interactiveLayerIds?: string[];
  navigationControlXOffset?: number;
  navigationControlYOffset?: number;
  onChangeViewport?: (viewport: Partial<WebMercatorViewport>) => void;
  onMapClick?: (pointerEvent: PointerEvent) => void;
  onMapClickGeneral?: (pointerEvent: PointerEvent) => void;
  onMapDblClick?: (pointerEvent: PointerEvent) => void;
  onMapRefLoaded: (map: mapboxgl.Map) => void;
  setIsSatelliteViewEnabled?: (enabled: boolean) => void;
  setNavigationRef?: (element: HTMLDivElement | null) => void;
  viewport?: MapGLViewport;
}

export const MapGL: React.FC<Props> = ({
  activeMarker: propsActiveMarker,
  bounds,
  children,
  defaultToSatelliteView = false,
  disableDefaultNavigationControl = false,
  disableDefaultSatelliteView = false,
  doubleClickZoom,
  dragPan = true,
  enableNewNavigationControl = false,
  enableNewSatelliteView = false,
  getPopupContent,
  interactiveLayerIds,
  navigationControlXOffset = 0,
  navigationControlYOffset = 0,
  onChangeViewport,
  onMapClick,
  onMapClickGeneral,
  onMapDblClick,
  onMapRefLoaded,
  setIsSatelliteViewEnabled,
  setNavigationRef,
  viewport: topLevelViewport,
}) => {
  const mapRef = useRef<MapRef>(null);
  const [viewport, setViewport] = useState<Partial<WebMercatorViewport>>({ width: 100, height: 400, zoom: 0 });
  const [activeMarker, setActiveMarker] = useState<ActiveMarker | undefined>(propsActiveMarker);
  const [isSatellite, setIsSatellite] = useState(defaultToSatelliteView);

  useEffect(() => {
    if (mapRef.current) {
      onMapRefLoaded(mapRef.current.getMap());
    }
  }, [isSatellite, onMapRefLoaded]);

  useEffect(() => {
    setActiveMarker(propsActiveMarker);
  }, [propsActiveMarker]);

  useEffect(() => {
    if (bounds) {
      setViewport(v => ({ ...v, ...bounds }));
    }
  }, [bounds]);

  useEffect(() => {
    if (topLevelViewport) {
      setViewport(v => ({ ...v, ...topLevelViewport }));
    }
  }, [topLevelViewport]);

  const onClick = (pointer: any) => {
    if (pointer.features.length && onMapClick) {
      onMapClick(pointer);
    }
    if (pointer.target?.classList?.contains('mapboxgl-canvas') && onMapClickGeneral) {
      onMapClickGeneral(pointer);
    }
  };

  const onDblClick = (pointer: any) => {
    onMapDblClick && onMapDblClick(pointer);
  };

  const toggleSatelliteView = (enabled = true) => {
    if (setIsSatelliteViewEnabled) setIsSatelliteViewEnabled(enabled);

    setIsSatellite(enabled);
  };

  const handleSetViewport = (newViewport: Partial<WebMercatorViewport>) => {
    let vp;
    if (newViewport.zoom && newViewport.zoom > 23) {
      vp = { ...viewport, zoom: 23 };
    } else {
      vp = { ...viewport, ...newViewport };
    }
    setViewport(vp);
    if (onChangeViewport) {
      onChangeViewport(vp);
    }
  };

  return (
    <ReactMapGL
      key={isSatellite ? 1 : 2}
      ref={mapRef}
      onClick={onClick}
      onDblClick={onDblClick}
      mapStyle={isSatellite ? getMapboxSatelliteThemeUrl() : getMapboxMonochromeThemeUrl()}
      getCursor={event => (event.isHovering ? 'pointer' : '')}
      {...viewport}
      interactiveLayerIds={interactiveLayerIds}
      width="100%"
      height="100%"
      onViewportChange={handleSetViewport}
      dragPan={dragPan}
      mapboxApiAccessToken={getMapboxApiKey()}
      doubleClickZoom={doubleClickZoom !== undefined ? doubleClickZoom : true}
    >
      {activeMarker && getPopupContent && (
        <Popup
          closeOnClick={false}
          onClose={() => setActiveMarker(undefined)}
          longitude={activeMarker.long}
          latitude={activeMarker.lat}
        >
          {getPopupContent(activeMarker)}
        </Popup>
      )}

      {!disableDefaultNavigationControl && <Navigation onViewportChange={handleSetViewport} />}

      {enableNewNavigationControl && (
        <NavigationControl
          ref={setNavigationRef}
          satelliteViewEnabled={isSatellite}
          disableSatelliteViewToggle={!enableNewSatelliteView}
          onViewportChange={handleSetViewport}
          xOffset={navigationControlXOffset}
          yOffset={navigationControlYOffset}
          onSatelliteViewToggle={() => toggleSatelliteView(!isSatellite)}
        />
      )}

      {!disableDefaultSatelliteView && (
        <SatelliteController style={{ position: 'absolute' }}>
          <ToggleSwitch
            afterText={translate('maps.satelliteView')}
            handleChange={val => toggleSatelliteView(val)}
            checked={isSatellite}
          />
        </SatelliteController>
      )}

      {children}
    </ReactMapGL>
  );
};
