import { map, mapKeys } from 'lodash-es';
import { RubiconServicesHistory } from 'src/customers/interfaces/RubiconServices';

export const transformRubiconServicesOptions = (RubiconServicesOptions: any) => {
  const equipments = map(RubiconServicesOptions.equipments, ({ code, description }) => ({
    label: description,
    value: code,
  }));
  const divisions = map(RubiconServicesOptions.divisions, ({ code, description }) => ({
    label: description,
    value: code,
  }));
  const materials = map(RubiconServicesOptions.materials, ({ code, description }) => ({
    label: description,
    value: code,
  }));
  const frequency = map(RubiconServicesOptions.frequencyType, ({ code, description }) => ({
    label: description,
    value: code,
  }));
  const uom = map(RubiconServicesOptions.unitOfMeasure, ({ description }) => ({
    label: description,
    value: description,
  }));
  const rates = map(RubiconServicesOptions.rateCodes, ({ description }) => ({
    label: description,
    value: description,
  }));
  const equipmentSize = RubiconServicesOptions.equipmentSize;
  return { equipments, equipmentSize, divisions, materials, frequency, uom, rates };
};

export const transformRubiconServices = (RubiconServices: RubiconServicesHistory[]) => {
  let cache = {} as any;
  let coordinates = [] as any;
  map(RubiconServices, ({ latitude, longitude, siteID }) => {
    if (cache[siteID]) return 0;
    let cons = [] as any;
    if (!cache[siteID]) {
      const services = RubiconServices.filter(item => item.siteID === siteID);
      cons = map(services, ({ svcID, equipmentName }) => {
        return { id: svcID, t: EQUIPMENTS[equipmentName] ? EQUIPMENTS[equipmentName].id : UNKNOWN_ID };
      });
      cache[siteID] = true;
    }
    coordinates.push({
      lid: siteID,
      la: latitude,
      lo: longitude,
      con: cons,
    });
  });
  return coordinates;
};

export const transformRubiconCustomerServiceInfo = (RubiconServices: RubiconServicesHistory[]) => {
  return map(
    RubiconServices,
    ({ customerName, svcID, equipmentName, equipmentSize, material, siteAddress, siteCity, siteState }) => ({
      label: `${customerName} ${svcID} ${equipmentName} ${equipmentSize} ${material} ${siteAddress}, ${siteCity}, ${siteState}`,
      value: svcID,
    }),
  );
};

const UNKNOWN_ID = 6;
const EQUIPMENTS_LIST = [
  { id: 1, name: 'Front Load' },
  { id: 2, name: 'Open Top' },
  { id: 3, name: 'Receiver Box' },
  { id: 4, name: 'Rear Load' },
  { id: 5, name: 'Self-contained Comp Box' },
  { id: UNKNOWN_ID, name: 'Unknown' },
  { id: 7, name: 'Vertical Compactor' },
  { id: 8, name: 'Stationary Compactor' },
  { id: 9, name: 'Toter' },
  { id: 9, name: 'CART' },
  { id: 10, name: 'Recycler' },
  { id: 11, name: 'Bags' },
  { id: 12, name: 'Public Container' },
  { id: 13, name: 'Portable Container' },
  { id: 14, name: 'Portable Sinc' },
  { id: 15, name: 'Portable Restroom Trailer' },
  { id: 16, name: 'Bulk Item' },
  { id: 17, name: 'Water Tote' },
  { id: 18, name: 'Holding Tank' },
  { id: 19, name: 'None' },
  { id: 20, name: 'Multiple' },
];

const EQUIPMENTS = mapKeys(EQUIPMENTS_LIST, 'name');
