import { FC } from 'react';
import { map, orderBy, size } from 'lodash-es';

import translate from 'src/core/services/translate';
import {
  RouteMapFiltersClose,
  RouteMapFiltersCloseIcon,
  RouteMapFiltersSection,
  RouteMapFiltersTitle,
  RouteMapFiltersWrapper,
} from 'src/routes/components/styled/RouteMapFilters';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';
import DispatchFilterRouteItemMapbox from './DispatchFilterRouteItemMapbox';
import SearchJobsForm from './SearchJobsForm';

type Props = {
  isRoutesSidebarOpen?: boolean;
  isLoading: boolean;
  routesList: DispatchBoardSimpleRoute[];
  selectedRouteIdsFilter: number[];
  onSelectRoute: (routeId: number) => void;
  searchJobs: (value: string) => void;
  closeRouteMapFilters: () => void;
};

const MapRoutesFilterList: FC<Props> = ({
  isRoutesSidebarOpen,
  isLoading,
  routesList,
  selectedRouteIdsFilter,
  onSelectRoute,
  searchJobs,
  closeRouteMapFilters,
}) => {
  if (!isRoutesSidebarOpen) {
    return null;
  }

  return (
    <RouteMapFiltersWrapper isOnRightSide={false} width="300">
      <RouteMapFiltersTitle>{translate('routes.routes')}</RouteMapFiltersTitle>
      <RouteMapFiltersClose onClick={closeRouteMapFilters}>
        <RouteMapFiltersCloseIcon />
      </RouteMapFiltersClose>
      <RouteMapFiltersSection>
        <SearchJobsForm searchJobs={searchJobs} />
      </RouteMapFiltersSection>

      {size(routesList)
        ? map(orderBy(routesList, 'statusId', 'asc'), route => (
            <DispatchFilterRouteItemMapbox
              key={route.id}
              route={route}
              onSelectRoute={onSelectRoute}
              isActive={selectedRouteIdsFilter.includes(route.id)}
            />
          ))
        : translate('routes.noRoutes')}
    </RouteMapFiltersWrapper>
  );
};

export default MapRoutesFilterList;
