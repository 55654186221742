import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { AppState } from 'src/store';
import { Grid, GridColumn, Button, ButtonSet } from '../../../core/components/styled';
import { hasPermissionSelector } from '../../../account/ducks';
import { isRequired } from '../../../utils/services/validator';
import { TextArea } from '../../../core/components';
import dispatchBoardRouteNoteFormInitialValuesSelector from '../../services/dispatchBoardRouteNoteFormInitialValuesSelector';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from '../../../account/constants';

interface ComponentProps {
  closeModal: (pristine: boolean) => void;
  isViewOnly: boolean;
}

interface FormData {
  routeNote: string;
}

type Props = ComponentProps & InjectedFormProps<FormData, ComponentProps>;

class UnassignedJobsRouteNoteForm extends PureComponent<Props> {
  render() {
    const { handleSubmit, closeModal, pristine, isViewOnly } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Grid padding="no small no no" multiLine>
          <GridColumn size="12/12">
            <Field
              name="routeNote"
              component={TextArea}
              rows="5"
              scrollYVisible
              validate={[isRequired]}
              label={translate('routes.routeNotes')}
              disabled={isViewOnly}
            />
          </GridColumn>
          <ButtonSet margin="large auto no">
            {!isViewOnly && (
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
            )}
            <Button type="button" margin="no small" color="secondary" onClick={() => closeModal(pristine)}>
              {translate('common.cancel')}
            </Button>
          </ButtonSet>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const {
    routes: { dispatchBoard },
  } = state;
  const hasPermissionToManageScheduledJobs = hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  );
  const hasPermissionToManageUnscheduledJobs = hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
  );

  return {
    initialValues: dispatchBoardRouteNoteFormInitialValuesSelector(dispatchBoard.unassignedJobsRouteNote),
    isViewOnly: !hasPermissionToManageScheduledJobs && !hasPermissionToManageUnscheduledJobs,
  };
};

export default connect(mapStateToProps)(
  reduxForm<FormData, ComponentProps>({
    form: 'unassignedJobsRouteNoteForm',
    onSubmitFail: focusFirstInvalidField,
  })(UnassignedJobsRouteNoteForm),
);
