import update from 'immutability-helper';
import { find, get, identity, sumBy } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import doLoadRunningVehicleCounts from '../services/loadRunningVehicleCounts';

// Actions
const START_LOAD = 'insights/runningVehicleCounts/START_LOAD';
const COMPLETE_LOAD = 'insights/runningVehicleCounts/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/runningVehicleCounts/FAIL_LOAD';
const RESET = 'insights/runningVehicleCounts/RESET';

// Initial state
const initialState = {
  isLoading: false,
  runningVehicleCounts: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          runningVehicleCounts: action.runningVehicleCounts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: initialState,
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (runningVehicleCounts: any) => ({
  type: COMPLETE_LOAD,
  runningVehicleCounts,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadRunningVehicleCounts =
  (vendorId: number, date: Date | string, insightGroupIds: number[]) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRunningVehicleCountsPromise = doLoadRunningVehicleCounts(vendorId, date, insightGroupIds);
    loadRunningVehicleCountsPromise
      .then(runningVehicleCounts => dispatch(completeLoad(runningVehicleCounts)))
      .catch(() => dispatch(failLoad()));
    return loadRunningVehicleCountsPromise;
  };

export const resetRunningVehicleCounts = () => ({
  type: RESET,
});

// Selectors
const getTotalVehicleCount = (runningVehicleCountsState: any) => sumBy(runningVehicleCountsState, 'count');
export const totalVehicleCountSelector = createSelector(getTotalVehicleCount, identity);

const getVehicleTechnicalNameById = (runningVehicleCountsState: any, vehicleTypeIdToFind: number) =>
  get(
    find(runningVehicleCountsState.runningVehicleCounts, ({ vehicleTypeId }) => vehicleTypeId === vehicleTypeIdToFind),
    'technicalName',
    undefined,
  );
export const vehicleTechnicalNameByIdSelector = createSelector(getVehicleTechnicalNameById, identity);
