import { filter, find } from 'lodash-es';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { isOnDemandVideoDownloadFeatureEnabled } from 'src/vendors/ducks/features';
import { MapGLPopupWrapper, MapGLPopupLoading } from 'src/common/components/map/MapGLPopup';
import { RouteMapFeature, clearRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { RouteVehicleCoord } from 'src/dashboard/interfaces/routesData';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteMapVehicleTrackingsGLPopup from './RouteMapVehicleTrackingsGLPopup';

export default function RouteMapVehicleTrackingsGLPopupResolver() {
  const dispatch = useDispatch();
  const { unitOfMeasure, vehicleTrackings } = useSelector(state => state.routes.routeMapVehicleData);
  const vehicleTrackingsForRoute = useSelector(state => state.dashboard.routesData.routeVehiclesBreadCrumbs);

  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const isOnDemandVideoDownloadEnabled = useSelector(isOnDemandVideoDownloadFeatureEnabled);

  const selectedTracking = useMemo(() => {
    if (
      !selectedFeature ||
      selectedFeature.feature !== RouteMapFeature.vehicleTrackings ||
      selectedFeature.vehicleTrackingGroupIndex === undefined
    ) {
      return undefined;
    }

    const { vehicleTrackingGroupIndex: groupIndex, id } = selectedFeature;

    let group: RouteVehicleCoord[] = [];

    const filtered = filter(vehicleTrackingsForRoute?.vehicles, v => v.id === id && v.coords.length > 0);
    if (filtered.length > 0) {
      group = filtered[0].coords[groupIndex];
    }

    if ((!vehicleTrackings && !vehicleTrackingsForRoute) || !group) {
      return undefined;
    }

    // get the closest coordinate to the selected point
    const closestCoordinate = group.reduce((prev, curr) => {
      if (!selectedFeature.coordinates) return prev;

      const prevDistance = Math.sqrt(
        Math.pow(prev.lat - selectedFeature.coordinates.lat, 2) +
          Math.pow(prev.lng - selectedFeature.coordinates.lng, 2),
      );
      const currDistance = Math.sqrt(
        Math.pow(curr.lat - selectedFeature.coordinates.lat, 2) +
          Math.pow(curr.lng - selectedFeature.coordinates.lng, 2),
      );

      return prevDistance < currDistance ? prev : curr;
    }, [] as any);

    const vehicle = find(vehicleTrackingsForRoute?.vehicles, { id });

    return {
      unitOfMeasure,
      vehicle: vehicle?.name || '',
      id: selectedFeature.id,
      selectedData: closestCoordinate,
      shouldDisplayDownloadVideo: vehicle?.hdv,
      videoDeviceTypeId: vehicle?.vdtId,
      anchor: selectedFeature.coordinates
        ? {
            latitude: selectedFeature.coordinates.lat,
            longitude: selectedFeature.coordinates.lng,
          }
        : {
            latitude: closestCoordinate.lat,
            longitude: closestCoordinate.lng,
          },
    };
  }, [selectedFeature, vehicleTrackingsForRoute, vehicleTrackings, unitOfMeasure]);

  if (!selectedTracking) {
    return null;
  }

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedTracking.anchor.latitude}
      longitude={selectedTracking.anchor.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <TypedResolver
        key={`${selectedFeature!.id}.${selectedFeature!.vehicleTrackingCoordinateGroupIndex}`}
        successComponent={RouteMapVehicleTrackingsGLPopup}
        successProps={{
          showTimestamp: true,
          speed: selectedTracking.selectedData.sp,
          unitOfMeasure: unitOfMeasure,
          vehicleId: selectedTracking.id,
          vehicleName: selectedTracking.vehicle,
          timestamp: selectedTracking.selectedData.ts,
          shouldDisplayDownloadVideo: selectedTracking.shouldDisplayDownloadVideo && isOnDemandVideoDownloadEnabled,
          videoDeviceTypeId: selectedTracking.videoDeviceTypeId,
          deviceId: selectedTracking.selectedData.deid,
          applicationModeStatusId: selectedTracking.selectedData.am,
        }}
        loadingComponent={MapGLPopupLoading}
        loadingProps={{ minHeight: 240 }}
      />
    </MapGLPopupWrapper>
  );
}
