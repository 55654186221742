import styled from 'styled-components';

import { colorMapper, sizeMapper } from '../../../utils/styles';
interface Props {
  margin?: string;
  padding?: string;
  color?: string;
  size?: number;
  width?: string;
}

const Separator = styled.div<Props>`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  width: ${props => props.width || 100}%;
  border-top: ${props => props.size || 1}px solid ${props => colorMapper(props, props.theme.gray)};
`;

export default Separator;
