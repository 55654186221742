import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { PageLoading } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import {
  loadRouteTemplateBuilderWorkSession,
  loadRouteTemplateBuilderWorkSessionChangeLog,
  loadRouteTemplateDraftServices,
  setFilterVehicle,
  clearSelectedDraft,
} from '../../../ducks/routeTemplateBuilder';
import { RouteTemplateBuilderWorkSessionPage } from './RouteTemplateBuilderWorkSessionPage';
import { loadWasteTypes, loadServiceTypes, loadEquipmentTypes, loadEquipmentSizes } from '../../../../common/ducks';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { AppState } from 'src/store';
import { RouteTemplateBuilderDraft } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import { createWarningNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { loadGeoFenceFilterOptions, loadGroups, loadServiceZones } from 'src/routes/ducks';

interface RouteParams {
  workSessionId: string;
}

type Props = RouteComponentProps<RouteParams>;

export const RouteTemplateBuilderWorkSessionPageResolver: React.FC<Props> = ({
  match: {
    params: { workSessionId },
  },
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const selectedDraftId = useSelector((s: AppState) => s.routes.routeTemplateBuilder.selectedDraftId);
  const selectedDraftVersionId = useSelector((s: AppState) => s.routes.routeTemplateBuilder.selectedDraftVersionId);

  const loadDependencies = async () => {
    const onlyRouteVehicleTypes = true;
    const dependencies: Promise<unknown>[] = [
      loadRouteTemplateBuilderWorkSession(+workSessionId)(dispatch),
      loadWasteTypes()(dispatch),
      loadEquipmentTypes()(dispatch),
      loadEquipmentSizes()(dispatch),
      loadGroups(vendorId)(dispatch),
      loadServiceZones(vendorId)(dispatch),
      loadServiceTypes()(dispatch),
      loadVehicleTypesForVendor(vendorId, onlyRouteVehicleTypes)(dispatch),
      loadGeoFenceFilterOptions({ vendorId })(dispatch),
    ];

    let draft: RouteTemplateBuilderDraft | undefined = undefined;

    if (selectedDraftId) {
      try {
        draft = await loadRouteTemplateDraftServices(selectedDraftId, selectedDraftVersionId)(dispatch);
      } catch (e) {
        dispatch(clearSelectedDraft());
        createWarningNotification(translate('routeTemplateBuilder.errors.draftNotFound'));
      }

      if (draft) {
        dispatch(setFilterVehicle(draft.vehicleTypeId));
      }
    }

    const [firstChange] = await loadRouteTemplateBuilderWorkSessionChangeLog(+workSessionId)(dispatch);

    if (!draft && firstChange) {
      dispatch(setFilterVehicle(firstChange.vehicleTypeId));
    }

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      resolve={loadDependencies}
      loadingComponent={PageLoading}
      successComponent={RouteTemplateBuilderWorkSessionPage}
      redirectOnError="/routes/route-template-builder"
    />
  );
};
