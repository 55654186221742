import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';

import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from '../../../../../../account/constants';
import { hasPermissionSelector } from '../../../../../../account/ducks';
import confirm from '../../../../../../core/services/confirm';
import translate from '../../../../../../core/services/translate';
import { CustomerLocationsEditorModalResolver } from '../../../../../../customers/components/modals';
import { AppState } from '../../../../../../store';
import { DispatchBoardRouteJob } from '../../../../../interfaces/DispatchBoardRouteJob';
import {
  DispatchBoardJobEditorModalResolver,
  DispatchBoardRouteEditorModalResolver,
  DispatchBoardUnassignedJobsNoteModalResolver,
  RouteLocationImagesModalResolver,
  RouteLocationIssuesModalResolver,
  RouteLocationNotesModalResolver,
} from '../../../../modals';
import { WeightTicketsResolver } from '../../../routes/routePageSections';
import { CustomerDetailsEditorModalResolver } from 'src/customers/components/modals/CustomerDetailsEditorModalResolver';

export type ModalTypes =
  | 'jobEditor'
  | 'routeEditor'
  | 'routeLocationImages'
  | 'routeLocationIssues'
  | 'routeLocationNotes'
  | 'unassignedJobsRouteNote'
  | 'weightTicket'
  | 'customerEditor'
  | 'locationEditor';

interface DispatchBoardModalsProps {
  isViewOnly: boolean;
  modalsOpen: {
    jobEditor?: boolean;
    routeEditor?: boolean;
    routeLocationImages?: boolean;
    routeLocationIssues?: boolean;
    routeLocationNotes?: boolean;
    unassignedJobsRouteNote?: boolean;
    weightTicket?: boolean;
    locationEditor?: boolean;
    customerEditor?: boolean;
  };
  refreshUnassignedJobs?: () => void;
  refreshOnHoldJobs?: () => void;
  routeId?: number;
  routeName?: string;
  selectedJob?: DispatchBoardRouteJob;
  toggleModal: (modal: ModalTypes, isOpen?: boolean, isHeader?: boolean) => void;
  vehicleTypeId?: number;
  date?: Date | string;
  refreshRoutes?: () => void;
  disabledFields?: string[];
}

class DispatchBoardModals extends Component<DispatchBoardModalsProps> {
  closeCustomerEditor = async (pristine: boolean) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
      return;
    }

    this.props.toggleModal('customerEditor', false);
  };

  closeLocationEditor = async (pristine: boolean) => {
    if (!pristine && !(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
      return;
    }

    this.props.toggleModal('locationEditor', false);
  };

  render() {
    const {
      modalsOpen,
      refreshUnassignedJobs,
      refreshOnHoldJobs,
      routeId,
      routeName = translate('routes.unassigned'),
      selectedJob,
      toggleModal,
      vehicleTypeId,
      isViewOnly,
      date,
      refreshRoutes,
    } = this.props;

    const {
      jobEditor = false,
      routeEditor = false,
      routeLocationImages = false,
      routeLocationIssues = false,
      routeLocationNotes = false,
      unassignedJobsRouteNote = false,
      weightTicket = false,
      locationEditor = false,
      customerEditor = false,
    } = modalsOpen;

    const siteAddress = selectedJob ? selectedJob.locationAddress.formattedAddress : undefined;
    const modalTitle = selectedJob ? selectedJob.customerName : routeName;
    const modalSubTitle = selectedJob ? selectedJob.locationName : '';
    const selectedJobId = selectedJob ? selectedJob.id : undefined;
    const forceMaterialTypeId =
      selectedJob && selectedJob.materialType.technicalName ? selectedJob.materialType.id : undefined;

    return (
      <Fragment>
        {routeEditor && (
          <DispatchBoardRouteEditorModalResolver
            routeId={routeId}
            closeModal={() => toggleModal('routeEditor', false)}
            onSuccess={refreshRoutes}
          />
        )}

        {jobEditor && !!selectedJob && (
          <DispatchBoardJobEditorModalResolver
            allowRoutesFromPast
            closeModal={() => toggleModal('jobEditor', false)}
            date={date}
            jobId={selectedJobId}
            refreshUnassignedJobs={refreshUnassignedJobs}
            refreshOnHoldJobs={refreshOnHoldJobs}
            routeId={routeId}
            vehicleTypeId={vehicleTypeId}
            forceMaterialTypeId={forceMaterialTypeId?.toString()}
          />
        )}

        {routeLocationIssues && routeId && (
          <RouteLocationIssuesModalResolver
            closeModal={() => toggleModal('routeLocationIssues', false)}
            modalSubTitle={modalSubTitle}
            modalTitle={modalTitle}
            routeId={routeId}
            routeLocationId={selectedJobId}
            vehicleTypeId={vehicleTypeId}
          />
        )}

        {routeLocationNotes && routeId && (
          <RouteLocationNotesModalResolver
            closeModal={() => toggleModal('routeLocationNotes', false)}
            isReadOnly={!selectedJobId || isViewOnly}
            modalSubTitle={modalSubTitle}
            modalTitle={modalTitle}
            routeId={routeId}
            routeLocationId={selectedJobId}
            siteAddress={siteAddress}
          />
        )}
        {routeLocationImages && routeId && (
          <RouteLocationImagesModalResolver
            closeModal={() => toggleModal('routeLocationImages', false)}
            routeId={routeId}
            routeLocationId={selectedJobId}
          />
        )}
        {weightTicket && routeId && (
          <WeightTicketsResolver
            closeModal={() => toggleModal('weightTicket', false)}
            isModal
            modalSubTitle={modalSubTitle}
            modalTitle={modalTitle}
            routeId={routeId}
            routeLocationId={selectedJobId}
            siteAddress={siteAddress}
          />
        )}
        {unassignedJobsRouteNote && (
          <DispatchBoardUnassignedJobsNoteModalResolver
            jobId={selectedJobId}
            closeModal={() => toggleModal('unassignedJobsRouteNote', false)}
          />
        )}

        {customerEditor && !!selectedJob?.customerId && (
          <CustomerDetailsEditorModalResolver customerId={selectedJob.customerId} onClose={this.closeCustomerEditor} />
        )}

        {locationEditor && !!selectedJob?.customerId && !!selectedJob?.locationId && (
          <CustomerLocationsEditorModalResolver
            customerId={selectedJob.customerId}
            locationId={selectedJob.locationId}
            onCancel={this.closeLocationEditor}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const hasPermissionToManageScheduledJobs = hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  );
  const hasPermissionToManageUnscheduledJobs = hasPermissionSelector(
    state.account.permissions,
    ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
  );

  return {
    isViewOnly: !hasPermissionToManageScheduledJobs && !hasPermissionToManageUnscheduledJobs,
  };
};

export default connect(mapStateToProps)(DispatchBoardModals);
