import { orderBy } from 'lodash-es';

import createTranslationKey from '../../utils/services/createTranslationKey';
import translate from '../../core/services/translate';

const transformLoadRunningVehicleCounts = (vehicleTypes: any[]) => {
  const vehicleTypesWithCounts = vehicleTypes.map(vehicleType => ({
    ...vehicleType,
    name: translate(createTranslationKey(vehicleType.technicalName, 'vehicles.vehicleTypes')),
  }));

  return orderBy(vehicleTypesWithCounts, ['name'], ['asc']);
};

export default transformLoadRunningVehicleCounts;
