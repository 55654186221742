import humps from 'humps';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { MapGLPopupLoading, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';

import { loadVehicleInformation } from 'src/dashboard/ducks';
import { clearDashboardModalMapSelectedFeature } from 'src/dashboard/ducks/mapControls';

import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import VehiclePositionsGLPopup from './VehiclePositionsGLPopup';

const VehiclePositionsGLPopupResolver = () => {
  const dispatch = useDispatch();

  const { routeHistoryItem } = useSelector(state => state.dashboard.routeHistoryData);

  const routeVehiclePositions = useSelector(state => state.dashboard.routeHistoryData.routeVehiclePositions);
  const vehicleInformation = useSelector(state => state.dashboard.vehiclesData.vehicleInformation);
  const selectedFeature = useSelector(state => state.dashboard.mapControls.selectedModalMapFeature);

  const vehiclePosition = useMemo(() => {
    if (!selectedFeature || selectedFeature.namespace !== 'vehiclePositions') {
      return undefined;
    }
    const routeVehiclePosition = routeVehiclePositions.find(position => position.vehicleId === selectedFeature?.id);

    if (routeVehiclePosition) {
      return {
        ...routeVehiclePosition,
        name: routeVehiclePosition.vehicleName,
        type: VEHICLE_TYPE_IDS[routeVehiclePosition.vehicleTypeId]?.technicalName,
        id: routeVehiclePosition.vehicleId,
      };
    }
    return null;
  }, [routeVehiclePositions, selectedFeature]);

  if (!vehiclePosition || !selectedFeature) {
    return null;
  }

  const loadDependencies = () => {
    if (!routeHistoryItem) return Promise.reject();

    return loadVehicleInformation(vehiclePosition.id, routeHistoryItem.routeDate)(dispatch);
  };

  const vehicleType = humps.camelize(vehiclePosition.type);

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedFeature.coordinates?.lat || vehiclePosition.latitude}
      longitude={selectedFeature.coordinates?.lng || vehiclePosition.longitude}
      onClose={() => dispatch(clearDashboardModalMapSelectedFeature())}
    >
      <TypedResolver
        key={selectedFeature.id}
        successComponent={VehiclePositionsGLPopup}
        successProps={{
          vehicleDateTime: vehicleInformation.reportDateTime,
          vehicleDriverName: vehicleInformation.driverName,
          vehicleId: selectedFeature!.id,
          vehicleMilesDriven: vehicleInformation.totalMilesDriven,
          vehicleName: vehiclePosition.name,
          vehicleOdometer: vehicleInformation.lastReportedSpeed,
          vehicleStatus: vehicleInformation.isActive,
          vehicleType,
        }}
        loadingComponent={MapGLPopupLoading}
        resolve={loadDependencies}
      />
    </MapGLPopupWrapper>
  );
};

export default VehiclePositionsGLPopupResolver;
