import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { Facility } from 'src/common/interfaces/Facility';
import { FACILITIES_LAYER } from 'src/fleet/constants/facilityStatus';

export type FacilityFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  tag: number;
  isSelected?: boolean;
};

export const getFacilitiesGeoJSON = (facilities: Facility[]) =>
  getFeatureCollection<GeoJSON.Point, FacilityFeatureProperties>(
    facilities.map((facility, index) =>
      getPointFeature(facility.id, [facility.address.longitude, facility.address.latitude], {
        id: facility.id,
        clickable: true,
        tag: index + 1,
        layer: FACILITIES_LAYER,
      }),
    ),
  );
