import React, { PureComponent } from 'react';

import { date, time } from '../../../utils/services/formatter';
import { TableCell, TableRow, Text } from '../../../core/components/styled';

interface Props {
  date: Date | string;
  notes: string;
}

class RouteLocationNotesModalTableRow extends PureComponent<Props> {
  render() {
    const { date: dateTime, notes } = this.props;

    return (
      <TableRow>
        <TableCell width="20%" vertical>
          <Text block margin="no no xxSmall">
            {date(dateTime)}
          </Text>
          <Text block size="small">
            {time(dateTime)}
          </Text>
        </TableCell>

        <TableCell width="80%">{notes}</TableCell>
      </TableRow>
    );
  }
}

export default RouteLocationNotesModalTableRow;
