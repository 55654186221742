import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MapGL } from 'src/common/components/map/MapGL';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import { getMapBounds } from 'src/common/components/map/util';
import { UnconnectedSwitch } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { resetVendorLocations } from 'src/dashboard/ducks';
import { DELIVERY_UTILITY_ID, ROLL_OFF_ID } from 'src/fleet/constants';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { resetGeoFences, resetRouteMapCityInsights } from 'src/routes/ducks';
import { clearRouteMapSelectedFeature, setIsDrawingMode, setRouteMapViewport } from 'src/routes/ducks/mapControls';
import { resetGeoFence } from 'src/routes/ducks/routeGeoFence';
import { resetRouteMapVehicleData } from 'src/routes/ducks/routeMapVehicleData';
import { resetRouteMapVehicleInsights } from 'src/routes/ducks/routeMapVehicleInsights';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { RouteSequenceSummary } from '../RouteSequenceSummary';
import RouteSequenceMapClustersGL from './RouteSequenceMapClustersGL';
import RouteSequenceStopsGL from './RouteSequenceStopsGL';

type Props = {
  vehicleTypeId: number;
  isTemplate?: boolean;
  routeStops?: RouteStop[];
  routeLocations?: RouteLocation[];
};

const RouteSequenceMapGL = ({ routeStops, routeLocations, vehicleTypeId, isTemplate }: Props) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<mapboxgl.Map>();
  const [showOrderNumbers, setShowOrderNumbers] = useState(false);
  const [mapViewStyle, setMapViewStyle] = useState<any>({
    isSatelliteEnabled: false,
    mapCenter: null,
    mapZoom: null,
  });

  const vendorId = useSelector(currentVendorId);
  const dragPan = useSelector(s => s.routes.routeMapSettings.dragPan);
  const { viewport } = useSelector(state => state.routes.mapControls);

  useEffect(() => {
    dispatch(resetGeoFences());
    dispatch(setIsDrawingMode(false));
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetGeoFence());
      dispatch(resetRouteMapVehicleData());
      dispatch(resetRouteMapVehicleInsights());
      dispatch(resetRouteMapCityInsights());
      dispatch(resetVendorLocations());
      dispatch(resetGeoFences());
      dispatch(setIsDrawingMode(false));
    },
    [dispatch],
  );

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const features = map.queryRenderedFeatures(event.point).filter(feature => !!feature.properties?.cluster_id);
        if (features.length) {
          dispatch(clearRouteMapSelectedFeature());
        }
      });
      map.on('mousemove', event => {
        const features = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.properties?.clickable === true || !!feature.properties?.cluster_id);

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch]);

  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];
    if (isTemplate)
      routeLocations?.forEach(
        ({
          location: {
            address: { latitude, longitude, binLatitude, binLongitude },
          },
        }) => {
          points.push({ latitude: binLatitude || latitude, longitude: binLongitude || longitude });
        },
      );
    else
      routeStops?.forEach(({ binLatitude, binLongitude }) => {
        points.push({ latitude: binLatitude, longitude: binLongitude });
      });

    if (mapViewStyle.mapCenter && mapViewStyle.mapZoom) {
      const bounds = getMapBounds([{ latitude: mapViewStyle.mapCenter.lat, longitude: mapViewStyle.mapCenter.lng }], {
        capZoom: mapViewStyle.mapZoom,
      });
      dispatch(setRouteMapViewport(bounds));
    } else if (!!points.length) {
      const bounds = getMapBounds(points, {
        capZoom: 16,
      });
      dispatch(setRouteMapViewport(bounds));
    }
  }, [mapViewStyle.mapCenter, mapViewStyle.mapZoom, routeStops, dispatch, vendorId, map, isTemplate, routeLocations]);

  const handleMapViewStyleChange = (enabled: boolean) => {
    setMapViewStyle({
      isSatelliteEnabled: enabled,
      mapCenter: map?.getCenter(),
      mapZoom: map?.getZoom(),
    });
  };

  return (
    <MapGLWrapper>
      <MapGL
        dragPan={dragPan}
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        viewport={viewport}
        onMapRefLoaded={setMap}
        setIsSatelliteViewEnabled={handleMapViewStyleChange}
        navigationControlYOffset={50}
      >
        <ComplexMapControl vertical position="bottom-left" xOffset={-15} yOffset={-15} background="#fff">
          <Box padding="xSmall">
            <UnconnectedSwitch
              checked={showOrderNumbers}
              onChange={(value: boolean) => setShowOrderNumbers(value)}
              label={translate('routes.showSuggestedStopNumbers')}
            />
          </Box>
        </ComplexMapControl>
        {map && (
          <>
            <RouteSequenceMapClustersGL map={map} routeStops={routeStops} routeLocations={routeLocations} />

            <RouteSequenceStopsGL
              map={map}
              showOrderNumbers={showOrderNumbers}
              routeStops={routeStops}
              routeLocations={routeLocations}
              isTemplate={isTemplate}
              zoomLevel={vehicleTypeId === DELIVERY_UTILITY_ID ? 10 : 15}
            />
          </>
        )}
      </MapGL>
      {vehicleTypeId !== ROLL_OFF_ID && <RouteSequenceSummary routeTemplate={isTemplate} />}
    </MapGLWrapper>
  );
};

export default RouteSequenceMapGL;
