import React, { useState } from 'react';

import { isRoutePlaybackFeatureEnabled } from 'src/vendors/ducks/features';
import { MapControl } from '../styled';
import { OpenTimeline } from '../styled/MapWithTimeline';
import { RouteMapProps } from '../RouteMap';
import { TooltipButtonTooltip, TooltipButtonWrapper } from 'src/core/components/styled/TooltipButtonStyles';
import { useSelector } from 'src/core/hooks/useSelector';
import MapTimelineModalResolver from './MapTimelineModalResolver';
import translate from '../../../core/services/translate';

export const MapWithTimelineModal: React.SFC<{
  notOnMap?: boolean;
  routeMapProps?: RouteMapProps;
  routeId: number;
  isYRoute: boolean;
  isDisabled?: boolean;
}> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isRoutePlaybackEnabled = useSelector(isRoutePlaybackFeatureEnabled);

  return (
    <React.Fragment>
      {!props.isYRoute && isRoutePlaybackEnabled && (
        <MapControl
          left="10px"
          top="90px"
          notAbsolute={props.notOnMap}
          onClick={() => !props.isDisabled && setIsModalOpen(true)}
          id="route-playback-button"
        >
          <TooltipButtonWrapper>
            {props.isDisabled && (
              <TooltipButtonTooltip position="right">
                {translate(`tooltips.routePlaybackUnavailable`)}
              </TooltipButtonTooltip>
            )}
            <OpenTimeline isDisabled={props.isDisabled}>{translate('routeTimeline.openTimeline')}</OpenTimeline>
          </TooltipButtonWrapper>
        </MapControl>
      )}
      {isModalOpen && (
        <MapTimelineModalResolver
          routeId={props.routeId}
          modalTitle={!!props.routeMapProps ? props.routeMapProps.routeName : ''}
          closeModal={() => setIsModalOpen(false)}
          mapProps={props.routeMapProps}
          isYRoute={props.isYRoute}
        />
      )}
    </React.Fragment>
  );
};
