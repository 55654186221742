import { http } from '../../core/services/http';

const createRouteImport = (vendorId: number, description: string, file: File) => {
  const formData = new FormData();

  formData.append('model', JSON.stringify({ vendorId, description }));
  formData.append('file', file);

  return http.post('vendorRouteImport', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default createRouteImport;
