import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const DAILY_ROUTE_GEO_FENCE_ID = 1;
export const ROUTE_TEMPLATE_GEO_FENCE_ID = 2;
export const STREET_SWEEPER_GEO_FENCE_ID = 3;
export const GENERAL_GEO_FENCE_ID = 4;
export const BULKY_PICKUPS_GEO_FENCE_ID = 5;

export const GEO_FENCE_TYPE = mapKeys(
  [
    { id: GENERAL_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.generalGeoFence') },
    { id: STREET_SWEEPER_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.streetSweeper') },
    { id: BULKY_PICKUPS_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.bulkyPickups') },
  ],
  'id',
);

export const GEO_FENCES_ALL_TYPES = mapKeys(
  [
    { id: DAILY_ROUTE_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.dailyRoute') },
    { id: ROUTE_TEMPLATE_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.routeTemplate') },
    { id: STREET_SWEEPER_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.streetSweeper') },
    { id: GENERAL_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.generalGeoFence') },
    { id: BULKY_PICKUPS_GEO_FENCE_ID, name: translate('routes.geoFences.zoneTypes.bulkyPickups') },
  ],
  'id',
);
