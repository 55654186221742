import React, { PureComponent } from 'react';
import { fill, map } from 'lodash-es';
import { Field, WrappedFieldProps } from 'redux-form';
import { Dropdown } from '.';
import translate from '../services/translate';
import { isRequired } from '../../utils/services/validator';
import { FieldProps } from './FieldProps';
import { DropDownProps } from './Dropdown';

interface Props extends WrappedFieldProps, FieldProps {
  dropdownProps: Partial<DropDownProps>;
  disabled?: boolean;
}

class HourPickerTimeSpan extends PureComponent<Props> {
  onChange = (event: any, value: string) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input: { name },
      placeholder,
      withPlaceholder,
      label,
      withLabel,
      dropdownProps,
      disabled,
    } = this.props;
    /* eslint-disable no-bitwise */
    const options = map(fill(Array(24 * 2), 0), (value, index) => {
      const hourN = index <= 25 ? ~~(index / 2) : ~~((index - 24) / 2);
      let hourName = '';
      if (hourN < 10 && hourN !== 0) {
        hourName = `0${hourN}`;
      } else if (hourN === 0) {
        hourName = '12';
      } else {
        hourName = `${hourN}`;
      }

      const hour = ~~(index / 2);
      let hourValue = '';
      if (hour < 10) {
        hourValue = `0${hour}`;
      } else {
        hourValue = `${hour}`;
      }
      const minute = 60 * ((index / 2) % 1);
      let minuteValue = '';
      if (minute === 0) {
        minuteValue = `0${minute}`;
      } else {
        minuteValue = `${minute}`;
      }

      return {
        label: `${hourName}:${minuteValue} ${index < 23 ? 'AM' : 'PM'}`,
        value: `${hourValue}:${minuteValue}:00`,
      };
    });

    return (
      <Field
        name={name}
        label={label || (withLabel ? translate('common.from') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('common.from') : undefined)}
        component={Dropdown as any}
        options={options}
        onChange={this.onChange}
        disabled={disabled}
        validate={!disabled ? [isRequired] : undefined}
        {...dropdownProps}
      />
    );
  }
}

export default HourPickerTimeSpan;
