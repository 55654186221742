import React, { useState } from 'react';

import styled from 'styled-components';
import { ActionButtonTooltip } from '../../../../core/components';
import { TableActionButton, TableCell, TableRow, Text } from '../../../../core/components/styled';
import { ThumbnailSetImageSlider } from '../../../../core/components/ThumbnailSetImageSlider';
import { WASTE_AUDIT_ID } from '../../../../fleet/constants';
import { WASTE_AUDIT_EXCEPTION_TYPES, WASTE_AUDIT_LOCATION_STATUS_TYPES } from '../../../../routes/constants';
import { PICKUP_ISSUE_TYPES } from '../../../../routes/constants/pickupIssueTypes';
import { date, time } from '../../../../utils/services/formatter';
import { Email } from '../../../interfaces/Email';
import { CustomerEmailModalResolver } from '../../modals/CustomerEmailModalResolver';

const ImagesContainer = styled.div`
  margin-top: 10px;
`;

export const CustomerCommunicationTableRow: React.FC<Email> = email => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const {
    fromEmail,
    imageUrl,
    rawImageUrl,
    pickupExceptionSubType,
    pickupExceptionType,
    sentByDriver,
    sentByUser,
    sentByVehicle,
    sentOn,
    toEmail,
    vehicleType,
  } = email;

  return (
    <>
      <TableRow>
        <TableCell align="center" width="4%">
          <TableActionButton onClick={() => setIsEmailModalOpen(true)}>
            <ActionButtonTooltip iconSize="medium" icon="email" tooltip="viewEmail" />
          </TableActionButton>
        </TableCell>
        <TableCell align="center" vertical width="10%">
          <Text>{date(sentOn)}</Text>
          <Text size="small">{time(sentOn)}</Text>
        </TableCell>
        <TableCell align="center" width="13%">
          <Text singleLine title={fromEmail}>
            {fromEmail}
          </Text>
        </TableCell>
        <TableCell vertical align="center" width="15%">
          {sentByDriver ? (
            <>
              <Text singleLine title={sentByDriver}>
                {sentByDriver}
              </Text>
              <Text singleLine title={sentByVehicle}>
                {sentByVehicle}
              </Text>
            </>
          ) : (
            <Text singleLine title={sentByUser}>
              {sentByUser}
            </Text>
          )}
        </TableCell>
        <TableCell align="center" width="15%">
          <Text singleLine title={toEmail}>
            {toEmail}
          </Text>
        </TableCell>
        <TableCell align="center" vertical width="15%">
          {pickupExceptionType &&
            (vehicleType && vehicleType.id === WASTE_AUDIT_ID ? (
              <Text>{WASTE_AUDIT_LOCATION_STATUS_TYPES[pickupExceptionType.id].name}</Text>
            ) : (
              <Text>{PICKUP_ISSUE_TYPES[pickupExceptionType.id].name}</Text>
            ))}
          {}
          {pickupExceptionSubType && <Text>{WASTE_AUDIT_EXCEPTION_TYPES[pickupExceptionSubType.id].name}</Text>}
          {!!imageUrl && (
            <ImagesContainer>
              <ThumbnailSetImageSlider images={[imageUrl]} imagesToDownload={[rawImageUrl || imageUrl]} />
            </ImagesContainer>
          )}
        </TableCell>
        <TableCell align="center" vertical width="15%">
          {email.location && (
            <>
              <Text title={email.location.name} block weight="medium" margin="no no xxSmall" singleLine>
                {email.location.name}
              </Text>
              <Text title={email.location.address.line1} weight="light" margin="no no xxSmall" doubleLine>
                {email.location.address.line1}
              </Text>
            </>
          )}
        </TableCell>
        <TableCell align="center" vertical width="13%">
          {email.serviceContract && (
            <>
              <Text
                block
                weight="medium"
                margin="no no xxSmall"
                singleLine
                title={email.serviceContract.serviceTypeName}
              >
                {email.serviceContract.serviceTypeName}
              </Text>
              <Text
                weight="light"
                margin="no no xxSmall"
                singleLine
                title={email.serviceContract.wasteMaterialTypeName}
              >
                {email.serviceContract.wasteMaterialTypeName}
              </Text>
            </>
          )}
        </TableCell>
      </TableRow>
      {isEmailModalOpen && (
        <CustomerEmailModalResolver onClose={() => setIsEmailModalOpen(false)} readOnly email={email} />
      )}
    </>
  );
};
