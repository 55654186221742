import { ChangeEvent, PureComponent, Fragment, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { includes, filter, camelCase } from 'lodash-es';
import { reset } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import humps from 'humps';

import { AppState } from '../../../store';
import { Action, Equipment, OpportunityRow, Options } from '../../interfaces/Opportunities';
import { ActionButtonTooltip, PopoverWrapper, UnconnectedCheckbox } from '../../../core/components';
import { AWAITING_HAULER_APPROVAL } from '../../constants/status';
import { AWARD_COMPLETED, BID_REVIEW, OPEN_AWARD, OPEN_FOR_BID } from '../../constants/opportunitiesStatus';
import { AWARD, SOURCING_TOOL } from 'src/opportunity/constants/requestType';
import { currency } from '../../../utils/services/formatter';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  DetailsListBodySection,
  Grid,
  GridColumn,
  IconButtonIcon,
  Text,
  TableActionButton,
  TableRow,
  TableCell,
  Popover,
} from '../../../core/components/styled';
import { DeclineOpportunityModal, AcceptOpportunityModal, RequestChangesForOpportunityModal } from '../modal';
import { DetailsContainer, ServiceIconImage } from '../../../fleet/components/styled/RubiconDispatches';
import {
  BUCKET,
  CADDY,
  CAGE,
  COMBINE_HAUL_FEE,
  DRUM,
  DISP,
  EXCFEE,
  HAUL,
  ROLL_OFF,
  TANK,
  TRAP,
  TRAILER,
  UNSPECIFIED,
} from '../../constants/status';
import { DocumentListModalResolver } from '../../../fleet/components/modal';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { getFrequencyTranslated } from 'src/customers/components/forms/EditWorkOrderForm';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { Offer } from '../../interfaces/ChangeRequests';
import { OpportunityInfoFillIcon } from '../styled';
import { OpportunityMobileListRowDetailsView } from '../OpportunityMobileListRowDetailsView';
import { RubiconEquipmentPopover } from '../../../fleet/components/pages/RubiconEquipmentPopover';
import { SERVICE_TYPE_ICONS as Icons } from '../../../customers/constants';
import { showNotesModal } from '../../../fleet/ducks';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import { TEMP, PERM } from '../../constants/serviceFrequencies';
import { updateHaulerOpportunity } from '../../ducks';
import confirm from '../../../core/services/confirm';
import EnterBidModal from '../modal/EnterBidModal';
import SourcingNotesModalResolver from '../modal/SourcingNotesModalResolver';
import translate from '../../../core/services/translate';
import { UNITS } from '../../../common/constants/unitOfMeasure';
import { CHANGE_OF_BILLING } from 'src/opportunity/constants/requestType';

interface ComponentProps extends RouteComponentProps {
  showMobileDetails: boolean;
  action: Action;
  bidRateDetails: any[];
  caseId: number;
  customerName: string;
  customerAddress?: string;
  divisionId: string;
  divisionName: string;
  endDate?: string;
  equipment: Equipment;
  equipmentType: string;
  endDestinations: string[];
  eventServiceId: number;
  eventType: string;
  hasVendorNotes: boolean;
  hasWorkorderDocuments: boolean;
  hasWorkOrderNotes: boolean;
  isChecked: boolean;
  isEquipmentCustomerOwned: boolean;
  isTemp: boolean;
  itemId: number;
  offer: Offer;
  options: Options;
  pendingBatchResponseStatus: boolean;
  requestType: TechnicalType;
  requestorName: string;
  status: string;
  startDate: string;
  siteName: string;
  opportunityStatus: TechnicalType;
  workOrder: string;
}

interface Props extends ComponentProps {
  activitiBaseUrl?: string;
  offer: any;
  response: any;
  readonly?: boolean;
  reset: any;
  requestedChanges: any;
  userId: number;
  vendorId: number;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  showNotesModal: DuckAction<typeof showNotesModal>;
  updateHaulerOpportunity: DuckFunction<typeof updateHaulerOpportunity>;
  checkOpportunity: (itemId: number, e: ChangeEvent<HTMLInputElement>) => void;
}

interface State {
  isAcceptOpportunityModalOpen: boolean;
  isDeclineOpportunityModalOpen: boolean;
  isDocumentListModalOpen: boolean;
  isEnterBidModalOpen: boolean;
  isSourcingNotesModalOpen: boolean;
  isRequestChangesForOpportunityModalOpen: boolean;
  response?: any;
  requestedChanges: { service?: any };
}

class OpportunitiesFormTableRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAcceptOpportunityModalOpen: false,
      isDeclineOpportunityModalOpen: false,
      isDocumentListModalOpen: false,
      isEnterBidModalOpen: false,
      isSourcingNotesModalOpen: false,
      isRequestChangesForOpportunityModalOpen: false,
      response: undefined,
      requestedChanges: { service: undefined },
    };
  }

  // Accept
  openAcceptOpportunityModal = (event?: MouseEvent) => {
    this.setState({ isAcceptOpportunityModalOpen: true });
    if (event) event.stopPropagation();
  };

  closeAcceptOpportunityModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('acceptOpportunity');
    this.setState({ isAcceptOpportunityModalOpen: false });
  };

  // Request Changes
  openRequestChangesOpportunityModal = (event: MouseEvent | null, value: string) => {
    this.setState({ isRequestChangesForOpportunityModalOpen: true });
    this.setState({ response: value });
  };

  closeRequestChangesOpportunityModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('opportunityForm');
    this.setState({ isRequestChangesForOpportunityModalOpen: false });
  };

  // Decline
  openDeclineOpportunityModal = () => {
    this.setState({ isDeclineOpportunityModalOpen: true });
  };

  closeDeclineOpportunityModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('declineOpportunity');
    this.setState({ isDeclineOpportunityModalOpen: false });
  };

  openEnterBidModal = () => {
    this.setState({ isEnterBidModalOpen: true });
  };

  closeEnterBidModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('enterBid');
    this.setState({ isEnterBidModalOpen: false });
  };

  splitRequestedChanges = (array: any[], length: number) =>
    array.reduce((result, item, index) => {
      if (index % length === 0) result.push([]);
      result[Math.floor(index / length)].push(item);
      return result;
    }, []);

  openNotesModal = (eventType: string) => {
    const { customerName, customerAddress, offer, showNotesModal, workOrder } = this.props;
    if (eventType) {
      this.setState({ isSourcingNotesModalOpen: true });
    } else {
      showNotesModal({
        workOrder,
        serviceDate: offer.deliveryDate,
        title: customerName,
        subtitle: customerAddress,
        isWorkOrderNote: true,
      });
    }
  };

  openDocumentListModal = () => {
    this.setState({ isDocumentListModalOpen: true });
  };

  closeDocumentListModal = () => {
    this.setState({ isDocumentListModalOpen: false });
  };

  closeSourcingNotesModal = () => {
    this.setState({ isSourcingNotesModalOpen: false });
  };

  render() {
    const {
      checkOpportunity,
      showMobileDetails,
      action,
      customerName,
      customerAddress,
      divisionId,
      endDate,
      equipment,
      equipmentType,
      eventServiceId,
      eventType,
      hasVendorNotes,
      hasWorkorderDocuments,
      hasWorkOrderNotes,
      itemId,
      isChecked,
      isTemp,
      offer,
      options,
      pendingBatchResponseStatus,
      requestType,
      requestorName,
      requestedChanges,
      siteName,
      status,
      startDate,
      opportunityStatus,
      workOrder,
    } = this.props;
    const { isRequired, numberOfDaysOverdue, numberOfHoursLeft } = action;
    const { type, subType, frequency, size, units, quantity, wasteMaterial, iconType } = equipment;

    const { canAccept, canSubmitBid, canDecline, canDeclineBid, canRequestChanges } = options;
    const { deliveryDate, isMonthly, overallserviceCharge, service } = offer;
    const { id, name } = opportunityStatus;
    const equipmentUnit = UNITS[units]?.name || units;
    const {
      isAcceptOpportunityModalOpen,
      isDeclineOpportunityModalOpen,
      isDocumentListModalOpen,
      isEnterBidModalOpen,
      isSourcingNotesModalOpen,
      isRequestChangesForOpportunityModalOpen,
    } = this.state;

    const frequencyType = isTemp ? TEMP : PERM;
    const isDateVisible = id === AWARD_COMPLETED || id === BID_REVIEW || id === OPEN_AWARD || id === OPEN_FOR_BID;
    const haulerRateDetails = requestedChanges?.haulerRateDetails;
    const haulFeeDetail = filter(haulerRateDetails, o => includes(o.rateCd, HAUL));
    const disposalFeeDetail = filter(haulerRateDetails, o => includes(o.rateCd, DISP));
    const combineHaulFeeDetail = filter(haulerRateDetails, o => includes(o.rateCd, COMBINE_HAUL_FEE));
    const excessFeeDetail = filter(haulerRateDetails, o => includes(o.rateCd, EXCFEE));
    const haulingFee = !!haulFeeDetail[0]?.rateType ? haulFeeDetail[0]?.rateInDollar : haulFeeDetail[0]?.rate;
    const dispFee = !!disposalFeeDetail[0]?.rateType ? disposalFeeDetail[0]?.rateInDollar : disposalFeeDetail[0]?.rate;
    const combineHaulFee = !!combineHaulFeeDetail[0]?.rateType
      ? combineHaulFeeDetail[0]?.rateInDollar
      : combineHaulFeeDetail[0]?.rate;
    const excessFee = !!excessFeeDetail[0]?.rateType ? excessFeeDetail[0]?.rateInDollar : excessFeeDetail[0]?.rate;

    const isBellIconVisible = id !== BID_REVIEW && isRequired;
    const greaseOilsAndRollOffEquipments = [TRAP, ROLL_OFF, BUCKET, CADDY, DRUM, TANK];
    const tiresEquipments = [CAGE, TRAILER, UNSPECIFIED];
    return (
      <Fragment>
        {showMobileDetails ? (
          <OpportunityMobileListRowDetailsView
            {...(this.props as any as OpportunityRow)}
            onAcceptBid={this.openAcceptOpportunityModal}
            onDeclineBid={this.openDeclineOpportunityModal}
            onRequestChanges={() => this.openRequestChangesOpportunityModal(null, 'RequestChanges')}
            onSubmitBid={this.openEnterBidModal}
          />
        ) : (
          <TableRow>
            <TableCell
              width={'5%'}
              padding={requestType.id === AWARD ? 'defaultCellVertical defaultCellHorizontal' : 'no'}
            >
              {requestType.id === AWARD && id === OPEN_AWARD && (
                <UnconnectedCheckbox
                  block
                  checked={isChecked}
                  disabled={pendingBatchResponseStatus}
                  onChange={e => checkOpportunity(itemId, e)}
                  onClick={e => e.stopPropagation()}
                  id={`openAwardOpportunity-${itemId}-checkbox`}
                />
              )}
            </TableCell>

            <TableCell width="10%">
              <DetailsListBodySection flex justifyStart overflow="hidden" padding="no">
                <Text block weight="medium">
                  {translate(`opportunity.requestTypes.${camelCase(requestType.name)}`)}
                </Text>
                {eventType && (
                  <Text block weight="light">
                    {translate(`opportunity.eventTypes.${camelCase(eventType)}`)}
                  </Text>
                )}
              </DetailsListBodySection>
            </TableCell>

            <TableCell width="19%">
              <DetailsListBodySection flex justifyStart overflow="hidden" padding="no">
                <Text block weight="medium" singleLine>
                  {customerName}
                </Text>

                {siteName && (
                  <Text weight="light" size="small" doubleLine>
                    {siteName}
                  </Text>
                )}
                <PopoverWrapper
                  triggerButton={
                    <Text block weight="light" size="small" singleLine>
                      {customerAddress}
                    </Text>
                  }
                  popoverContent={
                    <Popover>
                      <div>{customerAddress}</div>
                    </Popover>
                  }
                  width="200px"
                  size="large"
                />
              </DetailsListBodySection>
            </TableCell>

            <TableCell width="19%">
              <DetailsContainer noShadow padding="no">
                <PopoverWrapper
                  triggerButton={<ServiceIconImage src={Icons[humps.camelize(iconType.split('.').join(''))]} />}
                  popoverContent={
                    <RubiconEquipmentPopover service={equipment} startDate={startDate} endDate={endDate} />
                  }
                  width="53px"
                  size="large"
                  margin="no"
                />
                <DetailsListBodySection flex overflow="hidden" padding="no no no small">
                  <Text block weight="medium" singleLine>
                    {subType ? translate(`haulerProfile.equipments.equipmentSubTypes.${subType}`) : type} ({quantity})
                  </Text>

                  <Text block weight="light" size="small" singleLine>
                    {size} {equipmentUnit} | {wasteMaterial}
                  </Text>
                  <Text block weight="light" size="small" singleLine>
                    {getFrequencyTranslated(frequency)} ({frequencyType})
                  </Text>
                  <Text block weight="light" size="small" singleLine>
                    {translate('opportunity.opportunities.division', { divisionId })}
                  </Text>
                </DetailsListBodySection>
              </DetailsContainer>
            </TableCell>

            <TableCell width="13%">
              <DetailsListBodySection flex justifyStart overflow="hidden" padding="no">
                <Text block weight="medium">
                  {translate(`opportunity.opportunities.status.${camelCase(name)}`)}
                  {status === AWAITING_HAULER_APPROVAL && (
                    <PopoverWrapper
                      triggerButton={<OpportunityInfoFillIcon color="primary" margin="no" />}
                      popoverContent={
                        <Popover>
                          {this.splitRequestedChanges(requestedChanges.displayFees, 3).map(
                            (requestedChange: any, index: number) => (
                              <TableRow key={index} borderBottom="0px">
                                {requestedChange.map((fees: any) => (
                                  <TableCell vertical padding="no no no xSmall" width="200px">
                                    <Text block margin="no no no" weight="light" size="small" color="grayDarker">
                                      {fees.name}
                                    </Text>
                                    <Text margin="no no no" weight="normal" size="small">
                                      {fees.value}
                                    </Text>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ),
                          )}
                        </Popover>
                      }
                      size="xLarge"
                      width="16px"
                      height="18px"
                    />
                  )}
                </Text>
                {isDateVisible && (
                  <Fragment>
                    <Text block weight="light" size="small">
                      {requestType.id === AWARD && requestorName !== SOURCING_TOOL
                        ? translate('opportunity.opportunities.startDate', { deliveryDate })
                        : translate('opportunity.opportunities.bidDue', { deliveryDate })}
                      {isBellIconVisible && (
                        <IconButtonIcon icon="bell" color="alert" size="medium" margin="no no no xxSmall" />
                      )}
                    </Text>
                  </Fragment>
                )}
                {isBellIconVisible && !!numberOfDaysOverdue && (
                  <Text weight="normal" margin="no no xxSmall" singleLine size="small" color="alert">
                    {translate('common.xDaysOverdue', { days: numberOfDaysOverdue })}
                  </Text>
                )}
                {isBellIconVisible && !!numberOfHoursLeft && (
                  <Text block weight="normal" margin="no no xxSmall" singleLine size="small" color="alert">
                    {translate('common.xHoursLeft', { hours: numberOfHoursLeft })}
                  </Text>
                )}
                {endDate && (
                  <Fragment>
                    <Text margin="no no xxSmall" size="small" color="grayDarker">
                      {translate('common.endDate')}: {endDate}
                    </Text>
                  </Fragment>
                )}
                {pendingBatchResponseStatus && (
                  <Text block weight="normal" margin="no no xxSmall" singleLine size="small" color="primary">
                    {translate('dashboard.inProgress')}
                  </Text>
                )}
              </DetailsListBodySection>
            </TableCell>
            <TableCell width="18%">
              <DetailsListBodySection flex justifyStart overflow="hidden" padding="no">
                <PopoverWrapper
                  triggerButton={
                    <Fragment>
                      {!!service && (
                        <Text block weight="medium" size="small">
                          {translate('opportunity.opportunities.targetRate', { rate: currency(service) })}
                        </Text>
                      )}
                      {isMonthly ? (
                        <>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidAmount')}
                          </Text>{' '}
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.monthlyTotal', {
                              total: overallserviceCharge || 0,
                            })}
                          </Text>
                        </>
                      ) : greaseOilsAndRollOffEquipments.includes(equipmentType) ? (
                        <>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidAmount')}
                          </Text>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidHaulFee', {
                              hauleFee: currency(haulingFee || 0),
                            })}
                          </Text>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidDispFee', {
                              dispFee: currency(dispFee || 0),
                              units: disposalFeeDetail[0]?.uom.toLowerCase(),
                            })}
                          </Text>
                        </>
                      ) : tiresEquipments.includes(equipmentType) ? (
                        <>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidAmount')}
                          </Text>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidCombineHaulFee', {
                              combineHaulFee: currency(combineHaulFee || 0),
                            })}
                          </Text>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidExcessFee', {
                              excessFee: currency(excessFee || 0),
                              units: excessFeeDetail[0]?.uom.toLowerCase(),
                            })}
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidAmount')}
                          </Text>
                          <Text block weight="medium" size="small" color="primary">
                            {translate('opportunity.opportunities.bidHaulFee', { hauleFee: currency(haulingFee) })}
                          </Text>
                        </>
                      )}
                    </Fragment>
                  }
                  popoverContent={
                    <Popover>
                      <Fragment>
                        <Text block weight="medium" size="small" color="primary" margin="xSmall no xSmall">
                          {translate('opportunity.opportunities.submittedBidSummary')}
                        </Text>

                        <GridColumn size="12/12">
                          <Grid centered multiLine>
                            {haulerRateDetails.map((haulerRateDetail: any) => (
                              <Fragment key={haulerRateDetail.rateCd}>
                                <GridColumn size="9/12">
                                  <Text block weight="light" size="small" color="grayDarker">
                                    {haulerRateDetail.rateDesc}
                                  </Text>
                                </GridColumn>

                                <GridColumn size="3/12">
                                  <Text block weight="light" size="small" color="grayDarker">
                                    {currency(
                                      !!haulerRateDetail.rateType
                                        ? haulerRateDetail.rateInDollar
                                        : haulerRateDetail.rate,
                                    )}
                                  </Text>
                                </GridColumn>
                              </Fragment>
                            ))}
                          </Grid>
                        </GridColumn>
                      </Fragment>
                    </Popover>
                  }
                  size="large"
                  margin="no"
                />
              </DetailsListBodySection>
            </TableCell>

            <TableCell width="16%" align="right">
              {canAccept && (
                <TableActionButton
                  margin="small small no no"
                  onClick={eventType === CHANGE_OF_BILLING ? this.openEnterBidModal : this.openAcceptOpportunityModal}
                  disabled={pendingBatchResponseStatus}
                  id={`accept-button-${itemId}`}
                >
                  <ActionButtonTooltip customViewBox="0 0 25 25" size="mLarge" icon="checkCircle" tooltip="accept" />
                </TableActionButton>
              )}
              {canSubmitBid && (
                <TableActionButton
                  margin="xSmall sMedium no no"
                  onClick={this.openEnterBidModal}
                  id={`submit-button-${itemId}`}
                >
                  <ActionButtonTooltip size="mLarge" icon="bid" color="primary" tooltip="submitBid" />
                </TableActionButton>
              )}
              {(canDecline || canDeclineBid) && (
                <TableActionButton
                  margin="xSmall small no no"
                  onClick={this.openDeclineOpportunityModal}
                  disabled={pendingBatchResponseStatus}
                  id={`decline-button-${itemId}`}
                >
                  <ActionButtonTooltip customViewBox="0 0 25 25" size="mLarge" icon="closedIssue" tooltip="decline" />
                </TableActionButton>
              )}

              {canRequestChanges && (
                <TableActionButton
                  margin="xSmall xSmall no no"
                  disabled={pendingBatchResponseStatus}
                  onClick={event => this.openRequestChangesOpportunityModal(event, 'RequestChanges')}
                  id={`request-changeg-button-${itemId}`}
                >
                  <ActionButtonTooltip size="mMedium" icon="changeRequest" color="primary" tooltip="requestChange" />
                </TableActionButton>
              )}
              {(hasWorkOrderNotes || hasVendorNotes) && (
                <TableActionButton margin="xSmall xSmall no no" onClick={() => this.openNotesModal(eventType)}>
                  <ActionButtonTooltip
                    customViewBox="0 0 25 25"
                    size="medium"
                    icon="page-content"
                    color="primary"
                    tooltip="viewNote"
                  />
                </TableActionButton>
              )}
              {hasWorkorderDocuments && (
                <TableActionButton margin="xSmall xSmall no no" onClick={() => this.openDocumentListModal()}>
                  <ActionButtonTooltip
                    customViewBox="0 0 32 20"
                    size="medium"
                    icon="redeye"
                    color="primary"
                    tooltip="viewDocument"
                  />
                </TableActionButton>
              )}
            </TableCell>
          </TableRow>
        )}
        {isAcceptOpportunityModalOpen && (
          <AcceptOpportunityModal
            {...this.props}
            isBiddingSourcing={true}
            onCancel={this.closeAcceptOpportunityModal}
          />
        )}
        {isRequestChangesForOpportunityModalOpen && (
          <RequestChangesForOpportunityModal
            {...this.props}
            isBiddingSourcing={true}
            onCancel={this.closeRequestChangesOpportunityModal}
          />
        )}
        {isDeclineOpportunityModalOpen && (
          <DeclineOpportunityModal {...this.props} onCancel={this.closeDeclineOpportunityModal} />
        )}
        {isEnterBidModalOpen && <EnterBidModal {...this.props} onCancel={this.closeEnterBidModal} />}
        {isDocumentListModalOpen && (
          <DocumentListModalResolver
            scheduledDate={deliveryDate}
            workOrderNo={workOrder}
            closeModal={this.closeDocumentListModal}
          />
        )}
        {isSourcingNotesModalOpen && (
          <SourcingNotesModalResolver
            title={customerName}
            subTitle={customerAddress}
            itemId={itemId}
            eventServiceId={eventServiceId}
            onCancel={this.closeSourcingNotesModal}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => ({
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
});

const mapDispatchToProps = {
  loadDispatchOpportunitiesCount,
  updateHaulerOpportunity,
  reset,
  showNotesModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpportunitiesFormTableRow));
