import { useDispatch } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import { FC, useEffect, useMemo, useState } from 'react';

import { CommunityInsightItem } from 'src/insights/interfaces/communityInsights';
import { getCommunityInsightsGeoJSON } from './utils';
import { getMapBounds } from 'src/common/components/map/util';
import { MAP_CITY_ZOOM } from 'src/core/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { setCommunityinsightsMapSelectedFeature, setCommunityinsightsMapViewport } from 'src/insights/ducks/mapControl';
import { useSelector } from 'src/core/hooks/useSelector';
import CommunityInsightsClustersGL, { COMMUNITY_INSIGHTS_CLUSTERS_SOURCE } from './CommunityInsightsClustersGL';
import CommunityInsightsGL from './CommunityInsightsGL';
import CommunityInsightsGLHeatmap from './CommunityInsightsGLHeatmap';
import CommunityInsightsMapGLPopup from './CommunityInsightsMapGLPopup';
import CommunityInsightsMapLegend from './CommunityInsightsMapLegend';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';

interface Props {
  insights: CommunityInsightItem[];
}

const CommunityInsightsMapGL: FC<Props> = ({ insights }) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [isHeatMapVisible, setIsHeatMapVisible] = useState(false);

  const { selectedFeature, viewport } = useSelector(state => state.insights.mapControl);

  const handleSatelliteViewChange = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  const toggleHeatmap = () => {
    setIsHeatMapVisible(!isHeatMapVisible);
  };

  //fit map bounds
  useEffect(() => {
    const points = insights.map(insight => {
      return { longitude: insight.longitude, latitude: insight.latitude };
    });
    const bounds = getMapBounds(points, {
      padding: 25,
      capZoom: MAP_CITY_ZOOM,
    });
    dispatch(setCommunityinsightsMapViewport(bounds));
  }, [dispatch, insights]);

  //insight or row clicked
  useEffect(() => {
    if (map && !!selectedFeature) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      const selectedInsight = insights.find(insight => insight.id === selectedFeature.id);
      if (!!selectedInsight) {
        const bounds = getMapBounds([{ longitude: selectedInsight.longitude, latitude: selectedInsight.latitude }], {
          padding: 25,
          capZoom: MAP_CITY_ZOOM,
        });
        dispatch(setCommunityinsightsMapViewport(bounds));
      }
    }
  }, [dispatch, insights, map, selectedFeature]);

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === COMMUNITY_INSIGHTS_CLUSTERS_SOURCE);
        if (!!feature) {
          dispatch(setCommunityinsightsMapSelectedFeature(feature.id as number));
        }
      });
      map.on('mousemove', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true || !!feature.properties?.cluster_id,
        );

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [dispatch, map]);

  const geoJSON = useMemo(() => getCommunityInsightsGeoJSON(insights), [insights]);

  return (
    <MapGLWrapper>
      <MapGL
        viewport={viewport}
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        onMapRefLoaded={map => {
          setMap(map);
        }}
        setIsSatelliteViewEnabled={handleSatelliteViewChange}
      >
        {!!insights.length && (
          <CommunityInsightsMapLegend
            isHeatmapVisible={isHeatMapVisible}
            toggleHeatMap={toggleHeatmap}
            insights={insights}
          />
        )}

        {!!map &&
          (isHeatMapVisible ? (
            <CommunityInsightsGLHeatmap geoJSON={geoJSON} />
          ) : (
            <>
              <CommunityInsightsClustersGL map={map} insights={insights || []} />
              <CommunityInsightsGL map={map} />
            </>
          ))}

        <CommunityInsightsMapGLPopup />
      </MapGL>
    </MapGLWrapper>
  );
};

export default CommunityInsightsMapGL;
