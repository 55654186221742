import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';

import {
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { Panel, PanelSection, PanelSectionTitle } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import InvoiceDetailSection from './InvoiceDetailsSection';
import NewPaymentSectionResolver from './NewPaymentSectionResolver';
import PaymentMethodSection from './PaymentMethodsSection';
import { getHasProcessingFee } from 'src/finance/ducks/paymentManagement';

interface Props {
  invoiceId: string;
  returnToBillingDetails?: string;
}

const PayNowPage = ({ invoiceId, returnToBillingDetails }: Props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const invoiceDetails = useSelector(state => state.finance.paymentManagement.invoiceDetails);
  const paymentMethodsByInvoice = useSelector(state => state.finance.paymentManagement.paymentMethodsByInvoice);
  const totalAmountWithFees = useSelector(state => state.finance.paymentManagement.paymentFee.finalAmount || 0);
  const hasProcessingFee = useSelector(state => state.finance.paymentManagement.hasProcessingFee);

  useEffect(() => {
    if (invoiceDetails?.customerId) {
      getHasProcessingFee(invoiceDetails.customerId)(dispatch);
    }
  }, [invoiceDetails?.customerId, dispatch]);

  useEffect(() => {
    if (!paymentMethodsByInvoice || paymentMethodsByInvoice.length === 0) {
      setShowPaymentForm(true);
    }
  }, [paymentMethodsByInvoice]);

  const goBackToPreviousPage = () =>
    returnToBillingDetails
      ? dispatch(push(`/finance/billing/bill-details/${invoiceId}`))
      : dispatch(push(`/finance/payment-management${search}`));

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer fluid>
            <PageBackButtonAction onClick={goBackToPreviousPage} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle margin="small">{translate('finance.collectPayment')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
      </PageHeader>
      {showPaymentForm && (
        <Panel margin="no no xLarge" padding="medium" withBorder>
          <PanelSection>
            <NewPaymentSectionResolver
              successProps={{
                onCancel:
                  paymentMethodsByInvoice?.length === 0
                    ? () => goBackToPreviousPage()
                    : () => setShowPaymentForm(false),
              }}
            />
          </PanelSection>
        </Panel>
      )}
      {!showPaymentForm && paymentMethodsByInvoice && paymentMethodsByInvoice.length > 0 && !!invoiceDetails && (
        <>
          <Panel margin="no no xLarge" padding="medium" withBorder>
            <PanelSection>
              <PaymentMethodSection
                invoiceDetails={invoiceDetails}
                paymentMethods={paymentMethodsByInvoice}
                addPaymentMethod={() => setShowPaymentForm(true)}
                totalAmountWithFees={totalAmountWithFees}
                hasProcessingFee={hasProcessingFee}
              />
            </PanelSection>
          </Panel>

          <Panel margin="no no xLarge" padding="medium" withBorder>
            <PanelSection>
              <PanelSectionTitle>
                {`${translate('common.location')} - ${invoiceDetails.locationAddress || ' '}`}
              </PanelSectionTitle>
            </PanelSection>
            <PanelSection>
              <InvoiceDetailSection invoiceDetails={invoiceDetails} />
            </PanelSection>
          </Panel>
        </>
      )}
    </PageContent>
  );
};

export default PayNowPage;
