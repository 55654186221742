import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from 'src/store';
import { loadContainersStatistics, loadLowInventoryParts, saveLowInventoryPart } from 'src/fleet/ducks';
import { Modal } from 'src/core/components';
import EditInventoryForm from './EditInventoryForm';
import translate from 'src/core/services/translate';

interface Props extends RouteComponentProps {
  closeModal: () => void;
  id: number;
  isEditing: boolean;
  partName: string;
  saveEditInventory: (formData: any) => void;
}

const EditInventoryModal: React.FC<Props> = ({ closeModal, id, saveEditInventory, isEditing, partName }) => {
  return (
    <Modal
      title={translate('containers.editInventory')}
      onClose={closeModal}
      size="xSmall"
      padding="medium"
      isLoading={isEditing}
    >
      <EditInventoryForm id={id} onCancel={closeModal} onSubmit={saveEditInventory} partName={partName} />
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isEditing: state.fleet.lowInventoryParts.isEditing,
});

const mapDispatchToProps = { loadContainersStatistics, loadLowInventoryParts, saveLowInventoryPart };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditInventoryModal));
