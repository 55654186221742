import React from 'react';
import { get } from 'lodash-es';
import { WrappedFieldInputProps } from 'redux-form';
import { connect } from 'react-redux';
import { Text } from '../../core/components/styled';
import { disposalSiteLocationsByIdSelector } from '../ducks';
import { AppState } from '../../store';

interface Props {
  input: WrappedFieldInputProps;
  disposalSiteLocationsById: any;
}

const DisposalSiteLocationText: React.SFC<Props> = ({ disposalSiteLocationsById, input: { value } }) => (
  <Text>{get(disposalSiteLocationsById[value], 'customerName')}</Text>
);

const mapStateToProps = (state: AppState) => ({
  disposalSiteLocationsById: disposalSiteLocationsByIdSelector(state.common.disposalSiteLocations),
});

export default connect(mapStateToProps)(DisposalSiteLocationText);
