import React from 'react';
import { useDispatch } from 'react-redux';

import {
  loadRoutePlannerRouteDriversForAvailability,
  loadRoutePlannerRouteVehiclesForAvailability,
} from 'src/routes/ducks/routePlanner';
import { RoutePlannerRouteDriver } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteDriver';
import { RoutePlannerRouteVehicle } from 'src/routes/interfaces/routePlanner/RoutePlannerRouteVehicle';
import { PageLoadingOverlay } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import DriversAndVehiclesStatusEditModal, { STATUS_EDITOR_TABS } from './DriversAndVehiclesStatusEditModal';

interface Props {
  date: string;
  activeTab: STATUS_EDITOR_TABS;
  filters: any;
  closeModal: () => void;
  onStatusChanged: (drivers: RoutePlannerRouteDriver[], vehicles: RoutePlannerRouteVehicle[]) => void;
}

const DriversAndVehiclesStatusEditModalResolver: React.FC<Props> = ({
  date,
  activeTab,
  filters,
  closeModal,
  onStatusChanged,
}: Props) => {
  const dispatch = useDispatch();

  const loadDependencies = React.useCallback(() => {
    const dependencies = [
      loadRoutePlannerRouteDriversForAvailability({ ...filters, vehicleTypeIds: '' })(dispatch),
      loadRoutePlannerRouteVehiclesForAvailability({ ...filters, vehicleTypeIds: '' })(dispatch),
    ];

    return Promise.all(dependencies);
  }, [dispatch, filters]);

  return (
    <TypedResolver
      successComponent={DriversAndVehiclesStatusEditModal}
      loadingComponent={PageLoadingOverlay}
      successProps={{ date, activeTab, closeModal, onStatusChanged }}
      resolve={loadDependencies}
    />
  );
};

export default DriversAndVehiclesStatusEditModalResolver;
