import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { RoutePlannerVehicleStatus } from 'src/routes/interfaces/routePlanner/RoutePlannerVehicleStatus';
import {
  loadVehicleStatus as doLoadVehicleStatus,
  updateVehiclesStatus as doUpdateVehiclesStatus,
} from 'src/routes/services/routePlanner';

const START_LOAD = 'routePlannerVehicleStatus/START_LOAD';
const COMPLETE_LOAD = 'routePlannerVehicleStatus/END_LOAD';
const FAIL_LOAD = 'routePlannerVehicleStatus/FAIL_LOAD';
const START_SAVE = 'routePlannerVehicleStatus/START_SAVE';
const COMPLETE_SAVE = 'routePlannerVehicleStatus/END_SAVE';
const FAIL_SAVE = 'routePlannerVehicleStatus/FAIL_SAVE';
const RESET = 'routePlannerVehicleStatus/RESET';

interface State {
  isLoading: boolean;
  vehicleStatus: RoutePlannerVehicleStatus | null;
  updatedVehicleStatuses: RoutePlannerVehicleStatus[];
}

const initialState: State = {
  isLoading: false,
  vehicleStatus: null,
  updatedVehicleStatuses: [],
};

export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleStatus: action.vehicleStatus,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleStatus: null,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    // on complete kee the updatedVehicleStatuses updated with the new status, if any of them is already there update it otherwise add it
    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
          updatedVehicleStatuses: action.updatedVehicleStatuses.reduce(
            (acc: any[], updatedVehicleStatus: { vehicleId: any }) => {
              const index = acc.findIndex(vehicleStatus => vehicleStatus.vehicleId === updatedVehicleStatus.vehicleId);
              if (index === -1) {
                return [...acc, updatedVehicleStatus];
              }
              return update(acc, {
                [index]: {
                  $merge: updatedVehicleStatus,
                },
              });
            },
            state.updatedVehicleStatuses,
          ),
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });
    case RESET:
      return initialState;

    default:
      return state;
  }
};

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleStatus: RoutePlannerVehicleStatus) => ({
  type: COMPLETE_LOAD,
  vehicleStatus,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (updatedVehicleStatuses: RoutePlannerVehicleStatus[]) => ({
  type: COMPLETE_SAVE,
  updatedVehicleStatuses,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const resetRoutePlannerVehicleStatus = () => ({
  type: RESET,
});

export const loadRoutePlannerVehicleStatus =
  (vendorId: number, vehicleId: number, date: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadVehicleStatusPromise = doLoadVehicleStatus(vendorId, vehicleId, date);
    loadVehicleStatusPromise
      .then(vehicleStatus => {
        dispatch(completeLoad(vehicleStatus));
      })
      .catch(() => {
        dispatch(failLoad());
      });
    return loadVehicleStatusPromise;
  };

export const updateRoutePlannerVehiclesStatus =
  (vendorId: number, vehicleStatuses: RoutePlannerVehicleStatus[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const updateVehiclesStatusPromise = doUpdateVehiclesStatus(vendorId, vehicleStatuses);
    updateVehiclesStatusPromise
      .then(() => {
        dispatch(completeSave(vehicleStatuses));
      })
      .catch(() => {
        dispatch(failSave());
      });
    return updateVehiclesStatusPromise;
  };
