import React from 'react';
import { debounce, map } from 'lodash-es';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { PanelSearch, Dropdown, TypedField } from '../../../core/components';
import { USER_ROLES, USER_STATUSES } from '../../constants';
import translate from '../../../core/services/translate';

type UsersFilterFormValues = {
  userSearchTerm?: string;
  userRole?: number;
  userStatus?: string;
};

type PropsWithoutReduxForm = { setFilterValue: (filter: string, value: any) => void };

type Props = PropsWithoutReduxForm & InjectedFormProps<UsersFilterFormValues, PropsWithoutReduxForm>;

function UsersFilterForm({ setFilterValue }: Props) {
  const userRoleOptions = map(USER_ROLES, ({ name, id }) => ({ label: name, value: id }));
  const userStatusOptions = map(USER_STATUSES, ({ name, id }) => ({ label: name, value: id }));

  const handleChangeUserSearchTerm = debounce((_: unknown, searchTerm: string) => {
    setFilterValue('userSearchTerm', searchTerm);
  }, 300);

  const handleChangeUserRole = (_: unknown, role: number) => {
    setFilterValue('userRole', role);
  };

  const handleChangeUserStatus = (_: unknown, status: string) => {
    setFilterValue('userStatus', status);
  };

  return (
    <form>
      <PanelSection padding="sMedium xSmall" withBorder>
        <Grid>
          <GridColumn size="8/12">
            <TypedField
              name="userSearchTerm"
              component={PanelSearch}
              onChange={handleChangeUserSearchTerm}
              props={{
                margin: 'no',
              }}
            />
          </GridColumn>

          <GridColumn size="2/12">
            <TypedField
              name="userRole"
              component={Dropdown}
              onChange={handleChangeUserRole}
              props={{
                margin: 'no',
                options: userRoleOptions,
                placeholder: translate('vendors.role'),
                isClearable: true,
              }}
            />
          </GridColumn>

          <GridColumn size="2/12">
            <TypedField
              name="userStatus"
              component={Dropdown}
              onChange={handleChangeUserStatus}
              props={{
                margin: 'no',
                options: userStatusOptions,
                placeholder: translate('common.status'),
                isClearable: true,
              }}
            />
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
}

export default reduxForm<UsersFilterFormValues, PropsWithoutReduxForm>({
  form: 'usersFilterForm',
})(UsersFilterForm);
