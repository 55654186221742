import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from '../../../core/components/styled';
import Icon from '../../../core/components/Icon';

export const GeoFencePopoverTitle = styled.div`
  margin: 0 0 10px 0;
`;

export const GeoFencePopoverClose = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.grayLight};
  border-radius: 4px;
  color: ${props => props.theme.grayDark};
  transition: background-color 0.3s ease-out;
  cursor: pointer;
`;

export const PopoverCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
`;

export const GeoFencesFilters = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
`;

export const GeoFenceButtons = styled.div`
  text-align: right;

  a {
    white-space: nowrap;
  }
`;

export const GeoFenceMapIconButton = styled.div`
  white-space: nowrap;

  button {
    width: 100%;
    text-align: right;
    color: ${props => props.theme.brandPrimary};

    &:active {
      background: none;
    }
  }
`;
export const GeoFenceFilter = styled.button<{ isActive?: boolean }>`
  ${BareButtonStyle}
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  border: 1px solid ${props => (props.isActive ? props.theme.brandPrimary : props.theme.gray)};
  border-radius: 6px;
  width: 100%;
  height: 68px;
  align-items: center;
  margin: 0 15px 0 0;
  justify-content: center;

  &:disabled {
    cursor: default;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${rgba(props.theme.brandPrimary, 0.13)};
    `}
`;
