import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { isCityAlertFeatureEnabled, isLocationAlertFeatureEnabled } from 'src/vendors/ducks/features';
import { loadCityAlertSettings } from 'src/vendors/ducks';
import { loadVendor } from 'src/vendors/ducks';
import { ModalProps } from 'src/core/components/Modal';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import CityAlertCreateModal from './CityAlertCreateModal';

export interface Props extends ModalProps {
  isCityAlert?: boolean;
  isLocationAlert?: boolean;
  isSourceLocationAndCityAlerts?: boolean;
  modalTitle: string;
  onSavedSuccess?: (id?: number) => void;
  routeId?: number;
}

const CityAlertCreateModalResolver: FC<Props> = ({
  isCityAlert,
  isLocationAlert,
  isSourceLocationAndCityAlerts,
  modalTitle,
  onClose,
  onSavedSuccess,
  routeId,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const isCityAlertEnabled = useSelector(isCityAlertFeatureEnabled);
  const isLocationAlertEnabled = useSelector(isLocationAlertFeatureEnabled);

  const loadDependencies = async () => {
    if (
      (isSourceLocationAndCityAlerts && !isCityAlertEnabled && !isLocationAlertEnabled) ||
      (!isSourceLocationAndCityAlerts && !isCityAlertEnabled)
    ) {
      return Promise.reject();
    }

    const noLoadingIndicator = true;
    const dependencies: Promise<any>[] = [loadVendor(vendorId, noLoadingIndicator)(dispatch)];

    isCityAlertEnabled && dependencies.push(loadCityAlertSettings(vendorId)(dispatch));

    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={CityAlertCreateModal}
      successProps={{
        isCityAlert,
        isLocationAlert,
        isSourceLocationAndCityAlerts,
        modalTitle,
        onClose,
        onSavedSuccess,
        routeId,
      }}
      loadingComponent={PageLoadingOverlay}
      onError={onClose}
      resolve={loadDependencies}
    />
  );
};

export default CityAlertCreateModalResolver;
