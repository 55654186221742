import * as turf from '@turf/turf';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getFeatureCollection } from 'src/common/components/map/util';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import store from 'src/store';
import { DOUBLE_SIDE_SERVICE, SINGLE_SIDE_SERVICE } from '../constants';
import { StreetNetworkPropertiesTravelPath } from '../interfaces/TravelPath';

/**
 * Offsets a line by a given amount.
 * @param line The line to offset.
 * @param offset The amount to offset the line.
 * @returns The offset line.
 */
const offsetLine = (line: GeoJSON.Feature<GeoJSON.MultiLineString>, offset: number) => {
  return turf.lineOffset(line, offset, { units: 'meters' }) as GeoJSON.Feature<
    GeoJSON.MultiLineString,
    StreetNetworkPropertiesTravelPath
  >;
};

/**
 * Cuts a given amount from the beginning and the end of a line.
 * @param line The line to cut.
 * @param amount The amount to cut from the beginning and the end of the line.
 * @returns The line with the amount cut from the beginning and the end.
 */
const cutAmountFromBothLineEnds = (
  line: GeoJSON.Feature<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>,
  amount: number,
) => {
  const cutFromBeginning = amount;
  const cutFromEnd = amount;

  // Calculate the total length of the LineString
  const totalLength = turf.length(line, { units: 'meters' });
  if (totalLength - 2 < cutFromBeginning + cutFromEnd) return line;

  const coords = turf.getCoords(line).flat(1);
  const startDistance = cutFromBeginning;
  const endDistance = totalLength - cutFromEnd;

  // Convert the multiLineString to a lineString
  const lineConverted = turf.lineString(coords);

  const cutLine = turf.lineSliceAlong(lineConverted, startDistance, endDistance, { units: 'meters' });

  const multiLineCut: GeoJSON.Feature<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath> = {
    type: 'Feature',
    geometry: {
      type: 'MultiLineString',
      coordinates: [turf.getCoords(cutLine)],
    },
    properties: line.properties,
  };

  return multiLineCut;
};

const ONE_WAY_SAME_DIGITIZATION = ['FT', 'F', ''];
const ONE_WAY_AGAINST_DIGITIZATION = ['TF', 'T', ''];

/**
 * The service for the travel path editor. It handles the selection of the path.
 * @returns The service for the travel path editor.
 */
const useTravelPathEditorService = () => {
  const [selectedSegments, setSelectedSegments] = useState<
    GeoJSON.FeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>
  >({
    type: 'FeatureCollection',
    features: [],
  });

  const {
    startSegment,
    endSegment,
    streetNetwork,
    streetTurnRestrictions,
    travelPathEditData,
    hasAppliedEditsToTravelPath,
  } = useSelector(state => state.routes.travelPathBuildAndEdit);

  const { travelPathStatusDetails } = useSelector(state => state.routes.travelPath);

  const [hasReachedEndSegment, setHasReachedEndSegment] = useState<boolean>(false);

  const lastAddedSegment = useMemo(() => {
    if (!selectedSegments.features.length) return null;

    return selectedSegments.features[selectedSegments.features.length - 1];
  }, [selectedSegments]);

  /**
   * Returns the options to choose for the next segment of the path.
   * @returns The options to choose for the next segment of the path.
   */
  const optionsToChooseForPath = useMemo(() => {
    if (!lastAddedSegment || !streetNetwork || hasReachedEndSegment)
      return getFeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>([]);

    const options: GeoJSON.Feature<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>[] = [];

    let hasOptions = false;

    const lastSegmentCoordinates = lastAddedSegment.properties.isReversedDigitization
      ? turf.getCoords(lastAddedSegment).flat(1).reverse()
      : turf.getCoords(lastAddedSegment).flat(1);

    const lastPointOfLastAddedSegment = lastAddedSegment.properties.isReversedDigitization
      ? turf.point(lastSegmentCoordinates[0])
      : turf.point(lastSegmentCoordinates[lastSegmentCoordinates.length - 1]);

    streetNetwork.features.forEach(street => {
      if (street.properties.Street_Id !== lastAddedSegment.properties.Street_Id) {
        const streetCoords = turf.getCoords(street).flat(1);

        // same digitization (goes well for oneway streets)
        const firstPointOfStreet = turf.point(streetCoords[0]);
        // last point of street
        const distance = turf.distance(lastPointOfLastAddedSegment, firstPointOfStreet, { units: 'meters' });

        const isIntersecting = distance < 0.1;

        // check if is oneWayAgainst digitization allowed
        const isAllowForwardDirection =
          ONE_WAY_SAME_DIGITIZATION.includes(street?.properties?.One_way) || !street?.properties?.One_way;

        if (isIntersecting && isAllowForwardDirection) {
          options.push(
            cutAmountFromBothLineEnds(
              offsetLine(
                {
                  ...street,
                  properties: {
                    ...street.properties,
                    clickable: true,
                  },
                },
                2,
              ),
              3,
            ),
          );
          hasOptions = true;
        } else {
          // reverse digitization and check if it's two way street
          const lastPointOfStreet = turf.point(streetCoords[streetCoords.length - 1]);
          const distance = turf.distance(lastPointOfLastAddedSegment, lastPointOfStreet, { units: 'meters' });
          const isIntersecting = distance < 0.1;
          // check if is oneWay against digitization allowed
          const isAllowReverseDirection =
            ONE_WAY_AGAINST_DIGITIZATION.includes(street?.properties?.One_way) || !street?.properties?.One_way;

          if (isIntersecting && isAllowReverseDirection) {
            hasOptions = true;
            options.push(
              cutAmountFromBothLineEnds(
                offsetLine(
                  {
                    ...street,
                    geometry: {
                      ...street.geometry,
                      coordinates: [turf.getCoords(street).flat(1).reverse()],
                    },
                    properties: {
                      ...street.properties,
                      isReversedDigitization: true,
                      clickable: true,
                    },
                  },
                  2,
                ),
                3,
              ),
            );
          }
        }
      }
    });

    // compute when to allow doing a U turn on the same street
    // by default at all intersections we should allow doing a U turn
    if (options.length > 1) {
      const isReversedDigitization = lastAddedSegment.properties.isReversedDigitization;

      const coordinates = turf.getCoords(lastAddedSegment).flat(1).reverse();

      //check if the last added segment is one way so we do not allow U turn
      const isAllowReverseDirection =
        ONE_WAY_AGAINST_DIGITIZATION.includes(lastAddedSegment?.properties?.One_way) ||
        !lastAddedSegment?.properties?.One_way;

      if (isAllowReverseDirection) {
        options.push(
          cutAmountFromBothLineEnds(
            offsetLine(
              {
                ...lastAddedSegment,
                geometry: {
                  ...lastAddedSegment.geometry,
                  coordinates: [coordinates],
                },
                properties: {
                  ...lastAddedSegment.properties,
                  isReversedDigitization: !isReversedDigitization,
                  isSameStreet: true,
                },
              },
              2,
            ),
            3,
          ),
        );
      }
    }

    if (!hasOptions) {
      // offer the same street again in the reverse digitization
      const isReversedDigitization = !lastAddedSegment.properties.isReversedDigitization;
      const coordinates = turf.getCoords(lastAddedSegment).flat(1).reverse();

      options.push(
        cutAmountFromBothLineEnds(
          offsetLine(
            {
              ...lastAddedSegment,
              geometry: {
                ...lastAddedSegment.geometry,
                coordinates: [coordinates],
              },
              properties: {
                ...lastAddedSegment.properties,
                isReversedDigitization,
              },
            },
            2,
          ),
          3,
        ),
      );
    }

    // filter out the options that are found in turn restrictions
    const filteredOptions = options.filter(option => {
      const thereIsATurnRestriction = streetTurnRestrictions?.features.some(turnRestriction => {
        const fromStreetId = turnRestriction.properties.F_Str_Id;
        const toStreetId = turnRestriction.properties.T_Str_Id;
        const currentStreetId = lastAddedSegment.properties.Street_Id;
        return (
          (fromStreetId === currentStreetId && toStreetId === option.properties.Street_Id) ||
          (toStreetId === currentStreetId && fromStreetId === option.properties.Street_Id)
        );
      });

      return !thereIsATurnRestriction;
    });

    return getFeatureCollection(filteredOptions);
  }, [hasReachedEndSegment, lastAddedSegment, streetNetwork, streetTurnRestrictions?.features]);

  /**
   * Adds a street to the path selection.
   * @param feature The street segment to add to the selection.
   */
  const addFeatureToSelection = useCallback((streetId: number, isReversedDigitization: boolean) => {
    const streetNetwork = store.getState().routes.travelPathBuildAndEdit.streetNetwork;

    if (!streetNetwork) return;

    let streetNetworkFeature = streetNetwork?.features.find(
      feature => feature?.properties?.Street_Id === streetId,
    ) as GeoJSON.Feature<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>;

    if (!streetNetworkFeature) return;

    if (isReversedDigitization) {
      streetNetworkFeature = {
        ...streetNetworkFeature,
        geometry: {
          ...streetNetworkFeature.geometry,
          coordinates: [turf.getCoords(streetNetworkFeature).flat(1).reverse()],
        },
        properties: {
          ...streetNetworkFeature.properties,
          isReversedDigitization: true,
        },
      };
    } else {
      streetNetworkFeature = {
        ...streetNetworkFeature,
        properties: {
          ...streetNetworkFeature.properties,
        },
      };
    }

    setSelectedSegments(prevSelected => getFeatureCollection([...prevSelected.features, streetNetworkFeature]));
  }, []);

  /**
   * Clears the selection of the street sequence
   */
  const clearSelection = useCallback(() => {
    if (selectedSegments.features.length === 0) return;
    setSelectedSegments(getFeatureCollection([]));
    setHasReachedEndSegment(false);
  }, [selectedSegments.features.length]);

  /**
   * Checks if the start segment is added to the selection.
   * If not, it adds it to the selection.
   */
  useEffect(() => {
    if (!startSegment || !endSegment) {
      clearSelection();
    } else if (!selectedSegments.features.length) {
      //find the street in the street network and add it to the selection
      const startSegmentStreet = streetNetwork?.features.find(
        feature => feature?.properties?.Street_Id === startSegment?.properties?.streetId,
      );

      if (!startSegmentStreet) return;

      const coordsOfStartSegmentTP = turf.getCoords(startSegment);
      const latsPointCoordsOfStartSegmentTP = coordsOfStartSegmentTP[coordsOfStartSegmentTP.length - 1];
      const coordsOfStartStreet = turf.getCoords(startSegmentStreet).flat(1);

      const firstPointCoordsOfStartStreet = coordsOfStartStreet[0];

      //  we compare the last point of the start segment with the first point of the start street to check if the digitization is reversed
      // this might not be the best way as the distance is not so exact
      const isReversedDigitization =
        turf.distance(latsPointCoordsOfStartSegmentTP, firstPointCoordsOfStartStreet, {
          units: 'meters',
        }) < 25;

      addFeatureToSelection(startSegment.properties.streetId, isReversedDigitization);
    }
  }, [
    startSegment,
    endSegment,
    selectedSegments.features.length,
    clearSelection,
    addFeatureToSelection,
    streetNetwork?.features,
  ]);

  /**
   * Checks if the last added segment is the end segment.
   * If it is, it asks the user if he wants to end the travel path.
   */
  useEffect(() => {
    const checkEndSegment = async () => {
      if (!endSegment || !lastAddedSegment) return;

      //is ending segment if street id is the same as the end segment and is same digitization
      let isEndingSegment = lastAddedSegment.properties.Street_Id === endSegment?.properties?.streetId;

      // to be the same digitization the start coordinates should be approximately the same
      if (isEndingSegment) {
        const coordsOfEndSegment = turf.getCoords(endSegment);
        const firstPointOfEndSegment = coordsOfEndSegment[0];
        const coordsOfLastAddedSegment = turf.getCoords(lastAddedSegment).flat(1);
        const firstPointOfLastAddedFeature = coordsOfLastAddedSegment[0];

        const distance = turf.distance(firstPointOfEndSegment, firstPointOfLastAddedFeature, {
          units: 'meters',
        });

        if (distance < 25) {
          if (
            await confirm(
              translate('routes.travelPath.alertMessages.youReachedTheEndSegment'),
              translate('routes.travelPath.alertMessages.doYouWantToContinue'),
              translate('routes.travelPath.continueEditing'),
              translate('routes.travelPath.finishSelection'),
            )
          )
            setHasReachedEndSegment(true);
        }
      }
    };

    checkEndSegment();
  }, [endSegment, lastAddedSegment]);

  /**
   * Removes the last added segment from the selection.
   */
  const undoLastSelection = useCallback(() => {
    if (selectedSegments.features.length === 1) return;

    setHasReachedEndSegment(false);

    setSelectedSegments(prevSelected => {
      const features = [...prevSelected.features];
      features.pop();
      return getFeatureCollection(features);
    });
  }, [selectedSegments.features.length]);

  /**
   * @returns The selected segments for display.
   */
  const selectedSegmentsForDisplay = useMemo(() => {
    if (!selectedSegments.features.length)
      return getFeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>([]);

    const features: GeoJSON.Feature<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>[] = [];

    // count how many times a street id is found in the selected segments
    const streetIdsCount: { [key: number]: number } = {};

    for (let i = 0; i < selectedSegments.features.length; i++) {
      const streetId = selectedSegments.features[i].properties.Street_Id;

      if (streetIdsCount[streetId] === undefined) streetIdsCount[streetId] = 1;
      else streetIdsCount[streetId]++;

      const feature = selectedSegments.features[i];

      //  a formula where the normal pass is 3 but for each extra pass we add 1
      const amountToOffset = 1 + (streetIdsCount[streetId] - 1) * 1;

      const amountToCut = 5 - (streetIdsCount[streetId] - 1) * 1 > 0 ? 5 - (streetIdsCount[streetId] - 1) * 1 : 0;

      const offSetFeature = cutAmountFromBothLineEnds(offsetLine(feature, amountToOffset), amountToCut);

      if (i === 0) {
        features.push({
          ...offSetFeature,
          properties: {
            ...offSetFeature.properties,
            sequence: i + (startSegment?.properties?.sequence || 0),
            isInterconnectingStreets: false,
            isStartingSegment: true,
            isEndingSegment: false,
          },
        });
        continue;
      }

      const previousFeatureOffset = features[features.length - 1];

      const coordsOfPreviousFeature = turf.getCoords(previousFeatureOffset).flat(1);
      const coordsOfCurrentFeature = turf.getCoords(offSetFeature).flat(1);

      const lastPointOfPreviousFeature = coordsOfPreviousFeature[coordsOfPreviousFeature.length - 1];
      const firstPointOfCurrentFeature = coordsOfCurrentFeature[0];

      let connectingLine = turf.multiLineString<StreetNetworkPropertiesTravelPath>([
        lastPointOfPreviousFeature,
        firstPointOfCurrentFeature,
      ]);

      connectingLine = {
        ...connectingLine,
        geometry: {
          ...connectingLine.geometry,
          coordinates: [turf.getCoords(connectingLine)],
        },
        properties: {
          ...connectingLine.properties,
          isInterconnectingStreets: true,
          sequence: 0,
          isStartingSegment: false,
          isEndingSegment: false,
        },
      };

      features.push(connectingLine);

      //is ending segment if street id is the same as the end segment and is same digitization
      let isEndingSegment =
        hasReachedEndSegment &&
        lastAddedSegment?.properties?.Street_Id === feature?.properties?.Street_Id &&
        lastAddedSegment?.properties?.isReversedDigitization === feature?.properties?.isReversedDigitization;

      features.push({
        ...offSetFeature,
        properties: {
          ...offSetFeature.properties,
          sequence: i + (startSegment?.properties?.sequence || 0),
          isInterconnectingStreets: false,
          isStartingSegment: false,
          isEndingSegment,
        },
      });
    }

    return getFeatureCollection<GeoJSON.MultiLineString, StreetNetworkPropertiesTravelPath>(features);
  }, [
    hasReachedEndSegment,
    lastAddedSegment?.properties?.Street_Id,
    lastAddedSegment?.properties?.isReversedDigitization,
    selectedSegments.features,
    startSegment?.properties?.sequence,
  ]);

  const getApplyEditsTransaction = useCallback(() => {
    let streetIds: number[] = [];
    let streetDirectionCodes: number[] = [];
    let startPathElementId: number = startSegment?.properties?.id || 0;
    let endPathElementId: number = endSegment?.properties?.id || 0;

    const removedFirstAndLastStreetSegments = [...selectedSegments.features];
    removedFirstAndLastStreetSegments.shift();
    removedFirstAndLastStreetSegments.pop();

    const travelPathDataThatGetsEdited = travelPathEditData?.features.filter(
      feature =>
        feature.properties.Sequence > startSegment?.properties?.sequence &&
        feature.properties.Sequence < endSegment?.properties?.sequence,
    );

    const serviceCodesMappedToStreetIds: { [key: number]: number[] } = {};

    // get the service codes from travelPathEditData for the streets between the start and end segment and map them to the street ids
    removedFirstAndLastStreetSegments.forEach((feature, i) => {
      const streetId = feature.properties.Street_Id;
      const currentFeatureCoords = turf.getCoords(feature).flat(1);
      const firstPointOfCurrentFeature = currentFeatureCoords[0];

      // filtering the paths for same digitization and same street id
      const travelPaths = travelPathDataThatGetsEdited?.filter(tpFeature => {
        if (tpFeature.properties.Street_Id !== streetId) return false;

        if (tpFeature.properties.ServCode === DOUBLE_SIDE_SERVICE) {
          // we do not care about direction we can service it from both sides
          return true;
        } else {
          // we have to check if the service path is in the same direction as the selected segment
          const pathCoords = turf.getCoords(tpFeature).flat(1);
          const firstCoordsOfPath = pathCoords[0];

          const isSameDirection =
            turf.distance(firstCoordsOfPath, firstPointOfCurrentFeature, { units: 'meters' }) < 25;
          return isSameDirection;
        }
      });

      if (travelPaths?.length) {
        const serviceCodes = travelPaths.map(feature => feature.properties.ServCode);
        serviceCodesMappedToStreetIds[streetId] = [...serviceCodes.sort((a, b) => b - a)];
      } else {
        serviceCodesMappedToStreetIds[streetId] = [0];
      }

      streetIds.push(feature.properties.Street_Id);

      streetDirectionCodes.push(feature.properties.isReversedDigitization ? -1 : 1);
    });

    const streetServiceCodes = streetIds.map(streetId => {
      // get the first service code from the array and remove it
      const serviceCodes = serviceCodesMappedToStreetIds[streetId];
      const serviceCode = serviceCodes.shift() || 0;
      serviceCodesMappedToStreetIds[streetId] = [...serviceCodes];
      return serviceCode;
    });

    return {
      startPathElementId,
      endPathElementId,
      streetIds,
      streetServiceCodes,
      streetDirectionCodes,
    };
  }, [
    endSegment?.properties?.id,
    endSegment?.properties?.sequence,
    selectedSegments.features,
    startSegment?.properties?.id,
    startSegment?.properties?.sequence,
    travelPathEditData?.features,
  ]);

  /**
   * @returns True if all the service segments between the start and end segment are selected.
   * If there are no service segments between the start and end segment, it returns true.
   * If there are service segments between the start and end segment and not all of them are selected, it returns false.
   */
  const getHasSelectedAllServiceSegments = useCallback(() => {
    const serviceStreetsBetweenStartAndEnd =
      !travelPathEditData || !startSegment || !endSegment
        ? []
        : travelPathEditData.features.filter(feature => {
            return (
              feature.properties.Sequence >= startSegment.properties.sequence &&
              feature.properties.Sequence <= endSegment.properties.sequence &&
              [DOUBLE_SIDE_SERVICE, SINGLE_SIDE_SERVICE].includes(feature.properties.ServCode)
            );
          });

    if (!serviceStreetsBetweenStartAndEnd.length) return true;

    const selectedStreetIds = new Set(selectedSegments.features.map(segment => segment.properties.Street_Id));

    const isAllServiceSegmentsSelected =
      !!startSegment &&
      !!endSegment &&
      ((!!serviceStreetsBetweenStartAndEnd.length &&
        serviceStreetsBetweenStartAndEnd.every(servicePath => {
          if (!selectedStreetIds.has(servicePath.properties.Street_Id)) return false;

          const selectedStreetsOnSameId = selectedSegments.features.filter(
            segment => segment.properties.Street_Id === servicePath.properties.Street_Id,
          );
          // if it did not pass on this street id then it means that the street is not selected at all

          const hasSelectedTheServicePath = selectedStreetsOnSameId.some(segment => {
            if (servicePath.properties.ServCode === DOUBLE_SIDE_SERVICE) {
              // we do not care about direction we can service it from both sides
              return true;
            } else {
              // we have to check if the service path is in the same direction as the selected segment
              const pathCoords = turf.getCoords(servicePath).flat(1);
              const firstCoordsOfPath = pathCoords[0];

              const selectedCoords = turf.getCoords(segment).flat(1);
              const firstCoordsOfSelected = selectedCoords[0];

              const isSameDirection = turf.distance(firstCoordsOfPath, firstCoordsOfSelected, { units: 'meters' }) < 25;
              return isSameDirection;
            }
          });

          return hasSelectedTheServicePath;
        })) ||
        !serviceStreetsBetweenStartAndEnd.length);

    return isAllServiceSegmentsSelected;
  }, [endSegment, selectedSegments.features, startSegment, travelPathEditData]);

  /**
   * @returns True if the undo button should be disabled.
   * If there is only one segment selected,or no segments selected, it returns true.
   **/
  const isUndoDisabled = useMemo(() => {
    return selectedSegments.features.length === 1 || selectedSegments.features.length === 0;
  }, [selectedSegments.features.length]);

  /**
   * @returns True if the apply edits button should be disabled.
   **/
  const isApplyEditsButtonDisabled = useMemo(() => {
    const startSelected = Boolean(startSegment);
    const endSelected = Boolean(endSegment);
    const endReached = Boolean(hasReachedEndSegment);

    return !(startSelected && endSelected && endReached);
  }, [endSegment, hasReachedEndSegment, startSegment]);

  /**
   * @returns True if the save button should be disabled.
   * if there are no edits applied to the travel path
   **/
  const isSaveButtonDisabled = useMemo(() => {
    return (
      !travelPathStatusDetails?.lockDetails?.isTravelPathDirty &&
      (!hasAppliedEditsToTravelPath || startSegment || endSegment || !isApplyEditsButtonDisabled)
    );
  }, [
    endSegment,
    hasAppliedEditsToTravelPath,
    isApplyEditsButtonDisabled,
    startSegment,
    travelPathStatusDetails?.lockDetails?.isTravelPathDirty,
  ]);

  return {
    clearSelection,
    undoLastSelection,
    addFeatureToSelection,
    getApplyEditsTransaction,
    getHasSelectedAllServiceSegments,
    selectedSegmentsForDisplay,
    optionsToChooseForPath,
    isUndoDisabled,
    isApplyEditsButtonDisabled,
    lastSelectedSegment: lastAddedSegment,
    isSaveButtonDisabled,
    hasReachedEndSegment,
  };
};

export default useTravelPathEditorService;
