import { Grid, Text } from 'src/core/components/styled';
import { Table } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import DisposalTripTableRow from './DisposalTripTableRow';

export const disposalsTableCellWidths = ['20%', '20%', '20%', '20%', '20'];

const getDisposalsTableCells = (isSnowPlowRoute?: boolean) => [
  {
    name: 'disposalSite',
    label: translate(
      isSnowPlowRoute ? 'facilities.facilitySubTypes.saltChemicalFillingStation' : 'common.disposalSite',
    ),
    width: disposalsTableCellWidths[0],
  },
  {
    name: 'vehicleDriver',
    label: translate('common.vehicleDriver'),
    width: disposalsTableCellWidths[1],
  },
  {
    name: 'startTime',
    label: translate('common.startTime'),
    width: disposalsTableCellWidths[2],
  },
  {
    name: 'endTime',
    label: translate('common.endTime'),
    width: disposalsTableCellWidths[3],
  },
  {
    name: 'duration',
    label: translate('common.duration'),
    width: disposalsTableCellWidths[4],
  },
];

type Props = {
  isSnowPlowRoute?: boolean;
  margin?: string;
};

export default function DisposalTripsSection({ isSnowPlowRoute, margin }: Props) {
  const { disposalTrips } = useSelector(state => state.routes.disposals);
  const { materialPickups } = useSelector(state => state.routes.materialTickets);

  return (
    <>
      {disposalTrips.length || materialPickups.length ? (
        <Grid margin={margin || 'no'}>
          <Table
            cells={getDisposalsTableCells(isSnowPlowRoute)}
            rows={isSnowPlowRoute ? materialPickups : disposalTrips}
            rowComponent={DisposalTripTableRow}
          />
        </Grid>
      ) : (
        <Text block align="center" padding="small no">
          {translate(isSnowPlowRoute ? 'routes.noMaterialTrips' : 'routes.noDisposalTrips')}
        </Text>
      )}
    </>
  );
}
