import { FC, useCallback, useMemo } from 'react';
import { getFormValues } from 'redux-form';

import { Dropdown, Input, Switch, TypedField } from 'src/core/components';
import { TableCell, TableRow } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { CityAlertSetting } from 'src/vendors/interfaces/CityAlertSettings';
import { CITY_ALERT_SETTINGS_FORM_NAME, CityAlertSettingsFormValues } from './CityAlertSettingsForm';
import {
  isInteger,
  isRequired,
  maxValueNumeric120,
  maxValueNumeric59,
  maxValueNumeric72,
  maxValueNumeric999,
  minValueNumeric1,
} from 'src/utils/services/validator';
import { DAYS_TYPE_ID, FOOT_ID, HOURS_TYPE_ID, MILE_ID, MINUTES_TYPE_ID, YARD_ID } from 'src/common/constants';

const CityAlertSettingsTableRow: FC<CityAlertSetting> = ({ cityAlertType }) => {
  const formValues = useSelector(getFormValues(CITY_ALERT_SETTINGS_FORM_NAME)) as CityAlertSettingsFormValues;
  const timeMeasurementTypes = useSelector(state => state.common.timeMeasurementTypes.timeMeasurementTypes);
  const unitOfMeasureTypes = useSelector(state => state.common.unitOfMeasureTypes.unitOfMeasureTypes);

  const thisRowValues = useMemo(() => {
    if (!cityAlertType) return null;
    return formValues?.cityAlertTypes[cityAlertType?.technicalName];
  }, [cityAlertType, formValues?.cityAlertTypes]);

  const fieldDisabled = useMemo(() => {
    return !thisRowValues?.isActive;
  }, [thisRowValues]);

  const hasNoEndDate = formValues?.cityAlertTypes[cityAlertType?.technicalName].hasNoEndDate;

  const timeMeasurementTypeOptions = useMemo(
    () =>
      timeMeasurementTypes
        .filter(uom => [HOURS_TYPE_ID, MINUTES_TYPE_ID, DAYS_TYPE_ID].includes(uom.id))
        .map(timeMeasurementType => ({
          label: timeMeasurementType.name,
          value: timeMeasurementType.id,
        })),
    [timeMeasurementTypes],
  );

  const unitOfMeasureTypeOptions = useMemo(
    () =>
      unitOfMeasureTypes
        .filter(uom => [MILE_ID, YARD_ID, FOOT_ID].includes(uom.id))
        .map(unitOfMeasureType => ({
          label: unitOfMeasureType.name,
          value: unitOfMeasureType.id,
        })),
    [unitOfMeasureTypes],
  );

  const expirationValidation = useCallback(() => {
    const expirationUnitTimeMeasurementTypeId = thisRowValues?.expirationUnitTimeMeasurementType?.id;
    if (fieldDisabled) return undefined;
    else if (expirationUnitTimeMeasurementTypeId === DAYS_TYPE_ID) {
      return [isRequired, minValueNumeric1, maxValueNumeric120];
    } else if (expirationUnitTimeMeasurementTypeId === HOURS_TYPE_ID) {
      return [isRequired, minValueNumeric1, maxValueNumeric72];
    } else if (expirationUnitTimeMeasurementTypeId === MINUTES_TYPE_ID) {
      return [isRequired, minValueNumeric1, maxValueNumeric59];
    }
  }, [fieldDisabled, thisRowValues?.expirationUnitTimeMeasurementType?.id]);

  return (
    <TableRow>
      <TableCell width="15%" align="left" padding="small">
        {cityAlertType?.name}
      </TableCell>
      <TableCell width="10%">
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.isActive`}
          component={Switch}
          props={{
            inline: true,
          }}
        />
      </TableCell>
      <TableCell width="10%" align="left">
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.audioAlert`}
          component={Switch}
          props={{
            disabled: fieldDisabled,
          }}
        />
      </TableCell>
      <TableCell width="22%" align="left">
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.expirationUnit`}
          component={Input}
          validate={expirationValidation()}
          props={{
            type: 'number',
            disabled: fieldDisabled || hasNoEndDate,
            margin: 'no small no no',
            width: '30%',
          }}
        />
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.expirationUnitTimeMeasurementType.id`}
          component={Dropdown}
          validate={[isRequired]}
          props={{
            options: timeMeasurementTypeOptions,
            disabled: fieldDisabled || hasNoEndDate,
            inline: true,
            margin: 'no no',
            width: '60%',
          }}
        />
      </TableCell>
      <TableCell width="12%" align="left">
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.hasNoEndDate`}
          component={Switch}
          props={{
            disabled: fieldDisabled,
          }}
        />
      </TableCell>
      <TableCell width="18%" align="left">
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.proximityValue`}
          component={Input}
          validate={[isRequired, minValueNumeric1, maxValueNumeric999, isInteger]}
          props={{
            type: 'number',
            disabled: fieldDisabled,
            margin: 'no small no no',
            width: '44%',
          }}
        />
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.proximityUnitMeasurementType.id`}
          component={Dropdown}
          validate={[isRequired]}
          props={{
            options: unitOfMeasureTypeOptions,
            disabled: fieldDisabled,
            menuPosition: 'fixed',
            inline: true,
            margin: 'no no',
            width: '56%',
          }}
        />
      </TableCell>
      <TableCell width="13%" align="left">
        <TypedField
          name={`cityAlertTypes.${cityAlertType.technicalName}.canTakePicture`}
          component={Switch}
          props={{
            disabled: fieldDisabled,
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default CityAlertSettingsTableRow;
