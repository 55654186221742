import { orderBy } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentVendorGusIdSelector } from '../../../vendors/services/currentVendorSelector';
import { Districts } from '../../../vendors/interfaces/DivisionAccess';
import { DocumentTitle } from '../../../common/components';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { loadHaulerProfileDivisions } from '../../ducks/divisions';
import { loadServiceAreas, loadServiceAreaOptions } from '../../ducks';
import { loadVendor } from 'src/vendors/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import ServiceAreasPage from './ServiceAreasPage';
import translate from '../../../core/services/translate';

export default function ServiceAreasPageResolver() {
  const dispatch = useDispatch();
  const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
  const vendorId = useSelector((state: AppState) =>
    currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  );
  const haulerId = useSelector((state: AppState) =>
    currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  );
  const loadDependencies = () => {
    return divisionIdFromCookie
      ? Promise.all([
          loadServiceAreas(haulerId, divisionIdFromCookie)(dispatch),
          loadServiceAreaOptions(haulerId, divisionIdFromCookie)(dispatch),
          loadHaulerProfileDivisions(vendorId)(dispatch),
          loadVendor(vendorId)(dispatch),
        ])
      : loadHaulerProfileDivisions(vendorId)(dispatch).then((response: Districts[]) => {
          const allDivisions = response;
          if (allDivisions.length > 0) {
            const assignedDivisionsOrderByName = orderBy(allDivisions, 'name', 'asc');
            const divisionId = assignedDivisionsOrderByName[0].value;
            Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, {
              expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
            });
            return Promise.all([
              loadServiceAreas(haulerId, divisionId)(dispatch),
              loadServiceAreaOptions(haulerId, divisionId)(dispatch),
              loadVendor(vendorId)(dispatch),
            ]);
          }
        });
  };

  return (
    <>
      <DocumentTitle>{translate('haulerProfile.services')}</DocumentTitle>
      <Resolver resolve={loadDependencies} successComponent={ServiceAreasPage} loadingComponent={PageLoading} />
    </>
  );
}
