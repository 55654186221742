import { http } from '../../core/services/http';
import transformLoadRouteImportStatuses from './transformLoadRouteImportStatuses';

const loadRouteImportStatuses = (
  routeImportId: number,
  haulerRouteImportStatusTypeId: number,
  page: number,
  limit: number,
) =>
  http
    .get('vendorRouteImport/details', { params: { routeImportId, haulerRouteImportStatusTypeId, page, limit } })
    .then(response => transformLoadRouteImportStatuses(response.data));

export default loadRouteImportStatuses;
