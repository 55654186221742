import React, { ChangeEvent } from 'react';

import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { WrappedFieldInputProps } from 'redux-form';

import { AppState } from '../../../../store';
import { EventTypes } from '../../../interfaces/EventTypes';
import { MultiSelect, TypedField } from '../../../../core/components';
import { MultiSelectProps } from '../../../../core/components/MultiSelect';
import translate from '../../../../core/services/translate';

interface EventTypeMultiSelectProps {
  eventTypes: EventTypes[];
  input: WrappedFieldInputProps;
  label?: string;
  multiSelectProps?: Partial<MultiSelectProps>;
  placeholder?: string;
  withLabel?: boolean;
  withPlaceholder?: boolean;
}

const EventTypeMultiSelect = (props: EventTypeMultiSelectProps) => {
  const { input, placeholder, withPlaceholder, label, withLabel, eventTypes, multiSelectProps } = props;

  const eventTypeOptions = eventTypes.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const formatText = (value: any[], checkAll: boolean) =>
    checkAll ? translate('routes.allEventTypes') : translate('routes.xEventTypesSelected', { selected: size(value) });

  const onChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    input.onChange(value);
  };

  return (
    <TypedField
      name={input.name}
      component={MultiSelect}
      onChange={onChange as any}
      props={{
        label: label || (withLabel ? translate('routes.eventName') : undefined),
        placeholder: placeholder || (withPlaceholder ? translate('routes.eventName') : undefined),
        options: eventTypeOptions,
        formatText,
        ...multiSelectProps,
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  eventTypes: state.routes.eventTypes.eventTypes,
});

export default connect(mapStateToProps)(EventTypeMultiSelect);
