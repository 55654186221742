import { mapValues, mapKeys, flatten } from 'lodash-es';
import humps from 'humps';

import cart from '../assets/img/vehicles/cart.png';
import cartInactive from '../assets/img/vehicles/cartInactive.png';
import cartStationary from '../assets/img/vehicles/cartStationary.png';
import delivery from '../assets/img/vehicles/delivery.png';
import deliveryInactive from '../assets/img/vehicles/deliveryInactive.png';
import deliveryStationary from '../assets/img/vehicles/deliveryStationary.png';
import exclamation from '../assets/img/vehicles/exclamation.png';
import frontLoad from '../assets/img/vehicles/frontLoad.png';
import frontLoadInactive from '../assets/img/vehicles/frontLoadInactive.png';
import frontLoadStationary from '../assets/img/vehicles/frontLoadStationary.png';
import pickupTruck from '../assets/img/vehicles/pickUpTruck.png';
import pickupTruckInactive from '../assets/img/vehicles/pickUpTruckInactive.png';
import pickupTruckStationary from '../assets/img/vehicles/pickUpTruckStationary.png';
import residential from '../assets/img/vehicles/residential.png';
import residentialInactive from '../assets/img/vehicles/residentialInactive.png';
import residentialStationary from '../assets/img/vehicles/residentialStationary.png';
import rollOff from '../assets/img/vehicles/rollOff.png';
import rollOffInactive from '../assets/img/vehicles/rollOffInactive.png';
import rollOffStationary from '../assets/img/vehicles/rollOffStationary.png';
import snowPlow from '../assets/img/vehicles/snowPlow.png';
import snowPlowInactive from '../assets/img/vehicles/snowPlowInactive.png';
import snowPlowStationary from '../assets/img/vehicles/snowPlowStationary.png';
import streetSweeper from '../assets/img/vehicles/streetSweeper.png';
import streetSweeperInactive from '../assets/img/vehicles/streetSweeperInactive.png';
import streetSweeperStationary from '../assets/img/vehicles/streetSweeperStationary.png';
import toter from '../assets/img/vehicles/toter.png';
import toterInactive from '../assets/img/vehicles/toterInactive.png';
import toterStationary from '../assets/img/vehicles/toterStationary.png';
import vehicle from '../assets/img/vehicles/vehicle.png';
import vehicleInactive from '../assets/img/vehicles/vehicleInactive.png';
import vehicleStationary from '../assets/img/vehicles/vehicleStationary.png';
import wasteAudit from '../assets/img/vehicles/wasteAudit.png';
import wasteAuditInactive from '../assets/img/vehicles/wasteAuditInactive.png';
import wasteAuditStationary from '../assets/img/vehicles/wasteAuditStationary.png';
import { VehiclePosition } from 'src/dashboard/interfaces/vehiclePositions';
import IconWithTypes from '../interfaces/IconWithTypes';
import { RouteVehiclePosition } from 'src/dashboard/interfaces/routesData';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';

export const VEHICLE_ICON_TYPES: IconWithTypes[] = [
  new IconWithTypes('cart', cart, ['cart']),
  new IconWithTypes('cartInactive', cartInactive, ['cartInactive']),
  new IconWithTypes('cartStationary', cartStationary, ['cartStationary']),
  new IconWithTypes('delivery', delivery, ['delivery']),
  new IconWithTypes('deliveryInactive', deliveryInactive, ['deliveryInactive']),
  new IconWithTypes('deliveryStationary', deliveryStationary, ['deliveryStationary']),
  new IconWithTypes('exclamation', exclamation, ['exclamation']),
  new IconWithTypes('frontLoad', frontLoad, ['frontLoad']),
  new IconWithTypes('frontLoadInactive', frontLoadInactive, ['frontLoadInactive']),
  new IconWithTypes('frontLoadStationary', frontLoadStationary, ['frontLoadStationary']),
  new IconWithTypes('pickupTruck', pickupTruck, ['pickupTruck']),
  new IconWithTypes('pickupTruckInactive', pickupTruckInactive, ['pickupTruckInactive']),
  new IconWithTypes('pickupTruckStationary', pickupTruckStationary, ['pickupTruckStationary']),
  new IconWithTypes('residential', residential, ['residential']),
  new IconWithTypes('residentialInactive', residentialInactive, ['residentialInactive']),
  new IconWithTypes('residentialStationary', residentialStationary, ['residentialStationary']),
  new IconWithTypes('rollOff', rollOff, ['rollOff']),
  new IconWithTypes('rollOffInactive', rollOffInactive, ['rollOffInactive']),
  new IconWithTypes('rollOffStationary', rollOffStationary, ['rollOffStationary']),
  new IconWithTypes('snowPlow', snowPlow, ['snowPlow']),
  new IconWithTypes('snowPlowInactive', snowPlowInactive, ['snowPlowInactive']),
  new IconWithTypes('snowPlowStationary', snowPlowStationary, ['snowPlowStationary']),
  new IconWithTypes('streetSweeper', streetSweeper, ['streetSweeper']),
  new IconWithTypes('streetSweeperInactive', streetSweeperInactive, ['streetSweeperInactive']),
  new IconWithTypes('streetSweeperStationary', streetSweeperStationary, ['streetSweeperStationary']),
  new IconWithTypes('toter', toter, ['toter']),
  new IconWithTypes('toterInactive', toterInactive, ['toterInactive']),
  new IconWithTypes('toterStationary', toterStationary, ['toterStationary']),
  new IconWithTypes('vehicle', vehicle, ['vehicle']),
  new IconWithTypes('vehicleInactive', vehicleInactive, ['vehicleInactive']),
  new IconWithTypes('vehicleStationary', vehicleStationary, ['vehicleStationary']),
  new IconWithTypes('wasteAudit', wasteAudit, ['wasteAudit']),
  new IconWithTypes('wasteAuditInactive', wasteAuditInactive, ['wasteAuditInactive']),
  new IconWithTypes('wasteAuditStationary', wasteAuditStationary, ['wasteAuditStationary']),
];

const VEHICLE_ICONS = mapValues(
  mapKeys(
    flatten(
      VEHICLE_ICON_TYPES.map(iconType =>
        iconType.types.map(vehicleType => ({ type: vehicleType, icon: iconType.iconUrl })),
      ),
    ),
    value => value.type,
  ),
  value => value.icon,
);

export default VEHICLE_ICONS;

export const getIconTypeForVehiclePosition = (position: VehiclePosition) => {
  const camelizedVehicleTypeName = humps.camelize(position.vehicleType.technicalName);
  const normalizedVehicleTypeName = !!VEHICLE_ICONS[camelizedVehicleTypeName] ? camelizedVehicleTypeName : 'vehicle';
  const iconId = `${normalizedVehicleTypeName}${
    (position.isStationary && 'Stationary') || (!position.isActive && 'Inactive') || ''
  }`;
  const iconType = VEHICLE_ICON_TYPES.find(type => type.id === iconId);

  return iconType;
};

export const getIconTypeForVehiclesListPosition = (position: VehiclePosition | RouteVehiclePosition) => {
  const typeName =
    'type' in position ? position.type : VEHICLE_TYPE_IDS[(position as VehiclePosition).vehicleTypeId].technicalName;

  const camelizedVehicleTypeName = humps.camelize(typeName);
  const normalizedVehicleTypeName = !!VEHICLE_ICONS[camelizedVehicleTypeName] ? camelizedVehicleTypeName : 'vehicle';
  const iconId = `${normalizedVehicleTypeName}${
    (position.isStationary && 'Stationary') || (!position.isActive && 'Inactive') || ''
  }`;
  const iconType = VEHICLE_ICON_TYPES.find(type => type.id === iconId);

  return iconType;
};
