import { Modal } from './../../../core/components';
import { RouteSummary } from './../../../routes/interfaces/RouteSummary';
import { SNOW_PLOW_ID } from './../../../fleet/constants';
import EventLog from '../pages/routes/routePageSections/EventLog';
import translate from './../../../core/services/translate';

type Props = {
  closeModal: () => void;
  routeSummary: RouteSummary;
};

export default function ZEventLogModal({ closeModal, routeSummary }: Props) {
  return (
    <Modal title={translate('routes.zEventLog')} size="xLarge" onClose={closeModal}>
      <EventLog isZ routeId={routeSummary.routeId} isSnowPlowRoute={routeSummary.vehicleTypeId === SNOW_PLOW_ID} />
    </Modal>
  );
}
