import { mapKeys } from 'lodash-es';

export const BAHAMAS = 'BS';
export const BELGIUM = 'BE';
export const CANADA = 'CA';
export const DOMINICAN_REPUBLIC = 'DO';
export const FINLAND = 'FI';
export const FRANCE = 'FR';
export const GERMANY = 'DE';
export const INDIA = 'IN';
export const ITALY = 'IT';
export const JAPAN = 'JP';
export const MEXICO = 'MX';
export const NETHERLANDS = 'NL';
export const NEW_ZEALAND = 'NZ';
export const NORWAY = 'NO';
export const SPAIN = 'ES';
export const SWEDEN = 'SE';
export const SWITZERLAND = 'SZ';
export const UNITED_KINGDOM = 'UK';
export const UNITED_STATES = 'US';

export const COUNTRIES = mapKeys(
  [
    { id: BAHAMAS, name: 'Bahamas' },
    { id: BELGIUM, name: 'Belgium' },
    { id: CANADA, name: 'Canada' },
    { id: DOMINICAN_REPUBLIC, name: 'Dominican Republic' },
    { id: FINLAND, name: 'Finland' },
    { id: FRANCE, name: 'France' },
    { id: GERMANY, name: 'Germany' },
    { id: INDIA, name: 'India' },
    { id: ITALY, name: 'Italy' },
    { id: JAPAN, name: 'Japan' },
    { id: MEXICO, name: 'Mexico' },
    { id: NETHERLANDS, name: 'Netherlands' },
    { id: NEW_ZEALAND, name: 'New Zealand' },
    { id: NORWAY, name: 'Norway' },
    { id: SPAIN, name: 'Spain' },
    { id: SWEDEN, name: 'Sweden' },
    { id: SWITZERLAND, name: 'Switzerland' },
    { id: UNITED_KINGDOM, name: 'United Kingdom' },
    { id: UNITED_STATES, name: 'United States' },
  ],
  'id',
);
