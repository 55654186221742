import { reduce, identity, set } from 'lodash-es';
import { createSelector } from 'reselect';

const getOpportunityFormInitialValues = (opportunityState: any) => ({
  opportunities: reduce(
    opportunityState,
    (
      initialValues,
      {
        caseId,
        offer,
        hasCasters,
        hasLockbar,
        endDestinations,
        requestedChanges,
        respondedNo,
        response,
        responseStatus,
        status,
        workflowStatus,
        notifyPossibleCustomerApproval,
      },
    ) =>
      set(initialValues, `settings${caseId}`, {
        offer,
        hasCasters,
        hasLockbar,
        response,
        endDestinations,
        requestedChanges,
        respondedNo,
        responseStatus,
        status,
        workflowStatus,
        notifyPossibleCustomerApproval,
      }),
    {},
  ),
});

const opportunityFormInitialValueSelector = createSelector(getOpportunityFormInitialValues, identity);

export default opportunityFormInitialValueSelector;
