import { filter, find } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BareButtonStyle } from 'src/core/components/styled/Button';
import {
  BinLocationMappingTypeId,
  COLLECTION_POINT,
  CURBSIDE_LOOKUP,
  LOCATION_ADDRESS,
  SET_BY_USER,
} from 'src/common/constants/binMappingTypes';
import { BULK_ID, RESIDENTIAL_ID, STREET_ID } from 'src/common/constants/serviceTypes';
import { CollectionWaypoint, ServiceContainer, ServicePickupLocation } from '../interfaces/Services';
import { createWarningNotification } from 'src/core/services/createNotification';
import { getCollectionPoints } from 'src/vendors/services/collectionPoints';
import { loading } from 'src/core/styles/loading';
import { PickupLocationEditorMapbox } from './PickupLocationEditorMapbox';
import { ToggleSwitch } from 'src/core/components';
import addressLocationPin from 'src/common/assets/img/common/addressLocationPin.svg';
import collectionPointPin from 'src/common/assets/img/common/collectionPointPin.svg';
import confirm from 'src/core/services/confirm';
import customLocationPin from 'src/common/assets/img/common/customLocationPin.svg';
import mapPin from 'src/common/assets/img/common/mapPin.svg';
import theme from 'src/core/styles/theme';
import translate from 'src/core/services/translate';

const MapContainer = styled.div`
  height: 100%;
  position: relative;

  *:focus {
    outline: none;
  }
`;

const ActionButton = styled.button`
  ${BareButtonStyle}
  background-color: white;
  padding: 10px;
  margin-left: 10px;

  &:disabled {
    color: gray;
  }
`;

const InfoSection = styled.div`
  padding: 10px;
  margin-top: 20px;
  max-width: 300px;
  background-color: white;
  text-align: left;
`;

const Buttons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  text-align: right;
`;

const Loader = styled.div`
  ${loading('25px')}
`;

const MapLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgb(16, 22, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MapLegend = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 20px;
  right: 10px;
  background: white;
  padding: 10px;
`;

const MapLegendItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const MapLegendPin = styled.img`
  margin-right: 10px;
  width: 15px;
`;

const commonMapLegendItems = [
  {
    pin: addressLocationPin,
    translationKey: 'customers.pickupLocations.location',
  },
  {
    pin: customLocationPin,
    translationKey: 'customers.pickupLocations.userGenerated',
  },
];

const specificMapLegendItems = [
  {
    pin: mapPin,
    translationKey: 'customers.pickupLocations.curbside',
  },
  {
    pin: collectionPointPin,
    translationKey: 'customers.pickupLocations.collectionPoint',
  },
];

interface Props {
  vendorId?: number;
  serviceId?: number;
  defaultLatLng: any;
  serviceContainers: ServiceContainer[];
  pickupLocations: ServicePickupLocation;
  serviceTypeId?: number;
  deletedCollectionPoints: number[];
  onEditPickupLocation: (isEditing: boolean) => void;
  onUpdatePickupLocation: (pickupLocation: ServicePickupLocation) => void;
  handleCollectionPointsToDelete: (collectionPointIds: number[]) => void;
  onAddressChanged: (
    lat: number,
    lng: number,
    index: number,
    binLocationMappingTypeId?: BinLocationMappingTypeId,
  ) => void;
  resetDeletedCollectionPoints: () => void;
  isServiceTypeChanged: boolean;
}

export const EditableServiceLocationMap: React.FunctionComponent<Props> = ({
  onEditPickupLocation,
  handleCollectionPointsToDelete,
  onUpdatePickupLocation,
  resetDeletedCollectionPoints,
  pickupLocations,
  serviceId,
  serviceTypeId,
  vendorId,
  deletedCollectionPoints,
  isServiceTypeChanged,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCollectionPoints, setIsEditingCollectionPoints] = useState(false);
  const [isLoadingCollectionPoints, setIsLoadingCollectionPoints] = useState(false);
  const [localDeletedCollectionPoints, setLocalDeletedCollectionPoints] = useState<number[]>([
    ...deletedCollectionPoints,
  ]);

  const [collectionPoints, setCollectionPoints] = useState<CollectionWaypoint[]>(
    pickupLocations.collectionWaypoint &&
      !deletedCollectionPoints.includes(pickupLocations?.collectionWaypoint?.id || -1)
      ? [pickupLocations.collectionWaypoint]
      : [],
  );

  const [pickupLocation, setPickupLocation] = useState(pickupLocations);

  useEffect(() => {
    setPickupLocation(pickupLocations);
  }, [pickupLocations]);

  const fetchCollectionPoints = () => {
    if (!vendorId) {
      return;
    }

    setIsLoadingCollectionPoints(true);
    getCollectionPoints(vendorId, pickupLocation.addressLatitude, pickupLocation.addressLongitude).then(
      (collectionPoints: CollectionWaypoint[]) => {
        setIsLoadingCollectionPoints(false);
        setCollectionPoints(filter(collectionPoints, cp => !localDeletedCollectionPoints.includes(cp.id || -1)));
      },
    );
  };

  const toggleCollectionPoints = () => {
    if (!isEditingCollectionPoints) {
      fetchCollectionPoints();
    } else if (
      pickupLocations.collectionWaypoint &&
      !localDeletedCollectionPoints.includes(pickupLocations.collectionWaypoint.id || -1)
    )
      setCollectionPoints([pickupLocations.collectionWaypoint]);
    else setCollectionPoints([]);

    setIsEditingCollectionPoints(!isEditingCollectionPoints);
  };

  const closeEditMode = () => {
    onEditPickupLocation(false);
    setIsEditing(false);
    setIsEditingCollectionPoints(false);
    if (
      pickupLocations.collectionWaypoint &&
      !localDeletedCollectionPoints.includes(pickupLocations.collectionWaypoint.id || -1)
    )
      setCollectionPoints([pickupLocations.collectionWaypoint]);
    else setCollectionPoints([]);
  };

  const deleteCollectionPoints = (collectionPointId: number) => {
    setCollectionPoints(
      filter(collectionPoints, (collectionPoint: CollectionWaypoint) => collectionPoint.id !== collectionPointId),
    );

    setLocalDeletedCollectionPoints([...localDeletedCollectionPoints, collectionPointId]);

    if (collectionPointId === pickupLocation?.collectionWaypoint?.id) {
      setPickupLocation({
        ...pickupLocations,
        previousCollectionWaypoint: undefined,
        binLocationMappingTypeId:
          pickupLocation.binLocationMappingTypeId === COLLECTION_POINT
            ? pickupLocation.curbsideLatitude
              ? CURBSIDE_LOOKUP
              : LOCATION_ADDRESS
            : LOCATION_ADDRESS,
        collectionWaypoint: pickupLocations.previousCollectionWaypoint || undefined,
      });
    }
  };

  const handleRemoveCollectionPoints = async (collectionPointId: number) => {
    const collectionPointToBeDeleted = find(collectionPoints, c => c.id === collectionPointId);
    const hasAssociatedServices =
      (collectionPointToBeDeleted &&
        collectionPointToBeDeleted.serviceContractIds &&
        collectionPointToBeDeleted.serviceContractIds.length > 0) ||
      (collectionPointToBeDeleted && collectionPointToBeDeleted.isAssociated);

    if (hasAssociatedServices) {
      if (await confirm(translate('customers.pickupLocations.confirmDeleteAssociatedCollectionPoint'))) {
        deleteCollectionPoints(collectionPointId);
      } else {
        return null;
      }
    } else {
      if (await confirm(translate('customers.pickupLocations.confirmDeleteCollectionPoint'))) {
        deleteCollectionPoints(collectionPointId);
      } else {
        return null;
      }
    }
  };

  const handleCancel = () => {
    setPickupLocation(pickupLocations);
    setLocalDeletedCollectionPoints([]);
    resetDeletedCollectionPoints();
    closeEditMode();
  };

  const handleOk = () => {
    createWarningNotification(translate('customers.pickupLocations.saveInformation'));
    onUpdatePickupLocation({ ...pickupLocation });
    handleCollectionPointsToDelete(localDeletedCollectionPoints);
    closeEditMode();
  };

  const mapLegendItems =
    serviceTypeId === RESIDENTIAL_ID || serviceTypeId === BULK_ID
      ? [...commonMapLegendItems, ...specificMapLegendItems]
      : commonMapLegendItems;

  const isPinOnMapActive =
    (!isEditingCollectionPoints && pickupLocation.binLocationMappingTypeId !== SET_BY_USER) ||
    (isEditingCollectionPoints &&
      (pickupLocation.binLocationMappingTypeId !== COLLECTION_POINT ||
        (pickupLocation.collectionWaypoint && !pickupLocation.collectionWaypoint.isFresh)));

  return (
    <MapContainer>
      {serviceTypeId !== STREET_ID && pickupLocation && !isEditing && (
        <Buttons>
          <ActionButton
            disabled={!serviceTypeId}
            title={!serviceTypeId ? translate('customers.pickupLocations.disabledMessage') : undefined}
            onClick={() => {
              onEditPickupLocation(!isEditing);
              setIsEditing(!isEditing);
            }}
          >
            {translate('customers.pickupLocations.editPickupLocation')}
          </ActionButton>
        </Buttons>
      )}
      {pickupLocation && isEditing && (
        <>
          <Buttons>
            <ActionButton onClick={handleOk}>OK</ActionButton>
            <ActionButton onClick={handleCancel}>{translate('common.cancel')}</ActionButton>
            {(serviceTypeId === RESIDENTIAL_ID || serviceTypeId === BULK_ID) && (
              <InfoSection>
                <ToggleSwitch
                  margin="no"
                  offColor={theme.grayDark}
                  offHandleColor={theme.grayLight}
                  handleChange={toggleCollectionPoints}
                  checked={isEditingCollectionPoints}
                  afterText={translate('customers.pickupLocations.editCollectionPoints')}
                />
              </InfoSection>
            )}
            {isPinOnMapActive && (
              <InfoSection>
                {translate(
                  `customers.pickupLocations.${
                    isEditingCollectionPoints ? 'editCollectionPointsInfo' : 'userGeneratedMarkerInfo'
                  }`,
                )}
              </InfoSection>
            )}
          </Buttons>
          <MapLegend>
            {mapLegendItems.map(i => (
              <MapLegendItem key={i.translationKey}>
                <MapLegendPin src={i.pin} />
                <span>{translate(i.translationKey)}</span>
              </MapLegendItem>
            ))}
          </MapLegend>
        </>
      )}
      {isLoadingCollectionPoints && (
        <MapLoading>
          <Loader />
        </MapLoading>
      )}
      <PickupLocationEditorMapbox
        collectionPoints={collectionPoints}
        handleRemoveCollectionPoints={handleRemoveCollectionPoints}
        isEditingCollectionPoints={isEditingCollectionPoints}
        isPinOnMapActive={isPinOnMapActive}
        isReadOnly={!isEditing}
        isServiceTypeChanged={isServiceTypeChanged}
        pickupLocations={pickupLocation}
        serviceId={serviceId}
        serviceTypeId={serviceTypeId}
        updatePickupLocations={setPickupLocation}
      />
    </MapContainer>
  );
};
