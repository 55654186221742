import React from 'react';

import moment from 'moment';

import { TableCell, TableRow, Text } from '../../../core/components/styled';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';

interface Props {
  averageSpeed: number;
  duration: string;
  minimumSpeed: number;
  timeEntered: string;
  timeExited: string;
  vehicleName: string;
}

const ProximitySearchTableRow: React.FC<Props> = ({
  averageSpeed,
  duration,
  minimumSpeed,
  timeEntered,
  timeExited,
  vehicleName,
}) => {
  const timeEnteredFormatted = moment(timeEntered).format(timeFormatWithoutSeconds);
  const timeExitedFormatted = timeExited ? moment(timeExited).format(timeFormatWithoutSeconds) : '-';
  const durationFormatted = timeExited ? duration : '-';

  return (
    <TableRow>
      <TableCell width="16%" vertical align="center">
        <Text weight="light" margin="no">
          {vehicleName}
        </Text>
      </TableCell>
      <TableCell width="17%" vertical align="center">
        <Text weight="light" margin="no">
          {timeEnteredFormatted}
        </Text>
      </TableCell>
      <TableCell width="16%" vertical align="center">
        <Text weight="light" margin="no">
          {timeExitedFormatted}
        </Text>
      </TableCell>
      <TableCell width="15%" vertical align="center">
        <Text weight="light" margin="no">
          {durationFormatted}
        </Text>
      </TableCell>
      <TableCell width="18%" vertical align="center">
        <Text weight="light" margin="no">
          {averageSpeed}
          {' mph'}
        </Text>
      </TableCell>
      <TableCell width="18%" vertical align="center">
        <Text weight="light" margin="no">
          {minimumSpeed}
          {' mph'}
        </Text>
      </TableCell>
    </TableRow>
  );
};

export default ProximitySearchTableRow;
