import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import {
  loadUnassignedJob as doLoadUnassignedJob,
  createUnassignedJob as doCreateUnassignedJob,
  updateUnassignedJob as doUpdateUnassignedJob,
} from '../../services/dispatchBoardUnassignedJobs';
import { UnassignedJob } from '../../interfaces/DispatchBoardRouteJob';

// Actions
const START_LOAD = 'dispatchBoard/unassignedJob/START_LOAD';
const FAIL_LOAD = 'dispatchBoard/unassignedJob/FAIL_LOAD';
const COMPLETE_LOAD = 'dispatchBoard/unassignedJob/COMPLETE_LOAD';
const START_SAVE = 'dispatchBoard/unassignedJob/START_SAVE';
const FAIL_SAVE = 'dispatchBoard/unassignedJob/FAIL_SAVE';
const COMPLETE_SAVE = 'dispatchBoard/unassignedJob/COMPLETE_SAVE';
const RESET = 'dispatchBoard/unassignedJob/RESET';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  unassignedJob: UnassignedJob;
}

type Dispatch = ThunkDispatch<State, {}, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  unassignedJob: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          unassignedJob: action.unassignedJob,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (unassignedJob: UnassignedJob) => ({
  type: COMPLETE_LOAD,
  unassignedJob,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadDispatchBoardUnassignedJob = (unassignedJobId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDispatchBoardUnassignedJobPromise = doLoadUnassignedJob(unassignedJobId);
  loadDispatchBoardUnassignedJobPromise
    .then(unassignedJob => dispatch(completeLoad(unassignedJob)))
    .catch(() => dispatch(failLoad()));
  return loadDispatchBoardUnassignedJobPromise;
};

export const saveDispatchBoardUnassignedJob = (jobId: number, jobData: UnassignedJob) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveDispatchBoardUnassignedJobPromise = jobId
    ? doUpdateUnassignedJob(jobId, jobData)
    : doCreateUnassignedJob(jobData);

  saveDispatchBoardUnassignedJobPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveDispatchBoardUnassignedJobPromise;
};

export const resetDispatchBoardUnassignedJob = () => ({
  type: RESET,
});
