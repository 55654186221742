import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { ReportingConfiguration } from '../interfaces/ReportingConfiguration';
import {
  loadReportingConfiguration as doLoadReportingConfiguration,
  saveReportingConfiguration as doSaveReportingConfiguration,
} from '../services/reportingConfiguration';

// Actions
const START_LOAD = 'vendors/reportingConfiguraton/START_LOAD';
const COMPLETE_LOAD = 'vendors/reportingConfiguraton/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/reportingConfiguraton/FAIL_LOAD';
const START_SAVE = 'vendors/reportingConfiguraton/START_SAVE';
const COMPLETE_SAVE = 'vendors/reportingConfiguraton/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/reportingConfiguraton/FAIL_SAVE';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  reportingConfiguration: [] as ReportingConfiguration[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          reportingConfiguration: action.reportingConfiguration,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    case START_SAVE:
      return update(state, {
        $merge: { isSaving: true },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: { isSaving: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (reportingConfiguration: ReportingConfiguration[]) => ({
  type: COMPLETE_LOAD,
  reportingConfiguration,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadReportingConfiguration = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadReportingConfigurationPromise = doLoadReportingConfiguration();
  loadReportingConfigurationPromise
    .then((reportingConfiguration: ReportingConfiguration[]) => {
      dispatch(completeLoad(reportingConfiguration));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadReportingConfigurationPromise;
};

export const saveReportingConfiguration =
  (reportingConfiguration: ReportingConfiguration[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveReportingConfigurationPromise = doSaveReportingConfiguration(reportingConfiguration);
    saveReportingConfigurationPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveReportingConfigurationPromise;
  };
