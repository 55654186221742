import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';

import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { TypedResolver } from '../../../core/components';
import { loadVehicleStrobeImageFrequencyTypes, loadVehicleStrobeImagesSettings } from 'src/vendors/ducks';
import VehicleStrobeImagesModal from './VehicleStrobeImagesModal';

const VehicleStrobeImagesModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadVehicleStrobeImageFrequencyTypes()(dispatch),
      loadVehicleStrobeImagesSettings(vendorId)(dispatch),
    ];

    return Promise.all(dependencies);
  }, [vendorId, dispatch]);

  return (
    <TypedResolver
      successComponent={VehicleStrobeImagesModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default VehicleStrobeImagesModalResolver;
