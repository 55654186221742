import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router';
import Cookie from 'js-cookie';

import { DatePicker } from '../../../core/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { INSIGHTS_COOKIE_DATE_KEY } from '../../constants';
import { RunningVehicleCountDropdown } from '..';
import { TODAY, TODAY_FORMATTED } from '../../../core/constants/weekdays';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { isDateValidValidator, isRequired } from 'src/utils/services/validator';
import moment from 'moment';

interface ComponentProps extends RouteComponentProps {
  onDateChange(event: any, date: Date | string): void;
  onVehicleTypeChange(event: any, vehicleTypeId: number): void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  initialValues: any;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class InsightsForm extends PureComponent<Props> {
  render() {
    const { onDateChange, onVehicleTypeChange } = this.props;

    return (
      <form noValidate>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="3/12">
              <Field
                name="date"
                component={DatePicker as any}
                tabletAlignLeft
                onChange={onDateChange}
                margin="no"
                disabledDays={[{ after: TODAY }]}
                props={{ id: 'insights-form-date', disableFormSubmitOnDateChange: true }}
                validate={[isRequired, isDateValidValidator]}
              />
            </GridColumn>

            <GridColumn size="3/12">
              <Field
                name="vehicleTypeId"
                component={RunningVehicleCountDropdown}
                placeholder={translate('vehicles.allVehicleTypes')}
                dropdownProps={{ margin: 'no', id: 'insights-form-vehicle-type' }}
                onChange={onVehicleTypeChange}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { date, vehicleTypeId } = getQueryParams(ownProps.location.search);
  const insightsDate = Cookie.get(INSIGHTS_COOKIE_DATE_KEY);

  return {
    initialValues: {
      date: moment(date || insightsDate || TODAY_FORMATTED).format('MM/DD/YYYY'),
      vehicleTypeId: vehicleTypeId && Number(vehicleTypeId),
    },
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'insights',
      enableReinitialize: true,
    })(InsightsForm),
  ),
);
