import React from 'react';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { find } from 'lodash-es';

import { Table } from '../../../core/components';
import { Button, ButtonSet, Grid, GridColumn, Message, PanelSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { ROUTE_SEQUENCE_SETTINGS_FORM_NAME } from '../../constants/routeSettingsForm';
import { RouteSequenceFormValues } from '../../interfaces/RouteSequenceSettings';
import RouteSequenceFormTableRow from './RouteSequenceFormTableRow';

const routeSequenceFormTableCells = [
  { name: 'vehicleType', label: translate('vehicles.vehicleType'), width: '70%' },
  { name: 'isActive', label: translate('common.active'), width: '20%' },
];

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteSequenceFormValues, PropsWithoutReduxForm>;

const RouteSequenceForm: React.FC<Props> = ({ initialValues, form, change, handleSubmit }) => {
  const formSelector = formValueSelector(form);
  const { settings = [] } = initialValues;
  const [hasError, setHasError] = React.useState([]);

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection vertical>
        {!!settings.length && (
          <>
            <Table
              cells={routeSequenceFormTableCells}
              rowComponent={RouteSequenceFormTableRow}
              rows={settings}
              rowProps={{
                formSelector,
                change,
                hasError,
                setHasError,
              }}
            />

            <Grid centered>
              <GridColumn size="5/12">
                <ButtonSet margin="medium no">
                  <Button type="submit" color="primary" disabled={find(hasError, el => el && true)}>
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            </Grid>
          </>
        )}

        {!settings.length && (
          <Message padding="sMedium sMedium medium sMedium">{translate('vehicles.noVehiclesConfigured')}</Message>
        )}
      </PanelSection>
    </form>
  );
};

export default reduxForm<RouteSequenceFormValues, PropsWithoutReduxForm>({
  form: ROUTE_SEQUENCE_SETTINGS_FORM_NAME,
  onSubmitFail: focusFirstInvalidField,
})(RouteSequenceForm);
