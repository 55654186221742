import { http } from '../../core/services/http';
import { FOURTEEN_DAYS_AFTER_TODAY, TODAY_FORMATTED } from '../../core/constants/weekdays';
import { transformLoadUpcomingServices } from './transformLoadUpcomingServices';

export const loadUpcomingServices = (
  customerId: number,
  locationId: number,
  serviceContractIds: string | undefined,
  pickupStatusTypeIds: string | undefined,
  jobPriorityTypeIds: string | undefined,
  startDate: Date | string = TODAY_FORMATTED,
  endDate: Date | string = FOURTEEN_DAYS_AFTER_TODAY,
) =>
  http
    .get(`customers/${customerId}/upcomingServices`, {
      params: {
        locationId,
        serviceContractIds,
        pickupStatusTypeIds,
        jobPriorityTypeIds,
        startDate,
        endDate,
      },
    })
    .then(response => transformLoadUpcomingServices(response.data));

export const deleteUpcomingService = (routeLocationId: number) =>
  http.post(`routeLocations/${routeLocationId}/delete`, {
    params: {},
  });
