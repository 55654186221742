import { http } from '../../core/services/http';
import { transformRubiconServices, transformRubiconCustomerServiceInfo } from './transformRubiconServicesOptions';
import { ACTIVE, ON_HOLD } from '../../customers/constants/serviceStatuses';
import { LIMIT_PER_PAGE, PAGE } from 'src/core/constants';

export const saveAddNewWorkOrder = (vendorId: any, divisionId: any, payload: any) => {
  const formData = new FormData();

  formData.append('Customer', payload.Customer);
  formData.append('Location', payload.Location);
  formData.append('ServiceId', payload.ServiceId);
  formData.append('DatePerformed', payload.DatePerformed);
  formData.append('ServiceRequestType', payload.ServiceRequestType);
  formData.append('Amount', payload.Amount);
  formData.append('Notes', payload.Notes);
  formData.append('Files', payload.Files ? payload.Files[0] : null);

  return http
    .post(`/gus/vendors/${vendorId}/divisions/${divisionId}/internal-requests/work-orders`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data;
    });
};

export const submitChangeRequest = (vendorId: any, divisionId: any, payload: any) => {
  const formData = new FormData();
  formData.append('ServiceNumber', payload.ServiceNumber || null);
  formData.append('EquipmentSize', payload.EquipmentSize || null);
  formData.append('EquipmentType', payload.EquipmentType || null);
  formData.append('MaterialType', payload.MaterialType || null);
  formData.append('Frequency', payload.Frequency || null);
  formData.append('Container', payload.Container || null);
  formData.append('Rates', JSON.stringify(payload?.Rates || []));
  formData.append('DayOfService', payload.DayOfService || null);
  formData.append('Notes', payload.Notes || '');
  formData.append('Files', payload.Files ? payload.Files[0] : null);

  return http
    .post(`/gus/vendors/${vendorId}/divisions/${divisionId}/internal-requests/data-correction`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data;
    });
};

export const loadRubiconServices = (
  vendorId: number,
  userId: string,
  customerName?: string,
  divisionId?: string,
  serviceId?: string,
  equipmentType?: string,
  materialType?: string,
  servciceStatusIds: string = `${ACTIVE},${ON_HOLD}`,
  page: number = PAGE,
  limit: number = LIMIT_PER_PAGE,
) => {
  return http
    .post(`gus/rubiconservices/vendor/${vendorId}/user/${userId}`, {
      address: customerName,
      division: divisionId,
      serviceNumber: serviceId,
      equipmentType,
      materialType,
      serviceStatus: servciceStatusIds,
      pageNumber: page,
      pageSize: limit,
    })
    .then(response => {
      let coordinates = transformRubiconServices(response.data.services);
      let rubiconCustomerDropdownOptions = transformRubiconCustomerServiceInfo(response.data.services);
      return { ...response.data, coordinates, rubiconCustomerDropdownOptions };
    });
};
