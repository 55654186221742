import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import {
  loadLocationAndCityAlertsForMap as doLoadLocationAndCityAlertsForMap,
  loadLocationAndCityAlertsForList as doLoadLocationAndCityAlertsForList,
  saveLocationAndCityAlert as doSaveLocationAndCityAlert,
  loadLocationAndCityAlert as doLoadLocationAndCityAlert,
} from '../services/locationAndCityAlerts';
import {
  LocationAndCityAlert,
  LocationAndCityAlertsForList,
  LocationAndCityAlertsForMap,
  LocationAndCityAlertsFormValues,
  LocationAndCityAlertsParams,
} from '../interfaces/LocationAndCityAlerts';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';

// Actions
const START_LOAD = 'fleet/locationAndCityAlerts/START_LOAD';
const COMPLETE_LOAD = 'fleet/locationAndCityAlerts/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/locationAndCityAlerts/FAIL_LOAD';
const START_LOAD_FOR_LIST = 'fleet/locationAndCityAlerts/START_LOAD_FOR_LIST';
const COMPLETE_LOAD_FOR_LIST = 'fleet/locationAndCityAlerts/COMPLETE_LOAD_FOR_LIST';
const FAIL_LOAD_FOR_LIST = 'fleet/locationAndCityAlerts/FAIL_LOAD_FOR_LIST';
const START_SAVE = 'fleet/locationAndCityAlerts/START_SAVE';
const COMPLETE_SAVE = 'fleet/locationAndCityAlerts/COMPLETE_SAVE';
const FAIL_SAVE = 'fleet/locationAndCityAlerts/FAIL_SAVE';
const START_LOAD_ALERT = 'fleet/locationAndCityAlerts/START_LOAD_ALERT';
const COMPLETE_LOAD_ALERT = 'fleet/locationAndCityAlerts/COMPLETE_LOAD_ALERT';
const FAIL_LOAD_ALERT = 'fleet/locationAndCityAlerts/FAIL_FAIL_LOAD_ALERTSAVE';
const SET_VIEWPORT = 'fleet/locationAndCityAlerts/SET_VIEWPORT';
const SET_SELECTED_LOCATION_ALERTS = 'fleet/locationAndCityAlerts/SET_SELECTED_LOCATION_ALERTS';
const RESET_SELECTED_LOCATION_ALERTS = 'fleet/locationAndCityAlerts/RESET_SELECTED_LOCATION_ALERTS';

// Initial state =
const initialState = {
  isLoadingForList: false,
  isLoadingForMap: false,
  isLoadingAlert: false,
  isSaving: false,
  locationAndCityAlert: {} as LocationAndCityAlert,
  locationAndCityAlertsForList: [] as LocationAndCityAlertsForList[],
  locationAndCityAlertsForMap: [] as LocationAndCityAlertsForMap[],
  viewport: {} as MapGLViewport,
  selectedLocationAlertsIds: [] as number[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoadingForMap: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoadingForMap: false,
          locationAndCityAlertsForMap: action.locationAndCityAlertsForMap,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoadingForMap: false,
          locationAndCityAlertsForMap: [],
        },
      });

    case START_LOAD_FOR_LIST:
      return update(state, {
        $merge: {
          isLoadingForList: true,
        },
      });

    case COMPLETE_LOAD_FOR_LIST:
      return update(state, {
        $merge: {
          isLoadingForList: false,
          locationAndCityAlertsForList: action.locationAndCityAlertsForList,
        },
      });

    case FAIL_LOAD_FOR_LIST:
      return update(state, {
        $merge: {
          isLoadingForList: false,
          locationAndCityAlertsForList: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_LOAD_ALERT:
      return update(state, {
        $merge: {
          isLoadingAlert: true,
        },
      });

    case COMPLETE_LOAD_ALERT:
      return update(state, {
        $merge: {
          isLoadingAlert: false,
          locationAndCityAlert: action.locationAndCityAlert,
        },
      });

    case FAIL_LOAD_ALERT:
      return update(state, {
        $merge: {
          isLoadingAlert: false,
          locationAndCityAlert: {} as LocationAndCityAlert,
        },
      });

    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    case SET_SELECTED_LOCATION_ALERTS:
      return update(state, {
        $merge: {
          selectedLocationAlertsIds: action.selectedLocationAlertsIds,
        },
      });

    case RESET_SELECTED_LOCATION_ALERTS:
      return update(state, {
        $merge: {
          selectedLocationAlertsIds: [],
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (locationAndCityAlertsForMap: LocationAndCityAlertsForMap[]) => ({
  type: COMPLETE_LOAD,
  locationAndCityAlertsForMap,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadAlertsForList = () => ({
  type: START_LOAD_FOR_LIST,
});

const completeLoadAlertsForList = (locationAndCityAlertsForList: LocationAndCityAlertsForList[]) => ({
  type: COMPLETE_LOAD_FOR_LIST,
  locationAndCityAlertsForList,
});

const failLoadAlertsForList = () => ({
  type: FAIL_LOAD_FOR_LIST,
});

const startSaveLocationAndCityAlert = () => ({
  type: START_SAVE,
});

const completeSaveLocationAndCityAlert = () => ({
  type: COMPLETE_SAVE,
});

const failSaveLocationAndCityAlert = () => ({
  type: FAIL_SAVE,
});

const startLoadLocationAndCityAlert = () => ({
  type: START_LOAD_ALERT,
});

const completeLoadLocationAndCityAlert = (locationAndCityAlert: LocationAndCityAlert) => ({
  type: COMPLETE_LOAD_ALERT,
  locationAndCityAlert,
});

const failLoadLocationAndCityAlert = () => ({
  type: FAIL_LOAD_ALERT,
});

export const loadLocationAndCityAlertsForMap = (searchParams: LocationAndCityAlertsParams) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  return doLoadLocationAndCityAlertsForMap(searchParams)
    .then(locationAndCityAlertsForMap => dispatch(completeLoad(locationAndCityAlertsForMap)))
    .catch(() => dispatch(failLoad()));
};

export const loadLocationAndCityAlertsForList = (searchParams: LocationAndCityAlertsParams) => (dispatch: Dispatch) => {
  dispatch(startLoadAlertsForList());
  return doLoadLocationAndCityAlertsForList(searchParams)
    .then(locationAndCityAlertsForList => dispatch(completeLoadAlertsForList(locationAndCityAlertsForList)))
    .catch(() => dispatch(failLoadAlertsForList()));
};

export const saveLocationAndCityAlert = (payload: LocationAndCityAlertsFormValues) => (dispatch: Dispatch) => {
  dispatch(startSaveLocationAndCityAlert());
  return doSaveLocationAndCityAlert(payload)
    .then(() => dispatch(completeSaveLocationAndCityAlert()))
    .catch(() => dispatch(failSaveLocationAndCityAlert()));
};

export const loadLocationAndCityAlert = (alertId: number, alertTypeId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadLocationAndCityAlert());
  return doLoadLocationAndCityAlert(alertId, alertTypeId)
    .then(locationAndCityAlert => dispatch(completeLoadLocationAndCityAlert(locationAndCityAlert)))
    .catch(() => dispatch(failLoadLocationAndCityAlert()));
};

export const setLocationAndCityAlertsMapViewport = (viewport: MapGLViewport) => {
  return {
    type: SET_VIEWPORT,
    viewport,
  };
};

export const setSelectedLocationAlerts = (selectedLocationAlertsIds: number[]) => {
  return {
    type: SET_SELECTED_LOCATION_ALERTS,
    selectedLocationAlertsIds,
  };
};

export const resetSelectedLocationAlerts = () => {
  return {
    type: RESET_SELECTED_LOCATION_ALERTS,
  };
};
