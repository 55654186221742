import React from 'react';

import { InjectedFormProps, reduxForm } from 'redux-form';
import { map } from 'lodash';

import { Button } from 'src/core/components/styled/Button';
import { ButtonSet } from 'src/core/components/styled';
import { Dropdown, Input, TypedField } from 'src/core/components';
import { Grid, GridColumn } from 'src/core/components/styled/Grid';
import { maxLength30, isRequired } from 'src/utils/services/validator';
import { X_DEVICE_OWNERS } from 'src/settings/constants/xDeviceOwners';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';
import { useSelector } from 'react-redux';
import { isAdminSelector, isSuperAdminSelector } from 'src/account/ducks';
import { AppState } from 'src/store';

interface PropsWithoutReduxForm {
  closeModal(): void;
  deviceId?: string;
  deviceOwnerId?: number;
  phoneName?: string;
  serialNumber?: string;
}
interface FormValues {
  serialNumber: string;
  deviceOwnerTypeId: number;
  phoneName: string;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const EditDeviceDetailsForm: React.FC<Props> = ({ handleSubmit, serialNumber, deviceOwnerId }) => {
  const deviceTypeOptions = map(X_DEVICE_OWNERS, xDevType => ({
    label: xDevType.name,
    value: xDevType.id,
  }));

  const isRubiconAdmin = useSelector((state: AppState) => {
    const loginState = state.account.login;
    return isAdminSelector(loginState) || isSuperAdminSelector(loginState);
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid multiLine margin="small no no no">
        <GridColumn size="6/12">
          <TypedField
            component={Dropdown}
            name="deviceOwnerTypeId"
            props={{
              options: deviceTypeOptions,
              label: translate('settings.xDeviceOwner'),
              disabled: !isRubiconAdmin,
            }}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <TypedField
            validate={[isRequired, maxLength30]}
            component={Input}
            name="phoneName"
            props={{
              label: translate('settings.deviceName'),
            }}
          />
        </GridColumn>
        <GridColumn size="12/12">
          <TypedField
            validate={[isRequired, maxLength30]}
            component={Input}
            name="serialNumber"
            props={{
              label: translate('settings.deviceSerialNumber'),
            }}
          />
        </GridColumn>
      </Grid>
      <ButtonSet margin="medium no medium no">
        <Button type="submit" color="primary">
          {translate('common.save')}
        </Button>
      </ButtonSet>
    </form>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: 'EditDeviceDetailsForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(EditDeviceDetailsForm);
