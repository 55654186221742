import { CancelToken } from 'axios';
import * as turf from '@turf/turf';

import { http, httpInsightsReporting } from 'src/core/services/http';
import { ApplyEditsToTravelPathPayload, TravelPathTracerPayload } from '../interfaces/TravelPath';

export const loadTravelPathStatusDetails = (routeId: number) =>
  http.get(`route/${routeId}/travelPath/onDemandDetails`, { params: { routeId } }).then(response => response.data);

export const loadTravelPathStatusDetailsForTemplate = (routeTemplateId: number) =>
  http
    .get(`routeTemplate/${routeTemplateId}/travelPath/onDemandDetails`, { params: { routeTemplateId } })
    .then(response => response.data);

export const triggerTravelPath = (routeId: number) => http.put(`/route/${routeId}/travelPath`, { routeId });

export const triggerTravelPathForTemplate = (routeTemplateId: number) =>
  http.put(`/routeTemplate/${routeTemplateId}/travelPath`, { routeTemplateId });

export const getTravelPathGeoJsonFile = (routeId: number) =>
  http.get(`/route/downloadGeoJsonFile`, { params: { routeId } }).then(response => response.data);

export const getTravelPathGeoJsonFileForTemplate = (routeTemplateId: number) =>
  http.get(`/routeTemplate/downloadGeoJsonFile`, { params: { routeTemplateId } }).then(response => response.data);

export const loadTravelPathGeoLayers = (
  vendorId: number,
  dataLayerType: string,
  bbox?: turf.BBox,
  cancelToken?: CancelToken,
) =>
  http
    .get('/travelPath/dataLayer', {
      params: {
        dataLayerType,
        bbox: bbox ? `${bbox.join(',')},EPSG:4326` : undefined,
        vendorId,
      },
      cancelToken,
    })
    .then(response => response.data);

export const initializeTravelPathBuildOrEdit = (
  vendorId: number,
  isBuilder: boolean,
  routeId?: number,
  routeTemplateId?: number,
) =>
  http
    .post(`/travelPath/${isBuilder ? 'builder' : 'edit'}/initialize`, {
      vendorId,
      routeId,
      routeTemplateId,
    })
    .then(response => response.data);

export const applyEditsToTravelPath = (payload: ApplyEditsToTravelPathPayload) =>
  http.post('/travelPath/edit/apply', payload).then(response => response.data);

export const publishEditsToTravelPath = (
  vendorId: number,
  isBuilder: boolean,
  routeId?: number,
  routeTemplateId?: number,
) =>
  http
    .post(`/travelPath/${isBuilder ? 'builder' : 'edit'}/save`, { vendorId, routeId, routeTemplateId })
    .then(response => response.data);

export const finishTravelPathBuildOrEdit = (
  vendorId: number,
  isBuilder: boolean,
  routeId?: number,
  routeTemplateId?: number,
) =>
  http
    .post(`/travelPath/${isBuilder ? 'builder' : 'edit'}/finish`, { vendorId, routeId, routeTemplateId })
    .then(response => response.data);

export const loadTravelPathBuilderFilters = (vendorId: number, routeTemplateId: number) =>
  http.get(`/travelPath/builder/routes`, { params: { vendorId, routeTemplateId } }).then(response => response.data);

export const loadTravelPathBuilderRouteVehiclePositions = (
  routeId: number,
  date: string | Date,
  vehicleId: number,
  cancelToken: any,
) =>
  httpInsightsReporting
    .get(`/dashboard/routes/${routeId}/vehiclePositions`, { params: { date, vehicleIds: `${vehicleId}` }, cancelToken })
    .then(response => response.data);

export const loadTravelPathBuilderRouteVehiclesBreadCrumbs = (
  routeId: number,
  date: string | Date,
  vehicleId: number,
  cancelToken: any,
) =>
  httpInsightsReporting
    .get(`/dashboard/routes/${routeId}/breadcrumbs`, { params: { date, vehicleIds: `${vehicleId}` }, cancelToken })
    .then(response => response.data);

export const loadTravelPathBuilderRouteVehicleInformation = (vehicleId: number, date: Date | string) =>
  httpInsightsReporting
    .get(`/dashboard/vehicles/${vehicleId}/info`, {
      params: { date },
    })
    .then(response => response.data);

export const extendTravelPathLock = (
  vendorId: number,
  isBuilder: boolean,
  hours: number,
  routeId?: number,
  routeTemplateId?: number,
) =>
  http
    .post(`/travelPath/${isBuilder ? 'builder' : 'edit'}/extend`, { vendorId, hours, routeId, routeTemplateId })
    .then(response => response.data);

export const travelPathBuilderDoTracing = (payload: TravelPathTracerPayload) =>
  http.post('/travelPath/builder/trace', payload).then(response => response.data);
