import { http } from '../../core/services/http';
import { transformLoadRouteImportDetails, transformSaveRouteImportDetails } from './transformRouteImportDetails';

export const loadRouteImportDetails = (routeImportDetailId: number) =>
  http
    .get(`vendorRouteImport/details/${routeImportDetailId}`)
    .then(response => transformLoadRouteImportDetails(response.data));

export const saveRouteImportDetails = (routeImportDetailId: number, routeImportDetails: any) =>
  http.put(`vendorRouteImport/details/${routeImportDetailId}`, transformSaveRouteImportDetails(routeImportDetails));
