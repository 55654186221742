import { FC } from 'react';
import { SnowSweeperFilterItemContainer } from '../../styled/DashboardFilterMapbox';
import { Checkbox, TypedField } from 'src/core/components';

interface Props {
  name: string;
  label: string;
  rightContent?: JSX.Element;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue?: boolean,
    prevValue?: boolean,
    name?: string,
  ) => void;
}

const SnowSweeperFilterItem: FC<Props> = ({ name, label, rightContent, onChange }) => {
  return (
    <SnowSweeperFilterItemContainer>
      <TypedField
        name={name}
        component={Checkbox}
        onChange={onChange}
        props={{
          size: 'small',
          label,
          margin: 'xSmall no',
          padding: 'no',
        }}
      />

      {rightContent}
    </SnowSweeperFilterItemContainer>
  );
};

export default SnowSweeperFilterItem;
