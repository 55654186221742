import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { get, includes, map } from 'lodash-es';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { isAdminSelector, isSuperAdminSelector } from 'src/account/ducks';
import {
  DeviceRoleTypeCheckboxes,
  MeasurementTypeDropdown,
  RouteStartTimeDropdown,
  TimeZoneDropdown,
  VendorTypeDropdown,
} from '..';
import { AdminGuard } from '../../../account/components';
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  InputFieldLabel,
  LocationPicker,
  TypedField,
} from '../../../core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import {
  hasCity,
  hasCoordinates,
  hasCountry,
  hasStreet,
  hasStreetNumber,
  hasZip,
  isDateValidValidator,
  isEmail,
  isInteger,
  isOptionalNumberOrZero,
  isRequired,
  maxLength3,
  maxLength4,
  maxLength50,
  maxValueNumeric99,
  minValueNumeric1,
} from '../../../utils/services/validator';
import {
  AGREEMENT_TYPES,
  IMPERIAL,
  RUBICON_PRO_PORTAL,
  RUBICON_PRO,
  RUBICON_X,
  SMART_CITY,
  SUBSCRIPTION_PACKAGE_TYPES,
} from '../../constants';
import { Vendor } from '../../interfaces/Vendors';
import { DeviceTypesDropdown } from '../DeviceTypesDropdown';
import { X_DEVICE_TYPES } from '../../constants/xDeviceTypes';
import { US_SC_01_US_SMARTCITY_CLIENTS } from 'src/vendors/constants/fleetRouteInstances';

export interface VendorEditorFormValues extends Vendor {
  deviceRoles?: number[];
  xDeviceTypeId?: number;
}

interface PropsWithoutReduxForm {
  onCancel: (isPristine: boolean) => void;
  vendor?: Vendor;
  systemOfMeasurementId: number;
  isAutoSnapToCurbDisabled?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<VendorEditorFormValues, PropsWithoutReduxForm>;

const VendorEditorForm = (props: Props) => {
  const { vendor, systemOfMeasurementId, isAutoSnapToCurbDisabled, handleSubmit, onCancel, pristine, change } = props;

  const formSelector = formValueSelector('vendorEditorForm');
  const selectedFormValues = useSelector((state: AppState) =>
    formSelector(
      state,
      'activateAppLicenses',
      'endDate',
      'isActive',
      'startDate',
      'vendorTypeId',
      'deviceRoles',
      'fleetRouteInstanceId',
    ),
  );
  const { fleetRouteInstances } = useSelector((state: AppState) => state.common.fleetRouteInstances);

  const startDateValue = get(selectedFormValues, 'startDate');
  const endDateValue = get(selectedFormValues, 'endDate');

  const [values, setValues] = useState({
    currentValues: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    prevValues: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    changedInput: '',
  });

  const getSystemOfMeasurementLabel = (id: number) => translate(`common.speedUnit.${id === IMPERIAL ? 'MPH' : 'KPH'}`);

  const [systemOfMeasurementLabel, setSystemOfMeasurementLabel] = useState(
    getSystemOfMeasurementLabel(systemOfMeasurementId),
  );

  const systemOfMeasurementIdChange = (event: ChangeEvent<HTMLInputElement>, value: number) =>
    setSystemOfMeasurementLabel(getSystemOfMeasurementLabel(value));

  const trimValue = (value: string) => value && value.trim();

  const agreementTypesOptions = map(AGREEMENT_TYPES, agreementType => ({
    label: agreementType.name,
    value: agreementType.id,
  }));

  const fleetRouteInstanceOptions = map(fleetRouteInstances, fleetRouteInstance => ({
    label: `${fleetRouteInstance.name} (${fleetRouteInstance.description})`,
    value: fleetRouteInstance.id,
  }));
  const fleetRouteInstanceDefaultOption = fleetRouteInstanceOptions?.find(
    fleetRouteInstanceOption => fleetRouteInstanceOption.value === US_SC_01_US_SMARTCITY_CLIENTS,
  );

  const subscriptionPackageTypeOptions = map(SUBSCRIPTION_PACKAGE_TYPES, subscriptionPackageType => ({
    label: subscriptionPackageType.name,
    value: subscriptionPackageType.id,
  }));

  const rubiconXEnabled = includes(get(selectedFormValues, 'deviceRoles'), RUBICON_X);

  const existDates = startDateValue && endDateValue;
  const existPrevDates = values.prevValues.startDate && values.prevValues.endDate;

  const resetEndDate = moment(startDateValue).diff(moment(endDateValue), 'days') > 0;

  useEffect(() => {
    if (startDateValue && !values.currentValues.startDate) {
      setValues({
        currentValues: { startDate: startDateValue, endDate: endDateValue },
        prevValues: { startDate: values.prevValues.startDate, endDate: values.prevValues.endDate },
        changedInput: '',
      });
    }

    if (
      values.changedInput === 'endDate' &&
      (startDateValue || values.currentValues.startDate) &&
      endDateValue &&
      moment(endDateValue).diff(moment(startDateValue), 'days') < 0 &&
      moment(values.prevValues.endDate).format('MM/DD/YYYY') === moment(endDateValue).format('MM/DD/YYYY')
    ) {
      setValues({
        prevValues: {
          startDate: values.currentValues.startDate,
          endDate: null,
        },
        currentValues: { startDate: values.currentValues.startDate, endDate: null },
        changedInput: '',
      });
      change('endDate', null);
    }

    if (
      (!values.currentValues.startDate || values.currentValues.startDate === 'Invalid date') &&
      values.currentValues.endDate
    ) {
      setValues({
        prevValues: {
          startDate: values.currentValues.startDate,
          endDate: null,
        },
        currentValues: { startDate: values.currentValues.startDate, endDate: null },
        changedInput: '',
      });
      change('endDate', null);
    }

    if (
      (values.prevValues.startDate !== values.currentValues.startDate ||
        values.prevValues.endDate !== values.currentValues.endDate) &&
      existDates &&
      existPrevDates
    ) {
      if (values.changedInput === 'startDate' && values.prevValues.startDate.length && resetEndDate) {
        setValues({
          prevValues: { startDate: values.currentValues.startDate, endDate: null },
          currentValues: { startDate: values.prevValues.startDate, endDate: null },
          changedInput: '',
        });
        change('startDate', startDateValue);
        change('endDate', null);
      } else if (
        values.changedInput === 'endDate' &&
        values.prevValues.endDate.length &&
        moment(endDateValue).diff(moment(startDateValue), 'days') < 0
      )
        change('endDate', moment(values.prevValues.endDate));
    }
  }, [change, endDateValue, startDateValue, existDates, existPrevDates, resetEndDate, values, vendor]);

  const renderCheckboxes = (shouldRender: boolean) => {
    if (!shouldRender) return;
    return (
      <AdminGuard>
        <GridColumn size="12/12" />
        <GridColumn size="4/12">
          <Field
            name="isActive"
            component={Checkbox}
            props={{
              label: translate('vendors.portalAccessEnabled'),
              margin: 'defaultCellVertical no',
            }}
            onChange={(event, newIsActive) => {
              if (!newIsActive) {
                change('activateAppLicenses', false);
              }
            }}
          />
        </GridColumn>

        {get(selectedFormValues, 'vendorTypeId') !== RUBICON_PRO_PORTAL && (
          <GridColumn size="4/12">
            <Field
              name="activateAppLicenses"
              component={Checkbox}
              props={{
                label: translate('vendors.appLicensesEnabled'),
                margin: 'defaultCellVertical no no',
                disabled: !get(selectedFormValues, 'isActive') || !rubiconXEnabled,
                block: true,
              }}
            />
            <Field
              name="maxNumberOfAppLicenses"
              component={Input}
              validate={
                get(selectedFormValues, 'activateAppLicenses') ? [isOptionalNumberOrZero, maxLength4] : undefined
              }
              props={{
                label: translate('vendors.maxNumberOfAppLicenses'),
                margin: 'defaultCellVertical no defaultCellVertical',
                normalize: trimValue,
                disabled:
                  !get(selectedFormValues, 'isActive') ||
                  !get(selectedFormValues, 'activateAppLicenses') ||
                  !rubiconXEnabled,
              }}
            />
          </GridColumn>
        )}

        <GridColumn size="4/12">
          <Field
            name="isInternalVendor"
            component={Checkbox}
            props={{
              label: translate('vendors.isInternalVendor'),
              margin: 'defaultCellVertical no',
            }}
          />
        </GridColumn>
        <GridColumn size="6/12" />
      </AdminGuard>
    );
  };

  const isPro = get(selectedFormValues, 'vendorTypeId') === RUBICON_PRO;
  const isSmartCity = get(selectedFormValues, 'vendorTypeId') === SMART_CITY;

  const isRubiconAdmin = useSelector((state: AppState) => {
    const loginState = state.account.login;
    return isAdminSelector(loginState) || isSuperAdminSelector(loginState);
  });

  return (
    <Fragment>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="no">
          <PanelSectionGroup padding="no" width="100%">
            <Grid margin="no" multiLine>
              <GridColumn size="12/12">
                <PanelSectionTitle margin="no no small">{translate('vendors.vendorInformation')}</PanelSectionTitle>
              </GridColumn>

              <GridColumn size="12/12">
                <Field
                  name="name"
                  component={Input}
                  label={translate('common.name')}
                  validate={[isRequired, maxLength50]}
                />
              </GridColumn>

              <AdminGuard>
                <GridColumn size="4/12">
                  <Field name="vendorTypeId" component={VendorTypeDropdown} withLabel validate={[isRequired]} />
                </GridColumn>

                <GridColumn size="4/12">
                  <Field name="gusId" component={Input} label={translate('vendors.gusId')} normalize={trimValue} />
                </GridColumn>
              </AdminGuard>

              {!vendor && (
                <AdminGuard>
                  <GridColumn size="4/12">
                    <Field
                      name="haulerCode"
                      component={Input}
                      label={translate('vendors.haulerCode')}
                      validate={[isRequired, maxLength3]}
                      disabled={!!get(vendor, 'id')}
                    />
                  </GridColumn>
                </AdminGuard>
              )}

              <GridColumn size="12/12">
                <Field
                  name="homeAddress"
                  component={LocationPicker}
                  label={translate('vendors.companyAddress')}
                  validate={[isRequired, hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
                />
              </GridColumn>

              {!vendor && (
                <>
                  <GridColumn size="6/12">
                    <Field
                      name="sysAdminName"
                      component={Input}
                      label={translate('vendors.sysAdminName')}
                      validate={[isRequired]}
                    />
                  </GridColumn>

                  <GridColumn size="6/12">
                    <Field
                      name="inviteEmailAddress"
                      component={Input}
                      label={translate('vendors.sysAdminEmail')}
                      validate={[isRequired, isEmail]}
                    />
                  </GridColumn>
                </>
              )}
              {renderCheckboxes(!isPro && !isSmartCity)}
            </Grid>
          </PanelSectionGroup>
        </PanelSection>

        {(isPro || isSmartCity) && (
          <PanelSection padding="small no no">
            <PanelSectionGroup padding="no" width="100%">
              <Grid margin="no" multiLine>
                <GridColumn size="12/12">
                  <PanelSectionTitle margin="no no small">{translate('vendors.techConfiguration')}</PanelSectionTitle>
                </GridColumn>
                <GridColumn size="3/12">
                  <TypedField
                    name="agreementTypeId"
                    component={Dropdown}
                    props={{
                      label: translate('vendors.agreementType'),
                      options: agreementTypesOptions,
                    }}
                    validate={[isRequired]}
                  />
                </GridColumn>

                <GridColumn size="3/12">
                  <Field
                    onChange={(e: any) => {
                      setValues(prevValue => ({
                        prevValues: {
                          startDate: prevValue.currentValues.startDate
                            ? prevValue.currentValues.startDate
                            : moment(e).format('MM/DD/YYYY'),
                          endDate: values.currentValues.endDate,
                        },
                        currentValues: {
                          startDate: moment(e).format('MM/DD/YYYY'),
                          endDate: values.currentValues.endDate,
                        },
                        changedInput: 'startDate',
                      }));
                    }}
                    name="startDate"
                    component={DatePicker}
                    isClearable
                    label={translate('common.startDate')}
                    validate={[isDateValidValidator, isRequired]}
                  />
                </GridColumn>

                <GridColumn size="3/12">
                  <Field
                    onChange={(e: any) => {
                      setValues(prevValue => ({
                        prevValues: {
                          startDate: values.currentValues.startDate,
                          endDate: prevValue.currentValues.endDate
                            ? prevValue.currentValues.endDate
                            : moment(e).format('MM/DD/YYYY'),
                        },
                        currentValues: {
                          startDate: values.currentValues.startDate,
                          endDate: moment(e).format('MM/DD/YYYY'),
                        },
                        changedInput: 'endDate',
                      }));
                    }}
                    name="endDate"
                    component={DatePicker}
                    disabledDays={
                      get(selectedFormValues, 'startDate')
                        ? [{ before: new Date(get(selectedFormValues, 'startDate')) }]
                        : undefined
                    }
                    isClearable
                    label={translate('common.endDate')}
                    validate={[isDateValidValidator, isRequired]}
                  />
                </GridColumn>

                <AdminGuard>
                  <GridColumn size="3/12">
                    <TypedField
                      name="subscriptionPackageType.id"
                      component={Dropdown}
                      props={{
                        label: translate('vendors.subscriptionPackageTypes.subscriptionPackageType'),
                        options: subscriptionPackageTypeOptions,
                      }}
                      validate={[isRequired]}
                    />
                  </GridColumn>
                </AdminGuard>

                <AdminGuard>
                  <TypedField
                    name="deviceRoles"
                    component={DeviceRoleTypeCheckboxes}
                    onChange={(event: ChangeEvent<HTMLInputElement>, values: number[]) =>
                      change(
                        'deviceRoleTypeId',
                        values.length ? values.reduce((acc: number, curr: number) => acc + curr) : null,
                      )
                    }
                    props={{
                      margin: 'no no sMedium',
                      padding: 'no xSmall',
                      withLabel: true,
                      formGroupProps: {
                        grid: true,
                        gridColumns: 'repeat(3, 1fr)',
                        gridColumnGap: '20px',
                        gridRowGap: '5px',
                      },
                    }}
                  />
                  <GridColumn size="12/12">
                    <GridColumn padding="no" size="4/12">
                      <TypedField
                        name="xDeviceTypeId"
                        component={DeviceTypesDropdown}
                        onChange={(event: ChangeEvent<HTMLInputElement>, value: number) =>
                          change('xDeviceType', X_DEVICE_TYPES[value])
                        }
                        validate={rubiconXEnabled ? [isRequired] : undefined}
                        props={{
                          withLabel: true,
                          dropdownProps: {
                            disabled: !rubiconXEnabled,
                          },
                        }}
                      />
                    </GridColumn>
                  </GridColumn>
                </AdminGuard>

                <GridColumn size="4/12">
                  <TypedField
                    name="systemOfMeasurementId"
                    component={MeasurementTypeDropdown}
                    onChange={systemOfMeasurementIdChange}
                    props={{
                      withLabel: true,
                    }}
                    validate={[isRequired]}
                  />
                </GridColumn>

                <GridColumn size="4/12">
                  <Field name="timeZone" component={TimeZoneDropdown} withLabel validate={[isRequired]} />
                </GridColumn>

                <GridColumn size="4/12">
                  <Field name="routeStartTime" component={RouteStartTimeDropdown} withLabel validate={[isRequired]} />

                  <AdminGuard>
                    <Field
                      name="allowRouteDateOverride"
                      component={Checkbox}
                      label={translate('common.allowDriverToOverride')}
                      margin="no no sMedium"
                    />
                  </AdminGuard>
                </GridColumn>

                <GridColumn size="12/12" margin="no no ssMedium no">
                  <Field
                    name="isAutoSnapToCurb"
                    disabled={isAutoSnapToCurbDisabled}
                    component={Checkbox}
                    label={translate('vendors.autoSnapToCurb')}
                  />
                </GridColumn>

                <GridColumn size="4/12">
                  <Field
                    name="speedingThreshold"
                    component={InputFieldLabel}
                    label={translate('common.speedingThreshold')}
                    maxLength="2"
                    validate={[isRequired, isInteger, minValueNumeric1, maxValueNumeric99]}
                    inlineLabel={systemOfMeasurementLabel}
                    leftMargin={12}
                  />
                </GridColumn>
                {isRubiconAdmin && (
                  <GridColumn size="8/12">
                    <TypedField
                      name="fleetRouteInstanceId"
                      component={Dropdown}
                      props={{
                        label: translate('vendors.fleetRouteInstanceTypes.fleetRouteInstanceType'),
                        options: fleetRouteInstanceOptions,
                        defaultValue: fleetRouteInstanceDefaultOption,
                      }}
                    />
                  </GridColumn>
                )}
                <AdminGuard>
                  <GridColumn size="12/12" padding="no" />

                  {!!vendor && (
                    <GridColumn size="6/12">
                      <Field
                        name="code"
                        component={Input}
                        label={translate('vendors.deviceRegistrationCode')}
                        readOnly
                      />
                    </GridColumn>
                  )}
                  <GridColumn size="6/12">
                    <Field
                      name="fleetWorksClientId"
                      component={Input}
                      label={translate('vendors.fleetWorksClientId')}
                    />
                  </GridColumn>
                </AdminGuard>
                {renderCheckboxes(isPro || isSmartCity)}
              </Grid>
            </PanelSectionGroup>
          </PanelSection>
        )}

        <PanelSection padding="large no no">
          <PanelSectionGroup padding="no" width="100%">
            <Grid margin="no" centered>
              <GridColumn size="12/12">
                <ButtonSet margin="no">
                  <Button type="submit" color="primary">
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            </Grid>
          </PanelSectionGroup>
        </PanelSection>
      </form>
    </Fragment>
  );
};

export default reduxForm<VendorEditorFormValues, PropsWithoutReduxForm>({
  form: 'vendorEditorForm',
  onSubmitFail: focusFirstInvalidField,
  enableReinitialize: true,
})(VendorEditorForm);
