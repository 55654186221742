import { connect } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { filter, find, findIndex, get, size, sortBy } from 'lodash-es';
import { PureComponent } from 'react';

import { AppState } from 'src/store';
import { AsyncOptionsPositions, MultiSelectProps } from 'src/core/components/MultiSelect';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { FieldProps } from 'src/core/components/FieldProps';
import {
  FRONT_LOAD_ID as VEHICLE_FRONT_LOAD_ID,
  ROLL_OFF_ID as VEHICLE_ROLL_OFF_ID,
  TOTER_ID as VEHICLE_TOTER_ID,
  RESIDENTIAL_ID as VEHICLE_RESIDENTIAL_ID,
  WASTE_AUDIT_ID as VEHICLE_WASTE_AUDIT_ID,
  STREET_SWEEPER_ID as VEHICLE_STREET_SWEEPER_ID,
  SNOW_PLOW_ID as VEHICLE_SNOW_PLOW_ID,
  DELIVERY_UTILITY_ID as VEHICLE_DELIVERY_UTILITY_ID,
} from 'src/fleet/constants/vehicleTypes';
import {
  FRONT_LOAD_ID,
  RESIDENTIAL_ID,
  BULK_ID,
  ROLL_OFF_ID,
  WASTE_AUDIT_ID,
  CART_ID,
  PORTABLE_TOILET_ID,
  STREET_ID,
  STREET_SWEEPING_ID,
} from 'src/common/constants/serviceTypes';
import { loadServiceContractRecommendation } from 'src/customers/services/routeRecommendations';
import { MultiSelect } from 'src/core/components';
import {
  RouteRecommendationMultiSelectOption,
  RouteRecommendationResponse,
} from './RouteRecommendationMultiSelectOption';
import {
  routeRecommendationsFeatureSelector,
  ServiceContractsWithRecommendation,
} from 'src/customers/ducks/routeRecommendations';
import { RouteTemplateByScheduledDays } from 'src/customers/ducks/routeTemplatesByScheduledDays';
import { Service } from 'src/customers/interfaces/Services';
import { SERVICE_DETAILS_EDITOR_FORM } from 'src/customers/components/forms/ServiceDetailsEditorForm';
import { ServiceRouteTemplateTitle } from './styled/CustomerLocations';
import translate from 'src/core/services/translate';

const getRouteTemplateByDayOptions = (routeTemplates: any[], day: number) =>
  sortBy(
    filter(routeTemplates, routeTemplate => routeTemplate.scheduledDay === day),
    'label',
  );

interface Props extends FieldProps {
  multiSelectProps: Partial<MultiSelectProps>;
  routeTemplatesByScheduledDays: RouteTemplateByScheduledDays[];
  day: number;
  index: number;
  resetRouteTemplatesValue: (key: string, value: any) => void;
  service?: Service;
  selectedServiceTypeId: number;
  vendorId: number;
  recommendationsFeature: ServiceContractsWithRecommendation;
}

interface State {
  routeRecommendation?: RouteRecommendationResponse;
}

class ServiceRouteTemplatesMultiSelect extends PureComponent<Props, State> {
  readonly state: State = {};

  componentDidUpdate(prevProps: Props) {
    const {
      multiSelectProps: { disabled },
      resetRouteTemplatesValue,
      index,
    } = this.props;
    if (disabled && !prevProps.multiSelectProps.disabled) {
      resetRouteTemplatesValue(`dayOfServices[${index}].routeTemplateIds`, null);
    }
  }

  formatText = (selectedOptions: any[], allOptionsSelected: boolean) => {
    if (allOptionsSelected && size(selectedOptions) !== 1) {
      return translate('customers.allRouteTemplates');
    }

    const { routeTemplatesByScheduledDays } = this.props;
    return size(selectedOptions) === 1
      ? get(find(routeTemplatesByScheduledDays, { value: Number(selectedOptions[0]) }), 'label')
      : translate('customers.xRouteTemplatesSelected', { selected: size(selectedOptions) });
  };

  hasLoadedRecommendations = false;

  getRouteRecommendationOptions = (routeRecommendation?: RouteRecommendationResponse) => {
    const { service, day, vendorId } = this.props;
    return [
      {
        unselectable: !routeRecommendation || !routeRecommendation.recommendedRouteTemplateId,
        value: (routeRecommendation && routeRecommendation.recommendedRouteTemplateId) || 'þ',
        label: (routeRecommendation && routeRecommendation.recommendedRouteTemplateName) || 'þ',
        renderCustomOption: (loadAsyncOptions: () => void) => (
          <RouteRecommendationMultiSelectOption
            recommendation={routeRecommendation}
            onLoadComplete={routeRecommendation => {
              this.setState({ routeRecommendation }, () => loadAsyncOptions());
            }}
            service={service}
            day={day}
            vendorId={vendorId}
          />
        ),
      },
    ];
  };

  loadRouteRecommendation = () => {
    const { service, day, vendorId } = this.props;
    if (!this.hasLoadedRecommendations && service) {
      return loadServiceContractRecommendation(service.id, day, vendorId).then((response: any) => {
        this.hasLoadedRecommendations = true;
        const routeRecommendation = response.data;
        this.setState({ routeRecommendation });
        return this.getRouteRecommendationOptions(routeRecommendation);
      });
    }
    return new Promise(resolve => resolve(this.getRouteRecommendationOptions(this.state.routeRecommendation)));
  };

  render() {
    const {
      day,
      index,
      label,
      multiSelectProps,
      placeholder,
      recommendationsFeature,
      routeTemplatesByScheduledDays,
      selectedServiceTypeId,
      service,
      withLabel,
      withPlaceholder,
    } = this.props;

    const options = getRouteTemplateByDayOptions(routeTemplatesByScheduledDays, day);
    const showRecommendation = options.length && recommendationsFeature && recommendationsFeature.enabled && service;

    const getCorrectCustomRoutesOptions = () => {
      let customRouteTemplatesOptions = [...options] as any[];

      const insertLabels = (vehicleTypeId: number, label: object) => {
        const index = findIndex(customRouteTemplatesOptions, ['vehicleTypeId', vehicleTypeId]);
        if (index > -1) {
          customRouteTemplatesOptions.splice(index, 0, label);
        }
      };
      const getLabel = (label: string) => {
        return {
          label: <ServiceRouteTemplateTitle>{translate(`vehicles.vehicleTypes.${label}`)}</ServiceRouteTemplateTitle>,
          value: 0,
          vehicleTypeId: 0,
          scheduledDay: 0,
          isDisabled: true,
          isOrderedMultiselect: true,
        };
      };

      let routeTemplatesOrder = 0;

      switch (selectedServiceTypeId) {
        case FRONT_LOAD_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return (
              route.vehicleTypeId === VEHICLE_FRONT_LOAD_ID ||
              route.vehicleTypeId === VEHICLE_DELIVERY_UTILITY_ID ||
              route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID ||
              route.vehicleTypeId === VEHICLE_RESIDENTIAL_ID
            );
          });
          routeTemplatesOrder = VEHICLE_FRONT_LOAD_ID;
          customRouteTemplatesOptions = sortBy(
            customRouteTemplatesOptions.sort(function (x, y) {
              return x.vehicleTypeId - y.vehicleTypeId;
            }),
            function (routes) {
              return routes.vehicleTypeId === routeTemplatesOrder ? 0 : 1;
            },
          );
          insertLabels(VEHICLE_FRONT_LOAD_ID, getLabel('frontLoad'));
          insertLabels(VEHICLE_DELIVERY_UTILITY_ID, getLabel('deliveryUtility'));
          insertLabels(VEHICLE_WASTE_AUDIT_ID, getLabel('wasteAudit'));
          insertLabels(VEHICLE_RESIDENTIAL_ID, getLabel('residential'));

          break;
        case ROLL_OFF_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return route.vehicleTypeId === VEHICLE_ROLL_OFF_ID || route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID;
          });
          routeTemplatesOrder = VEHICLE_ROLL_OFF_ID;
          customRouteTemplatesOptions = sortBy(
            customRouteTemplatesOptions.sort(function (x, y) {
              return x.vehicleTypeId - y.vehicleTypeId;
            }),
            function (routes) {
              return routes.vehicleTypeId === routeTemplatesOrder ? 0 : 1;
            },
          );
          insertLabels(VEHICLE_ROLL_OFF_ID, getLabel('rollOff'));
          insertLabels(VEHICLE_WASTE_AUDIT_ID, getLabel('wasteAudit'));

          break;
        case CART_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return (
              route.vehicleTypeId === VEHICLE_TOTER_ID ||
              route.vehicleTypeId === VEHICLE_DELIVERY_UTILITY_ID ||
              route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID
            );
          });
          routeTemplatesOrder = VEHICLE_TOTER_ID;
          customRouteTemplatesOptions = sortBy(
            customRouteTemplatesOptions.sort(function (x, y) {
              return x.vehicleTypeId - y.vehicleTypeId;
            }),
            function (routes) {
              return routes.vehicleTypeId === routeTemplatesOrder ? 0 : 1;
            },
          );
          insertLabels(VEHICLE_TOTER_ID, getLabel('cart'));
          insertLabels(VEHICLE_DELIVERY_UTILITY_ID, getLabel('deliveryUtility'));
          insertLabels(VEHICLE_WASTE_AUDIT_ID, getLabel('wasteAudit'));

          break;
        case RESIDENTIAL_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return (
              route.vehicleTypeId === VEHICLE_RESIDENTIAL_ID ||
              route.vehicleTypeId === VEHICLE_DELIVERY_UTILITY_ID ||
              route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID ||
              route.vehicleTypeId === VEHICLE_FRONT_LOAD_ID
            );
          });
          routeTemplatesOrder = VEHICLE_RESIDENTIAL_ID;
          customRouteTemplatesOptions = sortBy(
            customRouteTemplatesOptions.sort(function (x, y) {
              return x.vehicleTypeId - y.vehicleTypeId;
            }),
            function (routes) {
              return routes.vehicleTypeId === routeTemplatesOrder ? 0 : 1;
            },
          );
          insertLabels(VEHICLE_RESIDENTIAL_ID, getLabel('residential'));
          insertLabels(VEHICLE_DELIVERY_UTILITY_ID, getLabel('deliveryUtility'));
          insertLabels(VEHICLE_WASTE_AUDIT_ID, getLabel('wasteAudit'));
          insertLabels(VEHICLE_FRONT_LOAD_ID, getLabel('frontLoad'));
          break;
        case WASTE_AUDIT_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID;
          });

          break;
        case STREET_SWEEPING_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return route.vehicleTypeId === VEHICLE_STREET_SWEEPER_ID || route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID;
          });
          routeTemplatesOrder = VEHICLE_STREET_SWEEPER_ID;
          customRouteTemplatesOptions = sortBy(
            customRouteTemplatesOptions.sort(function (x, y) {
              return x.vehicleTypeId - y.vehicleTypeId;
            }),
            function (routes) {
              return routes.vehicleTypeId === routeTemplatesOrder ? 0 : 1;
            },
          );
          insertLabels(VEHICLE_STREET_SWEEPER_ID, getLabel('streetSweeper'));
          insertLabels(VEHICLE_WASTE_AUDIT_ID, getLabel('wasteAudit'));

          break;
        case PORTABLE_TOILET_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return (
              route.vehicleTypeId === VEHICLE_DELIVERY_UTILITY_ID || route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID
            );
          });
          routeTemplatesOrder = VEHICLE_DELIVERY_UTILITY_ID;
          customRouteTemplatesOptions = sortBy(
            customRouteTemplatesOptions.sort(function (x, y) {
              return x.vehicleTypeId - y.vehicleTypeId;
            }),
            function (routes) {
              return routes.vehicleTypeId === routeTemplatesOrder ? 0 : 1;
            },
          );
          insertLabels(VEHICLE_DELIVERY_UTILITY_ID, getLabel('deliveryUtility'));
          insertLabels(VEHICLE_WASTE_AUDIT_ID, getLabel('wasteAudit'));

          break;
        case BULK_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return (
              route.vehicleTypeId === VEHICLE_ROLL_OFF_ID ||
              route.vehicleTypeId === VEHICLE_RESIDENTIAL_ID ||
              route.vehicleTypeId === VEHICLE_DELIVERY_UTILITY_ID ||
              route.vehicleTypeId === VEHICLE_WASTE_AUDIT_ID ||
              route.vehicleTypeId === VEHICLE_FRONT_LOAD_ID
            );
          });
          routeTemplatesOrder = VEHICLE_ROLL_OFF_ID;
          customRouteTemplatesOptions = sortBy(
            customRouteTemplatesOptions.sort(function (x, y) {
              return x.vehicleTypeId - y.vehicleTypeId;
            }),
            function (routes) {
              return routes.vehicleTypeId === routeTemplatesOrder ? 0 : 1;
            },
          );
          insertLabels(VEHICLE_ROLL_OFF_ID, getLabel('rollOff'));
          insertLabels(VEHICLE_RESIDENTIAL_ID, getLabel('residential'));
          insertLabels(VEHICLE_DELIVERY_UTILITY_ID, getLabel('deliveryUtility'));
          insertLabels(VEHICLE_WASTE_AUDIT_ID, getLabel('wasteAudit'));
          insertLabels(VEHICLE_FRONT_LOAD_ID, getLabel('frontLoad'));

          break;
        case STREET_ID:
          customRouteTemplatesOptions = options.filter(route => {
            return route.vehicleTypeId === VEHICLE_SNOW_PLOW_ID;
          });

          break;
      }

      return customRouteTemplatesOptions;
    };

    return (
      <Field
        name={`dayOfServices[${index}].routeTemplateIds`}
        label={label || (withLabel ? translate('customers.routeTemplates') : undefined)}
        placeholder={placeholder || (withPlaceholder ? translate('customers.routeTemplates') : undefined)}
        component={MultiSelect}
        isSearchable
        options={getCorrectCustomRoutesOptions()}
        formatText={this.formatText}
        canCheckAll={false}
        defaultToAll={false}
        margin="no"
        fitContentWidth={true}
        loadAsyncOptions={showRecommendation && this.loadRouteRecommendation}
        asyncOptionsPosition={showRecommendation && AsyncOptionsPositions.Top}
        {...multiSelectProps}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = (getFormValues as any)(SERVICE_DETAILS_EDITOR_FORM)(state);

  return {
    routeTemplatesByScheduledDays: state.customers.routeTemplatesByScheduledDays.routeTemplatesByScheduledDays,
    service: state.customers.service.service,
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    recommendationsFeature: routeRecommendationsFeatureSelector(state),
    selectedServiceTypeId: formValues?.serviceTypeId,
  };
};

export default connect(mapStateToProps)(ServiceRouteTemplatesMultiSelect);
