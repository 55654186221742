import React, { Component, Fragment } from 'react';

import { map } from 'lodash-es';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import { Collapsible, Table } from '../../../core/components';
import {
  Button,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionHeader,
  PanelSectionTitle,
  Tab as TabUntyped,
  Tabs,
  Text,
  ToggleIcon,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import { duration } from '../../../utils/services/formatter';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { exportTripInspectionReportV2 } from '../../services/vehicleInspections';
import InspectionPreviewImageModal from '../modal/InspectionPreviewImageModal';
import {
  InspectionFailedIcon,
  InspectionPassedIcon,
  InspectionsListContainer,
  ToggleIconContainer,
} from '../styled/VehicleInspections';
import { InspectionDetailsTableRow } from 'src/common/components';

const Tab = TabUntyped as any;

interface TripConfiguration {
  vendorId: number;
  vehicleTypeId: number;
  tripInspectionType: TechnicalType;
  additionalTripInspectionItem: {
    additionalTripInspectionItem: TechnicalType;
    isActive: boolean;
  };
  tripInspectionCategories: {
    tripInspectionCategory: TechnicalType;
    tripInspectionSubCategories: {
      tripInspectionSubCategory: TechnicalType;
      isRequired: boolean;
      isActive: boolean;
      orderNo: number;
      canTakePicture: number;
      canTakePictureIfIssueNotFlagged: number;
    }[];
  }[];
}

interface TripConfigurationRequiredSubMap {
  [subcategory: string]: boolean;
}

interface TripConfigurationRequiredMap {
  [category: string]: TripConfigurationRequiredSubMap;
}

interface Props extends RouteComponentProps {
  isLoading: boolean;
  vehicleTripInspectionDetails: any;
  vendorId: number;
  vehicleName: string;
  tripConfiguration: TripConfiguration;
}

interface State {
  activeTab: number;
  collapsedCategories: any;
  isImagePreviewModalOpen: boolean;
  selectedImageUrl: string;
  tripConfigurationRequiredMap: TripConfigurationRequiredMap | null;
}

const TABS = {
  summary: 1,
  details: 2,
};

class VehicleTripInspectionDetailsPage extends Component<Props, State> {
  readonly state: State = {
    activeTab: 1,
    collapsedCategories: {},
    isImagePreviewModalOpen: false,
    selectedImageUrl: '',
    tripConfigurationRequiredMap: null,
  };

  componentDidMount() {
    this.constructTripRequiredMap();
  }

  componentDidUpdate(prevProps: Props) {
    const { tripConfiguration } = this.props;
    const { tripConfigurationRequiredMap } = this.state;

    if (
      tripConfiguration &&
      (!tripConfigurationRequiredMap ||
        (prevProps.tripConfiguration &&
          prevProps.tripConfiguration.tripInspectionType.technicalName !==
            tripConfiguration.tripInspectionType.technicalName))
    ) {
      this.constructTripRequiredMap();
    }
  }

  constructTripRequiredMap() {
    const { tripConfiguration } = this.props;
    const map: TripConfigurationRequiredMap = {};

    tripConfiguration.tripInspectionCategories.forEach(category => {
      const submap: TripConfigurationRequiredSubMap = {};

      category.tripInspectionSubCategories.forEach(subcategory => {
        submap[subcategory.tripInspectionSubCategory.technicalName] = subcategory.isRequired;
      });

      map[category.tripInspectionCategory.technicalName] = submap;
    });

    this.setState({ tripConfigurationRequiredMap: map });
  }

  changeActiveTab = (tab: number) => {
    this.setState({
      activeTab: tab,
    });
  };

  openImagePreviewModal = (imageUrl: string) => {
    this.setState({
      isImagePreviewModalOpen: true,
      selectedImageUrl: imageUrl,
    });
  };

  closeImagePreviewModal = () => {
    this.setState({
      isImagePreviewModalOpen: false,
      selectedImageUrl: '',
    });
  };

  exportTripInspectionReport = () => {
    const { vendorId, vehicleTripInspectionDetails } = this.props;
    const { id } = vehicleTripInspectionDetails;

    exportTripInspectionReportV2(vendorId, id);
  };

  toggleCollapsibleTable = (tableName: string) => {
    const collapsedCategoriesCopy = { ...this.state.collapsedCategories };
    collapsedCategoriesCopy[tableName] = !this.state.collapsedCategories[tableName];

    this.setState({
      collapsedCategories: collapsedCategoriesCopy,
    });
  };

  render() {
    const { vehicleTripInspectionDetails, isLoading, vehicleName, history } = this.props;
    const { activeTab, collapsedCategories, isImagePreviewModalOpen, selectedImageUrl, tripConfigurationRequiredMap } =
      this.state;

    const { driverName, endTime, routeDate, signatureImage, startTime, mileage } = vehicleTripInspectionDetails;

    const formatedDate = moment(routeDate).format('MM/DD/YYYY');

    const vehicleInspectionDetailsTableCells = [
      { name: 'item', label: translate('vehicles.vehicleInspections.item'), width: '35%' },
      {
        name: 'passed',
        label: (
          <span>
            <InspectionPassedIcon />
            <Text size="large">{'/'}</Text>
            <InspectionFailedIcon />
          </span>
        ),
        width: '10%',
      },
      { name: 'imageUrl', label: translate('common.image'), width: '15%' },
      { name: 'note', label: translate('common.note'), width: '40%' },
    ];

    let tripInspectionCategories: any = {};

    vehicleTripInspectionDetails.tripInspectionDetails.forEach((inspection: any) => {
      const technicalName = inspection.tripInspectionCategory.technicalName;
      const subcategoryTechnicalName = inspection.tripInspectionSubCategory.technicalName;

      const inspectionLine = {
        item: inspection.tripInspectionSubCategory.name,
        passed: inspection.passedInspection,
        imageUrl: inspection.image && inspection.image.url,
        note: inspection.note,
        isRequired:
          tripConfigurationRequiredMap &&
          tripConfigurationRequiredMap[technicalName] &&
          tripConfigurationRequiredMap[technicalName][subcategoryTechnicalName],
      };

      if (tripInspectionCategories[technicalName]) {
        tripInspectionCategories[technicalName].items.push({ ...inspectionLine });
      } else {
        tripInspectionCategories[technicalName] = {
          items: [inspectionLine],
          name: inspection.tripInspectionCategory.name,
        };
      }
    });

    const formatedStartTime = moment(startTime, 'hh:mm:ss').format('LT');
    const formatedEndTime = moment(endTime, 'hh:mm:ss').format('LT');

    const timeDiff = moment(endTime, 'hh:mm:ss').diff(moment(startTime, 'hh:mm:ss'));
    let inspectionDuration = duration(timeDiff, 'milliseconds', 'h[h ] mm[m]');

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction
                onClick={() => {
                  history.goBack();
                }}
              >
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>
                {vehicleName + ' ' + translate('vehicles.vehicleInspections.inspectionLog') + ' ' + formatedDate}
              </PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button onClick={this.exportTripInspectionReport} color="primary" margin="no">
              {translate('common.export')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSection centered withBorder padding="sMedium">
            <Tabs>
              <Tab isSelected={activeTab === TABS.summary} onClick={() => this.changeActiveTab(TABS.summary)}>
                {translate('vehicles.vehicleInspections.summary')}
              </Tab>
              <Tab isSelected={activeTab === TABS.details} onClick={() => this.changeActiveTab(TABS.details)}>
                {translate('vehicles.vehicleInspections.details')}
              </Tab>
            </Tabs>
          </PanelSection>
          <PanelSectionGroup isLoading={isLoading}>
            {activeTab === TABS.summary && (
              <PanelSection>
                <InspectionsListContainer>
                  <Text block weight="medium" align="left">
                    {`${translate('common.vehicle')}:`}
                  </Text>
                  <Text>{vehicleName}</Text>

                  {mileage !== 0 && (
                    <>
                      <Text block weight="medium" align="left">
                        {`${translate('vehicles.vehicleInspections.mileage')}:`}
                      </Text>
                      <Text>{vehicleTripInspectionDetails.mileage}</Text>
                    </>
                  )}

                  <Text block weight="medium" align="left">
                    {`${translate('routes.routeDate')}:`}
                  </Text>
                  <Text>{formatedDate}</Text>

                  <Text block weight="medium" align="left">
                    {`${translate('common.startTime')}:`}
                  </Text>
                  <Text>{formatedStartTime}</Text>

                  <Text block weight="medium" align="left">
                    {`${translate('common.endTime')}:`}
                  </Text>
                  <Text>{formatedEndTime}</Text>

                  <Text block weight="medium" align="left">
                    {`${translate('common.duration')}:`}
                  </Text>
                  <Text>{inspectionDuration}</Text>

                  <Text block weight="medium" align="left">
                    {`${translate('drivers.driverName')}:`}
                  </Text>
                  <Text>{driverName}</Text>

                  <Text block weight="medium" align="left">
                    {`${translate('vehicles.vehicleInspections.signature')}:`}
                  </Text>

                  {!!signatureImage && <img src={signatureImage.url} alt={`${signatureImage.imageId}`} width="200px" />}
                </InspectionsListContainer>
              </PanelSection>
            )}
            {activeTab === TABS.details && (
              <Fragment>
                {map(tripInspectionCategories, (category: any, index: string) => (
                  <Fragment key={index}>
                    <PanelSectionHeader
                      margin="small"
                      onClick={() => {
                        this.toggleCollapsibleTable(index);
                      }}
                    >
                      <PanelSectionTitle>{category.name}</PanelSectionTitle>
                      <ToggleIconContainer>
                        <ToggleIcon size={16} isOpen={!collapsedCategories[index]} icon="arrowDownFill" />
                      </ToggleIconContainer>
                    </PanelSectionHeader>
                    <Collapsible isOpen={!collapsedCategories[index]}>
                      <PanelSection>
                        <Table
                          cells={vehicleInspectionDetailsTableCells}
                          rows={category.items}
                          rowComponent={InspectionDetailsTableRow}
                          withTopBorder
                          rowProps={{
                            openImagePreviewModal: this.openImagePreviewModal,
                          }}
                        />
                      </PanelSection>
                    </Collapsible>
                  </Fragment>
                ))}
              </Fragment>
            )}
          </PanelSectionGroup>
        </Panel>
        {isImagePreviewModalOpen && (
          <InspectionPreviewImageModal imageUrl={selectedImageUrl} closeModal={this.closeImagePreviewModal} />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.fleet.vehicleTripInspectionDetails.isLoading || state.fleet.vehicle.isLoading,
  vehicleTripInspectionDetails: state.fleet.vehicleTripInspectionDetails.vehicleTripInspectionDetails,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vehicleName: state.fleet.vehicle.vehicle.regplate,
  tripConfiguration: state.vendors.tripConfiguration.tripConfigurationForVendor,
});

export default withRouter(connect(mapStateToProps)(VehicleTripInspectionDetailsPage));
