import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { loadCityInsightTypes } from '../../../dashboard/ducks';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { shouldLoadPermissionsSelector, loadPermissions } from '../../../account/ducks';
import CommunityInsightsPage from './CommunityInsightsPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadCityInsightTypes: DuckFunction<typeof loadCityInsightTypes>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadPermissions: DuckFunction<typeof loadPermissions>;
  shouldLoadPermissions: boolean;
  userId: string;
  vendorId: number;
}

class CommunityInsightsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadCityInsightTypes, loadFiltersSettings, loadPermissions, shouldLoadPermissions, userId, vendorId } =
      this.props;

    const dependenciesWithoutPermissions: any[] = [
      loadCityInsightTypes(vendorId),
      loadFiltersSettings(vendorId, userId),
    ];

    return Promise.all(
      shouldLoadPermissions ? [loadPermissions(), ...dependenciesWithoutPermissions] : dependenciesWithoutPermissions,
    );
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('insights.communityInsights')}</DocumentTitle>

        <Resolver
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          successComponent={CommunityInsightsPage}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  shouldLoadPermissions: (shouldLoadPermissionsSelector as any)(state.account.login, state.account.permissions),
  userId: currentUserIdSelector(state.account.login) as any,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadCityInsightTypes,
  loadFiltersSettings,
  loadPermissions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunityInsightsPageResolver));
