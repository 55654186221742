import { map, size } from 'lodash-es';

const transformLoadRouteMapDetails = ({
  vehicleTrackings,
  vehiclePositions,
  vehicleInsights: {
    binInsights: { insights },
  },
  unitOfMeasure,
  hasStrobeData,
}: any) => ({
  vehiclePositions:
    size(vehiclePositions.dashboardVehicles) > 0 &&
    map(
      vehiclePositions.dashboardVehicles[0].vehicles,
      ({ id, name, vehicleType, vehicleTypeName, latitude, longitude, ...vehiclePosition }) => ({
        vehicle: { id, name },
        coordinates: { latitude, longitude },
        vehicleType: { technicalName: vehicleType, name: vehicleTypeName },
        ...vehiclePosition,
      }),
    ),
  vehicleTrackings: map(vehicleTrackings, ({ vehicle, coordinateGroups, applicationModeChanges }) => ({
    applicationModeChanges,
    coordinateGroups,
    vehicle,
  })),
  containerInsights: insights,
  unitOfMeasure,
  hasStrobeData,
});

export default transformLoadRouteMapDetails;
