import React, { PureComponent } from 'react';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import VehiclePositionMarker from './VehiclePositionMarker';
import { RubiconMarkerClusterer } from './RubiconMarkerClusterer';
import { OpenedInfoWindows } from '../interfaces/OpenedInfoWindows';

interface Props {
  vehiclePositions: any[];
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow: (key: string) => void;
}

class VehiclePositions extends PureComponent<Props> {
  render() {
    const { vehiclePositions, openedInfoWindows, toggleInfoWindow } = this.props;

    return (
      <RubiconMarkerClusterer>
        {(clusterer: Clusterer) => (
          <>
            {vehiclePositions.map((vehiclePosition, index) => (
              <VehiclePositionMarker
                key={index}
                vehiclePosition={vehiclePosition}
                isInfoWindowOpen={openedInfoWindows.vehiclePositions[vehiclePosition.vehicle.id]}
                toggleInfoWindow={toggleInfoWindow}
                clusterer={clusterer}
              />
            ))}
          </>
        )}
      </RubiconMarkerClusterer>
    );
  }
}

export default VehiclePositions;
