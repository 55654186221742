import { map, some } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { Grid, GridColumn, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import {
  clearSelectedDraft,
  loadServiceContractsTotalsCount,
  refreshWorkSession,
  resetSelectedServicesTotalsCounts,
  setSelectedDraftId,
  upsertDraftRouteTemplateForWorkSession,
} from 'src/routes/ducks/routeTemplateBuilder';
import { RouteTemplateBuilderService } from 'src/routes/interfaces/routeTemplateBuilder/RouteTemplateBuilderService';
import store from 'src/store';
import { Button, IconButtonIcon } from '../../../../../core/components/styled/Button';
import translate from '../../../../../core/services/translate';
import {
  SelectionInfoButtons,
  SelectionInfoContainer,
  SelectionInfoText,
} from '../../../styled/RouteTemplateBuilderMap';
import RouteTemplateBuilderServicesModalResolver from '../routeTemplateBuilderModals/RouteTemplateBuilderServicesModalResolver';
import RouteTemplateBuilderStopCountsPerDay from '../routeTemplateBuilderSections/RouteTemplateBuilderStopCountsPerDay';

interface Props {
  services: RouteTemplateBuilderService[];
  onAssignToExistingRouteClick(): void;
  onAssignToNewRouteClick(): void;
  onSectionHeightUpdate?: (height: number) => void;
}

export const RouteTemplateBuilderMapServiceActions: FC<Props> = ({
  services,
  onAssignToExistingRouteClick,
  onAssignToNewRouteClick,
  onSectionHeightUpdate = () => {},
}) => {
  const dispatch = useDispatch();
  const workSessionId = useSelector(s => s.routes.routeTemplateBuilder.workSession?.id);
  const selectedDraft = useSelector(s => s.routes.routeTemplateBuilder.selectedDraft);
  const selectedDraftIsLatestVersion = useSelector(s => s.routes.routeTemplateBuilder.selectedDraftIsLatestVersion);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  const isLoadingServicesTotalsCounts = useSelector(
    state => state.routes.routeTemplateBuilder.selectedServicesTotalsCountsLoading,
  );
  const servicesTotalsCounts = useSelector(state => state.routes.routeTemplateBuilder.selectedServicesTotalsCounts);

  const updateHeight = (ref: HTMLDivElement | null) => {
    if (ref) {
      onSectionHeightUpdate(ref.offsetHeight);
    }
  };

  const onDeleteStops = async () => {
    const deletableServices = services.filter(s => s.isDraft);
    if (
      !workSessionId ||
      !selectedDraft ||
      !(await confirm(
        translate('routeTemplateBuilder.serviceContractsDeleteConfirmation', {
          name: selectedDraft.name,
          count: deletableServices.length,
        }),
        translate('routeTemplateBuilder.onlyDeletedNewAsignedStops'),
      ))
    ) {
      return;
    }

    upsertDraftRouteTemplateForWorkSession(workSessionId, selectedDraft.id, {
      ...selectedDraft,
      serviceContracts: deletableServices.map(service => ({ id: service.id, isDeleted: true })),
    })(dispatch)
      .then(() => {
        createSuccessNotification(
          translate('routeTemplateBuilder.serviceContractsDeletedSuccessfully', { name: selectedDraft.name }),
        );
        refreshWorkSession()(dispatch, store.getState);
        dispatch(clearSelectedDraft());
        dispatch(setSelectedDraftId(selectedDraft.id));
      })
      .catch(error => {
        const { code } = error.response.data;

        switch (code) {
          case 'RouteTemplatePlannerWorkSessionRouteTemplateNameExists':
            createErrorNotification(translate('routeTemplateBuilder.errors.routeTemplateNameExistsWithinSession'));
            break;

          case 'RouteTemplatePlannerDeleteTransferredStops':
            createErrorNotification(translate('routeTemplateBuilder.errors.routeTemplateDeleteTransferredStops'));
            break;

          default:
            createErrorNotification(translate('routeTemplateBuilder.errors.unexpectedError'));
            break;
        }
      });
  };

  const serviceIds = useMemo(() => map(services, 'id'), [services]);

  useEffect(() => {
    if (serviceIds.length) loadServiceContractsTotalsCount(serviceIds)(dispatch);
    else dispatch(resetSelectedServicesTotalsCounts());
  }, [dispatch, serviceIds]);

  const displayServiceData = !!servicesTotalsCounts && !!serviceIds.length;

  return (
    <SelectionInfoContainer ref={updateHeight}>
      <Grid>
        <GridColumn size={displayServiceData ? '8/12' : '12/12'}>
          <SelectionInfoText>
            <Text block size="xLarge">
              {services.length}{' '}
              <Button
                text
                color="primary"
                size="xLarge"
                padding="no no"
                onClick={() => setIsServicesModalOpen(true)}
                disabled={!services.length}
              >
                {translate('common.services')}
              </Button>{' '}
              {translate('common.selected')}
            </Text>

            <RouteTemplateBuilderStopCountsPerDay forServiceActions services={services} />

            <Text block>{translate('routeTemplateBuilder.selectedStopsInfo')}</Text>
          </SelectionInfoText>
        </GridColumn>
        {displayServiceData && (
          <GridColumn size="4/12" isLoading={isLoadingServicesTotalsCounts} padding="large no no">
            <Text block size="small">
              {translate('routeTemplateBuilder.totalContainersCount')}:{' '}
              <Text color="primary">{servicesTotalsCounts.containerCount}</Text>
            </Text>
          </GridColumn>
        )}
      </Grid>

      <SelectionInfoButtons>
        {!!selectedDraft && selectedDraftIsLatestVersion && some(services, s => s.isDraft) && (
          <TooltipIconButton
            tooltipPosition="top"
            tooltipColor="alert"
            tooltipAsString
            tooltip={translate('routeTemplateBuilder.removeServiceContracts')}
            color="grayLight"
            margin="no small no no"
            onClick={onDeleteStops}
          >
            <IconButtonIcon icon="delete" color="alert" />
          </TooltipIconButton>
        )}

        <Button margin="no small no no" color="primary" onClick={onAssignToExistingRouteClick}>
          + {translate('routeTemplateBuilder.addToExistingRouteTemplate')}
        </Button>

        <Button onClick={onAssignToNewRouteClick} color="primary">
          + {translate('routeTemplateBuilder.createNewRouteTemplate')}
        </Button>
      </SelectionInfoButtons>
      {isServicesModalOpen && (
        <RouteTemplateBuilderServicesModalResolver
          serviceIds={serviceIds}
          closeModal={() => setIsServicesModalOpen(false)}
        />
      )}
    </SelectionInfoContainer>
  );
};
