import { orderBy } from 'lodash-es';

import { http } from 'src/core/services/http';
import { transformLoadDispatchBoardJobs } from './transformDispatchBoardRouteJobs';

export const loadOnHoldJobs = (
  vendorId: number,
  date?: Date | string,
  routeStatuses?: any[],
  searchTerm?: string,
  serviceZones?: number[],
  supervisors?: number[],
  groupIds?: number[],
) => {
  const params = {
    vendorId,
    date,
    searchTerm,
    routeStatuses: routeStatuses && routeStatuses.toString(),
    serviceZoneIds: serviceZones && serviceZones.toString(),
    supervisorIds: supervisors && supervisors.toString(),
    groupIds: groupIds && groupIds.toString(),
  };

  const isOnHoldJob = true;

  return http
    .get('dispatchBoard/onHoldStops', {
      params,
    })
    .then(response => orderBy(transformLoadDispatchBoardJobs(response.data.stops, isOnHoldJob), 'date', 'asc'));
};

export const loadOnHoldJobsCount = (
  vendorId: number,
  date?: Date | string,
  routeStatuses?: any[],
  searchTerm?: string,
  serviceZones?: number[],
  supervisors?: number[],
  groupIds?: number[],
) => {
  const params = {
    vendorId,
    date,
    searchTerm,
    routeStatuses: routeStatuses && routeStatuses.toString(),
    serviceZoneIds: serviceZones && serviceZones.toString(),
    supervisorIds: supervisors && supervisors.toString(),
    groupIds: groupIds && groupIds.toString(),
  };
  return http
    .get<{ count: number }>('dispatchBoard/onHoldStops/count', { params })
    .then(response => response.data.count);
};

export const deleteOnHoldJobs = (jobIds: number[]) => http.delete('dispatchBoard/onHoldStops/delete', { data: jobIds });

export const addOnHoldJobsToRoute = (routeData: any) => http.put('dispatchBoard/onHoldStops/routes', routeData);
