import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { AppState } from '../../../../../store';
import { Container, Message, PanelSection, PanelSectionGroup, Text } from '../../../../../core/components/styled';
import { DuckFunction } from '../../../../../contracts/ducks';
import { EventLogForm } from '../../../forms';
import { EventLogTableRow } from '.';

import { IEventLog } from '../../../../interfaces/EventLog';
import { loadEventLog } from '../../../../ducks';
import { loadZEventLog } from 'src/routes/ducks/eventLog';
import { Table } from '../../../../../core/components';
import { TABLE_ROW_HEIGHT } from '../../../../../core/constants';
import translate from '../../../../../core/services/translate';
import ZEventLogTableRow from './ZEventLogTableRow';

const getEventLogTableCells = (isSnowPlowRoute: boolean) => {
  return [
    {
      name: 'stopOrderNo',
      label: translate(isSnowPlowRoute ? 'routes.snowPlow.order' : 'routes.stopNumber'),
      width: '10%',
    },
    { name: 'customerName', label: translate('common.customer'), width: '28%' },
    { name: 'actionName', label: translate('routes.eventName'), width: '15%' },
    { name: 'batteryLevel', label: translate('common.batteryLevel'), width: '10%' },
    { name: 'cpuUsage', label: translate('common.cpuUsage'), width: '10%' },
    { name: 'memory', label: translate('common.memory'), width: '15%' },
    { name: 'actionTime', label: translate('common.timestamp'), width: '12%' },
  ];
};

const getZEventLogTableCells = () => {
  return [
    { name: 'creationTime', label: translate('common.creationDate'), width: '10%' },
    { name: 'appStatus', label: translate('common.appStatus'), width: '12%' },
    { name: 'wiFiStatus', label: translate('common.wifiStatus'), width: '10%' },
    { name: 'speed', label: translate('common.speed'), width: '8%' },
    { name: 'camera1Status', label: translate('common.camera.cameraStatus', { no: 1 }), width: '10%' },
    { name: 'camera2Status', label: translate('common.camera.cameraStatus', { no: 2 }), width: '10%' },
    { name: 'camera1Fps', label: translate('common.camera.cameraFPS', { no: 1 }), width: '9%' },
    { name: 'camera2Fps', label: translate('common.camera.cameraFPS', { no: 2 }), width: '9%' },
    { name: 'imageQueue', label: translate('common.imageQueue'), width: '22%' },
  ];
};

interface EventDetailsProps {
  event: IEventLog;
  isSnowPlowRoute: boolean;
  isZ?: boolean;
}

const EventDetails = ({
  event: { appVersion, deviceType, driverName, deviceUniqueId, sessionDetails, routeSessionZDeviceDetails },
  isSnowPlowRoute,
  isZ,
}: EventDetailsProps) => {
  const details = (isZ ? routeSessionZDeviceDetails : sessionDetails) || [];
  return details.length ? (
    <PanelSection padding="small no no" vertical dark>
      <Container margin="no no small sMedium">
        <Text weight="medium" margin="no medium no no">
          {translate('drivers.driverName')}: <Text weight="light">{driverName}</Text>
        </Text>

        <Text weight="medium" margin="no medium no no">
          {translate('common.appVersion')}: <Text weight="light">{appVersion}</Text>
        </Text>

        <Text weight="medium" margin="no medium no no">
          {translate('common.deviceType')}: <Text weight="light">{deviceType}</Text>
        </Text>
        {isZ && (
          <Text weight="medium" margin="no medium no no">
            {translate('common.deviceUniqueId')}: <Text weight="light">{deviceUniqueId}</Text>
          </Text>
        )}
      </Container>

      <Table
        virtualized
        cells={isZ ? getZEventLogTableCells() : getEventLogTableCells(isSnowPlowRoute)}
        rows={details}
        rowComponent={isZ ? ZEventLogTableRow : EventLogTableRow}
        virtualizedProps={{
          itemSize: TABLE_ROW_HEIGHT,
          height: Math.min(details.length * TABLE_ROW_HEIGHT, TABLE_ROW_HEIGHT * 8) || 1,
        }}
      />
    </PanelSection>
  ) : null;
};

interface ComponentProps {
  isZ?: boolean;
  routeId: number;
  isSnowPlowRoute: boolean;
}

interface EventLogProps extends ComponentProps {
  eventLog?: IEventLog[];
  isLoading: boolean;
  loadEventLog: DuckFunction<typeof loadEventLog>;
  loadZEventLog: DuckFunction<typeof loadZEventLog>;
  onLoadTab?: () => void;
}

class EventLog extends PureComponent<EventLogProps> {
  onSubmit = ({ eventTypeIds }: { eventTypeIds: number[] }) => {
    const { routeId, loadEventLog, loadZEventLog } = this.props;
    loadEventLog(routeId, eventTypeIds);
    loadZEventLog(routeId, eventTypeIds);
  };

  componentDidMount() {
    const { onLoadTab } = this.props;
    !!onLoadTab && onLoadTab();
  }

  render() {
    const { eventLog = [], isLoading, routeId, isSnowPlowRoute, isZ } = this.props;
    const hasRouteSessionZDeviceDetails = eventLog
      .map(el => el.routeSessionZDeviceDetails?.length)
      .some(el => el !== 0);

    return (
      <PanelSectionGroup isLoading={isLoading} id={`route-${routeId}-trigger-map-form`}>
        {!isZ && <EventLogForm routeId={routeId} onSubmit={this.onSubmit} />}
        {!!eventLog.length &&
          eventLog.map((event, index) => (
            <EventDetails isZ={isZ} key={index} event={event} isSnowPlowRoute={isSnowPlowRoute} />
          ))}
        {(!eventLog.length || (!hasRouteSessionZDeviceDetails && isZ)) && (
          <Message padding="sMedium">{translate('routes.noEventLogs')}</Message>
        )}
      </PanelSectionGroup>
    );
  }
}

const mapStateToProps = (state: AppState, { isZ }: ComponentProps) => ({
  eventLog: isZ ? state.routes.eventLog.zEventLog : state.routes.eventLog.eventLog,
  isLoading: state.routes.eventLog.isLoading,
});

const mapDispatchToProps = {
  loadEventLog,
  loadZEventLog,
};
export default connect(mapStateToProps, mapDispatchToProps)(EventLog);
