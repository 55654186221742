import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { getCurrencies } from '../services/currencyOptions';

// Constants
const START_LOAD_CURRENCY_OPTIONS = 'customers/currencyOptions/START_LOAD_CURRENCY_OPTIONS';
const COMPLETE_LOAD_CURRENCY_OPTIONS = 'customers/currencyOptions/COMPLETE_LOAD_CURRENCY_OPTIONS';
const FAIL_LOAD_CURRENCY_OPTIONS = 'customers/currencyOptions/FAIL_LOAD_CURRENCY_OPTIONS';

interface State {
  isLoading: boolean;
  currencies: TechnicalType[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial State
const initialState: State = {
  isLoading: false,
  currencies: [
    {
      id: 1,
      technicalName: 'AE',
      name: 'United Arab Emirates Funds',
    },
    {
      id: 2,
      technicalName: 'AUD',
      name: 'Australian Dollar',
    },
    {
      id: 3,
      technicalName: 'BE',
      name: 'Belgium Funds',
    },
    {
      id: 4,
      technicalName: 'BR',
      name: 'Brazil Funds',
    },
    {
      id: 5,
      technicalName: 'CAN',
      name: 'Canadian Funds',
    },
    {
      id: 6,
      technicalName: 'CH',
      name: 'Switzerland Funds',
    },
    {
      id: 7,
      technicalName: 'CL',
      name: 'Chile Funds',
    },
    {
      id: 8,
      technicalName: 'DE',
      name: 'Germany Funds',
    },
    {
      id: 9,
      technicalName: 'ES',
      name: 'Spain Funds',
    },
    {
      id: 10,
      technicalName: 'EUR',
      name: 'Euro',
    },
    {
      id: 11,
      technicalName: 'FRA',
      name: 'French funds',
    },
    {
      id: 12,
      technicalName: 'GB',
      name: 'United Kingdom Funds',
    },
    {
      id: 13,
      technicalName: 'HK',
      name: 'Hong Kong Funds',
    },
    {
      id: 14,
      technicalName: 'IT',
      name: 'Italy Funds',
    },
    {
      id: 15,
      technicalName: 'JP',
      name: 'Japan Funds',
    },
    {
      id: 16,
      technicalName: 'MX',
      name: 'Mexico Funds',
    },
    {
      id: 17,
      technicalName: 'NL',
      name: 'Netherlands Funds',
    },
    {
      id: 18,
      technicalName: 'SE',
      name: 'Sweden Funds',
    },
    {
      id: 19,
      technicalName: 'TR',
      name: 'Turkey Funds',
    },
    {
      id: 20,
      technicalName: 'US',
      name: 'US Dollars',
    },
  ],
};

// Action Creators
const startLoad = () => ({
  type: START_LOAD_CURRENCY_OPTIONS,
});

const completeLoad = (currencies: TechnicalType[]) => ({
  type: COMPLETE_LOAD_CURRENCY_OPTIONS,
  currencies,
});

const failLoad = () => ({
  type: FAIL_LOAD_CURRENCY_OPTIONS,
});

// Async Actions
export const loadCurrencies = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const getCurrenciesPromise = getCurrencies();
  getCurrenciesPromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));
  return getCurrenciesPromise;
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CURRENCY_OPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case FAIL_LOAD_CURRENCY_OPTIONS:
      return {
        ...initialState,
        isLoading: false,
      };
    case COMPLETE_LOAD_CURRENCY_OPTIONS:
      return {
        ...state,
        currencies: action.currencies,
        isLoading: false,
      };
    default:
      return state;
  }
};
