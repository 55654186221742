import { combineReducers } from 'redux';
import { reducer as createUserAccountReducer } from './createUserAccount';
import { reducer as loginReducer } from './login';
import { reducer as newsReducer } from './news';
import { reducer as permissionsReducer } from './permissions';
import { reducer as requestPasswordResetReducer } from './requestPasswordReset';
import { reducer as resetPasswordReducer } from './resetPassword';
import { reducer as validateResetPasswordTokenReducer } from './validateResetPasswordToken';

export {
  completeLogin,
  deviceRoleTypeIdSelector,
  hasRoleSelector,
  isAdminSelector,
  isAdminVendorSelector,
  isRubiconNonTechHauler,
  isSmartCitySelector,
  isSuperAdminSelector,
  isVendorManagerSelector,
  isVendorSelector,
  login,
  loginWithSSOToken,
  logout,
  nonTechHaulerSelector,
  resetLogin,
  updateTokerSuperAdmin,
  userIdSelector,
  vendorGusIdSelector,
  vendorIdSelector,
  vendorTypeIdSelector,
} from './login';
export { requestPasswordReset, resetRequestPasswordReset } from './requestPasswordReset';
export { resetPassword, resetResetPassword } from './resetPassword';
export { checkSelfUser, createSelfUser, selfUserDestroy } from './createUserAccount';
export {
  loadPermissions,
  resetPermissions,
  hasPermissionSelector,
  shouldLoadPermissions,
  shouldLoadPermissionsSelector,
} from './permissions';
export { loadBlogPosts, loadEnvironmentSafetyBlogPosts, loadPublicPolicyPosts, loadTwitterPosts } from './news';
export { validateResetPasswordToken } from './validateResetPasswordToken';

export const reducer = combineReducers({
  createUserAccount: createUserAccountReducer,
  login: loginReducer,
  news: newsReducer,
  permissions: permissionsReducer,
  requestPasswordReset: requestPasswordResetReducer,
  resetPassword: resetPasswordReducer,
  validateResetPasswordToken: validateResetPasswordTokenReducer,
});
