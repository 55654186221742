import React, { ChangeEvent, PureComponent } from 'react';

import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { DuckFunction } from '../../../contracts/ducks';
import { Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { loadFeatureConfigurations } from '../../ducks';
import { TypedField } from '../../../core/components';
import { VehicleTypeForVendorDropdown } from '../../../fleet/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

interface ComponentProps {
  featureCode?: string;
  loadFeatureConfigurations: DuckFunction<typeof loadFeatureConfigurations>;
  onChangeVehicleTypeId: () => void;
  vendorId: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class FeatureConfigurationForm extends PureComponent<Props> {
  onVehicleTypeIdChange = (event: ChangeEvent<HTMLInputElement>, vehicleTypeId: number) => {
    const { vendorId, featureCode, loadFeatureConfigurations, onChangeVehicleTypeId } = this.props;
    onChangeVehicleTypeId();
    loadFeatureConfigurations(vendorId, vehicleTypeId, featureCode);
  };

  render() {
    return (
      <form>
        <PanelSection padding="small xSmall">
          <Grid centered>
            <GridColumn size="4/12">
              <TypedField
                name="vehicleTypeId"
                component={VehicleTypeForVendorDropdown}
                onChange={this.onVehicleTypeIdChange}
                props={{
                  dropdownProps: {
                    margin: 'no',
                  },
                  withLabel: true,
                }}
              />
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const mapDispatchToProps = { loadFeatureConfigurations };

export default connect(
  undefined,
  mapDispatchToProps,
)(
  reduxForm<any, ComponentProps>({
    enableReinitialize: true,
    form: 'featureConfiguration',
    onSubmitFail: focusFirstInvalidField,
  })(FeatureConfigurationForm),
);
