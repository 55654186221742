import { memo } from 'react';
import { Layer } from 'react-map-gl';

import { ROUTE_MAP_CLUSTERS_SOURCE } from '../constants';
import { VENDOR_LOCATION_ICON_TYPES_NEW } from 'src/common/constants/vendorLocationIcons';

export default memo(function RouteMapHaulerLocationsGLLayers() {
  return (
    <>
      {VENDOR_LOCATION_ICON_TYPES_NEW.map(iconType => (
        <Layer
          key={iconType.id}
          id={iconType.id}
          type="symbol"
          source={ROUTE_MAP_CLUSTERS_SOURCE}
          filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'icon'], iconType.id]]}
          paint={{}}
          layout={{
            'icon-image': iconType.id,
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          }}
        />
      ))}
    </>
  );
});
