import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import AdhocServiceHistoryPage from './AdhocServiceHistoryPage';
import { currentVendorId } from '../../../../vendors/services/currentVendorSelector';
import DocumentTitle from '../../../../common/components/DocumentTitle';
import { getQueryParams } from 'src/utils/services/queryParams';
import { loadAdhocServiceHistory } from '../../../ducks/adhocServiceHistory';
import { loadReasonCodeTypes } from 'src/common/ducks';
import { PageLoading } from '../../../../common/components/styled';
import { TypedResolver } from '../../../../core/components';
import translate from '../../../../core/services/translate';

interface Props extends RouteComponentProps {}

const AdhocServiceHistoryPageResolver: React.FC<Props> = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const { locationId, pickupStatusTypeIds, startDate, endDate, workOrderNumber } = getQueryParams(search);

  const loadDependencies = () => {
    return Promise.all([
      loadAdhocServiceHistory(
        vendorId,
        locationId,
        pickupStatusTypeIds?.toString(),
        startDate,
        endDate,
        workOrderNumber,
      )(dispatch),
      loadReasonCodeTypes()(dispatch),
    ]);
  };

  return (
    <>
      <DocumentTitle>{translate('customers.adhocServiceHistory')}</DocumentTitle>
      <TypedResolver
        resolve={loadDependencies}
        loadingComponent={PageLoading}
        successComponent={AdhocServiceHistoryPage}
      />
    </>
  );
};

export default withRouter(AdhocServiceHistoryPageResolver);
