import { isArray, size } from 'lodash-es';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { Table } from 'src/core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from 'src/core/components/styled';
import { TableCell } from 'src/core/components/Table';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import ApplyChecksFilterForm, { ApplyChecksFilterFormValues } from 'src/finance/components/forms/ApplyChecksFilterForm';
import { CreatePaymentModalResolver } from 'src/finance/components/modals';
import {
  PaymentsStatisticsSection,
  PaymentsTableRow,
} from 'src/finance/components/pages/applyChecks/applyChecksPageSections';
import { INVOICE_PAYMENT_DEFAULT_STATUSES } from 'src/finance/constants/invoicePaymentStatuses';
import {
  loadOpenBills,
  loadPayments,
  resetOpenBills,
  resetPaymentMethods,
  resetPayments,
  resetPaymentStatistics,
  resetPaymentStatuses,
} from 'src/finance/ducks';
import { deletePayment, exportPayments } from 'src/finance/ducks/payments';
import { ILoadOpenBillsParams } from 'src/finance/interfaces/openBills';
import { LoadPaymentsParams } from 'src/finance/services/payments';
import { createUrl } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { getQueryParams } from 'src/utils/services/queryParams';
import { NINETY_DAYS_BEFORE_TODAY, TODAY_FORMATTED } from 'src/core/constants';

const paymentTableCellWidths = {
  paymentNumber: '12%',
  paymentDate: '13%',
  paymentMethod: '11%',
  customerName: '18%',
  paymentTotal: '12%',
  unappliedAmount: '10%',
  paymentStatus: '13%',
  options: '10%',
};

const paymentsTableCells: TableCell[] = [
  {
    name: 'paymentNumber',
    label: translate('finance.applyChecks.paymentNumber'),
    width: paymentTableCellWidths.paymentNumber,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'paymentDate',
    label: translate('finance.applyChecks.paymentDate'),
    width: paymentTableCellWidths.paymentDate,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: translate('finance.paymentManagement.paymentMethod'),
    width: paymentTableCellWidths.paymentMethod,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'customerName',
    label: translate('finance.applyChecks.customerInfo'),
    width: paymentTableCellWidths.customerName,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'paymentTotal',
    label: translate('finance.applyChecks.paymentAmount'),
    width: paymentTableCellWidths.paymentTotal,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'unappliedAmount',
    label: translate('finance.applyChecks.unappliedAmount'),
    width: paymentTableCellWidths.unappliedAmount,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'paymentStatus',
    label: translate('finance.applyChecks.status'),
    width: paymentTableCellWidths.paymentStatus,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: true,
  },
  {
    name: 'options',
    label: translate('common.options'),
    width: paymentTableCellWidths.options,
    padding: 'defaultCellVertical xSmall',
    noPaddingRight: true,
    sortable: false,
  },
];

export const getPaymentsParams = (vendorId: number, searchParams: LoadPaymentsParams, isInitialValue?: boolean) => {
  const startDate = searchParams.startDate || NINETY_DAYS_BEFORE_TODAY;
  const endDate = searchParams.endDate || TODAY_FORMATTED;
  const { searchText, paymentStatusIds, paymentMethodIds } = searchParams;

  return {
    vendorId,
    startDate,
    endDate,
    date: isInitialValue
      ? {
          from: startDate,
          to: endDate,
        }
      : undefined,
    searchText,
    paymentStatusIds: isInitialValue
      ? isArray(paymentStatusIds)
        ? paymentStatusIds
        : paymentStatusIds?.split(',')
      : isArray(paymentStatusIds)
      ? paymentStatusIds.join(',')
      : paymentStatusIds || undefined,
    paymentMethodIds: isInitialValue
      ? isArray(paymentMethodIds)
        ? paymentMethodIds
        : paymentMethodIds?.split(',')
      : isArray(paymentMethodIds)
      ? paymentMethodIds.join(',')
      : paymentMethodIds || undefined,
  };
};

const ApplyChecksPage = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const vendorId = useSelector(currentVendorId);

  const paymentMethodOptions = useSelector(state => state.finance.paymentMethods.paymentMethods);

  const {
    payments: paymentsData,
    isExporting: isExportingPayments,
    isLoading: isLoadingPaymentsData,
  } = useSelector(state => state.finance.payments);
  const { isLoading: isLoadingOpenBillsData } = useSelector(state => state.finance.openBills);

  const searchParams = getQueryParams(search);
  const paymentsParams = getPaymentsParams(vendorId, searchParams);
  const initialValues = getPaymentsParams(vendorId, searchParams, true);

  const didMountRef = useRef(false);
  const payments = paymentsData?.payments;

  const [isCreatePaymentModalOpen, setIsCreatePaymentModalOpen] = useState(false);

  // cleanup on unmount
  useEffect(
    () => () => {
      dispatch(resetPaymentStatistics());
      dispatch(resetPayments());
      dispatch(resetOpenBills());
      dispatch(resetPaymentStatuses());
      dispatch(resetPaymentMethods());
    },
    [dispatch],
  );

  const handleSubmitSearch = async ({
    date,
    paymentStatusIds,
    paymentMethodIds,
    searchText,
  }: ApplyChecksFilterFormValues) => {
    if (didMountRef.current) {
      const filters = {
        startDate: date?.from,
        endDate: date?.to,
        searchText,
        paymentStatusIds: isArray(paymentStatusIds) ? paymentStatusIds.join(',') : paymentStatusIds || undefined,
        paymentMethodIds: isArray(paymentMethodIds) ? paymentMethodIds.join(',') : paymentMethodIds || undefined,
      };

      await dispatch(
        push(
          createUrl(pathname, search, {
            startDate: filters.startDate,
            endDate: filters.endDate,
            searchText: filters.searchText,
            paymentStatusIds: filters.paymentStatusIds,
            paymentMethodIds: filters.paymentMethodIds,
          }),
        ),
      );

      const loadPaymentParams: LoadPaymentsParams = {
        vendorId,
        paymentStatusIds: filters.paymentStatusIds,
        paymentMethodIds: filters.paymentMethodIds,
        startDate: filters.startDate,
        endDate: filters.endDate,
        searchText: filters.searchText,
      };

      const loadOpenBillsParams: ILoadOpenBillsParams = {
        vendorId,
        billStatusIds: INVOICE_PAYMENT_DEFAULT_STATUSES,
        startDate: filters.startDate,
        endDate: filters.endDate,
      };

      loadPayments({ ...paymentsParams, ...loadPaymentParams })(dispatch);
      loadOpenBills(loadOpenBillsParams)(dispatch);
    } else {
      didMountRef.current = true;
    }
  };

  const virtualizedPropsPayments = {
    height: Math.min(size(payments) * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const handlePaymentsExport = () => {
    exportPayments(paymentsParams)(dispatch);
  };

  const handleEditPaymentClick = (paymentId: number, paymentCustomerId: number) =>
    dispatch(push(`/finance/payments/${paymentId}/${paymentCustomerId}${search}`));

  const handleDeletePayment = async (paymentId: number) => {
    if (await confirm(translate('finance.applyChecks.deletePaymentConfirmation'))) {
      deletePayment(paymentId)(dispatch).then(() => {
        createSuccessNotification(translate('finance.applyChecks.paymentDeletedSuccess'));
        loadPayments(paymentsParams)(dispatch);
      });
    }
  };

  const openCreatePaymentModal = () => {
    setIsCreatePaymentModalOpen(true);
  };

  const closeCreatePaymentModal = () => {
    setIsCreatePaymentModalOpen(false);
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('finance.applyChecks.applyChecks')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions>
          <Button line color="primary" onClick={() => openCreatePaymentModal()}>
            {translate('finance.applyChecks.newPayment')}
          </Button>
        </PageActions>
      </PageHeader>
      <ApplyChecksFilterForm onSubmit={handleSubmitSearch} initialValues={initialValues} />
      <Panel margin="no no xLarge">
        <PanelSectionGroup>
          <PanelSection vertical padding="no" withBorder>
            <PaymentsStatisticsSection />
          </PanelSection>
        </PanelSectionGroup>
        <PanelSectionGroup padding="no" width="100%">
          <PanelSection vertical padding="no" withBorder>
            <Grid padding="small medium">
              <GridColumn padding="no" size="10/12">
                <PanelSectionTitle margin="medium no no no">
                  {translate('finance.applyChecks.payments')}
                </PanelSectionTitle>
              </GridColumn>
              <GridColumn size="2/12" padding="no">
                <ButtonSet align="right">
                  <Button
                    type="button"
                    color="primary"
                    margin="no"
                    line
                    id="payments-export-button"
                    disabled={!size(payments)}
                    onClick={handlePaymentsExport}
                  >
                    {translate('finance.export')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            </Grid>
            {!!size(payments) && (
              <PanelSection isLoading={isExportingPayments || isLoadingPaymentsData || isLoadingOpenBillsData}>
                <Grid padding="small medium">
                  <Table
                    cells={paymentsTableCells}
                    rowComponent={PaymentsTableRow}
                    rows={payments}
                    rowProps={{
                      handleDeletePayment,
                      handleEditPaymentClick: handleEditPaymentClick,
                      tableCellWidths: paymentTableCellWidths,
                      paymentMethodOptions,
                    }}
                    scrollMarker
                    virtualized
                    virtualizedProps={virtualizedPropsPayments}
                  />
                </Grid>
              </PanelSection>
            )}
            {!size(payments) && (
              <Grid padding="xxSmall medium small medium">
                <Message padding="no no small no">{translate('finance.applyChecks.noPayments')}</Message>
              </Grid>
            )}
          </PanelSection>
        </PanelSectionGroup>

        {isCreatePaymentModalOpen && <CreatePaymentModalResolver onClose={closeCreatePaymentModal} />}
      </Panel>
    </PageContent>
  );
};

export default ApplyChecksPage;
