import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { formValueSelector } from 'redux-form';
import { GeoJsonLayer } from '@deck.gl/layers';
import { GoogleMapsOverlay as DeckOverlay } from '@deck.gl/google-maps';
import { InfoWindow } from '@react-google-maps/api';
import { intersection, includes, orderBy } from 'lodash-es';

import { getFeatureCollection, getLineString } from 'src/common/components/map/util';
import { OpenedInfoWindows } from 'src/common/interfaces/OpenedInfoWindows';
import {
  MapInfoWindow,
  MapInfoWindowDetail,
  MapInfoWindowDetailLabel,
  MapInfoWindowDetails,
  MapInfoWindowDetailsContainer,
  MapInfoWindowTitle,
  MapInfoWindowTitleContainer,
} from 'src/core/components/styled';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';
import { dateAndTime } from 'src/utils/services/formatter';
import { getSegmentCenter, getSegmentRGBColor, parseSegmentJSON } from 'src/dashboard/utils/snowRoadConditions';
import { MAP_DEFAULT_ZOOM } from 'src/core/constants';
import { NONE_ID } from 'src/routes/constants/routePriorityTypes';
import { SNOW_ROAD_CONDITIONS } from 'src/dashboard/constants/cityInsightTypeConditions';
import { SnowRoadSegmentCondition } from 'src/dashboard/interfaces/snowRoadConditions';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

let overlay = new DeckOverlay({ layers: [], style: { zIndex: 99999 } });

const getGeoJson = (snowRoadConditions: SnowRoadSegmentCondition[]) =>
  getFeatureCollection(
    snowRoadConditions.map(segment => {
      const parsedSegment = parseSegmentJSON(segment.lineSegment);
      const lineString = getLineString(parsedSegment) as any;

      lineString.properties = {
        id: segment.segmentId,
        lastPassDateTime: segment.lastPassDateTime,
        segmentCenter: getSegmentCenter(parsedSegment),
      };

      return lineString;
    }),
  );

interface Props {
  conditionType: string;
  map: google.maps.Map;
  openedInfoWindows: OpenedInfoWindows;
  toggleInfoWindow(key: string): void;
}

const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);

const DashboardSnowRoadConditionsDeckGL: React.FC<Props> = ({
  conditionType,
  map,
  openedInfoWindows,
  toggleInfoWindow: rawToggleInfoWindow,
}) => {
  const mapZoom = map.getZoom() || MAP_DEFAULT_ZOOM;
  const [infoWindowCoordinates, setInfoWindowCoordinates] = useState<number[]>([]);

  const snowRoadConditions = useSelector(state => state.dashboard.snowRoadConditions.snowRoadConditions);

  const streetSweeperConditions = useSelector(state => state.dashboard.streetSweeperConditions.streetSweeperConditions);

  const conditions = conditionType === SNOW_ROAD_CONDITIONS ? snowRoadConditions : streetSweeperConditions;

  const filter = useSelector(state => formSelector(state, conditionType) || {});

  const selectedPriorities = useMemo(
    () =>
      [filter.priority1, filter.priority2, filter.priority3, filter.none]
        .map((enabled, index) => (enabled ? index + 1 : -1))
        .filter(priority => priority > 0),
    [filter],
  );

  const dataWithPriorities = useMemo(
    () => conditions.filter(segment => !!intersection(selectedPriorities, segment.priorityTypeIds).length),
    [conditions, selectedPriorities],
  );
  const dataWithNonePriorities = useMemo(
    () => conditions.filter(segment => !segment.priorityTypeIds.length),
    [conditions],
  );

  const dataFiltered = orderBy(
    useMemo(
      () =>
        includes(selectedPriorities, NONE_ID) ? [...dataWithNonePriorities, ...dataWithPriorities] : dataWithPriorities,
      [dataWithNonePriorities, dataWithPriorities, selectedPriorities],
    ),
    'lastPassDateTime',
    'desc',
  );

  const data = useMemo(() => getGeoJson(dataFiltered), [dataFiltered]);

  useEffect(() => {
    if (map) {
      overlay.setMap(map);
    }
  }, [conditionType, conditions, map]);

  useEffect(() => {
    const layers = [
      new GeoJsonLayer({
        pickable: true,
        data: data,
        lineWidthScale: 20,
        lineWidthMinPixels: 5,
        lineWidthMaxPixels: 15,
        updateTriggers: {
          getLineColor: [mapZoom],
        },
        getLineColor: (f: any) => [
          ...getSegmentRGBColor(f.properties.lastPassDateTime, conditionType),
          mapZoom >= 16 ? 51 : 255,
        ],
        onHover: (info: any) => map.setOptions({ draggableCursor: info.object ? 'pointer' : 'grab' }),
        onClick: (e: any) => {
          setInfoWindowCoordinates(data.features[e.index].properties!.segmentCenter);
          rawToggleInfoWindow(`${conditionType}.${data.features[e.index].properties!.id}`);
        },
      }),
    ];

    overlay.setProps({ layers });
  }, [map, data, rawToggleInfoWindow, mapZoom, conditionType, conditions]);

  useEffect(() => () => overlay.finalize(), []);

  return (
    <>
      {conditions?.map(segment => {
        const isInfoWindowOpen =
          conditionType === SNOW_ROAD_CONDITIONS
            ? !!openedInfoWindows.snowRoadConditions[segment.segmentId.toString()]
            : !!openedInfoWindows.streetSweeperConditions[segment.segmentId.toString()];

        const toggleInfoWindow = () => rawToggleInfoWindow(`${conditionType}.${segment.segmentId}`);

        return (
          <Fragment key={segment.segmentId}>
            {isInfoWindowOpen &&
              typeof infoWindowCoordinates[0] === 'number' &&
              typeof infoWindowCoordinates[1] === 'number' && (
                <InfoWindow
                  onCloseClick={toggleInfoWindow}
                  position={{ lat: infoWindowCoordinates[1], lng: infoWindowCoordinates[0] }}
                >
                  <MapInfoWindow vertical>
                    <MapInfoWindowTitleContainer>
                      <MapInfoWindowTitle>{segment.streetName}</MapInfoWindowTitle>
                    </MapInfoWindowTitleContainer>

                    <MapInfoWindowDetailsContainer>
                      <MapInfoWindowDetails>
                        {!!segment.vehicleName && (
                          <MapInfoWindowDetail>
                            <MapInfoWindowDetailLabel>{translate('vehicles.vehicleName')}:</MapInfoWindowDetailLabel>
                            {segment.vehicleName}
                          </MapInfoWindowDetail>
                        )}

                        <MapInfoWindowDetail>
                          <MapInfoWindowDetailLabel>{translate('dashboard.priority')}:</MapInfoWindowDetailLabel>
                          {segment.priorityTypeIds.join(', ')}
                        </MapInfoWindowDetail>

                        <MapInfoWindowDetail>
                          <MapInfoWindowDetailLabel>{translate('dashboard.statusLastPass')}:</MapInfoWindowDetailLabel>
                          {!!segment.lastPassDateTime ? dateAndTime(segment.lastPassDateTime) : '–'}
                        </MapInfoWindowDetail>
                      </MapInfoWindowDetails>
                    </MapInfoWindowDetailsContainer>
                  </MapInfoWindow>
                </InfoWindow>
              )}
          </Fragment>
        );
      })}
    </>
  );
};

export default DashboardSnowRoadConditionsDeckGL;
