import { PureComponent } from 'react';

import * as signalR from '@microsoft/signalr';
import moment from 'moment';

import { getAuthToken } from 'src/account/services/auth';
import { getCommunicationApiUrl } from 'src/core/services/environment';
import { getItem, setItem } from 'src/core/services/persistentStorage';
import { Icon } from 'src/core/components';
import { ModalClose, ModalCloseIcon } from 'src/core/components/styled';
import { Name, SOSAlertContent, SOSAlertInner, SOSAlertWrapper, SOSIcon, Title } from './styled/SOSAlert';
import { timeFormat } from 'src/utils/services/validator';
import { VIDEO_REQUEST_ALERT_KEY } from 'src/common/constants/videoRequests';
import { VideoRequestAlert } from 'src/common/interfaces/VideoDownloadDetails';
import translate from 'src/core/services/translate';
import VideoMessageModalResolver from './VideoMessageModalResolver';
import VideoMessageTriggerModal from './VideoMessageTriggerModal';

interface Props {
  setConnection?(isConnectionInitiated: boolean): void;
  userId: string;
}

interface State {
  isVideoFootageModalOpen: boolean;
  messages: VideoRequestAlert[];
  videoDownloadJobId?: number;
}

class VideoMessageAlert extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isVideoFootageModalOpen: false,
      messages: [],
      videoDownloadJobId: undefined,
    };
  }

  componentDidMount() {
    const { userId } = this.props;

    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Trace)
      .withUrl(`${getCommunicationApiUrl()}/hubs/videoDownloadHub?userId=${userId}`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: getAuthToken,
      })
      .withAutomaticReconnect()
      .build();

    connection.on('Available', message => {
      const storageKey = getItem(VIDEO_REQUEST_ALERT_KEY);
      const videoRequestAlertMessages: VideoRequestAlert[] = storageKey ? JSON.parse(storageKey) : [];

      videoRequestAlertMessages.push(message);
      setItem(VIDEO_REQUEST_ALERT_KEY, JSON.stringify(videoRequestAlertMessages));
    });

    connection.start().catch(() => {
      connection.stop();
    });

    setInterval(() => {
      const storageKey = getItem(VIDEO_REQUEST_ALERT_KEY);
      const videoRequestAlertMessages = storageKey ? JSON.parse(storageKey) : undefined;
      const { messages } = this.state;

      videoRequestAlertMessages &&
        videoRequestAlertMessages.forEach((message: VideoRequestAlert) => {
          const currentMessages = messages.filter(
            videoRequestAlertMessage => videoRequestAlertMessage.videoDownloadJobId === message.videoDownloadJobId,
          );
          if (!currentMessages.length) {
            this.setState({
              messages: [...messages, message],
            });
          }
        });
    }, 1000);
  }

  closeVideoRequestAlert = (videoDownloadJobId?: number) => {
    const { messages } = this.state;
    const storageKey = getItem(VIDEO_REQUEST_ALERT_KEY);
    const videoRequestAlertMessages: VideoRequestAlert[] = storageKey ? JSON.parse(storageKey) : [];

    const newMessages = messages.filter(message => message.videoDownloadJobId !== videoDownloadJobId);
    const newMessagesFromStorage = videoRequestAlertMessages.filter(
      message => message.videoDownloadJobId !== videoDownloadJobId,
    );

    this.setState({
      messages: newMessages,
    });

    setItem(VIDEO_REQUEST_ALERT_KEY, JSON.stringify(newMessagesFromStorage));
  };

  toggleVideoFootageModal = (isOpen: boolean, videoDownloadJobId?: number) => {
    this.setState({
      isVideoFootageModalOpen: isOpen,
      videoDownloadJobId,
    });

    this.closeVideoRequestAlert(videoDownloadJobId);
  };

  render() {
    const { isVideoFootageModalOpen, messages, videoDownloadJobId } = this.state;

    return (
      <>
        <SOSAlertWrapper zIndex={6001}>
          {messages.map((message, index) => (
            <SOSAlertContent key={index}>
              <SOSIcon>
                <Icon icon="exclamationTriangleV2" height="22" />
              </SOSIcon>
              <Title isSuccess>{translate('routes.videoRequest.successMessage')}</Title>
              <SOSAlertInner isSuccess>
                <Name>
                  {message.vehicleName}: {moment(message.startDate).format(timeFormat)} -{' '}
                  {moment(message.endDate).format(timeFormat)}
                </Name>
                <Name margin="small no no no">
                  <VideoMessageTriggerModal
                    onOpenVideoFootageModal={() => this.toggleVideoFootageModal(true, message.videoDownloadJobId)}
                  />
                </Name>
              </SOSAlertInner>
              <SOSIcon alignRight withClose>
                <Icon icon="exclamationTriangleV2" height="22" />
              </SOSIcon>
              <ModalClose margin="xxSmall" onClick={() => this.closeVideoRequestAlert(message.videoDownloadJobId)}>
                <ModalCloseIcon />
              </ModalClose>
            </SOSAlertContent>
          ))}
        </SOSAlertWrapper>

        {isVideoFootageModalOpen && (
          <VideoMessageModalResolver
            videoDownloadJobId={videoDownloadJobId}
            closeModal={() => this.toggleVideoFootageModal(false)}
          />
        )}
      </>
    );
  }
}

export default VideoMessageAlert;
