import { Switch } from 'react-router';
import { connect } from 'react-redux';

import AuthRoute from './AuthRoute';
import GuestRoute from './GuestRoute';
import {
  LoginPageResolver,
  CreateUserAccountPage,
  RequestPasswordResetPage,
  PasswordResetEmailSentPage,
  LogoutPage,
  ChangePasswordPage,
} from './pages';
import ResetPasswordPageResolver from './pages/ResetPasswordPageResolver';
import { AppState } from 'src/store';
import { Dictionary } from 'src/common/interfaces/Dictionary';

const Account = (lastLocations: Dictionary<string>) => (
  <Switch>
    <GuestRoute exact path="/account/login" component={LoginPageResolver} lastLocations={lastLocations} />
    <GuestRoute exact path="/account/create-user-account" component={CreateUserAccountPage} />
    <GuestRoute exact path="/account/forgot-password" component={RequestPasswordResetPage} />
    <GuestRoute exact path="/account/password-reset-email-sent" component={PasswordResetEmailSentPage} />
    <GuestRoute exact path="/account/reset-password" component={ResetPasswordPageResolver} />
    <GuestRoute exact path="/account/create-password" component={ResetPasswordPageResolver} />
    <AuthRoute exact path="/account/logout" component={LogoutPage} />
    <GuestRoute exact path="/account/change-password" component={ChangePasswordPage} />
  </Switch>
);

const mapStateToProps = (state: AppState) => ({
  lastLocations: state.core.lastLocations,
});

export default connect(mapStateToProps)(Account);
