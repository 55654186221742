import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import history from '../../../core/services/history';
import { Resolver } from '../../../core/components';
import { currentUserIdSelector, currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { PageLoading } from '../../../common/components/styled';
import { loadChangeRequestServiceOptions, loadServiceChangeRequests } from '../../ducks';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks/dispatchOpportunitiesMenuCount';
import { loadHolidays } from '../../../common/ducks/holidays';
import { authenticateToken } from '../../../fleet/ducks';
import ServiceChangeRequestsPage from './ServiceChangeRequestsPage';
import translate from '../../../core/services/translate';
import { DuckFunction } from '../../../contracts/ducks';
import { AppState } from '../../../store';
import { FullStory } from '../../../fullstory';
import { YES } from '../../../fleet/constants/status';
interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  userId: string;
  vendorId: number;
  authenticateToken: DuckFunction<typeof authenticateToken>;
  loadChangeRequestServiceOptions: DuckFunction<typeof loadChangeRequestServiceOptions>;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadHolidays: DuckFunction<typeof loadHolidays>;
  loadServiceChangeRequests: DuckFunction<typeof loadServiceChangeRequests>;
}

class ServiceChangeRequestsPageResolver extends PureComponent<Props> {
  loadDependancies = () => {
    const {
      authenticateToken,
      loadChangeRequestServiceOptions,
      loadDispatchOpportunitiesCount,
      loadHolidays,
      loadServiceChangeRequests,
      location: { search },
      match: {
        params: { token },
      },
      userId,
      vendorId,
    } = this.props;

    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    if (token) {
      FullStory.setTokenBasedUserInfo(token);
    }

    return token
      ? authenticateToken(token).then(response => {
          if (!response || response.isVPortalLoginRequired) {
            history.push({ pathname: '/account/logout', state: { from: history.location } });
          } else {
            if (response) {
              FullStory.setTokenBasedVendorInfo(response);
            }
            return Promise.all([
              loadChangeRequestServiceOptions(),
              loadHolidays(),
              loadServiceChangeRequests(userId, token, vendorId, startDate, endDate, isActionRequired),
              loadDispatchOpportunitiesCount(userId, vendorId, token),
            ]);
          }
        })
      : Promise.all([
          loadChangeRequestServiceOptions(),
          loadHolidays(),
          loadServiceChangeRequests(userId, token, vendorId, startDate, endDate, isActionRequired),
        ]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('opportunity.serviceChangeRequests')}</DocumentTitle>
        <Resolver
          successComponent={ServiceChangeRequestsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependancies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  authenticateToken,
  loadChangeRequestServiceOptions,
  loadDispatchOpportunitiesCount,
  loadHolidays,
  loadServiceChangeRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceChangeRequestsPageResolver);
