import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { findIndex } from 'lodash-es';

import {
  getNotesByToken as doGetNotesByToken,
  getNotesByUserId as doGetNotesByUserId,
  addNotesByToken as doAddNotesByToken,
  addNotesByUserId as doAddNotesByUserId,
} from '../services/loadOpenDispatches';
import { NotesState, AddNoteBody, UserNotesResponse } from '../interfaces/RubiconDispatchesNotes';

//  Actions
const START_LOAD = 'fleet/notes/START_LOAD';
const COMPLETE_LOAD = 'fleet/notes/COMPLETE_LOAD';
const FAIL_LOAD = 'fleet/notes/FAIL_LOAD';
const FAIL_SAVE = 'fleet/notes/FAIL_SAVE';
const START_SAVE = 'fleet/notes/START_SAVE';
const COMPLETE_SAVE = 'fleet/notes/COMPLETE_SAVE';
const SHOW_MODAL = 'fleet/notes/SHOW_MODAL';
const HIDE_MODAL = 'fleet/notes/HIDE_MODAL';
const ADD_FRESH_NOTE = 'fleet/notes/ADD_FRESH_NOTE';
const REMOVE_FRESH_NOTE = 'fleet/notes/REMOVE_FRESH_NOTE';

//  Initial State
const initialState: NotesState = {
  isLoading: false,
  isReload: false,
  isSaving: false,
  notes: [],
  reasonCodes: [],
};

//  Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          notes: action.data.notes,
          reasonCodes: action.data.reasonCodes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          notes: [],
          reasonCodes: [],
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isReload: true,
          isSaving: false,
        },
      });

    case SHOW_MODAL:
      return update(state, {
        $merge: {
          modal: {
            workOrder: action.workOrder,
            serviceDate: action.serviceDate,
            title: action.title,
            subtitle: action.subtitle,
            isWorkOrderNote: action.isWorkOrderNote,
          },
        },
      });

    case HIDE_MODAL:
      return update(state, {
        $merge: {
          modal: null,
          isReload: false,
        },
      });

    case ADD_FRESH_NOTE: {
      if (!state.modal) {
        return state;
      }

      return update(state, {
        notes: {
          $push: [
            {
              userNote: '',
              reasonCode: 'OTH',
              createdByUserID: 'vendorportal',
              workOrder: state.modal.workOrder,
              serviceDate: state.modal.serviceDate,
              isFresh: true,
            },
          ],
        },
      });
    }

    case REMOVE_FRESH_NOTE: {
      if (action.index === -1) {
        return state;
      }
      const index = findIndex(state.notes, { isFresh: true });
      return update(state, {
        notes: {
          $splice: [[index, 1]],
        },
      });
    }

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (data: UserNotesResponse) => ({
  type: COMPLETE_LOAD,
  data,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const showModal = ({
  workOrder,
  serviceDate,
  title,
  subtitle,
  isWorkOrderNote,
}: {
  workOrder: string;
  serviceDate: string;
  title: string;
  subtitle?: string;
  isWorkOrderNote?: boolean;
}) => ({
  type: SHOW_MODAL,
  workOrder,
  serviceDate,
  title,
  subtitle,
  isWorkOrderNote,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const addFreshNote = () => ({
  type: ADD_FRESH_NOTE,
});

export const removeFreshNote = (index: number) => ({
  type: REMOVE_FRESH_NOTE,
  index,
});

export const getNotes =
  ({
    token,
    userId,
    vendorId,
    workOrder,
    serviceDate,
  }: {
    workOrder: string;
    serviceDate: Date | string;
    token?: string;
    userId?: string;
    vendorId?: number;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());

    if (token) {
      const getNotesPromise = doGetNotesByToken(token, workOrder, serviceDate);

      getNotesPromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));

      return getNotesPromise;
    } else if (userId && vendorId) {
      const getNotesByUserIdPromise = doGetNotesByUserId(userId, vendorId, workOrder, serviceDate);

      getNotesByUserIdPromise.then(data => dispatch(completeLoad(data))).catch(() => dispatch(failLoad()));

      return getNotesByUserIdPromise;
    }
  };

export const addNotes =
  ({ body, token, userId, vendorId }: { body: AddNoteBody; token?: string; userId?: string; vendorId?: number }) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());

    if (token) {
      const saveNotesContractPromise = doAddNotesByToken(body, token);

      saveNotesContractPromise.then(data => dispatch(completeSave())).catch(() => dispatch(failSave()));

      return saveNotesContractPromise;
    } else if (userId && vendorId) {
      const saveNotesByUserIdContractPromise = doAddNotesByUserId({ body, userId, vendorId });

      saveNotesByUserIdContractPromise.then(data => dispatch(completeSave())).catch(() => dispatch(failSave()));

      return saveNotesByUserIdContractPromise;
    }
  };
