import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const NEW = 0;
export const DRAFT = 1;
export const PUBLISHED = 2;

export type RTBTemplateStatuses = 0 | 1 | 2;

export const RTB_TEMPLATE_STATUSES = [
  { id: PUBLISHED, name: translate('routeTemplateBuilder.statuses.published'), color: '#00a599' },
  { id: DRAFT, name: translate('routeTemplateBuilder.statuses.draft'), color: '#ffa452' },
  { id: NEW, name: translate('routeTemplateBuilder.statuses.new'), color: '#8CC8FF' },
];

export const RTB_TEMPLATE_STATUSES_MAP = mapKeys(RTB_TEMPLATE_STATUSES, 'id');

export const UNASSIGNED_FILTER = 'unassigned';
export const ASSIGNED_FILTER = 'assigned';
export const ALL_STOPS_FILTER = 'all';

export const SOURCE_UNIQUE_KEY = 'routeTemplateBuilderServices';

export const ROUTE_TEMPLATE_BUILDER_PAGE_FORM = 'routeTemplateBuilderPage';

export const ROUTE_TEMPLATE_BUILDER_WORK_SESSION_FILTERS_FORM = 'routeTemplateBuilderWorkSessionFilters';

export const UNASSIGNED_COLOR = '#737373';
export const UNASSIGNED_COLOR_SATELLITE = '#ffffff';

export enum WorkSessionViews {
  Map = 'map',
  List = 'list',
}
