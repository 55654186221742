import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadStopMapDetails } from 'src/routes/ducks';
import { MapGLPopupWrapper, MapGLPopupLoading } from 'src/common/components/map/MapGLPopup';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import RouteSequenceStopPopup from './RouteSequenceStopPopup';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { find } from 'lodash-es';
import RouteTemplateSequenceStopPopup from './RouteTemplateStopPopup';

const Loading: React.FC<{ latitude: number; longitude: number }> = ({ latitude, longitude }) => {
  const dispatch = useDispatch();

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={latitude}
      longitude={longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopupLoading />
    </MapGLPopupWrapper>
  );
};

type Props = {
  routeStops?: RouteStop[];
  routeLocations?: RouteLocation[];
  isTemplate?: boolean;
};

export default function RouteSequenceStopPopupResolver({ routeStops, routeLocations, isTemplate }: Props) {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const routeStop: RouteStop | undefined = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeStops) {
      return undefined;
    }
    return find(routeStops, routeStop => routeStop.id === selectedFeature.id);
  }, [selectedFeature, routeStops]);

  const routeLocation: RouteLocation | undefined = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.routeStops) {
      return undefined;
    }
    return find(routeLocations, routeLocation => routeLocation.location.id === selectedFeature.id);
  }, [selectedFeature, routeLocations]);

  if (isTemplate) {
    if (!routeLocation) return null;

    return <RouteTemplateSequenceStopPopup routeLocation={routeLocation} />;
  } else {
    if (!routeSummary || !routeStop) return null;

    const loadDependencies = () => {
      if (!selectedFeature) return Promise.reject();
      const dependencies = [loadStopMapDetails(vendorId, routeSummary.routeId, selectedFeature.id)(dispatch)];
      return Promise.all(dependencies);
    };

    return (
      <TypedResolver
        key={routeStop.id}
        successComponent={RouteSequenceStopPopup}
        successProps={{ routeStop }}
        loadingComponent={Loading}
        loadingProps={{ latitude: routeStop.displayLatitude, longitude: routeStop.displayLongitude }}
        resolve={loadDependencies}
      />
    );
  }
}
