import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { uploadInvoices as doUploadInvoices } from '../services/invoiceUpload';


//  Actions
const START_LOAD = 'opportunity/invoiceUpload/START_LOAD';
const COMPLETE_LOAD = 'opportunity/invoiceUpload/COMPLETE_LOAD';
const FAIL_LOAD = 'opportunity/invoiceUpload/FAIL_LOAD';
const PROGRESS_UPDATE = 'opportunity/invoiceUpload/PROGRESS_UPDATE';

//  Initial State
const intialState = {
  isLoading: false,
  progress: 0,
  data: undefined,
  errorMessage: undefined,
};

//  Reducer
export const reducer = (state = intialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          data: action.invoices,
          progress: 0
        },
      });

      case PROGRESS_UPDATE:
        return update(state, {
          $merge: {
            isLoading: true,
            progress: action.progress,
          },
        });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          data: undefined,
        },
      });

    default:
      return state;
  }
};

//  Action Creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (invoices: any) => ({
  type: COMPLETE_LOAD,
  invoices,
});

const updateProgress = (progress: number) => ({
  type: PROGRESS_UPDATE,
  progress,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const uploadInvoices = (invoicesObj:any, vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  return doUploadInvoices(invoicesObj,vendorId,dispatch,updateProgress )
    .then((invoices: any) => dispatch(completeLoad(invoices)))
    .catch(() => dispatch(failLoad()));
};

