import { useMemo, useState } from 'react';

import { filter, map, size } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { getFormValues, reset } from 'redux-form';

import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { useSelector } from 'src/core/hooks/useSelector';
import { PermissionGuard } from '../../../account/components';
import { PageActions, PageContent, PageHeader, PageTitle, PageTitleContainer } from '../../../common/components/styled';
import { MapDragHandle, Table } from '../../../core/components';
import { Button, MapContainer, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { TABLE_ROW_HEIGHT_LARGEST } from '../../../core/constants';
import confirm from '../../../core/services/confirm';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { multiWordAndSearch } from '../../../core/services/search';
import translate from '../../../core/services/translate';
import { deleteFacility } from '../../ducks';
import { Resizable } from 're-resizable';
import { setRouteMapViewport } from 'src/routes/ducks';
import FacilitiesSearchForm, { FACILITIES_SEARCH_FORM } from '../forms/FacilitiesSearchForm';
import ContainersRelocationModalResolver from '../modal/ContainersRelocationModalResolver';
import FacilityEditorModalResolver from '../modal/FacilityEditorModalResolver';
import { FacilitiesPageTableRow } from './';
import { FLEET_FACILITIES_CREATE } from './../../../account/constants/permissions';
import FacilitiesMapGL from './facilitiesPageSections/FacilitiesMapGL';
import { ContainersRelocationFormValues } from '../forms/ContainersRelocationForm';

const FacilitiesPage = () => {
  const [isFacilityEditorModalOpen, setIsFacilityEditorModalOpen] = useState(false);
  const [isContainersRelocationModalOpen, setIsContainersRelocationModalOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<any>(undefined);
  const { facilities, isLoading } = useSelector(state => state.fleet.facilities);
  const dispatch = useDispatch();

  const { facilitySearchTerm, facilityStatus, facilityTypeDropdown } =
    (useSelector(getFormValues(FACILITIES_SEARCH_FORM)) as {
      facilitySearchTerm: string | undefined;
      facilityStatus: boolean | null;
      facilityTypeDropdown: TechnicalType | null;
    }) || {};

  const onDeleteFacility = async (
    facilityId: number,
    isDefaultStartLocation: boolean,
    hasContainerAssociated: boolean,
    event: any,
  ) => {
    event.stopPropagation();

    if (hasContainerAssociated) {
      if (isDefaultStartLocation) {
        const message = translate('facilities.alertMessages.confirmDeleteFacilityWithDefaultVehicleStartLocation');
        if (!(await confirm(message))) {
          return;
        }
      }
      openContainersRelocationModal(facilityId);
    } else {
      const message = isDefaultStartLocation
        ? translate('facilities.alertMessages.confirmDeleteFacilityWithDefaultVehicleStartLocation')
        : translate('facilities.alertMessages.confirmDeleteFacility');
      if (!(await confirm(message))) {
        return;
      }
      try {
        await deleteFacility(facilityId)(dispatch);
        createSuccessNotification(translate('facilities.alertMessages.facilityDeleted'));
      } catch (error) {
        createErrorNotification(translate('facilities.alertMessages.deleteFacilityError'));
        return;
      }
    }
  };

  const onDeleteFacilityWithRelocation = async ({ relocatedContainers }: ContainersRelocationFormValues) => {
    try {
      const containers = map(relocatedContainers, ({ containerNumber, ...rest }) => rest);

      await deleteFacility(selectedFacility, containers)(dispatch);
      createSuccessNotification(translate('facilities.alertMessages.facilityDeleted'));
      setIsContainersRelocationModalOpen(false);
    } catch (error) {
      createErrorNotification(translate('facilities.alertMessages.deleteFacilityError'));
      return;
    }
  };

  const filteredFacilities = useMemo(
    () =>
      filter(
        facilities,
        facility =>
          (facility.isActive === facilityStatus || facilityStatus == null) &&
          (!facilityTypeDropdown || facility.facilityType.id === facilityTypeDropdown) &&
          (!facilitySearchTerm ||
            multiWordAndSearch(facility.name, facilitySearchTerm) ||
            multiWordAndSearch(facility.address.line1, facilitySearchTerm)),
      ),
    [facilities, facilitySearchTerm, facilityStatus, facilityTypeDropdown],
  );

  const facilitiesTableRows = useMemo(
    () =>
      map(
        filteredFacilities,
        (
          {
            address,
            facilitySubType,
            facilityType,
            id,
            isActive,
            isDefaultStartLocation,
            name,
            locationId,
            hasContainerAssociated,
          },
          index,
        ) => ({
          address: address && address.line1,
          facilitySubType: facilitySubType && facilitySubType.technicalName,
          facilityType: facilityType && facilityType.technicalName,
          id,
          isActive,
          isDefaultStartLocation,
          location: address && address,
          locationNo: index + 1,
          locationId,
          name,
          hasContainerAssociated,
        }),
      ),
    [filteredFacilities],
  );

  const openFacilityEditorModal = (event: any, facilityId: number) => {
    event.stopPropagation();
    setSelectedFacility(facilityId);
    setIsFacilityEditorModalOpen(true);
  };

  const closeFacilityEditorModal = () => {
    setIsFacilityEditorModalOpen(false);
    setSelectedFacility(undefined);
  };

  const onTableRowCLick = (address: any) => {
    dispatch(
      setRouteMapViewport({
        latitude: address.latitude,
        longitude: address.longitude,
        zoom: 15,
      }),
    );
  };

  const onCancelFacilityEditorModal = async (formPristine: any) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    dispatch(reset('facilityEditor'));
    closeFacilityEditorModal();
  };

  const openContainersRelocationModal = (facilityId: number) => {
    setSelectedFacility(facilityId);
    setIsContainersRelocationModalOpen(true);
  };

  const closeContainersRelocationModal = () => {
    setIsContainersRelocationModalOpen(false);
    setSelectedFacility(undefined);
  };

  const facilitiesTableCells = [
    { name: 'locationNo', label: translate('facilities.locationNumber'), width: '10%', sortable: true },
    { name: 'name', label: translate('common.address'), width: '25%', sortable: true },
    { name: 'facilityType', label: translate('facilities.facilityType'), width: '20%', sortable: true },
    { name: 'locationId', label: translate('facilities.facilityId'), width: '20%', sortable: true },
    { name: 'isActive', label: translate('facilities.facilityStatus'), width: '15%', sortable: true },
    { name: 'options', label: translate('common.options'), width: '10%', align: 'right' },
  ];

  const virtualizedProps = {
    itemSize: TABLE_ROW_HEIGHT_LARGEST,
    height: Math.min(facilities.length * TABLE_ROW_HEIGHT_LARGEST, TABLE_ROW_HEIGHT_LARGEST * 6) || 1,
  };

  return (
    <PageContent>
      <PageHeader>
        <PageTitleContainer>
          <PageTitle>{translate('facilities.facilities')}</PageTitle>
        </PageTitleContainer>
        <PageActions>
          <PermissionGuard permission={FLEET_FACILITIES_CREATE}>
            <Button onClick={openFacilityEditorModal} color="primary" margin="no" id="create-facility-button">
              {translate('facilities.createFacility')}
            </Button>
          </PermissionGuard>
        </PageActions>
      </PageHeader>
      <Panel>
        <PanelSectionGroup isLoading={isLoading}>
          <FacilitiesSearchForm />
          {!!size(facilities) && (
            <PanelSection>
              <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
                <MapContainer>
                  <FacilitiesMapGL facilities={filteredFacilities} />
                </MapContainer>
              </Resizable>
            </PanelSection>
          )}

          {!!size(filteredFacilities) && (
            <PanelSection>
              <Table
                virtualized
                cells={facilitiesTableCells}
                rows={facilitiesTableRows}
                rowComponent={FacilitiesPageTableRow}
                rowProps={{
                  deleteFacility: onDeleteFacility,
                  editFacility: openFacilityEditorModal,
                  handleRowClick: onTableRowCLick,
                }}
                virtualizedProps={virtualizedProps}
                withClickableRows
                scrollMarker
              />
            </PanelSection>
          )}

          {!size(filteredFacilities) && <Message padding="sMedium">{translate('facilities.noFacilities')}</Message>}

          {!!isFacilityEditorModalOpen && (
            <FacilityEditorModalResolver
              onCancel={onCancelFacilityEditorModal}
              facilityId={selectedFacility}
              closeFacilityEditorModal={closeFacilityEditorModal}
            />
          )}
          {!!isContainersRelocationModalOpen && (
            <ContainersRelocationModalResolver
              facilityId={selectedFacility}
              closeModal={closeContainersRelocationModal}
              onSubmit={onDeleteFacilityWithRelocation}
              isDelete
            />
          )}
        </PanelSectionGroup>
      </Panel>
    </PageContent>
  );
};

export default FacilitiesPage;
