import { http } from '../../core/services/http';

export const loadRouteRecommendations = (vendorId: number) =>
  http
    .get('/routeRecommendation', { params: { vendorId } })
    .then(res => res.data)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

interface RecommendationTemplateAssignment {
  serviceContractId: number;
  serviceContractPosition: string;
  routeTemplates: [
    {
      routeTemplateId: number;
      serviceContractRouteTemplateRecommendationId?: number;
    },
  ];
}

export const saveRouteRecommendationAssignments = (
  assignments: RecommendationTemplateAssignment[],
  shouldRecreateRoutes: boolean,
) =>
  http.post('/routeRecommendation', assignments, {
    params: { shouldRecreateRoutes },
  });

export const loadServiceContractRecommendation = (serviceContractId: number, schedulerId: number, vendorId: number) =>
  http.get('/routeRecommendation/serviceContract', { params: { serviceContractId, schedulerId, vendorId } });

interface ServiceContractRecommendationRequestBody {
  vendorId: number;
  serviceContractId: number;
  wasteMaterialTypeId: number;
  serviceTypeId: number;
  binLatitude: number;
  binLongitude: number;
  schedulerId: number;
}

export const generateServiceContractRecommendation = (
  serviceContractRequestBody: ServiceContractRecommendationRequestBody,
) => http.post('/routeRecommendation/serviceContract', serviceContractRequestBody);
