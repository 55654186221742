import React, { useMemo } from 'react';
import { TableCell, TableRow } from '../../../../core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { PickupType } from 'src/routes/interfaces/PickupTypes';
import translate from 'src/core/services/translate';

interface Props {
  problemTypeId: number,
  vehicleTypeId: number,
  serviceTypeId: number,
  pickupTypeId: number,
  equipmentTypeId: number,
  equipmentSizeId: number,
  reasonCodeId?: number,
  isAdhoc: boolean,
  useScheduledDate: boolean,
  createServiceContractIfNotFound: boolean,
  responseCode: string,
  wasteMaterialTypeId: string,
  cellWidths: { [key: string]: string };
}

const CityWorksServiceRequestErrorsTableRow: React.FC<Props> = ({
  problemTypeId,
  vehicleTypeId,
  serviceTypeId,
  pickupTypeId,
  wasteMaterialTypeId,
  equipmentTypeId,
  equipmentSizeId,
  // reasonCodeId,
  isAdhoc,
  useScheduledDate,
  createServiceContractIfNotFound,
  responseCode,
  cellWidths
}) => {

  const vehicleTypes = useSelector(state => state.fleet.vehicleTypes);
  const equipmentTypes = useSelector(state => state.common.equipmentTypes.equipmentTypes);
  const problemTypes = useSelector(state => state.vendors.cityWorks.problemTypes);
  const wasteMaterialTypes = useSelector(state => state.common.wasteMaterialTypes.wasteMaterialTypes);
  const serviceTypes = useSelector(state => state.common.serviceTypes.serviceTypes);
  const pickupTypes = useSelector(state => state.routes.pickupTypes.pickupTypes);
  const equipmentSizes = useSelector(state => state.common.equipmentSizes.equipmentSizes);


  const vehicleTypeName = useMemo(() => {
    return vehicleTypes.vehicleTypes.find(x => x?.id === vehicleTypeId)?.name || "-";
  }, [vehicleTypes, vehicleTypeId]);

  const problemTypeName = useMemo(() => {
    return (problemTypes && problemTypes.find(x => x?.id === problemTypeId))?.probDesc || "-";
  }, [problemTypes, problemTypeId]);

  const serviceTypeName = useMemo(() => {
    return (serviceTypeId && serviceTypes.find(x => x?.id === serviceTypeId)?.name) || "-";
  }, [serviceTypes, serviceTypeId]);

  const wasteMaterialName = useMemo(() => {
    return (wasteMaterialTypeId && (wasteMaterialTypes as TechnicalType[]).find(x => x.id === Number(wasteMaterialTypeId))?.name) || "-";
  }, [wasteMaterialTypes, wasteMaterialTypeId]);

  const equipmentTypeName = useMemo(() => {
    return (equipmentTypeId && equipmentTypes.find(x => x?.id === equipmentTypeId)?.name) || "-";
  }, [equipmentTypes, equipmentTypeId]);

  const equipmentSizesName = useMemo(() => {
    return (equipmentSizeId && equipmentSizes.find(x => x?.id === equipmentSizeId)?.name) || "-";
  }, [equipmentSizes, equipmentSizeId]);

  const pickupDescription = useMemo(() => {
    const pickupTypesSelection = (vehicleTypeId && (pickupTypes as PickupType[]).find(x => x.vehicleTypeId === vehicleTypeId)) || undefined;
    return (pickupTypesSelection?.pickupTypes as TechnicalType[]).find(x => x.id === pickupTypeId)?.name || "-";
  }, [pickupTypes, vehicleTypeId, pickupTypeId]);

  return (
    <TableRow>
      <TableCell width={cellWidths.problemTypeId}>{problemTypeName}</TableCell>
      <TableCell width={cellWidths.vehicleTypeId}>{`${vehicleTypeName} - ${serviceTypeName}`}</TableCell>
      <TableCell width={cellWidths.pickupTypeId}>{pickupDescription}</TableCell>
      <TableCell width={cellWidths.wasteMaterialTypeId}>{wasteMaterialName}</TableCell>
      <TableCell width={cellWidths.equipmentTypeId}>{`${equipmentTypeName} - ${equipmentSizesName}`}</TableCell>
      {/* <TableCell width="10%">{reasonCodeName}</TableCell> Might add this later */}
      <TableCell width={cellWidths.useScheduledDate}>{useScheduledDate ? translate('common.yes') : translate('common.no')}</TableCell>
      <TableCell width={cellWidths.isAdhoc}>{isAdhoc ? translate('common.yes') : translate('common.no')}</TableCell>
      <TableCell align='center' width={cellWidths.createServiceContractIfNotFound}>{createServiceContractIfNotFound ? translate('common.yes') : translate('common.no')}</TableCell>
      <TableCell width={cellWidths.responseCode}>{responseCode}</TableCell>
    </TableRow>
  )
};

export default CityWorksServiceRequestErrorsTableRow;

