import React from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from '../../../store';
import { BulkDeclineAwardsFormData } from '../../interfaces/Opportunities';
import BulkDeclineAwardsForm from '../forms/BulkDeclineAwardsForm';
import { BulkDeclineAwardsObject } from '../../interfaces/Opportunities';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { closeMobilePageDetailOverlay } from 'src/core/ducks/mobilePage';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { Grid, GridColumn, ModalTitle, ModalSubtitle } from '../../../core/components/styled';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { loadOpportunities, updateBulkAwards } from '../../ducks/opportunities';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { useIsMobileView } from 'src/utils/hooks';
import { YES, NO } from '../../constants/status';
interface ComponentProps extends RouteComponentProps {
  selectedOpenAwardsItemIds: number[];
  onCancel(pristine: boolean, successful?: boolean): void;
}
interface ReduxProps {
  closeMobilePageDetailOverlay: DuckFunction<typeof closeMobilePageDetailOverlay>;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadOpportunities: DuckFunction<typeof loadOpportunities>;
  updateBulkAwards: DuckFunction<typeof updateBulkAwards>;
}

type Props = ComponentProps & ReduxProps;

const BulkDeclineAwardsModal: React.FC<Props> = props => {
  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);
  const { selectedOpenAwardsItemIds } = props;
  const { onCancel } = props;

  const handleSubmitBulkDeclineAwards = (formData: BulkDeclineAwardsFormData) => {
    const {
      location: { search },
      loadDispatchOpportunitiesCount,
      loadOpportunities,
      updateBulkAwards,
      onCancel,
      closeMobilePageDetailOverlay,
    } = props;

    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES;

    let bulkAwardsObj: BulkDeclineAwardsObject = {};
    bulkAwardsObj.itemIds = selectedOpenAwardsItemIds;
    bulkAwardsObj.Response = NO;
    bulkAwardsObj.RespondedNo = formData;

    const promise = updateBulkAwards(bulkAwardsObj, userId, vendorId, isActionRequired, startDate, endDate);

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        loadDispatchOpportunitiesCount(userId, vendorId, '');
        loadOpportunities(userId, vendorId, isActionRequired, startDate, endDate);
        createSuccessNotification(translate('opportunity.opportunitySave'));
        onCancel(true, true);
        closeMobilePageDetailOverlay();
      })
      .catch(() => {
        createErrorNotification(translate('opportunity.opportunitySaveError'));
      });
  };
  const isMobile = useIsMobileView();

  return (
    <Modal size="small">
      <ModalTitle>{translate('opportunity.declineService')}</ModalTitle>
      <ModalSubtitle />
      <Grid centered>
        <GridColumn size={isMobile ? '12/12' : '10/12'}>
          <BulkDeclineAwardsForm onSubmit={handleSubmitBulkDeclineAwards} onCancel={onCancel}></BulkDeclineAwardsForm>
        </GridColumn>
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isSaving: state.opportunity.opportunities.isSaving,
});

const mapDispatchToProps = {
  closeMobilePageDetailOverlay,
  loadOpportunities,
  loadDispatchOpportunitiesCount,
  updateBulkAwards,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkDeclineAwardsModal));
