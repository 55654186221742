import * as FS from '@fullstory/browser';
import { merge } from 'lodash-es';

import { User } from './account/ducks/login';
import { VENDOR_TYPES } from './vendors/constants';
import { Vendor } from './vendors/interfaces/Vendors';

interface UserVars {
  authType: 'LOGIN' | 'TOKEN';
  displayName: string;
  email: string;
  gusId: string;
  roles?: string[];
  userId: string;
  vendorId: number;
  vendorName: string;
  vendorType: string;
}

interface TokenHauler {
  errorMessage?: any;
  haulerId: string;
  haulerName: string;
  isVPortalLoginRequired: boolean;
  vendId: string;
}

class FullStoryClass {
  private userVars: any = {};

  private _setUserVars = (vars: Partial<UserVars>) => {
    merge(this.userVars, vars);
    FS.setUserVars(this.userVars);
  };

  setUserVars = (user: User) => {
    const vars: Partial<UserVars> = {
      userId: user.userId,
      displayName: user.name,
      email: user.email,
      roles: user.roles,
      authType: 'LOGIN',
    };
    this._setUserVars(vars);
    window.Rubicon.user.setUserInfo(vars);
  };

  setTokenBasedUserInfo = (token: string) => {
    const vars: Partial<UserVars> = {
      userId: token,
      displayName: 'TOKEN USER',
      email: token,
      authType: 'TOKEN',
    };
    this._setUserVars(vars);
    window.Rubicon.user.setUserInfo(vars);
  };

  setTokenBasedVendorInfo = (vendor: TokenHauler) => {
    const vars: Partial<UserVars> = {
      gusId: vendor.haulerId,
      vendorName: vendor.haulerName,
      vendorType: 'RUBICON_PRO',
    };
    this._setUserVars(vars);
    window.Rubicon.vendor.setVendorInfo(vars);
  };

  setVendorVars = (vendor: Vendor) => {
    const vars: Partial<UserVars> = {
      gusId: vendor.gusId,
      vendorName: vendor.name,
      vendorId: vendor.id,
      vendorType: VENDOR_TYPES[vendor.vendorTypeId].technicalName,
    };
    this._setUserVars(vars);
    window.Rubicon.vendor.setVendorInfo(vars);
  };
}

export const FullStory = new FullStoryClass();
