import { BareButtonStyle, InputStyle } from 'src/core/components/styled';
import { loading } from 'src/core/styles/loading';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

interface MessagingContainerDivProps {
  offsetX: number;
  offsetY: number;
}

export const MessagingContainerDiv = styled.div<MessagingContainerDivProps>`
  position: fixed;
  bottom: ${props => props.offsetY}px;
  right: ${props => props.offsetX}px;
  z-index: 5999;
  background: white;
  border-radius: 5px;
  transition: all 0.5s;
`;

export const DriverMessagingContainerDiv = styled.div`
  box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 450px;
  right: 95px;
  z-index: 6000;
  min-width: 200px;
  background: white;
  border-radius: 5px;
  transition: all 0.5s;
`;

export const MessagingHeaderContainer = styled.div`
  display: flex;
  font-weight: bolder;
  font-size: 18px;
  border-bottom: 1px solid lightgray;
  align-items: center;
  padding: 0 12px;
`;

export const MessagingHeader = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const MessagesToggler = styled.button<{ isOpen: boolean; isLoading: boolean; numberOfUnreadMessages: number }>`
  ${BareButtonStyle}
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px;
  width: 100%;
  justify-content: center;

  ${p =>
    p.isLoading &&
    css`
      &:before {
        left: 5px;
        position: absolute;
        content: '';
        ${loading('12px')}
      }
    `}

  ${p =>
    !p.isOpen &&
    p.numberOfUnreadMessages &&
    !p.isLoading &&
    css`
      &:before {
        background: ${p.theme.brandPrimary};
        border-radius: 15px;
        color: white;
        content: '${p.numberOfUnreadMessages > 99 ? '99+' : p.numberOfUnreadMessages}';
        left: 5px;
        padding: 5px;
        position: absolute;
        width: 25px;
        font-size: 10px;
      }
    `}

  ${p =>
    p.isOpen &&
    css`
      font-weight: bolder;
      justify-content: flex-start;
      span {
        font-size: 18px;
        flex: 1;
      }
      svg {
        color: ${p.theme.brandPrimary};
      }
    `}
`;

export const MessageContainer = styled.div<{ isReceived: boolean }>`
  width: 80%;
  background: ${p => (p.isReceived ? p.theme.grayLight : p.theme.brandPrimary)};
  align-self: ${p => (p.isReceived ? 'flex-start' : 'flex-end')};
  color: ${p => (p.isReceived ? 'black' : 'white')};
  margin: 6px 12px;
  padding: 10px;
  border-radius: 10px;
  white-space: break-spaces;
  word-wrap: break-word;
`;

export const ReadReceiptsContainer = styled.div`
  color: black;
  text-align: left;

  & > div {
    margin-bottom: 10px;
  }
`;

export const ReadReceiptsList = styled.ul`
  list-style: none;
`;

export const ReadReceiptRow = styled.li`
  width: 100%;
  white-space: nowrap;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const MessageDetailsContainer = styled.div`
  font-size: 10px;
  margin-top: 10px;
  display: flex;
`;

export const MessageDate = styled.div`
  flex: 1;
`;

export const MessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
`;

export const MessagingContentContainer = styled.div<{ isLoading?: boolean }>`
  position: relative;
  width: 700px;
  height: 300px;
  overflow-y: auto;

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -16px 0 0 -16px;
        ${loading('24px')};
      }
    `};
`;

export const ThreadsList = styled.ul`
  list-style: none;
`;

export const ThreadItem = styled.li`
  &:nth-child(odd) {
    background-color: ${p => p.theme.grayLight};
  }
`;

export const ThreadItemContent = styled.button<{ hasUnreadMessages: boolean }>`
  ${BareButtonStyle}
  text-align: left;
  position: relative;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  width: 100%;
  ${p =>
    p.hasUnreadMessages &&
    css`
      font-weight: bolder;
      &:before {
        z-index: 2;
        position: absolute;
        left: 10px;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: ${p => p.theme.brandPrimary};
      }
    `}
`;

export const MessagingActions = styled.div`
  padding: 12px;
  text-align: center;
  border-top: 1px solid lightgray;
`;

export const SendMessageContainer = styled.div`
  margin: 0;
`;

export const MessageLengthInfo = styled.div`
  color: ${p => p.theme.grayDark};
  margin-bottom: 5px;
  text-align: left;
  white-space: nowrap;
  margin: 0 10px;
  padding-bottom: 7px;
`;

export const ResizableTextarea = styled(TextareaAutosize)`
  ${InputStyle};
`;

export const SendMessageInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  div {
    flex: 1;
  }
  textarea {
    resize: none;
  }
`;

export const ThreadItemTitle = styled.div`
  width: 30%;
  height: 100%;
`;

export const ThreadItemMessage = styled.div`
  width: 55%;
  height: 100%;
  word-wrap: break-word;
`;

export const ThreadItemDate = styled.div`
  width: 15%;
  height: 100%;
`;
