import { AnyAction } from 'redux';
import { omit } from 'lodash-es';
import { push } from 'connected-react-router';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { CustomerDetailsEditorFormValues } from 'src/customers/components/forms/CustomerDetailsEditorForm';
import { Customer } from 'src/customers/interfaces/Customers';
import { createCustomer, loadCustomer as doLoadCustomer, updateCustomer } from 'src/customers/services/customer';
import { STREET } from 'src/common/constants';

// Actions
const START_LOAD = 'customers/customer/START_LOAD';
export const COMPLETE_LOAD = 'customers/customer/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/customer/FAIL_LOAD';
const START_SAVE = 'customers/customer/START_SAVE';
export const COMPLETE_SAVE = 'customers/customer/COMPLETE_SAVE';
const FAIL_SAVE = 'customers/customer/FAIL_SAVE';
const RESET = 'customers/customer/RESET';
const SET_CURRENT_CUSTOMERS_PAGE_URL = 'customers/serviceHistory/SET_CURRENT_CUSTOMERS_PAGE_URL';

interface State {
  isLoading: boolean;
  isSaving: boolean;
  customer?: Customer;
  currentCustomersPageUrl?: string;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  isLoading: false,
  isSaving: false,
  customer: undefined,
  currentCustomersPageUrl: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          customer: action.customer,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          customer: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          customer: action.customer,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          ...initialState,
          currentCustomersPageUrl: state.currentCustomersPageUrl,
        },
      });

    case SET_CURRENT_CUSTOMERS_PAGE_URL:
      return update(state, {
        $merge: {
          currentCustomersPageUrl: action.currentCustomersPageUrl,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (customer: Customer) => ({
  type: COMPLETE_LOAD,
  customer,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (customer: Customer, saveType: 'Update' | 'Create') => ({
  type: COMPLETE_SAVE,
  customer,
  saveType,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadCustomer = (customerId: number, preventStartLoad?: boolean) => (dispatch: Dispatch) => {
  if (!preventStartLoad) {
    dispatch(startLoad());
  }
  const loadCustomerPromise = doLoadCustomer(customerId);
  loadCustomerPromise.then(customer => dispatch(completeLoad(customer))).catch(() => dispatch(failLoad()));
  return loadCustomerPromise;
};

export const saveCustomer =
  (customer: CustomerDetailsEditorFormValues, redirectToPage: boolean) => (dispatch: Dispatch) => {
    dispatch(startSave());

    const {
      customerBillSettingBillingAddress,
      customerBillSettingSendInvoiceByEmail,
      customerIsPOBoxAddress,
      customerPOBoxAddress,
      customerBillSetting,
    } = customer;

    const billAddressSame = customerBillSetting?.billAddressSame;

    const formData = omit(
      {
        ...customer,
        customerBillSetting: {
          ...customerBillSetting,
          currencyType: { id: 1 }, // 1 is USD
          sendInvoiceByEmail: customerBillSettingSendInvoiceByEmail,
          billAddressSame: billAddressSame || false,
          billingAddress: !!customerIsPOBoxAddress
            ? { ...customerBillSettingBillingAddress, line1: customerPOBoxAddress, isPOBox: true }
            : { ...customerBillSettingBillingAddress, line1: null, isPOBox: false },
        },
      },
      [
        'customerBillSettingSendInvoiceByEmail',
        'customerBillSettingBillingAddress',
        'customerIsPOBoxAddress',
        'customerPOBoxAddress',
      ],
    );

    const newFormData =
      customer.customerTypeId === STREET ? omit(formData, ['address', 'customerBillSetting']) : formData;

    const saveCustomerPromise = newFormData.id ? updateCustomer(newFormData) : createCustomer(newFormData);
    const saveType = newFormData.id ? 'Update' : 'Create';
    saveCustomerPromise
      .then(newFormData => {
        dispatch(completeSave(redirectToPage ? newFormData : {}, saveType));

        if (redirectToPage) dispatch(push(`/customers/customers/${newFormData.id}/edit`));
      })
      .catch(() => dispatch(failSave()));
    return saveCustomerPromise;
  };

export const resetCustomer = () => ({
  type: RESET,
});

export const setCurrentCustomersPageUrl = (currentCustomersPageUrl: string) => ({
  type: SET_CURRENT_CUSTOMERS_PAGE_URL,
  currentCustomersPageUrl,
});
