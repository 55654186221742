import { http } from 'src/core/services/http';
import { Group } from '../interfaces/Groups';

export const loadGroups = (vendorId: number) => http.get(`/${vendorId}/groups`).then(response => response.data);

export const loadGroup = (groupId: number) => http.get(`/groups/${groupId}`).then(response => response.data);

export const createGroup = (group: Group) => http.post(`/groups`, { ...group }).then(response => response.data);

export const updateGroup = (group: Group) =>
  http.put(`/groups/${group.id}`, { ...group }).then(response => response.data);

export const deleteGroup = (groupId: number) => http.delete(`/groups/${groupId}`).then(response => response.data);

export const assignRoutesToGroups = (routeIds: number[], groupIds: number[]) =>
  http.post(`/routes/bulkAssignGroups`, { groupIds, routeIds }).then(response => response.data);

export const assignTemplatesToGroups = (routeTemplateIds: number[], groupIds: number[]) =>
  http.post(`/routeTemplates/bulkAssignGroups`, { groupIds, routeTemplateIds }).then(response => response.data);
