import { useEffect, useState } from 'react';
import { getFeatureCollection } from 'src/common/components/map/util';

import RouteMapGeoFencesGLSource from './RouteMapGeoFencesGLSource';

type Props = {
  geoFencesGeoJSON: GeoJSON.Feature<GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties>[];
  map: mapboxgl.Map;
  isSatellite: boolean;
};

export default function RouteMapGeoFencesGL({ map, geoFencesGeoJSON, isSatellite }: Props) {
  const geoJSON = getFeatureCollection(geoFencesGeoJSON);
  const [mapZoom, setMapZoom] = useState(map.getZoom());

  useEffect(() => {
    map?.once('load', () => {
      map.on('zoom', () => {
        setMapZoom(map.getZoom());
      });
    });
  }, [map]);

  return <RouteMapGeoFencesGLSource geoJSON={geoJSON} isSatellite={isSatellite} mapZoom={mapZoom} />;
}
