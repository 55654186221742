import moment from 'moment';

import { currency } from 'src/utils/services/formatter';
import { Rate } from 'src/customers/interfaces/Services';
import { RATE_STATUSES } from 'src/customers/constants/rateStatuses';
import { RateType } from 'src/finance/interfaces/ServiceRates';
import { TableCell, TableRow } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

const RatesTableRow = ({
  cellWidths,
  description,
  effectiveFromDate,
  effectiveToDate,
  includedQty,
  overridable,
  rate,
  rateCodeId,
  status,
  typeCode,
  unitOfMeasureTypeId,
}: Rate & { cellWidths: { [key: string]: string } }) => {
  const UOMs = useSelector(state => state.common.unitOfMeasureTypes.unitOfMeasureTypes);
  const unitOfMeasure = UOMs.filter(uom => uom.id === unitOfMeasureTypeId)[0].technicalName;

  const rateTypes: RateType[] = useSelector(state => state.finance.serviceRates.rateTypes || []);

  const rateCode = rateTypes.find(rateType => rateType.id === rateCodeId)?.typeCode || typeCode;
  const rateCodeDescription = rateTypes.find(rateType => rateType.id === rateCodeId)?.description || description;

  return (
    <TableRow>
      <TableCell width={cellWidths.rateTypeId}>
        {rateCode} / {rateCodeDescription}
      </TableCell>
      <TableCell width={cellWidths.overridable}>{translate(overridable ? 'common.yes' : 'common.no')}</TableCell>
      <TableCell width={cellWidths.rate}>{currency(rate)}</TableCell>
      <TableCell width={cellWidths.uomId}>{unitOfMeasureTypeId && unitOfMeasure}</TableCell>
      <TableCell width={cellWidths.quantity}>{includedQty}</TableCell>
      <TableCell width={cellWidths.effectiveFromDate}>
        {effectiveFromDate ? moment(effectiveFromDate).format('MM/DD/YYYY') : '-'}
      </TableCell>
      <TableCell width={cellWidths.effectiveToDate}>
        {effectiveToDate ? moment(effectiveToDate).format('MM/DD/YYYY') : '-'}
      </TableCell>
      <TableCell width={cellWidths.status}>{RATE_STATUSES[status]?.name}</TableCell>
    </TableRow>
  );
};

export default RatesTableRow;
