import { getItem, setItem, removeItem } from '../../core/services/persistentStorage';
import { TODAY_END_OF_DAY } from '../../core/constants';

export const DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY = 'dashboardFilters';
export const DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY_MAPBOX = 'dashboardFiltersMapbox';

const FILTER_EXPIRATION = {
  completed: TODAY_END_OF_DAY,
};

export const getPersistentFilters = (vendorId: number) => {
  let filters;
  try {
    filters = JSON.parse(getItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY) || '');
  } catch (error) {
    removeItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY);
    filters = {};
  }

  if ((filters && filters.vendorId && filters.vendorId !== vendorId) || !filters || !filters.vendorId) {
    removeItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY);
    filters = {};
  }
  return filters;
};

export const getPersistentFiltersMapbox = (vendorId: number) => {
  let filters;
  try {
    filters = JSON.parse(getItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY_MAPBOX) || '');
  } catch (error) {
    removeItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY_MAPBOX);
    filters = {};
  }

  if ((filters && filters.vendorId && filters.vendorId !== vendorId) || !filters || !filters.vendorId) {
    removeItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY_MAPBOX);
    filters = {};
  }
  return filters;
};

export const setPersistentFilters = (filters: any) => {
  setItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY, JSON.stringify(filters), FILTER_EXPIRATION.completed as any);
};

export const setPersistentFiltersMapbox = (filters: any) => {
  setItem(DASHBOARD_FILTER_PERSISTENT_STORAGE_KEY_MAPBOX, JSON.stringify(filters), FILTER_EXPIRATION.completed as any);
};

export const updatePersistentFiltersByKey = (vendorId: number, key: any, value: any) => {
  const filters = getPersistentFilters(vendorId);
  if (Object.prototype.hasOwnProperty.call(filters, key)) {
    filters[key] = value;
    setPersistentFilters(filters);
  }
};

export const updatePersistentFiltersMapboxByKey = (vendorId: number, key: any, value: any) => {
  const filters = getPersistentFiltersMapbox(vendorId);
  if (Object.prototype.hasOwnProperty.call(filters, key)) {
    filters[key] = value;
    setPersistentFiltersMapbox(filters);
  }
};
