import { map } from 'lodash';
import { PureComponent } from 'react';

import { Text } from '../../../core/components/styled';

interface Props {
  routeNames: string[];
}

class LocationAndCityAlertsRoutesPopover extends PureComponent<Props> {
  render() {
    const { routeNames } = this.props;

    return <Text size="small">{map(routeNames.slice(1), route => route).join(', ')}</Text>;
  }
}

export default LocationAndCityAlertsRoutesPopover;
