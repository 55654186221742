import { PureComponent } from 'react';

import { Modal } from '../../../core/components';
import PinOnMapMapbox from '../PinOnMapMapbox';

interface Props {
  handleAddressSelection: (address: any) => void;
  handleCloseModal: () => void;
  mapCenterByVendor?: any;
  minHeight?: string;
}

class PinOnMapModal extends PureComponent<Props> {
  render() {
    const { handleAddressSelection, handleCloseModal, mapCenterByVendor, minHeight } = this.props;

    return (
      <Modal onClose={handleCloseModal} padding="medium" size="large" minHeight={minHeight}>
        {mapCenterByVendor && (
          <PinOnMapMapbox
            handleBackClick={handleCloseModal}
            handlePinSelection={handleAddressSelection}
            modalHasTitle={false}
            mapCenterByVendor={mapCenterByVendor}
          />
        )}
      </Modal>
    );
  }
}

export default PinOnMapModal;
