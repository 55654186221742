import React from 'react';

import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import ServiceSideForm from '../forms/ServiceSideForm';
import translate from '../../../core/services/translate';

interface Props {
  vehicleTypeId: number;
  intermediateLocationId?: number | null;
  closeModal(): void;
  onSubmit(formData: any): void;
}

const ServiceSideModal: React.FC<Props> = ({ closeModal, onSubmit, intermediateLocationId, vehicleTypeId }) => (
  <Modal title={translate('routes.routeSequencing')} onClose={closeModal} padding="medium" size="medium">
    <ModalSection>
      <ServiceSideForm
        vehicleTypeId={vehicleTypeId}
        intermediateLocationId={intermediateLocationId}
        onSubmit={onSubmit}
        closeModal={closeModal}
      />
    </ModalSection>
  </Modal>
);

export default ServiceSideModal;
