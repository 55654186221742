import { map } from 'lodash-es';
import * as turf from '@turf/turf';

import { getFeatureCollection, getLineStringFeature, getPointFeature } from 'src/common/components/map/util';
import { DEADHEAD_ID1, DEADHEAD_ID2, DEADHEAD_ID3, TRAVEL_PATH_SERVICE_TYPES_IDS } from 'src/routes/constants';
import { TravelPathProperties } from 'src/routes/interfaces/TravelPath';

export const getTravelPathGeoJSON = (
  travelPathData: GeoJSON.FeatureCollection<GeoJSON.MultiLineString, TravelPathProperties>,
) =>
  getFeatureCollection<GeoJSON.LineString, any>(
    map(travelPathData.features, feature => {
      return getLineStringFeature(feature.properties.pathId, feature.geometry.coordinates as any, {
        id: feature.properties.pathId,
        streetId: feature.properties.streetId,
        side: feature.properties.side,
        service: feature.properties.service,
        priority: feature.properties.priority,
        sequence: feature.properties.sequence,
        color: TRAVEL_PATH_SERVICE_TYPES_IDS[feature.properties.servCode]?.color,
        isDashed: [DEADHEAD_ID1, DEADHEAD_ID2, DEADHEAD_ID3].includes(feature.properties.servCode),
        serviceName: TRAVEL_PATH_SERVICE_TYPES_IDS[feature.properties.servCode]?.name,
        clickable: true,
      });
    }),
  );

export const getTravelPathArrowsGeoJSON = (travelPathData: any, mapBearing?: number) =>
  getFeatureCollection<GeoJSON.Point, any>(
    map(travelPathData.features, feature => {
      const coordinates = feature.geometry.coordinates;
      const lastCoordinate = coordinates[coordinates.length - 1];
      const id = feature.properties.pathId;
      return getPointFeature(id, lastCoordinate, {
        id,
        clickable: true,
        color: TRAVEL_PATH_SERVICE_TYPES_IDS[feature.properties.servCode]?.color,
        isDashed: [DEADHEAD_ID1, DEADHEAD_ID2, DEADHEAD_ID3].includes(feature.properties.servCode),
        priority: feature.properties.priority,
        sequence: feature.properties.sequence,
        service: feature.properties.service,
        serviceName: TRAVEL_PATH_SERVICE_TYPES_IDS[feature.properties.servCode]?.name,
        side: feature.properties.side,
        streetId: feature.properties.streetId,
        rotate: getRotate(feature, mapBearing),
      });
    }),
  );

const getRotate = (coordinates: any, mapBearing?: number) => {
  const coordinatesFlatten = turf.getCoords(coordinates);
  const startPoint = turf.point(coordinatesFlatten[coordinatesFlatten.length - 2]);
  const endPoint = turf.point(coordinatesFlatten[coordinatesFlatten.length - 1]);
  const bearing = turf.bearing(startPoint, endPoint);
  let mapBearingToUse = mapBearing || 0;

  return bearing - mapBearingToUse - 90;
};
