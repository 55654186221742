import React from 'react';
import { Layer } from 'react-map-gl';
import { DASHBOARD_CLUSTERS_SOURCE, DASHBOARD_HAULER_LOCATIONS_LAYER } from 'src/dashboard/constants/dashboardMapGL';

const DashboardHaulerLocationsGLLayers: React.FC = React.memo(() => (
  <>
    <Layer
      id={DASHBOARD_HAULER_LOCATIONS_LAYER}
      type="symbol"
      source={DASHBOARD_CLUSTERS_SOURCE}
      filter={['all', ['!', ['has', 'point_count']], ['==', ['get', 'layer'], DASHBOARD_HAULER_LOCATIONS_LAYER]]}
      paint={{}}
      layout={{
        'icon-image': ['get', 'icon'],
        'icon-size': 0.5,
        'icon-allow-overlap': true,
      }}
    />
  </>
));

export default DashboardHaulerLocationsGLLayers;
