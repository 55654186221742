import { combineReducers } from 'redux';
import { reducer as serviceOptionsReducer } from './serviceOptions';
import { reducer as opportunityReducer } from './opportunity';
import { reducer as opportunitiesReducer } from './opportunities';
import { reducer as serviceChangeRequestReducer } from './serviceChangeRequests';
import { reducer as invoiceReducer } from './invoice';
import {reducer as invoiceUploadReducer} from './invoiceUpload'

export {
  loadOpportunity,
  updateHaulerOpportunity,
  COMPLETE_LOAD as COMPLETE_LOAD_OPPORTUNITY,
  vendorPaymentMethodTypes,
} from './opportunity';
export { loadOpportunities, loadMarketSubTypes } from './opportunities';
export { loadServiceChangeRequests, updateServiceChangeRequest } from './serviceChangeRequests';
export { loadChangeRequestServiceOptions } from './serviceOptions';

export { loadInvoices, exportInvoices } from './invoice';
export { uploadInvoices } from './invoiceUpload';
export const reducer = combineReducers({
  serviceOptions: serviceOptionsReducer,
  opportunity: opportunityReducer,
  opportunities: opportunitiesReducer,
  serviceChangeRequest: serviceChangeRequestReducer,
  invoice: invoiceReducer,
  invoiceUpload: invoiceUploadReducer
});
