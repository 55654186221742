import { CSSProperties, useState } from 'react';
import { transparentize } from 'polished';
import { WrappedFieldProps } from 'redux-form';
import Select, { MenuPosition, SelectComponentsConfig, StylesConfig } from 'react-select';

import { FormError, FormGroup, FormLabel } from './styled';
import { Option } from 'src/common/interfaces/Option';
import { theme } from '../styles';

const getControlBackgroundImage = (state: any) => {
  if (state.isFocused) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")';
  }

  if (state.isDisabled) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiM5Njk2OTYiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")';
  }

  return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2Utb3BhY2l0eT0iLjcwNCIvPjwvc3ZnPg==")';
};

export const dropdownStyles: StylesConfig<Option, false> = {
  container: () => ({}),
  control: (baseStyle: CSSProperties, state: any) => ({
    width: '100%',
    paddingRight: '26px',
    position: 'relative',
    background: 'right 2px center no-repeat',
    backgroundImage: getControlBackgroundImage(state),
    outline: 'none',
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
    borderColor: state.isFocused ? theme.brandPrimary : theme.grayLight,
    lineHeight: '22px',
    fontSize: '14px',
    color: theme.grayBase,
  }),
  valueContainer: (baseStyle: CSSProperties) => ({ ...baseStyle, padding: '0px 6px 2px 0' }),
  singleValue: (baseStyle: CSSProperties) => ({ ...baseStyle, top: '3px', transform: 'none' }),
  placeholder: (baseStyle: CSSProperties, state: any) => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    transform: 'none',
    textTransform: 'capitalize',
    color: theme.grayDark,
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    opacity: state.isDisabled ? '.4' : '1',
    fontSize: '14px',
    margin: '0',
  }),
  indicatorsContainer: () => ({ position: 'absolute', top: '4px', right: '22px' }),
  dropdownIndicator: () => ({ display: 'none' }),
  clearIndicator: (baseStyle: CSSProperties, state: any) => ({
    ...baseStyle,
    '::after': {
      display: 'inline-block',
      width: '10px',
      height: '10px',
      content: '""',
      backgroundColor: state.isDisabled ? theme.grayDark : theme.brandPrimary,
      backgroundImage:
        'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01LjkxOC45TDUuMS4wODJhLjI2NC4yNjQgMCAwIDAtLjM4MiAwTDMgMS44IDEuMjgyLjA4MmEuMjY0LjI2NCAwIDAgMC0uMzgyIDBMLjA4Mi45YS4yNjQuMjY0IDAgMCAwIDAgLjM4MkwxLjggMyAuMDgyIDQuNzE4YS4yNjQuMjY0IDAgMCAwIDAgLjM4MmwuODE4LjgxOGMuMTEuMTEuMjczLjExLjM4MiAwTDMgNC4ybDEuNzE4IDEuNzE4QS4yOTUuMjk1IDAgMCAwIDQuOTEgNmEuMjk1LjI5NSAwIDAgMCAuMTkxLS4wODJsLjgxOC0uODE4YS4yNjQuMjY0IDAgMCAwIDAtLjM4Mkw0LjIgM2wxLjcxOC0xLjcxOGEuMjY0LjI2NCAwIDAgMCAwLS4zODJ6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=)',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    '> svg': { display: 'none' },
  }),
  noOptionsMessage: (baseStyles: any) => ({
    ...baseStyles,
    textTransform: 'capitalize',
    lineHeight: '18px',
    fontSize: '12px',
  }),
  menu: (baseStyle: CSSProperties) => ({
    ...baseStyle,
    left: 'initial',
    top: 'initial',
    zIndex: 10001,
    width: 'auto',
    minWidth: '100%',
    marginTop: 0,
    borderRadius: '2px',
    boxShadow: `0 4px 8px ${transparentize(0.9, theme.grayBase)}`,
  }),
  option: (baseStyle: CSSProperties, state: any) => ({
    ...baseStyle,
    ':active': theme.grayLight,
    paddingRight: '30px',
    backgroundColor:
      (state.isSelected && transparentize(0.2, theme.brandPrimary)) ||
      (state.isFocused && theme.grayLight) ||
      'transparent',
    fontSize: '12px',
  }),
  multiValue: (baseStyle: CSSProperties, state: any) => ({
    ...baseStyle,
    backgroundColor: state.isDisabled ? theme.grayDark : theme.brandPrimary,
    color: theme.brandWhite,
    div: {
      color: theme.brandWhite,
    },
    'div:hover:last-child': {
      backgroundColor: theme.brandGreenDark,
      filter: 'brightness(80%)',
      color: theme.brandWhite,
    },
  }),
};

export interface DropdownOption {
  label: string;
  value: any;
}

export interface DropDownProps extends WrappedFieldProps {
  components?: SelectComponentsConfig<Option, false>;
  defaultValue?: any;
  disabled?: boolean;
  fieldNotTouched?: boolean;
  id?: string;
  inline?: boolean;
  isClearable?: boolean;
  isOptionDisabled?: (option: any) => boolean;
  label?: string;
  margin?: string;
  isMulti?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  options?: DropdownOption[];
  placeholder?: string;
  raisedLabel?: boolean;
  selfFlexStart?: boolean;
  showErrorBeforeSubmit?: boolean;
  width?: string;
  menuPosition?: MenuPosition;
}

const MultiDropdown = ({
  components,
  disabled,
  fieldNotTouched,
  defaultValue,
  input: { onChange: onChangeInput, value, name },
  label,
  margin,
  meta: { submitFailed, error },
  placeholder,
  showErrorBeforeSubmit,
  width,
  raisedLabel,
  inline,
  selfFlexStart,
  menuPosition,
  options,
  isOptionDisabled,
  ...props
}: DropDownProps) => {
  const [inputText, setInputText] = useState(defaultValue);

  const onInputChange = (v: string) => {
    if (!v || v !== inputText) setInputText(v);
  };

  const onChange = async (option: [DropdownOption]) => {
    const value = option ? option.map(e => e.value) : [];
    onChangeInput(value);
  };

  const findSelectedOption = (value: any) => {
    const newValue = options?.filter(function (item) {
      return value.indexOf(item.value) !== -1;
    });

    if (!newValue && defaultValue) {
      onChange(defaultValue);
    } else {
      return newValue;
    }
  };

  return (
    <FormGroup
      margin={margin}
      hasValue={value || inputText}
      width={width}
      raisedLabel={raisedLabel}
      inline={inline}
      disabled={disabled}
      selfFlexStart={selfFlexStart}
    >
      {!!label && <FormLabel>{label}</FormLabel>}
      <Select
        inputId={name}
        {...props}
        options={options}
        isDisabled={disabled}
        components={components}
        defaultValue={defaultValue}
        styles={dropdownStyles}
        placeholder={placeholder || ''}
        value={findSelectedOption(value)}
        onChange={onChange}
        onInputChange={onInputChange}
        isOptionDisabled={isOptionDisabled}
        isMulti
        menuPosition={menuPosition}
      />
      {(submitFailed || showErrorBeforeSubmit) && error && !fieldNotTouched && <FormError>{error}</FormError>}
    </FormGroup>
  );
};

export default MultiDropdown;
