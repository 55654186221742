import { http } from '../../core/services/http';
import { transformLoadRouteJobs } from '../services/transformDispatchBoardRouteJobs';
import { DispatchBoardRouteJob, DuplicateJobs } from '../interfaces/DispatchBoardRouteJob';

export const loadRouteJobs = (routeId: number, searchTerm?: string) =>
  http
    .get(`/dispatchBoard/routes/${routeId}/routeJobs`, { params: { searchTerm } })
    .then(response => transformLoadRouteJobs(response.data));

export const loadRoutesJobs = (routeIds: string, searchTerm?: string) =>
  http
    .get(`/dispatchBoard/routes/mapDetails?routeIds=${routeIds}`, { params: { searchTerm } })
    .then(response => response.data);

export const loadRouteJob = (jobId: number) =>
  http.get(`/dispatchBoard/routeJobs/${jobId}`).then(response => response.data);

export const createRouteJob = (jobData: DispatchBoardRouteJob) =>
  http.post('/dispatchBoard/routeJobs', jobData).then(response => response.data);

export const updateRouteJob = (jobId: number, jobData: DispatchBoardRouteJob) =>
  http.put(`/dispatchBoard/routeJobs/${jobId}`, jobData).then(response => response.data);

export const moveRouteJobToPosition = (routeId: number, orderedRouteJobs: any[], config?: any) =>
  http
    .post(`dispatchBoard/routes/${routeId}/reorderRouteJobs`, orderedRouteJobs, config)
    .then(response => response.data);

export const transferRouteJobs = (jobIds: any[], routeId: number, position?: number, isOnHoldJob?: boolean) =>
  http
    .post('/dispatchBoard/routeJobs/transfer', { jobIds, routeId, position, isOnHoldJob })
    .then(response => response.data);

export const addToRouteOrRouteTemplate = ({
  jobs,
  routeId,
  position,
  isTemplate,
  vendorId,
  date,
  positionTypeId,
}: any) => {
  const request = { vendorId, date, position, positionTypeId } as any;

  if (isTemplate) {
    request.routeTemplateId = routeId;
  } else {
    request.routeId = routeId;
  }

  request.unassignedJobIds = jobs
    .filter((j: DispatchBoardRouteJob) => j.isUnassigned)
    .map(({ id }: DispatchBoardRouteJob) => id);
  request.routeJobs = jobs
    .filter((j: DispatchBoardRouteJob) => !j.isUnassigned)
    .map(({ id, isOnHoldJob }: DispatchBoardRouteJob) => {
      return {
        jobId: id,
        isOnHoldJob: isOnHoldJob || null,
      };
    });

  return http.post('/dispatchBoard/jobs/addToRouteOrRouteTemplate', request).then(response => response.data);
};

export const checkDuplicateJobs = (duplicateJobs: DuplicateJobs) =>
  http.post('/dispatchBoard/transferJobs/checkForDuplicateJobs', duplicateJobs).then(response => response.data);

export const deleteRouteJobs = (routeId: number, jobIds: number[]) =>
  http.post(`/dispatchBoard/routes/${routeId}/routeJobs/delete`, jobIds).then(response => response.data);

export const checkDuplicateWorkOrderNoForRouteJob = (
  vendorId: number,
  workOrderNumber: number,
  routeLocationId: number,
) =>
  http
    .get('dispatchBoard/routes/duplicateWorkorderNo', { params: { vendorId, workOrderNumber, routeLocationId } })
    .then(response => response.data);

export const loadRouteHasStopsInPendingOptimization = (routeId?: number | null) =>
  http.get(`/routes/${routeId}/hasStopsInPendingOptimization`).then(response => response.data);

export const loadRouteTemplatesHaveStopsInPendingOptimization = (routeTemplateIds?: string) =>
  http
    .get(`/routeTemplates/hasStopsInPendingOptimization?routeTemplateIds=${routeTemplateIds}`)
    .then(response => response.data);

export const loadRouteHasMaterialTypeConfigured = (
  routeId?: number | null,
  materialTypeId?: string,
  isTemplate?: boolean,
) =>
  http
    .get(`/${isTemplate ? 'routeTemplates' : 'routes'}/${routeId}/hasMaterialTypeConfigured`, {
      params: { materialTypeId },
    })
    .then(response => response.data);
