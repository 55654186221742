import { FieldArray, getFormValues, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useMemo, useState, MouseEvent } from 'react';
import { uniqueId } from 'lodash-es';

import { ACTIVE_ACCOUNT, SCHEDULED } from 'src/common/constants';
import { Address } from 'src/common/interfaces/Facility';
import { Button, Grid, GridColumn, Popover, Text } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import EditRouteStopsTableRow, {
  EDIT_TABLE_ROW_HEIGHT,
  UnsortableEditRouteStopsTableRow,
} from './EditRouteStopsTableRow';
import { filterStopsPredicate } from './RouteStops';
import { FIRST_JOB_POSITION_ID, OPTIMIZED_JOB_POSITION_ID } from 'src/core/constants/jobPositionOptions';
import { getEditRouteStopsTableCells } from './utils';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { loadRouteHasStopsInPendingOptimization } from 'src/routes/ducks/dispatchBoard/dispatchBoardRouteJob';
import { loadRouteSummary, transferRouteLocations } from 'src/routes/ducks';
import { PageFooter } from 'src/common/components/styled';
import { PopoverWrapper, SortableTable, Table } from 'src/core/components';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { SELECTED_TRANSFERRED_STOPS } from 'src/routes/constants';
import { clearRouteMapSelectedFeature, setIsDrawingMode } from 'src/routes/ducks/mapControls';
import { TransferRouteStopsFormValues } from 'src/routes/components/forms/TransferRouteStopsForm';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import RouteStopsAddForm from 'src/routes/components/forms/RouteStopsAddForm';
import TransferRouteStopsModal from 'src/routes/components/modals/TransferRouteStopsModal';
import translate from 'src/core/services/translate';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';

export const ROUTE_STOPS_FORM_NAME = 'routeStopsForm';
export const ROUTE_STOPS_FIELD_ARRAY_NAME = 'routeStops';

type FieldArrayProps = {
  change: (field: string, value: any) => void;
  deleteStops: (stopIds: number[]) => void;
  isRouteStopsFormDirty: boolean;
  setOptimizationCanceled: (canceled: boolean) => void;
  transferStops: () => void;
};

let pinnedJobId = 0;

function RouteStopsFormFieldArray({
  change,
  deleteStops,
  fields,
  isRouteStopsFormDirty,
  setOptimizationCanceled,
  transferStops,
}: WrappedFieldArrayProps & FieldArrayProps) {
  const dispatch = useDispatch();
  const [isTransferStopsModalOpen, setTransferStopsModalOpen] = useState(false);

  const vendorId = useSelector(currentVendorId);
  const { routeSummary } = useSelector(state => state.routes.routeSummary);
  const { pickupStatusIds, searchTerm, showStopsWithLocationAlerts, showTemporaryStops, jobPriorityTypeIds } =
    useSelector(state => state.routes.filters);
  const {
    pickupTypeId,
    pinAddress,
    positionTypeId,
    jobPriorityTypeId,
    reasonCodeTypeId,
    routeLocation,
    wasteMaterialTypeId,
  } = useSelector(getFormValues(ROUTE_STOPS_FORM_NAME)) as RouteStopsFormValues;

  const isBillingFeatureActive = useSelector(state => billingFeatureStatusSelector(state.vendors.features.features));

  let routeStopsTableRows = useMemo(() => {
    dispatch(clearRouteMapSelectedFeature());
    return fields
      .map((fieldArrayKey, index, fields) => ({
        field: fields.get(index),
        fieldArrayKey,
        fieldIndex: index,
      }))
      .filter(row =>
        filterStopsPredicate(
          showStopsWithLocationAlerts,
          showTemporaryStops,
          pickupStatusIds,
          jobPriorityTypeIds,
          searchTerm,
        )(row.field),
      );
  }, [
    dispatch,
    fields,
    jobPriorityTypeIds,
    pickupStatusIds,
    searchTerm,
    showStopsWithLocationAlerts,
    showTemporaryStops,
  ]);

  const optimizedRouteStops = routeStopsTableRows.filter(
    rs => rs.field.positionTypeId === OPTIMIZED_JOB_POSITION_ID || rs.field.orderNo === JOB_PENDING_OPTIMIZATION_ID,
  );

  const newOptimizedRouteLocationsLength = routeStopsTableRows.filter(
    rs => rs.field.id && rs.field.orderNo === JOB_PENDING_OPTIMIZATION_ID,
  ).length;

  const currentOptimizedRouteLocationsLength = optimizedRouteStops.filter(rs => rs.field.isNew).length;

  if (optimizedRouteStops.length) {
    routeStopsTableRows = routeStopsTableRows
      .filter(rs => {
        return (
          (rs.field.positionTypeId && rs.field.positionTypeId !== OPTIMIZED_JOB_POSITION_ID) ||
          rs.field.orderNo > JOB_PENDING_OPTIMIZATION_ID
        );
      })
      .map((f, i) => ({ ...f, fieldIndex: i }));
  }

  const handleAddStop = () => {
    let location;

    const isOptimal = positionTypeId === OPTIMIZED_JOB_POSITION_ID;

    if (pinAddress) {
      pinnedJobId--;
      location = {
        ...pinAddress,
        accountTypeId: ACTIVE_ACCOUNT,
        binLatitude: pinAddress.binLatitude || pinAddress.latitude,
        binLongitude: pinAddress.binLongitude || pinAddress.longitude,
        displayLatitude: pinAddress.binLatitude || pinAddress.latitude,
        displayLongitude: pinAddress.binLongitude || pinAddress.longitude,
        customerName: `${pinAddress.streetNumber} ${pinAddress.street}`,
        locationName: pinAddress.line1,
        pickupStatusTypeId: SCHEDULED,
        jobPriorityTypeId,
        pickupTypeId,
        isOptimal,
        positionTypeId,
        reasonCodeTypeId,
        isPinned: true,
        id: pinnedJobId,
        wasteMaterialTypeId,
      };
    } else {
      location = {
        accountTypeId: routeLocation.accountStatusId,
        binLatitude: routeLocation.location.address.binLatitude || routeLocation.location.address.latitude,
        binLongitude: routeLocation.location.address.binLongitude || routeLocation.location.address.longitude,
        displayLatitude: routeLocation.location.address.latitude,
        displayLongitude: routeLocation.location.address.longitude,
        pickupStatusTypeId: SCHEDULED,
        jobPriorityTypeId,
        pickupTypeId,
        reasonCodeTypeId,
        serviceContractId: routeLocation.service.id,
        locationId: routeLocation.location.id,
        customerName: routeLocation.customer.name,
        locationName: routeLocation.location.name,
        streetNumber: routeLocation.location.address.streetNumber,
        street: routeLocation.location.address.street,
        addressForPopup: routeLocation.location.address.line1,
        serviceName: routeLocation.service.name,
        positionTypeId,
        isOptimal,
        isNew: true,
        wasteMaterialTypeId,
        wasteMaterialTypeTechnicalName: routeLocation.service.wasteMaterialTypeTechnicalName,
        id: uniqueId('newStop_'),
      };
    }

    positionTypeId === FIRST_JOB_POSITION_ID || !positionTypeId ? fields.unshift(location) : fields.push(location);

    createSuccessNotification(translate('routes.alertMessages.routeLocationAdded'));
  };

  const handleDeleteStop = (index: number) => {
    const stop = fields.get(index);
    if (stop.id) deleteStops([stop.id]);

    fields.remove(index);
    createSuccessNotification(translate('routes.alertMessages.routeLocationDeleted'));
  };

  const handleMassDeleteStops = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const deletedStops = getSelectedStops();
    const deletedStopsIndexes = deletedStops.map(stop => stop.index);
    const deletedStopsIds = deletedStops.filter(stop => stop.id).map(stop => stop.id);
    const stopsAfterDelete = fields.getAll().filter((_, index) => !deletedStopsIndexes.includes(index));

    change(ROUTE_STOPS_FIELD_ARRAY_NAME, stopsAfterDelete);
    deleteStops(deletedStopsIds);
    dispatch(setIsDrawingMode(false));

    const deleteStopsSuccessMessage =
      deletedStops.length === 1
        ? 'routes.alertMessages.routeLocationDeleted'
        : 'routes.alertMessages.routeLocationsDeleted';
    createSuccessNotification(translate(deleteStopsSuccessMessage, { deletedStops: deletedStops.length }));
  };

  const getSelectedStops = () => {
    const selectedStops: any[] = [];
    routeStopsTableRows.forEach(row => {
      const field = fields.get(row.fieldIndex);
      const index = row.fieldIndex;
      if (field.isChecked) selectedStops.push({ ...field, index });
    });
    return selectedStops;
  };

  const toggleSelectAllStops = () => {
    const selectedStops = getSelectedStops();
    routeStopsTableRows.forEach(row => {
      const field = fields.get(row.fieldIndex);
      change(row.fieldArrayKey, {
        ...field,
        isChecked: selectedStops.length === routeStopsTableRows.length ? false : true,
      });
    });
  };

  const handleChangeField = (fieldIndex: number) => {
    const field = fields.get(fieldIndex);
    dispatch(change(`${ROUTE_STOPS_FIELD_ARRAY_NAME}[${fieldIndex}]`, { ...field, isChanged: true }));
  };

  const handleOpenTransferStopsModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setTransferStopsModalOpen(true);
  };

  const handleTransferRouteStops = async ({
    targetRouteId,
    routeDate,
    positionTypeId,
  }: TransferRouteStopsFormValues) => {
    if (!routeSummary) return;

    const hasStopsInPendingOptimization = await loadRouteHasStopsInPendingOptimization(Number(targetRouteId))(dispatch);
    if (hasStopsInPendingOptimization)
      if (
        !(await confirm(
          translate('routes.alertMessages.routeHasStopsInOptimization'),
          '',
          translate('common.cancel'),
          translate('common.continue'),
        ))
      )
        return;

    const routeStopsToTransfer = getSelectedStops();

    transferRouteLocations({
      routeEntityLocationsToTransfer: routeStopsToTransfer,
      targetRouteId,
      routeDate,
      positionTypeId,
    })(dispatch)
      .then(({ totalStops, transferredStops, notTransfferedStopIds }) => {
        dispatch(setIsDrawingMode(false));

        if (transferredStops === 0) {
          createErrorNotification(translate('routes.alertMessages.routeLocationsStopsAlreadyExist'));
        } else {
          const deletedIndexes = routeStopsToTransfer
            .filter(el => !notTransfferedStopIds.includes(el.id))
            .map(stop => stop.index);

          const totalDeletedStops = deletedIndexes.length;
          const stopsAfterDelete = fields.getAll().filter((_, index) => !deletedIndexes.includes(index));

          change(ROUTE_STOPS_FIELD_ARRAY_NAME, stopsAfterDelete);

          loadRouteSummary(vendorId, routeSummary.routeId)(dispatch);
          transferStops();
          setTransferStopsModalOpen(false);
          createSuccessNotification(
            translate('routes.alertMessages.routeLocationsTransferred', {
              totalStops,
              transferredStops: totalDeletedStops,
            }),
          );
        }
      })
      .catch(({ code }) => {
        createErrorNotification(
          `${translate(createTranslationKey(code || 'routeLocationsTransferError', 'routes.alertMessages'))}`,
        );
      });
  };

  const handleOrderChange = (from: number, to: number) => {
    const min = Math.min(from, to);
    const max = Math.max(from, to);
    for (let index = min; index <= max; index++) handleChangeField(index);

    fields.move(from, to);
  };

  const selectedStops = getSelectedStops();

  const cancelOptimization = async () => {
    if (!(await confirm(translate('routes.alertMessages.confirmCancelOptimization')))) {
      return;
    }

    setOptimizationCanceled(true);

    fields.getAll().forEach((routeLocation: any, index: number) => {
      if (routeLocation.orderNo === JOB_PENDING_OPTIMIZATION_ID) {
        const newField = {
          ...routeLocation,
          orderNo: routeStopsTableRows.length + index + 1,
        };

        fields.push(newField);
        fields.shift();
      }
    });

    createSuccessNotification(translate('routes.alertMessages.confirmCancelOptimizationSucess'));
  };

  return (
    <>
      <Grid multiLine>
        <GridColumn size="12/12" padding="no">
          {!isBillingFeatureActive && (
            <RouteStopsAddForm
              change={change}
              newOptimizedRouteLocationsLength={newOptimizedRouteLocationsLength}
              onSubmit={handleAddStop}
              pickupTypeId={pickupTypeId}
              pinAddress={pinAddress}
              routeLocation={routeLocation}
            />
          )}
        </GridColumn>
        {!!optimizedRouteStops.length && (
          <GridColumn size="12/12" padding="no">
            <Table
              cells={getEditRouteStopsTableCells(
                getSelectedStops().length,
                fields.length,
                toggleSelectAllStops,
                newOptimizedRouteLocationsLength,
              )}
              rowComponent={UnsortableEditRouteStopsTableRow}
              rows={optimizedRouteStops}
              rowProps={{
                handleChangeField,
                handleOrderChange,
                deleteStop: handleDeleteStop,
                newOptimizedRouteLocationsLength: newOptimizedRouteLocationsLength,
              }}
              tableHeading={translate(
                currentOptimizedRouteLocationsLength
                  ? 'routes.pendingOptimizationWillStartAfterSave'
                  : 'routes.pendingOptimization',
              )}
              tableHeadingAction={
                newOptimizedRouteLocationsLength > 0 && (
                  <Button
                    type="button"
                    color="primary"
                    size="xSmall"
                    margin="no no no xSmall"
                    onClick={() => cancelOptimization()}
                  >
                    {translate('routes.cancelOptimization')}
                  </Button>
                )
              }
            />
          </GridColumn>
        )}
        <GridColumn size="12/12" padding="no">
          <SortableTable
            cells={
              optimizedRouteStops.length
                ? []
                : getEditRouteStopsTableCells(
                    getSelectedStops().length,
                    fields.length,
                    toggleSelectAllStops,
                    newOptimizedRouteLocationsLength,
                  )
            }
            rowComponent={EditRouteStopsTableRow}
            rowProps={{
              change,
              handleChangeField,
              handleOrderChange,
              deleteStop: handleDeleteStop,
              numberOfOptimizedStops: optimizedRouteStops.length,
              filtersActive: routeStopsTableRows.length < fields.length - optimizedRouteStops.length,
              newOptimizedRouteLocationsLength: newOptimizedRouteLocationsLength,
            }}
            rows={routeStopsTableRows}
            sort={handleOrderChange}
            virtualized
            virtualizedProps={{
              height: Math.min(routeStopsTableRows.length * EDIT_TABLE_ROW_HEIGHT, EDIT_TABLE_ROW_HEIGHT * 8) || 1,
              itemSize: EDIT_TABLE_ROW_HEIGHT,
            }}
            withClickableRows
          />
        </GridColumn>
      </Grid>
      {!!selectedStops.length && (
        <PageFooter>
          <PopoverWrapper
            triggerButton={
              <Button
                color="primary"
                margin="no small no no"
                onClick={handleOpenTransferStopsModal}
                disabled={isRouteStopsFormDirty || selectedStops.length > SELECTED_TRANSFERRED_STOPS}
              >
                {`${translate('routes.transferStops')} (${selectedStops.length})`}
              </Button>
            }
            popoverContent={
              (isRouteStopsFormDirty || selectedStops.length > SELECTED_TRANSFERRED_STOPS) && (
                <Popover>
                  {isRouteStopsFormDirty && (
                    <Text block weight="medium" margin="xxSmall no xxSmall">
                      {translate('routes.alertMessages.routeLocationsDirtyForm')}
                    </Text>
                  )}
                  {selectedStops.length > SELECTED_TRANSFERRED_STOPS && (
                    <Text block weight="medium" margin="xxSmall no xxSmall">
                      {translate('routes.alertMessages.routeLocationsMoreThan', {
                        SELECTED_TRANSFERRED_STOPS,
                      })}
                    </Text>
                  )}
                </Popover>
              )
            }
            size="large"
          />

          <Button color="primary" line onClick={handleMassDeleteStops}>
            {`${translate('routes.deleteStops')} (${selectedStops.length})`}
          </Button>
        </PageFooter>
      )}
      {isTransferStopsModalOpen && (
        <TransferRouteStopsModal
          transferRouteStops={handleTransferRouteStops}
          closeModal={() => setTransferStopsModalOpen(false)}
        />
      )}
    </>
  );
}

type PropsWithoutReduxForm = {
  deleteStops: (stopIds: number[]) => void;
  isRouteStopsFormDirty: boolean;
  setOptimizationCanceled: () => void;
  transferStops: () => void;
};

export type RouteStopsFormValues = {
  accountTypeId: number;
  pickupTypeId: number;
  pinAddress: Address;
  positionTypeId?: number;
  reasonCodeTypeId: number;
  jobPriorityTypeId?: number;
  routeLocation: RouteLocation;
  routeStops: RouteStop[];
  wasteMaterialTypeId: number;
};

type Props = PropsWithoutReduxForm & InjectedFormProps<RouteStopsFormValues, PropsWithoutReduxForm>;

export default reduxForm<RouteStopsFormValues, PropsWithoutReduxForm>({
  form: ROUTE_STOPS_FORM_NAME,
  shouldValidate: () => false,
  enableReinitialize: true,
})(function RouteStopsForm({
  change,
  deleteStops,
  handleSubmit,
  isRouteStopsFormDirty,
  setOptimizationCanceled,
  transferStops,
}: Props) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <FieldArray
        name={ROUTE_STOPS_FIELD_ARRAY_NAME}
        component={RouteStopsFormFieldArray}
        props={{ change, deleteStops, transferStops, isRouteStopsFormDirty, setOptimizationCanceled }}
      />
    </form>
  );
});
