import { ChangeEvent, PureComponent } from 'react';
import { camelCase, map } from 'lodash-es';

import { DispatchBoardJobRouteTitle } from './styled/DispatchBoard/DispatchBoardJobs';
import { Dropdown, TypedField } from '../../core/components';
import { DropDownFieldProps } from '../../common/components/DropDownFieldProps';
import { EnhancedOption, EnhancedOptionValue, EnhancedOptionLabel } from 'src/common/interfaces/Option';
import { MaterialTypes } from 'src/vendors/interfaces/MaterialTypes';
import { RouteTemplateStatus } from './styled/RouteTemplateBuilderMap';
import translate from '../../core/services/translate';

interface Props extends DropDownFieldProps {
  forceMaterialTypes: MaterialTypes[];
}

class MaterialTypesDropdown extends PureComponent<Props> {
  onChange = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      dropdownProps,
      forceMaterialTypes,
      input: { name },
      label,
      placeholder,
      withLabel,
      withPlaceholder,
    } = this.props;

    const getMaterialTypeOptionLabel = (itemName?: string, isActive?: boolean, isCustomBulkyItem?: boolean) => {
      let label = isCustomBulkyItem
        ? itemName
        : translate(`vendors.bulkyItemScheduler.itemTypes.${camelCase(itemName)}`);

      if (!isActive)
        label = (
          <>
            {label} <RouteTemplateStatus inherit>{translate('common.inactive')}</RouteTemplateStatus>
          </>
        );

      return label;
    };

    let materialTypesOptions: any[] = [];
    !!forceMaterialTypes.length &&
      forceMaterialTypes.forEach(materialType =>
        materialTypesOptions.push({
          label: (
            <DispatchBoardJobRouteTitle>
              {translate(`vendors.bulkyItemScheduler.categoryTypes.${camelCase(materialType.category.technicalName)}`)}
            </DispatchBoardJobRouteTitle>
          ),
          options: materialType.bulkyItemTypes
            ? map(materialType.bulkyItemTypes, item => ({
                label: getMaterialTypeOptionLabel(item.bulkyItem.technicalName, item.isActive, false),
                value: item.bulkyItem.id.toString(),
                disabled: item.isActive === false,
              }))
            : map(materialType.customBulkyItemTypes, item => ({
                label: getMaterialTypeOptionLabel(item.customBulkyItemName, item.isActive, true),
                value: item && item.id && item.id.toString(),
                disabled: item.isActive === false,
              })),
        }),
      );

    return (
      <TypedField
        name={name}
        component={Dropdown}
        onChange={this.onChange}
        props={{
          label: label || (withLabel ? translate('vendors.materialTypes.materialType') : undefined),
          placeholder: placeholder || (withPlaceholder ? translate('vendors.materialTypes.materialType') : undefined),
          options: materialTypesOptions,
          ...dropdownProps,
          isClearable: true,
          isOptionDisabled: (option: EnhancedOption<EnhancedOptionValue, EnhancedOptionLabel> | null | undefined) =>
            !!option?.disabled,
        }}
      />
    );
  }
}

export default MaterialTypesDropdown;
