import React, { Fragment } from 'react';

import { map, size, uniqueId } from 'lodash-es';

import { Text } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { WasterRecorder } from 'src/routes/interfaces/WasterRecorder';

interface RoutePageCartFillLevelPopoverProps {
  wasterRecorders: WasterRecorder[];
}

const RoutePageCartFillLevelPopover = ({ wasterRecorders }: RoutePageCartFillLevelPopoverProps) => (
  <Fragment>
    {!!size(wasterRecorders) &&
      map(
        wasterRecorders,
        ({
          fillLevelCount100,
          fillLevelCount75,
          fillLevelCount50,
          fillLevelCount25,
          numberOfBins,
          equipmentTypeName,
        }) => (
          <Fragment key={uniqueId(equipmentTypeName)}>
            <Text weight="medium" block underlined size="medium">
              {translate('common.total')} - {numberOfBins} {translate('routes.carts')}
            </Text>
            {fillLevelCount100 != null && (
              <Text weight="medium" block size="small">
                100% {translate('common.full')} - {fillLevelCount100} {translate('routes.carts')}
              </Text>
            )}
            {fillLevelCount75 != null && (
              <Text weight="medium" block size="small">
                75% {translate('common.full')} - {fillLevelCount75} {translate('routes.carts')}
              </Text>
            )}
            {fillLevelCount50 != null && (
              <Text weight="medium" block size="small">
                50% {translate('common.full')} - {fillLevelCount50} {translate('routes.carts')}
              </Text>
            )}
            {fillLevelCount25 != null && (
              <Text weight="medium" block size="small">
                25% {translate('common.full')} - {fillLevelCount25} {translate('routes.carts')}
              </Text>
            )}
          </Fragment>
        ),
      )}
  </Fragment>
);

export default RoutePageCartFillLevelPopover;
