import React, { useState } from 'react';

import { remove } from 'lodash-es';

import { Modal } from 'src/core/components';
import { ModalSection } from 'src/core/components/styled';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { sendCustomerEmail } from 'src/customers/services/customerCommunication';
import { Image } from 'src/routes/interfaces/RouteImages';
import EmailRouteImagesForm, { EmailRouteImagesFormValues } from '../forms/EmailRouteImagesForm';

export interface RouteImage {
  images?: Image[];
  routeId: number;
  vehicleTypeId: number;
  routeLocationId?: number;
  routeLocationDetailPickupExceptionId?: number;
  customerId?: number;
}

interface Props {
  closeModal: () => void;
  routeImage: RouteImage;
}

const EmailRouteImagesModal = (props: Props) => {
  const [isEmailing, setIsEmailing] = useState(false);
  const [selectedImageBlobIds, setSelectedImageBlobIds] = useState<string[]>([]);

  const {
    closeModal,
    routeImage: { images, vehicleTypeId, routeLocationId, customerId, routeLocationDetailPickupExceptionId, routeId },
  } = props;

  const onSubmit = ({ from, to, subject, body }: EmailRouteImagesFormValues) => {
    setIsEmailing(true);

    sendCustomerEmail({
      emailMessage: body,
      emailSubject: subject,
      fromEmail: from,
      toEmail: to,
      imageBlobIds: selectedImageBlobIds,
      vehicleTypeId,
      routeLocationId,
      customerId,
      routeLocationDetailPickupExceptionId,
      routeId,
    })
      .then(() => {
        setIsEmailing(false);
        createSuccessNotification(translate('routes.alertMessages.routeImagesEmailed'));
        closeModal();
      })
      .catch(() => {
        setIsEmailing(false);
        createErrorNotification(translate('common.alertMessages.feedbackSentFail'));
      });
  };

  const subTitle =
    selectedImageBlobIds.length === 1
      ? translate('routes.xImageWillBeAttached')
      : translate('routes.xImagesWillBeAttached', { count: selectedImageBlobIds.length });

  const toggleImageBlobId = (imageBlobId: string) => {
    const newIds = [...selectedImageBlobIds];
    if (!newIds.includes(imageBlobId)) {
      newIds.push(imageBlobId);
    } else {
      remove(newIds, id => id === imageBlobId);
    }
    setSelectedImageBlobIds(newIds);
  };

  return (
    <Modal title={translate('routes.emailRouteImages')} subTitle={subTitle} onClose={closeModal} isLoading={isEmailing}>
      <ModalSection margin="medium no no">
        <EmailRouteImagesForm
          onSubmit={onSubmit}
          images={images}
          toggleImageBlobId={toggleImageBlobId}
          selectedImageBlobIds={selectedImageBlobIds}
        />
      </ModalSection>
    </Modal>
  );
};

export default EmailRouteImagesModal;
