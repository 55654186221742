import update from 'immutability-helper';

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { filter, groupBy, flatten, values, findIndex, identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { arrayMove } from 'react-sortable-hoc';
import {
  loadInsightDashboardPreferences as doLoadInsightDashboardPreferences,
  updateInsightDashboardPreferences as doSaveInsightDashboardPreferences,
} from '../services/insightDashboardPreferences';

// Actions
const START_LOAD = 'insights/insightDashboardPreferences/START_LOAD';
const COMPLETE_LOAD = 'insights/insightDashboardPreferences/COMPLETE_LOAD';
const FAIL_LOAD = 'insights/insightDashboardPreferences/FAIL_LOAD';
const REORDER = 'insights/insightDashboardPreferences/REORDER';
const TOGGLE_REPORT_TYPE = 'insights/insightDashboardPreferences/TOGGLE_REPORT_TYPE';
const START_SAVE = 'insights/insightDashboardPreferences/START_SAVE';
const COMPLETE_SAVE = 'insights/insightDashboardPreferences/COMPLETE_SAVE';
const FAIL_SAVE = 'insights/insightDashboardPreferences/FAIL_SAVE';
const RESET = 'insights/insightDashboardPreferences/RESET';

const updateUntyped = update as any;

interface InsightDashboardPreferencesStateIntreface {
  isLoading: boolean;
  isSaving: boolean;
  insightDashboardPreferences?: any;
  insightDashboardDaysOfOperation: any[];
}

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  insightDashboardPreferences: undefined,
  insightDashboardDaysOfOperation: [],
};

type Dispatch = ThunkDispatch<InsightDashboardPreferencesStateIntreface, any, AnyAction>;

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return updateUntyped(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return updateUntyped(state, {
        $merge: {
          isLoading: false,
          insightDashboardPreferences: action.insightDashboardPreferences.userDashboardPreferences,
          insightDashboardDaysOfOperation: action.insightDashboardPreferences.daysOfOperation,
        },
      });

    case FAIL_LOAD:
      return updateUntyped(state, {
        $merge: initialState,
      });

    case REORDER: {
      const { reportCategory, fromIndex, toIndex } = action;
      const preferencesByReportCategory = groupBy(state.insightDashboardPreferences, 'reportCategory');
      preferencesByReportCategory[reportCategory] = arrayMove(
        preferencesByReportCategory[reportCategory],
        fromIndex,
        toIndex,
      );

      const insightDashboardPreferences = flatten(values(preferencesByReportCategory));
      return updateUntyped(state, {
        insightDashboardPreferences: { $set: insightDashboardPreferences },
      });
    }

    case TOGGLE_REPORT_TYPE: {
      const { reportType, isActive } = action;

      const preferencesForReportTypeIndex = findIndex(state.insightDashboardPreferences, { reportType });

      return updateUntyped(state, {
        insightDashboardPreferences: {
          [preferencesForReportTypeIndex]: {
            isActive: { $set: isActive },
          },
        },
      });
    }

    case START_SAVE:
      return updateUntyped(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return updateUntyped(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return updateUntyped(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return updateUntyped(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (insightDashboardPreferences: any[]) => ({
  type: COMPLETE_LOAD,
  insightDashboardPreferences,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const reorderInsightDashboardPreferences = (reportCategory: string, fromIndex: number, toIndex: number) => ({
  type: REORDER,
  reportCategory,
  fromIndex,
  toIndex,
});

export const toggleInsightDashboardReportType = (reportType: string, isActive: boolean) => ({
  type: TOGGLE_REPORT_TYPE,
  reportType,
  isActive,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadInsightDashboardPreferences =
  (vendorId: number, insightGroupIds: any[] = []) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadInsightDashboardPreferencesPromise = doLoadInsightDashboardPreferences(vendorId, insightGroupIds);
    loadInsightDashboardPreferencesPromise
      .then(insightDashboardPreferences => {
        dispatch(completeLoad(insightDashboardPreferences as any));
      })
      .catch(() => dispatch(failLoad()));
    return loadInsightDashboardPreferencesPromise;
  };

export const saveInsightDashboardPreferences =
  (vendorId: number, insightDashboardPreferences: any[], daysOfOperation: any[]) => (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveInsightDashboardPreferencesPromise = doSaveInsightDashboardPreferences(
      vendorId,
      insightDashboardPreferences,
      daysOfOperation,
    );
    saveInsightDashboardPreferencesPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
    return saveInsightDashboardPreferencesPromise;
  };

export const resetInsightDashboardPreferences = () => ({
  type: RESET,
});

// Selectors
const getInsightsDashboardPreferences = (
  insightDashboardPreferencesState: InsightDashboardPreferencesStateIntreface,
  reportType: string,
) => filter(insightDashboardPreferencesState.insightDashboardPreferences, { reportCategory: reportType });

export const insightsDashboardPreferencesSelector = createSelector(getInsightsDashboardPreferences, identity);

const getSelectedDaysOfOperation = (daysOfOperation: any[]) => {
  return { daysOfOperation };
};

export const insightDashboardDaysOfOperationSelector = createSelector(getSelectedDaysOfOperation, identity);
