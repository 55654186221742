import React, { Component } from 'react';

import { Radio as RadioContainer, RadioInput, CheckboxCheck, RadioText } from './styled';

interface Props {
  block?: boolean;
  checked?: boolean;
  disabled?: boolean;
  input: any;
  isStyleAsCircle?: boolean;
  label: string;
  margin?: string;
  size?: string;
  type?: string;
  value?: number | string;
}

class Radio extends Component<Props> {
  render() {
    const { block, checked, disabled, input, isStyleAsCircle, label, margin, size } = this.props;

    return (
      <RadioContainer isDisabled={disabled} block={block} size={size} margin={margin}>
        <RadioInput {...input} type="radio" disabled={disabled} checked={checked} />
        <CheckboxCheck isStyleAsCircle={isStyleAsCircle} />
        <RadioText>{label}</RadioText>
      </RadioContainer>
    );
  }
}

export default Radio;
