import React, { useCallback, useEffect, useState } from 'react';
import { filter, find } from 'lodash-es';
import { getFormValues } from 'redux-form';

import { Checkbox, Dropdown, TypedField } from 'src/core/components';
import { SegmentConfiguration, StreetSegmentAgingInterval } from '../interfaces/SnowPlowSettings';
import { TableCell, TableRow, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import CustomizedColorPicker from './styled/CustomizedColorPicker';
import translate from 'src/core/services/translate';

interface Props {
  agingInterval?: any[];
  agingIntervalErrors?: boolean[];
  agingIntervalOptions?: any[];
  blue: number;
  cooldownTimeInHours: number;
  cooldownTimeInMinutes: number;
  cooldownTimeInSec: number;
  formName: string;
  green: number;
  id: number;
  index: any;
  name: string;
  red: number;
  segmentColorSettings?: number;
  setAgingIntervalErrors?: (value: boolean[]) => void;
  snowPlowSegmentColoringTypeId: number;
  streetSegmentAgingInterval: StreetSegmentAgingInterval;
  technicalName: string;
}
const SnowPlowDisplayOptionsTableRow: React.FC<Props> = ({
  agingInterval,
  agingIntervalErrors = [],
  agingIntervalOptions,
  blue,
  formName,
  green,
  id,
  index,
  red,
  setAgingIntervalErrors,
}) => {
  const [error, setError] = useState<string>('');

  const formValues = useSelector(getFormValues(formName)) as any;
  const isEnabled = formValues?.displayConfiguration?.segmentColorSettings[index].enabled;

  const checkValidity = useCallback(
    (values: any) => {
      const displaySettings = filter(
        values?.displayConfiguration?.segmentColorSettings,
        el => el.enabled,
      ) as SegmentConfiguration[];
      let error = '';
      if (displaySettings.length === 1 && displaySettings[0].id === id) {
        error = translate('vendors.streetSweeper.errors.minTwoCategoriesActive');
      }
      displaySettings.forEach((el: SegmentConfiguration, i: number) => {
        if (el.id === id) {
          const interval = find(agingInterval, item => item.id === el.streetSegmentAgingInterval.id);

          if (interval) {
            if (i === 0) {
              if (interval.minValue !== 0) {
                error = translate(
                  'vendors.streetSweeper.errors.exactlyOneMandatoryStartingSegmentAgingIntervalMustBeSelected',
                );
              }
            } else {
              if (i === displaySettings.length - 1) {
                if (interval.maxValue !== null) {
                  error = translate(
                    'vendors.streetSweeper.errors.exactlyOneMandatoryEndingSegmentAgingIntervalMustBeSelected',
                  );
                }
              }
              const previousInterval = find(
                agingInterval,
                item => item.id === displaySettings[i - 1].streetSegmentAgingInterval.id,
              );
              if (previousInterval && interval.minValue !== previousInterval.maxValue) {
                error = translate('vendors.streetSweeper.errors.streetSegmentAgingIntervalMustBeConsecutive');
              }
            }
          }
        }
      });
      return error;
    },
    [agingInterval, id],
  );

  useEffect(() => {
    const errorTo = checkValidity(formValues);

    if (error !== errorTo) {
      setError(errorTo);
    }
  }, [formValues, error, checkValidity, id]);

  useEffect(() => {
    if (error.length && !agingIntervalErrors[index]) {
      const errorsCopy = [...agingIntervalErrors];
      errorsCopy[index] = true;
      setAgingIntervalErrors && setAgingIntervalErrors(errorsCopy);
    } else {
      if (!error.length && agingIntervalErrors[index]) {
        const errorsCopy = [...agingIntervalErrors];
        errorsCopy[index] = false;
        setAgingIntervalErrors && setAgingIntervalErrors(errorsCopy);
      }
    }
  }, [error, setAgingIntervalErrors, agingIntervalErrors, index]);

  return (
    <>
      <TableRow borderBottom={`${!!error.length && '0px'}`}>
        <TableCell width="40%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
          <TypedField name={`displayConfiguration.segmentColorSettings[${index}].enabled`} component={Checkbox} />
          <Text margin="no no no small">
            {translate('common.timeFrame')} {index + 1}
          </Text>
        </TableCell>

        <TableCell width="40%">
          <TypedField
            name={`displayConfiguration.segmentColorSettings[${index}].streetSegmentAgingInterval.id`}
            component={Dropdown}
            props={{
              width: '135px',
              label: translate('vendors.lastServicedTime'),
              options: agingIntervalOptions,
              disabled: !isEnabled,
            }}
          />
        </TableCell>
        <TableCell width="20%" position="relative">
          <TypedField
            name={`displayConfiguration.segmentColorSettings[${index}].color`}
            component={CustomizedColorPicker}
            props={{ disabled: !isEnabled, color: { r: red, g: green, b: blue } }}
          />
        </TableCell>
      </TableRow>

      {!!error.length && (
        <TableRow>
          <Text padding="no no xSmall xSmall" color="alert">
            {error}
          </Text>
        </TableRow>
      )}
    </>
  );
};

export default SnowPlowDisplayOptionsTableRow;
