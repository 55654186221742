import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { PureComponent, Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { orderBy } from 'lodash-es';

import { AppState } from '../../../store';
import {
  currentUserIdSelector,
  currentVendorGusIdSelector,
  currentVendorIdSelector,
} from '../../../vendors/services/currentVendorSelector';
import CompliancePage from './CompliancePage';
import { DocumentTitle } from '../../../common/components';
import { Districts } from '../../../vendors/interfaces/DivisionAccess';
import {
  HAULER_PROFILE_DIVISION_COOKIE,
  HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
} from '../../constants/HaulerProfileDivisionCookie';
import { loadCompliance } from '../../ducks';
import { loadCOIStatus } from '../../ducks/compliance';
import { loadHaulerProfileDivisions } from '../../ducks/divisions';

import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  gusId?: string;
  userId?: string;
  vendorId: number;
  loadCompliance(...args: any[]): any;
  loadCOIStatus(...args: any[]): any;
  loadHaulerProfileDivisions(...args: any[]): any;
}

class CompliancePageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const { loadCompliance, loadCOIStatus, loadHaulerProfileDivisions, gusId, vendorId } = this.props;
    const divisionIdFromCookie = Cookie.get(HAULER_PROFILE_DIVISION_COOKIE);
    return divisionIdFromCookie
      ? Promise.all([
          loadCompliance(gusId, divisionIdFromCookie),
          loadCOIStatus(gusId, divisionIdFromCookie),
          loadHaulerProfileDivisions(vendorId),
        ])
      : loadHaulerProfileDivisions(vendorId).then((response: Districts[]) => {
          const allDivisions = response;
          if (allDivisions.length > 0) {
            const assignedDivisionsOrderByName = orderBy(allDivisions, 'name', 'asc');
            const divisionId = assignedDivisionsOrderByName[0].value;
            Cookie.set(HAULER_PROFILE_DIVISION_COOKIE, divisionId, {
              expires: HAULER_PROFILE_DIVISION_COOKIE_EXPIRATION,
            });
            return Promise.all([loadCompliance(gusId, divisionId), loadCOIStatus(gusId, divisionId)]);
          }
        });
  };
  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('haulerProfile.compliance')}</DocumentTitle>
        <Resolver resolve={this.loadDependencies} successComponent={CompliancePage} loadingComponent={PageLoading} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  gusId: currentVendorGusIdSelector(state.account.login, state.vendors.defaultVendor),
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  userId: currentUserIdSelector(state.account.login),
});

const mapDispatchToProps = {
  loadCompliance,
  loadCOIStatus,
  loadHaulerProfileDivisions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompliancePageResolver);
