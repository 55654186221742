import React, { Fragment } from 'react';

import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { loadXDeviceStatuses } from '../../ducks/deviceStatuses';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import translate from '../../../core/services/translate';
import XDeviceStatusesPage from './XDeviceStatusesPage';
import { loadUserById, loadVendor } from 'src/vendors/ducks';
import { AppState } from '../../../store';

const XDeviceStatusesPageResolver: React.FC = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);
  const user = useSelector((state: AppState) => state.account.login.user);
  return (
    <Fragment>
      <DocumentTitle>{translate('vendors.manageDevices')}</DocumentTitle>
      <Resolver
        successComponent={XDeviceStatusesPage}
        loadingComponent={PageLoading}
        resolve={() => {
          return Promise.all([
            loadUserById(vendorId, user.userId)(dispatch),
            loadVendor(vendorId)(dispatch),
            loadXDeviceStatuses(vendorId)(dispatch).catch(() => {
              dispatch(push('/account-settings'));
              createErrorNotification(translate('vendors.alertMessages.manageDevicesError'));
            }),
          ]);
        }}
      />
    </Fragment>
  );
};

export default XDeviceStatusesPageResolver;
