import { isPristine } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../store';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { Modal } from '../../../core/components';
import { ModalSection } from '../../../core/components/styled';
import { saveVehicleTrackingSettings } from '../../ducks';
import { VehicleTrackingSettings } from '../../interfaces/VehicleTracking';
import translate from '../../../core/services/translate';
import VehicleTrackingForm from '../forms/VehicleTrackingForm';

type Props = {
  closeModal: (pristine?: boolean) => void;
  vendorId: number;
};

export default function VehicleTrackingModal({ closeModal, vendorId }: Props) {
  const dispatch = useDispatch();

  const { isLoading, isSaving, vehicleTrackingSettings } = useSelector(
    (state: AppState) => state.vendors.vehicleTrackingSettings,
  );
  const formPristine = useSelector((state: AppState) => isPristine('vehicleTrackingForm')(state));

  const vehicleTrackingSettingsInitialValues = vehicleTrackingSettings || ({} as VehicleTrackingSettings);
  const vehicleTrackingFrequency = vehicleTrackingSettingsInitialValues.subscriptionBasedFrequencyConfigurations?.find(
    config => config.isActive,
  )?.vehicleTrackingFrequencyValue;
  const initialValues = {
    ...vehicleTrackingSettingsInitialValues,
    vehicleTrackingFrequency,
  };

  const handleSubmit = (formData: VehicleTrackingSettings) => {
    // TODO: Uncomment this when we have subscriptionBasedFrequencyConfigurations on the response

    // const vehicleTrackingFrequency = vehicleTrackingSettings?.subscriptionBasedFrequencyConfigurations?.find(
    // config => config.vehicleTrackingFrequencyValue === formData.vehicleTrackingFrequency,
    // );
    const formDataVehicleTrackingSettings = {
      ...formData,
      // subscriptionBasedFrequencyConfigurations: [{ ...vehicleTrackingFrequency, isActive: true }],
    };

    saveVehicleTrackingSettings(
      vendorId,
      formDataVehicleTrackingSettings,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.vehicleTrackingSettingsSaved'));
        closeModal();
      })
      .catch(() => createErrorNotification(translate('vendors.alertMessages.vehicleTrackingSettingsError')));
  };

  const closeCurrentModal = (pristine: boolean) => {
    closeModal(pristine);
  };

  const tryCloseModal = () => {
    closeModal(formPristine);
  };

  return (
    <Modal
      title={translate('vendors.featureCodes.vehicleTracking')}
      padding="medium no no"
      size="small"
      onClose={tryCloseModal}
      isLoading={isLoading || isSaving}
    >
      <ModalSection>
        <VehicleTrackingForm initialValues={initialValues} onSubmit={handleSubmit} onCancel={closeCurrentModal} />
      </ModalSection>
    </Modal>
  );
}
