import { useEffect, useState } from 'react';

interface VisibilityProps {
  revisitAction?: () => void;
  leaveAction?: () => void;
}

const useWindowFocusChange = ({ revisitAction, leaveAction }: VisibilityProps) => {
  const [isWindowFocused, setIsWindowFocused] = useState(true);
  const [wasWindowFocused, setWasWindowFocused] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsWindowFocused(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!isWindowFocused && wasWindowFocused) {
      leaveAction && leaveAction();
    }

    setWasWindowFocused(isWindowFocused);
  }, [isWindowFocused, wasWindowFocused, leaveAction]);

  useEffect(() => {
    if (isWindowFocused && !wasWindowFocused) {
      revisitAction && revisitAction();
    }
  }, [isWindowFocused, wasWindowFocused, revisitAction]);

  return { isWindowFocused, wasWindowFocused };
};

export default useWindowFocusChange;
