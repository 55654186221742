import { FC } from 'react';

import { useSelector } from 'src/core/hooks/useSelector';
import StreetNetworkDataGLSource from './StreetNetworkDataGLSource';

const StreetNetworkDataGL: FC<{ zoomClassNumber?: number }> = ({ zoomClassNumber }) => {
  const { streetNetwork, streetNetworkWithBbox } = useSelector(state => state.customers.streetNetworkDataLayer);

  const json =
    !!streetNetwork.length &&
    !!zoomClassNumber &&
    streetNetwork?.find(streetNetworkLayer => streetNetworkLayer.zoomClassNumber === zoomClassNumber);

  const geoJSON = !!json ? json.data : streetNetworkWithBbox;

  if (!geoJSON) return null;

  return <StreetNetworkDataGLSource geoJSON={geoJSON} />;
};

export default StreetNetworkDataGL;
