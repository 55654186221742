import { ChangeEvent, MouseEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { find, get, map, reduce, size } from 'lodash-es';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import Cookie from 'js-cookie';
import moment from 'moment';

import { AppState } from 'src/store';
import { AddToGroupsFormValues } from '../../forms/AddToGroupsForm';
import { AddToGroupsModalResolver, RouteRescheduleModal } from '../../modals';
import { PermissionGuard } from 'src/account/components';
import {
  ROUTES_TRACKER_COPY,
  ROUTES_TRACKER_DELETE,
  ROUTES_TRACKER_EXPORT,
  ROUTES_TRACKER_SEQUENCE_ROUTE,
} from 'src/account/constants';
import { hasPermissionSelector } from 'src/account/ducks';
import { SESSION_COOKIE_KEY } from 'src/account/services/session';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageFooter,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { getIsVendorNotChanged } from 'src/common/utils/vendor';
import { DuckFunction } from 'src/contracts/ducks';
import { Table, UnconnectedCheckbox } from 'src/core/components';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import { LIMIT_PER_PAGE, TABLE_ROW_HEIGHT_LARGE, TOP } from 'src/core/constants';
import confirm from 'src/core/services/confirm';
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification,
} from 'src/core/services/createNotification';
import createPopover from 'src/core/services/createPopover';
import informationPrompt from 'src/core/services/informationPrompt';
import translate from 'src/core/services/translate';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { loadVehicleTypesForVendor, resetVehicleTypesForVendor } from 'src/fleet/ducks';
import { VehicleType } from 'src/fleet/interfaces/VehicleTypes';
import { RouteCloneForm, RouteResetForm, RouteTrackerForm } from 'src/routes/components/forms';
import {
  CANCELED,
  COMPLETE,
  FAILED,
  FAILED_CONFIRMED,
  PENDING,
  SCHEDULED,
  SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
} from 'src/routes/constants';
import {
  bulkDeleteRouteTrackers,
  cloneRouteTracker,
  resetRouteTracker,
  deleteRouteTracker,
  exportSnowOrSweeperRoutesTracker,
  loadRouteSequenceStatusByVendor,
  loadRoutesResourceAvailability,
  loadSnowOrSweeperRoutesHeader,
  loadSnowOrSweeperRoutesTracker,
  rescheduleRouteTrackers,
  resetRoutesTracker,
  updateRouteSequenceStatus,
  assignRoutesToGroups,
  loadNextRunNumber,
} from 'src/routes/ducks';
import {
  CloneRouteData,
  ResetRouteData,
  Route,
  RouteSequenceStatus,
  RoutesResourceAvailability,
  RoutesSummary,
  RouteStatuses,
} from 'src/routes/interfaces/Route';
import { ServiceZone } from 'src/routes/interfaces/ServiceZones';
import { Supervisor } from 'src/routes/interfaces/Supervisors';
import { getAllWithOptionNone } from 'src/routes/services/dispatchBoardGetAllWithOptionNone';
import {
  getIsDriverUnavailable,
  getIsVehicleUnavailable,
  getRouteNames,
  getRoutesResourceAvailabilityTranslation,
} from 'src/routes/utils/routeDetails';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import { RouteReschedulerFormValues } from '../../forms/RouteRescheduleForm';
import { RouteReschedulesErrorsModalContent } from '../../modals/RouteRescheduleErrorsModalContent';
import { routeSequencingStatusSelector } from 'src/vendors/ducks';
import { RouteTrackerFormValues } from '../../forms/RouteTrackerForm';
import { RouteTrackerSummary } from './routePageSections';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import RouteTrackerTableRow from './RouteTrackerTableRow';

const MAX_TIME_DIFF = 30;

export type AdvRoute = Route & { routeId: number };

interface Props extends RouteComponentProps {
  assignRoutesToGroups: DuckFunction<typeof assignRoutesToGroups>;
  bulkDeleteRouteTrackers: DuckFunction<typeof bulkDeleteRouteTrackers>;
  cloneRouteTracker: DuckFunction<typeof cloneRouteTracker>;
  deleteRouteTracker: DuckFunction<typeof deleteRouteTracker>;
  exportSnowOrSweeperRoutesTracker: DuckFunction<typeof exportSnowOrSweeperRoutesTracker>;
  filtersPreferences?: FilterSetting[];
  hasRouteCopyPermission: boolean;
  hasRouteDeletePermission: boolean;
  hasRouteSequencePermission: boolean;
  isLoading: boolean;
  loadNextRunNumber: DuckFunction<typeof loadNextRunNumber>;
  loadRouteSequenceStatusByVendor: DuckFunction<typeof loadRouteSequenceStatusByVendor>;
  loadRoutesResourceAvailability: DuckFunction<typeof loadRoutesResourceAvailability>;
  loadsDumped: boolean;
  loadSnowOrSweeperRoutesHeader: DuckFunction<typeof loadSnowOrSweeperRoutesHeader>;
  loadSnowOrSweeperRoutesTracker: DuckFunction<typeof loadSnowOrSweeperRoutesTracker>;
  materialPickUpTicket: boolean;
  push: typeof push;
  rescheduleRouteTrackers: DuckFunction<typeof rescheduleRouteTrackers>;
  reset: typeof reset;
  resetRoutesTracker: typeof resetRoutesTracker;
  resetRouteTracker: DuckFunction<typeof resetRouteTracker>;
  resetVehicleTypesForVendor: typeof resetVehicleTypesForVendor;
  routeSequencingEnabled: boolean;
  routesResourceAvailability: RoutesResourceAvailability[];
  routeStatuses?: RouteStatuses[];
  serviceZones: ServiceZone[];
  snowOrSeeperRoutes: Array<AdvRoute>;
  snowOrSweeperRoutesHeader?: RoutesSummary;
  supervisors: Supervisor[];
  total: number;
  updateRouteSequenceStatus: DuckFunction<typeof updateRouteSequenceStatus>;
  vehicleTypeIds: string;
  vehicleTypes?: VehicleType[];
  vendorId: number;
  waterFills: boolean;
}

interface State {
  isAddToGroupsModalOpen: boolean;
  isSchedulingModalOpen: boolean;
  routeList: Array<AdvRoute>;
  routeStatuses?: Array<RouteStatuses & { showedNotification: boolean }>;
  selectedRouteIdsToDelete: number[];
  selectedRoutesToReschedule: AdvRoute[];
}

class SnowOrSweeperTrackerPage extends PureComponent<Props, State> {
  refreshRouteSequenceStatus?: number;
  closePopover?: () => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      isAddToGroupsModalOpen: false,
      isSchedulingModalOpen: false,
      routeList: size(props.snowOrSeeperRoutes) ? props.snowOrSeeperRoutes.slice(0, LIMIT_PER_PAGE) : [],
      routeStatuses: map(props.routeStatuses, routeStatus => ({
        ...routeStatus,
        showedNotification: true,
      })),
      selectedRouteIdsToDelete: [],
      selectedRoutesToReschedule: [],
    };
  }

  componentDidMount = () => {
    this.getBatchedRoutes();
    this.refreshRouteSequenceStatus = window.setInterval(async () => {
      const { vendorId, loadRouteSequenceStatusByVendor } = this.props;
      if (getIsVendorNotChanged(vendorId) && Cookie.get(SESSION_COOKIE_KEY)) {
        await loadRouteSequenceStatusByVendor(vendorId, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE);
      }
    }, 60000);
  };

  componentDidUpdate(prevProps: Props) {
    const {
      loadRouteSequenceStatusByVendor,
      loadSnowOrSweeperRoutesTracker,
      loadSnowOrSweeperRoutesHeader,
      location,
      reset,
      routeStatuses = [],
      snowOrSeeperRoutes,
      vehicleTypeIds,
      vendorId,
    } = this.props;

    if (prevProps.snowOrSeeperRoutes !== snowOrSeeperRoutes) {
      const routeList = size(snowOrSeeperRoutes) ? snowOrSeeperRoutes.slice(0, LIMIT_PER_PAGE) : [];
      this.setState({
        routeList,
        isSchedulingModalOpen: false,
        selectedRouteIdsToDelete: [],
        selectedRoutesToReschedule: [],
      });
      this.getBatchedRoutes();
    }

    if (prevProps.location.search !== location.search) {
      const {
        searchTerm,
        startDate,
        endDate,
        routeStatusTypeIds,
        serviceZones,
        supervisors,
        priorityTypeIds,
        groupIds,
        agingIntervalIds,
      } = getQueryParams(location.search);

      loadSnowOrSweeperRoutesTracker({
        vendorId,
        searchTerm,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        serviceZones,
        supervisors,
        useUserGlobalFilters: !(serviceZones || supervisors || vehicleTypeIds),
        groupIds,
        priorityTypeIds,
        agingIntervalIds,
      }).then(data => {
        loadRouteSequenceStatusByVendor(vendorId, SEQUENCE_SOURCE_TYPE_DAILY_ROUTE);

        if (prevProps.routeStatuses !== routeStatuses) {
          this.updateRouteStatuses(routeStatuses);
        }
        if (prevProps.snowOrSeeperRoutes !== data) {
          this.getBatchedRoutes();
        }
      });

      loadSnowOrSweeperRoutesHeader({
        vendorId,
        searchTerm,
        vehicleTypeIds,
        routeStatusTypeIds,
        startDate,
        endDate,
        serviceZones,
        supervisors,
        useUserGlobalFilters: !(serviceZones || supervisors || vehicleTypeIds),
        groupIds,
        priorityTypeIds,
        agingIntervalIds,
      });
    }

    reset('routeTracker');
  }

  componentWillUnmount() {
    const { resetRoutesTracker, resetVehicleTypesForVendor } = this.props;
    resetRoutesTracker();
    resetVehicleTypesForVendor();
    window.clearInterval(this.refreshRouteSequenceStatus);
  }

  onSubmit = ({
    agingIntervalIds = [],
    date,
    priorityTypeIds,
    routeStatusTypeIds,
    searchTerm,
    serviceZones = [],
    groupIds = [],
    supervisors = [],
  }: RouteTrackerFormValues) => {
    const {
      location: { pathname, search },
      push,
      vehicleTypeIds,
    } = this.props;
    const { from, to } = date;

    push(
      createUrl(pathname, search, {
        startDate: from,
        endDate: to,
        routeStatusTypeIds: routeStatusTypeIds && routeStatusTypeIds.toString(),
        vehicleTypeIds,
        searchTerm,
        groupIds: groupIds && groupIds.toString(),
        serviceZones: serviceZones && serviceZones.toString(),
        supervisors: supervisors && supervisors.toString(),
        priorityTypeIds: priorityTypeIds && priorityTypeIds.toString(),
        agingIntervalIds: agingIntervalIds && agingIntervalIds.toString(),
      }),
    );
  };

  onCloneRouteFormSubmit = async (routeId: number, data: CloneRouteData) => {
    const { cloneRouteTracker, loadRoutesResourceAvailability } = this.props;

    loadRoutesResourceAvailability([{ routeId, date: data.routeCloneDate }]).then(
      async (routesResourceAvailability: RoutesResourceAvailability[]) => {
        const isDriverUnavailable = getIsDriverUnavailable(routesResourceAvailability);
        const isVehicleUnavailable = getIsVehicleUnavailable(routesResourceAvailability);

        if (isDriverUnavailable || isVehicleUnavailable) {
          if (!(await confirm(getRoutesResourceAvailabilityTranslation(isDriverUnavailable, isVehicleUnavailable)))) {
            return;
          }
        }

        cloneRouteTracker(routeId, data)
          .then(() => {
            !!this.closePopover && this.closePopover();
            createSuccessNotification(translate('routes.alertMessages.routeCloned'));
            this.reloadRoutes();
          })
          .catch(({ code }) => {
            createErrorNotification(
              `${translate(createTranslationKey(code, 'routes.alertMessages'), { routeDate: data.routeCloneDate })}`,
            );
          });
      },
    );
  };

  onResetRouteFormSubmit = (routeId: number, isSnowPlowRoute: boolean, data: ResetRouteData) => {
    const { resetRouteTracker } = this.props;

    resetRouteTracker(routeId, data)
      .then(response => {
        !!this.closePopover && this.closePopover();
        createSuccessNotification(translate('routes.alertMessages.routeReseted'));

        const { push } = this.props;
        const routeUrl = isSnowPlowRoute ? 'snow-tracker' : 'sweeper-tracker';
        push(`/routes/${routeUrl}/${response.id}`);
      })
      .catch(({ code }) => {
        createErrorNotification(
          `${translate(createTranslationKey(code, 'routes.alertMessages'), { routeDate: data.date })}`,
        );
      });
  };

  getBatchedRoutes = () => {
    setTimeout(() => {
      const { snowOrSeeperRoutes } = this.props;
      const { routeList } = this.state;
      const hasMore = routeList.length + 1 < snowOrSeeperRoutes.length;

      this.setState((prev, props) => ({
        routeList: props.snowOrSeeperRoutes.slice(0, prev.routeList.length + LIMIT_PER_PAGE),
      }));
      if (hasMore) this.getBatchedRoutes();
    }, 0);
  };

  updateRouteStatuses = (newRouteStatuses: any[]) => {
    const routeStatuses = map(newRouteStatuses, routeStatus => {
      const showedNotification = get(
        find(this.state.routeStatuses, { jobId: routeStatus.jobId }),
        'showedNotification',
        false,
      );
      if (!showedNotification) {
        switch (routeStatus.status) {
          case PENDING: {
            createWarningNotification(translate('routes.alertMessages.routeSequenceRequestProcessing'));
            break;
          }
          case COMPLETE:
            createSuccessNotification(translate('routes.alertMessages.routeSequenceRequestCompleted'));
            break;

          case FAILED:
            createErrorNotification(translate('routes.alertMessages.routeSequenceRequestFailed'));
            break;

          default:
            break;
        }
      }
      return {
        ...routeStatus,
        showedNotification: true,
      };
    });
    this.setState({ routeStatuses });
  };

  handleTableRowClick = async (
    routeDetailId: number,
    routeId: number,
    routeSequenceStatus: RouteSequenceStatus,
    jobId: number,
    isYRoute: boolean,
    isEdit: boolean,
    isSnowPlowRoute: boolean,
  ) => {
    const { hasRouteSequencePermission, push, routeSequencingEnabled, updateRouteSequenceStatus } = this.props;

    const routeUrl = isSnowPlowRoute ? 'snow-tracker' : 'sweeper-tracker';

    if (routeSequencingEnabled && hasRouteSequencePermission) {
      switch (routeSequenceStatus) {
        case PENDING: {
          if (!(await confirm(translate('routes.alertMessages.confirmCancelSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: routeId,
            jobId,
            status: CANCELED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
          }).then(() => push(`/routes/${routeUrl}/${routeId}`));
          break;
        }
        case COMPLETE:
          push(`/routes/${routeUrl}/${routeId}/route-sequence`);
          break;

        case FAILED:
          if (!(await informationPrompt(translate('routes.alertMessages.confirmFailedRouteSequenceRequest')))) {
            break;
          }
          updateRouteSequenceStatus({
            routeid: routeId,
            jobId,
            status: FAILED_CONFIRMED,
            sequenceSourceTypeId: SEQUENCE_SOURCE_TYPE_DAILY_ROUTE,
          }).then(() => push(`/routes/${routeUrl}/${routeId}${isEdit ? '/edit' : ''}`));
          break;

        default:
          push(`/routes/${routeUrl}/${routeId}${isEdit ? '/edit' : ''}`);
      }
    } else {
      push(`/routes/${routeUrl}/${routeId}${isEdit ? '/edit' : ''}`);
    }
  };

  cloneRoute = (routeId: number, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    this.closePopover = createPopover(
      event.currentTarget,
      RouteCloneForm,
      { onSubmit: (data: CloneRouteData) => this.onCloneRouteFormSubmit(routeId, data) },
      { position: TOP, zIndex: 6000 },
    );
  };

  resetSnowOrSweeperRoute = async (
    routeId: number,
    routeName: string,
    isSnowPlowRoute: boolean,
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();

    const { loadNextRunNumber } = this.props;
    loadNextRunNumber(routeId);

    this.closePopover = createPopover(
      event.currentTarget,
      RouteResetForm,
      { onSubmit: (data: ResetRouteData) => this.onResetRouteFormSubmit(routeId, isSnowPlowRoute, data), routeName },
      { position: TOP, zIndex: 6000 },
    );
  };

  exportRoutes = () => {
    const { vendorId, exportSnowOrSweeperRoutesTracker, location, vehicleTypeIds } = this.props;

    const { endDate, startDate } = getQueryParams(location.search);

    exportSnowOrSweeperRoutesTracker(vendorId, parseInt(vehicleTypeIds), startDate, endDate);
  };

  deleteRoute = async (routeId: number, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { deleteRouteTracker } = this.props;
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteRoute')))) {
      return;
    }

    deleteRouteTracker(routeId).then(() => {
      this.reloadRoutes();
    });
  };

  reloadRoutes = () => {
    const { vendorId, location, vehicleTypeIds, loadSnowOrSweeperRoutesTracker, loadSnowOrSweeperRoutesHeader } =
      this.props;

    const {
      searchTerm,
      startDate,
      endDate,
      routeStatusTypeIds,
      serviceZones,
      supervisors,
      priorityTypeIds,
      agingIntervalIds,
      groupIds,
    } = getQueryParams(location.search);

    loadSnowOrSweeperRoutesTracker({
      vendorId,
      searchTerm,
      vehicleTypeIds,
      routeStatusTypeIds,
      startDate,
      endDate,
      serviceZones,
      supervisors,
      useUserGlobalFilters: !(serviceZones || supervisors || vehicleTypeIds),
      groupIds,
      priorityTypeIds,
      agingIntervalIds,
    });

    loadSnowOrSweeperRoutesHeader({
      vendorId,
      searchTerm,
      vehicleTypeIds,
      routeStatusTypeIds,
      startDate,
      endDate,
      serviceZones,
      supervisors,
      useUserGlobalFilters: !(serviceZones || supervisors || vehicleTypeIds),
      groupIds,
      priorityTypeIds,
      agingIntervalIds,
    });
  };

  toggleSchedulingModal = () => {
    this.setState(({ isSchedulingModalOpen }) => ({ isSchedulingModalOpen: !isSchedulingModalOpen }));
  };

  toggleAddToGroupsModal = () => {
    this.setState(({ isAddToGroupsModalOpen }) => ({ isAddToGroupsModalOpen: !isAddToGroupsModalOpen }));
  };

  deleteRoutes = async () => {
    const { bulkDeleteRouteTrackers } = this.props;
    const { selectedRouteIdsToDelete } = this.state;
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteRoutes')))) return;
    bulkDeleteRouteTrackers(selectedRouteIdsToDelete).then(() => this.reloadRoutes());
  };

  addRoutesToGroups = ({ groupIds }: AddToGroupsFormValues) => {
    if (groupIds.length === 0) return;
    const { selectedRouteIdsToDelete } = this.state;
    const { assignRoutesToGroups } = this.props;

    assignRoutesToGroups(selectedRouteIdsToDelete, map(groupIds, Number))
      .then(() => {
        createSuccessNotification(translate('routes.alertMessages.routesAddedToGroup'));
      })
      .catch(() => {
        createErrorNotification(translate('routes.alertMessages.routesAddedToGroupError'));
      })
      .finally(() => {
        this.toggleAddToGroupsModal();
        this.setState({ selectedRouteIdsToDelete: [], selectedRoutesToReschedule: [] });
      });
  };

  submitRescheduleModal = async (formValues: RouteReschedulerFormValues) => {
    const { rescheduleRouteTrackers, loadRoutesResourceAvailability } = this.props;
    const routes = reduce(
      formValues.routes,
      (
        acc: Array<{
          routeId: number;
          newRouteDate?: Date | string;
          date?: Date | string;
          isDriverAvailable?: boolean;
          isVehicleAvailable?: boolean;
        }>,
        route,
      ) => {
        if (route.newRouteDate)
          acc.push({
            routeId: route.routeId,
            newRouteDate: route.newRouteDate,
            date: route.newRouteDate,
          });
        return acc;
      },
      [],
    );

    loadRoutesResourceAvailability(routes).then(async routesResourceAvailability => {
      const isDriverUnavailable = getIsDriverUnavailable(routesResourceAvailability);
      const isVehicleUnavailable = getIsVehicleUnavailable(routesResourceAvailability);
      const routesName = getRouteNames(routesResourceAvailability);

      if (isDriverUnavailable || isVehicleUnavailable) {
        if (
          !(await confirm(
            getRoutesResourceAvailabilityTranslation(isDriverUnavailable, isVehicleUnavailable),
            `${translate('routes.routes')}: ${routesName.join(', ')}`,
          ))
        ) {
          return;
        }
      }

      rescheduleRouteTrackers(routes).then(errorReschedules => {
        if (errorReschedules.length) {
          createWarningNotification(
            translate('routes.rescheduleErrors.rescheduleErrors', {
              numberOfErrors: (errorReschedules as any[]).length,
            }),
            20000,
            () => <RouteReschedulesErrorsModalContent errors={errorReschedules as any} />,
          );
        }
        this.reloadRoutes();
        this.toggleSchedulingModal();
      });
    });
  };

  checkAllRoutes = () => {
    const { snowOrSeeperRoutes } = this.props;

    const routeIdsToDelete: number[] = [];
    const routeIdsToReschedule: AdvRoute[] = [];

    snowOrSeeperRoutes.forEach(route => {
      routeIdsToDelete.push(route.routeId);

      const timeDiff = moment(route.routeDate).diff(moment(), 'days');
      if (route.routeStatusType.id === SCHEDULED && timeDiff >= 0 && timeDiff <= MAX_TIME_DIFF) {
        routeIdsToReschedule.push(route);
      }
    });

    this.setState(({ selectedRouteIdsToDelete: toDelete, selectedRoutesToReschedule: toReschedule }) => {
      let selectedRouteIdsToDelete: number[] = [];
      let selectedRoutesToReschedule: AdvRoute[] = [];

      if (toDelete.length === 0 && toReschedule.length === 0) {
        selectedRouteIdsToDelete = routeIdsToDelete;
        selectedRoutesToReschedule = routeIdsToReschedule;
      }

      return {
        selectedRouteIdsToDelete,
        selectedRoutesToReschedule,
      };
    });
  };

  checkRoute = (routeId: number, e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const { snowOrSeeperRoutes } = this.props;

    this.setState(({ selectedRouteIdsToDelete, selectedRoutesToReschedule }) => {
      const updatedSelectedRouteIdsToDelete = [...selectedRouteIdsToDelete];
      const updatedSelectedRouteIdsToReschedule = [...selectedRoutesToReschedule];

      const indexDelete = updatedSelectedRouteIdsToDelete.indexOf(routeId);
      if (indexDelete > -1) {
        updatedSelectedRouteIdsToDelete.splice(indexDelete, 1);
      } else {
        updatedSelectedRouteIdsToDelete.push(routeId);
      }

      const route = snowOrSeeperRoutes.find(r => r.routeId === routeId);

      if (!!route) {
        const indexReschedule = updatedSelectedRouteIdsToReschedule.indexOf(route);
        const timeDiff = moment(route.date).diff(moment(), 'days');
        if (indexReschedule > -1) {
          updatedSelectedRouteIdsToReschedule.splice(indexReschedule, 1);
        } else if (route.routeStatusType.id === SCHEDULED && timeDiff >= 0 && timeDiff <= MAX_TIME_DIFF) {
          updatedSelectedRouteIdsToReschedule.push(route);
        }
      }

      return {
        selectedRouteIdsToDelete: updatedSelectedRouteIdsToDelete,
        selectedRoutesToReschedule: updatedSelectedRouteIdsToReschedule,
      };
    });
  };

  render() {
    const {
      filtersPreferences,
      hasRouteCopyPermission,
      hasRouteDeletePermission,
      hasRouteSequencePermission,
      isLoading,
      loadsDumped,
      location,
      materialPickUpTicket,
      routeSequencingEnabled,
      routeStatuses,
      snowOrSeeperRoutes,
      snowOrSweeperRoutesHeader,
      vehicleTypeIds,
      waterFills,
    } = this.props;

    const {
      isAddToGroupsModalOpen,
      isSchedulingModalOpen,
      routeList,
      selectedRouteIdsToDelete,
      selectedRoutesToReschedule,
    } = this.state;

    // this should never happen, because we load data in the resolver, but
    // we have to please the gods of TypeScript
    if (!snowOrSweeperRoutesHeader) return null;

    const checkCell =
      !checkIfViewOnly() && !checkIfSupport()
        ? {
            name: 'selectAll',
            component: UnconnectedCheckbox,
            componentProps: {
              onChange: this.checkAllRoutes,
              checked: selectedRouteIdsToDelete.length === snowOrSeeperRoutes.length,
              partial:
                0 < selectedRouteIdsToDelete.length && selectedRouteIdsToDelete.length < snowOrSeeperRoutes.length,
            },
            width: '3%',
            padding: 'defaultCellVertical xSmall',
            noPaddingRight: true,
            onClick: (e: MouseEvent<HTMLElement>) => e.stopPropagation(),
          }
        : {
            name: 'selectAll',
            width: '3%',
            padding: 'defaultCellVertical xSmall',
            noPaddingRight: true,
          };

    const routeTrackerTableCells = [
      checkCell,
      {
        name: 'date',
        label: translate('common.date'),
        width: '9%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        sortable: true,
      },
      {
        name: 'nowServicingVehicleName',
        label: translate('vehicles.vehicleNowServicing'),
        width: '12%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        sortable: true,
      },
      {
        name: 'lastServicedVehicleName',
        label: translate('vehicles.lastServiced'),
        width: '12%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        sortable: true,
      },
      {
        name: 'routeName',
        label: translate('routes.route'),
        width: '14%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        sortable: true,
      },
      {
        name: 'segmentsCount',
        label: translate('routes.segments'),
        width: '9%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        sortable: true,
      },
      {
        name: 'routeProgress',
        label: translate('insights.routeProgress'),
        width: '13.5%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        sortable: false,
      },
      {
        name: 'routeStatusTypeTechnicalName',
        label: translate('common.status'),
        width: '10%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        sortable: true,
      },
      {
        name: 'events',
        label: translate('common.events'),
        width: '20.5%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
      },
      {
        name: 'options',
        label: (hasRouteCopyPermission || hasRouteDeletePermission) && translate('common.options'),
        width: '9%',
        padding: 'defaultCellVertical xSmall',
        noPaddingRight: true,
        align: 'right',
      },
    ];

    const routeOptions = map(routeList, route => {
      const routeSequence = find(routeStatuses, { routeId: route.routeId });
      return {
        ...route,
        routeSequenceStatus: get(routeSequence, 'status'),
        jobId: get(routeSequence, 'jobId'),
        isChecked: selectedRouteIdsToDelete.includes(route.routeId),
      };
    });

    const virtualizedProps = {
      height: Math.min(snowOrSeeperRoutes.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
      itemSize: TABLE_ROW_HEIGHT_LARGE,
    };

    const isSnowPlowRoute = Number(vehicleTypeIds) === SNOW_PLOW_ID;
    const isStreetSweeperRoute = Number(vehicleTypeIds) === STREET_SWEEPER_ID;

    return (
      <PageContent>
        <DesktopWidthView>
          <PageHeader>
            <PageDetails>
              <PageTitleContainer>
                <PageTitle>{translate(isSnowPlowRoute ? 'routes.snowTracker' : 'routes.sweeperTracker')}</PageTitle>
              </PageTitleContainer>
            </PageDetails>
            <PageActions>
              <PermissionGuard permission={ROUTES_TRACKER_EXPORT}>
                <Button id="export-routes-button" color="primary" onClick={this.exportRoutes} margin="no">
                  {translate('common.export')}
                </Button>
              </PermissionGuard>
            </PageActions>
          </PageHeader>
        </DesktopWidthView>

        <Panel>
          <PanelSectionGroup isLoading={isLoading}>
            <RouteTrackerForm
              onSubmit={this.onSubmit}
              isSnowPlowRoute={isSnowPlowRoute}
              isStreetSweeperRoute={isStreetSweeperRoute}
              isTrackerPage
            />
            <PanelSection>
              <RouteTrackerSummary
                routesSummary={snowOrSweeperRoutesHeader}
                location={location}
                isSnowPlowRoute={isSnowPlowRoute}
                isStreetSweeperRoute={isStreetSweeperRoute}
              />
            </PanelSection>
            <PanelSection>
              {!!size(snowOrSeeperRoutes) && (
                <Table
                  cells={routeTrackerTableCells}
                  noOverflow
                  rowComponent={RouteTrackerTableRow}
                  rowProps={{
                    checkRoute: this.checkRoute,
                    cloneRoute: this.cloneRoute,
                    deleteRoute: this.deleteRoute,
                    filtersPreferences,
                    handleTableRowClick: this.handleTableRowClick,
                    hasRouteSequencePermission,
                    isSnowOrSweeperTrackerPage: true,
                    loadsDumped,
                    materialPickUpTicket,
                    resetSnowOrSweeperRoute: this.resetSnowOrSweeperRoute,
                    routeSequencingEnabled,
                    totalRows: size(snowOrSeeperRoutes),
                    waterFills,
                  }}
                  rows={routeOptions}
                  scrollMarker
                  virtualized
                  virtualizedProps={virtualizedProps}
                  withClickableRows
                />
              )}
            </PanelSection>
            {!checkIfViewOnly() &&
              !checkIfSupport() &&
              (!!selectedRouteIdsToDelete.length || !!selectedRoutesToReschedule.length) && (
                <PanelSection centered padding="no">
                  <PageFooter>
                    {!!selectedRouteIdsToDelete.length && (
                      <Button
                        id="delete-route-button"
                        color="alert"
                        line
                        onClick={this.deleteRoutes}
                        margin="no small no no"
                      >
                        {`${translate('routes.deleteRoutes')} (${size(selectedRouteIdsToDelete)})`}
                      </Button>
                    )}
                    {!!selectedRoutesToReschedule.length && (
                      <Button
                        id="reschedule-route-button"
                        color="primary"
                        onClick={this.toggleSchedulingModal}
                        margin="no small no no"
                      >
                        {`${translate('routes.rescheduleRoutes')} (${size(selectedRoutesToReschedule)})`}
                      </Button>
                    )}
                    {!!selectedRouteIdsToDelete.length && (
                      <Button
                        id="add-to-groups-button"
                        color="primary"
                        onClick={this.toggleAddToGroupsModal}
                        margin="no small no no"
                      >
                        {`${translate('routes.groups.addToGroups')} (${size(selectedRouteIdsToDelete)})`}
                      </Button>
                    )}
                  </PageFooter>
                </PanelSection>
              )}
            {!size(snowOrSeeperRoutes) && <Message padding="sMedium">{translate('routes.noRoutes')}</Message>}
          </PanelSectionGroup>
        </Panel>
        {isSchedulingModalOpen && (
          <RouteRescheduleModal
            closeModal={this.toggleSchedulingModal}
            isSnowPlowRoute={isSnowPlowRoute}
            isStreetSweeperRoute={isStreetSweeperRoute}
            onSubmit={this.submitRescheduleModal}
            routes={selectedRoutesToReschedule}
          />
        )}
        {isAddToGroupsModalOpen && (
          <AddToGroupsModalResolver onAddToGroups={this.addRoutesToGroups} closeModal={this.toggleAddToGroupsModal} />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  hasRouteCopyPermission: hasPermissionSelector(state.account.permissions, ROUTES_TRACKER_COPY) || false,
  hasRouteDeletePermission: hasPermissionSelector(state.account.permissions, ROUTES_TRACKER_DELETE) || false,
  hasRouteSequencePermission: hasPermissionSelector(state.account.permissions, ROUTES_TRACKER_SEQUENCE_ROUTE) || false,
  isLoading:
    state.routes.routeTracker.isCloning ||
    state.routes.routeTracker.isDeleting ||
    state.routes.routeTracker.isExporting ||
    state.routes.routeTracker.isLoading ||
    state.routes.routeTracker.isRescheduling,
  filtersPreferences: state.common.filters.filters as unknown as FilterSetting[],
  loadsDumped: state.vendors.streetSweepingSettings.streetSweepingSettings?.loadsDumped,
  materialPickUpTicket: state.vendors.snowPlowSettings.snowPlowSettings?.materialPickUpTicket,
  routeSequencingEnabled: routeSequencingStatusSelector(state.vendors.features.features) || false,
  routesResourceAvailability: state.routes.routeTracker.routesResourceAvailability,
  routeStatuses: state.routes.routeSequence.routeStatuses,
  serviceZones: getAllWithOptionNone(state.routes.serviceZones.serviceZones || []),
  snowOrSeeperRoutes: state.routes.routeTracker.snowOrSweeperRoutes,
  snowOrSweeperRoutesHeader: state.routes.routeTracker.snowOrSweeperRoutesHeader,
  supervisors: getAllWithOptionNone(state.routes.supervisors.supervisors || []),
  total: state.routes.routeTracker.total,
  vehicleTypes: state.fleet.vehicleTypesForVendor.vehicleTypesForVendor,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  waterFills: state.vendors.streetSweepingSettings.streetSweepingSettings?.waterFillUps,
});

const mapDispatchToProps = {
  assignRoutesToGroups,
  bulkDeleteRouteTrackers,
  cloneRouteTracker,
  deleteRouteTracker,
  exportSnowOrSweeperRoutesTracker,
  loadNextRunNumber,
  loadRouteSequenceStatusByVendor,
  loadRoutesResourceAvailability,
  loadSnowOrSweeperRoutesHeader,
  loadSnowOrSweeperRoutesTracker,
  loadVehicleTypesForVendor,
  push,
  rescheduleRouteTrackers,
  reset,
  resetRoutesTracker,
  resetRouteTracker,
  resetVehicleTypesForVendor,
  updateRouteSequenceStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SnowOrSweeperTrackerPage));
