import { PureComponent } from 'react';

import { connect } from 'react-redux';
import { difference, filter, orderBy, size } from 'lodash-es';

import { AppState } from 'src/store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  Message,
  ModalSection,
  ModalFixedFooter,
} from '../../../core/components/styled';
import {
  clearVisionConfiguration,
  loadVisionConfigurationForVehicle,
  loadVisionConfigurationWithVehicleTypeId,
  saveVisionConfigurationForVehicle,
  saveVisionConfigurationWithVehicleTypeId,
  vehicleIdSelector,
  visionConfigurationSelector,
} from '../../ducks';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DuckFunction } from 'src/contracts/ducks';
import { loadVehicleTypesForVendor } from 'src/fleet/ducks';
import { Modal, Table } from '../../../core/components';
import { NUMERIC_TEXT_FIELD_ID, SLIDER_ID, TEXT_FIELD_ID } from 'src/fleet/constants';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { Vehicles } from '../../../vendors/interfaces/ZDeviceConfiguration';
import { VehicleTypeForm } from 'src/vendors/components/forms';
import {
  VisionConfiguration,
  VisionConfigurationItems,
  VisionConfigurationParams,
} from 'src/fleet/interfaces/VisionConfiguration';
import { VisionConfigurationModalTableRow } from '.';
import confirm from '../../../core/services/confirm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

type Props = {
  changeCustomVisionConfigurationDefaultValue?: (value: boolean) => void;
  clearVisionConfiguration: DuckFunction<typeof clearVisionConfiguration>;
  closeModal: (pristine: boolean) => void;
  hasCustomVisionConfigurationSettings?: boolean;
  hasVehicleTypeId?: boolean;
  id: number;
  isClearing: boolean;
  isLoading: boolean;
  isLoadingVehicles: boolean;
  isSaving: boolean;
  loadVehicleTypesForVendor?: DuckFunction<typeof loadVehicleTypesForVendor>;
  loadVisionConfigurationForVehicle?: DuckFunction<typeof loadVisionConfigurationForVehicle>;
  loadVisionConfigurationWithVehicleTypeId?: DuckFunction<typeof loadVisionConfigurationWithVehicleTypeId>;
  saveVisionConfigurationForVehicle: DuckFunction<typeof saveVisionConfigurationForVehicle>;
  saveVisionConfigurationWithVehicleTypeId: DuckFunction<typeof saveVisionConfigurationWithVehicleTypeId>;
  vehicleId?: number;
  vehicles?: Vehicles[];
  vehicleTypeId: number;
  vendorId: number;
  visionConfiguration: VisionConfiguration[];
};

type State = {
  fieldsWithError: number[];
  showVisionConfig: boolean;
  vehicleTypeIdSelected: number;
  visionConfiguration: VisionConfiguration[];
};

const modalId = 'vision-configuration-modal';

class VisionConfigurationEditorModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visionConfiguration: props.visionConfiguration,
      showVisionConfig: false,
      vehicleTypeIdSelected: 0,
      fieldsWithError: [],
    };
  }

  componentDidMount() {
    const {
      closeModal,
      hasCustomVisionConfigurationSettings,
      hasVehicleTypeId,
      loadVehicleTypesForVendor,
      loadVisionConfigurationForVehicle,
      vehicleId,
      vendorId,
    } = this.props;

    hasVehicleTypeId && loadVehicleTypesForVendor && loadVehicleTypesForVendor(vendorId);

    vehicleId &&
      loadVisionConfigurationForVehicle &&
      loadVisionConfigurationForVehicle({ vehicleId })
        .then(data => {
          this.setState({
            visionConfiguration: orderBy(data.sections, 'id'),
            showVisionConfig: hasCustomVisionConfigurationSettings || false,
          });
        })
        .catch(() => {
          createErrorNotification(translate('vendors.alertMessages.loadVisionConfigurationError'));
          closeModal(true);
        });
  }

  onChangeVehicleTypeId = (value: number) => {
    const { closeModal, loadVisionConfigurationWithVehicleTypeId, vendorId } = this.props;
    const loadVisionParams: VisionConfigurationParams = { vendorId, vehicleTypeId: value };

    this.setState({
      visionConfiguration: [],
    });

    loadVisionConfigurationWithVehicleTypeId &&
      loadVisionConfigurationWithVehicleTypeId(loadVisionParams)
        .then(data => {
          this.setState({
            visionConfiguration: orderBy(data.sections, 'id'),
            showVisionConfig: true,
            vehicleTypeIdSelected: value,
          });
        })
        .catch(() => {
          createErrorNotification(translate('vendors.alertMessages.loadVisionConfigurationError'));
          closeModal(true);
        });
  };

  saveVisionConfiguration = () => {
    const {
      changeCustomVisionConfigurationDefaultValue,
      closeModal,
      hasVehicleTypeId,
      id,
      saveVisionConfigurationForVehicle,
      saveVisionConfigurationWithVehicleTypeId,
      vehicleId,
      vendorId,
    } = this.props;
    const { visionConfiguration, vehicleTypeIdSelected } = this.state;

    let formHasError = false;
    let fieldsWithError: number[] = [];

    visionConfiguration.forEach(config => {
      config.items.forEach(item => {
        const { defaultId, fieldName, fieldTypeId, maximumValue, minimumValue, numericValue, textValue } = item;
        const configName = translate(createTranslationKey(fieldName, 'vendors.visionConfiguration'));

        const createError = (errorName: string) => {
          formHasError = true;
          createErrorNotification(`${configName} ${errorName}`);
          fieldsWithError.push(defaultId);
        };

        const createErrorRequired = () => {
          const errorName = translate('vendors.visionConfiguration.errorRequired');
          createError(errorName);
        };

        const createErrorLessOrGreater = (error: string, value?: string | number) => {
          const errorName = translate(`vendors.visionConfiguration.${error}`, {
            value,
          });
          createError(errorName);
        };

        if (fieldTypeId === TEXT_FIELD_ID) {
          (textValue == null || textValue === '') && createErrorRequired();
        }

        if (fieldTypeId === NUMERIC_TEXT_FIELD_ID || fieldTypeId === SLIDER_ID) {
          (numericValue == null || numericValue === '') && createErrorRequired();
        }

        if (fieldTypeId === SLIDER_ID) {
          maximumValue &&
            parseFloat(numericValue.toString()) > maximumValue &&
            createErrorLessOrGreater('errorGreaterThan', maximumValue);
          (minimumValue || minimumValue === 0) &&
            parseFloat(numericValue.toString()) < minimumValue &&
            createErrorLessOrGreater('errorLessThan', minimumValue);
        }
      });
    });

    if (formHasError) {
      this.setState({ fieldsWithError });
    } else {
      scrollToTopOfModal(modalId);

      const saveVisionConfiguration = hasVehicleTypeId
        ? saveVisionConfigurationWithVehicleTypeId
        : saveVisionConfigurationForVehicle;

      const saveVisionParams: VisionConfigurationParams & any = hasVehicleTypeId
        ? { sections: visionConfiguration, vendorId, vehicleTypeId: vehicleTypeIdSelected, id }
        : { sections: visionConfiguration, vehicleId, id };

      saveVisionConfiguration(saveVisionParams)
        .then(() => {
          createSuccessNotification(`${translate('vendors.alertMessages.saveVisionConfigurationSaved')}`);
          closeModal(true);
          changeCustomVisionConfigurationDefaultValue && changeCustomVisionConfigurationDefaultValue(true);
        })
        .catch(() => {
          createErrorNotification(translate('vendors.alertMessages.saveVisionConfigurationError'));
        });
    }
  };

  clearConfiguration = async () => {
    const { changeCustomVisionConfigurationDefaultValue, clearVisionConfiguration, closeModal, vehicleId } = this.props;
    const { visionConfiguration } = this.state;

    if (visionConfiguration && !(await confirm(translate('vehicles.alertMessages.clearVisionConfiguration')))) {
      return;
    }

    vehicleId &&
      clearVisionConfiguration(vehicleId).then(() => {
        this.setState({ visionConfiguration: [], showVisionConfig: false });
        closeModal(true);
        changeCustomVisionConfigurationDefaultValue && changeCustomVisionConfigurationDefaultValue(false);
      });
  };

  closeModal = () => {
    const { visionConfiguration, closeModal } = this.props;
    const { visionConfiguration: localVisionConfiguration } = this.state;
    closeModal(localVisionConfiguration ? !size(difference(visionConfiguration, localVisionConfiguration)) : true);
  };

  loadDefaultConfiguration = () => {
    const { visionConfiguration } = this.state;

    const newVisionConfiguration: VisionConfiguration[] = [];
    visionConfiguration.map(config => {
      const newConfig = config.items.map(item => ({
        ...item,
        booleanValue: item.defaultBooleanValue,
        numericValue: item.defaultNumericValue,
        textValue: item.defaultTextValue,
      }));

      newVisionConfiguration.push({ ...config, items: newConfig });

      return newConfig;
    });

    this.setState({
      showVisionConfig: true,
      visionConfiguration: newVisionConfiguration,
    });
  };

  setFieldValue = (defaultId: number, value: boolean | string | number, fieldName: string) => {
    const { visionConfiguration, fieldsWithError } = this.state;

    const newVisionConfiguration: VisionConfiguration[] = [];
    visionConfiguration.map(config => {
      const newConfig = config.items.map((item: VisionConfigurationItems) => {
        if (item.defaultId === defaultId) {
          const newItem = { ...item, [fieldName]: value };

          this.setState({
            fieldsWithError: filter(fieldsWithError, field => field !== item.defaultId),
          });

          return newItem;
        }

        return item;
      });

      newVisionConfiguration.push({ ...config, items: newConfig });
      return newConfig;
    });

    this.setState({
      visionConfiguration: newVisionConfiguration,
    });
  };

  render() {
    const { hasVehicleTypeId, isClearing, isLoading, isLoadingVehicles, isSaving, vehicles } = this.props;
    const { showVisionConfig, visionConfiguration, fieldsWithError } = this.state;

    const visionConfigurationCells = (sectionName?: string) => [
      {
        name: 'featureName',
        label: translate(createTranslationKey(sectionName, 'vendors.visionConfiguration')),
        width: hasVehicleTypeId ? '75%' : '50%',
      },
      {
        name: 'activeSettings',
        label: translate('vendors.visionConfiguration.activeSettings'),
        width: '25%',
        align: 'center',
      },
      {
        name: 'defaultSettings',
        label: translate('vendors.visionConfiguration.defaultSettings'),
        width: '25%',
        align: 'center',
      },
    ];
    const visionConfigurationTableCells = (sectionName: string) =>
      hasVehicleTypeId ? visionConfigurationCells(sectionName).slice(0, -1) : visionConfigurationCells(sectionName);

    return (
      <Modal
        title={translate('vendors.visionConfiguration.visionConfiguration')}
        onClose={this.closeModal}
        isLoading={isLoading || isSaving || isClearing}
        minHeight="450px"
        padding="medium no no"
        overflow={isLoading || isSaving || isClearing ? 'hidden' : 'auto'}
        id={modalId}
      >
        {hasVehicleTypeId ? (
          <VehicleTypeForm vehicles={vehicles} onChangeVehicleTypeId={this.onChangeVehicleTypeId} />
        ) : (
          <Grid centered>
            <GridColumn size="6/12">
              <ButtonSet margin="medium no">
                <Button color="primary" onClick={this.loadDefaultConfiguration} margin="no small no no">
                  {translate('vehicles.loadDefault')}
                </Button>
                <Button color="primary" onClick={this.clearConfiguration} disabled={!showVisionConfig}>
                  {translate('vehicles.clearConfig')}
                </Button>
              </ButtonSet>
            </GridColumn>
          </Grid>
        )}

        <ModalSection isLoading={isLoading} padding="small">
          {!!size(visionConfiguration) &&
            showVisionConfig &&
            visionConfiguration.map((config, index) => (
              <Table
                key={index}
                cells={visionConfigurationTableCells(config.name)}
                rows={config.items as unknown as VisionConfigurationItems[]}
                rowComponent={VisionConfigurationModalTableRow}
                rowProps={{
                  fieldsWithError,
                  hasVehicleTypeId,
                  setFieldValue: this.setFieldValue,
                }}
              />
            ))}
          {!size(vehicles) && !isLoadingVehicles && (
            <Message padding="sMedium">{translate('vendors.noConfigurations')}</Message>
          )}
        </ModalSection>

        <ModalFixedFooter isShadowed position={!showVisionConfig ? 'absolute' : undefined}>
          <Button type="button" color="secondary" margin="no xSmall no no" onClick={this.closeModal}>
            {translate('common.cancel')}
          </Button>
          <Button
            color="primary"
            margin="no no no xSmall"
            onClick={this.saveVisionConfiguration}
            disabled={!showVisionConfig}
          >
            {translate('common.save')}
          </Button>
        </ModalFixedFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  id: state.fleet.visionConfiguration?.vehicle?.id,
  isClearing: state.fleet.visionConfiguration.isClearing,
  isLoading: state.fleet.visionConfiguration.isLoading,
  isSaving: state.fleet.visionConfiguration.isSaving,
  vehicleId: vehicleIdSelector(state.fleet.vehicle.vehicle),
  vehicles: state.fleet.vehicleTypesForVendor.vehicleTypesForVendor || [],
  isLoadingVehicles: state.fleet.vehicleTypesForVendor.isLoading,
  vehicleTypeId: state.fleet.vehicle.vehicle?.vehicleTypeId || 0,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  visionConfiguration: visionConfigurationSelector(state) || [],
});

const mapDispatchToProps = {
  clearVisionConfiguration,
  loadVehicleTypesForVendor,
  loadVisionConfigurationForVehicle,
  loadVisionConfigurationWithVehicleTypeId,
  saveVisionConfigurationForVehicle,
  saveVisionConfigurationWithVehicleTypeId,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisionConfigurationEditorModal);
