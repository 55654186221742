import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { TechnicalTypeValue } from 'src/common/interfaces/TechnicalType';
import { VehicleTrackingSettings, VehicleTrackingSettingsState } from '../interfaces/VehicleTracking';
import {
  loadVehicleTrackingSettings as doLoadVehicleTrackingSettings,
  saveVehicleTrackingSettings as doSaveVehicleTrackingSettings,
  loadNavigationLineTypes as doLoadNavigationLineTypes,
} from '../services/vehicleTracking';

// Actions
const START_LOAD = 'vendors/vehicleTrackingSettings/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicleTrackingSettings/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicleTrackingSettings/FAIL_LOAD';
const START_LOAD_NAVIGATIOIN_LINE_TYPES = 'vendors/vehicleTrackingSettings/START_LOAD_NAVIGATIOIN_LINE_TYPES';
const COMPLETE_LOAD_NAVIGATIOIN_LINE_TYPES = 'vendors/vehicleTrackingSettings/COMPLETE_LOAD_NAVIGATIOIN_LINE_TYPES';
const FAIL_LOAD_NAVIGATIOIN_LINE_TYPES = 'vendors/vehicleTrackingSettings/FAIL_LOAD_NAVIGATIOIN_LINE_TYPES';
const START_SAVE = 'vendors/vehicleTrackingSettings/START_SAVE';
const COMPLETE_SAVE = 'vendors/vehicleTrackingSettings/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/vehicleTrackingSettings/FAIL_SAVE';

// Initial state
const initialState: VehicleTrackingSettingsState = {
  isLoading: false,
  isSaving: false,
  vehicleTrackingSettings: {} as VehicleTrackingSettings,
  navigationLineTypes: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          vehicleTrackingSettings: action.vehicleTrackingSettings,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_NAVIGATIOIN_LINE_TYPES:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_NAVIGATIOIN_LINE_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
          navigationLineTypes: action.navigationLineTypes,
        },
      });

    case FAIL_LOAD_NAVIGATIOIN_LINE_TYPES:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });
    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (vehicleTrackingSettings: VehicleTrackingSettings) => ({
  type: COMPLETE_LOAD,
  vehicleTrackingSettings,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadNavigationLineTypes = () => ({
  type: START_LOAD_NAVIGATIOIN_LINE_TYPES,
});

const completeLoadNavigationLineTypes = (navigationLineTypes: TechnicalTypeValue[]) => ({
  type: COMPLETE_LOAD_NAVIGATIOIN_LINE_TYPES,
  navigationLineTypes,
});

const failLoadNavigationLineTypes = () => ({
  type: FAIL_LOAD_NAVIGATIOIN_LINE_TYPES,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadVehicleTrackingSettings = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());

  const loadVehicleTrackingSettingsPromise = doLoadVehicleTrackingSettings(vendorId);

  loadVehicleTrackingSettingsPromise
    .then((vehicleTrackingSettings: VehicleTrackingSettings) => dispatch(completeLoad(vehicleTrackingSettings)))
    .catch(() => dispatch(failLoad()));

  return loadVehicleTrackingSettingsPromise;
};

export const saveVehicleTrackingSettings =
  (vendorId: number, vehicleTrackingSettings: VehicleTrackingSettings) => (dispatch: Dispatch) => {
    dispatch(startSave());

    const saveVehicleTrackingSettingsPromise = doSaveVehicleTrackingSettings(vendorId, vehicleTrackingSettings);
    saveVehicleTrackingSettingsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveVehicleTrackingSettingsPromise;
  };

export const loadNavigationLineTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoadNavigationLineTypes());

  const loadNavigationLineTypesPromise = doLoadNavigationLineTypes();

  loadNavigationLineTypesPromise
    .then((navigationLineTypes: TechnicalTypeValue[]) => dispatch(completeLoadNavigationLineTypes(navigationLineTypes)))
    .catch(() => dispatch(failLoadNavigationLineTypes()));

  return loadNavigationLineTypesPromise;
};
