import React, { PureComponent, MouseEvent } from 'react';

import { connect } from 'react-redux';
import { get, size } from 'lodash-es';

import { AppState } from '../../../store';
import { Button, ButtonSet, Message } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { currentVendorIdSelector } from '../../services/currentVendorSelector';
import { deleteFaultCodeEmail, resetFaultCodeEmails } from '../../ducks';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { FaultCode } from '../../interfaces/FaultCode';
import { FaultCodeEmailEditorModal, FaultCodeEmailsModalTableRow } from './';
import { Modal, Table } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';

interface Props extends ModalProps {
  deleteFaultCodeEmail: DuckFunction<typeof deleteFaultCodeEmail>;
  faultCodeEmails?: FaultCode[];
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  resetFaultCodeEmails: DuckAction<typeof resetFaultCodeEmails>;
  vendorId: number;
}

interface State {
  faultCodeEmail?: FaultCode;
  isFaultCodeEditorModalOpen: boolean;
}

class FaultCodeEmailsModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      faultCodeEmail: undefined,
      isFaultCodeEditorModalOpen: false,
    };
  }

  componentWillUnmount() {
    this.props.resetFaultCodeEmails();
  }

  openFaultCodeEditorModal = (event: MouseEvent, faultCodeEmail: FaultCode) => {
    event.stopPropagation();
    this.setState({ isFaultCodeEditorModalOpen: true, faultCodeEmail });
  };

  closeFaultCodeEditorModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.setState({ isFaultCodeEditorModalOpen: false, faultCodeEmail: undefined });
  };

  removeFaultCodeEmail = (faultCodeEmailId: number) => {
    const { vendorId, deleteFaultCodeEmail } = this.props;

    deleteFaultCodeEmail(vendorId, faultCodeEmailId)
      .then(() => createSuccessNotification(translate('vendors.alertMessages.faultCodeEmailDeleted')))
      .catch(() => createErrorNotification(translate('vendors.alertMessages.deleteFaultCodeEmailError')));
  };

  render() {
    const { faultCodeEmails, isLoading, isSaving, isDeleting, closeModal } = this.props;
    const { isFaultCodeEditorModalOpen, faultCodeEmail } = this.state;

    const faultCodeEmailsTableCells = [
      { name: 'email', label: translate('common.email'), width: '70%' },
      { name: 'isActive', label: translate('common.active'), width: '15%' },
      { name: 'options', label: translate('common.options'), width: '15%', align: 'right' },
    ];

    return (
      <Modal
        onClose={closeModal}
        title={translate('vendors.featureCodes.faultCodeEmail')}
        isLoading={isLoading || isSaving || isDeleting}
      >
        {!!size(faultCodeEmails) && (
          <Table
            cells={faultCodeEmailsTableCells}
            rows={faultCodeEmails}
            rowComponent={FaultCodeEmailsModalTableRow}
            rowProps={{
              openFaultCodeEditorModal: this.openFaultCodeEditorModal,
              removeFaultCodeEmail: this.removeFaultCodeEmail,
            }}
          />
        )}
        {!size(faultCodeEmails) && <Message padding="sMedium">{translate('vendors.noFaultCodeEmails')}</Message>}
        <ButtonSet margin="medium no medium no">
          <Button color="primary" onClick={this.openFaultCodeEditorModal}>
            {translate('vendors.addFaultCodeEmail')}
          </Button>
        </ButtonSet>

        {!!isFaultCodeEditorModalOpen && (
          <FaultCodeEmailEditorModal
            closeModal={this.closeFaultCodeEditorModal}
            faultCodeEmailId={get(faultCodeEmail, 'id')}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  faultCodeEmails: state.vendors.faultCodeEmails.faultCodeEmails,
  isDeleting: state.vendors.faultCodeEmails.isDeleting,
  isLoading: state.vendors.faultCodeEmails.isLoading,
  isSaving: state.vendors.faultCodeEmails.isSaving,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = { deleteFaultCodeEmail, resetFaultCodeEmails };

export default connect(mapStateToProps, mapDispatchToProps)(FaultCodeEmailsModal);
