import { mapKeys } from 'lodash-es';

import translate from '../../core/services/translate';

export const OPEN = 'OPEN';
export const PENDING = 'PENDING';
export const ACTION_REQUIRED = 'ACTION REQUIRED';
export const COMPLETED = 'COMPLETED';
export const DECLINED = 'DECLINED';
export const CONFIRMED_WITHOUT_UPLOAD = 'CONFIRMEDWITHOUTUPLOAD';
export const LIMIT_PER_PAGE = 10;

export const OPEN_DISPATCH_STATUS = mapKeys(
  [
    { technicalName: OPEN, name: translate('common.open') },
    { technicalName: PENDING, name: translate('common.pending') },
    { technicalName: COMPLETED, name: translate('common.serviceCompleted') },
    { technicalName: DECLINED, name: translate('common.declined') },
    { technicalName: ACTION_REQUIRED, name: translate('common.actionRequired') },
    { technicalName: CONFIRMED_WITHOUT_UPLOAD, name: translate('autoDispatch.confirmedWithoutUpload') },
  ],
  'technicalName',
);

export default OPEN_DISPATCH_STATUS;
