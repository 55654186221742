import React, { PureComponent } from 'react';

import { change, formValueSelector, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';

import { Address } from 'src/common/interfaces/Facility';
import { AppState } from '../../../store';
import { Button, Grid, GridColumn, PanelSection, Popover, Text } from '../../../core/components/styled';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DELIVERY_UTILITY_ID, ROLL_OFF_ID } from '../../../fleet/constants';
import { Dropdown, TypeAhead, TypedField } from '../../../core/components';
import { DuckFunction } from 'src/contracts/ducks';
import { isRequired } from 'src/utils/services/validator';
import { loadVendor } from '../../../vendors/ducks';
import { ON_HOLD } from '../../../common/constants/accountStatuses';
import {
  PICKUP_TYPE_EMPTY_AND_RETURN_ID,
  PICKUP_TYPE_PICKUP_ID,
  PICKUP_TYPE_SERVICE_ID,
} from '../../constants/routePickupTypes';
import { PickupTypeDropdown } from '..';
import { PopoverWrapper } from 'src/core/components';
import { renderCustomerLocationOptionLabel } from './utils/CustomerLocationUtils';
import { Route } from 'src/routes/interfaces/Route';
import { RouteLocation } from 'src/routes/interfaces/RouteLocation';
import { SCHEDULED } from 'src/routes/constants/routeStatuses';
import { TechnicalType } from '../../../common/interfaces/TechnicalType';
import { UNKNOWN_ID } from 'src/common/constants/wasteTypes';
import { WasteTypeDropdown } from 'src/common/components';
import CustomerLocationWithPin from '../CustomerLocationWithPin';
import JobPositionTypeDropdown from 'src/common/components/JobPositionTypeDropdown';
import loadPickupTypes from '../../services/loadPickupTypes';
import searchCustomerLocations from '../../services/searchCustomerLocations';
import translate from '../../../core/services/translate';
import PinOnMapModalResolver from '../modals/PinOnMapModalResolver';

interface ComponentProps {
  change: any;
  loadVendor: DuckFunction<typeof loadVendor>;
  newOptimizedRouteLocationsLength: number;
  pickupTypeId: number;
  reasonCodes: TechnicalType[];
  route?: Route;
  routeDate: Date | string;
  vehicleTypeId: number;
  vendorId: number;
  wasteMaterialTypeId: number;
}

interface FormData {
  pickupTypeId: number;
  routeLocation: RouteLocation;
  pinAddress: Address;
  reasonCodeTypeId: number;
}

type Props = ComponentProps & InjectedFormProps<FormData, ComponentProps>;

interface State {
  inputValue?: string;
  isPinOnMapModalOpen: boolean;
  isPinOnMapSelected: boolean;
  mapCenterByVendor?: {
    lat: string;
    lng: string;
  };
  pickupTypes: TechnicalType[];
}

class AddRouteLocationForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inputValue: undefined,
      isPinOnMapModalOpen: false,
      isPinOnMapSelected: false,
      mapCenterByVendor: undefined,
      pickupTypes: [],
    };
  }

  componentDidMount() {
    this.loadPickupTypes();
  }

  componentDidUpdate(prevProps: Props) {
    const { vehicleTypeId } = this.props;
    const { vehicleTypeId: prevVehicleTypeId } = prevProps;

    if (vehicleTypeId !== prevVehicleTypeId) {
      this.loadPickupTypes();
    }
  }

  onHandleClick = debounce(() => {
    const { change } = this.props;

    this.setState(
      {
        inputValue: undefined,
        isPinOnMapSelected: false,
      },
      () => {
        change('pinAddress', null);
      },
    );
  }, 400);

  setOpenSelectOnMap = () => {
    const { vendorId, loadVendor } = this.props;

    loadVendor(vendorId).then((response: any) => {
      const mapCenterByVendor = { lat: response.homeAddress.latitude, lng: response.homeAddress.longitude };

      this.setState({
        isPinOnMapModalOpen: true,
        mapCenterByVendor,
      });
    });
  };

  closePinOnMapModal = () => {
    this.setState({
      isPinOnMapModalOpen: false,
    });
  };

  handleAddressSelection = (address: any) => {
    const { change } = this.props;

    const newAddress = {
      ...address,
      formattedAddress: address.formattedAddress || address.line1,
    };

    change('pinAddress', newAddress);

    this.setState({
      isPinOnMapSelected: true,
    });

    this.closePinOnMapModal();
  };

  clearPinnedAddress = () => {
    const { change } = this.props;

    change('pinAddress', null);
    this.setState({ isPinOnMapSelected: false });
  };

  loadPickupTypes = async () => {
    if (this.props.vehicleTypeId) {
      const pickupTypesPromise = await loadPickupTypes(Number(this.props.vehicleTypeId));
      this.setState({
        pickupTypes: pickupTypesPromise[0].pickupTypes,
      });
    }
  };

  loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    const { vendorId, vehicleTypeId, route, routeDate } = this.props;
    const date = route ? route.routeDate : routeDate;

    searchCustomerLocations(vendorId, searchTerm, vehicleTypeId, undefined, date).then((customerGroups: any) => {
      const customerLocationsOptions = customerGroups.map((customerGroup: any) => ({
        ...customerGroup,
        options: customerGroup.options.map((customer: any) => ({
          ...customer,
          isOnHold: customer.value.service.serviceContractAccountStatusTypeIdAtDate === ON_HOLD,
        })),
      }));

      return onOptionsLoaded(customerLocationsOptions);
    });

    this.setState({ inputValue: undefined });
  }, 500);

  render() {
    const { handleSubmit, valid, route, vehicleTypeId, reasonCodes, newOptimizedRouteLocationsLength } = this.props;
    const { isPinOnMapModalOpen, isPinOnMapSelected, mapCenterByVendor, pickupTypes } = this.state;

    const isDeliveryUtility = vehicleTypeId === DELIVERY_UTILITY_ID;
    const isFormButtonDisabled = !valid;

    return (
      <>
        <form onSubmit={handleSubmit}>
          <PanelSection padding="medium xSmall" vertical withBorder>
            <Grid alignRight={isPinOnMapSelected} multiLine>
              <GridColumn size="12/12">
                <Text block weight="medium" size="large" margin="no no small">
                  {translate('routes.addStops')}
                </Text>
              </GridColumn>
            </Grid>

            <Grid position="relative" alignRight={isPinOnMapSelected} multiLine verticalAlign="center">
              {isDeliveryUtility ? (
                <CustomerLocationWithPin
                  colSize={5}
                  fieldName="routeLocation"
                  getOptions={this.loadCustomerLocations}
                  handleClearAddress={this.clearPinnedAddress}
                  handlePinClick={this.setOpenSelectOnMap}
                  isDeliveryUtility
                  isPinIconAbsolute
                  isPinOnMapSelected={!!isPinOnMapSelected}
                  margin="no"
                  renderCustomerLocationOptionLabel={renderCustomerLocationOptionLabel}
                />
              ) : (
                <GridColumn size="4/12">
                  <TypedField
                    name="routeLocation"
                    component={TypeAhead}
                    validate={[isRequired]}
                    props={
                      {
                        margin: 'no',
                        getOptions: this.loadCustomerLocations,
                        isClearable: true,
                        placeholder: `${translate('common.customer')} / ${translate('common.location')}`,
                        getOptionLabel: renderCustomerLocationOptionLabel,
                      } as any
                    }
                  />
                </GridColumn>
              )}
              {isPinOnMapSelected && (
                <GridColumn size="2/12">
                  <TypedField
                    name="wasteMaterialTypeId"
                    component={WasteTypeDropdown}
                    props={{
                      withPlaceholder: true,
                      dropdownProps: {
                        margin: 'no',
                        isClearable: true,
                        id: 'waste-material-type',
                      },
                    }}
                  />
                </GridColumn>
              )}

              <GridColumn size="2/12">
                <TypedField
                  name="pickupTypeId"
                  component={PickupTypeDropdown}
                  validate={[isRequired]}
                  props={{
                    pickupTypes,
                    withPlaceholder: true,
                    dropdownProps: {
                      margin: 'no',
                      isClearable: true,
                    },
                  }}
                />
              </GridColumn>

              <GridColumn size="2/12">
                <TypedField
                  name="reasonCodeTypeId"
                  component={Dropdown}
                  props={{
                    margin: 'no',
                    isClearable: true,
                    options: reasonCodes.map(code => ({
                      label: code.name,
                      value: code.id,
                    })),
                    placeholder: translate('common.reasonCode'),
                  }}
                />
              </GridColumn>

              {route && (
                <GridColumn size="2/12">
                  <TypedField
                    name="positionTypeId"
                    component={JobPositionTypeDropdown as any}
                    props={{
                      isOptimizedOptionHidden: vehicleTypeId === ROLL_OFF_ID || route.routeStatusTypeId !== SCHEDULED,
                      withLabel: true,
                      dropdownProps: {
                        margin: 'no no small',
                      },
                    }}
                  />
                </GridColumn>
              )}

              <GridColumn
                margin="no"
                size={isPinOnMapSelected ? (route ? '1/12' : '3/12') : route ? '2/12' : '4/12'}
                align="right"
              >
                <PopoverWrapper
                  margin="no"
                  triggerButton={
                    <Button
                      color="primary"
                      disabled={isFormButtonDisabled || newOptimizedRouteLocationsLength > 0}
                      id="route-add-stop-button"
                      onClick={this.onHandleClick}
                      size="xMedium"
                    >
                      {translate('routes.addStop')}
                    </Button>
                  }
                  popoverContent={
                    newOptimizedRouteLocationsLength ? (
                      <Popover>{translate('routes.alertMessages.addingJobDisabled')}</Popover>
                    ) : undefined
                  }
                  size="large"
                />
              </GridColumn>
            </Grid>
          </PanelSection>
        </form>

        {isPinOnMapModalOpen && (
          <PinOnMapModalResolver
            handleAddressSelection={this.handleAddressSelection}
            handleCloseModal={this.closePinOnMapModal}
            mapCenterByVendor={mapCenterByVendor}
            noLoad
          />
        )}
      </>
    );
  }
}

const selectInitialDropDownValue = (vehicleId: any) => {
  switch (vehicleId) {
    case ROLL_OFF_ID:
      return {
        pickupTypeId: PICKUP_TYPE_EMPTY_AND_RETURN_ID,
        wasteMaterialTypeId: UNKNOWN_ID,
      };
    case DELIVERY_UTILITY_ID:
      return {
        pickupTypeId: PICKUP_TYPE_SERVICE_ID,
        wasteMaterialTypeId: UNKNOWN_ID,
      };
    default:
      return {
        pickupTypeId: PICKUP_TYPE_PICKUP_ID,
        wasteMaterialTypeId: UNKNOWN_ID,
      };
  }
};

const mapStateToProps = (state: AppState) => {
  const { vehicleTypeId, wasteMaterialTypeId } = (getFormValues as any)('routeEditor')(state);
  const selector = formValueSelector('addRouteLocation');

  return {
    initialValues: selectInitialDropDownValue(vehicleTypeId),
    pickupTypeId: selector(state, 'pickupTypeId'),
    vehicleTypeId,
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    wasteMaterialTypeId,
    route: state.routes.route.route,
    reasonCodes: state.common.reasonCodeTypes.reasonCodeTypes,
  };
};

const mapDispatchToProps = {
  change,
  loadVendor,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<FormData, ComponentProps>({
    enableReinitialize: true,
    form: 'addRouteLocation',
  })(AddRouteLocationForm),
);
