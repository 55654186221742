import React, { PureComponent } from 'react';
import { Polyline } from '@react-google-maps/api';

import { POLYLINE_COLORS, GOOGLE as google } from '../constants';

const createPolylineCoordinates = (positions: any[]) =>
  positions.map(position => new google.maps.LatLng(position[1], position[0]));

interface Props {
  coordinates: any[];
  colorIndex: number;
}

class RoadConditionMarker extends PureComponent<Props> {
  polyline: any = null;
  
  setPolylineRef = (polyline: any) => {
    this.polyline = polyline;
  };

  render() {
    const { coordinates, colorIndex } = this.props;

    return (
      <Polyline
        path={createPolylineCoordinates(coordinates)}
        ref={this.setPolylineRef}
        options={{
          geodesic: true,
          clickable: true,
          strokeColor: POLYLINE_COLORS[colorIndex],
          strokeOpacity: 1,
          strokeWeight: 3,
          icons: [],
        }}
      />
    );
  }
}

export default RoadConditionMarker;
