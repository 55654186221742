import update from 'immutability-helper';
import { Dispatch, AnyAction } from 'redux';

import {
  FeatureNamespace,
  SelectedFeature,
  ServiceAreaOptions,
  ServiceAreas,
  ServiceAreasState,
  ZipCode,
} from '../interfaces/ServiceArea';
import {
  loadServiceAreas as doLoadServiceAreas,
  loadServiceAreaOptions as doLoadServiceAreaOptions,
  saveServiceArea as doSaveServiceArea,
  deleteServiceArea as doDeleteServiceArea,
} from '../services/serviceAreas';
import { loadZipCodeBoundaries } from '../ducks';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';

// Actions
const START_LOAD = 'haulerProfile/serviceAreas/START_LOAD';
const COMPLETE_LOAD = 'haulerProfile/serviceAreas/COMPLETE_LOAD';
const FAIL_LOAD = 'haulerProfile/serviceAreas/FAIL_LOAD';
const START_LOAD_OPTIONS = 'haulerProfile/serviceAreas/START_LOAD_OPTIONS';
const COMPLETE_LOAD_OPTIONS = 'haulerProfile/serviceAreas/COMPLETE_LOAD_OPTIONS';
const FAIL_LOAD_OPTIONS = 'haulerProfile/serviceAreas/FAIL_LOAD_OPTIONS';
const START_SAVE = 'haulerProfile/serviceAreas/START_SAVE';
const COMPLETE_SAVE = 'haulerProfile/serviceAreas/COMPLETE_SAVE';
const FAIL_SAVE = 'haulerProfile/serviceAreas/FAIL_SAVE';
const START_DELETE = 'haulerProfile/serviceAreas/START_DELETE';
const COMPLETE_DELETE = 'haulerProfile/serviceAreas/COMPLETE_DELETE';
const FAIL_DELETE = 'haulerProfile/serviceAreas/FAIL_DELETE';
const RESET = 'haulerProfile/serviceAreas/RESET';
const SET_SELECTED_FEATURE = 'haulerProfile/serviceAreas/SET_SELECTED_FEATURE';
const CLEAR_SELECTED_FEATURE = 'haulerProfile/serviceAreas/CLEAR_SELECTED_FEATURE';
const SET_VIEWPORT = 'haulerProfile/serviceAreas/SET_VIEWPORT';

// Initial state
const initialState: ServiceAreasState = {
  isDeleting: false,
  isLoading: false,
  isLoadingOptions: false,
  isSaving: false,
  serviceAreaOptions: undefined,
  serviceAreas: undefined,
  selectedFeature: undefined,
  viewport: {},
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          serviceAreas: action.serviceAreas,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_LOAD_OPTIONS:
      return update(state, {
        $merge: {
          isLoadingOptions: true,
        },
      });

    case COMPLETE_LOAD_OPTIONS:
      return update(state, {
        $merge: {
          isLoadingOptions: false,
          serviceAreaOptions: action.serviceAreaOptions,
        },
      });

    case FAIL_LOAD_OPTIONS:
      return update(state, {
        $merge: {
          isLoadingOptions: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    case SET_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: {
            namespace: action.namespace,
            id: action.id,
            ...action.additionalFields,
          },
        },
      });

    case CLEAR_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: undefined,
        },
      });

    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (serviceAreas: ServiceAreas) => ({
  type: COMPLETE_LOAD,
  serviceAreas,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadOptions = () => ({
  type: START_LOAD_OPTIONS,
});

const completeLoadOptions = (serviceAreaOptions: ServiceAreaOptions) => ({
  type: COMPLETE_LOAD_OPTIONS,
  serviceAreaOptions,
});

const failLoadOptions = () => ({
  type: FAIL_LOAD_OPTIONS,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadServiceAreas = (haulerId: string, divisionId?: string) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadServiceAreasPromise = doLoadServiceAreas(haulerId, divisionId).then(async serviceAreas => {
    const zipCodes = serviceAreas.zipCodes.map((zipCode: { zipCode: ZipCode }) => zipCode.zipCode.zipCode);
    await loadZipCodeBoundaries(zipCodes)(dispatch);
    return Promise.resolve(serviceAreas);
  });

  loadServiceAreasPromise.then(serviceAreas => dispatch(completeLoad(serviceAreas))).catch(() => dispatch(failLoad()));

  return loadServiceAreasPromise;
};

export const loadServiceAreaOptions = (haulerId: string, divisionId?: string) => (dispatch: Dispatch) => {
  dispatch(startLoadOptions());
  const loadServiceAreaOptionsPromise = doLoadServiceAreaOptions(haulerId, divisionId);
  loadServiceAreaOptionsPromise
    .then(serviceAreaOptions => dispatch(completeLoadOptions(serviceAreaOptions)))
    .catch(() => dispatch(failLoadOptions()));
  return loadServiceAreaOptionsPromise;
};

export const saveServiceArea = (haulerId: string, serviceArea: any) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveServiceAreaPromise = doSaveServiceArea(haulerId, serviceArea);
  saveServiceAreaPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));
  return saveServiceAreaPromise;
};

export const deleteServiceArea =
  (haulerId: string, equipmentId: number, divisionId?: string) => (dispatch: Dispatch) => {
    dispatch(startDelete());
    const deleteServiceAreaPromise = doDeleteServiceArea(haulerId, equipmentId, divisionId);
    deleteServiceAreaPromise.then(() => dispatch(completeDelete())).catch(() => dispatch(failDelete()));
    return deleteServiceAreaPromise;
  };

export const resetServiceAreas = () => ({
  type: RESET,
});

export const setServiceAreasSelectedFeature = (
  namespace: FeatureNamespace,
  id: number,
  additionalFields: Partial<Omit<SelectedFeature, 'namespace' | 'id'>> = {},
) => ({
  type: SET_SELECTED_FEATURE,
  namespace,
  id,
  additionalFields,
});

export const clearServiceAreasSelectedFeature = () => ({
  type: CLEAR_SELECTED_FEATURE,
});

export const setServiceAreasMapViewport = (viewport: MapGLViewport) => {
  return {
    type: SET_VIEWPORT,
    viewport,
  };
};
