import { Modal } from 'src/core/components';
import translate from 'src/core/services/translate';
import { ModalProps } from 'src/vendors/interfaces/ModalProps';
import DailyAndReoccurringFiltersSectionForm from '../../forms/routePlanner/DailyAndReoccurringFiltersSectionForm';

interface Props extends ModalProps {
  closeModal: () => void;
  initialValues: any;
  filtersOptions: any;
}

const DailyAndReoccurringFilterByModal: React.FC<Props> = ({ closeModal, initialValues, filtersOptions }) => {
  return (
    <Modal size="small" height="700px" onClose={closeModal} title={translate('common.filters')}>
      <DailyAndReoccurringFiltersSectionForm
        closeModal={closeModal}
        initialValues={initialValues}
        filtersOptions={filtersOptions}
      />
    </Modal>
  );
};
export default DailyAndReoccurringFilterByModal;
