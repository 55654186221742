import { ContainersRelocationForm } from '../forms';
import { Modal } from '../../../core/components';
import translate from '../../../core/services/translate';
import { useSelector } from 'src/core/hooks/useSelector';
import { useMemo } from 'react';
import { Text } from 'src/core/components/styled';

interface Props {
  closeModal: () => void;
  onSubmit: (values: any) => void;
  isDelete?: boolean;
  currentFacilityId?: number;
}

const ContainersRelocationModal = ({ closeModal, onSubmit, isDelete, currentFacilityId }: Props) => {
  const containers = useSelector(state => state.fleet.facilities.containersForReassignment);

  const initialValues = useMemo(
    () => ({
      relocatedContainers: containers.map(c => ({ containerId: c.id, containerNumber: c.boxNumber, locationId: null })),
    }),
    [containers],
  );

  const modalTitle = isDelete
    ? translate('facilities.alertMessages.confirmDeleteFacility')
    : translate('facilities.alertMessages.confirmDeactivateFacility');
  const warningMessage = isDelete
    ? translate('facilities.alertMessages.facilityHasContainersAtDelete')
    : translate('facilities.alertMessages.facilityHasContainersAtDeactivate');

  return (
    <Modal padding="medium" size="smallMedium" title={modalTitle} onClose={closeModal}>
      <Text color="alert" margin="medium small no" block>
        {translate('facilities.alertMessages.facilityHasContainers')}
      </Text>
      <Text color="alert" margin="no small medium" block>
        {warningMessage}
      </Text>
      <ContainersRelocationForm
        initialValues={initialValues}
        closeModal={closeModal}
        onSubmit={onSubmit}
        currentFacilityId={currentFacilityId}
      />
    </Modal>
  );
};

export default ContainersRelocationModal;
