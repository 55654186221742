import { camelCase } from 'lodash-es';
import { useState } from 'react';
import moment from 'moment';

import { ActionButtonTooltip, ThumbnailSet } from 'src/core/components';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { dateFormat, timeFormat } from 'src/utils/services/validator';
import { getMaterialTicketsTableCellWidths } from './MaterialTicketsSection';
import { MaterialTicket } from 'src/routes/interfaces/MaterialTickets';
import { MaterialTicketImagesModal } from '..';
import { TableActionButton, TableCell, TableRow, Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

type Props = {
  deleteMaterialTicket?: (materialTicketId: number) => void;
  editMaterialTicket?: (materialTicketId: number) => void;
  isViewOnly?: boolean;
  materialTicketsCellWidths?: string[];
};

export default function MaterialTicketsTableRow({
  deleteMaterialTicket,
  editMaterialTicket,
  id: materialTicketId,
  image,
  isViewOnly,
  materialAmount,
  materialPickUpLocationName,
  materialTicketsCellWidths,
  materialTypeTechnicalName,
  pickUpDateTimeLocal,
  ticketNumber,
  unitOfMeasureTypeTechnicalName,
}: Props & MaterialTicket) {
  const isSupportPermission = checkIfSupport();
  const isViewOnlyPermission = checkIfViewOnly();
  const { routeSummary } = useSelector(state => state.routes.routeSummary);

  const [isMaterialTicketImageModalOpen, setMaterialTicketImageModalOpen] = useState(false);

  if (!routeSummary && !isViewOnly) return null;

  const timestampMoment = moment(pickUpDateTimeLocal);

  const materialTicketsTableCellWidths =
    isViewOnly && materialTicketsCellWidths ? materialTicketsCellWidths : getMaterialTicketsTableCellWidths();

  return (
    <>
      <TableRow>
        <TableCell width={materialTicketsTableCellWidths[0]}>
          <Text>{ticketNumber}</Text>
        </TableCell>
        <TableCell width={materialTicketsTableCellWidths[1]}>
          <Text>{materialPickUpLocationName}</Text>
        </TableCell>
        <TableCell width={materialTicketsTableCellWidths[2]}>
          <Text>{translate(`common.materialTickets.materialTypeValues.${camelCase(materialTypeTechnicalName)}`)}</Text>
        </TableCell>
        <TableCell width={materialTicketsTableCellWidths[3]}>
          <Text>{materialAmount}</Text>
        </TableCell>
        <TableCell width={materialTicketsTableCellWidths[4]}>
          <Text>
            {translate(`common.materialTickets.unitOfMeasureValues.${camelCase(unitOfMeasureTypeTechnicalName)}`)}
          </Text>
        </TableCell>
        <TableCell width={materialTicketsTableCellWidths[5]}>
          <Text>
            <Text block size="medium">
              {timestampMoment.format(dateFormat)}
            </Text>
            <Text size="small">{timestampMoment.format(timeFormat)}</Text>
          </Text>
        </TableCell>
        <TableCell width={materialTicketsTableCellWidths[7]}>
          <Text>
            {image != null && image.url && (
              <ThumbnailSet images={[image.url]} onClick={() => setMaterialTicketImageModalOpen(true)} />
            )}
          </Text>
        </TableCell>

        {!isViewOnlyPermission && !isSupportPermission && !isViewOnly && (
          <>
            <TableCell width={materialTicketsTableCellWidths[8]} align="right">
              <TableActionButton
                onClick={() =>
                  editMaterialTicket && materialTicketId ? editMaterialTicket(materialTicketId) : undefined
                }
              >
                <ActionButtonTooltip icon="edit" tooltip="edit" />
              </TableActionButton>
              <TableActionButton
                onClick={() =>
                  deleteMaterialTicket && materialTicketId ? deleteMaterialTicket(materialTicketId) : undefined
                }
              >
                <ActionButtonTooltip icon="delete" tooltip="delete" />
              </TableActionButton>
            </TableCell>
          </>
        )}
      </TableRow>

      {isMaterialTicketImageModalOpen && image != null && image.url && (
        <MaterialTicketImagesModal
          materialTicket={{ url: image.url, ticketNumber, materialPickUpLocationName }}
          closeModal={() => setMaterialTicketImageModalOpen(false)}
        />
      )}
    </>
  );
}
