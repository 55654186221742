import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { FiltersPreferencesIds } from 'src/vendors/interfaces/Filters';
import { getAllFiltersPreferencesIds } from 'src/common/utils/filters';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadDailyDisposalSummary } from '../../ducks';
import { loadDisposalSiteLocations } from '../../../common/ducks';
import { loadFiltersSettings } from 'src/common/ducks/filters';
import { loadVehicleTypesForVendor } from '../../../fleet/ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import DisposalSummaryPage from './DisposalSummaryPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  filtersPreferencesIds: FiltersPreferencesIds;
  loadDailyDisposalSummary: DuckFunction<typeof loadDailyDisposalSummary>;
  loadDisposalSiteLocations: DuckFunction<typeof loadDisposalSiteLocations>;
  loadFiltersSettings: DuckFunction<typeof loadFiltersSettings>;
  loadVehicleTypesForVendor: DuckFunction<typeof loadVehicleTypesForVendor>;
  userId: string;
  vendorId: number;
}

class DisposalSummaryPageResolver extends PureComponent<Props> {
  loadDependencies = async () => {
    const {
      filtersPreferencesIds,
      loadDailyDisposalSummary,
      loadDisposalSiteLocations,
      loadFiltersSettings,
      loadVehicleTypesForVendor,
      location,
      userId,
      vendorId,
    } = this.props;

    const {
      startDate,
      endDate,
      vehicleTypeId,
      searchString,
      ticketNumber,
      disposalSiteLocationId,
      sortedBy,
      sortOrder,
    } = getQueryParams(location.search);

    await loadFiltersSettings(vendorId, userId);

    return Promise.all([
      loadDisposalSiteLocations(vendorId),
      loadVehicleTypesForVendor(vendorId),
      loadDailyDisposalSummary(
        vendorId,
        startDate,
        endDate,
        vehicleTypeId,
        searchString,
        ticketNumber,
        disposalSiteLocationId,
        sortedBy,
        sortOrder,
        filtersPreferencesIds,
      ),
    ]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('insights.dailyDisposalSummary')}</DocumentTitle>
        <Resolver
          successComponent={DisposalSummaryPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filtersPreferencesIds: getAllFiltersPreferencesIds(state.common.filters.filters),
  userId: currentUserIdSelector(state.account.login) as any,
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  loadDisposalSiteLocations,
  loadVehicleTypesForVendor,
  loadDailyDisposalSummary,
  loadFiltersSettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DisposalSummaryPageResolver));
