import React, { useCallback, useMemo } from 'react';
import collectionPointPin from '../../../../common/assets/img/common/collectionPointPin.png';
import { Source, Layer } from 'react-map-gl';
import { FeaturePoint } from 'src/common/components/map/FeaturePoint';
import { getFeatureCollection } from 'src/common/components/map/util';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import mapboxgl from 'mapbox-gl';
import { CollectionPoint } from 'src/routes/interfaces/CollectionPoint';
import { useDraggableMarkers } from 'src/common/components/map/hooks/useDraggableMarkers';
import { Coordinates } from 'src/common/components/map/Coordinates';
import confirm from 'src/core/services/confirm';
import translate from 'src/core/services/translate';
import { pickupLocationsLayerId } from './PickupLocationsMarkerPoints';
import { useMapDoubleClick } from 'src/common/components/map/hooks/useMapDoubleClick';
import { useClickableFeatures } from 'src/common/components/map/hooks/useClickableFeatures';
import { CollectionPointMarkerInfoWindow } from './CollectionPointMarkerInfoWindow';

const sourceId = 'collection-points';
const layerId = 'collection-points-layer';

const mapImages = [
  {
    id: layerId,
    url: collectionPointPin,
    sourceId,
  },
];

const getFeaturePoints = (collectionPoints: CollectionPoint[]) => {
  return collectionPoints.map(cp => {
    const { latitude, longitude, id } = cp;
    const featurePoint = new FeaturePoint(latitude, longitude, id as any);
    featurePoint.properties = {
      id: cp.id,
      latitude: latitude,
      longitude: longitude,
    };
    return featurePoint;
  });
};

interface RouteLocationsMarkerSourceProps {
  activeCollectionPoint?: CollectionPoint;
  addNewCollectionPoint: (lat: number, lng: number) => void;
  anyActiveLocations: boolean;
  assignToCollectionPoint: (collectionPoint: CollectionPoint) => void;
  collectionPoints: CollectionPoint[];
  handleRemoveCollectionPoints: (collectionPointId: number | string) => void;
  map: mapboxgl.Map;
  onMoveCollectionPoint: (collectionPoint: CollectionPoint, lat: number, long: number) => void;
  setActiveCollectionPoint: (activeCollectionPoint?: CollectionPoint) => void;
  setDragPan: (drag: boolean) => void;
}

export const PickupLocationsCollectionPointsMarkerSource: React.FC<RouteLocationsMarkerSourceProps> = ({
  activeCollectionPoint,
  addNewCollectionPoint,
  anyActiveLocations,
  assignToCollectionPoint,
  collectionPoints,
  handleRemoveCollectionPoints,
  map,
  onMoveCollectionPoint,
  setActiveCollectionPoint,
  setDragPan,
}) => {
  const geojson = useMemo(() => getFeatureCollection(getFeaturePoints(collectionPoints)), [collectionPoints]);

  useMapImages(mapImages, map, { addLayers: false });

  const onDragEnd = async (marker: GeoJSON.Feature<GeoJSON.Point, any>, updatedAddress: Coordinates) => {
    const collectionPoint = collectionPoints.find(cp => cp.id === marker.properties.id);
    if (collectionPoint) {
      if (typeof collectionPoint.id === 'number') {
        if (collectionPoint.totalNoOfServiceContracts > collectionPoint.serviceContractIds.length) {
          if (
            !(await confirm(
              translate('pickupLocationEditor.alerts.areYouSure'),
              translate('pickupLocationEditor.alerts.moveCollectionPointWarning'),
            ))
          ) {
            return false;
          }
        }
      }
      onMoveCollectionPoint(collectionPoint, updatedAddress.lat, updatedAddress.lng);
    }
    return true;
  };

  const handleDoubleClick = useCallback(
    (lng: number, lat: number, feature?: mapboxgl.MapboxGeoJSONFeature) => {
      if (!feature) {
        addNewCollectionPoint(lat, lng);
      }
    },
    [addNewCollectionPoint],
  );

  useMapDoubleClick([layerId, pickupLocationsLayerId], handleDoubleClick, map);

  const handleCollectionPointClick = useCallback(
    (marker: mapboxgl.MapboxGeoJSONFeature) => {
      setActiveCollectionPoint(collectionPoints.find(p => p.id === marker.properties?.id));
    },
    [collectionPoints, setActiveCollectionPoint],
  );

  useClickableFeatures([layerId], handleCollectionPointClick, map);

  useDraggableMarkers({ map, source: geojson, sourceId, layerId, setDragPan, updateAddress: onDragEnd });

  return (
    <>
      <Source type="geojson" id={sourceId} data={geojson}>
        <Layer
          id={layerId}
          type="symbol"
          source={sourceId}
          paint={{}}
          layout={{
            'icon-image': layerId,
            'icon-size': 0.7,
            'icon-allow-overlap': true,
          }}
        />
      </Source>
      {activeCollectionPoint && (
        <CollectionPointMarkerInfoWindow
          activeCollectionPoint={activeCollectionPoint}
          collectionPoint={activeCollectionPoint}
          anyActiveLocations={anyActiveLocations}
          assignToCollectionPoint={assignToCollectionPoint}
          handleRemoveCollectionPoints={handleRemoveCollectionPoints}
          onClose={() => setActiveCollectionPoint()}
        />
      )}
    </>
  );
};
