import React, { PureComponent } from 'react';

import { debounce } from 'lodash-es';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import { Dropdown, TypeAhead } from 'src/core/components';
import { Button, Grid, GridColumn, PanelSection } from 'src/core/components/styled';
import translate from 'src/core/services/translate';
import { CustomerLocation } from 'src/customers/interfaces/Customers';
import suspendSearchCustomerLocations from 'src/customers/services/suspendSearchCustomerLocations';
import { AppState } from 'src/store';
import { isRequired } from 'src/utils/services/validator';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';

interface ComponentProps {
  vendorId: number;
  locations: CustomerLocation[];
  serviceContracts: any[];
  onCustomerChange: () => void;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class AddSuspendedLocationForm extends PureComponent<Props> {
  static defaultProps = {
    locations: [],
    serviceContracts: [],
  };

  loadCustomerSuspendedLocations = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }
    const { vendorId } = this.props;
    suspendSearchCustomerLocations(vendorId, searchTerm).then(onOptionsLoaded);
  }, 500);

  render() {
    const { handleSubmit, locations, serviceContracts, onCustomerChange, valid } = this.props;

    const locationOptions = locations.map(location => ({
      label: location.name,
      value: location,
    }));

    const serviceContractsOptions = serviceContracts.map(service => ({
      label: service.name,
      value: service,
    }));

    return (
      <form onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall" withBorder>
          <Grid>
            <GridColumn size="4/12">
              <Field
                name="customer"
                component={TypeAhead}
                placeholder={`${translate('common.customer')} / ${translate('common.location')}`}
                getOptions={this.loadCustomerSuspendedLocations}
                isClearable
                validate={[isRequired]}
                onChange={onCustomerChange}
                id="customers-suspended-location-customer-field"
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="location"
                placeholder={translate('common.location')}
                isClearable
                component={Dropdown}
                options={locationOptions}
                id="customers-suspended-location-location-field"
              />
            </GridColumn>
            <GridColumn size="3/12">
              <Field
                name="serviceContract"
                placeholder={translate('common.service')}
                isClearable
                component={Dropdown}
                options={serviceContractsOptions}
                id="customers-suspended-location-service-contract-field"
              />
            </GridColumn>
            <GridColumn size="2/12" align="right">
              <Button id="customers-suspended-location-add-button" color="primary" disabled={!valid}>
                {translate('common.add')}
              </Button>
            </GridColumn>
          </Grid>
        </PanelSection>
      </form>
    );
  }
}

const formSelector = formValueSelector('addSuspendedLocation');

const mapStateToProps = (state: AppState) => {
  const { customer, location } = formSelector(state, 'customer', 'location');
  const locations = customer ? customer.locations : [];
  const serviceContracts = location ? location.serviceContracts : [];

  return {
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
    locations,
    serviceContracts,
  };
};

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'addSuspendedLocation',
    enableReinitialize: true,
  })(AddSuspendedLocationForm),
);
