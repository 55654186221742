import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  loadDefaultExceptionConfigurations as doLoadDefaultExceptionConfigurations,
  loadExceptionConfigurations as doLoadExceptionConfigurations,
  saveExceptionConfigurations as doSaveExceptionConfigurations,
} from '../services/exceptionConfigurations';

// Actions
const START_LOAD = 'vendors/exceptionConfigurations/START_LOAD';
const COMPLETE_LOAD = 'vendors/exceptionConfigurations/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/exceptionConfigurations/FAIL_LOAD';
const START_SAVE = 'vendors/exceptionConfigurations/START_SAVE';
const COMPLETE_SAVE = 'vendors/exceptionConfigurations/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/exceptionConfigurations/FAIL_SAVE';
const RESET = 'vendors/exceptionConfigurations/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  exceptionConfigurations: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          exceptionConfigurations: action.exceptionConfigurations,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (exceptionConfigurations: any) => ({
  type: COMPLETE_LOAD,
  exceptionConfigurations,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

export const loadExceptionConfigurations =
  (vendorId: number, vehicleTypeId: number, vehicleTypeName?: string, includeSubtypes?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadExceptionConfigurationsPromise = doLoadExceptionConfigurations(
      vendorId,
      vehicleTypeId,
      vehicleTypeName,
      includeSubtypes,
    );
    loadExceptionConfigurationsPromise
      .then(exceptionConfigurations => dispatch(completeLoad(exceptionConfigurations)))
      .catch(() => dispatch(failLoad()));

    return loadExceptionConfigurationsPromise;
  };

export const loadDefaultExceptionConfigurations =
  (vehicleTypeId: number, vehicleTypeName: string) => (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadDefaultExceptionConfigurationsPromise = doLoadDefaultExceptionConfigurations(
      vehicleTypeId,
      vehicleTypeName,
    );
    loadDefaultExceptionConfigurationsPromise
      .then(exceptionConfigurations => dispatch(completeLoad(exceptionConfigurations)))
      .catch(() => dispatch(failLoad()));

    return loadDefaultExceptionConfigurationsPromise;
  };

export const saveExceptionConfigurations =
  (vendorId: number, vehicleTypeId: number, vehicleTypeName: string, exceptionConfigurations: any) =>
  (dispatch: Dispatch) => {
    dispatch(startSave());
    const saveExceptionConfigurationsPromise = doSaveExceptionConfigurations(
      vendorId,
      vehicleTypeId,
      vehicleTypeName,
      exceptionConfigurations,
    );
    saveExceptionConfigurationsPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

    return saveExceptionConfigurationsPromise;
  };

export const resetExceptionConfigurations = () => ({
  type: RESET,
});

export const activeExceptionConfigurationsSelector = createSelector(
  (state: any) => state.vendors.exceptionConfigurations.exceptionConfigurations || [],
  (configs: any[]) => configs.filter(c => c.isEnabled),
);
