import { camelCase, size } from 'lodash-es';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import humps from 'humps';

import { ActionButtonTooltip } from 'src/core/components';
import { Box } from 'src/core/components/styled/Box';
import { clearDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import { CustomerProximitySearchModalResolver } from 'src/customers/components/modals';
import { dateAndTime } from 'src/utils/services/formatter';
import { DetailsListActionButton, DetailsListActionIcon, TableActionButton } from 'src/core/components/styled';
import { getRouteStopIconType } from './utils';
import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupTitle,
  MapGLPopupWrapper,
} from 'src/common/components/map/MapGLPopup';
import { PICKUP_STATUSES, ROUTE_PICKUP_TYPE_IDS, SCHEDULED } from 'src/common/constants';
import {
  RouteLocationImagesModalResolver,
  RouteLocationIssuesModalResolver,
  StrobeImagesModalResolver,
} from 'src/routes/components/modals';
import { RouteStopItem } from 'src/dashboard/interfaces/routesData';
import { useSelector } from 'src/core/hooks/useSelector';
import { WASTE_AUDIT_ID } from 'src/fleet/constants';
import JobPriorityTypeIndicator from 'src/routes/components/pages/common/JobPriorityTypeIndicator';
import LabeledDataView from 'src/core/components/LabeledDataView';
import StrobeImagesResolver from 'src/routes/components/pages/routes/routePageSections/routeMap/StrobeImagesResolver';
import translate from 'src/core/services/translate';

type Props = {
  routeStop: RouteStopItem;
  shouldLoadClosestStrobeImage: boolean;
};

const DashboardRouteStopGLPopup = ({ routeStop, shouldLoadClosestStrobeImage }: Props) => {
  const [isRouteLocationIssueModalOpen, setIsRouteLocationIssueModalOpen] = useState(false);
  const [isIssueImageModalOpen, setIssueImageModalOpen] = useState(false);
  const [isStrobeImageModalOpen, setIsStrobeImageModalOpen] = useState(false);
  const [isCustomerProximitySearchModalOpen, setCustomerProximitySearchModalOpen] = useState(false);

  const dispatch = useDispatch();

  const routeInformation = useSelector(state => state.dashboard.routesData.routeInformation);
  const routeVehicles = useSelector(state => state.dashboard.routesData.routeDriversVehicles);

  const images = useSelector(state => state.routes.routeLocationImages.routeLocationImages);
  const routeLocationIssues = useSelector(state => state.routes.routeLocationIssues.routeLocationIssues);

  const exceptionMessages = useMemo(() => {
    const messages: string[] = [];
    if (routeInformation?.vehicleType?.id === WASTE_AUDIT_ID) return messages.join(', ');

    if (routeStop.pickupExceptionType) {
      messages.push(translate(`routes.pickupIssueTypes.${camelCase(routeStop.pickupExceptionType.technicalName)}`));
    }

    return messages.join(', ');
  }, [routeInformation?.vehicleType?.id, routeStop.pickupExceptionType]);

  if (!routeInformation) return null;

  const pickupStatusName = PICKUP_STATUSES[routeStop.pickupStatusTypeId].technicalName;
  const insightType = humps.camelize(pickupStatusName);

  const iconType = getRouteStopIconType(routeStop);

  const vehicleName = routeVehicles.find(routeVehicle => routeVehicle.vehicleId === routeStop.vehicleId)?.vehicleName;

  return (
    <>
      <MapGLPopupWrapper
        closeOnClick={false}
        latitude={routeStop.displayLatitude}
        longitude={routeStop.displayLongitude}
        onClose={() => dispatch(clearDashboardSelectedFeature())}
      >
        <MapGLPopup>
          <MapGLPopupTitle
            beforeTitleContent={
              <Box mr={8} width={15} height={15}>
                <img src={iconType?.iconUrl} width="15" height="15" alt="" />
              </Box>
            }
            title={translate(`dashboard.${insightType}`)}
            afterTitleContent={
              <>
                {!!routeStop.hasIssueReported && (
                  <DetailsListActionButton
                    onClick={() => setIsRouteLocationIssueModalOpen(true)}
                    margin="no no no xSmall"
                  >
                    <DetailsListActionIcon icon="issue" />
                    {size(routeLocationIssues)}
                  </DetailsListActionButton>
                )}
                {!!size(images) && (
                  <DetailsListActionButton onClick={() => setIssueImageModalOpen(true)} margin="no xSmall">
                    <DetailsListActionIcon icon="image" /> {size(images)}
                  </DetailsListActionButton>
                )}

                {routeStop.jobPriorityTypeId && (
                  <Box ml={5}>
                    <JobPriorityTypeIndicator iconSize="mMedium" jobPriorityTypeId={routeStop.jobPriorityTypeId} />
                  </Box>
                )}
              </>
            }
          />
          {routeStop.pickupStatusTypeId !== SCHEDULED && (
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.truck')}
              value={routeStop.assistingVehicleRegplate || vehicleName}
              margin="xSmall no no no"
            />
          )}
          {routeStop.statusDate && (
            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.timestamp')}
              value={dateAndTime(routeStop.statusDate)}
            />
          )}
          {routeStop.customerName && (
            <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.customer')}>
              {routeStop.customerName}
              <TableActionButton
                margin="no no no xSmall"
                color="primary"
                onClick={() => setCustomerProximitySearchModalOpen(true)}
              >
                <ActionButtonTooltip
                  icon="searchVehicle"
                  size="sMedium"
                  sourceIsInfoWindow
                  tooltip="customerProximitySearch"
                />
              </TableActionButton>
            </LabeledDataView>
          )}

          <MapGLPopupContent>
            <LabeledDataView
              width="calc(100% - 10px)"
              size="small"
              label={translate('common.address')}
              value={routeStop.locationName}
            />

            {routeStop.pickupTypeId && (
              <LabeledDataView
                noWrap
                width="calc(100% - 10px)"
                size="small"
                label={translate('routes.pickupType')}
                value={ROUTE_PICKUP_TYPE_IDS[routeStop.pickupTypeId]?.name || ''}
              />
            )}

            <LabeledDataView
              noWrap
              width="calc(100% - 10px)"
              size="small"
              label={translate('dashboard.stopNumber')}
              value={routeStop.orderNo}
            />

            <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('routes.route')}>
              {routeInformation.id && (
                <Box textDecoration="underline">
                  <Link to={`/routes/route-tracker/${routeInformation.id}`}>{routeInformation.routeName}</Link>
                </Box>
              )}
            </LabeledDataView>
            {!!exceptionMessages.length && (
              <LabeledDataView
                width="calc(100% - 10px)"
                size="small"
                label={translate('dashboard.exceptionReasons')}
                value={exceptionMessages || ''}
              />
            )}

            {shouldLoadClosestStrobeImage && (
              <LabeledDataView noWrap width="calc(100% - 10px)" size="small" label={translate('common.images')}>
                <StrobeImagesResolver
                  vehicleId={routeStop.vehicleId}
                  timestamp={routeStop.statusDate}
                  setIsStrobeImageModalOpen={setIsStrobeImageModalOpen}
                />
              </LabeledDataView>
            )}
          </MapGLPopupContent>
        </MapGLPopup>
      </MapGLPopupWrapper>

      {isRouteLocationIssueModalOpen && (
        <RouteLocationIssuesModalResolver
          modalTitle={routeStop.customerName}
          modalSubTitle={routeStop.locationName}
          routeId={routeInformation.id}
          routeLocationId={routeStop.id}
          closeModal={() => setIsRouteLocationIssueModalOpen(false)}
          vehicleTypeId={routeInformation.vehicleType?.id}
        />
      )}

      {isIssueImageModalOpen && (
        <RouteLocationImagesModalResolver
          routeId={routeInformation.id}
          routeLocationId={routeStop.id}
          closeModal={() => setIssueImageModalOpen(false)}
        />
      )}

      {isStrobeImageModalOpen && (vehicleName || routeStop.assistingVehicleRegplate) && (
        <StrobeImagesModalResolver
          vehicleId={routeStop.vehicleId}
          vehicleName={routeStop.assistingVehicleRegplate || vehicleName || ''}
          timeStamp={routeStop.statusDate}
          closeModal={() => setIsStrobeImageModalOpen(false)}
        />
      )}

      {isCustomerProximitySearchModalOpen && (
        <CustomerProximitySearchModalResolver
          defaultDate={routeStop.statusDate}
          locationId={routeStop.id}
          onCancel={() => setCustomerProximitySearchModalOpen(false)}
        />
      )}
    </>
  );
};

export default DashboardRouteStopGLPopup;
