const cache: {
  [key: string]: any;
} = {};

const getValue = (key: string) => {
  if (cache[key]) {
    return cache[key];
  }
  cache[key] = localStorage.getItem(key);
  return cache[key];
};

const setValue = (key: string, value: any) => {
  localStorage.setItem(key, value);
  cache[key] = value;
};

const currentLanguageLocalStorageKey = 'languageId';
const initialLanguageLocalStorageKey = 'initalLocale';

export const getCurrentLanguageId = () => getValue(currentLanguageLocalStorageKey);

export const getInitialLocale = () => getValue(initialLanguageLocalStorageKey);

export const setCurrentLanguageId = (currentLanguageId: number | null) => setValue(currentLanguageLocalStorageKey, currentLanguageId);

export const setInitialLocale = (initialLanguageId: number) => setValue(initialLanguageLocalStorageKey, initialLanguageId);
