import React, { PureComponent } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { FeedbackFormWrapper, FeedbackSendButton, FeedbackClose, FeedbackCloseIcon } from './styled';
import translate from '../../core/services/translate';
import { Button } from '../../core/components/styled';
import { TextArea } from '../../core/components';
import { isRequired } from '../../utils/services/validator';
import focusFirstInvalidField from '../../utils/services/focusFirstInvalidField';
import { AppState } from '../../store';
import { Box } from 'src/core/components/styled/Box';

interface Props {
  isSendingFeedback: boolean;
  isFeedbackFormOpen: boolean;
  feedbackWidth: number;
  onCloseFeedback(): void;
}

class FeedbackForm extends PureComponent<Props & InjectedFormProps<{ feedbackText: string }, Props>> {
  render() {
    const { handleSubmit, isSendingFeedback, onCloseFeedback, isFeedbackFormOpen, feedbackWidth } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FeedbackFormWrapper
          isLoading={isSendingFeedback}
          isFeedbackFormOpen={isFeedbackFormOpen}
          xOffset={feedbackWidth}
        >
          <FeedbackClose onClick={onCloseFeedback}>
            <FeedbackCloseIcon />
          </FeedbackClose>

          <Box m={15} display="block">
            <Field
              name="feedbackText"
              component={TextArea}
              rows="5"
              validate={[isRequired]}
              label={translate('common.feedback')}
              noResize
            />
          </Box>

          <Box m={15} display="block">
            <FeedbackSendButton>
              <Button color="primary" margin="no" type="submit" id="send-feedback-button">
                {translate('common.send')}
              </Button>
            </FeedbackSendButton>
          </Box>
        </FeedbackFormWrapper>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isSendingFeedback: state.common.feedback.isSendingFeedback,
  feedbackWidth: state.dashboard.mapControls.feedback.width,
});

export default connect(mapStateToProps)(
  reduxForm<{ feedbackText: string }, Props>({
    form: 'feedbackForm',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(FeedbackForm),
);
