import { map } from 'lodash-es';
import translate from '../../core/services/translate';
import createTranslationKey from '../../utils/services/createTranslationKey';
import { TechnicalType } from '../interfaces/TechnicalType';

const transformServicesMonthlyFrequencyTypes = (pickupFrequencyOptions: TechnicalType[]) =>
  map(pickupFrequencyOptions, pickupFrequencyOption => ({
    ...pickupFrequencyOption,
    name: translate(createTranslationKey(pickupFrequencyOption.technicalName, 'customers.dayOfMonthList')),
  }));

export default transformServicesMonthlyFrequencyTypes;
