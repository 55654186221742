import React from 'react';

import { map } from 'lodash-es';

import { TypedField } from '../../core/components';
import CheckboxGroup, { CheckboxGroupProps } from '../../core/components/CheckboxGroup';
import { DEVICE_ROLE_TYPES_SIMPLIFIED } from '../constants';

type Props = Omit<
  CheckboxGroupProps,
  'items'
>;

const DeviceRoleTypeCheckboxes = (props: Props) => {
  const {
    input,
    input: { name, onChange },
    ...checkboxGroupProps
  } = props;

  const roleTypeOptions = map(DEVICE_ROLE_TYPES_SIMPLIFIED, deviceRoleType => ({
    label: deviceRoleType.name,
    value: deviceRoleType.id,
  }));

  return (
    <TypedField
      name={name}
      component={CheckboxGroup}
      onChange={onChange}
      props={{
        items: roleTypeOptions,
        ...checkboxGroupProps,
      }}
    />
  );
};

export default DeviceRoleTypeCheckboxes;
