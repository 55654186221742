import { filter, find, map } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import confirm from 'src/core/services/confirm';
import { createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import { DISPATCH_BOARD_SOURCE_FORM_NAME } from 'src/routes/components/forms/DispatchBoardSourceForm';
import { DISPATCH_BOARD_TARGET_FORM_NAME } from 'src/routes/components/forms/DispatchBoardTargetForm';
import DispatchBoardModals, {
  ModalTypes,
} from 'src/routes/components/pages/dispatchBoard/dispatchBoardPageSections/common/ModalContainer';
import RouteHeaderContainer from 'src/routes/components/pages/dispatchBoard/dispatchBoardPageSections/common/RouteHeaderContainer';
import useDispatchBoardFilters, { UnassignedJobsFilters } from 'src/routes/components/pages/dispatchBoard/dispatchBoardPageSections/common/useDispatchBoardFilters';
import { DispatchBoardSubPanel } from 'src/routes/components/styled';
import { JOB_PENDING_OPTIMIZATION_ID } from 'src/routes/constants';
import { JobTypes } from 'src/routes/constants/jobTypes';
import { moveDispatchBoardAllJobsToPosition } from 'src/routes/ducks/dispatchBoard';
import { DispatchBoardSimpleRoute } from 'src/routes/interfaces/DispatchBoardRoute';
import { DispatchBoardRouteJob, DispatchBoardRouteJobSimplified } from 'src/routes/interfaces/DispatchBoardRouteJob';
import routeJobCountFormatter from 'src/routes/services/routeJobCountFormatter';
import { RESPONSE_TYPES, displayMessageOnResponseError } from 'src/routes/utils/dispatchBoard';
import DispatchBoardJobs from './DispatchBoardJobs';
import { useSelector } from 'src/core/hooks/useSelector';

interface DispatchBoardRouteDetailsProps {
  deleteJob: (routeId: number, jobId: number) => Promise<void>;
  deleteRoute: (routeId: number) => Promise<void>;
  filteredJobsSelector: (
    routeJobs: DispatchBoardRouteJob[],
    dispatchBoardSearchFilters: UnassignedJobsFilters,
    allServiceZones: number,
    allSupervisors: number,
    allMaterialTypes: number,
  ) => DispatchBoardRouteJob[];
  isDeletingRoute: boolean;
  isLoadingRouteJobs: boolean;
  jobsTransferring: boolean;
  loadOppositeRouteJobs: (routeId: number, searchTerm?: string) => void;
  loadRouteJobs: (routeId: number, searchTerm?: string) => void;
  loadRoutesAndUnassignedJobs?: () => void;
  moveDispatchBoardAllJobsToPosition: (routeId: number, routeJobs: DispatchBoardRouteJobSimplified[]) => void;
  moveJobToPosition: (fromIndex: number, toIndex: number) => Promise<void>;
  onClose: () => void;
  onJobDrop: (
    sourceRouteId: number,
    targetRouteId: number,
    jobsSelected: DispatchBoardRouteJob[],
    position?: number,
    type?: string,
  ) => void;
  openMapWithRoute?: (route: DispatchBoardSimpleRoute) => void;
  refreshUnassignedJobs?: () => void;
  refreshOnHoldJobs?: () => void;
  resetRouteJobs: () => void;
  route: DispatchBoardSimpleRoute;
  routeDetails: any;
  routeJobs: DispatchBoardRouteJob[];
  routePriorityTypeId?: number;
  routeSearchTerm?: string;
  targetDate: Date | string;
  targetRouteId?: number;
  toggleJobTransferring: (value: boolean) => void;
  type: JobTypes;
  vehicleTypeId: number;
}

const DispatchBoardRouteDetails = (props: DispatchBoardRouteDetailsProps) => {
  const {
    deleteJob: deleteJobProp,
    deleteRoute: deleteRouteProp,
    filteredJobsSelector,
    isDeletingRoute,
    isLoadingRouteJobs,
    jobsTransferring,
    loadOppositeRouteJobs,
    loadRouteJobs,
    loadRoutesAndUnassignedJobs,
    moveJobToPosition,
    onClose,
    onJobDrop,
    openMapWithRoute: openMapWithRouteProp,
    refreshUnassignedJobs,
    refreshOnHoldJobs,
    resetRouteJobs,
    route,
    routeDetails,
    routeJobs,
    routeSearchTerm,
    targetDate,
    targetRouteId,
    toggleJobTransferring,
    type,
    vehicleTypeId,
  } = props;

  useEffect(() => {
    if (route && route.id) loadRouteJobs(route.id, routeSearchTerm);
    return () => resetRouteJobs();
  }, [loadRouteJobs, resetRouteJobs, route, routeSearchTerm]);

  const dispatch = useDispatch();

  const [modalsOpen, setModalsOpen] = useState<{
    jobEditor: boolean;
    routeEditor: boolean;
    routeLocationImages: boolean;
    routeLocationIssues: boolean;
    routeLocationNotes: boolean;
    weightTicket: boolean;
  }>({
    jobEditor: false,
    routeEditor: false,
    routeLocationImages: false,
    routeLocationIssues: false,
    routeLocationNotes: false,
    weightTicket: false,
  });

  const { dispatchBoardSearchFilters, filterFunctions } = useDispatchBoardFilters();
  const [openedJobId, setOpenedJobId] = useState<number | undefined>(undefined);
  const allMaterialTypes = useSelector(s => s.vendors.materialTypes.materialTypes || []);
  const allServiceZones = useSelector(s => s.routes.serviceZones.serviceZones || []);
  const allSupervisors = useSelector(s => s.routes.supervisors.supervisors || []);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const filteredJobs = filteredJobsSelector(
      routeJobs,
      dispatchBoardSearchFilters,
      allServiceZones.length,
      allSupervisors.length,
      allMaterialTypes.length,
    );
    const fromIndex = routeJobs.indexOf(filteredJobs[oldIndex]);
    const toIndex = routeJobs.indexOf(filteredJobs[newIndex]);

    moveJobToPosition(fromIndex, toIndex).then(() => {
      loadRouteJobs(route.id, routeSearchTerm);
      if (targetRouteId === route.id) {
        loadOppositeRouteJobs(route.id, routeSearchTerm);
      }
    });
  };

  const toggleJob = (e: MouseEvent, jobId: number) => {
    e.stopPropagation();
    setOpenedJobId(openedJobId !== jobId ? jobId : undefined);
  };

  const toggleModal = (modal: ModalTypes, open: boolean = true, isHeader: boolean = false) => {
    setOpenedJobId(isHeader ? undefined : openedJobId);
    setModalsOpen({
      ...modalsOpen,
      [modal]: open,
    });

    if (!open) {
      loadRouteJobs(route.id, routeSearchTerm);
      if (targetRouteId === route.id) {
        loadOppositeRouteJobs(route.id, routeSearchTerm);
      }
    }
  };

  const deleteRoute = async () => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteRoute')))) {
      return;
    }

    onClose();
    await deleteRouteProp(route.id);
    dispatch(submit(DISPATCH_BOARD_SOURCE_FORM_NAME));
    dispatch(submit(DISPATCH_BOARD_TARGET_FORM_NAME));
    createSuccessNotification(translate('routes.alertMessages.routeDeleted'));
  };

  const deleteJob = async (jobId: number) => {
    if (!(await confirm(translate('routes.alertMessages.confirmDeleteJob')))) {
      return;
    }

    await deleteJobProp(route.id, jobId)
      .then(() => {
        dispatch(submit(DISPATCH_BOARD_SOURCE_FORM_NAME));
        dispatch(submit(DISPATCH_BOARD_TARGET_FORM_NAME));
        createSuccessNotification(`${translate('routes.alertMessages.routeJobDeleted')}`);
      })
      .catch(error => {
        displayMessageOnResponseError(error, RESPONSE_TYPES.delete);
      });
  };

  const openMapWithRoute = () => {
    if (openMapWithRouteProp) {
      openMapWithRouteProp(route);
    }
  };

  const filteredJobs = useMemo(() => {
    return filter(
      filteredJobsSelector(
        routeJobs,
        dispatchBoardSearchFilters,
        allServiceZones.length,
        allSupervisors.length,
        allMaterialTypes.length,
      ),
      (job: DispatchBoardRouteJob) => {
        if (dispatchBoardSearchFilters.jobPriorityTypeId) {
          return job.jobPriorityTypeId === dispatchBoardSearchFilters.jobPriorityTypeId;
        }
        return true;
      },
    );
  }, [
    filteredJobsSelector,
    routeJobs,
    dispatchBoardSearchFilters,
    allServiceZones.length,
    allSupervisors.length,
    allMaterialTypes.length,
  ]);

  const openedJob = find(filteredJobs, { id: openedJobId });

  const allJobsExceptOptimized = routeJobs.filter(routeJob => routeJob.orderNo !== JOB_PENDING_OPTIMIZATION_ID);
  const allJobsExceptOptimizedLength = allJobsExceptOptimized.length;
  const optimizedJobs = routeJobs.filter(routeJob => routeJob.orderNo === JOB_PENDING_OPTIMIZATION_ID);
  const optimizedJobsLength = optimizedJobs.length;

  const cancelOptimization = async () => {
    if (!(await confirm(translate('routes.alertMessages.confirmCancelOptimization')))) {
      return;
    }

    let optimizedCanceledJobs: DispatchBoardRouteJob[] = [];
    optimizedJobs.forEach((job: DispatchBoardRouteJob, index: number) => {
      optimizedCanceledJobs.push({
        ...job,
        orderNo: allJobsExceptOptimizedLength + index + 1,
      });
    });
    const allJobs = [...allJobsExceptOptimized, ...optimizedCanceledJobs];

    const filteredJobsSimplified = map(allJobs, ({ id, orderNo }) => {
      return { jobId: id, orderNo };
    });

    moveDispatchBoardAllJobsToPosition(route.id, filteredJobsSimplified).then(() => {
      loadRouteJobs(route.id, routeSearchTerm);
      if (targetRouteId === route.id) {
        loadOppositeRouteJobs(route.id, routeSearchTerm);
      }
    });
  };

  return (
    <DispatchBoardSubPanel isLoading={isLoadingRouteJobs || isDeletingRoute} isFilterFocused>
      <RouteHeaderContainer
        date={route ? route.date : undefined}
        driverName={route ? route.driverName : undefined}
        filterFunctions={filterFunctions}
        jobsCountText={route ? routeJobCountFormatter(route) : ''}
        name={route ? route.name : undefined}
        onCancelOptimization={cancelOptimization}
        onClose={onClose}
        onDelete={deleteRoute}
        onMapClick={openMapWithRoute}
        optimizedJobsLength={optimizedJobsLength}
        routeDetails={routeDetails}
        routeJobs={routeJobs}
        statusId={route ? route.statusId : undefined}
        toggleModal={toggleModal}
        type={type}
        vehicleTypeId={vehicleTypeId}
      />
      <DispatchBoardJobs
        deleteJob={deleteJob}
        jobs={filteredJobs}
        jobsTransferring={jobsTransferring}
        loadOppositeRouteJobs={loadOppositeRouteJobs}
        loadRouteJobs={loadRouteJobs}
        onJobDrop={onJobDrop}
        onSortEnd={onSortEnd}
        openedJobId={openedJobId}
        optimizedJobsLength={optimizedJobsLength}
        route={route}
        routeIsFiltered={routeDetails.routeStopsCount > routeJobs.length}
        searchTerm={dispatchBoardSearchFilters.searchTerm}
        sortChanged={onSortEnd}
        targetRouteId={targetRouteId}
        toggleJob={toggleJob}
        toggleJobTransferring={toggleJobTransferring}
        toggleModal={toggleModal}
        type={type}
        vehicleTypeId={vehicleTypeId}
      />
      <DispatchBoardModals
        date={targetDate}
        modalsOpen={modalsOpen}
        refreshUnassignedJobs={refreshUnassignedJobs}
        refreshOnHoldJobs={refreshOnHoldJobs}
        refreshRoutes={loadRoutesAndUnassignedJobs}
        routeId={route ? route.id : undefined}
        routeName={route ? route.name : undefined}
        selectedJob={openedJob}
        toggleModal={toggleModal}
        vehicleTypeId={vehicleTypeId}
      />
    </DispatchBoardSubPanel>
  );
};

const mapDispatchToProps = {
  moveDispatchBoardAllJobsToPosition,
};

export default connect(undefined, mapDispatchToProps)(DispatchBoardRouteDetails);
