import { http } from '../../core/services/http';
import { VisionConfigurationParams, VisionConfiguration } from '../interfaces/VisionConfiguration';

export const loadVisionConfigurationForVehicle = (loadVisionParams: VisionConfigurationParams) =>
  http.get(`vehicles/${loadVisionParams.vehicleId}/vision-configuration`).then(response => response.data);

export const loadVisionConfigurationWithVehicleTypeId = (loadVisionParams: VisionConfigurationParams) =>
  http
    .get(`vendors/${loadVisionParams.vendorId}/vision-configuration`, {
      params: { vehicleTypeId: loadVisionParams.vehicleTypeId },
    })
    .then(response => response.data);

export const saveVisionConfigurationForVehicle = (
  visionConfiguration: VisionConfiguration,
  vehicleId?: number,
  id?: number,
) =>
  http
    .put(`vehicles/${vehicleId}/vision-configuration`, {
      sections: visionConfiguration,
      vehicleId,
      id,
    })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const saveVisionConfigurationWithVehicleTypeId = (
  visionConfiguration: VisionConfiguration,
  vendorId: number,
  vehicleTypeId: number,
  id: number,
) =>
  http
    .put(`vendors/${vendorId}/vision-configuration/${id}`, {
      sections: visionConfiguration,
      id,
      vehicleTypeId,
      vendorId,
    })
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const clearVisionConfiguration = (vehicleId: number) =>
  http.delete(`vehicles/${vehicleId}/vision-configuration`).then(response => response.data);
