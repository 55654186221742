import { filter, map } from 'lodash-es';
import {
  EVERY_OTHER_WEEK,
  EVERY_WEEK,
  EVERY_X_DAYS,
  EVERY_X_WEEKS,
  MONTHLY,
  X_TIMES_PER_WEEK,
} from 'src/common/constants';
import { DayOfServices } from '../interfaces/Customers';

export const formatDaysOfServiceByFrequencyType = (
  dayOfServices: DayOfServices[],
  dayOfService: string,
  pickupFrequencyTypeName: string,
  positionTypeId: any[],
  isMonthlyDayOfMonth?: boolean,
) => {
  const getRouteTemplate = (serviceDay: DayOfServices) => {
    return serviceDay.routeTemplateIds?.length
      ? map(serviceDay.routeTemplateIds, routeTemplateId => ({
          routeTemplateId: Number(routeTemplateId),
          positionTypeId: positionTypeId && positionTypeId.length ? positionTypeId[Number(routeTemplateId)] : undefined,
        }))
      : [];
  };

  switch (pickupFrequencyTypeName) {
    case EVERY_X_WEEKS:
      return map(filter(dayOfServices, { checked: true }), serviceDay => ({
        schedulerId: serviceDay.schedulerId,
        day: Number(dayOfService),
        routeTemplates: getRouteTemplate(serviceDay),
      }));

    case EVERY_OTHER_WEEK:
    case EVERY_WEEK:
    case MONTHLY: {
      if (!isMonthlyDayOfMonth) {
        return map(filter(dayOfServices, { checked: true }), serviceDay => ({
          schedulerId: serviceDay.schedulerId,
          routeTemplates: getRouteTemplate(serviceDay),
          day: serviceDay.shortCode,
        }));
      } else {
        return [{ schedulerId: 0, day: dayOfService }];
      }
    }

    case X_TIMES_PER_WEEK:
    case EVERY_X_DAYS:
      return [{ schedulerId: 0, day: dayOfService }];

    default:
      return undefined;
  }
};
