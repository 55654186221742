import update from 'immutability-helper';
import { AnyAction } from 'redux';

import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';

const SET_VIEWPORT = 'communityinsights/mapControls/SET_VIEWPORT';
const SET_SELECTED_FEATURE = 'communityinsights/mapControls/SET_SELECTED_FEATURE';
const CLEAR_SELECTED_FEATURE = 'communityinsights/mapControls/CLEAR_SELECTED_FEATURE';
const RESET = 'communityinsights/mapControls/RESET';

type SelectedFeature = {
  id: number;
};

interface State {
  selectedFeature?: SelectedFeature;
  viewport: MapGLViewport;
}

const initialState: State = {
  viewport: {},
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: {
            id: action.id,
          },
        },
      });

    case CLEAR_SELECTED_FEATURE:
      return update(state, {
        $merge: {
          selectedFeature: undefined,
        },
      });

    case SET_VIEWPORT:
      return update(state, {
        $merge: {
          viewport: action.viewport,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export const setCommunityinsightsMapViewport = (viewport: MapGLViewport) => {
  return {
    type: SET_VIEWPORT,
    viewport,
  };
};

export const setCommunityinsightsMapSelectedFeature = (id: number) => ({
  type: SET_SELECTED_FEATURE,
  id,
});

export const clearCommunityinsightsMapSelectedFeature = () => ({
  type: CLEAR_SELECTED_FEATURE,
});

export const resetCommunityinsightsMapControls = () => ({
  type: RESET,
});
