import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/core/hooks/useSelector';
import { setDashboardSelectedFeature } from 'src/dashboard/ducks/mapControls';
import DashboardGeoFenceIncidentsGLLayers from './DashboardGeoFenceIncidentsGLLayers';
import { VEHICLE_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import DashboardGeoFenceIncidentsGLPopup from './DashboardGeoFenceIncidentsGLPopup';
import {
  DASHBOARD_CLUSTERS_SOURCE,
  DASHBOARD_GEO_FENCE_INCIDENTS_LAYER,
  DASHBOARD_VEHICLE_INSIGHTS_LAYER,
} from 'src/dashboard/constants/dashboardMapGL';
import { GeoFenceIncidentsItem } from 'src/dashboard/interfaces/routesData';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { VehicleInsightDetails } from 'src/dashboard/interfaces/vehiclePositions';

const mapImages = VEHICLE_INSIGHT_ICON_TYPES.map(iconType => ({
  id: iconType.id,
  url: iconType.iconUrl,
  sourceId: DASHBOARD_CLUSTERS_SOURCE,
}));

const mapImagesOptions = { addLayers: false };

interface Props {
  map: mapboxgl.Map;
}

const DashboardGeoFenceIncidentsGL: React.FC<Props> = ({ map }) => {
  useMapImages(mapImages, map, mapImagesOptions);

  const dispatch = useDispatch();
  const geoFenceIncidents = useSelector(
    state => state.dashboard.routesData.geoFenceIncidents || [],
  ) as GeoFenceIncidentsItem[];

  const vehicleInsights = useSelector(
    state => (state.dashboard.vehiclesData.vehicleInsightDetails || []) as VehicleInsightDetails[],
  );

  useEffect(() => {
    map.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(
            feature =>
              (feature.source === DASHBOARD_CLUSTERS_SOURCE &&
                feature.properties?.layer === DASHBOARD_GEO_FENCE_INCIDENTS_LAYER) ||
              feature.properties?.layer === DASHBOARD_VEHICLE_INSIGHTS_LAYER,
          );
        if (!!feature) {
          const vehicleInsightsSource =
            feature.properties?.layer === DASHBOARD_GEO_FENCE_INCIDENTS_LAYER ? 'geoFenceIncidents' : 'safetyIncidents';

          dispatch(setDashboardSelectedFeature('vehicleInsights', feature.id as number, { vehicleInsightsSource }));
        }
      });
    });
  }, [map, dispatch]);

  if (!geoFenceIncidents.length && !vehicleInsights.length) {
    return null;
  }

  return (
    <>
      <DashboardGeoFenceIncidentsGLLayers />
      <DashboardGeoFenceIncidentsGLPopup />
    </>
  );
};

export default DashboardGeoFenceIncidentsGL;
