import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import { StringOrDate } from 'src/common/interfaces/StringOrDate';
import { UnbilledCharge } from 'src/finance/interfaces/UnbilledCharges';
import * as Services from 'src/finance/services/unbilledCharges';
import { CalendarUnbilledCharge } from 'src/finance/interfaces/Billing';

// Actions
const START_LOAD_CHARGES = 'finance/billing/unbilledCharges/START_LOAD_CHARGES';
const COMPLETE_LOAD_CHARGES = 'finance/billing/unbilledCharges/COMPLETE_LOAD_CHARGES';
const FAIL_LOAD_CHARGES = 'finance/billing/unbilledCharges/FAIL_LOAD_CHARGES';
const START_ASSIGN_TO_BILL = 'finance/billing/unbilledCharges/START_ASSIGN_TO_BILL';
const COMPLETE_ASSIGN_TO_BILL = 'finance/billing/unbilledCharges/COMPLETE_ASSIGN_TO_BILL';
const FAIL_ASSIGN_TO_BILL = 'finance/billing/unbilledCharges/FAIL_ASSIGN_TO_BILL';
const START_EXPORT = 'finance/billing/unbilledCharges/START_EXPORT';
const COMPLETE_EXPORT = 'finance/billing/unbilledCharges/COMPLETE_EXPORT';
const FAIL_EXPORT = 'finance/billing/unbilledCharges/FAIL_EXPORT';
const START_LOAD_CALENDAR_CHARGES = 'finance/billing/unbilledCharges/START_LOAD_CALENDAR_CHARGES';
const COMPLETE_LOAD_CALENDAR_CHARGES = 'finance/billing/unbilledCharges/COMPLETE_LOAD_CALENDAR_CHARGES';
const FAIL_LOAD_CALENDAR_CHARGES = 'finance/billing/unbilledCharges/FAIL_LOAD_CALENDAR_CHARGES';
const RESET = 'finance/billing/unbilledCharges/RESET';

// Initial state
const initialState: {
  unbilledCharges: UnbilledCharge[];
  unbilledChargesLoading: boolean;
  isAssigningToBill: boolean;
  isExportingUnbilledCharges: boolean;
  calendarUnbilledCharges: CalendarUnbilledCharge[];
} = {
  unbilledCharges: [],
  unbilledChargesLoading: false,
  isAssigningToBill: false,
  isExportingUnbilledCharges: false,
  calendarUnbilledCharges: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_CHARGES:
      return {
        ...state,
        unbilledChargesLoading: true,
      };

    case COMPLETE_LOAD_CHARGES:
      return {
        ...state,
        unbilledChargesLoading: false,
        unbilledCharges: action.unbilledCharges,
      };

    case FAIL_LOAD_CHARGES:
      return {
        ...state,
        unbilledChargesLoading: false,
        unbilledCharges: [],
      };

    case START_ASSIGN_TO_BILL:
      return {
        ...state,
        isAssigningToBill: true,
      };

    case COMPLETE_ASSIGN_TO_BILL:
      return {
        ...state,
        isAssigningToBill: false,
      };

    case FAIL_ASSIGN_TO_BILL:
      return {
        ...state,
        isAssigningToBill: false,
      };

    case START_EXPORT:
      return {
        ...state,
        isExportingUnbilledCharges: true,
      };

    case COMPLETE_EXPORT:
      return {
        ...state,
        isExportingUnbilledCharges: false,
      };

    case FAIL_EXPORT:
      return {
        ...state,
        isExportingUnbilledCharges: false,
      };

    case START_LOAD_CALENDAR_CHARGES:
      return update(state, {
        $merge: {
          unbilledChargesLoading: true,
        },
      });

    case COMPLETE_LOAD_CALENDAR_CHARGES:
      return update(state, {
        $merge: {
          unbilledChargesLoading: false,
          calendarUnbilledCharges: action.calendarUnbilledCharges,
        },
      });

    case FAIL_LOAD_CALENDAR_CHARGES:
      return update(state, {
        $merge: {
          unbilledChargesLoading: false,
          calendarUnbilledCharges: [],
        },
      });

    case RESET:
      return initialState;

    default:
      return { ...state };
  }
};

// Action creators
const startLoadCharges = () => ({
  type: START_LOAD_CHARGES,
});

const completeLoadCharges = (unbilledCharges: UnbilledCharge) => ({
  type: COMPLETE_LOAD_CHARGES,
  unbilledCharges,
});

const failLoadCharges = () => ({
  type: FAIL_LOAD_CHARGES,
});

const startAssignToBill = () => ({
  type: START_ASSIGN_TO_BILL,
});

const completeAssignToBill = () => ({
  type: COMPLETE_ASSIGN_TO_BILL,
});

const failAssignToBill = () => ({
  type: FAIL_ASSIGN_TO_BILL,
});

const startExportUnbilledCharges = () => ({
  type: START_EXPORT,
});

const completeExportUnbilledCharges = () => ({
  type: COMPLETE_EXPORT,
});

const failExportUnbilledCharges = () => ({
  type: FAIL_EXPORT,
});

const startLoadingCalendarUnbilledCharges = () => ({
  type: START_LOAD_CALENDAR_CHARGES,
});

const completeLoadingCalendarUnbilledCharges = (calendarUnbilledCharges: CalendarUnbilledCharge[]) => ({
  type: COMPLETE_LOAD_CALENDAR_CHARGES,
  calendarUnbilledCharges,
});

const failLoadingCalendarUnbilledCharges = () => ({
  type: FAIL_LOAD_CALENDAR_CHARGES,
});

export const loadUnbilledChargesList =
  (vendorId: number, invoiceId: number, includeRates: boolean) => (dispatch: Dispatch) => {
    dispatch(startLoadCharges());
    const loadUnbilledChargesListPromise = Services.loadUnbilledChargesList(vendorId, invoiceId, includeRates);
    loadUnbilledChargesListPromise
      .then(unbilledCharges => dispatch(completeLoadCharges(unbilledCharges)))
      .catch(() => dispatch(failLoadCharges()));
    return loadUnbilledChargesListPromise;
  };

export const assignToBill =
  (workOrderId: number, billDate: StringOrDate, vendorLocationId: number, billId?: number, headerId?: number) => (dispatch: Dispatch) => {
    dispatch(startAssignToBill());
    const assignToBillPromise = Services.assignToBill(workOrderId, billDate, vendorLocationId, billId, headerId);
    assignToBillPromise.then(() => dispatch(completeAssignToBill())).catch(() => dispatch(failAssignToBill()));
    return assignToBillPromise;
  };

export const exportUnbilledCharges = (searchTerm?: string, workOrderTypeId?: number, containerTypeId?: number, startDate?: StringOrDate,
  endDate?: StringOrDate) => (dispatch: Dispatch) => {
    dispatch(startExportUnbilledCharges());
    const exportUnbilledChargesPromise = Services.exportUnbilledCharges(searchTerm, workOrderTypeId, containerTypeId, startDate, endDate);
    exportUnbilledChargesPromise
      .then(() => dispatch(completeExportUnbilledCharges()))
      .catch(() => dispatch(failExportUnbilledCharges()));
    return exportUnbilledChargesPromise;
  };

export const loadCalendarUnbilledCharges = (startDate: StringOrDate, endDate: StringOrDate) => (dispatch: Dispatch) => {
  dispatch(startLoadingCalendarUnbilledCharges());
  const loadCalendarUnbilledChargesPromise = Services.loadCalendarUnbilledCharges(startDate, endDate);
  loadCalendarUnbilledChargesPromise
    .then((calendarUnbilledCharges: CalendarUnbilledCharge[]) =>
      dispatch(completeLoadingCalendarUnbilledCharges(calendarUnbilledCharges)),
    )
    .catch(() => dispatch(failLoadingCalendarUnbilledCharges()));
  return loadCalendarUnbilledChargesPromise;
};

export const resetUnbilledCharges = () => ({
  type: RESET,
});
