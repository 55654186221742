import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { createRouteNote, updateRouteNote, deleteRouteNote as doDeleteRouteNote } from '../services/routeNote';
import { RouteNotes } from '../interfaces/Route';
import doLoadRouteNotes from '../services/loadRouteNotes';

// Actions
const START_LOAD = 'routes/routeLocationNotes/START_LOAD';
export const COMPLETE_LOAD = 'routes/routeLocationNotes/COMPLETE_LOAD';
const FAIL_LOAD = 'routes/routeLocationNotes/FAIL_LOAD';
const START_SAVE_ROUTE_NOTE = 'routes/routeLocationNotes/START_SAVE_ROUTE_NOTE';
export const COMPLETE_SAVE_ROUTE_NOTE = 'routes/routeLocationNotes/COMPLETE_SAVE_ROUTE_NOTE';
const FAIL_SAVE_ROUTE_NOTE = 'routes/routeLocationNotes/FAIL_SAVE_ROUTE_NOTE';
const START_DELETE_ROUTE_NOTE = 'routes/routeLocationNotes/START_DELETE_ROUTE_NOTE';
const COMPLETE_DELETE_ROUTE_NOTE = 'routes/routeLocationNotes/COMPLETE_DELETE_ROUTE_NOTE';
const FAIL_DELETE_ROUTE_NOTE = 'routes/routeLocationNotes/FAIL_DELETE_ROUTE_NOTE';
const RESET = 'routes/routeLocationNotes/RESET';

interface State {
  isLoading: boolean;
  isSavingRouteNote: boolean;
  isDeletingRouteNote: boolean;
  routeLocationNotes?: RouteNotes[];
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState = {
  isLoading: false,
  isSavingRouteNote: false,
  isDeletingRouteNote: false,
  routeLocationNotes: undefined,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeLocationNotes: action.routeLocationNotes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_SAVE_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isSavingRouteNote: true,
        },
      });

    case COMPLETE_SAVE_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isSavingRouteNote: false,
        },
      });

    case FAIL_SAVE_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isSavingRouteNote: false,
        },
      });

    case START_DELETE_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isDeletingRouteNote: true,
        },
      });

    case COMPLETE_DELETE_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isDeletingRouteNote: false,
        },
      });

    case FAIL_DELETE_ROUTE_NOTE:
      return update(state, {
        $merge: {
          isDeletingRouteNote: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (routeLocationNotes: RouteNotes[]) => ({
  type: COMPLETE_LOAD,
  routeLocationNotes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSaveRouteNote = () => ({
  type: START_SAVE_ROUTE_NOTE,
});

const completeSaveRouteNote = (routeNote: RouteNotes) => ({
  type: COMPLETE_SAVE_ROUTE_NOTE,
  routeNote,
});

const failSaveRouteNote = () => ({
  type: FAIL_SAVE_ROUTE_NOTE,
});

const startDeleteRouteNote = () => ({
  type: START_DELETE_ROUTE_NOTE,
});

const completeDeleteRouteNote = () => ({
  type: COMPLETE_DELETE_ROUTE_NOTE,
});

const failDeleteRouteNote = () => ({
  type: FAIL_DELETE_ROUTE_NOTE,
});

export const loadRouteLocationNotes = (routeId?: number, routeLocationId?: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRouteNotesPromise = doLoadRouteNotes(routeId, routeLocationId);
  loadRouteNotesPromise.then(routeNotes => dispatch(completeLoad(routeNotes))).catch(() => dispatch(failLoad()));
  return loadRouteNotesPromise;
};

export const saveRouteNote = (routeId: number, routeNote: any) => (dispatch: Dispatch) => {
  dispatch(startSaveRouteNote());
  const saveRouteNotePromise = routeNote.id
    ? updateRouteNote(routeId, routeNote.id, routeNote)
    : createRouteNote(routeId, routeNote);

  saveRouteNotePromise
    .then(routeNote => dispatch(completeSaveRouteNote(routeNote)))
    .catch(() => dispatch(failSaveRouteNote()));
  return saveRouteNotePromise;
};

export const deleteRouteNote = (routeNoteId: number) => (dispatch: Dispatch) => {
  dispatch(startDeleteRouteNote());
  const deleteRouteNotePromise = doDeleteRouteNote(routeNoteId);
  deleteRouteNotePromise.then(() => dispatch(completeDeleteRouteNote())).catch(() => dispatch(failDeleteRouteNote()));
  return deleteRouteNotePromise;
};

export const resetRouteLocationNotes = () => ({
  type: RESET,
});
