import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ContainerLocationForMap } from 'src/fleet/interfaces/containers';
import { orderBy } from 'lodash-es';

import { ChangeServiceRates, RubiconServicesHistory } from '../interfaces/RubiconServices';

import {
  exportRubiconServices as doExportRubiconServices,
  loadRubiconServices as doLoadRubiconServices,
  loadRubiconServicesSubmittedTickets as doLoadRubiconServiceSubmitedTickets,
  loadEndDestinations as doLoadEndDestinations,
} from '../services/rubiconServices';

// Actions
const START_LOAD = 'customers/rubiconServices/START_LOAD';
const COMPLETE_LOAD = 'customers/rubiconServices/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/rubiconServices/FAIL_LOAD';
const RESET = 'customers/rubiconServices/RESET';
const START_EXPORT = 'customers/rubiconServices/START_EXPORT';
const COMPLETE_EXPORT = 'customers/rubiconServices/COMPLETE_EXPORT';
const FAIL_EXPORT = 'customers/rubiconServices/FAIL_EXPORT';
const START_LOAD_TICKETS = 'customers/rubiconServices/START_LOAD_TICKETS';
const COMPLETE_LOAD_TICKETS = 'customers/rubiconServices/COMPLETE_LOAD_TICKETS';
const FAIL_LOAD_TICKETS = 'customers/rubiconServices/FAIL_LOAD_TICKETS';
const START_LOAD_END_DESTINATIONS = 'customers/rubiconServices/START_LOAD_END_DESTINATIONS';
const COMPLETE_LOAD_END_DESTINATIONS = 'customers/rubiconServices/COMPLETE_LOAD_END_DESTINATIONS';
const FAIL_LOAD_END_DESTINATIONS = 'customers/rubiconServices/FAIL_LOAD_END_DESTINATIONS';
interface State {
  changeServiceRates: ChangeServiceRates[];
  coordinates: ContainerLocationForMap[];
  endDestinations: any[];
  rubiconServices: RubiconServicesHistory[];
  rubiconServicesSubmittedTickets: any[];
  isLoading: boolean;
  isLoadingDestinations: boolean;
  isLoadingTickets: boolean;
  isExporting: boolean;
  total?: number | undefined;
}

type Dispatch = ThunkDispatch<State, any, AnyAction>;

// Initial state
const initialState: State = {
  changeServiceRates: [],
  coordinates: [],
  endDestinations: [],
  rubiconServices: [],
  rubiconServicesSubmittedTickets: [],
  isLoading: false,
  isLoadingDestinations: false,
  isLoadingTickets: false,
  isExporting: false,
  total: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          coordinates: action.coordinates,
          isLoading: false,
          rubiconServices: action.rubiconServices,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          coordinates: [],
          rubiconServices: [],
          isLoading: false,
          total: undefined,
        },
      });
    case START_LOAD_TICKETS:
      return update(state, {
        $merge: {
          isLoadingTickets: true,
        },
      });

    case COMPLETE_LOAD_TICKETS:
      return update(state, {
        $merge: {
          isLoadingTickets: false,
          rubiconServicesSubmittedTickets: orderBy(action.submittedTickets, 'dateSubmitted', 'desc'),
        },
      });

    case FAIL_LOAD_TICKETS:
      return update(state, {
        $merge: {
          rubiconServicesSubmittedTickets: [],
          isLoadingTickets: false,
        },
      });

    case START_LOAD_END_DESTINATIONS:
      return update(state, {
        $merge: {
          isLoadingDestinations: true,
        },
      });

    case COMPLETE_LOAD_END_DESTINATIONS:
      return update(state, {
        $merge: {
          isLoadingDestinations: false,
          endDestinations: action.endDestinations,
        },
      });

    case FAIL_LOAD_END_DESTINATIONS:
      return update(state, {
        $merge: {
          endDestinations: [],
          isLoadingDestinations: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (rubiconServices: RubiconServicesHistory[], total: number, coordinates: any) => ({
  type: COMPLETE_LOAD,
  rubiconServices,
  total,
  coordinates,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadTickets = () => ({
  type: START_LOAD_TICKETS,
});

const completeLoadSubmittedTickets = (submittedTickets: any[]) => ({
  type: COMPLETE_LOAD_TICKETS,
  submittedTickets,
});

const failLoadTickets = () => ({
  type: FAIL_LOAD_TICKETS,
});

const startLoadEndDestinations = () => ({
  type: START_LOAD_END_DESTINATIONS,
});

const completeLoadEndDestinations = (endDestinations: any[]) => ({
  type: COMPLETE_LOAD_END_DESTINATIONS,
  endDestinations,
});

const failLoadEndDestinations = () => ({
  type: FAIL_LOAD_END_DESTINATIONS,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});
const failExport = () => ({
  type: FAIL_EXPORT,
});

export const loadRubiconServices =
  (
    vendorId: number,
    userId: string,
    customerName?: string,
    divisionId?: string,
    serviceId?: string,
    equipmentType?: string,
    materialType?: string,
    servciceStatusIds?: string,
    destinationId?: number,
    isTemp?: string,
    startDate?: string,
    endDate?: string,
    page?: number,
    limit?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoad());
    const loadRubiconServicesPromise = doLoadRubiconServices(
      vendorId,
      userId,
      customerName,
      divisionId,
      serviceId,
      equipmentType,
      materialType,
      servciceStatusIds,
      destinationId,
      isTemp,
      startDate,
      endDate,
      page,
      limit,
    );

    loadRubiconServicesPromise
      .then(({ services, total, coordinates }) => {
        dispatch(completeLoad(services, total, coordinates));
      })
      .catch(() => dispatch(failLoad()));
    return loadRubiconServicesPromise;
  };

export const exportRubiconServices =
  (
    vendorId: number,
    userId: string,
    customerName: string,
    divisionId: string,
    serviceId: string,
    equipmentType: string,
    materialType: string,
    servciceStatusIds: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());

    const exportRubiconServicesPromise = doExportRubiconServices(
      vendorId,
      userId,
      customerName,
      divisionId,
      serviceId,
      equipmentType,
      materialType,
      servciceStatusIds,
    );

    exportRubiconServicesPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportRubiconServicesPromise;
  };

export const loadRubiconServiceSubmitedTickets =
  (serviceId: number, vendorId: string, userId: string) => (dispatch: Dispatch) => {
    dispatch(startLoadTickets());
    const loadRubiconServiceSubmitedTicketsPromise = doLoadRubiconServiceSubmitedTickets(serviceId, vendorId, userId);

    loadRubiconServiceSubmitedTicketsPromise
      .then(submittedTickets => dispatch(completeLoadSubmittedTickets(submittedTickets)))
      .catch(() => dispatch(failLoadTickets()));

    return loadRubiconServiceSubmitedTicketsPromise;
  };

export const loadEndDestinations = (vendorId: number, userId: string) => (dispatch: Dispatch) => {
  dispatch(startLoadEndDestinations());
  const loadEndDestinationsPromise = doLoadEndDestinations(vendorId, userId);
  loadEndDestinationsPromise
    .then(endDestinations => dispatch(completeLoadEndDestinations(endDestinations)))
    .catch(() => dispatch(failLoadEndDestinations()));
  return loadEndDestinationsPromise;
};

export const resetRubiconServices = () => ({
  type: RESET,
});
