import { http } from '../../core/services/http';
import transformLoadProximitySettings from './transformLoadProximitySettings';

export const loadProximitySettings = (vendorId: number) =>
  http
    .get('settings/proximitysettingvendor', { params: { vendorId } })
    .then(response => transformLoadProximitySettings(response.data));

export const saveProximitySettings = (vendorId: number, proximitySettings: any) =>
  http.post('settings/proximitysettingvendor', proximitySettings, { params: { vendorId } });
