import { createSelector } from 'reselect';
import { identity } from 'lodash-es';

import { VehicleNotes } from '../interfaces/VehicleNotes';

const getVehicleNotesFormInitialValues = (vehicleNotesState: any) => ({
  vehicleNotes: vehicleNotesState?.vehicleNotes?.filter((vehicleNote: VehicleNotes) => vehicleNote.note) || [],
});

const vehicleNotesFormInitialValuesSelector = createSelector(getVehicleNotesFormInitialValues, identity);

export default vehicleNotesFormInitialValuesSelector;
