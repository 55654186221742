import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const MILES = 'MILES';
export const YARDS = 'YARDS';
export const DEGREES = 'DEGREES';

export const DISTANCE_MEASUREMENT_UNITS = mapKeys(
  [
    { id: MILES, name: translate('common.distanceMeasurementUnits.miles') },
    { id: YARDS, name: translate('common.distanceMeasurementUnits.yards') },
    { id: DEGREES, name: translate('common.distanceMeasurementUnits.degrees') },
  ],
  'id',
);

export default DISTANCE_MEASUREMENT_UNITS;
