import { uniqBy } from 'lodash-es';
import humps from 'humps';

import { CommunityInsightsIcon, MapLegend } from '../../styled';
import { IconButtonIcon, MapInfoWindowTitleContainer, Text } from 'src/core/components/styled';
import { INSIGHT_ICONS } from 'src/common/constants';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import translate from 'src/core/services/translate';

interface Props {
  toggleHeatMap: () => void;
  isHeatmapVisible: boolean;
  insights: any[];
}

export default function CommunityInsightsMapLegend({ isHeatmapVisible, insights, toggleHeatMap }: Props) {
  return (
    <MapLegend>
      {uniqBy(insights, 'locationFlaggingType.technicalName').map((insight, index) => (
        <MapInfoWindowTitleContainer key={index}>
          <CommunityInsightsIcon
            small
            src={INSIGHT_ICONS[humps.camelize(insight.locationFlaggingType.technicalName)]}
          />
          <Text size="small" margin="no no small no">
            {translate(createTranslationKey(insight.locationFlaggingType.technicalName, 'vendors.locationFlags'))}
          </Text>
        </MapInfoWindowTitleContainer>
      ))}
      <hr />
      <Text cursor="pointer" size="small" color="primary" onClick={toggleHeatMap}>
        <IconButtonIcon icon="map" margin="no" />{' '}
        {isHeatmapVisible ? translate('insights.streetMap') : translate('insights.heatMap')}
      </Text>
    </MapLegend>
  );
}
