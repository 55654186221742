import React, { PureComponent } from 'react';
import { size } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { Table } from '../../../core/components';
import { hasPermissionSelector } from '../../../account/ducks';
import { OPPORTUNITIES } from '../../../account/constants';
import { Form, PanelSection } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import opportunityFormInitialValueSelector from '../../services/opportunityFormInitialValueSelector';
import { OpportunityFormTableRow } from '.';
import { AppState } from '../../../store';

interface PropsWithoutReduxForm extends RouteComponentProps {
  initialValues: any;
  paymentInfo: any;
  hasViewOnlyPermission: boolean;
  opportunities?: any[];
  deliveries?: any[];
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class OpportunityForm extends PureComponent<Props> {
  handleSubmit = (event: any) => {
    event.preventDefault();
  };

  render() {
    const { opportunities, paymentInfo, hasViewOnlyPermission } = this.props;
    const opportunityTableCells = [
      { name: 'Customer', label: translate('common.customer'), width: '19%', sortable: false },
      { name: 'Service Level', label: translate('common.serviceLevel'), width: '15%', sortable: false },
      { name: 'Service Detail', label: translate('common.serviceDetail'), width: '16%', sortable: false },
      { name: 'Price', label: translate('opportunity.price'), width: '15%', sortable: false },
      { name: 'Status', label: translate('common.status'), width: '18%', sortable: false },
      { name: 'Option', label: hasViewOnlyPermission && translate('common.options'), width: '17%', sortable: false },
    ];

    return (
      <Form onSubmit={this.handleSubmit} noValidate>
        <PanelSection>
          {!!size(opportunities) && (
            <Table
              cells={opportunityTableCells}
              rows={opportunities}
              rowComponent={OpportunityFormTableRow}
              rowProps={{
                paymentInfo,
              }}
            />
          )}
        </PanelSection>
      </Form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  opportunities: state.opportunity.opportunity.opportunities,
  deliveries: state.opportunity.opportunity.deliveries,
  paymentInfo: state.opportunity.opportunity.paymentInfo,
  initialValues: opportunityFormInitialValueSelector(state.opportunity.opportunity.opportunities),
  hasViewOnlyPermission: (hasPermissionSelector as any)(state.account.permissions, OPPORTUNITIES),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'opportunityForm',
      enableReinitialize: true,
    })(OpportunityForm),
  ),
);
