import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import { SortableTableCells } from '.';
import { Table as TableContainer, TableBody, TableHead, TableHeadCell, TableRow } from './styled';

class SortableTable extends PureComponent<Props> {
  private containerRef: any;

  onSortEnd = ({ oldIndex, newIndex }: any) => {
    this.props.sort(oldIndex, newIndex);
  };

  onSortStart = (node: any, event: any) => event.preventDefault();

  render() {
    const {
      cells,
      noOverflow,
      overflow,
      rowComponent,
      rowProps,
      rows,
      virtualized,
      virtualizedProps,
      visibility,
      withClickableRows,
    } = this.props;

    return (
      <TableContainer>
        <TableHead>
          <TableRow>
            {cells.map(({ name, label, component: Component, componentProps, ...cellProps }) => (
              <TableHeadCell key={name} {...cellProps} visibility={visibility}>
                {Component ? <Component {...componentProps} /> : label}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody
          withClickableRows={withClickableRows}
          virtualized={virtualized}
          visibility={visibility}
          overflow={overflow}
        >
          <SortableTableCells
            lockAxis="y"
            lockToContainerEdges
            noOverflow={noOverflow}
            onSortEnd={this.onSortEnd}
            onSortStart={this.onSortStart}
            rowComponent={rowComponent}
            rowProps={rowProps}
            rows={rows!}
            useDragHandle
            virtualized={virtualized}
            virtualizedProps={virtualizedProps}
            setContainerRef={(ref: any) => (this.containerRef = ref)}
            getContainer={() => ReactDOM.findDOMNode(this.containerRef) as HTMLDivElement}
            overflow={overflow}
          />
        </TableBody>
      </TableContainer>
    );
  }
}

interface Props {
  cells: any[];
  noOverflow?: boolean;
  overflow?: string;
  rowComponent: any;
  rowProps?: any;
  rows?: any[];
  sort: (oldIndex: number, newIndex: number) => void;
  virtualized?: boolean;
  virtualizedProps?: any;
  visibility?: string;
  withClickableRows?: boolean;
}

export default SortableTable;
