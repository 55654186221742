import { map } from 'lodash-es';

import { getAgingIntervalOptionLabel } from '../utils/routeDetails';
import { http } from 'src/core/services/http';
import { ServiceDetails } from '../interfaces/ServiceDetails';

export const loadServiceDetails = (vendorId: number, routeId: number) =>
  http.get<ServiceDetails>(`${vendorId}/routes/${routeId}/routeDetail/serviceDetails`).then(response => response.data);

export const loadSnowSweeperServiceDetails = (
  vendorId: number,
  routeId: number,
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get<ServiceDetails>(`${vendorId}/routes/${routeId}/routeDetail/alternativeFleet/serviceDetails`, {
      params: { startDate, endDate },
    })
    .then(response => {
      return {
        ...response.data,
        agingIntervals: map(response.data.agingIntervals, agingInterval => {
          const { red, blue, green, timeMeasurementTypeId } = agingInterval;
          const { label, value } = agingInterval.valueLabel as any;

          return {
            label: getAgingIntervalOptionLabel(timeMeasurementTypeId, label),
            value,
            color: `rgba(${red}, ${green}, ${blue})`,
          };
        }),
      };
    });
