import { FC, Fragment } from 'react';
import { change } from 'redux-form';
import { formValueSelector } from 'redux-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { AccordionSection, AccordionToggleIconSimple } from 'src/core/components/styled/ConfigurationAccordion';
import { ActionButtonTooltip, Checkbox, Collapsible, DatePicker, Input } from 'src/core/components';
import { BULKY_CATEGORY_TYPES, BULKY_ITEM_TYPES, OTHER_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { BULKY_ITEM_SCHEDULER_FORM } from 'src/vendors/constants';
import { Grid, GridColumn, Message, Separator, Text, TableActionButton } from '../../../core/components/styled';
import { isBeforeStartDateMaterialSettings } from 'src/vendors/services/bulkyItemSchedulerValidator';
import { isRequired, maxValueNumeric9999_99, optionalMinValueNumeric0 } from 'src/utils/services/validator';
import { MaterialPickupSettings, SubsetItems } from 'src/vendors/interfaces/BulkyItemScheduler';
import { TypedField } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import BulkyItemPickupMaterialsAndPricingValidationField, {
  bulkyItemPickupMaterialsAndPricingValidate,
} from './BulkyItemPickupMaterialsAndPricingValidationField';
import translate from '../../../core/services/translate';

const formSelector = formValueSelector(BULKY_ITEM_SCHEDULER_FORM);

type Props = {
  categoriesOpen: boolean[];
  setIsCatgoryOpen: (index: number) => void;
};

const BulkyItemPickupMaterialsAndPricingSection: FC<Props> = ({ categoriesOpen, setIsCatgoryOpen }) => {
  const dispatch = useDispatch();

  const materialPickupSettings = useSelector(state => formSelector(state, 'materialPickupSettings'));

  const setNewMaterialPickupSettings = (newMaterialPickupSettings: MaterialPickupSettings) => {
    dispatch(change(BULKY_ITEM_SCHEDULER_FORM, 'materialPickupSettings', newMaterialPickupSettings));
  };

  // hide add new feature for now
  // const addNewItem = () => {
  //   const newMaterialPickupSettings = materialPickupSettings?.map((materialPickup: MaterialPickupSettings) => {
  //     const isCategoryOther = materialPickup.bulkyCategoryTypeId === OTHER_ID;

  //     return {
  //       ...materialPickup,
  //       categorySettings: {
  //         ...materialPickup.categorySettings,
  //         isEnabled: isCategoryOther ? true : materialPickup.categorySettings.isEnabled,
  //       },
  //       subsetItems: isCategoryOther
  //         ? [
  //             ...materialPickup.subsetItems,
  //             { customBulkyItemName: null, endDate: null, price: null, startFrom: null, isEnabled: true },
  //           ]
  //         : materialPickup.subsetItems,
  //     };
  //   });

  //   setNewMaterialPickupSettings(newMaterialPickupSettings);
  // };

  const deleteItem = (subsetItemIndex: number) => {
    const newMaterialPickupSettings = materialPickupSettings.map((materialPickup: MaterialPickupSettings) => {
      const isCategoryOther = materialPickup.bulkyCategoryTypeId === OTHER_ID;

      return {
        ...materialPickup,
        subsetItems: isCategoryOther
          ? materialPickup.subsetItems.filter((_: SubsetItems, itemIndex: number) => itemIndex !== subsetItemIndex)
          : materialPickup.subsetItems,
      };
    });

    const savedMaterialPickupSettings = newMaterialPickupSettings.map((materialPickup: MaterialPickupSettings) => {
      const isCategoryOther = materialPickup.bulkyCategoryTypeId === OTHER_ID;
      const categoryItemsCheckedLength = isCategoryOther
        ? materialPickup.subsetItems.filter((subsetItem: SubsetItems) => subsetItem.isEnabled)?.length
        : 0;

      return {
        ...materialPickup,
        categorySettings: {
          ...materialPickup.categorySettings,
          isEnabled:
            isCategoryOther && categoryItemsCheckedLength === 0 ? false : materialPickup.categorySettings?.isEnabled,
        },
      };
    });

    setNewMaterialPickupSettings(savedMaterialPickupSettings);
  };

  const onCategorySettingsEnableToggle = (_: any, value: boolean, index: number) => {
    const isEnabled = value;

    const newMaterialPickupSettings = materialPickupSettings.map(
      (materialPickup: MaterialPickupSettings, categoryIndex: number) => {
        return {
          ...materialPickup,
          subsetItems: materialPickup.subsetItems.map(subsetItem => {
            return {
              ...subsetItem,
              isEnabled: categoryIndex === index ? isEnabled : subsetItem.isEnabled,
            };
          }),
        };
      },
    );

    setNewMaterialPickupSettings(newMaterialPickupSettings);
  };

  const onCategoryItemSettingsEnableToggle = (_: any, value: boolean, index: number) => {
    const isEnabled = value;

    const newMaterialPickupSettings = materialPickupSettings.map(
      (materialPickup: MaterialPickupSettings, categoryIndex: number) => {
        const categoryItemsCheckedLength = materialPickup.subsetItems.filter(
          (subsetItem: SubsetItems) => subsetItem.isEnabled,
        )?.length;

        return {
          ...materialPickup,
          categorySettings: {
            ...materialPickup.categorySettings,
            isEnabled:
              categoryIndex === index
                ? isEnabled
                  ? true
                  : categoryItemsCheckedLength - 1 === 0
                  ? false
                  : materialPickup.categorySettings?.isEnabled
                : materialPickup.categorySettings?.isEnabled,
          },
        };
      },
    );

    setNewMaterialPickupSettings(newMaterialPickupSettings);
  };

  const hasNoMaterialPickupSettings = materialPickupSettings?.length === 1 && materialPickupSettings[0] === undefined;

  return (
    <GridColumn size="12/12" padding="no xSmall small xSmall">
      <Text block size="xLarge" weight="medium" margin="no no medium no" id="atLeastOnePickupMaterialAndPricing">
        {translate('vendors.bulkyItemScheduler.pickupMaterialsAndPricing')}
      </Text>

      <GridColumn size="12/12" padding="no">
        <TypedField
          name="atLeastOnePickupMaterialAndPricing"
          component={BulkyItemPickupMaterialsAndPricingValidationField}
          validate={[bulkyItemPickupMaterialsAndPricingValidate]}
        />
      </GridColumn>

      {!hasNoMaterialPickupSettings ? (
        <Grid multiLine margin="small no no no" padding="no xSmall small xSmall">
          {materialPickupSettings?.map((materialPickupSetting: MaterialPickupSettings, index: number) => (
            <Fragment key={index}>
              <GridColumn size="3/12" padding="xSmall xSmall no no" verticalAlign="baseline" id={`category-${index}`}>
                <AccordionSection onClick={() => setIsCatgoryOpen(index)} isOpen={!categoriesOpen[index]}>
                  <AccordionToggleIconSimple />
                </AccordionSection>
                <Text size="medium" weight="medium" margin="no no xSmall xSmall">
                  {translate('vendors.bulkyItemScheduler.category')}
                  {!categoriesOpen[index] && (
                    <Text size="small" weight="light">
                      {': '}
                      {BULKY_CATEGORY_TYPES[materialPickupSetting?.bulkyCategoryTypeId]?.name}
                    </Text>
                  )}
                </Text>
              </GridColumn>

              {categoriesOpen[index] && (
                <>
                  <GridColumn size="2/12" padding="xSmall xSmall no xSmall">
                    <Text block size="medium" weight="medium" margin="no no xSmall no">
                      {translate('vendors.bulkyItemScheduler.price')}
                    </Text>
                  </GridColumn>

                  <GridColumn size="2/12" padding="xSmall xSmall no xSmall">
                    <Text block size="medium" weight="medium" margin="no no xSmall no">
                      {translate('vendors.bulkyItemScheduler.priceStartDate')}
                    </Text>
                  </GridColumn>

                  <GridColumn size="2/12" padding="xSmall xSmall no xSmall">
                    <Text block size="medium" weight="medium" margin="no no xSmall no">
                      {translate('vendors.bulkyItemScheduler.priceEndDate')}
                    </Text>
                  </GridColumn>

                  <GridColumn size="3/12" padding="xSmall no no xSmall">
                    <Text block size="medium" weight="medium" margin="no no xSmall no">
                      {translate('vendors.bulkyItemScheduler.optionalMessaging')}
                    </Text>
                  </GridColumn>
                </>
              )}

              <Collapsible isOpen={categoriesOpen[index]} width="100%">
                <Grid multiLine margin="small no no no">
                  <GridColumn size="3/12" padding="xSmall xSmall small sMedium">
                    <TypedField
                      name={`materialPickupSettings[${index}].categorySettings.isEnabled`}
                      component={Checkbox}
                      props={{
                        label: BULKY_CATEGORY_TYPES[materialPickupSetting?.bulkyCategoryTypeId]?.name,
                        block: true,
                        margin: 'no',
                        size: 'small',
                      }}
                      onChange={(_: any, value: boolean) => onCategorySettingsEnableToggle(_, value, index)}
                    />
                  </GridColumn>

                  <GridColumn size="2/12" padding="xSmall xSmall small xSmall">
                    <TypedField
                      name={`materialPickupSettings[${index}].categorySettings.price`}
                      component={Input}
                      props={{
                        margin: 'no',
                        type: 'number',
                        showErrorBeforeSubmit: true,
                        placeholder: translate('vendors.bulkyItemScheduler.price'),
                      }}
                      validate={[optionalMinValueNumeric0, maxValueNumeric9999_99]}
                    />
                  </GridColumn>

                  <GridColumn size="2/12" padding="xSmall xSmall small xSmall"></GridColumn>
                  <GridColumn size="2/12" padding="xSmall xSmall small xSmall"></GridColumn>

                  <GridColumn size="3/12" padding="xSmall no small xSmall">
                    <TypedField
                      name={`materialPickupSettings[${index}].categorySettings.message`}
                      component={Input}
                      props={{
                        margin: 'no',
                        placeholder: translate('common.message'),
                      }}
                    />
                  </GridColumn>

                  {materialPickupSetting?.subsetItems?.map((subsetItem, subsetItemIndex) => {
                    const currentMaterialPickupSetting = materialPickupSettings[index]?.subsetItems[subsetItemIndex];

                    return (
                      <Fragment key={subsetItemIndex}>
                        <GridColumn size="3/12" padding="no xSmall no medium" verticalAlign="baseline">
                          <TypedField
                            name={`materialPickupSettings[${index}].subsetItems[${subsetItemIndex}].isEnabled`}
                            component={Checkbox}
                            props={{
                              label: subsetItem.bulkyItemTypeId
                                ? BULKY_ITEM_TYPES[subsetItem.bulkyItemTypeId].name
                                : undefined,
                              block: true,
                              margin: 'no no no xSmall',
                              size: 'small',
                            }}
                            onChange={(_: any, value: boolean) => onCategoryItemSettingsEnableToggle(_, value, index)}
                          />
                          {materialPickupSetting?.bulkyCategoryTypeId === OTHER_ID && (
                            <TypedField
                              name={`materialPickupSettings[${index}].subsetItems[${subsetItemIndex}].customBulkyItemName`}
                              component={Input}
                              props={{
                                padding: 'no no no xSmall',
                                showErrorBeforeSubmit: true,
                              }}
                              validate={[isRequired]}
                            />
                          )}
                        </GridColumn>

                        <GridColumn size="2/12" padding="no xSmall small xSmall">
                          <TypedField
                            name={`materialPickupSettings[${index}].subsetItems[${subsetItemIndex}].price`}
                            component={Input}
                            props={{
                              margin: 'no',
                              type: 'number',
                              showErrorBeforeSubmit: true,
                              placeholder: translate('vendors.bulkyItemScheduler.price'),
                            }}
                            validate={[optionalMinValueNumeric0, maxValueNumeric9999_99]}
                          />
                        </GridColumn>

                        <GridColumn size="2/12" padding="no xSmall small xSmall">
                          <TypedField
                            name={`materialPickupSettings[${index}].subsetItems[${subsetItemIndex}].startFrom`}
                            component={DatePicker}
                            props={{
                              margin: 'no',
                              disabledDays: [
                                {
                                  before: moment().toDate(),
                                },
                              ],
                              isClearable: true,
                              shouldNotLoadDataRetention: true,
                              showErrorBeforeSubmit: true,
                              placeholder: translate('common.startDate'),
                            }}
                          />
                        </GridColumn>

                        <GridColumn size="2/12" padding="no xSmall small xSmall">
                          <TypedField
                            name={`materialPickupSettings[${index}].subsetItems[${subsetItemIndex}].endDate`}
                            component={DatePicker}
                            props={{
                              margin: 'no',
                              disabledDays: [
                                {
                                  before: currentMaterialPickupSetting.startFrom
                                    ? moment(currentMaterialPickupSetting.startFrom).toDate()
                                    : moment().toDate(),
                                },
                              ],
                              isClearable: true,
                              shouldNotLoadDataRetention: true,
                              showErrorBeforeSubmit: true,
                              placeholder: translate('common.endDate'),
                            }}
                            validate={[isBeforeStartDateMaterialSettings]}
                          />
                        </GridColumn>

                        <GridColumn size="3/12" padding="no no small xSmall" spaceBetween verticalAlign="flex-start">
                          <TypedField
                            name={`materialPickupSettings[${index}].subsetItems[${subsetItemIndex}].message`}
                            component={Input}
                            props={{
                              margin: 'no',
                              placeholder: translate('common.message'),
                            }}
                          />

                          {materialPickupSetting?.bulkyCategoryTypeId === OTHER_ID && (
                            <TableActionButton onClick={() => deleteItem(subsetItemIndex)}>
                              <ActionButtonTooltip icon="delete" tooltip="delete" />
                            </TableActionButton>
                          )}
                        </GridColumn>
                      </Fragment>
                    );
                  })}

                  {/* hide add new feature for now */}
                  {/* {materialPickupSetting?.bulkyCategoryTypeId === OTHER_ID && (
                    <Button color="primary" margin="no no no lMedium" type="button" size="small" onClick={addNewItem}>
                      {translate('common.addNew')}
                    </Button>
                  )} */}
                </Grid>
              </Collapsible>

              {index + 1 < materialPickupSettings.length && (
                <Separator color="grayLight" size={1} margin="no no xSmall no" />
              )}
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Message padding="xSmall no">
          {translate('vendors.alertMessages.bulkyItemSchedulerError.noMaterialTypesDefined')}
        </Message>
      )}

      <Separator color="grayLight" size={2} margin="sMedium no xSmall no" />
    </GridColumn>
  );
};

export default BulkyItemPickupMaterialsAndPricingSection;
