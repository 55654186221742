import React, { useEffect, useMemo, useState } from 'react';

import { getFormValues } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { ACTIVE, INACTIVE, AVAILABLE, UNAVAILABLE } from '../../constants/status';
import { AppState } from '../../../store';
import { ButtonLink, Message, Panel, PanelSection, PanelSectionGroup, Text } from '../../../core/components/styled';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
// import { checkVehiclesHaveRoutesInRange } from 'src/fleet/ducks/vehicle';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { currentVendorId } from '../../../vendors/services/currentVendorSelector';
import { DetailsContainer, StatusFiltersContainer, StatusFilterButton } from '../styled/RubiconDispatches';
import { ENGINE_HOURS_DATA_ID, FUEL_DATA_ID, ODOMETER_DATA_ID } from 'src/dashboard/constants/yData';
import { FACILITY_NONE_VALUE } from 'src/fleet/constants/drivers';
import { FLEET_VEHICLES_CONFIGURE_VEHICLE_TYPES, FLEET_VEHICLES_CREATE } from '../../../account/constants';
import { getIsFacilityActive, useVehiclesTableColumns } from '../utils/vehiclesPageHooks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { hasPermissionSelector } from '../../../account/ducks';
import { MoreButtonItem } from '../../../core/components/MoreButton';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
  ButtonWrapperWithPopover,
} from '../../../common/components/styled';
import { PageFooter } from 'src/common/components/styled';
import { PermissionGuard } from '../../../account/components';
import { TABLE_ROW_HEIGHT_SMALL, TODAY } from 'src/core/constants';
import { Table, MoreButton } from '../../../core/components';
import { uploadVehiclesFile, downloadSampleVehicleTemplate } from '../../services/vehicleImport';
import { useSmartFilters } from '../../../common/hooks/smartFilters';
import { vehiclesSmartFilters } from './vehiclesPageSections/vehiclesSmartFilters';
import * as Ducks from '../../ducks/vehicles';
import * as Services from '../../services/vehicles';
import confirm from '../../../core/services/confirm';
import normalizeBoolean from '../../../core/services/normalizeBoolean';
import RecordsUploaderModal from '../../../common/components/RecordsUploaderModal';
import ResourcesAssignForm, { RESOURCES_ASSIGN_FORM } from '../forms/ResourcesAssignForm';
import translate from '../../../core/services/translate';
import VehiclesForm, { VehiclesFormValues } from '../forms/VehiclesForm';
import VehiclesPageCharts from './vehiclesPageSections/VehiclesPageCharts';
import VehiclesPageTableRow from './vehiclesPageSections/VehiclesPageTableRow';

interface QueryParams {
  operationalFacility?: number;
  searchTerm?: string;
  showActiveVehicles?: string;
  showAvailableVehicles?: string;
  vehicleTypeIds?: string;
}

interface Props extends RouteComponentProps {}

const VehiclesPage: React.FC<Props> = ({ location: { search } }) => {
  const dispatch = useDispatch();

  const [isVehicleImportModalOpen, setVehicleImportModalOpen] = useState(false);
  const [checkedVehicles, setCheckedVehicles] = useState<any[]>([]);

  const vendorId = useSelector(currentVendorId);
  const {
    isDeletingVehicles,
    isAssigningVehicles,
    vehicleListLoading: vehiclesLoading,
    vehicleList: vehicles,
  } = useSelector((state: AppState) => state.fleet.vehicles);
  const vendorHasActivePM = useSelector((state: AppState) => state.fleet.preventativeMaintenance.vendorHasActivePM);
  const allVehicleTypeIds = useSelector((state: AppState) =>
    state.fleet.vehicleTypes.vehicleTypes.map(type => type.id).join(','),
  );
  const hasPermissionConfigureVehicleTypes = useSelector((state: AppState) =>
    hasPermissionSelector(state.account.permissions, FLEET_VEHICLES_CONFIGURE_VEHICLE_TYPES),
  );
  const { vehicleFacilities, isCheckingVehiclesHaveRoutesInRange } = useSelector(
    (state: AppState) => state.fleet.vehicle,
  );

  const formValues = useSelector(getFormValues(RESOURCES_ASSIGN_FORM)) as any;
  const isNewServiceStatusUnavailable = formValues?.serviceStatus === UNAVAILABLE;

  const { yData } = useSelector((state: AppState) => state.vendors.yData);
  const isOdometerReadingDataVisible = yData.find(data => data.yDataType.id === ODOMETER_DATA_ID)?.isActive;
  const isEngineHoursDataVisible = yData.find(data => data.yDataType.id === ENGINE_HOURS_DATA_ID)?.isActive;
  const isFuelDataVisible = yData.find(data => data.yDataType.id === FUEL_DATA_ID)?.isActive;

  const smartFilters = useMemo(() => vehiclesSmartFilters(vendorHasActivePM), [vendorHasActivePM]);
  const { filters, activeFilterEntries: filteredVehicles } = useSmartFilters(smartFilters, vehicles);

  const checkAllVehicles = () => {
    if (!!checkedVehicles.length && checkedVehicles.length <= filteredVehicles.length) {
      setCheckedVehicles([]);
    } else {
      setCheckedVehicles(filteredVehicles);
    }
  };

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();
  const isNotSupportAndIsNotViewOnly = !isSupport && !isViewOnly;

  const allChecked = checkedVehicles.length === filteredVehicles.length;
  const partialChecked = 0 < checkedVehicles.length && checkedVehicles.length < filteredVehicles.length;
  const { columns } = useVehiclesTableColumns(
    isNotSupportAndIsNotViewOnly,
    checkAllVehicles,
    allChecked,
    partialChecked,
    isEngineHoursDataVisible,
    isFuelDataVisible,
    isOdometerReadingDataVisible,
  );

  const {
    searchTerm,
    vehicleTypeIds: rawVehicleTypeIds,
    showActiveVehicles: rawShowActiveVehicles,
    showAvailableVehicles: rawShowAvailableVehicles,
    operationalFacility,
  } = getQueryParams<QueryParams>(search);

  const showActiveVehicles = normalizeBoolean(rawShowActiveVehicles);
  const showAvailableVehicles = normalizeBoolean(rawShowAvailableVehicles);

  const vehicleTypeIds = useMemo(
    () =>
      (rawVehicleTypeIds || allVehicleTypeIds || '')
        .toString()
        .split(',')
        .map(vehicleTypeId => +vehicleTypeId),
    [rawVehicleTypeIds, allVehicleTypeIds],
  );

  const vehiclesFormInitialValues: Partial<VehiclesFormValues> = {
    searchTerm,
    vehicleTypes: vehicleTypeIds,
    vehicleStatus: showActiveVehicles === true ? ACTIVE : showActiveVehicles === false ? INACTIVE : undefined,
    serviceStatus:
      showAvailableVehicles === true ? AVAILABLE : showAvailableVehicles === false ? UNAVAILABLE : undefined,
    operationalFacility: Number(operationalFacility),
  };

  const payload = useMemo(
    () => ({
      searchTerm,
      showActiveVehicles,
      showAvailableVehicles,
      vehicleTypeIds,
      operationalFacility,
    }),
    [searchTerm, showActiveVehicles, showAvailableVehicles, vehicleTypeIds, operationalFacility],
  );

  const deleteVehicle = async (vehicleId: number) => {
    if (!(await confirm(translate('vehicles.alertMessages.confirmDeleteVehicle')))) {
      return;
    }

    Ducks.deleteVehicle(vehicleId)(dispatch)
      .then(() => {
        createSuccessNotification(translate('vehicles.alertMessages.vehicleDeleted'));

        const checkedVehiclesAfterDelete = checkedVehicles.filter(vehicle => vehicle.id !== vehicleId);
        setCheckedVehicles(checkedVehiclesAfterDelete);
      })
      .catch(() => {
        createErrorNotification(translate('vehicles.alertMessages.vehicleDeleteError'));
      });
  };

  const exportVehicleList = () => {
    if (!vendorId) {
      return;
    }

    Services.exportVehicleList(vendorId, payload);
  };

  useEffect(() => {
    if (!vendorId) {
      return;
    }

    setCheckedVehicles([]);

    Ducks.loadVehicleList(vendorId, payload)(dispatch);
    Ducks.loadVehicleSummary(vendorId, payload)(dispatch);
    Ducks.checkVendorHasMileageInformation(vendorId, payload.vehicleTypeIds)(dispatch);
  }, [dispatch, payload, vendorId]);

  useEffect(
    () => () => {
      dispatch(Ducks.resetVehicles());
    },
    [dispatch],
  );

  const moreButtonItems: MoreButtonItem[] = [
    {
      handler: () => setVehicleImportModalOpen(true),
      id: 'vehicle-import-button',
      text: translate('vehicles.vehicleImport.buttonTitle'),
    },
    {
      handler: exportVehicleList,
      id: 'vehicle-export-button',
      text: translate('common.export'),
    },
  ];

  if (hasPermissionConfigureVehicleTypes) {
    moreButtonItems.push({
      id: 'config-vehicle-types-button',
      isLink: true,
      to: '/fleet/vehicles/config-vehicle-types',
      text: translate('vehicles.configVehicleTypes'),
    });
  }

  const virtualizedProps = {
    height: Math.min(filteredVehicles.length * TABLE_ROW_HEIGHT_SMALL, TABLE_ROW_HEIGHT_SMALL * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_SMALL,
  };

  const checkVehicle = (event: any, id: number) => {
    let vehicleExistsInList = false;

    checkedVehicles.forEach(vehicle => {
      if (vehicle.id === id) {
        vehicleExistsInList = true;
      }
    });

    if (vehicleExistsInList) {
      const checkedAllVehicles = checkedVehicles.filter(vehicle => vehicle.id !== id);
      setCheckedVehicles([...checkedAllVehicles]);
    } else {
      const checkedVehicle = filteredVehicles.filter(vehicle => vehicle.id === id);
      setCheckedVehicles([...checkedVehicles, ...(checkedVehicle || [])]);
    }
  };

  const onDeleteVehicles = async () => {
    if (!(await confirm(translate('vehicles.alertMessages.confirmDeleteVehicles')))) {
      return;
    }

    const deletedVehiclesIds = checkedVehicles.map(vehicle => vehicle.id);

    Ducks.deleteVehicles(
      vendorId,
      deletedVehiclesIds,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vehicles.alertMessages.vehiclesDeleted'));

        setCheckedVehicles([]);

        Ducks.loadVehicleList(vendorId, payload)(dispatch);
        Ducks.loadVehicleSummary(vendorId, payload)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('vehicles.alertMessages.vehiclesDeletedError'));
      });
  };

  const onAssignVehicles = async (
    newStatus?: boolean,
    newServiceStatus?: boolean,
    newFacility?: number,
    vehicleUnavailableEndDate?: Date | string,
    vehicleUnavailableStartDate?: Date | string,
  ) => {
    const isFacilityActive = getIsFacilityActive(vehicleFacilities, undefined, newFacility);
    if (!isFacilityActive) {
      if (
        !(await confirm(
          translate(
            `vehicles.alertMessages.${
              checkedVehicles.length > 1 ? 'saveVehiclesWithInactiveFacility' : 'saveVehicleWithInactiveFacility'
            }`,
          ),
        ))
      ) {
        return;
      }
    }

    // TODO: uncomment after BE is done (see RVP-5369)

    // const currrentVehiclesWithRoutesInRange =
    //   newServiceStatus === false
    //     ? await checkVehiclesHaveRoutesInRange(
    //         vendorId,
    //         checkedVehicles.map(checkedVehicle => checkedVehicle.id),
    //         vehicleUnavailableStartDate || TODAY,
    //         vehicleUnavailableEndDate,
    //       )(dispatch)
    //     : [];

    // if (!!currrentVehiclesWithRoutesInRange.length) {
    //   const vehicleNamesWithRoutesInRange = checkedVehicles
    //     .filter(checkedVehicle =>
    //       currrentVehiclesWithRoutesInRange.find(currrentVehicle => currrentVehicle.vehicleId === checkedVehicle.id),
    //     )
    //     .map(checkedVehicle => checkedVehicle.regplate);

    //   if (
    //     !(await confirm(
    //       translate('vehicles.alertMessages.vehiclesHaveRoutesInRange', {
    //         selectedVehicles: vehicleNamesWithRoutesInRange.toString(),
    //       }),
    //       '',
    //       translate('common.cancel'),
    //       translate('common.continue'),
    //     ))
    //   )
    //     return;
    // }

    const assignedVehicles = checkedVehicles.map(vehicle => {
      const isActive = newStatus !== undefined ? newStatus : vehicle.isActive;
      const isAvailable = newServiceStatus !== undefined ? newServiceStatus : vehicle.isAvailable;
      const vehicleUnavailable =
        newServiceStatus && !vehicle.vehicleUnavailable
          ? null
          : newServiceStatus
          ? {
              id: vehicle.vehicleUnavailable?.id,
              startDate: vehicle.vehicleUnavailable?.startDate || TODAY,
              endDate: vehicle.vehicleUnavailable?.endDate,
              isDeleted: !!vehicle.vehicleUnavailable,
            }
          : newServiceStatus === false
          ? {
              id: vehicle.vehicleUnavailable?.id,
              startDate: vehicleUnavailableStartDate || TODAY,
              endDate: vehicleUnavailableEndDate,
              isDeleted: vehicle.vehicleUnavailable?.isDeleted,
            }
          : vehicle.vehicleUnavailable;

      return {
        ...vehicle,
        vehicleUnavailable,
        isActive,
        isAvailable,
        locationId:
          newFacility !== undefined ? (newFacility === FACILITY_NONE_VALUE ? null : newFacility) : vehicle.locationId,
      };
    });

    Ducks.assignVehicles(
      vendorId,
      assignedVehicles,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('vehicles.alertMessages.vehiclesAssigned'));

        setCheckedVehicles([]);

        Ducks.loadVehicleList(vendorId, payload)(dispatch);
        Ducks.loadVehicleSummary(vendorId, payload)(dispatch);
      })
      .catch(() => {
        createErrorNotification(translate('vehicles.alertMessages.vehiclesAssignedError'));
      });
  };

  const getVehicles = () =>
    filteredVehicles.map(vehicle => ({
      checked: checkedVehicles.some(checkedVehicle => checkedVehicle.id === vehicle.id),
      ...vehicle,
    }));

  return (
    <PageContent
      paddingBottom={isNewServiceStatusUnavailable ? '180px' : !!checkedVehicles.length ? '100px' : '70px'}
      marginBottom={'0'}
    >
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('vehicles.vehicles')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions flex>
          <MoreButton margin="no small no no" items={moreButtonItems} />

          <PermissionGuard permission={FLEET_VEHICLES_CREATE}>
            <ButtonLink to="/fleet/vehicles/create" color="primary" id="create-vehicle-button">
              {translate('vehicles.createVehicle')}
            </ButtonLink>
          </PermissionGuard>
        </PageActions>
      </PageHeader>

      <Panel margin="no">
        <PanelSectionGroup
          isLoading={
            vehiclesLoading || isDeletingVehicles || isAssigningVehicles || isCheckingVehiclesHaveRoutesInRange
          }
        >
          <VehiclesForm initialValues={vehiclesFormInitialValues} />

          <VehiclesPageCharts />

          <PanelSection>
            <DetailsContainer noShadow padding="no">
              <StatusFiltersContainer padding="xxSmall">
                {filters.map(filter => (
                  <ButtonWrapperWithPopover key={filter.id}>
                    <StatusFilterButton type="button" isActive={filter.isActive} onClick={() => filter.setActive()}>
                      <Text uppercase size="small" weight="medium" margin="no" color="primary">
                        {filter.title}
                      </Text>

                      {!!filter.dynamicSubtitle && (
                        <Text size="small" margin="xxSmall no no" weight="medium">
                          {filter.dynamicSubtitle(filter.entriesCount)}
                        </Text>
                      )}
                    </StatusFilterButton>
                  </ButtonWrapperWithPopover>
                ))}
              </StatusFiltersContainer>
            </DetailsContainer>
          </PanelSection>

          <PanelSection>
            {!!filteredVehicles.length && (
              <Table
                cells={columns}
                rowComponent={VehiclesPageTableRow}
                rowProps={{
                  deleteVehicle,
                  isEngineHoursDataVisible,
                  isFuelDataVisible,
                  isNotSupportAndIsNotViewOnly,
                  isOdometerReadingDataVisible,
                  onCheckChange: checkVehicle,
                }}
                rows={getVehicles()}
                scrollMarker
                virtualized
                virtualizedProps={virtualizedProps}
                withTopBorder
              />
            )}

            {!filteredVehicles.length && <Message padding="sMedium">{translate('vehicles.noVehicles')}</Message>}
          </PanelSection>
        </PanelSectionGroup>
      </Panel>

      {isVehicleImportModalOpen && (
        <RecordsUploaderModal
          downloadSample={downloadSampleVehicleTemplate}
          onClose={() => setVehicleImportModalOpen(false)}
          parentTranslationsPath="vehicles.vehicleImport"
          uploadFile={uploadVehiclesFile}
          vendorId={vendorId}
        />
      )}

      {!!checkedVehicles.length && !isDeletingVehicles && !isAssigningVehicles && (
        <PageFooter>
          <ResourcesAssignForm
            assignResources={onAssignVehicles}
            checkedResources={checkedVehicles}
            deleteResources={onDeleteVehicles}
            facilities={vehicleFacilities}
            isSourceVehicles
          />
        </PageFooter>
      )}
    </PageContent>
  );
};

export default withRouter(VehiclesPage);
