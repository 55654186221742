import disposal from '../../../../../common/assets/img/vendorLocationsNew/disposal.png'
import boxYard from '../../../../../common/assets/img/vendorLocations/boxYard.png';
import disposalSite from '../../../../../common/assets/img/vendorLocations/disposalSite.png';
import truckYard from '../../../../../common/assets/img/vendorLocations/truckYard.png';
import fuelingStation from '../../../../../common/assets/img/vendorLocationsNew/fuelingStation.png';
import containerYard from '../../../../../common/assets/img/vendorLocationsNew/containerYard.png';
import truckYardNew from '../../../../../common/assets/img/vendorLocationsNew/truckYardNew.png';
import maintenance from '../../../../../common/assets/img/vendorLocationsNew/maintenance.png';
import other from '../../../../../common/assets/img/vendorLocationsNew/other.png';
import waterFillingStation from '../../../../../common/assets/img/vendorLocationsNew/waterFillingStation.png';
import saltChemical from '../../../../../common/assets/img/vendorLocationsNew/saltChemicalFillingStation.png';
import icyBridge from 'src/common/assets/img/cityAlerts/icyBridge.png';
import locationAlert from 'src/common/assets/img/cityAlerts/locationAlert.png';
import metalPlates from 'src/common/assets/img/cityAlerts/metalPlates.png';
import slickSpot from 'src/common/assets/img/cityAlerts/slickSpot.png';
import snowDrift from 'src/common/assets/img/cityAlerts/snowDrift.png';
import speedBump from 'src/common/assets/img/cityAlerts/speedBump.png';
import accident from 'src/common/assets/img/cityAlerts/accident.png';
import baggedRecyclables from 'src/common/assets/img/insights/baggedRecyclables.png';
import brokenSidewalkCurb from 'src/common/assets/img/insights/brokenSidewalkCurb.png';
import brokenTrafficSignal from 'src/common/assets/img/insights/brokenTrafficSignal.png';
import cancelled from 'src/common/assets/img/insights/cancelled.png';
import cardboardInContainer from 'src/common/assets/img/insights/cardboardInContainer.png';
import cardboardNotFlattened from 'src/common/assets/img/insights/cardboardNotFlattened.png';
import cleanRecycling from 'src/common/assets/img/insights/cleanRecycling.png';
import cloggedInlet from 'src/common/assets/img/insights/cloggedInlet.png';
import cog from 'src/common/assets/img/insights/settings.png';
import completed from 'src/common/assets/img/insights/completed.png';
import containerNotOut from 'src/common/assets/img/insights/containerNotOut.png';
import containerOut from 'src/common/assets/img/insights/containerOut.png';
import contaminated from 'src/common/assets/img/insights/contaminated.png';
import curbObstruction from 'src/common/assets/img/insights/curbObstruction.png';
import damagedContainer from 'src/common/assets/img/insights/damagedContainer.png';
import damagedStreetSign from 'src/common/assets/img/insights/damagedStreetSign.png';
import exceptionsObstacles from 'src/common/assets/img/serviceExceptions/exceptionsObstacles.png';
import foodOrLiquid from 'src/common/assets/img/insights/foodOrLiquid.png';
import graffiti from 'src/common/assets/img/insights/graffiti.png';
import handicap from 'src/common/assets/img/insights/handicap.png';
import hangingWires from 'src/common/assets/img/insights/hangingWires.png';
import hardDriving from 'src/common/assets/img/insights/hardDriving.png';
import highGrassWeeds from 'src/common/assets/img/insights/grass.png';
import idleTime from 'src/common/assets/img/insights/idleTime.png';
import improperStorageArea from 'src/common/assets/img/insights/improperStorageArea.png';
import inProgress from 'src/common/assets/img/insights/inProgress.png';
import insideGeoFence from 'src/common/assets/img/insights/insideGeoFence.png';
import issue from 'src/common/assets/img/insights/issue.png';
import leftAsTrash from 'src/common/assets/img/insights/leftAsTrash.png';
import litterJunk from 'src/common/assets/img/insights/litter.png';
import lowBranches from 'src/common/assets/img/insights/lowBranches.png';
import materialContamination from 'src/common/assets/img/insights/materialContamination.png';
import metalWoodTanks from 'src/common/assets/img/insights/metalWoodTanks.png';
import missingNewAddress from 'src/common/assets/img/insights/missingNewAddress.png';
import multiple from 'src/common/assets/img/insights/multipleContamination.png';
import noElectronics from 'src/common/assets/img/insights/electronics.png';
import notSweeping from 'src/common/assets/img/insights/notSweeping.png';
import number1 from 'src/common/assets/img/insights/number1.png';
import number2 from 'src/common/assets/img/insights/number2.png';
import number3 from 'src/common/assets/img/insights/number3.png';
import offRoute from 'src/common/assets/img/insights/offRoute.png';
import offRouteStationary from 'src/common/assets/img/insights/offRouteStationary.png';
import oops from 'src/common/assets/img/insights/ooops.png';
import outsideGeoFence from 'src/common/assets/img/insights/outsideGeoFence.png';
import overflowingBins from 'src/common/assets/img/insights/overflowingBins.png';
import placedOnHold from 'src/common/assets/img/insights/placedOnHold.png';
import plasticBags from 'src/common/assets/img/insights/plasticBags.png';
import plasticInContainer from 'src/common/assets/img/insights/plasticInContainer.png';
import potholes from 'src/common/assets/img/insights/potHoles.png';
import recRolltainerOutside from 'src/common/assets/img/insights/recRolltainerOutside.png';
import scheduled from 'src/common/assets/img/insights/scheduled.png';
import serviced from 'src/common/assets/img/insights/serviced.png';
import speeding from 'src/common/assets/img/insights/speeding.png';
import stationary from 'src/common/assets/img/insights/stationary.png';
import streetLightBroken from 'src/common/assets/img/insights/streetLightBroken.png';
import streetObstruction from 'src/common/assets/img/insights/streetObstruction.png';
import styrofoam from 'src/common/assets/img/insights/styrofoam.png';
import tangledCords from 'src/common/assets/img/insights/tangledCords.png';
import textiles from 'src/common/assets/img/insights/textiles.png';
import thanks from 'src/common/assets/img/insights/thanks.png';
import trash from 'src/common/assets/img/insights/trash3048Gallons.png';
import unlockedContainer from 'src/common/assets/img/insights/unlockedContainer.png';
import unScheduled from 'src/common/assets/img/insights/unScheduled.png';
import vacant from 'src/common/assets/img/insights/vacant.png';
import vacantCar from 'src/common/assets/img/insights/vacantCar.png';

import icAssistedCancelled from 'src/common/assets/img/newInsights/Assisted_Cancelled.png';
import icAssistedCompleted from 'src/common/assets/img/newInsights/Assisted_Completed.png';
import icAssistedInProgress from 'src/common/assets/img/newInsights/Assisted_InProgress.png';
import icAssistedIssueReportedNotServiced from 'src/common/assets/img/newInsights/Assisted_IssueReported_NotServiced.png';
import icAssistedIssueReportedServiced from 'src/common/assets/img/newInsights/Assisted_IssueReported_Serviced.png';
import icAssistedIssueReported from 'src/common/assets/img/newInsights/Assisted_IssueReported.png';
import icAssistedOnHold from 'src/common/assets/img/newInsights/Assisted_OnHold.png';
import icAssistedReassigned from 'src/common/assets/img/newInsights/Assisted_Reassigned.png';
import icAssistedScheduled from 'src/common/assets/img/newInsights/Assisted_Scheduled.png';
import icCancelled from 'src/common/assets/img/newInsights/Cancelled.png';
import icCompleted from 'src/common/assets/img/newInsights/Completed.png';
import icScheduled from 'src/common/assets/img/newInsights/Scheduled.png';
import icFlaggedAssistedCancelled from 'src/common/assets/img/newInsights/Flagged_Assisted_Cancelled.png';
import icFlaggedAssistedCompleted from 'src/common/assets/img/newInsights/Flagged_Assisted_Completed.png';
import icFlaggedAssistedIssueReportedNotServiced from 'src/common/assets/img/newInsights/Flagged_Assisted_IssueReported_NotServiced.png';
import icFlaggedAssistedIssueReportedServiced from 'src/common/assets/img/newInsights/Flagged_Assisted_IssueReported_Serviced.png';
import icFlaggedAssistedIssueReported from 'src/common/assets/img/newInsights/Flagged_Assisted_IssueReported.png';
import icFlaggedAssistedReassigned from 'src/common/assets/img/newInsights/Flagged_Assisted_Reassigned.png';
import icFlaggedAssistedScheduled from 'src/common/assets/img/newInsights/Flagged_Assisted_Scheduled.png';
import icFlaggedAssistedOnHold from 'src/common/assets/img/newInsights/Flagged_Assisted_OnHold.png';
import icFlaggedCancelled from 'src/common/assets/img/newInsights/Flagged_Cancelled.png';
import icFlaggedCompleted from 'src/common/assets/img/newInsights/Flagged_Completed.png';
import icFlaggedInProgress from 'src/common/assets/img/newInsights/Flagged_InProgress.png';
import icFlaggedIssueReportedNotServiced from 'src/common/assets/img/newInsights/Flagged_IssueReported_NotServiced.png';
import icFlaggedIssueReportedServiced from 'src/common/assets/img/newInsights/Flagged_IssueReported_Serviced.png';
import icFlaggedIssueReported from 'src/common/assets/img/newInsights/Flagged_IssueReported.png';
import icFlaggedOnHold from 'src/common/assets/img/newInsights/Flagged_OnHold.png';
import icFlaggedReassigned from 'src/common/assets/img/newInsights/Flagged_Reassigned.png';
import icFlaggedScheduled from 'src/common/assets/img/newInsights/Flagged_Scheduled.png';
import icInProgress from 'src/common/assets/img/newInsights/InProgress.png';
import icIssueReportedNotServiced from 'src/common/assets/img/newInsights/IssueReported_NotServiced.png';
import icIssueReportedServiced from 'src/common/assets/img/newInsights/IssueReported_Serviced.png';
import icIssueReported from 'src/common/assets/img/newInsights/IssueReported.png';
import icNewAssistedCancelled from 'src/common/assets/img/newInsights/New_Assisted_Cancelled.png';
import icNewAssistedCompleted from 'src/common/assets/img/newInsights/New_Assisted_Completed.png';
import icNewAssistedInProgress from 'src/common/assets/img/newInsights/New_Assisted_InProgress.png';
import icNewAssistedIssueReportedNotServiced from 'src/common/assets/img/newInsights/New_Assisted_IssueReported_NotServiced.png';
import icNewAssistedIssueReportedServiced from 'src/common/assets/img/newInsights/New_Assisted_IssueReported_Serviced.png';
import icNewAssistedIssueReported from 'src/common/assets/img/newInsights/New_Assisted_IssueReported.png';
import icNewAssistedOnHold from 'src/common/assets/img/newInsights/New_Assisted_OnHold.png';
import icNewAssistedReassigned from 'src/common/assets/img/newInsights/New_Assisted_Reassigned.png';
import icNewAssistedScheduled from 'src/common/assets/img/newInsights/New_Assisted_Scheduled.png';
import icNewCancelled from 'src/common/assets/img/newInsights/New_Cancelled.png';
import icNewCompleted from 'src/common/assets/img/newInsights/New_Completed.png';
import icNewFlaggedAssistedCancelled from 'src/common/assets/img/newInsights/New_Flagged_Assisted_Cancelled.png';
import icNewFlaggedAssistedCompleted from 'src/common/assets/img/newInsights/New_Flagged_Assisted_Completed.png';
import icNewFlaggedAssistedInProgress from 'src/common/assets/img/newInsights/New_Flagged_Assisted_InProgress.png';
import icNewFlaggedAssistedIssueReportedNotServiced from 'src/common/assets/img/newInsights/New_Flagged_Assisted_IssueReported_NotServiced.png';
import icNewFlaggedAssistedIssueReportedServiced from 'src/common/assets/img/newInsights/New_Flagged_Assisted_IssueReported_Serviced.png';
import icNewFlaggedAssistedIssueReported from 'src/common/assets/img/newInsights/New_Flagged_Assisted_IssueReported.png';
import icNewFlaggedAssistedOnHold from 'src/common/assets/img/newInsights/New_Flagged_Assisted_OnHold.png';
import icNewFlaggedAssistedReassigned from 'src/common/assets/img/newInsights/New_Flagged_Assisted_Reassigned.png';
import icNewFlaggedAssistedScheduled from 'src/common/assets/img/newInsights/New_Flagged_Assisted_Scheduled.png';
import icNewFlaggedCancelled from 'src/common/assets/img/newInsights/New_Flagged_Cancelled.png';
import icNewFlaggedCompleted from 'src/common/assets/img/newInsights/New_Flagged_Completed.png';
import icNewFlaggedInProgress from 'src/common/assets/img/newInsights/New_Flagged_InProgress.png';
import icNewFlaggedIssueReportedNotServiced from 'src/common/assets/img/newInsights/New_Flagged_IssueReported_NotServiced.png';
import icNewFlaggedIssueReportedServiced from 'src/common/assets/img/newInsights/New_Flagged_IssueReported_Serviced.png';
import icNewFlaggedIssueReported from 'src/common/assets/img/newInsights/New_Flagged_IssueReported.png';
import icNewFlaggedOnHold from 'src/common/assets/img/newInsights/New_Flagged_OnHold.png';
import icNewFlaggedReassigned from 'src/common/assets/img/newInsights/New_Flagged_Reassigned.png';
import icNewFlaggedScheduled from 'src/common/assets/img/newInsights/New_Flagged_Scheduled.png';
import icNewInProgress from 'src/common/assets/img/newInsights/New_InProgress.png';
import icNewIssueReportedNotServiced from 'src/common/assets/img/newInsights/New_IssueReported_NotServiced.png';
import icNewIssueReportedServiced from 'src/common/assets/img/newInsights/New_IssueReported_Serviced.png';
import icNewIssueReported from 'src/common/assets/img/newInsights/New_IssueReported.png';
import icNewOnHold from 'src/common/assets/img/newInsights/New_OnHold.png';
import icNewReassigned from 'src/common/assets/img/newInsights/New_Reassigned.png';
import icNewScheduled from 'src/common/assets/img/newInsights/New_Scheduled.png';
import icOnHoldAssistedCompleted from 'src/common/assets/img/newInsights/OnHold_Assisted_Completed.png';
import icOnHoldAssistedCancelled from 'src/common/assets/img/newInsights/OnHold_Assisted_Cancelled.png';
import icOnHoldAssistedInProgress from 'src/common/assets/img/newInsights/OnHold_Assisted_InProgress.png';
import icOnHoldAssistedIssueReportedNotServiced from 'src/common/assets/img/newInsights/OnHold_Assisted_IssueReported_NotServiced.png';
import icOnHoldAssistedIssueReportedServiced from 'src/common/assets/img/newInsights/OnHold_Assisted_IssueReported_Serviced.png';
import icOnHoldAssistedIssueReported from 'src/common/assets/img/newInsights/OnHold_Assisted_IssueReported.png';
import icOnHoldAssistedOnHold from 'src/common/assets/img/newInsights/OnHold_Assisted_OnHold.png';
import icOnHoldAssistedReassigned from 'src/common/assets/img/newInsights/OnHold_Assisted_Reassigned.png';
import icOnHoldAssistedScheduled from 'src/common/assets/img/newInsights/OnHold_Assisted_Scheduled.png';
import icOnHoldCancelled from 'src/common/assets/img/newInsights/OnHold_Cancelled.png';
import icOnHoldCompleted from 'src/common/assets/img/newInsights/OnHold_Completed.png';
import icOnHoldFlaggedAssistedCancelled from 'src/common/assets/img/newInsights/OnHold_Flagged_Assisted_Cancelled.png';
import cart from 'src/common/assets/img/vehicles/cart.png';
import cartInactive from 'src/common/assets/img/vehicles/cartInactive.png';
import cartStationary from 'src/common/assets/img/vehicles/cartStationary.png';
import delivery from 'src/common/assets/img/vehicles/delivery.png';
import deliveryInactive from 'src/common/assets/img/vehicles/deliveryInactive.png';
import deliveryStationary from 'src/common/assets/img/vehicles/deliveryStationary.png';
import exclamation from 'src/common/assets/img/vehicles/exclamation.png';
import frontLoad from 'src/common/assets/img/vehicles/frontLoad.png';
import frontLoadInactive from 'src/common/assets/img/vehicles/frontLoadInactive.png';
import frontLoadStationary from 'src/common/assets/img/vehicles/frontLoadStationary.png';
import pickupTruck from 'src/common/assets/img/vehicles/pickUpTruck.png';
import pickupTruckInactive from 'src/common/assets/img/vehicles/pickUpTruckInactive.png';
import pickupTruckStationary from 'src/common/assets/img/vehicles/pickUpTruckStationary.png';
import residential from 'src/common/assets/img/vehicles/residential.png';
import residentialInactive from 'src/common/assets/img/vehicles/residentialInactive.png';
import residentialStationary from 'src/common/assets/img/vehicles/residentialStationary.png';
import rollOff from 'src/common/assets/img/vehicles/rollOff.png';
import rollOffInactive from 'src/common/assets/img/vehicles/rollOffInactive.png';
import rollOffStationary from 'src/common/assets/img/vehicles/rollOffStationary.png';
import snowPlow from 'src/common/assets/img/vehicles/snowPlow.png';
import snowPlowInactive from 'src/common/assets/img/vehicles/snowPlowInactive.png';
import snowPlowStationary from 'src/common/assets/img/vehicles/snowPlowStationary.png';
import streetSweeper from 'src/common/assets/img/vehicles/streetSweeper.png';
import streetSweeperInactive from 'src/common/assets/img/vehicles/streetSweeperInactive.png';
import streetSweeperStationary from 'src/common/assets/img/vehicles/streetSweeperStationary.png';
import toter from 'src/common/assets/img/vehicles/toter.png';
import toterInactive from 'src/common/assets/img/vehicles/toterInactive.png';
import toterStationary from 'src/common/assets/img/vehicles/toterStationary.png';
import vehicle from 'src/common/assets/img/vehicles/vehicle.png';
import vehicleInactive from 'src/common/assets/img/vehicles/vehicleInactive.png';
import vehicleStationary from 'src/common/assets/img/vehicles/vehicleStationary.png';
import wasteAudit from 'src/common/assets/img/vehicles/wasteAudit.png';
import wasteAuditInactive from 'src/common/assets/img/vehicles/wasteAuditInactive.png';
import wasteAuditStationary from 'src/common/assets/img/vehicles/wasteAuditStationary.png';

interface CategorySymbolMapping {
    [key: string]: string;
}

export const icCategorySymbolMapping: CategorySymbolMapping = {
    'icScheduled': icScheduled,
    'icAssistedCancelled': icAssistedCancelled,
    'icAssistedCompleted': icAssistedCompleted,
    'icAssistedInProgress': icAssistedInProgress,
    'icAssistedIssueReportedNotServiced': icAssistedIssueReportedNotServiced,
    'icAssistedIssueReportedServiced': icAssistedIssueReportedServiced,
    'icAssistedIssueReported': icAssistedIssueReported,
    'icAssistedOnHold': icAssistedOnHold,
    'icAssistedReassigned': icAssistedReassigned,
    'icAssistedScheduled': icAssistedScheduled,
    'icCancelled': icCancelled,
    'icCompleted': icCompleted,
    'icFlaggedAssistedCancelled': icFlaggedAssistedCancelled,
    'icFlaggedAssistedCompleted': icFlaggedAssistedCompleted,
    'icFlaggedAssistedIssueReportedNotServiced': icFlaggedAssistedIssueReportedNotServiced,
    'icFlaggedAssistedIssueReportedServiced': icFlaggedAssistedIssueReportedServiced,
    'icFlaggedAssistedIssueReported': icFlaggedAssistedIssueReported,
    'icFlaggedAssistedReassigned': icFlaggedAssistedReassigned,
    'icFlaggedAssistedScheduled': icFlaggedAssistedScheduled,
    'icFlaggedAssistedOnHold': icFlaggedAssistedOnHold,
    'icFlaggedCancelled': icFlaggedCancelled,
    'icFlaggedCompleted': icFlaggedCompleted,
    'icFlaggedInProgress': icFlaggedInProgress,
    'icFlaggedIssueReportedNotServiced': icFlaggedIssueReportedNotServiced,
    'icFlaggedIssueReportedServiced': icFlaggedIssueReportedServiced,
    'icFlaggedIssueReported': icFlaggedIssueReported,
    'icFlaggedOnHold': icFlaggedOnHold,
    'icFlaggedReassigned': icFlaggedReassigned,
    'icFlaggedScheduled': icFlaggedScheduled,
    'icInProgress': icInProgress,
    'icIssueReportedNotServiced': icIssueReportedNotServiced,
    'icIssueReportedServiced': icIssueReportedServiced,
    'icIssueReported': icIssueReported,
    'icNewAssistedCancelled': icNewAssistedCancelled,
    'icNewAssistedCompleted': icNewAssistedCompleted,
    'icNewAssistedInProgress': icNewAssistedInProgress,
    'icNewAssistedIssueReportedNotServiced': icNewAssistedIssueReportedNotServiced,
    'icNewAssistedIssueReportedServiced': icNewAssistedIssueReportedServiced,
    'icNewAssistedIssueReported': icNewAssistedIssueReported,
    'icNewAssistedOnHold': icNewAssistedOnHold,
    'icNewAssistedReassigned': icNewAssistedReassigned,
    'icNewAssistedScheduled': icNewAssistedScheduled,
    'icNewCancelled': icNewCancelled,
    'icNewCompleted': icNewCompleted,
    'icNewFlaggedAssistedCancelled': icNewFlaggedAssistedCancelled,
    'icNewFlaggedAssistedCompleted': icNewFlaggedAssistedCompleted,
    'icNewFlaggedAssistedInProgress': icNewFlaggedAssistedInProgress,
    'icNewFlaggedAssistedIssueReportedNotServiced': icNewFlaggedAssistedIssueReportedNotServiced,
    'icNewFlaggedAssistedIssueReportedServiced': icNewFlaggedAssistedIssueReportedServiced,
    'icNewFlaggedAssistedIssueReported': icNewFlaggedAssistedIssueReported,
    'icNewFlaggedAssistedOnHold': icNewFlaggedAssistedOnHold,
    'icNewFlaggedAssistedReassigned': icNewFlaggedAssistedReassigned,
    'icNewFlaggedAssistedScheduled': icNewFlaggedAssistedScheduled,
    'icNewFlaggedCancelled': icNewFlaggedCancelled,
    'icNewFlaggedCompleted': icNewFlaggedCompleted,
    'icNewFlaggedInProgress': icNewFlaggedInProgress,
    'icNewFlaggedIssueReportedNotServiced': icNewFlaggedIssueReportedNotServiced,
    'icNewFlaggedIssueReportedServiced': icNewFlaggedIssueReportedServiced,
    'icNewFlaggedIssueReported': icNewFlaggedIssueReported,
    'icNewFlaggedOnHold': icNewFlaggedOnHold,
    'icNewFlaggedReassigned': icNewFlaggedReassigned,
    'icNewFlaggedScheduled': icNewFlaggedScheduled,
    'icNewInProgress': icNewInProgress,
    'icNewIssueReportedNotServiced': icNewIssueReportedNotServiced,
    'icNewIssueReportedServiced': icNewIssueReportedServiced,
    'icNewIssueReported': icNewIssueReported,
    'icNewOnHold': icNewOnHold,
    'icNewReassigned': icNewReassigned,
    'icNewScheduled': icNewScheduled,
    'icOnHoldAssistedCompleted': icOnHoldAssistedCompleted,
    'icOnHoldAssistedCancelled': icOnHoldAssistedCancelled,
    'icOnHoldAssistedInProgress': icOnHoldAssistedInProgress,
    'icOnHoldAssistedIssueReportedNotServiced': icOnHoldAssistedIssueReportedNotServiced,
    'icOnHoldAssistedIssueReportedServiced': icOnHoldAssistedIssueReportedServiced,
    'icOnHoldAssistedIssueReported': icOnHoldAssistedIssueReported,
    'icOnHoldAssistedOnHold': icOnHoldAssistedOnHold,
    'icOnHoldAssistedReassigned': icOnHoldAssistedReassigned,
    'icOnHoldAssistedScheduled': icOnHoldAssistedScheduled,
    'icOnHoldCancelled': icOnHoldCancelled,
    'icOnHoldCompleted': icOnHoldCompleted,
    'icOnHoldFlaggedAssistedCancelled': icOnHoldFlaggedAssistedCancelled,

}

export const categorySymbolMapping: CategorySymbolMapping = {

    'cart': cart,
    'cartInactive': cartInactive,
    'cartStationary': cartStationary,
    'delivery': delivery,
    'deliveryInactive': deliveryInactive,
    'deliveryStationary': deliveryStationary,
    'exclamation': exclamation,
    'frontLoad': frontLoad,
    'frontLoadInactive': frontLoadInactive,
    'frontLoadStationary': frontLoadStationary,
    'pickupTruck': pickupTruck,
    'pickupTruckInactive': pickupTruckInactive,
    'pickupTruckStationary': pickupTruckStationary,
    'residential': residential,
    'residentialInactive': residentialInactive,
    'residentialStationary': residentialStationary,
    'rollOff': rollOff,
    'rollOffInactive': rollOffInactive,
    'rollOffStationary': rollOffStationary,
    'snowPlow': snowPlow,
    'snowPlowInactive': snowPlowInactive,
    'snowPlowStationary': snowPlowStationary,
    'streetSweeper': streetSweeper,
    'streetSweeperInactive': streetSweeperInactive,
    'streetSweeperStationary': streetSweeperStationary,
    'toter': toter,
    'toterInactive': toterInactive,
    'toterStationary': toterStationary,
    'vehicle': vehicle,
    'vehicleInactive': vehicleInactive,
    'vehicleStationary': vehicleStationary,
    'wasteAudit': wasteAudit,
    'wasteAuditInactive': wasteAuditInactive,
    'wasteAuditStationary': wasteAuditStationary,
    'disposal': disposal,
    'boxYard': boxYard,
    'disposalSite': disposalSite,
    'truckYard': truckYard,
    'saltChemical': saltChemical,
    'fuelingStation': fuelingStation,
    'other': other,
    'waterFillingStation': waterFillingStation,
    'containerYard': containerYard,
    'truckYardNew': truckYardNew,
    'maintenance': maintenance,
    'locationAlert': locationAlert,
    'accident': accident,
    'icyBridge': icyBridge,
    'metalPlates': metalPlates,
    'slickSpot': slickSpot,
    'snowDrift': snowDrift,
    'speedBump': speedBump,
    'issue': issue,
    'baggedRecyclables': baggedRecyclables,
    'doNotBagRecyclables': baggedRecyclables,
    'binNotOut': containerNotOut,
    'containerOut': containerOut,
    'brokenBin': damagedContainer,
    'brokenContainer': damagedContainer,
    'containerBroken': damagedContainer,
    'brokenSidewalkCurb': brokenSidewalkCurb,
    'brokenTrafficSignal': brokenTrafficSignal,
    'cancelled': cancelled,
    'cardboardInContainer': cardboardInContainer,
    'cardboardNotFlattened': cardboardNotFlattened,
    'cleanRecycling': cleanRecycling,
    'recycle3048Gallons': cleanRecycling,
    'recycleLessThan30Gallons': cleanRecycling,
    'recycleMoreThan48Gallons': cleanRecycling,
    'cloggedInlet': cloggedInlet,
    'cog': cog,
    'completed': completed,
    'contaminated': contaminated,
    'curbObstruction': curbObstruction,
    'damagedStreetSign': damagedStreetSign,
    'exceptionsObstacles': exceptionsObstacles,
    'graffiti': graffiti,
    'handicap': handicap,
    'hangingWires': hangingWires,
    'hardAcceleration': hardDriving,
    'hardBraking': hardDriving,
    'hardLeftTurn': hardDriving,
    'hardRightTurn': hardDriving,
    'hardTurning': hardDriving,
    'highGrassWeeds': highGrassWeeds,
    'idleTime': idleTime,
    'inProgress': inProgress,
    'insideGeoFence': insideGeoFence,
    'improperStorageArea': improperStorageArea,
    'leftAsTrash': leftAsTrash,
    'litterJunk': litterJunk,
    'lowBranches': lowBranches,
    'materialContamination': materialContamination,
    'multiple': multiple,
    'noElectronics': noElectronics,
    'noFoodOrLiquid': foodOrLiquid,
    'noPlasticBags': plasticBags,
    'noTanglersCordsHosesOrChains': tangledCords,
    'noTanksWoodPlasticFurnitureOrMetal': metalWoodTanks,
    'missingNewAddress': missingNewAddress,
    'notSweeping': notSweeping,
    'number1': number1,
    'number2': number2,
    'number3': number3,
    'offRoute': offRoute,
    'offRouteStationary': offRouteStationary,
    'oops': oops,
    'outsideGeoFence': outsideGeoFence,
    'overflowingBins': overflowingBins,
    'placedOnHold': placedOnHold,
    'plasticInContainer': plasticInContainer,
    'potholes': potholes,
    'recRolltainerOutside': recRolltainerOutside,
    'scheduled': scheduled,
    'serviced': serviced,
    'speeding': speeding,
    'stationary': stationary,
    'streetLightBroken': streetLightBroken,
    'streetObstruction': streetObstruction,
    'styrofoam': styrofoam,
    'textiles': textiles,
    'thanks': thanks,
    'trash3048Gallons': trash,
    'trashLessThan30Gallons': trash,
    'trashMoreThan48Gallons': trash,
    'unlockedContainer': unlockedContainer,
    'unScheduled': unScheduled,
    'vacant': vacant,
    'vacantCar': vacantCar,
    
};