import { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { size } from 'lodash-es';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import { Button, Message, Panel, PanelSection, PanelSectionGroup } from '../../../core/components/styled';
import { createUrl, getQueryParams } from '../../../utils/services/queryParams';
import { currentVendorIdSelector } from '../../../vendors/services/currentVendorSelector';
import { date } from '../../../utils/services/formatter';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { exportReportingFaultCodeDetails, resetReportingFaultCodeDetails } from '../../ducks';
import { FAULT_CODES } from '../../constants';
import {
  PageActions,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageTitleContainer,
  PageBackButtonAction,
} from '../../../common/components/styled';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { FaultCodeDetailsPageTableRow } from '.';
import { Table } from '../../../core/components';
import history from 'src/core/services/history';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  eventDate: string;
  exportReportingFaultCodeDetails: DuckFunction<typeof exportReportingFaultCodeDetails>;
  faultCodeDetails: any[];
  isExporting: boolean;
  isLoading: boolean;
  lastLocations: Dictionary<string>;
  push: (url: string) => void;
  resetReportingFaultCodeDetails: DuckAction<typeof resetReportingFaultCodeDetails>;
  vehicleName: string[];
  vehicleTypeName: string[];
  vendorId: number;
}

class FaultCodeDetailsPage extends Component<Props> {
  componentWillUnmount() {
    this.props.resetReportingFaultCodeDetails();
  }

  exportFaultCodeDetails = () => {
    const { vendorId, location, exportReportingFaultCodeDetails } = this.props;
    const { date, vehicleId, vehicleName } = getQueryParams(location.search);

    exportReportingFaultCodeDetails(vendorId, date, vehicleId, vehicleName);
  };

  render() {
    const { eventDate, faultCodeDetails, isExporting, isLoading, location, vehicleName, vehicleTypeName } = this.props;
    const { startDate, endDate, vehicleTypeId, reportType = FAULT_CODES } = getQueryParams(location.search);
    const faultCodeDetailsTableCells = [
      { name: 'faultCodeNumber', label: translate('insights.faultCodeNumber'), width: '10%' },
      { name: 'faultCode', label: translate('insights.faultCodeDescription'), width: '25%', sortable: true },
      { name: 'fmiNumber', label: translate('insights.fMINumber'), width: '10%' },
      { name: 'fmiDescription', label: translate('insights.fMIDescription'), width: '25%' },
      { name: 'occurenceCount', label: translate('insights.totalOccurrences'), width: '13%' },
      { name: 'dateTime', label: translate('common.timestamp'), width: '17%', sortable: true },
    ];

    const goBackToPreviousPage = () => {
      const { lastLocations, push } = this.props;
      const hasLastLocations = Object.keys(lastLocations).length > 1;

      return hasLastLocations
        ? history.goBack()
        : push(
            createUrl('/insights/reporting-details', undefined, {
              startDate,
              endDate,
              vehicleTypeId,
              reportType,
            }),
          );
    };

    return (
      <PageContent>
        <PageHeader>
          <PageDetails withBackButton>
            <PageTitleContainer>
              <PageBackButtonAction id={`fault-codes-back-button`} onClick={goBackToPreviousPage}>
                <PageBackButtonIcon />
              </PageBackButtonAction>
              <PageTitle>{translate('insights.faultCodeDetails')}</PageTitle>
              <PageSubtitle>
                {vehicleTypeName} - {vehicleName}
              </PageSubtitle>
              <PageSubtitle>{date(eventDate)}</PageSubtitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button color="primary" onClick={this.exportFaultCodeDetails} disabled={!size(faultCodeDetails)}>
              {translate('common.export')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isExporting}>
            <PanelSection>
              {!!size(faultCodeDetails) && (
                <Table
                  cells={faultCodeDetailsTableCells}
                  rows={faultCodeDetails}
                  rowComponent={FaultCodeDetailsPageTableRow}
                />
              )}

              {!size(faultCodeDetails) && (
                <Message padding="sMedium">{translate('insights.noFaultCodeDetails')}</Message>
              )}
            </PanelSection>
          </PanelSectionGroup>
        </Panel>
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { reportingFaultCodeDetails } = state.insights;
  const { reportingFaultCodeDetails: reportingFaultCodeDetailsDeep } = reportingFaultCodeDetails as any;

  return {
    eventDate: reportingFaultCodeDetailsDeep.eventDate,
    faultCodeDetails: reportingFaultCodeDetailsDeep.faultCodeDetails,
    isExporting: reportingFaultCodeDetails.isExporting,
    isLoading: reportingFaultCodeDetails.isLoading,
    vehicleName: reportingFaultCodeDetailsDeep.vehicleName,
    vehicleTypeName: reportingFaultCodeDetailsDeep.vehicleTypeName,
    vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
    lastLocations: state.core.lastLocations,
  };
};

const mapDispatchToProps = {
  exportReportingFaultCodeDetails,
  resetReportingFaultCodeDetails,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FaultCodeDetailsPage));
