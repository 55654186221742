import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const CONTAINER_TYPE_ROLL_OFF_ID = 1;
export const CONTAINER_TYPE_COMMERCIAL_ID = 2;
export const CONTAINER_TYPE_CART_ID = 3;

export const CONTAINER_TYPES = mapKeys(
  [
    { id: CONTAINER_TYPE_ROLL_OFF_ID, name: translate('common.equipmentTypes.rollOff') },
    { id: CONTAINER_TYPE_COMMERCIAL_ID, name: translate('common.equipmentTypes.commercial') },
    { id: CONTAINER_TYPE_CART_ID, name: translate('common.equipmentTypes.cart') },
  ],
  'id',
);
