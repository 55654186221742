import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';

import { RouteTemplateScheduler, RouteTemplatesQueryParams, RouteTemplatesState } from '../interfaces/RouteTemplates';
import { deleteRouteTemplate as doDeleteRouteTemplates } from '../services/routeTemplate';
import {
  createRouteFromRouteTemplate as doCreateRouteFromRouteTemplate,
  exportRouteTemplates as doExportRouteTemplates,
  fetchRouteTemplatesSchedulers as doFetchRouteTemplatesSchedulers,
  loadRouteTemplates as doLoadRouteTemplates,
  loadRouteTemplatesForHolidayPlanner as doLoadRouteTemplatesForHolidayPlanner,
  scheduleAllSnowRouteTemplates as doScheduleAllSnowRouteTemplates,
  scheduleAllStreetSweeperRouteTemplates as doScheduleAllStreetSweeperRouteTemplates,
  scheduleRouteTemplates as doScheduleRouteTemplates,
} from '../services/routeTemplates';

// Actions
const FAIL_CREATE_ROUTE = 'routes/routeTemplates/FAIL_CREATE_ROUTE';
const FAIL_DELETE = 'routes/routeTemplates/FAIL_DELETE';
const FAIL_EXPORT = 'routes/routeTemplates/FAIL_EXPORT';
const FAIL_LOAD = 'routes/routeTemplates/FAIL_LOAD';
const FAIL_LOAD_FOR_HOLIDAY_PLANNER = 'routes/routeTemplates/FAIL_LOAD_FOR_HOLIDAY_PLANNER';
const FAIL_SCHEDULE_ROUTES = 'routes/routeTemplates/FAIL_SCHEDULE_ROUTES';
const RESET = 'routes/routeTemplates/RESET';
const START_CREATE_ROUTE = 'routes/routeTemplates/START_CREATE_ROUTE';
const START_DELETE = 'routes/routeTemplates/START_DELETE';
const START_EXPORT = 'routes/routeTemplates/START_EXPORT';
const START_LOAD = 'routes/routeTemplates/START_LOAD';
const START_LOAD_FOR_HOLIDAY_PLANNER = 'routes/routeTemplates/START_LOAD_FOR_HOLIDAY_PLANNER';
const START_SCHEDULE_ROUTES = 'routes/routeTemplates/START_SCHEDULE_ROUTES';
export const COMPLETE_CREATE_ROUTE = 'routes/routeTemplates/COMPLETE_CREATE_ROUTE';
export const COMPLETE_DELETE = 'routes/routeTemplates/COMPLETE_DELETE';
export const COMPLETE_EXPORT = 'routes/routeTemplates/COMPLETE_EXPORT';
export const COMPLETE_LOAD = 'routes/routeTemplates/COMPLETE_LOAD';
export const COMPLETE_LOAD_FOR_HOLIDAY_PLANNER = 'routes/routeTemplates/COMPLETE_LOAD_FOR_HOLIDAY_PLANNER';
export const COMPLETE_SCHEDULE_ROUTES = 'routes/routeTemplates/COMPLETE_SCHEDULE_ROUTES';
const START_LOAD_SCHEDULERS = 'routes/routeTemplates/START_LOAD_SCHEDULERS';
const COMPLETE_LOAD_SCHEDULERS = 'routes/routeTemplates/COMPLETE_LOAD_SCHEDULERS';
const FAIL_LOAD_SCHEDULERS = 'routes/routeTemplates/FAIL_LOAD_SCHEDULERS';
const RESET_SCHEDULERS = 'routes/routeTemplates/RESET_SCHEDULERS';

// Initial state
const initialState: RouteTemplatesState = {
  isCreating: false,
  isDeleting: false,
  isExporting: false,
  isLoading: false,
  isScheduling: false,
  schedulers: [],
  isLoadingSchedulers: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case START_LOAD_FOR_HOLIDAY_PLANNER:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplates: action.routeTemplates,
          total: action.total,
        },
      });

    case COMPLETE_LOAD_FOR_HOLIDAY_PLANNER:
      return update(state, {
        $merge: {
          isLoading: false,
          routeTemplatesForHolidayPlanner: action.routeTemplatesForHolidayPlanner,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case FAIL_LOAD_FOR_HOLIDAY_PLANNER:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_CREATE_ROUTE:
      return update(state, {
        $merge: {
          isCreating: true,
        },
      });

    case COMPLETE_CREATE_ROUTE:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case FAIL_CREATE_ROUTE:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case START_SCHEDULE_ROUTES:
      return update(state, {
        $merge: {
          isScheduling: true,
        },
      });

    case COMPLETE_SCHEDULE_ROUTES:
      return update(state, {
        $merge: {
          isScheduling: false,
        },
      });

    case FAIL_SCHEDULE_ROUTES:
      return update(state, {
        $merge: {
          isScheduling: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const routeTemplateIndex = findIndex(state.routeTemplates, { id: action.routeTemplateId });
      return update(state, {
        routeTemplates: { $splice: [[routeTemplateIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_LOAD_SCHEDULERS:
      return update(state, {
        $merge: {
          isLoadingSchedulers: true,
        },
      });

    case COMPLETE_LOAD_SCHEDULERS:
      return update(state, {
        $merge: {
          schedulers: action.schedulers,
          isLoadingSchedulers: false,
        },
      });

    case FAIL_LOAD_SCHEDULERS:
      return update(state, {
        $merge: {
          isLoadingSchedulers: false,
        },
      });

    case RESET_SCHEDULERS:
      return update(state, {
        $merge: {
          schedulers: [],
          isLoadingSchedulers: false,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const startLoadForHolidayPlanner = () => ({
  type: START_LOAD_FOR_HOLIDAY_PLANNER,
});

const completeLoad = (routeTemplates: any[], total: number, sortedBy: any) => ({
  type: COMPLETE_LOAD,
  routeTemplates,
  total,
  sortedBy,
});

const completeLoadForHolidayPlanner = (routeTemplatesForHolidayPlanner: any[]) => ({
  type: COMPLETE_LOAD_FOR_HOLIDAY_PLANNER,
  routeTemplatesForHolidayPlanner,
});

const failLoadForHolidayPlanner = () => ({
  type: FAIL_LOAD,
});

const failLoad = () => ({
  type: FAIL_LOAD_FOR_HOLIDAY_PLANNER,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const startCreateRoute = () => ({
  type: START_CREATE_ROUTE,
});

const completeCreateRoute = () => ({
  type: COMPLETE_CREATE_ROUTE,
});

const failCreateRoute = () => ({
  type: FAIL_CREATE_ROUTE,
});

const startScheduleRoutes = () => ({
  type: START_SCHEDULE_ROUTES,
});

const completeScheduleRoutes = () => ({
  type: COMPLETE_SCHEDULE_ROUTES,
});

const failScheduleRoutes = () => ({
  type: FAIL_SCHEDULE_ROUTES,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = (routeTemplateId: number) => ({
  type: COMPLETE_DELETE,
  routeTemplateId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const startLoadSchedulers = () => ({
  type: START_LOAD_SCHEDULERS,
});

const completeLoadSchedulers = (schedulers: RouteTemplateScheduler[]) => ({
  type: COMPLETE_LOAD_SCHEDULERS,
  schedulers,
});

const failLoadSchedulers = () => ({
  type: FAIL_LOAD_SCHEDULERS,
});

export const resetSchedulers = () => ({
  type: RESET_SCHEDULERS,
});

export const deleteRouteTemplate = (routeTemplateId: number) => (dispatch: Dispatch) => {
  dispatch(startDelete());
  const deleteRouteTemplatesPromise = doDeleteRouteTemplates(routeTemplateId);
  deleteRouteTemplatesPromise.then(() => dispatch(completeDelete(routeTemplateId))).catch(() => dispatch(failDelete()));
  return deleteRouteTemplatesPromise;
};

export const loadRouteTemplates = (queryParams: RouteTemplatesQueryParams) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRouteTemplatesPromise = doLoadRouteTemplates(queryParams);
  loadRouteTemplatesPromise
    .then(({ routeTemplates, total }) => dispatch(completeLoad(routeTemplates, total, queryParams.sortedBy)))
    .catch(() => dispatch(failLoad()));
  return loadRouteTemplatesPromise;
};

export const loadRouteTemplatesForHolidayPlanner =
  (
    vendorId: number,
    routeTemplateName: string,
    daysOfServiceIds: any[],
    vehicleTypeIds: string,
    routeStatus: number,
    page: number,
    limit: number,
    sortOrder: string,
    sortedBy: string,
    holidayId: number,
    isDefaultHoliday: boolean,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startLoadForHolidayPlanner());
    const loadRouteTemplatesForHolidayPlannerPromise = doLoadRouteTemplatesForHolidayPlanner(
      vendorId,
      routeTemplateName,
      daysOfServiceIds,
      vehicleTypeIds,
      routeStatus,
      page,
      limit,
      sortOrder,
      sortedBy,
      holidayId,
      isDefaultHoliday,
    );
    loadRouteTemplatesForHolidayPlannerPromise
      .then(({ routeTemplates }) => dispatch(completeLoadForHolidayPlanner(routeTemplates)))
      .catch(() => dispatch(failLoadForHolidayPlanner()));
    return loadRouteTemplatesForHolidayPlannerPromise;
  };

export const exportRouteTemplates =
  (
    vendorId: number,
    routeTemplateName: string,
    daysOfServiceIds: any[],
    vehicleTypeIds: number[],
    routeStatus: any,
    page: number,
    limit: number,
    sortOrder: string,
    sortedBy: string,
    serviceZoneIdsCSV?: string,
    supervisorIdsCSV?: string,
    groupIds?: number[],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportRouteTemplatesPromise = doExportRouteTemplates(
      vendorId,
      routeTemplateName,
      daysOfServiceIds,
      vehicleTypeIds,
      routeStatus,
      page,
      limit,
      sortOrder,
      sortedBy,
      serviceZoneIdsCSV,
      supervisorIdsCSV,
      groupIds,
    );
    exportRouteTemplatesPromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportRouteTemplatesPromise;
  };

export const resetRouteTemplates = () => ({
  type: RESET,
});

export const createRouteFromRouteTemplate =
  (vendorId: number, routeTemplateId: number, date: string) => (dispatch: Dispatch) => {
    dispatch(startCreateRoute());
    const createRouteFromTemplatePromise = doCreateRouteFromRouteTemplate(vendorId, routeTemplateId, date);
    createRouteFromTemplatePromise.then(() => dispatch(completeCreateRoute())).catch(() => dispatch(failCreateRoute()));
    return createRouteFromTemplatePromise;
  };

export const fetchRouteTemplatesSchedulers =
  (routeTemplateIds: number[], date: Date | string, vendorId: number) => (dispatch: Dispatch) => {
    dispatch(startLoadSchedulers());

    const fetchRouteTemplatesSchedulersPromise = doFetchRouteTemplatesSchedulers(routeTemplateIds, date, vendorId);

    fetchRouteTemplatesSchedulersPromise
      .then(schedulers => dispatch(completeLoadSchedulers(schedulers)))
      .catch(() => dispatch(failLoadSchedulers()));

    return fetchRouteTemplatesSchedulersPromise;
  };

export const scheduleRouteTemplates =
  (
    routeTemplateIds: number[],
    date: Date | string,
    vendorId: number,
    skipOtherSchedulersDuringCurrentWeek: boolean = false,
    groupIds: number[] = [],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startScheduleRoutes());

    const scheduleRouteTemplatesPromise = doScheduleRouteTemplates(
      routeTemplateIds,
      date,
      vendorId,
      skipOtherSchedulersDuringCurrentWeek,
      groupIds,
    );

    scheduleRouteTemplatesPromise
      .then(() => dispatch(completeScheduleRoutes()))
      .catch(() => dispatch(failScheduleRoutes()));

    return scheduleRouteTemplatesPromise;
  };

export const scheduleAllSnowRouteTemplates =
  (
    date: Date | string,
    vendorId: number,
    skipOtherSchedulersDuringCurrentWeek: boolean = false,
    groupIds: number[] = [],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startScheduleRoutes());

    const scheduleAllRouteTemplatesPromise = doScheduleAllSnowRouteTemplates(
      date,
      vendorId,
      skipOtherSchedulersDuringCurrentWeek,
      groupIds,
    );

    scheduleAllRouteTemplatesPromise
      .then(() => dispatch(completeScheduleRoutes()))
      .catch(() => dispatch(failScheduleRoutes()));

    return scheduleAllRouteTemplatesPromise;
  };

export const scheduleAllStreetSweeperRouteTemplates =
  (
    date: Date | string,
    vendorId: number,
    skipOtherSchedulersDuringCurrentWeek: boolean = false,
    groupIds: number[] = [],
  ) =>
  (dispatch: Dispatch) => {
    dispatch(startScheduleRoutes());

    const scheduleAllStreetSweeperRouteTemplatesPromise = doScheduleAllStreetSweeperRouteTemplates(
      date,
      vendorId,
      skipOtherSchedulersDuringCurrentWeek,
      groupIds,
    );

    scheduleAllStreetSweeperRouteTemplatesPromise
      .then(() => dispatch(completeScheduleRoutes()))
      .catch(() => dispatch(failScheduleRoutes()));

    return scheduleAllStreetSweeperRouteTemplatesPromise;
  };
