import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash-es';
import { formValueSelector } from 'redux-form';

import {
  DashboardMapInsights as DashboardMapInsightsContainer,
  DashboardMapInsightsDate,
  DashboardMapInsightsDateLabel,
  DashboardMapInsightsToggle,
  DashboardMapInsightsToggleIcon,
  DashboardMapInsightsLinkContainer,
  DashboardMapInsightsLink,
  DashboardMapInsightsLinkIcon,
  DashboardMapInsightsCategories,
} from '../../styled';
import {
  vehicleInsightsSelector,
  vehicleMapInsightTypesSelector,
  containerMapInsightTypesSelector,
  cityMapInsightTypesSelector,
  toggleMapInsightsContainer,
} from '../../../ducks';
import { vehicleTypesForVendorWithRoleTypeSelector } from '../../../../fleet/ducks';
import { ROUTE } from '../../../../fleet/constants';
import DashboardCollapsible from './DashboardCollapsible';
import DashboardMapInsightsSection from './DashboardMapInsightsSection';
import translate from '../../../../core/services/translate';
import { AppState } from '../../../../store';
import { DuckAction } from '../../../../contracts/ducks';
import { DASHBOARD_FILTER_FORM_NAME } from 'src/dashboard/constants/dashboardFilter';

interface ComponentProps {
  isSubMenuHidden: boolean;
}

interface Props extends ComponentProps {
  date: Date | string;
  isLoadingVehiclePositions: boolean;
  isLoadingVehicleInsights: boolean;
  vehicleInsights?: any[];
  vehicleMapInsightTypes?: any[];
  containerMapInsightTypes?: any[];
  cityMapInsightTypes?: any[];
  vehicleTypesForVendor?: any[];
  toggleMapInsightsContainer: DuckAction<typeof toggleMapInsightsContainer>;
}

interface State {
  isOpen: boolean;
}

class DashboardMapInsights extends PureComponent<Props, State> {
  readonly state: State = { isOpen: true };

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
    this.props.toggleMapInsightsContainer(this.state.isOpen);
  };

  render() {
    const {
      cityMapInsightTypes = [],
      containerMapInsightTypes = [],
      date,
      isLoadingVehicleInsights,
      isLoadingVehiclePositions,
      isSubMenuHidden,
      vehicleInsights = [],
      vehicleMapInsightTypes = [],
      vehicleTypesForVendor,
    } = this.props;
    const isOpen = !isLoadingVehiclePositions && !isLoadingVehicleInsights && this.state.isOpen;
    const routeSummaryVehicleTypes = map(vehicleTypesForVendor, vehicleType => vehicleType.technicalName);

    return (
      <DashboardMapInsightsContainer isSubMenuHidden={isSubMenuHidden}>
        <DashboardMapInsightsDate>
          <DashboardMapInsightsDateLabel>{translate('common.serviceDate')}:</DashboardMapInsightsDateLabel>
          {date}
        </DashboardMapInsightsDate>

        {(!!vehicleInsights.length ||
          !!containerMapInsightTypes.length ||
          !!cityMapInsightTypes.length ||
          !!vehicleMapInsightTypes.length) && (
          <Fragment>
            <DashboardMapInsightsToggle isOpen={isOpen} onClick={this.toggle}>
              <DashboardMapInsightsToggleIcon />
            </DashboardMapInsightsToggle>

            <DashboardMapInsightsLinkContainer>
              <DashboardMapInsightsLink
                to={`/routes/route-tracker?startDate=${date}&endDate=${date}&vehicleTypes=${routeSummaryVehicleTypes.toString()}`}
                id="dashboard-route-tracker-link"
              >
                <DashboardMapInsightsLinkIcon icon="routes" />
                {translate('routes.tracker')}
              </DashboardMapInsightsLink>
            </DashboardMapInsightsLinkContainer>
          </Fragment>
        )}

        <DashboardCollapsible isOpen={isOpen}>
          <DashboardMapInsightsCategories>
            {!!vehicleInsights.length && (
              <DashboardMapInsightsSection
                insights={vehicleInsights}
                category="vehicles"
                title={translate('vehicles.vehicles')}
                hasVehicleInsights
                id="dashboard-insights-vehicles"
              />
            )}

            {!!vehicleMapInsightTypes.length && (
              <DashboardMapInsightsSection
                insights={vehicleMapInsightTypes}
                category="vehicleInsights"
                title={translate('dashboard.vehicleInsights')}
                id="dashboard-insights-vehicle-insights"
              />
            )}

            {!!containerMapInsightTypes.length && (
              <DashboardMapInsightsSection
                insights={containerMapInsightTypes}
                category="containerInsights"
                title={translate('dashboard.containerInsights')}
                id="dashboard-insights-container"
              />
            )}

            {!!cityMapInsightTypes.length && (
              <DashboardMapInsightsSection
                insights={cityMapInsightTypes}
                category="cityInsights"
                title={translate('dashboard.cityInsights')}
                id="dashboard-insights-city"
              />
            )}
          </DashboardMapInsightsCategories>
        </DashboardCollapsible>
      </DashboardMapInsightsContainer>
    );
  }
}

const formSelector = formValueSelector(DASHBOARD_FILTER_FORM_NAME);

const mapStateToProps = (state: AppState) => ({
  date: formSelector(state, 'date'),
  vehicleInsights: vehicleInsightsSelector(state.dashboard.vehiclesData),
  vehicleMapInsightTypes: vehicleMapInsightTypesSelector(state.dashboard.mapInsights),
  containerMapInsightTypes: containerMapInsightTypesSelector(state.dashboard.mapInsights),
  cityMapInsightTypes: cityMapInsightTypesSelector(state.dashboard.mapInsights),
  isLoadingVehiclePositions: state.dashboard.vehiclesData.isLoading,
  isLoadingVehicleInsights: state.dashboard.mapInsights.isLoading,
  vehicleTypesForVendor: (vehicleTypesForVendorWithRoleTypeSelector as any)(state.fleet.vehicleTypesForVendor, ROUTE),
});

const mapDispatchToProps = {
  toggleMapInsightsContainer,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMapInsights);
