import { AnyAction, Dispatch } from 'redux';
import update from 'immutability-helper';

import * as Services from '../services/vehicleFaultCodes';
import { VehicleFaultCodesState, VehicleFaultCode } from '../interfaces/VehicleFaultCodes';

// Actions
const START_LOAD = 'vendors/vehicle/faultCodes/START_LOAD';
const COMPLETE_LOAD = 'vendors/vehicle/faultCodes/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/vehicle/faultCodes/FAIL_LOAD';
const RESET = 'vendors/vehicle/faultCodes/RESET';

const initialState: VehicleFaultCodesState = {
  faultCodes: [],
  faultCodesLoading: false,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          faultCodesLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          faultCodes: action.faultCodes || [],
          faultCodesLoading: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          faultCodes: [],
          faultCodesLoading: false,
        },
      });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (faultCodes: VehicleFaultCode[] | null) => ({
  type: COMPLETE_LOAD,
  faultCodes,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const resetVehiclesFaultCodes = () => ({
  type: RESET,
});

export const loadVehicleFaultCodes = (vehicleId: number, startDate: string, endDate: string) => (
  dispatch: Dispatch,
) => {
  dispatch(startLoad());

  const promise = Services.loadVehicleFaultCodes(vehicleId, startDate, endDate);

  promise
    .then(faultCodes => {
      dispatch(completeLoad(faultCodes));
    })
    .catch(() => {
      dispatch(failLoad());
    });

  return promise;
};
