import { connect } from 'react-redux';
import { PureComponent, MouseEvent } from 'react';
import { size } from 'lodash-es';

import { AppState } from '../../../store';
import { Button, Message, ModalFixedFooter, PanelSection } from '../../../core/components/styled';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import { deleteLocationAlert, resetLocationAlerts } from '../../ducks';
import { DuckAction, DuckFunction } from '../../../contracts/ducks';
import { LocationAlert } from '../../interfaces/LocationAlert';
import { LocationAlertEditorModal, LocationAlertsModalTableRow } from './';
import { Modal, Table } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import confirm from '../../../core/services/confirm';
import translate from '../../../core/services/translate';

export const locationAlertsModalId = 'location-alerts-seetings-modal';

interface Props extends ModalProps {
  deleteLocationAlert: DuckFunction<typeof deleteLocationAlert>;
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  locationAlerts?: LocationAlert[];
  resetLocationAlerts: DuckAction<typeof resetLocationAlerts>;
  vendorId: number;
}

interface State {
  locationAlert?: LocationAlert;
  isLocationAlertEditorModalOpen: boolean;
}

class LocationAlertsModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLocationAlertEditorModalOpen: false,
      locationAlert: undefined,
    };
  }

  componentWillUnmount() {
    this.props.resetLocationAlerts();
  }

  openLocationAlertEditorModal = (event: MouseEvent, locationAlert: LocationAlert) => {
    event.stopPropagation();
    this.setState({ isLocationAlertEditorModalOpen: true, locationAlert });
  };

  closeLocationAlertEditorModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    this.setState({ isLocationAlertEditorModalOpen: false, locationAlert: undefined });
  };

  removeLocationAlert = async (locationAlertTypeId: number) => {
    const { vendorId, deleteLocationAlert } = this.props;

    if (!(await confirm(translate('vendors.alertMessages.confirmDeleteLocationAlert')))) {
      return;
    }

    scrollToTopOfModal(locationAlertsModalId);

    deleteLocationAlert(vendorId, locationAlertTypeId)
      .then(() => createSuccessNotification(translate('vendors.alertMessages.locationAlertDeleted')))
      .catch(() => createErrorNotification(translate('vendors.alertMessages.deleteLocationAlertError')));
  };

  render() {
    const { closeModal, isDeleting, isLoading, isSaving, locationAlerts, vendorId } = this.props;
    const { isLocationAlertEditorModalOpen, locationAlert } = this.state;

    const locationAlertsTableCells = [
      { name: 'title', label: translate('vendors.alertTitle'), width: '30%' },
      { name: 'description', label: translate('vendors.alertDescription'), width: '40%' },
      { name: 'isActive', width: '10%' },
      { name: 'options', label: translate('common.options'), width: '20%', align: 'right' },
    ];

    return (
      <Modal
        title={translate('vendors.featureCodes.locationAlerts')}
        onClose={closeModal}
        isLoading={isLoading || isSaving || isDeleting}
        padding="medium no no no"
        id={locationAlertsModalId}
        overflow={isSaving || isDeleting ? 'hidden' : 'auto'}
      >
        {!!size(locationAlerts) && (
          <PanelSection padding="small" vertical>
            <Table
              cells={locationAlertsTableCells}
              rows={locationAlerts}
              rowComponent={LocationAlertsModalTableRow}
              rowProps={{
                openLocationAlertEditorModal: this.openLocationAlertEditorModal,
                removeLocationAlert: this.removeLocationAlert,
              }}
            />
          </PanelSection>
        )}
        {!size(locationAlerts) && <Message padding="sMedium">{translate('vendors.noLocationAlerts')}</Message>}

        <ModalFixedFooter isShadowed>
          <Button color="primary" onClick={this.openLocationAlertEditorModal}>
            {translate('vendors.addLocationAlert')}
          </Button>
        </ModalFixedFooter>

        {!!isLocationAlertEditorModalOpen && (
          <LocationAlertEditorModal
            vendorId={vendorId}
            closeModal={this.closeLocationAlertEditorModal}
            locationAlert={locationAlert}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isDeleting: state.vendors.locationAlerts.isDeleting,
  isLoading: state.vendors.locationAlerts.isLoading,
  isSaving: state.vendors.locationAlerts.isSaving,
  locationAlerts: state.vendors.locationAlerts.locationAlerts,
});

const mapDispatchToProps = { deleteLocationAlert, resetLocationAlerts };

export default connect(mapStateToProps, mapDispatchToProps)(LocationAlertsModal);
