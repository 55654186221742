import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadClosestStrobeImage } from 'src/routes/ducks';
import { MapGLPopupSmallLoading } from 'src/common/components/map/MapGLPopup';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import StrobeImages from './StrobeImages';

interface StrobeImagesResolverProps {
  setIsStrobeImageModalOpen: (isOpen: boolean) => void;
  timestamp?: string;
  vehicleId: number;
}

const StrobeImagesResolver = ({ setIsStrobeImageModalOpen, timestamp, vehicleId }: StrobeImagesResolverProps) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const [strobeImageUrl, setStrobeImageUrl] = useState<string | undefined>();

  const loadDependencies = () => {
    if (!vendorId) return Promise.reject();

    return Promise.all([
      loadClosestStrobeImage(
        vendorId,
        vehicleId,
        timestamp,
      )(dispatch)
        .then(response => {
          setStrobeImageUrl(response?.thumbnailImageUrl);
        })
        .catch(() => {}),
    ]);
  };

  return (
    <TypedResolver
      successComponent={StrobeImages}
      successProps={{ strobeImageUrl, setIsStrobeImageModalOpen }}
      loadingComponent={MapGLPopupSmallLoading}
      resolve={loadDependencies}
    />
  );
};

export default StrobeImagesResolver;
