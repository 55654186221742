import { http } from '../../core/services/http';
import { RouteAssistData } from '../interfaces/RouteAssist';

export const loadRouteAssist = (vendorId: number, routeId: number) =>
  http.get<RouteAssistData>(`${vendorId}/routes/${routeId}/routeDetail/assists`).then(response => response.data);

export const loadSnowSweeperRouteAssist = (
  vendorId: number,
  routeId: number,
  startDate?: Date | string,
  endDate?: Date | string,
) =>
  http
    .get<RouteAssistData>(`${vendorId}/routes/${routeId}/routeDetail/alternativeFleet/assists`, {
      params: { startDate, endDate },
    })
    .then(response => response.data);
