import { FC, useCallback, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';

import alertMapIcon from 'src/common/assets/img/common/alertMapIcon.png';
import { Coordinates } from 'src/common/components/map/Coordinates';
import { useDraggableMarkers } from 'src/common/components/map/hooks/useDraggableMarkers';
import { useMapDoubleClick } from 'src/common/components/map/hooks/useMapDoubleClick';
import { useMapImages } from 'src/common/components/map/hooks/useMapImages';
import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { LocationData } from 'src/customers/interfaces/LocationServiceTypes';
import { LOCATION_ALERTS_ID } from 'src/insights/constants/reportTypes';

const sourceId = 'LocationMapPicker-point';
const layerId = 'LocationMapPicker-point-layer';

const getFeatureFromLocation = (location: LocationData) => {
  const { latitude, longitude } = location;
  const id = LOCATION_ALERTS_ID;
  return getPointFeature(id, [longitude, latitude], {
    id,
    latitude,
    longitude,
  });
};

interface Props {
  isPinOnMapVisible: boolean;
  location?: LocationData;
  map: mapboxgl.Map;
  onLocationChanged: (location: Coordinates) => void;
  setDragPan: (drag: boolean) => void;
}

const mapImages = [
  {
    id: 'mapbox-marker-icon-green',
    url: alertMapIcon,
  },
];

const LocationMapPickerLayer: FC<Props> = ({ isPinOnMapVisible, location, map, onLocationChanged, setDragPan }) => {
  useMapImages(mapImages, map, { addLayers: false });

  // DRAGGING THE  MARKERS
  const geojson = useMemo(() => getFeatureCollection(location ? [getFeatureFromLocation(location)] : []), [location]);

  const handleDoubleClick = useCallback(
    (lng: number, lat: number, feature?: mapboxgl.MapboxGeoJSONFeature) => {
      // should move the marker to the new location
      const newLocation = {
        lat,
        lng,
      };
      isPinOnMapVisible && onLocationChanged(newLocation);
    },
    [onLocationChanged, isPinOnMapVisible],
  );

  useMapDoubleClick([layerId], handleDoubleClick, map);

  const onDragEnd = async (marker: GeoJSON.Feature<GeoJSON.Point, any>, updatedAddress: Coordinates) => {
    const lat = updatedAddress.lat;
    const lng = updatedAddress.lng;

    const newLocation = {
      lat,
      lng,
    };

    onLocationChanged(newLocation);

    return true;
  };

  useDraggableMarkers({
    map: map,
    source: geojson,
    sourceId,
    layerId,
    setDragPan,
    updateAddress: onDragEnd,
    draggingEnabled: isPinOnMapVisible,
    getFeatureId: feature => feature.properties?.id,
  });

  return (
    <Source type="geojson" id={sourceId} data={geojson}>
      <Layer
        id={layerId}
        type="symbol"
        source={sourceId}
        paint={{}}
        layout={{
          'icon-image': 'mapbox-marker-icon-green',
          'icon-size': 1,
          'icon-allow-overlap': true,
        }}
      />
    </Source>
  );
};

export default LocationMapPickerLayer;
